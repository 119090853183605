import { useEffect, useState, useMemo, useRef } from "react";
import Globe from "react-globe.gl";
import ReactDOMServer from "react-dom/server";
import { scaleSequentialSqrt, interpolateCool } from "d3"; // https://github.com/d3/d3-scale-chromatic
import { CountriesGeoJSON } from "./Feature";
import { countriesGeoJSON } from "./countries";
import { Typography } from "antd";

const data = countriesGeoJSON;
const { Text } = Typography;

const World = () => {
  const [countries] = useState<CountriesGeoJSON>(data);
  const [hoverD, setHoverD] = useState<any>();
  const [size, setSize] = useState<any>({ width: 400, height: 400 });
  const ref = useRef<HTMLDivElement>(null);
  const globeElement = useRef<any>();
  const a = <Text>AAAA</Text>;
  var at = ReactDOMServer.renderToStaticMarkup(a);

  useEffect(() => {
    if (globeElement.current) {
      globeElement.current.controls().autoRotate = true;
      globeElement.current.controls().autoRotateSpeed = 0.4;
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      console.log(ref.current.offsetWidth);
      setSize({ ...size, width: ref.current.offsetWidth });
    }
  }, [size]);

  const colorScale = scaleSequentialSqrt(interpolateCool);

  // GDP per capita (avoiding countries with small pop)
  const getVal = (feat: any) => feat.properties.GDP_MD_EST / Math.max(1e5, feat.properties.POP_EST);

  const maxVal = useMemo(() => Math.max(...countries.features.map(getVal)), [countries]);
  colorScale.domain([0, maxVal]);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        width: "100%",
        height: "80vh",
        borderRadius: "4px",
      }}
    >
      <Globe
        ref={globeElement}
        animateIn={true}
        width={size.width}
        height={size.height}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
        lineHoverPrecision={0}
        polygonsData={countries.features}
        polygonAltitude={(d: any) => (d === hoverD ? 0.12 : 0.06)}
        polygonCapColor={(d: any) => (d === hoverD ? "steelblue" : colorScale(getVal(d)))}
        polygonSideColor={() => "rgba(0, 100, 0, 0.15)"}
        polygonStrokeColor={() => "#fff"}
        polygonLabel={(d: any) => `
          <b>${d.properties.ADMIN} (${d.properties.ISO_A2}):</b> <br />
          GDP: <i>${d.properties.GDP_MD_EST}</i> M$<br/>
          ${at} M$<br/>
          Population: <i>${d.properties.POP_EST}</i>
        `}
        onPolygonHover={setHoverD}
        polygonsTransitionDuration={300}
      />
    </div>
  );
};

export default World;
