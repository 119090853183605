import { Col, Row, Table, Typography } from "antd";
import dateFormat from "dateformat";
import { ColumnsType } from "antd/es/table";
import { FullStudy } from "../../../data/interfaces/study/Study";
import DotLoader from "../../../assets/components/other/DotLoader";
import { getValueByKeywordAndEnumKey } from "../../../assets/js/utils";

interface DetailsProps {
  study: FullStudy | null;
  loading: boolean;
}

interface StudyHighlights {
  key: string;
  title: string;
  content: any;
}

const { Paragraph, Text } = Typography;

const Details: React.FC<DetailsProps> = ({ study, loading }) => {
  // const [state, setState] = useState<DetailsState>({
  const rowsDetails = [
    {
      key: "sponsor",
      title: "Lead sponsor",
      content:
        // study && study.study_type ? getValueByKeywordAndEnumKey("type", study.study_type.toString()) : "Not available",
        study?.sponsors?.filter((s: any) => {
          return s?.is_lead;
        })[0]?.agency ?? "Not available",
    },
    {
      key: "phase",
      title: "Phase",
      content: study && study.phase ? getValueByKeywordAndEnumKey("phase", study.phase.toString()) : "Not available",
    },
    {
      key: "status",
      title: "Recruitment status",
      content:
        study && study.overall_status
          ? getValueByKeywordAndEnumKey("status", study.overall_status.toString())
          : "Not available",
    },
  ];
  const rowsDates = [
    {
      key: "posted",
      title: "First posted",
      content: dateFormat(
        study?.dates.find((d: any) => {
          return d?.name === "SFP";
        })?.date,
        "mmm d, yyyy"
      ),
    },
    {
      key: "start",
      title: "Start date (first patient in)",
      content: dateFormat(
        study?.dates.find((d: any) => {
          return d?.name === "SD";
        })?.date,
        "mmm d, yyyy"
      ),
    },
    {
      key: "end",
      title: "End date (last patient out)",
      content: dateFormat(
        study?.dates.find((d: any) => {
          return d?.name === "PCD";
        })?.date,
        "mmm d, yyyy"
      ),
    },
  ];
  const rowsDesign = [
    {
      key: "area",
      title: "Therapeutic area(s)",
      content:
        study?.therapeutic_areas
          ?.map((ta) => {
            return ta.heading;
          })
          .join(", ") ?? "Not available",
    },
    {
      key: "conditions",
      title: "Conditions",
      content: study?.conditions.join(", ") ?? "Not available",
    },
    {
      key: "interventions",
      title: "Interventions",
      content:
        study?.interventions
          ?.map((i) => {
            return i.name;
          })
          .join(", ") ?? "Not available",
    },
  ];
  const rowsCenterRight = [
    {
      key: "sponsor",
      title: "Lead sponsor",
      content:
        study?.sponsors?.filter((s: any) => {
          return s?.is_lead;
        })[0]?.agency ?? "Not available",
    },
    {
      key: "collaborators",
      title: "Collaborators",
      content:
        study?.sponsors
          ?.filter((s: any) => {
            return !s?.is_lead;
          })
          .map((s) => s.agency)
          .join(", ") ??
        "Not available" ??
        "Not available",
    },
    {
      key: "party",
      title: "Responsible party",
      content: study?.responsible_party?.affiliation ?? "Not available",
    },
  ];

  const columnsDetails: ColumnsType<StudyHighlights> = [
    {
      key: "cols-left",
      title: "Details",
      className: "description-header",
      children: [
        {
          key: "title",
          dataIndex: "title",
          className: "study-description-col header-0",
          width: 150,
          align: "right",
          render: (title: string) => <Text strong>{title}</Text>,
        },
        {
          key: "content",
          dataIndex: "content",
          className: "header-0",
          render: (content: string) =>
            loading ? (
              <DotLoader />
            ) : (
              <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: "more" }}>{content.toString()}</Paragraph>
            ),
        },
      ],
    },
  ];

  const columnsCenterRight: ColumnsType<StudyHighlights> = [
    {
      key: "cols-center-right",
      title: "Parties",
      className: "description-header",
      children: [
        {
          key: "title-center",
          dataIndex: "title",
          className: "study-description-col header-0",
          width: 150,
          align: "right",
          render: (title: string) => <Text strong>{title}</Text>,
        },
        {
          key: "content-center",
          dataIndex: "content",
          className: "header-0",
          render: (content: string) =>
            loading ? (
              <DotLoader />
            ) : (
              <Paragraph key="content-center-text" ellipsis={{ rows: 1, expandable: true, symbol: "more" }}>
                {content.toString()}
              </Paragraph>
            ),
        },
      ],
    },
  ];

  const columnsDates: ColumnsType<StudyHighlights> = [
    {
      key: "cols-right",
      title: "Dates",
      className: "description-header",
      children: [
        {
          key: "title-right",
          dataIndex: "title",
          className: "study-description-col header-0",
          width: 180,
          align: "right",
          render: (title: string) => <Text strong>{title}</Text>,
        },
        {
          key: "content-right",
          dataIndex: "content",
          className: "header-0",
          render: (content: string) =>
            loading ? (
              <DotLoader />
            ) : (
              <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: "more" }}>{content.toString()}</Paragraph>
            ),
        },
      ],
    },
  ];

  const columnsDesign: ColumnsType<StudyHighlights> = [
    {
      key: "cols-center",
      title: "Design",
      className: "description-header",
      children: [
        {
          key: "title-center",
          dataIndex: "title",
          className: "study-description-col header-0",
          width: 150,
          align: "right",
          render: (title: string) => <Text strong>{title}</Text>,
        },
        {
          key: "content-center",
          dataIndex: "content",
          className: "header-0",
          render: (content: string) =>
            loading ? (
              <DotLoader />
            ) : (
              <Paragraph
                key="content-center-text"
                // ellipsis={{ rows: 1, expandable: true, symbol: "more", onExpand: () => {}, onEllipsis: () => {} }}
                ellipsis={{ rows: 1, expandable: true, symbol: "more", tooltip: content.toString() }}
              >
                {content.toString()}
              </Paragraph>
            ),
        },
      ],
    },
  ];

  return (
    <Row key="details-row" gutter={16}>
      <Col key="details-col" span={6}>
        <Table
          key="details-table"
          className="description-table"
          dataSource={rowsDetails}
          columns={columnsDetails}
          pagination={false}
          footer={() => ""}
        />
      </Col>
      <Col key="dates-col" span={6}>
        <Table
          key="dates-table"
          className="description-table"
          dataSource={rowsDates}
          columns={columnsDates}
          pagination={false}
          footer={() => ""}
        />
      </Col>
      <Col key="center-left-highlights-col" span={12}>
        <Table
          key="study-center-left-table"
          className="description-table"
          dataSource={rowsDesign}
          columns={columnsDesign}
          pagination={false}
          footer={() => ""}
        />
      </Col>
      {/* <Col key="center-right-highlights-col" span={6}>
        <Table
          key="study-center-right-table"
          className="description-table"
          dataSource={rowsCenterRight}
          columns={columnsCenterRight}
          pagination={false}
          footer={() => ""}
        />
      </Col> */}
    </Row>
  );
};

export default Details;
