import { Button, Modal, Tabs } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import StatusChart from "../charts/StatusChart";
import PhaseChart from "../charts/PhaseChart";
import SponsorsChart from "../charts/SponsorsChart";
import InterventionsChart from "../charts/InterventionsChart";

interface DashboardProps {
  isVisible: boolean;
  onClose: () => void;
}

const Dashboard: React.FC<DashboardProps> = ({ isVisible, onClose }) => {
  const { TabPane } = Tabs;
  return (
    <Modal
      style={{ top: 50 }}
      width={1000}
      title="Dashboard"
      closeIcon={<CloseCircleOutlined />}
      visible={isVisible}
      bodyStyle={{
        backgroundColor: "lightgray",
        boxShadow: "inset 0 -3px 5px 0px rgb(0 0 0 / 20%), inset 0 3px 5px 2px rgb(0 0 0 / 20%)",
      }}
      footer={[
        <Button key="btn-ok" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      <Tabs type="card">
        <TabPane tab="Sponsors" key="1">
          <SponsorsChart />
        </TabPane>
        <TabPane tab="Phase" key="2">
          <PhaseChart />
        </TabPane>
        <TabPane tab="Recruitment" key="3">
          <StatusChart />
        </TabPane>
        <TabPane tab="Interventions" key="4">
          <InterventionsChart />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default Dashboard;
