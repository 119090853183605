import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import {
  AlignLeftOutlined,
  BarChartOutlined,
  BlockOutlined,
  DownloadOutlined,
  FilterOutlined,
  RollbackOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import FacetedSearch from "./modules/FacetedSearch";
import SearchTags from "./modules/SearchTags";
import Dashboard from "./modules/Dashboard";
import Timeline from "./charts/Timeline";
import Comparison from "./modules/Comparison";
import Break from "../../../assets/components/other/Break";
import { getNumberWithCommas } from "../../../assets/js/utils";

const { Text, Title } = Typography;

interface ButtonPanelProps {
  total: number | undefined;
  updateData: () => void;
  data: any;
}

const ButtonPanel: React.FC<ButtonPanelProps> = ({ total, updateData, data }) => {
  const navigate = useNavigate();
  const [areSearchTagsVisible, toggleSearchTagsVisibility] = useState(true);
  const [isFacetedSearchVisible, setFacetedSearchVisible] = useState(false);
  const onFacetedSearchClose = () => {
    setFacetedSearchVisible(false);
  };
  const [isDashboardVisible, setDashboardVisible] = useState(false);
  const onDashboardClose = () => {
    setDashboardVisible(false);
  };
  const [isTimelineVisible, setTimelineVisible] = useState(false);
  const onTimelineClose = () => {
    setTimelineVisible(false);
  };
  const [isComparisonVisible, setComparisonVisible] = useState(false);
  const onComparisonClose = () => {
    setComparisonVisible(false);
  };
  const [isDownloadVisible, setDownloadVisible] = useState(false);
  const onDownloadClose = () => {
    setDownloadVisible(false);
  };

  return (
    <>
      <Row className="mg-btm-16" key="btn-panel" gutter={16}>
        <Col span={24}>
          <Card bordered={false}>
            <Row>
              <Col key="title" span={12}>
                <Break key="br-1" height={4} />
                <Space>
                  <Title className="mg-btm-0" level={5} key="total-text">
                    {" "}
                    Total:{" "}
                  </Title>
                  {total ? <Text key="total-number">{getNumberWithCommas(total)}</Text> : <Text>Unknown</Text>}
                </Space>
              </Col>
              <Col key="buttons" span={12}>
                <Row justify="end">
                  <Space>
                    <Tooltip title="Go back to Advanced Search page">
                      <Button
                        key="btn-home"
                        icon={<RollbackOutlined />}
                        type="primary"
                        onClick={() => {
                          navigate("/");
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={areSearchTagsVisible ? "Hide filter tags" : "View filter tags"}>
                      <Button
                        key="btn-params"
                        icon={<TagsOutlined />}
                        onClick={() => {
                          toggleSearchTagsVisibility(!areSearchTagsVisible);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Edit filters">
                      <Button
                        key="btn-facets"
                        icon={<FilterOutlined />}
                        onClick={() => {
                          setFacetedSearchVisible(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="View dashboard">
                      <Button
                        key="btn-dashboard"
                        icon={<BarChartOutlined />}
                        onClick={() => {
                          setDashboardVisible(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="View timeline">
                      <Button
                        key="btn-timeline"
                        icon={<AlignLeftOutlined />}
                        onClick={() => {
                          setTimelineVisible(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Compare studies (under development)">
                      <Button
                        key="btn-compare"
                        disabled
                        icon={<BlockOutlined />}
                        onClick={() => {
                          setComparisonVisible(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Download studies (under development)">
                      <Button
                        key="btn-download"
                        disabled
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          setDownloadVisible(true);
                        }}
                      />
                    </Tooltip>
                  </Space>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <FacetedSearch
          key="faceted-search"
          isVisible={isFacetedSearchVisible}
          updateData={updateData}
          onClose={onFacetedSearchClose}
        />
      </Row>
      <SearchTags areTagsVisible={areSearchTagsVisible} updateData={updateData} />
      <Dashboard isVisible={isDashboardVisible} onClose={onDashboardClose} />
      <Timeline isVisible={isTimelineVisible} updateData={updateData} onClose={onTimelineClose} data={data} />
      <Comparison isVisible={isComparisonVisible} onClose={onComparisonClose} data={[]} />
    </>
  );
};

export default ButtonPanel;
