import { useContext, useEffect } from "react";
import { Card, Typography } from "antd";
import AInput from "../../../../assets/components/inputs/AInput";
import AInputWithPreselect from "../../../../assets/components/inputs/AInputWithPreselect";
import ASelect from "../../../../assets/components/inputs/ASelect";
import ASelectWithInputSearch from "../../../../assets/components/inputs/ASelectWithInputSearch";
import Break from "../../../../assets/components/other/Break";
import SearchContext from "../../../../data/api/SearchContext";

const { Title } = Typography;

const ConditionSearch: React.FC = () => {
  const { getSearchParams } = useContext(SearchContext);
  const searchParams = getSearchParams();
  const searchString = JSON.stringify(searchParams);
  let defaultValue;

  useEffect(() => {
    if (searchParams.target || searchParams.gene) {
      if (searchParams.target) {
        defaultValue = {
          key: "target1",
          input: searchParams.target,
        };
      } else if (searchParams.gene) {
        defaultValue = {
          key: "target2",
          input: searchParams.gene,
        };
      }
    }
  }, [searchString]);
  return (
    <Card key="study-search" bordered={false}>
      <Title level={5}>Condition / Treatment Details</Title>
      <Break height={8} />
      <ASelectWithInputSearch keyword="condition" />
      <ASelect keyword="area" select />
      <ASelect keyword="severity" multiple />
      <AInput keyword="intervention" />
      {/* <ASelectWithInputSearch keyword="drug" /> */}
      <ASelect keyword="roa" select multiple />
      <AInput keyword="target" />
      {/* <AInputWithPreselect keyword="target" defaultValue={defaultValue} /> */}
    </Card>
  );
};

export default ConditionSearch;
