import { Button, Card, Form, Input, Select, Space, Typography } from "antd";
import { InfoCircleOutlined, DownCircleTwoTone } from "@ant-design/icons";
import AInput from "../../../../assets/components/inputs/AInput";
import Break from "../../../../assets/components/other/Break";
import ASelect from "../../../../assets/components/inputs/ASelect";

const { Option } = Select;
const { Title } = Typography;

const BasicSearch: React.FC = () => {
  const onFinish = (values: Record<string, string>) => {
    console.log(values);
  };
  const [form] = Form.useForm();
  return (
    <Card key="basic-search" bordered={false}>
      <Form
        key="basic-search-card"
        form={form}
        name="search-form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Title level={5}>Search Clinical Studies</Title>
        <Break height={8} />
        <Form.Item
          label="Condition or Disease"
          tooltip={{
            title: "Tooltip with customize icon",
            icon: <InfoCircleOutlined />,
          }}
          name="condition"
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <ASelect keyword="status" />
        <Form.Item
          label="Country"
          tooltip={{
            title: "Tooltip with customize icon",
            icon: <InfoCircleOutlined />,
          }}
          name="country"
        >
          <Select suffixIcon={<DownCircleTwoTone />} showArrow={false}>
            <Option value="Daily">Daily</Option>
            <Option value="Mon">Every Monday</Option>
            <Option value="Tue">Every Tuesday</Option>
            <Option value="Wed">Every Wednesday</Option>
            <Option value="Thu">Every Thursday</Option>
            <Option value="Fri">Every Friday</Option>
            <Option value="Sat">Every Saturday</Option>
            <Option value="Sun">Every Sunday</Option>
            <Option value="Weekdays">On Weekdays</Option>
            <Option value="Weekends">On Weekends</Option>
          </Select>
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Space>
      </Form>
    </Card>
  );
};

export default BasicSearch;
