import React, { useState } from "react";
import { SearchParams } from "../interfaces/SearchParams";

// interface SearchContextValue {
//   searchParams: SearchParams;
//   setSearchParams: (params: SearchParams) => void;
// }

// const SearchContext = React.createContext<SearchContextValue>({
//   searchParams: {},
//   setSearchParams: () => {},
// });

interface SearchContextValue {
  setSearchParams: (params: SearchParams) => void;
  getSearchParams: () => SearchParams;
  clearSearchParams: () => void;
}

const SearchContext = React.createContext<SearchContextValue>({
  setSearchParams: () => {},
  getSearchParams: () => {
    return {};
  },
  clearSearchParams: () => {},
});

interface SearchContextProviderProps {
  children?: React.ReactNode;
}

export const SearchContextProvider = ({ children }: SearchContextProviderProps): JSX.Element => {
  const [params, setParams] = useState<SearchParams>({});

  const setSearchParams = (params: SearchParams) => {
    sessionStorage.setItem("params", JSON.stringify(params));
  };

  const getSearchParams = () => {
    let params = sessionStorage.getItem("params");
    if (params) {
      return JSON.parse(params);
    }
    return {};
  };

  const clearSearchParams = () => {
    sessionStorage.setItem("params", JSON.stringify({}));
  };

  const contextValue = {
    setSearchParams,
    getSearchParams,
    clearSearchParams,
  };

  return (
    // <SearchContext.Provider value={{ searchParams: params, setSearchParams: setParams }}>
    <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>
  );
};

export default SearchContext;
