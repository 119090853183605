import { Form, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getGlossaryTermByKeyword, getEnumOptionsByKeyword } from "../../js/utils";

interface AInputProps {
  keyword: string;
  select?: boolean;
  multiple?: boolean;
  disabled?: boolean;
}

const { Option } = Select;

const ASelect: React.FC<AInputProps> = ({ keyword, select, multiple, disabled }) => {
  let term = getGlossaryTermByKeyword(keyword);
  if (!term) {
    term = {
      keyword: "undefined",
      term: "Undefined",
      definition: "Undefined",
      source: "Undefined",
      url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
    };
  }

  let enumOptions = getEnumOptionsByKeyword(keyword)?.options;
  if (!enumOptions) {
    enumOptions = { NA: "undefined" };
  }

  let options = [];

  for (const [key, value] of Object.entries(enumOptions)) {
    options.push(
      <Option key={key} value={key}>
        {value}
      </Option>
    );
  }

  if (options.length === 0) {
    options.push(
      <Option key="NA" value="NA">
        Undefined
      </Option>
    );
  }

  return (
    <Form.Item
      label={term.term}
      tooltip={{
        title: term.definition,
        icon: <InfoCircleOutlined />,
      }}
      name={term.keyword}
      key={term.keyword}
    >
      <Select
        disabled={disabled ? true : false}
        mode={multiple ? "multiple" : undefined}
        showSearch={select ? true : false}
        optionFilterProp={select ? "children" : ""}
        allowClear
        placeholder={`Select ${term.keyword === "roa" ? "route of administration" : term.keyword}`}
        filterOption={(input, option) => {
          let match = false;
          if (
            option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) ||
            option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) === 0
          ) {
            match = option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return match;
        }}
      >
        {options}
      </Select>
    </Form.Item>
  );
};

export default ASelect;
