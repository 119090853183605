import { Form, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getGlossaryTermByKeyword } from "../../js/utils";

interface AInputProps {
  keyword: string;
  disabled?: boolean;
}

const AInput: React.FC<AInputProps> = ({ keyword, disabled }) => {
  let term = getGlossaryTermByKeyword(keyword);
  if (!term) {
    term = {
      keyword: "undefined",
      term: "Undefined",
      definition: "Undefined",
      source: "Undefined",
      url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
    };
  }

  return (
    <Form.Item
      label={term.term}
      tooltip={{
        title: term.definition,
        icon: <InfoCircleOutlined />,
      }}
      name={term.keyword}
      key={term.keyword}
    >
      <Input
        disabled={disabled ? true : false}
        allowClear
        placeholder={`Enter ${term.keyword === "nct" ? term.keyword.toUpperCase() + " number" : term.keyword}`}
      />
    </Form.Item>
  );
};

export default AInput;
