export interface Drug {
  primary_id: string;
  names?: string[] | null[];
}

export const drugs: Drug[] = [
  {
    primary_id: "D00AAN",
    names: ["8-O-(4-Chlorobenzenesulfonyl)Manzamine F", "CHEMBL400717"],
  },
  {
    primary_id: "D00AAU",
    names: ["3-[1-Ethyl-2-(3-Hydroxyphenyl)Butyl]phenol"],
  },
  {
    primary_id: "D00ABE",
    names: ["ALD-301"],
  },
  {
    primary_id: "D00ABO",
    names: ["KW-2449"],
  },
  {
    primary_id: "D00ABW",
    names: ["Opterone"],
  },
  {
    primary_id: "D00ACC",
    names: ["ND1251"],
  },
  {
    primary_id: "D00ACH",
    names: ["HMR-4004"],
  },
  {
    primary_id: "D00ACL",
    names: ["PMID28092474-Compound-33d"],
  },
  {
    primary_id: "D00ACP",
    names: ["Anti-HER-2 Mab-FcgR2- FcgR4 Fusion Proteins"],
  },
  {
    primary_id: "D00ACX",
    names: [
      "2-Chloro-5-Nitro-N-Phenylbenzamide",
      "GW9662",
      "22978-25-2",
      "GW 9662",
      "2-Chloro-5-Nitrobenzanilide",
      "GW-9662",
      "MLS001056751",
      "CHEBI:79993",
      "2-Chloro-5-Nitro-N-Phenyl-Benzamide",
      "2-Chloro-5-Nitro-N-4-Phenylbenzamide",
      "Benzamide, 2-Chloro-5-Nitro-N-Phenyl-",
      "SMR000326735",
      "(2-Chloro-5-Nitrophenyl)-N-Benzamide",
      "SR-01000075999",
      "Tocris-1508",
      "Spectrum5_001989",
      "Lopac-M-6191",
      "AC1LD8S0",
      "DSSTox_RID_79570",
      "DSSTox_CID_20723",
      "DSSTox_GSID_40723",
      "Lopac0_000798",
      "KBioGR_000361",
      "KBioSS_000361",
      "BSPBio_001021",
      "SCHEMBL420231",
      "CHEMBL375270",
      "GTPL3442",
      "Cid_644213",
    ],
  },
  {
    primary_id: "D00ADV",
    names: ["THF Carbapenems", "CL-188624", "CL-190294", "CL-191121", "OCA-983", "THF Carbapenems, Wyeth-Ayerst"],
  },
  {
    primary_id: "D00ADY",
    names: ["[3H]BSP", "[3H]-BSP"],
  },
  {
    primary_id: "D00AEQ",
    names: ["Hydroxyprogesterone", "Delalutin (TN)", "Proluton Depot (TN)"],
  },
  {
    primary_id: "D00AES",
    names: ["Dicopp"],
  },
  {
    primary_id: "D00AEV",
    names: ["Oxothiazolidine Carboxylate"],
  },
  {
    primary_id: "D00AEX",
    names: ["2-Hexyloxy-5-Imidazol-1-Yl-Pyridine", "CHEMBL320105", "SCHEMBL14129948", "BDBM50138236"],
  },
  {
    primary_id: "D00AHD",
    names: [
      "Anthracen-10-Yl(10H-Phenothiazin-10-Yl)Methanone",
      "CHEMBL392577",
      "BDBM50219206",
      "10-(9-Anthrylcarbonyl)Phenothiazine",
      "Anthracen-9-Yl (10H-Phenothiazine-10yl) Methanone, 5",
    ],
  },
  {
    primary_id: "D00AHT",
    names: ["PRAME Antigen-Specific Cancer Immunotherapeutic"],
  },
  {
    primary_id: "D00AHV",
    names: ["SKL-PD"],
  },
  {
    primary_id: "D00AII",
    names: ["(E)-4-(3,5-Dimethoxystyryl)Phenol"],
  },
  {
    primary_id: "D00AIO",
    names: ["Pyridoindole Derivative 3"],
  },
  {
    primary_id: "D00AIS",
    names: ["AP-11014"],
  },
  {
    primary_id: "D00AIZ",
    names: ["SND-159"],
  },
  {
    primary_id: "D00AJB",
    names: ["VOACANGINE"],
  },
  {
    primary_id: "D00AJF",
    names: ["ISIS 11219"],
  },
  {
    primary_id: "D00AJI",
    names: ["PF-4480682"],
  },
  {
    primary_id: "D00AJS",
    names: ["AIK11"],
  },
  {
    primary_id: "D00AKL",
    names: ["Immunose FLU"],
  },
  {
    primary_id: "D00AKQ",
    names: ["O-Desulfated Heparin"],
  },
  {
    primary_id: "D00AKR",
    names: ["AMG 479"],
  },
  {
    primary_id: "D00AKV",
    names: ["F-351"],
  },
  {
    primary_id: "D00AKY",
    names: ["SK&F-S-106203"],
  },
  {
    primary_id: "D00ALE",
    names: ["H1N1 Pandemic Influenza Vaccine"],
  },
  {
    primary_id: "D00ALJ",
    names: ["Placental Expanded Stem Cell Therapy"],
  },
  {
    primary_id: "D00ALX",
    names: ["PMID26004420-Compound-US20140309213B"],
  },
  {
    primary_id: "D00AMQ",
    names: [
      "Ethanol",
      "Aethanol",
      "Aethylalkohol",
      "Alcohol",
      "Alcohols",
      "Algrain",
      "Alkohol",
      "Anhydrol",
      "EOH",
      "EOX",
      "EtOH",
      "Etanol",
      "Etanolo",
      "Ethicap",
      "Ethylalcohol",
      "Ethylol",
      "Hinetoless",
      "Hydroxyethane",
      "Jaysol",
      "Methylcarbinol",
      "QMHAIh",
      "Sekundasprit",
      "Spirit",
      "Spirt",
      "Synasol",
      "Tecsol",
      "Thanol",
      "Absolute Alcohol",
      "Absolute Ethanol",
      "Aethanol [German]",
      "Alcare Hand Degermer",
      "Alcohol [USP]",
      "Alcohol Anhydrous",
      "Alcohol Dehydrated",
      "Alcohol Etilico",
      "Alcool Ethylique",
      "Alcool Etilico",
      "Alkohol [German]",
      "Alkoholu Etylowego",
      "Anhydrous Alcohol",
      "Anhydrous Ethanol",
      "Cologne Spirit",
      "Colognespirits",
      "Dehydrated Alcohol",
      "Dehydrated Ethanol",
      "Denatured Alcohol",
      "Denatured Ethanol",
      "Desinfektol EL",
      "Distilled Spirits",
      "Etanolo [Italian]",
      "Ethanol Absolute",
      "Ethanol Absolute Bp",
      "Ethanol Anhydrous",
      "Ethanol Extra Pure",
      "Ethanol Vapor",
      "Ethanol [JAN]",
      "Ethanol Solution",
      "Ethanolum Anhydricum",
      "Ethyl Alc",
      "Ethyl Alcohol",
      "Ethyl Alcohol Usp",
      "Ethyl Hydrate",
      "Ethyl Hydroxide",
      "Ethylalcohol [Dutch]",
      "Ethyloxy Group",
      "Etylowy Alkohol",
      "Fermentation Alcohol",
      "Grain Alcohol",
      "HYDROXYETHYL GROUP",
      "Infinity Pure",
      "Jaysol S",
      "Methylated Spirit",
      "Molasses Alcohol",
      "Oxydimethylene Group",
      "Potato Alcohol",
      "Reagent Alcohol",
      "SY Fresh M",
      "Spirits Of Wine",
      "Spiritus Vini",
      "Tecsol C",
      "AHD 2000",
      "Anhydrol PM 4085",
      "CDA 19",
      "E7148_ALDRICH",
      "Esumiru WK 88",
      "Ethanol 200 Proof",
      "IMS 99",
      "LTBB002977",
      "SDA 3A",
      "Alcohol (USP)",
      "Alcohol, Absolute",
      "Alcohol, Grain",
      "Alcohol, Anhydrous",
      "Alcohol, Dehydrated",
      "Alcohol, Diluted",
      "Alcohol, Ethyl",
      "Alcohols, C30",
      "CDA 19-200",
      "Ethanol (9CI)",
      "Ethanol, Undenatured",
      "Ru-Tuss Expectorant",
      "SDA 40-2",
      "SDM No. 37",
      "ALCOHOL 5% IN D5-W",
      "Alcohols, C1-3",
      "Alcohols, C6-9",
      "(C6-C9)Alkyl Alcohol",
      "1-Hydroxyethane",
      "100C.NPA",
    ],
  },
  {
    primary_id: "D00ANG",
    names: ["Erythropoietin-Transfected Autologous Cell Therapy"],
  },
  {
    primary_id: "D00ANJ",
    names: ["1-Dodecyl-1H-Indole-2,3-Dione", "1-Dodecylindoline-2,3-Dione"],
  },
  {
    primary_id: "D00AOJ",
    names: [
      "Docosanol",
      "Abreva",
      "Debat",
      "Doconsanol",
      "Erazaban",
      "Healip",
      "Herepair",
      "Lidakol",
      "Lidavol",
      "Tadenan",
      "Behenic Alcohol",
      "Behenyl Alcohol",
      "Docosanol [USAN]",
      "Docosyl Alcohol",
      "IK 2",
      "Lanette 22",
      "NAA 422",
      "Stenol 1822",
      "Stenol 1822A",
      "Abreva (TN)",
      "Docosanol (USAN)",
      "Docosanol (VAN)",
      "Erazaban (TN)",
      "IK.2",
      "N-Docosanol",
      "Nacol 22-97",
      "V-1326;BEHENYL ALCOHOL, 98%",
      "Docosan-1-Ol",
      "Behenyl Alcohol, Abreva, 1-Docosanol, Docosanol",
      "Behenyl Alcohol, AI3-36489, Behenic Alcohol, 1-Docosanol, Docosyl Alcohol, IK-2, Tadenan",
      "1-DOCOSANOL",
    ],
  },
  {
    primary_id: "D00AON",
    names: ["Sulfamic Acid 2-Nonyl-4-Oxo-4H-Chromen-6-Yl Ester", "CHEMBL137692", "ZINC36338395"],
  },
  {
    primary_id: "D00APD",
    names: [
      "3-(2-Dimethylamino-Ethyl)-1H-Indol-6-Ol",
      "BRN 0160817",
      "6-Hydroxy-N,N-Dimethyltryptamine",
      "1476-33-1",
      "INDOL-6-OL, 3-(2-(DIMETHYLAMINO)ETHYL)-",
      "CHEMBL382750",
      "3-(2-Dimethylaminoethyl)-1H-Indol-6-Ol",
      "1H-Indol-6-Ol, 3-[2-(Dimethylamino)Ethyl]-",
      "5-22-12-00067 (Beilstein Handbook Reference)",
      "6-Hydroxy-N-Dimethyltryptamine",
      "AC1L257Q",
      "DTXSID70163752",
      "BDBM50171259",
      "AKOS023092717",
      "LS-83678",
    ],
  },
  {
    primary_id: "D00AQR",
    names: ["WO2013026806C72", "EHT 5372", "Compound 8c [PMID 25264830]", "GTPL8166", "BDBM50434338"],
  },
  {
    primary_id: "D00AQV",
    names: ["COTI-4A"],
  },
  {
    primary_id: "D00AQW",
    names: ["PMID27967267-Compound-36"],
  },
  {
    primary_id: "D00AQY",
    names: ["IDDB-41331"],
  },
  {
    primary_id: "D00ARM",
    names: [
      "Brominide Tartrate",
      "BRIMONIDINE TARTRATE",
      "70359-46-5",
      "Brimonidine Tartarate",
      "Alphagan",
      "Brimonidine D-Tartrate",
      "UNII-4S9CL2DY2H",
      "Brimonidinne Tartrate",
      "C11H10BrN5.C4H6O6",
      "Brimonidine L-Tartrate",
      "Brimonidine (Tartrate)",
      "Brimonidine D-Tartarate",
      "79570-19-7",
      "4S9CL2DY2H",
      "5-Bromo-N-(4,5-Dihydro-1H-Imidazol-2-Yl)Quinoxalin-6-Amine (2R,3R)-2,3-Dihydroxysuccinate",
      "UK-14304-18",
      "Qoliana",
      "Brimonidine Purite",
      "Brimonidine Tartrate [USAN]",
    ],
  },
  {
    primary_id: "D00ARN",
    names: [
      "Tyloxapol",
      "Alevaire",
      "Enuclene",
      "Macrocyclon",
      "Oxirane",
      "Superinone",
      "Superiuone",
      "Tiloxapol",
      "Tyloxapolum",
      "Tyloxypal",
      "Component Of Alevaire",
      "Superiuone [French]",
      "Triton WR 1339",
      "P-Isooctylpolyoxyethylenephenol Formaldehyde Polymer",
      "Tiloxapol [INN-Spanish]",
      "Triton A-20",
      "Triton WR-1339",
      "Tyloxapolum [INN-Latin]",
      "Oxyethylated Tertiary Octyl-Phenol-Formaldehyde Polymer",
      "Triton W.R.1339",
      "Tyloxapol [USAN:BAN:INN]",
      "Ethylene Oxide-Formaldehyde-P-Octylphenol Copolymer",
      "P-(1,1,3,3-Tetramethylbutyl)Phenol Polymer With Ethylene Oxide And Formaldehyde",
      "Formaldehyde, Polymer With Oxirane And 4-(1,1,3,3-Tetra Methylbutyl)Phenol",
      "Formaldehyde, Polymer With Oxirane And 4-(1,1,3,3-Tetramethylbutyl)Phenol",
      "Phenol, 4-(1,1,3,3-Tetramethylbutyl)-, Polymer With Formaldehyde And Oxirane",
      "Phenol, P-(1,1,3,3-Tetramethylbutyl)-, Polymer With Ethylene Oxide And Formaldehyde",
      "4-(1,1,3,3-Tetramethylbutyl)Phenol, Polymer With Formaldehyde & Oxirane",
      "4-(1,1,3,3-Tetramethylbutyl)Phenol, Polymer With Formaldehyde Oxirane",
      "4-(1,1,3,3-Tetramethylbutyl)Phenol",
      "4-(2,4,4-Trimethylpentan-2-Yl)Phenol",
    ],
  },
  {
    primary_id: "D00ARS",
    names: ["MER-3001"],
  },
  {
    primary_id: "D00ARZ",
    names: ["Huntingtons Disease Therapy"],
  },
  {
    primary_id: "D00ASA",
    names: [
      "2-(3-Chloro-Phenyl)-Chromen-4-One",
      "CHEMBL56414",
      "2-(3-Chlorophenyl)Chromen-4-One",
      "1849-61-2",
      "4h-1-Benzopyran-4-One,2-(3-Chlorophenyl)-",
      "AC1LAD8B",
      "2-(3-CHLOROPHENYL)-4H-CHROMEN-4-ONE",
      "SCHEMBL6655676",
      "ZINC6583548",
      "MB10491",
      "2-(3'-Chlorophenyl)-4h-Chromen-4-One",
    ],
  },
  {
    primary_id: "D00ASH",
    names: ["3-Methoxydesaminokynurenine"],
  },
  {
    primary_id: "D00ASJ",
    names: ["Typherix"],
  },
  {
    primary_id: "D00ASL",
    names: ["N-Butyl 2beta-Hydroxyolean-12-En-28-Oate", "CHEMBL1077758"],
  },
  {
    primary_id: "D00ASX",
    names: ["PJ-015"],
  },
  {
    primary_id: "D00ASZ",
    names: [
      "2-(3''-Pyrrolylethyloxy)Adenosine",
      "CHEMBL222945",
      "2-(3'-Pyrrolylethyloxy)Adenosine",
      "BDBM50208806",
      "2-(3''''-Pyrrolylethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D00ATA",
    names: ["(Z)-3-((1H-Pyrrol-2-Yl)Methylene)Indolin-2-One"],
  },
  {
    primary_id: "D00AUC",
    names: ["(99M)-TC-CPI"],
  },
  {
    primary_id: "D00AUM",
    names: ["AQU-010"],
  },
  {
    primary_id: "D00AUX",
    names: ["MSDC-0602"],
  },
  {
    primary_id: "D00AUZ",
    names: ["Thiolated Chitosan Nanoparticle Theophylline"],
  },
  {
    primary_id: "D00AVG",
    names: ["GX-110E"],
  },
  {
    primary_id: "D00AVH",
    names: ["VEN100"],
  },
  {
    primary_id: "D00AVR",
    names: ["APLYSINOPSIN"],
  },
  {
    primary_id: "D00AVS",
    names: ["MR-20814"],
  },
  {
    primary_id: "D00AVT",
    names: ["PERILLALDEHYDE"],
  },
  {
    primary_id: "D00AWA",
    names: ["WIN-63759"],
  },
  {
    primary_id: "D00AWM",
    names: [
      "GW803430",
      "6-(4-Chlorophenyl)-3-(3-Methoxy-4-(2-(Pyrrolidin-1-Yl)Ethoxy)Phenyl)Thieno[3,2-D]pyrimidin-4(3H)-One",
      "515141-51-2",
      "GW 803430",
      "GW-3430",
      "UNII-4R0136W1PR",
      "GW-803430",
      "Thieno[3,2-D]pyrimidin-4(3H)-One, 6-(4-Chlorophenyl)-3-[3-Methoxy-4-[2-(1-Pyrrolidinyl)Ethoxy]phenyl]-",
      "CHEMBL214957",
      "GW-803,430",
      "4R0136W1PR",
      "GW3430",
      "6-(4-Chlorophenyl)-3-(3-Methoxy-4-(2-(Pyrrolidin-1-Yl)Ethoxy)Phenyl)Thieno(3,2-D)Pyrimidin-4(3H)-One",
      "Thieno(3,2-D)Pyrimidin-4(3H)-One,",
    ],
  },
  {
    primary_id: "D00AWT",
    names: [
      "Propericiazine",
      "Aolept",
      "Nemactil",
      "Neulactil",
      "Neuleptil",
      "PERICYAZINE",
      "Periciazina",
      "Periciazine",
      "Periciazinum",
      "Piperocyanomazine;Periciazine [INN]",
      "Bayer 1409",
      "IC 6002",
      "RP 8908",
      "WH 7508",
      "Neulactil (TN)",
      "Neuleptil (TN)",
      "Periciazina [INN-Spanish]",
      "Periciazinum [INN-Latin]",
      "Propericiazine (JAN)",
      "Propericiazine(Jan)",
      "SKF 20,716",
      "Cyano-3 ((Hydroxy-4 Piperidyl-1)-3 Propyl)-10 Phenothiazine",
      "Cyano-3 ((Hydroxy-4 Piperidyl-1)-3 Propyl)-10 Phenothiazine [French]",
      "10-(3-(4-Hydroxypiperidino)Propyl)Phenothiazine-2-Carbonitrile",
      "10-[3-(4-Hydroxypiperidin-1-Yl)Propyl]-10H-Phenothiazine-2-Carbonitrile",
      "10-[3-(4-Hydroxypiperidin-1-Yl)Propyl]phenothiazine-2-Carbonitrile",
      "2-Cyano-10-(3-(4-Hydroxy-1-Piperidyl)Propyl)Phenothiazine",
      "2-Cyano-10-(3-(4-Hydroxypiperidino)Propyl)Phenothiazine",
      "6909 RP",
    ],
  },
  {
    primary_id: "D00AXG",
    names: ["SMT-14224"],
  },
  {
    primary_id: "D00AXJ",
    names: [
      "Miconazole",
      "Dactarin",
      "MCZ",
      "Miconazol",
      "Miconazolo",
      "Miconazolum",
      "Micozole",
      "Minostate",
      "Monistat",
      "Vusion",
      "Zimycan",
      "Daktarin IV",
      "Miconasil Nitrate",
      "Miconazole Nitrate Salt",
      "Miconazolo [DCIT]",
      "Monistat IV",
      "MJR 1762",
      "Miconazole 3",
      "Miconazole 3 Combination Pack",
      "Miconazole 7 Combination Pack",
      "Monazole 7",
      "Monistat 1 Combination Pack",
      "Monistat 3 Vaginal Ovules",
      "Monistat 5 Tampon",
      "Monistat 7 Vaginal Suppositories",
      "R 18134",
      "Aflorix(Nitrate)",
      "Albistat(Nitrate)",
      "Andergin(Nitrate)",
      "Conofite(Nitrate)",
      "Daktarin (TN)",
      "Femizol (TN)",
      "Femizol-M",
      "Florid(Nitrate)",
      "Lotrimin AF(Nitrate)",
      "Micantin (Nitrate)",
      "Micatin (TN)",
      "Miconazex (TN)",
      "Miconazol [INN-Spanish]",
      "Miconazole-7",
      "Miconazolum [INN-Latin]",
      "Monista (Nitrate)",
      "Monistat (TN)",
      "Monistat 3 Dual-Pak",
      "Monistat 7 Dual-Pak",
      "Monistat Dual-PAK",
      "Monistat Iv (TN)",
      "Monistat-Derm",
      "Novo-Miconazole Vaginal Ovules",
      "Epi-Monistat(Nitrate)",
      "Gyno-Daktar(Nitrate)",
      "Monistat-Derm (TN)",
      "R-14,889",
      "Miconazole (JP15/USP/INN)",
      "Miconazole [USAN:BAN:INN:JAN]",
      "Imidazole, 1-(2-(2,4-Dichlorophenyl)-2-((2,4-Dichlorophenyl) Methoxy)Ethyl)-(9CI)",
      "Imidazole, 1-(2-(2,4-Dichlorophenyl)-2-((2,4-Dichlorophenyl)Methoxy)Ethyl)-(9CI)",
      "(+-)-1-(2,4-Dichloro-Beta-((2,4-Dichlorobenzyl)Oxy)Phenethyl)Imidazole",
      "1-(2,4-Dichloro-Beta-((2,4-Dichlorobenzyl)Oxy)Phenethyl)Imidazole",
      "1-(2,4-Dichloro-Beta-((2,4-Dichlorobenzyl)Oxy)Phenethyl) Imidazole",
      "1-[2,4-Dichloro-Beta-([2,4-Dichloro-Benzyl]oxy)Phenethyl]imidazole",
      "1-[2-(2,4-Dichlorophenyl)-2-[(2,4-Dichlorophenyl)Methoxy]ethyl]-1H-Imidazole",
      "1-[2-(2,4-Dichlorobenzyloxy)-2-(2,4-Dichlorophenyl)Ethyl]-1H-Imidazole",
      "1-[2-(2,4-Dichlorophenyl)-2-[(2,4-Dichlorophenyl)Methoxy]ethyl]imidazole",
      "1-[2-(2,4-Dichlorophenyl)-2-{[(2,4-Dichlorophenyl)Methyl]oxy}ethyl]-1H-Imidazole",
      "1-{2-[(2,4-Dichlorobenzyl)Oxy]-2-(2,4-Dichlorophenyl)Ethyl}-1H-Imidazole",
    ],
  },
  {
    primary_id: "D00AXT",
    names: ["MDC-922"],
  },
  {
    primary_id: "D00AXZ",
    names: ["CAP-22"],
  },
  {
    primary_id: "D00AYA",
    names: [
      "BMS-188797",
      "11,12,12a,12b-Dodecahydro-1H-7,11-Methanocyclodeca[3,4]benz[1,2-B]oxet-9-Yl Ester",
      "4-O-Deacetyl-4-O-(Methoxycarbonyl)Paclitaxel",
    ],
  },
  {
    primary_id: "D00AYW",
    names: [
      "HMS5552",
      "Dorzagliatin",
      "UNII-X59W6980E8",
      "X59W6980E8",
      "1191995-00-2",
      "Sinogliatin",
      "RO5305552",
      "Dorzagliatin [INN]",
      "Dorzagliatin [USAN:INN]",
      "SCHEMBL1079619",
      "HMUMWSORCUWQJO-QAPCUYQASA-N",
      "HMS-5552",
      "AKOS030527745",
      "RO-5305552",
      "(S)-2-(4-(2-Chlorophenoxy)-2-Oxo-2,5",
      "(S)-2-(4-(2-Chlorophenoxy)-2-Oxo-2,5-Dihydro-1H-Pyrrol-1-Yl)-N-(1-((R",
      "(S)-2-[4-(2-Chloro-Phenoxy)-2-Oxo-2,5-Dihydro-Pyrrol-1-Yl]-4-Methyl-Pentanoic Acid [1-((R)-2,3-Dihydroxy-Propyl)-1H-Pyrazol-3-Yl]-Amide",
      "(2S)-2-(4-(2-Chlorophenoxy)-2-Oxo-2,5-Dihydro-1H-Pyrrol-1-Yl)-N-(1",
    ],
  },
  {
    primary_id: "D00BAJ",
    names: ["KH-CB19"],
  },
  {
    primary_id: "D00BBB",
    names: ["MetPril", "Diabetes/Hypertension PolyCap Combination, MicroDose"],
  },
  {
    primary_id: "D00BCG",
    names: ["Goserelin", "AstraZeneca (TN)", "HS-2015", "ZD-9393", "Zoladex (TN)"],
  },
  {
    primary_id: "D00BCO",
    names: ["Ac-I[CV(Bta)QDWGAHRC]T"],
  },
  {
    primary_id: "D00BCP",
    names: [
      "Chlorpropamide",
      "Adiaben",
      "Asucrol",
      "Bioglumin",
      "Catanil",
      "Chlorodiabina",
      "Chloronase",
      "Chloropropamide",
      "Chlorporpamide",
      "Chlorpropamid",
      "Chlorpropamidum",
      "Clorpropamid",
      "Clorpropamida",
      "Clorpropamide",
      "Diabaril",
      "Diabechlor",
      "Diabenal",
      "Diabenese",
      "Diabeneza",
      "Diabetoral",
      "Diabexan",
      "Diabinese",
      "Dynalase",
      "Glisema",
      "Glucamide",
      "Insogen",
      "Insulase",
      "Meldian",
      "Melitase",
      "Mellinese",
      "Millinese",
      "Oradian",
      "Prodiaben",
      "Stabinol",
      "Apotex Brand Of Chlorpropamide",
      "Byk Gulden Brand Of Chlorpropamide",
      "CHLORPROPAMIDE USP",
      "Clorpropamide [DCIT]",
      "Clorpropamide [Italian];Dia Benese",
      "Diamel Ex",
      "Farmasierra Brand Of Chlorpropamide",
      "Pfizer Brand Of Chlorpropamide",
      "C 1290",
      "P 607",
      "Apo-Chlorpropamide",
      "Chlorpropamide Bp/ Usp",
      "Chlorpropamidum [INN-Latin]",
      "Clorpropamida [INN-Spanish]",
      "Diabet-Pages",
      "Diabinese (TN)",
      "Novo-Propamide",
      "U-3818",
      "U-9818",
      "Chlorpropamide [INN:BAN:JAN]",
      "Chlorpropamide (JP15/USP/INN)",
      "N3-Butyl-N1-P-Chlorobenzenesulfonylure A",
      "N3-Butyl-N1-P-Chlorobenzenesulfonylurea",
      "N-Propyl-N'-P-Chlorophenylsulfonylcarbamide",
      "N-Propyl-N'-P-Chlorphenylsulfonylcarbamide",
      "N-Propyl-N'-P-Chlorophenylsu Lfonylcarbamide",
      "N-(4-Chlorophenylsulfonyl)-N'-Propylurea",
      "N-(P-Chlorobenzenesulfonyl)-N'-Propylurea",
      "N-Propyl-N'-(P-Chlorobenzenesulfonyl)Urea",
      "1-((P-Chlorophenyl)Sulfonyl)-3-Propylurea",
      "1-(4-Chlorophenylsulfonyl)-3-Propylurea",
      "1-(4-Chlorophenyl)Sulfonyl-3-Propylurea",
      "1-(P-Chlorobenzenesulfonyl)-3-Propylurea",
      "1-(P-Chlorobenzensulfonyl)-3-Propylurea",
      "1-(P-Chlorophenylsulfonyl)-3-Propylurea",
      "1-(Para-Chlorophenylsulfonyl)-3-Propylurea",
      "1-Propyl-3-(P-Chlorobenzenesulfonyl)Urea",
      "1-[(4-Chlorobenzene)Sulfonyl]-3-Propylurea",
      "1-[p-Chlorobenzenesulfonyl]-3-Propylurea",
      "1-P-Chlorophenyl-3-(Propylsulfonyl)Urea",
      "4-Chloro-4-((Propylamino)Carbonyl)Benzenesulfonamide",
      "4-Chloro-N-((Propylamino)Carbonyl)Benzenesulfonamide",
      "4-Chloro-N-(Propylcarbamoyl)Benzenesulfonamide",
      "4-Chloro-N-[(Propylamino)Carbonyl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D00BCS",
    names: ["INT-0015/2008"],
  },
  {
    primary_id: "D00BCZ",
    names: ["XGP-610"],
  },
  {
    primary_id: "D00BDC",
    names: ["Morniflumate"],
  },
  {
    primary_id: "D00BEA",
    names: [
      "1,9-Dideoxyforskolin",
      "1,9-DIDEOXYFORSKOLIN",
      "UNII-OAW710HWIX",
      "64657-18-7",
      "OAW710HWIX",
      "CHEBI:50295",
      "(3R,4aS,5S,6S,6aS,10aS,10bR)-3-Ethenyl-6-Hydroxy-3,4a,7,7,10a-Pentamethyl-1-Oxododecahydro-1H-Benzo[f]chromen-5-Yl Acetate",
      "7beta-Acetoxy-8,13-Epoxy-6beta-Hydroxylabd-14-En-11-One",
      "(3R,4aS,5S,6S,6aS,10aS,10bR)-5-(Acetyloxy)-3-Ethenyldodecahydro-6-Hydroxy-3,4a,7,7,10a-Pentamethyl-1H-Naphtho(2,1-B)Pyran-1-One",
    ],
  },
  {
    primary_id: "D00BEJ",
    names: ["PMID27744724-Compound-19"],
  },
  {
    primary_id: "D00BEM",
    names: [
      "2-M-Tolyl-1H-[1,8]naphthyridin-4-One",
      "CHEMBL299979",
      "2-(3-Methylphenyl)-1,8-Naphthyridin-4(1h)-One",
      "NSC676185",
      "AC1L8OZI",
      "SCHEMBL7922307",
      "CTK8D2142",
      "ZINC5504118",
      "BDBM50059970",
      "NSC-676185",
      "NCI60_026912",
    ],
  },
  {
    primary_id: "D00BFF",
    names: ["Benzothiazine Derivative 1"],
  },
  {
    primary_id: "D00BFT",
    names: [
      "L-Azetidine-2-Carboxylate",
      "2133-34-8",
      "(S)-Azetidine-2-Carboxylic Acid",
      "L-Azetidine-2-Carboxylic Acid",
      "(S)-(-)-2-Azetidinecarboxylic Acid",
      "(2S)-Azetidine-2-Carboxylic Acid",
      "Azetidyl-2-Carboxylic Acid",
      "(S)-2-Azetidinecarboxylic Acid",
      "H-Aze-OH",
      "L-Azetidine 2-Carboxylic Acid",
      "2-Azetidinecarboxylic Acid, (S)-",
      "(S)-(-)-Azetidine-2-Carboxylic Acid",
      "2-Azetidinecarboxylic Acid, L-",
      "Azetidine-2-Carboxylic Acid, L-",
      "2-Azetidinecarboxylic Acid, (2S)-",
      "UNII-5GZ3E0L9ZU",
      "5GZ3E0L9ZU",
      "L-2-Azetidinecarboxylic Acid",
      "(L)-AZETIDINE-2-CARBOXYLIC ACID",
      "Azetidinecarboxylic A",
    ],
  },
  {
    primary_id: "D00BGN",
    names: [
      "2-(1H-Imidazol-4-Yl)Benzene-1,3-Diol",
      "1048983-14-7",
      "CHEMBL509549",
      "SCHEMBL1502239",
      "BDBM24664",
      "2-(1H-Imidazol-4-Yl)Benzene-1,3-Diol, 11",
    ],
  },
  {
    primary_id: "D00BHL",
    names: ["EC20"],
  },
  {
    primary_id: "D00BIE",
    names: ["Cyclo-[Asp6,Lys10]N/OFQ(1-13)NH2", "CHEMBL1162357"],
  },
  {
    primary_id: "D00BIY",
    names: ["CGP-48369", "CGS-48369"],
  },
  {
    primary_id: "D00BJV",
    names: ["BMS-986207"],
  },
  {
    primary_id: "D00BKV",
    names: ["SB 418790"],
  },
  {
    primary_id: "D00BLD",
    names: [
      "1,2,3,4,6-Penta-O-Galloyl-D-Glucopyranose",
      "D-Glucopyranose, Pentakis(3,4,5-Trihydroxybenzoate)",
      "40410-94-4",
      "1,2,3,4,6-Pentakis-O-(3,4,5-Trihydroxybenzoyl)-D-Glucopyranose",
      "AC1L4RL6",
      "SCHEMBL675693",
      "AC1Q62I0",
      "DTXSID00193426",
      "2,3,4,5,6-Pentagalloylglucose",
      "BDBM175525",
      "3-O-Digalloyl-1,2,6-Trigalloylglucose",
      "1,2,3,4,6-Penta-O-Galloyl--D-Glucopyranose",
      "US9120744, CDE-001 (Or 073)",
      "1-O,2-O,3-O,4-O,6-O-Pentagalloyl-D-Glucopyranose",
      "D-Glucose, 2,3,4,5,6-Pentakis(3,4,5-Trihydroxybenzoate)",
      "[(2R,3R,4S,5R)-3,4,5,6-Tetrakis[(3,4,5-Trihydroxybenzoyl)Oxy]oxan-2-Yl]methyl 3,4,5-",
    ],
  },
  {
    primary_id: "D00BLL",
    names: ["Fesoterodine Fumarate"],
  },
  {
    primary_id: "D00BLS",
    names: ["AZD0284"],
  },
  {
    primary_id: "D00BLY",
    names: ["4-(Isopropylthio)-2-Methoxybenzonitrile", "CHEMBL445429"],
  },
  {
    primary_id: "D00BMF",
    names: [
      "MK-4827",
      "1038915-60-4",
      "(S)-2-(4-(Piperidin-3-Yl)Phenyl)-2H-Indazole-7-Carboxamide",
      "UNII-HMC2H89N35",
      "HMC2H89N35",
      "CHEMBL1094636",
      "2-{4-[(3s)-Piperidin-3-Yl]phenyl}-2h-Indazole-7-Carboxamide",
      "MK4827",
      "MK 4827",
      "Niraparib [USAN:INN]",
      "2-{4-[(3S)-Piperidin-3-Yl]phenyl}indazole-7-Carboxamide",
      "2-[4-[(3S)-Piperidin-3-Yl]phenyl]indazole-7-Carboxamide",
      "MK 4827 (Base)",
      "Niraparib (USAN)",
      "Zejula (TN)",
      "MK-4827(Niraparib)",
      "SCHEMBL1421875",
      "GTPL8275",
      "CTK8B9123",
      "EX-A290",
      "DTXSID50146129",
      "MolPort-023-219-142",
      "ZINC43206370",
      "BDBM50316226",
    ],
  },
  {
    primary_id: "D00BMI",
    names: ["N-1,3,4-Thiadiazol-2-Ylsulfamide", "CHEMBL449527"],
  },
  {
    primary_id: "D00BMM",
    names: ["Dismutase Mimetics", "Dismutase Mimetics (Cancer/Oral Mucositis)"],
  },
  {
    primary_id: "D00BND",
    names: [
      "BF-1",
      "518980-66-0",
      "Piperidine, 4-(6-Ethoxy-1-Methoxy-9H-Thioxanthen-9-Ylidene)-1-Methyl-",
      "SCHEMBL4475418",
      "GTPL8424",
      "CTK1E4820",
      "DTXSID70437783",
      "SB18757",
      "L022232",
    ],
  },
  {
    primary_id: "D00BNE",
    names: ["(6s)-5,6,7,8-Tetrahydrofolate"],
  },
  {
    primary_id: "D00BOY",
    names: ["CP-18770"],
  },
  {
    primary_id: "D00BPA",
    names: ["Olomoucine"],
  },
  {
    primary_id: "D00BPE",
    names: ["AVE-1642"],
  },
  {
    primary_id: "D00BPH",
    names: ["COVA322"],
  },
  {
    primary_id: "D00BQR",
    names: ["PMID28870136-Compound-57"],
  },
  {
    primary_id: "D00BRD",
    names: [
      "4-Hydroxybenzaldehyde",
      "P-Hydroxybenzaldehyde",
      "123-08-0",
      "4-Formylphenol",
      "P-Formylphenol",
      "P-Oxybenzaldehyde",
      "Benzaldehyde, 4-Hydroxy-",
      "Parahydroxybenzaldehyde",
      "Benzaldehyde, P-Hydroxy-",
      "4-HYDROXY-BENZALDEHYDE",
      "USAF M-6",
      "4-Hydroxy Benzaldehyde",
      "NSC 2127",
      "P-Hydroxy-Benzaldehyde",
      "UNII-O1738X3Y38",
      "Para-Hydroxybenzaldehyde",
      "4-Hydroxybenzenecarbonal",
      "EINECS 204-599-1",
      "BRN 0471352",
      "CHEMBL14193",
      "AI3-15366",
      "PARA-HYDROXY BENZALDEHYDE",
      "CHEBI:17597",
      "RGHHSNMVTDWUBI-UHFFFAOYSA-N",
      "MFCD00006939",
      "O1738X3Y38",
      "4-Hydroxybenzaldehyde, 99%",
      "Benzaldehyde, 4-",
    ],
  },
  {
    primary_id: "D00BSR",
    names: ["JCAR017"],
  },
  {
    primary_id: "D00BSX",
    names: ["1,3,8-Trihydroxy-6-Methyl-10H-Anthracen-9-One"],
  },
  {
    primary_id: "D00BTW",
    names: ["UC-781"],
  },
  {
    primary_id: "D00BTZ",
    names: [
      "CT-003230",
      "CT-0011",
      "CT-007640",
      "CT-007650",
      "CT-007660",
      "CT-007670",
      "CT-007680",
      "CT-008650",
      "CT-0093",
      "CT-0107",
      "CT-0109",
      "Amyloid Beta 1-42 Inhibitors (Alzheimers/Mild Cognitive Impairment/Downs' Syndrome)",
      "Amyloid Beta 1-42 Inhibitors (Alzheimers/Mild Cognitive Impairment/Downs' Syndrome), Cognition",
    ],
  },
  {
    primary_id: "D00BUO",
    names: ["MM-141"],
  },
  {
    primary_id: "D00BUR",
    names: ["ICA-17043"],
  },
  {
    primary_id: "D00BVB",
    names: ["Men Quad TT"],
  },
  {
    primary_id: "D00BVF",
    names: ["IP-889"],
  },
  {
    primary_id: "D00BVJ",
    names: ["NHS-IL 12"],
  },
  {
    primary_id: "D00BVP",
    names: [
      "3-(Imidazolylmethyl)-4'-Methoxyflavone",
      "CHEMBL212473",
      "BDBM50191600",
      "3-(Imidazolylmethyl)-4''-Methoxyflavone",
    ],
  },
  {
    primary_id: "D00BVS",
    names: ["Tanezumab"],
  },
  {
    primary_id: "D00BWF",
    names: ["AGN-XX/YY"],
  },
  {
    primary_id: "D00BWZ",
    names: [
      "N-(Pyridin-3-Yl)Indoline-1-Carboxamide",
      "CHEMBL42327",
      "SCHEMBL9035888",
      "1-(3-Pyridylcarbamoyl) Indoline",
      "MolPort-003-287-500",
      "BDBM50321878",
      "AKOS001223824",
      "N-(3-Pyridinyl)-2,3-Dihydro-1H-Indole-1-Carboxamide",
      "2,3-Dihydro-Indole-1-Carboxylic Acid Pyridin-3-Ylamide",
    ],
  },
  {
    primary_id: "D00BXG",
    names: ["TIPPpsi"],
  },
  {
    primary_id: "D00BXK",
    names: ["Pyrazole Derivative 39"],
  },
  {
    primary_id: "D00BXZ",
    names: ["M-402"],
  },
  {
    primary_id: "D00BYK",
    names: ["PMID27774824-Compound-Figure12Example61"],
  },
  {
    primary_id: "D00BYM",
    names: ["Volasertib"],
  },
  {
    primary_id: "D00BZJ",
    names: ["Trumenba"],
  },
  {
    primary_id: "D00BZO",
    names: ["HL-035"],
  },
  {
    primary_id: "D00BZS",
    names: ["Gimatecan"],
  },
  {
    primary_id: "D00BZZ",
    names: [
      "PF-4603629",
      "BRX-1177",
      "PF-04603629",
      "Exendin-4 Transferrin Fusion Protein (Diabetes), Pfizer/BioRexis",
    ],
  },
  {
    primary_id: "D00CAF",
    names: ["Paraquat", "1,1'-Dimethyl-4,4' Bipyridinium Dichloride"],
  },
  {
    primary_id: "D00CAU",
    names: ["N-(3,3-Dimethyl-Cyclopentyl)-4-Hexyloxy-Benzamide", "CHEMBL121751", "SCHEMBL7219320", "BDBM50106184"],
  },
  {
    primary_id: "D00CBP",
    names: ["DTPw-HBV/Hib"],
  },
  {
    primary_id: "D00CBY",
    names: [
      "CEL-021",
      "APF Inhibitor (Interstitial Cystitis), Celek Pharmaceuticals",
      "Anti-Proliferative Factor Inhibitor (Interstitial Cystitis), Celek Pharmaceuticals",
    ],
  },
  {
    primary_id: "D00CCI",
    names: [
      "Benoxaprofen",
      "Benoxaprofene",
      "Benoxaprofeno",
      "Benoxaprofenum",
      "Coxigon",
      "Inflamid",
      "Opren",
      "Oraflex",
      "Uniprofen",
      "LRCL3794",
      "Lilly 90459",
      "Benoxaprofene [INN-French]",
      "Benoxaprofeno [INN-Spanish]",
      "Benoxaprofenum [INN-Latin]",
      "Dl-Benoxaprofen",
      "Oraflex (TN)",
      "Benoxaprofen (USAN/INN)",
      "Benoxaprofen [USAN:INN:BAN]",
      "(+-)-2-(P-Chlorophenyl)-Alpha-Methyl-5-Benzoxazoleacetic Acid",
      "(-)-2-(4-Chlorophenyl)-Alpha-Methyl-5-Benzoxazoleacetic Acid",
      "(-)-Benoxaprofen",
      "(1)-2-(4-Chlorophenyl)Benzoxazole-5-Propionic Acid",
      "2-(2-(4-Chlorophenyl)-1,3-Benzoxazol-5-Yl)Propanoic Acid",
      "2-(4-Chlorophenyl)-Alpha-Methyl-5-Benzoxazoleacetic Acid",
      "2-(P-Chlorophenyl)-.alpha.-Methyl-5-Benzoxazoleacetic Acid",
      "2-[2-(4-Chlorophenyl)-1,3-Benzoxazol-5-Yl]propanoic Acid",
    ],
  },
  {
    primary_id: "D00CCV",
    names: ["1,6-Bis(4-M-Tolylpiperazin-1-Yl)Hexane", "CHEMBL1172204", "SCHEMBL4519876"],
  },
  {
    primary_id: "D00CDF",
    names: ["Andarine"],
  },
  {
    primary_id: "D00CDI",
    names: ["Peptide Analog 67"],
  },
  {
    primary_id: "D00CDM",
    names: [
      "PD 128907",
      "(4As,10bS)-4-Propyl-3,4a,5,10b-Tetrahydro-2H-Chromeno[4,3-B][1,4]oxazin-9-Ol",
      "PBPO",
      "PBTO",
      "PD128907",
      "PD-128907",
      "CHEMBL97424",
      "[3H]PD 128907",
      "123594-64-9",
      "(4aS,10bS)-4-Propyl-3,4a,5,10b-Tetrahydro-2H-Chromeno[4,3-B][1,4]oxazin-9-Ol",
      "Trans-(+-)-3,4,4a,10b-Tetrahydro-4-Propyl-2H,5H-(1)Benzopyrano(4,3-B)-1,4-Oxazin-9-Ol",
      "3,4,4a,10b-Tetrahydro-4-Propyl-2H,5H-(1)Benzopyrano(4,3-B)-1,4-Oxazin-9-Ol",
      "2H,5H-(1)Benzopyrano(4,3-B)-1,4-Oxazin-9-Ol, 3,4,4a,10b-Tetrahydro-4-Propyl-, (4aR,10bR)-Rel-",
      "2H,5H-(1)Benzopyrano(4,3-B)-1,4-Oxazin-9-Ol, 3,4,4a,10b-Tetrahydro-4-Propyl-, Trans-(+-)-",
      "PD-128,907",
      "[3H]PD128907",
    ],
  },
  {
    primary_id: "D00CED",
    names: ["HG-1097"],
  },
  {
    primary_id: "D00CEE",
    names: ["Tetraxim"],
  },
  {
    primary_id: "D00CEG",
    names: [
      "D-43787",
      "UNII-18SFZ71BQO",
      "198016-44-3",
      "18SFZ71BQO",
      "CTK8E8581",
      "DTXSID30173514",
      "ZINC3960990",
      "D43787",
      "1H-Indole-1-Carboxylic Acid, 2-(((2S)-2,3-Dihydro-2-((((1S)-1-(Methoxycarbonyl)-5-(((Phenylmethoxy)Carbonyl)Amino)Pentyl)Amino)Carbonyl)-1H-Indol-1-Yl)Carbonyl)-2,3-Dihydro-, 1,1-Dimethylethyl Ester, (2S)-",
      "RT-012166",
      "FT-0665439",
      "J-012788",
      "(2S)-2-[[(2S)-2,3-Dihydro-2-[[[(1S)-1-(Methoxycarbonyl)-5-[[(Phenylmethoxy)Carbonyl]amino]pentyl]amino]carbonyl]-1H-Indol-1-Yl]carbonyl]-2,3-Dihydro-1H-Indole-1-Carboxylic Acid 1,1-Dime",
    ],
  },
  {
    primary_id: "D00CEI",
    names: ["CM-2501", "CB-2501"],
  },
  {
    primary_id: "D00CEN",
    names: ["L-735021"],
  },
  {
    primary_id: "D00CEO",
    names: [
      "6-Hydroxypropylthymine",
      "6-(3-Hydroxypropyl)Thymine",
      "6-(3-Hydroxypropyl)-5-Methylpyrimidine-2,4(1H,3H)-Dione",
      "2,4(1H,3H)-Pyrimidinedione, 6-(3-Hydroxypropyl)-5-Methyl-",
      "156569-47-0",
      "AC1L1CEO",
      "SCHEMBL4315838",
      "CHEBI:43299",
      "CTK8A4001",
      "DB04139",
    ],
  },
  {
    primary_id: "D00CFB",
    names: ["Zeniplatin", "CL-286558"],
  },
  {
    primary_id: "D00CFU",
    names: ["RO-4840700"],
  },
  {
    primary_id: "D00CGH",
    names: [
      "RO-23-7553",
      "BXL-353",
      "ILX-23-7553",
      "(1alpha,3beta,5Z,7E)-9,10-Secocholesta-5,7,10(19),16-Tetraen-23-Yne-1,3,25-Triol",
      "1alpha,25-Dihydroxy-16,17,23,23,24,24-Hexadehydrocholecalciferol",
      "1alpha,25-Dihydroxy-16,17,23,23,24,24-Hexadehydrovitamin D3",
    ],
  },
  {
    primary_id: "D00CGJ",
    names: [
      "2-(4-Bromo-2-Methoxy-Phenyl)-1-Methyl-Ethylamine",
      "CHEMBL60146",
      "99632-51-6",
      "Benzeneethanamine, 4-Bromo-2-Methoxy-Alpha-Methyl-",
      "BDBM50005262",
      "AKOS023456739",
    ],
  },
  {
    primary_id: "D00CGU",
    names: ["IMS"],
  },
  {
    primary_id: "D00CGX",
    names: ["DV-1179"],
  },
  {
    primary_id: "D00CHD",
    names: ["13-N-Heptanamidotridec-8(Z)-Enoic Acid", "CHEMBL560420"],
  },
  {
    primary_id: "D00CIF",
    names: ["EMD-503982"],
  },
  {
    primary_id: "D00CIT",
    names: ["Regavirumab", "CMV MAbs, Teijin", "CMV Antibodies, Teijin", "TI-23"],
  },
  {
    primary_id: "D00CIW",
    names: ["Phenyl-Phosphonic Acid"],
  },
  {
    primary_id: "D00CJJ",
    names: ["8-Bromo-9-Phenylethyl-9H-Adenine", "CHEMBL503376", "SCHEMBL5364361"],
  },
  {
    primary_id: "D00CJQ",
    names: ["MurD Ligase Inhibitors"],
  },
  {
    primary_id: "D00CKQ",
    names: ["TRPV1 Antagonists"],
  },
  {
    primary_id: "D00CKV",
    names: ["Indolizine Derivative 1"],
  },
  {
    primary_id: "D00CKY",
    names: ["7-Phenyl-1-(1,3,4-Thiadiazol-2-Yl)-Heptan-1-One", "CHEMBL477371", "SCHEMBL2162232"],
  },
  {
    primary_id: "D00CLB",
    names: ["SB-328872"],
  },
  {
    primary_id: "D00CLC",
    names: ["SLV-338"],
  },
  {
    primary_id: "D00CLK",
    names: ["PMID27977313-Compound-18"],
  },
  {
    primary_id: "D00CLM",
    names: [
      "NIP-004",
      "Blood Clotting Modulator, Nissan",
      "NIP-022",
      "ONO-7746",
      "ONO-7746)",
      "Peptidomimetic Thrombopoietin Agonist (Thrombocytopenia), Nissan Chemical",
      "Peptidomimetic Thrombopoietin Agonist (Thrombocytopenia), Nissan Chemical/Ono",
    ],
  },
  {
    primary_id: "D00CMA",
    names: ["VVZ-172"],
  },
  {
    primary_id: "D00CMC",
    names: ["BP-9906"],
  },
  {
    primary_id: "D00CMV",
    names: ["ABT-102", "3-(1H-Indazol-4-Yl)-1-(5-Tert-Butyl-2,3-Dihydro-1H-Inden-1-Yl)Urea"],
  },
  {
    primary_id: "D00CMX",
    names: ["PMID26004420-Compound-WO2013062900A"],
  },
  {
    primary_id: "D00COB",
    names: ["PD136450"],
  },
  {
    primary_id: "D00COH",
    names: ["Biaryl Mannoside Derivative 24"],
  },
  {
    primary_id: "D00COJ",
    names: ["Inclisiran"],
  },
  {
    primary_id: "D00CPI",
    names: [
      "1-(3-Hydroxyphenyl)-4-Propylpiperazine",
      "CHEMBL447809",
      "SCHEMBL3453730",
      "AWROKCIGUWAVRF-UHFFFAOYSA-N",
      "3-(4-Propyl-1-Piperazinyl)Phenol",
      "BDBM50308023",
    ],
  },
  {
    primary_id: "D00CPM",
    names: [
      "2-(4-Methoxy-Phenyl)-1H-Indole-3-Carbaldehyde",
      "2-(4-Methoxyphenyl)-1H-Indole-3-Carbaldehyde",
      "76195-80-7",
      "AC1MY4K3",
      "CHEMBL144222",
      "SCHEMBL15332438",
      "CTK2G8075",
      "DTXSID90396949",
      "MolPort-006-701-191",
      "IAL-1620",
      "ZX-BK002979",
      "ZINC3675784",
      "KM5032",
      "STK894724",
      "BBL022005",
      "AKOS005143919",
      "MCULE-1940549514",
      "1H-Indole-3-Carboxaldehyde, 2-(4-Methoxyphenyl)-",
    ],
  },
  {
    primary_id: "D00CPQ",
    names: ["RemoSynch"],
  },
  {
    primary_id: "D00CQB",
    names: ["CI-1021"],
  },
  {
    primary_id: "D00CQI",
    names: ["Indatuximab Ravtansine"],
  },
  {
    primary_id: "D00CQL",
    names: ["N-8295"],
  },
  {
    primary_id: "D00CQQ",
    names: ["TGFK09SD-ER"],
  },
  {
    primary_id: "D00CQR",
    names: ["6-Methyl-2-Oxa-Spiro[4.4]nonan-1-One", "CHEMBL97469"],
  },
  {
    primary_id: "D00CQT",
    names: [
      "Dichloroallyl Lawsone",
      "Dichlorolapachol",
      "Dichlorolawsone",
      "Dichloroallyllawsone",
      "Lawsone, Dichlorallyl-",
      "36417-16-0",
      "NSC 126771",
      "NSC-126771",
      "2-Hydroxy-3-(3,3-Dichloroallyl)-1,4-Naphthoquinone",
      "UNII-ZE2BI297KA",
      "BRN 1977648",
      "ZE2BI297KA",
      "1,4-Naphthoquinone, 2-(3,3-Dichloroallyl)-3-Hydroxy-",
      "MLS002701983",
      "1,4-NAPHTHALENEDIONE, 2-(3,3-DICHLORO-2-PROPENYL)-3-HYDROXY-",
      "NSC126771",
      "3-(3,3-Dichloroallyl)-2-Hydroxy-1,4-Naphthoquinone",
      "AC1L5MNX",
      "AC1Q3FFX",
      "NCIMech_000041",
      "Dichloroallyl Lawsone (DCL)",
      "C13H8Cl2O3",
    ],
  },
  {
    primary_id: "D00CQW",
    names: ["TNP-351"],
  },
  {
    primary_id: "D00CRA",
    names: ["2-Methyl-5-HT", "2-Methyl-5-Hydroxytryptamine", "2-Me-5-HT", "2-Methylserotonin"],
  },
  {
    primary_id: "D00CRQ",
    names: ["Phenylate Derivative 1"],
  },
  {
    primary_id: "D00CSA",
    names: ["LFF-269"],
  },
  {
    primary_id: "D00CSQ",
    names: ["Triclosan"],
  },
  {
    primary_id: "D00CTS",
    names: [
      "Lubiprostone",
      "Amitiza",
      "Lubiprostone [USAN]",
      "RU 0211",
      "RU0211",
      "SPI 0211",
      "SPI0211",
      "AMITIZA (TN)",
      "RU-0211",
      "SPI-0211",
      "Amitiza, RU-0211, SPI-0211, Lubiprostone",
      "(-)-7-((2R,4aR,5R,7aR)-2-(1,1-Difluoropentyl)-2-Hydroxy-6-Oxooctahydrocyclopenta (B)Pyran-5-Yl)Heptanoic Acid",
      "(-)-7-((2R,4aR,5R,7aR)-2-(1,1-Difluoropentyl)-2-Hydroxy-6-Oxooctahydrocyclopenta(B)Pyran-5-Yl)Heptanoic Acid",
      "7-((1R,4R,6R,9R)-4-(1,1-Difluoropentyl)-4-Hydroxy-8-Oxo-5-Oxabicyclo(4.3.0)Non-9-Yl)Heptanoic Acid",
      "7-[(2R,4aR,5R,7aR)-2-(1,1-Difluoropentyl)-2-Hydroxy-6-Oxo-3,4,4a,5,7,7a-Hexahydrocyclopenta[b]pyran-5-Yl]heptanoic Acid",
    ],
  },
  {
    primary_id: "D00CTZ",
    names: ["MEDI9929"],
  },
  {
    primary_id: "D00CUZ",
    names: ["[3H]pirenzepine", "[3H]PZ"],
  },
  {
    primary_id: "D00CVB",
    names: [
      "2'-Deoxyuridine",
      "2'-DEOXYURIDINE",
      "951-78-0",
      "Deoxyuridine",
      "Uracil Deoxyriboside",
      "1-((2R,4S,5R)-4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl)Pyrimidine-2,4(1H,3H)-Dione",
      "2-Deoxyuridine",
      "Deoxyribose Uracil",
      "2'-Desoxyuridine",
      "UNII-W78I7AY22C",
      "CCRIS 2832",
      "DURD",
      "EINECS 213-455-7",
      "BRN 0024433",
      "1-(2-Deoxy-Beta-D-Ribofuranosyl)Uracil",
      "W78I7AY22C",
      "CHEBI:16450",
      "2 -Deoxyuridine",
      "1-(2-Deoxy-Beta-D-Erythro-Pentofuranoxyl)Uracil",
      "MFCD00006527",
      "AK-54658",
      "2,4(1H,3H)-Pyrimidinedione, 1-(2-Deoxy-Beta-D-Ribofuranosyl)-",
      "NSC 23615",
      "DEOXYURIDINE",
    ],
  },
  {
    primary_id: "D00CVD",
    names: ["MRS1067"],
  },
  {
    primary_id: "D00CVJ",
    names: ["Neu-P11"],
  },
  {
    primary_id: "D00CVT",
    names: ["TD-6301"],
  },
  {
    primary_id: "D00CWH",
    names: [
      "2-Amino-N,3,3-Trimethylbutanamide",
      "2-AMINO-N,3,3-TRIMETHYLBUTANAMIDE",
      "AC1MRP6C",
      "SCHEMBL343358",
      "DTXSID30393038",
      "BPKJNEIOHOEWLO-UHFFFAOYSA-N",
      "2-Amino-N,3,3-Trimethyl-Butanamide",
      "2-Azanyl-N,3,3-Trimethyl-Butanamide",
      "AKOS022475747",
      "AN-25241",
    ],
  },
  {
    primary_id: "D00CWJ",
    names: ["ISIS 25565"],
  },
  {
    primary_id: "D00CWN",
    names: ["2-(4-Hydroxy-Phenyl)-Quinolin-6-Ol"],
  },
  {
    primary_id: "D00CYF",
    names: ["Ac-I[CVWQDWGWHRC]T-NH2"],
  },
  {
    primary_id: "D00CYX",
    names: ["Glu-Leu-Asp-Leu-(CHOH-CH2)-Ala-Ala-Glu-Phe", "CHEMBL363255"],
  },
  {
    primary_id: "D00CZL",
    names: ["INNO-206", "Aldoxorubicin [USAN:INN]"],
  },
  {
    primary_id: "D00CZT",
    names: ["Solanezumab"],
  },
  {
    primary_id: "D00DAE",
    names: ["Carboxyamidotriazole Orotate"],
  },
  {
    primary_id: "D00DAU",
    names: ["LCP-3301"],
  },
  {
    primary_id: "D00DBE",
    names: [
      "CI-1018",
      "UNII-Y4O6325SYW",
      "Y4O6325SYW",
      "CHEMBL111925",
      "DSSTox_CID_27248",
      "DSSTox_RID_82208",
      "DSSTox_GSID_47248",
      "SCHEMBL185627",
      "CAS-NOCAS_47248",
      "DTXSID0047248",
      "NOCAS_47248",
      "Tox21_300203",
      "BDBM50217550",
      "NCGC00254191-01",
      "NCGC00247925-01",
      "179024-48-7",
      "PD-168787",
      "(+)-CI-1018",
      "CI-1018, (+)-",
      "UNII-05127JZ9KQ Component KYFWUBJMTHVBIF-QFIPXVFZSA-N",
      "4-Pyridinecarboxamide, N-(3,4,6,7-Tetrahydro-9-Methyl-4-Oxo-1-Phenylpyrrolo(3,2,1-Jk)(1,4)Benzodiazepin-3-Yl)-, (R)-",
      "4-Pyridinecarboxamide, N-((3R)-3,4,6,7-Tetrahydro-9-Methyl-4-Oxo-1-Phenylpyrrol",
    ],
  },
  {
    primary_id: "D00DBH",
    names: ["NPS 1776"],
  },
  {
    primary_id: "D00DBO",
    names: ["Pipamperone"],
  },
  {
    primary_id: "D00DBR",
    names: ["6-Methylamino-5-Nitroisocytosine"],
  },
  {
    primary_id: "D00DBU",
    names: ["SA-IGIV"],
  },
  {
    primary_id: "D00DBV",
    names: ["Methylurea"],
  },
  {
    primary_id: "D00DCY",
    names: ["OraTest"],
  },
  {
    primary_id: "D00DDH",
    names: ["(2S)-Aminobutyryl-L-Proline-(2S)-Methylbutylamide", "CHEMBL223719"],
  },
  {
    primary_id: "D00DDJ",
    names: [
      "Adamantan-1-Yl-Piperazin-1-Yl-Methanone",
      "1-(1-Adamantylcarbonyl)Piperazine",
      "29869-08-7",
      "Adamantanyl Piperazinyl Ketone",
      "CHEMBL391335",
      "Piperazin-1-Yl(Tricyclo[3.3.1.1~3,7~]dec-1-Yl)Methanone",
      "AC1Q5KAX",
      "SCHEMBL1150471",
      "CTK4G3954",
      "DTXSID70589438",
      "XAFSAELWLMDLKL-UHFFFAOYSA-N",
      "MolPort-006-345-515",
      "1-(1-Adamantanecarbonyl)Piperazine",
      "ALBB-024506",
      "ZINC12343593",
      "STL412114",
      "BDBM50207783",
      "SBB071957",
      "BBL037233",
      "AKOS009097513",
      "MCULE-3925280273",
      "ST072044",
      "EN300-25317",
      "AB00983558-01",
      "Piperazine, 1-(Tricyclo[3.3.1.1~3,7~]dec-1-Ylcarbonyl)-",
    ],
  },
  {
    primary_id: "D00DDO",
    names: [
      "3-Bromo-5-Phenylsalicylc Acid",
      "4906-68-7",
      "CHEMBL387536",
      "3-Bromo-5-Phenylsalicylic Acid",
      "3-Bromo-5-Phenylsalicyclic Acid",
      "5-Bromo-4-Hydroxybiphenyl-3-Carboxylic Acid",
      "5-Bromo-4-Hydroxy-[1,1'-Biphenyl]-3-Carboxylic Acid",
      "3-Bromo-2-Hydroxy-5-Phenylbenzoic Acid",
      "NSC109116",
      "[1,1'-Biphenyl]-3-Carboxylic Acid, 5-Bromo-4-Hydroxy-",
      "AC1L6KWF",
      "AC1Q25S5",
      "SCHEMBL18319714",
      "KS-00002WWQ",
      "CTK1D8664",
      "3-Bromo-5-Phenyl-Salicylic Acid",
      "DTXSID70296397",
      "MolPort-018-496-593",
      "XVZSXNULHSIRCQ-UHFFFAOYSA-N",
      "ZINC1701218",
      "BDBM50219490",
      "AKOS005151372",
    ],
  },
  {
    primary_id: "D00DDR",
    names: ["G3139 + Thalidomide"],
  },
  {
    primary_id: "D00DDT",
    names: [
      "2-N,N-Dimethylamino-1-(4-Methylthiophenyl)Propane",
      "CHEMBL1078835",
      "NFRBJBFYUNQIQP-UHFFFAOYSA-N",
      "BDBM50310863",
      "N,N-Dimethyl-1-[4-(Methylthio)Phenyl]-2-Propaneamine",
    ],
  },
  {
    primary_id: "D00DDX",
    names: ["N-(Pyridin-2-Yl)Pyrimidin-4-Amine Derivative 1"],
  },
  {
    primary_id: "D00DEF",
    names: ["Lisdexamfetamine"],
  },
  {
    primary_id: "D00DEH",
    names: ["4-(2-(3-Chlorophenoxy)Phenyl)Piperidine", "CHEMBL575290"],
  },
  {
    primary_id: "D00DFK",
    names: ["Heteroaryl-Azepine Derivative 10"],
  },
  {
    primary_id: "D00DFP",
    names: ["KBP-5074"],
  },
  {
    primary_id: "D00DFR",
    names: ["(5R,6S)-5,6-Bis(Benzyloxy)Cyclohex-2-Enone", "CHEMBL378672", "GS416"],
  },
  {
    primary_id: "D00DHM",
    names: ["SDZ-208911"],
  },
  {
    primary_id: "D00DHT",
    names: ["Mammalian Sterile 20-Like Kinase 1 Gene Eluting Stent"],
  },
  {
    primary_id: "D00DHV",
    names: [
      "4-(Piperazin-1-Yl)Thieno[3,2-D]pyrimidine",
      "683274-67-1",
      "4-Piperazinothieno[3,2-D]pyrimidine",
      "CHEMBL594477",
      "1-{thieno[3,2-D]pyrimidin-4-Yl}piperazine",
      "1-(Thieno[3,2-D]pyrimidin-4-Yl)Piperazine",
      "AC1MC2NN",
      "SCHEMBL1601613",
      "CTK7D1709",
      "GVNSLBIXJYZCCB-UHFFFAOYSA-N",
      "MolPort-000-158-321",
      "ZINC2513539",
      "SBB095997",
      "KM1197",
      "BDBM50304815",
      "8099AE",
      "AKOS009377421",
      "FS-1502",
      "MCULE-3522335295",
      "KS-0000257Q",
      "4-Piperazinylthiopheno[3,2-D]pyrimidine",
      "4-Piperazin-1-Ylthieno[3,2-D]pyrimidine",
      "KB-86789",
      "KB-193828",
      "4-Piperazin-1-Yl-Thieno[3,2-D]pyrimidine",
    ],
  },
  {
    primary_id: "D00DIB",
    names: ["Anti-CD33"],
  },
  {
    primary_id: "D00DIF",
    names: [
      "CyPPA",
      "73029-73-9",
      "N-Cyclohexyl-2-(3,5-Dimethyl-1H-Pyrazol-1-Yl)-6-Methylpyrimidin-4-Amine",
      "CHEMBL453730",
      "Cyclohexyl-[2-(3,5-Dimethyl-Pyrazol-1-Yl)-6-Methyl-Pyrimidin-4-Yl]-Amine",
      "C16H23N5",
      "N-Cyclohexyl-2-(3,5-Dimethylpyrazol-1-Yl)-6-Methylpyrimidin-4-Amine",
      "AC1LIR5Q",
      "SCHEMBL3233745",
      "GTPL2323",
      "CTK8F8875",
      "DTXSID20358676",
      "USEMRPYUFJNFQN-UHFFFAOYSA-N",
      "MolPort-000-762-862",
      "ZINC492516",
      "HMS3262M15",
      "Tox21_500707",
      "STK052676",
      "MFCD01550586",
      "BDBM50275157",
      "CyPPA, &gt",
    ],
  },
  {
    primary_id: "D00DIJ",
    names: ["CGP-49870", "186615-82-7", "AT1 Angiotensin II Antagonist, Novartis"],
  },
  {
    primary_id: "D00DIM",
    names: ["Monohydroxyethylrutoside"],
  },
  {
    primary_id: "D00DIZ",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 17"],
  },
  {
    primary_id: "D00DJC",
    names: ["CJX-1", "CJX-2", "CJZ-3", "P-Glycoprotein Inhibitors (Cancer), China Pharmaceutical University"],
  },
  {
    primary_id: "D00DJE",
    names: ["N-(2,3-Dihydro-1H-Inden-2-Yl)Quinazolin-4-Amine", "CHEMBL471242", "SCHEMBL9040263"],
  },
  {
    primary_id: "D00DJX",
    names: ["JNJ-17148066", "CHEMBL1088485", "SCHEMBL14277842", "BDBM50310388"],
  },
  {
    primary_id: "D00DKI",
    names: ["(+/-)-HuprineY Hydrochloride", "CHEMBL536047", "(+/-)-Huprine Y", "(+/-)-Hupriney HCl"],
  },
  {
    primary_id: "D00DKK",
    names: [
      "Isotretinoin",
      "Accutane",
      "Amnesteem",
      "Claravis",
      "Isotane",
      "Isotretinoine",
      "Isotretinoino",
      "Isotretinoinum",
      "Isotrex",
      "Roaccutan",
      "Roaccutane",
      "Roacutan",
      "Sotret",
      "Teriosal",
      "Neovitamin A Acid",
      "R 3255",
      "Ro 4 3780",
      "Ro 43780",
      "Accutane (TN)",
      "Amnesteem (TN)",
      "BML2-E07",
      "CIP-Isotretinoin",
      "Claravi (TN)",
      "Clarus (TN)",
      "Decutan (TN)",
      "Isotane (TN)",
      "Isotretinoin (USP)",
      "Isotretinoin Zinc Salt, 13 Cis Isomer",
      "Isotretinoine [INN-French]",
      "Isotretinoino [INN-Spanish]",
      "Isotretinoinum [INN-Latin]",
      "Izotek (TN)",
      "Oratane (TN)",
      "Ro 4-3780",
      "Ro-43780",
      "Roaccutane (TN)",
      "Sotret (TN)",
      "Isotretinoin [USAN:INN:BAN]",
      "Ro-4-3780",
      "Isotretinoin Zinc Salt, 13-Cis-Isomer",
      "(2Z,4E,6E,8E)-3,7-Dimethyl-9-(2,6,6-Trimethylcyclohexen-1-Yl)Nona-2,4,6,8-Tetraenoic Acid",
      "(2Z,4E6E,8E)-3,7-Dimethyl-9-(2,6,6-Trimethylcyclohex-1-En-1-Yl)Nona-2,4,6,8-Tetraenoic Acid",
      "(7E,9E,11E,13Z)-Retinoic Acid",
      "13-RA",
      "13-Cis RA",
      "13-Cis-Vitamin A Acid",
      "3,7-Dimethyl-9-(2,6,6-Trimethyl-1-Cyclohexen-1-Yl)2-Cis-4-Trans-6-Trans-8-Trans-Nonatetraenoic Acid",
    ],
  },
  {
    primary_id: "D00DLU",
    names: ["GSK-9772"],
  },
  {
    primary_id: "D00DNT",
    names: ["JWH-406"],
  },
  {
    primary_id: "D00DNY",
    names: ["Ellipticine"],
  },
  {
    primary_id: "D00DPJ",
    names: ["Amezinium", "Amezinium Methyl Sulfate"],
  },
  {
    primary_id: "D00DPP",
    names: [
      "CYCLOPLATAM",
      "Ammine(Cyclopentanamine)[(S)-Hydroxybutanedioato(2-)-O1,O4]platinum",
      "(S)-(-)-Malatoammine(Cyclopentylamine)Platinum (II)",
    ],
  },
  {
    primary_id: "D00DPX",
    names: ["ASN04421891", "ASN 04421891", "ASN-04421891"],
  },
  {
    primary_id: "D00DQX",
    names: ["Motesanib"],
  },
  {
    primary_id: "D00DQZ",
    names: ["S-2,9-Dioxo-9-(Phenylamino)Nonyl Ethanethioate", "CHEMBL272613"],
  },
  {
    primary_id: "D00DRA",
    names: ["KA-20X"],
  },
  {
    primary_id: "D00DRL",
    names: ["MK-2461"],
  },
  {
    primary_id: "D00DRY",
    names: ["ISIS 9065"],
  },
  {
    primary_id: "D00DSD",
    names: ["GLI-5021"],
  },
  {
    primary_id: "D00DSE",
    names: ["4-(4'-Fluoro-Biphenyl-4-Ylmethyl)Pyridine", "CHEMBL1215732", "BDBM50324616"],
  },
  {
    primary_id: "D00DSG",
    names: ["PF-05313261"],
  },
  {
    primary_id: "D00DSN",
    names: ["Sepcidin"],
  },
  {
    primary_id: "D00DSQ",
    names: [
      "HI-244",
      "CHEMBL144604",
      "HI-256",
      "AC1MHE9N",
      "SCHEMBL6847403",
      "BDBM50097041",
      "AKOS030007896",
      "1-(5-Bromo-2-Pyridyl)-3-[2-(4-Hydroxyphenyl)Ethyl]thiourea",
      "N-[2-(4-Hydroxyphenethyl)]-N'-[2-(5-Bromopyridyl)]thiourea",
      "1-(5-Bromopyridin-2-Yl)-3-[2-(4-Hydroxyphenyl)Ethyl]thiourea",
    ],
  },
  {
    primary_id: "D00DSV",
    names: ["Polynoma-1"],
  },
  {
    primary_id: "D00DSW",
    names: ["NSC-640559"],
  },
  {
    primary_id: "D00DTP",
    names: ["ZP-006"],
  },
  {
    primary_id: "D00DUJ",
    names: [
      "3-Phenanthren-9-Yl-Pyridine",
      "3-(9-Phenanthryl)Pyridine",
      "3-(Phenanthren-9-Yl)Pyridine",
      "SCHEMBL4508899",
      "BDBM8915",
      "CHEMBL194277",
      "Pyridine-Substituted Phenanthryl 22",
    ],
  },
  {
    primary_id: "D00DUK",
    names: [
      "AFM-13",
      "Anti-CD30 And CD16 Bivalent Antibody (Hodgkin's Disease, TandAb), Affimed",
      "Anti-CD30 And CD16A Bivalent Antibody (Hodgkin's Disease, TandAb), Affimed",
      "Anti-CD30 X Anti-CD16 Bivalent Antibody (Hodgkin's Disease), Affimed",
    ],
  },
  {
    primary_id: "D00DXB",
    names: ["3-Mercapto-2-(Piperidin-3-Yl)Propanoic Acid", "CHEMBL245591", "SCHEMBL2354438"],
  },
  {
    primary_id: "D00DXC",
    names: ["Macrocycle Derivative 13"],
  },
  {
    primary_id: "D00DXH",
    names: ["LYS-6KAKT1"],
  },
  {
    primary_id: "D00DXI",
    names: ["Ischemin"],
  },
  {
    primary_id: "D00DXV",
    names: ["BAY 50-7952", "CHEMBL157769", "BAY-507952"],
  },
  {
    primary_id: "D00DYI",
    names: ["Benzothiazole Analog 1"],
  },
  {
    primary_id: "D00DYK",
    names: [
      "Lunacalcipol",
      "CTA-018",
      "MT-2832",
      "Lunacalcipol (Intravenous, Secondary Hyperparathyroidism)",
      "Lunacalcipol (Intravenous, Secondary Hyperparathyroidism), Cytochroma",
      "Vitamin D Analogs (Secondary Hyperparathyroidism), Johns Hopkins/Cytochroma",
      "CTA-018 (Intravenous, Secondary Hyperparathyroidism), Cytochroma",
      "CYP24 Inhibitors (Intravenous, Secondary Hyperparathyroidism), Johns Hopkins/Cytochroma",
    ],
  },
  {
    primary_id: "D00DYN",
    names: ["Botulinum Neurotoxin Vaccine"],
  },
  {
    primary_id: "D00DYQ",
    names: ["ISIS 2177"],
  },
  {
    primary_id: "D00DZN",
    names: [
      "Beclamide",
      "501-68-8",
      "Chlorakon",
      "Chloracon",
      "Neuracen",
      "N-Benzyl-3-Chloropropionamide",
      "Beklamid",
      "Nydrane",
      "Nydran",
      "Seclar",
      "Nidrane",
      "Benzchlorpropamide",
      "Posedrine",
      "Beclamid",
      "Hibicon",
      "Chloroethylphenamide",
      "N-Benzyl-3-Chloropropanamide",
      "Benzylamide",
      "Khlorakon",
      "Benzchlorpropamid",
      "Benzochlorpropamid",
      "Benxchlorpropamide",
      "Posedrin",
      "N-(3-Chloropropionyl)Benzylamine",
      "Chloropropionamide",
      "N-Benzyl-Beta-Chloropropionamide",
      "Chlorakon (VAN)",
      "NSC 67062",
      "Beclamidum [INN-Latin]",
      "Propanamide, 3-Chloro-N-(Phenylmethyl)-",
      "Beclamida [INN-Spanish]",
    ],
  },
  {
    primary_id: "D00DZY",
    names: ["4-(2-Benzenesulfonylphenyl)Piperazine", "CHEMBL209893", "SCHEMBL3796417"],
  },
  {
    primary_id: "D00EBH",
    names: ["ICI-170809"],
  },
  {
    primary_id: "D00EBN",
    names: ["Imidazopyridine Acetamide Analog 7"],
  },
  {
    primary_id: "D00ECB",
    names: [
      "CD-349",
      "CD 349",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(3-Nitrophenyl)-, 2-(Nitrooxy)Propyl 3-(Nitrooxy)Propyl Ester",
      "2-Nitratopropyl 3-Nitratopropyl 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "AC1L3OLK",
      "SCHEMBL7376581",
      "AC1Q63B2",
      "2-(Nitrooxy)Propyl 3-(Nitrooxy)Propyl 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "LS-186864",
      "LS-187520",
      "5-O-(2-Nitrooxypropyl) 3-O-(3-Nitrooxypropyl) 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
    ],
  },
  {
    primary_id: "D00ECO",
    names: [
      "SIB-1553A",
      "191611-89-9",
      "SIB 1553A Hydrochloride",
      "SIB 1553A Hyrdrochloride",
      "SIB 1553A HCl",
      "SCHEMBL6333252",
      "SIB 1553A",
      "MolPort-035-765-827",
      "AKOS024458336",
      "LS-104963",
      "J-012387",
      "(+/-)-4-[[2-(1-Methyl-2-Pyrrolidinyl)Ethyl]thio]phenol Hydrochloride",
      "(+-)-4-((2-(1-Methyl-2-Pyrrolidinyl)Ethyl)Thio)Phenol Hydrochloride",
      "Phenol, 4-((2-(1-Methyl-2-Pyrrolidinyl)Ethyl)Thio)-, Hydrochloride",
    ],
  },
  {
    primary_id: "D00ECV",
    names: ["SCH-225336"],
  },
  {
    primary_id: "D00EDB",
    names: ["Topical Etoricoxib"],
  },
  {
    primary_id: "D00EEL",
    names: ["Gaboxadol"],
  },
  {
    primary_id: "D00EEP",
    names: ["E-3309"],
  },
  {
    primary_id: "D00EEU",
    names: ["Arg-Pro-Lys-Pro-Ala-Ser-Phe-Phe-Gly-Leu-Met-NH2", "CHEMBL413827"],
  },
  {
    primary_id: "D00EFA",
    names: ["R-PSOP"],
  },
  {
    primary_id: "D00EFD",
    names: [
      "C-21191",
      "Deuterium-Modified GABA-A Modulator (Spasticity/Neuropathic Pain/Anxiety), CoNCERT Pharmaceuticals",
    ],
  },
  {
    primary_id: "D00EFO",
    names: ["Interferon Alpha-2b"],
  },
  {
    primary_id: "D00EFT",
    names: ["Norleucine"],
  },
  {
    primary_id: "D00EGN",
    names: ["ETX-9101"],
  },
  {
    primary_id: "D00EGU",
    names: ["PACAP38"],
  },
  {
    primary_id: "D00EGV",
    names: ["Monoamine Derivative 2"],
  },
  {
    primary_id: "D00EHP",
    names: ["HJC 0350"],
  },
  {
    primary_id: "D00EHX",
    names: ["TT-301"],
  },
  {
    primary_id: "D00EIB",
    names: ["Ha-1a", "Centoxin (TN)"],
  },
  {
    primary_id: "D00EIL",
    names: ["CG-103065"],
  },
  {
    primary_id: "D00EJP",
    names: ["GSK2339345"],
  },
  {
    primary_id: "D00EJS",
    names: [
      "5-Hydroxyindole",
      "1H-Indol-5-Ol",
      "1953-54-4",
      "INDOL-5-OL",
      "5-Hydroxy-1H-Indole",
      "Hydroxy-5 Indole",
      "5-Indolol",
      "UNII-320UN7XZYN",
      "Hydroxy-5-Indole",
      "Hydroxy-5 Indole [French]",
      "CCRIS 4422",
      "5-Hydroxyindole, 97%",
      "EINECS 217-782-6",
      "MFCD00005677",
      "NSC 87503",
      "BRN 0112349",
      "320UN7XZYN",
      "CHEBI:89649",
      "LMIQERWZRIFWNZ-UHFFFAOYSA-N",
      "NSC-87503",
      "5-Hydroxy-Indole",
      "Hydroxy-5 Indole  [French]",
      "5-Hydroxylindole",
      "5-Hydroxy-Indol",
      "5-Hydroxy Indole",
      "3fuh",
      "5H1",
      "Zlchem 359",
      "5Hydroxy-1H-Indole",
      "PubChem7263",
      "1-H-Indol-5-Ol",
      "1H-Indol-5-Ol.",
      "4b3c",
      "ACMC-1BQT3",
      "WLN: T56 BMJ GQ",
    ],
  },
  {
    primary_id: "D00EKM",
    names: ["Virapt"],
  },
  {
    primary_id: "D00EMB",
    names: ["GSK-1247446A"],
  },
  {
    primary_id: "D00EMF",
    names: ["SB 242084"],
  },
  {
    primary_id: "D00EMH",
    names: ["PMID21967808CR-16"],
  },
  {
    primary_id: "D00EML",
    names: ["Q-Vax"],
  },
  {
    primary_id: "D00ENC",
    names: [
      "4-Hydroxy-Pent-2-Enoic Acid",
      "CHEMBL171595",
      "(E)-4-Hydroxypent-2-Enoic Acid",
      "YANJKPZKTWMMOF-NSCUHMNNSA-N",
      "Trans-4-Hydroxypent-2-Enoic Acid",
      "AC1NSI5H",
      "4-Hydroxy-2-Pentenoic Acid",
      "SCHEMBL166635",
      "2-Pentenoic Acid, 4-Hydroxy-",
      "BDBM50023574",
      "(2E)-4-Hydroxy-2-Pentenoic Acid #",
      "AKOS022632862",
    ],
  },
  {
    primary_id: "D00ENR",
    names: ["1,8-Dihydroxy-2-Propionyl-10H-Anthracen-9-One", "CHEMBL126057", "BDBM50060901"],
  },
  {
    primary_id: "D00ENU",
    names: ["CAB-101", "Anti-Midkine Chimeric MAbs (Inflammatory Disease), Cellmid"],
  },
  {
    primary_id: "D00ENY",
    names: [
      "L-Glutamic Acid",
      "Aciglut",
      "Glu",
      "Glusate",
      "Glut",
      "Glutacid",
      "Glutamicol",
      "Glutamidex",
      "Glutaminol",
      "Glutaton",
      "Gulutamine",
      "Acide Glutamique",
      "Acido Glutamico",
      "Acidum Glutamicum",
      "Acidum Glutaminicum",
      "Glutamic Acid Polymer",
      "Glutaminic Acid",
      "E 620",
      "A-Aminoglutaric Acid",
      "A-Glutamic Acid",
      "Acide Glutamique [INN-French]",
      "Acido Glutamico [INN-Spanish]",
      "Acidum Glutamicum [INN-Latin]",
      "D-Glutamiensuur",
      "Glutamic Acid [USAN:INN]",
      "Glutamic Acid (VAN)",
      "Glutaminic Acid (VAN)",
      "Gulutamine (USP)",
      "L-Glutamic Acid Hydrochloride",
      "L-Glutaminic Acid",
      "L-Glutaminsaeure",
      "L-Glu",
      "L-Glutamate",
      "Alpha-Aminoglutaric Acid (VAN)",
      "Alpha-L-Glutamic Acid Polymer",
      "Glutamic Acid (L-Glutamic Acid)",
      "Glutamic Acid (H-3)",
      "H-Glu-OH",
      "L-2-Aminoglutaric Acid",
      "L-Glutamic Acid (9CI)",
      "L-Glutamic Acid (JAN)",
      "L-Glutamic Acid, Homopolymer",
      "L-A-Aminoglutaric Acid",
      "L-Alpha-Aminoglutaric Acid",
      "Poly-L-Glutamate",
      "Sodium Glutamate (L-Glutamic Acid)",
      "Glutamic Acid, L-, Peptides",
      "L-Glutamic Acid, Homopolymer (9CI)",
      "Poly(Alpha-L-Glutamic Acid)",
      "Glutamic Acid, L-(7CI,8CI)",
      "L-(+)-Glutamic Acid",
      "(S)-(+)-Glutamic Acid",
      "(S)-2-Aminopentanedioic Acid",
      "(S)-Glutamic Acid",
      "1-Aminopropane-1,3-Dicarboxylic Acid",
    ],
  },
  {
    primary_id: "D00EOD",
    names: ["CENTRATHERIN", "Centratherin", "CHEMBL382151"],
  },
  {
    primary_id: "D00EOK",
    names: ["Sarcoma Mab Vaccine"],
  },
  {
    primary_id: "D00EOP",
    names: ["AD-5061", "AD-7057", "AD7057", "AD 7057", "AD5061", "AD 5061"],
  },
  {
    primary_id: "D00EPB",
    names: ["ISIS 114397"],
  },
  {
    primary_id: "D00EPD",
    names: [
      "6-(2-Chloro-Benzenesulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL72699",
      "SCHEMBL6207180",
      "BDBM16444",
      "6-Phenylsulfonylpyridazin-2H-3-One, 6",
      "Phenyl-Substituted Sulfonylpyridazinone, 8c",
    ],
  },
  {
    primary_id: "D00EPL",
    names: ["PITRAZEPINE"],
  },
  {
    primary_id: "D00EPM",
    names: ["VU0463841"],
  },
  {
    primary_id: "D00EPS",
    names: ["PMID18163548C4", "7IN", "1vja", "AC1L9MNX", "GTPL6545", "BDBM50231520", "US8476306, 6.12"],
  },
  {
    primary_id: "D00EPT",
    names: ["E7850"],
  },
  {
    primary_id: "D00EQD",
    names: ["PN-277"],
  },
  {
    primary_id: "D00EQL",
    names: ["EPZ-6438"],
  },
  {
    primary_id: "D00EQY",
    names: ["KTN3379"],
  },
  {
    primary_id: "D00ERI",
    names: ["ISIS 102633"],
  },
  {
    primary_id: "D00ERV",
    names: [
      "Gallopamil",
      "16662-47-8",
      "Methoxyverapamil",
      "5-((3,4-Dimethoxyphenethyl)(Methyl)Amino)-2-Isopropyl-2-(3,4,5-Trimethoxyphenyl)Pentanenitrile",
      "Galopamilo",
      "Galopamilo [INN-Spanish]",
      "Gallopamillum [INN-Latin]",
      "Gallopamil [INN:BAN]",
      "D600",
      "D 600",
      "C28H40N2O5",
      "CHEBI:34772",
      "(+/-)-Methoxyverapamil Hydrochloride",
      "5-[(3,4-Dimethoxyphenethyl)Methylamino]-2-Isopropyl-2-(3,4,5-Trimethoxyphenyl)Valeronitrile",
      "Benzeneacetonitrile, Alpha-(3-((2-(3,4-Dimethoxyphenyl)Ethyl)Methylamino)Propyl)-3,4,5-Trimethoxy-Alpha-(1-Methylethyl)-",
      "GSK 796406",
    ],
  },
  {
    primary_id: "D00ERW",
    names: ["PMID17935989C25"],
  },
  {
    primary_id: "D00ESG",
    names: [
      "4-Pyridin-2-Yl-Piperazine-1-Carboxylic Acid Amide",
      "CHEMBL296963",
      "SCHEMBL1270156",
      "CTK7D3440",
      "BDBM50224176",
      "AKOS000158821",
      "4-(2-Pyridyl)Piperazine-1-Carboxamide",
    ],
  },
  {
    primary_id: "D00ESH",
    names: ["DCVax-Liver", "Dendritic Cell-Based Immunotherapy (Liver Cancer), Northwest Biotherapeutics"],
  },
  {
    primary_id: "D00ESU",
    names: ["Imfinzidurvalumab"],
  },
  {
    primary_id: "D00ETS",
    names: [
      "Hexobarbital",
      "Barbidorm",
      "Citodon",
      "Citopan",
      "Cyclonal",
      "Cyclopan",
      "Dorico",
      "Enhexymal",
      "Enhexymalum",
      "Esobarbitale",
      "Evipal",
      "Evipan",
      "Hexabarbital;Hexobarbitalum",
      "Hexobarbitone",
      "Hexobarbitonum",
      "Methexenyl",
      "Methylhexabarbital",
      "Methylhexabital",
      "Narcosan",
      "Noctivane",
      "Sombucaps",
      "Sombulex",
      "Somnalert",
      "Esobarbitale [DCIT]",
      "Esobarbitale [Italian]",
      "Hexanastab Oral",
      "Sodium Hexobarbital",
      "Citopan (TN)",
      "Evipan (TN)",
      "Hexenal (Barbiturate)",
      "Hexobarbital (VAN)",
      "Hexobarbital [INN:JAN]",
      "Hexobarbitalum [INN-Latin]",
      "Hexobarbital (JAN/INN)",
      "N-Methyl-5-Cyclohexenyl-5-Methylbarbituric Acid",
      "(+-)-Hexobarbital",
      "1,5-Dimethyl-5-(1-Cyclohexenyl)Barbituric Acid",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5-(1-Cyclohexen-1-Yl)-1,5-Dimethyl",
      "5-(1-CYCLOHEXEN-1-YL)-1,5-DIMETHYLBARBITURIC ACID",
      "5-(1-Cyclohexen-1-Yl)-1,5-Dimethyl-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-(1-Cyclohexenyl)-1,5-Dimethylbarbituric Acid",
      "5-(1-Cyclohexenyl-1)-1-Methyl-5-Methylbarbituric Acid",
      "5-(Cyclohex-1-En-1-Yl)-1,5-Dimethylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-(Cyclohexen-1-Yl)-1,5-Dimethyl-1,3-Diazinane-2,4,6-Trione",
      "5-(Delta-1,2-Cyclohexenyl)-5-Methyl-N-Methyl-Barbitursaeure [German]",
      "5-(Delta-1,2-Cyclohexenyl)-5-Methyl-N-Methyl-Barbitursaeure",
      "5-(Delta.-1,2-Cyclohexenyl)-5-Methyl-N-Methyl-Barbitursaeure",
      "5-Cyclohex-1-En-1-Yl-1,5-Dimethylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-Cyclohex-1-En-1-Yl-2-Hydroxy-1,5-Dimethylpyrimidine-4,6(1H,5H)-Dione",
    ],
  },
  {
    primary_id: "D00EUD",
    names: ["2-Amino-1-(4-Methylthiophenyl)Butane", "CHEMBL1078352", "634607-37-7", "BDBM50310844"],
  },
  {
    primary_id: "D00EUE",
    names: ["S-Sulpho-L-Cysteine"],
  },
  {
    primary_id: "D00EUL",
    names: ["PMID28270021-Compound-WO2016054807Example82"],
  },
  {
    primary_id: "D00EVC",
    names: [
      "6-Isobutyl-4-Methylpyridin-2-Amine",
      "CHEMBL294609",
      "SCHEMBL7052362",
      "VMTPVCVORKDABN-UHFFFAOYSA-N",
      "BDBM50091801",
      "2-Amino-4-Methyl-6-Isobutylpyridine",
      "6-Isobutyl-4-Methyl-Pyridin-2-Ylamine",
      "179555-28-3",
      "2-Amino-4-Methyl-6-(2-Methylpropyl)Pyridine",
      "2-Pyridinamine, 4-Methyl-6-(2-Methylpropyl)-",
    ],
  },
  {
    primary_id: "D00EVP",
    names: ["Simenepag Isopropyl"],
  },
  {
    primary_id: "D00EWP",
    names: ["2,7-Bis(Acetamido)Anthraquinone", "CHEMBL481175", "SCHEMBL4291944"],
  },
  {
    primary_id: "D00EWR",
    names: [
      "Fidarestat",
      "Aldos",
      "FID",
      "Fidaresstat",
      "SK 860",
      "Snk 860",
      "Aldos (TN)",
      "SNK-860",
      "Fidarestat (JAN/INN)",
      "(+)-(2S,4S)-6-Fluoro-2',5'-Dioxospiro(Chroman-4,4'-Imidazolidine)-2-Carboxamide",
      "(+)-(2s,4s)-6-Fluoro-2',5'-Dioxospiro[chroman-4,4'-Imidazolidine]-2-Carboxamide",
      "(2S,4S)-2-AMINOFORMYL-6-FLUORO-SPIRO[CHROMAN-4,4'-IMIDAZOLIDINE]-2',5'-DIONE",
      "(2S,4S)-6-Fluoro-2',5'-Dioxospiro(3,4-Dihydro-2H-1-Benzopyran-4,4'-Imidazolidine)-2-Carboxamide",
      "(2S,4S)-6-Fluoro-2',5'-Dioxospiro(Chroman-4,4'-Imidazolidine)-2-Carboxamide",
      "(2S,4S)-6-Fluoro-2',5'-Dioxospiro[2,3-Dihydrochromene-4,4'-Imidazolidine]-2-Carboxamide",
    ],
  },
  {
    primary_id: "D00EXZ",
    names: ["PB-357"],
  },
  {
    primary_id: "D00EYC",
    names: ["F-7TG"],
  },
  {
    primary_id: "D00EYF",
    names: ["PEGylated Insulin"],
  },
  {
    primary_id: "D00EYY",
    names: ["PMID16242323C18a"],
  },
  {
    primary_id: "D00EZE",
    names: ["Panamesine"],
  },
  {
    primary_id: "D00EZO",
    names: ["TC-2216"],
  },
  {
    primary_id: "D00FAE",
    names: ["H-DArg-Arg-Pro-Hyp-Gly-Igl-Ser-D-BT-OH(JMV1638)", "CHEMBL216618", "JMV 1638"],
  },
  {
    primary_id: "D00FAL",
    names: ["ISIS 11159"],
  },
  {
    primary_id: "D00FAW",
    names: [
      "CL-508",
      "866155-89-7",
      "2-[(5-{[3-(Trifluoromethoxy)Benzoyl]amino}-1,3,4-Thiadiazol-2-Yl)Sulfanyl]acetic Acid",
      "2-[[5-[[3-(Trifluoromethoxy)Benzoyl]amino]-1,3,4-Thiadiazol-2-Yl]sulfanyl]acetic Acid",
      "AC1MU807",
      "ZINC4107172",
      "AKOS005107951",
      "MCULE-3359225154",
      "MS-2050",
      "KS-00002829",
      "SR-01000309935",
      "SR-01000309935-1",
      "2-({5-[3-(Trifluoromethoxy)Benzamido]-1,3,4-Thiadiazol-2-Yl}sulfanyl)Acetic Acid",
    ],
  },
  {
    primary_id: "D00FAY",
    names: ["4-(3-Thienyl)Benzene-1,2-Diamine)"],
  },
  {
    primary_id: "D00FBC",
    names: ["KHK-6188"],
  },
  {
    primary_id: "D00FBM",
    names: ["LP-2307"],
  },
  {
    primary_id: "D00FCF",
    names: ["2,7-Bis(Cyclohexanecarbonamido)Anthraquinone", "CHEMBL479981"],
  },
  {
    primary_id: "D00FCJ",
    names: [
      "N5-[4-(3-Methylphenoxy)Phenyl]-L-Glutamamide",
      "CHEMBL446500",
      "Modified Amino Acid Analog, 9d",
      "BDBM24281",
    ],
  },
  {
    primary_id: "D00FCV",
    names: ["Inhaled Liposomal Ciprofloxacin"],
  },
  {
    primary_id: "D00FCW",
    names: ["ViaScint", "123I-Phenylpentadecanoic Acid, Medco/Nordion"],
  },
  {
    primary_id: "D00FDD",
    names: ["INT-0020"],
  },
  {
    primary_id: "D00FDS",
    names: ["(R)-2-((4-Phenoxyphenoxy)Methyl)Piperidine", "CHEMBL1099181", "SCHEMBL13809073"],
  },
  {
    primary_id: "D00FEA",
    names: ["GRL-0036A"],
  },
  {
    primary_id: "D00FEH",
    names: ["Mesuagenin D"],
  },
  {
    primary_id: "D00FEY",
    names: ["ISIS 110074"],
  },
  {
    primary_id: "D00FGA",
    names: ["Pyrrolidine-1-Carbonitrile"],
  },
  {
    primary_id: "D00FGB",
    names: [
      "2-Acetamido-5-Sulfonamidoindane",
      "CHEMBL184674",
      "N-(5-Sulfamoyl-Indan-2-Yl)-Acetamide",
      "Indanesulfonamide Derivative 6",
      "SCHEMBL1971499",
      "N-(5-Sulfamoyl-2,3-Dihydro-1H-Inden-2-Yl)Acetamide",
      "BDBM11029",
      "CTK6A0685",
      "RVPDXKXWYPPTAL-UHFFFAOYSA-N",
      "N-Acetyl-5-Sulfamyl-2-Indanamine",
      "Acetamide, N-[5-(Aminosulfonyl)-2,3-Dihydro-1H-Inden-2-Yl]-",
      "AKOS015966079",
      "74124-93-9",
    ],
  },
  {
    primary_id: "D00FGO",
    names: [
      "Melphalan",
      "Alkeran",
      "Levofalan",
      "Levofolan",
      "Levopholan",
      "Melfalan",
      "Melfalano",
      "Melphalanum",
      "Alanine Nitrogen Mustard",
      "Phenylalanine Mustard",
      "Phenylalanine Nitrogen Mu Stard",
      "Phenylalanine Nitrogen Mustard",
      "AY3360000",
      "CB 3025",
      "ALKERAN (TN)",
      "Alkeran (TN)",
      "At-290",
      "CB-3025",
      "L-PAM",
      "L-Phenylalanine Mustard",
      "L-Sarcolysin",
      "L-Sarcolysine",
      "L-Sarkolysin",
      "Melfalano [INN-Spanish]",
      "Melphalanum [INN-Latin]",
      "SK-15673",
      "TRANSGENIC MODEL EVALUATION (MELPHALAN)",
      "MELPHALAN (SEE ALSO TRANSGENIC MODEL EVALUATION (MELPHALAN))",
      "P-L-Sarcolysin",
      "P-L-Sarcolysine",
      "TRANSGENIC LEP (MELPHALAN) (SEE ALSO MELPHALAN)",
      "Melphalan (JP15/USP/INN)",
      "Melphalan [USAN:INN:BAN:JAN]",
      "P-Bis(Beta-Chloroethyl)Aminophenylalanine",
      "P-N-Di(Chloroethyl)Aminophenylalanine",
      "P-N-Di(Chloroethyl)Aminophenylala Nine",
      "P-Di-(2-Chloroethyl)Amino-L-Phenylalanine",
      "P-N-Bis(2-Chloroethyl)Amino-L-Phenylalanine",
      "L-3-(P-(Bis(2-Chloroethyl)Amino)Phenyl)Alanine",
      "L-3-(Para-(Bis(2-Chloroethyl)Amino)Phenyl)Alanine",
      "P-N,N-Bis(2-Chloroethyl)Amino-L-Phenylalanine",
      "(2S)-2-Amino-3-[4-[bis(2-Chloroethyl)Amino]phenyl]propanoic Acid",
      "(2s)-2-Amino-3-(4-[bis(2-Chloroethyl)Amino]phenyl)Propanoic Acid",
      "3-(P-(Bis(2-Chloroethyl)Amino)Phenyl)-L-Alanine",
      "3-(P-(Bis(2-Chloroethyl)Amino)Phenyl)Alanine",
      "3-P-(Di(2-Chloroethyl)Amino)-Phenyl-L-Alanine",
      "4-(Bis(2-Chloroethyl)Amino)-L-Phenylalanine",
      "4-[Bis(2-Chloroethyl)Amino]-L-Phenylalanine",
      "4-[Bis-(2-Chloroethyl)Amino]-L-Phenylalanine",
    ],
  },
  {
    primary_id: "D00FGR",
    names: ["Miltefosine"],
  },
  {
    primary_id: "D00FGV",
    names: [
      "Carbinoxamine",
      "Allergefon",
      "Carbinoxamina",
      "Carbinoxaminum",
      "Clistin",
      "Clistine",
      "Paracarbinoxamine",
      "Paracarinoxamine",
      "Rotoxamine",
      "Carbinoxamine Base",
      "Carbinoxamina [INN-Spanish]",
      "Carbinoxamine (INN)",
      "Carbinoxamine [INN:BAN]",
      "Carbinoxaminum [INN-Latin]",
      "Clistin (TN)",
      "McN-R 73Z",
      "Palgic (TN)",
      "{2-[(4-Chlorophenyl)-2-Pyridylmethoxy]ethyl}dimethylamine",
      "N,N-Dimethyl-2-(P-Chloro-Alpha-(2-Pyridyl)Benzyloxy)Ethylamine",
      "Ethanamine, 2-((4-Chlorophenyl)-2-Pyridinylmethoxy)-N,N-Dimethyl-(9CI)",
      "(+-)-Carbinoxamine",
      "2-(P-Chloro-Alpha-(2-(Dimethylamino)Ethoxy)Benzyl)Pyridine",
      "2-[(4-Chlorophenyl)(Pyridin-2-Yl)Methoxy]-N,N-Dimethylethanamine",
      "2-[(4-Chlorophenyl)-Pyridin-2-Ylmethoxy]-N,N-Dimethylethanamine",
      "2-{[(4-Chlorophenyl)(Pyridin-2-Yl)Methyl]oxy}-N,N-Dimethylethanamine",
    ],
  },
  {
    primary_id: "D00FGW",
    names: ["Anthrax Transdermal Vaccine"],
  },
  {
    primary_id: "D00FGY",
    names: ["PF-755616"],
  },
  {
    primary_id: "D00FHI",
    names: ["Zabiciprilat"],
  },
  {
    primary_id: "D00FHR",
    names: ["MGCD-0103"],
  },
  {
    primary_id: "D00FHV",
    names: ["Iva-His-Pro-Phe-His-ACHPA-Leu-Phe-NH2"],
  },
  {
    primary_id: "D00FHZ",
    names: ["CP 93129", "CP-93129", "CP93129", "CP-93,129"],
  },
  {
    primary_id: "D00FIE",
    names: ["2-(2-Hydroxyethyl)Anthra[1,9-Cd]pyrazol-6(2H)-One"],
  },
  {
    primary_id: "D00FIO",
    names: ["Anti-CD20-CAR-Transduced T Cells"],
  },
  {
    primary_id: "D00FIT",
    names: [
      "3-(6-Amino-Purin-9-Yl)-6-Phenyl-Hexan-2-Ol",
      "CHEMBL295417",
      "SCHEMBL7459663",
      "ZINC13835544",
      "BDBM50070642",
      "(2S,3R)-3-(6-Amino-Purin-9-Yl)-6-Phenyl-Hexan-2-Ol",
    ],
  },
  {
    primary_id: "D00FIV",
    names: ["Leukotoxin"],
  },
  {
    primary_id: "D00FIX",
    names: ["Agalsidase Beta"],
  },
  {
    primary_id: "D00FJC",
    names: ["Nuvanil"],
  },
  {
    primary_id: "D00FJH",
    names: ["N-Acylpiperidine Ether Derivative 4"],
  },
  {
    primary_id: "D00FJQ",
    names: ["Leucine + Metformin"],
  },
  {
    primary_id: "D00FJS",
    names: ["KRH-2731"],
  },
  {
    primary_id: "D00FJT",
    names: ["[11C]volinanserin", "[11C]M100907 (PET Ligand)", "[11C]MDL100907"],
  },
  {
    primary_id: "D00FKF",
    names: ["Pyrrolo-Pyridine Derivative 2"],
  },
  {
    primary_id: "D00FKJ",
    names: ["(1S,2R)-N-Oleoylcyclopropanolamide"],
  },
  {
    primary_id: "D00FLQ",
    names: ["PD-140547"],
  },
  {
    primary_id: "D00FLT",
    names: ["Sinefungin"],
  },
  {
    primary_id: "D00FLZ",
    names: ["CFGGFTGARKCARK"],
  },
  {
    primary_id: "D00FMK",
    names: ["SDZ-LAV-694"],
  },
  {
    primary_id: "D00FMQ",
    names: ["FX-300"],
  },
  {
    primary_id: "D00FMV",
    names: ["Putrescine"],
  },
  {
    primary_id: "D00FMW",
    names: ["TIM ETEC Vaccine"],
  },
  {
    primary_id: "D00FMX",
    names: ["3-(Piperidin-4-Yl)-2-(O-Tolyloxy)Pyridine", "CHEMBL596714"],
  },
  {
    primary_id: "D00FOE",
    names: [
      "COSALANE",
      "Cosalane",
      "AC1L2I0J",
      "CHEMBL300101",
      "CTK6A7531",
      "BDBM50036146",
      "LS-187298",
      "5-[1-(3-Carboxy-5-Chloro-4-Hydroxyphenyl)-4-[(3S,10S,13R,17R)-10,13-Dimethyl-17-(6-Methylheptan-2-Yl)-2,3,4,5,6,7,8,9,11,12,14,15,16,17-Tetradecahydro-1H-Cyclopenta[a]phenanthren-3-Yl]but-1-Enyl]-3-Chloro-2-Hydroxybenzoic Acid",
      "5-{1-(3-Carboxy-5-Chloro-4-Hydroxyphenyl-4-[1-(1,5-Dimethylhexyl)-9a,11a-Dimethylperhydrocyclopenta[a]phenanthren-7-Yl]-1-Butenyl}-3-Chloro-2-Hydroxy Acetic Acid (Cosalane)",
    ],
  },
  {
    primary_id: "D00FOR",
    names: [
      "NBI-80649",
      "Adenosine A2a Receptor Antagonists (Parkinsons)",
      "Adenosine A2a Receptor Antagonists (Parkinsons), Almirall/Neurocrine",
    ],
  },
  {
    primary_id: "D00FOZ",
    names: ["Propan-2-One O-3-Butoxyphenylcarbamoyl Oxime", "CHEMBL597130"],
  },
  {
    primary_id: "D00FPB",
    names: [
      "4-Hydroxy-3-Methyl-Butyric Acid",
      "4-Hydroxyisovaleric Acid",
      "4-Hydroxyisovalerate",
      "4-Hydroxyisopentanoate",
      "4-Hydroxyisopentanoic Acid",
      "4-Hydroxy-3-Methylbutanoic Acid",
      "4-Hydroxy-Isovaleric Acid",
      "77220-86-1",
      "CHEBI:90005",
      "3-Methyl-4-Hydroxy-Butanoic Acid",
      "AC1Q5V9X",
      "AC1Q2B3C",
      "AC1L2ZR2",
      "SCHEMBL43435",
      "CHEMBL353863",
      "CTK5E4050",
      "Butanoic Acid,4-Hydroxy-3-Methyl-",
      "Butanoic Acid, 4-Hydroxy-3-Methyl-",
      "LMFA01050399",
      "AKOS006373039",
      "4-Hydroxyisovaleric Acid Sodium Salt Hydrate, Analytical Standard",
    ],
  },
  {
    primary_id: "D00FPG",
    names: [
      "1-Methyl-1H-Indole-3-Carboxamide",
      "118959-44-7",
      "1H-Indole-3-Carboxamide, 1-Methyl-",
      "1-Methylindole-3-Carboxamide",
      "CHEMBL382602",
      "AC1LGL0W",
      "ACMC-20mo37",
      "MLS001049055",
      "SCHEMBL2174829",
      "CTK0F9691",
      "DTXSID00356397",
      "MolPort-002-560-820",
      "UHQHFXKJFJHBAE-UHFFFAOYSA-N",
      "HMS2271M13",
      "ZINC341025",
      "BDBM50427552",
      "STK721948",
      "AKOS009024585",
      "MCULE-8790085472",
      "AS-9399",
      "SMR000386893",
      "AJ-19941",
      "KB-219182",
      "FT-0721669",
    ],
  },
  {
    primary_id: "D00FPI",
    names: ["1,4-Di(Berberine-9-O-Yl)Ethane Dibromide", "CHEMBL1165194"],
  },
  {
    primary_id: "D00FPJ",
    names: ["3,4,6-Trihydroxy-2-(4-Hydroxy-Phenyl)-Inden-1-One"],
  },
  {
    primary_id: "D00FPT",
    names: ["7-Methyl-Guanosine-5'-Triphosphate"],
  },
  {
    primary_id: "D00FQP",
    names: ["LAGATIDE", "BN-52080", "Lagatide", "Prolyl-Valyl-Threonyl-Lysyl-Prolyl-Glutaminyl-D-Alaninamide"],
  },
  {
    primary_id: "D00FRQ",
    names: ["Actinomycetes Metabolites", "Actinomycetes Metabolites (Tuberculosis Infection)"],
  },
  {
    primary_id: "D00FRX",
    names: [
      "CTA-056",
      "CTA-151",
      "CTA-187",
      "ITK Inhibitors (Cancer)",
      "ITK Inhibitors (Cancer), University Of California Davis",
    ],
  },
  {
    primary_id: "D00FRY",
    names: ["BILD-1351", "BILD-1351-SE"],
  },
  {
    primary_id: "D00FSB",
    names: ["BIIB095"],
  },
  {
    primary_id: "D00FSS",
    names: ["SPD-452"],
  },
  {
    primary_id: "D00FSV",
    names: ["Phytonadione"],
  },
  {
    primary_id: "D00FTQ",
    names: ["3-Hydroxyoctanoic Acid", "3-Hydroxyoctanoate", "3-OH-Octanoic Acid"],
  },
  {
    primary_id: "D00FUD",
    names: ["HRA-081025"],
  },
  {
    primary_id: "D00FUO",
    names: ["NAFOXIDINE"],
  },
  {
    primary_id: "D00FUS",
    names: [
      "2-(2-Methoxy-Phenyl)-1-Methyl-Ethylamine",
      "1-(2-Methoxyphenyl)Propan-2-Amine",
      "15402-84-3",
      "N-Desmethylmethoxyphenamine",
      "2-Methoxyamphetamine",
      "O-Methoxyamphetamine",
      "NDMP",
      "CHEMBL16451",
      "Benzeneethanamine, 2-Methoxy-Alpha-Methyl-",
      "Benzeneethanamine,2-Methoxy--Methyl-",
      "1-(2-METHOXYPHENYL)-2-PROPYLAMINE",
      "AC1L4MFB",
      "AC1Q57OA",
      "AC1Q45CZ",
      "(D,l)-2-Methoxyamphetamine",
      "Methoxyphenamine, N-Desmethyl",
      "SCHEMBL266563",
      "O-Methoxy(Phenylisopropyl)Amine",
      "AMBZ0414",
      "AC1Q45D0",
      "2-Methoxy(Phenylisopropyl)Amine",
      "O-Methoxy-A-Methylphenethylamine",
    ],
  },
  {
    primary_id: "D00FUT",
    names: [
      "[Leu8,des-Arg9]bradykinin",
      "8-Leu-9-Des-Arg-BK",
      "8-Leu-9-De-Arg-Bradykinin",
      "Bradykinin, Leu(8) (1-8)",
      "Bradykinin, Leu(8)-Des-Arg(9)-",
      "Bradykinin, Leucyl(8)-Des-Arginine(9)-",
      "CHEMBL80472",
      "64695-06-3",
      "Bradykinin, 8-L-Leucine-9-De-L-Arginine-",
      "Arg-Pro-Pro-Gly-Phe-Ser-Pro-Leu",
      "(2S)-2-[[(2S)-1-[(2S)-2-[[(2S)-2-[[2-[[(2S)-1-[(2S)-1-[(2S)-2-Amino-5-(Diaminomethylideneamino)Pentanoyl]pyrrolidine-2-Carbonyl]pyrrolidine-2-Carbonyl]amino]acetyl]amino]-3-Phenylpropanoyl]amino]-3-Hydroxypropanoyl]pyrrolidine-2-Carbonyl]amino]-4-Methylpentanoic Acid",
    ],
  },
  {
    primary_id: "D00FUZ",
    names: ["Quinoline Carboxamide Derivative 2"],
  },
  {
    primary_id: "D00FWB",
    names: ["L-163017"],
  },
  {
    primary_id: "D00FXL",
    names: ["H-Aba-Ser-Gly-Phe-Leu-Thr-OH", "CHEMBL246479"],
  },
  {
    primary_id: "D00FXQ",
    names: ["IG-CN-023"],
  },
  {
    primary_id: "D00FYF",
    names: ["NVP-DPP728"],
  },
  {
    primary_id: "D00FYP",
    names: ["PG-36"],
  },
  {
    primary_id: "D00FZA",
    names: ["ALD-805"],
  },
  {
    primary_id: "D00FZN",
    names: ["Tyr-(R)-Aba-Gly-Phe-NH2", "CHEMBL250448"],
  },
  {
    primary_id: "D00FZZ",
    names: ["PEG-FGF-21"],
  },
  {
    primary_id: "D00GAM",
    names: ["Beclanorsen"],
  },
  {
    primary_id: "D00GAV",
    names: ["AR-2474", "AR-2475", "AR-3752", "Topical Antibiotic, Arpida"],
  },
  {
    primary_id: "D00GBB",
    names: ["6-(3-Benzoyl-Ureido)-Hexanoic Acid Hydroxyamide"],
  },
  {
    primary_id: "D00GBG",
    names: ["Resp Syncytial Virus Ig"],
  },
  {
    primary_id: "D00GBP",
    names: ["Tensirolimus"],
  },
  {
    primary_id: "D00GBX",
    names: ["Prifelone"],
  },
  {
    primary_id: "D00GBZ",
    names: [
      "CEQ-508",
      "CEQ-501",
      "TkRNAis (Oral, Familial Adenomatous Polyposis/Colon Cancer), Cequent",
      "TkRNAis (Oral, Familial Adenomatous Polyposis/Colon Cancer), Marina",
    ],
  },
  {
    primary_id: "D00GCA",
    names: ["MK-7288"],
  },
  {
    primary_id: "D00GCC",
    names: ["MCL0129"],
  },
  {
    primary_id: "D00GCK",
    names: [
      "3-Fluoren-9-Ylidenemethyl-Pyridine",
      "3-(9h-Fluoren-9-Ylidenemethyl)Pyridine",
      "3-(Fluoren-9-Ylidenemethyl)Pyridine",
      "3239-00-7",
      "NSC83320",
      "AC1Q4YVX",
      "AC1L5UM9",
      "NCIOpen2_004633",
      "CHEMBL194928",
      "BDBM8637",
      "SCHEMBL8276088",
      "CTK1C4120",
      "(3-Pyridylmethylene)Fluorene 32",
      "DTXSID10292510",
      "NSC-83320",
      "ZINC13283158",
      "AKOS030540073",
      "3-(9H-Fluorene-9-Ylidenemethyl)Pyridine",
    ],
  },
  {
    primary_id: "D00GCN",
    names: [
      "2-Naphthalen-2-Yl-1H-[1,8]naphthyridin-4-One",
      "CHEMBL298328",
      "NSC679017",
      "AC1L8RZV",
      "SCHEMBL7913593",
      "CTK8D2145",
      "ZINC5541468",
      "BDBM50059980",
      "NSC-679017",
      "NCI60_028216",
      "2-(2-Naphthyl)-1,8-Naphthyridin-4(1H)-One",
      "2-Naphthalen-2-Yl-1H-1,8-Naphthyridin-4-One",
    ],
  },
  {
    primary_id: "D00GDI",
    names: [
      "2-(N-Tert-Butylamino)-3',4'-Dichloropropiophenone",
      "CHEMBL568140",
      "SCHEMBL770039",
      "BDBM50302918",
      "AKOS022330446",
      "2-(N-Tert-Butylamino)-3'',4''-Dichloropropiophenone",
    ],
  },
  {
    primary_id: "D00GDN",
    names: ["ML-03", "Cystic Fibrosis Therapy, Milkhaus", "HP-3", "Respiratory Therapeutics, Milkhaus"],
  },
  {
    primary_id: "D00GEE",
    names: ["Corynanthine"],
  },
  {
    primary_id: "D00GEG",
    names: ["Ralfinamide"],
  },
  {
    primary_id: "D00GES",
    names: ["JD-5037"],
  },
  {
    primary_id: "D00GFU",
    names: [
      "BTma22",
      "BTma311",
      "BTma47",
      "BTma512",
      "BTma84",
      "Vitamin B12 Monoclonal Antibodies (Cancer)",
      "Vitamin B12 Monoclonal Antibodies, Kyto",
      "Transcobalamin II (TcII) Monoclonal Antibodies, B Twelve",
      "Vitamin B12 Monoclonal Antibodies (Cancer), Kyto",
      "T-12 Development (HuMab Mouse), Kyto",
      "Transcobalamin Monoclonal Antibodies (Autoimmune Disease/Cancer), Kyto",
    ],
  },
  {
    primary_id: "D00GFV",
    names: ["Leridistim"],
  },
  {
    primary_id: "D00GGB",
    names: ["Peptide Analog 37"],
  },
  {
    primary_id: "D00GHB",
    names: [
      "Carboxylic PRPP",
      "CARBOXYLIC PRPP",
      "CPRPP",
      "1-ALPHA-PYROPHOSPHORYL-2-ALPHA,3-ALPHA-DIHYDROXY-4-BETA-CYCLOPENTANE-METHANOL-5-PHOSPHATE",
      "AC1L9FVP",
      "DB03942",
      "(1S,2R,3R,4R)-2,3-Dihydroxy-4-[(Phosphonooxy)Methyl]cyclopentyl Trihydrogen Diphosphate",
      "[(1S,2R,3R,4R)-2,3-Dihydroxy-4-(Phosphonooxymethyl)Cyclopentyl] Phosphono Hydrogen Phosphate",
    ],
  },
  {
    primary_id: "D00GHG",
    names: ["VAX-102"],
  },
  {
    primary_id: "D00GHK",
    names: [
      "13,14-Dihydro-15-Keto-PGD2",
      "13,14-Dihydro-15-Keto-Prostaglandin D2",
      "13,14-Dihydro-15-Ketoprostaglandin D2",
      "DK-PGD2",
      "13,14-Dihydro-15-Oxo PGD2",
    ],
  },
  {
    primary_id: "D00GHZ",
    names: [
      "Antiandrogens",
      "Antiandrogens (Prostate Cancer)",
      "EM-4350",
      "EM-5854",
      "EM-6537",
      "Antiandrogens (Prostate Cancer), Endoceutics",
      "Androgen Receptor Antagonists (Endocrine-Related Disorders), Endoceutics",
    ],
  },
  {
    primary_id: "D00GIB",
    names: [
      "2-(4-Bromo-3-Phenoxy-Benzoylamino)-Benzoic Acid",
      "CHEMBL178585",
      "BDBM23581",
      "2-(4-Bromo-3-Phenoxybenzoylamino)Benzoic Acid",
      "2-[(4-Bromo-3-Phenoxybenzene)Amido]benzoic Acid",
      "(3-Phenoxybenzoylamino)Benzoic Acid Deriv., 23b",
    ],
  },
  {
    primary_id: "D00GII",
    names: ["Cyclo(-D-Tyr-L-Arg-L-MeArg-L-Nal-Gly-)", "CHEMBL436536"],
  },
  {
    primary_id: "D00GIK",
    names: [
      "1-(3-(4-Chlorophenyl)Propyl)-1H-Imidazole",
      "CHEMBL213493",
      "1-[3-(4-Chlorophenyl)Propyl]-1H-Imidazole",
      "1H-Imidazole,1-[3-(4-Chlorophenyl)Propyl]-",
      "SCHEMBL20570009",
      "BDBM50191792",
    ],
  },
  {
    primary_id: "D00GIR",
    names: ["DAC-060", "DAC-0060", "DAC-60", "HDAC Inhibitor (Topical, Skin Cancer/Actinic Keratosis), DAC"],
  },
  {
    primary_id: "D00GIU",
    names: ["NSC-238146"],
  },
  {
    primary_id: "D00GIW",
    names: [
      "1-(3,5-Dibromobenzyl)-1H-Imidazole",
      "CHEMBL211276",
      "528543-94-4",
      "CTK1G1925",
      "DTXSID30658795",
      "BDBM50188091",
      "1-[(3,5-Dibromophenyl)Methyl]-1H-Imidazole",
      "1H-Imidazole, 1-[(3,5-Dibromophenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D00GJK",
    names: [
      "Depigoid Birch Pollen Extract Vaccine",
      "Depigoid Birch Pollen Extract Vaccine (Sc, Rhinitis/Rhinoconjunctivitis)",
    ],
  },
  {
    primary_id: "D00GJY",
    names: ["Cyclohexyl Azetidine Derivative 1"],
  },
  {
    primary_id: "D00GJZ",
    names: ["N-Methylmesoporphyrin Containing Copper"],
  },
  {
    primary_id: "D00GKE",
    names: ["ISIS 113909"],
  },
  {
    primary_id: "D00GKH",
    names: [
      "HG-1179",
      "AG2 Modulator (Cancer), Human Genome Sciences",
      "AGR2 Modulator (Cancer), Human Genome Sciences",
      "Anterior Gradient Homolog 2 Modulator (Cancer), Human Genome Sciences",
      "HAG2 Modulator (Cancer), Human Genome Sciences",
      "GOB-4 Modulator (Cancer), Human Genome Sciences",
      "XAG-1 Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D00GLJ",
    names: ["PMID28092474-Compound-32u"],
  },
  {
    primary_id: "D00GLQ",
    names: [
      "2LM20-4",
      "Anti-CD20 SMIP (Autoimmune Disease), Pfizer",
      "Anti-CD20 Small Modular Immunopharmaceutical (Autoimmune Disease), Pfizer",
    ],
  },
  {
    primary_id: "D00GLW",
    names: ["OM-294-DP"],
  },
  {
    primary_id: "D00GMI",
    names: ["PMID25726713-Compound-36"],
  },
  {
    primary_id: "D00GMJ",
    names: ["Azaindole Derivative 5"],
  },
  {
    primary_id: "D00GNI",
    names: [
      "REGN-727",
      "Alirocumab",
      "Alirocumab)",
      "RENG-727",
      "Anti-PCSK9 Antibody (Hypercholesterolemia), Regeneron/Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D00GNJ",
    names: ["Linaclotide"],
  },
  {
    primary_id: "D00GNK",
    names: ["LY293111"],
  },
  {
    primary_id: "D00GNN",
    names: ["P53 Mdm-2-Binding Domain Peptides"],
  },
  {
    primary_id: "D00GNP",
    names: ["Triazole Piperazine Derivative 1"],
  },
  {
    primary_id: "D00GNW",
    names: ["2,6-Diphenyl-8-(1-Ethylpropyl)-1-Deazapurine", "CHEMBL385109"],
  },
  {
    primary_id: "D00GOC",
    names: ["Pyrazofurin"],
  },
  {
    primary_id: "D00GOD",
    names: ["6-Amino-2-(1H-Imidazol-4-Yl)-Hexanoic Acid", "CHEMBL65910", "SCHEMBL7257200"],
  },
  {
    primary_id: "D00GOH",
    names: ["HepeX-B"],
  },
  {
    primary_id: "D00GOM",
    names: ["2,2-Dimethylbenzopyran Derivative 2"],
  },
  {
    primary_id: "D00GOQ",
    names: ["Alpha-Methylcubebin", "CHEMBL520915"],
  },
  {
    primary_id: "D00GOS",
    names: [
      "Clocortolone",
      "Clocortolona",
      "Clocortolonum",
      "Clocortolone (INN)",
      "(6S,8S,9R,10S,11S,13S,14S,16R,17S)-9-Chloro-6-Fluoro-11-Hydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-7,8,11,12,14,15,16,17-Octahydro-6H-Cyclopenta[a]phenanthren-3-One",
      "9-Chloro-6alpha-Fluoro-11beta,21-Dihydroxy-16alpha-Methyl-1,4-Pregnadien-3,20-Dione",
      "9-Chloro-6alpha-Fluoro-11beta,21-Dihydroxy-16alpha-Methylpregna-1,4-Diene-3,20-Dione",
      "9-Chloro-6alpha-Fluoro-16alpha-Methyl-1,4-Pregnadiene-11beta,21-Diol-3,20-Dione",
    ],
  },
  {
    primary_id: "D00GOV",
    names: ["Nivolumab"],
  },
  {
    primary_id: "D00GOY",
    names: ["VER-3323"],
  },
  {
    primary_id: "D00GPQ",
    names: ["PF-03084014"],
  },
  {
    primary_id: "D00GPY",
    names: ["OMP-18R5"],
  },
  {
    primary_id: "D00GPZ",
    names: ["MCD Inhibitors"],
  },
  {
    primary_id: "D00GQC",
    names: ["Sterculic Acid"],
  },
  {
    primary_id: "D00GQI",
    names: [
      "N-(2,6-Diphenylpyrimidin-4-Yl)Isobutyramide",
      "CHEMBL222433",
      "820961-44-2",
      "SCHEMBL4822464",
      "CTK3E2659",
      "DTXSID20462085",
      "BDBM50157658",
      "Propanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-2-Methyl-",
    ],
  },
  {
    primary_id: "D00GQM",
    names: ["(4-(6-Morpholino-9H-Purin-2-Yl)Phenyl)Methanol", "CHEMBL594669", "SCHEMBL4442909"],
  },
  {
    primary_id: "D00GQP",
    names: ["AX-006"],
  },
  {
    primary_id: "D00GRN",
    names: ["5-Hydroxy-2-(Pentylthiomethyl)-4H-Pyran-4-One", "CHEMBL1269399"],
  },
  {
    primary_id: "D00GSD",
    names: ["LP-211", "Compound 25 [PMID 18800769]"],
  },
  {
    primary_id: "D00GSF",
    names: ["Di-Substituted Piperidine Derivative 3"],
  },
  {
    primary_id: "D00GTC",
    names: ["AS1269574", "AS 1269574", "AS-1269574"],
  },
  {
    primary_id: "D00GTU",
    names: ["STA2", "11alpha-Carba-9alpha,11beta-Thia-Txa2"],
  },
  {
    primary_id: "D00GTV",
    names: [
      "Guanylhydrazone",
      "7059-23-6",
      "AC1NS00Z",
      "2-[(E)-[(1E)-1-(Diaminomethylidenehydrazinylidene)Propan-2-Ylidene]amino]guanidine Chloride",
    ],
  },
  {
    primary_id: "D00GTY",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 28"],
  },
  {
    primary_id: "D00GUE",
    names: ["2-(2-Benzhydryl-4-Phenylthiazol-5-Yl)Acetic Acid", "CHEMBL603459", "SCHEMBL5056681", "BDBM50309282"],
  },
  {
    primary_id: "D00GUG",
    names: ["NSC-665126"],
  },
  {
    primary_id: "D00GUM",
    names: [
      "Dilazep",
      "35898-87-4",
      "Dilazep [INN]",
      "Dilazepum [INN-Latin]",
      "UNII-F8KLC2BD5Z",
      "Dilazepam [INN-Spanish]",
      "F8KLC2BD5Z",
      "CHEMBL126075",
      "Dilazep (INN)",
      "Benzoic Acid, 3,4,5-Trimethoxy-, (Tetrahydro-1H-1,4-Diazepine-1,4(5H)-Diyl)Di-3,1-Propanediyl Ester",
      "NCGC00016747-03",
      "AS 05",
      "DSSTox_RID_80871",
      "DSSTox_CID_25425",
      "DSSTox_GSID_45425",
      "Dilazepum",
      "Dilazepam",
      "Tetrahydro-1H-1,4-Diazepine-1,4(5H)-Dipropanol 3,4,5-Trimethoxybenzoate (Diester)",
      "3-{4-[3-(3,4,5-Trimethoxybenzoyloxy)Propyl]-1,4-Diazepan-1-Yl}propyl 3,4,5-Trimethoxybenzoate",
    ],
  },
  {
    primary_id: "D00GVA",
    names: ["4-(2-Pyridin-4-Ylacetamido)Benzenesulfonamide"],
  },
  {
    primary_id: "D00GVV",
    names: ["Indolin-2-One Deriv. 4b"],
  },
  {
    primary_id: "D00GWE",
    names: ["Bidentate Ligands Of Markush Derivative 2"],
  },
  {
    primary_id: "D00GWR",
    names: ["CT301"],
  },
  {
    primary_id: "D00GXL",
    names: ["PBT-2", "AD/HD Therapy, Prana", "Alzheimers/Huntingtons Disease Therapy (Chelating Agent), Prana"],
  },
  {
    primary_id: "D00GYE",
    names: [
      "6-(4-Fluorobutyl)-4-Methylpyridin-2-Amine",
      "CHEMBL509542",
      "2-Pyridinamine, 6-(4-Fluorobutyl)-4-Methyl-",
      "BDBM50267355",
    ],
  },
  {
    primary_id: "D00GYX",
    names: [
      "BENZENEMETHANETHIOL",
      "BENZYL MERCAPTAN",
      "Benzenemethanethiol",
      "Phenylmethanethiol",
      "100-53-8",
      "Benzylthiol",
      "Thiobenzyl Alcohol",
      "Alpha-Toluenethiol",
      "Benzyl Hydrosulfide",
      "(Mercaptomethyl)Benzene",
      "Alpha-Mercaptotoluene",
      "Benzylhydrosulfide",
      "Alpha-Toluolthiol",
      "Phenylmethyl Mercaptan",
      "Benzylmercaptan",
      "Toluene-Alpha-Thiol",
      "Benzyl Thiol",
      "Methanethiol, Phenyl-",
      "Alpha-Tolyl Mercaptan",
      "Phenyl-Methanethiol",
      "USAF Ex-1509",
      ".alpha.-Toluenethiol",
      "Toluene, Alpha-Mercapto-",
      "UNII-OS34A21OBZ",
      "NSC 41897",
      "Benzyl-MERCAPTAN",
      ".alpha.-Toluolthiol",
      "FEMA No. 2147",
      "CCRIS 9",
    ],
  },
  {
    primary_id: "D00GZW",
    names: [
      "ONO-2506",
      "(2R)-2-Propyloctanoic Acid",
      "Arundic Acid",
      "185517-21-9",
      "(R)-2-PROPYLOCTANOIC ACID",
      "Proglia",
      "Cereact",
      "ONO 2506",
      "R-(-)-Arundic Acid",
      "UNII-F2628ZD0FO",
      "MK-0724",
      "(R)-(-)-2-Propyloctanoic Acid",
      "F2628ZD0FO",
      "NCGC00183861-01",
      "Acide Arundique",
      "Acido Arzndico",
      "Acidum Arundicum",
      "Arundic Acid [INN]",
      "Arocyte",
      "Acide Arundique [INN-French]",
      "Acidum Arundicum [INN-Latin]",
      "Arundic Acid (JAN/INN)",
      "Acido Arzndico [INN-Spanish]",
      "ONO-2506PO",
      "MK 0724",
      "AC1L4LMD",
      "AC1Q5QIF",
      "SCHEMBL4365",
      "DSSTox_RID_83036",
      "DSSTox_CID_28767",
      "DSSTox_GSID_48841",
      "ONO 2506PO",
      "Ono2506",
      "Acido Arundico [INN-Spanish]",
      "2-Propyloctanoic Acid",
      "FR149175",
    ],
  },
  {
    primary_id: "D00GZY",
    names: ["AT1001"],
  },
  {
    primary_id: "D00HAJ",
    names: ["Hoo-Phe-Orn-Pro-Hle-Pff-Phe-NH2"],
  },
  {
    primary_id: "D00HAM",
    names: ["Clostridial Collagenase"],
  },
  {
    primary_id: "D00HAN",
    names: ["IM23"],
  },
  {
    primary_id: "D00HAP",
    names: ["Dapagliflozin/Metformin"],
  },
  {
    primary_id: "D00HAZ",
    names: ["N-(Naphthalen-1-Yl)Benzo[d]isoxazol-3-Amine", "CHEMBL565960"],
  },
  {
    primary_id: "D00HBE",
    names: ["AZD0914"],
  },
  {
    primary_id: "D00HBF",
    names: [
      "CAB-103",
      "CDY-91",
      "CDY-92",
      "CMS-101",
      "CRA-102",
      "Anti-Midkine Antibodies (Cancer/RA/MS)",
      "Anti-Midkine Antibodies (Cancer/RA/MS), Cellmid",
      "Anti-Midkine Antibodies (Rheumatoid Arthritis /Multiple Sclerosis/Restenosis), Cellmid",
    ],
  },
  {
    primary_id: "D00HBR",
    names: [
      "AIKO-150",
      "6beta-Naltrexol",
      "Beta-Naltrexol",
      "6beta-Hydroxynaltrexone",
      "6alpha-Hydroxynaltrexone",
      "49625-89-0",
      "6alpha-Naltrexol",
      "UNII-J0W963M37T",
      "CHEMBL140278",
      "J0W963M37T",
      "Naltrexol",
      "Naltrexone-6-Beta-Ol",
      "Alpha-Naltrexol",
      "6beta-Naltrexone",
      "6alpha-Naltrexone",
      "6-Alpha-Naltrexol",
      "N-Cyclopropylmethyl-7,8-Dihydro-14-Hydroxynorisomorphine",
      "SCHEMBL679700",
      "DTXSID80197942",
      "JLVNEHKORQFVQJ-PYIJOLGTSA-N",
      "ZINC6092289",
      "BDBM50001709",
      "NCGC00165851-01",
      "FT-0672600",
      "Opioid Neutral Antagonists (Iv, Pain), Aiko Biotechnology",
      "6-Beta-Naltrexol",
    ],
  },
  {
    primary_id: "D00HCC",
    names: [
      "1-(2-Phenoxyphenyl)Piperazine",
      "CHEMBL573532",
      "SCHEMBL546735",
      "VAXHGPCUAFNAQS-UHFFFAOYSA-N",
      "1-(2-Phenoxy-Phenyl)-Piperazine",
      "ZINC37635596",
      "BDBM50299785",
    ],
  },
  {
    primary_id: "D00HCI",
    names: ["2-APB", "2-Aminoethoxydiphenyl Borate", "2-Aminoethoxydiphenylborane"],
  },
  {
    primary_id: "D00HCK",
    names: ["DPPA-2"],
  },
  {
    primary_id: "D00HCM",
    names: ["Metallo-Levodopa"],
  },
  {
    primary_id: "D00HCQ",
    names: [
      "Capecitabine",
      "Capecitabin",
      "Capecitabina",
      "Capecitabinum",
      "Capecitibine",
      "Capiibine",
      "Caxeta",
      "Xabine",
      "Xeloda",
      "Capecitabine [USAN]",
      "R340;R-340",
      "RG-340",
      "Ro 09-1978",
      "Xeloda (TN)",
      "Ro 09-1978/000",
      "Ro-09-1978",
      "Xeloda, Captabin, Capecitabine",
      "Capecitabine (JAN/USAN/INN)",
      "Ro-09-1978/000",
      "N(4)-Pentyloxycarbonyl-5'-Deoxy-5-Fluorocytidine",
      "Pentyl 1-(5-Deoxy-Beta-D-Ribofuranosyl)-5-Fluoro-1,2-Dihydro-2-Oxo-4-Pyrimidinecarbamate",
      "Pentyl [1-(5-Deoxy-Beta-D-Ribofuranosyl)-5-Fluoro-2-Oxo-1,2-Dihydropyrimidin-4-Yl]carbamate",
      "Carbamic Acid, (1-(5-Deoxy-Beta-D-Ribofuranosyl)-5-Fluoro-1,2-Dihydro-2-Oxo-4-Pyrimidinyl)-, Pentyl Ester",
      "Pentyl N-[1-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-Methyloxolan-2-Yl]-5-Fluoro-2-Oxopyrimidin-4-Yl]carbamate",
      "(1-(5-Deoxy-Beta-D-Ribofuranosyl)-5-Fluoro-1,2-Dihydro-2-Oxo-4-Pyrimidinyl)-Carbamic Acid Pentyl Ester",
      "5'-Deoxy-5-Fluoro-N-((Pentyloxy)Carbonyl)Cytidine",
      "5'-Deoxy-5-Fluoro-N-[(Pentyloxy)Carbonyl]cytidine",
      "Capecitabine (Fluoropyrimidine)",
    ],
  },
  {
    primary_id: "D00HDB",
    names: ["AC-105"],
  },
  {
    primary_id: "D00HDC",
    names: ["MCP-204", "Delta Opioid Receptor Agonist (Urology Therapy/Neuropathic Pain), Mt Cook Pharma"],
  },
  {
    primary_id: "D00HDH",
    names: ["Molecule 22"],
  },
  {
    primary_id: "D00HDK",
    names: ["PGX-5188"],
  },
  {
    primary_id: "D00HDU",
    names: ["Moexipril"],
  },
  {
    primary_id: "D00HEA",
    names: ["Transcobalamin (Vitamin B12) Receptor-Targeting MabTCR23-Saporin Conjugate"],
  },
  {
    primary_id: "D00HEN",
    names: ["Rotavirus Vaccine, , Lanzhou Institute Of Biological Products"],
  },
  {
    primary_id: "D00HET",
    names: ["6-Methoxy-2-(4-Phenoxy-Benzyl)-1H-Benzoimidazole", "CHEMBL67196"],
  },
  {
    primary_id: "D00HFD",
    names: [
      "E-6080",
      "E 6080",
      "120164-49-0",
      "E6080",
      "AC1L2UXT",
      "SCHEMBL3053845",
      "DTXSID10152706",
      "6-Hydroxy-2-(4-Sulfamoylbenzylamine)-4,5,7-Trimethylbenzothiazole Hcl",
      "6-Hydroxy-2-(4-Sulfamoylbenzylamino)-4,5,7-Trimethylbenzothiazole Hydrochloride",
      "Benzenesulfonamide, 4-(((6-Hydroxy-4,5,7-Trimethyl-2-Benzothiazolyl)Amino)Methyl)-, Monohydrochloride",
      "4-[[(6-Hydroxy-4,5,7-Trimethyl-1,3-Benzothiazol-2-Yl)Amino]methyl]benzenesulfonamide Hydrochloride",
    ],
  },
  {
    primary_id: "D00HFS",
    names: ["N-Hydroxy-N'-(4-Methylphenyl)Octanediamide", "CHEMBL1092762", "BDBM50314138"],
  },
  {
    primary_id: "D00HFT",
    names: ["10-Methoxy-N(1)-Methylburnamine-17-O-Veratrate", "CHEMBL592603"],
  },
  {
    primary_id: "D00HFV",
    names: [
      "N-[4'-(Trifluoromethyl)-4-Biphenylyl]thiourea",
      "CHEMBL243885",
      "SCHEMBL5427766",
      "VTGXCQVBAHHGLK-UHFFFAOYSA-N",
      "BDBM50220195",
    ],
  },
  {
    primary_id: "D00HGB",
    names: [
      "Niflumic Acid",
      "Actol",
      "Donalgin",
      "Flunir",
      "Forenol",
      "Landruma",
      "NFL",
      "Niflactol",
      "Niflam",
      "Niflugel",
      "Niflumate",
      "Nifluril",
      "Acide Niflumique",
      "Acide Niflumique [French]",
      "Acido Niflumico",
      "Acido Niflumico [Italian]",
      "Acidum Niflumicum",
      "Nifluminic Acid",
      "UPSA Conseil Brand Of Niflumic Acid",
      "Upsamedica Brand Of Niflumic Acid",
      "N 0630",
      "SC 1332",
      "UP 83",
      "UPSA Brand 1 Of Niflumic Acid",
      "UPSA Brand 2 Of Niflumic Acid",
      "Acid, Niflumic",
      "Acide Niflumique [INN-French]",
      "Acido Niflumico [INN-Spanish]",
      "Acidum Niflumicum [INN-Latin]",
      "Niflugel (TN)",
      "Niflumic Acid (INN)",
      "Niflumic Acid [INN:DCF]",
      "Aza-2 Dimethyl-2',3' (Tetrazolyl-5)-6 Diphenylamino",
      "Aza-2 Dimethyl-2',3' (Tetrazolyl-5)-6 Diphenylamino [French]",
      "2-(3-(Trifluoromethyl)-Phenyl)Aminonicotinic Acid",
      "2-(3-(Trifluoromethyl)Anilino)Nicotinic Acid",
      "2-(3-Trifluoromethyl-Phenylamino)-Nicotinic Acid",
      "2-(3-Trifluoromethylanilino)Nicotinic Acid",
      "2-(3-[Trifluoromethyl]anilino)Nicotinic Acid",
      "2-(A,A,A-Trifluoro-M-Toluidino)Nicotinic Acid",
      "2-(Alpha,alpha,alpha-Trifluoro-M-Toluidino)Nicotinic Acid",
      "2-[(3-TRIFLUOROMETHYL)PHENYL]AMINO-3-PYRIDINE-CARBOXYLIC ACID",
      "2-[(3-Trifluoromethyl)Phenyl]amino]-3-Pyridinecarboxylic Acid",
      "2-[(3-Trifluoromethylphenyl)Amino]nicotinic Acid",
      "2-[3-(Trifluoromethyl)Anilino]nicotinic Acid",
      "2-[3-(Trifluoromethyl)Anilino]pyridine-3-Carboxylic Acid",
      "2-[alpha,alpha,alpha-Trifluoro-M-Toluidino]-Nicotinic Acid",
      "2-{[3-(TRIFLUOROMETHYL)PHENYL]AMINO}NICOTINIC ACID",
      "2-{[3-(Trifluoromethyl)Phenyl]amino}pyridine-3-Carboxylic Acid",
      "39690A",
    ],
  },
  {
    primary_id: "D00HGF",
    names: ["ERYTHRITOL"],
  },
  {
    primary_id: "D00HGY",
    names: ["Insulin B Chain Vaccine"],
  },
  {
    primary_id: "D00HHK",
    names: ["PD-135478"],
  },
  {
    primary_id: "D00HHP",
    names: [
      "CP-111905",
      "CE-129941",
      "CE-156811",
      "CP-766386",
      "CP-919474",
      "CP-929898",
      "PF-327496",
      "PF-887298",
      "PF-904048",
      "Hygromycin Derivatives (Protozoal/Bacterial Infection)",
      "Hygromycin Derivatives (Protozoal/Bacterial Infection), Pfizer",
    ],
  },
  {
    primary_id: "D00HHS",
    names: [
      "Pseudoephedrine",
      "Balminil Decongestant Syrup",
      "Dimetapp Decongestant",
      "Dimetapp Decongestant Pediatric Drops",
      "Drixoral Nasal Decongestant",
      "Pseudoephedrine Ephedrine",
      "Sudafed Decongestant Extra Strength",
      "Triaminic AM Decongestant Formula",
      "Triaminic Infant Oral Decongestant Drops",
      "Efidac 24 PseudoephedrineHcl",
      "Sudafed Decongestant 12 Hour",
      "Pseudoefedrina [INN-Spanish]",
      "Pseudoephedrine (INN)",
      "Pseudoephedrine [INN:BAN]",
      "Pseudoephedrine D-Form",
      "Pseudoephedrinum [INN-Latin]",
      "Sudafed (TN)",
      "Alpha-(1-(Methylamino)Ethyl)Benzyl Alcohol",
      "D-Psi-2-Methylamino-1-Phenyl-1-Propanol",
      "Benzenemethanol, Alpha-((1S)-1-(Methylamino)Ethyl)-, (Alpha-S)-(9CI)",
      "(+)-(1S,2S)-Pseudoephedrine",
      "(1S,2S)-(+)-Pseudoephedrine",
      "(1S,2S)-2-(Methylamino)-1-Phenylpropan-1-Ol",
      "(1S,2S)-2-Methylamino-1-Phenyl-1-Propanol",
      "(1S,2S)-Pseudoephedrine",
      "(1S,2S)-Pseudoephedrine, Polymer-Bound",
    ],
  },
  {
    primary_id: "D00HHU",
    names: ["AFO-18"],
  },
  {
    primary_id: "D00HHV",
    names: ["Nicotine Transmucosal"],
  },
  {
    primary_id: "D00HIC",
    names: ["Pseudomonas Aeruginosa Vaccine"],
  },
  {
    primary_id: "D00HIG",
    names: [
      "3-Methylthiofentanyl",
      "3-Methyl-Thiofentanyl",
      "UNII-C9C204973M",
      "86052-04-2",
      "CHEBI:53763",
      "N-(3-Methyl-1-(2-Thienyl)Ethyl-4-Piperidinyl)-N-Phenylpropanamide",
      "C9C204973M",
      "Propanamide, N-(3-Methyl-1-(2-(2-Thienyl)Ethyl)-4-Piperidinyl)-N-Phenyl-",
      "N-{3-Methyl-1-[2-(2-Thienyl)Ethyl]piperidin-4-Yl}-N-Phenylpropanamide",
      "DEA No. 9833",
      "3-Methylthiofentanil",
      "Epitope ID:153515",
      "AC1Q5I8I",
      "AC1L1WU2",
      "SCHEMBL1742151",
      "CHEMBL2365680",
      "DB01439",
      "FT-0672232",
    ],
  },
  {
    primary_id: "D00HIH",
    names: [
      "LSN2814617",
      "CHEMBL2431211",
      "SCHEMBL2161736",
      "GTPL6395",
      "BDBM50441231",
      "ZINC72315650",
      "BCP9000877",
      "5-[(7S)-3-Tert-Butyl-5H,6H,7H,8H-[1,2,4]triazolo[4,3-A]pyridin-7-Yl]-3-(4-Fluorophenyl)-1,2,4-Oxadiazole",
    ],
  },
  {
    primary_id: "D00HKQ",
    names: ["PMID22364337C48"],
  },
  {
    primary_id: "D00HLW",
    names: ["WZ4003"],
  },
  {
    primary_id: "D00HMQ",
    names: ["Ormaplatin"],
  },
  {
    primary_id: "D00HMZ",
    names: ["TV1-Kidney-1"],
  },
  {
    primary_id: "D00HNB",
    names: [
      "Clodronate",
      "Bonefos",
      "Clodronsaeure",
      "Dichloromethanediphosphonate",
      "Dichloromethylenebisphosphonate",
      "Methanedichlorodiphosphonate",
      "Acide Clodronique",
      "Acido Clodronico",
      "Acidum Clodronicum",
      "Clodronic Acid",
      "Dichloromethanediphosphonic Acid",
      "Dichloromethylene Biphosphonate",
      "Dichloromethylene Diphosphonate",
      "Dichloromethylidene Diphosphonate",
      "Liposomes Containing Clodronic Acid",
      "Methanedichlorodiphosphonic Acid",
      "Acid, Clodronic",
      "Acid, Dichloromethanediphosphonic",
      "Acide Clodronique [INN-French]",
      "Acido Clodronico [INN-Spanish]",
      "Acidum Clodronicum [INN-Latin]",
      "Biphosphonate, Dichloromethylene",
      "Bonefos (TN)",
      "Clodron (TN)",
      "Diphosphonate, Dichloromethane",
      "Diphosphonate, Dichloromethylene",
      "Disodium, Clodronate",
      "Loron (TN)",
      "Sodium, Clodronate",
      "[dichloro(Phosphono)Methyl]phosphonic Acid",
      "Clodronic Acid (USAN/INN)",
      "Clodronic Acid [USAN:BAN:INN]",
      "Dichlormethylen-Bis(Phosphonsaeure)",
      "Dichloromethylene-1,1-Bisphosphonic Acid",
      "Dichloromethylene-1,1-Diphosphonic Acid",
      "Phosphonic Acid, (Dichloromethylene)Di-(8CI)",
      "(Dichloro-Phosphono-Methyl)-Phosphonic Acid",
      "(Dichloromethylene)Bisphosphonic Acid",
      "(Dichloromethylene)Diphosphonic Acid",
      "(Lip-C)",
      "(Dichloromethanediyl)Bis(Phosphonic Acid)",
    ],
  },
  {
    primary_id: "D00HNG",
    names: ["Furan-1"],
  },
  {
    primary_id: "D00HNK",
    names: ["RHb1.1"],
  },
  {
    primary_id: "D00HNQ",
    names: ["TDI-0051"],
  },
  {
    primary_id: "D00HPD",
    names: [
      "Moxetumomab Pasudotox",
      "CAT-8015",
      "GCR-8015",
      "HA22",
      "UNII-2NDX4B6N8F",
      "Moxetumomab Pasudotox [USAN]",
      "Immunoglobulin, Anti-(Human CD22 (Antigen)) (Synthetic Mus Musculus CAT-8015 Heavy Chain Variable Region Fragment) Fusion Protein With Exotoxin PE38 (Pseudomonas Fragment), Disulfide With Immunoglobulin, Anti-(Human CD22 (Antigen)) (Synthetic Mus Musculus CAT-8015 Light Chain Fragment)",
    ],
  },
  {
    primary_id: "D00HPK",
    names: ["Naftifine"],
  },
  {
    primary_id: "D00HPX",
    names: ["ON-04"],
  },
  {
    primary_id: "D00HQB",
    names: ["TX-MSD"],
  },
  {
    primary_id: "D00HQE",
    names: ["Ty800"],
  },
  {
    primary_id: "D00HQJ",
    names: ["PMID25522065-Compound-27"],
  },
  {
    primary_id: "D00HSG",
    names: ["(2-(2-Chlorophenyl)Pyridin-3-Yl)Methanamine", "CHEMBL378152"],
  },
  {
    primary_id: "D00HSJ",
    names: ["PIK-75"],
  },
  {
    primary_id: "D00HSK",
    names: ["Algenpantucel-L"],
  },
  {
    primary_id: "D00HTO",
    names: [
      "APOMINE",
      "Apomine",
      "SR-45023A",
      "UNII-JQ95208805",
      "JQ95208805",
      "SR-9223i",
      "SR 45023A",
      "APB-231-A2",
      "SKF 99085",
      "F-99085",
      "126411-13-0",
      "SCHEMBL1648488",
      "AC1L423G",
      "DB12276",
      "4-[2,2-Bis[di(Propan-2-Yloxy)Phosphoryl]ethyl]-2,6-Ditert-Butylphenol",
      "Tetraisopropyl 2-(3,5-Di-Tert-Butyl-4-Hydroxyphenyl)Ethyl-1,1-Biphosphonate",
      "Phosphonic Acid, (2-(3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl)Ethylidene)Bis-, Tetrakis(1-Methylethyl)",
      "SK&F-99085",
      "2-(3,5-Di-Tert-Butyl-4-Hydroxyphenyl)Ethylidene-1,1-Diphosphonic Acid Tetraisopropyl Ester",
      "SR-45023A (Apomine)",
    ],
  },
  {
    primary_id: "D00HTT",
    names: ["XE-9"],
  },
  {
    primary_id: "D00HTY",
    names: ["PGF2alpha", "Amoglandin", "Cerviprost", "Cyclosin", "Enzaprost", "Panacelan", "Prostaglandin F2alpha"],
  },
  {
    primary_id: "D00HUB",
    names: ["[1,1':2',1'']Terphenyl-4'-Carbaldehyde Oxime"],
  },
  {
    primary_id: "D00HUP",
    names: [
      "N,8-Diphenyl-9H-Purin-6-Amine",
      "CHEMBL1210175",
      "N,8-Diphenyl-9H-Purine-6-Amine",
      "BDBM50322830",
      "SR-01000661492",
    ],
  },
  {
    primary_id: "D00HUV",
    names: ["[2-(1H-Benzoimidazol-4-Yloxy)-Ethyl]-Benzyl-Amine", "CHEMBL89420", "SCHEMBL8120489", "BDBM50081111"],
  },
  {
    primary_id: "D00HUY",
    names: ["L-708568"],
  },
  {
    primary_id: "D00HUZ",
    names: ["NDDP", "Cis-Bis(2,2-Dimethyloctanoato)[trans-1(R),2(R)-Diaminocyclohexane]platinum (II)"],
  },
  {
    primary_id: "D00HVA",
    names: ["Aminopyridine Deriv. 2"],
  },
  {
    primary_id: "D00HVP",
    names: ["Riccardin C"],
  },
  {
    primary_id: "D00HVX",
    names: [
      "[1,2,4]triazolo[1,5-A]quinoxalin-4(5H)-One",
      "150454-83-4",
      "ACMC-1C8D4",
      "CHEMBL365930",
      "CTK0G9579",
      "DTXSID10435607",
      "AKOS027398551",
    ],
  },
  {
    primary_id: "D00HVZ",
    names: ["(+/-)-7-Methoxy-2-(4-Methoxyphenyl)Chroman-4-One", "7-Methoxy-2-(4-Methoxyphenyl)Chroman-4-One"],
  },
  {
    primary_id: "D00HWD",
    names: ["(Carboxyhydroxyamino)Ethanoic Acid", "AC1NRBMN", "N-(Carboxymethyl)-N-Hydroxycarbamate"],
  },
  {
    primary_id: "D00HWF",
    names: ["Proxyfan"],
  },
  {
    primary_id: "D00HWJ",
    names: ["PMID: 24900446C5"],
  },
  {
    primary_id: "D00HWO",
    names: [
      "Cyclacillin",
      "Aminocyclohexylpenicillin",
      "Bastcillin",
      "Calthor",
      "Ciclacilina",
      "Ciclacillin",
      "Ciclacilline",
      "Ciclacillinum",
      "Ciclacillum",
      "Citosarin",
      "Cyclapen",
      "Noblicil",
      "Orfilina",
      "Peamezin",
      "Syngacillin",
      "Ultracillin",
      "Vastcillin",
      "Vipicil",
      "Wyvital",
      "Aminocyclohexyl Penicillin",
      "Cyclacillin [USAN]",
      "AC 98",
      "WY 4508",
      "WY4508",
      "C-12104",
      "Ciclacilina [INN-Spanish]",
      "Ciclacilline [INN-French]",
      "Ciclacillinum [INN-Latin]",
      "Cyclacillin (USAN)",
      "Cyclapen (TN)",
      "Cyclapen-W",
      "Vastcillin (TN)",
      "Wy-4508",
      "Ciclacillin (JP15/INN)",
      "Cyclapen-W (TN)",
      "(1-Aminocyclohexyl)Penicillin",
      "(2S,5R,6R)-6-[(1-Aminocyclohexanecarbonyl)Amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-{[(1-Aminocyclohexyl)Carbonyl]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid, 6-(1-Aminocyclohexanecarboxamido)-3,3-Dimethyl-7-Oxo-(8CI)",
      "6-(1-Aminocyclohexanecarboxamido)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "6-(1-Aminocyclohexanecarboxamido)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "6-(1-Aminocyclohexanecarboxamido)Penicillanic Acid",
      "6-(1-Aminocyclohexylcarboxamido)Penicillanic Acid",
      "6beta-(1-Aminocyclohexanecarboxamido)-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D00HXJ",
    names: ["PMID29671355-Compound-23"],
  },
  {
    primary_id: "D00HXX",
    names: ["RGFP966"],
  },
  {
    primary_id: "D00HXZ",
    names: ["2-Morpholino-6-(Thianthren-1-Yl)-4H-Chromen-4-One", "CHEMBL200824"],
  },
  {
    primary_id: "D00HYD",
    names: ["CDT-Fenofibrate"],
  },
  {
    primary_id: "D00HYF",
    names: ["H5N1 Influenza Vaccine", "Avian Influenza Vaccine, Denka Seiken", "H5N1 Influenza Vaccine, Denka Seiken"],
  },
  {
    primary_id: "D00HYK",
    names: [
      "AFN-1252",
      "API-1252",
      "API-1401",
      "Antibacterial Therapeutics, Affinium",
      "Antibacterials, Affinium",
      "Bacterial Fatty Acid Biosynthesis Inhibitors, Affinium",
      "Galapagos Program, Affinium",
      "Antibacterial Therapeutics, Affinium/GSK",
      "Antibacterials, Affinium/GSK",
      "FabI Inhibitors (Antibacterial), Affinium",
      "AFN-1252 (Oral, Bacterial Infection)",
      "AFN-1252 (Oral, Bacterial Infection), Affinium",
    ],
  },
  {
    primary_id: "D00HYL",
    names: ["F-G-G-F-T-G-A-R-K-S-MeA-R-K-L-A-N-Q-CONH2"],
  },
  {
    primary_id: "D00HYO",
    names: ["LEUCETTAMIDINE"],
  },
  {
    primary_id: "D00HYS",
    names: [
      "2-Amino-4,6-Diphenyl-Pyrimidin-5-Carbonitrile",
      "CHEMBL259736",
      "SCHEMBL6440910",
      "JMXZSEAMWCKILY-UHFFFAOYSA-N",
      "ZINC29063803",
      "BDBM50375505",
      "2-Amino-4,6-Diphenylpyrimidine-5-Carbonitrile",
    ],
  },
  {
    primary_id: "D00HZA",
    names: ["YPEG-Somatropin"],
  },
  {
    primary_id: "D00HZO",
    names: ["GS-497C"],
  },
  {
    primary_id: "D00HZT",
    names: ["2-(6-Hydroxy-Naphthalen-2-Yl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D00HZV",
    names: ["Trimipramine"],
  },
  {
    primary_id: "D00IAE",
    names: ["Prop-2-Ynyl 4-Sulfamoylbenzoate"],
  },
  {
    primary_id: "D00IAH",
    names: ["Hemiacidrin"],
  },
  {
    primary_id: "D00IAK",
    names: ["1,1,1-Trifluoro-3-(Octylsulfonyl)Propan-2-One", "CHEMBL273139"],
  },
  {
    primary_id: "D00IAV",
    names: ["APS001F"],
  },
  {
    primary_id: "D00IBG",
    names: ["Flavin Mononucleotide"],
  },
  {
    primary_id: "D00IBN",
    names: [
      "Piroxicam",
      "Akten",
      "Artroxicam",
      "Bruxicam",
      "Caliment",
      "Erazon",
      "Felden",
      "Feldene",
      "Flogobene",
      "Geldene",
      "Improntal",
      "Larapam",
      "Pipoxicam",
      "Pirkam",
      "Piroflex",
      "Piroftal",
      "Piroxicamum",
      "Pyroxycam",
      "Reudene",
      "Riacen",
      "Roxicam",
      "Roxiden",
      "Sasulen",
      "Solocalm",
      "Zunden",
      "Feldene Fast",
      "Feldene Gel",
      "Piroxicam Usp",
      "AK1015",
      "CHF 1251",
      "CP 16171",
      "CP16171",
      "P 5654",
      "Apo-Piroxicam",
      "Brexidol (TN)",
      "Brexin (TN)",
      "CP-16171",
      "Erazon (TN)",
      "Exipan (TN)",
      "Felden (TN)",
      "Feldene (TN)",
      "Feldoral (TN)",
      "Hotemin (TN)",
      "Mobilis (TN)",
      "Pirox Von Ct (TN)",
      "Piroxicamum [INN-Latin]",
      "Proponol (TN)",
      "Reumador (TN)",
      "Tracam (TN)",
      "Veral (TN)",
      "Vurdon (TN)",
      "Feldene, Roxam, Piroxicam",
      "Piroxicam (JP15/USP/INN)",
      "Piroxicam [USAN:BAN:INN:JAN]",
      "(3E)-3-[hydroxy-(Pyridin-2-Ylamino)Methylidene]-2-Methyl-1,1-Dioxo-1",
      "(4-Hydroxy-2-Methyl-1,1-Dioxobenzo[e]1,2-Thiazin-3-Yl)-N-(2-Pyridyl)Carboxamide",
      "2H-1,2-Benzothiazine-3-Carboxamide, 4-Hydroxy-2-Methyl-N-2-Pyridinyl-, 1,1-Dioxide",
      "3-[hydroxy(Pyridin-2-Ylamino)Methylidene]-2-Methyl-3,4-Dihydro-2H-1",
      "4-Hydroxy-2-Methyl-3-(Pyrid-2-Yl-Carbamoyl)-2H-1,2-Benzothiazine 1,1-Dioxide",
      "4-Hydroxy-2-Methyl-N-(2-Pyridyl)-2H-1,2-Benzothiazin-3-Caboxyamid-1,1-Dioxid",
      "4-Hydroxy-2-Methyl-N-(2-Pyridyl)-2H-1,2-Benzothiazin-3-Caboxyamid-1,1-Dioxid [German]",
      "4-Hydroxy-2-Methyl-N-(2-Pyridyl)-2H-1,2-Benzothiazine-3-Carboxamide-1,1-Dioxide",
      "4-Hydroxy-2-Methyl-N-2-Pyridyl-2H-1,2-Benzothiazine-3-Carboxamide 1,1-Dioxide",
      "4-Hydroxy-2-Methyl-N-(Pyridin-2-Yl)-2H-1,2-Benzothiazine-3-Carboxamide 1,1-Dioxide",
      "4-Hydroxy-2-Methyl-N-Pyridin-2-Yl-2H-1,2-Benzothiazine-3-Carboxamide 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D00ICA",
    names: ["AMP-PNP"],
  },
  {
    primary_id: "D00ICD",
    names: ["SH-529"],
  },
  {
    primary_id: "D00ICM",
    names: ["NALTIAZEM HYDROCHLORIDE"],
  },
  {
    primary_id: "D00ICS",
    names: ["Pyrrolidinyl Urea Derivative 11"],
  },
  {
    primary_id: "D00ICY",
    names: ["FP-187"],
  },
  {
    primary_id: "D00IDP",
    names: ["Emdogain"],
  },
  {
    primary_id: "D00IDR",
    names: ["Phenylalanine Hydroxylase"],
  },
  {
    primary_id: "D00IDV",
    names: ["CRTX-070"],
  },
  {
    primary_id: "D00IEB",
    names: ["DRL-21994"],
  },
  {
    primary_id: "D00IFH",
    names: ["INO-1400"],
  },
  {
    primary_id: "D00IFJ",
    names: [
      "1,2,5,6-Tetrahydro Pyrido[1,2-A]quinolin-3-One",
      "CHEMBL96006",
      "SCHEMBL6820047",
      "ZINC22616",
      "BDBM50072191",
      "AKOS023799425",
      "1,2,5,6-Tetrahydro-3H-Benzo[c]quinolizine-3-One",
    ],
  },
  {
    primary_id: "D00IFQ",
    names: ["MVT-1075"],
  },
  {
    primary_id: "D00IFR",
    names: [
      "AZD1208",
      "1204144-28-4",
      "AZD-1208",
      "UNII-S98NFM1378",
      "(R,Z)-5-((2-(3-Aminopiperidin-1-Yl)-[1,1'-Biphenyl]-3-Yl)Methylene)Thiazolidine-2,4-Dione",
      "CHEMBL2048872",
      "S98NFM1378",
      "AZD 1208",
      "AK174910",
      "GTPL7698",
      "(5Z)-5-[[2-[(3R)-3-Aminopiperidin-1-Yl]-3-Phenylphenyl]methylidene]-1,3-Thiazolidine-2,4-Dione",
      "(5Z)-5-({2-[(3R)-3-Aminopiperidin-1-Yl]-3-Phenylphenyl}methylidene)-1,3-Thiazolidine-2,4-Dione",
      "SCHEMBL1580644",
      "MolPort-035-395-904",
      "EX-A1804",
      "AOB87151",
      "S7104",
      "2236AH",
      "ZINC84670255",
      "BDBM50387298",
      "AKOS025404917",
      "CS-1668",
      "QC-11435",
      "HY-15604",
    ],
  },
  {
    primary_id: "D00IGI",
    names: [
      "2-Amino-4-Phosphono-Butyric Acid",
      "2-Amino-4-Phosphonobutanoic Acid",
      "6323-99-5",
      "DL-AP4",
      "20263-07-4",
      "DL-2-Amino-4-Phosphonobutyric Acid",
      "Butanoic Acid, 2-Amino-4-Phosphono-",
      "BRN 5255932",
      "CHEMBL285843",
      "BUTYRIC ACID, 2-AMINO-4-PHOSPHONO-",
      "MFCD00013999",
      "L-2-Amino-4-Phosphonobutyrate",
      "Abu(P)",
      "SR-01000075414",
      "L-2-Amino-4-Phosphobutyric Acid",
      "L-AP4 Cpd, Butyric",
      "AC1Q6RPJ",
      "4-Amino Phosphonobutyrate",
    ],
  },
  {
    primary_id: "D00IIQ",
    names: ["(S)-1-(4-Nitrophenethyl)-2-Methylpyrrolidine", "CHEMBL257235"],
  },
  {
    primary_id: "D00IJM",
    names: [
      "EPI-A0001",
      "Alpha-Tocopherolquinone",
      "Alpha-Tocopherylquinone",
      "A-0001, Penwest Pharmaceuticals",
      "Coenzyme Q10 Analog (Friedreich's Ataxia), Edison Pharmaceuticals",
      "Coenzyme Q10 Analog (Friedreich's Ataxia), Penwest Pharmaceuticals",
    ],
  },
  {
    primary_id: "D00IJQ",
    names: ["ZATOSETRON MALEATE"],
  },
  {
    primary_id: "D00IKH",
    names: ["Carmofur"],
  },
  {
    primary_id: "D00IKI",
    names: ["PF-04895162"],
  },
  {
    primary_id: "D00IKQ",
    names: ["4-Methoxy-3-Pyridinesulfonamide", "CHEMBL1165321"],
  },
  {
    primary_id: "D00IMW",
    names: ["Flavin-Adenine Dinucleotide"],
  },
  {
    primary_id: "D00INM",
    names: ["Occlusin"],
  },
  {
    primary_id: "D00IOF",
    names: ["PSB36"],
  },
  {
    primary_id: "D00IOS",
    names: ["CYT-017-IL17Qb"],
  },
  {
    primary_id: "D00IPI",
    names: ["Carbamide Derivative 24"],
  },
  {
    primary_id: "D00IPO",
    names: [
      "2-(3-Isopropyl-Phenyl)-Propionic Acid",
      "Benzeneacetic Acid, Alpha-Methyl-3-(1-Methylethyl)-",
      "CHEMBL191103",
      "SCHEMBL1345333",
      "DHFSUPPYOBITGY-UHFFFAOYSA-N",
      "2-(3-Isopropylphenyl)Propanoic Acid",
      "AKOS022699991",
      "2-[(3'-Isopropyl)Phenyl]propionic Acid",
    ],
  },
  {
    primary_id: "D00IPP",
    names: [
      "L-Valyl-L-Prolinamide",
      "CHEMBL375933",
      "76362-52-2",
      "L-Prolinamide, L-Valyl-",
      "CTK2G7856",
      "DTXSID40469490",
    ],
  },
  {
    primary_id: "D00IPW",
    names: ["CGP-47969A"],
  },
  {
    primary_id: "D00IQH",
    names: ["RQ-00000007 (Oral)", "CJ-023423", "RQ-00000007 (Iv)", "MR-10-A-7"],
  },
  {
    primary_id: "D00IQV",
    names: ["Homspera"],
  },
  {
    primary_id: "D00IRL",
    names: [
      "2-(4-Methoxyphenyl)-6-Morpholino-4H-Pyran-4-One",
      "CHEMBL319261",
      "SCHEMBL3545199",
      "BDBM50132361",
      "2-(4-Methoxyphenyl)-6-Morpholin-4-Ylpyran-4-One",
      "2-Morpholino-6-(4-Methoxyphenyl)-4H-Pyran-4-One",
      "2-(4-Methoxy-Phenyl)-6-Morpholin-4-Yl-Pyran-4-One",
    ],
  },
  {
    primary_id: "D00IRU",
    names: [
      "E-5842",
      "E 5842",
      "E5842",
      "4-(4-Fluorophenil)-1,2,3,4-Tetrahydro-1-(4-(1,2,4-Triazol-1-Il)Butyl)Pyridine Citrate",
      "4-(4-Fluorophenyl)-1-[4-(1,2,4-Triazol-1-Yl)Butyl]-3,6-Dihydro-2H-Pyridine",
    ],
  },
  {
    primary_id: "D00ISN",
    names: ["MT-1303"],
  },
  {
    primary_id: "D00ITC",
    names: ["PRT4445"],
  },
  {
    primary_id: "D00ITG",
    names: ["Clinafloxacin", "Clinafloxacin Hydrochloride", "AM-1091", "PD-127391"],
  },
  {
    primary_id: "D00ITT",
    names: ["PMID25726713-Compound-43"],
  },
  {
    primary_id: "D00IUB",
    names: [
      "4-CYANOPHENOL",
      "4-Hydroxybenzonitrile",
      "4-Cyanophenol",
      "767-00-0",
      "P-Hydroxybenzonitrile",
      "P-CYANOPHENOL",
      "Benzonitrile, 4-Hydroxy-",
      "Benzonitrile, P-Hydroxy-",
      "4-CYANO PHENOL",
      "4-Hydroxybenzoic Acid Nitrile",
      "4-Hydroxy-Benzonitrile",
      "4-Hydroxy Benzonitrile",
      "4-Hydroxybenzenecarbonitrile",
      "UNII-1S13529YJU",
      "EINECS 212-175-2",
      "C7H5NO",
      "NSC 400524",
      "AI3-52392",
      "CHEBI:38622",
      "CVNOWLNNPYYEOH-UHFFFAOYSA-N",
      "1S13529YJU",
      "4-Cyanophenol, 99%",
      "Paracyanophenol",
      "P-Cyano-Phenol",
      "4-Cyano-Phenol",
      "4 -Cyanophenol",
      "Para-Cyanophenol;",
      "4-Hydroxybezonitrile",
      "P-Hydroxybenzo-Nitrile",
    ],
  },
  {
    primary_id: "D00IUD",
    names: [
      "K-832",
      "10465-81-3",
      "1,1'-(Azodicarbonyl)-Dipiperidine",
      "AdDP",
      "1,1-(Azodicarbonyl)Dipiperidine",
      "1,1'-(Azodicarbonyl)Dipiperidine",
      "Diazene-1,2-Diylbis(Piperidin-1-Ylmethanone)",
      "NSC356027",
      "NSC 356027",
      "(NE)-N-(Piperidine-1-Carbonylimino)Piperidine-1-Carboxamide",
      "Azodicarboxylic Acid Dipiperidine",
      "(E)-Diazene-1,2-Diylbis(Piperidin-1-Ylmethanone)",
      "Azodicarboxylic Acid Dipiperidide",
      "J-503690",
      "SR-4077",
      "(E)-Diazene-1,2-Diylbis(Piperidin-1-Yl Methanone)",
      "SR 4077",
      "PubChem19605",
      "Piperidyl (Piperidylcarbonyl)Diazenyl Ketone",
      "AC1Q5JWC",
      "AC1NWBQ9",
    ],
  },
  {
    primary_id: "D00IUG",
    names: [
      "Levobunolol",
      "Akbeta",
      "Betagan",
      "Levobunololum",
      "Levobunolol HCl",
      "Betegan (TN)",
      "Levobunolol (INN)",
      "Levobunolol [INN:BAN]",
      "Levobunololum [INN-Latin]",
      "Liquifilm (TN)",
      "W-6412A",
      "W-7000A",
      "AK-Beta (TN)",
      "(-)-Bunolol",
      "(S)-5-(3-((1,1-Dimethylethyl)Amino)-2-Hydroxypropoxy)-3,4-Dihydro-1(2H)-Naphthalenone",
      "5-[(2S)-3-(Tert-Butylamino)-2-Hydroxypropoxy]-3,4-Dihydro-2H-Naphthalen-1-One",
      "5-[(2S)-3-(Tert-Butylamino)-2-Hydroxypropoxy]-3,4-Dihydronaphthalen-1(2H)-One",
    ],
  },
  {
    primary_id: "D00IUN",
    names: ["1-(1-(4-Thiophen-3-Ylphenyl)Ethyl)-1H-Imidazole", "CHEMBL273191", "SCHEMBL18770412"],
  },
  {
    primary_id: "D00IVF",
    names: ["Murepavadin"],
  },
  {
    primary_id: "D00IVM",
    names: ["PMID27788040-Compound-6"],
  },
  {
    primary_id: "D00IVN",
    names: ["Navicixizumab"],
  },
  {
    primary_id: "D00IWE",
    names: ["RP-6000", "Antidepressant (Oral), Reviva"],
  },
  {
    primary_id: "D00IWH",
    names: ["P-Nitrophenyl Glutamyl Anilide"],
  },
  {
    primary_id: "D00IWL",
    names: ["PolyHeme"],
  },
  {
    primary_id: "D00IXB",
    names: ["6-Ethyl-4,7-Dimethyl-6H-Benzo[c]chromene-3,8-Diol", "CHEMBL206547", "SCHEMBL6828050"],
  },
  {
    primary_id: "D00IXM",
    names: ["DR-3001"],
  },
  {
    primary_id: "D00IXN",
    names: ["GS-6637"],
  },
  {
    primary_id: "D00IYB",
    names: ["Maraciclatide"],
  },
  {
    primary_id: "D00IYM",
    names: ["AEZS-127"],
  },
  {
    primary_id: "D00IZD",
    names: ["DVC1-0101"],
  },
  {
    primary_id: "D00IZW",
    names: ["BM-13.1188"],
  },
  {
    primary_id: "D00IZY",
    names: ["DCN01"],
  },
  {
    primary_id: "D00JAH",
    names: ["PMID27841036-Compound-24"],
  },
  {
    primary_id: "D00JAT",
    names: [
      "(-)-Cyclorphan",
      "CHEMBL291468",
      "BDBM50105483",
      "17-Cyclopropylmethyl-17-Azatetracyclo[7.5.3.01,10.02,7]heptadeca-2,4,6-Trien-4-Ol",
      "17-Cyclopropylmethyl-(1R,9R)-17-Azatetracyclo[7.5.3.01,10.02,7]heptadeca-2(7),3,5-Trien-4-Ol",
    ],
  },
  {
    primary_id: "D00JAU",
    names: ["Irbesartan"],
  },
  {
    primary_id: "D00JBC",
    names: [
      "1-Butyl-3-Methyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL130503",
      "SCHEMBL7109948",
      "1H-Purine-2,6-Dione, 1-Butyl-3,9-Dihydro-3-Methyl-",
      "XT-56",
      "BDBM50001494",
    ],
  },
  {
    primary_id: "D00JBM",
    names: ["UR-5908", "COPD Therapy, UBE Industries"],
  },
  {
    primary_id: "D00JCA",
    names: ["NewGam"],
  },
  {
    primary_id: "D00JCB",
    names: ["CD19/CD22 CAR T Cells"],
  },
  {
    primary_id: "D00JCQ",
    names: ["Oxazolo[3,4-A]pyrazine Derivative 4"],
  },
  {
    primary_id: "D00JDJ",
    names: ["AX-024"],
  },
  {
    primary_id: "D00JDL",
    names: ["MGD006"],
  },
  {
    primary_id: "D00JDP",
    names: ["AMX-213"],
  },
  {
    primary_id: "D00JEC",
    names: ["PMID29338548-Compound-24"],
  },
  {
    primary_id: "D00JED",
    names: ["AN-2898"],
  },
  {
    primary_id: "D00JEO",
    names: ["CSNLSTCVLGKLSQELc[DKLHK]YPRTNTGSGTP-Amide"],
  },
  {
    primary_id: "D00JFE",
    names: ["ARN-810"],
  },
  {
    primary_id: "D00JFN",
    names: ["MDC-917"],
  },
  {
    primary_id: "D00JGL",
    names: ["D-4476", "CK1 Inhibitor", "D 4476"],
  },
  {
    primary_id: "D00JGN",
    names: ["PMID25991433-Compound-H3"],
  },
  {
    primary_id: "D00JHE",
    names: ["ISIS 298697"],
  },
  {
    primary_id: "D00JHN",
    names: ["PBD150", "Compound 53 [PMID 16420052]"],
  },
  {
    primary_id: "D00JID",
    names: ["Ac-Cys-Ile-Tyr-Lys-Phe(4-Cl)-Tyr"],
  },
  {
    primary_id: "D00JIV",
    names: ["PF-4989216"],
  },
  {
    primary_id: "D00JIX",
    names: ["IKH-25"],
  },
  {
    primary_id: "D00JJG",
    names: ["RAP101"],
  },
  {
    primary_id: "D00JJO",
    names: ["1-[(3-Methoxybenzyl)Oxy]-2-(2-Phenylethyl)Benzene"],
  },
  {
    primary_id: "D00JJP",
    names: ["UR-12519", "149692-09-1"],
  },
  {
    primary_id: "D00JJW",
    names: ["RH 1"],
  },
  {
    primary_id: "D00JJZ",
    names: ["LaViv", "Azficel-T"],
  },
  {
    primary_id: "D00JKB",
    names: ["SC-54750"],
  },
  {
    primary_id: "D00JKJ",
    names: ["HS-P1"],
  },
  {
    primary_id: "D00JLX",
    names: ["Sulfonamide Derivative 6"],
  },
  {
    primary_id: "D00JME",
    names: ["SMT-14400"],
  },
  {
    primary_id: "D00JMG",
    names: ["HRA-081035"],
  },
  {
    primary_id: "D00JMT",
    names: ["REVERSINE"],
  },
  {
    primary_id: "D00JMY",
    names: ["AMP-224"],
  },
  {
    primary_id: "D00JMZ",
    names: [
      "CGS-27830",
      "Cgs-27830",
      "Cgs 27830",
      "AC1L4RBN",
      "AC1Q5XDJ",
      "Cgs27830",
      "(4r)-5-(Methoxycarbonyl)-2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3-Carboxylic(4s)-5-(Methoxycarbonyl)-2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3-Carboxylic Anhydride",
      "SCHEMBL9089580",
      "155485-56-6",
      "Meso-1,4-Dihydro-5-Methoxycarbonyl-2,6-Dimethyl-4-(3-Nitrophenyl)-3-Pyridine Carboxylic Acid Anhydride",
      "5-O-[(4S)-5-Methoxycarbonyl-2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3-Carbonyl] 3-O-Methyl (4R)-2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydr",
    ],
  },
  {
    primary_id: "D00JNH",
    names: ["Conantokins G"],
  },
  {
    primary_id: "D00JNL",
    names: ["Tenilsetam", "CAS-997"],
  },
  {
    primary_id: "D00JNT",
    names: ["HCV-086"],
  },
  {
    primary_id: "D00JPB",
    names: ["5-(4-((Phenethylamino)Methyl)Phenoxy)Picolinamide", "CHEMBL236376", "SCHEMBL3771658"],
  },
  {
    primary_id: "D00JPH",
    names: [
      "N-(1-Benzyl-2-Oxo-Ethyl)-Benzamide",
      "CHEMBL287136",
      "N-Benzoyl-L-Phenylalaninal",
      "Benzamide, N-[(1s)-1-Formyl-2-Phenylethyl]-",
      "AC1L4VU2",
      "SCHEMBL40781",
      "AC1Q6A59",
      "ZINC3650451",
      "BDBM50014566",
      "N-[(2S)-1-Oxo-3-Phenylpropan-2-Yl]benzamide",
      "(2S)-2-(Benzoylamino)-3-Phenylpropionaldehyde",
      "Benzamide, N-(1-Formyl-2-Phenylethyl)-, (S)-",
    ],
  },
  {
    primary_id: "D00JPK",
    names: ["HYG-201"],
  },
  {
    primary_id: "D00JPV",
    names: ["N-Phenylbenzo[d]oxazol-2-Amine"],
  },
  {
    primary_id: "D00JQA",
    names: ["Benzimidazole Derivative 3"],
  },
  {
    primary_id: "D00JQE",
    names: [
      "BAY-60-5521",
      "CETP Inhibitors (Dyslipidemia), Bayer",
      "Cholesteryl Ester Transfer Protein Inhibitors (Dyslipidemia), Bayer",
    ],
  },
  {
    primary_id: "D00JQQ",
    names: ["RX-465"],
  },
  {
    primary_id: "D00JRA",
    names: [
      "Asenapine",
      "Asenapi",
      "Asenapine [INN:BAN]",
      "Saphris (TN)",
      "[13C,2H3]-Asenipine",
      "Trans-5-Chloro-2,3,3a,12b-Tetrahydro-2-Methyl-1H-Dibenz(2,3:6,7)Oxepino(4,5-C)Pyrrole",
      "1H-Dibenz[2,3:6,7]oxepino[4,5-C]pyrrole, 5-Chloro-2,3,3a,12b-Tetrahydro-2-Met",
      "5-Chloro-2,3,3a,12b-Tetrahydro-2-Methyl-1H-Dibenz(2,3-6,7)Oxepino(4,5-C)Pyrrole",
    ],
  },
  {
    primary_id: "D00JRD",
    names: ["PGF2ALPHA-IE"],
  },
  {
    primary_id: "D00JSG",
    names: ["Fluoratec"],
  },
  {
    primary_id: "D00JTE",
    names: [
      "1,1-Diphenyl-2-(3-Tropanyl)Ethanol",
      "CHEMBL558034",
      "BDBM50345674",
      "2-(Exo-8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl)-1,1-Diphenylethanol",
    ],
  },
  {
    primary_id: "D00JUD",
    names: [
      "4-Methyl-2H-Benzofuro[3,2-G]chromen-2-One",
      "CHEMBL201777",
      "105290-13-9",
      "2H-Benzofuro(3,2-G)-1-Benzopyran-2-One, 4-Methyl-",
      "4-Methyl-(1)Benzofuro(3,2-G)Chromen-2-One",
      "2H-Benzofuro[3,2-G]-1-Benzopyran-2-One, 4-Methyl-",
      "4-Methyl-[1]benzofuro[3,2-G]chromen-2-One",
      "ACMC-20c6p3",
      "AC1Q6A2V",
      "AC1L4EY2",
      "4-Methyl-2h-[1]benzofuro[3,2-G]chromen-2-One",
      "CTK0G5617",
      "DTXSID00147060",
      "ZINC6069630",
      "BDBM50181850",
    ],
  },
  {
    primary_id: "D00JUI",
    names: ["Triazolo-Pyridine Derivative 1"],
  },
  {
    primary_id: "D00JUO",
    names: ["PMID21757343C30"],
  },
  {
    primary_id: "D00JVC",
    names: [
      "ATB-429",
      "ATB-427",
      "ATB-428",
      "IBD Therapeutics, Antibe Therapeutics",
      "Inflammatory Bowel Disease Therapeutics, Antibe Therapeutics",
    ],
  },
  {
    primary_id: "D00JVQ",
    names: ["PMID26004420-Compound-WO2014099633D"],
  },
  {
    primary_id: "D00JVR",
    names: [
      "Azelastine",
      "Azelastina",
      "Azelastinum",
      "Optivar",
      "Astelin (TN)",
      "Astepro (TN)",
      "Azelastina [INN-Spanish]",
      "Azelastine (INN)",
      "Azelastine [INN:BAN]",
      "Azelastinum [INN-Latin]",
      "Optivar (TN)",
      "4-((4-Chlorophenyl)Methyl)-2-(Hexahydro-1-Methyl-1H-Azepin-4-Yl)-1(2H)-Phthalazinone Hydrochloride",
      "4-((4-Chlorophenyl)Methyl)-2-(Hexahydro-1-Methyl-1H-Azepin-4-Yl)-1(2H)-Phthalazinone HCl",
      "4-(4-Chlorobenzyl)-2-(1-Methylazepan-4-Yl)Phthalazin-1(2H)-One",
      "4-(P-Chlorobenzyl)-2-(Hexahydro-1-Methyl-1H-Azepin-4-Yl)-1-(2H)-Phthalazinone",
      "4-(P-Chlorobenzyl)-2-(N-Methylperhydroazepinyl-(4))-1-(2H)-Phthalazinone",
      "4-[(4-Chlorophenyl)Methyl]-2-(1-Methylazepan-4-Yl)Phthalazin-1(2H)-One",
      "4-[(4-Chlorophenyl)Methyl]-2-(1-Methylazepan-4-Yl)Phthalazin-1-One",
    ],
  },
  {
    primary_id: "D00JVX",
    names: ["Ibalizumab"],
  },
  {
    primary_id: "D00JWA",
    names: ["N-(2-(Furan-2-Yl)-3,4'-Bipyridin-6-Yl)Acetamide", "CHEMBL1081811", "BDBM50312964"],
  },
  {
    primary_id: "D00JWB",
    names: [
      "SGC707",
      "1687736-54-4",
      "SGC 707",
      "SGC-707",
      "1-Isoquinolin-6-Yl-3-[2-Oxo-2-(Pyrrolidin-1-Yl)Ethyl]urea",
      "GTPL8552",
      "KS-00000THM",
      "AOB5757",
      "MolPort-039-101-300",
      "EX-A1304",
      "BCP16746",
      "S7832",
      "ZINC220951188",
      "AKOS025142099",
      "CS-5370",
      "ACN-053041",
      "NCGC00384204-04",
      "HY-19715",
      "AK546586",
      "SGC707, &gt",
      "AS-16917",
      "FT-0700238",
      "J-690235",
      "1-Isoquinolin-6-Yl-3-(2-Oxo-2-Pyrrolidin-1-Ylethyl)Urea",
      "1-(Isoquinolin-6-Yl)-3-[2-Oxo-2-(Pyrrolidin-1-Yl)Ethyl]urea",
      "1-(Isoquinolin-6-Yl)-3-[2-Oxo-2-(Pyrrolidin-1-Yl)Ethyl] Urea",
    ],
  },
  {
    primary_id: "D00JWL",
    names: ["LEX-028"],
  },
  {
    primary_id: "D00JWR",
    names: ["PT-312"],
  },
  {
    primary_id: "D00JXJ",
    names: ["ISIS 107612"],
  },
  {
    primary_id: "D00JXL",
    names: [
      "N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL60334",
      "N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "14269-45-5",
      "N-(4-Phenylthiazol-2-Yl)Benzamide",
      "Enamine_004302",
      "AC1LF3YD",
      "AC1Q5LQ8",
      "Cambridge Id 5149845",
      "Oprea1_016303",
      "Oprea1_462006",
      "Oprea1_007140",
      "CBDivE_004384",
      "SCHEMBL1270375",
      "SCHEMBL18874193",
      "CTK0F0159",
      "DTXSID10353610",
      "MolPort-000-432-183",
      "HMS1406D12",
      "2-(Benzoylamino)-4-Phenylthiazole",
      "ZINC18022084",
      "BDBM50101790",
      "STK972349",
      "STK337540",
      "AKOS002233837",
      "AKOS000478692",
      "MCULE-8414212509",
      "Benzamide, N-(4-Phenyl-2-Thiazolyl)-",
      "NCGC00262409-01",
      "BAS 0",
    ],
  },
  {
    primary_id: "D00JXV",
    names: ["INCB-10820"],
  },
  {
    primary_id: "D00JYL",
    names: ["[D-Ncy(Isopropyl)7]acyline", "CHEMBL227451"],
  },
  {
    primary_id: "D00JZD",
    names: ["GSK2321138A"],
  },
  {
    primary_id: "D00JZS",
    names: [
      "Anti-FGFR1 Mab Program",
      "Anti-FGFR1 MAb Program (Cancer)",
      "Anti-FGFR1 MAb Program (Cancer), AVEO Pharmaceuticals",
      "Anti-Fibroblast Growth Factor Receptor-1 Monoclonal Antibody Program (Cancer), AVEO Pharmaceuticals",
    ],
  },
  {
    primary_id: "D00JZX",
    names: [
      "EP-012637",
      "Ciclosporin Analogs, Enanta",
      "Cyclosporin A Analogs, Enanta",
      "Cyclosporine Analogs, Enanta",
      "EP-1269",
      "EP-314",
      "EP-861",
      "Immunosuppressant Program, Enanta",
    ],
  },
  {
    primary_id: "D00KAI",
    names: ["Antidepressant", "Antidepressant (Oral)"],
  },
  {
    primary_id: "D00KCB",
    names: ["Fidarestat(Stereoisomer)"],
  },
  {
    primary_id: "D00KCR",
    names: ["Enkastim-Iv"],
  },
  {
    primary_id: "D00KCT",
    names: ["PMID27109571-Compound-27"],
  },
  {
    primary_id: "D00KDF",
    names: ["SB 218078"],
  },
  {
    primary_id: "D00KDQ",
    names: ["Anti-CEA CAR-T Cells"],
  },
  {
    primary_id: "D00KDT",
    names: ["Oligooxopiperazine Derivative 1"],
  },
  {
    primary_id: "D00KDZ",
    names: ["N-(1H-Indol-2-Ylmethyl)-N-Methyl-N-Phenylamine", "CHEMBL461245"],
  },
  {
    primary_id: "D00KEF",
    names: ["Org-2766"],
  },
  {
    primary_id: "D00KEP",
    names: ["Edetic Acid"],
  },
  {
    primary_id: "D00KEX",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 14"],
  },
  {
    primary_id: "D00KFB",
    names: ["Dextromethorphan Polistirex"],
  },
  {
    primary_id: "D00KFF",
    names: [
      "9,9,9-Trifluoro-8-Oxo-Nonanoic Acid Phenylamide",
      "9,9,9-Trifluoro-8-Oxo-N-Phenylnonanamide",
      "CHEMBL113537",
      "2gh6",
      "SCHEMBL2702892",
      "KRCXZGYVOZSCSF-UHFFFAOYSA-N",
      "BDBM50121062",
      "DB07553",
    ],
  },
  {
    primary_id: "D00KFI",
    names: ["Azetidinyl-Piperazine Derivative 3"],
  },
  {
    primary_id: "D00KGB",
    names: ["Abagovomab"],
  },
  {
    primary_id: "D00KGI",
    names: ["IVN-201"],
  },
  {
    primary_id: "D00KGK",
    names: [
      "4-Bromo-3-Hydroxy-1H-Quinolin-2-One",
      "2(1H)-Quinolinone, 4-Bromo-3-Hydroxy-",
      "CHEMBL146520",
      "SCHEMBL15690621",
      "BSYFERFWIZPSKL-UHFFFAOYSA-N",
      "BDBM247011",
      "4-Bromo-3-Hydroxyquinolin-2(1H)-One",
      "4-Bromo-3-Hydroxyquinoline-2(1H)-One",
      "176170-14-2",
      "US9701638, 2",
    ],
  },
  {
    primary_id: "D00KGL",
    names: ["PMID27998201-Compound-8"],
  },
  {
    primary_id: "D00KHD",
    names: ["TAK-128"],
  },
  {
    primary_id: "D00KHI",
    names: ["Anti-PSMA CAR-T Cell Therapy"],
  },
  {
    primary_id: "D00KHM",
    names: [
      "Pimozide",
      "Antalon",
      "Neoperidole",
      "Opiran",
      "Orap",
      "Pimozida",
      "Pimozidum",
      "Primozida",
      "ASTA Medica Brand Of Pimozide",
      "Janssen Brand Of Pimozide;Orap Forte",
      "Pharmascience Brand Of Pimozide",
      "P 1793",
      "R 6238",
      "McN-JR 6238",
      "Orap (TN)",
      "Pimozida [INN-Spanish]",
      "Pimozidum [INN-Latin]",
      "Primozida [INN-Spanish]",
      "R-6238",
      "McN-JR-6238",
      "Pimozide (JAN/USP/INN)",
      "Pimozide [USAN:INN:BAN:JAN]",
      "1-(1-(4,4-Bis(P-Fluorophenyl)Butyl)-4-Piperidyl)-2-Benzimidazolinone",
      "1-(4,4-Bis(P-Fluorophenyl)Butyl)-4-(2-Oxo-1-Benzimidazolinyl)Piperidine",
      "1-[1-[4,4-Bis(4-Fluorophenyl)Butyl]-4-Piperidinyl]-1,3-Dihydro-2H-Benzimidazol-2-One",
      "1-[1-[4,4-Bis(P-Fluorophenyl)Butyl]-4-Piperidyl]-2-Benzimidazolinone",
      "1-[4,4-Bis(P-Fluorophenyl)Butyl]-4-(2-Oxo-1-Benzimidazolinyl)Piperidine",
      "1-{1-[4,4-Bis(4-Fluorophenyl)Butyl]piperidin-4-Yl}-1,3-Dihydro-2H-Benzimidazol-2-One",
      "1-{1-[4,4-Bis(4-Fluorophenyl)Butyl]piperidin-4-Yl}-2,3-Dihydro-1H-1,3-Benzodiazol-2-One",
      "3-[1-[4,4-Bis(4-Fluorophenyl)Butyl]piperidin-4-Yl]-1H-Benzimidazol-2-One",
    ],
  },
  {
    primary_id: "D00KIE",
    names: [
      "Ensaculin Hydrochloride",
      "Anseculin Hydrochloride",
      "KA-672.HCl",
      "7-Methoxy-6-[3-[4-(2-Methoxyphenyl)Piperazin-1-Yl]propoxy]-3,4-Dimethyl-2H-1-Benzopyran-2-One Hydrochloride",
    ],
  },
  {
    primary_id: "D00KII",
    names: ["PMID30273516-Compound-39"],
  },
  {
    primary_id: "D00KIK",
    names: [
      "1-(1-Benzo[b]thiophen-2-Yl-Cyclohexyl)-Azepane",
      "CHEMBL17945",
      "1-[1-(1-Benzothiophen-2-Yl)Cyclohexyl]azepane",
      "NCI60_038332",
      "AC1L9GGF",
      "CTK5J7197",
      "QDGRJLAJZNWVGV-UHFFFAOYSA-N",
      "BDBM50040192",
      "1-[1-(1-Benzothien-2-Yl)Cyclohexyl]azepane #",
      "Thionaphthene, 2-[1-(1-Azacycloheptyl)Cyclohexyl]-",
    ],
  },
  {
    primary_id: "D00KIU",
    names: ["MKC-242844"],
  },
  {
    primary_id: "D00KIV",
    names: ["(1-Amino-3-Phenyl-Propyl)-Phosphinic Acid"],
  },
  {
    primary_id: "D00KJO",
    names: ["MUT-056399"],
  },
  {
    primary_id: "D00KJP",
    names: ["RPR-120844"],
  },
  {
    primary_id: "D00KJV",
    names: ["N-Butylgalactonojirimycin"],
  },
  {
    primary_id: "D00KKD",
    names: ["PMID26651364-Compound-5"],
  },
  {
    primary_id: "D00KKN",
    names: ["(2S,3S)-Iodoreboxetine", "CHEMBL490996", "BDBM50264064"],
  },
  {
    primary_id: "D00KLH",
    names: ["KR-6346"],
  },
  {
    primary_id: "D00KLP",
    names: ["Immune Globulin + Albumin"],
  },
  {
    primary_id: "D00KLR",
    names: ["3-Amino-Propane-1-Sulfonic Acid", "3-Ammoniopropane-1-Sulfonate"],
  },
  {
    primary_id: "D00KMC",
    names: ["PMID19191557C32"],
  },
  {
    primary_id: "D00KMI",
    names: [
      "CEP-37309",
      "ART-140",
      "EGX-040",
      "EGX-140",
      "Leukemia Antibody Therapy, Arana",
      "Leukemia Antibody Therapy, EvoGenix",
      "Leukemia Antibody Therapy, Peptech",
    ],
  },
  {
    primary_id: "D00KML",
    names: ["SYN004"],
  },
  {
    primary_id: "D00KMP",
    names: ["3-Butyl-1-(Naphthalen-1-Yl)Piperidin-4-Amine", "CHEMBL234615"],
  },
  {
    primary_id: "D00KMQ",
    names: ["OI362GT"],
  },
  {
    primary_id: "D00KNI",
    names: ["BI-853520"],
  },
  {
    primary_id: "D00KOE",
    names: [
      "4-Isopropyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL365637",
      "SCHEMBL7598537",
      "SCHEMBL10077978",
      "BDBM50150914",
    ],
  },
  {
    primary_id: "D00KOV",
    names: ["Monatepil Maleate", "AD-2615", "AJ-2615"],
  },
  {
    primary_id: "D00KPQ",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 19"],
  },
  {
    primary_id: "D00KPR",
    names: ["PMID17888661C18"],
  },
  {
    primary_id: "D00KQB",
    names: ["1-(4-Butoxyphenyl)Propan-2-Amine", "CHEMBL456012", "BDBM50276774"],
  },
  {
    primary_id: "D00KQN",
    names: [
      "PEGylated Pitrakinra",
      "Aeroderm",
      "Dermalast",
      "AER-003",
      "PEGylated Pitrakinra (Subcutaneous, Eczema/Allergy), Aerovance",
      "PEGylated AER-001 (Subcutaneous, Eczema/Allergy), Aerovance",
      "PEGylated IL-4 Variant (Subcutaneous, Eczema/Allergy), Aerovance",
    ],
  },
  {
    primary_id: "D00KQT",
    names: ["FR-129169"],
  },
  {
    primary_id: "D00KRE",
    names: ["Olsalazine", "Azodisal", "Dipentum", "Olsalazine Sodium", "Di-Mesalazine"],
  },
  {
    primary_id: "D00KRL",
    names: ["Ethyl 4-(1-Benzyl-1H-Indazol-3-Yl)Benzoate"],
  },
  {
    primary_id: "D00KRQ",
    names: ["4-(2-Phenoxyphenyl)Piperidine", "CHEMBL575077", "SCHEMBL4699769"],
  },
  {
    primary_id: "D00KRT",
    names: ["Indocyanine Green"],
  },
  {
    primary_id: "D00KSS",
    names: [
      "3-(4-Methoxy-Benzenesulfonyl)-Propane-1-Thiol",
      "CHEMBL51638",
      "SCHEMBL7076532",
      "JIFLEYNNNKKNOQ-UHFFFAOYSA-N",
      "BDBM50076592",
      "3-(4-Methoxyphenylsulfonyl)Propane-1-Thiol",
    ],
  },
  {
    primary_id: "D00KSU",
    names: ["MT-274 Lead"],
  },
  {
    primary_id: "D00KTE",
    names: ["(2S)-Aminobutyryl-L-Proline N-Butylamide", "CHEMBL225540", "SCHEMBL6242775"],
  },
  {
    primary_id: "D00KTI",
    names: ["PMID25399762-Compound-Table1-C17"],
  },
  {
    primary_id: "D00KTN",
    names: ["PMID25666693-Compound-121"],
  },
  {
    primary_id: "D00KTT",
    names: ["Isopropyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL450026"],
  },
  {
    primary_id: "D00KTV",
    names: [
      "Salirasib",
      "162520-00-5",
      "Farnesylthiosalicylic Acid",
      "S-Farnesylthiosalicylic Acid",
      "UNII-MZH0OM550M",
      "MZH0OM550M",
      "CHEMBL23293",
      "AK186909",
      "Farnesyl Thiosalicylic Acid",
      "2-[[(2E,6E)-3,7,11-Trimethyl-2,6,10-Dodecatrien-1-Yl]thio]benzoic Acid",
      "2-[(2E,6E)-3,7,11-Trimethyldodeca-2,6,10-Trienyl]sulfanylbenzoic Acid",
      "2-((2E,6E)-3,7,11-Trimethyldodeca-2,6,10-Trienylthio)Benzoic Acid",
      "2-(((2E,6E)-3,7,11-Trimethyl-2,6,10-Dodecatrienyl)Sulfanyl)Benzoic Acid",
      "Benzoic Acid, 2-(((2E,6E)-3,7,11-Trimethyl-2,6,10-Dodecatrienyl)Thio)-",
      "FTS",
      "Farnesylthiosalicyclic Acid",
      "FTS, Thyreos",
      "Ras Antagonists, Thyreos",
      "S-Trans",
      "Th-101",
      "Trans-Farnesylthiosalicylicacid",
      "FTS (Oral, Cancer), Concordia",
      "Farnesylthiosalicyclic Acid (Oral, Cancer), Concordia",
      "Ras-Inhibitors (Cancer), Concordia",
      "FTS (Oral, Cancer), Concordia/Ono",
      "KD032",
    ],
  },
  {
    primary_id: "D00KUD",
    names: ["ADL 10-0101"],
  },
  {
    primary_id: "D00KVE",
    names: ["Cutamesine", "AGY-94806", "Msc-1", "SA-4502", "SA-4503", "Sigma Opioid Receptor Agonist, Santen"],
  },
  {
    primary_id: "D00KVH",
    names: ["ISIS 11155"],
  },
  {
    primary_id: "D00KVO",
    names: [
      "Albendazole",
      "Albendazol",
      "Albendazolum",
      "Albendoral",
      "Albenza",
      "Bendapar",
      "Bilutac",
      "Digezanol",
      "Disthelm",
      "Endoplus",
      "Eskazole",
      "Gascop",
      "Lurdex",
      "Metiazol",
      "Proftril",
      "Valbazen",
      "Zental",
      "Zentel",
      "Albendazole Armstrong Brand",
      "Albendazole Diba Brand",
      "Albendazole Fustery Brand",
      "Albendazole Hormona Brand",
      "Albendazole Liferpal Brand",
      "Albendazole Monohydrochloride",
      "Albendazole Pfizer Brand",
      "Albendazole Sanicoopa Brand",
      "Albendazole Valdecasas Brand",
      "Armstrong Brand Of Albendazole",
      "Diba Brand Of Albendazole",
      "Fustery Brand Of Albendazole",
      "Hormona Brand Of Albendazole",
      "Liferpal Brand Of Albendazole",
      "Mediamix V Disthelm",
      "Noe Socopharm Brand Of Albendazole",
      "Pfizer Brand Of Albendazole",
      "Sanicoopa Brand Of Albendazole",
      "SmithKline Beecham Brand Of Albendazole",
      "Valdecasas Brand Of Albendazole",
      "SKF 62979",
      "SKF62979",
      "Albendazol [INN-Spanish]",
      "Albendazole Noe-Socopharm Brand",
      "Albendazolum [INN-Latin]",
      "Albenza (TN)",
      "Disthelm, Mediamix V",
      "Eskazole (TN)",
      "Monohydrochloride, Albendazole",
      "Noe-Socopharm Brand Of Albendazole",
      "SK&F 62979",
      "SK&F62979",
      "SKF-62979",
      "Smith Kline & French Brand Of Albendazole",
      "V Disthelm, Mediamix",
      "Zentel (TN)",
      "SK&F-62979",
      "Albendazole (JAN/USP/INN)",
      "Albendazole [USAN:INN:BAN:JAN]",
      "Albenza, Eskazole, Zentel, Andazol, Albendazole",
      "Methyl 5-(Propylthio)-2-Benzimidazolecarbamate",
      "Methyl [5-(Propylthio)Benzimidazol-2-Yl]carbamate",
      "Methyl-5-[propylthio]-2-Benzimidazole Carbamate",
      "[5-(Propylthio)Benzimidazol-2-Yl]carbamic Acid Methyl Ester",
      "Methyl 5-(Propyl-Thio)-2-Benzimidazolecarbamate",
      "Methyl [5-(Propylsulfanyl)-1H-Benzimidazol-2-Yl]carbamate",
      "Methyl [5-(Propylthio)-1H-Benzimidazol-2-Yl]carbamate",
      "Methyl [6-(Propylsulfanyl)-1H-Benzimidazol-2-Yl]carbamate",
      "[5-(Propythio)-1H-Benzimidazol-2-Yl]carbamic Acid Methyl Ester",
      "MethylN-(6-Propylsulfanyl-1H-Benzimidazol-2-Yl)Carbamate",
      "O-Methyl N-(5-(Propylthio)-2-Benzimidazolyl)Carbamate",
      "Carbamic Acid, [5-(Propylthio)-1H-Benzimidazol-2-Yl]-, Methyl Ester",
      "Methyl N-[6-(Propylsulfanyl)-1H-1,3-Benzodiazol-2-Yl]carbamate",
      "CARBAMIC ACID, (5-(PROPYLTHIO)-1H-BENZIMIDAZOL-2-YL)-, METHYL ESTER",
      "Methyl [(2Z)-5-(Propylsulfanyl)-1,3-Dihydro-2H-Benzimidazol-2-Ylidene]carbamate",
      "((Propylthio)-5 1H-Benzimidazolyl-2) Carbamate De Methyle",
      "((Propylthio)-5 1H-Benzimidazolyl-2) Carbamate De Methyle [French]",
      "(5-(Propylthio)-1H-Benzimidazol-2-Yl)Carbamic Acid Methyl Ester",
      "(5-Propylsulfanyl-1H-Benzoimidazol-2-Yl)-Carbamic Acid Methyl Ester",
      "5-(Propylthio)-2-Carbomethoxyaminobenzimidazole",
    ],
  },
  {
    primary_id: "D00KVX",
    names: ["LP-261"],
  },
  {
    primary_id: "D00KWA",
    names: ["Recainam"],
  },
  {
    primary_id: "D00KWF",
    names: ["PYN-9"],
  },
  {
    primary_id: "D00KWL",
    names: [
      "1-[5-(Triphenylmethoxy)Pentyl]thymine",
      "CHEMBL216997",
      "921587-92-0",
      "CTK3G1845",
      "DTXSID10582665",
      "BDBM50201001",
      "5-Methyl-1-[5-(Triphenylmethoxy)Pentyl]pyrimidine-2,4(1H,3H)-Dione",
      "2,4(1H,3H)-Pyrimidinedione, 5-Methyl-1-[5-(Triphenylmethoxy)Pentyl]-",
    ],
  },
  {
    primary_id: "D00KXJ",
    names: ["PMID26666870-Compound-16"],
  },
  {
    primary_id: "D00KXO",
    names: ["Ciraparantag"],
  },
  {
    primary_id: "D00KXQ",
    names: ["LF-13-0491c"],
  },
  {
    primary_id: "D00KXZ",
    names: [
      "CGP 54626A",
      "Cyclohexylmethyl-[(2S)-3-[[(1S)-1-(3,4-Dichlorophenyl)Ethyl]amino]-2-Hydroxypropyl]phosphinic Acid",
      "Cgp-54626",
      "Cgp 54626",
      "CGP54626",
      "[3H]CGP54626",
      "[3H]CGP 54626",
      "Tocris-1088",
      "NCGC00024989-01",
      "Biomol-NT_000240",
      "AC1L53OP",
      "GTPL1070",
      "SCHEMBL2957659",
      "GTPL1090",
      "BPBio1_000640",
      "CHEMBL1213187",
      "CGP-54626A",
      "ZINC31544793",
      "NCGC00024989-02",
      "CGP-54626",
    ],
  },
  {
    primary_id: "D00KZF",
    names: [
      "BMY-7378",
      "Bmy-7378",
      "UNII-08EI0K81OL",
      "CHEMBL13647",
      "08EI0K81OL",
      "8-(2-[4-(2-Methoxyphenyl)-1-Piperazinyl]ethyl)-8-Azaspiro[4.5]decane-7,9-Dione",
      "BMY7378",
      "8-(2-(4-(2-Methoxyphenyl)-1-Piperazinyl)Ethyl)-8-Azaspiro(4.5)Decane-7,9-Dione",
      "21102-94-3",
      "8-[2-[4-(2-Methoxyphenyl)-1-Piperazinyl]ethyl]-8-Azaspiro[4.5]decane-7,9-Dione",
      "8-(2-(4-(2-Methoxyphenyl)Piperazin-1-Yl)Ethyl)-8-Azaspiro[4.5]decane-7,9-Dione",
      "8-[2-[4-(2-Methoxyphenyl)Piperazin-1-Yl]ethyl]-8-Azaspiro[4.5]decane-7,9-Dione",
      "8-Azaspiro(4.5)Decane-7,9-Dione, 8-(2-(4-(2-Methox",
    ],
  },
  {
    primary_id: "D00KZO",
    names: ["Erybreadin C"],
  },
  {
    primary_id: "D00LAA",
    names: ["VA-118020"],
  },
  {
    primary_id: "D00LAR",
    names: ["BMS-404683"],
  },
  {
    primary_id: "D00LBC",
    names: ["Triazole Gold Complexe 1"],
  },
  {
    primary_id: "D00LBD",
    names: ["Metazosin"],
  },
  {
    primary_id: "D00LBX",
    names: [
      "Bicuculline",
      "(+)-Bicuculline",
      "D-Bicuculline",
      "485-49-4",
      "Bicculine",
      "Bicucullin",
      "Bicuculline (+)",
      "UNII-Y37615DVKC",
      "NSC 32192",
      "C20H17NO6",
      "EINECS 207-619-7",
      "BRN 0098786",
      "CHEMBL417990",
      "CHEBI:3092",
      "Y37615DVKC",
      "(6R)-6-[(5S)-6-Methyl-5,6,7,8-Tetrahydro[1,3]dioxolo[4,5-G]isoquinolin-5-Yl]furo[3,4-E][1,3]benzodioxol-8(6H)-One",
      "NSC32192",
      "Furo(3,4-E)-1,3-Benzodioxol-8(6H)-One, 6-(5,6,7,8-Tetrahydro-6-Methyl-1,3-Dioxolo(4,5-G)Isoquinolin-5-Yl)-, (R-(R*,S*))-",
    ],
  },
  {
    primary_id: "D00LCE",
    names: ["Antiproliferative Agent A771726", "AC1L1BPO", "2-Cyano-3-Hydroxy-N-(4-Trifluoromethyl-Phenyl)-Butyramide"],
  },
  {
    primary_id: "D00LCT",
    names: ["Symakalim"],
  },
  {
    primary_id: "D00LDB",
    names: [
      "3,5-Diiodothyropropionic Acid (Allan-Herndon-Dudley Syndrome), Zarion Pharmaceuticals",
      "DITPA (AHDS), Zarion Pharmaceuticals",
      "Thyroid Hormone Analog (Allan-Herndon-Dudley Syndrome), Zarion Pharmaceuticals",
    ],
  },
  {
    primary_id: "D00LDI",
    names: [
      "UPA-Targeted Oncolytic Sendai Virus",
      "BioKnife",
      "RSeV/Fct14(UPA2)DM",
      "UPA-Targeted Oncolytic Sendai Virus, DNAVEC",
    ],
  },
  {
    primary_id: "D00LEN",
    names: ["Sc-43077"],
  },
  {
    primary_id: "D00LEQ",
    names: ["FANA"],
  },
  {
    primary_id: "D00LFB",
    names: ["Ritodrine"],
  },
  {
    primary_id: "D00LFX",
    names: ["Estradiol 17-O-Sulfamate", "CHEMBL220493", "Estradiol-17-O-Sulfamate", "SCHEMBL628173", "BDBM50200938"],
  },
  {
    primary_id: "D00LGA",
    names: ["NN-8226"],
  },
  {
    primary_id: "D00LGR",
    names: ["TP-0903"],
  },
  {
    primary_id: "D00LHF",
    names: ["GS-9219"],
  },
  {
    primary_id: "D00LHI",
    names: [
      "BAY 794980",
      "BAY-794980",
      "Kogenate FS Liposome",
      "Kogenate Liposomal",
      "NecLip-RFVIII",
      "PEGLip-Formulated Exogenous FVIII - Bayer/Zilip-Pharma",
      "PEGLip-FVIII FS - Bayer/Zilip-Pharma",
      "PEGylated Octocog Alfa Sucrose - Bayer/Zilip-Pharma",
    ],
  },
  {
    primary_id: "D00LHN",
    names: ["Sulfonylurea"],
  },
  {
    primary_id: "D00LHS",
    names: ["TW-37"],
  },
  {
    primary_id: "D00LJF",
    names: ["INT0001/2004"],
  },
  {
    primary_id: "D00LLI",
    names: ["Candistat-G"],
  },
  {
    primary_id: "D00LLN",
    names: ["PMID28092474-Compound-33a"],
  },
  {
    primary_id: "D00LLO",
    names: ["H-Dmt-Tic-Lys-NH-Ph"],
  },
  {
    primary_id: "D00LMA",
    names: ["Heteroaryl-Carboxamide Derivative 4"],
  },
  {
    primary_id: "D00LMG",
    names: ["SCUTELLAREIN"],
  },
  {
    primary_id: "D00LMR",
    names: ["AP-006"],
  },
  {
    primary_id: "D00LMT",
    names: ["1'-(4-Methyl-Benzyl)-[1,4']bipiperidinyl", "CHEMBL399558", "Tyrosinase Inhibitor, 4", "BDBM50218211"],
  },
  {
    primary_id: "D00LMY",
    names: ["RO-5212651"],
  },
  {
    primary_id: "D00LNK",
    names: ["Org 24598"],
  },
  {
    primary_id: "D00LNW",
    names: ["KPT-330"],
  },
  {
    primary_id: "D00LOB",
    names: ["RWJ-671818"],
  },
  {
    primary_id: "D00LOL",
    names: ["PMID23849879C3"],
  },
  {
    primary_id: "D00LPZ",
    names: [
      "Vapill",
      "BTT-2026",
      "BTT-2027",
      "BTT-2052",
      "SzV-1287",
      "SzV-1928",
      "SzV-2007",
      "SzV-626",
      "VAP-1 SSAO Inhibitor, BioTie",
      "VAP-1 SSAO Inhibitors (Inflammatory Diseases)",
      "VAP-1 SSAO Inhibitors (Inflammatory Diseases), BioTie/Seikagaku",
    ],
  },
  {
    primary_id: "D00LRC",
    names: ["3-Cyclopentyl-6,7-Dimethoxy-Quinoline", "CHEMBL66785", "SCHEMBL8801444", "ZINC3834044"],
  },
  {
    primary_id: "D00LRP",
    names: ["PRS-080"],
  },
  {
    primary_id: "D00LSD",
    names: ["SERX-1"],
  },
  {
    primary_id: "D00LSI",
    names: ["Vidofludimus"],
  },
  {
    primary_id: "D00LSM",
    names: ["ISIS 112000"],
  },
  {
    primary_id: "D00LSQ",
    names: ["PMID26815044-Compound-126"],
  },
  {
    primary_id: "D00LSW",
    names: ["VA-111913"],
  },
  {
    primary_id: "D00LSZ",
    names: [
      "N,8-Dihydroxy-8-(Naphthalen-2-Yl)Octanamide",
      "CHEMBL319738",
      "SCHEMBL3382032",
      "JWCSCYWHCCHTEF-UHFFFAOYSA-N",
      "BDBM50114820",
      "N-Hydroxy-8-Hydroxy-8-(2-Naphthyl)Octanamide",
      "8-Hydroxy-8-Naphthalen-2-Yl-Octanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D00LVA",
    names: ["Dianicline"],
  },
  {
    primary_id: "D00LVW",
    names: ["Ada-RYYRIK-NH2", "CHEMBL256687"],
  },
  {
    primary_id: "D00LWD",
    names: ["AF-3473", "5-HT4 Antagonist (Neuropathic Pain), Angelini Pharmaceuticals"],
  },
  {
    primary_id: "D00LWF",
    names: [
      "P-1075",
      "60559-98-0",
      "P1075",
      "N-Cyano-N'-(1,1-Dimethylpropyl)-N''-3-Pyridylguanidine",
      "BRN 0480738",
      "2-Cyano-1-Tert-Pentyl-3-(3-Pyridyl)Guanidine",
      "CHEMBL11458",
      "Guanidine, N-Cyano-N'-(1,1-Dimethylpropyl)-N''-3-Pyridinyl-",
      "N-Cyano-N'-(1,1-Dimethylpropyl)-N''-(3-Pyridinyl)Guanidine",
      "P 1075",
      "GUANIDINE, 2-CYANO-1-Tert-PENTYL-3-(3-PYRIDYL)-",
      "Guanidine, N-Cyano-N'-(1,1-Dimethylpropyl)-N''-3-Pyridinyl- [CAS]",
      "SMR000466288",
      "Tocris-1355",
      "AC1L29NR",
      "MLS000758317",
      "MLS001424147",
      "SCHEMBL2129487",
      "SCHEMBL8747731",
      "BDBM86250",
      "CTK8J5792",
      "CTK2F3712",
    ],
  },
  {
    primary_id: "D00LWG",
    names: ["[Sar1,Tdf8]AngII", "CHEMBL1076633"],
  },
  {
    primary_id: "D00LWQ",
    names: [
      "3-Bromo-2'-Hydroxy-4-Methoxychalcone",
      "AC1ORO2M",
      "CHEMBL492941",
      "MolPort-000-648-211",
      "ZINC5549056",
      "STL192121",
      "AKOS025268524",
      "(E)-3-(3-Bromo-4-Methoxyphenyl)-1-(2-Hydroxyphenyl)Prop-2-En-1-One",
    ],
  },
  {
    primary_id: "D00LWR",
    names: ["BVT-A"],
  },
  {
    primary_id: "D00LWS",
    names: ["Ataprost"],
  },
  {
    primary_id: "D00LWX",
    names: ["Promegapoietin"],
  },
  {
    primary_id: "D00LWY",
    names: ["PMID28447479-Compound-24"],
  },
  {
    primary_id: "D00LXB",
    names: ["Panaecin"],
  },
  {
    primary_id: "D00LXD",
    names: ["Omega Interferon"],
  },
  {
    primary_id: "D00LXH",
    names: [
      "(R,S)-1-(5-Bromo-1H-Indol-1-Yl)Propan-2-Amine",
      "CHEMBL276673",
      "1H-Indole-1-Ethanamine, 5-Bromo-Alpha-Methyl-",
      "SCHEMBL8959875",
      "BDBM50321879",
      "169675-12-1",
    ],
  },
  {
    primary_id: "D00LYE",
    names: ["GC-003"],
  },
  {
    primary_id: "D00LYI",
    names: ["Meta-Sirtinol"],
  },
  {
    primary_id: "D00LYW",
    names: [
      "BW-1003C87",
      "144425-86-5",
      "5-(2,3,5-Trichlorophenyl)Pyrimidine-2,4-Diamine Ethane Sulfonate",
      "Ethanesulfonic Acid, Compd. With 5-(2,3,5-Trichlorophenyl)-2,4-Pyrimidinediamine (1:1)",
      "BW 1003C87",
      "ACMC-20n3z2",
      "AC1L31BW",
      "SCHEMBL15914195",
      "CTK0H6679",
      "DTXSID70162705",
      "AKOS030595718",
      "Ethanesulfonic Acid",
    ],
  },
  {
    primary_id: "D00LZH",
    names: ["Lymphocyte-Specific Tyrosine Kinase Inhibitors"],
  },
  {
    primary_id: "D00LZJ",
    names: ["JWH-405"],
  },
  {
    primary_id: "D00LZM",
    names: ["CP1"],
  },
  {
    primary_id: "D00LZO",
    names: ["CZC-19091"],
  },
  {
    primary_id: "D00LZS",
    names: ["KMI-538"],
  },
  {
    primary_id: "D00LZY",
    names: ["AA-184", "BMP Agonist (Acute Kidney Injury), Thrasos Therapeutics"],
  },
  {
    primary_id: "D00MAI",
    names: [
      "[3H]nicotinic Acid",
      "[3H]-Nicotinic Acid",
      "Akotin",
      "Anti-Pellagra Vitamin",
      "Apelagrin",
      "Bionic",
      "Daskil",
      "Davitamon PP",
      "Diacin",
      "Direktan",
      "Efacin",
      "Kyselina Nikotinova",
      "Linic",
      "Naotin",
      "Niac",
      "Niaspan",
      "Nicacid",
      "Nicangin",
      "NICO",
      "Nico-Span",
      "Nicocidin",
      "Nicocrisina",
      "Nicodan",
      "Nicodelmine",
      "Nicodon",
      "Niconacid",
      "Niconat",
      "Niconazid",
      "Nicorol",
      "Nicosan3",
      "Nicoside",
      "Nicosyl",
      "Nicotamin",
      "Nicotene",
      "Nicotil",
      "Nicotinipca",
      "Nicotinsaure",
      "Nicovasan",
      "Nicovasen",
      "Nicyl",
      "Nipellen",
      "Nyclin",
      "P.P. Factor",
      "Pellagra Preventive Factor",
      "Pellagramin",
      "Pellagrin",
      "Pelonin;Peviton",
      "PP Factor",
      "Simcor",
      "Sk-Niacin",
      "Tega-Span",
      "Tinic",
      "Vitaplex N",
    ],
  },
  {
    primary_id: "D00MAM",
    names: ["AMG-JAK2-01"],
  },
  {
    primary_id: "D00MAN",
    names: ["GRC-6211"],
  },
  {
    primary_id: "D00MBG",
    names: ["BRL50481", "BRL-50481", "BRL 50481"],
  },
  {
    primary_id: "D00MBR",
    names: ["Iratumumab"],
  },
  {
    primary_id: "D00MBY",
    names: [
      "AAD-2004",
      "927685-43-6",
      "UNII-11VWK61J69",
      "11VWK61J69",
      "2-Hydroxy-5-((4-(Trifluoromethyl)Phenethyl)Amino)Benzoic Acid",
      "Benzoic Acid, 2-Hydroxy-5-((2-(4-(Trifluoromethyl)Phenyl)Ethyl)Amino)-",
      "Benzoic Acid, 2-Hydroxy-5-[[2-[4-(Trifluoromethyl)Phenyl]ethyl]amino]-",
      "UTMVACIBQLDZLP-UHFFFAOYSA-N",
      "SCHEMBL608498",
      "ZINC34885635",
      "AKOS027338686",
      "SB16954",
      "AS-35180",
      "2-HYDROXY-5-(2-(4-TRIFLUOROMETHYL-PHENYL)-ETHYLAMINO)-BENZOIC ACID",
    ],
  },
  {
    primary_id: "D00MCA",
    names: ["BB-PTH 1-84", "Aursos BB", "BB-PTH 1-34", "Black Bear Parathyroid Hormone (Osteoporosis), Aursos"],
  },
  {
    primary_id: "D00MCG",
    names: ["2-(1H-Indol-5-Yl)-6-Morpholino-4H-Pyran-4-One", "CHEMBL222745"],
  },
  {
    primary_id: "D00MCJ",
    names: [
      "6-(1H-Benzoimidazol-2-Yl)-Benzocyclohepten-7-One",
      "Benzoannulenone 1",
      "AC1NS6NT",
      "CHEMBL327029",
      "BDBM5326",
      "6-(1H-Benzimidazol-2-Yl)Benzo[7]annulen-7-One",
    ],
  },
  {
    primary_id: "D00MCN",
    names: ["AGS-16M8F"],
  },
  {
    primary_id: "D00MCY",
    names: ["REC2615"],
  },
  {
    primary_id: "D00MDP",
    names: [
      "Prinomastat",
      "AG-3354",
      "AG-3362",
      "Prinomastat (USAN/INN)",
      "(3S)-N-Hydroxy-2,2-Dimethyl-4-(4-Pyridin-4-Yloxyphenyl)Sulfonylthiomorpholine-3-Carboxamide",
    ],
  },
  {
    primary_id: "D00MDQ",
    names: ["Eltanexor Oral"],
  },
  {
    primary_id: "D00MEM",
    names: [
      "H-Dmt-Tic-Gly-NH-CH2-Bid",
      "(3S)-N-[(1H-Benzimidazole-2-Ylmethyl)Carbamoylmethyl]-2-[(S)-3-(2,6-Dimethyl-4-Hydroxyphenyl)-2-Aminopropionyl]-1,2,3,4-Tetrahydroisoquinoline-3-Carboxamide",
    ],
  },
  {
    primary_id: "D00MET",
    names: ["Recombinant Uteroglobin"],
  },
  {
    primary_id: "D00MEV",
    names: [
      "Sch-45752",
      "Cephalochromin",
      "MLS000876993",
      "CHEMBL463175",
      "NSC627953",
      "NSC201419",
      "25908-26-3",
      "SMR000440611",
      "Cefalochromin",
      "5,6,8-Trihydroxy-2-Methyl-9-(5,6,8-Trihydroxy-2-Methyl-4-Oxo-2,3-Dihydrobenzo[g]chromen-9-Yl)-2,3-Dihydrobenzo[g]chromen-4-One",
      "Sch 45752",
      "AC1Q6DTF",
      "AC1L4N2K",
      "SCHEMBL1111561",
      "MEGxm0_000312",
      "Cid_160115",
      "ACon0_000585",
      "ACon1_000126",
      "MolPort-001-739-590",
      "HMS2270H11",
      "BDBM50269745",
      "NSC-201419",
      "NSC-627953",
      "MCULE-6609850631",
      "NCGC00180856-01",
      "SR-01000758207",
      "SR-01000758207-2",
      "CEPHALOCHROMIN",
    ],
  },
  {
    primary_id: "D00MFA",
    names: ["Aspartame", "D,D-Aspartame", "SCHEMBL216238"],
  },
  {
    primary_id: "D00MFR",
    names: ["6-Methoxy-1,2-Dimethyl-9H-Beta-Carbolin-2-Ium", "CHEMBL374729"],
  },
  {
    primary_id: "D00MFV",
    names: ["9-(Sec-Butyl)-9H-Adenine", "CHEMBL466044", "SCHEMBL240049"],
  },
  {
    primary_id: "D00MFY",
    names: ["GPI-3000"],
  },
  {
    primary_id: "D00MFZ",
    names: ["L-Isofagomine"],
  },
  {
    primary_id: "D00MGO",
    names: ["PMID28270021-Compound-WO2016054807Example1"],
  },
  {
    primary_id: "D00MGT",
    names: ["SRA880"],
  },
  {
    primary_id: "D00MHP",
    names: ["SR-2640"],
  },
  {
    primary_id: "D00MIF",
    names: ["JTP-4761"],
  },
  {
    primary_id: "D00MIG",
    names: ["MJ-451", "129655-17-0", "Potassium Channel Openers, National Taiwan University"],
  },
  {
    primary_id: "D00MIM",
    names: ["FR-122788"],
  },
  {
    primary_id: "D00MIN",
    names: ["Propylthiouracil"],
  },
  {
    primary_id: "D00MIX",
    names: ["CP-114271"],
  },
  {
    primary_id: "D00MJG",
    names: ["PMID16242323C16"],
  },
  {
    primary_id: "D00MJI",
    names: ["Emactuzumab"],
  },
  {
    primary_id: "D00MLE",
    names: ["2-(4-Phenoxy-Phenyl)-1H-Benzoimidazol-5-Ylamine", "CHEMBL179383"],
  },
  {
    primary_id: "D00MLJ",
    names: ["Pyrazole Derivative 61"],
  },
  {
    primary_id: "D00MLN",
    names: ["(R,S)-Homoaromaline Hydrochloride", "CHEMBL443953", "(R,S)-Homoaromaline HCl"],
  },
  {
    primary_id: "D00MLQ",
    names: ["Protosufloxacin", "A-71497"],
  },
  {
    primary_id: "D00MLW",
    names: ["Triheptanoin"],
  },
  {
    primary_id: "D00MLX",
    names: [
      "Abacavir/Dolutegravir/Lamivudine",
      "Abacavir Hydrochloride",
      "CTK8F7483",
      "{4-[2-Amino-6-(Cyclopropylamino)-9H-Purin-9-Yl]cyclopent-2-En-1-Yl}methanol Hydrochloride",
    ],
  },
  {
    primary_id: "D00MMV",
    names: ["Enoticumab"],
  },
  {
    primary_id: "D00MNE",
    names: ["Abyssinone-IV", "CHEMBL470454", "Abyssinone IV", "AC1OK505", "ZINC4716488", "BDBM50241806"],
  },
  {
    primary_id: "D00MNG",
    names: ["HIV Gp41 Vaccine"],
  },
  {
    primary_id: "D00MPA",
    names: [
      "6-(Furan-2-Yl)-9H-Purin-2-Amine",
      "CHEMBL404447",
      "6-(2-Furanyl)-9h-Purin-2-Amine",
      "442682-77-1",
      "SCHEMBL3272888",
      "6-(Furanyl)-9h-Purin-2-Amine",
      "JZSMPSCWNAYWOI-UHFFFAOYSA-N",
      "6-(2-Furyl)1H-Purine-2-Amine",
      "6-(2-Furyl)-1H-Purine-2-Amine",
    ],
  },
  {
    primary_id: "D00MPM",
    names: [
      "Broussonin C",
      "76045-49-3",
      "CHEBI:3186",
      "CHEMBL468906",
      "AC1L9CJQ",
      "MolPort-039-052-705",
      "ZINC898642",
      "BDBM50254430",
      "9122AF",
      "1,3-Benzenediol,4-[3-[4-Hydroxy-3-(3- Methyl-2-Butenyl)Phenyl]propyl]-",
    ],
  },
  {
    primary_id: "D00MPX",
    names: ["JX-1395"],
  },
  {
    primary_id: "D00MQS",
    names: [
      "PD-165167",
      "CHEMBL332154",
      "SCHEMBL8169927",
      "BDBM50080797",
      "7-[[(2-Anilinoethyl)Amino]methyl]coumarin",
      "L017812",
    ],
  },
  {
    primary_id: "D00MRH",
    names: ["MDPTQ", "Apoptosis Stimulator (Leukemia)", "Apoptosis Stimulator (Leukemia), Reliance Life Sciences"],
  },
  {
    primary_id: "D00MSP",
    names: ["FR-149581", "Angiotensin II Antagonist, Fujisawa"],
  },
  {
    primary_id: "D00MSU",
    names: ["8-(2-Cyclopropyl-Ethoxy)-Quinolin-2-Ylamine", "CHEMBL186949", "SCHEMBL5884150"],
  },
  {
    primary_id: "D00MTG",
    names: ["SAR279356"],
  },
  {
    primary_id: "D00MUC",
    names: ["CD19 CAR-T Cells"],
  },
  {
    primary_id: "D00MUJ",
    names: [
      "2-(Phenylsulfonamido)-5-Propylbenzoic Acid",
      "2-Benzenesulfonamido-5-Propylbenzoic Acid",
      "Sulfonamide Compound, 7",
      "SCHEMBL3984242",
      "CHEMBL209071",
      "BDBM17601",
      "MolPort-009-343-156",
      "ZINC14965014",
      "AKOS034553679",
      "MCULE-3140792066",
      "Z738155172",
    ],
  },
  {
    primary_id: "D00MVK",
    names: ["Ro-48-8391"],
  },
  {
    primary_id: "D00MVM",
    names: ["Salbutamol Dry-Powder Inhalation"],
  },
  {
    primary_id: "D00MWE",
    names: ["UX-004"],
  },
  {
    primary_id: "D00MXN",
    names: [
      "MK-3207",
      "957116-20-0",
      "MK-3207 Hydrochloride",
      "MK-3207 (Hydrochloride)",
      "MK-3207 HCl",
      "MK-3207 HCl Salt",
      "2-((R)-8-(3,5-Difluorophenyl)-10-Oxo-6,9-Diazaspiro[4.5]decan-9-Yl)-N-((R)-2'-Oxo-1,1',2',3-Tetrahydrospiro[indene-2,3'-Pyrrolo[2,3-B]pyridin]-5-Yl)Acetamide Hydrochloride",
      "MK3207",
      "(8R)-8-(3,5-Difluorophenyl)-10-Oxo-N-[(2r)-1,1',2',3-Tetrahydro-2'-Oxospiro[2h-Indene-2,3'-[3h]pyrrolo[2,3-B]pyridin]-5-Yl]-6,9-Diazaspiro[4.5]decane-9-Acetamide Hydrochloride",
      "MK 3207 Hydrochloride",
      "MK-3207(Hydrochloride)",
      "MK3207 HCl",
    ],
  },
  {
    primary_id: "D00MXR",
    names: ["PMID26924192-Compound-23"],
  },
  {
    primary_id: "D00MXS",
    names: ["WOGONIN"],
  },
  {
    primary_id: "D00MXW",
    names: ["8-(2-Acetylphenyl)-2-Morpholin-4-Ylchromen-4-One", "CHEMBL197284"],
  },
  {
    primary_id: "D00MYQ",
    names: [
      "Protriptyline",
      "Amimetilina",
      "Impril",
      "Maximed",
      "Novopramine",
      "Protriptilina",
      "Protriptylinum",
      "Protryptyline",
      "Rhotrimine",
      "Triptil",
      "Triptyl",
      "Vivactil",
      "Vivactyl",
      "Triptil Hydrochloride",
      "Normethyl EX4442",
      "Triadapin 5",
      "Apo-Imipramine",
      "Apo-Trimip",
      "Novo-Doxepin",
      "Novo-Tripramine",
      "Protriptilina [INN-Spanish]",
      "Protriptyline (INN)",
      "Protriptyline [INN:BAN]",
      "Protriptylinum [INN-Latin]",
      "Vivactil (TN)",
      "N-Methyl-5H-Dibenzo[a,d]cycloheptene-5-Propanamine",
      "N-Methyl-5H-Dibenzo[a,d]cycloheptene-5-Propylamine",
      "N-Methyl-5H-Dibenzo(A,d)Cycloheptene-5-Propylamine",
      "N-3-(5H-Dibenzo(A,d)Cyclohepten-5-Yl)Propyl-N-Methylamine",
      "3-(5H-Dibenzo[a,d]cyclohepten-5-Yl)-N-Methyl-1-Propanamine",
      "3-(5H-Dibenzo[a,d][7]annulen-5-Yl)-N-Methylpropan-1-Amine",
      "5-(3-Methylaminopropyl)-5H-Dibenzo[a,d]cycloheptene",
      "5-(3-Methylaminopropyl)-5H-Dibenzo(A,d)Cycloheptene",
      "5H-Dibenzo[a, D]cycloheptene-5-Propanamine, N-Methyl-, Hydrochloride",
      "7-(3-Methylaminopropyl)-1,2:5,6-Dibenzocycloheptatriene",
    ],
  },
  {
    primary_id: "D00MYT",
    names: ["Thiopental"],
  },
  {
    primary_id: "D00MYW",
    names: ["NS-018"],
  },
  {
    primary_id: "D00MZC",
    names: ["LTX-100"],
  },
  {
    primary_id: "D00MZD",
    names: ["7n-Methyl-8-Hydroguanosine-5'-Diphosphate"],
  },
  {
    primary_id: "D00MZK",
    names: [
      "FK-788",
      "2-[6(R)-(N,N-Diphenylcarbamoyloxymethyl)-6-Hydroxy-5,6,7,8-Tetrahydronaphthalen-1-Yloxy]acetic Acid",
    ],
  },
  {
    primary_id: "D00MZS",
    names: ["SK509"],
  },
  {
    primary_id: "D00NAX",
    names: [
      "Promazine",
      "Ampazine",
      "Berophen",
      "Esparin",
      "Liranol",
      "Prazin",
      "Prazine",
      "Promazin",
      "Promazina",
      "Promazinum",
      "Promwill",
      "Propazinum",
      "Protactyl",
      "Romtiazin",
      "Sinophenin",
      "Tomil",
      "Verophen",
      "Combelen [veterinary]",
      "Promazina [Italian]",
      "A 145",
      "RP 3276",
      "WY 1094",
      "Combelen [veterinary] (TN)",
      "Neo-Hibernex",
      "Promazina [INN-Spanish]",
      "Promazine (INN)",
      "Promazine [INN:BAN]",
      "Promazinum [INN-Latin]",
      "Sparine (TN)",
      "Wy-1094",
      "N-(3-Dimethylaminopropyl)Phenothiazine",
      "N-Dimethylamino-1-Methylethyl Thiodiphenylamine",
      "Dimethyl(3-Phenothiazin-10-Ylpropyl)Amine",
      "N,N-Dimethyl-3-Phenothiazin-10-Ylpropan-1-Amine",
      "N,N-Dimethyl-3-(10H-Phenothiazin-10-Yl)Propan-1-Amine",
      "10-(3-(Dimethylamino)Propyl)Phenothiazine",
      "10-[3-(Dimethylamino)Propyl]phenothiazine",
      "10H-Phenothiazine-10-Propanamine, N,N-Dimethyl-(9CI)",
      "10H-Phenothiazine-10-Propanamine, N,N-Dimethyl-, Monohydrochloride",
    ],
  },
  {
    primary_id: "D00NBF",
    names: ["GM06"],
  },
  {
    primary_id: "D00NBJ",
    names: ["Remetinostat"],
  },
  {
    primary_id: "D00NBW",
    names: ["ONCOS-102"],
  },
  {
    primary_id: "D00NCI",
    names: ["TGF Beta 2 Receptor Peptantagonists"],
  },
  {
    primary_id: "D00NCJ",
    names: ["Heterocycle-Containing Compound 5"],
  },
  {
    primary_id: "D00NCL",
    names: ["PMID21688779C22a"],
  },
  {
    primary_id: "D00NDF",
    names: ["PMID25399762-Compound-Table 7-3-Hydroxy-2-Butanone"],
  },
  {
    primary_id: "D00NEE",
    names: ["Bip-Tyr-Thr-Pro-Lys-Thr", "CHEMBL379496"],
  },
  {
    primary_id: "D00NEK",
    names: ["4-(3-Phenyl-1H-Indol-2-Yl)Phenol", "CHEMBL199766", "SCHEMBL7040440", "BDBM50175418"],
  },
  {
    primary_id: "D00NEL",
    names: ["BI-754091"],
  },
  {
    primary_id: "D00NFW",
    names: ["2,6-Diphenyl-8-Ethyl-1-Deazapurine", "CHEMBL222130"],
  },
  {
    primary_id: "D00NGN",
    names: ["Sabeluzole", "Arbetuzole", "R-58735"],
  },
  {
    primary_id: "D00NIT",
    names: ["YM 471"],
  },
  {
    primary_id: "D00NIV",
    names: ["ATYR-1920"],
  },
  {
    primary_id: "D00NIX",
    names: ["9-O-[3-(Phenylamino)Propyl]-Berberine Bromide", "CHEMBL1164163"],
  },
  {
    primary_id: "D00NJE",
    names: ["SFc"],
  },
  {
    primary_id: "D00NJJ",
    names: ["Mezlocillin"],
  },
  {
    primary_id: "D00NJL",
    names: [
      "Clofoctol",
      "37693-01-9",
      "Octofene",
      "Clofoctolum",
      "Clofoctol [INN]",
      "Clofoctolum [INN-Latin]",
      "C21H26Cl2O",
      "UNII-704083NI0R",
      "EINECS 253-632-6",
      "BRN 2478182",
      "Clofoctol (INN)",
      "2-((2,4-Dichlorophenyl)Methyl)-4-(1,1,3,3-Tetramethylbutyl)Phenol",
      "704083NI0R",
      "NCGC00095016-01",
      "2-(2,4-Dichlorobenzyl)-4-(1,1,3,3-Tetramethylbutyl)Phenol",
      "Alpha-(2,4-Dichlorophenyl)-4-(1,1,3,3-Tetramethylbutyl)-O-Cresol",
      "2-[(2,4-Dichlorophenyl)Methyl]-4-(2,4,4-Trimethylpentan-2-Yl)Phenol",
      "Phenol, 2-((2,4-Dichlorophenyl)Methyl)-4-(1,1,3,3-Tetramethylbutyl)-",
    ],
  },
  {
    primary_id: "D00NKB",
    names: [
      "Triamterene",
      "Ademin",
      "Ademine",
      "Amteren",
      "Anjal",
      "Dazid",
      "Diarol",
      "Dinazide",
      "Diren",
      "Ditak",
      "Diucelpin",
      "Diurene",
      "Diutensat",
      "Diuteren",
      "Dyberzide",
      "Dyren",
      "Dyrenium",
      "Dytac",
      "Dytenzide",
      "Esiteren",
      "Hidiurese",
      "Hydrene",
      "Hypertorr",
      "Isobar",
      "Jatropur",
      "Jenateren",
      "Kalspare",
      "Masuharmin",
      "Nephral",
      "Noridil",
      "Noridyl",
      "Pterofen",
      "Pterophene",
      "Renezide",
      "Reviten",
      "Taturil",
      "Teriam",
      "Teridin",
      "Triamizide",
      "Triampur",
      "Triamteren",
      "Triamterena",
      "Triamterenum",
      "Triamteril",
      "Triamterine",
      "Triamthiazid",
      "Tricilone",
      "Trispan",
      "Triteren",
      "Triurene",
      "Trizid",
      "Turfa",
      "Uretren",
      "Urocaudal",
      "Goldshield Brand Of Triamterene",
      "Jorba Brand Of Triamterene",
      "Pter Ophene",
      "SmithKline Beecham Brand Of Triamterene",
      "TRIAMTERENE USP",
      "Thiazid Wolff",
      "Triamteril Complex",
      "Wellspring Brand Of Triamterene",
      "Fluss 40",
      "SKF 8542",
      "T 4143",
      "Ademin(E)",
      "Apo-Triazide",
      "Dyrenium (TN)",
      "Pteridine Deriv. 11",
      "SALI-PUREN",
      "SK&F 8542",
      "SK-8542",
      "Tri-Span",
      "Triamterena [INN-Spanish]",
      "Triamterenum [INN-Latin]",
      "SK&F-8542",
      "Triamterene (JP15/USP/INN)",
      "Triamterene [USAN:INN:BAN:JAN]",
      "2,4,7-Triamino-6-Fenilpteridina",
      "2,4,7-Triamino-6-Fenilpteridina [Italian]",
      "2,4,7-Triamino-6-Phenylpteridine",
      "6-PHENYL-2,4,7-TRIAMINO PTERIDINE",
      "6-Phenyl-2,4,7-Pteridinetriamine",
      "6-Phenyl-2,4,7-Triaminopteridine",
      "6-Phenylpteridine-2,4,7-Triamine",
    ],
  },
  {
    primary_id: "D00NLD",
    names: ["L-741604"],
  },
  {
    primary_id: "D00NMB",
    names: ["Milatuzumab-Dox"],
  },
  {
    primary_id: "D00NNC",
    names: [
      "Etidronic Acid",
      "EHDP",
      "Etidronate",
      "Etidronsaeure",
      "HEDP",
      "Osteoscan",
      "Acetodiphosphonic Acid",
      "Acide Etidronique",
      "Acido Etidronico",
      "Acidumetidronicum",
      "Didronel IV",
      "Etidronic Acid Monohydrate",
      "Hydroxyethanediphosphonic Acid",
      "Oxyethylidenediphosphonic Acid",
      "Turpinal SL",
      "Dequest2010",
      "Dequest 2015",
      "Dequest Z 010",
      "Ferrofos 510",
      "RP 61",
      "Acide Etidronique [INN-French]",
      "Acido Etidronico [INN-Spanish]",
      "Acidum Etidronicum [INN-Latin]",
      "Diphosphonate (Base)",
      "Etidronic Acid (USAN/INN)",
      "Etidronic Acid [USAN:INN:BAN]",
      "Hydroxyethane-1,1-Diphosphonic Acid",
      "Ethane-1-Hydroxy-1,1-Bisphosphonate",
      "Ethane-1-Hydroxy-1,1-Bisphosphonic Acid",
      "Ethane-1-Hydroxy-1,1-Diphosphonate",
      "Ethane-1-Hydroxy-1,1-Diphosphonic Acid",
      "Phosphonic Acid, 1-Hydroxy-1,1-Ethanediyl Ester",
      "(1-Hydroxyethylene)Diphosphonic Acid",
      "(1-Hydroxyethylidene)Bis(Phosphonic Acid)",
      "(1-Hydroxyethylidene)Bisphosphonic Acid",
      "(1-Hydroxyethylidene)Diphoshonic Acid",
      "(1-Hydroxyethylidene)Diphosphonic Acid",
      "(1-Hydroxy-1-Phosphonoethyl)Phosphonic Acid",
      "(1-Hydroxyethane-1,1-Diyl)Bis(Phosphonic Acid)",
      "(Hydroxyethylidene)Diphosphonic Acid",
      "1,1,1-Ethanetriol Diphosphonate",
      "1-HYDROXY-1,1-DIPHOSPHONOETHANE",
      "1-Hydroxyethane-1,1,-Diphosphonic Acid",
      "1-Hydroxyethane-1,1-Bisphosphonic Acid",
      "1-Hydroxyethane-1,1-Diphosphonate",
      "1-Hydroxyethane-1,1-Diphosphonic Acid",
      "1-Hydroxyethanediphosphonic Acid",
      "1-Hydroxyethylidene 1,1-Diphosphonic Acid",
      "1-Hydroxyethylidene-1,1-Biphosphonate",
      "1-Hydroxyethylidene-1,1-Bisphosphonate",
      "1-Hydroxyethylidene-1,1-Diphosphonic Acid",
      "1-Hydroxyethylidenediphosphonic Acid",
      "1000SL",
    ],
  },
  {
    primary_id: "D00NNI",
    names: ["ASP-3652"],
  },
  {
    primary_id: "D00NNP",
    names: ["HI-172"],
  },
  {
    primary_id: "D00NOG",
    names: ["MK-11"],
  },
  {
    primary_id: "D00NOH",
    names: [
      "15-Deoxy-TIC",
      "GTPL5865",
      "SCHEMBL6085139",
      "5-[(3aS,5R,6R,6aS)-5-Hydroxy-6-[(E)-4-(3-Methylphenyl)But-1-Enyl]-1,3a,4,5,6,6a-Hexahydropentalen-2-Yl]pentanoic Acid",
      "(3aS)-4beta-[(E)-4-(3-Methylphenyl)-1-Butenyl]-5alpha-Hydroxy-3,3abeta,4,5,6,6abeta-Hexahydropentalene-2-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D00NOL",
    names: ["MK-0536"],
  },
  {
    primary_id: "D00NOP",
    names: ["OBP-401"],
  },
  {
    primary_id: "D00NOY",
    names: ["BetaRx"],
  },
  {
    primary_id: "D00NPE",
    names: ["MMP-408"],
  },
  {
    primary_id: "D00NPN",
    names: ["ISIS 29202"],
  },
  {
    primary_id: "D00NPO",
    names: ["ABT-199/GDC-0199"],
  },
  {
    primary_id: "D00NPP",
    names: ["Zanamivir"],
  },
  {
    primary_id: "D00NPT",
    names: ["Minodronate"],
  },
  {
    primary_id: "D00NQN",
    names: ["ISIS 109351"],
  },
  {
    primary_id: "D00NQQ",
    names: [
      "Alpha,beta-Methylene-DATP",
      "CHEMBL1162297",
      "2'-Deoxy-5'-O-[(S)-Hydroxy{[(S)-Hydroxy(Phosphonooxy)Phosphoryl]methyl}phosphoryl]adenosine",
      "F2A",
    ],
  },
  {
    primary_id: "D00NQV",
    names: ["RBI257"],
  },
  {
    primary_id: "D00NRB",
    names: ["MCL-117"],
  },
  {
    primary_id: "D00NRJ",
    names: ["Silahexocyclium"],
  },
  {
    primary_id: "D00NSA",
    names: [
      "5-Methoxy-2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "5-Methoxytryptoline",
      "30439-19-1",
      "CHEMBL6586",
      "1H-Pyrido(3,4-B)Indole, 2,3,4,9-Tetrahydro-5-Methoxy-",
      "1H-Pyrido[3,4-B]indole, 2,3,4,9-Tetrahydro-5-Methoxy-",
      "SCHEMBL4525164",
      "CTK4G5204",
      "DTXSID10184522",
      "VPZWHBCLJCKHGZ-UHFFFAOYSA-N",
      "AC1Q5856",
      "AC1L5390",
      "BDBM50136499",
      "AKOS023631742",
      "1,2,3,4-Tetrahydro-5-Methoxy-Beta-Carboline",
      "5-Methoxy-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
    ],
  },
  {
    primary_id: "D00NSD",
    names: ["XL-820"],
  },
  {
    primary_id: "D00NSM",
    names: [
      "CM-GLP-1",
      "CellBead Neuro",
      "GLP-1-Expressing Stem Cell Therapy (Drug Eluting Bead, Neurological Trauma/Neurodegeneration)",
      "CM-1-Expressing Stem Cell Therapy (Drug Eluting Bead, Neurological Trauma/Neurodegeneration), CellMed",
      "GLP-1-Expressing Stem Cell Therapy (Drug Eluting Bead, Neurological Trauma/Neurodegeneration), CellMed",
    ],
  },
  {
    primary_id: "D00NST",
    names: ["(2S)-1-(1H-Furo[2,3-G]indazol-1-Yl)Propan-2-Amine", "CHEMBL261476", "SCHEMBL905958"],
  },
  {
    primary_id: "D00NTR",
    names: ["Histoplasmin"],
  },
  {
    primary_id: "D00NTW",
    names: ["BIBR-363", "BIBR 363 CL2"],
  },
  {
    primary_id: "D00NUG",
    names: ["Olokizumab"],
  },
  {
    primary_id: "D00NUK",
    names: [
      "4-Methanesulfonylamino-Benzenesulfonamide",
      "4-(Methanesulfonamido)Benzenesulfonamide",
      "CHEMBL193648",
      "4426-90-8",
      "4-[(Methylsulfonyl)Amino]benzenesulfonamide",
      "AC1MDJCY",
      "Oprea1_082411",
      "SCHEMBL6401783",
      "DTXSID60385527",
      "MolPort-001-489-118",
      "ZINC3191876",
      "BDBM50174035",
      "STK416772",
      "BBL020042",
      "AKOS002973885",
      "MCULE-3544784326",
      "4-(Methylsulfonylamino)Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D00NUO",
    names: ["IDP-122 Topical"],
  },
  {
    primary_id: "D00NVC",
    names: ["Z-Ala-Leu-Nal-Agly-Ile-Val-OMe"],
  },
  {
    primary_id: "D00NVM",
    names: ["SNS-595"],
  },
  {
    primary_id: "D00NVO",
    names: ["Flezelastine", "D-18024"],
  },
  {
    primary_id: "D00NVU",
    names: ["Imidazole Derivative 10"],
  },
  {
    primary_id: "D00NVX",
    names: ["Forasartan"],
  },
  {
    primary_id: "D00NWF",
    names: ["ZM-336372"],
  },
  {
    primary_id: "D00NWT",
    names: ["GR-213487"],
  },
  {
    primary_id: "D00NWV",
    names: ["Ypeginterferon Alfa-2a (Chronic Hepatitis B/C) Amoytop"],
  },
  {
    primary_id: "D00NXA",
    names: [
      "Benzofuran-2-Ylboronic Acid",
      "Benzofuran-2-Boronic Acid",
      "98437-24-2",
      "Benzo[b]furan-2-Boronic Acid",
      "1-Benzofuran-2-Ylboronic Acid",
      "2-Benzofuranboronic Acid",
      "2-BENZOFURANYLBORONIC ACID",
      "Benzo(B)Furan-2-Boronic Acid",
      "(1-Benzofuran-2-Yl)Boronic Acid",
      "2,3-Benzo[b]furan-2-Boronic Acid",
      "Boronic Acid, 2-Benzofuranyl-",
      "CHEMBL143399",
      "MFCD00236019",
      "Benzo[b]furan-2-Boronic Acid, 97%",
      "Benzofuran-2-Boronicacid",
      "Benzofurylboronic Acid",
      "PubChem1724",
      "Bezofuran Boronic Acid",
      "PubChem1754",
      "Benzofuran Boronic Acid",
      "2-Boronobenzo[b]furan",
    ],
  },
  {
    primary_id: "D00NXM",
    names: [
      "GSK-2251052",
      "ABX",
      "AN-3365",
      "GSK '052",
      "Boron-Based LeuRS Inhibitors (Gram-Negative Bacterial Infection), Anacor/GSK",
      "Boron-Based Leucyl-TRNA Synthetase Inhibitors (Bacterial Infection), Anacor/GSK",
    ],
  },
  {
    primary_id: "D00NXT",
    names: ["Xmab 5871"],
  },
  {
    primary_id: "D00NYG",
    names: ["4-Amino-2,6-Diphenyl-Pyrimidine-5-Carbonitrile"],
  },
  {
    primary_id: "D00NYJ",
    names: ["Varlitinib"],
  },
  {
    primary_id: "D00NYL",
    names: ["AAV2/8-CBA-HSMN1"],
  },
  {
    primary_id: "D00NYT",
    names: [
      "3,4-Di-(4-Methoxyphenyl)-1H-Pyrrole-2,5-Dione",
      "1H-Pyrrole-2,5-Dione, 3,4-Bis(4-Methoxyphenyl)-",
      "108774-82-9",
      "ACMC-20mbs9",
      "CHEMBL381099",
      "CTK0G2626",
      "DTXSID90449388",
    ],
  },
  {
    primary_id: "D00NZL",
    names: ["LY2603618"],
  },
  {
    primary_id: "D00NZP",
    names: ["Dmt-Sar-Phe-D-2-Nal-NH", "CHEMBL254927", "BDBM50232770"],
  },
  {
    primary_id: "D00OAB",
    names: ["APX-007"],
  },
  {
    primary_id: "D00OAN",
    names: [
      "9H-Beta-Carboline-3-Carboxylic Acid Propyl Ester",
      "Beta-Ccp",
      "76808-18-9",
      "Beta-Carboline-3-Carboxylic Acid Propyl Ester",
      "Propyl Beta-Carboline-3-Carboxylate",
      "Pbc-3-C",
      "Propyl 9H-Pyrido[3,4-B]indole-3-Carboxylate",
      "9H-Pyrido(3,4-B)Indole-3-Carboxylic Acid, Propyl Ester",
      "C15H14N2O2",
      "Beta CCP",
      "Biomol-NT_000275",
      "AC1Q2Y9C",
      "AC1L3X2D",
      "AC1Q66NN",
      ".beta.-Carboline-3-Carboxylic Acid Propyl Ester",
      "SCHEMBL2348263",
      "9H-B-CARBOLINE-3-CARBOXYLIC ACID PROPYL ESTER",
      "CHEMBL274847",
      "BPBio1_001101",
      "CTK5E3459",
      "CHEBI:93491",
      "BDBM84992",
      "DTXSID30227627",
      "WGRVAGIQTHEEQW-UHFFFAOYSA-N",
      "ZINC2391117",
      "PDSP2_001744",
    ],
  },
  {
    primary_id: "D00OAU",
    names: ["GW-328267"],
  },
  {
    primary_id: "D00OAY",
    names: ["Lenampicillin Hci"],
  },
  {
    primary_id: "D00OBI",
    names: [
      "4-Methyl-2-Oxo-2H-Chromen-7-Yl Sulfamate",
      "CHEMBL11185",
      "136167-05-0",
      "Sulfamic Acid, 4-Methyl-2-Oxo-2H-1-Benzopyran-7-Yl Ester",
      "ACMC-20mw22",
      "SCHEMBL9451464",
      "CTK0F3928",
      "DTXSID30431984",
      "2H-1-Benzopyran-2-One, 7-[(Aminosulfonyl)Oxy]-4-Methyl-",
      "UFGBGFMPBMEVMI-UHFFFAOYSA-N",
      "BDBM50051829",
      "Sulfamic Acid 4-Methyl-2-Oxo-2H-Chromen-7-Yl Ester",
      "Sulfamic Acid 4-Methyl-2-Oxo-2H-1-Benzopyran-7-Yl Ester",
    ],
  },
  {
    primary_id: "D00OBS",
    names: ["Cyclo[(6-Bromotryptophan)Arginine]", "CHEMBL511609"],
  },
  {
    primary_id: "D00OCB",
    names: [
      "CCT-018159",
      "CCT 018159",
      "CCT018159",
      "(6Z)-6-[4-(2,3-Dihydro-1,4-Benzodioxin-6-Yl)-5-Methyl-1,2-Dihydropyrazol-3-Ylidene]-4-Ethyl-3-Hydroxycyclohexa-2,4-Dien-1-One",
      "4-[4-(2,3-Dihydro-1,4-Benzodioxin-6-Yl)-5-Methyl-1H-Pyrazol-3-Yl]-6-Ethylbenzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D00OCC",
    names: [
      "4-(3-Benzenesulfonylphenyl)Piperazine",
      "CHEMBL212995",
      "SCHEMBL3826873",
      "KDBOZUADFFNYSM-UHFFFAOYSA-N",
      "BDBM50193464",
    ],
  },
  {
    primary_id: "D00OCL",
    names: ["Nalbuphine Extended Release"],
  },
  {
    primary_id: "D00OCS",
    names: ["AdcAhxArg4NH(CH2)6NH2", "CHEMBL263964"],
  },
  {
    primary_id: "D00OCV",
    names: ["3-(2-Dimethylamino-Ethyl)-2-Methyl-1H-Indol-4-Ol", "CHEMBL370859"],
  },
  {
    primary_id: "D00ODC",
    names: ["Imidazo Pyridine Derivative 4"],
  },
  {
    primary_id: "D00ODF",
    names: ["ISIS 102604"],
  },
  {
    primary_id: "D00ODH",
    names: ["Antineoplaston Therapy"],
  },
  {
    primary_id: "D00ODL",
    names: ["IQM-97423"],
  },
  {
    primary_id: "D00ODN",
    names: ["9-Benzyl-6-Phenylsulfanyl-9H-Purine"],
  },
  {
    primary_id: "D00ODY",
    names: ["PMID25666693-Compound-95"],
  },
  {
    primary_id: "D00OEG",
    names: ["RhTPO"],
  },
  {
    primary_id: "D00OFN",
    names: ["4-(Ethylthio)-6-Phenyl-1,3,5-Triazin-2-Amine", "CHEMBL220445"],
  },
  {
    primary_id: "D00OFV",
    names: [
      "Asperlicin",
      "UNII-U0MJE9LRXV",
      "93413-04-8",
      "U0MJE9LRXV",
      "CHEMBL283117",
      "(S-(2alpha,9beta,9(R*),9alpha,beta))-6,7-Dihydro-7-((2,3,9,9a-Tetrahydro-9-Hydroxy-2-(2-Methylpropyl)-3-Oxo-1H-Imidazo(1,2-A)Indol-9-Yl)Methyl)Quinazolino(3,2-A)(1,4)Benzodiazepine-5,13-Dione",
      "Quinazolino(3,2-A)(1,4)Benzodiazepine-5,13-Dione, 6,7-Dihydro-7-((2,3,9,9a-Tetrahydro-9-Hydroxy-2-(2-Methylpropyl)-3-Oxo-1H-Imidazo(1,2-A)Indol-9-Yl)Methyl)-, (2S-(2alpha,9beta,9(R*),9abeta))-",
      "C31H29N5O4",
      "AC1MHZCP",
      "SCHEMBL1004269",
      "BDBM50019777",
      "LS-178211",
    ],
  },
  {
    primary_id: "D00OGX",
    names: ["Anapsos"],
  },
  {
    primary_id: "D00OHP",
    names: [
      "PRO-GR-300",
      "Carbohydrate Compounds (Fibrosis), Pro-Pharmaceuticals",
      "Galectin Modulator (Carbohydrate Compounds, Fibrosis), Galectin",
    ],
  },
  {
    primary_id: "D00OJL",
    names: ["QBP1 Mimetics"],
  },
  {
    primary_id: "D00OKK",
    names: ["PC4708"],
  },
  {
    primary_id: "D00OLA",
    names: ["H-[Trp-Arg-Nva-Arg-Tyr]3-NH2", "CHEMBL413871"],
  },
  {
    primary_id: "D00OLH",
    names: ["4-[1,2-Bis(4-Hydroxyphenyl)Vinyl]phenol", "CHEMBL34709"],
  },
  {
    primary_id: "D00OLX",
    names: ["8-(3-Methylbenzyloxy)Caffeine", "CHEMBL589765"],
  },
  {
    primary_id: "D00OLY",
    names: ["PMID25666693-Compound-149"],
  },
  {
    primary_id: "D00OMJ",
    names: ["SJ-BRV"],
  },
  {
    primary_id: "D00OMV",
    names: ["ARTEMINOLIDE"],
  },
  {
    primary_id: "D00OMW",
    names: [
      "Group E 1682-2106",
      "CHEMBL1770295",
      "3-Methyl-2-[(5Z)-5-(1-Methyl-2-Oxoindol-3-Ylidene)-4-Oxo-2-Sulfanylidene-1,3-Thiazolidin-3-Yl]butanoic Acid",
      "AC1NT1C6",
      "GTPL5827",
      "MolPort-000-500-334",
      "BDBM50418364",
      "AKOS016308025",
      "AKOS002200571",
      "ST50147548",
      "UNM000000513101",
      "Group E 1682-2106 [PMID:16118363]",
      "3-METHYL-2-[(5Z)-5-(1-METHYL-2-OXO-1,2-DIHYDRO-3H-INDOL-3-YLIDENE)-4-OXO-2-THIOXO-1,3-THIAZOLIDIN-3-YL]BUTANOIC ACID",
      "3-Methyl-2-[5-(1-Methyl-2-Oxobenzo[d]azolin-3-Ylidene)-4-Oxo-2-Thioxo(1,3-Thia Zolidin-3-Yl)]butanoic Acid",
    ],
  },
  {
    primary_id: "D00ONE",
    names: ["CWF-0710"],
  },
  {
    primary_id: "D00ONN",
    names: ["Imidazo Triazine Derivative 1"],
  },
  {
    primary_id: "D00OON",
    names: ["CAB-AXL-ADC"],
  },
  {
    primary_id: "D00OOZ",
    names: ["E-7107"],
  },
  {
    primary_id: "D00OPO",
    names: ["PMID9871507C14"],
  },
  {
    primary_id: "D00OPX",
    names: [
      "Fostamatinib Disodium",
      "1025687-58-4",
      "R788(Prodrug Of R406)",
      "R788 (Fostamatinib) Disodium",
      "UNII-X9417132K8",
      "R 788 Sodium",
      "R788(Fostamatinib Disodium)",
      "R 935788 Sodium",
      "R935788 (Fostamatinib Disodium, R788)",
      "R788 Disodium",
      "X9417132K8",
      "R788 Disodium Hexahydrate",
      "R788 SODIUM",
      "R-788 Disodium",
      "R788, Fostamatinib",
      "Tube731",
      "Sodium (6-((5-Fluoro-2-((3,4,5-Trimethoxyphenyl)Amino)Pyrimidin-4-Yl)Amino)-2,2-Dimethyl-3-Oxo-2,3-Dihydro-4H-Pyrido[3,2-B][1,4]oxazin-4-Yl)Methyl Phosphate",
      "Disodium (6-(5-Fluoro-2-(3",
    ],
  },
  {
    primary_id: "D00OQL",
    names: [
      "7-Acetonyloxy-3,4-Cyclopentene-8-Methylcoumarin",
      "307548-94-3",
      "CHEMBL488076",
      "6-Methyl-7-(2-Oxopropoxy)-2,3-Dihydrocyclopenta[c]chromen-4(1H)-One",
      "6-METHYL-7-(2-OXOPROPOXY)-2,3-DIHYDROCYCLOPENTA(C)CHROMEN-4(1H)-ONE",
      "CHEMBRDG-BB 6159766",
      "AC1MY6ZC",
      "CBMicro_001748",
      "CTK4G5858",
      "DTXSID20397328",
      "MolPort-000-688-742",
      "ZX-CM016564",
      "SMSF0015818",
      "ZINC4387711",
      "STK829219",
      "BDBM50246264",
      "AKOS001021363",
      "CB03533",
      "MCULE-6433360521",
      "ACM307548943",
      "AJ-51032",
      "BIM-0001876.P001",
      "W-8723",
      "SR-01000229477",
      "SR-01000229477-1",
    ],
  },
  {
    primary_id: "D00OQW",
    names: ["N-Acetylcysteine"],
  },
  {
    primary_id: "D00ORM",
    names: ["5-Carbazol-9-Yl-Pentanoic Acid", "CHEMBL184795", "SCHEMBL12951764", "BDBM50152858"],
  },
  {
    primary_id: "D00ORZ",
    names: ["BMS-824393", "Antiviral (Oral, HCV), Bristol-Myers Squibb"],
  },
  {
    primary_id: "D00OSB",
    names: ["Ala7-SRIF-14-Amide"],
  },
  {
    primary_id: "D00OTC",
    names: [
      "NSC-381864",
      "108957-73-9",
      "(E)-3'-Hydroxy-3,5,4'-Trimethoxystilbene",
      "CHEMBL419378",
      "5-[(E)-2-(3,5-Dimethoxyphenyl)Ethenyl]-2-Methoxyphenol",
      "5-[(E)-2-(3,5-Dimethoxyphenyl)Ethenyl]-2-Methoxy-Phenol",
      "4,3',5'-Tri-O-Methylpiceatannol",
      "NSC381864",
      "AC1NTOCZ",
      "(E) -3'-Hydroxy-3,5,4'-Trimethoxystilbene",
      "SCHEMBL1743354",
      "SCHEMBL1743353",
      "(E)-5-(2-(3,5-Dimethoxyphenyl)Ethenyl)-2-Methoxyphenol",
      "UQIWTPQGJCCTPA-SNAWJCMRSA-N",
      "MolPort-019-990-180",
      "ZINC1591410",
      "BDBM50332182",
      "AKOS015914978",
      "4,3'',5''-Tri-O-Methylpiceatannol",
      "AC-24233",
      "AN-33692",
    ],
  },
  {
    primary_id: "D00OTH",
    names: ["P-979"],
  },
  {
    primary_id: "D00OTL",
    names: ["Vernakalant"],
  },
  {
    primary_id: "D00OTM",
    names: ["LY3022855"],
  },
  {
    primary_id: "D00OTQ",
    names: ["Proximab"],
  },
  {
    primary_id: "D00OTT",
    names: ["ISIS 1570"],
  },
  {
    primary_id: "D00OUF",
    names: ["1-(3-Phenoxypropyl)-4-(Piperidin-1-Yl)Piperidine", "CHEMBL428756"],
  },
  {
    primary_id: "D00OVC",
    names: ["EVT-201"],
  },
  {
    primary_id: "D00OVE",
    names: ["PF-06650808"],
  },
  {
    primary_id: "D00OVU",
    names: ["Suvorexant"],
  },
  {
    primary_id: "D00OWE",
    names: ["L-652,343"],
  },
  {
    primary_id: "D00OWF",
    names: ["HARMINE"],
  },
  {
    primary_id: "D00OWU",
    names: ["RBP-8000", "Cocaine Esterase"],
  },
  {
    primary_id: "D00OWZ",
    names: [
      "4-Methoxy-N-(Thiazol-2-Yl)Benzamide",
      "4-Methoxy-N-Thiazol-2-Yl-Benzamide",
      "4-Methoxy-N-1,3-Thiazol-2-Ylbenzamide",
      "4-Methoxy-Benzoic Acid Thiazol-2-Ylamide",
      "4-Methoxy-N-(1,3-Thiazol-2-Yl)Benzamide",
      "303122-55-6",
      "BAS 00626024",
      "Oprea1_481467",
      "Oprea1_830630",
      "MLS001209223",
      "ARONIS023447",
      "CHEMBL387618",
      "AC1Q49Y5",
      "AC1LF170",
      "MolPort-001-004-838",
      "ZINC123532",
      "HMS2848F19",
      "STK031490",
      "AKOS001320075",
      "MCULE-9448418356",
      "KS-0000469Q",
      "ST089107",
      "SMR000514833",
      "KB-115289",
      "4-Methoxy-N~1~-(1,3-Thiazol-2-Yl)Benzamide",
    ],
  },
  {
    primary_id: "D00OXH",
    names: ["U-44069", "5-Trans U-44069", "CTK8G3685"],
  },
  {
    primary_id: "D00OXP",
    names: ["Bb21217"],
  },
  {
    primary_id: "D00OXQ",
    names: ["Companion Diagnostic Test EGFR-TKI"],
  },
  {
    primary_id: "D00OYC",
    names: ["Motolimid"],
  },
  {
    primary_id: "D00OYQ",
    names: ["Imisopasem Manganese"],
  },
  {
    primary_id: "D00OZA",
    names: ["3-Ethynylquinoline-8-Carboxamide", "CHEMBL501330", "8-Quinolinecarboxamide, 3-Ethynyl-", "BDBM50255268"],
  },
  {
    primary_id: "D00OZC",
    names: ["THX-TS01"],
  },
  {
    primary_id: "D00OZN",
    names: ["N-[3'-(Trifluoromethyl)-4-Biphenylyl]urea", "CHEMBL244690", "BDBM50220199"],
  },
  {
    primary_id: "D00OZS",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Pentyl Ester",
      "CHEMBL306782",
      "BDBM50116811",
      "4-(Sulfamoyloxy)Benzoic Acid Pentyl Ester",
    ],
  },
  {
    primary_id: "D00PAG",
    names: [
      "[35S]ADPbetaS",
      "GTPL3408",
      "[({[(2R,3S,4R,5R)-5-(6-Amino-9H-Purin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy}(Hydroxy)Phosphoryl)Oxy](Sulfanylidene)Phosphonous Acid",
    ],
  },
  {
    primary_id: "D00PAS",
    names: [
      "Cristacarpin",
      "Erythrabyssin I",
      "74515-47-2",
      "Crystacarpin",
      "CHEMBL454849",
      "CHEBI:3917",
      "AC1L2QIR",
      "AC1Q70TT",
      "SCHEMBL4740053",
      "MolPort-005-945-627",
      "ZINC4098607",
      "LMPK12070114",
      "BDBM50317430",
      "9166AF",
      "6h-Benzofuro(3,2-C)(1)Benzopyran-3,6a(11ah)-Diol, 9-Methoxy-10-(3-Methyl-2-Butenyl)-,(6as-Cis)-",
      "C10206",
      "6H-Benzofuro(3,2-C)(1)Benzopyran-3,6a(11aH)-Diol, 9-Methoxy-10-(3-Methyl-2-Butenyl)-, (6aS-Cis)-",
      "6H-Benzofuro[3,2-C][1]benzopyran-3,6a(11aH)-Diol,9-Methoxy-10-(3-Methyl-2-Buten-1-Yl)-, (6aS,11aS)-",
    ],
  },
  {
    primary_id: "D00PBI",
    names: ["SUVN-501"],
  },
  {
    primary_id: "D00PBX",
    names: ["GDC-0199"],
  },
  {
    primary_id: "D00PCE",
    names: [
      "BRL-24682",
      "Brl-24682",
      "Brl 24682",
      "SCHEMBL7292676",
      "CHEMBL301039",
      "BDBM82519",
      "PDSP2_001249",
      "PDSP1_001265",
      "76272-78-1",
      "CAS_76272-78-1",
      "4-Amino-5-Chloro-2-Methoxy-N-(8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl)Benzamide",
      "Benzamide, 4-Amino-5-Chloro-2-Methoxy-N-(8-Methyl-8-Azabicyclo(3.2.1)Oct-3-Yl)-, Endo-",
    ],
  },
  {
    primary_id: "D00PDG",
    names: [
      "CORT-108297",
      "1018679-79-2",
      "Cort108297",
      "CORT 108297",
      "UNII-A17W0640NB",
      "CHEMBL271220",
      "A17W0640NB",
      "1H-Pyrazolo[3,4-G]isoquinoline, 4a-(Ethoxymethyl)-1-(4-Fluorophenyl)-4,4a,5,6,7,8-Hexahydro-6-[[4-(Trifluoromethyl)Phenyl]sulfonyl]-, (4aR)-;1H-Pyrazolo[3,4-G]isoquinoline, 4a-(Ethoxymethyl)-1-(4-Fluorophenyl)-4,4a,5,6,7,8-Hexahydro-6-[[4-(Trifluoromethyl)Phenyl]sulfonyl]-, (4aR)-",
      "ADS-108297",
      "SCHEMBL755070",
      "DTXSID70144304",
      "MolPort-044-723-886",
      "BCP16798",
      "BDBM50372968",
      "ZINC29128012",
      "AKOS027338706",
      "SB17316",
      "KS-0000064X",
      "AS-35226",
    ],
  },
  {
    primary_id: "D00PDQ",
    names: ["Kuzbanian"],
  },
  {
    primary_id: "D00PEC",
    names: ["PIBTU", "1,3-PB-ITU"],
  },
  {
    primary_id: "D00PEH",
    names: ["Eltrombopag"],
  },
  {
    primary_id: "D00PEJ",
    names: ["1-Guanidino-N-Phenyl-7-Isoquinolinesulphonamide"],
  },
  {
    primary_id: "D00PEN",
    names: ["MGD007"],
  },
  {
    primary_id: "D00PES",
    names: ["PMID27109571-Compound-5"],
  },
  {
    primary_id: "D00PEU",
    names: ["Pirenperone"],
  },
  {
    primary_id: "D00PEV",
    names: ["PMID26666989-Compound-Figure9middle03"],
  },
  {
    primary_id: "D00PFD",
    names: ["Benzyl Pyrrolyloxoacetamide Derivative 1"],
  },
  {
    primary_id: "D00PFS",
    names: ["Azetidinyl-Piperidine Derivative 1"],
  },
  {
    primary_id: "D00PFT",
    names: ["PMID26293650-Compound-34"],
  },
  {
    primary_id: "D00PFW",
    names: ["AZD4818", "18-HYDROXYASCOMYCIN", "AC1L9IBR", "SCHEMBL14434002"],
  },
  {
    primary_id: "D00PGA",
    names: [
      "ALS-AAV9",
      "Amyotrophic Lateral Sclerosis Gene Therapy, RegenX Biosciences",
      "Adeno-Associated Virus Vector-9 Based Gene Therapy (Injectable, ALS), RegenX Bioscience",
    ],
  },
  {
    primary_id: "D00PGF",
    names: ["RV-1088"],
  },
  {
    primary_id: "D00PGP",
    names: ["Benzamide Derivative 6"],
  },
  {
    primary_id: "D00PHK",
    names: ["Carboxylic Acid Derivative 1"],
  },
  {
    primary_id: "D00PIE",
    names: ["Banoxantrone", "AQ4M", "AQ4N", "AZD-1689", "AQ4N, AstraZeneca/Novacea"],
  },
  {
    primary_id: "D00PJE",
    names: ["Tetra-Hydro-Carboline Derivative 2"],
  },
  {
    primary_id: "D00PJJ",
    names: ["FK-614", "ATx08-001", "ATx08-001)", "PPAR Gamma Agonist (Oral, Neuropathic Pain), Aestus"],
  },
  {
    primary_id: "D00PJK",
    names: ["SRI-21009"],
  },
  {
    primary_id: "D00PJT",
    names: ["(S)(+)-7-Fluoro-2-P-Tolylchroman-4-One", "CHEMBL597472"],
  },
  {
    primary_id: "D00PJY",
    names: ["Prosorba"],
  },
  {
    primary_id: "D00PLH",
    names: ["1,2-Bis(2,5-Difluorophenyl)-2-Hydroxyethanone", "CHEMBL244428"],
  },
  {
    primary_id: "D00PLM",
    names: ["N-Hydroxy-5-(Pyridin-3-Yl)Thiophene-2-Carboxamide", "CHEMBL216292", "SCHEMBL5903988"],
  },
  {
    primary_id: "D00PLP",
    names: ["Pyrazole Derivative 72"],
  },
  {
    primary_id: "D00PLV",
    names: ["Nitroprusside", "C07269", "LS-187044"],
  },
  {
    primary_id: "D00PLX",
    names: ["MystiLol"],
  },
  {
    primary_id: "D00PMA",
    names: ["CL-246738", "3,6-Bis[2-(1-Piperidinyl)Ethoxy]acridine Trihydrochloride"],
  },
  {
    primary_id: "D00PMB",
    names: ["VGX-3100"],
  },
  {
    primary_id: "D00PMF",
    names: ["5-Bromo-6-Hydrazinouracil Hydrochloride", "CHEMBL536284"],
  },
  {
    primary_id: "D00PMS",
    names: [
      "8-BENZO[1,3]DIOXOL-,5-YLMETHYL-9-BUTYL-9H-",
      "CHEMBL326564",
      "PU6",
      "8-(1,3-Benzodioxol-5-Ylmethyl)-9-Butyl-9H-Purin-6-Amine",
      "1uy9",
      "Purine-Based Inhibitor 4",
      "AC1L9MK7",
      "BDBM15377",
      "DB08436",
      "8-(1,3-Benzodioxol-5-Ylmethyl)-9-Butylpurin-6-Amine",
      "8-(2H-1,3-Benzodioxol-5-Ylmethyl)-9-Butylpurin-6-Amine",
      "8-(1,3-Benzodioxole-5-Ylmethyl)-9-Butyl-9H-Purine-6-Amine",
    ],
  },
  {
    primary_id: "D00PNU",
    names: ["TGN-1412"],
  },
  {
    primary_id: "D00POF",
    names: ["6-Styryl-Naphthalene-2-Carboxamidine", "CHEMBL185345", "ZINC28365935"],
  },
  {
    primary_id: "D00POS",
    names: [
      "DL-017",
      "3-[4-(2-Methoxyphenyl)Piperazin-1-Ylmethyl]-5-(Methylsulfanyl)-2,3-Dihydroimidazo[1,2-C]quinazoline",
    ],
  },
  {
    primary_id: "D00PPB",
    names: ["N-(3-(Trifluoromethyl)Benzyl)-4-Phenoxybenzamide", "CHEMBL198563"],
  },
  {
    primary_id: "D00PPV",
    names: ["Siderophore-Conjugated Monocarbams"],
  },
  {
    primary_id: "D00PQC",
    names: ["CTP", "Cytidine Triphosphate"],
  },
  {
    primary_id: "D00PQS",
    names: ["NE-2"],
  },
  {
    primary_id: "D00PRE",
    names: [
      "4'-(Trifluoromethyl)-4-Biphenylsulfonamide",
      "CHEMBL242783",
      "WWPFVDFIDSAULR-UHFFFAOYSA-N",
      "BDBM50220193",
      "ZINC28711886",
      "AKOS015966248",
      "4'-Trifluoromethylbiphenyl-4-O-Sulfamate",
      "950500-72-8",
      "4'-(Trifluoromethyl)-4-Biphenylsulfoamide",
      "4'-(Trifluoromethyl)Biphenyl-4-Sulfonamide",
    ],
  },
  {
    primary_id: "D00PRI",
    names: [
      "Edetate Calcium Disodium",
      "Calcium Disodium Versenate",
      "UNII-5M645317UB",
      "Disodium ((Ethylenedinitrilo)Tetraacetato)Calciate(2-) Hydrate",
      "5M645317UB",
      "23411-34-9",
      "Edetate Calcium Disodium [USAN:USP]",
      "Calciate(2-), ((N,N'-1,2-Ethanediylbis(N-(Carboxymethyl)Glycinato))(4-)-N,N',O,O',ON,ON')-, Disodium, Hydrate, (OC-6-21)-",
      "Calciate (2-), ((N,N'-1,2-Ethanediylbis(N-(Carboxymethyl)Glycinato))(4-)-N,N',O,O',O(Sup N),O(Sup N)')-, Disodium, Hydrate, (OC-6-21)-",
      "50322-16-2",
    ],
  },
  {
    primary_id: "D00PRR",
    names: ["MRS2298"],
  },
  {
    primary_id: "D00PRY",
    names: ["Ezogabine Extended Release"],
  },
  {
    primary_id: "D00PSG",
    names: ["NOX-100"],
  },
  {
    primary_id: "D00PSJ",
    names: [
      "2-(3-Methyl-3H-Imidazol-4-Yl)-Ethylamine",
      "3-Methylhistamine",
      "Tau-Methylhistamine",
      "644-42-8",
      "Imidazole, 5-(2-Aminoethyl)-1-Methyl-",
      "L-Histamine Deriv. 1",
      "2-(1-Methyl-1H-Imidazol-5-Yl)Ethan-1-Amine",
      "1H-Imidazole-5-Ethanamine, 1-Methyl-",
      "CHEMBL14722",
      "2-(1-Methyl-1H-Imidazol-5-Yl)Ethanamine",
      "CPAGZVLINCPJEH-UHFFFAOYSA-N",
      "2-(3-Methylimidazol-4-Yl)Ethanamine",
      "3-Methyl Histamine Dihydrochloride",
      "(R)-A-Methylhistamine",
      "AC1Q4WNL",
      "SCHEMBL946729",
      "BDBM7967",
      "AC1L2C25",
      "CTK5C1256",
      "DTXSID10214651",
      "ZINC403074",
      "AKOS006340201",
      "1H-Imidazole-5-Ethanamine,1-Methyl-",
    ],
  },
  {
    primary_id: "D00PSW",
    names: ["MERCK-544"],
  },
  {
    primary_id: "D00PTE",
    names: ["Imidazopyridazine Derivative 2"],
  },
  {
    primary_id: "D00PTZ",
    names: ["Dasiprotimut-T"],
  },
  {
    primary_id: "D00PUF",
    names: [
      "4-Amino-3-Hydroxy-Butyric Acid",
      "4-Amino-3-Hydroxybutanoic Acid",
      "924-49-2",
      "4-Amino-3-Hydroxybutyric Acid",
      "Gabob",
      "DL-4-Amino-3-Hydroxybutyric Acid",
      "3-Hydroxy-GABA",
      "Gamibetal",
      "Gabomade",
      "Gaboril",
      "Idramina",
      "Gaminal",
      "Buksamin",
      "Gabimex",
      "Bogil",
      "352-21-6",
      "Gamma-Amino-Beta-Hydroxybutyric Acid",
      "Butanoic Acid, 4-Amino-3-Hydroxy-",
      "Buxamine",
      "Buxamin",
      "Gabobe",
      "Beta-Oxy-Gaba",
      "3-Hydroxy-4-Aminobutyric Acid",
      "Buxamine (VAN)",
      "NSC 40244",
      "Gamma-Amino-Beta-Hydroxybutyrate",
      "Beta-Hydroxy-Gamma-Aminobutyrate",
      "BUTYRIC ACID, 4-AMINO-3-HYDROXY-",
    ],
  },
  {
    primary_id: "D00PUR",
    names: ["3'-(1-Benzothien-2-Yl)Biphenyl-3-Ol", "CHEMBL1097016", "BDBM50318420"],
  },
  {
    primary_id: "D00PUT",
    names: ["BT-016"],
  },
  {
    primary_id: "D00PVH",
    names: ["Triazole Derivative 2"],
  },
  {
    primary_id: "D00PVO",
    names: ["GNF-PF-3464"],
  },
  {
    primary_id: "D00PWQ",
    names: ["KENPAULLONE"],
  },
  {
    primary_id: "D00PWV",
    names: ["2-[3-(1-Phenyl-Ethyl)-Phenyl]-Propionic Acid"],
  },
  {
    primary_id: "D00PXF",
    names: ["1-(4-Cyanobenzyl)-5-(3-Fluorophenyl)-1H-Imidazole", "CHEMBL598789", "SCHEMBL3828287"],
  },
  {
    primary_id: "D00PXO",
    names: [
      "CGK733",
      "905973-89-9",
      "CGK 733",
      "CGK-733",
      "2,2-Diphenyl-N-(2,2,2-Trichloro-1-(3-(4-Fluoro-3-Nitrophenyl)Thioureido)Ethyl)Acetamide",
      "CHEMBL1221601",
      "2,2-Diphenyl-N-(2,2,2-Trichloro-1-[3-(4-Fluoro-3-Nitrophenyl)Thioureido]ethyl)Acetamide",
      "UNII-L3DGZ99QYM",
      "2,2-Diphenyl-N-(2,2,2-Trichloro-1-{[(4-Fluoro-3-Nitrophenyl)Carbamothioyl]amino}ethyl)Acetamide",
      "2,2-Diphenyl-N-[2,2,2-Trichloro-1-[(4-Fluoro-3-Nitrophenyl)Carbamothioylamino]ethyl]acetamide",
      "L3DGZ99QYM",
    ],
  },
  {
    primary_id: "D00PXQ",
    names: [
      "Fananserin",
      "127625-29-0",
      "RP 62203",
      "Fananserin [USAN:INN]",
      "RP-62203",
      "UNII-38QJ762ET6",
      "CHEMBL83894",
      "C23H24FN3O2S",
      "VGIGHGMPMUCLIQ-UHFFFAOYSA-N",
      "38QJ762ET6",
      "NCGC00167808-01",
      "DSSTox_RID_81871",
      "DSSTox_CID_26743",
      "2-(3-(4-(P-Fluorophenyl)-1-Piperazinyl)Propyl)-2H-Naphth(1,8-Cd)Isothiazole 1,1-Dioxide",
      "2H-Naphth(1,8-Cd)Isothiazole, 2-(3-(4-(4-Fluorophenyl)-1-Piperazinyl)Propyl)-, 1,1-Dioxide",
      "DSSTox_GSID_46743",
      "[3H]fananserin",
      "CAS-127625-29-0",
      "Fananserin (USAN/INN)",
      "[3H]RP62203",
      "[3H]RP-62203",
      "AC1L1TX5",
      "2-(3-(4-(4-Fluorophenyl)-1-Piperazinyl)Propyl)-2H-Naphth(1,8-Cd)Isothiazole 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D00PYB",
    names: [
      "CODEINONE",
      "Codeinone",
      "6-Codeinone",
      "467-13-0",
      "UNII-22B5AW0ANN",
      "(5alpha)-7,8-Didehydro-4,5-Epoxy-3-Methoxy-17-Methylmorphinan-6-One",
      "EINECS 207-386-1",
      "BRN 0094188",
      "22B5AW0ANN",
      "CHEBI:18399",
      "3-Methoxy-17-Methyl-7,8-Didehydro-4,5alpha-Epoxymorphinan-6-One",
      "Morphinan-6-One, 7,8-Didehydro-4,5-Alpha-Epoxy-3-Methoxy-17-Methyl-",
      "6-Oxocodeine",
      "(-)-Codeinone",
      "SCHEMBL100655",
      "CHEMBL257627",
      "DTXSID70196909",
      "XYYVYLMBEZUESM-CMKMFDCUSA-N",
      "ZINC4097036",
      "API0000487",
      "LS-92114",
      "C06171",
      "7,8-Didehydro-4,5",
      "A-Epoxy-3-Methoxy-17-Methyl-Morphinan-6-One",
    ],
  },
  {
    primary_id: "D00PYD",
    names: ["ISIS 109352"],
  },
  {
    primary_id: "D00PYP",
    names: ["NBI-37582"],
  },
  {
    primary_id: "D00PYW",
    names: ["4-[2-(4-Imidazol-1-Yl-Phenoxy)-Ethyl]-Morpholine", "CHEMBL113018", "SCHEMBL14129876", "BDBM50138231"],
  },
  {
    primary_id: "D00PZE",
    names: [
      "2-Hydroxy-17beta-Estradiol",
      "2-Hydroxyestradiol",
      "2-OH-Estradiol",
      "362-05-0",
      "2-Hydroxy-Estradiol",
      "2-OH-E2",
      "(17beta)-Estra-1,3,5(10)-Triene-2,3,17-Triol",
      "UNII-AYU2L67YUU",
      "Estra-1,3,5(10)-Triene-2,3,17beta-Triol",
      "CHEBI:28744",
      "NSC 61711",
      "AYU2L67YUU",
      "2-Hydroxy-17",
      "MLS000069506",
      "CHEMBL467987",
      "DILDHNKDVHLEQB-XSSYPUMDSA-N",
      "ESTRA-1,3,5(10)-TRIENE-2,3,17-Beta-TRIOL",
      "SMR000058615",
      "ECS",
      "CCRIS 8709",
      "2bw7",
    ],
  },
  {
    primary_id: "D00PZH",
    names: ["AC-187", "AC 187", "151804-77-2", "AC187", "Salmon Calcitonin (8-32) Reduced", "GTPL689", "AKOS024457619"],
  },
  {
    primary_id: "D00PZK",
    names: ["APD-405"],
  },
  {
    primary_id: "D00PZM",
    names: ["Erythribyssin D"],
  },
  {
    primary_id: "D00QAQ",
    names: ["Terpenoid Schweinfurthins"],
  },
  {
    primary_id: "D00QAR",
    names: ["Benzylpenicillin"],
  },
  {
    primary_id: "D00QAX",
    names: ["PF-708093"],
  },
  {
    primary_id: "D00QBV",
    names: ["DD7"],
  },
  {
    primary_id: "D00QBY",
    names: ["Angiogenesis Promoters", "Angiogenesis Promoters (Cardiovascular Disease)"],
  },
  {
    primary_id: "D00QCB",
    names: [
      "SRI-62-834",
      "CRC-8605",
      "Rac-2-[Hydroxy[2-(Octadecyloxymethyl)Tetrahydrofuran-2-Ylmethoxy]phosphinyloxy]-N,N,N-Trimethylethylamminium Hydroxyde,inner Salt P-Oxide",
    ],
  },
  {
    primary_id: "D00QCL",
    names: ["SOPHOFLAVESCENOL"],
  },
  {
    primary_id: "D00QCP",
    names: [
      "(2S,3S)-2-Phenyl-3,5,5-Trimethylmorpholin-2-Ol",
      "CHEMBL1173274",
      "SCHEMBL15007461",
      "NGHJSSNVKXEBAB-GXFFZTMASA-N",
    ],
  },
  {
    primary_id: "D00QCV",
    names: ["MG-401"],
  },
  {
    primary_id: "D00QCX",
    names: ["SCP-123"],
  },
  {
    primary_id: "D00QDE",
    names: ["7-Bromo-6H-Chromeno[4,3-B]quinoline-3,9-Diol"],
  },
  {
    primary_id: "D00QDR",
    names: ["1,2,3,4,6-Penta-O-Galloyl-Beta-D-Glucose"],
  },
  {
    primary_id: "D00QEP",
    names: ["Inotuzumab Ozogamicin"],
  },
  {
    primary_id: "D00QET",
    names: [
      "Montelukast",
      "Brondilat",
      "Montair",
      "Singular",
      "Apxi Toxin",
      "MK 0476",
      "Brondilat (TN)",
      "MK-0476",
      "Montelukast (INN)",
      "Montelukast [INN:BAN]",
      "Singulair (TN)",
      "Sodium 1-(((1-(3-(2-(7-Chloro-2-Quinolinyl)Ethenyl)Phenyl)-3-(2-(1-Hydroxy-1-Methylethyl)Phenyl)Propyl)Thio)Methyl)Cyclopropylacetate",
      "{1-[({(1R)-1-{3-[(E)-2-(7-Chloroquinolin-2-Yl)Ethenyl]phenyl}-3-[2-(1-Hydroxy-1-Methylethyl)Phenyl]propyl}sulfanyl)Methyl]cyclopropyl}acetic Acid",
      "(R-(E))-1-(((1-(3-(2-(7-Chloro-2-Quinolinyl)Ethenyl)Phenyl)-3-(2-(1-Hydroxy-1-Methylethyl)Phenyl)Propyl)Thio)Methyl)Cyclopropaneacetic Acid",
      "1-((((1R)-1-(3-((1E)-2-(7-Chloro-2-Quinolinyl)Ethenyl)Phenyl)-3-(2-(1-Hydroxy-1-Methylethyl)Phenyl)Propyl)Thio)Methyl)Cyclopropaneacetic Acid",
      "2-[1-[[(1R)-1-[3-[(E)-2-(7-Chloroquinolin-2-Yl)Ethenyl]phenyl]-3-[2-(2-Hydroxypropan-2-Yl)Phenyl]propyl]sulfanylmethyl]cyclopropyl]acetic Acid",
    ],
  },
  {
    primary_id: "D00QEZ",
    names: ["PMID25666693-Compound-60"],
  },
  {
    primary_id: "D00QFL",
    names: ["PMID19456099C13", "Compound 1d [PMID: 19456099]", "GTPL3127"],
  },
  {
    primary_id: "D00QFV",
    names: ["GR-DD1", "Cytotoxin (Hepatic Metastasis), ERYtech"],
  },
  {
    primary_id: "D00QFY",
    names: ["YM533", "Beraprost Sodium"],
  },
  {
    primary_id: "D00QGB",
    names: [
      "O-2442",
      "DAT Specific Inhibitors, Organix",
      "Dopamine Transporter Modulator, Organix",
      "O-2443",
      "O-2642",
      "O-506",
      "O-509",
    ],
  },
  {
    primary_id: "D00QGF",
    names: ["ISIS 9063"],
  },
  {
    primary_id: "D00QGP",
    names: ["ERR Alpha Modulators"],
  },
  {
    primary_id: "D00QGV",
    names: ["Human Interferon Alpha Oral"],
  },
  {
    primary_id: "D00QHT",
    names: ["AK-295"],
  },
  {
    primary_id: "D00QIA",
    names: [
      "(S)-Tert-Butyl 4-Methyl-1-Oxopentan-2-Ylcarbamate",
      "58521-45-2",
      "TERT-BUTYL (S)1-FORMYL-3-METHYLBUTYLCARBAMATE",
      "Boc-Leucinal",
      "N-T-Butoxycarbonyl-L-Leucinal",
      "CHEMBL96823",
      "N-Boc-2(S)-2-Amino-4-Methyl-Pentanal",
      "Tert-Butyl N-[(2S)-4-Methyl-1-Oxopentan-2-Yl]carbamate",
      "Boc-Leu-H",
      "Boc--L--Leucinal",
      "(S)-Boc-Leucinal",
      "T-Boc-Leu-H",
      "BOC-L-LEUCINAL",
      "BOC-LEU-CHO",
      "AC1L2WGL",
      "N--BOC--L--Leucinal",
      "T-Butoxycarbonyl-L-Leucinal",
      "Tert-Butyl (1S)-1-Formyl-3-Methylbutylcarbamate",
      "Tert-Butyloxycarbonyl-Leucinal",
      "SCHEMBL739719",
      "N-Tert-Butoxycarbonyl-L-Leucinal",
      "(T-Butyloxycarbonyl)-L-Leucinal",
    ],
  },
  {
    primary_id: "D00QIJ",
    names: ["Huntingtin Gene Antisense"],
  },
  {
    primary_id: "D00QIK",
    names: ["PS-519"],
  },
  {
    primary_id: "D00QIU",
    names: ["LXB4"],
  },
  {
    primary_id: "D00QJA",
    names: ["Biphenyl Carboxamidopropanoic Acid Derivative 4"],
  },
  {
    primary_id: "D00QJL",
    names: ["Influvac TC"],
  },
  {
    primary_id: "D00QKI",
    names: ["SCH-527123"],
  },
  {
    primary_id: "D00QKL",
    names: [
      "T2c-003",
      "Bone Marrow-Derived Progenitor Cells Therapy (Intra-Arterial Infusion, Diabetic Neuropathy), T2cure",
    ],
  },
  {
    primary_id: "D00QKS",
    names: [
      "BMS-954561",
      "AGN-001",
      "AGN-209323",
      "AGN-2XXXXX",
      "AGN-323",
      "EHT-001",
      "EHT/AGN-0001",
      "EHT/AGN-001",
      "GABA/Nicotinic Modulator (Neuroscience), BMS",
      "Neuropathic Pain Therapy, ExonHit/Allergan/Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D00QLG",
    names: ["Lovaxin M"],
  },
  {
    primary_id: "D00QLH",
    names: ["H-Dmt-Tic-D-Asp-N(Me)-Ph", "CHEMBL502302"],
  },
  {
    primary_id: "D00QLM",
    names: [
      "[3H]alphabeta-MeATP",
      "Diphosphomethylphosphonic Acid Adenosyl Ester",
      "Alpha,beta-Methylene ATP",
      "Alpha,beta-MeATP",
      "7292-42-4",
      "UNII-NYX13NT29D",
      "Alpha,beta-Methyleneadenosine 5'-Triphosphate",
      "EINECS 230-723-9",
      "NYX13NT29D",
      "ALPHA,BETA-METHYLENEADENOSINE-5'-TRIPHOSPHATE",
      "CHEMBL132722",
      "CHEBI:35056",
      "5'-O-(Hydroxy{[hydroxy(Phosphonooxy)Phosphoryl]methyl}phosphoryl)Adenosine",
      "5'-(Hydrogen((Hydroxy(Phosphonooxy)Phosphinyl)Methyl)Phosphonate)Adenosine",
      "Adenosine 5'-[alpha,beta-Methylene]triphosphate",
      "AMP-CPP",
      "Adenosine 5'-(Hydrogen",
      "Alpha,Beta-Methyleneadenosine-5'-Triphosphate",
    ],
  },
  {
    primary_id: "D00QLR",
    names: ["(-)-DEBROMOFLUSTRAMINE B", "CHEMBL383307", "(-)-DebromoflustramineB", "BDBM50241051"],
  },
  {
    primary_id: "D00QMQ",
    names: ["D-220", "CHEMBL468833", "BDBM50261303"],
  },
  {
    primary_id: "D00QMZ",
    names: ["PS-662477", "CHEMBL74562", "BDBM50071550"],
  },
  {
    primary_id: "D00QNW",
    names: ["Laropiprant/Niacin/ Simvastatin Combination"],
  },
  {
    primary_id: "D00QOF",
    names: ["ORG-13011"],
  },
  {
    primary_id: "D00QOG",
    names: ["F-2833"],
  },
  {
    primary_id: "D00QOH",
    names: ["OCT-SG815"],
  },
  {
    primary_id: "D00QOJ",
    names: [
      "99MTC-RP-128",
      "(SP-5-25)-Hydrogen [N,N-Dimethylglycyl-KappaN-L-Seryl-KappaN-L-Cysteinyl-KappaN,kappaS-Glycyl-L-Threonyl-L-Lysyl-L-Prolyl-L-Prolyl-L-Argininato(4-)]oxotechnetate(1-)-99Tc",
      "186037-48-9",
    ],
  },
  {
    primary_id: "D00QOL",
    names: [
      "3-Benzyl-1-Cyclohexylpyrrolidin-2-One",
      "CHEMBL218285",
      "3-Benzyl-1-Cyclohexyl-Pyrrolidin-2-One",
      "SCHEMBL2986964",
      "DGGDICYTCJZLPW-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D00QPG",
    names: [
      "3-(4-Dimethylamino-Benzylidenyl)-2-Indolinone",
      "Dmbi",
      "3-(4-Dimethylaminobenzylidenyl)-2-Indolinone",
      "CHEMBL296455",
      "(Z)-3-[4-(Dimethylamino)Benzylidenyl]indolin-2-One",
      "3-[4-(Dimethylamino)Benzylidene]-1,3-Dihydro-2H-Indol-2-One",
      "SU-4312",
      "Tocris-1459",
      "Trans-SU 4312",
      "AC1NS4CN",
      "Lopac-S-8567",
      "AC1Q6J0Q",
      "SCHEMBL644678",
      "GTPL5958",
      "SCHEMBL13394695",
      "ZINC21786",
      "MolPort-019-728-327",
      "MolPort-002-894-447",
      "3-[[(4-Dimethyl-Amino)Phenyl]methylene]-1,3-Dihydro-2H-Indol-2-One, E/Z Mixture",
      "HMS3229C19",
      "HSCI1_000057",
      "MFCD00118147",
      "BDBM50111603",
      "AKOS001034409",
      "CCG-206747",
      "NCGC00025170-01",
    ],
  },
  {
    primary_id: "D00QPU",
    names: [
      "Dehydroepiandrosterone Sulphate",
      "3-Beta-Hydroxyandrost-5-En-17-One 3-Sulfate",
      "CCG-204473",
      "[3H]dehydroepiandrosterone Sulfate",
      "[3H]dehydroepiandrosterone Sulphate",
      "[3H]DHEAS",
      "GTPL5577",
      "GTPL4528",
      "CHEBI:57905",
      "Dehydroepiandrosterone Sulfate(1-)",
      "3beta-Sulfooxy-Androst-5-En-17-One",
      "17-Oxoandrost-5-En-3beta-Yl Sulfate",
      "3beta-Hydroxyandrost-5-En-17-One 3-Sulfate Anion",
      "3beta-Hydroxyandrost-5-En-17-One 3-Sulfate(1-)",
      "(1S,2R,5S,10R,11S,15S)-2,15-Dimethyl-14-Oxotetracyclo[8700^{2,7}0^{11,15}]heptadec-7-En-5-Yl Sulfate",
    ],
  },
  {
    primary_id: "D00QSV",
    names: [
      "2-(3-Methoxy-Phenyl)-1-Methyl-Ethylamine",
      "3-Methoxyamphetamine",
      "1-(3-Methoxyphenyl)Propan-2-Amine",
      "17862-85-0",
      "Benzeneethanamine, 3-Methoxy-Alpha-Methyl-",
      "CHEMBL16247",
      "VEJWNIYARKAHFI-UHFFFAOYSA-N",
      "2-(3-Methoxyphenyl)-1-Methylethylamine",
      "17862-91-8",
      "Meta-Methoxyamphetamine",
      "3-Methoxyamphetamine -",
      "AC1L47YE",
      "NCIOpen2_006073",
      "SCHEMBL269071",
      "CTK0H8604",
      "1-(3-Methoxyphenyl)-2-Propylamine",
      "BDBM50024211",
      "AKOS000157039",
      "AKOS022196418",
      "MCULE-6670391418",
      "MB01562",
      "1-(3-Methoxyphenyl)-2-Propanamine #",
      "NCI60_042089",
    ],
  },
  {
    primary_id: "D00QTA",
    names: [
      "A-058",
      "A-095",
      "A-166",
      "A-167",
      "A-168",
      "A-169",
      "A-448",
      "A-510",
      "A-521",
      "A-524",
      "A-526",
      "A-629",
      "A-640",
      "A-644",
      "A-850",
      "A-874",
      "A-972",
      "HCV NS5b Polymerase Inhibitors, Arrow Therapeutics",
    ],
  },
  {
    primary_id: "D00QTL",
    names: ["MDDR 184045"],
  },
  {
    primary_id: "D00QTU",
    names: ["TH-1320"],
  },
  {
    primary_id: "D00QTX",
    names: ["ZK110841"],
  },
  {
    primary_id: "D00QUJ",
    names: [
      "AM-3005",
      "Bacterial Protein Synthesis Inhibitor (Gram-Positive Bacterial Infection), Kyorin",
      "Topoisomerase II Inhibitor (Gram-Positive Bacterial Infection), Kyorin",
      "Mutilin-Quinolone Hybrid Antibiotic (Gram-Positive Bacterial Infection), Kyorin",
    ],
  },
  {
    primary_id: "D00QUN",
    names: ["Isopropyl 4-Nitrophenyl Dodecylphosphonate"],
  },
  {
    primary_id: "D00QUZ",
    names: ["TTP-814"],
  },
  {
    primary_id: "D00QVP",
    names: [
      "6,11-Dihydrothiochromeno[4,3-B]indol-8-Ol",
      "CHEMBL210924",
      "SCHEMBL1986592",
      "BVGUKMAMOJMOFD-UHFFFAOYSA-N",
      "BDBM50185203",
    ],
  },
  {
    primary_id: "D00QVT",
    names: ["ATN-161"],
  },
  {
    primary_id: "D00QVY",
    names: ["Resorcinol Compound 23"],
  },
  {
    primary_id: "D00QWK",
    names: ["CLL1-Specific Gene-Engineered T Cells"],
  },
  {
    primary_id: "D00QWP",
    names: ["HYG-113"],
  },
  {
    primary_id: "D00QWU",
    names: ["BMS-779788"],
  },
  {
    primary_id: "D00QXB",
    names: ["WT1 Peptide Vaccine"],
  },
  {
    primary_id: "D00QXV",
    names: ["BMS-181101", "1H-Indole"],
  },
  {
    primary_id: "D00QYH",
    names: ["Apelin-13", "[Pyr1]-Apelin-13"],
  },
  {
    primary_id: "D00QYO",
    names: [
      "2,5-Didesoxy-3-ATP",
      "2',5'-DD-3'-ATP",
      "2',5'-Dideoxyadenosine 3'-(Tetrahydrogen Triphosphate)",
      "ZDA",
      "SCHEMBL375553",
      "CHEMBL1162355",
      "ZINC13829365",
      "2',5'-Dideoxyadenosine 3'-Triphosphate",
      "2',5'-Dideoxyadenosine 3'-Triphosphoric Acid",
      "(2R,3S,5R)-5-(6-Amino-9H-Purin-9-Yl)-2-Methyltetrahydrofuran-3-Yl Tetrahydrogen Triphosphate",
    ],
  },
  {
    primary_id: "D00QZA",
    names: ["DS-437", "DS437"],
  },
  {
    primary_id: "D00QZJ",
    names: ["Growth Hormone"],
  },
  {
    primary_id: "D00RAK",
    names: ["7,8-Dihydroneopterin"],
  },
  {
    primary_id: "D00RAO",
    names: ["4,5-ISOBUDLEIN A", "4,5-Isobudlein A"],
  },
  {
    primary_id: "D00RBV",
    names: [
      "6,7-Dimethoxy-3-Pyridin-3-Yl-Quinoline",
      "CHEMBL304668",
      "SCHEMBL8170900",
      "KYJQMVJJOORKLO-UHFFFAOYSA-N",
      "ZINC3832247",
      "BDBM50039050",
      "3-(6,7-Dimethoxyquinolin-3-Yl)Pyridine",
      "6,7-Dimethoxy-3-(3-Pyridinyl)Quinoline",
    ],
  },
  {
    primary_id: "D00RCI",
    names: ["Teduglutide"],
  },
  {
    primary_id: "D00RCV",
    names: [
      "Acetic Acid 2-Hex-2-Ynylsulfanyl-Phenyl Ester",
      "CHEMBL144920",
      "ZINC13782090",
      "BDBM50068198",
      "Acetic Acid 2-(2-Hexynylthio)Phenyl Ester",
    ],
  },
  {
    primary_id: "D00RDE",
    names: [
      "4-(4'-N-Methylphenyl)Diazenylbenzenesulfonamide",
      "Azo-Sulfonamide, 1c",
      "CHEMBL571371",
      "BDBM33268",
      "4-[(E)-[4-(Methylamino)Phenyl]azo]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D00RDI",
    names: ["OPN-401"],
  },
  {
    primary_id: "D00RDL",
    names: ["PMID27109571-Compound-31"],
  },
  {
    primary_id: "D00RDO",
    names: ["PMID26651364-Compound-116"],
  },
  {
    primary_id: "D00RDS",
    names: [
      "4-Tert-Butyl-N-(Quinolin-3-Yl)Benzamide",
      "CHEMBL258917",
      "331850-72-7",
      "AC1LGMQ4",
      "Oprea1_306931",
      "Oprea1_506548",
      "SCHEMBL846396",
      "CTK1B8678",
      "DTXSID30355358",
      "MolPort-001-496-766",
      "ZINC302143",
      "BDBM50376277",
      "AKOS000637295",
      "MCULE-9209807944",
      "4-Tert-Butyl-N-Quinolin-3-Ylbenzamide",
      "4-Tert-Butyl-N-Quinolin-3-Yl-Benzamide",
      "BAS 00631860",
      "ST45006323",
      "[4-(Tert-Butyl)Phenyl]-N-(3-Quinolyl)Carboxamide",
      "Benzamide, 4-(1,1-Dimethylethyl)-N-3-Quinolinyl-",
    ],
  },
  {
    primary_id: "D00RDY",
    names: [
      "5'-Iodotubercidin",
      "5-Iodotubercidin",
      "24386-93-4",
      "5-Iodotubericidin",
      "7-Deaza-7-Iodoadenosine",
      "7-Iodo-7-Deazaadenosine",
      "7-Iodotubercidin",
      "(2R,3R,4S,5R)-2-(4-AMINO-5-IODO-7H-PYRROLO[2,3-D]PYRIMIDIN-7-YL)-5-(HYDROXYMETHYL)TETRAHYDROFURAN-3,4-DIOL",
      "CHEMBL99203",
      "CHEBI:40167",
      "5-Iodo-7-Beta-D-Ribofuranosyl-7H-Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "NSC 113939",
      "7H-Pyrrolo[2,3-D]pyrimidin-4-Amine,5-Iodo-7-B-D-Ribofuranosyl-",
      "7H-Pyrrolo(2,3-D)Pyrimidin-4-Amine, 5-Iodo-7-Beta-D-Ribofuranosyl-",
    ],
  },
  {
    primary_id: "D00REB",
    names: ["AZ11657312 (Salt Free)", "SCHEMBL5079735", "GTPL7722", "CHEMBL550637"],
  },
  {
    primary_id: "D00REK",
    names: ["(5-(1-Benzyl-1H-Indazol-3-Yl)Furan-2-Yl)Methanol"],
  },
  {
    primary_id: "D00REW",
    names: [
      "[11C]MMTP",
      "GTPL6212",
      "CHEMBL1082810",
      "Dimethylamino-3(4-[11C]methoxyphenyl)-3H-Pyrido[3',2':4,5]thieno[3,2-D]pyrimidin-4-One",
    ],
  },
  {
    primary_id: "D00RFY",
    names: [
      "DIOSMETIN",
      "Diosmetin",
      "520-34-3",
      "Luteolin 4'-Methyl Ether",
      "5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)-4H-Chromen-4-One",
      "Salinigricoflavonol",
      "4'-Methylluteolin",
      "5,7,3'-Trihydroxy-4'-Methoxyflavone",
      "UNII-TWZ37241OT",
      "Luteolin 4-Methyl Ether",
      "5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)-4-Benzopyrone",
      "3',5,7-Trihydroxy-4'-Methoxyflavone",
      "CHEBI:4630",
      "TWZ37241OT",
      "5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)Chromen-4-One",
      "4H-1-Benzopyran-4-One, 5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)-",
      "MFCD00017425",
      "AK111246",
    ],
  },
  {
    primary_id: "D00RFZ",
    names: ["PSI-6130"],
  },
  {
    primary_id: "D00RGD",
    names: [
      "N-Hydroxy-8-Oxo-8-(Pyridin-3-Yl)Octanamide",
      "CHEMBL98911",
      "SCHEMBL3378753",
      "BDBM50114831",
      "8-Oxo-8-Pyridin-3-Yl-Octanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D00RGF",
    names: ["GCC-4401"],
  },
  {
    primary_id: "D00RGL",
    names: ["W-F-F-N", "CHEMBL413880", "W-F-F-N--Y-Y-W"],
  },
  {
    primary_id: "D00RGO",
    names: ["AG-L-66494"],
  },
  {
    primary_id: "D00RGP",
    names: ["MEDIORESINOL"],
  },
  {
    primary_id: "D00RGU",
    names: ["Ion Channel Therapy"],
  },
  {
    primary_id: "D00RGX",
    names: ["RG7876"],
  },
  {
    primary_id: "D00RHH",
    names: ["PMID17574411C41", "2q6b", "GTPL3006", "BDBM50215676"],
  },
  {
    primary_id: "D00RIB",
    names: ["RG-201"],
  },
  {
    primary_id: "D00RIK",
    names: [
      "B7/CD28 Costimulatory Proteins",
      "CGEN-15021",
      "B7/CD28 Costimulatory Proteins (Autoimmune Disease)",
      "B7/CD28 Costimulatory Proteins (Autoimmune Disease), Compugen",
      "CGEN-15021T (Recombinant Fusion Protein, Autoimmune Diseases), Compugen",
    ],
  },
  {
    primary_id: "D00RIX",
    names: [
      "APIGENIN",
      "Apigenin",
      "520-36-5",
      "5,7-Dihydroxy-2-(4-Hydroxyphenyl)-4H-Chromen-4-One",
      "Chamomile",
      "Versulin",
      "Spigenin",
      "Apigenol",
      "4',5,7-Trihydroxyflavone",
      "Apigenine",
      "C.I. Natural Yellow 1",
      "5,7,4'-Trihydroxyflavone",
      "Pelargidenon 1449",
      "5,7-Dihydroxy-2-(4-Hydroxyphenyl)-4-Benzopyrone",
      "2-(P-Hydroxyphenyl)-5,7-Dihydroxychromone",
      "UCCF 031",
      "NSC 83244",
      "UNII-7V515PI7F6",
      "5,7-Dihydroxy-2-(4-Hydroxyphenyl)-4H-1-Benzopyran-4-One",
      "5,7-Dihydroxy-2-(4-Hydroxyphenyl)Chromen-4-One",
      "CCRIS 3789",
      "CHEBI:18388",
      "CHEMBL28",
      "EINECS 208-292-3",
      "4H-1-Benzopyran-4-One, 5,7-Di",
    ],
  },
  {
    primary_id: "D00RJA",
    names: ["VX-148"],
  },
  {
    primary_id: "D00RJB",
    names: ["Rilopirox"],
  },
  {
    primary_id: "D00RJE",
    names: ["Pancopride"],
  },
  {
    primary_id: "D00RJJ",
    names: ["Ganirelix"],
  },
  {
    primary_id: "D00RJN",
    names: [
      "House Dust Mite Allergen",
      "Acaroid",
      "House Dust Mite Allergen (Asthma/Rhinitis)",
      "House Dust Mite Allergen (Asthma/Rhinitis), Allergopharma",
    ],
  },
  {
    primary_id: "D00RJT",
    names: ["NITRATE"],
  },
  {
    primary_id: "D00RKV",
    names: ["Carbamate Derivative 8"],
  },
  {
    primary_id: "D00RLE",
    names: [
      "4-(4-Chlorobenzyl)-2-Methylphthalazin-1(2H)-One",
      "CHEMBL67457",
      "4-[(4-Chlorophenyl)Methyl]-2-Methyl-Phthalazin-1-One",
      "BDBM50131411",
      "4-(4-Chloro-Benzyl)-2-Methyl-2H-Phthalazin-1-One",
      "2-Methyl-4-(4-Chlorobenzyl)-1,2-Dihydrophthalazine-1-One",
      "1(2H)-Phthalazinone, 4-[(4-Chlorophenyl)Methyl]-2-Methyl-",
    ],
  },
  {
    primary_id: "D00RLO",
    names: ["(3-Bromo-Propyl)-Trimethyl-Ammonium"],
  },
  {
    primary_id: "D00RMC",
    names: ["BIBX-1382"],
  },
  {
    primary_id: "D00RMD",
    names: ["FK-960"],
  },
  {
    primary_id: "D00RNA",
    names: [
      "N-Hydroxy-3-(2-Oxo-2H-Chromen-3-Yl)Propanamide",
      "CHEMBL252674",
      "2H-1-Benzopyran-3-Propanamide, N-Hydroxy-2-Oxo-",
      "BDBM50224963",
    ],
  },
  {
    primary_id: "D00RNB",
    names: ["IQB-9302"],
  },
  {
    primary_id: "D00RNZ",
    names: [
      "7-(Benzyloxy)-2H-Chromen-2-One",
      "7-BENZYLOXYCOUMARIN",
      "CHEMBL145781",
      "31005-04-6",
      "7-Phenylmethoxychromen-2-One",
      "AC1LIRVN",
      "AC1Q6HKT",
      "7-Benzyloxychromen-2-One",
      "ChemDiv3_006648",
      "Oprea1_123644",
      "MLS001198544",
      "CM16",
      "SCHEMBL3901170",
      "BEN294",
      "7-(Phenylmethoxy)Chromen-2-One",
      "CTK1C0068",
      "DTXSID30358687",
      "QDLFCBKKECTJSK-UHFFFAOYSA-N",
      "MolPort-001-573-426",
      "ZINC493069",
      "HMS2864P20",
      "HMS1491O04",
      "CCG-25276",
      "STK373844",
      "BDBM50378564",
      "AKOS001064953",
      "MCULE-8905496403",
      "IDI1_024558",
      "SMR000558913",
      "ST50429120",
      "2H-1-Benzopyran-2-One, 7-(Phenylmethoxy)",
    ],
  },
  {
    primary_id: "D00ROV",
    names: ["FK-041"],
  },
  {
    primary_id: "D00ROY",
    names: [
      "Adjustable Basal Insulin",
      "Adjustable Basal Insulin (Diabetes)",
      "BIOD-Adjustable Basal",
      "BIOD-Basal",
      "Adjustable Basal Insulin (Diabetes), Biodel",
    ],
  },
  {
    primary_id: "D00RPV",
    names: ["EPB-200"],
  },
  {
    primary_id: "D00RPW",
    names: ["1,6-Diazanaphthalenes And Pyrido[2,3-D] Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D00RQQ",
    names: [
      "ASP-1120",
      "ASP-9596",
      "Sodium Channel Blockers (Pain)",
      "Sodium Channel Blockers (Pain), Astellas/Kotobuki",
    ],
  },
  {
    primary_id: "D00RQW",
    names: ["Biaryl Mannoside Derivative 8"],
  },
  {
    primary_id: "D00RRB",
    names: ["Isoflavone Derivative 1"],
  },
  {
    primary_id: "D00RRU",
    names: ["BIIB037"],
  },
  {
    primary_id: "D00RRV",
    names: [
      "3-Imidazol-1-Ylmethyl-1H-Indole",
      "CHEMBL116794",
      "1H-Indole, 3-(1H-Imidazol-1-Ylmethyl)-",
      "19714-15-9",
      "MLS000105265",
      "AC1LGCIB",
      "Cambridge Id 5261471",
      "CBDivE_013805",
      "SCHEMBL9214699",
      "3-(Imidazol-1-Ylmethyl)Indole",
      "CTK0E0809",
      "DTXSID40355147",
      "FRADTKAUXQUOIJ-UHFFFAOYSA-N",
      "MolPort-001-788-875",
      "HMS2390H20",
      "ZINC293947",
      "3-(Imidazol-1-Ylmethyl)-1H-Indole",
      "BDBM50022120",
      "AKOS030489795",
      "MCULE-1147695503",
      "3-(1H-Imidazol-1-Ylmethyl)-1H-Indole",
      "BAS 01027138",
      "1-(1H-Indole-3-Ylmethyl)-1H-Imidazole",
    ],
  },
  {
    primary_id: "D00RSA",
    names: ["XmAb-20717"],
  },
  {
    primary_id: "D00RSK",
    names: ["GW-406381"],
  },
  {
    primary_id: "D00RST",
    names: ["1-Adamantan-1-Yl-3-(6-Propyloxyhexyl)Urea", "CHEMBL245144", "SCHEMBL12931856"],
  },
  {
    primary_id: "D00RSX",
    names: ["Porcine Liver Cells"],
  },
  {
    primary_id: "D00RTC",
    names: ["Vicinium"],
  },
  {
    primary_id: "D00RTM",
    names: ["3-(1H-Indol-3-Yl)-N,N-Dimethylpropan-1-Amine"],
  },
  {
    primary_id: "D00RTU",
    names: ["227Th-Rituximab (Cancer), Algeta"],
  },
  {
    primary_id: "D00RUE",
    names: ["PMID25522065-Compound-29"],
  },
  {
    primary_id: "D00RUN",
    names: [
      "4-(7,7-Dimethyl-Oct-3-Ynyl)-1H-Imidazole",
      "CHEMBL280076",
      "1H-Imidazole, 5-(7,7-Dimethyl-3-Octyn-1-Yl)-",
      "SCHEMBL7619037",
      "PDSP1_000108",
      "BDBM50070214",
      "PDSP2_000108",
      "GT 2293",
      "209599-16-6",
    ],
  },
  {
    primary_id: "D00RWP",
    names: ["GwTLNSAGYLLGPHAVGNHRSFSDKNGLTS-CONH2"],
  },
  {
    primary_id: "D00RWQ",
    names: [
      "CX157",
      "O6L62LZJ0Q",
      "CX-157",
      "CX 157",
      "205187-53-7",
      "UNII-O6L62LZJ0Q",
      "SCHEMBL235650",
      "DTXSID40174517",
      "PDIMOTRDGUQMNY-UHFFFAOYSA-N",
      "HY-100178",
      "CS-0018174",
      "3-Fluoro-7-(2,2,2-Trifluoroethoxy)Phenoxathiin 10,10-Dioxide",
      "Phenoxathiin, 3-Fluoro-7-(2,2,2-Trifluoroethoxy)-, 10,10-Dioxide",
    ],
  },
  {
    primary_id: "D00RWU",
    names: ["FT-301"],
  },
  {
    primary_id: "D00RWW",
    names: ["Fexinidazole"],
  },
  {
    primary_id: "D00RXR",
    names: ["AC-253", "Antam", "Amylin Antagonist, Amylin", "Amylin Antagonist, Glaxo", "GG-747"],
  },
  {
    primary_id: "D00RXS",
    names: ["AMD3101"],
  },
  {
    primary_id: "D00RYA",
    names: ["SB-1578"],
  },
  {
    primary_id: "D00RYU",
    names: ["IPH-2102"],
  },
  {
    primary_id: "D00RZA",
    names: ["EPB-500"],
  },
  {
    primary_id: "D00RZN",
    names: [
      "Abyssinin II",
      "5'-Prenylhomoeriodictyol",
      "CHEMBL388722",
      "671781-82-1",
      "Abyssinoflavanone II",
      "AC1L9CV8",
      "SCHEMBL4743888",
      "DTXSID10331832",
      "ZINC4098362",
      "LMPK12140439",
      "BDBM50212397",
      "C09831",
    ],
  },
  {
    primary_id: "D00SAA",
    names: [
      "3-Isopropylnaphthalene-1,2-Dione",
      "CHEMBL44166",
      "3-Isopropyl-[1,2]naphthoquinone",
      "3-Isopropyl-1,2-Naphthoquinone",
      "BDBM50009207",
      "ZINC13821901",
    ],
  },
  {
    primary_id: "D00SAB",
    names: ["7-M-Tolyl-1,6-Naphthyridine", "CHEMBL238388"],
  },
  {
    primary_id: "D00SAH",
    names: ["PMID26560530-Compound-54"],
  },
  {
    primary_id: "D00SAR",
    names: ["PMID27109571-Compound-41"],
  },
  {
    primary_id: "D00SBD",
    names: ["Recoflavone Eye Drops"],
  },
  {
    primary_id: "D00SBN",
    names: ["Perhexiline"],
  },
  {
    primary_id: "D00SBU",
    names: ["PF614"],
  },
  {
    primary_id: "D00SBX",
    names: [
      "ZL006",
      "4-((3,5-Dichloro-2-Hydroxybenzyl)Amino)-2-Hydroxybenzoic Acid",
      "SCHEMBL15367533",
      "ZINC68267234",
      "ZL-006",
      "AKOS032945012",
      "CS-5881",
      "ZL006, &gt",
      "HY-100456",
      "J3.550.774D",
    ],
  },
  {
    primary_id: "D00SCI",
    names: ["MI-3"],
  },
  {
    primary_id: "D00SDJ",
    names: [
      "A-706149",
      "CHEMBL201232",
      "SCHEMBL3290844",
      "FKHBHNWGBJHKGW-UHFFFAOYSA-N",
      "BDBM50176440",
      "1-(4-(4-(2-Tert-Butyl-6-(Trifluoromethyl)Pyrimidin-4-Yl)Piperazin-1-Yl)Butyl)-3,4-Dihydro-1H-Benzo[b]azepine-2,5-Dione",
    ],
  },
  {
    primary_id: "D00SDP",
    names: ["17-(Cyclopropylmethyl)-N-Phenylmorphinan-3-Amine", "CHEMBL568757"],
  },
  {
    primary_id: "D00SDQ",
    names: ["PMID21907142CJ"],
  },
  {
    primary_id: "D00SEB",
    names: ["Enalapril"],
  },
  {
    primary_id: "D00SEC",
    names: ["Aloisine"],
  },
  {
    primary_id: "D00SEE",
    names: [
      "7-Mercapto-Heptanoic Acid Phenylamide",
      "Thiol-SAHA (T-SAHA)",
      "CHEMBL325676",
      "SCHEMBL14821761",
      "BDBM152692",
    ],
  },
  {
    primary_id: "D00SFN",
    names: [
      "TUCARESOL",
      "BW-A589C",
      "Tucaresol < Rec INN",
      "4-(2-Formyl-3-Hydroxyphenoxymethyl)Benzoic Acid",
      "589C",
      "589C80",
    ],
  },
  {
    primary_id: "D00SFY",
    names: ["BI-44847"],
  },
  {
    primary_id: "D00SGD",
    names: ["Pentacel"],
  },
  {
    primary_id: "D00SGZ",
    names: ["YM 218"],
  },
  {
    primary_id: "D00SHQ",
    names: [
      "Bromperidol",
      "10457-90-6",
      "Impromen",
      "Bromoperidol",
      "Tesoprel",
      "Azurene",
      "4-(4-(4-Bromophenyl)-4-Hydroxypiperidin-1-Yl)-1-(4-Fluorophenyl)Butan-1-One",
      "UNII-LYH6F7I22E",
      "Bromperidolum [INN-Latin]",
      "R 11333",
      "EINECS 233-943-3",
      "4-(4-(4-Bromophenyl)-4-Hydroxypiperidino)-4'-Fluorobutyrophenone",
      "CC 2489",
      "BRN 1552256",
      "LYH6F7I22E",
      "4-(4-(P-Bromophenyl)-4-Hydroxypiperidino)-4'-Fluorobutyrophenone",
      "RKLNONIVDFXQRX-UHFFFAOYSA-N",
      "4-(4-(4-Bromophenyl)-4-Hydroxy-1-Piperidinyl)-1-(4-Fluorophenyl)-1-Butanone",
      "NCGC00016692-01",
      "CAS-10457-90-6",
    ],
  },
  {
    primary_id: "D00SHS",
    names: ["Human Hemoglobin"],
  },
  {
    primary_id: "D00SHX",
    names: ["1-[2-(2,4,6-Trimethyl-Phenyl)-Ethyl]-Pyrrolidine", "CHEMBL272140"],
  },
  {
    primary_id: "D00SIA",
    names: [
      "ARQ-171",
      "ARQ-550RP",
      "ARQ-580",
      "E2F Modulators (Anticancer)",
      "E2F Modulators (Anticancer), ArQule",
      "550 Series (E2F Modulators), Arqule",
      "550 Series (Anticancer), Arqule",
      "550 Series, Arqule",
    ],
  },
  {
    primary_id: "D00SIC",
    names: ["ISIS 4189"],
  },
  {
    primary_id: "D00SIR",
    names: [
      "CS-461",
      "CS 461",
      "Thiazolium, 3-((7-(((2-Amino-4-Thiazolyl)(Methoxyimino)Acetyl)Amino)-2-En-3-Yl)Methyl)-5-(2-Hydroxyethyl)-4-Methyl-, (6R-(6alpha,7beta(Z)))-, Sulfate (1:1) (Salt)",
      "Thiazolium, 3-((7-(((2-Amino-4-Thiazolyl)(Methoxyimino)Acetyl)Amino)-2-Carboxy-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-3-Yl)Methyl)-5-(2-Hydroxyethyl)-4-Methyl-, (6R-(6-Alpha,7-Beta(Z)))-, Sulfate (1:1) (Salt)",
      "115948-58-8",
      "134102-27-5",
      "LS-151924",
    ],
  },
  {
    primary_id: "D00SIZ",
    names: ["RG6180"],
  },
  {
    primary_id: "D00SJE",
    names: [
      "Secobarbital",
      "Barbosec",
      "Evronal",
      "Hypotrol",
      "Hyptran",
      "Meballymal",
      "Meballymalum",
      "Pramil",
      "Quinalbarbital",
      "Quinalbarbitone",
      "Secobarbitale",
      "Secobarbitalum",
      "Secobarbitone",
      "Seconal",
      "Seotal",
      "Somosal",
      "Secobarbital Solution",
      "Secobarbital Suppository Dosage Form",
      "Secobarbitale [DCIT]",
      "Secobarbital [USAN:INN]",
      "Secobarbitalum [INN-Latin]",
      "Seconal (TN);Secobarbital (USP/INN)",
      "(+-)-5-Allyl-5-(1-Methylbutyl)-Barbituric Acid",
      "(+-)-Secobarbital",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5-(1-Methylbutyl)-5-(2-Propenyl)-, (+-)-(9CI)",
      "5-(1-Methylbutyl)-5-(2-Propenyl)-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-(Pentan-2-Yl)-5-(Prop-2-En-1-Yl)-Pyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-ALLYL-5-(1-METHYLBUTYL)BARBITURIC ACID",
      "5-Allyl-5-(1-Methylbutyl)-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Allyl-5-(1-Methylbutyl)Barbiturate",
      "5-Allyl-5-(1-Methylbutyl)Malonylurea",
      "5-Allyl-5-(1-Methylbutyl)Pyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-Pentan-2-Yl-5-Prop-2-Enyl-1,3-Diazinane-2,4,6-Trione",
    ],
  },
  {
    primary_id: "D00SJN",
    names: ["HLL1-Fab-A3B3"],
  },
  {
    primary_id: "D00SJT",
    names: [
      "4-Flourobenzenesulfonamide",
      "4-Fluorobenzenesulfonamide",
      "402-46-0",
      "P-Fluorobenzenesulfonamide",
      "4-Fluorobenzenesulphonamide",
      "4-Fluorobenzene-1-Sulfonamide",
      "P-Fluorobenzenesulphonamide",
      "4-FLOUROBENZENESULFONAMIDE",
      "EINECS 206-946-2",
      "4-Fluoro-Benzenesulfonamide",
      "BRN 2832246",
      "Benzenesulfonamide, P-Fluoro-",
      "Benzenesulfonamide, 4-Fluoro-",
      "PubChem2167",
      "ACMC-1ACSJ",
      "4-Fuloro Sulfonamide, C",
      "4-Fluorobenzene Sulfonamide",
      "Cambridge Id 5135296",
      "AC1Q55BS",
      "AC1L3QD2",
      "4-Fluoranylbenzenesulfonamide",
      "4-Fluoro Benzene Sulfonamide",
      "Benzenesulfonamide,4-Fluoro-",
      "4-11-00-00102 (Be",
    ],
  },
  {
    primary_id: "D00SJX",
    names: ["NK-110"],
  },
  {
    primary_id: "D00SKM",
    names: ["TT12"],
  },
  {
    primary_id: "D00SKS",
    names: ["GS-8374"],
  },
  {
    primary_id: "D00SLD",
    names: ["PMID26004420-Compound-WO2013028474C"],
  },
  {
    primary_id: "D00SLE",
    names: ["3-[5-(4-Hydroxyphenyl)-1,3-Thiazol-2-Yl]phenol"],
  },
  {
    primary_id: "D00SLJ",
    names: ["N1-(3,3-Diphenylpropyl)-N3-Hydroxymalonamide", "CHEMBL219785"],
  },
  {
    primary_id: "D00SLR",
    names: ["2,7-Bis[2-(Isopropylamino)Acetamido]anthraquinone", "SCHEMBL4300462", "CHEMBL494259"],
  },
  {
    primary_id: "D00SLY",
    names: ["Umeclidinium", "573719"],
  },
  {
    primary_id: "D00SMC",
    names: ["PAN-22", "Anti-HAAH Monoclonal Antibody (Hepatocellular Carcinoma), Panacea"],
  },
  {
    primary_id: "D00SML",
    names: ["NMHSS1-1"],
  },
  {
    primary_id: "D00SMU",
    names: [
      "Lenti-D",
      "Adrenoleukodystrophy Gene Therapy, INSERM/Genetix",
      "CCALD Program (Cerebral Adrenoleukodystrophy), INSERM/Bluebird",
      "Genetically-Modified Stem Cell Therapy (Adrenoleukodystrophy), INSERM/Bluebird",
    ],
  },
  {
    primary_id: "D00SNI",
    names: ["ISIS 29475"],
  },
  {
    primary_id: "D00SNJ",
    names: ["N-(1-(1-Phenylethyl)Piperidin-4-Yl)-1-Naphthamide", "CHEMBL228865"],
  },
  {
    primary_id: "D00SNO",
    names: ["NSC-645833"],
  },
  {
    primary_id: "D00SNY",
    names: ["TDI-0057"],
  },
  {
    primary_id: "D00SOE",
    names: ["KSG-YEKLSSIESDV", "CHEMBL445253"],
  },
  {
    primary_id: "D00SOM",
    names: ["Spiro[piperidine-2,2-Adamantane]"],
  },
  {
    primary_id: "D00SOZ",
    names: ["DE-117"],
  },
  {
    primary_id: "D00SPK",
    names: ["E-4021", "CHEMBL25616", "E4021"],
  },
  {
    primary_id: "D00SPM",
    names: [
      "Amyloid Beta Oligomers",
      "Amyloid Beta Oligomers (Imaging Agent)",
      "LAS-0419",
      "LRL-22",
      "LRL-22 Analogs",
      "LRL-50",
      "Amyloid Beta Oligomers (Imaging Agent), University Of California Davis",
    ],
  },
  {
    primary_id: "D00SQD",
    names: ["ISIS 112022"],
  },
  {
    primary_id: "D00SQN",
    names: [
      "HBI-8000",
      "CS055",
      "SCHEMBL5500152",
      "GTPL8305",
      "AKOS026750315",
      "N-(2-Amino-5-Fluorophenyl)-4-{[3-(Pyridin-3-Yl)Prop-2-Enamido]methyl}benzamide",
    ],
  },
  {
    primary_id: "D00SRE",
    names: ["HPMPA Prodrugs"],
  },
  {
    primary_id: "D00SRM",
    names: ["NSC-354279"],
  },
  {
    primary_id: "D00SRS",
    names: [
      "BUDLEIN A",
      "Budlein A Tiglate",
      "CHEMBL189707",
      "BDBM50433422",
      "(E)-2-Methyl-2-Butenoic Acid [(3aR,4R,6R,10Z,11aR)-2,3,3a,4,5,6,7,11a-Octahydro-10-Hydroxymethyl-6-Methyl-3-Methylene-2,7-Dioxo-6,9-Epoxycyclodeca[b]furan]-4-Yl Ester",
    ],
  },
  {
    primary_id: "D00SSW",
    names: [
      "7-Amino-4-Hydroxy-2-Naphthalenesulfonic Acid",
      "1987/2/5",
      "J Acid",
      "7-AMINO-4-HYDROXY-2-NAPHTHALENESULFONIC ACID",
      "7-Amino-4-Hydroxynaphthalene-2-Sulfonic Acid",
      "Isogamma Acid",
      "I Acid",
      "2-Naphthalenesulfonic Acid, 7-Amino-4-Hydroxy-",
      "Kyselina I",
      "2-Amino-5-Naphthol-7-Sulfonic Acid",
      "Kyselina I [Czech]",
      "Aminonaphthol Sulfonic Acid J",
      "6-Amino-1-Naphthol-3-Sulfonic Acid",
      "CCRIS 8989",
      "EINECS 201-718-9",
      "NSC 31510",
      "Kyselina 2-Amino-5-Naftol-7-Sulfonova",
      "BRN 2217192",
      "UNII-9A1IU1C93L",
      "MLS002638012",
      "CHEMBL220259",
      "9A1IU1C93L",
      "CHEBI:87316",
      "7-Amino-4-Hydroxynaphthalene-2-Sulphonic Acid",
      "Kyselina 6",
    ],
  },
  {
    primary_id: "D00SSZ",
    names: ["OBP-801"],
  },
  {
    primary_id: "D00STJ",
    names: ["Enocitabine"],
  },
  {
    primary_id: "D00STL",
    names: ["Nilotinib"],
  },
  {
    primary_id: "D00STZ",
    names: ["Mam-05.200"],
  },
  {
    primary_id: "D00SUK",
    names: [
      "Tonapofylline",
      "Bg 9928",
      "340021-17-2",
      "BG-9928",
      "UNII-83VNU4U44T",
      "BG9928",
      "CHEMBL386974",
      "CHEMBL414157",
      "83VNU4U44T",
      "3-[4-(2,6-Dioxo-1,3-Dipropyl-7H-Purin-8-Yl)-1-Bicyclo[222]octanyl]propanoic Acid",
      "Bicyclo[222]octane-1-Propanoic Acid, 4-(2,3,6,9-Tetrahydro-2,6-Dioxo-1,3-Dipropyl-1h-Purin-8-Yl)-",
      "3-[4-(2,6-Dioxo-1,3-Dipropyl-2,3,6,7-Tetrahydro-1H-Purin-8-Yl)-Bicyclo[222]oct-1-Yl]-Propionic Acid",
      "Bicyclo(222)Octane-1-Propanoic Acid, 4-(2,3,6,7-Tetrahydro-2,6-Dioxo-1,3-Dipropyl-1H-Purin-8-Yl)-",
      "Tonapofylline [USAN:INN]",
    ],
  },
  {
    primary_id: "D00SVK",
    names: ["PMID25684022-Compound-WO2011137219 37(4-6)"],
  },
  {
    primary_id: "D00SVP",
    names: ["PF-4455242"],
  },
  {
    primary_id: "D00SVU",
    names: [
      "Y-25130",
      "Azasetron",
      "Nazasetron",
      "Azasetron [INN]",
      "C17H20ClN3O3",
      "123039-99-6",
      "WUKZPHOXUVCQOR-UHFFFAOYSA-N",
      "Azasetron (INN)",
      "6-Chloro-4-Methyl-3-Oxo-N-(Quinuclidin-3-Yl)-3,4-Dihydro-2H-Benzo[b][1,4]oxazine-8-Carboxamide",
      "NCGC00024563-02",
      "DSSTox_RID_81029",
      "DSSTox_CID_25651",
      "DSSTox_GSID_45651",
      "(- )-6-Chloro-3,4-Dihydro-4-Methyl-3-Oxo-N-3-Quinuclidinyl-2H-1,4-Benzoxazine-8-Carboxamide",
      "N-(1-Azabicyclo[2.2.2]octan-3-Yl)-6-Chloro-4-Methyl-3-Oxo-1,4-Benzoxazine-8-Carboxamide",
    ],
  },
  {
    primary_id: "D00SWN",
    names: ["MS-806"],
  },
  {
    primary_id: "D00SWQ",
    names: ["Mitoglitazone"],
  },
  {
    primary_id: "D00SXO",
    names: [
      "3-Methyl-1-Phenyl-1H-Chromeno[4,3-C]pyrazol-4-One",
      "CHEMBL412610",
      "AC1MDPHC",
      "BDBM50026583",
      "3-Methyl-1-Phenylchromeno[4,3-C]pyrazol-4-One",
    ],
  },
  {
    primary_id: "D00SYV",
    names: ["GSK2618960"],
  },
  {
    primary_id: "D00SZR",
    names: ["PMID27454349-Compound-98"],
  },
  {
    primary_id: "D00TAP",
    names: ["Helivax"],
  },
  {
    primary_id: "D00TBD",
    names: ["PHA-665752"],
  },
  {
    primary_id: "D00TBG",
    names: ["PMID8246233C5ab"],
  },
  {
    primary_id: "D00TBI",
    names: ["Imidazole Derivative 7"],
  },
  {
    primary_id: "D00TBJ",
    names: ["PF-271836"],
  },
  {
    primary_id: "D00TBS",
    names: ["SPIRU-211"],
  },
  {
    primary_id: "D00TCL",
    names: ["Peptide Analog 59"],
  },
  {
    primary_id: "D00TCM",
    names: ["Yellow Fever Vaccine"],
  },
  {
    primary_id: "D00TDJ",
    names: ["1-(2-Amino-Benzothiazol-5-Yl)-2-Ethyl-Isothiourea", "CHEMBL397745"],
  },
  {
    primary_id: "D00TED",
    names: ["JNJ-54728518"],
  },
  {
    primary_id: "D00TEI",
    names: ["PMID22390415C13k"],
  },
  {
    primary_id: "D00TEN",
    names: ["TV-1390"],
  },
  {
    primary_id: "D00TFA",
    names: ["GW7647"],
  },
  {
    primary_id: "D00TFF",
    names: ["PMID28766366-Compound-Scheme2WO2012/098065bottom"],
  },
  {
    primary_id: "D00TGC",
    names: ["N-Me-Aminopyrimidinone 9"],
  },
  {
    primary_id: "D00TGR",
    names: ["Citric Acid, Magnesium Oxide, Sodium Picosulfate"],
  },
  {
    primary_id: "D00THA",
    names: ["QLT-091001", "11-Cis-Retinal Retinoid Replacement Therapy (Oral, Leber's Congenial Amaurosis), QLT"],
  },
  {
    primary_id: "D00THG",
    names: ["DE-105"],
  },
  {
    primary_id: "D00THJ",
    names: ["1-Hydroxyl-3,5-Bis(4-Hydroxylstyryl)Benzene Derivative 3"],
  },
  {
    primary_id: "D00THO",
    names: ["Phosphoethanolamine", "2-Ammonioethyl Phosphate", "O-Phosphonatoethanaminium"],
  },
  {
    primary_id: "D00TIB",
    names: ["PMID27215781-Compound-34"],
  },
  {
    primary_id: "D00TIG",
    names: ["RG3806"],
  },
  {
    primary_id: "D00TIO",
    names: ["PMID1656041C4ff"],
  },
  {
    primary_id: "D00TJF",
    names: [
      "N4-(3-Chlorophenyl)Quinazoline-4,6-Diamine",
      "CHEMBL343352",
      "Anilinoquinazoline, 4c",
      "SCHEMBL1262804",
      "AWAYNEMCHILZCF-UHFFFAOYSA-N",
      "ZINC13527446",
      "BDBM50133382",
      "AKOS013915036",
      "6-Amino-4-(3'-Chloroanilino)Quinazoline",
      "N4-(3-Chlorophenyl)-4,6-Quinazolinediamine",
      "6-Amino-4[(3-Chlorophenyl)Amino]quinazoline",
    ],
  },
  {
    primary_id: "D00TJV",
    names: ["RP-3120"],
  },
  {
    primary_id: "D00TJX",
    names: [
      "Albinterferon Alfa-2b",
      "Albuferon",
      "Joulferon",
      "Zalbin",
      "ABF-656",
      "Albuferon-Alpha",
      "Interferon Alpha-Albumin Fusion Protein, HGS",
      "Albumin-Based Fusion Protein (Viral Disease), Principia",
      "Interferon Alpha-Albumin Fusion Protein, HGS/Novartis",
    ],
  },
  {
    primary_id: "D00TKC",
    names: ["NCP-004"],
  },
  {
    primary_id: "D00TKJ",
    names: ["7-Fluoroindirubin-3-Oxime"],
  },
  {
    primary_id: "D00TKO",
    names: [
      "1-(4-(Methylsulfonyl)Phenyl)-1H-Pyrrole",
      "CHEMBL1240963",
      "1-[4-(Methylsulfonyl)Phenyl]-1H-Pyrrole",
      "ZINC35120168",
      "BDBM50326521",
      "AKOS008961779",
      "1-(4-Methanesulfonylphenyl)-1H-Pyrrole",
    ],
  },
  {
    primary_id: "D00TLD",
    names: [
      "RIG-200",
      "1,3,4,6-Tetra-O-Acetyl-2-[2(S)-(Acetylamino)-3-Methyl-3-(Nitrososulfanyl)Butyramido]-2-Deoxy-Beta-D-Glucopyranose",
    ],
  },
  {
    primary_id: "D00TLM",
    names: ["PRUVANSERIN"],
  },
  {
    primary_id: "D00TLN",
    names: [
      "Cyproheptadine",
      "Ciproheptadina",
      "Ciprovit",
      "Cypoheptadine",
      "Cyproheptadiene",
      "Cyproheptadinum",
      "Dihexazin",
      "Dronactin",
      "Eiproheptadine",
      "Periactin",
      "Periactine",
      "Periactinol",
      "Viternum",
      "Cyproheptadine Hcl",
      "MK 141",
      "Ciproheptadina [INN-Spanish]",
      "Ciprovit (TN)",
      "Cyproheptadine (INN)",
      "Cyproheptadine [INN:BAN]",
      "Cyproheptadinum [INN-Latin]",
      "Dibenzosuberonone/Cyproheptadine",
      "Periactin (TN)",
      "1-Methyl-4-(5-Dibenzo(A,e)Cycloheptatrienylidene)Piperidine",
      "1-Methyl-4-(5H-Dibenzo(A,d)Cycloheptenylidene)Piperidine",
      "4-(5-Dibenzo(A,d)Cyclohepten-5-Ylidine)-1-Methylpiperidine",
      "4-(5H-Dibenzo(A,d)Cyclohepten-5-Ylidene)-1-Methylpiperidene",
      "4-(5H-Dibenzo(A,d)Cyclohepten-5-Ylidene)-1-Methylpiperidine",
      "4-(5H-Dibenzo[a,d]cyclohepten-5-Ylidene)-1-Methylpiperidine",
      "4-(5H-Dibenzo[a,d][7]annulen-5-Ylidene)-1-Methylpiperidine",
      "5-(1-Methylpiperidylidene-4)-5H-Dibenzo(A,d)Cyclopheptene",
    ],
  },
  {
    primary_id: "D00TLP",
    names: ["Simeprevir"],
  },
  {
    primary_id: "D00TMK",
    names: ["Dengvaxia"],
  },
  {
    primary_id: "D00TMQ",
    names: ["Azetidine-1-Carboxamide Derivative 3"],
  },
  {
    primary_id: "D00TNC",
    names: ["S-8510"],
  },
  {
    primary_id: "D00TNN",
    names: ["(R/S)-2-((4-Phenoxyphenoxy)Methyl)Piperidine", "CHEMBL1097151", "SCHEMBL13809108", "BDBM50317492"],
  },
  {
    primary_id: "D00TNR",
    names: ["ENDOMORPHIN 2"],
  },
  {
    primary_id: "D00TNZ",
    names: ["Enadenotucirev"],
  },
  {
    primary_id: "D00TOF",
    names: [
      "DIHYDROCUBEBIN",
      "Dihydrocubebin",
      "(-)-Dihydrocubebin",
      "24563-03-9",
      "(2R,3R)-2,3-Bis(1,3-Benzodioxol-5-Ylmethyl)Butane-1,4-Diol",
      "CHEMBL486597",
      "CHEBI:543841",
      "1,4-Butanediol, 2,3-Bis(1,3-Benzodioxol-5-Ylmethyl)-, (R-(R*,R*))-",
      "1,4-Butanediol, 2,3-Bis(1,3-Benzodioxol-5-Ylmethyl)-, [R-(R*,R*)]-",
      "Cubebin, Dihydro-",
      "AC1Q6ZWV",
      "AC1L4VHK",
      "SCHEMBL15775181",
      "CTK4F3896",
      "DTXSID00179301",
      "JKCVMTYNARDGET-HOTGVXAUSA-N",
      "ZINC899938",
      "BDBM50241937",
      "1,4-Butanediol, 2,3-Bis(1,3-Benzodioxol-5-Ylmethyl)-, (R*,R*)-(-)-",
      "1,4-Butanediol, 2,3-Dipiperonyl-, (-)-",
      "C10558",
    ],
  },
  {
    primary_id: "D00TPC",
    names: ["MAT-401"],
  },
  {
    primary_id: "D00TPG",
    names: [
      "5-Benzyl-1H-Pyrazole-3-Carboxylic Acid",
      "1H-Pyrazole-3-Carboxylic Acid, 5-(Phenylmethyl)-",
      "CHEMBL247317",
      "595610-51-8",
      "3-Benzyl-1H-Pyrazole-5-Carboxylic Acid",
      "SCHEMBL12703568",
      "MolPort-035-831-532",
      "MolPort-022-664-582",
      "ZINC13526594",
      "BDBM50216537",
      "AKOS026575568",
    ],
  },
  {
    primary_id: "D00TPI",
    names: ["Nalidixic Acid + Ampicillin"],
  },
  {
    primary_id: "D00TPO",
    names: ["JWH-201"],
  },
  {
    primary_id: "D00TPQ",
    names: ["VT-384"],
  },
  {
    primary_id: "D00TPV",
    names: [
      "Arg-Arg-Pro-Tyr-Ile-Leu-OH",
      "CHEMBL415788",
      "60482-95-3",
      "NT(8-13)",
      "AC1NSKF5",
      "HY-P0251",
      "ZINC85577030",
      "MFCD00076686",
      "BDBM50048908",
      "CS-7110",
      "L-Leucine,L-Arginyl-L-Arginyl-L-Prolyl-L-Tyrosyl-L-Isoleucyl-",
      "(2S)-2-[[(2S,3S)-2-[[(2S)-2-[[(2S)-1-[(2S)-2-[[(2S)-2-Amino-5-(Diaminomethylideneamino)Pentanoyl]amino]-5-(Diaminomethylideneamino)Pentanoyl]pyrrolidine-2-Carbonyl]amino]-3-(4-Hydroxyphenyl)Propanoyl]amino]-3-Methylpentanoyl]amino]-4-Methylpentanoic Acid",
    ],
  },
  {
    primary_id: "D00TPW",
    names: [
      "FST-201",
      "Dexamethasone + Povidone Iodine (Otitis), Foresight Biotherapeutics",
      "Otic Anti-Infective (Otitis), Foresight Biotherapeutics",
    ],
  },
  {
    primary_id: "D00TQI",
    names: ["ISIS 32014"],
  },
  {
    primary_id: "D00TQN",
    names: ["JTK-003"],
  },
  {
    primary_id: "D00TQR",
    names: ["1-Methyl-3-Propyl-3,7-Dihydro-Purine-2,6-Dione"],
  },
  {
    primary_id: "D00TRF",
    names: ["2-(Benzyloxyamino)-N-Hydroxyacetamide", "CHEMBL226149"],
  },
  {
    primary_id: "D00TRL",
    names: ["REV-598"],
  },
  {
    primary_id: "D00TRO",
    names: ["[3H]NBI-49202", "CHEMBL540207", "SCHEMBL5758037", "BDBM50162021"],
  },
  {
    primary_id: "D00TRV",
    names: ["Besifloxacin", "Besivance (TN)"],
  },
  {
    primary_id: "D00TRY",
    names: ["Tert-Butyl(1s)-1-Cyclohexyl-2-Oxoethylcarbamate"],
  },
  {
    primary_id: "D00TRZ",
    names: ["JWH-407"],
  },
  {
    primary_id: "D00TSX",
    names: ["C[Nle-His-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL425591", "BDBM50184356"],
  },
  {
    primary_id: "D00TSY",
    names: ["[D-Dap(CO-NH-OCH3)3]degarelix", "CHEMBL411381"],
  },
  {
    primary_id: "D00TTS",
    names: ["Human Chorionic Gonadotropin"],
  },
  {
    primary_id: "D00TUB",
    names: ["2-(4-Amino-Phenyl)-8-Hydroxy-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D00TUH",
    names: ["8-(4-Acetylphenyl)-2-Morpholin-4-Ylchromen-4-One", "CHEMBL197218"],
  },
  {
    primary_id: "D00TUP",
    names: ["Vitreosolve"],
  },
  {
    primary_id: "D00TUR",
    names: ["LY2495655"],
  },
  {
    primary_id: "D00TVG",
    names: ["Piperazine Carbamic Compound 4"],
  },
  {
    primary_id: "D00TVI",
    names: ["SR-58894A"],
  },
  {
    primary_id: "D00TVL",
    names: ["PMID28454500-Compound-57"],
  },
  {
    primary_id: "D00TVY",
    names: ["Glu-Pro-Gln-F2Pmp-Glu-Glu-Ile-Pro-Ile-Tyr-Leu", "CHEMBL413629"],
  },
  {
    primary_id: "D00TWA",
    names: ["[2,2':5',2'']Terthiophen-4-Yl-Methanol", "CHEMBL76100", "BDBM50217032"],
  },
  {
    primary_id: "D00TWC",
    names: [
      "4-(2,4-Dichloro-Phenoxy)-2'-Methyl-Biphenyl-3-Ol",
      "4-(2,4-Dichlorophenoxy)-2'-Methylbiphenyl-3-Ol",
      "Triclosan Derivative, 15",
      "CHEMBL261708",
      "BDBM25414",
      "2-(2,4-Dichlorophenoxy)-5-(2-Methylphenyl)Phenol",
    ],
  },
  {
    primary_id: "D00TWK",
    names: [
      "5-[2-(4-Hydroxyphenyl)Ethyl]benzene-1,3-Diol",
      "Dihydroresveratrol",
      "58436-28-5",
      "3,4',5-Trihydroxybibenzyl",
      "CHEMBL111234",
      "CHEBI:4582",
      "5-(4-Hydroxyphenethyl)Benzene-1,3-Diol",
      "Dihydroresveratol",
      "3ftu",
      "Dihydro-Resveratrol",
      "RE2",
      "A, B-Dihydroresveratrol",
      "AC1L4HRG",
      "C10255",
      "5-[2-(4-Hydroxyphenyl)Ethyl]-1,3-Benzenediol",
      "3,5,4'-Trihydroxybibenzyl",
      "3,4'',5-Trihydroxybibenzyl",
      "1,3-Benzenediol, 5-(2-(4-Hydroxyphenyl)Ethyl)-",
      "SCHEMBL716856",
      "AC1Q79W7",
      "CTK5A8302",
      "MolPort-035-706-156",
      "ZINC899123",
      "LMPK13090035",
      "BDBM50085531",
      "NSC723534",
      "AKOS030555676",
    ],
  },
  {
    primary_id: "D00TWV",
    names: ["[125I]EXP985", "[125I]-EXP985"],
  },
  {
    primary_id: "D00TXL",
    names: [
      "AAV2-HFIX16",
      "AAV-F.IX, University Of Pennsylvania",
      "AAV-Factor IX Gene Therapy (Hemophilia B), University Of Pennsylvania",
    ],
  },
  {
    primary_id: "D00TXZ",
    names: ["4-Aryl Quinol Derivative 2"],
  },
  {
    primary_id: "D00TYI",
    names: ["TG-3030"],
  },
  {
    primary_id: "D00TYP",
    names: ["KIN-4064", "Aurora B Kinase Inhibitor (Cancer), Kinentia"],
  },
  {
    primary_id: "D00TYR",
    names: ["Anti-Tubulin Agents"],
  },
  {
    primary_id: "D00TYW",
    names: ["6-Heptyl-4-Hydroxy-3-Octanoyl-Pyran-2-One", "CHEMBL269138", "SCHEMBL10337594", "BDBM50024653"],
  },
  {
    primary_id: "D00TYX",
    names: ["[3H]sumatriptan", "GTPL119"],
  },
  {
    primary_id: "D00TZD",
    names: [
      "CL-387785",
      "Cl-387785",
      "194423-06-8",
      "EKI-785",
      "CL-387785 (EKI-785)",
      "CL-387,785",
      "UNII-B4W27J1Z8B",
      "CL-387785(EKI785",
      "WAY-EKI 785)",
      "EKB-785",
      "CHEMBL91867",
      "B4W27J1Z8B",
      "N-[4-[(3-Bromophenyl)Amino]-6-Quinazolinyl]-2-Butynamide",
      "N-(4-((3-Bromophenyl)Amino)Quinazolin-6-Yl)But-2-Ynamide",
      "CHEBI:90180",
      "C18H13BrN4O",
      "N-{4-[(3-Bromophenyl)Amino]quinazolin-6-Yl}but-2-Ynamide",
      "N-[4-[(3-Bromophenyl)Amino]quinazolin-6-Yl]but-2-Ynamide",
      "N-[4-[(3-Bromophenyl)Amino]-6-Quinazolinyl)-2-Butynamide",
      "N-{4-[(3-Bromophenyl)Amino]-6-Quinazolinyl}-2-Butynamide",
    ],
  },
  {
    primary_id: "D00UAE",
    names: ["Bavituximab"],
  },
  {
    primary_id: "D00UBK",
    names: ["L-159689"],
  },
  {
    primary_id: "D00UBR",
    names: ["Cyclohexylmethylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL485685"],
  },
  {
    primary_id: "D00UBX",
    names: ["TNX-355"],
  },
  {
    primary_id: "D00UCC",
    names: ["ABT-518"],
  },
  {
    primary_id: "D00UCE",
    names: ["S 0957"],
  },
  {
    primary_id: "D00UCP",
    names: [
      "N-Phenethyl-2-(Toluene-4-Sulfonylamino)-Benzamide",
      "CHEMBL314328",
      "AC1OUGMN",
      "Oprea1_191889",
      "MolPort-003-205-548",
      "ZINC5882219",
      "STK825561",
      "BDBM50146384",
      "AKOS000952939",
      "MCULE-7382886996",
      "2-[(4-Methylphenyl)Sulfonylamino]-N-Phenethylbenzamide",
      "2-{[(4-Methylphenyl)Sulfonyl]amino}-N~1~-Phenethylbenzamide",
    ],
  },
  {
    primary_id: "D00UCW",
    names: ["CEREBROCRAST"],
  },
  {
    primary_id: "D00UCX",
    names: ["HAEGTFTSDVSSYLEGQAAKEIFAWLVKGR"],
  },
  {
    primary_id: "D00UDD",
    names: ["ISIS 100913"],
  },
  {
    primary_id: "D00UDJ",
    names: ["Indole-Based Analog 5"],
  },
  {
    primary_id: "D00UDL",
    names: ["ISIS 23452"],
  },
  {
    primary_id: "D00UDP",
    names: ["Terameprocol"],
  },
  {
    primary_id: "D00UDX",
    names: [
      "1-Guanidinium-7-Aminoheptane",
      "1-GUANIDINIUM-7-AMINOHEPTANE",
      "AC1NRBDQ",
      "N-(7-Aminoheptyl)Methanetriamine",
      'N"-(7-Aminoheptyl)Methanetriamine',
      "GC7",
    ],
  },
  {
    primary_id: "D00UEC",
    names: ["ASP3662"],
  },
  {
    primary_id: "D00UEG",
    names: ["LY288513"],
  },
  {
    primary_id: "D00UEQ",
    names: ["Etomoxir"],
  },
  {
    primary_id: "D00UET",
    names: ["SAR-407899"],
  },
  {
    primary_id: "D00UFJ",
    names: [
      "4-Chloro-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "4-Chloro-N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "CHEMBL62078",
      "103966-00-3",
      "(Z)-4-Chloro-N-(4-Phenylthiazol-2(3H)-Ylidene)Benzamide",
      "AC1LF4A9",
      "Oprea1_546006",
      "SCHEMBL17107148",
      "MolPort-000-422-245",
      "MolPort-003-009-218",
      "CCG-1056",
      "ZINC4693069",
      "STK514420",
      "BDBM50101787",
      "AKOS024573977",
      "AKOS000620846",
      "MCULE-1729549249",
      "BAS 00444248",
      "ST45229639",
      "N-(4-Phenyl-2-Thiazolyl)-4-Chlorobenzamide",
      "AH-034/06884044",
      "SR-01000394981",
      "SR-01000394981-1",
      "F0013-0438",
    ],
  },
  {
    primary_id: "D00UFR",
    names: [
      "Aconitate Ion",
      "(E)-Prop-1-Ene-1,2,3-Tricarboxylate",
      "(1E)-Prop-1-Ene-1,2,3-Tricarboxylate",
      "Trans-Aconitate(3-)",
      "AC1NRDE4",
    ],
  },
  {
    primary_id: "D00UFZ",
    names: ["AMG 745"],
  },
  {
    primary_id: "D00UGC",
    names: ["HSC660"],
  },
  {
    primary_id: "D00UGX",
    names: ["Cenplacel-L"],
  },
  {
    primary_id: "D00UHK",
    names: ["KI-0905"],
  },
  {
    primary_id: "D00UIK",
    names: ["4-(Benzylamino)-1-Naphthol", "CHEMBL569096", "SCHEMBL6677663"],
  },
  {
    primary_id: "D00UIN",
    names: [
      "JNJ-10392980",
      "841200-46-2",
      "Benzoxazole,2-[4-[2-(1-Piperidinyl)Ethoxy]phenoxy]-",
      "Benzoxazole Compound, 10b",
      "CHEMBL481863",
      "SCHEMBL2170316",
      "BDBM24203",
      "ZIQGASWXUSSORV-UHFFFAOYSA-N",
      "ZINC36377694",
      "KB-306080",
      "2-[4-(2-Piperidin-1-Yl-Ethoxy)-Phenoxy]-Benzooxazole",
    ],
  },
  {
    primary_id: "D00UJF",
    names: ["Dextran"],
  },
  {
    primary_id: "D00UKA",
    names: ["Boc-D-Glu-Tyr(SO3H)-Nle-D-Nle-Trp-Nle-Asp-Phe-NH2", "CHEMBL216831"],
  },
  {
    primary_id: "D00ULT",
    names: [
      "1,1,1-Trifluoro-Undecan-2-One",
      "CHEMBL90258",
      "1,1,1-Trifluoroundecan-2-One",
      "1,1,1-Trifluoro-2-Undecanone",
      "BDBM50073985",
      "AKOS012260318",
      "3-Octyl-1,1,1-Trifluoro-2-Propanone",
      "3-Octyl-1,1,1-Trifluoropropan-2-One",
    ],
  },
  {
    primary_id: "D00ULY",
    names: ["KAI-9803/ BMS-875944"],
  },
  {
    primary_id: "D00UMG",
    names: ["ANALOGUE A", "CHEMBL410852"],
  },
  {
    primary_id: "D00UMJ",
    names: ["NM-007-LENK", "Brain-Targeted Peptides (Pain), Nanomerics"],
  },
  {
    primary_id: "D00UNK",
    names: ["Coltuximab Ravtansine"],
  },
  {
    primary_id: "D00UOI",
    names: ["PMID17574412C33"],
  },
  {
    primary_id: "D00UOQ",
    names: ["PAT-SC1"],
  },
  {
    primary_id: "D00UOR",
    names: ["Desirudin"],
  },
  {
    primary_id: "D00UOZ",
    names: ["MEDI-491"],
  },
  {
    primary_id: "D00UPU",
    names: ["RPR-107836"],
  },
  {
    primary_id: "D00UQF",
    names: ["GW4064"],
  },
  {
    primary_id: "D00UQW",
    names: ["RG7741"],
  },
  {
    primary_id: "D00URP",
    names: ["4,10-Dimethyl-6H-Benzo[c]chromene-3,8-Diol", "CHEMBL206582", "SCHEMBL6924719"],
  },
  {
    primary_id: "D00URZ",
    names: ["LG-740"],
  },
  {
    primary_id: "D00USF",
    names: [
      "Trazodone",
      "Beneficat",
      "Desirel",
      "Sideril",
      "Tradozone",
      "Trazalon",
      "Trazodil",
      "Trazodon",
      "Trazodona",
      "Trazodonum",
      "Trazolan",
      "Trazonil",
      "Trialodine",
      "Trittico",
      "Desyrel Dividose",
      "Trazodona [Spanish]",
      "Trazodone Hcl",
      "Trazodonum [Latin]",
      "Beneficat (TN)",
      "Deprax (TN)",
      "Desirel (TN)",
      "Desyrel (TN)",
      "Desyrel(Trazodone Hcl Salt)",
      "Molipaxin (TN)",
      "Thombran (TN)",
      "Trazodona [INN-Spanish]",
      "Trazodone (Desyrel)",
      "Trazodone (INN)",
      "Trazodone [INN:BAN]",
      "Trazodonum [INN-Latin]",
      "Trazorel (TN)",
      "Trialodine (TN)",
      "Trittico (TN)",
      "2-(3-[4-(3-Chlorophenyl)-1-Piperazinyl]propyl)[1,2,4]triazolo[4,3-A]pyridin-3(2H)-One",
      "2-[3-[4-(3-Chlorophenyl)Piperazin-1-Yl]propyl]-[1,2,4]triazolo[4,3-A]pyridin-3-One",
      "2-{3-[4-(3-Chlorophenyl)Piperazin-1-Yl]propyl}[1,2,4]triazolo[4,3-A]pyridin-3(2H)-One",
      "8-[3-[4-(3-Chlorophenyl)Piperazin-1-Yl]propyl]-1,7,8-Triazabicyclo[4.3.0]nona-2,4,6-Trien-9-One Hydrochloride",
    ],
  },
  {
    primary_id: "D00USS",
    names: ["Cholera Vaccine"],
  },
  {
    primary_id: "D00USX",
    names: ["CEFMATILEN HYDROCHLORIDE HYDRATE"],
  },
  {
    primary_id: "D00UTD",
    names: ["Nedaplatin"],
  },
  {
    primary_id: "D00UTF",
    names: ["NS5818"],
  },
  {
    primary_id: "D00UTP",
    names: ["Estrogen Platinum(II) Hybrid Derivative"],
  },
  {
    primary_id: "D00UTU",
    names: ["7-Bromoindirubin-3-Acetoxime"],
  },
  {
    primary_id: "D00UUG",
    names: ["10-Formyl-5,8,10-Trideazafolic Acid", "10-Formyl-5,8,10-Trideazafolic"],
  },
  {
    primary_id: "D00UUL",
    names: ["N8-GP"],
  },
  {
    primary_id: "D00UUM",
    names: [
      "PMEA",
      "ADEFOVIR",
      "106941-25-7",
      "((2-(6-Amino-9H-Purin-9-Yl)Ethoxy)Methyl)Phosphonic Acid",
      "9-(2-Phosphonylmethoxyethyl)Adenine",
      "GS-0393",
      "GS 0393",
      "GS 393",
      "9-(2-(Phosphonomethoxy)Ethyl)Adenine",
      "N-(2-Phosphonylmethoxyethyl)Adenine",
      "DRG-0156",
      "UNII-6GQP90I798",
      "C8H12N5O4P",
      "HSDB 8079",
      "CHEMBL484",
      "2-(6-Aminopurin-9-Yl)Ethoxymethylphosphonic Acid",
      "BRN 3561094",
      "{[2-(6-Amino-9h-Purin-9-Yl)Ethoxy]methyl}phosphonic Acid",
      "N-(2-Phophonomethoxyethyl-2,6-Diaminopurine)",
      "Phosphonic Acid, [[2-(6-Amino-9H-Purin-9-Yl)Ethoxy]methyl]-",
      "6GQP90I798",
    ],
  },
  {
    primary_id: "D00UVA",
    names: [
      "Carfilzomib",
      "868540-17-4",
      "Kyprolis",
      "Carfilzomib (PR-171)",
      "PR-171",
      "UNII-72X6E3J5AR",
      "72X6E3J5AR",
      "CHEMBL451887",
      "CHEBI:65347",
      "NCGC00249613-01",
      "DSSTox_RID_82886",
      "DSSTox_CID_28616",
      "DSSTox_GSID_48690",
      "(S)-4-Methyl-N-((S)-1-(((S)-4-Methyl-1-((R)-2-Methyloxiran-2-Yl)-1-Oxopentan-2-Yl)Amino)-1-Oxo-3-Phenylpropan-2-Yl)-2-((S)-2-(2-Morpholinoacetamido)-4-Phenylbutanamido)Pentanamide",
      "N-{(2S)-2-[(Morpholin-4-Ylacetyl)Amino]-4-Phenylbutanoyl}-L-Leucyl-N-{(2S)-4-Methyl-1-[(2R)-2-Methyloxiran-2-Yl]-1-Oxopentan-2-Yl}-L-Phenylalaninamide",
    ],
  },
  {
    primary_id: "D00UVU",
    names: ["Lipoteichoic Acid"],
  },
  {
    primary_id: "D00UWL",
    names: ["Sar-Arg-Val-Tyr-Ile-His-Pro-Ile"],
  },
  {
    primary_id: "D00UWY",
    names: ["RT-001"],
  },
  {
    primary_id: "D00UXD",
    names: ["Inhaled Alprazolam"],
  },
  {
    primary_id: "D00UXT",
    names: ["Acetrizoate Sodium"],
  },
  {
    primary_id: "D00UYB",
    names: [
      "Anti-DLL-4 Mab",
      "Anti-DLL-4 MAb (Cancer)",
      "Anti-DLL-4 MAb (Cancer), MedImmune",
      "Delta-Like Ligand 4-Targeted Monoclonal Antibody (Cancer), MedImmune",
    ],
  },
  {
    primary_id: "D00UYE",
    names: [
      "Meperidine",
      "Demarol",
      "Demerol",
      "Dolcontral",
      "Dolosal",
      "Dolsin",
      "Isonipecain",
      "Isonipecaine",
      "Lidol",
      "Lydol",
      "Meperidol",
      "Nemerol",
      "Pethanol",
      "Pethidin",
      "Pethidine",
      "Pethidineter",
      "Pethidinum",
      "Petidina",
      "Petydyna",
      "Phetidine",
      "Piperosal",
      "Pipersal",
      "Meperidine Solution",
      "Methyl Phenylpiperidine Carbonic Acid Ethyl Ester",
      "Operidine EPJ I",
      "Pethidine DBL",
      "Petydyna [Polish]",
      "Demerol (TN)",
      "Operidine EPJ-I",
      "Pethidine (INN)",
      "Pethidine DBL (TN)",
      "Pethidinum [INN-Latin]",
      "Petidina [INN-Spanish]",
      "Ethyl 1-Methyl-4-Phenylisonipecotate",
      "Ethyl 1-Methyl-4-Phenylpiperidine-4-Carboxylate",
      "N-Methyl-4-Phenyl-4-Carbethoxypiperidine",
      "Isonipecotic Acid, 1-Methyl-4-Phenyl-, Ethyl Ester",
      "YIsonipecotic Acid, 1-Methyl-4-Phenyl-, Ethyl Ester (8CI)",
      "1-Methyl-4-Phenyl-4-Piperidinecarboxylic Acid Ethyl Ester",
      "1-Methyl-4-Phenyl-Piperidin-4-Carbon-Saeure-Aethylester",
      "1-Methyl-4-Phenyl-Piperidin-4-Carbon-Saeure-Aethylester [German]",
      "1-Methyl-4-Phenylisonipecotic Acid, Ethyl Ester;1-Methyl-4-Phenylpiperidine-4-Carboxylic Acid Ethyl Ester",
      "4-Carbethoxy-1-Methyl-4-Phenylpiperidine",
      "4-Piperidinecarboxylic Acid, 1-Methyl-4-Phenyl-, Ethyl Ester",
    ],
  },
  {
    primary_id: "D00UYR",
    names: ["IMC-1"],
  },
  {
    primary_id: "D00UZO",
    names: ["O-Arachidonoyl Ethanolamine"],
  },
  {
    primary_id: "D00UZR",
    names: ["PD-332991"],
  },
  {
    primary_id: "D00VAJ",
    names: ["ORBOFIBAN"],
  },
  {
    primary_id: "D00VAN",
    names: ["[(3-Bromophenyl)-M-Tolyl-Ketone]thiosemicarbazone", "CHEMBL602092"],
  },
  {
    primary_id: "D00VBP",
    names: ["PMID29053063-Compound-7f"],
  },
  {
    primary_id: "D00VBT",
    names: ["ZK 304709"],
  },
  {
    primary_id: "D00VBW",
    names: [
      "11-Cis-Retinol",
      "11-Cis Retinol",
      "22737-96-8",
      "11Z-Retinol",
      "11-Cis-Vitamin A Alcohol",
      "AC1NQWYZ",
      "GTPL6670",
      "CHEBI:16302",
      "FPIPGXGPPPQFEQ-IOUUIBBYSA-N",
      "ZINC12496764",
      "LMPR01090005",
      "C00899",
      "(2E,4Z,6E,8E)-3,7-Dimethyl-9-(2,6,6-Trimethylcyclohex-1-En-1-Yl)Nona-2,4,6,8-Tetraen-1-Ol",
      "(2E,4Z,6E,8E)-3,7-Dimethyl-9-(2,6,6-Trimethylcyclohexen-1-Yl)Nona-2,4,6,8-Tetraen-1-Ol",
    ],
  },
  {
    primary_id: "D00VBX",
    names: ["2-(P-Methylsulfonylbenzoyl)Furan", "CHEMBL1240887", "SCHEMBL7814046", "BDBM50326535"],
  },
  {
    primary_id: "D00VCH",
    names: ["F-98214"],
  },
  {
    primary_id: "D00VCW",
    names: [
      "ACEA",
      "Arachidonyl-2-Chloroethylamide",
      "Arachidonyl-2'-Chloroethylamide",
      "CHEMBL151167",
      "(5Z,8Z,11Z,14Z)-N-(2-Chloroethyl)Icosa-5,8,11,14-Tetraenamide",
      "C22H36ClNO",
      "Arachidonoyl-2-Chloroethylamide",
      "AC1NSJTE",
      "GTPL738",
      "SCHEMBL1517676",
      "MolPort-003-940-362",
      "HMS3649N11",
      "MFCD02683581",
      "BN0049",
      "BDBM50072769",
      "ZINC13675373",
      "1595AH",
      "AKOS024456526",
      "Arachidonyl-2'-Chloroethylamide Hydrate",
      "NCGC00162406-01",
      "LS-63778",
      "ACM273734079",
      "SR-01000946682",
      "L000112",
      "SR-01000946682-1",
      "N-(2-Chloroethyl)-5Z,8Z,11Z,14Z-Eicosatetraenamide",
    ],
  },
  {
    primary_id: "D00VCZ",
    names: ["HGT-2610"],
  },
  {
    primary_id: "D00VEM",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 25"],
  },
  {
    primary_id: "D00VEZ",
    names: ["2,4-Epi-Neodysiherbaine", "2,4-Epi-NeoDH"],
  },
  {
    primary_id: "D00VFE",
    names: ["Saralasin Acetate"],
  },
  {
    primary_id: "D00VFH",
    names: ["PXS-25"],
  },
  {
    primary_id: "D00VFM",
    names: ["RWJ-49968"],
  },
  {
    primary_id: "D00VGP",
    names: ["XGP-215"],
  },
  {
    primary_id: "D00VHG",
    names: ["Inhibitor Idd 384"],
  },
  {
    primary_id: "D00VHN",
    names: ["3-(N-Alkylamino) Propylphosphonic Acid Derivative", "CHEMBL1161691"],
  },
  {
    primary_id: "D00VHS",
    names: ["4-(5-(4-Hydroxyphenyl)Isothiazol-4-Yl)Phenol"],
  },
  {
    primary_id: "D00VHW",
    names: ["4-Benzyl-1-Chroman-3-Ylmethyl-Piperidine", "CHEMBL179373"],
  },
  {
    primary_id: "D00VIT",
    names: ["GM-CSF/B7-2 Gene Therapy And Vaccine (CIT, Cancer)"],
  },
  {
    primary_id: "D00VJC",
    names: ["Peg. Adrenomedullin"],
  },
  {
    primary_id: "D00VJY",
    names: [
      "Octanol",
      "1-Octanol",
      "Octan-1-Ol",
      "111-87-5",
      "N-Octanol",
      "Capryl Alcohol",
      "Octyl Alcohol",
      "Caprylic Alcohol",
      "N-Octyl Alcohol",
      "Heptyl Carbinol",
      "1-Hydroxyoctane",
      "Primary Octyl Alcohol",
      "Alcohol C-8",
      "N-Octan-1-Ol",
      "Octilin",
      "Sipol L8",
      "Alfol 8",
      "Lorol 20",
      "Dytol M-83",
      "N-Caprylic Alcohol",
      "1-Octyl Alcohol",
      "N-Heptyl Carbinol",
      "EPAL 8",
      "Octyl-Alcohol",
      "Alcohols, C6-12",
      "N-Octyl-Alcohol",
      "Octyl Alcohol, Normal-Primary",
      "C8 Alcohol",
      "Fatty Alcohol(C8)",
      "Octyl Alcohol, Primary",
      "Caswell No. 611A",
      "Octyl Alcohol (Natural)",
      "FEMA Number 2800",
      "Lorol C 8-98",
      "N-Capryl Alcohol",
    ],
  },
  {
    primary_id: "D00VKB",
    names: ["3-(Decylsulfinyl)-1,1,1-Trifluoropropan-2-One", "CHEMBL269931"],
  },
  {
    primary_id: "D00VKK",
    names: ["VPI-2690B"],
  },
  {
    primary_id: "D00VKV",
    names: ["Tenocyclidine"],
  },
  {
    primary_id: "D00VLF",
    names: ["HCV/MF59"],
  },
  {
    primary_id: "D00VLQ",
    names: ["Hydroxyqunoline Analog 1"],
  },
  {
    primary_id: "D00VMA",
    names: ["Vatreptacog Alfa (Activated)"],
  },
  {
    primary_id: "D00VMO",
    names: ["PMID1992149C13"],
  },
  {
    primary_id: "D00VMX",
    names: ["PMID30247903-Compound-General Structure26"],
  },
  {
    primary_id: "D00VMY",
    names: ["P-3378"],
  },
  {
    primary_id: "D00VNC",
    names: ["3beta-Hydroxyolean-12-En-27-Oic Acid", "CHEMBL210970"],
  },
  {
    primary_id: "D00VNH",
    names: ["ZK-200775"],
  },
  {
    primary_id: "D00VOE",
    names: ["Myoblast Cell Transplantation"],
  },
  {
    primary_id: "D00VOF",
    names: ["TNF-PEG-20"],
  },
  {
    primary_id: "D00VOK",
    names: ["PMID30247903-Compound-General Structure37"],
  },
  {
    primary_id: "D00VPT",
    names: ["SB222200"],
  },
  {
    primary_id: "D00VPU",
    names: ["SLV-323"],
  },
  {
    primary_id: "D00VPV",
    names: ["TAK-013"],
  },
  {
    primary_id: "D00VQI",
    names: [
      "SQ-30741",
      "[1S-(1beta,2alpha(5Z),3alpha,4beta)]-7-[3-[[[[(1-Oxoheptyl)Amino]acetyl]amino]methyl]-7-Oxabicyclo[2.2.1]hept-2-Yl]-5-Heptenoic Acid",
    ],
  },
  {
    primary_id: "D00VQT",
    names: [
      "99mTc-EC-DG",
      "EC-DG-[99mTc]",
      "Technetium-99m-EC-DG",
      "Technetium-99m-EC-G",
      "Technetium-99m-EC-Deoxyglucose",
      "Technetium-99m-Ethylenedicysteine-Glucosamine",
      "99mTc-EC-G",
      "99mTc-EC-Deoxyglucose",
      "99mTc-Ethylenedicysteine-Glucosamine",
      "99mTechnetium-EthylenediCysteine-N-Acetyl-Glucosamine",
    ],
  },
  {
    primary_id: "D00VQZ",
    names: [
      "1-Hydroxy-3-(4-Nitrophenyl)Urea",
      "CHEMBL259979",
      "53731-88-7",
      "NSC163567",
      "AC1NQIZ7",
      "SCHEMBL16005844",
      "CTK1H3407",
      "DTXSID10411168",
      "BDBM50377113",
    ],
  },
  {
    primary_id: "D00VRV",
    names: ["2-Bromo-LSD", "2-Bromolysergic Acid Diethylamide", "2-Br-LSD", "Bromolysergide"],
  },
  {
    primary_id: "D00VSQ",
    names: ["E-1455", "E-1043", "Clozapine Prodrug (Oral, ECLYPS), ProCor Pharmaceuticals"],
  },
  {
    primary_id: "D00VSX",
    names: ["PMID25399762-Compound-Table1-C10"],
  },
  {
    primary_id: "D00VUG",
    names: ["FF21101"],
  },
  {
    primary_id: "D00VUI",
    names: ["Psoralen"],
  },
  {
    primary_id: "D00VUL",
    names: ["Phenazopyridine"],
  },
  {
    primary_id: "D00VUN",
    names: [
      "TRX-818",
      "CVM-1118",
      "ZRPWVAHVWBPHID-UHFFFAOYSA-L",
      "TRX-818 Sodium",
      "TRX818",
      "EX-A580",
      "MolPort-044-567-575",
      "CVM-1118(CVM1118)",
      "B5932",
      "J-690105",
      "(2'S,3R,4'S,5'R)-6-Chloro-4'-(3-Chloro-2-Fluorophenyl)-N-((1r,4R)-4-Hydroxycyclohexyl)-2'-Neopentyl-2-Oxospiro[indoline-3,3'-Pyrroli Dine]-5'-Carboxamide",
    ],
  },
  {
    primary_id: "D00VUO",
    names: [
      "3-[6-Methoxy-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 15a",
      "CHEMBL366772",
      "BDBM8601",
      "AC1O7055",
      "3-[(E)-(6-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D00VUT",
    names: ["D-257C", "CHEMBL408950"],
  },
  {
    primary_id: "D00VVB",
    names: ["Propan-2-One O-4-(Heptyloxy)Phenylcarbamoyl Oxime", "CHEMBL597294"],
  },
  {
    primary_id: "D00VVU",
    names: ["AE-H Vaccine"],
  },
  {
    primary_id: "D00VWN",
    names: ["Citral"],
  },
  {
    primary_id: "D00VWR",
    names: [
      "3,3-Diethyl-Dihydro-Furan-2-One",
      "2(3H)-Furanone, 3,3-Diethyldihydro-",
      "50994-87-1",
      "SCHEMBL576533",
      "CHEMBL286415",
      "Dihydro-3,3-Diethyl-2(3h)-Furanone",
    ],
  },
  {
    primary_id: "D00VXE",
    names: [
      "N-(Adamant-1-Yl)-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL591468",
      "AC1MLZYY",
      "N-(1-Adamantyl)Phenothiazine-10-Carboxamide",
      "Oprea1_167795",
      "ZINC6252722",
      "BDBM50308401",
      "AKOS004107814",
    ],
  },
  {
    primary_id: "D00VXM",
    names: ["Droxinostat"],
  },
  {
    primary_id: "D00VXV",
    names: ["PMID25980951-Compound-40"],
  },
  {
    primary_id: "D00VXZ",
    names: ["YT-1006"],
  },
  {
    primary_id: "D00VYC",
    names: ["Ac-YR[CEHdFRWC]-NH2", "CHEMBL264352"],
  },
  {
    primary_id: "D00VYD",
    names: ["RS-136270"],
  },
  {
    primary_id: "D00VZA",
    names: ["2-Benzoyl-3-(3,4-Dihydroxyphenyl)Acrylonitrile"],
  },
  {
    primary_id: "D00VZE",
    names: [
      "N-Adamantyl-N'-Cyclohexylurea",
      "CHEMBL242255",
      "N-1-Adamantyl-N'-Cyclohexylurea",
      "AC1NMPQQ",
      "Urea-Based Compound, 13",
      "ChemDiv3_000178",
      "Oprea1_419513",
      "1-Adamantyl-3-Cyclohexylurea",
      "MLS000719875",
      "SCHEMBL653168",
      "BDBM25732",
      "MolPort-003-713-177",
      "HMS2616F12",
      "HMS1473I02",
      "3-Adamantan-1-Yl-1-Cyclohexylurea",
      "1-(1-Adamantyl)-3-Cyclohexylurea",
      "ZINC3895072",
      "AKOS001483388",
      "MCULE-6291906995",
      "1-(Adamantane-1-Yl)-3-Cyclohexylurea",
      "IDI1_019496",
      "N-Adamantanyl(Cyclohexylamino)Carboxamide",
      "NCGC00177652-01",
      "SMR000304404",
      "EU-0000129",
      "ST51030996",
      "US8815951, 192",
      "SR-01000389488",
    ],
  },
  {
    primary_id: "D00VZF",
    names: ["Merbarone"],
  },
  {
    primary_id: "D00VZZ",
    names: [
      "ALLOPREGNANOLONE",
      "516-54-1",
      "Brexanolone",
      "Allotetrahydroprogesterone",
      "Allopregnan-3alpha-Ol-20-One",
      "3alpha-Hydroxy-5alpha-Pregnan-20-One",
      "SAGE-547",
      "UNII-S39XZ5QV8Y",
      "3-Alpha,5-Alpha-Pregnanolone",
      "3alpha-OH DHP",
      "3alpha,5alpha-THP",
      "5alpha-Pregnan-3alpha-Ol-20-One",
      "3a-Hydroxy-5a-Pregnan-20-One",
      "3a,5a-THP",
      "(3alpha)-Allopregnanolone",
      "3-Alpha,5-Alpha-Tetrahydroprogesterone",
      "BRN 3211363",
      "S39XZ5QV8Y",
      "3alpha-Hydroxy-5alpha-Dihydroprogesterone",
      "3-Alpha-Hydroxy-5-Alpha-Pregnan-20-One",
      "CHEMBL207538",
      "CHEBI:50169",
      "SGE-102",
    ],
  },
  {
    primary_id: "D00WAM",
    names: [
      "Hydroflumethiazide",
      "Bristab",
      "Bristurin",
      "Dihydroflumethazide",
      "Dihydroflumethiazide",
      "Diucardin",
      "Diuredemina",
      "Diurometon",
      "Elodrin",
      "Elodrine",
      "Enjit",
      "Finuret",
      "Flutizide",
      "Glomerulin",
      "HFZ",
      "Hidroalogen",
      "Hidroflumetiazid",
      "Hidroflumetiazida",
      "Hydol",
      "Hydrenox",
      "Hydroflumethazide",
      "Hydroflumethiazidum",
      "Hydroflumethizide",
      "Idroflumetiazide",
      "Leodrine",
      "Metflorylthiazidine",
      "Metforylthiadiazin",
      "Metforylthiazidin",
      "Methforylthiazidine",
      "NaClex",
      "Olmagran",
      "Rivosil",
      "Robezon",
      "Rodiuran",
      "Rontyl",
      "Saluron",
      "Sisuril",
      "Spandiuril",
      "Trifluoromethylhydrazide",
      "Trifluoromethylhydrothiazide",
      "Vergonil",
      "Component Of Salutensin",
      "Idroflumetiazide [DCIT]",
      "SB01887",
      "Di-Ademil",
      "Di-Adenil",
      "Hidroflumetiazida [INN-Spanish]",
      "Hydroflumethiazidum [INN-Latin]",
      "Naciex (Glaxo)",
      "Saluron (TN)",
      "Hydroflumethiazide [INN:BAN:JAN]",
      "Hydroflumethiazide (JAN/USP/INN)",
      "Ethyl-2H-1,2, 4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "Fluoromethyl-3, 4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "1,1-Dioxo-6-(Trifluoromethyl)-3,4-Dihydro-2H-1",
      "2H-1,2, 4-Benzothiadiazine-7-Sulfonamide, 3, 4-Dihydro-6-(Trifluoromethyl)-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3,4-Dihydro-6-(Trifluoromethyl)-, 1,1-Dioxide",
      "3, 4-Dihydro-6-Trifluorom",
      "3, 4-Dihydro-6-Trifluoromethyl-7-Sulfamoylbenzo-1,2,4-Thiadiazine 1, 1-Dioxide",
      "3,4-Dihydro-6-(Trifluoromethyl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3,4-Dihydro-6-Trifluoromethyl-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3,4-Dihydro-6-Trifluoromethyl-7-Sulfamoylbenzo-1,2,4-Thiadiazine 1,1-Dioxide",
      "3,4-Dihydro-7-Sulfamoyl-6-Trifluoromethyl-2H-1,2, 4-Benzothi",
      "3,4-Dihydro-7-Sulfamoyl-6-Trifluoromethyl-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "3,4-Dihydro-7-Sulfamyl-6-Trifluoromethyl-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-(Trifluoromethyl)-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Trifluoromethyl-3, 4-Dihydro-7-Sulfamoyl-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-Trifluoromethyl-3,4-Dihydro-7-Sulfamoyl-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-Trifluoromethyl-7-Sulfamoyl-3,4-Dihydro-1,2, 4-Benzothiadiazine-1,1-Dioxide",
      "6-Trifluoromethyl-7-Sulfamoyl-3,4-Dihydro-1,2,4-Benzothiadiazine-1,1-Dioxide",
      "6-Trifluoromethyl-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine-1,1-Dioxide",
      "7-Sulfamoyl-6-Tri",
      "7-Sulfamoyl-6-Trifluoromethyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "7-Sulfamyl-6-Trifluoromethyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1, 1-Dioxide",
      "7-Sulfamyl-6-Trifluoromethyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D00WBN",
    names: ["ALV-003"],
  },
  {
    primary_id: "D00WBS",
    names: ["Glycodeoxycholic Acid"],
  },
  {
    primary_id: "D00WBU",
    names: ["H-Dmt-Tic-NH-(R)CH(CH2-COOH)-Bid", "CHEMBL444197"],
  },
  {
    primary_id: "D00WBY",
    names: ["Pain Therapy, Sephoris"],
  },
  {
    primary_id: "D00WCT",
    names: ["EVP-1001-1"],
  },
  {
    primary_id: "D00WCX",
    names: [
      "Bezafibrate",
      "Azufibrat",
      "Befibrat",
      "Befizal",
      "BezaLande",
      "BezaPuren",
      "Bezabeta",
      "Bezacur",
      "Bezafibrat",
      "Bezafibrato",
      "Bezafibratum",
      "Bezafisal;Bezalip",
      "Bezamerck",
      "Bezatol",
      "Cedur",
      "Difaterol",
      "Durabezur",
      "Eulitop",
      "Lipox",
      "Reducterol",
      "Sklerofibrat",
      "Solibay",
      "Azupharma Brand Of Bezafibrate",
      "Bayer Brand Of Bezafibrate",
      "Berlin Chemie Brand Of Bezafibrate",
      "Betapharm Brand Of Bezafibrate",
      "Beza Lande",
      "Beza Puren",
      "Bezafibrat PB",
      "Bezafibrate Azupharma Brand",
      "Bezafibrate Bayer Brand",
      "Bezafibrate Betapharm Brand",
      "Bezafibrate Cryopharma Brand",
      "Bezafibrate Elfar Brand",
      "Bezafibrate Hennig Brand",
      "Bezafibrate Hexal Brand",
      "Bezafibrate Isis Brand",
      "Bezafibrate Lakeside Brand",
      "Bezafibrate Merckle Brand",
      "Bezafibrate Roche Brand",
      "Bezafibrate Synthelabo Brand",
      "Bezafibrate TAD Brand",
      "Bezafibrate Teva Brand",
      "Bezafibrato [Spanish]",
      "Bezalip Retard",
      "Bezatol SR",
      "Boehringer Mannheim Brand Of Bezafibrate",
      "Cryopharma Brand Of Bezafibrate",
      "Elfar Brand Of Bezafibrate;Hennig Brand Of Bezafibrate",
      "Hexal Brand Of Bezafibrate",
      "Isis Brand Of Bezafibrate",
      "Lakeside Brand Of Bezafibrate",
      "Merckle Brand Of Bezafibrate",
      "Regadrin B",
      "Roche Brand Of Bezafibrate",
      "Synthelabo Brand Of Bezafibrate",
      "TAD Brand Of Bezafibrate",
      "Teva Brand Of Bezafibrate",
      "BM 15075",
      "LO 44",
      "BF-759",
      "BM 15.075",
      "BM-15075",
      "BM15.075",
      "Berlin-Chemie Brand Of Bezafibrate",
      "Beza-Lande",
      "Beza-Puren",
      "Bezafibrate Berlin-Chemie Brand",
      "Bezafibrato [INN-Spanish]",
      "Bezafibratum [INN-Latin]",
      "Bezalip (TN)",
      "Bezatol SR (TN)",
      "PB, Bezafibrat",
      "BM-15.075",
      "Bezafibrate (JP15/USAN/INN)",
      "Bezafibrate [USAN:BAN:INN:JAN]",
      "A-[4-(4-Chlorobenzoylaminoethyl)Phenoxy]isobutyric Acid",
      "2-(4-{2-[(4-Chlorobenzoyl)Amino]ethyl}phenoxy)-2-Methylpropanoic Acid",
      "2-(P-(2-(P-Chlorobenzamido)Ethyl)Phenoxy)-2-Methylpropionic Acid",
      "2-[4-(2-[4-Chlorobenzamido]ethyl)-Phenoxy]-2-Methylpropanoic Acid",
      "2-[4-[2-(4-Chlorobenzamido)Ethyl]phenoxy]-2-Methylpropanoic Acid",
      "2-[4-[2-(4-Chlorobenzamido)Ethyl]phenoxy]isobutyric Acid",
      "2-[4-[2-[(4-Chlorobenzoyl)Amino]ethyl]phenoxy]-2-Methylpropanoic Acid",
    ],
  },
  {
    primary_id: "D00WDA",
    names: ["Indazole Amide Derivative 2"],
  },
  {
    primary_id: "D00WDM",
    names: ["BST-204"],
  },
  {
    primary_id: "D00WDY",
    names: ["BMS-903452"],
  },
  {
    primary_id: "D00WEF",
    names: ["Opdivo"],
  },
  {
    primary_id: "D00WEL",
    names: ["ABS-401"],
  },
  {
    primary_id: "D00WEP",
    names: ["BLX-155"],
  },
  {
    primary_id: "D00WEQ",
    names: [
      "Brolamfetamine",
      "2,5-Dimethoxy-4-Bromoamphetamine",
      "4-Bromo-2,5-Dimethoxyamphetamine",
      "DOB-4",
      "4-Bromo-2,5-Dimethoxyphenylisopropylamine",
      "4-Bromo-DMA",
      "32156-26-6",
      "2,5-Dimethoxy-(4-Bromo)Phenylisopropylamine",
      "64638-07-9",
      "1-(4-Bromo-2,5-Dimethoxyphenyl)Propan-2-Amine",
      "1-(2,5-Dimethoxyphenyl-4-Bromo)-2-Aminopropane",
      "1-(4-Bromo-2,5-Dimethoxyphenyl)-2-Aminopropane",
      "4-Bromo-2,5-Dimethoxy-Alpha-Methylbenzeneethanamine",
      "CHEMBL6607",
      "DOB",
      "DEA No. 7391",
      "Benzeneethanamine, 4-Bromo-2,5-Dimethoxy-Alpha-Methyl-",
      "Brolamfetaminum",
    ],
  },
  {
    primary_id: "D00WEV",
    names: ["H-Dmt-Tic-Glu-Dap(6DMN)-NH(2)", "CHEMBL208916"],
  },
  {
    primary_id: "D00WFB",
    names: [
      "7-Hydroxy-2-(4-Hydroxy-Benzyl)-Chromen-4-One",
      "CHEMBL56650",
      "ZINC13797581",
      "BDBM50077320",
      "2-(4-Hydroxybenzyl)-7-Hydroxychromone",
    ],
  },
  {
    primary_id: "D00WFQ",
    names: [
      "(4-Nitro-1H-Pyrazol-1-Yl)(O-Tolyl)Methanone",
      "BAS 02052986",
      "AC1LDSC0",
      "N-Benzoylpyrazole Deriv., 20",
      "CHEMBL244072",
      "ZINC37170",
      "BDBM23712",
      "MolPort-001-971-782",
      "AKOS000577347",
      "2-Methylphenyl 4-Nitropyrazolyl Ketone",
      "MCULE-4726161589",
      "ST033205",
      "(4-Nitro-Pyrazol-1-Yl)-O-Tolyl-Methanone",
      "(2-Methylphenyl)-(4-Nitropyrazol-1-Yl)Methanone",
    ],
  },
  {
    primary_id: "D00WFU",
    names: [
      "LUF-5737",
      "CHEMBL376778",
      "820961-68-0",
      "SCHEMBL6502989",
      "CTK3E2640",
      "DTXSID50464193",
      "BDBM50157654",
      "N-(4,6-Diphenylpyrimidin-2-Yl)Isobutyramide",
      "L023249",
      "Propanamide, N-(4,6-Diphenyl-2-Pyrimidinyl)-2-Methyl-",
    ],
  },
  {
    primary_id: "D00WHC",
    names: [
      "MB-03966",
      "AK Inhibitors, Gensia",
      "AK Inhibitors, SICOR",
      "Adenosine Kinase Inhibitors, Metabasis",
      "Adenosine Kinase Inhibitors, SICOR",
      "GP-3966",
      "GP-4364",
      "MB-03691",
      "MB-4587",
      "Tubercidin Derivatives, Metabasis",
    ],
  },
  {
    primary_id: "D00WHH",
    names: ["4-Pyridone Derivative 2"],
  },
  {
    primary_id: "D00WHL",
    names: ["Juvidex", "Adaprev", "Mannose-6-Phosphate (Scarring), Renovo"],
  },
  {
    primary_id: "D00WHO",
    names: ["Ro-15-3505"],
  },
  {
    primary_id: "D00WJC",
    names: ["DCDS0780A"],
  },
  {
    primary_id: "D00WJE",
    names: ["SUN-1334H"],
  },
  {
    primary_id: "D00WKK",
    names: ["2-Morpholino-2-Phenyl-1,1-Di(Pyridin-3-Yl)Ethanol", "CHEMBL1090794", "SCHEMBL1240366"],
  },
  {
    primary_id: "D00WKL",
    names: ["PSX-4206"],
  },
  {
    primary_id: "D00WLO",
    names: [
      "GBR-12909",
      "67469-78-7",
      "Vanoxerine Dihydrochloride",
      "Vanoxerine Hydrochloride",
      "UNII-MWO1IP03EV",
      "MWO1IP03EV",
      "Vanoxerine Dihydrochloride(GBR12909)",
      "CHEBI:64086",
    ],
  },
  {
    primary_id: "D00WMC",
    names: ["N-(4-Phenylthiazol-2-Yl)Isonicotinamide"],
  },
  {
    primary_id: "D00WME",
    names: ["Conatumumab"],
  },
  {
    primary_id: "D00WMJ",
    names: ["CMC-001"],
  },
  {
    primary_id: "D00WMK",
    names: ["Relvar/Breo"],
  },
  {
    primary_id: "D00WMS",
    names: [
      "2-(N-Tert-Butylamino)Propiophenone",
      "CHEMBL569699",
      "AC1MIL82",
      "SCHEMBL769768",
      "JQNSRSIGKZYQAA-UHFFFAOYSA-N",
      "Alpha-(Tert-Butylamino)Propiophenone",
      "BDBM50302912",
      "AKOS022329747",
    ],
  },
  {
    primary_id: "D00WMV",
    names: ["UCL1684"],
  },
  {
    primary_id: "D00WNA",
    names: ["ML381"],
  },
  {
    primary_id: "D00WOE",
    names: [
      "N-(4'-Chloro-4-Biphenylyl)Methanesulfonamide",
      "CHEMBL242150",
      "SCHEMBL4661119",
      "BDBM50220160",
      "ZINC28711996",
    ],
  },
  {
    primary_id: "D00WOK",
    names: ["ITP"],
  },
  {
    primary_id: "D00WON",
    names: [
      "3-Ethyl-3-Methyl-Dihydro-Furan-2-One",
      "Alpha-Emgbl",
      "2-Ethyl-2-Methyl-4-Butyrolactone",
      "3-Ethyl-3-Methyldihydrofuran-2(3h)-One",
      "31004-76-9",
      "AC1Q6HQE",
      "CHEBI:35057",
      "2(3H)-Furanone, 3-Ethyldihydro-3-Methyl-",
      "Alpha-Ethyl-Alpha-Methyl-Gamma-Butyrolactone",
      "SureCN132980",
      "C13714",
      "AC1L53DL",
      "SCHEMBL132980",
      "CHEMBL285171",
      "3-Ethyl-3-Methyloxolan-2-One",
      "CTK4G6275",
      "3-Ethyl-3-Methyl-Tetrahydrofuran-2-One",
    ],
  },
  {
    primary_id: "D00WOT",
    names: ["CDP-850"],
  },
  {
    primary_id: "D00WOX",
    names: ["Human Retinal Pigment Epithelial Cell Therapy"],
  },
  {
    primary_id: "D00WPT",
    names: ["ACV-1"],
  },
  {
    primary_id: "D00WPU",
    names: [
      "Sazetidine-A",
      "AMOP-H-OH",
      "Nicotinic Ligands (Depression, Pain, Nicotine Dependence)",
      "Nicotinic Ligands (Depression, Pain, Nicotine Dependence), PsychoGenics",
    ],
  },
  {
    primary_id: "D00WSM",
    names: [
      "2-Oxo-N-M-Tolyl-2H-Chromene-3-Carboxamide",
      "1846-99-7",
      "2-Oxo-N-(M-Tolyl)-2H-Chromene-3-Carboxamide",
      "2-Oxo-2H-Chromene-3-Carboxylic Acid M-Tolylamide",
      "N-(3-Methylphenyl)-2-Oxo-2H-Chromene-3-Carboxamide",
      "AC1LCCPQ",
      "BAS 00837918",
      "AC1Q2NKJ",
      "3-Carboxamido Coumarin, 8",
      "TimTec1_006709",
      "Oprea1_808053",
      "Oprea1_256730",
      "CBDivE_000076",
      "MLS001209245",
      "CHEMBL445184",
      "SCHEMBL6228552",
      "BDBM29158",
      "2h-1-Benzopyran-3-Carboxamide,n-(3-Methylphenyl)-2-Oxo-",
      "MolPort-000-375-220",
      "XUNAVIWFCXQWCJ-UHFFFAOYSA-N",
      "HMS2825F09",
      "HMS1553A21",
      "ZINC130233",
      "N-(M-Tolyl)-3-Coumarincarboxamide",
      "STK408207",
    ],
  },
  {
    primary_id: "D00WSN",
    names: [
      "N-(2-Morpholinophenyl)-5-Nitrofuran-2-Carboxamide",
      "CHEMBL251369",
      "AC1MH48W",
      "MLS001162447",
      "SCHEMBL13148062",
      "Cid_2996093",
      "N-[2-(Morpholin-4-Yl)Phenyl]-5-Nitrofuran-2-Carboxamide",
      "MolPort-002-305-194",
      "HMS2967E08",
      "ZINC4842600",
      "STK355602",
      "BDBM50223675",
      "AKOS000458368",
      "MCULE-3330873928",
      "SMR000670465",
      "VU0465060-1",
      "N-(2-Morpholin-4-Ylphenyl)-5-Nitro-2-Furamide",
      "AP-970/43481542",
      "N-[2-(4-Morpholinyl)Phenyl]-5-Nitro-2-Furamide",
    ],
  },
  {
    primary_id: "D00WUC",
    names: ["Histidase-PEG-20"],
  },
  {
    primary_id: "D00WUF",
    names: ["Pregabalin"],
  },
  {
    primary_id: "D00WVG",
    names: [
      "2-(2-Cyclopentylidenehydrazinyl)-4-Phenylthiazole",
      "CHEMBL386941",
      "AC1LBKTO",
      "SCHEMBL18998987",
      "WUSIIHCESPBBHG-UHFFFAOYSA-N",
      "MolPort-002-698-743",
      "ZINC7350034",
      "BDBM50326626",
      "STK673148",
      "AKOS005593088",
      "MCULE-7323011972",
      "Cyclopentanone, (4-Phenyl-2-Thiazolyl)Hydrazone",
      "N-(Cyclopentylideneamino)-4-Phenyl-Thiazol-2-Amine",
      "1-Cyclopentylidene-2-(4-Phenylthiazol-2-Yl)Hydrazine",
      "N-(Cyclopentylideneamino)-4-Phenyl-1,3-Thiazol-2-Amine",
      "A1355/0061138",
      "Cyclopentanone ((2Z)-4-Phenyl-1,3-Thiazol-2(3H)-Ylidene)Hydrazone #",
    ],
  },
  {
    primary_id: "D00WVI",
    names: [
      "AS-1907417",
      "AS-1535907",
      "AS-1669058",
      "AS-179009",
      "GPR119 Agonists (Type 2 Diabetes), Astellas Pharma",
    ],
  },
  {
    primary_id: "D00WVW",
    names: ["YM443", "Acofide", "Acotiamide"],
  },
  {
    primary_id: "D00WWU",
    names: ["SMP-028"],
  },
  {
    primary_id: "D00WYS",
    names: ["3-(4-Phenyl-6-(Thiophen-2-Yl)Pyridin-2-Yl)-Phenol", "CHEMBL1094819"],
  },
  {
    primary_id: "D00WYV",
    names: ["V-3526"],
  },
  {
    primary_id: "D00WZE",
    names: ["ISIS 102619"],
  },
  {
    primary_id: "D00WZG",
    names: ["6,7-Dimethoxy-4-(3-Phenoxyprop-1-Ynyl)Quinazoline", "CHEMBL250523", "BDBM50222432"],
  },
  {
    primary_id: "D00WZH",
    names: ["PMID26004420-Compound-WO2015017305F"],
  },
  {
    primary_id: "D00WZI",
    names: [
      "5-Methyl-Benzo[b]thiophen-2-Ylboronic Acid",
      "5-Methylbenzo[b]thiophene-2-Boronic Acid",
      "136099-65-5",
      "5-METHYLBENZOTHIPHENE-2-BORONIC ACID",
      "5-Methylbenzo[b]thiophen-2-Ylboronic Acid",
      "CHEMBL238243",
      "5-METHYLBENZOTHIOPHENE-2-BORONIC ACID",
      "(5-Methyl-1-Benzothiophen-2-Yl)Boronic Acid",
      "5-Methyl-1-Benzothiophen-2-Ylboronic Acid",
      "(5-Methylbenzothiophen-2-Yl)Boronic Acid",
      "AC1MDRK8",
      "C9H9BO2S",
      "SCHEMBL289762",
      "CTK6C0387",
      "DTXSID20383286",
      "MolPort-000-145-050",
      "DHNHZPQPQAINDI-UHFFFAOYSA-N",
      "2-Borono-5-Methylbenzo[b]thiophene",
      "6437AA",
      "SBB091358",
    ],
  },
  {
    primary_id: "D00WZJ",
    names: ["2,2-Dimethylbenzopyran Derivative 3"],
  },
  {
    primary_id: "D00WZK",
    names: ["BC-3781 Intravenous", "BC-3781 (Intravenous), Nabriva", "BC-3781 (Oral), Nabriva"],
  },
  {
    primary_id: "D00XBC",
    names: ["GSK-2239633"],
  },
  {
    primary_id: "D00XBH",
    names: ["MNLP-462a"],
  },
  {
    primary_id: "D00XBM",
    names: ["Cyclo(-D-MeTyr-D-Arg-L-Arg-L-Nal-Gly-)", "CHEMBL375993"],
  },
  {
    primary_id: "D00XCC",
    names: ["(D)-Phe-(D)-Phe-NH2", "CHEMBL590276", "AC1OEQ8I", "BDBM50308389"],
  },
  {
    primary_id: "D00XCL",
    names: [
      "1-(4-Aminophenylsulfonyl)-3-(2-Mercaptoethyl)Urea",
      "CHEMBL497689",
      "4-Amino-N-[(2-Sulfanylethyl)Carbamoyl]benzenesulfonamide",
      "BDBM50272611",
      "DB08484",
      "1-(4-Aminobenzenesulfonyl)-3-(2-Sulfanylethyl)Urea",
    ],
  },
  {
    primary_id: "D00XCX",
    names: [
      "DIFLOMOTECAN",
      "BN-80915",
      "R-1536",
      "Diflomotecan",
      "(+)-5(R)-Ethyl-9,10-Difluoro-5-Hydroxy-4,5,13,15-Tetrahydro-1H,3H-Oxepino[3',4':6,7]indolizino[1,2-B]quinoline-3,15-Dione",
    ],
  },
  {
    primary_id: "D00XDG",
    names: [
      "5-Amino-2-Phenyl-Oxazole-4-Carboxylic Acid Amide",
      "CHEMBL207987",
      "5-Amino-2-Phenyloxazole-4-Carboxylic Acid Amide",
      "99185-68-9",
    ],
  },
  {
    primary_id: "D00XDN",
    names: ["Pyrazole Derivative 40"],
  },
  {
    primary_id: "D00XEM",
    names: ["Beta-Cyclodextrin Conjugate Derivative 3"],
  },
  {
    primary_id: "D00XEN",
    names: ["NGB 2904"],
  },
  {
    primary_id: "D00XEU",
    names: [
      "GSK-923295",
      "1088965-37-0",
      "GSK923295",
      "3-Chloro-N-((S)-1-(2-(Dimethylamino)Acetamido)-3-(4-(8-((S)-1-Hydroxyethyl)Imidazo[1,2-A]pyridin-2-Yl)Phenyl)Propan-2-Yl)-4-Isopropoxybenzamide",
      "3-Chloro-N-((S)-1-(2-(Dimethylamino)Acetamido)-3-(4-(8-((S)-1-Hydroxyethyl)-Imidazo[1,2-A]pyridin-2-Yl)Phenyl)Propan-2-Yl)-4-Isopropoxybenzamide",
      "GSK 923295",
      "MLS006011170",
      "SCHEMBL1492164",
      "UNII-072702W9QD",
      "CTK8C0600",
      "DTXSID50677145",
      "GSK-295",
      "AOB5937",
      "EX-A633",
      "MolPort-023-332-816",
      "GSK-923295A",
      "BCP09858",
      "ANW-64945",
      "S7090",
      "ZINC68151184",
      "AKOS016005270",
    ],
  },
  {
    primary_id: "D00XEX",
    names: ["MN-201"],
  },
  {
    primary_id: "D00XFM",
    names: ["Phthalazinone Derivative 1"],
  },
  {
    primary_id: "D00XFT",
    names: ["Pyrazole Derivative 53"],
  },
  {
    primary_id: "D00XFY",
    names: ["ISIS 2922 + Foscarnet 3' -Azido-3'-Deoxythymidine 2'-3'-Dideoxycytidine"],
  },
  {
    primary_id: "D00XGW",
    names: ["BVT173187", "BVT 173187"],
  },
  {
    primary_id: "D00XHC",
    names: ["N,N-Diisopropyl-1'H-Phenothiazine-1'-Carboxamide", "CHEMBL588172", "Oprea1_258691", "BDBM50308413"],
  },
  {
    primary_id: "D00XHD",
    names: [
      "Balofloxacin",
      "Baloxin",
      "Balofloxacin [INN]",
      "Q 35",
      "(+-)-1-Cyclopropyl-6-Fluoro-1,4-Dihydro-8-Methoxy-7-(3-(Methylamino)Piperidino)-4-Oxo-3-Quinoline Arboxylic Acid",
      "(+-)-1-Cyclopropyl-6-Fluoro-1,4-Dihydro-8-Methoxy-7-(3-(Methylamino)Piperidino)-4-Oxo-3-Quinolinecarboxylic Acid",
      "1-Cyclopropyl-6-Fluoro-8-Methoxy-7-[3-(Methylamino)Piperidin-1-Yl]-4-Oxoquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D00XIB",
    names: ["1-Acetamido-5-Sulfonamidoindane"],
  },
  {
    primary_id: "D00XIP",
    names: ["MVA-BN Brachyury"],
  },
  {
    primary_id: "D00XIX",
    names: ["5-(4-Chlorophenyl)-4-P-Tolyl-3H-1,2-Dithiol-3-One", "CHEMBL475545"],
  },
  {
    primary_id: "D00XJA",
    names: [
      "6-Methoxy-2-Oxo-2H-Chromene-3-Carboxylic Acid",
      "35924-44-8",
      "6-Methoxy-2-Oxochromene-3-Carboxylic Acid",
      "CHEMBL568416",
      "6-Methoxy-2-Oxo-2H-1-Benzopyran-3-Carboxylic Acid",
      "MLS000061036",
      "AC1LEG8M",
      "CBMicro_000141",
      "Oprea1_705276",
      "Oprea1_357602",
      "6-Methoxy-3-Carboxy-Coumarin",
      "SCHEMBL978456",
      "CTK1B0306",
      "DTXSID90351856",
      "XIQQAIQZABTSNZ-UHFFFAOYSA-N",
      "MolPort-000-467-024",
      "ZINC103684",
      "HMS2443I11",
      "HMS1607B10",
      "SMSF0006060",
      "ZX-AL001956",
      "STL553633",
      "SBB043625",
      "BDBM50303489",
      "AKOS000274154",
      "CB01296",
      "MCULE-7757033841",
      "BAS 00518708",
      "SMR000069802",
    ],
  },
  {
    primary_id: "D00XJR",
    names: ["JNJ-10191584"],
  },
  {
    primary_id: "D00XKA",
    names: [
      "SC-41661A",
      "105357-16-2",
      "105357-17-3",
      "Propanamide, 3-[(3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl]thio]-N-Methyl-N-[2-(2-Pyridinyl)Ethyl]-",
    ],
  },
  {
    primary_id: "D00XKB",
    names: ["AZ-4217", "AZ4217", "AZ 4217"],
  },
  {
    primary_id: "D00XKE",
    names: ["PMID26651364-Compound-109"],
  },
  {
    primary_id: "D00XKH",
    names: ["S-(N-Butyl-N-Hydroxycarbamoyl)Glutathione", "CHEMBL128447", "SCHEMBL3281791", "BDBM50092822"],
  },
  {
    primary_id: "D00XKM",
    names: [
      "4-Hydroxy-4-Phenyl-Butyric Acid",
      "4-Hydroxy-4-Phenylbutanoic Acid",
      "34674-93-6",
      "BAS 00281341",
      "AC1O5G7Z",
      "SCHEMBL258372",
      "CHEMBL170514",
      "4-Hydroxy-4-Phenylbutyric Acid",
      "Gamma-Hydroxybenzenebutanoic Acid",
      "CTK7J6414",
      "SBB010649",
      "AKOS016038533",
      "AKOS000301399",
      "MCULE-4325680851",
      "BBV-183857",
      "TR-045028",
      "BB0266635",
      "ST50341916",
    ],
  },
  {
    primary_id: "D00XLE",
    names: ["PF-4764793"],
  },
  {
    primary_id: "D00XLU",
    names: ["N-Benzyl-4-Hydroxy-N-Phenylbenzenesulfonamide", "CHEMBL203860"],
  },
  {
    primary_id: "D00XMF",
    names: ["Isoxazoles And Isoxazoline Derivative 2"],
  },
  {
    primary_id: "D00XMO",
    names: ["2-(4-Chlorobenzylidene)Cyclopentyl Ethyl Ether", "CHEMBL538612"],
  },
  {
    primary_id: "D00XMP",
    names: ["1-Methyl-4-(1-Phenyl-Ethyl)-Piperazine", "CHEMBL195009", "SCHEMBL2625061", "BDBM50174064"],
  },
  {
    primary_id: "D00XMZ",
    names: ["Drug 1940029", "1940029"],
  },
  {
    primary_id: "D00XNC",
    names: ["CAT-2200"],
  },
  {
    primary_id: "D00XND",
    names: ["PMA-511R"],
  },
  {
    primary_id: "D00XNE",
    names: ["Incrementally Modified Drug"],
  },
  {
    primary_id: "D00XNF",
    names: ["LY256548"],
  },
  {
    primary_id: "D00XNW",
    names: ["AL-54478"],
  },
  {
    primary_id: "D00XNY",
    names: ["4,5,6,7-Tetrahydroisoxazolo-[5,4-C]-Pyridin-3-Ol"],
  },
  {
    primary_id: "D00XOQ",
    names: ["SB-210313"],
  },
  {
    primary_id: "D00XOT",
    names: ["4-(2-Hydroxyethoxy)-1-Naphthol", "CHEMBL570066", "83115-56-4", "SCHEMBL12599103"],
  },
  {
    primary_id: "D00XOV",
    names: ["B3C", "Compound 23 [PMID: 18752942]"],
  },
  {
    primary_id: "D00XPA",
    names: ["L-759274"],
  },
  {
    primary_id: "D00XPB",
    names: ["PMID22533316C1"],
  },
  {
    primary_id: "D00XPC",
    names: ["Halobetasol Propionate"],
  },
  {
    primary_id: "D00XPD",
    names: ["ISIS 29233"],
  },
  {
    primary_id: "D00XPZ",
    names: ["ELB-WE-007"],
  },
  {
    primary_id: "D00XQK",
    names: ["HRC-102"],
  },
  {
    primary_id: "D00XQO",
    names: ["MANOALIDE"],
  },
  {
    primary_id: "D00XQR",
    names: ["SAR-288233"],
  },
  {
    primary_id: "D00XQV",
    names: ["Tyr-Pro-D-Phe-Pro-NH2"],
  },
  {
    primary_id: "D00XRH",
    names: ["PMID26155854C60"],
  },
  {
    primary_id: "D00XRI",
    names: ["TJN-220"],
  },
  {
    primary_id: "D00XRK",
    names: ["BMS-182193"],
  },
  {
    primary_id: "D00XRM",
    names: ["ISIS 109353"],
  },
  {
    primary_id: "D00XRN",
    names: ["PMID28074661-Compound-US20100022547C81"],
  },
  {
    primary_id: "D00XSL",
    names: ["RHuB-D2"],
  },
  {
    primary_id: "D00XSP",
    names: ["S-35678"],
  },
  {
    primary_id: "D00XSQ",
    names: ["PGL-1001"],
  },
  {
    primary_id: "D00XTC",
    names: ["Cyclo(-L-Am7(S2Py)-L-A1in-L-Ala-D-Pro-)", "CHEMBL393260"],
  },
  {
    primary_id: "D00XTS",
    names: ["CR-3834", "Angiotensin II Antagonist (Hypertension), Rottapharm"],
  },
  {
    primary_id: "D00XUE",
    names: [
      "(S)-2-Amino-3-Cyclohexylpropanoic Acid",
      "27527-05-5",
      "L-Cyclohexylalanine",
      "H-Cha-OH",
      "(2S)-2-Amino-3-Cyclohexylpropanoic Acid",
      "3-Cyclohexyl-L-Alanine",
      "L-3-Cyclohexylalanine",
      "(S)-2-Amino-3-Cyclohexylpropionic Acid",
      "BETA-CYCLOHEXYL-ALANINE",
      "L-Beta-Cyclohexylalanine",
      "UNII-GYR4SF0FVQ",
      "GYR4SF0FVQ",
      "CHEMBL383208",
      "Cyclohexanepropanoic Acid, Alpha-Amino-, (AlphaS)-",
      "2-AMINO-3-CYCLOHEXYL-PROPIONIC ACID",
      "3-Cyclohexylalanine",
      "B-Cyclohexyl Alanine",
      "Beta-Cyclohexylalanine",
      "PubChem5760",
      "Beta-Cyclohexyl Alanine",
      "AC1LEHTM",
      "AC1Q5QKA",
    ],
  },
  {
    primary_id: "D00XUF",
    names: ["N-(8-(3-Cyanophenyl)-9H-Purin-6-Yl)Pentanamide", "CHEMBL1210476"],
  },
  {
    primary_id: "D00XUN",
    names: ["Etidronate Disodium", "Didronel"],
  },
  {
    primary_id: "D00XVE",
    names: ["BR6819"],
  },
  {
    primary_id: "D00XVL",
    names: ["ITI-214"],
  },
  {
    primary_id: "D00XVS",
    names: [
      "3beta-Hydroxyrus-18,20(30)-Dien-28-Oic Acid",
      "CHEMBL479472",
      "BDBM50259782",
      "3beta-Hydroxyurs-18,20(30)-Diene-28-Oic Acid",
    ],
  },
  {
    primary_id: "D00XVV",
    names: ["[11C]WAY100635", "[11C]WAY100635 (PET Ligand)", "[11C]WAY-100635"],
  },
  {
    primary_id: "D00XWB",
    names: ["F-15845"],
  },
  {
    primary_id: "D00XWD",
    names: ["Tegaserod"],
  },
  {
    primary_id: "D00XWF",
    names: ["RBX2660"],
  },
  {
    primary_id: "D00XWZ",
    names: ["PMID28092474-Compound-32a"],
  },
  {
    primary_id: "D00XXZ",
    names: ["N-Acylpiperidine Ether Derivative 1"],
  },
  {
    primary_id: "D00XZH",
    names: ["1-Benzhydryl-4-Isopropylpiperidin-4-Ol", "CHEMBL233721"],
  },
  {
    primary_id: "D00XZP",
    names: ["UR-7198"],
  },
  {
    primary_id: "D00XZY",
    names: ["ORC-Vax"],
  },
  {
    primary_id: "D00XZZ",
    names: [
      "4-Butyl-N-(Pyridin-3-Yl)Benzamide",
      "CHEMBL259396",
      "4-Butyl-N-Pyridin-3-Ylbenzamide",
      "AC1N7X0I",
      "Oprea1_505737",
      "MolPort-003-720-572",
      "ZINC3899411",
      "STK493059",
      "BDBM50376254",
      "AKOS001583267",
      "MCULE-4107696507",
      "(4-Butylphenyl)-N-(3-Pyridyl)Carboxamide",
      "ST50859128",
      "SR-01000396349",
    ],
  },
  {
    primary_id: "D00YAH",
    names: ["E2730"],
  },
  {
    primary_id: "D00YAS",
    names: [
      "Q-102",
      "(-)-Quinpirole Hydrochloride",
      "QUINPIROLE HYDROCHLORIDE",
      "85798-08-9",
      "Quinpirole HCl",
      "UNII-T6I2W5V2K1",
      "Quinpirole Hydrochloride [USAN]",
      "LY 171555",
      "T6I2W5V2K1",
      "LY-171,555",
      "DSSTox_RID_80926",
      "DSSTox_CID_25519",
      "DSSTox_GSID_45519",
      "(-)-(4aR,8aR)-4,4a,5,6,7,8,8a,9-Octahydro-5-Propyl-1H-Pyrazolo(3,4-G)Quinoline Monohydrochloride",
      "Quinpirole Hydrochloride (USAN)",
      "(4AR,8aR)-5-Propyl-4,4a,5,6,7,8,8a,9-Octahydro-1H-Pyrazolo[3,4-G]quinoline Hydrochloride",
      "SMR000326933",
      "1H-Pyrazolo(3,4-G)Quinoline, 4,4a,5,6,7,8,8a,9-Octahydro-5",
    ],
  },
  {
    primary_id: "D00YAZ",
    names: ["R-138727"],
  },
  {
    primary_id: "D00YBT",
    names: ["PMID25399762-Compound-Table1-C3"],
  },
  {
    primary_id: "D00YBU",
    names: ["C(RGDfF)", "CHEMBL380434"],
  },
  {
    primary_id: "D00YBW",
    names: ["OXY-111A"],
  },
  {
    primary_id: "D00YBZ",
    names: ["OC-10X", "AMD Therapy, OcuCure"],
  },
  {
    primary_id: "D00YCG",
    names: ["5-[2-(1H-Pyrrol-1-Yl)Ethoxy]-1H-Indole", "3fuj", "DB06828", "5-[2-(Pyrrol-1-Yl)Ethoxy]-1H-Indole"],
  },
  {
    primary_id: "D00YCO",
    names: ["Pulvomycin"],
  },
  {
    primary_id: "D00YCU",
    names: [
      "1-Phenyl-1H-Benzoimidazol-5-Ol",
      "1-Phenyl-5-Hydroxybenzimidazole",
      "1-Phenyl-1H-Benzimidazol-5-Ol",
      "69445-45-0",
      "1-Phenylbenzimidazole Deriv. 65",
      "AC1LDIKP",
      "1-Phenylbenzimidazol-5-Ol",
      "CHEMBL80873",
      "SCHEMBL4280861",
      "BDBM3848",
      "5-Hydroxy-1-Phenylbenzimidazole",
      "CTK5C9901",
      "ZINC17525",
      "DTXSID70349872",
      "1-Phenyl-1H-Benzimidazole-5-Ol",
      "1H-Benzimidazol-5-Ol,1-Phenyl-",
      "OQAPWZCMAFFBBL-UHFFFAOYSA-N",
      "1-Phenyl-1H-1,3-Benzodiazol-5-Ol",
      "AKOS022650144",
      "5-Substituted 1-Phenylbenzimidazole 5",
      "DA-04176",
      "KB-219704",
      "FT-0734974",
    ],
  },
  {
    primary_id: "D00YCW",
    names: ["4-Phenyl-1-(1-Phenylbutyl)Piperidin-4-Ol", "CHEMBL230145"],
  },
  {
    primary_id: "D00YDN",
    names: ["CXR-1002"],
  },
  {
    primary_id: "D00YDR",
    names: ["18-Methylaminocoronaridine"],
  },
  {
    primary_id: "D00YEB",
    names: ["11-Butyryloxy-N-N-Propylnoraporphine", "CHEMBL478671"],
  },
  {
    primary_id: "D00YEF",
    names: ["Decane-1,10-Diyl Disulfamate"],
  },
  {
    primary_id: "D00YEO",
    names: ["NZ-1002"],
  },
  {
    primary_id: "D00YER",
    names: ["HM-78136B"],
  },
  {
    primary_id: "D00YEV",
    names: [
      "BAICALEIN",
      "Baicalein",
      "491-67-8",
      "5,6,7-Trihydroxyflavone",
      "5,6,7-Trihydroxy-2-Phenyl-4H-Chromen-4-One",
      "Noroxylin",
      "Biacalein",
      "BaiKalein",
      "Baicelein",
      "UNII-49QAH60606",
      "NSC661431",
      "NSC 661431",
      "CHEMBL8260",
      "5,6,7-Trihydroxy-2-Phenylchromen-4-One",
      "CHEBI:2979",
      "FXNFHKRTJBSTCS-UHFFFAOYSA-N",
      "MFCD00017459",
      "4H-1-Benzopyran-4-One, 5,6,7-Trihydroxy-2-Phenyl-",
      "49QAH60606",
      "5,7-Trihydroxyflavone",
      "5,6,7-Trihydroxy-2-Phenyl-Chromen-4-One",
      "Sho-Saiko-To",
      "SMR000112462",
      "SR-01000597499",
      "Baicalein, 8",
      "Baicalein,(S)",
      "3WL",
      "Baicalein, 14",
      "Baicalein, 98%",
      "AC1NQYPP",
      "Tocris-1761",
    ],
  },
  {
    primary_id: "D00YFP",
    names: ["RG7697"],
  },
  {
    primary_id: "D00YGA",
    names: ["SB-219994"],
  },
  {
    primary_id: "D00YGP",
    names: ["6-Hydroxy-3-(3',5'-Dihydroxyphenyl)Coumarin", "CHEMBL199397", "BDBM50176526"],
  },
  {
    primary_id: "D00YGY",
    names: ["Pyrimidine Derivative 29"],
  },
  {
    primary_id: "D00YHD",
    names: ["4'-(2-Methyl-1-(Pyridin-4-Yl)Propyl)Biphenyl-3-Ol", "CHEMBL1172559", "SCHEMBL18770344", "BDBM50322796"],
  },
  {
    primary_id: "D00YHI",
    names: ["N-(14-Methylallyl)Norgalanthamine"],
  },
  {
    primary_id: "D00YID",
    names: ["ISIS 102462"],
  },
  {
    primary_id: "D00YIS",
    names: ["PMID19191557C14"],
  },
  {
    primary_id: "D00YJD",
    names: ["SEL-103"],
  },
  {
    primary_id: "D00YJI",
    names: [
      "EOS-013",
      "AAV Channel Rhodopsin Gene Therapy (Retinitis Pigmentosa), Eos",
      "ChR2 Gene Therapy (Retinitis Pigementosa), Eos",
      "Channel Rhodopsin Gene Therapy (Retinitis Pigmentosa), Eos",
      "Adeno-Associated Viral Delivery ChR2 (Retinitis Pigmentosa), Eos",
    ],
  },
  {
    primary_id: "D00YLF",
    names: ["Serpacwa"],
  },
  {
    primary_id: "D00YLI",
    names: ["T-Cell Receptors"],
  },
  {
    primary_id: "D00YLM",
    names: [
      "TRAXOPRODIL MESYLATE",
      "(S,S)-1-(4-Hydroxyphenyl)-2-[4-Hydroxy-4-Phenylpiperidin-1-Yl)-1-Propanol Methanesulfonate Trihydrate",
    ],
  },
  {
    primary_id: "D00YLW",
    names: [
      "Dolasetron",
      "Dolasetronum",
      "Dolasteron",
      "Anzemet (TN)",
      "Dolasetron (INN)",
      "Dolasetron [INN:BAN]",
      "Dolasetronum [INN-Latin]",
      "3-Oxooctahydro-2h-2,6-Methanoquinolizin-8-Yl 1h-Indole-3-Carboxylate",
    ],
  },
  {
    primary_id: "D00YMM",
    names: [
      "SAR-215500",
      "Antimicrobial Peptide, Novozymes",
      "NZ-2098",
      "NZ-2114",
      "Plectasin NZ-2114",
      "Plectasin (Recombinant), Novozymes",
      "Plectasins (Antibacterial), Novozymes",
      "Plectasins (Antibacterial), Novozymes/Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D00YMN",
    names: ["AIKO-152", "AIKO-150 + Opioid Analgesic (Pain/Opiate Dependence), Aiko Biotechnology"],
  },
  {
    primary_id: "D00YNA",
    names: [
      "3,3-(1,3-Thiazole-2,5-Diyl)Diphenol",
      "CHEMBL456379",
      "SCHEMBL1181184",
      "BDBM25832",
      "Hydroxyphenyl Substituted Thiazole, 4",
    ],
  },
  {
    primary_id: "D00YNI",
    names: ["L-(Cis)-Diltiazem"],
  },
  {
    primary_id: "D00YNJ",
    names: ["XB-387"],
  },
  {
    primary_id: "D00YNK",
    names: ["MGN-7455", "Anti-MiR-199 Therapeutic (Ischemic Heart Disease), MiRagen Therapeutics"],
  },
  {
    primary_id: "D00YNW",
    names: ["M826", "Compound 4sx"],
  },
  {
    primary_id: "D00YNY",
    names: ["VPC-94075"],
  },
  {
    primary_id: "D00YOM",
    names: [
      "3-Benzyl-7-Methyl-[1,8]naphthyridin-4-Ol",
      "CHEMBL198760",
      "BDBM50171758",
      "3-Benzyl-7-Methyl-1H-1,8-Naphthyridin-4-One",
    ],
  },
  {
    primary_id: "D00YOV",
    names: ["MT-110"],
  },
  {
    primary_id: "D00YOW",
    names: ["LM-1376"],
  },
  {
    primary_id: "D00YOY",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 2"],
  },
  {
    primary_id: "D00YPH",
    names: ["3-Hydroxy-2-Phenylquinoline-4-Carboxylic Acid"],
  },
  {
    primary_id: "D00YPL",
    names: ["Triplex-Forming Oligonucleotides"],
  },
  {
    primary_id: "D00YPO",
    names: [
      "TETRAHYDROBENXIMIDAZOLE",
      "CHEMBL282765",
      "SCHEMBL10625034",
      "KDEKNYKHIZTSLE-UHFFFAOYSA-N",
      "BDBM50016839",
      "4,5-Dihydro-6-[4-(4,5,6,7-Tetrahydro-1H-Benzimidazol-1-Yl)Phenyl]-5-Methyl-3(2H)-Pyridazinone",
    ],
  },
  {
    primary_id: "D00YPR",
    names: [
      "CP-547632",
      "BFF",
      "CP 547632",
      "CP-547,632",
      "3-(4-Bromo-2,6-Difluorobenzyloxy)-5-(3-(4-Pyrrolidin-1-Ylbutyl)Ureido)Isothiazole-4-Carboxylic Acid Amide",
    ],
  },
  {
    primary_id: "D00YPW",
    names: ["ZAPRINAST"],
  },
  {
    primary_id: "D00YQI",
    names: ["TRC105"],
  },
  {
    primary_id: "D00YQZ",
    names: ["L-702958"],
  },
  {
    primary_id: "D00YRD",
    names: ["Heteroaryl-Azepine Derivative 7"],
  },
  {
    primary_id: "D00YRF",
    names: ["UCB-1277763"],
  },
  {
    primary_id: "D00YRS",
    names: ["PMID26815044-Compound-H"],
  },
  {
    primary_id: "D00YSJ",
    names: [
      "Tecadenoson",
      "CVT-510",
      "204512-90-3",
      "UNII-GZ1X96601Z",
      "CHEMBL392149",
      "GZ1X96601Z",
      "(2R,3S,4R,5R)-2-(Hydroxymethyl)-5-[6-[[(3R)-Oxolan-3-Yl]amino]purin-9-Yl]oxolane-3,4-Diol",
      "BDBM50224766",
      "Tecadenoson [USAN:INN]",
      "Tecadenoson (USAN/INN)",
      "AC1L4KMO",
      "SCHEMBL246787",
      "GTPL5592",
      "CHEMBL356254",
      "DTXSID80174415",
      "BDBM50138530",
      "DB04954",
      "N6-[3-(R)-Tetrahydrofuranyl]adenosine",
      "HY-19661",
      "Adenosine, N-(3R)-Tetrahydro-3-Furanyl-",
      "LS-190860",
      "Adenosine, N-(3R)-Tetrahydro-3-Furanyl)-",
      "CS-0016174",
      "D06019",
      "N-(3-Tetrahydrofuranyl)-6-Aminopurine Riboside",
      "Selenocompounds",
    ],
  },
  {
    primary_id: "D00YTQ",
    names: [
      "BMS-345541",
      "445430-58-0",
      "BMS345541",
      "BMS-345541 Free Base",
      "BMS-345541 (Free Base)",
      "UNII-26SU0NEF5F",
      "N1-(1,8-Dimethylimidazo[1,2-A]quinoxalin-4-Yl)Ethane-1,2-Diamine",
      "26SU0NEF5F",
      "IKK Inhibitor III, BMS-345541",
      "C14H17N5",
      "4-(2&amp",
      "-Aminoethyl)Amino-1,8-Dimethylimidazo[1,2-A]quinoxaline",
      "BMS-345541(Free Base)",
      "IKK Inhibitor III",
      "Kinome_3215",
      "SCHEMBL118886",
      "GTPL5669",
      "CHEMBL249697",
      "CTK8E9618",
      "KS-00001CRN",
      "CHEBI:91340",
      "BDBM25919",
      "DTXSID60196216",
      "PSPFQEBFYXJZEV-UHFFFAOYSA-N",
      "MolPort-035-395-836",
      "HMS3653F06",
      "HMS2043P05",
    ],
  },
  {
    primary_id: "D00YTS",
    names: ["AVP-13546"],
  },
  {
    primary_id: "D00YTX",
    names: ["OG-19"],
  },
  {
    primary_id: "D00YUB",
    names: ["NT-3"],
  },
  {
    primary_id: "D00YUO",
    names: [
      "4-Iodo-Acetamido Phenylboronic Acid",
      "4-IODO-ACETAMIDO PHENYLBORONIC ACID",
      "Boronic Acid, [3-[(Iodoacetyl)Amino]phenyl]-",
      "87199-19-7",
      "AC1NE0SC",
      "[3-[(2-Iodoacetyl)Amino]phenyl]boronic Acid",
      "C8H9BINO3",
      "SCHEMBL7744399",
      "CTK2I2732",
      "DTXSID90405045",
      "AKOS030568788",
      "DB02122",
      "{3-[(Iodoacetyl)Amino]phenyl}boronic Acid",
    ],
  },
  {
    primary_id: "D00YUP",
    names: ["LT-3001"],
  },
  {
    primary_id: "D00YUQ",
    names: ["CART-19 T Cells"],
  },
  {
    primary_id: "D00YUX",
    names: ["Alpha-Conotoxin PnIA"],
  },
  {
    primary_id: "D00YVE",
    names: [
      "1-(2-Naphthyl)-2-Aminopropane",
      "1-(Naphthalen-2-Yl)Propan-2-Amine",
      "CHEMBL471839",
      "AC1Q2BAX",
      "SCHEMBL268036",
      "1-(2-Naphthyl)Propan-2-Amine",
      "1-(2-Naphthyl)-2-Propanamine",
      "CTK6A7105",
      "MolPort-004-323-272",
      "BDBM50276189",
      "AKOS022488613",
      "AKOS000160972",
      "MCULE-4757807613",
      "NE51647",
      "EN300-62738",
    ],
  },
  {
    primary_id: "D00YVF",
    names: ["Exenatide"],
  },
  {
    primary_id: "D00YVJ",
    names: ["N-(3,4-Difluorophenyl)Propyl-ETAV"],
  },
  {
    primary_id: "D00YVL",
    names: ["PMID27537201-Compound-Figure15a"],
  },
  {
    primary_id: "D00YWH",
    names: ["Anti-MRSA", "Anti-MRSA, Shikoku"],
  },
  {
    primary_id: "D00YWL",
    names: [
      "BMS-936557",
      "Anti-CXCL10 Antibody (Iv, Ulcerative Colitis/ Rheumatoid Arthritis/ Inflammation), Medarex/BMS",
      "Anti-IP-10 Antibody (Iv, Ulcerative Colitis/ Rheumatoid Arthritis/ Inflammation), Bristol-Myers Squibb/Medarex",
    ],
  },
  {
    primary_id: "D00YWP",
    names: [
      "Nandrolone",
      "Decadura",
      "Estrenolone",
      "Menidrabol",
      "Nadrolone",
      "Nandrolon",
      "Nandrolona",
      "Nandrolonum",
      "Norandrostenolon;Norandrostenolone",
      "Nortestonate",
      "Nortestosterone",
      "Nortestosteronum",
      "Oestrenolon",
      "LT03330030",
      "U 2410",
      "Decadura (TN)",
      "Durabolin (TN)",
      "Nandrolona [INN-Spanish]",
      "Nandrolone (INN)",
      "Nandrolonum [INN-Latin]",
      "Estr-4-En-3-One, 17beta-Hydroxy-(8CI)",
      "Estr-4-En-3-One, 17-Hydroxy-, (17beta)-(9CI)",
      "Estr-4-En-3-One, 17-Hydroxy-, (17-Beta)-(9CI)",
      "(17-Beta)-17-Hydroxyestr-4-En-3-One",
      "(17beta)-17-Hydroxyestr-4-En-3-One",
      "(8R,9S,10R,13S,14S,17S)-17-Hydroxy-13-Methyl-2,6,7,8,9,10,11,12,14,15,16,17-Dodecahydro-1H-Cyclopenta[a]phenanthren-3-One",
      "17-Hydroxy-Estr-4-Ene-3-One",
      "17-Beta-Hydroestr-4-En-3-One",
      "17beta-Hydroxy-19-Nor-4-Androsten-3-One",
      "17beta-Hydroxy-19-Norandrost-4-En-3-One",
      "17beta-Hydroxy-4-Estren-3-One",
      "17beta-Hydroxyestr-4-En-3-One",
      "19-Norandrostenolone",
      "19-Nortestosterone",
      "4-Estren-17beta-Ol-3-One",
    ],
  },
  {
    primary_id: "D00YWZ",
    names: [
      "3-(4-Aminophenyl)Thieno[3,2-C]pyridin-4-Amine",
      "CHEMBL231153",
      "832694-16-3",
      "Kinome_611",
      "SCHEMBL3531281",
      "CTK3D3472",
      "DTXSID50593932",
      "RSUCBZJSTXEGSF-UHFFFAOYSA-N",
      "BDBM50201316",
      "Thieno[3,2-C]pyridin-4-Amine, 3-(4-Aminophenyl)-",
    ],
  },
  {
    primary_id: "D00YXA",
    names: ["PMID25666693-Compound-2"],
  },
  {
    primary_id: "D00YXE",
    names: ["2-(5-Chloro-1H-Benzo[d]imidazol-2-Yl)Quinoxaline", "CHEMBL198673"],
  },
  {
    primary_id: "D00YXG",
    names: ["Anti-Mesothelin CAR Transduced PBL"],
  },
  {
    primary_id: "D00YXL",
    names: ["2-Pyrazinone Derivative 1"],
  },
  {
    primary_id: "D00YXR",
    names: ["Foxy-5"],
  },
  {
    primary_id: "D00YXU",
    names: [
      "CCX-025",
      "CCX-807",
      "CCR9 Chemokine Receptor-Targeting Therapeutic (Gastrointestinal Diseases), ChemoCentryx",
    ],
  },
  {
    primary_id: "D00YYA",
    names: ["Cyclopentane Carboxamide Derivative 2"],
  },
  {
    primary_id: "D00YYQ",
    names: ["ISIS 183881"],
  },
  {
    primary_id: "D00YZA",
    names: ["Thiotepa"],
  },
  {
    primary_id: "D00YZD",
    names: [
      "Dolutegravir",
      "1051375-16-6",
      "GSK1349572",
      "Tivicay",
      "S/GSK1349572",
      "Dolutegravir (GSK1349572)",
      "S-349572",
      "GSK-1349572",
      "GSK 1349572",
      "UNII-DKO1W9H7M1",
      "(4r,12as)-N-(2,4-Difluorobenzyl)-7-Hydroxy-4-Methyl-6,8-Dioxo-3,4,6,8,12,12a-Hexahydro-2h-Pyrido[1',2':4,5]pyrazino[2,1-B][1,3]oxazine-9-Carboxamide",
      "CHEBI:76010",
      "DKO1W9H7M1",
      "Tivicay (TN)",
      "(4R,12aS)-N-[(2,4-Difluorophenyl)Methyl]-3,4,6,8,12,12a-Hexahydro-7-Hydroxy-4-Methyl-6,8-Dioxo-2H-Pyrido[1',2':4,5]pyrazino[2,1-B][1,3]oxazine-9-Carboxamide",
      "Dolutegravir Sodium (",
    ],
  },
  {
    primary_id: "D00YZP",
    names: [
      "EBS-A42",
      "EBS-C12",
      "Neonatal Group B Streptococcal Vaccine, Emergent",
      "Neonatal Group B Streptococcal Vaccine, Microscience",
      "Vaccine (Neonatal Group B Streptococcus), Emergent",
      "Vaccine (Neonatal Group B Streptococcus), Microscience",
    ],
  },
  {
    primary_id: "D00ZAI",
    names: ["Isoquinolinone Derivative 1"],
  },
  {
    primary_id: "D00ZAJ",
    names: ["2-AMINO-1-METHYL-6-PHENYLIMIDAZO[4,5-B]PYRIDINE"],
  },
  {
    primary_id: "D00ZBH",
    names: ["Alpha-Sulfanyl(4-Fluorobenzyl)Phosphonic Acid"],
  },
  {
    primary_id: "D00ZBL",
    names: ["7-Allyl-2-(4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D00ZBQ",
    names: ["TL-1892"],
  },
  {
    primary_id: "D00ZBZ",
    names: ["OGX-427 + Paclitaxel"],
  },
  {
    primary_id: "D00ZCD",
    names: ["Piperazine Urea Derivative 2"],
  },
  {
    primary_id: "D00ZCN",
    names: [
      "Caspofungin",
      "CASPO",
      "Cancidas",
      "Capsofungin",
      "Caspofungin [INN]",
      "M991",
      "Cancidas (TM)",
      "Cancidas (TN)",
      "Caspofungin (INN)",
      "MK-0991",
      "L-743,872",
      "[1(R)-Hydroxyethyl]-1,4,7,13,16,22-Hexaazatricyclo[22.3.0.0(9,13)]heptacosane-2,5,8,14,17,23-Hexaone Diacetate",
      "Pneumocandin B0, 1-[(4R,5S)-5-[(2-Aminoethyl)Amino]-N2-(10,12-Dimethyl-1-Oxotetradecyl)-4-Hydroxy-L-Ornithine]-5-[(3R)-3-Hydroxy-L-Ornithine]-(9CI)",
      "(4R,5S)-5-((2-Aminoethyl)Amino)-N(Sup 2)-(10,12-Dimethyltetradecanoyl)-4-Hydroxy-L-Ornithyl-L-Threonyl-Trans-4-Hydroxy-L-Prolyl-(S)-4-Hydroxy-4-(P-Hydroxyphenyl)-L-Threonyl-Threo-3-Hydroxy-L-Ornithyl-Trans-3-Hydroxy-L-Proline Cyclic (6-1)-Peptide",
      "(4R,5S)-5-((2-Aminoethyl)Amino)-N(2)-(10,12-Dimethyltetradecanoyl)-4-Hydroxy-L-Ornithyl-L-Threonyl-Trans-4-Hydroxy-L-Prolyl-(S)-4-Hydroxy-4-(P-Hydroxyphenyl)-L-Threonyl-Threo-3-Hydroxy-L-Ornithyl-Trans-3-Hydroxy-L-Proline Cyclic (6-1)-Peptide",
      "1-[(4R,5S)-5-[(2-Aminoethyl)Amino]-N(2)-(10,12-Dimethyl-1-Oxotetradecyl)-4-Hydroxy-L-Ornithine]-5-[(3R)-3-Hydroxy-L-Ornithine]-Pneumocandin B0",
    ],
  },
  {
    primary_id: "D00ZCT",
    names: [
      "BMS-663068",
      "BMS-216",
      "BMS-248",
      "BMS-378806",
      "BMS-626529",
      "BMS-705",
      "BMS-806",
      "BMS-806 Series",
      "BMS-853",
      "HIV-1 Attachment Inhibitors, BMS",
      "HIV-1 Entry Inhibitors, BMS",
      "BMS-488043 Follow-On Compounds, BMS",
      "HIV-1 Attachment Inhibitors, Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D00ZCU",
    names: ["BW723C86", "BW-72386"],
  },
  {
    primary_id: "D00ZDO",
    names: ["SELA-070"],
  },
  {
    primary_id: "D00ZDX",
    names: [
      "CFMMC",
      "1190598-60-7",
      "3-Cyclohexyl-5-Fluoro-6-Methyl-7-[2-(Morpholin-4-Yl)Ethoxy]-4H-Chromen-4-One",
      "GTPL6340",
      "AKOS028114903",
      "ZINC207688804",
    ],
  },
  {
    primary_id: "D00ZEG",
    names: ["Umespirone"],
  },
  {
    primary_id: "D00ZEN",
    names: ["Seasonal Influenza Vaccine"],
  },
  {
    primary_id: "D00ZEY",
    names: ["PMID22533316C4"],
  },
  {
    primary_id: "D00ZFC",
    names: ["WilVent"],
  },
  {
    primary_id: "D00ZFP",
    names: [
      "Estrone",
      "Aquacrine",
      "Crinovaryl",
      "Cristallovar",
      "Crystogen",
      "Destrone",
      "Disynformon",
      "Endofolliculina",
      "Esterone",
      "Estrin",
      "Estrol",
      "Estron",
      "Estrona",
      "Estronum",
      "Estrovarin",
      "Estrugenone",
      "Estrusol",
      "Femidyn",
      "Fermidyn",
      "Folikrin",
      "Folipex",
      "Folisan",
      "Follestrine",
      "Follestrol",
      "Folliculin",
      "Folliculine",
      "Follicunodis",
      "Glandubolin",
      "Hiestrone",
      "Hormestrin",
      "Hormofollin",
      "Hormovarine",
      "Kestrone",
      "Ketodestrin",
      "Ketohydroxyestrin",
      "Ketohydroxyoestrin",
      "Ketophydroxyestrin",
      "Kolpon",
      "Menagen",
      "Menformon",
      "Mestronaq",
      "OESTRONE",
      "Oestrin",
      "Oestroform",
      "Oestronum",
      "Oestroperos",
      "Ovifollin",
      "Perlatan",
      "Solliculin",
      "Theelin",
      "Thelestrin",
      "Thelykinin",
      "Thynestron",
      "Tokokin",
      "Unden",
      "Unigen",
      "Wehgen",
      "Wynestron",
      "Estrogenic Substance",
      "Estrona [Spanish]",
      "Femestrone Injection",
      "Follicular Hormone",
      "Folliculine Benzoate",
      "Hauck Brand Of Estrone",
      "Hyrex Brand Of Estrone",
      "Menformon A",
      "Oestrone [Steroidal Oestrogens]",
      "Penncap M",
      "Vortech Brand Of Estrone",
      "WynestronPencap M",
      "CMC_13458",
      "E 9750",
      "E0026",
      "E(Sub 1)",
      "Estrona [INN-Spanish]",
      "Estrone (E1)",
      "Estrone (TN)",
      "Estrone [USAN:INN]",
      "Estrone-A",
      "Estronum [INN-Latin]",
      "Femestrone Inj.",
      "Ketohydroxy-Estratriene",
      "NATURAL ESTROGENIC SUBSTANCE-ESTRONE",
      "Oestrone, Estrone",
      "Ovex (Tablets)",
      "Unden (Pharmaceutical)",
      "Unden (Pharmaceutical) (VAN)",
      "Estrone (JAN/USP/INN)",
      "Estrone, (8 Alpha)-Isomer",
      "Estrone, (9 Beta)-Isomer",
      "Estrone, (+-)-Isomer",
      "[2,4,6,7-3H]-E1",
      "Delta-1,3,5-Estratrien-3beta-Ol-17-One",
      "Delta-1,3,5-Oestratrien-3beta-Ol-17-One",
      "Delta-1,3,5-Estratrien-3-Beta-Ol-17-One",
      "Delta-1,3,5-Oestratrien-3-Beta-Ol-17-One",
      "(13S)-3-Hydroxy-13-Methyl-7,8,9,11,12,13,15,16-Octahydro-6H-Cyclopenta[a]phenanthren-17(14H)-One",
      "(8R,9S,13S,14S)-3-Hydroxy-13-Methyl-7,8,9,11,12,14,15,16-Octahydro-6H-Cyclopenta[a]phenanthren-17-One",
      "1,3,5(10)-Estratrien-3-Ol-17-One",
      "1,3,5(10)-Oestratrien-3-Ol-17-One",
      "3-Hydroxy-1,3,5(10)-Estratrien-17-One",
      "3-Hydroxy-17-Keto-Estra-1,3,5-Triene",
      "3-Hydroxy-17-Keto-Oestra-1,3,5-Triene",
      "3-Hydroxy-Oestra-1,3,5(10)-Trien-17-One",
      "3-Hydroxyestra-1,3,5(10)-Trien-17-One",
      "3-Hydroxyestra-1,3,5(10)-Triene-17-One",
      "3-Hydroxy-Estra-1,3,5(10)-Trien-17-One",
    ],
  },
  {
    primary_id: "D00ZFQ",
    names: ["H2N3 MO 2003/AA"],
  },
  {
    primary_id: "D00ZFY",
    names: ["Carbamide Derivative 17"],
  },
  {
    primary_id: "D00ZGA",
    names: ["Casopitant"],
  },
  {
    primary_id: "D00ZGE",
    names: ["Oncopurge"],
  },
  {
    primary_id: "D00ZGF",
    names: ["DA-6886", "5-HT4 Agonist (IBS-C), Dong-A"],
  },
  {
    primary_id: "D00ZGG",
    names: ["Azole Derivative 2"],
  },
  {
    primary_id: "D00ZGT",
    names: ["MMR Vaccine"],
  },
  {
    primary_id: "D00ZGW",
    names: ["GSK-325971A", "CHEMBL1093602", "SCHEMBL4938465", "GSK325971A"],
  },
  {
    primary_id: "D00ZHM",
    names: ["Autologous Adipose-Derived Stem Cells", "Cells", "Autologous Adipose-Derived Stem Cells (Anal Fistula)"],
  },
  {
    primary_id: "D00ZHR",
    names: ["Salmonella Typhimurium A1-R"],
  },
  {
    primary_id: "D00ZHX",
    names: ["L-DOS47", "Antibody-Targeted Urease (Cancer), Helix Biopharma/Canadian Research Council"],
  },
  {
    primary_id: "D00ZIK",
    names: ["IC87114"],
  },
  {
    primary_id: "D00ZJG",
    names: ["PMID2231594C3k"],
  },
  {
    primary_id: "D00ZJZ",
    names: ["Glycogen Synthase Kinase-3 Inhibitors"],
  },
  {
    primary_id: "D00ZKC",
    names: ["CS-92", "Antiviral (HIV), Triangle Pharmaceuticals"],
  },
  {
    primary_id: "D00ZKW",
    names: [
      "[3H]CPP",
      "100828-16-8",
      "4-(3-Phosphonopropyl)Piperazine-2-Carboxylic Acid",
      "(RS)-CPP",
      "3-2-Cpp",
      "CARBOXYPEPTIDASE P",
      "3-(2-Carboxypiperazin-4-Yl)Propyl-1-Phosphonic Acid",
      "(+/-)-CPP",
      "4-(3-Phosphonopropyl)-2-Piperazinecarboxylic Acid",
      "CHEMBL22304",
      "DL-3-(2-Carboxypiperazin-4-Yl)-Propyl-1-Phosphonic Acid",
      "4-(3-Phosphono-Propyl)-Piperazine-2-Carboxylic Acid",
      "2-Piperazinecarboxylic Acid, 4-(3-Phosphonopropyl)-",
      "(+)-3-(2-Carboxypiperazin-4-Yl)-Propyl-1-Phosphoric Acid",
      "2-Piperazinecarboxylic Acid, 4-(3-Phosphonopropyl)-, (+-)-",
      "108549-42-4",
    ],
  },
  {
    primary_id: "D00ZLC",
    names: ["NeuroCell-PD"],
  },
  {
    primary_id: "D00ZME",
    names: [
      "1-((9H-Fluoren-2-Yl)Ethyl)-1H-Imidazole",
      "CHEMBL501198",
      "SCHEMBL8609682",
      "QOKBMECMKDLYIV-UHFFFAOYSA-N",
      "BDBM50272209",
      "1-[1-(9H-Fluoren-2-Yl)Ethyl]-1H-Imidazole",
      "1-[1-(9H-Fluoren-2-Yl) Ethyl]-1H-Imidazole",
    ],
  },
  {
    primary_id: "D00ZMK",
    names: ["KNI-10740"],
  },
  {
    primary_id: "D00ZMN",
    names: ["Fluorofenidone", "AKF-PD"],
  },
  {
    primary_id: "D00ZMS",
    names: [
      "1-(But-2-Ylamino)Ethyl 1,I-Bisphosphonic Acid",
      "CHEMBL262598",
      "BDBM50374761",
      "[1-Phosphono-2-(Sec-Butylamino)Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D00ZMU",
    names: ["P28 Cell Penetrating Peptide"],
  },
  {
    primary_id: "D00ZMY",
    names: ["2-Cyclohexylacetic Acidbiphenyl-3-Yl Ester", "CHEMBL505895"],
  },
  {
    primary_id: "D00ZNP",
    names: ["HMR-1426", "6-Chloro-2-Phenyl-8,8a-Dihydro-3aH-Indeno[1,2-D]thiazol-3a-Ol"],
  },
  {
    primary_id: "D00ZNS",
    names: [
      "CDE-5110",
      "CDE-6963",
      "ROCK I/II Inhibitors (Inflammation)",
      "ROCK I/II Inhibitors (Inflammation), Devgen",
    ],
  },
  {
    primary_id: "D00ZOF",
    names: ["Sodium Lactate"],
  },
  {
    primary_id: "D00ZPJ",
    names: [
      "NSC-12999",
      "CHEMBL495567",
      "AC1L8UK1",
      "ZINC4366490",
      "BDBM50265443",
      "6-Chloro-2-Methoxy-N-Pyridin-3-Ylacridin-9-Amine",
      "6-Chloro-2-Methoxy-N-(3-Pyridyl)Acridin-9-Amine",
    ],
  },
  {
    primary_id: "D00ZPR",
    names: ["Neucardin"],
  },
  {
    primary_id: "D00ZPT",
    names: ["BI-11634"],
  },
  {
    primary_id: "D00ZRJ",
    names: [
      "Anti FGFR4 Mab Program",
      "Anti FGFR4 MAb Program (Cancer)",
      "Anti FGFR4 MAb Program (Cancer), AVEO Pharmaceuticals",
      "Anti-Fibroblast Growth Factor Receptor-4 Monoclonal Antibody Program (Cancer), AVEO Pharmaceuticals",
    ],
  },
  {
    primary_id: "D00ZRO",
    names: ["Cyclic Compound 3"],
  },
  {
    primary_id: "D00ZRS",
    names: ["TARP Peptide Vaccines"],
  },
  {
    primary_id: "D00ZSD",
    names: [
      "6-Benzenesulfinylhexanoic Acid Hydroxamide",
      "6-(Benzenesulfinyl)Hexanoic Acid Hydroxyamide",
      "875737-03-4",
    ],
  },
  {
    primary_id: "D00ZSF",
    names: ["AB-1101"],
  },
  {
    primary_id: "D00ZTD",
    names: [
      "OZ277",
      "Arterolane",
      "UNII-3N1TN351VB",
      "664338-39-0",
      "3N1TN351VB",
      "OZ-277",
      "C22H36N2O4",
      "Arterolane [INN]",
      "RBx11160",
      "OZ 277",
      "SCHEMBL4547841",
      "CHEMBL404431",
      "CHEMBL580910",
      "CHEMBL221773",
      "SCHEMBL12612733",
      "CHEMBL1197433",
      "SCHEMBL13951812",
      "SCHEMBL11379522",
      "SCHEMBL10307589",
      "VXYZBLXGCYNIHP-BBMJWGJISA-N",
      "VXYZBLXGCYNIHP-CAGINOIPSA-N",
      "ZINC14191464",
      "RBX-11160",
      "NCGC00274173-01",
      "HY-10852",
      "Y1261",
    ],
  },
  {
    primary_id: "D00ZUQ",
    names: ["ETHYL 3-[4-(AMINOSULFONYL)PHENYL]PROPANOATE"],
  },
  {
    primary_id: "D00ZUU",
    names: [
      "ONO-5334",
      "Bone Resorption Inhibitor (Tablet/Oral, Osteoporosis/Osteopenia), Ono",
      "Cathepsin K Inhibitor (Tablet/Oral Formulation, Osteoporosis/Osteopenia), Ono",
    ],
  },
  {
    primary_id: "D00ZUY",
    names: ["Umirolimus"],
  },
  {
    primary_id: "D00ZVM",
    names: ["BAY1129980"],
  },
  {
    primary_id: "D00ZVS",
    names: ["Condoliase"],
  },
  {
    primary_id: "D00ZVY",
    names: ["Pyrazolopyridine Derivative 2"],
  },
  {
    primary_id: "D00ZWA",
    names: ["P'-Geranyl 3,5,8-Trihydroxy-3-Methyloctanate 8-Diphosphate"],
  },
  {
    primary_id: "D00ZWE",
    names: [
      "AT-007",
      "AT-007, Affitech",
      "Chemokine Receptor Antagonist (Human Monoclonal Antibody, Lymphoid Cancer/Inflammatory Disease/Autoimmune Disease), Affitech",
    ],
  },
  {
    primary_id: "D00ZWG",
    names: [
      "NS-638",
      "150493-34-8",
      "NS638",
      "2-Amino-1-(4-Chlorobenzyl)-5-Trifluoromethylbenzimidazole",
      "1-[(4-Chlorophenyl)Methyl]-5-(Trifluoromethyl)Benzimidazol-2-Amine",
      "1H-Benzimidazol-2-Amine,1-[(4-Chlorophenyl)Methyl]-5-(Trifluoromethyl)-",
      "NS 638",
      "GXQCVUZORDAARJ-UHFFFAOYSA-N",
      "ACMC-20n5zm",
      "AC1L2SIQ",
      "SCHEMBL3083259",
      "ZINC5752",
      "CTK4C6667",
      "DTXSID10164561",
      "MolPort-046-033-572",
      "BCP20747",
      "AKOS032947353",
      "1-((4-Chlorophenyl)Methyl)-5-(Trifluoromethyl)-1H-Benzimidazol-2-Amine",
      "CS-6337",
      "KB-227320",
      "HY-101428",
    ],
  },
  {
    primary_id: "D00ZWU",
    names: [
      "4-Butyl-N-(Quinolin-3-Yl)Benzamide",
      "CHEMBL259385",
      "AC1LXX0M",
      "Oprea1_455948",
      "SCHEMBL846651",
      "4-Butyl-N-Quinolin-3-Ylbenzamide",
      "MolPort-002-564-310",
      "ZINC2228626",
      "STL329914",
      "BDBM50376281",
      "AKOS022106211",
      "MCULE-9844265532",
      "ST020606",
      "(4-Butylphenyl)-N-(3-Quinolyl)Carboxamide",
      "SR-01000396347",
    ],
  },
  {
    primary_id: "D00ZXR",
    names: ["Heteroaryl-Carboxamide Derivative 3"],
  },
  {
    primary_id: "D00ZXX",
    names: ["CYM5442", "CYM-5442"],
  },
  {
    primary_id: "D00ZYK",
    names: ["Indole-3-Propionic Acid"],
  },
  {
    primary_id: "D00ZYP",
    names: ["[3H]TBZOH", "[3H]-TBZOH", "Alpha-[O-Methyl-3H]dihydrotetrabenazine", "[3H]dihydrotetrabenazine"],
  },
  {
    primary_id: "D00ZZB",
    names: ["Oxaborole"],
  },
  {
    primary_id: "D00ZZH",
    names: [
      "BK-218",
      "7-[(3-Chloroisoxazol-5-Yl)Acetamido]-3-[[(1-Methyl-1H-Tetrazol-5-Yl)Thiomethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-En-2-Carboxylic Acid Sodium Salt Dihydrate",
      "7-[2-(3-Chloroisoxazol-5-Yl)Acetamido]-3-(1-Methyl-1H-Tetrazol-5-Ylthiomethyl)-3-Cephem-4-Carboxylic Acid Sodium Salt Dihydrate",
    ],
  },
  {
    primary_id: "D00ZZJ",
    names: ["PMID29130358-Compound-Figure17(12)"],
  },
  {
    primary_id: "D01AAD",
    names: ["NSC-745884"],
  },
  {
    primary_id: "D01AAH",
    names: ["CAR-T Cells Targeting CD22"],
  },
  {
    primary_id: "D01AAI",
    names: ["VUF5574"],
  },
  {
    primary_id: "D01ABR",
    names: ["1-(1-Phenyl-2-(2-Propoxyphenyl)Ethyl)Piperazine", "CHEMBL213754", "SCHEMBL5447617"],
  },
  {
    primary_id: "D01ABX",
    names: ["PF-03382792"],
  },
  {
    primary_id: "D01ACE",
    names: ["BF/PC-13"],
  },
  {
    primary_id: "D01ACH",
    names: ["Peginterferon Beta - Toray Industries"],
  },
  {
    primary_id: "D01ACT",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Propyl Ester",
      "CHEMBL77121",
      "BDBM50116817",
      "4-(Sulfamoyloxy)Benzoic Acid Propyl Ester",
    ],
  },
  {
    primary_id: "D01ADA",
    names: ["N-Propyl-2-Methylnorapomorphine Hydrochloride", "CHEMBL550180"],
  },
  {
    primary_id: "D01ADE",
    names: ["PMID28870136-Compound-46"],
  },
  {
    primary_id: "D01ADH",
    names: ["Evitar"],
  },
  {
    primary_id: "D01ADK",
    names: ["Tyr-D-Pro-Gly-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D01ADL",
    names: [
      "Roledumab",
      "DMATRIA",
      "LFB-R593",
      "R-297",
      "R-593",
      "Anti-D Human Monoclonal Antibody, Laboratoire Francais Du Fractionnement Et Des Biotechnologies",
      "R297 Antibody (Hematological Disease), LFB",
      "R297 Antibody (Hematological Disease), Laboratoire Francais Du Fractionnement Et Des Biotechnologies",
      "Anti-RhD Antibody (Hematological Disease), LFB",
      "Anti-Rhesus D MAB (Hematological Disease), LFB",
    ],
  },
  {
    primary_id: "D01AEF",
    names: ["3F8"],
  },
  {
    primary_id: "D01AET",
    names: ["MPC-150-IM"],
  },
  {
    primary_id: "D01AFG",
    names: ["RG7414"],
  },
  {
    primary_id: "D01AFH",
    names: ["1-(3,4-Dihydroxy-5-Nitrophenyl)-2-Phenoxyethanone", "CHEMBL374904", "SCHEMBL7251537"],
  },
  {
    primary_id: "D01AFN",
    names: ["Pyrrolidine Derivative 3"],
  },
  {
    primary_id: "D01AFV",
    names: [
      "2-(4-Bromo-2-Cyclohexylphenoxy)Acetic Acid",
      "CHEMBL246311",
      "(4-Bromo-2-Cyclohexylphenoxy)Acetic Acid",
      "SCHEMBL4882946",
      "BDBM50213922",
    ],
  },
  {
    primary_id: "D01AGR",
    names: ["1-[5-(4-Chlorophenyl)-2-Furoyl]piperazine", "CHEMBL521211", "BDBM50270649"],
  },
  {
    primary_id: "D01AHI",
    names: ["Dance-501"],
  },
  {
    primary_id: "D01AHL",
    names: ["7-Hydroxy-1,2,9,9a-Tetrahydrofluoren-3-One", "CHEMBL377622"],
  },
  {
    primary_id: "D01AHO",
    names: ["Abarelix"],
  },
  {
    primary_id: "D01AIM",
    names: [
      "HBsAG-HBIG Complex",
      "Complexed Hepatitis B Vaccine, Shanghai Medical University",
      "HBsAG-HBIG Complex (HBV Vaccine)",
      "HBsAG-HBIG Complex (HBV Vaccine), Shanghai Medical University",
    ],
  },
  {
    primary_id: "D01AIN",
    names: ["Fezakinumab"],
  },
  {
    primary_id: "D01AJM",
    names: ["YP-009"],
  },
  {
    primary_id: "D01AJO",
    names: ["Imidazo Bicyclic Iminium Derivative 9"],
  },
  {
    primary_id: "D01AJQ",
    names: ["Isosorbide-Di-(Benzylcarbamate)", "CHEMBL600013"],
  },
  {
    primary_id: "D01AJY",
    names: [
      "Baclofen",
      "Arbaclofen",
      "(R)-Baclofen",
      "69308-37-8",
      "(R)-4-Amino-3-(4-Chlorophenyl)Butanoic Acid",
      "D-Baclofen",
      "(-)-Baclofen",
      "(R)-(-)-Baclofen",
      "R-Baclofen",
      "R-(-)-Baclofen",
      "STX209",
      "(3R)-4-Amino-3-(4-Chlorophenyl)Butanoic Acid",
      "UNII-NYU6UTW25B",
      "L-Baclofen",
      "STX 209",
      "(R)-4-Amino-3-(4-Chlorophenyl)Butyric Acid",
      "NYU6UTW25B",
      "CHEMBL301742",
      "AK109161",
      "Benzenepropanoic Acid, Beta-(Aminomethyl)-4-Chloro-, (R)-",
      "Benzeneporopanoic Acid, (Beta-(Aminomethyl)-4-Chloro-, (BetaR)-",
      "Arbaclofen [USAN:INN]",
      "R-Baclofen RS",
      "Arbaclofen (USAN)",
      "P-Chlorophenyl GABA",
      "ApoBaclofen",
      "Atrofen",
      "Baclofene",
      "BaclofeneIrex",
      "Baclofeno",
      "Baclofenum",
      "Baclon",
      "Baclophen",
      "Baclospas",
      "Clofen",
      "Gabalon",
      "GenBaclofen",
      "Genpharm",
      "Kemstro",
      "Lebic",
      "Lioresal",
      "NuBaclo",
      "PMSBaclofen",
      "ASTA Medica Brand Of Baclofen",
      "Alphapharm Brand Of Baclofen",
      "Apo Baclofen",
      "Apotex Brand Of Baclofen",
      "Ashbourne Brand Of Baclofen",
      "Athena Brand Of Baclofen",
      "Baclofen AWD",
      "Baclofen Alphapharm Brand",
      "Baclofen Apotex Brand",
      "Baclofen Ashbourne Brand",
      "Baclofen Athena Brand",
      "Baclofen Irex Brand",
      "Baclofen Isis Brand",
      "Baclofen Medtronic Brand",
      "Baclofen Novartis Brand",
      "Baclofen Pharmascience Brand",
      "Baclofene Irex",
      "Chlorophenyl GABA",
      "Ciba Geigy Brand Of Baclofen",
      "Gen Baclofen",
      "Irex Brand Of Baclofen",
      "Isis Brand Of Baclofen",
      "Lioresal Intrathecal",
      "Medtronic Brand Of Baclofen",
      "Novartis Brand Of Baclofen",
      "Nu Baclo",
      "Nu Pharm Brand Of Baclofen",
      "PMS Baclofen",
      "Pharmascience Brand Of Baclofen",
      "B 5399",
      "Ba 34647",
      "Ba34647",
      "C 34647Ba",
      "AWD, Baclofen",
      "Apo-Baclofen",
      "Ba-34647",
      "Ba34,647",
      "Baclofen Ciba-Geigy Brand",
      "Baclofen Nu-Pharm Brand",
      "Baclofene [INN-French]",
      "Baclofene-Irex",
      "Baclofeno [INN-Spanish]",
      "Baclofenum [INN-Latin]",
      "CIBA34,647BA",
      "Ciba-Geigy Brand Of Baclofen",
      "DL-Baclofen",
      "GABA, Chlorophenyl",
      "Gen-Baclofen",
      "Kemstro (TN)",
      "Lioresal (TN)",
      "Nu-Baclo",
      "Nu-Baclofen",
      "Nu-Pharm Brand Of Baclofen",
      "PCP-GABA",
      "Pms-Baclofen",
      "Ba-34,647",
      "Baclofen (R,S)",
      "Ciba 34,647-Ba",
      "Baclofen (JP15/USP/INN)",
      "Baclofen [USAN:INN:BAN:JAN]",
      "Beta-(4-Chlorophenyl)Gaba",
      "CIBA-34,647-BA",
      "Beta-(Aminomethyl)-4-Chlorobenzenepropanoic Acid",
      "Beta-(Aminomethyl)-P-Chlorohydrocinnamic Acid",
      "Beta-(P-Chlorophenyl)-Gamma-Aminobutyric Acid",
      "DL-4-Amino-3-P-Chlorophenylbutanoic Acid",
      "Gamma-Amino-Beta-(P-Chlorophenyl)Butyric Acid",
      "Benzenepropanoic Acid, Beta-(Aminomethyl)-4-Chloro-(9CI)",
      "(+-)-Baclofen",
      "(+/-)-BACLOFEN",
      "(+/-)-Beta-(Aminoethyl)-4-Chlorobenzenepropanoic Acid",
      "(+/-)-Beta-(Aminomethyl)-4-Chlorobenzenepropanoic Acid",
      "(Inverted Question Mark)-Baclofen",
      "4-Amino-3-(4-Chlorophenyl)Butanoic Acid",
      "4-Amino-3-(4-Chlorophenyl)Butyric Acid",
      "(R)-4-Amino-3-(4-Chloro-Phenyl)-Butyric Acid",
    ],
  },
  {
    primary_id: "D01AJZ",
    names: [
      "3-(4-Pyridin-2-Yl-Imidazol-1-Yl)-Benzonitrile",
      "CHEMBL363883",
      "SCHEMBL5224021",
      "WFXSTBNPVNZJHP-UHFFFAOYSA-N",
      "BDBM50151900",
      "ZINC13582329",
    ],
  },
  {
    primary_id: "D01AKK",
    names: ["ALXN1101"],
  },
  {
    primary_id: "D01AKU",
    names: ["MCP-201"],
  },
  {
    primary_id: "D01ALG",
    names: ["MCH Antagonist"],
  },
  {
    primary_id: "D01ALJ",
    names: ["BV-6481"],
  },
  {
    primary_id: "D01ALP",
    names: [
      "MBC-31",
      "Conjugated Bisphosphonates (Osteoporosis), MBC Pharma",
      "Alendronate-Vitamin B6 Conjugate (Osteoporosis), MBC Pharma",
    ],
  },
  {
    primary_id: "D01ALS",
    names: [
      "2-Methyl-4-(2-Thienylethynyl)Thiazole",
      "CHEMBL202331",
      "Thiazole, 2-Methyl-4-[2-(2-Thienyl)Ethynyl]-",
      "SCHEMBL4151041",
      "BDBM50181768",
      "878018-56-5",
    ],
  },
  {
    primary_id: "D01AMS",
    names: ["N-(2,3-Dimethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093043", "BDBM50314143"],
  },
  {
    primary_id: "D01AMV",
    names: ["3-(6-Fluoroquinazolin-4-Ylamino)Benzonitrile", "CHEMBL1080128", "BDBM50311855"],
  },
  {
    primary_id: "D01ANE",
    names: ["EB-1,2,3 Mab"],
  },
  {
    primary_id: "D01ANN",
    names: ["SBIM Therapeutics"],
  },
  {
    primary_id: "D01AOI",
    names: ["6-(Piperidin-4-Ylmethoxy)-2-Naphthonitrile", "CHEMBL469552"],
  },
  {
    primary_id: "D01APA",
    names: ["PMID22984835C20o"],
  },
  {
    primary_id: "D01APJ",
    names: ["Anticancer Therapeutic", "Anticancer Therapeutic (Prostate Cancer)"],
  },
  {
    primary_id: "D01APL",
    names: ["Lysergol"],
  },
  {
    primary_id: "D01APZ",
    names: ["(D)Arg-Arg-Pro-Hyp-Gly-Thi-Cys-(D)Phe-Phe-Cys-Arg"],
  },
  {
    primary_id: "D01AQC",
    names: ["Tyr-D-Phe-Gly-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D01AQN",
    names: ["Rapamycin Complexed With Immunophilin FKBP12"],
  },
  {
    primary_id: "D01AQT",
    names: ["Terconazole"],
  },
  {
    primary_id: "D01ARE",
    names: ["Methyl-(5-Pyridin-3-Yl-Thiophen-2-Yl)-Amine", "CHEMBL179529"],
  },
  {
    primary_id: "D01ARG",
    names: ["DB-900"],
  },
  {
    primary_id: "D01ARY",
    names: ["Bbs-Arg-(D-Pip)-Gly-S-(GS)3-Gly-Hir", "CHEMBL414341"],
  },
  {
    primary_id: "D01ASB",
    names: [
      "CGRP-A2 Radioligand Agent",
      "CGRPA2-[3H]",
      "Tritium-CGRPA2",
      "CGRP-A2 Radioligand Agent (Migraine)",
      "CGRP-A2 Radioligand Agent (Migraine), Merck",
      "[3H]-CGRP-A2 Radioligand, Merck",
      "3H-CGRPA2",
    ],
  },
  {
    primary_id: "D01ASJ",
    names: ["Nalbuphine Hydrochloride ER"],
  },
  {
    primary_id: "D01ASM",
    names: [
      "NPC-15199",
      "2-(9H-Fluoren-9-Ylmethoxycarbonylamino)-4-Methylpentanoic Acid",
      "F-L-Leu",
      "CHEMBL294989",
      "PPARgamma Activator, Fmoc-Leu",
      "Fmoc-DL-Leucine",
      "N-Alpha-Fmoc-L-Leucine",
      "NSC334290",
      "ACMC-209iik",
      "AC1L1HVH",
      "N-[(9h-Fluoren-9-Ylmethoxy)Carbonyl]leucin",
      "ACMC-2099ky",
      "AC1Q5SK7",
      "126727-03-5",
      "2-(9H-Fluoren-9-Ylmethoxycarbonylamino)-4-Methyl-Pentanoic Acid",
      "(2S)-2-[(Fluoren-9-Ylmethoxy)Carbonylamino]-4-Methylpentanoic Acid",
      "GTPL2705",
      "SCHEMBL1002861",
      "CHEBI:91726",
      "CTK8G6077",
      "MolPort-003-700-319",
      "HMS3266H19",
      "SBB056939",
      "IN1334",
      "BDBM50034787",
      "AKOS017268980",
    ],
  },
  {
    primary_id: "D01ATS",
    names: ["LEO-90110"],
  },
  {
    primary_id: "D01ATW",
    names: [
      "Adenovirus Gene Therapy (Maroteux-Lamy Syndrome)",
      "Adenovirus Gene Therapy (Maroteux-Lamy Syndrome (Mucopolysaccharidosis Type VI)), Fondazione Telethon",
    ],
  },
  {
    primary_id: "D01AUI",
    names: ["SF303"],
  },
  {
    primary_id: "D01AUL",
    names: ["L-697639"],
  },
  {
    primary_id: "D01AUS",
    names: ["4-[4-Benzyloxy)Benzoyl]benzoic Acid", "CHEMBL202871"],
  },
  {
    primary_id: "D01AUX",
    names: [
      "2-(3-Biphenyl-4-Yl-Propionylamino)-Benzoic Acid",
      "CHEMBL235741",
      "SCHEMBL12671515",
      "BDBM23519",
      "Biaryl Anthranilide Analogue, 1c",
      "2-[3-(4-Phenylphenyl)Propanamido]benzoic Acid",
    ],
  },
  {
    primary_id: "D01AVF",
    names: ["SAR-106881"],
  },
  {
    primary_id: "D01AVM",
    names: ["Ethyl Estrone-16-Methylcarboxylate"],
  },
  {
    primary_id: "D01AVS",
    names: ["N8-Hydroxy-2-Methoxy-N1-Phenyloctanediamide", "CHEMBL251010", "SCHEMBL8158442"],
  },
  {
    primary_id: "D01AWD",
    names: [
      "Alpha-D-Glucose-6-Phosphate",
      "6-O-Phosphono-Alpha-D-Glucopyranose",
      "UNII-03JQ7I74CO",
      "03JQ7I74CO",
      "CHEBI:17665",
      "Alpha-D-Glucopyranose, 6-(Dihydrogen Phosphate)",
      "Alpha-D-Glucopyranose 6-(Dihydrogen Phosphate)",
      "15209-11-7",
      "Alpha-D-Glucopyranose 6-Phosphate",
      "G6P",
      "1gpy",
      "Epitope ID:144999",
      "SCHEMBL6598",
      "AC1L971Q",
      "NBSCHQHZLSJFNQ-DVKNGEFBSA-N",
      "CHEBI:136602",
      "ZINC3875375",
      "DB02007",
      "C00668",
      "WURCS=2.0/1,1,0/[a2122h-1a_1-5_6*OPO/3O/3=O]/1/",
    ],
  },
  {
    primary_id: "D01AWE",
    names: ["Acetyl CoA", "Acetyl Coenzyme A", "S-Acetyl Coenzyme A", "AcetylCoA", "Acetyl-CoA"],
  },
  {
    primary_id: "D01AWH",
    names: ["PMID25726713-Compound-45"],
  },
  {
    primary_id: "D01AXA",
    names: ["Ac-Asp-Arg-Leu-Asp-Ser-OH", "CHEMBL238484", "Acetyl-Asp-Arg-Leu-Asp-Ser-OH", "ZINC28869420"],
  },
  {
    primary_id: "D01AXB",
    names: ["Duloxetine"],
  },
  {
    primary_id: "D01AXK",
    names: ["Antibodie Derivative 10"],
  },
  {
    primary_id: "D01AXT",
    names: ["NP-50301"],
  },
  {
    primary_id: "D01AYF",
    names: [
      "ND-1.1",
      "Avian Influenza Vaccine, West Coast Biologicals",
      "Ad-HA-DsRNA",
      "H5N1 Avian Influenza Vaccine (Oral, Adenoviral Vector,dsRNA Adjuvant), Vaxart",
    ],
  },
  {
    primary_id: "D01AYJ",
    names: [
      "Terbinafine",
      "Bramazil",
      "Lamasil",
      "TerbiFoam",
      "Terbina",
      "Lamisil AT",
      "Lamisil Tablet",
      "Ternbinafine HCl",
      "Lamasil (TN)",
      "Lamisil (TN)",
      "SF 86-327",
      "Terbisil (TN)",
      "Zabel (TN)",
      "SF-86-327",
      "Terbinafine (USAN/INN)",
      "Terbinafine [USAN:BAN:INN]",
      "Lamisil, Terbinex, Corbinal, Zabel, Terbinafine",
      "Terbinafine, SF-86-327, Lamisil, TBNF",
      "(2E)-N,6,6-Trimethyl-N-(1-Naphthylmethyl)-2-Hepten-4-Yn-1-Amine",
      "(2E)-N,6,6-Trimethyl-N-(Naphthalen-1-Ylmethyl)Hept-2-En-4-Yn-1-Amine",
      "(E)-N,6,6-Trimethyl-N-(Naphthalen-1-Ylmethyl)Hept-2-En-4-Yn-1-Amine",
      "(E)-N-(6,6-Dimethyl-2-Hepten-4-Ynyl)-N-Methyl-1-Naphthalene Methanamine",
      "(E)-N-(6,6-Dimethyl-2-Hepten-4-Ynyl)-N-Methyl-1-Naphthalenemethylamine",
      "(E)-N-(6,6-Dimethyl-2-Heptenynyl)-N-Methyl-1-Naphthalenementhamin Hydrochloride",
    ],
  },
  {
    primary_id: "D01AYP",
    names: ["Bromo Benzyl Ether Derivative 1"],
  },
  {
    primary_id: "D01AYX",
    names: ["AZD3293", "CHEMBL2152914", "SCHEMBL9948518", "SCHEMBL9948271", "SCHEMBL18562845", "BDBM50393099"],
  },
  {
    primary_id: "D01AZG",
    names: [
      "Risperidone",
      "Belivon",
      "Risperdal",
      "Risperdone",
      "Risperidal",
      "Risperidona",
      "Risperidonum",
      "Risperin",
      "Rispolept",
      "Rispolin",
      "Sequinan",
      "Risperdal Consta",
      "Risperidona [Spanish]",
      "Risperidonum [Latin]",
      "R 62 766",
      "R 64766",
      "R64766",
      "Consta, Risperdal",
      "KS-1106",
      "R 64,766",
      "R-118",
      "R-64766",
      "R64,766",
      "Risperdal (TN)",
      "Risperdal M-Tab",
      "Risperidal M-Tab",
      "Risperidone (RIS)",
      "Risperidone, Placebo",
      "R 64 766, Risperdal, Risperidone",
      "R-64,766",
      "R-64-766",
      "Risperidone [USAN:BAN:INN]",
      "Risperidone (JAN/USAN/INN)",
      "3-(2-(4-(6-Fluoro-1,2-Benzisoxazol-3-Yl)Piperidino)Ethyl)-6,7,8,9-Tetrahydro-2-Methyl-4H-Pyrido(1,2-A)Pyrimidin-4-One",
      "3-[2-[-4-(6-Fluoro-1,2-Benzisoxazol-3-Yl)-1-Piperidinyl]ethyl]-6,7,8,9-Tetrahydro-2-Methyl-4H-Pyrido[1,2-A]pyrimidin-4-One",
      "3-[2-[4-(6-Fluoro-1,2-Benzisoxazol-3-Yl)-1-Piperidinyl]-6,7,8,9-Tetrahydro-2-Methyl-4H-Pyrido [1,2-A] Pyrimidin-4-One",
      "3-[2-[4-(6-Fluoro-1,2-Benzisoxazol-3-Yl)Piperi-Dino]ethyl]-6,7,8,9-Tetrahydro-2-Methyl-4H-Pyrido[1,2-A]-Pyrimidin-4-One",
      "3-[2-[4-(6-Fluoro-1,2-Benzisoxazol-3-Yl)-1-Piperidinyl]ethyl]-6,7,8,9-Tetrahydro-2-Methyl-4H-Pyrido[1,2-A]pyrimidin-4-One",
      "3-[2-[4-(6-Fluoro-1,2-Benzoxazol-3-Yl)Piperidin-1-Yl]ethyl]-2-Methyl-6,7,8,9-Tetrahydropyrido[1,2-A]pyrimidin-4-One",
      "3-{2-[4-(6-Fluoro-Benzo[d]isoxazol-3-Yl)-Piperidin-1-Yl]-Ethyl}-2-Methyl-6,7,8,9-Tetrahydropyrido[1,2-A]pyrimidin-4-One",
      "3-{2-[4-(6-Fluoro-1,2-Benzisoxazol-3-Yl)Piperidin-1-Yl]ethyl}-2-Methyl-6,7,8,9-Tetrahydro-4H-Pyrido[1,2-A]pyrimidin-4-One",
      "3-{2-[4-(6-Fluoro-1,2-Benzoxazol-3-Yl)Piperidin-1-Yl]ethyl}-2-Methyl-6,7,8,9-Tetrahydro-4H-Pyrido[1,2-A]pyrimidin-4-One",
    ],
  },
  {
    primary_id: "D01AZJ",
    names: [
      "[18F]FPIT",
      "GTPL6392",
      "5-{1-[2-(18F)Fluoropyridin-3-Yl]-5-Methyl-1H-1,2,3-Triazol-4-Yl}-2-(Propan-2-Yl)-2,3-Dihydro-1H-Isoindol-1-One",
    ],
  },
  {
    primary_id: "D01AZP",
    names: [
      "BNC-105",
      "BNC-105P",
      "IL-0035",
      "IL-0049",
      "Combretastatin A4 Analogs (Cancer), Bionomics",
      "VDAs (Cancer), Bionomics",
      "Vascular Disruption Agents (Cancer), Bionomics",
      "Vascular Targeting Agents (Cancer), Bionomics",
    ],
  },
  {
    primary_id: "D01BAG",
    names: [
      "ACET",
      "CHEMBL373429",
      "3-({3-[(2s)-2-Amino-2-Carboxyethyl]-5-Methyl-2,6-Dioxo-3,6-Dihydropyrimidin-1(2h)-Yl}methyl)-5-Phenylthiophene-2-Carboxylic Acid",
      "(S)-1-(2-Amino-2-Carboxyethyl)-3-(2-Carboxy-5-Phenylthiophene-3-Yl-Methyl)-5-Methylpyrimidine-2,4-Dione",
      "GTPL4123",
      "MolPort-023-276-559",
      "ZINC35324127",
      "BDBM50207591",
      "AKOS024457262",
      "NCGC00378702-01",
      "UBE",
      "3-({3-[(2S)-2-Amino-2-Carboxyethyl]-5-Methyl-2,6-Dioxo-1,2,3,6-Tetrahydropyrimidin-1-Yl}methyl)-5-Phenylthiophene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D01BAK",
    names: [
      "2,7-Bis[3-(Piperidino)Propionamido]anthraquinone",
      "CHEMBL89250",
      "SCHEMBL4300594",
      "OFUHVIUIKBRCMP-UHFFFAOYSA-N",
      "BDBM50068321",
    ],
  },
  {
    primary_id: "D01BAU",
    names: ["SPN-812"],
  },
  {
    primary_id: "D01BBB",
    names: ["VM-1500"],
  },
  {
    primary_id: "D01BBR",
    names: ["Dezinamide", "ADD-94057", "ADDR-84116", "AHR-11748", "AN-051"],
  },
  {
    primary_id: "D01BCI",
    names: [
      "D-Alanine",
      "338-69-2",
      "H-D-Ala-OH",
      "(2R)-2-Aminopropanoic Acid",
      "(R)-Alanine",
      "(R)-2-Aminopropanoic Acid",
      "D-2-Aminopropionic Acid",
      "D-Alpha-Alanine",
      "Alanine, D-",
      "Alanine D-Form",
      "D-Alanin",
      "(R)-2-Aminopropionic Acid",
      "D-Alpha-Aminopropionsaeure",
      "(R)-2-Aminopropionsaeure",
      "Ba 2776",
      "D-Alpha-Aminopropionic Acid",
      "UNII-E3UDS4613U",
      "D-.alpha.-Alanine",
      "D-(-)-Alanine",
      "CHEMBL66693",
      "E3UDS4613U",
      "CHEBI:15570",
      "QNAYBMKLOCPYGJ-UWTATZPHSA-N",
      "MFCD00008077",
      "DAL",
      "NCGC00024493-02",
      "D-Alanine, 99+%",
      "DSSTox_RID_81027",
      "DSSTox_CID_25649",
      "DSSTox_GSID_45649",
    ],
  },
  {
    primary_id: "D01BCK",
    names: [
      "N-Hydroxy-1-Naphthamide",
      "1-Naphthohydroxamic Acid",
      "6953-61-3",
      "1-NAPHTHALENECARBOXAMIDE, N-HYDROXY-",
      "NSC 57457",
      "N-Hydroxynaphthalene-1-Carboxamide",
      "Alpha-Naphthohydroxamic Acid",
      "A-Naphthohydroxamic Acid",
      "BRN 2094470",
      "CHEMBL115468",
      "Naphthalene-1-Carboxylic Acid Hydroxyamide",
      "8GK",
      "Naphthylhydroxamic Acid",
      "AC1L2M8D",
      "N-Hydroxy-1-Naphthamide #",
      "4-09-00-02407 (Beilstein Handbook Reference)",
      ".alpha.-Naphthohydroxamic Acid",
      "ACMC-209o98",
      "SCHEMBL2230679",
      "CTK5D0341",
      "DTXSID30219789",
      "JRZGPWOEHDOVMC-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D01BCL",
    names: [
      "(+/-)-2-Amino-1,2,3,4-Tetrahydro-2-Naphthoic Acid",
      "2-Aminotetralin-2-Carboxylic Acid",
      "74444-77-2",
      "D,L-2-Aminotetralin-2-Carboxylic Acid",
      "2-Amino-1,2,3,4-Tetrahydronaphthalene-2-Carboxylic Acid",
      "6331-63-1",
      "CHEMBL8708",
      "2-Amino-3,4-Dihydro-1H-Naphthalene-2-Carboxylic Acid",
      "2-Amino-1,2,3,4-Tetrahydro-Naphthalene-2-Carboxylic Acid",
      "2-AMINO-1,2,3,4-TETRAHYDRO-2-NAPHTHALENECARBOXYLIC ACID",
      "2-Naphthalenecarboxylic Acid, 2-Amino-1,2,3,4-Tetrahydro-",
      "ACMC-20apiw",
      "ACMC-20apfh",
      "CB 1647",
      "AC1L5ULH",
      "SCHEMBL268919",
      "NIOSH/QL2303010",
    ],
  },
  {
    primary_id: "D01BCQ",
    names: [
      "Divaplon",
      "90808-12-1",
      "UNII-4AOV43246G",
      "4AOV43246G",
      "Divaplonum",
      "Divaplone",
      "Methanone,(6-Ethyl-7-Methoxy-5-Methylimidazo[1,2-A]pyrimidin-2-Yl)Phenyl-",
      "Divaplon [INN]",
      "Divaplonum [INN-Latin]",
      "Divaplone [INN-French]",
      "SCHEMBL678101",
      "CHEMBL281164",
      "ZINC1325",
      "DTXSID40238269",
      "AC1L2418",
      "RU-32698",
      "KB-274411",
      "6-Ethyl-7-Methoxy-5-Methylimidazo(1,2-A)Pyrimidin-2-Yl Phenyl Ketone",
      "(6-Ethyl-7-Methoxy-5-Methylimidazo[1,2-A]pyrimidin-2-Yl)-Phenylmethanone",
      "Methanone, (6-Ethyl-7-Methoxy-5-Methylimidazo(1,2-A)Pyrimidin-2-Yl)Phenyl-",
    ],
  },
  {
    primary_id: "D01BDJ",
    names: [
      "Diphenyltetrahydrofuran",
      "2,2-Diphenyltetrahydrofuran",
      "887-15-0",
      "2,2-Diphenyloxolane",
      "NSC89761",
      "EINECS 212-957-3",
      "2,2-Di(Phenyl)Oxolane",
      "AC1L2DIK",
      "AC1Q1IAM",
      "AC1Q70T2",
      "GTPL2498",
      "SCHEMBL1569110",
      "Furan,tetrahydro-2,2-Diphenyl-",
      "CTK5G1253",
      "DTXSID70237245",
      "Furan, Tetrahydro-2,2-Diphenyl-",
      "ZINC1575610",
      "NSC-89761",
      "AKOS024323254",
      "MCULE-6052973560",
      "X4640",
    ],
  },
  {
    primary_id: "D01BDY",
    names: ["Axomadol"],
  },
  {
    primary_id: "D01BEL",
    names: ["Blincytoblinatumomab"],
  },
  {
    primary_id: "D01BEX",
    names: ["PRS-060"],
  },
  {
    primary_id: "D01BFA",
    names: ["BIK-381", "BIK-380", "Non-Retinoid, Small Molecule Chaperone (Retinitis Pimentosa), BIKAM"],
  },
  {
    primary_id: "D01BGG",
    names: ["Isoflavone Derivative 5"],
  },
  {
    primary_id: "D01BGK",
    names: ["1-(4-Methoxyoxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL526161", "SCHEMBL2163269"],
  },
  {
    primary_id: "D01BGU",
    names: ["UX-002"],
  },
  {
    primary_id: "D01BGW",
    names: ["(2S)-1-(6-Methoxy-1H-Indazol-1-Yl)Propan-2-Amine", "(S)-2-(6-Methoxyindazol-1-Yl)-1-Methylethylamine"],
  },
  {
    primary_id: "D01BGY",
    names: ["PMID16766089C(R)-4d"],
  },
  {
    primary_id: "D01BHC",
    names: [
      "BTA-929",
      "BTA-938",
      "BTA-978",
      "Dimeric Zanamivir, Biota",
      "FLUNET Compounds, Biota",
      "Dimeric Zanamivir, Biota/GSK",
      "Dimeric Zanamivir, Biota/Sankyo",
      "FLUNET Compounds, Biota/ Daiichi Sankyo",
      "FLUNET Compounds, Biota/GSK",
      "FLUNET Compounds, Biota/Sankyo",
      "LANI, Biota/Sankyo",
      "Long Acting Neuraminidase Inhibitors, Biota/Sankyo",
      "Second Generation Influenza Program, Biota/Sankyo",
    ],
  },
  {
    primary_id: "D01BIQ",
    names: ["Human Embryonic Stem Cell-Derived Motor Neuron Progenitors"],
  },
  {
    primary_id: "D01BIZ",
    names: ["Z-Arg-Leu-Val-Agly-Trp-Val-Ala-NH2"],
  },
  {
    primary_id: "D01BJB",
    names: ["Pregnenolone-16alpha-Carbonitrile", "16alpha-Carbonitrile, Pregnenolone"],
  },
  {
    primary_id: "D01BJK",
    names: ["Mimovax"],
  },
  {
    primary_id: "D01BJO",
    names: ["GABA Mimetics"],
  },
  {
    primary_id: "D01BJS",
    names: ["HL-028"],
  },
  {
    primary_id: "D01BJZ",
    names: ["SNAP-5036"],
  },
  {
    primary_id: "D01BKD",
    names: ["Birch Peptide Allergy Desensitization Agent"],
  },
  {
    primary_id: "D01BKS",
    names: ["Ginsenoside Rg3", "(20S)-Ginsenoside Rg3"],
  },
  {
    primary_id: "D01BMA",
    names: [
      "BELFOSDIL",
      "103486-79-9",
      "2-(2-Phenoxyethyl)-1,3-Propylenediphosphonic Acid Tetrabutyl Ester",
      "BMY-21891",
      "Belfosdil < Rec INN",
      "SR-7037",
    ],
  },
  {
    primary_id: "D01BMX",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 8"],
  },
  {
    primary_id: "D01BOD",
    names: ["Pro-Islet"],
  },
  {
    primary_id: "D01BOE",
    names: ["44-Bu"],
  },
  {
    primary_id: "D01BOJ",
    names: ["I.V. Carbamazepine"],
  },
  {
    primary_id: "D01BPF",
    names: ["Avitriptan", "Avitriptan Fumarate", "BMS-180048"],
  },
  {
    primary_id: "D01BPI",
    names: ["Salvinorin B Fluoromethyl Ether", "CHEMBL270844"],
  },
  {
    primary_id: "D01BPP",
    names: ["EC0225"],
  },
  {
    primary_id: "D01BPR",
    names: [
      "1-Benzylpiperidine Hydrochloride",
      "6295-81-4",
      "Piperidine, 1-Benzyl-, Hydrochloride",
      "1-Benzyl-Piperidine HCl",
      "Piperidine, 1-(Phenylmethyl)-, Hydrochloride",
      "AC1L46RA",
      "Benzylpiperidine Hydrochloride",
      "CHEMBL555311",
      "SCHEMBL7409551",
      "2905-56-8 (Parent)",
      "DTXSID70212153",
      "NSC49527",
      "NSC-49527",
      "LS-114289",
      "KB-218184",
    ],
  },
  {
    primary_id: "D01BQC",
    names: [
      "Cefonicid",
      "Cefonicido",
      "Cefonicidum",
      "Monocef",
      "Praticef",
      "Cefonicid Monosodium Salt",
      "Sodium Cefonicid",
      "Cefonicid (INN)",
      "Cefonicid [INN:BAN]",
      "Cefonicido [INN-Spanish]",
      "Cefonicidum [INN-Latin]",
      "Monocef (TN)",
      "SK&F-75073",
      "SKF-75073-2",
      "(6R,7R)-7-[(2-Hydroxy-2-Phenylacetyl)Amino]-8-Oxo-3-[[1-(Sulfomethyl)Tetrazol-5-Yl]sulfanylmethyl]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2R)-2-Hydroxy-2-Phenylacetyl]amino]-8-Oxo-3-[[1-(Sulfomethyl)Tetrazol-5-Yl]sulfanylmethyl]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-Hydroxy-2-Phenylacetyl]amino}-8-Oxo-3-({[1-(Sulfomethyl)-1H-Tetrazol-5-Yl]sulfanyl}methyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-Hydroxy-2-Phenylacetyl]amino}-8-Oxo-3-({[1-(Sulfomethyl)-1H-Tetrazol-5-Yl]thio}methyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(7R)-7-(2-Hydroxy-2-Phenylacetylamino)-6-Oxo-3-{[1-(Sulfomethyl)(1,2,3,4-Tetraazol-5-Ylthio)]methyl}-2H,7H,7aH-Azetidino[2,1-B]1,3-Thiazine-4-Carboxylic Acid",
      "6beta-[(2R)-2-Hydroxy-2-Phenylacetamido]-3-({[1-(Sulfomethyl)-1H-Tetrazol-5-Yl]sulfanyl}methyl)Ceph-3-Em-4-Carboxylic Acid",
      "7-[(2-Hydroxy-2-Phenylacetyl)Amino]-8-Oxo-3-[[1-(Sulfomethyl)Tetrazol-5-Yl]sulfanylmethyl]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D01BQD",
    names: ["2-(4-Phenoxy-Benzyl)-3H-Benzoimidazol-5-Ylamine", "CHEMBL65119"],
  },
  {
    primary_id: "D01BQI",
    names: [
      "1-(3-Methoxy-Benzyl)-5-Methyl-1H-Indole-2,3-Dione",
      "CHEMBL324722",
      "BDBM50133632",
      "AKOS000245789",
      "VU0238422-1",
      "1-(3-Methoxybenzyl)-5-Methylindoline-2,3-Dione",
      "1-(3-Methoxybenzyl)-5-Methyl-1h-Indole-2,3-Dione",
    ],
  },
  {
    primary_id: "D01BQJ",
    names: ["TCV-295", "2-(6-Bromo-7-Chloro-2,2-Dimethyl-2H-1,3-Benzoxazin-4-Yl)Pyridine N-Oxide"],
  },
  {
    primary_id: "D01BQK",
    names: ["Carbamide Peroxide"],
  },
  {
    primary_id: "D01BRD",
    names: ["SGN-CD123A"],
  },
  {
    primary_id: "D01BRX",
    names: ["IMM-252"],
  },
  {
    primary_id: "D01BSX",
    names: ["Ethynyl Compound 7"],
  },
  {
    primary_id: "D01BTF",
    names: ["Melanin-Concentrating Hormone"],
  },
  {
    primary_id: "D01BTV",
    names: [
      "Aminooxyacetic Acid",
      "(Aminooxy)Acetic Acid",
      "2-(Aminooxy)Acetic Acid",
      "AOAA",
      "Carboxymethoxylamine",
      "645-88-5",
      "Carboxymethoxyamine",
      "ACETIC ACID, (AMINOOXY)-",
      "Aminooxyacetate",
      "Aminooxy-Acetic Acid",
      "(Carboxymethoxy)Amine",
      "(O-Carboxymethyl)Hydroxylamine",
      "Aminoxyacetic Acid",
      "UNII-14I68GI3OQ",
      "BRN 0878238",
      "C2H5NO3",
      "14I68GI3OQ",
      "U 7524",
      "NQRKYASMKDDGHT-UHFFFAOYSA-N",
      "U-7524",
      "AOA",
      "Aminooxyacetic Acid Hemihydrochloride",
      "Carboxymethoxylamine Hemihydrochloride",
      "Aminoxy-Acetic Acid",
      "Amino-Oxyacetic Acid",
      "AOA Hemihydrochloride",
      "2-Aminooxyacetic Acid",
    ],
  },
  {
    primary_id: "D01BUB",
    names: [
      "2,5-Difluorophenol",
      "2713-31-7",
      "Phenol, 2,5-Difluoro-",
      "1,4-Difluoro-2-Hydroxybenzene",
      "Phenol,2,5-Difluoro-",
      "INXKVYFOWNAVMU-UHFFFAOYSA-N",
      "2,5-Difluorphenol",
      "MFCD00042501",
      "2,5-Difluorophenol;2,5-Difluorophenol;",
      "NSC10288",
      "PubChem3464",
      "Phenol Derivative, 5",
      "2,5-Difluoro-Phenol",
      "2,5-Difluoro Phenol",
      "ACMC-209gva",
      "2,5-Difluorophenol 97%",
      "AC1Q78DB",
      "SCHEMBL70273",
      "2,5-Difluorophenol, 95%",
      "KSC493C1H",
      "CHEMBL261219",
      "AC1L3T96",
      "INXKVYFOWNAVMU-UHFFFAOYSA-",
      "BDBM26191",
      "CTK3J3113",
      "DTXSID90181594",
      "MolPort-000-154-447",
      "ZINC409280",
      "CS-D1368",
      "JRD-0251",
    ],
  },
  {
    primary_id: "D01BUS",
    names: ["CA9-ADC"],
  },
  {
    primary_id: "D01BUT",
    names: [
      "LXT-101",
      "DepoLXT-101",
      "GnRH Antagonist (Sustained Release, Prostate Cancer), Beijing Institute Of Pharmacology And Toxicology",
    ],
  },
  {
    primary_id: "D01BVI",
    names: ["V-101"],
  },
  {
    primary_id: "D01BVR",
    names: ["5-(4-Piperidyl)-4-Propylisothiazol-3-Ol", "CHEMBL382791"],
  },
  {
    primary_id: "D01BVU",
    names: ["Platinum IV Complexe 1"],
  },
  {
    primary_id: "D01BWJ",
    names: [
      "Tyrophostins",
      "CP-2005",
      "Tyrophostins (Cancer)",
      "WP-1130",
      "Degrasyns (Cancer), Callisto/MD Anderson Cancer Center",
      "Jak2 And Bcr/Abl Protein Destabiliser (Chronic Myelogenous Leukemia), MD Anderson Cancer Center",
      "Tyrophostins (Cancer), Callisto/MD Anderson Cancer Center",
      "C-Myc/Bcr-Abl/Jak-2 Inhibitors (Cancer), MD Anderson Cancer Center/Callisto",
    ],
  },
  {
    primary_id: "D01BWV",
    names: ["BN-81,644", "BN 81,644", "BN81,644", "BN-81644", "BN81644", "BN 81644"],
  },
  {
    primary_id: "D01BXA",
    names: ["PMID27607364-Compound-65"],
  },
  {
    primary_id: "D01BXE",
    names: [
      "CITCO",
      "338404-52-7",
      "UNII-D77H8321PB",
      "6-(4-CHLOROPHENYL)IMIDAZO[2,1-B][1,3]THIAZOLE-5-CARBALDEHYDE O-(3,4-DICHLOROBENZYL)OXIME",
      "CHEMBL458603",
      "D77H8321PB",
      "6-(4-Chlorophenyl)Imidazo[2,1-B][1,3]thiazole-5-Carbaldehyde-O-(3,4-Dichlorobenzyl)Oxime",
      "6-(4-Chlorophenyl)Imidazo(2,1-B)(1,3)Thiazole-5-Carbaldehyde O-(3,4-Dichlorobenzyl)Oxime",
      "CITCO Cpd",
      "Spectrum5_001974",
      "BSPBio_001028",
      "SCHEMBL241281",
      "DTXSID4040761",
      "CHEBI:92928",
      "BCBcMAP01_000217",
      "6-[4-Chlorophenyl]imidazo[2,1-B][1,3]thiazole-5-Carbaldehydeo-[3,4-Dichlorobenzyl]oxime",
    ],
  },
  {
    primary_id: "D01BXV",
    names: ["PMID27977313-Compound-46"],
  },
  {
    primary_id: "D01BYB",
    names: [
      "Adenosine Triphosphate",
      "Adenylpyrophosphate",
      "Triphosadenine",
      "Triphosadenine (DCF)",
      "Adenosine 5'-Triphosphate(4-)",
      "[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] Phosphono Hydrogen Phosphate",
      "[[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Oxidophosphoryl]oxy-Oxidophosphoryl] Phosphate",
      "[[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxy-Oxolan-2-Yl]methoxy-Hydroxy-Phosphoryl]oxy-Hydroxy-Phosphoryl]oxyphosphonic Acid",
      "3b2q",
      "5-(6-Aminopurin-9-Yl)-3,4-Dihydroxy-Oxolan-2-Ylmethoxy-Hydroxy-Phosphoryloxy-Hydroxy-Phosphoryl Oxyphosphonic Acid",
      "9H-Purin-6-Amine, 9-[5-O-[hydroxy[[hydroxy(Phosphonooxy)Phosphinyl]oxy]phosphinyl]",
    ],
  },
  {
    primary_id: "D01BZE",
    names: ["MASLINIC ACID"],
  },
  {
    primary_id: "D01BZH",
    names: ["C[-Arg-Gly-Asp-Acpca21-]", "CHEMBL534711"],
  },
  {
    primary_id: "D01BZP",
    names: ["Tuberculosis Therapy, Ithemba"],
  },
  {
    primary_id: "D01BZR",
    names: ["IC-041"],
  },
  {
    primary_id: "D01CAC",
    names: ["(4-Methoxyphenyl)(4-Phenylazepan-1-Yl)Methanone", "CHEMBL562195", "SCHEMBL19248204"],
  },
  {
    primary_id: "D01CAJ",
    names: ["AZD-3342"],
  },
  {
    primary_id: "D01CAY",
    names: ["2,5-Bis-(3,4-Dimethoxy-Phenyl)-Furan", "CHEMBL110937"],
  },
  {
    primary_id: "D01CBE",
    names: ["Ygalo"],
  },
  {
    primary_id: "D01CBG",
    names: ["SRP-5051"],
  },
  {
    primary_id: "D01CBK",
    names: ["OSE2101"],
  },
  {
    primary_id: "D01CBU",
    names: ["FG-020318"],
  },
  {
    primary_id: "D01CBV",
    names: [
      "C-5997",
      "7709-58-2",
      "4-(Chloromethyl)Thiazole Hydrochloride",
      "4-(Chloromethyl)-1,3-Thiazole Hydrochloride",
      "4-(Chloromethyl)Thiazole Hcl",
      "4-Chloromethylthiazole Hydrochloride",
      "4-Chloromethylthiazole Hcl",
      "4-(Chloromethyl)Thiazolehcl",
      "C4H4ClNS.ClH",
      "ACMC-1BBSC",
      "Chloromethylthiazole-4 Hcl",
      "AC1Q3BH9",
      "AC1MC82W",
      "SCHEMBL122926",
      "4-(Chloromethyl)Thiazole, HCl",
      "CTK6H6834",
      "DTXSID60376874",
      "NVTBASMQHFMANH-UHFFFAOYSA-N",
      "MolPort-000-139-475",
      "4-(Chloromethyl)Thiazole HCl Salt",
      "ACT02312",
      "KS-000004ND",
      "ANW-36949",
      "4-Chloromethyl Thiazole Hydrochloride",
    ],
  },
  {
    primary_id: "D01CCG",
    names: ["TUG-424"],
  },
  {
    primary_id: "D01CCT",
    names: ["Triazole Derivative 1"],
  },
  {
    primary_id: "D01CDE",
    names: ["BIMU-1", "BIMU-8"],
  },
  {
    primary_id: "D01CDK",
    names: ["PMID27109571-Compound-16"],
  },
  {
    primary_id: "D01CDL",
    names: [
      "L-652731",
      "CHEMBL297624",
      "AC1OCET6",
      "SCHEMBL7018011",
      "BDBM50366241",
      "U-80271",
      "(2R,5R)-2,5-Bis(3,4,5-Trimethoxyphenyl)Oxolane",
      "([trans-2,5-Bis(3,4,5-Trimethoxyphenyl)Tetrahydrofuran])",
    ],
  },
  {
    primary_id: "D01CEG",
    names: ["P-1736"],
  },
  {
    primary_id: "D01CEQ",
    names: ["CER-227185"],
  },
  {
    primary_id: "D01CFL",
    names: ["Benzothiazoline Derivative 1"],
  },
  {
    primary_id: "D01CFO",
    names: ["IsoVa-RYYRIK-NH2", "CHEMBL404530"],
  },
  {
    primary_id: "D01CFQ",
    names: ["LB-2.1"],
  },
  {
    primary_id: "D01CGC",
    names: ["13-(N-Methyl-N-Heptnamido)Tridec-8(Z)-Enoic Acid", "CHEMBL559454"],
  },
  {
    primary_id: "D01CGM",
    names: [
      "ALTANSERIN",
      "ALTANSERIN TARTRATE",
      "UNII-9P204CHE8J",
      "79449-96-0",
      "Altanserin Tartrate [USAN]",
      "9P204CHE8J",
      "Altanserin Tartrate (USAN)",
      "3-[2-[4-(4-Fluorobenzoyl)Piperidin-1-Yl]ethyl]-2-Sulfanylidene-1H-Quinazolin-4-One",
      "EINECS 279-159-5",
      "AC1Q59OU",
      "AC1MHW43",
      "SCHEMBL124036",
      "DTXSID20229675",
      "3-(2-(4-(P-Fluorobenzoyl)Piperidino)Ethyl)-2-Thio-2,4(1H,3H)-Quinazolinedione L-(+)-Tartrate (1:1)",
      "D02836",
      "R-53,200",
      "4(1H)-Quinazolinone, 3-(2-(4-(4-Fluorobenzoyl)-1-Piperidinyl)Ethyl)-2,3-Dihydro-2-Thioxo-, (R-(R*,R",
    ],
  },
  {
    primary_id: "D01CGQ",
    names: ["PMID27376512-Compound-MiR-155-5p"],
  },
  {
    primary_id: "D01CGR",
    names: [
      "3-Imidazol-1-Ylmethyl-2-Isopropyl-1H-Indole",
      "72818-36-1",
      "UK 34787",
      "UK-34787",
      "CHEMBL284356",
      "3-(1h-Imidazol-1-Ylmethyl)-2-(Propan-2-Yl)-1h-Indole",
      "3-(Imidazol-1-Ylmethyl)-2-Propan-2-Yl-1H-Indole",
      "3-Isopropyl-3-(1-Imidazolylmethyl)Indole",
      "AC1L4YBG",
      "AC1Q4X5Y",
      "SCHEMBL11346119",
      "CTK5D6874",
      "DTXSID00223136",
      "NZXGMVRYQLENHC-UHFFFAOYSA-N",
      "BDBM50022121",
      "NSC342229",
      "AKOS030539398",
      "NSC-342229",
      "3-(Imidazol-1-Ylmethyl)-2-Isopropylindole",
      "3-Imidazol-1-Ylmethyl-2-Isopropyl-1H-Indole(UK-34787)",
      "1H-Indole, 3-(1H-Imidazol-1-Ylmethyl)-2-(1-Methylethy",
    ],
  },
  {
    primary_id: "D01CGV",
    names: ["LY-2599506"],
  },
  {
    primary_id: "D01CGX",
    names: ["ApoC-III"],
  },
  {
    primary_id: "D01CHD",
    names: ["RG6061"],
  },
  {
    primary_id: "D01CHI",
    names: ["VR315"],
  },
  {
    primary_id: "D01CIB",
    names: ["SAR164653"],
  },
  {
    primary_id: "D01CIK",
    names: ["Heteroaryl-Cyclopropylamine Derivative 4"],
  },
  {
    primary_id: "D01CIN",
    names: ["STF-62247"],
  },
  {
    primary_id: "D01CJB",
    names: ["Novovac-M1"],
  },
  {
    primary_id: "D01CJE",
    names: ["4-(Thiazol-5-Yl)-Pyrimidine Derivative 2"],
  },
  {
    primary_id: "D01CJY",
    names: ["FR-146687"],
  },
  {
    primary_id: "D01CJZ",
    names: [
      "VUF 5681",
      "CHEMBL78498",
      "4-[3-(1h-Imidazol-4-Yl)Propyl]piperidine",
      "768358-61-8",
      "VUF-5681",
      "4-[3-(1H-IMIDAZOL-4-YL)-PROPYL]-PIPERIDINE",
      "SCHEMBL15139142",
      "CHEBI:93084",
      "ZINC13530263",
      "BDBM50409817",
      "AKOS006290761",
      "NCGC00167742-01",
      "KB-333611",
    ],
  },
  {
    primary_id: "D01CKB",
    names: ["Carbamide Derivative 16"],
  },
  {
    primary_id: "D01CKY",
    names: ["Ecabet Sodium"],
  },
  {
    primary_id: "D01CLK",
    names: ["CHGN111"],
  },
  {
    primary_id: "D01CMX",
    names: ["Exeporfinium Chloride Topical"],
  },
  {
    primary_id: "D01CNB",
    names: ["E6201"],
  },
  {
    primary_id: "D01CND",
    names: ["PMID26651364-Compound-114"],
  },
  {
    primary_id: "D01CNF",
    names: ["OAADPR", "AC1MMT9E", "2'-O-Acetyl-ADP-Ribose", "2''-O-Acetyl-ADP-D-Ribose", "OAADPr"],
  },
  {
    primary_id: "D01CNV",
    names: ["PL-611"],
  },
  {
    primary_id: "D01COH",
    names: ["ZP-010"],
  },
  {
    primary_id: "D01CON",
    names: ["IGIV3I Grifols 10%"],
  },
  {
    primary_id: "D01CPZ",
    names: ["ACP-196"],
  },
  {
    primary_id: "D01CQJ",
    names: ["KLS-HPV"],
  },
  {
    primary_id: "D01CQU",
    names: ["MRS1706"],
  },
  {
    primary_id: "D01CRB",
    names: [
      "L-Tyrosine",
      "DTY",
      "Rxosine",
      "Tirosina",
      "Tyr",
      "Tyrosine",
      "Tyrosinum",
      "Tirosina [Spanish]",
      "Tyrosine Power",
      "Tyrosinum [Latin]",
      "Melanin Synthesized From Tyr Substrate Catalyzed By Tyrosinase For 6 Hrs",
      "L-Tyrosin",
      "L-Tyrosine Hydrochloride",
      "Melanin Synthesized From Tyr Substrate Catalyzed By Tyrosinase For 6 Hrs, Oxidized With Hydrogen Peroxide",
      "P-Tyrosine",
      "TYR NH3+ COOH",
      "Tyrosine (VAN)",
      "Tyrosine [USAN:INN]",
      "Free-Form L-Tyrosine",
      "H-Tyr-OH",
      "L-Tyrosine (9CI)",
      "L-Tyrosine (JAN)",
      "L-Tyrosine, Homopolymer",
      "L-Tyrosine, Monomer",
      "L-P-Tyrosine",
      "Melanin Synthesized From Tyr Substrate Catalyzed By Tyrosinase, Brominated With N-Bromosuccinimide",
      "Tyrosine (USP/INN)",
      "Beta-(P-Hydroxyphenyl)Alanine",
      "Melanin Synthesized From Tyr Substrate Catalyzed By Tyrosinase For 6 Hrs, Oxidized With Hydrogen Peroxide, <3 Kd Fraction",
      "Tyrosine, L-(8CI)",
      "DD69927C-C6A8-4BC6-8E9A-0AB423B176E7",
      "L-(-)-Tyrosine",
      "Melanin Synthesized From Tyr Substrate Catalyzed By Tyrosinase, Sulfonated Using Sulfur Trioxide/DMF Complex For 1.5-7 Hours",
      "Alpha-Amino-Beta-(4-Hydroxyphenyl)Propionic Acid",
      "L-2-Amino-3-P-Hydroxyphenylpropanoic Acid",
      "(-)-Alpha-Amino-P-Hydroxyhydrocinnamic Acid",
      "(S)-2-Amino-3-(4-Hydroxyphenyl)Propionic Acid",
      "(S)-2-Amino-3-(P-Hydroxyphenyl)Propionic Acid",
      "(S)-3-(P-Hydroxyphenyl)Alanine",
      "(S)-Tyrosine",
      "(S)-Alpha-Amino-4-Hydroxybenzenepropanoic Acid",
      "2-Amino-3-(P-Hydroxyphenyl)Propionic Acid",
      "3-(4-Hydroxyphenyl)-L-Alanine",
      "3-(P-Hydroxyphenyl)Alanine",
      "4-Hydroxy-L-Phenylalanine",
      "4ts1",
    ],
  },
  {
    primary_id: "D01CRJ",
    names: ["ZK-158252"],
  },
  {
    primary_id: "D01CRK",
    names: [
      "Small Molecule 34",
      "3-(2-(4-Pyridyl)Ethyl)Indole",
      "3-(2-PYRIDIN-4-YLETHYL)-1H-INDOLE",
      "INHIBITOR OF P38 KINASE",
      "3-(2-(Pyridin-4-Yl)Ethyl)-1H-Indole",
      "3-[2-(Pyridin-4-Yl)Ethyl]-1H-Indole",
      "3-(2-Pyridin-4-Yl-Ethyl)-1H-Indole",
      "L12",
      "Maybridge1_000718",
      "Cambridge Id 5373101",
      "AC1L1E9J",
      "Oprea1_574113",
      "Oprea1_420786",
      "SCHEMBL735580",
      "CHEMBL193156",
      "HMS543I14",
      "ZINC94256",
      "BDBM13346",
      "UUEYCHLWAOBOHG-UHFFFAOYSA-N",
      "1w84",
      "MolPort-001-886-488",
      "HMS3604M06",
      "3-[2-(4-Pyridyl)-Ethyl]-Indole",
      "STL328644",
      "CCG-45397",
      "3-[2-(4-Pyridyl)Ethyl]-1H-Indole",
      "AKOS000545583",
      "MCULE-4657429599",
    ],
  },
  {
    primary_id: "D01CRX",
    names: ["Benzimidazole Derivative 7"],
  },
  {
    primary_id: "D01CSE",
    names: ["Bis(3-Bromophenyl)(5-Hydroxy)Thiosemicarbazone", "CHEMBL1271493"],
  },
  {
    primary_id: "D01CSM",
    names: ["[3H]nelivaptan", "[3H]-SSR149415", "[3H]SSR149415"],
  },
  {
    primary_id: "D01CSZ",
    names: [
      "5-(Isopropylamino)Quinolin-8-Ol",
      "CHEMBL571661",
      "8-Quinolinol, 5-[(1-Methylethyl)Amino]-",
      "SCHEMBL12599076",
      "BDBM50303920",
    ],
  },
  {
    primary_id: "D01CTM",
    names: ["PMID21823616C22"],
  },
  {
    primary_id: "D01CTR",
    names: ["Plasminogen Intravenous"],
  },
  {
    primary_id: "D01CUC",
    names: ["BCMA CAR T Cells"],
  },
  {
    primary_id: "D01CUJ",
    names: ["3-(2-Propyn-1-Yl)Thymidine", "3-Propargylthymidine", "SCHEMBL1619074", "CHEMBL524872"],
  },
  {
    primary_id: "D01CUO",
    names: ["TTI-621"],
  },
  {
    primary_id: "D01CUR",
    names: [
      "3-Ethoxy-9H-Beta-Carboline",
      "3-Ethoxy-Beta-Carboline",
      "3-Ethoxy-9H-Pyrido[3,4-B]indole",
      "CHEMBL498936",
      "CHEMBL299250",
      "3-Ethoxy-9h-",
      "AC1Q4YMX",
      "AC1L3YI4",
      "SCHEMBL9531510",
      "FNNPSIGQQWCKKB-UHFFFAOYSA-N",
      "ZINC5139892",
      "BDBM50001475",
      "PDSP2_001734",
      "BDBM50244034",
      "PDSP1_001751",
      "3-Ethoxy-9H-Beta-Carboline(3-EBC)",
      "9H-Pyrido(3,4-B)Indole, 3-Ethoxy-",
    ],
  },
  {
    primary_id: "D01CWN",
    names: [
      "D-Ascorbic Acid",
      "D-Xyloascorbic Acid",
      "D-Lyxoascorbic Acid",
      "10504-35-5",
      "UNII-C8DQ5M1Y1E",
      "C8DQ5M1Y1E",
      "D-Threo-Hex-2-Enono-1,4-Lactone",
      "D-Threo-Hex-2-Enoic Acid Gamma-Lactone",
      "Kangbingfeng",
      "Ascorbinsaure",
      "Juvamine",
      "Chewcee",
      "Citrovit",
      "Ceklin",
      "Vasc",
      "Rovimix C",
      "Cell C",
      "Viscorin 100M",
      "(5S)-5-[(1R)-1,2-Dihydroxyethyl]-3,4-Dihydroxyfuran-2(5H)-One",
      "Suncoat VC 40",
      "Ronotec 100",
      "Rontex 100",
      "Xyloascorbic Acid, L-",
      "Ascorbic Acid, D-",
      "L-Ascorbate Acid",
      "E 300",
      "Cebion, Gamma-Lactone",
    ],
  },
  {
    primary_id: "D01CXD",
    names: ["9-(Aminomethyl)-9,10-Dihydroanthracene", "C-(9,10-Dihydro-Anthracen-9-Yl)-Methylamine"],
  },
  {
    primary_id: "D01CXJ",
    names: ["PAC-113"],
  },
  {
    primary_id: "D01CXM",
    names: ["6-Acetyl-7-Ethoxy-2H-Chromen-2-One", "CHEMBL1288559"],
  },
  {
    primary_id: "D01CXW",
    names: ["MEDI0680"],
  },
  {
    primary_id: "D01CXY",
    names: ["ODM-201"],
  },
  {
    primary_id: "D01CYA",
    names: ["Bendamustine Hydrochloride"],
  },
  {
    primary_id: "D01CYI",
    names: [
      "7-Hydroxy-3-(4-Hydroxyphenyl)-3H-Quinazolin-4-One",
      "3-Arylquinazolinone, 1as",
      "CHEMBL203370",
      "SCHEMBL1795123",
      "BDBM19927",
      "AKOS002674620",
    ],
  },
  {
    primary_id: "D01CYK",
    names: ["ME1100", "Arbekacin Inhalation"],
  },
  {
    primary_id: "D01CYT",
    names: ["ELND-002"],
  },
  {
    primary_id: "D01CYW",
    names: ["PK-236"],
  },
  {
    primary_id: "D01CZH",
    names: [
      "Chanllergen",
      "Dermatophagoides Farinae Drops (Sublingual, Allergy), Wolwo",
      "House Dust Mite Vaccine (Sublingual, Allergy), Wolwo",
    ],
  },
  {
    primary_id: "D01DAD",
    names: ["CJ-13610", "CJ 13610", "CJ-13,610"],
  },
  {
    primary_id: "D01DAQ",
    names: ["5-Fluoro-6-[(2-Aminoimidazol-1-Yl)Methyl]uracil", "CHEMBL1080896"],
  },
  {
    primary_id: "D01DAU",
    names: [
      "4-(3-Pent-1-Ynyl-Benzyl)-1H-Imidazole",
      "CHEMBL451399",
      "BDBM22540",
      "4-Benzyl-1H-Imidazole Derivative, 21",
    ],
  },
  {
    primary_id: "D01DAW",
    names: [
      "Bifeprunox",
      "Bifeprunoxum",
      "Bifeprunox [INN]",
      "DU 127090",
      "DU127090",
      "Bifeprunoxum [INN-Latin]",
      "Bifeprunox (USAN/INN)",
      "7-(4-(Biphenyl-3-Ylmethyl)Piperazin-1-Yl)Benzoxazol-2(3H)-One",
      "7-[4-[(3-Phenylphenyl)Methyl]piperazin-1-Yl]-3H-1,3-Benzoxazol-2-One",
    ],
  },
  {
    primary_id: "D01DBB",
    names: ["PMID27774824-Compound-Figure9Example2down"],
  },
  {
    primary_id: "D01DBF",
    names: ["APIT", "APIT-1001", "APIT-2001", "Aplysia Punctata Ink Toxin (Cancer), Celonic"],
  },
  {
    primary_id: "D01DBQ",
    names: ["Teniposide"],
  },
  {
    primary_id: "D01DBW",
    names: ["[Ncy(Isopropyl)7]acyline", "CHEMBL415659"],
  },
  {
    primary_id: "D01DCE",
    names: ["4-(Indan-1-Ylsulfanyl)-Phenylamine", "CHEMBL191844"],
  },
  {
    primary_id: "D01DCJ",
    names: ["GRNOPC-1"],
  },
  {
    primary_id: "D01DCZ",
    names: ["ISIS 112690"],
  },
  {
    primary_id: "D01DDB",
    names: ["2-(Phenylamino)-6-(3-Acetamidophenyl)Pyrazine", "CHEMBL425576", "SCHEMBL1122285"],
  },
  {
    primary_id: "D01DDN",
    names: ["SCH-900271"],
  },
  {
    primary_id: "D01DDQ",
    names: ["ISIS 298744"],
  },
  {
    primary_id: "D01DEU",
    names: ["2-P-Tolyl-4,5-Dihydro-1H-Imidazole"],
  },
  {
    primary_id: "D01DFK",
    names: ["ISIS 32024"],
  },
  {
    primary_id: "D01DFS",
    names: ["EP4-R Antagonist"],
  },
  {
    primary_id: "D01DFX",
    names: ["4-[(2-Methyl-1H-Imidazol-1-Yl)Methyl]pyridine", "CHEMBL1269156", "BDBM50330054", "AKOS013124194"],
  },
  {
    primary_id: "D01DGN",
    names: ["Ethiodized Oil"],
  },
  {
    primary_id: "D01DGR",
    names: ["Male Contraceptive"],
  },
  {
    primary_id: "D01DGZ",
    names: ["ABBV-2222"],
  },
  {
    primary_id: "D01DHT",
    names: ["Azole Derivative 7"],
  },
  {
    primary_id: "D01DHW",
    names: ["9-O-[2-(Phenylol-1-Yloxy)Ethyl]berberine Bromide", "CHEMBL602662"],
  },
  {
    primary_id: "D01DIA",
    names: ["NKTR-262"],
  },
  {
    primary_id: "D01DIM",
    names: ["CLL442"],
  },
  {
    primary_id: "D01DIQ",
    names: ["Alstiphyllanine F", "CHEMBL592521"],
  },
  {
    primary_id: "D01DIT",
    names: ["N-(2-Hydroxyethyl)Linoleoylamide"],
  },
  {
    primary_id: "D01DJA",
    names: ["TSK-204", "Antirheumatic, Kyowa/Taisho"],
  },
  {
    primary_id: "D01DJI",
    names: ["PHY-906"],
  },
  {
    primary_id: "D01DJZ",
    names: ["Indoline Derivative 18"],
  },
  {
    primary_id: "D01DLK",
    names: ["Vaccine, Cancer, Wistar Institute Of Anatomy & Biology"],
  },
  {
    primary_id: "D01DLT",
    names: ["Encapsulated Live Cells Converting Ifosfamide"],
  },
  {
    primary_id: "D01DLX",
    names: ["SMC-0001 Prodrug"],
  },
  {
    primary_id: "D01DMN",
    names: ["XD1", "Colchicein", "Colchiceine", "NSC33411"],
  },
  {
    primary_id: "D01DMS",
    names: ["(S)-5-Iodowillardiine"],
  },
  {
    primary_id: "D01DMT",
    names: [
      "Dendrogenin A",
      "Dendrogenine A",
      "AF-122",
      "DDA, Affichem",
      "Imidazole-Substituted Sterol Derivatives, AFFICHEM/INSERM",
    ],
  },
  {
    primary_id: "D01DNH",
    names: ["PMID25666693-Compound-150"],
  },
  {
    primary_id: "D01DNN",
    names: ["Bip-Tyr(3bzl)-Thr-Pro-Lys-Thr", "CHEMBL385983"],
  },
  {
    primary_id: "D01DOV",
    names: ["(S)-2-Amino-N-Cyclopentyl-7-Mercaptoheptanamide"],
  },
  {
    primary_id: "D01DOW",
    names: ["Trisodium Phosphate"],
  },
  {
    primary_id: "D01DOY",
    names: ["GR-127607"],
  },
  {
    primary_id: "D01DPD",
    names: ["KW-6055"],
  },
  {
    primary_id: "D01DPF",
    names: ["MRS1505"],
  },
  {
    primary_id: "D01DPN",
    names: ["3-[(5-Ethyl-2-Methyl-4-Thiazolyl)Ethynyl]pyridine", "CHEMBL202277", "SCHEMBL4151649"],
  },
  {
    primary_id: "D01DQI",
    names: ["Parvovirus H-1"],
  },
  {
    primary_id: "D01DQR",
    names: ["AIR-Epinephrine"],
  },
  {
    primary_id: "D01DQT",
    names: ["Ac-ICVWQDWGAHRCT-NH2"],
  },
  {
    primary_id: "D01DRB",
    names: ["Autologous Cell Vaccine"],
  },
  {
    primary_id: "D01DRE",
    names: ["SOM-0002"],
  },
  {
    primary_id: "D01DRL",
    names: ["RGH-2721"],
  },
  {
    primary_id: "D01DSB",
    names: ["VTX-744"],
  },
  {
    primary_id: "D01DSL",
    names: ["Triptolidenol Analog 1"],
  },
  {
    primary_id: "D01DSP",
    names: [
      "Cephapirin",
      "CEPR",
      "Cefa",
      "Cefadyl",
      "Cefapirin",
      "Cefapirina",
      "Cefapirine",
      "Cefapirinum",
      "Cefaprin",
      "Cephapirine",
      "Metricure",
      "CEPHAPIRIN SODIUM",
      "Cefaprinsodium",
      "Cephapirin Monosodium Salt",
      "ANTIBIOTIC BL-P1322",
      "BL-P 1322",
      "Cefa-Ak",
      "Cefadyl (TN)",
      "Cefapirin (BAN)",
      "Cefapirin [INN:BAN]",
      "Cefapirina [INN-Spanish]",
      "Cefapirine [INN-French]",
      "Cefapirinum [INN-Latin]",
      "Metricure (TN)",
      "(6R,7R)-3-(Acetoxymethyl)-8-Oxo-7-(2-(4-Pyridylthio)Acetamido)-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2-Carbonsaeure",
      "(6R,7R)-3-(Acetyloxymethyl)-8-Oxo-7-[(2-Pyridin-4-Ylsulfanylacetyl)Amino]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-[(Acetyloxy)Methyl]-8-Oxo-7-{[(Pyridin-4-Ylthio)Acetyl]amino}-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-Acetoxymethyl-7-[(Pyridin-4-Ylsulfanyl)Acetamido]-3,4-Didehydrocepham-4-Carboxylic Acid",
      "3-(Hydroxymethyl)-8-Oxo-7-(2-(4-Pyridylthio)Acetamidol-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2carbonsaeure Acetat",
      "5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 3-(Hydroxymethyl)-8-Oxo-7-(2-(4-Pyridylthio)Acetamido)-, Acetate (Ester)",
      "7-(2-(4-Pyridylthio)Acetamido)Cephalosporanic Acid",
    ],
  },
  {
    primary_id: "D01DTE",
    names: [
      "5-Chloro-2-(2-Chloro-4-Hydroxyphenoxy)Phenol",
      "CHEMBL200379",
      "656833-26-0",
      "SCHEMBL10539354",
      "CTK1J6007",
      "DTXSID70611844",
      "BDBM50174768",
      "2-(2-Chloro-4-Hydroxyphenoxy)-5-Chlorophenol",
      "3-Chloro-4-(4-Chloro-2-Hydroxy-Phenoxy)Phenol",
      "Phenol, 5-Chloro-2-(2-Chloro-4-Hydroxyphenoxy)-",
    ],
  },
  {
    primary_id: "D01DTO",
    names: ["AOB102"],
  },
  {
    primary_id: "D01DTQ",
    names: ["PXS-2076"],
  },
  {
    primary_id: "D01DTW",
    names: ["FARNESYL"],
  },
  {
    primary_id: "D01DVR",
    names: ["NT-001"],
  },
  {
    primary_id: "D01DVS",
    names: ["ISIS 183911"],
  },
  {
    primary_id: "D01DVV",
    names: ["ISIS 100939"],
  },
  {
    primary_id: "D01DVX",
    names: [
      "DPC-333",
      "BMS-561392",
      "UNII-2X066A8676",
      "BMS 561392",
      "CHEMBL489100",
      "BMS561392",
      "2X066A8676",
      "DPC 333",
      "611227-74-8",
      "SCHEMBL6350418",
      "GTPL6509",
      "BDBM50247606",
      "C476910000",
      "(2R)-2-[(3R)-3-Amino-3-[4-[(2-Methylquinolin-4-Yl)Methoxy]phenyl]-2-Oxopyrrolidin-1-Yl]-N-Hydroxy-4-Methylpentanamide",
    ],
  },
  {
    primary_id: "D01DWN",
    names: ["PMID27599163-Compound-79"],
  },
  {
    primary_id: "D01DXB",
    names: ["PMID26666989-Compound-Figure9middle11"],
  },
  {
    primary_id: "D01DXI",
    names: ["PF-06290510"],
  },
  {
    primary_id: "D01DYZ",
    names: [
      "NSC-204996",
      "CHEMBL1288104",
      "7,8-Dimethoxy-4-(3,4,5-Trimethoxyphenyl)-2,3-Dihydro-1H-Benzo[e]isoindole",
      "AC1MTWS1",
      "ZINC1740637",
    ],
  },
  {
    primary_id: "D01DZF",
    names: [
      "N-(3,3-Diphenyl-Propyl)-Isonicotinamide",
      "CHEMBL571898",
      "N-(3,3-Diphenylpropyl)Pyridine-4-Carboxamide",
      "AC1NQBE3",
      "SCHEMBL5948064",
      "MolPort-003-323-378",
      "ZINC5509004",
      "STK483446",
      "BDBM50297397",
      "AKOS001272797",
      "MCULE-6529824249",
      "ST50922884",
    ],
  },
  {
    primary_id: "D01EAG",
    names: ["MDDR 287569"],
  },
  {
    primary_id: "D01EAQ",
    names: [
      "[3H]2,2,2-TEMPS",
      "2,2,2-Trifluoro-N-(3-Pentan-2-Yloxyphenyl)-N-(Pyridin-3-Ylmethyl)Ethanesulfonamide",
      "CHEMBL105296",
      "[3H]-PAM",
      "2,2,2-TEMPS",
      "SCHEMBL6735527",
      "GTPL6329",
      "GTPL6328",
      "BDBM50147143",
      "2,2,2-Trifluoro-Ethanesulfonic Acid [3-(1-Methyl-Butoxy)-Phenyl]-Pyridin-3-Ylmethyl-Amide",
    ],
  },
  {
    primary_id: "D01EAV",
    names: ["ISIS 133244"],
  },
  {
    primary_id: "D01EBJ",
    names: ["4991W93"],
  },
  {
    primary_id: "D01EBV",
    names: [
      "3-Epicorosolic Acid Methyl Ester",
      "CHEMBL604538",
      "BDBM50307106",
      "2alpha,3alpha-Dihydroxyurs-12-En-28-Oic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D01EBW",
    names: ["Quinoline Derivative 12"],
  },
  {
    primary_id: "D01EBY",
    names: ["L-Thiocitrulline"],
  },
  {
    primary_id: "D01ECL",
    names: ["2-(2-Methyl-Thiazol-4-Ylethynyl)-Pyridine", "CHEMBL65022", "SCHEMBL15049682", "BDBM50122757"],
  },
  {
    primary_id: "D01ECN",
    names: [
      "MVA-BN DF",
      "Dengue Vaccine, Bavarian Nordic",
      "MVA Vector (Dengue), Bavarian Nordic",
      "MVA-BN DF, Bavarian Nordic",
      "MVA-BN Dengue Fever, Bavarian Nordic",
    ],
  },
  {
    primary_id: "D01EDA",
    names: ["[Mpa1, D-Tyr(Et)2, Pip7]OT", "CHEMBL393020"],
  },
  {
    primary_id: "D01EDB",
    names: ["NSC-746457"],
  },
  {
    primary_id: "D01EDE",
    names: ["IRL-2500"],
  },
  {
    primary_id: "D01EDJ",
    names: ["CSE-1034", "Ceftriaxone, Disodium-Edetate,sulbactam"],
  },
  {
    primary_id: "D01EDO",
    names: ["GW-250495"],
  },
  {
    primary_id: "D01EDS",
    names: ["SL-65.1498"],
  },
  {
    primary_id: "D01EEM",
    names: ["2-Mercapto-N-(4-Sulfamoyl-Phenyl)-Benzamide"],
  },
  {
    primary_id: "D01EEY",
    names: ["PMID25514969-Compound-Figure3"],
  },
  {
    primary_id: "D01EFO",
    names: ["CGP-53716", "CGS-53716"],
  },
  {
    primary_id: "D01EFQ",
    names: ["2-(4,6-Dimethoxypyrimidin-2-Yloxy)-5-Hexylphenol", "CHEMBL258933"],
  },
  {
    primary_id: "D01EGC",
    names: ["PMID27967267-Compound-47"],
  },
  {
    primary_id: "D01EGN",
    names: ["4-(4,6-Diphenylpyridin-2-Yl)Phenol"],
  },
  {
    primary_id: "D01EHI",
    names: ["Live Attenuated H1N1 Pandemic Influenza Vaccine"],
  },
  {
    primary_id: "D01EHP",
    names: ["Cyclopentyl-(9-Methyl-9H-Purin-6-Yl)-Amine"],
  },
  {
    primary_id: "D01EHS",
    names: ["7-Methyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL365202", "SCHEMBL7682273", "BDBM50155792"],
  },
  {
    primary_id: "D01EHW",
    names: [
      "Antifibrinolytic Agents",
      "Antifibrinolytic Agents (Bleeding)",
      "Antifibrinolytic Agents (Bleeding), Omeros",
      "Antifibrinolytic Agents (Bleeding), University Of California",
      "Selective Plasmin Inhibitors (Bleeding), Omeros",
      "Selective Plasmin Inhibitors (Bleeding), University Of California",
    ],
  },
  {
    primary_id: "D01EHZ",
    names: ["Piperazine Carbamate/Urea Derivative 5"],
  },
  {
    primary_id: "D01EIS",
    names: [
      "1-(1,2-Diphenylethyl)Piperazine",
      "CHEMBL214237",
      "88144-93-8",
      "SCHEMBL6171525",
      "CTK3B7060",
      "N-(1,2-Diphenylethyl)Piperazine",
      "DTXSID00483036",
      "BDBM50188059",
      "AKOS010287239",
      "Piperazine, 1-(1,2-Diphenylethyl)-",
    ],
  },
  {
    primary_id: "D01EIT",
    names: ["Pyrrolo-Pyrimidine Derivative 8"],
  },
  {
    primary_id: "D01EJG",
    names: ["RG6100"],
  },
  {
    primary_id: "D01EJH",
    names: ["NEORAUTENOL"],
  },
  {
    primary_id: "D01EJJ",
    names: ["JTE-051"],
  },
  {
    primary_id: "D01EKN",
    names: ["RAV-12", "Anti-RAAG12 Mab", "Anti-RAAG12 Monoclonal Antibody", "KID-3 Antibody (Cancer), Raven"],
  },
  {
    primary_id: "D01EKO",
    names: ["GR-86014", "132537-24-7"],
  },
  {
    primary_id: "D01EKQ",
    names: ["Ferrous Citrate, Fe-59"],
  },
  {
    primary_id: "D01EKS",
    names: ["Acyl Piperidine Derivative 3"],
  },
  {
    primary_id: "D01ELC",
    names: ["NM-4540"],
  },
  {
    primary_id: "D01ELU",
    names: ["VSN-16R"],
  },
  {
    primary_id: "D01EMD",
    names: ["Ovarian Dendritic Cell-Based Vaccine"],
  },
  {
    primary_id: "D01ENA",
    names: ["[Fe(CN)6]4-"],
  },
  {
    primary_id: "D01ENO",
    names: ["P'-Geranyl 3,5,9-Trihydroxy-3-Methylnonanate 9-Diphosphate"],
  },
  {
    primary_id: "D01ENR",
    names: [
      "4-[2-(2-Benzyl-Phenoxy)-Ethyl]-Morpholine",
      "CHEMBL365697",
      "4-[2-(2-Benzylphenoxy)Ethyl]morpholine",
      "AC1MGXJ0",
      "BDBM50151042",
    ],
  },
  {
    primary_id: "D01ENV",
    names: ["PMID27109571-Compound-4"],
  },
  {
    primary_id: "D01EOQ",
    names: ["PMID22607879CR-(-)-5f"],
  },
  {
    primary_id: "D01EOU",
    names: ["(+/-)-Threo-N-(3-Chlorobenzyl)Methylphenidate", "CHEMBL1254195"],
  },
  {
    primary_id: "D01EPD",
    names: ["ISIS 133726"],
  },
  {
    primary_id: "D01EPO",
    names: ["ABIO 09-01", "Antidepressants, Abiogen", "BTG-1675A"],
  },
  {
    primary_id: "D01EPP",
    names: ["EL-715"],
  },
  {
    primary_id: "D01EPU",
    names: [
      "BUTORPHAN",
      "CHEMBL301160",
      "Butorphan",
      "MCL-101",
      "SCHEMBL11805323",
      "ZINC3982790",
      "3-Hydroxy-N-Cyclobutylmethylmorphinan",
      "BDBM50135808",
      "3-Hydroxy-N-Cyclobutylmethyl Morphinan",
    ],
  },
  {
    primary_id: "D01EQG",
    names: [
      "Azapropazone",
      "APAZONE",
      "Cinnopropazone",
      "Prolixan",
      "Azapropazonum",
      "Azapropazona",
      "Cinnamin",
      "Rheumox",
      "Sinnamin",
      "Mitrolan",
      "Azapropazon",
      "Prolix",
      "Xani",
      "Azapropazone (Anhydrous)",
      "13539-59-8",
      "AHR-3018",
      "Prolixan 300",
      "MI 85",
      "AHR 3018",
      "Apazone [USAN]",
      "Azapropazon [German]",
      "Mi-85",
      "NSC-102824",
      "Azapropazonum [INN-Latin]",
      "Azapropazona [INN-Spanish]",
      "NSC 102824",
      "5-(Dimethylamino)-9-Methyl-2-Propyl-1H-Pyrazolo(1,2-A)(1,2,4)Benzotriazine-1,3(2H)-Dione",
      "C16H20N4O2",
      "3-Dimethylamino-7-Methyl-1,2-(N-Propylmalonyl)-1,2-Dihydro-1,2,4-Benzotriazine",
      "EINECS 236-9",
    ],
  },
  {
    primary_id: "D01EQU",
    names: ["SCH-725739"],
  },
  {
    primary_id: "D01ERB",
    names: ["16-(Pyridin-4-Yl)Methylene-Estradiol"],
  },
  {
    primary_id: "D01ERE",
    names: ["ASP4345"],
  },
  {
    primary_id: "D01ERN",
    names: ["MGluR5 Antagonists (Anxiety), Novartis"],
  },
  {
    primary_id: "D01ERV",
    names: ["PMID25772215-Compound-US02014179750TG7-170"],
  },
  {
    primary_id: "D01ERW",
    names: [
      "CFMTI",
      "864864-17-5",
      "CHEMBL578995",
      "SCHEMBL1499981",
      "GTPL6341",
      "KS-00001CMY",
      "XZBFQWRAIYRPPZ-UHFFFAOYSA-N",
      "MolPort-044-567-623",
      "BCP18264",
      "ZINC13983188",
      "BDBM50301824",
      "AKOS028114902",
      "CS-5757",
      "2-Cyclopropyl-5-[1-(2-Fluoropyridin-3-Yl)-5-Methyl-1H-1,2,3-Triazol-4-Yl]-2,3-Dihydro-1H-Isoindol-1-One",
      "HY-100402",
      "C-365",
      "L023899",
      "2-Cyclopropyl-5-[1-(2-Fluoropyridin-3-Yl)-5-Methyltriazol-4-Yl]-3H-Isoindol-1-One.",
      "2-Cyclopropyl-5-[1-(2-Fluoropyridin-3-Yl)-5-Methyl-1H-1,2,3-Triazol-4-Yl]-2,3-Dihydro-1H-Isoindol-1-",
    ],
  },
  {
    primary_id: "D01ESB",
    names: ["YM-992"],
  },
  {
    primary_id: "D01ESC",
    names: ["BBT-009", "BBT-004", "Long-Acting Erythropoietin (PEGylated, Anemia), Bolder BioTechnology Inc"],
  },
  {
    primary_id: "D01ESJ",
    names: ["Luzindole"],
  },
  {
    primary_id: "D01ESN",
    names: ["[Tyr-Pro-Phe-Phe-NH-]2", "CHEMBL386887"],
  },
  {
    primary_id: "D01EST",
    names: ["PKR Inhibitor, Negative Control"],
  },
  {
    primary_id: "D01ESV",
    names: [
      "4'-(Trifluoromethyl)-4-Biphenylyl Carbamate",
      "CHEMBL394512",
      "BDBM50220194",
      "4''-(Trifluoromethyl)-4-Biphenylyl Carbamate",
    ],
  },
  {
    primary_id: "D01ETL",
    names: [
      "3-MPPTS",
      "2,2,2-Trifluoro-N-[3-(2-Methoxyphenoxy)Phenyl]-N-(3-Pyridinylmethyl)-Ethanesulfonamide",
      "SCHEMBL6732943",
      "GTPL1401",
      "GJWZEUKWBMFOAA-UHFFFAOYSA-N",
      "2,2,2-Trifluoro-N-[3-(2-Methoxyphenoxy)Phenyl]-N-(Pyridin-3-Ylmethyl)Ethanesulfonamide",
    ],
  },
  {
    primary_id: "D01ETZ",
    names: ["[3H]methyltrienolone"],
  },
  {
    primary_id: "D01EUF",
    names: [
      "BMS 275291",
      "D 2163",
      "N-((2S)-2-Mercapto-1-Oxo-4-(3,4,4-Trimethyl-2,5-Dioxo-1-Imidazolidinyl)Butyl)-L-Leucyl-N,3-Dimethyl-L-Valinamide",
      "(2S)-N-[(2S)-3,3-Dimethyl-2-(Methylamino)Butanoyl]-4-Methyl-2-[[(2S)-2-Sulfanyl-4-(3,4,4-Trimethyl-2,5-Dioxoimidazolidin-1-Yl)Butanoyl]amino]pentanamide",
    ],
  },
  {
    primary_id: "D01EUO",
    names: ["Ragweed Extract"],
  },
  {
    primary_id: "D01EVA",
    names: [
      "CPCCOEt",
      "CHEMBL327783",
      "179067-99-3",
      "AC1O3E8H",
      "7-(Hydroxyimino)Cyclopropa[b]chromen-1a-Carboxylate Ethyl Ester",
      "SCHEMBL12648369",
      "MolPort-003-940-874",
      "CPCCOEt, &gt",
      "AKOS024456339",
      "LP00300",
      "NCGC00024948-02",
      "KB-49121",
      "AS-16761",
      "L000202",
      "SR-01000597466",
      "SR-01000597466-1",
      "BRD-A05729358-001-01-2",
      "Ethyl (7E)-7-Hydroxyimino-1,7a-Dihydrocyclopropa[b]chromene-1a-Carboxylate",
    ],
  },
  {
    primary_id: "D01EVE",
    names: ["PMID25703523C7d", "43U", "GTPL8304"],
  },
  {
    primary_id: "D01EVI",
    names: [
      "Alpha-Methylisocitric Acid",
      "Methylisocitrate",
      "71183-66-9",
      "(2S,3R)-3-Hydroxybutane-1,2,3-Tricarboxylic Acid",
      "Methylisocitric Acid",
      "(2S,3R)-3-Hydroxybutane-1,2,3-Tricarboxylate",
      "AC1NUSCP",
      "SCHEMBL1533821",
      "CHEBI:15607",
      "ZINC902108",
      "LMFA01050444",
      "AKOS030526883",
      "DB04072",
      "HY-16581",
      "C04593",
      "3-Carboxy-2,3-Dideoxy-4-C-Methyl-L-Threo-Pentaric Acid",
      "UNII-8518EC1Z2E Component HHKPKXCSHMJWCF-WVBDSBKLSA-N",
      "UNII-ZC5YS92LCE Component HHKPKXCSHMJWCF-WVBDSBKLSA-N",
    ],
  },
  {
    primary_id: "D01EVP",
    names: ["Resorcinol Compound 17"],
  },
  {
    primary_id: "D01EVT",
    names: ["Ethinyl-Pyrazole Derivative 1"],
  },
  {
    primary_id: "D01EVX",
    names: ["5-Chloro-2-Methyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D01EWC",
    names: ["CR-2991"],
  },
  {
    primary_id: "D01EWD",
    names: [
      "Acetic Acid 2-Heptylselanyl-Phenyl Ester",
      "CHEMBL342799",
      "BDBM50068185",
      "Acetic Acid 2-(Heptylseleno)Phenyl Ester",
    ],
  },
  {
    primary_id: "D01EWH",
    names: ["Quinolinyl Compound 1"],
  },
  {
    primary_id: "D01EXG",
    names: ["AMG 224"],
  },
  {
    primary_id: "D01EXL",
    names: ["RNF43-721"],
  },
  {
    primary_id: "D01EXY",
    names: ["BN-AO-014"],
  },
  {
    primary_id: "D01EYJ",
    names: ["Ruxolitinib Derivative 2"],
  },
  {
    primary_id: "D01EYM",
    names: ["BB-2275"],
  },
  {
    primary_id: "D01EYR",
    names: ["ImmunoVEX-Timelan"],
  },
  {
    primary_id: "D01EZA",
    names: ["EC-8042"],
  },
  {
    primary_id: "D01EZC",
    names: ["3-[4-(Benzylmethylamino)Butoxy]xanthen-9-One", "CHEMBL225611"],
  },
  {
    primary_id: "D01EZI",
    names: ["CVT-301"],
  },
  {
    primary_id: "D01EZY",
    names: ["JTP-2724"],
  },
  {
    primary_id: "D01FAA",
    names: [
      "Ac-Lys-[Leu(8)]-Des-Arg(9)-BK",
      "4-(5-AMINO-4-OXO-4H-PYRAZOL-3-YL)-2-BROMO-4,5,6,7-TETRAHYDRO-3AH-PYRROLO[2,3-C]AZEPIN-8-ONE",
      "AC1L1GFH",
      "CTK5I6002",
      "4-(2-Amino-4-Oxo-4h-Imidazol-5-Yl)-2-Bromo-4,5,6,7-Tetrahydropyrrolo[2,3-C]azepin-8(3ah)-One",
      "4-(2-Amino-5-Oxoimidazol-4-Yl)-2-Bromo-4,5,6,7-Tetrahydro-3aH-Pyrrolo[2,3-C]azepin-8-One",
      "(3aR,4S)-4-(2-Amino-4-Oxo-4H-Imidazol-5-Yl)-2-Bromo-4,5,6,7-Tetrahydropyrrolo[2,3-C]azepin-8(3aH)-One",
    ],
  },
  {
    primary_id: "D01FAR",
    names: ["Oxalate"],
  },
  {
    primary_id: "D01FBL",
    names: ["(-)-Yatein"],
  },
  {
    primary_id: "D01FBO",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D01FBP",
    names: ["Vanutide Cridificar", "ACC-001"],
  },
  {
    primary_id: "D01FBS",
    names: ["ALF-5755"],
  },
  {
    primary_id: "D01FCH",
    names: ["Sp-722"],
  },
  {
    primary_id: "D01FCK",
    names: ["Teglicar"],
  },
  {
    primary_id: "D01FDL",
    names: ["Dabelotine", "Dabelotine Mesilate", "S-12024", "S-12024-2"],
  },
  {
    primary_id: "D01FDN",
    names: [
      "NAL-GLU",
      "[Ac-D2Nal1,D4ClPhe2,D3Pal3,Arg5,DGlu5(Anisole Adduct),DAla10]-GnRH",
      "Acetyl-D-2-Naphthylalanyl-D-4-Chlorophenylalanyl-D-3-Pyridylalanyl-L-Seryl-L-Arginyl-[4-(4-Methoxybenzoyl)-D-2-Aminobutyryl]-L-Leucyl-L-Arginyl-L-Prolyl-D-Alanylamide",
      "N-Acetyl-3-(2-Naphthalenyl)-D-Alanyl-4-Chloro-D-Phenylalanyl-3-(3-Pyridinyl)-D-Alanyl-L-Seryl-L-Arginyl-5-(4-Methoxyphenyl)-5-Oxo-D-Norvalyl-L-Leucyl-L-Arginyl-L-Prolyl-D-Alanylamide",
    ],
  },
  {
    primary_id: "D01FDW",
    names: [
      "ARGENINE VASOPRESSIN",
      "8-Arginine Vasopressin",
      "Arginine-Vasopressin (AVP)",
      "AVP (Arginine-Vasopressin )",
      "CHEMBL264048",
      "BDBM50291330",
      "Cyclo[Cys-Tyr-Phe-Gln-Asn-Cys]-Pro-Arg-Gly-NH2",
      "1-[19-Amino-13-Benzyl-10-(2-Carbamoyl-Ethyl)-7-Carbamoylmethyl-16-(4-Hydroxy-Benzyl)-6,9,12,15,18-Pentaoxo-1,2-Dithia-5,8,11,14,17-Pentaaza-Cycloicosane-4-Carbonyl]-Pyrrolidine-2-Carboxylic Acid [1-(Carbamoylmethyl-Carbamoyl)-4-Guanidino-Butyl]-Amide",
    ],
  },
  {
    primary_id: "D01FEA",
    names: [
      "8-DEOXYGARTANIN",
      "8-Desoxygartanin",
      "33390-41-9",
      "CHEMBL488606",
      "1,3,5-Trihydroxy-2,4-Bis(3-Methylbut-2-Enyl)Xanthen-9-One",
      "1,3,5-Trihydroxy-2,4-Bis(3-Methylbut-2-En-1-Yl)-9h-Xanthen-9-One",
      "9H-Xanthen-9-One, 1,3,5-Trihydroxy-2,4-Bis(3-Methyl-2-Butenyl)-",
      "Deoxygartinin",
      "Desoxygartanin,8-",
      "NSC692944",
      "AC1L944B",
      "SCHEMBL17056909",
      "CTK8C7216",
      "MolPort-039-052-631",
      "ZINC5849312",
      "BDBM50311742",
      "AKOS028108663",
      "NSC-692944",
      "FT-0686569",
      "390D419",
      "1,3,5-Trihydroxy-2,4-Bis(3-Methyl-2-Butenyl)-9H-Xanthen-9-One",
    ],
  },
  {
    primary_id: "D01FEC",
    names: ["Rusalatide Acetate", "Chrysalin", "TP508", "Chrysalin (Gel)", "TP-508", "TP508 (Gel)", "TRAP-508"],
  },
  {
    primary_id: "D01FFA",
    names: ["(+-)-Tetrahydropalmatine"],
  },
  {
    primary_id: "D01FFC",
    names: ["OPB-51602"],
  },
  {
    primary_id: "D01FFG",
    names: ["Antistasin"],
  },
  {
    primary_id: "D01FFX",
    names: [
      "4-((Naphthalen-2-Yloxy)Methyl)Piperidine",
      "4-[(2-Naphthyloxy)Methyl]piperidine",
      "CHEMBL453996",
      "946680-75-7",
      "4-[(Naphthalen-2-Yloxy)Methyl]piperidine",
      "CTK7D1529",
      "ZINC14631494",
      "BDBM50278526",
      "AKOS000172158",
    ],
  },
  {
    primary_id: "D01FGM",
    names: [
      "CHRYSOERIOL",
      "Chrysoeriol",
      "491-71-4",
      "Chryseriol",
      "Luteolin 3'-Methyl Ether",
      "3'-O-Methylluteolin",
      "3'-O-Methyluteolin",
      "5,7-Dihydroxy-2-(4-Hydroxy-3-Methoxyphenyl)-4-Benzopyrone",
      "5,7-Dihydroxy-2-(4-Hydroxy-3-Methoxyphenyl)-4H-Chromen-4-One",
      "5,7-Dihydroxy-2-(4-Hydroxy-3-Methoxyphenyl)-4H-1-Benzopyran-4-One",
      "4H-1-Benzopyran-4-One, 5,7-Dihydroxy-2-(4-Hydroxy-3-Methoxyphenyl)-",
      "UNII-Q813145M20",
      "EINECS 207-742-6",
      "BRN 0295004",
      "CHEMBL214321",
      "CHEBI:16514",
    ],
  },
  {
    primary_id: "D01FGR",
    names: [
      "Diphenhydramine",
      "58-73-1",
      "Benadryl",
      "Benzhydramine",
      "Probedryl",
      "Alledryl",
      "Antistominum",
      "Dihidral",
      "2-(Benzhydryloxy)-N,N-Dimethylethanamine",
      "Benzhydraminum",
      "Benzhydroamina",
      "Diphantine",
      "Difenhydramin",
      "Ibiodral",
      "Difedryl",
      "Dibondrin",
      "Mephadryl",
      "Desentol",
      "Dermistina",
      "Betramin",
      "Benzhydril",
      "Benzantine",
      "Benachlor",
      "Bagodryl",
      "Syntedril",
      "Novamina",
      "Medidryl",
      "Hyadrine",
      "Histaxin",
      "Etanautine",
      "Drylistan",
      "Diabylen",
      "Diabenyl",
      "Dermodrin",
      "Benodine",
      "Baramine",
      "Allergical",
      "Debendrin",
      "Benadrin",
      "Dabylen",
      "Antomin",
      "Nausen",
      "Benapon",
      "Dylamon",
      "Benylin",
      "Benylan",
      "Benodin",
      "Amidryl",
      "Aleryl",
      "Allerdryl",
      "Allergeval",
      "Allergina",
      "Allergival",
      "Antitussive",
      "Automin",
      "Banophen",
      "Beldin",
      "Belix",
      "Bena",
      "Benhydramin",
      "Benzhydryl",
      "Compoz",
      "Dibendrin",
      "Dibenil",
      "Difenhidramina",
      "Difenidramina",
      "Dimedrol",
      "Dimedryl",
      "Dimehydrinate",
      "Diphen",
      "Diphenhist",
      "Diphenhydraminum",
      "Diphenylhydramin",
      "Diphenylhydramine",
      "Dryistan",
      "Genahist",
      "Hydramine",
      "Hyrexin",
      "Restamin",
      "Rigidil",
      "Siladryl",
      "Silphen",
      "Syntodril",
      "Allergan B",
      "Allermax Caplets",
      "BENADRYL HCl",
      "Banophen Caplets",
      "Benadryl Allergy",
      "Difenidramina [Italian]",
      "Dimedrol Base",
      "Diphen Cough",
      "Diphenhist Captabs",
      "Diphenhydramine Base",
      "Diphenhydramine HCl",
      "Nytol Quickcaps",
      "Nytol Quickgels;Twilite Caplets",
      "Unisom Sleepgels Maximum Strength",
      "Dormarex 2",
      "FAR 90X2",
      "PM 255",
      "S51",
      "Aller-Med",
      "Ben-Allergin",
      "Benadryl (TN)",
      "Benadryl (Hydrochloride)",
      "Beta-Dimethylaminoethanol Diphenylmethyl Ether",
      "Beta-Dimethylaminoethylbenzhydrylether",
      "Beta-Dimethylaminoethyl Benzhydryl Ether",
      "Difenhidramina [INN-Spanish]",
      "Dimedrol (TN)",
      "Diphenhydraminum [INN-Latin]",
      "Nervine Nighttime Sleep-Aid",
      "Nytol (TN)",
      "O-Benzhydryldimethylaminoethanol",
      "Restamin (TN)",
      "Sleep-Eze D",
      "Sleep-Eze D Extra Strength",
      "DIPHENHYDRAMINE, ANTISTOMINUM, BENZHYDRAMINE",
      "Diphenhydramine (JP15/INN)",
      "Diphenhydramine [INN:BAN:JAN]",
      "Alpha-(2-Dimethylaminoethoxy)Diphenylmethane",
      "N-(Benzhydryloksy-Etylo)Dwumetyloamina",
      "N-(Benzhydryloksy-Etylo)Dwumetyloamina [Polish]",
      "Beta-Dimethylamino-Aethyl-Benzhydryl-Aether",
      "Beta-Dimethylamino-Aethyl-Benzhydryl-Aether [German]",
      "N,N-Dimethyl-2-(Diphenylmethoxy)-Ethylamine Hydrochloride",
      "N-[2-(BENZHYDRYLOXY)ETHYL]-N,N-DIMETHYLAMINE",
      "N-(2-(Diphenylmethoxy)Ethyl)-N,N-Dimethylamine",
      "2-(Benzhydryloxy)-N,N-Dimethylethylamine",
      "2-(Benzhydryloxy)-N,N-Dimethylethylamine, Hydrochloride",
      "2-(Diphenylmethoxy)-N,N-Dimethylethylamine",
      "2-(Diphenylmethoxy)-N,N-Dimethylethanamine",
      "2-Diphenylmethoxy-N,N-Dimethylethylamine",
      "2-[(Diphenylmethyl)Oxy]-N,N-Dimethylethanamine",
      "2-Benzhydryloxy-N,N-Dimethylethanamine",
      "2-Benzhydryloxyethyl-N,N-Dimethylammonium",
      "2-Diphenylmethoxy-N,N-Demthylethanamine",
      "2PM",
    ],
  },
  {
    primary_id: "D01FGW",
    names: ["6-Bromo-N-(3-Bromophenyl)Quinazolin-4-Amine", "CHEMBL1081536"],
  },
  {
    primary_id: "D01FHK",
    names: [
      "Boc-Tyr(SO3H)-Nle-Gly-Trp-Nle-Asp-Phe-NH2",
      "CHEMBL384035",
      "Cholecystokinin (27-33), Tert-Butyloxycarbonyl-Nle(28,31)-",
      "98640-66-5",
      "Boc-28,31-Nle-Cck-7",
      "BDNL",
      "Boc-Tyr(SO3H)Nle-Gly-Trp-Nle-Asp-Phenh2",
      "T-Boc(Nle(28,31))-Cck (27-33)",
      "AC1MJ67S",
      "DTXSID90243747",
      "Tert-Butyloxycarbonyl-28,31-Nle-Cholecystokinin (27-33)",
      "Cholecystokinin (27-33), Tert-Butyloxycarbonylnorleucyl(28,31)-",
      "BDBM50016425",
      "Tert-Butoxycarbonyltyrosyl(Sulfo)-Norleucyl-Glycyl-Tryptophyl-Norleucyl-Aspartyl-Phenylalaninamide",
    ],
  },
  {
    primary_id: "D01FHL",
    names: ["AGN-150998"],
  },
  {
    primary_id: "D01FIM",
    names: ["PMID25772215-Compound-WO2012177618M6"],
  },
  {
    primary_id: "D01FJA",
    names: ["UC-2024"],
  },
  {
    primary_id: "D01FJB",
    names: [
      "6-(Cyclohex-3-Enylmethoxy)-9H-Purin-2-Ylamine",
      "CHEMBL115498",
      "O6-Substituted Guanine Deriv. 27",
      "AC1NS6ZQ",
      "9H-Purin-2-Amine, 6-(3-Cyclohexen-1-Ylmethoxy)-",
      "SCHEMBL6267556",
      "BDBM5487",
      "220035-95-0",
      "6-(Cyclohex-3-En-1-Ylmethoxy)-9H-Purin-2-Amine",
    ],
  },
  {
    primary_id: "D01FJE",
    names: ["ISOSAKUTANETIN"],
  },
  {
    primary_id: "D01FJN",
    names: ["BAY-1006451", "[18F]-BAY-1006451"],
  },
  {
    primary_id: "D01FJQ",
    names: ["Pro-His-Pro-His-Phe-Phe-Val-Tyr", "CHEMBL384760"],
  },
  {
    primary_id: "D01FJT",
    names: ["Bucillamine"],
  },
  {
    primary_id: "D01FLN",
    names: ["LX-2932"],
  },
  {
    primary_id: "D01FLQ",
    names: [
      "2-Methoxyphenylboronic Acid",
      "5720/6/9",
      "2-Methoxybenzeneboronic Acid",
      "(2-Methoxyphenyl)Boronic Acid",
      "2-Methoxyphenyl Boronic Acid",
      "O-Methoxyphenylboronic Acid",
      "2-Boronoanisole",
      "(2-Methoxyphenyl)Boranediol",
      "MFCD00236047",
      "O-Methoxyphenyl Boronic Acid",
      "2-Methoxy Phenylboronic Acid",
      "2-Methoxybenzene Boronic Acid",
      "Boronic Acid, (2-Methoxyphenyl)-",
      "2-Methoxypenylboronic Acid",
      "2-Methoxybenzeneboronicacid",
      "PubChem1865",
      "ACMC-1AZDE",
      "2-Methoxypbenylboronic Acid",
      "AC1Q44YL",
      "AC1MC01Q",
      "2-Methyoxyphenylboronic Acid",
      "2-Methoxyphenyl-Boronic Aci",
    ],
  },
  {
    primary_id: "D01FLR",
    names: ["Vanilloid VR1 Receptor Antagonists"],
  },
  {
    primary_id: "D01FME",
    names: ["HNK-20"],
  },
  {
    primary_id: "D01FMS",
    names: ["AP-Cav"],
  },
  {
    primary_id: "D01FMW",
    names: ["Fused Heterocyclic Compound 3"],
  },
  {
    primary_id: "D01FNC",
    names: [
      "PMID24440480C3",
      "99573-25-8",
      "ACMC-20m2v2",
      "GTPL8682",
      "CTK3F1142",
      "DTXSID60617875",
      "BDBM50447757",
      "AKOS030554829",
      "SR-02000001193",
      "SR-02000001140",
      "SR-02000001193-1",
      "SR-02000001140-2",
    ],
  },
  {
    primary_id: "D01FNH",
    names: [
      "4-Methoxyphenyl 10H-Phenothiazine-10-Carboxylate",
      "CHEMBL481872",
      "SCHEMBL4453079",
      "MolPort-004-877-802",
      "BDBM50292614",
      "STK354315",
      "ZINC20085903",
      "AKOS005442135",
    ],
  },
  {
    primary_id: "D01FOK",
    names: ["7-Ethoxy-8-Propionyl-2H-Chromen-2-One", "CHEMBL1288622"],
  },
  {
    primary_id: "D01FOM",
    names: [
      "4-(Piperazin-1-Yl)Thieno[2,3-D]pyrimidine",
      "373356-48-0",
      "1-{thieno[2,3-D]pyrimidin-4-Yl}piperazine",
      "4-Piperazin-1-Ylthieno[2,3-D]pyrimidine",
      "CHEMBL596108",
      "1-(Thieno[2,3-D]pyrimidin-4-Yl)Piperazine",
      "AC1Q1I4T",
      "SCHEMBL4922800",
      "CTK7D1707",
      "MolPort-001-760-894",
      "ZINC12954996",
      "SBB095996",
      "BDBM50304810",
      "AKOS000123564",
      "MCULE-6714459164",
      "PS-3311",
      "NE24045",
      "KS-000029X0",
      "4-Piperazinylthiopheno[2,3-D]pyrimidine",
      "KB-85055",
      "DA-06355",
      "4-Piperazin-1-Yl-Thieno[2,3-D]pyrimidine",
      "KB-243074",
      "FT-0727983",
      "EN300-29445",
      "Thieno[2,3-D]pyrimidine, 4-(1-Piperaziny",
    ],
  },
  {
    primary_id: "D01FPD",
    names: ["Salvinorin B 2-Fluoroethoxymethyl Ether", "CHEMBL271077"],
  },
  {
    primary_id: "D01FPR",
    names: ["PMID28074661-Compound-US20120264760C80"],
  },
  {
    primary_id: "D01FPX",
    names: [
      "5-Amino-2-P-Tolyl-Oxazole-4-Carboxylic Acid Amide",
      "5-Amino-2-(4-Methylphenyl)-1,3-Oxazole-4-Carboxamide",
      "CHEMBL382993",
      "CTK6B8237",
      "MolPort-000-894-518",
      "SBB024037",
      "BDBM50186628",
      "STK351079",
      "ZINC12394806",
      "AKOS000313592",
      "MCULE-2038651256",
    ],
  },
  {
    primary_id: "D01FQR",
    names: ["Ocaperidone"],
  },
  {
    primary_id: "D01FQV",
    names: [
      "Dichloroacetate Sodium",
      "Dichloroacetate Sodium (Pulmonary Arterial Hypertension), University Of Alberta/Imperial College London",
    ],
  },
  {
    primary_id: "D01FQZ",
    names: ["Erythribyssin O"],
  },
  {
    primary_id: "D01FRF",
    names: ["Trequinsin"],
  },
  {
    primary_id: "D01FRI",
    names: ["PMID27967267-Compound-15"],
  },
  {
    primary_id: "D01FRK",
    names: ["LA-N8"],
  },
  {
    primary_id: "D01FRP",
    names: [
      "APC-0013",
      "APC-0014",
      "APC-0015",
      "STAT-3 Signal Transduction Inhibitors (Cancer)",
      "STAT-3 Signal Transduction Inhibitors (Cancer), Shanghai Ambrosia Pharmaceutical",
    ],
  },
  {
    primary_id: "D01FSW",
    names: ["(D-Arg)(9)-P19(ARF) 26-44 Peptide"],
  },
  {
    primary_id: "D01FTB",
    names: [
      "2-(4-Chlorophenyl)-4,5-Dihydro-1H-Imidazole",
      "13623-52-4",
      "CHEMBL448710",
      "1H-Imidazole,2-(4-Chlorophenyl)-4,5-Dihydro-",
      "NSC129258",
      "AC1L5PGD",
      "AC1Q3R0Q",
      "AC1Q3IU9",
      "1h-Imidazole, 2-(4-Chlorophenyl)-4,5-Dihydro-",
      "SCHEMBL523059",
      "CTK4C0220",
      "DTXSID90299300",
      "XUMWMBLBTZAVLW-UHFFFAOYSA-N",
      "MolPort-005-311-522",
      "ZINC608390",
      "2-(4-Chlorophenyl)-2-Imidazoline",
      "2-(4-Chlorophenyl)-1-Imidazoline",
      "BDBM50266028",
      "STK367850",
      "AKOS005444786",
      "NE32890",
      "NSC-129258",
      "MCULE-4125153952",
      "2-Imidazoline, 2-(P-Chloro-Phenyl)-",
      "DB-063124",
      "FT-0766187",
      "EN300-29599",
    ],
  },
  {
    primary_id: "D01FTK",
    names: ["PN-403"],
  },
  {
    primary_id: "D01FTP",
    names: ["2-Methyl-7-M-Tolylquinoline", "CHEMBL231765"],
  },
  {
    primary_id: "D01FTR",
    names: ["PMID25991433-Compound-C3"],
  },
  {
    primary_id: "D01FTU",
    names: ["EQ-317"],
  },
  {
    primary_id: "D01FTV",
    names: ["Cyramza"],
  },
  {
    primary_id: "D01FUB",
    names: [
      "AZD8529",
      "AZD-8529",
      "UNII-6H81G454I7",
      "CHEMBL3937907",
      "6H81G454I7",
      "7-Methyl-5-(3-Piperazin-1-Ylmethyl-(1,2,4)Oxadiazol-5-Yl)-2-(4-Trifluoromethoxybenzyl)-2,3-Dihydroisoindol-1-One",
      "7-Methyl-5-(3-Piperazin-1-Ylmethyl-[1,2,4]oxadiazol-5-Yl)-2-(4-Trifluoromethoxybenzyl)-2,3-Dihydroisoindol-1-One",
      "GTPL7678",
      "SCHEMBL2124540",
      "AZD 8529",
      "ZINC43201296",
      "BDBM50194618",
      "HY-107457",
      "CS-0028525",
      "1092453-15-0",
      "7-Methyl-5-[3-(Piperazin-1-Ylmethyl)-1,2,4-Oxadiazol-5-Yl]-2-[[4-(Trifluoromethoxy)Phenyl]methyl]-3H-Isoindol-1-One",
    ],
  },
  {
    primary_id: "D01FUC",
    names: ["Exatecan"],
  },
  {
    primary_id: "D01FUJ",
    names: ["GIP/GLP-1 Co-Agonist Peptide"],
  },
  {
    primary_id: "D01FUL",
    names: [
      "Balapiravir",
      "Balapiravir Hydrochloride",
      "HCV NS5B Polymerase Inhibitor, Roche",
      "RO4588161-001",
      "RNA Polymerase Inhibitor (HCV Infection), Roche",
      "RNA Polymerase Inhibitor (Hepatitis C Infection), Roche",
      "R-1479 Prodrug (HCV Infection), Roche",
    ],
  },
  {
    primary_id: "D01FVC",
    names: ["Bis-Aminopyrimidine Derivative 1"],
  },
  {
    primary_id: "D01FVY",
    names: [
      "N-(2,3,4,9-Tetrahydro-1H-Carbazol-3-Yl)-Acetamide",
      "60480-69-5",
      "N-(2,3,4,9-TETRAHYDRO-1H-CARBAZOL-3-YL)ACETAMIDE",
      "CHEMBL41973",
      "3-Acetamido-1,2,3,4-Tetrahydrocarbazole",
      "SCHEMBL7406266",
      "KS-00000TMN",
      "CTK2F0321",
      "DTXSID90494196",
      "MolPort-042-663-532",
      "DNJBLXBGYRYOBT-UHFFFAOYSA-N",
      "BDBM50212923",
      "AKOS027461084",
      "3-Acetamido-1,2,3,4-Tetrahydrocarbazol",
      "DS-19200",
      "AK543106",
      "Acetamide, N-(2,3,4,9-Tetrahydro-1H-Carbazol-3-Yl)-",
    ],
  },
  {
    primary_id: "D01FWD",
    names: [
      "1-Phenyl-1H-Benzoimidazole",
      "2622-60-8",
      "1-Phenyl-1H-Benzo[d]imidazole",
      "1-Phenylbenzimidazole",
      "1-Phenyl-1H-Benzimidazole",
      "97542-80-8",
      "1H-Benzimidazole,1-Phenyl-",
      "BAS 00350928",
      "1H-Benzimidazole, 1-Phenyl-",
      "XNCMQRWVMWLODV-UHFFFAOYSA-N",
      "1-PHENYL-1H-1,3-BENZODIAZOLE",
      "AK123264",
      "AC1LCTXI",
      "PubChem19972",
      "1-Phenyl Benzoimidazole",
      "1-Phenylbenzimidazole 3",
      "Phenyl-1H-?Benzimidazole",
      "1-Phenyl-1,3-Benzodiazole",
      "MLS000765952",
      "SCHEMBL157265",
      "1-Phenyl-1H-Benzimidazole #",
      "CHEMBL310080",
      "BDBM3794",
      "CTK4F7433",
      "DTXSID10345732",
      "1-Phenylbenzimidazole Deriv.",
    ],
  },
  {
    primary_id: "D01FWE",
    names: [
      "Certiva",
      "Anavacel",
      "Certiva-EU",
      "DTaP Vaccine, North American Vaccine",
      "Vaccine (Diphtheria/Tetanus/Pertussis), North American Vaccine",
    ],
  },
  {
    primary_id: "D01FWI",
    names: ["Wound Healing Agents"],
  },
  {
    primary_id: "D01FWL",
    names: ["Neridronate"],
  },
  {
    primary_id: "D01FWR",
    names: ["MPL-Containing Pollinex Allergy Desensitization Vaccine"],
  },
  {
    primary_id: "D01FXD",
    names: ["SCH-351591"],
  },
  {
    primary_id: "D01FYA",
    names: ["PU-H71"],
  },
  {
    primary_id: "D01FYB",
    names: ["N-(2-Aminophenyl)Quinoxaline-6-Carboxamide"],
  },
  {
    primary_id: "D01FYX",
    names: ["Andes-1537"],
  },
  {
    primary_id: "D01FYY",
    names: ["SP-650003"],
  },
  {
    primary_id: "D01FZX",
    names: ["LEO 22811"],
  },
  {
    primary_id: "D01FZZ",
    names: ["GDC0310"],
  },
  {
    primary_id: "D01GAG",
    names: ["PV-921"],
  },
  {
    primary_id: "D01GAR",
    names: [
      "1-[(N-Hex-1-Ylamino)Ethyl]-1,1-Bisphosphonic Acid",
      "CHEMBL259192",
      "[2-(Hexylamino)Ethane-1,1-Diyl]bis(Phosphonic Acid)",
      "SCHEMBL8674064",
      "BDBM50341917",
      "2-(Hexylamino)Ethane-1,1-Diyldiphosphonic Acid",
      "[2-(Hexylamino)-1-Phosphono-Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D01GAX",
    names: [
      "1-Methyl-4-Phenyl-1,2,3,6-Tetrahydropyridine",
      "MPTP",
      "28289-54-5",
      "CCRIS 2186",
      "1,2,3,6-Tetrahydro-1-Methyl-4-Phenylpyridine",
      "HSDB 6942",
      "PYRIDINE, 1,2,3,6-TETRAHYDRO-1-METHYL-4-PHENYL-",
      "1-METHYL-4-PHENYL-PIPERIDINE-3-EN",
      "UNII-9P21XSP91P",
      "N-Methyl-4-Phenyl-1,2,3,6-Tetrahydropyridine",
      "NSC 139654",
      "EINECS 248-939-7",
      "1-Methyl-4-Phenyl-1,2,3,6-Tetrahydroxpyridine",
      "BRN 0133712",
      "CHEMBL24172",
      "9P21XSP91P",
      "1-Methyl-4-Phenyl-3,6-Dihydro-2H-Pyridine",
      "CHEBI:17963",
      "1-Methyl-4-Phenyl-1,2,5,6-Tetrahydropyridine",
      "PLRACCBDVIHHLZ-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D01GAZ",
    names: [
      "123I-DRM-106",
      "DRM-106-[123I]",
      "Iodine-123-DRM-106",
      "SPECT Diagnostic (Alzheimer's Disease), Fujifilm RI Pharma Co Ltd",
    ],
  },
  {
    primary_id: "D01GBV",
    names: ["CDD-190"],
  },
  {
    primary_id: "D01GBY",
    names: [
      "Desloratadine",
      "Aerius",
      "Allex",
      "Azomyr",
      "Claramax",
      "Clarinex",
      "Denosin",
      "Desalex",
      "Descarboethoxyloratadine",
      "Descarboethoxyoratidine",
      "Desloratidine",
      "Neoclarityn",
      "Opulis",
      "Clarinex RediTabs",
      "Desloratadine [USAN]",
      "Essex Brand Of Desloratadine",
      "Schering Brand Of Desloratadine",
      "Sch 34117",
      "Aerius (TN)",
      "Claramax (TN)",
      "Clarinex (TN)",
      "NeoClarityn (TN)",
      "Sch-34117",
      "Schering-Plough Brand Of Desloratadine",
      "Desloratadine (USAN/INN)",
      "8-Chloro-11-Piperidin-4-Ylidene-6,11-Dihydro-5H-Benzo[5,6]cyclohepta[1,2-B]pyridine",
      "8-Chloro-6,11-Dihydro-11-(4-Piperidinylidene)-5H-Benzo(5,6)Cyclohepta(1,2-B)Pyridine",
      "8-Chloro-11-(Piperidin-4-Ylidene)-6,11-Dihydro-5H-Benzo[5,6]cyclohepta[1,2-B]pyridine",
    ],
  },
  {
    primary_id: "D01GCI",
    names: ["PMID25776143-Compound-7"],
  },
  {
    primary_id: "D01GCT",
    names: ["AZM-090"],
  },
  {
    primary_id: "D01GCY",
    names: ["NNC-26-0762"],
  },
  {
    primary_id: "D01GDB",
    names: ["Tat Oyi-Cys22"],
  },
  {
    primary_id: "D01GDH",
    names: ["XR-9051"],
  },
  {
    primary_id: "D01GDO",
    names: [
      "Cytoporins",
      "Cytoporins (Lung Infection)",
      "HB-12",
      "HB-146",
      "HB-153",
      "HB-71",
      "HB-CM2",
      "HB-CM3",
      "HB-CPalpha2",
      "HB-PM4",
      "Cecropin A-Melittin Peptide Hybrids (Bacterial Infection)",
      "Cytoporins (Lung Infection), Helix BioMedix",
      "HB-CPa-2",
      "CA(1-7)M(2-9)",
      "CA(1-7)M(5-9)",
      "CA(1-8)M(1-18)",
      "Oct-CA(1-7)M(2-9)",
    ],
  },
  {
    primary_id: "D01GEB",
    names: ["ROXINDOLE"],
  },
  {
    primary_id: "D01GEE",
    names: ["Piperidinyl Pyrazole Derivative 4"],
  },
  {
    primary_id: "D01GEK",
    names: ["PMID25666693-Compound-133"],
  },
  {
    primary_id: "D01GEO",
    names: ["Telomestatin"],
  },
  {
    primary_id: "D01GFU",
    names: ["ADCON(R)", "ADCON-A", "ADCON-P"],
  },
  {
    primary_id: "D01GFX",
    names: ["OSI-906"],
  },
  {
    primary_id: "D01GGB",
    names: ["SL-301"],
  },
  {
    primary_id: "D01GGU",
    names: [
      "9-Butyl-8-(3-Methoxybenzyl)-9h-Purin-6-Amine",
      "9-BUTYL-8-(3-METHOXYBENZYL)-9H-PURIN-6-AMINE",
      "CHEMBL109612",
      "AC1NRCWL",
      "1uy8",
      "Purine-Based Inhibitor 3",
      "SCHEMBL1966787",
      "BDBM15376",
      "BWLWUGBHOXIUBP-UHFFFAOYSA-N",
      "DB03809",
      "8-(3-Methoxybenzyl)-9-Butyl-9H-Purine-6-Amine",
      "9-Butyl-8-[(3-Methoxyphenyl)Methyl]purin-6-Amine",
      "9-Butyl-8-(3-Methoxy-Benzyl)-9H-Purin-6-Ylamine",
    ],
  },
  {
    primary_id: "D01GHV",
    names: ["RANBEZOLID HYDROCHLORIDE"],
  },
  {
    primary_id: "D01GIF",
    names: ["BM-113", "1-[2-(Benzhydryloxy)Ethyl]piperidine-4-Acetic Acid Ethyl Ester"],
  },
  {
    primary_id: "D01GIG",
    names: ["RepliVec"],
  },
  {
    primary_id: "D01GIW",
    names: ["S3"],
  },
  {
    primary_id: "D01GJI",
    names: ["GS-9256 + Tegobuvir"],
  },
  {
    primary_id: "D01GLG",
    names: ["Deoxythymidine"],
  },
  {
    primary_id: "D01GLR",
    names: ["ISIS 103387"],
  },
  {
    primary_id: "D01GMJ",
    names: [
      "1,4-Dithiothreitol",
      "Cleland's Reagent",
      "Dithiothreitol",
      "Dithiotreitol",
      "1,4-Dimercaptobutane-2,3-Diol",
      "Dl-Dithiothreitol",
      "Rac-Dithiothreitol",
      "Cleland Reagent",
      "7634-42-6",
      "1,4-Disulfanylbutane-2,3-Diol",
      "Clelands Reagent",
      "Sputolysin",
      "DL-Threo-1,4-Dimercapto-2,3-Butanediol",
      "1,4-Dimercapto-2,3-Butanediol",
      "3483/12/3",
      "2,3-Butanediol, 1,4-Dimercapto-",
      "Threitol, 1,4-Dithio-",
      "1,4-Bis(Sulfanyl)Butane-2,3-Diol",
      "Trans-1,4-Dimercaptobutane-2,3-Diol",
      "L-1,4-Dithiothreitol",
      "L-Dithiothreitol",
      "CHEBI:18320",
      "1,4-Bissulfanylbutane-2,3-Diol",
    ],
  },
  {
    primary_id: "D01GNF",
    names: ["BAS-00387328", "CHEMBL210766", "SCHEMBL5737024", "BDBM50187569"],
  },
  {
    primary_id: "D01GNL",
    names: ["Pyrazolopyridine Derivative 5"],
  },
  {
    primary_id: "D01GNQ",
    names: ["Phenoxyarsonous Acid"],
  },
  {
    primary_id: "D01GNW",
    names: ["NGN-9076"],
  },
  {
    primary_id: "D01GON",
    names: ["Peptide Analog 38"],
  },
  {
    primary_id: "D01GOO",
    names: ["PMID27109571-Compound-14"],
  },
  {
    primary_id: "D01GPA",
    names: ["Gepon"],
  },
  {
    primary_id: "D01GPC",
    names: ["PMID21273063C58"],
  },
  {
    primary_id: "D01GPY",
    names: [
      "6-(4-Hydroxy-2-Methyl-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL195389",
      "SCHEMBL4077781",
      "YIKDKHNOMWERET-UHFFFAOYSA-N",
      "ZINC13645062",
      "BDBM50168377",
    ],
  },
  {
    primary_id: "D01GQD",
    names: ["EZN-3042"],
  },
  {
    primary_id: "D01GQO",
    names: ["Mesenchymal Stem Cell Therapy, Ischemic-Tolerant Stem Cells"],
  },
  {
    primary_id: "D01GRB",
    names: ["2MeSAMP", "2-Methylthio-AMP", "Poly(2'-Methylthioadenylic Acid)"],
  },
  {
    primary_id: "D01GSA",
    names: ["C-0334578"],
  },
  {
    primary_id: "D01GSE",
    names: ["E-10030"],
  },
  {
    primary_id: "D01GSG",
    names: ["(R)-6-(Pyrrolidin-3-Ylmethoxy)-2-Naphthonitrile", "CHEMBL506397"],
  },
  {
    primary_id: "D01GSO",
    names: ["Diethyl 2,2,2-Trifluoro-1-Phenylethyl Phosphate", "CHEMBL575301"],
  },
  {
    primary_id: "D01GSU",
    names: ["PMID25991433-Compound-H2"],
  },
  {
    primary_id: "D01GSW",
    names: ["ISAINDIGOTONE"],
  },
  {
    primary_id: "D01GTB",
    names: [
      "ZD-2767",
      "Colorectal Cancer Therapy (ADEPT), AstraZeneca/CRCT",
      "Colorectal Cancer Therapy (ADEPT), Zeneca/CRCT",
    ],
  },
  {
    primary_id: "D01GTC",
    names: ["TAK-041"],
  },
  {
    primary_id: "D01GTL",
    names: ["ISIS 134602"],
  },
  {
    primary_id: "D01GTU",
    names: ["PMID28454500-Compound-8"],
  },
  {
    primary_id: "D01GUG",
    names: ["12-O-Deacetyl-12-Epi-19-Deoxy-21-Hydroxyscalarin", "CHEMBL251695", "CHEBI:68038", "BDBM50226460"],
  },
  {
    primary_id: "D01GUK",
    names: ["Cyclopentyl-(9-Phenyl-9H-Purin-6-Yl)-Amine", "CHEMBL319010", "BDBM50009699"],
  },
  {
    primary_id: "D01GUS",
    names: ["Udenafil"],
  },
  {
    primary_id: "D01GVC",
    names: ["GP-3269"],
  },
  {
    primary_id: "D01GVM",
    names: [
      "Lemuteporfin",
      "A-EA 6",
      "QLT-0074",
      "QLT-00748",
      "Immunomodulator, QLT/Valocor",
      "Third Generation Photosensitizers, QLT/Valocor",
    ],
  },
  {
    primary_id: "D01GWH",
    names: ["N-(1-(4-Phenylbutyl)Piperidin-4-Yl)-2-Naphthamide", "CHEMBL229025"],
  },
  {
    primary_id: "D01GWN",
    names: ["(24S)-Ethylcholesta-7,9(11),22(E)-Triene-3b-Ol", "CHEMBL202119"],
  },
  {
    primary_id: "D01GWS",
    names: ["Pyridinone Compound 1"],
  },
  {
    primary_id: "D01GXB",
    names: ["Combinations SAR245409 / MSC1936369B SAR245408/SAR256212"],
  },
  {
    primary_id: "D01GXL",
    names: ["REP8839"],
  },
  {
    primary_id: "D01GYK",
    names: ["Succimer"],
  },
  {
    primary_id: "D01GYS",
    names: ["Tyr-Gly-Gly-Phe-Leu-C(Cys-Arg-Ile-Arg-Cys)-Lys", "CHEMBL265241"],
  },
  {
    primary_id: "D01GYT",
    names: ["Fosfomycin"],
  },
  {
    primary_id: "D01GZV",
    names: ["N-(4-Ethoxybutyl)-2-Oxohexadecanamide", "CHEMBL461318"],
  },
  {
    primary_id: "D01HAT",
    names: ["CD19.CAR-ANKT Cells"],
  },
  {
    primary_id: "D01HAZ",
    names: [
      "3-(Dibutylamino)-1-(4-Hexylphenyl)Propan-1-One",
      "Beta-Aminophenylketone, 3g",
      "CHEMBL396086",
      "SCHEMBL15862326",
      "BDBM18820",
    ],
  },
  {
    primary_id: "D01HBE",
    names: ["RHIZOXIN", "90996-54-6", "Rhizoxin From Rhizopus Sp."],
  },
  {
    primary_id: "D01HBG",
    names: [
      "Apadoline",
      "ACMC-20mvlw",
      "SCHEMBL7518667",
      "CTK8G8521",
      "10H-Phenothiazine-2-Carboxamide,10-[(1R)-1-Methyl-2-(1-Pyrrolidinyl)Ethyl]-N-Propyl-",
      "KXMAIWXPZGQNCR-UHFFFAOYSA-N",
      "L001386",
      "N-Propyl-10-[1-(Pyrrolidinyl)-2-Propyl]-2-Phenothiazinecarboxamide",
      "N-Propyl-10-[1-(1-Pyrrolidinyl)-2-Propyl]-2-Phenothiazinecarboxamide",
    ],
  },
  {
    primary_id: "D01HBS",
    names: [
      "Cyclohexylcarbamic Acidbiphenyl-3-Yl Ester",
      "CHEMBL431202",
      "Biphenyl-N-Cyclopentyl-Carbamate",
      "546141-07-5",
      "URB524",
      "URB-524",
      "SCHEMBL530169",
      "3-Biphenylyl Cyclohexylcarbamate",
      "Biphenyl-3-Yl-Cyclohexylcarbamate",
      "Biphenyl-3-Yl Cyclohexylcarbamate",
      "ZINC3816417",
      "BDBM50128585",
      "AKOS015909861",
      "KB-48012",
      "Cyclohexyl-Carbamic Acid Biphenyl-3-Yl Ester",
    ],
  },
  {
    primary_id: "D01HCL",
    names: ["13,14-Dihydro-PGE1", "13,14-Dihydro-Prostaglandin E1"],
  },
  {
    primary_id: "D01HCO",
    names: [
      "2-(2-Propoxypyrimidin-4-Ylamino)Benzoic Acid",
      "4-Anilinopyrimidine 6b",
      "CHEMBL242489",
      "BDBM15980",
      "2-[(2-Propoxypyrimidin-4-Yl)Amino]benzoic Acid",
    ],
  },
  {
    primary_id: "D01HCR",
    names: ["Benzaldehyde O-4-(Octyloxy)Phenylcarbamoyl Oxime", "CHEMBL597229"],
  },
  {
    primary_id: "D01HDD",
    names: [
      "2-Benzyl-4,5,6,7-Tetrachloroisoindoline-1,3-Dione",
      "CHEMBL11516",
      "63586-13-0",
      "2-Benzyl-4,5,6,7-Tetrachloro-Isoindole-1,3-Dione",
      "2-Benzyl-4,5,6,7-Tetrachloro-1H-Isoindole-1,3(2H)-Dione",
      "AC1LK1ZO",
      "Oprea1_023833",
      "2-Benzyl-4,5,6,7-Tetrachloroisoindole-1,3-Dione",
      "SCHEMBL10675358",
      "CTK2A8819",
      "DTXSID60359687",
      "MolPort-001-020-850",
      "ZINC630019",
      "STK246502",
      "BDBM50088678",
      "AKOS003243470",
      "MCULE-7798575308",
      "N-Benzyl-3,4,5,6-Tetrachlorophthalimide",
      "ST50854964",
      "AK-918/11423009",
      "4,5,6,7-Tetrachloro-2-Benzylbenzo[c]azolidine-1,3-Dione",
    ],
  },
  {
    primary_id: "D01HDP",
    names: [
      "SLV 306",
      "Daglutril",
      "SLV-306",
      "SLV306",
      "UNII-KKV299446X",
      "182821-27-8",
      "KKV299446X",
      "((3S)-3-{1-((2R)-2-Ethoxycarbonyl-4-Phenylbutyl)Cyclopentanecarboxamido}-2-Oxo-2,3,4,5-Tetrahydro-1H-1-Benzazepin-1-Yl)Acetic Acid",
      "Daglutril [INN]",
      "2-[(3S)-3-[[1-[(2R)-2-Ethoxycarbonyl-4-Phenylbutyl]cyclopentanecarbonyl]amino]-2-Oxo-4,5-Dihydro-3H-1-Benzazepin-1-Yl]acetic Acid",
      "1H-1-Benzazepine-1-Acetic Acid, 3-(((1-(2-(Ethoxycarbonyl)-4-Phenylbutyl)Cyclopentyl)Carbonyl)Amino)-2,3,4,5-Tetrahydro-2-Oxo-, (S-(R*,S*))-",
    ],
  },
  {
    primary_id: "D01HED",
    names: ["PMID25666693-Compound-158"],
  },
  {
    primary_id: "D01HEN",
    names: ["HBDt.Fc"],
  },
  {
    primary_id: "D01HFI",
    names: ["Bifenazate Acequinocyl"],
  },
  {
    primary_id: "D01HFL",
    names: ["2,4,4-Triphenylimidazoline", "CHEMBL493970", "SCHEMBL4463957", "BDBM50257786"],
  },
  {
    primary_id: "D01HFP",
    names: ["HL-160"],
  },
  {
    primary_id: "D01HGI",
    names: [
      "TGSC01AA(4)",
      "Benzodiazepine Receptor Binder (Anxiety), Fabre Kramer Pharmaceuticals",
      "TGSC01AA Series (Anxiety), Fabre Kramer Pharmaceuticals",
    ],
  },
  {
    primary_id: "D01HGT",
    names: ["Anti-CD22"],
  },
  {
    primary_id: "D01HGV",
    names: ["L-708,906"],
  },
  {
    primary_id: "D01HHD",
    names: ["Fovepta"],
  },
  {
    primary_id: "D01HHH",
    names: [
      "CHR-4125",
      "CHR-4146",
      "CHR-5010",
      "PLK-1 Inhibitors (Cancer), Chroma Therapeutics",
      "Polo-Like Kinase-1 Inhibitors (Cancer)",
      "Polo-Likekinase-1 Inhibitors (Cancer), Chroma Therapeutics",
    ],
  },
  {
    primary_id: "D01HHJ",
    names: ["HTU-PA"],
  },
  {
    primary_id: "D01HHL",
    names: ["Gelsolin"],
  },
  {
    primary_id: "D01HJN",
    names: [
      "Ethyl 7-Hydroxy-2-Oxo-2H-Chromene-3-Carboxylate",
      "6093-71-6",
      "3-CARBETHOXYUMBELIFERONE",
      "Ethyl 7-Hydroxy-2-Oxochromene-3-Carboxylate",
      "7-Hydroxycoumarin-3-Carboxylic Acid Ethyl Ester",
      "3-Carbethoxy-7-Hydroxycoumarin",
      "YZ9",
      "2H-1-Benzopyran-3-Carboxylic Acid, 7-Hydroxy-2-Oxo-, Ethyl Ester",
      "CHEMBL155103",
      "7-HYDROXY-2-OXO-CHROMENE-3-CARBOXYLIC ACID ETHYL ESTER",
      "Ethyl Umbelliferone-3-Carboxylate",
      "Ethyl 7-Hydroxy-2-Oxo-2H-1-Benzopyran-3-Carboxylate",
      "AK146029",
      "7-Hydroxy-2-Oxo-2H-Chromene-3-Carboxylic Acid Ethyl Ester",
      "1gcz",
      "AC1NRDLP",
    ],
  },
  {
    primary_id: "D01HJQ",
    names: ["Aminoazetidine Derivative 1"],
  },
  {
    primary_id: "D01HJZ",
    names: ["N,N-Dimethyl-2,2-Diphenylethanamine"],
  },
  {
    primary_id: "D01HKL",
    names: ["ABT-335", "Choline Fenofibrate"],
  },
  {
    primary_id: "D01HKU",
    names: ["Methotrexate Gamma-L-Proline-Hydroxamic Acid", "CHEMBL388879"],
  },
  {
    primary_id: "D01HKZ",
    names: ["TSR-011"],
  },
  {
    primary_id: "D01HLF",
    names: ["ATI-0810"],
  },
  {
    primary_id: "D01HLO",
    names: ["PF-05190457"],
  },
  {
    primary_id: "D01HMN",
    names: ["Cyclohexenonic Long-Chain Fatty Alcohol Derivatives"],
  },
  {
    primary_id: "D01HNL",
    names: [
      "Allantoin",
      "97-59-6",
      "5-Ureidohydantoin",
      "Glyoxyldiureide",
      "1-(2,5-Dioxoimidazolidin-4-Yl)Urea",
      "Glyoxyldiureid",
      "Cordianine",
      "Allantol",
      "Sebical",
      "Alantan",
      "AVC/Dienestrolcream",
      "Urea, (2,5-Dioxo-4-Imidazolidinyl)-",
      "Hydantoin, 5-Ureido-",
      "Cutemol Emollient",
      "Uniderm A",
      "Glyoxylic Diureide",
      "Glyoxylic(Acid) Diureide",
      "(2,5-Dioxo-4-Imidazolidinyl)Urea",
      "Psoralon",
      "Septalan",
      "Caswell No. 024",
      "(2,5-Dioxoimidazolidin-4-Yl)Urea",
      "5-Ureido-2,4-Imidazolidindion",
      "NSC 7606",
      "Ureidohydantoin",
      "CCRIS 1958",
      "Allantoin [USAN:BAN]",
      "Fancol TOIN",
      "5-Ureidohydrantoin",
    ],
  },
  {
    primary_id: "D01HNP",
    names: ["Hydroxyacetic Acid"],
  },
  {
    primary_id: "D01HOI",
    names: ["MT-503"],
  },
  {
    primary_id: "D01HOL",
    names: ["Autologous Idiotypic Cancer Vaccine"],
  },
  {
    primary_id: "D01HOO",
    names: ["8-Deoxy-Neodysiherbaine", "8-Deoxy-NeoDH"],
  },
  {
    primary_id: "D01HOP",
    names: ["H-MCpa-Ala-Gly-Phe-Leu-OH", "CHEMBL246682"],
  },
  {
    primary_id: "D01HOT",
    names: ["X-072-NAB"],
  },
  {
    primary_id: "D01HPI",
    names: ["DLX-0110"],
  },
  {
    primary_id: "D01HPP",
    names: [
      "ECFCs",
      "ECFCs (Cardiovascular Disease)",
      "Adult Stem Cell Therapy (Cardiovascular Disease), EndGenitor",
      "ECFCs (Cardiovasculardisease), EndGenitor",
      "Cord Blood-Derived Endothelial Colony Forming Cells (Cardiovascular Disease), EndGenitor",
    ],
  },
  {
    primary_id: "D01HPY",
    names: ["Melphalan Flufenamide"],
  },
  {
    primary_id: "D01HQQ",
    names: [
      "Bone Morphogenetic Protein-4",
      "Bone Morphogenetic Protein-4 (Intracranial, Glioblastoma)",
      "BMP-4 (Intracranial, Glioblastoma), String Therapeutics",
      "Bone Morphogenetic Protein-4 (Intracranial, Glioblastoma), String Therapeutics",
    ],
  },
  {
    primary_id: "D01HQU",
    names: [
      "AK107",
      "2,5-Bis[4-(1H-Imidazol-2-Yl)Phenyl]-1,3-Oxazole",
      "AK.107",
      "AC1LARBL",
      "CTK5J5532",
      "2,5-Bis[4-(1H-Imidazol-2-Yl)Phenyl]oxazole",
      "2,5-Bis(4-Imidazol-2-Ylphenyl)-1,3-Oxazole",
    ],
  },
  {
    primary_id: "D01HQV",
    names: ["3-(4-Hydroxyphenyl)-6-Methyl-2H-Chromen-2-One", "CHEMBL577099", "ZINC45291389"],
  },
  {
    primary_id: "D01HRG",
    names: [
      "1-Phenyl-1H-Indole-2,3-Dione",
      "1-Phenylisatin",
      "723-89-7",
      "1-Phenylindoline-2,3-Dione",
      "1-Phenylindole-2,3-Dione",
      "1H-Indole-2,3-Dione, 1-Phenyl-",
      "1-Phenyl-Indole-2,3-Dione",
      "INDOLE-2,3-DIONE, 1-PHENYL-",
      "NSC 100013",
      "BRN 0164531",
      "1H-Indole-2,3-Dione, 1-Phenyl- (9CI)",
      "1-Phenyl-2,3-Dihydro-1H-Indole-2,3-Dione",
      "UWCPWBIMRYXUOU-UHFFFAOYSA-N",
      "AE-641/30401023",
      "N-Phenylisatin",
      "SMR000131753",
      "Phenylisatin",
      "Phenylindoledione",
      "Phenyl Isatin",
      "N-Phenyl Isatin",
      "1-Phenyl Isatin",
      "NSC100013",
      "1-Phenylisatin,98%",
      "1-Phenylisatin, 97%",
      "Isatin-Based Compound,",
    ],
  },
  {
    primary_id: "D01HRJ",
    names: ["JEBIK V"],
  },
  {
    primary_id: "D01HRR",
    names: ["Domitroban"],
  },
  {
    primary_id: "D01HRU",
    names: ["SBR-759"],
  },
  {
    primary_id: "D01HRV",
    names: ["L-772,405"],
  },
  {
    primary_id: "D01HSA",
    names: ["HG-1154"],
  },
  {
    primary_id: "D01HST",
    names: [
      "Amg-1",
      "CHEMBL3342402",
      "N-(1,3-Benzodioxol-5-Yl)-2-{[5-(4-Methylphenyl)[1,3]thiazolo[2,3-C][1,2,4]triazol-3-Yl]sulfanyl}acetamide",
      "BAS 06591295",
      "AC1LLIU7",
      "GTPL8227",
      "SCHEMBL20313457",
      "MolPort-000-220-791",
      "ZINC801901",
      "STL305655",
      "BDBM50030791",
      "AKOS000657540",
      "MCULE-5294683561",
      "ST50049773",
      "Z56882059",
      "N-(2H-1,3-Benzodioxol-5-Yl)-2-{[5-(4-Methylphenyl)-[1,2,4]triazolo[3,4-B][1,3]thiazol-3-Yl]sulfanyl}acetamide",
      "496023-55-3",
      "N-(2H-Benzo[3,4-D]1,3-Dioxolen-5-Yl)-2-[5-(4-Methylphenyl)(1,3-Thiazolino[3,2- D]1,2,4-Triazol-3-Ylthio)]acetamide",
    ],
  },
  {
    primary_id: "D01HTH",
    names: [
      "Aptiganel HCl",
      "Cerestat",
      "CNS-1102",
      "APTIGANEL HYDROCHLORIDE",
      "UNII-40PWH14OXW",
      "Cns 1102",
      "137160-11-3",
      "40PWH14OXW",
      "NCGC00093759-01",
      "DSSTox_CID_25762",
      "DSSTox_RID_81104",
      "DSSTox_GSID_45762",
      "CHEMBL554054",
      "N-(1-Naphthyl)-N'-(3-Ethylphenyl)-N'-Methylguanidine Hcl",
      "SMR000326976",
      "CAS-137160-11-3",
      "AC1Q3CFJ",
      "MLS001056791",
      "MLS000862213",
      "SCHEMBL871689",
      "AC1L1U12",
      "DTXSID9045762",
      "MolPort-003-940-636",
      "CKAKVKWRMCAYJD-UHFFFAOYSA-N",
      "Tox21_500312",
      "Tox21_111220",
      "Tox21_111220_1",
      "AKOS025293480",
      "CCG-221616",
      "LP00312",
      "NCGC00015236-04",
      "NCGC00260997-01",
      "CNS-1102, &gt;9",
    ],
  },
  {
    primary_id: "D01HTJ",
    names: ["FG-65155"],
  },
  {
    primary_id: "D01HTL",
    names: ["Vinorelbine"],
  },
  {
    primary_id: "D01HTO",
    names: ["MBX-2982"],
  },
  {
    primary_id: "D01HTS",
    names: ["MIP-1231"],
  },
  {
    primary_id: "D01HTX",
    names: ["C[Nle-Nle-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL380638"],
  },
  {
    primary_id: "D01HUN",
    names: ["UR-PG131A", "CHEMBL470563", "GTPL1198", "BDBM50413183"],
  },
  {
    primary_id: "D01HUO",
    names: ["BIS-IMIDE A", "CHEMBL388648", "SCHEMBL5879127", "BDBM50220067", "9H-Carbazole-1,2:3,4-Bisdicarbimide"],
  },
  {
    primary_id: "D01HUQ",
    names: [
      "3-(1H-Pyrrol-3-Yl)-Propionamide",
      "1H-Pyrrole-3-Propanamide",
      "CHEMBL277776",
      "152509-75-6",
      "ACMC-20n6gu",
      "CTK0I3006",
      "DTXSID90435928",
      "BDBM50287316",
    ],
  },
  {
    primary_id: "D01HVC",
    names: ["MTD-102"],
  },
  {
    primary_id: "D01HVE",
    names: ["ICI-D-1542"],
  },
  {
    primary_id: "D01HVM",
    names: ["99mTc-DI-DD3B6/22-80B3"],
  },
  {
    primary_id: "D01HVR",
    names: ["SAV-001"],
  },
  {
    primary_id: "D01HVS",
    names: ["APD-811"],
  },
  {
    primary_id: "D01HVT",
    names: [
      "Ribociclib Succinate",
      "1374639-75-4",
      "LEE011 Succinate",
      "LEE011 (Succinate)",
      "UNII-BG7HLX2919",
      "LEE011-BBA",
      "Ribociclib Succinate [USAN]",
      "BG7HLX2919",
      "Kisqali (TN)",
      "Ribociclib Succinate (USAN)",
      "LEE-011 Succinate",
      "SCHEMBL2684999",
      "EX-A1586",
      "HY-15777B",
      "1374639-75-4 (Succinate)",
      "AKOS030526460",
      "CS-2277",
      "ACN-040739",
    ],
  },
  {
    primary_id: "D01HVZ",
    names: ["TD-8954"],
  },
  {
    primary_id: "D01HWK",
    names: ["AA-10044"],
  },
  {
    primary_id: "D01HXA",
    names: ["AUS-131"],
  },
  {
    primary_id: "D01HXI",
    names: [
      "N-Substituted Amino-Benzamides",
      "AMB (Epilepsy), Auburn University/University Of Alabama",
      "N-Substituted Amino-Benzamides (Epilepsy)",
      "N-Substituted Amino-Benzamides (Epilepsy), Auburn University/University Of Alabama",
      "4-Amino-N-(Alpha-Methylbenzyl)-Benzamide (Epilepsy), Auburn University/University Of Alabama",
    ],
  },
  {
    primary_id: "D01HXN",
    names: ["TRL-382", "COX-1 Inhibitor (Neuropathic Pain), Toray Industries"],
  },
  {
    primary_id: "D01HYQ",
    names: ["IONIS-GCCRRX"],
  },
  {
    primary_id: "D01HZE",
    names: ["BRL-25594"],
  },
  {
    primary_id: "D01HZN",
    names: ["1,2-Bis(3,4,5-Trifluorophenyl)-2-Hydroxyethanone", "CHEMBL394806"],
  },
  {
    primary_id: "D01HZQ",
    names: ["VP-102"],
  },
  {
    primary_id: "D01IAT",
    names: ["7-(3-Hydroxy-Phenyl)-Naphthalen-2-Ol", "7-(3-Hydroxyphenyl)-2-Naphthol"],
  },
  {
    primary_id: "D01IBO",
    names: [
      "BCX-1470",
      "BCX 1470",
      "217099-43-9",
      "CHEMBL2220483",
      "2-(Aminoiminomethyl)Benzo[b]thiophen-6-Yl Ester-2-Thiophenecarboxylic Acid",
      "SCHEMBL7614663",
      "DTXSID30431348",
      "ZINC95558579",
      "BDBM50201566",
      "AKOS030526719",
      "2-(Aminoiminomethyl)Benzo[b]thiophen-6-Yl Ester 2-Thiophenecarboxylic Acid",
      "CS-0313",
      "RL02657",
      "KB-74872",
      "HY-50874",
      "W-5553",
      "099A439",
      "J-506195",
      "2-Thiophenecarboxylic Acid 2-(Aminoiminomethyl)Benzo[b]thiophene-6-Yl Ester",
    ],
  },
  {
    primary_id: "D01ICF",
    names: ["Tracazolate"],
  },
  {
    primary_id: "D01ICI",
    names: ["GS-5734"],
  },
  {
    primary_id: "D01ICU",
    names: ["Conjugated Estrogens B"],
  },
  {
    primary_id: "D01ICY",
    names: ["AVE-0657"],
  },
  {
    primary_id: "D01IDD",
    names: [
      "BAS-4844343",
      "CHEMBL207672",
      "N-[5-(1H-Indol-3-Ylmethyl)-1,3,4-Thiadiazol-2-Yl]-4-Methoxybenzamide",
      "AC1LK4AS",
      "Oprea1_740559",
      "MolPort-000-746-934",
      "MolPort-001-911-292",
      "ZINC17747698",
      "STK758751",
      "STK846597",
      "BDBM50187576",
      "AKOS005626974",
      "AKOS000597970",
      "MCULE-8653633709",
      "BAS 04844343",
      "SR-01000521375",
      "SR-01000521375-1",
      "A1870/0078659",
      "N-[5-(1H-Indol-3-Ylmethyl)-[1,3,4]thiadiazol-2-Yl]-4-Methoxy-Benzamide",
      "N~1~-[5-(1H-Indol-3-Ylmethyl)-1,3,4-Thiadiazol-2-Yl]-4-Methoxybenzamide",
    ],
  },
  {
    primary_id: "D01IDE",
    names: [
      "E-5324",
      "141799-76-0",
      "CHEMBL47095",
      "E 5324",
      "E5324",
      "AC1L30VJ",
      "SCHEMBL692563",
      "DTXSID20161824",
      "BDBM50047557",
      "N-Butyl-N'-(2-(3-(5-Ethyl-4-Phenyl-1H-Imidazol-1-Yl)Propoxy)-6-Methylphenyl)Urea",
      "HY-19183",
      "CS-0014924",
      "Urea, N-Butyl-N'-(2-(3-(5-Ethyl-4-Phenyl-1H-Imidazol-1-Yl)Propoxy)-6-Methylphenyl)-",
      "1-Butyl-3-[2-[3-(5-Ethyl-4-Phenylimidazol-1-Yl)Propoxy]-6-Methylphenyl]urea",
    ],
  },
  {
    primary_id: "D01IDQ",
    names: ["IPH-1101", "BrHPP, BromoHydrin, PyroPhosphate, Phosphostim"],
  },
  {
    primary_id: "D01IDW",
    names: ["SKI-O-703"],
  },
  {
    primary_id: "D01IED",
    names: ["BVD-523"],
  },
  {
    primary_id: "D01IEM",
    names: ["Pneumovax 23"],
  },
  {
    primary_id: "D01IES",
    names: ["Rituxan Rituxumab"],
  },
  {
    primary_id: "D01IEX",
    names: ["PSB-0739"],
  },
  {
    primary_id: "D01IFD",
    names: ["Antibodie Derivative 11"],
  },
  {
    primary_id: "D01IFO",
    names: [
      "6-(3-(Trifluoromethyl)Phenyl)Picolinonitrile",
      "CHEMBL1210691",
      "833457-45-7",
      "SCHEMBL9954071",
      "CTK3D2833",
      "DTXSID80678807",
      "BDBM50322920",
      "6-[3-(Trifluoromethyl)Phenyl]pyridine-2-Carbonitrile",
      "2-Pyridinecarbonitrile, 6-[3-(Trifluoromethyl)Phenyl]-",
    ],
  },
  {
    primary_id: "D01IFR",
    names: [
      "5-Benzhydryl-1,3,4-Oxadiazole-2(3H)-Thione",
      "CHEMBL593832",
      "5-Benzhydryl-1,3,4-Oxadiazole-2-Thiol",
      "93073-39-3",
      "5-(Diphenylmethyl)-1,3,4-Oxadiazole-2-Thiol",
      "AC1MJWI7",
      "5-Benzhydryl-3H-1,3,4-Oxadiazole-2-Thione",
      "MolPort-001-902-005",
      "ZINC17946026",
      "STK803293",
      "BDBM50320727",
      "BBL012648",
      "AKOS002344283",
      "MCULE-6937439472",
      "BAS 00805225",
      "5-Benzhydryl-[1,3,4]oxadiazole-2-Thiol",
      "SR-01000312098",
      "5-Diphenylmethyl-1,3,4-Oxadiazole-2(3H)-Thione",
    ],
  },
  {
    primary_id: "D01IGH",
    names: ["SERA5-Targeted Malaria Vaccine", "BK-SE36", "SERA5-Targeted Malaria Vaccine, BIKEN"],
  },
  {
    primary_id: "D01IGI",
    names: [
      "Disodium Cromoglycate",
      "15826-37-6",
      "Sodium Cromoglycate",
      "Sodium Cromoglicate",
      "Sodium Cromolyn",
      "Gastrocrom",
      "Nasalcrom",
      "Cromoglycate Disodium",
      "Cromoglycic Acid",
      "Cromolyn Disodium Salt",
      "Natrium Cromoglicat",
      "Cromoglycate",
      "Opticrom",
      "Lomudal",
      "Nalcrom",
      "Gastrofrenal",
      "Cromoptic",
      "Gynazole",
      "Crolom",
      "Lomuda",
      "Intal Nebulizer",
      "Chromolyn Sodium",
      "Natrii Cromoglicas",
      "Sodium Cromoylycate",
      "Cromolyn Sodium Salt",
      "Nebulasma",
      "Aararre",
      "Lomudas",
      "Rynacrom",
      "Lomusol",
      "Frenasma",
      "Nasmil",
      "Cromo Asma Aerosol",
    ],
  },
  {
    primary_id: "D01IGL",
    names: [
      "(+/-)-3-Allyl-1-Pent-4-Enoylazetidin-2-One",
      "CHEMBL487448",
      "1-(4'-Pentenoyl)-3-(2'-Propenyl)-2-Azetidinone",
      "BDBM50243198",
    ],
  },
  {
    primary_id: "D01IGU",
    names: [
      "APA",
      "1-Aminooxy-3-Aminopropane",
      "3-Aminooxy-1-Aminopropane",
      "O-(3-Aminopropyl)Hydroxylamine",
      "98532-00-4",
      "CHEMBL281021",
      "1-Propanamine, 3-(Aminooxy)-",
      "3-Aminooxy-1-Propanamine",
      "XAP",
      "3-Aminooxypropylamine",
      "3-(Aminooxy)Propan-1-Amine",
      "GTPL5139",
      "AC1L22F5",
      "CTK3I7322",
      "DTXSID20243634",
      "BDBM50005719",
      "AKOS006348478",
      "(APA)O-(3-Amino-Propyl)-Hydroxylamine",
      "O-(3-Aminopropyl)Hydroxylamine,",
    ],
  },
  {
    primary_id: "D01IGW",
    names: [
      "6-Chloro-5-Phenylpyrimidine-2,4(1H,3H)-Dione",
      "1000680-77-2",
      "CHEMBL235001",
      "BDBM20065",
      "5-Substituted-6-Chlorouracil, 10a",
    ],
  },
  {
    primary_id: "D01IGZ",
    names: [
      "Napabucasin",
      "83280-65-3",
      "UNII-Z1HHM49K7O",
      "2-Acetylnaphtho[2,3-B]furan-4,9-Dione",
      "Z1HHM49K7O",
      "2-Acetylnaphtho(2,3-B)Furan-4,9-Dione",
      "2-Acetyl-4H,9H-Naphtho[2,3-B]furan-4,9-Dione",
      "Napabucasin [USAN:INN]",
      "Napabucasin (BBI608)",
      "2-Acetylfuranonaphthoquinone",
      "CHEMBL64130",
      "Napabucasin (JAN/USAN/INN)",
      "SCHEMBL1883845",
      "Napabucasin - BBI 608/ FNQ",
      "2-Acetylfuro-1,4-Naphthoquinone",
      "DPHUWDIXHNQOSY-UHFFFAOYSA-N",
      "MolPort-039-101-321",
      "EX-A1314",
      "ZINC13306865",
      "S7977",
      "AKOS027470201",
      "DB12155",
      "CS-1747",
      "ACN-053294",
      "HY-13919",
    ],
  },
  {
    primary_id: "D01IHM",
    names: [
      "CI-218872",
      "66548-69-4",
      "CL 218872",
      "CL 218,872",
      "3-Methyl-6-[3-(Trifluoromethyl)Phenyl]-[1,2,4]triazolo[4,3-B]pyridazine",
      "CHEMBL13662",
      "CL-218872",
      "3-Methyl-6-(3-(Trifluoromethyl)Phenyl)-1,2,4-Triazolo(4,2-B)Pyridazine",
      "1,2,4-Triazolo(4,3-B)Pyridazine, 3-Methyl-6-(3-(Trifluoromethyl)Phenyl)-",
      "3-Methyl-6-(3-(Trifluoromethyl)Phenyl)-[1,2,4]triazolo[4,3-B]pyridazine",
      "3-Methyl-6-[-3-(Trifluoromethyl)Phenyl]-1,2,4-Triazolo[4,3-B]pyridazine",
      "3-Methyl-6-[3-(Trifluoromethyl)Phenyl]-1,2,4-Triazolo-(4,3-B)Pyridazine",
    ],
  },
  {
    primary_id: "D01IHR",
    names: ["AC-1202"],
  },
  {
    primary_id: "D01IHU",
    names: ["ISIS 29471"],
  },
  {
    primary_id: "D01IHV",
    names: ["AD-0027"],
  },
  {
    primary_id: "D01IJA",
    names: ["Piperazine Carbamate/Urea Derivative 3"],
  },
  {
    primary_id: "D01IJD",
    names: ["AH13205", "AH 13205", "AH-13205"],
  },
  {
    primary_id: "D01IJJ",
    names: ["MV-NIS"],
  },
  {
    primary_id: "D01IJL",
    names: ["FR-182877"],
  },
  {
    primary_id: "D01IJM",
    names: ["LY3164530"],
  },
  {
    primary_id: "D01IJO",
    names: ["PMID29338548-Compound-25"],
  },
  {
    primary_id: "D01IJX",
    names: [
      "1-(2-(Benzo[b]thiophen-4-Yl)Ethyl)-1H-Imidazole",
      "CHEMBL460382",
      "Benzo[b]thiophene 32",
      "BDBM10038",
      "ZINC13808220",
      "1-[2-(1-Benzothiophen-4-Yl)Ethyl]-1H-Imidazole",
      "4-[2-(1H-Imidazole-1-Yl)Ethyl]benzo[b]thiophene",
    ],
  },
  {
    primary_id: "D01ILA",
    names: ["GNbAC-1"],
  },
  {
    primary_id: "D01ILH",
    names: [
      "B-2-Octylglucoside",
      "B-2-OCTYLGLUCOSIDE",
      "BGL",
      "AC1L9I1F",
      "SCHEMBL61561",
      "2-O-Octyl-Beta-D-Glucopyranose",
      "DB03152",
      "(2R,3R,4S,5S,6R)-6-(Hydroxymethyl)-3-Octoxyoxane-2,4,5-Triol",
    ],
  },
  {
    primary_id: "D01ILJ",
    names: ["Celvapan", "Celvapan A/H1N1", "H1N1 Pandemic Influenza Vaccine, Baxter"],
  },
  {
    primary_id: "D01ILN",
    names: [
      "6,7-Diphenyl-Pteridine-2,4-Diamine",
      "6,7-Diphenylpteridine-2,4-Diamine",
      "18181-93-6",
      "2,4-DIAMINO-6,7-DIPHENYL-PTERIDINE",
      "NSC10922",
      "CHEMBL48296",
      "2,4-Diamino-6,7-Diphenylpteridine",
      "AC1Q4WHR",
      "AC1L5CHW",
      "Diphenylpteridine-2,4-Diamine",
      "SCHEMBL3746280",
      "Diaminopteridine Derivative, 24",
      "CTK4D7985",
      "BDBM25101",
      "DTXSID80278987",
      "DDZJPETYMUJLIW-UHFFFAOYSA-N",
      "ZINC1712532",
      "NSC-10922",
      "2,4-Pteridinediamine,6,7-Diphenyl-",
      "6,7-Diphenyl-2,4-Pteridinediamine #",
      "2,4-Pteridinediamine, 6,7-Diphenyl-",
      "ACM18181936",
      "DB-081833",
    ],
  },
  {
    primary_id: "D01ILO",
    names: [
      "2-Aminothiazoline",
      "2-Amino-2-Thiazoline",
      "1779-81-3",
      "4,5-Dihydrothiazol-2-Amine",
      "2-Iminothiazolidine",
      "2-Thiazolidinimine",
      "2-Thiazolamine, 4,5-Dihydro-",
      "2-Amino-4,5-Dihydrothiazole",
      "2-Thiazoline, 2-Amino-",
      "4,5-Dihydro-1,3-Thiazol-2-Amine",
      "USAF PD-57",
      "2-Amino-4,5-Dihydrothiazoline",
      "4,5-DIHYDRO-2-THIAZOLAMINE",
      "2-Thiazolin-2-Ylamine",
      "2-Aminothiazoline (VAN)",
      "UNII-5JC2YZG56Q",
      "NSC 3110",
      "NSC 24626",
      "Thiazolidin-(2E)-Ylideneamine",
      "2-Amino-DELTA2-Thiazoline",
      "EINECS 217-224-1",
      "5JC2YZG56Q",
      "CHEMBL362148",
      "2-Amino-2-Thiazoline, 97%",
    ],
  },
  {
    primary_id: "D01ILP",
    names: ["CNT0-3157"],
  },
  {
    primary_id: "D01ILT",
    names: [
      "CRISNATOL MESILATE",
      "2-[(6-Chrysenylmethyl)Amino]-2-Methyl-1,3-Propanediol Methanesulfonate",
      "770U82 Mesylate",
      "96389-69-4",
      "BW-A770U Mesylate",
      "Crisnatol Mesilate < Rec INNM",
    ],
  },
  {
    primary_id: "D01IMJ",
    names: ["N-(4-Methylthiazol-2-Yl)Benzamide"],
  },
  {
    primary_id: "D01IMN",
    names: ["VLA15-101"],
  },
  {
    primary_id: "D01IMY",
    names: ["EPO Program, Medexgen"],
  },
  {
    primary_id: "D01IOH",
    names: ["Indole-Based Analog 14"],
  },
  {
    primary_id: "D01IPF",
    names: [
      "N-(6-(3-Hydroxyphenyl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1086639",
      "SCHEMBL6485118",
      "UKQRPDHIQLHFNS-UHFFFAOYSA-N",
      "BDBM50313706",
    ],
  },
  {
    primary_id: "D01IPI",
    names: [
      "Gemopatrilat",
      "AC1L58XI",
      "2-[(6S)-2,2-Dimethyl-7-Oxo-6-[(3-Phenyl-2-Sulfanylpropanoyl)Amino]azepan-1-Yl]acetic Acid",
    ],
  },
  {
    primary_id: "D01IPS",
    names: [
      "5",
      "5--MONOPHOSPHATE-9-BETA-D-RIBOFURANOSYL XANTHINE",
      "AC1O43SS",
      "DB02309",
      "5-MONOPHOSPHATE-9-BETA-D-RIBOFURANOSYL XANTHINE",
      "[(2R,3S,4R,5R)-5-(2,6-Dioxo-3,7-Dihydropurin-9-Ium-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D01IPT",
    names: ["AGS-V"],
  },
  {
    primary_id: "D01IPU",
    names: ["Triazole Gold Complexe 2"],
  },
  {
    primary_id: "D01IRA",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 3"],
  },
  {
    primary_id: "D01IRG",
    names: ["Bictegravir, Emtricitabine And Tenofovir Alafenamide"],
  },
  {
    primary_id: "D01IRO",
    names: ["4-(4-HYDROXYPHENYL)-1-NAPHTHALDEHYDE OXIME"],
  },
  {
    primary_id: "D01IRW",
    names: ["Bermoprofen", "Dibenon", "Bermoprofen Potasium Salt", "AD-1590", "AJ-1590"],
  },
  {
    primary_id: "D01IRX",
    names: ["ISIS 19657"],
  },
  {
    primary_id: "D01ISE",
    names: ["Bimakalim"],
  },
  {
    primary_id: "D01ISX",
    names: ["4-[2-(2-Thienyl)Acetamidoethyl]benzenesulfonamide"],
  },
  {
    primary_id: "D01ITB",
    names: ["TV1001sr"],
  },
  {
    primary_id: "D01ITP",
    names: ["Triazolo-Pyridine Derivative 5"],
  },
  {
    primary_id: "D01IUB",
    names: ["[3H]resolvin E1"],
  },
  {
    primary_id: "D01IUD",
    names: ["PRT-20"],
  },
  {
    primary_id: "D01IUI",
    names: ["JWH-253"],
  },
  {
    primary_id: "D01IUS",
    names: [
      "Mapracorat",
      "BOL-303242X",
      "SEGRA, Intendis",
      "SEGRA, Schering AG",
      "ZK-216348",
      "ZK-238587",
      "ZK-245186",
      "Mapracorat (Ophthalmic, Ocular Inflammation)",
      "Mapracorat Ophthalmic Suspension, Bausch & Lomb",
      "SEGRA (Atopic Dermatitis), Bayer",
      "Selective Glucocorticoid Receptor Agonists (SEGRA) Bayer Schering/AstraZeneca",
      "Selective Glucocorticoid Receptor Agonists (SEGRA) Schering AG/AstraZeneca",
      "Selective Glucocorticoid Receptor Agonists (SEGRA), Schering AG",
      "Selective Glucocorticoid Receptor Agonists (Dermatology), Schering AG",
      "Mapracorat (Dermatological, Atopic Dermatitis), Intendis",
      "SEGRA (Ophthalmic Disease), Bausch & Lomb",
      "Selective Glucocorticoid Receptor Agonist (Ophthalmic Disease), Bausch & Lomb",
      "Selective Glucocorticoid Receptor Agonists (Dermatological, Eczema), Intendis",
      "Suppressor Of Pro-Inflammatory Mediators (Eczema), Intendis",
      "BOL-303242-X Ophthalmic Suspension, Bausch & Lomb",
      "Mapracorat (Ophthalmic, Ocular Inflammation), Bausch & Lomb",
      "ZK-245186 (Ophthalmic), Bausch & Lomb",
    ],
  },
  {
    primary_id: "D01IVE",
    names: ["Lobeline"],
  },
  {
    primary_id: "D01IVR",
    names: ["Piperazinyl Methyl Quinazolinone Derivative 2"],
  },
  {
    primary_id: "D01IWD",
    names: ["MDL-74156"],
  },
  {
    primary_id: "D01IWJ",
    names: ["Fluticasone Furoate/ Fluticasone Propionate"],
  },
  {
    primary_id: "D01IWS",
    names: ["Inulin"],
  },
  {
    primary_id: "D01IXD",
    names: ["Finafloxacin"],
  },
  {
    primary_id: "D01IYF",
    names: ["N-Oxalylglycine Derivative 2"],
  },
  {
    primary_id: "D01IYQ",
    names: ["TAK-201"],
  },
  {
    primary_id: "D01IZE",
    names: ["Recombinant Botulinum Toxin Vaccine"],
  },
  {
    primary_id: "D01IZM",
    names: ["SND-121 Prodrugs"],
  },
  {
    primary_id: "D01IZN",
    names: ["Y-C[D-Pen-(2')Nal-GSFC]KR-NH2", "CHEMBL405681", "BDBM50064832"],
  },
  {
    primary_id: "D01JBJ",
    names: ["Antiviral Agents"],
  },
  {
    primary_id: "D01JCH",
    names: ["P'-Geranyl 3,5,7-Trihydroxy-3-Methylheptanate 7-Diphosphate"],
  },
  {
    primary_id: "D01JCJ",
    names: ["1-(Oxazolo[4,5-B]pyridin-2-Yl)Octan-1-One", "CHEMBL257305", "SCHEMBL3966136", "BDBM50350545"],
  },
  {
    primary_id: "D01JCT",
    names: ["CD19-CAR T Cells"],
  },
  {
    primary_id: "D01JCX",
    names: ["Antileukoprotease (ALP)"],
  },
  {
    primary_id: "D01JCZ",
    names: ["PMID26004420-Compound-U2014S0288042C"],
  },
  {
    primary_id: "D01JDK",
    names: ["PMID25399762-Compound-Table1-C13"],
  },
  {
    primary_id: "D01JEH",
    names: ["PMID25980951-Compound-29"],
  },
  {
    primary_id: "D01JEI",
    names: ["F-50003"],
  },
  {
    primary_id: "D01JEO",
    names: ["Brevenal"],
  },
  {
    primary_id: "D01JEU",
    names: ["Memantine"],
  },
  {
    primary_id: "D01JEX",
    names: ["ROSETTACIN"],
  },
  {
    primary_id: "D01JFF",
    names: ["VU0361737"],
  },
  {
    primary_id: "D01JFM",
    names: [
      "AC-170",
      "88139-91-7",
      "5-Bromo-2-Hydroxymethylpyridine",
      "(5-Bromopyridin-2-Yl)Methanol",
      "2-Hydroxymethyl-5-Bromopyridine",
      "5-Bromo-2-(Hydroxymethyl)Pyridine",
      "(5-Bromopyrid-2-Yl)Methanol",
      "5-Bromo-2-Pyridinemethanol",
      "(5-Bromo-Pyridin-2-Yl)-Methanol",
      "2-PYRIDINEMETHANOL, 5-BROMO-",
      "5-BROMOPYRIDINE-2-METHANOL",
      "(5-Bromo-2-Pyridyl)Methanol",
      "(5-Bromo-2-Pyridyl)Methan-1-Ol",
      "(5-Bromo-Pyridin-2-Yl)Methanol (5-Bromo-2-Hydroxymethylpyridine)",
      "Zlchem 391",
      "PubChem7710",
      "PubChem12387",
      "ACMC-209qrb",
      "AC1NP90H",
      "5-Bromo-2-Pyridine Methanol",
      "KSC494K9D",
    ],
  },
  {
    primary_id: "D01JFT",
    names: [
      "Dipivefrin",
      "AKPro",
      "Dipivefrina",
      "Dipivefrine",
      "Dipivefrinum",
      "Propine",
      "Dipivalyl Epinephrine",
      "Dipivefrin HCL",
      "Dipivefrin [USAN]",
      "K 30081",
      "Dipivefrin (USAN)",
      "Dipivefrina [INN-Spanish]",
      "Dipivefrine (INN)",
      "Dipivefrinum [INN-Latin]",
      "Ophtho-Dipivefrin",
      "Propine C Cap B.I.D.",
      "[2-(2,2-Dimethylpropanoyloxy)-4-[1-Hydroxy-2-(Methylamino)Ethyl]phenyl] 2,2-Dimethylpropanoate",
      "(+-)-3,4-Dihydroxy-Alpha-((Methylamino)Methyl)Benzyl Alcohol 3,4-Dipivalate",
      "(+-)-4-[1-Hydroxy-2-(Methylamino)Ethyl]-O-Phenylene Divavalate",
      "(-)-2,2-Dimethylpropansaeure-4-(1-Hydroxy-2-(Methylamino)Ethyl)-1,2-Phenylenester",
      "(-)-4-(1-Hydroxy-2-Methylaminoethyl)-O-Phenylendipivalat",
      "(RS)-4-(1-Hydroxy-2-(Methylamino)Ethyl)-1,2-Phenylen Dipivalat",
      "1-(3',4'-Dipivaloyloxyphenyl)-2-Methylamino-1-Ethanol",
      "2,2-Dimethylpropanoic Acid 4-[1-Hydroxy-2-(Methylamino)Ethyl]-1,2-Phenylene Ester",
      "4-(1-Hydroxy-2-(Methylamino)Ethyl)-1,2-Phenylen Dipivalat",
      "4-[1-Hydroxy-2-(Methylamino)Ethyl]-O-Phenylene Divavalate",
      "4-[1-Hydroxy-2-(Methylamino)Ethyl]benzene-1,2-Diyl Bis(2,2-Dimethylpropanoate)",
    ],
  },
  {
    primary_id: "D01JGB",
    names: ["4-Cyclooctyl-6-Propylpyrimidine-2-Carbonitrile", "CHEMBL1077194"],
  },
  {
    primary_id: "D01JGD",
    names: ["AD-03", "AFFITOPE AD03", "Amyloid Beta Vaccine (Affitome, Alzheimers Disease), AFFiRiS/ GlaxoSmithKline"],
  },
  {
    primary_id: "D01JGE",
    names: ["Netupitant/Palonosetron"],
  },
  {
    primary_id: "D01JGG",
    names: ["Sodelglitazar"],
  },
  {
    primary_id: "D01JGV",
    names: ["Ajmalicine"],
  },
  {
    primary_id: "D01JHA",
    names: ["2'-Amino-4-(1,1-Dimethyl-Heptyl)-Biphenyl-2-Ol", "CHEMBL234897", "BDBM50213173"],
  },
  {
    primary_id: "D01JHN",
    names: ["CR-3124"],
  },
  {
    primary_id: "D01JHR",
    names: ["H-Gly-DmP-Glu-OH"],
  },
  {
    primary_id: "D01JIA",
    names: ["L-Glutamine"],
  },
  {
    primary_id: "D01JID",
    names: ["NSC-660841"],
  },
  {
    primary_id: "D01JIM",
    names: [
      "Cell Therapies",
      "Cell Therapies (Cancer)",
      "Cell Therapies (Cancer), Choongwae",
      "Cell Therapies (Cancer), JW Pharmaceutical",
    ],
  },
  {
    primary_id: "D01JIO",
    names: ["ZK-91587"],
  },
  {
    primary_id: "D01JJH",
    names: ["(3R,4S)-293B"],
  },
  {
    primary_id: "D01JJN",
    names: ["PMID25772215-Compound-EP2149552diaminopyrimidines"],
  },
  {
    primary_id: "D01JJO",
    names: ["Calaspargase Pegol"],
  },
  {
    primary_id: "D01JJR",
    names: ["RU90395"],
  },
  {
    primary_id: "D01JJV",
    names: ["1-Adamantan-1-Yl-3-(3-Hydroxy-Phenyl)-Urea", "CHEMBL477671", "SCHEMBL6568488", "BDBM50267093"],
  },
  {
    primary_id: "D01JKV",
    names: ["MK-8266"],
  },
  {
    primary_id: "D01JLH",
    names: ["RESP-3000"],
  },
  {
    primary_id: "D01JLO",
    names: ["Tat-NR2B9c"],
  },
  {
    primary_id: "D01JMC",
    names: [
      "Lorcaserin",
      "APD 356",
      "APD356",
      "AR-10A",
      "(R)-8-Chloro-1-Methyl-2,3,4,5-Tetrahydro-1H-Benzo[d]azepine",
      "10-Chloro-2-Methyl-4-Azabicyclo[5.4.0]undeca-8,10,12-Triene",
    ],
  },
  {
    primary_id: "D01JMW",
    names: ["2-Bromo-N-(3-Morpholinopropyl)Nicotinamide", "CHEMBL598373"],
  },
  {
    primary_id: "D01JMX",
    names: ["INS-1", "Diabetes Therapeutics, Insmed", "Inositol Phosphoglycans, Insmed", "D-Chiro-Inositol, Insmed"],
  },
  {
    primary_id: "D01JNE",
    names: ["Lexibulin Iv Infusion"],
  },
  {
    primary_id: "D01JOA",
    names: ["D-1927"],
  },
  {
    primary_id: "D01JOJ",
    names: ["RX-77368"],
  },
  {
    primary_id: "D01JPA",
    names: ["TRC102"],
  },
  {
    primary_id: "D01JPJ",
    names: ["PTC-725"],
  },
  {
    primary_id: "D01JPL",
    names: ["VEGFR3 Monoclonal Antibody"],
  },
  {
    primary_id: "D01JPM",
    names: ["PMID28092474-Compound-33"],
  },
  {
    primary_id: "D01JQJ",
    names: ["Iodinated Glycerol"],
  },
  {
    primary_id: "D01JQX",
    names: ["JWH-342", "CHEMBL260130"],
  },
  {
    primary_id: "D01JRF",
    names: ["SPD-453"],
  },
  {
    primary_id: "D01JSA",
    names: ["H-Dmt-D-Arg(NO2)-Phe-Lys(Z)-NH2", "CHEMBL371856"],
  },
  {
    primary_id: "D01JSG",
    names: ["SBL-109"],
  },
  {
    primary_id: "D01JSI",
    names: ["PF-06863135"],
  },
  {
    primary_id: "D01JSO",
    names: ["VM-505"],
  },
  {
    primary_id: "D01JTI",
    names: ["3-Bromophenyl-Difluoromethanesulfonamide", "CHEMBL200673", "BDBM50175006"],
  },
  {
    primary_id: "D01JTL",
    names: ["KM-023"],
  },
  {
    primary_id: "D01JTW",
    names: ["PEV3A"],
  },
  {
    primary_id: "D01JTY",
    names: ["JWH-313"],
  },
  {
    primary_id: "D01JUF",
    names: [
      "Phentolamine",
      "Dibasin",
      "Fentolamin",
      "Fentolamina",
      "Phenotolamine",
      "Phentalamine",
      "Phentolaminum",
      "Regitin",
      "Regitine",
      "Regitipe",
      "Regityn",
      "Rogitine",
      "Phentolamine Mesylate [USAN]",
      "Phentolamine Methanesulfonate",
      "C 7337",
      "C 7337 Ciba",
      "Fentolamina [INN-Spanish]",
      "Phentolamine (INN)",
      "Phentolamine [INN:BAN]",
      "Phentolamine, Methyl Sulfonate",
      "Phentolaminum [INN-Latin]",
      "Vasomax (TN)",
      "2-((N-(M-Hydroxyphenyl)-P-Toluidino)Methyl)-2-Imidazoline",
      "2-(N'-P-Tolyl-N'-M-Hydroxyphenylaminomethyl)-2-Imidazoline",
      "2-(N-(M-Hydroxyphenyl)-P-Toluidinomethyl)Imidazoline",
      "2-(M-Hydroxy-N-P-Tolylanilinomethyl)-2-Imidazoline",
      "3-[(4,5-Dihydro-1H-Imidazol-2-Ylmethyl)(4-Methylphenyl)Amino]phenol",
      "3-[N-(4,5-Dihydro-1H-Imidazol-2-Ylmethyl)-4-Methylanilino]phenol",
    ],
  },
  {
    primary_id: "D01JUI",
    names: ["N-(4-Chloro-7-P-Tolyl-Isoquinolin-1-Yl)-Guanidine", "CHEMBL109274"],
  },
  {
    primary_id: "D01JVG",
    names: ["3-Fluoro-5-[5-(4-Hydroxyphenyl)-2-Thienyl]phenol", "CHEMBL583520"],
  },
  {
    primary_id: "D01JVP",
    names: ["(2R,3R)-Iodoreboxetine", "CHEMBL477406"],
  },
  {
    primary_id: "D01JWH",
    names: ["SAN-134"],
  },
  {
    primary_id: "D01JWT",
    names: [
      "2-(10-Imidazol-1-Yl-Decyl)-Isoindole-1,3-Dione",
      "CHEMBL166731",
      "SCHEMBL10655149",
      "DOVKNTJKHQSOTE-UHFFFAOYSA-N",
      "BDBM50024103",
      "2[10-(1H-Imidazol-1-Yl)Decyl]-1H-Isoindole-1,3(2H)Dione",
    ],
  },
  {
    primary_id: "D01JXM",
    names: [
      "2-(2-(4-Tert-Butylphenylthio)Ethyl)-1H-Imidazole",
      "4-(2-(4-Tert-Butylphenylthio)Ethyl)-1H-Imidazole",
      "4-(2-(40-Tert-Butylphenylthio)Ethyl)-1H-Imidazole",
    ],
  },
  {
    primary_id: "D01JYF",
    names: [
      "2,4'-Dimethoxy-5,3'-Di-(2-Propenyl)-Biphenyl",
      "CHEMBL89828",
      "68592-18-7",
      "NSC293102",
      "AC1L6VMD",
      "HONOKIOL DIMETHYL ETHER",
      "SCHEMBL13978616",
      "CTK2F6825",
      "DTXSID00315202",
      "ZINC1566148",
      "BDBM50295927",
      "3',5-Diallyl-2,4'-Dimethoxybiphenyl",
      "NSC-293102",
      "5,3''-Diallyl-2,4''-Dimethoxy-Biphenyl",
      "3,3'-Diallyl-4',6-Dimethoxy-1,1'-Biphenyl",
      "2,4''-Dimethoxy-5,3''-Di-(2-Propenyl)-Biphenyl",
      "1-Methoxy-2-(4-Methoxy-3-Prop-2-Enylphenyl)-4-Prop-2-Enylbenzene",
    ],
  },
  {
    primary_id: "D01JYT",
    names: ["INT-0022", "Anti-Psychotic Agent (Oral Quick Release Thin Film, VersaFilm), IntelGenx"],
  },
  {
    primary_id: "D01JYY",
    names: ["(R)-N-Isobutyl-N-(Pyrrolidin-3-Yl)-2-Naphthamide", "CHEMBL577283", "SCHEMBL6416013"],
  },
  {
    primary_id: "D01JZO",
    names: [
      "1-(4-Chlorobenzyl)-1H-Imidazole",
      "42032-27-9",
      "CHEMBL441367",
      "1-[(4-Chlorophenyl)Methyl]imidazole",
      "Chlorbenzyl Imidazole",
      "AC1MCUZ8",
      "1H-Imidazole, 1-[(4-Chlorophenyl)Methyl]-",
      "1-(P-Chlorobenzyl)Imidazole",
      "1-(4-Chlorobenzyl)Imidazole",
      "SCHEMBL570342",
      "CTK6H0128",
      "MolPort-001-764-407",
      "RPLWYOLCHGTNSX-UHFFFAOYSA-N",
      "ZINC161401",
      "BDBM50188097",
      "AKOS002677375",
      "MCULE-5157261929",
    ],
  },
  {
    primary_id: "D01JZV",
    names: [
      "Banyu (I)",
      "2-(6-Amino-Benzothiazol-2-Ylsulfanyl)-N-[1-(3,4-Dichloro-Benzyl)-Piperidin-4-Yl]-Acetamide",
      "CHEMBL20921",
      "Banyu Compound 1b",
      "[3H]banyu (I)",
      "GTPL795",
      "GTPL796",
      "SCHEMBL5489840",
      "BDBM50099482",
      "N-[1-(3,4-Dichlorobenzyl)-4-Piperidinyl](6-Amino-2-Benzothiazolylthio)Acetamide",
      "2-[(6-Amino-1,3-Benzothiazol-2-Yl)Sulfanyl]-N-[1-[(3,4-Dichlorophenyl)Methyl]piperidin-4-Yl]acetamid",
      "290363-23-4",
    ],
  },
  {
    primary_id: "D01KAH",
    names: ["1-[(3-Methoxybenzyl)Amino]ethaniminium Chloride"],
  },
  {
    primary_id: "D01KAM",
    names: [
      "O7-Nitrooxyethyl Chrysin",
      "CHEMBL459374",
      "2-(5-Hydroxy-4-Oxo-2-Phenyl-4H-Chromen-7-Yloxy)Ethyl Nitrate",
      "1141487-91-3",
      "DTXSID00659597",
      "BDBM50318055",
      "2-[(5-Hydroxy-4-Oxo-2-Phenyl-4H-1-Benzopyran-7-Yl)Oxy]ethyl Nitrate",
    ],
  },
  {
    primary_id: "D01KAX",
    names: ["1-((S)-2-Aminopropyl)-1H-Indazol-6-Ol"],
  },
  {
    primary_id: "D01KCA",
    names: ["PMID27376512-Compound-PG-11047"],
  },
  {
    primary_id: "D01KCE",
    names: ["BLB-750"],
  },
  {
    primary_id: "D01KCF",
    names: ["Small Molecule 2a"],
  },
  {
    primary_id: "D01KCM",
    names: ["ISIS 114563"],
  },
  {
    primary_id: "D01KDM",
    names: ["GTP-004"],
  },
  {
    primary_id: "D01KDQ",
    names: ["Triethylcholine"],
  },
  {
    primary_id: "D01KEB",
    names: ["MK-1293"],
  },
  {
    primary_id: "D01KEE",
    names: ["Formaldehyde"],
  },
  {
    primary_id: "D01KEF",
    names: ["ISIS 10707"],
  },
  {
    primary_id: "D01KEH",
    names: [
      "Domoric Acid",
      "CHEMBL482097",
      "E,E-Isodomoic Acid",
      "ISODOMOIC ACID E",
      "(?)-Isodomoic Acid E",
      "(-)-Isodomoic Acid E",
      "SCHEMBL13319904",
      "ZINC4521465",
      "(2S,3S,4S)-2-CARBOXY-4-[(1Z,3E,5R)-5-CARBOXY-1-METHYL-1,3-HEXADIENYL]-3-PYRROLIDINEACETIC ACID",
      "BDBM50252103",
    ],
  },
  {
    primary_id: "D01KER",
    names: [
      "ARX-8203 Injectable",
      "ARX-8203",
      "Lipophilic NSAID Prodrug (Pain/Inflammation), AlphaRx",
      "ARX-8203 (Injectable, Pain), AlphaRx",
      "ARX-8203 (Ophthalmic, Inflammation), AlphaRx",
      "ARX-8203 (Oral, Pain/Inflammation), AlphaRx",
    ],
  },
  {
    primary_id: "D01KFW",
    names: ["3-Oxo-2,3-Dihydro-1H-Indazole-4-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D01KGP",
    names: ["IPL 550,260"],
  },
  {
    primary_id: "D01KHH",
    names: [
      "Cyclobenzaprine",
      "Ciclobenzaprina",
      "Cyclobenz",
      "Cyclobenzaprinum",
      "Fexmid",
      "Flexeril",
      "Lisseril",
      "Proeptatriene",
      "Proheptatrien",
      "Proheptatriene",
      "Yurelax",
      "Cyclobenzaprine [INN]",
      "Flexeril Hydrochloride",
      "Proeptatriene [Italian]",
      "MK 130",
      "NCI78206",
      "Amrix (TN)",
      "Ciclobenzaprina [INN-Spanish]",
      "Cyclobenzaprine (INN)",
      "Cyclobenzaprinum [INN-Latin]",
      "Fexmid(TN)",
      "Flexeril (TN)",
      "MK-130 HCl",
      "Ro 4-1577",
      "Apo-Cyclobenzaprine (TN)",
      "N,N-Dimethyl-3-(Dibenzo(A,d)Cycloheptene-4-Ylidene)Propylamine",
      "N,N-Dimethyl-5H-Dibenzo(A,d)Cycloheptene-Delta(Sup 5),gamma-Propylamine",
      "N,N-Dimethyl-5H-Dibenzo(A,d)Cycloheptene-Delta(5,gamma)-Propylamine",
      "1-Propanamine, 3-(5H-Dibenzo(A,d)Cyclohepten-5-Ylidene)-N,N-Dimethyl-(9CI)",
      "10,11-Dehydroamitriptyline",
      "3-(5H-Dibenzo[a,d]cyclohepten-5-Ylidene)-N,N-Dimethyl-1-Propanamine",
      "3-(5H-Dibenzo[a,d][7]annulen-5-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "3-(5H-Dibenzo[a,d]cyclohepten-5-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "5-(3-Dimetilaminopropiliden)-5H-Dibenzo-(A,d)-Ciclopentene",
      "5-(3-Dimetilaminopropiliden)-5H-Dibenzo-(A,d)-Ciclopentene [Italian]",
      "9715 R.P",
      "9715 R.P.",
    ],
  },
  {
    primary_id: "D01KHW",
    names: ["CZEN-002", "Bis(N-Acetyl-L-Cysteinyl-L-Lysyl-L-Prolyl-L-Valinamide) Disulfide"],
  },
  {
    primary_id: "D01KHX",
    names: ["Rilmakalim"],
  },
  {
    primary_id: "D01KIC",
    names: ["ORV-116E"],
  },
  {
    primary_id: "D01KIH",
    names: ["4-(4-Benzyl-Piperazin-1-Yl)-1H-Benzoimidazole", "CHEMBL88837"],
  },
  {
    primary_id: "D01KIJ",
    names: ["PMID25656651-Compound-37b"],
  },
  {
    primary_id: "D01KIS",
    names: ["CGP-53437", "CGP-58707"],
  },
  {
    primary_id: "D01KIU",
    names: ["PRE-084"],
  },
  {
    primary_id: "D01KIY",
    names: ["EPI-0031"],
  },
  {
    primary_id: "D01KJM",
    names: ["KST-401"],
  },
  {
    primary_id: "D01KJU",
    names: [
      "1-CHLORO-6-(4-HYDROXYPHENYL)-2-NAPHTHOL",
      "1-Chloro-6-(4-Hydroxyphenyl)-2-Naphthol",
      "CHEMBL364092",
      "1-Chloro-6-(4-Hydroxyphenyl)Naphthalen-2-Ol",
      "4NA",
      "1yy4",
      "AC1O0UUG",
      "1-Chloro-6-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "SCHEMBL3933824",
      "YHEHVRSGKUYDON-UHFFFAOYSA-N",
      "ZINC13645009",
      "BDBM50168323",
    ],
  },
  {
    primary_id: "D01KJZ",
    names: ["Pyrazole Derivative 4"],
  },
  {
    primary_id: "D01KKB",
    names: ["PW-2101", "Antihypertensive (Oral Once-Daily, TIMERx), Penwest"],
  },
  {
    primary_id: "D01KKE",
    names: ["GW876008"],
  },
  {
    primary_id: "D01KKF",
    names: ["PP2A Stimulator"],
  },
  {
    primary_id: "D01KKI",
    names: ["SGN-CD33A"],
  },
  {
    primary_id: "D01KKM",
    names: ["House Dust Mite Allergy Vaccine"],
  },
  {
    primary_id: "D01KKQ",
    names: ["Raltitrexed"],
  },
  {
    primary_id: "D01KKU",
    names: [
      "NSC-94258",
      "7-Hydroxyflavone",
      "6665-86-7",
      "7-Hydroxy-2-Phenyl-4H-Chromen-4-One",
      "Flavone, 7-Hydroxy-",
      "7-Hydroxy-2-Phenyl-4-Benzopyrone",
      "4H-1-Benzopyran-4-One, 7-Hydroxy-2-Phenyl-",
      "7-Hydroxy-2-Phenylchromen-4-One",
      "4H-1-Benzopyran-4-One, 7-Hydroxy-",
      "7-Hydroxy-2-Phenyl-Chromen-4-One",
      "NSC 94258",
      "UNII-ZE72458E4L",
      "EINECS 229-705-3",
      "MLS002695239",
      "CHEBI:2268",
      "CHEMBL276915",
      "MQGPSCMMNJKMHQ-UHFFFAOYSA-N",
      "ZE72458E4L",
      "NSC94258",
      "MFCD00006835",
      "7-Hydroxy-2-Phenyl-4H-1-Benzopyran-4-One",
      "H-5100",
      "SR-01000359106",
      "7-Hydroxyflavone, 11",
      "7-Hydroxy-Flavone,",
    ],
  },
  {
    primary_id: "D01KLI",
    names: ["1-Propyl-3-M-Tolyl-Piperidine Hydrochloride"],
  },
  {
    primary_id: "D01KMI",
    names: ["RS 116 0086"],
  },
  {
    primary_id: "D01KMR",
    names: ["Neramexane"],
  },
  {
    primary_id: "D01KMW",
    names: ["BMS-830216"],
  },
  {
    primary_id: "D01KMY",
    names: ["MK-8342"],
  },
  {
    primary_id: "D01KNA",
    names: ["Diamyd"],
  },
  {
    primary_id: "D01KNO",
    names: [
      "CTA1-3M2e-DD",
      "CTA1-M2e-DD",
      "Protein Fusion Vaccine (Influenza Virus Infection), Goteborg University",
      "CTA1-DD/M2e Vaccine (Influenza Virus Infection)",
      "CTA1-DD/M2e Vaccine (Influenza Virus Infection), Goteborg University",
    ],
  },
  {
    primary_id: "D01KNY",
    names: ["LCB-2183"],
  },
  {
    primary_id: "D01KNZ",
    names: ["[125I]ABOPX", "[125I]3-(3,4-Aminobenzyl)-8-(4-Oxyacetate)Phenyl-1-Propyl-Xanthine"],
  },
  {
    primary_id: "D01KOA",
    names: ["LOXO-292"],
  },
  {
    primary_id: "D01KOI",
    names: [
      "4-(2-Amino-1,3-Thiazol-4-Yl)Phenol",
      "57634-55-6",
      "4-(2-Amino-4-Thiazolyl)Phenol",
      "4-(2-Amino-Thiazol-4-Yl)-Phenol",
      "4-(2-Aminothiazol-4-Yl)Phenol",
      "CHEMBL483790",
      "QGSJYYIRAFRPIT-UHFFFAOYSA-N",
      "Phenol, 4-(2-Amino-4-Thiazolyl)-",
      "2-Amino-4-(4-Hydroxyphenyl)-Thiazole",
      "NSC405294",
      "3fu3",
      "Oprea1_415030",
      "SCHEMBL5321407",
      "P-(2-Amino-4-Thiazolyl)Phenol",
      "CTK5A7198",
      "DTXSID30323956",
      "MolPort-000-141-769",
      "HMS3604B06",
      "Phenol,4-(2-Amino-4-Thiazolyl)-",
      "AC1L8637",
      "KS-00000A4L",
      "ZINC16951624",
      "STK723345",
      "SBB018017",
      "BDBM50293592",
      "KM0129",
      "5926AE",
    ],
  },
  {
    primary_id: "D01KOO",
    names: [
      "(2,2-Dimethyl-1,3-Dioxolan-4-Yl)Methyl Sulfamate",
      "CHEMBL172215",
      "103596-21-0",
      "Sulfamic Acid, (2,2-Dimethyl-1,3-Dioxolan-4-Yl)Methyl Ester",
      "ACMC-20m6fs",
      "SCHEMBL18063439",
      "CTK0G6911",
      "DTXSID50545931",
      "BDBM50188393",
      "Sulfamic Acid 2,2-Dimethyl-[1,3]dioxolan-4-Ylmethyl Ester",
    ],
  },
  {
    primary_id: "D01KPN",
    names: ["CP-775146", "CP775146"],
  },
  {
    primary_id: "D01KPS",
    names: ["IP-112"],
  },
  {
    primary_id: "D01KPV",
    names: [
      "Fexofenadine",
      "Carboxyterfenadine",
      "Fexofendine",
      "Terfenadine Acid Metabolite",
      "Terfenadine Carboxylate",
      "F 9427",
      "MDL 16455",
      "Allegra (TN)",
      "Fastofen (TN)",
      "Fexofenadine (INN)",
      "Fexofenadine [INN:BAN]",
      "Telfast (TN)",
      "Terfenadine-COOH",
      "Terfenidine Carboxylate, MDL 16455",
      "Tilfur (TN)",
      "2-[4-(1-Hydroxy-4-{4-[hydroxy(Diphenyl)Methyl]piperidin-1-Yl}butyl)Phenyl]-2-Methylpropanoic Acid",
      "2-[4-[1-Hydroxy-4-[4-[hydroxy(Diphenyl)Methyl]piperidin-1-Yl]butyl]phenyl]-2-Methylpropanoic Acid",
      "4-(1-Hydroxy-4-(4-(Hydroxydiphenylmethyl)-1-Piperidinyl)Butyl)-Alpha,alpha-Dimethylbenzeneacetic Acid",
    ],
  },
  {
    primary_id: "D01KPW",
    names: ["Isoquinoline Carboxamide Derivative 1"],
  },
  {
    primary_id: "D01KPX",
    names: ["PT-315"],
  },
  {
    primary_id: "D01KQA",
    names: ["Nalbuphine"],
  },
  {
    primary_id: "D01KQW",
    names: ["ID-93/GLA-SE"],
  },
  {
    primary_id: "D01KRQ",
    names: ["EMC-0901"],
  },
  {
    primary_id: "D01KSO",
    names: ["Synthetic Peptide"],
  },
  {
    primary_id: "D01KSR",
    names: ["5-Chloro-2-(4-Hydroxy-Phenyl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D01KTB",
    names: ["ISIS 25080"],
  },
  {
    primary_id: "D01KTF",
    names: ["ISIS 113048"],
  },
  {
    primary_id: "D01KTG",
    names: ["HDM201"],
  },
  {
    primary_id: "D01KTM",
    names: ["(-)-Pinoresinol"],
  },
  {
    primary_id: "D01KTN",
    names: ["Cefcapene Pivoxil"],
  },
  {
    primary_id: "D01KVG",
    names: ["DR-4004", "Compound 7 [PMID 10052959]"],
  },
  {
    primary_id: "D01KVT",
    names: ["MEMOQUIN"],
  },
  {
    primary_id: "D01KWB",
    names: ["AD-313"],
  },
  {
    primary_id: "D01KWJ",
    names: ["VX-150"],
  },
  {
    primary_id: "D01KWR",
    names: ["HyperAcute Renal"],
  },
  {
    primary_id: "D01KWU",
    names: ["Uinagolide"],
  },
  {
    primary_id: "D01KXE",
    names: ["Influenza Vaccine"],
  },
  {
    primary_id: "D01KYA",
    names: ["6-Mercaptopurine (Oral Delayed-Release, Crohn's Disease), Teva"],
  },
  {
    primary_id: "D01KYG",
    names: ["INA-115E"],
  },
  {
    primary_id: "D01KYM",
    names: ["(S)-3-(2',2'-Dimethyl-Propionyl)Amino-Caprolactam", "CHEMBL512607"],
  },
  {
    primary_id: "D01KYP",
    names: [
      "APL-130277",
      "Apomorphine Hydrochloride Hemihydrate",
      "UNII-F39049Y068",
      "Apmorphine Hydrochloride Hemihydrate",
      "Apomorphin Hydrochlorid Wasser (2/1)",
      "Apomorphine Hydrochloride [USP]",
      "6abeta-Aporphine-10,11-Diol Hydrochloride Hemihydrate",
      "Apomorphine Hydrochloride Hydrate",
      "F39049Y068",
      "Uprima (TN)",
      "DSSTox_RID_82721",
      "DSSTox_CID_28159",
      "DSSTox_GSID_48185",
      "Apomorphine Hydrochloride (USP)",
      "58-00-4 (Parent)",
      "CHEBI:31228",
      "4H-Dibenzo(De,g)Quinoline-10,11-Diol, 5,6,6a,7-Tetrahydro-6-Methyl-, Hydrochloride, Hydrate",
    ],
  },
  {
    primary_id: "D01KYS",
    names: ["PMID29130358-Compound-Figure15(8)"],
  },
  {
    primary_id: "D01KZC",
    names: ["Minopafant", "E-5880"],
  },
  {
    primary_id: "D01KZF",
    names: ["(S)(+)-2-(4-Fluorophenyl)-7-Methoxychroman-4-One", "CHEMBL598096"],
  },
  {
    primary_id: "D01KZV",
    names: ["MIQ-004"],
  },
  {
    primary_id: "D01LAC",
    names: ["MOFEGILINE"],
  },
  {
    primary_id: "D01LAP",
    names: ["ISIS 2922 + Ganciclovir"],
  },
  {
    primary_id: "D01LAU",
    names: [
      "5-Methoxycarbonylamino-N-Acetyltryptamine",
      "GR 135531",
      "190277-13-5",
      "5-MCA-NAT",
      "5MCA-NAT",
      "CHEMBL504585",
      "Methyl 3-(2-Acetamidoethyl)-1H-Indol-5-Ylcarbamate",
      "Methyl N-[3-(2-Acetamidoethyl)-1H-Indol-5-Yl]carbamate",
      "Methyl {3-[2-(Acetylamino)Ethyl]-1h-Indol-5-Yl}carbamate",
      "Methyl [3-[2-(Acetylamino)Ethyl]-1H-Indol-5-Yl]carbamate",
      "Tocris-0896",
      "AC1N7T0H",
      "GTPL3393",
      "SCHEMBL4655625",
      "CTK6J2921",
      "CHEBI:93457",
      "DTXSID70401560",
      "MPZVHKLZCUEJFO-UHFFFAOYSA-N",
      "MolPort-003-848-587",
      "HMS3266P16",
      "ZINC2567732",
      "BN0231",
      "BDBM50260394",
      "PDSP2_001790",
    ],
  },
  {
    primary_id: "D01LAX",
    names: ["Poloxafuse-R"],
  },
  {
    primary_id: "D01LBD",
    names: ["Haw-AD-14"],
  },
  {
    primary_id: "D01LBI",
    names: [
      "LIV-1205",
      "Anti-Dlk-1 Antibody (Cancer), LivTech/Kyowa Hakko Kirin",
      "Anti-Dlk-1 Antibody (Cancer), LivTech/Kyowa Hakko Kogyo",
    ],
  },
  {
    primary_id: "D01LCC",
    names: [
      "PF-4217903",
      "2-(4-(1-(Quinolin-6-Ylmethyl)-1H-[1,2,3]triazolo[4,5-B]pyrazin-6-Yl)-1H-Pyrazol-1-Yl)Ethanol",
      "PF-04217903",
      "956905-27-4",
      "PF04217903",
      "PF 04217903",
      "UNII-CYJ9ATV1IJ",
      "CYJ9ATV1IJ",
      "CHEMBL2001019",
      "1159490-85-3",
      "Aka PF-04217903",
      "C19H16N8O",
      "2-[4-(3-Quinolin-6-Ylmethyl-3H-[1,2,3]triazolo[4,5-B]pyrazin-5-Yl)-Pyrazol-1-Yl]-Ethanol",
      "2-(4-(1-(Quinolin-6-Ylmethyl)-1H-(1,2,3)Triazolo(4,5-B)Pyrazin-6-Yl)-1H-Pyrazol-1-Yl)Ethanol",
      "3zxz",
      "PDMUGYOXRHVNMO-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D01LCS",
    names: ["CRA_10655", "AC1O4QGF"],
  },
  {
    primary_id: "D01LCT",
    names: ["Sodium Glucose Cotransporter 2 (SGLT2) Inhibitor"],
  },
  {
    primary_id: "D01LCZ",
    names: ["PMID28092474-Compound-32j"],
  },
  {
    primary_id: "D01LDO",
    names: [
      "(R)-2-Amino-7-Phosphono-Heptanoic Acid",
      "81338-23-0",
      "UNII-89FET0690I",
      "(2R)-2-Amino-7-Phosphonoheptanoic Acid",
      "D(-)-2-Amino-7-Phosphonoheptanoic Acid",
      "Heptanoic Acid, 2-Amino-7-Phosphono-, (2R)-",
      "CHEMBL302783",
      "89FET0690I",
      "D-(-)-2-Amino-7-Phosphonoheptanoic Acid",
      "(R)-AP7",
      "D-(-)-2-Amino-7-Phosphonoheptanoate",
      "AC1LUTYN",
      "Tocris-0164",
      "Tocris-0104",
      "Lopac-A-167",
      "Lopac-A-5157",
      "Lopac0_000157",
      "SCHEMBL9848541",
      "ZINC1856281",
      "BDBM50017226",
      "STL526509",
      "BN0090",
      "AKOS024456719",
      "CCG-204252",
    ],
  },
  {
    primary_id: "D01LDP",
    names: ["Ampicidin Analog 2"],
  },
  {
    primary_id: "D01LDQ",
    names: [
      "Mycophenolic Hydroxamic Acid",
      "CHEMBL238461",
      "BDBM50228085",
      "N-Hydroxy-6-(4-Hydroxy-6-Methoxy-7-Methyl-3-Oxo-1,3-Dihydroisobenzofuran-5-Yl)-4-Methylhex-4-Enamide",
    ],
  },
  {
    primary_id: "D01LEO",
    names: ["LJJ-10"],
  },
  {
    primary_id: "D01LEP",
    names: ["2-Amino-8-Methyl-7,8-Dihydropteridin-4(3H)-One"],
  },
  {
    primary_id: "D01LES",
    names: ["MEGF0444A"],
  },
  {
    primary_id: "D01LEV",
    names: ["3-((3-Bromothiophen-2-Yl)Methylene)Indolin-2-One", "CHEMBL230414"],
  },
  {
    primary_id: "D01LFA",
    names: ["ARX-201"],
  },
  {
    primary_id: "D01LFC",
    names: ["Mutant Ras Vaccine"],
  },
  {
    primary_id: "D01LFH",
    names: ["Curmucin"],
  },
  {
    primary_id: "D01LFN",
    names: ["S. Mutans Replacement Therapy"],
  },
  {
    primary_id: "D01LFP",
    names: ["N1-(4-Chlorobenzyl)-2-Benzyl-N3-Hydroxymalonamide", "CHEMBL374577", "BDBM50206546"],
  },
  {
    primary_id: "D01LFQ",
    names: ["DWP-438"],
  },
  {
    primary_id: "D01LGQ",
    names: ["PMID25666693-Compound-103"],
  },
  {
    primary_id: "D01LKO",
    names: ["WFwGG-NH2", "CHEMBL444131"],
  },
  {
    primary_id: "D01LMH",
    names: ["[Sar1Gly]GAL-B2", "CHEMBL499807"],
  },
  {
    primary_id: "D01LMM",
    names: ["BLY719"],
  },
  {
    primary_id: "D01LMW",
    names: ["Pyrrolo[2,3-B]pyridine Derivative 4"],
  },
  {
    primary_id: "D01LNG",
    names: ["Balicatib"],
  },
  {
    primary_id: "D01LNP",
    names: ["Samarium-153-DOTMP", "DOTMP-[153Sm]", "153Sm-DOTMP"],
  },
  {
    primary_id: "D01LOE",
    names: ["LY2963016"],
  },
  {
    primary_id: "D01LOJ",
    names: ["Pyrazolopyrimidine Acetamide Analog 1"],
  },
  {
    primary_id: "D01LOU",
    names: [
      "4-Imidazol-1-Ylmethyl-1-Nitro-Xanthen-9-One",
      "Aromatase Inhibitor I",
      "CHEMBL159073",
      "4-(Imidazolylmethyl)-1-Nitro-9H-9-Xanthenone",
      "AC1L1D8C",
      "CTK5I3006",
      "BDBM50097366",
      "4-(Imidazol-1-Ylmethyl)-1-Nitroxanthen-9-One",
      "4-(1H-Imidazol-1-Ylmethyl)-1-Nitro-9H-Xanthen-9-One",
      "4-((1H-Imidazol-1-Yl)Methyl)-1-Nitro-9H-Xanthen-9-One",
      "1-Nitro-4-(1H-Imidazole-1-Ylmethyl)-9H-Xanthene-9-One",
    ],
  },
  {
    primary_id: "D01LPG",
    names: [
      "4-[(6-Chloropyrazin-2-Yl)Amino]benzenesulfonamide",
      "CHEMBL487738",
      "4-(6-Chloropyrazin-2-Ylamino)Benzenesulfonamide",
      "642459-21-0",
      "2vtj",
      "SCHEMBL6659290",
      "BDBM24631",
      "ZINC16052861",
      "DB08134",
      "FT-0723597",
      "4-[(6-Chloropyrazin-2-Yl)Amino]benzene-1-Sulfonamide",
      "4-[(6-Chloropyrazin-2-Yl)Amino]benzenesulfonamide, 10",
    ],
  },
  {
    primary_id: "D01LPK",
    names: ["MGX-006", "Anti-Emetic (Nausea/Pain/Emesis), Victory Pharma"],
  },
  {
    primary_id: "D01LPX",
    names: ["Linotroban", "CL-871502", "HN-11500"],
  },
  {
    primary_id: "D01LQC",
    names: [
      "P45",
      "CHEMBL230347",
      "2-(4-Chlorobenzylamino)-4-(Phenylamino)Pyrazolo[1,5-A][1,3,5]triazine-8-Carbonitrile",
      "2pvk",
      "BDBM50214465",
      "DB08354",
      "2-[(4-Chlorobenzyl)Amino]-4-(Phenylamino)Pyrazolo[1,5-A][1,3,5]triazine-8-Carbonitrile",
    ],
  },
  {
    primary_id: "D01LQJ",
    names: ["VRS-XXX"],
  },
  {
    primary_id: "D01LQZ",
    names: ["TG-4023"],
  },
  {
    primary_id: "D01LRC",
    names: ["ANIBAMINE", "Anibamine TFA"],
  },
  {
    primary_id: "D01LRH",
    names: [
      "Sibopirdine",
      "DuP-921",
      "EXP-9121",
      "EXP-921",
      "5H-Cyclopenta[2,1-B:3,4-B']dipyridine, 5,5-Bis(4-Pyridinylmethyl)-, Monohydrate",
    ],
  },
  {
    primary_id: "D01LRM",
    names: [
      "SBC-014",
      "AVI-014",
      "Glycosylated Recombinant G-CSF (Neutropenia), AviGenics",
      "Glycosylated Recombinant G-CSF (Neutropenia), Synageva",
    ],
  },
  {
    primary_id: "D01LSB",
    names: ["MEMP1972A"],
  },
  {
    primary_id: "D01LSP",
    names: ["Pyrido[3,2-E][1,2,4]triazolo[4,3-A]pyrazine Derivative 1"],
  },
  {
    primary_id: "D01LTK",
    names: ["PMID25553724-Compound-WO2011152671 2"],
  },
  {
    primary_id: "D01LUB",
    names: [
      "4,8-Dimethyl-7-(2'-Oxocyclohexyloxy)Coumarin",
      "CHEMBL488917",
      "4,8-Dimethyl-7-((2-Oxocyclohexyl)Oxy)-2H-Chromen-2-One",
      "4,8-Dimethyl-7-[(2-Oxocyclohexyl)Oxy]-2H-Chromen-2-One",
      "AC1N8LB1",
      "MLS001213498",
      "MolPort-000-837-574",
      "HMS2863H04",
      "STL528052",
      "BDBM50246220",
      "AKOS030497124",
      "MCULE-3956285825",
      "SMR000540525",
      "4,8-Dimethyl-7-(2''-Oxocyclohexyloxy)Coumarin",
      "4,8-Dimethyl-7-(2-Oxocyclohexyl)Oxychromen-2-One",
    ],
  },
  {
    primary_id: "D01LUH",
    names: ["SP-30"],
  },
  {
    primary_id: "D01LUJ",
    names: ["PMID27376512-Compound-Figure2aExample1"],
  },
  {
    primary_id: "D01LUS",
    names: ["Mesoglycan"],
  },
  {
    primary_id: "D01LVE",
    names: ["CPI-0610"],
  },
  {
    primary_id: "D01LVM",
    names: ["PMA-BB10"],
  },
  {
    primary_id: "D01LVT",
    names: ["Quinoline Derivative 2"],
  },
  {
    primary_id: "D01LVY",
    names: [
      "(10H-Phenothiazin-10-Yl)(P-Tolyl)Methanone",
      "CHEMBL397100",
      "AC1MRSS1",
      "Oprea1_194230",
      "MLS002608923",
      "Cid_3473393",
      "CHEBI:93656",
      "BDBM50219212",
      "SMR001537727",
      "(4-Methylphenyl)-Phenothiazin-10-Ylmethanone",
    ],
  },
  {
    primary_id: "D01LWE",
    names: ["EGFRvIII CAR T Cells"],
  },
  {
    primary_id: "D01LWJ",
    names: [
      "Apigenin-7-O-Beta-D-Glucuronide",
      "29741-09-1",
      "Apigenin-7-Glucuronide",
      "Apigenin 7-O-B-Glucuronide",
      "Apigenin 7-Glucuronide",
      "Apigenin-7-O-Glucuronide",
      "UNII-2CQ5KB3CH0",
      "2CQ5KB3CH0",
      "CHEMBL254213",
      "Scutellarin A",
      "Apigenin 7-O-Glucuronide",
      "Apigenin 7-Beta-Glucuronide",
      "Apigenin 7-O-Beta-D-Glucuronide",
      "Apigenin-7-O-Beta-D-Glucuronoside",
      "Apigenin 7-O-Beta-D-Glucuronopyranoside",
      "AC1NSY61",
      "MEGxp0_000795",
      "SCHEMBL2400851",
      "ACon1_001025",
      "DTXSID10183893",
      "MolPort-001-740-947",
      "Apigenin-7-O-BetaD-Glucuronic Acid",
      "HY-N1454",
      "Apigenin 7-O-.beta.-D-Glucur",
    ],
  },
  {
    primary_id: "D01LWO",
    names: ["TM-601"],
  },
  {
    primary_id: "D01LXD",
    names: [
      "Semagacestat",
      "DAPT",
      "AN-37124",
      "AN-44989",
      "LY-374973",
      "LY-411575",
      "LY-424354",
      "LY-450139",
      "LY-4501395",
      "Gamma-Secretase Inhibitors (Alzheimer's Disease), Elan Pharmaceuticals/Eli Lilly",
    ],
  },
  {
    primary_id: "D01LXG",
    names: ["KC-11425"],
  },
  {
    primary_id: "D01LXS",
    names: [
      "GM604",
      "Alirinetide",
      "UNII-6BK9OEG8CC",
      "6BK9OEG8CC",
      "725715-18-4",
      "GM-603",
      "GM-605",
      "GM-604",
      "GM-607",
      "GM-609",
      "GM-606",
      "L-Arginine, L-Phenylalanyl-L-Seryl-L-Arginyl-L-Tyrosyl-L-Alanyl-",
      "(2S)-2-(((2S)-2-(((2S)-2-(((2S)-2-(((2S)-2-(((2S)-2-Amino-3-Phenyl-Propanoyl)Amino)-3-Hydroxy-Propanoyl)Amino)-5-Guanidino-Pentanoyl)Amino)-3-(4-Hydroxyphenyl)Propanoyl)Amino)Propanoyl)Amino)-5-Guanidino-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D01LXZ",
    names: ["DWJ-206", "Antiulcer Agents (Gastric Ulcer), Daewoong Pharmaceuticals"],
  },
  {
    primary_id: "D01LYK",
    names: ["ISIS 9058"],
  },
  {
    primary_id: "D01LYX",
    names: ["Eptastigmine"],
  },
  {
    primary_id: "D01LZB",
    names: ["Anti-HM1.24"],
  },
  {
    primary_id: "D01LZC",
    names: ["SDZ-GLI-328"],
  },
  {
    primary_id: "D01LZL",
    names: ["FGGFTGCRKSCRK"],
  },
  {
    primary_id: "D01MAC",
    names: ["Isosorbide-2-(Butylcarbamate)-5-Mononitrate"],
  },
  {
    primary_id: "D01MAN",
    names: ["2-Benzoxy-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL1098228"],
  },
  {
    primary_id: "D01MAU",
    names: ["AZD-1446"],
  },
  {
    primary_id: "D01MBF",
    names: ["4-(2-Fluoro-6-(4-Fluorophenoxy)Phenyl)Piperidine", "CHEMBL574231"],
  },
  {
    primary_id: "D01MBG",
    names: ["N-(Sulfanylacetyl)Tyrosylprolylmethioninamide"],
  },
  {
    primary_id: "D01MBM",
    names: ["LPO-1010DR"],
  },
  {
    primary_id: "D01MBR",
    names: ["BMS-245782"],
  },
  {
    primary_id: "D01MBV",
    names: ["SB-207266A"],
  },
  {
    primary_id: "D01MCB",
    names: ["HTERT Plasmid DNA Cancer Vaccine"],
  },
  {
    primary_id: "D01MCD",
    names: [
      "(2R)-N-(6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDIN-4-YL)-2-HYDROXYPROPANAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1083646",
      "SCHEMBL1404538",
      "BDBM50320177",
    ],
  },
  {
    primary_id: "D01MCF",
    names: ["ISIS 23454"],
  },
  {
    primary_id: "D01MDE",
    names: ["Fumarate"],
  },
  {
    primary_id: "D01MDH",
    names: ["Conjugated Stigmines", "Conjugated Stigmines (CNS Disorders)"],
  },
  {
    primary_id: "D01MDT",
    names: ["Tizanidine"],
  },
  {
    primary_id: "D01MDX",
    names: ["TRBC1"],
  },
  {
    primary_id: "D01MEA",
    names: ["3-Butyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL186091", "SCHEMBL7679025"],
  },
  {
    primary_id: "D01MEP",
    names: ["GSK-3beta Inhibitor II"],
  },
  {
    primary_id: "D01MFA",
    names: ["Infusible Platelet Membrane", "Cyplex", "Infusible Platelet Membrane, Cypress"],
  },
  {
    primary_id: "D01MFG",
    names: ["Cholera EPVaccine"],
  },
  {
    primary_id: "D01MFK",
    names: [
      "DFU",
      "Deoxyfuconojirimycin",
      "1,2,6-Trideoxy-2,6-Imino-D-Galactitol",
      "(2S,3R,4S,5R)-2-Methylpiperidine-3,4,5-Triol",
      "99212-30-3",
      "1,5-Dideoxy-1,5-Iminofucitol",
      "CHEMBL314772",
      "1-Deoxyfuconojirimycin HCl",
      "1,5-Dideoxy-1,5-Imino-L-Fucitol",
      "L-Fuco-Deoxynojirimycin",
      "Fucosidase Inhibitor, 3",
      "AC1L3UXS",
      "AC1Q59GC",
      "SCHEMBL4378188",
      "3,4,5-Piperidinetriol, 2-Methyl-, (2S,3R,4S,5R)-",
      "CHEBI:132866",
      "1-Deoxyfuconojirimycin Hydrochloride",
      "ZINC2585424",
      "BDBM50065258",
      "AKOS006283710",
      "D-Galactitol, 1,2,6-Trideoxy-2,6-Imino-",
      "FT-0624513",
    ],
  },
  {
    primary_id: "D01MFM",
    names: ["H-Dmt-D-Arg(NO2)-Phe-Lys(Z)-OH", "CHEMBL370701"],
  },
  {
    primary_id: "D01MFT",
    names: ["Chlordane", "Chlorindan", "Chlordan"],
  },
  {
    primary_id: "D01MFV",
    names: ["N-(2-Mercapto-Ethyl)-N'-Phenyl-Succinamide", "CHEMBL193959"],
  },
  {
    primary_id: "D01MGA",
    names: ["Alatrofloxacin Mesylate", "Alatrofloxacin", "Trovan IV", "Turvel IV", "CP-116517", "CP-116517-27"],
  },
  {
    primary_id: "D01MGI",
    names: ["C-[-Arg-Gly-Asp-Acpca32-]", "CHEMBL556402"],
  },
  {
    primary_id: "D01MGV",
    names: ["WC-3048"],
  },
  {
    primary_id: "D01MHI",
    names: [
      "Coluracetam",
      "BCI-540",
      "MKC-231",
      "High-Affinity Choline Uptake Facilitator (CNS Disorders), Mitsubishi",
      "Neurons Growth Promoting Compound (Major Depressive Disorder/Anxiety), BrainCells",
      "High-Affinity Choline Uptake Facilitator (Depression/ Anxiety), BrainCells",
    ],
  },
  {
    primary_id: "D01MHL",
    names: ["T-2307"],
  },
  {
    primary_id: "D01MHO",
    names: ["DC6S"],
  },
  {
    primary_id: "D01MHP",
    names: ["Benzyloxycarbonyl-Glycylryanodine", "CHEMBL28383"],
  },
  {
    primary_id: "D01MHT",
    names: ["BBT-046"],
  },
  {
    primary_id: "D01MIP",
    names: ["SU5614"],
  },
  {
    primary_id: "D01MJF",
    names: ["JWH-404"],
  },
  {
    primary_id: "D01MJT",
    names: ["MGN-2677"],
  },
  {
    primary_id: "D01MKD",
    names: ["Phenylalanine Derivative 1"],
  },
  {
    primary_id: "D01MKM",
    names: ["Tyr-Pro-Hfe-Phe-NH2", "CHEMBL378466"],
  },
  {
    primary_id: "D01MLQ",
    names: ["(4-Benzylpiperidin-1-Yl)(2-Fluorophenyl)Methanone"],
  },
  {
    primary_id: "D01MMB",
    names: ["Extrothyroxine"],
  },
  {
    primary_id: "D01MMG",
    names: ["BBT-015", "BBT-002"],
  },
  {
    primary_id: "D01MML",
    names: ["MLN9708"],
  },
  {
    primary_id: "D01MMM",
    names: ["TENELLONE A"],
  },
  {
    primary_id: "D01MMT",
    names: ["PMID25482888-Compound-13"],
  },
  {
    primary_id: "D01MMU",
    names: ["5-Methoxy-2-(4-Methylaminophenyl)Benzofuran", "CHEMBL208212", "BDBM50185947", "ZINC13686344"],
  },
  {
    primary_id: "D01MND",
    names: ["EU-C-001"],
  },
  {
    primary_id: "D01MNK",
    names: ["BS-194", "CDK Inhibitors (Cancer), Imperial College London/Emory University"],
  },
  {
    primary_id: "D01MNV",
    names: ["HER-2VAX", "Anticancer Vaccine, Aixlie", "HER-2 Vaccine, Aixlie", "HER-2 Vaccine, Ohio State"],
  },
  {
    primary_id: "D01MOQ",
    names: [
      "7-Methyl-2-Propyl-[1,8]naphthyridin-4-Ol",
      "CHEMBL103150",
      "1,8-Naphthyridin-4-Ol, 7-Methyl-2-Propyl-",
      "BDBM50147877",
      "718624-73-8",
    ],
  },
  {
    primary_id: "D01MPE",
    names: ["S-2150"],
  },
  {
    primary_id: "D01MPK",
    names: [
      "3-(4-Amino-Phenyl)-1-Methyl-Pyrrolidine-2,5-Dione",
      "CHEMBL357775",
      "2,5-Pyrrolidinedione, 3-(4-Aminophenyl)-1-Methyl-",
      "59512-13-9",
      "CTK1E7233",
      "DTXSID50490593",
      "BDBM50025151",
      "AKOS022647888",
    ],
  },
  {
    primary_id: "D01MPX",
    names: [
      "PC-190723",
      "CDI-936",
      "PC-182267",
      "PC-189309",
      "PC-189747",
      "Anti-Bacterial, Prolysis",
      "Antibacterial (Staphylococcal Infection), Biota",
      "FtsZ Protein Inhibitor (Staphylococcus Infections), Biota",
    ],
  },
  {
    primary_id: "D01MQH",
    names: ["Vepalimomab"],
  },
  {
    primary_id: "D01MQP",
    names: ["SECTA Belomycin"],
  },
  {
    primary_id: "D01MSJ",
    names: ["Veronate"],
  },
  {
    primary_id: "D01MSM",
    names: ["3-Hydroxy-7-Nitro-1H-Benzo[b]azepine-2,5-Dione"],
  },
  {
    primary_id: "D01MSP",
    names: ["PMID30107136-Compound-Example3"],
  },
  {
    primary_id: "D01MSZ",
    names: ["RM-4865"],
  },
  {
    primary_id: "D01MUN",
    names: [
      "4-Benzoimidazol-1-Yl-Phenylamine",
      "4-(1H-Benzimidazol-1-Yl)Aniline",
      "52708-36-8",
      "4-(1H-Benzo[d]imidazol-1-Yl)Aniline",
      "4-(1H-1,3-Benzodiazol-1-Yl)Aniline",
      "4-Benzimidazolylphenylamine",
      "AC1NS4KU",
      "4-(Benzimidazol-1-Yl)Aniline",
      "AC1Q51W1",
      "SCHEMBL1208951",
      "BDBM3829",
      "CHEMBL346870",
      "CTK1H4832",
      "DTXSID80416160",
      "1-Phenylbenzimidazole Deriv. 46",
      "MolPort-000-892-003",
      "ZINC2538824",
      "7454AB",
      "STK122023",
      "1-(4-Aminophenyl)-1H-Benzimidazole",
      "SBB022485",
      "AKOS000200797",
      "MCULE-3811873515",
      "AJ-38769",
      "DA-18573",
      "Benzenamine, 4-(1H-Benzimidazol-1-Yl)-",
      "KB-237102",
      "ST45055167",
      "FT-0748937",
      "EN300-14992",
    ],
  },
  {
    primary_id: "D01MUR",
    names: [
      "Brinzolamide",
      "Azopt",
      "Birnzolamide",
      "Alcon Brand Of Brinzolamide",
      "Allphar Brand Of Brinzolamide",
      "Brinzolamide [USAN]",
      "AL 4862",
      "AL04862",
      "BZ1",
      "AL-4862",
      "Azopt (TN)",
      "Brinzolamide (BRZ)",
      "Brinzolamide (JAN/USP/INN)",
      "(+)-4-ETHYLAMINO-3,4-DIHYDRO-2-(METHOXY)PROPYL-2H-THIENO[3,2-E]-1,2-THIAZINE-6-SULFONAMIDE-1,1-DIOXIDE",
      "(4R)-4-(Ethylamino)-2-(3-Methoxypropyl)-1,1-Dioxo-3,4-Dihydrothieno[3,2-E]thiazine-6-Sulfonamide",
      "(4R)-4-(Ethylamino)-2-(3-Methoxypropyl)-3,4-Dihydro-2H-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide 1,1-Dioxide",
      "(R)-4-(Ethylamino)-3,4-Dihydro-2-(3-Methoxypropyl)-2H-Thieno(3,2-E)-1,2-Thiazine-6-Sulfonamide 1,1-Dioxide",
      "(R)-4-(Ethylamino)-3,4-Dihydro-2-(3-Methoxypropyl)-2H-Thieno(3,2-E)-1,2-Thiazine-6-Sulfonamide",
      "2H-Thieno(3,2-E)-1,2-Thiazine-6-Sulfonamide,4-(Ethylamino)-3,4-Dihydro-2-(3-Methoxypropyl)-,1,1-Dioxide,R",
    ],
  },
  {
    primary_id: "D01MUU",
    names: ["MELAGATRAN"],
  },
  {
    primary_id: "D01MUV",
    names: ["1-Benzhydryl-4-Tert-Butylpiperidin-4-Ol", "CHEMBL234771"],
  },
  {
    primary_id: "D01MUW",
    names: ["Antineoplaston AS2-1", "Astugenal", "Antineoplaston AS2-1, Burzynski Institute"],
  },
  {
    primary_id: "D01MVK",
    names: ["CV-787", "CG-7870"],
  },
  {
    primary_id: "D01MVO",
    names: ["ARG201"],
  },
  {
    primary_id: "D01MVX",
    names: ["TACRINE(8)-4-AMINOQUINOLINE"],
  },
  {
    primary_id: "D01MWF",
    names: ["GTx-878"],
  },
  {
    primary_id: "D01MWX",
    names: ["2,7-Bis(Cyclopropanecarbonamido)Anthraquinone", "CHEMBL520920", "SCHEMBL4296357"],
  },
  {
    primary_id: "D01MXG",
    names: ["GLP-1 Receptor PAM"],
  },
  {
    primary_id: "D01MXK",
    names: ["2PX"],
  },
  {
    primary_id: "D01MXN",
    names: ["8-(1H-Indol-5-Yl)-2-Morpholin-4-Ylchromen-4-One", "CHEMBL200567"],
  },
  {
    primary_id: "D01MXV",
    names: ["Etilefrine"],
  },
  {
    primary_id: "D01MYJ",
    names: ["WT-4869"],
  },
  {
    primary_id: "D01MYN",
    names: [
      "LY-2523199",
      "11-Beta Hydroxysteroid Dehydrogenase Type 1 Inhibitor (Diabetes), Eli Lilly",
      "11-Beta-HSD-1 Inhibitor (Type 2 Diabetes), Eli Lilly",
    ],
  },
  {
    primary_id: "D01MYO",
    names: [
      "Fexaramine",
      "574013-66-4",
      "CHEMBL192966",
      "CHEBI:80003",
      "FEX",
      "(E)-Methyl 3-(3-(N-((4'-(Dimethylamino)-[1,1'-Biphenyl]-4-Yl)Methyl)Cyclohexanecarboxamido)Phenyl)Acrylate",
      "C32H36N2O3",
      "AC1NS0YI",
      "A1-01872",
      "GTPL2744",
      "Fexaramine, &gt",
      "SCHEMBL17083218",
      "AOB5017",
      "MolPort-023-276-486",
      "EX-A2246",
      "BCP15784",
      "ZINC13831232",
      "3671AH",
      "BDBM50167161",
      "AKOS024457169",
      "DB02545",
      "CS-3884",
      "NCGC00167776-01",
      "HY-10912",
      "AS-35318",
      "AK175872",
      "BC600742",
      "KB-69557",
      "RT-012704",
      "B7108",
      "C15649",
      "BRD-K34170797-001-01-1",
    ],
  },
  {
    primary_id: "D01MZI",
    names: ["Pyrazino[2,1-A]isoquinolin Derivative 4"],
  },
  {
    primary_id: "D01MZJ",
    names: ["LR3001"],
  },
  {
    primary_id: "D01MZT",
    names: ["FGFR2-ADC"],
  },
  {
    primary_id: "D01NAM",
    names: ["ELB-139"],
  },
  {
    primary_id: "D01NAP",
    names: ["MM-151"],
  },
  {
    primary_id: "D01NBF",
    names: ["3alpha-O-Trans-P-Coumaroyl-7-Labden-15-Oic Acid", "CHEMBL494656"],
  },
  {
    primary_id: "D01NBK",
    names: ["Pyridoxamine-5'-Phosphate"],
  },
  {
    primary_id: "D01NBW",
    names: ["PMID28870136-Compound-53"],
  },
  {
    primary_id: "D01NBX",
    names: ["RS-2135", "(+)-RS-2135", "133775-36-7"],
  },
  {
    primary_id: "D01NCC",
    names: ["Factor-VIIa-XTEN"],
  },
  {
    primary_id: "D01NCH",
    names: ["Lufironil"],
  },
  {
    primary_id: "D01NCJ",
    names: ["Peptide Analog 10"],
  },
  {
    primary_id: "D01NCS",
    names: ["TOL-463", "Biofilm Disruptor (Vaginal, Vaginitis), Toltec"],
  },
  {
    primary_id: "D01NCW",
    names: ["Cbz-Ile-Phe-Ala-LeuVSMe", "CHEMBL207284"],
  },
  {
    primary_id: "D01NDE",
    names: [
      "2,2-Dimethyl-3-Methyleneheptadecane",
      "CHEMBL270375",
      "2-Propanone, 3-(Dodecylthio)-1,1,1-Trifluoro-",
      "92682-28-5",
      "ACMC-20lwey",
      "CTK3F7670",
      "DTXSID90467547",
      "BDBM50371972",
    ],
  },
  {
    primary_id: "D01NDF",
    names: ["AVN 101"],
  },
  {
    primary_id: "D01NDN",
    names: ["SNG-8006"],
  },
  {
    primary_id: "D01NEC",
    names: ["GSK2141795"],
  },
  {
    primary_id: "D01NEZ",
    names: ["CHLORODYSINOSIN A", "Chlorodysinosin A", "CHEMBL568990"],
  },
  {
    primary_id: "D01NFH",
    names: ["1-Cyclohexyl-3-(1-Propionylpiperidin-4-Yl)Urea", "CHEMBL1256164", "SCHEMBL2730613", "BDBM50327823"],
  },
  {
    primary_id: "D01NFK",
    names: ["FP-1069"],
  },
  {
    primary_id: "D01NFW",
    names: ["HIV Integrase Inhibitors"],
  },
  {
    primary_id: "D01NGB",
    names: [
      "ADC-1001",
      "DC-1001",
      "Second Generation Rheumatoid Arthritis Therapy, Alligator BioScience",
      "ADC-1001-RA",
      "Interleukin-1 Receptor Antagonist (Subcutaneous, Inflammation), Alligator Bioscience",
    ],
  },
  {
    primary_id: "D01NGF",
    names: ["BIOO-4"],
  },
  {
    primary_id: "D01NGH",
    names: ["Multi-Envelope HIV Vaccine"],
  },
  {
    primary_id: "D01NGT",
    names: ["RG-7185", "CRTH2 Antagonist (Asthma), Roche"],
  },
  {
    primary_id: "D01NHH",
    names: ["2,6-Diphenyl-8-TButyl-1-Deazapurine", "CHEMBL220053"],
  },
  {
    primary_id: "D01NHO",
    names: ["Undisclosed Protein"],
  },
  {
    primary_id: "D01NID",
    names: ["Des-AA1,2,5-[D-Nal8,IAmp9,(NalphaMe)Ser13]SRIF", "CHEMBL264839"],
  },
  {
    primary_id: "D01NIJ",
    names: ["S 1743"],
  },
  {
    primary_id: "D01NIR",
    names: ["Radotinib"],
  },
  {
    primary_id: "D01NJE",
    names: ["ED45"],
  },
  {
    primary_id: "D01NJI",
    names: ["Mepyramine Maleate"],
  },
  {
    primary_id: "D01NJT",
    names: [
      "Esreboxetine",
      "(S,S)-Reboxetine",
      "98819-76-2",
      "UNII-L8S50ZY490",
      "PNU-165442G",
      "L8S50ZY490",
      "Norebox",
      "Davedax",
      "Solvex",
      "(2S)-2-[(S)-(2-Ethoxyphenoxy)-Phenylmethyl]morpholine",
      "(2S)-2-[(S)-2-Ethoxyphenoxy(Phenyl)Methyl]morpholine",
      "(2S)-2-[(S)-(2-Ethoxyphenoxy)-Phenyl-Methyl]morpholine",
      "Prolift",
      "Esreboxetine [USAN:INN]",
      "Esreboxetine (USAN/INN)",
      "(S,S)-( )-Reboxetine",
      "S-Reboxetine",
      "PNU 165442g",
      "2-((2-Ethoxyphenoxy)Benzyl)Morpholine Methanesulfonate",
      "Tocris-1982",
      "NCGC00025335-01",
      "SCHEMBL34534",
      "Reboxetine Mesylate",
      "(+-)-(2R*)-2-((AlphaR*)-Alpha-(O-Ethoxyphenoxy)Benzyl)Morpholine",
    ],
  },
  {
    primary_id: "D01NJW",
    names: ["PMID26815044-Compound-31"],
  },
  {
    primary_id: "D01NKF",
    names: ["LY-1-100"],
  },
  {
    primary_id: "D01NKW",
    names: ["P2B-001"],
  },
  {
    primary_id: "D01NLB",
    names: ["Ziconotide"],
  },
  {
    primary_id: "D01NMN",
    names: [
      "ALORACETAM",
      "Aloracetam",
      "N-[2-(3-Formyl-2,5-Dimethylpyrrol-1-Yl)Ethyl]acetamide",
      "1-(2-Acetamidoethyl)-2,5-Dimethylpyrrole-3-Carboxaldehyde",
    ],
  },
  {
    primary_id: "D01NNL",
    names: ["Phosphonate"],
  },
  {
    primary_id: "D01NOI",
    names: ["IPH-1201"],
  },
  {
    primary_id: "D01NOZ",
    names: ["ADS-103274", "CHEMBL276393", "BDBM50241099"],
  },
  {
    primary_id: "D01NPA",
    names: ["Neurokinin A"],
  },
  {
    primary_id: "D01NPK",
    names: ["PTH-CBD"],
  },
  {
    primary_id: "D01NQM",
    names: ["Lasmiditan", "COL-144", "LY-573144", "Migraine Therapy, CoLucid"],
  },
  {
    primary_id: "D01NSM",
    names: ["TST-10088"],
  },
  {
    primary_id: "D01NSO",
    names: ["ISIS 103616"],
  },
  {
    primary_id: "D01NST",
    names: ["Monofluorine Derivative 1"],
  },
  {
    primary_id: "D01NTH",
    names: ["Ruthenium-Based Complexes"],
  },
  {
    primary_id: "D01NTI",
    names: ["N*2*-Benzyl-N*6*-Cyclohexyl-9H-Purine-2,6-Diamine", "CHEMBL190147", "BDBM50170836"],
  },
  {
    primary_id: "D01NTM",
    names: ["VER-5593"],
  },
  {
    primary_id: "D01NTN",
    names: ["3-(3,5-Dibromo-4-Hexyloxy-Phenyl)-Propionic Acid"],
  },
  {
    primary_id: "D01NTO",
    names: [
      "Meningitis B",
      "Cuban Neisseria Meningitidis Serogroup B OMP Vaccine",
      "Meningitis B (Cuba Vaccine)",
      "Meningitis B (Cuba);VA-MENGOC-BC",
      "Meningitis B (Cuba Vaccine), GlaxoSmithKline/Finlay Institute",
      "Vaccine (Meningitis B/C), Finlay Institute/SmithKline Beecham",
    ],
  },
  {
    primary_id: "D01NTQ",
    names: ["SAR127963"],
  },
  {
    primary_id: "D01NTR",
    names: ["NTF-1836"],
  },
  {
    primary_id: "D01NTW",
    names: ["2-Cyano-3-(3,4-Dihydroxyphenyl)Acrylamide"],
  },
  {
    primary_id: "D01NTX",
    names: ["Mifamurtide"],
  },
  {
    primary_id: "D01NTY",
    names: ["8-Iodo-Guanine"],
  },
  {
    primary_id: "D01NUG",
    names: ["GSK870086"],
  },
  {
    primary_id: "D01NUR",
    names: ["ASTX660"],
  },
  {
    primary_id: "D01NUT",
    names: ["Mulberrofuran D"],
  },
  {
    primary_id: "D01NVD",
    names: ["GSK-1564023A", "CHEMBL1093774", "SCHEMBL17963371", "GSK1564023A"],
  },
  {
    primary_id: "D01NVL",
    names: [
      "9-Chloro-5H-6-Thia-4,5-Diaza-Chrysene 6,6-Dioxide",
      "CHEMBL429599",
      "SCHEMBL11973725",
      "JVPPTVNJBXFSPQ-UHFFFAOYSA-N",
      "BDBM50372498",
      "NCGC00163054-01",
      "CU-00000000357-1",
      "2-(8-Amino*-7-Quinolinyl)-4-Chlorobenzenesulfonic Acid Sultam",
    ],
  },
  {
    primary_id: "D01NVM",
    names: ["PMID27109571-Compound-25"],
  },
  {
    primary_id: "D01NWQ",
    names: ["N-(4-Bromo-2-Methylphenyl)-2-Naphthamide"],
  },
  {
    primary_id: "D01NWS",
    names: ["3-(Biphenyl-4-Yl)-1-(Oxazol-2-Yl)Propan-1-One", "CHEMBL261172"],
  },
  {
    primary_id: "D01NWU",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 5"],
  },
  {
    primary_id: "D01NWV",
    names: ["Granexin Gel", "AlphaCT1"],
  },
  {
    primary_id: "D01NXF",
    names: ["Mydicar"],
  },
  {
    primary_id: "D01NXX",
    names: ["SAR-126119"],
  },
  {
    primary_id: "D01NYQ",
    names: ["Drug 2696274", "2696274"],
  },
  {
    primary_id: "D01NZH",
    names: ["ABBV-323"],
  },
  {
    primary_id: "D01NZS",
    names: ["2-(6-Imidazol-1-Yl-Hexyl)-Isoindole-1,3-Dione", "CHEMBL167372"],
  },
  {
    primary_id: "D01NZT",
    names: ["Prohibitin-TP-01"],
  },
  {
    primary_id: "D01OAG",
    names: ["13-Cis-Retinal", "13-Cis-Retinaldehyde"],
  },
  {
    primary_id: "D01OBB",
    names: ["Peptide Analog 40"],
  },
  {
    primary_id: "D01OBJ",
    names: [
      "5-Bromo-6-Methoxy-9H-Beta-Carboline",
      "5-Bromo-6-Methoxy-Beta-Carboline",
      "CHEMBL310975",
      "SCHEMBL6465779",
      "BDBM50130233",
    ],
  },
  {
    primary_id: "D01OBM",
    names: ["MEDl-559"],
  },
  {
    primary_id: "D01OCR",
    names: ["3-(4-Methoxy-Benzenesulfonyl)-Pentane-1-Thiol", "CHEMBL368972", "SCHEMBL7207764"],
  },
  {
    primary_id: "D01OCZ",
    names: ["16-Isobutylidene-Estrone"],
  },
  {
    primary_id: "D01ODC",
    names: ["TA-106"],
  },
  {
    primary_id: "D01ODR",
    names: ["AG 1024"],
  },
  {
    primary_id: "D01ODT",
    names: ["Sophorolipid Isoforms"],
  },
  {
    primary_id: "D01ODU",
    names: [
      "BAY-35-3377",
      "BY-377",
      "Gastroquinolone Antibacterial, Altana",
      "Gastroquinolone Antibacterial, Bayer",
      "Quinolone Antibacterials, Bayer",
    ],
  },
  {
    primary_id: "D01OEF",
    names: ["Pyridine Derivative 2"],
  },
  {
    primary_id: "D01OFD",
    names: [
      "ACTINOPLANIC ACID A",
      "CHEMBL100684",
      "SCHEMBL8909241",
      "BDBM50059873",
      "3-Carboxy-Pentanedioic Acid Mono-{(E)-10-[(14E,18Z)-4-Carboxy-19-(6-Carboxy-4-Ethyl-3-Oxo-Heptyl)-12-Hydroxy-11,13,15,17-Tetramethyl-2,6-Dioxo-1,7dioxa-Cycloicosa-14,18-Dien-8-Yl]-1,4-Dimethyl-Dec-5-Enyl} Ester",
    ],
  },
  {
    primary_id: "D01OFH",
    names: [
      "METHYLHONOKIOL",
      "4-O-Methylhonokiol",
      "68592-15-4",
      "4-O-Methyl Honokiol",
      "4-Methoxyhonokiol",
      "HONOKIOL MONO-METHYL ETHER",
      "CHEMBL89700",
      "(1,1'-Biphenyl)-2-Ol, 4'-Methoxy-3',5-Di-2-Propenyl-",
      "[1,1'-Biphenyl]-2-Ol, 4'-Methoxy-3',5-Di-2-Propenyl-",
      "Magreth-14a",
      "NSC293101",
      "4'-O-Methylhonokiol",
      "AC1L4DQF",
      "4'-O-Methylhonokiol, MH",
      "SCHEMBL12391677",
      "CTK5C8246",
      "DTXSID50218693",
      "ZINC1566147",
      "HY-U00450",
      "BDBM50295926",
      "AKOS028111321",
      "NSC-293101",
      "CS-0035406",
      "5,3''-Diallyl-4''-Methoxy-Biphenyl-2-Ol",
      "3,5'-Diallyl-2'-Hydroxy-4-Methoxybiphenyl",
    ],
  },
  {
    primary_id: "D01OFY",
    names: ["LAS-31180"],
  },
  {
    primary_id: "D01OGL",
    names: ["Technetium Tc-99m Red Blood Cell Kit"],
  },
  {
    primary_id: "D01OGR",
    names: ["Secondary And Tertiary (Hetero)Arylamide Derivative 2"],
  },
  {
    primary_id: "D01OGV",
    names: ["POL-6014"],
  },
  {
    primary_id: "D01OGZ",
    names: ["TEFLUDAZINE"],
  },
  {
    primary_id: "D01OHD",
    names: [
      "AMXT-1501",
      "Difluoromethylornithine + Polyamine Transport Inhibitors (Head And Neck Cancer), Aminex Therapeutics",
      "Polyamine Transport Inhibitors + Polyamine Biosynthesis Inhibitor (Head And Neck Cancer), Aminex Therapeutics/MediQuest Therapeutics",
      "MQT-1426 + DFMO (Head And Neck Cancer), Aminex Therapeutics/MediQuest Therapeutics",
      "Polyamine-Based Therapy (Head And Neck-Squamous Cell Carcinoma), Aminex Therapeutics/MediQuest Therapeutics",
    ],
  },
  {
    primary_id: "D01OHV",
    names: ["8-Azaguanine"],
  },
  {
    primary_id: "D01OIJ",
    names: ["PMID25259874C21"],
  },
  {
    primary_id: "D01OIK",
    names: ["Xamoterol"],
  },
  {
    primary_id: "D01OIL",
    names: ["Gadobenate Dimeglumine"],
  },
  {
    primary_id: "D01OIP",
    names: [
      "Antibiotic Program, Gram Negative Bacterial Infections",
      "Antibiotic Program (Gram Negative Bacterial Infections)",
    ],
  },
  {
    primary_id: "D01OIQ",
    names: ["PMID28870136-Compound-45"],
  },
  {
    primary_id: "D01OIR",
    names: ["AYX-1"],
  },
  {
    primary_id: "D01OIV",
    names: ["T-0509"],
  },
  {
    primary_id: "D01OJG",
    names: ["3-Phenyl-4-(Phenylamino)-1H-Pyrrole-2,5-Dione"],
  },
  {
    primary_id: "D01OKF",
    names: ["4-(Bis(2-Chloroethyl)Amino)-N-P-Tolylbenzamide", "CHEMBL601040"],
  },
  {
    primary_id: "D01OKS",
    names: ["Axokine"],
  },
  {
    primary_id: "D01OLO",
    names: [
      "AS602868",
      "Angelicin",
      "ISOPSORALEN",
      "523-50-2",
      "2H-Furo[2,3-H]chromen-2-One",
      "Angecin",
      "Furo[2,3-H]chromen-2-One",
      "Isopsoralin",
      "Furo(2,3-H)Coumarin",
      "Angelecin",
      "Angelicin (Coumarin Derivative)",
      "2H-Furo[2,3-H]-1-Benzopyran-2-One",
      "2-Oxo-(2H)-Furo(2,3-H)-1-Benzopyran",
      "UNII-CZZ080D7BD",
      "Angelicin (Coumarin Deriv)",
      "NSC 404563",
      "Furo(5',4':7,8)Coumarin",
      "CCRIS 4276",
      "HSDB 3554",
      "4-Hydroxy-5-Benzofuranacrylic Acid Gamma-Lactone",
      "BRN 0153970",
      "CZZ080D7BD",
      "2H-Furo(2,3-H)-1-Benzopyran-2-One",
      "CHEMBL53569",
      "Furo[5',4':7,8]coumarin",
      "Isopsoralen",
    ],
  },
  {
    primary_id: "D01OMG",
    names: ["NS9283"],
  },
  {
    primary_id: "D01OML",
    names: ["IoGen"],
  },
  {
    primary_id: "D01OMM",
    names: ["DeltaFLU-LAIV"],
  },
  {
    primary_id: "D01OMO",
    names: [
      "AdHSV-2",
      "Ad-5 Vaccine (HSV-2 Infection)",
      "Ad-5 Vaccine (HSV-2 Infection), GenVec",
      "Adenovector-Based Vaccine (HSV-2 Infection), GenVec",
      "Adenovector-Based Vaccine (Herpes Simplex Virus-2 Infection), GenVec",
    ],
  },
  {
    primary_id: "D01OMY",
    names: ["BHT-3034"],
  },
  {
    primary_id: "D01OND",
    names: [
      "3-(Dimethylamino)Phenyl Phenylcarbamate",
      "CHEMBL1171854",
      "AC1L2FAU",
      "AC1Q61FO",
      "YNGSTYODGGYXSA-UHFFFAOYSA-N",
      "BDBM50321912",
      "AKOS029953756",
      "[3-(Dimethylamino)Phenyl] N-Phenylcarbamate",
      "Phenol, 3-(Dimethylamino)-, Phenylcarbamate (Ester)",
    ],
  },
  {
    primary_id: "D01OOI",
    names: ["ISIS 114399"],
  },
  {
    primary_id: "D01OOO",
    names: ["Peptide Analog 41"],
  },
  {
    primary_id: "D01OOQ",
    names: ["FP-253-GDEPT"],
  },
  {
    primary_id: "D01OPV",
    names: ["Selenomethionine Se-75"],
  },
  {
    primary_id: "D01OQQ",
    names: ["PMID30107136-Compound-Example50"],
  },
  {
    primary_id: "D01OQW",
    names: ["ALBANOL A"],
  },
  {
    primary_id: "D01ORL",
    names: ["2-(4-Benzyl-Piperidin-1-Ylmethyl)-1H-Indol-6-Ol", "CHEMBL220775"],
  },
  {
    primary_id: "D01ORM",
    names: ["Mitonafide"],
  },
  {
    primary_id: "D01ORS",
    names: ["Moxidectin"],
  },
  {
    primary_id: "D01OSN",
    names: ["Riferminogene Pecaplasmid"],
  },
  {
    primary_id: "D01OTW",
    names: ["4-(Cyanomethylthio)Pyridine-3-Sulfonamide", "CHEMBL1164420", "1078160-09-4"],
  },
  {
    primary_id: "D01OUA",
    names: ["TJN-598"],
  },
  {
    primary_id: "D01OUE",
    names: [
      "Betazole",
      "Ametazole",
      "Betazol",
      "Betazolo",
      "Betazolum",
      "Betazole [INN]",
      "Betazolo [DCIT]",
      "Lilly 96791",
      "Betazol [INN-Spanish]",
      "Betazolum [INN-Latin]",
      "1H-Pyrazole-3-Ethanamine",
      "2-(1H-Pyrazol-3-Yl)Ethanamine",
      "2-(1H-Pyrazol-5-Yl)Ethanamine",
      "2-(3-Pyrazolyl)Ethylamine",
      "3-(2-Aminoethyl)Pyrazole",
      "3-(Beta-Aminoethyl)Pyrazole",
    ],
  },
  {
    primary_id: "D01OUJ",
    names: ["D-Val-L-BoroPro"],
  },
  {
    primary_id: "D01OVV",
    names: ["OVI-117"],
  },
  {
    primary_id: "D01OWB",
    names: ["Immune Globulin High Dose"],
  },
  {
    primary_id: "D01OWJ",
    names: ["TG-1024", "Ad-IL-2, Transgene", "Adenoviral-IL-2, Transgene"],
  },
  {
    primary_id: "D01OXH",
    names: ["ASP-7035"],
  },
  {
    primary_id: "D01OXI",
    names: [
      "DTI-015",
      "Carmustine",
      "154-93-8",
      "1,3-Bis(2-Chloroethyl)-1-Nitrosourea",
      "BCNU",
      "Carmustin",
      "Nitrumon",
      "Carmubris",
      "Gliadel",
      "BiCNU",
      "Bi CNU",
      "Carmustinum",
      "Bischlorethylnitrosurea",
      "Bischlorethylnitrosourea",
      "Carmustina",
      "Becenun",
      "Becenum",
      "Bischloroethyl Nitrosourea",
      "N,N'-BIS(2-CHLOROETHYL)-N-NITROSOUREA",
      "Bis(2-Chloroethyl)Nitrosourea",
      "Urea, N,N'-Bis(2-Chloroethyl)-N-Nitroso-",
      "Gliadel Wafer",
      "FDA 0345",
      "Bischloroethylnitrosourea",
      "SRI 1720",
      "1,3-Bis(2-Chloroethyl)Nitrosourea",
      "BiCNU (TN)",
      "Carmustinum [INN-Latin]",
      "Carmustina [INN-Spanish]",
      "DTI 015",
      "NCI-C04773",
      "SK",
      "Injectable Carmustine, Direct Therapeutics",
    ],
  },
  {
    primary_id: "D01OXV",
    names: ["GNE-9605"],
  },
  {
    primary_id: "D01OYC",
    names: ["8-Sec-Butoxy-Quinolin-2-Ylamine", "CHEMBL186818", "SCHEMBL5883697"],
  },
  {
    primary_id: "D01OYE",
    names: ["PMID27336223-Compound-13"],
  },
  {
    primary_id: "D01OYG",
    names: ["ZK-136798", "176175-63-6"],
  },
  {
    primary_id: "D01OYR",
    names: ["AZD-9343"],
  },
  {
    primary_id: "D01OYS",
    names: ["Cyclolignan Picropodophyllin"],
  },
  {
    primary_id: "D01OYY",
    names: ["PMID25656651-Compound-16a"],
  },
  {
    primary_id: "D01OZF",
    names: [
      "1-(4-(3-Morpholinopropoxy)Phenyl)-3-Phenylurea",
      "CHEMBL561382",
      "SCHEMBL4256144",
      "HTAZKQMMJVVVSK-UHFFFAOYSA-N",
      "BDBM50296222",
    ],
  },
  {
    primary_id: "D01OZL",
    names: ["8-(2-Methyl-Butoxy)-Quinolin-2-Ylamine", "CHEMBL362720", "SCHEMBL5884531"],
  },
  {
    primary_id: "D01OZS",
    names: [
      "3,3-Diisopropyl-Dihydro-Furan-2-One",
      "CHEMBL34879",
      "2(3H)-Furanone, Dihydro-3,3-Bis(1-Methylethyl)-",
      "SCHEMBL10358026",
      "132462-12-5",
    ],
  },
  {
    primary_id: "D01PAC",
    names: ["[125I]L703,606", "[125I]L703606", "[125I]-L703,606"],
  },
  {
    primary_id: "D01PAK",
    names: ["Ubiquitin-Specific Protease-8 Inhibitors"],
  },
  {
    primary_id: "D01PAP",
    names: ["PMID26609882-Compound-83"],
  },
  {
    primary_id: "D01PAR",
    names: ["Vitamin"],
  },
  {
    primary_id: "D01PBO",
    names: ["(+)-3,3'-Bisdemethyltanegool", "CHEMBL436990", "(+)-3,3''-Bisdemethyltanegool"],
  },
  {
    primary_id: "D01PBW",
    names: ["CB-839"],
  },
  {
    primary_id: "D01PBX",
    names: ["Biphenyl Mannoside Derivative 6"],
  },
  {
    primary_id: "D01PCB",
    names: [
      "VALERGEN-DS (Subcutaneous)",
      "Dermatophagoides Siboney Allergen Immunotherapy (Subcutaneous, Asthma), National Biopreparations Center",
    ],
  },
  {
    primary_id: "D01PCE",
    names: ["BCH-2687"],
  },
  {
    primary_id: "D01PCI",
    names: ["PMID25399762-Compound-Table1-C19"],
  },
  {
    primary_id: "D01PEC",
    names: [
      "(2R)-N-(7'-(2-CHLOROPHENYL)-6'-(4-CHLOROPHENYL)-3',4'-DIHYDROSPIRO[CYCLOHEXANE-1,2'-PYRANO[2,3-B]PYRIDINE]-4'-YL)-2-HYDROXYPROPANAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1083619",
      "SCHEMBL1404711",
      "BDBM50320187",
      "(2R)-N-(7''-(2-CHLOROPHENYL)-6''-(4-CHLOROPHENYL)-3'',4''-DIHYDROSPIRO[CYCLOHEXANE-1,2''-PYRANO[2,3-B]PYRIDINE]-4''-YL)-2-HYDROXYPROPANAMIDE (ENANTIOMERIC MIX)",
    ],
  },
  {
    primary_id: "D01PEF",
    names: ["MERIOLIN 8"],
  },
  {
    primary_id: "D01PES",
    names: [
      "N-(1H-Indol-2-Ylmethyl)-N-Phenylamine",
      "CHEMBL447043",
      "(Phenylamino)Methylindole",
      "SCHEMBL10549005",
      "MolPort-013-783-264",
      "BDBM50273565",
      "ZINC40829296",
    ],
  },
  {
    primary_id: "D01PFN",
    names: ["8-Cyclobutylmethoxy-Quinolin-2-Ylamine", "CHEMBL184980", "SCHEMBL5883878"],
  },
  {
    primary_id: "D01PFX",
    names: ["TDI-0065"],
  },
  {
    primary_id: "D01PFZ",
    names: ["Pyridopyrimidinone Derivative 2"],
  },
  {
    primary_id: "D01PGJ",
    names: ["PDM-08"],
  },
  {
    primary_id: "D01PGO",
    names: [
      "DM-PPP",
      "CHEMBL232052",
      "GTPL1383",
      "BDBM50204428",
      "D-255",
      "O4-(3,3-Dimethylbutan-2-Yl) O2-Propyl 3,5-Dimethyl-1H-Pyrrole-2,4-Dicarboxylate",
      "(S)-4-(3,3-Dimethylbutan-2-Yl) 2-Propyl 3,5-Dimethyl-1H-Pyrrole-2,4-Dicarboxylate",
      "4-O-[(2S)-3,3-Dimethylbutan-2-Yl] 2-O-Propyl 3,5-Dimethyl-1H-Pyrrole-2,4-Dicarboxylate.",
    ],
  },
  {
    primary_id: "D01PGR",
    names: ["SAR245409"],
  },
  {
    primary_id: "D01PGU",
    names: [
      "15-Deoxy-Delta(12, 14)-Prostaglandin J(2)",
      "15d-PGJ2",
      "15-Deoxy-PGJ2",
      "15-Deoxy-Delta-12,14-Prostaglandin J2",
      "15-Deoxy-Delta12,14-Prostaglandin J2",
      "Delta12,14-PGJ 2",
      "UNII-ALI977775J",
      "87893-55-8",
      "CHEMBL482477",
      "CHEBI:34159",
      "(5Z,12E,14E)-11-Oxoprosta-5,9,12,14-Tetraen-1-Oic Acid",
      "ALI977775J",
      "Delta-12,14-15-Deoxy-PGJ2",
      "15-Deoxy-Delta12,14-Prostaglandin",
      "15-Deoxy-Delta(12,14)-Prostaglandin J2",
      "Prostaglandin J2, 15-Deoxy-Delta12,14",
      "15-Deoxy-Delta 12, 14-Prostaglandin J2",
    ],
  },
  {
    primary_id: "D01PGY",
    names: ["2-(Hex-1-Ynyl)-N6-Methoxyadenosine", "CHEMBL220889", "SCHEMBL4384408"],
  },
  {
    primary_id: "D01PHK",
    names: ["PCL-016"],
  },
  {
    primary_id: "D01PIL",
    names: ["Remoxipride"],
  },
  {
    primary_id: "D01PIU",
    names: [
      "5-Chloro-3-Ethyl-1-(4-Fluoro-Phenyl)-1H-Indole",
      "CHEMBL300907",
      "181116-12-1",
      "CTK0A6482",
      "DTXSID30658199",
      "BDBM50127987",
      "ZINC13489982",
      "AKOS030619591",
      "5-Chloro-3-Ethyl-1-(4-Fluorophenyl)-1H-Indole",
      "1H-Indole, 5-Chloro-3-Ethyl-1-(4-Fluorophenyl)-",
    ],
  },
  {
    primary_id: "D01PJR",
    names: ["Tocainide"],
  },
  {
    primary_id: "D01PJZ",
    names: ["Cyclic Peptidomimetic Derivative 1"],
  },
  {
    primary_id: "D01PKC",
    names: ["PMID18178086C15"],
  },
  {
    primary_id: "D01PKG",
    names: ["Fenofibrate/ Simvastatin"],
  },
  {
    primary_id: "D01PKX",
    names: ["ISIS 191731"],
  },
  {
    primary_id: "D01PKZ",
    names: ["PMID28454500-Compound-36"],
  },
  {
    primary_id: "D01PLH",
    names: ["7U85", "BW-A7U"],
  },
  {
    primary_id: "D01PLJ",
    names: ["PMID25666693-Compound-58"],
  },
  {
    primary_id: "D01PLN",
    names: [
      "Cefalotin",
      "Cefalothin",
      "Cefalotina",
      "Cefalotine",
      "Cefalotinum",
      "Cemastin",
      "Cephalothinum",
      "Cephalotin",
      "Coaxin",
      "Cefalotina Fabra",
      "Cephalothin Monosodium Salt",
      "Averon-1",
      "Cefalotin (BAN)",
      "Cefalotina [INN-Spanish]",
      "Cefalotina Fabra (TN)",
      "Cefalotine [INN-French]",
      "Cefalotinum [INN-Latin]",
      "Keflin (TN)",
      "(6R,7R)-3-(Acetyloxymethyl)-8-Oxo-7-[(2-Thiophen-2-Ylacetyl)Amino]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-[(Acetyloxy)Methyl]-8-Oxo-7-[(2-Thienylacetyl)Amino]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "3-(Acetoxymethyl)-8-Oxo-7-(2-(2-Thienyl)Acetamido)-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "3-(Hydroxymethyl)-8-Oxo-7-(2-(2-Thienyl)Acetamido)-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid Acetate",
      "3-ACETOXYMETHYL-8-OXO-7-(2-THIOPHEN-2-YL-ACETYLAMINO)-5-THIA-1-AZA-BICYCLO[4.2.0]OCT-2-ENE-2-CARBOXYLIC ACID",
      "3-Acetoxymethyl-7-(2-Thienylacetamido)-3-Cephem-4-Carboxylic Acid",
      "5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 3-(Hydroxymethyl)-8-Oxo-7-(2-(2-Thienyl)Acetamido)-, Acetate (Ester)",
      "6R-Trans-3-((Acetyloxy)Methyl)-8-Oxo-7-((2-Thienylacetyl)Amino)-5-Thia-1-Azabicyclo(4.2.0)-Oct-2-Ene-2-Carboxylic Acid",
      "7-(2-(2-Thienyl)Acetylamido)Cephalosporanic Acid",
      "7-(2-Thienylacetamido)Cephalosporanic Acid",
      "7-(Thiophene-2-Acetamido)Cephalosporanic Acid",
      "7-(Thiophene-2-Acetamido)Cephalosporin",
      "7beta-(Thiophen-2-Ylacetamido)-3-Acetoxymethyl-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D01PLP",
    names: [
      "3,7-DIHYDROXYNAPHTHALENE-2-CARBOXYLIC ACID",
      "3,7-DIHYDROXY-2-NAPHTHOIC ACID",
      "83511-07-3",
      "2,6-Dihydroxy-3-Naphthoic Acid",
      "3,7-Dihydroxy-2-Naphthalenecarboxylic Acid",
      "CHEMBL1231350",
      "2,6-DIOXYNAPHTHALENE-3-CARBOXYLIC ACID",
      "PubChem9419",
      "ACMC-209psi",
      "AC1LEHT6",
      "AC1Q5UP9",
      "AC1Q72HK",
      "SCHEMBL124773",
      "3,7-Dihydroxy-2-Naphtoic Acid",
      "KS-00000GHV",
      "CTK3E8003",
      "DTXSID30352988",
      "QMWOUSYSNFCKAZ-UHFFFAOYSA-N",
      "MolPort-001-790-958",
      "ZINC156898",
      "STL426758",
      "BDBM50379183",
      "BBL034659",
      "ANW-37696",
      "SBB037881",
      "AKOS015856275",
      "VZ22995",
      "MCULE-9133006477",
      "CD-1291",
    ],
  },
  {
    primary_id: "D01PLR",
    names: ["PF-04171327"],
  },
  {
    primary_id: "D01PNM",
    names: ["KURARINONE", "(-)-Kurarinone", "Kurarinone", "CHEMBL243796", "SCHEMBL10307523", "BDBM50208612"],
  },
  {
    primary_id: "D01PNR",
    names: ["Gastrin 17C Diphtheria Toxoid Conjugate"],
  },
  {
    primary_id: "D01PNV",
    names: ["Acetyl CoA Carboxylase Inhibitors", "Acetyl CoA Carboxylase Inhibitors, Torrent"],
  },
  {
    primary_id: "D01POG",
    names: ["GW-2331", "190844-95-2", "PPAR Ligand, Glaxo Wellcome"],
  },
  {
    primary_id: "D01PPQ",
    names: ["Podophyllum"],
  },
  {
    primary_id: "D01PPZ",
    names: ["Ridine-5-Carboxylic Acid Ethyl Ester"],
  },
  {
    primary_id: "D01PQM",
    names: ["Vaccinia Immune Globulin"],
  },
  {
    primary_id: "D01PRH",
    names: ["APX-009"],
  },
  {
    primary_id: "D01PSI",
    names: ["AM-3301"],
  },
  {
    primary_id: "D01PSK",
    names: ["PMID27841036-Compound-23"],
  },
  {
    primary_id: "D01PSS",
    names: [
      "Deoxyuridine-5'-Triphosphate",
      "DUTP",
      "Deoxyuridine Triphosphate",
      "Deoxy-UTP",
      "DEOXYURIDINE-5'-TRIPHOSPHATE",
      "2'-Deoxyuridine 5'-Triphosphate",
      "Deoxyuridine 5'-Triphosphate",
      "2'-Deoxy-UTP",
      "2'-Deoxyuracil 5'-Triphosphate",
      "1173-82-6",
      "102814-08-4",
      "CHEMBL374361",
      "CHEBI:17625",
      "N(4)-Methoxydeoxycytidine Triphosphate",
      "2'-Deoxyuridine 5'-(Tetrahydrogen Triphosphate)",
      "Uridine 5'-(Tetrahydrogen Triphosphate), 2'-Deoxy-",
      "2'-Deoxyuridine-5'-Triphosphate Trisodium Salt",
      "[[(2R,3S,5R)-5-(2,4-Dioxopyrimidin-1-Yl)-3-Hydroxy-Tetrahydrofuran-2-Yl]methoxy-Hydroxy-Phosphoryl] Phosp",
    ],
  },
  {
    primary_id: "D01PSU",
    names: ["(2'Z,3'E)-5-Chloro-5'-Chloro-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D01PSY",
    names: [
      "SIG-005",
      "Anti-Inflammatory G-Protein Modulators (Parkinson's Disease)",
      "Anti-Inflammatory G-Protein Modulators (Parkinson's Disease), Signum",
      "Anti-Inflammatory G-Protein Modulators (Parkinson's Disease), Florida A & M University",
    ],
  },
  {
    primary_id: "D01PTE",
    names: [
      "2-Benzyloxy-7,8-Dihydro-6H-Quinolin-5-One",
      "CHEMBL400104",
      "SCHEMBL4275594",
      "2-(BENZYLOXY)-7,8-DIHYDROQUINOLIN-5(6H)-ONE",
      "VGMBEUFDLPGOMH-UHFFFAOYSA-N",
      "BDBM50231740",
      "ZINC28967402",
      "AB68928",
      "143232-64-8",
      "2-Benzyloxy-7,8-Dihydroquinolin-5(6H)-One",
      "5,6,7,8-Tetrahydro-2-(Phenylmethoxy)-5-Oxoquinoline",
      "5,6,7,8-Tetrahydro-5-Oxo-2-(Phenylmethoxy)Quinoline",
    ],
  },
  {
    primary_id: "D01PTS",
    names: ["VG-101"],
  },
  {
    primary_id: "D01PUE",
    names: ["O-GlcNAcase (OGA)-Targeting Agents"],
  },
  {
    primary_id: "D01PUS",
    names: ["PMID27215781-Compound-32"],
  },
  {
    primary_id: "D01PVV",
    names: ["KD3010"],
  },
  {
    primary_id: "D01PWK",
    names: ["RPR-104632"],
  },
  {
    primary_id: "D01PWM",
    names: ["Kuwanon H"],
  },
  {
    primary_id: "D01PWO",
    names: ["4-(2-Nitrobenzylthio)-2-Aminobutanoic Acid", "CHEMBL380506", "BDBM50179699"],
  },
  {
    primary_id: "D01PXH",
    names: ["NAM-NK Cells + IL-2"],
  },
  {
    primary_id: "D01PXI",
    names: ["Indole-Based Analog 10"],
  },
  {
    primary_id: "D01PYH",
    names: ["PANFLUVAC"],
  },
  {
    primary_id: "D01PYM",
    names: ["PMID27977313-Compound-48"],
  },
  {
    primary_id: "D01PYO",
    names: ["Benzimidazole Carboxamide Derivative 1"],
  },
  {
    primary_id: "D01PYU",
    names: ["3-(1-Phenethyl-Piperidin-3-Yl)-2-Phenyl-1H-Indole", "CHEMBL44310", "SCHEMBL8525084", "BDBM50099267"],
  },
  {
    primary_id: "D01PZD",
    names: ["Romiplostim"],
  },
  {
    primary_id: "D01PZJ",
    names: ["BDNA-002"],
  },
  {
    primary_id: "D01PZP",
    names: ["Hydroxycitronellal"],
  },
  {
    primary_id: "D01PZU",
    names: ["N-Ethyl-5,6-Dip-Tolylpyrazine-2-Carboxamide", "CHEMBL246476"],
  },
  {
    primary_id: "D01PZZ",
    names: ["PMID28394193-Compound-56"],
  },
  {
    primary_id: "D01QAR",
    names: ["LY-221068"],
  },
  {
    primary_id: "D01QBK",
    names: ["8-Bromo-9-Cyclohexyl-9H-Adenine", "CHEMBL516238", "BDBM50256952"],
  },
  {
    primary_id: "D01QCJ",
    names: ["4,4'-(4-Phenylpyridine-2,6-Diyl)Diphenol"],
  },
  {
    primary_id: "D01QCY",
    names: ["OBP-AI1"],
  },
  {
    primary_id: "D01QDB",
    names: ["SAM486"],
  },
  {
    primary_id: "D01QDG",
    names: ["4-Hydroxy-5-Methoxy-10H-Anthracen-9-One", "CHEMBL123148", "ZINC13606502", "BDBM50060871"],
  },
  {
    primary_id: "D01QGX",
    names: ["Lurasidone Hydrochloride"],
  },
  {
    primary_id: "D01QIA",
    names: ["BL-1872"],
  },
  {
    primary_id: "D01QIN",
    names: [
      "Atorvastatin",
      "Atogal",
      "Atorlip",
      "Atorpic",
      "Atorvastatina",
      "Atorvastatine",
      "Atorvastatinium",
      "Atrovastin",
      "Cardyl",
      "Faboxim",
      "Lipotropic",
      "Lipovastatinklonal",
      "Liprimar",
      "Lowden",
      "Sincol",
      "Sortis",
      "Sotis",
      "Torvacard",
      "Torvast",
      "Totalip",
      "Tozalip",
      "Tulip",
      "Vastina",
      "Xanator",
      "Xarator",
      "Xavator",
      "Zurinel",
      "Atorvastatin (INN)",
      "Atorvastatin [INN:BAN]",
      "Lipitor (TN)",
      "Lipitor(TM)",
      "Sortis (TN)",
      "(3R,5R)-7-[2-(4-Fluorophenyl)-3-Phenyl-4-(Phenylcarbamoyl)-5-Propan-2-Ylpyrrol-1-Yl]-3,5-Dihydroxyheptanoic Acid",
      "(3R,5R)-7-[3-(Anilinocarbonyl)-5-(4-Fluorophenyl)-2-Isopropyl-4-Phenyl-1H-Pyrrol-1-Yl]-3,5-Dihydroxyheptanoic Acid",
      "(R-(R*,R*))-2-(4-Fluorophenyl)-Beta,delta-Dihydroxy-5-(1-Methylethyl)-3-Phenyl-4-((Phenylamino)Carbonyl)-1H-Pyrrole-1-Heptanoic Acid",
      "(BetaR,deltaR)-2-(P-Fluorophenyl)-Beta,delta-Dihydroxy-5-Isopropyl-3-Phenyl-4-(Phenylcarbamoyl)Pyrrole-1-Heptanoic Acid",
      "7-[2-(4-FLUORO-PHENYL)-5-ISOPROPYL-3-PHENYL-4-PHENYLCARBAMOYL-PYRROL-1-YL]-3,5-DIHYDROXY-HEPTANOIC ACID",
    ],
  },
  {
    primary_id: "D01QIV",
    names: ["Prostaview"],
  },
  {
    primary_id: "D01QIX",
    names: ["Drug 2696275", "2696275"],
  },
  {
    primary_id: "D01QJT",
    names: ["Example 166  (WO2014154727)"],
  },
  {
    primary_id: "D01QJY",
    names: ["Alzheimer's Disease Therapeutic", "Alzheimer's Disease Therapeutic (Transdermal Formulation)"],
  },
  {
    primary_id: "D01QKD",
    names: ["ATL-1101"],
  },
  {
    primary_id: "D01QKK",
    names: ["NUTLIN-3"],
  },
  {
    primary_id: "D01QKR",
    names: ["ALNESPIRONE"],
  },
  {
    primary_id: "D01QKX",
    names: [
      "(5Z,9Z)-5,9-Heptacosadienoic Acid",
      "5Z,9Z-Heptacosadienoic Acid",
      "CHEMBL463437",
      "27:2(5Z,9Z)",
      "LMFA01020363",
    ],
  },
  {
    primary_id: "D01QKZ",
    names: ["ABT-413"],
  },
  {
    primary_id: "D01QLH",
    names: [
      "Amyl Nitrite",
      "Pentyl Nitrite",
      "N-Amyl Nitrite",
      "463-04-7",
      "Nitramyl",
      "N-Pentyl Nitrite",
      "Nitrous Acid, Pentyl Ester",
      "Pentyl Alcohol, Nitrite",
      "UNII-H2HUX79FYK",
      "CCRIS 763",
      "EINECS 207-332-7",
      "H2HUX79FYK",
      "BRN 1701241",
      "AI3-25184",
      "CHEBI:55344",
      "CSDTZUBPSYWZDX-UHFFFAOYSA-N",
      "Pentylnitrite",
      "N-Pentylnitrite",
      "Amyl 1 Nitrite",
      "N-Phenyl Nitrile",
      "ACMC-1ACPW",
      "Nitrous Acid Pentyl Ester",
      "Nitrousacid, Pentyl Ester",
      "DSSTox_CID_4522",
      "UN 1113 (Related)",
      "Pentyl Nitrite, AldrichCPR",
      "AC1L1UC5",
      "DSSTox_RID_77442",
      "SCHEMBL34065",
      "DSSTox_GSID_24522",
      "KSC492M8F",
      "4-01-00-01644",
    ],
  },
  {
    primary_id: "D01QLM",
    names: ["AAB-002"],
  },
  {
    primary_id: "D01QLP",
    names: ["Tarextumab"],
  },
  {
    primary_id: "D01QMC",
    names: ["LAI338"],
  },
  {
    primary_id: "D01QMK",
    names: ["Denintuzumab Mafodotin"],
  },
  {
    primary_id: "D01QML",
    names: [
      "Carphenazine",
      "Carfenazine",
      "Carphenazin",
      "Procethazine",
      "Proketazin",
      "Carfenazina [INN-Spanish]",
      "Carfenazine [INN:BAN]",
      "Carfenazinum [INN-Latin]",
      "1-(10-(3-(4-(2-Hydroxyethyl)-1-Piperazinyl)Propyl)Phenothiazin-2-Yl)-1-Propanone",
      "1-(10-{3-[4-(2-Hydroxyethyl)Piperazin-1-Yl]propyl}-10H-Phenothiazin-2-Yl)Propan-1-One",
      "1-[10-[3-[4-(2-Hydroxyethyl)Piperazin-1-Yl]propyl]phenothiazin-2-Yl]propan-1-One",
    ],
  },
  {
    primary_id: "D01QNF",
    names: ["ISIS 111117"],
  },
  {
    primary_id: "D01QNJ",
    names: ["KD-501"],
  },
  {
    primary_id: "D01QNO",
    names: ["LM-13765"],
  },
  {
    primary_id: "D01QNR",
    names: ["PIM447"],
  },
  {
    primary_id: "D01QNX",
    names: ["PMID29649907-Compound-15"],
  },
  {
    primary_id: "D01QOB",
    names: ["4-Butoxy-N-Hydroxycarbamoylmethyl-Benzamide", "CHEMBL371715"],
  },
  {
    primary_id: "D01QOU",
    names: ["ONO-1581"],
  },
  {
    primary_id: "D01QQC",
    names: ["Hematopoietic Stem Cell Gene Therapy"],
  },
  {
    primary_id: "D01QQD",
    names: ["PMID25259874C4"],
  },
  {
    primary_id: "D01QQM",
    names: ["LAMIFIBAN"],
  },
  {
    primary_id: "D01QRF",
    names: ["IKS-04"],
  },
  {
    primary_id: "D01QRG",
    names: ["RPR 200765A", "CHEMBL68211", "SCHEMBL5770288", "SCHEMBL13819552", "SCHEMBL20555598", "SCHEMBL18797648"],
  },
  {
    primary_id: "D01QRR",
    names: ["Isosorbide-2-Benzylcarbamate-5-Cyclopentanoate", "CHEMBL492635"],
  },
  {
    primary_id: "D01QSG",
    names: ["VTP-27999"],
  },
  {
    primary_id: "D01QSO",
    names: ["Buparlisib", "BKM120"],
  },
  {
    primary_id: "D01QSU",
    names: ["Leualacin", "128140-12-5"],
  },
  {
    primary_id: "D01QTD",
    names: ["GT-038", "AAV Channel Rhodopsin Gene Therapy (Retinitis Pigmentosa), Genable Technologies"],
  },
  {
    primary_id: "D01QTJ",
    names: ["XEN901"],
  },
  {
    primary_id: "D01QUF",
    names: ["GW-3600"],
  },
  {
    primary_id: "D01QUS",
    names: [
      "Calcipotriol",
      "Calcipotriene",
      "Divonex",
      "Dovonex",
      "BMS-181161",
      "Daivonex (TN)",
      "Dovonex (TN)",
      "MC-903",
      "(1R,3S,5Z)-5-[(2E)-2-[(1R,3aS,7aR)-1-[(E,2R,5S)-5-Cyclopropyl-5-Hydroxypent-3-En-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylidenecyclohexane-1,3-Diol",
      "(1S,5Z,7Z,17ALPHA,22E)-24-CYCLOPROPYL-9,10-SECOCHOLA-5,7,10,22-TETRAENE-1,3,24-TRIOL",
      "1-ALPHA,24S-(OH)2-22-ENE-26,27-DEHYDROVITAMIN D3",
    ],
  },
  {
    primary_id: "D01QWL",
    names: ["N-(3,4-Dihydroxybenzyl)Oleamide", "CHEMBL403034", "SCHEMBL10713028"],
  },
  {
    primary_id: "D01QWO",
    names: ["4-(4-Butoxyphenoxy)Phenol", "CHEMBL467374"],
  },
  {
    primary_id: "D01QWS",
    names: ["GM-CT-01"],
  },
  {
    primary_id: "D01QWT",
    names: ["Cyramzaramucirumab"],
  },
  {
    primary_id: "D01QXL",
    names: [
      "2-(6-Hydroxy-Naphthalen-1-Yl)-Benzooxazol-5-Ol",
      "CHEMBL183367",
      "SCHEMBL3933889",
      "GLVGKXBQPYTMGJ-UHFFFAOYSA-N",
      "ZINC3817709",
      "BDBM50154056",
      "2-(6-Hydroxynaphthalen-1-Yl)Benzo[d]oxazol-5-Ol",
    ],
  },
  {
    primary_id: "D01QXS",
    names: ["RX-0201N"],
  },
  {
    primary_id: "D01QXW",
    names: [
      "CSL-324",
      "Anti-GCSF Human MAb (Inflammation), CSL",
      "Anti-GCSF Human Monoclonal Antibody (Inflammation), CSL",
      "Soluble Human GCSFR Extracellular Domain-Fc (Inflammation), CSL",
      "Soluble Human GCSFR Extracellular Domain-Fc (Arthritis), MuriGen/CSL LTd",
      "Soluble Human Granulocyte-Colony Stimulating Factor Receptor Extracellular Domain-Fc (Arthritis), MuriGen/CSL LTd",
      "Soluble Human Granulocyte-Colony Stimulating Factor Receptor Extracellular Domain-Fc (Arthritis), MuriGen/Zenyth",
    ],
  },
  {
    primary_id: "D01QXY",
    names: ["B7-2/GM-CSF"],
  },
  {
    primary_id: "D01QYA",
    names: ["Diamine Derivative 1"],
  },
  {
    primary_id: "D01QYQ",
    names: ["ATL-844"],
  },
  {
    primary_id: "D01QZI",
    names: [
      "Cytochrome P450 3A Inhibitors",
      "Cytochrome P450 3A Inhibitors (HIV)",
      "Cytochrome P450 3A Inhibitors (HIV), Abbott",
    ],
  },
  {
    primary_id: "D01QZR",
    names: ["Vaccine, Japanese Encephalitis Virus"],
  },
  {
    primary_id: "D01RAD",
    names: [
      "Bay K 8644",
      "71145-03-4",
      "Methyl 2,6-Dimethyl-5-Nitro-4-[2-(Trifluoromethyl)Phenyl]-1,4-Dihydropyridine-3-Carboxylate",
      "MLS000028858",
      "3-Pyridinecarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-5-Nitro-4-(2-(Trifluoromethyl)Phenyl)-, Methyl Ester",
      "C16H15F3N2O4",
      "R(+)-BAY K 8644",
      "SMR000058424",
      "Methyl 2,6-Dimethyl-5-Nitro-4-(2-(Trifluoromethyl)Phenyl)-1,4-Dihydropyridine-3-Carboxylate",
    ],
  },
  {
    primary_id: "D01RAE",
    names: ["Sonepiprazole"],
  },
  {
    primary_id: "D01RAN",
    names: ["LA-419"],
  },
  {
    primary_id: "D01RBE",
    names: ["Pyrrolidine Carboxamide Derivative 2"],
  },
  {
    primary_id: "D01RBK",
    names: ["Nisin"],
  },
  {
    primary_id: "D01RBP",
    names: ["RE-024"],
  },
  {
    primary_id: "D01RBR",
    names: ["SGS742"],
  },
  {
    primary_id: "D01RBX",
    names: ["Targeted Gold Nanoparticle"],
  },
  {
    primary_id: "D01RCC",
    names: [
      "BMS-186318",
      "BMS 186318",
      "161302-40-5",
      "12-Oxa-2,6,10-Triazatetradecanoic Acid, 4,8-Dihydroxy-13,13-Dimethyl-3-((4-(2-(4-Morpholinyl)-2-Oxoethoxy)Phenyl)Methyl)-11-Oxo-9-(Phenylmethyl)-, 1,1-Dimethylethyl Ester, (3S,4R,8R,9S)-",
      "BMS-186318 Analog 19",
      "12-Oxa-2,6,10-Triazatetradecanoic Acid, 4,8-Dihydroxy-13,13-Dimethyl-3-[[4-[2-(4-Morpholinyl)-2-Oxoethoxy]phenyl]methyl]-11-Oxo-9-(Phenylmethyl)-, 1,1-Dimethylethyl Ester, (3S,4R,8R,9S)-",
      "Tert-Butyl N-[(1S,2R)-1-Benzyl-3-[[(2R,3S)-3-(Tert-Butoxycarbonylamino)-2-Hydroxy-4",
    ],
  },
  {
    primary_id: "D01RCM",
    names: ["TAK-114"],
  },
  {
    primary_id: "D01RCS",
    names: ["TIOSPIRONE"],
  },
  {
    primary_id: "D01RDB",
    names: ["N-[2-(1H-Benzoimidazol-2-Yl)-Benzoyl]-Guanidine", "CHEMBL233863"],
  },
  {
    primary_id: "D01RDR",
    names: ["AB569"],
  },
  {
    primary_id: "D01REE",
    names: ["DA-3031"],
  },
  {
    primary_id: "D01REI",
    names: ["4'-(Pyridin-4-Ylmethyl)Biphenyl-3,4-Diol", "CHEMBL1172779", "SCHEMBL18770369", "BDBM50322793"],
  },
  {
    primary_id: "D01REJ",
    names: ["VBY- 891"],
  },
  {
    primary_id: "D01RFF",
    names: ["Etoxazole"],
  },
  {
    primary_id: "D01RFG",
    names: [
      "8-Carboxamidocyclazocine",
      "CHEMBL58646",
      "8-CAC",
      "SCHEMBL12014004",
      "(6S,11R)-3-Cyclopropylmethyl-6,11-Dimethyl-1,2,3,4,5,6-Hexahydro-2,6-Methano-Benzo[d]azocine-8-Carboxylic Acid Amide",
      "PDSP1_000954",
      "PDSP2_000939",
    ],
  },
  {
    primary_id: "D01RFI",
    names: ["Ro-24-0238"],
  },
  {
    primary_id: "D01RFU",
    names: ["Artemisinin Trioxane Dimers", "Artemisinin Trioxane Dimers, Artemisia BioMedical"],
  },
  {
    primary_id: "D01RGG",
    names: ["N-(4-Tert-Butylthiazol-2-Yl)-1H-Indol-4-Amine", "CHEMBL207479", "SCHEMBL5966378"],
  },
  {
    primary_id: "D01RGM",
    names: ["S-Ethyl-N-[4-(Trifluoromethyl)Phenyl]Isothiourea"],
  },
  {
    primary_id: "D01RHK",
    names: ["PMID26651364-Compound-5d"],
  },
  {
    primary_id: "D01RHR",
    names: ["PMID24915291C31"],
  },
  {
    primary_id: "D01RIP",
    names: ["KNI-10265"],
  },
  {
    primary_id: "D01RIT",
    names: ["Pyrazole Derivative 20"],
  },
  {
    primary_id: "D01RJL",
    names: ["Cyclopropyl-Spiro Piperidine Derivative 4"],
  },
  {
    primary_id: "D01RJV",
    names: [
      "4-((2S,3R)-2-Methyl-Pyrrolidin-3-Yl)-1H-Imidazole",
      "CHEMBL18980",
      "BDBM50034707",
      "151093-64-0",
      "1H-Imidazole, 4-[(2S,3R)-2-Methyl-3-Pyrrolidinyl]-",
    ],
  },
  {
    primary_id: "D01RJY",
    names: [
      "7-Biphenyl-4-Yl-Heptanoic Acid Hydroxyamide",
      "CHEMBL125098",
      "BDBM50222335",
      "7-(4-Biphenylyl)Heptanehydroximic Acid",
    ],
  },
  {
    primary_id: "D01RKI",
    names: ["8-O-(4-Bromobenzenesulfonyl)Manzamine F", "CHEMBL414128"],
  },
  {
    primary_id: "D01RKQ",
    names: ["SDZ-280-446"],
  },
  {
    primary_id: "D01RKY",
    names: ["VAG-624", "Acne Treatment, Novartis"],
  },
  {
    primary_id: "D01RLA",
    names: [
      "CYC-4068",
      "CYC-4",
      "CYC-400 Series",
      "Cyclin Dependent Kinase Inhibitors (Cancer)",
      "Cyclin Dependent Kinase Inhibitors (Cancer), Cyclacel",
    ],
  },
  {
    primary_id: "D01RLF",
    names: ["9H-Beta-Carboline-3-Carboxylic Acid Ethyl Ester"],
  },
  {
    primary_id: "D01RLG",
    names: ["RTU-007"],
  },
  {
    primary_id: "D01RLS",
    names: ["Allostrome", "Bone Marrow-Derived Mesenchymal Stem Cell Therapy (Myocardial Infarction), Stematix"],
  },
  {
    primary_id: "D01RLU",
    names: ["EG-016"],
  },
  {
    primary_id: "D01RNA",
    names: [
      "Desmosterol",
      "DESMOSTEROL",
      "24-Dehydrocholesterol",
      "313-04-2",
      "UNII-ANP93865R8",
      "Cholest-5,24-Dien-3beta-Ol",
      "Cholesta-5,24-Dien-3beta-Ol",
      "NSC 226126",
      "CHEBI:17737",
      "3beta-Cholesta-5,24-Dien-3-Ol",
      "ANP93865R8",
      "(3S,8S,9S,10R,13R,14S,17R)-10,13-Dimethyl-17-[(2R)-6-Methylhept-5-En-2-Yl]-2,3,4,7,8,9,11,12,14,15,16,17-Dodecahydro-1H-Cyclopenta[a]phenanthren-3-Ol",
      "Cholesta-5,24-Dien-3-Ol",
      "Desmesterol",
      "EINECS 206-236-2",
      "24-Dehydro Cholesterol",
      "24,25-Dehydrocholesterol",
      "AC1L97ME",
      "Cholesta-5,24-Dien-3b-Ol",
      "5,24-Cholestadien-3beta-Ol",
      "5,24-Cholestadien-3",
    ],
  },
  {
    primary_id: "D01RNF",
    names: [
      "ZG-451",
      "Adenovirus-Based Suicide Gene Therapy (Cancer), ZGene",
      "Anticancer GDEPT (Oncolytic Recombinant Adenovirus, Gemcitabine), ZGene",
      "Deoxyribonucleoside Kinase-Expressing Adenovirus Gene Therapy (ZAS) + Gemcitabine (Cancer), ZGene",
    ],
  },
  {
    primary_id: "D01RNL",
    names: ["Lintuzumab"],
  },
  {
    primary_id: "D01RNV",
    names: ["AMT-011", "Alipogene Tiparvovec"],
  },
  {
    primary_id: "D01RPD",
    names: [
      "(E,E)-8-(4-Phenylbutadien-1-Yl)Caffeine",
      "CHEMBL483663",
      "SCHEMBL15647106",
      "BDBM50264585",
      "(E,e)-8-(4-Phenylbutadien-1-Yl) Caffeine",
    ],
  },
  {
    primary_id: "D01RPM",
    names: [
      "(E)-9-Nitrooctadec-9-Enoic Acid",
      "9-Nitro-9E-Octadecenoic Acid",
      "9-Nitro Oleic Acid",
      "9-Nitrooleic Acid",
      "Nitroleic Acid",
      "875685-44-2",
      "9-Nitrooleate",
      "CHEMBL550762",
      "(9E)-9-Nitrooctadecenoic Acid",
      "CHEBI:86329",
      "9-Nitro-9E-Octadecenoate",
      "(9E)-9-Nitrooctadecenoate",
      "9-Nitro-9-Octadecenoic Acid",
      "(E)-9-Nitrooctadec-9-Enoate",
      "GTPL6296",
      "SCHEMBL1015108",
      "MolPort-027-641-228",
      "CQOAKBVRRVHWKV-SAPNQHFASA-N",
      "(E)-9-Nitro-Octadec-9-Enoic Acid",
      "(9E)-9-Nitrooctadec-9-Enoic Acid",
      "(9E)-9-Nitro-9-Octadecenoic Acid",
      "BDBM50295048",
      "1573AH",
      "LMFA01120004",
      "Nitrooleic Acid",
    ],
  },
  {
    primary_id: "D01RQE",
    names: ["PMID25522065-Compound-34"],
  },
  {
    primary_id: "D01RQK",
    names: ["15-Deoxy-Delta12,14-PGJ2", "15d-Delta12,14-PGJ2", "LS-125843"],
  },
  {
    primary_id: "D01RQN",
    names: ["DS-1971"],
  },
  {
    primary_id: "D01RQP",
    names: ["IGN002"],
  },
  {
    primary_id: "D01RQV",
    names: [
      "Eculizumab",
      "Eculizumab (Intravitreal, AMD)",
      "Eculizumab (Intravitreal, AMD), Alexion",
      "Eculizumab (Intravitreal, Age-Related Macular Degeneration), Alexion",
    ],
  },
  {
    primary_id: "D01RRF",
    names: ["STA-5312", "2-[3-(4-Cyanobenzyl)Indolizin-1-Yl]-N-(3-Methylisothiazol-5-Yl)-2-Oxoacetamide"],
  },
  {
    primary_id: "D01RSH",
    names: [
      "2-(3-Bromobenzoylamino)-4-Chlorobenzoic Acid",
      "827325-60-0",
      "2-[(3-Bromobenzoyl)Amino]-4-Chlorobenzoic Acid",
      "CHEMBL225612",
      "2-(3-Bromobenzamido)-4-Chlorobenzoic Acid",
      "Benzoic Acid, 2-[(3-Bromobenzoyl)Amino]-4-Chloro-",
      "AC1N8QX1",
      "SCHEMBL14075271",
      "CTK3D6918",
      "KS-00003MRF",
      "DTXSID50401927",
      "MolPort-002-885-931",
      "ZINC4104976",
      "ZX-AN021268",
      "ALBB-022752",
      "BDBM50158557",
      "AKOS005107642",
      "MCULE-7998531013",
      "MS-0095",
      "R4909",
      "SR-01000309735",
      "SR-01000309735-1",
      "2-[(3-Bromobenzoyl)Amino]-4-Chlorobenzenecarboxylic Acid",
    ],
  },
  {
    primary_id: "D01RSK",
    names: [
      "CDP-146",
      "CT-8730",
      "MAPK Antagonists, Celltech",
      "NG-1054",
      "P38 MAP Kinase Inhibitor, Celltech",
      "P38 Kinase Inhibitor, Celltech",
    ],
  },
  {
    primary_id: "D01RSW",
    names: ["Orazamide Orotate"],
  },
  {
    primary_id: "D01RTO",
    names: ["Polyhydroxy Benzamide Derivative 4"],
  },
  {
    primary_id: "D01RTR",
    names: ["(R)-3-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Pyridine", "CHEMBL271226"],
  },
  {
    primary_id: "D01RTU",
    names: ["METHYL-PHE-PRO-AMINO-CYCLOHEXYLGLYCINE"],
  },
  {
    primary_id: "D01RUH",
    names: ["LYSICAMINE"],
  },
  {
    primary_id: "D01RUI",
    names: ["PMID30273516-Compound-13"],
  },
  {
    primary_id: "D01RUK",
    names: ["PMID25666693-Compound-104"],
  },
  {
    primary_id: "D01RUN",
    names: [
      "(R)-Rolipram",
      "(R)-(-)-Rolipram",
      "85416-75-7",
      "(R)-ROLIPRAM",
      "(4R)-4-[3-(CYCLOPENTYLOXY)-4-METHOXYPHENYL]PYRROLIDIN-2-ONE",
      "R-Rolipram",
      "UNII-DPX51KUP08",
      "DPX51KUP08",
      "CHEMBL430893",
      "CHEBI:40133",
      "1xmy",
      "NCGC00016899-01",
      "CAS-61413-54-5",
      "Tocris-1349",
      "Tocris-0905",
      "Tocris-1350",
      "PubChem18284",
      "1ro6",
      "3g4k",
      "1q9m",
      "AC1L9LJS",
      "Lopac-R-6520",
      "SCHEMBL576805",
      "ZINC4982",
      "KS-00000QDB",
      "MolPort-003-983-801",
      "HMS3267P19",
      "BCP14111",
    ],
  },
  {
    primary_id: "D01RUO",
    names: ["Morphanthridine"],
  },
  {
    primary_id: "D01RVA",
    names: [
      "ADR-851",
      "Adr 851",
      "123805-17-4",
      "UNII-303JW6Z3QJ",
      "303JW6Z3QJ",
      "Adr-851",
      "PCBGZTCWZUGEJX-QRPNPIFTSA-N",
      "AC1L2WPB",
      "SCHEMBL8785455",
      "DTXSID00154097",
      "4-Amino-5-Chloro-N-(2-Pyrrolidinylmethyl)-2,3-Dihydrobenzofuran-7-Carboxamide Hcl",
      "KB-289941",
      "4-Amino-5-Chloro-N-[[(2S)-Pyrrolidin-2-Yl]methyl]-2,3-Dihydro-1-Benzofuran-7-Carboxamide Hydrochloride",
      "7-Benzofurancarboxamide, 4-Amino-5-Chloro-2,3-Dihydro-N-(2-Pyrrolidinylmethyl)-, Monohydrochloride, (S)-",
      "(S)-4-Amino-5-Chloro-N-(2-Pyrrolidinylmethyl)-2,3-Dihydrobenzo[b]furan-7-Carboxamide Hydro",
    ],
  },
  {
    primary_id: "D01RVF",
    names: ["(R)-N-Benzyl-4-(Pyrrolidin-2-Ylmethoxy)Aniline"],
  },
  {
    primary_id: "D01RVS",
    names: ["AZD5985"],
  },
  {
    primary_id: "D01RWS",
    names: ["3-Benzyl-5-Isopropyl-8-Methylchromen-2-One", "CHEMBL470224"],
  },
  {
    primary_id: "D01RWY",
    names: ["2-(N-Tert-Butylamino)-3'-Chloroheptanophenone", "CHEMBL565382", "SCHEMBL769133", "BDBM50302921"],
  },
  {
    primary_id: "D01RXM",
    names: [
      "BZ3",
      "5-METHOXYINDOLE",
      "1006-94-6",
      "5-Methoxy-1H-Indole",
      "1H-Indole, 5-Methoxy-",
      "Femedol",
      "5-Methoxy Indole",
      "Indole, 5-Methoxy-",
      "Methoxy-5 Indole",
      "Indol-5-Yl Methyl Ether",
      "UNII-DQM3AS43PQ",
      "Methoxy-5 Indole [French]",
      "5-Methoxyindole, 99%",
      "EINECS 213-745-3",
      "DQM3AS43PQ",
      "NSC 521752",
      "CHEMBL280311",
      "DWAQDRSOVMLGRQ-UHFFFAOYSA-N",
      "MFCD00005674",
      "916979-77-6",
      "5Methoxyindole",
      "5-Methoxyindol",
      "5-Methoxy-Indole",
      "3img",
      "3imc",
      "PubChem7432",
      "1,3-Dihydro-5-Methyl-2H-Indol-2-One",
      "ACMC-1BMAQ",
      "5-(Methyloxy)-1H-Indole",
      "AC1Q4F1F",
      "AC1L22NW",
      "SCHEMBL74720",
      "KSC177G3N",
      "WLN: T",
    ],
  },
  {
    primary_id: "D01RXW",
    names: ["Vaniprevir"],
  },
  {
    primary_id: "D01RYJ",
    names: [
      "OP-02",
      "DNA Alkylating Agents, Onco-Pharmakon",
      "OP-02 Program (Cancer)",
      "OP-02 Program (Cancer), Onco-Pharmakon/NCI",
    ],
  },
  {
    primary_id: "D01RZG",
    names: ["Amidine Oxime Derivative 1"],
  },
  {
    primary_id: "D01RZP",
    names: ["4-[5-(3-Hydroxyphenyl)-3-Thienyl]-2-Methylphenol", "CHEMBL569862", "SCHEMBL1180208"],
  },
  {
    primary_id: "D01SAD",
    names: ["TT-115"],
  },
  {
    primary_id: "D01SAK",
    names: ["TL-14077"],
  },
  {
    primary_id: "D01SAT",
    names: [
      "Bevantolol",
      "Bevantololum",
      "Bevantolol [INN:BAN]",
      "Bevantololum [INN-Latin]",
      "(+-)-Bevantolol",
      "1-((2-(3,4-Dimethoxyphenyl)Ethyl)Amino)-3-(3-Methylphenoxy)-2-Propanol",
      "1-(3,4-Dimethoxyphenethylamino)-3-(M-Tolyloxy)-2-Propanol",
      "1-(3,4-Dimethoxyphenethylamino)-3-M-Tolyloxy-Propan-2-Ol",
      "1-[2-(3,4-Dimethoxy-Phenyl)-Ethylamino]-3-M-Tolyloxy-Propan-2-Ol",
      "1-[2-(3,4-Dimethoxyphenyl)Ethylamino]-3-(3-Methylphenoxy)Propan-2-Ol",
      "1-{[2-(3,4-Dimethoxyphenyl)Ethyl]amino}-3-(3-Methylphenoxy)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D01SAV",
    names: ["Oshadi D"],
  },
  {
    primary_id: "D01SAX",
    names: ["KC-12291"],
  },
  {
    primary_id: "D01SAY",
    names: ["Sipuleucel-T"],
  },
  {
    primary_id: "D01SBC",
    names: ["TSRI-265"],
  },
  {
    primary_id: "D01SBP",
    names: ["Co-152791"],
  },
  {
    primary_id: "D01SBZ",
    names: [
      "1-(4-(Benzyloxy)Phenyl)Propan-2-Amine",
      "4-Benzyloxyamphetamine",
      "1-(4-Phenylmethoxyphenyl)Propan-2-Amine",
      "CHEMBL456013",
      "7176-39-8",
      "ZDZASCUBDMUTSR-UHFFFAOYSA-N",
      "P-Benzyloxyamphetamine",
      "Phenethylamine, P-(Benzyloxy)-Alpha-Methyl-",
      "Alpha-Methyl-4-(Phenylmethoxy)Benzeneethanamine",
      "AC1L34WV",
      "Benzeneethanamine, Alpha-Methyl-4-(Phenylmethoxy)-",
      "SCHEMBL2750286",
      "AC1Q56Y9",
      "CTK2H7369",
      "BDBM50276775",
      "AKOS022489255",
      "AKOS000152896",
      "1-(4-Phenylmethoxyphenyl)-2-Propanamine",
      "1-Methyl-2-(4-Benzyloxyphenyl)Ethanamine",
    ],
  },
  {
    primary_id: "D01SCR",
    names: ["NPB-001-056"],
  },
  {
    primary_id: "D01SDX",
    names: ["H-Aba-Ala-Gly-Phe-Met-OH", "CHEMBL246572"],
  },
  {
    primary_id: "D01SEO",
    names: ["ICI-181037"],
  },
  {
    primary_id: "D01SFN",
    names: ["IC9-GD2-CD28-OX40"],
  },
  {
    primary_id: "D01SGC",
    names: ["P-Hydroxyatorvastatin"],
  },
  {
    primary_id: "D01SGK",
    names: ["Opicapone", "BIA-9-1067"],
  },
  {
    primary_id: "D01SGM",
    names: ["FK-906"],
  },
  {
    primary_id: "D01SGP",
    names: ["N-Methyl Estrone-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D01SHB",
    names: ["TRANSTORINE"],
  },
  {
    primary_id: "D01SHJ",
    names: ["E7046"],
  },
  {
    primary_id: "D01SHR",
    names: ["((3R,4R)-4-(O-Tolyloxy)Chroman-3-Yl)Methanamine", "CHEMBL498509", "SCHEMBL5808356", "BDBM50272715"],
  },
  {
    primary_id: "D01SHZ",
    names: ["Rucaparib"],
  },
  {
    primary_id: "D01SIB",
    names: ["MK-6349"],
  },
  {
    primary_id: "D01SJC",
    names: ["Transdur-Sufentanil"],
  },
  {
    primary_id: "D01SJL",
    names: ["1-((S)-2-Aminopropyl)-7-Chloro-1H-Indazol-6-Ol"],
  },
  {
    primary_id: "D01SJS",
    names: [
      "AXL-1717",
      "BVT-51004",
      "IGF-1 Inhibitors, Axelar/Biovitrum",
      "IGF-1 Inhibitors, Karolinska/Biovitrum",
      "Insulin-Like Growth Factor 1 Inhibitors, Axelar/Biovitrum",
    ],
  },
  {
    primary_id: "D01SJT",
    names: ["PF-562271"],
  },
  {
    primary_id: "D01SKR",
    names: ["Lamivudine /Maraviroc/Zidovudine Fixed-Dose Combination"],
  },
  {
    primary_id: "D01SKT",
    names: ["IONIS-SOD111RX"],
  },
  {
    primary_id: "D01SLA",
    names: [
      "ETHISTERONE",
      "17-Hydroxypregn-4-En-20-Yn-3-One",
      "Syngestrotabs",
      "Aethisteron",
      "Progestolets",
      "Pregneninolone",
      "Progestoral",
      "Gestoral",
      "Ethinone",
      "Colutoid",
      "Trosinone",
      "Prolutol",
      "Progestab",
      "Produxan",
      "Nalutoral",
      "Prodroxan",
      "Prodoxan",
      "Nugestoral",
      "Pregnin",
      "Prone",
      "Lucorteum Oral",
      "Lutidon Oral",
      "Progestin P",
      "Proluton C",
      "Primolut C",
      "Ora-Lutin",
      "Ethinyltestosterone",
      "Anhydroxyprogesterone",
      "17.alpha.-Ethynyltestosterone",
      "Anhydrohydroxyprogesterone",
      "Ethynyltestosterone",
      "Testosterone, 17-Ethynyl-",
      "NSC-9565",
      "17-Ethinyltestosterone",
      "17.alpha.-Pregn-4-En-20-Yn-3-One, 17-Hydroxy-",
    ],
  },
  {
    primary_id: "D01SLG",
    names: [
      "Okadaic Acid",
      "78111-17-8",
      "CHEMBL280487",
      "CHEBI:44658",
      "C44H68O13",
      "9,10-Deepithio-9,10-Didehydroacanthifolicin",
      "Ocadaic Acid",
      "OKA",
      "(2R)-3-[(2S,5R,6R,8S)-8-[(2R,3E)-4-[(2R,4'aR,5R,6'S,8'R,8'aS)-8'-Hydroxy-6'-[(1S,3S)-1-Hydroxy-3-[(2S,3R,6S)-3-Methyl-1,7-Dioxaspiro[5.5]undecan-2-Yl]butyl]-7'-Methylidene-Hexahydro-3'H-Spiro[oxolane-2,2'-Pyrano[3,2-B]pyran]-5-Yl]but-3-En-2-Yl]-5-Hydroxy-10-Methyl-1,7-Dioxaspiro[5.5]undec-10-En-2-Yl]-2-Hydroxy-2-Methylpropanoic Acid",
      "UNII-1W21G5Q4N2",
      "CCRIS 3329",
      "HSDB 7243",
      "1W21G5Q4N2",
      "1jk7",
    ],
  },
  {
    primary_id: "D01SLS",
    names: ["ML9"],
  },
  {
    primary_id: "D01SLZ",
    names: ["1-[(Z)-4-Trityloxy-2-Butenyl]uracil", "CHEMBL377199", "SCHEMBL12268304"],
  },
  {
    primary_id: "D01SNR",
    names: ["Firocoxib"],
  },
  {
    primary_id: "D01SNS",
    names: ["IIK7"],
  },
  {
    primary_id: "D01SNV",
    names: ["Recombinant Human Nerve Growth Factor"],
  },
  {
    primary_id: "D01SNZ",
    names: ["VTS-270"],
  },
  {
    primary_id: "D01SPD",
    names: ["BAY-85-3934"],
  },
  {
    primary_id: "D01SPH",
    names: ["Diphosphoglycolyl Proline", "Diphosphoglycolyproline", "(R,S)-Diphosphoglycolyl Proline"],
  },
  {
    primary_id: "D01SPI",
    names: [
      "CGRP",
      "Calcitonin Gene Related Peptide",
      "LAB CGRP",
      "CGRP (Inhaled, Asthma)",
      "CGRP (Inhaled, Asthma), Akela Pharma",
      "Calcitonin Gene-Related Peptide (Inhaled, Asthma), LAB International",
    ],
  },
  {
    primary_id: "D01SPJ",
    names: ["Litenimod"],
  },
  {
    primary_id: "D01SPW",
    names: ["VR647"],
  },
  {
    primary_id: "D01SQI",
    names: [
      "POT-4",
      "AL-78898A",
      "POT-4 EOS Insert",
      "Compstatin Complement-Inhibiting Peptides, Pennsylvania/Potentia",
      "4(1MeW)7W",
    ],
  },
  {
    primary_id: "D01SQO",
    names: ["Quinazoline Alkaloid Derivative 1"],
  },
  {
    primary_id: "D01SQP",
    names: ["HM-61713B", "EGFR Mutant Selective Inhibitor (Cancer), Hanmi", "EMSI (Cancer), Hanmi"],
  },
  {
    primary_id: "D01SQY",
    names: ["TZI-41127"],
  },
  {
    primary_id: "D01SRB",
    names: ["AAV2-HRPE65v2"],
  },
  {
    primary_id: "D01SRL",
    names: [
      "7-Nitro-1,2,3,4-Tetrahydro-Isoquinoline",
      "7-Nitro-1,2,3,4-Tetrahydroisoquinoline",
      "42923-79-5",
      "1,2,3,4-Tetrahydro-7-Nitroisoquinoline",
      "CHEMBL281289",
      "YPRWYZSUBZXORL-UHFFFAOYSA-N",
      "ISOQUINOLINE, 1,2,3,4-TETRAHYDRO-7-NITRO-",
      "N90100",
      "7-Nitrotetrahydroisoquinoline",
      "SCHEMBL324460",
      "7-Nitro-Tetrahydroisoquinoline",
      "AC1O502M",
      "SCHEMBL10066165",
      "CTK4I6796",
      "DTXSID30423728",
      "MolPort-003-886-230",
      "BCP09681",
      "KS-00000DK2",
      "ACT10738",
      "CS-D0150",
      "ANW-50594",
      "ZINC13686846",
      "BDBM50072991",
      "AKOS005259136",
      "QC-5046",
      "AB21318",
      "RP03102",
      "MCULE-4384372233",
      "AC-9598",
    ],
  },
  {
    primary_id: "D01SRS",
    names: [
      "3-Methyl-7-Nitro-1,2,3,4-Tetrahydro-Isoquinoline",
      "CHEMBL59719",
      "3-Methyl-7-Nitro-1,2,3,4-Tetrahydroisoquinoline",
      "SCHEMBL7512740",
      "Isoquinoline, 1,2,3,4-Tetrahydro-3-Methyl-7-Nitro-",
      "OTVSEAHLIUCIKT-UHFFFAOYSA-N",
      "BDBM50077543",
    ],
  },
  {
    primary_id: "D01SSI",
    names: ["PI-3065"],
  },
  {
    primary_id: "D01SSK",
    names: ["MTRN-2696"],
  },
  {
    primary_id: "D01SSL",
    names: ["AT-027"],
  },
  {
    primary_id: "D01STB",
    names: [
      "Ramipril",
      "Acovil",
      "Altace",
      "Carasel",
      "Cardace",
      "Delix",
      "Hypren",
      "Hytren",
      "Lostapres",
      "Naprix",
      "Pramace",
      "Quark",
      "Ramace",
      "Ramiprilum",
      "Triatec",
      "Tritace",
      "Vesdil",
      "Zabien",
      "Almirall Brand Of Ramipril",
      "Astra Brand Of Ramipril",
      "AstraZeneca Brand Of Ramipril",
      "Aventis Brand Of Ramipril",
      "Aventis Pharma Brand Of Ramipril",
      "Hoechst Brand Of Ramipril",
      "Monarch Brand Of Ramipril",
      "Promed Brand Of Ramipril",
      "Ramiprilum [Latin]",
      "HOE 498",
      "HOE498",
      "Altace (TN)",
      "Hoe-498",
      "Pramace (Discontinued)",
      "Tritace (TN)",
      "Ramipril (USP/INN)",
      "Ramipril [USAN:INN:BAN]",
      "Ramipro, Tritace, Altace, Prilace, Ramipril",
      "N-(1S-Carboethoxy-3-Phenylpropyl)-S-Alanyl-Cis,endo-2-Azabicyclo[3.3.0]octane-3S-Carboxylic Acid",
      "[2s,3as,6as]-1-[(2s)-2-[[(1s)-1-(Ethoxycarbonyl)-3-Phenylpropyl]amino]-1-Oxopropyl]octahydrocyclopen",
      "[2S,3aS,6aS]-1-[(2S)-2-[[(1S)-1-(Ethoxycarbonyl)-3-Phenylpropyl]amino]-1-Oxopropyl]octahydrocyclopenta[b]pyrrole-2-Carboxylic Acid",
      "(2 S ,3 AS,6 AS)-1[(S)-N-[(S)-1-Carboxy-3-Phenylpropyl] Alanyl] Octahydrocyclopenta [ B ]pyrrole-2-Carboxylic Acid, 1-Ethyl Ester",
      "(2S,3aS,6aS)-1-((S)-2-((S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Ylamino)Propanoyl) Octahydrocyclopenta[b]pyrrole-2-Carboxylic Acid",
      "(2S,3aS,6aS)-1-((S)-N-((S)-1-Carboxy-3-Phenylpropyl)Alanyl)Octahydrocyclopenta(B)Pyrrole-2-Carboxylic Acid, 1-Ethyl Ester",
      "(2S,3aS,6aS)-1-((S)-N-((S)-1-Ethoxycarbonyl-3-Phenylpropyl)Alanyl)Octahydrocyclopenta(B)Pyrrol-2-Carbonsaeure",
      "(2S,3aS,6aS)-1-[(2S)-2-[[(2S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Yl]amino]propanoyl]-3,3a,4,5,6,6a-Hexahydro-2H-Cyclopenta[b]pyrrole-2-Carboxylic Acid",
      "(2S,3aS,6aS)-1-[(2S)-2-{[(1S)-1-Ethoxycarbonyl-3-Phenylpropyl]amino}propanoyl]octahydrocyclopenta[b]pyrrole-2-Carboxylic Acid",
      "(2S,3aS,6aS)-1-[(2S)-2-{[(2S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Yl]amino}propanoyl]octahydrocyclopenta[b]pyrrole-2-Carboxylic Acid (Non-Preferred Name)",
      "(2S-(1(R*(R*)),2alpha,3abeta,6abeta))-1-(2-((1-(Ethoxycarbonyl)-3-Phenylpropyl)Amino)-1-Oxopropyl)Octahydrocyclopenta(B)Pyrrole-2-Carboxylic Acid",
      "(2s,3as,6as)-1((S)-N-((S)-1-Carboxy-3-Phenylpropyl)Alanyl)Octahydrocyclopenta(B)Pyrrole-2-Carboxylic",
      "(2s,3as,6as)-1-((S)-2-((S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Ylamino)Propanoyl)-Octahydrocyclopenta[b]p",
      "(2s,3as,6as)-1-[(S)-2-((S)-1-Ethoxycarbonyl-3-Phenyl-Propylamino)-Propionyl]-Octahydro-Cyclopenta[b]",
    ],
  },
  {
    primary_id: "D01STE",
    names: ["Alkyl Glycerol Phosphate 18:1", "AGP 18.1"],
  },
  {
    primary_id: "D01STY",
    names: ["4-Amino-3-Iodo-Benzenesulfonamide", "4-Amino-3-Iodobenzenesulfonamide"],
  },
  {
    primary_id: "D01SUE",
    names: [
      "3-Indan-(1E)-Ylidenemethyl-Pyridine",
      "(3-Pyridylmethylene)Indane 1a",
      "SCHEMBL4221377",
      "SCHEMBL4221371",
      "CHEMBL364080",
      "BDBM8581",
      "AC1O7046",
      "3-[(E)-2,3-Dihydroinden-1-Ylidenemethyl]pyridine",
      "3-[(1E)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine",
      "3-[(E)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine Hydrochloride",
    ],
  },
  {
    primary_id: "D01SUF",
    names: ["GP-1200"],
  },
  {
    primary_id: "D01SUL",
    names: ["JNJ-10397049"],
  },
  {
    primary_id: "D01SUO",
    names: ["EBC-46"],
  },
  {
    primary_id: "D01SUY",
    names: ["BRU-83-92"],
  },
  {
    primary_id: "D01SVG",
    names: ["KA-10X"],
  },
  {
    primary_id: "D01SWJ",
    names: ["AVP-13748"],
  },
  {
    primary_id: "D01SWK",
    names: ["Rafabegron", "AD-9677", "AJ-9677", "TAK-677"],
  },
  {
    primary_id: "D01SWO",
    names: [
      "2-(3-Hydroxyphenyl)-1,2'-Spirobi[1H-Indene]-5-Ol",
      "CHEMBL24950",
      "SCHEMBL6286195",
      "BDBM50123206",
      "2-(3-Hydroxyphenyl)-1,2''-Spirobi[1H-Indene]-5-Ol",
    ],
  },
  {
    primary_id: "D01SXB",
    names: ["3-(6-Morpholino-4-Oxo-4H-Pyran-2-Yl)Benzaldehyde", "CHEMBL223437", "SCHEMBL3535188"],
  },
  {
    primary_id: "D01SXE",
    names: ["F-18 T808"],
  },
  {
    primary_id: "D01SXG",
    names: ["ThermoStem"],
  },
  {
    primary_id: "D01SXH",
    names: ["CD20-CAR-T Cells"],
  },
  {
    primary_id: "D01SXY",
    names: ["NVB-302"],
  },
  {
    primary_id: "D01SYN",
    names: ["PMID25522065-Compound-39"],
  },
  {
    primary_id: "D01SYO",
    names: ["4-(2-Methyl-Thiazol-4-Ylethynyl)-Pyridine", "CHEMBL303090"],
  },
  {
    primary_id: "D01SYU",
    names: [
      "Turofexorate Isopropyl",
      "629664-81-9",
      "WAY-362450",
      "XL335",
      "Isopropyl 3-(3,4-Difluorobenzoyl)-1,1-Dimethyl-1,2,3,6-Tetrahydroazepino[4,5-B]indole-5-Carboxylate",
      "FXR-450",
      "Turofexorate Isopropyl (XL335)",
      "UNII-S6KDM312I5",
      "WAY 362450",
      "S6KDM312I5",
      "WAY362450",
      "XL-335",
      "3-(3,4-Difluorobenzoyl)-1,2,3,6-Tetrahydro-1,1-Dimethyl-Azepino[4,5-B]indole-5-Carboxylic Acid 1-Methylethyl Ester",
      "1-Methylethyl 3-[(3,4-Difluorophenyl)Carbonyl]-1,1-Dimethyl-1,2,3,6-Tetrahydroazepino[4,5-B]indole-5-Carboxylate",
      "FXR 450",
      "Turofexorate Isopropyl [USAN:I",
    ],
  },
  {
    primary_id: "D01SZA",
    names: ["MR-1817"],
  },
  {
    primary_id: "D01SZX",
    names: ["1-Imidazol-1-Ylmethylxanthen-9-One", "CHEMBL1083652"],
  },
  {
    primary_id: "D01TAJ",
    names: ["ISIS 109525"],
  },
  {
    primary_id: "D01TAQ",
    names: ["NLP-44", "Acne Therapy, NovaLead Pharma"],
  },
  {
    primary_id: "D01TBJ",
    names: ["CMX-2043"],
  },
  {
    primary_id: "D01TBL",
    names: [
      "HRGP",
      "Angiogenesis Inhibitor, Innoventus",
      "HRGP (Solid Tumor)",
      "HRGP (Solid Tumor), Innoventus Project",
      "Histidine-Rich Glycoprotein (Solid Tumor), Innoventus Project",
    ],
  },
  {
    primary_id: "D01TBN",
    names: ["Ezlopitant"],
  },
  {
    primary_id: "D01TCB",
    names: ["ELND005"],
  },
  {
    primary_id: "D01TCM",
    names: ["CEE-03-320", "CEE-320", "NNC-220215"],
  },
  {
    primary_id: "D01TDC",
    names: [
      "6-Methyl-5,6-Dihydro-4H-1,3-Thiazin-2-Amine",
      "2-Amino-5,6-Dihydro-6-Methyl-4h-1,3-Thiazine",
      "1121-91-1",
      "(+/-)-AMT Hydrochloride",
      "4H-1,3-Thiazin-2-Amine, 5,6-Dihydro-6-Methyl-",
      "AC1L1BOF",
      "Lopac0_000085",
      "SCHEMBL523755",
      "CHEMBL106322",
      "CHEBI:91610",
      "MolPort-009-018-788",
      "BATVOUKHGLKDGQ-UHFFFAOYSA-N",
      "FCH842002",
      "AKOS006274541",
      "CCG-204180",
      "NCGC00024834-02",
      "NCGC00015114-03",
      "NCGC00015114-04",
      "KB-19997",
      "5,6-Dihydro-6-Methyl-4h-1,3-Thiazin-2-Amine",
      "C-41564",
    ],
  },
  {
    primary_id: "D01TDG",
    names: ["S-Ethyl-N-Phenyl-Isothiourea"],
  },
  {
    primary_id: "D01TDT",
    names: ["Aryl Pyrimidine Derivative 7"],
  },
  {
    primary_id: "D01TDV",
    names: ["PMID21742770C1"],
  },
  {
    primary_id: "D01TDY",
    names: [
      "Unacylated Ghrelin",
      "AZP-01",
      "Diabetes Therapeutic Peptide, Theratechnologies",
      "TH-0332",
      "Un-Octanoylated Ghrelin",
      "Unacylated Ghrelin (Diabetes)",
      "Ghrelin Inhibitor Peptides (Diabetes), Theratechnologies",
      "UAG (Diabetes), Alize Pharma",
      "Unacylated Ghrelin (Diabetes), Alize Pharma",
      "Unacylated Ghrelin (Diabetes), Theratechnologies",
      "Un-Octanoylated Ghrelin (Diabetes), Alize Pharma",
      "Unacylated Ghrelin (Diabetes), Alize Pharma/Eli Lilly",
    ],
  },
  {
    primary_id: "D01TEA",
    names: [
      "DSC-127",
      "Aclerastide",
      "UNII-YYD6UT8T47",
      "YYD6UT8T47",
      "Aclerastide [INN]",
      "Norleu3-A(1-7)",
      "Asp-Arg-Nle-Tyr-Ile-His-Pro",
      "DSC127",
      "USB-001",
      "227803-63-6",
      "1-7-Angiotensin II, 3-L-Norleucine-5-L-Isoleucine",
    ],
  },
  {
    primary_id: "D01TFO",
    names: ["Z-Ala-Leu-Lle-Agly-Ile-Val-OMe"],
  },
  {
    primary_id: "D01TFQ",
    names: ["KNI-10124"],
  },
  {
    primary_id: "D01TGE",
    names: ["Heterocycle-Containing Compound 2"],
  },
  {
    primary_id: "D01TGF",
    names: ["PMID30247903-Compound-General Structure7"],
  },
  {
    primary_id: "D01TGH",
    names: ["PF-572778"],
  },
  {
    primary_id: "D01TGI",
    names: ["4-(2,4-Dichloro-Phenoxy)-Biphenyl-3-Ol"],
  },
  {
    primary_id: "D01THA",
    names: ["Ethylaminopyrimidine Derivative 1"],
  },
  {
    primary_id: "D01THF",
    names: ["4-(Mesitylthio)-2-Methoxybenzonitrile", "CHEMBL462022"],
  },
  {
    primary_id: "D01THG",
    names: ["Trans-2-Methylcrotonic Acid"],
  },
  {
    primary_id: "D01THN",
    names: [
      "JTT-654",
      "11-Beta HSD1 Inhibitor (Oral, Type 2 Diabetes), Japan Tobacco",
      "11-Beta Hydroxysteroid Dehydrogenase (Oral, Type 2 Diabetes), Japan Tobacco",
    ],
  },
  {
    primary_id: "D01THX",
    names: ["Factor Viii"],
  },
  {
    primary_id: "D01TIN",
    names: ["R-1458"],
  },
  {
    primary_id: "D01TJM",
    names: ["CTCE-9908", "Anticancer Therapy, Chemokine Therapeutics", "CTCE-9908/0019"],
  },
  {
    primary_id: "D01TJV",
    names: ["K-103-PA"],
  },
  {
    primary_id: "D01TJW",
    names: ["PMID27109571-Compound-30"],
  },
  {
    primary_id: "D01TJY",
    names: ["GLPG-0187"],
  },
  {
    primary_id: "D01TKS",
    names: ["Tyr-D-Ala-Gly-Phe-Met-NH2"],
  },
  {
    primary_id: "D01TLN",
    names: ["9-O-[4-(Phenylol-1-Yloxy)Butyl]berberine Bromide", "CHEMBL602861"],
  },
  {
    primary_id: "D01TLV",
    names: ["EVT 401"],
  },
  {
    primary_id: "D01TMC",
    names: ["PMID16099654C4b"],
  },
  {
    primary_id: "D01TMF",
    names: ["Latrodectus Antivenin"],
  },
  {
    primary_id: "D01TMK",
    names: ["GX-G6"],
  },
  {
    primary_id: "D01TMQ",
    names: ["Bupropion+naltrexone"],
  },
  {
    primary_id: "D01TMW",
    names: ["Tradjeta"],
  },
  {
    primary_id: "D01TNK",
    names: ["CAR-T Cells Targeting EpCAM"],
  },
  {
    primary_id: "D01TNT",
    names: ["Sanggenon G", "CHEMBL382338"],
  },
  {
    primary_id: "D01TNW",
    names: [
      "Dapagliflozin",
      "BMS 512148",
      "BMS-512148",
      "BMS-512148, Dapagliflozin",
      "Dapagliflozin (INN/USAN)",
      "2-(3-(4-Ethoxybenzyl)-4-Chlorophenyl)-6-Hydroxymethyltetrahydro-2H-Pyran-3,4,5-Triol",
    ],
  },
  {
    primary_id: "D01TOR",
    names: [
      "Benzyl-(2-Pyridin-3-Yl-Quinazolin-4-Yl)-Amine",
      "CHEMBL119841",
      "AC1LDIC6",
      "Oprea1_641196",
      "MLS001163907",
      "Cid_667555",
      "ZINC5903",
      "SCHEMBL7286130",
      "MolPort-002-565-372",
      "HMS2878H17",
      "PCOP-956263",
      "BDBM50033284",
      "STK536221",
      "NCGC00012858",
      "AKOS001104531",
      "MCULE-1376892352",
      "NCGC00012858-02",
      "SMR000539306",
      "Benzyl(2-(3-Pyridyl)Quinazolin-4-Yl)Amine",
      "N-Benzyl-2-Pyridin-3-Ylquinazolin-4-Amine",
      "N-Benzyl-2-(3-Pyridinyl)Quinazolin-4-Amine",
      "ST50015136",
      "N-Benzyl-2-(Pyridin-3-Yl)Quinazolin-4-Amine",
    ],
  },
  {
    primary_id: "D01TOU",
    names: ["FLUANISONE"],
  },
  {
    primary_id: "D01TPU",
    names: ["AWD-160-275"],
  },
  {
    primary_id: "D01TPW",
    names: ["4-Hydroxy-But-2-Enoate", "CHEMBL354804"],
  },
  {
    primary_id: "D01TQD",
    names: ["VU0424465"],
  },
  {
    primary_id: "D01TQR",
    names: ["Nonoxynol-9"],
  },
  {
    primary_id: "D01TRI",
    names: ["2,7-Bis(Phenylacetamido)Anthraquinone", "CHEMBL480771"],
  },
  {
    primary_id: "D01TRN",
    names: ["ONO-AE3-237"],
  },
  {
    primary_id: "D01TRU",
    names: ["NFTA-105"],
  },
  {
    primary_id: "D01TSI",
    names: [
      "Ergotamine",
      "Avetol",
      "Cornutamin",
      "Cornutamine",
      "ETIN",
      "Ergam",
      "Ergate",
      "Ergomar",
      "Ergonsvine",
      "Ergostat",
      "Ergotamin",
      "Ergotartrate",
      "Exmigra",
      "Femergin;Gynergen",
      "Lingraine",
      "Lingran",
      "Migretamine",
      "Rigetamin",
      "Secagyn",
      "Secupan",
      "Temigran",
      "Wigrettes",
      "Ergotamine Bitartrate",
      "Ergotamine Hemitartrate",
      "Ergotamine Tartrate",
      "Gotamine Tartrate",
      "Medihaler Ergotamine",
      "Ergomar (TN)",
      "Ergotamina [INN-Spanish]",
      "Ergotamine (INN)",
      "Ergotamine [INN:BAN]",
      "Ergotamine Tartrate (VAN)",
      "Ergotaminum [INN-Latin]",
      "Ergoton-A",
      "Neo-Ergotin",
      "Ergotamine, Tartrate (2:1)",
      "Ergotamine Tartrate, Avetol, Cornutamin, Ergam, Ergotartrate",
      "ERGOTAMINE (SEE ALSO: ERGOTAMINE TARTRATE (CAS 379-79-3))",
      "Ergotamine, Tartrate (2:1) (Salt) (8CI)",
      "Ergotaman-3',6',18-Trione, 12'-Hydroxy-2'-Methyl-5'-(Phenylmethyl)-, (5'-Alpha)-(9CI)",
      "Ergotaman-3',6',18-Trione, 12'-Hydroxy-2'-Methyl-5'-(Phenylmethyl)-, (5alpha)-, (2R,3R)-2,3-Dihydroxybutanedioate (2:1)",
      "Ergotaman-3',6',18-Trione, 12'-Hydroxy-2'-Methyl-5'-(Phenylmethyl)-, (5'alpha)-, (2R,3R)-2,3-Dihydroxybutanedioate (2:1) (Salt)",
      "(5'A)-5'-Benzyl-12'-Hydroxy-2'-Methyl-3',6',18-Trioxoergotaman 2,3-Dihydroxybutanedioate(2:1)",
      "12'-Hydroxy-2'-Methyl-5'alpha-(Phenylmethyl)Ergotaman-3',6',18-Trione",
    ],
  },
  {
    primary_id: "D01TSM",
    names: ["N-(4'-Acetyl-4-Aminobiphenyl-3-Yl)Benzamide", "CHEMBL1099078", "BDBM50317997"],
  },
  {
    primary_id: "D01TSN",
    names: [
      "PD-128483",
      "CCRIS 4394",
      "PD 128483",
      "153260-23-2",
      "115688-97-6",
      "C11H15N3S",
      "6-Methyl-4,5,5a,6,7,8-Hexahydrothiazolo(4,5-F)Quinolin-2-Amine",
      "4,5,5a,6,7,8-Hexahydro-6-Methylthiazolo(4,5-F)Quinoline-2-Amine",
      "Thiazolo(4,5-F)Quinolin-2-Amine, 4,5,5a,6,7,8-Hexahydro-6-Methyl-, (+-)-",
      "CCRIS 6698",
      "6-METHYL-4,5,5A,6,7,8-HEXAHYDROTHIAZOLO[4,5-F]QUINOLIN-2-AMINE",
      "4,5,5A,6,7,8-HEXAHYDRO-6-METHYLTHIAZOLO[4,5-F]QUINOLINE-2-AMINE",
      "XFJSSDHKIXXJLM-UHFFFAOYSA-N",
      "AC1L2Y0S",
      "CHEMBL350920",
      "SCHEMBL10665477",
      "AKOS022654211",
      "LS-172297",
      "LS-190761",
    ],
  },
  {
    primary_id: "D01TSX",
    names: [
      "(R)-1-(4-Iodo-2,5-Dimethoxyphenyl)Propan-2-Amine",
      "CHEMBL134519",
      "R-DOI",
      "Lopac0_000478",
      "GTPL157",
      "(R)-DOI",
      "SCHEMBL713061",
      "ZINC2516053",
      "PDSP2_001372",
      "Benzeneethanamine, 4-Iodo-2,5-Dimethoxy-Alpha-Methyl-, (AlphaR)-",
      "BDBM50133231",
      "PDSP1_001388",
      "CCG-204569",
      "NCGC00162167-01",
      "AJ-36945",
      "82864-06-0",
      "(2R)-1-(4-Iodo-2,5-Dimethoxyphenyl)Propan-2-Amine",
      "(R)-1-(2,5-Dimethoxy-4-Iodophenyl)-2-Aminopropane",
      "(R)-2-(4-Iodo-2,5-Dimethoxyphenyl)-1-Methylethylamine",
      "(R)-2-(4-Iodo-2,5-Dimethoxy-Phenyl)-1-Methyl-Ethylamine",
      "UNII-OOM10GW9UE",
    ],
  },
  {
    primary_id: "D01TUB",
    names: ["4-Chloro-2-(4-Hydroxy-Phenyl)-Quinolin-6-Ol", "4-Chloro-2-(4-Hydroxyphenyl)Quinolin-6-Ol"],
  },
  {
    primary_id: "D01TVQ",
    names: ["Ibritumomab"],
  },
  {
    primary_id: "D01TVR",
    names: ["Tagatose"],
  },
  {
    primary_id: "D01TVT",
    names: ["FAS-115"],
  },
  {
    primary_id: "D01TWD",
    names: [
      "3-Tapap",
      "TAPAP",
      "AC1Q5K4B",
      "AC1L2Q7B",
      "Nalpha-Tosyl-(3-Amidinophenyl)Alanine Piperidide",
      "N(Alpha)-(4-Toluenesulfonyl)-3-Amidinophenylalanylpiperidine",
      "CHEMBL384295",
      "80457-09-6",
      "3-[2-{[(4-Methylphenyl)Sulfonyl]amino}-3-Oxo-3-(Piperidin-1-Yl)Propyl]benzenecarboximidamide",
      "1-(3-(3-(Aminoiminomethyl)Phenyl)-2-(((4-Methylphenyl)Sulfonyl)Amino)-1-Oxopropyl)Piperidine",
      "Piperidine, 1-(3-(3-(Aminoiminomethyl)Phenyl)-2-(((4-Methylphenyl)Sulfonyl)Amino)-1-Oxopropyl)-",
      "73438-63-8",
      "N(Alpha)-Tosyl-(3-Amidinophenyl)Alanine Piperidide",
    ],
  },
  {
    primary_id: "D01TWG",
    names: ["Rozanolixizumab"],
  },
  {
    primary_id: "D01TYB",
    names: ["Basulin"],
  },
  {
    primary_id: "D01TYJ",
    names: ["4-(4,6-Dinitro-1H-Indol-1-Ylsulfonyl)Benzenamine", "CHEMBL200844"],
  },
  {
    primary_id: "D01TYO",
    names: ["SCH-210971"],
  },
  {
    primary_id: "D01TYY",
    names: ["SiRNA Therapeutic, Lauriad/Tablet, Prostate Cancer"],
  },
  {
    primary_id: "D01TZI",
    names: ["AMPA Receptor Positive Modulator"],
  },
  {
    primary_id: "D01TZT",
    names: [
      "2-(2-Nitro-Benzoyl)-Cyclohexane-1,3-Dione",
      "CHEMBL169696",
      "2-(2-Nitrobenzoyl)Cyclohexane-1,3-Dione",
      "1,3-Cyclohexanedione, 2-(2-Nitrobenzoyl)-",
      "104206-60-2",
      "ACMC-20m705",
      "SCHEMBL7275303",
      "CTK0G6513",
      "DTXSID90553792",
      "ZZJZBPYZTKGWTD-UHFFFAOYSA-N",
      "2-Nitrobenzoylcyclohexane-1,3-Dione",
      "BDBM50088793",
      "2-(2-Nitrobenzoyl)-1 ,3-Cyclohexanedione",
    ],
  },
  {
    primary_id: "D01UAD",
    names: ["PTX-022"],
  },
  {
    primary_id: "D01UAL",
    names: ["Purine Derivative 4"],
  },
  {
    primary_id: "D01UAM",
    names: ["Human And Mouse Gp100 DNA Plasmid Vaccines"],
  },
  {
    primary_id: "D01UAO",
    names: ["3-Chloro-4-(4-Hydroxyphenyl)Salicylaldoxime"],
  },
  {
    primary_id: "D01UAS",
    names: ["6-Bromo-3-Ethoxycarbonyl-4-Quinolone"],
  },
  {
    primary_id: "D01UAU",
    names: ["CTP-543"],
  },
  {
    primary_id: "D01UBA",
    names: ["PMID25656651-Compound-30a"],
  },
  {
    primary_id: "D01UBV",
    names: ["DXL-1215"],
  },
  {
    primary_id: "D01UBX",
    names: [
      "Aclarubicin",
      "Aclacin",
      "Aclacur",
      "Aclarubicine",
      "Aclarubicino",
      "Aclarubicinum",
      "Jaclacin",
      "Aclacinomycin A",
      "Aclucinomycin A",
      "Antibiotic MA 144A",
      "Antibiotic MA 144A1",
      "Antibiotic MA 144G1",
      "MA 144G1",
      "Aclarubicine [INN-French]",
      "Aclarubicino [INN-Spanish]",
      "Aclarubicinum [INN-Latin]",
      "Antibiotic MA144-A1",
      "MA 144-A1",
      "MA-144A1",
      "Acene-1-Carboxylate",
      "Aclarubicin (USAN/INN)",
      "Aclarubicin [USAN:BAN:INN]",
      "Alpha-L-Lyxo-Hexopyranosyl]-Oxy]-1-Naphthacenecarboxalic Acid Methyl Ester",
      "10-Epi-Aclacinomycin A",
    ],
  },
  {
    primary_id: "D01UBY",
    names: ["JNJ-41443532"],
  },
  {
    primary_id: "D01UDC",
    names: ["Acetic Acid 8-Aza-Bicyclo[3.2.1]oct-6-Yl Ester", "CHEMBL64000", "BDBM50064609"],
  },
  {
    primary_id: "D01UDR",
    names: ["Ro-25-0993"],
  },
  {
    primary_id: "D01UDS",
    names: ["CNV-2197944"],
  },
  {
    primary_id: "D01UDU",
    names: [
      "3-Chloro-4-Hydrazino-Benzenesulfonamide",
      "3-Chloro-4-Hydrazino-Benzenesulphonamide",
      "776281-37-9",
      "Hydrazide Deriv. 16",
      "SCHEMBL3952309",
      "CHEMBL176203",
      "CTK2G6221",
      "BDBM12161",
      "DTXSID30461261",
      "MIIRMBFCBFPOKU-UHFFFAOYSA-N",
      "4-Hydrazino-3-Chlorobenzenesulfonamide",
      "AKOS030585345",
      "Benzenesulfonamide, 3-Chloro-4-Hydrazino-",
    ],
  },
  {
    primary_id: "D01UFF",
    names: ["ORG2058"],
  },
  {
    primary_id: "D01UFH",
    names: ["Trastuzumab Biosimilar"],
  },
  {
    primary_id: "D01UFK",
    names: [
      "ATX-MS-1467",
      "Antigen Processing Independent EpiTOPES Vaccine (APITOPES, Multiple Sclerosis) Apitope",
      "Therapeutic Vaccine (Multiple Sclerosis), Apitope",
      "Therapeutic Vaccine (Multiple Sclerosis), Apitope/Merck Serono",
      "API-1,4,6,7",
    ],
  },
  {
    primary_id: "D01UFM",
    names: ["N-Cyclohexyl-2-Oxo-2H-Chromene-3-Carboxamide"],
  },
  {
    primary_id: "D01UFN",
    names: ["Falecalcitrol"],
  },
  {
    primary_id: "D01UFZ",
    names: ["REL-1017"],
  },
  {
    primary_id: "D01UGG",
    names: ["Sabarubicin", "BMS-195615", "MEN-10755"],
  },
  {
    primary_id: "D01UGN",
    names: ["CNI-1493"],
  },
  {
    primary_id: "D01UGS",
    names: ["Bipolar Disorder NCE"],
  },
  {
    primary_id: "D01UHA",
    names: ["MIS-416"],
  },
  {
    primary_id: "D01UHU",
    names: ["Pyriplatin", "CDPCP", "Pyriplatin (Cancer)", "Pyriplatin (Cancer), MIT"],
  },
  {
    primary_id: "D01UIE",
    names: ["Vaccine, Lauriad, Influenza"],
  },
  {
    primary_id: "D01UIY",
    names: ["N-(3-(2-Ethoxy-5-Methoxyphenyl)Propyl)Acetamide", "CHEMBL1091561"],
  },
  {
    primary_id: "D01UJK",
    names: ["AVE0897"],
  },
  {
    primary_id: "D01UJL",
    names: ["N-Trimethyllysine"],
  },
  {
    primary_id: "D01UJS",
    names: ["Rebelex"],
  },
  {
    primary_id: "D01UKK",
    names: ["PF-06760805"],
  },
  {
    primary_id: "D01UKL",
    names: ["S-(N-Heptyl-N-Hydroxycarbamoyl)Glutathione", "CHEMBL128836", "BDBM50092831"],
  },
  {
    primary_id: "D01UKV",
    names: [
      "3-[1'-{4'-(Benzyloxy)-Phenyl}]-Quinuclidine-2-Ene",
      "CHEMBL453439",
      "BDBM50270321",
      "3-[1''-{4''-(Benzyloxy)-Phenyl}]-Quinuclidine-2-Ene",
    ],
  },
  {
    primary_id: "D01ULE",
    names: ["Alpha-Sulfanylbenzylphosphonic Acid"],
  },
  {
    primary_id: "D01ULX",
    names: ["CC-0651", "Cdc34 Inhibitors (Cancer)", "Cdc34 Inhibitors (Cancer), Celgene"],
  },
  {
    primary_id: "D01UMC",
    names: ["Vaccine, RSV Infection"],
  },
  {
    primary_id: "D01UMT",
    names: [
      "Ro 31-7453",
      "Bisindolylmaleimide Deriv. 44",
      "MKC-1",
      "Ro-31-7453",
      "3-(1-Methylindol-3-Yl)-4-(1-Methyl-6-Nitroindol-3-Yl)Pyrrole-2,5-Dione",
    ],
  },
  {
    primary_id: "D01UNC",
    names: [
      "2-(4-Aminophenylsulfonamido)Acetic Acid",
      "5616-30-8",
      "(4-Amino-Benzenesulfonylamino)-Acetic Acid",
      "2-[(4-Aminophenyl)Sulfonylamino]acetic Acid",
      "CHEMBL285053",
      "(4-Aminophenylsulfonylamino)Acetic Acid",
      "Glycine, N-[(4-Aminophenyl)Sulfonyl]-",
      "2-(4-Aminobenzenesulfonylamino)Ethanoic Acid",
      "Sulfanilamidoacetic Acid",
      "2-(4-Aminophenylsulfonamido)Aceticacid",
      "AC1L92ZE",
      "SCHEMBL1228868",
      "N-4-Aminobenzenesulfonyl Glycine",
      "CTK1F5176",
      "DTXSID30328582",
      "((4-Aminophenyl)Sulfonyl)Glycine",
      "BPJUMYMTQYMSFZ-UHFFFAOYSA-N",
      "ZINC1670307",
    ],
  },
  {
    primary_id: "D01UNE",
    names: ["PMID18790648C10"],
  },
  {
    primary_id: "D01UNF",
    names: [
      "BAL-0019764",
      "BAL-19764",
      "PTX-2416",
      "Syn-2416",
      "Dihydroxypyridone Monobactam (Bacterial Infection), PanTherix",
    ],
  },
  {
    primary_id: "D01UNT",
    names: ["ICI 200,880"],
  },
  {
    primary_id: "D01UOK",
    names: ["ISIS 10228"],
  },
  {
    primary_id: "D01UOO",
    names: ["3-Benzyl-2-Ethylthio-6-Nitro-Quinazolin-4(3H)-One", "CHEMBL1092690"],
  },
  {
    primary_id: "D01UPT",
    names: ["FA-70"],
  },
  {
    primary_id: "D01UQJ",
    names: ["FP-0240"],
  },
  {
    primary_id: "D01UQU",
    names: ["Concatameric CD2Ig", "Concatameric CD2Ig (Immunoadhesin)"],
  },
  {
    primary_id: "D01URR",
    names: ["PMID21939274C2"],
  },
  {
    primary_id: "D01URS",
    names: ["PF-592379"],
  },
  {
    primary_id: "D01USH",
    names: ["7-Hydroxycholesterol", "7-Hydroxy-Cholesterol", "7-OHC", "SCHEMBL281943", "GTPL3432", "KB-200035"],
  },
  {
    primary_id: "D01UTL",
    names: [
      "Desipramine",
      "Demethylimipramine",
      "Desimipramine",
      "Desimpramine",
      "Desipramin",
      "Desipramina",
      "Desipraminum",
      "Desmethylimipramine",
      "Dezipramine",
      "Dimethylimipramine",
      "Methylaminopropyliminodibenzyl",
      "Monodemethylimipramine",
      "Norimipramine",
      "Norpramine",
      "Pentofran",
      "Pertrofane",
      "Sertofran",
      "Desipramine Hcl",
      "DMI 50475",
      "DMI (Pharmaceutical)",
      "Desipramina [INN-Spanish]",
      "Desipramine (D4)",
      "Desipramine (INN)",
      "Desipramine [INN:BAN]",
      "Desipraminum [INN-Latin]",
      "Norpramin (TN)",
      "Pertofrane (TN)",
      "ZERO/006017",
      "N-(3-Methylaminopropyl)Iminobibenzyl",
      "(3-(10H,11H-Dibenzo[b,f]azepin-5-Yl)Propyl)Methylamine",
      "10, 11-Dihydro-N-Methyl-5H-Dibez[b,f]azepine-5-Propanamine",
      "10,11-Dihydro-5-(3-Methylaminopropyl)-5H-Dibenz(B,f)Azepine",
      "3-(10,11-DIHYDRO-5H-DIBENZO[B,F]AZEPIN-5-YL)-N-METHYLPROPAN-1-AMINE",
      "3-(5,6-Dihydrobenzo[b][1]benzazepin-11-Yl)-N-Methylpropan-1-Amine",
      "5-(Gamma-Methylaminopropyl)Iminodibenzyl",
    ],
  },
  {
    primary_id: "D01UTX",
    names: ["PMID25656651-Compound-31c"],
  },
  {
    primary_id: "D01UUD",
    names: ["Pitolisant"],
  },
  {
    primary_id: "D01UUF",
    names: ["Rh-GDNF"],
  },
  {
    primary_id: "D01UUH",
    names: ["4-(3-Pyridylethynyl)-2-Thiazolylamine", "CHEMBL381031", "329205-54-1", "SCHEMBL4158461"],
  },
  {
    primary_id: "D01UUW",
    names: ["Trecetilide Fumarate"],
  },
  {
    primary_id: "D01UUZ",
    names: [
      "(R)-2-Amino-4-Hydroxy-N-(4-Octylphenyl)Butanamide",
      "CHEMBL572687",
      "SCHEMBL1312879",
      "QJTVTVAQRCRXSM-QGZVFWFLSA-N",
    ],
  },
  {
    primary_id: "D01UVA",
    names: ["Tagitinin A"],
  },
  {
    primary_id: "D01UVD",
    names: ["N-(2-Iodoethyl)Linoleoylamide"],
  },
  {
    primary_id: "D01UVG",
    names: ["RhVGF Modulators, Wound Healing, Stainwei Biotech"],
  },
  {
    primary_id: "D01UVU",
    names: [
      "4-(3-Bromopyrazolo[1,5-A]pyrimidin-6-Yl)Phenol",
      "945376-95-4",
      "CHEMBL1269227",
      "AK-87708",
      "SCHEMBL2813127",
      "CTK8B8546",
      "DTXSID20680661",
      "MolPort-023-331-308",
      "XBHIAJWNXVJSAA-UHFFFAOYSA-N",
      "KS-00000P8Z",
      "ZINC64512573",
      "ANW-60649",
      "6223AC",
      "AKOS016003310",
      "ACN-048482",
      "QC-10378",
      "DS-18362",
      "ST2405344",
      "KB-237849",
      "AX8219172",
      "AB0098071",
      "DB-079951",
      "TC-149394",
      "AJ-114863",
      "4CH-017501",
    ],
  },
  {
    primary_id: "D01UWZ",
    names: ["PMID16242323C22c"],
  },
  {
    primary_id: "D01UXC",
    names: [
      "Atenolol",
      "Aircrit",
      "Alinor",
      "Altol",
      "Anselol",
      "Antipressan",
      "Atcardil",
      "Atecard",
      "Atehexal",
      "Atenblock",
      "Atendol",
      "Atenet",
      "Ateni",
      "Atenil",
      "Atenol",
      "Atenolin",
      "Atenololum",
      "Atenomel",
      "Atereal",
      "Aterol",
      "Betablok",
      "Betacard",
      "Betasyn",
      "Blocotenol",
      "Blokium",
      "Cardaxen",
      "Cardiopress",
      "Corotenol",
      "Cuxanorm",
      "Duraatenolol",
      "Duratenol",
      "Evitocor",
      "Farnormin",
      "Hipres",
      "Hypoten",
      "Ibinolo",
      "Internolol",
      "Jenatenol",
      "Juvental",
      "Loten",
      "Lotenal",
      "Myocord",
      "Neatenol",
      "Normalol",
      "Normiten",
      "Noten",
      "Oraday",
      "Ormidol",
      "Panapres",
      "Plenacor",
      "Premorine",
      "Prenolol",
      "Prenormine",
      "Prinorm",
      "Selobloc",
      "Serten",
      "Servitenol",
      "Stermin",
      "Tenidon",
      "Tenobloc",
      "Tenoblock",
      "Tenolol",
      "Tenoprin",
      "Tenormin",
      "Tenormine",
      "Tensimin",
      "Tensotin",
      "Tredol",
      "Unibloc",
      "Uniloc",
      "Vascoten",
      "Vericordin",
      "Wesipin",
      "Xaten",
      "Atenol AL",
      "Atenol Atid",
      "Atenol Cophar",
      "Atenol Fecofar",
      "Atenol GNR",
      "Atenol Gador",
      "Atenol Genericon",
      "Atenol Heumann",
      "Atenol MSD",
      "Atenol NM Pharma",
      "Atenol Nordic",
      "Atenol PB",
      "Atenol Quesada",
      "Atenol Stada",
      "Atenol Tika",
      "Atenol Trom",
      "Atenol Acis",
      "Atenol Ct",
      "Atenol Von Ct",
      "Betatop Ge",
      "Scheinpharm Atenol",
      "Seles Beta",
      "Tenormine [French]",
      "A 7655",
      "Atenol 1A Pharma",
      "Apo-Atenolol",
      "Atenol-Mepha",
      "Atenol-Wolff",
      "Atenol-Ratiopharm",
      "Atenololum [INN-Latin]",
      "Felo-Bits",
      "Lo-Ten",
      "Teno-Basan",
      "Tenormin (TN)",
      "Atenolol (JP15/USP/INN)",
      "Atenolol [USAN:INN:BAN:JAN]",
      "Acetamide,2-(P-(2-Hydroxy-3-(Isopropylamino)Propoxy)Phenyl)",
      "(+-)-4-(2-Hydroxy-3-Isopropylaminopropoxy)Phenylacetamide",
      "(+-)-Atenolol",
      "(+/-)-4-[2-Hydroxy-3-[(1-Methylethyl)Amino]propoxy]benzeneacetamide",
      "(1)-2-(4-(2-Hydroxy-3-(Isopropylamino)Propoxy)Phenyl)Acetamide",
      "(Inverted Question Mark)-Atenolol",
      "1-P-Carbamoylmethylphenoxy-3-Isopropylamino-2-Propanol",
      "2-(4-(2-Hydroxy-3-Isopropylaminopropoxy)Phenyl)Acetamid",
      "2-(4-{2-Hydroxy-3-[(Propan-2-Yl)Amino]propoxy}phenyl)Acetamide",
      "2-(P-(2-Hydroxy-3-(Isopropylamino)Propoxy)Phenyl)Acetamide",
      "2-[4-(2-Hydroxy-3-Isopropylaminopropoxy)Phenyl]acetamide",
      "2-[4-({2-Hydroxy-3-[(1-Methylethyl)Amino]propyl}oxy)Phenyl]acetamide",
      "2-[4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl]acetamide",
      "2-{4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl}acetamide",
      "4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)Benzeneacetamide",
      "4-[2'-Hydroxy-3'-(Isopropylamino)Propoxy]phenylacetamide",
      "4-[2-Hydroxy-3-(Isopropylamino)-Propoxy]phenylacetamide",
    ],
  },
  {
    primary_id: "D01UXJ",
    names: ["Resminostat"],
  },
  {
    primary_id: "D01UXP",
    names: ["LASSBio-294"],
  },
  {
    primary_id: "D01UXW",
    names: ["Morphine-6-Glucuronide"],
  },
  {
    primary_id: "D01UYA",
    names: ["MEDI1341"],
  },
  {
    primary_id: "D01UYI",
    names: [
      "CHRYSIN",
      "Chrysin",
      "480-40-0",
      "5,7-Dihydroxyflavone",
      "Chrysine",
      "5,7-Dihydroxy-2-Phenyl-4H-Chromen-4-One",
      "Crysin",
      "5,7-Dihydroxy-2-Phenylchromen-4-One",
      "4H-1-Benzopyran-4-One, 5,7-Dihydroxy-2-Phenyl-",
      "UNII-3CN01F5ZJ5",
      "NSC-407436",
      "FLAVONE, 5,7-DIHYDROXY-",
      "EINECS 207-549-7",
      "NSC407436",
      "5,7-Dihydroxy-2-Phenyl-4H-1-Benzopyran-4-One",
      "CHEMBL117",
      "NSC 407436",
      "5,7-Dihydroxy-2-Phenyl-Chromen-4-One",
      "BRN 0233276",
      "3CN01F5ZJ5",
      "CHEBI:75095",
      "RTIXKCRFFJGDFG-UHFFFAOYSA-N",
      "5,7-Dihydroxy-2-Phenyl-4H-Benzo(B)Pyran-4-One",
      "MFCD00006834",
      "Chrysin, 99+%",
      "CAS-480-40-0",
    ],
  },
  {
    primary_id: "D01UYS",
    names: [
      "5-(6,7-Dimethoxy-Quinolin-3-Yl)-1H-Pyridin-2-One",
      "CHEMBL66981",
      "SCHEMBL8176516",
      "ZINC9633633",
      "BDBM50039067",
    ],
  },
  {
    primary_id: "D01UZH",
    names: ["IQP-0528"],
  },
  {
    primary_id: "D01UZT",
    names: ["4-(4-Chlorophenyl)-1-Pyrid-2-Yl-Pyrazole", "CHEMBL206705"],
  },
  {
    primary_id: "D01VAA",
    names: ["ACR-125", "Anti-Inflammatory Agent (Psoriasis), Acretia"],
  },
  {
    primary_id: "D01VAB",
    names: [
      "Alprenolol",
      "Alfeprol",
      "Alpheprol",
      "Alprenololum",
      "Yobir",
      "Alfeprol [Russian]",
      "Alprenolol (INN)",
      "Alprenolol [INN:BAN]",
      "Alprenololum [INN-Latin]",
      "Apllobal (TN)",
      "Aptine (TN)",
      "Aptol (TN)",
      "Duriles (TN)",
      "Gubernal (TN)",
      "Regletin (TN)",
      "Yobir (TN)",
      "H-56-28",
      "1-(2-Allylphenoxy)-3-Isopropylamino-2-Propanol",
      "1-(O-Allylphenoxy)-3-(Isopropylamino)-2-Propanol",
      "1-(O-Allylphenoxy)-3-Isopropylamino)-2-Propanol",
      "1-(Propan-2-Ylamino)-3-(2-Prop-2-Enylphenoxy)Propan-2-Ol",
      "1-[2-(Propen-2-Ylphenoxy)]-3-(Isopropylamino)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D01VAP",
    names: ["YM-430"],
  },
  {
    primary_id: "D01VAU",
    names: [
      "1,2-Bis-(3-Methoxy-Phenyl)-Ethane-1,2-Dione",
      "40101-17-5",
      "3,3'-Dimethoxybenzil",
      "1,2-Bis(3-Methoxyphenyl)Ethane-1,2-Dione",
      "Ethanedione, Bis(3-Methoxyphenyl)-",
      "1,2-Ethanedione, 1,2-Bis(3-Methoxyphenyl)-",
      "PJGXOGKIVAJFTE-UHFFFAOYSA-N",
      "MFCD00038221",
      "Bis(3-Methoxyphenyl)Ethanedione",
      "EINECS 254-793-5",
      "ACMC-20a2cz",
      "3,3'-Dimethoxy Benzil",
      "AC1Q5BUM",
      "AC1L3WPM",
      "3,3'-Dimethoxydibenzoyl",
      "Bis(3-Methoxyphenyl)Glyoxal",
      "Benzil-Based Compound, 22",
      "CHEMBL435109",
      "SCHEMBL2156482",
      "3,3'-Dimethoxybenzil, 99%",
      "DTXSID7068213",
      "BDBM22744",
      "CTK4I2455",
      "3,3''-Dimethoxybenzil, 99+%",
      "ZINC2168289",
      "5327AB",
    ],
  },
  {
    primary_id: "D01VBU",
    names: ["ISIS 28466"],
  },
  {
    primary_id: "D01VCB",
    names: ["ACH-2928"],
  },
  {
    primary_id: "D01VCE",
    names: ["Gadomelitol"],
  },
  {
    primary_id: "D01VCS",
    names: ["1,2-Bis(2,6-Difluorophenyl)Ethane-1,2-Dione", "CHEMBL242288"],
  },
  {
    primary_id: "D01VCW",
    names: ["MEN-10690"],
  },
  {
    primary_id: "D01VDB",
    names: ["Howiinol A (GHM-10)"],
  },
  {
    primary_id: "D01VDI",
    names: ["AVI-5126"],
  },
  {
    primary_id: "D01VEJ",
    names: ["Elapegademase", "Elapegademase [INN]", "UNII-9R3D3Y0UHS", "Elapegademase [USAN:INN]", "9R3D3Y0UHS"],
  },
  {
    primary_id: "D01VEP",
    names: [
      "N-Cetylgallate",
      "Cetyl Gallate",
      "5026-65-3",
      "Hexadecyl Gallate",
      "Hexadecyl 3,4,5-Trihydroxybenzoate",
      "Gallic Acid Cetyl Ester",
      "N-Hexadecyl-Gallate",
      "UNII-X1J85N35ZO",
      "CHEMBL464675",
      "X1J85N35ZO",
      "3,4,5-Trihydroxybenzoic Acid Hexadecyl Ester",
      "Cetylgallat",
      "HexadecylGallate",
      "EINECS 225-715-7",
      "ACMC-209kkz",
      "AC1L2VUG",
      "AC1Q67EH",
      "Gallic Acid Hexadecyl Ester",
      "SCHEMBL36506",
      "Benzoic Acid, 3,4,5-Trihydroxy-, Hexadecyl Ester",
      "CTK8B1762",
      "DTXSID00198254",
      "MolPort-006-116-598",
      "TYCUSKFOGZNIBO-UHFFFAOYSA-N",
      "KS-000013SR",
      "BDBM50250973",
      "ANW-30945",
      "ZINC33861467",
    ],
  },
  {
    primary_id: "D01VEU",
    names: [
      "9-Ethyl-8-Phenylethynyl-9H-Purin-6-Ylamine",
      "CHEMBL61259",
      "8-Phenylethynyl-9-Ethyladenine",
      "BDBM50102169",
    ],
  },
  {
    primary_id: "D01VEV",
    names: ["Cannabinoids", "Cannabinoids (Schizophrenia)"],
  },
  {
    primary_id: "D01VFQ",
    names: [
      "CGEN-855",
      "CGEN-855A",
      "CGEN-855B",
      "FPRL1 G-Protein-Coupled Receptor Agonists (Peptide, Inflammation/Cardiac Disorder)",
      "FPRL1 G-Protein-Coupled Receptor Agonists (Peptide, Inflammation/Cardiac Disorder), Compugen",
    ],
  },
  {
    primary_id: "D01VFR",
    names: ["PD-168393"],
  },
  {
    primary_id: "D01VGQ",
    names: ["MG-1101"],
  },
  {
    primary_id: "D01VHD",
    names: ["PMID29865878-Compound-41"],
  },
  {
    primary_id: "D01VIA",
    names: [
      "(4-Benzhydrylpiperazin-1-Yl)(Cyclohexyl)Methanone",
      "CHEMBL1088730",
      "AC1LKN75",
      "Oprea1_813517",
      "Oprea1_627428",
      "AC1Q5F91",
      "MolPort-001-543-551",
      "STK413456",
      "BDBM50312578",
      "ZINC19834645",
      "AKOS003287837",
      "MCULE-5141193554",
      "ST50927985",
      "4-(Diphenylmethyl)Piperazinyl Cyclohexyl Ketone",
      "(4-Benzhydrylpiperazin-1-Yl)-Cyclohexylmethanone",
      "1-Cyclohexanecarbonyl-4-(Diphenylmethyl)Piperazine",
    ],
  },
  {
    primary_id: "D01VIL",
    names: ["Hypertension NCE"],
  },
  {
    primary_id: "D01VIX",
    names: [
      "PMID 24997608CA",
      "1599477-75-4",
      "GPR120-IN-1",
      "GPR120 Compound A",
      "GTPL8418",
      "MolPort-039-136-860",
      "EX-A1318",
      "BCP19379",
      "BDBM50208095",
      "ZINC209053267",
      "AKOS025289518",
      "CS-6429",
      "HY-101492",
    ],
  },
  {
    primary_id: "D01VJN",
    names: ["Olanzapine/Fluoxetine Combination"],
  },
  {
    primary_id: "D01VJR",
    names: ["PMID27551786-Compound-138"],
  },
  {
    primary_id: "D01VJT",
    names: [
      "1-Amino-2,4-Bis(Phenylthio)Anthracene-9,10-Dione",
      "CHEMBL600009",
      "ChemDiv1_000050",
      "AC1MZ0W8",
      "SCHEMBL13944330",
      "HMS587C06",
      "MolPort-003-717-430",
      "ZINC4421222",
      "BDBM50306772",
      "AKOS001588262",
      "MCULE-8570323585",
      "1-Amino-2,4-Bis(Phenylthio)Anthra-9,10-Quinone",
    ],
  },
  {
    primary_id: "D01VKE",
    names: [
      "ITdU",
      "3-Hydroxyandrost-5-En-17-One",
      "Diandron, 17-Hormoforin",
      "A-Hydroxy-5-Androsten-17-One",
      "Spectrum_000661",
      "SpecPlus_000094",
      "Androst-5-En-17-One, 3-Hydroxy-, (3.beta.)-",
      "Spectrum3_000116",
      "Spectrum5_000130",
      "Spectrum4_001395",
      "Spectrum2_000359",
      "AC1L18FD",
      "KBioSS_001141",
      "KBioGR_001750",
      "CHEMBL31399",
      "DivK1c_006190",
      "SPBio_000457",
      "SCHEMBL9969169",
      "KBio1_001134",
      "KBio3_000872",
      "KBio2_006277",
      "KBio2_003709",
      "KBio2_001141",
      "CHEBI:95212",
      "MolPort-003-891-893",
      "ALBB-023670",
      "CCG-38634",
      "Androst-5-En-17-One, 3-Hydroxy-",
    ],
  },
  {
    primary_id: "D01VKI",
    names: ["Physalaemin"],
  },
  {
    primary_id: "D01VLQ",
    names: [
      "15-Hydroxyeicosatetraenoic Acid",
      "Eicosatetraenoic Acid, 15-Hydroxy-",
      "AC1O5OKC",
      "SCHEMBL462602",
      "LS-63796",
      "(2E,4E,6E,8E)-15-Hydroxyicosa-2,4,6,8-Tetraenoic Acid",
    ],
  },
  {
    primary_id: "D01VLT",
    names: ["ASP8232"],
  },
  {
    primary_id: "D01VLV",
    names: ["AG-11/03", "AG-11/03 [benzimidazole Derivative]", "STK220090"],
  },
  {
    primary_id: "D01VMA",
    names: ["ITX-4520"],
  },
  {
    primary_id: "D01VMN",
    names: ["PMID29865878-Compound-53"],
  },
  {
    primary_id: "D01VMO",
    names: ["Flutrimazole"],
  },
  {
    primary_id: "D01VMU",
    names: ["C[CO-(CH2)3-CO-Pro-D-Phe-Arg-Trp-Lys]-NH2", "CHEMBL398664"],
  },
  {
    primary_id: "D01VMZ",
    names: ["ARI-3037MO"],
  },
  {
    primary_id: "D01VNL",
    names: ["JM-1232(-)"],
  },
  {
    primary_id: "D01VNN",
    names: ["NSC-622444"],
  },
  {
    primary_id: "D01VNQ",
    names: [
      "AEM-18",
      "Apo-E Peptide Mimetic (Familial Hypercholesterolemia, Type III Hyperlipidemia), University Of Alabama At Birmingham/LipimetiX",
    ],
  },
  {
    primary_id: "D01VNT",
    names: ["COL-003"],
  },
  {
    primary_id: "D01VOJ",
    names: ["RTI-4229-982"],
  },
  {
    primary_id: "D01VOU",
    names: ["2,2',4,4',6'-Pentahydroxychalcone", "CHEMBL244249", "BDBM50203986"],
  },
  {
    primary_id: "D01VPK",
    names: ["FK-453"],
  },
  {
    primary_id: "D01VPR",
    names: ["BBR-3438"],
  },
  {
    primary_id: "D01VPZ",
    names: ["2-P-Tolyl-4H-Chromene-4-Thione", "CHEMBL582494", "4'-Methylthioflavone", "BDBM50310183"],
  },
  {
    primary_id: "D01VQA",
    names: ["VAL BRO 03"],
  },
  {
    primary_id: "D01VQM",
    names: ["CHR-5154"],
  },
  {
    primary_id: "D01VRB",
    names: ["4,5-Bis(4-Chlorophenyl)Isothiazole", "CHEMBL491088"],
  },
  {
    primary_id: "D01VRQ",
    names: ["BAY-1902607a"],
  },
  {
    primary_id: "D01VSI",
    names: ["Kisspeptin-10"],
  },
  {
    primary_id: "D01VSK",
    names: ["TR-14531"],
  },
  {
    primary_id: "D01VSM",
    names: ["6-Benzyl-2-Isobutylsulfanyl-3H-Pyrimidin-4-One"],
  },
  {
    primary_id: "D01VSR",
    names: ["Hepatitis C Immune Globulin"],
  },
  {
    primary_id: "D01VTT",
    names: ["P-Coumaric Acid Derivative 6"],
  },
  {
    primary_id: "D01VUE",
    names: [
      "BMS-182874",
      "Bms 182874",
      "5-Ddins",
      "153042-42-3",
      "CHEMBL267458",
      "5-(Dimethylamino)-N-(3,4-Dimethyl-5-Isoxazolyl)-1-Naphthalenesulfonamide",
      "1-Naphthalenesulfonamide, 5-(Dimethylamino)-N-(3,4-Dimethyl-5-Isoxazolyl)-",
      "Tocris-1441",
      "AC1L3UAG",
      "SCHEMBL802730",
      "ZINC5803",
      "CHEBI:92993",
      "DTXSID60165205",
      "BDBM50034435",
      "NCGC00025164-01",
      "LS-173440",
      "L009159",
      "BRD-K56509348-003-01-3",
      "5-Dimethylamino-Naphthalene-1-Sulfonic Acid (3,4-Dimethyl-Isoxazol-5-Yl)-Amide",
      "5-Dimethylamino-Naphthalene-1-Sulfonic Acid (3,4-Dimethyl-2,3-Dihydro-Oxazol-5-Yl)-Amide",
    ],
  },
  {
    primary_id: "D01VUY",
    names: ["[N40,Pro1,Tyr4,Nle 14]BB"],
  },
  {
    primary_id: "D01VVL",
    names: ["PF-04523655"],
  },
  {
    primary_id: "D01VVM",
    names: ["TNP-ATP", "2',3'-O-(2,4,6-Trinitrophenyl)-ATP"],
  },
  {
    primary_id: "D01VWB",
    names: ["IL-2/Gene-Modified Lymphocytes"],
  },
  {
    primary_id: "D01VWD",
    names: ["HTI-101"],
  },
  {
    primary_id: "D01VWR",
    names: ["S-15261"],
  },
  {
    primary_id: "D01VXC",
    names: [
      "N-(4,6-Diphenylpyrimidin-2-Yl)Propionamide",
      "CHEMBL222718",
      "820961-64-6",
      "SCHEMBL6505603",
      "CTK3E2644",
      "DTXSID70461023",
      "BDBM50157663",
      "ZINC13586469",
      "N-(4,6-Diphenylpyrimidine-2-Yl)Propanamide",
      "Propanamide, N-(4,6-Diphenyl-2-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D01VXP",
    names: ["2-Ethynyl-N6-Methoxyadenosine", "CHEMBL374019", "SCHEMBL4389749"],
  },
  {
    primary_id: "D01VZE",
    names: ["PMID25435285-Compound-16"],
  },
  {
    primary_id: "D01VZL",
    names: ["PSN632408"],
  },
  {
    primary_id: "D01VZQ",
    names: ["LG-723"],
  },
  {
    primary_id: "D01WAI",
    names: ["DEBROMOHYMENIALDISINE"],
  },
  {
    primary_id: "D01WAJ",
    names: ["PMID27828716-Compound-21"],
  },
  {
    primary_id: "D01WBD",
    names: ["Mibampator"],
  },
  {
    primary_id: "D01WBT",
    names: ["DYN-15", "DYN-12", "RAGE Inhibitors (Diabetic Kidney Disease/Blindness), Dynamis Therapeutics"],
  },
  {
    primary_id: "D01WCA",
    names: [
      "2-(4-HYDROXY-PHENYL)BENZOFURAN-5-OL",
      "2-(4-Hydroxy-Phenyl)Benzofuran-5-OL",
      "2-(4-Hydroxy-Phenyl)-Benzofuran-5-Ol",
      "2-(4-Hydroxyphenyl)-1-Benzofuran-5-Ol",
      "CHEMBL367588",
      "52814-86-5",
      "2-(4-Hydroxyphenyl)Benzofuran-5-Ol",
      "AC1LCVQT",
      "1u9e",
      "SCHEMBL3922677",
      "CTK1G1983",
      "DTXSID60349669",
      "SNNNDCMXZYWCCI-UHFFFAOYSA-N",
      "ZINC3816519",
      "BDBM50152627",
      "SBB096959",
      "AKOS030555876",
      "DB07032",
      "2-(4-Hydroxyphenyl)Benzo[b]furan-5-Ol",
      "5-Benzofuranol,",
    ],
  },
  {
    primary_id: "D01WCL",
    names: ["SLV 354"],
  },
  {
    primary_id: "D01WCU",
    names: ["4-Aryl Quinol Derivative 4"],
  },
  {
    primary_id: "D01WCW",
    names: ["KPPF-1"],
  },
  {
    primary_id: "D01WDB",
    names: [
      "S-2238",
      "D-Phenylalanyl-L-2-Piperidinecarbonyl-N-(4-Nitro Phenyl)-L-Argininamide",
      "H-D-Phe-Pip-Arg-Pna",
      "Chromogenic Substrate S-2238",
      "D-Phe-Pip-Arg-PNA",
      "D-F-Pip-R-PNA",
      "H-Phe-Pip-Arg-Pna",
      "D-Phe-Pip-Arg-Paranitroanilide",
      "H-Phe-Pip-Arg-P-Nitroanilide",
      "S 2238",
      "H-D-Phenylalanyl-Pip-Arg-P-Nitroanilide",
      "AC1Q5KFD",
      "AC1L3XF1",
      "H-D-Phenylalanyl-L-Pipecolyl-Arginine-Nitroanilide",
      "(2s)-N-{(2s)-5-[(Diaminomethylidene)Amino]-1-[(4-Nitrophenyl)Amino]-1-Oxopentan-2-Yl}-1-(D-Phenylalanyl)Piperidine-2-Carboxamidato(3-)",
      "BDBM12678",
      "YDMBNDUHUNWWRP-VJBWXMMDSA-N",
      "BDBM233013",
      "ZINC14950391",
    ],
  },
  {
    primary_id: "D01WDI",
    names: ["Benzyl-Quinazolin-4-Yl-Amine"],
  },
  {
    primary_id: "D01WDS",
    names: ["4-(Cyclohexylamino)-N-(Quinolin-3-Yl)Benzamide", "CHEMBL406573", "SCHEMBL846014"],
  },
  {
    primary_id: "D01WFI",
    names: ["VG-FLU"],
  },
  {
    primary_id: "D01WGJ",
    names: [
      "Daidzin",
      "AC1N4RYA",
      "MLS000563445",
      "SCHEMBL3423674",
      "CHEMBL115316",
      "KYQZWONCHDNPDP-UHFFFAOYSA-N",
      "HMS3604G15",
      "HMS3348J07",
      "HMS2194K16",
      "AKOS024284500",
      "AC-6036",
      "MCULE-3802707688",
      "SMR000232339",
      "ST077114",
      "SR-01000721602",
      "SR-01000721602-3",
      "I06-0227",
      "3-(4-Hydroxyphenyl)-7-[3,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxychromen-4-One",
      "4H-1-Benzopyran-4-One, 7-(.beta.-D-Glucopyranosyloxy)-3-(4-Hydroxyphenyl)-",
      "3-(4-Hydroxyphenyl)-7-{[3,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxy}-4H-Chromen-4-One",
    ],
  },
  {
    primary_id: "D01WHC",
    names: ["Leuvectin"],
  },
  {
    primary_id: "D01WHI",
    names: ["L023103"],
  },
  {
    primary_id: "D01WIH",
    names: [
      "(4-Fluoro-Phenyl)-(9H-Purin-6-Yl)-Amine",
      "73663-95-3",
      "N-(P-Fluorophenyl)Adenine",
      "(4-Fluorophenyl)(9H-Purin-6-Yl)Amine",
      "NSC 21554",
      "N-(4-Fluorophenyl)-9H-Purin-6-Amine",
      "7H-ADENINE, N-(P-FLUOROPHENYL)-",
      "N-(4-Fluorophenyl)-7H-Purin-6-Amine",
      "1H-Purin-6-Amine, N-(4-Fluorophenyl)-",
      "CHEMBL361227",
      "N-(4-Fluorophenyl)-1H-Purin-6-Amine",
      "W-203680",
      "AC1L1C3G",
      "6-(4-Fluoroanilino)Purine",
      "Oprea1_396984",
      "SCHEMBL4922514",
      "N-(P-Fluorophenyl)-1H-Adenine",
      "CTK5D8432",
      "(4-Fluorophenyl)Purin-6-Ylamine",
      "DTXSID30223881",
      "MolPort-000-384-310",
      "NSC21554",
    ],
  },
  {
    primary_id: "D01WJL",
    names: [
      "Aminosalicylic Acid",
      "4-Aminosalicylic Acid",
      "65-49-6",
      "P-AMINOSALICYLIC ACID",
      "Rezipas",
      "Aminopar",
      "Pamisyl",
      "Parasalindon",
      "Ferrosan",
      "Deapasil",
      "Apacil",
      "Parasal",
      "Paser",
      "Paramycin",
      "Gabbropas",
      "Parasalicil",
      "Pasnodia",
      "Osacyl",
      "Aminox",
      "Pasolac",
      "Pasmed",
      "Propasa",
      "Pasalon",
      "Entepas",
      "Pasdium",
      "Pasara",
      "Pamacyl",
      "Pasem",
      "Pasa",
      "2-Hydroxy-4-Aminobenzoic Acid",
      "PASK",
      "APAS",
      "Para-Pas",
      "Sanipirol-4",
      "Hellipidyl",
      "Pascorbic",
      "PAS-C",
      "Benzoic Acid, 4-Amino-2-Hydroxy-",
      "PAS (Acid)",
      "Aminosalicylic Acid Resin Complex",
      "Aminosalicylate Sodium",
    ],
  },
  {
    primary_id: "D01WJM",
    names: ["D-26344"],
  },
  {
    primary_id: "D01WJV",
    names: ["F-0401"],
  },
  {
    primary_id: "D01WJZ",
    names: ["MAZAPERTINE"],
  },
  {
    primary_id: "D01WKE",
    names: ["Semapimod", "CPSI-2364", "Semapimod (Oral)", "Semapimod (Oral), Cytokine PharmaSciences"],
  },
  {
    primary_id: "D01WKR",
    names: ["EGlu", "EGLU", "(S)-Alpha-Ethylglutamate"],
  },
  {
    primary_id: "D01WKZ",
    names: [
      "Citarinostat",
      "ACY-241",
      "1316215-12-9",
      "HDAC-IN-2",
      "2-((2-Chlorophenyl)(Phenyl)Amino)-N-(7-(Hydroxyamino)-7-Oxoheptyl)Pyrimidine-5-Carboxamide",
      "UNII-441P620G3P",
      "441P620G3P",
      "Citarinostat [USAN]",
      "Citarinostat (USAN)",
      "2-(N-(2-Chlorophenyl)Anilino)-N-[7-(Hydroxyamino)-7-Oxoheptyl]pyrimidine-5-Carboxamide",
      "2-((2-Chlorophenyl)Phenylamino)-N-(7-(Hydroxyamino)-7-Oxoheptyl)-5-Pyrimidinecarboxamide",
      "2-[(2-Chlorophenyl)Phenylamino]-N-[7-(Hydroxyamino)-7-Oxoheptyl]-5-Pyrimidinecarboxamide",
      "Citarinostat (ACY-241)",
      "SCHEMBL2225863",
      "GTPL942",
    ],
  },
  {
    primary_id: "D01WLB",
    names: ["Lu-AA39959"],
  },
  {
    primary_id: "D01WLC",
    names: [
      "Metolazone",
      "Diulo",
      "Metalazone",
      "Metalozone",
      "Metenix",
      "Metolazona",
      "Metolazonum",
      "Microx",
      "Mykrox",
      "Oldren",
      "Xuret",
      "Zaroxolyn",
      "Metolazona [INN-Spanish]",
      "Metolazonum [INN-Latin]",
      "Mykrox (TN)",
      "SR 720-22",
      "Zaroxolyn (TN)",
      "SR-720-22",
      "Metolazone (JAN/USP/INN)",
      "Metolazone [USAN:BAN:INN:JAN]",
      "Mykrox, Zaroxolyn, Diulo, Metolazone",
      "2-Methyl-3-O-Tolyl-6-Sulfamyl-7-Chloro-1,2,3,4-Tetrahydro-4-Quinazolinone",
      "7-Chloro-1,2,3,4-Tetrahydro-2-Methyl-3-(2-Methylphenyl)-4-Oxo-6-Quinazolinesulfonamide",
      "7-Chloro-1,2,3,4-Tetrahydro-2-Methyl-4-Oxo-3-O-Tolyl-6-Quinazolinesulfonamide",
      "7-Chloro-2-Methyl-3-(2-Methylphenyl)-4-Oxo-1,2-Dihydroquinazoline-6-Sulfonamide",
    ],
  },
  {
    primary_id: "D01WLE",
    names: ["MC-1101"],
  },
  {
    primary_id: "D01WLL",
    names: [
      "N-(3-(Phenylthio)Pyridin-4-Yl)Methanesulfonamide",
      "CHEMBL224122",
      "N-(3-Phenylsulfanyl-4-Pyridinyl)Methanesulfonamide",
      "833455-58-6",
      "CTK3D2868",
      "DTXSID10466638",
      "BDBM50158452",
      "Methanesulfonamide, N-[3-(Phenylthio)-4-Pyridinyl]-",
    ],
  },
  {
    primary_id: "D01WMI",
    names: ["SP-1031C"],
  },
  {
    primary_id: "D01WMJ",
    names: ["Copper", "Cu-7", "Paragard T 380a", "Tatum-T"],
  },
  {
    primary_id: "D01WMO",
    names: ["Tularemia Vaccine"],
  },
  {
    primary_id: "D01WMU",
    names: ["1-(Dio-Tolylmethyl)-4-Phenylpiperidin-4-Ol", "CHEMBL389665"],
  },
  {
    primary_id: "D01WMW",
    names: ["RG7232"],
  },
  {
    primary_id: "D01WNA",
    names: [
      "8-Bromo-9-Ethyl-9H-Adenine",
      "8-Bromo-9-Ethyladenine",
      "CHEMBL468633",
      "21031-79-8",
      "AC1NRVH3",
      "SCHEMBL1755068",
      "ANR-82",
      "8-Bromo-9-Ethylpurin-6-Amine",
    ],
  },
  {
    primary_id: "D01WNO",
    names: [
      "7-Chloro-1,2,3,4-Tetrahydro-Pyrazino[1,2-A]indole",
      "7-Chloro-1H,2H,3H,4H-Pyrazino[1,2-A]indole",
      "CHEMBL290779",
      "287384-61-6",
      "7-Chloro-1,2,3,4-Tetrahydropyrazino[1,2-A]indole",
      "SCHEMBL5619809",
      "PSLRQWHMXFDQNK-UHFFFAOYSA-N",
      "MolPort-022-445-854",
      "BDBM50108315",
      "ZINC13586841",
      "AKOS022692453",
      "NE33360",
    ],
  },
  {
    primary_id: "D01WOD",
    names: ["PF-4064900"],
  },
  {
    primary_id: "D01WPJ",
    names: [
      "Duroquinone",
      "527-17-3",
      "Tetramethyl-1,4-Benzoquinone",
      "Tetramethyl-P-Benzoquinone",
      "Tetramethylquinone",
      "2,3,5,6-Tetramethyl-1,4-Benzoquinone",
      "2,3,5,6-Tetramethylcyclohexa-2,5-Diene-1,4-Dione",
      "Tetramethyl-P-Quinone",
      "2,3,5,6-Tetramethylbenzoquinone",
      "P-Benzoquinone, Tetramethyl-",
      "P-Benzoquinone, 2,3,5,6-Tetramethyl-",
      "2,3,5,6-Tetramethyl-P-Benzoquinone",
      "2,5-Cyclohexadiene-1,4-Dione, 2,3,5,6-Tetramethyl-",
      "NSC 2068",
      "CCRIS 2989",
      "P-Benzoquinone, Tetramethyl-, Semiquinone",
      "UNII-X0Q8791R69",
      "EINECS 208-409-8",
    ],
  },
  {
    primary_id: "D01WQF",
    names: [
      "FPYVAE Peptide",
      "CHEMBL1165259",
      "FPYVAE",
      "GTPL3024",
      "BDBM50321107",
      "FPYVAE Peptide [PMID: 20494585]",
      "(2S)-2-[(2S)-2-[(2S)-2-[(2S)-2-{[(2S)-1-[(2S)-2-Amino-3-Phenylpropanoyl]pyrrolidin-2-Yl]formamido}-3-(4-Hydroxyphenyl)Propanamido]-3-Methylbutanamido]propanamido]pentanedioic Acid",
    ],
  },
  {
    primary_id: "D01WQL",
    names: ["MPC-9528"],
  },
  {
    primary_id: "D01WQO",
    names: ["BIRT 2584"],
  },
  {
    primary_id: "D01WQQ",
    names: ["ABT-981"],
  },
  {
    primary_id: "D01WQX",
    names: ["Imidazopyridine Derivative 2"],
  },
  {
    primary_id: "D01WRT",
    names: ["NSC-300853"],
  },
  {
    primary_id: "D01WSL",
    names: ["NPB-1"],
  },
  {
    primary_id: "D01WTQ",
    names: [
      "MANGOSTANIN",
      "9-Hydroxycalabaxanthone",
      "Mangostanin",
      "35349-68-9",
      "CHEMBL561643",
      "Q-100424",
      "5,9-Dihydroxy-8-Methoxy-2,2-Dimethyl-7-(3-Methylbut-2-Enyl)Pyrano[3,2-B]xanthen-6-One",
      "AC1NUY37",
      "SCHEMBL5617031",
      "MolPort-029-887-080",
      "ZINC13382495",
      "BDBM50311741",
      "AKOS032962410",
      "W2653",
      "2H,6H-Pyrano[3,2-B]xanthen-6-One,5,9-Dihydroxy-8-Methoxy-2,2-Dimethyl-7-(3-Methyl-2-Butenyl)-",
      "1,6-Dihydroxy-7-Methoxy-8-(3-Methylbut-2-Enyl)-6'',6''-Dimethylpyrano(2'',3'':3,2)Xanthone",
    ],
  },
  {
    primary_id: "D01WTV",
    names: ["HDL-200"],
  },
  {
    primary_id: "D01WUA",
    names: ["Treprostinil"],
  },
  {
    primary_id: "D01WUT",
    names: ["Enfortumab Vedotin", "ASG-2ME"],
  },
  {
    primary_id: "D01WVE",
    names: ["KB-130015"],
  },
  {
    primary_id: "D01WVJ",
    names: [
      "BIO-306",
      "AC1L1H3K",
      "CTK6E1327",
      "CCG-204816",
      "NCGC00162232-03",
      "NCGC00162232-02",
      "NCGC00162232-01",
      "AB00513961",
      "AB00513961_07",
      "4-[(3,4-Dichlorobenzoyl)Amino]-5-(Dipentylamino)-5-Oxopentanoate",
    ],
  },
  {
    primary_id: "D01WVV",
    names: ["[35S]ibutamoren", "[35S]MK-0677"],
  },
  {
    primary_id: "D01WWC",
    names: ["N-Methyllaurotetanine"],
  },
  {
    primary_id: "D01WWD",
    names: ["KPS-0373"],
  },
  {
    primary_id: "D01WWK",
    names: [
      "(2E, 6E)-Farnesylbisphosphonate",
      "GTPL3200",
      "SCHEMBL8916061",
      "SCHEMBL2875241",
      "(E,e)-(4,8,12-Trimethyl-3,7,11-Tridecatrienylidene)Bisphosphonic Acid",
      "[(3E,7E)-4,8,12-Trimethyl-1-Phosphonotrideca-3,7,11-Trien-1-Yl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D01WWN",
    names: ["Pyrazole Derivative 57"],
  },
  {
    primary_id: "D01WWO",
    names: ["ML290"],
  },
  {
    primary_id: "D01WXA",
    names: ["TMB-8"],
  },
  {
    primary_id: "D01WXB",
    names: [
      "Monothioglycerol",
      "3-Mercapto-1,2-Propanediol",
      "1-Thioglycerol",
      "96-27-5",
      "THIOGLYCEROL",
      "3-Mercaptopropane-1,2-Diol",
      "Thioglycerine",
      "Thioglycerin",
      "Thiovanol",
      "Alpha-Thioglycerol",
      "Glycerol-1-Thiol",
      "1-Monothioglycerol",
      "1-Mercaptoglycerol",
      "Monothioglycerin",
      "2,3-Dihydroxypropanethiol",
      "Alpha-Thiolglycerol",
      "3-Sulfanylpropane-1,2-Diol",
      "1-Mercapto-2,3-Propanediol",
      "Glycerol, 1-Thio-",
      "Alpha-Monothioglycerol",
      "1-Thio-2,3-Propanediol",
      "USAF CB-37",
      "USAF B-40",
      "3-Mercapto-1,2-Propanediole",
      "1,2-Propanediol, 3-Mercapto-",
      "NSC 5370",
      ".alpha.-Thioglycerol",
    ],
  },
  {
    primary_id: "D01WXR",
    names: [
      "N-(4,5-Diphenylpyrimidin-2-Yl)Acetamide",
      "CHEMBL225244",
      "58413-41-5",
      "CTK1E9829",
      "DTXSID90482573",
      "2-Acetamido-4,6-Diphenylpyrimidine",
      "ZINC13586468",
      "BDBM50157675",
      "AKOS030564193",
      "Acetamide, N-(4,6-Diphenyl-2-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D01WXU",
    names: ["ZK-205367"],
  },
  {
    primary_id: "D01WXV",
    names: ["6,7-Diethoxy-4-Styrylquinazoline", "CHEMBL250925"],
  },
  {
    primary_id: "D01WXZ",
    names: [
      "11-Deoxy-PGE2",
      "11-Deoxy Prostaglandin E2",
      "9-Oxo-15S-Hydroxy-5Z,13E-Prostadienoic Acid",
      "(Z)-7-[(1R,2R)-2-[(E,3S)-3-Hydroxyoct-1-Enyl]-5-Oxocyclopentyl]hept-5-Enoic Acid",
      "11-Deoxyprostaglandin E2",
      "35536-53-9",
      "LMFA03010061",
      "AC1NR1FV",
      "11-Deoxy-Prostaglandin E2",
      "SCHEMBL9247000",
      "GTPL1945",
      "MolPort-039-138-850",
      "HMS3648H09",
      "ZINC27640133",
      "1151AH",
      "SR-01000946427",
    ],
  },
  {
    primary_id: "D01WYI",
    names: [
      "N-(4-M-Tolylamino-Quinazolin-6-Yl)-Acrylamide",
      "CHEMBL31815",
      "N-[4-[(3-Methyl-Phenyl)Amino]quinazolin-6-Yl]acrylamide",
      "N-[4-[(3-Methyl-Phenyl)Amino]quinazolin-6-Yl)Acrylamide",
      "SCHEMBL2991796",
      "AWQLTDUXGVCRBV-UHFFFAOYSA-N",
      "BDBM50077244",
    ],
  },
  {
    primary_id: "D01WYT",
    names: ["ASP3550"],
  },
  {
    primary_id: "D01WYV",
    names: ["Angiostatin/Paclitaxel/Carboplatin"],
  },
  {
    primary_id: "D01WZG",
    names: [
      "Creatine ALS-08",
      "Creatine",
      "ALS-02",
      "ALS-05",
      "ALS-08",
      "HD-02",
      "PD-02",
      "PD-04",
      "PD-06",
      "Creatine (ALS), Avicena",
      "Creatine (Amyotrophic Lateral Sclerosis), Avicena",
      "PD-01, Avicena",
      "AL-02 (ALS), Avicena",
      "AL-08 (ALS), Avicena",
      "Creatine (Huntington's Disease), Avicena",
      "Creatine (Parkinson's Disease), Avicena",
    ],
  },
  {
    primary_id: "D01WZL",
    names: ["AVE-8112", "AVE8112"],
  },
  {
    primary_id: "D01XAE",
    names: [
      "PRX-12239",
      "Dopamine/Noradrenaline Reuptake Inhibitor (Psychoneurological Disorders), Prexa Pharmaceuticals",
    ],
  },
  {
    primary_id: "D01XAJ",
    names: ["CSL-425"],
  },
  {
    primary_id: "D01XAR",
    names: ["AP-521"],
  },
  {
    primary_id: "D01XBA",
    names: ["Raloxifene"],
  },
  {
    primary_id: "D01XBW",
    names: ["External Guide Sequence Oligozyme"],
  },
  {
    primary_id: "D01XBZ",
    names: [
      "Dimethylallyl Diphosphate",
      "Di-CH3-Allyl-PPi",
      "Dimethylallyl-PP",
      "Prenyl Diphosphate Trianion",
      "Delta-Prenyl Diphosphate",
      "Prenyl-Diphosphate",
      "Delta2-Isopentenyl-Diphosphate",
      "Prenyl Diphosphate(3-)",
      "Prenyl Pyrophosphate Trianion",
      "Polycis-Polyprenyl Diphosphate",
      "GTPL3049",
      "CHEBI:83374",
      "CHEBI:57623",
      "CTK4H5497",
      "CHEBI:58914",
      "CBIDRCWHNCKSTO-UHFFFAOYSA-K",
      "Dimethylallyl Pyrophosphate Trianion",
      "An All-Trans-Polyprenyl Diphosphate",
      "3-Methylbut-2-En-1-Yl Diphosphate",
      "All-Cis-Polyprenyl Diphosphate(3-)",
      "Poly-Cis-Polyprenyl Diphosphate(3-)",
      "All-Trans-Polyprenyl Diphospha",
    ],
  },
  {
    primary_id: "D01XCA",
    names: ["N-Cyclohexyl-4-Hydroxy-N-Phenylbenzenesulfonamide", "CHEMBL382873"],
  },
  {
    primary_id: "D01XCL",
    names: ["Veratrum Viride Root"],
  },
  {
    primary_id: "D01XDL",
    names: [
      "Idarubicin",
      "DMDR",
      "Idamycin",
      "Idarubicina",
      "Idarubicine",
      "Idarubicinum",
      "Zavedos",
      "Idarubicin Hcl",
      "Idarubicin Hcl Pfs",
      "Idarubicin Hydrochloride",
      "DM5",
      "I 1656",
      "IMI 30",
      "IMI-30",
      "Idamycin (TN)",
      "Idarubicin (INN)",
      "Idarubicin [INN:BAN]",
      "Idarubicina [INN-Spanish]",
      "Idarubicine [INN-French]",
      "Idarubicinum [INN-Latin]",
      "Zavedos (TN)",
      "(1S,3S)-3-Acetyl-3,5,12-Trihydroxy-6,11-Dioxo-1,2,3,4,6,11-Hexahydrotetracen-1-Yl 3-Amino-2,3,6-Trideoxy-Alpha-L-Lyxo-Hexopyranoside",
      "(1s,3s)-3-Acetyl-3,5,12-Trihydroxy-6,11-Dioxo-1,2,3,4,6,11-Hexahydrotetracen-1-Yl 3-Amino-2,3,6-Trideoxy-A-L-Lyxo-Hexopyranoside",
      "(7S,9S)-9-Acetyl-7-[(2R,4S,5S,6S)-4-Amino-5-Hydroxy-6-Methyloxan-2-Yl]oxy-6,9,11-Trihydroxy-8,10-Dihydro-7H-Tetracene-5,12-Dione",
      "(7S-Cis)-9-Acetyl-7-[(3-Amino-2,3,6-Trideoxy-Alpha-L-Lyxo-Hexopyranosyl)Oxy]-7,8,9,10-Tetrahydro-6,9,11-Trihydroxy-5,12-Naphthacenedione",
      "4-DMD",
      "4-Demethoxydaunomycin",
      "4-Demethoxydaunorubicin",
      "4-Desmethoxydaunorubicin",
    ],
  },
  {
    primary_id: "D01XDN",
    names: ["ISIS 2503"],
  },
  {
    primary_id: "D01XDO",
    names: ["VSSP"],
  },
  {
    primary_id: "D01XDV",
    names: [
      "6,7-Dimethoxy-4-(2-Methoxy-Phenoxy)-Quinoline",
      "CHEMBL415501",
      "6,7-Dimethoxy-4-(2-Methoxyphenoxy)Quinoline",
      "190726-38-6",
      "SCHEMBL7487419",
      "CTK0E1594",
      "DTXSID50622690",
      "ZTLZYKICAWTOKI-UHFFFAOYSA-N",
      "BDBM50290848",
      "Quinoline, 6,7-Dimethoxy-4-(2-Methoxyphenoxy)-",
    ],
  },
  {
    primary_id: "D01XEC",
    names: ["Botulinum Toxin Recombinant Vaccine"],
  },
  {
    primary_id: "D01XEO",
    names: ["Debio-0824"],
  },
  {
    primary_id: "D01XEQ",
    names: ["R348"],
  },
  {
    primary_id: "D01XEY",
    names: [
      "Alpha-D-Fucose",
      "Alpha-D-Fucopyranose",
      "Alpha-D-Fucp",
      "6-Deoxy-Alpha-D-Galactopyranose",
      "Alpha-D-Fuc",
      "UNII-K15K52FOK4",
      "CHEBI:42564",
      "K15K52FOK4",
      "(2S,3R,4S,5R,6R)-6-Methyloxane-2,3,4,5-Tetrol",
      "6-Deoxy-D-Galactose",
      "Fucopyranose, Alpha-D-",
      "6-Deoxy-Alpha-D-Galactose",
      "Fucoside",
      "1abf",
      "7abp",
      "Alpha-D-Galactopyranose, 6-Deoxy-",
      "6-Dideoxy Galactose",
      "AC1L9FWD",
      "Epitope ID:115015",
      "SCHEMBL1399668",
      "CHEMBL609880",
      "ZINC1532815",
      "AKOS006272406",
      "RP22690",
      "DB03485",
      "6189-71-5",
      "CJ-24263",
      "F-8000",
      "A823144",
      "111719-EP2371811A2",
      "111719-EP2275420A1",
      "111719-EP2298757A2",
    ],
  },
  {
    primary_id: "D01XFH",
    names: ["N-Propynyl Amidebenzenesulphonide"],
  },
  {
    primary_id: "D01XFM",
    names: ["2-Piperidine Thiazole Derivative 2"],
  },
  {
    primary_id: "D01XFR",
    names: ["7-Allyl-1,3-Dipropyl-3,7-Dihydro-Purine-2,6-Dione", "CHEMBL284860", "BDBM50025571"],
  },
  {
    primary_id: "D01XFT",
    names: [
      "3-(6-Amino-Purin-9-Yl)-8-Phenyl-Octan-2-Ol",
      "CHEMBL43742",
      "SCHEMBL7458088",
      "ZINC13835547",
      "BDBM50070645",
      "(2S,3R)-3-(6-Amino-Purin-9-Yl)-8-Phenyl-Octan-2-Ol",
    ],
  },
  {
    primary_id: "D01XFZ",
    names: ["GPD-1116"],
  },
  {
    primary_id: "D01XGG",
    names: ["N-(7-Methoxy-4-Phenylbenzofuran-2-Yl)Acetamide", "CHEMBL598250"],
  },
  {
    primary_id: "D01XGP",
    names: [
      "1,2-Indanedione",
      "16214-27-0",
      "1H-Indene-1,2(3H)-Dione",
      "Indan-1,2-Dione",
      "1,2-Indandione",
      "Indandione",
      "3H-Indene-1,2-Dione",
      "2,3-Dihydro-1H-Indene-1,2-Dione",
      "Indane Dione",
      "NSC62555",
      "Indane-1,2-Dione",
      "ACMC-20a7vb",
      "AI3-25041",
      "AC1L3WEO",
      "AC1Q6K5I",
      "1,2-Indanedione, 95%",
      "Indan-1,2-Dione, 97%",
      "1H-Indene-1,2(3H)Dione",
      "SCHEMBL669542",
      "CHEMBL235288",
      "1,2-Dione-Based Compound, 9",
      "CTK0I2756",
      "BDBM22852",
      "DTXSID50167314",
      "1,2-IND",
      "MolPort-000-004-392",
      "WFFZGYRTVIPBFN-UHFFFAOYSA-N",
      "ZINC1691218",
      "KS-00000C3N",
      "NSC-62555",
      "BBL103322",
      "8216AA",
      "STL557132",
      "ANW-61125",
    ],
  },
  {
    primary_id: "D01XGX",
    names: ["JTP-4819"],
  },
  {
    primary_id: "D01XHG",
    names: [
      "DENBUFYLLINE",
      "Denbufylline",
      "57076-71-8",
      "Denbufyllinum [Latin]",
      "Denbufilina [Spanish]",
      "BRL 30892",
      "BRL-30892",
      "UNII-04B949KO6F",
      "1,3-Di-N-Butyl-7-(2-Oxopropyl)Xanthine",
      "CHEMBL277465",
      "7-Acetonyl-1,3-Dibutylxanthine",
      "C16H24N4O3",
      "04B949KO6F",
      "3,7-Dihydro-1,3-Dibutyl-7-(2-Oxopropyl)-1H-Purine-2,6-Dione",
      "1,3-Di-N-Butyl-7-(2&amp",
      "-Oxopropyl)Xanthine",
      "Denbufyllinum",
      "Denbufilina",
      "1H-Purine-2,6-Dione,1,3-Dibutyl-3,7-Dihydro-7-(2-Oxopropyl)-",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1,3-Dibutyl-7-(2-Oxopropyl)-",
      "Denbufylline [INN:BAN]",
      "Denbufylline [BAN:INN",
    ],
  },
  {
    primary_id: "D01XHU",
    names: ["AN-207", "D-26232", "Doxorubicin-Analog-[D-Lys(6)]LHRH Conjugate, Tulane University/Zentaris"],
  },
  {
    primary_id: "D01XHX",
    names: [
      "(+/-)-Threo-N-(2-Phenylethyl)Methylphenidate",
      "CHEMBL1254101",
      "BDBM50327116",
      "(AlphaR,2R)-Alpha-Phenyl-1-Phenethylpiperidine-2beta-Acetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D01XIK",
    names: [
      "Antimalarials",
      "Antimalarials, Broad Institute/Genzyme/Medicines For Malaria Venture",
      "Miniportfolio (Malaria), Broad Institute/Genzyme/Medicine Malaria Venture",
    ],
  },
  {
    primary_id: "D01XIW",
    names: ["PMID29649907-Compound-22"],
  },
  {
    primary_id: "D01XJR",
    names: ["ISIS 109116"],
  },
  {
    primary_id: "D01XKY",
    names: [
      "(4-Bromophenyl)(10H-Phenothiazin-10-Yl)Methanone",
      "CHEMBL239617",
      "AC1MRSRY",
      "Oprea1_099691",
      "BDBM50219219",
      "AKOS002684887",
    ],
  },
  {
    primary_id: "D01XLM",
    names: [
      "Piperazine",
      "Anthalazine",
      "Antiren",
      "Diethylenediamine",
      "Diethyleneimine",
      "Dispermine",
      "Eraverm",
      "Hexahydropyrazine",
      "Lumbrical",
      "PZE",
      "Piperazidine",
      "Piperazin",
      "Pipersol",
      "Upixon",
      "Uvilon",
      "Vermex",
      "Vermizine",
      "Wurmirazin",
      "Piperazin [German]",
      "Piperazin [Germany]",
      "Piperazine Dihydrochloride Dihydrochloride Hydrate",
      "Piperazine [USAN]",
      "Piperazine Anhydrous",
      "Piperazinium Oleate",
      "Pyrazine Hexahydride",
      "LTBB000432",
      "Piperazine [UN2579] [Corrosive]",
      "Eraverm (VAN)",
      "PIPERAZINE (HEXAHYDRATE)",
      "Piperazine (USP)",
      "Piperazine Hexa-Hydrate",
      "Piperazine, Anhydrous",
      "Pyrazine, Hexahy",
      "Vermizine (TN)",
      "Worm-Away",
      "Asca-Trol No. 3",
      "Worm-A-Ton",
      "Hexahydro-1,4-Diazine",
      "1,4-Diazacyclohexane",
      "1,4-Diethylenediamine",
      "1,4-Piperazine",
    ],
  },
  {
    primary_id: "D01XLZ",
    names: ["MJN228"],
  },
  {
    primary_id: "D01XMC",
    names: ["VTB-38543"],
  },
  {
    primary_id: "D01XMK",
    names: ["2-(3-(2-Methylquinolin-7-Yl)Phenyl)Acetonitrile", "CHEMBL267839"],
  },
  {
    primary_id: "D01XMM",
    names: [
      "5-(Piperidin-1-Ylsulfonyl)Indoline-2,3-Dione",
      "329905-79-5",
      "5-(Piperidin-1-Ylsulfonyl)-1H-Indole-2,3-Dione",
      "5-(Piperidine-1-Sulfonyl)-1H-Indole-2,3-Dione",
      "5-[1-(Piperidinyl)Sulfonyl]isatin",
      "Isatin Sulfonamide 21",
      "AC1N3S5Q",
      "CHEMBL61320",
      "BDBM10310",
      "DTXSID40399501",
      "MolPort-000-149-258",
      "ZX-BK002316",
      "ZINC2944779",
      "MIX-0755",
      "STK894352",
      "BBL021638",
      "2990AJ",
      "AKOS005144288",
      "MCULE-6394963078",
      "5-(Piperidinosulfonyl)Indoline-2,3-Dione",
      "AJ-44313",
      "ACM329905795",
      "AX8291419",
      "H8238",
      "5-(Piperidinosulfonyl)-1H-Indole-2,3-Dione",
    ],
  },
  {
    primary_id: "D01XMO",
    names: ["LY-292728"],
  },
  {
    primary_id: "D01XMP",
    names: ["TG-D"],
  },
  {
    primary_id: "D01XMS",
    names: ["ENT-103"],
  },
  {
    primary_id: "D01XMY",
    names: ["PMID1895299C1"],
  },
  {
    primary_id: "D01XNB",
    names: ["Omeprazole"],
  },
  {
    primary_id: "D01XOD",
    names: [
      "ALPIDEM",
      "82626-01-5",
      "Ananxyl",
      "Alpidemum [Latin]",
      "UNII-I93SC245QZ",
      "SL 80.0342-00",
      "2-(6-Chloro-2-(4-Chlorophenyl)Imidazo[1,2-A]pyridin-3-Yl)-N,N-Dipropylacetamide",
      "6-Chloro-2-(P-Chlorophenyl)-N,N-Dipropylimidazo(1,2-A)Pyridine-3-Acetamide",
      "CHEMBL54349",
      "I93SC245QZ",
      "S-800342",
      "NCGC00182850-01",
      "6-Chlor-2-(4-Chlorphenyl)-N,N-Dipropylimidazol(1,2-A)Pyridin-3-Acetamid",
      "DSSTox_RID_83237",
      "DSSTox_CID_28972",
      "DSSTox_GSID_49046",
      "Alpidemum",
      "6-Chloro-2-(4-Chlorophenyl)-N,N-Dipropyl-Imidazo[1,2-A]pyridine-3-Acetamide",
    ],
  },
  {
    primary_id: "D01XOR",
    names: ["(5-Imino-[1,4]thiazepan-3-Yl)-Methanol", "CHEMBL361722"],
  },
  {
    primary_id: "D01XPG",
    names: ["CAN-296"],
  },
  {
    primary_id: "D01XPY",
    names: ["L-Citrulline"],
  },
  {
    primary_id: "D01XQQ",
    names: [
      "TT-30",
      "CR2/Factor H Modulator (Fusion Protein, Autoimmune/Inflammatory Disease), Alexion",
      "CR2/Factor H Modulator (Fusion Protein, Autoimmune/Inflammatory Disease), Taligen",
    ],
  },
  {
    primary_id: "D01XRG",
    names: ["CRYPTADINE B", "Cryptadine B", "CHEMBL240094"],
  },
  {
    primary_id: "D01XRP",
    names: ["Simethicone"],
  },
  {
    primary_id: "D01XRW",
    names: ["PMID27977313-Compound-31"],
  },
  {
    primary_id: "D01XSX",
    names: [
      "(S)-2-Amino-6-Hydroxy-N-(4-Octylphenyl)Hexanamide",
      "CHEMBL568698",
      "SCHEMBL1311633",
      "UKLIXHBQCDTKPU-IBGZPJMESA-N",
    ],
  },
  {
    primary_id: "D01XTN",
    names: ["Epaxal"],
  },
  {
    primary_id: "D01XUE",
    names: ["Tedatioxetine"],
  },
  {
    primary_id: "D01XUG",
    names: ["ASP6432"],
  },
  {
    primary_id: "D01XUI",
    names: ["Carbamide Derivative 9"],
  },
  {
    primary_id: "D01XUJ",
    names: ["Cancer Therapy, ProMediTech Inc"],
  },
  {
    primary_id: "D01XUU",
    names: [
      "L-739758",
      "CHEMBL78760",
      "BDBM50078437",
      "3-{[5-(2-Piperidin-4-Yl-Ethyl)-Thieno[2,3-B]thiophene-2-Carbonyl]-Amino}-2-(Pyridine-3-Sulfonylamino)-Propionic Acid(L-739758)",
      "(S)-3-{[5-(2-Piperidin-4-Yl-Ethyl)-Thieno[2,3-B]thiophene-2-Carbonyl]-Amino}-2-(Pyridine-3-Sulfonylamino)-Propionic Acid",
      "(2S)-2-[(3-Pyridinyl)Sulfonylamino]-3-[[5-[2-(4-Piperidinyl)Ethyl]thieno[2,3-B]thiophen-2-Yl]carbonylamino]propanoic Acid",
    ],
  },
  {
    primary_id: "D01XVF",
    names: ["Sirukumab"],
  },
  {
    primary_id: "D01XVK",
    names: ["Pyrazoline Derivative 2"],
  },
  {
    primary_id: "D01XVX",
    names: ["CC-220", "AC1OEW2H", "NPD6561", "MCULE-9299015048", "BCP9000573", "BCP0726000266"],
  },
  {
    primary_id: "D01XWG",
    names: [
      "Daunorubicin",
      "Daunomycin",
      "20830-81-3",
      "Rubidomycin",
      "Cerubidine",
      "Daunorubicine",
      "Acetyladriamycin",
      "Leukaemomycin C",
      "Daunorubicinum",
      "Daunarubicinum",
      "Daunorrubicina",
      "Daunamycin",
      "Cerubidin",
      "DaunoXome",
      "Rubomycin C",
      "(+)-Daunomycin",
      "Daunoblastin",
      "Anthracyline",
      "Rubomycin",
      "Daunorubicinum [INN-Latin]",
      "RP 13057",
      "Daunorubicin [INN:BAN]",
      "RCRA Waste No U059",
      "FI6339",
      "NSC-82151",
      "DAUNORUBICIN HCL",
      "DaunoXome (TN)",
      "UNII-ZS7284E0ZP",
      "CCRIS 914",
      "ZS7284E0ZP",
      "CHEBI:41977",
      "HSDB 5095",
      "C27H29NO10",
      "NCI-C04693",
      "EINECS 244-069-7",
      "Ondena",
      "NSC 83142",
      "Daunoblastine",
      "Antibiotics From Streptomyces Coeruleorubidus",
      "DM1",
      "FI 6339",
      "Dauno-Rubidomycine",
      "Daunorubicin (INN)",
      "Daunorubicin (Liposomal)",
      "Daunorubicin, Hydrochloride",
      "VS-103",
      "(1S,3S)-3-Acetyl-3,5,12-Trihydroxy-10-(Methyloxy)-6,11-Dioxo-1,2,3,4,6,11-Hexahydrotetracen-1-Yl 3-Amino-2,3,6-Trideoxy-Alpha-L-Lyxo-Hexopyranoside",
      "(1S,3S)-3-Acetyl-3,5,12-Trihydroxy-10-Methoxy-6,11-Dioxo-1,2,3,4,6,11-Hexahydrotetracen-1-Yl 3-Amino-2,3,6-Trideoxy-A-L-Lyxo-Hexopyranoside",
      "(7S,9R)-9-Acetyl-7-[(2S,4S,5S,6S)-4-Amino-5-Hydroxy-6-Methyl-Oxan-2-Yl]oxy-6,9,11-Trihydroxy-4-Methoxy-8,10-Dihydro-7H-Tetracene-5,12-Dione",
      "(7S,9S)-9-Acetyl-7-[(2R,4S,5S,6S)-4-Amino-5-Hydroxy-6-Methyloxan-2-Yl]oxy-6,9,11-Trihydroxy-4-Methoxy-8,10-Dihydro-7H-Tetracene-5,12-Dione",
      "(8S-Cis)-8-Acetyl-10-((3-Amino-2,3,6-Trideoxy-Alpha-L-Lyxo-Hexopyrannosyl)Oxy)-7,8,9,10-Tetrahydro-6,8,11-Trihydroxy-1-Methoxy-5,12-Napthacenedione",
      "(8S-Cis)-8-Acetyl-10-[(3-Amino-2,3,6-Trideoxy-Alpha-L-Lyxo-Hexopyranosyl)Oxy]-7,8,9,10-Tetrahydro-6,8,11-Trihydroxy-1-Methoxy-5,12-Naphthacenedione",
      "Anthracycline",
    ],
  },
  {
    primary_id: "D01XWL",
    names: [
      "3-Hydroxy-8-Methyl-1H-Benzo[b]azepine-2,5-Dione",
      "144040-48-2",
      "2,5-Dihydro-2,5-Dioxo-3-Hydroxy-8-Methyl-1H-Benzazepine",
      "8-Me-Ddhb",
      "AC1L2QYL",
      "5-Hydroxy-8-Methyl-1H-1-Benzazepine-2,3-Dione",
      "1H-1-Benzazepine-2,5-Dione, 3-Hydroxy-8-Methyl-",
      "CHEMBL143519",
      "SCHEMBL9387738",
      "CTK4C3913",
      "8-Methyl-3-Hydroxy-1H-1-Benzazepine-2,5-Dione",
      "1H-1-Benzaepine-2,5-Dione, 3-Hydroxy-8-Methyl-",
    ],
  },
  {
    primary_id: "D01XWZ",
    names: ["7-Mercapto-Heptanoic Acid Biphenyl-4-Ylamide", "CHEMBL112311"],
  },
  {
    primary_id: "D01XXF",
    names: ["Modified SDF-1"],
  },
  {
    primary_id: "D01XXN",
    names: [
      "N-Hydroxy-4-Phenylacetylamino-Benzamide",
      "CHEMBL356824",
      "656261-23-3",
      "SCHEMBL675578",
      "CTK1J6158",
      "DTXSID40458440",
      "ZINC13533297",
      "AKOS030583151",
      "Benzeneacetamide, N-[4-[(Hydroxyamino)Carbonyl]phenyl]-",
    ],
  },
  {
    primary_id: "D01XXZ",
    names: [
      "6-Ethyl-2-Phenyl-Chromen-4-One",
      "CHEMBL134732",
      "288401-00-3",
      "6-Ethylflavone",
      "CTK4G2218",
      "DTXSID80451374",
      "ZINC2573995",
      "AKOS030553658",
    ],
  },
  {
    primary_id: "D01XYD",
    names: ["HMS3229G13"],
  },
  {
    primary_id: "D01XYJ",
    names: ["Zidovudine"],
  },
  {
    primary_id: "D01XZG",
    names: ["G1T28-1"],
  },
  {
    primary_id: "D01XZI",
    names: ["(-)-Trans-H2-PAT"],
  },
  {
    primary_id: "D01YBD",
    names: [
      "2-(4-Methyl-Piperazin-1-Yl)-4-Phenyl-Pyrimidine",
      "CHEMBL45158",
      "159692-85-0",
      "2-(4-Methylpiperazin-1-Yl)-4-Phenylpyrimidine",
      "SCHEMBL1275203",
      "CTK0B0099",
      "DTXSID80438281",
      "QHOBPFOGFURKFZ-UHFFFAOYSA-N",
      "BDBM50289600",
      "1-(4-Phenylpyrimidin-2-Yl)-4-Methylpiperazine",
      "Pyrimidine, 2-(4-Methyl-1-Piperazinyl)-4-Phenyl-",
    ],
  },
  {
    primary_id: "D01YBR",
    names: ["ST-3050"],
  },
  {
    primary_id: "D01YCF",
    names: ["4SCAR-GD2"],
  },
  {
    primary_id: "D01YCW",
    names: ["L-671,480"],
  },
  {
    primary_id: "D01YCX",
    names: ["YJRN-0904"],
  },
  {
    primary_id: "D01YDF",
    names: ["Pibutidine", "Alex", "Pibutidine Hydrochloride", "IT-066"],
  },
  {
    primary_id: "D01YDH",
    names: ["GSK2981710"],
  },
  {
    primary_id: "D01YDO",
    names: ["[3H]dihydroalprenolol", "[3H]-Dihydroalprenolol"],
  },
  {
    primary_id: "D01YDR",
    names: ["[125I]SD6"],
  },
  {
    primary_id: "D01YED",
    names: ["2-(N-Pyrrolidinyl)-3'-Methoxypropiophenone", "CHEMBL580143", "BDBM50308119"],
  },
  {
    primary_id: "D01YEN",
    names: ["Ibotenate"],
  },
  {
    primary_id: "D01YFR",
    names: [
      "DWJ-205",
      "DWP-06018",
      "DWP-06081",
      "DWP-06092",
      "DWP-07118",
      "Triazole Derivatives (Fungal Infection), Daewoong",
    ],
  },
  {
    primary_id: "D01YGD",
    names: ["ICO-25"],
  },
  {
    primary_id: "D01YGF",
    names: ["KF-15570"],
  },
  {
    primary_id: "D01YGY",
    names: ["UC-1011"],
  },
  {
    primary_id: "D01YHK",
    names: [
      "3-Fluoro-4-Hydrazino-Benzenesulfonamide",
      "606126-17-4",
      "3-Fluoro-4-Hydrazinobenzenesulfonamide",
      "Hydrazide Deriv. 15",
      "SCHEMBL1244816",
      "CHEMBL176180",
      "CTK2E9801",
      "BDBM12160",
      "DTXSID90448811",
      "IDSRHQBHQWPDNY-UHFFFAOYSA-N",
      "AKOS013645919",
      "4-Hydrazino-3-Fluorobenzenesulfonamide",
      "Benzenesulfonamide, 3-Fluoro-4-Hydrazino-",
      "TC-063144",
      "3-Fluoro-4-Hydrazinylbenzene-1-Sulfonamide",
      "144147-EP2287165A2",
      "144147-EP2292620A2",
    ],
  },
  {
    primary_id: "D01YHL",
    names: ["DasKloster 0039-01"],
  },
  {
    primary_id: "D01YHO",
    names: ["WP-1122"],
  },
  {
    primary_id: "D01YHY",
    names: ["PMID28117607-Compound-20"],
  },
  {
    primary_id: "D01YIF",
    names: ["PAC1"],
  },
  {
    primary_id: "D01YIO",
    names: ["JNJ-10311795"],
  },
  {
    primary_id: "D01YIR",
    names: [
      "2-(3'-Methoxyphenyl) Benzimidazole-4-Carboxamide",
      "2-(3-Methoxyphenyl)-1H-Benzimidazole-4-Carboxamide",
      "CHEMBL134022",
      "BZC",
      "1efy",
      "AC1L1BMO",
      "SCHEMBL4321727",
      "CTK7A9229",
      "NVVWVYYHTKCIAE-UHFFFAOYSA-N",
      "ZINC11565446",
      "BDBM50093373",
      "DB04010",
      "2-(3'-Methoxyphenyl)-1-H-Benzimidazole-4-Carboxamide",
      "2-(3-Methoxyphenyl)-1H-1,3-Benzodiazole-4-Carboxamide",
      "2-(3''-METHOXYPHENYL) BENZIMIDAZOLE-4-CARBOXAMIDE",
      "2-(3-Methoxy-Phenyl)-1H-Benzoimidazole-4-Carboxylic Acid Amide",
    ],
  },
  {
    primary_id: "D01YJD",
    names: ["ISIS 6445"],
  },
  {
    primary_id: "D01YJJ",
    names: [
      "N5-[4-(3-Phenylpropoxy)Phenyl]-L-Glutamine",
      "CHEMBL477513",
      "Modified Amino Acid Analog, 8j",
      "BDBM24275",
      "(2S)-2-Amino-4-{[4-(3-Phenylpropoxy)Phenyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D01YJR",
    names: ["IVN-Birch", "Birch-MAT"],
  },
  {
    primary_id: "D01YKI",
    names: ["ANX-510"],
  },
  {
    primary_id: "D01YKR",
    names: ["FCR001"],
  },
  {
    primary_id: "D01YLA",
    names: ["NP-432"],
  },
  {
    primary_id: "D01YLF",
    names: ["PTI-701"],
  },
  {
    primary_id: "D01YLK",
    names: ["Meningitis ACYW Conj."],
  },
  {
    primary_id: "D01YNC",
    names: [
      "N-(Tert-Butoxycarbonyl)-Isoleucyl-Glycine-Nitrile",
      "Dipeptide-Derived Nitrile, 9",
      "CHEMBL197113",
      "BDBM20088",
      "Tert-Butyl N-[(1S,2R)-1-[(Cyanomethyl)Carbamoyl]-2-Methylbutyl]carbamate",
    ],
  },
  {
    primary_id: "D01YNL",
    names: ["PD-137789"],
  },
  {
    primary_id: "D01YNT",
    names: ["((E)-7-But-2-Enyl)-Azepan-(2Z)-Ylideneamine", "CHEMBL89459", "BDBM50104655"],
  },
  {
    primary_id: "D01YNZ",
    names: ["4-(2-(Benzyloxy)Phenyl)Piperidine", "CHEMBL573769"],
  },
  {
    primary_id: "D01YOC",
    names: [
      "BMS-181100",
      "BMY-14786",
      "BMY-14802",
      "BMY-14802 Hydrochloride",
      "MJ-14676",
      "MJ-14786",
      "MJ-14802",
      "BMY-14802, Skybridge",
      "BMY-14802-1",
      "BMY-14802 (Dyskinesia), Skybridge",
      "5-HT 1a Receptor Agonist (Dyskinesia), Skybridge",
    ],
  },
  {
    primary_id: "D01YPD",
    names: [
      "GNF-PF-3832",
      "3-(4-Methylphenyl)-5H-Indeno[1,2-C]pyridazin-5-One",
      "300843-87-2",
      "AC1MCE1I",
      "Cambridge Id 5215436",
      "Oprea1_419211",
      "CBDivE_007454",
      "MLS000104226",
      "CHEMBL261693",
      "Cid_2767330",
      "KS-00001RTF",
      "BDBM21629",
      "MolPort-002-136-671",
      "HMS1578F11",
      "HMS2748H23",
      "ZINC196662",
      "STK746982",
      "MMV666021",
      "AKOS001704735",
      "MCULE-6620780852",
      "SMR000051275",
      "SR-01000507948",
      "3-(4-Methylphenyl)Indeno[1,2-C]pyridazin-5-One",
      "11K-012",
      "SR-01000507948-1",
    ],
  },
  {
    primary_id: "D01YPT",
    names: ["CCNCSSKWCRAHSRCC", "CHEMBL524475"],
  },
  {
    primary_id: "D01YQE",
    names: ["PMID30074415-Compound-GS3"],
  },
  {
    primary_id: "D01YQH",
    names: ["GM-CSF"],
  },
  {
    primary_id: "D01YQX",
    names: [
      "6-Hydroxy-7,9-Octadecadiynoic Acid",
      "CHEMBL448462",
      "SCHEMBL3199736",
      "6-Hydroxyoctadeca-7,9-Diynoic Acid",
      "LMFA01031024",
    ],
  },
  {
    primary_id: "D01YRL",
    names: ["6-Benzyl-2-Cyclohexylsulfanyl-3H-Pyrimidin-4-One"],
  },
  {
    primary_id: "D01YRT",
    names: [
      "Di-Imidazole Lexitropsin",
      "DIIMIDAZOLE LEXITROPSIN",
      "AC1NDQQ9",
      "[1-Amino-3-[[4-[(4-Formamido-1-Methylimidazole-2-Carbonyl)Amino]-1-Methylimidazole-2-Carbonyl]amino]propylidene]azanium",
    ],
  },
  {
    primary_id: "D01YSH",
    names: ["DV-7021"],
  },
  {
    primary_id: "D01YSN",
    names: ["Isoxazole Azepine Compound 3"],
  },
  {
    primary_id: "D01YSU",
    names: ["AG-PPC709", "Antitussive Mucolytic Agent (Respiratory Disorders), Ahn-Gook"],
  },
  {
    primary_id: "D01YSX",
    names: ["ISIS 116625"],
  },
  {
    primary_id: "D01YTS",
    names: [
      "CAA0225",
      "CAA-0225",
      "GTPL6532",
      "(2S,3S)-2-N-[(1S)-1-(Benzylcarbamoyl)-2-Phenylethyl]-3-N-[2-(4-Hydroxyphenyl)Ethyl]oxirane-2,3-Dicarboxamide",
    ],
  },
  {
    primary_id: "D01YTX",
    names: ["CBLC-4H10"],
  },
  {
    primary_id: "D01YVT",
    names: [
      "Colesevelam",
      "CholestaGel",
      "Welchol",
      "Colesevelam Hydrochloride",
      "Welchol DM",
      "GT31-104",
      "GT31-104HB",
    ],
  },
  {
    primary_id: "D01YVZ",
    names: ["GLYX-13"],
  },
  {
    primary_id: "D01YWD",
    names: ["4-Fluorophenyl 1-(4-Butoxyphenyl)Propylcarbamate", "CHEMBL604388", "SCHEMBL5215965"],
  },
  {
    primary_id: "D01YWE",
    names: ["PMID19364658C33"],
  },
  {
    primary_id: "D01YYP",
    names: ["Margetuximab"],
  },
  {
    primary_id: "D01YZF",
    names: ["CART-BCMA"],
  },
  {
    primary_id: "D01ZAL",
    names: ["PMID2296036C4i"],
  },
  {
    primary_id: "D01ZAQ",
    names: [
      "AZD5363",
      "1143532-39-1",
      "AZD-5363",
      "Capivasertib",
      "AZD 5363",
      "UNII-WFR23M21IE",
      "WFR23M21IE",
      "Cc-638",
      "4-Amino-N-[(1s)-1-(4-Chlorophenyl)-3-Hydroxypropyl]-1-(7h-Pyrrolo[2,3-D]pyrimidin-4-Yl)Piperidine-4-Carboxamide",
      "C21H25ClN6O2",
      "(S)-4-AMINO-N-(1-(4-CHLOROPHENYL)-3-HYDROXYPROPYL)-1-(7H-PYRROLO[2,3-D]PYRIMIDIN-4-YL)PIPERIDINE-4-CARBOXAMIDE",
      "4-Amino-N-[(1S)-1-(4-Chlorophenyl)-3-Hydroxypropyl]-1-(7H-Pyrrolo[2,3-D]pyrimidin-4-Yl)-4-Piperidinecarboxamide",
      "4-Piperidinecarboxamide, 4-Amino-N-[(1S)-1-(4-Chlorophenyl)-3-Hydroxypropyl]-1",
    ],
  },
  {
    primary_id: "D01ZBF",
    names: [
      "E-1224",
      "BMS-292655",
      "BMS-315801",
      "BMS-379224",
      "Ravuconazole Prodrugs, BMS",
      "Ravuconazole Back-Up Compounds, Eisai",
      "Ravuconazole Prodrug (Fungal Infection), Brain Factory",
      "Ravuconazole Prodrugs, Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D01ZCC",
    names: ["PMID26815044-Compound-118"],
  },
  {
    primary_id: "D01ZCG",
    names: [
      "3-[5-(4-Hydroxyphenyl)-2-Thienyl]phenol",
      "SCHEMBL1180468",
      "CHEMBL457323",
      "BDBM25850",
      "PKHBRBMFZXTMIS-UHFFFAOYSA-N",
      "ZINC40847645",
      "4,3'-(2,5-Thiophenediyl)Bisphenol",
      "Hydroxyphenyl Substituted Thiophene, 22",
    ],
  },
  {
    primary_id: "D01ZCH",
    names: ["ISIS 191756"],
  },
  {
    primary_id: "D01ZCI",
    names: ["Phosphatidyl (3,5) Inositol Biphosphate"],
  },
  {
    primary_id: "D01ZCK",
    names: ["ISIS 298746"],
  },
  {
    primary_id: "D01ZCL",
    names: ["ACT-N", "Autologous Cell Therapy-Neurological"],
  },
  {
    primary_id: "D01ZDC",
    names: ["HCV NS3 Protease Inhibitors"],
  },
  {
    primary_id: "D01ZDQ",
    names: ["ACH-1095", "ACH-0199", "GS-9525", "NS4A Protease Inhibitors (Oral, HCV Infection), Achillion/Gilead"],
  },
  {
    primary_id: "D01ZDR",
    names: ["FR-208419"],
  },
  {
    primary_id: "D01ZEC",
    names: [
      "Cefacetrile",
      "Cefacetril",
      "Cefacetrilo",
      "Cefacetrilum",
      "Celospor",
      "Cephacetrile",
      "Vetrimast [veterinary]",
      "Cefacetrile (INN)",
      "Cefacetrilo [INN-Spanish]",
      "Cefacetrilum [INN-Latin]",
      "Celospor (TN)",
      "Celtol (TN)",
      "Cristacef (TN)",
      "Vetrimast [veterinary] (TN)",
      "C-36278-Ba",
      "(6R,7R)-3-(Acetyloxymethyl)-7-[(2-Cyanoacetyl)Amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-Acetoxymethyl-7-(2-Cyanacetamido)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2-Carbonsaeure",
      "(6R,7R)-3-[(Acetyloxy)Methyl]-7-[(Cyanoacetyl)Amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-(2-Cyanacetamido)-3-(Hydroxymethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2-Carboxylat Acetat (Ester)",
      "7-Cyanacetylamino-Cephalosporansaeure",
    ],
  },
  {
    primary_id: "D01ZEY",
    names: ["MDL-201112", "Carbocyclic Nucleoside, Hoechst", "MDL-201449", "MDL-201449A"],
  },
  {
    primary_id: "D01ZFK",
    names: ["Tuberculosis Recombinant Vaccine"],
  },
  {
    primary_id: "D01ZFM",
    names: ["LNK-3248"],
  },
  {
    primary_id: "D01ZGH",
    names: ["JTK-853"],
  },
  {
    primary_id: "D01ZGS",
    names: ["TGN-208"],
  },
  {
    primary_id: "D01ZHB",
    names: ["Revusiran"],
  },
  {
    primary_id: "D01ZHK",
    names: ["N-Isopropylnorlitebamine"],
  },
  {
    primary_id: "D01ZHQ",
    names: ["MIM-A2", "Anti-MIMA2 Antibody (Glaucoma), Mimetogen Pharmaceuticals"],
  },
  {
    primary_id: "D01ZHW",
    names: ["N-Arachidonoylglycine"],
  },
  {
    primary_id: "D01ZII",
    names: [
      "Chlorpromazine",
      "Aminasine",
      "Aminazin",
      "Aminazine",
      "Ampliactil",
      "Amplicitil",
      "Amplictil",
      "Chlordelazine",
      "Chlorderazin",
      "Chloropromazine",
      "Chlorpromados",
      "Chlorpromazin",
      "Chlorpromazinum",
      "Clorpromazina",
      "Contomin",
      "Cromedazine",
      "Elmarin",
      "Esmind",
      "Fenactil",
      "Fenaktyl",
      "Largactil",
      "Largactilothiazine",
      "Largactyl",
      "Megaphen",
      "Novomazina",
      "Phenactyl",
      "Phenathyl",
      "Plegomasine",
      "Plegomazin",
      "Prazilpromactil",
      "Proma",
      "Promactil",
      "Promazil",
      "Propaphen",
      "Propaphenin",
      "Prozil",
      "Psychozine",
      "Sanopron",
      "Thorazine",
      "Torazina",
      "Wintermin",
      "Chlorpromazine Tannate",
      "Clorpromazina [Italian]",
      "Fraction AB",
      "Largactil Liquid",
      "Largactil Oral Drops",
      "Phenothiazine Hydrochloride",
      "Thorazine Spansule",
      "Thorazine Suppositories",
      "Thorazine Hydrochloride",
      "BC 135",
      "HL 5746",
      "JHICC02042",
      "SKF 2601A",
      "Z80",
      "Chlor-PZ",
      "Chlor-Promanyl",
      "Chlorpromanyl (Discontinued)",
      "Chlorpromazinum [INN-Latin]",
      "Clorpromazina [INN-Spanish]",
      "Largactil (TN)",
      "Novo-Chlorpromazine",
      "SKF 2601-A",
      "SKF-2601",
      "Thorazine (TN)",
      "Chlorpromazine (USP/INN)",
      "Chlorpromazine [USAN:INN:BAN]",
      "Chloro-3 (Dimethylamino-3 Propyl)-10 Phenothiazine",
      "Chloro-3 (Dimethylamino-3 Propyl)-10 Phenothiazine [French]",
      "N-(3-Dimethylaminopropyl)-3-Chlorophenothiazine",
      "[3-(2-Chloro-Phenothiazin-10-Yl)-Propyl]-Dimethyl-Amine",
      "10-(3-Dimethylaminopropyl)-2-Chlorophenothiazine",
      "10H-Phenothiazine-10-Propanamine, 2-Chloro-N,N-Dimethyl-, Radical Ion(1+)",
      "2-Chloro-10-(3-(Dimethylamino)Propyl)Phenothiazine",
      "2-Chloro-10-[3-(Dimethylamino)Propyl]phenothiazine",
      "2-Chloropromazine",
      "2-Cloro-10 (3-Dimetilaminopropil)Fenotiazina",
      "2-Cloro-10 (3-Dimetilaminopropil)Fenotiazina [Italian]",
      "2-Chloro-10-(3-(Dimethylamino)Propyl)-Phenothiazine",
      "2-Chloro-N,N-Dimethyl-10H-Phenothiazine-10-Propanamine",
      "3-(2-Chloro-10H-Phenothiazin-10-Yl)-N,N-Dimethyl-1-Propanamine",
      "3-(2-Chloro-10H-Phenothiazin-10-Yl)-N,N-Dimethylpropan-1-Amine",
      "3-(2-Chlorophenothiazin-10-Yl)-N,N-Dimethyl-Propan-1-Amine",
      "3-(2-Chlorophenothiazin-10-Yl)-N,N-Dimethylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D01ZIP",
    names: ["N-[5-(2-Ethyl-Phenyl)-Furan-2-Carbonyl]-Guanidine", "CHEMBL372051", "SCHEMBL4939618"],
  },
  {
    primary_id: "D01ZJB",
    names: ["Autologous Bone Marrow-Derived Mesenchymal Stem Cells"],
  },
  {
    primary_id: "D01ZJF",
    names: ["3-(3-(Carbamoyl)Benzamido)Propanoic Acid", "CHEMBL219603"],
  },
  {
    primary_id: "D01ZJK",
    names: [
      "Ardeparin",
      "Arteven",
      "Bemiparin",
      "Certoparin",
      "Clivarine",
      "Dalteparin",
      "Eparina",
      "Fluxum",
      "Fraxiparin",
      "Heparina",
      "Heparinate",
      "Heparine",
      "Heparinum",
      "Hepathrom",
      "Innohep",
      "Liquaemin",
      "Liquemin",
      "Logiparin",
      "Multiparin",
      "Nadroparin",
      "Nadroparine",
      "Novoheparin",
      "Pabyrin",
      "Parnaparin",
      "Parvoparin",
      "Pularin",
      "Sandoparin",
      "Subeparin",
      "Sublingula",
      "Thromboliquine",
      "Tinzaparin",
      "Triofiban",
      "Vetren",
      "Allocinnamic Acid",
      "Ardeparin Sodium",
      "Bemiparin Sodium",
      "Enoxaparin Sodium",
      "Eparina [DCIT]",
      "Fragmin A",
      "Fragmin B",
      "Fragmin IV",
      "Hep Flush Kit In Plastic Container",
      "Heparin Lock Flush In Plastic Container",
      "Heparin Lock Flush Preservative Free",
      "Heparin Lock Flush Preservative Free In Plastic Container",
      "Heparin Natrium",
      "Heparin Sulfate",
      "Heparinic Acid",
      "Heparinum Natricum",
      "Isocinnamic Acid",
      "Minolteparin Sodium",
      "Parnaparin Sodium",
      "Reviparin Sodium",
      "Sodium Acid Heparin",
      "Sodium Heparinate",
      "Tinzaparin Sodium",
      "Vitrum AB",
      "CY 216",
      "Cy 222",
      "FR 860",
      "H 2149",
      "Heparin CY 216",
      "KB 101",
      "Kabi 2165",
      "LHN 1",
      "Lioton 1000",
      "OP 386",
      "OP 622",
      "Ro 11",
      "WY 90493RD",
      "ALFA 87-120",
      "ALFA 87-163",
      "ALFA 87-198",
      "ALFA 87-81",
      "ALFA 88-247",
      "Alpha-Heparin",
      "Cis-Cinnamic Acid",
      "Depo-Heparin",
      "Hed-Heparin",
      "Hep-Lock",
      "Heparin, Sodium Salt",
      "Heparina [INN-Spanish]",
      "Heparine [INN-French]",
      "Heparinum [INN-Latin]",
      "Hepflush-10",
      "Inno-Hep",
      "Normiflo (TN)",
      "Hep-Lock U/P",
      "Cis-.beta.-Carboxystyrene",
    ],
  },
  {
    primary_id: "D01ZJM",
    names: ["R-1479", "4-Azidocytidine"],
  },
  {
    primary_id: "D01ZKE",
    names: ["Biaryl Mannoside Derivative 21"],
  },
  {
    primary_id: "D01ZKN",
    names: ["NSC-645836"],
  },
  {
    primary_id: "D01ZLI",
    names: ["CBR-2092"],
  },
  {
    primary_id: "D01ZLK",
    names: ["APX005M"],
  },
  {
    primary_id: "D01ZLL",
    names: ["Aom-0755"],
  },
  {
    primary_id: "D01ZLR",
    names: ["PF-05082566"],
  },
  {
    primary_id: "D01ZLU",
    names: ["TAS-120"],
  },
  {
    primary_id: "D01ZMJ",
    names: ["N-(2-Ethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093040", "BDBM50314142"],
  },
  {
    primary_id: "D01ZMO",
    names: ["4-(3H-Inden-1-Ylmethyl)-Phenylamine", "CHEMBL371048"],
  },
  {
    primary_id: "D01ZMP",
    names: ["2-Morpholino-6-(Thiophen-2-Yl)-4H-Pyran-4-One", "CHEMBL374133"],
  },
  {
    primary_id: "D01ZMR",
    names: ["Calcineurin"],
  },
  {
    primary_id: "D01ZMS",
    names: ["PK1"],
  },
  {
    primary_id: "D01ZNH",
    names: ["IDAZOXAN HYDROCHLORIDE"],
  },
  {
    primary_id: "D01ZNQ",
    names: ["TMC-310911"],
  },
  {
    primary_id: "D01ZOB",
    names: ["Terguride"],
  },
  {
    primary_id: "D01ZOG",
    names: ["Difluprednate", "Durezol (TN)"],
  },
  {
    primary_id: "D01ZPK",
    names: ["BA-207"],
  },
  {
    primary_id: "D01ZPT",
    names: ["ACTR087"],
  },
  {
    primary_id: "D01ZPY",
    names: [
      "FMP2.1/AS02A",
      "AMA-1 Malaria Vaccine",
      "Recombinant Protein Vaccine (Plasmodium Falciparum Infection), WRAIR/NIAID",
      "Apicalmembrane Antigen-1 Vaccine (Malaria), NIAID/WRAIR",
    ],
  },
  {
    primary_id: "D01ZQN",
    names: ["ALD-306"],
  },
  {
    primary_id: "D01ZRI",
    names: [
      "Axitinib",
      "AG 013736",
      "AG013736",
      "AG-013736",
      "AG-13736",
      "AG-013736, Axitinib",
      "N-Methyl-2-[[3-[(E)-2-Pyridin-2-Ylethenyl]-1H-Indazol-6-Yl]sulfanyl]benzamide",
      "N-Methyl-2-((3-((1E)-2-(Pyridin-2-Yl)Ethenyl)-1H-Indazol-6-Yl)Sulfanyl)Benzamide",
      "Axitinib (VEGFR Inhibitor)",
    ],
  },
  {
    primary_id: "D01ZRJ",
    names: ["Ac-I[CV(Bta)QDWGAHRC]T-NH2"],
  },
  {
    primary_id: "D01ZRR",
    names: ["RGI-4000"],
  },
  {
    primary_id: "D01ZRX",
    names: [
      "3-CHLORO-2-(4-HYDROXYPHENYL)-2H-INDAZOL-5-OL",
      "3-Chloro-2-(4-Hydroxyphenyl)-2H-Indazol-5-Ol",
      "848142-62-1",
      "3-Chloro-2-(4-Hydroxyphenyl)Indazol-5-Ol",
      "CHEMBL180071",
      "CTK2I5101",
      "DTXSID40463812",
      "Indazole-Cl, &gt",
      "ZINC13609473",
      "AKOS030536245",
      "DB07708",
      "2-(P-Hydroxyphenyl)-3-Chloro-2H-Indazole-5-Ol",
      "2H-Indazol-5-Ol, 3-Chloro-2-(4-Hydroxyphenyl)-",
    ],
  },
  {
    primary_id: "D01ZSF",
    names: ["GR-218231"],
  },
  {
    primary_id: "D01ZSO",
    names: [
      "Moclobemide",
      "Arima",
      "Aurorex",
      "Aurorix",
      "Deprenorm",
      "Feraken",
      "Manerix",
      "Moclaime",
      "Moclamide",
      "Moclamine",
      "Moclix",
      "Moclobamide",
      "Moclobemid",
      "Moclobemida",
      "Moclobemidum",
      "Moclobeta",
      "Moclodura",
      "Moclonorm",
      "NovoMoclobemide",
      "NuMoclobemide",
      "Rimoc",
      "Alphapharm Brand Of Moclobemide",
      "Alpharma Brand Of Moclobemide",
      "Apo Moclobemide",
      "Apotex Brand Of Moclobemide",
      "Azupharma Brand Of Moclobemide",
      "BC Brand Of Moclobemide",
      "Betapharm Brand Of Moclobemide",
      "Bull Brand Of Moclobemide",
      "Chem Mart Brand Of Moclobemide",
      "Chem Mart Moclobemide",
      "Ct Arzneimittel Brand Of Moclobemide",
      "DBL Moclobemide",
      "Esparma Brand Of Moclobemide",
      "Faulding Brand Of Moclobemide",
      "GenRX Moclobemide",
      "Healthsense Brand Of Moclobemide",
      "Healthsense Moclobemide",
      "Hexal Brand Of Moclobemide",
      "Hoffmann La Roche Brand Of Moclobemide",
      "Kendrick Brand Of Moclobemide",
      "Merck Dura Brand Of Moclobemide",
      "Moclobemid AZU",
      "Moclobemid Puren",
      "Moclobemid Stada",
      "Moclobemid Ratiopharm",
      "Moclobemid Von Ct",
      "Moclobemide Alphapharm Brand",
      "Moclobemide Alpharma Brand",
      "Moclobemide Apotex Brand",
      "Moclobemide Azupharma Brand",
      "Moclobemide BC Brand",
      "Moclobemide Bull Brand",
      "Moclobemide Faulding Brand",
      "Moclobemide Healthsense Brand",
      "Moclobemide Hexal Brand",
      "Moclobemide Kendrick Brand",
      "Moclobemide Novopharm Brand",
      "Moclobemide Pharmascience Brand",
      "Moclobemide Roche Brand",
      "Moclobemide Stadapharm Brand",
      "Moclobemide Temmler Brand",
      "Moclobemide Betapharm Brand",
      "Moclobemide Esparma Brand",
      "Moclobemide Ratiopharm Brand",
      "Novo Moclobemide",
      "Novopharm Brand Of Moclobemide",
      "Nu Moclobemide",
      "Nu Pharm Brand Of Moclobemide",
      "PMS Moclobemide",
      "Pharmascience Brand Of Moclobemide",
      "Ratiopharm Brand Of Moclobemide",
      "Roche Brand Of Moclobemide",
      "Stadapharm Brand Of Moclobemide",
      "Temmler Brand Of Moclobemide",
      "Terry White Chemists Brand Of Moclobemide",
      "Terry White Chemists Moclobemide",
      "CBMicro_048319",
      "Moclobemid 1A Pharma",
      "Moclobemid1A Pharma",
      "Moclobemide 1A Brand",
      "Ro 11 1163",
      "AZU, Moclobemid",
      "Apo-Moclobemide",
      "Aurorix (TN)",
      "Ct-Arzneimittel Brand Of Moclobemide",
      "Hoffmann-La Roche Brand Of Moclobemide",
      "Manerix (TN)",
      "Moclobemid-1A Pharma",
      "Moclobemid-Puren",
      "Moclobemid-Ratiopharm",
      "Moclobemida [INN-Spanish]",
      "Moclobemide Nu-Pharm Brand",
      "Moclobemide Ct-Arzneimittel Brand",
      "Moclobemide, Chem Mart",
      "Moclobemide, DBL",
      "Moclobemide, GenRX",
      "Moclobemide, Healthsense",
      "Moclobemidum [INN-Latin]",
      "Novo-Moclobemide",
      "Nu-Moclobemide",
      "Nu-Pharm Brand Of Moclobemide",
      "PMS-Moclobemide",
      "Ro 11-1163",
      "Stada, Moclobemid",
      "Von Ct, Moclobemid",
      "GNF-PF-695",
      "Moclobemide (USAN/INN)",
      "Moclobemide [USAN:BAN:INN]",
      "Moclobemide [USAN:INN:BAN]",
      "Ro 11-1163/000",
      "Ro-11-1163",
      "P-Chloro-N-(2-Morpholinoethyl)Benzamide",
      "1A Brand Of Moclobemide",
      "4-Chlor-N-(2-Morpholinoethyl)Benzamid",
      "4-Chloro-N-(2-(4-Morpholinyl)Ethyl)Benzamide",
      "4-Chloro-N-(2-Morpholin-4-Yl-Ethyl)-Benzamide",
      "4-Chloro-N-(2-Morpholin-4-Ylethyl)Benzamide",
      "4-Chloro-N-[2-(Morpholin-4-Yl)Ethyl]benzamide",
    ],
  },
  {
    primary_id: "D01ZSP",
    names: ["AAB-003/PF-05236812"],
  },
  {
    primary_id: "D01ZTR",
    names: [
      "Colon Cancer Vaccine",
      "Colon Cancer Vaccine, Immune Response Corp",
      "Established Cancer Cell Line Therapy (Colon Cancer), Immune Response Corp",
      "IL-2 Vaccine (Colon Cancer) , Immune Response Corp",
    ],
  },
  {
    primary_id: "D01ZUA",
    names: [
      "Coenzyme Q10",
      "CoQ10",
      "Coenzyme Q10 (Oral Formulation)",
      "CoQ10 Platform Technology, Ryan (Receptagen)",
      "Coenzyme Q10 (Oral Formulation), Receptagen",
    ],
  },
  {
    primary_id: "D01ZUH",
    names: ["Short-Acting Thrombosis Inhibitor"],
  },
  {
    primary_id: "D01ZUN",
    names: ["3,3-Di(Pent-4-Enyl)Azetidin-2-One", "CHEMBL487447"],
  },
  {
    primary_id: "D01ZUV",
    names: ["GDC-0425"],
  },
  {
    primary_id: "D01ZVK",
    names: ["SCD-211"],
  },
  {
    primary_id: "D01ZWF",
    names: [
      "BPI-711001",
      "BPI-712001",
      "DPP-4 Inhibitors (Oral, Diabetes)",
      "DPP-4 Inhibitors (Oral, Diabetes), Beta Pharma",
    ],
  },
  {
    primary_id: "D01ZXW",
    names: ["Lintitript"],
  },
  {
    primary_id: "D01ZYG",
    names: ["Alemtuzumab", "Campath (TN)", "MabCampath (TN)", "Campath-1H (TN)"],
  },
  {
    primary_id: "D01ZYY",
    names: ["Intranasal Apomorphine"],
  },
  {
    primary_id: "D01ZZG",
    names: ["INV-311"],
  },
  {
    primary_id: "D01ZZJ",
    names: ["2-Amino-Uridine-5'-Monophosphate"],
  },
  {
    primary_id: "D02AAD",
    names: ["Biaryl Mannoside Derivative 31"],
  },
  {
    primary_id: "D02AAH",
    names: ["6-(4-Chloro-Benzenesulfonyl)-2H-Pyridazin-3-One"],
  },
  {
    primary_id: "D02AAO",
    names: ["AZD-4877", "Anticancer Therapeutic (Solid Tumors), AstraZeneca"],
  },
  {
    primary_id: "D02AAR",
    names: ["Indole And Benzimidazole Derivative 1"],
  },
  {
    primary_id: "D02ABC",
    names: ["PMID27410995-Compound-Figure3c"],
  },
  {
    primary_id: "D02ABO",
    names: ["NARINGENIN"],
  },
  {
    primary_id: "D02ABS",
    names: ["BMS-180560", "CHEMBL2021417"],
  },
  {
    primary_id: "D02ACL",
    names: [
      "Benzamil",
      "2898-76-2",
      "UNII-04659UUJ94",
      "N-(N-Benzylamidino)-3,5-Diamino-6-Chloropyrazine Carboxamide",
      "GNF-Pf-192",
      "CHEMBL212579",
      "CHEBI:34558",
      "04659UUJ94",
      "3,5-Diamino-6-Chloro-N-(Imino((Phenylmethyl)Amino)Methyl)Pyrazinecarboxamide",
      "3,5-Diamino-N-(N'-Benzylcarbamimidoyl)-6-Chloropyrazine-2-Carboxamide",
      "Pyrazinecarboxamide, 3,5-Diamino-6-Chloro-N-(Imino((Phenylmethyl)Amino)Methyl)-",
      "Spectrum3_001823",
      "Prestwick1_000657",
      "Prestwick3_000657",
      "Prestwick2_000657",
      "Prestwick0_000657",
      "Lopac-B-2417",
      "AC1L33CQ",
      "Lopac0_000211",
      "KBioGR_000300",
    ],
  },
  {
    primary_id: "D02ADC",
    names: ["SDZ-MKS-492"],
  },
  {
    primary_id: "D02ADJ",
    names: [
      "Apolipoprotein E",
      "Apolipoprotein E, Bio-Technology General",
      "Apo-A4, Bio-Technology General",
      "Apo-E, Bio-Technology General",
      "Apolipoprotein-A4, Bio-Technology General",
    ],
  },
  {
    primary_id: "D02ADM",
    names: ["Silibinin"],
  },
  {
    primary_id: "D02ADN",
    names: ["Methamphetamine Intravenous"],
  },
  {
    primary_id: "D02ADV",
    names: [
      "5-P-Tolyl-1H-[1,2,3]triazole",
      "4-(P-Tolyl)-1H-1,2,3-Triazole",
      "5301-96-2",
      "4-(P-Tolyl)-2H-1,2,3-Triazole",
      "5-(4-Methylphenyl)-1H-1,2,3-Triazole",
      "1H-1,2,3-Triazole, 4-(4-Methylphenyl)-",
      "4-(4-Methylphenyl)-2H-Triazole",
      "5-(P-Tolyl)-1H-1,2,3-Triazole",
      "59066-81-8",
      "V-Triazole, 4-P-Tolyl-",
      "AC1LC4W8",
      "SCHEMBL20848",
      "1,2,3-Triazole Analogue, 3",
      "CHEMBL193610",
      "CTK1G1560",
      "BDBM17447",
      "DTXSID60344093",
      "ZPCIKQLLQORQCV-UHFFFAOYSA-N",
      "MolPort-023-332-536",
      "4-P-Tolyl-1H-1,2,3-Triazole",
      "ZINC13671868",
      "ANW-65599",
      "AKOS015907809",
      "FCH4165656",
      "KS-0000101Z",
      "SC-23256",
      "AJ-64294",
      "AK-90097",
    ],
  },
  {
    primary_id: "D02AEI",
    names: ["BM-13.1196"],
  },
  {
    primary_id: "D02AEW",
    names: ["Xenon Xe-127"],
  },
  {
    primary_id: "D02AFI",
    names: [
      "1-D-Glucopyranosyl Cytosine",
      "CHEMBL1096136",
      "4-Amino-1-Beta-D-Glucopyranosylpyrimidin-2(1h)-One",
      "1-D-Glucopyranosyl-Cytosine",
      "SCHEMBL7520145",
      "1-Beta-D-Glucopyranosylcytosine",
      "BDBM50316588",
    ],
  },
  {
    primary_id: "D02AFZ",
    names: ["2-Propyl-Beta-Carboline-2-Ium Iodide", "CHEMBL1088723"],
  },
  {
    primary_id: "D02AIC",
    names: ["CP-870893"],
  },
  {
    primary_id: "D02AIH",
    names: ["1-(5-(Pyridin-2-Yl)Oxazol-2-Yl)Pentan-1-One", "CHEMBL174951", "SCHEMBL3194478", "BDBM50163189"],
  },
  {
    primary_id: "D02AIM",
    names: ["Metoprine"],
  },
  {
    primary_id: "D02AIN",
    names: ["AZD5718"],
  },
  {
    primary_id: "D02AIZ",
    names: ["BIL010t"],
  },
  {
    primary_id: "D02AKP",
    names: [
      "4-Methoxy-N'-(2-Phenylacetyl)Benzohydrazide",
      "4-Methoxy-N'-(Phenylacetyl)Benzohydrazide",
      "Benzohydrazide, 15",
      "AC1LEZHC",
      "CHEMBL244921",
      "ARONIS002261",
      "BDBM23731",
      "ZINC68809",
      "KS-00003VJE",
      "MolPort-000-681-545",
      "STK056698",
      "AKOS000492246",
      "MCULE-6943344531",
      "ST040019",
      "4-Methoxy-N -(Phenylacetyl)Benzohydrazide",
      "KB-115318",
      "4-Methoxy-N''-(2-Phenylacetyl)Benzohydrazide",
    ],
  },
  {
    primary_id: "D02ALD",
    names: [
      "NOLOMIROLE HYDROCHLORIDE",
      "CHF-1035",
      "Nolomirole Hydrochloride",
      "Rac-5,6-Diisobutyryloxy-2-(Methylamino)-1,2,3,4-Tetrahydronaphthalene Hydrochloride",
    ],
  },
  {
    primary_id: "D02ALI",
    names: ["H-Tyr-C[pen-Gly-Phe-Pen]OH"],
  },
  {
    primary_id: "D02ALK",
    names: [
      "6-Pyridin-3-Yl-Naphthalen-2-Ol",
      "6-Pyridin-3-Ylnaphthalen-2-Ol",
      "CHEMBL195571",
      "SCHEMBL3008490",
      "BDBM8906",
      "Pyridine-Substituted Naphthalene 3",
      "6-(Pyridin-3-Yl)Naphthalen-2-Ol",
    ],
  },
  {
    primary_id: "D02ALQ",
    names: ["PMID25050158C14", "CLH-304a", "GTPL8539", "J3.660.730K"],
  },
  {
    primary_id: "D02ALZ",
    names: ["ONO-3307", "4-Guanidinobenzoic Acid 4-Sulfamoylphenyl Ester Methanesulfonate"],
  },
  {
    primary_id: "D02AMG",
    names: [
      "HGS-1029",
      "AEG-40056",
      "AEG-40057",
      "AEG-40706",
      "AEG-40730",
      "AEG-40826",
      "XIAP Antagonists (Anticancer), Aegera",
      "Inhibitor Of Apoptosis (IAP) Protein Antagonists (Cancer), Aegera/HGS",
      "XIAP Antagonists (Small Molecule, Cancer), Aegera/HGS",
      "Inhibitory Apoptosis Protein (IAP) Antagonists (Apoptosis Stimulators, Cancer), Aegera/HGS",
    ],
  },
  {
    primary_id: "D02AMI",
    names: ["ISIS 156452"],
  },
  {
    primary_id: "D02ANE",
    names: [
      "1-(Naphthalen-2-Yl)-3-Aza-Bicyclo[3.1.0]hexane",
      "CHEMBL452045",
      "SCHEMBL4177088",
      "BDBM50243942",
      "SY247368",
      "MFCD28902249 (97%)",
    ],
  },
  {
    primary_id: "D02ANG",
    names: [
      "(2-Amino-4,5-Dihydro-Thiazol-4-Yl)-Acetic Acid",
      "CHEMBL79325",
      "2-(2-Amino-2-Thiazolin-4-Yl)Acetic Acid",
      "AC1NQ6SQ",
      "SCHEMBL2711624",
      "2-(2-Amino-4,5-Dihydro-1,3-Thiazol-4-Yl)Acetic Acid",
      "ANCWBXJZUCHVHL-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D02ANJ",
    names: ["NZ-107", "4-Bromo-5-(3-Ethoxy-4-Methoxybenzylamino)Pyridazin-3(2H)-One"],
  },
  {
    primary_id: "D02ANL",
    names: ["4-(3-Fluorobenzylthio)-2-Aminobutanoic Acid", "CHEMBL204611", "BDBM50179707"],
  },
  {
    primary_id: "D02ANO",
    names: ["LY-2393910"],
  },
  {
    primary_id: "D02ANZ",
    names: ["Phenylacetylrinvanil"],
  },
  {
    primary_id: "D02AOC",
    names: ["ISIS 104258"],
  },
  {
    primary_id: "D02AOD",
    names: [
      "HF-0220",
      "Cytoprotective Steroid (Alzheimer's Disease), Newron",
      "Prostaglandin D Synthase Stimulator (Alzheimer's Disease), Newron",
      "Prostaglandin J2 Synthesis Stimulator (Alzheimer's Disease), Newron",
      "Cytoprotective Steroid (Alzheimer's Disease), Hunter-Fleming",
      "Prostaglandin D Synthase Stimulator (Alzheimer's Disease), Hunter-Fleming",
      "Prostaglandin J2 Synthesis Stimulator (Alzheimer's Disease), Hunter-Fleming",
    ],
  },
  {
    primary_id: "D02AOT",
    names: ["Isoindolinone Urea Derivative", "CHEMBL184971"],
  },
  {
    primary_id: "D02AOV",
    names: ["Humanized Mabs"],
  },
  {
    primary_id: "D02APY",
    names: ["A-357300"],
  },
  {
    primary_id: "D02AQG",
    names: ["N-Benzyl-(6-Butoxy-2-Naphthyl)-2-Aminopropane", "CHEMBL456005"],
  },
  {
    primary_id: "D02AQL",
    names: ["AT-003"],
  },
  {
    primary_id: "D02AQM",
    names: ["VAR-10300"],
  },
  {
    primary_id: "D02AQY",
    names: ["Actarit"],
  },
  {
    primary_id: "D02ARA",
    names: ["(1-Benzyl-1H-Indol-5-Yl)-Quinazolin-4-Yl-Amine", "CHEMBL287591", "SCHEMBL8554934", "BDBM50099967"],
  },
  {
    primary_id: "D02ARR",
    names: ["Dehydrocholic Acid"],
  },
  {
    primary_id: "D02ATA",
    names: ["9-(3-N-Pentylureido)Non-4-Ynoic Acid", "CHEMBL551900"],
  },
  {
    primary_id: "D02AUB",
    names: ["Bimosiamose"],
  },
  {
    primary_id: "D02AUI",
    names: ["Technetium Tc-99m Mebrofenin Kit", "Choletec", "Technetium Tc-99m Mebrofenin"],
  },
  {
    primary_id: "D02AVC",
    names: ["BMS-820132"],
  },
  {
    primary_id: "D02AVL",
    names: ["ZK-813039", "DE-00684", "Factor Xa Inhibitor (Oral), Schering AG/Pfizer"],
  },
  {
    primary_id: "D02AVN",
    names: [
      "(3-Chloro-Phenyl)-Piperazin-1-Yl-Methanone",
      "100939-90-0",
      "1-(3-Chlorobenzoyl)Piperazine",
      "(3-Chlorophenyl)(Piperazin-1-Yl)Methanone",
      "BAS 04443403",
      "(3-Chlorophenyl)(1-Piperazinyl)Methanone",
      "CHEMBL160911",
      "Methanone, (3-Chlorophenyl)-1-Piperazinyl-",
      "1-[(3-Chlorophenyl)Carbonyl]piperazine",
      "3-Chlorophenyl Piperazinyl Ketone",
      "AC1LLWSU",
      "AC1Q3I0G",
      "TimTec1_008470",
      "4-(3-Chlorobenzoyl)Piperazine",
      "SCHEMBL2733887",
      "1-(3-Chlorobenzoyl)-Piperazine",
      "CTK6H2326",
      "DTXSID70360082",
      "MolPort-000-163-293",
      "FBTDRVSCKFZIQH-UHFFFAOYSA-N",
      "ZINC782987",
    ],
  },
  {
    primary_id: "D02AVZ",
    names: ["2-Phenylheme"],
  },
  {
    primary_id: "D02AWC",
    names: ["ActoBiotic", "ActoBiotic (Clostridium Difficile-Associated Enteric Disease)"],
  },
  {
    primary_id: "D02AWQ",
    names: ["AZD1419"],
  },
  {
    primary_id: "D02AWT",
    names: ["Ac-I[CV(Bpa)QDWGAHRC]T"],
  },
  {
    primary_id: "D02AXG",
    names: ["Testosterone Undecanoate"],
  },
  {
    primary_id: "D02AXS",
    names: ["GSK406725"],
  },
  {
    primary_id: "D02AYD",
    names: ["PMID19289283C32"],
  },
  {
    primary_id: "D02AYI",
    names: [
      "2-(4-Phenoxyphenoxy)Ethanamine",
      "CHEMBL472386",
      "2-(4-Phenoxyphenoxy)Ethylamine",
      "72490-14-3",
      "SCHEMBL9486899",
      "SCHEMBL4653229",
      "CTK2H2396",
      "DTXSID40452640",
      "FBLANPWAVFBRNS-UHFFFAOYSA-N",
      "2-[(P-Phenoxy)Phenoxy]ethylamine",
      "Ethanamine, 2-(4-Phenoxyphenoxy)-",
      "ZINC11945831",
      "BDBM50246062",
    ],
  },
  {
    primary_id: "D02AYL",
    names: ["PA-824"],
  },
  {
    primary_id: "D02AYX",
    names: [
      "2H-Chromeno[2,3-D]pyrimidine-2,4(3H)-Dione",
      "CHEMBL611665",
      "Chromeno[2,3-D]pyrimidine-2,4-Dione",
      "AC1LQMEG",
      "5-Deaza-10-Oxaflavin",
      "SCHEMBL11333239",
      "BFMCRAXOACCPEL-UHFFFAOYSA-",
      "ZINC1280587",
      "STK236511",
      "BDBM50309832",
      "AKOS000428551",
      "MCULE-3496773034",
      "ST50987740",
      "3-Hydrochromeno[2,3-D]pyrimidine-2,4-Dione",
      "2H,3H,4H-Chromeno[2,3-D]pyrimidine-2,4-Dione",
      "2H-[1]Benzopyrano[2,3-D]pyrimidine-2,4(3H)-Dione",
      "InChI=1/C11H6N2O3/C14-9-7-5-6-3-1-2-4-8(6)16-10(7)13-11(15)12-9/H1-5H,(H,12,14,15)",
    ],
  },
  {
    primary_id: "D02BAA",
    names: ["Patient-Derived CD19- And CD22 Specific CAR"],
  },
  {
    primary_id: "D02BAE",
    names: [
      "Synthetic Conjugated Estrogen",
      "Bijuva",
      "Bithena",
      "DR-2041",
      "SCE-A Vaginal Cream",
      "Synthetic Conjugated Estrogen (Cream, Vulvovaginal Atrophy)",
      "Synthetic Conjugated Estrogen (Cream, Vulvovaginal Atrophy), Teva",
    ],
  },
  {
    primary_id: "D02BAW",
    names: ["MK-6325"],
  },
  {
    primary_id: "D02BBA",
    names: ["EPZ005687"],
  },
  {
    primary_id: "D02BBC",
    names: [
      "CL-329167",
      "2-Butyl-6-(1-Methoxy-1-Methylethyl)-3-[2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Ylmethyl]quinazolin-4(3H)-One",
    ],
  },
  {
    primary_id: "D02BBJ",
    names: [
      "5-Hydroxy-2-(4-Methylaminophenyl)Benzofuran",
      "CHEMBL204829",
      "2-(4-Methylaminophenyl)Benzofuran-5-Ol",
      "CTK6I4824",
      "BDBM50185948",
      "ZINC13686348",
      "AKOS015966035",
    ],
  },
  {
    primary_id: "D02BBN",
    names: [
      "4-Amino-3-Benzofuran-2-Yl-Butyric Acid",
      "121838-28-6",
      "CHEMBL349520",
      "4-Amino-3-Benzo(B)Furan-2-Ylbutanoic Acid",
      "2-Benzofuranpropanoicacid, B-(Aminomethyl)-",
      "4-Amino-3-Benzo[b]furan-2-Ylbutanoic Acid",
      "Bfg-Butanoic Acid",
      "ACMC-20mpq1",
      "AC1L2VQL",
      "4-Amino-3-(1-Benzofuran-2-Yl)Butanoic Acid",
      "2-Benzofuranpropanoic Acid, Beta-(Aminomethyl)-",
      "CTK4B2770",
      "BDBM50021756",
      "AKOS015439841",
      "KB-240006",
    ],
  },
  {
    primary_id: "D02BBT",
    names: ["2-Bromo-N-(2-Morpholinoethyl)Nicotinamide", "CHEMBL607864", "BDBM50307208"],
  },
  {
    primary_id: "D02BCM",
    names: [
      "2-Furan-2-Yl-3-Phenethyl-3H-Quinazolin-4-One",
      "CHEMBL180336",
      "2-(Furan-2-Yl)-3-Phenethylquinazolin-4-One",
      "AC1LEB94",
      "Oprea1_070906",
      "2-(2-Furyl)-3-(2-Phenylethyl)-4(3H)-Quinazolinone",
      "ZINC49416",
      "NPS-53574",
      "BDBM50162542",
      "STK674065",
      "AKOS005593239",
      "MCULE-4160039246",
      "ST008066",
      "J3.621.250K",
      "2-(2-Furyl)-3-Phenethyl-4(3H)-Quinazolinone",
      "AG-205/11559428",
      "SR-01000416527",
      "SR-01000416527-1",
      "2-(2-Furyl)-3-(2-Phenylethyl)-3-Hydroquinazolin-4-One",
      "2-(Furan-2-Yl)-3-(2-Phenylethyl)Quinazolin-4(3H)-One",
      "A1715/0073151",
    ],
  },
  {
    primary_id: "D02BCY",
    names: ["CD19 CART"],
  },
  {
    primary_id: "D02BDL",
    names: ["Dibenzoylmethane", "Dibenzoylmethane (Chronic Inflammatory Disease/Cancer)"],
  },
  {
    primary_id: "D02BEJ",
    names: ["3-[5-(3,4-Difluorophenyl)-2-Thienyl]phenol", "CHEMBL566521"],
  },
  {
    primary_id: "D02BER",
    names: ["Quadazocine", "(-)-WIN 44,441", "(-)-Quadazocine"],
  },
  {
    primary_id: "D02BFC",
    names: ["Anti-Alpha5Beta1-Integrin Antibody"],
  },
  {
    primary_id: "D02BFK",
    names: ["4-(3-Chlorophenylethynyl)-2-Methylthiazole", "CHEMBL202124", "SCHEMBL4153004"],
  },
  {
    primary_id: "D02BGA",
    names: [
      "9-Methyl-8-[1,2,3]triazol-2-Yl-9H-Purin-6-Ylamine",
      "CHEMBL194728",
      "SCHEMBL4462539",
      "BDBM50176057",
      "496955-70-5",
      "9H-Purin-6-Amine, 9-Methyl-8-(2H-1,2,3-Triazol-2-Yl)-",
    ],
  },
  {
    primary_id: "D02BGL",
    names: ["DHAC"],
  },
  {
    primary_id: "D02BGR",
    names: [
      "(S)-N-[1-(3-Morpholin-4-Yl-Phenyl)-Ethyl]-3-Phenyl-Acrylamide",
      "(S)-1 [PMID: 16904708]",
      "Compound 2 [PMID: 12852750]",
    ],
  },
  {
    primary_id: "D02BGU",
    names: ["Pyrido/Pyrrolo-Fused Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D02BGZ",
    names: ["AXP-107-17"],
  },
  {
    primary_id: "D02BHP",
    names: ["Nacolomab Tafenatox"],
  },
  {
    primary_id: "D02BHW",
    names: ["IM19 CAR-T"],
  },
  {
    primary_id: "D02BIK",
    names: ["AR-534", "CHEMBL2177159"],
  },
  {
    primary_id: "D02BIY",
    names: ["DCVax-Direct"],
  },
  {
    primary_id: "D02BJB",
    names: ["RO116-9132/238"],
  },
  {
    primary_id: "D02BJK",
    names: ["CXCR-2 Targeting Mabs", "CXCR-2 Targeting MAbs (Cancer)"],
  },
  {
    primary_id: "D02BJN",
    names: ["Human Liver Progenitor Cells"],
  },
  {
    primary_id: "D02BLC",
    names: [
      "Lys-Thiol",
      "6-Amino-2-Mercapto-Hexanoic Acid",
      "CHEMBL432852",
      "GTPL8667",
      "SCHEMBL11506085",
      "6-Amino-2-Sulfanylhexanoic Acid",
      "BDBM50036831",
    ],
  },
  {
    primary_id: "D02BLD",
    names: ["Oprozomib"],
  },
  {
    primary_id: "D02BLO",
    names: ["Oxilan Iohexol"],
  },
  {
    primary_id: "D02BMJ",
    names: ["ND-601"],
  },
  {
    primary_id: "D02BMV",
    names: ["4-(2-Propynylthio)Pyridine-3-Sulfonamide", "CHEMBL1164486"],
  },
  {
    primary_id: "D02BNC",
    names: ["3-(1,8-Naphthyridin-2-Yl)Benzonitrile", "CHEMBL235397"],
  },
  {
    primary_id: "D02BOG",
    names: ["H-Tyr(OMe)-Phe(2-Me)-NH2", "CHEMBL589543", "SCHEMBL13538437"],
  },
  {
    primary_id: "D02BOW",
    names: ["McN-A-343"],
  },
  {
    primary_id: "D02BPI",
    names: ["TCPOBOP"],
  },
  {
    primary_id: "D02BPJ",
    names: ["4,5,6,7-Tetrahydrofuro[3,4-C]pyridine-1(3H)-One Derivative 1"],
  },
  {
    primary_id: "D02BPT",
    names: [
      "[3H]nicotine",
      "3-(1-Methylpyrrolidin-2-Yl)Pyridine",
      "22083-74-5",
      "Nikotin",
      "(R,S)-Nicotine",
      "Black Leaf 40",
      "Pyridine, 3-(1-Methyl-2-Pyrrolidinyl)-",
      "Black Leaf",
      "Nicotine Alkaloid",
      "Nicotin",
      "Nicocide",
      "Tendust",
      "(RS)-Nicotine",
      "Nico-Dust",
      "Emo-Nik",
      "Nico-Fume",
      "Mach-Nic",
      "Nic-Sal",
      "Nicotina",
      "Nikotyna",
      "CHEMBL440464",
      "(+-)-3-(1-Methyl-2-Pyrrolidinyl)Pyridine",
      "NSC5065",
      "SNICXCGAKADSCV-UHFFFAOYSA-N",
      "BRN 0082111",
      "3-(1-Methyl-2-Pyrrolidinyl)Pyridine",
    ],
  },
  {
    primary_id: "D02BPV",
    names: ["5-(2-Amino-Ethyl)-2-Chloro-Phenol Hydrobromide"],
  },
  {
    primary_id: "D02BPX",
    names: ["NGM 282"],
  },
  {
    primary_id: "D02BQE",
    names: [
      "E7820",
      "289483-69-8",
      "E-7820",
      "E 7820",
      "UNII-TVH5K7949N",
      "TVH5K7949N",
      "3-Cyano-N-(3-Cyano-4-Methyl-1H-Indol-7-Yl)Benzenesulfonamide",
      "N-(3-Cyano-4-Methyl-1H-Indol-7-Yl)-3-Cyanobenzene-Sulfonamide",
      "NSC-719239",
      "N-(3-Cyano-4-Methyl-1H-Indol-7-Yl)-3-Cyanobenzenesulfonamide",
      "Benzenesulfonamide, 3-Cyano-N-(3-Cyano-4-Methyl-1H-Indol-7-Yl)-",
      "LWGUASZLXHYWIV-UHFFFAOYSA-N",
      "AC1L52N5",
      "ZINC8704",
      "SCHEMBL1581157",
      "DTXSID20183142",
      "BCP25835",
      "EX-A1818",
      "3-Cyano-N-(3-Cyano-4-Methyl-1H-Indole-7-Yl)Benzensulfonamide",
      "NSC719239",
      "AKOS032944953",
      "CS-6075",
    ],
  },
  {
    primary_id: "D02BRA",
    names: ["ISIS-1939", "136216-59-6"],
  },
  {
    primary_id: "D02BRJ",
    names: ["Vabicaserin"],
  },
  {
    primary_id: "D02BTV",
    names: ["3-Benzenesulfinyl-Heptanoic Acid Hydroxyamide", "CHEMBL75433", "BDBM50089197"],
  },
  {
    primary_id: "D02BTZ",
    names: ["Twinrix", "Ambirix", "Hepatitis A+B Vaccine, GlaxoSmithKline"],
  },
  {
    primary_id: "D02BUW",
    names: ["2-(3,4,5-Trihydroxy-Benzylidene)-Malononitrile"],
  },
  {
    primary_id: "D02BVQ",
    names: ["TGC-9"],
  },
  {
    primary_id: "D02BVS",
    names: ["KNI-10761"],
  },
  {
    primary_id: "D02BVW",
    names: ["FF-10501-01"],
  },
  {
    primary_id: "D02BWQ",
    names: [
      "3-[3-Methyl-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "CHEMBL373074",
      "AC1O707F",
      "BDBM8647",
      "(3-Pyridylmethylene)Indane 36b",
      "3-[(Z)-(3-Methyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D02BWY",
    names: ["JNJ-64041757"],
  },
  {
    primary_id: "D02BXA",
    names: [
      "2-(2-(Pyrrolidin-1-Yl)Ethyl)Pyridine",
      "2-(2-Pyrrolidinoethyl)Pyridine",
      "6311-90-6",
      "CHEMBL270595",
      "2-[2-(Pyrrolidin-1-Yl)Ethyl]pyridine",
      "N-(2-(2-Pyridyl)Ethyl)Pyrrolidine",
      "NSC42644",
      "2-[2-(1-Pyrrolidinyl)Ethyl]pyridine",
      "AC1Q4VNT",
      "AC1L60PK",
      "AC1Q28PR",
      "SCHEMBL868571",
      "2-(2-Pyrrolizinoethyl)Pyridine",
      "CTK5B7636",
      "DTXSID60285694",
      "OXEFHEYEQUISKC-UHFFFAOYSA-N",
      "ZINC393798",
      "NSC-42644",
      "2-(2-Pyrrolidin-1-Ylethyl)Pyridine",
      "BDBM50372289",
      "AKOS006243249",
      "MCULE-8128571557",
      "2-(2-Pyrrolidin-1-Yl-Ethyl)-Pyridine",
      "Pyridine,2-[2-(1-Pyrrolidinyl)Ethyl]-",
      "2-[2-(1-Pyrrolidinyl)Ethyl]pyridine #",
    ],
  },
  {
    primary_id: "D02BXC",
    names: [
      "GNF-PF-117",
      "4-Hydroxy-6-Methoxy-2-Phenylquinoline",
      "17282-70-1",
      "6-Methoxy-2-Phenylquinolin-4-Ol",
      "6-Methoxy-2-Phenyl-4-Quinolinol",
      "6-Methoxy-2-Phenyl-1H-Quinolin-4-One",
      "CHEMBL14120",
      "6-Methoxy-2-Phenyl-Quinolin-4-Ol",
      "Maybridge3_005419",
      "AC1L3YAP",
      "Oprea1_238017",
      "SCHEMBL6534282",
      "SCHEMBL10610678",
      "CTK8F6034",
      "CTK7A7980",
      "MolPort-000-677-170",
      "KUC100231N",
      "HMS1446G07",
      "ZINC8649323",
      "2-Phenyl-4-Oxy-6-Methoxy-Chinolin",
      "KUC100231",
      "BDBM50041130",
      "SBB016283",
      "AKOS009868047",
      "AKOS000273771",
      "MCULE-6727803755",
      "AB10684",
      "CCG-252411",
      "IDI1_016806",
    ],
  },
  {
    primary_id: "D02BXG",
    names: [
      "1-Benzo[b]thiophen-2-Yl-Cyclohexylamine",
      "Cyclohexanamine, 1-Benzo[b]thien-2-Yl-",
      "CHEMBL18556",
      "143603-26-3",
      "NSC708121",
      "ACMC-20bbzv",
      "AC1L9GJR",
      "SCHEMBL7095248",
      "CTK0B4318",
      "DTXSID80327969",
      "HYVMNAZTYJDWIZ-UHFFFAOYSA-N",
      "ZINC1657638",
      "BDBM50040190",
      "AKOS030613260",
      "NSC-708121",
      "1-(1-Benzothien-2-Yl)Cyclohexanamine #",
      "Thionaphthene, 2-[1-Aminocyclohex-1-Yl]-",
      "1-(1-Benzothiophen-2-Yl)Cyclohexan-1-Amine",
    ],
  },
  {
    primary_id: "D02BXJ",
    names: ["PMID21561767C8h"],
  },
  {
    primary_id: "D02BXP",
    names: ["Small-Molecule Cetuximab Binding Mimics"],
  },
  {
    primary_id: "D02BXS",
    names: ["4'-(Pyridin-4-Ylmethyl)Biphenyl-3,4-Diamine", "CHEMBL1173327", "SCHEMBL18770336", "BDBM50322785"],
  },
  {
    primary_id: "D02BYS",
    names: ["N-Mesityl-6-Methyl-3-Tosylpyridin-2-Amine", "CHEMBL204967", "SCHEMBL5861890"],
  },
  {
    primary_id: "D02BYZ",
    names: ["ASP8273"],
  },
  {
    primary_id: "D02BZV",
    names: [
      "BMS-1",
      "474429-87-3",
      "Carbamic Acid,N-[6-(2-Methyl-4,5-Diphenyl-1H-Imidazol-1-Yl)Hexyl]-,2-Fluorophenyl Ester",
      "SCHEMBL1709522",
      "CHEMBL232782",
      "Bisarylimidazole Derivative, 17",
      "BDBM26758",
      "ZINC1912275",
      "KB-75932",
      "2-Fluorophenyl N-[6-(2-Methyl-4,5-Diphenyl-1H-Imidazol-1-Yl)Hexyl]carbamate",
    ],
  },
  {
    primary_id: "D02CAF",
    names: ["NOX-H94"],
  },
  {
    primary_id: "D02CAT",
    names: [
      "2-(2-Iodo-Benzoyl)-Cyclohexane-1,3-Dione",
      "2-(2-Iodobenzoyl)Cyclohexane-1,3-Dione",
      "CHEMBL165937",
      "88562-10-1",
      "1,3-Cyclohexanedione, 2-(2-Iodobenzoyl)-",
      "ACMC-20lbdr",
      "CTK3A9586",
      "DTXSID90558702",
    ],
  },
  {
    primary_id: "D02CAU",
    names: ["Triazole Derivative 7"],
  },
  {
    primary_id: "D02CBD",
    names: [
      "J-30",
      "7-Aroyl-Amino-1-Benzenesulfonamides (Cancer), National Health Research Inst/Taipei Medical University",
    ],
  },
  {
    primary_id: "D02CBE",
    names: ["ISIS 112724"],
  },
  {
    primary_id: "D02CBR",
    names: [
      "(S)-3-Amino-2-Methylpropanoate",
      "(2S)-3-Azaniumyl-2-Methylpropanoate",
      "L-3-Amino-Isobutanoate",
      "AC1ODZ52",
      "GTPL6610",
      "CHEBI:58655",
      "(S)-3-Aminoisobutyric Acid Zwitterion",
    ],
  },
  {
    primary_id: "D02CCA",
    names: ["GSK-172981"],
  },
  {
    primary_id: "D02CCB",
    names: ["2-(2,3,4-Trimethoxyphenyl)-1H-Indene", "CHEMBL228561"],
  },
  {
    primary_id: "D02CCD",
    names: [
      "1-[6-(Triphenylmethoxy)Hexyl]thymine",
      "CHEMBL216998",
      "921587-93-1",
      "CTK3G1844",
      "DTXSID00658828",
      "BDBM50200990",
      "5-Methyl-1-[6-(Triphenylmethoxy)Hexyl]pyrimidine-2,4(1H,3H)-Dione",
      "2,4(1H,3H)-Pyrimidinedione, 5-Methyl-1-[6-(Triphenylmethoxy)Hexyl]-",
    ],
  },
  {
    primary_id: "D02CCF",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 20"],
  },
  {
    primary_id: "D02CDS",
    names: [
      "Edarbi",
      "Azilsartan Medoxomil",
      "AZILSARTAN MEDOXOMIL",
      "863031-21-4",
      "Azilsartan (Medoxomil)",
      "UNII-LL0G25K7I2",
      "Azilsartan Medoxomilo",
      "Azilsartanum Medoxomilum",
      "Azilsartan Kamedoxomil",
      "LL0G25K7I2",
      "1-[[2'-(2,5-Dihydro-5-Oxo-1,2,4-Oxadiazol-3-Yl)[1,1'-Biphenyl]-4-Yl]Methyl]-2-Ethoxy-1H-BenziMidazole-7-Carboxylic Acid (5-Methyl-2-Oxo-1,3-Dioxol-4-Yl)Methyl Ester",
      "CHEBI:68845",
      "AK-68150",
      "W-203965",
      "(5-Methyl-2-Oxo-1,3-Dioxol-4-Yl)Methyl 2-Ethoxy-1-{[2'-(5-Oxo-4,5-Dihydro-1,2,4-Oxadiazol-3-Yl)Biphenyl-4-Yl]methyl}-1-Benzimidazole-7-Carbox",
    ],
  },
  {
    primary_id: "D02CEO",
    names: ["SND-226"],
  },
  {
    primary_id: "D02CEY",
    names: ["PMID25553724-Compound-WO2013082253 33"],
  },
  {
    primary_id: "D02CFE",
    names: ["NC-2100"],
  },
  {
    primary_id: "D02CFH",
    names: ["PHENYLTHIOUREA"],
  },
  {
    primary_id: "D02CFP",
    names: [
      "Citicoline",
      "Alphcolin",
      "CerAxon",
      "Citicholine",
      "Hipercol",
      "Neurex",
      "Nicholin",
      "Somazina",
      "Trausan",
      "Citicoline Sodium",
      "Cytidil Diphosphocholine",
      "CDP-Choline",
      "IP-302",
      "Cytidine-5-Diphosphocholine",
    ],
  },
  {
    primary_id: "D02CFR",
    names: ["GW-559768X"],
  },
  {
    primary_id: "D02CFV",
    names: ["Solubilized Type 1 Native Bovine Collagen"],
  },
  {
    primary_id: "D02CGC",
    names: ["Sorbinil"],
  },
  {
    primary_id: "D02CGG",
    names: ["2-Hexylphenyl Acrylate", "SCHEMBL4475068"],
  },
  {
    primary_id: "D02CGY",
    names: ["Bifemelane", "Alnert", "Celeport", "E-0687", "MCI-2016", "SON-216"],
  },
  {
    primary_id: "D02CHG",
    names: [
      "6-Chloro-2-Phenyl-Chromen-4-One",
      "6-Chloroflavone",
      "10420-73-2",
      "6-Chloro-2-Phenyl-4H-Chromen-4-One",
      "MLS002701775",
      "CHEMBL293478",
      "6-Chloro-2-Phenylchromen-4-One",
      "NSC63999",
      "4l2f",
      "AC1L6LIW",
      "Maybridge4_000538",
      "AC1Q3RN7",
      "NCIOpen2_003001",
      "Oprea1_047009",
      "SCHEMBL4649891",
      "ZINC58093",
      "DTXSID10289783",
      "MolPort-002-893-915",
      "IFNDLWHUYFSXBK-UHFFFAOYSA-N",
      "HMS1522I10",
      "KS-000017VJ",
      "MRS-1131",
      "NSC621450",
      "BDBM50051364",
      "NSC-63999",
      "5979AH",
      "AKOS000603599",
      "MCULE-8596095757",
      "MB00919",
      "CCG-233377",
      "NSC-621450",
      "IDI1_031120",
      "NCGC00177472-01",
      "SMR001565369",
      "ZB002315",
    ],
  },
  {
    primary_id: "D02CII",
    names: ["Abaloparatide"],
  },
  {
    primary_id: "D02CIT",
    names: ["EI1"],
  },
  {
    primary_id: "D02CIX",
    names: ["Cyclopropylamine Derivative 11"],
  },
  {
    primary_id: "D02CJA",
    names: ["CM-2236"],
  },
  {
    primary_id: "D02CJU",
    names: ["Peptidomimetic Analog 3"],
  },
  {
    primary_id: "D02CJX",
    names: ["Desoxycorticosterone Acetate", "Doca"],
  },
  {
    primary_id: "D02CKF",
    names: ["13-(3-N-Pentylureido)Tridec-8-Ynoic Acid", "CHEMBL551161"],
  },
  {
    primary_id: "D02CKH",
    names: ["OPI-1002"],
  },
  {
    primary_id: "D02CKL",
    names: ["KNI-10113"],
  },
  {
    primary_id: "D02CKM",
    names: ["N-(3-Chlorophenyl)-3-Cyano-5-Fluorobenzamide"],
  },
  {
    primary_id: "D02CKO",
    names: ["Anoro"],
  },
  {
    primary_id: "D02CKX",
    names: [
      "Carbimazole",
      "Athyromazole",
      "Atirozidina",
      "Basolest",
      "Carbethoxymethimazole",
      "Carbimazol",
      "Carbimazolum",
      "Carbinazole",
      "Carbotiroid",
      "Mertiran",
      "Neomercazole",
      "TYRAZOL",
      "Thyrostat",
      "Carbimazol Henning",
      "Carbimazol Spofa",
      "CG1",
      "Cg 1",
      "Carbimazol Henning (TN)",
      "Carbimazole (INN)",
      "Carbimazolum [INN-Latin]",
      "Neo-Mercazole",
      "Neo-Thyreostat",
      "Neo-Tireol",
      "Carbimazol [INN-Spanish, French]",
      "Carbimazole [INN:BAN:DCF]",
      "Ethyl 3-Methyl-2-Sulfanylideneimidazole-1-Carboxylate",
      "Ethyl 3-Methyl-2-Thioimidazoline-1-Carboxylate",
      "Ethyl 3-Methyl-2-Thioxo-2,3-Dihydro-1H-Imidazole-1-Carboxylate",
      "1-Ethoxycarbonyl-3-Methyl-2-Thioimidazol",
      "1-Ethoxycarbonyl-3-Methyl-2-Thioxo-4-Imidazoline",
      "1H-Imidazole-1-Carboxylic Acid, 2,3-Dihydro-3-Methyl-2-Thioxo, Ethyl Ester",
      "1H-Imidazole-1-Carboxylic Acid, 2,3-Dihydro-3-Methyl-2-Thioxo-, Ethyl Ester",
      "3-Methyl-2-Thioxo-4-Imidazoline-1-Carboxylic Acid Ethyl Ester",
      "4-Imidazoline-1-Carboxylic Acid, 3-Methyl-2-Thioxo-, Ethyl Ester",
    ],
  },
  {
    primary_id: "D02CLU",
    names: [
      "DNA Vaccine, Memorial Sloan-Kettering Cancer Center",
      "DNA Vaccine (Cancer), Memorial Sloan-Kettering Cancer Center",
      "DNA Vaccine (Melanoma), Memorial Sloan-Kettering Cancer Center",
    ],
  },
  {
    primary_id: "D02CLV",
    names: ["AdCh63 AMA1"],
  },
  {
    primary_id: "D02CND",
    names: ["KI-0901"],
  },
  {
    primary_id: "D02CNG",
    names: ["ISIS 100748"],
  },
  {
    primary_id: "D02CNR",
    names: ["Anecortave Acetate"],
  },
  {
    primary_id: "D02COM",
    names: ["7-Tert-Butyl-2, 3-Dihydro-3, 3-Dimethyl Substituted Dihydrofuran 30 (DHDMBF30)"],
  },
  {
    primary_id: "D02COQ",
    names: ["Petrosaspongiolide P"],
  },
  {
    primary_id: "D02COS",
    names: ["IdB-1016"],
  },
  {
    primary_id: "D02CPP",
    names: ["TAK-438"],
  },
  {
    primary_id: "D02CQG",
    names: ["Peptide Analog 39"],
  },
  {
    primary_id: "D02CQL",
    names: ["Adyvia"],
  },
  {
    primary_id: "D02CQN",
    names: ["5-(4-Chlorophenyl)-N-(2-Methylbenzyl)Nicotinamide", "CHEMBL1269882"],
  },
  {
    primary_id: "D02CQW",
    names: ["H-D-Phe-C[Cys-Ala-D-Trp-Lys-Thr-Cys]-Thr-NH2", "CHEMBL376703"],
  },
  {
    primary_id: "D02CRM",
    names: ["KI-0907"],
  },
  {
    primary_id: "D02CSC",
    names: ["NSC-745887"],
  },
  {
    primary_id: "D02CSO",
    names: ["Ac-I[CVWQDWG(Abu)HRC]T-NH2"],
  },
  {
    primary_id: "D02CSU",
    names: [
      "4-Indan-(1Z)-Ylidenemethyl-Pyridine",
      "(4-Pyridylmethylene)Indane 2b",
      "SCHEMBL4230792",
      "CHEMBL179149",
      "BDBM8612",
      "AC1O705N",
      "4-[(Z)-2,3-Dihydroinden-1-Ylidenemethyl]pyridine",
      "4-[(1Z)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine",
      "4-[(Z)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine Oxalate",
    ],
  },
  {
    primary_id: "D02CTG",
    names: ["Carbamide Derivative 3"],
  },
  {
    primary_id: "D02CTS",
    names: ["Ospemifene"],
  },
  {
    primary_id: "D02CTZ",
    names: ["LY433221"],
  },
  {
    primary_id: "D02CUF",
    names: ["Benzyl-(6,7-Dimethoxy-Quinolin-3-Yl)-Amine", "CHEMBL65850", "ZINC3834030"],
  },
  {
    primary_id: "D02CUN",
    names: ["NM504"],
  },
  {
    primary_id: "D02CVF",
    names: ["SynGEM"],
  },
  {
    primary_id: "D02CWE",
    names: ["Dioxothiomolybdenum(VI) Ion", "DIOXOTHIOMOLYBDENUM(VI) ION", "Dioxo(Sulfanyl)Molybdenum", "CHEBI:47566"],
  },
  {
    primary_id: "D02CWP",
    names: ["Taurolithocholic Acid-3-Sulphate"],
  },
  {
    primary_id: "D02CWR",
    names: ["OXYBENZONE"],
  },
  {
    primary_id: "D02CWZ",
    names: [
      "AMBAZONE",
      "Ambazone < Rec INNM",
      "P-Benzoquinone Amidinohydrazone Thiosemicarbazone Monohydrate",
      "1,4-Benzoquinoneguanylhydrazone Thiosemicarbazone Monohydrate",
    ],
  },
  {
    primary_id: "D02CXA",
    names: [
      "LG-912",
      "E1/E2-Expressing Lentiviral Vector-Based Gene Therapy (VLPs, HCV Infection), Lentigen",
      "E1/E2-Expressing Lentiviral Vector-Based Gene Therapy (Virus-Like Particles, Hepatitis C Virus Infection), Lentigen/Epixis",
    ],
  },
  {
    primary_id: "D02CXU",
    names: ["3-Methoxy-4-(M-Tolyloxy)Benzonitrile", "CHEMBL447604"],
  },
  {
    primary_id: "D02CXV",
    names: ["Lartruvoolaratumab"],
  },
  {
    primary_id: "D02CXZ",
    names: ["PMID26815044-Compound-123"],
  },
  {
    primary_id: "D02CYL",
    names: ["MOR-208"],
  },
  {
    primary_id: "D02CYQ",
    names: [
      "2-Hydroxycarbamoyl-Pentanedioic Acid",
      "CHEMBL432726",
      "SCHEMBL13996815",
      "BDBM50129193",
      "2-(Hydroxycarbamoyl)Pentanedioic Acid",
    ],
  },
  {
    primary_id: "D02CYR",
    names: ["Chromic Phosphate P 32"],
  },
  {
    primary_id: "D02CYZ",
    names: [
      "[2-(Pyrazol-1-Yl)Ethyl]-Bisphosphonic Acid",
      "CHEMBL457069",
      "2-(Pyrazol-1-Yl)Ethane-1,1-Diphosphonic Acid",
      "SCHEMBL10410288",
      "UUGUSMHQBIUYKQ-UHFFFAOYSA-N",
      "BDBM50273715",
    ],
  },
  {
    primary_id: "D02CZC",
    names: ["4-Fluorophenyl-2,2-Diphenylacetamide", "CHEMBL258604", "SCHEMBL2337394"],
  },
  {
    primary_id: "D02CZF",
    names: ["Aderbasib"],
  },
  {
    primary_id: "D02CZK",
    names: ["Karenitecin"],
  },
  {
    primary_id: "D02CZO",
    names: ["6-Ethyl-3-(3-Methylbutoxycarbonyl)-4-Quinolone", "CHEMBL206071"],
  },
  {
    primary_id: "D02DAJ",
    names: ["DCDPC", "Dichloro-Diphenylamine 2-Carboxylic Acid"],
  },
  {
    primary_id: "D02DAU",
    names: ["ISIS 183910"],
  },
  {
    primary_id: "D02DBA",
    names: ["Helicobacter Pylori Vaccine"],
  },
  {
    primary_id: "D02DBH",
    names: ["PMID28766366-Compound-Scheme21Left"],
  },
  {
    primary_id: "D02DBP",
    names: ["RESORCINOL"],
  },
  {
    primary_id: "D02DCD",
    names: ["4-(4-Tert-Butylbenzylideneamino)Benzoic Acid", "CHEMBL567821"],
  },
  {
    primary_id: "D02DCM",
    names: ["5-(3-(4-Fluorobenzyl)Pyrrolidin-3-Yl)-1H-Indole", "CHEMBL513258", "SCHEMBL982062"],
  },
  {
    primary_id: "D02DDI",
    names: ["KYS-05064"],
  },
  {
    primary_id: "D02DDM",
    names: [
      "2-(2-Methoxyphenyl)-6-Morpholino-4H-Pyran-4-One",
      "CHEMBL105256",
      "SCHEMBL3538816",
      "BDBM50132363",
      "2-Morpholino-6-(2-Methoxyphenyl)-4H-Pyran-4-One",
    ],
  },
  {
    primary_id: "D02DEF",
    names: ["JNJ-42153605"],
  },
  {
    primary_id: "D02DEH",
    names: ["GM-602"],
  },
  {
    primary_id: "D02DES",
    names: ["Anakinra"],
  },
  {
    primary_id: "D02DEZ",
    names: ["GALE-301"],
  },
  {
    primary_id: "D02DFJ",
    names: ["Biaryl Mannoside Derivative 10"],
  },
  {
    primary_id: "D02DFT",
    names: ["4-(5-(4-(Methylamino)Phenyl)Thiophen-2-Yl)Phenol", "CHEMBL205512"],
  },
  {
    primary_id: "D02DGP",
    names: ["H-Lys-Arg-Pro-Hyp-Gly-Thi-Ser-D-BT-OH(JMV1669)", "CHEMBL412824"],
  },
  {
    primary_id: "D02DGT",
    names: ["IS20I"],
  },
  {
    primary_id: "D02DGU",
    names: ["Tretinoin"],
  },
  {
    primary_id: "D02DHS",
    names: ["ISIS 133245"],
  },
  {
    primary_id: "D02DHU",
    names: ["SKF-81297"],
  },
  {
    primary_id: "D02DJF",
    names: ["BAX-111"],
  },
  {
    primary_id: "D02DJG",
    names: [
      "LY-2189102",
      "Anti-IL-1 Beta MAb (Cardiovascular Disease), Lilly",
      "Anti IL-1 Beta Antibody (Non-Insulin Dependent Diabetes, Rheumatoid Athritis), Lilly",
    ],
  },
  {
    primary_id: "D02DJR",
    names: ["AMG 167"],
  },
  {
    primary_id: "D02DJS",
    names: ["Iodoxamate Meglumine", "Cholovue"],
  },
  {
    primary_id: "D02DKD",
    names: [
      "Benzquinamide",
      "Benzchinamid",
      "Benzchinamide",
      "Benzchinamidum",
      "Benzochinamide",
      "Benzoquinamida",
      "Benzoquinamide",
      "Benzquinamid",
      "Benzquinamida",
      "Benzquinamidum",
      "Emeticon",
      "Promecon",
      "Quantril",
      "Quantryl",
      "P 2647",
      "Benzoquinamida [INN-Spanish]",
      "Benzquinamide (USAN)",
      "Benzquinamidum [INN-Latin]",
      "Emete-Con",
      "P-2647",
      "Benzquinamide [USAN:INN:BAN]",
      "[3-(Diethylcarbamoyl)-9,10-Dimethoxy-2,3,4,6,7,11b-Hexahydro-1H-Benzo[a]quinolizin-2-Yl] Acetate",
      "N,N-Diethyl-1,3,4,6,7,11b-Hexahydro-2-Hydroxy-9,10-Dimethoxy-2H-Benzo(A)Quinolizine-3-Carboxamideacetate",
      "N,N-Diethyl-1,3,4,6,7,11b-Hexahydro-2-Hydroxy-9,10-Dimethoxy-2H-Benzo(A)Quinolizine-3-Carboxamide Acetate (Ester)",
      "N,N-Diethyl-1,3alpha,4,6,7,11bbeta-Hexahydro-2beta-Hydroxy-9,10-Dimethoxy-2H-Benzo(A)Quinolizine-3-Carboxamide Acetate (Ester)",
      "2-(Acetyloxy)-N,N-Diethyl-1,3,4,6,7,11b-Hexahydro-9,10-Dimethoxy-2H-Benzo(A)Quinolizine-3-Carboxamide",
      "2-(Acetoxy)-N,N-Diethyl-1,3,4,6,7,11b-Hexahydro-9,10-Dimethoxy-2H-Benzo[a]quinolizine-3-Carboxamide",
      "2-(Acetyloxy)-N,N-Diethyl-1,3,4,6,7,11b-Hexahydro-9,10-Dimethoxy-2H-Benzo[a]quinolizine-3-Carboxamide",
      "2-Acetoxy-3-(N,N-Diethylcarboxamido)-9,10-Dimethoxy-1,2,3,4,6,7-Hexahydro-11bH-Benzopyridocoline",
      "2-Acetoxy-3-Diethylcarbamyl-9,10-Dimethoxy-1,2,3,4,6,7-Hexahydro-11b-Benzo(A)Quinolizine",
      "2-Hydroxy-3-Diethylcarbamyl-9,10-Dimethoxy-1,2,3,4,6,7-Hexahydro-11bH-Benzoquinolizine Acetate",
      "2H-Benzo(A)Quinolizine-3-Carboxamide, 2-(Acetyloxy)-N,N-Diethyl-1,3,4,6,7,11b-Hexahydro-9,10-Dimethoxy-, (2alpha,3beta,11balpha)-(9CI)",
      "2H-Benzo(A)Quinolizine-3-Carboxamide, N,N-Diethyl-1,3,4,6,7,11b-Hexahydro-2-Hydroxy-9,10-Dimethoxy-, Acetate (Ester)",
      "2H-Benzo(A)Quinolizine-3-Carboxamide, N,N-Diethyl-1,3alpha,4,6,7,11bbeta-Hexahydro-2beta-Hydroxy-9,10-Dimethoxy-, Acetate (Ester)",
      "2H-Benzo[a]quinolizine-3-Carboxamide, N,N-Diethyl-1,3.alpha.,4,6,7,11b.beta.-Hexahydro-2.beta.-Hydroxy-9,10-Dimethoxy-, Acetate (Ester)",
      "3-(Diethylcarbamoyl)-9,10-Dimethoxy-1,3,4,6,7,11b-Hexahydro-2H-Pyrido[2,1-A]isoquinolin-2-Yl Acetate",
      "3-Diethylcarbamoyl-2,3,4,6,7,11b-Hexahydro-9,10-Dimethoxy-1H-Benzo(A)Chinolizin-2-Yl Acetat",
      "3-[(Diethylamino)Carbonyl]-9,10-Bis(Methyloxy)-1,3,4,6,7,11b-Hexahydro-2H-Pyrido[2,1-A]isoquinolin-2-Yl Acetate",
    ],
  },
  {
    primary_id: "D02DKM",
    names: ["Propan-2-One O-4-(Hexyloxy)Phenylcarbamoyl Oxime", "CHEMBL597088"],
  },
  {
    primary_id: "D02DKX",
    names: ["LK 204-545"],
  },
  {
    primary_id: "D02DLC",
    names: ["PYRAZOLOPYRIDAZINE 1"],
  },
  {
    primary_id: "D02DLE",
    names: [
      "CGS-26303",
      "Cgs-26303",
      "Cgs 26303",
      "154116-31-1",
      "CHEMBL290698",
      "(S)-2-Biphenyl-4-Yl-1-(1H-Tetrazol-5-Yl)Ethylaminomethyl Phosphonic Acid",
      "Cgs26303",
      "SCHEMBL653011",
      "AC1L31X6",
      "CTK4C8115",
      "DTXSID40165541",
      "BDBM50064106",
      "Phosphonic Acid, (((2-(1,1'-Biphenyl)-4-Yl-1-(1H-Tetrazol-5-Yl)Ethyl)Amino)Methyl)-, (S)-",
      "{[(S)-2-Biphenyl-4-Yl-1-(2H-Tetrazol-5-Yl)-Ethylamino]-Methyl}-Phosphonic Acid",
      "{[(S)-2-Biphenyl-4-Yl-1-(1H-Tetrazol-5-Yl)-Ethylamino]-Methyl}-Phosphonic Acid",
      "{[(R)-2-Biphenyl-4-Yl-1-(1H-Tetrazol-5-Yl)-Ethylamino]-Methyl}-Phosphonic Ac",
    ],
  },
  {
    primary_id: "D02DLG",
    names: ["PMID28870136-Compound-38"],
  },
  {
    primary_id: "D02DLL",
    names: ["PMID27539678-Compound-6"],
  },
  {
    primary_id: "D02DLP",
    names: ["VOFOPITANT HYDROCHLORIDE"],
  },
  {
    primary_id: "D02DLW",
    names: [
      "Cholesterol",
      "57-88-5",
      "Cholesterin",
      "Cholest-5-En-3beta-Ol",
      "Cholesteryl Alcohol",
      "Cholestrin",
      "Dusoran",
      "Cordulan",
      "Dusoline",
      "Cholesterine",
      "Hydrocerin",
      "Cholestrol",
      "Kathro",
      "Dythol",
      "Lanol",
      "Super Hartolan",
      "Provitamin D",
      "Cholesterol Base H",
      "Lidinite",
      "Nimco Cholesterol Base H",
      "Lidinit",
      "Wool Alcohols B. P.",
      "(-)-Cholesterol",
      "Tegolan (VAN)",
      "Cholest-5-En-3-Beta-Ol",
      "5:6-Cholesten-3beta-Ol",
      "Tegolan",
      "3beta-Hydroxycholest-5-Ene",
      "5-Cholesten-3beta-Ol",
      "Cholest-5-En-3-Ol (3beta)-",
      "3-Beta-Hydroxycholest-5-Ene",
      "(3beta)-Cholest-5-En-3-Ol",
      "CCRIS 2834",
      "Dastar",
      "HSDB 7",
    ],
  },
  {
    primary_id: "D02DML",
    names: [
      "COUMESTROL",
      "479-13-0",
      "Cumoestrol",
      "Cumoesterol",
      "Cumostrol",
      "Coumesterol",
      "3,9-Dihydroxy-6H-[1]benzofuro[3,2-C]chromen-6-One",
      "7,12-Dihydroxycoumestan",
      "3,9-Dihydroxycoumestan",
      "UNII-V7NW98OB34",
      "NSC 22842",
      "CCRIS 7311",
      "NSC22842",
      "6H-Benzofuro[3,2-C][1]benzopyran-6-One, 3,9-Dihydroxy-",
      "EINECS 207-525-6",
      "BRN 0266702",
      "CHEMBL30707",
      "MLS000738006",
      "V7NW98OB34",
      "CHEBI:3908",
      "ZZIALNLLNHEQPJ-UHFFFAOYSA-N",
      "3,9-Dihydroxy-6H-Benzofuro(3,2-C)(1)Benzopyran-6-One",
      "6H-Benzofuro(3,2-C)(1)Benzopyran-6-One, 3,9-Dihydroxy-",
    ],
  },
  {
    primary_id: "D02DMQ",
    names: [
      "Eletriptan",
      "Eletriptanum",
      "Relpax",
      "UK 116044",
      "UK116044",
      "Eletriptan (INN)",
      "Eletriptan [INN:BAN]",
      "Relpax (TN)",
      "UK-116044",
      "UK-116044-04",
      "(R)-3-[(1-Methyl-2-Pyrrolidinyl)Methyl]-5-[2-(Phenylsulfonyl)Ethyl]-1H-Indole",
      "(R)-5-[2-(Benzenesulfonyl)Ethyl]-3-[(N-Methylpyrrolidin-2-Yl)Methyl]-1H-Indole",
      "3-(((R)-1-Methyl-2-Pyrrolidinyl)Methyl)-5-(2-(Phenylsulfonyl)Ethyl)Indole",
      "3-{[(2R)-1-Methylpyrrolidin-2-Yl]methyl}-5-[2-(Phenylsulfonyl)Ethyl]-1H-Indole",
      "5-[2-(Benzenesulfonyl)Ethyl]-3-[[(2R)-1-Methylpyrrolidin-2-Yl]methyl]-1H-Indole",
    ],
  },
  {
    primary_id: "D02DMW",
    names: ["S-1360"],
  },
  {
    primary_id: "D02DNA",
    names: ["Cureskin", "Autologous Adult Stem Cell Therapy (Scars), S.Biomedics"],
  },
  {
    primary_id: "D02DNH",
    names: ["Therapeutic Mabs"],
  },
  {
    primary_id: "D02DOE",
    names: ["PMID25747044-Compound-MiRNA"],
  },
  {
    primary_id: "D02DOK",
    names: ["SJ-8002"],
  },
  {
    primary_id: "D02DOP",
    names: [
      "3-(1-Pyridin-2-Yl-1H-Pyrazol-3-Yl)-Benzonitrile",
      "CHEMBL182771",
      "SCHEMBL3922673",
      "LQROCYQCXGUXNA-UHFFFAOYSA-N",
      "ZINC13582316",
      "BDBM50151902",
    ],
  },
  {
    primary_id: "D02DOQ",
    names: [
      "Diisopropyl 2-(Sulfanylmethyl)Phenylphosphonate",
      "CHEMBL1173337",
      "Bis(1-Methylethyl) [2-(Sulfanylmethyl)Phenyl]phosphonate",
      "3iof",
      "BDBM50322605",
      "Dipropan-2-Yl [2-(Sulfanylmethyl)Phenyl]phosphonate",
    ],
  },
  {
    primary_id: "D02DOX",
    names: ["NSC-677939"],
  },
  {
    primary_id: "D02DOZ",
    names: ["ALKS 3831"],
  },
  {
    primary_id: "D02DPA",
    names: [
      "Cimetidine",
      "Acibilin",
      "Acinil",
      "Altramet",
      "Brumetidina",
      "Cimal",
      "Cimetadine",
      "Cimetag",
      "Cimetidina",
      "Cimetidinum",
      "Cimetum",
      "Dyspamet",
      "Edalene",
      "Eureceptor",
      "Evicer",
      "Gastrobitan",
      "Gastromet",
      "Histodil",
      "Magicul",
      "Metracin",
      "Peptol",
      "Sigmetadine",
      "Tagamet",
      "Tametin",
      "Tratul",
      "Ulcedin",
      "Ulcedine",
      "Ulcestop",
      "Ulcimet",
      "Ulcofalk",
      "Ulcomedina",
      "Ulcomet",
      "Ulhys",
      "Valmagen",
      "Venopex",
      "Ci Metum",
      "Cimetidine Hcl",
      "Tagamet Hb",
      "Biomet400",
      "C 4522",
      "FPF 1002",
      "SKF 92334",
      "Tagamet Hb 200",
      "CIMETIDINE A/AB",
      "Cimetidina [INN-Spanish]",
      "Cimetidinum [INN-Latin]",
      "DRG-0150",
      "SKF-92334",
      "Tagamet (TN)",
      "Tagamet HB (TN)",
      "Tagamet HB200 (TN)",
      "SK&F-92334",
      "Cimetidine (JP15/USP/INN)",
      "Cimetidine [USAN:INN:BAN:JAN]",
      "Tagamet, SKF-92334, Tratul, Tametin, Dyspamet, Acinil, Cimetidine",
      "N-Cyano-N'-Methyl-[2-[[[5-Methyl-1H-Imidazol-4-Yl]methyl]thio]ethyl]guanidine",
      "N''-Cyano-N-Methyl-N'-[2-[(5-Methyl-1H-Imidazol-4-Yl)Methylthio]ethyl]guanidine",
      "N''-Cyano-N-Methyl-N'-(2-{[(5-Methyl-1H-Imidazol-4-Yl)Methyl]thio}ethyl)Guanidine",
      "N-Cyano-N'-Methyl-N''-(2-([(5-Methyl-1H-Imidazol-4-Yl)Methyl]sulfanyl)Ethyl)Guanidine",
      "N-Cyano-N'-Methyl-N''-(2-{[(5-Methyl-1H-Imidazol-4-Yl)Methyl]thio}ethyl)Guanidine",
      "N-Cyano-N'-Methyl-N''-(2-(((5-Methyl-1H-Imidazol-4-YL)Methyl)Thio)Ethyl) Guanidine",
      "N-Cyano-N'-Methyl-N''-(2-(((5-Methyl-1 H-Imidazol-4-Yl) Methyl)Thio)Ethyl)Guanidine",
      "N-Cyano-N'-Methyl-N''-(2-(((5-Methyl-1H-Imidazol-4-Yl)Methyl)Thio)Ethyl)Guanidine",
      "N''-Cyano-N-Methyl-N'-(2-(((5-Methyl-1H-Imidazol-4-Yl)Methyl)Thio)-Ethyl)Guanidine",
      "1-Cyano-2-Methyl-3-(2-(((5-Methyl-4-Imidazolyl)Methyl)Thio)Ethyl)Guanidine",
      "1-Cyano-2-Methyl-3-[2-[[(5-Methylimidazol-4-Yl)Methyl]thio]ethyl]guanidine",
      "1-Cyano-2-Methyl-3-[2-[(5-Methyl-1H-Imidazol-4-Yl)Methylsulfanyl]ethyl]guanidine",
      "2-Cyano-1-Methyl-3-(2-(((5-Methylimidazol-4-Yl)Methyl)Thio)Ethyl)Guanidine",
      "2-Cyano-1-Methyl-3-[2-(5-Methyl-1H-Imidazol-4-Yl-Methylthio)Ethyl]guanidine",
      "2-Cyano-1-Methyl-3-(2-{[(5-Methyl-1H-Imidazol-4-Yl)Methyl]sulfanyl}ethyl)Guanidine",
      "2-Cyano-1-Methyl-3-(2-{[(5-Methyl-1H-Imidazol-4-Yl)Methyl]thio}ethyl)Guanidine",
    ],
  },
  {
    primary_id: "D02DPK",
    names: ["Hyp 9", "2,4-(1-Keto-Hexyl) Phloroglucinol"],
  },
  {
    primary_id: "D02DPM",
    names: [
      "[3H]dimethyl-W84",
      "Dimethyl-W84",
      "6-[dimethyl-[3-(4-Methyl-1,3-Dioxoisoindol-2-Yl)Propyl]azaniumyl]hexyl-Dimethyl-[3-(4-Methyl-1,3-Dioxoisoindol-2-Yl)Propyl]azanium",
      "Dimethyl-W-84",
      "GTPL364",
    ],
  },
  {
    primary_id: "D02DPP",
    names: ["MTHF"],
  },
  {
    primary_id: "D02DPU",
    names: [
      "Aniracetam",
      "72432-10-1",
      "Draganon",
      "Sarpul",
      "1-(4-Methoxybenzoyl)-2-Pyrrolidinone",
      "Ampamet",
      "1-(4-Methoxybenzoyl)Pyrrolidin-2-One",
      "1-P-Anisoyl-2-Pyrrolidinone",
      "Ro 13-5057",
      "Aniracetamun [INN-Latin]",
      "Aniracetam [USAN:INN:JAN]",
      "UNII-5L16LKN964",
      "Ro 135057",
      "BRN 4807205",
      "Ro-13-5057",
      "1-P-Anisoylpyrrolidin-2-One",
      "2-Pyrrolidinone, 1-(4-Methoxybenzoyl)-",
      "CHEMBL36994",
      "MLS000079240",
      "CHEBI:47943",
      "1-(P-Methoxybenzoyl)-2-Pyrrolidinon [German]",
      "ZXNRTKGTQJPIJK-UHFFFAOYSA-N",
      "5L16LKN964",
      "NCGC00015116-10",
      "SMR000038438",
    ],
  },
  {
    primary_id: "D02DQQ",
    names: ["N,N-Dimethylarginine"],
  },
  {
    primary_id: "D02DQT",
    names: ["PMID11934595C2"],
  },
  {
    primary_id: "D02DRL",
    names: [
      "S-(2-Chlorobenzyl)Isothiourea Hydrochloride",
      "3778-85-6",
      "NSC 60041",
      "2-(2-Chlorobenzyl)-2-Thiopseudourea Hydrochloride",
      "Pseudourea, 2-(2-Chlorobenzyl)-2-Thio-, Hydrochloride",
      "2-Chlorobenzyl Carbamimidothioate Hydrochloride",
      "14122-38-4",
      "2-Chlorobenzyl Imidothiocarbamate Hydrochloride",
      "CHEMBL1224310",
      "2-Chlorobenzyl Imidothiocarbamate",
      "2-Chlorophenylmethyl Carbamimidothioate Hydrochloride",
      "AC1L56TG",
      "MLS000698043",
      "WLN: MUYZS1R BG &amp",
      "DTXSID00191225",
      "MolPort-001-529-519",
      "S-(2-Chlorobenzyl)Isothiourea HCl",
      "NSC60041",
      "NSC-60041",
      "Carbamimidothioic Acid, (2-Chlorophenyl)Methy",
    ],
  },
  {
    primary_id: "D02DSD",
    names: ["Sodium Chromate Cr-51", "Chromitope Sodium", "Sodium Chromate Cr 51"],
  },
  {
    primary_id: "D02DSJ",
    names: ["Cyclosporin A Analogue"],
  },
  {
    primary_id: "D02DSZ",
    names: ["Temocapril Hydrochloride", "Acecol (TN)"],
  },
  {
    primary_id: "D02DTG",
    names: ["DNA Vaccine (Hepatitis B Infection)"],
  },
  {
    primary_id: "D02DTQ",
    names: ["PF-3893787"],
  },
  {
    primary_id: "D02DTR",
    names: ["3-[3-(4-Imidazol-1-Yl-Phenoxy)-Propyl]-Pyridine", "CHEMBL262968", "SCHEMBL14130000", "BDBM50138237"],
  },
  {
    primary_id: "D02DUJ",
    names: ["GENESERINE"],
  },
  {
    primary_id: "D02DUM",
    names: ["ISIS 188780"],
  },
  {
    primary_id: "D02DUO",
    names: ["KYS-05001"],
  },
  {
    primary_id: "D02DVB",
    names: ["AGN-191743"],
  },
  {
    primary_id: "D02DVK",
    names: [
      "CV-9201",
      "CV-9201-1",
      "CV-9201-2",
      "CV-9201-3",
      "CV-9201-4",
      "CV-9201-5",
      "RNA-Based Vaccine (NY-ESO-1, MAGE-C1/CT7, MAGE-C2/CT10, NSCLC), CureVac",
    ],
  },
  {
    primary_id: "D02DVT",
    names: ["4-[1,2-Bis(4-Hydroxyphenyl)Pent-1-Enyl]phenol", "CHEMBL38266"],
  },
  {
    primary_id: "D02DWE",
    names: [
      "3,6,9,12,15-Pentaoxaheptadecane",
      "Diethoxytetraethylene Glycol",
      "Tetraethylene Glycol Diethyl Ether",
      "4353-28-0",
      "Glycol, Diethoxytetraethylene",
      "Ether, Bis(2-(2-Ethoxyethoxy)Ethyl)",
      "Diethylether Tetraethylenglykolu",
      "EINECS 224-423-7",
      "Diethylether Tetraethylenglykolu [Czech]",
      "BRN 1767873",
      "Bis(2-(2-Ethoxyethoxy)Ethyl) Ether",
      "P3G",
      "1-Ethoxy-2-[2-[2-(2-Ethoxyethoxy)Ethoxy]ethoxy]ethane",
      "AC1Q58NT",
      "AC1L2UE6",
      "SCHEMBL216926",
      "Tetraethyleneglycol Diethyl Ether",
      "DTXSID0052099",
      "CTK3I9254",
      "CHEBI:44751",
      "HYDWALOBQJFOMS-UHFFFAOYSA-N",
      "ZINC5650744",
    ],
  },
  {
    primary_id: "D02DWK",
    names: [
      "2-Hydroxyoleic Acid",
      "2R-Hydroxy-Oleic Acid",
      "2R-Hydroxy-9Z-Octadecenoic Acid",
      "R-Hydroxy-Olsaure",
      "(R)-2-Hydroxyoleic Acid",
      "SCHEMBL378127",
      "ZINC14210872",
      "LMFA02000056",
      "UNII-OSV3KVO1BT Component JBSOOFITVPOOSY-DOOKAGJSSA-N",
    ],
  },
  {
    primary_id: "D02DWM",
    names: [
      "Amphotericin B",
      "ABLC",
      "Abelcet",
      "Abelecet",
      "Ambisome",
      "Amphocin",
      "Amphomoronal",
      "Amphotec",
      "Amphotericin",
      "Amphotericinum",
      "Amphotherizin",
      "Amphozone",
      "Fungilin",
      "Fungisome",
      "Fungisone",
      "Fungizone",
      "Halizon",
      "SinuNase",
      "Amfotericina B",
      "Amophotericin B",
      "Amphortericin B",
      "Amphotericin B Cholesterol Dispersion",
      "Amphotericin B Colloidal Dispersion",
      "Amphotericin B Liposomal",
      "Amphotericin BETA",
      "Amphotericine B",
      "Amphotericinum B",
      "Amphotherizin [German]",
      "Liposomal Amphotericin B",
      "NCI527017",
      "NS 718",
      "AMPH-B",
      "Abelcet (TN)",
      "AmBisome (TN)",
      "Amfotericina B [INN-Spanish]",
      "Ampho-Moronal",
      "Amphocil (TN)",
      "Amphotec (TN)",
      "Amphotericin-B",
      "Amphotericine B [INN-French]",
      "Amphotericinum B [INN-Latin]",
      "Fungilin (TN)",
      "Fungisome (TN)",
      "Fungizone (TN)",
      "LNS-AmB",
      "Mysteclin-F",
      "NKTR-024",
      "Amphotericin B [USAN:INN:JAN]",
      "Amphotericin B, Lipid-Based",
      "Amphotericin B, Streptomyces Sp.",
      "Amphotericin B (JP15/USP/INN)",
    ],
  },
  {
    primary_id: "D02DWN",
    names: ["AB-0440a"],
  },
  {
    primary_id: "D02DWX",
    names: ["Prostvac-VF"],
  },
  {
    primary_id: "D02DXA",
    names: ["Benzamide Derivative 18"],
  },
  {
    primary_id: "D02DXC",
    names: ["SPN-803"],
  },
  {
    primary_id: "D02DXI",
    names: ["ISIS 19650"],
  },
  {
    primary_id: "D02DXP",
    names: [
      "Alpha-Aminobutyric Acid",
      "1492-24-6",
      "L-2-Aminobutyric Acid",
      "H-Abu-Oh",
      "(S)-2-Aminobutanoic Acid",
      "(2S)-2-Aminobutanoic Acid",
      "(S)-(+)-2-Aminobutyric Acid",
      "L-Alpha-Aminobutyric Acid",
      "L-(+)-2-Aminobutyric Acid",
      "(S)-2-Aminobutyric Acid",
      "(-)-2-Aminobutyric Acid",
      "(S)-2-Amino-Butyric Acid",
      "L-Butyrine",
      "L-Alpha-Amino-N-Butyric Acid",
      "H-2-Abu-Oh",
      "L-Alpha-Aminobutyrate",
      "Butanoic Acid, 2-Amino-, (2S)-",
      "(S)-2-Aminobutanoate",
      "2S-Amino-Butanoic Acid",
      "UNII-0QAJ5KN9IM",
      "S-Butyrine",
      "ALPHA-AMINOBUTYRIC ACID",
      "(2S)-2-Aminobutyric Acid",
      "L-2-Aminobuttersaeure",
      "L(+)-2-Aminobutyric A",
    ],
  },
  {
    primary_id: "D02DXV",
    names: ["HAP Aniline Mustard"],
  },
  {
    primary_id: "D02DXY",
    names: ["ISIS 12659"],
  },
  {
    primary_id: "D02DYB",
    names: ["TRIPTOCALLINE A"],
  },
  {
    primary_id: "D02DYG",
    names: [
      "4-Amino-N-(4-Chlorophenyl)Benzenesulfonamide",
      "16803-92-2",
      "4-Amino-N-(4-Chloro-Phenyl)-Benzenesulfonamide",
      "CHEMBL227413",
      "Benzenesulfonamide, 4-Amino-N-(4-Chlorophenyl)-",
      "4-Amino-N-(4-Chlorophenyl)Benzene-1-Sulfonamide",
      "Benzenesulfonamide,4-Amino-N-(4-Chlorophenyl)-",
      "[(4-Aminophenyl)Sulfonyl](4-Chlorophenyl)Amine",
      "BAS 02102352",
      "AC1Q3REH",
      "AC1LG33E",
      "ARONIS021171",
      "AC1Q51X9",
      "SCHEMBL14093755",
      "KS-00003SYR",
      "CTK4D2850",
      "DTXSID90354963",
      "MolPort-000-164-113",
      "ZINC286765",
      "ALBB-000053",
      "BDBM50197705",
      "5394AD",
      "BBL037004",
      "SBB009347",
      "STK091555",
    ],
  },
  {
    primary_id: "D02DYU",
    names: ["SC-75416"],
  },
  {
    primary_id: "D02DYZ",
    names: ["N-(Pyridin-4-Ylmethyl) Estradiol-16-Carboxamide"],
  },
  {
    primary_id: "D02DZI",
    names: ["MGN-8107"],
  },
  {
    primary_id: "D02DZK",
    names: ["LY3303560"],
  },
  {
    primary_id: "D02DZN",
    names: ["Ruthenium Red"],
  },
  {
    primary_id: "D02EAO",
    names: ["N*6*-Cyclohexyl-N*2*-Phenyl-9H-Purine-2,6-Diamine", "CHEMBL188592"],
  },
  {
    primary_id: "D02EAP",
    names: ["3-(5-[1,3]dithiolan-2-Yl-Furan-2-Yl)Pyridine"],
  },
  {
    primary_id: "D02EAS",
    names: ["TBC 11251 (TBC)"],
  },
  {
    primary_id: "D02EBQ",
    names: ["3-Pyridine-Acetic Acid"],
  },
  {
    primary_id: "D02ECA",
    names: ["PF-00356231"],
  },
  {
    primary_id: "D02ECG",
    names: ["Sodium Pyruvate"],
  },
  {
    primary_id: "D02EEJ",
    names: ["SCY-635"],
  },
  {
    primary_id: "D02EEK",
    names: ["J 591 Lu-177"],
  },
  {
    primary_id: "D02EFJ",
    names: ["MDAB-16"],
  },
  {
    primary_id: "D02EFK",
    names: ["N1,2-Dibenzyl-N3-Hydroxy-N1-Phenethylmalonamide", "CHEMBL426572"],
  },
  {
    primary_id: "D02EFQ",
    names: [
      "PD-169469",
      "CHEMBL42420",
      "SCHEMBL7404078",
      "BDBM12074",
      "Alpha-Arylsulfonylamino Carboxylate 2R",
      "(2R)-2-{[4-(4-Bromophenyl)Benzene]sulfonamido}-3-Methylbutanoic Acid",
    ],
  },
  {
    primary_id: "D02EFX",
    names: ["AZ11645373", "AZ 11645373", "AZ-11645373"],
  },
  {
    primary_id: "D02EGY",
    names: ["Aromatic Hydrazine Carboxyimidoamide Derivative 6"],
  },
  {
    primary_id: "D02EHE",
    names: ["BI730357"],
  },
  {
    primary_id: "D02EHL",
    names: [
      "1-Benzo[b]thiophen-2-Yl-Cyclopentylamine",
      "CHEMBL18502",
      "NCI60_038335",
      "AC1L9GGU",
      "CTK7D5118",
      "ZINC1657608",
      "BDBM50040195",
      "1-(Benzothiophen-2-Yl)Cyclopentan-1-Amine",
      "1-(1-Benzothiophen-2-Yl)Cyclopentan-1-Amine",
    ],
  },
  {
    primary_id: "D02EHQ",
    names: ["Deoxycortisone", "21-Deoxycortisone"],
  },
  {
    primary_id: "D02EHR",
    names: ["PMID26293650-Compound-35"],
  },
  {
    primary_id: "D02EHV",
    names: ["Human Neutral Insulin"],
  },
  {
    primary_id: "D02EHY",
    names: ["2MD"],
  },
  {
    primary_id: "D02EHZ",
    names: [
      "5-Chloro-N-(Quinolin-8-Yl)Thiophene-2-Sulfonamide",
      "CHEMBL257446",
      "620103-77-7",
      "AC1NGSHS",
      "SCHEMBL13633877",
      "CTK1I9417",
      "DTXSID80406061",
      "MolPort-003-263-222",
      "ZINC6858682",
      "BDBM50372504",
      "AKOS001150619",
      "MCULE-4753456414",
      "NCGC00163030-02",
      "NCGC00163030-01",
      "5-Chloro-N-Quinolin-8-Ylthiophene-2-Sulfonamide",
      "2-Thiophenesulfonamide, 5-Chloro-N-8-Quinolinyl-",
      "CU-00000000281-1",
    ],
  },
  {
    primary_id: "D02EIB",
    names: ["4-(Spiro[chromene-2,4'-Piperidine]-4-Yl)Phenol", "CHEMBL495074", "SCHEMBL471335", "BDBM50252834"],
  },
  {
    primary_id: "D02EIC",
    names: ["ISIS 116648"],
  },
  {
    primary_id: "D02EIK",
    names: [
      "Para-Mercury-Benzenesulfonic Acid",
      "(4-Sulfophenyl)Mercury",
      "4-Mercuriphenylsulfonate",
      "PARA-MERCURY-BENZENESULFONIC ACID",
      "P-Mercuribenzenesulfonic Acid",
      "17014-79-8",
      "PMBS",
      "P-Mercuriphenylsulfonate",
      "Para-Mercuriphenylsulfonate",
      "Mercury, Bis(4-Sulfophenyl)-",
      "SCHEMBL3980997",
      "AC1L22X1",
      "CHEBI:49823",
      "DTXSID30168809",
    ],
  },
  {
    primary_id: "D02EJM",
    names: ["2-(4-Hydroxy-Phenyl)-7-Propenyl-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D02EKO",
    names: ["PMID25666693-Compound-126"],
  },
  {
    primary_id: "D02EKR",
    names: [
      "2-(N-Isopropylamino)-3'-Chloropropiophenone",
      "CHEMBL565397",
      "130839-36-0",
      "AC1MHRAR",
      "SCHEMBL770155",
      "1-(3-Chlorophenyl)-2-(Propan-2-Ylamino)Propan-1-One",
      "BDBM50302920",
    ],
  },
  {
    primary_id: "D02ELA",
    names: ["L-NIO"],
  },
  {
    primary_id: "D02ELG",
    names: ["4-Formylphenyl-O-Beta-Dglucopyranoside"],
  },
  {
    primary_id: "D02ELN",
    names: ["HM-5016699", "Dual PI3K/MTOR Inhibitor (Cancer)", "Dual PI3K/MTOR Inhibitor (Cancer), Hutchison"],
  },
  {
    primary_id: "D02ELS",
    names: ["L-Alanosine"],
  },
  {
    primary_id: "D02EME",
    names: ["Tetra-Hydro-Isoquinoline Derivative 3"],
  },
  {
    primary_id: "D02EMH",
    names: ["All-Trans-4-Oxo-Retinoic Acid", "4-Oxoretinoic Acid", "Ro 12-4824"],
  },
  {
    primary_id: "D02EMR",
    names: [
      "Cilomilast",
      "Ariflo",
      "CIO",
      "SB 207499",
      "SB207499",
      "Ariflo (TN)",
      "Cilomilast [USAN:INN]",
      "SB-207499",
      "Ariflo, SB-207499,Cilomilast",
      "Cilomilast (JAN/USAN/INN)",
      "CIS-4-CYANO-4-[3-(CYCLOPENTYLOXY)-4-METHOXYPHENYL]CYCLOHEXANECARBOXYLIC ACID",
      "Cis-4-Cyano-4-(3-Cyclopentyloxy-4-Methoxyphenyl)Cyclohexanecarboxylic Acid",
      "Cis-4-Cyano-4-(3-(Cyclopentyloxy)-4-Methoxyphenyl)Cyclohexanecarboxylic Acid",
      "Cis-4-(3-(Cyclopentyloxy)-4-Methoxyphenyl)-4-Cyanocyclohexane-1-Carboxylic Acid",
      "4-Cyano-4-(3-Cyclopentoxy-4-Methoxy-Phenyl)-Cyclohexane-1-Carboxylic Acid",
      "4-Cyano-4-(3-Cyclopentyloxy-4-Methoxyphenyl)Cyclohexane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D02ENG",
    names: [
      "BVT.2733",
      "CHEMBL553071",
      "AC1OCFOV",
      "376641-65-5",
      "SCHEMBL5692696",
      "MolPort-042-665-756",
      "3-Chloro-2-Methyl-N-[4-[2-(4-Methylpiperazin-1-Yl)-2-Oxoethyl]-1,3-Thiazol-2-Yl]benzenesulfonamide Hydrochloride",
    ],
  },
  {
    primary_id: "D02ENH",
    names: [
      "(R)-Norfluoxetine",
      "130194-43-3",
      "UNII-05UQ1299DN",
      "(R)-3-Phenyl-3-(4-Trifluoromethyl-Phenoxy)-Propylamine",
      "CHEMBL446084",
      "05UQ1299DN",
      "(R)-3-(4-Trifluoromethylphenoxy)-3-Phenylpropylamine",
      "Norfluoxetine, (R)-",
      "(3r)-3-Phenyl-3-[4-(Trifluoromethyl)Phenoxy]propan-1-Amine",
      "R-Norfluoxetine",
      "SCHEMBL3750512",
      "CTK8E9557",
      "MolPort-028-616-048",
      "ZINC1645453",
      "Benzenepropanamine, Gamma-(4-(Trifluoromethyl)Phenoxy)-, (GammaR)-",
      "BDBM50254791",
      "9049AH",
      "AKOS027384575",
      "NE64378",
      "AJ-28674",
      "RT-014016",
      "FT-0673087",
      "J-005760",
    ],
  },
  {
    primary_id: "D02ENI",
    names: [
      "(Z)-1-(Benzo[d]oxazol-2-Yl)Octadec-9-En-1-One",
      "CHEMBL285678",
      "SCHEMBL3964233",
      "BDBM50100871",
      "LS-97768",
      "1-Benzooxazol-2-Yl-Nonadec-10-En-2-One",
      "(Z)-1-Benzooxazol-2-Yl-Octadec-9-En-1-One",
      "(9Z)-1-(Benzoxazole-2-Yl)-9-Octadecene-1-One",
    ],
  },
  {
    primary_id: "D02EOF",
    names: [
      "4-Ethyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL360616",
      "CHEMBL543886",
      "4-Ethylpyrrolidine-2-Imine",
      "SCHEMBL7593608",
      "BDBM50066783",
      "AKOS006353400",
      "4-Ethyl-Pyrrolidin-(2E)-Ylideneamine",
    ],
  },
  {
    primary_id: "D02EOH",
    names: ["SGT-53"],
  },
  {
    primary_id: "D02EOR",
    names: ["ABBV-399"],
  },
  {
    primary_id: "D02EOS",
    names: ["CVX-241"],
  },
  {
    primary_id: "D02EPD",
    names: ["DiBA-(5)-C4", "Bis-(1,3-Dibutylbarbituric Acid)Pentamethine Oxanol", "Oxalon Dye"],
  },
  {
    primary_id: "D02EPS",
    names: ["Oralair Grasses"],
  },
  {
    primary_id: "D02EPZ",
    names: [
      "2-(2,6-Dimethyl-Benzyl)-4,5-Dihydro-1H-Imidazole",
      "CHEMBL71033",
      "SCHEMBL953521",
      "1H-Imidazole, 2-[(2,6-Dimethylphenyl)Methyl]-4,5-Dihydro-",
      "BDBM50064817",
      "2-(2,6-Dimethylbenzyl)-2-Imidazoline",
    ],
  },
  {
    primary_id: "D02EQL",
    names: ["PMID2231594C3u"],
  },
  {
    primary_id: "D02ERH",
    names: [
      "EP-2060",
      "Apoptosis Inducers, EpiCept",
      "MX-11697",
      "Anticancer Agents, Celera/EpiCept",
      "Apoptosis Inducers, Celera/EpiCept",
      "Apoptosis Inducers, Celera/Maxim",
      "Anti-Cancer Agents, Celera/Maxim",
    ],
  },
  {
    primary_id: "D02ESK",
    names: ["GSK-732461"],
  },
  {
    primary_id: "D02ESL",
    names: ["ON-01210.Na"],
  },
  {
    primary_id: "D02ESW",
    names: ["MK-212", "64022-27-1"],
  },
  {
    primary_id: "D02ESX",
    names: ["Neomedi"],
  },
  {
    primary_id: "D02ETA",
    names: ["AMN082", "AMN-082", "AMN 082"],
  },
  {
    primary_id: "D02ETJ",
    names: ["SIL-43"],
  },
  {
    primary_id: "D02ETP",
    names: ["SOM-0420"],
  },
  {
    primary_id: "D02ETU",
    names: ["PMID28270021-Compound-WO2014152663 701"],
  },
  {
    primary_id: "D02ETY",
    names: [
      "SAR97276",
      "Albitiazolium Bromide",
      "UNII-3AC0AJ4ILP",
      "3AC0AJ4ILP",
      "321915-72-4",
      "SAR 97276",
      "Albitiazolium Bromide [INN]",
      "SCHEMBL2017697",
      "CHEMBL520613",
      "DTXSID60185968",
      "3,3'-(Dodecan-1,12-Diyl)Bis(5-(2-Hydroxyethyl)-4-Methyl-1,3-Thiazol-3-Ium) Dibromide",
      "AN-28886",
      "2-[3-[12-[5-(2-Hydroxyethyl)-4-Methyl-Thiazol-3-Ium-3-Yl]dodecyl]-4-Methyl-Thiazol-3-Ium-5-Yl]ethanol Dibromide",
    ],
  },
  {
    primary_id: "D02EVF",
    names: ["Losartan/Thioctic Acid"],
  },
  {
    primary_id: "D02EVI",
    names: ["Controlled-Release Agent", "Controlled-Release Agent (Oral/Micropump, CNS Disorders)"],
  },
  {
    primary_id: "D02EVL",
    names: ["TDT-077"],
  },
  {
    primary_id: "D02EVT",
    names: ["SAR-152954"],
  },
  {
    primary_id: "D02EWF",
    names: ["RiVax"],
  },
  {
    primary_id: "D02EWM",
    names: ["(R)-Sirtinol"],
  },
  {
    primary_id: "D02EWR",
    names: ["L-779,976"],
  },
  {
    primary_id: "D02EXB",
    names: ["Vero Cell Seasonal Influenza Vaccine"],
  },
  {
    primary_id: "D02EXX",
    names: ["GNF-PF-5618"],
  },
  {
    primary_id: "D02EXZ",
    names: ["ST10-021"],
  },
  {
    primary_id: "D02EYG",
    names: ["Linagliptin"],
  },
  {
    primary_id: "D02EYZ",
    names: ["GSK2654909A"],
  },
  {
    primary_id: "D02EZF",
    names: ["SMP-797", "Compound 22b [PMID: 16242323]"],
  },
  {
    primary_id: "D02EZM",
    names: ["Fospropofol Disodium"],
  },
  {
    primary_id: "D02FAA",
    names: ["PMID27336223-Compound-4"],
  },
  {
    primary_id: "D02FAI",
    names: ["Pbi-ShPDX-1 LP"],
  },
  {
    primary_id: "D02FBG",
    names: ["UC-2029"],
  },
  {
    primary_id: "D02FCQ",
    names: ["Protokylol Hydrochloride"],
  },
  {
    primary_id: "D02FCR",
    names: ["2-Oxothiazolidine-4-Carboxylate", "GTPL4521"],
  },
  {
    primary_id: "D02FCS",
    names: ["15-Nor-18-Methoxycornaridine", "CHEMBL607096"],
  },
  {
    primary_id: "D02FCU",
    names: ["MIV-210"],
  },
  {
    primary_id: "D02FCY",
    names: ["NAAG"],
  },
  {
    primary_id: "D02FDJ",
    names: ["Ioforminol", "AN-113111", "GE-145"],
  },
  {
    primary_id: "D02FDZ",
    names: [
      "LCP-AtorFen",
      "AtorFen",
      "Atorvastatin +fenofibrate, Veloxis",
      "Atorvastatin/Fenofibrate, Veloxis",
      "Fenofibrate +atorvastatin, Veloxis",
    ],
  },
  {
    primary_id: "D02FEA",
    names: ["FENDILINE"],
  },
  {
    primary_id: "D02FEF",
    names: [
      "4-(3-Methoxy-Benzylideneamino)-Benzenesulfonamide",
      "CHEMBL271930",
      "NSC720589",
      "AC1L8N6O",
      "CTK7A8689",
      "BDBM50371745",
      "NSC-720589",
      "4-[(3-Methoxy-Benzylidene)-Amino]-Benzenesulfonamide",
      "4-[(3-Methoxyphenyl)Methylideneamino]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D02FEJ",
    names: ["ODC-0501", "Dendritic Cell Vaccine (Melanoma), ODC", "Vaccine (Melanoma), ODC"],
  },
  {
    primary_id: "D02FEM",
    names: [
      "Natamycin",
      "Delvocid",
      "Delvolan",
      "Delvopos",
      "Mycophyt",
      "Myprozine",
      "Natacyn",
      "Natafucin",
      "Natamicina",
      "Natamycine",
      "Natamycinum",
      "Pimafucin",
      "Pimaricin",
      "Pimaricine",
      "Pimarizin",
      "Synogil",
      "Tennecetin",
      "Natamycin Preparation",
      "Pimaricin Preparation",
      "Pimarizin [German]",
      "CL 12625",
      "Antibiotic A-5283",
      "CL 12,625",
      "CL-12625",
      "Natacyn (TN)",
      "Natamicina [INN-Spanish]",
      "Natamycine [INN-French]",
      "Natamycinum [INN-Latin]",
      "Pimaricin (JP15)",
      "Natamycin (USP/INN)",
      "Natamycin [USAN:INN:BAN]",
      "Stereoisomer Of 22-((3-Amino-3,6-Dideoxy-Beta-D-Mannopyranosyl)Oxy)-1,3,26-Trihydroxy-12-Methyl-10-Oxo-6,11,28-Trioxatricyclo(22.3.1.0(Sup 5,7))Octacosa-8,14,16,18,20-Pentaene-25-Carboxylic Acid",
      "(1R,3S,5R,7R,8E,12R,14E,16E,18E,20E,22R,24S,25R,26S)-22-[(3-Amino-3,6-Dideoxy-Beta-D-Mannopyranosyl)Oxy]-1,3,26-Trihydroxy-12-Methyl-10-Oxo-6,11,28-Trioxatricyclo[22.3.1.0~5,7~]octacosa-8,14,16,18,20-Pentaene-25-Carboxylic Acid",
      "16-(3-Amino-3,6-Didesoxy-Beta-D-Mannopyranosyloxy)-5,6-Epoxy-8,12,14-Trihydroxy-26-Methyl-2,10-Dioxo-1-Oxacyclohexacosa-3,17,19,21,23-Pentaen-13-Carbonsaeure",
      "6,11,28-Trioxatricyclo[22.3.1.05,7]octacosa-8,14,16,18,20-Pentaene-25-Carboxylic Acid, 22-[(3-Amino-3,6-Dideoxy-.beta.-D-Mannopyranosyl)Oxy]-1,3,26-Trihydroxy-12-Methyl-10-Oxo-, (1R,3S,5R,7R,8E,12R,14",
    ],
  },
  {
    primary_id: "D02FEV",
    names: ["S-8921"],
  },
  {
    primary_id: "D02FGF",
    names: ["PO3 2-Nle-Trp-O-3K", "CHEMBL22509"],
  },
  {
    primary_id: "D02FGG",
    names: ["AFM-21", "Anti-EGFR Antibody (RECRUIT-TandAb, Colon/Solid Tumors), Affimed"],
  },
  {
    primary_id: "D02FGK",
    names: ["PMID21571530C5g"],
  },
  {
    primary_id: "D02FGY",
    names: ["2-(N-(2-Ffuorophenyl)Pyrrol-3-Yl) Acetic Acid", "CHEMBL398087"],
  },
  {
    primary_id: "D02FHP",
    names: ["RX-01-423"],
  },
  {
    primary_id: "D02FHX",
    names: [
      "4-Ethyl-Oxazolidin-(2Z)-Ylideneamine",
      "CHEMBL108788",
      "4-Ethyloxazolidine-2-Imine",
      "SCHEMBL10211233",
      "BDBM50138188",
      "AKOS013533141",
    ],
  },
  {
    primary_id: "D02FIC",
    names: ["JB-004/A"],
  },
  {
    primary_id: "D02FID",
    names: ["ALN-TTR02"],
  },
  {
    primary_id: "D02FIJ",
    names: ["FK-224"],
  },
  {
    primary_id: "D02FJX",
    names: ["8-[(3-Trifluoromethyl)Benzyloxy]caffeine", "CHEMBL602466"],
  },
  {
    primary_id: "D02FKS",
    names: ["Selective Estrogen Receptor Degraders"],
  },
  {
    primary_id: "D02FLB",
    names: [
      "Sodium Bicarbonate",
      "Acidosan",
      "Colovage",
      "Glycoprep",
      "Golytely",
      "Jusonin",
      "Meylon",
      "Natriumhydrogenkarbonat",
      "Natron",
      "Neut",
      "Nulytely",
      "Soludal",
      "BSS Plus",
      "Baking Soda",
      "Bicarbonate Of Soda",
      "Carbonic Acid Monosodium Salt",
      "Gripe Water",
      "Lithium Bicarbonate",
      "Monosodium Hydrogen Carbonate",
      "Natrii Hydrogencarbonas",
      "Natrium Bicarbonicum",
      "Natrium Hydrogencarbonicum",
      "Natrum Bicarbonicum",
      "Sandoz Sodium Bicarbonate",
      "Sel De Vichy",
      "Soda Mint",
      "Sodium Acid Carbonate",
      "Sodium Bicarbonate In Plastic Container",
      "Sodium Bicarbonate Liquid Concentrate",
      "Sodium Bicarbonate Solution",
      "Sodium Hydrocarbonate",
      "Sodium Hydrogen Carbonate",
      "Sodium Hydrogencarbonate",
      "NaHCO3",
      "Bicarbonate, Sodium",
      "Carbonic Acid, Monosodium Salt",
      "Co-Lav",
      "Col-Evac",
      "Go-Evac",
      "Hema BP-38",
      "Hydrogen Carbonate, Sodium",
      "Meylon (TN)",
      "Neut (TN)",
      "Peg-Lyte",
      "Soda (Van)",
      "Soda, Baking",
      "Sodium Bicarbonate [USAN:JAN]",
      "Carbonic Acid Sodium Salt (1:1)",
      "E-Z-EM Prep Lyte",
      "Sodium Bicarbonate (1:1)",
      "Sodium Bicarbonate (JP15/USP)",
      "Sodium Carbonate (Na(HCO3))",
      "800 Sodium Bicarbonate Powder",
    ],
  },
  {
    primary_id: "D02FLH",
    names: ["2,6-Dphenyl-8-Propyl-1-Deazapurine", "CHEMBL376648"],
  },
  {
    primary_id: "D02FMN",
    names: ["RXP-407"],
  },
  {
    primary_id: "D02FMR",
    names: [
      "6-Bromo-N-(3-Chlorophenyl)Quinazolin-4-Amine",
      "CHEMBL1079374",
      "AC1LEZQW",
      "C14H9BrClN3",
      "ChemDiv1_028574",
      "Oprea1_590274",
      "Neuro1_000364",
      "MLS000572427",
      "Cid_720635",
      "SCHEMBL4783154",
      "HMS668C18",
      "MolPort-000-660-268",
      "ZINC122236",
      "HMS2493B23",
      "BDBM50311887",
      "STK965864",
      "AKOS002348868",
      "MCULE-5418328745",
      "SMR000194385",
      "EU-0077259",
      "ST50477345",
      "6-Bromo-N-(3-Chlorophenyl)-4-Quinazolinamine",
      "(6-Bromoquinazolin-4-Yl)(3-Chlorophenyl)Amine",
      "SR-01000485096",
      "SR-01000485096-1",
    ],
  },
  {
    primary_id: "D02FNJ",
    names: ["AL-309", "D-SAL"],
  },
  {
    primary_id: "D02FNU",
    names: ["Ipsapirone"],
  },
  {
    primary_id: "D02FPT",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D02FPU",
    names: ["N-(1-Oxobutan-2-Yl)-3-(Trifluoromethyl)Benzamide", "CHEMBL1288588"],
  },
  {
    primary_id: "D02FQG",
    names: [
      "Pyrrolidine MCHR1 Antagonist 1",
      "CHEMBL1760248",
      "GTPL7756",
      "BDBM50340202",
      "N-(3-Chlorophenyl)-2-((3R,4S)-1-(2-(4-Hydroxytetrahydro-2H-Pyran-4-Yl)Ethyl)-4-(4-Methoxyphenyl)Pyrrolidin-3-Yl)Acetamide",
    ],
  },
  {
    primary_id: "D02FRA",
    names: [
      "3-(2-Chloro-1,1-Dimethyl-2-Phenyl-Ethyl)-Pyridine",
      "CHEMBL55746",
      "87372-77-8",
      "3-(1-Chloro-2-Methyl-1-Phenylpropan-2-Yl)Pyridine",
      "CTK3C4530",
      "DTXSID30658230",
      "BDBM50028163",
      "Pyridine, 3-(2-Chloro-1,1-Dimethyl-2-Phenylethyl)-",
    ],
  },
  {
    primary_id: "D02FRR",
    names: ["PMID25399762-Compound-Table1-C18"],
  },
  {
    primary_id: "D02FTR",
    names: ["3,5-Dimethyl PPP", "3,5-DMPPP"],
  },
  {
    primary_id: "D02FTU",
    names: ["Recombinant Follicle Stimulating Hormone"],
  },
  {
    primary_id: "D02FTV",
    names: [
      "INGN-241",
      "AD-IL-24, Introgen",
      "AD-Mda-7, Introgen",
      "Adenovirus-IL-24, Introgen",
      "Adenovirus-Mda-7, Introgen",
    ],
  },
  {
    primary_id: "D02FUG",
    names: [
      "S-(-)CPP",
      "2-(4-CHLOROPHENOXY)PROPANOIC ACID",
      "3307-39-9",
      "2-(4-Chlorophenoxy)Propionic Acid",
      "4-CPP",
      "2-(P-Chlorophenoxy)Propionic Acid",
      "2-(4-Chlorophenoxy) Propionic Acid",
      "Propanoic Acid, 2-(4-Chlorophenoxy)-",
      "2-(4-CPP)",
      "C9H9ClO3",
      "CHEBI:34603",
      "DKHJWWRYTONYHB-UHFFFAOYSA-N",
      "EINECS 221-991-8",
      "NSC 70174",
      "Alpha-(4-Chlorophenoxy)Propionic Acid",
      "Propionic Acid, 2-(P-Chlorophenoxy)-",
      "AC1L2CMI",
      "C13701",
      "NCIOpen2_003229",
      "CHEMBL23629",
      "SCHEMBL117259",
      "AC1Q2C09",
      "GTPL4098",
      "ARONIS008729",
      "Jsp006087",
      "CTK4E4178",
      "MolPort-000-685-652",
      "NSC70174",
      "SP4232",
    ],
  },
  {
    primary_id: "D02FVN",
    names: ["ISIS 20585"],
  },
  {
    primary_id: "D02FVV",
    names: ["HELENAQUINONE"],
  },
  {
    primary_id: "D02FWA",
    names: ["Naphthalene Derivative 1"],
  },
  {
    primary_id: "D02FWO",
    names: ["Pyrido[1,2-A]indole-1.-Carboxylic Acid Analog 4"],
  },
  {
    primary_id: "D02FWR",
    names: ["PMID28460551-Compound-9"],
  },
  {
    primary_id: "D02FWT",
    names: ["PMID28447479-Compound-14"],
  },
  {
    primary_id: "D02FXK",
    names: ["Botulinum Toxin Type B", "BTXA (TN)", "Dysport (TN)", "Myobloc (TN)", "Neurobloc (TN)", "Xeomin (TN)"],
  },
  {
    primary_id: "D02FXS",
    names: [
      "3-Carboxy-6-Ethyl-4-Quinolone",
      "6-Ethyl-4-Hydroxyquinoline-3-Carboxylic Acid",
      "6-Ethyl-4-Hydroxy-Quinoline-3-Carboxylic Acid",
      "103802-41-1",
      "35128-00-8",
      "111185-85-4",
      "BAS 02168017",
      "CBMicro_031482",
      "AC1LF0Z6",
      "Cambridge Id 6145909",
      "Oprea1_555463",
      "Oprea1_768752",
      "SCHEMBL396402",
      "CHEMBL206122",
      "3-Quinolinecarboxylic Acid, 6-Ethyl-1,4-Dihydro-4-Oxo-",
      "SCHEMBL10032038",
      "CTK6D1895",
      "CTK8F7130",
      "DTXSID70352225",
      "MolPort-000-653-081",
      "MolPort-015-116-184",
      "ZINC3677328",
      "STL229952",
      "1251AE",
      "SBB015438",
      "AKOS000273237",
      "AKOS009582526",
      "CCG-108136",
      "AB11572",
      "MCULE-1628369792",
      "OR309458",
    ],
  },
  {
    primary_id: "D02FXU",
    names: ["Lapaquistat Acetate"],
  },
  {
    primary_id: "D02FXZ",
    names: ["DesGly-NH2,d(CH2)5[D-Tyr2,Thr4,Orn8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D02FYE",
    names: [
      "TT-223",
      "E1-INT",
      "GLP1-INT",
      "Islet Cell Neogenesis Therapy, Transition Therapeutics",
      "Islet Neogenesis Therapy, Transition",
      "Diabetes Therapy, Waratah/MGH",
      "EGF-INT, Transition Therapeutics",
      "Glucagon-Like Peptide + Gastrin Analog, Transition",
      "INT (Diabetes), Waratah/MGH",
      "Epidermal Growth Factor Analog + TT-223 (Diabetes), Transition",
      "Islet Neogenesis Therapy (GLP-1, Gastrin), Transition",
      "Glucagon-Like Peptide + TT-223, (Diabetes) Transition",
    ],
  },
  {
    primary_id: "D02FYI",
    names: ["TOzaride"],
  },
  {
    primary_id: "D02FYN",
    names: ["(E)-4-(2-(Furan-2-Yl)Vinyl)Benzenesulfonamide", "CHEMBL1288783"],
  },
  {
    primary_id: "D02FZD",
    names: [
      "(S)-Rolipram",
      "(S)-(+)-Rolipram",
      "85416-73-5",
      "S-(+)-Rolipram",
      "(S)-ROLIPRAM",
      "S- (+)-Rolipram",
      "UNII-H6G4VMQ24K",
      "H6G4VMQ24K",
      "CHEMBL325795",
      "CHEBI:59540",
      "(4S)-4-[3-(CYCLOPENTYLOXY)-4-METHOXYPHENYL]PYRROLIDIN-2-ONE",
      "(S)-4-(3-(Cyclopentyloxy)-4-Methoxyphenyl)Pyrrolidin-2-One",
      "(4S)-4-[3-(Cyclopentyloxy)-4-Methoxyphenyl]-Pyrrolidin-2-One",
      "S-Rolipram",
      "1oyn",
      "1xn0",
      "AC1L4KJV",
      "MLS006011228",
      "SCHEMBL5321568",
      "CTK8G3018",
      "DTXSID30234634",
      "HJORMJIFDVBMOB-GFCCVEGCSA-N",
    ],
  },
  {
    primary_id: "D02FZM",
    names: ["Lys[Z(NO2)]-Pro"],
  },
  {
    primary_id: "D02FZQ",
    names: [
      "2,5-Bis(4-Methoxyphenyl)Thiophene",
      "CHEMBL378797",
      "55827-09-3",
      "AC1MS3AA",
      "SCHEMBL1181273",
      "CTK1F5890",
      "DTXSID30393578",
      "SNVBIBDQUGLRIV-UHFFFAOYSA-N",
      "ZINC405078",
      "BDBM50178543",
      "AKOS024342405",
      "MCULE-5357210093",
      "Thiophene, 2,5-Bis(4-Methoxyphenyl)-",
    ],
  },
  {
    primary_id: "D02FZW",
    names: ["17-Methyl-4'-Methyldihydromorphinone", "CHEMBL607018"],
  },
  {
    primary_id: "D02GAC",
    names: [
      "Clomocycline",
      "Chlormethylenecycline",
      "Clomociclina",
      "Clomocyclina",
      "Clomocyclinum",
      "Clomociclina [INN-Spanish]",
      "Clomocycline (INN)",
      "Clomocycline [INN:BAN]",
      "Clomocyclinum [INN-Latin]",
      "N-2-Hydroxymethylchlortetracycline",
      "N'-Methylolo-Chlortetracycline",
      "(2Z,4S,4aS,5aS,6S,12aS)-7-Chloro-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-2-[hydroxy-(Hydroxymethylamino)Methylidene]-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(4S,4aS,5aS,6S,12aS)-7-Chloro-4-(Dimethylamino)-3,6,10,12,12a-Pentahydroxy-N-(Hydroxymethyl)-6-Methyl-1,11-Dioxo-1,4,4a,5,5a,6,11,12a-Octahydrotetracene-2-Carboxamide",
      "7-Chloro-4-(Dimethylamino)-1,4,4a,5,5a,6,11,12a-Octahydro-3,6,10,12,12a-Pentahydroxy-N-(Hydroxymethyl)-6-Methyl-1,11-Dioxo-2-Naphthacenecarboxamide",
    ],
  },
  {
    primary_id: "D02GBZ",
    names: ["Tau Phosphorylation Inhibitors"],
  },
  {
    primary_id: "D02GCE",
    names: ["PD182905"],
  },
  {
    primary_id: "D02GCM",
    names: ["SiRNA Therapeutics, CNS Disorders, Nasal"],
  },
  {
    primary_id: "D02GCY",
    names: ["Cyclopropyl-Spiro Piperidine Derivative 3"],
  },
  {
    primary_id: "D02GCZ",
    names: ["PMID27967267-Compound-Stenine"],
  },
  {
    primary_id: "D02GDG",
    names: ["NSC-640353"],
  },
  {
    primary_id: "D02GDK",
    names: ["Cx-601"],
  },
  {
    primary_id: "D02GDN",
    names: ["Berirab"],
  },
  {
    primary_id: "D02GEC",
    names: ["Omalizumab"],
  },
  {
    primary_id: "D02GEJ",
    names: ["PMID28766366-Compound-Scheme16DMAT"],
  },
  {
    primary_id: "D02GEQ",
    names: ["Lysophosphatidylethanolamine"],
  },
  {
    primary_id: "D02GEW",
    names: ["AdcAhxArg4Lys-PEGOMe", "CHEMBL426336"],
  },
  {
    primary_id: "D02GFK",
    names: ["3-(6-Methoxynaphthalen-2-Yl)Pyridin-4-Amine", "CHEMBL453169", "ZINC40835651", "BDBM50272248"],
  },
  {
    primary_id: "D02GFM",
    names: ["18S-Resolvin E1", "18S-RvE1"],
  },
  {
    primary_id: "D02GFZ",
    names: ["Heparin-EGF-Like Factor"],
  },
  {
    primary_id: "D02GGG",
    names: ["BLI-1100"],
  },
  {
    primary_id: "D02GIA",
    names: ["BL-7040"],
  },
  {
    primary_id: "D02GIB",
    names: ["ISIS 111119"],
  },
  {
    primary_id: "D02GIU",
    names: [
      "Cilastatin",
      "Cilastatina",
      "Cilastatine",
      "Cilastatinum",
      "Cilastatin Monosodium Salt",
      "Cilastatin Acid",
      "Cilastatina [Spanish]",
      "Cilastatine [French]",
      "Cilastatinum [Latin]",
      "MK0791",
      "Cilastatin (INN)",
      "Cilastatin [INN:BAN]",
      "MK-791",
      "(2Z)-7-{[(2R)-2-Amino-2-Carboxyethyl]sulfanyl}-2-({[(1S)-2,2-Dimethylcyclopropyl]carbonyl}amino)Hept-2-Enoic Acid",
      "(Z)-(S)-6-Carboxy-6-[(S)-2,2-Dimethylcyclopropanecarboxamido]hex-5-Enyl-L-Cysteine",
      "(Z)-7-[(2R)-2-Amino-3-Hydroxy-3-Oxopropyl]sulfanyl-2-[[(1S)-2,2-Dimethylcyclopropanecarbonyl]amino]hept-2-Enoic Acid",
    ],
  },
  {
    primary_id: "D02GJE",
    names: ["CG-8840", "CV-884"],
  },
  {
    primary_id: "D02GJG",
    names: [
      "1,2-Bis(2-Fluorophenyl)Ethane-1,2-Dione",
      "CHEMBL244633",
      "573-43-3",
      "Bis(2-Fluorophenyl) Diketone",
      "SCHEMBL2156696",
      "CTK1F2289",
      "DTXSID60453098",
      "Ethanedione, Bis(2-Fluorophenyl)-",
      "ZINC28713881",
      "BDBM50209423",
      "AKOS030595584",
      "1,2-Bis(2-Fluorophenyl)-1,2-Ethanedione",
    ],
  },
  {
    primary_id: "D02GJI",
    names: [
      "3-Methoxy-N-(1,3,4-Thiadiazol-2-Yl)Benzamide",
      "ASN 03033124",
      "AC1N2XQD",
      "Oprea1_831715",
      "CHEMBL388817",
      "MolPort-000-015-090",
      "HMS1702F08",
      "ZINC4167694",
      "STK237517",
      "AKOS000648965",
      "MCULE-6144605851",
      "ST064843",
      "3-Methoxy-N-[1,3,4]thiadiazol-2-Yl-Benzamide",
      "3-Methoxy-N~1~-(1,3,4-Thiadiazol-2-Yl)Benzamide",
    ],
  },
  {
    primary_id: "D02GJZ",
    names: ["Ioflupane I-123", "Datscan (TN)"],
  },
  {
    primary_id: "D02GKJ",
    names: ["Monosialoganglioside"],
  },
  {
    primary_id: "D02GLD",
    names: [
      "MF-101",
      "DW-700",
      "DW-700)",
      "Menopause Formula-101",
      "Estrogen Receptor Beta Agonist (Menopausal Symptoms), Bionovo",
      "Liquiritigenin (Menopausal Symptoms), Bionovo",
    ],
  },
  {
    primary_id: "D02GLM",
    names: ["2,4-Diisobutylamino-6-Isopentylpyrimidine", "CHEMBL347927", "ZINC13559143", "BDBM50138886"],
  },
  {
    primary_id: "D02GLY",
    names: ["Br-5MPEPy", "SCHEMBL3963310", "GTPL6397", "CHEMBL475270"],
  },
  {
    primary_id: "D02GMT",
    names: ["Org-7797"],
  },
  {
    primary_id: "D02GMU",
    names: ["Tyr-Pro-3,5Dmp-Phe-NH2", "CHEMBL413892"],
  },
  {
    primary_id: "D02GNH",
    names: ["Quinazoline Derivative 9"],
  },
  {
    primary_id: "D02GNL",
    names: ["KMJ-372"],
  },
  {
    primary_id: "D02GNN",
    names: ["N-Methyl-3,3-Diphenylpropan-1-Amine"],
  },
  {
    primary_id: "D02GNX",
    names: [
      "N*4*-Benzyl-6-Chloro-Pyrimidine-2,4-Diamine",
      "91066-67-0",
      "CHEMBL158113",
      "N4-Benzyl-6-Chloropyrimidine-2,4-Diamine",
      "N~4~-Benzyl-6-Chloropyrimidine-2,4-Diamine",
      "4-N-Benzyl-6-Chloropyrimidine-2,4-Diamine",
      "N~4~-Benzyl-6-Chloro-2,4-Pyrimidinediamine",
      "2,4-Pyrimidinediamine, 6-Chloro-N4-(Phenylmethyl)-",
      "Oprea1_700029",
      "AC1Q52S4",
      "AC1LS938",
      "SCHEMBL1319082",
      "CTK6G7145",
      "DTXSID50363293",
      "GXNJAZPAQIPYMJ-UHFFFAOYSA-N",
      "MolPort-001-843-083",
      "KS-00003D1N",
      "ALBB-022867",
      "ZINC1398022",
      "SBB098322",
      "BDBM50022150",
      "AKOS015993366",
    ],
  },
  {
    primary_id: "D02GOE",
    names: ["(Sar)WTLNSAGYLLGPKK(Lys-Lauroyl)K", "CHEMBL507733"],
  },
  {
    primary_id: "D02GOG",
    names: ["BY-963"],
  },
  {
    primary_id: "D02GOH",
    names: [
      "AM-404",
      "AM 404",
      "183718-77-6",
      "Am404",
      "UNII-XVJ94H0U21",
      "198022-70-7",
      "CHEMBL39878",
      "XVJ94H0U21",
      "CPD000449274",
      "(5Z,8Z,11Z,14Z)-N-(4-Hydroxyphenyl)Icosa-5,8,11,14-Tetraenamide",
      "N-(4-Hydroxyphenyl)-Eicosa-5,8,11,14-Tetraenamide",
      "SMR000449274",
      "SR-01000597390",
      "N-(4-Hydroxyphenyl)-Arachidonylamide",
      "N-(4-Hydroxyphenyl)Arachidonylamide",
      "N-(4-Hydroxyphenyl)Arachidonoyl Amide",
      "NCGC00025010-01",
      "Tocris-1116",
      "AC1O7GXA",
      "5,8,11,14-Eicosatetraenamide, N-(4-Hydroxyphenyl)-, (All-Z)-",
      "N-(4-Hydroxyphenyl)Icosa-5,8,11,14-Tetraenamide",
    ],
  },
  {
    primary_id: "D02GPG",
    names: ["JNJ-42165279"],
  },
  {
    primary_id: "D02GPJ",
    names: [
      "CEP-6800",
      "UNII-0X7U7SRK9H",
      "0X7U7SRK9H",
      "CHEMBL247374",
      "609848-02-4",
      "SCHEMBL12256417",
      "BDBM50197585",
      "1H-Cyclopenta(A)Pyrrolo(3,4-C)Carbazole-1,3(2H)-Dione, 10-(Aminomethyl)-4,5,6,7-Tetrahydro-",
      "8-Aminomethyl-1,2,3,11-Tetrahydro-5,11-Diaza-Benzo[a]trindene-4,6-Dione",
    ],
  },
  {
    primary_id: "D02GPZ",
    names: [
      "Beta-2 Adrenergic Receptor Targeting Mabs",
      "Beta-2 Adrenergic Receptor Targeting MAbs (Respiratory Diseases)",
    ],
  },
  {
    primary_id: "D02GRR",
    names: ["Oleclumab"],
  },
  {
    primary_id: "D02GRY",
    names: ["PMID17358052C5b"],
  },
  {
    primary_id: "D02GSA",
    names: ["RBx10558"],
  },
  {
    primary_id: "D02GSD",
    names: ["3VM1001 Topical Cream"],
  },
  {
    primary_id: "D02GSL",
    names: ["4-Iodo-6-Nitro-2-Piperazin-1-Yl-Quinoline", "CHEMBL167475", "SCHEMBL6365240", "BDBM50110579"],
  },
  {
    primary_id: "D02GSX",
    names: ["2-(2-Aminoethyl)Anthra[1,9-Cd]pyrazol-6(2H)-One", "CHEMBL1163331"],
  },
  {
    primary_id: "D02GTN",
    names: ["ZY-15106"],
  },
  {
    primary_id: "D02GTT",
    names: ["AR-XYZ", "CB2 Agonists (Pain/Inflammation)", "CB2 Agonists (Pain/Inflammation), Arena Pharmaceuticals"],
  },
  {
    primary_id: "D02GTU",
    names: ["Benzoyl-Piperidine Derivative 2"],
  },
  {
    primary_id: "D02GTV",
    names: ["Menomune"],
  },
  {
    primary_id: "D02GTY",
    names: ["Tamibarotene"],
  },
  {
    primary_id: "D02GTZ",
    names: ["[3,4''']biflavone", "CHEMBL204948", "[3,4'''''']biflavone", "BDBM50183244"],
  },
  {
    primary_id: "D02GUG",
    names: ["Ajmaline", "(17R,21"],
  },
  {
    primary_id: "D02GVH",
    names: ["PIRODAVIR"],
  },
  {
    primary_id: "D02GWF",
    names: ["PACTIMIBE"],
  },
  {
    primary_id: "D02GWJ",
    names: ["MCT-125"],
  },
  {
    primary_id: "D02GYD",
    names: ["CDP-845"],
  },
  {
    primary_id: "D02GZX",
    names: [
      "2-(3'-Ethylsulfanyl-Biphenyl-4-Yl)-Propionic Acid",
      "CHEMBL107938",
      "BDBM50074924",
      "2-(3'-Ethylthio-4-Biphenylyl)Propanoic Acid",
      "2-(3''-Ethylsulfanyl-Biphenyl-4-Yl)-Propionic Acid",
    ],
  },
  {
    primary_id: "D02HAL",
    names: ["Uridine-5'-Diphosphate"],
  },
  {
    primary_id: "D02HBB",
    names: ["AEZS-108"],
  },
  {
    primary_id: "D02HBI",
    names: [
      "Bmab-100",
      "Anti-VEGF MAb (Cancer), Biocon",
      "Anti-Vascular Endothelial Growth Factor Monoclonal Antibody (Cancer), Biocon",
    ],
  },
  {
    primary_id: "D02HBW",
    names: ["SCH-32615", "(S)-N-[N-(1-Carboxy-2-Phenylethyl)-L-Phenylalanyl]-Beta-Alanine", "83861-02-3"],
  },
  {
    primary_id: "D02HCQ",
    names: [
      "Chlorogenic Acid",
      "CHLOROGENIC ACID",
      "327-97-9",
      "3-Caffeoylquinic Acid",
      "3-O-Caffeoylquinic Acid",
      "3-(3,4-Dihydroxycinnamoyl)Quinic Acid",
      "Chlorogenate",
      "Heriguard",
      "Hlorogenic Acid",
      "Caffeoyl Quinic Acid",
      "NSC-407296",
      "3-Caffeoylquinate",
      "UNII-318ADP12RI",
      "5-O-(3,4-Dihydroxycinnamoyl)-L-Quinic Acid",
      "CCRIS 1400",
      "Chlorogenic Acid (8CI)",
      "EINECS 206-325-6",
      "NSC 70861",
      "NSC 407296",
      "3-Trans-Caffeoylquinic Acid",
      "CHEMBL284616",
      "318ADP12RI",
      "CHEBI:16112",
      "1,3,4,5-Tetrahydroxycyclohexanecarboxylic Acid 3-(3,4-Dihydroxycinnamate)",
      "3-(3,4-Dihydroxycinnamoyl)Quinate",
    ],
  },
  {
    primary_id: "D02HCU",
    names: ["WEB-2347"],
  },
  {
    primary_id: "D02HDG",
    names: [
      "AQX-1125",
      "AQX-108",
      "AQX-131",
      "AQX-132",
      "AQX-133",
      "AQX-134",
      "AQX-135",
      "AQX-140",
      "AQX-150",
      "SHIP Agonists (Cancer/Inflammation), Aquinox",
    ],
  },
  {
    primary_id: "D02HDI",
    names: ["Arachidonylcyclopropylamide", "Arachidonyl Cyclopropylamide"],
  },
  {
    primary_id: "D02HDP",
    names: ["V-1810"],
  },
  {
    primary_id: "D02HED",
    names: [
      "Perphenazine",
      "Chlorperphenazine",
      "Chlorpiprazine",
      "Decentan",
      "Emesinal",
      "Etaperazin",
      "Etaperazine",
      "Ethaperazine",
      "Fentazin;PZC",
      "Perfenazina",
      "Perfenazine",
      "Perphenan",
      "Perphenazin",
      "Perphenazinum",
      "Thilatazin",
      "Tranquisan",
      "Trifaron",
      "Trilafon",
      "Trilifan",
      "Triphenot",
      "Perfenazina [Italian]",
      "Perphenazine [Trilafon]",
      "Perphenazine Maleate",
      "P 6402",
      "Sch 3940",
      "Apo-Perphenazine",
      "F-Mon",
      "Perfenazina [INN-Spanish]",
      "Perphenazinum [INN-Latin]",
      "SCH-3940",
      "Siquil (TN)",
      "Triavil (TN)",
      "Perphenazine [INN:BAN:JAN]",
      "Perphenazine (JP15/USP/INN)",
      "Gamma-[4-(Beta-Hydroxyethyl)Piperazin-1-Yl]propyl-2-Chlorophenothiazine",
      "Gamma-(4-(Beta-Hydroxyethyl)Piperazin-1-Yl)Propyl-2-Chlorophenothiazine",
      "1',1-(2-Idrossietil)-4,3-(2-Cloro-10-Fenotiazil)Propilpiperazina",
      "1',1-(2-Idrossietil)-4,3-(2-Cloro-10-Fenotiazil)Propilpiperazina [Italian]",
      "1-(2-Hydroxyethyl)-4-(3-(2-Chloro-10-Phenothiazinyl)Propyl)Piperazine",
      "1-(2-Hydroxyethyl)-4-[3-(2-Chloro-10-Phenothiazinyl)Propyl]piperazine",
      "1-Piperazineethanol, 4-[3-(2-Chlorophenothiazin-10-Yl)Propyl]-, Trihydrochloride",
      "2-(4-[3-(2-Chloro-10H-Phenothiazin-10-Yl)Propyl]-1-Piperazinyl)Ethanol",
      "2-Chloro-10-(3-(1-(2-Hydroxyethyl)-4-Piperazinyl)Propyl)Phenothiazine",
      "2-Chloro-10-(3-(4-(2-Hydroxyethyl)Piperazin-1-Yl)Propyl)Phenothiazine",
      "2-Chloro-10-3-(1-(2-Hydroxyethyl)-4-Piperazinyl)Propyl Phenothiazine",
      "2-Chloro-10-3-[1-(2-Hydroxyethyl)-4-Piperazinyl]propyl Phenothiazine",
      "2-Chloro-10-[3-[4-(2-Hydroxyethyl)Piperazin-1-Yl]propyl]phenothiazine",
      "2-[4-[3-(2-Chlorophenothiazin-10-Yl)Propyl]piperazin-1-Yl]ethanol;2-{4-[3-(2-Chloro-Phenothiazin-10-Yl)-Propyl]-Piperazin-1-Yl}-Ethanol",
      "2-{4-[3-(2-Chloro-10H-Phenothiazin-10-Yl)Propyl]piperazin-1-Yl}ethanol",
      "4-(3-(2-Chlorophenothiazin-10-Yl)Propyl)-1-Piperazineethanol",
      "4-[3-(2-Chloro-10H-Phenothiazin-10-Yl)Propyl]-1-Piperazineethanol",
      "4-[3-(2-Chlorophenothiazin-10-Yl)Propyl]-1-Piperazineethanol",
    ],
  },
  {
    primary_id: "D02HEK",
    names: [
      "1-(9H-Beta-Carbolin-3-Yl)-Ethanone",
      "82596-93-8",
      "3-Acetyl-Beta-Carboline",
      "1-(9H-Pyrido[3,4-B]indol-3-Yl)-Ethanone",
      "CHEMBL11863",
      "SCHEMBL10857122",
      "CTK3D8180",
      "DTXSID90509895",
      "MPLXFBXMWYMAFN-UHFFFAOYSA-N",
      "ZINC26025316",
      "AKOS030597437",
      "1-(9H-Beta-Carbolin-3-Yl)Ethan-1-One",
      "Ethanone, 1-(9H-Pyrido[3,4-B]indol-3-Yl)-",
    ],
  },
  {
    primary_id: "D02HFB",
    names: ["Rizatriptan Oral Film"],
  },
  {
    primary_id: "D02HFD",
    names: ["Glutathione"],
  },
  {
    primary_id: "D02HFN",
    names: ["BFCR4350A"],
  },
  {
    primary_id: "D02HGS",
    names: ["5-Chloro-2-(4-Hydroxy-Phenyl)-Quinolin-6-Ol"],
  },
  {
    primary_id: "D02HHM",
    names: ["SGN-CD19B"],
  },
  {
    primary_id: "D02HHW",
    names: ["CP608,039", "CP-608,039"],
  },
  {
    primary_id: "D02HJR",
    names: ["SID-530"],
  },
  {
    primary_id: "D02HKC",
    names: ["MUC1-Poly-ICLC"],
  },
  {
    primary_id: "D02HKL",
    names: ["Suriclone"],
  },
  {
    primary_id: "D02HLL",
    names: ["RQ-00317076", "COX-2 Inhibitor (Oral, Pain), RaQualia"],
  },
  {
    primary_id: "D02HMN",
    names: ["2-(Difluoromethyl)-9H-Carbazole", "CHEMBL1170284"],
  },
  {
    primary_id: "D02HMZ",
    names: ["4-(2'-(Trifluoromethoxy)Biphenyl-3-Yl)Thiazole", "CHEMBL1224656", "BDBM50325809"],
  },
  {
    primary_id: "D02HNE",
    names: ["5-(2-(Pyrrolidin-1-Yl)Ethyl)Isothiazole", "CHEMBL272131"],
  },
  {
    primary_id: "D02HNF",
    names: ["Radium-223 Chloride", "Alpharadin (TN)"],
  },
  {
    primary_id: "D02HNG",
    names: ["MK-0752"],
  },
  {
    primary_id: "D02HNM",
    names: ["Phaclofen"],
  },
  {
    primary_id: "D02HNQ",
    names: ["PMID21155570C14"],
  },
  {
    primary_id: "D02HOG",
    names: ["RU78300"],
  },
  {
    primary_id: "D02HOL",
    names: [
      "8-Bromo-9-Propyl-9H-Adenine",
      "CHEMBL506450",
      "8-Bromo-9-Propyl-9H-Purin-6-Ylamine",
      "908355-66-8",
      "BDBM50256893",
      "8-Bromo-9-Propyl-9H-Purine-6-Amine",
    ],
  },
  {
    primary_id: "D02HOM",
    names: ["PMID26004420-Compound-WO2013062900B"],
  },
  {
    primary_id: "D02HOP",
    names: [
      "6-Iodo-2-Piperazin-1-Yl-Quinoline",
      "296759-25-6",
      "6-Iodo-2-(Piperazin-1-Yl)Quinoline",
      "CHEMBL38466",
      "2-Piperazino-6-Iodoquinoline",
      "SCHEMBL6364907",
      "C13H14IN3",
      "DTXSID00476408",
      "ZINC2527444",
      "3556AJ",
      "BDBM50090210",
      "AKOS024260375",
      "NE63975",
      "AB19831",
      "ACM296759256",
      "AJ-83657",
      "AX8289444",
      "DB-068143",
    ],
  },
  {
    primary_id: "D02HPF",
    names: ["Biaryl Mannoside Derivative 9"],
  },
  {
    primary_id: "D02HPI",
    names: ["FGI-103-723"],
  },
  {
    primary_id: "D02HQK",
    names: ["2-Biphenyl-4-Yl-N-Hydroxy-N-Methyl-Acetamide", "CHEMBL174051", "BDBM50015169"],
  },
  {
    primary_id: "D02HQR",
    names: [
      "Anti-HBs Human Mab",
      "Anti-HBs Human MAb, SC World",
      "Anti-Hepatitis B Surface Antigen Human Monoclonal Antibody, SC World",
    ],
  },
  {
    primary_id: "D02HQS",
    names: ["1,2-Bis(2,4-Difluorophenyl)Ethane-1,2-Dione", "CHEMBL242287", "SCHEMBL8070666"],
  },
  {
    primary_id: "D02HRB",
    names: ["RWJ-16979"],
  },
  {
    primary_id: "D02HRD",
    names: ["KLS-0611"],
  },
  {
    primary_id: "D02HSB",
    names: ["Cabazitaxel"],
  },
  {
    primary_id: "D02HSP",
    names: ["(-)-Cubebinin", "CHEMBL480296", "BDBM50259873"],
  },
  {
    primary_id: "D02HSZ",
    names: ["IODOINDOMETHACIN"],
  },
  {
    primary_id: "D02HTD",
    names: ["GS-9451"],
  },
  {
    primary_id: "D02HTG",
    names: [
      "1-(3,5-Difluorobenzyl)-1H-Imidazole",
      "CHEMBL446946",
      "170166-01-5",
      "SCHEMBL10518956",
      "CTK0E4909",
      "DTXSID20474067",
      "1-(3,5-Difluorobenzyl)Imidazole",
      "ZINC36466875",
      "BDBM50188089",
      "AKOS027391623",
      "1-[(3,5-Difluorophenyl)Methyl]-1H-Imidazole",
      "1H-Imidazole, 1-[(3,5-Difluorophenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D02HUB",
    names: ["Nestorone Transdermal Spray"],
  },
  {
    primary_id: "D02HUU",
    names: ["FluPro"],
  },
  {
    primary_id: "D02HUW",
    names: ["SAR-548304", "Bile Acid Reabsorption Inhibitor (Hypercholesterolemia), Sanofi-Aventis"],
  },
  {
    primary_id: "D02HUY",
    names: ["Debio 0827"],
  },
  {
    primary_id: "D02HVH",
    names: [
      "7-Mercapto-N-(4-Phenylthiazol-2-Yl)Heptanamide",
      "CHEMBL419758",
      "NCH-31",
      "JMC505425 Compound 7",
      "BDBM19131",
      "7-Mercapto-N-(4-Phenyl-2-Thiazolyl)Heptanamide",
      "N-(4-Phenyl-1,3-Thiazol-2-Yl)-7-Sulfanylheptanamide",
    ],
  },
  {
    primary_id: "D02HVL",
    names: [
      "(3-Chloro-4-Propoxy-Phenyl)-Acetic Acid",
      "(3-Chloro-4-Propoxyphenyl)Acetic Acid",
      "(3-CHLORO-4-PROPOXY-PHENYL)-ACETIC ACID",
      "2-(3-Chloro-4-Propoxyphenyl)Acetic Acid",
      "AC1L1CVR",
      "SCHEMBL4321455",
      "CTK6E6068",
      "MolPort-005-983-242",
      "HMS3604N10",
      "ZINC3871464",
      "STL386671",
      "AKOS000296250",
      "MCULE-5882985924",
    ],
  },
  {
    primary_id: "D02HVT",
    names: [
      "AcAsp-D-Gla-Leu-Ile-Cha-Cys",
      "CHEMBL179084",
      "AC1NX3UI",
      "BDBM50158804",
      "(4S)-4-[[(2S)-2-Acetamido-4-Hydroxy-4-Oxobutanoyl]amino]-5-[[(2S)-1-[[(2S,3R)-1-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-3-Methyl-1-Oxopentan-2-Yl]amino]-4-Methyl-1-Oxopentan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D02HVW",
    names: ["Zibotentan"],
  },
  {
    primary_id: "D02HWL",
    names: ["Oleoyl-Lysophosphatidylcholine"],
  },
  {
    primary_id: "D02HWP",
    names: [
      "CHLOROXINE",
      "Chloroxine",
      "5,7-Dichloroquinolin-8-Ol",
      "773-76-2",
      "5,7-Dichloro-8-Hydroxyquinoline",
      "Chlorquinol",
      "Quixalin",
      "Capitrol",
      "Dichloroxin",
      "Chloroxyquinoline",
      "Dichloroquinolinol",
      "Dikhloroskin",
      "5,7-Dichloro-8-Quinolinol",
      "Endiaron",
      "Clofuzid",
      "Quinolor",
      "Dichlorohydroxyquinoline",
      "Chlofucid",
      "Quesyl",
      "5,7-Dichloroxine",
      "5,7-Dichlorooxine",
      "Chlorohydroxyquinoline",
      "8-QUINOLINOL, 5,7-DICHLORO-",
      "5,7-Dichloro-8-Oxyquinoline",
      "Chloroxine [USAN]",
      "5,7-Dichlor-8-Hydroxychinolin",
      "C9H5Cl2NO",
      "NSC 3904",
      "UNII-2I8BD50I8B",
      "CCRIS 5751",
      "CHQ",
      "Capitrol Cream Shampoo",
      "EINECS 212-2",
    ],
  },
  {
    primary_id: "D02HWR",
    names: [
      "PV-1019",
      "Checkpoint Kinase 2 Inhibitor (Cancer), Provid Pharmaceuticals",
      "Chk2 Inhibitor (Cancer), Provid Pharmaceuticals",
    ],
  },
  {
    primary_id: "D02HWU",
    names: ["IMT-1012"],
  },
  {
    primary_id: "D02HXC",
    names: ["NSC-30171"],
  },
  {
    primary_id: "D02HXS",
    names: [
      "Bufexamac",
      "2438-72-4",
      "Bufexamic Acid",
      "2-(4-Butoxyphenyl)-N-Hydroxyacetamide",
      "Parfenac",
      "Parfenal",
      "Feximac",
      "Mofenar",
      "Malipuran",
      "Droxaryl",
      "Bufexamacum",
      "Flogicid",
      "Bufexamaco",
      "Paraderm",
      "Norfemac",
      "Anderm",
      "4-Butoxy-N-Hydroxybenzeneacetamide",
      "P-Butoxyphenylacetohydroxamic Acid",
      "4-Butoxyphenylacetohydroxamic Acid",
      "Flogocid N Plastigel",
      "Acide P-Butoxyphenylacethydroxamique",
      "2-(P-Butoxyphenyl)-Acetohydroxamic Acid",
      "Bufessamac [DCIT]",
      "Bufexamacum [INN-Latin]",
      "Bufexamaco [INN-Spanish]",
      "CP 1044 J3",
      "UNII-4T3C38J78L",
      "Benzeneacetamide, 4-Butoxy-N-Hyd",
    ],
  },
  {
    primary_id: "D02HYG",
    names: [
      "4-Aminobutanal",
      "4-Aminobutyraldehyde",
      "Butanal, 4-Amino-",
      "Gamma-Aminobutyraldehyde",
      "4390-05-0",
      "4-Amino-Butyraldehyde",
      "Butyraldehyde, 4-Amino-",
      "CHEBI:17769",
      "4-Ammoniobutanal",
      "4-Amino-Butanal",
      "AC1Q6QLB",
      "AC1L18IO",
      "Butanal, 4-Amino- (9CI)",
      "GTPL6606",
      "CHEMBL2261442",
      "CTK1D6325",
      "DZQLQEYLEYWJIB-UHFFFAOYSA-N",
      "AKOS006338849",
      "TX-013160",
      "Butyraldehyde, 4-Amino- (6CI,7CI,8CI)",
      "C00555",
    ],
  },
  {
    primary_id: "D02HYK",
    names: [
      "Chondroitin",
      "UNSATURATED CHONDROITIN DISACCHARIDE",
      "Chondroitin Disaccharide",
      "4-DEOXY-L-THREO-HEX-4-ENOPYRANOSYLURONIC ACID-(1,3)-N-ACETYL-D-GALACTOSAMINE",
      "(2R,3R,4S)-2-[(2R,3R,4R,5R,6R)-3-Acetamido-2,5-Dihydroxy-6-(Hydroxymethyl)Oxan-4-Yl]oxy-3,4-Dihydroxy-3,4-Dihydro-2H-Pyran-6-Carboxylic Acid",
      "(3R,4R)-2-{[(2R,3S,4R,5R,6R)-3-Acetamido-2,5-Dihydroxy-6-(Hydroxymethyl)Oxan-4-Yl]oxy}-3,4-Dihydroxy-3,4-Dihydro-2H-Pyran-6-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D02HYT",
    names: [
      "N-(2,6-Diphenylpyrimidin-4-Yl)Propionamide",
      "CHEMBL222474",
      "820961-41-9",
      "SCHEMBL4825106",
      "CTK3E2662",
      "DTXSID00465933",
      "BDBM50157672",
      "Propanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D02HYW",
    names: ["CUDRATRICUSXANTHONE"],
  },
  {
    primary_id: "D02HZG",
    names: [
      "2-(2-Bromophenoxy)-N,N-Dimethylethanamine",
      "886851-37-2",
      "2-(2-Dimethylaminoethoxy)-Bromobenzene",
      "CHEMBL599818",
      "[2-(2-Bromophenoxy)Ethyl]dimethylamine",
      "AC1LHJHJ",
      "SCHEMBL1857526",
      "DTXSID50358341",
      "MolPort-004-331-572",
      "ZINC4914895",
      "KS-000000PE",
      "BDBM50307492",
      "2-(2-Dimethylaminoethoxy)Bromobenzene",
      "AKOS000170274",
      "MCULE-8721188188",
      "AM87375",
      "AS-44355",
      "AJ-52607",
      "KB-323008",
      "Y-0778",
    ],
  },
  {
    primary_id: "D02HZJ",
    names: ["N-(2,6-Diphenylpyrimidin-4-Yl)Benzamide", "CHEMBL441555", "SCHEMBL4825271"],
  },
  {
    primary_id: "D02IAR",
    names: ["Nov-038"],
  },
  {
    primary_id: "D02IBE",
    names: ["ISIS 32035"],
  },
  {
    primary_id: "D02IBM",
    names: [
      "SQ-29548",
      "7-(3-((2-((Phenylamino)Carbonyl)Hydrazino)Methyl)-7-Oxabicyclo(221)Hept-2-Yl)-5-Heptenoic Acid",
      "SQ 28053",
      "(Z)-7-[(1R,4S,5R,6R)-5-[(2-(Phenylcarbamoyl)Hydrazinyl)Methyl]-7-Oxabicyclo[221]heptan-6-Yl]hept-5-Enoic Acid",
      "98672-91-4",
      "98299-61-7",
      "[3H]SQ29548",
      "SQ-28053",
      "[3H]SQ-29548",
      "[3H]-SQ29548",
      "[3H]SQ-28053",
      "GTPL1985",
      "GTPL1980",
      "5-Heptenoic Acid, 7-((1R,2R,3R,4S)-3-((2-((Phenylamino)Carbonyl)Hydrazino)Methyl)-7-Oxabicyclo(221)Hept-2-Yl)-, (5Z)-Rel-",
      "5-Heptenoic Acid,",
    ],
  },
  {
    primary_id: "D02IBS",
    names: ["INH-001"],
  },
  {
    primary_id: "D02IBU",
    names: ["Oritavancin"],
  },
  {
    primary_id: "D02IBX",
    names: ["Pyrrolo[2,3-B]pyridine Derivative 3"],
  },
  {
    primary_id: "D02ICI",
    names: ["VIT-45"],
  },
  {
    primary_id: "D02ICJ",
    names: ["Gdc-0810"],
  },
  {
    primary_id: "D02ICX",
    names: [
      "PYROVALERONE",
      "Centroton",
      "Thymergix",
      "PYROVALERONE HYDROCHLORIDE",
      "Pyrovalerone HCl",
      "Sp 1059",
      "Pyrovalerone Hydrochloride [USAN]",
      "EINECS 214-556-9",
      "F 1983",
      "1147-62-2",
      "4'-Methyl-2-(1-Pyrrolidinyl)Valerophenone Hydrochloride",
      "1-(P-Methylphenyl)-2-Pyrrolidyl-1-Pentanone Hydrochloride",
      "DEA No. 1485",
      "1-P-Tolyl-1-Oxo-2-Pyrrolidino-N-Pentane Hydrochloride",
      "Valerophenone, 4'-Methyl-2-(1-Pyrrolidinyl)-, Hydrochloride",
      "1-Pentanone, 1-(4-Methylphenyl)-2-(1-Pyrrolidinyl)-, Hydrochloride",
      "Pyrovalerone Hydrochloride (USAN)",
      "AC1L23PD",
      "SCHEMBL121638",
    ],
  },
  {
    primary_id: "D02IDM",
    names: [
      "(6-Methoxy-2-Naphthyl)-2-Aminopropane",
      "CHEMBL443427",
      "SCHEMBL13484409",
      "CTK7A8193",
      "BDBM50276190",
      "AKOS000161922",
      "AKOS022489543",
    ],
  },
  {
    primary_id: "D02IDN",
    names: ["2-(4-Amino-Phenyl)-Benzo[h]chromen-4-One", "CHEMBL179276", "BDBM50159660"],
  },
  {
    primary_id: "D02IED",
    names: ["5-Iododeoxyuridine", "54-42-2"],
  },
  {
    primary_id: "D02IEO",
    names: ["LY108742"],
  },
  {
    primary_id: "D02IET",
    names: ["PMID27774824-Compound-Figure3Example7"],
  },
  {
    primary_id: "D02IHW",
    names: ["Nepafenac"],
  },
  {
    primary_id: "D02IIE",
    names: ["Penicillin V Potassium"],
  },
  {
    primary_id: "D02IIL",
    names: ["KOSN1559"],
  },
  {
    primary_id: "D02IIM",
    names: ["FCX-013"],
  },
  {
    primary_id: "D02IIN",
    names: ["WIN 62,577"],
  },
  {
    primary_id: "D02IIW",
    names: ["Faropenem"],
  },
  {
    primary_id: "D02IJA",
    names: ["Tau-Binding PET Tracer"],
  },
  {
    primary_id: "D02IJB",
    names: ["SIB-1533A"],
  },
  {
    primary_id: "D02IJM",
    names: ["DAEDALIN A", "CHEMBL602533", "CHEBI:65721", "(2R)-2-(Hydroxymethyl)-2-Methyl-2H-Chromen-6-Ol"],
  },
  {
    primary_id: "D02IJP",
    names: ["CLX-0900", "CLX-0900E"],
  },
  {
    primary_id: "D02IJW",
    names: ["(S)-4-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Pyridine", "CHEMBL405069"],
  },
  {
    primary_id: "D02IKD",
    names: [
      "HG-1245",
      "AGR1 Modulator (Cancer), Human Genome Sciences",
      "ERP18 Modulator (Cancer), Human Genome Sciences",
      "Homolog Of Xenopus Anterior Gradient 1 Modulator (Cancer), Human Genome Sciences",
      "Human Colon Cancer Specific Gene Modulator (Cancer), Human Genome Sciences",
      "Human Growth Factors Modulator (Cancer), Human Genome Sciences",
      "TLP19 Modulator (Cancer), Human Genome Sciences",
      "Txndc12 Modulator (Cancer), Human Genome Sciences",
      "Endoplasmic Reticulum Protein 18-KD Modulator (Cancer), Human Genome Sciences",
      "Thioredoxin Domain-Containing Protein 12 Modulator (Cancer), Human Genome Sciences",
      "Thioredoxin-Like Protein P19 Modulator (Cancer), Human Genome Sciences",
      "XAG-2 Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D02IKF",
    names: [
      "M9831",
      "2-Methyl-1,3-Benzothiazole-5-Carboxylic Acid",
      "24851-69-2",
      "2-Methylbenzo[d]thiazole-5-Carboxylic Acid",
      "2-Methyl-Benzothiazole-5-Carboxylic Acid",
      "5-Benzothiazolecarboxylic Acid, 2-Methyl-",
      "2-Methylbenzothiazole-5-Carboxylic Acid",
      "SMR000015267",
      "MLS000030206",
      "AC1LDLFR",
      "AC1Q2PD7",
      "SCHEMBL1105986",
      "Cid_649953",
      "CHEMBL1480093",
      "CTK0J4554",
      "KS-00000IVD",
      "BDBM87603",
      "DTXSID50349491",
      "MolPort-002-499-021",
      "TVUFPZOJUJGDDD-UHFFFAOYSA-N",
      "HMS2396B08",
      "ZINC873707",
      "ZX-CM001754",
      "ZX-AL001070",
      "ANW-56675",
      "AKOS000320290",
      "FCH1781098",
      "CS-W019372",
      "MCULE-8154234969",
      "FS",
    ],
  },
  {
    primary_id: "D02IKQ",
    names: ["Hepatitis B DNA Vaccine"],
  },
  {
    primary_id: "D02ILH",
    names: ["PMID26394986-Compound-11"],
  },
  {
    primary_id: "D02ILI",
    names: ["ZP-G-CSF"],
  },
  {
    primary_id: "D02IMK",
    names: ["FGGFTGARKSARKRKRK"],
  },
  {
    primary_id: "D02IMZ",
    names: ["MCL-443"],
  },
  {
    primary_id: "D02IND",
    names: ["CBI-006"],
  },
  {
    primary_id: "D02INF",
    names: ["L-759,656"],
  },
  {
    primary_id: "D02INJ",
    names: [
      "4-(2-(Phenylamino)Ethyl)-1H-Imidazole",
      "CHEMBL1096514",
      "51720-84-4",
      "Phenylhistamine",
      "BDBM50317871",
      "ZINC49088978",
    ],
  },
  {
    primary_id: "D02INX",
    names: [
      "TD-1211",
      "Axelopran",
      "UNII-85U7ROB149",
      "85U7ROB149",
      "949904-48-7",
      "Axelopran [USAN:INN]",
      "TD 1211",
      "SCHEMBL1773803",
      "SCHEMBL1773801",
      "CHEMBL3137313",
      "AKOS027337117",
      "SB16947",
      "DB12013",
      "Benzamide, 3-((3-Endo)-8-(2-((Cyclohexylmethyl)((2S)-2,3-Dihydroxy-1-Oxopropyl)Amino)Ethyl)-8-Azabicyclo(3.2.1)Oct-3-Yl)-",
    ],
  },
  {
    primary_id: "D02INZ",
    names: [
      "BABIM",
      "Bis(5-Amidino-2-Benzimidazolyl)Methane",
      "74733-75-8",
      "CHEMBL99951",
      "1H-Benzimidazole-5-Carboximidamide, 2,2'-Methylenebis-",
      "1xug",
      "1c1v",
      "AC1L1DKB",
      "SCHEMBL65730",
      "AC1Q1U90",
      "CTK5E0415",
      "BDBM16127",
      "DTXSID60225717",
      "AIDS007118",
      "ZINC3871731",
      "AKOS028112598",
      "Bis(5-Amidino-1H-Benzimidazole-2-Yl)Methane",
      "1H-Benzimidazole-6-Carboximidamide,2,2'-Methylenebis-",
      "2,2 -Methanediylbis(1H-Benzimidazole-6-Carboximidamide)",
      "2-[(5-Carbamimidoyl-1H-Benzimidazol-2-Yl)Methyl]-1H-Benzimidazole-5-Carboxamidine",
    ],
  },
  {
    primary_id: "D02IOC",
    names: [
      "CP154,526",
      "CP-154526",
      "157286-86-7",
      "CP 154,526",
      "CHEMBL9946",
      "CP154526",
      "UNII-9A549FB00R",
      "9A549FB00R",
      "CHEMBL475903",
      "CP-154526-1",
      "CP-154,526",
      "N-Butyl-N-Ethyl-2,5-Dimethyl-7-(2,4,6-Trimethylphenyl)Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "N-Butyl-N-Ethyl-2,5-Dimethyl-7-(2,4,6-Trimethylphenyl)Pyrrolo(2,3-D)Pyrimidin-4-Amine",
      "PDSP1_001299",
      "AC1NSJWW",
      "Butyl-(2,5-Dimethyl-7-(2,4,6-Trimethylphenyl)-7H-Pyrrolo(2,3-D)Pyrimidin-4-Yl)Ethylamine",
      "SCHEMBL5707848",
      "GTPL3495",
      "FHQYJZCJRZHINA-UHFFFAOYSA-N",
      "ZINC1539133",
      "BCP26372",
      "BDBM50058163",
      "BDBM50369802",
    ],
  },
  {
    primary_id: "D02IOH",
    names: [
      "Alphaprodine",
      "Alphaprodin",
      "ALPHAPRODINE",
      "Prodine",
      "1,3-Dimethyl-4-Phenyl-4-Propionoxypiperidine",
      "4-Piperidinol, 1,3-Dimethyl-4-Phenyl-, Propanoate (Ester), Cis-",
      "Betaprodine",
      "Alpha-Prodinol",
      "1,3-Dimethyl-4-Phenyl-4-Piperidinol Propanoate",
      "Alphaprodine [INN:BAN]",
      "Alphaprodinum [INN-Latin]",
      "Alfaprodina [INN-Spanish]",
      "Prisilidin",
      "Prisilidine",
      "Nisintil",
      ".alpha.-Prodine",
      "HSDB 3283",
      ".alpha.-Prodinol",
      "EINECS 201-011-5",
      "4-Piperidinol, 1,3-Dimethyl-4-Phenyl-, Propanoate (Ester), Trans-",
      "Alphaprodine [USAN]",
      "Betaprodinum [INN-Latin]",
      "Betaprodina [INN-S",
    ],
  },
  {
    primary_id: "D02IPI",
    names: ["CTA-002"],
  },
  {
    primary_id: "D02IPX",
    names: ["Prostaglandin G2"],
  },
  {
    primary_id: "D02IQG",
    names: ["H-Dmt-Tic-NH-CH2-CH2-NH2", "CHEMBL241010"],
  },
  {
    primary_id: "D02IQH",
    names: ["7-Diazonium-Naphthalene-1-Sulfonic Acid Anion", "CHEMBL71830"],
  },
  {
    primary_id: "D02IQP",
    names: [
      "1-(Oxazolo[4,5-B]pyridin-2-Yl)Pentan-1-One",
      "CHEMBL402740",
      "SCHEMBL3961770",
      "BDBM50350542",
      "288862-78-2",
      "1-(Oxazolo[4,5-B]pyridin-2-Yl)-1-Oxo-Pentane",
    ],
  },
  {
    primary_id: "D02IQY",
    names: [
      "Ergoloid Mesylate",
      "Alkergot",
      "Circanol",
      "Gerimal",
      "Hydergin",
      "Ischelium",
      "Redergin",
      "Trigot",
      "Dihydroergotoxin Mesilat",
      "Dihydroergotoxinmesylate",
      "Dihydroergotoxin Methanesulfonate",
      "Dihydroergotoxine Methanesulfonate",
      "Dihydroergotoxine Methanesulphonate",
      "Ergoloid Mesylates [USAN]",
      "Hydergine LC",
      "Hydrogenated Ergot Alkaloids",
      "Alkergot (TN)",
      "Cicanol (TN)",
      "Deapril-ST",
      "Gerimal (TN)",
      "Hydergina (TN)",
      "Hydergine (TN)",
      "Niloric (TN)",
      "Redergin (TN)",
      "Redizork (TN)",
    ],
  },
  {
    primary_id: "D02IRC",
    names: ["4-(2-Cyanobenzylthio)-2-Aminobutanoic Acid", "CHEMBL204479", "BDBM50179704"],
  },
  {
    primary_id: "D02IRD",
    names: ["Megesterol Acetate"],
  },
  {
    primary_id: "D02IRL",
    names: ["IDX375"],
  },
  {
    primary_id: "D02ISP",
    names: ["SKI-2034R"],
  },
  {
    primary_id: "D02ITL",
    names: [
      "AV-201",
      "AAV (Amino Acid Decarboxylase), Avigen",
      "AAV (Tyrosine Hydroxylase), Avigen",
      "AAV Gene Therapy (Parkinsons), Avigen",
      "AAV Vector (AADC), Avigen",
      "AAV Vector (TH), Avigen",
      "AAV-HAADC-2",
      "Gene Therapy (PD), Avigen",
      "Gene Therapy (Parkinsons Disease), Avigen",
      "Gene Therapy (L-Aromatic Amino Acid Decarboxylase), Avigen",
    ],
  },
  {
    primary_id: "D02ITS",
    names: ["PMID1992149C9", "Compound 10b [PMID: 1597859]", "GTPL2984"],
  },
  {
    primary_id: "D02IUD",
    names: ["Sodium Fluoride F-18"],
  },
  {
    primary_id: "D02IUO",
    names: ["CORT-113083", "CORT-112716"],
  },
  {
    primary_id: "D02IUX",
    names: ["MA-2029"],
  },
  {
    primary_id: "D02IVA",
    names: ["PMID30273516-Compound-22a"],
  },
  {
    primary_id: "D02IVJ",
    names: [
      "S-(4-Fluorobenzyl)Isothiourea Hydrochloride",
      "122305-64-0",
      "4-FLUOROBENZYL CARBAMIMIDOTHIOATE HYDROCHLORIDE",
      "4-FLUOROBENZYL CARBAMIMIDOTHIOATE HCL",
      "CHEMBL1224313",
      "AC1MCYW1",
      "CTK7D2165",
      "MolPort-000-700-596",
      "S-(4-Fluorobenzyl)Isothiourea HCl",
      "AR2252",
      "AKOS027334840",
      "4-Fluorobenzyl Carbamimidothioate, HCl",
      "MCULE-7671257099",
      "(4-Fluorophenyl)Methyl Carbamimidothioate Hydrochloride",
    ],
  },
  {
    primary_id: "D02IVR",
    names: ["ONO-DI-004"],
  },
  {
    primary_id: "D02IVU",
    names: ["CACOSPONGIONOLIDE", "Cacospongionolide", "CHEMBL480681"],
  },
  {
    primary_id: "D02IWC",
    names: ["AZD2624"],
  },
  {
    primary_id: "D02IWH",
    names: ["F-G-G-F-T-G-A-R-K-S-MeA-R-K-L-A-N-Q-COOH"],
  },
  {
    primary_id: "D02IWY",
    names: ["ALKS 4230"],
  },
  {
    primary_id: "D02IXA",
    names: ["Uracil Derivative 1"],
  },
  {
    primary_id: "D02IXC",
    names: ["D-Phe-C[Cys-Tyr-D-Trp-Lys-Val-Cys]-Asp-NH2", "CHEMBL269532"],
  },
  {
    primary_id: "D02IYC",
    names: ["IMP-701", "Anti-Membrane Bound LAG-3 Monoclonal Antibody (Cancer, Infectious Disease), Immutep"],
  },
  {
    primary_id: "D02IYE",
    names: ["Cbz-Ile-Leu-L-(Difluoro)Aminobutyric Acid", "CHEMBL175623"],
  },
  {
    primary_id: "D02IYN",
    names: ["RU82129"],
  },
  {
    primary_id: "D02IZN",
    names: ["ON-123"],
  },
  {
    primary_id: "D02JAY",
    names: ["Stannsoporfin"],
  },
  {
    primary_id: "D02JBI",
    names: ["SAL-200"],
  },
  {
    primary_id: "D02JBK",
    names: ["ISIS 156453"],
  },
  {
    primary_id: "D02JCG",
    names: ["GL-ONC1"],
  },
  {
    primary_id: "D02JCM",
    names: ["N(6)-Cyclohexyladenosine"],
  },
  {
    primary_id: "D02JDL",
    names: ["Porfimer Sodium"],
  },
  {
    primary_id: "D02JDN",
    names: ["IFN-Alpha"],
  },
  {
    primary_id: "D02JDR",
    names: ["Aromatic And Heteroaryl Compound 1"],
  },
  {
    primary_id: "D02JER",
    names: ["Tetra-Substituted Urea Derivative 2"],
  },
  {
    primary_id: "D02JEW",
    names: ["GSK-2130579A"],
  },
  {
    primary_id: "D02JEZ",
    names: ["7-Hydroxycoumarin"],
  },
  {
    primary_id: "D02JFD",
    names: ["SB399885"],
  },
  {
    primary_id: "D02JFF",
    names: ["ISIS 16518"],
  },
  {
    primary_id: "D02JFQ",
    names: ["RNAi Therapeutics, Alnylam"],
  },
  {
    primary_id: "D02JFV",
    names: ["PMID28454500-Compound-58"],
  },
  {
    primary_id: "D02JGD",
    names: ["SKL-10406"],
  },
  {
    primary_id: "D02JGZ",
    names: ["Heparin Pentasaccharide"],
  },
  {
    primary_id: "D02JHE",
    names: ["[U-13C]ascomycin"],
  },
  {
    primary_id: "D02JHW",
    names: ["ANG-2864"],
  },
  {
    primary_id: "D02JIB",
    names: ["Ro-25-2016"],
  },
  {
    primary_id: "D02JIF",
    names: ["EDP-19"],
  },
  {
    primary_id: "D02JIL",
    names: ["N-(1H-Benzo[d]imidazol-2-Yl)-3-Nitrobenzamide"],
  },
  {
    primary_id: "D02JIN",
    names: ["264W94"],
  },
  {
    primary_id: "D02JIS",
    names: ["Iobenguane I-123"],
  },
  {
    primary_id: "D02JJU",
    names: ["2-(6-Hydroxy-Naphthalen-2-Yl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D02JJX",
    names: ["WAY-213613"],
  },
  {
    primary_id: "D02JKY",
    names: ["TD-9855"],
  },
  {
    primary_id: "D02JMI",
    names: ["SLV-310"],
  },
  {
    primary_id: "D02JMR",
    names: [
      "2-(3''-(6''-Chloro-Indolyl)Ethyloxy)Adenosine",
      "CHEMBL222907",
      "BDBM50208786",
      "2-(3''''-(6''''-Chloro-Indolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D02JMU",
    names: ["Tasquinimod"],
  },
  {
    primary_id: "D02JMZ",
    names: ["Recombinant Human Keratinocyte Growth Factor-2"],
  },
  {
    primary_id: "D02JNC",
    names: ["BCMA CAR-T"],
  },
  {
    primary_id: "D02JND",
    names: [
      "ADC-3680",
      "ADC-3680B",
      "CRTh2 Antagonists (Oral, Allergic Asthma/Allergic Rhinitis/COPD), Argenta Discovery",
      "CRTh2 Antagonists (Oral, Allergic Asthma/Allergic Rhinitis/COPD), Pulmagen",
      "Prostaglandin D2 Receptor Antagonists (Oral, COPD/Allergic Asthma/Allergic Rhinitis), Argenta Discovery",
    ],
  },
  {
    primary_id: "D02JNM",
    names: [
      "Desonide",
      "Apolar",
      "DesOwen",
      "Desilux",
      "Desonate",
      "Desonida",
      "Desonidum",
      "Flusemidon",
      "Hamiltoderm",
      "Locapred",
      "Prednacinolone",
      "Prenacid",
      "Reticus",
      "Sterax",
      "Steroderm",
      "Topifug",
      "Tridesilon",
      "Tridesonit",
      "Verdeso",
      "Zotinar",
      "Desfluorotriamcinolone Acetonide",
      "D 2083",
      "D-2083",
      "Desonida [INN-Spanish]",
      "Desonidum [INN-Latin]",
      "Desowen (TN)",
      "Verdeso (TN);Verdeso Foam (TN)",
      "Desonide (USAN/INN)",
      "Desonide [USAN:INN:BAN]",
      "Locapred, Topifug, Tridesilon, Desonide",
      "(4aR,4bS,5S,6aS,6bS,9aR,10aS,10bS)-5-Hydroxy-6b-(Hydroxyacetyl)-4a,6a,8,8-Tetramethyl-4a,4b,5,6,6a,6b,9a,10,10a,10b,11,12-Dodecahydro-2H-Naphtho[2',1':4,5]indeno[1,2-D][1,3]dioxol-2-One",
      "11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione Cyclic 16,17-Acetal With Acetone",
      "11beta,21-Dihydroxy-16alpha,17-[(1-Methylethylidene)Bis(Oxy)]pregna-1,4-Diene-3,20-Dione",
      "11beta,21-Dihydroxy-16alpha,17-Isopropylidenedioxypregna-1,4-Diene-3,20-Dione",
      "11beta,21-Dihydroxy-16alpha,17alpha-Isopropylidenedioxypregna-1,4-Diene-3,20-Dione",
      "16-Alpha-Hydroxyprednisole-16,17-Acetonide",
      "16alpha,17alpha-Isopropylidenedioxyprednisolone",
      "16alpha-Hydroxyprednisole-16,17-Acetonide",
      "16alpha-Hydroxyprednisolone-16alpha,17-Acetonide",
    ],
  },
  {
    primary_id: "D02JNX",
    names: ["OxycoDex"],
  },
  {
    primary_id: "D02JNY",
    names: [
      "BIBN-140",
      "Bibn 140",
      "145301-79-7",
      "SCHEMBL8879830",
      "Pentanamide, N-(3-(1-(2-(10,11-Dihydro-11-Oxo-5H-Dibenzo(B,e)(1,4)Diazepin-5-Yl)-2-Oxoethyl)-4-Piperidinyl)Propyl)-N-Ethyl-2,2-Dimethyl-",
    ],
  },
  {
    primary_id: "D02JOH",
    names: ["BMS-708163", "Avagacestat"],
  },
  {
    primary_id: "D02JOI",
    names: ["EPI-3905"],
  },
  {
    primary_id: "D02JOK",
    names: ["Sulfonamide Derivative 11"],
  },
  {
    primary_id: "D02JOZ",
    names: [
      "4-Bromo-6-Nitro-2-Piperazin-1-Yl-Quinoline",
      "4-Bromo-6-Nitroquipazine",
      "143954-73-8",
      "CHEMBL167825",
      "Quinoline, 4-Bromo-6-Nitro-2-(1-Piperazinyl)-",
      "ACMC-20n3fo",
      "SCHEMBL6364738",
      "AC1L318Y",
      "CTK0I3188",
      "DTXSID50162581",
      "NRCNLRMWSMFETI-UHFFFAOYSA-N",
      "BDBM50110574",
    ],
  },
  {
    primary_id: "D02JPD",
    names: [
      "3-(1-Benzyl-Piperidin-4-Yl)-2-Phenyl-1H-Indole",
      "CHEMBL327169",
      "SCHEMBL7955758",
      "BDBM50095051",
      "L018754",
    ],
  },
  {
    primary_id: "D02JPV",
    names: ["N-(Benzimidazole/Indole) Benzoic Acid Derivative 2"],
  },
  {
    primary_id: "D02JQB",
    names: ["PMID25666693-Compound-140"],
  },
  {
    primary_id: "D02JQP",
    names: ["GalB2"],
  },
  {
    primary_id: "D02JRB",
    names: ["6-Oxo-16-Formyl-Estrone", "CHEMBL202432"],
  },
  {
    primary_id: "D02JRD",
    names: ["VT-310"],
  },
  {
    primary_id: "D02JRK",
    names: ["Phenyl 1-(4-Butoxyphenyl)Propylcarbamate"],
  },
  {
    primary_id: "D02JRT",
    names: ["111In-Imciromab Pentetate"],
  },
  {
    primary_id: "D02JSW",
    names: ["PMID25726713-Compound-58"],
  },
  {
    primary_id: "D02JUJ",
    names: ["5-(6-Methoxynaphthalen-2-Yl)Pyridin-3-Ol", "CHEMBL500636", "ZINC40425010"],
  },
  {
    primary_id: "D02JUM",
    names: ["METIAPRIL"],
  },
  {
    primary_id: "D02JUP",
    names: [
      "5'-Deoxy-5'-(Methylthio)-Tubercidin",
      "5'-Methylthiotubercidin",
      "5'-DEOXY-5'-(METHYLTHIO)-TUBERCIDIN",
      "2-(4-AMINO-PYRROLO[2,3-D]PYRIMIDIN-7-YL)-5-METHYLSULFANYLMETHYL-TETRAHYDRO-FURAN-3,4-DIOL",
      "MTH",
      "2qtg",
      "7-(5-S-Methyl-5-Thio-Beta-D-Ribofuranosyl)-7H-Pyrrolo(2,3-D)Pyrimidin-4-Amine",
      "7-(5-S-Methyl-5-Thio-Beta-D-Ribofuranosyl)-7H-Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "61893-98-9",
      "1nc1",
      "AC1Q7DT3",
      "AC1L4MN1",
      "5'-(Methylsulfanyl)Tubercidin",
      "CHEMBL551561",
      "DB02933",
      "7-(5-S-Methyl-5-Thio-",
      "A-D-Ribofuranosyl)-7h-Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "7H-Pyrrolo(2,3-D)Pyrimidin-4-Amine,",
    ],
  },
  {
    primary_id: "D02JUT",
    names: [
      "Acivicin",
      "42228-92-2",
      "Antibiotic AT 125",
      "Acivicinum",
      "Acivicino",
      "Acivicine",
      "AT-125",
      "AT 125",
      "NSC-163501",
      "NSC 163501",
      "NSC163501",
      "UNII-O0X60K76I6",
      "ACIA",
      "U 42126",
      "CHEBI:74545",
      "O0X60K76I6",
      "U-42,126",
      "Acivicin [USAN:INN]",
      "(AlphaS,5S)-Alpha-Amino-3-Chloro-2-Isoxazoline-5-Acetic Acid",
      "Acivicinum [INN-Latin]",
      "Acivicine [INN-French]",
      "Acivicino [INN-Spanish]",
      "(Alpha-S,5S)-Alpha-Amino-3-Chloro-4,5-Dihydro-5-Isoxazoleacetic Acid",
      "C5H7ClN2O3",
      "(2S)-2-Amino-2-[(5S)-3-Chloro-4,5-Dihydro-1,2-Oxazol-5-Yl]acetic Acid",
      "U-42126",
    ],
  },
  {
    primary_id: "D02JUW",
    names: ["GUAIACOL"],
  },
  {
    primary_id: "D02JVA",
    names: ["C[Nle-Arg-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL204263", "BDBM50184365"],
  },
  {
    primary_id: "D02JVG",
    names: [
      "2-Amino-1-(4-Ethylthiophenyl)Propane",
      "CHEMBL193309",
      "4-Ethylthioamphetamine",
      "BDBM50164327",
      "AKOS013148908",
    ],
  },
  {
    primary_id: "D02JVI",
    names: ["1-Benzooxazol-2-Yl-6-Phenyl-Hexan-1-One", "CHEMBL366907"],
  },
  {
    primary_id: "D02JVX",
    names: ["Resorcinol Compound 7"],
  },
  {
    primary_id: "D02JWA",
    names: ["Flomoxef Sodium"],
  },
  {
    primary_id: "D02JWD",
    names: ["13-HODE", "Alpha-Artemisolic Acid", "13-Hydroxylinoleic Acid", "Coriolic Acid"],
  },
  {
    primary_id: "D02JWN",
    names: ["STNM-01"],
  },
  {
    primary_id: "D02JWY",
    names: ["SRI-224"],
  },
  {
    primary_id: "D02JXG",
    names: ["SB-265610"],
  },
  {
    primary_id: "D02JXK",
    names: ["N,N'-(1',11'-Undecydene)-Bis-(-)-Nor-MEP", "CHEMBL406885"],
  },
  {
    primary_id: "D02JXQ",
    names: ["7alpha,25-Dihydroxycholesterol", "7alpha-25-OHC"],
  },
  {
    primary_id: "D02JXT",
    names: ["Selective Rac1/1b GTPase Nucleotide Binding Inhibitors"],
  },
  {
    primary_id: "D02JYA",
    names: ["Raxibacumab"],
  },
  {
    primary_id: "D02JYT",
    names: ["GSK2654911A"],
  },
  {
    primary_id: "D02JYY",
    names: ["Indium In-111 Oxyquinoline"],
  },
  {
    primary_id: "D02JZJ",
    names: ["ETS-6218", "Antihistamine+antiviral (Fibromyalgia), E-Therapeutics"],
  },
  {
    primary_id: "D02KAF",
    names: ["GT-2203"],
  },
  {
    primary_id: "D02KAU",
    names: ["PF-03882845"],
  },
  {
    primary_id: "D02KAW",
    names: ["CDP-7657"],
  },
  {
    primary_id: "D02KBD",
    names: ["Mebutamate", "Dormate"],
  },
  {
    primary_id: "D02KBE",
    names: [
      "Ad-OC-HsvTK/Valacyclovir",
      "Ad-OC-HsvTK",
      "Gene Therapy (Prostate Cancer), Winship Cancer Institute",
      "Adenovirus Osteocalcin-Promoter-Driven HSV Thymidine Kinase, Winship Cancer Institute",
      "Ad-OC-HsvTK/Valacyclovir, Winship Cancer Institute",
    ],
  },
  {
    primary_id: "D02KBO",
    names: ["Ciprokiren"],
  },
  {
    primary_id: "D02KCV",
    names: ["Iothalamate"],
  },
  {
    primary_id: "D02KDA",
    names: ["Flavonoid Derivative 5"],
  },
  {
    primary_id: "D02KDH",
    names: ["2-(N''-Acetyl-Hydrazino)-Benzenesulfonamide"],
  },
  {
    primary_id: "D02KEL",
    names: ["AVO113"],
  },
  {
    primary_id: "D02KEY",
    names: ["SPP-676"],
  },
  {
    primary_id: "D02KFP",
    names: ["Invert Sugar"],
  },
  {
    primary_id: "D02KGR",
    names: ["THAPSIGARGIN"],
  },
  {
    primary_id: "D02KGZ",
    names: [
      "N-(2-Aminoethyl)-2-Oxo-2H-Chromene-3-Carboxamide",
      "3-Carboxamido Coumarin, 5",
      "CHEMBL472638",
      "SCHEMBL15892984",
      "BDBM29156",
      "AKOS005787848",
    ],
  },
  {
    primary_id: "D02KHF",
    names: ["2-Cyclohexylethynyl-Pyridine", "CHEMBL195965", "2-(Cyclohexylethynyl)Pyridine"],
  },
  {
    primary_id: "D02KIE",
    names: ["Cimetropium Bromide", "Alginor (TN)"],
  },
  {
    primary_id: "D02KII",
    names: ["Vaqta"],
  },
  {
    primary_id: "D02KIM",
    names: ["PMID21536438C36j"],
  },
  {
    primary_id: "D02KIS",
    names: [
      "(S)-3-Methyl-1,2,3,4-Tetrahydro-Isoquinoline",
      "(3S)-3-Methyl-1,2,3,4-Tetrahydroisoquinoline",
      "CHEMBL294079",
      "(S)-3-Methyl-1,2,3,4-Tetrahydroisoquinoline",
      "15547-43-0",
      "J-501273",
      "AC1OM1IZ",
      "SCHEMBL1690518",
      "ZINC19928408",
      "FCH855835",
      "BDBM50052884",
      "AKOS006286080",
      "AB39365",
      "AJ-75701",
      "KB-31985",
      "(3S)-1,2,3,4-Tetrahydro-3-Methyl-Isoquinoline",
      "Isoquinoline, 1,2,3,4-Tetrahydro-3-Methyl-, (3S)-",
    ],
  },
  {
    primary_id: "D02KIU",
    names: ["Etonogestrel"],
  },
  {
    primary_id: "D02KJI",
    names: [
      "3-Boronobenzoic Acid",
      "3-Carboxyphenylboronic Acid",
      "25487-66-5",
      "3-(Dihydroxyboranyl)Benzoic Acid",
      "3-Carboxybenzeneboronic Acid",
      "M-Carboxyphenylboronic Acid",
      "Benzoic Acid, 3-Borono-",
      "3-(Dihydroxyborane)Benzoic Acid",
      "3-Carboxyphenylboronicacid",
      "3-(DIHYDROXYBORYL)BENZOIC ACID",
      "3-Phenyl Ester Boronic Acid",
      "3-Carboxy-Phenyl-Boronic Acid",
      "(3-Carboxyphenyl)Boronic Acid",
      "CHEMBL421765",
      "MFCD00036833",
      "3-Carboxyphenylboronic Acid, 97%",
      "Zlchem 425",
      "PubChem1773",
      "3-Borono-Benzoic Acid",
      "ACMC-1CMDG",
      "3-Carboxyphenyboronic Acid",
      "AC1MC01N",
    ],
  },
  {
    primary_id: "D02KJX",
    names: ["Betain Anhydrous", "Cystadane (TN)"],
  },
  {
    primary_id: "D02KKA",
    names: ["MK-1775"],
  },
  {
    primary_id: "D02KKL",
    names: ["Hemi-Babim"],
  },
  {
    primary_id: "D02KKQ",
    names: [
      "BAM-1110",
      "(6aR,9R,10aR)-7-Methyl-9-(1,2,4-Triazol-1-Ylmethyl)-4,6,6a,7,8,9,10,10a-Octahydroindolo[4,3-Fg]quinolinemaleate",
    ],
  },
  {
    primary_id: "D02KKU",
    names: ["Oleanonic Acid", "3-Oxoolean-12-En-28-Oic Acid"],
  },
  {
    primary_id: "D02KLD",
    names: [
      "BVT-74316",
      "BVT-5182",
      "BVT-5182C",
      "5-HT6 Antagonists (Obesity), Biovitrum",
      "5-HT6 Antagonists (Obesity), Proximagen",
    ],
  },
  {
    primary_id: "D02KLE",
    names: [
      "P-536",
      "5'-O-[[[(2,3,4,6-Tetra-O-Benzoyl-Alpha-D-Glucopyranosyl)Oxy]carbonyl]sulfamate]uridine",
      '5\'-O-[[[[2",3",4",6"-Tetra-O-Benzoyl-Alpha-D-Glucopyranosyl)Oxy]carbonyl]amino]sulfonyl]uridine',
    ],
  },
  {
    primary_id: "D02KLU",
    names: ["Ribose-5-Phosphate"],
  },
  {
    primary_id: "D02KLW",
    names: [
      "DIZOCILPINE",
      "77086-21-6",
      "MK-801 (Dizocilpine)",
      "UNII-7PY8KH681I",
      "MK-801",
      "Lopac-M-108",
      "Lopac-M-107",
      "MK 801",
      "Prestwick0_000109",
      "(5S,10R)-5-Methyl-10,11-Dihydro-5H-5,10-Epiminodibenzo[a,d][7]annulene",
      "7PY8KH681I",
      "CHEMBL284237",
      "CHEBI:34725",
      "Dizocilpine [INN]",
      "Dizocilpinum [INN-Latin]",
      "Dizocilpina [INN-Spanish]",
      "Dizocilpinum",
      "Dizocilpina",
      "MK801",
      "MK-801(Dizocilpine)",
      "HSDB 7641",
      "(+)MK-801",
      "(+)-MK-801",
      "(+/-)-MK801",
      "Prestwick2_000109",
      "Prestwick3_000109",
      "Prestwick1_000109",
      "Biomol-NT_000210",
      "SCHEMBL34528",
      "Lopac0_000872",
      "BSPBio_000098",
      "SPBio_002037",
    ],
  },
  {
    primary_id: "D02KMG",
    names: ["Milveterol"],
  },
  {
    primary_id: "D02KMI",
    names: ["PMID27841036-Compound-33"],
  },
  {
    primary_id: "D02KMO",
    names: ["Pinacidil"],
  },
  {
    primary_id: "D02KNP",
    names: ["LP-403812"],
  },
  {
    primary_id: "D02KNW",
    names: ["PMID25656651-Compound-36a"],
  },
  {
    primary_id: "D02KOF",
    names: ["Lomefloxacin"],
  },
  {
    primary_id: "D02KOY",
    names: ["RotaTeq"],
  },
  {
    primary_id: "D02KPJ",
    names: ["UCART19"],
  },
  {
    primary_id: "D02KPK",
    names: ["PMID29865878-Compound-58"],
  },
  {
    primary_id: "D02KPV",
    names: ["Phorbol 12,13-Butyrate"],
  },
  {
    primary_id: "D02KQE",
    names: ["ON-09300"],
  },
  {
    primary_id: "D02KRI",
    names: ["Epristeride"],
  },
  {
    primary_id: "D02KRS",
    names: [
      "Amantadine",
      "Adamantamine",
      "Adamantanamine",
      "Adamantylamine",
      "Amant",
      "Amantadina",
      "Amantadinum",
      "Amantidine",
      "Aminoadamantane",
      "Endantadine",
      "Mantadine",
      "Symadine",
      "Wiregyt",
      "Amantadine Base",
      "BIA4304",
      "OR14310",
      "Amantadina [INN-Spanish]",
      "Amantadine (INN)",
      "Amantadine [INN:BAN]",
      "Amantadinum [INN-Latin]",
      "Gen-Amantadine",
      "Pk-Merz",
      "Symmetrel (TN)",
      "TCMDC-125869",
      "ADAMANTANE,1-AMINO",
      "Adamantan-1-Amine",
      "Adamantan-1-Ylamine",
      "Tricyclo[3.3.1.1^3,7]decan-1-Amine",
      "Tricyclo(3.3.1.13,7)Decan-1-Amine",
      "Tricyclo[3.3.1.1(3,7)]decan-1-Amine",
      "Tricyclo[3.3.1.1(3,7)]decan-1-Ylamine",
      "Tricyclo[3.3.1.1(3,7)]decane-1-Amine",
      "Tricyclo[3.3.1.1(Sup3,7)]decan-1-Amine",
      "Tricyclo(3.3.1.1(Sup 3,7))Decan-1-Amine",
      "Tricyclo(3.3.1.1(Sup 3.7))Decan-1-Amine",
      "Tricyclo[3.3.1.1~3,7~]decan-1-Amine",
      "Tricyclo(3.3.1.1(3,7))-Decan-1-Amine",
      "1-Adamantamine",
      "1-Adamantanamine",
      "1-Adamantanamine (8CI)",
      "1-Adamantylamine",
      "1-Aminoadamantane",
      "1-Aminotricyclo(3.3.1.1(Sup 3,7))Decane",
    ],
  },
  {
    primary_id: "D02KSN",
    names: ["PMID25719566C7"],
  },
  {
    primary_id: "D02KSP",
    names: ["Tislelizumab"],
  },
  {
    primary_id: "D02KTA",
    names: ["DER-45-EV"],
  },
  {
    primary_id: "D02KTG",
    names: ["CGEN-25068"],
  },
  {
    primary_id: "D02KUO",
    names: [
      "Cenegermin",
      "Cenegermin [INN]",
      "UNII-B6E7K36KT8",
      "B6E7K36KT8",
      "Human Beta-Nerve Growth Factor (Beta-Ngf)-(1-118)- Peptide (Non-Covalent Dimer) Produced In Escherichia Coli Nerve Growth Factor",
    ],
  },
  {
    primary_id: "D02KVB",
    names: ["AG 112"],
  },
  {
    primary_id: "D02KVR",
    names: ["LB42708"],
  },
  {
    primary_id: "D02KVT",
    names: ["TUSC2/FUS1 Gene Therapy"],
  },
  {
    primary_id: "D02KWX",
    names: ["Indole-Based Analog 11"],
  },
  {
    primary_id: "D02KWZ",
    names: ["PMID26666989-Compound-Figure9middle04"],
  },
  {
    primary_id: "D02KXE",
    names: [
      "Aom-0811",
      "5-Hydroxytryptamine Reuptake Inhibitor (Oral/Tablet, Premature Ejaculation), Hangzhou Adamerck",
    ],
  },
  {
    primary_id: "D02KXU",
    names: ["(+/-)-7-Methoxy-2-P-Tolylchroman-4-One", "CHEMBL602141", "SCHEMBL13394439"],
  },
  {
    primary_id: "D02KXW",
    names: ["HE3286"],
  },
  {
    primary_id: "D02KXX",
    names: [
      "1-(4-Chlorophenyl)-2-(Phenylsulfonyl)Ethanone",
      "38488-19-6",
      "CHEMBL238834",
      "1-(4-Chlorophenyl)-2-(Phenylsulfonyl)-1-Ethanone",
      "4-Chlorophenacylphenyl Sulfone",
      "AC1LBPW5",
      "SCHEMBL10931021",
      "ZINC58239",
      "CTK6G9147",
      "MolPort-002-876-864",
      "KZACHGLUJYSTGQ-UHFFFAOYSA-N",
      "BDBM50212531",
      "CCG-43075",
      "AKOS003310153",
      "MCULE-9299558448",
      "7H-330S",
      "KS-00001Z91",
      "ST50308734",
      "Ethanone, 1-(4-Chlorophenyl)-2-Phenylsulfonyl-",
      "4'-CHLORO-2(PHENYL SULFONYL)ACETOPHENONE",
      "2-(Benzenesulfonyl)-1-(4-Chlorophenyl)Ethanone",
      "AF-731/00081054",
    ],
  },
  {
    primary_id: "D02KYP",
    names: ["AC-101"],
  },
  {
    primary_id: "D02KYR",
    names: [
      "ALVAC HIV Vaccine Program",
      "ALVAC HIV Vaccine Program, Aventis Pasteur",
      "VCP-1433",
      "VCP-1521",
      "VCP-205",
      "VCP-300",
      "ALVAC HIV Vaccine Program, Sanofi-Aventis",
      "HIV (Canarypox) Vaccine, Aventis Pasteur",
      "VCP-1521, Aventis Pasteur",
      "HIV Vaccine (Canarypox Vector), Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D02KZD",
    names: ["ML375"],
  },
  {
    primary_id: "D02KZI",
    names: [
      "L-786392",
      "1B-Methyl-2-(Naphthosultamyl) Methyl-Carbapenems",
      "1B-Methyl-2-(Sulfonamido) Methyl-Carbapenems",
      "2-Substituted-Methyl-1B-Methyl-Carbapenems",
    ],
  },
  {
    primary_id: "D02LAD",
    names: ["PMID27551786-Compound-IV"],
  },
  {
    primary_id: "D02LAP",
    names: ["Oxymera"],
  },
  {
    primary_id: "D02LBK",
    names: ["ARRY-797", "CHEMBL1088750", "SCHEMBL222635", "ARRY-371797", "BDBM50314072"],
  },
  {
    primary_id: "D02LBZ",
    names: ["PMID26560530-Compound-14"],
  },
  {
    primary_id: "D02LCC",
    names: ["2-(4-Hydroxy-Phenyl)-7-Isopropyl-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D02LCH",
    names: ["GRL-7234"],
  },
  {
    primary_id: "D02LCI",
    names: ["TA-5493"],
  },
  {
    primary_id: "D02LCL",
    names: ["T-5224", "AKOS003047196", "T5224"],
  },
  {
    primary_id: "D02LCR",
    names: ["Tiapride"],
  },
  {
    primary_id: "D02LCT",
    names: [
      "ARTELINIC ACID",
      "Artelinate",
      "(3R,5aS,6R,8aS,9R,10S,12R,12aR)-4-(3,12-Epoxy-3,6,9-Trimethyl-Perhydropyrano[4,3-J]-1,2-Benzodioxepin-10-Yloxymethyl)Benzoic Acid",
    ],
  },
  {
    primary_id: "D02LDN",
    names: ["Magnesium Sulfate"],
  },
  {
    primary_id: "D02LDV",
    names: ["Thiamine Hydrochloride"],
  },
  {
    primary_id: "D02LDY",
    names: ["N-[4-(1,3-Benzodioxol-5-Yl)Phenyl]sulfamide", "CHEMBL242180", "SCHEMBL5535049"],
  },
  {
    primary_id: "D02LEO",
    names: ["Allergy Immunotherapy Tablet"],
  },
  {
    primary_id: "D02LFU",
    names: [
      "1-Benzenesulfonyl-3-Piperidin-3-Yl-1H-Indole",
      "CHEMBL371292",
      "Piperidinylindole, 7",
      "SCHEMBL5251020",
      "BDBM34148",
    ],
  },
  {
    primary_id: "D02LHQ",
    names: [
      "3-(2,4-Dihydroxyphenyl)Propionic Acid",
      "5631-68-5",
      "3-(2,4-Dihydroxyphenyl)Propanoic Acid",
      "Hydroumbellic Acid",
      "Benzenepropanoic Acid, 2,4-Dihydroxy-",
      "CHEMBL228155",
      "2,4-Dihydroxybenzenepropanoic Acid",
      "AI3-13072",
      "AC1L3WC8",
      "AC1Q5VW7",
      "NCIOpen2_000618",
      "SCHEMBL490491",
      "ACMC-1B046",
      "2,4-Dihydroxyhydrocinnamic Acid",
      "CTK5A4966",
      "DTXSID50204854",
      "MolPort-000-877-529",
      "HMCMTJPPXSGYJY-UHFFFAOYSA-N",
      "ZINC388556",
      "NSC71551",
      "KS-00000N9R",
      "NSC-71551",
      "BDBM50211056",
      "STL034241",
      "SBB068271",
      "BBL028276",
      "Benzenepropanoic Acid,2,4-Dihydroxy-",
    ],
  },
  {
    primary_id: "D02LHR",
    names: ["INDORAMIN"],
  },
  {
    primary_id: "D02LIR",
    names: ["PMID19854648C27"],
  },
  {
    primary_id: "D02LIY",
    names: ["Euro-Celtique 2"],
  },
  {
    primary_id: "D02LJQ",
    names: ["Boronic Acid Derivative 1"],
  },
  {
    primary_id: "D02LJR",
    names: ["Methenamine"],
  },
  {
    primary_id: "D02LJW",
    names: ["KX2-391"],
  },
  {
    primary_id: "D02LLU",
    names: ["2-Phenyl-6H-Pyrazolo[1,5-C]quinazolin-5-One"],
  },
  {
    primary_id: "D02LMJ",
    names: ["FGGFTGARKCARKC"],
  },
  {
    primary_id: "D02LML",
    names: ["N-Methyl-2,2,2-Triphenylacetamide"],
  },
  {
    primary_id: "D02LMR",
    names: ["Adva-27a", "Difluoro-Etoposide"],
  },
  {
    primary_id: "D02LMZ",
    names: ["ATPase", "56-65-5", "UNII-8L70Q75FXE"],
  },
  {
    primary_id: "D02LNB",
    names: ["PMID25482888-Compound-15"],
  },
  {
    primary_id: "D02LNU",
    names: ["CART22-65s Cells And HuCART19 Cells"],
  },
  {
    primary_id: "D02LOB",
    names: ["JIN-2013"],
  },
  {
    primary_id: "D02LPF",
    names: [
      "Altretamine",
      "Altretamina",
      "Altretaminum",
      "HEXAMETHYLMELAMINE",
      "HMM",
      "HTM",
      "HXM",
      "Hemel",
      "Hexalen",
      "Hexastat",
      "Hexinawas",
      "Altretamine Bellon Brand",
      "Altretamine Chiesi Brand",
      "Altretamine Wassermann Brand",
      "Bellon Brand Of Altretamine",
      "Chiesi Brand Of Altretamine",
      "MGI Pharma Brand Of Altretamine",
      "Rhone Poulenc Rorer Brand Of Altretamine",
      "Wassermann Brand Of Altretamine",
      "A 8723",
      "ENT 50852",
      "NC 195",
      "Altretamina [INN-Spanish]",
      "Altretaminum [INN-Latin]",
      "Hexalen (TN)",
      "Hexalen, Altretamine",
      "KB-913",
      "Rhone-Poulenc Rorer Brand Of Altretamine",
      "Altretamine (USP/INN)",
      "Altretamine [USAN:INN:BAN]",
      "No-S-Triazine",
      "N,N,N',N',N'',N''-Hexamethyl-1,3,5-Triazine-2,4,6-Triamine",
      "N~2~,N~2~,N~4~,N~4~,N~6~,N~6~-Hexamethyl-1,3,5-Triazine-2,4,6-Triamine",
      "2,4, 6-Tris(Dimethylamino)-1,3,5-Triazine",
      "2,4,6-Tris(Dimethylami",
      "2,4,6-Tris(Dimethylamino)-1,3,5-Triazine",
      "2,4,6-Tris(Dimethylamino-S-Triazine",
      "2-N,2-N,4-N,4-N,6-N,6-N-Hexamethyl-1,3,5-Triazine-2,4,6-Triamine",
    ],
  },
  {
    primary_id: "D02LPY",
    names: ["A-623"],
  },
  {
    primary_id: "D02LQA",
    names: ["TTH 03-001"],
  },
  {
    primary_id: "D02LQG",
    names: ["WIN-35065"],
  },
  {
    primary_id: "D02LRQ",
    names: [
      "Cycrimine",
      "Cicrimina",
      "Cycriminum",
      "Pagitane",
      "Cicrimina [INN-Spanish]",
      "Cycriminum [INN-Latin]",
      "Alpha-Cyclopentyl-Alpha-Phenyl-1-Piperidinepropanol",
      "(+-)-Cycrimine",
      "1-Phenyl-1-Cyclopentyl-3-Piperidino-1-Propanol",
      "1-Cyclopentyl-1-Phenyl-3-(Piperidin-1-Yl)Propan-1-Ol",
      "1-Cyclopentyl-1-Phenyl-3-Piperidin-1-Ylpropan-1-Ol",
    ],
  },
  {
    primary_id: "D02LSO",
    names: ["P2Y1 Antagonists"],
  },
  {
    primary_id: "D02LSR",
    names: ["SNK-882", "2-(2-Oxopyrrolidin-1-Yl)-N-(Perhydropyrrolizin-7a-Ylmethyl)Acetamide Hydrochloride"],
  },
  {
    primary_id: "D02LTL",
    names: ["Dermatan Sulfate"],
  },
  {
    primary_id: "D02LTS",
    names: ["NIBR-0213"],
  },
  {
    primary_id: "D02LTV",
    names: ["MR-20494"],
  },
  {
    primary_id: "D02LUF",
    names: ["(+)-(5S,8S,10S)-20-Methoxy-9,15-Ene-Puupehenol", "CHEMBL456949"],
  },
  {
    primary_id: "D02LUP",
    names: ["RN-1005"],
  },
  {
    primary_id: "D02LVZ",
    names: ["Darbepoetin Alfa", "Aranesp (TN)"],
  },
  {
    primary_id: "D02LWB",
    names: ["3-(1H-Inden-2-Yl)Pyridine", "Indene 1", "SCHEMBL4504698", "CHEMBL205782", "BDBM8888", "AC1O70H3"],
  },
  {
    primary_id: "D02LWF",
    names: ["GCCSHPACAGNNQHIC*", "CHEMBL437423"],
  },
  {
    primary_id: "D02LWJ",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 15"],
  },
  {
    primary_id: "D02LWR",
    names: ["Radium Ra 223 Dichloride"],
  },
  {
    primary_id: "D02LWU",
    names: ["Pralatrexate"],
  },
  {
    primary_id: "D02LWX",
    names: [
      "6-Hydroxy-2-Methyl-9H-Beta-Carbolin-2-Ium",
      "CHEMBL220905",
      "2-Methyl-Beta-Carbolinium-6-Ol",
      "CHEBI:59956",
      "2-Methyl-9H-Pyrido[3,4-B]indol-2-Ium-6-Ol",
    ],
  },
  {
    primary_id: "D02LXI",
    names: ["Ki16425"],
  },
  {
    primary_id: "D02LXJ",
    names: ["3-(2-Cinnamylphenyl)Acrylic Acid", "CHEMBL376282", "SCHEMBL585953"],
  },
  {
    primary_id: "D02LYH",
    names: ["PMI-004"],
  },
  {
    primary_id: "D02LZA",
    names: ["ISIS 103669"],
  },
  {
    primary_id: "D02LZB",
    names: [
      "Demecolcine",
      "Colcemid",
      "1,2,3,10-Tetramethoxy-7-(Methylamino)-6,7-Dihydro-5H-Benzo[a]heptalen-9-One",
      "Demecolcine,colcemid, N-Deacetyl-N-Methylcolchicine, N-Methyl-N-Desacetylcolchicine",
      "Demicolcine",
      "NSC3096",
      "1,2,3,10-Tetramethoxy-7-(Methylamino)-6,7-Dihydrobenzo[a]heptalen-9(5H)-One",
      "NCGC00166035-01",
      "AC1L1EKK",
      "MolMap_000011",
      "SCHEMBL8160",
      "AC1Q698E",
      "CHEMBL433632",
      "CTK8E8505",
      "MolPort-003-846-192",
      "6,7-Dihydro-1,2,3,10-Tetramethoxy-7-(Methylamino) Benzo (Alpha) Heptalen-9(5H)-One",
      "HMS3374M04",
      "HSCI1_000384",
      "BBL027927",
      "STL377913",
      "CD0013",
    ],
  },
  {
    primary_id: "D02LZL",
    names: [
      "4-(6-Cyclohexyl-Hex-3-Ynyl)-1H-Imidazole",
      "CHEMBL15000",
      "SCHEMBL7621912",
      "LATXTZVCBBIDBM-UHFFFAOYSA-N",
      "ZINC2001873",
      "PDSP2_000104",
      "BDBM50070211",
      "PDSP1_000104",
      "GT 2260",
      "GT-2260",
      "1-[1H-Imidazol-4-Yl]-6-Cyclohexyl-3-Hexyne",
    ],
  },
  {
    primary_id: "D02MAG",
    names: ["PMID26394986-Compound-56"],
  },
  {
    primary_id: "D02MAK",
    names: [
      "6-Acetyl-7-Hydroxy-2H-Chromen-2-One",
      "CHEMBL1288534",
      "6-Acetyl-7-Hydroxy-2H-1-Benzopyran-2-One",
      "6835-55-8",
      "AC1NWHPN",
      "6-Acetyl-7-Hydroxycoumarin",
      "2H-1-Benzopyran-2-One, 6-Acetyl-7-Hydroxy-",
      "SCHEMBL4640781",
      "6-Acetyl-7-Hydroxychromen-2-One",
      "ZINC3848612",
      "BDBM50332033",
    ],
  },
  {
    primary_id: "D02MBM",
    names: ["Mosquirix Malaria Recombinant Vaccine"],
  },
  {
    primary_id: "D02MBN",
    names: ["SD-6010"],
  },
  {
    primary_id: "D02MBO",
    names: [
      "EPICOAT Cancer Vaccine",
      "CVP Cancer Vaccine, Axis",
      "Chimeric Virus Particle Cancer Vaccine, Axis",
      "EPICOAT Cancer Vaccine, Axis",
      "MUC-1 Cancer Vaccine, Axis",
    ],
  },
  {
    primary_id: "D02MBV",
    names: ["[123I]AM251"],
  },
  {
    primary_id: "D02MBW",
    names: [
      "2-Methyl-3-(2-Aminothiazolo)Propanal",
      "(2S)-3-(2-Amino-1,3-Thiazol-4-Yl)-2-Methylpropanal",
      "AC1NRBOT",
      "DB03024",
    ],
  },
  {
    primary_id: "D02MDC",
    names: ["EMPA"],
  },
  {
    primary_id: "D02MDK",
    names: ["ISIS 2674"],
  },
  {
    primary_id: "D02MEX",
    names: ["PMID2153213C2c"],
  },
  {
    primary_id: "D02MFC",
    names: ["62Cu-ATSM"],
  },
  {
    primary_id: "D02MFK",
    names: ["Affinitak + Cisplatin"],
  },
  {
    primary_id: "D02MFQ",
    names: [
      "Azonan-(2Z)-Ylideneamine",
      "CHEMBL158641",
      "SCHEMBL8466372",
      "SCHEMBL2661739",
      "BDBM50049256",
      "AKOS006356817",
    ],
  },
  {
    primary_id: "D02MFX",
    names: ["C[homoPhe-Hca-Glu-Gly-Leu-Glu-Glu]-NH2", "CHEMBL410408"],
  },
  {
    primary_id: "D02MGH",
    names: ["Pneumococcal Vaccine"],
  },
  {
    primary_id: "D02MGJ",
    names: ["Benzoxazinone Derivative 2"],
  },
  {
    primary_id: "D02MHD",
    names: ["NPC-16570"],
  },
  {
    primary_id: "D02MHE",
    names: ["CVS-1123", "Corthrombin Oral"],
  },
  {
    primary_id: "D02MIH",
    names: [
      "PT-104",
      "149488-13-1",
      "Thiourea, N-(5-Bromo-2-Pyridinyl)-N'-(2-(2,6-Difluorophenyl)Ethyl)-",
      "Thiourea, N-(5-Bromo-2-Pyridinyl)-N'-[2-(2,6-Difluorophenyl)Ethyl]-",
      "PETT Analog 57",
      "PETT Deriv. 21",
      "AC1MHDJX",
      "LY300046HCl Analog 1",
      "CHEMBL88677",
      "BDBM1890",
      "SCHEMBL6905477",
      "DTXSID00164313",
      "ZINC3832005",
      "1-(2,6-Difluorophenethyl)-3-(5-Bromo-2-Pyridinyl)Thiourea",
      "1-(5-Bromo-2-Pyridyl)-3-[2-(2,6-Difluorophenyl)Ethyl]thiourea",
      "3-(5-Bromopyridin-2-Yl)-1-[2-(2,6-Difluorophenyl)Ethyl]thiourea",
      "N-(2-(2,6-Difluorophenyl)Ethyl)-N -(2-(5-Bromopyri",
    ],
  },
  {
    primary_id: "D02MIN",
    names: ["PD-138915"],
  },
  {
    primary_id: "D02MJE",
    names: ["NNC 11-1314"],
  },
  {
    primary_id: "D02MJN",
    names: [
      "2-Amino-6-(3-Cyano-Benzenesulfonyl)-Benzonitrile",
      "6-Amino-2-[(3-Cyanophenyl)Sulfonyl]benzenecarbonitrile",
      "AC1LA8F3",
      "CHEMBL299271",
      "BDBM1797",
      "CTK7C8628",
      "ZINC5933126",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3q",
      "2-Amino-6-(3-Cyanophenyl)Sulfonylbenzonitrile",
      "2-(3-Cyanophenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(3-Cyanophenyl)Sulfonyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D02MKP",
    names: ["AcPYY(22-36)"],
  },
  {
    primary_id: "D02MKW",
    names: [
      "3'-THIO-THYMIDINE-5'-PHOSPHATE",
      "DB08651",
      "3'-Thiothymidine 5'-(Dihydrogen Phosphate)",
      "3'-Mercapto-3'-Deoxythymidine 5'-Phosphoric Acid",
      "[(2R,3S,5R)-5-(5-Methyl-2,4-Dioxo-3H-Pyrimidin-1-Yl)-3-Sulfanyloxolan-2-Yl]methoxyphosphonic Acid",
    ],
  },
  {
    primary_id: "D02MLA",
    names: ["AXP-107-08"],
  },
  {
    primary_id: "D02MLI",
    names: ["NVP ACQ090"],
  },
  {
    primary_id: "D02MLO",
    names: ["WT1-Targeted Autologous Dendritic Cell Vaccine"],
  },
  {
    primary_id: "D02MLV",
    names: ["Onzetra Xsail"],
  },
  {
    primary_id: "D02MLW",
    names: [
      "Ibutilide",
      "Corvert",
      "Ibutilida",
      "Ibutilidum",
      "Butilide Fumarate",
      "IBUTILIDE FUMARATE",
      "Ibutilide Fumarate [USAN]",
      "U 70226E",
      "U82208E",
      "U82209E",
      "Corvert (TN)",
      "Ibutilida [INN-Spanish]",
      "Ibutilide (INN)",
      "Ibutilide [BAN:INN]",
      "Ibutilide [INN:BAN]",
      "Ibutilide Fumarate (USAN)",
      "Ibutilidum [INN-Latin]",
      "U-70226E",
      "U-82208E",
      "U-82209E",
      "Ibutilide, (+-)-Isomer",
      "N-[4-[4-[ethyl(Heptyl)Amino]-1-Hydroxybutyl]phenyl]methanesulfonamide",
      "Ibutilide, Fumarate Salt (2:1), (+-)-Isomer",
      "N-(4-(4-(Ethylheptylamino)-1-Hydroxybutyl)Phenyl)Methanesulfonamide",
      "N-(4-{4-[ethyl(Heptyl)Amino]-1-Hydroxybutyl}phenyl)Methanesulfonamide",
      "Methanesulfonamide, N-(4-(4-(Ethylheptylamino)-1-Hydroxybutyl)Phenyl)-, (E)-2-Butenedioate (2:1) (Salt)",
      "Methanesulfonamide, N-(4-(4-(Ethylheptylamino)-1-Hydroxybutyl)Phenyl)-, (+-)-, (E)-2-Butenedioate (2:1) (Salt)",
      "Methanesulfonamide, N-(4-(4-(Ethylheptylamino)-1-Hydroxybutyl)Phenyl)-, (+-)-,(E)-2-Butenedioate (2:1) (Salt)",
      "(+-)-4'-(4-(Ethylheptylamino)-1-Hydroxybutyl)Methanesulfoanilide (E)-2-Butenedioate (2:1)",
      "(+-)-4'-(4-(Ethylheptylamino)-1-Hydroxybutyl)Methanesulfonanilide Fumarate (2:1) (Salt)",
      "(+-)-N-(4-(4-(Ethylheptylamino)-1-Hydroxybutyl)Phenyl)Methanesulfonamide (E)-Butenedioate",
    ],
  },
  {
    primary_id: "D02MME",
    names: ["N-Methyl-N-(Methylbenzyl)Formamide"],
  },
  {
    primary_id: "D02MMF",
    names: ["FP-1097"],
  },
  {
    primary_id: "D02MMG",
    names: ["Pyrimidine Derivative 3"],
  },
  {
    primary_id: "D02MMP",
    names: ["VX-500"],
  },
  {
    primary_id: "D02MMS",
    names: ["2-N-(N-Propyl)Amino-1-(4-Methylthiophenyl)Butane", "CHEMBL1077898"],
  },
  {
    primary_id: "D02MMY",
    names: ["2,3-Dimethoxybenzo[i]phenanthridines"],
  },
  {
    primary_id: "D02MNM",
    names: ["PMID23916253C17"],
  },
  {
    primary_id: "D02MNR",
    names: ["S-888711", "Butyzamide", "Thrombopoietin (TPO) Mimetic (Thrombocytopenia), Shionogi"],
  },
  {
    primary_id: "D02MNY",
    names: [
      "ON-01135",
      "Amerliorex",
      "CDK1 Inhibitors, Onconova",
      "Novonex Analogs, Onconova",
      "ON-01060",
      "ON-01130",
      "ON-01220",
      "ON-01250",
      "ON-01300",
      "ON-01330",
      "ON-01370",
      "ON-01500",
      "ON-01560",
      "ON-01590",
      "ON-01620",
      "Cell Cycle Checkpoint Activators (Cytoprotection), Onconova",
      "ON-01060 Derivatives, Onconova",
      "ON-01500 Series, Onconova",
      "ON-01910 Analogs, Onconova",
      "Styryl Benzyl Sulfones (Cell Cycle Checkpoint Activators), Onconova",
    ],
  },
  {
    primary_id: "D02MOV",
    names: [
      "6-Bromo-N-(3-Fluorophenyl)Quinazolin-4-Amine",
      "CHEMBL1079375",
      "ZINC32540960",
      "BDBM50311885",
      "AKOS002366762",
    ],
  },
  {
    primary_id: "D02MPG",
    names: [
      "2-(4-Chlorophenyl)-6-Morpholino-4H-Pyran-4-One",
      "CHEMBL105612",
      "SCHEMBL3535601",
      "BDBM50132355",
      "2-Morpholino-6-(4-Chlorophenyl)-4H-Pyran-4-One",
    ],
  },
  {
    primary_id: "D02MPP",
    names: [
      "3-Methoxybenzamide",
      "5813-86-5",
      "M-Methoxybenzamide",
      "M-Anisamide",
      "3-Methoxy-Benzamide",
      "Benzamide, 3-Methoxy-",
      "UNII-M8502TLK98",
      "EINECS 227-379-7",
      "NSC 28589",
      "NSC 209527",
      "BRN 2206857",
      "CHEMBL123978",
      "VKPLPDIMEREJJF-UHFFFAOYSA-N",
      "M8502TLK98",
      "3MB",
      "3pax",
      "5-Methoxybenzamide",
      "3-Methoxy-Benzamid",
      "ACMC-1ASRE",
      "AC1Q5DMC",
      "M-METHOXY BENZAMIDE",
      "Bmse000775",
      "3-Methoxybenzamide, 97%",
      "Oprea1_695428",
      "MLS001066418",
      "4-10-00-00326 (Beilstein Handbook Reference)",
      "Cid_98487",
      "SCHEMBL283787",
      "AC1L407F",
      "KS-00000VVU",
      "CTK1H2082",
      "VKPLPDIMEREJJF-UHFFFAOYSA-",
      "DTXSID00206848",
    ],
  },
  {
    primary_id: "D02MPT",
    names: ["MAGE-A3 Immunotherapeutic"],
  },
  {
    primary_id: "D02MPZ",
    names: ["C[Nle-His-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL446185"],
  },
  {
    primary_id: "D02MQL",
    names: ["RWJ-61907"],
  },
  {
    primary_id: "D02MQM",
    names: [
      "6-(2-Bromo-Acetylamino)-Hexanoic Acid Phenylamide",
      "CHEMBL344920",
      "651767-99-6",
      "SCHEMBL3736839",
      "CTK1J8444",
      "DTXSID50432973",
      "HWYLREOMBVUGJQ-UHFFFAOYSA-N",
      "BDBM50222416",
      "ZINC13587789",
      "AKOS030603042",
      "N-Phenyl-6-(Bromoacetylamino)Hexanamide",
      "Hexanamide, 6-[(Bromoacetyl)Amino]-N-Phenyl-",
    ],
  },
  {
    primary_id: "D02MQT",
    names: ["16-(4-Dimethylamino-Benzylidene)-Estrone"],
  },
  {
    primary_id: "D02MRN",
    names: [
      "Golotimod",
      "229305-39-9",
      "SCV-07",
      "Gamma-D-Glu-L-Trp",
      "SCV07",
      "Gamma-D-Glutamyl-L-Tryptophan",
      "SCV 07",
      "UNII-637C487Y09",
      "637C487Y09",
      "(R)-2-Amino-5-(((S)-1-Carboxy-2-(1H-Indol-3-Yl)Ethyl)Amino)-5-Oxopentanoic Acid",
      "Golotimod [USAN:INN]",
      "(2R)-2-Amino-5-[[(1S)-1-Carboxy-2-(1H-Indol-3-Yl)Ethyl]amino]-5-Oxopentanoic Acid",
      "(2R)-2-Amino-5-(((1S)-1-Carboxy-2-(1H-Indol-3-Yl)Ethyl)Amino)-5-Oxopentanoic Acid",
      "Golotimod (USAN/INN)",
      "GAMMA-D-GLU-TRP-OH",
      "H-D-Glu(L-Trp-OH)-OH",
      "SCHEMBL727944",
      "(Gamma-Glutamyl-L-Tryptophan)",
      "CHEMBL2103812",
      "Golotimod (Oral)",
      "Golotimod (Oral), SciClone/Verta",
      "SCV-07 (Oral, Tuberculosis), SciClone/Verta",
    ],
  },
  {
    primary_id: "D02MRX",
    names: ["ONO-4059"],
  },
  {
    primary_id: "D02MSG",
    names: ["AcVx-25 Cream", "Cream", "AcVx-25 Cream (Acne)"],
  },
  {
    primary_id: "D02MTK",
    names: ["AT-008"],
  },
  {
    primary_id: "D02MTQ",
    names: ["PMID25522065-Compound-40"],
  },
  {
    primary_id: "D02MUA",
    names: ["PF-06439535"],
  },
  {
    primary_id: "D02MUO",
    names: [
      "4-CHLORO-3',3''-DIBROMOPHENOL-1,8-NAPHTHALEIN",
      "3,3-BIS(3-BROMO-4-HYDROXYPHENYL)-7-CHLORO-1H,3H-BENZO[DE]ISOCHROMEN-1-ONE",
      "BDBM18753",
      "DB04696",
      "4-CHLORO-3',3\"-DIBROMOPHENOL-1,8-NAPHTHALEIN",
      "4,4-Bis(3-Bromo-4-Hydroxyphenyl)-10-Chloro-3-Oxatricyclo[7.3.1.0;{5,13}]trideca-1(12),5(13),6,8,10-Pentaen-2-One",
    ],
  },
  {
    primary_id: "D02MVA",
    names: ["NI-0701", "Alpha-RANTES", "RANTES Ligand Inhibitor (Iv, Autoimmune/Inflammatory Disease), NovImmune"],
  },
  {
    primary_id: "D02MVG",
    names: ["AMG-548"],
  },
  {
    primary_id: "D02MVR",
    names: [
      "3-(1,2,3,6-Tetrahydro-Pyridin-4-Yl)-1H-Indole",
      "3-(1,2,3,6-Tetrahydropyridin-4-Yl)-1H-Indole",
      "65347-55-9",
      "38620-69-8",
      "MLS000093715",
      "SMR000029333",
      "CHEMBL27811",
      "3-(1,2,3,6-Tetrahydro-4-Pyridinyl)-1H-Indole",
      "3-(4-1,2,5,6-Tetrahydropyridyl)Indole",
      "THPindole",
      "ChemDiv2_002931",
      "AC1Q1GX4",
      "SCHEMBL653742",
      "AC1MC402",
      "CTK5C2597",
      "Cid_2761023",
      "BDBM31023",
      "DTXSID20375484",
      "CIRSPTXGPFAXRE-UHFFFAOYSA-N",
      "MolPort-000-159-541",
      "HMS1377F05",
      "ZINC154399",
      "STK801496",
      "2202AE",
      "SBB092334",
      "BBL010213",
      "MFCD03695471",
      "AKOS000505683",
      "FS-1743",
      "NE12559",
      "CCG-139909",
    ],
  },
  {
    primary_id: "D02MXT",
    names: ["MK-2894"],
  },
  {
    primary_id: "D02MYI",
    names: ["CAR-T Cells Targeting PSCA"],
  },
  {
    primary_id: "D02MYV",
    names: ["IAA-94"],
  },
  {
    primary_id: "D02MYZ",
    names: ["ISIS 25237"],
  },
  {
    primary_id: "D02MZD",
    names: ["AMG 876"],
  },
  {
    primary_id: "D02MZN",
    names: ["N-N-Diethyl Estradiol-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D02MZY",
    names: [
      "Cefpiramide",
      "Cefpiramido",
      "Cefpiramidum",
      "WY 44635",
      "Cefpiramide [USAN:INN]",
      "Cefpiramido [INN-Spanish]",
      "Cefpiramidum [INN-Latin]",
      "WY44,635",
      "Cefpiramide (USP/INN)",
      "(6R)-7-[[(2R)-2-(4-Hydroxyphenyl)-2-[(6-Methyl-4-Oxo-1H-Pyridine-3-Carbonyl)Amino]acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-((R)-2-(4-Hydroxy-6-Methylnicotinamido)-2-(P-Hydroxyphe-Nyl)Acetamido)-3-(((1-Methyl-1H-Tetrazol-5-Yl)Thio)Methyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2R)-2-(4-Hydroxyphenyl)-2-[(6-Methyl-4-Oxo-1H-Pyridine-3-Carbonyl)Amino]acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[2-(4-Hydroxyphenyl)-2-[(6-Methyl-4-Oxo-1H-Pyridine-3-Carbonyl)Amino]acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-{[(4-Hydroxy-6-Methylpyridin-3-Yl)Carbonyl]amino}-2-(4-Hydroxyphenyl)Acetyl]amino}-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6r)-7-{[(2r)-2-(4-Hydroxyphenyl)-2-{[(6-Methyl-4-Oxo-1,4-Dihydropyridin-3-Yl)Carbonyl]amino}acetyl]amino}-3-{[(1-Methyl-1h-Tetrazol-5-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-[[2-(4-Hydroxyphenyl)-2-[(6-Methyl-4-Oxo-1H-Pyridine-3-Carbonyl)Amino]acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-[(2R)-2-{[(4-Hydroxy-6-Methylpyridin-3-Yl)Carbonyl]amino}-2-(4-Hydroxyphenyl)Acetamido]-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D02MZZ",
    names: [
      "2-Amino-6-(Toluene-2-Sulfonyl)-Benzonitrile",
      "AC1LA8DU",
      "CHEMBL54444",
      "BDBM1785",
      "CTK7C6579",
      "ZINC5931403",
      "2-Amino-6-(O-Tolylsulfonyl)Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3e",
      "2-(2-Methylphenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(2-Methylphenyl)Sulfonylbenzonitrile",
      "2-Amino-6-[(2-Methylbenzene)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D02NAF",
    names: ["Cyclo(-L-Am7(S2Py)-D-A1in-L-Ala-D-Pro-)", "CHEMBL390991"],
  },
  {
    primary_id: "D02NAG",
    names: [
      "3-[5-Chloro-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 7a",
      "CHEMBL383807",
      "SCHEMBL4220789",
      "AC1O704H",
      "SCHEMBL4220782",
      "BDBM8588",
      "ZINC13610774",
      "3-[(E)-(5-Chloro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D02NAW",
    names: [
      "N-(7'-(2-CHLOROPHENYL)-6'-(4-CHLOROPHENYL)-3',4'-DIHYDROSPIRO[CYCLOHEXANE-1,2'-PYRANO[2,3-B]PYRIDINE]-4'-YL)-2-HYDROXY-2-METHYLPROPANAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1085566",
      "SCHEMBL1404756",
      "BDBM50320185",
      "N-(7''-(2-CHLOROPHENYL)-6''-(4-CHLOROPHENYL)-3'',4''-DIHYDROSPIRO[CYCLOHEXANE-1,2''-PYRANO[2,3-B]PYRIDINE]-4''-YL)-2-HYDROXY-2-METHYLPROPANAMIDE (ENANTIOMERIC MIX)",
    ],
  },
  {
    primary_id: "D02NBB",
    names: [
      "9-(3-Nitrobenzyl)-6-(Furan-2-Yl)-9H-Purin-2-Amine",
      "CHEMBL260790",
      "SCHEMBL3268445",
      "LYSMIARHRJVSBZ-UHFFFAOYSA-N",
      "BDBM50377515",
    ],
  },
  {
    primary_id: "D02NBY",
    names: ["LACTIN-V", "CTV-05"],
  },
  {
    primary_id: "D02NCO",
    names: ["T-98475"],
  },
  {
    primary_id: "D02NCW",
    names: ["Olean-12-En-3beta,15alpha-Diol", "CHEMBL491188", "Oleana-12-Ene-3beta,15alpha-Diol"],
  },
  {
    primary_id: "D02NCY",
    names: [
      "6-(4-Chloro-Phenylamino)-1H-Pyrimidine-2,4-Dione",
      "CHEMBL51694",
      "STK374802",
      "21333-02-8",
      "6-[(4-Chlorophenyl)Amino]pyrimidine-2,4(1H,3H)-Dione",
      "NSC210499",
      "AC1L7DC5",
      "Oprea1_449957",
      "Oprea1_000118",
      "SCHEMBL5309531",
      "2,4(1H,3H)-Pyrimidinedione, 6-[(4-Chlorophenyl)Amino]-",
      "DTXSID10308968",
      "MolPort-002-117-140",
      "ZINC169555",
      "BDBM50028338",
      "AKOS001620570",
      "NSC-210499",
      "MCULE-2076182094",
      "BAS 00450419",
      "ST50876537",
      "6-(4-Chloroanilino)-1H-Pyrimidine-2,4-Dione",
      "AB00091363-01",
      "6-(4-Chlorophenylamino)Pyrimidine-2,4(1H,3H)-Dione",
    ],
  },
  {
    primary_id: "D02NDC",
    names: ["GSK-2202083A"],
  },
  {
    primary_id: "D02NDP",
    names: ["PMID26651364-Compound-7a"],
  },
  {
    primary_id: "D02NDV",
    names: [
      "Levovist",
      "Echoscope",
      "SH-508",
      "TA-508",
      "Galactose + Palmitic Acid Microbubbles, Bayer Schering",
      "Galactose + Palmitic Acid Microbubbles, Schering AG",
      "SH-U-508A",
      "SH/TA-508",
      "ZK-44012 + ZK-1190 + ZK-56565",
    ],
  },
  {
    primary_id: "D02NED",
    names: [
      "NSC-637991",
      "CCRIS 7538",
      "5-((2-(Dimethylamino)Ethyl)Amino)-8-Hydroxy-1-Methyl-6H-Imidazo(4,5,1-De)Acridin-6-One",
      "CHEMBL124373",
      "138154-38-8",
      "AC1L4CDI",
      "DTXSID50160507",
      "ZINC5116458",
      "BDBM50008352",
      "NCI60_012640",
      "LS-189185",
      "5-(2-(Dimethylamino)Ethylamino)-8-Hydroxy-1-Methyl-6H-Imidazo[4,5,1-De]acridin-6-One",
    ],
  },
  {
    primary_id: "D02NEF",
    names: ["Type II Secretion Inhibitors"],
  },
  {
    primary_id: "D02NEH",
    names: ["Ibutamoren"],
  },
  {
    primary_id: "D02NEY",
    names: ["ISIS 19213"],
  },
  {
    primary_id: "D02NEZ",
    names: ["IMX-942"],
  },
  {
    primary_id: "D02NFA",
    names: [
      "A-68828",
      "A 68828",
      "L-Cysteinamide, L-Arginyl-L-Cysteinyl-3-Cyclohexyl-L-Alanylglycylglycyl-L-Arginyl-L-Isoleucyl-L-Alpha-Aspartyl-L-Arginyl-L-Isoleucyl-L-Phenylalanyl-L-Arginyl-, Cyclic(2-13)-Disulfide",
    ],
  },
  {
    primary_id: "D02NFE",
    names: ["Anti-CD19 CART Cells"],
  },
  {
    primary_id: "D02NGR",
    names: ["MK-499"],
  },
  {
    primary_id: "D02NGU",
    names: [
      "H-8",
      "84478-11-5",
      "Protein Kinase Inhibitor H-8",
      "H-8 Protein Kinase Inhibitor",
      "N-(2-(Methylamino)Ethyl)-5-Isoquinolinesulfonamide",
      "N-[2-(METHYLAMINO)ETHYL]-5-ISOQUINOLINESULFONAMIDE",
      "N-[2-(Methylamino)Ethyl]isoquinoline-5-Sulfonamide",
      "(5-Isoquinolylsulfonyl)[2-(Methylamino)Ethyl]amine",
      "Lopac-M-9656",
      "CHEMBL148333",
      "CHEBI:43561",
      "H8",
      "N-(2-Methylaminoethyl)Isoquinoline-5-Sulfonamide",
      "(5-Isoquinolylsulfonyl)(2-(Methylamino)Ethyl)Amine",
      "5-Isoquinolinesulfonamide, N-(2-(Methylamino)Ethyl)-",
      "N-(2-(Methylamino)Ethyl)Isoquinoline- 5-S",
    ],
  },
  {
    primary_id: "D02NHD",
    names: ["Ragaglitazar"],
  },
  {
    primary_id: "D02NHS",
    names: [
      "L-375378",
      "CHEMBL19080",
      "L-37378",
      "199294-70-7",
      "AC1L4BPU",
      "DTXSID80276443",
      "ZINC1487441",
      "BDBM50067797",
      "N-[(6-Amino-2-Methyl-3-Pyridinyl)Methyl]-2-[6-Methyl-2-Oxo-3-(2-Phenylethylamino)-1-Pyrazinyl]acetamide",
      "A814081",
      "N-(6-Amino-2-Methyl-Pyridin-3-Ylmethyl)-2-(6-Methyl-2-Oxo-3-Phenethylamino-2H-Pyrazin-1-Yl)-Acetamide",
      "N-[(6-Azanyl-2-Methyl-Pyridin-3-Yl)Methyl]-2-[6-Methyl-2-Oxidanylidene-3-(2-Phenylethylamino)Pyrazin-1-Yl]ethanamide",
    ],
  },
  {
    primary_id: "D02NHW",
    names: ["GSK-264220A"],
  },
  {
    primary_id: "D02NHY",
    names: ["PLADD"],
  },
  {
    primary_id: "D02NHZ",
    names: ["7-(4-Methoxyphenyl)-2-Methylquinoline", "CHEMBL396134"],
  },
  {
    primary_id: "D02NIP",
    names: [
      "ITASETRON",
      "DAU6215CL",
      "DAU-6215",
      "Itasetron < Rec INN",
      "U-98079A",
      "N-(Endo-8-Methyl-8-Azabicyclo[3.2.1]oct-3-Yl)-2-Oxo-2,3-Dihydro-1H-Benzimidazole-1-Carboxamide",
      "2-Oxo-N-(1alphaH,5alphaH-Tropan-3alpha-Yl)-1-Benzimidazoline-1-Carboxamide",
    ],
  },
  {
    primary_id: "D02NJA",
    names: [
      "Fomepizole",
      "Antizol",
      "Fomepizol",
      "Fomepizolum",
      "Orphan Brand Of Fomepizole",
      "Paladin Brand Of Fomepizole",
      "M0774",
      "Antizol (TN)",
      "Fomepizol [INN-Spanish]",
      "Fomepizole [USAN:INN]",
      "Fomepizolum [INN-Latin]",
      "S14-0570",
      "Fomepizole (USAN/INN)",
      "4-Methyl-1H-Pyrazole",
      "4-Methylpyrazol",
      "4-Methylpyrazole",
      "4-Methylpyrazole Monohydrochloride",
    ],
  },
  {
    primary_id: "D02NJE",
    names: ["PEK Fusion Protein Vaccine"],
  },
  {
    primary_id: "D02NJK",
    names: ["MDR-03030"],
  },
  {
    primary_id: "D02NJV",
    names: ["Des-AA1,2,5,12,13-[D-Trp8,IAmp9]SRIF", "CHEMBL265836"],
  },
  {
    primary_id: "D02NJY",
    names: ["Jak2 Tyrosine Inhibitors"],
  },
  {
    primary_id: "D02NKD",
    names: [
      "8-(3-Nitro-Phenyl)-6-Phenyl-[1,7]naphthyridine",
      "CHEMBL161465",
      "6-Phenyl-8-(3-Nitrophenyl)-1,7-Naphthyridine",
      "SCHEMBL7015917",
      "SRVUGPYWYLBZNI-UHFFFAOYSA-N",
      "ZINC13805266",
    ],
  },
  {
    primary_id: "D02NKV",
    names: [
      "SB-218655",
      "1bmk",
      "4-[1-(Cyclopropylmethyl)-4-(4-Fluorophenyl)-1h-Imidazol-5-Yl]pyrimidin-2-Amine",
      "4-(FLUOROPHENYL)-1-CYCLOPROPYLMETHYL-5-(2-AMINO-4-PYRIMIDINYL)IMIDAZOLE",
      "165806-51-9",
      "SB 218655",
      "C14316",
      "4-[3-(Cyclopropylmethyl)-5-(4-Fluorophenyl)Imidazol-4-Yl]pyrimidin-2-Amine",
      "SB5",
      "AC1L1JRC",
      "AC1Q4ON9",
      "SCHEMBL4323457",
      "CHEMBL590753",
      "BDBM15238",
      "CTK4D2173",
      "DTXSID50274456",
      "Sb218655",
      "AKOS030547879",
    ],
  },
  {
    primary_id: "D02NLI",
    names: ["PD-85639"],
  },
  {
    primary_id: "D02NMG",
    names: ["SLV-332", "AC1MHEA6", "Isoxyl &amp"],
  },
  {
    primary_id: "D02NMJ",
    names: ["Meningococcal B Vaccine"],
  },
  {
    primary_id: "D02NMK",
    names: ["FVPTDVGAFAF", "CHEMBL406459"],
  },
  {
    primary_id: "D02NMN",
    names: ["PMID19433355C11s"],
  },
  {
    primary_id: "D02NMO",
    names: ["FR-79620", "3-(5-Methyl-2-Phenyl-1H-Imidazol-4-Yl)Pyridine"],
  },
  {
    primary_id: "D02NMY",
    names: [
      "6-Bromo-2-Piperazin-1-Yl-Quinoline",
      "124782-95-2",
      "6-Bromo-2-(Piperazin-1-Yl)Quinoline",
      "6-Bromoquipazine",
      "6-Bromo-2-Piperazin-1-Ylquinoline",
      "Quinoline, 6-Bromo-2-(1-Piperazinyl)-",
      "CHEMBL39164",
      "Quinoline,6-Bromo-2-(1-Piperazinyl)-",
      "ACMC-20mr6z",
      "AC1L2X2K",
      "SCHEMBL5912936",
      "CTK4B4077",
      "DTXSID90154485",
      "MKIHFWMBAKJYJL-UHFFFAOYSA-N",
      "ZINC2527449",
      "BDBM50090213",
      "6-Bromo-2-(1-Piperazinyl)Quinoline",
      "3453AJ",
      "AKOS022401899",
      "AB19846",
      "6-Bromanyl-2-Piperazin-1-Yl-Quinoline",
      "AJ-83658",
      "DB-062320",
      "AX8027344",
      "FT-0713002",
      "Z-6757",
      "A805296",
    ],
  },
  {
    primary_id: "D02NNN",
    names: ["CBP-501"],
  },
  {
    primary_id: "D02NNS",
    names: [
      "SNAP-8719",
      "CHEMBL98241",
      "SCHEMBL6753278",
      "WCWNYVYNQAPOOX-OAHLLOKOSA-N",
      "BDBM50122803",
      "8-{(1R)-2-[4-(2,4,5-Trifluorophenyl)Piperazin-1-Yl]-1-Methylethyl}-8-Azaspiro[4.5]decane-7,9-Dione",
      "8-{1-Methyl-2-[4-(2,4,5-Trifluoro-Phenyl)-Piperazin-1-Yl]-Ethyl}-8-Aza-Spiro[4.5]decane-7,9-Dione",
      "8-{(R)-1-Methyl-2-[4-(2,4,5-Trifluoro-Phenyl)-Piperazin-1-Yl]-Ethyl}-8-Aza-Spiro[4.5]decane-7,9-Dione",
    ],
  },
  {
    primary_id: "D02NNT",
    names: ["PMID21493067C1d"],
  },
  {
    primary_id: "D02NNU",
    names: ["PMID17929793C23h"],
  },
  {
    primary_id: "D02NNV",
    names: [
      "VX-745",
      "5-(2,6-Dichlorophenyl)-2-((2,4-Difluorophenyl)Thio)-6H-Pyrimido[1,6-B]pyridazin-6-One",
      "209410-46-8",
      "Neflamapimod",
      "VX 745",
      "VX745",
      "VRT-031745",
      "UNII-TYL52QM320",
      "TYL52QM320",
      "CHEBI:90528",
      "5-(2,6-Dichlorophenyl)-2-(2,4-Difluorophenylthio)-6H-Pyrimido[1,6-B]pyridazin-6-One",
      "5-(2,6-Dichlorophenyl)-2-(2,4-Difluorophenyl)Sulfanylpyrimido[1,6-B]pyridazin-6-One",
      "Neflamapimod (USAN)",
      "Neflamapimod [USAN]",
      "AK-44905",
      "C19H9Cl2F2N3OS",
      "5-(2,6-Dichlorophenyl)-2-[(2,4-Difluorophenyl)Sulfanyl]-6h-Pyrimido[1,6-B]pyridazin-6-One",
      "VX745, VX-745",
      "5-(2,6-Dichlorophenyl)-2-((2,4-Difluorophenyl)Thio)-6H-Pyrimido(1,6-B)Pyridazin-6-One",
      "Vertex 745 (VX745)",
    ],
  },
  {
    primary_id: "D02NNZ",
    names: ["1,2,4-Triazole Derivative 1"],
  },
  {
    primary_id: "D02NOC",
    names: ["Cotara", "Cotara (TN)"],
  },
  {
    primary_id: "D02NPB",
    names: ["Isoindoline Derivative 5"],
  },
  {
    primary_id: "D02NQB",
    names: ["LBQ657"],
  },
  {
    primary_id: "D02NQK",
    names: ["4-(N-Oxide-2-Pyridylthio)Pyridine-3-Sulfonamide", "CHEMBL1165118"],
  },
  {
    primary_id: "D02NRD",
    names: [
      "T863",
      "701232-20-4",
      "DGAT-3",
      "DGAT-1 Inhibitor",
      "UNII-OF68Z8HK6V",
      "OF68Z8HK6V",
      "Trans-4-[4-(4-Amino-7,7-Dimethyl-7H-Pyrimido[4,5-B][1,4]oxazin-6-Yl)Phenyl]cyclohexaneacetic Acid",
      "T-863",
      "2-(4-(4-(4-Amino-7,7-Dimethyl-7H-Pyrimido[4,5-B][1,4]oxazin-6-Yl)Phenyl)Cyclohexyl)Acetic Acid",
      "Trans-4-(4-(4-Amino-7,7-Dimethyl-7h-Pyrimido(4,5-B)(1,4)Oxazin-6-Yl)Phenyl)Cyclohexaneacetic Acid",
      "Cyclohexaneacetic Acid, 4-[4-(4-Amino-7,7-Dimethyl-7H-Pyrimido[4,5-B][1,4]oxazin-6-Yl)Phenyl]-, Trans-",
      "Cyclohexaneacetic Acid, 4-(4-(4-Amino-7,7-Di",
    ],
  },
  {
    primary_id: "D02NRI",
    names: ["Eryvarin D"],
  },
  {
    primary_id: "D02NRP",
    names: ["MRS2927"],
  },
  {
    primary_id: "D02NRY",
    names: [
      "(4-Bromo-1H-Pyrazol-1-Yl)(O-Tolyl)Methanone",
      "(4-Bromo-1H-Pyrazol-1-Yl)(2-Methylphenyl)Methanone",
      "AC1LDSC9",
      "N-Benzoylpyrazole Deriv., 23",
      "CHEMBL245163",
      "ZINC37182",
      "BDBM23715",
      "MolPort-002-118-506",
      "STK059624",
      "AKOS001747311",
      "4-Bromopyrazolyl 2-Methylphenyl Ketone",
      "MCULE-5811997287",
      "ST033218",
      "(4-Bromopyrazol-1-Yl)-(2-Methylphenyl)Methanone",
      "SR-01000524307",
      "SR-01000524307-1",
      "4-Bromo-1-[(2-Methylphenyl)Carbonyl]-1H-Pyrazole",
    ],
  },
  {
    primary_id: "D02NSF",
    names: ["Oxymorphone"],
  },
  {
    primary_id: "D02NSS",
    names: ["CART-22 Cells"],
  },
  {
    primary_id: "D02NSY",
    names: ["Banyu Compound-24", "C-24"],
  },
  {
    primary_id: "D02NTO",
    names: [
      "Tolnaftate",
      "Aftate",
      "Breezee",
      "Chinofungin",
      "Dermoxin",
      "Dungistop",
      "Focusan",
      "Fungistop",
      "Genaspor",
      "Micoisdin",
      "Phytoderm",
      "Pitrex",
      "Separin",
      "Sorgoa",
      "Sporiline",
      "Timoped",
      "Tinactin",
      "Tinaderm",
      "Tinatox",
      "Tinavet",
      "Ting",
      "Tniaderm",
      "Tolnaftato",
      "Tolnaftatum",
      "Tolnaphthate",
      "Tolsanil",
      "Tonoftal",
      "Tritin",
      "ZeaSorb",
      "Aftate For Jock Itch Aerosol Spray Powder",
      "Aftate For Jock Itch Gel",
      "Aftate For Jock Itch Sprinkle Powder",
      "Bioglan Brand Of Tolnaftate",
      "Carter Wallace Brand Of Tolnaftate",
      "Chinosol Brand Of Tolnaftate",
      "Douglas Brand Of Tolnaftate",
      "Essex Brand Of Tolnaftate",
      "Genaspore Cream",
      "Insight Brand Of Tolnaftate",
      "Isdin Brand Of Tolnaftate",
      "Naphthiomate T",
      "Ony Clear",
      "Pitrex Cream",
      "Schering Brand Of Tolnaftate",
      "Stiefel Brand Of Tolnaftate",
      "Thompson Brand Of Tolnaftate",
      "Tinactin Aerosol Liquid",
      "Tinactin Aerosol Powder",
      "Tinactin Antifungal Deodorant Powder Aerosol",
      "Tinactin Cream",
      "Tinactin Jock Itch Aerosol Powder",
      "Tinactin Jock Itch Cream;Tinactin Jock Itch Spray Powder",
      "Tinactin Plus Aerosol Powder",
      "Tinactin Plus Powder",
      "Tinactin Powder",
      "Tinactin Solution",
      "Ting Products",
      "Ting Antifungal Cream",
      "Ting Antifungal Powder",
      "Ting Antifungal Spray Liquid",
      "Ting Antifungal Spray Powder",
      "Tolnaftat Purder N",
      "Tolnaftate Douglas Brand",
      "Tolnaftate Schering Brand",
      "Tolnaftato [DCIT]",
      "Wernigerode Brand Of Tolnaftate",
      "Zenith Brand Of Tolnaftate",
      "NP 27",
      "NP27",
      "Pedinol Brand 1 Of Tolnaftate",
      "Pedinol Brand 2 Of Tolnaftate",
      "Sch 10144",
      "Absorbine (TN)",
      "Aftate (TN)",
      "Aftate For Athlete's Foot Aerosol Spray Liquid",
      "Aftate For Athlete's Foot Aerosol Spray Powder",
      "Aftate For Athlete's Foot Gel",
      "Aftate For Athlete's Foot Sprinkle Powder",
      "Genaspor (TN)",
      "HI-Alazin",
      "Hi-Alarzin",
      "Lamasil AF (TN)",
      "NP 27 (TN)",
      "NP-27 Cream",
      "NP-27 Powder",
      "NP-27 Solution",
      "NP-27 Spray Powder",
      "Naphthiomate-T",
      "Odor Eaters (TN)",
      "Ony-Clear",
      "Purder N, Tolnaftat",
      "Schering-Plough Brand 1 Of Tolnaftate",
      "Schering-Plough Brand 2 Of Tolnaftate",
      "Scholl (TN)",
      "Separin (TN)",
      "Tinactin (TN)",
      "Tinavet (Veterinary)",
      "Ting (TN)",
      "Tolnaftate(USAN)",
      "Tolnaftato [INN-Spanish]",
      "Tolnaftatum [INN-Latin]",
      "Zeasorb-AF Powder",
      "Tolnaftate (JP14/USP)",
      "Tolnaftate (JP15/USP)",
      "Dr. Scholl's Athlete's Foot Spray",
      "Tolnaftate (JP15/USP/INN)",
      "Tolnaftate [USAN:BAN:INN:JAN]",
      "Tolnaftate [USAN:INN:BAN:JAN]",
      "M,N-Dimethylthiocarbanilic Acid O-2-Naphthyl Ester",
      "Methyl (3-Methylphenyl)Carbamothioic Acid O-2-Naphthalenyl Ester",
      "O-2-Naphthyl N,N-Dimethylthiocarbanilate",
      "O-2-Naphthyl M,N-Dimethylthiocarbanilate",
      "Methyl-(3-Methylphenyl)Carbamothioic Acid O-2-Naphthyl Ester",
      "Methyl-[3-Methylphenyl]-Carbamothioic Acid O-2-Naphthyl Ester",
      "O-(2-Naphthyl) Methyl(3-Methylphenyl)Thiocarbamate",
      "O-Naphthalen-2-Yl Methyl(3-Methylphenyl)Carbamothioate",
      "Carbamothioic Acid, Methyl(3-Methylphenyl)-, O-2-Naphthalenyl Ester",
      "Carbanilic Acid, N,m-Dimethylthio-, O-2-Naphthyl Ester",
      "Carbanilic Acid, M,N-Dimethylthio-, O-2-Naphthyl Ester",
      "N-Methyl-(3-Methylphenyl)-Carbamothioic Acid, O-2-Naphthalenyl Ester",
      "O-Naphthalen-2-Yl N-Methyl-N-(3-Methylphenyl)Carbamothioate",
      "Carbamothioic Acid, N-Methyl-N-(3-Methylphenyl)-, O-2-Naphthalenyl Ester",
      "2-Naphthyl N-Methyl-N-(3-Tolyl)Thionocarbamate",
    ],
  },
  {
    primary_id: "D02NTS",
    names: ["JNJ-39393406"],
  },
  {
    primary_id: "D02NUC",
    names: ["NM-3014"],
  },
  {
    primary_id: "D02NUN",
    names: ["ISIS 6432"],
  },
  {
    primary_id: "D02NUR",
    names: ["2-(Tert-Butylamino)-3',5'-Difluoropropiophenone", "CHEMBL577501", "SCHEMBL634113", "BDBM50302939"],
  },
  {
    primary_id: "D02NUS",
    names: ["MB-314"],
  },
  {
    primary_id: "D02NUT",
    names: ["KU-60019"],
  },
  {
    primary_id: "D02NVF",
    names: ["M-241247"],
  },
  {
    primary_id: "D02NVY",
    names: ["Cyclo(-L-Am7(S2Py)-A2in-L-Ala-D-Pro-)", "CHEMBL394261"],
  },
  {
    primary_id: "D02NWC",
    names: ["CA-01"],
  },
  {
    primary_id: "D02NWF",
    names: [
      "Benzyl-(2-Thiophen-2-Yl-Quinazolin-4-Yl)-Amine",
      "CHEMBL117108",
      "MLS000334673",
      "SCHEMBL7287132",
      "AC1MA981",
      "HMS3383C08",
      "HMS2596E12",
      "BDBM50033291",
      "MCULE-3637836664",
      "NCGC00184968-01",
      "SMR000249431",
    ],
  },
  {
    primary_id: "D02NWL",
    names: ["[125I]iodoaminopotentidine", "[125I]APT", "[125I]-Iodoaminopotentidine"],
  },
  {
    primary_id: "D02NWS",
    names: ["4-Phenylbutyrohydroxamic Acid"],
  },
  {
    primary_id: "D02NXM",
    names: ["GSK2110183"],
  },
  {
    primary_id: "D02NXQ",
    names: ["NXL-105"],
  },
  {
    primary_id: "D02NXR",
    names: ["S-117957"],
  },
  {
    primary_id: "D02NYA",
    names: ["Anti-Ebola MAb"],
  },
  {
    primary_id: "D02NYK",
    names: ["Pifithrin-Alpha"],
  },
  {
    primary_id: "D02NYT",
    names: ["Golden Phosphorous Acetyletic Compound 2"],
  },
  {
    primary_id: "D02NZE",
    names: ["PMID20483621C5m"],
  },
  {
    primary_id: "D02NZF",
    names: ["NZ-17000", "Arenicin"],
  },
  {
    primary_id: "D02NZG",
    names: ["C[YYDEKLEE]-NH2", "CHEMBL1082946"],
  },
  {
    primary_id: "D02NZI",
    names: ["SM-130,686"],
  },
  {
    primary_id: "D02NZO",
    names: ["Amlodipine/ Irbesartan Fixed-Dose Combination"],
  },
  {
    primary_id: "D02OAB",
    names: [
      "5-Bromo-1H-Indazole",
      "5-Bromoindazole",
      "53857-57-1",
      "5-Bromo-2H-Indazole",
      "1h-Indazole, 5-Bromo-",
      "465529-55-9",
      "CHEMBL16425",
      "5-Bromo-1H-Indazole, 97%",
      "5-Brom-1h-Indazol",
      "MLS000088501",
      "5-Bromoazaindole",
      "5-Bromo-Indazole",
      "5- Bromoindazole",
      "5-Bromo-Azaindole",
      "5-Bromo-1hindazole",
      "AC1LFXVY",
      "4-Nitrotrityl Chloride",
      "PubChem15628",
      "5-Bromo-1 H-Indazole",
      "ACMC-209lal",
      "5-Bromanyl-1H-Indazole",
      "2H-Indazole, 5-Bromo-",
      "SCHEMBL5562",
      "AC1Q1H9U",
      "KSC269G3N",
      "Cid_761929",
      "AMBZ0279",
      "CTK1G9336",
      "DTXSID50354020",
      "HID1020",
      "STVHMYNPQCLUNJ-UHFFFAOYSA-N",
      "MolPort-029-084-055",
    ],
  },
  {
    primary_id: "D02OAH",
    names: ["PMID25666693-Compound-5"],
  },
  {
    primary_id: "D02OAI",
    names: ["AGN193836", "AGN-193836", "AGN 193836"],
  },
  {
    primary_id: "D02OAJ",
    names: ["UTPgammaS"],
  },
  {
    primary_id: "D02OAS",
    names: ["PMID30247903-Compound-General Structure40"],
  },
  {
    primary_id: "D02OAV",
    names: [
      "Chloral Hydrate",
      "302-17-0",
      "2,2,2-Trichloroethane-1,1-Diol",
      "Trichloroacetaldehyde Hydrate",
      "Noctec",
      "Tosyl",
      "Chloral Monohydrate",
      "Phaldrone",
      "Chloraldurat",
      "Nycoton",
      "Kessodrate",
      "Chloradorm",
      "Rectules",
      "Lorinal",
      "Felsules",
      "Cohidrate",
      "Trawotox",
      "Oradrate",
      "Sontec",
      "Somnos",
      "Nycton",
      "Hynos",
      "Escre",
      "Dormal",
      "Knockout Drops",
      "Trichloracetaldehyd-Hydrat",
      "Somni Sed",
      "1,1-Ethanediol, 2,2,2-Trichloro-",
      "SK-Chloral Hydrate",
      "Chloralhydrate",
      "Aquachloral",
      "Somnote",
      "2,2,2-Trichloro-1,1-Ethanediol",
      "Hydrate De Chloral",
      "1,1,1-Trichloro-2,2-Ethanediol",
      "Trichloroacetaldehyde Mono",
    ],
  },
  {
    primary_id: "D02OBA",
    names: ["Bis(5-Hydroxybenzo[b]furan-2-Yl)Methanone", "CHEMBL225826"],
  },
  {
    primary_id: "D02OBL",
    names: ["Iv Human Immunoglobulin"],
  },
  {
    primary_id: "D02OBU",
    names: ["Valpentin"],
  },
  {
    primary_id: "D02OBW",
    names: ["Aminoazetidine Derivative 2"],
  },
  {
    primary_id: "D02OCD",
    names: ["NIBR-785"],
  },
  {
    primary_id: "D02OCF",
    names: ["AZD-8294"],
  },
  {
    primary_id: "D02ODA",
    names: ["Palmarumycin Derivative 4"],
  },
  {
    primary_id: "D02ODJ",
    names: [
      "AMMTC",
      "AC1NSJT8",
      "CHEMBL53824",
      "SCHEMBL6268265",
      "GTPL3385",
      "L000015",
      "N-[(6-Methoxy-9-Methyl-1,2,3,4-Tetrahydrocarbazol-4-Yl)Methyl]acetamide",
      "N-Acetyl-4-Aminomethyl-6-Methoxy-9-Methyl-1,2,3,4-Tetrahydrocarbazole",
      "N-[(6-Methoxy-9-Methyl-1,2,3,4-Tetrahydro-9H-Carbazol)-4-Ylmethyl]acetamide",
    ],
  },
  {
    primary_id: "D02ODX",
    names: ["Cancer Therapy, Revalesio Corp"],
  },
  {
    primary_id: "D02OEF",
    names: ["Mutant B-Raf Kinase Inhibitors"],
  },
  {
    primary_id: "D02OEI",
    names: [
      "3-(2,3-Dimethyl-Phenyl)-Piperidine",
      "3-(2,3-Dimethylphenyl)Piperidine",
      "CHEMBL144019",
      "219703-97-6",
      "3-(Dimethylphenyl) Piperidine",
      "SCHEMBL9310414",
      "BDBM50068426",
      "AKOS006308927",
    ],
  },
  {
    primary_id: "D02OEP",
    names: ["CWF-07pre03"],
  },
  {
    primary_id: "D02OET",
    names: ["PMID1895299C4p"],
  },
  {
    primary_id: "D02OGA",
    names: ["Thiadiazolidindione Derivative 2"],
  },
  {
    primary_id: "D02OGQ",
    names: [
      "APD",
      "DXG",
      "APD Nucleoside Derivative (Oral, HIV-1) RFS Pharma",
      "APD (Oral, HIV-1, HBV)",
      "DXG Prodrug (Oral, HIV-1), RFS Pharma",
      "APD (Oral, HIV-1, HBV), RFS Pharma",
      "Nucleoside Reverse Transcriptase Inhibitor (Oral, HIV-1, HBV), RFS Pharma",
      "(-)-Beta-D-2-Aminopurine Dioxolane",
      "(-)-Beta-D-Dioxolane Guanine",
    ],
  },
  {
    primary_id: "D02OHL",
    names: ["ISIS 133712"],
  },
  {
    primary_id: "D02OHR",
    names: ["SC-57666"],
  },
  {
    primary_id: "D02OHW",
    names: ["2-(3-Chlorophenyl)-7-Methyl-1,8-Naphthyridine", "CHEMBL397551"],
  },
  {
    primary_id: "D02OIP",
    names: ["OP-05"],
  },
  {
    primary_id: "D02OJC",
    names: ["Xaliproden"],
  },
  {
    primary_id: "D02OJH",
    names: ["PMID29649907-Compound-26"],
  },
  {
    primary_id: "D02OJN",
    names: ["Ru-41740", "Biostim (TN)"],
  },
  {
    primary_id: "D02OJQ",
    names: ["Des-AA1,2,5-[D-Trp8,Tyr11]SRIF", "CHEMBL262379"],
  },
  {
    primary_id: "D02OKF",
    names: [
      "6-(2-Chloro-4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL195311",
      "SCHEMBL3932692",
      "FNSIACDWDZGUHN-UHFFFAOYSA-N",
      "BDBM50168347",
      "ZINC13645053",
    ],
  },
  {
    primary_id: "D02OKK",
    names: ["SDZ 216-525"],
  },
  {
    primary_id: "D02OLJ",
    names: ["N-Acylpiperidine Ether Derivative 7"],
  },
  {
    primary_id: "D02OLS",
    names: ["PPAR Delta Agonists"],
  },
  {
    primary_id: "D02OME",
    names: ["IMMU-132"],
  },
  {
    primary_id: "D02OMP",
    names: ["I-ABA"],
  },
  {
    primary_id: "D02ONA",
    names: ["AUTO3"],
  },
  {
    primary_id: "D02ONK",
    names: ["Y-20024"],
  },
  {
    primary_id: "D02ONP",
    names: ["Ethyl Bis(4-Hydroxy-2-Oxo-2H-Chromen-3-Yl)Acetate", "CHEMBL571926"],
  },
  {
    primary_id: "D02ONR",
    names: ["RG7686"],
  },
  {
    primary_id: "D02ONT",
    names: ["5-FP", "5-Fluoro-2(1H)-Pyrimidinone"],
  },
  {
    primary_id: "D02ONZ",
    names: [
      "6-Bromo-2-(4-Nitro-Phenyl)-Chromen-4-One",
      "CHEMBL55023",
      "6-Bromo-4'-Nitroflavone",
      "SCHEMBL4990126",
      "GMVMGIYPRPPNMB-UHFFFAOYSA-N",
      "ZINC29544424",
    ],
  },
  {
    primary_id: "D02OOB",
    names: ["PRX-12205"],
  },
  {
    primary_id: "D02OPG",
    names: ["[3H]BQ123", "[3H]cyc(DTrp-DAsp-Pro-DVal-Leu", "[3H]-BQ123"],
  },
  {
    primary_id: "D02OPH",
    names: ["I-5"],
  },
  {
    primary_id: "D02ORJ",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D02ORS",
    names: ["YKP-GI"],
  },
  {
    primary_id: "D02OTH",
    names: ["FR-115427"],
  },
  {
    primary_id: "D02OTK",
    names: ["CH-4051"],
  },
  {
    primary_id: "D02OVN",
    names: [
      "Acylated Ceftazidime",
      "ACYLATED CEFTAZIDIME",
      "DB03530",
      "(2R)-2-[(1R)-1-({(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(1-Carboxy-1-Methylethoxy)Imino]acetyl}amino)-2-Oxoethyl]-5-Methylidene-5,6-Dihydro-2H-1,3-Thiazine-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D02OXG",
    names: ["PMID26560530-Compound-1"],
  },
  {
    primary_id: "D02OYL",
    names: ["1-(Biphenyl-4-Yl)-3-(4-(Piperidin-1-Yl)Butyl)Urea", "CHEMBL1086332", "SCHEMBL13527422"],
  },
  {
    primary_id: "D02OZB",
    names: [
      "2-Piperazin-1-Yl-Phenol",
      "1011-17-2",
      "2-(Piperazin-1-Yl)Phenol",
      "1-(2-Hydroxyphenyl)Piperazine",
      "2-(1-Piperazinyl)Phenol",
      "2-(1-Piperazino)Phenol",
      "2-Piperazin-1-Ylphenol",
      "Phenol, 2-(1-Piperazinyl)-",
      "O-(1-Piperazinyl)Phenol",
      "N-(2-Hydroxyphenyl)Piperazine",
      "1-(2-Hydroxyphenyl)-Piperazine",
      "CHEMBL266250",
      "2-Piperazinylphenol",
      "EINECS 213-782-5",
      "2-Piperazinophenol",
      "Rarechem Ah Ck 0117",
      "ACMC-209v6m",
      "2-(1-Piperazinyl) Phenol",
      "AC1L2EA8",
      "AC1Q7AO1",
      "2-(Piperazine-1-Yl)Phenol",
      "Oprea1_446736",
      "Labotest-Bb Lt01596124",
      "SCHEMBL531748",
      "AC1Q788T",
    ],
  },
  {
    primary_id: "D02OZE",
    names: [
      "Digoxin",
      "20830-75-5",
      "12beta-Hydroxydigitoxin",
      "Digoxine",
      "Lanoxin",
      "Lanoxicaps",
      "Digossina",
      "Digoxina",
      "Digoxinum",
      "Digosin",
      "Lanicor",
      "Digacin",
      "Dilanacin",
      "CHEBI:4551",
      "MLS000069819",
      "Lanacordin",
      "Cardiogoxin",
      "Eudigox",
      "Davoxin",
      "SMR000059217",
      "Rougoxin",
      "Mapluxin",
      "Lenoxin",
      "Lanacrist",
      "Dynamos",
      "Vanoxin",
      "Neo-Lanicor",
      "Lanoxin PG",
      "Digoxin Pediatric",
      "Digoxin Nativelle",
      "SK-Digoxin",
      "UNII-73K4184T59",
      "Homolle's Digitalin",
      "Hemigoxine Nativelle",
      "MFCD00003674",
      "Digitek (TN)",
      "Lanoxicaps (TN)",
      "Lanoxin (TN)",
      "Digoxin (JP15/USP)",
      "(3beta,5beta,12beta)-3-{[2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl]oxy}-12,14-Dihydroxycard-20(22)-Enolide",
      "4-[(1S,2S,5S,7R,10R,11S,14R,15S,16R)-5-{[(2R,4S,5S,6R)-5-{[(2S,4S,5S,6R)-5-{[(2S,4S,5S,6R)-4,5-Dihydroxy-6-Methyloxan-2-Yl]oxy}-4-Hydroxy-6-Methyloxan-2-Yl]oxy}-4-Hydroxy-6-Methyloxan-2-Yl]oxy}-11,16-Dihydroxy-2,15-Dimethyltetracyclo[8700^{2,7}",
      "[3H]digoxin",
    ],
  },
  {
    primary_id: "D02OZK",
    names: [
      "Obatoclax",
      "CHEMBL408194",
      "UNII-QN4128B52A",
      "QN4128B52A",
      "SCHEMBL631676",
      "SCHEMBL16340850",
      "BDBM50376902",
      "ZINC136873052",
      "DB12191",
      "NCGC00387855-01",
      "BRD-K15600710-066-01-7",
    ],
  },
  {
    primary_id: "D02OZT",
    names: ["DCR-MYC"],
  },
  {
    primary_id: "D02OZY",
    names: ["Metharbital"],
  },
  {
    primary_id: "D02PAH",
    names: [
      "Bendroflumethiazide",
      "Aprinox",
      "BHFT",
      "Bendrofluazide",
      "Bendroflumethazide",
      "Bendroflumethiazidum",
      "Bendroflumetiazida",
      "Bendroflumetiazide",
      "Bendrofumethiazide",
      "Bentride",
      "Benuron",
      "Benzhydroflumethiazide",
      "Benzydroflumethiazide",
      "Benzylhydroflumethiazide",
      "Benzylrodiuran",
      "Berkozide",
      "Bristuric",
      "Bristuron",
      "Centyl",
      "Corzide",
      "Flumersil",
      "Flumesil",
      "Intolex",
      "Livesan",
      "Naigaril",
      "Nateretin",
      "Naturetin",
      "Naturine",
      "Niagaril",
      "Nikion",
      "Orsile",
      "Pluryl",
      "Pluryle",
      "Plusuril",
      "Poliuron",
      "Rauzide",
      "Repicin",
      "Salural",
      "Salures",
      "Sinesalin",
      "Sodiuretic",
      "Thiazidico",
      "Urlea",
      "Bendroflumethiazide [USP]",
      "Bendroflumetiazide [DCIT]",
      "Rautrax N",
      "Relan Beta",
      "BL H368",
      "FT 8",
      "FT 81",
      "Be 724-A",
      "Bendroflumethiazidum [INN-Latin]",
      "Bendroflumetiazida [INN-Spanish]",
      "Benzy-Rodiuran",
      "NATURETIN-10",
      "NATURETIN-5",
      "Naturetin (TN)",
      "Neo-Naclex",
      "Neo-Rontyl",
      "Naturetin-2.5",
      "Bendroflumethiazide (JAN/USP/INN)",
      "(+-)-3-Benzyl-3,4-Dihydro-6-(Trifluoromethyl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3,4-Dihydro-3-(Phenylmethyl)-6-(Trifluoromethyl)-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3-Benzyl-3,4-Dihydro-6-(Trifluoromethyl)-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3-Benzyl-3,4-Dihydro-6-(Trifluoromethyl)-,1,1-Dioxide",
      "3,4-Dihydro-3-(Phenylmethyl)-6-(Trifluoromethyl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide1,1-Dioxide",
      "3-(Phenylmethyl)-6-(Trifluoromethyl)-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3-Benzyl-3,4-Dihydro-6-(Trifluoromethyl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3-Benzyl-6-(Trifluoromethyl)-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3-Benzyl-6-Trifluoromethyl-7-Sulfamoyl-3,4-Dihydro-1,2,4-Benzothiadiazine, 1,1-Dioxide",
      "3-Benzyl-6-Trifluoromethyl-7-Sulfamoyl-3,4-Dihydro-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-Trifluoromethyl-3-Benzyl-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine, 1,1-Dioxide",
      "6-Trifluoromethyl-3-Benzyl-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D02PAJ",
    names: ["UR-9650"],
  },
  {
    primary_id: "D02PAU",
    names: ["ZK-814048"],
  },
  {
    primary_id: "D02PAW",
    names: ["TLC-388"],
  },
  {
    primary_id: "D02PBS",
    names: ["7-Benzoyloxy-2-(Morpholin-4-Yl)-Chromen-4-One", "CHEMBL198944", "SCHEMBL3534476"],
  },
  {
    primary_id: "D02PBY",
    names: ["3-Noradamantyl-1,3-Dipropylxanthine"],
  },
  {
    primary_id: "D02PCR",
    names: [
      "Spectinomycin",
      "Actinospectacin",
      "Actinospectacina",
      "Adspec",
      "Espectinomicina",
      "Prospec",
      "SCM",
      "SPCM",
      "Spectam",
      "Spectinomicina",
      "Spectinomycine",
      "Spectinomycinum",
      "Spectogard",
      "Stanilo",
      "Togamycin",
      "Trobicin",
      "Actinospectacina [Italian]",
      "Spectam Scour Halt",
      "Spectinomicina [Italian]",
      "Spectinomycin Di HCl",
      "Spectinomycin HCl",
      "Spectinomycin Dihydrochloride",
      "Spectinomycin Hydrochloride",
      "Spectinomycin Hydrochloride Anhydrous",
      "Spectinomycin Monohydrochloride",
      "Spectinomycin Sulfate",
      "Antibiotic 2233wp",
      "M 141",
      "U 18409",
      "U 18409 E",
      "Actinospectacin, Hydrochloride",
      "Adspec (TN)",
      "Espectinomicina [INN-Spanish]",
      "M-141",
      "Prospec (TN)",
      "Spectinomycin (INN)",
      "Spectinomycin Dihydrochloride, Anhydrous",
      "Spectinomycin Dihydrochloride, Pentahydrate",
      "SpectinomycinHCl/ Sulphate",
      "Spectinomycin Hydrochloride (Anhydrous)",
      "Spectinomycin [INN:BAN]",
      "Spectinomycine [INN-French]",
      "Spectinomycinum [INN-Latin]",
      "Trobicin (TN)",
      "U-18409AE",
      "XK 43-1",
      "Togamycin Sulfate (1:1)",
      "ACTINOSPECTACIN, ESPECTINOMICINA, CHX-3101",
      "(2R,4aR,5aR,6S,7S,8R,9S,9aR,10aS)-4a,7,9-Trihydroxy-2-Methyl-6,8-Bis(Methylamino)Decahydro-4H-Pyrano[2,3-B][1,4]benzodioxin-4-One",
      "(2R-(2alpha,4abeta,5abeta,6beta,7beta,8beta,9alpha,9aalpha,10abeta))-Decahydro-4a,7,9-Trihydroxy-2-Methyl-6,8-Bis(Methylamino)-4H-Pyrano(2,3-B)(1,4)Benzodioxin-4-One Monohydrochloride",
      "4H-Pyrano[2,3-B][1,4]benzodioxin-4-One, Decahydro-4a,7,9-Trihydroxy-2-Methyl-6,8-Bis(Methylamino)-, [2R-(2.alpha.,4a.beta.,5a.beta.,6.beta.,7.beta.,8.beta.,9.alpha.,9a.alpha.,10a.beta.)]-, Sulfate (1:1) (Salt)",
    ],
  },
  {
    primary_id: "D02PDP",
    names: ["3-(2,6-Diphenylpyridin-4-Yl)Phenol", "CHEMBL1096032"],
  },
  {
    primary_id: "D02PFE",
    names: ["Steroid Derivative 4"],
  },
  {
    primary_id: "D02PFJ",
    names: ["1-Methyl-1-(4-Pyrrolidin-1-Yl-But-2-Ynyl)-Urea", "CHEMBL110018", "BDBM50004732"],
  },
  {
    primary_id: "D02PFP",
    names: ["PMID27321640-Compound-58"],
  },
  {
    primary_id: "D02PGM",
    names: ["ISIS 16124"],
  },
  {
    primary_id: "D02PGP",
    names: ["PU3"],
  },
  {
    primary_id: "D02PHE",
    names: [
      "AZD9668",
      "Alvelestat",
      "848141-11-7",
      "Alvelestat (AZD9668)",
      "AZD 9668",
      "CHEMBL3617964",
      "AZD-9668",
      "KB-105160",
      "Avelestat",
      "6-Methyl-5-(1-Methyl-1H-Pyrazol-5-Yl)-N-{[5-(Methylsulfonyl)Pyridin-2-Yl]methyl}-2-Oxo-1-[3-(Trifluoromethyl)Phenyl]-1,2-Dihydropyridine-3-Carboxamide",
      "6-Methyl-5-(1-Methyl-1H-Pyrazol-5-Yl)-N-((5-(Methylsulfonyl)Pyridin-2-Yl)Methyl)-2-Oxo-1-(3-(Trifluoromethyl)Phenyl)-1,2-Dihydropyridine-3-Carboxamide",
    ],
  },
  {
    primary_id: "D02PIG",
    names: ["H-Cpa-Gly-Gly-Phe-Met-OH", "CHEMBL391580"],
  },
  {
    primary_id: "D02PIH",
    names: ["HO-221"],
  },
  {
    primary_id: "D02PIN",
    names: ["GR-94839"],
  },
  {
    primary_id: "D02PIO",
    names: ["Benralizumab"],
  },
  {
    primary_id: "D02PJA",
    names: ["Hydriodic Acid Iodine-125"],
  },
  {
    primary_id: "D02PJI",
    names: ["Peptide Analog 21"],
  },
  {
    primary_id: "D02PKO",
    names: ["3-(4-Methoxy-Benzenesulfonyl)-Cyclopentanethiol", "CHEMBL51013"],
  },
  {
    primary_id: "D02PKR",
    names: [
      "3-Bromo-6-Nitro-2-Piperazin-1-Yl-Quinoline",
      "CHEMBL290523",
      "SCHEMBL6365221",
      "MPHPQAKQQDLIGK-UHFFFAOYSA-N",
      "BDBM50090214",
      "2-Piperazino-3-Bromo-6-Nitroquinoline",
    ],
  },
  {
    primary_id: "D02PKV",
    names: ["Human Recombinant Factor VIIa"],
  },
  {
    primary_id: "D02PLD",
    names: [
      "1-(2-Butoxy-Phenyl)-Piperazine",
      "CHEMBL9452",
      "Piperazine, 1-(2-Butoxyphenyl)-",
      "106476-37-3",
      "ACMC-20ma64",
      "1-(2-Butoxyphenyl)Piperazine",
      "SCHEMBL9319475",
      "CTK0G3319",
      "DTXSID60560817",
      "ZINC25997855",
      "BDBM50017460",
    ],
  },
  {
    primary_id: "D02PLE",
    names: ["KB-001"],
  },
  {
    primary_id: "D02PLG",
    names: ["4,5-Bis(4-Methoxyphenyl)-3H-1,2-Dithiole-3-Thione", "CHEMBL489899"],
  },
  {
    primary_id: "D02PMO",
    names: ["Topotecan"],
  },
  {
    primary_id: "D02PMT",
    names: ["Nagrestipen"],
  },
  {
    primary_id: "D02PNH",
    names: ["COL-171"],
  },
  {
    primary_id: "D02PNP",
    names: ["AGN-214868"],
  },
  {
    primary_id: "D02POA",
    names: [
      "5-(1,1-Dimethyl-Heptyl)-2-Pyridin-3-Yl-Phenol",
      "CHEMBL234207",
      "SCHEMBL4888568",
      "OYBDWAVFBKZYIO-UHFFFAOYSA-N",
      "BDBM50213139",
      "5-(1,1-Dimethylheptyl)-2-Pyridin-3-Yl Phenol",
    ],
  },
  {
    primary_id: "D02POF",
    names: ["Vaccine, Parasitic Infection"],
  },
  {
    primary_id: "D02POH",
    names: ["TA-7906"],
  },
  {
    primary_id: "D02POV",
    names: ["1-(2-Phenyl-Propyl)-3-Thiazol-2-Yl-Thiourea"],
  },
  {
    primary_id: "D02PPN",
    names: [
      "Methylphenidate",
      "Calocain",
      "Centedein",
      "Centedrin",
      "Centredin",
      "Concerta",
      "Daytrana",
      "Meridil",
      "Metadate",
      "Methylfenidan",
      "Methylin",
      "Methylofenidan",
      "Methylphen",
      "Methylphenidan",
      "Methylphenidatum",
      "Metilfenidato",
      "Phenidylate",
      "Plimasine",
      "Riphenidate",
      "Ritalin",
      "Ritaline",
      "Tsentedrin",
      "Metadate CD",
      "Metadate ER",
      "Methyl Phenidate",
      "Methyl Phenidyl Acetate",
      "Methyl Phenidylacetate",
      "Methylin ER",
      "Metilfenidato [Italian]",
      "Ritalin LA",
      "Ritalin SR",
      "Ritcher Works",
      "C 4311",
      "Attenta (TN)",
      "Biphentin (TN)",
      "Concerta (TN)",
      "D-Methylphenidate HCl",
      "Daytrana (TN)",
      "Equasym (TN)",
      "Metadate ER (TN)",
      "Methylin (TN)",
      "Methylphenidate [INN:BAN]",
      "Methylphenidatum [INN-Latin]",
      "Metilfenidato [INN-Spanish]",
      "Motiron (TN)",
      "PMS-Methylphenidate",
      "Ritalin (TN)",
      "Ritalin LA (TN)",
      "Ritalin-SR",
      "Rubifen (TN)",
      "Methylphenidate (USAN/INN)",
      "Alpha-Phenyl-2-Piperidineacetic Acid Methyl Ester",
      "Methyl Phenyl(Piperidin-2-Yl)Acetate",
      "Methyl Alpha-Phenyl-Alpha-2-Piperidinylacetate",
      "Alpha-Phenyl-Alpha-(2-Piperidyl)Acetic Acid Methyl Ester",
      "Methyl 2-Phenyl-2-Piperidin-2-Ylacetate",
      "Methyl Alpha-Phenyl-Alpha-(2-Piperidyl)Acetate",
      "Methyl (2-Phenyl-2-(2-Piperidyl)Acetate)",
      "METHYLPHENIDATE (SEE ALSO: METHYLPHENIDATE HYDROCHLORIDE, CAS 298-59-9, NTPNO 10266-R)",
      "2-Piperidineacetic Acid, .alpha.-Phenyl-, Methyl Ester",
      "2-Piperidineacetic Acid, Alpha-Phenyl-, Methyl Ester",
    ],
  },
  {
    primary_id: "D02PPQ",
    names: ["Ethyl 1-[(1H-Benzimidazol-5(6)-Yl)Sulfonyl]-1H-Pyrrole-2-Carboxylate"],
  },
  {
    primary_id: "D02PQB",
    names: ["ATX-101", "ATX-101, APIM Therapeutics", "Proliferating Cell Nuclear Antigen (Peptide, Cancer), APIM"],
  },
  {
    primary_id: "D02PSL",
    names: ["1-(4-Cyanobenzyl)-5-(3-Methylphenyl)-1H-Imidazole", "CHEMBL607862"],
  },
  {
    primary_id: "D02PSR",
    names: [
      "N'-(2-Phenylallyl)Hydrazine Hydrochloride",
      "CHEMBL536057",
      "SCHEMBL18953115",
      "N'-(2-Phenylallyl)Hydrazine HCl",
      "LS-193138",
      "N'-(2-Phenyl-Allyl)-Hydrazine Hydrochloride",
    ],
  },
  {
    primary_id: "D02PSS",
    names: ["RG1577"],
  },
  {
    primary_id: "D02PSZ",
    names: ["A-74498"],
  },
  {
    primary_id: "D02PTD",
    names: ["IDC-7181", "Cephalosporin Antibiotic (Bacterial Infection), Il Dong"],
  },
  {
    primary_id: "D02PTI",
    names: ["AST-005"],
  },
  {
    primary_id: "D02PUV",
    names: ["4-[1-(4-Hydroxyphenyl)-3-Methyl-1-Butenyl]phenol"],
  },
  {
    primary_id: "D02PVB",
    names: [
      "2,6-Dihydroanthra/1,9-Cd/Pyrazol-6-One",
      "Anthra[1,9-Cd]pyrazol-6(2H)-One",
      "129-56-6",
      "1,9-Pyrazoloanthrone",
      "SP600125",
      "Pyrazolanthrone",
      "Dibenzo[cd,g]indazol-6(2H)-One",
      "Pyrazoleanthrone",
      "SP 600125",
      "Anthra-1,9-Pyrazol-6-None",
      "SP-600125",
      "CI 70300",
      "2H-Dibenzo[cd,g]indazol-6-One",
      "JNK Inhibitor II",
      "SAPK Inhibitor II",
      "NSC 75890",
      "UNII-1TW30Y2766",
      "ANTHRA(1,9-Cd)PYRAZOL-6(2H)-ONE",
      "EINECS 204-955-6",
      "2,6-DIHYDROANTHRA/1,9-CD/PYRAZOL-6-ONE",
      "BRN 0746890",
      "CHEMBL7064",
      "MLS002693964",
      "CHEBI:90695",
      "ACPOUJIDANTYHO-UHFFFAOYSA-N",
      "1TW30Y2766",
    ],
  },
  {
    primary_id: "D02PVD",
    names: ["PMID12014956C12", "AC1NV8HB", "GTPL3192", "BDBM50113287"],
  },
  {
    primary_id: "D02PVS",
    names: ["KUR-213"],
  },
  {
    primary_id: "D02PWM",
    names: [
      "Dyphylline",
      "Aristophyllin",
      "Asthmolysin",
      "Astmamasit",
      "Astrophyllin",
      "Circain",
      "Circair",
      "Coronal",
      "Coronarin",
      "Corphyllin",
      "Dihydroxypropyltheophylline",
      "Diprofilina",
      "Diprofillin",
      "Diprofillina",
      "Diprofilline",
      "Diprophyllin",
      "Diprophylline",
      "Diprophyllinum",
      "Dipropylline",
      "Droxine",
      "Dyflex",
      "Glyfyllin",
      "Glyphyllin",
      "Glyphylline",
      "Glyphyllinum",
      "Hidroxiteofillina",
      "Hiphyllin",
      "Hyphylline",
      "Iphyllin",
      "Isophyllen",
      "Liactemin",
      "Lufyllin",
      "Neophyl",
      "Neophyllin",
      "Neophylline",
      "Neostenovasan",
      "Neothylline",
      "Neotilina",
      "Neufil",
      "Neutrafil",
      "Neutrafillina",
      "Neutraphyllin",
      "Neutraphylline",
      "Neutroxantina",
      "Propyphyllin",
      "Protheophylline",
      "Purifilin",
      "Silbephyllin",
      "Silbephylline",
      "Solufilin",
      "Solufyllin",
      "Soluphyllin",
      "Synthophylline",
      "Tefilan",
      "Teofen",
      "Tesfen",
      "Theal",
      "Thefylan",
      "Dihydroxypropyl Theophylline",
      "Dihydroxypropyl Theopylin",
      "Dilor G",
      "Diprofillina [DCIT]",
      "Dyphylline [USAN]",
      "Neophyllin M",
      "Theal Ampules",
      "AFI-Phyllin",
      "COR-Theophylline",
      "Dihydroxypropyl Theopylin (German)",
      "Dilor-400",
      "Diphyllin (VAN)",
      "Diprofilina [INN-Spanish]",
      "Diprophyllinum [INN-Latin]",
      "Dyphylline (USP)",
      "Lufyllin (TN)",
      "Neo-Vasophylline",
      "Diprophylline (JAN/INN)",
      "Neothylline, Lufyllin, Diprophylline,Dyphylline",
      "(+-)-7-(2,3-Dihydroxypropyl)Theophylline",
      "(+-)-Diprophylline",
      "(+-)-Dyphylline",
      "(1,2-Dihydroxy-3-Propyl)Thiophyllin",
      "1,3-Dimethyl-7-(2,3-Dihydroxypropyl)Xanthine",
      "1H-Purine-2,6-Dione, 7-(2,3-Dihydroxypropyl)-3,7-Dihydro-1,3-Dimethyl",
      "7-(2,3-Dihydroxypropyl)-1,3-Dimethyl-3,7-Dihydro-1H-Purine-2,6-Dione",
      "7-(2,3-Dihydroxypropyl)-1,3-Dimethylxanthine",
      "7-(2,3-Dihydroxypropyl)-3,7-Dihydro-1,3-Dimethyl-1H-Purine-2,6-Dione",
      "7-(2,3-Dihydroxypropyl)Theophylline",
      "7-(2,3-Dioxypropyl)Theophylline",
      "7-(2,3-Dihydroxypropyl)-1,3-Dimethyl-2,3,6,7-Tetrahydro-1H-Purine-2,6-Dione",
      "7-(2,3-Dihydroxypropyl)-1,3-Dimethylpurine-2,6-Dione",
      "7-(Beta,gamma-Dihydroxypropyl)Theophylline",
      "7-(Beta.,.gamma.-Dihydroxypropyl)Theophylline",
      "7-DIHYDROXYPROPYLTHEOPHYLLINE",
      "7-[2,3-Dihydroxypropyl]-Theophylline",
    ],
  },
  {
    primary_id: "D02PWZ",
    names: ["N-(2,4-Dihydroxybenzyl)-3,5-Dihydroxybenzamide", "CHEMBL437510"],
  },
  {
    primary_id: "D02PXN",
    names: ["N1-Dansyl-Spermine"],
  },
  {
    primary_id: "D02PXP",
    names: ["CIGB-370"],
  },
  {
    primary_id: "D02PYB",
    names: ["SDZ-GPI-562"],
  },
  {
    primary_id: "D02PYM",
    names: ["QAK-423"],
  },
  {
    primary_id: "D02PYN",
    names: [
      "SR 16234",
      "229634-98-4",
      "TS 108",
      "TAS-108",
      "SR16234",
      "UNII-9B29N23K7E",
      "9B29N23K7E",
      "SCHEMBL2836841",
      "DTXSID30177512",
      "C33H47NO3.C6H8O7",
      "(7alpha)-21-(4-((Diethylamino)Methyl)-2-Methoxyphenoxy)-7-Methyl-19-Norpregna-1,3,5(10)-Trien-3-Ol 2-Hydroxy-1,2,3-Propanetricarboxylate",
      "Y1847",
      "354808-47-2",
      "(7R,8S,9S,13R,14S,17R)-17-(2-(4-((Diethylamino)Methyl)-2-Methoxyphenoxy)Ethyl)-7,13-Dimethyl-7,8,9,11,12,13,14,15,16,17-Decahydro-6H-Cyclopenta[a]phenanthren-3-Ol 2-Hydroxypropane-1,2,3-Tricarboxylate",
    ],
  },
  {
    primary_id: "D02PZJ",
    names: ["4-(2'-(Trifluoromethoxy)Biphenyl-3-Yl)Oxazole", "CHEMBL1224520", "BDBM50325802"],
  },
  {
    primary_id: "D02PZZ",
    names: ["7-Methoxy-3-Pyridin-4-Yl-Quinoline", "CHEMBL329622", "7-Methoxy-3-(4-Pyridyl)Quinoline"],
  },
  {
    primary_id: "D02QAO",
    names: ["IPP-203101"],
  },
  {
    primary_id: "D02QAZ",
    names: [
      "PF-228",
      "869288-64-2",
      "PF-573228",
      "PF 573228",
      "PF573228",
      "CHEMBL514554",
      "3,4-Dihydro-6-[[4-[[[3-(Methylsulfonyl)Phenyl]methyl]amino]-5-(Trifluoromethyl)-2-Pyrimidinyl]amino]-2(1H)-Quinolinone",
      "6-((4-((3-(Methylsulfonyl)Benzyl)Amino)-5-(Trifluoromethyl)Pyrimidin-2-Yl)Amino)-3,4-Dihydroquinolin-2(1H)-One",
      "6-(4-(3-(Methylsulfonyl)Benzylamino)-5-(Trifluoromethyl)Pyrimidin-2-Ylamino)-3,4-Dihydroquinolin-2(1H)-One",
      "6-[4-(3-Methanesulfonyl-Benzylamino)-5-Trifluoromethyl-Pyrimidin-2-Ylamino]-3,4-Dihydro-1H-Quinolin-2-One",
    ],
  },
  {
    primary_id: "D02QBH",
    names: [
      "3-(6,7-Dimethoxy-Quinolin-4-Yloxy)-Phenylamine",
      "CHEMBL321572",
      "SCHEMBL7478985",
      "ZINC13859457",
      "BDBM50290846",
    ],
  },
  {
    primary_id: "D02QBJ",
    names: ["TIPLASININ"],
  },
  {
    primary_id: "D02QBT",
    names: [
      "AZETIRELIN",
      "Azetirelin",
      "YM-14673 (Dihydrate)",
      "Nalpha-[(S)-2-Azetidinone-4-Carbonyl]-L-Histidyl-L-Prolinamide",
    ],
  },
  {
    primary_id: "D02QBV",
    names: ["N2-{4-[(3-Fluorobenzyl)Oxy]benzyl}glycinamide", "CHEMBL231583", "SCHEMBL963386"],
  },
  {
    primary_id: "D02QCC",
    names: ["Erythribyssin M"],
  },
  {
    primary_id: "D02QCD",
    names: [
      "DEXMETHYLPHENIDATE HYDROCHLORIDE",
      "Dexmethylphenidate Hydrochloride",
      "Focalin",
      "D-Threo-Methylphenidate Hydrochloride",
      "Dexmethylphenidate HCl",
      "Focalin XR",
      "UNII-1678OK0E08",
      "Dexmethylphenidate Hydrochloride [USAN]",
      "19262-68-1",
      "1678OK0E08",
      "23655-65-4",
      "CHEMBL904",
      "Methyl (2R)-Phenyl((2R)-Piperidin-2-Yl)Acetate Hydrochloride",
      "Dl-Threo-Methylphenidate Hydrochloride",
      "2-Piperidineacetic Acid, Alpha-Phenyl-, Methyl Ester, Hydrochloride, (R-(R*,R*))-",
      "2-Piperidineacetic Acid, Alpha-Phenyl-, Methyl Ester, Hydrochloride, (AlphaR,2R)-",
      "Dexmethylphenidate",
      "NWP06",
    ],
  },
  {
    primary_id: "D02QCM",
    names: ["Calcitonin Human"],
  },
  {
    primary_id: "D02QDQ",
    names: ["QAX-576"],
  },
  {
    primary_id: "D02QDW",
    names: ["L-Norvalyl-L-Prolinamide"],
  },
  {
    primary_id: "D02QDX",
    names: ["Tie-1"],
  },
  {
    primary_id: "D02QEP",
    names: ["VU0361747"],
  },
  {
    primary_id: "D02QFM",
    names: ["3-Oxo-2,3-Dihydro-1H-Indazole-4-Carboxamide Derivative 4"],
  },
  {
    primary_id: "D02QFW",
    names: ["NNZ-2566"],
  },
  {
    primary_id: "D02QGI",
    names: ["Pentane-1,5-Diamine"],
  },
  {
    primary_id: "D02QGM",
    names: ["CGS-26529", "CGS-265529"],
  },
  {
    primary_id: "D02QHJ",
    names: ["Intratect"],
  },
  {
    primary_id: "D02QJE",
    names: ["PMID25666693-Compound-23"],
  },
  {
    primary_id: "D02QJH",
    names: [
      "Fluocinolone Acetonide",
      "Coriphate",
      "Cortiplastol",
      "Dermalar",
      "Flucinar",
      "Flucinolone",
      "Flucort",
      "Fluocet",
      "Fluocinolonacetonidum",
      "Fluonid",
      "Fluotrex",
      "Fluovitif",
      "Flupollon",
      "Jellin",
      "Localyn",
      "Medidur",
      "Omniderm",
      "Percutina",
      "Prodermin",
      "Radiocin",
      "Retisert",
      "Sinalar",
      "Synalar",
      "Synamol",
      "Synandone",
      "Synandrone",
      "Synemol",
      "Synotic",
      "Synsac",
      "Tefunote;Acetonida De Fluocinolona",
      "Acetonide De Fluocinolone",
      "Fluocinolone Acetonide [DCIT]",
      "Fluocinoloni Acetonidum",
      "Fluortriamcinolone Acetonide",
      "Fs Shampoo",
      "Localyn Syntex",
      "F0657",
      "RS 1401AT",
      "Acetonida De Fluocinolona [INN-Spanish]",
      "Acetonide De Fluocinolone [INN-French]",
      "Component Of Neo-Synalar",
      "Dermatin (Steroid)",
      "Fluocet (TN)",
      "Fluocinoloni Acetonidum [INN-Latin]",
      "Neo-Synalar",
      "RS-1401 AT",
      "Retisert (TN)",
      "Synalar (TN)",
      "Synalar-HP",
      "Derma-Smoothe/Fs",
      "Flucort-N (TN)",
      "Fluocinolone 16,17-Acetonide",
      "Fluocinolone Acetonide [USAN:INN:JAN]",
      "Ydroxyprednisolone 16,17-Acetonide",
      "Fluocinolone Acetonide (JP15/USP/INN)",
      "Pregna-1,4-Diene-3,20-Dione, 6alpha,9-Difluoro-11beta,16alpha,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone",
      "Pregna-1,4-Diene-3,20-Dione, 6alpha,9-Difluoro-11beta,16alpha,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone (8CI)",
      "Pregna-1,4-Diene-3,20-Dione, 6.alpha.,9-Difluoro-11.beta.,16.alpha.,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone",
      "(4aS,4bR,5S,6aS,6bS,9aR,10aS,10bS,12S)-4b,12-Difluoro-5-Hydroxy-6b-(Hydroxyacetyl)-4a,6a,8,8-Tetramethyl-4a,4b,5,6,6a,6b,9a,10,10a,10b,11,12-Dodecahydro-2H-Naphtho[2',1':4,5]indeno[1,2-D][1,3]dioxol-2-One",
      "4b,12-Difluoro-6b-Glycoloyl-5-Hydroxy-4a,6a,8,8-Tetramethyl-4a,4b,5,6,6a,6b,9a,10,10a,10b,11,12-Dodecahydro-2H-Naphtho[2',1':4,5]indeno[1,2-D][1,3]dioxol-2-One",
      "6-Alpha,9-Alpha-Difluoro-16-Alpha-Hydroxyprednisolone 16,17-Acetonide",
      "6.Alpha., 9.alpha.-Difluoro-16.alpha.-H",
      "6.Alpha.,9-Difluoro",
      "6.alpha.,9-Difluoro-11.beta.,16.alpha.,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione, Cyclic 16,17-Acetal With Acetone",
      "6.alpha.,9.alpha.-Difluoro-16.alpha.-Hydroxyprednisolone 16,17-Acetonide",
      "6.alpha.-Fluorotriamcinolone Acetonide",
      "6a,9a-Difluoro-16a-Hydroxyprednisolone 16,17-Acetonide",
      "6alpha,9-Difluoro-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione, Cyclic 16,17-Acetal With Acetone",
      "6alpha,9alpha-Difluoro-11beta,16alpha,17alpha,21-Tetrahydroxy-1,4-Pregnadiene-3,20-Dione",
      "6alpha,9alpha-Difluoro-11beta,16alpha,17alpha,21-Tetrahydroxy-1,4-Pregnadiene-3,20-Dione 16,17-Acetonide",
      "6alpha,9alpha-Difluoro-16alpha-Hydroxyprednisolone 16,17-Acetonide",
      "6alpha-Fluorotriamcinolone Acetonide",
    ],
  },
  {
    primary_id: "D02QJW",
    names: [
      "Clofilium",
      "68379-02-2",
      "4-(4-Chlorophenyl)Butyl-Diethyl-Heptylazanium",
      "UNII-847G178BMC",
      "4-Chloro-N,N-Diethyl-N-Heptylbenzenebutanaminium",
      "AC1L1EHQ",
      "CHEMBL9484",
      "Benzenebutanaminium, 4-Chloro-N,N-Diethyl-N-Heptyl-",
      "CHEBI:34649",
      "847G178BMC",
      "MLS002154263",
      "GNF-Pf-3889",
      "SMR001233522",
      "Prestwick2_000319",
      "Prestwick3_000319",
      "Prestwick1_000319",
      "BSPBio_000318",
      "SCHEMBL157537",
      "SPBio_002537",
      "Cid_175533",
      "GTPL2507",
      "BPBio1_000350",
      "DTXSID9048568",
      "CTK2F6670",
      "BDBM89440",
      "ZINC8035458",
      "NCGC00168464-02",
      "NCGC00168464-01",
      "68379-03-3 (Phosphate[1:1])",
      "LS-186732",
    ],
  },
  {
    primary_id: "D02QKC",
    names: ["PMID25435179-Compound-WO2012106509Salermide"],
  },
  {
    primary_id: "D02QLH",
    names: ["PTL-43401", "Antiangiogenic (Peptide, Cancer), Peptron"],
  },
  {
    primary_id: "D02QLQ",
    names: ["SRD-174"],
  },
  {
    primary_id: "D02QLY",
    names: ["2-Hydroxy-N,6-Bis(3-Hydroxyphenyl)-1-Naphthamide", "SCHEMBL3009043", "CHEMBL511606"],
  },
  {
    primary_id: "D02QME",
    names: ["2-(4-Hydroxy-Phenyl)-3,3-Diphenyl-Acrylonitrile", "CHEMBL305128"],
  },
  {
    primary_id: "D02QML",
    names: ["1-(3-(2-Methylquinolin-7-Yl)Phenyl)Ethanone", "CHEMBL233370"],
  },
  {
    primary_id: "D02QMQ",
    names: ["S-3578"],
  },
  {
    primary_id: "D02QMU",
    names: ["9-O-[3-(2-Pyridinoxyl)Butyl]-Berberine Bromide", "CHEMBL1163829"],
  },
  {
    primary_id: "D02QND",
    names: ["GF-011802-00"],
  },
  {
    primary_id: "D02QOD",
    names: ["Bombesin"],
  },
  {
    primary_id: "D02QOL",
    names: ["XMetD"],
  },
  {
    primary_id: "D02QOT",
    names: ["3-Amino-5-Phenylpentane"],
  },
  {
    primary_id: "D02QPP",
    names: [
      "(R,S)-(-)-Fenoterol",
      "CHEMBL229476",
      "(R,S)-Fenoterol",
      "AC1LEM4Z",
      "SCHEMBL9971022",
      "ZINC57319",
      "BDBM50213106",
      "5-[(1R)-1-Hydroxy-2-[[(2S)-1-(4-Hydroxyphenyl)Propan-2-Yl]amino]ethyl]benzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D02QPR",
    names: ["Manganese Chloride"],
  },
  {
    primary_id: "D02QQB",
    names: [
      "A-216546",
      "ABT-546",
      "(2S,3R,4S)-1-[2-(Dibutylamino)-2-Oxoethyl]-2-(2,2-Dimethylpentyl)-4-(7-Methoxy-1,3-Benzodioxol-5-Yl)Tetrahydro-1H-Pyrrole-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D02QQD",
    names: ["VU0010010"],
  },
  {
    primary_id: "D02QQE",
    names: ["NKTR 214"],
  },
  {
    primary_id: "D02QQT",
    names: [
      "Quattvaxem",
      "DTwPHib-CRM197 Conjugate Vaccine",
      "DPT-Hib Vaccine, Chiron",
      "DTP-Hib Vaccine, Chiron",
      "Diphtheria, Tetanus, Pertussis, Haemophilus Influenzae B Vaccine (CRM-197 Adjuvant), Chiron",
    ],
  },
  {
    primary_id: "D02QQV",
    names: ["ACAM-1000", "Acambis 1000", "Smallpox Vaccine, Acambis", "Smallpox Vaccine, OraVax"],
  },
  {
    primary_id: "D02QSH",
    names: ["NSC-368280"],
  },
  {
    primary_id: "D02QTB",
    names: [
      "JWH-307",
      "914458-26-7",
      "(5-(2-Fluorophenyl)-1-Pentyl-1H-Pyrrol-3-Yl)(Naphthalen-1-Yl)Methanone",
      "UNII-06QTR14ONW",
      "06QTR14ONW",
      "CHEMBL216857",
      "[5-(2-Fluorophenyl)-1-Pentyl-1H-Pyrrol-3-Yl](Naphthalen-1-Yl)Methanone",
      "SCHEMBL17464417",
      "CTK8C0835",
      "DTXSID60581542",
      "ZINC34947163",
      "5343AC",
      "ANW-65338",
      "BDBM50192607",
      "AKOS016005279",
      "AJ-90459",
      "TC-154083",
      "KB-208480",
      "AX8234389",
      "ST24034774",
      "FT-0747021",
      "(5-(2-Fluorophenyl)-1-Pentylpyrrol-3-Yl)-Naphthalen-1-Ylmethanone",
      "[5-(2-Fluorophenyl)-1-Pentyl-1H-Pyrrol-3-Yl]-1-Naphthalenyl-Methanone",
    ],
  },
  {
    primary_id: "D02QTE",
    names: [
      "ACDPP",
      "3-Amino-6-Chloro-5-(Dimethylamino)-N-(Pyridin-3-Yl)Pyrazine-2-Carboxamide",
      "GTPL3388",
      "CHEMBL1616372",
      "CHEBI:104024",
      "ZINC7992851",
      "BRD-K68633617-003-01-1",
    ],
  },
  {
    primary_id: "D02QTU",
    names: [
      "CRTh2 Receptor Antagonists, Novartis",
      "CRTh2 Receptor Antagonists (Inflammatory/Allergic Diseases), Novartis",
      "DP2 Receptor Antagonists (Inflammatory/Allergic Diseases), Novartis",
    ],
  },
  {
    primary_id: "D02QTV",
    names: [
      "Efegatran",
      "Efegatran [INN]",
      "UNII-VT0VK2474K",
      "CHEMBL273196",
      "VT0VK2474K",
      "105806-65-3",
      "L-Prolinamide, N-Methyl-D-Phenylalanyl-N-(4-((Aminoiminomethyl)Amino)-1-Formylbutyl)-, (S)-",
      "Me-Phe-Pro-Arg-H",
      "AC1L3UA7",
      "Me-(D-Phe)-Pro-Arg-CHO",
      "SCHEMBL635674",
      "SCHEMBL635675",
      "126721-07-1 (Sulfate)",
      "(S)-1-((R)-2-Methylamino-3-Phenyl-Propionyl)-Pyrrolidine-2-Carboxylic Acid ((S)-1-Formyl-4-Guanidino-Butyl)-Amide",
      "D-Methyl-Phenylalanyl-Prolyl-Arginal",
      "BDBM50228863",
      "LS-171983",
    ],
  },
  {
    primary_id: "D02QUB",
    names: ["GM-CT-02"],
  },
  {
    primary_id: "D02QUS",
    names: ["RP-52770"],
  },
  {
    primary_id: "D02QWB",
    names: [
      "[2-(Imidazol-1-Yl)Ethyl]-Bisphosphonic Acid",
      "CHEMBL446734",
      "1-Deshydroxyzoledronate",
      "SCHEMBL515542",
      "FBSQLNMIJFINOJ-UHFFFAOYSA-N",
      "BDBM50273714",
      "2-(Imidazol-1-Yl)Ethane-1,1-Diphosphonic Acid",
      "2-(1H-Imidazole-1-Yl)Ethylidenebisphosphonic Acid",
    ],
  },
  {
    primary_id: "D02QWJ",
    names: ["EREMOMYCIN"],
  },
  {
    primary_id: "D02QWK",
    names: ["SHP647"],
  },
  {
    primary_id: "D02QWL",
    names: ["ISIS 20995"],
  },
  {
    primary_id: "D02QWO",
    names: ["HOMOAROMOLINE"],
  },
  {
    primary_id: "D02QWP",
    names: ["N-Arylmethyl-N-Phenyl Cyclic Urea Derivative 2"],
  },
  {
    primary_id: "D02QWY",
    names: [
      "BMS-754807",
      "1001350-96-4",
      "BMS 754807",
      "BMS754807",
      "UNII-W9E3353E8J",
      "CHEMBL575448",
      "CHEBI:88339",
      "W9E3353E8J",
      "1-{4-[(3-Cyclopropyl-1H-Pyrazol-5-Yl)Amino]pyrrolo[2,1-F][1,2,4]triazin-2-Yl}-N-(6-Fluoropyridin-3-Yl)-2-Methyl-L-Prolinamide",
      "W-204348",
      "J-501009",
      "2-Pyrrolidinecarboxamide, 1-[4-[(5-Cyclopropyl-1H-Pyrazol-3-Yl)Amino]pyrrolo[2,1-F][1,2,4]triazin-2-Yl]-N-(6-Fluoro-3-Pyridinyl)-2-Methyl-, (2S)-;2-Pyrrolidinecarboxamide, 1-[4-[(5-Cyclopropyl-1H-Pyrazol-3-Yl)Amino]pyrrolo[2,1-F][1,2,4]triazin-2-Yl]-N-(6-Fluoro-3-Pyridin",
    ],
  },
  {
    primary_id: "D02QXF",
    names: ["8-(1-Methyl-Butoxy)-Quinolin-2-Ylamine", "CHEMBL187702", "SCHEMBL5884359"],
  },
  {
    primary_id: "D02QXI",
    names: ["ST-04"],
  },
  {
    primary_id: "D02QXT",
    names: [
      "8-Methoxy-1,2,3,4-Tetrahydro-Benzo[h]isoquinoline",
      "CHEMBL338311",
      "8-Methoxy-1,2,3,4-Tetrahydrobenzo[h]isoquinoline",
      "BDBM50061314",
      "ZINC13766766",
    ],
  },
  {
    primary_id: "D02QYY",
    names: ["LY01005"],
  },
  {
    primary_id: "D02QZF",
    names: ["2-Benzyl-2H-Indazole-7-Carboxamide", "CHEMBL1094951", "SCHEMBL2268172"],
  },
  {
    primary_id: "D02QZL",
    names: ["PNQX"],
  },
  {
    primary_id: "D02QZR",
    names: ["LY-545694"],
  },
  {
    primary_id: "D02RAI",
    names: ["ABX-PTH"],
  },
  {
    primary_id: "D02RAN",
    names: ["Imetit"],
  },
  {
    primary_id: "D02RAU",
    names: ["KRP-297"],
  },
  {
    primary_id: "D02RAY",
    names: ["GLAUCINE"],
  },
  {
    primary_id: "D02RBB",
    names: ["SUPERVAX"],
  },
  {
    primary_id: "D02RBK",
    names: [
      "9-Benzyl-8-Bromo-9H-Adenine",
      "9-Benzyl-8-Bromoadenine",
      "CHEMBL151071",
      "9-Benzyl-8-Bromo-9H-Purin-6-Amine",
      "56046-34-5",
      "8-Bromo-9-Benzyladenine",
      "N9-Benzyl-8-Bromoadenine",
      "8-Bromo-N9-Benzyladenine",
      "SCHEMBL4341941",
      "OUPAEJUFVKPEHM-UHFFFAOYSA-N",
      "BDBM50240851",
      "6-Amino-8-Bromo-9-Benzyl-9H-Purine",
      "9-Benzyl-8-Bromo-9H-Purin-6-Ylamine",
      "9-Benzyl-8-Bromo-9H-Purin-6-Yl Amine",
    ],
  },
  {
    primary_id: "D02RBQ",
    names: [
      "NG-2",
      "DG-2",
      "NG2-GAD",
      "Nurel-N1",
      "Glutamic Acid Decarboxylase-Based Pain Therapy, Diamyd",
      "GAD-Based Gene Therapy (Pain, NTDDS),Diamyd",
      "Neurotrophic Factor-Expressing Gene Therapies (HSV Carrier, Neuropathy), Nurel",
      "Neurotrophic Factor-Expressing Gene Therapies (HSV Carrier, Neuropathy/Shingles), Diamyd",
    ],
  },
  {
    primary_id: "D02RBU",
    names: [
      "4-Butoxy-N-Hydroxy-N-Methyl-Benzamide",
      "CHEMBL177392",
      "Benzamide, 4-Butoxy-N-Hydroxy-N-Methyl-",
      "BDBM50015090",
      "4-Butoxy-N-Methylbenzohydroxamic Acid",
    ],
  },
  {
    primary_id: "D02RCV",
    names: ["BVF-247", "Cardiovascular Therapeutic, Biovail", "Cardiovascular Therapeutic, Pharma Pass"],
  },
  {
    primary_id: "D02RCY",
    names: ["PMID29649907-Compound-41"],
  },
  {
    primary_id: "D02RDC",
    names: ["NV.XOD.09"],
  },
  {
    primary_id: "D02RDE",
    names: [
      "JTV-605",
      "CHEMBL430083",
      "BDBM50137940",
      "L001683",
      "Biphenyl-2-Carboxylic Acid [2-[4-(4-Dimethylamino-Piperidin-1-Yl)-4-Oxo-Butoxy]-4-(5,6,7,8-Tetrahydro-Thieno[3,2-B]azepine-4-Carbonyl)-Phenyl]-Amide",
    ],
  },
  {
    primary_id: "D02RDX",
    names: ["XP-23829"],
  },
  {
    primary_id: "D02RDY",
    names: ["PMID15664838C18"],
  },
  {
    primary_id: "D02RED",
    names: ["DCVax-Brain", "Dendritic Cell-Based Immunotherapy (Brain Cancer), Northwest Biotherapeutics"],
  },
  {
    primary_id: "D02REJ",
    names: ["JNJ-17216498"],
  },
  {
    primary_id: "D02REM",
    names: ["DPI-221"],
  },
  {
    primary_id: "D02REO",
    names: ["TDI-0050"],
  },
  {
    primary_id: "D02RFA",
    names: [
      "Alkylisothioureas",
      "Alkylisothioureas (Neurological Diseases)",
      "NT-0904",
      "Alkylisothioureas (Neurological Diseases), Medivation",
    ],
  },
  {
    primary_id: "D02RGB",
    names: ["LY339434"],
  },
  {
    primary_id: "D02RGZ",
    names: ["MKC-204"],
  },
  {
    primary_id: "D02RHC",
    names: ["ET190"],
  },
  {
    primary_id: "D02RHJ",
    names: ["Gene-Based Dengue Fever Therapeutics"],
  },
  {
    primary_id: "D02RHU",
    names: ["SF-111"],
  },
  {
    primary_id: "D02RHV",
    names: ["PROXODOLOL"],
  },
  {
    primary_id: "D02RID",
    names: ["KX-02"],
  },
  {
    primary_id: "D02RIE",
    names: ["4-(2,4-Dichlorophenyl)-1-Tosylpyrrolidin-3-Amine", "CHEMBL245552"],
  },
  {
    primary_id: "D02RIG",
    names: [
      "2-(4,5-Dihydroxy-Indan-1-Ylidene)-Malononitrile",
      "(4,5-Dihydroxyindanylidene)Malononitrile",
      "Tyrphostin Deriv. 17",
      "AC1NS59O",
      "CHEMBL57300",
      "BDBM4286",
      "133550-13-7",
      "2-(4,5-Dihydroxy-2,3-Dihydroinden-1-Ylidene)Propanedinitrile",
      "2-(4,5-Dihydroxy-2,3-Dihydro-1H-Inden-1-Ylidene)Propanedinitrile",
    ],
  },
  {
    primary_id: "D02RIH",
    names: [
      "(-)-(S)-BayK8644",
      "98625-26-4",
      "(S)-(-)-BAY K 8644",
      "Bay-K-8644 ((S)-(-)-)",
      "Bay-K-8644 (S)-(-)-",
      "(S)-(-)-Bay K8644",
      "Methyl (4S)-2,6-Dimethyl-5-Nitro-4-[2-(Trifluoromethyl)Phenyl]-1,4-Dihydropyridine-3-Carboxylate",
      "S-(-)-1,4-Dihydro-2,6-Dimethyl-5-Nitro-4-[2-(Trifluoromethyl)Phenyl]-3-Pyridinecarboxylic Acid Methyl Ester",
      "CHEMBL283013",
      "Tocris-1544",
      "Lopac-B-112",
      "(S)-BayK8644",
      "GTPL4065",
      "AC1O7G05",
      "SCHEMBL2845539",
      "(S)-Bay K8644",
      "CHEBI:93962",
      "(S)-Bay-K-8644",
      "DTXSID50424954",
      "MolPort-003-940-398",
      "CHEBI:131347",
    ],
  },
  {
    primary_id: "D02RIV",
    names: ["Hydroxystilbamidine Isethionate"],
  },
  {
    primary_id: "D02RJA",
    names: ["Sulfamic Acid 3-(3-Methoxy-Benzoyl)-Phenyl Ester", "CHEMBL305077"],
  },
  {
    primary_id: "D02RJY",
    names: [
      "PHA-739358",
      "Danusertib",
      "PHA 739358",
      "Danusertib, PHA-739358",
      "(R)-N-(5-(2-Methoxy-2-Phenylacetyl)-1,4,5,6-Tetrahydropyrrolo[3,4-C]pyrazol-3-Yl)-4-(4-Methylpiperazin-1-Yl)Benzamide",
      "5-Amido-Pyrrolopyrazole 9d",
    ],
  },
  {
    primary_id: "D02RKG",
    names: ["MORAb-004"],
  },
  {
    primary_id: "D02RLS",
    names: ["R-1554"],
  },
  {
    primary_id: "D02RMR",
    names: [
      "1,3-Diethyl-3,7-Dihydro-Purine-2,6-Dione",
      "1,3-Diethylxanthine",
      "SC 2079",
      "5169-95-9",
      "1,3-Diethyl-3,9-Dihydro-1h-Purine-2,6-Dione",
      "CHEMBL416013",
      "UNII-4550Q64O79",
      "4550Q64O79",
      "1,3-Diethylxantine",
      "Xanthine,3-Diethyl-",
      "NSC 515479",
      "1,3-Diethyl-Xanthine",
      "AC1L2QSV",
      "AC1Q6LGQ",
      "1,3-Diethyl-1H-Purine-2,6(3H,7H)-Dione",
      "SCHEMBL515564",
      "BDBM82021",
      "CTK8I9744",
      "CTK8D7769",
      "DTXSID40199644",
      "1H-Purine-2,6-Dione, 1,3-Diethyl-3,7-Dihydro- (9CI)",
      "GIMNFISWKTZFKJ-UHFFFAOYSA-N",
      "Xanthine, 1,3-Diethyl- (8CI)",
      "NSC515479",
      "1,3-Diethyl-7H-Purine-2,6-Dione",
      "AKOS030616954",
      "NSC_101420",
      "NSC-515479",
      "CAS_101420",
    ],
  },
  {
    primary_id: "D02RMS",
    names: [
      "Ethyl-(4,5,6,7-Tetrahydro-2H-Indazol-5-Yl)-Amine",
      "CHEMBL77421",
      "2H-Indazol-5-Amine, N-Ethyl-4,5,6,7-Tetrahydro-",
      "121867-55-8",
    ],
  },
  {
    primary_id: "D02RNH",
    names: ["Se-Ethyl-Isoselenourea"],
  },
  {
    primary_id: "D02RNJ",
    names: [
      "SK&F-104662",
      "(3S,6R,7R,22R,23S,26S,36R,38aR)-22-(3-Amino-2,3,6-Trideoxy-Beta-L-Allopyranosyloxy)-19-Chloro-44-(Beta-D-Glucopyranosyloxy)-7,28,30,32-Tetrahydroxy-3-[2-(Methylsulfanyl)Ethyl]-6-(Nalpha-Methyl-D-Tyros",
    ],
  },
  {
    primary_id: "D02RNR",
    names: ["LB-100"],
  },
  {
    primary_id: "D02RNS",
    names: ["Oglufanide"],
  },
  {
    primary_id: "D02ROU",
    names: ["ASP-1707"],
  },
  {
    primary_id: "D02RPK",
    names: ["Z 360"],
  },
  {
    primary_id: "D02RPP",
    names: ["TPI ASM8"],
  },
  {
    primary_id: "D02RQP",
    names: ["Bisphenol A", "BPA"],
  },
  {
    primary_id: "D02RQT",
    names: ["TENIDAP"],
  },
  {
    primary_id: "D02RQU",
    names: ["Pravastatin"],
  },
  {
    primary_id: "D02RRE",
    names: ["Pyrazole Derivative 78"],
  },
  {
    primary_id: "D02RRR",
    names: ["TRPV1 Inhibitor (Pain)"],
  },
  {
    primary_id: "D02RRX",
    names: ["SILTENZEPINE"],
  },
  {
    primary_id: "D02RRY",
    names: ["BI6015", "BI 6015", "BI-6015"],
  },
  {
    primary_id: "D02RSN",
    names: [
      "Levofloxacin",
      "Aeroquin",
      "Cravit",
      "Elequine",
      "Floxacin",
      "Floxel",
      "Iquix",
      "LFX",
      "LVX",
      "Leroxacin",
      "Lesacin",
      "Levaquin",
      "Levofloxacine",
      "Levofloxacino",
      "Levofloxacinum",
      "Levokacin",
      "Levox",
      "Levoxacin",
      "Mosardal",
      "Nofaxin",
      "Oftaquix",
      "Quixin",
      "Reskuin",
      "Tavanic",
      "Volequin",
      "Cravit Ophthalmic",
      "DR 3354",
      "DR3355",
      "HR 355",
      "Cravit (TN)",
      "D-Levofloxacin",
      "DR-3355",
      "HR-355",
      "Iquix (TN)",
      "L-Ofloxacin",
      "LEVAQUIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
      "Levaquin (TN)",
      "Levofloxacin (INN)",
      "Levofloxacin Tablet, Suspension Or Intravenous",
      "Levofloxacine [INN-French]",
      "Levofloxacino [INN-Spanish]",
      "Levofloxacinum [INN-Latin]",
      "MP-376",
      "Oftaquix (TN)",
      "Quixin (TN)",
      "R-Ofloxacin",
      "RWJ 25213-097",
      "RWJ-25213",
      "Tavanic (TN)",
      "Levofloxacin [USAN:INN:JAN]",
      "DR-3355: L-Isomer Of Ofloxacin",
      "Ofloxacin S-(-)-Form",
      "S-(-)-Ofloxacin",
      "(-)-(S)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyrido(1,2,3-De)-1,4-Benzoxazine-6-Carboxylic Acid",
      "(-)-(S)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyridol[1,2,3-De]-1,4-Benzoxazine-6-Carboxylic Acid Hemihydrate",
      "(-)-(S)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7Hpyrido[1,2,3-De]-1,4-Benzoxazine-6-Carboxylic Acid Hemihydrate",
      "(3S)-9-Fluoro-3-Methyl-10-(4-Methylpiperazin-1-Yl)-7-Oxo-2,3-Dihydro-7H-[1,4]oxazino[2,3,4-Ij]quinoline-6-Carboxylic Acid",
      "(R)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyrido(1,2,3-De)-1,4-Benzoxazine-6-Carboxylic Acid",
      "(R)-Isomer",
      "(S)-(-)-Ofloxacin",
      "(S)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyrido(1,2,3-De)-1,4-Benzoxazine-6-Carboxylic Acid",
      "(S)-Ofloxacin",
    ],
  },
  {
    primary_id: "D02RTS",
    names: [
      "Citric Acid",
      "77-92-9",
      "Citric Acid, Anhydrous",
      "Citrate",
      "Citro",
      "Anhydrous Citric Acid",
      "Aciletten",
      "Citretten",
      "Chemfill",
      "Hydrocerol A",
      "1,2,3-Propanetricarboxylic Acid, 2-Hydroxy-",
      "Kyselina Citronova",
      "2-Hydroxy-1,2,3-Propanetricarboxylic Acid",
      "2-Hydroxytricarballylic Acid",
      "Citric Acid Anhydrous",
      "Caswell No 221C",
      "F 0001 (Polycarboxylic Acid)",
      "3-Carboxy-3-Hydroxypentane-1,5-Dioic Acid",
      "2-Hydroxypropanetricarboxylic Acid",
      "Beta-Hydroxytricarballylic Acid",
      "FEMA Number 2306",
      "K-Lyte",
    ],
  },
  {
    primary_id: "D02RUO",
    names: ["ONO-8815Ly"],
  },
  {
    primary_id: "D02RUV",
    names: [
      "3-Isopropyl-3-Methyl-Dihydro-Furan-2-One",
      "132462-11-4",
      "Alpha-Imgbl",
      "2(3H)-Furanone, Dihydro-3-Methyl-3-(1-Methylethyl)-",
      "3-Methyl-3-Propan-2-Yloxolan-2-One",
      "Alpha-Isopropyl-Alpha-Methyl-Gamma-Butyrolactone",
      "AC1L2ZBI",
      "CHEMBL35007",
      "SCHEMBL10171772",
    ],
  },
  {
    primary_id: "D02RUZ",
    names: [
      "A-922500",
      "DGAT-1 Inhibitors (Dyslipidemia/Obesity)",
      "Diacylglycerol Acyltransferase (Dyslipidemia/Obesity) Inhibitors, Abbott",
      "DGAT-1 Inhibitors (Dyslipidemia/Obesity), Abbott",
    ],
  },
  {
    primary_id: "D02RVC",
    names: ["PPI-383"],
  },
  {
    primary_id: "D02RVE",
    names: ["TA-7552"],
  },
  {
    primary_id: "D02RVN",
    names: ["Selurampanel", "BGG-492", "BGG-492A", "AMPA Antagonist (Oral, Epilepsy), Novartis"],
  },
  {
    primary_id: "D02RWC",
    names: ["GNF-5837"],
  },
  {
    primary_id: "D02RWK",
    names: ["K-252a Analogue"],
  },
  {
    primary_id: "D02RWL",
    names: [
      "BIPHENYL-4-YL-ACETALDEHYDE",
      "2-([1,1'-Biphenyl]-4-Yl)Acetaldehyde",
      "61502-90-7",
      "2-(4-Phenylphenyl)Acetaldehyde",
      "4-Biphenylacetaldehyde",
      "2-{[1,1'-Biphenyl]-4-Yl}acetaldehyde",
      "AC1MRDQD",
      "(Biphenyl-4-Yl)Ethanone",
      "SCHEMBL850634",
      "2-(Biphenyl-4-Yl)Acetaldehyde",
      "CTK2D8632",
      "DTXSID30392980",
      "OIDMZCMVYZLDLI-UHFFFAOYSA-N",
      "MolPort-020-915-677",
      "[1,1'-Biphenyl]-4-Acetaldehyde",
      "ZINC2581188",
      "AKOS006278290",
      "AS-49992",
      "BP4",
    ],
  },
  {
    primary_id: "D02RXA",
    names: ["LVAYPWT", "CHEMBL395724"],
  },
  {
    primary_id: "D02RXG",
    names: ["Nalfurafine Hcl"],
  },
  {
    primary_id: "D02RXZ",
    names: ["LIM-Kinase 2 Inhibitors"],
  },
  {
    primary_id: "D02RYE",
    names: ["AMA-237", "AMA-63", "ROCK Inhibitors (COPD)", "ROCK Inhibitors (COPD), Amakem"],
  },
  {
    primary_id: "D02RYR",
    names: ["Phosphoric Acid Monodec-9-Enyl Ester"],
  },
  {
    primary_id: "D02RYZ",
    names: ["MCL-450", "CHEMBL373984", "BDBM50194274"],
  },
  {
    primary_id: "D02RZC",
    names: [
      "ASC-JMX2",
      "ASCJ-M12",
      "ASCJ-M5",
      "Q-103",
      "Q-49",
      "Androgen Antagonist (Prostate Tumor/Benign Prostatic Hyperplasia), AndroScience",
      "ARD Enhancers (Oral, Prostate Tumor/Benign Prostatic Hyperplasia), AndroScience",
    ],
  },
  {
    primary_id: "D02RZH",
    names: ["3-Cyano-5-Fluoro-N-(3-Fluorophenyl)Benzamide", "CHEMBL1209394"],
  },
  {
    primary_id: "D02RZL",
    names: [
      "AZD4769",
      "Acetic Acid, ((4-Phenyl-1-(4-(2-Quinolinylmethoxy)Phenyl)Butyl)Thio)-",
      "127481-29-2",
      "L-674573",
      "((4-Phenyl-1-(4-(2-Quinolinylmethoxy)Phenyl)Butyl)Thio)Acetic Acid",
      "{[4-Phenyl-1-(4-(2-Quinolinylmethoxy)Phenyl)Butyl]thio}acetic Acid",
      "L 674573",
      "L-674,573",
      "AC1L3YFJ",
      "SCHEMBL9460146",
      "CHEMBL422872",
      "JOIXGLLMSDPZDN-UHFFFAOYSA-N",
      "AZD-4769",
      "2-[[4-Phenyl-1-[4-(2-Quinolinylmethoxy)Phenyl]butyl]thio]Acetic Acid",
      "DA-13018",
      "FT-0734991",
      "AZ-12096971",
      "2-[4-Phenyl-1-[4-(Quinolin-2-Ylmethoxy)Phenyl]butyl]sulfanylacetic",
    ],
  },
  {
    primary_id: "D02RZV",
    names: ["ARQ-151 Cream"],
  },
  {
    primary_id: "D02SAC",
    names: ["Anti-CD123 CAR-T Cells"],
  },
  {
    primary_id: "D02SAD",
    names: ["3-(2-(4-Methoxycinnamyl)Phenyl)Acrylic Acid", "CHEMBL219590"],
  },
  {
    primary_id: "D02SAE",
    names: ["Sanfetrinem Cilexetil"],
  },
  {
    primary_id: "D02SAF",
    names: [
      "1-(2,5-Dimethoxy-Phenyl)-Piperazine",
      "1-(2,5-Dimethoxyphenyl)Piperazine",
      "CHEMBL156216",
      "1019-06-3",
      "Piperazine, 1-(2,5-Dimethoxyphenyl)-",
      "AC1MBY8N",
      "Oprea1_560724",
      "SCHEMBL2349863",
      "CTK0G7924",
      "DTXSID10374340",
      "IZFHRJZAZNTUOI-UHFFFAOYSA-N",
      "4-[2,5-Dimethoxyphenyl]piperazine",
      "ZINC11632569",
      "BDBM50226212",
      "MFCD03444397",
      "AKOS003591177",
      "DB-017049",
    ],
  },
  {
    primary_id: "D02SAK",
    names: ["2NTX-99"],
  },
  {
    primary_id: "D02SAM",
    names: ["Bone Marrow Therapy, Oral, University Of North Carolina", "Bone Marrow Therapy (Oral)"],
  },
  {
    primary_id: "D02SBM",
    names: ["6-(Piperazin-1-Yl)-3-Tosyl-1H-Indazole", "CHEMBL1277286"],
  },
  {
    primary_id: "D02SBQ",
    names: [
      "Urofollitropin",
      "Bravelle",
      "Fertinex",
      "Metrodin",
      "Urofollitrophin",
      "Fertinorm HP",
      "Bravelle (TN)",
      "Fertinex (TN)",
      "Follistim (TN)",
      "Gonal-F(TN)",
      "(4-Threonine)Oxytocin",
      "1,2-Dithia-5,8,11,14,17-Penaazacycloeicosane, Cyclic Peptide Deriv.",
      "4-(L-Threonine)Oxytocin",
    ],
  },
  {
    primary_id: "D02SDC",
    names: ["Inactine"],
  },
  {
    primary_id: "D02SDD",
    names: ["ISIS 183882"],
  },
  {
    primary_id: "D02SDM",
    names: ["LU32-176B"],
  },
  {
    primary_id: "D02SDT",
    names: ["3-(Dodecylsulfonyl)-1,1,1-Trifluoropropan-2-One", "CHEMBL257071"],
  },
  {
    primary_id: "D02SDX",
    names: [
      "N,N-Dimethyl-3,3-Diphenylpropan-1-Amine",
      "3,3-Diphenyl-N-Dimethylpropylamine",
      "4646-55-3",
      "Oprea1_032403",
      "AC1Q3WP9",
      "AC1L45WF",
      "Diphenylalkylamine (DPA), 5c",
      "SCHEMBL1802493",
      "3,3-Diphenylpropyldimethylamine",
      "CHEMBL610739",
      "CTK1C5217",
      "BDBM35934",
      "DTXSID90196845",
      "(3,3-Diphenylpropyl)Dimethylamine",
      "ZINC1679452",
      "3,3-Diphenyl-N,N-Dimethylpropylamine",
      "AKOS016035475",
      "Benzenepropanamine, N,N-Dimethyl-Gamma-Phenyl-",
    ],
  },
  {
    primary_id: "D02SDY",
    names: ["NaBen"],
  },
  {
    primary_id: "D02SES",
    names: ["Peptide Analog 2"],
  },
  {
    primary_id: "D02SEU",
    names: ["PT-107"],
  },
  {
    primary_id: "D02SFJ",
    names: ["4-(10H-Anthracen-9-Ylidene)-1-Methyl-Piperidine", "CHEMBL348459", "SCHEMBL13921290", "BDBM50097218"],
  },
  {
    primary_id: "D02SGH",
    names: [
      "Methyl 7-Tert-Butyl-9H-Carbazole-3-Carboxylate",
      "CHEMBL1173167",
      "BDBM50322578",
      "ZINC53299021",
      "7-Tert-Butyl-9H-Carbazole-3-Carboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D02SGJ",
    names: ["PALMATINE"],
  },
  {
    primary_id: "D02SGL",
    names: ["PMID26815044-Compound-49"],
  },
  {
    primary_id: "D02SGT",
    names: ["OM-197-MP-AC"],
  },
  {
    primary_id: "D02SHI",
    names: ["M8891"],
  },
  {
    primary_id: "D02SIT",
    names: [
      "1-(9-Phenyl-9H-Fluoren-9-Yl)1H-Imidazole",
      "CHEMBL223874",
      "1H-Imidazole, 1-(9-Phenyl-9H-Fluoren-9-Yl)-",
      "27608-12-4",
      "Fluorenyl Deriv. 14",
      "BDBM9465",
      "CTK0J2505",
      "DTXSID90432952",
    ],
  },
  {
    primary_id: "D02SJB",
    names: ["Imidazopyridazine Derivative 8"],
  },
  {
    primary_id: "D02SJG",
    names: ["VT-362", "CD30 Homologue (Inflammation), Viron"],
  },
  {
    primary_id: "D02SJN",
    names: ["VBP"],
  },
  {
    primary_id: "D02SKA",
    names: [
      "FCE-22716",
      "1-(6-Methylergolin-8beta-Yl)Hydantoin",
      "1-[[(8beta)-6-Methylergolin-8-Yl]methyl]-2,4-Imidazolidinedione",
      "7beta-(2,4,-Dioxo-1-Imidazolidinylmethyl)-9-Methyl-2,5balpha,6,7,8,9,9abeta,10-Octahydroindolo[4,3a,3-F,g]quinoline",
      "8beta-(2,4-Dioxo-1-Imidazolidinylmethyl)-6-Methylergoline",
    ],
  },
  {
    primary_id: "D02SKN",
    names: [
      "MRS1041",
      "3,5,7-Triethoxyflavone",
      "MRS-1041",
      "CHEMBL72862",
      "3,5,7-Triethoxy-2-Phenyl-Chromen-4-One",
      "GTPL392",
      "SCHEMBL1663784",
      "BDBM50051347",
    ],
  },
  {
    primary_id: "D02SKQ",
    names: ["PMID29334795-Compound-28"],
  },
  {
    primary_id: "D02SLV",
    names: ["CB-1922"],
  },
  {
    primary_id: "D02SME",
    names: ["INO-4885"],
  },
  {
    primary_id: "D02SMG",
    names: ["2-(Furan-2-Yl)-6-Morpholino-4H-Pyran-4-One", "CHEMBL223775"],
  },
  {
    primary_id: "D02SMK",
    names: ["CID1172084", "CID 1172084"],
  },
  {
    primary_id: "D02SMV",
    names: ["Herpes Simplex Virus Therapy"],
  },
  {
    primary_id: "D02SNE",
    names: ["NeuGene"],
  },
  {
    primary_id: "D02SNJ",
    names: ["SPD602"],
  },
  {
    primary_id: "D02SNO",
    names: ["2-Phenyl-3-(1-Phenyl-Ethoxy)-Piperidine", "CHEMBL103657"],
  },
  {
    primary_id: "D02SNQ",
    names: ["Pyrazole Derivative 8"],
  },
  {
    primary_id: "D02SNU",
    names: ["NE97220"],
  },
  {
    primary_id: "D02SNY",
    names: ["WF-10"],
  },
  {
    primary_id: "D02SOL",
    names: ["GF-15"],
  },
  {
    primary_id: "D02SPI",
    names: ["Chalcone Derivative 4"],
  },
  {
    primary_id: "D02SPX",
    names: ["IDX899"],
  },
  {
    primary_id: "D02SQF",
    names: [
      "4-Methylhistamine",
      "5-Methylhistamine",
      "36507-31-0",
      "5(4)-Methylhistamine",
      "1H-Imidazole-4-Ethanamine, 5-Methyl-",
      "2-(5-Methyl-1H-Imidazol-4-Yl)Ethanamine",
      "UNII-54ST71P9EE",
      "1H-Imidazo-4-Ethanamine, 5-Methyl-",
      "54ST71P9EE",
      "CHEBI:74760",
      "IMIDAZOLE, 4-(2-AMINOETHYL)-5-METHYL-",
      "2-(5-Methyl-4-Imidazolyl)Ethylamine",
      "2-(4-METHYL-1H-IMIDAZOL-5-YL)ETHANAMINE",
      "P-Methylhistamine",
      "Methylhistamine-4",
      "5-(2-Aminoethyl)-4-Methylimidazole",
      "AC1L1XS2",
      "SCHEMBL606803",
      "CHEMBL275443",
      "GTPL1269",
      "SCHEMBL11452941",
      "BDBM22880",
      "UGYXPZQILZRKJJ-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D02SQJ",
    names: ["HyperAcute Melanoma"],
  },
  {
    primary_id: "D02SQQ",
    names: ["PMID27967267-Compound-13"],
  },
  {
    primary_id: "D02SQZ",
    names: ["Kibow Biotics"],
  },
  {
    primary_id: "D02SRA",
    names: ["Y-C[D-Pen-D-(2')Nal-GSFC]KR-NH2", "CHEMBL404595", "BDBM50064829"],
  },
  {
    primary_id: "D02SRB",
    names: ["BPL-001"],
  },
  {
    primary_id: "D02SRL",
    names: [
      "4-Phenylthiazole-2-Thiol",
      "2103-88-0",
      "2-Mercapto-4-Phenylthiazole",
      "4-Phenyl-2-Thiazolethiol",
      "4-Phenyl-1,3-Thiazole-2-Thiol",
      "2(3H)-Thiazolethione, 4-Phenyl-",
      "4-Phenyl-2-Mercaptothiazole",
      "4-Thiazoline-2-Thione, 4-Phenyl-",
      "2-Thiazolethiol, 4-Phenyl-",
      "4-Phenyl-Thiazole-2-Thiol",
      "CHEMBL571436",
      "4-Phenylthiazole-2(3H)-Thione",
      "NSC 678",
      "CYCKHTAVNBPQDB-UHFFFAOYSA-N",
      "EINECS 218-274-7",
      "4-Phenyl-3H-1,3-Thiazole-2-Thione",
      "NSC678",
      "Zlchem 830",
      "AC1MHDCC",
      "ACMC-1CJ8W",
      "2-Mercapto-4-Phenylthiazol",
      "MLS001006837",
      "SCHEMBL178051",
      "SCHEMBL11460891",
    ],
  },
  {
    primary_id: "D02SSD",
    names: ["7-(2-Methoxyphenyl)-2-Methylquinoline", "CHEMBL231991"],
  },
  {
    primary_id: "D02SSG",
    names: ["KYS-05077"],
  },
  {
    primary_id: "D02SSV",
    names: ["ALN-RSV02", "ALN-RSV (Second-Generation), Alnylam/Kyowa Hakko Kirin/Cubist"],
  },
  {
    primary_id: "D02STG",
    names: ["ONO-4232"],
  },
  {
    primary_id: "D02STN",
    names: ["ABIRATERONE", "Abiraterone (AR Inhibitor)"],
  },
  {
    primary_id: "D02SUB",
    names: ["SWT-06101"],
  },
  {
    primary_id: "D02SUP",
    names: ["TT-114"],
  },
  {
    primary_id: "D02SUR",
    names: ["L-697,661"],
  },
  {
    primary_id: "D02SUS",
    names: ["Imidazo Cyclohexylamine Derivative 1"],
  },
  {
    primary_id: "D02SUX",
    names: ["Small Molecule 19a"],
  },
  {
    primary_id: "D02SVQ",
    names: ["HBI-3000"],
  },
  {
    primary_id: "D02SWB",
    names: ["Grass Pollen-Derived Peptides"],
  },
  {
    primary_id: "D02SWF",
    names: ["(1R,2R)-2-(1H-Imidazol-4-Yl)-1-Methyl-Propylamine", "CHEMBL40378", "BDBM50034706", "AKOS006360607"],
  },
  {
    primary_id: "D02SXZ",
    names: ["S-707106"],
  },
  {
    primary_id: "D02SYQ",
    names: ["Sm-14"],
  },
  {
    primary_id: "D02SYR",
    names: ["Peptide Analog 27"],
  },
  {
    primary_id: "D02SYV",
    names: ["Lenzilumab"],
  },
  {
    primary_id: "D02SZC",
    names: ["Natural Product Anti-Infective Therapeutics"],
  },
  {
    primary_id: "D02SZF",
    names: ["18F-SMIBR-K5"],
  },
  {
    primary_id: "D02TAB",
    names: ["CJ-50300", "CJ-53300", "Smallpox Vaccine, CJ Corporation"],
  },
  {
    primary_id: "D02TBI",
    names: [
      "Carbenicillin",
      "CBPC",
      "Carbenicilina",
      "Carbenicillina",
      "Carbenicilline",
      "Carbenicillinum",
      "Carboxybenzylpenicillin",
      "Pyopen",
      "Carbenicillina [DCIT]",
      "Carboxybenzyl Penicillin",
      "Carboxybenzylpenicillin Acid",
      "Alpha-Carboxybenzylpencillin",
      "Alpha-Carboxybenzylpenicillin Solution",
      "Carbenicilina [INN-Spanish]",
      "Carbenicillin (INN)",
      "Carbenicillin [INN:BAN]",
      "Carbenicilline [INN-French]",
      "Carbenicillinum [INN-Latin]",
      "Alpha-Phenyl(Carboxymethylpenicillin)",
      "N-(2-Carboxy-3,3-Dimethyl-7-Oxo-4-Thia-Azabicyclo(3.2.0)Hept-6-Yl)-2-Phenylmalonamic Acid;N-(2-Carboxy-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Hept-6-Yl)-2-Phenylmalonamic Acid",
      "(2S,5R,6R)-6-[(3-Hydroxy-3-Oxo-2-Phenylpropanoyl)Amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-{[carboxy(Phenyl)Acetyl]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "6-(Alpha-Carboxyphenylacetamido)Penicillanic Acid",
      "6beta-(2-Carboxy-2-Phenylacetamido)-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D02TBU",
    names: ["CIGB-552"],
  },
  {
    primary_id: "D02TCF",
    names: ["C-16-(S)-3-Methylindolerapamycin", "CHEMBL503885"],
  },
  {
    primary_id: "D02TCZ",
    names: ["2-Thiomorpholin-4-Yl-Pyrido[1,2-A]pyrimidin-4-One", "CHEMBL179168", "SCHEMBL3540143"],
  },
  {
    primary_id: "D02TDV",
    names: [
      "ZK-001",
      "Anfibatide",
      "Declotana",
      "Antithrombosis Agent (Injectable, Angina), Lee's Pharmaceutical Holdings",
      "Thrombolysin (Injectable, Angina), Lee's Pharmaceutical Holdings",
    ],
  },
  {
    primary_id: "D02TEW",
    names: [
      "2-P-Tolyl-2H-Pyrazolo[3,4-C]quinolin-4-Amine",
      "CHEMBL106346",
      "BDBM50091144",
      "2-P-Tolyl-2H-Pyrazolo[3,4-C]quinolin-4-Ylamine",
    ],
  },
  {
    primary_id: "D02TFB",
    names: ["EC-SOD, PPL Therapeutics"],
  },
  {
    primary_id: "D02TFD",
    names: ["(7-Benzyloxy-2-Oxo-2H-Chromen-4-Yl)Acetonitrile", "CHEMBL572768"],
  },
  {
    primary_id: "D02TGD",
    names: ["Netoglitazone"],
  },
  {
    primary_id: "D02TGF",
    names: [
      "Apolipoprotein E-Derived Peptides",
      "ApoEdpL-W",
      "Apolipoprotein E-Derived Peptides (Bacterial Infection)",
      "Apolipoprotein E-Derived Peptides (Bacterial Infection), Ai2",
    ],
  },
  {
    primary_id: "D02TGJ",
    names: ["Prophage"],
  },
  {
    primary_id: "D02TGN",
    names: ["XMT-1536"],
  },
  {
    primary_id: "D02TGW",
    names: ["GE-20372A"],
  },
  {
    primary_id: "D02THB",
    names: [
      "SKF-96067",
      "115607-61-9",
      "Skf-96067",
      "F-96067",
      "SKF96067",
      "3-Butyryl-4-(2-Methylphenylamino)-8-Methoxyquinoline",
      "CHEMBL327717",
      "SK-96067",
      "1-Butanone,1-[8-Methoxy-4-[(2-Methylphenyl)Amino]-3-Quinolinyl]-",
      "1-Butanone, 1-[8-Methoxy-4-[(2-Methylphenyl)Amino]-3-Quinolinyl]-",
      "1-Butanone, 1-(8-Methoxy-4-((2-Methylphenyl)Amino)-3-Quinolinyl)-",
      "MAVJDLHBPIXVJL-UHFFFAOYSA-N",
      "AC1MIWAF",
      "ACMC-1BTM4",
      "SCHEMBL451221",
      "ZINC3665",
      "CTK4A9399",
      "DTXSID40151146",
      "BDBM50001254",
      "HY-U00042",
      "1-[8-METHOXY-4-[(2-METHYLPHENYL)AMINO]-3-QUINOLINYL]-1-BUTANONE",
    ],
  },
  {
    primary_id: "D02TIK",
    names: ["HP-184"],
  },
  {
    primary_id: "D02TIO",
    names: ["RU85053"],
  },
  {
    primary_id: "D02TIT",
    names: ["PMID18412317C13b"],
  },
  {
    primary_id: "D02TIY",
    names: ["Monoamine Derivative 1"],
  },
  {
    primary_id: "D02TJS",
    names: [
      "Dicumarol",
      "Acadyl",
      "Acavyl",
      "Anathrombase",
      "Antitrombosin",
      "Apekumarol",
      "Baracoumin",
      "Bishydroxycoumarin",
      "Cuma",
      "Cumid",
      "Dicoumal",
      "Dicoumarin",
      "Dicoumarol",
      "Dicoumarolum",
      "Dicoumerol",
      "Dicuman",
      "Dicumarine",
      "Dicumarinum",
      "Dicumarolo",
      "Dicumarolum",
      "Dicumol",
      "Dikumarol",
      "Dufalone",
      "Dwukumarol",
      "Kumoran",
      "Melitoxin",
      "Temparin",
      "Trombosan",
      "Dicumaol R",
      "Dicumarol [USAN]",
      "Dicumarolo [DCIT]",
      "Dwukumarol [Polish]",
      "Uncoupler Of Oxidative Respiration",
      "M0216",
      "NC 034",
      "Bis-Hydroxycoumarin",
      "Dicoumarol (INN)",
      "Dicoumarolum [INN-Latin]",
      "Dicumarol (TN)",
      "Dicumarol (USAN)",
      "Dicumarol [INN-Spanish]",
      "Symmetric Dicoumarol Analogue, 1",
      "Bis(4-Hydroxycoumarin-3-Yl)Methane",
      "Di-(4-Hydroxy-3-Coumarinyl)Methane",
      "Bis-3,3'-(4-Hydroxycoumarinyl)Methane",
      "Bis-3,3'-(4-Oxycoumarinyl)Ethylacetate",
      "Di-4-Hydroxy-3,3'-Methylenedicoumarin",
      "Coumarin, 3,3'-Methylenebis[4-Hydroxy-(8CI)",
      "2-Hydroxy-3-[(2-Hydroxy-4-Oxochromen-3-Yl)Methyl]chromen-4-One",
      "2H-1-Benzopyran-2-One, 3,3'-Methylenebis[4-Hydroxy-(9CI)",
      "3,3&prime;-Methylenebis(4-Hydroxycoumarin)",
      "3,3'-Methyleen-Bis(4-Hydroxy-Cumarine)",
      "3,3'-Methyleen-Bis(4-Hydroxy-Cumarine) [Dutch]",
      "3,3'-Methylen-Bis(4-Hydroxy-Cumarin)",
      "3,3'-Methylen-Bis(4-Hydroxy-Cumarin) [German]",
      "3,3'-Methylene-Bis(4-Hydroxycoumarin)",
      "3,3'-Methylene-Bis(4-Hydroxycoumarine)",
      "3,3'-Methylene-Bis(4-Hydroxycoumarine) [French]",
      "3,3'-Methylenebis(4-Hydroxy-1,2-Benzopyrone)",
      "3,3'-Methylenebis(4-Hydroxy-2H-1-Benzopyran-2-One)",
      "3,3'-Methylenebis(4-Hydroxycoumarin)",
      "3,3'-Methylenebis[4-Hydroxy-1,2-Benzopyrone]",
      "3,3'-Methylenebis[4-Hydroxy-2H-1-Benzopyran-2-One]",
      "3,3'-Methylenebis[4-Hydroxycoumarin]",
      "3,3'-Metilen-Bis(4-Idrossi-Cumarina)",
      "3,3'-Metilen-Bis(4-Idrossi-Cumarina) [Italian]",
      "3,3'-Methanediylbis(4-Hydroxy-2H-Chromen-2-One)",
      "3,3'-Methylenebis(2-Hydroxy-4h-Chromen-4-One)",
      "3,3'-Methylenebis(4-Hydroxy-2H-Chromen-2-One)",
      "3,3-Methylene-Bis[4-Hydroxycoumarin]",
      "4,4'-Dihydroxy-3,3'-Methylene Bis Coumarin",
    ],
  },
  {
    primary_id: "D02TJT",
    names: ["MK-8109"],
  },
  {
    primary_id: "D02TJY",
    names: [
      "Triazine",
      "Anilazine",
      "DYRENE",
      "Zinochlor",
      "Anilazin",
      "Kemate",
      "101-05-3",
      "Bortrysan",
      "Triazin",
      "Triasyn",
      "Triasym",
      "Direz",
      "Dyrene 50W",
      "Aniyaline",
      "Dyrene Flussig",
      "Triazine (Pesticide)",
      "4,6-Dichloro-N-(2-Chlorophenyl)-1,3,5-Triazin-2-Amine",
      "2,4-Dichloro-6-(O-Chloroanilino)-S-Triazine",
      "NCI-C08684",
      "(O-Chloroanilino)Dichlorotriazine",
      "Triazine (VAN)",
      "NSC 3851",
      "Caswell No. 302",
      "2,4-Dichloro-6-(2-Chloroanilino)-1,3,5-Triazine",
      "Dairene",
      "Anilazine [BSI:ISO]",
      "ENT 26,058",
      "1,3,5-Triazin-2-Amine, 4,6-Dichloro-N-(2-Chlorophenyl)-",
      "UNII-C6E8Y03ZJN",
      "B-622",
      "CCRIS 43",
    ],
  },
  {
    primary_id: "D02TKC",
    names: [
      "1,2-Diacetoxylycorine",
      "1,2-Diacetyllycorine",
      "CHEMBL465295",
      "Lycorin Diacetate",
      "LYCORINE DIACETATE",
      "1,2-Di-O-Acetyllycorine",
      "SCHEMBL12319317",
      "BDBM50293602",
      "(1S,17S,18S,19S)-17-(Acetyloxy)-5,7-Dioxa-12-Azapentacyclo[10.6.1.0^{2,10}.0^{4,8}.0^{15,19}]nonadeca-2,4(8),9,15-Tetraen-18-Yl Acetate",
    ],
  },
  {
    primary_id: "D02TKI",
    names: ["L-733060"],
  },
  {
    primary_id: "D02TKT",
    names: ["HAEGTFTSDVSSYLEGQAAKEFIAWLVKGRG-NH2"],
  },
  {
    primary_id: "D02TKV",
    names: ["ISIS 3224"],
  },
  {
    primary_id: "D02TKX",
    names: [
      "Sac-0601",
      "Cholesterol-Derived Apoptosis Inhibitors (Diabetic Retinopathy), Seoul National University/Yonsei University",
    ],
  },
  {
    primary_id: "D02TLD",
    names: ["AMT-050", "ApoA1 Deficiency Gene Therapy, Amsterdam Molecular Therapeutics"],
  },
  {
    primary_id: "D02TLO",
    names: [
      "Ifosfamide",
      "Cyfos",
      "Holoxan",
      "Ifex",
      "Ifosfamid",
      "Ifosfamida",
      "Ifosfamidum",
      "Ifosphamide",
      "Ifsofamide",
      "Iphosphamid",
      "Iphosphamide",
      "Isoendoxan",
      "Isofosfamide",
      "Isophosphamide",
      "Isosfamide",
      "Mitoxana",
      "Naxamide",
      "Ifosfamide Sterile",
      "Iso Endoxan",
      "A 4942",
      "ASTA Z 4942",
      "Holoxan 1000",
      "MJF 9325",
      "Z 4942",
      "Z4942",
      "I-Phosphamide",
      "IFEX (TN)",
      "Ifex (TN)",
      "Ifosfamida [INN-Spanish]",
      "Ifosfamidum [INN-Latin]",
      "Iphosphamid(E)",
      "Iso-Endoxan",
      "MJF-9325",
      "Mitoxana (TN)",
      "NPFAPI-04",
      "Z-4942",
      "Mitoxana, Ifex, Ifosfamide",
      "Ifosfamide (JAN/USP/INN)",
      "Ifosfamide [USAN:INN:BAN:JAN]",
      "N,N-Bis(Beta-Chloroethyl)-Amino-N',O-Propylene-Phosphoric Acid Ester Diamide",
      "N,3-Bis(2-Chloroethyl)-1,3,2-Oxazaphosphinan-2-Amine 2-Oxide",
      "N,3-Bis(2-Chloroethyl)Tetrahydro-2H-1,3,2-Oxazaphosphorin-2-Amine 2-Oxide",
      "N-(2-Chloroethyl)-N'-(2-Chloroethyl)-N',O-Propylen Ephosphoric Acid Diamide",
      "N-(2-Chloroethyl)-N'-(2-Chloroethyl)-N',O-Propylene Phosphoric Acid Ester Diamide",
      "N-(2-Chloroethyl)-N'-(2-Chloroethyl)-N',O-Propylenephosphoric Acid Diamide",
      "N-(2-Chloroethyl)-N'-(2-Chloroethyl)-N',O-Propylenephosphoric Acid Ester Diamide",
      "N-(2-Chloraethyl)-N'-(2-Chloraethyl)-N',O-Propylen-Phosphorsaureester-Diamid",
      "N-(2-Chloraethyl)-N'-(2-Chloraethyl)-N',O-Propylen-Phosphorsaureester-Diamid [German]",
      "N-(2-Chloroethyl)-N-(3-(2-Chloroethyl)-2-Oxido-1,3,2-Oxazaphosphinan-2-Yl)Amine",
      "(+-)-Ifosfamid",
      "(+-)-Ifosphamide",
      "(+-)-Tetrahydro-N,3-Bis(2-Chloroethyl)-2H-1,3,2-Oxazaphosphorin-2-Amine 2-Oxide",
      "(D,L)-Ifosfamide",
      "(R,S)-Ifosphamide",
      "(R,S)-N,3-Bis(2-Chloroethyl)Tetrahydro-2H-1,3,2-Oxazaphosphorin-2-Amine 2-Oxide",
      "1,3,2-Oxazaphosphorine, 3-(2-Chloroethyl)-2-((2-Chloroethyl)Amino)Tetrahydro-, 2-Oxide",
      "1,3,2-Oxazaphosphorine, 3-(2-Chloroethyl)-2-[(2-Chloroethyl)Amino]tetrahydro-, 2-Oxide",
      "2,3-(N,N(Sup 1)-Bis(2-Chloroethyl)Diamido)-1,3,2-Oxazaphosphoridinoxyd",
      "2,3-N,N(Sup 1)-Bis(2-Chloroethyl)Diamido-1,3,2-Oxazaphosphoridinoxyd",
      "2H-1,3,2-Oxazaphosphorin-2-Amine, N,3-Bis(2-Chloroethyl)Tetrahydro-, 2-Oxide",
      "2H-1,3,2-Oxazaphosphorine, 3-(2-Chloroethyl)-2-((2-Chloroethyl)Amino)Tetrahydro-, 2-Oxide",
      "2H-1,3,2-Oxazaphosphorine, 3-(2-Chloroethyl)-2-((2-Chloroethyl)Amino)Tetrahydro-, 2-Oxide (8CI)",
      "2H-1,3,2-Oxazaphosphorine, 3-(2-Chloroethyl)-2-[(2-Chloroethyl)Amino]tetrahydro-, 2-Oxide",
      "3,} 2-Oxazaphosphorine Oxide",
      "3-(2-Chloroethyl)-2-((2-Chloroethyl)Amino)Perhydro-2H-1,3,2-Oxazaphosphorine Oxide",
      "3-(2-Chloroethyl)-2-((2-Chloroethyl)Amino)Tetrahydro-2H-1,3,2-Oxazaphosphorine 2-Oxide",
      "3-(2-Chloroethyl)-2-[(2-Chloroethyl)Amino]perhydro-2H-1,3,2-Oxazaphosphorine Oxide",
      "3-(2-Chloroethyl)-2-[(2-Chloroethyl)Amino]perhydro-2H-1,3,2-Oxazaphosphorineoxide",
      "3-(2-Chloroethyl)-2-[(2-Chloroethyl)Amino]tetrahydro-2H-1,3,2-Oxazaphosphorine 2-Oxide",
    ],
  },
  {
    primary_id: "D02TLP",
    names: [
      "1-Phenyl-Propane-1,2-Dione",
      "1-PHENYL-1,2-PROPANEDIONE",
      "1-Phenylpropane-1,2-Dione",
      "579-07-7",
      "Acetylbenzoyl",
      "Acetyl Benzoyl",
      "Benzoylacetyl",
      "1,2-Propanedione, 1-Phenyl-",
      "Methylphenylglyoxal",
      "Pyruvophenone",
      "Phenylmethyldiketone",
      "Benzoyl Methyl Ketone",
      "Methyl Phenyl Diketone",
      "3-Phenyl-2,3-Propanedione",
      "Methyl Phenyl Glyoxal",
      "Phenyl Methyl Diketone",
      "UNII-ZB5XA3GD0I",
      "1-Phenyl-1,2-Propandione",
      "FEMA No. 3226",
      "CCRIS 6297",
      "NSC 7643",
      "EINECS 209-435-2",
      "ZB5XA3GD0I",
      "AI3-23868",
      "CHEBI:63552",
      "BVQVLAIMHVDZEL-UHFFFAOYSA-N",
      "1-Phenyl-1,2-Propanedione, 98",
    ],
  },
  {
    primary_id: "D02TLZ",
    names: [
      "3-Biphenyl-4-Yl-N-Hydroxy-N-Methyl-Acrylamide",
      "CHEMBL178053",
      "ZINC28103871",
      "BDBM50015167",
      "(E)-3-[4-Phenylphenyl]-N-Methyl-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D02TMK",
    names: ["METHYLENEDIOXYAMPHETAMINE"],
  },
  {
    primary_id: "D02TMO",
    names: ["177Lu-AMBA"],
  },
  {
    primary_id: "D02TMR",
    names: ["N5-[(4-Phenoxy)-3-Pyridyl]-L-Glutamamide", "CHEMBL478551", "Modified Amino Acid Analog, 8d", "BDBM24269"],
  },
  {
    primary_id: "D02TMX",
    names: [
      "4-Methyl-6-Propylpyridin-2-Amine",
      "CHEMBL302657",
      "2-Amino-4-Methyl-6-Propylpyridine",
      "179555-24-9",
      "SCHEMBL7051196",
      "QGCCHDIBMYPTPX-UHFFFAOYSA-N",
      "BDBM50091817",
      "4-Methyl-6-Propyl-Pyridin-2-Ylamine",
      "2-Pyridinamine, 4-Methyl-6-Propyl-",
      "AKOS027400844",
      "4-Methyl-6-Propylpyridin-2-Amine, 7",
    ],
  },
  {
    primary_id: "D02TNK",
    names: ["STYLISSADINE B"],
  },
  {
    primary_id: "D02TNR",
    names: ["N-(1-((Cyanomethyl)Carbamoyl)Cyclohexyl)Benzamide"],
  },
  {
    primary_id: "D02TNS",
    names: ["TMC-353121"],
  },
  {
    primary_id: "D02TNW",
    names: ["VR179"],
  },
  {
    primary_id: "D02TOI",
    names: ["Tricyclic Phytocannabinoid Derivative 2"],
  },
  {
    primary_id: "D02TOK",
    names: [
      "IL-2 Mimetic Peptides",
      "AGIL2-03",
      "IL-2 Immunomers, AplaGen",
      "IL-2 Mimetic Peptides (Cancer Immunotherapy)",
      "Interleukin-2 Peptide Mimetics, AplaGen",
      "IL-2 Mimetic Peptides (Cancer Immunotherapy), AplaGen",
    ],
  },
  {
    primary_id: "D02TOP",
    names: ["S6-Nitrobenzyl Mercaptopurine Riboside"],
  },
  {
    primary_id: "D02TOS",
    names: ["AMSH"],
  },
  {
    primary_id: "D02TPK",
    names: ["Ac-RYYRIK-GGG-K-(NH2)-YRFB-GGGGG", "CHEMBL441930"],
  },
  {
    primary_id: "D02TPO",
    names: ["JNJ-26529152"],
  },
  {
    primary_id: "D02TPS",
    names: ["ISIS 3792"],
  },
  {
    primary_id: "D02TPY",
    names: ["Bag-1", "AK120413"],
  },
  {
    primary_id: "D02TQA",
    names: [
      "N-Hydroxy-E-3-(4'-Chlorobiphenyl-4-Yl)-Acrylamide",
      "CHEMBL557066",
      "SCHEMBL3292989",
      "SCHEMBL3292983",
      "BDBM50293365",
    ],
  },
  {
    primary_id: "D02TQC",
    names: [
      "N-(5-Bromo-Quinoxalin-6-Yl)-Guanidine",
      "CHEMBL883",
      "168329-48-4",
      "BDBM50213216",
      "AKOS027322398",
      "Guanidine, N-(5-Bromo-6-Quinoxalinyl)-",
      "(5-Bromo-6-Quinoxalinyl)Guanidine Hydrochloride",
    ],
  },
  {
    primary_id: "D02TQE",
    names: ["TA-102"],
  },
  {
    primary_id: "D02TQO",
    names: [
      "Vitamin E",
      "Covi-Ox",
      "VITAMIN E",
      "E-Vimin",
      "Syntopherol",
      "Profecundin",
      "Evitaminum",
      "Viprimol",
      "Vascuals",
      "Etamican",
      "Viteolin",
      "Tokopharm",
      "Vitayonon",
      "Epsilan",
      "Emipherol",
      "Denamone",
      "Almefrol",
      "Verrol",
      "Ilitia",
      "Etavit",
      "77171-98-3",
      "Evion",
      "Esorb",
      "Alpha-Tokoferol",
      "Alpha Tocopherol",
      "Vitaplex E",
      "Vitamin E Alpha",
      "Eprolin S",
      "Viterra E",
      "E Prolin",
      "Spavit E",
      "Vita E",
      "Endo E",
      "Med-E",
      "Lan-E",
      "Antisterility Vitamin",
      "Alpha-Tocopherol Acid",
      "Tenox GT 1",
      "(R)-2,5,7,8-Tetramethyl-2-((4S,8S)-4,8,12-Trimethyltridecyl)Chroman-6-Ol",
      "Rhenogran Ronotec 50",
      "Vi-E",
      "Covitol F 1000",
      "E 307 (Tocopherol)",
    ],
  },
  {
    primary_id: "D02TRN",
    names: ["Palmitoleic Acid"],
  },
  {
    primary_id: "D02TSJ",
    names: ["Promethazine + Sumatriptan"],
  },
  {
    primary_id: "D02TSN",
    names: ["AZ20", "AZ-20", "AZ 20", "Compound 6 [PMID: 46244454]"],
  },
  {
    primary_id: "D02TTF",
    names: [
      "YM-90K",
      "154164-30-4",
      "YM 90K Hydrochloride",
      "YM 90K",
      "UAEXCLWI0Q",
      "YM-90K Hydrochloride",
      "UNII-UAEXCLWI0Q",
      "6-(1H-Imidazol-1-Yl)-7-Nitro-2,3(1H,4H)-Quinoxalinedione",
      "2,3-Quinoxalinedione, 1,4-Dihydro-6-(1H-Imidazol-1-Yl)-7-Nitro-, Monohydrochloride",
      "1,4-Dihydro-6-(1H-Imidazol-1-Yl)-7-Nitro-2,3-Quinoxalinedione Monohydrochloride",
      "6-(1h-Imidazol-1-Yl)-7-Nitro-2,3(1h,4h)-Quinoxalinedione Hydrochloride",
      "YM-900",
      "YM90K Hydrochloride",
      "AC1NUOLT",
      "YM90KHYDROCHLORIDE",
      "YM 90K HCl",
      "YM-90K HCl",
      "CHEMBL3400490",
      "C11H7N5O4",
      "EX-A113",
      "DTXSID00165547",
    ],
  },
  {
    primary_id: "D02TTL",
    names: ["Debio 0932"],
  },
  {
    primary_id: "D02TTP",
    names: ["TDI-0015"],
  },
  {
    primary_id: "D02TUL",
    names: [
      "PF-06651600",
      "CBRJPFGIXUFMTM-WDEREUQCSA-N",
      "1792180-81-4",
      "UNII-2OYE00PC25",
      "2OYE00PC25",
      "PF06651600",
      "1-((2S,5R)-5-((7H-Pyrrolo[2,3-D]pyrimidin-4-Yl)Amino)-2-Methylpiperidin-1-Yl)Prop-2-En-1-One",
      "GTPL9559",
      "SCHEMBL16764638",
      "BCP24778",
      "EX-A2613",
      "ZINC526061581",
      "Example 5 [WO2015083028]",
      "ACN-040697",
      "Compound 11 [PMID: 28139931]",
      "HY-100754",
      "CS-0020243",
      "1-[(2S,5R)-2-Methyl-5-(7H-Pyrrolo[2,3-D]pyrimidin-4-Ylamino)-1-Piperidinyl]-2-Propen-1-One Malonate",
      "1-[(2S,5R)-2-Methyl-5-(7H-Pyrrolo[2,3-D]pyrimidin-4-Ylamino)Piperidin-1-Yl]prop-2-En-1-One",
    ],
  },
  {
    primary_id: "D02TVQ",
    names: ["GSK343", "Compound 6 [PMID 24900432]", "GSK 343"],
  },
  {
    primary_id: "D02TVS",
    names: ["Hexadecanoic Acid"],
  },
  {
    primary_id: "D02TVU",
    names: ["HTERT Vaccine"],
  },
  {
    primary_id: "D02TWR",
    names: ["BIIB080"],
  },
  {
    primary_id: "D02TXD",
    names: ["NITROCEFIN"],
  },
  {
    primary_id: "D02TXV",
    names: ["TRN-157"],
  },
  {
    primary_id: "D02TXW",
    names: ["BX 667", "BX-667", "BX667"],
  },
  {
    primary_id: "D02TYC",
    names: ["BetaARKct"],
  },
  {
    primary_id: "D02TYK",
    names: ["6-[(E)-2-(4-Fluoro-Phenyl)-Vinyl]-9H-Purine"],
  },
  {
    primary_id: "D02TYL",
    names: ["HER-801"],
  },
  {
    primary_id: "D02TYS",
    names: ["[1,4]Thiazepan-(3E)-Ylideneamine", "CHEMBL186856", "BDBM50155780"],
  },
  {
    primary_id: "D02TYV",
    names: ["DPS-102"],
  },
  {
    primary_id: "D02TZB",
    names: ["T2 18C3"],
  },
  {
    primary_id: "D02TZT",
    names: ["PMID29338548-Compound-17"],
  },
  {
    primary_id: "D02UAR",
    names: ["DiffGAM", "Antibody (Colitis), Immucell"],
  },
  {
    primary_id: "D02UBC",
    names: ["MEDI6383"],
  },
  {
    primary_id: "D02UCA",
    names: ["Pyrimidine Derivative 34"],
  },
  {
    primary_id: "D02UCT",
    names: ["R-820"],
  },
  {
    primary_id: "D02UCW",
    names: ["PA-2794"],
  },
  {
    primary_id: "D02UDJ",
    names: [
      "D-Serine",
      "312-84-5",
      "(R)-2-Amino-3-Hydroxypropanoic Acid",
      "(2R)-2-Amino-3-Hydroxypropanoic Acid",
      "Serine D-Form",
      "(R)-Serine",
      "Serine, D-",
      "D-(+)-Serine",
      "D-2-Amino-3-Hydroxypropionic Acid",
      "D-Serin",
      "D-Ser",
      "UNII-1K77H2Z9B1",
      "EINECS 206-229-4",
      "MFCD00004269",
      "NSC 77689",
      "(2R)-2-Amino-3-Hydroxy-Propanoic Acid",
      "AI3-18476",
      "CHEMBL285123",
      "CHEBI:16523",
      "MTCFGRXMJLQNBG-UWTATZPHSA-N",
      "1K77H2Z9B1",
      "(R)-2-Amino-3-Hydroxypropionic Acid",
      "DSN",
      "NCGC00094363-03",
      "D-Serine, 98%",
      "D-2-Amino-3-Hydroxypropanoic Acid",
      "NSC77689",
      "Serine D-",
      "[3h]-D-Serine",
      "(2R)-Serine",
    ],
  },
  {
    primary_id: "D02UDO",
    names: ["PMID26651364-Compound-9d"],
  },
  {
    primary_id: "D02UEJ",
    names: ["JWH-311"],
  },
  {
    primary_id: "D02UES",
    names: ["Cyclo(-D-Tyr-D-MeArg-L-Arg-L-Nal-Gly-)", "CHEMBL218806"],
  },
  {
    primary_id: "D02UFG",
    names: ["Metaproterenol Sulfate"],
  },
  {
    primary_id: "D02UFQ",
    names: ["TAK-285"],
  },
  {
    primary_id: "D02UGL",
    names: ["TR-Flu"],
  },
  {
    primary_id: "D02UHI",
    names: ["ISIS 29136"],
  },
  {
    primary_id: "D02UHP",
    names: [
      "6,7-Dibromoquinoline-5,8-Dione",
      "CHEMBL488961",
      "18633-05-1",
      "6,7-Dibrom-5,8-Dihydrochinolin-5,8-Dion",
      "NSC82128",
      "AC1L5TKN",
      "SCHEMBL6866292",
      "AC1Q6K93",
      "6,7-Dibromo-5,8-Quinolindion",
      "CTK4D9216",
      "DTXSID40292366",
      "CPZKOUMVYNIHQE-UHFFFAOYSA-N",
      "BDBM50264041",
      "NSC-82128",
    ],
  },
  {
    primary_id: "D02UHS",
    names: ["CGS 24012", "DPMA", "CGS-24012", "CGS24012"],
  },
  {
    primary_id: "D02UIC",
    names: ["SSR-128428"],
  },
  {
    primary_id: "D02UID",
    names: [
      "WAY-252623",
      "875787-07-8",
      "UNII-4W82FEU838",
      "2-(2-Chloro-4-Fluorobenzyl)-3-(4-Fluorophenyl)-7-(Trifluoromethyl)-2H-Indazole",
      "4W82FEU838",
      "2-[(2-Chloro-4-Fluorophenyl)Methyl]-3-(4-Fluorophenyl)-7-(Trifluoromethyl)-2H-Indazole",
      "WAY 252623",
      "LX-623",
      "LX623",
      "WAY252623",
      "C21H12ClF5N2",
      "JMC517161 Compound 12",
      "CHEMBL456237",
      "SCHEMBL1407261",
      "BDBM26066",
      "AOB1088",
      "KYWWJENKIMRJBI-UHFFFAOYSA-N",
      "MolPort-028-749-214",
      "EX-A1002",
      "BCP03659",
      "S8390",
      "ZINC40379749",
      "3089AH",
      "AKOS026750209",
      "CS-1721",
      "2H-Indazole, 2-((2-Chloro-4-Fluorophenyl)Met",
    ],
  },
  {
    primary_id: "D02UIE",
    names: ["ZK-91296"],
  },
  {
    primary_id: "D02UII",
    names: ["4-Hydroxy-Aconitate Ion", "ATH", "AC1NRA5R", "DB03964", "(E,3R)-3-Hydroxyprop-1-Ene-1,2,3-Tricarboxylate"],
  },
  {
    primary_id: "D02UJD",
    names: ["PD-143188"],
  },
  {
    primary_id: "D02UJG",
    names: ["ISIS-387898"],
  },
  {
    primary_id: "D02UJO",
    names: ["GR138676"],
  },
  {
    primary_id: "D02UJQ",
    names: ["TM-400"],
  },
  {
    primary_id: "D02UJS",
    names: ["PMID30107136-Compound-Example49"],
  },
  {
    primary_id: "D02UJV",
    names: [
      "1-(3-(3-Morpholinopropoxy)Phenyl)-3-Phenylurea",
      "CHEMBL540430",
      "SCHEMBL4263031",
      "ANYYSWDTIHWUQV-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D02UKE",
    names: [
      "5-(Pyridin-4-Yl)-1,3,4-Thiadiazole-2(3H)-Thione",
      "13581-25-4",
      "5-(Pyridin-4-Yl)-1,3,4-Thiadiazole-2-Thiol",
      "CHEMBL596214",
      "5-(4-Pyridyl)-1,3,4-Thiadiazole-2-Thiol",
      "5-Pyridin-4-Yl-1,3,4-Thiadiazole-2-Thiol",
      "5-Mercapto-2-(Pyridin-4-Yl)-1,3,4-Thiadiazole",
      "1,3,4-Thiadiazole-2(3H)-Thione, 5-(4-Pyridinyl)-",
      "AC1NRXV6",
      "5-Pyridin-4-Yl-3H-1,3,4-Thiadiazole-2-Thione",
      "SCHEMBL969939",
      "CTK0F4035",
      "DTXSID70415420",
      "MolPort-002-738-405",
      "BDBM50304604",
      "ZINC13532584",
      "STK328652",
      "AKOS030229631",
      "AKOS005174322",
      "MCULE-1265725983",
      "KB-243965",
      "R9200",
      "ST45106367",
    ],
  },
  {
    primary_id: "D02UKX",
    names: ["CAP1-6D"],
  },
  {
    primary_id: "D02UKY",
    names: ["6-Hydroxymethylpterin"],
  },
  {
    primary_id: "D02ULG",
    names: ["CP-4033"],
  },
  {
    primary_id: "D02ULU",
    names: ["Semaglutide"],
  },
  {
    primary_id: "D02UMF",
    names: ["KT6-971", "Cholesterol Absorption Inhibitor, Kotobuki"],
  },
  {
    primary_id: "D02UMS",
    names: ["Quinazoline Derivative 5"],
  },
  {
    primary_id: "D02UNP",
    names: ["IMA-901"],
  },
  {
    primary_id: "D02UNX",
    names: [
      "VU0255035",
      "1135243-19-4",
      "VU 0255035",
      "N-(3-Oxo-3-(4-(Pyridin-4-Yl)Piperazin-1-Yl)Propyl)Benzo[c][1,2,5]thiadiazole-4-Sulfonamide",
      "CHEMBL1628667",
      "N-[3-Oxo-3-[4-(4-Pyridinyl)-1-Piperazinyl]propyl]-2,1,3-Benzothiadiazole-4-Sulfonamide",
      "SCHEMBL2730708",
      "GTPL3274",
      "CHEBI:93015",
      "MolPort-023-276-911",
      "BDBM50362367",
      "ZINC43207362",
      "AKOS024457774",
      "AK163845",
      "AX8294949",
      "ST24048355",
      "J-002979",
      "BRD-K58033748-001-01-2",
    ],
  },
  {
    primary_id: "D02UOD",
    names: ["PMID25980951-Compound-3"],
  },
  {
    primary_id: "D02UOE",
    names: ["Miltirone"],
  },
  {
    primary_id: "D02UOW",
    names: ["Beta-Phe Compound 2"],
  },
  {
    primary_id: "D02UOZ",
    names: ["NB-001"],
  },
  {
    primary_id: "D02UPI",
    names: ["AGS-6MF"],
  },
  {
    primary_id: "D02UQG",
    names: ["RDEA-436"],
  },
  {
    primary_id: "D02UQU",
    names: ["ABS-301", "ABS-302", "ABS-303", "ABS-304", "Tacrine Analogs, ABS"],
  },
  {
    primary_id: "D02URA",
    names: ["Pyridine-2,4-Dicarboxylic Acid Derivative 1"],
  },
  {
    primary_id: "D02URD",
    names: ["SAR425899"],
  },
  {
    primary_id: "D02URS",
    names: ["Potassium Iodide"],
  },
  {
    primary_id: "D02URX",
    names: ["KamRAB"],
  },
  {
    primary_id: "D02USA",
    names: ["Cycloalkyl Acid Derivative 1"],
  },
  {
    primary_id: "D02USE",
    names: ["Heterocyclic Derivative 15"],
  },
  {
    primary_id: "D02USG",
    names: ["P-2745"],
  },
  {
    primary_id: "D02USV",
    names: ["ANA-505", "Antibody Program (SHM), AnaptysBio"],
  },
  {
    primary_id: "D02UTC",
    names: ["N-Hydroxy-4-(Phenylacetylamino-Methyl)-Benzamide", "CHEMBL143674", "SCHEMBL673760"],
  },
  {
    primary_id: "D02UTG",
    names: ["Binuclear Gold(I) Compound 1"],
  },
  {
    primary_id: "D02UUC",
    names: [
      "3-P-Tolyl-8-Aza-Bicyclo[3.2.1]octane",
      "CHEMBL80262",
      "SCHEMBL15961866",
      "3-P-Tolyl-8-Aza-Bicyclo[3.2.1]octane-2-Carboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D02UUK",
    names: [
      "KAZINOL S",
      "CHEMBL469113",
      "BDBM50254431",
      "5''-(2-Methylbut-3-En-2-Yl)-6''''-(3-Methylbut-2-Enyl)-5''''-(2,3-Epoxy-3-Methylbytyl)-2'',4'',3'''',4''''-Tetrahydroxy Diphenylpropane",
    ],
  },
  {
    primary_id: "D02UUO",
    names: ["Dihydrosphingosine-1-Phosphate"],
  },
  {
    primary_id: "D02UUR",
    names: ["3-[3-(4-Hydroxyphenyl)Isoxazol-5-Yl]phenol"],
  },
  {
    primary_id: "D02UVG",
    names: [
      "7-Bromo-2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "7-Bromo-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
      "108061-47-8",
      "CHEMBL441891",
      "7-Bromo-1,2,3,4-Tetrahydro-Beta-Carboline",
      "CS-WAA0033",
      "SCHEMBL3476066",
      "CTK8C1152",
      "DTXSID00434588",
      "XAHAQVIJZAMMLO-UHFFFAOYSA-N",
      "ZINC13587967",
      "BDBM50136507",
      "1620AA",
      "ANW-65970",
      "AKOS016005623",
      "CB-3796",
      "FCH1610363",
      "CS-15602",
      "AJ-64214",
      "TC-154715",
      "KB-249517",
      "AX8236304",
    ],
  },
  {
    primary_id: "D02UVH",
    names: [
      "B-Octylglucoside",
      "29836-26-8",
      "Octyl-Beta-D-Glucopyranoside",
      "OCTYL BETA-D-GLUCOPYRANOSIDE",
      "Octyl Beta-D-Glucoside",
      "Octyl Glucoside",
      "Octyl B-D-Glucopyranoside",
      "N-Octyl-Beta-D-Glucoside",
      "1-O-N-Octyl-Beta-D-Glucopyranoside",
      "Octyl-Beta-D-Glucoside",
      "Beta-D-Octyl Glucoside",
      "1-O-Octyl-Beta-D-Glucopyranoside",
      "(2R,3S,4S,5R,6R)-2-(Hydroxymethyl)-6-(Octyloxy)Tetrahydro-2H-Pyran-3,4,5-Triol",
      "UNII-V109WUT6RL",
      "N-Octyl Glucoside",
      "N-Octyl-Beta-D-Glucopyranoside",
      "Octyl-Beta-D-Glucopyranose",
      "Octyl",
      "V109WUT6RL",
    ],
  },
  {
    primary_id: "D02UVR",
    names: [
      "Bisandrographolide",
      "Bisandrographolide A",
      "Bisandrographolide C",
      "160498-00-0",
      "160498-02-2",
      "C40H56O8",
      "GTPL2502",
      "MolPort-039-338-289",
      "9116AF",
      "9115AF",
      "AKOS032948243",
    ],
  },
  {
    primary_id: "D02UVS",
    names: ["NSC-1771"],
  },
  {
    primary_id: "D02UVT",
    names: ["Dolutegravir + Abacavir Sulphate + Lamivudine"],
  },
  {
    primary_id: "D02UWB",
    names: ["5-CT", "5-Carboxamidotryptamine"],
  },
  {
    primary_id: "D02UWX",
    names: ["OT-440"],
  },
  {
    primary_id: "D02UXK",
    names: ["MBX-3254"],
  },
  {
    primary_id: "D02UYB",
    names: ["Patisiran"],
  },
  {
    primary_id: "D02UYM",
    names: ["SB414"],
  },
  {
    primary_id: "D02UYQ",
    names: ["Darzalexdaratumumab"],
  },
  {
    primary_id: "D02UYR",
    names: ["PKF-241-466"],
  },
  {
    primary_id: "D02UZI",
    names: ["3-[(Z)-2-Phenylvinyl]pyridine"],
  },
  {
    primary_id: "D02VBF",
    names: [
      "BI-D1870",
      "501437-28-1",
      "2-((3,5-Difluoro-4-Hydroxyphenyl)Amino)-8-Isopentyl-5,7-Dimethyl-7,8-Dihydropteridin-6(5H)-One",
      "2-(3,5-Difluoro-4-Hydroxyanilino)-5,7-Dimethyl-8-(3-Methylbutyl)-7H-Pteridin-6-One",
      "C19H23F2N5O2",
      "2-(3,5-Difluoro-4-Hydroxyphenylamino)-8-Isopentyl-5,7-Dimethyl-7,8-Dihydropteridin-6(5H)-One",
      "BI-D 1870",
      "CHEMBL573107",
      "GTPL8038",
      "SCHEMBL13881333",
      "CTK8I8998",
      "BDBM25017",
      "DTXSID40648506",
      "BID1870",
      "MolPort-008-266-821",
      "HMS3244D11",
      "HMS3244C12",
      "HMS3244C11",
      "BCPP000343",
      "HMS3651D17",
      "BCP01977",
      "EX-A2471",
      "NSC747342",
    ],
  },
  {
    primary_id: "D02VBL",
    names: ["EMZ701"],
  },
  {
    primary_id: "D02VBO",
    names: ["RG7604"],
  },
  {
    primary_id: "D02VBX",
    names: [
      "ISIS-SOD1Rx",
      "ALS Antisense Therapeutics, Isis",
      "ISIS-333611",
      "Antisense Oligonucleotides (SOD1), Isis",
    ],
  },
  {
    primary_id: "D02VCJ",
    names: ["Metrizamide"],
  },
  {
    primary_id: "D02VCK",
    names: ["FLUPAROXAN"],
  },
  {
    primary_id: "D02VDD",
    names: ["Deuterated 3-Cyanoquinoline Derivative 1"],
  },
  {
    primary_id: "D02VER",
    names: ["Radavirsen", "AVI-7100", "AVI-7367", "H1N1 Influenza Virus Inhibitor (Antisense), AVI Biopharma"],
  },
  {
    primary_id: "D02VET",
    names: ["HYG-302"],
  },
  {
    primary_id: "D02VFB",
    names: ["Miproxifene"],
  },
  {
    primary_id: "D02VFC",
    names: ["Cefoxitin"],
  },
  {
    primary_id: "D02VFF",
    names: ["VU0357121"],
  },
  {
    primary_id: "D02VFK",
    names: ["PPRT-152"],
  },
  {
    primary_id: "D02VFM",
    names: [
      "DECIDIUM",
      "Decidium Diiodide",
      "AC1L2WE8",
      "123048-04-4",
      "3,8-Diamino-5,10'-(Trimethylammonium)Decyl-6-Phenyl Phenanthridium Diiodide",
      "4-Phenanthrenedecanaminium, 1,6-Diamino-N,N,N-Trimethyl-3-Phenyl-, Iodide, Monohydriodide",
      "10-(1,6-Diamino-3-Phenylphenanthren-4-Yl)Decyl-Trimethylazanium Iodide Hydroiodide",
    ],
  },
  {
    primary_id: "D02VFQ",
    names: ["HI-241"],
  },
  {
    primary_id: "D02VGC",
    names: ["Pyrazolopyridine Derivative 6"],
  },
  {
    primary_id: "D02VGM",
    names: [
      "[3H]QNB",
      "3-Quinuclidinyl Benzilate",
      "6581/6/2",
      "3-Chinuclidylbenzilate",
      "3-Quinuclidyl Benzilate",
      "3-Quinuclidinylbenzilate",
      "3-Quinuclidinol Benzilate",
      "Ro 2-3308",
      "CHEMBL12980",
      "Benzilic Acid, 3-Quinuclidinyl Ester",
      "(+/-)-QNB",
      "NSC 173698",
      "QNB",
      "EA 2277",
      "CS 4030",
      "BZ",
      "1-Azabicyclo(222)Octan-3-Ol, Benzilate",
      "Buzz",
      "Agent BZ",
      "1-Azabicyclo[222]octan-3-Yl 2-Hydroxy-2,2-Diphenylacetate",
      "BZ [anticholinergic]",
      "4478-53-9",
      "NSC170423",
      "Benzilic Acid, 3-Quinuclidinyl Ester, (+-)-",
      "3-Quinuclidinyl-Benzilate",
      "[3H]quinuclidinylbenzilate",
      "Benzeneacetic",
    ],
  },
  {
    primary_id: "D02VGS",
    names: ["Desulfated Cholecystokinin-8", "Des-CCK-8", "Desulfated Sincalide"],
  },
  {
    primary_id: "D02VHD",
    names: ["SCH51866"],
  },
  {
    primary_id: "D02VHE",
    names: ["A-72055"],
  },
  {
    primary_id: "D02VHJ",
    names: [
      "2-(1-(Phenylsulfonyl)-1H-Indol-3-Yl)Ethanamine",
      "CHEMBL363275",
      "SCHEMBL1357126",
      "BDBM21341",
      "N1-Phenylsulfonyltryptamine, 11a",
    ],
  },
  {
    primary_id: "D02VHZ",
    names: ["2-Amino-1-(4-Ethylthiophenyl)Butane", "CHEMBL1077883", "1204749-31-4", "BDBM50310840"],
  },
  {
    primary_id: "D02VIF",
    names: ["OLT1177 Gel"],
  },
  {
    primary_id: "D02VIP",
    names: [
      "SB-267268",
      "2-[3-Oxo-8-[3-(2-Pyridylamino)Propoxy]-2-(2,2,2-Trifluoroethyl)-2,3,4,5-Tetrahydro-1H-2-Benzazepin-4(S)-Yl]acetic Acid",
    ],
  },
  {
    primary_id: "D02VIR",
    names: ["PMID30074415-Compound-21"],
  },
  {
    primary_id: "D02VIT",
    names: [
      "Arbidol",
      "Arbidole",
      "MC-101",
      "Ethyl 6-Bromo-4-(Dimethylaminomethyl)-5-Hydroxy-1-Methyl-2-(Phenylsulfanylmethyl)Indole-3-Carboxylate",
      "Ethyl 6-Bromo-4-(Dimethylaminomethyl)-5-Hydroxy-1-Methyl-2-(Phenylsulfanylmethyl)Indole-3-Carboxylate Hydrate Hydrochloride",
      "Ethyl 6-Bromo-4-[(Dimethylamino)Methyl]-5-Hydroxy-1-Methyl-2-[(Phenylsulfanyl)Methyl]-1H-Indole-3-Carboxylate",
      "1-Methyl-2-((Phenylthio)Methyl)-3-Carbethoxy-4-((Dimethylamino)Methyl)-5-Hydroxy-6-Bromindole Hydrochloride",
      "1H-Indole-3-Carboxylic Acid, 6-Bromo-4-((Dimethylamino)Methyl)-5-Hydroxy-1-Methyl-2-((Phenylthio)Methyl)-, Ethyl Ester",
      "1H-Indole-3-Carboxylic Acid, 6-Bromo-4-((Dimethylamino)Methyl)-5-Hydroxy-1-Methyl-2-((Phenylthio)Methyl)-, Ethyl Ester, Monohydrochloride, Hydrate",
      "1H-Indole-3-Carboxylic Acid, 6-Bromo-4-((Dimethylamino)Methyl)-5-Hydroxy-1-Methyl-2-((Phenylthio)Methyl)-, Ethyl Ester, Moonohydrochloride",
      "6-Bromo-4-Dimethylaminomethyl-5-Hydroxy-1-Methyl-2-Phenylsulfanylmethyl-1H-Indole-3-Carboxylic Acid Ethyl Ester",
      "6-Bromo-5-Hydroxy-4-Methylaminomethyl-1-Methyl-2-Benzenesulfenylmethylindole-3-Ethyl Carboxylate",
    ],
  },
  {
    primary_id: "D02VIV",
    names: [
      "2-Hydrazino-3-Methyl-4(3H)-Quinazolinone",
      "2-Hydrazino-3-Methylquinazolin-4(3H)-One",
      "61507-80-0",
      "2-Hydrazino-3-Methyl-3H-Quinazolin-4-One",
      "NSC657432",
      "CHEMBL503552",
      "2-Hydrazinyl-3-Methylquinazolin-4(3H)-One",
      "2-Hydrazinyl-3-Methylquinazolin-4-One",
      "BAS 00718750",
      "4(3H)-Quinazolinone,2-Hydrazinyl-3-Methyl-",
      "2-Hydrazinyl-3-Methyl-3,4-Dihydroquinazolin-4-One",
      "2-Hydrazino-3-Methyl-3-Hydroquinazolin-4-One",
      "AC1Q6DRE",
      "AC1L8CIY",
      "AC1Q3XQM",
      "2-Hydrazino-3-Methyl-3 H -Quinazolin-4-One",
      "Oprea1_318278",
      "Oprea1_401830",
      "MLS000713036",
      "CTK5B3345",
    ],
  },
  {
    primary_id: "D02VIW",
    names: ["DIHYDRORALOXIFENE", "CHEMBL14955", "BDBM50217538", "ZINC15919388"],
  },
  {
    primary_id: "D02VIZ",
    names: ["GR 196429"],
  },
  {
    primary_id: "D02VJI",
    names: ["Non-Anticoagulation Heparins"],
  },
  {
    primary_id: "D02VJJ",
    names: ["TACE Inhibitors"],
  },
  {
    primary_id: "D02VJP",
    names: [
      "Cobicistat",
      "1004316-88-4",
      "Cobicistat (GS-9350)",
      "GS 9350",
      "GS-9350",
      "UNII-LW2E03M5PG",
      "Cobicistat,GS-9350",
      "CHEBI:72291",
      "LW2E03M5PG",
      "Tybost",
      "Thiazol-5-Ylmethyl (2R,5R)-5-((S)-2-(3-((2-Isopropylthiazol-4-Yl)Methyl)-3-Methylureido)-4-Morpholinobutanamido)-1,6-Diphenylhexan-2-Ylcarbamate",
      "1,3-Thiazol-5-Ylmethyl [(2R,5R)-5-{[(2S)-2-({[(2-Isopropyl-1,3-Thiazol-4-Yl)Methyl](Methyl)Carbamoyl}amino)-4-(Morpholin-4-Yl)Butanoyl]amino}-1,6-Diphenylhexan-2-Yl]carbamate",
      "1,3-Thiazol-5-Ylmethyl",
    ],
  },
  {
    primary_id: "D02VJX",
    names: [
      "AVE 0991",
      "304462-19-9",
      "AVE-0991",
      "AVE0991",
      "UNII-68JR6NCI7I",
      "68JR6NCI7I",
      "GTPL5579",
      "SCHEMBL15045765",
      "KS-00000LDU",
      "DTXSID50184526",
      "C29H32N4O5S2",
      "MolPort-019-995-543",
      "BCP11067",
      "ZINC3927311",
      "3460AH",
      "FD5038",
      "AKOS027338221",
      "CS-1752",
      "HY-15778",
      "AK340230",
      "4CA-0212",
      "2-Thiophenesulfonamide, N-((Ethylamino)Carbonyl)-3-(4-((5-Formyl-4-Methoxy-2-Phenyl-1H-Imidazol-1-Yl)Methyl)Phenyl)-5-(2-Methylpropyl)-",
      "AB0087841",
      "B1007",
      "A820377",
    ],
  },
  {
    primary_id: "D02VJY",
    names: ["Biricodar"],
  },
  {
    primary_id: "D02VKC",
    names: ["NBQX"],
  },
  {
    primary_id: "D02VKU",
    names: ["Mast Cell Degranulating Peptide"],
  },
  {
    primary_id: "D02VKX",
    names: [
      "4-Adamantyl Resorcinol",
      "CHEMBL474953",
      "4-(Adamant-1-Yl)Resorcin",
      "4-(1-Adamantyl)Resorcinol",
      "SCHEMBL2420330",
      "NAJPJOQCMCYGLX-UHFFFAOYSA-N",
      "1-(2,4-Dihydroxyphenyl)Adamantane",
    ],
  },
  {
    primary_id: "D02VLL",
    names: ["ISIS 7942"],
  },
  {
    primary_id: "D02VLR",
    names: [
      "Acetic Acid 2-Hept-2-Ynylsulfanyl-Phenyl Ester",
      "Aphs",
      "209125-28-0",
      "2-(2-Heptynylthio)Phenol Acetate",
      "(2-Hept-2-Ynylsulfanylphenyl) Acetate",
      "CHEMBL345018",
      "O-(Acetophenyl)Hept-2-Ynyl Sulphide",
      "Phenol, 2-(2-Heptynylthio)-, Acetate",
      "AC1L1D69",
      "SCHEMBL3842943",
      "DTXSID40175069",
      "ZINC1903162",
      "BDBM50068160",
      "O-(Acetoxyphenyl)Hept-2-Ynyl Sulphide",
      "RT-011298",
      "FT-0669138",
    ],
  },
  {
    primary_id: "D02VLT",
    names: ["CholeraGarde Cholera Vaccine Live Attenuated", "CholeraGarde (TN)"],
  },
  {
    primary_id: "D02VLX",
    names: ["Beractant"],
  },
  {
    primary_id: "D02VMC",
    names: ["RP-1000"],
  },
  {
    primary_id: "D02VMJ",
    names: ["TRICLABENDAZOLE"],
  },
  {
    primary_id: "D02VNG",
    names: ["Sc-43076"],
  },
  {
    primary_id: "D02VNJ",
    names: [
      "CRTH2 Antagonists",
      "CRTH2 Antagonists (Allergic Asthma/Allergic Rhinitis/Inflammatory Disease)",
      "CRTH2 Antagonists (Allergic Asthma/Allergic Rhinitis/Inflammatory Disease), Abbott",
    ],
  },
  {
    primary_id: "D02VNY",
    names: ["Pleodrug"],
  },
  {
    primary_id: "D02VOJ",
    names: [
      "RWJ-51204",
      "5-(Ethoxymethyl)-7-Fluoro-N-(2-Fluorophenyl)-3-Oxo-1,2,3,5-Tetrahydropyrido[1,2-A]benzimidazole-4-Carboxamide",
    ],
  },
  {
    primary_id: "D02VPC",
    names: [
      "5-Hydroxy-1H-Indole-3-Acetic Acid",
      "5-Hydroxyindole-3-Acetic Acid",
      "54-16-0",
      "5-HIAA",
      "2-(5-Hydroxy-1H-Indol-3-Yl)Acetic Acid",
      "5-Hydroxyindoleacetic Acid",
      "5-Hydroxyheteroauxin",
      "(5-Hydroxy-1H-Indol-3-Yl)Acetic Acid",
      "5-Oxyindoleacetic Acid",
      "5-Hydroxy-IAA",
      "5-Hydroxyindolacetic Acid",
      "5-Hydroxy-3-Indoleacetic Acid",
      "5-Hydroxyindol-3-Ylacetic Acid",
      "Hydroxyindoleacetic Acid",
      "5-Hydroxyindolylacetic Acid",
      "1H-Indole-3-Acetic Acid, 5-Hydroxy-",
      "1321-73-9",
      "Indole-3-Acetic Acid, 5-Hydroxy-",
      "5-Hydroxyindoleacetate",
      "UNII-YHC763JY1P",
      "NSC 90432",
    ],
  },
  {
    primary_id: "D02VPR",
    names: ["ISIS 109491"],
  },
  {
    primary_id: "D02VPS",
    names: ["Anti-FGFR2 Humanized Mab"],
  },
  {
    primary_id: "D02VPW",
    names: ["[3H](-)CGP 12177"],
  },
  {
    primary_id: "D02VPX",
    names: [
      "Calcidiol",
      "Calcifediolum",
      "Calcifidiol",
      "Delakmin",
      "VDY",
      "Calcifediol Anhydrous",
      "BML2-E02",
      "Calcifediolum [INN-Latin]",
      "Ro 8-8892",
      "U-32070E",
      "(1R,3Z)-3-[(2E)-2-[(1R,7aR)-1-[(2R)-6-Hydroxy-6-Methylheptan-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylidenecyclohexan-1-Ol",
      "(1S,3Z)-3-[(2E)-2-[(1R,3aS,7aR)-1-[(2R)-6-Hydroxy-6-Methylheptan-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylidenecyclohexan-1-Ol",
      "(3S,5Z,7E)-9,10-Secocholesta-5,7,10(19)-Triene-3,25-Diol",
      "(3S,5Z,7E)-9,10-Secocholesta-5,7,10-Triene-3,25-Diol",
      "(3beta,5Z,7E)-9,10-Secocholesta-5,7,10(19)-Triene-3,25-Diol",
      "(5Z,7E)-(3S)-9,10-Secocholesta-5,7,10(19)-Triene-3,25-Diol",
      "(5Z,7E)-9,10-Seco-5,7,10(19)-Cholestatrien-3beta,25-Diol",
      "25(OH)D3",
      "25-(OH)Vitamin D3",
      "25-Hydroxycholecalciferol",
      "25-Hydroxycholecalciferol (Calcifediol)",
      "25-Hydroxycholescalciferol",
      "25-Hydroxyvitamin D",
      "25-Hydroxyvitamin D3 Monohydrate",
      "25-Hydroxyvitamin D3",
      "3-{2-[1-(5-HYDROXY-1,5-DIMETHYL-HEXYL)-7A-METHYL-OCTAHYDRO-INDEN-4-YLIDENE]-ETHYLIDENE}-4-METHYLENE-CYCLOHEXANOL",
      "5,6-Cis-25-Hydroxyvitamin D3",
      "5,6-Trans-25-Hydroxycholescalciferol",
      "5,6-Trans-9,10-Seco-5,7,10(19)-Cholestatrien-3beta,25-Diol",
      "9,10-Secocholesta-5,7,10(19)-Triene-3beta,25-Diol",
    ],
  },
  {
    primary_id: "D02VQK",
    names: ["LUF-5433"],
  },
  {
    primary_id: "D02VQO",
    names: ["Triazine Compound PC10"],
  },
  {
    primary_id: "D02VQU",
    names: ["STF-31"],
  },
  {
    primary_id: "D02VRI",
    names: ["Tricyclic Indole Compound 11"],
  },
  {
    primary_id: "D02VRN",
    names: ["KT-1"],
  },
  {
    primary_id: "D02VSL",
    names: ["JWH-133"],
  },
  {
    primary_id: "D02VSP",
    names: ["MK-7655"],
  },
  {
    primary_id: "D02VTB",
    names: ["BAY1193397"],
  },
  {
    primary_id: "D02VTV",
    names: ["PMID27376512-Compound-Figure3CN"],
  },
  {
    primary_id: "D02VUL",
    names: ["AIKO-151"],
  },
  {
    primary_id: "D02VUM",
    names: ["L-731120"],
  },
  {
    primary_id: "D02VUX",
    names: ["PF-217830"],
  },
  {
    primary_id: "D02VVG",
    names: ["D[Orn8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D02VVS",
    names: ["Filgrastim"],
  },
  {
    primary_id: "D02VVU",
    names: [
      "4-Benzenesulfonylamino-N-Hydroxy-Benzamide",
      "CHEMBL98345",
      "SCHEMBL15480538",
      "BDBM50105682",
      "N-Hydroxy-4-(Phenylsulfonamido)Benzamide",
      "4-(Phenylsulfonylamino)Benzohydroximic Acid",
    ],
  },
  {
    primary_id: "D02VXH",
    names: ["Imaging Agent"],
  },
  {
    primary_id: "D02VXW",
    names: ["(E)-4-(2-(Thiophen-2-Yl)Vinyl)Benzenesulfonamide", "CHEMBL1288801"],
  },
  {
    primary_id: "D02VYE",
    names: ["Opdivo + Yervoy"],
  },
  {
    primary_id: "D02VYI",
    names: ["Dibutyl 2,2,2-Trifluoro-1-Phenylethyl Phosphate", "CHEMBL573979"],
  },
  {
    primary_id: "D02VYR",
    names: ["Azaindole Derivative 7"],
  },
  {
    primary_id: "D02VZI",
    names: ["L-Argininosuccinic Acid"],
  },
  {
    primary_id: "D02VZP",
    names: ["Flesinoxan"],
  },
  {
    primary_id: "D02WAB",
    names: ["Diloxanide"],
  },
  {
    primary_id: "D02WBC",
    names: [
      "2-(2-Chlorophenyl)-2-Morpholinoethanamine",
      "866782-01-6",
      "2-(2-Chlorophenyl)-2-Morpholin-4-Ylethylamine",
      "2-(2-Chlorophenyl)-2-(Morpholin-4-Yl)Ethan-1-Amine",
      "CHEMBL382382",
      "4-Morpholineethanamine,b-(2-Chlorophenyl)-",
      "2-(2-Chlorophenyl)-2-Morpholin-4-Ylethanamine",
      "2-(2-Chlorophenyl)-2-Morpholinoethylamine",
      "2-(2-Chlorophenyl)-2-(Morpholin-4-Yl)Ethylamine",
      "Beta-Phenethylamine 6",
      "AC1MZFUY",
      "AC1Q53NI",
      "SCHEMBL8276869",
      "CTK5F7083",
      "BDBM11556",
      "DCA-S02-0",
      "DTXSID90397254",
      "MolPort-001-760-150",
      "KS-000029ZH",
      "4759AH",
      "SBB073200",
      "AR3509",
    ],
  },
  {
    primary_id: "D02WBU",
    names: ["2,2-DiMeBut-RYYRIK-NH2", "CHEMBL403588"],
  },
  {
    primary_id: "D02WBY",
    names: ["JNJ-5207787"],
  },
  {
    primary_id: "D02WCI",
    names: [
      "Pirfenidone",
      "Deskar",
      "Esbriet",
      "Pirespa",
      "Pirfenidona",
      "Pirfenidonum",
      "AMR 69",
      "P 2116",
      "AMR-69",
      "F-647",
      "KS-5041",
      "Pirfenidona [INN-Spanish]",
      "Pirfenidone (Deskar)",
      "Pirfenidone [USAN:INN]",
      "Pirfenidonum [INN-Latin]",
      "S-7701",
      "Pirfenidone (JAN/USAN/INN)",
      "5-Methyl-1-Phenyl-1H-Pyridin-2-One",
      "5-Methyl-1-Phenyl-2(1H)-Pyridone",
      "5-Methyl-1-Phenyl-2-(1H)-Pyridone",
      "5-Methyl-1-Phenylpyridin-2(1H)-One",
      "5-Methyl-1-Phenylpyridin-2-One",
    ],
  },
  {
    primary_id: "D02WCJ",
    names: [
      "Neurotensin(8-13)",
      "CHEMBL342252",
      "BDBM50240845",
      "(S)-2-{(2S,3R)-2-[(S)-2-({(S)-1-[(S)-2-((S)-2-Amino-5-Guanidino-Pentanoylamino)-5-Guanidino-Pentanoyl]-Pyrrolidine-2-Carbonyl}-Amino)-3-(4-Hydroxy-Phenyl)-Propionylamino]-3-Methyl-Pentanoylamino}-4-Methyl-Pentanoic Acid",
      "2-{(S)-2-[2-({(S)-1-[(S)-2-((S)-2-Amino-5-Guanidino-Pentanoylamino)-5-Guanidino-Pentanoyl]-Pyrrolidine-2-Carbonyl}-Amino)-3-(4-Hydroxy-Phenyl)-Propionylamino]-3-Methyl-Pentanoylamino}-4-(S)-Methyl-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D02WDO",
    names: ["MGN-1333"],
  },
  {
    primary_id: "D02WEL",
    names: ["VG-106", "Anticancer Cytokine, Viragen"],
  },
  {
    primary_id: "D02WEU",
    names: ["ApoCell", "Cell-Based Therpy (Iv Suspension, Inflammatory/Autoimmune Diseases), Tolarex"],
  },
  {
    primary_id: "D02WFA",
    names: ["Stearic Acid", "C18:0"],
  },
  {
    primary_id: "D02WFK",
    names: [
      "Cycloserine",
      "D-Cycloserine",
      "68-41-7",
      "Seromycin",
      "Orientomycin",
      "Oxamycin",
      "Cyclorin",
      "Cyclo-D-Serine",
      "Wasserina",
      "Farmiserina",
      "Tisomycin",
      "Cicloserina",
      "Miroseryn",
      "Closina",
      "D-4-Amino-3-Isoxazolidinone",
      "(R)-4-AMINOISOXAZOLIDIN-3-ONE",
      "Alpha-Cycloserine",
      "(+)-4-Amino-3-Isoxazolidinone",
      "Miroserina",
      "Cycloserinum",
      "D-4-Amino-3-Isoxazolidone",
      "Oxamicina",
      "(4R)-4-Aminoisoxazolidin-3-One",
      "PA 94",
      "(+)-Cycloserine",
      "(4R)-4-Amino-1,2-Oxazolidin-3-One",
      "Oxamicina [Italian]",
      "Cycloserin",
      "Micoserina",
      "Cicloserina [Italian]",
      "PA-94",
      "Tebemicina",
      "Novoserin",
      "D-Oxamycin",
      "Closerin",
      "C 3909",
      "C-9390",
      "C-9400",
      "Cicloserina [INN-Spanish]",
      "Cycloserinum [INN-Latin]",
      "D-CS",
      "DRG-0195",
      "I-1431",
      "K-300",
      "SC-49088",
      "Seromycin (TN)",
      "Cycloserine [INN:BAN:JAN]",
      "E-733-A",
      "RO-1-9213",
      "Cycloserine (JP15/USP/INN)",
      "D-Amino-3-Isoxazolidinone",
      "D-(+)-Cycloserine",
      "D-4-Amino-3-Isossazolidone",
      "D-4-Amino-3-Isossazolidone [Italian]",
      "FA6C7F8B-D080-4EA3-978F-1ECFB5A29D09",
      "R-(+)-Cycloserine",
      "R-4-Amino-3-Isoxazolidinone",
      "R(+)-4-Amino-3-Isoxazolidinone",
      "(4R)-4-Amino-3-Isoxazolidinone",
      "(R)-(+)-4-Amino-3-Isoxazolidinone",
      "(R)-(+)-Cycloserine",
      "(R)-4-AMINO-ISOXAZOLIDIN-3-ONE",
      "(R)-4-Amino-3-Isoxazolidinone",
      "(R)-4-Amino-3-Isoxazolidone",
      "(R)-Cycloserine",
      "3-Isoxazolidinone, 4-Amino-, (+)-(8CI)",
      "3-Isoxazolidinone, 4-Amino-, (4R)-(9CI)",
      "3-Isoxazolidinone, 4-Amino-, (R)",
      "3-Isoxazolidinone, 4-Amino-, D",
      "(R)-4-Amino-Isoxazolidin-3-One",
    ],
  },
  {
    primary_id: "D02WFN",
    names: ["MK-7622"],
  },
  {
    primary_id: "D02WFO",
    names: ["Antiviral Polymerase Inhibitors (HCV Infection)"],
  },
  {
    primary_id: "D02WGD",
    names: ["LOR-220"],
  },
  {
    primary_id: "D02WGN",
    names: ["YM-114"],
  },
  {
    primary_id: "D02WGY",
    names: ["Eprotirome"],
  },
  {
    primary_id: "D02WHY",
    names: ["Rubidium Chloride Rb82"],
  },
  {
    primary_id: "D02WII",
    names: [
      "TR-14035",
      "232271-19-1",
      "(S)-2-(2,6-Dichlorobenzamido)-3-(2',6'-Dimethoxy-[1,1'-Biphenyl]-4-Yl)Propanoic Acid",
      "CHEMBL91485",
      "N-(2,6-DICHLOROBENZOYL)-4-(2,6-DIMETHOXYPHENYL)-L-PHENYLALANINE",
      "C24H21Cl2NO5",
      "DRSJLVGDSNWQBI-SFHVURJKSA-N",
      "TR 14035",
      "SCHEMBL1222491",
      "BDBM16801",
      "CTK8C1530",
      "MolPort-023-334-229",
      "ZINC1490484",
      "ANW-66841",
      "N-Acyl-4-Arylphenylalanine Analogue.",
      "AKOS016008162",
      "CS-1812",
      "CS-W000631",
      "MP-2027",
      "NCGC00390619-01",
      "SB683698",
      "HY-15770",
      "AK-95353",
      "AJ-26339",
      "KB-210922",
      "TC-155586",
      "AB0077984",
      "AX8237663",
      "ST24020804",
      "Z4458",
    ],
  },
  {
    primary_id: "D02WIL",
    names: ["G15"],
  },
  {
    primary_id: "D02WIW",
    names: [
      "Dexbrompheniramine",
      "Dexbromfeniramina",
      "Dexbrompheniraminum",
      "Ilvin",
      "Parabromodylamine",
      "D-Brompheniramine",
      "Dexbromfeniramina [INN-Spanish]",
      "Dexbrompheniramine [INN:BAN]",
      "Dexbrompheniraminum [INN-Latin]",
      "N,N-Dimethyl-(3-(4-Bromphenyl)-3-(2-Pyridyl)Propyl)Amin",
      "(3-P-Bromphenyl-3-Pyrid-2'-Ylpropyl)Dimethylamin",
      "(3S)-3-(4-Bromophenyl)-N,N-Dimethyl-3-(Pyridin-2-Yl)Propan-1-Amine",
      "(3S)-3-(4-Bromophenyl)-N,N-Dimethyl-3-Pyridin-2-Ylpropan-1-Amine",
      "(S)-(+)-Brompheniramine",
      "(S)-Brompheniramine",
    ],
  },
  {
    primary_id: "D02WJB",
    names: ["PMID26815044-Compound-124"],
  },
  {
    primary_id: "D02WJT",
    names: [
      "1,3-Ditolylguanidine",
      "1,3-Di-O-Tolylguanidine",
      "97-39-2",
      "Di-O-Tolylguanidine",
      "DOTG",
      "Vulkacite DOTG",
      "Vulkacit DOTG",
      "Eveite DOTG",
      "Ditolylguanidine",
      "Vulkacit Dotg/C",
      "Nocceler DT",
      "Sanceler DT",
      "DOTG Accelerator",
      "Soxinol DT",
      "Akrochem DOTG",
      "Vulcafor DOTG",
      "1,3-Bis(O-Tolyl)Guanidine",
      "Guanidine, N,N'-Bis(2-Methylphenyl)-",
      "Diorthotolylguanidine",
      "1,3-Di-O-Tolyguanidine",
      "N,N'-Di-O-Tolylguanidine",
      "Guanidine, 1,3-Di-O-Tolyl-",
      "USAF A-6598",
      "NSC 473",
      "CNS 1001",
      "N,N'-Di-O-Tolueylguanidine",
      "UNII-LL2P01I17O",
      "1,3-Di-(2-Tolyl)Guanidine",
      "NSC 132023",
      "[3H]-Di-O-Tolylguanidine",
      "DITOLYLGUANIDINE",
    ],
  },
  {
    primary_id: "D02WKB",
    names: ["9-O-[2-(Phenylol-1-Yloxy)Hexyl]berberine Bromide", "CHEMBL605344"],
  },
  {
    primary_id: "D02WKZ",
    names: ["F-G-G-F-T-G-MeA-R-K-S-A-R-K-L-A-N-Q-CONH2"],
  },
  {
    primary_id: "D02WLI",
    names: ["DM-199"],
  },
  {
    primary_id: "D02WLS",
    names: [
      "Onyvax-105",
      "Antibody Therapy (Colerectal Cancer), CRC/Onyvax",
      "Vaccine (Colorectal Cancer), CRC/ImClone",
      "Vaccine (Colorectal Cancer), CRC/Onyvax",
    ],
  },
  {
    primary_id: "D02WMX",
    names: ["CR-6261"],
  },
  {
    primary_id: "D02WOQ",
    names: ["(R)(+)-7-Fluoro-2-P-Tolylchroman-4-One", "CHEMBL598151"],
  },
  {
    primary_id: "D02WOW",
    names: ["PMID25553724-Compound-WO2011127048 32"],
  },
  {
    primary_id: "D02WOX",
    names: ["Des-AA1,2,5-[D-Trp8,IAmp9]SRIF CH-275", "CHEMBL386023"],
  },
  {
    primary_id: "D02WQR",
    names: ["GSK-008A"],
  },
  {
    primary_id: "D02WQZ",
    names: ["Aprosulate Sodium", "Aprosulate", "LW-10082", "MW-2388"],
  },
  {
    primary_id: "D02WRE",
    names: [
      "ATS-907",
      "AK-138 Series",
      "ATS-907M1",
      "Rho Kinase Inhibitor (Ophthalmic Liquid, Glaucoma/Ocular Hypertension), Altheos",
    ],
  },
  {
    primary_id: "D02WRM",
    names: ["PMID27376512-Compound-MTC-424"],
  },
  {
    primary_id: "D02WRO",
    names: ["11-Beta-HSD1 Inhibitors", "11beta-Hydroxysteroid Dehydrogenase-1 Inhibitors (Metabolic Disorder), Sanofi"],
  },
  {
    primary_id: "D02WRU",
    names: [
      "PHTPP",
      "805239-56-9",
      "4-[2-PHENYL-5,7-BIS(TRIFLUOROMETHYL)PYRAZOLO[1,5-A]PYRIMIDIN-3-YL]PHENOL",
      "4-(2-Phenyl-5,7-Bis(Trifluoromethyl)Pyrazolo[1,5-A]pyrimidin-3-Yl)Phenol",
      "CHEMBL191066",
      "GTPL3461",
      "SCHEMBL15556029",
      "Pyrazolo[1,5-A]pyrimidine, 2a",
      "CTK8F1047",
      "BDBM19452",
      "CHEBI:93344",
      "DTXSID10458573",
      "AOB5403",
      "MolPort-023-276-532",
      "HMS3269L05",
      "PHTPP, &gt",
      "BCP29119",
      "ZINC13584996",
      "AKOS024457227",
      "NCGC00167819-01",
      "HY-103456",
      "RT-015048",
      "B7144",
      "CS-0027927",
      "BRD-K76568384-001-01-4",
    ],
  },
  {
    primary_id: "D02WRV",
    names: ["OrthoDerm"],
  },
  {
    primary_id: "D02WSA",
    names: ["5,7-Dihydroxy-3-Phenyl-3H-Quinazolin-4-One", "3-Arylquinazolinone, 1au", "CHEMBL378572", "BDBM19929"],
  },
  {
    primary_id: "D02WSB",
    names: ["CRS-207"],
  },
  {
    primary_id: "D02WST",
    names: [
      "Unfractionated Heparin",
      "Elaprin",
      "UFH (Solid Oral), Emisphere",
      "Unfractionated Heparin (Solid Oral, Eligen)",
      "SNAC/Heparin Capsule (Solid Oral), Emisphere",
      "SNAD/Heparin Tablet (Solid Oral), Emisphere",
      "Unfractionated Heparin (Solid Oral, Eligen), Emisphere",
    ],
  },
  {
    primary_id: "D02WSY",
    names: ["CVX-060"],
  },
  {
    primary_id: "D02WTN",
    names: [
      "Hexanoic Acid (2,6-Diphenylpyrimidin-4-Yl)Amide",
      "CHEMBL222432",
      "820961-43-1",
      "CTK3E2660",
      "DTXSID20464908",
      "BDBM50157668",
      "Pentanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D02WUC",
    names: ["Fenquizone"],
  },
  {
    primary_id: "D02WUU",
    names: [
      "2-(Phenylsulfonyl)-1-P-Tolylethanone",
      "38488-14-1",
      "CHEMBL392808",
      "Ethanone, 1-(4-Methylphenyl)-2-(Phenylsulfonyl)-",
      "4-Methylphenacylphenyl Sulfone",
      "SCHEMBL6202982",
      "CTK1A8876",
      "DTXSID20575987",
      "BDBM50212530",
      "ZINC28874639",
      "AKOS008967833",
      "1-P-Tolyl-2-(Phenylsulphonyl)Ethanone",
      "1-(4-Methylphenyl)-2-(Phenylsulfonyl)Ethanone",
      "2-(Benzenesulfonyl)-1-(4-Methylphenyl)Ethan-1-One",
    ],
  },
  {
    primary_id: "D02WUW",
    names: ["PLD-177"],
  },
  {
    primary_id: "D02WUY",
    names: ["[125I]CGP 64213", "[125I]CGP64213"],
  },
  {
    primary_id: "D02WUZ",
    names: ["Tyr-Pro-Phe-Phe-OCH2CH3", "CHEMBL505502"],
  },
  {
    primary_id: "D02WVT",
    names: ["E-3810"],
  },
  {
    primary_id: "D02WWM",
    names: ["LY97241"],
  },
  {
    primary_id: "D02WWT",
    names: ["Aryl Mannoside Derivative 19"],
  },
  {
    primary_id: "D02WXS",
    names: ["PMID25399762-Compound-Table 6-11"],
  },
  {
    primary_id: "D02WXX",
    names: ["ISIS 3801"],
  },
  {
    primary_id: "D02WYE",
    names: ["4-(2-Aminoethyl)-2-Cyclohexylphenol", "CHEMBL404150", "BDBM50372980"],
  },
  {
    primary_id: "D02WZA",
    names: [
      "6-(Benzothiazole-2-Sulfonyl)-2H-Pyridazin-3-One",
      "Benzothiazole, 19",
      "CHEMBL73330",
      "BDBM16453",
      "Heterocyclic Sulfonylpyridazinone, 19z",
    ],
  },
  {
    primary_id: "D02WZK",
    names: ["7-(Biphenyl-4-Yloxy)-1-Oxazol-2-Yl-Heptan-1-One", "CHEMBL126355", "BDBM50222394"],
  },
  {
    primary_id: "D02WZW",
    names: ["ADPS Topical"],
  },
  {
    primary_id: "D02XAC",
    names: ["MG-1102"],
  },
  {
    primary_id: "D02XAS",
    names: ["SGM-1019"],
  },
  {
    primary_id: "D02XAX",
    names: ["LY86057"],
  },
  {
    primary_id: "D02XBE",
    names: ["Oxygent"],
  },
  {
    primary_id: "D02XBN",
    names: ["ZPL521"],
  },
  {
    primary_id: "D02XBW",
    names: [
      "Urea",
      "Carbamide",
      "57-13-6",
      "Isourea",
      "Carbonyldiamide",
      "Ureophil",
      "Carbonyldiamine",
      "Carbamimidic Acid",
      "Pseudourea",
      "Ureaphil",
      "Urevert",
      "Carbaderm",
      "Alphadrate",
      "Keratinamin",
      "Calmurid",
      "Aquadrate",
      "Carbonyl Diamide",
      "Pastaron",
      "Urepearl",
      "Carbamide Resin",
      "Ultra Mide",
      "Varioform Ii",
      "Aqua Care",
      "Prespersion, 75 Urea",
      "Carmol",
      "Basodexan",
      "Harnstoff",
      "Nutraplus",
      "Mocovina",
      "Urea Solution",
      "Supercel 3000",
      "Aqua Care HP",
      "Ureacin-20",
      "Ureacin-40 Creme",
      "Ureacin-10 Lotion",
      "Polyurea",
      "Panafil",
      "Onychomal",
      "Hyanit",
      "Caswell No. 902",
      "Carbonyl Diamine",
      "Mocovina [Czech]",
      "Harnstoff [Ge",
    ],
  },
  {
    primary_id: "D02XBY",
    names: ["AGS-005"],
  },
  {
    primary_id: "D02XCE",
    names: ["S-Oxy Cysteine"],
  },
  {
    primary_id: "D02XDG",
    names: ["PMID26560530-Compound-3"],
  },
  {
    primary_id: "D02XEG",
    names: ["GEN-1"],
  },
  {
    primary_id: "D02XEI",
    names: [
      "DCVax-Ovarian",
      "DCVax-L",
      "Dendritic Cell-Based Immunotherapy (Ovarian Cancer), Northwest Biotherapeutics",
    ],
  },
  {
    primary_id: "D02XES",
    names: [
      "Cidofovir Derivatives",
      "Adefovir Derivatives, University Of California San Diego",
      "Antivirals (University Of California San Diego)",
      "Cidofovir Derivatives (Oral)",
      "HDP-PMEC",
      "ODE-CDV",
      "OLP-CDV",
      "Oral Cidofovir Derivatives, UCSD",
      "Cidofovir Derivatives (Oral), UCSD",
      "HDP-PME-5FC",
      "ODE(S)-HPMPA",
      "ODE-(S)-MPMPA",
      "1-O-Hexadecyloxy-Propyl-Cidofovir",
      "1-O-Hexadecyloxypropyl-CDV",
    ],
  },
  {
    primary_id: "D02XEX",
    names: [
      "3-Acetyl-11-Keto-Beta-Boswellic Acid",
      "AKBA",
      "67416-61-9",
      "3-O-Acetyl-11-Keto-Beta-Boswellic Acid",
      "AKbetaBA",
      "UNII-BS16QT99Q1",
      "Acetyl-11-Keto-Beta-Boswellic Acid",
      "CHEMBL237111",
      "BS16QT99Q1",
      "Acetyl-11-Keto--Boswellic Acid",
      "AKBA Cpd",
      "Acetyl-11-Keto-B-Boswellic Acid",
      "Acetyl-11-Ketoboswellic Acid",
      "Acetyl-11-Keto-Beta-Boswellic Acid, Boswellia Serrata",
      "3-Acetyl-11-Keto-",
      "3alpha-Acetoxy-11-Keto-Beta-Boswellic Acid",
      "Curator_000001",
      "MolPort-020-005-785",
      "HMMGKOVEOFBCAU-BCDBGHSCSA-N",
    ],
  },
  {
    primary_id: "D02XFA",
    names: ["HG-1387"],
  },
  {
    primary_id: "D02XFR",
    names: [
      "3-Chloro-N-(4-Methylthiazol-2-Yl)Benzamide",
      "3-Chloro-N-(4-Methyl-1,3-Thiazol-2-Yl)Benzamide",
      "CHEMBL212529",
      "AC1LI75J",
      "MolPort-003-812-188",
      "ZINC477397",
      "BDBM50186320",
      "AKOS003848769",
      "MCULE-7000942763",
    ],
  },
  {
    primary_id: "D02XGC",
    names: ["PF-3864086"],
  },
  {
    primary_id: "D02XGF",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D02XGS",
    names: ["2-Fluorophenyl 4-(Hexyloxy)Phenylcarbamate", "CHEMBL590243"],
  },
  {
    primary_id: "D02XHC",
    names: [
      "Aloisine A",
      "ALOISINE A",
      "4-(7-Butyl-5H-Pyrrolo[2,3-B]pyrazin-6-Yl)Phenol",
      "496864-16-5",
      "RP107",
      "6-PHENYL[5H]PYRROLO[2,3-B]PYRAZINE",
      "CHEMBL75680",
      "7-N-Butyl-6-(4-Hydroxyphenyl)[5H]pyrrolo[2,3-B]pyrazine",
      "RP-107",
      "ALH",
      "4-{7-Butyl-5H-Pyrrolo[2,3-B]pyrazin-6-Yl}phenol",
      "1ung",
      "SCHEMBL80147",
      "BDBM7377",
      "GTPL5924",
      "AC1NS169",
      "4-(7-Butyl-5H-Pyrrolo[2,3-B]pyrazin-6-Yl)-Phenol",
      "CHEBI:93641",
      "CTK8D4068",
      "DTXSID30416115",
      "HMS3229A14",
      "RM-39",
      "BCP26893",
      "ZINC2540737",
      "ACT06534",
      "IN1539",
      "HSCI1_000219",
      "MFCD04973541",
      "AKOS005145972",
      "CCG-206814",
      "DB07364",
      "RTC-063070",
      "AJ-39131",
    ],
  },
  {
    primary_id: "D02XHD",
    names: ["KI-0805"],
  },
  {
    primary_id: "D02XHH",
    names: ["5-[5-Bromo-Indan-(1Z)-Ylidenemethyl]-1H-Imidazole", "AC1O70GX", "SCHEMBL4231849", "CHEMBL174579"],
  },
  {
    primary_id: "D02XHM",
    names: ["3-[10-(Benzylmethylamino)Decyloxy]xanthen-9-One", "CHEMBL390598"],
  },
  {
    primary_id: "D02XHV",
    names: ["FluBlok"],
  },
  {
    primary_id: "D02XIF",
    names: ["HYDROXY[3-(6-METHYLPYRIDIN-2-YL)PROPYL]FORMAMIDE"],
  },
  {
    primary_id: "D02XIY",
    names: ["Octreotide"],
  },
  {
    primary_id: "D02XJI",
    names: ["Emedastine"],
  },
  {
    primary_id: "D02XJJ",
    names: ["LM11A-31"],
  },
  {
    primary_id: "D02XJW",
    names: [
      "5-Methylsulfanyl-Thiophene-2-Carboxamidine",
      "CHEMBL28890",
      "2-Thiophenecarboximidamide, 5-(Methylthio)-",
      "SCHEMBL5982145",
      "AXSQTCBARFBKPH-UHFFFAOYSA-N",
      "BDBM50099912",
      "5-Methylthiothiophene-2-Carboxamidine",
    ],
  },
  {
    primary_id: "D02XJY",
    names: [
      "Midodrine",
      "Alphamine",
      "Amatine",
      "Gutron",
      "Hipertan",
      "Metligene",
      "Midamine",
      "Midodrin",
      "Midodrina",
      "Midodrinum",
      "ProAmatine",
      "MIDODRINE HYDROCHLORIDE",
      "Midodrine HCL",
      "Midodrine Monohydrochloride",
      "Midodrine Hydrochloride [USAN]",
      "A 4020 Linz",
      "St 1085",
      "TS 701",
      "A-4020 Linz",
      "Midodrina [INN-Spanish]",
      "Midodrine (INN)",
      "Midodrine [BAN:INN]",
      "Midodrine [INN:BAN]",
      "Midodrine Hydrochloride [USAN:JAN]",
      "Midodrinum [INN-Latin]",
      "Pro-Amatine",
      "Proamatine (TN)",
      "ST-1085",
      "St. Peter 224",
      "Midodrine Hydrochloride (JAN/USAN)",
      "Pro-Amatine (TN)",
      "DL-N1-(Beta-Hydroxy-2,5-Dimethoxyphenethyl)Glycinamid",
      "N-[2-(2,5-Dimethoxyphenyl)-2-Hydroxyethyl]glycinamide",
      "N-{2-[2,5-Bis(Methyloxy)Phenyl]-2-Hydroxyethyl}glycinamide",
      "[+/-]-1-[2,5-Dimethoxyphenyl]-2-Glycinamidoethanol",
      "Acetamide, 2-Amino-N-(Beta-Hydroxy-2,5-Dimethoxyphenethyl)-, Hydrochloride",
      "Acetamide, 2-Amino-N-(2-(2,5-Dimethoxyphenyl)-2-Hydroxyethyl)-, Monohydrochloride",
      "(+-)-1-(2',5'-Dimethoxyphenyl)-2-Glycinamidoethanol Hydrochloride",
      "(+-)-2-Amino-N-(Beta-Hydroxy-2,5-Dimethoxyphenethyl)Acetamide Monohydrochloride",
      "(+-)-Midodrine Hydrochloride",
      "(+/-)-1-(2,5-Dimethoxyphenyl)-2-Glycinamidoethanol",
      "(-)-Midodrin Hydrochloride",
      "(-)-Midodrine Hydrochloride",
      "(RS)-N'-(Beta-Hydroxy-2,5-Dimethoxy-Phenethyl)Glycinamid",
      "1-(2',5'-Dimethoxyphenyl)-2-Glycinamidoethanol",
      "1-(2',5'-Dimethoxyphenyl)-2-Glycinamidoethanol Hydrochloride",
      "1-(2,5-Dimethoxyphenyl)-2-Glycinamidoethanol",
      "2-Amino-N-(2,5-Dimethoxy-Beta-Hydroxyphenethyl)Acetamid",
      "2-Amino-N-(2,5-Dimethoxy-Beta-Hydroxyphenethyl)Acetamide",
      "2-Amino-N-(2,5-Dimethoxy-Beta-Hydroxyphenethylacetamide",
      "2-Amino-N-(2-(2,5-Dimethoxyphenyl)-2-Hydroxyethyl)Acetamide Monohydrochloride",
      "2-Amino-N-(2-(2,5-Dimethoxyphenyl)-2-Hyyroxyethyl)Acetamide Monohydrochloride",
      "2-Amino-N-[2-(2,5-Dimethoxyphenyl)-2-Hydroxyethyl]acetamide",
      "2-Amino-N-[2-(2,5-Dimethoxyphenyl)-2-Hydroxyethyl]acetamide Hydrochloride",
    ],
  },
  {
    primary_id: "D02XKC",
    names: ["Imvamune"],
  },
  {
    primary_id: "D02XKD",
    names: ["3-Bromo-4,4',5-Trimethoxy-(Z)-Stilbene"],
  },
  {
    primary_id: "D02XKE",
    names: [
      "1-Oleoyl Glycerol",
      "2,3-Dihydroxypropyl Oleate",
      "Monoolein",
      "Glyceryl Monooleate",
      "1-Monoolein",
      "111-03-5",
      "1-Oleoylglycerol",
      "Glyceryl Oleate",
      "1-Oleoyl-Rac-Glycerol",
      "1-Glyceryl Oleate",
      "1-Monooleoylglycerol",
      "Glycerol 1-Monooleate",
      "Rac-1-Monoolein",
      "Aldo MO",
      "Aldo HMO",
      "Danisco MO 90",
      "1-Monooleoyl-Rac-Glycerol",
      "Glycerin 1-Monooleate",
      "Monooleoylglycerol",
      "Oleic Monoglyceride",
      "Rac-1-Monooleoylglycerol",
      "Olein, 1-Mono-",
      "Oleoylglycerol",
      "Olicine",
      "Peceol",
      "Supeol",
      "Alpha-Monoolein",
      "Sinnoester Ogc",
      "Oleylmonoglyceride",
      "Emalsy MO",
      "Emalsy OL",
      "Dimodan LSQK",
      "Glycerol Alpha-Monooleat",
    ],
  },
  {
    primary_id: "D02XKJ",
    names: [
      "5-Methoxy-1-Phenyl-1H-Benzoimidazole",
      "1H-Benzimidazole, 5-Methoxy-1-Phenyl-",
      "38205-85-5",
      "1-Phenylbenzimidazole Deriv. 64",
      "AC1NS4LX",
      "CHEMBL76202",
      "BDBM3847",
      "SCHEMBL8006768",
      "5-Methoxy-1-Phenylbenzimidazole",
      "CTK1B5049",
      "DTXSID40416161",
      "5-Methoxy-1-Phenyl-1H-Benzimidazole",
      "AKOS023169650",
      "5-Substituted 1-Phenylbenzimidazole 6",
    ],
  },
  {
    primary_id: "D02XKK",
    names: ["FRM-0962"],
  },
  {
    primary_id: "D02XLC",
    names: ["5-Methylpyrrolo[3,4-C]carbazole-1,3(2H,6H)-Dione", "CHEMBL201723"],
  },
  {
    primary_id: "D02XLI",
    names: ["LY-2979165", "Bipolar Disorder Therapy, Eli Lilly"],
  },
  {
    primary_id: "D02XMD",
    names: ["BGS-649"],
  },
  {
    primary_id: "D02XMM",
    names: ["Midaglizole"],
  },
  {
    primary_id: "D02XMN",
    names: ["Application Submitted"],
  },
  {
    primary_id: "D02XMQ",
    names: [
      "1-Amino-4-Methoxyanthracene-9,10-Dione",
      "1-Amino-4-Methoxyanthraquinone",
      "116-83-6",
      "4-Methoxy-1-Anthraquinonylamine",
      "1-Amino-4-Methoxy-9,10-Anthraquinone",
      "UNII-BCH9T82ZKZ",
      "NSC 84162",
      "BCH9T82ZKZ",
      "BRN 2218945",
      "ANTHRAQUINONE, 1-AMINO-4-METHOXY-",
      "CHEMBL600008",
      "9,10-Anthracenedione, 1-Amino-4-Methoxy- (9CI)",
      "NSC84162",
      "AC1L1QQQ",
      "Cid_8321",
      "9, 1-Amino-4-Methoxy-",
      "MLS000585344",
      "1-Methoxy-4-Aminoanthraquinone",
      "SCHEMBL2303569",
      "CTK4A9995",
      "DTXSID40151262",
      "KXOGXZAXERYOTC-UHFFFAOYSA-N",
      "HMS2536C23",
      "ZINC3874026",
      "NSC-84162",
      "BDBM50306771",
      "AKOS001666411",
    ],
  },
  {
    primary_id: "D02XNH",
    names: ["C-Linked Disaccharide Biphenyl Mannoside Derivative 6"],
  },
  {
    primary_id: "D02XNJ",
    names: ["CogniXan"],
  },
  {
    primary_id: "D02XNT",
    names: [
      "BMS-A",
      "CCR2 Antagonists (Inflammatory/Metabolic Diseases)",
      "CCR2 Antagonists (Inflammatory/Metabolic Diseases), Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D02XNU",
    names: [
      "Carbaphosphonate",
      "CRB",
      "[1R-(1ALPHA,3BETA,4ALPHA,5BETA)]-5-(PHOSPHONOMETHYL)-1,3,4-TRIHYDROXYCYCLOHEXANE-1-CARBOXYLIC ACID",
      "AC1L9HHC",
      "DB02592",
      "(1S,3R,4R,5S)-1,3,4-Trihydroxy-5-(Phosphonomethyl)Cyclohexanecarboxylic Acid",
      "1,3beta,4alpha-Trihydroxy-5beta-(Phosphonomethyl)Cyclohexane-1beta-Carboxylic Acid",
      "(1S,3R,4R,5S)-1,3,4-Trihydroxy-5-(Phosphonomethyl)Cyclohexane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D02XNW",
    names: ["VX-680"],
  },
  {
    primary_id: "D02XOK",
    names: ["Levomilnacipran"],
  },
  {
    primary_id: "D02XOM",
    names: ["HX-01"],
  },
  {
    primary_id: "D02XOP",
    names: ["2-Methyl-1-(Thiophen-2-Yl)Pent-1-En-3-One Oxime"],
  },
  {
    primary_id: "D02XPB",
    names: ["4-(4-Benzyloxy-3-Methoxy-Benzylamino)-Benzamidine", "Benzylamine 6", "CHEMBL179938", "BDBM13580"],
  },
  {
    primary_id: "D02XPR",
    names: ["(S)-DHPA"],
  },
  {
    primary_id: "D02XQB",
    names: ["ISIS 102563"],
  },
  {
    primary_id: "D02XQE",
    names: ["PMID29473428-Compound-39"],
  },
  {
    primary_id: "D02XQX",
    names: ["ETHIOFENCARB"],
  },
  {
    primary_id: "D02XRG",
    names: [
      "4-(Ethylamino)Naphthalen-1-Ol",
      "CHEMBL577335",
      "4-(Ethylamino)-1-Naphthol",
      "SCHEMBL12599061",
      "BDBM50303902",
      "AKOS022516437",
    ],
  },
  {
    primary_id: "D02XSA",
    names: ["Stiripentol", "Diacomit", "ME-2080", "Stiripentol (Oral)", "Stiripentol (Oral), Laboratoires Biocodex"],
  },
  {
    primary_id: "D02XSH",
    names: ["DIMETHYLGRISABINE", "Dimethylgrisabine", "CHEMBL450147", "Grisabine Dimethyl Ether"],
  },
  {
    primary_id: "D02XSL",
    names: ["GlyT1 PET Radiotracers"],
  },
  {
    primary_id: "D02XTN",
    names: ["RO5545965"],
  },
  {
    primary_id: "D02XTS",
    names: [
      "3-(2,2-Diphenyl-Vinyl)-Pyridine",
      "3-(2,2-Diphenylethenyl)Pyridine",
      "3-Pyridylmethylene 33",
      "BDBM8638",
      "CHEMBL370317",
      "3-(2,2-Diphenylvinyl)Pyridine",
    ],
  },
  {
    primary_id: "D02XTT",
    names: ["LV305"],
  },
  {
    primary_id: "D02XUC",
    names: ["VF001"],
  },
  {
    primary_id: "D02XUD",
    names: ["4-(Biphenyl-3-Yl)-5-(Piperidin-4-Yl)Isoxazol-3-Ol", "CHEMBL223324", "BDBM50208721", "J3.502.571E"],
  },
  {
    primary_id: "D02XUE",
    names: ["TDI-0106"],
  },
  {
    primary_id: "D02XUN",
    names: ["Trivalent Seasonal Influenza Vaccine"],
  },
  {
    primary_id: "D02XUY",
    names: ["AZ-1297442"],
  },
  {
    primary_id: "D02XVC",
    names: ["Phenylate Derivative 2"],
  },
  {
    primary_id: "D02XVV",
    names: ["3-Hydroxy-4,7-Dimethyl-6H-Benzo[c]chromen-6-One", "CHEMBL204959", "BDBM50180476"],
  },
  {
    primary_id: "D02XWJ",
    names: ["AG 1296"],
  },
  {
    primary_id: "D02XXA",
    names: ["3-Phenyl-1-Propyl-Pyrrolidine", "CHEMBL82541", "SCHEMBL1546830"],
  },
  {
    primary_id: "D02XXF",
    names: ["BMN044"],
  },
  {
    primary_id: "D02XXK",
    names: ["(9-Benzyl-9H-Purin-6-Yl)-Cyclopropyl-Amine", "CHEMBL7583"],
  },
  {
    primary_id: "D02XXN",
    names: ["Evacetrapib"],
  },
  {
    primary_id: "D02XXQ",
    names: ["MEDl-557"],
  },
  {
    primary_id: "D02XXT",
    names: [
      "Diphencyprone",
      "Diphenylcyclopropenone",
      "886-38-4",
      "2,3-Diphenylcycloprop-2-En-1-One",
      "2,3-Diphenylcyclopropenone",
      "2,3-Diphenylcycloprop-2-Enone",
      "Cyclopropenone, Diphenyl-",
      "DPCP",
      "2-Cyclopropen-1-One, 2,3-Diphenyl-",
      "1,2-Diphenylcyclopropen-3-One",
      "Cyclopropenone, 2,3-Diphenyl-",
      "UNII-I7G14NW5EC",
      "2,3-Diphenyl-2-Cyclopropen-1-One",
      "CHEBI:53074",
      "EINECS 212-948-4",
      "NSC 57541",
      "I7G14NW5EC",
      "Diphenylcyclopropenone, 98%",
      "Diphenylcycloprop-2-En-1-One",
      "HCIBTBXNLVOFER-UHFFFAOYSA-N",
      "GEO-01240",
      "MFCD00001311",
      "NCGC00166113-01",
      "CPD000449319",
    ],
  },
  {
    primary_id: "D02XXW",
    names: ["Allogeneic/Autologous Adult Mesenchymal Stem Cell Therapy"],
  },
  {
    primary_id: "D02XYA",
    names: ["Astuprotimut-R"],
  },
  {
    primary_id: "D02XYB",
    names: ["SARIPIDEM"],
  },
  {
    primary_id: "D02XYT",
    names: ["Furopyridine Derivative 1"],
  },
  {
    primary_id: "D02XYV",
    names: [
      "(2R,5R)-Delta-Methyl-Alpha-Acetylenic Putrescine",
      "6-Heptyne-2,5-Diamine",
      "Methylacetylenic Putrescine",
      "Methyl-Acetylenicputrescine",
      "Mdl 72175",
      "8-Methyl-8-Acetylenic Putrescine",
      "Delta-Methyl-Alpha Acetylene Putrescine",
      "Hept-6-Yne-2,5-Diamine",
      "MDL-72175",
      "AC1L3U4J",
      "81645-70-7",
      "SCHEMBL4808395",
      "CTK5F9486",
      "AC1Q2832",
      "AKOS006338563",
      "1-Ethynyl-4-Methyl-1,4-Butanediamine",
    ],
  },
  {
    primary_id: "D02XYW",
    names: ["CD19-Directed CAR-T Cells"],
  },
  {
    primary_id: "D02XZI",
    names: [
      "Pyridoxyl-N,O-Cycloserylamide-5-Monophosphate",
      "[5-Hydroxy-6-Methyl-4-[[(3-Oxo-1,2-Oxazolidin-4-Yl)Amino]methyl]pyridin-3-Yl]methyl Dihydrogen Phosphate",
      "(5-Hydroxy-6-Methyl-4-{[(3-Oxo-1,2-Oxazolidin-4-Yl)Amino]methyl}pyridin-3-Yl)Methyl Dihydrogen Phosphate",
      "AC1L1ESQ",
      "PYRIDOXYL-N,O-CYCLOSERYLAMIDE-5-MONOPHOSPHATE",
      "CTK7H6662",
      "L-PYRIDOXYL-N,O-CYCLOSERYLAMIDE-5-MONOPHOSPHATE",
      "L-[3-HYDROXY-2-METHYL-5-PHOSPHONOOXYMETHYL-PYRIDIN-4-YLMETHYL]-N,O-CYCLOSERYLAMIDE",
      "[3-HYDROXY-2-METHYL-5-PHOSPHONOOXYMETHYL-PYRIDIN-4-YLMETHYL]-N,O-CYCLOSERYLAMIDE",
      "Phosphoric Acid [4-[[(3-O",
    ],
  },
  {
    primary_id: "D02XZO",
    names: ["16-(Pyridin-2-Yl)Methylene-Estradiol"],
  },
  {
    primary_id: "D02YAD",
    names: ["Vivia-008"],
  },
  {
    primary_id: "D02YAQ",
    names: ["GW-974"],
  },
  {
    primary_id: "D02YBD",
    names: ["CAL-401"],
  },
  {
    primary_id: "D02YBP",
    names: ["Mequitamium Iodide"],
  },
  {
    primary_id: "D02YBV",
    names: ["[Val4]AVP", "4-Val-Argipressin"],
  },
  {
    primary_id: "D02YCH",
    names: ["Icodextrin"],
  },
  {
    primary_id: "D02YCL",
    names: ["SB-36131"],
  },
  {
    primary_id: "D02YCN",
    names: ["PMID29130358-Compound-Figure14(5)"],
  },
  {
    primary_id: "D02YCY",
    names: ["(R)-11-Amino-2-Methoxyaporphine", "CHEMBL506721"],
  },
  {
    primary_id: "D02YDG",
    names: ["PMID28092474-Compound-32z"],
  },
  {
    primary_id: "D02YDM",
    names: ["Dichloroally-Lawsone"],
  },
  {
    primary_id: "D02YDU",
    names: ["4-Dipropylamino-Cyclohex-1-Enecarbonitrile", "CHEMBL164631", "SCHEMBL153730"],
  },
  {
    primary_id: "D02YEE",
    names: ["N-Benzyl-6-(3,3,3-Trifluoropropoxy)Nicotinamide", "CHEMBL576449"],
  },
  {
    primary_id: "D02YEG",
    names: ["VBY-376"],
  },
  {
    primary_id: "D02YEO",
    names: ["NBTXR3"],
  },
  {
    primary_id: "D02YEY",
    names: ["FPL-62064"],
  },
  {
    primary_id: "D02YFZ",
    names: [
      "2-Piperazin-1-Yl-Benzonitrile",
      "1-(2-Cyanophenyl)Piperazine",
      "111373-03-6",
      "2-(Piperazin-1-Yl)Benzonitrile",
      "2-Piperazin-1-Ylbenzonitrile",
      "2-(1-Piperazino)Benzonitrile",
      "Benzonitrile, 2-(1-Piperazinyl)-",
      "1-(2-Cyanophenyl)-Piperazine",
      "2-(1-Piperazinyl)Benzonitrile",
      "CHEMBL275943",
      "2-Piperazinylbenzenecarbonitrile",
      "PubChem9347",
      "2-Piperazinobenzonitrile",
      "AC1MC4BA",
      "AC1Q4R0F",
      "(2-Cyano-Phenyl)-Piperazine",
      "KSC491G1H",
      "SCHEMBL233169",
      "1-(2-Cyanophenyl) Piperazine",
      "ACMC-20999f",
      "1-(2-Cyano Phenyl) Piperazine",
      "Jsp000853",
    ],
  },
  {
    primary_id: "D02YGR",
    names: ["PMID12470711C3"],
  },
  {
    primary_id: "D02YGX",
    names: ["PMID27019002-Compound-16"],
  },
  {
    primary_id: "D02YGZ",
    names: ["5-Tert-Butyl-2-(4-Fluoro-Phenyl)-[1,3]dithiane", "CHEMBL315953", "ZINC584614859", "ZINC584614855"],
  },
  {
    primary_id: "D02YHA",
    names: ["Geranyl Diphosphate"],
  },
  {
    primary_id: "D02YHD",
    names: ["HX 531"],
  },
  {
    primary_id: "D02YIJ",
    names: ["Isocarbacyclin", "9-O-Methanoprostaglandin I"],
  },
  {
    primary_id: "D02YIP",
    names: [
      "AbGn-168",
      "AbGn-168H",
      "Antibody 168 (Autoimmune Diseases), Boehringer Ingelheim/AbGenomics",
      "Anti-CD162 MAb (Psoriasis), Boehringer/AbGenomics",
    ],
  },
  {
    primary_id: "D02YIW",
    names: [
      "5-(N,N-Hexamethylene)-Amiloride",
      "5-(N,N-Hexamethylene)Amiloride",
      "Hexamethyleneamiloride",
      "1428-95-1",
      "Hexamethylene Amiloride",
      "HMA-5",
      "HMA",
      "C12H18ClN7O",
      "3-Amino-6-Chloro-5-(1-Homopiperidyl)-N-(Diaminomethylene)Pyrazinecarboxamide",
      "N,N-HEXAMETHYLENEAMILORIDE",
      "CHEBI:76400",
      "3-Amino-5-(Azepan-1-Yl)-6-Chloro-N-(Diaminomethylene)Pyrazine-2-Carboxamide",
      "3-Amino-N-(Aminoiminomethyl)-6-Chloro-5-(Hexahydro-1H-Azepin-1-Yl)Pyrazinecarboxamide",
      "Pyrazinecarboxamide, 3-Amino-N-(Aminoiminomethyl)-6-Chloro-5-(Hexahydro-1H-Azepin-1-Yl)-",
      "5-HMA",
      "Spectrum_001596",
    ],
  },
  {
    primary_id: "D02YIZ",
    names: [
      "Erythromycin",
      "Abboticin",
      "Abomacetin",
      "Acneryne",
      "Acnesol",
      "Aknemycin",
      "Aknin",
      "AustriaS",
      "Benzamycin",
      "Derimer",
      "Deripil",
      "Dotycin",
      "Dumotrycin",
      "ERY",
      "ERYC",
      "Emgel",
      "Emuvin",
      "Emycin",
      "Endoeritrin",
      "Erecin",
      "Erisone",
      "Eritomicina",
      "Eritrocina",
      "Eritromicina",
      "Ermycin",
      "Eros",
      "Eryacne",
      "Eryacnen",
      "Erycen",
      "Erycette",
      "Erycin",
      "Erycinum",
      "Eryderm",
      "Erydermer",
      "Erygel",
      "Eryhexal",
      "Erymax",
      "Erymed",
      "Erysafe",
      "Erytab",
      "Erythro",
      "Erythroderm",
      "Erythrogran",
      "Erythroguent",
      "Erythromid",
      "Erythromycine",
      "Erythromycinum",
      "Erytop",
      "Erytrociclin",
      "Ilocaps",
      "Iloticina",
      "Ilotycin",
      "Inderm",
      "IndermRetcin",
      "Latotryd",
      "Lederpax",
      "Mephamycin",
      "Mercina",
      "Oftamolets",
      "Paediathrocin",
      "Pantoderm",
      "Pantodrin",
      "Pantomicina",
      "Pharyngocin",
      "Primacine",
      "Propiocine",
      "Proterytrin",
      "Retcin",
      "Robimycin",
      "Romycin",
      "Sansac",
      "Staticin",
      "Stiemicyn",
      "Stiemycin",
      "Tiloryth",
      "Tiprocin",
      "Torlamicina",
      "Wemid",
      "Akne Cordes Losung",
      "Aknederm Ery Gel",
      "Benzamycin Pak",
      "ERYTHROMYCIN STEARATE",
      "Eryc Sprinkles",
      "Erythromycin A",
      "Erythromycin Lactate",
      "Erythromycin Ointment",
      "Erythromycin Base",
      "Erythromycin Intravenous",
      "Erythromycin Sodium Lauryl Sulfate",
      "Inderm Gel",
      "Oftalmolosa Cusi Eritromicina",
      "Skid Gel E",
      "Theramycin Z",
      "Udima Ery Gel",
      "E0751",
      "Eryc 125",
      "Erythromast 36",
      "Ak-Mycin",
      "Akne-Mycin",
      "Del-Mycin",
      "E-Base",
      "E-Glades",
      "E-Mycin",
      "E-Solve 2",
      "ERYC (Base)",
      "Emu-V",
      "Emu-Ve",
      "Erimycin-T",
      "Eritromicina [INN-Spanish]",
      "Ery-B",
      "Ery-Diolan",
      "Ery-Sol",
      "Ery-Tab",
      "Ery-Maxin",
      "Eryc (TN)",
      "Eryc-125",
      "Eryc-250",
      "Erygel (TN)",
      "Erythra-Derm",
      "Erythro-Statin",
      "Erythro-Teva",
      "Erythromycin & VRC3375",
      "Erythromycine [INN-French]",
      "Erythromycinum [INN-Latin]",
      "Ilosone (TN)",
      "Ilosone (Estolate)",
      "Ilotycin T.S",
      "Kesso-Mycin",
      "N-Methylerythromycin A",
      "PCE Dispertab (Base)",
      "Pce (TN)",
      "R-P Mycin",
      "Sans-Acne",
      "Staticin (TN)",
      "T-Stat",
      "Taimoxin-F",
      "A/T/S",
      "Akne-Mycin (TN)",
      "C-Solve-2",
      "E-Base (Base)",
      "E-Mycin (Base)",
      "Ery-Tab (Base)",
      "Erythromycin [INN:BAN:JAN]",
      "Ilotycin T.S.",
      "T-Stat (TN)",
      "E-Mycin, Erycin, Robimysin",
      "Erythromycin (JP15/USP/INN)",
      "Erythromycin, Compd. With Monododecyl Sulfate, Sodium Salt",
      "Sulfuric Acid, Monododecyl Ester, Sodium Salt, Compd. With Erythromycin",
      "Adecane-2,10-Dione (Non-Preferred Name)",
      "Erythromycin A, T-Stat, Pantomicina, HSDB 3074, Erytab, DRG-0279",
      "Ery",
    ],
  },
  {
    primary_id: "D02YJL",
    names: ["Taurochenodeoxycholic Acid"],
  },
  {
    primary_id: "D02YKI",
    names: ["PF-04995274"],
  },
  {
    primary_id: "D02YKK",
    names: ["NADP+"],
  },
  {
    primary_id: "D02YLT",
    names: ["PMID29671355-Compound-52"],
  },
  {
    primary_id: "D02YMI",
    names: ["Trans-4-Hydroxy-Proline", "4-Hydroxyproline"],
  },
  {
    primary_id: "D02YMQ",
    names: ["6-Phenyl-3-(Pyridin-4-Yl)-1H-Indole", "CHEMBL207621"],
  },
  {
    primary_id: "D02YNE",
    names: ["ISIS 140158"],
  },
  {
    primary_id: "D02YNH",
    names: ["CP-809101"],
  },
  {
    primary_id: "D02YNI",
    names: ["OTX-015"],
  },
  {
    primary_id: "D02YNU",
    names: ["2-Hydroxybenzamide Derivative 1"],
  },
  {
    primary_id: "D02YOO",
    names: ["NISOXETINE"],
  },
  {
    primary_id: "D02YOQ",
    names: [
      "NUCB-1000",
      "DNA Plasmid Expressing ShRNA (HBV), Nucleonics",
      "EiRNA Therapeutics (HBV), Nucleonics",
      "NUC-500-001",
      "SiRNA Therapeutics Expressed Via Plasmid (HBV), Nucleonics",
    ],
  },
  {
    primary_id: "D02YPE",
    names: [
      "1,2-Dihydroxy-10H-Anthracen-9-One",
      "CHEMBL123692",
      "1,2-Dihydroxy-9-Anthron",
      "SCHEMBL6418805",
      "ZINC13606491",
      "BDBM50060891",
    ],
  },
  {
    primary_id: "D02YPG",
    names: [
      "Etomidate",
      "Absele",
      "Amidate",
      "Ethnor",
      "Ethomidate",
      "Etomidato",
      "Etomidatum",
      "Hypnomidate",
      "Radenarcon",
      "Radenarkon",
      "Etomidic Acid",
      "R 16659",
      "R 26490",
      "R26490",
      "Amidate (TN)",
      "Amidate (Pharmaceutical)",
      "Amidate, Etomidate",
      "D-Etomidate",
      "Etomidato [INN-Spanish]",
      "Etomidatum [INN-Latin]",
      "R-26490",
      "Etomidate (USAN/INN)",
      "Etomidate [USAN:BAN:INN]",
      "Ethyl 3-(1-Phenylethyl)Imidazole-4-Carboxylate",
      "Ethyl 1-(1-Phenylethyl)-1H-Imidazole-5-Carboxylate",
      "R-(+)-Ethyl 1-(1-Phenylethyl)-1H-Imidazole-5-Carboxylate",
      "(+)-Ethyl 1-(Alpha-Methylbenzyl)Imidazole-5-Carboxylate",
      "(+)-Etomidate",
      "(R)-(+)-1-(Alpha-Methylbenzyl)Imidazole-5-Carboxylic Acid Ethyl Ester",
      "(D)-Etomidate",
      "1-(1-Phenylethyl)-1H-Imidazole-5-Carboxylic Acid Ethyl Ester",
      "1-(1-Phenylethyl)-Imidazole-5-Carboxylic Acid, Ethyl Ester",
      "1-(Alpha-Methylbenzyl)-1H-Imidazole-5-Carboxylic Acid Ethyl Ester",
      "1H-Imidazole-5-Carboxylic Acid, 1-((1R)-1-Phenylethyl)-, Ethyl Ester",
      "3-(1-Phenyl-Ethyl)-3H-Imidazole-4-Carboxylic Acid Ethyl Ester",
    ],
  },
  {
    primary_id: "D02YPU",
    names: ["Pyridoxamine"],
  },
  {
    primary_id: "D02YQJ",
    names: ["B-5354a", "B-5354b", "B-5354c", "Sphingosine Kinase Inhibitors, Sankyo"],
  },
  {
    primary_id: "D02YQO",
    names: [
      "SCH-442416",
      "316173-57-6",
      "SCH 442416",
      "UNII-ZMC4G1W59S",
      "2-(Furan-2-Yl)-7-(3-(4-Methoxyphenyl)Propyl)-7H-Pyrazolo[4,3-E][1,2,4]triazolo[1,5-C]pyrimidin-5-Amine",
      "SCH442416",
      "ZMC4G1W59S",
      "CHEMBL136689",
      "SCH-442,416",
      "2-(Furan-2-Yl)-7-(3-(4-Methoxyphenyl)Propyl)-7H-Pyrazolo-[4,3-E][1,2,4]triazolo[1,5-C]pyrimidin-5-Amine",
      "SCHEMBL981184",
      "GTPL3283",
      "CTK8B9468",
      "CHEBI:92814",
      "DTXSID70443263",
      "MolPort-023-276-442",
      "ZINC602847",
      "HMS3269G07",
      "BCP01942",
      "MFCD08703126",
      "BDBM50094037",
      "ANW-62570",
      "AKOS016003924",
      "SCH442,416",
      "NCGC00159575-01",
      "AJ-23684",
      "AC-27414",
    ],
  },
  {
    primary_id: "D02YRD",
    names: ["4SCAR19 And 4SCAR70"],
  },
  {
    primary_id: "D02YRX",
    names: ["C-0333158"],
  },
  {
    primary_id: "D02YSI",
    names: ["FR-901277", "CHEMBL437538", "BDBM50080264"],
  },
  {
    primary_id: "D02YSN",
    names: ["2-Aminomethyl-5-(P-T-Butylphenyl)Tetrahydrofuran", "CHEMBL447750", "SCHEMBL2690807"],
  },
  {
    primary_id: "D02YSO",
    names: ["GX-C4", "CTLA-4/Fc Region Of A Hybrid IgG4/IgD Antibody (Fusion Protein, Autoimmune Disease), Genexine"],
  },
  {
    primary_id: "D02YSX",
    names: [
      "DIMEBOLIN",
      "Dimebolin",
      "Dimebon",
      "3613-73-8",
      "Latrepirdine",
      "Dimebone",
      "Dimeboline",
      "Preparation 84",
      "UNII-OD9237K1Z6",
      "C21H25N3",
      "BRN 0622478",
      "CHEMBL589390",
      "JNODQFNWMXFMEV-UHFFFAOYSA-N",
      "OD9237K1Z6",
      "2,8-Dimethyl-5-(2-(6-Methylpyridin-3-Yl)Ethyl)-2,3,4,5-Tetrahydro-1H-Pyrido[4,3-B]indole",
      "2,3,4,5-Tetrahydro-2,8-Dimethyl-5-[2-(6-Methyl-3-Pyridinyl)Ethyl]-1H-Pyrido[4,3-B]indole",
      "2,3,4,5-Tetrahydro-2,8-Dimethyl-5-(2-(6-Methyl-3-Pyridyl)Ethyl)-1H-Pyrido(4,3-B)Indole",
      "2,8-Dimethyl-5-[2-(6-Methylpyridin-3-Yl)Ethyl]-3,4-Dihydro-1H-Pyrido[4,3-B]indole",
    ],
  },
  {
    primary_id: "D02YTB",
    names: ["Pyrrolo-Pyrimidine Derivative 9"],
  },
  {
    primary_id: "D02YUQ",
    names: ["Benzyl Amine Derivative 1"],
  },
  {
    primary_id: "D02YVB",
    names: ["ISIS 110065"],
  },
  {
    primary_id: "D02YVM",
    names: ["Zicronapine"],
  },
  {
    primary_id: "D02YVZ",
    names: ["2-(6-Phenylhexyl)Pyrido[2,3-D]pyrimidin-4(3H)-One"],
  },
  {
    primary_id: "D02YWD",
    names: ["B27PD"],
  },
  {
    primary_id: "D02YWE",
    names: ["EBP-1597"],
  },
  {
    primary_id: "D02YWM",
    names: ["BIO-500"],
  },
  {
    primary_id: "D02YWV",
    names: [
      "GR-159897",
      "CHEMBL2110370",
      "GR 159897",
      "158848-32-9",
      "5-FLUORO-3-[2-[4-METHOXY-4-[[(R)-PHENYLSULPHINYL]METHYL]-1-PIPERIDINYL]ETHYL]-1H-INDOLE",
      "GR-159,897",
      "GTPL2115",
      "CHEBI:92912",
      "MolPort-023-276-059",
      "ZINC7999073",
      "BDBM50212987",
      "AKOS025147300",
      "AKOS024456505",
      "NCGC00381706-02",
      "KB-309739",
      "SR-01000597395",
      "SR-01000597395-1",
      "5-Fluoro-3-[2-[4-Methoxy-4-[[(R)-Phenylsulfinyl]methyl]piperidin-1-Yl]ethyl]-1H-Indole",
      "5-Fluoro-3-[2-[4-Methoxy-4-[[?-Phenylsulphinyl]methyl]-1-Piperidinyl]ethyl]-1H-Indole",
    ],
  },
  {
    primary_id: "D02YWW",
    names: ["IDM-1"],
  },
  {
    primary_id: "D02YXB",
    names: ["Infanrix Hep B", "DTPa Hep B Vaccine, SB Biologicals"],
  },
  {
    primary_id: "D02YXE",
    names: ["ISIS 25124"],
  },
  {
    primary_id: "D02YXI",
    names: ["Ac-WVTH[hArg]LAGLLS[hArg]SGGVVRKNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D02YXP",
    names: ["UNC1999"],
  },
  {
    primary_id: "D02YXQ",
    names: ["GT-2016"],
  },
  {
    primary_id: "D02YXR",
    names: ["MM3A6S", "CHEMBL379928"],
  },
  {
    primary_id: "D02YYF",
    names: [
      "Bretylium",
      "Bretylum",
      "Bretylium Tolsylate",
      "Bretylium Tosylate(USAN)",
      "N-(2-Bromobenzyl)-N,N-Dimethylethanaminium",
      "N-Ethyl-N,N-Dimethyl-2-Bromobenzenemethanaminium",
      "Benzenemethanaminium, 2-Bromo-N-Ethyl-N,N-Dimethyl-, Salt With 4-Methylbenzenesulfonic Acid (1:1)",
      "(2-Bromobenzyl)Ethyldimethylaminium",
      "(2-Bromophenyl)Methyl-Ethyl-Dimethylazanium",
      "(O-Bromobenzyl)Ethyldimethylammonium P-Toluenesulfonate",
      "2-Bromo-N-Ethyl-N,N-Dimethylbenzenemethanaminium",
    ],
  },
  {
    primary_id: "D02YYQ",
    names: ["5'-Deoxy-5'-Ureidoadenosine", "CHEMBL231952", "5''-Deoxy-5''-Ureidoadenosine"],
  },
  {
    primary_id: "D02YYS",
    names: ["BDNA-001"],
  },
  {
    primary_id: "D02YZK",
    names: ["RO-5303253"],
  },
  {
    primary_id: "D02ZAN",
    names: ["SparVax Recombinant Protective Antigen Anthrax Vaccine"],
  },
  {
    primary_id: "D02ZAR",
    names: ["SGC Activator 2"],
  },
  {
    primary_id: "D02ZAT",
    names: ["VGV-S", "Antibacterial (TPT, Sepsis/Bacterial Infection), Viral Genetics"],
  },
  {
    primary_id: "D02ZBW",
    names: ["LC-150444"],
  },
  {
    primary_id: "D02ZCE",
    names: ["AD-337"],
  },
  {
    primary_id: "D02ZCM",
    names: ["Ad26.ENVA.01 HIV-1 Recombinant Vaccine"],
  },
  {
    primary_id: "D02ZCP",
    names: [
      "5-Phenylsulfanyl-2,4-Quinazolinediamine",
      "5-PHENYLSULFANYL-2,4-QUINAZOLINEDIAMINE",
      "TCMDC-131943",
      "5-(Phenylsulfanyl)Quinazoline-2,4-Diamine",
      "5-Phenylsulfanylquinazoline-2,4-Diamine",
      "TQ3",
      "AC1L9JD6",
      "SCHEMBL2125192",
      "CHEMBL100239",
      "BDBM18045",
      "GW997",
      "ZINC5974663",
      "5-Phenylthioquinazoline-2,4-Diamine",
      "2,4-Diamino-5-Phenylthio-Quinazoline",
      "DB04163",
      "5-Phenylsulfanyl-Quinazoline-2,4-Diamine",
    ],
  },
  {
    primary_id: "D02ZEE",
    names: ["2'-Hydroxy-3-Methoxy-Biphenyl-4-Carbonitrile", "Diarylphenol, 5b", "CHEMBL503575", "BDBM25431"],
  },
  {
    primary_id: "D02ZEM",
    names: ["OBERADILOL MONOETHYL MALEATE"],
  },
  {
    primary_id: "D02ZFN",
    names: [
      "(+/-)-Threo-3',4'-Dichlororitalinol Methyl Ether",
      "CHEMBL1253378",
      "BDBM50327167",
      "(+/-)-Threo-3'',4''-Dichlororitalinol Methyl Ether",
    ],
  },
  {
    primary_id: "D02ZGI",
    names: ["Secalciferol"],
  },
  {
    primary_id: "D02ZGN",
    names: ["1-(6-Amino-4-Methylpyridin-2-Yl)Propan-2-Ol", "CHEMBL500364"],
  },
  {
    primary_id: "D02ZGO",
    names: ["PMID26004420-Compound-WO2010129379A"],
  },
  {
    primary_id: "D02ZGY",
    names: ["PMID30185082-Compound-53"],
  },
  {
    primary_id: "D02ZHC",
    names: ["PMID25980951-Compound-4"],
  },
  {
    primary_id: "D02ZHW",
    names: ["177Lu-DOTA-Octreotate (Neuroendocrine Tumors), Advanced Accelerator Applications"],
  },
  {
    primary_id: "D02ZIO",
    names: ["2,7-Bis[2-(Butylamino)Acetamido]anthraquinone", "SCHEMBL4294896", "CHEMBL494076"],
  },
  {
    primary_id: "D02ZIQ",
    names: ["1-(3-Chlorophenyl)-2-(Piperidin-1-Yl)Propan-1-One", "2-Piperidino-3'-Chloropropiophenone"],
  },
  {
    primary_id: "D02ZIY",
    names: ["GSK-3beta Inhibitor XI"],
  },
  {
    primary_id: "D02ZJI",
    names: ["Levalbuterol"],
  },
  {
    primary_id: "D02ZKB",
    names: ["WAY-126227"],
  },
  {
    primary_id: "D02ZKL",
    names: ["Reglixane"],
  },
  {
    primary_id: "D02ZKR",
    names: ["BCX-3408"],
  },
  {
    primary_id: "D02ZLE",
    names: ["2-(3-BENZOYLPHENOXY)ETHYL(HYDROXY)FORMAMIDE"],
  },
  {
    primary_id: "D02ZLM",
    names: [
      "F-991",
      "Asthma Therapeutic, Fornix",
      "Immunoglobulin Light Chain Antagonist, Fornix",
      "Inflammatory Bowel Disease Therapeutic, Fornix",
      "Multiple Sclerosis Therapeutic, Fornix",
    ],
  },
  {
    primary_id: "D02ZMB",
    names: ["Urelumab"],
  },
  {
    primary_id: "D02ZMH",
    names: ["ISIS 1931"],
  },
  {
    primary_id: "D02ZMI",
    names: ["AA-29504"],
  },
  {
    primary_id: "D02ZML",
    names: ["GW-5823"],
  },
  {
    primary_id: "D02ZNH",
    names: ["TT-310"],
  },
  {
    primary_id: "D02ZNQ",
    names: ["Streptonigrin"],
  },
  {
    primary_id: "D02ZPG",
    names: ["Aryl Urea Derivative 1"],
  },
  {
    primary_id: "D02ZPM",
    names: ["Anpirtoline", "D-16949"],
  },
  {
    primary_id: "D02ZPN",
    names: ["NN-414"],
  },
  {
    primary_id: "D02ZPT",
    names: ["ISIS 2324"],
  },
  {
    primary_id: "D02ZPV",
    names: ["UCB-101333-3"],
  },
  {
    primary_id: "D02ZPZ",
    names: ["5-((E)-Styryl)-Quinazoline-2,4-Diamine", "CHEMBL164845", "AC1O6QWN", "ZINC5974460", "BDBM50036484"],
  },
  {
    primary_id: "D02ZQH",
    names: ["N-Ethylmaleimide"],
  },
  {
    primary_id: "D02ZQK",
    names: [
      "N-3-(4-Bromophenyl)Propyl Imidazole",
      "CHEMBL386256",
      "1H-Imidazole, 1-[3-(4-Bromophenyl)Propyl]-",
      "SCHEMBL11404089",
      "BDBM50191795",
    ],
  },
  {
    primary_id: "D02ZQM",
    names: ["6-Pyridin-3-Yl-1,2,3,4-Tetrahydronaphthalen-2-Ol", "CHEMBL461458"],
  },
  {
    primary_id: "D02ZQN",
    names: ["PMID28270021-Compound-WO2013009582Example16"],
  },
  {
    primary_id: "D02ZQS",
    names: ["GSK269962A"],
  },
  {
    primary_id: "D02ZRO",
    names: ["(3-Bromo-Phenyl)-Quinazolin-4-Yl-Amine"],
  },
  {
    primary_id: "D02ZRX",
    names: [
      "1-Methyl-5-Phenyl-Piperidin-2-One",
      "CHEMBL55261",
      "SCHEMBL16591470",
      "1-Methyl-5-Phenylpiperidine-2-One",
      "BDBM50091702",
    ],
  },
  {
    primary_id: "D02ZSO",
    names: ["L-775606"],
  },
  {
    primary_id: "D02ZSU",
    names: ["NS-49"],
  },
  {
    primary_id: "D02ZSX",
    names: ["OCTYL_GALLATE"],
  },
  {
    primary_id: "D02ZTJ",
    names: ["Sulfasalazine"],
  },
  {
    primary_id: "D02ZTS",
    names: ["ISIS 109526"],
  },
  {
    primary_id: "D02ZUG",
    names: ["Atogepant"],
  },
  {
    primary_id: "D02ZUO",
    names: ["Forodesine"],
  },
  {
    primary_id: "D02ZUT",
    names: [
      "TRP6-01",
      "Brain Ischemia Therapeutic, Theraptosis",
      "Caspase Inhibitors (Neonatal Brain Injury)",
      "Caspase Inhibitors (Neonatalbrain Injury), Chiesi",
    ],
  },
  {
    primary_id: "D02ZUW",
    names: ["Carbamide Derivative 26"],
  },
  {
    primary_id: "D02ZVY",
    names: ["Diphenyl Purine Derivative 4"],
  },
  {
    primary_id: "D02ZWC",
    names: [
      "[125I]PD142308",
      "(3S)-3-[[(2R)-2-(2-Adamantyloxycarbonylamino)-3-(1H-Indol-3-Yl)-2-Methylpropanoyl]amino]-4-(4-Amino-3-Iodophenyl)Butanoic Acid",
    ],
  },
  {
    primary_id: "D02ZWG",
    names: ["SR-80027A"],
  },
  {
    primary_id: "D02ZWJ",
    names: ["EP-94"],
  },
  {
    primary_id: "D02ZWO",
    names: ["DEF-201"],
  },
  {
    primary_id: "D02ZWR",
    names: ["IMO-2134"],
  },
  {
    primary_id: "D02ZWX",
    names: ["AdoC(GABA)Arg6", "CHEMBL611125"],
  },
  {
    primary_id: "D02ZXM",
    names: ["Thioguanine"],
  },
  {
    primary_id: "D02ZYA",
    names: [
      "6-Cyclohexylmethoxy-Pyrimidine-2,4,5-Triamine",
      "6-(Cyclohexylmethoxy)Pyrimidine-2,4,5-Triamine",
      "Pyrimidine Deriv. 16",
      "AC1NS76Q",
      "CHEMBL71036",
      "BDBM5588",
      "SCHEMBL6263731",
      "IMNSQZHRHASLAR-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D02ZYI",
    names: ["IPP-201007"],
  },
  {
    primary_id: "D02ZYV",
    names: ["Biaryl Mannoside Derivative 15"],
  },
  {
    primary_id: "D02ZZA",
    names: ["(S)-2-Amino-1-(4-Ethylthiophenyl)-Propane", "CHEMBL387777", "BDBM50213676"],
  },
  {
    primary_id: "D02ZZC",
    names: ["NN-8630"],
  },
  {
    primary_id: "D02ZZG",
    names: ["LEO 43204"],
  },
  {
    primary_id: "D02ZZQ",
    names: ["8-Hydroxy-5,7,9-Trimethyl-Delta-Carboline", "CHEMBL458947"],
  },
  {
    primary_id: "D02ZZU",
    names: ["DM-CHOC-PEN"],
  },
  {
    primary_id: "D036P7",
    names: ["Chimeric Natriuretic Peptide"],
  },
  {
    primary_id: "D03AAC",
    names: ["W146"],
  },
  {
    primary_id: "D03AAV",
    names: ["ProLindaun"],
  },
  {
    primary_id: "D03ABH",
    names: ["Deriglidole"],
  },
  {
    primary_id: "D03ACE",
    names: ["ASP5094"],
  },
  {
    primary_id: "D03ACT",
    names: [
      "PRS-211375 Iv",
      "Cannabinor",
      "Bicyclic Cannabinoids, Pharmos",
      "HU-308",
      "PRS-211058",
      "PRS-211096",
      "PRS-211335",
      "PRS-211359",
      "PRS-211375",
      "CB2-Selective Cannabinoid Platform, Pharmos",
      "Oral Cannabinor (Pain), Pharmos",
      "PRS-211375 (Oral, Pain), Pharmos",
      "PRS-211375 (Iv, Inflammation/Autoimmune Disease), Pharmos",
    ],
  },
  {
    primary_id: "D03AEG",
    names: ["CBI-008"],
  },
  {
    primary_id: "D03AER",
    names: [
      "A-641359",
      "CHEMBL381203",
      "N-(4-{4-Amino-1-[1-(Tetrahydro-2h-Pyran-4-Yl)Piperidin-4-Yl]-1h-Pyrazolo[3,4-D]pyrimidin-3-Yl}-2-Methoxyphenyl)-1-Methyl-1h-Indole-2-Carboxamide",
      "N-(4-(4-Amino-1-(1-(Tetrahydro-2H-Pyran-4-Yl)Piperidin-4-Yl)-1H-Pyrazolo[3,4-D]pyrimidin-3-Yl)-2-Methoxyphenyl)-1-Methyl-1H-Indole-2-Carboxamide",
      "SCHEMBL4822105",
      "BDBM50175207",
    ],
  },
  {
    primary_id: "D03AFF",
    names: ["JNJ-64007957"],
  },
  {
    primary_id: "D03AFX",
    names: ["CM-2397"],
  },
  {
    primary_id: "D03AGD",
    names: [
      "BWA868C",
      "3-Benzyl-5-(6-Carboxyhexyl)-1-(2-Cyclohexyl-2-Hydroxyethylamino)Hydantoin",
      "BW A868C",
      "118675-50-6",
      "BW-A868C",
      "BW A 868C",
      "BW-A-868C",
      "C25H37N3O5",
      "(+/-)-3-Benzyl-5-(6-Carboxyhexyl)-1-(2-Cyclohexyl-2-Hydroxyethylamino)Hydantoin",
      "4-Imidazolidineheptanoic Acid, 3-((2-Cyclohexyl-2-Hydroxyethyl)Amino)-2,5-Dioxo-1-(Phenylmethyl)-",
      "4-Imidazolidineheptanoicacid, 3-[(2-Cyclohexyl-2-Hydroxyethyl)Amino]-2,5-Dioxo-1-(Phenylmethyl)-",
      "3-[(2-Cyclohexyl-2-Hydroxyethyl)Amino]-2,5-Dioxo-1-(Phenylmethyl)- 4-Imidazolidineheptanoic",
      "BWA-868C",
      "BWA 868C",
      "[3H]BWA868C",
    ],
  },
  {
    primary_id: "D03AGK",
    names: ["PF-05251749"],
  },
  {
    primary_id: "D03AGV",
    names: ["N-(3-Methanesulfonyl-4-Methyl-Benzoyl)-Guanidine"],
  },
  {
    primary_id: "D03AHJ",
    names: ["Elaidoylamide"],
  },
  {
    primary_id: "D03AHQ",
    names: ["Ac-[CFWKFC]-NH2", "CHEMBL1163471"],
  },
  {
    primary_id: "D03AHW",
    names: ["Acarbose Derived Hexasaccharide"],
  },
  {
    primary_id: "D03AIP",
    names: ["S-36212"],
  },
  {
    primary_id: "D03AJF",
    names: ["SPN-810"],
  },
  {
    primary_id: "D03AJS",
    names: ["SERGLIFLOZIN A"],
  },
  {
    primary_id: "D03AJU",
    names: ["LCI699"],
  },
  {
    primary_id: "D03AJW",
    names: ["N-(3-Benzooxazol-7-Yl-Propyl)-Butyramide", "CHEMBL124914"],
  },
  {
    primary_id: "D03AJX",
    names: [
      "PS-341",
      "1132709-16-0",
      "[(1S)-3-Methyl-1-[[(2R)-3-Phenyl-2-(Pyrazine-2-Carbonylamino)Propanoyl]amino]butyl]boronic Acid",
      "PS 341 (Pharmaceutical)",
      "MG 341",
      "NSC 681239",
      "NCGC00168751-01",
      "(1S,2R)-Bortezomib",
      "Bortezomib [USAN:INN]",
      "SCHEMBL3676",
      "AC1L3R1E",
      "CHEMBL1530",
      "Bortezomib Impurity (S,S-Isomer)",
      "Bortezomib Impurity (R,R-Isomer)",
      "Bortezomib Impurity (R,S-Isomer)",
      "N-((1S)-1-Benzyl-2-(((1R)-1-(Dihydroxyboranyl)-3-Methylbutyl)Amino)-2-Oxoethyl)Pyrazinecarboxamide",
      "Boronic Acid, (3-Methyl-1-((1-Oxo-3-Phenyl-2-",
    ],
  },
  {
    primary_id: "D03AKH",
    names: ["CER-001"],
  },
  {
    primary_id: "D03AKL",
    names: ["ATLIPROFEN METHYL ESTER"],
  },
  {
    primary_id: "D03ALC",
    names: ["Oxindole 16 (Compound 3)"],
  },
  {
    primary_id: "D03ALS",
    names: ["VA-DIFTET", "Diphtheria-Tetanus Vaccine (Pediatric Im Suspension), Finlay Institute"],
  },
  {
    primary_id: "D03AMR",
    names: ["SR-973"],
  },
  {
    primary_id: "D03AND",
    names: ["RAF265"],
  },
  {
    primary_id: "D03ANS",
    names: [
      "SB-525334",
      "356559-20-1",
      "SB 525334",
      "SB525334",
      "6-(2-(Tert-Butyl)-5-(6-Methylpyridin-2-Yl)-1H-Imidazol-4-Yl)Quinoxaline",
      "6-[2-TERT-BUTYL-5-(6-METHYL-PYRIDIN-2-YL)-1H-IMIDAZOL-4-YL]-QUINOXALINE",
      "CHEMBL401570",
      "6-[2-(1,1-DiMethylethyl)-5-(6-Methyl-2-Pyridinyl)-1H-IMidazol-4-Yl]quinoxaline",
      "C21H21N5",
      "6-[2-Tert-Butyl-5-(6-Methylpyridin-2-Yl)-1H-Imidazol-4-Yl]quinoxaline",
      "6-(2-Tert-Butyl-5-(6-Methylpyridin-2-Yl)-1H-Imidazol-4-Yl)Quinoxaline",
      "MLS006011134",
      "SCHEMBL373474",
      "QCR-68",
      "SCHEMBL10000768",
      "CTK4H5088",
      "CHEBI:91391",
      "AOB5969",
    ],
  },
  {
    primary_id: "D03AOP",
    names: ["DS-8500"],
  },
  {
    primary_id: "D03AOX",
    names: ["Triazole Derivative 5"],
  },
  {
    primary_id: "D03APL",
    names: ["AS-1403"],
  },
  {
    primary_id: "D03APM",
    names: ["ABT-110"],
  },
  {
    primary_id: "D03APP",
    names: ["Diphenylamine-2-Carboxylic Acid"],
  },
  {
    primary_id: "D03AQZ",
    names: ["GMB-Vax"],
  },
  {
    primary_id: "D03ARS",
    names: ["Salicylic Acid Derivative 6"],
  },
  {
    primary_id: "D03ASG",
    names: ["Piperidinyl Pyrazole Derivative 1"],
  },
  {
    primary_id: "D03ATH",
    names: ["RES-701-1"],
  },
  {
    primary_id: "D03ATI",
    names: ["ITV-2"],
  },
  {
    primary_id: "D03ATU",
    names: [
      "PD-158780",
      "171179-06-9",
      "PD158780",
      "Pd 158780",
      "N4-(3-Bromophenyl)-N6-Methylpyrido[3,4-D]pyrimidine-4,6-Diamine",
      "AC1L1IRV",
      "CHEMBL53753",
      "PD 158780",
      "4-N-(3-Bromophenyl)-6-N-Methylpyrido[3,4-D]pyrimidine-4,6-Diamine",
      "AK160389",
      "4-(3-Bromoanilino)-6-Methylaminopyrido[3,4-D]pyrimidine",
      "4-[(3-Bromophenyl)Amino]-6-(Methylamino)Pyrido[3,4-D]pyrimidine",
      "4-[(3-Bromophenyl)Amino]-6-(Methylamino)-Pyrido[3,4-D]pyridimine",
      "Pyrido[3,4-D]pyrimidine-4,6-Diamine, N4-(3-Bromophenyl)-N6-Methyl-",
    ],
  },
  {
    primary_id: "D03AUN",
    names: ["RG1512"],
  },
  {
    primary_id: "D03AVA",
    names: ["SNG-8033"],
  },
  {
    primary_id: "D03AVS",
    names: ["SR13668"],
  },
  {
    primary_id: "D03AWH",
    names: ["AM-4768", "CHEMBL238359", "BDBM50228075"],
  },
  {
    primary_id: "D03AXQ",
    names: ["Soluble FePPi"],
  },
  {
    primary_id: "D03AYF",
    names: ["3-Chlorophenyl 4-Butoxybenzylcarbamate", "CHEMBL596874", "SCHEMBL5222756"],
  },
  {
    primary_id: "D03AYQ",
    names: ["EB-1020"],
  },
  {
    primary_id: "D03AZB",
    names: ["Neo-1528", "Analgesic (Visceral Chronic Pain), Neorphys"],
  },
  {
    primary_id: "D03AZC",
    names: ["Ac-YRMEHdFRWG-NH2", "CHEMBL266879"],
  },
  {
    primary_id: "D03AZI",
    names: [
      "D-Mannose 1-Phosphate",
      "Mannose 1-Phosphate",
      "Mannose-1-P",
      "D-Mannose-1-Phosphate",
      "D-Mannose 1-Phosphates",
      "CHEBI:35374",
      "27251-84-9",
      "AC1LD8PO",
      "Delta-Mannose-1-Phosphate",
      "Mannose 1-Phosphoric Acid",
      "Delta-Mannose 1-Phosphates",
      "Epitope ID:145013",
      "D-Mannopyranose-1-Phosphate",
      "D-Mannopyranose, 1-Phosphate",
      "SCHEMBL284741",
      "Alpha-Delta-Mannose 1-Phosphate",
      "Mannose 1-Phosphate-Mannopyranose",
      "D-Mannose, 1-(Dihydrogen Phosphate)",
      "DB02867",
      "D-Mannopyranose 1-(Dihydrogen Phosphate)",
      "1-(Dihydrogen Phosphate)-D-Mannopyranose",
      "D-Mannopyranose, 1-(D",
    ],
  },
  {
    primary_id: "D03AZK",
    names: ["Sodium Zirconium Cyclosilicate"],
  },
  {
    primary_id: "D03AZR",
    names: [
      "Berubicin",
      "Berubicin Hydrochloride",
      "RTA-744",
      "RTA-769",
      "WP-744",
      "WP-769",
      "Anthracycline Derivative Topoisomerase II Inhibitor (Cancer), MD Anderson",
      "Anthracycline Derivative Topoisomerase II Inhibitor (Cancer), Reata",
      "Berubicin (Intravenous), Reata Pharmaceuticals",
      "Berubicin (Oral, Cancer)",
      "Anthracycline Topoisomerase II Inhibitor (Oral, Cancer), MD Anderson Cancer Center",
      "Anthracycline Topoisomerase II Inhibitor (Oral, Cancer), Reata Pharmaceuticals",
      "Berubicin (Oral, Cancer), Reata Pharmaceuticals",
    ],
  },
  {
    primary_id: "D03AZU",
    names: ["Org-31710"],
  },
  {
    primary_id: "D03AZY",
    names: ["PNU-177864"],
  },
  {
    primary_id: "D03BAB",
    names: ["Cacospongionolide E", "CHEMBL480874", "BDBM50259952"],
  },
  {
    primary_id: "D03BAC",
    names: [
      "Revacept",
      "Arterial Thrombosis Treatment, Trigen",
      "PR-15",
      "Antiplatelet Protein Therapy (Arterial Thrombosis/Atherosclerosis), Trigen",
    ],
  },
  {
    primary_id: "D03BAY",
    names: ["N-Acylpiperidine Ether Derivative 5"],
  },
  {
    primary_id: "D03BBG",
    names: [
      "Afuresertib",
      "1047634-63-8",
      "SCHEMBL1529680",
      "CHEMBL3953883",
      "BCP15122",
      "EX-A1184",
      "ZINC43197673",
      "AKOS030526222",
      "CS-3326",
      "NCGC00390611-01",
      "BC600705",
      "HY-15966",
      "KB-334362",
    ],
  },
  {
    primary_id: "D03BBU",
    names: ["Lucatumumab"],
  },
  {
    primary_id: "D03BCE",
    names: ["FPT-038"],
  },
  {
    primary_id: "D03BCF",
    names: ["Poly-Microbial Agents"],
  },
  {
    primary_id: "D03BCV",
    names: ["Macrocycle Derivative 6"],
  },
  {
    primary_id: "D03BDE",
    names: [
      "QRX-401",
      "Antihypercholesterolemia Agents, QUATRx",
      "Antihyperlipidemic Agents, QUATRx",
      "LDL Cholesterol Lowering Agents, QUATRx",
      "Lipid Lowering Agents (Dual Action), QUATRx",
    ],
  },
  {
    primary_id: "D03BDK",
    names: ["L-366,948"],
  },
  {
    primary_id: "D03BDP",
    names: ["Triferic"],
  },
  {
    primary_id: "D03BFH",
    names: [
      "N-Alpha-Amidino-Tyr(Me)-Pro-Trp-P-Cl-Phe-NH2",
      "CHEMBL243189",
      "BDBM50201412",
      "N Alpha-Amidino-Tyr(Me)-Pro-Trp-P-Cl-Phe-NH2",
    ],
  },
  {
    primary_id: "D03BFS",
    names: ["SPN-809"],
  },
  {
    primary_id: "D03BFU",
    names: [
      "Pradaxa",
      "Dabigatran Etexilate Mesylate",
      "872728-81-9",
      "XETBXHPXHHOLOE-UHFFFAOYSA-N",
      "CP0128",
      "FT-0660872",
      "Ethyl 3-[(2-{[4-(Hexyloxycarbonylamino-Imino-Methyl)-Phenylamino]-Methyl}-1-Methyl-1h-Benzimidazole-5-Carbonyl)-Pyridin-2-Yl-Amino]-Propionate Methanesulphonate",
      "1-Methyl-2-[N-[4-(N-N-Hexyloxycarbonylamidino)Phenyl]-Amino-Methyl]-Benzimidazol-5-Yl-Carboxylic Acid-N-(2-Pyridyl)-N-(2-Ethoxycarbonylethyl)-Amide Mesylate",
    ],
  },
  {
    primary_id: "D03BFX",
    names: ["PB-1120"],
  },
  {
    primary_id: "D03BGG",
    names: ["ST-1141"],
  },
  {
    primary_id: "D03BGP",
    names: ["Bradykinin B1 Receptor Antagonists", "Bradykinin B1 Receptor Antagonists (Pain/Inflammation), Merck & Co"],
  },
  {
    primary_id: "D03BHM",
    names: ["R1507"],
  },
  {
    primary_id: "D03BHO",
    names: ["JTP-2942"],
  },
  {
    primary_id: "D03BHQ",
    names: ["FORMYLCHROMONE", "CHEMBL559311", "BDBM50296368"],
  },
  {
    primary_id: "D03BHZ",
    names: [
      "AZD8186",
      "1627494-13-6",
      "AZD-8186",
      "AIQ4OWD0RA",
      "UNII-AIQ4OWD0RA",
      "CHEMBL3408248",
      "AZD 8186",
      "8-[(1R)-1-(3,5-Difluoroanilino)Ethyl]-N,N-Dimethyl-2-Morpholin-4-Yl-4-Oxochromene-6-Carboxamide",
      "SCHEMBL1636956",
      "GTPL8527",
      "MolPort-039-137-739",
      "EX-A1649",
      "BDBM50070322",
      "S7694",
      "AKOS025404922",
      "ZINC116734639",
      "CS-4975",
      "ACN-037528",
      "SB16709",
      "KS-0000063L",
      "AK174916",
      "AC-29024",
      "HY-12330",
      "KB-334360",
      "B5950",
      "UNII-AIQ4OWD0RA;",
    ],
  },
  {
    primary_id: "D03BID",
    names: ["Neuroprotectants"],
  },
  {
    primary_id: "D03BIH",
    names: ["DFN-15"],
  },
  {
    primary_id: "D03BIQ",
    names: ["ADS-102891", "CHEMBL424728", "SCHEMBL6508765", "BDBM50200792"],
  },
  {
    primary_id: "D03BIT",
    names: ["[Pterin-6-Yl Methanyl]-Phosphonophosphate"],
  },
  {
    primary_id: "D03BJB",
    names: [
      "EGFR Inhibitor",
      "879127-07-8",
      "CHEMBL387187",
      "N-(3-((6-((3-(Trifluoromethyl)Phenyl)Amino)Pyrimidin-4-Yl)Amino)Phenyl)Cyclopropanecarboxamide",
      "Cyclopropanecarboxylic Acid-(3-(6-(3-Trifluoromethyl-Phenylamino)-Pyrimidin-4-Ylamino)-Phenyl)-Amide",
      "K00598a",
      "N-{3-[(6-{[3-(Trifluoromethyl)Phenyl]amino}pyrimidin-4-Yl)Amino]phenyl}cyclopropanecarboxamide",
      "EGFR Inhibitor 324674",
      "SCHEMBL242230",
      "GTPL5963",
      "CTK8E9808",
      "DTXSID40429554",
      "MolPort-042-665-758",
      "HMS3229E03",
      "BDBM50200394",
      "ZINC13831337",
      "API0010446",
      "CCG-206749",
      "NCGC00167954-01",
    ],
  },
  {
    primary_id: "D03BJF",
    names: ["PMID23099093C17d"],
  },
  {
    primary_id: "D03BJX",
    names: ["Botulinum Toxin", "Botulinum Toxin (Adjunct, Solid Tumor)"],
  },
  {
    primary_id: "D03BKN",
    names: ["MS201408-0005B"],
  },
  {
    primary_id: "D03BKV",
    names: ["KML-001"],
  },
  {
    primary_id: "D03BLF",
    names: [
      "Triamcinolone",
      "Adcortyl",
      "Aristocort",
      "Celeste",
      "Cinolone",
      "Delphicort",
      "Fluoxiprednisolone",
      "Fluoxyprednisolone",
      "Fougera",
      "Ione",
      "Kenacort",
      "Ledercort",
      "Mycolog",
      "Omcilon",
      "Omicilon",
      "Orion",
      "Rodinolone",
      "Tiamcinolonum",
      "Triamcet",
      "Triamcinalone",
      "Triamcinlon",
      "Triamcinolon",
      "Triamcinolona",
      "Triamcinolonum",
      "Tricortale",
      "Trilone",
      "Tristoject",
      "Volon",
      "Aristocort Tablets",
      "Triamcinolonum [INN]",
      "CL 19823",
      "Aristocort (TN)",
      "Azmacort (TN)",
      "Cinolone-T",
      "Fougera (TN)",
      "Kenacort (TN)",
      "Kenacort-AG",
      "Kenalog (TN)",
      "Nasacort (TN)",
      "SK-Triamcinolone",
      "Tiamcinolonum [INN-Latin]",
      "Triam-Tablinen",
      "Triamcinolona [INN-Spanish]",
      "Tricortone (TN)",
      "Triderm (TN)",
      "Triesence (TN)",
      "Trilone (TN)",
      "Tristoject (TN)",
      "Volon A (TN)",
      "Tri-Nasal (TN)",
      "Triamcinolone (JP15/USP/INN)",
      "Triamcinolone [USAN:INN:BAN:JAN]",
      "Droxypregna-1,4-Diene-3,20-Dione",
      "Pregna-1,4-Diene-3,20-Dio",
      "TRIAMCINOLONE (SEE ALSO TRIAMCINOLONE ACETONIDE (76-25-5) AND TRIAMCINOLONE DIACETATE (67-78-7))",
      "Pregna-1,4-Diene-3,20-Dione, 9-Fluoro-11beta,16alpha,17,21-Tetrahydroxy-(8CI)",
      "Pregna-1,4-Diene-3,20-Dione, 9-Fluoro-11,16,17,21-Tetrahydroxy-, (11beta,16alpha)",
      "(8S,9R,10S,11S,13S,14S,16R,17S)-9-Fluoro-11,16,17-Trihydroxy-17-(2-Hydroxyacetyl)-10,13-Dimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "11-Beta,16-Alpha,17-Alpha,21-Tetrahydroxy-9-Alpha-Fluoro-1,4-Pregnadiene-3,20-Dione",
      "11.Beta.,16.alpha.,17.alpha., 21-Tetrahydroxy-9.alpha.-Fluoro-1,4-Pregnadiene-3,20-Dione",
      "11.beta.,16.alpha.,17.alpha.,21-Tetrahydroxy-9.alpha.-Fluoro-1,4-Pregnadiene-3,20-Dione",
      "9-Fluoro-11,16,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione",
      "9-Fluoro-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione",
      "9-Alpha-Fluoro-11-Beta,16-Alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione",
      "9-Alpha-Fluoro-16-Alpha-Hydroxyprednisolone",
      "9.Alpha.-Fluoro-11.beta.,16.alpha.,17,21-Tetrahy",
      "9.Alpha.-Fluoro-11.beta.,16.alpha.,17,21-Tetrahydroxy-1,4-Pregnadiene-3,20-Dione",
      "9.Alpha.-Fluoro-11.beta.,16.alpha.,17.alpha., 21-Tetrahydroxypregna-1,4-Diene-3,20-D",
      "9.Alpha.-Fluoro-16.alpha.-Hydroxyprednisolone",
      "9.alpha.-Fluoro-11.beta.,16.alpha.,17,21-Tetrahydroxy-1,4-Pregnadiene-3,20-Dione",
      "9.alpha.-Fluoro-11.beta.,16.alpha.,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione",
      "9.alpha.-Fluoro-11.beta.,16.alpha.,17.alpha.,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione",
      "9.alpha.-Fluoro-16.alpha.-Hydroxyprednisolone",
      "9alpha-Fluoro-11beta,16alpha,17,21-Tetrahydroxy-1,4-Pregnadiene-3,20-Dione",
      "9alpha-Fluoro-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione",
      "9alpha-Fluoro-16alpha-Hydroxyprednisolone",
    ],
  },
  {
    primary_id: "D03BLG",
    names: ["AGS-009"],
  },
  {
    primary_id: "D03BMR",
    names: ["PRX-1"],
  },
  {
    primary_id: "D03BMT",
    names: ["EphB4 Inhibitors"],
  },
  {
    primary_id: "D03BNC",
    names: ["HI-240"],
  },
  {
    primary_id: "D03BND",
    names: ["GFC-001"],
  },
  {
    primary_id: "D03BNF",
    names: ["IMGN632"],
  },
  {
    primary_id: "D03BNM",
    names: [
      "Cbz-Val-Pro-Val-(2-Benzoxazole)",
      "CHEMBL108783",
      "BDBM50031199",
      "((S)-1-{(S)-2-[(S)-1-(Benzooxazole-2-Carbonyl)-2-Methyl-Propylcarbamoyl]-Pyrrolidine-1-Carbonyl}-2-Methyl-Propyl)-Carbamic Acid Benzyl Ester",
    ],
  },
  {
    primary_id: "D03BNO",
    names: ["PGH2"],
  },
  {
    primary_id: "D03BNU",
    names: ["XL-388"],
  },
  {
    primary_id: "D03BOO",
    names: ["2,4-Dibenzylamino-6-Isopentylpyrimidine", "CHEMBL494121"],
  },
  {
    primary_id: "D03BOZ",
    names: [
      "RS-130830",
      "CTS-1027",
      "193022-04-7",
      "UNII-2QD3F58224",
      "CHEMBL440498",
      "2QD3F58224",
      "4-[4-(4-CHLORO-PHENOXY)-BENZENESULFONYLMETHYL]-TETRAHYDRO-PYRAN-4-CARBOXYLIC ACID HYDROXYAMIDE",
      "4-[[[4-(4-Chlorophenoxy)Phenyl]sulfonyl]methyl]tetrahydro-N-Hydroxy-2H-Pyran-4-Carboxamide",
      "4-(4-(4-Chloro-Phenoxy)-Benzenesulfonylmethyl)-Tetrahydro-Pyran-4-Carboxylic Acid Hydroxyamide",
      "Ro-1130830",
      "AC1MOE9A",
      "SCHEMBL2381112",
      "BDBM11863",
      "DTXSID90172907",
      "830c",
      "CTS 1027",
      "ZINC1488366",
      "BCP13018",
      "3563AH",
      "Alpha-Tetrahydropyran Beta-Sulfone 1B",
      "AKOS030526690",
    ],
  },
  {
    primary_id: "D03BPG",
    names: [
      "T0070907",
      "2-Chloro-5-Nitro-N-4-Pyridinylbenzamide",
      "313516-66-4",
      "2-CHLORO-5-NITRO-N-4-PYRIDINYLBENZAMIDE",
      "2-Chloro-5-Nitro-N-(Pyridin-4-Yl)Benzamide",
      "T 0070907",
      "T-0070907",
      "2-Chloro-5-Nitro-N-Pyridin-4-Ylbenzamide",
      "2-Chloro-5-Nitro-N-(4-Pyridyl)Benzamide",
      "CHEMBL510698",
      "Benzamide, 2-Chloro-5-Nitro-N-4-Pyridinyl-",
      "2-Chloro-5-Nitro-N-4-Pyridinyl-Benzamide",
      "SR-01000392700",
      "AC1MCROG",
      "Oprea1_586106",
      "ZINC3381",
      "GTPL3444",
      "SCHEMBL2128178",
      "CTK6H1028",
      "KS-00000MYU",
      "CHEBI:92553",
      "DTXSID30380504",
      "MolPort-001-763-336",
      "FRPJSHKMZHWJBE-UHFFFAOYSA-N",
      "HMS3268J16",
      "HMS3651P21",
      "HMS3262J21",
    ],
  },
  {
    primary_id: "D03BPU",
    names: ["Hematological Disease Agents"],
  },
  {
    primary_id: "D03BPZ",
    names: ["Terpyridineplatinum(II) Complexe 3"],
  },
  {
    primary_id: "D03BQD",
    names: ["NMS-1"],
  },
  {
    primary_id: "D03BQH",
    names: ["Methanesulfonamide Derivative"],
  },
  {
    primary_id: "D03BQI",
    names: ["WHI-P131"],
  },
  {
    primary_id: "D03BQJ",
    names: [
      "2-P-Tolyl-4H-Chromen-4-One",
      "4'-Methylflavone",
      "2-(4-Methylphenyl)-4H-Chromen-4-One",
      "2-(4-Methylphenyl)Chromen-4-One",
      "2-P-Tolyl-Chromen-4-One",
      "CHEMBL16861",
      "4'-Methylflavon",
      "4hlk",
      "AC1LEMVU",
      "Maybridge3_003060",
      "Oprea1_528340",
      "Oprea1_754899",
      "2-(P-Tolyl)-Chromen-4-One",
      "SCHEMBL7593917",
      "ZINC57876",
      "MolPort-000-648-141",
      "OPHKKQQCOYMLPW-UHFFFAOYSA-N",
      "HMS1439L02",
      "BDBM50310190",
      "AKOS000603601",
      "MCULE-3669298263",
      "IDI1_014447",
      "41255-30-5",
      "ST093690",
      "BAS 01121718",
      "NS-00120",
      "4H-1-Benzopyran-4-One, 2-(4-Methylphenyl)-",
      "AG-690/13115015",
    ],
  },
  {
    primary_id: "D03BQP",
    names: ["Rebamipide"],
  },
  {
    primary_id: "D03BQS",
    names: ["N-Hydroxy-9,10-Dihydroanthracene-9-Carboxamide", "CHEMBL575482", "SCHEMBL4541357"],
  },
  {
    primary_id: "D03BQV",
    names: [
      "1S,2R-Milnacipran",
      "CHEMBL471001",
      "AC1MHWCG",
      "SCHEMBL1707471",
      "ZINC5649654",
      "BDBM50275771",
      "NCGC00165825-02",
      "AJ-54473",
      "FT-0696627",
      "Cyclopropanecarboxamide,2-(Aminomethyl)-N,n-Diethyl-1-Phenyl-, (1r,2s)-",
      "N,N-Diethyl-1-Phenyl-2alpha-(Aminomethyl)Cyclopropane-1beta-Carboxamide",
    ],
  },
  {
    primary_id: "D03BRB",
    names: ["(H-Dmt-Tic-Glu-NH-(CH(2))(5)-CO-Dap(6DMN)-NH(2)", "CHEMBL426320"],
  },
  {
    primary_id: "D03BRM",
    names: ["AUY954", "AUY 954"],
  },
  {
    primary_id: "D03BRP",
    names: ["Aromatic Hydrazine Carboxyimidoamide Derivative 5"],
  },
  {
    primary_id: "D03BRZ",
    names: ["Therapeutic Program, Ironwood"],
  },
  {
    primary_id: "D03BSK",
    names: ["URC102"],
  },
  {
    primary_id: "D03BSQ",
    names: ["ISIS 28477"],
  },
  {
    primary_id: "D03BSR",
    names: ["NNC55-0396"],
  },
  {
    primary_id: "D03BTJ",
    names: ["PMID25408830C3"],
  },
  {
    primary_id: "D03BTP",
    names: ["Fosfluridine Tidoxil"],
  },
  {
    primary_id: "D03BUD",
    names: [
      "BREMAZOCINE",
      "Bremazocine",
      "(-)-Bremazocine",
      "Bremazocine, (-)-",
      "75684-07-0",
      "2-(1-Hydroxy-Cyclopropylmethyl)-5-Ethyl-9,9-Dimethyl-2'-Hydroxy-6,7-Benzomorphan",
      "2,6-Methano-3-Benzazocin-8-Ol, 6-Ethyl-1,2,3,4,5,6-Hexahydro-3-((1-Hydroxycyclopropyl)Methyl)-11,11-Dimethyl-, (2R)-",
      "SureCN157210",
      "AC1Q2C6X",
      "SCHEMBL157210",
      "CHEBI:3171",
      "GTPL1603",
      "ZINC3870315",
      "PDSP2_001570",
      "PDSP1_001586",
      "C11791",
      "UNII-ISF76M2DBE Component ZDXGFIXMPOUDFF-XLIONFOSSA-N",
    ],
  },
  {
    primary_id: "D03BUG",
    names: ["1-(Benzofuran-2-Yl)-3-Aza-Bicyclo[3.1.0]hexane", "CHEMBL509158"],
  },
  {
    primary_id: "D03BVC",
    names: ["D[Arg4,Orn8]VP", "CHEMBL385068"],
  },
  {
    primary_id: "D03BVH",
    names: ["AWD-23-111"],
  },
  {
    primary_id: "D03BVK",
    names: ["[3H]glutamine"],
  },
  {
    primary_id: "D03BVN",
    names: ["2-MePen-RYYRIK-NH2", "CHEMBL437601"],
  },
  {
    primary_id: "D03BVW",
    names: ["V-419"],
  },
  {
    primary_id: "D03BWH",
    names: ["7-(2-Hydroxyethyl)-3-O-Rhamnosylicariin", "CHEMBL504656"],
  },
  {
    primary_id: "D03BWQ",
    names: [
      "ATN-658",
      "ATN-291",
      "ATN-292",
      "HuATN-658",
      "UPA MAbs, Attenuon",
      "MAb (Urokinase Plasminogen Activator System), Attenuon/Kyowa Hakko Kirin",
    ],
  },
  {
    primary_id: "D03BXA",
    names: ["EVP4593", "AG-F-89549"],
  },
  {
    primary_id: "D03BXU",
    names: ["LNK-754"],
  },
  {
    primary_id: "D03BYM",
    names: ["BAY1895344"],
  },
  {
    primary_id: "D03BZN",
    names: ["Sodium Taurocholate"],
  },
  {
    primary_id: "D03BZS",
    names: ["MK-8655"],
  },
  {
    primary_id: "D03BZT",
    names: [
      "6-Oxo-Estrone",
      "6-Ketoestrone",
      "1476-34-2",
      "6-Oxoestrone",
      "6-Keto Estrone",
      "CHEMBL1628005",
      "3-Hydroxyestra-1,3,5(10)-Triene-6,17-Dione",
      "Estra-1,3,5(10)-Triene-6,17-Dione, 3-Hydroxy-",
      "SCHEMBL1977286",
      "DTXSID50514400",
      "JOVYPIGRPWIXHQ-ONUSSAAZSA-N",
      "MFCD00056399",
      "BDBM50370698",
      "ZINC13547985",
      "1,3,5(10)-Estratrien-3-Ol-6,17-Dione",
      "C-44091",
      "3-Hydroxyestra-1,3,5(10)-Triene-6,17-Dione #",
    ],
  },
  {
    primary_id: "D03BZU",
    names: ["RG7802"],
  },
  {
    primary_id: "D03BZV",
    names: ["PHENYLAMINOIMIDAZO(1,2-ALPHA)PYRIDINE"],
  },
  {
    primary_id: "D03BZW",
    names: [
      "[3H]CGP12177",
      "4-(3-Tert-Butylamino-2-Hydroxypropoxy)Benzimidazol-2-One",
      "Tbhpbo",
      "81047-99-6",
      "CHEBI:73288",
      "C14H19N3O3",
      "4-[3-(Tert-Butylamino)-2-Hydroxypropoxy]-1,3-Dihydro-2H-Benzimidazol-2-One",
      "4-[3-(Tert-Butylamino)-2-Hydroxypropoxy]-1,3-Dihydrobenzimidazol-2-One",
      "2H-Benzimidazol-2-One, 4-(3-((1,1-Dimethylethyl)Amino)-2-Hydroxypropoxy)-1,3-Dihydro-",
      "(+/-)-CGP-12177A Hydrochloride",
      "Spectrum_001907",
      "SpecPlus_000776",
      "Spectrum4_000777",
      "AC1Q6M3F",
    ],
  },
  {
    primary_id: "D03BZY",
    names: ["KNI-10313"],
  },
  {
    primary_id: "D03CAE",
    names: [
      "4-[2-(5-Phenyl-Pentylamino)-Ethyl]-Phenol",
      "CHEMBL119778",
      "790642-63-6",
      "CTK2G4587",
      "DTXSID40438363",
      "BDBM50066544",
      "4-[2-[(5-Phenylpentyl)Amino]ethyl]phenol",
      "Phenol, 4-[2-[(5-Phenylpentyl)Amino]ethyl]-",
    ],
  },
  {
    primary_id: "D03CAJ",
    names: ["7-(2-Nitro-Ethyl)-Azepan-(2Z)-Ylideneamine", "CHEMBL315194", "SCHEMBL6980573", "BDBM50104649"],
  },
  {
    primary_id: "D03CAT",
    names: ["Cefteram Pivoxil"],
  },
  {
    primary_id: "D03CAY",
    names: ["CMP-3", "Chitin Microparticle Vaccine Adjuvant (Nasal, Vaccination), CMP Therapeutics"],
  },
  {
    primary_id: "D03CBD",
    names: [
      "Isoxazolecarboxylic Acid",
      "CHEMBL133541",
      "SCHEMBL5619846",
      "SCHEMBL5619857",
      "UBNWPQXLFRMMEI-GQCTYLIASA-N",
      "BDBM50133280",
      "Isochroman-3-(S)-Carboxylic Acid Methylester",
      "5-{3-[3-(3-Hydroxy-2-Methoxycarbonyl-Phenoxy)-Propenyl]-Phenyl}-Isoxazole-3-Carboxylic Acid",
      "5-(3-((1e)-3-(3-Hydroxy-2-(Methoxycarbonyl)Phenoxy)Prop-1-Enyl)Phenyl)Isoxazole-3-Carboxylic Acid",
      "5-(3-(3-(3-Hydroxy-2-(Methoxycarbonyl)Phenoxy)Prop-1-Enyl)Phenyl)Isoxazole-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03CBO",
    names: ["Marizomib IV"],
  },
  {
    primary_id: "D03CBU",
    names: ["PHY-626"],
  },
  {
    primary_id: "D03CBX",
    names: ["4-Bromo-2-(4-Hydroxy-Phenyl)-Quinolin-6-Ol", "4-Bromo-2-(4-Hydroxyphenyl)Quinolin-6-Ol"],
  },
  {
    primary_id: "D03CCH",
    names: ["SK&F-95601", "2-[3-Chloro-4-(4-Morpholinyl)Pyridin-2-Ylmethylsulfinyl]-5-Methoxybenzimidazole"],
  },
  {
    primary_id: "D03CCP",
    names: [
      "2-Ethynyl-4-(3-Fluorophenylethynyl)Thiazole",
      "CHEMBL201939",
      "SCHEMBL4138461",
      "Thiazole, 2-Ethynyl-4-[2-(3-Fluorophenyl)Ethynyl]-",
      "BDBM50181773",
    ],
  },
  {
    primary_id: "D03CCY",
    names: ["XEL 001HP"],
  },
  {
    primary_id: "D03CDB",
    names: ["PFFFFF", "CHEMBL246187"],
  },
  {
    primary_id: "D03CDH",
    names: ["3-Benzyl-4-Hydroxy-2H-Benzo[h]chromen-2-One", "CHEMBL576081"],
  },
  {
    primary_id: "D03CDK",
    names: ["ASP-7147"],
  },
  {
    primary_id: "D03CDM",
    names: ["8-(2-Ethoxy-1-Methyl-Ethoxy)-Quinolin-2-Ylamine", "CHEMBL184001", "SCHEMBL5884055"],
  },
  {
    primary_id: "D03CEF",
    names: [
      "Desvenalfaxine Succinate",
      "386750-22-7",
      "Desvenlafaxine Succinate Monohydrate",
      "Desvenlafaxine (Succinate Hydrate)",
      "Desvenlafaxine Succinate Hydrate",
      "Desvenlafaxine Succinate [USAN]",
      "Desvenlafaxine Succinate (USAN)",
      "O-Desmethylvenlafaxine Succinate Monohydrate",
      "Desvenlafaxine Succinate Monohydrate (O-Desmethylvenlafaxine Succinate Monohydrate)",
      "Pristiq (TN)",
      "AC1OCFPN",
      "Desvenlafaxine(Succinatehydrate)",
      "SCHEMBL1278407",
      "CTK8E8387",
      "HY-B0602A",
      "MolPort-021-783-032",
    ],
  },
  {
    primary_id: "D03CEP",
    names: ["CGNLSTCMLGTYTQDFNKPHTFPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D03CEZ",
    names: [
      "AGROCLAVINE",
      "Agroclavine",
      "8,9-Didehydro-6,8-Dimethylergoline",
      "548-42-5",
      "UNII-A8SW57GO7T",
      "(-)-Agroclavine",
      "CCRIS 2099",
      "Ergoline, 8,9-Didehydro-6,8-Dimethyl-",
      "EINECS 208-947-3",
      "(5R,10R)-Agroclavine",
      "NSC 93132",
      "6,8-Dimethyl-8,9-Didehydroergoline",
      "AGROCLAVIN",
      "BRN 0024966",
      "A8SW57GO7T",
      "CHEMBL449081",
      "CHEBI:2519",
      "Indolo(4,3-Fg)Quinoline, 4,6,6a,7,8,10a-Hexahydro-7,9-Dimethyl-",
      "NSC93132",
      "(6aR,10aR)-7,9-Dimethyl-4,6,6a,7,8,10a-Hexahydroindolo[4,3-Fg]quinoline",
      "Agroclavine-1",
      "Lopac-A-206",
      "AC1L2JXJ",
      "Biomol-NT_000066",
      "Lopac0_000128",
      "MLS001216424",
      "Cid_73484",
    ],
  },
  {
    primary_id: "D03CFJ",
    names: ["NCX-701"],
  },
  {
    primary_id: "D03CFR",
    names: ["SEP-226332"],
  },
  {
    primary_id: "D03CGQ",
    names: ["AG-013958", "AG-13958"],
  },
  {
    primary_id: "D03CGS",
    names: ["CHF-3098"],
  },
  {
    primary_id: "D03CHA",
    names: ["CB1 Antagonists, Esteve", "CB1 Antagonists (Metabolic Syndrome), Esteve"],
  },
  {
    primary_id: "D03CHE",
    names: ["PF-251802"],
  },
  {
    primary_id: "D03CHM",
    names: ["MSC1936369B"],
  },
  {
    primary_id: "D03CHT",
    names: ["Fudosteine", "Cleanal (TN)", "Spelear (TN)"],
  },
  {
    primary_id: "D03CII",
    names: ["Refametinib"],
  },
  {
    primary_id: "D03CIN",
    names: ["PTX-007011"],
  },
  {
    primary_id: "D03CJL",
    names: [
      "Droperidol",
      "DHBP",
      "Dehidrobenzperidol",
      "Dehydrobenzoperidol",
      "Dehydrobenzperidol",
      "Deidrobenzperidolo",
      "Dihidrobenzperidol",
      "Dridol",
      "Droleptan",
      "Droperidolo",
      "Droperidolum",
      "Halkan",
      "Inappin",
      "Inapsin",
      "Inapsine",
      "Innovan",
      "Inopsin",
      "Inoval",
      "Leptanal",
      "Leptofen",
      "Properidol",
      "Sintodril",
      "Sintosian",
      "Thalamanol",
      "Thalamonal",
      "Vetkalm",
      "Component Of Innovar",
      "Droperidolo [DCIT]",
      "Janssen Brand Of Droperidol",
      "Kern Brand Of Droperidol",
      "Taylor Brand Of Droperidol",
      "R 4749",
      "R4749",
      "Dridol (TN)",
      "Droleptan (TN)",
      "Droperidolum [INN-Latin]",
      "Dropletan (TN)",
      "HS-0065",
      "INAPSINE (TN)",
      "Inapsine (TN)",
      "Innovar-Vet",
      "McN-JR 4749",
      "R-4749",
      "Ina.psi.n",
      "Ina.psi.ne",
      "Ino.psi.n",
      "McN-JR-4749",
      "Droperidol (JP15/USP/INN)",
      "Droperidol [USAN:INN:BAN:JAN]",
      "Luorophenyl)-4-Oxobutyl]-1,2,3,6-Tetrahydro-4-Pyridyl]-2-Benzimidazolinone",
      "1-(1-(3-(P-Fluorobenzoyl)Propyl)-1,2,3,6-Tetrahydro-4-Pyridyl)-2-Benzimidazolinone",
      "1-(1-(4-(4-Fluorophenyl)-4-Oxobutyl)-1,2,3,6-Tetrahydro-4-Pyridinyl)-1,3-Dihydro-2H-Benzimidazol-2-One",
      "1-(1-(4-(P-Fluorophenyl)-4-Oxobutyl)-1,2,3,6-Tetrahydro-4-Pyridyl)-2-Benzimidazolinone",
      "1-1-[3-(P-Fluorobenzoyl)Propyl]-1,2,3,6-Tetrahydro-4-Pyridyl-2-Benzimidazolinone",
      "1-[1-[3-(P-Fluorobenzoyl)Propyl]-1,2,3,6-Tetrahydro-4-Pyridyl]-2-Benzimidazolinone",
      "1-[1-[4-(P-F",
      "1-[1-[4-(P-Fluorophenyl)-4-Oxobutyl]-1,2,3,6-Tetrahydro-4-Pyridyl]-2-Benzimidazolinone",
      "1-{1-[4-(4-Fluorophenyl)-4-Oxobutyl]-1,2,3,6-Tetrahydropyridin-4-Yl}-1,3-Dihydro-2H-Benzimidazol-2-One",
      "2-Benzimidazolinone, 1-[1-[3-(P-Fluorobenz",
      "3-[1-[4-(4-Fluorophenyl)-4-Oxobutyl]-3,6-Dihydro-2H-Pyridin-4-Yl]-1H-Benzimidazol-2-One",
    ],
  },
  {
    primary_id: "D03CJT",
    names: ["PMID27109571-Compound-29"],
  },
  {
    primary_id: "D03CKF",
    names: ["H-Tyr-C[D-Orn-Aic-Glu]-NH2", "CHEMBL106849"],
  },
  {
    primary_id: "D03CKG",
    names: [
      "5-(DIMETHYLAMINO)-2-NAPHTHALENESULFONIC ACID",
      "5-(Dimethylamino)Naphthalene-2-Sulfonic Acid",
      "AC1NBV3O",
      "SCHEMBL18300795",
      "DB07684",
      "5-Dimethyl-Aminonaphthalene-2-Sulfonic Acid",
    ],
  },
  {
    primary_id: "D03CKN",
    names: ["3-Ethoxycarbonyl-6-Ethyl-2-Methyl-4-Quinolone", "CHEMBL205159"],
  },
  {
    primary_id: "D03CKO",
    names: ["Anticancer Therapy, Endo"],
  },
  {
    primary_id: "D03CKP",
    names: ["DasKloster 0182-01"],
  },
  {
    primary_id: "D03CKX",
    names: ["Cromafiban"],
  },
  {
    primary_id: "D03CLP",
    names: ["Dendritic Cell Vaccine", "Dendritic Cell Vaccine (Glioblastoma Multiforme)"],
  },
  {
    primary_id: "D03CMD",
    names: ["PMID29865878-Compound-55"],
  },
  {
    primary_id: "D03CMR",
    names: ["TER-16998"],
  },
  {
    primary_id: "D03CMZ",
    names: ["CAR-T Cells Targeting EGFRviii"],
  },
  {
    primary_id: "D03CNS",
    names: [
      "Cyclothiazide",
      "Anhydron",
      "Aquirel",
      "Ciclotiazida",
      "Ciclotiazide",
      "Cyclothiazidum",
      "Doburil",
      "Fluidil",
      "Renazide",
      "Valmiran",
      "Ciclotiazide [DCIT]",
      "C 9847",
      "MDi 193",
      "Anhydron (TN)",
      "Ciclotiazida [INN-Spanish]",
      "Cyclothiazidum [INN-Latin]",
      "Lilly 35,483",
      "Cyclothiazide [USAN:INN:BAN]",
      "Cyclothiazide (JAN/USAN/INN)",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3,4-Dihydro-6-Chloro-3-(5-Norbornen-2-Yl)-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3,4-Dihydro-6-Chloro-3-(5-Norbornen-2-Yl)-,1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3-Bicyclo(2.2.1)Hept-5-En-2-Yl-6-Chloro-3,4-Dihydro-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3-Bicyclo[2.2.1]hept-5-En-2-Yl-6-Chloro-3,4-Dihydro-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3,4-Dihydro-3-(5-Norbornen-2-Yl)-, 1,1-Dioxide",
      "3-(Bicyclo[2.2.1]hept-5-En-2-Yl)-6-Chloro-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3-Bicyclo(2.2.1)Hept-5-En-2-Yl-6-Chloro-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3-Bicyclo[2.2.1]hept-5-En-2-Yl-6-Chloro-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-3-(2-Norbornen-5-Yl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-3-(2-Norbornen-5-Yl)-2H-1,2-4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-3-(2-Norbornen-5-Yl)-7-Sulfamoyl-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-3-(5-Norbornen-2-Yl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-3-(Norbornen-2-Yl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3-(2-Norbornen-5-Yl)-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D03CNT",
    names: [
      "L-698532",
      "28563-19-1",
      "3-Phenyl-4-Hydroxy-7-Chloroquinolin-2(1H)-One",
      "CHEMBL31661",
      "7-Chloro-4-Hydroxy-3-Phenyl-2(1H)-Quinolone",
      "7-Chloro-4-Hydroxy-3-Phenyl-2(1H)-Quinolinone",
      "7-Chloro-4-Hydroxy-3-Phenyl-1H-Quinolin-2-One",
      "Mdl 104,653",
      "ZINC5910",
      "AC1MD767",
      "SCHEMBL1612505",
      "CTK4G1708",
      "DTXSID80182777",
      "RDXQSWLUXKUQSI-UHFFFAOYSA-N",
      "BCP18252",
      "BDBM50041467",
      "AKOS032961557",
      "7-Chloro-4-Hydroxy-3-Phenylquinolin-2(1H)-One",
      "7-Chloro-2-Hydroxy-3-Phenyl-1H-Quinolin-4-One",
      "3-Phenyl-4-Hydroxy-7-Chloro-Quinolin-2(1h)-One",
    ],
  },
  {
    primary_id: "D03CNV",
    names: ["VMD-30283"],
  },
  {
    primary_id: "D03CPB",
    names: ["RKI-1447"],
  },
  {
    primary_id: "D03CPM",
    names: ["SLV-334"],
  },
  {
    primary_id: "D03CQE",
    names: [
      "Gatifloxacin",
      "Bonoq",
      "GTFX",
      "Gaity",
      "Gatiflo",
      "Gatilox",
      "Gatiquin",
      "Gatispan",
      "Tequin",
      "Zymar",
      "Zymaxid",
      "Zymer",
      "AM 1155",
      "CG 5501;PD 135432",
      "PD135432",
      "AM-1155",
      "BMS 206584-01",
      "BMS-206584",
      "CG-5501",
      "Gatiflo (TN)",
      "Gatifloxacin & Gamma Interferon",
      "Gatifloxacin (INN)",
      "Gatifloxacin (TN)",
      "Gatifloxacin [USAN:INN]",
      "PD-135432",
      "Tequin (TN)",
      "Tequin In Dextrose 5% In Plastic Container",
      "Zymar (TN)",
      "Zymer (TN)",
      "BMS-206584-01",
      "Gatiflo,Tequin And Zymar, Gatifloxacin",
      "AM-1155 (*Sesquihydrate*)",
      "(+-)-1-Cyclopropyl-6-Fluoro-1,4-Dihydro-8-Methoxy-7-(3-Methyl-1-Piperazinyl)-4-Oxo-3-Quinolinecarboxylic Acid",
      "1-Cyclopropyl-1,4-Dihydro-6-Fluoro-8-Methoxy-7-(3-Methyl-1-Piperazinyl)-4-Oxo-3-Quinolinecarboxylic Acid",
      "1-Cyclopropyl-6-Fluoro-1,4-Dihydro-8-Methoxy-7-(3-Methyl-1-Piperazinyl)-4-Oxo-3-Quinolinecarboxylic Acid",
      "1-Cyclopropyl-6-Fluoro-8-Methoxy-7-(3-Methylpiperazin-1-Yl)-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "1-Cyclopropyl-6-Fluoro-8-Methoxy-7-(3-Methylpiperazin-1-Yl)-4-Oxoquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03CQN",
    names: ["Chk1-A", "Chk1-B", "Chk1-C", "Chk 1 Inhibitor (Oral, Cancer)", "Chk 1 Inhibitor (Oral, Cancer), Array"],
  },
  {
    primary_id: "D03CQQ",
    names: ["PMID27977313-Compound-17"],
  },
  {
    primary_id: "D03CSL",
    names: ["13-(N-Pentylcarbamoyloxy)Tridec-8(Z)-Enoic Acid", "CHEMBL558485"],
  },
  {
    primary_id: "D03CTH",
    names: ["Biphenyl Mannoside Derivative 10"],
  },
  {
    primary_id: "D03CTR",
    names: ["PMID29473428-Compound-50"],
  },
  {
    primary_id: "D03CUF",
    names: [
      "Furazolidone",
      "Bifuron",
      "Corizium",
      "Coryzium",
      "Diafuron",
      "Enterotoxon",
      "Furall",
      "Furaxon",
      "Furaxone",
      "Furazol",
      "Furazolidine",
      "Furazolidon",
      "Furazolidona",
      "Furazolidonum",
      "Furazolum",
      "Furazon",
      "Furidon",
      "Furovag",
      "Furox",
      "Furoxal",
      "Furoxane",
      "Furoxon",
      "Furoxone",
      "Furozolidine",
      "Giardil",
      "Giarlam",
      "Medaron",
      "Neftin",
      "Nicolen",
      "Nifulidone",
      "Nifuran",
      "Nifurazolidone",
      "Nifurazolidonum",
      "Nitrofurazolidone",
      "Nitrofurazolidonum",
      "Nitrofuroxon",
      "Optazol",
      "Ortazol",
      "Puradin",
      "Roptazol",
      "Sclaventerol",
      "Tikofuran",
      "Topazone",
      "Trichofuron",
      "Tricofuron",
      "Tricoron",
      "Trifurox",
      "Viofuragyn",
      "Fiurox Aerosol Powder",
      "Furoxone Liquid",
      "Furoxone Swine Mix",
      "NF 180 Custom Mix Ten",
      "F-8900",
      "Furazolidona [INN-Spanish]",
      "Furazolidone [INN:BAN]",
      "Furazolidonum [INN-Latin]",
      "Furox Aerosol Powder (Veterinary)",
      "Furoxone (TN)",
      "NF-180",
      "Usaf Ea-1",
      "Dependal-M (TN)",
      "Furazolidone (USP/INN)",
      "N-(5-Nitro-2-Furfurylidene)-3-Amino-2-Oxazolidone",
      "N-(5-Nitro-2-Furfurylidene)-3-Aminooxazolidine-2-One",
      "3-(((5-Nitro-2-Furanyl)Methylene)Amino)-2-Oxazolidinone",
      "3-((5-Nitrofurfurylidene)Amino)-2-Oxazolidinone",
      "3-((5-Nitrofurfurylidene)Amino)-2-Oxazolidone",
      "3-((5-Nitrofurylidene)Amino)-2-Oxazolidone",
      "3-(5'-Nitrofurfuralamino)-2-Oxazolidone",
      "3-(5-Nitrofurfurylideneamino)-2-Oxazolidinone",
      "3-[(5-Nitrofurfurylidene)Amino]-2-Oxazolidinone",
      "3-[(5-Nitrofurfurylidene)Amino]-2-Oxazolidone",
      "3-[(5-Nitrofurylidene)Amino]-2-Oxazolidone",
      "3-[(E)-(5-Nitrofuran-2-Yl)Methylideneamino]-1,3-Oxazolidin-2-One",
      "3-[[(5-Nitro-2-Furanyl)Methylene]amino]-2-Oxazolidinone",
      "3-{[(5-Nitro-2-Furanyl)Methylene]amino}-2-Oxazolidinone",
      "3-{[(5-Nitro-2-Furyl)Methylene]amino}-1,3-Oxazolidin-2-One",
      "3-{[(E)-(5-Nitrofuran-2-Yl)Methylidene]amino}-1,3-Oxazolidin-2-One",
      "5-Nitro-N-(2-Oxo-3-Oxazolidinyl)-2-Furanmethanimine",
    ],
  },
  {
    primary_id: "D03CUH",
    names: ["PMID7966163C6c"],
  },
  {
    primary_id: "D03CUS",
    names: [
      "CS-207",
      "CS-307",
      "PPAR Alpha Agonists (Cardiovascular Disease Associated With Dyslipidemia)",
      "PPAR Alpha Agonists (Cardiovascular Disease Associated With Dyslipidemia), Chipscreen Biosciences",
    ],
  },
  {
    primary_id: "D03CVQ",
    names: ["6,7-Diethoxy-4-(4-Phenylbut-1-Enyl)Quinazoline", "CHEMBL399371"],
  },
  {
    primary_id: "D03CVT",
    names: ["ONC1-13B"],
  },
  {
    primary_id: "D03CVV",
    names: ["Anisperimus"],
  },
  {
    primary_id: "D03CVY",
    names: ["4-(Furan-3-Yl)-2,6-Di(Thiophen-2-Yl)Pyridine", "CHEMBL601911"],
  },
  {
    primary_id: "D03CWL",
    names: [
      "Methyl 3-(1-Benzyl-1H-Imidazol-5-Yl)-Propanoate",
      "CHEMBL608140",
      "SCHEMBL9642312",
      "FEWHBUJYQHXFMV-UHFFFAOYSA-N",
      "BDBM50307219",
      "1-Benzyl-5-Imidazole Propionic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D03CWO",
    names: ["ENDG-3020"],
  },
  {
    primary_id: "D03CWX",
    names: ["MEM-1414"],
  },
  {
    primary_id: "D03CXW",
    names: ["Tirapazamine Derivatives"],
  },
  {
    primary_id: "D03CYB",
    names: ["Aryl 1,2-Diamine Derivative 1"],
  },
  {
    primary_id: "D03CYF",
    names: ["RVX-408"],
  },
  {
    primary_id: "D03CYV",
    names: ["6-Ile-Ustiloxin"],
  },
  {
    primary_id: "D03CYY",
    names: ["GFPDGG", "CHEMBL261622"],
  },
  {
    primary_id: "D03CZE",
    names: ["VPZ-758"],
  },
  {
    primary_id: "D03CZX",
    names: ["PMA-201"],
  },
  {
    primary_id: "D03DAM",
    names: [
      "2,2-Dimethyl-N-(4-Sulfamoyl-Phenyl)-Propionamide",
      "CHEMBL23285",
      "2,2-Dimethyl-N-(4-Sulfamoylphenyl)Propanamide",
      "103826-87-5",
      "AC1MBHEI",
      "Oprea1_396828",
      "P-Pivaloylaminobenzenesulfonamide",
      "Propanamide, N-[4-(Aminosulfonyl)Phenyl]-2,2-Dimethyl-",
      "N-(4-Sulfamoylphenyl)Pivalamide",
      "SCHEMBL10603454",
      "ZINC73524",
      "BDBM16649",
      "Aromatic Sulfonamide Compound 14",
      "CCG-916",
      "KGBTVIQRHXYTRQ-UHFFFAOYSA-N",
      "4-Pivaloylamido-Benzenesulfonamide",
      "MolPort-002-912-681",
      "AKOS003441276",
      "MCULE-2674794031",
      "BRD-K77020128-001-01-6",
    ],
  },
  {
    primary_id: "D03DAP",
    names: ["Flecainide"],
  },
  {
    primary_id: "D03DAY",
    names: ["ITV-1"],
  },
  {
    primary_id: "D03DBW",
    names: ["Pretubulysin"],
  },
  {
    primary_id: "D03DCE",
    names: ["BPH-830", "BPH830", "BPH 830"],
  },
  {
    primary_id: "D03DDL",
    names: ["JWH-303"],
  },
  {
    primary_id: "D03DDR",
    names: [
      "Quinine",
      "Aflukin",
      "Chinin",
      "Chinine",
      "Chininum",
      "Conchinin",
      "Conquinine",
      "Quindan",
      "Quinidex",
      "Quinidine",
      "Quinimax",
      "Quinina",
      "Quinineanhydrous",
      "Quinora",
      "Quniacridine",
      "Chinin [German]",
      "Kinder Quinina",
      "QUININE MONO HCL",
      "Quinine Dab",
      "Quinine [BAN]",
      "Quinine Anhydrous",
      "Quinine Bisulfate",
      "Quinine Sulfate",
      "Quinine Sulphate",
      "Quinoline Alkaloid",
      "LT00645788;Q0028",
      "SB01652",
      "Beta-Quinine",
      "Cin-Quin",
      "Coco-Quinine",
      "IBS-L0034250",
      "Kinder Quinina (TN)",
      "Legatrin (TN)",
      "Quinine (BAN)",
      "Quinine, Anhydrous",
      "Quinine, Polymers",
      "Quinine, Tannate",
      "Biquinate (*Bisulfate Heptathydrate*)",
      "Dentojel (*Bisulfate Heptathydrate*)",
      "Quinamm (*2:1 Sulfate Salt*), Dihydrate",
      "Quine (*2:1 Sulfate Salt*, Dihydrate)",
      "Quinsan (*2:1 Sulfate Salt*), Dihydrate",
      "Alpha-(6-Methoxy-4-Quinoyl)-5-Vinyl-2-Quinclidinemethanol",
      "Cinchonan-9-Ol, 6'-Methoxy-, (8.alpha.,9R)-, Sulfate",
      "(+)-Quinidine",
      "(-)-Quinine",
      "(1R)-(6-Methoxyquinolin-4-Yl)((1S,4S,5R)-5-Vinylquinuclidin-2-Yl)Methanol",
      "(3A,8A,9r)-6'-Methoxycinchonan-9-Ol",
      "(5-Ethenyl-1-Azabicyclo[2.2.2]octan-2-Yl)-(6-Methoxyquinolin-4-Yl)Methanol",
      "(8-Alpha,9R)-6'-Methoxycinchonan-9-Ol",
      "(8.alpha.,9R)-6'-Methoxycinchonan-9-Ol",
      "(8S,9R)-6'-Methoxycinchonan-9-Ol",
      "(8S,9R)-Quinine",
      "(9R)-6'-Methoxy-8alpha-Cinchonan-9-Ol",
      "(R)-(-)-Quinine, 6-Methoxycinchonidine",
      "(R)-(-)-Quinine",
      "(R)-(6-Methoxy-Quinolin-4-Yl)-((2S,5S)-5-Vinyl-1-Aza-Bicyclo[2.2.2]oct-2-Yl)-Methanol",
      "(R)-(6-Methoxyquinolin-4-Yl)((2S,4S,8R)-8-Vinylquinuclidin-2-Yl)Methanol",
      "(R)-[(2S,4R,5R)-5-Ethenyl-1-Azabicyclo[2.2.2]octan-2-Yl]-(6-Methoxyquinolin-4-Yl)Methanol",
      "(R)-[(2S,4S,5R)-5-Ethenyl-1-Azabicyclo[2.2.2]octan-2-Yl]-(6-Methoxyquinolin-4-Yl)Methanol",
      "(R)-[(2S,5R)-5-Ethenyl-1-Azabicyclo[2.2.2]octan-2-Yl]-(6-Methoxyquinolin-4-Yl)Methanol",
      "(S)-(5-Ethenyl-1-Azabicyclo[2.2.2]octan-2-Yl)-(6-Methoxyquinolin-4-Yl)Methanol",
      "(S)-[(2R,4R,5S)-5-Ethenyl-1-Azabicyclo[2.2.2]octan-2-Yl]-(6-Methoxyquinolin-4-Yl)Methanol",
      "6'-Methoxycinchonan-9-Ol",
      "6'-Methoxycinchonidine",
      "6'-Methoxycinchonine",
      "6-Methoxycinchonine",
    ],
  },
  {
    primary_id: "D03DEA",
    names: ["BCX-4161"],
  },
  {
    primary_id: "D03DEI",
    names: [
      "Sulfinpyrazone",
      "Anturan",
      "Anturane",
      "Anturanil",
      "Anturano",
      "Anturen",
      "Anturidin",
      "Diphenylpyrazone",
      "Enturan",
      "Enturen",
      "Novopyrazone",
      "Sulfinpirazona",
      "Sulfinpyrazine",
      "Sulfinpyrazon",
      "Sulfinpyrazonum",
      "Sulfoxyphenylpyrazolidin",
      "Sulfoxyphenylpyrazolidine",
      "Sulphinpyrazone",
      "Apo Sulfinpyrazone",
      "Apotex Brand Of Sulfinpyrazone",
      "Novartis Brand Of Sulfinpyrazone",
      "Nu Pharm Brand Of Sulfinpyrazone",
      "Nu Sulfinpyrazone",
      "G 28315",
      "Anturane (TN)",
      "Apo-Sulfinpyrazone",
      "G 28,315",
      "Nu-Pharm Brand Of Sulfinpyrazone",
      "Nu-Sulfinpyrazone",
      "Sulfinpirazona [INN-Spanish]",
      "Sulfinpyrazone (SPZ)",
      "Sulfinpyrazonum [INN-Latin]",
      "USAF GE-13",
      "Sulfinpyrazone [USAN:INN:JAN]",
      "Sulfinpyrazone (JP15/USP/INN)",
      "(+/-)-SULFINPYRAZONE",
      "1,2-Diphenyl-3,5-Dioxo-4-(2'-Phenyl-Sulfinyl-Aethyl)-Pyrazolidin",
      "1,2-Diphenyl-3,5-Dioxo-4-(2'-Phenyl-Sulfinyl-Aethyl)-Pyrazolidin [German]",
      "1,2-Diphenyl-3,5-Dioxo-4-(2-Phenylsulfinylethyl) Pyrazolidine",
      "1,2-Diphenyl-3,5-Dioxo-4-(2-Phenylsulfinylethyl)Pyrazolidine",
      "1,2-Diphenyl-4-(2'-Phenylsulfinethyl)-3,5-Pyrazolidinedione",
      "1,2-Diphenyl-4-(2-(Phenylsulfinyl)Ethyl)-3,5-Pyrazolidinedione",
      "1,2-Diphenyl-4-(Phenylsulfinylethyl)-3,5-Pyrazolidinedione",
      "1,2-Diphenyl-4-[2-(Phenylsulfinyl)Ethyl-3,5-Pyrazolidinedione",
      "1,2-Diphenyl-4-[2-(Phenylsulfinyl)Ethyl]pyrazolidine-3,5-Dione",
      "4-(2-Benzenesulfinylethyl)-1,2-Diphenylpyrazolidine-3,5-Dione",
      "4-(Phenylsulfoxyethyl)-1,2-Diphenyl-3,5-Pyrazolidinedione",
      "4-[2-(Benzenesulfinyl)Ethyl]-1,2-Diphenylpyrazolidine-3,5-Dione",
    ],
  },
  {
    primary_id: "D03DEJ",
    names: ["BMS614", "BMS 614", "BMS-614"],
  },
  {
    primary_id: "D03DEO",
    names: [
      "PALBINONE",
      "139954-00-0",
      "AC1L4U4C",
      "18-Norandrosta-11,13(17)-Diene-15,16-Dione,3,17-Dihydroxy-4,4,8,14-Tetramethyl-, (3b,5a)-",
    ],
  },
  {
    primary_id: "D03DET",
    names: ["SB 714786"],
  },
  {
    primary_id: "D03DEX",
    names: ["N6-Benzyl Adenosine-5'-Diphosphate"],
  },
  {
    primary_id: "D03DFG",
    names: [
      "1-(1,3,4-Thiadiazol-2-Yl)Octadec-9-En-1-One",
      "CHEMBL467354",
      "SCHEMBL2162931",
      "OGPJTMSGDUTXCW-KTKRTIGZSA-N",
      "BDBM50262029",
    ],
  },
  {
    primary_id: "D03DFY",
    names: ["NP-7557", "Alzheimers Disease Therapy, Nastech", "AChEI Therapy (Intranasal), Nastech"],
  },
  {
    primary_id: "D03DGP",
    names: ["6-(2-Acetylphenyl)-2-Morpholin-4-Ylchromen-4-One", "CHEMBL381869"],
  },
  {
    primary_id: "D03DHM",
    names: ["CCX507"],
  },
  {
    primary_id: "D03DHQ",
    names: [
      "CX-516",
      "Ampalex",
      "BDP 12",
      "CX 516",
      "CX516",
      "BDP-12",
      "SPD-420",
      "Piperidin-1-Yl(Quinoxalin-6-Yl)Methanone",
      "1-(6-Quinoxalinylcarbonyl)Piperidine",
      "1-(6-Quinoxalinylcarbonyl)-Piperidine",
      "1-(Quinoxalin-6-Ylcarbonyl)Piperidine",
      "6-(Piperidin-1-Ylcarbonyl)Quinoxaline",
    ],
  },
  {
    primary_id: "D03DIG",
    names: [
      "Codeine",
      "Methylmorphine",
      "Codeine Anhydrous",
      "Codicept",
      "L-Codeine",
      "Coducept",
      "Morphine Monomethyl Ether",
      "Morphine 3-Methyl Ether",
      "76-57-3",
      "(-)-Codeine",
      "O3-Methylmorphine",
      "Codeinum",
      "Morphine-3-Methyl Ether",
      "Codein",
      "Norcodine, N-Methyl",
      "Norcodeine, N-Methyl",
      "CODEINE BASE",
      "Codeine Polistirex",
      "Codeina",
      "O(3)-Methylmorphine",
      "CCRIS 7555",
      "Morphine-3-Methyl Ester",
      "HSDB 3043",
      "UNII-UX6OWY2V7J",
      "CHEBI:16714",
      "EINECS 200-969-1",
      "UX6OWY2V7J",
      "Morphinan-6-Ol, 7,8-Didehydro-4,5-Epoxy-3-Methoxy-17-Methyl-, (5alpha,6alpha)-",
      "Codeine Anhydrate",
    ],
  },
  {
    primary_id: "D03DII",
    names: ["Pollen Allergen Immunotherapy"],
  },
  {
    primary_id: "D03DIP",
    names: ["3-(2-Diethylamino-Propionamino)-Rutaecarpine", "CHEMBL1098295"],
  },
  {
    primary_id: "D03DIQ",
    names: ["Egonol Compound 1"],
  },
  {
    primary_id: "D03DIV",
    names: ["1,1,1-Trifluoro-6-(Naphthalen-2-Yl)Hexan-2-One"],
  },
  {
    primary_id: "D03DJC",
    names: ["2-(2-Aminophenyl)-6-Morpholino-4H-Pyran-4-One", "CHEMBL375346"],
  },
  {
    primary_id: "D03DJL",
    names: [
      "Indacaterol",
      "312753-06-3",
      "QAB149",
      "Arcapta",
      "Onbrez",
      "QAB-149",
      "QAB 149",
      "UNII-8OR09251MQ",
      "753498-25-8",
      "CHEMBL1095777",
      "CHEBI:68575",
      "8OR09251MQ",
      "(R)-5-(2-(5,6-Diethyl-2,3-Dihydro-1H-Inden-2-Ylamino)-1-Hydroxyethyl)-8-Hydroxyquinolin-2(1H)-One",
      "5-[(1R)-2-[(5,6-Diethyl-2,3-Dihydro-1H-Inden-2-Yl)Amino]-1-Hydroxyethyl]-8-Hydroxy-1H-Quinolin-2-One",
      "Indacaterol Maleic Acid Salt",
      "Indacaterol (USAN/INN)",
      "Indacaterol [USAN:INN:BAN]",
      "5-{(1R)-2-[(5,6-Diethyl-2,3-Dihydro-1H-Inden-2-Yl)Amino]-1-Hydroxyethyl}-8-Hydroxyquinolin-2(1H)-One",
      "5-(2-(5,6-Diethylindan-2-Ylamino)-1-Hydroxyethyl)-8-Hydroxy-1H-Quinolin-2-One",
      "Indacaterol/Mometasone",
    ],
  },
  {
    primary_id: "D03DJR",
    names: ["D-65476", "Bis(1H-2-Indolyl)Methanone Deriv. 52"],
  },
  {
    primary_id: "D03DJT",
    names: ["Bococizumab"],
  },
  {
    primary_id: "D03DKG",
    names: ["EMD 56551"],
  },
  {
    primary_id: "D03DKN",
    names: ["Ap5a", "Diadenosine Pentaphosphate"],
  },
  {
    primary_id: "D03DKR",
    names: [
      "GALAMUSTINE HYDROCHLORIDE",
      "C6-GLM",
      "C6-GalM",
      "C6-Galactose Mustard",
      "G-6-M",
      "Galamustine Hydrochloride",
      "6-[Bis(2-Chloroethyl)Amino]-6-Deoxy-D-Galactopyranose Hydrochloride",
    ],
  },
  {
    primary_id: "D03DKV",
    names: ["RGB-286638"],
  },
  {
    primary_id: "D03DKZ",
    names: ["VU0238429"],
  },
  {
    primary_id: "D03DLD",
    names: ["H-Tyr-Ala-Phe-Phe-NH2", "CHEMBL58178", "BDBM50308383", "NCGC00163200-01"],
  },
  {
    primary_id: "D03DLZ",
    names: ["2-(1H-Indol-3-Yl)-N,N-Dimethylethanamine"],
  },
  {
    primary_id: "D03DMA",
    names: ["Geranylcoumarin"],
  },
  {
    primary_id: "D03DMB",
    names: ["GSK2894512"],
  },
  {
    primary_id: "D03DMI",
    names: ["SIB-1663"],
  },
  {
    primary_id: "D03DMN",
    names: [
      "AKUAMMIGINE",
      "Akuammigine",
      "642-17-1",
      "UNII-1E21YYE0UM",
      "1E21YYE0UM",
      "CHEMBL122404",
      "C21H24N2O3",
      "AC1LOQX5",
      "SCHEMBL563799",
      "MolPort-039-052-713",
      "CHEBI:141878",
      "ZINC53174616",
      "PDSP1_001665",
      "BDBM50030622",
      "9078AF",
      "AKOS032948886",
      "Oxayohimban-16-Carboxylic Acid, 16,17-Didehydro-19-Methyl-, Methyl Ester, (3beta,19alpha,20alpha)-",
      "4H-Indolo(2,3-A)Pyrano(3,4-G)Quinolizine-1-Carboxylic Acid, 4a,5,7,8,13,13b,14,14a-Octahydro-4-Methyl-, Methyl Ester, (4S,4aS,13bR,14aS)-",
      "Methyl (3beta,19alpha,20alpha)-19-Methyl-16,17-Didehydro-18-Oxayohimban-16-Carboxylate",
    ],
  },
  {
    primary_id: "D03DMR",
    names: ["Lithocholylcholine"],
  },
  {
    primary_id: "D03DND",
    names: ["2-(2,4-Dichlorophenoxy)-5-Propylphenol", "Triclosan Derivative, 9", "CHEMBL263896", "BDBM25408"],
  },
  {
    primary_id: "D03DNE",
    names: ["Benzamide Derivative 3"],
  },
  {
    primary_id: "D03DNI",
    names: ["Hepatitis C Virus E2 Protein Vaccines"],
  },
  {
    primary_id: "D03DNV",
    names: ["ARI-3099", "Compound 6 [PMID: 23594271]", "Compound 226 [PMID: 24997602]", "Py(D)AlaboroPro"],
  },
  {
    primary_id: "D03DOC",
    names: ["DCC-2909", "CFMS Kinase Inhibitors (Bone Metastasis/Tumor Metastasis), Deciphera"],
  },
  {
    primary_id: "D03DOW",
    names: ["SWT-05141"],
  },
  {
    primary_id: "D03DPD",
    names: ["CGNLSTCMLGTYTQDFc[DKFHK]FPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D03DPZ",
    names: [
      "INK128",
      "1224844-38-5",
      "Sapanisertib",
      "INK-128",
      "INK 128",
      "INK 128 (MLN0128)",
      "TAK-228",
      "UNII-JGH0DF1U03",
      "JGH0DF1U03",
      "5-(4-Amino-1-Isopropyl-1H-Pyrazolo[3,4-D]pyrimidin-3-Yl)Benzo[d]oxazol-2-Amine",
      "INK-0128",
      "3-(2-Amino-5-Benzoxazolyl)-1-(1-Methylethyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "C15H15N7O",
      "5-(4-Amino-1-Propan-2-Ylpyrazolo[3,4-D]pyrimidin-3-Yl)-1,3-Benzoxazol-2-Amine",
      "5-(4-Amino-1-Isopropyl-1H-Pyrazolo[3,4-D]-Pyrimidin-3-Yl)Benzo[d]oxazol-2-Amine",
      "Sapanisertib (USAN/INN)",
    ],
  },
  {
    primary_id: "D03DQK",
    names: ["PEG-Hb/HS"],
  },
  {
    primary_id: "D03DQQ",
    names: ["4-(2-Fluoro-6-(3-Fluorophenoxy)Phenyl)Piperidine", "CHEMBL574004"],
  },
  {
    primary_id: "D03DQW",
    names: ["PMID25980951-Compound-21"],
  },
  {
    primary_id: "D03DRF",
    names: ["2-Allylthio-3-Benzyl-6-Nitro-Quinazolin-4(3H)-One", "CHEMBL1092451"],
  },
  {
    primary_id: "D03DRL",
    names: ["Des-AA1,2,5-[D-Nal8,IAmp9,(NalphaMe)Thr12]SRIF", "CHEMBL437905"],
  },
  {
    primary_id: "D03DRM",
    names: ["AS-604850", "AS 604850", "PI 3-Kgamma Inhibitor II"],
  },
  {
    primary_id: "D03DRR",
    names: ["Noberastine"],
  },
  {
    primary_id: "D03DRW",
    names: ["UTIBAPRIL"],
  },
  {
    primary_id: "D03DSB",
    names: ["Trospectomycin"],
  },
  {
    primary_id: "D03DSG",
    names: ["Ac-His-Trp-Ala-Val-D-Ala-His-Leu-Met-NH2", "CHEMBL263873"],
  },
  {
    primary_id: "D03DSP",
    names: [
      "Cidofovir Prodrugs",
      "Cidofovir Prodrugs, UCLA",
      "D-Ser-OIPr CHPMPC",
      "L-Ser-OIPr CHPMPC",
      "L-Ser-L-Ala-OIPr CHPMPC",
    ],
  },
  {
    primary_id: "D03DSR",
    names: [
      "Ambroxol",
      "Ambroxol Hydrochloride",
      "23828-92-4",
      "Ambroxol HCl",
      "Mucosolvan",
      "Mucoangin",
      "Ambroxolhydrochloride",
      "Cis-4-((2-Amino-3,5-Dibromobenzyl)Amino)Cyclohexanol Hydrochloride",
      "UNII-CC995ZMV90",
      "Ambroxol  Hydrochloride",
      "Trans-4-((2-Amino-3,5-Dibromobenzyl)Amino)Cyclohexanol Hydrochloride",
      "Ambroxol Hydrochloride [JAN]",
      "CC995ZMV90",
      "15942-05-9",
      "Ambroxol Hydrochloride (JAN)",
      "2-Amino-3,5-Dibromo-N-[trans-4-Hydroxycyclohexyl]benzylamine",
      "4-((2-Amino-3,5-Dibromobenzyl)Amino)Cyclohexan-1-Ol Hydrochloride",
    ],
  },
  {
    primary_id: "D03DSX",
    names: ["SSR411298"],
  },
  {
    primary_id: "D03DTQ",
    names: ["PMX-700"],
  },
  {
    primary_id: "D03DTU",
    names: ["BCL-5"],
  },
  {
    primary_id: "D03DTW",
    names: ["EHT-107"],
  },
  {
    primary_id: "D03DTZ",
    names: ["ZK 756326"],
  },
  {
    primary_id: "D03DUH",
    names: ["ACAM-FLU-A"],
  },
  {
    primary_id: "D03DUI",
    names: ["GBR 830"],
  },
  {
    primary_id: "D03DUN",
    names: [
      "C-(1-Hydrogyl-Beta-D-Glucopyranosyl) Formamide",
      "C-(1-HYDROGYL-BETA-D-GLUCOPYRANOSYL) FORMAMIDE",
      "CBF",
      "Alpha-D-Gluco-2-Heptulopyranosonamide",
      "AC1L9L7Q",
      "DB02719",
      "361440-32-6",
      "(1-Hydroxy-Beta-D-Glucopyranosyl)Formamide",
      "(2R,3R,4S,5S,6R)-2,3,4,5-Tetrahydroxy-6-(Hydroxymethyl)Oxane-2-Carboxamide",
      "(2R,3R,4S,5S,6R)-2,3,4,5-Tetrahydroxy-6-(Hydroxymethyl)Tetrahydro-2H-Pyran-2-Carboxamide (Non-Preferred Name)",
    ],
  },
  {
    primary_id: "D03DUX",
    names: ["2-(4-Methyl-Indole-1-Sulfonyl)-Benzoic Acid", "CHEMBL184195"],
  },
  {
    primary_id: "D03DUY",
    names: [
      "BMS-833923",
      "1059734-66-5",
      "BMS 833923",
      "XL139",
      "XL 139",
      "BMS833923",
      "UNII-41J7ZJ239R",
      "N-(2-Methyl-5-((Methylamino)Methyl)Phenyl)-4-((4-Phenylquinazolin-2-Yl)Amino)Benzamide",
      "41J7ZJ239R",
      "XL-139",
      "C30H27N5O",
      "N-(2-Methyl-5-((Methylamino)Methyl)Phenyl)-4-(4-Phenylquinazolin-2-Ylamino)Benzamide",
      "Benzamide, N-[2-Methyl-5-[(Methylamino)Methyl]phenyl]-4-[(4-Phenyl-2-Quinazolinyl)Amino]-",
      "BMS-833923 Free Base Anhydrous",
      "XL139 Mesylate",
      "SCHEMBL4138073",
      "GTPL8202",
      "CHEMBL3545403",
      "EX-A789",
      "MolPort-035-395-837",
      "HMS3653O09",
      "BCP07837",
      "AOB87352",
    ],
  },
  {
    primary_id: "D03DVD",
    names: ["Norovirus Virus-Like Particle Vaccine"],
  },
  {
    primary_id: "D03DVF",
    names: [
      "Gabaculine",
      "DL-Gabaculine",
      "3-Amino-2,3-Dihydrobenzoic Acid",
      "GABACULINE, D.L-",
      "Gabaculin",
      "59556-18-2",
      "5-Amino-1,3-Cyclohexadienylcarboxylic Acid",
      "5-Aminocyclohexa-1,3-Diene-1-Carboxylic Acid",
      "NSC-329502",
      "87980-11-8",
      "C7H9NO2",
      "(+-)-Gabaculine",
      "AC1L1FYB",
      "AC1Q5RCE",
      "Biomol-NT_000232",
      "Lopac0_000006",
      "SCHEMBL2024044",
      "BPBio1_000639",
      "CHEMBL1394922",
      "CTK1H1588",
      "DTXSID90274399",
      "KFNRJXCQEJIBER-UHFFFAOYSA-N",
      "CPD0-1467",
      "NSC329502",
      "Benzoic Acid, 3-Amino-2,3-Dihydro-",
      "NSC 329502",
      "CCG-204102",
      "NCGC00015051-04",
      "NCGC00015051-03",
      "NCGC00162040-01",
      "NCGC00015051-02",
    ],
  },
  {
    primary_id: "D03DVJ",
    names: ["Propylhexedrine"],
  },
  {
    primary_id: "D03DVM",
    names: [
      "2-(3-Hydroxyphenyl)-1,2'-Spirobi[1H-Indene]-6-Ol",
      "CHEMBL144431",
      "SCHEMBL6283932",
      "BDBM50123207",
      "2-(3-Hydroxyphenyl)-1,2''-Spirobi[1H-Indene]-6-Ol",
    ],
  },
  {
    primary_id: "D03DVV",
    names: ["NC-1006"],
  },
  {
    primary_id: "D03DWG",
    names: ["NADPH"],
  },
  {
    primary_id: "D03DWZ",
    names: ["SNP-002"],
  },
  {
    primary_id: "D03DXK",
    names: [
      "D[Leu4,Lys8]VP",
      "42061-33-6",
      "CHEMBL412972",
      "D[Leu4,Lys8]-VP",
      "1-Deamino-4-Leu-8-Lys-Vasopressin",
      "Vasopressin, 1-Deamino-Leu(4)-Lys(8)-",
      "Vasopressin, 1-Deamino-Leucyl(4)-Lysine(8)-",
      "BDBM50205309",
      "AKOS024457446",
      "(Deamino-Cys1,Leu4,Lys8)-Vasopressin",
      "Vasopressin, 1-(3-Mercaptopropanoic Acid)-4-L-Leucine-8-L-Lysine-",
    ],
  },
  {
    primary_id: "D03DXN",
    names: ["Polyestradiol Phosphate"],
  },
  {
    primary_id: "D03DXS",
    names: ["Odapipam"],
  },
  {
    primary_id: "D03DXV",
    names: [
      "4-(1,2,3-Thiadiazol-4-Yl)Phenyl Butylcarbamate",
      "CHEMBL551567",
      "AC1MCABO",
      "MolPort-002-920-365",
      "HMS1668O20",
      "ZINC3119736",
      "BDBM50295657",
      "MCULE-2085974247",
      "CCG-245837",
      "[4-(Thiadiazol-4-Yl)Phenyl] N-Butylcarbamate",
    ],
  },
  {
    primary_id: "D03DYB",
    names: ["AZD-0275"],
  },
  {
    primary_id: "D03DYT",
    names: ["K252a"],
  },
  {
    primary_id: "D03DZK",
    names: ["WSBI-19711A"],
  },
  {
    primary_id: "D03DZO",
    names: ["PHA-767491"],
  },
  {
    primary_id: "D03DZP",
    names: ["HI-242"],
  },
  {
    primary_id: "D03DZQ",
    names: ["PMID30259754-Compound-WAY-362450"],
  },
  {
    primary_id: "D03EAN",
    names: ["PMID2153213C1e"],
  },
  {
    primary_id: "D03EAQ",
    names: ["N-Hydroxysulfonamides"],
  },
  {
    primary_id: "D03EBE",
    names: ["6s-5,6,7,8-Tetrahydrobiopterin"],
  },
  {
    primary_id: "D03EBH",
    names: ["D(CH2)5[Tyr(Me)2,Thr4,Orn8,Tyr9-NH2]VT"],
  },
  {
    primary_id: "D03EBK",
    names: ["HIV-Specific STAR Fusions"],
  },
  {
    primary_id: "D03EBV",
    names: ["JNS-001"],
  },
  {
    primary_id: "D03EBZ",
    names: [
      "HMR1883",
      "Clamikalant",
      "Clamikalant [INN]",
      "158751-64-5",
      "UNII-94301K998R",
      "CHEMBL12531",
      "HMR-1883",
      "94301K998R",
      "1-((5-(2-(5-Chloro-O-Anisamido)Ethyl)-2-Methoxyphenyl)Sulfonyl)-3-Methyl-2-Thiourea",
      "AC1MI3H0",
      "SCHEMBL726268",
      "DTXSID30166522",
      "VXTKXGKPBOLHRY-UHFFFAOYSA-N",
      "ZINC599915",
      "BDBM50099573",
      "AKOS027339538",
      "API0011065",
      "NCGC00386636-01",
      "AS-44931",
      "LS-26123",
      "SR-01000945051",
      "SR-01000945051-1",
      "5-Chloro-2-Methoxy-N-[2-[4-Methoxy-3-(Methylcarbamothioylsulfamoyl)Phenyl]ethyl]benzamide",
    ],
  },
  {
    primary_id: "D03ECE",
    names: ["(Sar)WTLNSAGYLLGPKK(Lys-Octanoyl)K", "CHEMBL503900"],
  },
  {
    primary_id: "D03ECU",
    names: [
      "[125I]iodophenpropit",
      "(1S)-1-[3-(3H-Imidazol-4-Yl)Propylsulfanyl]-N-[2-(4-Iodophenyl)Ethyl]methanediamine",
      "[125I]IODOPHENPROPIT",
      "AC1NSK68",
      "GTPL3960",
      "GTPL1266",
      "IODOPHENPROPIT",
    ],
  },
  {
    primary_id: "D03EDD",
    names: [
      "VN/107-1",
      "CYP17 Inhibitors (Prostate Cancer)",
      "CYP17 Inhibitors (Prostate Cancer), University Of Maryland",
      "VN/108-1",
      "VN/109-1",
      "VN/125-1",
      "VN/85-1",
      "VN/87-1",
    ],
  },
  {
    primary_id: "D03EDF",
    names: [
      "DEMETHOXYCURCUMIN",
      "Demethoxycurcumin",
      "Monodemethoxycurcumin",
      "22608-11-3",
      "BHCFM",
      "24939-17-1",
      "4-Hydroxycinnamoyl(Feroyl)Methane",
      "Desmethoxycurcumin",
      "Feruloyl-P-Hydroxycinnnamoylmethane",
      "CurcuminII",
      "UNII-W2F8059T80",
      "33171-16-3",
      "4-Hydroxycinnamoyl(Feruloyl)Methane",
      "NSC687841",
      "W2F8059T80",
      "1,6-Heptadiene-3,5-Dione, 1-(4-Hydroxy-3-Methoxyphenyl)-7-(4-Hydroxyphenyl)-",
      "1-(4-Hydroxy-3-Methoxyphenyl)-7-(4-Hydroxyphenyl)Hepta-1,6-Diene-3,5-Dione",
      "(1E,6E)-1-(4-Hydroxy-3-Methoxyphenyl)-7-(4-Hydroxyphenyl)Hepta-1,6-Diene-3,5-Dione",
      "Demethoxy-Curcumin",
    ],
  },
  {
    primary_id: "D03EDQ",
    names: [
      "Vismodegib",
      "879085-55-9",
      "GDC-0449",
      "Erivedge",
      "2-Chloro-N-(4-Chloro-3-(Pyridin-2-Yl)Phenyl)-4-(Methylsulfonyl)Benzamide",
      "Vismodegib (GDC-0449)",
      "HhAntag691",
      "GDC0449",
      "GDC 0449",
      "UNII-25X868M3DS",
      "CHEMBL473417",
      "CHEBI:66903",
      "25X868M3DS",
      "NSC755986",
      "AK-77261",
      "2-Chloro-N-[4-Chloro-3-(Pyridin-2-Yl)Phenyl]-4-(Methylsulfonyl)Benzamide",
      "C19H14Cl2N2O3S",
      "2-Chloro-N-(4-Chloro-3-Pyridin-2-Ylphenyl)-4-Methylsulfonylbenzamide",
      "2-Chloro-N-(4-Chloro-3-(2-Pyridinyl)Phenyl)-4-(Methylsulfonyl)Benzamide",
      "Erivedge (TN)",
      "Vismodegib (SHH Inhibitor)",
      "Gdc-0449",
    ],
  },
  {
    primary_id: "D03EDR",
    names: ["N-Acetyl-N'-Beta-D-Glucopyranosyl Urea"],
  },
  {
    primary_id: "D03EDW",
    names: ["D-193", "CHEMBL469181", "BDBM50261305"],
  },
  {
    primary_id: "D03EEA",
    names: ["PMID25666693-Compound-70"],
  },
  {
    primary_id: "D03EEH",
    names: ["Gadodiamide"],
  },
  {
    primary_id: "D03EEO",
    names: ["MRS 2219"],
  },
  {
    primary_id: "D03EER",
    names: ["Thyrotropin"],
  },
  {
    primary_id: "D03EFD",
    names: ["KT-95"],
  },
  {
    primary_id: "D03EGA",
    names: [
      "SRT1720",
      "925434-55-5",
      "N-(2-(3-(Piperazin-1-Ylmethyl)Imidazo[2,1-B]thiazol-6-Yl)Phenyl)Quinoxaline-2-Carboxamide",
      "SRT 1720",
      "SRT-1720",
      "CHEMBL257991",
      "N-[2-[3-(1-PIPERAZINYLMETHYL)IMIDAZO[2,1-B]THIAZOL-6-YL]PHENYL]-2-QUINOXALINECARBOXAMIDE",
      "N-(2-{3-[(Piperazin-1-Yl)Methyl]imidazo[2,1-B][1,3]thiazol-6-Yl}phenyl)Quinoxaline-2-Carboxamide",
      "Tafluprost Enone",
      "N-[2-[3-(Piperazin-1-Ylmethyl)Imidazo[2,1-B]thiazol-6-Yl]phenyl]quinoxaline-2-Carboxamide",
      "IASPBORHOMBZMY-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D03EGR",
    names: ["(1R,2R)-N-Oleoylcyclopropanolamide"],
  },
  {
    primary_id: "D03EGZ",
    names: ["OHIOENSIN A"],
  },
  {
    primary_id: "D03EHB",
    names: [
      "3-Bromo-1H-Indazole-7-Carbonitrile",
      "945762-00-5",
      "CHEMBL451541",
      "1H-Indazole-7-Carbonitrile,3-Bromo-",
      "AK-27881",
      "CTK8B8302",
      "KS-00000HFF",
      "DTXSID00659577",
      "MolPort-009-197-290",
      "3-Bromo-2H-Indazole-7-Carbonitrile",
      "ZINC40448363",
      "BDBM50270530",
      "ANW-59984",
      "AKOS015898261",
      "FCH1406736",
      "VI20021",
      "RP05214",
      "SC-35603",
      "QC-11297",
      "KB-70485",
      "DS-11111",
      "CJ-20125",
      "TC-148729",
      "ST2417498",
      "AX8158454",
      "AB0037522",
      "AJ-102668",
      "AJ-102669",
      "FT-0646755",
      "Y4808",
      "4CH-016057",
      "A15929",
      "J-511797",
    ],
  },
  {
    primary_id: "D03EHC",
    names: ["PMID25399762-Compound-Table 5-8"],
  },
  {
    primary_id: "D03EHI",
    names: ["Acrylamide Compound 3"],
  },
  {
    primary_id: "D03EHM",
    names: ["PP121"],
  },
  {
    primary_id: "D03EHR",
    names: ["Lecimibide", "DuP-128"],
  },
  {
    primary_id: "D03EHS",
    names: ["Boc-D-Glu-Tyr(SO3H)-Nle-D-Lys-Trp-Nle-Asp-Phe-NH2", "CHEMBL440259"],
  },
  {
    primary_id: "D03EIA",
    names: ["Anti-CD19-CAR T"],
  },
  {
    primary_id: "D03EIC",
    names: ["ZD-7349"],
  },
  {
    primary_id: "D03EIG",
    names: ["C3BS-GQR-1"],
  },
  {
    primary_id: "D03EIK",
    names: ["NV.RYM.09"],
  },
  {
    primary_id: "D03EIM",
    names: [
      "Borrelidin",
      "7184-60-3",
      "2-(7-Cyano-8,16-Dihydroxy-9,11,13,15-Tetramethyl-18-Oxooxacyclooctadeca-4,6-Dien-2-Yl)Cyclopentanecarboxylic Acid",
      "Quinquangulin K 182",
      "Quinquangulin K 031",
      "AC1O53K5",
      "Cyclopentanecarboxylic Acid, 2-(7-Cyano-8, 16-Dihydroxy-9,11,13,15-Tetramethyl-18-Oxooxacyclooctadeca-4, 6-Dien-2-Yl)-",
      "2-[(4Z,6Z)-7-Cyano-8,16-Dihydroxy-9,11,13,15-Tetramethyl-18-Oxo-1-Oxacyclooctadeca-4,6-Dien-2-Yl]cyclopentane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03EJP",
    names: ["SHA 68"],
  },
  {
    primary_id: "D03ELL",
    names: [
      "Amisulpride",
      "Aminosultopride",
      "Amisulpiride",
      "Amisulprida",
      "Amisulpridum",
      "Deniban",
      "Socian",
      "Solian",
      "Amisulpride [INN]",
      "DAN 2163",
      "Amisulprida [INN-Spanish]",
      "Amisulpride (INN)",
      "Amisulpridum [INN-Latin]",
      "DAN-2163",
      "Deniban (TN)",
      "Solian (TN)",
      "Solian, Amisulpride",
      "SL-91.1077",
      "4-Amino-N-((1-Ethyl-2-Pyrrolidinyl)Methyl)-5-(Ethylsulfonyl)-2-Anisamid",
      "4-Amino-N-((1-Ethyl-2-Pyrrolidinyl)Methyl)-5-(Ethylsulfonyl)-2-Methoxybenzamide",
      "4-Amino-N-((1-Ethyl-2-Pyrrolidinyl)Methyl)-5-(Ethylsulfonyl)-O-Anisamide",
      "4-Amino-N-[(1-Ethyl-2-Pyrrolidinyl)Methyl]-5-(Ethylsulfonyl)-2-Methoxybenzamide",
      "4-Amino-N-[(1-Ethylpyrrolidin-2-Yl)Methyl]-5-(Ethylsulfonyl)-2-Methoxybenzamide",
      "4-Amino-N-[(1-Ethylpyrrolidin-2-Yl)Methyl]-5-Ethylsulfonyl-2-Methoxybenzamide",
    ],
  },
  {
    primary_id: "D03EML",
    names: ["PMID26077642-Compound-Vif2"],
  },
  {
    primary_id: "D03EMO",
    names: ["(RS/SR)-2-[1-(4-Chlorophenyl)Hexyl]piperidine", "CHEMBL219703", "BDBM50202394"],
  },
  {
    primary_id: "D03EMY",
    names: ["Pyrrolo[2,3-B]pyridine Carboxamide Derivative 1"],
  },
  {
    primary_id: "D03END",
    names: ["Insecticide"],
  },
  {
    primary_id: "D03ENW",
    names: ["Benzothiazepine Analog 3"],
  },
  {
    primary_id: "D03EOD",
    names: ["4-[2,2-Bis(4-Hydroxyphenyl)-1-Methylvinyl]phenol"],
  },
  {
    primary_id: "D03EOJ",
    names: ["N-Oxalyl-D-Tyrosine Derivative 2"],
  },
  {
    primary_id: "D03EOK",
    names: ["N-Indan-1-Yl-2-(1H-Indol-3-Yl)-2-Oxo-Acetamide"],
  },
  {
    primary_id: "D03EOT",
    names: ["Borocaptate Sodium B 10"],
  },
  {
    primary_id: "D03EOZ",
    names: ["IMG-7289"],
  },
  {
    primary_id: "D03EPA",
    names: ["Tyr-D-Phe-Gly-Trp-Nle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D03EPB",
    names: [
      "BIS(18)-HUPERZINE B",
      "Bis(18)-Huperzine B",
      "Bis-Huperzine B 5g",
      "Bis(18)-HupB",
      "CHEMBL176132",
      "BDBM10947",
    ],
  },
  {
    primary_id: "D03EPY",
    names: [
      "1,2,3,4-Tetrahydroisoquinoline",
      "91-21-4",
      "Tetrahydroisoquinoline",
      "1,2,3,4-Tetrahydro-Isoquinoline",
      "ISOQUINOLINE, 1,2,3,4-TETRAHYDRO-",
      "1,2,3,4-Tetrahydro Isoquinoline",
      "EINECS 202-050-0",
      "NSC 15312",
      "1,2,3,4-Tetrahydro-2-Isoquinoline",
      "1,2,3,4-Tetrahydro-2-Azanaphthalene",
      "BRN 0116156",
      "UNII-56W89FBX3E",
      "CHEMBL14346",
      "AI3-15931",
      "56W89FBX3E",
      "UWYZHKAOTLEWKK-UHFFFAOYSA-N",
      "MFCD00006896",
      "1,2,3,4-Tetrahydroisoquinoline, 95%",
      "3,4-Dihydro-1H-Isoquinoline",
      "Tetrahydroisoquinolin",
      "Tetrahyroisoquinoline",
      "Tetrahydro-Isoquinoline",
      "PubChem7253",
      "AC1L1NVS",
    ],
  },
  {
    primary_id: "D03EQH",
    names: ["RIPAZEPAM"],
  },
  {
    primary_id: "D03EQN",
    names: [
      "2'-Deoxyuridine 5'-Alpha,beta-Imido-Diphosphate",
      "2'-DEOXYURIDINE 5'-ALPHA,BETA-IMIDO-DIPHOSPHATE",
      "DUN",
      "AC1L9MQ3",
      "2,4(1H,3H)-PYRIMIDINEDIONE, 1-[2-DEOXY-5-O-[HYDROXY(PHOSPHONOAMINO)PHOSPHINYL]-BETA-D-ERYTHRO-PENTOFURANOSYL]-",
      "DB03641",
      "[(2'-Deoxy-5'-Uridylyl)Amino]phosphonic Acid",
      "2'-Deoxy-5'-O-[(R)-Hydroxy(Phosphonoamino)Phosphoryl]uridine",
      "1-[2-DEOXY-5-O-[HYDROXY(PHOSPHONOAMINO)PHOSPHINYL]-BETA-D-ERYTHRO-PENTOFURANOSYL]-",
      "[[[(2R,3S,5R)-5-(2,4-Dioxopyrimidin-1-Yl)-3-Hydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl]amino]phosphonic Acid",
    ],
  },
  {
    primary_id: "D03ERR",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D03ERS",
    names: ["MRS2500"],
  },
  {
    primary_id: "D03ESA",
    names: ["P-MPPI"],
  },
  {
    primary_id: "D03ESC",
    names: [
      "5-{8(Z),11(Z),14-Pentadecatrienyl}resorcinol",
      "Cardol Triene",
      "CHEMBL459603",
      "5-(8Z,11Z,14-Pentadecatrienyl)Resorcinol",
      "79473-24-8",
      "CHEBI:52680",
      "5-(Pentadeca-8,11,14-Trien-1-Yl)Resorcinol",
      "5-[(8Z,11Z)-Pentadeca-8,11,14-Trien-1-Yl]benzene-1,3-Diol",
      "(8Z,11Z)-5-(Pentadeca-8,11,14-Trien-1-Yl)Resorcinol",
      "5-Pentadecatrienyl Resorcinol",
      "5-((8Z,11Z)-Pentadeca-8,11,14-Trien-1-Yl)Benzene-1,3-Diol",
      "SCHEMBL2467234",
      "MEGxp0_000811",
      "ACon1_000596",
      "DTXSID30872873",
      "MolPort-001-740-965",
      "1,3-Benzenediol, 5-(8Z,11Z)-8,11,14-Pentadecatrienyl-",
    ],
  },
  {
    primary_id: "D03ESO",
    names: ["GW-3333"],
  },
  {
    primary_id: "D03ESR",
    names: ["Sphingosome Encapsulated Vinorelbine"],
  },
  {
    primary_id: "D03ESU",
    names: [
      "Anti-SEB Mabs",
      "Anti-Staphylococcal Enterotoxin B Monoclonal Antibodies, Mapp Biopharmaceutical/Tulane National Primate Research Center",
      "Anti-SEB MAbs (Lyophilized/Im, Staphylococcus Infection )",
      "Anti-SEB MAbs (Lyophilized/Im, Staphylococcus Infection), Mapp/ Tulane",
    ],
  },
  {
    primary_id: "D03ESW",
    names: ["Cetefloxacin", "E-4868"],
  },
  {
    primary_id: "D03ETD",
    names: ["Nectamazin C"],
  },
  {
    primary_id: "D03ETK",
    names: [
      "2-N-Hydroxyamino-1-(4-Methylthiophenyl)Propane",
      "CHEMBL1077857",
      "1204749-10-9",
      "Hydroxy-4-Methylthioamphetamine",
    ],
  },
  {
    primary_id: "D03ETN",
    names: ["PMID27828716-Compound-18"],
  },
  {
    primary_id: "D03ETT",
    names: ["Z-Ala-Leu-His-Agly-Ile-Val-OMe"],
  },
  {
    primary_id: "D03ETX",
    names: ["S-Benzyl-Brassinin"],
  },
  {
    primary_id: "D03EUD",
    names: ["PMID28092474-Compound-32g"],
  },
  {
    primary_id: "D03EWD",
    names: ["CTAP101 Capsules"],
  },
  {
    primary_id: "D03EXD",
    names: ["4-[(4'-Hydroxybiphenyl-4-Yl)Methyl]pyridine", "CHEMBL1215664", "BDBM50324611"],
  },
  {
    primary_id: "D03EXK",
    names: ["Povidone-Iodine"],
  },
  {
    primary_id: "D03EYA",
    names: ["Carveol"],
  },
  {
    primary_id: "D03EYI",
    names: ["Phenylcarbamic Acid Biphenyl-3-Yl Ester"],
  },
  {
    primary_id: "D03EYJ",
    names: ["Isoxazoles And Isoxazoline Derivative 1"],
  },
  {
    primary_id: "D03EYM",
    names: ["ISIS 29112"],
  },
  {
    primary_id: "D03EYT",
    names: ["N-(Pyridin-2-Yl)Pyridine Methylsulfone Derivative 1"],
  },
  {
    primary_id: "D03EZB",
    names: ["AR-H047108"],
  },
  {
    primary_id: "D03EZE",
    names: [
      "GSK2126458",
      "1086062-66-9",
      "Omipalisib",
      "GSK-2126458",
      "GSK 2126458",
      "UNII-1X8F5A3NA0",
      "2,4-Difluoro-N-(2-Methoxy-5-(4-(Pyridazin-4-Yl)Quinolin-6-Yl)Pyridin-3-Yl)Benzenesulfonamide",
      "2,4-Difluoro-N-[2-Methoxy-5-(4-Pyridazin-4-Ylquinolin-6-Yl)Pyridin-3-Yl]benzenesulfonamide",
      "2,4-Difluoro-N-[2-Methoxy-5-[4-(4-Pyridazinyl)-6-Quinolinyl]-3-Pyridinyl]benzenesulfonamide",
      "1X8F5A3NA0",
      "Omipalisib (GSK2126458, GSK458)",
      "C25H17F2N5O3S",
      "Omipalisib [USAN:INN]",
    ],
  },
  {
    primary_id: "D03EZQ",
    names: [
      "AN-1006",
      "AN 1006",
      "3',11-Dibutyrylankinomycin",
      "151283-90-8",
      "AC1L4JTW",
      "An1006",
      "2-(4,5-Anhydro-1,6-Dideoxy-Alpha-L-Sorbo-2,3-Oxiranosyl)-7,12-Dihydro-5-Methyl-4,7,12-Trioxo-10-(2,3,6-Trideoxy-3-(Dimethylamino)-3-C-Methyl-4-O-(1-Oxobutyl)-Alph-L-Lyxo-Hexopyranosyl)-4H-Anthra(1,2-B)Pyran-11-Yl Butanoate",
      "2-(4,5-Anhydro-1,6-Dideoxy-Alpha-L-Sorbo-2,3-Oxiranosyl)-7,12-Dihydro-5-Methyl-4,7,12-Trioxo-10-(2,3,6-Trideoxy-3-(Dimethylamino)-3-C-Methyl-4-O-(1-Oxobutyl)-Alpha-L-Lyxo-Hexopyranosyl)-4H-Anthra(1,2-B)Pyran-11-Yl Butanoate",
    ],
  },
  {
    primary_id: "D03EZR",
    names: ["CMD-003"],
  },
  {
    primary_id: "D03FAG",
    names: ["TRIPHENYLMETHANAMINE"],
  },
  {
    primary_id: "D03FAI",
    names: ["{[(9Z)-Octadec-9-En-1-Yl]sulfamoyl}amine", "CHEMBL570566", "SCHEMBL3117233"],
  },
  {
    primary_id: "D03FAP",
    names: ["A-75729", "A-75290", "A-75619"],
  },
  {
    primary_id: "D03FAX",
    names: [
      "2-(1-Adamantyl) Piperidine",
      "2-(1-Adamantyl)Piperidine",
      "2-Adamantanylpiperidine",
      "2-Adamantan-1-Yl-Piperidine",
      "AC1LAFGW",
      "Oprea1_303203",
      "MLS000121957",
      "SCHEMBL587889",
      "2-(Adamantan-1-Yl)Piperidine",
      "CHEMBL294443",
      "CTK5J6774",
      "MolPort-002-006-546",
      "HMS2382E07",
      "STL326117",
      "AKOS016346316",
      "AKOS000201213",
      "MCULE-8550112936",
      "BAS 06744019",
      "SMR000119379",
      "ST50340078",
      "J3.628.419F",
    ],
  },
  {
    primary_id: "D03FBI",
    names: ["3,5-Diamino-4'-Amino-Trans-Stilbene", "CHEMBL1171091", "BDBM50322064"],
  },
  {
    primary_id: "D03FBS",
    names: ["6-Ethyl-3-Propoxycarbonyl-4-Quinolone", "CHEMBL206068"],
  },
  {
    primary_id: "D03FBV",
    names: ["PF-04950615"],
  },
  {
    primary_id: "D03FCF",
    names: ["Novolimus"],
  },
  {
    primary_id: "D03FCU",
    names: ["4-(3-Ethyl-Phenoxy)-6,7-Dimethoxy-Quinoline", "CHEMBL100765"],
  },
  {
    primary_id: "D03FDC",
    names: [
      "Diisopropylphosphono Group",
      "Diisopropyl Phosphonate",
      "Phosphonic Acid, Bis(1-Methylethyl) Ester",
      "Diisopropylphosphine Oxide",
      "Phosphonic Acid, Diisopropyl Ester",
      "O,O-Diisopropyl Phosphonate",
      "Isopropyl Phosphonate",
      "Diisopropyl Hydrogen Phosphonate",
      "Isopropyl Phosphonate ((C3H7O)2HPO)",
      "Diisopropylphosphonate",
      "DIISOPROPYLPHOSPHONO GROUP",
      "NSC 82344",
      "O,O-Diisopropyl Phosphite",
      "Bis(Propan-2-Yl) Phosphonate",
      "BLKXLEPPVDUHBY-UHFFFAOYSA-N",
      "EINECS 217-317-7",
      "BRN 1703675",
      "Diisopropylphosponate",
      "Di-I-Propylphosphite",
      "AC1O3FKK",
      "Diisopropoxy(Oxo)Phosphonium",
    ],
  },
  {
    primary_id: "D03FDN",
    names: ["PMID26161824-Compound-69"],
  },
  {
    primary_id: "D03FDT",
    names: ["Phenolphthalein"],
  },
  {
    primary_id: "D03FEI",
    names: ["1-(4-Methoxy-Benzenesulfonyl)-Heptane-3-Thiol", "CHEMBL176319", "SCHEMBL7212147"],
  },
  {
    primary_id: "D03FEO",
    names: ["Ipecac"],
  },
  {
    primary_id: "D03FEP",
    names: ["Nanofibers"],
  },
  {
    primary_id: "D03FEV",
    names: [
      "N-Methyl-2-(Pyridin-3-Yloxy)Ethanamine",
      "CHEMBL112221",
      "228272-00-2",
      "SCHEMBL7101352",
      "CTK0J6067",
      "DTXSID50430732",
      "HKLZABNBARUZMN-UHFFFAOYSA-N",
      "Methyl(2-(3-Pyridyloxy)Ethyl)Amine",
      "BDBM50120586",
      "Methyl 2-(3-Pyridinyloxy)Ethylamine",
      "AKOS011738046",
      "N-Methyl-2-(Pyridin-3-Yloxy)Ethanaminium",
      "Methyl-[2-(Pyridin-3-Yloxy)-Ethyl]-Amine",
      "Ethanamine, N-Methyl-2-(3-Pyridinyloxy)-",
    ],
  },
  {
    primary_id: "D03FFA",
    names: ["HB-AS02V"],
  },
  {
    primary_id: "D03FFI",
    names: [
      "N-Hydroxy-7-(Naphthalen-2-Yl)-7-Oxoheptanamide",
      "CHEMBL320909",
      "SCHEMBL1520961",
      "AQLMJRZLPWGPGD-UHFFFAOYSA-N",
      "BDBM50114827",
      "N-Hydroxy-6-(2-Naphthoyl)Hexanamide",
      "7-Naphthalen-2-Yl-7-Oxo-Heptanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D03FFL",
    names: [
      "Chitosan-Thiomer",
      "Chitosan-N-Acetylcysteine",
      "Chitosan-Thiomer (Ophthalmic Liquid Formulation, Dry Eye)",
      "Chitosan-Thiomer (Ophthalmic Liquid Formulation, Dry Eye), Croma",
    ],
  },
  {
    primary_id: "D03FFY",
    names: ["AZD3355"],
  },
  {
    primary_id: "D03FGI",
    names: ["Anti-LAG3 MAb"],
  },
  {
    primary_id: "D03FGM",
    names: ["3-(2-Phenyl-2-(Piperazin-1-Yl)Ethyl)Benzamide", "CHEMBL379536"],
  },
  {
    primary_id: "D03FHV",
    names: ["MESUAGENIN A"],
  },
  {
    primary_id: "D03FIA",
    names: ["Rituxan Immunology"],
  },
  {
    primary_id: "D03FIE",
    names: [
      "AEZS-126",
      "AEZS-129",
      "AEZS-132",
      "D-106669",
      "D-87503",
      "Dual PI3K/ERK Kinase Inhibitors, AEterna Zentaris",
    ],
  },
  {
    primary_id: "D03FIL",
    names: ["Insulin Zinc Susp Recombinant Human"],
  },
  {
    primary_id: "D03FIS",
    names: ["EDP-23"],
  },
  {
    primary_id: "D03FJH",
    names: ["Z-Arg-Leu-Val-Agly-Ile-Val-Trp-NH2"],
  },
  {
    primary_id: "D03FJK",
    names: [
      "8-(1,3,3-Trimethyl-Butoxy)-Quinolin-2-Ylamine",
      "CHEMBL183159",
      "SCHEMBL5884151",
      "ZDVPLVAZUCNXKZ-UHFFFAOYSA-N",
      "BDBM50152425",
    ],
  },
  {
    primary_id: "D03FJM",
    names: [
      "2-Amino-5-(N-Nitro-Guanidino)-Pentanoic Acid",
      "CHEMBL353794",
      "N-Nitro-L-Arg",
      "KSC911C9L",
      "SCHEMBL5271958",
      "CTK8B1195",
      "BDBM50049252",
      "ANW-24471",
      "ZINC27740002",
    ],
  },
  {
    primary_id: "D03FJS",
    names: [
      "S-(-)-Enantiomer Of 3-(3-Hydroxyphenyl)-N-N-Propylpiperidine (3-PPP)",
      "AC1MT1CM",
      "S-(10,13-Dimethyl-17-Octyl-2,3,4,7,8,9,11,12,14,15,16,17-Dodecahydro-1H-Cyclopenta[a]phenanthren-3-Yl) 3-Methylpentanethioate",
    ],
  },
  {
    primary_id: "D03FKF",
    names: ["Repinotan"],
  },
  {
    primary_id: "D03FKQ",
    names: ["Ultrase"],
  },
  {
    primary_id: "D03FLC",
    names: [
      "Lamotrigine",
      "Crisomet",
      "Labileno",
      "Lamictal",
      "Lamictin",
      "Lamiktal",
      "Lamitor",
      "Lamotrigina",
      "Lamotriginum",
      "Desitin Brand Of Lamotrigine",
      "Faes Brand Of Lamotrigine",
      "Glaxo Wellcome Brand Of Lamotrigine",
      "GlaxoSmithKline Brand Of Lamotrigine",
      "Juste Brand Of Lamotrigine",
      "Lamictal Cd",
      "Lamictal ODT",
      "Lamictal XR",
      "Lamotrigina [Spanish]",
      "Lamotriginum [Latin]",
      "BW 430C",
      "GI 267119X",
      "GW 273293",
      "L 3791",
      "BW-430C",
      "EUR-1048",
      "Lamictal (TN)",
      "Lamictin (TN)",
      "Lamotrigine [USAN:INN:BAN]",
      "Lamotrigine (JAN/USAN/INN)",
      "3,5-Diamino-6-(2,3-Dichlorophenyl)-1,2,4-Triazine",
      "3,5-Diamino-6-(2,3-Dichlorophenyl)-As-Triazine",
      "6-(2,3-Dichlorophenyl)-1,2,4-Triazine-3,5-Diamine",
      "6-(2,3-Dichlorophenyl)-1,2,4-Triazine-3,5-Diyldiamine",
    ],
  },
  {
    primary_id: "D03FLT",
    names: ["CP-331684", "2-[4-[2-[2(R)-(6-Amino-3-Pyridinyl)-2-Hydroxyethylamino]ethoxy]phenyl]acetic Acid"],
  },
  {
    primary_id: "D03FNJ",
    names: [
      "Pipobroman",
      "Amedel",
      "Pipobromanum",
      "Vercyte",
      "A 1803",
      "A-8103",
      "Pipobroman [USAN:INN]",
      "Pipobromanum [INN-Latin]",
      "VERCYTE (TN)",
      "Pipobroman (JAN/USAN/INN)",
      "N,N'-Bis(3-Bromopropionyl)Piperazine",
      "N,N-Bis-(3-Bromopropionyl)-Piperazine",
      "1,4-Bis(3-Bromopropanoyl)Piperazine",
      "1,4-Bis(3-Bromopropionyl)Piperazine",
      "3-Bromo-1-[4-(3-Bromopropanoyl)Piperazin-1-Yl]propan-1-One",
    ],
  },
  {
    primary_id: "D03FNQ",
    names: ["ADS-103214", "CHEMBL216430", "SCHEMBL3831809", "BDBM50200793"],
  },
  {
    primary_id: "D03FOD",
    names: [
      "4-Tert-Butyl-N-Hydroxybenzamide",
      "62034-73-5",
      "CHEMBL249284",
      "Benzamide, 4-(1,1-Dimethylethyl)-N-Hydroxy-",
      "P-Tert-Butyl Benzohydroxamic Acid",
      "Oprea1_740446",
      "SCHEMBL5696343",
      "4-Tert-Butylbenzhydroxamic Acid",
      "4-Tert-Butylbenzohydroxamic Acid",
      "CTK2C8432",
      "DTXSID80613051",
      "BDBM50215019",
      "4-(Tert-Butyl )-Benzhydroxamic Acid",
      "ZINC19478595",
      "AKOS000181279",
    ],
  },
  {
    primary_id: "D03FOM",
    names: ["2-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL434585"],
  },
  {
    primary_id: "D03FON",
    names: [
      "Comvac5",
      "BioHib + Comvac4-HB, Bharat",
      "Pentavalent Vaccine (Haemophilus Influenza Type B (Hib), Diphtheria, Pertussis, Tetanus And Hepatitis B, Injectable), Bharat",
    ],
  },
  {
    primary_id: "D03FOP",
    names: ["AMG 319"],
  },
  {
    primary_id: "D03FOT",
    names: ["AMD-AAV7", "Adeno-Associated Virus Vector-7 Based Gene Therapy (Injectable, Wet AMD), RegenX Biosciences"],
  },
  {
    primary_id: "D03FPA",
    names: [
      "APP-018",
      "D-4F",
      "Apolipoprotein A1 Mimetic (Atherosclerosis), Bruin Pharma",
      "Apolipoprotein A1 Mimetic (Atherosclerosis),Novartis",
    ],
  },
  {
    primary_id: "D03FPH",
    names: [
      "2-(2-Bromo-Benzoyl)-Cyclohexane-1,3-Dione",
      "2-(2-Bromobenzoyl)Cyclohexane-1,3-Dione",
      "CHEMBL168906",
      "88562-08-7",
      "1,3-Cyclohexanedione, 2-(2-Bromobenzoyl)-",
      "ACMC-20lbdp",
      "CTK3A9588",
      "DTXSID70558700",
    ],
  },
  {
    primary_id: "D03FPQ",
    names: ["PNU-120596"],
  },
  {
    primary_id: "D03FQJ",
    names: ["1,1,1,3-Tetrafluoro-7-Phenylheptan-2-One"],
  },
  {
    primary_id: "D03FQP",
    names: ["ME4"],
  },
  {
    primary_id: "D03FQU",
    names: ["H-Tyr-Pro-Phe-Ala-NH2", "CHEMBL388265", "BDBM50209300"],
  },
  {
    primary_id: "D03FQY",
    names: ["PMID26815044-Compound-121"],
  },
  {
    primary_id: "D03FSF",
    names: ["PMID25666693-Compound-73"],
  },
  {
    primary_id: "D03FSU",
    names: [
      "Concatameric CTLA4Ig",
      "Concatameric CTLA4Ig (Immunoadhesin)",
      "Concatameric CD152Ig (Immunoadhesin), Medexgen",
      "Concatameric CTLA4Ig (Immunoadhesin), Medexgen",
    ],
  },
  {
    primary_id: "D03FTE",
    names: ["2-Dibenzofuran-4-Yl-6-Morpholin-4-Yl-Pyran-4-One", "CHEMBL373979", "SCHEMBL13113941"],
  },
  {
    primary_id: "D03FUG",
    names: ["Pegylated Carboxyhemoglobin"],
  },
  {
    primary_id: "D03FUO",
    names: ["GDC-0834"],
  },
  {
    primary_id: "D03FVI",
    names: ["97-139"],
  },
  {
    primary_id: "D03FVK",
    names: ["N6-Methoxy-2-[(4-Pyridinyl)Ethynyl]adenosine", "CHEMBL220899", "SCHEMBL4384730"],
  },
  {
    primary_id: "D03FVR",
    names: ["GSK-1827771"],
  },
  {
    primary_id: "D03FVS",
    names: ["Salvicine"],
  },
  {
    primary_id: "D03FXI",
    names: [
      "4-(4-Nitro-Benzylideneamino)-Benzenesulfonamide",
      "CHEMBL255175",
      "66667-60-5",
      "4-[(1E)-2-(4-Nitrophenyl)-1-Azavinyl]benzenesulfonamide",
      "AC1LBU0E",
      "4-[(4-Nitrophenyl)Methylideneamino]benzenesulfonamide",
      "SCHEMBL12416808",
      "CTK5I3043",
      "CTK1J4461",
      "DTXSID10340363",
      "IJEQJCKQAKELNC-OQLLNIDSSA-N",
      "MolPort-001-818-097",
      "ZINC131032",
      "BDBM50371746",
      "STK790732",
      "SBB006116",
      "AKOS001719928",
      "MCULE-1611993564",
      "MCULE-9098092341",
      "ST016439",
      "EU-0084260",
      "4-(4-Nitrobenzylideneamino)Benzenesulfonamide",
      "4-[(4-Nitrobenzylidene)Amino]benzenesulfonamide",
      "Benzenesulfonamide, 4-(4-Nitrobenzylidenamino)-",
    ],
  },
  {
    primary_id: "D03FXM",
    names: ["Protamine"],
  },
  {
    primary_id: "D03FZA",
    names: ["FUSIDIENOL"],
  },
  {
    primary_id: "D03FZG",
    names: ["LY-593039"],
  },
  {
    primary_id: "D03FZI",
    names: ["Biphenyl Carboxylic Acid Derivative 1"],
  },
  {
    primary_id: "D03FZK",
    names: ["(4-Bromo-Butyl)-Trimethyl-Ammonium"],
  },
  {
    primary_id: "D03FZT",
    names: ["JNJ-40411813"],
  },
  {
    primary_id: "D03GAI",
    names: ["IR-103"],
  },
  {
    primary_id: "D03GAN",
    names: [
      "A-558693",
      "A-705239",
      "A-705253",
      "Calpain Inhibitors (Neurological Disorders)",
      "Calpain Inhibitors, Knoll",
      "Calpain Inhibitors (Neurological Disorders), Abbott",
    ],
  },
  {
    primary_id: "D03GAQ",
    names: ["8-Methyl-1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One", "CHEMBL97922", "SCHEMBL10757167"],
  },
  {
    primary_id: "D03GAX",
    names: ["TAK-802"],
  },
  {
    primary_id: "D03GAY",
    names: ["H-409/22"],
  },
  {
    primary_id: "D03GBO",
    names: [
      "4,6-Dichloro-1H-Indole-2-Carboxylic Acid",
      "101861-63-6",
      "4,6-Dichloroindole-2-Carboxylic Acid",
      "CHEMBL301556",
      "1H-Indole-2-Carboxylicacid, 4,6-Dichloro-",
      "3-(4,6-Dichloro-2-Carboxyindol-3-Yl)Propionic Acid",
      "3-Dccip",
      "KBio2_007460",
      "PubChem7197",
      "Spectrum_001822",
      "SpecPlus_000800",
      "4,6-Dichloro-1H-Indole-2-Carboxylicacid",
      "ACMC-1BPUR",
      "AC1Q3MQM",
      "AC1L2SZE",
      "Maybridge1_006804",
      "AC1Q73TA",
      "Oprea1_268024",
      "KBioSS_002327",
      "SCHEMBL727389",
      "DivK1c_006896",
      "KBio2_004892",
      "KBio2_002324",
      "KBio1_001840",
      "CTK0H4782",
      "HMS560N06",
      "DTXSID80144299",
      "MolPort-000-147-351",
      "Mdl-29951",
    ],
  },
  {
    primary_id: "D03GCJ",
    names: [
      "Carteolol",
      "Carteololum",
      "Carteolol HCl",
      "Carteolol Monohydrochloride",
      "Arteolol (TN)",
      "Arteoptic (TN)",
      "Calte (TN)",
      "Carteol (TN)",
      "Carteolol (INN)",
      "Carteolol [INN:BAN]",
      "Carteololum [INN-Latin]",
      "Cartrol (TN)",
      "Elebloc (TN)",
      "Endak (TN)",
      "Glauteolol (TN)",
      "Mikelan (TN)",
      "Ocupress (TN)",
      "Poenglaucol (TN)",
      "Singlauc (TN)",
      "Teoptic (TN)",
      "5-({3-[(1,1-Dimethylethyl)Amino]-2-Hydroxypropyl}oxy)-3,4-Dihydroquinolin-2(1H)-One",
      "5-[3-(Tert-Butylamino)-2-Hydroxypropoxy]-3,4-Dihydro-1H-Quinolin-2-One",
      "5-[3-(Tert-Butylamino)-2-Hydroxypropoxy]-3,4-Dihydroquinolin-2(1H)-One",
    ],
  },
  {
    primary_id: "D03GCN",
    names: [
      "L-709780",
      "CHEMBL32960",
      "BDBM50078448",
      "L709780",
      "N-[2-[2-(4-Piperidinyl)Ethyl]-3-Oxoisoindoline-5-Ylcarbonyl]-Beta-Alanine",
      "3-{[3-Oxo-2-(2-Piperidin-4-Yl-Ethyl)-2,3-Dihydro-1H-Isoindole-5-Carbonyl]-Amino}-Propionic Acid(L-709780)",
      "3-{[3-Oxo-2-(2-Piperidin-4-Yl-Ethyl)-2,3-Dihydro-1H-Isoindole-5-Carbonyl]-Amino}-Propionic Acid",
    ],
  },
  {
    primary_id: "D03GCR",
    names: ["MK-8742"],
  },
  {
    primary_id: "D03GDC",
    names: ["Pyrrolo-Pyridine Derivative 1"],
  },
  {
    primary_id: "D03GDP",
    names: ["AM-1715", "CHEMBL235585", "BDBM50228074"],
  },
  {
    primary_id: "D03GES",
    names: ["1-Benzyl-4-(3-Oxazol-5-Yl-Pyrrol-1-Yl)-Piperidine", "CHEMBL107529", "BDBM50082170"],
  },
  {
    primary_id: "D03GET",
    names: ["Menadione"],
  },
  {
    primary_id: "D03GFB",
    names: ["Spirolaxine Derivative 1"],
  },
  {
    primary_id: "D03GFC",
    names: [
      "AZD-7545",
      "AZD7545",
      "252017-04-2",
      "4-[(3-Chloro-4-{[(2r)-3,3,3-Trifluoro-2-Hydroxy-2-Methylpropanoyl]amino}phenyl)Sulfonyl]-N,N-Dimethylbenzamide",
      "4-{3-Chloro-4-[(2R)-3,3,3-Trifluoro-2-Hydroxy-2-Methylpropanamido]benzenesulfonyl}-N,N-Dimethylbenzamide",
      "DTDZLJHKVNTQGZ-GOSISDBHSA-N",
      "AZD 7545",
      "2q8g",
      "C19H18ClF3N2O5S",
      "GTPL9362",
      "SCHEMBL6350247",
      "AOB6866",
      "MolPort-035-395-876",
      "ZINC1547088",
      "EX-A1208",
      "AZD-7547",
      "BCP07524",
      "KS-00000RM2",
      "S7517",
      "2244AH",
      "AZD 7545;AZD-7545",
      "AKOS027326647",
      "DB07403",
      "CS-1740",
      "NCGC00250391-07",
      "NCGC00250391-01",
      "HY-16082",
    ],
  },
  {
    primary_id: "D03GFZ",
    names: ["Thieno[3,2-C]pyridine-7-Carboxamide Derivative 2"],
  },
  {
    primary_id: "D03GGN",
    names: ["RO-09-4889"],
  },
  {
    primary_id: "D03GHN",
    names: ["3-Hydroxy Capric Acid", "3-OH-C10", "3-Hydroxy-Decanoic Acid"],
  },
  {
    primary_id: "D03GHR",
    names: ["C-Myb"],
  },
  {
    primary_id: "D03GHV",
    names: ["PR1 Peptide Antigen Vaccine"],
  },
  {
    primary_id: "D03GII",
    names: ["SR-26831"],
  },
  {
    primary_id: "D03GIV",
    names: ["SNP-001"],
  },
  {
    primary_id: "D03GJB",
    names: ["Pteroic Acid"],
  },
  {
    primary_id: "D03GKP",
    names: ["FD-891"],
  },
  {
    primary_id: "D03GLK",
    names: ["Imidazole Derivative 1"],
  },
  {
    primary_id: "D03GLL",
    names: ["TSS-HIG"],
  },
  {
    primary_id: "D03GLM",
    names: ["BMS-210285"],
  },
  {
    primary_id: "D03GNI",
    names: ["ISOQUINE"],
  },
  {
    primary_id: "D03GNM",
    names: ["AdcAhxArg4Lys(Biotin)-PEG-OMe", "CHEMBL413173"],
  },
  {
    primary_id: "D03GNR",
    names: ["ANA-975"],
  },
  {
    primary_id: "D03GOI",
    names: ["Liraglutide"],
  },
  {
    primary_id: "D03GON",
    names: [
      "2-[4-(2-Azepan-1-Ylethoxy)Phenoxy]benzooxazole",
      "Benzoxazole Compound, 10c",
      "SCHEMBL2168424",
      "CHEMBL482254",
      "BDBM24206",
      "ZINC40919149",
    ],
  },
  {
    primary_id: "D03GOO",
    names: ["Fumaric Acid"],
  },
  {
    primary_id: "D03GOP",
    names: ["Imvamune Smallpox Vaccine"],
  },
  {
    primary_id: "D03GPH",
    names: ["1-(4-Chloro-Phenyl)-3-(3-Pentyl-Oct-2-Enyl)-Urea", "CHEMBL422036", "BDBM50005450"],
  },
  {
    primary_id: "D03GPK",
    names: ["DA-9801"],
  },
  {
    primary_id: "D03GPP",
    names: ["Inhaled Xenon Gas"],
  },
  {
    primary_id: "D03GPV",
    names: ["Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D03GPW",
    names: ["Benzodihydroisofuran Derivative 1"],
  },
  {
    primary_id: "D03GQC",
    names: ["PD-117596"],
  },
  {
    primary_id: "D03GQF",
    names: ["Manzamine E"],
  },
  {
    primary_id: "D03GQH",
    names: [
      "B-581",
      "149759-96-6",
      "B581",
      "FTase Inhibitor I",
      "B 581",
      "CHEMBL91722",
      "CHEBI:83620",
      "N-[2(S)-(2(R)-2-Amino-3-Mercaptopropylamino)-3-Methylbutyl]-L-Phenylalanyl-L-Methionine Trifluoroacetate Salt",
      "(S)-2-((S)-2-((S)-2-((R)-2-Amino-3-Mercaptopropylamino)-3-Methylbutylamino)-3-Phenylpropanamido)-4-(Methylthio)Butanoic Acid",
      "CBiol_001875",
      "H-Cys-Psi(CH2NH)Val-Psi(CH2NH)Phe-Met-OH",
      "AC1L31RF",
      "BSPBio_001466",
      "KBioSS_000186",
      "KBioGR_000186",
      "SCHEMBL2723960",
      "KBio3_000372",
      "KBio2_005322",
      "KBio2_000186",
      "KBio3_000371",
      "KBio2_002754",
      "CTK8E9094",
    ],
  },
  {
    primary_id: "D03GQI",
    names: [
      "BAS-00387347",
      "CHEMBL209617",
      "AC1LKSRL",
      "4-Benzylidene-1-(4-Iodophenyl)Pyrazolidine-3,5-Dione",
      "SCHEMBL5735322",
      "ZINC726017",
      "BDBM50187575",
      "AKOS000438122",
      "AG-690/10031054",
      "4-Benzylidene-1-(4-Iodophenyl)-3,5-Pyrazolidinedione",
    ],
  },
  {
    primary_id: "D03GRA",
    names: [
      "(R)-Mevalonate",
      "Mevalonate",
      "Mevalonic Acid",
      "(3R)-3,5-Dihydroxy-3-Methylpentanoate",
      "AC1NRC4P",
      "GTPL3042",
      "CHEBI:36464",
      "CTK4B1152",
      "DTXSID50415347",
    ],
  },
  {
    primary_id: "D03GRD",
    names: ["AX-200"],
  },
  {
    primary_id: "D03GSK",
    names: ["Carbamide Derivative 7"],
  },
  {
    primary_id: "D03GSN",
    names: ["KVD001"],
  },
  {
    primary_id: "D03GTO",
    names: ["C-Met/PD-L1 CAR-T Cell"],
  },
  {
    primary_id: "D03GVC",
    names: [
      "Methyclothiazide",
      "Aquaresen",
      "Aquatensen",
      "Duretic",
      "Enduron",
      "Enduronum",
      "METHYLCLOTHIAZIDE",
      "Methychlothiazide",
      "Methyclothiazid",
      "Methyclothiazidum;Methycyclothiazide",
      "Methylchlorothiazide",
      "Methylcyclothiazide",
      "Meticlotiazida",
      "Meticlotiazide",
      "Naturon",
      "Meticlotiazide [DCIT]",
      "Ciba 7272-Su",
      "Enduron (TN)",
      "Methyclothiazidum [INN-Latin]",
      "Meticlotiazida [INN-Spanish]",
      "Naturon (VAN)",
      "Methyclothiazide (JAN/USP/INN)",
      "Methyclothiazide [USAN:INN:BAN:JAN]",
      "(+-)-6-Chloro-3-(Chloromethyl)-3,4-Dihydro-2-Methyl-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3-(Chloromethyl)-3,4-Dihydro-,1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3-(Chloromethyl)-3,4-Dihydro-2-Methyl-, 1,1-Dioxid;2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3-(Chloromethyl)-3,4-Dihydro-2-Methyl-, 1,1-Dioxide",
      "6-Chloro-3-(Chloromethyl)-2-Methyl-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3-(Chloromethyl)-3,4-Dihydro-2-Methyl-2H-1,2, 4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3-(Chloromethyl)-3,4-Dihydro-2-Methyl-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3-Chloromethyl-2-Methyl-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-Chloro-3-(Chloromethyl)-2-Methyl-1,1-Dioxo-3,4-Dihydro-1",
    ],
  },
  {
    primary_id: "D03GVG",
    names: ["NP-2"],
  },
  {
    primary_id: "D03GVL",
    names: [
      "6-Aza-Uridine Monophosphate",
      "6-Aza-Ump",
      "6-Azauridine-5'-Monophosphate",
      "6-Aza Uridine 5'-Monophosphate",
      "1los",
      "UP6",
      "[(2R,3S,4R,5R)-5-(3,5-Dioxo-1,2,4-Triazin-2-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Dihydrogen Phosphate",
      "Aza UMP",
      "6-Azauridine-5'-Phosphate",
      "6-Azauridine 5'M",
      "NSC47549",
      "6-Azauridine 5'MP",
      "6-Azauridine 5'-Phosphate",
      "AC1L45DF",
      "C6-Uridine Derivative, 18",
      "6-Azauridine-5-Monophosphate",
      "CHEMBL463480",
      "SCHEMBL3214622",
      "BDBM21340",
      "6-Azauridine 5'-Phosphoric Acid",
      "ZINC12503861",
      "AKOS027381721",
      "DB03718",
      "W-201761",
    ],
  },
  {
    primary_id: "D03GWA",
    names: ["NSC-669977"],
  },
  {
    primary_id: "D03GWV",
    names: ["ZP-002"],
  },
  {
    primary_id: "D03GWY",
    names: [
      "Ciglitazone",
      "74772-77-3",
      "Ciglitizone",
      "ADD-3878",
      "Ciglitazonum",
      "Ciglitazona",
      "Ciglitazonum [Latin]",
      "Ciglitazona [Spanish]",
      "Ciglitazone [USAN:INN]",
      "ADD 3878",
      "CHEBI:64227",
      "U-63287",
      "(+-)-5-(P-((1-Methylcyclohexyl)Methoxy)Benzyl)-2,4-Thiazolidinedione",
      "C18H23NO3S",
      "5-(4-((1-Methylcyclohexyl)Methoxy)Benzyl)Thiazolidine-2,4-Dione",
      "YZFWTZACSRHJQD-UHFFFAOYSA-N",
      "(+/-)-5-[4-(1-Methylcyclohexylmethoxy)Benzyl]thiazolidine-2,4-Dione",
      "U 63287",
      "5-{4-[(1-Methylcyclohexyl)Methoxy]benzyl}-1,3-Thiazolidine-2,4-Dione",
      "NCGC00164446-01",
    ],
  },
  {
    primary_id: "D03GXQ",
    names: ["Iodoacetamide"],
  },
  {
    primary_id: "D03GXU",
    names: ["WIN-901"],
  },
  {
    primary_id: "D03GYK",
    names: ["Laropiprant+niacin"],
  },
  {
    primary_id: "D03GYS",
    names: ["7-Butyl-2-(4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D03HAA",
    names: ["PMID30107136-Compound-Example45"],
  },
  {
    primary_id: "D03HAW",
    names: ["HemaMax"],
  },
  {
    primary_id: "D03HBG",
    names: ["2-(3''-(4''-Bromo-Indolyl)Ethyloxy)Adenosine", "CHEMBL434377", "BDBM50208797"],
  },
  {
    primary_id: "D03HBZ",
    names: ["(3-Ethyl-Indol-1-Yl)-Propyl-Pyridin-4-Yl-Amine", "CHEMBL154228", "SCHEMBL7616000", "BDBM50048611"],
  },
  {
    primary_id: "D03HCR",
    names: ["(4S,5S)-4,5-Dipropyl-Oxazolidin-(2Z)-Ylideneamine", "CHEMBL110498", "BDBM50138199"],
  },
  {
    primary_id: "D03HCT",
    names: ["KKHT-10608"],
  },
  {
    primary_id: "D03HCZ",
    names: [
      "Propoxyphene Hydrochloride",
      "Darvon",
      "Dolene",
      "Kesso-Gesic",
      "Prophene 65",
      "Propoxyphene Hydrochloride 65",
      "Darvon-N",
      "Propoxyphene Napsylate",
    ],
  },
  {
    primary_id: "D03HDL",
    names: ["MK 8719"],
  },
  {
    primary_id: "D03HDP",
    names: ["ACEA-1416"],
  },
  {
    primary_id: "D03HDS",
    names: [
      "C4-MAD",
      "AC1O51QQ",
      "CHEMBL1170847",
      "[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxy-Tetrahydrofuran-2-Yl]methoxy-[[hydroxy-[(E)-4-(4-Hydroxy-6-Methoxy-7-Methyl-3-Oxo-1H-Isobenzofuran-5-Yl)-2-Methyl-But-2-Enoxy]phosphoryl]methyl]phosphinic Acid",
      "9H-Purin-6-Amine, 9-[5-O-[[[[[(2E)-4-(1,3-Dihydro-4-Hydroxy-6-Methoxy-7-Methyl-3-Oxo-5-Isobenzofuranyl)-2-Methyl-2-Butenyl]oxy]hydroxyphosphinyl]methyl]hydroxyphosphinyl]pentofuranosyl]-",
    ],
  },
  {
    primary_id: "D03HDV",
    names: ["DWP-431"],
  },
  {
    primary_id: "D03HED",
    names: ["Palbociclib/Ribociclib Analog 1"],
  },
  {
    primary_id: "D03HEK",
    names: ["PMID25991433-Compound-A10"],
  },
  {
    primary_id: "D03HEP",
    names: ["Gold-Complexed Thiosaccharide Derivative 2"],
  },
  {
    primary_id: "D03HFG",
    names: [
      "Fluvoxamine",
      "Dumyrox",
      "Fluvoxamina",
      "Fluvoxaminum",
      "Fluvoxamina [INN-Spanish]",
      "Fluvoxamine (INN)",
      "Fluvoxamine [INN:BAN]",
      "Fluvoxamine-CR",
      "Fluvoxaminum [INN-Latin]",
      "Luvox (TN)",
      "(1E)-5-Methoxy-1-[4-(Trifluoromethyl)Phenyl]pentan-1-One O-(2-Aminoethyl)Oxime",
      "2-[(E)-[5-Methoxy-1-[4-(Trifluoromethyl)Phenyl]pentylidene]amino]oxyethanamine",
      "2-[({5-Methoxy-1-[4-(Trifluoromethyl)Phenyl]pentylidene}amino)Oxy]ethanamine",
    ],
  },
  {
    primary_id: "D03HFH",
    names: ["1-(4-Benzylsulfanyl-Phenyl)-Propylamine", "CHEMBL1079057", "BDBM50310833"],
  },
  {
    primary_id: "D03HFM",
    names: ["(1R,2R)-N-Arachidonoylcyclopropanolamide"],
  },
  {
    primary_id: "D03HGN",
    names: ["Aurora A Kinase Inhibitors (Cancer), Evotec"],
  },
  {
    primary_id: "D03HGP",
    names: ["CTP-Conjugated WT1 Peptide", "CTP-Conjugated WT1 Peptide (Cancer)"],
  },
  {
    primary_id: "D03HGW",
    names: ["AV-370", "Anti-FGF3 Receptor Antibody (Cancer), AVEO Pharmaceuticals"],
  },
  {
    primary_id: "D03HHD",
    names: ["Pyrazoline Derivative 4"],
  },
  {
    primary_id: "D03HHG",
    names: ["PMID26651364-Compound-121"],
  },
  {
    primary_id: "D03HHJ",
    names: ["GSK3174998"],
  },
  {
    primary_id: "D03HHN",
    names: ["PMID29053063-Compound-7b"],
  },
  {
    primary_id: "D03HHP",
    names: [
      "2,3-Didehydroalanine",
      "Dehydroalanine",
      "2-Aminoacrylic Acid",
      "2-Aminoacrylate",
      "2,3-DIDEHYDROALANINE",
      "1948-56-7",
      "Alpha,beta-Dehydroalanine",
      "Alpha-Aminoacrylate",
      "2-Aminoprop-2-Enoic Acid",
      "2-Aminoprop-2-Enoate",
      "UNII-98RA387EKY",
      "2-AMINO-ACRYLIC ACID",
      "98RA387EKY",
      "A-B-Di-Dehydroalanine",
      "2-Ammonioprop-2-Enoate",
      "AC1L3XOY",
      "Alpha-Beta-Di-Dehydroalanine",
      "2-Propenoic Acid,2-Amino-",
      "2-Propenoic Acid, 2-Amino-",
      "CHEBI:76565",
      "CHEBI:17123",
      "CTK4E1639",
      "DTXSID90173131",
      "UQBOJOOOTLPNST-UHFFFAOYSA-N",
      "(Alpha)-(Beta)-Di-Dehydroalanine",
      "Anhydroserine 2-Aminopropenoic Acid",
    ],
  },
  {
    primary_id: "D03HIG",
    names: ["AVX-470", "Anti-TNF Antibody (Oral, IBD), Avaxia Biologics"],
  },
  {
    primary_id: "D03HJK",
    names: [
      "Zithromax",
      "Azithromycin",
      "83905-01-5",
      "Azithromycinum",
      "Azithromycine",
      "Sumamed",
      "Zitromax",
      "Zmax",
      "Hemomycin",
      "Azitrocin",
      "Azasite",
      "Azenil",
      "Aritromicina",
      "Zitrotek",
      "Zithrax",
      "Mixoterin",
      "Setron",
      "Aziwok",
      "Zitrim",
      "Aztrin",
      "Zifin",
      "Tobil",
      "Zmas",
      "Zeto",
      "Azithromycinum [Latin]",
      "Azithromycine [French]",
      "Zithromax IV",
      "AZITHROMYCIN DIHYDRATE",
      "Misultina",
      "Azitromax",
      "Z-Pak",
      "Tromix",
      "Aritromicina [Spanish]",
      "Azitromicina",
      "CP-62993",
      "UNII-J2KLZ20U1M",
      "DRG-0104",
      "CCRIS 1961",
      "HSDB 7205",
      "Azithromycin (Anhydrous)",
      "C38H72N2O12",
      "BRN 5387583",
      "J2KLZ20U1M",
      "Azythromycin",
      "CHEBI:2955",
    ],
  },
  {
    primary_id: "D03HJW",
    names: ["GSK1016790A"],
  },
  {
    primary_id: "D03HJZ",
    names: ["AZ-11657312"],
  },
  {
    primary_id: "D03HKA",
    names: ["ZFP TF"],
  },
  {
    primary_id: "D03HKN",
    names: ["PMID27537201-Compound-Figure13b"],
  },
  {
    primary_id: "D03HKR",
    names: ["CDX-0158"],
  },
  {
    primary_id: "D03HKV",
    names: ["Metanephrine"],
  },
  {
    primary_id: "D03HKX",
    names: ["TNF-Alpha Production Inhibitors"],
  },
  {
    primary_id: "D03HLH",
    names: ["4-(3-Bromo-Phenoxy)-6,7-Dimethoxy-Quinazoline", "CHEMBL98798"],
  },
  {
    primary_id: "D03HLK",
    names: ["PRT-060096"],
  },
  {
    primary_id: "D03HLU",
    names: [
      "Phosphoric Acid Mono-((E)-Dec-4-Enyl) Ester",
      "CHEMBL188081",
      "SCHEMBL193089",
      "4-Decen-1-Ol, Dihydrogen Phosphate",
      "BDBM50170856",
    ],
  },
  {
    primary_id: "D03HML",
    names: ["CD56-Specific Gene-Engineered T Cells"],
  },
  {
    primary_id: "D03HMM",
    names: ["ABS-201", "GTPL5308", "ABS201", "CHEMBL1170625", "ABS 201"],
  },
  {
    primary_id: "D03HMW",
    names: ["Pyrimidine Derivative 20"],
  },
  {
    primary_id: "D03HNC",
    names: ["N-Hydroxy-4-(Naphthalen-1-Yl)Benzamide"],
  },
  {
    primary_id: "D03HNU",
    names: ["Hydrazide Derivative 5"],
  },
  {
    primary_id: "D03HOP",
    names: ["HG-1220"],
  },
  {
    primary_id: "D03HOT",
    names: ["BC-19"],
  },
  {
    primary_id: "D03HPW",
    names: ["Indoline Derivative 16"],
  },
  {
    primary_id: "D03HQE",
    names: ["CRT0066101"],
  },
  {
    primary_id: "D03HQF",
    names: ["2-Aminomethyl-5-(P-Bromophenyl)Tetrahydrofuran", "CHEMBL461268", "SCHEMBL2689197"],
  },
  {
    primary_id: "D03HQK",
    names: [
      "2-(5-Phenyl-Oxazol-2-Ylamino)-Benzonitrile",
      "2-[(5-Phenyl-1,3-Oxazol-2-Yl)Amino]benzonitrile",
      "2-Anilino-5-Aryloxazole 8",
      "AC1NS7QZ",
      "CHEMBL193912",
      "BDBM5847",
      "N-(2-Cyanophenyl)-5-Phenyl-1,3-Oxazol-2-Amine Hydrochloride",
    ],
  },
  {
    primary_id: "D03HQR",
    names: ["SUN-44"],
  },
  {
    primary_id: "D03HSA",
    names: ["Actimab-A"],
  },
  {
    primary_id: "D03HSF",
    names: ["Ro-23-7777"],
  },
  {
    primary_id: "D03HSN",
    names: ["CNTO-1959"],
  },
  {
    primary_id: "D03HSQ",
    names: ["GBR-401", "Anti-CD19 MAb (Leukemia/Lymphoma/Inflammation), Glenmark"],
  },
  {
    primary_id: "D03HSS",
    names: ["LG-1041"],
  },
  {
    primary_id: "D03HTI",
    names: [
      "HMR-3787",
      "11-Deoxy-3-Des(Hexopyranosyloxy)-2-Fluoro-11-[4-(3H-Imidazo[4,5-B]pyridin-3-Yl)Butylamino]-6-O-Methyl-3-Oxoerythromycin A 11-N,12-O-Cyclic Carbamate",
    ],
  },
  {
    primary_id: "D03HTT",
    names: ["4-(Isoquinolin-5-Yl)-N-O-Tolylphthalazin-1-Amine", "CHEMBL204220", "BDBM50179895"],
  },
  {
    primary_id: "D03HTZ",
    names: ["LD201t1"],
  },
  {
    primary_id: "D03HUX",
    names: ["PCM-075"],
  },
  {
    primary_id: "D03HVE",
    names: ["Hypoxanthine"],
  },
  {
    primary_id: "D03HVN",
    names: ["P-1"],
  },
  {
    primary_id: "D03HVU",
    names: ["ISIS 101987"],
  },
  {
    primary_id: "D03HVV",
    names: ["Efaproxyn", "Efaproxiral", "RSR13"],
  },
  {
    primary_id: "D03HXD",
    names: ["(6-Benzyloxy-2-Naphthyl)-2-Aminopropane", "CHEMBL456006"],
  },
  {
    primary_id: "D03HXN",
    names: ["3,6-Bis(Dmt-Tic-NH-Methyl)-2(1H)-Pyrazinone", "CHEMBL200890"],
  },
  {
    primary_id: "D03HXO",
    names: ["NPS-1"],
  },
  {
    primary_id: "D03HYH",
    names: ["Chondroprotective Agents", "Chondroprotective Agents (Cartilage Disorders)"],
  },
  {
    primary_id: "D03HYQ",
    names: ["Ranolazine"],
  },
  {
    primary_id: "D03HYX",
    names: ["Mometasone"],
  },
  {
    primary_id: "D03HYZ",
    names: ["N-Propyl-ETAV"],
  },
  {
    primary_id: "D03HZT",
    names: [
      "1,2-Bis-(2-Chloro-Phenyl)-Ethane-1,2-Dione",
      "2,2'-Dichlorobenzil",
      "21854-95-5",
      "1,2-Bis(2-Chlorophenyl)Ethane-1,2-Dione",
      "2,2'-Dichlorodibenzoyl",
      "Ethanedione, Bis(2-Chlorophenyl)-",
      "Benzil, 2,2'-Dichloro-",
      "MFCD00018263",
      "1,2-Ethanedione, 1,2-Bis(2-Chlorophenyl)-",
      "AE-562/43458948",
      "NSC114829",
      "Benzil,2'-Dichloro-",
      "ACMC-20aok0",
      "AC1Q5FHJ",
      "Benzil-Based Compound, 10",
      "Bis(2-Chlorophenyl) Diketone",
      "2,2'-Dichlorobenzil, 97%",
      "SCHEMBL2156567",
      "CHEMBL365392",
      "AC1L3H37",
      "DTXSID1066736",
      "CTK4E7789",
      "BDBM22732",
      "VOSNNSVWVJFJCR-UHFFFAOYSA-N",
      "MolPort-003-915-120",
      "ZINC1704748",
      "Benzil, 2,2'-Dichloro- (8CI)",
    ],
  },
  {
    primary_id: "D03IAB",
    names: ["Pyrazole Derivative 68"],
  },
  {
    primary_id: "D03IBI",
    names: ["Levotofisopam"],
  },
  {
    primary_id: "D03ICO",
    names: ["AR-709", "AR-103", "AR-103 Program, Arpida"],
  },
  {
    primary_id: "D03ICS",
    names: ["NPT-500"],
  },
  {
    primary_id: "D03IDK",
    names: [
      "CEP-18050",
      "ALK Inhibitors (Anaplastic Large Cell Lymphoma)",
      "ALK Inhibitors (Anaplastic Large Cell Lymphoma), Cephalon",
      "Alk Inhibitors (Cancer), Cephalon",
    ],
  },
  {
    primary_id: "D03IDU",
    names: ["Hydrogen Peroxide"],
  },
  {
    primary_id: "D03IDX",
    names: ["WA-8242-A1", "171272-08-5", "204272-98-0", "204273-00-7", "WA-8242-A2", "WA-8242-B"],
  },
  {
    primary_id: "D03IEA",
    names: ["Virion Project"],
  },
  {
    primary_id: "D03IEB",
    names: ["Penecure"],
  },
  {
    primary_id: "D03IES",
    names: [
      "CyPPTS",
      "CHEMBL321968",
      "SCHEMBL6733000",
      "GTPL6257",
      "BDBM50147156",
      "2,2,2-Trifluoro-Ethanesulfonic Acid (3-Cyclopentyloxy-Phenyl)-Pyridin-3-Ylmethyl-Amide",
      "N-[3-(Cyclopentyloxy)Phenyl]-2,2,2-Trifluoro-N-(Pyridin-3-Ylmethyl)Ethane-1-Sulfonamide",
    ],
  },
  {
    primary_id: "D03IGB",
    names: ["BVT.28949"],
  },
  {
    primary_id: "D03IGH",
    names: [
      "Darunavir",
      "206361-99-1",
      "TMC114",
      "Prezista",
      "TMC-114",
      "UIC-94017",
      "Darunavirum",
      "TMC 114",
      "Darunavirum [INN-Latin]",
      "UNII-YO603Y8113",
      "AIDS073035",
      "CHEMBL1323",
      "(3R,3AS,6AR)-HEXAHYDROFURO[2,3-B]FURAN-3-YL(1S,2R)-3-[[(4-AMINOPHENYL)SULFONYL](ISOBUTYL)AMINO]-1-BENZYL-2-HYDROXYPROPYLCARBAMATE",
      "CHEBI:367163",
      "Darunavir-D9",
      "YO603Y8113",
      "(3R,3aS,6aR)-Hexahydrofuro[2,3-B]furan-3-Yl ((2S,3R)-4-(4-Amino-N-Isobutylphenylsulfonamido)-3-Hydroxy-1-Phenylbutan-2-Yl)Carbamate",
      "NCGC00168773-01",
      "(3R,3aS,6aR)-Hexahydrofuro[2,3-B]furan-3-Yl",
      "Darunavir [USAN]",
      "UIC 94017",
      "Prezista(TM)",
      "UIC-96017",
      "Darunavir (USAN/INN)",
      "Carbamic Acid, [(1S,2R)-3-[[(4-Aminophenyl)Sulfonyl](2-Methylpropyl)Amino]-2-Hydroxy-1-(Phenylmethyl)Propyl]-, (3R,3aS,6aR)-Hexahydrofuro[2,3-B]furan-3-Yl Ester",
      "{(1S,2R)-3-[(4-Amino-Benzenesulfonyl)-Isobutyl-Amino]-1-Benzyl-2-Hydroxy-Propyl}-Carbamic Acid (3R,3aS,6aR)-(Hexahydro-Furo[2,3-B]furan-3-Yl) Ester",
      "[(S)-3-[(4-Amino-Benzenesulfonyl)-Isobutyl-Amino]-2-Hydroxy-1-((R)-Phenylmethyl)-Propyl]-Carbamic Acid (3R,3aS,6aR)-(Hexahydro-Furo[2,3-B]furan-3-Yl) Ester",
      "N-((1S,2R)-3-(((4-Aminophenyl)Sulfonyl)(2-Methylpropyl)Amino)-2-Hydroxy-1-Benzylpropyl)((1S,2R,5R)-4,6-Dioxabicyclo(330)Oct-2-Yloxy)Carboxamide",
      "[(3aS,4R,6aR)-2,3,3a,4,5,6a-Hexahydrofuro[2,3-B]furan-4-Yl] N-[(2S,3R)-4-[(4-Aminophenyl)Sulfonyl-(2-Methylpropyl)Amino]-3-Hydroxy-1-Phenylbutan-2-Yl]carbamate",
      "(3-((4-Aminobenzenesulfonyl)Isobutylamino)-1-Benzyl-2-Hydroxypropyl)Carbamic Acid Hexahydrofuro(2,3-B)Furan-3-Yl Ester",
      "(3R,3aS,6aR)-Hexahydrofuro(2,3-B)Furan-3-Yl N-((1S,2R)-1-Benzyl-2-Hydroxy-3-(N1-Isobutylsulfanilamido)Propyl)Carbamate",
      "(3R,3aS,6aR)-Hexahydrofuro[2,3-B]furan-3-Yl N-((1S,2R)-1-Benzyl-2-Hydroxy-3-(N(1)-Isobutylsulfanilamido)Propyl)Carbamate",
      "(3R,3aS,6aR)-Hexahydrofuro[2,3-B]furan-3-Yl [(2S,3R)-4-{[(4-Aminophenyl)Sulfonyl](2-Methylpropyl)Amino}-3-Hydroxy-1-Phenylbutan-2-Yl]carbamate",
      "(3R,3aS,6aR)-Tetrahydro-2H-Furo[2,3-B]furan-3-Yl (2S,3R)-4-(4-Amino-N-Isobutylphenylsulfonamido)-3-Hydroxy-1-Phenylbutan-2-Ylcarbamate",
      "(3R,3aS,6aR)-Tetrahydro-2H-Furo[2,3-B]furan-3-Yl (2S,3R)-4-(4-Amino-N-Neopentylphenylsulfonamido)-3-Hydroxy-1-Phenylbutan-2-Ylcarbamate",
    ],
  },
  {
    primary_id: "D03IHA",
    names: ["GT 1061"],
  },
  {
    primary_id: "D03IHD",
    names: ["Anti-CD22-CAR-Transduced T Cells"],
  },
  {
    primary_id: "D03IIP",
    names: ["EP-3533"],
  },
  {
    primary_id: "D03IJO",
    names: ["GS-9820"],
  },
  {
    primary_id: "D03IJZ",
    names: ["Recombinant Botulinum Toxin"],
  },
  {
    primary_id: "D03IKT",
    names: [
      "Paramethasone",
      "Cassenne",
      "Cortiden",
      "Dillar",
      "Flumethone",
      "Metilar",
      "Parametasona",
      "Parametasone",
      "Paramethasonum",
      "Paramezone",
      "Parametasone [DCIT]",
      "CS 1483",
      "Dillar (TN)",
      "Parametasona [INN-Spanish]",
      "Paramethasone (INN)",
      "Paramethasone [INN:BAN]",
      "Paramethasonum [INN-Latin]",
      "(6S,8S,9S,10R,11S,13S,14S,16R,17R)-6-Fluoro-11,17-Dihydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-7,8,9,11,12,14,15,16-Octahydro-6H-Cyclopenta[a]phenanthren-3-One",
      "(6alpha,11beta,16alpha)-6-Fluoro-11,17,21-Trihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
      "16alpha-Methyl-6alpha-Fluoroprednisolone",
      "6alpha-Fluoro-16alpha-Methylprednisolone",
    ],
  },
  {
    primary_id: "D03IKW",
    names: ["PMID25772215-Compound-WO2012177618M4"],
  },
  {
    primary_id: "D03IKZ",
    names: ["HBP-347"],
  },
  {
    primary_id: "D03ILU",
    names: [
      "D17.4",
      "26482-53-1",
      "2-(1-Adamantyl)Ethanamine",
      "2-(Adamantan-1-Yl)Ethanamine",
      "1-C-Ethylaminoadamantane",
      "Tricyclo[3.3.1.13,7]decane-1-Ethanamine",
      "1-Adamantanethylamine",
      "1-Adamantaneethanamine",
      "2-Adamantan-1-Yl-Ethylamine",
      "2-Adamantanylethylamine",
      "Tricyclo(3.3.1.13,7)Decane-1-Ethanamine",
      "PubChem21555",
      "Enamine_005504",
      "D-174",
      "2-(1-Adamantyl)Ethylamine",
      "AC1L20RI",
      "2-(2-Amino)Ethyladamantane",
      "Oprea1_362282",
      "2-(Adamant-1-Yl)Ethylamine",
      "SCHEMBL973650",
      "CHEMBL341013",
      "Mrz-2/174",
      "CTK1A4494",
      "DTXSID50181075",
      "MolPort-002-464-537",
    ],
  },
  {
    primary_id: "D03IMJ",
    names: ["PMID29649907-Compound-20"],
  },
  {
    primary_id: "D03IMU",
    names: ["TNFR1 NAM"],
  },
  {
    primary_id: "D03IMY",
    names: [
      "6-Chloro-5-Heptylpyrimidine-2,4(1H,3H)-Dione",
      "CHEMBL435558",
      "BDBM20059",
      "5-Substituted-6-Chlorouracil, 5f",
    ],
  },
  {
    primary_id: "D03IMZ",
    names: [
      "4-Hydroxy-1,2,5-Oxadiazole-3-Carboxylic Acid",
      "4-HYDROXY-1,2,5-OXADIAZOLE-3-CARBOXYLIC ACID",
      "4-Hydroxy-Furazan-3-Carboxylic Acid",
      "66313-36-8",
      "OXQ",
      "AC1L9M6V",
      "1,2,5-Oxadiazole, OXD1",
      "SCHEMBL1043406",
      "CTK8F6043",
      "CTK7J1234",
      "BDBM23232",
      "MolPort-002-679-216",
      "ZINC6523895",
      "FCH841687",
      "AKOS030214651",
      "AKOS000301440",
      "DB02401",
      "MCULE-2928095407",
      "ST077901",
      "TR-055247",
      "AX8302019",
      "4-Oxo-1,2,5-Oxadiazole-3-Carboxylic Acid",
      "BB 0257536",
    ],
  },
  {
    primary_id: "D03INC",
    names: ["Triplelastat"],
  },
  {
    primary_id: "D03ING",
    names: [
      "Anrukinzumab",
      "Ab13-2",
      "IMA-638",
      "PF-05230917",
      "Anti-IL-13 Antibody (Asthma), Wyeth",
      "Anti-IL-13 Antibody (Ulcerative Colitis), Pfizer",
    ],
  },
  {
    primary_id: "D03INQ",
    names: ["Laevo-Bambuterol"],
  },
  {
    primary_id: "D03IOM",
    names: ["CART-19"],
  },
  {
    primary_id: "D03IPJ",
    names: ["ISIS 28425"],
  },
  {
    primary_id: "D03IPZ",
    names: [
      "4-(3-Fluoro-Phenoxy)-6,7-Dimethoxy-Quinoline",
      "CHEMBL102191",
      "SCHEMBL7490337",
      "LQOBRLFNSGKIFO-UHFFFAOYSA-N",
      "BDBM50290841",
    ],
  },
  {
    primary_id: "D03IQD",
    names: ["PF-06480605"],
  },
  {
    primary_id: "D03IQH",
    names: ["EDG-006"],
  },
  {
    primary_id: "D03IQK",
    names: ["BC-821"],
  },
  {
    primary_id: "D03IQM",
    names: ["NAV Therapeutic"],
  },
  {
    primary_id: "D03IQX",
    names: ["2-(2-Fluoro-Phenyl)-Benzo[h]chromen-4-One", "CHEMBL361608"],
  },
  {
    primary_id: "D03IRC",
    names: ["HIV-1 RNase H Inhibitors"],
  },
  {
    primary_id: "D03IRG",
    names: ["PNK-007"],
  },
  {
    primary_id: "D03IRR",
    names: [
      "Phenazone",
      "Antipyrine",
      "60-80-0",
      "Antipyrin",
      "Phenazon",
      "Analgesine",
      "Anodynine",
      "Fenazone",
      "Anodynin",
      "Azophen",
      "2,3-Dimethyl-1-Phenyl-5-Pyrazolone",
      "Antipirin",
      "Phenozone",
      "Methozin",
      "Azophene",
      "Phenylone",
      "Pyrazophyl",
      "Sedatine",
      "Apirelina",
      "Sedatin",
      "Dimethyloxychinizin",
      "Dimethyloxyquinazine",
      "Phenazonum",
      "Antipyrinum",
      "Oxydimethylquinazine",
      "Azophenum",
      "Parodyne",
      "Phenylon",
      "Fenazona",
      "3-Antipyrine",
      "Fenazon [Czech]",
      "Auralgan",
      "Fenazon",
      "Fenazona [INN-Spanish]",
      "Phenazone (Pharmaceutical)",
      "3H-Pyrazol-3-One, 1,2-Dihydro-1,5-Dimethyl-2-Phenyl-",
      "Oxydimethylquinizine",
    ],
  },
  {
    primary_id: "D03ISE",
    names: ["PAFENOLOL"],
  },
  {
    primary_id: "D03ISJ",
    names: ["BMS-863233"],
  },
  {
    primary_id: "D03ITH",
    names: ["1-(Cyclohexyl)Methyl-5'-O-Tritylinosine", "CHEMBL211259", "BDBM50194181"],
  },
  {
    primary_id: "D03ITI",
    names: ["Sunepitron"],
  },
  {
    primary_id: "D03ITZ",
    names: ["Aryl Mannoside Derivative 4"],
  },
  {
    primary_id: "D03IUD",
    names: [
      "Ceftolozane Sulfate",
      "Ceftolozane",
      "CXA-101",
      "CXA-301",
      "CXA-301)",
      "Cephalosporin Derivatives, Astellas",
      "Cephalosporinderivatives, Calixa Therapeutics",
      "FR-193879",
      "FR-264205",
      "FR-295389",
      "CXA-101 (Inhaled), Calixa",
      "CXA-101 (Inhaled), Cubist",
      "Cephalosporin Derivative (H Pylori/P Aeruginosa Infection), Astellas",
      "CXA-101 (Inhaled, Bacterial Lung Infection), Cubist",
    ],
  },
  {
    primary_id: "D03IUY",
    names: ["Estropipate"],
  },
  {
    primary_id: "D03IVC",
    names: ["S 202 791"],
  },
  {
    primary_id: "D03IVG",
    names: ["Imiloxan"],
  },
  {
    primary_id: "D03IVU",
    names: ["Methyl 14-(3-N-Butylureido)Tetradec-8(Z)-Enoate", "CHEMBL555832"],
  },
  {
    primary_id: "D03IVW",
    names: ["CBDMB", "CB-DMB"],
  },
  {
    primary_id: "D03IWC",
    names: [
      "3-Benzyl-6-Methylbenzo[d]oxazol-2(3H)-One",
      "CHEMBL1256155",
      "SCHEMBL243468",
      "HYWGUCHKPHXSGZ-UHFFFAOYSA-N",
      "BDBM50327347",
    ],
  },
  {
    primary_id: "D03IWT",
    names: [
      "NT-13317",
      "BND-003",
      "BND-13317",
      "NT-24336",
      "NiK-003",
      "NiK-13317",
      "Dimiracetam Derivatives (Pain), Brane",
      "Dimiracetam Derivatives (Pain), Neurotune",
    ],
  },
  {
    primary_id: "D03IWW",
    names: [
      "(S)-Piperidine-3-Carboxylic Acid",
      "59045-82-8",
      "(S)-(+)-Nipecotic Acid",
      "(3S)-Piperidine-3-Carboxylic Acid",
      "(S)-Nipecotic Acid",
      "(3S)-Nipecotic Acid",
      "(S)-(+)-3-Piperidinecarboxylic Acid",
      "(S)-Hexahydronicotinic Acid",
      "L(+)-NIPECOTIC ACID",
      "(3S)-Hexahydronicotinic Acid",
      "CHEBI:222169",
      "(3S)-3-Piperidinecarboxylic Acid",
      "3-Piperidinecarboxylic Acid, (3S)-",
      "(S)-(+)-PIPERIDINE-3-CARBOXYLIC ACID",
      "UNII-2635N2PS4Q",
      "(3S)-(+)-Piperidine-3-Carboxylic Acid",
      "L-PIPERIDINE-3-CARBOXYLIC ACID",
    ],
  },
  {
    primary_id: "D03IWX",
    names: ["2-Methoxy-9-Aminomethyl-9,10-Dihydroanthracene", "CHEMBL497157", "BDBM50264949"],
  },
  {
    primary_id: "D03IWY",
    names: ["ISIS 103806"],
  },
  {
    primary_id: "D03IXK",
    names: ["ME-344"],
  },
  {
    primary_id: "D03IZV",
    names: [
      "DS1",
      "372497-52-4",
      "4-Chloro-N-[6,8-Dibromo-2-(Thiophen-2-Yl)Imidazo[1,2-A]pyridin-3-Yl]benzamide",
      "AC1LJTKG",
      "Oprea1_660341",
      "GTPL4183",
      "CTK8E8259",
      "DTXSID60359667",
      "MolPort-002-552-049",
      "ZINC624119",
      "STK706326",
      "DS1, &gt",
      "AKOS001667044",
      "MCULE-4602668576",
      "API0008444",
      "RT-012419",
      "EU-0078283",
      "J3.560.884B",
      "SR-01000493101",
      "SR-01000493101-1",
      "4-Chloro-N-(6,8-Dibromo-2-Thien-2-Ylimidazo[1,2-A]pyridin-3-Yl)Benzamide",
      "4-Chloro-N-[6,8-Dibromo-2-(2-Thienyl)Imidazo[1,2-A]pyridine-3-Yl]benzamide",
    ],
  },
  {
    primary_id: "D03IZY",
    names: ["Benzazepine Derivative 4"],
  },
  {
    primary_id: "D03JAA",
    names: ["Glycodiazine"],
  },
  {
    primary_id: "D03JAE",
    names: [
      "S-(4-Bromobenzyl)Isothiourea Hydrobromide",
      "111039-41-9",
      "4-Bromobenzyl Carbamimidothioate Hydrobromide",
      "{[(4-Bromophenyl)Methyl]sulfanyl}methanimidamide Hydrobromide",
      "CHEMBL1224314",
      "([(4-Bromophenyl)Methyl]sulfanyl)Methanimidamide Hydrobromide",
      "SCHEMBL854064",
      "KS-00001OGW",
      "MolPort-002-945-994",
      "STK099425",
      "NSC403785",
      "AKOS005396363",
      "NSC-403785",
    ],
  },
  {
    primary_id: "D03JAF",
    names: ["Nrti"],
  },
  {
    primary_id: "D03JAL",
    names: [
      "1-Aminohexylphosphonic Acid",
      "(1-Aminohexyl)Phosphonic Acid",
      "63207-60-3",
      "CHEMBL40508",
      "109638-78-0",
      "ACMC-20mcgx",
      "AC1MC01Z",
      "SCHEMBL625939",
      "CTK6D6935",
      "DTXSID70370060",
      "1-AMINOHEXYL PHOSPHONIC ACID",
      "Phosphonic Acid, P-(1-Aminohexyl)-",
      "AKOS015892987",
      "DB-054431",
      "FT-0640877",
    ],
  },
  {
    primary_id: "D03JAO",
    names: ["Saridegib"],
  },
  {
    primary_id: "D03JAZ",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 13"],
  },
  {
    primary_id: "D03JBI",
    names: ["PPI-03306"],
  },
  {
    primary_id: "D03JCA",
    names: [
      "MB-11055",
      "Cryptotanshinone, MD BioAlpha",
      "Cryptotanshinone, Mazence",
      "AMP Activated Protein Kinase Stimulator (Liver Cirrhosis/Steatosis/Steatohepatitis), Mazence",
      "AMPK Stimulator (Liver Cirrhosis/Steatosis/Steatohepatitis), Mazence",
      "Lipid Metabolism Stimulator (Liver Cirrhosis/Steatosis/Steatohepatitis), Mazence",
    ],
  },
  {
    primary_id: "D03JCI",
    names: ["PMID26560530-Compound-11"],
  },
  {
    primary_id: "D03JCN",
    names: ["PMID26004420-Compound-US20140142115C"],
  },
  {
    primary_id: "D03JCT",
    names: ["M-110101"],
  },
  {
    primary_id: "D03JDA",
    names: ["Nu-3"],
  },
  {
    primary_id: "D03JDH",
    names: ["PMID27109571-Compound-32"],
  },
  {
    primary_id: "D03JDL",
    names: ["AZ10417808", "AZ-10417808", "AZ 10417808"],
  },
  {
    primary_id: "D03JDR",
    names: ["Fused Heterocyclic Compound 4"],
  },
  {
    primary_id: "D03JDY",
    names: ["[Ncy(SO,methyl)4]acyline", "CHEMBL415432"],
  },
  {
    primary_id: "D03JEU",
    names: ["Glucuronic Acid"],
  },
  {
    primary_id: "D03JEW",
    names: ["N-(1-(4-Nitrobenzyl)Piperidin-4-Yl)-2-Naphthamide", "CHEMBL234644"],
  },
  {
    primary_id: "D03JFB",
    names: ["PMID12723954C21b"],
  },
  {
    primary_id: "D03JFC",
    names: ["3-(Benzo[d]oxazol-2-Yl)Phenyl Propylcarbamate", "CHEMBL573871"],
  },
  {
    primary_id: "D03JFJ",
    names: ["PMID26651364-Compound-108"],
  },
  {
    primary_id: "D03JFK",
    names: ["PMID25991433-Compound-O2"],
  },
  {
    primary_id: "D03JFL",
    names: ["2-(2-Carboxy-7-Mercaptoheptyl)Benzoic Acid", "CHEMBL206804"],
  },
  {
    primary_id: "D03JFU",
    names: ["Alpha-Sulfanylpropylphosphonic Acid"],
  },
  {
    primary_id: "D03JFX",
    names: [
      "9-Ethyl-9H-Adenine",
      "9-Ethyl-9h-Purin-6-Amine",
      "2715-68-6",
      "9-Ethyladenine",
      "9H-Purin-6-Amine,9-Ethyl",
      "N9-Ethyladenine",
      "9-Ethylpurin-6-Amine",
      "9H-Purin-6-Amine, 9-Ethyl-",
      "9-Ethyl Adenine",
      "9-Ethyl-9H-Purin-6-Ylamine",
      "9H-Purin-6-Amine,9-Ethyl-",
      "CHEMBL304009",
      "Adenine, 9-Ethyl-",
      "N9 -Ethyladenine",
      "NSC 14580",
      "6-Amino-9-Ethylpurine",
      "AC1Q4VHH",
      "ACMC-1CJP6",
      "(9-Ethylpurin-6-Yl)Amine",
      "Adenine, 9-Ethyl- (8CI)",
      "AC1L189M",
      "SCHEMBL1924594",
      "CTK4F9170",
      "DTXSID50181616",
      "MUIPLRMGAXZWSQ-UHFFFAOYSA-N",
      "MolPort-004-964-357",
      "ZINC402896",
      "NSC14580",
      "KS-000000ID",
    ],
  },
  {
    primary_id: "D03JHD",
    names: ["PMID25980951-Compound-15"],
  },
  {
    primary_id: "D03JHJ",
    names: ["FK-352"],
  },
  {
    primary_id: "D03JIH",
    names: ["SSR-125180"],
  },
  {
    primary_id: "D03JJU",
    names: ["Cladribine Tablets"],
  },
  {
    primary_id: "D03JJW",
    names: ["PMID25522065-Compound-11"],
  },
  {
    primary_id: "D03JJY",
    names: [
      "GANTACURIUM CHLORIDE",
      "AV-430A",
      "GW-0430",
      "GW-280430A",
      "Gantacurium Chloride",
      "(Z)-2-Chlorofumaric Acid 1-[3-[6,7-Dimethoxy-2(S)-Methyl-1(R)-(3,4,5-Trimethoxybenzyl)-1,2,3,4-Tetrahydroisoquinolinium-2-Yl]propyl] 4-[3-[6,7-Dimethoxy-2(R)-Methyl-1(S)-(3,4,5-Trimethoxyphenyl)-1,2,3",
    ],
  },
  {
    primary_id: "D03JLK",
    names: ["Ac-FWKY-NH2", "CHEMBL1165795"],
  },
  {
    primary_id: "D03JLR",
    names: [
      "PLX51107",
      "AMSUHYUVOVCWTP-INIZCTEOSA-N",
      "SCHEMBL16085681",
      "HY-111422",
      "CS-0040568",
      "4-[6-(3,5-Dimethylisoxazol-4-Yl)-1-[(1S)-1-(2-Pyridyl)Ethyl]pyrrolo[3,2-B]pyridin-3-Yl]benzoic Acid",
    ],
  },
  {
    primary_id: "D03JMD",
    names: ["S-(4-Cyanobenzyl)Isothiourea Hydrobromide"],
  },
  {
    primary_id: "D03JMF",
    names: ["IKI-1"],
  },
  {
    primary_id: "D03JMN",
    names: ["AD-177"],
  },
  {
    primary_id: "D03JMZ",
    names: ["Peptide Analog 28"],
  },
  {
    primary_id: "D03JNC",
    names: ["Garcinol"],
  },
  {
    primary_id: "D03JNS",
    names: ["Peanut Allergy Vaccine"],
  },
  {
    primary_id: "D03JOA",
    names: ["Autologous Anti-CD19CAR-4-1BB-CD3zeta-EGFRt-Expressing T Lymphocytes"],
  },
  {
    primary_id: "D03JOS",
    names: [
      "3-(4-Phenyl-Piperazin-1-Ylmethyl)-1H-Indole",
      "3-[(4-Phenylpiperazino)Methyl]-1h-Indole",
      "CHEMBL59324",
      "4281-72-5",
      "3-[(4-Phenylpiperazin-1-Yl)Methyl]-1H-Indole",
      "NSC33115",
      "AC1Q1ILD",
      "AC1L5R6D",
      "Oprea1_259378",
      "CBDivE_012786",
      "CTK4I6632",
      "MolPort-002-141-505",
      "NSC-33115",
      "CCG-45935",
      "ZINC53151307",
      "BDBM50050464",
      "AKOS030540271",
      "MCULE-7772581256",
      "KB-286915",
    ],
  },
  {
    primary_id: "D03JOX",
    names: ["1-(2-Methylphenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One", "CHEMBL203403", "SCHEMBL4935428"],
  },
  {
    primary_id: "D03JPH",
    names: ["Isoxicam"],
  },
  {
    primary_id: "D03JPL",
    names: [
      "12-Hydroxylauric Acid",
      "12-Hydroxydodecanoic Acid",
      "505-95-3",
      "12-Hydroxy-Dodecanoic Acid",
      "Dodecanoic Acid, 12-Hydroxy-",
      "Omega-Hydroxydodecanoic Acid",
      "12-Hydroxy Lauric Acid",
      "Dodecanoic Acid,12-Hydroxy-",
      "CHEBI:39567",
      "ZDHCZVWCTKTBRY-UHFFFAOYSA-N",
      "NSC664211",
      "AK-51216",
      "Omega-Hydroxydodecanoate",
      "J-504146",
      "12H",
      "Sabinic Acid",
      "EINECS 208-025-0",
      "Omega-OH Lauric Acid",
      "NSC 159293",
      "NSC 664211",
      "Omega Hydroxy Dodecanoate",
      "Omega-OH Dodecanoic Acid",
      "AC1L2WIA",
      "Omega-Hydroxy Lauric Acid",
      "AC1Q7CPY",
      "ACMC-209x3w",
      "12-Hydroxy Dodecanoic Acid",
      "Omega-Hydroxy D",
    ],
  },
  {
    primary_id: "D03JPN",
    names: ["A-582941", "A582941", "A 582941"],
  },
  {
    primary_id: "D03JQD",
    names: [
      "BETP",
      "1371569-69-5",
      "(4-(3-Benzyloxyphenyl)-2-Ethylsulfinyl-6-(Trifluoromethyl)Pyrimidine",
      "GTPL7585",
      "SCHEMBL20657432",
      "MolPort-035-765-832",
      "BETP, &gt",
      "BCP19312",
      "AKOS024458341",
      "NCGC00379201-03",
      "KB-271527",
      "HY-103546",
      "CS-0028074",
      "4-(3-Benzyloxyphenyl)-2-Ethylsulfinyl-6-(Trifluoromethyl)Pyrimidine (BETP)",
      "2-Ethylsulfinyl-4-(3-Phenylmethoxyphenyl)-6-(Trifluoromethyl)Pyrimidine",
      "2-(Ethylsulfinyl)-4-[3-(Phenylmethoxy)Phenyl]-6-(Trifluoromethyl)Pyrimidine",
    ],
  },
  {
    primary_id: "D03JQL",
    names: ["PMID29473428-Compound-6"],
  },
  {
    primary_id: "D03JQR",
    names: ["RG-7273"],
  },
  {
    primary_id: "D03JRA",
    names: ["Beta-Lactam"],
  },
  {
    primary_id: "D03JRG",
    names: ["VU0364739"],
  },
  {
    primary_id: "D03JRJ",
    names: ["NTE-122", "ACAT Inhibitor, Nissin Food"],
  },
  {
    primary_id: "D03JRL",
    names: ["TDI-0102"],
  },
  {
    primary_id: "D03JRO",
    names: ["ADCI"],
  },
  {
    primary_id: "D03JRX",
    names: ["N-N-Dodecyl-7-Methoxytacrine Hydrochloride", "CHEMBL1257525"],
  },
  {
    primary_id: "D03JSJ",
    names: [
      "Mupirocin",
      "Bactoderm",
      "Bactroban",
      "Centany",
      "MRC",
      "Mupirocina",
      "Mupirocine",
      "Mupirocinum",
      "Plasimine",
      "Bactroban Nasal",
      "Mupirocina [Spanish]",
      "Mupirocine [French]",
      "Mupirocinum [Latin]",
      "Pseudomonic Acid",
      "Pseudomonic Acid A",
      "BRL 4910A",
      "BRL-4910A",
      "Bactroban (TN)",
      "Centany (TN)",
      "Mupirocin (USP/INN)",
      "Mupirocin, 14C-Labeled",
      "5,9-Anhydro-2,3,4,8-Tetradeoxy-8-[[3-(2-Hydroxy-1-Methylpropyl)Oxiranyl]methyl]-3-Methyl-[2E,8[2S,3S(1S,2S)",
      "8-Carboxyoctyl (E)-4-(2S,3R,4R,5S)-5-((2S,3S,4S,5S)-2,3-Epoxy-5-Hydroxy-4-Methylhexyl)-3,4-Dihydroxytetrahydro-2H-Pyran-2-Yl)-3-Methylcrotonat",
      "9-[(E)-4-[(2S,3R,4R,5S)-3,4-Dihydroxy-5-[[(2S,3S)-3-[(2S,3S)-3-Hydroxybutan-2-Yl]oxiran-2-Yl]methyl]oxan-2-Yl]-3-Methylbut-2-Enoyl]oxynonanoic Acid",
    ],
  },
  {
    primary_id: "D03JSO",
    names: ["ASP-2535"],
  },
  {
    primary_id: "D03JSS",
    names: ["Tidembersat"],
  },
  {
    primary_id: "D03JTG",
    names: ["Fruquintinib"],
  },
  {
    primary_id: "D03JTL",
    names: ["APVO414"],
  },
  {
    primary_id: "D03JTS",
    names: [
      "4-Amino-1,8-Naphthalimide",
      "1742-95-6",
      "4-Aminonaphthalimide",
      "6-AMINO-BENZO[DE]ISOQUINOLINE-1,3-DIONE",
      "6-Amino-1H-Benzo[de]isoquinoline-1,3(2H)-Dione",
      "4-Aminonaphthalene-1,8-Dicarboximide",
      "Naphthalimide, 4-Amino-",
      "DFP 1",
      "1H-Benz[de]isoquinoline-1,3(2H)-Dione, 6-Amino-",
      "EINECS 217-110-1",
      "BRN 0177185",
      "6-Aminobenzo[de]isoquinoline-1,3-Dione",
      "PARP Inhibitor V, 4-ANI",
      "CHEMBL338790",
      "CHEBI:40071",
      "SSMIFVHARFVINF-UHFFFAOYSA-N",
      "6-Amino-1H-Benz(De)Isoquinoline-1,3(2H)-Dione",
      "4-AMINO-1,8 NAPHTHALIMIDE",
    ],
  },
  {
    primary_id: "D03JUF",
    names: ["KRL-104"],
  },
  {
    primary_id: "D03JUM",
    names: ["Pentaxim"],
  },
  {
    primary_id: "D03JUN",
    names: ["Anti-IL31"],
  },
  {
    primary_id: "D03JVY",
    names: ["ISIS 116361"],
  },
  {
    primary_id: "D03JWH",
    names: ["2-(O-Toluidino)-5-Ethylthiazol-4(5H)-One"],
  },
  {
    primary_id: "D03JWM",
    names: ["PMID29865878-Compound-38"],
  },
  {
    primary_id: "D03JWU",
    names: ["REC-01"],
  },
  {
    primary_id: "D03JXD",
    names: ["PMID25656651-Compound-34c"],
  },
  {
    primary_id: "D03JXN",
    names: ["Recombinant Anti-IgE Fabs"],
  },
  {
    primary_id: "D03JXR",
    names: ["NOSO-74"],
  },
  {
    primary_id: "D03JXX",
    names: ["4,5-Dimethyl-Pyrrolidin-(2Z)-Ylideneamine", "CHEMBL185584", "BDBM50150924"],
  },
  {
    primary_id: "D03JYD",
    names: ["Tnk-6123"],
  },
  {
    primary_id: "D03JYN",
    names: ["1-(Phenyl(P-Tolyl)Methylene)Thiosemicarbazide", "CHEMBL402465"],
  },
  {
    primary_id: "D03JZC",
    names: ["PF-06263507"],
  },
  {
    primary_id: "D03JZD",
    names: ["Neurosolve"],
  },
  {
    primary_id: "D03JZP",
    names: ["Dihydropyridine Compound 4"],
  },
  {
    primary_id: "D03JZQ",
    names: ["3-(4-Phenyl-6-(Thiophen-3-Yl)Pyridin-2-Yl)-Phenol", "CHEMBL1095140"],
  },
  {
    primary_id: "D03KAC",
    names: ["XL147"],
  },
  {
    primary_id: "D03KAM",
    names: [
      "DNA Alkylating Agents",
      "BO-1012",
      "BO-1055",
      "BO-1090",
      "BO-1393",
      "BO-1509",
      "DNA Alkylating Agents (Cancer)",
      "DNA Alkylating Agents (Cancer), Academia Sinica",
    ],
  },
  {
    primary_id: "D03KAQ",
    names: ["2-Benzylaminomethyl-3-Hydroxymorphinan", "CHEMBL578173"],
  },
  {
    primary_id: "D03KAV",
    names: ["Biphenyl Mannoside Derivative 12"],
  },
  {
    primary_id: "D03KBV",
    names: [
      "2-Phosphoglyceric Acid",
      "2-Phospho-D-Glyceric Acid",
      "D-Glycerate 2-Phosphate",
      "2-Phospho-D-Glycerate",
      "(2R)-3-Hydroxy-2-(Phosphonooxy)Propanoic Acid",
      "3-D-Hydroxy-2-Phosphonooxy-Propanoic Acid",
      "2-Phospho-(R)-Glycerate",
      "Glyceric Acid-2-Phosphate",
      "D(+)2-Phosphoglyceric Acid",
      "SCHEMBL2726719",
      "CTK1B7706",
      "CHEBI:17835",
      "GXIURPTVHJPJLF-UWTATZPHSA-N",
      "1-Carboxy-2-Hydroxyethyl Phosphate",
      "AC1L9718",
      "ZINC3869232",
      "3443-57-0",
      "2PG",
      "3-D-Hydroxy-2-Phosphonooxy-Propanoate",
      "DB01709",
      "(2R)-3-Hydroxy-2-(Phosphonooxy)Propanoate",
    ],
  },
  {
    primary_id: "D03KDD",
    names: ["N-Methyl,N-(Propargyl),N-(Pyrrol-2-Ylmethyl)Amine"],
  },
  {
    primary_id: "D03KDO",
    names: ["Prochyma"],
  },
  {
    primary_id: "D03KEK",
    names: ["Artemisinin SP"],
  },
  {
    primary_id: "D03KFJ",
    names: ["TB-102"],
  },
  {
    primary_id: "D03KFN",
    names: ["PMID28074661-Compound-US20100022547C88"],
  },
  {
    primary_id: "D03KGH",
    names: ["Upamostat"],
  },
  {
    primary_id: "D03KGJ",
    names: [
      "AM-92016",
      "COMPOUND II",
      "N-(4-(3-((2-(3,4-Dichlorophenyl)Ethyl)Methylamino)-2-Hydroxypropoxy)Phenyl)Methanesulfonamide",
      "Bio2_000288",
      "AC1L1CZF",
      "Msaph-2ClPhEA-2-Propanol",
      "CBiol_001738",
      "KBioGR_000288",
      "BSPBio_001568",
      "KBioSS_000288",
      "CHEMBL43527",
      "AC1Q3O93",
      "SCHEMBL9655059",
      "KBio3_000575",
      "CHEBI:91532",
      "KBio3_000576",
      "KBio2_002856",
      "KBio2_005424",
      "KBio2_000288",
      "Bio1_000513",
      "HMS1791O10",
      "Bio1_001002",
      "Bio1_000024",
      "HMS1989O10",
      "Bio2_000768",
      "AC-115",
      "IDI1_034038",
      "NCGC00024837-04",
      "NCGC00024837-03",
      "NCGC00024837-02",
      "BRD-A11813248-003-01-6",
      "BRD-A11813248-001-02-8",
    ],
  },
  {
    primary_id: "D03KGX",
    names: ["Rotungenic Acid"],
  },
  {
    primary_id: "D03KHN",
    names: ["CLR-1404", "CLR-1501", "CLR-1502"],
  },
  {
    primary_id: "D03KHO",
    names: ["PF-05175157"],
  },
  {
    primary_id: "D03KHQ",
    names: [
      "DNA-Ad",
      "DNA-Prime/Adenovirus (Ad5)-Boost CSP-And AMA1-Encoding Malaria Vaccine (Biojector2000, Intramuscular), US NavalMedical Research Center (NMRC)/Bioject/Vical/GenVec",
    ],
  },
  {
    primary_id: "D03KHX",
    names: ["MC-D11"],
  },
  {
    primary_id: "D03KIA",
    names: [
      "Cerivastatin",
      "Baycol",
      "Cerivastatin Acid",
      "Lipobay",
      "145599-86-6",
      "Cerivastatin [INN:BAN]",
      "UNII-AM91H2KS67",
      "CHEBI:3558",
      "AM91H2KS67",
      "HSDB 7357",
      "(3R,5S,6E)-7-(4-(P-Fluorophenyl)-2,6-Diisopropyl-5-(Methoxymethyl)-3-Pyridyl)-3,5-Dihydroxy-6-Heptenoic Acid",
      "(3R,5S,6E)-7-(4-(4-Fluorophenyl)-5-(Methoxymethyl)-2,6-Bis(1-Methylethyl)-3-Pyridinyl)-3,5-Dihydroxy-6-Heptenoic Acid",
      "143201-11-0",
      "6-Heptenoic Acid, 7-(4-(4-Fluorophenyl)-5-(Methoxymethyl)-2,6-Bis(1-Methylethyl)-3-Pyridinyl)-3,5-Dihydroxy-, (3R,5S,6E)-",
      "Statins",
    ],
  },
  {
    primary_id: "D03KIK",
    names: ["AP-300"],
  },
  {
    primary_id: "D03KIZ",
    names: ["RG7596"],
  },
  {
    primary_id: "D03KJL",
    names: [
      "1-Iodyl-4-Nitro-Benzene",
      "CHEMBL118424",
      "P-Iodoxynitrobenzene",
      "1-Iodoxy-4-Nitrobenzene",
      "SCHEMBL1572451",
      "BDBM50075023",
    ],
  },
  {
    primary_id: "D03KJP",
    names: ["Meningococcal CYW Vaccine"],
  },
  {
    primary_id: "D03KJS",
    names: [
      "Antalarmin",
      "157284-96-3",
      "CHEMBL296641",
      "7H-Pyrrolo(2,3-D)Pyrimidin-4-Amine, N-Butyl-N-Ethyl-2,5,6-Trimethyl-7-(2,4,6-Trimethylphenyl)-",
      "SCHEMBL466243",
      "GTPL3489",
      "AC1L433C",
      "DTXSID50166241",
      "CHEBI:139557",
      "ZINC1539136",
      "NSC715590",
      "BDBM50074501",
      "AKOS030559812",
      "NSC-715590",
      "NCGC00165732-01",
      "NCI60_039940",
      "LS-139513",
      "N-Butyl-N-Ethyl-2,5,6-Trimethyl-7-(2,4,6-Trimethylphenyl)Pyrrolo[3,2-E]pyrimidin-4-Amine",
      "N-Butyl-N-Ethyl-7-Mesityl-2,5,6-Trimethyl-7H-Pyrrolo[2,3-D]pyrimidin-4-Amine",
    ],
  },
  {
    primary_id: "D03KJU",
    names: ["NV-04"],
  },
  {
    primary_id: "D03KJV",
    names: ["Ultramorph"],
  },
  {
    primary_id: "D03KKH",
    names: [
      "(S)-Phenylglycine",
      "2935-35-5",
      "H-Phg-Oh",
      "L-Phenylglycine",
      "(S)-2-Amino-2-Phenylacetic Acid",
      "L-(+)-Alpha-Phenylglycine",
      "L-2-Phenylglycine",
      "(2S)-Amino(Phenyl)Acetic Acid",
      "L-(+)-2-Phenylglycine",
      "Phenylglycine",
      "(2S)-2-Amino-2-Phenylacetic Acid",
      "L-(+)-Alpha-Aminophenylacetic Acid",
      "UNII-3I753R0XI6",
      "L-Alpha-Phenylglycine",
      "L(+)-Alpha-Phenylglycine",
      "(S)-(+)-2-Phenylglycine",
      "CHEMBL378605",
      "(2S)-Amino(Phenyl)Ethanoic Acid",
      "CHEBI:439819",
      "3I753R0XI6",
      "MFCD00064403",
      "(AlphaS)-Alpha-Aminobenzeneacetic Acid",
    ],
  },
  {
    primary_id: "D03KKJ",
    names: ["PMID28460551-Compound-5"],
  },
  {
    primary_id: "D03KKK",
    names: [
      "1,3-Dipropyl-3,7-Dihydro-Purine-2,6-Dione",
      "1,3-Dipropylxanthine",
      "31542-62-8",
      "1,3-Dipropyl-1H-Purine-2,6(3H,7H)-Dione",
      "UNII-Z02T66W92D",
      "CHEMBL157655",
      "Z02T66W92D",
      "1,3-Dipropyl-7H-Purine-2,6-Dione",
      "1,3-Dipropyl-3,7-Dihydro-1h-Purine-2,6-Dione",
      "1,3-Dipropyl-Xanthine",
      "AC1Q6LGS",
      "1,3-Di-N-Propylxanthine",
      "AC1L53IX",
      "GTPL427",
      "SCHEMBL515771",
      "1H-Purine-2,6-Dione, 3,9-Dihydro-1,3-Dipropyl-",
      "BDBM82032",
      "CTK4G7324",
      "DTXSID00185447",
      "MJVIGUCNSRXAFO-UHFFFAOYSA-N",
      "ZINC4654890",
      "PDSP2_000967",
      "PDSP1_000983",
      "PDSP1_000328",
      "PDSP2_001217",
      "BDBM50227331",
    ],
  },
  {
    primary_id: "D03KKR",
    names: [
      "Fasudil",
      "103745-39-7",
      "HA1077",
      "Ha-1077",
      "HA 1077",
      "Fasudil [INN]",
      "AT 877",
      "Fasudilum [INN-Latin]",
      "1-(5-Isoquinolinesulfonyl)Homopiperazine",
      "1H-1,4-Diazepine, Hexahydro-1-(5-Isoquinolinylsulfonyl)-",
      "UNII-Q0CH43PGXS",
      "5-(1,4-Diazepane-1-Sulfonyl)Isoquinoline",
      "5-(1,4-Diazepan-1-Ylsulfonyl)Isoquinoline",
      "(5-ISOQUINOLINESULFONYL)HOMOPIPERAZINE",
      "AT-877",
      "AT877",
      "Q0CH43PGXS",
      "5-(1,4-DIAZEPAN-1-SULFONYL)ISOQUINOLINE",
      "CHEMBL38380",
      "Hexahydro-1-(5-Isoquinolinylsulfonyl)-1H-1,4-Diazepine",
      "Fasudil (INN)",
      "Fasudilhydrochloride",
    ],
  },
  {
    primary_id: "D03KLF",
    names: ["MDX-1203", "Alkylating Agent-Antibody Conjugate (Cancer), Medarex"],
  },
  {
    primary_id: "D03KLM",
    names: ["(2'Z,3'E)-5-Chloro-5'-Methyl-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D03KLN",
    names: [
      "1-(3-Methoxy-Naphthalen-2-Yl)-1H-Imidazole",
      "1H-Imidazole, 1-(3-Methoxy-2-Naphthalenyl)-",
      "674309-81-0",
      "CHEMBL194472",
      "SCHEMBL4508354",
      "BDBM8917",
      "CTK1H7855",
      "DTXSID70465102",
      "Imidazole-Substituted Naphthalene 24",
      "ZINC13674482",
      "AKOS030619217",
      "1-(3-Methoxy-2-Naphthyl)-1h-Imidazole",
    ],
  },
  {
    primary_id: "D03KLO",
    names: [
      "2,6-Dimethyl-8-Ethyl-1-Deazapurine",
      "133240-06-9",
      "2-Ethyl-5,7-Dimethyl-1H-Imidazo[4,5-B]pyridine",
      "2-Ethyl-5,7-Dimethyl-3H-Imidazo[4,5-B]pyridine",
      "CHEMBL221579",
      "3H-Imidazo[4,5-B]pyridine,2-Ethyl-5,7-Dimethyl-",
      "5,7-Dimethyl-2-Ethylimidazo[4,5-B]pyridine",
      "3H-Imidazo[4,5-B]pyridine, 2-Ethyl-5,7-Dimethyl-",
      "ACMC-20dzbe",
      "SCHEMBL1918476",
      "CTK4B8431",
      "DTXSID40431137",
      "XWWJWZJOSWSJQV-UHFFFAOYSA-N",
      "ZINC22058897",
      "BDBM50205288",
      "ANW-72022",
      "AKOS000278272",
      "AKOS015904384",
      "RTX-011778",
      "FCH1411209",
      "AM803160",
      "SC-21701",
      "KB-23752",
      "AJ-80864",
      "TC-160767",
    ],
  },
  {
    primary_id: "D03KLT",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp22-]", "CHEMBL406912"],
  },
  {
    primary_id: "D03KLU",
    names: ["CT-406"],
  },
  {
    primary_id: "D03KLW",
    names: [
      "PT-109",
      "1-(5-Chloropyridin-2-Yl)-3-[2-(2,4,6-Trifluorophenyl)Ethyl]thiourea",
      "LY300046HCl Analog 29",
      "AC1NS2Q0",
      "BDBM2143",
      "CHEMBL103706",
    ],
  },
  {
    primary_id: "D03KMG",
    names: [
      "1-Benzene Sulfonyl-Cis-2,6-Dimethyl Piperidine",
      "CHEMBL575619",
      "AC1LDWTF",
      "BDBM50298422",
      "ZINC12417747",
    ],
  },
  {
    primary_id: "D03KNI",
    names: ["GR 125487"],
  },
  {
    primary_id: "D03KNZ",
    names: ["Monoamine Derivative 3"],
  },
  {
    primary_id: "D03KOH",
    names: ["PMID29671355-Compound-72a"],
  },
  {
    primary_id: "D03KOU",
    names: ["XPro-1595"],
  },
  {
    primary_id: "D03KOZ",
    names: ["Epalrestat"],
  },
  {
    primary_id: "D03KPA",
    names: [
      "5-Ethyl-3-(2-Pyrrolidin-1-Yl-Ethyl)-1H-Indole",
      "CHEMBL49048",
      "SCHEMBL8506288",
      "PWLIYMBPGFVKOO-UHFFFAOYSA-N",
      "BDBM50090520",
      "3-(2-Pyrrolizinoethyl)-5-Ethyl-1H-Indole",
      "5-Ethyl-3-(2-Pyrrolidinylethyl) -1H-Indole",
    ],
  },
  {
    primary_id: "D03KPZ",
    names: [
      "Ciprofloxacin+dexamethasone",
      "CIPRODEX",
      "Ciprofloxacin Mixture With Dexamethasone",
      "130244-48-3",
      "Ciprodex Otic",
      "Ciprofloxacin / Dexamethasone",
      "Ciprofloxacin And Dexamethasone",
      "Ciprofloxacin-Dexamethasone Mixt.",
      "SCHEMBL2523572",
      "DTXSID50156420",
      "NTRHYMXQWWPZDD-WKSAPEMMSA-N",
      "Pregna-1,4-Diene-3,20-Dione, 9-Fluoro-11,17,21-Trihydroxy-16-Methyl-, (11beta,16alpha)-, Mixt. With 1-Cyclopropyl-6-Fluoro-1,4-Dihydro-4-Oxo-7-(1-Piperazinyl)-3-Quinolinecarboxylic Acid",
    ],
  },
  {
    primary_id: "D03KQF",
    names: [
      "Maprotiline",
      "Deprilept",
      "Dibencycladine",
      "Maprotilin",
      "Maprotilina",
      "Maprotilinum",
      "Maprotylina",
      "Maprotylina [Polish]",
      "TBB066626",
      "BA-34276",
      "Deprilept (TN)",
      "Ludiomil (TN)",
      "Maprotilina [INN-Spanish]",
      "Maprotiline (USAN)",
      "Maprotilinum [INN-Latin]",
      "Psymion (TN)",
      "Ba-34,276",
      "Maprotiline [USAN:BAN:INN]",
      "N-Methyl-9,10-Ethanoanthracene-9(10H)-Propanamine",
      "N-Methyl-9,10-Ethanoanthracene-9(10H)-Propylamine",
      "3-(9,10-Dihydro-9,10-Ethanoanthracen-9-Yl)Propylmethylamine",
      "3-(9,10-Ethanoanthracen-9(10H)-Yl)-N-Methylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D03KQG",
    names: [
      "Pyrrolidin-1-Yl-Thiourea",
      "CHEMBL48338",
      "88147-87-9",
      "N-Pyrrolidin-1-Ylthiourea",
      "Thiourea, 1-Pyrrolidinyl-",
      "SCHEMBL5320211",
      "CTK3B7005",
      "DTXSID20658163",
    ],
  },
  {
    primary_id: "D03KRB",
    names: [
      "4,4'-Thiodipyridine-3-Sulfonamide",
      "CHEMBL1164504",
      "1078160-08-3",
      "NSC743332",
      "BDBM50320519",
      "4,4''-Thiodipyridine-3-Sulfonamide",
      "4,4'-Thiodi(Pyridine-3-Sulfonamide)",
      "NSC-743332",
    ],
  },
  {
    primary_id: "D03KRF",
    names: ["Superoxide Dismutase Mimetics"],
  },
  {
    primary_id: "D03KRW",
    names: ["1-(4-Methoxyphenylsulfonyl)-4-Phenylazepan-4-Ol", "CHEMBL561076", "SCHEMBL12549973"],
  },
  {
    primary_id: "D03KSK",
    names: ["Lenvima"],
  },
  {
    primary_id: "D03KSL",
    names: [
      "L-761000",
      "CHEMBL352174",
      "SCHEMBL7070915",
      "AXFSURYONUFFTK-UHFFFAOYSA-N",
      "BDBM50289087",
      "4-(1-(4-Bromobenzyl)-5-Methoxy-2-Methyl-1H-Indol-3-Yl)-3-Methylbutanoic Acid",
      "4-[1-(4-Bromo-Benzyl)-5-Methoxy-2-Methyl-1H-Indol-3-Yl]-3-Methyl-Butyric Acid",
    ],
  },
  {
    primary_id: "D03KST",
    names: ["KT2-962", "3-[4-(4-Chlorophenylsulfonamido)Butyl]-6-Isopropylazulene-1-Sulfonic Acid Sodium Salt"],
  },
  {
    primary_id: "D03KTD",
    names: ["Chromomycin A3"],
  },
  {
    primary_id: "D03KTE",
    names: ["LGD2941"],
  },
  {
    primary_id: "D03KTK",
    names: [
      "TAK-937",
      "Cerebroprotectant (Acute Stroke, Injection), Takeda",
      "CB1/CB2 Receptor Agonist (Acute Stroke, Injection), Takeda",
    ],
  },
  {
    primary_id: "D03KTU",
    names: ["BMS-561388"],
  },
  {
    primary_id: "D03KUB",
    names: ["Deslorelin"],
  },
  {
    primary_id: "D03KUD",
    names: ["Pyrrolidine Derivative 6"],
  },
  {
    primary_id: "D03KUH",
    names: ["ETICLOPRIDE"],
  },
  {
    primary_id: "D03KVW",
    names: ["MDX-1411"],
  },
  {
    primary_id: "D03KVY",
    names: ["BCT303"],
  },
  {
    primary_id: "D03KWN",
    names: ["AZD-6703"],
  },
  {
    primary_id: "D03KWP",
    names: ["PMID25522065-Compound-50"],
  },
  {
    primary_id: "D03KXO",
    names: ["FP-1039/HGS1036"],
  },
  {
    primary_id: "D03KXQ",
    names: ["L-Valyl-L-Proline Benzylamide", "CHEMBL376375"],
  },
  {
    primary_id: "D03KXT",
    names: ["4-[3-(4-Imidazol-1-Yl-Phenoxy)-Propyl]-Pyridine", "CHEMBL113227", "SCHEMBL14130001", "BDBM50138238"],
  },
  {
    primary_id: "D03KXV",
    names: ["Octagam"],
  },
  {
    primary_id: "D03KXY",
    names: ["Doxifluridine"],
  },
  {
    primary_id: "D03KXZ",
    names: ["TF 2-Targeted Bispecific Antibody"],
  },
  {
    primary_id: "D03KYG",
    names: [
      "Perindopril",
      "ACEON",
      "Coverex",
      "Coversum",
      "Coversyl",
      "Prestarium",
      "Coverene Cor",
      "Cpd With Unspecified MF",
      "Aceon (TN)",
      "Acertil (TN)",
      "Armix (TN)",
      "Coverene (TN)",
      "Coverex (TN)",
      "Coversum (TN)",
      "DW-7950",
      "Prestarium (TN)",
      "Prexanil (TN)",
      "Prexum (TN)",
      "Procaptan (TN)",
      "S-9490",
      "SED-9490",
      "McN-A-2833",
      "Perindopril (USAN/INN)",
      "Ethyl N-{(2S)-1-[(2S,3aS,7aS)-2-Carboxyoctahydro-1H-Indol-1-Yl]-1-Oxopropan-2-Yl}-L-Norvalinate",
      "(2S)-2-[(1S)-1-Carbethoxybutylamino]-1-Oxopropyl-(2S,3aS,7aS)-Perhydroindole-2-Carboxylic Acid",
      "(2S,3aS,7aS)-1-[(2S)-2-[[(2S)-1-Ethoxy-1-Oxopentan-2-Yl]amino]propanoyl]-2,3,3a,4,5,6,7,7a-Octahydroindole-2-Carboxylic Acid",
      "(2S,3aS,7aS)-1-[(2S)-2-{[(2S)-1-Ethoxy-1-Oxopentan-2-Yl]amino}propanoyl]octahydro-1H-Indole-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03KYI",
    names: ["IMD-1622"],
  },
  {
    primary_id: "D03KYL",
    names: ["Quinoline Derivative 4"],
  },
  {
    primary_id: "D03KYW",
    names: [
      "Calusterone",
      "CALUSTERONE",
      "Methosarb",
      "Dimethyltestosterone",
      "Calusteronum",
      "Calusterona",
      "7-Beta,17-Dimethyltestosterone",
      "Calusteronum [INN-Latin]",
      "Calusterona [INN-Spanish]",
      "7beta,17alpha-Dimethyltestosterone",
      "17021-26-0",
      "7beta,17-Dimethyltestosterone",
      "Calusteron",
      "7-Beta,17-Alpha-Dimethyl Testosterone",
      "Calusterone [USAN:INN]",
      "NSC-88536",
      "UNII-0678G6Q58A",
      "HSDB 3210",
      "17-Beta-Hydroxy-7-Beta,17-Alpha-Dimethylandrost-4-Ene-3-One",
      "U 22550",
      "U-22,550",
      "BRN 3212336",
      "17beta-Hydroxy-7beta,17-Dimethylandrost-4-En-3-One",
    ],
  },
  {
    primary_id: "D03KYZ",
    names: ["(S)-N2-{4-[(3-Chlorobenzyl)Oxy]benzyl}alaninamide", "CHEMBL242618", "SCHEMBL2018555"],
  },
  {
    primary_id: "D03KZH",
    names: ["SM-337"],
  },
  {
    primary_id: "D03KZK",
    names: [
      "CGP-34938",
      "CHEMBL113304",
      "AC1NOZJY",
      "SCHEMBL185737",
      "MolPort-003-824-912",
      "AKOS006286757",
      "(3-Amino-2-Hydroxypropyl)-Methylphosphinic Acid",
      "L013629",
      "(3-Amino-2-Hydroxypropyl)(Methyl)Phosphinic Acid",
    ],
  },
  {
    primary_id: "D03KZM",
    names: ["Tolvaptan"],
  },
  {
    primary_id: "D03LAB",
    names: ["TBC-3711"],
  },
  {
    primary_id: "D03LAI",
    names: ["MSB0010445"],
  },
  {
    primary_id: "D03LAL",
    names: ["RBBX-01"],
  },
  {
    primary_id: "D03LAV",
    names: ["MEDI0700"],
  },
  {
    primary_id: "D03LBD",
    names: ["G-115"],
  },
  {
    primary_id: "D03LCM",
    names: ["3-Chloro-N-(6-Chloropyridin-2-Yl)Benzamide", "CHEMBL208976", "BDBM50186322"],
  },
  {
    primary_id: "D03LCX",
    names: ["CCX-662", "CCX-733", "CCX-754", "CXCR7 Antagonists, ChemoCentryx"],
  },
  {
    primary_id: "D03LDH",
    names: [
      "1-Benzyl-4-Chroman-2-Ylmethyl-Piperazine",
      "CHEMBL179648",
      "SCHEMBL8031431",
      "AJZBPCAHQHCXMX-UHFFFAOYSA-N",
      "BDBM50159018",
      "1-[(Chroman-2-Yl)Methyl]-4-Benzylpiperazine",
    ],
  },
  {
    primary_id: "D03LFA",
    names: ["Allogeneic CART-19"],
  },
  {
    primary_id: "D03LFO",
    names: ["P-BCMA-101"],
  },
  {
    primary_id: "D03LFR",
    names: ["SB-756050"],
  },
  {
    primary_id: "D03LGG",
    names: ["Zucapsacin", "Civanex (TN)"],
  },
  {
    primary_id: "D03LGM",
    names: [
      "Beta-Phenyl-D-Phenylalanyl-N-Propyl-L-Prolinamide",
      "Proline Scaffold, 26",
      "CHEMBL490730",
      "BDBM29377",
      "DB07083",
      "(2S)-1-[(2R)-2-Amino-3,3-Diphenylpropanoyl]-N-Propylpyrrolidine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D03LGV",
    names: ["Trans-2-(4-Chlorophenyl)-2-Fluorocyclopropanamine", "CHEMBL466770"],
  },
  {
    primary_id: "D03LGX",
    names: ["NI-105"],
  },
  {
    primary_id: "D03LGY",
    names: [
      "Divalproex Sodium",
      "Delepsine",
      "Depakote",
      "Divalproate",
      "Divalproex",
      "Epilex",
      "Epival",
      "Sprinkle",
      "Valcote",
      "Valdisoval",
      "Valparin",
      "Depakote CP",
      "Depakote ER",
      "Depakote Sprinkle",
      "Divalproex Sodium [USAN]",
      "Sodium Divalproate",
      "Sodium Hydrogen Divalproate",
      "Valproate Semisodique",
      "Valproate Semisodique [French]",
      "Valproate Semisodium",
      "Valproato Semisodico",
      "Valproato Semisodico [Spanish]",
      "Valproatum Seminatricum",
      "Valproatum Seminatricum [Latin]",
      "Abbott 50711",
      "Abbott-50711",
      "Depacon (TN)",
      "Depakote (TN)",
      "Depakote ER (TN)",
      "Divalproex Sodium (USP)",
      "Epival (TN)",
      "Ergenyl Chrono (TN)",
      "Valance (TN)",
      "Valproate Semisodium (INN)",
      "Divalproex Sodium, Depakote, Epival",
      "Natrium Hydrogen Bis(2-Propylvalerat)",
      "Sodium Hydrogen Bis(2-Propylpentanoate)",
      "Sodium Hydrogen Bis(2-Propylvalerate)",
      "Valproic Acid Semisodium Salt (2:1)",
      "Zalkote. (TN)",
      "Sodium Hydrogen Bis(2-Propylvalerate), Oligomer",
      "Sodium 2-Propylpentanoate-2-Propylpentanoic Acid (1:1)",
      "Pentanoic Acid, 2-Propyl-, Sodium Salt (2:1)",
      "Pentanoic Acid, 2-Propyl-, Sodium Salt(2:1)",
      "2-Propylpentanoate",
    ],
  },
  {
    primary_id: "D03LHJ",
    names: ["8-Acetyl-7-Propoxy-2H-Chromen-2-One", "CHEMBL1288596"],
  },
  {
    primary_id: "D03LIE",
    names: ["PAV-059"],
  },
  {
    primary_id: "D03LIP",
    names: ["GW841819X"],
  },
  {
    primary_id: "D03LIS",
    names: ["Selenazofurin"],
  },
  {
    primary_id: "D03LJR",
    names: [
      "Sirolimus",
      "53123-88-9",
      "Rapamune",
      "Rapamycin (Sirolimus)",
      "AY-22989",
      "Rapammune",
      "Sirolimusum",
      "WY-090217",
      "RAPA",
      "Antibiotic AY 22989",
      "AY 22989",
      "UNII-W36ZG6FT64",
      "CCRIS 9024",
      "CHEBI:9168",
      "SILA 9268A",
      "W36ZG6FT64",
      "HSDB 7284",
      "C51H79NO13",
      "NSC 226080",
      "DE-109",
      "NCGC00021305-05",
      "DSSTox_CID_3582",
      "DSSTox_RID_77091",
      "DSSTox_GSID_23582",
      "Cypher",
      "Supralimus",
      "Wy 090217",
      "Perceiva",
      "RAP",
      "RPM",
      "Rapamycin From Streptomyces Hygroscopicus",
      "SIIA 9268A",
      "LCP-Siro",
      "MS-R001",
      "Rapamune (TN)",
      "Rapamycin (TN)",
      "Sirolimus (RAPAMUNE)",
      "Rapamycin C-7, Analog 4",
      "Sirolimus (USAN/INN)",
      "Sirolimus [USAN:BAN:INN]",
      "Sirolimus, Rapamune,Rapamycin",
      "Heptadecahydro-9,27-Dihydroxy-3-[(1R)-2-[(1S,3R,4R)-4-Hydroxy",
      "23,27-Epoxy-3H-Pyrido(2,1-C)(1,4)Oxaazacyclohentriacontine",
      "23,27-Epoxy-3H-Pyrido[2,1-C][1,4]oxaazacyclohentriacontine",
      "23,27-Epoxy-3H-Pyrido[2,1-C][1,4]oxaazacyclohentriacontine-1,5,11,28,29",
      "3H-Pyrido(2,1-C)(1,4)Oxaazacyclohentriacontine-1,5,11,28,29(4H,6H,31H)-Pentone",
      "Sirolimus (MTOR Inhibitor)",
    ],
  },
  {
    primary_id: "D03LJX",
    names: [
      "(3-Amino-5-Bromobenzofuran-2-Yl)(Phenyl)Methanone",
      "CHEMBL591458",
      "(3-Amino-5-Bromo-Benzofuran-2-Yl)-Phenyl-Methanone",
      "(3-Amino-5-Bromo-1-Benzofuran-2-Yl)(Phenyl)Methanone",
      "SMR000010549",
      "MLS000031617",
      "AC1LCKCT",
      "Regid849115",
      "Oprea1_732422",
      "Oprea1_454471",
      "MLS002534670",
      "SCHEMBL13102601",
      "ZINC97827",
      "CTK5I8073",
      "MolPort-000-164-762",
      "HMS2395L21",
      "STK078344",
      "BDBM50306745",
      "AKOS000510990",
      "MCULE-9438936220",
      "ST037109",
      "BAS 01507283",
      "EU-0018517",
      "3-Amino-5-Bromobenzo[d]furan-2-Yl Phenyl Ketone",
      "SR-01000523207",
      "SR-01000523207-1",
    ],
  },
  {
    primary_id: "D03LKM",
    names: ["PMID29053063-Compound-7a"],
  },
  {
    primary_id: "D03LKU",
    names: ["Spheramine"],
  },
  {
    primary_id: "D03LKV",
    names: ["BMN045"],
  },
  {
    primary_id: "D03LLL",
    names: ["PRX-12255"],
  },
  {
    primary_id: "D03LLM",
    names: ["SLG", "1-Stearoyl-2-Linoleoyl-Sn-Glycerol"],
  },
  {
    primary_id: "D03LMH",
    names: ["N-Phenylethyl-ETAV"],
  },
  {
    primary_id: "D03LMK",
    names: ["IB-07A085"],
  },
  {
    primary_id: "D03LMM",
    names: ["G418"],
  },
  {
    primary_id: "D03LMR",
    names: [
      "6-Benzyl-3-Ethoxycarbonyl-4-Quinolone",
      "CHEMBL381059",
      "SCHEMBL7728970",
      "WQYYZNGOTUCWQX-UHFFFAOYSA-N",
      "BDBM50185292",
      "ZINC13685782",
      "Ethyl 6-Benzyl-4-Oxo-1,4-Dihydroquinoline-3-Carboxylate",
      "Ethyl 6-Benzyl-1,4-Dihydro-4-Oxoquinoline-3-Carboxylate",
      "6-Benzyl-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid EthylEster",
    ],
  },
  {
    primary_id: "D03LNF",
    names: ["PFI-1"],
  },
  {
    primary_id: "D03LNM",
    names: ["TK-112690"],
  },
  {
    primary_id: "D03LNN",
    names: ["IDX136"],
  },
  {
    primary_id: "D03LNV",
    names: ["Argotom-VAX"],
  },
  {
    primary_id: "D03LOG",
    names: ["LINOLEIC ACID"],
  },
  {
    primary_id: "D03LPH",
    names: [
      "TRYPTOLINE",
      "16502-01-5",
      "Tryptoline",
      "2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
      "Noreleagnine",
      "1,2,3,4-Tetrahydro-9H-Pyrido[3,4-B]indole",
      "1,2,3,4-Tetrahydro-Beta-Carboline",
      "Tetrahydro-Beta-Carboline",
      "THBC",
      "Tetrahydronorharman",
      "UNII-65027TMI0H",
      "1H,2H,3H,4H,9H-Pyrido[3,4-B]Indole",
      "1H-Pyrido[3,4-B]indole, 2,3,4,9-Tetrahydro-",
      "2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "MLS000069483",
      "CHEMBL269236",
      "65027TMI0H",
      "SMR000059115",
      "Triptoline",
      "Tetrahydronorharmane",
      "Tetrahydro-Beta-Carboline, 98%",
      "1,2,3,4-Tetrahydrobeta-Carboline",
    ],
  },
  {
    primary_id: "D03LQC",
    names: ["APD-209"],
  },
  {
    primary_id: "D03LQQ",
    names: ["Alinidine"],
  },
  {
    primary_id: "D03LRN",
    names: ["PMID17888661C19"],
  },
  {
    primary_id: "D03LRQ",
    names: [
      "2-(3-Benzoyl-1H-Pyrrol-1-Yl)Acetic Acid",
      "CHEMBL93723",
      "3-Benzoylpyrrole-1-Acetic Acid",
      "BDBM50146187",
      "3-Benzoyl-1H-Pyrrole-1-Acetic Acid",
      "(3-Benzoyl-Pyrrol-1-Yl)-Acetic Acid",
    ],
  },
  {
    primary_id: "D03LRU",
    names: [
      "Alvespimycin Hydrochloride",
      "Alvespimycin Hydrochloride (USAN)",
      "17-DMAG",
      "17-Desmethoxy-17-N,N-Dimethylaminoethylamino-Geldanamycin, HCl",
      "17-N,N-Dimethylaminoethylamino-17-Demethoxy-Geldanamycin, HCl",
      "17DMAG",
      "17DMAG, Alvespimycin, KOS-1022, NSC 707545, 17-DMAG",
    ],
  },
  {
    primary_id: "D03LRY",
    names: [
      "C-(6-Methoxy-Naphthalen-1-Yl)-Methylamine",
      "CHEMBL131412",
      "57382-44-2",
      "6-Methoxy-1-Naphthalenemethanamine",
      "SCHEMBL15345391",
      "BDBM50061312",
      "1-(Aminomethyl)-6-Methoxynaphthalene",
      "6-Methoxynaphthalene-1-(Methanamine)",
    ],
  },
  {
    primary_id: "D03LSH",
    names: [
      "UH-AH 37",
      "1-Chloro-11-[2-(1-Methylpiperidin-4-Yl)Acetyl]-5H-Benzo[b][1,4]benzodiazepin-6-One",
      "AC1L2V2Z",
      "GTPL8583",
      "SCHEMBL6746859",
      "UFJWXHOFKIGIAF-UHFFFAOYSA-N",
      "6-Chloro-5-[(1-Methylpiperidin-4-Yl)Acetyl]-5H-Dibenzo[b,e][1,4]diazepin-11(10H)-One",
    ],
  },
  {
    primary_id: "D03LSO",
    names: ["SPD-418"],
  },
  {
    primary_id: "D03LTA",
    names: ["Haemophilus Influenzae B (Hib) Vaccine"],
  },
  {
    primary_id: "D03LTO",
    names: ["Tigatuzumab"],
  },
  {
    primary_id: "D03LUF",
    names: ["CD137 CAR-T Cell"],
  },
  {
    primary_id: "D03LUN",
    names: ["Laronidase", "Aldurazyme (TN)"],
  },
  {
    primary_id: "D03LVI",
    names: [
      "AMG-2504",
      "CHEMBL1086587",
      "4-Nitro-N-{2,2,2-Trichloro-1-[(4-Chlorophenyl)Sulfanyl]ethyl}benzamide",
      "4-Nitro-N-(2,2,2-Trichloro-1-((4-Chlorophenyl)Sulfanyl)Ethyl)Benzamide",
      "AC1MD3F6",
      "SCHEMBL19743227",
      "MolPort-002-133-093",
      "STK822395",
      "BDBM50318457",
      "AKOS016332516",
      "AKOS003622338",
      "MCULE-9954660789",
      "AE-848/32004034",
      "4-Nitro-N-(2,2,2-Trichloro-1-(4-Chlorophenylthio)Ethyl)Benzamide",
    ],
  },
  {
    primary_id: "D03LVL",
    names: ["GSK2193874"],
  },
  {
    primary_id: "D03LVT",
    names: ["SEMIPLENAMIDE B"],
  },
  {
    primary_id: "D03LVV",
    names: ["Neridronate Intravenous"],
  },
  {
    primary_id: "D03LWG",
    names: ["BGJ398"],
  },
  {
    primary_id: "D03LWO",
    names: ["Desirudin Recombinant"],
  },
  {
    primary_id: "D03LWV",
    names: ["Nle-Pro-Glu"],
  },
  {
    primary_id: "D03LWY",
    names: ["SULFATE"],
  },
  {
    primary_id: "D03LXH",
    names: ["GAI-122"],
  },
  {
    primary_id: "D03LXJ",
    names: ["KB-2683"],
  },
  {
    primary_id: "D03LZK",
    names: ["APS-3010"],
  },
  {
    primary_id: "D03LZO",
    names: ["PMID27376512-Compound-Figure3CG"],
  },
  {
    primary_id: "D03MAD",
    names: [
      "CP-800569",
      "(2R)-3-[3-(4-Chloro-3-Ethylphenoxy)-N-[[3-(1,1,2,2-Tetrafluoroethoxy)Phenyl]methyl]anilino]-1,1,1-Trifluoropropan-2-Ol",
    ],
  },
  {
    primary_id: "D03MBC",
    names: ["KAE609"],
  },
  {
    primary_id: "D03MBU",
    names: ["SC-006"],
  },
  {
    primary_id: "D03MCC",
    names: ["Tetra-Hydro-Naphthalene Derivative 1"],
  },
  {
    primary_id: "D03MCV",
    names: ["RB-006"],
  },
  {
    primary_id: "D03MDA",
    names: [
      "2-Morpholin-4-Yl-6-Phenyl-4H-Thiopyran-4-One",
      "CHEMBL276746",
      "2-Morpholino-6-Phenyl-4H-Thiopyran-4-One",
      "2-Morpholin-4-Yl-6-Phenyl-Thiopyran-4-One",
      "SCHEMBL5842568",
    ],
  },
  {
    primary_id: "D03MDV",
    names: ["PAT-CSL1"],
  },
  {
    primary_id: "D03MDW",
    names: ["DS-7309"],
  },
  {
    primary_id: "D03MDY",
    names: ["PLX9486"],
  },
  {
    primary_id: "D03MEI",
    names: ["VU0080241"],
  },
  {
    primary_id: "D03MER",
    names: ["Endogenous Human Peptide LL-37"],
  },
  {
    primary_id: "D03MFQ",
    names: [
      "CHF-5074",
      "CHF 5074",
      "CHF5074",
      "749269-83-8",
      "Itanapraced",
      "UNII-C35RF1MWQZ",
      "GHF-5074",
      "C35RF1MWQZ",
      "CHEMBL196945",
      "1-(3',4'-Dichloro-2-Fluoro(1,1'-Biphenyl)-4-Yl)Cyclopropanecarboxylic Acid",
      "1-[4-(3,4-Dichlorophenyl)-3-Fluorophenyl]cyclopropane-1-Carboxylic Acid",
      "1-(3',4'-Dichloro-2-Fluorobiphenyl-4-Yl)Cyclopropanecarboxylic Acid",
      "H50",
      "SCHEMBL407631",
      "GTPL7339",
      "DTXSID30225901",
      "AOB5325",
      "LIYLTQQDABRNRX-UHFFFAOYSA-N",
      "ZINC3986651",
      "EX-A1963",
      "BDBM50172482",
      "AKOS026750398",
      "SB16945",
      "CS-5022",
      "NCGC00408905-01",
      "AS-16850",
      "HY-14399",
      "BC600569",
      "FT-0708261",
    ],
  },
  {
    primary_id: "D03MFV",
    names: ["MNLP-18"],
  },
  {
    primary_id: "D03MGL",
    names: ["Tafamidis Meglumine"],
  },
  {
    primary_id: "D03MGV",
    names: ["ASP-5034"],
  },
  {
    primary_id: "D03MHA",
    names: ["FR260010"],
  },
  {
    primary_id: "D03MIN",
    names: ["M-0004"],
  },
  {
    primary_id: "D03MIP",
    names: ["SPI-256"],
  },
  {
    primary_id: "D03MIR",
    names: [
      "Doxazosin",
      "Doxazosina",
      "Doxazosine",
      "Doxazosinum",
      "Normothen",
      "Doxazosina [Spanish]",
      "Doxazosine [French]",
      "Doxazosinum [Latin]",
      "UK 33274",
      "Cardura (TN)",
      "Cardura XL (TN)",
      "Cardura-1",
      "Cardura-2",
      "Cardura-4",
      "Carduran (TN)",
      "Doxazosin (INN)",
      "Doxazosin [INN:BAN]",
      "UK-33274",
      "[4-(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)Piperazin-1-Yl]-(2,3-Dihydro-1,4-Benzodioxin-3-Yl)Methanone",
      "1-(4-Amino-6,7-Dimethoxy-2-Quinazolinyl)-4-(1,4-Benzodioxan-2-Ylcarbonyl)Piperazin",
      "1-(4-Amino-6,7-Dimethoxy-2-Chinazolinyl)-4-(2,3-Dihydro-1,4-Benzodioxixin-2-Ylcarbonyl)Piperazin",
      "1-(4-Amino-6,7-Dimethoxy-2-Quinazolinyl)-4-[4-(1,4-Benzodioxan-2-Yl)Carpiperazin-1-Yl)]-6,7-Dimethoxyquinazoline Mesylate",
      "2-[4-(2,3-Dihydro-1,4-Benzodioxin-2-Ylcarbonyl)Piperazin-1-Yl]-6,7-Bis(Methyloxy)Quinazolin-4-Amine",
      "2-[4-(2,3-Dihydro-1,4-Benzodioxin-2-Ylcarbonyl)Piperazin-1-Yl]-6,7-Dimethoxyquinazolin-4-Amine",
      "2-{4-[(2,3-Dihydro-1,4-Benzodioxin-2-Yl)Carbonyl]piperazin-1-Yl}-6,7-Dimethoxyquinazolin-4-Amine",
    ],
  },
  {
    primary_id: "D03MJJ",
    names: ["Thiocoumarin"],
  },
  {
    primary_id: "D03MJM",
    names: ["Trans-(R(S))-2-Hydroxy-1-Phenylethyl Nitrate", "CHEMBL563619", "BDBM50293660"],
  },
  {
    primary_id: "D03MJQ",
    names: ["Inositol 1-Phosphate", "1D-Myo-Inositol 1-Phosphate"],
  },
  {
    primary_id: "D03MKE",
    names: ["Resorcinol Compound 15"],
  },
  {
    primary_id: "D03MKT",
    names: [
      "4-[5-Fluoro-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 6a",
      "BDBM8614",
      "AC1O705R",
      "SCHEMBL4231103",
      "CHEMBL424960",
      "SCHEMBL4231111",
      "ZINC13535834",
      "4-[(E)-(5-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D03MKY",
    names: ["Benzamide Derivative 9"],
  },
  {
    primary_id: "D03MLA",
    names: [
      "PT-302",
      "149485-77-8",
      "Thiourea, N-(2-(4-Fluorophenyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(4-Fluorophenyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 4",
      "AC1MHDMB",
      "CHEMBL253996",
      "BDBM1897",
      "DTXSID00164292",
      "1-[2-(4-Fluorophenyl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "AKOS030030877",
      "1-(2-Thiazolyl)-3-[4-Fluorophenethyl]thiourea",
      "N-(2-(4-Fluorophenethyl))-N -(2-Thiazolyl)Thiourea",
      "1-[2-(4-Fluorophenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
    ],
  },
  {
    primary_id: "D03MLC",
    names: [
      "EUPAFOLIN",
      "6-Methoxyluteolin",
      "520-11-6",
      "Nepetin",
      "2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-6-Methoxy-4H-Chromen-4-One",
      "Eurafolin",
      "UNII-I3O7LF3GED",
      "I3O7LF3GED",
      "CHEMBL172350",
      "NSC122416",
      "4H-1-Benzopyran-4-One, 2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-6-Methoxy-",
      "2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-6-Methoxychromen-4-One",
      "SMR000440634",
      "Pedaltin",
      "NSC 122416",
      "AC1NSVCV",
      "MLS000728561",
      "MLS000877025",
      "SCHEMBL828390",
      "MEGxp0_000458",
      "Cid_5317284",
      "ACon1_000516",
      "BDBM23412",
      "DTXSID10199959",
      "FHHSEFRSDKWJKJ-UHFFFAOYSA-N",
      "REGID_For_CID_5317284",
    ],
  },
  {
    primary_id: "D03MLR",
    names: ["ADE-LAM"],
  },
  {
    primary_id: "D03MMZ",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 6"],
  },
  {
    primary_id: "D03MNN",
    names: ["SKI-758"],
  },
  {
    primary_id: "D03MNO",
    names: ["L-658,758"],
  },
  {
    primary_id: "D03MNS",
    names: ["ADL-7445"],
  },
  {
    primary_id: "D03MOQ",
    names: ["1-(4-Chloro-Phenyl)-3-(3,3-Diphenyl-Allyl)-Urea", "CHEMBL9308", "BDBM50005456"],
  },
  {
    primary_id: "D03MPD",
    names: ["PMID27019002-Compound-20a"],
  },
  {
    primary_id: "D03MQA",
    names: ["4-(Cyclopropylmethylthio)-2-Methoxybenzonitrile", "CHEMBL504110"],
  },
  {
    primary_id: "D03MQI",
    names: ["4-(6,7-Dimethoxy-Quinolin-3-Yl)-Benzoic Acid", "CHEMBL66868", "SCHEMBL8172493", "ZINC13737718"],
  },
  {
    primary_id: "D03MRO",
    names: ["ISIS 113187"],
  },
  {
    primary_id: "D03MSF",
    names: ["Nerenone"],
  },
  {
    primary_id: "D03MSN",
    names: ["HD-101", "Antibody (Asthma/Autoimmune Disease), Dreampharma"],
  },
  {
    primary_id: "D03MSV",
    names: ["PB-1023"],
  },
  {
    primary_id: "D03MTM",
    names: ["JTE-013"],
  },
  {
    primary_id: "D03MTN",
    names: [
      "Mepolizumab",
      "Bosatria",
      "Scoparianoside C",
      "SB 240563",
      "Immunoglobulin G1, Anti-(Human Interleukin 5) (Human-Mouse Monoclonal SB-240563 Gamma1-Chain), Disulfide With Human-Mouse Monoclonal SB-240563 Kappa-Chain, Dimer",
      "(2S,3S,4S,5R,6R)-6-[[(3S,4aR,6aR,6bS,8aS,14aR,14bR)-8a-Carboxy-4,4,6a,6b,11,11,14b-Heptamethyl-1,2,3,4a,5,6,7,8,9,10,12,13,14,14a-Tetradecahydropicen-3-Yl]oxy]-3,5-Dihydroxy-4-[(2S,3R,4S,5R)-3,4,5-Trihydroxyoxan-2-Yl]oxyoxane-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03MTU",
    names: ["Dual Specificity CD19 And CD22 CAR-T Cell"],
  },
  {
    primary_id: "D03MTX",
    names: ["PMID25991433-Compound-O1"],
  },
  {
    primary_id: "D03MTY",
    names: ["LA22-Radioimmunoconjugates"],
  },
  {
    primary_id: "D03MUI",
    names: ["Single Tablet Regimen"],
  },
  {
    primary_id: "D03MUM",
    names: [
      "EDONENTAN HYDRATE",
      "Edonentan Hydrate < USAN",
      "BMS-207940 (Anhydrous)",
      "BMS-207940-02",
      "N-[2'-(4,5-Dimethylisoxazol-3-Ylsulfamoyl)-4-(2-Oxazolyl)Biphenyl-2-Ylmethyl]-N,3,3-Trimethylbutyramide Hydrate",
    ],
  },
  {
    primary_id: "D03MUN",
    names: ["Xilonix"],
  },
  {
    primary_id: "D03MUT",
    names: ["Calfactant"],
  },
  {
    primary_id: "D03MUW",
    names: ["3-Phosphoglycerol"],
  },
  {
    primary_id: "D03MUZ",
    names: [
      "8-Chloro-Quinoline-3-Carbonitrile",
      "CHEMBL436817",
      "Cyanoquinoline, 11",
      "SCHEMBL6071997",
      "BDBM21898",
      "CHEBI:94979",
      "BRD-K00088062-001-01-3",
      "4-[(3-Chloro-4-Fluorophenyl)Amino]-6-[(1H-Imidazole-5-Ylmethyl)Amino]-8-Chloro-3-Quinolinecarbonitrile",
    ],
  },
  {
    primary_id: "D03MVY",
    names: [
      "2-(5-Thiophen-2-Yl-1H-Indol-3-Yl)-Ethylamine",
      "CHEMBL355517",
      "SCHEMBL8686120",
      "BDBM50088863",
      "AKOS022550938",
      "3-(2-Aminoethyl)-5-(2-Thienyl)-1H-Indole",
    ],
  },
  {
    primary_id: "D03MWM",
    names: ["Oncolysin B", "Anti-B4-BR"],
  },
  {
    primary_id: "D03MWW",
    names: ["ISIS 114372"],
  },
  {
    primary_id: "D03MWZ",
    names: ["Syntropin"],
  },
  {
    primary_id: "D03MXK",
    names: [
      "AT-406",
      "1071992-99-8",
      "SM 406",
      "(5S,8S,10aR)-N-Benzhydryl-5-((S)-2-(Methylamino)Propanamido)-3-(3-Methylbutanoyl)-6-Oxodecahydropyrrolo[1,2-A][1,5]diazocine-8-Carboxamide",
      "CHEMBL2158051",
      "QCR-136",
      "UNII-N65WC8PXDD",
      "N65WC8PXDD",
      "AT406 (SM-406, ARRY-334543)",
      "J-501625",
      "(5S,8S,10aR)-N-(Diphenylmethyl)-5-[(N-Methyl-L-Alanyl)Amino]-3-(3-Methylbutanoyl)-6-Oxodecahydropyrrolo[1,2-A][1,5]diazocine-8-Carboxamide",
      "AT-406/Debio-1143",
    ],
  },
  {
    primary_id: "D03MXZ",
    names: ["Malonate Sodium"],
  },
  {
    primary_id: "D03MYI",
    names: ["PMID28394193-Compound-33"],
  },
  {
    primary_id: "D03MYL",
    names: ["Lysophosphotidylserine"],
  },
  {
    primary_id: "D03MYO",
    names: ["MGL-3196"],
  },
  {
    primary_id: "D03MYV",
    names: ["2-(2-Phenyl-2-(Piperazin-1-Yl)Ethyl)Benzonitrile", "CHEMBL213035"],
  },
  {
    primary_id: "D03MYX",
    names: ["AM0010"],
  },
  {
    primary_id: "D03MYY",
    names: ["L-Asparagine", "Agedoite", "Altheine", "Crystal VI"],
  },
  {
    primary_id: "D03MZJ",
    names: [
      "BUCINDOLOL",
      "Bucindolol",
      "71119-11-4",
      "Bucindolol [INN:BAN]",
      "Bucindololum [INN-Latin]",
      "CHEMBL321582",
      "C22H25N3O2",
      "NCGC00167817-01",
      "2-(2-Hydroxy-3-((2-(1H-Indol-3-Yl)-1,1-Dimethylethyl)Amino)Propoxy)Benzonitrile",
      "DSSTox_RID_81872",
      "DSSTox_CID_26744",
      "DSSTox_GSID_46744",
      "Bucindololum",
      "2-[2-Hydroxy-3-[2-(Indol-3-Yl)-1,1-Dimethylethylamino]propoxy]benzonitrile",
      "Benzonitrile, 2-(2-Hydroxy-3-((2-(1H-Indol-3-Yl)-1,1-Dimethylethyl)Amino)Propoxy)-",
      "2-[2-Hydroxy-3-[[2-(1H-Indol-3-Yl)-1,1-Dimethylethyl]amino]propoxy]benzonitrile",
    ],
  },
  {
    primary_id: "D03MZQ",
    names: ["Sevelamer Hydrochloride"],
  },
  {
    primary_id: "D03MZU",
    names: ["AHCQ"],
  },
  {
    primary_id: "D03NAW",
    names: ["Dulaglutide"],
  },
  {
    primary_id: "D03NBX",
    names: ["P-Hydroxyphenethyl Trans-Ferulate"],
  },
  {
    primary_id: "D03NCB",
    names: ["Ac-[CFWkYC]-NH2", "CHEMBL1163477", "Ac-[CFWKYC]-NH2"],
  },
  {
    primary_id: "D03NCF",
    names: ["Tyr-Pro-Hfe-Pro-NH2", "CHEMBL380286"],
  },
  {
    primary_id: "D03NCK",
    names: ["RU82197"],
  },
  {
    primary_id: "D03NCL",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 3"],
  },
  {
    primary_id: "D03NDD",
    names: ["LY466195"],
  },
  {
    primary_id: "D03NDI",
    names: ["KCO-912"],
  },
  {
    primary_id: "D03NEA",
    names: ["ISIS 114559"],
  },
  {
    primary_id: "D03NEF",
    names: [
      "6-Methoxykaempferol 3-O-Beta-D-Robinobioside",
      "90706-64-2",
      "AC1NUSLN",
      "CHEMBL445993",
      "AKOS030567035",
      "5,7-Dihydroxy-2-(4-Hydroxyphenyl)-6-Methoxy-3-[(2S,3R,4S,5R,6R)-3,4,5-Trihydroxy-6-[[(2R,3R,4R,5R,6S)-3,4,5-Trihydroxy-6-Methyloxan-2-Yl]oxymethyl]oxan-2-Yl]oxychromen-4-One",
      "4H-1-Benzopyran-4-One, 3-((6-O-(6-Deoxy-Alpha-L-Mannopyranosyl)-Beta-D-Galactopyranosyl)Oxy)-5,7-Dihydroxy-2-(4-Hydroxyphenyl)-6-Methoxy-",
    ],
  },
  {
    primary_id: "D03NFE",
    names: ["Vaccine, Gp 120"],
  },
  {
    primary_id: "D03NFW",
    names: ["PMID19289283C22"],
  },
  {
    primary_id: "D03NGH",
    names: ["Ramoplanin"],
  },
  {
    primary_id: "D03NHO",
    names: ["Monoaryl-1,2-Diamine Derivative 1"],
  },
  {
    primary_id: "D03NHS",
    names: ["PCO371"],
  },
  {
    primary_id: "D03NHW",
    names: [
      "Ofloxacin",
      "Bactocin",
      "DEXTROFLOXACINE",
      "Danoflox",
      "Effexin",
      "Exocin",
      "Exocine",
      "Flobacin",
      "Flodemex",
      "Flotavid",
      "Flovid",
      "Floxal",
      "Floxil",
      "Floxin",
      "Floxstat",
      "Fugacin",
      "Inoflox",
      "Kinflocin",
      "Kinoxacin",
      "Liflox",
      "Loxinter",
      "Marfloxacin",
      "Medofloxine",
      "Mergexin",
      "Novecin",
      "Nufafloqo",
      "OFLX",
      "OFX",
      "Obide",
      "Occidal",
      "Ocuflox",
      "Ofcin",
      "Oflin",
      "Oflocee",
      "Oflocet",
      "Oflocin",
      "Oflodal",
      "Oflodex",
      "Oflodura",
      "Oflox",
      "Ofloxacina",
      "Ofloxacine",
      "Ofloxacino",
      "Ofloxacinum",
      "Ofloxin",
      "Ofus",
      "Onexacin",
      "Operan",
      "Orocin",
      "Otonil",
      "Oxaldin",
      "Pharflox",
      "Praxin",
      "Puiritol",
      "Qinolon",
      "Qipro",
      "Quinolon",
      "Quotavil",
      "Rilox",
      "Sinflo",
      "Tabrin",
      "Taravid",
      "Tariflox",
      "Tarivid",
      "Telbit",
      "Tructum",
      "Viotisone",
      "Visiren",
      "XED",
      "Zanocin",
      "Floxin Otic",
      "Ofloxacin Otic",
      "Ofloxacina [DCIT]",
      "Ofloxacine [French]",
      "Ofloxacino [Spanish]",
      "Ofloxacinum [Latin]",
      "Uro Tarivid",
      "DL 8280",
      "HOE 280",
      "O 8757",
      "ORF 18489",
      "PT 01",
      "DL-8280",
      "FLOXIN IN DEXTROSE 5%",
      "FLOXIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
      "Floxin Otic (TN)",
      "HOE-280",
      "Hoe-280",
      "Marfloxacin (TN)",
      "O-Flox",
      "ORF-28489",
      "Ocuflox (TN)",
      "Ru-43280",
      "WP-0405",
      "Ofloxacin (JP15/USP/INN)",
      "Ofloxacin [USAN:BAN:INN:JAN]",
      "Ofloxacin, (S)-Isomer",
      "DL-8280, HOE-280, Exocin, Flobacin, Floxin, Floxil, Monoflocet, Ofloxacin",
      "(+-)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyrido(1,2,3-De)-1,4-Benzoxazine-6-Carboxylic Acid",
      "(+/-)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperaz Inyl)-7-Oxo-7H-Pyrido(1,2,3-De)-1,4-Benzoxazine-6-Carboxylic Acid",
      "(+/-)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyrido(1,2,3-De)-1,4-Benzoxazine-6-Carboxylic Acid",
      "(+/-)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyrido[1,2,3-De]-1,4-Benzoxazine-6-Carboxylic Acid",
      "(+/-)-Floxin",
      "(-)-9-Fluoro-2,3-Dihydro-3-Methyl-10-(4-Methyl-1-Piperazinyl)-7-Oxo-7H-Pyrido(1,2,3-De)(1,4)Benzoxazin-6-Carbonsaeure",
      "9-Fluoro-3-Methyl-10-(4-Methylpiperazin-1-Yl)-7-Oxo-2,3-Dihydro-7H-[1,4]oxazino[2,3,4-Ij]quinoline-6-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03NHZ",
    names: ["Engineered Toxin Bodies"],
  },
  {
    primary_id: "D03NIO",
    names: ["5-[(2-Methyl-4-Thiazolyl)Ethynyl]pyrimidine", "CHEMBL202042", "SCHEMBL4144583"],
  },
  {
    primary_id: "D03NIW",
    names: ["B244"],
  },
  {
    primary_id: "D03NJN",
    names: ["PAT-SM5"],
  },
  {
    primary_id: "D03NJY",
    names: [
      "SF1126",
      "CC-1126",
      "SF-1126",
      "L-Serine, N2-(1,4-Dioxo-4-((4-(4-Oxo-8-Phenyl-4H-1-Benzopyran-2-Yl)Morpholinium-4-Yl)Methoxy)Butyl)-L-Arginylglycyl-L-Alpha-Aspartyl-, Inner Salt",
    ],
  },
  {
    primary_id: "D03NKE",
    names: ["6-Bromo-2'-De-N-Methylaplysinopsin"],
  },
  {
    primary_id: "D03NLE",
    names: ["IGERNELLIN"],
  },
  {
    primary_id: "D03NLG",
    names: ["ISIS 9064"],
  },
  {
    primary_id: "D03NLJ",
    names: ["FGI-106"],
  },
  {
    primary_id: "D03NLL",
    names: [
      "(2R)-1-(2,6-Dimethylphenoxy)Propan-2-Amine",
      "94991-73-8",
      "(R)-1-(2,6-Dimethylphenoxy)Propan-2-Amine",
      "2-Propanamine, 1-(2,6-Dimethylphenoxy)-, (2R)-",
      "CHEMBL147507",
      "Zlchem 1301",
      "(R)-MEXILETINE",
      "(R)-(-)-Mexiletine",
      "AC1L47IL",
      "SCHEMBL16082",
      "BIDD:GT0498",
      "ZINC20257",
      "ZLE0076",
      "DTXSID50241709",
      "BDBM50135883",
      "AKOS017529564",
      "DB07129",
      "AJ-08428",
      "KB-209407",
      "(2R)-1-(2,6-Dimethylphenoxy)-2-Propanamine",
      "A821017",
      "(R)-2-(2,6-Dimethyl-Phenoxy)-1-Methyl-Ethylamine",
      "UNII-1U511HHV4Z Component VLPIATFUUWWMKC-SNVBAGLBSA-N",
    ],
  },
  {
    primary_id: "D03NLQ",
    names: ["ABT-957"],
  },
  {
    primary_id: "D03NLV",
    names: ["KDT-501"],
  },
  {
    primary_id: "D03NMB",
    names: ["3-(4-Bromobenzyl)-1,1-Dimethylselenourea", "CHEMBL398715"],
  },
  {
    primary_id: "D03NMM",
    names: ["AM-643"],
  },
  {
    primary_id: "D03NMQ",
    names: ["131I-CLR-1404"],
  },
  {
    primary_id: "D03NND",
    names: ["N-(3-Benzooxazol-7-Yl-Propyl)-Propionamide", "CHEMBL124476", "SCHEMBL6780633"],
  },
  {
    primary_id: "D03NOS",
    names: ["Rimiterol"],
  },
  {
    primary_id: "D03NOU",
    names: [
      "Alpha-Ketoisovalerate",
      "3-Methyl-2-Oxobutanoic Acid",
      "759-05-7",
      "3-Methyl-2-Oxo-Butanoic Acid",
      "Alpha-Ketoisovaleric Acid",
      "2-Ketoisovaleric Acid",
      "Alpha-Ketovaline",
      "2-Oxoisovalerate",
      "3-Methyl-2-Oxobutyric Acid",
      "Butanoic Acid, 3-Methyl-2-Oxo-",
      "2-Oxoisovaleric Acid",
      "2-Ketovaline",
      "3-Methyl-2-Oxobutanoate",
      "2-Keto-3-Methylbutyric Acid",
      "2-Oxo-3-Methylbutanoate",
      "2-Oxoisopentanoate",
      "Alpha-Oxoisovalerate",
      "Dimethylpyruvic Acid",
      "A-Ketoisovaleric Acid",
      "KETOVALINE",
      "Isopropylglyoxylic Acid",
      "Alpha-Keto-Isovaleric Acid",
      "2-Oxo-3-Methylbutanoic Acid",
    ],
  },
  {
    primary_id: "D03NPA",
    names: ["Ganoderic Acid A"],
  },
  {
    primary_id: "D03NPH",
    names: [
      "Quazepam",
      "Cetrane",
      "Doral",
      "Dormalin",
      "Oniria",
      "Prosedar",
      "Quazapam",
      "Quazepamum",
      "Quazium",
      "Selepam",
      "Sch 16134",
      "Doral (TN)",
      "Dormalin (TN)",
      "Quazepamum [INN-Latin]",
      "Sch-161",
      "Sch-16134",
      "Quazepam (JAN/USP)",
      "Quazepam [USAN:BAN:INN]",
      "Quazepam (JAN/USP/INN)",
      "7-Chloro-5-(2-Fluorophenyl)-1,3-Dihydro-1-(2,2,2-Trifluoroethyl)-2H-1,4-Benzodiazepine-2-Thione",
      "7-Chloro-5-(O-Fluorophenyl)-1,3-Dihydro-1-(2,2,2-Trifluoroethyl)-2H-1,4-Benzodiazepine-2-Thione",
      "7-Chloro-5-(2-Fluorophenyl)-1-(2,2,2-Trifluoroethyl)-3H-1,4-Benzodiazepine-2-Thione",
    ],
  },
  {
    primary_id: "D03NPI",
    names: ["Bb2121"],
  },
  {
    primary_id: "D03NPX",
    names: ["PD-0173955"],
  },
  {
    primary_id: "D03NPZ",
    names: ["NNC-22-0031"],
  },
  {
    primary_id: "D03NRJ",
    names: [
      "4-Benzenesulfonyl-1-(3-Phenyl-Propyl)-Piperidine",
      "CHEMBL148565",
      "BDBM50108708",
      "ZINC13472074",
      "4-(Phenylsulfonyl)-1-Hydrocinnamylpiperidine",
    ],
  },
  {
    primary_id: "D03NSJ",
    names: ["NSC-45592"],
  },
  {
    primary_id: "D03NTD",
    names: [
      "3,4',5-Trimethoxy-(Z)-Stilbene",
      "(Z)-3,5,4'-TRIMETHOXYSTILBENE",
      "Cis-Trismethoxy Resveratrol",
      "94608-23-8",
      "CHEMBL311636",
      "(Z)-3,4',5-Trimethoxystilbene",
      "Cis-Trismethoxyresveratrol",
      "Cis-Stilbene Derivative, 5a",
      "SCHEMBL1006207",
      "ZINC9252",
      "8Z-3,4',5-Trimethoxystilbene",
      "BDBM23940",
      "MolPort-009-018-268",
      "GDHNBPHYVRHYCC-PLNGDYQASA-N",
      "(Z)-3,5,40-Trimethoxystilbene",
      "HMS3648G16",
      "1101AH",
      "AKOS025401671",
      "AC-24237",
      "DB-079973",
      "608T238",
      "SR-01000946368",
      "SR-01000946368-1",
      "I14-7426",
      "1,3-Dimethoxy-5-[(Z)-2-(4-Methoxyphenyl)Ethenyl]benzene",
    ],
  },
  {
    primary_id: "D03NTF",
    names: ["CWP-231"],
  },
  {
    primary_id: "D03NTG",
    names: ["Sp7343-Sp7964"],
  },
  {
    primary_id: "D03NTJ",
    names: ["Prolixin Decanoate"],
  },
  {
    primary_id: "D03NTO",
    names: [
      "Isosorbide-2-Benzylcarbamate-5-Nicotinate",
      "CHEMBL492283",
      "BDBM50253230",
      "(3R,3aR,6S,6aR)-6-(Benzylcarbamoyloxy)Hexahydrofuro[3,2-B]furan-3-Yl Nicotinate",
    ],
  },
  {
    primary_id: "D03NTQ",
    names: ["7-Phenyl-1-(Pyridazin-3-Yl)-Heptan-1-One", "CHEMBL455556", "SCHEMBL2161875", "BDBM50262479"],
  },
  {
    primary_id: "D03NUC",
    names: [
      "Cyclopentylcarbamic Acid Biphenyl-3-Yl Ester",
      "CHEMBL448753",
      "BDBM50256913",
      "Cyclopentyl-Carbamic Acid Biphenyl-3-Yl Ester",
    ],
  },
  {
    primary_id: "D03NUN",
    names: ["(+/-)-Threo-Methylphenidate Amide"],
  },
  {
    primary_id: "D03NUT",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Octyl Ester",
      "CHEMBL421505",
      "BDBM50116814",
      "4-(Sulfamoyloxy)Benzoic Acid Octyl Ester",
    ],
  },
  {
    primary_id: "D03NVE",
    names: ["CVT-6694", "CHEMBL252048", "SCHEMBL242234", "BDBM50233086"],
  },
  {
    primary_id: "D03NVS",
    names: ["NC-003"],
  },
  {
    primary_id: "D03NWC",
    names: ["SNG-8023"],
  },
  {
    primary_id: "D03NWH",
    names: [
      "2-(3-Bromo-Phenyl)-Chromen-4-One",
      "3'-Bromoflavone",
      "CHEMBL55148",
      "2-(3-Bromophenyl)Chromen-4-One",
      "AC1LAQPY",
      "SCHEMBL6654321",
      "MolPort-001-904-025",
      "ZINC265291",
      "AKOS000603552",
      "BAS 01121885",
      "4H-1-Benzopyran-4-One, 2-(3-Bromophenyl)-",
    ],
  },
  {
    primary_id: "D03NWI",
    names: [
      "Ala-Pro-Glu",
      "71190-92-6",
      "CHEMBL381831",
      "CTK2G2707",
      "DTXSID10658778",
      "L-Alanyl-L-Prolyl-L-Glutamic Acid",
      "L-Glutamic Acid, L-Alanyl-L-Prolyl-",
    ],
  },
  {
    primary_id: "D03NWT",
    names: ["PMID26924192-Compound-104"],
  },
  {
    primary_id: "D03NWV",
    names: [
      "1-(2-Ethoxy-Phenyl)-Piperazine",
      "1-(2-Ethoxyphenyl)Piperazine",
      "13339-01-0",
      "CHEMBL273923",
      "Piperazine,1-(2-Ethoxyphenyl)-",
      "FBQIUSDQWOLCNY-UHFFFAOYSA-N",
      "1-(2-Ethoxyphenyl)Piperazinium Chloride",
      "Ehoxyphenylpiperazine",
      "EINECS 236-389-0",
      "AC1Q57PR",
      "AC1Q37ZC",
      "Oprea1_356570",
      "Labotest-Bb Lt00233165",
      "4(2-Eth-Oxyphenyl)Piperazine",
      "SCHEMBL255189",
      "2-Ethoxy-1-Piperazinylbenzene",
      "1-(2-Ehoxyphenyl)-Piperazine",
      "N-(2-Ethoxyphenyl)-Piperazine",
      "AC1L359U",
      "1-(2-Ethoxyphenyl)-Piperazine",
      "N-(2-Ethyoxyphenyl)-Piperazine",
      "CTK4B8518",
    ],
  },
  {
    primary_id: "D03NXS",
    names: ["GKT-04"],
  },
  {
    primary_id: "D03NXV",
    names: [
      "Ametantrone",
      "64862-96-0",
      "Ametantrone [INN]",
      "NSC-196473",
      "NSC 196473",
      "Ametantronum [INN-Latin]",
      "Ametantrona [INN-Spanish]",
      "UNII-PNT6041ST1",
      "BRN 2791800",
      "PNT6041ST1",
      "C22H28N4O4",
      "1,4-Bis((2-((2-Hydroxyethyl)Amino)Ethyl)Amino)Anthraquinone",
      "1,4-Bis(2-((2-Hydroxyethyl)Amino)Ethylamino)-9,10-Anthracenedione",
      "9,10-Anthracenedione,1,4-Bis[[2-[(2-Hydroxyethyl)Amino]ethyl]amino]-",
      "1,4-Bis((2-((2-Hydroxyethyl)Amino)Ethyl)Amino)Anthracene-9,10-Dione",
      "1,4-Bis((2-((2-Hydroxyethyl)Amino)Ethyl)Amino)-9,10-Anthracenedione",
      "Ametantronum",
    ],
  },
  {
    primary_id: "D03NXZ",
    names: ["CV-1674", "2-(4-Methoxyphenyl)Adenosine", "CV 1674", "NSC 310669", "CV1674"],
  },
  {
    primary_id: "D03NYE",
    names: [
      "4-(2-Oxohexadecanamido)Butanoic Acid",
      "CHEMBL95190",
      "SCHEMBL1877382",
      "ZINC36310606",
      "BDBM50149408",
      "4-(2-Oxo-Hexadecanoylamino)-Butyric Acid",
      "4-[(1,2-Dioxohexadecyl)Amino]butanoic Acid",
    ],
  },
  {
    primary_id: "D03NYF",
    names: ["4-Biphenyl-4-Ylethynyl-2-Methyl-Thiazole", "CHEMBL185078"],
  },
  {
    primary_id: "D03NZM",
    names: ["T-588", "(-)-(R)-1-(Benzothiophen-5-Yl)-2-[2-(Diethylamino)Ethoxy]ethanol Hydrochloride", "131965-06-5"],
  },
  {
    primary_id: "D03NZU",
    names: ["FMOC-L-Leucine"],
  },
  {
    primary_id: "D03NZV",
    names: ["SC-236"],
  },
  {
    primary_id: "D03NZW",
    names: ["PMD38"],
  },
  {
    primary_id: "D03OBV",
    names: [
      "AS-1405",
      "AngioMab",
      "Angiogenesis Antibody, Antisoma",
      "HuBC-1",
      "MuBC-1",
      "Humanized BC-1 Antibodies, Antisoma",
    ],
  },
  {
    primary_id: "D03OCG",
    names: ["FGF-21"],
  },
  {
    primary_id: "D03OCS",
    names: ["ME36"],
  },
  {
    primary_id: "D03ODQ",
    names: [
      "[1,4]Oxazepan-(5E)-Ylideneamine",
      "CHEMBL330548",
      "SCHEMBL2590253",
      "5-Iminohexahydro-1,4-Oxazepine",
      "[1,4]Oxazepan-(5Z)-Ylideneamine",
      "BDBM50104659",
    ],
  },
  {
    primary_id: "D03ODX",
    names: ["IC-84", "Clostridium Difficile Vaccine, Intercell"],
  },
  {
    primary_id: "D03OED",
    names: [
      "N-(6-(4-Fluorophenyl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1084681",
      "SCHEMBL4488738",
      "ORRUGYIZWSJRJU-UHFFFAOYSA-N",
      "BDBM50313680",
    ],
  },
  {
    primary_id: "D03OEN",
    names: ["N-Butyl-2-(5-Nitro-1H-Indol-3-Yl)-2-Oxoacetamide", "CHEMBL222316"],
  },
  {
    primary_id: "D03OFA",
    names: ["Ro-09-1428"],
  },
  {
    primary_id: "D03OFF",
    names: [
      "Pioglitazone",
      "111025-46-8",
      "Actos",
      "Pioglitazona",
      "Pioglitazonum",
      "Glustin",
      "Zactos",
      "105355-27-9",
      "Pioglitazonum [INN-Latin]",
      "Pioglitazona [INN-Spanish]",
      "Duetact",
      "Pioglitazone [INN:BAN]",
      "Pioglitazone [BAN:INN]",
      "5-(4-(2-(5-Ethylpyridin-2-Yl)Ethoxy)Benzyl)Thiazolidine-2,4-Dione",
      "AD-4833",
      "U 72107",
      "CHEBI:8228",
      "Pioglitazone (Actos)",
      "HSDB 7322",
      "Actos (TN)",
      "5-{4-[2-(5-Ethylpyridin-2-Yl)Ethoxy]benzyl}-1,3-Thiazolidine-2,4-Dione",
      "C19H20N2O3S",
      "AD 4833",
      "5-[4-[2-(5-ETHYL-2-PYRIDYL)ETHOXY]BENZYL]-2,4-THIAZOLIDINEDIONE",
      "U 72107A",
      "Actost",
      "Glustin (TN)",
      "HS-0047",
      "Pioglitazone (INN)",
      "U-72107",
      "U72,107A",
      "Zactos (TN)",
      "(+-)-5-((4-(2-(5-Ethyl-2-Pyridinyl)Ethoxy)Phenyl)Methyl)-2,4-Thiazolidinedione",
      "(+/-)-5-[[4-[2-(5-Ethyl-2-Pyridinyl)-Ethoxy]phenyl]methyl]-2,4-Thiazolidinedione",
      "(+/-)-5-[p-[2-(Ethyl-2-Pyridyl)Ethoxy]benzyl]-2,4-Thiazolidinedione",
      "2,4-Thiazolidinedione, 5-[[4-[2-(5-Ethyl-2-Pyridinyl)Ethoxy]phenyl]methyl]-(9CI)",
      "5-((4-(2-(5-Ethyl-2-Pyridinyl)Ethoxy)Phenyl)Methyl)-2,4-Thiazolidinedione",
      "5-(4-(2-(5-Ethyl-2-Pyridyl)Ethoxy)Benzyl)-2,4-Thiazolidinedione",
      "5-[4-[2-(5-Ethyl-2-Pyridyl)Ethoxy]benzyl]thiazolidine-2,4-Dione",
      "5-[[4-[2-(5-Ethyl-2-Pyridinyl)Ethoxy]phenyl]methyl]-2,4-Thiazolidinedione",
      "5-[[4-[2-(5-Ethylpyridin-2-Yl)Ethoxy]phenyl]methyl]-1,3-Thiazolidine-2,4-Dione",
      "5-[[4-[2-(5-Ethylpyridin-2-Yl)Ethoxy]phenyl]methyl]thiazolidine-2,4-Dione",
      "5-[[4-[2-[(5-Ethyl-2-Pyridyl)]ethoxy]phenyl]methyl]thiazolidine-2,4-Dione",
      "Linagliptin + Pioglitazone",
      "PCG1",
    ],
  },
  {
    primary_id: "D03OFN",
    names: ["NK-314"],
  },
  {
    primary_id: "D03OFO",
    names: ["L-768673"],
  },
  {
    primary_id: "D03OGA",
    names: ["2-Methoxy-Alpha,beta-Didehydro-Agomelatine", "CHEMBL2314259", "GTPL7775", "BDBM50425202"],
  },
  {
    primary_id: "D03OGC",
    names: ["ISIS 114388"],
  },
  {
    primary_id: "D03OGD",
    names: [
      "Methyl 2-(7-Phenylheptanoyl)Oxazole-4-Carboxylate",
      "CHEMBL461539",
      "SCHEMBL2164164",
      "CRHSFDPXTRFSMK-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D03OGO",
    names: [
      "Cyclosal-D4TMP",
      "CycloSal-D4TMP",
      "Cycosal-D4TMP",
      "CHEMBL375767",
      "AC1LABYX",
      "BDBM50206637",
      "5-Methyl-1-[(2R,5S)-5-[(2-Oxo-4H-1,3,2",
      "5-Methyl-1-{5-[(2-Oxo(4H-Benzo[e]1,3,2-Dioxaphosphan-2-Yloxy))Methyl](2-2,5-Dihydrofuryl)}-1,3-Dihydropyrimidine-2,4-Dione",
      "5-Methyl-1-[(2R,5S)-5-[(2-Oxo-4H-1,3,2$l^{5}-Benzodioxaphosphinin-2-Yl)Oxymethyl]-2,5-Dihydrofuran-2-Yl]pyrimidine-2,4-Dione",
      "5-Methyl-1-[(2R,5R)-5-(2-Oxo-4H-2lambda5-Benzo[1,3,2]dioxaphosphinin-2-Yloxy)-2,5-Dihydro-Furan-2-Yl]-1H-Pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D03OGQ",
    names: ["AZD-7009", "AR-H065522XX", "AZD-7009 (Iv Formulation, Atrial Fibrillation Conversion)"],
  },
  {
    primary_id: "D03OGR",
    names: ["Alzheimers Disease Therapeutics"],
  },
  {
    primary_id: "D03OIA",
    names: ["Macrocycle Derivative 14"],
  },
  {
    primary_id: "D03OIO",
    names: ["Anti-Mesothelin CAR-T Cells"],
  },
  {
    primary_id: "D03OIW",
    names: ["Kinetin"],
  },
  {
    primary_id: "D03OKG",
    names: ["FK-739"],
  },
  {
    primary_id: "D03OKS",
    names: ["DC-IL-12 DNA Therapeutic"],
  },
  {
    primary_id: "D03OKV",
    names: [
      "BMS-279700",
      "UNII-8FX9DP2Y1W",
      "8FX9DP2Y1W",
      "CHEMBL189338",
      "240814-54-4",
      "SCHEMBL4036209",
      "BDBM50151366",
      "Imidazo(1,5-A)Pyrido(3,2-E)Pyrazin-6-Amine, N-(2-Chloro-6-Methylphenyl)-2-((3S)-3-Methyl-1-Piperazinyl)-",
    ],
  },
  {
    primary_id: "D03OLA",
    names: ["L-745631", "CHEMBL103575", "SCHEMBL16111943", "BDBM50059846"],
  },
  {
    primary_id: "D03OLR",
    names: ["PMID23374867C24"],
  },
  {
    primary_id: "D03OLU",
    names: ["RN1734"],
  },
  {
    primary_id: "D03OLX",
    names: [
      "5'-Deoxy-5'-(N,N-Dimethylamino)Adenosine",
      "5'-Deoxy-5'-(Dimethylamino)Adenosine",
      "SCHEMBL952928",
      "CHEMBL472733",
      "AdoMet Substrate Analogue, 23b",
      "BDBM28453",
      "SLNWRDWGFHZRAQ-WOUKDFQISA-N",
      "ZINC40914070",
      "(2R,3R,4S,5R)-2-(6-Amino-9H-Purin-9-Yl)-5-[(Dimethylamino)Methyl]oxolane-3,4-Diol",
    ],
  },
  {
    primary_id: "D03OMK",
    names: ["DM-107"],
  },
  {
    primary_id: "D03OMZ",
    names: ["Fulvestrant Intraductal Microcatheter Delivery"],
  },
  {
    primary_id: "D03ONE",
    names: ["BL-6010", "EDP-01"],
  },
  {
    primary_id: "D03ONX",
    names: ["CL-H02"],
  },
  {
    primary_id: "D03ONZ",
    names: ["MCS-18"],
  },
  {
    primary_id: "D03OPA",
    names: ["HCV Polymerase Inhibitors"],
  },
  {
    primary_id: "D03OPE",
    names: ["CL-284027"],
  },
  {
    primary_id: "D03OPW",
    names: ["WK-X-34"],
  },
  {
    primary_id: "D03OQG",
    names: ["1-Methyl-5-Oxa-Spiro[2.4]heptan-4-One"],
  },
  {
    primary_id: "D03OQI",
    names: ["Tozadenant"],
  },
  {
    primary_id: "D03OQK",
    names: ["NSC-380292"],
  },
  {
    primary_id: "D03ORX",
    names: ["Dihydropyridine Compound 6"],
  },
  {
    primary_id: "D03OSE",
    names: ["GVAX Multiple"],
  },
  {
    primary_id: "D03OSG",
    names: ["MBX-8025"],
  },
  {
    primary_id: "D03OSO",
    names: ["HT-61"],
  },
  {
    primary_id: "D03OTH",
    names: ["Belagenpumatucel-L"],
  },
  {
    primary_id: "D03OTX",
    names: ["PSP-D-GITRL"],
  },
  {
    primary_id: "D03OUU",
    names: ["6-(2-Amino-Ethyl)-Biphenyl-2,3,4'-Triol", "CHEMBL57470", "ZINC29557762", "BDBM50025207"],
  },
  {
    primary_id: "D03OVA",
    names: ["8-Chloro-Adenosine"],
  },
  {
    primary_id: "D03OVH",
    names: ["ISCOMATRIX"],
  },
  {
    primary_id: "D03OVN",
    names: ["ReN-003"],
  },
  {
    primary_id: "D03OXL",
    names: ["S-(N-Propyl-N-Hydroxycarbamoyl)Glutathione", "CHEMBL129597", "SCHEMBL3286977", "BDBM50092829"],
  },
  {
    primary_id: "D03OYD",
    names: [
      "13,14-Dihydro-16-M-Chlorophenoxy-W-Tetranor-PGF1alpha",
      "CHEMBL36041",
      "7-[(1R,2R,3R,5S)-2-[(3R)-4-(3-Chlorophenoxy)-3-Hydroxybutyl]-3,5-Dihydroxycyclopentyl]heptanoic Acid",
      "AC1NSKAB",
      "GTPL1955",
      "13,14-Dihydro-16-M-Chlorophenoxy-Omega-Tetranor PGF-1alpha",
      "BDBM50085911",
      "7-{2-[4-(3-Chloro-Phenoxy)-3-Hydroxy-Butyl]-3,5-Dihydroxy-Cyclopentyl}-Heptanoic Acid",
      "7-[(1R)-2beta-[(3R)-3-Hydroxy-4-(3-Chlorophenoxy)Butyl]-3alpha,5alpha-Dihydroxycyclopentane-1alpha-Yl]heptanoic Acid",
    ],
  },
  {
    primary_id: "D03OYJ",
    names: ["PMID26882240-Compound-32"],
  },
  {
    primary_id: "D03OYL",
    names: ["N-Hydroxy-7-(Naphthalen-2-Yloxy)Heptanamide", "CHEMBL217083"],
  },
  {
    primary_id: "D03OYY",
    names: ["MAR-531"],
  },
  {
    primary_id: "D03OZD",
    names: ["(S)-Crizotinib"],
  },
  {
    primary_id: "D03PAH",
    names: [
      "1-(2-Methoxy-Phenyl)-4-Propyl-Piperazine",
      "1-(2-Methoxyphenyl)-4-Propylpiperazine",
      "CHEMBL92887",
      "AC1L8ZT0",
      "Oprea1_562540",
      "SCHEMBL3261221",
      "MolPort-009-059-633",
      "ZINC1663859",
      "BDBM50039783",
      "AKOS034319760",
      "MCULE-3739556252",
      "Z432755564",
    ],
  },
  {
    primary_id: "D03PBE",
    names: ["ERK Inhibitors"],
  },
  {
    primary_id: "D03PBI",
    names: ["Pyrrolo-Pyridone Derivative 2"],
  },
  {
    primary_id: "D03PBL",
    names: [
      "PF-08",
      "Amphetamine Prodrug (Tamper And Abuse Resistant, Bio-MD, ADHD), PharmacoFore",
      "PF-08 (Tamper And Abuse-Resistant/Bio-MD/Prodrug, ADHD)",
      "PF-08 (Tamper And Abuse-Resistant/Bio-MD/Prodrug, ADHD), PharmacoFore",
    ],
  },
  {
    primary_id: "D03PBN",
    names: ["Tetrahydrodeoxycorticosterone"],
  },
  {
    primary_id: "D03PBT",
    names: ["DuP-983"],
  },
  {
    primary_id: "D03PBW",
    names: ["PG-760564"],
  },
  {
    primary_id: "D03PCJ",
    names: ["MPI-461359"],
  },
  {
    primary_id: "D03PCP",
    names: ["NCT-400"],
  },
  {
    primary_id: "D03PCV",
    names: ["ISIS 133207"],
  },
  {
    primary_id: "D03PDY",
    names: [
      "SB-332235",
      "1-(4-Chloro-2-Hydroxy-3-Sulfamoyl-Phenyl)-3-(2,3-Dichlorophenyl)Urea",
      "276702-15-9",
      "SB 332235",
      "UNII-5HLP8UVL8M",
      "5HLP8UVL8M",
      "CHEMBL3819292",
      "WTLRWOHEKQGKDS-UHFFFAOYSA-N",
      "SCHEMBL1535901",
      "Sb332235",
      "MolPort-042-624-550",
      "ZINC3990011",
      "BDBM50182254",
      "AKOS027470251",
      "N-(4-Chloro-2hydroxy-3-Aminosulfonylphenyl)-N'-(2,3-Dichlorophenyl) Urea",
      "N-(4-Chloro-2-Hydroxy-3-Aminosulfonylphenyl)-N'-(2,3-Dichlorophenyl)Urea",
      "N-(4-Chloro-2-Hydroxy-3-Aminosulfonylphenyl)-N'-(2,3-Dichlorophenyl) Urea",
      "N-(3-(Aminosulfonyl)-4-Chloro-2-Hydroxyphenyl)-N'-(2,3-Dichlorophenyl) Urea",
    ],
  },
  {
    primary_id: "D03PDZ",
    names: ["D1 Agonist D2 Antagonist"],
  },
  {
    primary_id: "D03PEA",
    names: ["NF546"],
  },
  {
    primary_id: "D03PEF",
    names: ["Hedgehog Labelled Stem Cells"],
  },
  {
    primary_id: "D03PFD",
    names: ["Vaccines, Immunobody, Colorectal Cancer"],
  },
  {
    primary_id: "D03PGE",
    names: ["PG-2"],
  },
  {
    primary_id: "D03PGF",
    names: ["RPR-118723"],
  },
  {
    primary_id: "D03PGL",
    names: ["PMID20638279C11"],
  },
  {
    primary_id: "D03PGS",
    names: ["IONIS-TTRRX"],
  },
  {
    primary_id: "D03PGV",
    names: ["Phenylglycine-01"],
  },
  {
    primary_id: "D03PHU",
    names: ["Tetrazolast Meglumine"],
  },
  {
    primary_id: "D03PIA",
    names: ["CB-183315"],
  },
  {
    primary_id: "D03PIB",
    names: [
      "Alrestatin",
      "51411-04-2",
      "Alrestatine",
      "Alrestatin [INN]",
      "Alrestatinum [INN-Latin]",
      "Alrestatine [INN-French]",
      "Alrestatino [INN-Spanish]",
      "UNII-515DHK15LG",
      "Spectrum_001449",
      "Tocris-0485",
      "(1,3-Dioxo-1H,3H-Benzo[de]isoquinolin-2-Yl)-Acetic Acid",
      "(1,3-Dioxo-1H-Benzo[de]isoquinolin-2(3H)-Yl)Acetic Acid",
      "NSC 299132",
      "BRN 0244370",
      "AY 22284",
      "CHEMBL63055",
      "515DHK15LG",
      "NSC299132",
      "1,3-Dioxo-1H-Benz(De)Isoquinoline-2(3H)-Acetic Acid",
      "1H-Benz[de]isoquinoline-2(3H)-Aceticacid, 1,3-Dioxo-",
      "NCGC00024613-01",
    ],
  },
  {
    primary_id: "D03PIF",
    names: ["Salvinorin B Tetrahydropyran-2-Yl Ether", "CHEMBL403137"],
  },
  {
    primary_id: "D03PIP",
    names: ["1,6-Bis(4-(3-Methoxyphenyl)Piperazin-1-Yl)Hexane", "CHEMBL1172206", "SCHEMBL4525293"],
  },
  {
    primary_id: "D03PIR",
    names: [
      "Ceftezole",
      "Ceftezole Sodium",
      "41136-22-5",
      "Falomesin",
      "Celoslin",
      "Alomen",
      "UNII-3NHZ4Y117H",
      "3NHZ4Y117H",
      "Ceftezole Sodium [JAN]",
      "Ceftezole Sodium (JAN)",
      "DSSTox_CID_28520",
      "DSSTox_RID_82792",
      "DSSTox_GSID_48594",
      "Sodium (6R,7R)-3-(((1,3,4-Thiadiazol-2-Yl)Thio)Methyl)-7-(2-(1H-Tetrazol-1-Yl)Acetamido)-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 8-Oxo-7-((1H-Tetrazol-1-Ylacetyl)Amino)-3-((1,3,4-Thiadiazol-2-Ylthio)Methyl)-, Monosodium Salt, (6R-Trans)-",
      "CAS-41136-22-5",
    ],
  },
  {
    primary_id: "D03PJO",
    names: ["Diclosan"],
  },
  {
    primary_id: "D03PJP",
    names: ["Trenonacog Alfa"],
  },
  {
    primary_id: "D03PJU",
    names: [
      "Carfentanil",
      "CARFENTANIL",
      "Carfentanyl",
      "Carfentanila",
      "Carfentanilum",
      "Wildnil",
      "59708-52-0",
      "Carfentanil [INN]",
      "Carfentanila [Spanish]",
      "UNII-LA9DTA2L8F",
      "Carfentanilum [INN-Latin]",
      "Carfentanila [INN-Spanish]",
      "Methyl 1-Phenylethyl-4-(N-Phenylpropionamido)Isonipecotate",
      "CHEBI:61084",
      "Methyl 4-(N-Propionyl-N-Phenylamino)-1-(2-Phenylethyl)-4-Piperidine-Carboxylate",
      "Methyl 4-(N-(1-Oxopropyl)-N-Phenylamino)-1-(2-Phenylethyl)-4-Piperidinecarboxylate",
      "4-((1-Oxopropyl)Phenylamino)-1-(2-Phenylethyl)-4-Piperidinecarboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D03PJW",
    names: ["RU 24969"],
  },
  {
    primary_id: "D03PKI",
    names: ["Geldanamycin"],
  },
  {
    primary_id: "D03PKJ",
    names: ["MK-6186"],
  },
  {
    primary_id: "D03PKL",
    names: ["GSK933776"],
  },
  {
    primary_id: "D03PLH",
    names: [
      "U46619",
      "(Z)-7-[(1S,4R,5R,6S)-5-[(E,3S)-3-Hydroxyoct-1-Enyl]-3-Oxabicyclo[221]heptan-6-Yl]hept-5-Enoic Acid",
      "U 46619",
      "MLS000028857",
      "CHEMBL521784",
      "U-46619",
      "56985-40-1",
      "SMR000058897",
      "(5Z)-7-[(1R,4S,5S,6R)-6-[(1E,3S)-3-Hydroxy-1-Octenyl]-2-Oxabicyclo[221]hept-5-Yl]-5-Heptenoic Acid",
      "9a,11a-Methanoepoxy-15-Hydroxyprosta-5,13-Dienoic Acid",
      "9,11-Methanoepoxy PGH2",
      "11alpha,9alpha-Epoxymethano-PGH2",
      "9alpha-PGM",
      "9alpha,11alpha-Methanoepoxy-PGF2alpha",
      "Opera_ID_380",
      "[3H]U46619",
      "9,11-Dideoxy-9alpha,11alpha-Methanoepoxy PGF2alpha",
    ],
  },
  {
    primary_id: "D03PLP",
    names: ["[1,1':2',1'']-Terphenyl-4,3'',5''-Triol", "CHEMBL208098", "BDBM50186757", "ZINC35856323"],
  },
  {
    primary_id: "D03PLQ",
    names: ["Benzothiazole Analog 3"],
  },
  {
    primary_id: "D03PLR",
    names: ["RemuneX"],
  },
  {
    primary_id: "D03PLV",
    names: [
      "1-Deaza-Adenosine",
      "1-Deazaadenosine",
      "14432-09-8",
      "CHEMBL115824",
      "1DA",
      "1add",
      "1-Deaza-A",
      "AC1L4ME8",
      "AC1Q4XY1",
      "Adenosine-N,1,3-15N3",
      "SCHEMBL18490353",
      "A-D-Ribofuranosyl)-3h-Imidazo[4,5-B]pyridin-7-Amine",
      "MolPort-023-278-902",
      "3H-Imidazo(4,5-B)Pyridin-7-Amine, 3-Beta-D-Ribofuranosyl-",
      "ZINC3814313",
      "PDSP1_001078",
      "BDBM50060165",
      "PDSP2_001062",
      "AKOS024458188",
      "DB04218",
      "NCGC00163308-01",
      "3-?-D-Ribofuranosyl-3H-Imidazo[4,5-B]pyridin-7-Amine",
      "3-Beta-D-Ribofuranosyl-3H-Imidazo[4,5-B]pyridin-7-Amine",
      "3H-Imidazo[4,5-B]Pyridine, 7-Amino-3-Ss-D-Ribof",
    ],
  },
  {
    primary_id: "D03PMB",
    names: ["PTI-601"],
  },
  {
    primary_id: "D03POE",
    names: [
      "2-[(2,2-Diphenylacetyl)Amino]benzoic Acid",
      "CHEMBL196755",
      "2-[(Diphenylacetyl)Amino]benzoic Acid",
      "Oprea1_593830",
      "Oprea1_339985",
      "MLS001143352",
      "AC1N2C22",
      "MolPort-001-015-645",
      "HMS2781B19",
      "ZINC3466236",
      "BDBM50174204",
      "STK004896",
      "AKOS003244646",
      "MCULE-3235692188",
      "2-(2,2-Diphenylacetylamino)Benzoic Acid",
      "SMR000473187",
    ],
  },
  {
    primary_id: "D03POP",
    names: ["N6-Methoxy-2-Phenylethynyladenosine", "CHEMBL441707"],
  },
  {
    primary_id: "D03POU",
    names: [
      "8-(3,4-Dimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL102518",
      "AC1LAR99",
      "CTK7A7284",
      "BDBM50059936",
      "2,6-Diamino-8-(3',4'-Dimethoxyphenyl)Purine",
      "8-(3,4-Dimethoxyphenyl)-3h-Purine-2,6-Diamine",
      "8-(3,4-Dimethoxyphenyl)-7H-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D03POY",
    names: ["ISIS-AR"],
  },
  {
    primary_id: "D03PPZ",
    names: ["Beta-Carboline-3-Carboxylic Acid T-Butyl Ester"],
  },
  {
    primary_id: "D03PQB",
    names: ["Imidazoleisoindoles Derivative 1"],
  },
  {
    primary_id: "D03PQH",
    names: ["3-Hydroxy-N,N,N-Trimethylbenzenaminium Iodide"],
  },
  {
    primary_id: "D03PQS",
    names: ["PMID29338548-Compound-38"],
  },
  {
    primary_id: "D03PQW",
    names: ["BAY 79-4620"],
  },
  {
    primary_id: "D03PRA",
    names: ["Heteroaryl-Pyrazole Derivative 2"],
  },
  {
    primary_id: "D03PRC",
    names: ["K562/GM-CSF"],
  },
  {
    primary_id: "D03PRG",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Ph5-D-Pro-)", "CHEMBL391384"],
  },
  {
    primary_id: "D03PRM",
    names: ["NST-141"],
  },
  {
    primary_id: "D03PRT",
    names: ["PMID26004420-Compound-WO2014015495A"],
  },
  {
    primary_id: "D03PRV",
    names: ["BGC-20-1259"],
  },
  {
    primary_id: "D03PSA",
    names: ["1,2,4-Oxadiazole Derivative 2"],
  },
  {
    primary_id: "D03PSP",
    names: ["Panobacumab"],
  },
  {
    primary_id: "D03PSY",
    names: [
      "3 9-DIHYDRO-N-DESMETHYL-N-ISOPROPYLERYTHROMYCIN A",
      "CHEMBL584549",
      "3 9-Dihydro-N-Desmethyl-N-Isopropylerythromycin A",
      "SCHEMBL13681692",
    ],
  },
  {
    primary_id: "D03PTG",
    names: ["PMID25980951-Compound-42"],
  },
  {
    primary_id: "D03PTH",
    names: ["Pyrantel"],
  },
  {
    primary_id: "D03PTV",
    names: ["1-Benzhydryl-4-Ethoxy-4-Phenylpiperidine", "CHEMBL229016"],
  },
  {
    primary_id: "D03PTY",
    names: ["PMID27607364-Compound-64"],
  },
  {
    primary_id: "D03PTZ",
    names: [
      "BTS-73947",
      "BTS-72428 (HBr Salt)",
      "(+)-1-[1-(2-Chlorophenyl)Cyclopropyl]-7-Hydroxy-6-Methoxy-2-Methyl-1,2,3,4-Tetrahydroisoquinoline",
    ],
  },
  {
    primary_id: "D03PUG",
    names: ["Revax-TBE"],
  },
  {
    primary_id: "D03PVF",
    names: [
      "2-(4-Tosylpiperazin-1-Yl)Nicotinonitrile",
      "Arylsulfonylpiperazine, 27",
      "CHEMBL404148",
      "BDBM32555",
      "MolPort-005-568-142",
      "ZINC23996035",
      "AKOS029603555",
      "MCULE-2636718919",
      "AB00772161-01",
      "Z31097483",
      "2-[4-(4-Methylbenzenesulfonyl)Piperazin-1-Yl]pyridine-3-Carbonitrile",
    ],
  },
  {
    primary_id: "D03PVM",
    names: ["1-Cyclohexyl-1-Isopropyl-3,3-Dimethylselenourea", "CHEMBL399930"],
  },
  {
    primary_id: "D03PVQ",
    names: ["EDP-420"],
  },
  {
    primary_id: "D03PVS",
    names: [
      "3-Amino-Benzo[b]thiophene-2-Carboxylic Acid Amide",
      "3-Amino-1-Benzothiophene-2-Carboxamide",
      "37839-59-1",
      "3-Aminobenzo[b]thiophene-2-Carboxamide",
      "CHEMBL211002",
      "Benzo[b]thiophene-2-Carboxamide, 3-Amino-",
      "3-Aminobenzo[b]thiophene-2-Carboxylic Acid Amide",
      "AC1LGIJV",
      "SCHEMBL6609258",
      "CTK1B5350",
      "DTXSID80356350",
      "MolPort-002-798-643",
      "ZINC339150",
      "STL134701",
      "BDBM50186634",
      "AKOS001782516",
      "CCG-127335",
      "MCULE-9206749194",
      "NE38914",
      "EN300-88534",
    ],
  },
  {
    primary_id: "D03PVV",
    names: ["1-Palmitoyl-Lysophosphatidylcholine"],
  },
  {
    primary_id: "D03PVY",
    names: ["GlycoPEGylated Erythropoietin"],
  },
  {
    primary_id: "D03PWD",
    names: ["GMI-1271"],
  },
  {
    primary_id: "D03PWU",
    names: ["NSC-138419"],
  },
  {
    primary_id: "D03PXS",
    names: [
      "2-Oxo-N-Phenyl-2H-Chromene-3-Carboxamide",
      "NSC375104",
      "2-Oxo-N-Phenylchromene-3-Carboxamide",
      "54396-25-7",
      "AC1L7UQ9",
      "Oprea1_247443",
      "Oprea1_472798",
      "CBDivE_011982",
      "JMC521935 Compound 6",
      "SCHEMBL831792",
      "IFLab1_001129",
      "CHEMBL494795",
      "NIOSH/DJ2267500",
      "Coumarin, 3-(Anilinocarbonyl)-",
      "BDBM23543",
      "ZINC42178",
      "DTXSID80321427",
      "MolPort-000-375-218",
      "HMS1415D07",
      "(2-Oxochromen-3-Yl)-N-Benzamide",
      "3-Carboxamide-Coumarin Deriv., 18",
      "STK337557",
      "AKOS000511884",
      "2-Oxo-N-Phenyl-Chromene-3-Carboxamide",
      "NSC-375104",
      "MCULE-7275389335",
      "IDI1_008996",
    ],
  },
  {
    primary_id: "D03PXT",
    names: ["Altastaph"],
  },
  {
    primary_id: "D03PYA",
    names: ["Cyclohexyl Carbamate Derivative 3"],
  },
  {
    primary_id: "D03PYD",
    names: ["K-604", "BDBM86691"],
  },
  {
    primary_id: "D03PYO",
    names: ["PROMACTA"],
  },
  {
    primary_id: "D03PYX",
    names: [
      "[3H]7-OH-DPAT",
      "7-(Dipropylamino)-5,6,7,8-Tetrahydronaphthalen-2-Ol",
      "7-OH-DPAT",
      "7-Ohdpat",
      "DP-7-AT",
      "7-Hydroxy-N,N-Di-N-Propyl-2-Aminotetralin",
      "7-Hydroxy-2-N,N-Dipropylaminotetralin",
      "N,N-Di-N-Propyl-7-Hydroxy-2-Aminotetralin",
      "CHEMBL285755",
      "7-(Dipropylamino)-5,6,7,8-Tetrahydro-2-Naphthalenol",
      "2-Naphthalenol, 7-(Dipropylamino)-5,6,7,8-Tetrahydro-",
      "7-Hydroxy-DPAT Hydrobromide",
      "(+)-7-(Dipropylamino)-5,6,7,8-Tetrahydronaphthalen-2-Ol Hydrobromide",
      "[3H]-7-OH-DPAT",
      "(+)7-OH-DPAT",
      "AC1Q7AGR",
      "AC1L1AZK",
      "(+/-)-7-Hydroxy-2-(N,N-Di-N-Propylamino)Tetralin",
    ],
  },
  {
    primary_id: "D03PZX",
    names: ["2-Ethyl-3-(4-Hydroxy) Benzoyl Benzofuran Derivative 1"],
  },
  {
    primary_id: "D03PZY",
    names: ["PHER-O2"],
  },
  {
    primary_id: "D03PZZ",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 7"],
  },
  {
    primary_id: "D03QAN",
    names: [
      "N,N'-Bis(4-Amino-2-Methylquinolin-6-Yl)Urea",
      "Aminoquinuride",
      "Surfen",
      "Aminokinuride",
      "UNII-08T7936572",
      "3811-56-1",
      "N,N'-BIS(4-AMINO-2-METHYLQUINOLIN-6-YL)UREA",
      "1,3-Bis(4-Amino-2-Methylquinolin-6-Yl)Urea",
      "N,N'-Bis(4-Amino-2-Methyl-6-Quinolinyl)Urea",
      "Aminochinurid",
      "08T7936572",
      "Aminochinuridum",
      "Aminoquinuridum",
      "Aminoquinurida",
      "Aminokinuridum",
      "Aminochincarbamidum",
      "Aminoquin Carbamide",
      "NSC12155",
      "Aminoquinuride [INN]",
      "NCGC00013130-04",
      "NCGC00013130-03",
      "5424-37-3",
      "Aminoquinuridum [INN-Latin]",
      "Aminoquinurida [INN-Spanish]",
      "1pwp",
      "N,N-Bis(4-Aminochinaldyl-6)Harnstoff",
    ],
  },
  {
    primary_id: "D03QAO",
    names: ["AZD-9742"],
  },
  {
    primary_id: "D03QAQ",
    names: [
      "SN429",
      "CHEMBL37251",
      "SN-429",
      "209954-52-9",
      "1H-Pyrazole-5-Carboxamide,1-[3-(Aminoiminomethyl)Phenyl]-N-[2'-(Aminosulfonyl)[1,1'-Biphenyl]-4-Yl]-3-Methyl-",
      "SCHEMBL14534861",
      "BDBM12751",
      "ZINC2006270",
      "KB-65315",
    ],
  },
  {
    primary_id: "D03QAS",
    names: [
      "E-758",
      "E-759",
      "E-761",
      "Lycobetaine Analogs (Cancer)",
      "Lycobetaine Analogs (Cancer), Oncotest",
      "Topoisomerase Inhibitors (Cancer), Oncotest",
    ],
  },
  {
    primary_id: "D03QAU",
    names: ["PMID25726713-Compound-42"],
  },
  {
    primary_id: "D03QAW",
    names: ["PMID25666693-Compound-61"],
  },
  {
    primary_id: "D03QAY",
    names: ["Pyrazolopyridine Derivative 3"],
  },
  {
    primary_id: "D03QBL",
    names: ["Autologous Idiotypic Cancer Vaccin"],
  },
  {
    primary_id: "D03QBS",
    names: ["Furazolidinone"],
  },
  {
    primary_id: "D03QBT",
    names: ["Golnerminogene Pradenovac"],
  },
  {
    primary_id: "D03QBY",
    names: ["Anavip", "Antivenom (Poison Intoxication), Rare Theapeutics/Instituto Bioclon"],
  },
  {
    primary_id: "D03QCW",
    names: ["Interferon Alfa-N1"],
  },
  {
    primary_id: "D03QDS",
    names: ["2-(4-Hydroxystyryl)Quinolin-8-Ol"],
  },
  {
    primary_id: "D03QEB",
    names: ["VGX-1027"],
  },
  {
    primary_id: "D03QEC",
    names: ["ASP4131"],
  },
  {
    primary_id: "D03QFD",
    names: ["AR234960", "AR 234960", "AR-234960"],
  },
  {
    primary_id: "D03QFN",
    names: ["Patrome"],
  },
  {
    primary_id: "D03QFO",
    names: ["SOR-N43"],
  },
  {
    primary_id: "D03QFY",
    names: ["PPLs"],
  },
  {
    primary_id: "D03QGB",
    names: [
      "JNJ-5207852",
      "398473-34-2",
      "UNII-4I9OVB1G7D",
      "JNJ 5207852",
      "1-[4-(3-Piperidinopropoxy)Benzyl]piperidine",
      "4I9OVB1G7D",
      "CHEMBL129542",
      "JNJ 5207852 Dihydrochloride",
      "1-[3-[4-(Piperidin-1-Ylmethyl)Phenoxy]propyl]piperidine",
      "Piperidine, 1-(3-(4-(1-Piperidinylmethyl)Phenoxy)Propyl)-",
      "1-(3-(4-((Piperidin-1-Yl)Methyl)Phenoxy)Propyl)Piperidine",
      "1-(3-(4-(Piperidin-1-Ylmethyl)Phenoxy)Propyl)Piperidine",
      "Piperidine, 1-[3-[4-(1-Piperidinylmethyl)Phenoxy]propyl]-",
      "1-(3-{4-[(Piperidin-1-Yl)Methyl]phenoxy}propyl)Piperidine",
      "AC1MCCJO",
    ],
  },
  {
    primary_id: "D03QGH",
    names: ["Tihoacetate Derivative 1"],
  },
  {
    primary_id: "D03QGL",
    names: [
      "DALTROBAN",
      "79094-20-5",
      "BM-13505",
      "Daltrobanum",
      "SKF 96148",
      "BM 13505",
      "UNII-S25VDY08ZC",
      "BM 13.505",
      "C16H16ClNO4S",
      "SKF-96148",
      "F 96148",
      "S25VDY08ZC",
      "CHEMBL71685",
      "4-(2-(4-Chlorophenylsulfonylamino)Ethyl)Phenylacetic Acid",
      "4-(2-(4-Chlorobenzenesulfonylamino)Ethyl)Benzeneacetic Acid",
      "F-96148",
      "NCGC00165787-02",
      "2-(4-(2-(4-Chlorophenylsulfonamido)Ethyl)Phenyl)Acetic Acid",
      "2-[4-[2-[(4-Chlorophenyl)Sulfonylamino]ethyl]phenyl]acetic Acid",
      "DSSTox_RID_81670",
      "DSSTox_CID_26501",
      "DSSTox_GSID_46501",
      "105218-03-9",
      "Benzeneacetic Acid, 4-(2-((",
    ],
  },
  {
    primary_id: "D03QGM",
    names: [
      "Sulpiride",
      "Abilit",
      "Aiglonyl",
      "Alimoral",
      "Arminol",
      "Calmoflorine",
      "Championyl",
      "Coolspan",
      "Darleton",
      "Deponerton",
      "Desisulpid",
      "Desmenat",
      "Digton",
      "Dobren",
      "Dogmatil",
      "Dogmatyl",
      "Dolmatil",
      "Dresent",
      "Eglonil",
      "Eglonyl",
      "Ekilid",
      "Enimon",
      "Equilid",
      "Eusulpid",
      "Fardalan",
      "Fidelan",
      "Guastil",
      "Isnamide",
      "Kylistro",
      "Lebopride",
      "Levobren",
      "Levopraid",
      "Levosulpirida",
      "Levosulpiridum",
      "Lisopiride",
      "Mariastel",
      "Meresa",
      "Miradol",
      "Mirbanil",
      "Misulvan",
      "Neogama",
      "Norestran",
      "Normum",
      "Nufarol",
      "Omiryl",
      "Omperan",
      "Ozoderpin",
      "Pontiride",
      "Psicocen",
      "Pyrikappl",
      "Pyrkappl",
      "Restful",
      "Sernevin",
      "Splotin",
      "Stamonevrol",
      "Sulp",
      "Sulperide",
      "Sulpirid",
      "Sulpirida",
      "Sulpiridum",
      "Sulpitil",
      "Sulpivert",
      "Sulpor",
      "Sulpride",
      "Sulpyrid",
      "Suprium",
      "Sursumid",
      "Synedil",
      "Tepavil",
      "Valirem",
      "Zemorcon",
      "Allphar Brand Of Sulpiride",
      "Almirall Brand Of Sulpiride",
      "Areu Brand Of Sulpiride",
      "Centrum Brand Of Sulpiride",
      "Desitin Brand Of Sulpiride",
      "Dolorgiet Brand Of Sulpiride",
      "Erempharma Brand Of Sulpiride",
      "Fumouzer Brand Of Sulpiride",
      "Hennig Brand Of Sulpiride",
      "Hexal Brand Of Sulpiride",
      "Hoechst Brand Of Sulpiride",
      "Hormosan Brand Of Sulpiride",
      "Krewel Brand Of Sulpiride",
      "Levosulpiride [INN]",
      "Magnetic Resonance Imaging Sulpiride",
      "Pharmacia Brand Of Sulpiride",
      "Psicofarma Brand Of Sulpiride",
      "Rosemont Brand Of Sulpiride",
      "Sanofi Synthelabo Brand Of Sulpiride",
      "Spyfarma Brand Of Sulpiride",
      "Uriach Brand Of Sulpiride",
      "Vertigo Meresa",
      "Vertigo Neogama",
      "RD 1403",
      "S 8010",
      "Bosnyl (TN)",
      "Dogmatil (TN)",
      "Dogmatyl (TN)",
      "Eglonyl (TN)",
      "Levosulpirida [INN-Spanish]",
      "Levosulpiridum [INN-Latin]",
      "Meresa (TN)",
      "Neuraxpharm (TN)",
      "RV-12309",
      "Ratiopharm (TN)",
      "Sanofi-Synthelabo Brand Of Sulpiride",
      "Sulpirid (TN)",
      "Sulpirida [INN-Spanish]",
      "Sulpiridum [INN-Latin]",
      "Sulpiryd (TN)",
      "Vertigo-Meresa",
      "Vertigo-Neogama",
      "R. D. 1403",
      "R.D. 1403",
      "Sulpiride (JP15/USAN/INN)",
      "Sulpiride [USAN:INN:BAN:JAN]",
      "N-((1-Ethyl-2-Pyrrolidinyl)Methyl)-5-Sulfamoyl-O-Anisamide",
      "(+/-)-Sulpiride",
      "(RS)-(+/-)-Sulpiride",
      "(Inverted Question Mark)-Sulpiride",
    ],
  },
  {
    primary_id: "D03QHC",
    names: ["KAF156"],
  },
  {
    primary_id: "D03QHG",
    names: ["8-(2,2-Dimethylpropyl)Naringenin"],
  },
  {
    primary_id: "D03QHI",
    names: ["Ro-43-8857"],
  },
  {
    primary_id: "D03QHM",
    names: ["JNJ-64041809"],
  },
  {
    primary_id: "D03QHR",
    names: ["GFC-036"],
  },
  {
    primary_id: "D03QIE",
    names: [
      "Bicifadine",
      "BICIFADINE",
      "71195-57-8",
      "1-(4-Methylphenyl)-3-Azabicyclo[3.1.0]hexane",
      "1-(P-Tolyl)-3-Azabicyclo[3.1.0]hexane",
      "1-P-Tolyl-3-Azabicyclo[3.1.0]hexane",
      "5-(4-Methylphenyl)-3-Azabicyclo[3.1.0]hexane",
      "3-Azabicyclo[3.1.0]hexane, 1-(4-Methylphenyl)-",
      "CL-220075",
      "PubChem18323",
      "AC1L2J2O",
      "SCHEMBL38793",
      "AZA001",
      "CHEMBL511099",
      "OFYVIGTWSQPCLF-UHFFFAOYSA-N",
      "NIH-9542",
      "MCV-4147",
      "BDBM50417944",
      "AKOS006271730",
      "DOV-220075",
      "AN-3206",
      "DB04889",
      "KB-13207",
      "5-(P-Tolyl)-3-Azabicyclo[3.1.0]hexane",
      "AM20041063",
      "FT-0718156",
      "1-(P-Tolyl)-3-Azabicyclo[3.1.0]-Hexane",
    ],
  },
  {
    primary_id: "D03QIP",
    names: [
      "Fluciclovine F-18",
      "18F-Ge-148",
      "18F Fluciclovine",
      "[18F]-FACBC",
      "Anti-1-Amino-3-18F-Fluorocyclobutane-1-Carboxylic-Acid",
      "Anti-1-Amino-3-F-18-Fluorocyclobutane-1-Carboxylic-Acid",
      "Anti-1-Amino-3-Fluorine-18-Fluorocyclobutane-1-Carboxylic Acid",
      "Anti-18-F-FACBC",
      "Anti-18F-FABC",
      "Anti-3-18F-FACBC",
      "Anti-F-18-Fluorocyclobutane-Carboxylic-Acid",
      "Axumin",
      "FACBC",
      "Fluciclovine-F-18",
      "GE-148",
      "Ge-148-18F",
      "Ge-148-F-18",
      "Trans-1-Amino-3-18F-Fluorocyclobutanecarboxylic-Acid",
    ],
  },
  {
    primary_id: "D03QJA",
    names: [
      "GSK-812397",
      "CXCR4 Receptor Antagonists (HIV-1 Infection)",
      "CXCR4 Receptor Antagonists (HIV-1 Infection), GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D03QJL",
    names: ["Ibudilast"],
  },
  {
    primary_id: "D03QJN",
    names: [
      "DPC 423",
      "DPC602",
      "228258-45-5",
      "DPC-423",
      "209957-47-1",
      "SCHEMBL3052253",
      "CHEMBL176140",
      "BDBM12657",
      "ZINC1490578",
      "1H-Pyrazole-5-Carboxamide,1-[3-(Aminomethyl)Phenyl]-N-[3-Fluoro-2'-(Methylsulfonyl)[1,1'-Biphenyl]-4-Yl]-3-(Trifluoromethyl)-",
      "KB-65316",
      "KB-76718",
      "1-[3-(Aminomethyl)Phenyl]-N-[3-Fluoro-2'-(Methylsulfonyl)Biphenyl-4-Yl]-3-(Trifluoromethyl)-1H-Pyrazole-5-Carboxamide",
      "1-[3-(Aminomethyl)Phenyl]-N-[3-Fluoro-2-(Methylsulfonyl)-[1,1-Biphenyl]-4-Yl]-3-(Trifluoromethyl)-1H-Pyrazole-5-Carboxamide",
    ],
  },
  {
    primary_id: "D03QKF",
    names: [
      "2-(3-(3,5-Dichlorophenyl)Ureido)Benzoic Acid",
      "CHEMBL1088742",
      "2-{[(3,5-Dichlorophenyl)Carbamoyl]amino}benzoic Acid",
      "SCHEMBL11983339",
      "MolPort-011-309-281",
      "BDBM50313600",
      "AKOS012857299",
    ],
  },
  {
    primary_id: "D03QKW",
    names: ["KUC-7483"],
  },
  {
    primary_id: "D03QLG",
    names: ["CEP1347", "AC1L31ZX", "SCHEMBL1649555", "ZINC103589452", "DB05403"],
  },
  {
    primary_id: "D03QMA",
    names: ["PMID25399762-Compound-Figure2-Artoxanthochromane"],
  },
  {
    primary_id: "D03QMG",
    names: ["Glutamate Racemase Inhibitors"],
  },
  {
    primary_id: "D03QMR",
    names: ["WB-4101"],
  },
  {
    primary_id: "D03QMS",
    names: ["PD-157667", "CHEMBL78017", "SCHEMBL7307021", "Pd157667", "BDBM50071611"],
  },
  {
    primary_id: "D03QMU",
    names: [
      "7-Butyl-Azepan-(2Z)-Ylideneamine",
      "CHEMBL89288",
      "CHEMBL543514",
      "SCHEMBL7628313",
      "BDBM50064011",
      "2-Imino-7-Butylhexahydro-1H-Azepine",
    ],
  },
  {
    primary_id: "D03QMY",
    names: ["IDM-2101"],
  },
  {
    primary_id: "D03QNA",
    names: ["CIGB-845"],
  },
  {
    primary_id: "D03QNE",
    names: [
      "BW A360C",
      "139290-69-0",
      "MDL 100151",
      "C22H28FNO3",
      "HXTGXYRHXAGCFP-UHFFFAOYSA-N",
      "AC1Q4NRW",
      "AC1L1U2G",
      "SCHEMBL340674",
      "CHEMBL257283",
      "SCHEMBL13701277",
      "4-Piperidinemethanol, Alpha-(2,3-Dimethoxyphenyl)-1-(2-(4-Fluorophenyl)Ethyl)-, (+)-",
      "MolPort-039-331-873",
      "MDL100105",
      "BDBM50232153",
      "PDSP2_001480",
      "PDSP1_001496",
      "AKOS027338720",
      "MDL 100105",
      "AN-5556",
      "LS-115604",
    ],
  },
  {
    primary_id: "D03QNS",
    names: [
      "7-Hydroxy-3-Phenyl-2H-Chromen-2-One",
      "6468-96-8",
      "3-Phenylumbelliferone",
      "7-Hydroxy-3-Phenylcoumarin",
      "7-Hydroxy-3-Phenylchromen-2-One",
      "3-Phenyl-7-Hydroxycoumarin",
      "CHEMBL153505",
      "7-Hydroxy-3-Phenyl-2H-1-Benzopyran-2-One",
      "2H-1-Benzopyran-2-One,7-Hydroxy-3-Phenyl-",
      "7-Hydroxy-3-Phenyl-Chromen-2-One",
      "EN300-36438",
      "AE-641/01995035",
      "AC1NU2DD",
      "MLS001167019",
      "SCHEMBL591688",
      "AC1Q797K",
      "ZINC58069",
      "CTK5C1589",
      "Cid_5393176",
      "DTXSID60419831",
      "MolPort-000-870-829",
      "RIPZCQZTVDNJHQ-UHFFFAOYSA-N",
      "HMS2955O07",
      "BB_NC-01950",
      "SBB042221",
      "STL033560",
      "MFCD00037574",
    ],
  },
  {
    primary_id: "D03QNW",
    names: ["Hextend"],
  },
  {
    primary_id: "D03QOD",
    names: [
      "N-Hydroxy-2,2-Diphenylacetamide",
      "Diphenylacetohydroxamic Acid",
      "4099-51-8",
      "N-Hydroxy Diphenylacetamide",
      "CHEMBL396097",
      "NSC44620",
      "Benzeneacetamide, N-Hydroxy-",
      "A-Phenyl-",
      "N-Hydroxydiphenylacetamide",
      "BENZENEACETAMIDE,N-HYDROXY-A-PHENYL-",
      "AC1Q5QC3",
      "SCHEMBL2839032",
      "CTK8I6435",
      "DTXSID10286297",
      "AC1L6390",
      "ZINC4522248",
      "NSC-44620",
      "BDBM50207561",
      "MFCD16314231",
      "AKOS022308585",
      "Diphenylacetohydroxamic Acid, &gt",
    ],
  },
  {
    primary_id: "D03QPF",
    names: ["Immune Globulin Subcutaneous 20%"],
  },
  {
    primary_id: "D03QQH",
    names: ["AMA37", "Arylmorpholine Analog 37"],
  },
  {
    primary_id: "D03QQP",
    names: ["BDNA-003"],
  },
  {
    primary_id: "D03QRG",
    names: [
      "3-(3-Methylphenylethynyl)-5-Methyl[1,2,4]triazine",
      "CHEMBL227200",
      "SCHEMBL4298939",
      "ZINC28701667",
      "BDBM50215452",
    ],
  },
  {
    primary_id: "D03QRS",
    names: ["Triptorelin"],
  },
  {
    primary_id: "D03QRT",
    names: ["NSC-645812"],
  },
  {
    primary_id: "D03QSC",
    names: ["NT-KC-005"],
  },
  {
    primary_id: "D03QSQ",
    names: ["5-Ethyl-4-Propyl-Pyrrolidin-(2Z)-Ylideneamine", "CHEMBL360009"],
  },
  {
    primary_id: "D03QSR",
    names: ["TP-20"],
  },
  {
    primary_id: "D03QSS",
    names: ["AMX0035"],
  },
  {
    primary_id: "D03QTB",
    names: ["NSD-551", "BK Channel Activator (Cancer), NeuroSearch/TopoTarget"],
  },
  {
    primary_id: "D03QTH",
    names: [
      "BC11-38",
      "686770-80-9",
      "BC 11-38",
      "C15H16N2OS2",
      "AC1M0MXE",
      "GTPL6559",
      "CHEMBL3928332",
      "SCHEMBL16200740",
      "BDBM86642",
      "AOB1847",
      "SYN5070",
      "MolPort-003-028-365",
      "HMS1650C11",
      "ZINC2459456",
      "CCG-26916",
      "AKOS024458190",
      "MCULE-6438827024",
      "AS-16436",
      "KB-270820",
      "B7673",
      "EU-0088935",
      "SR-01000093012",
      "SR-01000093012-1",
      "F0579-0060",
      "3-Phenyl-2-Propylsulfanyl-6,7-Dihydrothieno[3,2-D]pyrimidin-4-One",
      "3-Phenyl-2-(Propylthio)-6,7-Dihydrothieno[3,2-D]pyrimidin-4(3H)-One",
      "6,7-Dihydro-3-Phenyl-2-(Propylthio)Thieno[3,2-D]pyrimidin-4(3H)One",
    ],
  },
  {
    primary_id: "D03QTM",
    names: ["Oncophage Vitespen"],
  },
  {
    primary_id: "D03QTP",
    names: ["CGP-52411", "DAPH"],
  },
  {
    primary_id: "D03QUJ",
    names: ["PYM-60001"],
  },
  {
    primary_id: "D03QUQ",
    names: ["LS-192629"],
  },
  {
    primary_id: "D03QUT",
    names: ["Therapeutic Peptide Subunit Vaccine"],
  },
  {
    primary_id: "D03QVY",
    names: ["EZN-4482"],
  },
  {
    primary_id: "D03QWC",
    names: ["SV-BR-1-GM"],
  },
  {
    primary_id: "D03QWG",
    names: [
      "Ad-IFN-Alpha",
      "Ad-IFN-Alpha (Cancer)",
      "Ad-IFN-Alpha (Cancer), MD Anderson Cancer Center/NCI",
      "Adenoviral-Mediated IFN-Alpha (Gene Therapy, Cancer), MD Anderson Cancer Center/NCI",
    ],
  },
  {
    primary_id: "D03QWO",
    names: [
      "Dexefaroxan",
      "Dexefaroxan [INN]",
      "(+)-(R)-2-(2-Ethyl-2,3-Dihydro-2-Benzofuranyl)-2-Imidazoline",
      "2-[(2R)-2-Ethyl-3H-1-Benzofuran-2-Yl]-4,5-Dihydro-1H-Imidazole",
    ],
  },
  {
    primary_id: "D03QWP",
    names: ["Dcp-C[D-Cys-Gly-Phe(PNO2)-D-Cys]NH2", "CHEMBL373489"],
  },
  {
    primary_id: "D03QWT",
    names: [
      "Doripenem",
      "Doribax",
      "S 4661",
      "S-4661",
      "Doripenem (USAN/INN)",
      "(4R,5S,6S)-6-[(1R)-1-Hydroxyethyl]-4-Methyl-7-Oxo-3-[(3S,5S)-5-[(Sulfamoylamino)Methyl]pyrrolidin-3-Yl]sulfanyl-1-Azabicyclo[3.2.0]hept-2-Ene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03QWZ",
    names: ["PP-099"],
  },
  {
    primary_id: "D03QXD",
    names: ["RXP-470"],
  },
  {
    primary_id: "D03QYZ",
    names: ["PF-877423"],
  },
  {
    primary_id: "D03QZB",
    names: [
      "F-1394",
      "AC1OCF5R",
      "SCHEMBL4296509",
      "[(1S,2S)-2-[[2,2-Dimethylpropyl(Nonyl)Carbamoyl]amino]cyclohexyl] 3-[(2,2,5,5-Tetramethyl-1,3-Dioxane-4-Carbonyl)Amino]propanoate",
    ],
  },
  {
    primary_id: "D03QZJ",
    names: [
      "CC-122",
      "Cc-122",
      "1015474-32-4",
      "Avadomide",
      "3-(5-Amino-2-Methyl-4-Oxoquinazolin-3(4H)-Yl)Piperidine-2,6-Dione",
      "CC122",
      "CC 122",
      "3-(5-Amino-2-Methyl-4-Oxo-4H-Quinazolin-3-Yl)-Piperidine-2,6-Dione",
      "2,6-Piperidinedione, 3-(5-Amino-2-Methyl-4-Oxo-3(4H)-Quinazolinyl)-;2,6-Piperidinedione, 3-(5-Amino-2-Methyl-4-Oxo-3(4H)-Quinazolinyl)-",
      "Avadomide [USAN]",
      "Avadomide(CC-122)",
      "Avadomide (USAN/INN)",
      "SCHEMBL282749",
      "US9694015, Compound A",
      "CHEMBL3989934",
      "BDBM76986",
      "RSNPAKAFCAAMBH-UHFFFAOYSA-N",
      "EX-A1191",
      "BCP15938",
      "S7892",
      "AKOS025399378",
      "SB18829",
      "CS-5995",
    ],
  },
  {
    primary_id: "D03RAN",
    names: ["CPSI-306"],
  },
  {
    primary_id: "D03RAU",
    names: ["Pyrimidinone Derivative 5"],
  },
  {
    primary_id: "D03RBD",
    names: [
      "8-Azabicyclo[3.2.1]octan-3-Yloxy-Benzamide",
      "CHEMBL1257578",
      "CHEMBL1223815",
      "SCHEMBL15031381",
      "BDBM50325848",
      "BDBM50327244",
      "AZ-MTAB, &gt",
      "ZINC101363709",
    ],
  },
  {
    primary_id: "D03RCB",
    names: ["FGI-101-1A6"],
  },
  {
    primary_id: "D03RCJ",
    names: [
      "Sodium Succinate",
      "Disodium Succinate",
      "SODIUM SUCCINATE",
      "150-90-3",
      "Disodium Butanedioate",
      "Butanedioic Acid, Disodium Salt",
      "Succinic Acid Disodium Salt",
      "Soduxin",
      "Jantaran Sodny [Czech]",
      "UNII-V8ZGC8ISR3",
      "Succinic Acid, Disodium Salt",
      "FEMA No. 3277",
      "CCRIS 3700",
      "Sodium Succinate Dibasic",
      "EINECS 205-778-7",
      "V8ZGC8ISR3",
      "Butanedioic Acid, Sodium Salt (1:2)",
      "Succinic Acid Disodium Salt Anhydrous",
      "Butanedioic Acid Disodium Salt",
      "Sodium Succinate (Anhydrous)",
      "Jantaran Sodny",
      "Butanedioic Acid Disodium Hexahydrate",
      "Succinic Acid, Disodium Salt, 99%, Anhydrous",
    ],
  },
  {
    primary_id: "D03RCP",
    names: ["NM-135"],
  },
  {
    primary_id: "D03RCV",
    names: ["D2A21", "Demegel (Demegen, US, US)", "Alanyl-L-Phenylalanyl-L-Alanyl-L-Phenylalanine"],
  },
  {
    primary_id: "D03RDQ",
    names: ["Gavilimomab", "ABX-CBL", "CBL-1"],
  },
  {
    primary_id: "D03RDS",
    names: ["Labetuzumab Govitecan"],
  },
  {
    primary_id: "D03REA",
    names: ["5-Methoxy-3-(2-Methoxybenzyl)-2H-Chromen-2-One", "CHEMBL505327"],
  },
  {
    primary_id: "D03REC",
    names: [
      "KP-736",
      "E-2-Carboxylic Acid Disodium Salt",
      "[6R-[6alpha,7beta(Z)]-7-[[(2-Amino-4-Thiazolyl)[[(1,4-Dihydro-1,5-Dihydroxy-4-Oxo-2-Pyridinyl)Methoxy]imino]acetyl]amino]-8-Oxo-3-[(1,2,3-Thiadiazol-5-Ylthio)Methyl]-5-Thia-1-Azabicyclo[4.2.0]oct-2-En",
      "(6R,7R)-7-[2-(2-Aminothiazol-4-Yl)-2(Z)-(1,5-Dihydroxy-4-Oxo-1,4-Dihydropyridin-2-Ylmethoxyimino)Acetamido]-3-(1,2,3-Thiadiazol-5-Ylthiomethyl)-3-Cephem-4-Carboxylic Acid Disodium Salt",
    ],
  },
  {
    primary_id: "D03RER",
    names: ["ISIS 29470"],
  },
  {
    primary_id: "D03REU",
    names: ["Oncology Therapeutics"],
  },
  {
    primary_id: "D03RFA",
    names: ["N6-CYCLOPENTYLADENOSINE"],
  },
  {
    primary_id: "D03RFK",
    names: ["GTP-200"],
  },
  {
    primary_id: "D03RFS",
    names: ["N-(3,3-Diphenyl)Propyl-2-Phenylacetamide"],
  },
  {
    primary_id: "D03RGI",
    names: [
      "1,2,4-Triazole-Carboxamidine",
      "1,2,4-TRIAZOLE-CARBOXAMIDINE",
      "AC1MPLO2",
      "TZC",
      "ZINC162503468",
      "DB02048",
      "CJ-13028",
      "Amino(1H-1,2,4-Triazol-1-Yl)Methaniminium",
    ],
  },
  {
    primary_id: "D03RGO",
    names: ["CP-331"],
  },
  {
    primary_id: "D03RGP",
    names: [
      "(R,S)-AMPA",
      "Alpha-AMINO-3-HYDROXY-5-METHYL-4-ISOXAZOLEPROPIONIC ACID",
      "Ampa",
      "74341-63-2",
      "77521-29-0",
      "(RS)-AMPA",
      "AMPA (Pharmaceutical)",
      "CHEMBL13378",
      "CHEBI:34018",
      "4-Isoxazolepropanoic Acid, Alpha-Amino-2,3-Dihydro-5-Methyl-3-Oxo-",
      "Gamma-Amino-3-Hydroxy-5-Methylisoxazole-4-Propionic Acid",
      "Dl-Alpha-Amino-3-Hydroxy-5-Methylisoxazole-4-Propionic Acid",
      "(+-)-Alpha-Amino-3-Hydroxy-5-Methyl-4-Isoxazolepropionic Acid",
      "D,L-Alpha-Amino-3-Hydroxy-5-Methylisoxazole-4-Propionic Acid",
      "AMPA",
    ],
  },
  {
    primary_id: "D03RGV",
    names: ["JWH-156"],
  },
  {
    primary_id: "D03RHW",
    names: ["5-BODMT", "5-N-Butyryloxy-N,N-Dimethyltryptamine", "Compound 10 [PMID 21422162]"],
  },
  {
    primary_id: "D03RHZ",
    names: [
      "MDL-27192",
      "3-(4-Chlorophenyl)-4-Ethyl-1H-1,2,4-Triazol-5(4H)-One",
      "5-(4-Chlorophenyl)-4-Ethyl-3,4-Dihydro-2H-1,2,4-Triazol-3-One",
    ],
  },
  {
    primary_id: "D03RIO",
    names: ["GSK598809"],
  },
  {
    primary_id: "D03RJH",
    names: ["2,5-Bis(2-Methoxyphenyl)Thiophene", "CHEMBL204873", "SCHEMBL12973253"],
  },
  {
    primary_id: "D03RJZ",
    names: ["Oxeglitazar"],
  },
  {
    primary_id: "D03RKC",
    names: ["EG-Vac"],
  },
  {
    primary_id: "D03RKP",
    names: ["Trisnorsqualene Difluoromethylidene"],
  },
  {
    primary_id: "D03RKU",
    names: ["ISIS 102490"],
  },
  {
    primary_id: "D03RKW",
    names: ["Tauroursodeoxycholic Acid"],
  },
  {
    primary_id: "D03RLA",
    names: ["Nogalamycin", "AC1MBPR2"],
  },
  {
    primary_id: "D03RLC",
    names: ["MOR-6292"],
  },
  {
    primary_id: "D03RLJ",
    names: [
      "(C8-R)-Hydantocidin 5'-Phosphate",
      "(C8-R)-HYDANTOCIDIN 5'-PHOSPHATE",
      "1qf4",
      "AC1L9LL4",
      "DB02666",
      "5-{(5S,7R,8S,9R)-8,9-Dihydroxy-2,4-Dioxo-7-[(Phosphonooxy)Methyl]-6-Oxa-1,3-Diazaspiro[4.4]non-3-Yl}-N-Hydroxy-N-(Hydroxymethyl)-D-Norvaline",
      "(2R)-5-[(1R,2S,3R,5S)-1,2-Dihydroxy-6,8-Dioxo-3-(Phosphonooxymethyl)-4-Oxa-7,9-Diazaspiro[4.4]nonan-7-Yl]-2-[hydroxy(Hydroxymethyl)Amino]pentanoic Acid",
    ],
  },
  {
    primary_id: "D03RLP",
    names: ["GT160-246"],
  },
  {
    primary_id: "D03RMB",
    names: ["2-{[R-(-)-Apomorphine-2'-Oxy]ethoxy}-Ethanol", "CHEMBL516151", "BDBM50251329"],
  },
  {
    primary_id: "D03RMI",
    names: ["LY2033298"],
  },
  {
    primary_id: "D03RMN",
    names: [
      "1069C",
      "CHEMBL418565",
      "1069C85",
      "AC1OCF0R",
      "SCHEMBL8965590",
      "UMSHZWFCVXIDEO-UHFFFAOYSA-N",
      "BDBM50280140",
      "Methyl N-[6-(3,4,5-Trimethoxybenzyloxy)Imidazo[1,2-B]pyridazine-2-Yl]carbamate",
      "Methyl N-[6-(3,4,5-Trimethoxybenzyloxy)Imidazo[1,2-B]pyridazin-2yl]carbamate",
      "[6-(3,4,5-Trimethoxy-Benzyloxy)-Imidazo[1,2-B]pyridazin-2-Yl]-Carbamic Acid Methyl Ester",
      "Methyl N-[6-[(3,4,5-Trimethoxyphenyl)Methoxy]imidazo[1,2-B]pyridazin-2-Yl]carbamate",
    ],
  },
  {
    primary_id: "D03RMT",
    names: ["3-(2-Carboxy-6-Mercaptohexyl)Benzoic Acid"],
  },
  {
    primary_id: "D03RNX",
    names: [
      "EPC-K1",
      "CHX-3107",
      "Phosphoric Acid 2-L-Ascorbyl D,L-Alpha-Tocopheryl Diester Potassium Salt",
      "2-O-[Hydroxy[2,5,7,8-Tetramethyl-2-(4,8,12-Trimethyltridecyl)-3,4-Dihydro-2H-1-Benzopyran-6-Yloxy]phosphoryl]-L-Ascorbic Acid Potassium Salt",
    ],
  },
  {
    primary_id: "D03ROA",
    names: ["Tr-2", "Antennapedia-P53 Recombinant Fusion Protein (Cancer), Trojantec"],
  },
  {
    primary_id: "D03ROX",
    names: [
      "Betaxolol",
      "Betaxololum",
      "Betazolol",
      "KERLEDEX",
      "Kerlone",
      "Betaxolol HCL",
      "SL 75212",
      "Betaxolol (INN)",
      "Betaxolol (TN)",
      "Betaxolol [INN:BAN]",
      "Betaxololum [INN-Latin]",
      "Betoptic (TN)",
      "Betoptic S (TN)",
      "Kerlone (TN)",
      "Lokren (TN)",
      "SL-75212",
      "ALO-1401-02",
      "(+/-)-1-[p-[2-(Cyclopropylmethoxy)Ethyl]phenoxy]-3-(Isopropylamino)-2-Propanol",
      "1-(4-(2-(Cyclopropylmethoxy)Ethyl)Phenoxy)-3-((1-Methylethyl)Amino)-2-Propanol",
      "1-(Isopropylamino)-3-[p-(Cyclopropylmethoxyethyl)Phenoxy]-2-Propanol",
      "1-[(4-{2-[(Cyclopropylmethyl)Oxy]ethyl}phenyl)Oxy]-3-[(1-Methylethyl)Amino]propan-2-Ol",
      "1-[4-[2-(Cyclopropylmethoxy)Ethyl]phenoxy]-3-(Propan-2-Ylamino)Propan-2-Ol",
      "1-{4-[2-(Cyclopropylmethoxy)Ethyl]phenoxy}-3-(Propan-2-Ylamino)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D03ROY",
    names: ["Aryl Pyrimidine Derivative 2"],
  },
  {
    primary_id: "D03RPJ",
    names: ["PMID26924192-Compound-102"],
  },
  {
    primary_id: "D03RQI",
    names: ["ER-37328"],
  },
  {
    primary_id: "D03RQW",
    names: ["Rostaporfin"],
  },
  {
    primary_id: "D03RRJ",
    names: ["Iodine-131-Tositumomab"],
  },
  {
    primary_id: "D03RRK",
    names: ["PTI-301"],
  },
  {
    primary_id: "D03RSA",
    names: ["M6S"],
  },
  {
    primary_id: "D03RSI",
    names: [
      "IMMLG-5521",
      "Coumarin Derivative (Inflammation), Chinese Academy Of Medical Sciences Institute Of Materia Medica",
    ],
  },
  {
    primary_id: "D03RSQ",
    names: ["Vivia-007"],
  },
  {
    primary_id: "D03RTC",
    names: ["BMS-812204", "CB1 Antagonists (Obesity), BMS", "CB1 Antagonists (Obesity), Bristol-Myers Squibb"],
  },
  {
    primary_id: "D03RTK",
    names: ["Trabectedin", "Ecteinascidin", "Ecteinascidin-743", "Et-743", "Yondelis (TN)"],
  },
  {
    primary_id: "D03RTM",
    names: ["Benzo[d]oxazole Derivative 1"],
  },
  {
    primary_id: "D03RTR",
    names: [
      "INP-02",
      "Antiviral Combination Therapy (Pressurized Olfactory Delivery, HIV-Associated Neurological Complications), Impel NeuroPharma",
    ],
  },
  {
    primary_id: "D03RTS",
    names: [
      "Celecoxib",
      "CEL",
      "Celebra",
      "Celebrex",
      "Celecox",
      "Celecoxi",
      "Celocoxib",
      "Eurocox",
      "Medicoxib",
      "Onsenal",
      "Solexa",
      "Xilebao",
      "Celecoxib [Old RN]",
      "Celecoxib [USAN]",
      "Pfizer Brand Of Celecoxib",
      "SC 58635",
      "SC58635",
      "YM 177",
      "YM177",
      "AI-525",
      "CEP-33222",
      "Celebra (TN)",
      "Celebrex (TN)",
      "SC-58635",
      "TPI-336",
      "YM-177",
      "Celebrex, Celebra, Celecoxib",
      "Celecoxib (SC-58635)",
      "Celecoxib (JAN/USAN/INN)",
      "SC-58553, SC-58635",
      "P-(5-P-Tolyl-3-(Trifluoromethyl)Pyrazol-1-Yl)Benzenesulfonamide",
      "Benzenesulfonamide,4-(5-(4-Methylphenyl)-3-(Trifluoromethyl)-1H-Pyrazol-1-Yl)",
      "4-(5-(4-Methylphenyl)-3-(Trifluoromethyl)-1H-Pyrazol-1-Yl)Benzenesulfonamide",
      "4-[5-(4-METHYLPHENYL)-3-(TRIFLUOROMETHYL)-1H-PYRAZOL-1-YL]BENZENESULFONAMIDE",
      "4-[5-(4-Methylphenyl)-3-(Trifluoromethyl)-1Hpyrazol-1-Yl] Benzenesulfonamide",
      "4-[5-(4-Methylphenyl)-3-(Trifluoromethyl)Pyrazol-1-Yl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D03RUL",
    names: ["AP5280"],
  },
  {
    primary_id: "D03RVC",
    names: ["YH-4808"],
  },
  {
    primary_id: "D03RXE",
    names: ["Biphenyl Mannoside Derivative 3"],
  },
  {
    primary_id: "D03RXS",
    names: ["TAK-831"],
  },
  {
    primary_id: "D03RYI",
    names: ["LY2606368 MsOH H2O"],
  },
  {
    primary_id: "D03RYJ",
    names: ["REL-1015"],
  },
  {
    primary_id: "D03RYN",
    names: ["Cytolin"],
  },
  {
    primary_id: "D03RYR",
    names: ["Pan-RAF Inhibitors"],
  },
  {
    primary_id: "D03RYS",
    names: ["VNA-932"],
  },
  {
    primary_id: "D03RYU",
    names: ["4-(6-Hydroxy-Benzooxazol-2-Yl)-Benzene-1,3-Diol"],
  },
  {
    primary_id: "D03RYY",
    names: ["NPC-349", "[D-Arg0,Hyp2,3,Thi5,8,D-Phe7]bradykinin", "NPC 349"],
  },
  {
    primary_id: "D03RZM",
    names: [
      "Steroid Hormone Conjugates",
      "AM-454",
      "Steroid Hormone Conjugates (Diabetes)",
      "DHEA-PC, AMUR",
      "DHEA-PC, SuperGen",
      "DHEA-Phosphocoline, SuperGen",
      "Steroid Hormone Conjugates (Diabetes), SuperGen",
    ],
  },
  {
    primary_id: "D03RZU",
    names: ["CNS-5788"],
  },
  {
    primary_id: "D03RZV",
    names: [
      "Tolazoline",
      "Artonil",
      "Benzalolin",
      "Benzazoline",
      "Benzidazol",
      "Benzolin",
      "Benzylimidazoline",
      "Divascol",
      "Imidalin",
      "Imidaline",
      "Kasimid;Lambril",
      "Olitensol",
      "Peripherine",
      "Phenylmethylimidazoline",
      "Prefaxil",
      "Pridazole",
      "Priscol",
      "Priscoline",
      "Tolazolin",
      "Tolazolina",
      "Tolazolinum",
      "Vasimid",
      "Vasodil",
      "Vasodilatan",
      "Dilatol ASI",
      "Tolazine [veterinary]",
      "Tolazoline Monohydrochloride",
      "Ciba 3259",
      "Benzolin (VAN)",
      "Benzolin (Vasodilator)",
      "Tolazine [veterinary] (TN)",
      "Tolazolina [INN-Spanish]",
      "Tolazoline (INN)",
      "Tolazoline [INN:BAN]",
      "Tolazolinum [INN-Latin]",
      "Benzolin (Vasodilator) (VAN)",
      "1H-Imidazole, 4,5-Dihydro-2-(Phenylmethyl)-(9CI)",
      "2-(Phenylmethyl)-4,5-Dihydro-1H-Imidazole",
      "2-BENZYL-4,5-IMIDAZOLINE HCl",
      "2-Benzyl-2-Imidazoline",
      "2-Benzyl-4,5-Dihydro-1H-Imidazole",
      "2-Benzyl-4,5-Imidazoline",
      "2-Benzylimidazoline",
      "4,5-Dihydro-2-(Phenylmethyl)-1H-Imidazole",
    ],
  },
  {
    primary_id: "D03SAM",
    names: [
      "(R)-Alpha-Methylhistamine",
      "CHEBI:73337",
      "R-Alpha-Methylhistamine",
      "(R)Alpha-Me-Histamine",
      "CHEMBL268229",
      "(R)-(-)-Alpha-Methylhistamine",
      "(2R)-1-(1H-Imidazol-4-Yl)Propan-2-Amine",
      "75614-87-8",
      "1H-Imidazole-4-Ethanamine, Alpha-Methyl-, (AlphaR)-",
      "(R)-(-)-4-(2-Aminopropyl)Imidazole",
      "(2R)-1-(3H-Imidazol-4-Yl)Propan-2-Amine",
      "(2R)-1-(1H-Imidazol-5-Yl)Propan-2-Amine",
      "Alpha-Methylhistamine-R",
      "C6H11N3",
      "[3H]-R-Alpha-Methylhistamine",
      "(R)-[3H]alpha-Methylhistamine",
      "[3H](R)-Alpha-Methylhistamine",
    ],
  },
  {
    primary_id: "D03SAV",
    names: [
      "ICI-199441",
      "115199-84-3",
      "ICI-199,441 HYDROCHLORIDE",
      "ICI 199,441 Hydrochloride",
      "Ici 199441",
      "Ici 199,441",
      "AC1MIZCX",
      "N-(2-(N-Methyl-3,4-Dichlorophenylacetamido)-2-Phenylethyl)Pyrrolidine",
      "SCHEMBL7647023",
      "CTK8E9897",
      "MolPort-023-275-929",
      "AKOS024458644",
      "(S)-3,4-Dichloro-N-Methyl-N-(1-Phenyl-2-(1-Pyrrolidinyl)Ethyl)Benzeneacetamide Monohydrochloride",
      "RT-013313",
      "B6448",
      "J-003264",
      "Benzeneacetamide, 3,4-Dichloro-N-Methyl-N-(1-Phenyl-2-(1-Pyrrolidinyl)Ethyl)-, Monohydrochloride, (S)-",
    ],
  },
  {
    primary_id: "D03SAZ",
    names: [
      "Cytosine Arabinose-5'-Phosphate",
      "7075/11/8",
      "Ara-5'-CMP",
      "Ara-CMP",
      "Cytarabine 5'-Monophosphate",
      "((2R,3S,4S,5R)-5-(4-Amino-2-Oxopyrimidin-1(2H)-Yl)-3,4-Dihydroxytetrahydrofuran-2-Yl)Methyl Dihydrogen Phosphate",
      "CYTOSINE ARABINOSE-5'-PHOSPHATE",
      "AraCMP",
      "Cytosine Arabinoside Monophosphate",
      "Aracytidine 5'-Phosphate",
      "Cytosine Arabinoside 5'MP",
      "Aracytidine 5'-Monophosphate",
      "UNII-Y73692GHI4",
      "Y73692GHI4",
      "NSC 99445",
      "1-Beta-D-Arabinofuranosylcytosine 5'-Phosphate",
      "Cytosine-Beta-D-Arabinofuranoside-5'-Monophosphate",
      "Cytosine Beta-D-Arabinofuranoside 5'-Monophosphate",
    ],
  },
  {
    primary_id: "D03SBI",
    names: ["NSC-660838"],
  },
  {
    primary_id: "D03SCD",
    names: ["5-Biphenyl-4-Ylmethyl-1-Isobutyl-1H-Tetrazole", "CHEMBL255903"],
  },
  {
    primary_id: "D03SCM",
    names: ["PMID25726713-Compound-62"],
  },
  {
    primary_id: "D03SCP",
    names: ["PNT-200"],
  },
  {
    primary_id: "D03SDH",
    names: ["Siratiazem"],
  },
  {
    primary_id: "D03SEC",
    names: ["8-Butoxy-Quinolin-2-Ylamine", "CHEMBL186659", "SCHEMBL5884456"],
  },
  {
    primary_id: "D03SFB",
    names: [
      "2-Methyl-1(3)H-Anthrasimidazole-6,11-Dione",
      "AC1MZY8L",
      "CHEMBL594892",
      "SCHEMBL17702673",
      "MolPort-002-517-242",
      "MolPort-000-752-121",
      "ZINC9353066",
      "AKOS030496241",
      "MCULE-7367469235",
      "2-Methyl-3H-Naphtho[3,2-E]benzimidazole-6,11-Dione",
    ],
  },
  {
    primary_id: "D03SFN",
    names: ["Elzasonan Hydrochloride"],
  },
  {
    primary_id: "D03SFU",
    names: [
      "TERTATOLOL",
      "(-)-Tertatolol",
      "(+)-Tertatolol",
      "GTPL64",
      "ZINC748",
      "PDSP2_001696",
      "PDSP1_001713",
      "117305-34-7",
      "UNII-9ZO341YQXP Component HTWFXPCUFWKXOP-CYBMUJFWSA-N",
    ],
  },
  {
    primary_id: "D03SGF",
    names: ["WBZ-7"],
  },
  {
    primary_id: "D03SGR",
    names: ["Efalizumab"],
  },
  {
    primary_id: "D03SHD",
    names: ["Myo-Inositol"],
  },
  {
    primary_id: "D03SHI",
    names: ["Ro-5287373"],
  },
  {
    primary_id: "D03SHM",
    names: ["PMID25666693-Compound-88"],
  },
  {
    primary_id: "D03SHO",
    names: ["Ac-I[CVVQDWGAHRC]T-NH2", "CHEMBL269043"],
  },
  {
    primary_id: "D03SHP",
    names: ["GSK2849466"],
  },
  {
    primary_id: "D03SHX",
    names: [
      "5-Iodo,5'-Deoxytubercidin",
      "CHEMBL66280",
      "7-(5-Deoxy-Beta-D-Ribofuranosyl)-5-Iodo-7h-Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "2i6a",
      "5-Iodo-5-Deoxytubercidin",
      "5-Iodo-5'-Deoxytubercidin",
      "SCHEMBL6236636",
      "BDBM14486",
      "DB07173",
      "5I5",
      "(2R,3R,4S,5R)-2-{4-Amino-5-Iodopyrrolo[2,3-D]pyrimidin-7-Yl}-5-Methyloxolane-3,4-Diol",
      "5'-DEOXY-5-IODOTUBERCIDIN",
      "(2R,3R,4S,5R)-2-(4-AMINO-5-IODO-7H-PYRROLO[2,3-D]PYRIMIDIN-7-YL)-5-(METHYL)TETRAHYDROFURAN-3,4-DIOL",
      "(2R,3R,4S,5R)-2-{4-Amino-5-Iodo-7H-Pyrrolo[2,3-D]pyrimidin-7-Yl}-5-Methyloxolane-3,4-Diol",
    ],
  },
  {
    primary_id: "D03SIA",
    names: ["OBI-1"],
  },
  {
    primary_id: "D03SIP",
    names: ["TNAP Inhibitors"],
  },
  {
    primary_id: "D03SJY",
    names: ["N-[2-(5-Hydroxy-1H-Indol-3-Yl)Ethyl]undecanamide"],
  },
  {
    primary_id: "D03SKC",
    names: ["KRP-104", "Antigen Inhibitor"],
  },
  {
    primary_id: "D03SKD",
    names: ["Oxycodone"],
  },
  {
    primary_id: "D03SKE",
    names: [
      "2,2',3-Tribromo-4,4',5,5'-Tetrahydroxybibenzyl",
      "CHEMBL1270043",
      "SCHEMBL15511567",
      "BDBM50329699",
      "2,2'',3-Tribromo-4,4'',5,5''-Tetrahydroxybibenzyl",
    ],
  },
  {
    primary_id: "D03SKF",
    names: ["SC-124a"],
  },
  {
    primary_id: "D03SKL",
    names: [
      "BAY 86-9766",
      "923032-37-5",
      "RDEA119",
      "BAY 869766",
      "RDEA 119",
      "UNII-JPX07AFM0N",
      "RDEA-119",
      "Refametinib (RDEA119, Bay 86-9766)",
      "JPX07AFM0N",
      "(S)-N-(3,4-Difluoro-2-((2-Fluoro-4-Iodophenyl)Amino)-6-Methoxyphenyl)-1-(2,3-Dihydroxypropyl)Cyclopropane-1-Sulfonamide",
      "N-[3,4-Difluoro-2-(2-Fluoro-4-Iodoanilino)-6-Methoxyphenyl]-1-[(2S)-2,3-Dihydroxypropyl]cyclopropane-1-Sulfonamide",
      "Refametinib [INN]",
      "923032-38-6",
      "BAY-869766",
      "BAY 8697661",
      "3e8n",
      "Refametinib R Enantiomer",
      "Refametinib (RDEA119)",
      "SCHEMBL345333",
    ],
  },
  {
    primary_id: "D03SKR",
    names: [
      "Clonidine",
      "Clonidin",
      "Duraclon",
      "Chlornidinum",
      "4205-90-7",
      "Catapres-TTS",
      "Catarpresan",
      "Catarpres",
      "Adesipress",
      "Catapres",
      "Catapressan",
      "Catapresan",
      "ST 155BS",
      "ST-155-BS",
      "Clonidinum",
      "Clonidina",
      "Clonidinum [INN-Latin]",
      "Clonidinhydrochlorid",
      "CATAPRES-TTS-3",
      "CATAPRES-TTS-1",
      "CATAPRES-TTS-2",
      "N-(2,6-Dichlorophenyl)-4,5-Dihydro-1H-Imidazol-2-Amine",
      "Isoglaucon",
      "2-(2,6-Dichloroanilino)-2-Imidazoline",
      "Dixarit",
      "SKF 34427",
      "Catapres- TTS",
      "Clonidina [INN-Spanish]",
      "M-5041T",
      "Hemiton",
      "CLORPRES",
      "Clonidine (Amino Form)",
      "Clofenil",
      "Clopheline",
      "Duraclont",
      "Gemiton",
      "Klofelin",
      "Klofenil",
      "M 5041T",
      "Catapres (TN)",
      "Catarpres-TTS",
      "ST-155BS",
      "Tenso-Timelets",
      "Catarpres-TTS (TN)",
      "Clonidine [USAN:BAN:INN]",
      "Clonidine (JAN/USAN/INN)",
      "1H-Imidazol-2-Amine, N-(2,6-Dichlorophenyl)-4,5-Dihydro-(9CI)",
      "2,6-Dichloro-N-2-Imidazolidinylidenebenzenamine",
      "2,6-Dichloro-N-(2-Imidazolidinylidene)Aniline",
      "2,6-Dichloro-N-2-Imidazolidinylidenebenzenamide",
      "2,6-Dichloro-N-Imidazolidin-2-Ylideneaniline",
      "2-((2,6-Dichlorophenyl)Imino)Imidazolidine",
      "2-(2,6-Dichloroanilino)-1,3-Diazacyclopentene-(2);2-(2,6-Dichloroanilino)-2-Imidazoline",
      "2-(2,6-Dichlorophenylamino)-2-Imidazoline",
      "2-(2,6-Dichlorophenylimino)Imidazolidine",
      "2-Imidazoline, 2-(2,6-Dichloroanilino)-(7CI,8CI)",
      "2-[(2,6-Dichlorophenyl)Imino]imidazoline",
      "2-[(2,6-Dichlorophenyl)Imino]-2-Imidazoline",
      "734571A",
      "2,6-DICHLORO-N-IMIDAZOLIDIN-2-YLIDENEANILINE",
    ],
  },
  {
    primary_id: "D03SLK",
    names: ["SAR292833"],
  },
  {
    primary_id: "D03SLO",
    names: ["OSU-2S"],
  },
  {
    primary_id: "D03SLR",
    names: ["FERULIC ACID"],
  },
  {
    primary_id: "D03SMB",
    names: ["2-18F-FE-5-Br-Glyburide", "2-(18F) Fluoroethoxyglyburide"],
  },
  {
    primary_id: "D03SNE",
    names: ["PMID27828716-Compound-BIO-Acetoxime"],
  },
  {
    primary_id: "D03SNI",
    names: ["VUF-10148"],
  },
  {
    primary_id: "D03SNK",
    names: [
      "2-(Biphenyl-4-Ylsulfonamido)Pentanedioic Acid",
      "CHEMBL475540",
      "2-(Biphenyl-4-Sulfonylamino)-Pentanedioic Acid",
      "SR-01000365325",
      "BAS 07869980",
      "AC1LD9LY",
      "MLS000075919",
      "CHEBI:91838",
      "HMS2373B21",
      "ML034",
      "BDBM50247207",
      "AKOS000737926",
      "AKOS024284065",
      "MCULE-3770968562",
      "ST072428",
      "SMR000014780",
      "2-[(4-Phenylphenyl)Sulfonylamino]pentanedioic Acid",
      "SR-01000365325-1",
      "SR-01000365325-3",
      "2-{[(4-Phenylphenyl)Sulfonyl]amino}pentanedioic Acid",
    ],
  },
  {
    primary_id: "D03SNN",
    names: ["2-Fluorophenyl 4-Butoxyphenylcarbamate", "CHEMBL597924"],
  },
  {
    primary_id: "D03SPJ",
    names: [
      "CORILAGIN",
      "Corilagin",
      "23094-69-1",
      "CHEMBL449392",
      "CHEBI:3884",
      "TUSDEZXZIZRFGC-XIGLUPEJSA-N",
      "Corillagin",
      "Corilagin,(S)",
      "AC1Q6PBU",
      "AC1L2K3M",
      "(1s,19r,21s,22r,23r)-6,7,8,11,12,13,22,23-Octahydroxy-3,16-Dioxo-2,17,20-Trioxatetracyclo[17.3.1.04,9.010,15]tricosa-4,6,8,10,12,14-Hexaen-21-Yl 3,4,5-Trihydroxybenzoate",
      "SCHEMBL329080",
      "Corilagin, Analytical Standard",
      "CTK4F0871",
      "MolPort-020-005-758",
      "B-D-Glucopyranose, Cyclic3,6-[(1R)-4,4',5,5',6,6'-Hexahydroxy[1,1'-Biphenyl]-2,2'-Dicarboxylate]1-(3,4,5-Trihydroxybenzoate)",
      "ZINC4098612",
      "BDBM50242279",
    ],
  },
  {
    primary_id: "D03SQT",
    names: ["Q203"],
  },
  {
    primary_id: "D03SQZ",
    names: ["ISIS 23459"],
  },
  {
    primary_id: "D03SRV",
    names: ["JWH-292"],
  },
  {
    primary_id: "D03SRY",
    names: ["Estramustine"],
  },
  {
    primary_id: "D03SSE",
    names: [
      "Mechlorethamine",
      "Antimit",
      "Carolysine",
      "Caryolysin",
      "Caryolysine",
      "Chlorethazine",
      "Chlormethine",
      "Cloramin",
      "Clormetina",
      "Dichloren",
      "Embechine",
      "Embichin",
      "MBA",
      "Mebichloramine",
      "Mechlorethanamine",
      "Mechloroethamine",
      "Mecloretamina",
      "Mustargen",
      "Mustine",
      "Mutagen",
      "Nitrogranulogen",
      "Thyldiethylamine",
      "Dichlor Amine",
      "Me Chloroethamine",
      "Mecloretamina [Italian]",
      "Mustine Note",
      "Nitrogen Mustard",
      "HN2",
      "T 1024",
      "TL 146",
      "Chloramine (The Nitrogen Mustard)",
      "Chlormethine (INN)",
      "Chlormethine [INN:BAN]",
      "Chlormethinum [INN-Latin]",
      "Clormetina [INN-Spanish]",
      "ENT-25294",
      "HN-2",
      "IBS-L0033631",
      "Mustargen (TN)",
      "N-Lost",
      "N-Methyl Lost",
      "Stickstofflost (Ebewe)",
      "T-1024",
      "Bis(2-Chloroethyl)Methylamine",
      "Bis(Beta-Chloroethyl) Methylamine",
      "Bis(Beta-Chloroethyl)Methylamine",
      "Di(2-Chloroethyl)Methylamine",
      "Methylbis(2-Chloroethyl)Amine",
      "Methylbis(Beta-Chloroethyl)Amine",
      "Methyldi(2-Chloroethyl)Amine",
      "N-Methyl-Lost",
      "N-Methyl-Lost [German]",
      "Nitrogen Mustard (HN-2)",
      "Mitoxine (*Hydrochloride*)",
      "N,N-Di(Chloroethyl)Methylamine",
      "N-Methyl-Bis-Chloraethylamin",
      "N-Methyl-Bis-Chloraethylamin [German]",
      "Nitol (*Hydrochloride*)",
      "Stickstofflost (*Hydrochloride*)",
      "N,N-Bis(2-Chloroethyl)Methylamine",
      "N-Methyl-Bis(2-Chloroethyl)Amine",
      "N-Methyl-Bis(Beta-Chloroethyl)Amine",
      "Beta,beta'-Dichlorodiethyl-N-Methylamine",
      "N-Methyl-2,2'-Dichlorodiethylamine",
      "N,N-Bis(2-Chloroethyl)-N-Methylamine",
      "1,5-Dichloro-3-Methyl-3-Azapentane Hydrochloride",
      "2,2'-Dichloro-N-Me",
      "2,2'-Dichloro-N-Methyldiethylamine",
      "2,2'-Dichlorodiethyl-Methylamine",
      "2-Chloro-N-(2-Chloroethyl)-N-Methylethanamine",
    ],
  },
  {
    primary_id: "D03SSG",
    names: ["PA-10040"],
  },
  {
    primary_id: "D03SSH",
    names: ["AI-1008", "Abl-Kinase Inhibitor (CML/GIST), Incozen"],
  },
  {
    primary_id: "D03STA",
    names: ["Darotropium"],
  },
  {
    primary_id: "D03STO",
    names: ["1-Benzyl-3,3-Diethylazetidine-2,4-Dione", "CHEMBL271819"],
  },
  {
    primary_id: "D03STV",
    names: ["GSK-2197870A"],
  },
  {
    primary_id: "D03SUI",
    names: ["Pyridin-3-Yl 4-Butoxybenzylcarbamate", "CHEMBL596877"],
  },
  {
    primary_id: "D03SUQ",
    names: ["Apilimod Dimesylate"],
  },
  {
    primary_id: "D03SVG",
    names: ["GSK-2189242A"],
  },
  {
    primary_id: "D03SVX",
    names: ["Aliskiren"],
  },
  {
    primary_id: "D03SVZ",
    names: ["Yersinia Pestis Vaccine"],
  },
  {
    primary_id: "D03SWH",
    names: ["PNU-142633"],
  },
  {
    primary_id: "D03SXC",
    names: ["Des-AA1,2,5-[(NalphaMe)D-Nal8,IAmp9]SRIF", "CHEMBL407125"],
  },
  {
    primary_id: "D03SXE",
    names: ["Betamethasone Valerate", "Beta-Val", "Betaderm", "Betatrex", "Dermabet", "Valnac"],
  },
  {
    primary_id: "D03SXI",
    names: ["INCAGN1949"],
  },
  {
    primary_id: "D03SXN",
    names: [
      "CP-96345",
      "CP 96345",
      "132746-60-2",
      "UNII-W22ILA2I52",
      "CP 96344",
      "CP 96,345",
      "CHEMBL16192",
      "W22ILA2I52",
      "2-(Diphenylmethyl)-N-((2-Methoxyphenyl)Methyl)-1-Azabicyclo(2.2.2)Octan-3-Amine",
      "CP-96,345",
      "(2S,3S)-Cis-2-(Diphenylmethyl)-N-[(2-Methoxyphenyl)Methyl]-1-Azabicyclo[2.2.2]octan-3-Amine",
      "(2S,3S)-2-Benzhydryl-N-[(2-Methoxyphenyl)Methyl]quinuclidin-3-Amine",
      "(2S,3S)-N-(2-Methoxyphenyl)Methyl-2-Diphenylmethyl-1-Azabicyclo[2.2.2]octan-3-Amine",
      "1-Azabicyclo(2.2.2)Octan-3-Amine, 2-(Diphenylmethyl)-N-((2-Methoxyphenyl)Methyl)-, (2S-Cis",
    ],
  },
  {
    primary_id: "D03SXQ",
    names: ["MDX-1140", "Anti-O8E Antibodies (Cancer), Medarex", "Anti-B7-H4 Antibodies (Cancer), Medarex"],
  },
  {
    primary_id: "D03SXS",
    names: ["LY-2875358", "C-Met MAb (Cancer), Eli Lilly", "Anti-C-Met Antibody (Cancer), Lilly"],
  },
  {
    primary_id: "D03SYC",
    names: ["LY-GSK-3i"],
  },
  {
    primary_id: "D03SYO",
    names: [
      "N-Methyl-4-(Pyridin-3-Yl)But-3-Yn-1-Amine",
      "CHEMBL54860",
      "SCHEMBL3023293",
      "WBAIXNUCEGCLDC-UHFFFAOYSA-N",
      "BDBM50138489",
      "Methyl-(4-Pyridin-3-Yl-But-3-Ynyl)-Amine",
      "N-Methyl-4-(3pyridinyl)-3-Butyne-1-Amine",
      "1-(3-Pyridinyl)-4-(Methylamino)-1-Butyne",
      "N-Methyl-4-(3-Pyridinyl)-3-Butyne-1-Amine",
    ],
  },
  {
    primary_id: "D03SZK",
    names: ["VXM-01"],
  },
  {
    primary_id: "D03SZN",
    names: ["Carbamide Derivative 22"],
  },
  {
    primary_id: "D03SZR",
    names: [
      "M-Carboxycinnamic Acid Bishydroxamide",
      "Cbha",
      "Histone Deacetylase Inhibitor II",
      "HDInhib_000062",
      "M-Carboxycinnamic Acid Bis-Hydroxamide",
      "N-Hydroxy-3-[(E)-3-(Hydroxyamino)-3-Oxoprop-1-Enyl]benzamide",
    ],
  },
  {
    primary_id: "D03SZY",
    names: ["Levosimendan IV"],
  },
  {
    primary_id: "D03TAP",
    names: ["MCT-275"],
  },
  {
    primary_id: "D03TAS",
    names: ["Hoe-33342"],
  },
  {
    primary_id: "D03TAT",
    names: ["Weight Regulating Human Endocrine Peptide"],
  },
  {
    primary_id: "D03TAY",
    names: ["VGX-3400"],
  },
  {
    primary_id: "D03TBA",
    names: ["RPR121154"],
  },
  {
    primary_id: "D03TBD",
    names: ["BMS-986148"],
  },
  {
    primary_id: "D03TBJ",
    names: [
      "GRT-6005",
      "Cebranopadol",
      "863513-91-1",
      "GRT6005",
      "UNII-7GDW9S3GN3",
      "GRT 6005",
      "7GDW9S3GN3",
      "Cebranopadol [USAN:INN]",
      "Cebranopadol (USAN/INN)",
      "Cebranopadol(GRT-6005)",
      "SCHEMBL566256",
      "SCHEMBL154336",
      "GTPL8866",
      "CHEMBL3545616",
      "CHEMBL2364605",
      "SCHEMBL10035739",
      "CHEMBL3962932",
      "SCHEMBL19336241",
      "SCHEMBL14183935",
      "MolPort-039-063-422",
      "BDBM177926",
      "ZINC3950145",
      "EX-A1095",
      "BCP09612",
      "BDBM50101095",
      "BDBM50101112",
      "AKOS032953927",
      "AKOS025402576",
      "AKOS025290738",
      "DB12830",
      "CS-1323",
      "SB16531",
      "NCGC00378895-01",
      "AK170452",
      "HY-15536",
      "AS-35183",
      "Centrally-Acting Nociceptin Agonist Analgesic (Oral Liquid, Pain/Diabetic Neuropathy), Grunenthal",
      "GRT 6005/06",
    ],
  },
  {
    primary_id: "D03TCR",
    names: ["PMID25522065-Compound-12"],
  },
  {
    primary_id: "D03TDB",
    names: ["TMB-358"],
  },
  {
    primary_id: "D03TDE",
    names: ["AC-4402"],
  },
  {
    primary_id: "D03TDK",
    names: ["Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D03TDN",
    names: [
      "1-Naphthylpiperazine",
      "1-(1-Naphthyl)Piperazine",
      "57536-86-4",
      "1-(Naphthalen-1-Yl)Piperazine",
      "1-Naphthalen-1-Ylpiperazine",
      "1-Naphthalen-1-Yl-Piperazine",
      "CHEMBL277120",
      "Piperazine, 1-(1-Naphthalenyl)-",
      "Piperazine,1-(1-Naphthalenyl)-",
      "4-(Naphth-1-Yl)Piperazine",
      "Naphthylpiperazine",
      "GTPL3",
      "Lopac-S-003",
      "AC1Q1INU",
      "N-(1-Naphthyl)Piperazine",
      "4-(1-Naphthyl)Piperazine",
      "Biomol-NT_000100",
      "N-(1- Naphthyl)Piperazine",
      "N-(1-Naphthyl)-Piperazine",
      "N-(1-Naphthyl) Piperazine",
      "AC1L1B9K",
      "4-(1-Naphthyl) Piperazine",
      "1-(Naphth-1-Yl)Piperazine",
      "Oprea1_621306",
      "1-NP",
    ],
  },
  {
    primary_id: "D03TDW",
    names: ["Thiopilocarpine"],
  },
  {
    primary_id: "D03TEE",
    names: ["Tricyclic Heterocycle Derivative 2"],
  },
  {
    primary_id: "D03TEJ",
    names: ["PD 165929"],
  },
  {
    primary_id: "D03TFJ",
    names: [
      "Benzimidazole",
      "BENZIMIDAZOLE",
      "51-17-2",
      "1H-Benzimidazole",
      "1H-Benzo[d]imidazole",
      "1,3-Benzodiazole",
      "Benzoimidazole",
      "O-Benzimidazole",
      "3-Azaindole",
      "Benziminazole",
      "Benzoglyoxaline",
      "Azindole",
      "1,3-Diazaindene",
      "1H-Benzoimidazole",
      "N,N'-Methenyl-O-Phenylenediamine",
      "NSC 759",
      "Benzimidazol",
      "1H-1,3-Benzodiazole",
      "BZI",
      "UNII-E24GX49LD8",
      "CCRIS 5967",
      "Benzimidazole, 98%",
      "CHEBI:41275",
      "HSDB 2797",
      "EINECS 200-081-4",
      "BRN 0109682",
      "AI3-03737",
      "E24GX49LD8",
      "HYZJCKYKOHLVJF-UHFFFAOYSA-N",
      "DSSTox_CID_4573",
      "DSSTox_RID_77454",
      "DSSTox_GSID_24573",
      "F3366-5347",
      "CAS-51-17-2",
      "Bezimidazole",
    ],
  },
  {
    primary_id: "D03TFP",
    names: ["KNI-10758"],
  },
  {
    primary_id: "D03TGB",
    names: ["MOD-1002"],
  },
  {
    primary_id: "D03TGG",
    names: ["PMID26004420-Compound-WO2012058134C"],
  },
  {
    primary_id: "D03TGJ",
    names: ["Sorivudine"],
  },
  {
    primary_id: "D03THP",
    names: ["4-(5-(4-Methoxyphenyl)Thiophen-2-Yl)Benzenamine", "CHEMBL203320", "SCHEMBL12973259"],
  },
  {
    primary_id: "D03TIA",
    names: ["PKR-A"],
  },
  {
    primary_id: "D03TIJ",
    names: ["Titanocene Dichloride"],
  },
  {
    primary_id: "D03TIS",
    names: ["Brentuximab Vedotin", "Adcetris (TN)"],
  },
  {
    primary_id: "D03TJJ",
    names: [
      "[125I]7-Azido-8-Iodoketanserine",
      "[125I]-7-Azido-8-Iodoketanserine (Photoaffinity Ligand)",
      "[125I]azidoiodoketanserin",
      "[125I]AZIK",
    ],
  },
  {
    primary_id: "D03TJN",
    names: ["KOS-1803"],
  },
  {
    primary_id: "D03TKI",
    names: ["FM-201"],
  },
  {
    primary_id: "D03TKT",
    names: ["O-Hydroxyatorvastatin", "BMS-243887"],
  },
  {
    primary_id: "D03TLQ",
    names: ["PMID25776143-Compound-1"],
  },
  {
    primary_id: "D03TLR",
    names: ["5-(4-Chlorophenyl)-4-P-Tolylisothiazole", "CHEMBL524133"],
  },
  {
    primary_id: "D03TMD",
    names: [
      "Barbituric Acid Derivative",
      "UNII-R1JI58032B",
      "R1JI58032B",
      "AC1MHEP3",
      "DB01496",
      "2-Thioxo-5-[[5-[2-(Trifluoromethyl)Phenyl]-2-Furyl]methyl]hexahydropyrimidine-4,6-Dione",
      "2-Sulfanylidene-5-[[5-[2-(Trifluoromethyl)Phenyl]furan-2-Yl]methyl]-1,3-Diazinane-4,6-Dione",
      "959343-20-5",
      "4,6(1H,5H)-Pyrimidinedione, Dihydro-2-Thioxo-5-((5-(2-(Trifluoromethyl)Phenyl)-2-Furanyl)Methyl)-",
    ],
  },
  {
    primary_id: "D03TME",
    names: ["Crotalidae Fab", "Crofab (TN)"],
  },
  {
    primary_id: "D03TNA",
    names: ["ABT-724", "2-[4-(2-Pyridyl)Piperazin-1-Ylmethyl]-1H-Benzimidazole Maleate"],
  },
  {
    primary_id: "D03TNE",
    names: ["N7-Hydroxy-2-Methoxy-N1-Phenylheptanediamide", "CHEMBL251206", "SCHEMBL8143763"],
  },
  {
    primary_id: "D03TNG",
    names: ["GCAN 101"],
  },
  {
    primary_id: "D03TNT",
    names: [
      "(11-BETA)-11,21-DIHYDROXY-PREGN-4-ENE-3,20-DIONE",
      "Corticosterone",
      "CORTICOSTERONE",
      "50-22-6",
      "17-Deoxycortisol",
      "Reichstein's Substance H",
      "Kendall's Compound B",
      "Corticosteron",
      "11beta,21-Dihydroxyprogesterone",
      "Compound B",
      "Reichstein's B",
      "11-Hydroxycorticoaldosterone",
      "(11beta)-11,21-Dihydroxypregn-4-Ene-3,20-Dione",
      "11beta,21-Dihydroxy-4-Pregnene-3,20-Dione",
      "4-Pregnene-11beta,21-Diol-3,20-Dione",
      "11,21-Dihydroxyprogesterone",
      "11Beta,21-Dihydroxypregn-4-Ene-3,20-Dione",
      "11,12-Dihydroxyprogesterone",
      "UNII-W980KJ009P",
      "CCRIS 6753",
      "11-Beta,21-Dihydroxypregn-3,20-Dione",
      "CHEBI:16827",
      "NSC9705",
    ],
  },
  {
    primary_id: "D03TOH",
    names: ["AC-0523"],
  },
  {
    primary_id: "D03TOK",
    names: [
      "1,6-Bis(4'-Hydroxyphenyl)-Hexa-1,3,5-Triene",
      "CHEMBL381731",
      "ZINC13686478",
      "BDBM50185986",
      "4,4'-(1,3,5-Hexatriene-1,6-Diyl)Bisphenol",
    ],
  },
  {
    primary_id: "D03TPB",
    names: ["1-[bis(4-Iodophenyl)Methyl]-3-(4-Bromophenyl)Urea", "CHEMBL388398"],
  },
  {
    primary_id: "D03TPE",
    names: ["Hydroxyqunoline Analog 2"],
  },
  {
    primary_id: "D03TPH",
    names: [
      "5-Chloro-1H-Indole-2,3-Dione",
      "5-Chloroisatin",
      "17630-76-1",
      "5-Chloroindoline-2,3-Dione",
      "5-Chlorisatin",
      "1H-Indole-2,3-Dione, 5-Chloro-",
      "5-Chloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "5-CHLORO ISATIN",
      "5-Chlor-2,3-Dioxoindolin",
      "NSC 135811",
      "XHDJYQWGFIBCEP-UHFFFAOYSA-N",
      "MFCD00014567",
      "5-Chlor-1h-Indol-2,3-Dion",
      "2HQ",
      "EINECS 241-614-0",
      "NSC135811",
      "PubChem13607",
      "ACMC-209zyf",
      "5-Chloroisatin, 97%",
      "AC1Q3MRO",
      "AC1Q3KL6",
      "Isatin-Based Compound, 33",
      "AC1Q3KL5",
      "AC1L3D5R",
      "5-Chloro-2,3-Dioxoindolin",
      "5-Chloroindolin-2,3-Dione",
      "1H-Indole-2, 5-Chloro-",
      "KSC174O1F",
    ],
  },
  {
    primary_id: "D03TPI",
    names: ["TV-1102"],
  },
  {
    primary_id: "D03TPR",
    names: ["Dextrothyroxine Sodium", "Choloxin"],
  },
  {
    primary_id: "D03TPY",
    names: ["SC-52491", "147600-74-6", "175773-85-0", "SC-52490", "SC-52491A"],
  },
  {
    primary_id: "D03TQB",
    names: ["AU105"],
  },
  {
    primary_id: "D03TQD",
    names: ["N7-Hydroxy-N1-Phenyl-2-Propoxyheptanediamide", "CHEMBL251406", "SCHEMBL8144856"],
  },
  {
    primary_id: "D03TQO",
    names: ["6-(3-Hydroxyphenyl)-2-Morpholino-4H-Chromen-4-One", "CHEMBL427009"],
  },
  {
    primary_id: "D03TQU",
    names: ["PMID25980951-Compound-31"],
  },
  {
    primary_id: "D03TRM",
    names: ["5-Indan-(1E)-Ylidenemethyl-1H-Imidazole"],
  },
  {
    primary_id: "D03TRO",
    names: ["GRN-529"],
  },
  {
    primary_id: "D03TSR",
    names: ["Grass Pollen Immunotherapy"],
  },
  {
    primary_id: "D03TSY",
    names: ["E55888", "E-55888", "E 55888"],
  },
  {
    primary_id: "D03TTQ",
    names: [
      "TRYPAN BLUE",
      "AC1O6WJ7",
      "Tetrasodium (3E)-5-Amino-3-[[4-[4-[(2Z)-2-(8-Amino-1-Oxo-3,6-Disulfonatonaphthalen-2-Ylidene)Hydrazinyl]-3-Methylphenyl]-2-Methylphenyl]hydrazinylidene]-4-Oxonaphthalene-2,7-Disulfonate",
      "3,3',3,3'-[(3,3'-Dimethylbiphenyl-4,4'-Diyl)Bis(Iminonitrilo)]bis(3,4-Dihydro-4-Oxo-5-Amino-2,7-Naphthalenedisulfonic Acid Disodium) Salt",
    ],
  },
  {
    primary_id: "D03TTT",
    names: ["AR-00341677"],
  },
  {
    primary_id: "D03TTW",
    names: [
      "MIS-416/Immunogen",
      "Anthrax Vaccine (Microparticle), Virionyx",
      "MIS-416/Immunogen (Anthrax), Virionyx",
      "MIS-416/Immunogen (Anthrax/Malaria/Tuberculosis/Neutropenia)",
      "MIS-416/Immunogen (Anthrax/Malaria/Tuberculosis/Neutropenia), Innate",
    ],
  },
  {
    primary_id: "D03TUM",
    names: ["N-Arachidonyl-Gamma-Aminobutyric Acid"],
  },
  {
    primary_id: "D03TUX",
    names: ["L-Phenylalaninol"],
  },
  {
    primary_id: "D03TVE",
    names: ["FGF21-PKE Adnectin"],
  },
  {
    primary_id: "D03TVW",
    names: [
      "Stepronin",
      "Bronchoplus",
      "Bronplus",
      "Masor",
      "Mucodil",
      "Prostenoglycine",
      "Ronpect",
      "TTPG",
      "Tenoglycine",
      "Tiase",
      "Tioten",
      "Valase",
      "Bronco-Plus",
      "KY-01",
      "TS-5010680",
    ],
  },
  {
    primary_id: "D03TVY",
    names: ["OTI-030"],
  },
  {
    primary_id: "D03TWD",
    names: ["PMID30107136-Compound-Example4"],
  },
  {
    primary_id: "D03TWR",
    names: ["Diphenylboronic Anhydride", "Diphenylborinic Anhydride"],
  },
  {
    primary_id: "D03TXW",
    names: [
      "CSP-9222",
      "Caspase Activators (Cancer), Bayer Healthcare",
      "Caspase Activators (Cancer), OncoGenex",
      "Caspase Activators (Cancer), Sonus",
    ],
  },
  {
    primary_id: "D03TYC",
    names: [
      "Alpha-Homonojirimycin",
      "Homonojirimycin",
      "119557-99-2",
      "A-Homonojirimycin",
      "Alpha-Homoallonojirimycin",
      "(2r,3r,5s,6r)-2,6-Bis(Hydroxymethyl)Piperidine-3,4,5-Triol",
      "CHEMBL501355",
      "CHEMBL2111688",
      "Hnj Cpd",
      ".alpha.-Homonojirimycin",
      "2,6-Bis-Hydroxymethyl-Piperidine-3,4,5-Triol",
      "AC1Q59RO",
      "AC1L4LY0",
      "3,4,5-Piperidinetriol,2,6-Bis(Hydroxymethyl)-, (2R,3R,5S,6R)-",
      "SCHEMBL2265531",
      "SCHEMBL1469250",
      "SCHEMBL2265529",
      "BDBM18366",
      "CTK4B1372",
      "CLVUFWXGNIFGNC-OVHBTUCOSA-N",
      "CLVUFWXGNIFGNC-QTSLKERKSA-N",
      "Alpha-Homonojirimycin (Alpha-HNJ)",
      "BDBM50259956",
    ],
  },
  {
    primary_id: "D03TYE",
    names: [
      "PT-108",
      "149487-94-5",
      "Thiourea, N-(2-(2,6-Difluorophenyl)Ethyl)-N'-(5-Methyl-2-Pyridinyl)-",
      "1-[2-(2,6-Difluorophenyl)Ethyl]-3-(5-Methylpyridin-2-Yl)Thiourea",
      "PETT Analog 58",
      "Thiourea, N-[2-(2,6-Difluorophenyl)Ethyl]-N'-(5-Methyl-2-Pyridinyl)-",
      "AC1MHDL5",
      "BDBM1891",
      "CHEMBL398766",
      "DTXSID40164312",
      "ZINC13744917",
      "N-(2-(2,6-Difluorophenyl)Ethyl)-N -(2-(5-Methylpyridyl))Thiourea",
    ],
  },
  {
    primary_id: "D03TYI",
    names: [
      "Aminophylline",
      "Aminocardol",
      "Aminodur",
      "Aminofilina",
      "Aminofillina",
      "Aminophyllin",
      "Ammophyllin",
      "Cardiofilina",
      "Cardiomin",
      "Cardophylin",
      "Cardophyllin",
      "Carena",
      "Cariomin",
      "DOBO",
      "Diaphilline",
      "Diaphyllin",
      "Diaphylline",
      "Diophllin",
      "Diophyllin",
      "Diuxanthine",
      "Ethophylline",
      "Eufilina",
      "Euphyllin",
      "Euphylline",
      "Euphyllinum",
      "Eurphyllin",
      "Euufilin",
      "Euufillin",
      "Genophyllin",
      "Grifomin",
      "Inophylline",
      "Lasodex",
      "Linampheta",
      "Lixaminol",
      "Metaphyllin",
      "Metaphylline",
      "Minaphil",
      "Miofilin",
      "Neophyiline",
      "Norofilina",
      "Peterphyllin",
      "Phylcardin",
      "Phyllindon",
      "Phyllocontin",
      "Phyllotemp",
      "Somophyllin",
      "Stenovasan",
      "Syntophyllin",
      "Theodrox",
      "Theolamine",
      "Theolone",
      "Theomin",
      "Theophyldine",
      "Thephyldine",
      "Truphylline",
      "Vasofilina",
      "A 1755",
      "BY 108",
      "Minomal(TN)",
      "Phyllocontin (TN)",
      "TH/100",
      "Truphylline (TN)",
    ],
  },
  {
    primary_id: "D03TYJ",
    names: ["ATHX-105", "ATH-88651", "5-HT2c Receptor Agonists (Obesity), Athersys Inc"],
  },
  {
    primary_id: "D03TYT",
    names: [
      "PF-03800130",
      "Dual Dopamine D2 Partial Agonist/Serotonin Reuptake Inhibitor (Schizophrenia/Bipolar Disorder), Pfizer",
    ],
  },
  {
    primary_id: "D03TYV",
    names: [
      "5,5'-Methylenebis(1,3,4-Thiadiazole-2(3H)-Thione)",
      "CHEMBL595241",
      "70066-79-4",
      "SCHEMBL737372",
      "CTK2G3163",
      "DTXSID80673150",
      "BDBM50320717",
      "Bis(1,3,4-Thiadiazole-2-Methyl)-5-Thione",
      "5,5'-Methylenedi(1,3,4-Thiadiazole-2(3H)-Thione)",
      "1,3,4-Thiadiazole-2(3H)-Thione, 5,5'-Methylenebis-",
    ],
  },
  {
    primary_id: "D03TYY",
    names: ["Nicotine-Q"],
  },
  {
    primary_id: "D03TZE",
    names: ["PMID29473428-Compound-22"],
  },
  {
    primary_id: "D03TZG",
    names: ["MGN-9103"],
  },
  {
    primary_id: "D03UAO",
    names: ["MG-1105", "DTC-Apo"],
  },
  {
    primary_id: "D03UBI",
    names: [
      "VALERGEN-DP (Subcutaneous)",
      "Dermatophagoides Pteronyssinus Allergen Immunotherapy (Subcutaneous, Asthma), National Biopreparations Center",
    ],
  },
  {
    primary_id: "D03UBW",
    names: ["Ficlatuzumab", "AV-299"],
  },
  {
    primary_id: "D03UCC",
    names: ["ETHYL HYDROGEN DIETHYLAMIDOPHOSPHATE"],
  },
  {
    primary_id: "D03UCG",
    names: ["PMID30247903-Compound-General Structure29"],
  },
  {
    primary_id: "D03UDF",
    names: ["LOXO-195"],
  },
  {
    primary_id: "D03UDW",
    names: ["AI-850"],
  },
  {
    primary_id: "D03UFD",
    names: [
      "OXYMORPHINDOLE",
      "ACMC-20di42",
      "AC1L9G6X",
      "CHEMBL1985511",
      "CTK7J9928",
      "4,8-Methanobenzofuro[2,3-A]pyrido[4,3-B]carbazole-1,8a(9H)-Diol,5,6,7,8,14,14b-Hexahydro-7-Methyl-, (4bS,8R,8aS,14bR)-",
      "NSC707844",
      "NSC-707844",
      "NCI60_038277",
    ],
  },
  {
    primary_id: "D03UFM",
    names: ["BMS-200980"],
  },
  {
    primary_id: "D03UFQ",
    names: ["Malaria Vaccine"],
  },
  {
    primary_id: "D03UHT",
    names: ["MCL-458"],
  },
  {
    primary_id: "D03UHV",
    names: ["ONO-4578"],
  },
  {
    primary_id: "D03UIB",
    names: ["TOK-8801"],
  },
  {
    primary_id: "D03UID",
    names: ["RJR-2403"],
  },
  {
    primary_id: "D03UIF",
    names: ["SA-6541"],
  },
  {
    primary_id: "D03UIR",
    names: [
      "(2S,4R)-4-(3-Methoxy-3-Oxopropyl)Glutamic Acid",
      "CHEMBL482080",
      "(2s,4r)-4-(3-Methoxy-3-Oxopropyl) Glutamic Acid",
      "ZINC40934342",
      "BDBM50252048",
      "(4R)-4-(3-Methoxy-3-Oxopropyl)-L-Glutamic Acid",
      "(2S,4R)-2-Amino-4-(3-Methoxy-3-Oxopropyl)Pentanedioic Acid",
    ],
  },
  {
    primary_id: "D03UIW",
    names: ["Brontictuzumab"],
  },
  {
    primary_id: "D03UJT",
    names: [
      "[3H](-)Devapamil",
      "(-)-Desmethoxyverapamil",
      "(-)-Devapamil",
      "4-Desmethoxyverapamil",
      "UNII-99EAI85506",
      "99EAI85506",
      "93468-87-2",
      "(2S)-2-(3,4-Dimethoxyphenyl)-5-[2-(3-Methoxyphenyl)Ethyl-Methylamino]-2-Propan-2-Ylpentanenitrile",
      "AC1L32ZZ",
      "AC1Q4Q2Z",
      "4-DMV",
      "(-)-H3-Desmethoxyverapamil",
      "GTPL2515",
      "GTPL2517",
      "SCHEMBL10453636",
      "4-Desmethoxyverapamil (4-DMV)",
      "ZINC2016058",
      "PDSP2_001070",
      "PDSP1_001086",
      "Benzeneacetonitrile, 3,4-Dimethoxy-",
      "A-(3-((2-(3-Methoxyphenyl)Ethyl)Methylamino)Propyl)-",
      "A-(1-Methylethyl)-,(S)-",
      "D888",
      "D 888",
      "D-888",
      "(-)-D888",
      "Desmethoxyverapamil",
    ],
  },
  {
    primary_id: "D03UJW",
    names: ["1-Benzhydryl-4-Butylpiperidin-4-Ol", "CHEMBL397761", "SCHEMBL13597038"],
  },
  {
    primary_id: "D03UKI",
    names: [
      "Autologous Dendritic Cell Vaccines",
      "Autologous Dendritic Cell Vaccines (Cancer)",
      "Autologous Dendritic Cell Vaccines (Cancer), Tella",
      "Tumor Antigen-Pulsed Dendritic Cells (Cancer), Tella",
    ],
  },
  {
    primary_id: "D03UKJ",
    names: ["Pazopanib + Tyverb/Tykerb"],
  },
  {
    primary_id: "D03ULH",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 13"],
  },
  {
    primary_id: "D03UMA",
    names: ["CNT0-0007"],
  },
  {
    primary_id: "D03UMB",
    names: ["Inhaled Retinoids"],
  },
  {
    primary_id: "D03UMN",
    names: ["Anetumab Ravantansine"],
  },
  {
    primary_id: "D03UNB",
    names: ["Cycloalkyl Acid Derivative 2"],
  },
  {
    primary_id: "D03UNN",
    names: ["L-689560"],
  },
  {
    primary_id: "D03UNT",
    names: ["Anti-CD19-CAR Vector-Transduced T Cells"],
  },
  {
    primary_id: "D03UOG",
    names: ["P2B-004"],
  },
  {
    primary_id: "D03UOT",
    names: ["Hydroquinone"],
  },
  {
    primary_id: "D03UQM",
    names: [
      "Retapamulin",
      "Altabax",
      "Altargo",
      "SB 275833",
      "Altabax (Glaxo)",
      "Altabax (TN)",
      "Altargo (TN)",
      "Retapamulin (USAN/INN)",
    ],
  },
  {
    primary_id: "D03URI",
    names: ["GW 627368"],
  },
  {
    primary_id: "D03URV",
    names: ["HL-1172"],
  },
  {
    primary_id: "D03USI",
    names: ["SL-87.0495"],
  },
  {
    primary_id: "D03USW",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 25"],
  },
  {
    primary_id: "D03UTI",
    names: [
      "4-Iodo-1H-Indazole",
      "885522-11-2",
      "4-Iodoindazole",
      "4-IODO (1H)INDAZOLE",
      "CHEMBL246534",
      "Zlchem 1146",
      "PubChem21944",
      "SCHEMBL358061",
      "4-Iodo-1H-Indazole, 95%",
      "4-IODO-(1H)-INDAZOLE",
      "1H-INDAZOLE, 4-IODO-",
      "CTK8B7170",
      "ZLD0614",
      "DTXSID10646276",
      "MolPort-009-197-588",
      "CJQXSPSUIGJNJX-UHFFFAOYSA-N",
      "ZX-RL006042",
      "BCP26449",
      "ZINC14983484",
      "BDBM50209235",
      "ANW-56585",
      "CI-483",
      "AKOS015853740",
      "FCH1325887",
      "VI20042",
      "QC-2871",
      "PB27826",
      "RP05951",
      "AS-50534",
      "AJ-65949",
      "EN002531",
      "KB-39256",
      "OR308061",
      "AK-27887",
      "TC-062829",
      "DB-023141",
      "AB0037276",
      "ST1100625",
      "AX8158792",
      "Y4536",
      "AM20041322",
    ],
  },
  {
    primary_id: "D03UUL",
    names: ["APN01"],
  },
  {
    primary_id: "D03UUO",
    names: [
      "2-(2-(Butylamino)Pyrimidin-4-Ylamino)Benzoic Acid",
      "4-Anilinopyrimidine 7a",
      "CHEMBL394869",
      "BDBM15986",
      "AKOS029246132",
      "2-{[2-(Butylamino)Pyrimidin-4-Yl]amino}benzoic Acid",
    ],
  },
  {
    primary_id: "D03UVL",
    names: ["SSR-240612"],
  },
  {
    primary_id: "D03UVR",
    names: ["[18F]SPA-RQ", "[18F]-SPA-RQ"],
  },
  {
    primary_id: "D03UVS",
    names: [
      "Gemcitabine",
      "DDFC",
      "DFdC",
      "DFdCyd",
      "Folfugem",
      "GEO",
      "Gamcitabine",
      "GemLip",
      "Gemcel",
      "Gemcin",
      "Gemcitabina",
      "Gemcitabinum",
      "Gemtro",
      "Gemzar",
      "Zefei",
      "Gemcitabine HCl",
      "Gemcitabine Stereoisomer",
      "LY 188011",
      "LY188011",
      "Gemcitabina [INN-Spanish]",
      "Gemcitabinum [INN-Latin]",
      "Gemzar (TN)",
      "Gemzar (Hydrochloride)",
      "Inno-D07001",
      "LY-188011",
      "Gemcitabine (USAN/INN)",
      "Cytidine, 2'-Deoxy-2',2'-Difluoro-2'-Deoxy-.beta.-D-2',2'-Difluorocytidine",
      "2',2'-DiF-DC",
      "2',2'-Difluoro-2'-Deoxycytidine",
      "2',2'-Difluorodeoxycytidine",
      "2'-Deoxy-.beta.-D-2',2'-Difluorocytidine",
      "2'-Deoxy-2',2'-Difluorocytidine",
      "4-Amino-1-[(2R,4R,5R)-3,3-Difluoro-4-Hydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]pyrimidin-2-One",
      "4-Amino-1-[3,3-Difluoro-4-Hydroxy-5-(Hydroxymethyl) Tetrahydrofuran-2-Yl]-1H-Pyrimidin-2-One",
      "4-Amino-1-((2R,4R,5R)-3,3-Difluoro-4-Hydroxy-5-(Hydroxymethyl)-Tetrahydrofuran-2-Yl)Pyrimidin-2(1H)-One",
    ],
  },
  {
    primary_id: "D03UVX",
    names: ["NOPPI"],
  },
  {
    primary_id: "D03UWE",
    names: ["CAT-1904"],
  },
  {
    primary_id: "D03UXO",
    names: ["NKY80"],
  },
  {
    primary_id: "D03UXT",
    names: [
      "Recombinant House Dust Mite Allergy Desensitization Injection",
      "Alutard SQ (House Dust Mite)",
      "Maxy-320",
      "Recombinant House Dust Mite Allergy Desensitization Injection, ALK-Abello",
      "Recombinant House Dust Mite Allergy Desensitization Injection, ALK-Abello/ Torii",
      "Allergy Therapeutic (Maxy-320), Maxygen/ALK-Abello",
      "Recombinant House Dust Mite Allergen (Sc, Rhinitis/Asthma Prevention), ALK-Abello",
    ],
  },
  {
    primary_id: "D03UYI",
    names: ["TAK-427"],
  },
  {
    primary_id: "D03UYO",
    names: ["PMID8831774C19"],
  },
  {
    primary_id: "D03UZC",
    names: ["DS-1150b"],
  },
  {
    primary_id: "D03UZP",
    names: ["RG7888"],
  },
  {
    primary_id: "D03UZZ",
    names: ["3-Cyano-N-(3-Ethynylphenyl)-5-Fluorobenzamide", "CHEMBL1209549"],
  },
  {
    primary_id: "D03VAA",
    names: ["Bis(6-Hydroxybenzo[b]furan-2-Yl)Methanone", "CHEMBL225827"],
  },
  {
    primary_id: "D03VAO",
    names: ["N-(3-Bromophenyl)-6-Fluoroquinazolin-4-Amine", "CHEMBL1076333", "BDBM50311858"],
  },
  {
    primary_id: "D03VAR",
    names: ["Trecovirsen Sodium"],
  },
  {
    primary_id: "D03VBJ",
    names: [
      "ATPO",
      "252930-37-3",
      "(R,S)-2-Amino-3-[5-Tert-Butyl-3-(Phosphonomethoxy)-4-Isoxazolyl]propionic Acid",
      "2-Amino-3-[5-Tert-Butyl-3-(Phosphonomethoxy)-1,2-Oxazol-4-Yl]propanoic Acid",
      "ATPO, Solid",
      "AC1NECDZ",
      "Lopac0_000092",
      "SCHEMBL4318475",
      "CHEMBL265301",
      "GTPL4141",
      "DTXSID70404915",
      "MolPort-003-940-289",
      "HMS3261F20",
      "HMS3260C06",
      "Tox21_500589",
      "Tox21_500092",
      "MFCD03452828",
      "LP00092",
      "LP00589",
      "CCG-204187",
      "NCGC00261274-01",
      "NCGC00260777-01",
      "NCGC00015093-05",
      "NCGC00015093-02",
      "NCGC00093594-01",
      "NCGC00015093-04",
      "NCGC00093594-02",
      "NCGC00015093-03",
      "EU-0100092",
      "A 7845",
    ],
  },
  {
    primary_id: "D03VCF",
    names: ["Urtoxazumab"],
  },
  {
    primary_id: "D03VCJ",
    names: ["NP-01"],
  },
  {
    primary_id: "D03VDA",
    names: ["PTL-303"],
  },
  {
    primary_id: "D03VDC",
    names: ["Talc"],
  },
  {
    primary_id: "D03VDJ",
    names: ["SULFAMATE"],
  },
  {
    primary_id: "D03VDM",
    names: [
      "N-(2-(1H-Indol-3-Yl)Ethyl)Biphenyl-4-Carboxamide",
      "CHEMBL521733",
      "N-[2-(1H-Indol-3-Yl)Ethyl]biphenyl-4-Carboxamide",
      "AC1LG1FS",
      "Oprea1_829316",
      "Oprea1_192683",
      "MolPort-001-987-537",
      "ZINC285233",
      "STK129364",
      "BDBM50272836",
      "AKOS000554028",
      "MCULE-1062167425",
      "NCGC00304458-01",
      "BAS 03049800",
      "ST45172084",
      "AB01300868-01",
      "N-[2-(1H-Indol-3-Yl)Ethyl]-4-Phenylbenzamide",
      "N-(2-Indol-3-Ylethyl)(4-Phenylphenyl)Carboxamide",
      "Biphenyl-4-Carboxylic Acid [2-(1H-Indol-3-Yl)-Ethyl]-Amide",
    ],
  },
  {
    primary_id: "D03VDX",
    names: ["CPD-0905", "DB-820"],
  },
  {
    primary_id: "D03VEQ",
    names: ["Tyr-Gly-Gly-Phe-C(Cys-Arg-Arg-Ile-Cys)-Arg-Lys", "CHEMBL415406"],
  },
  {
    primary_id: "D03VEZ",
    names: [
      "AD-439",
      "AIDS-439",
      "CGP-47439",
      "Anti-HIV Antibodies, Tanox",
      "Anti-HIV Monoclonals, Tanox",
      "HIV-Neutralizing Antibodies, Tanox",
    ],
  },
  {
    primary_id: "D03VFL",
    names: [
      "TOCOTRIENOL",
      "2-Methyl-2-[[4,8,12-Trimethyl-3(E),7(E),11(E)]-Tridecatrienyl]-3,4-Dihydro-2H-Benzo[b]pyran-6-Ol",
    ],
  },
  {
    primary_id: "D03VFP",
    names: ["Ac-WVEHRLKGELSRKGGVV[hArg]KNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D03VFV",
    names: ["IL1aQb"],
  },
  {
    primary_id: "D03VGD",
    names: ["PACIS"],
  },
  {
    primary_id: "D03VGN",
    names: ["PMID25399762-Compound-Table 5-O-Methyl-M30"],
  },
  {
    primary_id: "D03VGO",
    names: ["GSK2194069"],
  },
  {
    primary_id: "D03VGR",
    names: ["N-Methyl LTC4"],
  },
  {
    primary_id: "D03VGW",
    names: ["GCR-1087"],
  },
  {
    primary_id: "D03VHI",
    names: [
      "HMR-3562",
      "11-Deoxy-3-Des(Hexopyranosyloxy)-2-Fluoro-11-[4-[4-(3-Pyridinyl)-1H-Imidazol-1-Yl]butylamino]-6-O-Methyl-3-Oxoerythromycin A 11-N,12-O-Cyclic Carbamate",
    ],
  },
  {
    primary_id: "D03VHK",
    names: ["L-748337"],
  },
  {
    primary_id: "D03VHO",
    names: ["Z-Ala-Leu-Tyr(Me)-Agly-Ile-Val-OMe"],
  },
  {
    primary_id: "D03VHX",
    names: ["Des-AA1,2,5-[D-Nal8,(NalphaMe)IAmp9,Tyr11]SRIF", "CHEMBL384195"],
  },
  {
    primary_id: "D03VIS",
    names: [
      "4-Tert-Butyl-N-Phenylthiazol-2-Amine",
      "CHEMBL206103",
      "SCHEMBL5486145",
      "ZINC2161671",
      "BDBM50183112",
      "N-Phenyl-4-Tert-Butyl-2-Thiazolamine",
    ],
  },
  {
    primary_id: "D03VKF",
    names: ["MBS-101C", "Bismuth Thiol Based Anti-Infective Agent (Topical, Chronic Wound Infection), Microbion"],
  },
  {
    primary_id: "D03VLG",
    names: ["DCB-WH1"],
  },
  {
    primary_id: "D03VLH",
    names: ["ColoAd1"],
  },
  {
    primary_id: "D03VLO",
    names: ["CD1-TB"],
  },
  {
    primary_id: "D03VLS",
    names: ["Lu-AA37096"],
  },
  {
    primary_id: "D03VLV",
    names: ["PMID28870136-Compound-36"],
  },
  {
    primary_id: "D03VMP",
    names: ["Peptidomimetic Analog 1"],
  },
  {
    primary_id: "D03VMW",
    names: ["HepaGam B"],
  },
  {
    primary_id: "D03VMX",
    names: ["GW1100"],
  },
  {
    primary_id: "D03VNB",
    names: ["NI-0801", "Anti-Interferon Inducible Protein 10, NovImmune"],
  },
  {
    primary_id: "D03VNJ",
    names: [
      "Carperitide",
      "Alpha-Atriopeptin",
      "89213-87-6",
      "Alpha-Human Atriopeptin",
      "Atriopeptin-28 (Human)",
      "Alpha-Atriopeptin (Human)",
      "Human Atriopeptin(1-28)",
      "SUN-4936",
      "UNII-GZ8FA500J0",
      "Human Atriopeptin(99-126)",
      "CCRIS 4930",
      "Triopeptin (Human Alpha-Component)",
      "Atrial Natriuretic Factor (99-126)",
      "Alpha-Human Atrial Natriuretic Hormone",
      "GZ8FA500J0",
      "Atriopeptin",
      "Anp 1-28, Human",
      "Atrial Natriuretic Factor (1-28) (Human, Porcine)",
      "Atriopeptins",
      "Atriopeptin-33(Rat), 1-De-L-Leucine-2-De-L-Alanine-3-Deglycine-4-De-L-Proline-5-De-L-Arginine-17-L-Me",
    ],
  },
  {
    primary_id: "D03VNX",
    names: ["Pro-Urokinase"],
  },
  {
    primary_id: "D03VNZ",
    names: ["Pyrrolo[3,4-E]indole-1,3(2H,6H)-Dione", "CHEMBL373066", "Pyrroloisoindoledione"],
  },
  {
    primary_id: "D03VOB",
    names: ["9-[2-(1-Phosphonopropan-2-Yloxy)Ethyl]guanine"],
  },
  {
    primary_id: "D03VOJ",
    names: ["PMID25399762-Compound-Table 7-3,4-Dihydroxybenzaldehyde"],
  },
  {
    primary_id: "D03VOM",
    names: [
      "3-(Dimethylamino)-1-(4-Hexylphenyl)Propan-1-One",
      "BAS 00130047",
      "AC1MJHY9",
      "Beta-Aminophenylketone, 3h",
      "CHEMBL236033",
      "BDBM18821",
      "ZINC3065713",
      "NCGC00168202-01",
      "4'-Hexyl-Beta-(Dimethylamino)Propiophenone",
    ],
  },
  {
    primary_id: "D03VPC",
    names: [
      "Cilostazol",
      "73963-72-1",
      "Pletal",
      "Cilostazole",
      "Pletaal",
      "Cilostazolum",
      "OPC-13013",
      "Cilostazolum [INN-Latin]",
      "OPC-21",
      "OPC 13013",
      "OPC 21",
      "UNII-N7Z035406B",
      "CHEBI:31401",
      "C20H27N5O2",
      "6-(4-(1-Cyclohexyl-1H-Tetrazol-5-Yl)Butoxy)-3,4-Dihydro-2(1H)-Quinolinone",
      "BRN 3632107",
      "6-[4-(1-Cyclohexyltetrazol-5-Yl)Butoxy]-3,4-Dihydro-1H-Quinolin-2-One",
      "6-(4-(1-Cyclohexyl-1H-Tetrazol-5-Yl)Butoxy)-3,4-Dihydroquinolin-2(1H)-One",
      "3,4-Dihydro-6-(4-(1-Cyclohexyl-1H-Tetrazol-5-Yl)Butoxy)-2(1H)-Quinolinone",
      "MLS000028470",
      "Otsuka Brand Of Cilostazol",
      "C 0737",
      "CL23867",
      "Cilostazol [INN:JAN]",
      "Pleta (TN)",
      "Pletal (TN)",
      "Pletal, Cilostazol",
      "Cilostazol (JP15/USAN/INN)",
      "6-(4-(1-Cyclohexyl-1H-Tetrazol-5-Yl)Butoxy)-3,4-Dihydrocarbostyril",
      "6-(4-(1-Cyclohexyl-1H-Tetrazol-5-Yl)Butoxyl)-3,4-Dihydrocarobostyril",
      "6-[4-(1-Cyclohexyl-1H-Tetrazol-5-Yl)-Butoxy]-3,4-Dihydro-2(1H)-Quinolinone",
      "6-[4-(1-Cyclohexyl-1H-Tetrazol-5-Yl)Butoxy]-3,4-Dihydroquinolin-2(1H)-One",
    ],
  },
  {
    primary_id: "D03VPJ",
    names: [
      "AN0128",
      "Bis(3-Chloro-4-Methyl-Phenyl)Boranyl 3-Hydroxypyridine-2-Carboxylate",
      "2-Pyridinecarboxylic Acid, 3-Hydroxy-, Anhydride With Bis(3-Chloro-4-Methylphenyl)Borinic Acid",
      "3-Hydroxypyridine-2-Carbonyloxy-Bis(3-Chloro-4-Methylphenyl)Borane",
    ],
  },
  {
    primary_id: "D03VPM",
    names: ["DPV-001"],
  },
  {
    primary_id: "D03VPP",
    names: ["PMID25980951-Compound-22"],
  },
  {
    primary_id: "D03VPS",
    names: [
      "ABT-670",
      "UNII-4L6071XH2J",
      "CHEMBL219182",
      "630119-43-6",
      "4L6071XH2J",
      "GTPL8439",
      "ABT670",
      "SCHEMBL4010523",
      "CTK2B0090",
      "DTXSID30212235",
      "ABT 670",
      "ZINC35075418",
      "BDBM50200050",
      "Compound 6b [PMID 17149874]",
      "CS-6805",
      "3-Methyl-N-(1-Oxy-3',4',5',6'-Tetrahydro-2'h-(2,4'-Bipyridine)-1'-Ylmethyl)Benzamide",
      "HY-19483",
      "3-Methyl-N-[[4-(1-Oxidopyridin-1-Ium-2-Yl)Piperidin-1-Yl]methyl]benzamide",
      "Benzamide, 3-Methyl-N-[[4-(1-Oxido-2-Pyridinyl)-1-Piperidinyl]methyl]-",
    ],
  },
  {
    primary_id: "D03VQC",
    names: ["4-(Isopentylthio)-2-Methoxybenzonitrile", "CHEMBL462663"],
  },
  {
    primary_id: "D03VQF",
    names: [
      "(2S)-2'-Methoxy Kurarinone",
      "2'-Methoxykurarinone",
      "(2S)-2'-Methoxykurarinone",
      "270249-38-2",
      "CHEMBL496451",
      "CHEBI:66151",
      "(2S)-7,4'-Dihydroxy-8-Lavandulyl-5,2'-Dimethoxyflavanone",
      "MolPort-039-337-150",
      "ZINC14711635",
      "LMPK12140500",
      "BDBM50377944",
      "AKOS032962578",
      "7,4'-Dihydroxy-5,2'-Dimethoxy-8-Lavandulylflavanone",
      "4H-1-Benzopyran-4-One,2,3-Dihydro-7-Hydroxy-2-(4-Hydroxy-2-Methoxyphenyl)-5-Methoxy-8-[(2R)-5-Methyl-2-(1-Methylethenyl)-4-Hexenyl]-, (2S)-",
    ],
  },
  {
    primary_id: "D03VRC",
    names: [
      "1-(4-Bromobenzyl)-1H-Imidazole",
      "72459-46-2",
      "1-(4-Bromobenzyl)Imidazole",
      "1H-Imidazole, 1-[(4-Bromophenyl)Methyl]-",
      "CHEMBL211207",
      "1-[(4-Bromophenyl)Methyl]imidazole",
      "1H-Imidazole,1-[(4-Bromophenyl)Methyl]-",
      "[(4-Bromophenyl)Methyl]imidazole",
      "AC1MGP7Q",
      "SCHEMBL707075",
      "1-(4Bromobenzyl)-1H-Imidazole",
      "KS-00000LSK",
      "CTK5D6137",
      "DTXSID20388130",
      "MZJSYLIOMLCYIS-UHFFFAOYSA-N",
      "MolPort-000-143-261",
      "1-(4bromo-Benzyl)-1H-Imidazole",
      "HMS1603K13",
      "CS-D0078",
      "ZINC4810192",
      "STK951803",
      "ANW-63041",
      "CB-660",
      "SBB098736",
      "BDBM50188088",
      "AKOS002272497",
      "RP05746",
    ],
  },
  {
    primary_id: "D03VRX",
    names: ["Albumin Iodinated Serum"],
  },
  {
    primary_id: "D03VSJ",
    names: ["Antanal 1", "CHEMBL507442"],
  },
  {
    primary_id: "D03VSQ",
    names: ["Kushenol N"],
  },
  {
    primary_id: "D03VTN",
    names: [
      "TRIMOPROSTIL",
      "CTK2F3572",
      "Prosta-5,13-Dien-1-Oicacid, 15-Hydroxy-11,16,16-Trimethyl-9-Oxo-, (5Z,11a,13E,15R)-",
    ],
  },
  {
    primary_id: "D03VUN",
    names: ["Lycorine Dimer Salt Derivative 1"],
  },
  {
    primary_id: "D03VUQ",
    names: ["4-(4-Hydroxy-Benzylideneamino)-Benzenesulfonamide", "4-(4-Hydroxybenzylideneamino)Benzenesulfonamide"],
  },
  {
    primary_id: "D03VUU",
    names: [
      "2-(9,10-Dihydroanthracen-9-Yl)-N-Methylethanamine",
      "CHEMBL595158",
      "BDBM35924",
      "9,10-Dihydroanthracene(DHA), 2b",
    ],
  },
  {
    primary_id: "D03VUV",
    names: [
      "Adarotene Ester Derivative Back-Up Compounds",
      "Adarotene",
      "Adarotene Ester Derivative Back-Up Compounds (Cancer)",
      "Adarotene Ester Derivative Back-Up Compounds (Cancer), Sigma-Tau",
    ],
  },
  {
    primary_id: "D03VWB",
    names: ["MP-157"],
  },
  {
    primary_id: "D03VXG",
    names: ["Insulin Lyspro Recombinant"],
  },
  {
    primary_id: "D03VXJ",
    names: [
      "Hydrochloric Acid",
      "Anhydrous HCl",
      "Hydrochloric Acid - Drilling Grade",
      "Hydrochloric Acid - 22 Degrees Baume",
      "Hydrochloric Acid - 20 Degrees Baume",
      "Hydrochloric Acid - Reagent Grade ACS",
    ],
  },
  {
    primary_id: "D03VXP",
    names: ["PMID25666693-Compound-145"],
  },
  {
    primary_id: "D03VXQ",
    names: ["Oxetane 3,3-Dicarboxamide Compound 1"],
  },
  {
    primary_id: "D03VYO",
    names: [
      "10-Ethyl-10H-Dibenzo[b,f][1,4]oxazepin-11-One",
      "10Et-DBOA-11one",
      "10-Ethyl-Dibenz(B,f)(1,4)Oxazepin-11-(10H)-One",
      "17296-50-3",
      "5-Ethylbenzo[b][1,4]benzoxazepin-6-One",
      "10-Ethyl-Dibenz[b,f][1,4]oxazepin-11-(10H)-One",
      "Dibenzoxazepinone 10",
      "AC1L9R9K",
      "CHEMBL299710",
      "BDBM1971",
      "SCHEMBL8401275",
      "DTXSID20169441",
      "ZINC6385234",
      "10-Ethyldibenz[b,f][1,4]oxazepin-11(10H)-One",
    ],
  },
  {
    primary_id: "D03VYU",
    names: ["Isohelenin"],
  },
  {
    primary_id: "D03VYZ",
    names: ["MRX-4"],
  },
  {
    primary_id: "D03VZF",
    names: [
      "4-Hydroxy-3-Nitrobenzaldehyde",
      "3011-34-5",
      "Benzaldehyde, 4-Hydroxy-3-Nitro-",
      "3-Nitro-4-Hydroxybenzaldehyde",
      "CHEMBL378361",
      "4-Hydroxy-3-Nitro-Benzaldehyde",
      "4-FORMYL-2-NITROPHENOL",
      "YTHJCZRFJGXPTL-UHFFFAOYSA-N",
      "4-Hydroxy-3-Nitrobenzaldehyde, 97%",
      "EINECS 221-141-6",
      "NSC138267",
      "NSC 138267",
      "PubChem8135",
      "ACMC-1CJNU",
      "AC1L2BHN",
      "4-Hydroxy-3-Nitrobenzaldeyde",
      "KSC223K5F",
      "SCHEMBL244093",
      "4-Hydroxyl-3-Nitrobenzaldehyde",
      "4-Hydroxy-3-Nitro Benzaldehyde",
      "Jsp005694",
      "KS-00000AYN",
      "CTK1C3552",
      "DTXSID80184195",
      "MolPort-000-156-359",
      "ZINC562702",
      "ACT01022",
    ],
  },
  {
    primary_id: "D03VZH",
    names: [
      "Ethchlorvynol",
      "Alvinol",
      "Arvynol",
      "Etchlorvinolo",
      "Etclorvinol",
      "Ethchlorovynol",
      "Ethchlorvinol",
      "Ethchlorvinyl",
      "Ethchlorvynolum;Ethclorvynol",
      "Ethochlorvynol",
      "Ethychlorvynol",
      "Normonson",
      "Normosan",
      "Normoson",
      "Nostel",
      "Nromoson",
      "Placidil",
      "Placidyl",
      "Roeridorm",
      "Serenesil",
      "Serenil",
      "Serensil",
      "Serensiloline",
      "A 71",
      "Aethyl-Chlorvynol",
      "Beta-Chlorovinyl Ethyl Ethynyl Carbinol",
      "Beta-Chlorvinyl Ethyl Ethynyl Carbinol",
      "Etclorvinol [INN-Spanish]",
      "Ethchlorvynolum [INN-Latin]",
      "Ethyl Beta-Chlorovinyl Ethynyl Carbinol",
      "Placidyl (TN)",
      "Ethchlorvynol [USAN:INN:BAN]",
      "Ethchlorvynol (JAN/USP/INN)",
      "(E)-1-Chloro-3-Ethylpent-1-En-4-Yn-3-Ol",
      "1-Chloro-3-Ethyl-1-Penten-4-Yn-3-Ol",
      "1-Chloro-3-Ethylpent-1-4-Yn-3-Ol",
      "1-Chloro-3-Ethylpent-1-En-4-Yn-3-Ol",
      "3-(Beta-Chlorovinyl)-1-Pentyn-3-Ol",
      "5-Chloro-3-Ethylpent-1-Yn-4-En-3-Ol",
    ],
  },
  {
    primary_id: "D03WAE",
    names: ["VU0405601"],
  },
  {
    primary_id: "D03WAJ",
    names: [
      "Heptabarbital",
      "Cycloheptenylethylmalonylurea",
      "Heptabarb",
      "Heptabarbe",
      "Heptabarbitone",
      "Heptabarbo",
      "Heptabarbum",
      "Heptadorm",
      "Heptamal",
      "Heptamalum",
      "Heptbarbital",
      "Medapan",
      "Medomin",
      "Medomine",
      "Noctyn",
      "Cycloheptenylethylbarbituric Acid",
      "G 475",
      "Heptabarbe [INN-French]",
      "Heptabarbo [INN-Spanish]",
      "Heptabarbum [INN-Latin]",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5-(1-Cyclohepten-1-Yl)-5-Ethyl-(9CI)",
      "5-(1-CYCLOHEPTEN-1-YL)-5-ETHYLBARBITURIC ACID",
      "5-(1-Cyclohepten-1-Yl)-5-Ethyl-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-(1-Cycloheptenyl)-5-Ethylbarbitursaeure",
      "5-(Cyclohepten-1-Yl)-5-Ethyl-1,3-Diazinane-2,4,6-Trione",
      "5-Ethyl-5-(1'-Cycloheptenyl)-Barbituric Acid",
      "5-Ethyl-5-Cycloheptenylbarbituric Acid",
    ],
  },
  {
    primary_id: "D03WAT",
    names: ["PRC-4016"],
  },
  {
    primary_id: "D03WAU",
    names: [
      "Anamorelin",
      "249921-19-5",
      "UNII-DD5RBA1NKF",
      "DD5RBA1NKF",
      "RC 1291",
      "Anamorelin [INN]",
      "ONO-7643",
      "SCHEMBL935751",
      "CHEMBL2110579",
      "EX-A154",
      "DTXSID20179702",
      "C31H42N6O3",
      "BCP06108",
      "3444AH",
      "ZINC43130908",
      "AKOS030526752",
      "SB16911",
      "API0008642",
      "RC-1291",
      "NCGC00378854-01",
      "KB-80338",
      "HY-14734",
      "BC600755",
      "(3R)-1-(2-Methylalanyl-D-Tryptophyl)-3-(Phenylmethyl)-3-Piperidinecarboxylic Acid 1,2,2-Trimethylhydrazide",
      "AB0165721",
      "FT-0773543",
      "W-5500",
      "921A195",
      "CS 1036",
    ],
  },
  {
    primary_id: "D03WAX",
    names: ["KST-301"],
  },
  {
    primary_id: "D03WBD",
    names: ["FV-Hyp-TDVGPFAF"],
  },
  {
    primary_id: "D03WBS",
    names: ["Aryl Carboxamide Derivative 3"],
  },
  {
    primary_id: "D03WBW",
    names: ["Anti-Epileptics"],
  },
  {
    primary_id: "D03WBX",
    names: ["MRS2782"],
  },
  {
    primary_id: "D03WCJ",
    names: ["4-Methyl-4,5-Dihydro-1H-Pyrazole-3-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D03WCT",
    names: ["LAS-189913"],
  },
  {
    primary_id: "D03WDH",
    names: ["Cyclohexane Carboxamide Derivative 2"],
  },
  {
    primary_id: "D03WDN",
    names: ["BI-638683"],
  },
  {
    primary_id: "D03WDY",
    names: ["N-(1,3,4-Thiadiazol-2-Yl)Benzamide"],
  },
  {
    primary_id: "D03WEN",
    names: ["5-Acrylamido-N-(3-Bromophenyl)-2-Hydroxybenzamide", "CHEMBL382537"],
  },
  {
    primary_id: "D03WEX",
    names: ["Vortioxetine"],
  },
  {
    primary_id: "D03WFE",
    names: ["Quinazolines And Azaquinazoline Derivative 1"],
  },
  {
    primary_id: "D03WFI",
    names: ["Heteroaryl-Carboxamide Derivative 9"],
  },
  {
    primary_id: "D03WGA",
    names: ["PMID27967267-Compound-Neostenine"],
  },
  {
    primary_id: "D03WGD",
    names: [
      "AMINOBENZTROPINE",
      "Aminobenztropine",
      "CHEMBL330957",
      "88097-86-3",
      "3-(2'-Aminobenzhydryloxy)Tropane",
      "ABHT",
      "Aminobenztropine, Solid",
      "Spectrum_000273",
      "SpecPlus_000797",
      "Spectrum5_001758",
      "Spectrum4_001211",
      "Spectrum3_000745",
      "AC1L1D0L",
      "AC1Q57ZN",
      "Lopac0_000126",
      "KBioGR_001642",
      "BSPBio_002330",
      "KBioSS_000753",
      "MLS000859976",
      "SPECTRUM2300334",
      "DivK1c_006893",
      "SCHEMBL1231000",
      "2-[(8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl)Oxy-Phenylmethyl]aniline",
      "KBio3_001550",
      "KBio2_003321",
      "KBio1_001837",
      "KBio2_005889",
      "KBio2_000753",
      "CTK5F9344",
      "CHEBI:125384",
      "HMS3260I14",
      "HMS3374M03",
      "HMS2232P19",
    ],
  },
  {
    primary_id: "D03WGM",
    names: ["SOM-0888"],
  },
  {
    primary_id: "D03WGS",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-Phe-Asp-Nle-Trp-Ac", "CHEMBL442483"],
  },
  {
    primary_id: "D03WHA",
    names: ["N-Quinolin-Benzene Sulphonamide Derivative 1"],
  },
  {
    primary_id: "D03WHC",
    names: ["Nnrti"],
  },
  {
    primary_id: "D03WHD",
    names: [
      "6-Amino-4-Trifluoromethylquinolin-2(1H)-One",
      "328955-57-3",
      "6-Amino-4-(Trifluoromethyl)Quinolin-2(1H)-One",
      "2(1H)-Quinolinone, 6-Amino-4-(Trifluoromethyl)-",
      "SCHEMBL1770828",
      "CHEMBL385509",
      "SCHEMBL19680530",
      "CTK8B4763",
      "BDBM18526",
      "DTXSID60582115",
      "LMXZMEMPGRLDIG-UHFFFAOYSA-N",
      "ANW-46096",
      "ZINC12349446",
      "AKOS015951346",
      "AK-41783",
      "AC-27434",
      "SC-50493",
      "AJ-61229",
      "AB1009151",
      "AB0024239",
      "ST2406401",
      "AX8208563",
      "W5464",
      "CS-0084832",
      "FT-0688581",
      "6-Amino-4-Trifluoromethyl-2(1H)-Quinolinone",
      "6-Amino-4-(Trifluoromethyl)-Quinolin-2(1h)-One",
    ],
  },
  {
    primary_id: "D03WIC",
    names: ["Arzerraofatumumab"],
  },
  {
    primary_id: "D03WIK",
    names: ["2-O-Ethyl-PAF C-16", "2-O-Ethyl-Platelet-Activating Factor C-16"],
  },
  {
    primary_id: "D03WJB",
    names: ["Biphenyl Mannoside Derivative 25"],
  },
  {
    primary_id: "D03WJD",
    names: ["PMID23788254C9"],
  },
  {
    primary_id: "D03WJG",
    names: ["PMID15055993C1a"],
  },
  {
    primary_id: "D03WLI",
    names: ["Technetium Tc-99m Pentetate Kit", "An-Dtpa", "Dtpa", "Mpi Dtpa Kit - Chelate"],
  },
  {
    primary_id: "D03WMK",
    names: ["AC-162352"],
  },
  {
    primary_id: "D03WMT",
    names: ["ML-721"],
  },
  {
    primary_id: "D03WMY",
    names: [
      "NPC-17731",
      "Bradykinin Antagonists, Scios",
      "NPC-16731",
      "NPC-17761",
      "NPC-18521",
      "NPC-18688",
      "NPC-18884",
    ],
  },
  {
    primary_id: "D03WMZ",
    names: ["MEDI5884"],
  },
  {
    primary_id: "D03WNG",
    names: ["CORT-125134"],
  },
  {
    primary_id: "D03WNI",
    names: ["Malic Acid"],
  },
  {
    primary_id: "D03WNN",
    names: ["RG-7010"],
  },
  {
    primary_id: "D03WOG",
    names: ["Evinacumab"],
  },
  {
    primary_id: "D03WOQ",
    names: ["LY3127804"],
  },
  {
    primary_id: "D03WOS",
    names: [
      "5-Chloro-1,2,3,4-Tetrahydro-Acridin-9-Ylamine",
      "CHEMBL369661",
      "SCHEMBL12043414",
      "ZINC27993222",
      "BDBM50280623",
      "AKOS011477647",
    ],
  },
  {
    primary_id: "D03WOU",
    names: [
      "1,2-Di-Naphthalen-2-Yl-Ethane-1,2-Dione",
      "CHEMBL189162",
      "1,2-Dinaphthalen-2-Ylethane-1,2-Dione",
      "Bis(2-Naphthyl)Glyoxal",
      "AC1MN9RI",
      "SCHEMBL7220072",
      "MolPort-003-808-637",
      "ZINC5705162",
      "BDBM50171924",
    ],
  },
  {
    primary_id: "D03WOW",
    names: ["QS-21", "AC1L1U2U", "C92H148O46", "LS-173109", "124716-EP2295426A1"],
  },
  {
    primary_id: "D03WOZ",
    names: [
      "7-Nitro-1H-Indazole",
      "7-Nitroindazole",
      "2942-42-9",
      "1H-Indazole, 7-Nitro-",
      "7-Nitro-Indazole",
      "7-NI",
      "UNII-UX0N37CMVH",
      "CCRIS 3309",
      "EINECS 220-934-4",
      "NSC 72843",
      "UX0N37CMVH",
      "BRN 0006809",
      "MLS000028452",
      "CHEMBL247378",
      "PQCAUHUKTBHUSA-UHFFFAOYSA-N",
      "MFCD00022789",
      "SMR000058266",
      "7NI",
      "2h-Indazole,7-Nitro-",
      "SR-01000075512",
      "7- Nitroindazole",
      "7-Nitroisoindazole",
      "Tocris-0602",
      "PubChem14009",
      "AC1L1CGU",
      "Spectrum3_001980",
      "Spectrum2_001715",
      "Opera_ID_1690",
      "Lopac-N-7778",
      "ACMC-209h8w",
      "AC1Q1Y3U",
      "7-Nitroindazole, &gt",
      "NCIOpen2_000477",
      "Lopac0_000839",
      "BSPBio_003580",
    ],
  },
  {
    primary_id: "D03WPA",
    names: ["Trovafloxacin"],
  },
  {
    primary_id: "D03WPE",
    names: ["AB-NECA", "Aminobenzyl-5'-N-Ethylcarboxamidoadenosine"],
  },
  {
    primary_id: "D03WPI",
    names: ["NC-190"],
  },
  {
    primary_id: "D03WPO",
    names: ["PMID26666989-Compound-Figure9middle10"],
  },
  {
    primary_id: "D03WPP",
    names: ["Necitumumab"],
  },
  {
    primary_id: "D03WQK",
    names: [
      "Alpha-D-Glucopyranosyl-2-Carboxylic Acid Amide",
      "ALPHA-D-GLUCOPYRANOSYL-2-CARBOXYLIC ACID AMIDE",
      "CHEMBL135302",
      "GLG",
      "AC1L9IMU",
      "SCHEMBL5961308",
      "3,4,5-Trihydroxy-6-Hydroxymethyl-Tetrahydro-Pyran-2-Carboxylic Acid Amide",
      "(Alpha-D-Glucopyranosyl)Formamide",
      "BDBM50306562",
      "DB02720",
      "(2S,3R,4S,5S,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Oxane-2-Carboxamide",
      "(2S,3R,4S,5S,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Tetrahydro-2H-Pyran-2-Carboxamide",
      "(2S,3R,4S,5S,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Tetrahydro-2H-Pyran-2-Carboxamide (Non-Preferred Name)",
    ],
  },
  {
    primary_id: "D03WQS",
    names: [
      "BMS-582664",
      "Brivanib Alaninate",
      "BMS 582664",
      "BMS582664",
      "BMS-582664, Brivanib Alaninate",
      "Brivanib Alaninate (INN/USAN)",
      "L-Alanine, (1R)-2-((4-((4-Fluoro-2-Methyl-1H-Indol-5-Yl)Oxy)-5-Methylpyrrolo(2,1-F)(1,2,4)Triazin-6-Yl)Oxy)-1-Methylethyl Ester",
    ],
  },
  {
    primary_id: "D03WRJ",
    names: ["Lisofylline"],
  },
  {
    primary_id: "D03WRN",
    names: ["AVT-02 UE"],
  },
  {
    primary_id: "D03WSE",
    names: ["XomaZyme-Mel"],
  },
  {
    primary_id: "D03WTE",
    names: ["BT-301"],
  },
  {
    primary_id: "D03WTT",
    names: ["RG-13022"],
  },
  {
    primary_id: "D03WTU",
    names: ["TG4-155"],
  },
  {
    primary_id: "D03WTX",
    names: ["SBC-105"],
  },
  {
    primary_id: "D03WUH",
    names: ["YM-099"],
  },
  {
    primary_id: "D03WUJ",
    names: ["DPPA-4"],
  },
  {
    primary_id: "D03WUU",
    names: ["Multivalent (HIVIS 07) DNA Vaccine Derma Vax/ZetaJet"],
  },
  {
    primary_id: "D03WVP",
    names: ["IBU-PC"],
  },
  {
    primary_id: "D03WWG",
    names: ["Elcatonin", "Diatin (TN)"],
  },
  {
    primary_id: "D03WWW",
    names: ["Hepcidin"],
  },
  {
    primary_id: "D03WXL",
    names: ["Staphguard"],
  },
  {
    primary_id: "D03WXM",
    names: ["C-Linked Disaccharide Biphenyl Mannoside Derivative 4"],
  },
  {
    primary_id: "D03WYF",
    names: ["L-695902"],
  },
  {
    primary_id: "D03WYO",
    names: ["KETOCYCLAZOCINE"],
  },
  {
    primary_id: "D03WYU",
    names: ["CDDO-Me", "2-Cyano-3,12-Dioxooleana-1,9-Dien-28-Oic Acid Methyl Ester"],
  },
  {
    primary_id: "D03WYV",
    names: ["TAK-225", "2,2-Diethyl-3-(7-Methyl[1,2,4]triazolo[1,5-B]pyridazin-6-Yloxy)Propanesulfonamide"],
  },
  {
    primary_id: "D03WZJ",
    names: ["A33-Ac-225", "A33-Targeting MAb And Ac-225 Conjugate (Metastatic Colon Cancer), Actinium Pharmaceuticals"],
  },
  {
    primary_id: "D03XAE",
    names: [
      "[2-(5-Ethyl-1H-Indol-3-Yl)-Ethyl]-Dimethyl-Amine",
      "CHEMBL50492",
      "171783-25-8",
      "5-Ethyl-DMT",
      "DTXSID30444736",
      "BDBM50049099",
    ],
  },
  {
    primary_id: "D03XAK",
    names: ["Quinoline Carboxamide Derivative 1"],
  },
  {
    primary_id: "D03XAO",
    names: [
      "NVX-108",
      "Dodecafluoropentane",
      "PERFLUOROPENTANE",
      "678-26-2",
      "Pentane, Dodecafluoro-",
      "Perfluoro-N-Pentane",
      "Perfuoro-N-Pentane",
      "Flutec PP50",
      "N-Perfluoropentane",
      "UNII-483AU1Y5CZ",
      "EINECS 211-647-5",
      "BRN 1712388",
      "483AU1Y5CZ",
      "CHEBI:39428",
      "NJCBUSHGCBERSK-UHFFFAOYSA-N",
      "R 41(12)",
      "NCGC00167420-01",
      "1,1,1,2,2,3,3,4,4,5,5,5-Dodecafluoropentane",
      "Pentane,1,1,1,2,2,3,3,4,4,5,5,5-Dodecafluoro-",
      "Pentane, 1,1,1,2,2,3,3,4,4,5,5,5-Dodecafluoro-",
      "Perflenapent [USAN]",
      "Perflenapent [USAN:INN]",
      "Perflenapentum",
      "Fluorinert FC 87",
      "Fluorinertr(Fc-87)",
      "Dodecaf",
    ],
  },
  {
    primary_id: "D03XAQ",
    names: ["CH-5132799", "CH-5033855", "CH-5061565", "CH-5089788", "CH-5108135", "CH-5111436", "CH-5138134"],
  },
  {
    primary_id: "D03XBA",
    names: ["PT-102"],
  },
  {
    primary_id: "D03XBB",
    names: ["DX-2400", "DX-2410", "MMP Inhibitors (Fully Human Antibodies), Dyax"],
  },
  {
    primary_id: "D03XBT",
    names: ["Prostatix", "Antioxidant Prodrug (Prostate Cancer), Cardax Pharmaceuticals"],
  },
  {
    primary_id: "D03XCG",
    names: ["1-(4-Hexylphenyl)-3-(Propylamino)Propan-1-One", "Beta-Aminophenylketone, 3k", "CHEMBL396873", "BDBM18824"],
  },
  {
    primary_id: "D03XCR",
    names: ["Simotaxel"],
  },
  {
    primary_id: "D03XDD",
    names: ["OG-45"],
  },
  {
    primary_id: "D03XDN",
    names: ["PD-174265"],
  },
  {
    primary_id: "D03XDX",
    names: ["Tarazepide"],
  },
  {
    primary_id: "D03XES",
    names: ["Eptazocine Hbr"],
  },
  {
    primary_id: "D03XET",
    names: ["POD-003"],
  },
  {
    primary_id: "D03XEU",
    names: [
      "2-(4-Hydroxy-Phenyl)-7-Methoxy-Benzofuran-5-Ol",
      "CHEMBL361226",
      "SCHEMBL3928011",
      "KEWMBPCQWDVBAS-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D03XEV",
    names: ["NDC-1187"],
  },
  {
    primary_id: "D03XEY",
    names: ["Epstein-Barr Virus-Specific Immunotherapy"],
  },
  {
    primary_id: "D03XFH",
    names: ["ACTR-BCMA", "ACTR087 + SEA-BCMA"],
  },
  {
    primary_id: "D03XGJ",
    names: [
      "(2,6-Diamino-Pyridin-3-Yl)-Phenyl-Methanone",
      "(2,6-Diaminopyridin-3-Yl)(Phenyl)Methanone",
      "157924-28-2",
      "3-Acyl-2,6-Diaminopyridine Deriv. 3c",
      "(2,6-Diamino-3-Pyridinyl)Phenylmethanone",
      "AC1NS9QT",
      "SCHEMBL4419093",
      "BDBM6908",
      "CHEMBL193590",
      "3-Benzoylpyridine-2,6-Diamine",
      "852997-66-1",
    ],
  },
  {
    primary_id: "D03XGO",
    names: ["Nematode Anticoagulant Proteins"],
  },
  {
    primary_id: "D03XHD",
    names: [
      "A-11259",
      "A-00000762",
      "A-00000764",
      "A-00026158",
      "A-00026161",
      "A-764",
      "Shikimate Kinase Inhibitors (Antibacterial)",
      "Shikimate Kinase Inhibitors (Antibacterial), Arrow",
    ],
  },
  {
    primary_id: "D03XHE",
    names: ["Alloswitch-1"],
  },
  {
    primary_id: "D03XHF",
    names: ["2-Fluoro-5-[5-(4-Hydroxyphenyl)-2-Thienyl]phenol", "CHEMBL570595"],
  },
  {
    primary_id: "D03XHS",
    names: ["ACT-178882"],
  },
  {
    primary_id: "D03XIH",
    names: ["ABS-103", "ABS-103R", "R-103", "R-ABS-103"],
  },
  {
    primary_id: "D03XIS",
    names: ["R-568"],
  },
  {
    primary_id: "D03XIY",
    names: ["7-Phenyl-1-(Thiazol-2-Yl)-Heptan-1-One", "CHEMBL468221", "SCHEMBL2161939"],
  },
  {
    primary_id: "D03XJE",
    names: ["2-Adamantan-2-Ylidenemethyl-Benzooxazol-6-Ol", "CHEMBL187900"],
  },
  {
    primary_id: "D03XJP",
    names: ["Y-175L"],
  },
  {
    primary_id: "D03XKJ",
    names: ["N2-{4-[(4-Chlorobenzyl)Oxy]benzyl}glycinamide", "CHEMBL396267"],
  },
  {
    primary_id: "D03XKK",
    names: ["6-(3-Methyl-Benzyloxy)-9H-Purin-2-Ylamine"],
  },
  {
    primary_id: "D03XKL",
    names: ["1,6-Bis(4-(3-Chlorophenyl)Piperazin-1-Yl)Hexane", "CHEMBL1171823", "SCHEMBL4525555"],
  },
  {
    primary_id: "D03XKM",
    names: ["PMID28074661-Compound-US20100022547C82"],
  },
  {
    primary_id: "D03XKU",
    names: ["4-Ethyl-3,4-Dihydroquinazolin-2-Amine", "CHEMBL270841"],
  },
  {
    primary_id: "D03XLO",
    names: ["PEV-8"],
  },
  {
    primary_id: "D03XLS",
    names: [
      "D-CCPene",
      "Cppene",
      "137424-80-7",
      "4-(3-Phosphonoprop-2-Enyl)Piperazine-2-Carboxylic Acid",
      "3-(2-Carboxypiperazine-4-Yl)-1-Propenyl-1-Phosphonic Acid",
      "C8H15N2O5P",
      "2-Piperazinecarboxylic Acid, 4-(3-Phosphono-2-Propenyl)-",
      "Midafotee",
      "SDZ EAA 494, (E)-Isomer",
      "AC1O5NJ1",
      "SCHEMBL726553",
      "GTPL4170",
      "CHEMBL224678",
      "RT-012176",
      "LS-172991",
      "LS-172816",
      "J-003616",
      "(E)-4-(3-Phosphonoprop-2-Enyl)Piperazine-2-Carboxylic Acid",
      "4-[(E)-3-Phosphonoprop-2-Enyl]piperazine-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D03XLX",
    names: ["CP-778875", "Dyslipidemia Therapy, Pfizer", "CP-778,875"],
  },
  {
    primary_id: "D03XMD",
    names: ["SB 699551"],
  },
  {
    primary_id: "D03XMR",
    names: ["N-Methyl-N-Ethyl Estrone-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D03XOB",
    names: [
      "5-Hydroxymethylbenzo[b]thiophen-2-Ylboronic Acid",
      "CHEMBL395398",
      "Boronic Acid, B-(5-Hydroxybenzo[b]thien-2-Yl)-",
      "959636-67-0",
      "BDBM50225392",
    ],
  },
  {
    primary_id: "D03XOC",
    names: [
      "Trilostane",
      "Desopan",
      "Desopan (TN)",
      "Modrenal (TN)",
      "Vetoryl (TN)",
      "Vetoryl, Trilostane",
      "Win-24540",
      "Trilostane (JAN/USAN)",
      "(4alpha,5alpha,17beta)-3,17-Dihydroxy-4,5-Epoxyandrost-2-Ene-2-Carbonitrile",
      "3,17beta-Dihydroxy-4alpha,5-Epoxy-5alpha-Androst-2-Ene-2-Carbonitrile",
    ],
  },
  {
    primary_id: "D03XPV",
    names: ["Ginkgolide B (GKB)"],
  },
  {
    primary_id: "D03XQB",
    names: [
      "(+/-)-7-Fluoro-2-(4-Methoxyphenyl)Chroman-4-One",
      "CHEMBL598720",
      "844-66-6",
      "4h-1-Benzopyran-4-One,7-Fluoro-2,3-Dihydro-2-(4-Methoxyphenyl)-",
      "4H-1-Benzopyran-4-One, 7-Fluoro-2,3-Dihydro-2-(4-Methoxyphenyl)-",
      "AC1NRRFL",
      "CTK3D0370",
      "DTXSID70414918",
      "BDBM50310194",
      "AKOS030553462",
      "KB-306159",
      "7-Fluoro-2-(4-Methoxyphenyl)Chroman-4-One",
    ],
  },
  {
    primary_id: "D03XQI",
    names: ["Fentanyl Transdermal"],
  },
  {
    primary_id: "D03XRA",
    names: ["GSK2661380"],
  },
  {
    primary_id: "D03XRZ",
    names: ["PF-05094037"],
  },
  {
    primary_id: "D03XSJ",
    names: [
      "Buclizine",
      "Bucladin",
      "Buclifen",
      "Buclina",
      "Buclizina",
      "Buclizinum",
      "Buclodin",
      "Histabuticine",
      "Histabutizine",
      "Histabutyzine",
      "Hitabutyzyne",
      "Posdel",
      "Softran",
      "Vibazine",
      "Aphilan R",
      "Buclizine Dihydrochloride",
      "Buclizine Hydrochloride",
      "Component Of Softran",
      "Histabutyzine Dihydrochloride",
      "Histabutyzine Hydrochloride",
      "Vibazine Hydrochloride",
      "AH 2526",
      "UCB 4445",
      "Aphilan-R Base",
      "Buclina (TN)",
      "Buclizina [INN-Spanish]",
      "Buclizine (INN)",
      "Buclizine [INN:BAN]",
      "Buclizine, Hydrochloride",
      "Buclizinum [INN-Latin]",
      "Component Of Bucladin-S",
      "Migraleve (TN)",
      "Buclizine Hydrochloride (*dihydrochloride*)",
      "Piperazine, 1-[(4-Chlorophenyl)Phenylmethyl]-4-[[4-(1,1-Dimethylethyl)Phenyl]methyl]-, Dihydrochloride",
      "Piperazine, 1-((4-Chlorophenyl)Phenylmethyl)-4-((4-(1,1-Dimethylethyl)Phenyl)Methyl)-(9CI)",
      "1-(4-Tert-Butylbenzyl)-4-((4-Chlorophenyl)(Phenyl)Methyl)Piperazine",
      "1-(4-Tert-Butylbenzyl)-4-[(4-Chlorophenyl)(Phenyl)Methyl]piperazine",
      "1-(P-Tert-Butylbenzyl)-4-(4-Chloro-Alpha-Phenylbenzyl)Piperazine",
      "1-[(4-Chlorophenyl)(Phenyl)Methyl]-4-{[4-(1,1-Dimethylethyl)Phenyl]methyl}piperazine",
      "1-[(4-Tert-Butylphenyl)Methyl]-4-[(4-Chlorophenyl)-Phenylmethyl]piperazine",
    ],
  },
  {
    primary_id: "D03XST",
    names: ["MC-207252"],
  },
  {
    primary_id: "D03XSX",
    names: ["CEL-2000"],
  },
  {
    primary_id: "D03XTA",
    names: ["PMID28870136-Compound-49"],
  },
  {
    primary_id: "D03XTC",
    names: [
      "Esmolol",
      "ASL 8052-001",
      "Brevibloc (TN)",
      "Esmolol (INN)",
      "Esmolol [INN:BAN]",
      "Methyl P-[2-Hydroxy-3-(Isopropylamino)Propoxy]hydrocinnamate",
      "Methyl P-(2-Hydroxy-3-(Isopropylamino)Propoxy)Hydrocinnamate",
      "Methyl 3-[4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl]propanoate",
      "Methyl 4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)Benzenepropanoate",
      "Methyl 3-[4-({2-Hydroxy-3-[(1-Methylethyl)Amino]propyl}oxy)Phenyl]propanoate",
      "Methyl 3-{4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl}propanoate",
      "Benzenepropanoic Acid, 4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)-, Methyl Ester",
      "(+-)-Esmolol",
      "(+-)-Methyl P-(2-Hydroxy-3-(Isopropylamino)Propoxy)Hydrocinnamate",
    ],
  },
  {
    primary_id: "D03XTY",
    names: [
      "APR-246",
      "APR-017",
      "APR-304",
      "APR-305",
      "APR-310",
      "PRIMA-1",
      "PRIMA-1-MET",
      "Apoptosis Stimulators (Iv, Cancer), Karolinska Institute",
      "Mutant P53 Reactivation Compounds (Iv, Cancer), Karolinksa Institute",
      "P53 Modulators (Iv, Cancer), Aprea",
    ],
  },
  {
    primary_id: "D03XUQ",
    names: ["ABS-212"],
  },
  {
    primary_id: "D03XVJ",
    names: ["C7/3-Phth"],
  },
  {
    primary_id: "D03XVT",
    names: [
      "(S)-1,7-Diphenyl-6(E)-Hepten-3-Ol",
      "CHEMBL461017",
      "(S)-1,7-Diphenylhept-6-En-3-Ol",
      "BDBM50300189",
      "(3S,6E)-1,7-Diphenyl-6-Heptene-3-Ol",
    ],
  },
  {
    primary_id: "D03XWL",
    names: ["Low Dose ITI-007"],
  },
  {
    primary_id: "D03XWN",
    names: ["KLH/IMG"],
  },
  {
    primary_id: "D03XXI",
    names: [
      "8-(Biphenyl-4-Yloxy)-2-Oxo-Octanoic Acid",
      "CHEMBL115049",
      "436150-72-0",
      "SCHEMBL7368556",
      "CTK1D2674",
      "DTXSID40658342",
      "BDBM50221807",
      "8-[([1,1'-Biphenyl]-4-Yl)Oxy]-2-Oxooctanoic Acid",
      "Octanoic Acid, 8-([1,1'-Biphenyl]-4-Yloxy)-2-Oxo-",
    ],
  },
  {
    primary_id: "D03XXT",
    names: ["SGC-CBP30"],
  },
  {
    primary_id: "D03XXV",
    names: ["6-Methyl-4-(4-Phenylpiperazin-1-Yl)Coumarin", "CHEMBL461305"],
  },
  {
    primary_id: "D03XYG",
    names: ["1-Methyl-1,3-Dihydro-Indol-2-One"],
  },
  {
    primary_id: "D03XYK",
    names: ["9-Benzyl-6-(4-Fluoro-Phenylsulfanyl)-9H-Purine"],
  },
  {
    primary_id: "D03XYW",
    names: ["Fenoprofen"],
  },
  {
    primary_id: "D03XZJ",
    names: ["(E)-3-(4-(Methylsulfonyl)Styryl)Thiophene", "CHEMBL1288782"],
  },
  {
    primary_id: "D03XZP",
    names: ["IL-12 Gene Therapy"],
  },
  {
    primary_id: "D03XZT",
    names: ["Cyclic Compound 1"],
  },
  {
    primary_id: "D03YAA",
    names: ["PMID27215781-Compound-28"],
  },
  {
    primary_id: "D03YAG",
    names: ["PIPERROLEIN B"],
  },
  {
    primary_id: "D03YAS",
    names: ["ONO-AE1-437", "16-[3-(Methoxymethyl)Phenyl]-5-Thia-17,18,19,20-Tetranorprostaglandin E1"],
  },
  {
    primary_id: "D03YBH",
    names: ["LTD4"],
  },
  {
    primary_id: "D03YBJ",
    names: ["DS-8895"],
  },
  {
    primary_id: "D03YBQ",
    names: ["DNL151"],
  },
  {
    primary_id: "D03YBR",
    names: [
      "6-Phenylmethanesulfonyl-2H-Pyridazin-3-One",
      "92016-73-4",
      "CHEMBL199028",
      "BDBM16611",
      "Benzyl-Substituted Sulfonylpyridazinone, 15",
    ],
  },
  {
    primary_id: "D03YCT",
    names: ["TVX-004"],
  },
  {
    primary_id: "D03YCW",
    names: [
      "4'-Demethyl-Epipodophyllotoxin",
      "4'-Demethylepipodophyllotoxin",
      "6559-91-7",
      "4'-DMEP",
      "4-Demethylepipodophyllotoxin",
      "UNII-X0S6I23X6L",
      "(-)-4'-Demethylepipodophyllotoxin",
      "BRN 1358259",
      "X0S6I23X6L",
      "CHEBI:74422",
      "C21H20O8",
      "(5R,5aR,8aR,9S)-9-Hydroxy-5-(4-Hydroxy-3,5-Dimethoxyphenyl)-5,8,8a,9-Tetrahydrofuro[3',4':6,7]naphtho[2,3-D][1,3]dioxol-6(5aH)-One",
      "(5s,5ar,8ar,9r)-9-(3,5-Dimethoxy-4-Oxidanyl-Phenyl)-5-Oxidanyl-5a,6,8a,9-Tetrahydro-5h-[2]benzofuro[6,5-F][1,3]benzodioxol-8-One",
      "Furo(3',4':6,7)Naphtho(2,3-D)-1,3-Dioxol-6(5ah)-One, 5,8,8a,9-Tetrahydro-9-Hydrox",
    ],
  },
  {
    primary_id: "D03YDG",
    names: ["UFP-502"],
  },
  {
    primary_id: "D03YDP",
    names: ["C[Glu22-Orn26][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D03YEG",
    names: ["REGN-910"],
  },
  {
    primary_id: "D03YEM",
    names: ["CD19/CD22 CAR T-Cells"],
  },
  {
    primary_id: "D03YET",
    names: ["Coal Tar"],
  },
  {
    primary_id: "D03YFL",
    names: ["PMID25666693-Compound-71"],
  },
  {
    primary_id: "D03YGL",
    names: ["RHIgM22"],
  },
  {
    primary_id: "D03YGP",
    names: ["CC-8490"],
  },
  {
    primary_id: "D03YGR",
    names: [
      "Cinacalcet",
      "Cinacalcet [INN]",
      "AMG 073",
      "AMG073",
      "Cinacalcet (USAN)",
      "Mimpara (TN)",
      "Sensipar (TN)",
      "N-[(1R)-1-(1-Naphthyl)Ethyl]-3-[3-(Trifluoromethyl)Phenyl]propan-1-Amine",
      "N-[(1R)-1-Naphthalen-1-Ylethyl]-3-[3-(Trifluoromethyl)Phenyl]propan-1-Amine",
      "N-((1R)-1-(1-Naphthyl)Ethyl)-3-(3-(Trifluoromethyl)Phenyl)Propan-1-Amine",
      "N-((1R)-1-(Naphthalen-1-Yl)Ethyl)-3-(3-(Trifluoromethyl)Phenyl)Propan-1-Amine",
      "(R)-N-(1-(Naphthalen-1-Yl)Ethyl)-3-(3-(Trifluoromethyl)Phenyl)Propan-1-Amine",
      "(R)-Alpha-Methyl-N-[3-[3-(Trifluoromethyl)Phenyl]propyl]-1-Naphthalenemethane Amine",
    ],
  },
  {
    primary_id: "D03YGX",
    names: ["SP-D"],
  },
  {
    primary_id: "D03YHE",
    names: ["N-Acetylmethionine"],
  },
  {
    primary_id: "D03YHF",
    names: ["Propan-2-One O-4-Butoxybenzylcarbamoyl Oxime", "CHEMBL597131"],
  },
  {
    primary_id: "D03YHT",
    names: ["[Ncy(SO,methyl)10]acyline", "CHEMBL389248"],
  },
  {
    primary_id: "D03YHZ",
    names: ["6-Isoquinolin-4-Yl-3,4-Dihydroquinolin-2(1H)-One", "CHEMBL456390", "SCHEMBL1120250", "BDBM50273813"],
  },
  {
    primary_id: "D03YIT",
    names: ["CL-186659"],
  },
  {
    primary_id: "D03YIX",
    names: ["Pneumostem"],
  },
  {
    primary_id: "D03YJC",
    names: ["SKL-NP"],
  },
  {
    primary_id: "D03YJD",
    names: ["VU-71"],
  },
  {
    primary_id: "D03YJW",
    names: ["Fus-1 Tumor Suppressor Gene Therapy - Nanoparticle Formulation"],
  },
  {
    primary_id: "D03YJZ",
    names: ["SDZ-89-104"],
  },
  {
    primary_id: "D03YKE",
    names: ["FG-4592"],
  },
  {
    primary_id: "D03YKO",
    names: [
      "VT-346",
      "Cytokine Binding Proteins, Viron",
      "VT-300 Series, Viron",
      "TNF-Alpha Binding Protein (Rheumatoid Arthritis/ Psoriasis), Viron",
    ],
  },
  {
    primary_id: "D03YKQ",
    names: ["AD-452"],
  },
  {
    primary_id: "D03YLN",
    names: [
      "CP-67015",
      "100325-51-7",
      "CCRIS 2955",
      "CP 67015",
      "CP-67,015",
      "3-Quinolinecarboxylic Acid, 1,4-Dihydro-6,8-Difluoro-1-Ethyl-4-Oxo-7-(4-Pyridinyl)-",
      "3-Quinolinecarboxylicacid, 1-Ethyl-6,8-Difluoro-1,4-Dihydro-4-Oxo-7-(4-Pyridinyl)-",
      "ACMC-20m3dd",
      "AC1L2S7K",
      "SCHEMBL9304919",
      "CTK0H9319",
      "DTXSID00143163",
      "AKOS030543955",
      "LS-141575",
      "1-Ethyl-6,8-Difluoro-4-Oxo-7-Pyridin-4-Ylquinoline-3-Carboxylic Acid",
      "3-Quinolinecarboxylic Acid, 1-Ethyl-6,8-Difluoro-1,4-Dihydro-4-Oxo-7-(4-Pyridinyl)-",
      "3-Quinolinecarboxylic Acid, 1-Ethyl-6,8-Difluoro-1,4-Dih",
    ],
  },
  {
    primary_id: "D03YLQ",
    names: ["[125I]BE-2254"],
  },
  {
    primary_id: "D03YLX",
    names: [
      "3-Methyl-9H-Beta-Carboline",
      "3-Methyl-Beta-Carboline",
      "3-Methyl-9h-Pyrido[3,4-B]indole",
      "CHEMBL344859",
      "18203-06-0",
      "3-Methyl-B-Carboline",
      "SCHEMBL3333771",
      "XOHBPUCYRSORIZ-UHFFFAOYSA-N",
      "BDBM50035527",
    ],
  },
  {
    primary_id: "D03YLY",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp21-]", "CHEMBL411863", "BDBM50372589"],
  },
  {
    primary_id: "D03YLZ",
    names: ["Thiamphenicol"],
  },
  {
    primary_id: "D03YMA",
    names: ["Aryl Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D03YMD",
    names: ["RP-805"],
  },
  {
    primary_id: "D03YMX",
    names: [
      "3,4,6-Tribromo-2-(2,4-Dibromo-Phenoxy)-Phenol",
      "CHEMBL186098",
      "6-Hydroxy-2,2',3,4',5-Pentabromodiphenyl Ether",
      "ZINC95606620",
      "BDBM50150782",
      "497069-29-1",
      "2-(2,4-Dibromophenoxy)-3,4,6-Tribromophenol",
    ],
  },
  {
    primary_id: "D03YOS",
    names: [
      "Phenoxodiol",
      "Dehydroequol",
      "Idronoxil",
      "Haginin E",
      "NV 06",
      "NV-06",
      "Idronoxil (USAN/INN)",
      "2H-1-Benzopyran-7-0,1,3-(4-Hydroxyphenyl)",
      "3-(4-Hydroxyphenyl)-2H-1-Benzopyran-7-Ol",
      "3-(4-Hydroxyphenyl)-2H-Chromen-7-Ol",
      "7-Hydroxy-3-Hydroxyphenyl-1H-Benzopyran",
    ],
  },
  {
    primary_id: "D03YOU",
    names: ["PMID25399762-Compound-Figure4-Gypensapogenin A"],
  },
  {
    primary_id: "D03YPJ",
    names: ["SB 206553"],
  },
  {
    primary_id: "D03YPL",
    names: ["RP-70676"],
  },
  {
    primary_id: "D03YPR",
    names: ["HL-1983"],
  },
  {
    primary_id: "D03YQY",
    names: [
      "(2S,3S)-2-(M-Tolyl)-3,5,5-Trimethylmorpholin-2-Ol",
      "CHEMBL1173277",
      "SCHEMBL15007654",
      "RLZACOPBIXSOEX-SMDDNHRTSA-N",
    ],
  },
  {
    primary_id: "D03YRN",
    names: ["MRS2578"],
  },
  {
    primary_id: "D03YRO",
    names: ["(4S,5R)-4,5-Dipropyl-Oxazolidin-(2Z)-Ylideneamine", "CHEMBL322085", "BDBM50138197"],
  },
  {
    primary_id: "D03YSJ",
    names: ["NBI-29"],
  },
  {
    primary_id: "D03YSL",
    names: ["CAT-1000"],
  },
  {
    primary_id: "D03YSQ",
    names: [
      "SAR-405838",
      "AT-219",
      "MI-147",
      "MI-219",
      "MI-319",
      "MI-43",
      "MI-5",
      "MI-63",
      "MI-772",
      "MI-773",
      "MI-519-64",
      "P53-HDM2 Protein Interaction Inhibitors (Cancer)",
      "P53-HDM2 Protein Interaction Inhibitors (Cancer), Ascenta/Sanofi",
      "P53-HDM2 Protein Interaction Inhibitors (Cancer), Ascenta/Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D03YUL",
    names: ["AZ12260493", "AZ 12260493", "AZ-12260493"],
  },
  {
    primary_id: "D03YUT",
    names: [
      "2-Chloro-1-(5-(Pyridin-2-Yl)Oxazol-2-Yl)Ethanone",
      "945414-11-9",
      "Alpha-Ketooxazole, 14f",
      "SCHEMBL710273",
      "CHEMBL229323",
      "BDBM23135",
      "MKVLXIACPLGLLP-UHFFFAOYSA-N",
      "ZINC14980143",
      "DA-40252",
      "FT-0713758",
    ],
  },
  {
    primary_id: "D03YUW",
    names: ["SBB007833"],
  },
  {
    primary_id: "D03YVB",
    names: ["HG-9-91-01"],
  },
  {
    primary_id: "D03YVL",
    names: [
      "Cdk1 Inhibitor",
      "3-(2-Chloro-3-Indolylmethylene)-1,3-Dihydroindol-2-One",
      "CHEMBL261425",
      "220749-41-7",
      "K00028",
      "AC1NV88J",
      "SCHEMBL1394886",
      "GTPL5944",
      "CHEBI:113538",
      "HMS3229C12",
      "NSC720148",
      "BDBM50375670",
      "HSCI1_000249",
      "CCG-206822",
      "NSC-720148",
      "RT-011955",
      "J-014469",
      "BRD-K81836716-001-01-7",
      "3-[(2-Chloro-1H-Indole-3-Yl)Methylene]-1H-Indole-2(3H)-One",
      "(3E)-3-[(2-Chloro-1H-Indol-3-Yl)Methylidene]-1H-Indol-2-One",
    ],
  },
  {
    primary_id: "D03YVO",
    names: ["Methylnaltrexone Bromide"],
  },
  {
    primary_id: "D03YVR",
    names: ["Oxamflatin"],
  },
  {
    primary_id: "D03YVX",
    names: ["SC66"],
  },
  {
    primary_id: "D03YWY",
    names: ["PMID27215781-Compound-30"],
  },
  {
    primary_id: "D03YXB",
    names: ["(+)-(1R,1'S)-Berbamunine Hydrochloride", "CHEMBL503882", "(+)-(1R,1'S)-Berbamunine HCl"],
  },
  {
    primary_id: "D03YXG",
    names: ["ISIS 116632"],
  },
  {
    primary_id: "D03YXI",
    names: ["PIKAMILONE"],
  },
  {
    primary_id: "D03YZJ",
    names: ["ALO-02 Oxycodone- Naltrexone Core", "ALO-02 Oxycodone-Naltrexone Core"],
  },
  {
    primary_id: "D03YZT",
    names: [
      "5-Ethyl-Oxazolidin-(2Z)-Ylideneamine",
      "CHEMBL108352",
      "5-Ethyloxazolidine-2-Imine",
      "SCHEMBL8968728",
      "BDBM50138193",
      "AKOS013533140",
      "AKOS006375671",
    ],
  },
  {
    primary_id: "D03ZAE",
    names: ["Peg-G-CSF"],
  },
  {
    primary_id: "D03ZAT",
    names: ["Nitric Oxide Topical"],
  },
  {
    primary_id: "D03ZBL",
    names: ["MMS-255"],
  },
  {
    primary_id: "D03ZBM",
    names: ["NPC-567"],
  },
  {
    primary_id: "D03ZBN",
    names: ["Olprinone"],
  },
  {
    primary_id: "D03ZBS",
    names: ["4-Cyanophenyl Ethyl Dodecylphosphonate", "CHEMBL462582"],
  },
  {
    primary_id: "D03ZBT",
    names: ["Crizotinib"],
  },
  {
    primary_id: "D03ZCG",
    names: [
      "CMV Vaccine",
      "CMV Vaccine, Aviron",
      "CMV Vaccine, MedImmune",
      "Cytomegaloviris Vaccine, MedImmune",
      "Cytomegalovirusvaccine, Aviron",
      "Vaccine (CMV), MedImmune",
      "Live Attenuated Vaccine (Recombinant/Injectable, CMV), Aviron",
    ],
  },
  {
    primary_id: "D03ZCH",
    names: ["4SCAR19 And 4SCAR20"],
  },
  {
    primary_id: "D03ZDC",
    names: [
      "NE21650",
      "CHEMBL414849",
      "NE-21650",
      "Ne 21650",
      "GTPL3167",
      "BDBM50173792",
      "2-(2-Aminophenyl)-1-Hydroxyethane-1,1-Diyldiphosphonic Acid",
      "[2-(2-Aminophenyl)-1-Hydroxy-1-Phosphonoethyl]phosphonic Acid",
      "[2-(2-Amino-Phenyl)-1-Hydroxy-1-Phosphono-Ethyl]-Phosphonic Acid",
      "Hydrogen [2-(2-Azaniumylphenyl)-1-Hydroxy-1-Phosphonatoethyl]phosphonate",
    ],
  },
  {
    primary_id: "D03ZDX",
    names: ["SM-88"],
  },
  {
    primary_id: "D03ZDY",
    names: ["1-(2-(2-Bromophenyl)-1-Phenylethyl)Piperazine", "CHEMBL378418", "SCHEMBL5442011"],
  },
  {
    primary_id: "D03ZDZ",
    names: ["Carboxylated Glucosamine"],
  },
  {
    primary_id: "D03ZEH",
    names: ["EPO"],
  },
  {
    primary_id: "D03ZEL",
    names: ["CD3254", "CD 3254", "CD-3254"],
  },
  {
    primary_id: "D03ZFG",
    names: ["Cerulenin"],
  },
  {
    primary_id: "D03ZFN",
    names: ["Tromethamine"],
  },
  {
    primary_id: "D03ZFX",
    names: ["D-254C", "CHEMBL258725", "SCHEMBL4886148"],
  },
  {
    primary_id: "D03ZGF",
    names: ["Pyrrolidine Derivative 10"],
  },
  {
    primary_id: "D03ZGV",
    names: ["Co-60549"],
  },
  {
    primary_id: "D03ZHB",
    names: [
      "5-Chloro-4-Ethyl-3,4-Dihydroquinazolin-2-Amine",
      "CHEMBL270176",
      "918134-92-6",
      "2-Quinazolinamine, 5-Chloro-4-Ethyl-1,4-Dihydro-",
      "5-Chloro-4-Ethyl-1,4-Dihydroquinazolin-2-Amine",
      "SCHEMBL3570857",
      "CTK3H8593",
      "DTXSID00659173",
    ],
  },
  {
    primary_id: "D03ZHE",
    names: ["MDX-070"],
  },
  {
    primary_id: "D03ZHK",
    names: ["LYCOGARUBIN B"],
  },
  {
    primary_id: "D03ZHM",
    names: ["ISIS 183912"],
  },
  {
    primary_id: "D03ZHN",
    names: ["SL-65.1708"],
  },
  {
    primary_id: "D03ZIH",
    names: ["Oxalylaminobenzoic Acid"],
  },
  {
    primary_id: "D03ZIK",
    names: ["MT-001"],
  },
  {
    primary_id: "D03ZIP",
    names: ["LOAd703"],
  },
  {
    primary_id: "D03ZIS",
    names: ["PERFOSFAMIDE"],
  },
  {
    primary_id: "D03ZIW",
    names: ["ICR 62"],
  },
  {
    primary_id: "D03ZJE",
    names: ["PAI-1"],
  },
  {
    primary_id: "D03ZJK",
    names: ["NNZ-3006"],
  },
  {
    primary_id: "D03ZKE",
    names: ["CS-003"],
  },
  {
    primary_id: "D03ZKM",
    names: ["UX-001"],
  },
  {
    primary_id: "D03ZKN",
    names: ["Beta-Guanidinopropionic Acid", "Beta-GPA"],
  },
  {
    primary_id: "D03ZLN",
    names: ["SEL-403"],
  },
  {
    primary_id: "D03ZLR",
    names: ["Apimostinel"],
  },
  {
    primary_id: "D03ZME",
    names: ["Darinaparsin"],
  },
  {
    primary_id: "D03ZMK",
    names: ["NTP-6009"],
  },
  {
    primary_id: "D03ZMQ",
    names: [
      "COUMARIN",
      "Coumarin",
      "2H-Chromen-2-One",
      "91-64-5",
      "2H-1-Benzopyran-2-One",
      "Cumarin",
      "1,2-Benzopyrone",
      "Rattex",
      "Tonka Bean Camphor",
      "Coumarinic Anhydride",
      "Chromen-2-One",
      "Coumarine",
      "Benzo-Alpha-Pyrone",
      "Cis-O-Coumarinic Acid Lactone",
      "O-Hydroxycinnamic Acid Lactone",
      "O-Hydroxycinnamic Lactone",
      "2-Oxo-1,2-Benzopyran",
      "Coumarinic Lactone",
      "Kumarin",
      "Benzo-A-Pyrone",
      "Kumarin [Czech]",
      "5,6-Benzo-2-Pyrone",
      "2H-1-Benzopyran, 2-Oxo-",
      "5,6-Benzo-Alpha-Pyrone",
      "2H-Benzo(B)Pyran-2-One",
      "2H-Benzo[b]pyran-2-One",
      "Caswell No. 259C",
      "O-Coumaric Acid Lactone",
    ],
  },
  {
    primary_id: "D03ZMX",
    names: ["Monoaryl-1,2-Diamine Derivative 3"],
  },
  {
    primary_id: "D03ZMY",
    names: ["AM-220", "Chondroitin Sulphate E", "AM-220 ColMod", "Col-Mod", "Wound Healing Therapy, AnaMar Medical"],
  },
  {
    primary_id: "D03ZNB",
    names: ["L-Ornithine"],
  },
  {
    primary_id: "D03ZNK",
    names: ["Tc99-Labeled 14F7 Humanized Mab"],
  },
  {
    primary_id: "D03ZOH",
    names: ["PMID25399762-Compound-Table 7-4-Hydroxy-2,5-Dimethyl-3(2H)-Furanone"],
  },
  {
    primary_id: "D03ZOJ",
    names: ["D-189", "CHEMBL468834", "SCHEMBL7877095", "BDBM50261304"],
  },
  {
    primary_id: "D03ZOR",
    names: ["Epoxysuccinate Derivative 9"],
  },
  {
    primary_id: "D03ZOZ",
    names: ["PMID25666693-Compound-97"],
  },
  {
    primary_id: "D03ZPH",
    names: ["15-Deoxy-Delta12,14-PGD2", "15-Deoxy-Delta12,14-Prostaglandin D2", "15d-Delta12,14-PGD2"],
  },
  {
    primary_id: "D03ZPX",
    names: [
      "PRX-07034",
      "5-HT 6 Antagonists (Obesity/Cognitive Impairment), EPIX",
      "5-HT 6 Antagonists (Obesity/Cognitive Impairment), Predix",
    ],
  },
  {
    primary_id: "D03ZPY",
    names: ["SQ-33351"],
  },
  {
    primary_id: "D03ZSA",
    names: ["BMS-986016"],
  },
  {
    primary_id: "D03ZSY",
    names: ["Nitrilotriacetic Acid"],
  },
  {
    primary_id: "D03ZTE",
    names: [
      "Chenodiol",
      "474-25-9",
      "Chenix",
      "Chenic Acid",
      "Chenodeoxycholate",
      "Gallodesoxycholic Acid",
      "Chendol",
      "Chenodesoxycholic Acid",
      "Cdca",
      "Chenofalk",
      "Anthropodeoxycholic Acid",
      "Anthropodesoxycholic Acid",
      "Anthropododesoxycholic Acid",
      "Chenodesoxycholsaeure",
      "Xenbilox",
      "Henohol",
      "Chenique Acid",
      "Chenodiol [USAN]",
      "3alpha,7alpha-Dihydroxy-5beta-Cholan-24-Oic Acid",
      "Sodium Chenodeoxycholate",
      "Acido Chenodeoxicholico",
      "Chenodesoxycholsaeure [German]",
      "Acide Chenodeoxycholique",
      "7-Alpha-Hydroxylithocholic Acid",
      "Acidum Chenodeoxycholicum",
    ],
  },
  {
    primary_id: "D03ZUB",
    names: ["Ro67-7476"],
  },
  {
    primary_id: "D03ZUJ",
    names: [
      "Cis-JB253",
      "(E)-N-(Cyclohexylcarbamoyl)-4-((4-(Diethylamino)Phenyl)Diazenyl)Benzenesulfonamide",
      "Trans-JB253",
      "GTPL7787",
      "GTPL7788",
      "SCHEMBL17685466",
      "SCHEMBL17685465",
      "1-Cyclohexyl-3-{4-[(Z)-2-[4-(Diethylamino)Phenyl]diazen-1-Yl]benzenesulfonyl}urea",
      "1-Cyclohexyl-3-{4-[(E)-2-[4-(Diethylamino)Phenyl]diazen-1-Yl]benzenesulfonyl}urea",
      "(Z)-N-(Cyclohexylcarbamoyl)-4-((4-(Diethylamino)Phenyl)Diazenyl)Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D03ZUM",
    names: [
      "Tert-Butyloxy Carbonyl-D-Phe-Pro-Arg-H",
      "CHEMBL104472",
      "LY-178207",
      "AC1OCEOE",
      "SCHEMBL7371676",
      "BDBM50046360",
      "BDBM50029511",
      "{1-Benzyl-2-[2-(1-Formyl-4-Guanidino-Butylcarbamoyl)-Pyrrolidin-1-Yl]-2-Oxo-Ethyl}-Carbamic Acid Tert-Butyl Ester",
      "{(R)-1-Benzyl-2-[(S)-2-((S)-1-Formyl-4-Guanidino-Butylcarbamoyl)-Pyrrolidin-1-Yl]-2-Oxo-Ethyl}-Carbamic Acid Tert-Butyl Ester",
      "Tert-Butyl N-[(2R)-1-[(2S)-2-[[(2S)-5-(Diaminomethylideneamino)-1-Oxopentan-2-Yl]carbamoyl]pyrrolidin-1-Yl]-1-Oxo-3-Phenylpropan-2-Yl]carbamate",
    ],
  },
  {
    primary_id: "D03ZUZ",
    names: [
      "8-(3-Benzoyl-Ureido)-Octanoic Acid Hydroxyamide",
      "CHEMBL1083441",
      "SCHEMBL4928514",
      "WUKFQTZVXXYEAB-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D03ZVD",
    names: ["KW-4490"],
  },
  {
    primary_id: "D03ZVM",
    names: [
      "5-(1-Methyl-Pyrrolidin-2-Yl)-2-Phenethyl-Pyridine",
      "CHEMBL190295",
      "SCHEMBL11307517",
      "(+/-)6-(2-Phenylethyl)Nicotine",
    ],
  },
  {
    primary_id: "D03ZVW",
    names: ["Vapiprost"],
  },
  {
    primary_id: "D03ZWB",
    names: [
      "Alpha3IA",
      "CHEMBL413325",
      "GTPL4094",
      "BDBM50112236",
      "3-(4-Methoxy-Phenyl)-1-Methyl-6-Oxo-1,6-Dihydro-[2,4'']bipyridinyl-5-Carboxylic Acid Methyl Ester",
      "Methyl 5-(4-Methoxyphenyl)-1-Methyl-2-Oxo-6-(Pyridin-4-Yl)-1,2-Dihydropyridine-3-Carboxylate",
    ],
  },
  {
    primary_id: "D03ZWK",
    names: ["AC45594", "4-(Heptyloxy)Benzolol", "4-Heptoxyphenol"],
  },
  {
    primary_id: "D03ZWL",
    names: ["L-740093"],
  },
  {
    primary_id: "D03ZWR",
    names: ["APC-300", "CPC-300"],
  },
  {
    primary_id: "D03ZXG",
    names: ["Imidazopyridine And Triazolopyridine Compound 1"],
  },
  {
    primary_id: "D03ZXP",
    names: ["PMID27454349-Compound-97"],
  },
  {
    primary_id: "D03ZXZ",
    names: ["XL-228"],
  },
  {
    primary_id: "D03ZYX",
    names: ["N-Hydroxyglycine Derivative"],
  },
  {
    primary_id: "D03ZYZ",
    names: [
      "Prophylactic Dengue Fever Vaccine",
      "Attenuated Tetravalent Prophylactic Dengue Vaccine, GlaxoSmithKline",
      "Prophylactic Dengue Fever Vaccine, GlaxoSmithKline",
      "T-DEN",
    ],
  },
  {
    primary_id: "D03ZZC",
    names: ["Vascugel"],
  },
  {
    primary_id: "D03ZZH",
    names: ["SLV-313"],
  },
  {
    primary_id: "D03ZZK",
    names: [
      "Fluocinonide",
      "Bestasone",
      "Biscosal",
      "Cortalar",
      "Fluocinolide",
      "Fluocinonido",
      "Fluocinonidum",
      "Fluonex",
      "Fluzon",
      "Lidex",
      "Lonide",
      "Lyderm;Metosyn",
      "Straderm",
      "Topsymin",
      "Topsyn",
      "Vanos",
      "Fluocinolide Acetate",
      "Fluocinolone Acetonide Acetate",
      "Fluocinonide Emulsified Base",
      "Fluocinonide FAPG",
      "Lidex E",
      "Synalar Acetate",
      "Fluocinolone Acetonide 21-Acetate",
      "Fluocinonido [INN-Spanish]",
      "Fluocinonidum [INN-Latin]",
      "Fluonex (TN)",
      "LIDEX (TN)",
      "LIDEX-E",
      "Lidex (TN)",
      "Lonide (TN)",
      "Lyderm (TN)",
      "Vanos (TN)",
      "Lidex-E (TN)",
      "Fluocinonide (JP15/USP/INN)",
      "Fluocinonide [USAN:BAN:INN:JAN]",
      "Fluocinonide [USAN:INN:BAN:JAN]",
      "Pregna-1,4-Diene-3,20-Dione, 6alpha,9-Difluoro-11beta,16alpha,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone, 21-Acetate",
      "Pregna-1,4-Diene-3,20-Dione, 6alpha,9-Difluoro-11beta,16alpha,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone, 21-Acetate (8CI)",
      "Pregna-1,4-Diene-3,20-Dione, 6-Alpha, 9-Difluoro-11-Beta,16-Alpha,17,21-Tetrahydroxy-, Cyclic17-Acetal With Acetone, 21-Acetate",
      "6alpha,9-Difluoro-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione, Cyclic 16,17-Acetal With Acetone, 21-Acetate",
    ],
  },
  {
    primary_id: "D03ZZM",
    names: ["DMT310"],
  },
  {
    primary_id: "D03ZZX",
    names: [
      "123I-MNI-420",
      "123I-SPECT Diagnostic (Parkinson's/Huntington's Disease), IND",
      "123I-SPECT Imaging Marker, IND",
    ],
  },
  {
    primary_id: "D04AAI",
    names: ["1-Benzyl-4-[3-(Methylsulfonyl)Phenyl]piperazine", "CHEMBL597413"],
  },
  {
    primary_id: "D04AAN",
    names: [
      "Mesoridazine",
      "Calodal",
      "Lidanar",
      "Lidanil",
      "Mesoridazina",
      "Mesoridazinum",
      "Serentil",
      "Serentil Concentrate",
      "Thioridazien Thiomethyl Sulfoxide",
      "Thioridazine Monosulfoxide Analog",
      "Thioridazine Thiomethyl Sulfoxide",
      "TPS 23",
      "Tps23",
      "Lidanar (TN)",
      "Mesoridazina [INN-Spanish]",
      "Mesoridazinum [INN-Latin]",
      "NC-123",
      "Serentil (TN)",
      "TPS-23",
      "Mesoridazine (USAN/INN)",
      "Mesoridazine [USAN:BAN:INN]",
      "T-2-SO",
      "THD-2-SO",
      "Thioridazine-2-Sulfoxide",
      "10-(2(1-Methyl-2-Piperidyl)Ethyl)-2-(Methylsulfinyl)-Phenothiazine",
      "10-(2(1-Methyl-2-Piperidyl)Ethyl)-2-(Methylsulfinyl)Phenothiazine",
      "10-(2-(1-Methyl-2-Piperidinyl)Ethyl)-2-(Methylsulfinyl)-10H-Phenothiazine",
      "10-(2-(1-Methyl-2-Piperidyl)Ethyl)-2-Methylsulfinyl Phenothiazine",
      "10-[2(1-Methyl-2-Piperidyl)Ethyl]-2-(Methylsulfinyl)Phenothiazine",
      "10-[2-(1-Methyl-2-Piperidyl)Ethyl]-2-Methylsulfinyl Phenothiazine",
      "10-[2-(1-Methylpiperidin-2-Yl)Ethyl]-2-(Methylsulfinyl)-10H-Phenothiazine",
      "10-[2-(1-Methylpiperidin-2-Yl)Ethyl]-2-Methylsulfinylphenothiazine",
    ],
  },
  {
    primary_id: "D04AAR",
    names: [
      "XB-513",
      "140941-86-2",
      "3-Pyridinecarboxylicacid,2-Amino-1,4-Dihydro-4-[2-[[4-[4-(2-Methoxyphenyl)-1-Piperazinyl]butyl]sulfinyl]phenyl]-6-Methyl-5-Nitro-,methyl Ester",
      "XB 513",
      "JQTJGLURUUNKCA-UHFFFAOYSA-N",
      "ACMC-20mzxr",
      "AC1L4DOE",
      "SCHEMBL7379746",
      "CTK4C2408",
      "AKOS030566276",
      "2-Amino-1,4-Dihydro-4-(2-(4-(4-(2-Methoxyphenyl)-1-Piperazinyl)Butylsulfinyl)Phenyl)-6-Methyl-5-Nitro-3-Pyridinecarboxylic Acid Methyl Ester",
      "L006210",
      "3-Pyridinecarboxylic Acid, 2-Amino-1,4-Dihydro-4-(2-((4-(4-(2-Methoxyphenyl)-1-Piperazinyl)Butyl)Sulfinyl)",
    ],
  },
  {
    primary_id: "D04AAT",
    names: ["PMID18754614C7"],
  },
  {
    primary_id: "D04AAV",
    names: [
      "2-(4-Diethylamino-But-2-Ynyl)-Isoindole-1,3-Dione",
      "CHEMBL176400",
      "F2147-1072",
      "7351-91-9",
      "SCHEMBL14676513",
      "MolPort-023-212-106",
      "DEWGBLHKQDVWLX-UHFFFAOYSA-N",
      "BDBM50291598",
      "ZINC28090831",
      "AKOS024627738",
      "MCULE-2799628954",
      "2-(4-(Diethylamino)But-2-Yn-1-Yl)Isoindoline-1,3-Dione",
    ],
  },
  {
    primary_id: "D04AAW",
    names: [
      "Cidofovir",
      "CDV",
      "Cidofovirum",
      "Forvade",
      "HPMPC",
      "Vistide",
      "Cidofovir Anhydrous",
      "GS 0504",
      "GS 504",
      "GS504",
      "Cidofovir (Vistide)",
      "Cidofovir (Anhydrous)",
      "GS-0504",
      "GS-504",
      "Vistide (TN)",
      "Vistide, Cidofovir",
      "[(2S)-1-(4-Amino-2-Oxopyrimidin-1-Yl)-3-Hydroxypropan-2-Yl]oxymethylphosphonic Acid",
      "(2S)-3-Hydroxy-2-Phosphonylmethoxypropyl-Cytosine",
      "(S)-1-(3-Hydroxy-2-Phosphonomethoxypropyl)Cytosine",
      "(S)-1-[3-Hydroxy-2-(Phosphonylmethoxy)-Propyl]cytosine",
      "(S)-2-(4-Amino-2-Oxo-1(2H)-Pyrimidinyl-1-(Hydroxymethyl)Ethoxy)Methyl Phosphonic Acid",
      "(S)-HPMPC",
      "(S)-[[2-(4-Amino-2-Oxo-1(2h)-Pyrimidinyl)-1-(Hydroxymethyl)Ethoxy]methyl]phosphonic Acid",
      "({[(2S)-1-(4-Amino-2-Oxopyrimidin-1(2H)-Yl)-3-Hydroxypropan-2-Yl]oxy}methyl)Phosphonic Acid",
      "1-(3-Hydroxy-2-Phosphonylmethoxypropyl)Cytosine",
      "1-[(S)-3-Hydroxy-2-(Phosphonomethoxy)Propyl]-Cytosine Dihydrate",
    ],
  },
  {
    primary_id: "D04ABS",
    names: ["CPH-102"],
  },
  {
    primary_id: "D04ACC",
    names: ["Doxorubicin-Peptide-PEG Conjugate, Watson"],
  },
  {
    primary_id: "D04ACD",
    names: ["[111In]B3"],
  },
  {
    primary_id: "D04ACJ",
    names: [
      "N-(6-(2-Chlorophenyl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1086175",
      "N-[6-(2-Chlorophenyl)-1H-Indazol-3-Yl]butanamide",
      "SCHEMBL6485112",
      "WPJGFQOEGTUKTE-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D04ACK",
    names: ["Anti-CD30 CAR T Cells"],
  },
  {
    primary_id: "D04ACW",
    names: [
      "Alogliptin/Metformin",
      "Alogliptin / Metformin",
      "Alogliptin Mixture With Metformin",
      "Kazano",
      "SYR-322 + Metformin Hydrochloride",
      "Alogliptin / Metformin Hydrochloride",
      "Alogliptin And Metformin Hydrochloride",
      "Alogliptin Mixture With Metformin Hydrochloride",
    ],
  },
  {
    primary_id: "D04AED",
    names: ["4-[4-(Benzhydryloxy)Benzoyl]benzoic Acid", "CHEMBL203352"],
  },
  {
    primary_id: "D04AEG",
    names: ["3-(2-Phenylthiazol-4-Yl)Quinolin-2(1H)-One"],
  },
  {
    primary_id: "D04AEQ",
    names: ["V81444"],
  },
  {
    primary_id: "D04AGC",
    names: ["Phenyl(2-(Trifluoromethyl)Quinolin-4-Yl)Methanol", "CHEMBL408869", "SCHEMBL7226777"],
  },
  {
    primary_id: "D04AGE",
    names: ["(2'Z,3'E)-5-Fluoro-5'-Methoxy-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D04AGM",
    names: ["PRTT-100", "Calcium Channel Inhibitor + Opioid (Pain), Pro-Tect Pharmaceuticals"],
  },
  {
    primary_id: "D04AHG",
    names: ["BMS-986012"],
  },
  {
    primary_id: "D04AHQ",
    names: ["LY3009120"],
  },
  {
    primary_id: "D04AHY",
    names: ["HT-2157"],
  },
  {
    primary_id: "D04AIB",
    names: ["AZD1772/RDX5791"],
  },
  {
    primary_id: "D04AIT",
    names: ["B-Lactams"],
  },
  {
    primary_id: "D04AIV",
    names: ["Eteplirsen"],
  },
  {
    primary_id: "D04AJS",
    names: ["ISIS 6756"],
  },
  {
    primary_id: "D04AKA",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 10"],
  },
  {
    primary_id: "D04AKF",
    names: ["DKN-01"],
  },
  {
    primary_id: "D04AKG",
    names: ["SER-287"],
  },
  {
    primary_id: "D04ALI",
    names: ["FHT-CT4", "CTLA4-PTD Fusion Protein (Inflammation), Forhumantech"],
  },
  {
    primary_id: "D04ALU",
    names: ["[3H](+)-Cis-Diltiazem", "[3H]-(+)-Cis-Diltiazem"],
  },
  {
    primary_id: "D04AMC",
    names: ["N-Arachidonoyl-N-(2-Hydroxyethyl)Hydroxylamine", "CHEMBL204403"],
  },
  {
    primary_id: "D04AMD",
    names: [
      "ZD-6126",
      "219923-05-4",
      "ZD6126",
      "UNII-GBO3S6M9W7",
      "GBO3S6M9W7",
      "N-Acetylcochinol-O-Phosphate",
      "AZD-6126",
      "N-Acetylcolchicinol Dihydrogenphosphate",
      "ZD 6126",
      "SCHEMBL1285000",
      "CHEMBL257662",
      "ANG 453",
      "AZD6126, ANG453",
      "ZINC3993833",
      "AZD 6126",
      "BCP9000366",
      "DB11872",
      "BCP0726000110",
      "ZM 445526",
      "Acetamide, N-((5S)-6,7-Dihydro-9,10,11-Trimethoxy-3-(Phosphonooxy)-5H-Dibenzo(A,c)Cyclohepten-5-Yl)-",
    ],
  },
  {
    primary_id: "D04AMI",
    names: ["PMID28092474-Compound-34c"],
  },
  {
    primary_id: "D04AMY",
    names: ["Phenylpyrrolidinone Derivative 3"],
  },
  {
    primary_id: "D04ANB",
    names: ["Salicylic Acid Derivative 4"],
  },
  {
    primary_id: "D04ANV",
    names: ["Cholic Acid - Asklepion", "Cholic Acid FGK", "Kolbam"],
  },
  {
    primary_id: "D04AOA",
    names: ["SC-51895"],
  },
  {
    primary_id: "D04APR",
    names: ["Tirilazad Mesylate"],
  },
  {
    primary_id: "D04APY",
    names: ["KC-11404"],
  },
  {
    primary_id: "D04AQE",
    names: [
      "5-METHOXYTRYPTAMINE",
      "608-07-1",
      "2-(5-Methoxy-1H-Indol-3-Yl)Ethanamine",
      "Mexamine",
      "Methoxytryptamine",
      "3-(2-Aminoethyl)-5-Methoxyindole",
      "Mexamine Base",
      "5MOT",
      "5-Mot",
      "1H-Indole-3-Ethanamine, 5-Methoxy-",
      "2-(5-Methoxyindol-3-Yl)Ethylamine",
      "Meksamin",
      "Meksamin (Free Base)",
      "5-MeOT",
      "O-Methylserotonin",
      "2-(5-Methoxy-1H-Indol-3-Yl)Ethan-1-Amine",
      "NSC 56422",
      "5-MT",
      "UNII-3VMW6141KC",
      "INDOLE, 3-(2-AMINOETHYL)-5-METHOXY-",
      "5-Methoxy-1H-Indole-3-Ethanamine",
      "EINECS 210-153-7",
      "Lopac-M-6628",
      "5-Methoxytryptamine, 97%",
      "BRN 0145587",
      "CHEMBL8165",
      "3VMW6141KC",
    ],
  },
  {
    primary_id: "D04AQH",
    names: ["Sermorelin"],
  },
  {
    primary_id: "D04AQR",
    names: ["Imidazo [1,2-A]pyridine Compound 1"],
  },
  {
    primary_id: "D04AQW",
    names: [
      "10-Isopropyl-10H-Dibenzo[b,f][1,4]oxazepin-11-One",
      "10iPr-DBOA-11one",
      "10-Isopropyl-Dibenz(B,f)(1,4)Oxazepin-11-(10H)-One",
      "135810-42-3",
      "10-Isopropyl-Dibenz[b,f][1,4]oxazepin-11-(10H)-One",
      "Dibenzoxazepinone 12",
      "AC1L9R9N",
      "CHEMBL56567",
      "SCHEMBL7955434",
      "BDBM1974",
      "DTXSID10159533",
      "ACJZSMFIMMQNTP-UHFFFAOYSA-N",
      "5-Isopropylbenzo[b][1,4]benzoxazepin-6-One",
      "5-Propan-2-Ylbenzo[b][1,4]benzoxazepin-6-One",
      "10-Isopropyldibenz[b,f][1,4]oxazepin-11(10H)-One",
      "10-Isopropyl-10H-Dibenz[b,f][1,4]oxazepin-11-One",
    ],
  },
  {
    primary_id: "D04ARH",
    names: ["1-Octyl-3-(1-Propionylpiperidin-4-Yl)Urea", "CHEMBL1257514", "SCHEMBL2721029"],
  },
  {
    primary_id: "D04ARO",
    names: ["PEV-4"],
  },
  {
    primary_id: "D04ASA",
    names: ["X-083-NAB"],
  },
  {
    primary_id: "D04ASH",
    names: ["Leuprorelin Acetate"],
  },
  {
    primary_id: "D04ASP",
    names: ["Influenza Virus DNA Vaccine"],
  },
  {
    primary_id: "D04ATE",
    names: ["AT-009"],
  },
  {
    primary_id: "D04ATM",
    names: ["Trimegestone"],
  },
  {
    primary_id: "D04ATN",
    names: [
      "4-[3-(4-Ethynyl-Phenoxy)-Propyl]-1H-Imidazole",
      "CHEMBL309600",
      "SCHEMBL7987317",
      "FUB-470",
      "BDBM50067472",
      "L018291",
      "4-[3-(4-Ethynyl-Phenoxy)-Propyl]-1H-Imidazole (FUB 470)",
    ],
  },
  {
    primary_id: "D04ATU",
    names: ["8-Amino-6H,11H-Indeno[1,2-C]isoquinolin-5-One"],
  },
  {
    primary_id: "D04ATY",
    names: ["Bulleyaconitine A"],
  },
  {
    primary_id: "D04AUA",
    names: ["MIV-170"],
  },
  {
    primary_id: "D04AUL",
    names: ["Propan-2-One O-4-(Decyloxy)Phenylcarbamoyl Oxime", "CHEMBL597215"],
  },
  {
    primary_id: "D04AUM",
    names: [
      "AM-1710",
      "UNII-R0GPP0WDF9",
      "R0GPP0WDF9",
      "CHEMBL266712",
      "3-(1,1-Dimethylheptyl)-1-Hydroxy-9-Methoxy-6H-Dibenzo(B,d)Pyran-6-One",
      "3-(1,1-Dimethylheptyl)-1-Hydroxy-9-Methoxy-6H-Dibenzo[b,d]pyran-6-One",
      "SCHEMBL3297145",
      "GTPL10202",
      "ZAIKPEWFCSQNQB-UHFFFAOYSA-N",
      "BDBM50228073",
      "AM1710",
      "Analog 4b [PMID: 18038967]",
      "1-Hydroxy-9-Methoxy-3-(2-Methyloctan-2-Yl)Benzo[c]chromen-6-One",
      "3-(1',1'-Dimethylheptyl)-1-Hydroxy-9-Methoxy-6h-Benzo[c]chromene-6-One",
      "6H-Dibenzo(B,d)Pyran-6-One, 3-(1,1-Dimethylheptyl)-1-Hydroxy-9-Methoxy-",
    ],
  },
  {
    primary_id: "D04AWP",
    names: [
      "Guanosine-5'-Monophosphate",
      "5'-Guanylic Acid",
      "Guanylic Acid",
      "Guanosine Monophosphate",
      "Guanosine 5'-Monophosphate",
      "85-32-5",
      "5'-Gmp",
      "Guanosine 5'-Phosphate",
      "Guanidine Monophosphate",
      "Guanylate",
      "CHEBI:17345",
      "Guanosine-Phosphate",
      "Guanosine-Monophosphate",
      "Guanosine 5'-Monophosphoric Acid",
      "AI3-28539",
      "Guanosine-5'-Phosphate",
      "UNII-16597955EP",
      "EINECS 201-598-8",
      "Guanosine 5'-Phosphoric Acid",
      "CHEMBL283807",
      "GMP",
      "((2R,3S,4R,5R)-5-(2-Amino-6-Oxo-1H-Purin-9(6H)-Yl)-3,4-Dihydroxytetrahydrofuran-2-Yl)Methyl Dihydrogen Phosphate",
      "E 626",
      "16597955EP",
    ],
  },
  {
    primary_id: "D04AWS",
    names: [
      "N-Cyclopentyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL601297",
      "AC1LE08W",
      "Cambridge Id 6793618",
      "Oprea1_215546",
      "ZINC88021",
      "MolPort-002-222-847",
      "BDBM50308399",
      "AKOS001667643",
      "MCULE-5831533144",
      "N-Cyclopentylphenothiazine-10-Carboxamide",
      "EU-0013438",
      "AB00119419-01",
    ],
  },
  {
    primary_id: "D04AWZ",
    names: [
      "SB-201146",
      "CHEMBL112338",
      "AC1O5RO8",
      "SCHEMBL13696780",
      "BDBM50042182",
      "L009515",
      "(E)-3-{6-(3-Amino-Benzenesulfinylmethyl)-3-[8-(4-Methoxy-Phenyl)-Octyloxy]-Pyridin-2-Yl}-Acrylic Acid",
      "3-{6-(3-Amino-Benzenesulfinylmethyl)-3-[8-(4-Methoxy-Phenyl)-Octyloxy]-Pyridin-2-Yl}-Acrylic Acid",
      "(E)-3-[6-[(3-Aminophenyl)Sulfinylmethyl]-3-[8-(4-Methoxyphenyl)Octoxy]pyridin-2-Yl]prop-2-Enoic Acid",
      "(E)-3-[[8-(4-Methoxyphenyl)Octyl]oxy]-6-[[(3-Aminophenyl)Sulfinyl]methyl]pyridine-2-Propenoic Acid",
    ],
  },
  {
    primary_id: "D04AXC",
    names: ["Tetra-Hydro-Isoquinoline Derivative 1"],
  },
  {
    primary_id: "D04AXH",
    names: ["8-Octyl-Benzolactam-V9", "CHEMBL206866", "8-Octylbenzolactam-V9"],
  },
  {
    primary_id: "D04AXP",
    names: [
      "[3H]estrone-3-Sulphate",
      "[3H]-Estrone Hydrogen Sulfate",
      "[3H]-Estrone-3-Sulphate",
      "[3H]estrone-3-Sulfate",
    ],
  },
  {
    primary_id: "D04AXR",
    names: [
      "4-Chloro-1H-Indole-2,3-Dione",
      "4-Chloroisatin",
      "6344/5/4",
      "4-Chloroindoline-2,3-Dione",
      "4-Chloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "1H-Indole-2,3-Dione, 4-Chloro-",
      "4-Chloroindole-2,3-Dione",
      "4-Chloro-2,3-Indolinedione",
      "Indole-2,3-Dione, 4-Chloro-",
      "HSYFISNDMZKGRS-UHFFFAOYSA-N",
      "4-Chloro Isatin",
      "NSC49794",
      "PubChem13911",
      "AC1Q3HWJ",
      "AC1L3VNL",
      "ACMC-2097je",
      "AC1Q3P5R",
      "Isatin-Based Compound, 29",
      "AC1Q3KG8",
      "KSC492I4L",
      "SCHEMBL282066",
      "CHEMBL376228",
      "KS-00000DIY",
      "HSYFISNDMZKGRS-UHFFFAOYSA-",
      "CTK3J2445",
      "BDBM22809",
      "DTXSID90212852",
      "MolPort-001-797-339",
    ],
  },
  {
    primary_id: "D04AXY",
    names: [
      "6-(4-Methyl-Piperazin-1-Yl)-Phenanthridine",
      "CHEMBL43193",
      "23441-13-6",
      "6-(4-Methylpiperazin-1-Yl)Phenanthridine",
      "CTK0I7937",
      "DTXSID50433889",
      "ZINC13778637",
      "BDBM50063258",
      "6-(4-Methylpiperazino)Phenanthridine",
      "Phenanthridine, 6-(4-Methyl-1-Piperazinyl)-",
    ],
  },
  {
    primary_id: "D04AYF",
    names: [
      "Musculoskeletal Disorder/Metabolic Disease Therapy",
      "Disorder/Metabolic Disease Therapy",
      "Musculoskeletal Disorder/Metabolic Disease Therapy (Oral)",
    ],
  },
  {
    primary_id: "D04AYG",
    names: ["Minocycline Topical"],
  },
  {
    primary_id: "D04AYH",
    names: ["ADE-CRAN"],
  },
  {
    primary_id: "D04AYN",
    names: [
      "EB-47",
      "EB 47",
      "366454-36-6",
      "5'-Deoxy-5'-[4-[2-[(2,3-Dihydro-1-Oxo-1H-Isoindol-4-Yl)Amino]-2-Oxoethyl]-1-Piperazinyl]-5'-Oxoadenosine Dihydrochloride",
      "DTXSID40692822",
      "ZINC98052573",
      "NCGC00370771-01",
      "KB-76747",
      "FT-0667818",
      "4-[1-(6-Amino-9H-Purin-9-Yl)-1-Deoxy-",
      "A-D-Ribofuranuronoyl]-N-(2,3-Dihydro-1-Oxo-1H-Isoindol-4-Yl)-1-Piperazineacetamide Dihydrochloride",
      "2-{4-[(2R,3R,4S,5S)-5-(6-Amino-9H-Purin-9-Yl)-3,4-Dihydroxyoxolane-2-Carbonyl]piperazin-1-Yl}-N-(1-Oxo-2,3-Dihydro-1H-Isoindol-4-Yl)Acetamide (Non-Preferred Name)",
    ],
  },
  {
    primary_id: "D04AYZ",
    names: ["CSL-654"],
  },
  {
    primary_id: "D04AZE",
    names: ["SB 204741"],
  },
  {
    primary_id: "D04AZM",
    names: ["ARQ 736"],
  },
  {
    primary_id: "D04AZT",
    names: ["MEDI1814"],
  },
  {
    primary_id: "D04BAQ",
    names: ["Fused Heterocyclic Compound 11"],
  },
  {
    primary_id: "D04BBA",
    names: ["Thiazole Carboxamide Derivative 24"],
  },
  {
    primary_id: "D04BBF",
    names: ["P1-(5'-Adenosyl)P5-(5'-Thymidyl)Pentaphosphate"],
  },
  {
    primary_id: "D04BBK",
    names: [
      "VU10007",
      "(S)-3-Amino-4,6-Dimethyl-N-(1-Phenylethyl)Thieno[2,3-B]pyridine-2-Carboxamide 2,2,2-Trifluoroacetate",
      "AC1LEQT2",
      "CHEMBL1212991",
      "ZINC61353",
    ],
  },
  {
    primary_id: "D04BBT",
    names: ["Heterocyclic Derivative 12"],
  },
  {
    primary_id: "D04BCB",
    names: ["Lobeglitazone"],
  },
  {
    primary_id: "D04BCG",
    names: ["LLZ16407"],
  },
  {
    primary_id: "D04BCW",
    names: ["Ketobemidone"],
  },
  {
    primary_id: "D04BDL",
    names: ["PMID22123324C38"],
  },
  {
    primary_id: "D04BEL",
    names: ["FIV Vectors"],
  },
  {
    primary_id: "D04BEN",
    names: [
      "Amrubicin",
      "110267-81-7",
      "Amrubicin [INN]",
      "SCHEMBL119022",
      "AC1L4374",
      "(+-)-(7S,9S)-9-Acetyl-9-Amino-7-((2-Deoxy-Beta-D-Erythro-Pentopyranosyl)Oxy)-7,8,9,10-Tetrahydro-6,11-Dihydroxy-5,12-Naphthacenedione",
      "BC677604",
      "AN-16624",
      "LS-183761",
      "FT-0662134",
      "( -)-(7S,9S)-9-Acetyl-9-Amino-7-((2-Deoxy-Beta-D-Erythro-Pentopyranosyl)Oxy)-7,8,9,10-Tetrahydro-6,11-Dihydroxy-5,12-Naphthacenedione",
      "(7S,9S)-9-Acetyl-9-Amino-7-(4,5-Dihydroxytetrahydropyran-2-Yl)Oxy-6,11-Dihydroxy-8,10-Dihydro-7H-Tetracene-5,12-Dione",
    ],
  },
  {
    primary_id: "D04BES",
    names: [
      "2-(4-(3-Chlorophenyl)But-1-Ynyl)-6-Methylpyridine",
      "CHEMBL213001",
      "SCHEMBL4293064",
      "2-[4-(3-Chlorophenyl)But-1-Yn-1-Yl]-6-Methylpyridine",
      "BDBM50191127",
    ],
  },
  {
    primary_id: "D04BFC",
    names: [
      "CC-292",
      "AVL-292",
      "1202757-89-8",
      "Spebrutinib",
      "Cc-292",
      "UNII-DRU6NG543J",
      "CC-292 (AVL-292)",
      "DRU6NG543J",
      "AVL292",
      "N-[3-[[5-Fluoro-2-[4-(2-Methoxyethoxy)Anilino]pyrimidin-4-Yl]amino]phenyl]prop-2-Enamide",
      "AK198940",
      "N-(3-((5-Fluoro-2-((4-(2-Methoxyethoxy)Phenyl)Amino)Pyrimidin-4-Yl)Amino)Phenyl)Acrylamide",
      "N-[3-[[5-Fluoro-2-[[4-(2-Methoxyethoxy)Phenyl]amino]-4-Pyrimidinyl]amino]phenyl]-2-Propenamide",
      "Btk Inhibitor CC-292",
      "Spebrutinib [USAN:INN]",
      "Spebrutinib (USAN/INN)",
      "SCHEMBL626216",
      "GTPL7837",
      "CHEMBL3301625",
      "EX-A255",
      "AVL 292",
    ],
  },
  {
    primary_id: "D04BFI",
    names: ["LGD-4665"],
  },
  {
    primary_id: "D04BFU",
    names: ["LIDORESTAT"],
  },
  {
    primary_id: "D04BFX",
    names: ["IPL-423"],
  },
  {
    primary_id: "D04BGT",
    names: ["4-Propyl-6-M-Tolylpyrimidine-2-Carbonitrile", "CHEMBL1082859", "SCHEMBL4453859"],
  },
  {
    primary_id: "D04BHL",
    names: ["ROXIFIBAN"],
  },
  {
    primary_id: "D04BHQ",
    names: ["N-(2,4-Dimethylphenyl)-2-Nitrobenzamide"],
  },
  {
    primary_id: "D04BHR",
    names: ["PT-313"],
  },
  {
    primary_id: "D04BHS",
    names: ["Recombinant Batroxobin"],
  },
  {
    primary_id: "D04BHY",
    names: ["KP-27"],
  },
  {
    primary_id: "D04BIF",
    names: ["7-Azaindole Derivative 7"],
  },
  {
    primary_id: "D04BIG",
    names: ["SR-90067"],
  },
  {
    primary_id: "D04BIH",
    names: ["4-Phenylbutylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL486492"],
  },
  {
    primary_id: "D04BII",
    names: ["H-Dmt-Tic-D-Asp-NH-Ph", "CHEMBL498799"],
  },
  {
    primary_id: "D04BIY",
    names: ["NKTR-140"],
  },
  {
    primary_id: "D04BJC",
    names: ["Analgesic Peptides", "Analgesic Peptides (Intranasal, Temporomandibular Joint Pain)"],
  },
  {
    primary_id: "D04BKO",
    names: ["Etripamil"],
  },
  {
    primary_id: "D04BKU",
    names: [
      "Sulfamic Acid 4-Sulfamoyloxy-Butyl Ester",
      "1,4-Butanediyl Sulfamate",
      "Sulfamic Acid, Tetramethylene Ester",
      "1,4-Butanediol, Bissulfamate (Ester)",
      "BRN 2275335",
      "CHEMBL353258",
      "60548-62-1",
      "4-Sulfamoyloxybutyl Sulfamate",
      "AC1L3Y05",
      "SCHEMBL11593067",
      "DTXSID40209297",
      "XHCZRWMTVBHWQT-UHFFFAOYSA-N",
      "Bissulfamideoic Acid 1,4-Butanediyl",
      "BDBM50159801",
      "1,4-Bis-O-Sulfamyl-1,4-Butanediol",
    ],
  },
  {
    primary_id: "D04BKZ",
    names: ["Mangafodipir Trisodium"],
  },
  {
    primary_id: "D04BLE",
    names: ["A-3309"],
  },
  {
    primary_id: "D04BLL",
    names: ["Phosphoric Acid Mono-((E)-Tetradec-11-Enyl) Ester", "CHEMBL188591", "SCHEMBL193531", "ZINC13648502"],
  },
  {
    primary_id: "D04BLO",
    names: ["Difluoroagomelatine", "GTPL7776", "CHEMBL1946221"],
  },
  {
    primary_id: "D04BMR",
    names: ["AL-59640"],
  },
  {
    primary_id: "D04BNA",
    names: ["RF1V"],
  },
  {
    primary_id: "D04BNM",
    names: [
      "5,6-Dichloro-1-Beta-D-Ribofuranosylbenzimidazole",
      "53-85-0",
      "NSC 401575",
      "Dichlororibofuranosylbenzimidazole",
      "DRB",
      "C12H12Cl2N2O4",
      "5,6-Dichlorobenzimidazole Riboside",
      "BRN 0039123",
      "CHEMBL375530",
      "UNII-8153319T3Q",
      "5,6-Dichloro-1-Beta-D-Ribofuranosyl-1h-Benzimidazole",
      "8153319T3Q",
      "(2R,3R,4S,5R)-2-(5,6-Dichloro-1H-Benzo[d]imidazol-1-Yl)-5-(Hydroxymethyl)Tetrahydrofuran-3,4-Diol",
      "Benzimidazole, 5,6-Dichloro-1-Beta-D-Ribofuranosyl-",
      "5,6-Dichloro-1-.beta.-D-Ribofuranosylbenzimidazole",
    ],
  },
  {
    primary_id: "D04BNP",
    names: [
      "Temazepam",
      "Cerepax",
      "Crisonar",
      "Dasuen",
      "Euhypnos",
      "Euipnos",
      "Gelthix",
      "Hydroxydiazepam",
      "Lenal",
      "Levanxene",
      "Levanxol",
      "Levanzene",
      "Mabertin",
      "Methyloxazepam",
      "Nocturne",
      "Nomapam",
      "Normison",
      "Normitab",
      "Nortem",
      "Oxydiazepam",
      "Perdorm",
      "Planum",
      "Remestan",
      "Restoril",
      "Signopam",
      "Temador",
      "Temaz",
      "Temaze",
      "Temazepamum",
      "Temtabs",
      "Tenox",
      "AHP Brand Of Temazepam",
      "Alphapharm Brand Of Temazepam",
      "Apo Temazepam",
      "Apotex Brand Of Temazepam",
      "Ct Arzneimittel Brand Of Temazepam",
      "Desitin Brand Of Temazepam",
      "Gen Temazepam",
      "Genopharm Brand Of Temazepam",
      "Genpharm Brand Of Temazepam",
      "ICN Brand Of Temazepam",
      "Katwijk Brand Of Temazepam",
      "Knoll Brand Of Temazepam",
      "Mallinckrodt Brand Of Temazepam",
      "Neodorm SP",
      "Norkotral Tema",
      "Norton Brand Of Temazepam",
      "Novartis Brand Of Temazepam",
      "Novo Temazepam",
      "Novopharm Brand Of Temazepam",
      "Nu Pharm Brand Of Temazepam",
      "Nu Temazepam",
      "Orion Brand Of Temazepam",
      "PMS Temazepam",
      "Pharmascience Brand Of Temazepam",
      "Pronervon T",
      "Scheffler Brand Of Temazepam",
      "Sigma Brand Of Temazepam",
      "Temazep Von Ct",
      "Uvamin Retard",
      "Wyeth Brand Of Temazepam",
      "ER 115",
      "K3917",
      "Pfizer Brand 1 Of Temazepam",
      "Pfizer Brand 2 Of Temazepam",
      "Ro 5 5345",
      "Ro55345",
      "SaH 47 603",
      "SaH 47603",
      "WY 2917",
      "WY 3917",
      "WY3917",
      "Apo-Temazepam",
      "Ct-Arzneimittel Brand Of Temazepam",
      "Euhypnos (TN)",
      "Gen-Temazepam",
      "K-3917",
      "N-Methyloxazepam",
      "Norkotral (TN)",
      "Normison (TN)",
      "Novo-Temazepam",
      "Nu-Pharm Brand Of Temazepam",
      "Nu-Temazepam",
      "PMS-Temazepam",
      "Remestan (TN)",
      "Restoril (TN)",
      "Ro 5-5345",
      "SaH 47-603",
      "Tema, Norkotral",
      "Temazepam, Pharmaceutical Grade",
      "Temazepamum [INN-Latin]",
      "Temtabs (TN)",
      "Tenox (TN)",
      "Wy-3917",
      "Ro-5-5345",
      "Temazepam (USP/INN)",
      "Temazepam [USAN:INN:BAN]",
      "1,3-Dihydro-7-Chloro-3-Hydroxy-1-Methyl-5-Phenyl-2H-1,4-Benzodiazepin-2-One",
      "3 Hydroxydiazepam",
      "3-Hydroxydiazepam",
      "7-CHLORO-1,3-DIHYDRO-3-HYDROXY-1-METHYL-5-PHENYL-2H-1,4-BENZODIAZEPIN-2-ONE",
      "7-Chloro-3-Hydroxy-1-Methyl-5-Phenyl-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-3-Hydroxy-1-Methyl-5-Phenyl-3H-1,4-Benzodiazepin-2-One",
    ],
  },
  {
    primary_id: "D04BOG",
    names: ["C[Nle-Asp-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL203170"],
  },
  {
    primary_id: "D04BOK",
    names: ["DiabeCell"],
  },
  {
    primary_id: "D04BON",
    names: ["PMID30247903-Compound-General Structure25"],
  },
  {
    primary_id: "D04BOQ",
    names: ["7alpha,27-Dihydroxycholesterol", "7alpha, 27-OHC"],
  },
  {
    primary_id: "D04BOT",
    names: [
      "Dalotuzumab",
      "AC1MHYZY",
      "SCHEMBL4534994",
      "BDBM82422",
      "NSC_3035240",
      "CAS_109872-41-5",
      "D06396",
      "L000945",
      "4-Amino-N-(1-Azabicyclo[3.3.1]nonan-4-Yl)-5-Chloro-2-Methoxybenzamide",
    ],
  },
  {
    primary_id: "D04BOW",
    names: ["Tricyclic Pyrrolopyridine Compound 1"],
  },
  {
    primary_id: "D04BPQ",
    names: ["PMID23692593C20", "Ahx-FSQn(Boro)Bpg", "GTPL8486", "BDBM50434142"],
  },
  {
    primary_id: "D04BPS",
    names: ["Naveglitazar"],
  },
  {
    primary_id: "D04BQR",
    names: ["Acecainide"],
  },
  {
    primary_id: "D04BQV",
    names: ["Isofagomine Tartrate"],
  },
  {
    primary_id: "D04BRF",
    names: ["TA-101"],
  },
  {
    primary_id: "D04BRW",
    names: ["Five Membered Heterocyclic Benzamide Derivative 2"],
  },
  {
    primary_id: "D04BSD",
    names: ["Piperazinyl Norbenzomorphane Compound 1"],
  },
  {
    primary_id: "D04BSJ",
    names: ["Pam2CSK4"],
  },
  {
    primary_id: "D04BSV",
    names: ["L-377202"],
  },
  {
    primary_id: "D04BSZ",
    names: ["PD-0204318"],
  },
  {
    primary_id: "D04BUA",
    names: ["STAT-3 Inhibitors"],
  },
  {
    primary_id: "D04BUH",
    names: [
      "Cizolirtine",
      "Cizolirtine [INN]",
      "N,N-Dimethyl-2-[(2-Methylpyrazol-3-Yl)-Phenylmethoxy]ethanamine",
      "N,N-Dimethyl-2-[(R)-(2-Methylpyrazol-3-Yl)-Phenylmethoxy]ethanamine",
    ],
  },
  {
    primary_id: "D04BVL",
    names: ["IM19"],
  },
  {
    primary_id: "D04BVN",
    names: ["PDGF-BB"],
  },
  {
    primary_id: "D04BVW",
    names: ["Cyclo(-D-Tyr-D-Arg-L-Arg-L-MeNal-Gly-)", "CHEMBL426169"],
  },
  {
    primary_id: "D04BVX",
    names: ["[D-Ncy(SO,methyl)10]acyline", "CHEMBL434371"],
  },
  {
    primary_id: "D04BWH",
    names: [
      "Benzo[b]thiophen-2-Ylboronic Acid",
      "Benzo[B]thiophene-2-Boronic Acid",
      "98437-23-1",
      "2-Benzothienylboronic Acid",
      "BENZO[B]THIOPHENE-2-BORONIC ACID",
      "1-Benzothiophen-2-Ylboronic Acid",
      "1-Benzothien-2-Ylboronic Acid",
      "Benzo(B)Thiophene-2-Boronic Acid",
      "Benzothiophene-2-Boronic Acid",
      "Thianaphthene-2-Boronic Acid",
      "Benzo[b]thiophene-2-Ylboronic Acid",
      "C8H7BO2S",
      "2-Benzothiopheneboronic Acid",
      "CHEMBL34964",
      "Benzothiophen-2-Ylboronic Acid",
      "Benzo[b]thiophen-2-Boronic Acid",
      "1-Benzothiophen-2-Yl-Boranediol",
      "Boronic Acid, Benzo[b]thien-2-Yl-",
      "(1-Benzothiophen-2-Yl)Boronic Acid",
      "Benzo[B]Thiophene-2-Boronic Acid",
    ],
  },
  {
    primary_id: "D04BXA",
    names: ["Pyrazole Derivative 32"],
  },
  {
    primary_id: "D04BXH",
    names: [
      "COTI-001",
      "COTI-002",
      "COTI-003E",
      "Protein Tyrosine Kinase Inhibitors (AML)",
      "Protein Tyrosine Kinase Inhibitors (AML), Critical Outcome Technologies",
    ],
  },
  {
    primary_id: "D04BXJ",
    names: ["ED-1812"],
  },
  {
    primary_id: "D04BXL",
    names: ["2-Morpholino-7-(2-Phenylethynyl)-4H-Chromen-4-One", "CHEMBL95923"],
  },
  {
    primary_id: "D04BXX",
    names: ["AN-019"],
  },
  {
    primary_id: "D04BYB",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 6"],
  },
  {
    primary_id: "D04BYL",
    names: ["6-Fluoronorepinehprine", "CHEMBL295072", "2-Fluoronorepinephrine", "Fluoronorepinephrine", "BDBM50087518"],
  },
  {
    primary_id: "D04BYU",
    names: ["Pamoic Acid"],
  },
  {
    primary_id: "D04BZI",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 4"],
  },
  {
    primary_id: "D04CAR",
    names: ["(R)-1-(2-Aminoacetyl)Pyrrolidin-2-Ylboronic Acid"],
  },
  {
    primary_id: "D04CBI",
    names: [
      "Oestradiol Valerate And Dienogest",
      "Dienogest",
      "65928-58-7",
      "Dienogestrel",
      "Dienogestum",
      "Dienogestril",
      "Dinagest",
      "Natazia",
      "Endometrion",
      "Visanne",
      "STS-557",
      "STS 557",
      "Dienogestum [Latin]",
      "UNII-46M3EV8HHE",
      "MJR-35",
      "17alpha-17-Hydroxy-3-Oxo-19-Norpregna-4,9-Diene-21-Nitrile",
      "BAY86-5258",
      "BAY 86-5258",
      "46M3EV8HHE",
      "CHEBI:70708",
      "17-Alpha-Cyanomethyl-17-Beta-Hydroxy-Estra-4,9(10)-Dien-3-One",
      "2-[(8S,13S,14S,17R)-17-Hydroxy-13-Methyl-3-Oxo-1,2,6,7,8,11,12,14,15,16-Decahydrocyclopenta[a]phenanthren-17-Yl]acetonitrile",
      "17-Alpha-Cyanomethyl-17-Beta-Hydroxyestra-4,9(10)-Diene-3-One",
      "Natazia (TN)",
    ],
  },
  {
    primary_id: "D04CCK",
    names: ["MT-119"],
  },
  {
    primary_id: "D04CCX",
    names: ["PMID30107136-Compound-Example14"],
  },
  {
    primary_id: "D04CDO",
    names: ["TAS-102"],
  },
  {
    primary_id: "D04CDV",
    names: ["1,2-Bis(2,6-Difluorophenyl)-2-Hydroxyethanone", "CHEMBL244426"],
  },
  {
    primary_id: "D04CDZ",
    names: ["Cyclo(-L-Am7(S2Py)-D-2MePhe-L-Ala-D-Pro-)", "CHEMBL393261"],
  },
  {
    primary_id: "D04CEY",
    names: ["PYM-50018"],
  },
  {
    primary_id: "D04CFM",
    names: ["ND-2158", "CHEMBL3361255", "GTPL8083", "SCHEMBL15227691", "SCHEMBL15115519", "BDBM50040805"],
  },
  {
    primary_id: "D04CFP",
    names: ["5-[4-(Pyridin-4-Ylmethyl)Phenyl]-1H-Indole", "CHEMBL1214737", "ZINC58581744"],
  },
  {
    primary_id: "D04CFW",
    names: [
      "Bacampicillin",
      "Bacampicilina",
      "Bacampicilline",
      "Bacampicillinum",
      "Penglobe",
      "Bacampicillin Hydrochloride",
      "Bacampicilina [INN-Spanish]",
      "Bacampicillin (INN)",
      "Bacampicillin [INN:BAN]",
      "Bacampicilline [INN-French]",
      "Bacampicillinum [INN-Latin]",
      "Penglobe (TN)",
      "Spectrobid (TN)",
      "(2S,5R,6R)-6((R)-(2-Amino-2-Phenylacetamido))-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid Ester With Ethyl 1-Hydroxyethylcarbonate",
      "1'-Ethoxycarbonyloxyethyl-(6-D-Alpha-Aminophenylacetamido)Penicillanate",
      "1-Ethoxycarbonyloxyethyl (2S,5R,6R)-6((R)-(2-Amino-2-Phenylacetamido)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptan-2-Carboxylat",
      "1-[(Ethoxycarbonyl)Oxy]ethyl (2S,5R,6R)-6-{[(2R)-2-Amino-2-Phenylacetyl]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
      "1-[(Ethoxycarbonyl)Oxy]ethyl 6beta-[(2R)-2-Amino-2-Phenylacetamido]-2,2-Dimethylpenam-3alpha-Carboxylate",
      "1-Ethoxycarbonyloxyethyl (2S,5R)-6-[[(2R)-2-Amino-2-Phenylacetyl]amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
      "1-Ethoxycarbonyloxyethyl (2S,5R,6R)-6-[[(2R)-2-Amino-2-Phenylacetyl]amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
      "1-Ethoxycarbonyloxyethyl (2S,5R,6R)-6-[[(2S)-2-Amino-2-Phenylacetyl]amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
      "6-((R)-2-Amino-2-Phenylacetamido)Penicillansaeure-(1-(Ethoxycarbonyloxy)Ethylester",
    ],
  },
  {
    primary_id: "D04CIL",
    names: ["HMG-CoA Reductase Inhibitor"],
  },
  {
    primary_id: "D04CJE",
    names: ["ATF-936"],
  },
  {
    primary_id: "D04CJG",
    names: ["Phosphoric Acid Monotetradecyl Ester"],
  },
  {
    primary_id: "D04CJL",
    names: ["Ferric Citrate"],
  },
  {
    primary_id: "D04CJN",
    names: ["CTP-298", "C-10276", "CTP-518", "Deuterated Atazanavir Isotopologs (HIV Infection), CoNCERT/GSK"],
  },
  {
    primary_id: "D04CKB",
    names: ["IkT-001"],
  },
  {
    primary_id: "D04CKC",
    names: ["GPC3-CART Cells"],
  },
  {
    primary_id: "D04CKG",
    names: ["SPI-2012"],
  },
  {
    primary_id: "D04CKR",
    names: ["CAL-103-R"],
  },
  {
    primary_id: "D04CLL",
    names: ["3-[4-Methyl-Indan-(1E)-Ylidenemethyl]-Pyridine"],
  },
  {
    primary_id: "D04CLM",
    names: ["YM-758"],
  },
  {
    primary_id: "D04CLQ",
    names: ["ISIS 103615"],
  },
  {
    primary_id: "D04CNX",
    names: ["Ro-25-1132"],
  },
  {
    primary_id: "D04COD",
    names: [
      "Gepotidacin",
      "1075236-89-3",
      "UNII-DVF0PR037D",
      "DVF0PR037D",
      "3H,8H-2a,5,8a-Triazaacenaphthylene-3,8-Dione, 2-((4-(((3,4-Dihydro-2H-Pyrano(2,3-C)Pyridin-6-Yl)Methyl)Amino)-1-Piperidinyl)Methyl)-1,2-Dihydro-, (2R)-",
      "GSK2140944E",
      "Gepotidacin [USAN:INN]",
      "3H,8H-2a,5,8a-Triazaacenaphthylene-3,8-Dione, 2-[[4-[[(3,4-Dihydro-2H-Pyrano[2,3-C]pyridin-6-Yl)Methyl]amino]-1-Piperidinyl]methyl]-1,2-Dihydro-, (2R)-",
      "Gepotidacin (USAN/INN)",
      "SCHEMBL3332319",
      "CHEMBL3317856",
      "GSK-2140944E",
      "GSK 2140944E",
      "BDBM5005",
    ],
  },
  {
    primary_id: "D04CON",
    names: ["SL-89.0591"],
  },
  {
    primary_id: "D04CPW",
    names: ["N-(4-Propylaminobutyl)-4-Biphenylcarboxamide", "CHEMBL444128"],
  },
  {
    primary_id: "D04CQG",
    names: [
      "Heparin-Cantithrombin III",
      "212391-63-4",
      "PD 166285 Dihydrochloride",
      "6-(2,6-Dichlorophenyl)-2-[[4-[2-(Diethylamino)Ethoxy]phenyl]amino]-8-Methylpyrido[2,3-D]pyrimidin-7(8H)-Onedihydrochloride",
      "PD166285/PD-166285",
      "SCHEMBL15106017",
      "CHEMBL3545196",
      "CTK8E7972",
      "DTXSID80432749",
      "MolPort-023-219-121",
      "PD 166285 2HCl",
      "EX-A1369",
      "AKOS024457798",
      "BCP9001063",
      "BCP0726000019",
      "KB-309461",
      "RT-014958",
      "FT-0673536",
      "J-013945",
      "6-(2,6-Dichlorophenyl)-2-[[4-[2-(Diethylamino)Ethoxy]phenyl]amino]-8-Methylpyrido[2,3-D]pyrimidin-7(8H)-One Dihydrochloride",
    ],
  },
  {
    primary_id: "D04CQK",
    names: ["MK-8521"],
  },
  {
    primary_id: "D04CQM",
    names: ["N-(1'-Phenyl-Ethyl) Estradiol-16-Carboxamide"],
  },
  {
    primary_id: "D04CQV",
    names: ["Cis-Urocanic Acid"],
  },
  {
    primary_id: "D04CRB",
    names: ["6-Phenylsulfanyl-9H-Purine"],
  },
  {
    primary_id: "D04CRL",
    names: ["Glatiramer Acetate"],
  },
  {
    primary_id: "D04CRM",
    names: ["Renazorb"],
  },
  {
    primary_id: "D04CRN",
    names: ["Atomoxetine"],
  },
  {
    primary_id: "D04CSZ",
    names: ["Menthol"],
  },
  {
    primary_id: "D04CTD",
    names: ["SNDX-6352"],
  },
  {
    primary_id: "D04CTN",
    names: ["3-(1-Propyl-Pyrrolidin-3-Yl)-Phenol", "CHEMBL81675", "SCHEMBL7316143"],
  },
  {
    primary_id: "D04CTO",
    names: ["2-Biphenyl-4-Yl-1-Phenothiazin-10-Yl-Ethanone", "CHEMBL240469"],
  },
  {
    primary_id: "D04CTP",
    names: ["EP-200"],
  },
  {
    primary_id: "D04CUA",
    names: ["PMID24210504C1o"],
  },
  {
    primary_id: "D04CUL",
    names: ["KKHT-10613"],
  },
  {
    primary_id: "D04CUZ",
    names: ["SRG"],
  },
  {
    primary_id: "D04CVC",
    names: ["4-Amino-3-Fluoro-Benzenesulfonamide", "4-Amino-3-Fluorobenzenesulfonamide"],
  },
  {
    primary_id: "D04CWS",
    names: ["Anti-M1 Prime"],
  },
  {
    primary_id: "D04CXX",
    names: ["GNKG-168"],
  },
  {
    primary_id: "D04CXY",
    names: ["Hedgehog Pathway Antagonists"],
  },
  {
    primary_id: "D04CYD",
    names: ["ONT-10"],
  },
  {
    primary_id: "D04CYY",
    names: ["ISIS 25123"],
  },
  {
    primary_id: "D04CZF",
    names: ["N5-[4-(Phenylmethoxy)Phenyl]-L-Glutamamide", "CHEMBL478717", "Modified Amino Acid Analog, 6c"],
  },
  {
    primary_id: "D04CZQ",
    names: ["CGP-57698", "168082-74-4"],
  },
  {
    primary_id: "D04DAL",
    names: ["LAS-41001", "Anti-Inflammatory Doxycycline Derivative (Rosacea), Almirall"],
  },
  {
    primary_id: "D04DBA",
    names: ["BAG956"],
  },
  {
    primary_id: "D04DBR",
    names: [
      "2,2'-Pyridylisatogen Tosylate",
      "56583-49-4",
      "PIT",
      "2-(2-Pyridinyl)-(3h)-Indol-3-One-1-Oxide 4-Methylbenzenesulfonate",
      "4-Methylbenzenesulfonic Acid",
      "1-Oxido-2-Pyridin-2-Ylindol-1-Ium-3-One",
      "Tocris-1682",
      "AC1L3Y9P",
      "AC1Q6WM2",
      "GTPL1729",
      "CHEMBL1364808",
      "CTK1H0971",
      "MolPort-023-276-189",
      "HMS3268O03",
      "AKOS024456745",
      "2-(Pyridin-2-Yl)-3h-Indol-3-One 1-Oxide 4-Methylbenzenesulfonate(1:1)",
      "NCGC00025378-01",
      "KB-221271",
      "B6827",
      "SR-01000597767",
      "SR-01000597767-1",
    ],
  },
  {
    primary_id: "D04DCO",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 9"],
  },
  {
    primary_id: "D04DCW",
    names: ["(+/-)-2-(4-Fluorophenyl)-7-Methylchroman-4-One", "CHEMBL600596"],
  },
  {
    primary_id: "D04DDM",
    names: ["ISIS 3521"],
  },
  {
    primary_id: "D04DDW",
    names: ["MK-49"],
  },
  {
    primary_id: "D04DFJ",
    names: ["Pulmonary Insulin"],
  },
  {
    primary_id: "D04DFR",
    names: ["FX005"],
  },
  {
    primary_id: "D04DFX",
    names: ["Bis(3-Bromophenyl)(4-Hydroxy)Thiosemicarbazone"],
  },
  {
    primary_id: "D04DGE",
    names: ["SB-247853", "5-Methyl-N-[6-(2-Pyridylmethoxy)Pyridin-3-Yl]-6-(Trifluoromethyl)Indoline-1-Carboxamide"],
  },
  {
    primary_id: "D04DGN",
    names: ["Clopidogrel Intravenous"],
  },
  {
    primary_id: "D04DHB",
    names: ["5,7-Dichlorokynurenic Acid", "Dcka"],
  },
  {
    primary_id: "D04DHD",
    names: [
      "Fasidotrilat",
      "AC1O52DL",
      "DTXSID20159221",
      "(2R)-2-[[(2R)-2-(1,3-Benzodioxol-5-Ylmethyl)-3-Sulfanylpropanoyl]amino]propanoic Acid",
    ],
  },
  {
    primary_id: "D04DHZ",
    names: ["CART-EGFR"],
  },
  {
    primary_id: "D04DJM",
    names: [
      "4-Hydroxysaprothoquinone",
      "CHEMBL453303",
      "4-Hydroxysaprorthoquinone",
      "AC1NRZ82",
      "BDBM50242319",
      "8-(4-Hydroxy-4-Methylpentyl)-7-Methyl-3-Propan-2-Ylnaphthalene-1,2-Dione",
    ],
  },
  {
    primary_id: "D04DJN",
    names: [
      "Dihydrotestosterone",
      "Androstanolone",
      "STANOLONE",
      "521-18-6",
      "Andractim",
      "Androlone",
      "Proteina",
      "Anaboleen",
      "Stanaprol",
      "Anabolex",
      "Protona",
      "Neodrol",
      "Cristerona MB",
      "4-Dihydrotestosterone",
      "17beta-Hydroxy-5alpha-Androstan-3-One",
      "5alpha-Dihydrotestosterone",
      "Androstanolonum",
      "Androstanolona",
      "DHT",
      "5-Alpha-Dihydrotestosterone",
      "Dihydrotestosteron",
      "Testosterone, Dihydro-",
      "4,5alpha-Dihydrotestosterone",
      "5alpha-Androstan-17beta-Ol-3-One",
      "Stanorone",
      "Stanolon",
      "17beta-Hydroxyandrostan-3-One",
      "5alpha-DHT",
      "LG 152",
      "17beta-Hydroxy-3-Androstanone",
      "[3H]dihydrotestosterone",
    ],
  },
  {
    primary_id: "D04DJS",
    names: ["ICI 118,551"],
  },
  {
    primary_id: "D04DKH",
    names: ["NAFTOPIDIL"],
  },
  {
    primary_id: "D04DKL",
    names: ["ALT-836"],
  },
  {
    primary_id: "D04DKM",
    names: ["(1S,3R)-ACPD", "(1S,3R)-1-ACPD", "Trans-ACPD", "(1S,3S)-ACPD", "1S,3R-ACPD"],
  },
  {
    primary_id: "D04DKX",
    names: [
      "NSC-527035",
      "Dicyclopentamethylenethiuram Disulfide",
      "94-37-1",
      "Dipentamethylenethiuram Disulfide",
      "1-Piperidinethiocarbonyl Disulfide",
      "Bis(1-Piperidylthiocarbonyl) Disulfide",
      "Bis(Pentamethylene)Thiuram Disulfide",
      "Bis(Piperidinothiocarbonyl) Disulfide",
      "Disulfide, Bis(1-Piperidinylthioxomethyl)",
      "NSC 527035",
      "UNII-CR113982E5",
      "DISULFIDE, BIS(PIPERIDINOTHIOCARBONYL)",
      "Bis(Piperidinothiocarbonyl)Disulfide",
      "EINECS 202-328-1",
      "Bis(1-Piperidylthiocarbonyl)Disulfide",
      "Bis(Piperidinothiocarbonyl) Disulphide",
      "BRN 0247608",
    ],
  },
  {
    primary_id: "D04DLM",
    names: [
      "AMINOHIPPURIC ACID",
      "4-Aminohippuric Acid",
      "Aminohippuric Acid",
      "61-78-9",
      "P-AMINOHIPPURIC ACID",
      "Paha",
      "Glycine, N-(4-Aminobenzoyl)-",
      "N-(4-Aminobenzoyl)Glycine",
      "Para-Aminohippuric Acid",
      "Nefrotest",
      "2-(4-Aminobenzamido)Acetic Acid",
      "N-(P-Aminobenzoyl)Glycine",
      "P-Aminohippurate",
      "Aminohippurate",
      "PAH (Amino Acid)",
      "4-Amino Hippuric Acid",
      "N-(Para-Aminobenzoyl)Glycine",
      "Hippuric Acid, P-Amino-",
      "N-(P-Aminobenzoyl)Aminoacetic Acid",
      "Para-Aminohippurate",
      "Paraaminohippuric Acid",
      "PAH",
      "UNII-Y79XT83BJ9",
      "NSC 13064",
      "HSDB 2139",
      "4-Aminobenzoyl Glycine",
      "EINECS 200-518-9",
    ],
  },
  {
    primary_id: "D04DLR",
    names: ["GR55562"],
  },
  {
    primary_id: "D04DLW",
    names: ["NA-1 Neuroprotectant"],
  },
  {
    primary_id: "D04DMO",
    names: ["FR-194738"],
  },
  {
    primary_id: "D04DMQ",
    names: ["Fabesetron"],
  },
  {
    primary_id: "D04DMZ",
    names: ["AVXS-101"],
  },
  {
    primary_id: "D04DNB",
    names: ["Xentuzumab"],
  },
  {
    primary_id: "D04DNL",
    names: ["ALN-FLU01"],
  },
  {
    primary_id: "D04DOL",
    names: ["N1-(Biphenyl-4-Yl)-N8-Hydroxyoctanediamide", "CHEMBL512644", "SCHEMBL8226957"],
  },
  {
    primary_id: "D04DOV",
    names: ["Trimethylamine"],
  },
  {
    primary_id: "D04DQF",
    names: ["2-(3-Acetylphenyl)-6-Morpholino-4H-Pyran-4-One", "CHEMBL223436"],
  },
  {
    primary_id: "D04DQJ",
    names: ["HONOKIOL"],
  },
  {
    primary_id: "D04DQO",
    names: ["ABBV-257"],
  },
  {
    primary_id: "D04DQP",
    names: ["ADRIAC-36"],
  },
  {
    primary_id: "D04DQZ",
    names: ["AZD-2327"],
  },
  {
    primary_id: "D04DRI",
    names: ["Mycophenolic Acid/Nucleotide Derivative 10"],
  },
  {
    primary_id: "D04DRM",
    names: [
      "N1-[4-(Phenylmethoxy)Phenyl]-D-Glutamine",
      "Amino Acid Analog, 4c",
      "CHEMBL477478",
      "BDBM24253",
      "(4R)-4-Amino-4-{[4-(Benzyloxy)Phenyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D04DSD",
    names: [
      "TEI-5178",
      "[1R-[1alpha,2beta(1E,3S*),3alpha]]-6-[[2-(3-Cyclohexyl-3-Hydroxy-1-Propenyl)-3-Hydroxy-5-Oxocyclopentyl]thio]hexanoic Acid Methyl Ester",
      "15-Cyclohexyl-16,17,18,19,20-Pentanor-7-Thiaprostaglandin E1 Methyl Ester",
    ],
  },
  {
    primary_id: "D04DTE",
    names: [
      "Inhibitor 19",
      "CHEMBL1782887",
      "GTPL8621",
      "BDBM50346448",
      "Inhibitor 19 [PMID: 21476495]",
      "2-[2-[[[(2S,6S,9E,13S)-13-Amino-2-[(4-Hydroxyphenyl)Methyl]-4,14-Dioxo-1,5-Diazacyclotetradec-9-Ene-6-Carbonyl]amino]methyl]phenyl]acetic Acid",
      "2-(2-(((2S,6S,13S,E)-13-Amino-2-(4-Hydroxybenzyl)-4,14-Dioxo-1,5-Diazacyclotetradec-9-Ene-6-Carboxamido)Methyl)Phenyl)Acetic Acid",
    ],
  },
  {
    primary_id: "D04DUF",
    names: ["Phenylsulfonyl Derivative 2"],
  },
  {
    primary_id: "D04DUG",
    names: ["AAV-GUS"],
  },
  {
    primary_id: "D04DUH",
    names: ["PMI-005"],
  },
  {
    primary_id: "D04DUJ",
    names: [
      "S-2474",
      "158089-95-3",
      "CTK4C9584",
      "DTXSID20724630",
      "PHENOL, 2,6-BIS(1,1-DIMETHYLETHYL)-4-[(E)-(2-ETHYL-1,1-DIOXIDO-5-ISOTHIAZOLIDINYLIDENE)METHYL]-",
    ],
  },
  {
    primary_id: "D04DVA",
    names: ["HAp-IFN"],
  },
  {
    primary_id: "D04DVF",
    names: ["CHF-1522"],
  },
  {
    primary_id: "D04DVT",
    names: ["XCC"],
  },
  {
    primary_id: "D04DWJ",
    names: ["Zn2+"],
  },
  {
    primary_id: "D04DXA",
    names: ["Tofogliflozin"],
  },
  {
    primary_id: "D04DXC",
    names: ["AcGlu-Dif-Ile-Cha-Cys", "CHEMBL304005"],
  },
  {
    primary_id: "D04DXN",
    names: [
      "Benzoyl Peroxide",
      "BENZOYL PEROXIDE",
      "94-36-0",
      "Dibenzoyl Peroxide",
      "Peroxide, Dibenzoyl",
      "Benzoperoxide",
      "Benzoyl Superoxide",
      "Acetoxyl",
      "Lucidol",
      "Panoxyl",
      "Benoxyl",
      "Benzol Peroxide",
      "Benzoylperoxid",
      "Asidopan",
      "Persadox",
      "Eloxyl",
      "Mytolac",
      "Oxylite",
      "Benzac",
      "Dibenzoylperoxid",
      "Resdan Akne",
      "Diphenylglyoxal Peroxide",
      "Epi-Clear",
      "Persa-Gel",
      "Dry And Clear",
      "Akneroxid 5",
      "Duresthin 5",
      "Nayper BO",
      "Luperco AST",
      "Benzoylperoxyde",
      "Theraderm",
      "Dibenzoylperoxyde",
      "Peroxyde De Benzoyle",
      "Benzoyl Benzenecarboperoxoate",
      "Perossido Di Benzoile",
      "Lucidol G 20",
      "Lucidol B 50",
      "Benzoic Acid, Peroxide",
    ],
  },
  {
    primary_id: "D04DYA",
    names: ["SMTP-0"],
  },
  {
    primary_id: "D04DYC",
    names: ["PF-06463922"],
  },
  {
    primary_id: "D04DYM",
    names: ["GW1929"],
  },
  {
    primary_id: "D04DYO",
    names: ["KAEMPFERIDE"],
  },
  {
    primary_id: "D04DYZ",
    names: [
      "ABT-839",
      "CHEMBL29982",
      "216234-25-2",
      "2-{(5-{[BUTYL-(2-CYCLOHEXYL-ETHYL)-AMINO]-METHYL}-2'-METHYL-BIPHENYL-2-CARBONYL)-AMINO]-4-METHYLSULFANYL-BUTYRIC ACID",
      "A 228839",
      "AC1L9KPG",
      "SCHEMBL629301",
      "BDBM17325",
      "1n94",
      "ZINC3973581",
      "KB-74366",
      "(2S)-2-[(4-{[butyl(2-Cyclohexylethyl)Amino]methyl}-2-(2-Methylphenyl)Phenyl)Formamido]-4-(Methylsulfanyl)Butanoic Acid",
      "N-[(5-{[butyl(2-Cyclohexylethyl)Amino]methyl}-2'-Methylbiphenyl-2-Yl)Carbonyl]-L-Methionine",
    ],
  },
  {
    primary_id: "D04DZD",
    names: ["Bidisomide", "Butanamide", "SC-40230"],
  },
  {
    primary_id: "D04DZE",
    names: [
      "BRL 26830A",
      "Brl 26830",
      "Methyl 4-(2-((2-Hydroxy-2-Phenethyl)Amino)Propyl)Benzoate-2-Butanedioate",
      "Brl-26830A",
      "Benzoic Acid, 4-(2-((2-Hydroxy-2-Phenylethyl)Amino)Propyl)-, Methyl Ester, (R*,R*)-(+-)-, (E)-2-Butenedioate (2:1) (Salt)",
      "87857-42-9",
      "AC1O5PHH",
      "LS-186797",
      "Methyl 4-[(2S)-2-[[(2S)-2-Hydroxy-2-Phenylethyl]amino]propyl]benzoate",
      "(E)-4-Oxopent-2-Enoic Acid",
    ],
  },
  {
    primary_id: "D04DZP",
    names: ["(H-261)Boc-His-Pro-Phe-His-Leu(OH)-Val-Ile-His-OH"],
  },
  {
    primary_id: "D04DZS",
    names: ["PMID8576905C4"],
  },
  {
    primary_id: "D04DZZ",
    names: ["N-(2-Nonyloxy-4-Nitrophenyl)Methanesulfonamide", "CHEMBL202469", "SCHEMBL3295100", "BDBM50182532"],
  },
  {
    primary_id: "D04EAT",
    names: [
      "MCS-110",
      "Colony Stimulating Factor-1 Inhibiting Antibody (Cancer), Novartis",
      "Anti-CSF-1 Antibody (Cancer), Novartis",
    ],
  },
  {
    primary_id: "D04EAY",
    names: ["3-(2-Hydroxy-Phenyl)-Benzo[d]isoxazol-6-Ol"],
  },
  {
    primary_id: "D04EAZ",
    names: ["M102.4"],
  },
  {
    primary_id: "D04ECT",
    names: ["4-Thiomorpholin-4-Yl-Benzo[g]chromen-2-One", "CHEMBL179640"],
  },
  {
    primary_id: "D04ECZ",
    names: [
      "Rac-Trans-N-Oleoylcyclopropanolamide",
      "Cyclopropanolamide, 12b",
      "Cyclopropanolamide, Rac-9b",
      "CHEMBL472662",
      "BDBM29082",
    ],
  },
  {
    primary_id: "D04EDK",
    names: ["6-Fluoro-N-(2-Morpholinoethyl)Nicotinamide", "CHEMBL611061", "BDBM50307187", "AKOS013288891"],
  },
  {
    primary_id: "D04EDM",
    names: ["PMID25684022-Compound-US20120277229 41(1.3)"],
  },
  {
    primary_id: "D04EDO",
    names: ["Improgan"],
  },
  {
    primary_id: "D04EDQ",
    names: ["CXCR3 Antagonists, Merck Serono", "CXCR3 Antagonists (Rheumatoid Arthritis), Merck Serono"],
  },
  {
    primary_id: "D04EET",
    names: ["West Nile Virus Vaccine"],
  },
  {
    primary_id: "D04EEU",
    names: [
      "Dazoxiben",
      "DAZOXIBEN",
      "78218-09-4",
      "Dazoxibene",
      "Dazoxiben [INN-Spanish]",
      "Dazoxibenum",
      "Dazoxibenum [INN-Latin]",
      "Dazoxibene [INN-French]",
      "Dazoxiben [INN:BAN]",
      "4-(2-(1H-Imidazol-1-Yl)Ethoxy)Benzoic Acid",
      "UNII-09ZFC7974Q",
      "4-(2-Imidazol-1-Ylethoxy)Benzoic Acid",
      "UK 37248",
      "Benzoic Acid, 4-(2-(1H-Imidazol-1-Yl)Ethoxy)-",
      "P-(2-Imidazol-1-Ylethoxy)Benzoic Acid",
      "4-(2-(1-Imidazolyl)Ethoxy)Benzoesaeure",
      "UK-37248",
      "CHEMBL267473",
      "103735-00-8",
      "4-[2-(1H-Imidazol-1-Yl)Ethoxy]benzoic Acid",
      "09ZFC7974Q",
      "AH-P 719 (9CI)",
      "NCGC00018938-03",
    ],
  },
  {
    primary_id: "D04EFB",
    names: ["OP-03"],
  },
  {
    primary_id: "D04EFG",
    names: ["DD-3", "DD-3 Program (Autoimmune/Inflammatory Disorders), Palau/Almirall/Esteve/Proteomika"],
  },
  {
    primary_id: "D04EFK",
    names: [
      "DDP-225",
      "AA-10021",
      "AA-10025",
      "AA-10026",
      "4-(2-Fluorophenyl)-6-Methyl-2-Piperazin-1-Ylthieno[2,3-D]pyrimidine Hydrate Hydrochloride",
    ],
  },
  {
    primary_id: "D04EFQ",
    names: ["Benzamide Derivative 19"],
  },
  {
    primary_id: "D04EGE",
    names: ["11C-PBR-170"],
  },
  {
    primary_id: "D04EGR",
    names: ["1-MNA"],
  },
  {
    primary_id: "D04EGX",
    names: [
      "Cabergoline",
      "Cabaser",
      "Cabaseril",
      "Cabergolina",
      "Cabergolinum",
      "Dostinex",
      "Galastop",
      "Sogilen",
      "Cabergolina [Spanish]",
      "Cabergolinum [Latin]",
      "CG-101",
      "Cabaser (TN)",
      "Dostinex (TN)",
      "FCE-21336",
      "Cabergoline [USAN:BAN:INN]",
      "Cabergoline (JAN/USAN/INN)",
      "(8R)-6-Allyl-N-[3-(Dimethylamino)Propyl]-N-(Ethylcarbamoyl)Ergoline-8-Carboxamide",
      "(8beta)-N-[3-(Dimethylamino)Propyl]-N-(Ethylcarbamoyl)-6-(Prop-2-En-1-Yl)Ergoline-8-Carboxamide",
      "(8beta)-N-[3-(Dimethylamino)Propyl]-N-[(Ethylamino)Carbonyl]-6-(2-Propenyl)-Ergoline-8-Carboxamide",
      "(8beta)-N-[3-(Dimethylamino)Propyl]-N-[(Ethylamino)Carbonyl]-6-Prop-2-En-1-Ylergoline-8-Carboxamide",
      "1-((6-Allylergolin-8beta-Yl)Carbonyl)-1-(3-(Dimethylamino)Propyl)-3-Ethylurea",
      "1-[(6-Allylergoline-8beta-Yl)Carbonyl]-1-[3-(Dimethylamino)Propyl]-3-Ethylurea",
      "1-Ethyl-2-(3'-Dimethylaminopropyl)-3-(6'-Allylergoline-8'-Beta-Carbonyl)Urea Diphosphate",
      "1-Ethyl-3-(3'-Dimethylamionpropyl)-2-(6'-Allylergoline-8'beta-Carbonyl)Urea",
    ],
  },
  {
    primary_id: "D04EHF",
    names: ["DAV-132"],
  },
  {
    primary_id: "D04EIE",
    names: ["N5-(1-Iminopent-3-Enyl)-L-Ornithine"],
  },
  {
    primary_id: "D04EIF",
    names: [
      "LOXORIBINE",
      "AOG",
      "Loxoribine < Rec INN",
      "RWJ-21757",
      "7-(2-Propenyl)-8-Oxo-7,8-Dihydroguanosine",
      "7-Allyl-2-Amino-9-Beta-D-Ribofuranosylpurine-6,8(1H,9H)-Dione",
      "7-Allyl-8-Oxo-7,8-Dihydroguanosine",
      "7A8OGua",
    ],
  },
  {
    primary_id: "D04EIL",
    names: ["YM-344031"],
  },
  {
    primary_id: "D04EIX",
    names: ["AA-102", "Apoptosis Inducer (Advanced Breast Cancer), Bionovo"],
  },
  {
    primary_id: "D04EJG",
    names: ["SHR-1314"],
  },
  {
    primary_id: "D04EJU",
    names: ["Ropinirole Subdermal Implant"],
  },
  {
    primary_id: "D04EKZ",
    names: ["Ro 8-4304"],
  },
  {
    primary_id: "D04ELA",
    names: ["Picolinamido Propanoic Acid Derivative 2"],
  },
  {
    primary_id: "D04ELD",
    names: ["Glassia"],
  },
  {
    primary_id: "D04ELJ",
    names: [
      "3-[5-Chloro-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 7b",
      "AC1O704J",
      "CHEMBL193652",
      "BDBM8589",
      "SCHEMBL4220785",
      "ZINC13610777",
      "3-[(Z)-(5-Chloro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D04ELS",
    names: ["ISCAR"],
  },
  {
    primary_id: "D04EMB",
    names: ["IW-1973"],
  },
  {
    primary_id: "D04EMN",
    names: ["Mipsagargin"],
  },
  {
    primary_id: "D04ENF",
    names: ["Gene Therapy, Retinopathy, Copernicus"],
  },
  {
    primary_id: "D04ENK",
    names: [
      "TCV-309",
      "AC1L3GRQ",
      "LS-184311",
      "2-[3-(N-(2-Bromo-1-Propylpyridin-1-Ium-4-Carbonyl)Anilino)Propanoylamino]ethyl 3,4-Dihydro-1H-Isoquinoline-2-Carboxylate Nitrate",
    ],
  },
  {
    primary_id: "D04EOH",
    names: ["APC-003", "Autophagy Inducer (Cancer), Shanghai Ambrosia Pharmaceutical"],
  },
  {
    primary_id: "D04EON",
    names: ["BAL-101553"],
  },
  {
    primary_id: "D04EOV",
    names: ["3-Ethoxycarbonyl-6-Propyl-4-Quinolone", "CHEMBL381491"],
  },
  {
    primary_id: "D04EOZ",
    names: ["JWH-294"],
  },
  {
    primary_id: "D04EPO",
    names: [
      "7-Hydroxy-3-(3-Hydroxyphenyl)-1-Naphthonitrile",
      "SCHEMBL3020111",
      "CHEMBL467183",
      "UXJULIKCAFGFJL-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D04EPP",
    names: ["RTL-342m"],
  },
  {
    primary_id: "D04EPS",
    names: [
      "ON-24160",
      "Beta Tubulin Depolymerizer (Cancer), Onconova",
      "Beta-Tubulin Polymerization Inhibitors (Cancer), Onconova",
      "ON-24 Series (Cancer), Onconova",
    ],
  },
  {
    primary_id: "D04EQE",
    names: ["N-Methylstephisoferulin"],
  },
  {
    primary_id: "D04EQF",
    names: ["MT-101"],
  },
  {
    primary_id: "D04EQX",
    names: ["BIIB-098"],
  },
  {
    primary_id: "D04ERQ",
    names: ["CXD-101"],
  },
  {
    primary_id: "D04ESE",
    names: ["Imidazo[1,2-B]pyridazine Acetamide Derivative 4"],
  },
  {
    primary_id: "D04ESH",
    names: ["PMID30107136-Compound-Example8"],
  },
  {
    primary_id: "D04ESM",
    names: ["Pyrimidinediones"],
  },
  {
    primary_id: "D04ETB",
    names: [
      "CYLINDROL A",
      "CHEMBL318783",
      "BDBM50059845",
      "Propionic Acid (E)-(R)-4-(3-Formyl-2,6-Dihydroxy-4-Methyl-Phenyl)-2-Methyl-1-((1S,2R,6R)-1,2,6-Trimethyl-3-Oxo-Cyclohexylmethyl)-But-2-Enyl Ester",
    ],
  },
  {
    primary_id: "D04ETV",
    names: ["Nemonaxacin"],
  },
  {
    primary_id: "D04ETX",
    names: ["1,1,1-Trifluoro-8-Phenoxy-Octan-2-One", "CHEMBL114796", "BDBM50217940"],
  },
  {
    primary_id: "D04EVE",
    names: ["VR876"],
  },
  {
    primary_id: "D04EVL",
    names: ["ISIS 134583"],
  },
  {
    primary_id: "D04EWA",
    names: ["Des-AA1,2,5-[(NalphaMe)Lys4,D-Nal8,IAmp9]SRIF", "CHEMBL406559"],
  },
  {
    primary_id: "D04EWW",
    names: ["Isoferulic Acid"],
  },
  {
    primary_id: "D04EXA",
    names: [
      "Erythro-3,4-Dichloromethylphenidate Hydrochloride",
      "CHEMBL537654",
      "Erythro-3,4-Dichloromethylphenidate HCl",
    ],
  },
  {
    primary_id: "D04EXR",
    names: [
      "4,6-Dichloro-1H-Indole-2,3-Dione",
      "4,6-Dichloroisatin",
      "18711-15-4",
      "4,6-DICHLOROINDOLINE-2,3-DIONE",
      "4,6-Dichloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "1H-Indole-2,3-Dione,4,6-Dichloro-",
      "CGCVHJCZBIYRQC-UHFFFAOYSA-N",
      "4,6-Dichloro-1H-Benzo[d]azoline-2,3-Dione",
      "4,6-Dichlorisatin",
      "NSC72897",
      "AC1L5KVQ",
      "AC1Q3MQL",
      "Isatin-Based Compound, 50",
      "SCHEMBL124586",
      "CHEMBL385800",
      "Jsp003834",
      "BDBM22830",
      "CTK4D9417",
      "4,6-Dichloroindolin-2,3-Dione",
      "DTXSID60291058",
      "4,6-Dichloro-2,3-Indolinedione",
      "MolPort-001-969-148",
      "ACT09176",
      "ZINC1698777",
      "ZERO/005650",
      "KS-00000J1A",
    ],
  },
  {
    primary_id: "D04EXW",
    names: ["A-Fizz"],
  },
  {
    primary_id: "D04EXX",
    names: ["KBF-611"],
  },
  {
    primary_id: "D04EYC",
    names: [
      "Metaraminol",
      "Araminol",
      "Hydroxynorephedrine",
      "Isophenylephrine",
      "Metaradrin",
      "Metaradrine",
      "Metaraminolum",
      "Pressonex",
      "Pressorol",
      "Icoral B",
      "Metaraminolum [Latin]",
      "Aramine (TN)",
      "L-Metaraminol",
      "M-Hydroxy Norephedrine",
      "M-Hydroxynorephedrine",
      "M-Hydroxyphenylpropanolamine",
      "M-Hydroxypropadrine",
      "Meta-Hydroxynorephedrine",
      "Metaraminol (INN)",
      "Metaraminol [INN:BAN]",
      "Metaraminolum [INN-Latin]",
      "Alpha-(1-Aminoethyl)-3-Hydroxybenzenemethanol",
      "Alpha-(1-Aminoethyl)-M-Hydroxybenzyl Alcohol",
      "Alpha-(M-Hydroxyphenyl)-Beta-Aminopropanol",
      "M-Hydroxy-Alpha-(1-Aminoethyl)-Benzyl Alcohol",
      "Benzenemethanol, Alpha-(1-Aminoethyl)-3-Hydroxy-, (R-(R*,S*))-(9CI)",
      "(-)-Erythro-Metaraminol",
      "1-(M-Hydroxyphenyl)-2-Amino-1-Propanol",
      "1-Metaraminol",
      "1-Alpha-(1-Aminoethyl)-M-Hydroxybenzyl Alcohol",
      "2-Amino-1-(M-Hydroxyphenyl)-1-Propanol",
      "3-Hydroxyphenylisopropanolamine",
      "3-[(1R,2S)-2-Amino-1-Hydroxypropyl]phenol",
    ],
  },
  {
    primary_id: "D04EYG",
    names: ["Ranibizumab"],
  },
  {
    primary_id: "D04EYR",
    names: ["Metrenperone"],
  },
  {
    primary_id: "D04EZI",
    names: [
      "Peru-15 PCTB",
      "Cholera And Enterotoxigenic Escherichia Coli (ETEC)-Induced Travelers' Diarrhea Vaccine, AVANT",
      "Cholera And Enterotoxigenic Escherichia Coli (ETEC)-Induced Travelers' Diarrhea Vaccine, Celldex",
    ],
  },
  {
    primary_id: "D04EZN",
    names: [
      "Dimethylthiambutene",
      "Dimethibutin",
      "Ohton",
      "Dimetiltiambuteno",
      "Aminobutene",
      "Dimethylthiambutenum",
      "Kobaton",
      "N,N,1-Trimethyl-3,3-Di-2-Thienylallylamine",
      "3-Dimethylamino-1,1-Bis(2-Thienyl)-1-Butene",
      "524-84-5",
      "3-Dimethylamino-1,1-Di-(2'-Thienyl)-1-Butene",
      "Dimetiltiambutene [DCIT]",
      "N,N,1-Trimethyl-3,3-Di(2-Thienyl)-2-Propenylamine",
      "Dimetiltiambuteno [INN-Spanish]",
      "Dimethylthiambutenum [INN-Latin]",
      "Dimethylthiambutene [INN:BAN:DCF]",
      "338C48",
      "Allylamine, N,N,1-Trimethyl-3,3-Di-2-Thienyl-",
      "BRN 0084891",
      "DEA No. 9619",
      "CHEBI:59781",
      "NIH4542",
    ],
  },
  {
    primary_id: "D04EZR",
    names: ["SIG-003"],
  },
  {
    primary_id: "D04FAC",
    names: [
      "SHEF-1",
      "AMD Stem Cell Therapy, Axordia/London Project To Cure Blindness",
      "AMD Stem Cell Therapy, Intercytex/London Project To Cure Blindness",
      "Age-Related Macular Degeneration Stem Cell Therapy, Intercytex/London Project To Cure Blindness",
      "Embryonic Stem Cell Therapy (Retinal Disease), UCL/Pfizer",
      "Retinal Pigmentepithelium-Based Stem Cell Therapy (AMD/Retinal Diseases), University College London/Pfizer",
    ],
  },
  {
    primary_id: "D04FAI",
    names: ["SCIO-323"],
  },
  {
    primary_id: "D04FAS",
    names: ["PMID29757691-Compound-8b"],
  },
  {
    primary_id: "D04FBR",
    names: ["Mycophenolate Mofetil"],
  },
  {
    primary_id: "D04FBZ",
    names: ["Nalorphine"],
  },
  {
    primary_id: "D04FDN",
    names: ["Palifosfamide"],
  },
  {
    primary_id: "D04FFM",
    names: ["ADXS-HPV"],
  },
  {
    primary_id: "D04FFS",
    names: ["Benzaldehyde O-4-(Pentyloxy)Phenylcarbamoyl Oxime", "CHEMBL598244"],
  },
  {
    primary_id: "D04FFY",
    names: ["AMG-8563"],
  },
  {
    primary_id: "D04FGX",
    names: ["EP-302"],
  },
  {
    primary_id: "D04FHB",
    names: ["R547"],
  },
  {
    primary_id: "D04FHG",
    names: ["PAF"],
  },
  {
    primary_id: "D04FHQ",
    names: ["ISIS 114197"],
  },
  {
    primary_id: "D04FHY",
    names: ["BL-7020"],
  },
  {
    primary_id: "D04FHZ",
    names: [
      "(-)-Tolserine",
      "CHEMBL300143",
      "Physostigmine Carbamate 17a",
      "SCHEMBL14242946",
      "BDBM10611",
      "(3aS)-1,3a,8-Trimethyl-1H,2H,3H,3aH,8H,8aH-Pyrrolo[2,3-B]indol-5-Yl N-(2-Methylphenyl)Carbamate",
    ],
  },
  {
    primary_id: "D04FIN",
    names: ["ZAPOTIN"],
  },
  {
    primary_id: "D04FIO",
    names: ["Aom-0871"],
  },
  {
    primary_id: "D04FIP",
    names: [
      "Cyclodextrin",
      "Cyclodextrins",
      "Cyclodextrin (Osteoarthritis)",
      "Cyclodextrin (Osteoarthritis), Arcarios",
    ],
  },
  {
    primary_id: "D04FJY",
    names: ["PMID25399762-Compound-Table1-C24"],
  },
  {
    primary_id: "D04FKX",
    names: ["D-3263"],
  },
  {
    primary_id: "D04FLL",
    names: [
      "(+/-)-2-Phenylthiomorpholin-5-One",
      "2-Phenylthiomorpholin-5-One",
      "CHEMBL592160",
      "42109-49-9",
      "6-Phenylthiomorpholin-3-One",
      "SCHEMBL10833495",
      "MolPort-039-240-430",
      "BDBM50307372",
      "AKOS027196661",
    ],
  },
  {
    primary_id: "D04FMB",
    names: ["XK-469"],
  },
  {
    primary_id: "D04FMU",
    names: [
      "3-Amino-5-(Furan-2-Yl)Biphenyl-2,4-Dicarbonitrile",
      "CHEMBL577938",
      "SMR000054390",
      "AC1LCDQ5",
      "Cambridge Id 5105969",
      "MLS000104455",
      "Cid_625239",
      "ZINC28097",
      "SLQVQKSDEBBOSA-UHFFFAOYSA-N",
      "MolPort-000-475-780",
      "HMS1700M17",
      "BDBM50303384",
      "STK831931",
      "AKOS000663950",
      "MCULE-3002689151",
      "BAS 13070166",
      "ST001263",
      "AB00073722-01",
      "3-Amino-5-Furan-2-Yl-Biphenyl-2,4-Dicarbonitrile",
      "2-Amino-6-(2-Furyl)-4-Phenylbenzene-1,3-Dicarbonitrile",
      "1,1'-Biphenyl-2,4-Dicarbonitrile, 3-Amino-5-(2-Furyl)-",
      "3-Amino-5-(2-Furyl)[1,1'-Biphenyl]-2,4-Dicarbonitrile #",
    ],
  },
  {
    primary_id: "D04FMY",
    names: ["Oncolym"],
  },
  {
    primary_id: "D04FNI",
    names: [
      "Chaetocin",
      "Chetocin",
      "28097-03-2",
      "Chaetocin From Chaetomium Minutum",
      "BRN 5722505",
      "AC1L4PPK",
      "GTPL8388",
      "SCHEMBL13662584",
      "CHEBI:94977",
      "MolPort-003-983-881",
      "NSC745363",
      "NSC-745363",
      "LS-52961",
      "Chaetocin From Chaetomium Minutum, &gt",
      "J-016970",
      "BRD-A85860691-001-01-4",
      "BRD-A85860691-001-03-0",
      "Hydroxymethyl-(Hydroxymethyl-Methyl-Dioxo-[?]yl)-Methyl-[?]dione",
      "[10b,10'b(11H,11'H)-Bi-3,11a-Epidithio-11aH-Pyrazino[1',2':1,5]pyrrolo[2,3-B]indole]-1,1',4,4'-Tetrone, 2,2',3,3',5a,5'a,6,6'-Octahydro-3,3'-Bis(Hydroxymethyl)-2,2'-Dime",
    ],
  },
  {
    primary_id: "D04FNQ",
    names: ["BIM23A760"],
  },
  {
    primary_id: "D04FNR",
    names: ["Mitumomab"],
  },
  {
    primary_id: "D04FNY",
    names: [
      "NP-506",
      "CHEMBL480356",
      "SCHEMBL20130652",
      "BDBM50256010",
      "3-{2,4-Dimethyl-5-[2-Oxo-5-(N''-Phenylhydrazinocarbonyl)-1,2-Dihydro-Indol-3-Ylidenemethyl]-1H-Pyrrol-3-Yl}-Propionic Acid",
    ],
  },
  {
    primary_id: "D04FPN",
    names: ["CD19 CAR-T Lymphocytes"],
  },
  {
    primary_id: "D04FPW",
    names: ["Des-AA1,4,5,13-[Tyr2,D-Trp8]-SRIF", "CHEMBL438630"],
  },
  {
    primary_id: "D04FQA",
    names: ["4-Hydroxy-1-Methyl-4-(4-Methylphenyl)-3-Piperidyl 4-Methylphenyl Ketone"],
  },
  {
    primary_id: "D04FQD",
    names: ["PH-80"],
  },
  {
    primary_id: "D04FQV",
    names: ["PMID24900526C3"],
  },
  {
    primary_id: "D04FQZ",
    names: ["4-(4-Hexylphenyl)-4-Oxobut-2-Enoic Acid", "CHEMBL237601", "SCHEMBL16227167"],
  },
  {
    primary_id: "D04FRB",
    names: ["CGP-13774"],
  },
  {
    primary_id: "D04FRL",
    names: ["PMID25666693-Compound-44"],
  },
  {
    primary_id: "D04FRU",
    names: ["LLG783"],
  },
  {
    primary_id: "D04FSD",
    names: ["TgAAG76"],
  },
  {
    primary_id: "D04FTB",
    names: ["AR-H049020"],
  },
  {
    primary_id: "D04FUZ",
    names: ["1-(3-(2-(3-Methoxyphenoxy)Ethoxy)Propyl)Azepane", "CHEMBL476465"],
  },
  {
    primary_id: "D04FVD",
    names: [
      "TGBA01AD",
      "ARABINOFURANOSYLURACIL",
      "Spongouridin",
      "1-Pentofuranosylpyrimidine-2,4(1H,3H)-Dione",
      "Uracil Arabinoside",
      "3083-77-0",
      "1-[3,4-Dihydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl]pyrimidine-2,4-Dione",
      "Uridine-5-D",
      "AK-47314",
      "AC1L1AWE",
      "ACMC-209m8u",
      "1-.beta.-D-Ribofuranosyl-2,4(1H,3H)-Pyrimidinedione",
      "NCIOpen2_003486",
      "Oprea1_327785",
      "MLS006011814",
      "CHEMBL68846",
      "SCHEMBL2056758",
      "ARONIS003790",
      "AC1Q69D3",
      "KS-00003WPG",
      "CTK8J4890",
    ],
  },
  {
    primary_id: "D04FVP",
    names: [
      "TGF-Beta Receptor 1 Kinase Inhibitor",
      "BIBF-0775",
      "TGF-Beta Receptor 1 Kinase Inhibitor (Fibrosis/Cancer)",
      "ALK-5 Inhibitors (Fibrosis/Cancer), Boehringer Ingelheim",
      "TGF-Beta Receptor 1 Kinase Inhibitor (Fibrosis/Cancer), Boehringer Ingelheim",
    ],
  },
  {
    primary_id: "D04FVU",
    names: [
      "Palonosetron",
      "Aloxi",
      "Onicit",
      "Palonosetron [INN]",
      "Aloxi (TN)",
      "RS 25233-197",
      "RS 25259-197",
      "RS-25233-197",
      "RS-25259-197",
      "(S-(R*,R*))-2-(1-Azabicyclo(2.2.2)Oct-3-Yl)-2,3,3a,4,5,6-Hexahydro-1H-Benz(De)Isoquinolin-1-One",
    ],
  },
  {
    primary_id: "D04FVX",
    names: [
      "(E)-9-Nitrohexadec-9-EnoicAcid",
      "CHEMBL560513",
      "9-Nitropalmitoleic Acid",
      "E-9-Nitropalmitoleic Acid",
      "SCHEMBL15633556",
      "YZNSJIAWRWPQGX-NTCAYCPXSA-N",
      "(E)-9-Nitro-9-Hexadecenoic Acid",
      "BDBM50295047",
    ],
  },
  {
    primary_id: "D04FWC",
    names: ["BMS-986249"],
  },
  {
    primary_id: "D04FWH",
    names: ["4-Hydroxyphenyl Acetaldehyde"],
  },
  {
    primary_id: "D04FWP",
    names: ["AMG 157"],
  },
  {
    primary_id: "D04FXD",
    names: [
      "CYANATE",
      "Cyanate",
      "ISOCYANATE",
      "UNII-YML51WA0FW",
      "YML51WA0FW",
      "661-20-1",
      "71000-82-3",
      "UN2206",
      "Cyanate Ion(1-)",
      "Isocyanate Ion(1-)",
      "Isocyanate Ion (1-)",
      "Isocyanic Acid, Ion(1-)",
      "Isocynate",
      "Zyanat",
      "Cyanat",
      "Carbonyl Amide",
      "Hydrogen Cyanate",
      "UN3080",
      "Nitridooxidocarbonate(1-)",
      "AC1L2XX2",
      "OCN(-)",
      "CHEBI:29195",
      "CTK5I4582",
      "CTK2H4173",
      "BDBM26982",
      "CNO(-1)",
      "IQPQWNKOIGAROB-UHFFFAOYSA-N",
      "XLJMAIOERFSOGZ-UHFFFAOYSA-M",
      "AC1Q2283",
      "BDBM50098562",
      "[C(N)O](-)",
      "Isocyanates, Toxic, N.o.s. Or Isocyanate Solutions, Toxic, N.o.s., Fp 61 C And Bp 300 C",
      "Isocyanates, Toxic, N.o.s. O",
    ],
  },
  {
    primary_id: "D04FXE",
    names: ["KW-3433", "CHEMBL433895", "BDBM50049200"],
  },
  {
    primary_id: "D04FXS",
    names: [
      "Didox",
      "69839-83-4",
      "N,3,4-Trihydroxybenzamide",
      "3,4-Dihydroxybenzohydroxamic Acid",
      "NSC-324360",
      "Benzamide, N,3,4-Trihydroxy-",
      "3,4-Dihydroxyphenylhydroxamic Acid",
      "UNII-L106XFV0RQ",
      "VF 147",
      "NSC 324360",
      "DIDO",
      "CCRIS 7909",
      "BRN 2096682",
      "L106XFV0RQ",
      "C7H7NO4",
      "NSC324360",
      "AC1L1F1T",
      "Benzamide,3,4-Trihydroxy-",
      "AC1Q5DI9",
      "N-3,4-Tridhydroxybenzamide",
      "SCHEMBL171446",
      "CHEMBL367788",
      "SCHEMBL18732059",
      "Didox, &gt",
      "SCHEMBL18346609",
      "CTK8F9165",
      "DTXSID90220134",
      "QJMCKEPOKRERLN-UHFFFAOYSA-N",
      "MolPort-009-019-216",
      "KS-00001DF2",
      "BCP16846",
      "ZINC3872288",
      "1716AH",
    ],
  },
  {
    primary_id: "D04FXY",
    names: ["SMT-19969"],
  },
  {
    primary_id: "D04FYI",
    names: ["H Pylori Vaccine"],
  },
  {
    primary_id: "D04FYL",
    names: [
      "Contusugene Ladenovec",
      "Advexin",
      "Ad5CMV-P53",
      "INGN-004",
      "INGN-201",
      "Ad-P53, Introgen",
      "Gene Therapy (P53/Adenovirus), University Of Texas",
      "Gene Therapy (P53/Adenoviral), Introgen/Aventis",
      "Gene Therapy (P53/Adenoviral), Introgen/RPR",
    ],
  },
  {
    primary_id: "D04FYM",
    names: ["Genital Herpes Therapy"],
  },
  {
    primary_id: "D04FYN",
    names: [
      "Diethyl 2-((Biphenyl-3-Ylamino)Methylene)Malonate",
      "CHEMBL217897",
      "ZINC14956351",
      "Diethyl [(Biphenyl-3-Ylamino)Methylidene]propanedioate",
    ],
  },
  {
    primary_id: "D04FYU",
    names: ["DMP-444"],
  },
  {
    primary_id: "D04FYZ",
    names: ["Plevitrexed (R)-Isomer"],
  },
  {
    primary_id: "D04FZL",
    names: ["H.P. Acthar Gel"],
  },
  {
    primary_id: "D04GAG",
    names: ["GBR 1342"],
  },
  {
    primary_id: "D04GAX",
    names: ["3-(4,5-Dihydrooxazol-2-Yl)Phenyl Propylcarbamate", "CHEMBL576381", "SCHEMBL4751773"],
  },
  {
    primary_id: "D04GBL",
    names: ["2-(1-O-Tolyl-1H-Pyrrol-3-Yl)Ethanamine", "CHEMBL232994"],
  },
  {
    primary_id: "D04GBP",
    names: ["QR-010"],
  },
  {
    primary_id: "D04GBW",
    names: ["IC31 Seasonal Influenza Vaccine"],
  },
  {
    primary_id: "D04GCC",
    names: ["N-Acylpiperidine Ether Derivative 6"],
  },
  {
    primary_id: "D04GCH",
    names: [
      "Bmab-200",
      "Anti-EGFR MAb (Cancer), Biocon",
      "Anti-Epidermal Growth Factor Receptor Monoclonal Antibody (Cancer), Biocon",
    ],
  },
  {
    primary_id: "D04GCV",
    names: ["APS-2010"],
  },
  {
    primary_id: "D04GDI",
    names: ["NP93-31"],
  },
  {
    primary_id: "D04GDW",
    names: [
      "2-Methoxyestradiol-17-O-Sulfamate",
      "CHEMBL374721",
      "2-Methoxyestradiol 17-O-Sulfamate",
      "(13alpha,14beta,17alpha)-3-Hydroxy-2-Methoxyestra-1,3,5(10)-Trien-17-Yl Sulfamate",
      "2x7s",
      "BDBM50200940",
    ],
  },
  {
    primary_id: "D04GDY",
    names: [
      "DELTA 8-TETRAHYDROCANNOBINOL",
      "5957-75-5",
      "UNII-B49D0HH807",
      "CHEMBL267227",
      "B49D0HH807",
      "Delta8-THC",
      "Delta(Sup 8)-Thc",
      "Delta(Sup 6)-Thc",
      "Delta-(Sup6)-THC",
      "Delta-(Sup8)-THC",
      "Delta6-Tetrahydrocannabinol",
      "Delta-6-THC",
      "(-)-Delta8-Tetrahydrocannabinol",
      "Cannabinol, Delta1(6)-Tetrahydro-",
      "Delta1(6)-Trans-Tetrahydrocannabinol",
      "BRN 0023921",
      "1-Trans-Delta-(Sup8)-Tetrahydrocannabinol",
      "1-Trans-Delta(Sup 8)-Tetrahydrocannabinol",
      "(-)-Delta(Sup 8)-Trans-Tetrahydrocannabinol",
      "(-)-Delta-(Sup8)-Trans-Tetrahydrocannabinol",
      "Cannabinol, 1-Trans-Delta(S",
    ],
  },
  {
    primary_id: "D04GEI",
    names: ["TAK-448"],
  },
  {
    primary_id: "D04GFO",
    names: ["MC-2001"],
  },
  {
    primary_id: "D04GFX",
    names: ["MRS1132"],
  },
  {
    primary_id: "D04GGA",
    names: [
      "StaphStop",
      "Anti-Autolysin Monoclonal Antibody Passive Immunotherapy (MRSA Associated Bone Infections)",
      "Passive Immunotherapy (MRSA Associated Bone Infections), Codevax/University Of Rochester Medical Center",
      "Anti-Autolysin Monoclonal Antibody Passive Immunotherapy (MRSA Associated Bone Infections), Codevax/University Of Rochester Medical Center",
    ],
  },
  {
    primary_id: "D04GGG",
    names: ["CD123/CLL1 CAR-T Cells"],
  },
  {
    primary_id: "D04GGJ",
    names: ["WST-057"],
  },
  {
    primary_id: "D04GGK",
    names: ["KO-286011", "132657-47-7"],
  },
  {
    primary_id: "D04GGQ",
    names: ["MPC-0767"],
  },
  {
    primary_id: "D04GGW",
    names: ["Octanedioic Acid Hydroxyamide Pyridin-4-Ylamide"],
  },
  {
    primary_id: "D04GHG",
    names: ["Pim1 Inhibitors"],
  },
  {
    primary_id: "D04GHK",
    names: ["2-N-Hydroxyamino-1-(4-Methylthiophenyl)Butane", "CHEMBL1078920", "1204749-75-6"],
  },
  {
    primary_id: "D04GIJ",
    names: ["Piperazine Urea Derivative 4"],
  },
  {
    primary_id: "D04GIK",
    names: ["Smac Mimetics"],
  },
  {
    primary_id: "D04GIO",
    names: ["Anti-CD19 And Anti-CD20 CAR-T Cells"],
  },
  {
    primary_id: "D04GJN",
    names: [
      "Megestrol",
      "Chronopil",
      "Megestrolo",
      "Megestrolo [DCIT]",
      "Chronopil (TN)",
      "Megace (TN)",
      "Megestrol (INN)",
      "Megestrol [INN:BAN]",
      "Megestrolum [INN-Latin]",
      "(8R,9S,10R,13S,14S,17R)-17-Acetyl-17-Hydroxy-6,10,13-Trimethyl-2,8,9,11,12,14,15,16-Octahydro-1H-Cyclopenta[a]phenanthren-3-One",
      "17-Hydroxy-6-Methylpregna-4,6-Diene-3,20-Dione",
    ],
  },
  {
    primary_id: "D04GJX",
    names: ["ZD-9720"],
  },
  {
    primary_id: "D04GKL",
    names: ["RAXOFELAST"],
  },
  {
    primary_id: "D04GKO",
    names: [
      "Cilazapril",
      "Cilazaprilum",
      "Cilazil",
      "Dynorm",
      "Inhibace",
      "Inibace",
      "Justor",
      "Vascace",
      "CILAZAPRIL MONOHYDRATE",
      "Cilazapril Anhydrous;Cilazapril Hydrate",
      "Cilazaprilum [Latin]",
      "Cilazapril (INN)",
      "Cilazapril (USAN)",
      "Cilazapril (Anhydrous)",
      "Cilazapril Hydrate (JAN)",
      "Cilazapril, Anhydrous",
      "Inhibace (TN)",
      "Ro 31-2848",
      "Ro 31-2848 Monohydrate",
      "Ro 34-2848",
      "Ro-312848",
      "Ro-31-2848",
      "Cilazapril [USAN:INN:BAN:JAN]",
      "(1S,9S)-9-(((S)-1-Carboxy-3-Phenylpropyl)Amino)Octahydro-10-Oxo-6H-Pyridazino(1,2-A)(1,2)Diazepine-1-Carboxylic Acid 9-Ethyl Estermonohydrate",
      "(4S,7S)-7-[[(2S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Yl]amino]-6-Oxo-1,2,3,4,7,8,9,10-Octahydropyridazino[1,2-A]diazepine-4-Carboxylic Acid",
      "(4S,7S)-7-[[(2S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Yl]amino]-6-Oxo-1,2,3,4,7,8,9,10-Octahydropyridazino[1,2-A]diazepine-4-Carboxylic Acid Hydrate",
    ],
  },
  {
    primary_id: "D04GKP",
    names: ["AS-902330"],
  },
  {
    primary_id: "D04GLJ",
    names: ["OCFENTANIL"],
  },
  {
    primary_id: "D04GMP",
    names: ["N-Quinolin-Benzene Sulphonamide Derivative 3"],
  },
  {
    primary_id: "D04GMY",
    names: ["TD-5108"],
  },
  {
    primary_id: "D04GNG",
    names: ["CC-90011"],
  },
  {
    primary_id: "D04GNX",
    names: ["SHP615"],
  },
  {
    primary_id: "D04GOO",
    names: ["N1,N3-Bis(3-Methoxybenzyl)Isophthalamide", "CHEMBL605201", "SCHEMBL5332068"],
  },
  {
    primary_id: "D04GPC",
    names: ["ISIS 102664"],
  },
  {
    primary_id: "D04GPF",
    names: ["ISIS 6958"],
  },
  {
    primary_id: "D04GPT",
    names: ["N-(3-Acetamidophenyl)-3-Chlorobenzamide"],
  },
  {
    primary_id: "D04GPZ",
    names: ["Hyaluronidase (Ovine)"],
  },
  {
    primary_id: "D04GRB",
    names: ["THRX-198321", "Beta 2 Adrenoreceptor Agonist/Muscarinic Receptor Antagonist (Asthma), Theravance"],
  },
  {
    primary_id: "D04GRS",
    names: [
      "(S)-BULBOCAPNINE",
      "Bulbocapnine",
      "298-45-3",
      "UNII-O0TGI865QO",
      "Bulbokaprin",
      "O0TGI865QO",
      "CHEMBL157912",
      "CHEBI:3211",
      "EINECS 206-061-1",
      "Bulbocapnin",
      "Buibocapnine Hydrochloride",
      "AC1L1ZLR",
      "632-47-3",
      "ZINC103",
      "SCHEMBL678694",
      "6a-Alpha-APORPHIN-11-OL, 10-METHOXY-1,2-(METHYLENEDIOXY)-",
      "AC1Q703Q",
      "DTXSID20183940",
      "BDBM50016018",
      "9126AF",
      "PDSP2_000621",
      "ACM298453",
      "AKOS000276822",
      "C09367",
      "11-Methoxy-7-Methyl-6,7,7a,8-Tetrahydro-5H-Benzo[g][1,3]dioxolo[4'',5'':4,5]benzo[1,2,3-De]quinolin-12-Ol (Bulbocapnine)",
    ],
  },
  {
    primary_id: "D04GSA",
    names: [
      "DAU-6285",
      "Dau 6285",
      "CHEMBL552854",
      "(8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl) 6-Methoxy-2-Oxo-3H-Benzimidazole-1-Carboxylate",
      "AC1L2WHB",
      "GTPL246",
      "SCHEMBL2945410",
      "CHEMBL1194556",
      "PDSP1_000035",
      "BDBM50007863",
      "PDSP2_000035",
      "PDSP1_001680",
      "PDSP2_001663",
      "L000897",
      "6-Methoxy-2-Oxo-2,3-Dihydro-Benzoimidazole-1-Carboxylic Acid 8-Methyl-8-Aza-Bicyclo[3.2.1]oct-3-Yl Ester",
    ],
  },
  {
    primary_id: "D04GSL",
    names: ["ImmuCyst"],
  },
  {
    primary_id: "D04GSN",
    names: ["Zaragozic Acid C"],
  },
  {
    primary_id: "D04GSV",
    names: ["TRICIRIBINE"],
  },
  {
    primary_id: "D04GSY",
    names: ["Buserelin Acetate"],
  },
  {
    primary_id: "D04GTA",
    names: ["Pegsunercept"],
  },
  {
    primary_id: "D04GTP",
    names: ["CHF-2819", "CHEMBL539624"],
  },
  {
    primary_id: "D04GTW",
    names: ["ReSX-I"],
  },
  {
    primary_id: "D04GVN",
    names: ["PF-807925"],
  },
  {
    primary_id: "D04GVX",
    names: [
      "6-Bromo-4,9-Dihydro-3H-Beta-Carboline",
      "CHEMBL6626",
      "BDBM50136508",
      "6-Bromo-3,4-Dihydro-Beta-Carboline",
    ],
  },
  {
    primary_id: "D04GVY",
    names: ["PMID26394986-Compound-12"],
  },
  {
    primary_id: "D04GWD",
    names: ["[3H]JNJ-40068782"],
  },
  {
    primary_id: "D04GWR",
    names: ["FR117016"],
  },
  {
    primary_id: "D04GWY",
    names: ["Telinavir"],
  },
  {
    primary_id: "D04GXL",
    names: ["Sul-Pro-Phe-N-MeHis-LVA-Ile-Amp-(O)"],
  },
  {
    primary_id: "D04GXS",
    names: ["BL-1893", "Chemotherapy (Crystalip Formulation), Bioglan"],
  },
  {
    primary_id: "D04GXW",
    names: [
      "5-Iodo-2'-Deoxyuridine-5'-Monophosphate",
      "AC1L52UF",
      "[(2R,3S)-3-Hydroxy-5-(5-Iodo-2,4-Dioxopyrimidin-1-Yl)Oxolan-2-Yl]methyl Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D04GYD",
    names: ["2-Benzhydryl-7-Hydroxy-Chromen-4-One", "CHEMBL292697", "2-Benzhydryl-7-Hydroxychromone"],
  },
  {
    primary_id: "D04GYO",
    names: ["Oxiconazole"],
  },
  {
    primary_id: "D04GYR",
    names: ["PMID28870136-Compound-61"],
  },
  {
    primary_id: "D04GYT",
    names: ["XR-9456"],
  },
  {
    primary_id: "D04GZA",
    names: [
      "Gentian Violet",
      "Crystal Violet",
      "548-62-9",
      "Basic Violet 3",
      "Aniline Violet",
      "Hexamethylpararosaniline Chloride",
      "Methylrosaniline Chloride",
      "Hexamethyl Violet",
      "Gentiaverm",
      "Pyoktanin",
      "Vianin",
      "Atmonil",
      "Viocid",
      "Avermin",
      "Adergon",
      "Axuris",
      "Badil",
      "Bismuth Violet",
      "Methyl Violet 10B",
      "Basic Violet BN",
      "Methylrosanilinium Chloride",
      "Gentioletten",
      "Gentersal",
      "Oxycolor",
      "Genticid",
      "Vermicid",
      "Oxiuran",
      "Oxyozyl",
      "Calcozine Violet C",
      "Meroxyl-Wander",
      "Meroxylan-Wander",
      "Brilliant Violet 5B",
      "Hecto Violet R",
      "Calcozine Violet 6BN",
      "Violet CP",
      "Crystal Violet O",
      "Paper Blue R",
    ],
  },
  {
    primary_id: "D04GZD",
    names: ["4-(4-Phenoxybenzoyl)Phenylacetic Acid", "CHEMBL370016"],
  },
  {
    primary_id: "D04GZS",
    names: ["Tempol"],
  },
  {
    primary_id: "D04HAA",
    names: ["WL-276"],
  },
  {
    primary_id: "D04HAF",
    names: ["LY-2624803"],
  },
  {
    primary_id: "D04HBH",
    names: ["AM-3701", "Cathepsin K Inhibitors (Oral, Osteoporosis/Bone Metastasis), Amura"],
  },
  {
    primary_id: "D04HBJ",
    names: ["IGFBP 2 Variants"],
  },
  {
    primary_id: "D04HBL",
    names: ["PMID30107136-Compound-Example59"],
  },
  {
    primary_id: "D04HCS",
    names: ["PMID25666693-Compound-93"],
  },
  {
    primary_id: "D04HDO",
    names: [
      "3-Phenethyl-2-Phenyl-3H-Quinazolin-4-One",
      "CHEMBL180391",
      "AC1LJAK1",
      "Oprea1_440152",
      "SCHEMBL5777984",
      "MolPort-002-576-141",
      "KANHEDFPOIQKTH-UHFFFAOYSA-N",
      "ZINC546443",
      "BDBM50162540",
      "STL361550",
      "3-Phenethyl-2-Phenylquinazolin-4-One",
      "AKOS003179499",
      "MCULE-6975064033",
      "3-Phenethyl-2-Phenylquinazoline-4(3H)-One",
      "2-Phenyl-3-(2-Phenylethyl)Quinazolin-4(3H)-One",
    ],
  },
  {
    primary_id: "D04HDZ",
    names: ["GRN163"],
  },
  {
    primary_id: "D04HER",
    names: ["SOM-0999"],
  },
  {
    primary_id: "D04HEW",
    names: ["PMID26815044-Compound-32"],
  },
  {
    primary_id: "D04HFC",
    names: ["[(R)-(+)-Deoxytylophorinidine", "CHEMBL250474", "BDBM50213932"],
  },
  {
    primary_id: "D04HGN",
    names: ["HTS-00213"],
  },
  {
    primary_id: "D04HHR",
    names: [
      "BMY-27709",
      "AC1L2RSV",
      "AC1Q3LRY",
      "99390-76-8",
      "CHEMBL68649",
      "Brl 20627",
      "SCHEMBL15532024",
      "4-Amino-5-Chloro-2-Hydroxy-N-[(2s,6r,9ar)-6-Methyloctahydro-2h-Quinolizin-2-Yl]benzamide",
      "4-Amino-5-Chloro-2-Hydroxy-N-(Octahydro-6-Methyl-2H-Quinolizin-2yl)Benzamide",
      "N-[(2S,6R,9aR)-6-Methyl-2,3,4,6,7,8,9,9a-Octahydro-1H-Quinolizin-2-Yl]-4-Amino-5-Chloro-2-Hydroxy-Benzamide",
      "Benzamide, 4-Amino-N-[(2S,6R,9aR)-Octahydro-6-Methyl-2H-Quinolizin-2-Yl]-5-Chloro-2-Hydroxy-",
    ],
  },
  {
    primary_id: "D04HHW",
    names: ["FK-011"],
  },
  {
    primary_id: "D04HIS",
    names: ["Varilrix"],
  },
  {
    primary_id: "D04HIZ",
    names: [
      "BO-653",
      "UNII-2VSP9T5LRX",
      "2VSP9T5LRX",
      "157360-23-1",
      "2,3-Dihydro-5-Hydroxy-2,2-Dipentyl-4,6-Di-Tert-Butylbenzofuran",
      "BO 653",
      "SCHEMBL5055886",
      "CHEMBL104186",
      "DTXSID20166253",
      "AGURKSYKTJQPNA-UHFFFAOYSA-N",
      "2,3-Dihydro-5-Hydroxy-4,6-Di-Tert-Butyl-2,2-Dipentylbenzofuran",
      "4,6-Ditert-Butyl-2,2-Dipentyl-3H-Benzofuran-5-Ol",
      "4,6-Di-T-Butyl-5-Hydroxy-2,2-Di-N-Pentyl-2,3-Dihydrobenzofuran",
    ],
  },
  {
    primary_id: "D04HJE",
    names: ["AZ-01, PEGylated Interferon-Beta"],
  },
  {
    primary_id: "D04HLE",
    names: ["Vitaxin"],
  },
  {
    primary_id: "D04HMD",
    names: ["Glyphosate"],
  },
  {
    primary_id: "D04HMK",
    names: ["Hi8 Melanoma Vaccine"],
  },
  {
    primary_id: "D04HMM",
    names: ["XP-20925"],
  },
  {
    primary_id: "D04HMP",
    names: ["PMID29865878-Compound-61"],
  },
  {
    primary_id: "D04HNO",
    names: ["3-{3-[(Aminosulfonyl)Oxy]benzoyl}phenyl Sulfamate", "CHEMBL65204"],
  },
  {
    primary_id: "D04HOT",
    names: ["LFG-316"],
  },
  {
    primary_id: "D04HPP",
    names: ["[D-Gln3,D-Ncy(Isopropyl)7]acyline", "CHEMBL428795"],
  },
  {
    primary_id: "D04HPR",
    names: ["Peptide Analog 30"],
  },
  {
    primary_id: "D04HPX",
    names: ["MCL-515"],
  },
  {
    primary_id: "D04HQA",
    names: ["FIT-06"],
  },
  {
    primary_id: "D04HQC",
    names: ["SB-9200"],
  },
  {
    primary_id: "D04HQH",
    names: ["Dihydrothieno [2,3-E]indazole Derivative 1"],
  },
  {
    primary_id: "D04HQJ",
    names: ["AB-103"],
  },
  {
    primary_id: "D04HQK",
    names: ["N-(5-Sulfamoyl-[1,3,4]thiadiazol-2-Yl)-Benzamide"],
  },
  {
    primary_id: "D04HQL",
    names: ["N-Cyclohexylmethyl-ETAV"],
  },
  {
    primary_id: "D04HQP",
    names: [
      "N,N-Dimethyl-4-(Pyridin-3-Yl)But-3-Yn-1-Amine",
      "CHEMBL150893",
      "BDBM50138494",
      "Dimethyl-(4-Pyridin-3-Yl-But-3-Ynyl)-Amine",
    ],
  },
  {
    primary_id: "D04HQT",
    names: ["Proglumide"],
  },
  {
    primary_id: "D04HRS",
    names: ["4-((4-Hydroxyphenyl)Diazenyl)Benzenesulfonamide", "4-(4'-Hydroxyphenyl)Diazenylbenzenesulfonamide"],
  },
  {
    primary_id: "D04HSH",
    names: ["IMGN289"],
  },
  {
    primary_id: "D04HSS",
    names: ["N-(6-Mercapto-Hexyl)-Benzamide", "CHEMBL112364", "BDBM50223650"],
  },
  {
    primary_id: "D04HTL",
    names: ["ABX-0402"],
  },
  {
    primary_id: "D04HTM",
    names: ["Aminocyclopentenone Compound 1"],
  },
  {
    primary_id: "D04HUC",
    names: ["MRNA-046"],
  },
  {
    primary_id: "D04HVB",
    names: ["Panomifene"],
  },
  {
    primary_id: "D04HVL",
    names: [
      "4-(3,4,5-Trimethoxyphenethyl)Aniline",
      "CHEMBL86928",
      "110997-90-5",
      "Benzenamine, 4-[2-(3,4,5-Trimethoxyphenyl)Ethyl]-",
      "4-[2-(3,4,5-Trimethoxy-Phenyl)-Ethyl]-Phenylamine",
      "4-[2-(3,4,5-Trimethoxyphenyl)Ethyl]Aniline",
      "NSC631359",
      "ACMC-20mdvn",
      "AC1L7PCU",
      "AC1Q56FF",
      "SCHEMBL6483085",
      "CTK0G1952",
      "DTXSID30327095",
      "BPIQFCQFIYIISU-UHFFFAOYSA-N",
      "ZINC1622338",
      "BDBM50322061",
      "4'-Amino-3,4,5-Trimethoxybibenzyl",
      "AKOS030581735",
      "NSC-631359",
      "NCI60_010236",
      "4-[2-(3,5-Trimethoxyphenyl)Ethyl]Aniline",
      "4-[2-(3,4,5-Trimethoxy-Phenyl)Ethyl]-Phenylamine",
    ],
  },
  {
    primary_id: "D04HWF",
    names: ["Talmapimod"],
  },
  {
    primary_id: "D04HWJ",
    names: ["METHOXYUNDECYLPHOSPHINIC ACID"],
  },
  {
    primary_id: "D04HWK",
    names: ["IL-7/CD80-Expressing Allogeneic RCC-26 Tumor Cell Vaccine"],
  },
  {
    primary_id: "D04HXB",
    names: ["Meningitec"],
  },
  {
    primary_id: "D04HXF",
    names: [
      "1-(2-Isopropylphenoxy)-3-Morpholinopropan-2-Ol",
      "CHEMBL1083668",
      "Oprea1_740178",
      "AC1N31P0",
      "1-Morpholin-4-Yl-3-(2-Propan-2-Ylphenoxy)Propan-2-Ol",
      "BDBM50318979",
    ],
  },
  {
    primary_id: "D04HXP",
    names: ["PN-2XXX"],
  },
  {
    primary_id: "D04HXS",
    names: ["REC-15/3079"],
  },
  {
    primary_id: "D04HXU",
    names: ["4,9-Anhydro-Tetrodotoxin", "4,9-Anhydro-TTX", "4,9-Ah-TTX"],
  },
  {
    primary_id: "D04HYP",
    names: ["ABT-594"],
  },
  {
    primary_id: "D04HYR",
    names: ["XN-05"],
  },
  {
    primary_id: "D04HZA",
    names: [
      "2-(2-Bromo-2-Naphthamido)Benzoic Acid",
      "CHEMBL199574",
      "SCHEMBL18120563",
      "BDBM16096",
      "2-[(6-Bromonaphthalene-2-)Amido]benzoic Acid",
      "2-{[(6-Bromonaphthalen-2-Yl)Carbonyl]amino}benzoic Acid",
    ],
  },
  {
    primary_id: "D04HZW",
    names: ["8-Diazonium-Naphthalene-1-Sulfonic Acid Anion", "CHEMBL304774"],
  },
  {
    primary_id: "D04HZX",
    names: ["CCX-832", "ChemR2 Receptor Antagonist (Inflammation), ChemoCentryx/GlaxoSmithKline"],
  },
  {
    primary_id: "D04IAF",
    names: [
      "(S)-FTY720-Phosphate",
      "(S) FTY720 Phosphate",
      "402616-26-6",
      "FTY720 (S)-Phosphate",
      "UNII-92YDM6122J",
      "CHEMBL366208",
      "92YDM6122J",
      "Fingolimod P",
      "Fingolimod Phosphate Ester, S-",
      "Rac FTY720 Phosphate",
      "SCHEMBL4267565",
      "GTPL6996",
      "C19H34NO5P",
      "FTY720-Phosphate, (S)-2",
      "BDBM23165",
      "AOB5357",
      "MolPort-003-847-514",
      "(+)-(S)-Fty 720 Phosphate",
      "ZINC14163159",
      "1093AH",
      "HY-15382",
      "AS-16872",
      "FT-0668884",
      "CS-0006041",
      "[(2S)-2-Amino-3-Hydroxy-2-[2-(4-Octylphenyl)Ethyl]propoxy]phosphonic Acid",
    ],
  },
  {
    primary_id: "D04IAJ",
    names: ["MVA62B"],
  },
  {
    primary_id: "D04IBA",
    names: ["Ellipravin"],
  },
  {
    primary_id: "D04IBG",
    names: ["Infanrix-DTaP-IPV-Hep B"],
  },
  {
    primary_id: "D04IBH",
    names: ["3-(5-Bromo-6-Methoxy-Naphthalen-2-Yl)-Pyridine"],
  },
  {
    primary_id: "D04IBI",
    names: ["ABX-301"],
  },
  {
    primary_id: "D04IBO",
    names: ["ISIS 29161"],
  },
  {
    primary_id: "D04ICH",
    names: ["N-Alkyl Gly-Boro-Pro Derivative"],
  },
  {
    primary_id: "D04ICJ",
    names: [
      "4-Benzhydryl-N-Cyclohexylpiperazine-1-Carboxamide",
      "CHEMBL1086903",
      "MLS000532882",
      "AC1LQXAP",
      "CBMicro_028883",
      "Oprea1_410069",
      "Oprea1_118265",
      "MolPort-001-490-660",
      "HMS2484B06",
      "STK413461",
      "ZINC13468414",
      "BDBM50312579",
      "AKOS003253125",
      "MCULE-2070823146",
      "SMR000140320",
      "BIM-0028795.P001",
      "ST50914634",
      "N-Cyclohexyl-4-(Diphenylmethyl)Piperazine-1-Carboxamide",
      "N-Cyclohexyl-4-(Diphenylmethyl)-1-Piperazinecarboxamide",
    ],
  },
  {
    primary_id: "D04ICN",
    names: ["DuP-630"],
  },
  {
    primary_id: "D04ICR",
    names: ["Second-Generation Leptin"],
  },
  {
    primary_id: "D04ICS",
    names: ["Psychosine"],
  },
  {
    primary_id: "D04IDT",
    names: ["Helicobacter Vaccine"],
  },
  {
    primary_id: "D04IDW",
    names: ["SNS-314 Prodrugs"],
  },
  {
    primary_id: "D04IEK",
    names: ["Receptor-Fc Fusion Proteins"],
  },
  {
    primary_id: "D04IEM",
    names: ["Nartograstim"],
  },
  {
    primary_id: "D04IEW",
    names: ["GINKGOLIDE A"],
  },
  {
    primary_id: "D04IFD",
    names: ["GR-ARA1"],
  },
  {
    primary_id: "D04IFL",
    names: ["Isoflavone Derivative 7"],
  },
  {
    primary_id: "D04IFU",
    names: ["GSK835726"],
  },
  {
    primary_id: "D04IFV",
    names: [
      "5-Methyl-2-P-Tolyl-Thiazol-4-Ol",
      "CHEMBL69094",
      "4-Thiazolol, 5-Methyl-2-(4-Methylphenyl)-",
      "SCHEMBL9629237",
      "BDBM50012393",
      "131786-76-0",
    ],
  },
  {
    primary_id: "D04IGE",
    names: ["PMID27841045-Compound-130"],
  },
  {
    primary_id: "D04IGZ",
    names: ["Pyrrolidine Derivative 4"],
  },
  {
    primary_id: "D04IHB",
    names: ["RUTI"],
  },
  {
    primary_id: "D04IHL",
    names: ["Tyr-Pro-D-(NMe)Phe-D-Pro-NH2"],
  },
  {
    primary_id: "D04IIB",
    names: ["H-Dmt-Tic-Asp-NH-Bzl", "CHEMBL450566"],
  },
  {
    primary_id: "D04IIK",
    names: ["(R)-(-)-N-Ethyl-2-Methoxy-11-Hydroxynoraporphine"],
  },
  {
    primary_id: "D04IIT",
    names: ["ZM 325802"],
  },
  {
    primary_id: "D04IIU",
    names: ["INDISULAM"],
  },
  {
    primary_id: "D04IJB",
    names: ["[125I]resiniferatoxin"],
  },
  {
    primary_id: "D04IJE",
    names: ["4-Fluoren-9-Ylidenemethyl-Pyridine"],
  },
  {
    primary_id: "D04IJZ",
    names: ["Microplasmin"],
  },
  {
    primary_id: "D04IKF",
    names: ["18F-LMI-1195"],
  },
  {
    primary_id: "D04IKS",
    names: ["7-(3-Methoxyphenyl)-2-Methyl-1,6-Naphthyridine", "CHEMBL238139"],
  },
  {
    primary_id: "D04ILM",
    names: ["Vinyl Sulfone Derivative 1"],
  },
  {
    primary_id: "D04ILS",
    names: ["2-(3-Chlorobenzyloxy)-6-Chloroisonicotinonitrile", "CHEMBL202908", "SCHEMBL4292772", "BDBM50181075"],
  },
  {
    primary_id: "D04ILU",
    names: ["CA-327"],
  },
  {
    primary_id: "D04IME",
    names: ["SCH-48461", "(-)-(3R,4S)-1,4-Bis(4-Methoxyphenyl)-3-(3-Phenylpropyl)Azetidin-2-One", "148260-92-8"],
  },
  {
    primary_id: "D04IMF",
    names: ["Lipoic"],
  },
  {
    primary_id: "D04IMY",
    names: ["Leucettine L41"],
  },
  {
    primary_id: "D04INP",
    names: ["Pixykine"],
  },
  {
    primary_id: "D04INS",
    names: ["Long-Acting Exenatide"],
  },
  {
    primary_id: "D04IPX",
    names: [
      "WEHI-0103122",
      "BH3 Mimetics (Cancer), WEHI",
      "Bcl-XL Inhibitors (Cancer)",
      "Bcl-XL Inhibitors (Cancer), WEHI/ Genentech",
    ],
  },
  {
    primary_id: "D04IQH",
    names: ["PF-655"],
  },
  {
    primary_id: "D04IRR",
    names: ["Ly231514 Tetra Glu"],
  },
  {
    primary_id: "D04ISG",
    names: ["3-(1-Chloro-7-Methoxy-Naphthalen-2-Yl)-Pyridine"],
  },
  {
    primary_id: "D04ITO",
    names: ["Rifaximin"],
  },
  {
    primary_id: "D04IUB",
    names: ["NT0202", "Amphetamine Polistirex"],
  },
  {
    primary_id: "D04IUQ",
    names: ["SonoRx"],
  },
  {
    primary_id: "D04IUW",
    names: ["BI-32169", "CHEMBL526383"],
  },
  {
    primary_id: "D04IUY",
    names: [
      "APC-001",
      "APC-002",
      "Cell Cycle Inhibitors (Cancer)",
      "Cell Cycle Inhibitors (Cancer), Shanghai Ambrosia Pharmaceutical",
    ],
  },
  {
    primary_id: "D04IWB",
    names: ["2-ThioUTP", "2-Thio-UTP"],
  },
  {
    primary_id: "D04IWT",
    names: ["Fat Emulsion"],
  },
  {
    primary_id: "D04IWZ",
    names: ["PT2.0", "Antibacterial (SASPject, Oral, Clostridium Difficile) Phico"],
  },
  {
    primary_id: "D04IXD",
    names: ["IPH-2101"],
  },
  {
    primary_id: "D04IXJ",
    names: ["Bimetopyrole"],
  },
  {
    primary_id: "D04IXL",
    names: ["HCV Vaccine"],
  },
  {
    primary_id: "D04IXN",
    names: [
      "4-Chlorophenyl 10H-Phenothiazine-10-Carboxylate",
      "CHEMBL481530",
      "SCHEMBL4442850",
      "ZINC27529769",
      "BDBM50292625",
      "AKOS002279099",
      "4-Chlorophenyl Phenothiazine-10-Carboxylate",
    ],
  },
  {
    primary_id: "D04IZU",
    names: ["4-(2-((Dimethylamino)Methyl)Phenoxy)Benzonitrile", "CHEMBL481941"],
  },
  {
    primary_id: "D04JAB",
    names: ["Tcelna"],
  },
  {
    primary_id: "D04JAQ",
    names: ["FH-AAV8"],
  },
  {
    primary_id: "D04JAT",
    names: ["PF-4427429"],
  },
  {
    primary_id: "D04JAX",
    names: ["6-Methyl-4-(4-O-Tolylpiperazin-1-Yl)Coumarin", "CHEMBL509670"],
  },
  {
    primary_id: "D04JCD",
    names: ["Six-Membered Heterocyclic Benzamide Derivative 6"],
  },
  {
    primary_id: "D04JCF",
    names: ["Active Specific Immunotherapy"],
  },
  {
    primary_id: "D04JCJ",
    names: [
      "6-(4-(2-(Benzylamino)Ethyl)Phenoxy)Picolinamide",
      "CHEMBL235560",
      "SCHEMBL3702023",
      "JKDWSAGAQLISRL-UHFFFAOYSA-N",
      "BDBM50219927",
    ],
  },
  {
    primary_id: "D04JCK",
    names: ["Etorphine"],
  },
  {
    primary_id: "D04JCN",
    names: ["Pergolide"],
  },
  {
    primary_id: "D04JCT",
    names: ["AA-204", "BMP Agonist (Chronic Kidney Disease), Thrasos Therapeutics"],
  },
  {
    primary_id: "D04JCW",
    names: ["Isosorbide-2-Benzylcarbamate-5-Pentanoate", "CHEMBL521666"],
  },
  {
    primary_id: "D04JDQ",
    names: ["6-Furan-2-Yl-9-Phenethyl-9H-Purin-2-Ylamine", "CHEMBL194383", "SCHEMBL3272682"],
  },
  {
    primary_id: "D04JEE",
    names: [
      "Propranolol",
      "Anaprilin",
      "Anapriline",
      "Avlocardyl",
      "Bedranol",
      "Berkolol",
      "Betachron",
      "Betadren",
      "Betalong",
      "Caridolol",
      "Corpendol",
      "Dociton",
      "Efektolol",
      "Etalong",
      "Euprovasin",
      "Herzbase",
      "Ikopal",
      "Inderal",
      "Inderalici",
      "Inderex",
      "Inderol",
      "Intermigran",
      "Kemi",
      "Migrastat",
      "Naprilin",
      "Obsidan",
      "Obzidan",
      "Pranolol",
      "Pronovan",
      "Propanalol",
      "Propanix",
      "Propanolol",
      "Propranalol",
      "Propranololo",
      "Propranololum",
      "Proprasylyt",
      "Reducor",
      "Sawatal",
      "Servanolol",
      "Sumial",
      "Beta Neg",
      "Inderal Hydrochloride",
      "Kemi S",
      "Propranolol Hcl Intensol",
      "Propranololo [DCIT]",
      "Racemic Propranolol",
      "Reducorline",
      "AY 64043",
      "AY-20694",
      "Anaprilinum (TN)",
      "Avlocardyl (TN)",
      "Beta-Propranolol",
      "Beta-Tablinen",
      "Beta-Timelets",
      "DL-Propranolol Hydrochloride",
      "Deralin (TN)",
      "Dociton (TN)",
      "Inderal (TN)",
      "Inderal LA (TN)",
      "Inderalici (TN)",
      "InnoPran XL (TN)",
      "Propanolol [INN-Spanish]",
      "Propranolol (INN)",
      "Propranolol (TN)",
      "Propranolol [INN:BAN]",
      "Propranololum [INN-Latin]",
      "Sumial (TN)",
      "D,L-Propranolol",
      "INDERIDE-40/25",
      "R,S-Propranolol Hydrochloride",
      "(+-)-Propranolol",
      "(1)-1-(Isopropylamino)-3-(Naphthyloxy)Propan-2-Ol",
      "(R)-(+)-Propranolol",
      "(S)-(-)-PROPRANOLOL",
      "1-((1-Methylethyl)Amino)-3-(1-Naphthalenyloxy)-2-Propanol",
      "1-(1-Naphthyloxy)-2-Hydroxy-3-(Isopropylamino)Propane",
      "1-(Isopropylamino)-3-(1-Naphthoxy)-Propan-2-Ol",
      "1-(Isopropylamino)-3-(1-Naphthyloxy)-2-Propanol",
      "1-(Isopropylamino)-3-(1-Naphthyloxy)Propan-2-Ol",
      "1-(Naphthalen-1-Yloxy)-3-(Propan-2-Ylamino)Propan-2-Ol",
      "1-Isopropylamino-3-(1-Naphthyloxy)-2-Propanol",
      "1-[(1-Methylethyl)Amino]-3-(Naphthalen-1-Yloxy)Propan-2-Ol",
      "1-Naphthalen-1-Yloxy-3-(Propan-2-Ylamino)Propan-2-Ol",
      "2-Propanol, 1-((1-Methylethyl)Amino)-3-(1-Naphthalenyloxy)-(9CI)",
      "2-Propanol, 1-((1-Methylethyl)Amino)-3-(1-Naphthalenyloxy)-, (+-)-(9CI)",
      "3-(Naphthalen-1-Yloxy)-1-(Propan-2-Ylamino)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D04JEH",
    names: ["Aryl Oxyanilide Derivative 1"],
  },
  {
    primary_id: "D04JFB",
    names: ["RX-20001"],
  },
  {
    primary_id: "D04JFL",
    names: [
      "Naltrexone-6-Alpha-Ol",
      "CHEMBL468469",
      "20410-98-4",
      "SCHEMBL679581",
      "ZINC1656961",
      "BDBM50254511",
      "FT-0672601",
      "17-(Cyclopropylmethyl)-4,5",
      "A-Epoxy-Morphinan-3,6",
      "A,14-Triol",
      "A)-17-(Cyclopropylmethyl)-4,5-Epoxy-Morphinan-3,6,14-Triol",
      "6alpha-Naltrexol Solution, 1.0 Mg/ML In Methanol, Ampule Of 1 ML, Certified Reference Material",
    ],
  },
  {
    primary_id: "D04JFT",
    names: ["KW-5139"],
  },
  {
    primary_id: "D04JGA",
    names: ["BMS-191095", "BMS-199080"],
  },
  {
    primary_id: "D04JGC",
    names: ["(R,R)-(-)-Fenoterol"],
  },
  {
    primary_id: "D04JGZ",
    names: ["2-Morpholin-4-Yl-8-Styrylchromen-4-One", "CHEMBL198476", "SCHEMBL6357331"],
  },
  {
    primary_id: "D04JHH",
    names: ["CAR-T Cells Targeting CD19"],
  },
  {
    primary_id: "D04JHN",
    names: ["Hydromorphone"],
  },
  {
    primary_id: "D04JIE",
    names: ["SER-201"],
  },
  {
    primary_id: "D04JIH",
    names: ["PMID27376512-Compound-Table1Example11"],
  },
  {
    primary_id: "D04JIM",
    names: [
      "UCB-35440",
      "5-[4-(N-Carbamoyl-N-Hydroxyamino)-1-Butynyl]-2-[2-[4-[1(R)-(4-Chlorophenyl)-1-Phenylmethyl]piperazin-1-Yl]ethoxy]benzamide",
    ],
  },
  {
    primary_id: "D04JIN",
    names: ["(2-Bromophenyl)Difluoromethanesulfonamide", "CHEMBL199493"],
  },
  {
    primary_id: "D04JIR",
    names: ["9-Methyl-9H-Adenine", "9-Methyl-9H-Purin-6-Ylamine"],
  },
  {
    primary_id: "D04JIS",
    names: ["OG-Cx-833"],
  },
  {
    primary_id: "D04JJE",
    names: ["RP-66153"],
  },
  {
    primary_id: "D04JJL",
    names: [
      "N,N'-Bis-(4-Ethyl-Phenyl)-Guanidine",
      "CHEMBL78083",
      "Guanidine, N,N'-Bis(4-Ethylphenyl)-",
      "128413-47-8",
      "ACMC-20msu7",
      "SCHEMBL6155127",
      "SCHEMBL10825147",
      "CTK0C1708",
      "DTXSID70563854",
      "1,3-Bis(4-Ethylphenyl)Guanidine",
      "N,N''-Bis(4-Ethylphenyl)Guanidine",
      "BDBM50010736",
      "ZINC13733770",
      "AKOS030602502",
    ],
  },
  {
    primary_id: "D04JKE",
    names: ["Promostem"],
  },
  {
    primary_id: "D04JKG",
    names: ["TherAtoH"],
  },
  {
    primary_id: "D04JKL",
    names: ["AIDA"],
  },
  {
    primary_id: "D04JLF",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 8"],
  },
  {
    primary_id: "D04JLW",
    names: [
      "AWD-72-011",
      "AWD-12-187",
      "Anti-Allergic Therapeutics, ASTA Medica",
      "Anti-Allergic Therapeutics, Arzneimittelwerk",
    ],
  },
  {
    primary_id: "D04JMG",
    names: ["CEQ-626"],
  },
  {
    primary_id: "D04JMI",
    names: ["Plasma Derived Human Butyrylcholinesterase"],
  },
  {
    primary_id: "D04JMK",
    names: ["Derivative Of Methotrexate"],
  },
  {
    primary_id: "D04JMQ",
    names: ["Flurithromycin Ethylsuccinate"],
  },
  {
    primary_id: "D04JNC",
    names: ["ZL-N-91"],
  },
  {
    primary_id: "D04JNI",
    names: ["GSK2245840"],
  },
  {
    primary_id: "D04JNR",
    names: ["SIB-1765F"],
  },
  {
    primary_id: "D04JNZ",
    names: ["PROSTRATIN"],
  },
  {
    primary_id: "D04JOE",
    names: ["Placulumab", "ART-621/CEP-37247/DOM0200"],
  },
  {
    primary_id: "D04JOG",
    names: ["TZD18"],
  },
  {
    primary_id: "D04JOV",
    names: ["MT-Immucillin-H"],
  },
  {
    primary_id: "D04JPG",
    names: [
      "PF-04447943",
      "1082744-20-4",
      "UNII-7N969W8Y4O",
      "CHEMBL2179105",
      "7N969W8Y4O",
      "6-[(3s,4s)-4-Methyl-1-(Pyrimidin-2-Ylmethyl)Pyrrolidin-3-Yl]-1-(Tetrahydro-2h-Pyran-4-Yl)-1,5-Dihydro-4h-Pyrazolo[3,4-D]pyrimidin-4-One",
      "4H-Pyrazolo[3,4-D]pyrimidin-4-One, 1,5-Dihydro-6-[(3S,4S)-4-Methyl-1-(2-Pyrimidinylmethyl)-3-Pyrrolidinyl]-1-(Tetrahydro-2H-Pyran-4-Yl)-",
      "6-((3S,4S)-4-Methyl-1-(Pyrimidin-2-Ylmethyl)Pyrrolidin-3-Yl)-1-(Tetrahydro-2H-Pyran-4-Yl)-1,5-Dihydro-4H-Pyrazolo(3,4-D)Pyrimidin-4-One",
      "4H-Pyrazolo(3,4-D)Pyrimidin-4-One,",
    ],
  },
  {
    primary_id: "D04JPJ",
    names: [
      "Lomustine",
      "Belustine",
      "CCNU",
      "CINU",
      "Cecenu",
      "CeeNU",
      "Chloroethylcyclohexylnitrosourea",
      "Lomustina",
      "Lomustinum",
      "Bristol Myers Squibb Brand Of Lomustine",
      "CCNU [Chloroethyl Nitrosoureas]",
      "Cyclohexyl Chloroethyl Nitrosourea",
      "Lomustine Medac Brand",
      "Medac Brand Of Lomustine",
      "Rhone Poulenc Rorer Brand Of Lomustine",
      "OR5087",
      "RB 1509",
      "SRI 2200",
      "Bristol-Myers Squibb Brand Of Lomustine",
      "CeeNU (TN)",
      "Lomustina [INN-Spanish]",
      "Lomustinum [INN-Latin]",
      "NPFAPI-06",
      "Rhone-Poulenc Rorer Brand Of Lomustine",
      "CeeNU, CCNU, Lomustine",
      "Lomustine (USAN/INN)",
      "Lomustine [USAN:BAN:INN]",
      "N-(2-Chloroethyl)-N'-Cyclohexyl-N-Nitrosourea",
      "(Chloro-2-Ethyl)-1-Cyclohexyl-3-Nitrosourea",
      "(Cloro-2-Etil)-1-Cicloesil-3-Nitrosourea",
      "(Cloro-2-Etil)-1-Cicloesil-3-Nitrosourea [Italian];1-(2-Chloroethyl)-3-Cyclohexyl-1-Nitrosourea",
      "1-(2-Chloroethyl)-3-Cyclohexyl-1-Nitrosourea [Chloroethyl Nitrosoureas]",
      "1-(2-Chloroethyl)-3-Cyclohexylnitrosourea",
    ],
  },
  {
    primary_id: "D04JPQ",
    names: ["PSN-101"],
  },
  {
    primary_id: "D04JQR",
    names: ["LY-367385"],
  },
  {
    primary_id: "D04JQT",
    names: ["Goat Polyclonal Antibodies", "Antibodies", "Goat Polyclonal Antibodies (Autoimmune Disorders)"],
  },
  {
    primary_id: "D04JRK",
    names: ["P-1104"],
  },
  {
    primary_id: "D04JSE",
    names: [
      "MK-7725",
      "BRS-3 Agonists (Obesity)",
      "BRS-3 Agonists (Obesity), Merck",
      "Bombesin Receptor Subtype-3 Agonists (Obesity), Merck",
    ],
  },
  {
    primary_id: "D04JSI",
    names: ["CAR-T Cells Targeting CD133"],
  },
  {
    primary_id: "D04JSS",
    names: ["MK-771", "66537-55-1"],
  },
  {
    primary_id: "D04JTD",
    names: ["PX-12"],
  },
  {
    primary_id: "D04JTK",
    names: [
      "(6-Benzylamino-9H-Beta-Carbolin-3-Yl)-Methanol",
      "[6-(Benzylamino)-9H-Pyrido[3,4-B]indol-3-Yl]methanol",
      "NSC629814",
      "AC1NTT2B",
      "CHEMBL166270",
      "CHEMBL2008860",
      "NCI60_009695",
      "(6-(Benzylamino)-9H-Beta-Carbolin-3-Yl)Methanol Hydrochloride",
    ],
  },
  {
    primary_id: "D04JTX",
    names: ["BI-224436"],
  },
  {
    primary_id: "D04JTY",
    names: [
      "Thiolactomycin",
      "Antibiotic 2-200",
      "82079-32-1",
      "(R)-(+)-Thiolactomycin",
      "(+)-Thiolactomycin",
      "BRN 4423670",
      "4-HYDROXY-3,5-DIMETHYL-5-(2-METHYL-BUTA-1,3-DIENYL)-5H-THIOPHEN-2-ONE",
      "2(5H)-Thiophenone, 3,5-Dimethyl-4-Hydroxy-5-(2-Methyl-1,3-Butadienyl)-, (R-(E))-",
      "(R-(E))-3,5-Dimethyl-4-Hydroxy-5-(2-Methyl-1,3-Butadienyl)-2(5H)-Thiophenone",
      "TLM",
      "2(5H)-Thiophenone, 4-Hydroxy-3,5-Dimethyl-5-(2-Methyl-1,3-Butadienyl)-, (S-(E))-",
      "(2R)-5-Hydroxy-2,4-Dimethyl-2-[(1E)-2-Methylbuta-1,3-Dienyl]thiophen-3-One",
      "(5R)-Thiolactomycin",
      "2vb8",
    ],
  },
  {
    primary_id: "D04JUD",
    names: ["PMID25991433-Compound-A6"],
  },
  {
    primary_id: "D04JUH",
    names: ["NSC-73613"],
  },
  {
    primary_id: "D04JUJ",
    names: [
      "1-Methyl-1,2,3,4-Tetrahydro-Isoquinoline-6,7-Diol",
      "1-Methyl-1,2,3,4-Tetrahydroisoquinoline-6,7-Diol",
      "525-72-4",
      "(+/-) Salsolinol",
      "CHEMBL416732",
      "1-Methyl-6,7-Dihydroxytetrahydroisoquinoline",
      "CHEBI:88801",
      "(R)-Salsolinol",
      "IBRKLUSXDYATLG-UHFFFAOYSA-N",
      "6,7-Isoquinolinediol, 1,2,3,4-Tetrahydro-1-Methyl-",
      "1-Methyl-6,7-Dihydroxy-1,2,3,4-Tetrahydroisoquinoline",
      "Sasolinol",
      "(+)-Salsolinol",
      "(R)-(+)-Salsolinol",
      "(+)-(R)-Salsolinol",
      "AC1L1H1L",
      "Oprea1_470083",
      "SCHEMBL1627427",
      "CTK4J6115",
      "1,2,3,4-TETRAHYDRO-1-METHYL-6,7-ISOQUINOLINEDIOL",
    ],
  },
  {
    primary_id: "D04JVA",
    names: ["1,1,1-Trifluoro-3-(Hexylsulfonyl)Propan-2-One", "CHEMBL155621"],
  },
  {
    primary_id: "D04JVU",
    names: ["Long-Acting IFN-Alpha"],
  },
  {
    primary_id: "D04JVX",
    names: ["AKP-320", "Bis(Ethylmaltolato)Oxovanadium (IV) + Rosiglitazone (Type 2 Diabetes), Akesis"],
  },
  {
    primary_id: "D04JVY",
    names: ["PMID25991433-Compound-J6"],
  },
  {
    primary_id: "D04JWR",
    names: ["PMID29671355-Compound-36"],
  },
  {
    primary_id: "D04JWS",
    names: ["NSC-292213"],
  },
  {
    primary_id: "D04JWW",
    names: ["1-(4-Iodooxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL462302", "SCHEMBL2162540"],
  },
  {
    primary_id: "D04JXA",
    names: ["W54011"],
  },
  {
    primary_id: "D04JXX",
    names: ["D[Pen1,Tyr(Me)2]AVP", "1-Deaminopenicillamine-2-(O-Methyl-Tyr)-Argipressin", "DPTyr(Me)AVP"],
  },
  {
    primary_id: "D04JYI",
    names: ["1-Benzothiazol-2-Yl-3-Phenethyl-Thiourea"],
  },
  {
    primary_id: "D04JYK",
    names: ["GC-1107"],
  },
  {
    primary_id: "D04JYW",
    names: [
      "(+/-)-Oxiran-2-Ylmethyl (9Z)-Hexadec-9-Enoate",
      "CHEMBL230968",
      "213738-77-3",
      "SCHEMBL4979121",
      "BDBM50220357",
      "UCM710, &gt",
      "Oxiran-2-Ylmethyl (9z)-Hexadec-9-Enoate",
    ],
  },
  {
    primary_id: "D04JZD",
    names: ["Polyvalent Crotalid Antivenin Ovine Fab"],
  },
  {
    primary_id: "D04JZE",
    names: [
      "CAMBINOL",
      "Cambinol",
      "14513-15-6",
      "SIRT1/2 Inhibitor IV, Cambinol",
      "NSC112546",
      "NSC-112546",
      "NSC-1125476",
      "5-[(2-Hydroxy-1-Naphthyl)Methyl]-2-Mercapto-6-Phenyl-4(3H)-Pyrimidinone",
      "5-(2-Hydroxynaphthalen-1-Ylmethyl)-6-Phenyl-2-Thioxo-2,3-Dihydro-1H-Pyrimidin-4-One",
      "5-(2-Hydroxy-Naphthalen-1-Ylmethyl)-6-Phenyl-2-Thioxo-2,3-Dihydro-1H-Pyrimidin-4-One",
      "Tetrahydro-5-[(2-Hydroxy-1-Naphthalenyl)Methyl]-6-Phenyl-2-Thioxo-4(1H)-Pyrimidinone",
      "AC1MMYEF",
      "NCIStruc2_001159",
      "NCIStruc1_001428",
      "SCHEMBL2538372",
      "CHEMBL491960",
      "CTK8G3107",
      "BDBM29040",
    ],
  },
  {
    primary_id: "D04JZH",
    names: ["PF-01247324"],
  },
  {
    primary_id: "D04JZJ",
    names: [
      "3-(4-Benzyl-Piperidin-1-Ylmethyl)-Chromen-4-One",
      "CHEMBL426958",
      "AC1MDK2H",
      "Oprea1_222683",
      "MolPort-045-902-159",
      "BDBM50159007",
    ],
  },
  {
    primary_id: "D04JZP",
    names: ["Mazapertine Succinate"],
  },
  {
    primary_id: "D04JZV",
    names: ["(6r,1'r,2's)-5,6,7,8 Tetrahydrobiopterin"],
  },
  {
    primary_id: "D04JZY",
    names: ["Resveratrol Potassium4,-Sulfate"],
  },
  {
    primary_id: "D04KAM",
    names: ["Acetic Acid 2-Hexylsulfanyl-Phenyl Ester", "CHEMBL144218", "ZINC13782080"],
  },
  {
    primary_id: "D04KAQ",
    names: ["Pivampicillin"],
  },
  {
    primary_id: "D04KAS",
    names: ["Ac-Ala-Pro-Val-(2-Benzoxazole)", "CHEMBL438709"],
  },
  {
    primary_id: "D04KAZ",
    names: ["1-Phenyl-3-(Phenylsulfonyl)Propan-1-One", "CHEMBL240292", "ZINC28874843"],
  },
  {
    primary_id: "D04KBL",
    names: ["Bevacizumab", "Bevacizumab (Ophthalmic Slow-Release Tissue Tablet)"],
  },
  {
    primary_id: "D04KBU",
    names: ["AIR-001", "Aironite", "Inhaled Sodium Nitrite (Pulmonary Hypertension), Aires"],
  },
  {
    primary_id: "D04KCT",
    names: ["Org-21465"],
  },
  {
    primary_id: "D04KCX",
    names: ["5-(4-Hydroxyphenyl)-1,3,4-Oxadiazole-2(3H)-Thione"],
  },
  {
    primary_id: "D04KDA",
    names: [
      "A-87049",
      "CHEMBL445618",
      "BDBM50058945",
      "(1S,2S,3S,4S)-3,4-Bis-[(4-Phenoxy-Benzyl)-Propyl-Carbamoyl]-Cyclobutane-1,2-Dicarboxylic Acid",
    ],
  },
  {
    primary_id: "D04KDB",
    names: [
      "6-Imidazol-1-Yl-3,4-Dihydro-1H-Quinolin-2-One",
      "CHEMBL17516",
      "119924-94-6",
      "3,4-Dihydro-6-(1H-Imidazol-1-Yl)Quinolin-2(1H)-One",
      "2(1H)-Quinolinone, 3,4-Dihydro-6-(1H-Imidazol-1-Yl)-",
      "ACMC-20momi",
      "SCHEMBL10463333",
      "CTK0F9353",
      "DTXSID20551867",
      "BDMKPUAZENLRDY-UHFFFAOYSA-N",
      "BDBM50000309",
      "6-(Imidazol-1-Yl)-3,4-Dihydrocarbostyril",
    ],
  },
  {
    primary_id: "D04KDW",
    names: [
      "2,7-Bis[3-(Pyrrolidino)Propionamido]anthraquinone",
      "CHEMBL144757",
      "SCHEMBL4298492",
      "CUZAYOMYKDAXQR-UHFFFAOYSA-N",
      "BDBM50068320",
      "N,N'-(9,10-Dihydro-9,10-Dioxoanthracene-2,7-Diyl)Bis[3-(Pyrrolidine-1-Yl)Propanamide]",
    ],
  },
  {
    primary_id: "D04KEL",
    names: ["EV-086 Oral/Intravenous Formulation"],
  },
  {
    primary_id: "D04KFR",
    names: [
      "NVP-SAA164",
      "Bradykinin B1 Receptor Antagonists (Inflammatory Pain)",
      "Bradykinin B1 Receptor Antagonists (Inflammatory Pain), Novartis",
    ],
  },
  {
    primary_id: "D04KGB",
    names: [
      "Anti-IFN-Alpha Human Mabs",
      "Anti-IFN-Alpha Human MAbs (Autoimmune Disease)",
      "Anti-IFN-Alpha Human MAbs (Autoimmune Disease), Theraclone Sciences",
      "Anti-IFN-Alpha Human Monoclonal Antibodies (Autoimmune Disease), Theraclone Sciences",
      "Anti-Interferon-Alpha Human Monoclonal Antibodies (Autoimmune Disease), Theraclone Sciences",
    ],
  },
  {
    primary_id: "D04KGK",
    names: ["10,11-Dihydro-5H-Dibenzo[b,f]azepine"],
  },
  {
    primary_id: "D04KGP",
    names: ["(R)-(-)-11-Hydroxy-N-N-Propylnoraporphine", "11-Hydroxy-N-Propylnoraporphine"],
  },
  {
    primary_id: "D04KHE",
    names: ["H-Tyr-Pro-Phe-Phe-NH-CH2-CH2-NH Tic Dmt-H", "CHEMBL392469"],
  },
  {
    primary_id: "D04KHH",
    names: ["CEFCANEL DALOXATE HYDROCHLORIDE"],
  },
  {
    primary_id: "D04KHN",
    names: ["Anti Melanoma Oligopeptides"],
  },
  {
    primary_id: "D04KHY",
    names: ["Spiroglumide"],
  },
  {
    primary_id: "D04KIH",
    names: ["Ac-YVAD-FMK"],
  },
  {
    primary_id: "D04KJO",
    names: ["Formoterol"],
  },
  {
    primary_id: "D04KJT",
    names: [
      "ABT-333",
      "Dasabuvir",
      "1132935-63-7",
      "N-(6-(3-(Tert-Butyl)-5-(2,4-Dioxo-3,4-Dihydropyrimidin-1(2H)-Yl)-2-Methoxyphenyl)Naphthalen-2-Yl)Methanesulfonamide",
      "ABT333",
      "UNII-DE54EQW8T1",
      "ABT 333",
      "Dasabuvir (ABT-333)",
      "DE54EQW8T1",
      "CHEBI:85182",
      "N-(6-(3-Tert-Butyl-5-(2,4-Dioxo-3,4-Dihydropyrimidin-1(2H)-Yl)-2-Methoxyphenyl)Naphthalen-2-Yl)Methanesulfonamide",
      "C26H27N3O5S",
      "Dasabuvir [INN]",
      "N-{6-[3-Tert-Butyl-5-(2,4-Dioxo-3,4-Dihydropyrimidin-1(2H)-Yl)-2-Methoxyphenyl]naphthalen-2-Yl}methanesulfonamide",
      "Dasabuvir [USAN:INN]",
      "Dasabuvir (USA",
    ],
  },
  {
    primary_id: "D04KKE",
    names: ["Diaziquone"],
  },
  {
    primary_id: "D04KKT",
    names: [
      "ISO-1",
      "478336-92-4",
      "MIF Antagonist, ISO-1",
      "Methyl 2-(3-(4-Hydroxyphenyl)-4,5-Dihydroisoxazol-5-Yl)Acetate",
      "CHEMBL210858",
      "IN1228",
      "5-Isoxazoleacetic Acid, 4,5-Dihydro-3-(4-Hydroxyphenyl)-, Methyl Ester",
      "Macrophage Migration Inhibitory Factor Antagonist, ISO-1",
      "(S,R)-3-(4-Hydroxyphenyl)-4,5-Dihydro-5-Isoxazole Acetic Acid, Methyl Ester",
      "4,5-DIHYDRO-3-(4-HYDROXYPHENYL)-5-ISOXAZOLEACETIC ACID, METHYL ESTER",
      "AC1O1KG2",
      "SCHEMBL668287",
      "GTPL9984",
      "CTK8E8677",
      "MolPort-027-836-626",
      "BCP15471",
      "EX-A2475",
      "BDBM50186426",
      "6540AB",
    ],
  },
  {
    primary_id: "D04KKX",
    names: ["QX-30002222", "Antibacterial DNA Ligase Inhibitors, Quorex"],
  },
  {
    primary_id: "D04KMO",
    names: [
      "CGP-35348",
      "Cgp 35348",
      "Cgp-35348",
      "123690-79-9",
      "UNII-87TI61875H",
      "P-(3-Aminopropyl)-P-Diethoxymethylphosphinic Acid",
      "CHEMBL40157",
      "3-Aminopropyl(Diethoxymethyl)Phosphinic Acid",
      "Phosphinic Acid, (3-Aminopropyl)(Diethoxymethyl)-",
      "CGP35348",
      "87TI61875H",
      "(S)-(3-Aminopropyl)(Diethoxymethyl)Phosphinic Acid",
      "CGP 35348 Hydrate",
      "(3-Aminopropyl)(Diethoxymethyl)Phosphinic Acid Hydrate",
      "Tocris-1245",
      "Biomol-NT_000237",
      "AC1L32KY",
      "C8H20NO4P",
      "SCHEMBL195698",
      "GTPL1069",
      "BPBio1_000508",
      "DTXSID70154064",
      "QIIVUOWTHWIXFO-UHFFFAOYSA-N",
      "MolPort-003-983-819",
      "PDSP2_000408",
    ],
  },
  {
    primary_id: "D04KMP",
    names: ["SUVN-G3031"],
  },
  {
    primary_id: "D04KMQ",
    names: ["GSK3117391"],
  },
  {
    primary_id: "D04KNL",
    names: ["PG-545"],
  },
  {
    primary_id: "D04KOZ",
    names: [
      "(S)-2-Amino-3-Phenyl-Propane-1-Thiol",
      "CHEMBL34907",
      "2-Amino-3-Phenyl-Propane-1-Thiol",
      "SCHEMBL6805553",
      "BDBM50078129",
    ],
  },
  {
    primary_id: "D04KPW",
    names: ["2-Methoxy-4-(M-Tolyloxy)Benzonitrile", "CHEMBL490957"],
  },
  {
    primary_id: "D04KQB",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 24"],
  },
  {
    primary_id: "D04KQF",
    names: ["Tubacin"],
  },
  {
    primary_id: "D04KQT",
    names: [
      "Dodecyl-Thiophosphate",
      "Thiophosphoric Acid Dodecyl Ester",
      "CHEMBL191365",
      "Phosphorothioic Acid, O-Dodecyl Ester",
      "SCHEMBL40052",
      "GTPL6990",
      "Thiophosphoric Acid O-Dodecyl Ester",
      "BDBM50170860",
    ],
  },
  {
    primary_id: "D04KQU",
    names: ["PMID28092474-Compound-32x"],
  },
  {
    primary_id: "D04KRV",
    names: ["Iofolastat I-124"],
  },
  {
    primary_id: "D04KSU",
    names: ["TENELLONE B"],
  },
  {
    primary_id: "D04KSX",
    names: ["ISIS 9984"],
  },
  {
    primary_id: "D04KTJ",
    names: ["NSC-645834"],
  },
  {
    primary_id: "D04KTZ",
    names: [
      "Cloxacillin",
      "Chloroxacillin",
      "Clossacillina",
      "Cloxacilina",
      "Cloxacilline",
      "Cloxacillinum",
      "MCIPC",
      "Orbenin",
      "Syntarpen",
      "Tegopen",
      "Clossacillina [DCIT]",
      "Cloxacillin Sodium",
      "Methocillin S",
      "BRL 1621",
      "Cloxacilina [INN-Spanish]",
      "Cloxacillin (INN)",
      "Cloxacillin [INN:BAN]",
      "Cloxacilline [INN-French]",
      "Cloxacillinum [INN-Latin]",
      "Cloxapen (TN)",
      "Cloxapen (Sodium Monohydrate)",
      "Methylchlorphenylisoxazoryl-Penicillin",
      "Novo-Cloxin",
      "Nu-Cloxi",
      "Orbenin (TN)",
      "Tegopen (Sodium Monohydrate)",
      "BRL-1621 (Sodium Monohydrate)",
      "P-25 (Sodium Monohydrate)",
      "[3-(O-CHLOROPHENYL)-5-METHYL-4-ISOXAZOLYL]PENICILLIN",
      "(2S,5R,6R)-6-({[3-(2-Chlorophenyl)-5-Methylisoxazol-4-Yl]carbonyl}amino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-[[3-(2-Chlorophenyl)-5-Methyl-1,2-Oxazole-4-Carbonyl]amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(3-(O-Chlorophenyl)-5-Methyl-4-Isoxazolyl)Penicillin",
      "6-(((3-(2-Chlorophenyl)-5-Methyl-4-Isoxazolyl)Carbonyl)Amino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "6-(3-(O-Chlorophenyl)-5-Methyl-4-Isoxazolecarboxamido)Penicillanic Acid",
    ],
  },
  {
    primary_id: "D04KUD",
    names: ["Pyrido[1,2-A]indole-1.-Carboxylic Acid Analog 1"],
  },
  {
    primary_id: "D04KUJ",
    names: ["Purine Derivative 1"],
  },
  {
    primary_id: "D04KUK",
    names: ["Medium Chain Triglycerides"],
  },
  {
    primary_id: "D04KUP",
    names: [
      "6-Bromo-1H-Indazole",
      "79762-54-2",
      "6-Bromoindazole",
      "6-Bromo-2H-Indazole",
      "1H-Indazole, 6-Bromo-",
      "CHEMBL247365",
      "1H-INDAZOLE,6-BROMO-",
      "1788080-23-8",
      "6-Bromo-1H-Indazole, 97%",
      "6- Bromoindazole",
      "PubChem7831",
      "Zlchem 1237",
      "6-BroMo-1H Indazole",
      "6-Bromo-1-H-Indazole",
      "6-Bromanyl-1H-Indazole",
      "ACMC-209pi5",
      "SCHEMBL5902",
      "KSC376S4P",
      "6-Bromo-1H-Indazole, 95%",
      "AMBZ0200",
      "AC1Q251Y",
      "CTK2H6947",
      "ZLE0012",
      "DTXSID80591229",
      "MolPort-029-084-056",
      "WMKDUJVLNZANRN-UHFFFAOYSA-N",
      "MolPort-000-001-819",
      "ZINC8764137",
      "KS-000002SJ",
      "CS-D0757",
      "BCP00386",
      "ACN-S003161",
      "ACT06505",
    ],
  },
  {
    primary_id: "D04KVA",
    names: ["BVI-007"],
  },
  {
    primary_id: "D04KVQ",
    names: [
      "BAY-86-9596",
      "D-FMT",
      "D-18F-Fluoromethyl Tyrosine",
      "D-Fluoromethyl Tyrosine-[18F]",
      "Diagnostic 18F-Labeled PET Tracer, Bayer",
      "18-F PET Tracer (Diagnostic Imaging), Bayer",
      "18F-Labeled PET Imaging Agent (Cancer), Bayer Schering Pharma",
    ],
  },
  {
    primary_id: "D04KWA",
    names: ["Bis(14)-Huperzine B"],
  },
  {
    primary_id: "D04KWH",
    names: [
      "Des-Arg(9)-[Leu(8)]-BK",
      "5-Chloro-4-(N-(Cyclopropyl)Methyl-N-Propylamino)-2-Methyl-6-(2,4,6-Trichlorophenyl)Aminopyridine",
      "NBI 27914",
      "NBI27914",
      "NBI-27914",
      "CHEMBL45281",
      "SR-01000075865",
      "Tocris-1591",
      "Lopac-N-3911",
      "AC1L41YH",
      "Lopac0_000493",
      "MLS000860080",
      "Cid_176157",
      "GTPL3512",
      "SCHEMBL16606734",
      "CHEBI:93069",
      "DTXSID20171549",
      "HMS3261D07",
      "HMS3371C20",
      "HMS2230I15",
      "ZINC2511843",
      "Tox21_500493",
      "BDBM50054245",
      "CCG-204584",
      "API0008466",
      "LP00493",
      "2-Methyl-4-(N-Propyl-N-Cycloproanemethylamino)-5-Chloro-6-(2,4,6-Trichloranilino)Pyrimidine",
      "NCGC00261178-01",
      "NCGC00015737-05",
    ],
  },
  {
    primary_id: "D04KWO",
    names: ["MEDl-551"],
  },
  {
    primary_id: "D04KWY",
    names: [
      "RTI-336",
      "CHEMBL432878",
      "3-(4-Chloro-Phenyl)-8-Methyl-2-(3-P-Tolyl-Isoxazol-5-Yl)-8-Aza-Bicyclo[3.2.1]octane",
    ],
  },
  {
    primary_id: "D04KXF",
    names: ["CEFTRAZONAL BOPENTIL"],
  },
  {
    primary_id: "D04KYB",
    names: [
      "ASCJ-9 Topical",
      "Dimethylcurcumin",
      "ASC-JMX1",
      "ASCJ-9",
      "ASCJ-9 (Systemic), AndroScience",
      "ASCJ-9 (Topical), AndroScience",
      "Androgen Antagonist (Alopecia/Acne), AndroScience",
      "Androgen Receptor Degradation Enhancers (Oral, Spinal Bulbar Muscular Atrophy), AndroScience",
    ],
  },
  {
    primary_id: "D04KYH",
    names: ["PSP-D-BAFF"],
  },
  {
    primary_id: "D04KYY",
    names: [
      "Allopurinol",
      "Adenock",
      "Ailural",
      "Ailurial",
      "Allohexal",
      "Allohexan",
      "Alloprin",
      "Allopur",
      "Allopurin",
      "Allopurinolum",
      "Allorin",
      "Allozym",
      "Allpargin",
      "Allural",
      "Aloprim",
      "Alopurinol",
      "Aloral",
      "Alositol",
      "Aluline",
      "Anoprolin",
      "Anzief",
      "Apulonga",
      "Apurin",
      "Apurol",
      "Atisuril",
      "Bleminol",
      "Bloxanth",
      "Caplenal",
      "Capurate",
      "Cellidrin",
      "Cosuric",
      "Dabrosin",
      "Dabroson",
      "Embarin",
      "Epidropal",
      "Epuric",
      "Foligan",
      "Geapur",
      "Gichtex",
      "Gotax",
      "Hamarin",
      "Hexanuret",
      "Jenapurinol",
      "Ketanrift",
      "Ledopur",
      "Lopurin",
      "Lysuron",
      "Milurit",
      "Milurite",
      "Miniplanor",
      "Monarch",
      "Nektrohan",
      "Novopurol",
      "Progout",
      "Pureduct",
      "Purinol",
      "Remid",
      "Riball",
      "Rimapurinol",
      "Roucol",
      "Sigapurol",
      "Suspendol",
      "Takanarumin",
      "Tipuric",
      "Urbol",
      "Uribenz",
      "Uricemil",
      "Uridocid",
      "Uriprim",
      "Uripurinol",
      "Uritas",
      "Urobenyl",
      "Urolit",
      "Urosin",
      "Urtias",
      "Xanthomax",
      "Xanturat",
      "Xanturic",
      "Zygout",
      "Zyloprim",
      "Zyloric",
      "Dura Al",
      "Pan Quimica",
      "A 8003",
      "BW 56158",
      "Urtias 100",
      "AL-100",
      "Allo-Puren",
      "Allohexal (TN)",
      "Allopurinol(I)",
      "Allosig (TN)",
      "Apo-Allopurinol",
      "BW 56-158",
      "Ketobun-A",
      "Progout (TN)",
      "Puricos (TN)",
      "Quimica, Pan",
      "Zyloprim (TN)",
      "Zyloric (TN)",
      "BW-56-158",
      "B. W. 56-158",
      "4-HPP",
    ],
  },
  {
    primary_id: "D04KZH",
    names: ["FluBiovax"],
  },
  {
    primary_id: "D04KZN",
    names: ["CTP-354"],
  },
  {
    primary_id: "D04KZS",
    names: ["PRO-045"],
  },
  {
    primary_id: "D04KZX",
    names: [
      "2-(Tert-Butylamino)-3',4'-Dichlorobutyrophenone",
      "CHEMBL566208",
      "SCHEMBL634229",
      "BDBM50302914",
      "2-(Tert-Butylamino)-3'',4''-Dichlorobutyrophenone",
    ],
  },
  {
    primary_id: "D04KZY",
    names: ["Gilteritinib"],
  },
  {
    primary_id: "D04LAQ",
    names: [
      "Candoxatrilat",
      "Candoxatrilat < Rec INN",
      "UK-69578",
      "UK-73967",
      "Cis-4-[[[1-[2-Carboxy-3-(2-Methoxyethoxy)Propyl]cyclopentyl]carbonyl]amino]cyclohexanecarboxylic Acid",
      "(+)-Cis-4-[1-[(S)-2-Carboxy-3-(2-Methoxyethoxy)Propyl]cyclopentylcarbonylamino]cyclohexanecarboxylic Acid",
      "3-[1-(4-Cis-Carboxycyclohexylcarbamoyl)Cyclopentyl]-2(R,S)-(2-Methoxyethoxymethyl)Propanoic Acid",
    ],
  },
  {
    primary_id: "D04LBC",
    names: [
      "Iclaprim",
      "192314-93-5",
      "AR-100",
      "5-[(2-Cyclopropyl-7,8-Dimethoxy-2h-Chromen-5-Yl)Methyl]pyrimidine-2,4-Diamine",
      "RO-48-2622",
      "Mersarex",
      "5-((2-Cyclopropyl-7,8-Dimethoxy-2H-Chromen-5-Yl)Methyl)Pyrimidine-2,4-Diamine",
      "Iclaprim [USAN:INN]",
      "Iclaprim (USAN/INN)",
      "2,4-Pyrimidinediamine, 5-((2-Cyclopropyl-7,8-Dimethoxy-2H-1-Benzopyran-5-Yl)Methyl)-",
      "2,4-Pyrimidinediamine, 5-[(2-Cyclopropyl-7,8-Dimethoxy-2H-1-Benzopyran-5-Yl)Methyl]-",
      "AR 100",
      "AC1Q4WM5",
      "SCHEMBL379386",
      "CHEMBL134561",
      "AC1L4U54",
      "SCHEMBL12899446",
      "CTK4E0971",
      "BDBM18070",
      "Iclaprim [INN]",
      "AR-102",
      "AR-100001",
      "5-((2RS)-2-Cyclopropyl-7,8-Dimethoxy-2H-Chromen-5-Ylmethyl)Pyrimidine-2,4-Diamine",
    ],
  },
  {
    primary_id: "D04LBJ",
    names: [
      "ASOBAMAST",
      "Asobamast < Rec INN",
      "Z-1819",
      "N-[4-(3-Methylisoxazol-5-Yl)Thiazol-2-Yl]oxamic Acid 2-Ethoxyethyl Ester",
    ],
  },
  {
    primary_id: "D04LBL",
    names: ["PMID27376512-Compound-MTC-434"],
  },
  {
    primary_id: "D04LBT",
    names: [
      "Antimycin A",
      "Antipiricullin",
      "Fintrol",
      "Virosin",
      "Antimycin A1b",
      "Antimycin A1",
      "Caswell No. 052B",
      "Antimycin-A",
      "CCRIS 924",
      "HSDB 6417",
      "UNII-75G3NMU1TS",
      "EPA Pesticide Chemical Code 006314",
      "75G3NMU1TS",
      "CHEMBL211501",
      "CHEBI:22584",
      "1397-94-0",
      "(2R,3S,6S,7R,8R)-3-[(3-Formamido-2-Hydroxybenzoyl)Amino]-8-Hexyl-2,6-Dimethyl-4,9-Dioxo-1,5-Dioxonan-7-Yl 3-Methylbutanoate",
      "642-15-9",
      "116095-18-2",
      "[(2r,3s,6s,7r,8r)-3-[(3-Formamido-2-Oxidanyl-Phenyl)Carbonylamino]-8-Hexyl-2,6-Dimethyl-4,9-Bis(Oxidanylidene)-1,5-Dioxonan-7-Yl] 3-Methylbutanoate",
    ],
  },
  {
    primary_id: "D04LBV",
    names: ["AZ4800", "AZ 4800", "AZ-4800"],
  },
  {
    primary_id: "D04LCQ",
    names: ["Tafluprost"],
  },
  {
    primary_id: "D04LCX",
    names: ["Vax-SPIRAL"],
  },
  {
    primary_id: "D04LDH",
    names: ["BZ6"],
  },
  {
    primary_id: "D04LDK",
    names: ["TZP-202"],
  },
  {
    primary_id: "D04LDP",
    names: ["5-Deoxy-5-Phosphono-D-Arabinonate"],
  },
  {
    primary_id: "D04LEF",
    names: ["JMV449"],
  },
  {
    primary_id: "D04LEH",
    names: ["LTX-03"],
  },
  {
    primary_id: "D04LEK",
    names: ["GSK729327"],
  },
  {
    primary_id: "D04LFG",
    names: ["CGP-25454A"],
  },
  {
    primary_id: "D04LFK",
    names: ["2-(2-(Pyrrolidin-1-Yl)Ethyl)-1H-Indole", "CHEMBL271899"],
  },
  {
    primary_id: "D04LFR",
    names: ["SAR404460", "DHA-GPL + Vit D"],
  },
  {
    primary_id: "D04LGK",
    names: ["VRT-750018"],
  },
  {
    primary_id: "D04LGV",
    names: ["1-(4-Ethylphenylsulfonyl)-4-Phenylazepan-4-Ol", "CHEMBL562684", "SCHEMBL12549978"],
  },
  {
    primary_id: "D04LHG",
    names: ["SP-SAP"],
  },
  {
    primary_id: "D04LHI",
    names: ["ISIS 113871"],
  },
  {
    primary_id: "D04LHJ",
    names: ["Methscopolamine Bromide"],
  },
  {
    primary_id: "D04LIH",
    names: ["AVI-6002"],
  },
  {
    primary_id: "D04LIR",
    names: ["PD-159913"],
  },
  {
    primary_id: "D04LJB",
    names: ["NP-213"],
  },
  {
    primary_id: "D04LJH",
    names: ["[3H]A-585539", "GTPL3974", "SCHEMBL18833113"],
  },
  {
    primary_id: "D04LKS",
    names: ["Ruxolitinib"],
  },
  {
    primary_id: "D04LLB",
    names: ["ISIS 25078"],
  },
  {
    primary_id: "D04LLD",
    names: ["7-(Biphenyl-4-Yloxy)-Heptanoic Acid Hydroxyamide", "CHEMBL114184", "SCHEMBL3383144"],
  },
  {
    primary_id: "D04LLE",
    names: ["Antihypertensives", "Antihypertensives (Nitroxyl Technology, Pulmonary Arterial Hypertension)"],
  },
  {
    primary_id: "D04LLK",
    names: ["Chloroacetyl Ester Derivative 1"],
  },
  {
    primary_id: "D04LMF",
    names: ["[125I]cetrorelix"],
  },
  {
    primary_id: "D04LMJ",
    names: [
      "MMB-4",
      "Acetocholinesterase Reactivator (Poison Intoxication), SwRI",
      "Dimethanesulfonate (Poison Intoxication), SwRI",
    ],
  },
  {
    primary_id: "D04LMZ",
    names: ["X-22"],
  },
  {
    primary_id: "D04LNI",
    names: [
      "Di(2,6-Dimethylphenol)",
      "2417/4/1",
      "2,2',6,6'-Tetramethyl-4,4'-Biphenol",
      "3,3',5,5'-Tetramethylbiphenyl-4,4'-Diol",
      "2,2',6,6'-Tetramethyl-P,p'-Biphenol",
      "CHEMBL449983",
      "[1,1'-Biphenyl]-4,4'-Diol, 3,3',5,5'-Tetramethyl-",
      "3,3',5,5'-Tetramethyl-4,4'-Diallyloxybiphenyl",
      "3,3,5,5-Tetramehtyl [1,1'-Biphenyl] 4,4'-Diol",
      "4-(4-Hydroxy-3,5-Dimethylphenyl)-2,6-Dimethylphenol",
      "3,3',5,5'-TETRAMETHYL-4,4'-DIHYDROXYBIPHENYL",
      "3,3,5,5-Tetramethylbiphenyl-4,4-Diol",
      "4,4'-Dihydroxy-3,3',5,5'-Tetramethyl-Biphenyl",
    ],
  },
  {
    primary_id: "D04LOR",
    names: ["BMS-919373"],
  },
  {
    primary_id: "D04LPX",
    names: [
      "4-Bromo-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL61836",
      "4-Bromo-N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "AC1LKC2E",
      "Oprea1_799831",
      "SCHEMBL17107249",
      "MolPort-001-934-338",
      "ZINC5775827",
      "BDBM50101783",
      "AKOS000591432",
      "MCULE-9538176345",
      "BAS 00470402",
      "N-(4-Phenyl-2-Thiazolyl)-4-Bromobenzamide",
      "ST50002847",
    ],
  },
  {
    primary_id: "D04LPY",
    names: ["8-(3-Fluorobenzyloxy)Caffeine", "CHEMBL602465"],
  },
  {
    primary_id: "D04LQN",
    names: ["ELAD"],
  },
  {
    primary_id: "D04LRA",
    names: ["Rimantadine Isomer 2"],
  },
  {
    primary_id: "D04LRN",
    names: ["2-Benzyl-6-Methoxy-5-Oxazol-5-Yl-1H-Indole", "CHEMBL281635", "SCHEMBL5831606", "BDBM50126015"],
  },
  {
    primary_id: "D04LRQ",
    names: ["TDI-0059"],
  },
  {
    primary_id: "D04LRT",
    names: ["Quinoline Derivative 9"],
  },
  {
    primary_id: "D04LRU",
    names: ["AHR-16462B"],
  },
  {
    primary_id: "D04LSD",
    names: ["ABT-239", "4-[2-[2-(2-Methylpyrrolidin-1-Yl)Ethyl]benzofuran-5-Yl]benzonitrile"],
  },
  {
    primary_id: "D04LSH",
    names: ["SAR101099"],
  },
  {
    primary_id: "D04LSR",
    names: ["Thio-BCTC"],
  },
  {
    primary_id: "D04LSW",
    names: ["C[Glu21-Lys25][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D04LTF",
    names: [
      "TLN-4601",
      "Diazepinomicin",
      "BU-4664L",
      "733035-26-2",
      "UNII-YPH994Y0RF",
      "ECO-4601",
      "YPH994Y0RF",
      "11H-Dibenzo(B,E)(1,4)Diazepin-11-One, 5,10-Dihydro-4,6,8-Trihydroxy-10-((2E,6E)-3,7,11-Trimethyl-2,6,10-Dodecatrien-1-Yl)-",
      "TLN 4601",
      "ECO 4601",
      "CHEMBL550961",
      "DTXSID40223539",
      "SALVHVNECODMJP-GNUCVDFRSA-N",
      "ZINC3938687",
      "ECO-04601",
      "DB12420",
      "KB-81170",
    ],
  },
  {
    primary_id: "D04LTO",
    names: ["Steamchol"],
  },
  {
    primary_id: "D04LTQ",
    names: ["Buproprion+zonisamide"],
  },
  {
    primary_id: "D04LTV",
    names: [
      "2,3-Dimethylnaphtho[2,3-F]quinoxaline-7,12-Dione",
      "89986-91-4",
      "Naphtho[2,3-F]quinoxaline-7,12-Dione, 2,3-Dimethyl-",
      "ACMC-20lsfz",
      "SCHEMBL3505034",
      "CHEMBL604342",
      "CTK2I7956",
      "DTXSID80658048",
    ],
  },
  {
    primary_id: "D04LUF",
    names: [
      "AcGlu-Dif-Glu-Cha-Cys",
      "CHEMBL431559",
      "AC1LAAI4",
      "Ac-Glu-Dif-Glu-Cha-Cys-OH",
      "BDBM50096407",
      "Ac-L-Glu-3,3-Diphenyl-L-Ala-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "AcGlu-Dif(3,3-Diphenylalanine)-Glu-Cha(Beta-Cychohexylalanine)-Cys",
      "(4S)-4-Acetamido-5-[[(2S)-1-[[(2S)-1-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-5-Hydroxy-1,5-Dioxopentan-2-Yl]amino]-1-Oxo-3,3-Diphenylpropan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D04LUG",
    names: [
      "BSP-C801",
      "BSP-C801 Series (Gram-Negative Bacterial Infections)",
      "Polymyxin B Based Antibiotics (Gram-Negative Bacterial Infections), BioSource Pharm Inc",
      "BSP-C801 Series (Gram-Negative Bacterial Infections), BioSource Pharm Inc",
    ],
  },
  {
    primary_id: "D04LUI",
    names: ["Ribose-1-Phosphate"],
  },
  {
    primary_id: "D04LVK",
    names: [
      "Ceritinib",
      "LDK378",
      "1032900-25-6",
      "ZYKADIA",
      "NVP-LDK378-NX",
      "LDK-378",
      "UNII-K418KG2GET",
      "LDK378(Ceritinib)",
      "LDK 378",
      "Eritinib (LDK378)",
      "5-Chloro-N2-(2-Isopropoxy-5-Methyl-4-(Piperidin-4-Yl)Phenyl)-N4-(2-(Isopropylsulfonyl)Phenyl)Pyrimidine-2,4-Diamine",
      "K418KG2GET",
      "CHEMBL2403108",
      "CHEBI:78432",
      "AK174337",
      "C28H36ClN5O3S",
      "5-Chloro-N2-[2-Isopropoxy-5-Methyl-4-(4-Piperidyl)Phenyl]-N4-(2-Isopropylsulfonylphenyl)PyriMidine-2,4-DiaMine",
      "5-Chloro-N2-(5-Methyl-4-(Piperidin-4-Yl)-2-(Propan-2-Yloxy)Phenyl)-N4-(2-(Propane-2-Sulfonyl)Phenyl)Pyrim",
    ],
  },
  {
    primary_id: "D04LWD",
    names: ["4-Benzyl-1-Indan-2-Ylmethyl-Piperidine", "CHEMBL179145"],
  },
  {
    primary_id: "D04LWL",
    names: [
      "1,4-Dimethylquinolin-2(1H)-One",
      "1,4-Dimethylquinolin-2(1h)-One",
      "2584-47-6",
      "2(1H)-Quinolinone, 1,4-Dimethyl-",
      "1,4-Dimethyl-2(1H)-Quinolinone",
      "1,4-Dimethyl-1,2-Dihydroquinolin-2-One",
      "UNII-6WT6AG3ZQJ",
      "6WT6AG3ZQJ",
      "1,4-Dimethyl-2-Quinolone",
      "1,4-Dimethylquinolin-2-One",
      "ST019653",
      "Q-101237",
      "2(1H)-Quinolinone,1,4-Dimethyl-",
      "NSC108420",
      "NSC 108420",
      "1,4-DIMETHYL-2(1H)-QUINOLONE",
      "Casimiroin Analogue, 1d",
      "1,4-Dimethylcarbostyril",
      "4-Methyl-N-Methylquinolone",
      "AC1Q6I1L",
      "AC1L3Y4F",
      "KSC497K0B",
      "MLS000764269",
      "CHEMBL60376",
      "SCHEMBL436564",
      "ZINC32147",
      "CTK3J7500",
      "BDBM29215",
    ],
  },
  {
    primary_id: "D04LWT",
    names: ["GSK1440115"],
  },
  {
    primary_id: "D04LXB",
    names: ["Captisol-Enabled Melphalan"],
  },
  {
    primary_id: "D04LXG",
    names: ["6,7-Diethoxy-4-(5-Phenylpent-1-Enyl)Quinazoline", "CHEMBL251123"],
  },
  {
    primary_id: "D04LXR",
    names: ["3-[4-(4-Phenoxybenzoyl)Phenyl]propanoic Acid", "CHEMBL201425"],
  },
  {
    primary_id: "D04LYD",
    names: [
      "Azosemide",
      "27589-33-9",
      "Azosemida",
      "Azosemidum",
      "Diart",
      "PLE 1053",
      "Azosemidum [INN-Latin]",
      "Azosemida [INN-Spanish]",
      "UNII-MR40VT1L8Z",
      "Azosemide [USAN:INN:JAN]",
      "C12H11ClN6O2S2",
      "EINECS 248-549-7",
      "SK-110",
      "BRN 1178491",
      "MR40VT1L8Z",
      "2-Chloro-5-(2H-Tetrazol-5-Yl)-4-((Thiophen-2-Ylmethyl)Amino)Benzenesulfonamide",
      "CHEBI:31248",
      "NCGC00181340-01",
      "Azosemid",
      "5-(4'-Chloro-5'-Sulfamoyl-2'-Thenylaminophenyl)-Tetrazole",
      "2-Chloro-5-(1H-Tetrazol-5-Yl)-N(Sup 4)-2-Thenylsulfanilamide",
      "Benzenesulfonamide, 2-Chloro-5-(1H-Tetrazol-5-Yl)-4-((2-Thienylmethyl)",
    ],
  },
  {
    primary_id: "D04LZA",
    names: ["JWH-370"],
  },
  {
    primary_id: "D04LZF",
    names: ["NVX-208"],
  },
  {
    primary_id: "D04LZJ",
    names: [
      "(3-Amino-Propyl)-Phosphonic Acid",
      "3-Aminopropylphosphonic Acid",
      "13138-33-5",
      "(3-Aminopropyl)Phosphonic Acid",
      "Aminopropylphosphonate",
      "3-Aminopropylphosphonicacid",
      "UNII-VM92T06VPB",
      "Phosphonic Acid, (3-Aminopropyl)-",
      "VM92T06VPB",
      "CHEMBL286077",
      "GSZQTIFGANBTNF-UHFFFAOYSA-N",
      "GEO-00218",
      "NSC-133832",
      "3-Phosphonopropylamine",
      "AK-33107",
      "3-Appn",
      "(3-Aminopropyl)Phosphonic Acid, 97%",
      "NSC 133832",
      "Phosphate Analogue, 8",
      "PubChem10325",
      "ACMC-1BXXY",
      "AC1L3YTO",
      "3-Aminopropyl-Phosphonate",
      "Timtec-Bb Sbb004184",
      "Lopac-A-7162",
      "AC1Q6RQ7",
      "SCHEMBL35883",
    ],
  },
  {
    primary_id: "D04LZN",
    names: ["8-Bromo-9-Cyclobutyl-9H-Adenine", "CHEMBL515419"],
  },
  {
    primary_id: "D04MAM",
    names: [
      "Sulfamic Acid Chroman-2-Ylmethyl Ester",
      "CHEMBL180475",
      "835894-63-8",
      "Sulfamic Acid, (3,4-Dihydro-2H-1-Benzopyran-2-Yl)Methyl Ester",
      "SCHEMBL5274616",
      "CTK3D1605",
      "DTXSID80463432",
      "BDBM50163317",
      "(3,4-Dihydro-2H-Chromen-2-Yl)Methyl Sulfamate",
    ],
  },
  {
    primary_id: "D04MAP",
    names: ["CID16020046", "CID 16020046"],
  },
  {
    primary_id: "D04MAZ",
    names: ["Pyrimidine Derivative 33"],
  },
  {
    primary_id: "D04MBD",
    names: [
      "1,8-Dichloro-10H-Anthracen-9-One",
      "CHEMBL122022",
      "50259-93-3",
      "NSC162539",
      "1,8-Dichloroanthrone",
      "AC1Q3KGA",
      "AC1L9O6M",
      "SCHEMBL7103871",
      "CTK1H2907",
      "DTXSID70329816",
      "ZINC393214",
      "BDBM50060867",
      "AKOS024331862",
      "NSC-162539",
      "MCULE-9362278595",
    ],
  },
  {
    primary_id: "D04MBK",
    names: ["AGS-67E"],
  },
  {
    primary_id: "D04MBQ",
    names: ["IPSC-Derived RPE (Autologous)"],
  },
  {
    primary_id: "D04MBS",
    names: ["BMS-182657"],
  },
  {
    primary_id: "D04MCL",
    names: ["Cypate-[(RGD)2-NH2]2"],
  },
  {
    primary_id: "D04MCY",
    names: [
      "BMS 650032",
      "Asunaprevir",
      "630420-16-5",
      "BMS-650032",
      "UNII-S9X0KRJ00S",
      "S9X0KRJ00S",
      "Asunaprevir (BMS-650032)",
      "N-(Tert-Butoxycarbonyl)-3-Methyl-L-Valyl-(4r)-4-[(7-Chloro-4-Methoxyisoquinolin-1-Yl)Oxy]-N-{(1r,2s)-1-[(Cyclopropylsulfonyl)Carbamoyl]-2-Ethenylcyclopropyl}-L-Prolinamide",
      "Asunaprevir [USAN:INN]",
      "Sunvepra (TN)",
      "Tert-Butyl N-[(1S)-1-[(2S,4R)-4-[(7-Chloro-4-Methoxy-1-Isoquinolyl)Oxy]-2-[[(1R,2S)-1-(Cyclopropylsulfonylcarbamoyl)-2-Vinyl-Cyclopropyl]carbamoyl]pyrrolidine-1-Carbonyl]-2,2-Dimethyl-Propyl]carbamate",
      "2R9",
    ],
  },
  {
    primary_id: "D04MDJ",
    names: ["CEFDALOXIME PENTEXIL TOSILATE"],
  },
  {
    primary_id: "D04MDO",
    names: ["HuL2G7"],
  },
  {
    primary_id: "D04MEB",
    names: ["Antituberculosis Agents"],
  },
  {
    primary_id: "D04MEE",
    names: [
      "2-Methyl-1,2-Di-Pyridin-3-Yl-Propyliodide",
      "CHEMBL55678",
      "87372-72-3",
      "CTK3C4532",
      "DTXSID20658224",
      "BDBM50028168",
      "3,3'-(1-Iodo-2-Methylpropane-1,2-Diyl)Dipyridine",
      "Pyridine, 3,3'-(2-Iodo-1,1-Dimethyl-1,2-Ethanediyl)Bis-",
    ],
  },
  {
    primary_id: "D04MEM",
    names: ["ORMD-1001"],
  },
  {
    primary_id: "D04MFJ",
    names: ["Quinoline Derivative 17"],
  },
  {
    primary_id: "D04MFQ",
    names: ["Myotubularin"],
  },
  {
    primary_id: "D04MFW",
    names: ["DL-3"],
  },
  {
    primary_id: "D04MFY",
    names: [
      "AJ-3941",
      "AJ 3941",
      "AC1O5RPK",
      "Piperazine, 1-(3-Fluoro-6,11-Dihydrodibenz(B,e)Oxepin-11-Yl)-4-(3-Phenyl-2-Propenyl)-, (E)-(+-)-, (Z)-2-Butenedioate (1:2)",
      "1-(3-Fluoro-6,11-Dihydrodibenz(B,e)Oxepin-11-Yl)-4-(3-Phenyl-2-Propenyl)Piperazine Dimaleate",
      "(+-)-(E)-1-(3-Fluoro-6,11-Dihydrodibenz(B,e)Oxepin-11-Yl)-4-(3-Phenyl-2-Propenyl)Piperazine Dimaleate",
    ],
  },
  {
    primary_id: "D04MGD",
    names: ["Elpetrigine"],
  },
  {
    primary_id: "D04MGI",
    names: ["PF-821385"],
  },
  {
    primary_id: "D04MGU",
    names: ["Technetium (99m Tc) Bicisate"],
  },
  {
    primary_id: "D04MGW",
    names: ["BI-836858"],
  },
  {
    primary_id: "D04MHP",
    names: ["AZP-2006", "Amyloid Precursor Protein Modulator (AD), AlzProtect/INSERM/University Of Lille II"],
  },
  {
    primary_id: "D04MHV",
    names: ["Heterocyclic Derivative 17"],
  },
  {
    primary_id: "D04MIG",
    names: ["LY2087101"],
  },
  {
    primary_id: "D04MIL",
    names: ["Bispecific Antobody", "Bispecific Antobody (Type 1 Diabetes)"],
  },
  {
    primary_id: "D04MIN",
    names: ["Example 373 [WO2012093101]", "CHEMBL2180765", "GTPL8296", "SCHEMBL19210538", "BDBM50400274"],
  },
  {
    primary_id: "D04MIQ",
    names: ["PMID27172114-Compound-30"],
  },
  {
    primary_id: "D04MIY",
    names: [
      "5-Chloro-2-(Pyridin-2-Yl)-1H-Benzo[d]imidazole",
      "63053-15-6",
      "6-Chloro-2-(Pyridin-2-Yl)-1H-1,3-Benzodiazole",
      "1H-Benzimidazole, 6-Chloro-2-(2-Pyridinyl)-",
      "CHEMBL166127",
      "5-Chloro-2-Pyridin-2-Yl-3H-Benzoimidazole",
      "5-Chlor-2-(2-Pyridinyl)-1h-Benzimidazol",
      "AC1L4JHX",
      "AC1Q3S8V",
      "SCHEMBL124391",
      "CTK2F4016",
      "MolPort-006-709-924",
      "KS-00001PG4",
      "BDBM50180732",
      "ZINC13679488",
      "AKOS008957084",
      "AKOS005256857",
      "NE28766",
      "MCULE-2896710700",
      "6-Chloro-2-Pyridin-2-Yl-1H-Benzimidazole",
      "2-(2-Pyridyl)-5-Chloro-1H-Benzoimidazole",
    ],
  },
  {
    primary_id: "D04MKB",
    names: ["ST-1025"],
  },
  {
    primary_id: "D04MKJ",
    names: ["Renzapride"],
  },
  {
    primary_id: "D04MKK",
    names: ["Haloxyfop"],
  },
  {
    primary_id: "D04MKQ",
    names: ["(Amino-Phenyl-Methyl)-Phosphinic Acid"],
  },
  {
    primary_id: "D04MLE",
    names: ["GKA-23"],
  },
  {
    primary_id: "D04MLR",
    names: ["CD123-Specific Gene-Engineered T Cells"],
  },
  {
    primary_id: "D04MLU",
    names: ["[1-(4-P-Tolyl-Butyl)-Piperidin-4-Yl]-Methanol", "CHEMBL570915"],
  },
  {
    primary_id: "D04MMD",
    names: ["D[Arg4,Lys8]VP", "CHEMBL375325"],
  },
  {
    primary_id: "D04MMG",
    names: ["N6-[(4-Nitro)-Phenyl]-9-Benzyl-2-Phenyladenine", "CHEMBL496984"],
  },
  {
    primary_id: "D04MMJ",
    names: ["ADX-2 Series"],
  },
  {
    primary_id: "D04MML",
    names: ["Mogamulizumab"],
  },
  {
    primary_id: "D04MMW",
    names: ["MST-188"],
  },
  {
    primary_id: "D04MNL",
    names: ["SERM-3339"],
  },
  {
    primary_id: "D04MOF",
    names: ["Milveterol+Fluticasone"],
  },
  {
    primary_id: "D04MOZ",
    names: ["NB3178"],
  },
  {
    primary_id: "D04MPB",
    names: [
      "Bevirimat",
      "DSB",
      "Bevirimat Dimeglumine",
      "DSB 2NMG",
      "MPC-4326",
      "PA-457",
      "PA-103001-01",
      "PA-103001-04",
      "PA-457 Di-NMG",
      "Viral Maturation Inhibitor (HIV), Myrexis",
      "Viral Maturation Inhibitor (HIV), Myriad",
      "Viral Maturation Inhibitor (HIV), Panacos",
      "YK-FH-312",
      "3-O-(3' 3'-Dimethylsuccinyl)-Betulinic Acid",
    ],
  },
  {
    primary_id: "D04MPV",
    names: ["Pyrazole Derivative 15"],
  },
  {
    primary_id: "D04MQF",
    names: ["Tricyclic Heterocycle Derivative 3"],
  },
  {
    primary_id: "D04MQZ",
    names: ["PMID26560530-Compound-7"],
  },
  {
    primary_id: "D04MRA",
    names: [
      "BAY-73-7977",
      "Dual-Acting GLP Modulator/ Glucagon Antagonist (Diabetes), Bayer",
      "Dual-Acting Glucagon-Like Peptide Modulator/ Glucagon Antagonist (Diabetes), Bayer",
    ],
  },
  {
    primary_id: "D04MRG",
    names: ["Stevioside"],
  },
  {
    primary_id: "D04MRQ",
    names: ["Irsogladine"],
  },
  {
    primary_id: "D04MRV",
    names: [
      "Amifloxacin",
      "AMIFLOXACIN",
      "86393-37-5",
      "UNII-5TU5227KYQ",
      "Win49375",
      "WIN-49375",
      "5TU5227KYQ",
      "6-Fluoro-1,4-Dihydro-1-(Methylamino)-7-(4-Methyl-1-Piperazinyl)-4-Oxo-3-Quinolinecarboxylic Acid",
      "Amifloxacino",
      "Amifloxacine",
      "Amifloxacinum",
      "Win-493753",
      "Amifloxacinum [Latin]",
      "Amifloxacine [French]",
      "Amifloxacino [Spanish]",
      "Amifloxacin [USAN:INN:BAN]",
      "Win 49375",
      "EINECS 289-231-8",
      "Amifloxacin (USAN/INN)",
      "CHEMBL6231",
      "SCHEMBL48001",
      "ZINC917",
      "AC1L1J77",
      "CTK3E8601",
      "DTXSID20235519",
      "Win 49,375",
      "RUXPNBWPIRDVTH-UHFFFAOYSA-N",
      "HY-U00221",
      "AKOS030530713",
      "CS-7366",
      "FT-0711169",
    ],
  },
  {
    primary_id: "D04MRX",
    names: ["Chondroitinase"],
  },
  {
    primary_id: "D04MSI",
    names: ["Threo-3-Methylglutamate", "(+/-)-Threo-3-Methylglutamic Acid"],
  },
  {
    primary_id: "D04MSM",
    names: [
      "Carbamazepine",
      "Carbamazepine (Iv, Epilepsy)",
      "Carbamazepine (Iv, Epilepsy), Lundbeck",
      "Carbamazepine (Iv, Epilepsy), Ovation Pharmaceuticals",
    ],
  },
  {
    primary_id: "D04MSX",
    names: ["Calcitonin Oral"],
  },
  {
    primary_id: "D04MTT",
    names: ["PMID25435179-Compound-WO2012006391H3K9TSu"],
  },
  {
    primary_id: "D04MUQ",
    names: ["RiVax + TVax"],
  },
  {
    primary_id: "D04MVL",
    names: ["(R)-(-)-10-Methyl-11-Hydroxyaporphine"],
  },
  {
    primary_id: "D04MVN",
    names: ["PMID28092474-Compound-33b"],
  },
  {
    primary_id: "D04MVR",
    names: ["A-794282", "Compound 1n [PMID: 16279797]", "A 794282", "A794282"],
  },
  {
    primary_id: "D04MWJ",
    names: ["Methacholine Chloride"],
  },
  {
    primary_id: "D04MXN",
    names: ["CEP-32496", "AB-024", "B-RAF Kinase Inhibitors (Melanoma), Ambit/Cephalon"],
  },
  {
    primary_id: "D04MXO",
    names: [
      "BCX-5191",
      "BCX-4678",
      "HCV Polymerase Inhibitors, BioCryst",
      "Hepatitis C Virus Polymerase Inhibitors, BioCryst",
    ],
  },
  {
    primary_id: "D04MXV",
    names: ["VGV-L"],
  },
  {
    primary_id: "D04MYC",
    names: ["NSC-625487", "1-(2,6-Difluorophenyl)-1H,3H-Thiazolo[3,4-A]benzimidazole"],
  },
  {
    primary_id: "D04MYG",
    names: ["Fenbunafide"],
  },
  {
    primary_id: "D04MYO",
    names: ["ABN-912", "Anti-CD46 MAb, Novartis", "Anti-MCP1 Monoclonal Antibody, Novartis"],
  },
  {
    primary_id: "D04MYP",
    names: ["Tyr-Pro-Phe-Phg-NH2", "CHEMBL378111"],
  },
  {
    primary_id: "D04MYS",
    names: ["LL-4218", "Desoside-P"],
  },
  {
    primary_id: "D04MYT",
    names: [
      "4-(1H-Pyrazol-4-Yl)-1H-Pyrrolo[2,3-B]pyridine",
      "CHEMBL1089985",
      "SCHEMBL2239177",
      "1H-Pyrrolo[2,3-B]pyridine, 4-(1H-Pyrazol-4-Yl)-",
      "BDBM50315770",
    ],
  },
  {
    primary_id: "D04MZK",
    names: [
      "6-Phenylpyridazin-3-Yl Thiophene-2-Carboxylate",
      "CHEMBL1253380",
      "AC1O4BXS",
      "MolPort-002-097-410",
      "HMS1597J02",
      "ZINC1059557",
      "BDBM50326574",
      "MCULE-6134548476",
      "(6-Phenylpyridazin-3-Yl) Thiophene-2-Carboxylate",
    ],
  },
  {
    primary_id: "D04MZM",
    names: ["TVX-011"],
  },
  {
    primary_id: "D04MZP",
    names: ["NI-0101"],
  },
  {
    primary_id: "D04MZR",
    names: ["Bucelipase Alfa"],
  },
  {
    primary_id: "D04NAL",
    names: ["LY314228"],
  },
  {
    primary_id: "D04NAQ",
    names: ["Emricasan"],
  },
  {
    primary_id: "D04NBD",
    names: ["Quin-C7"],
  },
  {
    primary_id: "D04NBE",
    names: [
      "4-Hydroxy-2-Butanone",
      "4-Hydroxybutan-2-One",
      "590-90-9",
      "2-Butanone, 4-Hydroxy-",
      "Methylolacetone",
      "3-Oxo-1-Butanol",
      "3-Oxobutanol",
      "3-Ketobutan-1-Ol",
      "Monomethylolacetone",
      "4-Butanol-2-One",
      "UNII-TCM0BJ44MF",
      "2-Hydroxyethyl Methyl Ketone",
      "NSC 41219",
      "TCM0BJ44MF",
      "CH3C(O)CH2CH2OH",
      "LVSQXDHWDCMMRJ-UHFFFAOYSA-N",
      "BUQ",
      "Ketones, C4-6, Beta-Hydroxy",
      "3-Ketobutanol",
      "4-Hydroxy Butanone",
      "EINECS 209-693-6",
      "1-Hydroxy-3-Butanone",
      "4-Oxidanylbutan-2-One",
      "1d7j",
      "1 -Hydroxy-3-Butanone",
      "4-Hydroxy-Butan-2-One",
      "AI3-11747",
      "EC 209-693-6",
      "ACMC-1B12A",
    ],
  },
  {
    primary_id: "D04NBY",
    names: ["SPVF-2801-10"],
  },
  {
    primary_id: "D04NBZ",
    names: ["ISIS 6755"],
  },
  {
    primary_id: "D04NCC",
    names: ["PMID28350212-Compound-18"],
  },
  {
    primary_id: "D04NCJ",
    names: ["BMY-21950"],
  },
  {
    primary_id: "D04NDK",
    names: ["Oral Human Gammaglobulin"],
  },
  {
    primary_id: "D04NDM",
    names: [
      "Paromomycin",
      "Aminosidin",
      "Catenulin",
      "Humatin",
      "Hydroxymycin Sulfate",
      "Paramomycin Sulfate",
      "Paromomycin I",
      "Paromomycin Sulfate Rx346208",
      "Aminosidine, Sulfate",
      "HATT & Paromomycin",
      "Humatin (TN)",
      "Paromomycin (INN)",
      "Paromomycin (TN)",
      "Paromomycin (Complex)",
      "PA1-PA2-PA3-PA4",
      "Human .alpha.-1-Antitrypsin & Paromomyin",
      "PAROMOMYCIN I, AMMINOSIDIN, CATENULIN, CRESTOMYCIN, MONOMYCIN A, NEOMYCIN E",
      "(1R,2R,3S,4R,6S)-4,6-Diamino-2-{[3-O-(2,6-Diamino-2,6-Dideoxy-Beta-L-Idopyranosyl)-Beta-D-Ribofuranosyl]oxy}-3-Hydroxycyclohexyl 2-Amino-2-Deoxy-Alpha-D-Glucopyranoside",
      "(2S,3S,4R,5R,6R)-5-Amino-2-(Aminomethyl)-6-[(2R,3S,4R,5S)-5-[(1R,2R,3S,5R,6S)-3,5-Diamino-2-[(2S,3R,4R,5S,6R)-3-Amino-4,5-Dihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxy-6-Hydroxycyclohexyl]oxy-4-Hydroxy-2-(Hydroxymethyl)Oxolan-3-Yl]oxyoxane-3,4-Diol",
      "O-2-Amino-2-Deoxy-.alpha.-D-Glucopyranosyl-(1->4)-O-[O-2,6-Diamino-2,6-Dideoxy-.beta.-L-Idopyranosyl-(1->3)-.beta.D-Ribofuranosyl(1->5)]-2-Deoxy-D-Streptamine",
    ],
  },
  {
    primary_id: "D04NDO",
    names: ["4-(2-(2-Fluorobenzyloxy)Phenyl)Piperidine", "CHEMBL573897"],
  },
  {
    primary_id: "D04NFB",
    names: [
      "DMP-963",
      "DPC-963",
      "4(S)-(2-Cyclopropylethynyl)-5,6-Difluoro-4-(Trifluoromethyl)-3,4-Dihydro-1H-Quinazolin-2-One",
    ],
  },
  {
    primary_id: "D04NGC",
    names: [
      "5-(Methylthio)Thiophene-2-Carboxylic Acid",
      "20873-58-9",
      "5-Methylsulfanylthiophene-2-Carboxylic Acid",
      "5-(Methylsulfanyl)Thiophene-2-Carboxylic Acid",
      "CHEMBL450866",
      "2-Thiophenecarboxylic Acid, 5-(Methylthio)-",
      "2-Thiophenecarboxylicacid, 5-(Methylthio)-",
      "5-Methylthiothiophene-2-Carboxylic Acid",
      "ZERO/008316",
      "AC1Q5UIM",
      "Maybridge1_007717",
      "ChemDiv2_000116",
      "AC1Q4H1V",
      "AC1LEM73",
      "Cambridge Id 5103856",
      "SCHEMBL2723219",
      "KS-00000KTP",
      "HMS563G17",
      "CTK1A1236",
      "DTXSID70351935",
      "FWFWUSLBIIIIEN-UHFFFAOYSA-N",
      "MolPort-000-146-385",
      "ZINC109304",
      "HMS1369F06",
    ],
  },
  {
    primary_id: "D04NGL",
    names: ["WS-12"],
  },
  {
    primary_id: "D04NGS",
    names: ["JNJ-7706621"],
  },
  {
    primary_id: "D04NHQ",
    names: ["HSV529"],
  },
  {
    primary_id: "D04NIU",
    names: ["PMID25522065-Compound-2"],
  },
  {
    primary_id: "D04NIZ",
    names: ["AMG 747"],
  },
  {
    primary_id: "D04NJC",
    names: [
      "1-Adamantan-1-Yl-3-(4-Methoxy-Phenyl)-Urea",
      "1-(1-Adamantyl)-3-(4-Methoxyphenyl)Urea",
      "CHEMBL477067",
      "AC1MT5QX",
      "Oprea1_591947",
      "BDBM50267097",
    ],
  },
  {
    primary_id: "D04NJP",
    names: ["TRF-1101"],
  },
  {
    primary_id: "D04NJS",
    names: ["SAR351034"],
  },
  {
    primary_id: "D04NKE",
    names: ["Tihourea Derivative 1"],
  },
  {
    primary_id: "D04NKQ",
    names: ["4-(2-(4-Fluorophenoxy)Phenyl)Piperidine", "CHEMBL584173", "SCHEMBL3040991", "BDBM50299806"],
  },
  {
    primary_id: "D04NLG",
    names: ["BRL 43694A"],
  },
  {
    primary_id: "D04NMF",
    names: [
      "DISC-PRO",
      "DISC-HSV",
      "GW-419458",
      "HSV Vaccine, Xenova",
      "TA-HSV",
      "Herpes Simplex Vaccine, Cantab/GlaxoSmithKline",
      "Vaccine (Herpes Simplex, Prophylactic), Xenova",
      "Anti-HSV (DISC) Therapy, Cantab/GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D04NMJ",
    names: ["PMID25553724-Compound-US2012830453910"],
  },
  {
    primary_id: "D04NNJ",
    names: ["ZANOTERONE"],
  },
  {
    primary_id: "D04NNK",
    names: ["Recombinant Therapeutic Proteins"],
  },
  {
    primary_id: "D04NOE",
    names: ["OTSGC-A24"],
  },
  {
    primary_id: "D04NOT",
    names: ["Bromoacetone"],
  },
  {
    primary_id: "D04NPE",
    names: ["UBP141"],
  },
  {
    primary_id: "D04NPP",
    names: ["SLX-2101"],
  },
  {
    primary_id: "D04NPR",
    names: ["Lu AF35700"],
  },
  {
    primary_id: "D04NPX",
    names: ["ZD-1611"],
  },
  {
    primary_id: "D04NQI",
    names: [
      "Amodiaquine",
      "Amodiachin",
      "Amodiachinum",
      "Amodiaquin",
      "Amodiaquina",
      "Amodiaquinum",
      "Basoquin",
      "CQA",
      "Camochin",
      "Camoquin",
      "Camoquinal",
      "Camoquine",
      "Flavoquin",
      "Flavoquine",
      "Miaquin",
      "Sunoquine",
      "Amodiaquine Hydrochloride",
      "Amodiaquine USP24",
      "SN 10751",
      "AMODIAQUINE, FLAVOQUINE",
      "Amodiaquina [INN-Spanish]",
      "Amodiaquinum [INN-Latin]",
      "CAM-AQ 1",
      "CAM-AQI",
      "Cam-AQ1",
      "Camoquin (TN)",
      "Flavoquine (TN)",
      "SN 10,751",
      "WR-002977",
      "Amodiaquine (USAN/INN)",
      "Amodiaquine [USAN:INN:BAN]",
      "Amodiaquine, Ring-Closed",
      "S. N. 10751",
      "O-Cresol, 4-[(7-Chloro-4-Quinolyl)Amino]-.alpha.-(Diethylamino)-(6CI,7CI,8CI)",
      "4-((7-Chloro-4-Quinolinyl)Amino)-2-((Diethylamino)Methyl)Phenol",
      "4-((7-Chloro-4-Quinolyl)Amino)-Alpha-(Diethylamino)-O-Cresol",
      "4-(7-Chloro-Quinolin-4-Ylamino)-2-Diethylaminomethyl-Phenol",
      "4-[(7-CHLOROQUINOLIN-4-YL)AMINO]-2-[(DIETHYLAMINO)METHYL]PHENOL",
      "4-[(7-Chloro-4-Quinolinyl)Amino]-2-[(Diethylamino)Methyl]phenol",
      "4-[(7-Chloroquinolin-4-Yl)Amino]-2-(Diethylaminomethyl)Phenol",
      "7-Chloro-4-(3-Diethylaminomethyl-4-Hydroxyanilino)",
      "7-Chloro-4-(3-Diethylaminomethyl-4-Hydroxyanilino)Quinoline",
      "7-Chloro-4-(3-Diethylaminomethyl-4-Hydroxyphenylamino)Quinoline",
    ],
  },
  {
    primary_id: "D04NQZ",
    names: ["V502"],
  },
  {
    primary_id: "D04NRB",
    names: [
      "Cetilistat",
      "Cametor",
      "Cetilistat [INN]",
      "ATL 962",
      "ATL962",
      "ATL-962",
      "Cetilistat (JAN/USAN/INN)",
      "2-(Hexadecyloxy)-6-Methyl-4H-3,1-Benzoxazin-4-One",
    ],
  },
  {
    primary_id: "D04NRP",
    names: ["ALS-2135"],
  },
  {
    primary_id: "D04NRV",
    names: ["IMM-101"],
  },
  {
    primary_id: "D04NSN",
    names: [
      "4-(2-AMINOETHYL)BENZENESULFONAMIDE",
      "4-(2-Aminoethyl)Benzenesulfonamide",
      "35303-76-5",
      "4-(2-Aminoethyl)Benzene-1-Sulfonamide",
      "4-(2-Aminoethyl)Benzene Sulfonamide",
      "4-(2-Amino-Ethyl)-Benzenesulfonamide",
      "Benzenesulfonamide, 4-(2-Aminoethyl)-",
      "4-(2-Aminoethyl)Benzenesulphonamide",
      "CHEMBL7087",
      "4-Aminoethylbenzenesulfonamide",
      "2-(4-Sulfamoylphenyl)Ethylamine",
      "4-(2-Aminoethyl)Benzesulfonamide",
      "FXNSVEQMUYPYJS-UHFFFAOYSA-N",
      "4-(2-Aminoethyl) Benzenesulfonamide",
      "MFCD00010301",
      "4-(2-Aminoethyl)-Benzenesulfonamide Hydrochloride",
      "EINECS 252-501-0",
    ],
  },
  {
    primary_id: "D04NSP",
    names: ["4-Hydroxy-N-(5-Hydroxycarbamoyl-Pentyl)-Benzamide", "CHEMBL167455"],
  },
  {
    primary_id: "D04NSW",
    names: ["S-12340"],
  },
  {
    primary_id: "D04NTJ",
    names: ["Glisoxepide"],
  },
  {
    primary_id: "D04NUS",
    names: ["Spiroimidazolone Derivative 4"],
  },
  {
    primary_id: "D04NUZ",
    names: ["C[Glu26-Lys30][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D04NVK",
    names: ["ShRNA Molecule Silencing DNAPK Radiosensitizer"],
  },
  {
    primary_id: "D04NWI",
    names: ["(R)-2-Methyl-1-(2-M-Tolyl-Ethyl)-Pyrrolidine", "CHEMBL257915"],
  },
  {
    primary_id: "D04NWP",
    names: [
      "DRAFLAZINE",
      "Draflazine < Rec INN",
      "R-70380",
      "R-75231",
      "Rac-N-(4-Amino-2,6-Dichlorophenyl)-4-[5,5-Bis(4-Fluorophenyl)Pentyl]-2-Carbamoylpiperazine-1-Acetamide",
      "Rac-2-(Aminocarbonyl)-N-(4-Amino-2,6-Dichlorophenyl)-4-[5,5-Bis(4-Fluorophenyl)Pentyl]-1-Piperazineacetamide",
      "Rac-4'-Amino-4-[5,5-Bis(P-Fluorophenyl)Pentyl]-2-Carbamoyl-2',6'-Dichloro-1-Piperazineacetanilide",
    ],
  },
  {
    primary_id: "D04NWU",
    names: ["ONO-4053"],
  },
  {
    primary_id: "D04NXG",
    names: ["PMID26651364-Compound-128"],
  },
  {
    primary_id: "D04NXL",
    names: ["Indibulin"],
  },
  {
    primary_id: "D04NXQ",
    names: [
      "Pefloxacin",
      "Abactal",
      "Labocton",
      "PERFLOXACIN",
      "PFLX",
      "Peflacine",
      "Pefloxacine",
      "Pefloxacino",
      "Pefloxacinum",
      "Pefloxacin Methanesulfonate",
      "Silver Pefloxacin",
      "AM-725",
      "EU-5306",
      "Pefloxacin [INN-French]",
      "Pefloxacino [INN-Spanish]",
      "Pefloxacinum [INN-Latin]",
      "Pefloxacin (USAN/INN)",
      "Pefloxacin [USAN:BAN:INN]",
      "1-Ethyl-6-Fluoro-1,4-Dihydro-4-Oxo-7-(4-Methyl-1-Piperazinyl)-3-Quinolinecarboxylic Acid",
      "1-Ethyl-6-Fluoro-1,4-Dihydro-7-(4-Methyl-1-Piperazinyl)-4-Oxo-3-Quinolinecarboxylic Acid",
      "1-Ethyl-6-Fluoro-7-(4-Methyl-1-Piperazinyl)-4-Oxo-1,4-Dihydro-3-Quinolinecarboxylic Acid",
      "1-Ethyl-6-Fluoro-7-(4-Methylpiperazin-1-Yl)-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-7-(4-Methylpiperazin-1-Yl)-4-Oxoquinoline-3-Carboxylic Acid",
      "3-Quinolinecarboxylic Acid, 1-Ethyl-6-Fluoro-1,4-Dihydro-7-(4-Methyl-1-Piperazinyl)-4-Oxo",
    ],
  },
  {
    primary_id: "D04NXR",
    names: ["RO-16-8714"],
  },
  {
    primary_id: "D04NYQ",
    names: ["N3-GHQMFYYPra-NH2"],
  },
  {
    primary_id: "D04NYX",
    names: ["AP-15012"],
  },
  {
    primary_id: "D04NZF",
    names: ["Cmp5", "ChemBridge ID 9033823"],
  },
  {
    primary_id: "D04OBB",
    names: [
      "Pheniramine",
      "Avil",
      "Feniramina",
      "Feniramine",
      "Histapyridamine",
      "Metron",
      "Pheniraminum",
      "Propheniramine",
      "Prophenpyridamine",
      "Pyriton",
      "Trimeton",
      "Tripoton",
      "Pheniramine Bimaleate",
      "Avil (TN)",
      "Feniramina [INN-Spanish]",
      "P-Aminosalicylsaures Salz",
      "Pheniramine (INN)",
      "Pheniramine [INN:BAN]",
      "Pheniraminum [INN-Latin]",
      "PHENIRAMINE (SEE ALSO PHENIRAMINE MALEATE 132-20-7)",
      "Dimethyl(3-Phenyl-3-(2-Pyridyl)Propyl)Amine",
      "N,N-Dimethyl-3-Phenyl-3-(2-Pyridyl)Propylamine",
      "N,N-Dimethyl-3-Phenyl-3-Pyridin-2-Ylpropan-1-Amine",
      "1-Phenyl-1-(2-Pyridyl)-3-Dimethylaminopropane",
      "2-(3-Dimethylamino-1-Phenylpropyl)Pyridine",
      "2-(Alpha-(2-Dimethylaminoethyl)Benzyl)Pyridine",
      "2-Pyridinepropanamine, N,N-Dimethyl-Gamma-Phenyl-(9CI)",
      "2-[.alpha.-(2-Dimethylaminoethyl)Benzyl]pyridine",
      "2-[.alpha.-[2-(Dimethylamino)Ethyl]benzyl]pyridine",
      "3-Phenyl-3-(2-Pyridyl)-N,N-Dimethylpropylamine",
      "3-Phenyl-3-(2-Pyridyl)-N,N-Dimethylpropylanine",
    ],
  },
  {
    primary_id: "D04OBS",
    names: ["ZP-013"],
  },
  {
    primary_id: "D04OCC",
    names: ["Org-9616"],
  },
  {
    primary_id: "D04OCD",
    names: ["PMID27774822-Compound-Figure7Example63"],
  },
  {
    primary_id: "D04OCO",
    names: ["AC-163954"],
  },
  {
    primary_id: "D04ODF",
    names: ["LY-3390334", "206444-72-6", "LY-339840", "LY-367366", "LY-393053"],
  },
  {
    primary_id: "D04ODG",
    names: ["3-Ethyl-3-Isobutyl-1-Phenylazetidine-2,4-Dione", "CHEMBL566475"],
  },
  {
    primary_id: "D04ODH",
    names: [
      "Gonadorelin",
      "Dirigestran",
      "Factrel",
      "Fertagyl",
      "Gonadorelina",
      "Gonadorelinum",
      "Hypocrine",
      "Kryptocur",
      "LFRH",
      "LHFSHRH",
      "Luforan",
      "Luliberin",
      "Lutal",
      "Lutamin",
      "Lutrefact",
      "Relefact",
      "Relisorm",
      "Dirigestran Spofa",
      "Gonadoliberin I",
      "Gonadotropin Releasing Hormone",
      "LHFSH Releasing Hormone",
      "LUTEINIZING HORMONE",
      "Lutrepulse KIT",
      "MammalianGnRH",
      "Relisorm L",
      "Synthetic LRF",
      "Synthetic Gonadoliberin",
      "HOE 471",
      "AY-24031",
      "FSH-Releasing Hormone",
      "Fertagyl (TN)",
      "GnRH-I",
      "Gonadorelin (INN)",
      "Gonadorelin [INN:BAN]",
      "Gonadorelina [INN-Spanish]",
      "Gonadorelinum [INN-Latin]",
      "Gonadotropin-Releasing Factor",
      "Gonadotropin-Releasing Hormone",
      "Gonadotropin-Releasing Hormone I",
      "Human LH-RH",
      "LH-FSH Releasing Hormone",
      "LH-Releasing Hormone",
      "Luteinizing Hormone-Releasing Hormone",
      "Mammalian LH-RH",
      "Mammalian Gonadotropin-Releasing Hormone",
      "Ovine LH-RH",
      "Ovine Gonadotropin-Releasing Hormone",
      "Porchine LH-RH",
      "Porcine LH-Releasing Factor",
      "Synthetic Gn-RH",
      "Synthetic LH-FSH Releasing Hormone",
      "Synthetic LH-RF",
      "Synthetic LH-RH",
      "Synthetic LH-Releasing Factor",
      "Synthetic LH-Releasing Hormone",
      "Synthetic Gonadotropic Hormone-Releasing Hormone",
      "Synthetic Gonadotropin-Releasing Hormone",
      "LH-RH (Swine)",
      "LH-Releasing Factor (Pig)",
      "LH-Releasing Hormone (Porcine)",
      "Luteinizing Hormone-Releasing Factor (Human)",
      "Luteinizing Hormone-Releasing Factor (Pig)",
      "Luteinizing Hormone-Releasing Factor (Rat)",
      "Luteinizing Hormone-Releasing Factor (Sheep)",
      "Luteinizing Hormone-Releasing Factor (Swine)",
      "Luteinizing Hormone-Releasing Hormone (Swin)",
      "Synthetic LH-FSH-RH",
      "Synthetic Decapeptide FSH/LH-RH",
      "Follicle-Stimulating Hormone-Releasing Factor (Pig)",
      "Gonadotropin, Luteinizing Hormone-Releasing Hormone, Synthetic",
      "Synthetic LH-RH/FSH-RH",
      "Synthetic LH-Releasing Hormone/FSH-Releasing Hormone",
      "PyroGlu-His-Trp-Ser-Tyr-Gly-Leu-Arg-Pro-Gly-NH2",
    ],
  },
  {
    primary_id: "D04ODO",
    names: ["GSK-256073"],
  },
  {
    primary_id: "D04ODQ",
    names: ["447C88", "BW-447C88"],
  },
  {
    primary_id: "D04OEV",
    names: ["Cyclic Peptide Derivative 1"],
  },
  {
    primary_id: "D04OFR",
    names: ["MEDI8897"],
  },
  {
    primary_id: "D04OFY",
    names: ["ABBV-321"],
  },
  {
    primary_id: "D04OGH",
    names: ["SEL-068"],
  },
  {
    primary_id: "D04OGP",
    names: ["HL-10"],
  },
  {
    primary_id: "D04OGS",
    names: ["LIV-2008"],
  },
  {
    primary_id: "D04OGZ",
    names: [
      "D-699126",
      "Bisbenzimidazole Analogs, ICR",
      "Bisbenzimidazole Analogs, Institute Of Cancer Research",
      "D-699127",
      "D-701062",
      "DNA Synthesis Inhibitors, ICR",
      "DNA Synthesis Inhibitors, Institute Of Cancer Research",
    ],
  },
  {
    primary_id: "D04OHC",
    names: ["Tarnylcypromine Derivative 2"],
  },
  {
    primary_id: "D04OHM",
    names: [
      "4-Cyclopentyliden(4-Hydroxyphenyl)Methylphenol",
      "66422-10-4",
      "SCHEMBL3902681",
      "CHEMBL352015",
      "CTK1J4764",
      "DTXSID00468323",
      "ZINC13650257",
      "Bis(4-Hydroxyphenyl)Methylidenecyclopentane",
      "4,4'-(Cyclopentylidenemethylene)Bis(Phenol)",
      "Phenol, 4-[cyclopentylidene(4-Hydroxyphenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D04OIF",
    names: ["TAK-259", "Alpha1D-Adrenoceptor Antagonist (Overactive Bladder), Takeda"],
  },
  {
    primary_id: "D04OIG",
    names: [
      "DSLIM",
      "DNABarrier II",
      "DSLIM (Colon Cancer)",
      "DSLIM (Colon Cancer), Mologen",
      "Immunomodulator (Colon Cancer), Mologen",
      "MIDGE Technology (Colon Cancer), Mologen",
      "Immunomodulator + Immunoadjuvant (MIDGE/DNABarrier II/DSLIM, Cancer), Mologen/Starvax",
    ],
  },
  {
    primary_id: "D04OJM",
    names: ["Human Interleukin-2"],
  },
  {
    primary_id: "D04OKJ",
    names: ["Garenoxacin"],
  },
  {
    primary_id: "D04OLA",
    names: ["Diphtheria Toxoid Adsorbed"],
  },
  {
    primary_id: "D04OLK",
    names: ["AST-1306", "Allitinib", "Allitinib Tosylate"],
  },
  {
    primary_id: "D04OMK",
    names: ["Nicotinaldehyde O-4-Butoxyphenylcarbamoyl Oxime", "CHEMBL597445"],
  },
  {
    primary_id: "D04OMR",
    names: ["PF-04840082"],
  },
  {
    primary_id: "D04ONP",
    names: ["ALBIFYLLINE"],
  },
  {
    primary_id: "D04ONQ",
    names: ["4-Aryl Quinol Derivative 7"],
  },
  {
    primary_id: "D04OOC",
    names: ["FCE-20696"],
  },
  {
    primary_id: "D04OOK",
    names: [
      "Phenyl-N,N-Bis(Tosylmethyl)Methanamine",
      "CHEMBL399303",
      "Benzylbis[(4-Methylbenzenesulfonyl)Methyl]amine",
      "65950-40-5",
      "AC1MXBV6",
      "SCHEMBL3972986",
      "CTK1I1277",
      "DTXSID60396416",
      "ZINC15776492",
      "BDBM50232467",
      "AKOS027445593",
      "N,N-Bis(Tosylmethyl)Benzenemethanamine",
      "N,N-Bis[(4-Methylphenyl)Sulfonylmethyl]-1-Phenylmethanamine",
      "Benzenemethanamine, N,N-Bis[[(4-Methylphenyl)Sulfonyl]methyl]-",
    ],
  },
  {
    primary_id: "D04OPE",
    names: ["SF-11"],
  },
  {
    primary_id: "D04OPJ",
    names: ["Bicyclo-Heptan-2-Amine Derivative 1"],
  },
  {
    primary_id: "D04OPS",
    names: ["MDT-011"],
  },
  {
    primary_id: "D04OQQ",
    names: ["Anti-Avian Influenza Agent"],
  },
  {
    primary_id: "D04OQT",
    names: ["Musculoskeletal Mab Therapeutic"],
  },
  {
    primary_id: "D04OQU",
    names: ["ASP2408", "CTLA4-Ig Fusion Protein"],
  },
  {
    primary_id: "D04OQZ",
    names: [
      "Arabinose-5-Phosphate",
      "D-Arabinose 5-Phosphate",
      "Arabinose 5-Phosphate",
      "13137-52-5",
      "5-O-Phosphono-D-Arabinose",
      "D-A-5-P",
      "D-Arabinose, 5-(Dihydrogen Phosphate)",
      "CHEBI:16241",
      "Aldehydo-D-Arabinose 5-Phosphate",
      "Arabinose-5P",
      "Arabinose-5-P",
      "1uj6",
      "AC1L4MLG",
      "(2,3,4-Trihydroxy-5-Oxopentyl) Dihydrogen Phosphate",
      "AC1Q6RV6",
      "SCHEMBL939515",
      "D-Arabinose 5-Phosphoric Acid",
      "CHEMBL1162524",
      "ZINC3606137",
      "D-Arabinose 5-(Dihydrogen Phosphate)",
      "AKOS027382411",
      "C01112",
      "(2,3,4-Trihydroxy-5-Oxo-Pentyl) Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D04ORP",
    names: [
      "GMK",
      "(3-Deoxy-3-(3-Methoxy-Benzamido)-B-D-Galactopyranosyl)-(3-Deoxy-3-(3-Methoxy-Benzamido)-2-O-Sulfo-B-D-Galactopyranosyl)-Sulfide",
      "3-Deoxy-3-[(3-Methoxybenzoyl)Amino]-Beta-D-Galactopyranosyl 3-Deoxy-3-[(3-Methoxybenzoyl)Amino]-2-O-Sulfo-1-Thio-Beta-D-Galactopyranoside",
    ],
  },
  {
    primary_id: "D04ORV",
    names: ["Des-AA1,2,5,12,13-[D-Trp8]SRIF", "CHEMBL216992"],
  },
  {
    primary_id: "D04ORY",
    names: [
      "4-Amino-N-P-Tolylbenzenesulfonamide",
      "4-Amino-N-(4-Methylphenyl)Benzenesulfonamide",
      "16803-95-5",
      "4-Amino-N-(P-Tolyl)Benzenesulfonamide",
      "QXIZWXNTAVXJNC-UHFFFAOYSA-N",
      "Benzenesulfonamide, 4-Amino-N-(4-Methylphenyl)-",
      "AC1LC9K2",
      "Oprea1_140477",
      "CHEMBL389286",
      "SCHEMBL2240008",
      "CTK0E5316",
      "DTXSID50341293",
      "MolPort-002-788-929",
      "ZINC265634",
      "ALBB-005339",
      "ZX-AN005271",
      "BBL008295",
      "STK288097",
      "4-Amino-N-P-Tolyl-Benzenesulfonamide",
      "AKOS000151276",
      "MCULE-6336197788",
      "SEL11434898",
      "TR-041978",
      "TR-058746",
      "AB0221486",
      "ST45130422",
      "R4223",
      "EU-0099855",
      "[(4-Aminophenyl)Sulfonyl](4-Methylphenyl)Amine",
    ],
  },
  {
    primary_id: "D04OSA",
    names: ["AZD-3839"],
  },
  {
    primary_id: "D04OSE",
    names: [
      "Nifedipine",
      "Adalat",
      "Adalate",
      "Adapine",
      "Adapress",
      "Afeditab",
      "Alat",
      "Aldipin",
      "Alfadal",
      "Alonix",
      "Angipec",
      "Anifed",
      "Anpine",
      "Aprical",
      "Bonacid",
      "Calcibloc",
      "Calcigard",
      "Calcilat",
      "Camont",
      "Cardifen",
      "Cardilat",
      "Cardionorm",
      "Chronadalate",
      "Citilat",
      "Coracten",
      "Coral",
      "Cordafen",
      "Cordaflex",
      "Cordalat",
      "Cordicant",
      "Cordilan",
      "Cordipin",
      "Cordipine",
      "Corinfar",
      "Corotrend",
      "Corynphar",
      "Depin",
      "Dignokonstant",
      "Dilafed",
      "Dipinkor",
      "Duranifin",
      "Ecodipi",
      "Ecodipin",
      "Emaberin",
      "Fedcor",
      "Fenamon",
      "Fenigidin",
      "Fenihidin",
      "Fenihidine",
      "Glopir",
      "Hadipin",
      "Hexadilat",
      "Infedipin",
      "Introcar",
      "Kordafen",
      "Korinfar",
      "Macorel",
      "Megalat",
      "Myogard",
      "Nedipin",
      "Nicardia",
      "Nifangin",
      "Nifar",
      "Nifdemin",
      "Nifebene",
      "Nifecard",
      "Nifecor",
      "Nifedepat",
      "Nifediac",
      "Nifedical",
      "Nifedicor",
      "Nifedin",
      "Nifedine",
      "Nifedipino",
      "Nifedipinum",
      "Nifedipres",
      "Nifelan",
      "Nifelat",
      "Nifelate",
      "Nificard",
      "Nifidine",
      "Nifipen",
      "Niphedipine",
      "Orix",
      "Oxcord",
      "Pidilat",
      "Procardia",
      "Sepamit",
      "Tibricol",
      "Vascard",
      "Zenusin",
      "AWD Pharma Brand Of Nifedipine",
      "Adalat CC",
      "Adalat CR",
      "Adalat Crono",
      "Adalat FT",
      "Adalat GITS",
      "Adalat LA",
      "Adalat LP",
      "Adalat Oros",
      "Adalat PA",
      "Adalat Retard",
      "Adalat XL",
      "Adalate LP",
      "Adcock Ingram Brand Of Nifedipine",
      "Adipine XL",
      "Afeditab CR",
      "Alonix S",
      "Aprical Long",
      "Bayer Brand Of Nifedipine",
      "Chronadalate LP",
      "Coracten XL",
      "Ecodipin E",
      "Fedcor Retard",
      "Fenamon SR",
      "Fortipine LA",
      "KRKA Brand Of Nifedipine",
      "Nifedical XL",
      "Nifedipine Bayer Brand",
      "Nifedipine GTIS",
      "Nifedipine KRKA Brand",
      "Nifedipine Monohydrochloride",
      "Nifedipine Orion Brand",
      "Nifedipine Pfizer Brand",
      "Nifedipine Retard",
      "Nifedirex LP",
      "Nifelat Q",
      "Nifensar XL",
      "Orion Brand Of Nifedipine",
      "Pfizer Brand Of Nifedipine",
      "Procardia XL",
      "Slofedipine XL",
      "Tensipine MR",
      "Adalat 10",
      "Adalat 20",
      "Adalat 5",
      "Adalat GITS 30",
      "Bay1040",
      "N 7634",
      "N1fedilat",
      "Adalat (TN)",
      "Afeditab CR (TN)",
      "Alpha-Nifedipine Retard;Apo-Nifed",
      "Bay-1040",
      "KB-1712P",
      "Monohydrochloride, Nifedipine",
      "Nifedical (TN)",
      "Nifedipine-GTIS",
      "Nifedipino [INN-Spanish]",
      "Nifedipinum [INN-Latin]",
      "Procardia (TN)",
      "Bay-A-1040",
      "Nifedipine (JP15/USP/INN)",
      "Nifedipine [USAN:BAN:INN:JAN]",
    ],
  },
  {
    primary_id: "D04OSU",
    names: ["L-709,587"],
  },
  {
    primary_id: "D04OTF",
    names: ["N-(4-Chloro-3-Methanesulfonyl-Benzoyl)-Guanidine", "CHEMBL62469"],
  },
  {
    primary_id: "D04OTH",
    names: ["SomaKit-TOC; Gallium (68Ga) Edotreotide"],
  },
  {
    primary_id: "D04OTY",
    names: ["BFH-772"],
  },
  {
    primary_id: "D04OUJ",
    names: [
      "1-Deoxy-1-Thio-Heptaethylene Glycol",
      "1-DEOXY-1-THIO-HEPTAETHYLENE GLYCOL",
      "PE7",
      "20-Sulfanyl-3,6,9,12,15,18-Hexaoxaicosan-1-Ol",
      "AC1L9JR2",
      "MolPort-006-168-364",
      "ZINC5828797",
      "DB02404",
      "3,6,9,12,15,18-Hexaoxa-20-Mercaptoicosane-1-Ol",
    ],
  },
  {
    primary_id: "D04OUP",
    names: ["NNI-AD"],
  },
  {
    primary_id: "D04OUT",
    names: ["ISIS 102584"],
  },
  {
    primary_id: "D04OWX",
    names: ["1-(Cyclopropyl)Methyl-5'-O-Tritylinosine", "CHEMBL379981", "BDBM50194179"],
  },
  {
    primary_id: "D04OXC",
    names: ["PMID29324067-Compound-38"],
  },
  {
    primary_id: "D04OXD",
    names: ["Netivudine"],
  },
  {
    primary_id: "D04OXG",
    names: ["PMZ-2123"],
  },
  {
    primary_id: "D04OXI",
    names: ["PMID27109571-Compound-42"],
  },
  {
    primary_id: "D04OXU",
    names: ["E-2078", "66A-078"],
  },
  {
    primary_id: "D04OXY",
    names: [
      "2-Methoxy-1,4-Naphthoquinone",
      "2348-82-5",
      "2-Methoxynaphthoquinone",
      "2-Methoxynaphthalene-1,4-Dione",
      "2-Methoxy-P-Naphthoquinone",
      "1,4-Naphthalenedione, 2-Methoxy-",
      "1,4-NAPHTHOQUINONE, 2-METHOXY-",
      "UNII-39020BUT1D",
      "2-Methoxy-1,4-Naphthalenedione",
      "NSC 31530",
      "AI3-17893",
      "CHEMBL106562",
      "2-Methoxy-[1,4]naphthoquinone",
      "CHEBI:69522",
      "OBGBGHKYJAOXRR-UHFFFAOYSA-N",
      "39020BUT1D",
      "2-Methoxy-1,4-Dihydronaphthalene-1,4-Dione",
      "ACMC-20ans6",
      "AC1L28UU",
      "MLS002472995",
      "2-Methoxy-1,4-Napthoquinone",
      "SCHEMBL571061",
      "AC1Q483I",
      "DTXSID1062338",
      "CTK4F1565",
    ],
  },
  {
    primary_id: "D04OYW",
    names: ["ST-06"],
  },
  {
    primary_id: "D04OZL",
    names: ["Hu3S193", "Anti-Lewis Y Antigen Mab, MSKCC/NCI/Ludwig"],
  },
  {
    primary_id: "D04OZR",
    names: ["TTH 02-001"],
  },
  {
    primary_id: "D04PAF",
    names: [
      "ANA-04",
      "ANA-506",
      "ANA-506)",
      "ANB-004",
      "Human Monoclonal Antibody (Inflammatory Disease/Autoimmune Disease), AnaptysBio",
      "Multi-Specific Antibody (Autoimmune Disease/Inflammatory Disease), AnaptysBio/VLST Corp",
    ],
  },
  {
    primary_id: "D04PAN",
    names: ["Perflubron"],
  },
  {
    primary_id: "D04PAU",
    names: ["GSK2245035"],
  },
  {
    primary_id: "D04PAW",
    names: ["Methotrexate Gamma-L-Phenylalaninehydroxamic Acid", "CHEMBL396296"],
  },
  {
    primary_id: "D04PBA",
    names: ["3-(4-Benzyl-Piperazin-1-Yl)-Phenol", "CHEMBL88365", "SCHEMBL13902706", "ZINC26638652", "BDBM50069907"],
  },
  {
    primary_id: "D04PBD",
    names: ["MLS1547"],
  },
  {
    primary_id: "D04PBH",
    names: ["CONTIGOSIDE B"],
  },
  {
    primary_id: "D04PBI",
    names: ["N5-(1-Iminopropyl)-L-Ornithine"],
  },
  {
    primary_id: "D04PBS",
    names: ["MC-3001"],
  },
  {
    primary_id: "D04PBZ",
    names: [
      "4-(3-Methoxyphenylethynyl)-2-Methylthiazole",
      "CHEMBL440220",
      "Thiazole, 4-[2-(3-Methoxyphenyl)Ethynyl]-2-Methyl-",
      "SCHEMBL4150915",
      "BDBM50181739",
    ],
  },
  {
    primary_id: "D04PCA",
    names: ["Pyrazole Derivative 86"],
  },
  {
    primary_id: "D04PCQ",
    names: ["Fungicide"],
  },
  {
    primary_id: "D04PCT",
    names: ["PMID30107136-Compound-Example12"],
  },
  {
    primary_id: "D04PCW",
    names: ["PMID28394193-Compound-Figure3bI"],
  },
  {
    primary_id: "D04PDF",
    names: ["Cyclo(-D-Tyr-L-Arg-L-Arg-L-Nal-L-Ala-)", "CHEMBL387120"],
  },
  {
    primary_id: "D04PDI",
    names: ["CD19-Targeted CAR-T Cells"],
  },
  {
    primary_id: "D04PDM",
    names: ["Benzamide Derivative 2"],
  },
  {
    primary_id: "D04PEI",
    names: [
      "BMS-509744",
      "439575-02-7",
      "BMS 509744",
      "CHEMBL209148",
      "N-(5-((5-(4-Acetylpiperazine-1-Carbonyl)-4-Methoxy-2-Methylphenyl)Thio)Thiazol-2-Yl)-4-(((3,3-Dimethylbutan-2-Yl)Amino)Methyl)Benzamide",
      "N-[5-[5-(4-Acetylpiperazine-1-Carbonyl)-4-Methoxy-2-Methylphenyl]sulfanyl-1,3-Thiazol-2-Yl]-4-[(3,3-Dimethylbutan-2-Ylamino)Methyl]benzamide",
      "GTPL9994",
      "SCHEMBL14460651",
      "KS-00001CVA",
      "CHEBI:91404",
      "MolPort-039-101-283",
      "C32H41N5O4S2",
      "BMS509744",
      "BDBM50186460",
      "3499AH",
      "Compound 3 [PMID:16682193]",
      "AKOS025142036",
      "NCGC00229512-02",
      "NCGC00229512-01",
    ],
  },
  {
    primary_id: "D04PEM",
    names: ["LTERHKILHRLLQEGSPSD"],
  },
  {
    primary_id: "D04PEO",
    names: ["IRT-101"],
  },
  {
    primary_id: "D04PER",
    names: ["XEN-402"],
  },
  {
    primary_id: "D04PFD",
    names: [
      "TriMixDC",
      "Dendritic Cell Vaccine (Melanoma), Vrije Universiteit Brussel",
      "TriMixDC (Melanoma, Intrademal), Universite Libre De Bruxelles",
    ],
  },
  {
    primary_id: "D04PFN",
    names: [
      "[3H]kainate",
      "(2S,3S,4S)-3-(Carboxylatomethyl)-4-(Prop-1-En-2-Yl)Pyrrolidine-2-Carboxylate",
      "GTPL4231",
      "GTPL4085",
      "KAINATE",
    ],
  },
  {
    primary_id: "D04PGB",
    names: [
      "Gpi-1046",
      "186452-09-5",
      "(S)-3-(Pyridin-3-Yl)Propyl 1-(3,3-Dimethyl-2-Oxopentanoyl)Pyrrolidine-2-Carboxylate",
      "CHEMBL6367",
      "L-Proline, 1-(3,3-Dimethyl-1,2-Dioxopentyl)-, 3-(3-Pyridinyl)Propyl Ester",
      "(2S)-[3-PYRIDYL-1-PROPYL]-1-[3,3-DIMETHYL-1,2-DIOXOPENTYL]-2-PYRROLIDINECARBOXYLATE",
      "3-Pyridin-3-Ylpropyl (2S)-1-(3,3-Dimethyl-2-Oxopentanoyl)Pyrrolidine-2-Carboxylate",
      "3-(Pyridin-3-Yl)Propyl (2S)-1-(3,3-Dimethyl-2-Oxopentanoyl)Pyrrolidine-2-Carboxylate",
      "GPI",
      "AC1L9I3R",
      "SCHEMBL4766380",
      "BDBM23334",
      "CTK0I3903",
      "DTXSID00332191",
    ],
  },
  {
    primary_id: "D04PGO",
    names: ["MDL-28133A"],
  },
  {
    primary_id: "D04PGW",
    names: [
      "[(40-OH)MeLeu]4-Cyclosporin A",
      "CHEMBL442519",
      "AC1O51FB",
      "[(4'-OH)MeLeu]4-CsA",
      "SCHEMBL18736971",
      "[.gamma.-Hydroxy-MeLeu]4-CsA",
      "DFZMWWCBMPCKFD-DTLHCQOUSA-N",
      "[gamma-Hydroxy-MeLeu]4 -Ciclosporin",
      "BDBM50136487",
      "[.gamma.-Hydroxy-MeLeu]4-Ciclosporin",
      "[.gamma.-Hydroxy-MeLeu]4-Cyclosporin",
    ],
  },
  {
    primary_id: "D04PHC",
    names: [
      "Epinephrine",
      "ADROP",
      "Adnephrine",
      "Adrenal",
      "Adrenalin",
      "Adrenalina",
      "Adrenaline",
      "Adrenalinum",
      "Adrenamine",
      "Adrenan",
      "Adrenapax",
      "Adrenasol",
      "Adrenatrate",
      "Adrenine",
      "Adrenodis",
      "Adrenohorma",
      "Adrenosan",
      "Adrenutol",
      "Adrin",
      "Adrine",
      "Antiasthmatique",
      "Asthmanefrin",
      "Astmahalin",
      "Astminhal",
      "Balmadren",
      "Bernarenin",
      "Biorenine",
      "Bosmin",
      "Brevirenin",
      "Bronkaid",
      "Chelafrin",
      "Corisol",
      "Drenamist",
      "Dylephrin",
      "Epifrin",
      "Epiglaufrin",
      "Epinefrin",
      "Epinefrina",
      "Epinephran",
      "Epinephrin",
      "Epinephrinum",
      "Epipen",
      "Epirenamine",
      "Epirenan",
      "Epirenin",
      "Epitrate",
      "Eppy",
      "Esphygmogenina",
      "Exadrin",
      "Glaucon",
      "Glaucosan",
      "Glauposine",
      "Glycirenan",
      "Haemostasin",
      "Haemostatin",
      "Hektalin",
      "Hemisine",
      "Hemostasin",
      "Hemostatin",
      "Hypernephrin",
      "Hyporenin",
      "IOP",
      "Intranefrin",
      "Iontocaine",
      "Isoptoepinal",
      "Kidoline",
      "Levoadrenaline",
      "Levoepinephrine",
      "Levorenen",
      "Levorenin",
      "Levorenine",
      "Lyophrin",
      "Metanephrin",
      "Methylaminoethanolcatechol",
      "Methylarterenol",
      "Micronefrin",
      "Micronephrine",
      "Mucidrina",
      "Myosthenine",
      "Mytrate",
      "Nephridine",
      "Nieraline",
      "Paranephrin",
      "Primatene",
      "Racepinephrine",
      "Renagladin",
      "Renaglandin",
      "Renaglandulin",
      "Renaleptine",
      "Renalina",
      "Renoform",
      "Renostypricin",
      "Renostypticin",
      "Renostyptin",
      "Scurenaline",
      "Septocaine",
      "Simplene",
      "Sindrenina",
      "Soladren",
      "Sphygmogenin",
      "Stryptirenal",
      "Styptirenal",
      "Supracapsulin",
      "Supradin",
      "Supranefran",
      "Supranephrane",
      "Supranephrine",
      "Supranol",
      "Suprarenaline",
      "Suprarenin",
      "Suprel",
      "Surenine",
      "Surrenine",
      "Susphrine",
      "Takamina",
      "Takamine",
      "Tokamina",
      "Tonogen",
      "Twinject",
      "Vaponefrin",
      "Vasoconstrictine",
      "Vasoconstrictor",
      "Vasodrine",
      "Vasoton",
      "Vasotonin",
      "ADR ADRENALINE",
      "Adrenalin In Oil",
      "Adrenalina [DCIT]",
      "Asmatane Mist",
      "Asthma Meter Mist",
      "Asthmahaler Mist",
      "Bronkaid Mist",
      "Bronkaid Suspension Mist",
      "Bupivacaine Hcl And Epinephrine",
      "Citanest Forte",
      "EPI E Z PEN JR",
      "EPIPEN E Z PEN",
      "EPIPEN JR",
      "Epi EZ Pen Jr",
      "Epinefrin [Czech]",
      "Epinephrine Hydrochloride",
      "Epipen EZ Pen",
      "Primatene Mist",
      "Racemic Epinephrine",
      "Sympathin I",
      "Adrenalin (TN)",
      "Adrenalin-Medihaler",
      "Adrenaline (JP15)",
      "Adrenaline/Epinephrine",
      "Ana-Guard",
      "Asthma-Nefrin",
      "D-Adrenaline",
      "D-Epifrin",
      "D-Epinephrine",
      "Dyspne-Inhal",
      "Epinefrina [INN-Spanish]",
      "Epinephrine (USP)",
      "Epinephrinum [INN-Latin]",
      "Epipen (TN)",
      "Epipen Auto-Injector",
      "Epipen Jr.",
      "L-Adrenalin",
      "L-Adrenaline",
      "L-Adrenaline Base",
      "L-Epinehphrine",
      "L-Epinephine",
      "L-Epirenamine",
      "L-Methylaminoethanolcatechol",
      "L-Epinephrine",
      "Levo-Methylaminoethanolcatechol",
      "Medihaler-Epi",
      "R-Adrenaline",
      "Sus-Phrine",
      "Twinject 0.15",
      "Twinject 0.3",
      "Twinject 0.30",
      "Epinephrine (USP/INN)",
      "Epinephrine [USAN:INN:JAN]",
      "Epipen Jr. Auto-Injector",
      "L-Epinephrine (Synthetic)",
      "SUS-PHRINE SULFITE-FREE",
      "R-(-)-Epinephrine",
      "L-1-(3,4-Dihydroxyphenyl)-2-Methylaminoethanol",
      "(-)-(R)-Epinephrine",
      "(-)-3,4-Dihydroxy-Alpha-((Methylamino)Methyl)Benzyl Alcohol",
      "(-)-Adrenalin",
      "(-)-Adrenaline",
      "(-)-R-Epinephrine",
      "(R)-(-)-Adnephrine",
      "(R)-(-)-Adrenaline",
      "(R)-(-)-Epinephrine",
      "(R)-(-)-Epirenamine",
      "(R)-4-[1-Hydroxy-2-(Methylamino)Ethyl]-1,2-Benzenediol",
      "(R)-Adrenaline",
      "(R)-Epinephrine",
      "1-1-(3,4-Dihydroxyphenyl)-2-Methylaminoethanol",
      "1-Adrenalin",
      "1-Epinephrine",
      "4-(1-Hydroxy-2-(Methylamino)Ethyl)-1,2-Benzenediol",
      "4-(1-Hydroxy-2-Methylamino-Ethyl)Benzene-1,2-Diol",
      "4-[(1R)-1-Hydroxy-2-(Methylamino)Ethyl]-1,2-Benzenediol",
      "4-[(1R)-1-Hydroxy-2-(Methylamino)Ethyl]benzene-1,2-Diol",
    ],
  },
  {
    primary_id: "D04PHG",
    names: ["2-Methyl-3-Phenethyl-3H-Pyrimidin-4-One", "Compound 2H [PMID: 15863312]"],
  },
  {
    primary_id: "D04PHR",
    names: ["UK-129485"],
  },
  {
    primary_id: "D04PIH",
    names: ["Novaferon"],
  },
  {
    primary_id: "D04PIU",
    names: ["CART-BCMA Cells"],
  },
  {
    primary_id: "D04PJO",
    names: [
      "2-M-Tolyl-2H-Pyrazolo[3,4-C]quinolin-4-Amine",
      "CHEMBL107446",
      "BDBM50091130",
      "2-M-Tolyl-2H-Pyrazolo[3,4-C]quinolin-4-Ylamine",
    ],
  },
  {
    primary_id: "D04PKD",
    names: ["Isosorbide-2-Benzylcarbamate-5-Propionate", "CHEMBL493672"],
  },
  {
    primary_id: "D04PKE",
    names: ["2-Methyl-1-(Pyridin-4-Yl)Pent-1-En-3-One Oxime"],
  },
  {
    primary_id: "D04PKP",
    names: ["3-(1,2-Dihydroacenaphthylen-5-Yl)Pyridine", "Acenaphthene Derivative B", "CHEMBL199683"],
  },
  {
    primary_id: "D04PKQ",
    names: ["Conjugated 3-(Indolyl)-And 3-(Azaindolyl)-4-Arylmaleimide Compound 1"],
  },
  {
    primary_id: "D04PKX",
    names: ["PMID26651364-Compound-125"],
  },
  {
    primary_id: "D04PLB",
    names: ["2-Chloro-4-(O-Tolyloxy)Benzonitrile", "CHEMBL470204", "SCHEMBL4175953", "BDBM50278226"],
  },
  {
    primary_id: "D04PLC",
    names: ["FOV1101"],
  },
  {
    primary_id: "D04PLH",
    names: ["VUF-8507"],
  },
  {
    primary_id: "D04PLI",
    names: ["SR46349B"],
  },
  {
    primary_id: "D04PMA",
    names: [
      "1,1,1-Trifluoro-Tridecan-2-One",
      "1,1,1-Trifluorotridecan-2-One",
      "CHEMBL90308",
      "86571-23-5",
      "CTK2I3319",
      "DTXSID20571888",
      "2-Tridecanone, 1,1,1-Trifluoro-",
    ],
  },
  {
    primary_id: "D04PMM",
    names: [
      "AZD1386",
      "Azd1386",
      "946080-23-5",
      "AZD-1386",
      "KB-270807",
      "AZD 1386",
      "SCHEMBL4257077",
      "GTPL7820",
      "CHEMBL3545039",
      "AZ12457709",
    ],
  },
  {
    primary_id: "D04PMS",
    names: ["HER2 Antigen-Specific Cancer Immunotherapeutic"],
  },
  {
    primary_id: "D04PMX",
    names: [
      "NSC-115890",
      "1,3-Naphthalenediol",
      "132-86-5",
      "Naphthalene-1,3-Diol",
      "1,3-DIHYDROXYNAPHTHALENE",
      "Naphthoresorcinol",
      "Naphthoresoucinol",
      "3-Hydroxybenzocyclohexadien-1-One",
      "Naphthoresorcin",
      "CCRIS 7896",
      "UNII-5X457YEW8Y",
      "NSC 115890",
      "AI3-08780",
      "EINECS 205-079-7",
      "BRN 2044002",
      "5X457YEW8Y",
      "XOOMNEFVDUTJPP-UHFFFAOYSA-N",
      "MFCD00003965",
      "AK-47815",
      "N-2150",
      "1,3-Dihydroxynaphthalene, 99+%",
      "1-Hydroxy-3-Naphthol",
      "ACMC-1BVWB",
      "Naphthalenediol-(1,3)",
      "2,4-Dihydroxynaphthalene",
      "AC1L1RBY",
      "1,3-Dihydroxy-Naphthalene",
      "WLN: L66J BQ DQ",
      "SCHEMBL28681",
      "4-06-00-06543 (Beilst",
    ],
  },
  {
    primary_id: "D04PNC",
    names: [
      "2-Cyclohexyl-N-Phenethyl-Acetamide",
      "CHEMBL193019",
      "AC1LM26R",
      "Oprea1_260865",
      "SCHEMBL12932020",
      "2-Cyclohexyl-N-Phenethylacetamide",
      "MolPort-002-220-431",
      "ZINC858629",
      "STK222764",
      "BDBM50167042",
      "AKOS003337086",
      "MCULE-6211391975",
    ],
  },
  {
    primary_id: "D04POA",
    names: ["Tyr-Pro-L-Phe-Pro-NH2"],
  },
  {
    primary_id: "D04POB",
    names: [
      "ACETYLSULFANILAMIDE",
      "4-Acetamidobenzenesulfonamide",
      "121-61-9",
      "N-(4-Sulfamoylphenyl)Acetamide",
      "Acetylsulfanilamide",
      "Neotherapol",
      "P-Sulfamylacetanilide",
      "N4-ACETYLSULFANILAMIDE",
      "Erytrin",
      "N4-Acetsulfanilamide",
      "4-Acetylaminobenzenesulfonamide",
      "4'-Sulfamylacetanilide",
      "4'-Sulfamoylacetanilide",
      "Sulfanilamide-N4-Acetate",
      "P-Sulfamoylacetanilide",
      "P-Acetamidobenzenesulfonamide",
      "N'-Acetylsulphanilamide",
      "4'-Sulphamoylacetanilide",
      "N-(4-Sulphamoylphenyl)Acetamide",
      "Benzenesulfonamide, P-Acetamido-",
      "P-(Acetylamino)Benzenesulfonamide",
      "Acetanilide, 4'-Sulfamoyl-",
    ],
  },
  {
    primary_id: "D04POR",
    names: ["U73122"],
  },
  {
    primary_id: "D04PPE",
    names: [
      "VE-04051645",
      "Direct Thrombin Inhibitors (Cardiovascular Diseases/Cancer/Neurodegenerative Disease)",
      "Direct Thrombin Inhibitors (Cardiovascular Diseases/Cancer/Neurodegenerative Disease), Verseon",
      "Small Molecule Allosteric Thrombin Inhibitors (Cancer/Neurodegenerative/Cardiovascular Disease), Verseon",
    ],
  },
  {
    primary_id: "D04PPK",
    names: ["CER-522", "ApoA-I Peptide HDL Mimetic (Aortic Valve Stenosis), Cerenis/Montreal Heart Institute"],
  },
  {
    primary_id: "D04PPP",
    names: ["MCL-139"],
  },
  {
    primary_id: "D04PQN",
    names: ["N-(P-Methylbenzyl)-5-Nitroindol-3-Ylglyoxylamide", "CHEMBL223707"],
  },
  {
    primary_id: "D04PQX",
    names: ["6-Methoxy-3-Pyridin-4-Yl-Quinoline", "CHEMBL90780", "6-Methoxy-3-(4-Pyridyl)Quinoline"],
  },
  {
    primary_id: "D04PRM",
    names: ["Parathyroid Hormone (Oral), Nobex"],
  },
  {
    primary_id: "D04PSH",
    names: ["ISIS 222041"],
  },
  {
    primary_id: "D04PSK",
    names: ["N-[5-(Methylthio)-1,3,4-Thiadiazol-2-Yl]sulfamide", "CHEMBL459356"],
  },
  {
    primary_id: "D04PSL",
    names: ["FINROZOLE"],
  },
  {
    primary_id: "D04PTI",
    names: ["WR-289010"],
  },
  {
    primary_id: "D04PUI",
    names: ["BT-062"],
  },
  {
    primary_id: "D04PUK",
    names: ["1-(3,3-Diphenylpropyl)-4-Phenylpiperidin-4-Ol", "CHEMBL230033"],
  },
  {
    primary_id: "D04PUO",
    names: ["OPNT001"],
  },
  {
    primary_id: "D04PVE",
    names: ["PDA-002"],
  },
  {
    primary_id: "D04PVN",
    names: ["SPI-376"],
  },
  {
    primary_id: "D04PWT",
    names: [
      "DP-B99",
      "343340-21-6",
      "222315-88-0",
      "SCHEMBL720046",
      "DP-B 99",
      "DTXSID20187908",
      "DP-BAPTA-99, DP-B99",
      "ZINC162948634",
      "2,2'-(((Ethane-1,2-Diylbis(Oxy))Bis(2,1-Phenylene))Bis((2-(2-(Octyloxy)Ethoxy)-2-Oxoethyl)Azanediyl))Diacetic Acid",
    ],
  },
  {
    primary_id: "D04PWX",
    names: ["Cyclopropylamine Derivative 9"],
  },
  {
    primary_id: "D04PXS",
    names: ["Amitifadine", "EB-1010"],
  },
  {
    primary_id: "D04PYQ",
    names: ["PIPEROXAN"],
  },
  {
    primary_id: "D04PYV",
    names: ["ISIS 32000"],
  },
  {
    primary_id: "D04QAC",
    names: [
      "[3H]GABA",
      "4-Ammoniobutanoate",
      "4-Azaniumylbutanoate",
      "4euo",
      "Gamma-Amino-N-Butyrate",
      "G-Amino-N-Butyric Acid",
      "3ip9",
      "CHEBI:59888",
      "Gamma-Aminobutyric Acid Zwitterion",
      "CJ-05261",
    ],
  },
  {
    primary_id: "D04QAD",
    names: [
      "4-Tert-Butyl-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL293635",
      "4-Tert-Butyl-N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "BAS 03572060",
      "AC1Q1LYP",
      "AC1LGN92",
      "Oprea1_621031",
      "Oprea1_258896",
      "MolPort-001-030-330",
      "ZINC342768",
      "BDBM50101785",
      "STK401915",
      "AKOS000569708",
      "MCULE-2080665457",
      "ST50017828",
      "N-(4-Phenyl-2-Thiazolyl)-4-Tert-Butylbenzamide",
      "AG-690/09665053",
    ],
  },
  {
    primary_id: "D04QAE",
    names: ["CXCR3 Antagonists, Amgen", "CXCR3 Antagonists (Autoimmune Disease), Amgen"],
  },
  {
    primary_id: "D04QAT",
    names: ["4-(P-Tolyloxy)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL465539", "BDBM50248554"],
  },
  {
    primary_id: "D04QBM",
    names: ["L-803,087"],
  },
  {
    primary_id: "D04QBY",
    names: ["PMID29649907-Compound-27"],
  },
  {
    primary_id: "D04QCF",
    names: ["AS-601245"],
  },
  {
    primary_id: "D04QCY",
    names: ["Antineoplaston-A3", "Antineoplaston-A3, Burzynski Institute"],
  },
  {
    primary_id: "D04QDB",
    names: ["ABT-341", "CHEMBL437341"],
  },
  {
    primary_id: "D04QDI",
    names: ["Posintro"],
  },
  {
    primary_id: "D04QEC",
    names: ["Volitinib"],
  },
  {
    primary_id: "D04QEO",
    names: ["KNI-10332"],
  },
  {
    primary_id: "D04QEW",
    names: ["Ac-Cys-Ile-Tyr-Lys-Phe(4-I)-Tyr"],
  },
  {
    primary_id: "D04QFO",
    names: ["Hexahydro-Cyclopenta[b]pyrrol-(2Z)-Ylideneamine", "CHEMBL361419", "BDBM50150921"],
  },
  {
    primary_id: "D04QFU",
    names: ["2-(Cyclohexylthiomethyl)-5-Hydroxy-4H-Pyran-4-One", "CHEMBL1269426"],
  },
  {
    primary_id: "D04QFV",
    names: ["SNX-024"],
  },
  {
    primary_id: "D04QFX",
    names: ["1-TDC", "1-Tetradecanol Complex"],
  },
  {
    primary_id: "D04QFY",
    names: ["GTx-027"],
  },
  {
    primary_id: "D04QGF",
    names: ["PMID30247903-Compound-General Structure8"],
  },
  {
    primary_id: "D04QGH",
    names: ["Piragliatin"],
  },
  {
    primary_id: "D04QGW",
    names: ["Lactam Derivative 5"],
  },
  {
    primary_id: "D04QHB",
    names: [
      "2-Cyclohexylamino-Pyrimidine-4-Carbonitrile",
      "4-Cyano-Pyrimidine, 9",
      "2-(Cyclohexylamino)Pyrimidine-4-Carbonitrile",
      "CHEMBL221804",
      "BDBM19748",
      "927176-96-3",
      "4-Pyrimidinecarbonitrile, 2-(Cyclohexylamino)-",
    ],
  },
  {
    primary_id: "D04QHI",
    names: ["Imidazo[1,2-B]pyridazine Derivative 7"],
  },
  {
    primary_id: "D04QHT",
    names: [
      "EDP-20",
      "Cis-[Pt(II)(NH3)2(MalSAHAH)]",
      "Platinum-Based HDAC Inhibitors (Cancer), Royal College Of Surgeons In Ireland/BioLineRx",
    ],
  },
  {
    primary_id: "D04QIA",
    names: ["Ro-66-1168"],
  },
  {
    primary_id: "D04QIE",
    names: ["K-877"],
  },
  {
    primary_id: "D04QIL",
    names: [
      "IMP-111",
      "Anti-CD44 Monoclonal Antibody (Cancer), Immune Pharmaceuticals",
      "Antibody-Dependent Cellular Phagocytosis Stimulator (Cancer), Immune Pharmaceuticals",
    ],
  },
  {
    primary_id: "D04QJD",
    names: ["Valaciclovir"],
  },
  {
    primary_id: "D04QJK",
    names: ["A-62824", "CHEMBL202864", "SCHEMBL607791", "BDBM50178926", "111279-87-9"],
  },
  {
    primary_id: "D04QJY",
    names: ["KELATORPHAN"],
  },
  {
    primary_id: "D04QKN",
    names: ["K-Ras(G12C) Inhibitor 12"],
  },
  {
    primary_id: "D04QKU",
    names: ["RG-7090"],
  },
  {
    primary_id: "D04QLC",
    names: ["Benzamide Derivative 13"],
  },
  {
    primary_id: "D04QLL",
    names: ["HESC-Derived RPE (MA09-HRPE)"],
  },
  {
    primary_id: "D04QLR",
    names: [
      "Dyclonine",
      "Diclonia",
      "Diclonina",
      "Dyclocaine",
      "Dyclocainum",
      "Dyclonin",
      "Dycloninum",
      "Dyclothane",
      "Tanaclone",
      "Diclonina [INN-Spanish]",
      "Dyclonine (INN)",
      "Dyclonine [INN:BAN]",
      "Dycloninum [INN-Latin]",
      "1-(4-Butoxy-Phenyl)-3-Piperidin-1-Yl-Propan-1-One",
      "1-(4-Butoxyphenyl)-3-(1-Piperidinyl)-1-Propanone",
      "1-(4-Butoxyphenyl)-3-(Piperidin-1-Yl)Propan-1-One",
      "1-(4-Butoxyphenyl)-3-Piperidin-1-Ylpropan-1-One",
      "1-Propanone, 1-(4-Butoxyphenyl)-3-(1-Piperidinyl)-(9CI)",
      "2-(1-Piperidyl)Ethyl P-Butoxyphenyl Ketone",
      "3-Piperidino-4'-Butoxypropiophenone",
      "4'-Butoxy-3-Piperidinopropiophenone",
      "4-Butoxy-Beta-Piperidinopropiophenone",
      "4-N-Butoxy-Beta-(1-Piperidyl)Propiophenone",
    ],
  },
  {
    primary_id: "D04QNI",
    names: ["(O10eq)-Beta-Alanyl-Anhydro-Ryanodine", "CHEMBL28261"],
  },
  {
    primary_id: "D04QNO",
    names: [
      "Symbicort",
      "(22R)-Budesonide",
      "UNII-2HI1006KPH",
      "51333-22-3",
      "51372-29-3",
      "2HI1006KPH",
      "DSSTox_CID_202",
      "DSSTox_RID_75430",
      "DSSTox_GSID_20202",
      "(1~{s},2~{s},4~{r},6~{r},8~{s},9~{s},11~{s},12~{s},13~{r})-9,13-Dimethyl-11-Oxidanyl-8-(2-Oxidanylethanoyl)-6-Propyl-5,7-Dioxapentacyclo[10.8.0.0^{2,9}.0^{4,8}.0^{13,18}]icosa-14,17-Dien-16-One",
      "R-Budesonide",
      "Budesonide-22R",
      "NCGC00016862-01",
      "EINECS 257-161-7",
      "CAS-51333-22-3",
      "BUDESONIDE (11beta,16alpha(R))",
      "SCHEMBL4095",
      "AC1L22VC",
      "CHEMBL2110662",
    ],
  },
  {
    primary_id: "D04QOU",
    names: ["Adenosine-5'-Diphosphate", "ADENOSINE 5", "E0F3E3C0-2894-4499-B7DF-B9DC895D662B"],
  },
  {
    primary_id: "D04QPA",
    names: ["AST-OPC1"],
  },
  {
    primary_id: "D04QPD",
    names: [
      "FV-100",
      "CF-1001",
      "CF-1094",
      "CF-1368",
      "CF-1369",
      "CF-1449",
      "CF-1452",
      "CF-1698",
      "CF-1712",
      "CF-1743",
      "CF-1821",
      "CF-1835",
      "CF-1837",
      "CF-1838",
      "CF-1851",
      "CF-2004",
      "CF-2160",
      "CF-2161",
      "CF-2200",
      "BCNAs (Antiviral), FermaVir Pharmaceuticals",
      "Bicyclic Nucleoside Analogs (VZV Infection), FermaVir",
      "Bicyclic Nucleoside Analogs (VZV Infection), Inhibitex",
      "Antivirals (Nucleoside Derivatives), Welsh School Of Pharmacy/Rega",
      "BCNAs (Antiviral), Rega/Welsh School Of Pharmacy",
      "Bicyclic Nucleoside Analogs (Antiviral), Rega/Welsh School Of Pharmacy",
    ],
  },
  {
    primary_id: "D04QPE",
    names: ["Human Mab"],
  },
  {
    primary_id: "D04QPK",
    names: [
      "3-[4-Fluoro-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 24a",
      "SCHEMBL4221248",
      "SCHEMBL4221241",
      "AC1O705H",
      "CHEMBL366490",
      "BDBM8607",
      "3-[(E)-(4-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D04QQG",
    names: [
      "AUNP-12",
      "Aurigene NP-12",
      "Aurigene-012",
      "Aur-012",
      "GTPL10117",
      "MolPort-044-830-666",
      "S8549",
      "Compound #8 [WO2011161699A3]",
    ],
  },
  {
    primary_id: "D04QQZ",
    names: ["AT132"],
  },
  {
    primary_id: "D04QRG",
    names: ["Antineoplaston A-10", "Atengenal", "Antineoplaston A-10, Burzynski Institute"],
  },
  {
    primary_id: "D04QRM",
    names: ["ME0328"],
  },
  {
    primary_id: "D04QRS",
    names: ["PNU-22394"],
  },
  {
    primary_id: "D04QRX",
    names: ["Thiazole Carboxamide Derivative 27"],
  },
  {
    primary_id: "D04QSH",
    names: [
      "DW-167",
      "Chitosan",
      "Milican",
      "Milican Film",
      "DW167HC",
      "DW-166HC",
      "DW-166HC)",
      "Holmium-166",
      "Holmium-166 (Injectable), Dong Wha",
      "Holmium-166/Chitosan, Dong Wha",
      "Holmium-166 (Topical Patch, Skin Cancer), Dong Wha",
    ],
  },
  {
    primary_id: "D04QSJ",
    names: ["R0-93877"],
  },
  {
    primary_id: "D04QST",
    names: ["Riboflavin"],
  },
  {
    primary_id: "D04QUB",
    names: [
      "12-EPI-SALVINORIN A",
      "12-Epi-Salvinorin A",
      "CHEMBL458235",
      "SCHEMBL10071862",
      "OBSYBRPAKCASQB-KWMFLVSKSA-N",
    ],
  },
  {
    primary_id: "D04QUD",
    names: ["PDE4 Inhibitors, GlaxoSmithKline"],
  },
  {
    primary_id: "D04QUG",
    names: [
      "A-101",
      "AC1L4MEL",
      "AC1Q1Z6J",
      "N-Phenylacetoaminomethylene-DL-P-Nitrophenylalanine",
      "4-Nitro-N-{[(Phenylacetyl)Amino]methyl}-L-Phenylalanine",
      "MALANEHOOMRDSZ-INIZCTEOSA-N",
      "70172-42-8",
      "DL-Phenylalanine, 4-Nitro-N-(((Phenylacetyl)Amino)Methyl)-",
      "(S)-Alpha-[[[(Phenylacetyl)Amino]methyl]amino]-P-Nitrobenzenepropanoic Acid",
      "(2S)-3-(4-Nitrophenyl)-2-[[(2-Phenylacetyl)Amino]methylamino]propanoic Acid",
    ],
  },
  {
    primary_id: "D04QUI",
    names: [
      "BAS-00387275",
      "CHEMBL379308",
      "(4Z)-4-[(6-Bromo-1,3-Benzodioxol-5-Yl)Methylidene]-1-(4-Iodophenyl)Pyrazolidine-3,5-Dione",
      "AC1M44RA",
      "MolPort-001-928-844",
      "ZINC37858290",
      "STL508972",
      "STL430984",
      "BDBM50187579",
      "AKOS000437993",
      "BAS 00387275",
      "4-((6-Bromobenzo[d][1,3]dioxol-5-Yl)Methylene)-1-(4-Iodophenyl)Pyrazolidine-3,5-Dione",
    ],
  },
  {
    primary_id: "D04QUO",
    names: ["KNI-10282"],
  },
  {
    primary_id: "D04QUX",
    names: ["PF-446687"],
  },
  {
    primary_id: "D04QVK",
    names: ["1,2,4-Tri-Substituted Imidazoline Derivative 1"],
  },
  {
    primary_id: "D04QVN",
    names: ["FPL-64170"],
  },
  {
    primary_id: "D04QVX",
    names: ["N-(5-Methylthiazol-2-Yl)Benzamide"],
  },
  {
    primary_id: "D04QWD",
    names: ["Gluconolactone"],
  },
  {
    primary_id: "D04QWE",
    names: ["Levallorphan"],
  },
  {
    primary_id: "D04QWQ",
    names: [
      "VX-509",
      "Decernotinib",
      "Adelatinib",
      "944842-54-0",
      "UNII-MZK2GP0RHK",
      "Decernotinib(VX-509)",
      "VX509",
      "MZK2GP0RHK",
      "VRT-831509",
      "VX 509",
      "(2r)-2-Methyl-2-[[2-(1h-Pyrrolo[2,3-B]pyridin-3-Yl)Pyrimidin-4-Yl]amino]-N-[2,2,2-Tris(Fluoranyl)Ethyl]butanamide",
      "(R)-2-((2-(1H-Pyrrolo[2,3-B]pyridin-3-Yl)Pyrimidin-4-Yl)Amino)-2-Methyl-N-(2,2,2-Trifluoroethyl)Butanamide",
      "Decernotinib [USAN:INN]",
      "VRT 831509",
      "Decernotinib,VX-509",
      "Decernotinib (USAN/INN)",
      "Decernotinib (VX-509)",
      "VX-509 (Decernotinib)",
      "SCHEMBL2630387",
      "GTPL8309",
      "CHEMBL3039513",
      "KS-00001CYK",
    ],
  },
  {
    primary_id: "D04QWV",
    names: ["APX-008"],
  },
  {
    primary_id: "D04QWX",
    names: [
      "2-Methyl-1,2-Di-Pyridin-3-Yl-Propylchloride",
      "CHEMBL300127",
      "87372-70-1",
      "CTK3C4534",
      "DTXSID60658223",
      "BDBM50028167",
      "3,3'-(1-Chloro-2-Methylpropane-1,2-Diyl)Dipyridine",
      "Pyridine, 3,3'-(2-Chloro-1,1-Dimethyl-1,2-Ethanediyl)Bis-",
    ],
  },
  {
    primary_id: "D04QXF",
    names: [
      "Anti-CD3 Monoclonal Antibody",
      "Anti-CD3 Monoclonal Antibody (Oral, Autoimmune/Inflammatory Disease), NasVax",
      "Anti-CD3 Monoclonal Antibody (Oral, Autoimmune/Inflammatory Disease/HCV Infection)",
      "ACD3 MAb (Oral, Autoimmune/Inflammatory Disease/HCV Infection), NasVax/Centocor Ortho Biotech",
      "Anti-CD3 Monoclonal Antibody + Glycolipids (Oral, Autoimmune Disease), Harvard Medical School/Hadassah",
      "Anti-CD3 Monoclonal Antibody + Glycolipids (Oral, Autoimmune/Inflammatory Disease), NasVax",
      "Anti-CD3 Monoclonal Antibody (Oral, Autoimmune/Inflammatory Disease/HCV Infection), NasVax/Centocor Ortho Biotech",
    ],
  },
  {
    primary_id: "D04QXG",
    names: ["SR 120819A"],
  },
  {
    primary_id: "D04QXK",
    names: ["KY-62"],
  },
  {
    primary_id: "D04QXM",
    names: ["Tyr-D-Ala-Phe-Asp-Val-Val-Thr[Beta-D-Glc]-Gly-NH2"],
  },
  {
    primary_id: "D04QXW",
    names: ["ARC-069"],
  },
  {
    primary_id: "D04QYN",
    names: [
      "5-(Pyridin-4-Yl)-1,3,4-Oxadiazole-2(3H)-Thione",
      "15264-63-8",
      "5-(4-Pyridyl)-1,3,4-Oxadiazole-2-Thiol",
      "5-(Pyridin-4-Yl)-1,3,4-Oxadiazole-2-Thiol",
      "5-Pyridin-4-Yl-[1,3,4]oxadiazole-2-Thiol",
      "CHEMBL571513",
      "5-Pyridin-4-Yl-1,3,4-Oxadiazole-2-Thiol",
      "5-(4-Pyridinyl)-1,3,4-Oxadiazole-2-Thiol",
      "TXCXZVFDWQYTIC-UHFFFAOYSA-N",
      "1,3,4-Oxadiazole-2(3H)-Thione,5-(4-Pyridinyl)-",
      "1,3,4-Oxadiazole-2-Thiol, 5-(4-Pyridinyl)-",
      "ZERO/005315",
      "AC1LGNJA",
      "ChemDiv2_000174",
      "AC1Q7F3J",
      "Oprea1_812023",
      "Oprea1_290121",
      "SCHEMBL987340",
      "SCHEMBL17065364",
      "CTK4C7526",
    ],
  },
  {
    primary_id: "D04QYS",
    names: ["SNA-120"],
  },
  {
    primary_id: "D04QYU",
    names: ["ISIS 29200"],
  },
  {
    primary_id: "D04QZD",
    names: ["Eslicarbazepine", "Aptiom", "BIA 2-093", "Exalief", "SEP-0002093", "Sep-93", "Stedesa", "Zebinix"],
  },
  {
    primary_id: "D04QZF",
    names: ["SK554"],
  },
  {
    primary_id: "D04QZI",
    names: [
      "Retosiban",
      "820957-38-8",
      "UNII-GIE06H28OX",
      "GSK221149A",
      "GSK-221149A",
      "GIE06H28OX",
      "CHEMBL429736",
      "Retosiban [USAN:INN]",
      "Retosiban (USAN)",
      "GSK 221149A",
      "SCHEMBL230301",
      "GTPL8403",
      "DTXSID00231559",
      "PLVGDGRBPMVYPB-FDUHJNRSSA-N",
      "ZINC6718496",
      "BDBM50372608",
      "GSK-221,149-A",
      "SB16762",
      "DB11818",
      "HY-14778",
      "CS-0003555",
      "J3.542.064I",
      "D08986",
      "2,5-Piperazinedione, 3-(2,3-Dihydro-1H-Inden-2-Yl)-1-[(1R)-1-(2-Methyl-4-Oxazolyl)-2-(4-Morpholinyl)-2-Oxoethyl]-6-[(1S)-1-Methylpropyl]-, (3R,6R)-",
    ],
  },
  {
    primary_id: "D04QZR",
    names: ["Diketonitrile"],
  },
  {
    primary_id: "D04QZV",
    names: ["BHQ-880", "BHQ-880A", "Anti-DKK1 Monoclonal Antibody (Multiple Myeloma), Novartis"],
  },
  {
    primary_id: "D04RAL",
    names: ["Emplicitielotuzumab"],
  },
  {
    primary_id: "D04RAT",
    names: [
      "BIIR-777",
      "AMPA Receptor Modulator (Memory/Neurological Disorders)",
      "AMPA Receptor Modulator (Memory/Neurological Disorders), Boehringer Ingelheim",
    ],
  },
  {
    primary_id: "D04RAW",
    names: ["2-Morpholin-4-Yl-8-Naphthalen-2-Ylchromen-4-One", "CHEMBL200340"],
  },
  {
    primary_id: "D04RBG",
    names: ["GW-8510"],
  },
  {
    primary_id: "D04RBQ",
    names: [
      "1,2,3,4-Tetrahydro-Benzo[h]isoquinolin-8-Ol",
      "CHEMBL130744",
      "BDBM50061318",
      "1,2,3,4-Tetrahydrobenz[h]isoquinolin-8-Ol",
    ],
  },
  {
    primary_id: "D04RBU",
    names: [
      "D-366",
      "CHEMBL569746",
      "BDBM50303793",
      "(S)-(4-(2-((5-Hydroxy-1,2,3,4-Tetrahydronaphthalen-2-Yl)(Propyl)Amino)Ethyl)Piperazin-1-Yl)(1H-Indol-2-Yl)Methanone",
    ],
  },
  {
    primary_id: "D04RBY",
    names: ["EX-201"],
  },
  {
    primary_id: "D04RCG",
    names: ["MEN-10627"],
  },
  {
    primary_id: "D04RCR",
    names: [
      "3-Phenylprop-1-Enylboronic Acid",
      "129423-29-6",
      "Trans-3-Phenylpropen-1-Yl-Boronic Acid",
      "Trans-3-Phenyl-1-Propen-1-Ylboronic Acid",
      "(3-Phenylprop-1-En-1-Yl)Boronic Acid",
      "CHEMBL539140",
      "Boronic Acid, (3-Phenyl-1-Propenyl)-, (E)-",
      "SCHEMBL2207331",
      "3-Phenyl-1-Propenylboronic Acid",
      "GMGWFDHLFMBIDS-XBXARRHUSA-N",
      "214907-30-9",
      "BDBM50294581",
      "(E)-3-Phenylprop-1-Enylboronic Acid",
      "Trans-3-Phenylpropen-1-Ylboronic Acid",
      "(E)-3-Phenyl-1-Propenylboronic Acid",
      "ZINC169877170",
      "AKOS015840563",
      "(E)-3-Phenylpropen-1-Yl-Boronic Acid",
      "RTR-004066",
    ],
  },
  {
    primary_id: "D04RCT",
    names: ["CHF-3381"],
  },
  {
    primary_id: "D04RCZ",
    names: ["Immunosuppressant Synthetic Peptide"],
  },
  {
    primary_id: "D04RDR",
    names: ["Quinoline Derivative 15"],
  },
  {
    primary_id: "D04RDT",
    names: ["CEP-431", "Clipsin Inhibitor, Cephalon"],
  },
  {
    primary_id: "D04RDW",
    names: ["CAR-T Cells Targeting Lewis-Y"],
  },
  {
    primary_id: "D04REL",
    names: ["ISIS 29477"],
  },
  {
    primary_id: "D04REV",
    names: [
      "CDX-1307",
      "BHCG-VAC",
      "BetaHCG-VAC",
      "MDX-1307",
      "Dendritic Cell Targeted HCG-Beta Vaccine, Medarex",
      "B11-HCG-Beta, Medarex",
      "Antigen-Presenting Cell-Targeted Vaccine (Injectable, Cancer), Celldex Therapeutics",
      "Antigen-Presenting Cell-Targeted Vaccine (Intradermal, Cancer), Celldex Therapeutics",
      "Antigen-Presenting Cell-Targeted Vaccine (Iv, Cancer), Celldex Therapeutics",
    ],
  },
  {
    primary_id: "D04REX",
    names: [
      "5-[5-Fluoro-Indan-(1Z)-Ylidenemethyl]-Pyrimidine",
      "(5-Pyrimidylmethylene)Indane 28b",
      "SCHEMBL4225541",
      "AC1O706V",
      "CHEMBL364956",
      "BDBM8634",
      "5-[(Z)-(5-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyrimidine",
    ],
  },
  {
    primary_id: "D04RFE",
    names: ["AnervaX.DB", "DM-DiavaX", "AnervaX (Diabetes), Corixa"],
  },
  {
    primary_id: "D04RFT",
    names: ["Protein Subunit Vaccine, Novartis"],
  },
  {
    primary_id: "D04RFX",
    names: ["LFn-P24"],
  },
  {
    primary_id: "D04RGA",
    names: [
      "Dinoprost Tromethamine",
      "Dinolytic",
      "Ensaprost",
      "Lutalyse",
      "Prostamate",
      "Zinoprost",
      "Panacelan F Tromethamine Salt",
      "Pronalgon F",
      "Prostalmon F",
      "PGF2alpha THAM",
      "PGF2alpha Tromethamine",
      "PROSTAGLANDIN F2ALPHA TRIS SALT",
      "Prostaglandin F2a Tromethamine",
      "Prostaglandin F2alpha Tham",
      "Prostaglandin F2alpha Tromethamine",
      "Prostin F2 Alpha",
      "U 14585",
      "Dinoprost Tromethamine [USAN:JAN]",
      "Dinoprost, Trometamol Salt",
      "Enzaprost F Compd. With Trisamine",
      "Lutalyse (Veterinary)",
      "PGF2-Alpha Tham",
      "PGF2-Alpha Tris Salt",
      "PGF2-Alpha Tromethamine",
      "PGF2-Alpha Tromethamine Salt",
      "PGF2alpha-Tris",
      "Prostaglandin F(Sub 2a) Tromethamine",
      "Prostaglandin F-2alpha Tromethamine Salt",
      "Prostaglandin F2-Alpha THAM",
      "Prostaglandin F2-Alpha THAM Salt",
      "Prostaglandin F2-Alpha Tromethamine",
      "Prostin F2 Alpha (TN)",
      "THAM PGF2-Alpha",
      "Tromethamine Prostaglandin F2-Alpha",
      "U-14583E",
      "Dinoprost Tromethamine (JAN/USP)",
      "Protaglandin F2-Alpha-Tham Salt",
      "U-14,583E",
      "Prosta-5,13-Dien-1-Oic Acid, (5Z,9-Alpha,11-Alpha,13E,15S)-9,11,15-Trihydroxy-, Compd. With Trimethylolaminomethane",
      "Prosta-5,13-Dien-1-Oic Acid, (5Z,9-Alpha,11-Alpha,13E,15S)-9,11,15-Trihydroxy-,compd. With Trimethylolaminomethane",
      "Prosta-5,13-Dien-1-Oic Acid, 9,11,15-Trihydroxy-, (5Z,9alpha,11alpha,13E,15S)-, Compd. With 2-Amino-2-(Hydroxymethyl)-1,3-Propanediol (1:1)",
      "(5Z,9alpha,11alpha,13E,15S)-9,11,15-Trihydroxyprosta-5,13-Dienoic Acid Tris Salt",
      "(E,Z)-(1R,2R,3R,5S)-7-(3,5-Dihydroxy-2-((3S)-(3-Hydroxy-1-Octenyl))Cyclopentyl)-5-Heptenoic Acid Compound With 2-Amino-2-(Hydroxymethyl)-1,3-Propanediol (1:1)",
      "(Z)-7-[(1R,2R,3R,5S)-3,5-Dihydroxy-2-[(E,3S)-3-Hydroxyoct-1-Enyl]cyclopentyl]hept-5-Enoic Acid",
      "1,3-Propanediol, 2-Amino-2-(Hydroxymethyl)-, (5Z,9alpha,11alpha,13E,15S)-9,11,15-Trihydroxyprosta-5,13-Dien-1-Oate (Salt)",
      "5-Heptenoic Acid, 7-(3,5-Dihydroxy-2-(3-Hydroxy-1-Octenyl)Cyclopentyl)-, Tham",
      "7-(3,5-Dihydroxy-2-(3-Hydroxy-1-Octenyl)Cyclopentyl)-5-Heptenoic Acid, Tromethamine Salt",
    ],
  },
  {
    primary_id: "D04RGM",
    names: ["ISIS 107676"],
  },
  {
    primary_id: "D04RGN",
    names: ["SYMBIOPOLYOL"],
  },
  {
    primary_id: "D04RHI",
    names: ["MRS1086"],
  },
  {
    primary_id: "D04RHJ",
    names: ["RG7116"],
  },
  {
    primary_id: "D04RHU",
    names: [
      "DANA",
      "(2R,3R,4S)-3-Acetamido-4-Hydroxy-2-[(1S,2R)-1,2,3-Trihydroxypropyl]-3,4-Dihydro-2H-Pyran-6-Carboxylic Acid",
      "AC1LAOXY",
      "(4S,5R,6R)-5-Acetylamino-4-Hydroxy-6-((1S,2R)-1,2,3-Trihydroxy-Propyl)-5,6-Dihydro-4H-Pyran-2-Carboxylic Acid",
      "(4S)-4alpha-Hydroxy-5beta-(Acetylamino)-6alpha-[(1S,2R)-1,2,3-Trihydroxypropyl]-5,6-Dihydro-4H-Pyran-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D04RJM",
    names: ["DW-941"],
  },
  {
    primary_id: "D04RKA",
    names: ["PIM Protein Kinase Inhibitors"],
  },
  {
    primary_id: "D04RKH",
    names: [
      "APH-0911",
      "Docetaxel Prodrug (Oral Nanoparticle Formulation), Aphios",
      "Paclitaxel Prodrug (Oral Formulation), Aphios",
      "Docetaxel Prodrug (Water-Soluble), Aphios",
      "Paclitaxel Prodrug (Water-Soluble), Aphios",
      "10alpha-GAG-Docetaxel",
    ],
  },
  {
    primary_id: "D04RKW",
    names: ["7-Methylxanthine", "Adenosine Receptor Antagonist (Myopia), Trier", "7-MX"],
  },
  {
    primary_id: "D04RLB",
    names: ["HPIV-1 Vaccine"],
  },
  {
    primary_id: "D04RLX",
    names: ["Tariquidar"],
  },
  {
    primary_id: "D04RLY",
    names: [
      "Vindesine",
      "DAVA",
      "Vindesin",
      "Vindesina",
      "Vindesinum",
      "Desacetylvinblastine Amide",
      "Lilly 112531",
      "Vindesina [INN-Spanish]",
      "Vindesinum [INN-Latin]",
      "Vindesine (USAN/INN)",
      "Vindesine [USAN:BAN:INN]",
      "Vindesine [USAN:INN:BAN]",
      "3-(Aminocarbonyl)-O(Sup 4)-Deacetyl-3-De(Methoxycarbonyl)Vincaleukoblastine",
      "3-(Aminocarbonyl)-O(4)-Deacetyl-3-De(Methoxycarbonyl)Vincaleukoblastine",
      "3-Carbamoyl-4-Deacetyl-3-De(Methoxycarbonyl)Vincaleukoblastine",
      "3-Carbamoyl-O(4)-Deacetyl-3-De(Methoxycarbonyl)Vincaleukoblastine",
      "Methyl (5S,7S,9S)-9-[(2b,3b,4b,5a,12b,19a)-3-Carbamoyl-3,4-Dihydroxy-16-Methoxy-1-Methyl-6,7-Didehydroaspidospermidin-15-Yl]-5-Ethyl-5-Hydroxy-1,4,5,6,7,8,9,10-Octahydro-2H-3,7-Methanoazacycloundecino[5,4-B]indole-9-Carboxylate",
      "Methyl (5S,7S,9S)-9-[(2beta,3beta,4beta,5alpha,12beta,19alpha)-3-Carbamoyl-3,4-Dihydroxy-16-Methoxy-1-Methyl-6,7-Didehydroaspidospermidin-15-Yl]-5-Ethyl-5-Hydroxy-1,4,5,6,7,8,9,10-Octahydro-2H-3,7-Methanoazacycloundecino[5,4-B]indole-9-Carboxylate",
    ],
  },
  {
    primary_id: "D04RMM",
    names: ["Clazakizumab"],
  },
  {
    primary_id: "D04RMP",
    names: ["Co-111732", "Co-122016"],
  },
  {
    primary_id: "D04RNG",
    names: ["CAR-T Cells Recognizing EpCAM"],
  },
  {
    primary_id: "D04RNN",
    names: ["SUN-101"],
  },
  {
    primary_id: "D04RNU",
    names: [
      "6-Benzyloxy-5-Nitroso-Pyrimidine-2,4-Diamine",
      "101724-61-2",
      "2,4-Pyrimidinediamine, 5-Nitroso-6-(Phenylmethoxy)-",
      "CHEMBL71484",
      "6-(Benzyloxy)-5-Nitrosopyrimidine-2,4-Diamine",
      "Pyrimidine Deriv. 1g",
      "NU 6039",
      "AC1L48AH",
      "5-Nitroso-6-Phenylmethoxypyrimidine-2,4-Diamine",
      "BDBM5573",
      "SCHEMBL2054835",
      "DTXSID50144145",
      "MBQPVHNJEDDVCF-UHFFFAOYSA-N",
      "ZINC6096446",
      "AKOS028111161",
      "2,4-Diamino-6-Benzyloxy-5-Nitrosopyrimidine",
      "2,6-Diamino-4-Benzyloxy-5-Nitrosopyrimidine",
      "2,4-Diamino-5-Nitroso-6-Benzyloxypyrimidine",
    ],
  },
  {
    primary_id: "D04RPI",
    names: ["Dmt-Pro-Trp-D-2-Nal-NH2", "CHEMBL218433"],
  },
  {
    primary_id: "D04RQG",
    names: [
      "1-Benzyl-1,2-Dihydro-Indazol-3-One",
      "1-Benzyl-3-Hydroxy-1H-Indazole",
      "2215-63-6",
      "1-Benzyl-1H-Indazol-3-Ol",
      "1-Benzyl-1,2-Dihydro-3H-Indazol-3-One",
      "1-Benzyl-2H-Indazol-3-One",
      "1-Benzylindazol-3-Ol",
      "CHEMBL8612",
      "SXPJFDSMKWLOAB-UHFFFAOYSA-N",
      "3H-Indazol-3-One, 1,2-Dihydro-1-(Phenylmethyl)-",
      "1-Benzyl-2,3-Dihydroindazol-3-One",
      "CBMicro_007258",
      "EINECS 218-680-4",
      "5-Hydroxybenzydamine",
      "AC1Q6GZH",
      "ACMC-1CR7T",
      "ChemDiv2_000492",
      "1-Benzyl-3-Hydroxyindazole",
      "AC1Q6K7E",
      "AC1L2O7R",
      "Oprea1_591436",
      "Oprea1_122894",
      "MLS001211149",
      "SCHEMBL376005",
    ],
  },
  {
    primary_id: "D04RQU",
    names: ["8-Methyl-4H,7H-Indolo[6,5,4-Cd]indol-5-One", "CHEMBL388936"],
  },
  {
    primary_id: "D04RRZ",
    names: ["MEDI4736"],
  },
  {
    primary_id: "D04RSC",
    names: ["OM00-3"],
  },
  {
    primary_id: "D04RSI",
    names: ["FAS1"],
  },
  {
    primary_id: "D04RSR",
    names: [
      "Crolibulin",
      "1000852-17-4",
      "UNII-9ENT43KY91",
      "9ENT43KY91",
      "EPC2407",
      "4H-1-Benzopyran-3-Carbonitrile, 2,7,8-Triamino-4-(3-Bromo-4,5-Dimethoxyphenyl)-, (4R)-;4H-1-Benzopyran-3-Carbonitrile, 2,7,8-Triamino-4-(3-Bromo-4,5-Dimethoxyphenyl)-, (4R)-",
      "Crolibulin [USAN:INN]",
      "EPC 2407",
      "Crolibulin (USAN)",
      "Crinobulin",
      "SCHEMBL1750575",
      "CHEMBL2103852",
      "DTXSID10142925",
      "JXONINOYTKKXQQ-CQSZACIVSA-N",
      "EX-A2452",
      "ZINC38153934",
      "SB17203",
      "DB12925",
      "AJ-94587",
      "HY-13603",
      "CS-0007407",
      "J3.648.108K",
      "D09882",
    ],
  },
  {
    primary_id: "D04RSZ",
    names: ["GI-13000"],
  },
  {
    primary_id: "D04RTY",
    names: ["N-Phenyl-2,3,4-Trihydroxy-5-Benzyl-Benzamide", "CHEMBL214965", "SCHEMBL15313847"],
  },
  {
    primary_id: "D04RUA",
    names: ["WRR-276"],
  },
  {
    primary_id: "D04RUF",
    names: ["2-N-Ethylamino-1-(4-Ethylthiophenyl)Butane", "CHEMBL1077884"],
  },
  {
    primary_id: "D04RUP",
    names: ["SDZ-NKT-343"],
  },
  {
    primary_id: "D04RUS",
    names: ["KNI-10074"],
  },
  {
    primary_id: "D04RVA",
    names: ["3-Demethoxy-3-D-Lyxopyranosylaminothiocolchicine", "CHEMBL215655"],
  },
  {
    primary_id: "D04RVW",
    names: ["2-Phenyl-5-(2-Methylthiazol-4ylethynyl)Pyridine", "CHEMBL184995", "SCHEMBL7419236", "BDBM50149801"],
  },
  {
    primary_id: "D04RWF",
    names: ["Dual Antigen Vaccine, Aparna Biosciences", "Dual Antigen Vaccine (Nanoparticle, Bacillus Infection)"],
  },
  {
    primary_id: "D04RWV",
    names: ["INS1009"],
  },
  {
    primary_id: "D04RXX",
    names: ["N-Acylpiperidine Ether Derivative 2"],
  },
  {
    primary_id: "D04RYE",
    names: ["PMID20346665C27"],
  },
  {
    primary_id: "D04RYU",
    names: ["Digitalin"],
  },
  {
    primary_id: "D04RYY",
    names: [
      "Maltose",
      "Beta-Maltose",
      "Maltobiose",
      "Maltose, Pure",
      "Advanctose 100",
      "4-O-Alpha-D-Glucopyranosyl-Beta-D-Glucopyranose",
      "Cextromaltose",
      "Maltodiose",
      "Finetose",
      "Sunmalt",
      "Malt Sugar",
      "Finetose F",
      "Sunmalt S",
      "UNII-R4B6462NGR",
      "D-Glucose, 4-O-Alpha-D-Glucopyranosyl-",
      "AI3-09089",
      "EINECS 200-716-5",
      "BRN 0093798",
      "4-O-Alpha-D-Glucopyranosyl-D-Glucose",
      "R4B6462NGR",
      "4-(Alpha-D-Glucosido)-D-Glucose",
      "CHEBI:18147",
      "Beta-D-Cellobiose",
      "GUBGYTABKSRVRQ-QUYVBRFLSA-N",
      "Beta-D-Glucopyranose, 4-O-Alpha-D-Glucopyranosyl-",
    ],
  },
  {
    primary_id: "D04RZD",
    names: [
      "Interferon Alfa-2b, Recombinant",
      "Alpharona (TN)",
      "Genferon (TN)",
      "Gerpferon (TN)",
      "Grippferon (TN)",
      "Infagel (TN)",
      "Intron A (TN)",
      "Kipferon (TN)",
      "Realderon (TN)",
      "Recolin (TN)",
      "Teva (TN)",
      "Viferon (TN)",
    ],
  },
  {
    primary_id: "D04RZE",
    names: ["FluLaval FluLaval"],
  },
  {
    primary_id: "D04RZZ",
    names: ["A-5021", "(-)-9-[1(S),2(R)-Bis(Hydroxymethyl)Cyclopropan-1-Ylmethyl]guanine", "145512-85-2"],
  },
  {
    primary_id: "D04SAO",
    names: ["GW7604"],
  },
  {
    primary_id: "D04SBH",
    names: ["Zamifenacin"],
  },
  {
    primary_id: "D04SBI",
    names: ["H.P. Acthar Gel (Repository Corticotropin Injection) Gel"],
  },
  {
    primary_id: "D04SBW",
    names: ["Hexavalent Conjugate Vaccine"],
  },
  {
    primary_id: "D04SBX",
    names: ["Temafloxacin"],
  },
  {
    primary_id: "D04SCD",
    names: ["6-Chloro-4-Morpholin-4-Yl-Chromen-2-One", "CHEMBL179591"],
  },
  {
    primary_id: "D04SCQ",
    names: [
      "4,4-Diphenylbutan-1-Amine",
      "4,4-Diphenylbutylamine",
      "36765-74-9",
      "EINECS 253-194-6",
      "Diphenylbutylamine",
      "AC1MI1YM",
      "Delta-Phenylbenzenebutanamine",
      "Benzenebutanamine, D-Phenyl-",
      "SCHEMBL157808",
      "Diphenylalkylamine (DPA), 6a",
      "CHEMBL593734",
      "CTK4H7118",
      "BDBM35935",
      "DTXSID10190229",
      "MAAQGZFMFXQVOG-UHFFFAOYSA-N",
      "AKOS030583685",
    ],
  },
  {
    primary_id: "D04SDD",
    names: ["N6-(3-Iodobenzyl)-2'-O-Methyladenosine", "CHEMBL428377", "BDBM50215115"],
  },
  {
    primary_id: "D04SDN",
    names: [
      "RWJ-3981",
      "5-Chloro-4-Phenyl-3H-1,2-Dithiol-3-One",
      "Hercules 3944",
      "UNII-J77BU43MZU",
      "2425-05-0",
      "Hercules 3944X",
      "3H-1,2-Dithiol-3-One, 5-Chloro-4-Phenyl-",
      "J77BU43MZU",
      "He 3944",
      "NSC29804",
      "5-Chloro-4-Phenyldithiol-3-One",
      "NSC 29804",
      "5-CHLORO-4-PHENYL-1,2-DITHIOLE-3-ONE",
      "RWJ-3981 (Compound 2)",
      "SCHEMBL6064553",
      "3H-1, 5-Chloro-4-Phenyl-",
      "DTXSID8041537",
      "BDBM119067",
      "5-Chloro-4-Phenyl-Dithiol-3-One",
      "ZINC1655991",
      "AC1L2978",
      "NSC-29804",
      "5-Chloro-4-Phenyl-[1,2]-Dithiol-3-One",
      "H 3944",
    ],
  },
  {
    primary_id: "D04SEJ",
    names: ["IW-9179"],
  },
  {
    primary_id: "D04SEX",
    names: [
      "R,R-THC",
      "(R,R)-THC",
      "138090-06-9",
      "(R,R)-Cis-Diethyl Tetrahydro-2,8-Chrysenediol",
      "CHEMBL282489",
      "221368-54-3",
      "(5R,11R)-5,11-Diethyl-5,6,11,12-Tetrahydrochrysene-2,8-Diol",
      "(R,R)-5,11-DIETHYL-5,6,11,12-TETRAHYDRO-2,8-CHRYSENEDIOL",
      "(R,R)-Cis-Diethyltetrahydro-2,8-Chrysenediol",
      "(R,R)-5,11-CIS-DIETHYL-5,6,11,12-TETRAHYDROCHRYSENE-2,8-DIOL",
      "Lopac-D-8690",
      "AC1L9K5Y",
      "Lopac0_000463",
      "MLS002153151",
      "BIDD:ER0043",
      "GTPL2822",
      "DTXSID6040749",
      "SCHEMBL13352540",
      "CTK4E8744",
      "MolPort-003-941-174",
      "HMS3268D03",
      "HMS3261M08",
      "HMS2232C18",
      "ZINC3940885",
      "Tox21_500463",
    ],
  },
  {
    primary_id: "D04SFF",
    names: ["Peptide Aldehydes"],
  },
  {
    primary_id: "D04SFH",
    names: ["Medrysone"],
  },
  {
    primary_id: "D04SFK",
    names: ["H-Tyr-Tic-Phe-Phe-OH"],
  },
  {
    primary_id: "D04SFM",
    names: ["R59949"],
  },
  {
    primary_id: "D04SFN",
    names: ["Genz-682452"],
  },
  {
    primary_id: "D04SFR",
    names: ["RPI-78"],
  },
  {
    primary_id: "D04SHH",
    names: ["TC-6499-12"],
  },
  {
    primary_id: "D04SHK",
    names: ["Isothiazolidine Derivative 1"],
  },
  {
    primary_id: "D04SHO",
    names: ["NMI-1182"],
  },
  {
    primary_id: "D04SHY",
    names: ["Rac-4'-(1-Imidazol-1-Yl-Propyl)-Biphenyl-3,5-Diol", "CHEMBL565098", "BDBM50295626"],
  },
  {
    primary_id: "D04SIO",
    names: ["NGD-91-1", "Anxiolytics, Neurogen", "CP-143867"],
  },
  {
    primary_id: "D04SIT",
    names: ["1-(4-Bromo-2,5-Difluorophenyl)-2-Aminopropane", "CHEMBL509461"],
  },
  {
    primary_id: "D04SIU",
    names: [
      "MCD-386",
      "CDD-0102",
      "CDD-0262",
      "CDD-0264",
      "CDD-102",
      "MCD-386 Forte",
      "MCD-386 Transderm",
      "MCD-386CR",
      "MCD-386 Forte/Transderm",
      "Tetrahydropyrimidine Muscarinic M1 Agonists (Alzeimer's Disease), Cognitive Pharmaceuticals",
      "Tetrahydropyrimidine Muscarinic M1 Agonists (Alzeimer's Disease), University Of Toledo",
      "Tetrahydropyrimidine MuscarinicM1 Agonists (Alzheimer's Disease), Mithridion",
      "MCD-386 (Oral Controlled Release, CNS Disorders), Mithridion",
      "MCD-386 (High Dose Transdermal, Alzheimer's Disease/Schizophrenia), Mithridion",
      "MCD-386 (High Dose, Alzheimer's Disease/Schizophrenia), Mithridion",
      "MCD-386 (Transdermal, Alzheimer's Disease/Schizophrenia), Mithridion",
    ],
  },
  {
    primary_id: "D04SJC",
    names: ["6-Chloro-N-(3-Morpholinopropyl)Nicotinamide", "CHEMBL610499", "BDBM50307201"],
  },
  {
    primary_id: "D04SJT",
    names: ["MB-11040"],
  },
  {
    primary_id: "D04SKM",
    names: ["CART-10 Cells"],
  },
  {
    primary_id: "D04SKW",
    names: ["2,7-Bis[3-(Ethylamino)Propionamido]anthraquinone", "CHEMBL481161", "SCHEMBL4291487"],
  },
  {
    primary_id: "D04SLF",
    names: ["Bevacizumab + Trastuzumab"],
  },
  {
    primary_id: "D04SLM",
    names: ["Ovalicin"],
  },
  {
    primary_id: "D04SMQ",
    names: [
      "(S)-WILLARDIINE",
      "Willardiine",
      "S(-)-Willardiine",
      "21416-43-3",
      "3-(Uracil-1-Yl)-L-Alanine",
      "(-)-Willardiine",
      "CHEMBL122005",
      "CHEBI:15851",
      "3-(2,4-Dioxo-3,4-Dihydropyrimidin-1(2H)-Yl)-L-Alanine",
      "HWD",
      "2-AMINO-3-(2,4-DIOXO-3,4-DIHYDRO-2H-PYRIMIDIN-1-YL)-PROPIONIC ACID",
      "1mqj",
      "3-(2,4-Dioxo-3,4-Dihydropyrimidin-1(2H)-Yl)Alanine",
      "AC1L98KY",
      "Lopac0_001219",
      "SCHEMBL401191",
      "SCHEMBL13319907",
      "BDBM17661",
      "CTK5H8581",
      "MolPort-003-959-938",
      "ZINC901774",
      "96108-98-4",
      "2990AH",
      "AKOS006281897",
      "DB04129",
      "CCG-205293",
      "CS-3618",
      "NCGC00094464-03",
      "HY-12499",
      "EU-0101219",
      "C03584",
    ],
  },
  {
    primary_id: "D04SNH",
    names: ["1-Benzyl-1,4-Dihydro-4-Oxo-3-Pyridinesulfonamide", "CHEMBL1240573", "182556-28-1"],
  },
  {
    primary_id: "D04SNL",
    names: ["Grippol Plus", "Egg-Based Adjuvanted Seasonal Influenza Vaccine, Solvay"],
  },
  {
    primary_id: "D04SOA",
    names: ["H1N1 Pandemic Influenza A Vaccine"],
  },
  {
    primary_id: "D04SOS",
    names: ["BMS-181168", "BMY-21502"],
  },
  {
    primary_id: "D04SOU",
    names: ["MK-4074"],
  },
  {
    primary_id: "D04SPJ",
    names: ["AZD7624"],
  },
  {
    primary_id: "D04SPK",
    names: [
      "Seratrodast",
      "Bronica",
      "A 73001",
      "AA 2414",
      "ABT 001",
      "Abbott 73001",
      "A-73001",
      "AA-2414",
      "ABT-001",
      "Abbott-73001",
      "Bronica (TN)",
      "Seratrodast [USAN:INN]",
      "Seratrodast (JAN/USAN/INN)",
      "(+-)-2,4,5-Trimethyl-3,6-Dioxo-Zeta-Phenyl-1,4-Cyclohexadiene-1-Heptanoic Acid",
      "(+-)-7-(3,5,6-Trimethyl-1,4-Benzoquinon-2-Yl)-7-Phenylheptanoic Acid",
      "7-(3,5,6-Trimethyl-1,4-Benzoquinon-2-Yl)-7-Phenylheptanoic Acid",
      "7-Phenyl-7-(2,4,5-Trimethyl-3,6-Dioxocyclohexa-1,4-Dien-1-Yl)Heptanoic Acid",
    ],
  },
  {
    primary_id: "D04SPO",
    names: [
      "E-6-O-P-Methoxycinnamoyl Scandoside Methyl Ester",
      "CHEMBL447012",
      "BDBM50305815",
      "6-O-E-P-Methoxycinnamoyl Scandoside Methyl Ester",
      "(1S)-1alpha-(Beta-D-Glucopyranosyloxy)-1,4aalpha,5,7aalpha-Tetrahydro-5alpha-[[(E)-4-Methoxycinnamoyl]oxy]-7-Hydroxymethylcyclopenta[c]pyran-4-Carboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D04SPQ",
    names: ["PMID16242323C26a"],
  },
  {
    primary_id: "D04SQJ",
    names: ["EW-A-401"],
  },
  {
    primary_id: "D04SQO",
    names: ["DEBIO 1562"],
  },
  {
    primary_id: "D04SQY",
    names: ["Deoxycorticosterone", "11-Deoxycorticosterone", "21-Hydroxyprogesterone"],
  },
  {
    primary_id: "D04SRO",
    names: ["GRT-3983Y"],
  },
  {
    primary_id: "D04STF",
    names: ["NSC-745798"],
  },
  {
    primary_id: "D04STQ",
    names: [
      "CBH-11",
      "CBH-17",
      "CBH-2",
      "CBH-4",
      "CBH-5",
      "CBH-7",
      "CBH-8",
      "Anti-HCV Antibodies, XTL",
      "Anti-Hepatitis C Virus Antibodies, XTL",
    ],
  },
  {
    primary_id: "D04SUV",
    names: ["ISIS 9061"],
  },
  {
    primary_id: "D04SUX",
    names: ["Recombinant Birch Pollen Allergen"],
  },
  {
    primary_id: "D04SVB",
    names: ["FR-133605"],
  },
  {
    primary_id: "D04SVT",
    names: [
      "L-159093",
      "CHEMBL24322",
      "BDBM50041969",
      "L006880",
      "3-{2-Butyl-4-Oxo-3-[2''-(1H-Tetrazol-5-Yl)-Biphenyl-4-Ylmethyl]-3,4-Dihydro-Quinazolin-6-Yl}-1-Isopropyl-1-Methyl-Urea",
    ],
  },
  {
    primary_id: "D04SWE",
    names: ["Anticancer Stem Cell Therapeutics"],
  },
  {
    primary_id: "D04SWF",
    names: ["FP-1302", "Clevegen", "Clever-1 MAb (Inflammation, Metastasis), Faron"],
  },
  {
    primary_id: "D04SWT",
    names: ["Anti-CD47 Mab", "Anti-CD47 MAb (Hypertension/Tissue Ischemia/Organ Transplants)"],
  },
  {
    primary_id: "D04SWU",
    names: ["Tricyclic Indole Compound 12"],
  },
  {
    primary_id: "D04SXH",
    names: ["Salicyclic"],
  },
  {
    primary_id: "D04SXL",
    names: [
      "APG-115",
      "15Qau0SI9J",
      "15QAU0SI9J",
      "UNII-15QAU0SI9J",
      "1818393-16-6",
      "APG 115 [WHO-DD]",
      "SCHEMBL17189805",
      "Bicyclo(2.2.2)Octane-1-Carboxylic Acid, 4-((((3'R,4'S,5'R)-6''-Chloro-4'-(3-Chloro-2-Fluorophenyl)-1'-Ethyl-1'',2''-Dihydro-2''-Oxodispiro(Cyclohexane-1,2'-Pyrrolidine-3',3''-(3H)Indol)-5'-Yl)Carbonyl)Amino)-",
    ],
  },
  {
    primary_id: "D04SYJ",
    names: ["PMID28766366-Compound-Scheme14BINDY"],
  },
  {
    primary_id: "D04SYM",
    names: ["Isosorbide-2-(Methylcarbamate)-5-Mononitrate", "CHEMBL492179"],
  },
  {
    primary_id: "D04SZA",
    names: ["P-3914", "Anti-Inflammatory, Piramal"],
  },
  {
    primary_id: "D04SZG",
    names: ["COTI-219"],
  },
  {
    primary_id: "D04SZR",
    names: ["4-(2-Carboxy-5-Mercaptopentyl)Benzoic Acid"],
  },
  {
    primary_id: "D04TBB",
    names: [
      "11BETA,13-DIHYDRO-10-EPI-8-DEOXYCUMAM-BRIN B",
      "CHEMBL1097371",
      "11beta,13-Dihydro-10-Epi-8-Deoxycumam-Brin B",
      "BDBM50318411",
      "LS-23585",
      "11betaH,13-Dihydro-10-Epi-8-Deoxycumambrin B",
    ],
  },
  {
    primary_id: "D04TBC",
    names: ["OZ-439"],
  },
  {
    primary_id: "D04TBJ",
    names: [
      "O-Phosphoethanolamine",
      "2-Aminoethyl Dihydrogen Phosphate",
      "O-Phosphorylethanolamine",
      "1071-23-4",
      "O-Phosphocolamine",
      "Colamine Phosphate",
      "Phosphorylethanolamine",
      "Ethanolamine Phosphate",
      "Phosphonoethanolamine",
      "Ethanolamine O-Phosphate",
      "Mono(2-Aminoethyl) Phosphate",
      "Phosphoryethanolamine",
      "COLAMINE PHOSPHORIC ACID",
      "Colaminephosphoric Acid",
      "Pe 104",
      "Colaminphosphoric Acid",
      "Ethanol, 2-Amino-, Phosphate",
      "Ethanolamine Acid Phosphate",
      "Ethanol, 2-Amino-, Dihydrogen Phosphate (Ester)",
      "Monoaminoethyl Phosphate",
    ],
  },
  {
    primary_id: "D04TBW",
    names: ["C-Rx"],
  },
  {
    primary_id: "D04TCQ",
    names: ["J-775Y"],
  },
  {
    primary_id: "D04TCU",
    names: [
      "CMI-977",
      "CHEMBL73148",
      "CMI977",
      "LDP-977",
      "SCHEMBL16932371",
      "LDP 977",
      "CMI 977",
      "HY-U00260",
      "BDBM50144621",
      "CS-7474",
      "175212-04-1",
      "UNII-VNR0T3Q498 Component YANONWCPCKIWEC-CABCVRRESA-N",
      "N-(4-{(2S,5S)-5-[(4-Fluorophenoxy)Methyl]tetrahydrofuran-2-Yl}but-3-Ynyl)-N-Hydroxyurea",
      "Urea, N-(4-((2S,5S)-5-((4-Fluorophenoxy)Methyl)Tetrahydro-2-Furanyl)-3-Butyn-1-Yl)-N-Hydroxy-",
    ],
  },
  {
    primary_id: "D04TDF",
    names: ["[4Aph(CO-NH-OCH3)5,D-4Aph(CO-NH-OCH3)6]degarelix", "CHEMBL219061"],
  },
  {
    primary_id: "D04TDQ",
    names: ["Noscapine (Narcotine)", "Anarcotine (TN)"],
  },
  {
    primary_id: "D04TDX",
    names: [
      "2-(4-Methoxyphenyl)Quinoline-8-Carboxamide",
      "CHEMBL481591",
      "655222-47-2",
      "CTK1J6622",
      "DTXSID70649086",
      "BDBM50255383",
      "AKOS030560276",
      "8-Quinolinecarboxamide, 2-(4-Methoxyphenyl)-",
    ],
  },
  {
    primary_id: "D04TED",
    names: ["Cyclo(-D-Tyr-L-MeArg-L-Arg-L-Nal-Gly-)", "CHEMBL374421"],
  },
  {
    primary_id: "D04TEF",
    names: ["Alpha-1 Antitrypsin", "AAT, Transgene", "Alpha-1 Antitrypsin, Transgene"],
  },
  {
    primary_id: "D04TFE",
    names: [
      "CreaVax-HCC",
      "Autologous DC Vaccine Therapy (Injectable, Hepatocellular Carcinoma), CreaGene",
      "Autologous Dendritic Cell Vaccine(Injectable, Hepatocellular Carcinoma), CreaGene",
    ],
  },
  {
    primary_id: "D04TFL",
    names: ["SGX203"],
  },
  {
    primary_id: "D04TGF",
    names: ["AZD-2927", "AZD2927"],
  },
  {
    primary_id: "D04TGG",
    names: ["PMID28870136-Compound-68"],
  },
  {
    primary_id: "D04TGJ",
    names: ["PMID27967267-Compound-3"],
  },
  {
    primary_id: "D04TGN",
    names: [
      "4-Oxo-4H-Chromene-3-Carboxylic Acid",
      "Chromone-3-Carboxylic Acid",
      "39079-62-4",
      "4-Oxo-4H-1-Benzopyran-3-Carboxylic Acid",
      "4-Oxochromene-3-Carboxylic Acid",
      "CHEMBL315361",
      "PCIITXGDSHXTSN-UHFFFAOYSA-N",
      "MFCD00017338",
      "Maybridge1_005842",
      "3-Carboxychromone",
      "ACMC-1AD65",
      "SCHEMBL1253672",
      "AC1L49D2",
      "CTK1C4919",
      "ZINC57725",
      "HMS558B12",
      "DTXSID90192327",
      "MolPort-000-145-536",
      "4-Oxo-Chromene-3-Carboxylic Acid",
      "Chromone-3-Carboxylic Acid, 97%",
      "CC-721",
      "BDBM50131081",
      "4H-Chromen-4-One-3-Carboxylic Acid",
      "SBB017525",
      "AKOS001044083",
      "CS-W015906",
      "VZ31583",
    ],
  },
  {
    primary_id: "D04TGU",
    names: ["MK-3901"],
  },
  {
    primary_id: "D04THH",
    names: ["Fulvestrant For Injectable Suspension"],
  },
  {
    primary_id: "D04THK",
    names: ["AL0-212", "AL0212"],
  },
  {
    primary_id: "D04THU",
    names: ["KP-624"],
  },
  {
    primary_id: "D04TIJ",
    names: ["Namitecan"],
  },
  {
    primary_id: "D04TIL",
    names: ["ANA773"],
  },
  {
    primary_id: "D04TIO",
    names: [
      "Cyclo(-D-Tyr-Arg-Arg-Nal-Gly-)",
      "Fc-131",
      "FC131",
      "CHEMBL436283",
      "FC 131",
      "606968-52-9",
      "CHEMBL2180076",
      "AC1NQNK6",
      "SCHEMBL15987252",
      "ZINC3925712",
      "Cyclo(-Nal-Gly-D-Tyr-Arg-Arg-)",
      "BDBM50399002",
      "BDBM50166106",
      "KB-272560",
      "B7647",
      "N-{3-[(2S,5S,8S,14R)-5-(3-Guanidino-Propyl)-14-(4-Hydroxy-Benzyl)-8-Naphthalen-2-Ylmethyl-3,6,9,12,15-Pentaoxo-1,4,7,10,13pentaaza-Cyclopentadec-2-Yl]-Propyl}-Guanidine",
    ],
  },
  {
    primary_id: "D04TIS",
    names: [
      "PMID18800762C11",
      "Bisphosphonate, 11",
      "UNII-S888WM4024",
      "S888WM4024",
      "16610-63-2",
      "Tensan AO",
      "AC1NA3ZJ",
      "GTPL3191",
      "BDBM25285",
      "DB07873",
    ],
  },
  {
    primary_id: "D04TIT",
    names: ["Nowarta110"],
  },
  {
    primary_id: "D04TIU",
    names: ["PMID25666693-Compound-154"],
  },
  {
    primary_id: "D04TJL",
    names: ["LAS-41004", "Anti-Inflammatory (Psoriasis/Atopic Dermatitis), Almirall"],
  },
  {
    primary_id: "D04TJP",
    names: ["Totrombopag"],
  },
  {
    primary_id: "D04TKG",
    names: ["LUFFARIELLOLIDE"],
  },
  {
    primary_id: "D04TKP",
    names: [
      "L-748415",
      "CHEMBL122000",
      "SCHEMBL244700",
      "XAHWDFRDQNVXQX-IBGZPJMESA-N",
      "BDBM50092508",
      "4 -[2-(3,4,5,6-Tetrahydropyrimidin-2-Ylamino)Ethyloxy]benzoyl-2(S)-Phenylsulfonylamino-Beta-Alanine",
      "2-Benzenesulfonylamino-3-{4-[2-(1,4,5,6-Tetrahydro-Pyrimidin-2-Ylamino)-Ethoxy]-Benzoylamino}-Propionic Acid",
      "(S)-3-[4-[2-(3,4,5,6-Tetrahydropyrimidine-2-Ylamino)Ethoxy]benzoylamino]-2-(Phenylsulfonylamino)Propionic Acid",
    ],
  },
  {
    primary_id: "D04TKU",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D04TLB",
    names: ["2,6-Diamino-5-Nitrosopyrimidin-4(3H)-One"],
  },
  {
    primary_id: "D04TLI",
    names: ["PMID17600705C23"],
  },
  {
    primary_id: "D04TLJ",
    names: ["Soblidotin", "Auristatin PE", "Dolastatin Derivative", "TZT-1027", "YHI-501", "ZT-1027"],
  },
  {
    primary_id: "D04TLQ",
    names: ["Cyclic Peptidomimetic Derivative 2"],
  },
  {
    primary_id: "D04TMV",
    names: [
      "3r-Hydroxydecanoyl-Coa",
      "3R-HYDROXYDECANOYL-COA",
      "3R-HYDROXYDECANOYL-COENZYME A",
      "(R)-3-Hydroxydecanoyl-CoA",
      "AC1L9LCA",
      "CHEBI:74405",
      "(R)-3-Hydroxydecanoyl-Coenzyme A",
      "DB03192",
      "S-{(9R,13S,15R)-17-[(2R,3S,4R,5R)-5-(6-Amino-9H-Purin-9-Yl)-4-Hydroxy-3-(Phosphonooxy)Tetrahydrofuran-2-Yl]-9,13,15-Trihydroxy-10,10-Dimethyl-13,15-Dioxido-4,8-Dioxo-12,14,16-Trioxa-3,7-Diaza-13,15-Diphosphaheptadec-1-Yl} (3R)-3-Hydroxydecanethioate (Non-Preferred Name)",
    ],
  },
  {
    primary_id: "D04TNI",
    names: ["Nitroflurbiprofen"],
  },
  {
    primary_id: "D04TNR",
    names: ["Dronabinol Oral Solution"],
  },
  {
    primary_id: "D04TNT",
    names: [
      "ABT-639",
      "1235560-28-7",
      "ABT 639",
      "UNII-0G7D0CQ88I",
      "ABT639",
      "0G7D0CQ88I",
      "CHEMBL3589557",
      "ABT-639 Free Base",
      "SCHEMBL400073",
      "GTPL7721",
      "CHEMBL3590674",
      "AGPIHNZOZNKRGT-CYBMUJFWSA-N",
      "MolPort-046-033-584",
      "EX-A1021",
      "BCP17732",
      "BDBM50095309",
      "ZINC113741875",
      "AKOS030526344",
      "SB19105",
      "CS-5515",
      "HY-19721",
      "4-Chloro-2-Fluoro-N-(2-Fluorophenyl)-5-[[(8aR)-Hexahydropyrrolo[1,2-A]pyrazin-2(1H)-Yl]carbonyl]benzenesulfonamide",
      "4-Chloro-2-Fluoro-N-(2-Fluorophenyl)-5-[(8aR)-Hexahydropyrrolo[1,2-A]pyrazin-2(1H)-Ylcarbonyl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D04TPA",
    names: ["PMID24900749C1a"],
  },
  {
    primary_id: "D04TPC",
    names: ["4-Benzyl-2,6-Diisobutylamino-Pyrimidine", "CHEMBL493703"],
  },
  {
    primary_id: "D04TPF",
    names: ["TDI-0054"],
  },
  {
    primary_id: "D04TPO",
    names: [
      "5-Chloro-4-Methyl-3,4-Dihydroquinazolin-2-Amine",
      "CHEMBL270177",
      "918134-82-4",
      "2-Quinazolinamine, 5-Chloro-1,4-Dihydro-4-Methyl-",
      "5-Chloro-4-Methyl-1,4-Dihydroquinazolin-2-Amine",
      "SCHEMBL1381915",
      "CTK3H8599",
      "DTXSID00581041",
      "XFMPGBXLGWZMQP-UHFFFAOYSA-N",
      "BDBM50371466",
    ],
  },
  {
    primary_id: "D04TPQ",
    names: ["Aganepag Isopropyl", "AGN-210961"],
  },
  {
    primary_id: "D04TPS",
    names: ["A-887755"],
  },
  {
    primary_id: "D04TPU",
    names: ["RO-5323441"],
  },
  {
    primary_id: "D04TQF",
    names: ["1-(Benzyloxy)-2-[2-(3-Methoxyphenyl)Ethyl]benzene", "CHEMBL214739", "BDBM50197201"],
  },
  {
    primary_id: "D04TQO",
    names: ["AZD1722"],
  },
  {
    primary_id: "D04TQT",
    names: ["Barusiban"],
  },
  {
    primary_id: "D04TSC",
    names: ["Proenzy"],
  },
  {
    primary_id: "D04TSL",
    names: ["JWH-392"],
  },
  {
    primary_id: "D04TSO",
    names: ["UNBS-1450"],
  },
  {
    primary_id: "D04TSP",
    names: ["PMID25470667-Compound-K-604"],
  },
  {
    primary_id: "D04TSV",
    names: [
      "CI-992",
      "CHEMBL34894",
      "135704-06-2",
      "CTK4B9950",
      "N-[2-(2-Amino-Thiazol-4-Yl)-1-(1-Cyclohexylmethyl-2,3-Dihydroxy-5-Methyl-Hexylcarbamoyl)-Ethyl]-2-(Morpholine-4-Sulfonylamino)-3-Phenyl-Propionamide",
      "L-Alaninamide,N-(4-Morpholinylsulfonyl)-L-Phenylalanyl-3-(2-Amino-4-Thiazolyl)-N-[(1S,2R,3S)-1-(Cyclohexylmethyl)-2,3-Dihydroxy-5-Methylhexyl]-(9CI)",
      "BDBM50006844",
    ],
  },
  {
    primary_id: "D04TTB",
    names: ["3alpha,24-Dihydroxyolean-12-En-27-Oic Acid", "CHEMBL207657"],
  },
  {
    primary_id: "D04TTN",
    names: ["(+)-(1R,1'S)-Thaligrisine Hydrochloride", "CHEMBL451734", "(+)-(1R,1'S)-Thaligrisine HCl"],
  },
  {
    primary_id: "D04TTP",
    names: [
      "5,7-Dibromo-9H-Pyrido[3,4-B]indol-6-Ol",
      "7-Bromoeudistomine D",
      "7-Bromo-Eudistomin D",
      "CHEMBL272185",
      "101927-49-5",
      "Bromo-Eudistomin D",
      "Bromoeudistomin D",
      "AC1L2SZQ",
      "5,7-Dibromo-9H-Prido(3,4-B)Indol-6-Ol",
      "SCHEMBL7085913",
      "DTXSID80144350",
      "BDBM50207817",
      "9H-Prido(3,4-B)Indol-6-Ol, 5,7-Dibromo-",
    ],
  },
  {
    primary_id: "D04TUG",
    names: ["Heteroaromatic Ring Derivative 3"],
  },
  {
    primary_id: "D04TUN",
    names: ["HTI-1066"],
  },
  {
    primary_id: "D04TVG",
    names: ["BRL-15572", "BRL15572", "BRL-15,572", "BRL 15572"],
  },
  {
    primary_id: "D04TVP",
    names: ["N-Acetyloxyethyl-Exo-THPO"],
  },
  {
    primary_id: "D04TVU",
    names: ["RU-5135", "78774-26-2", "R-5135"],
  },
  {
    primary_id: "D04TWU",
    names: ["(5R,6S)-6-(Benzyloxy)-5-Methoxycyclohex-2-Enone", "CHEMBL212055", "BDBM50186506"],
  },
  {
    primary_id: "D04TWX",
    names: [
      "(R)-3-(4-Propylmorpholin-2-Yl)Phenol",
      "CHEMBL250403",
      "710654-74-3",
      "Pf-219061",
      "SCHEMBL2078437",
      "DTXSID90430791",
      "ZINC26822913",
      "BDBM50226008",
      "KB-274981",
    ],
  },
  {
    primary_id: "D04TXB",
    names: ["Ceplene/Peg-Intron/Rebetol"],
  },
  {
    primary_id: "D04TXE",
    names: ["PD-157672"],
  },
  {
    primary_id: "D04TYP",
    names: ["RG7600"],
  },
  {
    primary_id: "D04TZA",
    names: ["PF-04957325"],
  },
  {
    primary_id: "D04TZI",
    names: [
      "Cis-2-Fluoro-2-Phenylcyclopropanamine",
      "CHEMBL466769",
      "SCHEMBL16010002",
      "ZINC13584960",
      "BDBM50261916",
      "AKOS027415699",
      "784133-88-6",
      "KB-276212",
    ],
  },
  {
    primary_id: "D04TZK",
    names: ["LNK-3186"],
  },
  {
    primary_id: "D04TZR",
    names: ["ICI-204448", "121264-04-8"],
  },
  {
    primary_id: "D04TZT",
    names: ["SDZ-280-636"],
  },
  {
    primary_id: "D04UAL",
    names: ["Clovoxamine"],
  },
  {
    primary_id: "D04UAZ",
    names: ["CTA-091", "Cytochrome P-450 24 (CYP24) Inhibitors (Secondary Hyperparathyroidism), Cytochroma"],
  },
  {
    primary_id: "D04UBI",
    names: ["AB928"],
  },
  {
    primary_id: "D04UBQ",
    names: [
      "8-(3,4-Dichlorophenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL312688",
      "8-(3,4-Dichlorophenyl)-7H-Purine-2,6-Diamine",
      "AC1LAR9C",
      "CTK7E1080",
      "BDBM50059929",
      "2,6-Diamino-8-(3',4'-Dichlorophenyl)Purine",
    ],
  },
  {
    primary_id: "D04UBY",
    names: [
      "5-(3'-Cyanobiphenyl-4-Yl)-3-Hydroxypentanoic Acid",
      "CHEMBL574666",
      "SCHEMBL5613445",
      "BDBM50300481",
      "5-(3''-Cyanobiphenyl-4-Yl)-3-Hydroxypentanoic Acid",
    ],
  },
  {
    primary_id: "D04UCF",
    names: ["MRE 3010F20"],
  },
  {
    primary_id: "D04UCV",
    names: [
      "8-Chloro-1,2,3,4-Tetrahydro-Pyrazino[1,2-A]indole",
      "CHEMBL443258",
      "8-Chloro-1H,2H,3H,4H-Pyrazino[1,2-A]indole",
      "126718-22-7",
      "SCHEMBL5917499",
      "MolPort-022-445-855",
      "BDBM50108304",
      "ZINC13586842",
      "AKOS022692454",
      "MCULE-6563903231",
      "NE39901",
      "8-Chloro-1,2,3,4-Tetrahydropyrazino[1,2-A]indole",
    ],
  },
  {
    primary_id: "D04UDE",
    names: ["7-(4,6-Dimethoxypyrimidin-2-Yl)-2-Methylquinoline", "CHEMBL245235"],
  },
  {
    primary_id: "D04UDT",
    names: ["MEBUDIPINE"],
  },
  {
    primary_id: "D04UEN",
    names: ["1,1,1-Trifluoro-3-(Octylsulfinyl)Propan-2-One", "CHEMBL270373"],
  },
  {
    primary_id: "D04UFI",
    names: ["ImMucin"],
  },
  {
    primary_id: "D04UFM",
    names: ["Dmt-Pro-Dmt-Phe-NH2", "CHEMBL388800"],
  },
  {
    primary_id: "D04UFY",
    names: ["UX-003"],
  },
  {
    primary_id: "D04UGI",
    names: ["NSC-645835"],
  },
  {
    primary_id: "D04UGJ",
    names: ["PMID29671355-Compound-37"],
  },
  {
    primary_id: "D04UHT",
    names: ["Urotensin II-Related Peptide"],
  },
  {
    primary_id: "D04UHW",
    names: ["PMID25684022-Compound-WO2008079988"],
  },
  {
    primary_id: "D04UHX",
    names: ["HumaRAD-HN"],
  },
  {
    primary_id: "D04UIH",
    names: ["Amidopyrazole Derivative 1"],
  },
  {
    primary_id: "D04UIK",
    names: ["MCL-429"],
  },
  {
    primary_id: "D04UJI",
    names: [
      "Naphthalene-2-Carboxylic Acid Hydroxyamide",
      "2-Naphthohydroximic Acid",
      "2-Naphthylhydroxamic Acid",
      "N,N-Naphthaloylhydroxylamine",
      "2-NAPHTHOHYDROXAMIC ACID",
      "10335-79-2",
      "2-Naphthoylhydroxamic Acid",
      "2-Naphthalenecarboxamide, N-Hydroxy-",
      "N-Hydroxy-2-Naphthalenecarboxamide",
      "CHEMBL156672",
      "N-Hydroxynaphthalene-2-Carboxamide",
      "SCHEMBL6010034",
      "AC1L18K7",
      "DTXSID70145795",
      "KUC110312N",
      "BDBM50015160",
      "AKOS000178377",
      "KSC-264-28-1",
      "LS-95339",
      "2-Naphthalenecarboxamide, N-Hydroxy- (9CI)",
    ],
  },
  {
    primary_id: "D04UJZ",
    names: ["[125I]CGP42112"],
  },
  {
    primary_id: "D04UKC",
    names: ["Riociguat"],
  },
  {
    primary_id: "D04UKD",
    names: ["1,6-Naphyridine-4-Ketone Fused Heterocyclic Derivative 1"],
  },
  {
    primary_id: "D04UKJ",
    names: [
      "NP-61",
      "Dual Binding Site Acetylcholinesterase Inhibitor, Neuropharma",
      "NP-00361",
      "NP-0361",
      "AChE Inhibitor (Alzheimer's) Neuropharma (Zeltia)",
      "AChE Inhibitor/Beta Amyloid Secretion Inhibitor (Oral Formulation, Alzheimer's Disease), Noscira",
    ],
  },
  {
    primary_id: "D04UKP",
    names: ["Tenatumomab"],
  },
  {
    primary_id: "D04ULI",
    names: ["[3H]PGF2alpha", "[3H]dinoprost", "[3H]prostaglandin F2alpha"],
  },
  {
    primary_id: "D04ULU",
    names: ["Icilin"],
  },
  {
    primary_id: "D04ULX",
    names: ["Succinamide Derivative 1"],
  },
  {
    primary_id: "D04ULY",
    names: [
      "Bradyzide",
      "CHEMBL308468",
      "AC1NSM1I",
      "GTPL680",
      "SCHEMBL8488232",
      "LS-137389",
      "263011-13-8",
      "(2S)-N-[2-(2-Dimethylaminoethyl-Methylamino)Ethyl]-1-[4-[2-[di(Phenyl)Methylcarbamothioyl]hydrazinyl]-3-Nitrophenyl]sulfonylpyrrolidine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D04UME",
    names: ["BI-144807"],
  },
  {
    primary_id: "D04UMT",
    names: ["CP-409092"],
  },
  {
    primary_id: "D04UMY",
    names: ["N-(4-Cyano-3-Methanesulfonyl-Benzoyl)-Guanidine", "CHEMBL303297"],
  },
  {
    primary_id: "D04UNE",
    names: ["NIHP"],
  },
  {
    primary_id: "D04UNO",
    names: ["KUL-7211"],
  },
  {
    primary_id: "D04UNQ",
    names: ["4-(Phenyldiazenyl)-1H-Pyrazole-3,5-Diamine"],
  },
  {
    primary_id: "D04UNY",
    names: ["Anti-GD2 T-Cells (1RG-CART)"],
  },
  {
    primary_id: "D04UOJ",
    names: ["PYN-7"],
  },
  {
    primary_id: "D04UOP",
    names: ["Butoxamine", "AC1LELZ5", "ZINC57209"],
  },
  {
    primary_id: "D04UPJ",
    names: ["DasKloster 0247-01"],
  },
  {
    primary_id: "D04UPM",
    names: ["KBAB-401"],
  },
  {
    primary_id: "D04UPQ",
    names: [
      "9-Amino-6H,11H-Indeno[1,2-C]isoquinolin-5-One",
      "CHEMBL194155",
      "SCHEMBL4078284",
      "BDBM27515",
      "BIBLEFNXUYTZIB-UHFFFAOYSA-N",
      "Indeno[1,2-C]isoquinolinone, 2b",
      "ZINC13652899",
      "5,6-Dihydro-5-Oxo-9-Amino-Indeno[1,2-C]isoquinoline",
    ],
  },
  {
    primary_id: "D04UPU",
    names: [
      "2-(3,4-Dihydroxyphenyl)Acetic Acid",
      "3,4-Dihydroxyphenylacetic Acid",
      "102-32-9",
      "Dopac",
      "Homoprotocatechuic Acid",
      "2-(3,4-DIHYDROXYPHENYL)ACETIC ACID",
      "Dopacetic Acid",
      "Benzeneacetic Acid, 3,4-Dihydroxy-",
      "3,4-Dihydroxybenzeneacetic Acid",
      "Dihydroxyphenylacetic Acid",
      "4-Carboxymethylcatechol",
      "(3,4-DIHYDROXYPHENYL)ACETIC ACID",
      "Homoprotocatechuate",
      "3,4-Dihydroxy-Phenylacetic Acid",
      "Acetic Acid, (3,4-Dihydroxyphenyl)-",
      "BA 2773",
      "UNII-KEX5N0R4N5",
      "CCRIS 3765",
      "3,4-Dihydroxyphenylacetate",
      "Lopac-D-9128",
      "EINECS 203-024-1",
      "NSC 73191",
      "BRN 2211017",
      "KEX5N0R4N5",
      "3,4-DHPOP",
      "MLS001056737",
    ],
  },
  {
    primary_id: "D04UPW",
    names: ["RWJ-68023"],
  },
  {
    primary_id: "D04UQF",
    names: ["Influvac TC Cell Culture-Derived Influenza Vaccine"],
  },
  {
    primary_id: "D04UQS",
    names: ["NXD-9062"],
  },
  {
    primary_id: "D04URI",
    names: ["PMID21277783C7", "Citibrasine", "Citbrasine", "GTPL8561", "BDBM50336482"],
  },
  {
    primary_id: "D04URO",
    names: [
      "Streptokinase",
      "Awelysin",
      "Estreptoquinasa",
      "Kabikinase",
      "Streptase",
      "Streptochinasi",
      "Streptokinasum",
      "Streptochinasi [DCIT]",
      "Streptococcal Fibrinolysin",
      "Estreptoquinasa [INN-Spanish]",
      "Streptase (TN)",
      "Streptokinasum [INN-Latin]",
      "4-Cyclohexylpyrrolidine-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D04URZ",
    names: ["AVX-003"],
  },
  {
    primary_id: "D04USC",
    names: ["Ergotidine"],
  },
  {
    primary_id: "D04USG",
    names: ["MMP-13 Inhibitors"],
  },
  {
    primary_id: "D04USK",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D04USO",
    names: ["4-(Mesityloxy)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL513768", "SCHEMBL4163778"],
  },
  {
    primary_id: "D04USU",
    names: ["Heteroaryl-Substituted Urea Derivative 2"],
  },
  {
    primary_id: "D04UTH",
    names: ["Long-Acting Erythropoietin Conjugate"],
  },
  {
    primary_id: "D04UTQ",
    names: ["N,N-Dimethyl-3,4-Dihydroquinazolin-2-Amine", "CHEMBL270409"],
  },
  {
    primary_id: "D04UTT",
    names: ["Olodaterol", "BI-1744"],
  },
  {
    primary_id: "D04UUD",
    names: [
      "CC-401",
      "395104-30-0",
      "UNII-NOE38VQA1W",
      "NOE38VQA1W",
      "3-(3-(2-(Piperidin-1-Yl)Ethoxy)Phenyl)-5-(1H-1,2,4-Triazol-5-Yl)-1H-Indazole",
      "3-(3-(2-(Piperidin-1-Yl)Ethoxy)Phenyl)-5-(1H-1,2,4-Triazol-3-Yl)-1H-Indazole",
      "3-[3-[2-(1-PIPERIDINYL)ETHOXY]PHENYL]-5-(1H-1,2,4-TRIAZOL-5-YL)-1H-INDAZOLE",
      "CC 401",
      "C22H24N6O",
      "PubChem22434",
      "SCHEMBL4604749",
      "CHEMBL1614713",
      "CHEBI:91437",
      "DTXSID40192650",
      "MolPort-044-561-531",
      "MolPort-021-804-942",
      "BCPP000298",
      "BCP01960",
      "ZINC38836256",
      "RS0046",
      "HY-13022A",
      "AKOS027251057",
      "AKOS027336636",
      "DB12432",
      "BCP9000495",
      "CS-1955",
    ],
  },
  {
    primary_id: "D04UUH",
    names: ["Lithium Carbonate"],
  },
  {
    primary_id: "D04UUT",
    names: ["2-Furan-2-Yl-6H-Pyrazolo[1,5-C]quinazolin-5-One"],
  },
  {
    primary_id: "D04UVC",
    names: ["2-Methoxy-N-(3-Methyl-2-Phenyl-Butyl)-Benzamide", "CHEMBL16450"],
  },
  {
    primary_id: "D04UVL",
    names: ["N-(2-Aminophenyl)Nicotinamide"],
  },
  {
    primary_id: "D04UWG",
    names: [
      "Autologous T-Cell Therapy",
      "Anti-PSMA Designer T Cells, Roger Williams",
      "Gene Therapy (Chimeric Anti-PSMA Antibody-TCR), Roger Williams",
      "Autologous T-Cell Therapy (Anti-PSMA-CD3, Prostate Cancer)",
      "Autologous T-Cell Therapy (Anti-PSMA-CD3, Prostate Cancer), Roger Williams Medical Center",
      "Retrovirus-Based Ex Vivo Gene Therapy (Anti-PSMA-CD3, Prostate Cancer), Roger Williams Medical Center",
    ],
  },
  {
    primary_id: "D04UWU",
    names: ["Oraxol"],
  },
  {
    primary_id: "D04UXC",
    names: ["Imidazo Cyclohexylamine Derivative 3"],
  },
  {
    primary_id: "D04UXO",
    names: ["PMID25656651-Compound-15a"],
  },
  {
    primary_id: "D04UXX",
    names: ["PD-158771"],
  },
  {
    primary_id: "D04UYB",
    names: ["MANGIFERIN"],
  },
  {
    primary_id: "D04UYO",
    names: ["HMR-1556"],
  },
  {
    primary_id: "D04UYT",
    names: ["CBLB-612"],
  },
  {
    primary_id: "D04UYY",
    names: ["AD5-10"],
  },
  {
    primary_id: "D04UYZ",
    names: ["(R,S)-N-(R-Phenylethyl)-1H-Pyrrole-2-Carboxamide"],
  },
  {
    primary_id: "D04UZN",
    names: ["Pyrimethamine/Sulfadoxine"],
  },
  {
    primary_id: "D04UZT",
    names: [
      "Quinestrol",
      "EECPE",
      "Eston",
      "Estrovis",
      "Estrovister",
      "Plestrovis",
      "Quilea",
      "Quinestrolo",
      "Quinestrolum",
      "Quinestrolo [DCIT]",
      "Estrovis 4000",
      "W 3566",
      "Estrovis (TN)",
      "Ethinyl Estradiol 3-Cyclopentyl Ether",
      "Qui-Lea",
      "Quinestrolum [INN-Latin]",
      "W-3566",
      "Quinestrol (USAN/INN)",
      "Quinestrol [USAN:INN:BAN]",
      "Estradiol-17-Beta 3-Cyclopentyl Ether",
      "(8R,9S,13S,14S,17R)-3-Cyclopentyloxy-17-Ethynyl-13-Methyl-7,8,9,11,12,14,15,16-Octahydro-6H-Cyclopenta[a]phenanthren-17-Ol",
      "17-Alpha-Ethinylestradiol 3-Cyclopentyl Ether",
      "17ALPHA-ETHYLNYL-1,3,5[10]ESTRATRIENE-3,17BETA-DIOL 3-CYCLOPENTYL ETHER",
      "17alpha-Ethynyl-1,3,5(10)-Estratriene-3,17beta-Diol 3-Cyclopentyl Ether",
      "17alpha-Ethynylestradiol 3-Cyclopentyl Ether",
      "3-(Cyclopentyloxy)-19-Nor-17-Alpha-Pregna-1,3,5(10)-Trien-20-Yn-17-Ol",
      "3-(Cyclopentyloxy)-19-Nor-17alpha-Pregna-1,3,5(10)-Trien-20-Yn-17-Ol",
      "3-(Cyclopentyloxy)-17beta-Ethynylestra-1,3,5(10)-Trien-17-Ol",
      "3-Cyclopentyloxy-17alpha-Ethynylestra-1,3,5(10)-Trien-17beta-Ol",
    ],
  },
  {
    primary_id: "D04UZX",
    names: ["Prodrug"],
  },
  {
    primary_id: "D04VAE",
    names: ["PMID25435285-Compound-26"],
  },
  {
    primary_id: "D04VAR",
    names: ["GSK-2136773"],
  },
  {
    primary_id: "D04VBD",
    names: [
      "N-(6-Bromo-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1086780",
      "N-[6-Bromo-1H-Indazol-3-Yl]butanamide",
      "599191-53-4",
      "Butanamide,n-(6-Bromo-1h-Indazol-3-Yl)-",
      "SCHEMBL6490286",
      "QQVNXRROZHWJNS-UHFFFAOYSA-N",
      "ZINC38816494",
      "BDBM50313708",
    ],
  },
  {
    primary_id: "D04VBF",
    names: ["JNJ-38518168"],
  },
  {
    primary_id: "D04VCE",
    names: ["TETRAHYDROQUINOLINE A", "CHEMBL490868", "Tetrahydroquinline A"],
  },
  {
    primary_id: "D04VCF",
    names: ["ADR58"],
  },
  {
    primary_id: "D04VCN",
    names: ["Bovactant", "Alveofact"],
  },
  {
    primary_id: "D04VCQ",
    names: ["CART33 Cells"],
  },
  {
    primary_id: "D04VCS",
    names: ["Neocartilage"],
  },
  {
    primary_id: "D04VCW",
    names: [
      "G-301",
      "Diazinon",
      "Dimpylate",
      "333-41-5",
      "Diazinone",
      "Oleodiazinon",
      "Neocidol",
      "Spectracide",
      "Diazitol",
      "Nedcidol",
      "Dassitox",
      "Ciazinon",
      "Ektoband",
      "Nucidol",
      "Flytrol",
      "Exodin",
      "Galesan",
      "Bazuden",
      "Diazol",
      "Diazide",
      "Sarolex",
      "Dazzel",
      "Dacutox",
      "Basudin",
      "Antigal",
      "Dicid",
      "Alfa-Tox",
      "Diazajet",
      "Dimpylat",
      "Garden Tox",
      "Neocidol (Oil)",
      "Bassadinon",
      "Terminator",
      "Kayazol",
      "Dizinon",
      "Disonex",
      "Compass",
      "Meodinon",
      "Kayazinon",
      "Drawizon",
      "Nipsan",
      "Dyzol",
      "Diazinon Ag 500",
      "Knox-Out",
      "Neodinon",
      "Optimizer",
      "Dimpylatum",
      "Delzinon",
      "Dizictol",
      "Dipofene",
      "Dizinil",
      "Bazudin",
      "Srolex",
      "Basudin 10 G",
      "Geigy 24480",
      "Basudin S",
      "Knox",
    ],
  },
  {
    primary_id: "D04VDD",
    names: ["Methylbenzenesulfonamide Derivative 1"],
  },
  {
    primary_id: "D04VDY",
    names: ["L-741671"],
  },
  {
    primary_id: "D04VEJ",
    names: [
      "Lymecycline",
      "Aureociclin",
      "Aureomicina",
      "Aurofac",
      "Biovetin",
      "Centraureo",
      "Chlortetracyclin",
      "Chrusomykine",
      "Limeciclina",
      "Lymecyclinum",
      "Orospray",
      "Acronize PD",
      "Aurofac 10",
      "Tetrlysal 300",
      "Lymecycline (INN)",
      "Tetralysal 300 (TN)",
      "N(2)-(((+)-5-Amino-5-Carboxypentylamino)Methyl)Tetracycline",
      "N(6)-[({[(4S,4aS,5aS,6S,12aS)-4-(Dimethylamino)-3,6,10,12,12a-Pentahydroxy-6-Methyl-1,11-Dioxo-1,4,4a,5,5a,6,11,12a-Octahydrotetracen-2-Yl]carbonyl}amino)Methyl]-L-Lysine",
      "(+)-N-(5-Amino-5-Carboxypentylaminomethyl)-4-Dimethylamino-1,4,4a,5,5a,6,11,12a-Octahydro-3,6,10,12,12a-Pentahydroxy-6-Methyl-1,11-Dioxonaphthacene-2-Carboxamide",
    ],
  },
  {
    primary_id: "D04VFJ",
    names: [
      "Toremifene",
      "Acapodene",
      "Estrimex",
      "Farestone",
      "Toremifeno",
      "Toremifenum",
      "Toremifene Base",
      "Toremifeno [Spanish]",
      "Toremifenum [Latin]",
      "GTx 006",
      "Acapodene (TN)",
      "FC-1157a",
      "Fareston (TN)",
      "GTx-006",
      "Toremifene (INN)",
      "Toremifene [INN:BAN]",
      "Z-Toremifene",
      "GTX-006 (Acapodene)",
      "Toremifene Citrate (1:1)",
      "{2-[4-(4-Chloro-1,2-Diphenyl-But-1-Enyl)-Phenoxy]-Ethyl}-Dimethyl-Amine",
      "(Z)-2-(4-(4-Chloro-1,2-Diphenyl-1-Butenyl)Phenoxy)-N,N-Dimethylethanamine",
      "2-(P-[(Z)-4-Chloro-1,2-Diphenyl-1-Butenyl]-Phenoxy)-N,N-Dimethyl-Ethylamine Citrate(1:1)",
      "2-(Para-((Z)-4-Chloro-1,2-Diphenyl-1-Butenyl)Phenoxy)-N,N-Dimethylethylamine (IUPAC)",
      "2-({4-[(1Z)-4-Chloro-1,2-Diphenylbut-1-En-1-Yl]phenyl}oxy)-N,N-Dimethylethanamine",
      "2-[4-[(Z)-4-Chloro-1,2-Diphenylbut-1-Enyl]phenoxy]-N,N-Dimethylethanamine",
      "2-{4-[(1Z)-4-Chloro-1,2-Diphenylbut-1-En-1-Yl]phenoxy}-N,N-Dimethylethanamine",
    ],
  },
  {
    primary_id: "D04VFS",
    names: ["3-(2-Carboxy-4-Mercaptobutyl)Benzoic Acid"],
  },
  {
    primary_id: "D04VFW",
    names: ["SAR-116242"],
  },
  {
    primary_id: "D04VGL",
    names: ["4P-PDOT"],
  },
  {
    primary_id: "D04VGR",
    names: ["PMID27998201-Compound-9"],
  },
  {
    primary_id: "D04VGS",
    names: ["Selenium Sulfide"],
  },
  {
    primary_id: "D04VGY",
    names: ["CYT-007-TNFQb"],
  },
  {
    primary_id: "D04VHB",
    names: ["RSV Recombinant Vaccine"],
  },
  {
    primary_id: "D04VHK",
    names: ["CLR-3001"],
  },
  {
    primary_id: "D04VHL",
    names: ["MystiTears"],
  },
  {
    primary_id: "D04VHQ",
    names: ["AVI-7012", "Antisense Therapeutic (Junin Virus Infection), AVI BioPharma"],
  },
  {
    primary_id: "D04VIP",
    names: ["4030W92", "BW-4030W92", "5-(2,3-Dichlorophenyl)-6-(Fluoromethyl)Pyrimidine-2,4-Diamine"],
  },
  {
    primary_id: "D04VIS",
    names: [
      "Andrographolide",
      "5508-58-7",
      "Andrographis",
      "UNII-410105JHGR",
      "CHEBI:65408",
      "410105JHGR",
      "(S,E)-4-Hydroxy-3-(2-((1R,4aS,5R,6R,8aS)-6-Hydroxy-5-(Hydroxymethyl)-5,8a-Dimethyl-2-Methylenedecahydronaphthalen-1-Yl)Ethylidene)Dihydrofuran-2(3H)-One",
      "AK-47364",
      "3-(2-(Decahydro-6-Hydroxy-5-(Hydroxymethyl)-5,8a-Dimethyl-2-Methylenenaphthyl)Ethylidene)Dihydro-4-Hydroxyfuran-2(3H)-One",
      "EINECS 226-852-5",
      "NSC 383468",
      "3alpha,14,15,18-Tetrahydroxy-5b,9bH,10a-Labda-8(20),12-Dien-16-Oic Acid Gamma-Lactone",
    ],
  },
  {
    primary_id: "D04VJE",
    names: ["6-(2-Phenethylisoindolin-5-Yloxy)Nicotinamide", "CHEMBL235154", "SCHEMBL3696847"],
  },
  {
    primary_id: "D04VJH",
    names: ["ACREOZAST"],
  },
  {
    primary_id: "D04VJQ",
    names: ["BVS857"],
  },
  {
    primary_id: "D04VKO",
    names: ["ATON-004"],
  },
  {
    primary_id: "D04VKS",
    names: ["Acrisorcin", "Akrinol"],
  },
  {
    primary_id: "D04VLI",
    names: ["4-(2-Hydroxy-Ethyl)-Benzenesulfonamide", "4-(2-Hydroxyethyl)Benzenesulfonamide"],
  },
  {
    primary_id: "D04VLR",
    names: ["PA-161"],
  },
  {
    primary_id: "D04VMG",
    names: [
      "ELEMENE",
      "Cyclohexane, 1-Ethenyl-1-Methyl-2,4-Bis(1-Methylethenyl)-, (1R,2R,4S)-Rel-",
      "33880-83-0",
      "Beta-Elemene, (-)-",
      "(- )-Bete-Elemene",
      "EINECS 251-713-0",
      "AC1L1VID",
      "Cyclohexane, 2,4-Diisopropenyl-1-Methyl-1-Vinyl-, (1S,2S,4R)-",
      "OPFTUNCRGUEPRZ-UHFFFAOYSA-N",
      "Cyclohexane, 1-Ethenyl-1-Methyl-2,4-Bis(1-Methylethenyl)-, (1S-(1-Alpha,2-Beta,4-Beta))-",
      "(1alpha,2beta,4beta)-1-Methyl-2,4-Bis(Methylvinyl)-1-Vinylcyclohexane",
      "LS-56795",
      "EN300-296339",
      "1-Methyl-1-Vinyl-2,4-Diisopropenylcyclohexane",
    ],
  },
  {
    primary_id: "D04VMK",
    names: ["PTI-421"],
  },
  {
    primary_id: "D04VMM",
    names: ["Encapsulated Cancer Cells"],
  },
  {
    primary_id: "D04VMT",
    names: ["Sotalol"],
  },
  {
    primary_id: "D04VNR",
    names: [
      "2-Naphthalen-2-Ylmethyl-4,5-Dihydro-1H-Imidazole",
      "CHEMBL441948",
      "DSSTox_RID_82756",
      "DSSTox_CID_28423",
      "DSSTox_GSID_48449",
      "2-(Naphthalen-2-Ylmethyl)-4,5-Dihydro-1H-Imidazole",
      "CAS-835-31-4",
      "SMR000054538",
      "Lopac-N-5504",
      "AC1LD21I",
      "Lopac0_000828",
      "CBDivE_007782",
      "MLS000104605",
      "SCHEMBL953099",
      "Cid_610682",
      "DTXSID3048449",
      "VIXRKFBKWOHVBR-UHFFFAOYSA-N",
      "MolPort-001-896-776",
      "HMS2287I17",
      "ZINC2472262",
      "CCG-2426",
      "Tox21_113058",
      "Tox21_113645",
      "BDBM50057120",
      "MCULE-4581148654",
      "NCGC00015743-03",
      "NCGC00015743-02",
      "NCGC00015743-01",
      "BAS 00364529",
      "AB00076191-01",
    ],
  },
  {
    primary_id: "D04VNS",
    names: ["Alpha-Conotoxin AuIB"],
  },
  {
    primary_id: "D04VOE",
    names: ["AS-1402"],
  },
  {
    primary_id: "D04VOI",
    names: ["PMID17064898C29"],
  },
  {
    primary_id: "D04VOM",
    names: [
      "PD-136621",
      "CHEMBL263969",
      "4-[(S)-2-[(R)-2-(Adamantan-2-Yloxycarbonylamino)-3-(1H-Indol-3-Yl)-2-Methylpropanoylamino]-1-Phenylethylamino]-4-Oxobutyric Acid",
    ],
  },
  {
    primary_id: "D04VOZ",
    names: ["TKM-ApoB"],
  },
  {
    primary_id: "D04VPA",
    names: ["Moricizine"],
  },
  {
    primary_id: "D04VPF",
    names: ["3-Mercaptopyruvic Acid", "3-Mercaptopyruvate"],
  },
  {
    primary_id: "D04VPH",
    names: ["PMID22017539C15", "4UF", "GTPL8636", "BDBM50361618"],
  },
  {
    primary_id: "D04VPP",
    names: ["ATI-1150"],
  },
  {
    primary_id: "D04VPV",
    names: ["AD 198"],
  },
  {
    primary_id: "D04VQC",
    names: ["ISIS 134566"],
  },
  {
    primary_id: "D04VQE",
    names: ["PMID28092474-Compound-32b"],
  },
  {
    primary_id: "D04VQH",
    names: ["XEN-D0501"],
  },
  {
    primary_id: "D04VQM",
    names: [
      "5-Bromo-N-(Quinolin-8-Yl)Thiophene-2-Sulfonamide",
      "5-Bromo-N-Quinolin-8-Ylthiophene-2-Sulfonamide",
      "620103-87-9",
      "CHEMBL401504",
      "AC1M3N93",
      "SCHEMBL11995751",
      "CTK5I7875",
      "MolPort-000-693-109",
      "ZINC2888883",
      "ALBB-010070",
      "STK506180",
      "BDBM50372503",
      "BBL018066",
      "AKOS001440266",
      "MCULE-3903306629",
      "NCGC00161700-01",
      "TR-061560",
      "TR-039409",
      "R9130",
      "2-Thiophenesulfonamide, 5-Bromo-N-8-Quinolinyl-",
    ],
  },
  {
    primary_id: "D04VRB",
    names: ["NeuVax"],
  },
  {
    primary_id: "D04VRN",
    names: ["MMR"],
  },
  {
    primary_id: "D04VRY",
    names: [
      "3-(Imidazolylmethyl)-7-Methoxy-4'-Nitroflavone",
      "CHEMBL212168",
      "BDBM50191603",
      "3-(Imidazolylmethyl)-7-Methoxy-4''-Nitroflavone",
    ],
  },
  {
    primary_id: "D04VSM",
    names: ["CS-8958"],
  },
  {
    primary_id: "D04VSP",
    names: [
      "4-Ethoxy-3-Pyridinesulfonamide",
      "CHEMBL1165319",
      "4-Ethoxypyridine-3-Sulfonamide",
      "SCHEMBL20610078",
      "MolPort-038-948-438",
      "BDBM50320504",
      "ZINC49803276",
    ],
  },
  {
    primary_id: "D04VTV",
    names: ["SYN-114", "5-HT 6 Receptor Antagonists (Cognitive Disorders), Synosia/Roche"],
  },
  {
    primary_id: "D04VUH",
    names: ["PMID29473428-Compound-48"],
  },
  {
    primary_id: "D04VUM",
    names: [
      "CGEN-928",
      "Anti-CGEN-928 Polyclonal Antibody (Multiple Myeloma Treatment)",
      "Anti-TM21 Polyclonal Antibody (Multiple Myeloma Treatment), Compugen",
      "CGEN-928 (Multiple Myeloma Treatment), Compugen",
      "Anti-CGEN-928 Polyclonal Antibody (Multiple Myeloma Treatment), Compugen",
    ],
  },
  {
    primary_id: "D04VUY",
    names: ["RG-7166"],
  },
  {
    primary_id: "D04VVD",
    names: ["TeloB-Vax"],
  },
  {
    primary_id: "D04VVL",
    names: ["Malaria Next Generation Vaccine"],
  },
  {
    primary_id: "D04VWJ",
    names: ["ARRY-520"],
  },
  {
    primary_id: "D04VWL",
    names: [
      "Cannabinoid 1 Antagonists",
      "Cannabinoid 1 Antagonists (Obesity)",
      "Cannabinoid 1 Antagonists (Obesity), Gedeon Richter",
    ],
  },
  {
    primary_id: "D04VWO",
    names: ["Recombinant Soluble CD6"],
  },
  {
    primary_id: "D04VXN",
    names: ["Moxalactam Disodium"],
  },
  {
    primary_id: "D04VXP",
    names: [
      "4-(Piperazin-1-Yl)Furo[3,2-C]pyridine",
      "81078-84-4",
      "4-PIPERAZIN-1-YL-FURO[3,2-C]PYRIDINE",
      "1-{furo[3,2-C]pyridin-4-Yl}piperazine",
      "CHEMBL595555",
      "Furo[3,2-C]pyridine, 4-(1-Piperazinyl)-",
      "AK-76639",
      "CHEMBL543969",
      "PubChem17312",
      "AC1MDRY3",
      "SCHEMBL1026755",
      "KS-00000EXO",
      "CTK5E8449",
      "DTXSID10383432",
      "MolPort-000-145-249",
      "LUIZVTKJWSGSPA-UHFFFAOYSA-N",
      "ZINC4287704",
      "4-Piperazinylfurano[3,2-C]pyridine",
      "ANW-49259",
      "BDBM50016972",
      "SBB093149",
      "4-Piperazin-1-Ylfuro[3,2-C]pyridine",
      "AKOS015919593",
      "FS-3269",
      "1-(4-Furo[3,2-C]pyridinyl)Piperazine",
    ],
  },
  {
    primary_id: "D04VXS",
    names: ["ENT-102"],
  },
  {
    primary_id: "D04VYE",
    names: ["Benzyl Pyrrolyloxoacetamide Derivative 2"],
  },
  {
    primary_id: "D04VYT",
    names: ["Bbs-Arg-(D-Pip)-Gly-S-(GS)7-Gly-Hir", "CHEMBL427951"],
  },
  {
    primary_id: "D04VYZ",
    names: ["2-(3''-(Benzotriazole-1''-Yl)Ethyloxy)Adenosine", "CHEMBL374737", "BDBM50208808"],
  },
  {
    primary_id: "D04VZD",
    names: ["Angiotensin Inhibitor Therapeutic Vaccine"],
  },
  {
    primary_id: "D04VZS",
    names: ["CTS-21166"],
  },
  {
    primary_id: "D04WBT",
    names: [
      "Act1",
      "51-34-3",
      "UNII-DL48G20X8X",
      "Alpha-(Hydroxymethyl)Benzeneacetic Acid 9-Methyl-3-Oxa-9-Azatricyclo(3.3.1.0(2.4))Non-7-Yl Ester",
      "DL48G20X8X",
      "CHEBI:16794",
    ],
  },
  {
    primary_id: "D04WBU",
    names: [
      "1-Ethyl-3-Methyl-3,7-Dihydro-Purine-2,6-Dione",
      "1-Ethyl-3-Methylxanthine",
      "CHEMBL24107",
      "1-Ethyl-3,7-Dihydro-3-Methyl-1H-Purine-2,6-Dione",
      "37102-58-2",
      "SCHEMBL7016029",
      "KVSRUALMCYFLEP-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D04WBY",
    names: ["Menotropins"],
  },
  {
    primary_id: "D04WCA",
    names: ["AVP-7", "Cell Surface Receptor (Cancer), AviPep", "Imaging Agent (Cancer), AviPep"],
  },
  {
    primary_id: "D04WCH",
    names: ["1,3,7-Tripropyl-3,7-Dihydro-Purine-2,6-Dione", "1,3,7-Tripropylxanthine", "CHEMBL417834", "SCHEMBL316171"],
  },
  {
    primary_id: "D04WCI",
    names: ["PAMAPIMOD"],
  },
  {
    primary_id: "D04WCJ",
    names: ["DV-100"],
  },
  {
    primary_id: "D04WCO",
    names: ["EPB-415"],
  },
  {
    primary_id: "D04WCW",
    names: ["Hydoxyamidine Derivative 1"],
  },
  {
    primary_id: "D04WDA",
    names: ["CSL-412"],
  },
  {
    primary_id: "D04WDX",
    names: ["PJ34"],
  },
  {
    primary_id: "D04WFD",
    names: [
      "Nortriptyline",
      "Acetexa",
      "Allegron",
      "Altilev",
      "Ateben",
      "Avantyl",
      "Aventyl",
      "Demethylamitriptylene",
      "Demethylamitriptyline",
      "Demethylamitryptyline",
      "Desitriptilina",
      "Desitriptyline",
      "Desmethylamitriptylin",
      "Desmethylamitriptyline",
      "Lumbeck",
      "Noramitriptyline",
      "Noritren",
      "Nortrilen",
      "Nortriptilina",
      "Nortriptylinum",
      "Nortryptiline",
      "Nortryptyline",
      "Pamelor",
      "Psychostyl",
      "Sensaval",
      "Sesaval",
      "Vividyl",
      "AVENTYL HCL",
      "Nortriptilina [DCIT]",
      "Nortriptylene Hydrochloride",
      "Pamelor Hydrochloride",
      "Sensival Ventyl",
      "NCI169453",
      "Allegron (TN)",
      "Aventyl (TN)",
      "Norpress (TN)",
      "Nortrilen (TN)",
      "Nortriptyline (INN)",
      "Nortriptyline [INN:BAN]",
      "Nortriptylinum [INN-Latin]",
      "Pamelor (TN)",
      "Sensoval (TN)",
      "N-Methyl-3-(10,11-Dihydro-5H-Dibenzo(A,d)Cyclohepten-5-Yliden)Propylamin",
      "(2)10,11-Dihydro-N-Methyl-5H-Dibenzo[a,d]cycloheptene-.delta.5.gamma.-Propylamine",
      "10,11-Dihydro-5-(3-Methylaminopropylidene)-5H-Dibenzo(A,d)(1,4)Cycloheptene",
      "10,11-Dihydro-N-Methyl-5H-Dibenzo(A,d)Cycloheptene-Delta(5,gamma)-Propylamine",
      "10,11-Dihydro-N-Methyl-5H-Dibenzo[a,d]cycloheptene-Delta(5,gamma)-Propylamine",
      "3-(10,11-Dihydro-5H-Dibenzo(A,d)Cyclohepten-5-Ylidene)-N-Methylpropylamine",
      "3-(10,11-Dihydro-5H-Dibenzo[a,d]cyclohepten-5-Ylidene)-N-Methyl-1-Propanamine",
      "3-(10,11-Dihydro-5H-Dibenzo[a,d][7]annulen-5-Ylidene)-N-Methylpropan-1-Amine",
      "5-(3-(Methylamino)Propylidene)Dibenzo(A,e)Cyclohepta(1,5)Diene",
      "5-(3-Methylaminopropylidene)-10,11-Dihydro-5H-Dibenzo(A,d)Cycloheptene",
      "5-(Alpha-Methylaminopropylidene)Dibenzo(A,d)Cyclohepta(1,4)Diene",
    ],
  },
  {
    primary_id: "D04WFL",
    names: ["Trastuzumab"],
  },
  {
    primary_id: "D04WFN",
    names: [
      "NU6140",
      "Cdk2 Inhibitor IV, NU6140",
      "444723-13-1",
      "NU 6140",
      "CHEMBL1802728",
      "4-(6-Cyclohexylmethoxy-9H-Purin-2-Ylamino)-N,N-Diethylbenzamide",
      "4-{[6-(Cyclohexylmethoxy)-7H-Purin-2-Yl]amino}-N,N-Diethylbenzamide",
      "Cdk2 Inhibitor IV",
      "SCHEMBL2169233",
      "GTPL5949",
      "CTK8E7940",
      "DTXSID30436732",
      "MolPort-023-276-742",
      "MolPort-044-561-419",
      "HMS3229E18",
      "IN1369",
      "ZINC22309248",
      "BDBM50347924",
      "AKOS024457537",
      "CCG-206836",
      "NCGC00370819-01",
      "NU6140, &gt",
      "RT-011957",
      "4-(6-Cyclohexylmethoxy-9hpurin-2-Ylamino)-N,N-Diethyl-Benzamide",
    ],
  },
  {
    primary_id: "D04WFV",
    names: ["Hepatitis B Immune Globulin", "Anti-HBV Polyclonal Antibodies"],
  },
  {
    primary_id: "D04WGR",
    names: ["5-(2-Methylquinolin-7-Yl)Isophthalonitrile", "CHEMBL231788", "SCHEMBL4304888"],
  },
  {
    primary_id: "D04WHE",
    names: [
      "9-Propyl-9H-Adenine",
      "9-Propyl-9h-Purin-6-Amine",
      "CHEMBL475023",
      "707-98-2",
      "9-Propyl-9H-Purin-6-Ylamine",
      "9-Propylpurin-6-Amine",
      "9-Propyladenine",
      "NSC61961",
      "AC1Q4VHN",
      "AC1L6K9K",
      "SCHEMBL233405",
      "9-Propyl-9H-Purine-6-Amine",
      "CTK5D3013",
      "DTXSID10289561",
      "BDBM50256891",
      "NSC-61961",
    ],
  },
  {
    primary_id: "D04WHH",
    names: ["2-(4-Hydroxy-Phenyl)-7-Propyl-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D04WHM",
    names: ["G0-203-2c"],
  },
  {
    primary_id: "D04WHO",
    names: ["PMID12812482C11"],
  },
  {
    primary_id: "D04WHX",
    names: ["FP-21399"],
  },
  {
    primary_id: "D04WIA",
    names: ["2-Pyridin-2-Yl-6H-Pyrazolo[1,5-C]quinazolin-5-One"],
  },
  {
    primary_id: "D04WIE",
    names: ["Sirna-027"],
  },
  {
    primary_id: "D04WIF",
    names: ["ISIS-GCGR"],
  },
  {
    primary_id: "D04WIG",
    names: ["CY-1747"],
  },
  {
    primary_id: "D04WJM",
    names: ["ISIS 103803"],
  },
  {
    primary_id: "D04WJN",
    names: [
      "3-((1H-Imidazol-1-Yl)Methyl)-9H-Xanthen-9-One",
      "CHEMBL350922",
      "BDBM50097364",
      "3-Imidazol-1-Ylmethyl-Xanthen-9-One",
    ],
  },
  {
    primary_id: "D04WJO",
    names: ["Talaporfin", "Aptocine (TN)"],
  },
  {
    primary_id: "D04WKL",
    names: ["Piperazinyl Methyl Quinazolinone Derivative 3"],
  },
  {
    primary_id: "D04WLH",
    names: [
      "5-{8(Z),11(Z)-Pentadecadienyl}resorcinol",
      "CHEMBL459604",
      "5-(8,11-Pentadecadienyl)-1,3-Benzenediol",
      "5-[(8Z,11Z)-Pentadeca-8,11-Dien-1-Yl]benzene-1,3-Diol",
      "Cardoldiene",
      "5-((8Z,11Z)-Pentadeca-8,11-Dien-1-Yl)Benzene-1,3-Diol",
      "SCHEMBL9475761",
      "DTXSID70872872",
      "MolPort-005-944-663",
      "79473-25-9",
      "ZINC31157863",
      "LMPK15030021",
      "BDBM50292428",
      "AKOS030555484",
      "MCULE-2398296746",
      "RESORCINOL(15:2(8Z,11Z))",
      "5-(Pentadeca-8,11-Dienyl)Benzene-1,3-Diol",
      "5-((8Z,11Z)-Pentadeca-8,11-Dien-1-Yl)Resorcinol",
    ],
  },
  {
    primary_id: "D04WLX",
    names: ["AIK-21"],
  },
  {
    primary_id: "D04WMN",
    names: ["Polyclonal Antibody Stimulator"],
  },
  {
    primary_id: "D04WMR",
    names: ["Tepoxalin"],
  },
  {
    primary_id: "D04WNA",
    names: ["ART-237", "ART-237 (Intra-Articular/Injectable/Sustained Release, Joint Injury), Artyx Pharmaceuticals"],
  },
  {
    primary_id: "D04WND",
    names: ["Pyridopyrimidinone Derivative 1"],
  },
  {
    primary_id: "D04WNN",
    names: ["PMID26924192-Compound-53"],
  },
  {
    primary_id: "D04WOM",
    names: ["Seractide Acetate", "Acthar Gel-Synthetic"],
  },
  {
    primary_id: "D04WPA",
    names: ["SL-327"],
  },
  {
    primary_id: "D04WPM",
    names: ["SC-795"],
  },
  {
    primary_id: "D04WQJ",
    names: ["AMG-181"],
  },
  {
    primary_id: "D04WQT",
    names: ["RG7201"],
  },
  {
    primary_id: "D04WRK",
    names: ["ISIS 2921"],
  },
  {
    primary_id: "D04WRO",
    names: ["H-RYYRIK-NH2", "CHEMBL444110"],
  },
  {
    primary_id: "D04WSK",
    names: ["99mTc-ImmuRAID-LL1"],
  },
  {
    primary_id: "D04WSL",
    names: ["Cardiotoxin"],
  },
  {
    primary_id: "D04WTN",
    names: ["Oxabact"],
  },
  {
    primary_id: "D04WUA",
    names: ["CP-101,606"],
  },
  {
    primary_id: "D04WVG",
    names: ["C[-Arg-Gly-Asp-Acpca35-]", "CHEMBL534713"],
  },
  {
    primary_id: "D04WVI",
    names: ["M-17 E. Coli"],
  },
  {
    primary_id: "D04WVL",
    names: ["SF-1"],
  },
  {
    primary_id: "D04WVV",
    names: ["FIG 1", "CHEMBL428429", "SCHEMBL5095865", "BDBM50239276"],
  },
  {
    primary_id: "D04WVY",
    names: ["SPI-026"],
  },
  {
    primary_id: "D04WWG",
    names: ["S-1389", "711389-S"],
  },
  {
    primary_id: "D04WWM",
    names: [
      "2-(1-Adamantyl) Pyrrolidine",
      "2-(1-Adamantyl)Pyrrolidine",
      "2-(Adamantan-1-Yl)Pyrrolidine",
      "180258-96-2",
      "2-Adamantan-1-Yl-Pyrrolidine",
      "2-(Tricyclo[3.3.1.1~3,7~]dec-1-Yl)Pyrrolidine",
      "AC1LADDP",
      "SCHEMBL587943",
      "CHEMBL323901",
      "CTK5J7450",
      "YVRRAISBZUMEJB-UHFFFAOYSA-N",
      "MolPort-004-320-513",
      "ALBB-007403",
      "ZX-AN006941",
      "BBL022461",
      "STK504519",
      "AKOS016345558",
      "AKOS000157977",
      "RTR-059731",
      "MCULE-4533332464",
      "ACM180258962",
      "TR-059731",
      "W-9623",
      "Pyrrolidine, 2-Tricyclo[3.3.1.1~3,7~]dec-1-Yl-, (2S)-",
    ],
  },
  {
    primary_id: "D04WWO",
    names: ["IM-491"],
  },
  {
    primary_id: "D04WXG",
    names: ["DVC-LVS"],
  },
  {
    primary_id: "D04WXI",
    names: ["Oxo-Arpromidine"],
  },
  {
    primary_id: "D04WYX",
    names: ["Netarsudil"],
  },
  {
    primary_id: "D04WZK",
    names: [
      "AZD-2858",
      "AZD2858",
      "486424-20-8",
      "AZD 2858",
      "CHEMBL2177161",
      "3-Amino-6-[4-(4-Methylpiperazin-1-Yl)Sulfonylphenyl]-N-Pyridin-3-Ylpyrazine-2-Carboxamide",
      "3-Amino-6-(4-((4-Methylpiperazin-1-Yl)Sulfonyl)Phenyl)-N-(Pyridin-3-Yl)Pyrazine-2-Carboxamide",
      "3-Amino-6-{4-[(4-Methylpiperazin-1-Yl)Sulfonyl]phenyl}-N-Pyridin-3-Ylpyrazine-2-Carboxamide",
      "GSK-3 Inhibition",
      "3-Amino-6-{4-[(4-Methylpiperazin-1-Yl)Sulfonyl]phenyl}-N-(Pyridin-3-Yl)Pyrazine-2-Carboxamide",
      "GTPL8478",
      "SCHEMBL3327702",
      "C21H23N7O3S",
      "EX-A707",
      "AOB6850",
      "MolPort-035-395-808",
    ],
  },
  {
    primary_id: "D04WZL",
    names: ["CART-Meso Cells"],
  },
  {
    primary_id: "D04XAB",
    names: ["Autologous Cancer Vaccine"],
  },
  {
    primary_id: "D04XBQ",
    names: ["Volatile Anesthetics"],
  },
  {
    primary_id: "D04XBU",
    names: ["ATL-104"],
  },
  {
    primary_id: "D04XBZ",
    names: ["1-Aminohexylphosphonic Acid Monophenyl Ester", "CHEMBL1093531"],
  },
  {
    primary_id: "D04XCB",
    names: [
      "3-Amino-5-(4-Octylphenyl)Pentanoic Acid",
      "CHEMBL326346",
      "SCHEMBL5464250",
      "BDBM50147707",
      "3-Amino-5-(4-Octyl-Phenyl)-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D04XCK",
    names: ["OMP-313M32"],
  },
  {
    primary_id: "D04XCO",
    names: ["AZD-8848", "AZ-12441970", "DSP-3025", "SM-324405", "TLR7 Agonist (Allergy), Dainippon/AstraZeneca"],
  },
  {
    primary_id: "D04XCQ",
    names: ["SNAP-6201"],
  },
  {
    primary_id: "D04XDS",
    names: ["GLUTARATE"],
  },
  {
    primary_id: "D04XDT",
    names: ["Delafloxacin Meglumine", "Baxdela"],
  },
  {
    primary_id: "D04XED",
    names: ["Crocusatin-K", "Crocusatin K", "CHEMBL446756", "AC1LD3L5", "BDBM50250531"],
  },
  {
    primary_id: "D04XEG",
    names: [
      "Fenoterol",
      "Berotec",
      "Berotek",
      "Fenoterolum",
      "Phenoterol",
      "TH 1165A",
      "Berotec (TN)",
      "Fenoterolum [INN-Latin]",
      "P-Hydroxyphenylorciprenaline",
      "Th-1165a",
      "Fenoterol (USAN/INN)",
      "Fenoterol [USAN:BAN:INN]",
      "P-Hydroxyphenyl-Orciprenaline",
      "1-(3,5-Dihydroxyphenyl)-1-Hydroxy-2-((4-Hydroxyphenyl)Isopropylamino)Ethane",
      "1-(P-Hydroxyphenyl)-2-((Beta-Hydroxy-Beta-(3',5'-Dihydroxyphenyl))Ethyl)Aminopropane",
      "3,5-Dihydroxy-Alpha-(((P-Hydroxy-Alpha-Methylphenethyl)Amino)Methyl)Benzyl Alcohol",
      "5-(1-Hydroxy-2-{[1-(4-Hydroxyphenyl)Propan-2-Yl]amino}ethyl)Benzene-1,3-Diol",
      "5-(1-Hydroxy-2-{[2-(4-Hydroxyphenyl)-1-Methylethyl]amino}ethyl)Benzene-1,3-Diol",
      "5-[1-Hydroxy-2-[1-(4-Hydroxyphenyl)Propan-2-Ylamino]ethyl]benzene-1,3-Diol",
      "5-{1-Hydroxy-2-[2-(4-Hydroxy-Phenyl)-1-Methyl-Ethylamino]-Ethyl}-Benzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D04XEO",
    names: ["ChelASE"],
  },
  {
    primary_id: "D04XEQ",
    names: ["Imetelstat"],
  },
  {
    primary_id: "D04XFN",
    names: ["7-Phenyloxyethoxy-2-Morpholin-4-Yl-Chromen-4-One", "CHEMBL372804", "SCHEMBL9535095"],
  },
  {
    primary_id: "D04XGT",
    names: [
      "Benznidazole",
      "BENZNIDAZOLE",
      "Radanil",
      "Benzonidazole",
      "22994-85-0",
      "Benznidazol",
      "Benzonidazol",
      "Benznidazolum;Ro 07-1051",
      "Rochagan",
      "UNII-YC42NRJ1ZD",
      "Benznidazolum [INN-Latin]",
      "Benznidazol [INN-Spanish]",
      "Ro 71051",
      "NSC 299972",
      "Benznidazole [INN]",
      "CCRIS 2200",
    ],
  },
  {
    primary_id: "D04XGU",
    names: ["Crestor/TriLipix"],
  },
  {
    primary_id: "D04XGX",
    names: ["2-(2-Methoxybenzyl)-3H-Benzo[f]chromen-3-One", "CHEMBL469340"],
  },
  {
    primary_id: "D04XHE",
    names: ["PMID27967267-Compound-39"],
  },
  {
    primary_id: "D04XHR",
    names: [
      "Anti-Virulence Program, Antibiotic, Bacterial Infections",
      "Anti-Virulence Program (Antibiotic, Bacterial Infections)",
      "Anti-Virulence Program (Antibiotic, Bacterial Infections), Merlion",
      "Anti-Virulence Program (Antibiotic, Infection), Merlion/Athelas",
    ],
  },
  {
    primary_id: "D04XHX",
    names: ["Cyclo(-D-Tyr-D-Arg-L-Arg-L-Nal-Beta-Ala-)", "CHEMBL219135"],
  },
  {
    primary_id: "D04XIA",
    names: ["Meningococcus B Vaccine"],
  },
  {
    primary_id: "D04XIO",
    names: [
      "Dutogliptin",
      "Dutogliptin Tartrate",
      "PHX-1004",
      "PHX-1149",
      "PHX-1149T",
      "DPP IV Inhibitors (Diabetes), Phenomix",
      "DPP IV Program (Diabetes), Phenomix",
    ],
  },
  {
    primary_id: "D04XIV",
    names: ["Mesenchymal Precursor Cell"],
  },
  {
    primary_id: "D04XJE",
    names: ["REM-001"],
  },
  {
    primary_id: "D04XJG",
    names: ["BKR-013"],
  },
  {
    primary_id: "D04XJM",
    names: ["R1295", "CHEMBL2146615"],
  },
  {
    primary_id: "D04XJT",
    names: ["2-Methoxy-5-(3,4,5-Trimethoxy-Phenoxy)-Phenol", "CHEMBL356370", "BDBM50095326"],
  },
  {
    primary_id: "D04XJV",
    names: ["GRC-9332"],
  },
  {
    primary_id: "D04XJX",
    names: ["GP-1001"],
  },
  {
    primary_id: "D04XLU",
    names: [
      "Dendritic Cell Vaccine",
      "Dendritic Cell Vaccine (Prostate Tumor)",
      "ODC-0601",
      "Dendritic Cell Vaccine (Prostate Tumor), ODC",
    ],
  },
  {
    primary_id: "D04XLX",
    names: ["2-Amino-N-Benzyl-6-Phenyl-9H-Purine-9-Carboxamide", "CHEMBL259164"],
  },
  {
    primary_id: "D04XMJ",
    names: [
      "LY525327",
      "CHEMBL187941",
      "BDBM86753",
      "BDBM50168966",
      "LY-525327",
      "LY 525327",
      "LS-193664",
      "(3S,4aR,6S,8aR)-6-[2-(1H-Tetrazol-5-Yl)-Phenylamino]-Decahydro-Isoquinoline-3-Carboxylic Acid 2-Ethyl-Butyl Ester",
    ],
  },
  {
    primary_id: "D04XML",
    names: ["TRI-002"],
  },
  {
    primary_id: "D04XMN",
    names: [
      "NK-109",
      "1-Hydroxy-2-Methoxy-12-Methyl[1,3]benzodioxolo[5,6-C]phenanthridinium Chloride",
      "7-Hydroxy-8-Methoxy-5-Methyl-2,3-(Methylenedioxy)Benzo[c]phenanthridinium Chloride",
    ],
  },
  {
    primary_id: "D04XMZ",
    names: [
      "Al7089a",
      "AL7089A",
      "220402-83-5",
      "(R)-3,4-Dihydro-2-(3-Methoxyphenyl)-4-Methylamino-2h-Thieno[3,2-E]-1,2-Thiazine-6-Sulfonamide-1,1-Dioxide",
      "AL8",
      "1bnm",
      "AC1MHYPX",
      "SCHEMBL679736",
      "BDBM11936",
      "DTXSID20388458",
      "AL7089",
      "AL 7089",
      "DB02220",
      "(4R)-2-(3-Methoxyphenyl)-4-(Methylamino)-1,1-Dioxo-3H,4H-1$l^{6},7,2-Thieno[3,2-E][1$l^{6},2]thiazine-6-Sulfonamide",
      "(4R)-2-(3-Methoxyphenyl)-4-(Methylamino)-3,4-Dihydro-2H-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide 1,1-Dioxide",
      "2H-Thieno[3,2-E]-1,2-Thiazine-6-Sulfonamide, 3,4-Dihydro-2-(3-Methoxyphenyl)-4-(M",
    ],
  },
  {
    primary_id: "D04XNH",
    names: ["Propionic Acid 8-Aza-Bicyclo[3.2.1]oct-6-Yl Ester", "CHEMBL310558"],
  },
  {
    primary_id: "D04XNJ",
    names: ["PP3"],
  },
  {
    primary_id: "D04XNL",
    names: ["Ruxolitinib Derivative 1"],
  },
  {
    primary_id: "D04XNQ",
    names: ["BioScavenger", "Butyrylcholinesterase"],
  },
  {
    primary_id: "D04XOE",
    names: ["ISIS 112019"],
  },
  {
    primary_id: "D04XOT",
    names: [
      "2-(3-Hydroxy-Phenyl)-6-Methyl-Chromen-4-One",
      "3'-HYDROXY-6-METHYLFLAVONE",
      "468060-75-5",
      "SCHEMBL5424662",
      "CHEMBL336559",
      "CTK6C0232",
      "BNOQFZSTTRNHCN-UHFFFAOYSA-N",
      "ZINC13491192",
      "3'-Hydroxy-6-Methylflavone, AldrichCPR",
      "RT-008155",
      "4H-1-Benzopyran-4-One, 2-(3-Hydroxyphenyl)-6-Methyl-",
    ],
  },
  {
    primary_id: "D04XOX",
    names: ["Malate Synthase"],
  },
  {
    primary_id: "D04XPD",
    names: ["DV-0100"],
  },
  {
    primary_id: "D04XPJ",
    names: [
      "Biphenyl-4-Carboxylic Acid Hydroxyamide",
      "Biphenylhydroxamic Acid",
      "UNII-R3GRG20IPQ",
      "R3GRG20IPQ",
      "CHEMBL154585",
      "106359-54-0",
      "4-Biphenyl Hydroxamic Acid",
      "(1,1'-Biphenyl)-4-Carboxamide, N-Hydroxy-",
      "4-Phenylbenzohydroximic Acid",
      "SCHEMBL2143958",
      "DTXSID00147585",
      "JWLKPHOMARIZAB-UHFFFAOYSA-N",
      "BDBM50015086",
    ],
  },
  {
    primary_id: "D04XPW",
    names: [
      "Cocaine",
      "Allococaine",
      "Allopseudococaine",
      "Badrock",
      "Bazooka",
      "Benzoylethylecgonine",
      "Benzoylmethylecgonine",
      "Bernice",
      "Bernies",
      "Blast",
      "Blizzard",
      "Blow",
      "Burese",
      "COC",
      "Cabello",
      "Candy",
      "Carrie",
      "Caviar",
      "Cecil",
      "Charlie",
      "Cholly",
      "Coca",
      "Cocain",
      "Cocaina",
      "Cocainum",
      "Cocktail",
      "Coke",
      "Cola",
      "Corine",
      "Crack",
      "Eritroxilina",
      "Erytroxylin",
      "Flake",
      "Flex",
      "Freeze",
      "Girl",
      "Goofball",
      "Heaven",
      "Hell",
      "Jam",
      "Kokain",
      "Kokan",
      "Kokayeen",
      "Lady",
      "Leaf",
      "Moonrocks",
      "Neurocaine",
      "Pseudoallococaine",
      "Rock",
      "Sleighride",
      "Snort",
      "Snow",
      "Toke",
      "Toot",
      "Trails",
      "Yeyo",
      "Bernice [Street Name]",
      "Blow [Street Name]",
      "Bouncing Powder",
      "Cecil [Street Name]",
      "Chicken Scratch",
      "Cocaine [BAN]",
      "Cocaine Free Base",
      "Cocaine Solution",
      "Crack Cocaine",
      "Dama Blanca",
      "Ecgonine Methyl Ester Benzoate",
      "Ecgonine Methyl Ester Benzoate Solution",
      "Flake [Street Name]",
      "Florida Snow",
      "Foo Foo",
      "Girl [Street Name]",
      "Gold Dust",
      "Gold Dust [Street Name]",
      "Green Gold",
      "Happy Dust",
      "Happy Dust [Street Name]",
      "Happy Powder",
      "Happy Trails",
      "Lady [Street Name]",
      "Methyl Benzoylecgonine",
      "Nose Candy",
      "Prime Time",
      "Rock [Street Name]",
      "Sweet Stuff",
      "Toot [Street Name]",
      "White Girl Or Lady",
      "Beta-Cocain",
      'C" Carrie',
      "Cocaine (TN)",
      "Cocaine (USP)",
      "Cocaine-M",
      "G-Rock",
      "Kibbles N' Bits",
      "L-Cocain",
      "L-Cocaine",
      "Pimp's Drug",
      "Snow (Birds)",
      "Star-Spangled Powder",
      "Ecgonine, Methyl Ester, Benzoate (Ester)",
      "Methyl 3beta-Hydroxy-1alphaH,5alphaH-Tropane-2beta-Carboxylate Benzoate (Ester)",
      "Methyl 3-Benzoyloxy-8-Methyl-8-Azabicyclo[3.2.1]octane-4-Carboxylate",
      "Methyl 3-(Benzoyloxy)-8-Methyl-8-Azabicyclo[3.2.1]octane-2-Carboxylate",
      "Methyl (3S,4R)-3-Benzoyloxy-8-Methyl-8-Azabicyclo[3.2.1]octane-4-Carboxylate",
      "Methyl (1S,4R,5R)-3-Benzoyloxy-8-Methyl-8-Azabicyclo[3.2.1]octane-4-Carboxylate",
      "(-)-Cocaine",
      "(-)-Cocaine Base",
      "(1R,2R,3S,5S)-2-(Methoxycarbonyl)Tropan-3-Yl Benzoate",
      "(1R,2R,3S,5S)-2-Methoxycarbonyltropan-3-Yl Benzoate",
      "(R)-Cocaine",
      "1-Cocaine",
      "2-Beta-Carbomethoxy-3-Beta-Benzoxytropane",
      "2-Beta-Tropanecarboxylic Acid, 3-Beta-Hydroxy-, Methyl Ester, Benzoate (Ester)",
      "2beta-Carbomethoxy-3beta-Benzoxytropane",
      "3-Tropanylbenzoate-2-Carboxylic Acid Methyl Ester",
      "3beta-Hydroxy-2beta-Tropanecarboxylic Acid Methyl Ester Benzoate (Ester)",
    ],
  },
  {
    primary_id: "D04XPZ",
    names: [
      "N-Hydroxy-8-(Naphthalen-2-Yl)Non-8-Enamide",
      "CHEMBL95535",
      "SCHEMBL3382075",
      "BDBM50114823",
      "8-Naphthalen-2-Yl-Non-8-Enoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D04XQG",
    names: ["NCX-1067"],
  },
  {
    primary_id: "D04XRV",
    names: ["6-Azido-8-(3-Iodo-Phenyl)-Quinoline", "CHEMBL124575", "6-Azido-8-(3-Iodophenyl)Quinoline"],
  },
  {
    primary_id: "D04XSG",
    names: ["Mab Therapy, Cancer, Igenica"],
  },
  {
    primary_id: "D04XSP",
    names: ["Alpha-Fetoprotein Cancer Vaccine", "Alpha-Fetoprotein Cancer Vaccine (Hepatocellular Carcinoma)"],
  },
  {
    primary_id: "D04XTK",
    names: ["SRM003"],
  },
  {
    primary_id: "D04XTP",
    names: ["RWJ-53419"],
  },
  {
    primary_id: "D04XUJ",
    names: ["CBT-101"],
  },
  {
    primary_id: "D04XUV",
    names: ["5-Hexyl-2-(3-Nitrophenoxy)Phenol", "CHEMBL264416"],
  },
  {
    primary_id: "D04XVE",
    names: ["QAX-028", "Bronchodilatory Agent (Inhaled, COPD), Novartis"],
  },
  {
    primary_id: "D04XVI",
    names: ["CNTO-607"],
  },
  {
    primary_id: "D04XVJ",
    names: ["LEO-29102"],
  },
  {
    primary_id: "D04XVN",
    names: [
      "Trametinib",
      "871700-17-3",
      "GSK1120212",
      "Mekinist",
      "GSK-1120212",
      "GSK 1120212",
      "Trametinib (GSK1120212)",
      "JTP-74057",
      "JTP 74057",
      "N-[3-[3-Cyclopropyl-5-[(2-Fluoro-4-Iodophenyl)Amino]-3,4,6,7-Tetrahydro-6,8-Dimethyl-2,4,7-Trioxopyrido[4,3-D]pyrimidin-1(2H)-Yl]phenyl]acetamide",
      "UNII-33E86K87QN",
      "Trametinib (GSK1120212JTP 74057)",
      "CHEBI:75998",
      "33E86K87QN",
      "AK174783",
      "A1-01871",
      "N-(3-{3-Cyclopropyl-5-[(2-Fluoro-4-Iodophenyl)Amino]-6,8-Dimethyl-2,4,7-Trioxo-3,4,6,7-Tetrahydropyrido[4,3-D]pyrimidin-1(2H)-Yl}phenyl)Acetamide",
      "Trametinib (MEK Inhibitor)",
    ],
  },
  {
    primary_id: "D04XVU",
    names: ["GKT-137831"],
  },
  {
    primary_id: "D04XWX",
    names: ["PS-178990"],
  },
  {
    primary_id: "D04XXE",
    names: [
      "CORONARIDINE",
      "Coronardine",
      "CHEMBL364613",
      "CHEBI:3887",
      "(-)-Coronaridine",
      "NSC127490",
      "Ibogamine-18-Carboxylic Acid, Methyl Ester",
      "Methyl Ibogamine-18-Carboxylate",
      "467-77-6",
      "AC1L2JXS",
      "NVVDQMVGALBDGE-KSWFMABOSA-N",
      "BDBM50329102",
    ],
  },
  {
    primary_id: "D04XXF",
    names: ["Peptide Analog 49"],
  },
  {
    primary_id: "D04XYL",
    names: ["2'3'-Dideoxyinosine"],
  },
  {
    primary_id: "D04XZE",
    names: [
      "NS-7",
      "(3r)-3-Cyclopentyl-6-Methyl-7-[(4-Methylpiperazin-1-Yl)Sulfonyl]-3,4-Dihydro-2h-1,2-Benzothiazine 1,1-Dioxide",
      "DB08305",
      "NS7",
    ],
  },
  {
    primary_id: "D04XZP",
    names: ["DIHYDROKAINATE"],
  },
  {
    primary_id: "D04YAJ",
    names: [
      "SELODENOSON",
      "DT-009",
      "DTI-0009",
      "RG-14202",
      "Selodenoson",
      "N6-Cyclopentyl-N5'-Ethyladenosine-5'-Uronamide",
    ],
  },
  {
    primary_id: "D04YAK",
    names: ["MPC-2130"],
  },
  {
    primary_id: "D04YCE",
    names: ["Pidotimod"],
  },
  {
    primary_id: "D04YCJ",
    names: ["1-((S)-2-Aminopropyl)-7-Fluoro-1H-Indazol-6-Ol"],
  },
  {
    primary_id: "D04YCQ",
    names: ["GEN-003"],
  },
  {
    primary_id: "D04YEW",
    names: ["Tarafenacin"],
  },
  {
    primary_id: "D04YEY",
    names: ["PS-388023"],
  },
  {
    primary_id: "D04YFA",
    names: ["VAX-161"],
  },
  {
    primary_id: "D04YGT",
    names: ["VAL-001"],
  },
  {
    primary_id: "D04YHG",
    names: ["ErepoXen"],
  },
  {
    primary_id: "D04YHJ",
    names: ["Meninge B"],
  },
  {
    primary_id: "D04YHR",
    names: ["Samarium Sm-153 Lexidronam Pentasodium"],
  },
  {
    primary_id: "D04YHW",
    names: [
      "R-1487",
      "449811-92-1",
      "6-(2,4-Difluorophenoxy)-8-Methyl-2-((Tetrahydro-2H-Pyran-4-Yl)Amino)Pyrido[2,3-D]pyrimidin-7(8H)-One",
      "UNII-IO0DCY55NQ",
      "IO0DCY55NQ",
      "CHEMBL1230122",
      "6-(2,4-Difluorophenoxy)-8-Methyl-2-[(Tetrahydro-2H-Pyran-4-Yl)Amino]-Pyrido[2,3-D]pyrimidin-7(8H)-One",
      "6-(2,4-Difluorophenoxy)-8-Methyl-2-(Tetrahydro-2h-Pyran-4-Ylamino)Pyrido[2,3-D]pyrimidin-7(8h)-One",
      "Kinome_3762",
      "SCHEMBL5120612",
      "KKKRKRMVJRHDMG-UHFFFAOYSA-N",
      "HMS3401C13",
      "BDBM50341342",
      "ZINC58633224",
      "AKOS027420928",
      "NCGC00262195-02",
      "KB-80224",
      "ACM449811921",
    ],
  },
  {
    primary_id: "D04YHZ",
    names: ["3-Methoxycarbonyl-2-Methyl-9H-Beta-Carbolin-2-Ium", "CHEMBL97741"],
  },
  {
    primary_id: "D04YIA",
    names: ["CNVN-202"],
  },
  {
    primary_id: "D04YIV",
    names: [
      "2-(Phenylsulfonamido)Acetic Acid",
      "5398-96-9",
      "Benzenesulfonylamino-Acetic Acid",
      "N-(Phenylsulfonyl)Glycine",
      "2-Benzenesulfonamidoacetic Acid",
      "CHEMBL37054",
      "2-(Benzenesulfonamido)Acetic Acid",
      "2-[(Phenylsulfonyl)Amino]acetic Acid",
      "N-Benzenesulfonylglycine",
      "N-Benzenesulfonyl-Glycine",
      "ChemDiv3_006100",
      "CBChromo1_000178",
      "AC1Q6W5M",
      "Oprea1_227014",
      "Oprea1_645532",
      "CBDivE_002981",
      "SCHEMBL359770",
      "AC1Q763K",
      "NSC3682",
      "MolPort-000-182-647",
      "WTSZSAHZIMPSDM-UHFFFAOYSA-N",
      "ZINC187388",
      "HMS1490F06",
      "NSC-3682",
      "AC1L5954",
      "NSC121986",
      "STL477593",
      "SBB015317",
    ],
  },
  {
    primary_id: "D04YJN",
    names: ["R411"],
  },
  {
    primary_id: "D04YJO",
    names: ["CGS-19480A", "3-(Dipropylamino)-5-Ethoxy-3,4-Dihydro-2H-[1]-Benzothiopyran Hydrochloride"],
  },
  {
    primary_id: "D04YJU",
    names: [
      "L-755507",
      "159182-43-1",
      "L755507",
      "L-755,507",
      "CHEMBL12998",
      "4-[[(HEXYLAMINO)CARBONYL]AMINO]-N-[4-[2-[[(2S)-2-HYDROXY-3-(4-HYDROXYPHENOXY)PROPYL]AMINO]ETHYL]PHENYL]-BENZENESULFONAMIDE",
      "L 755507",
      "SCHEMBL5165122",
      "GTPL3931",
      "MolPort-003-983-607",
      "ZINC3939153",
      "EX-A2397",
      "S7974",
      "BDBM50070156",
      "AKOS024456983",
      "CP-0074",
      "CS-7782",
      "NCGC00092331-01",
      "HY-19334",
      "F10055",
      "L755507, &gt",
      "J-009574",
      "L-755 507",
      "(S)-4-(3-Hexylureido)-N-(4-(2-(1-Hydroxy-2-(4-Hydroxyphenoxy)Ethylamino)Ethyl)Phenyl)Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D04YKA",
    names: ["ISIS 6553"],
  },
  {
    primary_id: "D04YKM",
    names: ["Microfibrillar Collagen Hemostat"],
  },
  {
    primary_id: "D04YKO",
    names: ["Piperidine Derivative 2"],
  },
  {
    primary_id: "D04YKQ",
    names: ["NaQuinate"],
  },
  {
    primary_id: "D04YKV",
    names: ["PMID28454500-Compound-93"],
  },
  {
    primary_id: "D04YKY",
    names: ["NT-502"],
  },
  {
    primary_id: "D04YLQ",
    names: ["AMG 334"],
  },
  {
    primary_id: "D04YLU",
    names: ["AZ-599", "CB1 Agonist (Pain), AstraZeneca"],
  },
  {
    primary_id: "D04YMH",
    names: ["Lumiracoxib"],
  },
  {
    primary_id: "D04YNI",
    names: ["WY-48989"],
  },
  {
    primary_id: "D04YNS",
    names: ["2-Amino-4-(2-Methyl-Benzylsulfanyl)-Butyric Acid", "CHEMBL203743", "BDBM50179732"],
  },
  {
    primary_id: "D04YNW",
    names: [
      "BRL 52537 Hydrochloride",
      "2-(3,4-Dichlorophenyl)-1-(2-(Pyrrolidin-1-Ylmethyl)Piperidin-1-Yl)Ethanone Hydrochloride",
      "130497-33-5",
      "112282-24-3",
      "BRL 52537",
      "AK-57392",
      "(+/-)-1-(3,4-Dichlorophenyl)Acetyl-2-(1-Pyrrolidinyl)Methylpiperidine Hydrochloride",
      "2-(3,4-DICHLOROPHENYL)-1-(2-(PYRROLIDIN-1-YLMETHYL)PIPERIDIN-1-YL)ETHANONE HCL",
      "SR-01000076141",
      "EU-0101141",
      "BRL-52537 Hydrochloride",
      "Brl 52537 HCl",
      "MLS002153457",
      "SCHEMBL8435659",
      "CHEMBL1256835",
      "KS-00000FTT",
      "CTK8C4721",
      "DTXSID00462129",
      "MolPort-003-940-484",
      "BCP21081",
      "EBD29079",
    ],
  },
  {
    primary_id: "D04YPN",
    names: ["Treosulfan"],
  },
  {
    primary_id: "D04YPP",
    names: [
      "5-Methyl-3,4-Diphenyl-Isoxazole",
      "5-Methyl-3,4-Diphenylisoxazole",
      "37928-17-9",
      "5-Methyl-3,4-Diphenyl-1,2-Oxazole",
      "CHEMBL365033",
      "Isoxazole, 5-Methyl-3,4-Diphenyl-",
      "AK110518",
      "SCHEMBL2277199",
      "3,4-Diphenyl-5-Methylisoxazole",
      "DTXSID40433813",
      "3,4-Diphenyl-5-Methyl Isoxazole",
      "5-Methyl-3,4-Diphenyl Isoxazole",
      "3,4-Diphenyl-5-Methyl-Isoxazole",
      "ZXIRUKJWLADSJS-UHFFFAOYSA-N",
      "MolPort-027-835-429",
      "KS-00000PQ9",
      "Isoxazole, 5-Methyl-3,4-Diphenyl- (Parecoxib SodiuM InteMediate)",
      "ZINC12352313",
      "BDBM50153036",
      "AKOS016008848",
      "VO10067",
      "DS-4629",
      "LS40778",
    ],
  },
  {
    primary_id: "D04YPZ",
    names: ["Decapeptide Allergy Vaccine"],
  },
  {
    primary_id: "D04YQF",
    names: ["2-(1H-Pyrazol-3-Yl)-1H-Benzimidazole"],
  },
  {
    primary_id: "D04YQN",
    names: ["ISIS 29135"],
  },
  {
    primary_id: "D04YQS",
    names: ["PknB Inhibitor"],
  },
  {
    primary_id: "D04YRD",
    names: ["DTX101"],
  },
  {
    primary_id: "D04YSC",
    names: ["CD19 CAR T Cells"],
  },
  {
    primary_id: "D04YSK",
    names: [
      "Pagibaximab",
      "BSYX-A110",
      "HU96-110",
      "Staphylococcal Antibodies, Biosynexus/GlaxoSmithKline",
      "Staphylococcal Antibodies, Sunol/Biosynexus",
    ],
  },
  {
    primary_id: "D04YSQ",
    names: ["HepaXen"],
  },
  {
    primary_id: "D04YSY",
    names: ["Recombinant NOV"],
  },
  {
    primary_id: "D04YTE",
    names: ["ROCAGLAMIDE"],
  },
  {
    primary_id: "D04YTO",
    names: ["6-Fluoro-N-M-Tolylquinazolin-4-Amine", "CHEMBL1079598", "SCHEMBL8427296", "BDBM50311857"],
  },
  {
    primary_id: "D04YTT",
    names: ["DAV-148"],
  },
  {
    primary_id: "D04YTX",
    names: ["HPy-HP 097"],
  },
  {
    primary_id: "D04YUA",
    names: ["Octane-1-Sulfonyl Fluoride"],
  },
  {
    primary_id: "D04YUM",
    names: [
      "9-Butyl-9H-Adenine",
      "2715-70-0",
      "9-Butyl-9H-Purin-6-Ylamine",
      "9H-Purin-6-Amine, 9-Butyl-",
      "9-Butyl-9H-Purin-6-Amine",
      "9H-Purin-6-Amine, 9-Butyl- (9CI)",
      "CHEMBL449628",
      "9-Butylpurin-6-Amine",
      "9-Butyladenine",
      "NSC19094",
      "NSC 19094",
      "Adenine, 9-Butyl-",
      "AC1L2PTM",
      "AC1Q4VHO",
      "9H-Purin-6-Amine,9-Butyl-",
      "SCHEMBL1074972",
      "CTK4F9171",
      "DTXSID10181617",
      "MolPort-011-342-029",
      "BKXMJMZKKNIYRD-UHFFFAOYSA-N",
      "ZINC1562338",
      "NSC-19094",
      "BDBM50256951",
      "AKOS012922662",
      "MCULE-9977877843",
      "9-Butyl-9H-Purin-6-Amine, AldrichCPR",
    ],
  },
  {
    primary_id: "D04YVI",
    names: ["Antiangiogenic Surrobodies", "Antiangiogenic Surrobodies (Cancer)"],
  },
  {
    primary_id: "D04YVS",
    names: ["3-{4-[(Aminosulfonyl)Oxy]benzoyl}phenyl Sulfamate", "CHEMBL65211"],
  },
  {
    primary_id: "D04YWM",
    names: ["Atacicept"],
  },
  {
    primary_id: "D04YWS",
    names: ["RAGE-Fc Fusion Protein"],
  },
  {
    primary_id: "D04YWU",
    names: ["ISIS 11220"],
  },
  {
    primary_id: "D04YWX",
    names: ["S22153"],
  },
  {
    primary_id: "D04YXB",
    names: [
      "2-Phenyl-1,8-Naphthyridine",
      "CHEMBL235396",
      "SKVWGHJCDNDXAG-UHFFFAOYSA-N",
      "SCHEMBL5309600",
      "1,8-Naphthyridine, 2-Phenyl-",
      "ZINC28826013",
    ],
  },
  {
    primary_id: "D04YXL",
    names: [
      "PEGylated IFN Beta 1-A",
      "BIIB-017",
      "PEG-Avonex",
      "PEGylated IFN Beta 1-A (Multiple Sclerosis)",
      "PEGylated IFN Beta 1-A (Multiple Sclerosis), Biogen Idec",
      "PEGylated Interferon Beta 1-A (Multiple Sclerosis), Biogen Idec",
    ],
  },
  {
    primary_id: "D04YZL",
    names: ["Sodium Chloride"],
  },
  {
    primary_id: "D04YZS",
    names: ["KIT-302", "4-(1-Oxoisochromene-4-Carbonyl)Benzoic Acid", "CTK8J6900", "62416-90-4"],
  },
  {
    primary_id: "D04YZZ",
    names: ["C[CO-(CH2)3-CO-Pro-D-Nal(2)-Arg-Trp-Lys]-NH2", "CHEMBL398665"],
  },
  {
    primary_id: "D04ZAH",
    names: ["Piperacillin"],
  },
  {
    primary_id: "D04ZAO",
    names: ["7-Hydroxy-Pyrazolo[4,3-D]Pyrimidine"],
  },
  {
    primary_id: "D04ZAQ",
    names: ["Small Molecule Boron-Based Therapeutics, Acne"],
  },
  {
    primary_id: "D04ZBA",
    names: ["NVLS/FX-18A", "[18F]FXA", "NVLS / FX_18A"],
  },
  {
    primary_id: "D04ZBT",
    names: ["Icatibant"],
  },
  {
    primary_id: "D04ZBU",
    names: ["JTT-302", "CETP Inhibitor (Oral, Dyslipidemia), Japan Tobacco"],
  },
  {
    primary_id: "D04ZBV",
    names: [
      "VU-29",
      "4-Nitro-N-(1,3-Diphenyl-1H-Pyrazol-5-Yl)Benzamide",
      "VU 29",
      "890764-36-0",
      "DPAP",
      "N-(1,3-Diphenyl-1H-Pyrazol-5-Yl)-4-Nitrobenzamide",
      "CHEMBL411221",
      "N-(2,5-Diphenylpyrazol-3-Yl)-4-Nitro-Benzamide",
      "SCHEMBL1821818",
      "VU29",
      "GTPL6403",
      "KS-00002WSR",
      "DTXSID60469437",
      "MolPort-020-313-399",
      "BCP27694",
      "ZINC13704338",
      "BDBM50187615",
      "AKOS005266482",
      "ES-0027",
      "NCGC00370929-02",
      "AN-16932",
      "B7668",
      "FT-0704727",
      "N-[2,5-Di(Phenyl)Pyrazol-3-Yl]-4-Nitrobenzamide",
    ],
  },
  {
    primary_id: "D04ZCL",
    names: ["VZ-564"],
  },
  {
    primary_id: "D04ZCZ",
    names: ["GINKOLIDE B"],
  },
  {
    primary_id: "D04ZDD",
    names: ["LONCHOCARPUSONE"],
  },
  {
    primary_id: "D04ZDI",
    names: ["N-Hydroxy-4-((R)-2-Phenyl-Butyrylamino)-Benzamide"],
  },
  {
    primary_id: "D04ZEP",
    names: ["A-849531"],
  },
  {
    primary_id: "D04ZEX",
    names: ["Pyrazole Derivative 85"],
  },
  {
    primary_id: "D04ZFH",
    names: ["2-(4-Phenoxy-Benzyl)-1H-Benzoimidazole", "CHEMBL67249", "SCHEMBL7510229"],
  },
  {
    primary_id: "D04ZFO",
    names: ["WVE-120102"],
  },
  {
    primary_id: "D04ZGF",
    names: [
      "5-(2-Imidazol-1-Yl-Ethyl)-7,8-Dihydro-Quinoline",
      "CHEMBL293122",
      "Dihydroquinoline 31",
      "BDBM10027",
      "ZINC13808192",
      "5-[2-(Imidazol-1-Yl)Ethyl]-7,8-Dihydroquinoline",
      "5-[2-(1H-Imidazol-1-Yl)Ethyl]-7,8-Dihydroquinoline",
    ],
  },
  {
    primary_id: "D04ZGR",
    names: ["Phenanthrene-3-Carboxylic Acid Hydroxyamide"],
  },
  {
    primary_id: "D04ZHJ",
    names: ["TNF Alpha Kinoid"],
  },
  {
    primary_id: "D04ZIA",
    names: ["ISIS 9071"],
  },
  {
    primary_id: "D04ZIJ",
    names: [
      "Pepstatin",
      "AC1NUZ3J",
      "L-Valinamide, N-(3-Methyl-1-Oxobutyl)-L-Valyl-N-(4-((2-((1-(2-Carboxy-1-Hydroxyethyl)-3-Methylbutyl)Amino)-1-Methyl-2-Oxoethyl)Amino)-2-Hydroxy-1-(2-Methylpropyl)-4-Oxobutyl)-, (1S-(1R*,2R*,4(R*(R*(R*)))))-",
      "N-Isovaleryl-L-Valyl-L-Valyl-3-Hydroxy-6-Methyl-Gamma-Aminoheptanoyl-L-Alanyl-3-Hydroxy-6-Methyl-Gamma-Aminoheptanoic Acid",
    ],
  },
  {
    primary_id: "D04ZIO",
    names: ["NBI-640756"],
  },
  {
    primary_id: "D04ZIP",
    names: [
      "CBIQ",
      "4-Chlorobenzo[f]isoquinoline",
      "32081-28-0",
      "SCHEMBL610880",
      "GTPL4152",
      "CHEMBL1372588",
      "CTK8F8501",
      "DTXSID30464737",
      "HMS3260E20",
      "ZINC2577904",
      "Tox21_500109",
      "CBIQ, &gt",
      "MFCD02179769",
      "AKOS017550392",
      "CCG-221413",
      "LP00109",
      "NCGC00093606-01",
      "NCGC00093606-05",
      "NCGC00260794-01",
      "NCGC00093606-03",
      "NCGC00093606-04",
      "NCGC00093606-02",
      "KB-38182",
      "UX00000203",
      "FT-0618182",
    ],
  },
  {
    primary_id: "D04ZIV",
    names: ["WO2012007375C1"],
  },
  {
    primary_id: "D04ZIZ",
    names: ["DMUC-5754A"],
  },
  {
    primary_id: "D04ZJG",
    names: ["PI-88", "CHEMBL603733"],
  },
  {
    primary_id: "D04ZJK",
    names: [
      "BIS(20)-HUPERZINE B",
      "Bis(20)-Huperzine B",
      "Bis-Huperzine B 5h",
      "Bis(20)-HupB",
      "CHEMBL368897",
      "BDBM10948",
    ],
  },
  {
    primary_id: "D04ZJL",
    names: ["8-Bromo-3-Hydroxy-1H-Benzo[b]azepine-2,5-Dione"],
  },
  {
    primary_id: "D04ZJO",
    names: ["(-)-Huperzine B", "SCHEMBL2759896", "BDBM10632"],
  },
  {
    primary_id: "D04ZJP",
    names: ["HSR-113"],
  },
  {
    primary_id: "D04ZJQ",
    names: ["VN/13-1RA"],
  },
  {
    primary_id: "D04ZKY",
    names: ["SEP-363856"],
  },
  {
    primary_id: "D04ZLC",
    names: ["N-(Arylalkyl)-1H-Indole-2-Sulfonic Acid Amide Derivative 1"],
  },
  {
    primary_id: "D04ZLG",
    names: ["IDEC-151", "Clenoliximab"],
  },
  {
    primary_id: "D04ZLJ",
    names: ["LOSOXANTRONE"],
  },
  {
    primary_id: "D04ZLZ",
    names: [
      "N4-[4-(Phenylmethoxy)Phenyl]-L-Aspartamine",
      "Amino Acid Analog, 4e",
      "CHEMBL517209",
      "BDBM24255",
      "(2S)-2-Amino-3-{[4-(Benzyloxy)Phenyl]carbamoyl}propanoic Acid",
    ],
  },
  {
    primary_id: "D04ZMM",
    names: ["PMID24900311C1a"],
  },
  {
    primary_id: "D04ZMS",
    names: ["3-(4-Benzyl-Piperidin-1-Ylmethyl)-Chroman-4-One", "CHEMBL178519"],
  },
  {
    primary_id: "D04ZND",
    names: ["PGL-2001"],
  },
  {
    primary_id: "D04ZNG",
    names: ["LF-08-0133"],
  },
  {
    primary_id: "D04ZNL",
    names: ["RG-7234"],
  },
  {
    primary_id: "D04ZOI",
    names: ["4-(8-Phenyl-Oct-3-Ynyl)-1H-Imidazole", "CHEMBL14811", "SCHEMBL7621857", "ZINC13795232", "BDBM50070216"],
  },
  {
    primary_id: "D04ZOZ",
    names: ["MS-322"],
  },
  {
    primary_id: "D04ZPF",
    names: ["4-(2-(2-Fluoro-5-Methylphenoxy)Phenyl)Piperidine", "CHEMBL583332"],
  },
  {
    primary_id: "D04ZPU",
    names: [
      "2-(Naphthalen-2-Yl)-4,5-Dihydro-1H-Imidazole",
      "CHEMBL266155",
      "2-(2-Naphthyl)-1-Imidazoline",
      "2-Naphthalen-2-Yl-4,5-Dihydro-1H-Imidazole",
      "113698-36-5",
      "SCHEMBL9575180",
      "DTXSID8048442",
      "BGWRJAXPPYHIRG-UHFFFAOYSA-N",
      "ZINC13805903",
    ],
  },
  {
    primary_id: "D04ZQB",
    names: ["KPT-9274"],
  },
  {
    primary_id: "D04ZQC",
    names: ["3-Hydroxyphenethyl 3,4,5-Trihydroxybenzoate", "CHEMBL246622", "Gallic Acid 3-Hydroxyphenethyl Ester"],
  },
  {
    primary_id: "D04ZQJ",
    names: [
      "(E)-N-(4-Phenylthiazol-2-Yl) Cinnamamide",
      "CHEMBL452100",
      "2-Cinnamamido-4-Phenylthiazole",
      "(2E)-3-Phenyl-N-(4-Phenyl-1,3-Thiazol-2-Yl)Prop-2-Enamide",
      "1107621-03-3",
      "AC1LEPJR",
      "ZINC60264",
      "WOJRHCOBUKJCAJ-VAWYXSNFSA-N",
      "MolPort-019-760-060",
      "MolPort-001-931-977",
      "HMS1397P15",
      "STK173781",
      "BDBM50255301",
      "N-(4-Phenylthiazol-2-Yl)Cinnamamide",
      "AKOS000523355",
      "MCULE-8934603681",
      "BAS 00417267",
      "ST4016450",
      "3-Phenyl-N-(4-Phenyl-Thiazol-2-Yl)-Acrylamide",
      "AG-690/11629440",
      "3-Phenyl-N-(4-Phenyl-1,3-Thiazol-2-Yl)Acrylamide",
      "F0298-0058",
      "A0793/0037152",
    ],
  },
  {
    primary_id: "D04ZQL",
    names: ["But-3-Enyl-Trimethyl-Ammonium Bromide"],
  },
  {
    primary_id: "D04ZQO",
    names: ["TG-2349"],
  },
  {
    primary_id: "D04ZQX",
    names: ["4-Phenyl-1-(1-Phenylheptyl)Piperidin-4-Ol", "CHEMBL231607"],
  },
  {
    primary_id: "D04ZSL",
    names: ["SB-313-XTZ"],
  },
  {
    primary_id: "D04ZSZ",
    names: ["DS-AH-14"],
  },
  {
    primary_id: "D04ZTA",
    names: ["ANA-5"],
  },
  {
    primary_id: "D04ZTB",
    names: [
      "CGRP Pepducins",
      "CGRP Pepducins (Lipopeptide, Pain)",
      "CGRP Pepducins (Lipopeptide, Pain), Anchor Therapeutics",
      "Calcitonin Gene-Related Peptidemodulators (Lipopeptide, Pain), Anchor Therapeutic",
    ],
  },
  {
    primary_id: "D04ZTH",
    names: [
      "2-Amino-4,6-Di-Thiophen-2-Yl-Nicotinonitrile",
      "CHEMBL455264",
      "2-Amino-4,6-Di(2-Thienyl)Nicotinonitrile",
      "AC1MDUJS",
      "Maybridge3_002859",
      "AK-245/12665006",
      "MolPort-002-818-186",
      "HMS1439B21",
      "CCG-51878",
      "BDBM50262435",
      "ZINC19815853",
      "MCULE-1768454862",
      "IDI1_014246",
      "SR-01000003726",
      "SR-01000003726-1",
      "2-Amino-4,6-Dithiophen-2-Ylpyridine-3-Carbonitrile",
      "SR-01000003726-2",
    ],
  },
  {
    primary_id: "D04ZTY",
    names: [
      "Voglibose",
      "Basen",
      "Glustat",
      "Vocarb",
      "Basen OD",
      "A-71100",
      "AO-128",
      "Basen (TN)",
      "Basen (Takeda Chemical Industries)",
      "Volix (Ranbaxy Labs);Voglibose (JP15/USAN/INN)",
      "N-(1,3-Dihydroxyprop-2-Yl)Valiolamine",
      "(1S,2S,3R,4S,5S)-5-(1,3-Dihydroxypropan-2-Ylamino)-1-(Hydroxymethyl)Cyclohexane-1,2,3,4-Tetrol",
      "(1S,2S,3R,4S,5S)-5-{[2-Hydroxy-1-(Hydroxymethyl)Ethyl]amino}-1-(Hydroxymethyl)Cyclohexane-1,2,3,4-Tetrol",
      "3,4-Dideoxy-4-{[2-Hydroxy-1-(Hydroxymethyl)Ethyl]amino]-2-D-Epi-Inositol",
    ],
  },
  {
    primary_id: "D04ZUU",
    names: ["AKT-10081", "Antimicrobial Peptide (Clostridium Difficile), Akthelia"],
  },
  {
    primary_id: "D04ZVO",
    names: ["ZS-9", "Cation Exhange Agent (Oral Sorbent, Hyperkalemia), ZS Pharma"],
  },
  {
    primary_id: "D04ZWD",
    names: ["EZN-4150"],
  },
  {
    primary_id: "D04ZWF",
    names: ["CHIR-98014", "CHIR98014", "CHIR 98014"],
  },
  {
    primary_id: "D04ZWH",
    names: ["UK-279,276"],
  },
  {
    primary_id: "D04ZWL",
    names: ["Mimosine"],
  },
  {
    primary_id: "D04ZWQ",
    names: ["Org-25435"],
  },
  {
    primary_id: "D04ZWS",
    names: ["DEMETHYLDEBROMOFLUSTRAMINE B", "Demethyldebromoflustramine B", "CHEMBL446242"],
  },
  {
    primary_id: "D04ZXE",
    names: ["MSC2363318A"],
  },
  {
    primary_id: "D04ZXP",
    names: ["Dicycloplatin"],
  },
  {
    primary_id: "D04ZXR",
    names: ["PMID25656651-Compound-23a"],
  },
  {
    primary_id: "D04ZYA",
    names: [
      "3-(Octahydro-Indolizin-8-Yl)-Phenol",
      "CHEMBL268240",
      "SCHEMBL9406084",
      "BDBM50010616",
      "AKOS022652621",
      "(-)-3-(Octahydro-Indolizin-8-Yl)-Phenol",
      "(+)-3-(Octahydro-Indolizin-8-Yl)-Phenol",
      "Isomer-13-(Octahydro-Indolizin-8-Yl)-Phenol",
    ],
  },
  {
    primary_id: "D04ZYP",
    names: ["Cat-SPIRE"],
  },
  {
    primary_id: "D04ZZD",
    names: ["AVP-6"],
  },
  {
    primary_id: "D04ZZM",
    names: ["Resorcinol Compound 21"],
  },
  {
    primary_id: "D04ZZQ",
    names: ["VAS-2870"],
  },
  {
    primary_id: "D04ZZR",
    names: ["Diazeniumdiolate NO Releasing Compounds"],
  },
  {
    primary_id: "D04ZZT",
    names: ["AGS-004"],
  },
  {
    primary_id: "D04ZZU",
    names: ["3-Chlorophenyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL481529"],
  },
  {
    primary_id: "D04ZZX",
    names: ["Mi-TUMEX"],
  },
  {
    primary_id: "D05AAD",
    names: [
      "Isatoribine",
      "7-Thia-8-Oxoguanosine",
      "ANA245",
      "122970-40-5",
      "UNII-2DNT962H92",
      "ANA-245",
      "2DNT962H92",
      "Immusine",
      "Isatoribine [USAN:INN]",
      "7-TOG",
      "Immunosine",
      "Satoribine",
      "N10146",
      "7-Deaza-7-Thia-8-Oxoguanosine",
      "AC1L2HO7",
      "SCHEMBL127980",
      "AC1Q6N18",
      "SCHEMBL12592999",
      "CHEMBL2105116",
      "ANA 245",
      "TZYVRXZQAWPIAB-FCLHUMLKSA-N",
      "ZINC8214713",
      "5-Amino-3-Ribofuranosylthiazolo(4,5-D)Pyrimidine-2,7(3H,6H)-Dione",
      "DB04860",
      "5-Amino-3-(",
      "A-D-Ribofuranosyl)[1,3]thiazolo[4,5-D]pyrimidine-2,7(3h,4h)-Dione",
      "BRD-K87535339-001-01-6",
    ],
  },
  {
    primary_id: "D05AAM",
    names: [
      "BINOSPIRONE MESYLATE",
      "Binospirone Mesylate < Rec INNM",
      "MDL-73005EF",
      "Rac-N-[2-[(1,4-Benzodioxan-2-Ylmethyl)Amino]ethyl]-1,1-Cyclopentanediazetimide Methanesulfonate",
      "Rac-N-[4-(1,4-Benzodioxan-2-Ylmethylamino)Ethyl]-3,3-Tetramethyleneglutarimide Methanesulfonate",
      "Rac-8-[2-[(2,3-Dihydro-1,4-Benzodioxin-2-Yl)Methylamino]ethyl]-8-Azaspiro[4.5]decane-7,9-Dione Methanesulfonate",
    ],
  },
  {
    primary_id: "D05AAQ",
    names: ["BCP-1081"],
  },
  {
    primary_id: "D05AAW",
    names: ["VR-776"],
  },
  {
    primary_id: "D05ABK",
    names: ["ATF-HI-8"],
  },
  {
    primary_id: "D05ABT",
    names: [
      "3-Formyl-4-Phenyl-1,2,5-Oxadiazole 2-Oxide",
      "CHEMBL567637",
      "135733-34-5",
      "1,2,5-Oxadiazole-3-Carboxaldehyde, 4-Phenyl-, 2-Oxide",
      "3-Formyl-4-Phenyl-1,2,5-Oxadiazole N2-Oxide",
      "ACMC-20mvvi",
      "CTK0F4054",
      "DTXSID00438521",
      "3-Formyl-4-Phenylfurazan 2-Oxide",
      "BDBM50300756",
    ],
  },
  {
    primary_id: "D05ACE",
    names: ["1,5-Bis(4-Chlorophenyl)-1H-1,2,3-Triazole", "CHEMBL503677"],
  },
  {
    primary_id: "D05ACQ",
    names: ["Gallium Maltolate"],
  },
  {
    primary_id: "D05ADD",
    names: ["1-(4-Fluorophenyl)-3-(Phenylsulfonyl)Propan-1-One", "CHEMBL397827", "ZINC28874847", "BDBM50212549"],
  },
  {
    primary_id: "D05ADE",
    names: ["RAD-100"],
  },
  {
    primary_id: "D05ADP",
    names: ["R-Roscovitine"],
  },
  {
    primary_id: "D05AEE",
    names: ["RG7935"],
  },
  {
    primary_id: "D05AEG",
    names: [
      "N-(9H-Beta-Carbolin-3-Yl)-Acetamide",
      "91985-75-0",
      "N-9h-Pyrido[3,4-B]indol-3-Yl-Acetamide",
      "3-Acetamino-Beta-Carboline",
      "CHEMBL167732",
      "SCHEMBL10645219",
      "ZINC25307",
      "JZIFOHJMLBAWQZ-UHFFFAOYSA-N",
      "N-(Beta-Carboline-3-Yl)Acetamide",
      "N-(9H-Pyrido[3,4-B]indol-3-Yl)Acetamide",
    ],
  },
  {
    primary_id: "D05AFC",
    names: [
      "Dalfopristin",
      "5-(2-DIETHYLAMINO-ETHANESULFONYL)-21-HYDROXY-10-ISOPROPYL-11,19-DIMETHYL-9,26-DIOXA-3,15,28-TRIAZA-TRICYCLO[23.2.1.00,255]OCTACOSA-1(27),12,17,19,25(28)-PENTAENE-2,8,14,23-TETRAONE",
    ],
  },
  {
    primary_id: "D05AFI",
    names: ["[3H]hemicholinium-3", "[3H]-HC-3", "[3H]-Hemicholinium-3", "[3H]HC-3"],
  },
  {
    primary_id: "D05AFP",
    names: ["CY-301"],
  },
  {
    primary_id: "D05AFR",
    names: [
      "Rolitetracycline",
      "Bristacin",
      "Kinteto",
      "Pyrrolidinomethyltetracycline",
      "Reverin",
      "Revrin",
      "Rolitetraciclina",
      "Rolitetracyclinum",
      "Solvocillin",
      "Superciclin",
      "Synotodecin",
      "Synterin",
      "Syntetrex",
      "Syntetrin",
      "Tetraverin",
      "Transcycline",
      "Velacicline",
      "Velacycline",
      "AAT 4",
      "SQ 15659",
      "Pirrolidinometil-Tetraciclina",
      "Pirrolidinometil-Tetraciclina [Italian]",
      "Prm-TC",
      "Rolitetraciclina [INN-Spanish]",
      "Rolitetracyclinum [INN-Latin]",
      "SQ 15,659",
      "Synterin (TN)",
      "N-(Pyrrolidinomethyl)Tetracycline",
      "Pyrrolidino-Methyl-Tetracycline",
      "N-Pyrrolidino-Methyl-Tetracycline",
      "Rolitetracycline (JAN/USAN/INN)",
      "Rolitetracycline [USAN:INN:BAN:JAN]",
      "N-(1-Pyrrolidinylmethyl)-Tetracycline",
      "(2Z,4S,4aS,5aS,6S,12aS)-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-2-[hydroxy-(Pyrrolidin-1-Ylmethylamino)Methylidene]-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(2Z,4S,4aS,6S,12aS)-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-2-[hydroxy-(Pyrrolidin-1-Ylmethylamino)Methylidene]-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(2Z,4S,6S,12aS)-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-2-[hydroxy-(Pyrrolidin-1-Ylmethylamino)Methylidene]-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(4S,4aS,6S,12aS)-4-(Dimethylamino)-3,6,10,12,12a-Pentahydroxy-6-Methyl-1,11-Dioxo-N-(Pyrrolidin-1-Ylmethyl)-1,4,4a,5,5a,6,11,12a-Octahydrotetracene-2-Carboxamide",
      "4-(Dimethylamino)-1,4,4a,5,5a,6,11,12a-Octahydro-3,6,10,12,12a-Pentahydroxy-6-Methyl-1,11-Dioxo-N-(1-Pyrrolidinylmethyl)-2-Naphthacenecarboxamide",
    ],
  },
  {
    primary_id: "D05AFU",
    names: ["Macrocyclic Lipopeptidolactone Derivative"],
  },
  {
    primary_id: "D05AFV",
    names: ["UFP-512"],
  },
  {
    primary_id: "D05AFW",
    names: ["S-(4-Ethylbenzyl)Isothiourea Hydrochloride", "CHEMBL1224390", "S-(4-Ethylbenzyl)Isothiourea HCl"],
  },
  {
    primary_id: "D05AFX",
    names: ["Nefopam"],
  },
  {
    primary_id: "D05AGK",
    names: ["L-750034"],
  },
  {
    primary_id: "D05AHC",
    names: ["NOV-205"],
  },
  {
    primary_id: "D05AHE",
    names: [
      "Methylergonovine",
      "Basofortina",
      "Ergotyl",
      "Metenarin",
      "Methergen",
      "Methergin",
      "Methergine",
      "Methylergobasin",
      "Methylergobasine",
      "Methylergobrevin",
      "Methylergometrin",
      "Methylergometrine",
      "Methylergometrinum",
      "Methylergonovin",
      "Metilergometrina",
      "Metilergometrinio",
      "Partergin",
      "Ryegonovin",
      "Lysergic Acid Butanolamide",
      "Methylergometrine Maleate",
      "Metilergometrina [DCIT]",
      "ME 277",
      "Ergotyl (TN)",
      "Methergine (TN)",
      "Methylergometrine (INN)",
      "Methylergometrine [INN:BAN]",
      "Methylergometrinum [INN-Latin]",
      "Metilergometrinio [INN-Spanish]",
      "Spametrin-M",
      "D-Lysergic Acid-Dl-Hydroxybutylamide-2",
      "N-(Alpha-(Hydroxymethyl)Propyl)-D-Lysergamide",
      "D-Lysergic Acid-(+)-Butanolamide-(2)",
      "(8beta)-N-[(1S)-1-(Hydroxymethyl)Propyl]-6-Methyl-9,10-Didehydroergoline-8-Carboxamide",
      "9,10-Didehydro-N-(1-(Hydroxymethyl)Propyl)-6-Methylergoline-8-Carboxamide",
      "9,10-Didehydro-N-(Alpha-(Hydroxymethyl)Propyl)-6-Methyl-Ergoline-8-Beta-Carboxamide",
    ],
  },
  {
    primary_id: "D05AHH",
    names: ["ABT-199/GDC-0199/RG7601"],
  },
  {
    primary_id: "D05AHK",
    names: ["INGN-234"],
  },
  {
    primary_id: "D05AHQ",
    names: ["Epiroprim"],
  },
  {
    primary_id: "D05AHZ",
    names: ["6-Benzenesulfonylhexanoic Acid Hydroxamide", "CHEMBL203207"],
  },
  {
    primary_id: "D05AIB",
    names: ["Ac-I[CVHQDWGHHRC]T-NH2"],
  },
  {
    primary_id: "D05AIM",
    names: ["CSNLSTCVLGKLSQELc[DKLHO]YPRTNTGSGTP-Amide"],
  },
  {
    primary_id: "D05AIN",
    names: ["1-Benzyl-4-(3-Hydroxyphenyl)Piperidine", "CHEMBL592377", "SCHEMBL4826023"],
  },
  {
    primary_id: "D05AIT",
    names: ["3-[6-(5-Chloro-2-Thienyl)Pyridin-2-Yl]phenol", "CHEMBL1096027"],
  },
  {
    primary_id: "D05AJJ",
    names: [
      "VU0240382",
      "CHEMBL1682799",
      "SCHEMBL504253",
      "GTPL6422",
      "1(2H)-Isoquinolinone, 3,4-Dihydro-6-(2-Phenylethynyl)-",
      "BDBM50337503",
      "1092550-36-1",
      "VU0240382-4",
      "6-(Phenylethynyl)-3,4-Dihydroisoquinolin-1(2h)-One",
    ],
  },
  {
    primary_id: "D05AJT",
    names: ["XL019"],
  },
  {
    primary_id: "D05AJX",
    names: ["PMID26004420-Compound-WO2013062892E"],
  },
  {
    primary_id: "D05AJZ",
    names: ["6-Bromo-1-Methyl-3-(Pyridin-4-Yl)-1H-Indole", "CHEMBL383060"],
  },
  {
    primary_id: "D05AKH",
    names: ["OnabotulinumtoxinA"],
  },
  {
    primary_id: "D05ALG",
    names: [
      "RWJ-49815",
      "2-[2-[4-(3,3,3-Triphenylpropoxy)Phenyl]ethyl]guanidine",
      "AC1LAN8D",
      "SCHEMBL13339359",
      "AJKCPFPHDCULCG-UHFFFAOYSA-N",
      "2-(4-(3,3,3-Triphenylpropoxy)Phenyl)Ethylguanidine",
      "1-[2-[4-(3,3,3-Triphenylpropoxy)Phenyl]ethyl]guanidine",
      "RJW-57926",
    ],
  },
  {
    primary_id: "D05ALP",
    names: ["REG1"],
  },
  {
    primary_id: "D05AMQ",
    names: ["Thiadiazolidinone (TDZD) Deriv. 6"],
  },
  {
    primary_id: "D05AMX",
    names: [
      "Cysteinesulfonic Acid",
      "Cysteic Acid",
      "3-Sulfoalanine",
      "2-Amino-3-Sulfopropanoic Acid",
      "DL-CYSTEIC ACID",
      "13100-82-8",
      "Beta-Sulfoalanine",
      "Alanine, 3-Sulfo-",
      "Cysteinic Acid",
      "Cysteric Acid",
      "Cipteic Acid",
      "Cepteic Acid",
      "3024-83-7",
      "CHEBI:21260",
      "C-9550",
      "2-Amino-3-Sulfopropanoate",
      "Cysteinsaure",
      "Cepteate",
      "Cysterate",
      "Cipteate",
      "Cysteinesulfonate",
      "NSC 254030",
      "(2R)-2-Amino-3-Sulfo-Propanoic Acid",
      "L-Cysteic Acid, 8",
      "ACMC-209kii",
      "3-Sulfoalanine, (L)-",
      "2-Amino-3-Sulfopropionate",
      "AC1L19KC",
      "SCHEMBL44030",
      "CHEMBL1171434",
      "2-Amino-3-Sulfopro-Panoic Acid",
      "BDBM85473",
      "CTK8G7889",
    ],
  },
  {
    primary_id: "D05ANK",
    names: ["N-Benzyl-N-(1H-Indol-2-Ylmethyl)-N-Methylamine", "CHEMBL462054"],
  },
  {
    primary_id: "D05ANN",
    names: ["Naltriben"],
  },
  {
    primary_id: "D05ANV",
    names: ["1-(2-P-Tolyl-Ethyl)-Pyrrolidine", "CHEMBL257912", "SCHEMBL13164030"],
  },
  {
    primary_id: "D05AOH",
    names: [
      "1-Iodyl-3-Nitro-Benzene",
      "Benzene, 1-Iodyl-3-Nitro-",
      "CHEMBL118998",
      "16825-78-8",
      "1-Iodoxy-3-Nitrobenzene",
      "SCHEMBL2217800",
      "CTK0E5266",
      "DTXSID30438317",
    ],
  },
  {
    primary_id: "D05AON",
    names: ["LSI-518P"],
  },
  {
    primary_id: "D05APC",
    names: [
      "4-Methyl-5-Propyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL186142",
      "CHEMBL541081",
      "BDBM50066785",
      "AKOS006377665",
      "4-Methyl-5-Propyl-Pyrrolidin-(2E)-Ylideneamine",
    ],
  },
  {
    primary_id: "D05APM",
    names: [
      "ANG-2684",
      "ANG-3038",
      "PARP-1 Inhibitors (Acute Pancreatitis/Stroke)",
      "PARP-1 Inhibitors (Acute Pancreatitis/Stroke), Angion Biomedica",
    ],
  },
  {
    primary_id: "D05APW",
    names: ["ABT-116", "A-1165442"],
  },
  {
    primary_id: "D05APZ",
    names: ["Akt Inhibitor VIII"],
  },
  {
    primary_id: "D05AQB",
    names: ["FAS-89B"],
  },
  {
    primary_id: "D05AQP",
    names: ["ASP-0306"],
  },
  {
    primary_id: "D05AQT",
    names: ["PMID28270021-Compound-WO2014078408Example1"],
  },
  {
    primary_id: "D05ARF",
    names: [
      "N*4*-Benzyl-Pyrido[4,3-D]pyrimidine-4,7-Diamine",
      "CHEMBL300083",
      "4-N-Benzylpyrido[4,3-D]pyrimidine-4,7-Diamine",
      "7-Aminopyrido[4,3-D]pyrimidine 8a",
      "AC1NS3PZ",
      "SCHEMBL6839398",
      "BDBM3331",
      "Pyrido[4,3-D]pyrimidine-4,7-Diamine,n4-(Phenylmethyl)-",
      "ZINC3815364",
      "171178-89-5",
      "PD-69896",
      "KB-302882",
    ],
  },
  {
    primary_id: "D05ARI",
    names: [
      "Propyl-(4,5,6,7-Tetrahydro-2H-Indazol-5-Yl)-Amine",
      "CHEMBL80414",
      "2H-Indazol-5-Amine, 4,5,6,7-Tetrahydro-N-Propyl-",
      "SCHEMBL19048481",
      "BDBM50017539",
    ],
  },
  {
    primary_id: "D05ARP",
    names: ["Ganciclovir"],
  },
  {
    primary_id: "D05ASV",
    names: ["VTX-1463"],
  },
  {
    primary_id: "D05ATD",
    names: ["AVX-502"],
  },
  {
    primary_id: "D05ATI",
    names: [
      "Sodium Lauryl Sulfate",
      "Anticerumen",
      "Dreft",
      "Dupanal",
      "Duponal",
      "Duponol",
      "Gardinol",
      "Irium",
      "NALS",
      "Natriumlaurylsulfat",
      "Neutrazyme",
      "SDS",
      "SLS",
      "Syntapon",
      "WAQE",
      "Akyposal SDS",
      "Aquarex ME",
      "Aquarex Methyl",
      "Carsonol SLS",
      "Carsonol SLS Paste B",
      "Carsonol SLS Special",
      "Conco Sulfate WAG",
      "Conco Sulfate WAN",
      "Conco Sulfate WAS",
      "Conco Sulfate WA",
      "Conco Sulfate WN",
      "DODECYL SULFATE",
      "Dupanol WAQ",
      "Duponal WAQE",
      "Duponol C",
      "Duponol Me",
      "Duponol QC",
      "Duponol WA",
      "Duponol WA Dry",
      "Duponol WAQ",
      "Duponol WAQE",
      "Duponol WAQM",
      "Duponol Methyl",
      "Duponol Qx",
      "Duponol Waqa",
      "EMAL O",
      "Empicol LPZ",
      "Hexamol SLS",
      "Incronol SLS",
      "Laurylsiran Sodny",
      "Lauyl Sodium Sulfate",
      "Maprobix NEU",
      "Maprofix LK",
      "Maprofix NEU",
      "Maprofix WAC",
      "Melanol CL",
      "Montopol La Paste",
      "Nikkol SLS",
      "Orvus WA Paste",
      "Perlandrol L",
      "Perlankrol L",
      "Richonol A",
      "Richonol C",
      "Richonol Af",
      "SDS Running Buffer",
      "Sintapon L",
      "Sipex OP",
      "Sipex SP",
      "Sipex UB",
      "Sipex Sb",
      "Sipex Sd",
      "Sipon LS",
      "Sipon LSB",
      "Sipon PD",
      "Sipon WD",
      "Sodium Laurylsulfate",
      "Solsol Needles",
      "Standapol WAQ",
      "Stepanol ME",
      "Stepanol ME Dry AW",
      "Stepanol WA",
      "Stepanol WA Paste",
      "Stepanol Me Dry",
      "Stepanol Methyl",
      "Stepanol Wac",
      "Stepanol Waq",
      "Sterling Wa Paste",
      "Sulfotex Wa",
      "Texapon DL",
      "Trepenol WA",
      "Avirol 101",
      "Avirol 118",
      "Avirol 118 Conc",
      "Berol 452",
      "CP 75424",
      "Cycloryl 21",
      "Cycloryl 31",
      "Cycloryl 580",
      "Cycloryl 585N",
      "Detergent 66",
      "Emal 10",
      "Emersal 6400",
      "Empicol LS 30",
      "Empicol LX 28",
      "Finasol Osr2",
      "MP SILICA RP 18",
      "Maprofix 563",
      "Melanol CL 30",
      "Monagen Y 100",
      "Monogen Y 100",
      "Odoripon Al 95",
      "Perklankrol ESD 60",
      "Rewopol NLS 30",
      "Sinnopon LS 100",
      "Sinnopon LS 95",
      "Sipon LS 100",
      "Standapol 112",
      "Standapol 112 Conc",
      "Standapol Was 100",
      "Steinapol NLS 90",
      "Stepanol T 28",
      "Sulfetal L 95",
      "Sulfopon Wa 1",
      "Swascol 3L",
      "Tarapon K 12",
      "Tvm 474",
      "Emulsifier No. 104",
      "Finasol Osr(Sub 2)",
      "IPC-SDS;Jordanol SL-300",
      "Lanette Wax-S",
      "Maprofix WAC-LA",
      "Product No. 161",
      "Product No. 75",
      "Quolac EX-UB",
      "S-4600",
      "Standapol Wa-Ac",
      "Stepanol WA-100",
      "Sterling WAQ-CH",
      "Ultra Sulfate Sl-1",
    ],
  },
  {
    primary_id: "D05ATQ",
    names: ["MVA HER-2 AutoVac"],
  },
  {
    primary_id: "D05ATZ",
    names: ["Beta 3-Adrenoceptor"],
  },
  {
    primary_id: "D05AUY",
    names: ["PMID25416646-Compound-Figure5-H"],
  },
  {
    primary_id: "D05AVB",
    names: ["DW-1029M"],
  },
  {
    primary_id: "D05AVL",
    names: ["JNJ-61178104"],
  },
  {
    primary_id: "D05AVX",
    names: [
      "Heptane-2,3-Dione",
      "2,3-HEPTANEDIONE",
      "1996/4/8",
      "Acetyl Valeryl",
      "Valerylacetyl",
      "Acetylvaleryl",
      "Acetyl Pentanoyl",
      "UNII-DK55DDE86P",
      "DK55DDE86P",
      "Benzil-Related Compound, 47",
      "CHEBI:88623",
      "MFCD00036550",
      "FR-2294",
      "Heptanedione",
      "FEMA No. 2543",
      "2,3-Dioxoheptane",
      "EINECS 202-472-5",
      "2,3-Heptane Dione",
      "NSC 31668",
      "2,3 - Heptanedione",
      "AC1L1UAC",
      "SCHEMBL109416",
      "ACMC-20978p",
      "CHEMBL364588",
      "DTXSID4059128",
      "KS-00000YJN",
      "FEMA 2543",
      "CTK0C6666",
      "BDBM22769",
      "MolPort-002-501-994",
      "FJPGAMCQJNLTJC-UHFFFAOYSA-N",
      "NSC31668",
      "ZINC1663926",
      "2,3-Heptanedione, &gt;=97%, FG",
    ],
  },
  {
    primary_id: "D05AWM",
    names: ["Spiroimidazolone Derivative 6"],
  },
  {
    primary_id: "D05AWP",
    names: ["Moxidectin"],
  },
  {
    primary_id: "D05AXE",
    names: ["Aminooxazole Carboxamide Derivative 1"],
  },
  {
    primary_id: "D05AXS",
    names: ["CRx-189"],
  },
  {
    primary_id: "D05AYU",
    names: ["ISIS 113874"],
  },
  {
    primary_id: "D05AZE",
    names: ["Biphenyl Mannoside Derivative 30"],
  },
  {
    primary_id: "D05AZU",
    names: [
      "1-(4-Cyano-2-Hydroxyphenyl)-3-Phenylurea",
      "CHEMBL25573",
      "1-(4-Cyano-2-Hydroxy-Phenyl)-3-Phenyl-Urea",
      "SCHEMBL7489930",
      "ZINC13559607",
    ],
  },
  {
    primary_id: "D05AZW",
    names: ["Alstiphyllanine E", "CHEMBL592477"],
  },
  {
    primary_id: "D05BAJ",
    names: ["PDR001"],
  },
  {
    primary_id: "D05BBB",
    names: ["Saratin"],
  },
  {
    primary_id: "D05BBQ",
    names: ["RP-66364"],
  },
  {
    primary_id: "D05BCA",
    names: [
      "MX-4509",
      "17alpha-Estradiol",
      "57-91-0",
      "Alfatradiol",
      "Epiestradiol",
      "17-Alpha-Estradiol",
      "3,17-Dihydroxyestratriene",
      "17a-Estradiol",
      "Estradiol-17alpha",
      "Epiestrol",
      "17-Epiestradiol",
      "Estra-1,3,5(10)-Triene-3,17alpha-Diol",
      "17alpha Estradiol",
      "NSC 20293",
      "UNII-3VQ38D63M7",
      "Alfatradiol [INN]",
      "Oestradiol-17alpha",
      "17alpha-Oestradiol",
      "Estradiol, 17alpha-",
      "CHEBI:17160",
      "VOXZDWNPVJITMN-SFFUCWETSA-N",
      "3VQ38D63M7",
      "17-Epi-Estradiol",
      "Alfatradiol (INN)",
      "Oestradiol-17-Alpha",
      "MITO-4509",
      "Parkinsons Therapeutics, MitoKor",
      "Drug Screening (Parkinsons), MitoKor",
      "Estrogen Analogs (Parkinsons), Mitokor",
    ],
  },
  {
    primary_id: "D05BCF",
    names: ["Clavulanate+Amoxicillin"],
  },
  {
    primary_id: "D05BCJ",
    names: [
      "1-Benzyl-4-Pyrrol-1-Yl-Piperidine",
      "1-Benzyl-4-(1H-Pyrrol-1-Yl)Piperidine",
      "254115-91-8",
      "CHEMBL326877",
      "1-(Phenylmethyl)-4-Pyrrol-1-Yl-Piperidine",
      "1-Benzyl-4-Pyrrolylpiperidine",
      "AC1MCV5B",
      "AC1Q28X7",
      "CTK5J6306",
      "KS-00003TOT",
      "MolPort-001-764-445",
      "ZINC161500",
      "1-Benzyl-4-Pyrrol-1-Ylpiperidine",
      "BDBM50082169",
      "SBB056067",
      "1-Benzyl-4-(Pyrrol-1-Yl)Piperidine",
      "AKOS008965823",
      "NE54439",
      "MCULE-3402690429",
      "TS-03355",
      "1-Benzyl-4-(1H-Pyrrole-1-Yl)Piperidine",
      "KB-152224",
      "ST50950067",
      "EN300-52430",
      "Z56618096",
    ],
  },
  {
    primary_id: "D05BCU",
    names: ["RQ-00000004"],
  },
  {
    primary_id: "D05BCY",
    names: ["2-EPI-2-THIOSALVINORIN B", "2-Epi-2-Thiosalvinorin B", "CHEMBL231638"],
  },
  {
    primary_id: "D05BDY",
    names: ["PXT-3003"],
  },
  {
    primary_id: "D05BEL",
    names: [
      "N-Methyl-Exo-THPO",
      "CHEMBL149914",
      "SCHEMBL5725228",
      "GTPL5419",
      "BDBM50427396",
      "AKOS023541986",
      "496812-67-0",
      "4-(Methylamino)-4,5,6,7-Tetrahydrobenzo[d]isoxazol-3-Ol",
    ],
  },
  {
    primary_id: "D05BFC",
    names: ["ISOPPADS"],
  },
  {
    primary_id: "D05BFX",
    names: ["ISIS 29177"],
  },
  {
    primary_id: "D05BGM",
    names: ["Eusynstyelamide C"],
  },
  {
    primary_id: "D05BGS",
    names: ["Urea And Carbamate Bioisostere Derivative 7"],
  },
  {
    primary_id: "D05BGY",
    names: ["PM184"],
  },
  {
    primary_id: "D05BHF",
    names: ["Masilukast", "D-3523", "MCC-847", "ZD-3523"],
  },
  {
    primary_id: "D05BHG",
    names: ["Phosphonothreonine"],
  },
  {
    primary_id: "D05BHL",
    names: ["D[Cha4,Lys8]VP", "CHEMBL221485"],
  },
  {
    primary_id: "D05BII",
    names: [
      "Ancrod",
      "Arvin",
      "Arwin",
      "Venacil",
      "Viprinex",
      "Agkistrodon Rhodostoma Venom Proteinase",
      "Agkistrodon Serine Proteinase",
      "Snake Venom, Knoll",
    ],
  },
  {
    primary_id: "D05BIJ",
    names: [
      "RWJ-140998",
      "4-Hydroxy-1-Methyl-7,8,9,10-Tetrahydro-6H-[1,3]thiazocino[2,3-F]purin-2(1H)-One",
      "AC1LIVUI",
      "Oprea1_483450",
      "MolPort-005-971-769",
      "MolPort-002-544-111",
      "ZINC496244",
      "STK643127",
      "AKOS005574570",
      "AKOS030506897",
      "MCULE-9461774198",
      "1-Methyl-7,8,9,10-Tetrahydro-6H-Purino[8,7-B][1,3]thiazocine-2,4-Dione",
      "1-Methyl-7,8,9,10-Tetrahydro-1H-[1,3]thiazocino[2,3-F]purine-2,4(3H,6H)-Dione",
    ],
  },
  {
    primary_id: "D05BIN",
    names: ["F-14512"],
  },
  {
    primary_id: "D05BIT",
    names: ["Maxy-10"],
  },
  {
    primary_id: "D05BJD",
    names: ["Motexafin Gadolinium"],
  },
  {
    primary_id: "D05BJN",
    names: ["Polyclonal Antiboby Stimulator"],
  },
  {
    primary_id: "D05BJY",
    names: ["7alpha-OCOCH2Ph-Ginkgolide B", "7alpha-OCOCH2Ph-GB"],
  },
  {
    primary_id: "D05BKG",
    names: ["AMA"],
  },
  {
    primary_id: "D05BKS",
    names: ["Nimotuzumab"],
  },
  {
    primary_id: "D05BKY",
    names: ["NK-1005"],
  },
  {
    primary_id: "D05BLG",
    names: ["MC-116"],
  },
  {
    primary_id: "D05BLN",
    names: ["MGCD516"],
  },
  {
    primary_id: "D05BLQ",
    names: ["Neuromed 2"],
  },
  {
    primary_id: "D05BMA",
    names: [
      "D-Leucyl-N-(4-Carbamimidoylbenzyl)-L-Prolinamide",
      "CHEMBL1230016",
      "DB06996",
      "N-(4-Amidinobenzyl)-1-[(2R)-2-Amino-4-Methylpentanoyl]-L-Prolinamide",
      "31U",
    ],
  },
  {
    primary_id: "D05BMC",
    names: [
      "GW-42004",
      "CB1/CB2 Receptor Antagonist (Oral/Capsule, Obesity/Type 2 Diabetes/Dyslipidemia), GW Pharmaceuticals",
    ],
  },
  {
    primary_id: "D05BMD",
    names: ["2-(4-Chlorobenzyloxyamino)-N-Hydroxyacetamide", "CHEMBL227566"],
  },
  {
    primary_id: "D05BMG",
    names: [
      "Amphetamine",
      "Amphetamine (Tamper And Abuse-Resistant, Bio-MD/MPAR, ADHD)",
      "Amphetamine (Tamper And Abuse-Resistant, Bio-MD/MPAR, ADHD), PharmacoFore",
      "PF-08 (Bio-MD/MPAR/Prodrug/Oral, ADHD), PharmacoFore",
    ],
  },
  {
    primary_id: "D05BMP",
    names: [
      "5''-Quinolin-4-Yl-[2,4'']bithiazolyl-2''-Ylamine",
      "CHEMBL362710",
      "BDBM50151351",
      "5''-(Quinolin-4-Yl)-2,4''-Bithiazol-2''-Amine",
    ],
  },
  {
    primary_id: "D05BNS",
    names: ["Oral-Vimo-001"],
  },
  {
    primary_id: "D05BOD",
    names: [
      "BCH",
      "20448-79-7",
      "2-Amino-2-Norbornanecarboxylic Acid",
      "2-Aminobicyclo[2.2.1]heptane-2-Carboxylic Acid",
      "2-Aminonorbornane-2-Carboxylic Acid",
      "MPUVBVXDFRDIPT-UHFFFAOYSA-N",
      "Bicyclo[2.2.1]heptane-2-Carboxylicacid, 2-Amino-",
      "B-BCH",
      "2-Amino-2-Carboxy-Bicyclo-2,2,1-Heptane",
      "AC1L3GVN",
      "AC1Q5TB7",
      "2-Aminobicyclo(2,2,1)Heptane-2-Carboxylic Acid",
      "SCHEMBL202772",
      "GTPL4700",
      "CHEMBL2074957",
      "CTK1A1593",
      "MolPort-003-928-121",
      "2-Amino-2-Norbornane Carboxylic Acid",
      "AKOS016044739",
      "AKOS002666381",
      "VZ23542",
      "MCULE-9216762478",
      "Norbornane-2-Carboxylic Acid, 2-Amino",
    ],
  },
  {
    primary_id: "D05BPC",
    names: [
      "Tiprelestat",
      "Elafin",
      "Recombinant Leukocyte Elastase/Proteinase-3 Inhibitor (Iv, Inflammation), Proteo Biotech",
    ],
  },
  {
    primary_id: "D05BPD",
    names: ["Emepepimut-S"],
  },
  {
    primary_id: "D05BPM",
    names: ["3-Cyano-5-Fluoro-N-M-Tolylbenzamide", "CHEMBL1209203"],
  },
  {
    primary_id: "D05BPO",
    names: ["PF-06801591"],
  },
  {
    primary_id: "D05BPX",
    names: ["3-(4-Morpholinothieno[3,2-D]pyrimidin-2-Yl)Phenol", "CHEMBL541643"],
  },
  {
    primary_id: "D05BQE",
    names: ["P(1)-(6-Hydroxymethylpterin)-P(4)-(5'-Adenosyl)Tetraphosphate"],
  },
  {
    primary_id: "D05BQK",
    names: [
      "Butalbital",
      "Alisobumal",
      "Alisobumalum",
      "Allylbarbital",
      "Allylbarbitone",
      "Allylisobutylbarbital",
      "Allylisobutylbarbiturate",
      "Butalbarbital;Butalbitale",
      "Butalbitalum",
      "Itobarbital",
      "Profundal",
      "Sandoptal",
      "Tetrallobarbital",
      "Butalbitale [DCIT]",
      "Axocet (TN)",
      "Axotal (TN)",
      "Bucet (TN)",
      "Bupap (TN)",
      "Butalbital [USAN:INN]",
      "Butalbitalum [INN-Latin]",
      "Cephadyn (TN)",
      "Dolgic (TN)",
      "Esgic (TN)",
      "Fioricet (TN)",
      "Fiorinal (TN)",
      "Fiormor (TN)",
      "Fiortal (TN)",
      "Fortabs (TN)",
      "IBS-L0126512",
      "Iso-Butylallylbarbituric Acid",
      "Laniroif (TN)",
      "Phrenilin (TN)",
      "Phrenilin Forte (TN)",
      "Sandoptal (TN)",
      "Sedapap (TN)",
      "Butalbital (USP/INN)",
      "Esgic-Plus (TN)",
      "5-(2-Methylpropyl)-5-(Prop-2-En-1-Yl)Pyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-(2-Methylpropyl)-5-Prop-2-En-1-Ylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-(2-Methylpropyl)-5-Prop-2-Enyl-1,3-Diazinane-2,4,6-Trione",
      "5-Allyl-5-(2'-Methyl-N-Propyl) Barbituric Acid",
      "5-Allyl-5-(2-Methylpropyl)Barbituric Acid",
      "5-Allyl-5-Isobutyl-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Allyl-5-Isobutyl-Pyrimidine-2,4,6-Trione",
      "5-Allyl-5-Isobutylbarbituric Acid",
    ],
  },
  {
    primary_id: "D05BRZ",
    names: ["PG-140", "CD86 Antagonist (Transplant Rejection), PanGenetics"],
  },
  {
    primary_id: "D05BSG",
    names: ["Enkastim-Ev"],
  },
  {
    primary_id: "D05BTF",
    names: ["Tert-Butyl 5-(2-Oxohexadecanamido)Pentanoate", "CHEMBL529887"],
  },
  {
    primary_id: "D05BTG",
    names: ["3,6-Diphenyl-1-Oxa-7-Aza-Spiro[4.5]decane"],
  },
  {
    primary_id: "D05BTL",
    names: ["PMID26651364-Compound-126c"],
  },
  {
    primary_id: "D05BTM",
    names: ["Maxacalcitol"],
  },
  {
    primary_id: "D05BTR",
    names: ["Tallimustine"],
  },
  {
    primary_id: "D05BUD",
    names: ["Mannoside Derivative 10"],
  },
  {
    primary_id: "D05BUX",
    names: ["Cyclopentane Amide Derivatives 1"],
  },
  {
    primary_id: "D05BVP",
    names: ["PMID28766366-Compound-Scheme8NCGC-00010037"],
  },
  {
    primary_id: "D05BWH",
    names: ["PRX-3140"],
  },
  {
    primary_id: "D05BWX",
    names: ["CB-2431"],
  },
  {
    primary_id: "D05BWY",
    names: ["CLR-300", "CLR-302", "CLR-316", "CLR-300 Series, Clera"],
  },
  {
    primary_id: "D05BXO",
    names: ["Brasofensine", "Brasofensine [INN]"],
  },
  {
    primary_id: "D05BXY",
    names: ["Talimogene Laherparepvec"],
  },
  {
    primary_id: "D05BYA",
    names: ["NVP-AUY922"],
  },
  {
    primary_id: "D05BYD",
    names: ["A-304121", "A304121", "A 304121"],
  },
  {
    primary_id: "D05BYE",
    names: ["Hydroxy Amidine Derivative 2"],
  },
  {
    primary_id: "D05BYJ",
    names: ["PF-06413367"],
  },
  {
    primary_id: "D05BYO",
    names: ["ISIS-SOD1"],
  },
  {
    primary_id: "D05BYP",
    names: ["Lupron 3-Month Depot"],
  },
  {
    primary_id: "D05BZQ",
    names: [
      "DEOXY SALVINORIN A",
      "Deoxy Salvinorin A",
      "CHEMBL378635",
      "SCHEMBL13044189",
      "BDBM50189165",
      "(2S,4aR,6aR,7R,9S,10aS,10bR)-Methyl 9-Ethoxy-2-(Furan-3-Yl)-6a,10b-Dimethyl-4,10-Dioxo-Dodecahydro-1H-Benzo[f]isochromene-7-Carboxylate",
    ],
  },
  {
    primary_id: "D05CBG",
    names: [
      "4-(3'-Furyl)-Aniline",
      "Benzenamine, 4-(3-Furanyl)-",
      "4-(Furan-3-Yl)Aniline",
      "4-Furan-3-Yl-Phenylamine",
      "CHEMBL374967",
      "SCHEMBL1016530",
      "NSKKXDBWURWAOW-UHFFFAOYSA-N",
      "AKOS013265082",
    ],
  },
  {
    primary_id: "D05CBJ",
    names: ["PA-799"],
  },
  {
    primary_id: "D05CBP",
    names: ["PMID26651364-Compound-9a"],
  },
  {
    primary_id: "D05CBS",
    names: ["PMID25435285-Compound-37"],
  },
  {
    primary_id: "D05CCF",
    names: [
      "1-(5-Chloro-2-Hydroxy-4-Nitrophenyl)-3-Phenylurea",
      "CHEMBL430376",
      "117745-34-3",
      "Urea, N-(5-Chloro-2-Hydroxy-4-Nitrophenyl)-N'-Phenyl-",
      "ACMC-20mnej",
      "AC1MXJKZ",
      "SCHEMBL4241814",
      "CTK0G0114",
      "DTXSID10396461",
      "MolPort-001-764-839",
      "ZX-AT026392",
      "ZINC3917497",
      "BDBM50203018",
      "AKOS024384929",
      "MCULE-2402836101",
      "ST51035461",
      "3-(5-Chloro-2-Hydroxy-4-Nitrophenyl)-1-Phenylurea",
    ],
  },
  {
    primary_id: "D05CCT",
    names: ["3,7-BIS(DIMETHYLAMINO)PHENOTHIAZIN-5-IUM"],
  },
  {
    primary_id: "D05CCZ",
    names: ["ISIS 134524"],
  },
  {
    primary_id: "D05CDC",
    names: ["Correolide", "C13849", "GTPL2555"],
  },
  {
    primary_id: "D05CDG",
    names: ["R-1164"],
  },
  {
    primary_id: "D05CEJ",
    names: [
      "N-(4,6-Diphenylpyrimidin-2-Yl)-4-Chlorobenzamide",
      "CHEMBL376034",
      "820961-59-9",
      "CTK3E2649",
      "DTXSID20458923",
      "BDBM50157674",
      "AKOS030577991",
      "Benzamide, 4-Chloro-N-(4,6-Diphenyl-2-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D05CEK",
    names: [
      "CV 6209",
      "CHEMBL274420",
      "CV6209",
      "GTPL1852",
      "AC1L32P4",
      "SCHEMBL8538219",
      "CHEMBL1180101",
      "PDSP1_000728",
      "PDSP1_000727",
      "PDSP2_000718",
      "PDSP2_000717",
      "BDBM50005232",
      "2-(2-Acetyl-6-Methoxy-3,9-Dioxo-4,8-Dioxa-2,10-Diazaoctacosan-1-Yl)-1-Ethylpyridinium",
      "2-{[Acetyl-(2-Methoxy-3-Octadecylcarbamoyloxy-Propoxycarbonyl)-Amino]-Methyl}-1-Ethyl-Pyridinium",
      "[2-Methoxy-3-(Octadecylcarbamoyloxy)Propyl] N-Acetyl-N-[(1-Ethylpyridin-1-Ium-2-Yl)Methyl]carbamate",
    ],
  },
  {
    primary_id: "D05CEL",
    names: ["2-Cycloalkyl Resorcinol Cannabinoid Ligand Derivative 1"],
  },
  {
    primary_id: "D05CEM",
    names: ["NNC269100"],
  },
  {
    primary_id: "D05CEO",
    names: ["Brodalumab", "AMG 827"],
  },
  {
    primary_id: "D05CEU",
    names: ["Flunarizine"],
  },
  {
    primary_id: "D05CFG",
    names: [
      "PR104",
      "851627-62-8",
      "PR-104",
      "UNII-V16D2ZT7DT",
      "V16D2ZT7DT",
      "2-((2-Bromoethyl)(2,4-Dinitro-6-((2-(Phosphonooxy)Ethyl)Carbamoyl)Phenyl)Amino)Ethyl Methanesulfonate",
      "2-[(2-Bromoethyl)(2,4-Dinitro-6-{[2-(Phosphonooxy)Ethyl]carbamoyl}phenyl)Amino]ethyl Methanesulfonate",
      "PR 104",
      "SCHEMBL367963",
      "ZINC43131754",
      "AKOS022174966",
      "RL05275",
      "KB-80083",
      "4CA-1041",
      "AX8282408",
      "J-507569",
      "Benzamide, 2-((2-Bromoethyl)(2-((Methylsulfonyl)Oxy)Ethyl)Amino)-3,5-     Dinitro-N-(2-(Phosphonooxy)Ethyl)-",
    ],
  },
  {
    primary_id: "D05CFH",
    names: ["AGN-199981"],
  },
  {
    primary_id: "D05CFQ",
    names: ["AMG-191"],
  },
  {
    primary_id: "D05CFV",
    names: ["Dapirolizumab Pegol"],
  },
  {
    primary_id: "D05CGH",
    names: ["Evatak", "Echovirus Type 1 Cancer Therapy, Psiron", "Echovirus Type 1 Cancer Therapy, Viralytics"],
  },
  {
    primary_id: "D05CGI",
    names: ["2-(4-Benzyl-Piperidin-1-Ylmethyl)-Chroman-4-One", "CHEMBL179397"],
  },
  {
    primary_id: "D05CGO",
    names: ["XP-28"],
  },
  {
    primary_id: "D05CGR",
    names: ["PMID25553724-Compound-WO2013082253 31"],
  },
  {
    primary_id: "D05CGX",
    names: ["SPK-FIX"],
  },
  {
    primary_id: "D05CHI",
    names: [
      "Rifabutin",
      "Alfacid",
      "Ansamycin",
      "Ansatipin",
      "Ansatipine",
      "Assatipin",
      "Mycobutin",
      "RBT",
      "Rifabutina",
      "Rifabutine",
      "Rifabutinum",
      "Grunenthal Brand Of Rifabutin",
      "Kenfarma Brand Of Rifabutin",
      "Pfizer Brand Of Rifabutin",
      "Rifabutin Pfizer Brand",
      "Rifabutina [Spanish]",
      "Rifabutine [French]",
      "Rifabutinum [Latin]",
      "Antibiotic LM 427",
      "LM 427",
      "LM427",
      "DRG-0085",
      "LM-427",
      "Mycobutin (TN)",
      "Rifabutin [USAN:BAN:INN]",
      "Rifabutin (JAN/USP/INN)",
      "Mycobutin, Ansamycin, LM 427, Ansatipine, Rifabutin",
      "(9S,12E,14S,15R,16S,17R,18R,19R,20S,21S,22E,24Z)-6,18,20-Trihydroxy-1'-Isobutyl-14-Methoxy-7,9,15,17,19,21,25-Heptamethyl-5,10,26-Trioxo-3,5,9,10-Tetrahydrospiro[9,4-(Epoxypentadeca[1,11,13]trienoimino)Furo[2',3':7,8]naphtho[1,2-D]imidazole-2,4'-Piperidin",
      "Spiro[9,4-(Epoxypentadeca[1,11,13]trienimino)-2H-Furo[2',3':7,8]naphth[1,2-D]imidazole-2,4'-Piperidine]-5,10,26(3H,9H)-Trione, 16-(Acetyloxy)-6,18,20-Trihydroxy-14-Methoxy-7,9,15,17,19,21,25-Heptameth",
      "1,4-Dihydro-1-Deoxy-1',4-Didehydro-5'-(2-Methylpropyl)-1-Oxorifamycin XIV",
      "4-Deoxo-3,4-(2-Spiro(N-Isobutyl-4-Piperidyl)-2,5-Dihydro-1H-Imidazo)-Rifamycin S",
      "4-N-Isobutylspiropiperidylrifamycin S",
    ],
  },
  {
    primary_id: "D05CHN",
    names: ["Irofulven/Taxotere"],
  },
  {
    primary_id: "D05CHR",
    names: ["LBY-135"],
  },
  {
    primary_id: "D05CJJ",
    names: ["Non-Nucleoside Reverse Transcriptase Inhibitors"],
  },
  {
    primary_id: "D05CJN",
    names: ["HG-1336"],
  },
  {
    primary_id: "D05CJU",
    names: [
      "DLX-105",
      "DLX-106",
      "ESBA-105 (Intra-Articular Formulation), ESBATech",
      "Anti-TNF Alpha Antibody Fragment (Intra-Articular, Osteoarthritis), Delenex",
      "Anti-TNF Alpha Antibody Fragment (Intra-Articular, Osteoarthritis), ESBATech",
    ],
  },
  {
    primary_id: "D05CKF",
    names: ["AJD-101"],
  },
  {
    primary_id: "D05CKR",
    names: ["Nabumetone"],
  },
  {
    primary_id: "D05CLL",
    names: ["PMID28766366-Compound-Scheme7WO2012/098070upper"],
  },
  {
    primary_id: "D05CLM",
    names: ["GSK-9578"],
  },
  {
    primary_id: "D05CMT",
    names: ["COMBO Stent"],
  },
  {
    primary_id: "D05CMZ",
    names: ["ONO-2952"],
  },
  {
    primary_id: "D05CNB",
    names: ["Amythiamicin"],
  },
  {
    primary_id: "D05CND",
    names: [
      "6-(3-Nitro-Phenyl)-3H-Benzothiazol-2-One",
      "CHEMBL315445",
      "SCHEMBL6541670",
      "NGWRFPUBBONZGG-UHFFFAOYSA-N",
      "BDBM50105566",
    ],
  },
  {
    primary_id: "D05CNJ",
    names: ["Five-Membered Heteroaromatic Compound 1"],
  },
  {
    primary_id: "D05CNT",
    names: ["BAYu9773", "BAY U9773"],
  },
  {
    primary_id: "D05COE",
    names: ["PMID30107136-Compound-Example52"],
  },
  {
    primary_id: "D05COG",
    names: [
      "DU-1777",
      "DU 1777",
      "AC1MIWBL",
      "Du1777",
      "1-(N2-Nicotinoyl-L-Lysyl-Gamma-D-Glutamyl)Octahydro-1H-Indole-2-Carboxylic Acid",
      "5-(2-Carboxyoctahydro-1H-Indol-1-Yl)-5-Oxo-N-(N2-(3-Pyridinylcarbonyl)-L-Lysyl)-D-Norvaline (2S-(2alpha,3abeta,7abeta))-",
      "(2S,3aS,7aS)-1-[(2R)-2-[[(2S)-6-Amino-2-(Pyridine-3-Carbonylamino)Hexanoyl]amino]-5-Hydroxy-5-Oxopentanoyl]-2,3,3a,4,5,6,7,7a-Octahydroindole-2-Carboxylic Acid",
      "D-Norvaline, 5-(2-Carboxyoctahydro-1H-Indol-1-Yl)-5-Oxo-N-(N2-(3-Pyridinylcarbonyl)-L-Lysyl)-, (2S-(2alpha,3abeta,7abeta))-",
    ],
  },
  {
    primary_id: "D05COL",
    names: ["SALVINORIN B"],
  },
  {
    primary_id: "D05COQ",
    names: [
      "H-DPhe-C[Cys-Phe-DTrp-Lys-Thr-Cys]-Thr-NH2",
      "CHEMBL219375",
      "BDBM50191271",
      "H-DPhe2-C[Cys3-Phe7-DTrp8-Lys9-Thr10-Cys14]-Thr15-NH2",
    ],
  },
  {
    primary_id: "D05CPD",
    names: ["BIOO-2"],
  },
  {
    primary_id: "D05CPS",
    names: [
      "Dendritic Cell Vaccine",
      "Dendritic Cell Vaccine (Injectable, Head And Neck Cancer)",
      "Dendritic Cell Vaccine (Injectable, Head And Neck Cancer), National Cancer Institute",
      "Mutant P53 Peptide Pulsed Dendritic Cell Vaccine (Injectable, Head And Neck Cancer), National Cancer Institute",
    ],
  },
  {
    primary_id: "D05CPV",
    names: ["Dronedarone"],
  },
  {
    primary_id: "D05CQR",
    names: ["N-(4-Bromo-3-Methanesulfonyl-Benzoyl)-Guanidine", "CHEMBL62468"],
  },
  {
    primary_id: "D05CQS",
    names: ["NB1011"],
  },
  {
    primary_id: "D05CRC",
    names: ["SL-3111"],
  },
  {
    primary_id: "D05CRG",
    names: ["L-054852"],
  },
  {
    primary_id: "D05CRM",
    names: [
      "AMG-9090",
      "118215-82-0",
      "CHEMBL1086371",
      "N-{2,2,2-Trichloro-1-[(4-Chlorophenyl)Sulfanyl]ethyl}benzamide",
      "N-(2,2,2-Trichloro-1-((4-Chlorophenyl)Sulfanyl)Ethyl)Benzamide",
      "AC1MLXZ1",
      "SCHEMBL15159501",
      "MolPort-002-804-203",
      "BDBM50318455",
      "AKOS001602233",
      "MCULE-4762847116",
      "NCGC00322013-01",
      "BC600809",
      "A-300",
      "ST50981355",
      "AB01317363-02",
      "AF-962/31929042",
      "N-(2,2,2-Trichloro-1-(4-Chlorophenylthio)Ethyl)Benzamide",
      "N-[2,2,2-Trichloro-1-(4-Chlorophenyl)Sulfanylethyl]benzamide",
      "N-{2,2,2-Trichloro-1-[(4-Chlorophenyl)Thio]ethyl}benzamide",
    ],
  },
  {
    primary_id: "D05CRY",
    names: ["ADCT-502"],
  },
  {
    primary_id: "D05CSS",
    names: ["CAR-T Cells Targeting MucI"],
  },
  {
    primary_id: "D05CTQ",
    names: ["CD138 CAR T Cells"],
  },
  {
    primary_id: "D05CTS",
    names: ["C-202", "C-301"],
  },
  {
    primary_id: "D05CTY",
    names: ["NV.TLM.10"],
  },
  {
    primary_id: "D05CVC",
    names: ["90Y-CG250"],
  },
  {
    primary_id: "D05CVH",
    names: ["Hu Neutral Insulin"],
  },
  {
    primary_id: "D05CWG",
    names: ["TEI-8362"],
  },
  {
    primary_id: "D05CWM",
    names: ["PMID25726713-Compound-12"],
  },
  {
    primary_id: "D05CXZ",
    names: ["PMID30273516-Compound-37"],
  },
  {
    primary_id: "D05CYD",
    names: [
      "C3",
      "4-[2-[2-[3-Acetamido-2-[[[5-(2,4-Dioxopyrimidin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl]oxy-Hydroxyphosphoryl]oxy-5-Hydroxy-6-(Hydroxymethyl)Oxan-4-Yl]oxypropanoylamino]propanoylamino]-5-[[6-Amino-1-[[1-[(1-Hydroxy-1-Oxopropan-2-Yl)Amino]-1-Oxopropan-2-Yl]amino]-1-Oxohexan-2-Yl]amino]-5-Oxopentanoic Acid",
      "AC1L18UY",
    ],
  },
  {
    primary_id: "D05CYJ",
    names: ["N,N-Dimethyl-2-(2-Phenyl-1H-Indol-3-Yl)-Acetamide", "CHEMBL320626", "BDBM50045869"],
  },
  {
    primary_id: "D05CYX",
    names: ["DAV-121"],
  },
  {
    primary_id: "D05DAE",
    names: [
      "2-Phenyl-N-(1,3,4-Thiadiazol-2-Yl)Acetamide",
      "AC1LGHTG",
      "CHEMBL225956",
      "MolPort-002-561-993",
      "MolPort-000-823-043",
      "ZINC17043968",
      "STK536010",
      "AKOS002254375",
      "MCULE-9751182652",
      "84954-79-0",
      "ST50752086",
      "Benzeneacetamide, N-1,3,4-Thiadiazol-2-Yl-",
    ],
  },
  {
    primary_id: "D05DAF",
    names: ["N-(3-Aminophenyl)Benzenesulfonamide"],
  },
  {
    primary_id: "D05DAG",
    names: [
      "2-(5-Fluoro-1H-Indol-3-Yl)Ethanamine",
      "5-Fluorotryptamine",
      "576-16-9",
      "3-(2-Aminoethyl)-5-Fluoro-1H-Indole",
      "1H-Indole-3-Ethanamine, 5-Fluoro-",
      "2-(5-FLUORO-1H-INDOL-3-YL)ETHAN-1-AMINE",
      "2-(5-Fluoro-1H-Indol-3-Yl)Ethylamine",
      "CHEMBL275628",
      "2-(5-Fluoro-1H-Indol-3-Yl)-Ethylamine",
      "2-(5-Fluoroindol-3-Yl)Ethylamine",
      "PubChem1926",
      "PubChem13384",
      "ChemDiv2_003993",
      "AC1L4V8Y",
      "AC1Q4NE7",
      "Oprea1_696214",
      "GTPL146",
      "SCHEMBL387663",
      "5-Fluorotryptamine, AldrichCPR",
      "Cid_197774",
      "CTK5A7173",
      "ZINC77278",
      "BDBM30853",
      "DTXSID20206229",
      "ZKIORVIXEWIOGB-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D05DAN",
    names: ["CP-470,711", "CP-470711", "CHEMBL36514", "SCHEMBL6294633", "BDBM50113494", "LS-135453"],
  },
  {
    primary_id: "D05DAX",
    names: ["TT-120", "Anticoagulant (Peptide), Thrombotargets", "CO2/TT-120"],
  },
  {
    primary_id: "D05DBG",
    names: ["Carbamate Derivative 16"],
  },
  {
    primary_id: "D05DBJ",
    names: ["2-(4-Methoxybenzylthio)-6-Methylpyrimidin-4-Ol"],
  },
  {
    primary_id: "D05DBT",
    names: ["EG-HPV"],
  },
  {
    primary_id: "D05DBY",
    names: ["Imagabalin"],
  },
  {
    primary_id: "D05DBZ",
    names: ["AGS-8M4", "AGS-8 MAb, Agensys, AGS8M4, ASP-6183"],
  },
  {
    primary_id: "D05DCL",
    names: ["ZYT-1"],
  },
  {
    primary_id: "D05DCP",
    names: ["Small Molecule 20a"],
  },
  {
    primary_id: "D05DCQ",
    names: [
      "3-Anilino-5-Benzylthio-1,2,4-Triazole",
      "CHEMBL243303",
      "SCHEMBL5376882",
      "1,2,4-Triazole Compound, 6",
      "BDBM17352",
      "QBUZLNWDECJZMT-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D05DCW",
    names: ["PMID20346665C24"],
  },
  {
    primary_id: "D05DCX",
    names: ["Genetically Modified Allogeneic Sertoli Cells"],
  },
  {
    primary_id: "D05DDN",
    names: ["HCV-796"],
  },
  {
    primary_id: "D05DDS",
    names: ["Isopentyl Pyrophosphate"],
  },
  {
    primary_id: "D05DDU",
    names: ["3-(2-Methylquinolin-7-Yl)Phenol", "CHEMBL231988"],
  },
  {
    primary_id: "D05DDZ",
    names: ["MV-6401"],
  },
  {
    primary_id: "D05DFJ",
    names: [
      "2,6-Difluorobenzenesulfonamide",
      "2,6-DIFLUOROBENZENESULFONAMIDE",
      "60230-37-7",
      "2,6-Difluorobenzenesulphonamide",
      "2,6-Difluorobenzene-1-Sulfonamide",
      "Benzenesulfonamide, 2,6-Difluoro-",
      "FBT",
      "2,6-Difluorophenylsulphonylamide",
      "PubChem11779",
      "ACMC-209vki",
      "AC1L9JF9",
      "KSC495S1R",
      "SCHEMBL677588",
      "2,6-Difluorobenzenesulfon-Amide",
      "2,6-Difluoro-Benzenesulfonamide",
      "CTK3J5918",
      "CHEBI:42429",
      "DTXSID60332218",
      "MolPort-000-154-262",
      "RVVVGGCOFWWDEL-UHFFFAOYSA-N",
      "ZINC404039",
      "KS-000010UU",
      "WT2099",
      "STL554304",
      "SBB091559",
      "WT1897",
      "BBL100510",
      "ANW-45184",
      "2,6-Difluorobenzenesulfonamide, 97%",
    ],
  },
  {
    primary_id: "D05DFO",
    names: ["N-(4-Methoxyphenyl)Oleamide"],
  },
  {
    primary_id: "D05DFV",
    names: ["PRO-044"],
  },
  {
    primary_id: "D05DGE",
    names: ["Fluoro-Phosphite Ion"],
  },
  {
    primary_id: "D05DHX",
    names: ["HELENALINMETHACRYLATE"],
  },
  {
    primary_id: "D05DIH",
    names: ["Methyl 4-(4-Tert-Butylbenzylideneamino)Benzoate", "CHEMBL572526"],
  },
  {
    primary_id: "D05DIN",
    names: ["2-Methoxyphenyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL481525"],
  },
  {
    primary_id: "D05DKT",
    names: ["CART19 Cell"],
  },
  {
    primary_id: "D05DLI",
    names: ["STYLISSADINE A"],
  },
  {
    primary_id: "D05DMH",
    names: [
      "(5BETA)-PREGNANE-3,20-DIONE",
      "5beta-Pregnane-3,20-Dione",
      "3,20-Pregnanedione",
      "5beta-Pregnan-3,20-Dione",
      "128-23-4",
      "5beta-Dihydroprogesterone",
      "NSC 82868",
      "UNII-105J2Q45A0",
      "5beta-Dihydro Progesterone",
      "5.beta.-Dihydroprogesterone",
      "5.beta.-Pregnan-3,20-Dione",
      "CHEBI:30154",
      "Pregnane-3,20-Dione, (5b)-",
      "XMRPGKVKISIQBV-XWOJZHJZSA-N",
      "105J2Q45A0",
      "5b-Pregnane-3,20-Dione",
      "CI2",
      "(5R,8R,9S,10S,13S,14S,17S)-17-Acetyl-10,13-Dimethyl-1,2,4,5,6,7,8,9,11,12,14,15,16,17-Tetradecahydrocyclopenta[a]phenanthren-3-One",
      "5.beta.-Pregnane-3,20-Dione",
    ],
  },
  {
    primary_id: "D05DNA",
    names: ["FR-183998"],
  },
  {
    primary_id: "D05DNH",
    names: ["A-79296"],
  },
  {
    primary_id: "D05DNU",
    names: ["KNI-1293"],
  },
  {
    primary_id: "D05DNW",
    names: ["B-623", "149732-37-6"],
  },
  {
    primary_id: "D05DOM",
    names: [
      "2-Bromoacetyl Group",
      "BROMOACETIC ACID",
      "2-Bromoacetic Acid",
      "1979/8/3",
      "Monobromoacetic Acid",
      "Bromoethanoic Acid",
      "Acetic Acid, Bromo-",
      "To NTU",
      "Bromoacetate Ion",
      "Acide Bromacetique",
      "2-Bromoethanoic Acid",
      "Monobromessigsaeure",
      "Acetic Acid, 2-Bromo-",
      "Bromo-Acetic Acid",
      "Kyselina Bromoctova",
      "Alpha-Bromoacetic Acid",
      "Caswell No. 112A",
      "NSC 141",
      ".alpha.-Bromoacetic Acid",
      "Acide Bromacetique [French]",
      "Kyselina Bromoctova [Czech]",
      "Monobromessigsaeure [German]",
      "CCRIS 7886",
      "UNII-2B3HS32431",
      "Acetic Acid, Bromo-, (Solution)",
      "Alpha-Bromoethanoic Acid",
    ],
  },
  {
    primary_id: "D05DON",
    names: [
      "MILACAINIDE TARTRATE",
      "(R)-RO-22-9194.tartrate",
      "2(R)-Amino-N-(2,6-Dimethylphenyl)-N-[3-(3-Pyridyl)Propyl]propionamide D-Tartrate",
    ],
  },
  {
    primary_id: "D05DOY",
    names: ["ING-1"],
  },
  {
    primary_id: "D05DPE",
    names: ["BR-05001"],
  },
  {
    primary_id: "D05DPU",
    names: [
      "SP-102",
      "3-Methoxy-2-(2-Morpholin-4-Ylethoxy)Benzaldehyde",
      "883546-13-2",
      "3-Methoxy-2-(2-Morpholin-4-Yl-Ethoxy)-Benzaldehyde",
      "3-METHOXY-2-[2-(4-MORPHOLINYL)ETHOXY]BENZALDEHYDE",
      "3-Methoxy-2-[2-(Morpholin-4-Yl)Ethoxy]benzaldehyde",
      "ASN 16463226",
      "AC1O6PGE",
      "CTK5F9691",
      "DTXSID50424712",
      "MolPort-000-137-397",
      "SP102",
      "ZX-CM011707",
      "ZINC19201168",
      "1719AF",
      "AKOS000104022",
      "IMED425516173",
      "RTR-045466",
      "MCULE-6451491280",
      "AJ-72537",
      "TR-045466",
      "DB-027972",
      "FT-0713025",
      "ST50401327",
      "Y-3737",
    ],
  },
  {
    primary_id: "D05DQF",
    names: [
      "Indole N -Acetamide",
      "1H-Indole-1-Acetamide",
      "2-(1H-Indol-1-Yl)Acetamide",
      "39597-63-2",
      "2-Indol-1-Ylacetamide",
      "2(N-Indolyl) Acetamide",
      "2-(N-Indolyl) Acetamide",
      "AC1N9C0U",
      "SCHEMBL1519806",
      "CHEMBL3252106",
      "MolPort-003-001-159",
      "IUUZMSMGSOUFTO-UHFFFAOYSA-N",
      "ZINC6393531",
      "STK319340",
      "AKOS003406817",
      "MCULE-8299376429",
      "VU0526183-1",
    ],
  },
  {
    primary_id: "D05DQR",
    names: ["HDV-Interferon"],
  },
  {
    primary_id: "D05DRG",
    names: ["PDC-31"],
  },
  {
    primary_id: "D05DRQ",
    names: ["N-(4-(Diethylamino)Phenyl)-2-Nitrobenzamide"],
  },
  {
    primary_id: "D05DSK",
    names: ["E2101"],
  },
  {
    primary_id: "D05DSL",
    names: ["PMID27376512-Compound-Table1Example16"],
  },
  {
    primary_id: "D05DSS",
    names: ["GI-13020"],
  },
  {
    primary_id: "D05DTJ",
    names: ["3-Phenyl-1-Propyl-Piperidine Hydrochloride"],
  },
  {
    primary_id: "D05DTN",
    names: ["4-Oxo-Nonenal", "4-Oxo-2E-Nonenal"],
  },
  {
    primary_id: "D05DUX",
    names: ["Petrosaspongiolide M"],
  },
  {
    primary_id: "D05DVP",
    names: [
      "Zafirlukast",
      "Accolate",
      "Accoleit",
      "Aeronix",
      "Olmoran",
      "Respix",
      "Zafirst",
      "AstraZeneca Brand Of Zafirlukast",
      "Menarini Brand Of Zafirlukast",
      "Novartis Brand Of Zafirlukast",
      "Zeneca Brand Of Zafirkulast",
      "Accolate (TN)",
      "Accoleit (TN)",
      "Vanticon (TN)",
      "Zafirlukast [USAN:BAN:INN]",
      "Accolate, Accoleit, Vanticon, Zafirlukast",
      "Zafirlukast (JAN/USAN/INN)",
      "Cyclopentyl N-[3-[[2-Methoxy-4-[(2-Methylphenyl)Sulfonylcarbamoyl]phenyl]methyl]-1-Methylindol-5-Yl]carbamate",
      "Cyclopentyl 3-(2-Methoxy-4-((O-Tolylsulfonyl)Carbamoyl)Benzyl)-1-Methylindole-5-Carbamate",
      "Cyclopentyl 3-[2-Methoxy-4-(2-Methylphenylsulfonylcarbamoyl)Benzyl]-1-Methyl-1H-Indol-5-Ylcarbamate",
      "Cyclopentyl [3-(2-Methoxy-4-{[(2-Methylphenyl)Sulfonyl]carbamoyl}benzyl)-1-Methyl-1H-Indol-5-Yl]carbamate",
      "Cyclopentyl (1-Methyl-3-{[2-(Methyloxy)-4-({[(2-Methylphenyl)Sulfonyl]amino}carbonyl)Phenyl]methyl}-1H-Indol-5-Yl)Carbamate",
      "Carbamic Acid, (3-((2-Methoxy-4-((((2-Methylphenyl)Sulfonyl)Amino)Carbonyl)Phenyl)Methyl)-1-Methyl-1H-Indol-5-Yl)-, Cyclopentyl Ester",
      "4-(5-Cyclopentyloxy-Carbonylamino-1-Methyl-Indol-3-Ylmethyl)-3-Methoxy-N-O-Tolylsulfonylbenzamide",
      "4-(5-Cyclopentyloxycarbonylamino-1-Methyl-1H-Indol-3-Ylmethyl)-3-Methoxy-N-O-Tolylsulfonylbenzamide",
      "4-(5-Cyclopentyloxycarbonylamino-2-Methylindol-3-Yl-Methyl)-3-Methoxy-N-O-Tolylsulfonylbenzamide",
    ],
  },
  {
    primary_id: "D05DWG",
    names: [
      "Dodecyl-Alpha-D-Maltoside",
      "DODECYL-ALPHA-D-MALTOSIDE",
      "116183-64-3",
      "N-Dodecylmaltoside",
      "Dodecyl A-D-Maltopyranoside",
      "N-Dodecyl Alpha-D-Maltoside",
      "LMU",
      "(2R,3R,4S,5S,6R)-2-(((2R,3S,4R,5R,6S)-6-(Dodecyloxy)-4,5-Dihydroxy-2-(Hydroxymethyl)Tetrahydro-2H-Pyran-3-Yl)Oxy)-6-(Hydroxymethyl)Tetrahydro-2H-Pyran-3,4,5-Triol",
      "AC1L9I0U",
      "Dodecyl Alpha-D-Maltopyranoside",
      "SCHEMBL17817178",
      "Dodecyl-I+/--D-Maltopyranoside",
      "ZINC14253923",
      "GC5636",
      "AKOS030567697",
      "DB03279",
      "W0537",
      "K-1145",
      "183D643",
      "W-200893",
      "Dodecyl 4-O-Alpha-D-Glucopyranosyl-Alpha-D-Glucopyranoside",
    ],
  },
  {
    primary_id: "D05DWM",
    names: ["Hydrocortisone Modified Release Capsules"],
  },
  {
    primary_id: "D05DXT",
    names: ["XEN-D0103"],
  },
  {
    primary_id: "D05DXW",
    names: ["JWH-251"],
  },
  {
    primary_id: "D05DXZ",
    names: ["RXI-109"],
  },
  {
    primary_id: "D05DYX",
    names: ["ZM 253270"],
  },
  {
    primary_id: "D05DZA",
    names: ["PUR-0110"],
  },
  {
    primary_id: "D05DZJ",
    names: ["TDI-0055"],
  },
  {
    primary_id: "D05DZK",
    names: ["Interferon Alpha 2a"],
  },
  {
    primary_id: "D05DZR",
    names: ["Elotuzumab", "BMS-901608"],
  },
  {
    primary_id: "D05EAJ",
    names: ["ETV-003"],
  },
  {
    primary_id: "D05EAM",
    names: ["Pentamidine"],
  },
  {
    primary_id: "D05EBY",
    names: ["PCSK9 Adnectin"],
  },
  {
    primary_id: "D05EBZ",
    names: ["4-(4-Cyanophenoxy)-3-Pyridinesulfonamide", "CHEMBL1164572", "BDBM50320503"],
  },
  {
    primary_id: "D05ECA",
    names: ["LJM716"],
  },
  {
    primary_id: "D05ECK",
    names: ["SKF-110679"],
  },
  {
    primary_id: "D05ECQ",
    names: ["RX-10001"],
  },
  {
    primary_id: "D05EDA",
    names: ["AcAsp-Glu-Cha-Val-Prb-Cys", "CHEMBL179963"],
  },
  {
    primary_id: "D05EDD",
    names: ["VPC23019"],
  },
  {
    primary_id: "D05EDR",
    names: ["Beta-Methylmelatonin", "CHEMBL210147", "SCHEMBL7055939"],
  },
  {
    primary_id: "D05EDW",
    names: ["6,7-Dimethoxy-3-(2-Methoxy-Phenyl)-Quinoline", "CHEMBL419248", "ZINC13737710"],
  },
  {
    primary_id: "D05EEJ",
    names: ["ETRX-101"],
  },
  {
    primary_id: "D05EEV",
    names: [
      "DAU-5750",
      "Dau-5750",
      "Dau 5750",
      "AC1Q6M3K",
      "AC1L4RM2",
      "(1r,5s)-8-Methyl-8-Azabicyclo[3.2.1]oct-3-Yl(3s)-3-Methyl-2-Oxo-1,2,3,4-Tetrahydroquinoline-3-Carboxylate",
      "164575-86-4",
      "[(1R,5S)-8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl] (3S)-3-Methyl-2-Oxo-1,4-Dihydroquinoline-3-Carboxylate",
      "3-Quinolinecarboxylic Acid, 1,2,3,4-Tetrahydro-3-Methyl-2-Oxo-, 8-Methyl-8-Azabicyclo(3.2.1)Oct-3-Yl Ester, (3(S)-Endo)-",
    ],
  },
  {
    primary_id: "D05EFR",
    names: ["SprayGel"],
  },
  {
    primary_id: "D05EGH",
    names: ["Delta-Kappa Opioid Heterodimer"],
  },
  {
    primary_id: "D05EGR",
    names: [
      "Bis-Napthyl Beta-Ketophosphonic Acid",
      "BIS-NAPTHYL BETA-KETOPHOSPHONIC ACID",
      "[(1R)-1-Naphthalen-1-Yl-2-Naphthalen-2-Yl-2-Oxoethyl]phosphonic Acid",
      "(2-NAPHTHALEN-2-YL-1-NAPHTHALEN-1-YL-2-OXO-ETHYL)-PHOSPHONIC ACID",
      "KTP",
      "1kyn",
      "AC1L9K5C",
      "DB02360",
      "(1R)-1-(Naphthalen-1-Yl)-2-(Naphthalen-2-Yl)-2-Oxoethylphosphonic Acid",
    ],
  },
  {
    primary_id: "D05EHE",
    names: ["(S,R)-Pseudoxandrine Hydrochloride", "CHEMBL504303", "(S,R)-Pseudoxandrine HCl"],
  },
  {
    primary_id: "D05EHJ",
    names: ["IMOVAX"],
  },
  {
    primary_id: "D05EHM",
    names: ["6-Cl-IMP"],
  },
  {
    primary_id: "D05EHP",
    names: ["ISIS 104254"],
  },
  {
    primary_id: "D05EHS",
    names: ["MyoDys"],
  },
  {
    primary_id: "D05EIT",
    names: ["CVT-E002"],
  },
  {
    primary_id: "D05EJG",
    names: [
      "L-Tryptophan",
      "Ardeytropin",
      "Kalma",
      "LTR",
      "Lyphan",
      "Optimax",
      "Pacitron",
      "Sedanoct",
      "Triptofano",
      "Trofan",
      "Trp",
      "Tryptacin",
      "Tryptan",
      "Tryptophane",
      "Tryptophanum",
      "Triptofano [Spanish]",
      "Tryptophane [French]",
      "Tryptophanum [Latin]",
      "EH 121",
      "MT1",
      "T 0254",
      "Alti-Tryptophan",
      "L-TRYPTOPHAN SIGMA GRADE",
      "L-Trp",
      "L-Tryptofan",
      "L-Tryptophane",
      "L-Ttp",
      "TRP NH3+ COOH",
      "TRP-01",
      "Tryptophan (VAN)",
      "Tryptophan [USAN:INN]",
      "H-Trp-Oh",
      "Indole-3-Alanine",
      "L-Tryptophan (9CI)",
      "L-Tryptophan (JP15)",
      "Tryptophan (H-3)",
      "Tryptophan (USP/INN)",
      "L-B-3-Indolylalanine",
      "L-Beta-3-Indolylalanine",
      "Tryptophan, L-(8CI)",
      "Alanine, 3-Indol-3-Yl",
      "Alpha'-Amino-3-Indolepropionic Acid",
      "L-(-)-Tryptophan",
      "L-(-)-Tryptophane",
      "L-A-Aminoindole-3-Propionic Acid",
      "L-Alpha-Aminoindole-3-Propionic Acid",
      "L-Alpha-Amino-3-Indolepropionic Acid",
      "Alpha-Amino-Beta-(3-Indolyl)-Propionic Acid",
      "Alpha-Amino-Beta-(3-Indolyl)-Pr Opionic Acid",
      "Propionic Acid, 2-Amino-3-Indol-3-Yl",
      "S(-)-1-Alpha-Aminoindole-3-Propionic Acid",
      "(2S)-2-Amino-3-(1H-Indol-3-Yl)Propanoic Acid",
      "(L)-TRYPTOPHAN",
      "(S)-(-)-2-Amino-3-(3-Indolyl)Propionic Acid",
      "(S)-(-)-Tryptopha N",
      "(S)-(-)-Tryptophan",
      "(S)-2-Amino-3-(1H-Indol-3-Yl)Propanoic Acid",
      "(S)-2-Amino-3-(3-Indolyl)Propionic Acid",
      "(S)-Tryptophan",
      "(S)-A-Amino-1H-Indole-3-Propanoic Acid",
      "(S)-A-Amino-B-Indolepropionic Acid",
      "(S)-A-Aminoindole-3-Propionic Acid",
      "(S)-Alpha-Amino-1H-Indole-3-Propanoic Acid",
      "(S)-Alpha-Amino-Beta-Indolepropionic Acid",
      "(S)-Alpha-Aminoindole-3-Propionic Acid",
      "(S)-Alpha-Amino-Beta-(3-Indolyl)-Propionic Acid",
      "1-Beta-3-Indolylalanine",
      "151A3008-4CFE-40C9-AC0B-467EF0CB50EA",
      "1H-Indole-3-Alanine",
      "1H-Indole-3-Alanine (VAN)",
      "1beta-3-Indolylalanine",
      "2-Amino-3-(LH-Indol-3-Yl)-Propanoic Acid",
      "2-Amino-3-Indolylpropanoic Acid",
      "2-Amino-3-Indol-3-Ylpropionic Acid",
      "3-Indol-3-Ylalanine",
    ],
  },
  {
    primary_id: "D05EJO",
    names: ["ISIS 150463"],
  },
  {
    primary_id: "D05EKM",
    names: ["FR-121196"],
  },
  {
    primary_id: "D05EKO",
    names: ["OvaSave", "Autologous Tr1 Cell Therapy (Autoimmune Disease), TxCell"],
  },
  {
    primary_id: "D05EKQ",
    names: ["Beta Amyloid Lowering Agents", "Beta Amyloid Lowering Agents (Alzheimer's Disease)"],
  },
  {
    primary_id: "D05ELM",
    names: ["AC-150", "AC-150A", "AC-150B"],
  },
  {
    primary_id: "D05ELO",
    names: ["Insulin, Porcine"],
  },
  {
    primary_id: "D05ELQ",
    names: ["[Ncy(SO,isopropyl)7]acyline", "CHEMBL441211"],
  },
  {
    primary_id: "D05ELV",
    names: ["Zinc Chloride"],
  },
  {
    primary_id: "D05ELY",
    names: ["Influenza A Virus Vaccine H9N2"],
  },
  {
    primary_id: "D05ELZ",
    names: ["IC9/CAR.19/IL15-Transduced CB-NK Cells"],
  },
  {
    primary_id: "D05EMG",
    names: ["Butylscopolamine", "AC1LCV8Y"],
  },
  {
    primary_id: "D05EMI",
    names: ["PMID28454500-Compound-92"],
  },
  {
    primary_id: "D05EML",
    names: [
      "1'-Benzyl-3-Phenyl-[3,4']bipiperidinyl-2,6-Dione",
      "Benzetimidum",
      "14051-33-3",
      "Benzetimide [INN]",
      "5633-14-7",
      "CHEMBL10272",
      "Benzetimide (INN)",
      "NCGC00160474-01",
      "2-(1-Benzyl-4-Piperidyl)-2-Phenylglutarimide",
      "Dexbenzetimide",
      "119391-55-8",
      "Dextrobenzetimide",
      "Benzezimida",
      "Benzetimidum [INN-Latin]",
      "2-[1-BENZYL-4-PIPERIDYL]-2-PHENYLGLUTARIMIDE",
      "Benzezimida [INN-Spanish]",
      "[3,4'-Bipiperidine]-2,6-Dione, 3-Phenyl-1'-(Phenylmethyl)-, (S)-",
      "Benzetimide(Hydrochloride)",
      "1''-Benzyl-3-Phenyl-[3,4'']bipiperidinyl-2,6-Dione",
      "AC1L2J7P",
      "DSSTox_RID_81404",
      "DSSTox_CID_26173",
    ],
  },
  {
    primary_id: "D05EMT",
    names: ["1,2-Bis(2,5-Difluorophenyl)Ethane-1,2-Dione", "CHEMBL242721", "SCHEMBL3180092"],
  },
  {
    primary_id: "D05EMV",
    names: ["GR148672X"],
  },
  {
    primary_id: "D05EMW",
    names: ["Long-Acting Polysialic Acid-Interferon Alpha 2b Conjugate"],
  },
  {
    primary_id: "D05EMX",
    names: [
      "2-(2-(2,5-Dichlorophenylamino)Phenyl)Acetic Acid",
      "CHEMBL77981",
      "SCHEMBL7166052",
      "JSJQNYIFLKCXRE-UHFFFAOYSA-N",
      "ZINC13809288",
      "BDBM50090679",
      "2-(2,5-Dichloroanilino)Benzeneacetic Acid",
      "2-[(2,5-Dichlorophenyl)Amino]phenylacetic Acid",
      "[2-(2,5-Dichloro-Phenylamino)-Phenyl]-Acetic Acid",
    ],
  },
  {
    primary_id: "D05ENG",
    names: ["AVX701", "Cea Cancer Immunotherapy"],
  },
  {
    primary_id: "D05ENJ",
    names: ["Fasitibant Chloride"],
  },
  {
    primary_id: "D05EON",
    names: ["Quinolinyl Compound 2"],
  },
  {
    primary_id: "D05EOT",
    names: ["CM-PK"],
  },
  {
    primary_id: "D05EOW",
    names: [
      "Diarylpropionitril",
      "1428-67-7",
      "Diarylpropionitrile",
      "2,3-Bis(4-Hydroxyphenyl)Propionitrile",
      "2,3-Bis(4-Hydroxyphenyl)Propanenitrile",
      "2,3-Bis(4-Hydroxyphenyl)-Propionitrile",
      "2,3-Bis(P-Hydroxyphenyl)Propionitrile",
      "CHEMBL334773",
      "CHEBI:63949",
      "DPN Compound",
      "Benzenepropanenitrile,4-Hydroxy-A-(4-Hydroxyphenyl)-",
      "SR-01000597601",
      "BRN 3337109",
      "SC-4473",
      "2,3-Bis(P-Hydroxyphenyl)-Propionitrile",
      "Propionitrile, 2,3-Bis(P-Hydroxyphenyl)",
      "Propionitrile, 2,3-Bis(P-Hydroxyphenyl)-",
      "AC1L2T6L",
      "GTPL2825",
      "SCHEMBL1940848",
      "DTXSID0040387",
      "CTK8F9375",
      "MolPort-003-983-761",
    ],
  },
  {
    primary_id: "D05EPE",
    names: ["VRC-SRSDNA015-00-VP"],
  },
  {
    primary_id: "D05EPI",
    names: ["Isosorbide-Di-(4-Nitrophenyl Carbamate)", "CHEMBL603100"],
  },
  {
    primary_id: "D05EPM",
    names: [
      "Ethotoin",
      "Accenon",
      "Ethotoine",
      "Ethotoinum",
      "Etotoina",
      "Peganone",
      "Pegoanone",
      "Abbott Brand Of Ethotoin",
      "Accenon (TN)",
      "Ethotoine [INN-French]",
      "Ethotoinum [INN-Latin]",
      "Etotoina [INN-Spanish]",
      "Peganone (TN)",
      "Ethotoin [INN:BAN:JAN]",
      "Ethotoin (JAN/USP/INN)",
      "(+-)-3-Ethyl-5-Phenylhydantoin",
      "1-Ethyl-2,5-Dioxo-4-Phenylimidazolidine",
      "3-Ethyl-5-Phenyl-2,4-Imidazolidinedione",
      "3-Ethyl-5-Phenylhydantoin",
      "3-Ethyl-5-Phenylimidazolidin-2,4-Dione",
      "3-Ethyl-5-Phenylimidazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D05EPO",
    names: [
      "1-Aminobutylphosphonic Acid",
      "(1-Aminobutyl)Phosphonic Acid",
      "13138-36-8",
      "Phosphonic Acid, (1-Aminobutyl)-",
      "NSC-117799",
      "CHEMBL1089898",
      "Phosphonic Acid,P-(1-Aminobutyl)-",
      "Alpha-Aminobutanephosphonic Acid",
      "DL-(1-Aminobutyl)Phosphonic Acid",
      "Phosphonic Acid, P-(1-Aminobutyl)-",
      "NSC117799",
      "(+/-)-(1-Aminobutyl)Phosphonic Acid",
      "J441.089K",
      "AC1Q6RLK",
      "AC1L6SK2",
      "SCHEMBL745916",
      "Alpha-Aminobutylphosphonic Acid",
      "CTK4B7247",
      "BDBM50316029",
      "AKOS015854597",
      "FCH1112358",
      "TRA0100955",
      "KB-00224",
      "TR-032039",
      "DB-042023",
      "FT-0636808",
      "C-51169",
    ],
  },
  {
    primary_id: "D05EPU",
    names: ["ZD7114"],
  },
  {
    primary_id: "D05EPV",
    names: ["Ozoralizumab", "ATN-103"],
  },
  {
    primary_id: "D05EPY",
    names: [
      "L-693612",
      "(-)-(2S,4S)-6-(3-Methoxypropyl)-4-(Propylamino)-5,6-Dihydro-4H-Thieno[2,3-B]thiopyran-2-Sulfonamide 7,7-Dioxide",
      "138301-71-0",
    ],
  },
  {
    primary_id: "D05EQC",
    names: ["LY301875"],
  },
  {
    primary_id: "D05EQG",
    names: ["[3H]spiperone"],
  },
  {
    primary_id: "D05ERA",
    names: ["PXL-01"],
  },
  {
    primary_id: "D05ERF",
    names: ["TQX-173"],
  },
  {
    primary_id: "D05ERT",
    names: ["GV-196771"],
  },
  {
    primary_id: "D05ETC",
    names: ["SN-32793"],
  },
  {
    primary_id: "D05ETD",
    names: ["TRC-8XXX Series"],
  },
  {
    primary_id: "D05ETG",
    names: ["Tyr-Pro-Phe-D-Phg-NH2", "CHEMBL208990"],
  },
  {
    primary_id: "D05ETM",
    names: [
      "CP-99,994",
      "CP-99994",
      "CHEMBL441225",
      "CP99994",
      "CP 99994",
      "(2S,3S)-N-(2-Methoxybenzyl)-2-Phenylpiperidin-3-Amine",
      "(2S,3S)-N-[(2-Methoxyphenyl)Methyl]-2-Phenylpiperidin-3-Amine",
      "(2~{S},3~{S})-~{N}-[(2-Methoxyphenyl)Methyl]-2-Phenyl-Piperidin-3-Amine",
      "UNII-5KM4QA7RZC",
      "5KM4QA7RZC",
      "AC1NSJX2",
      "(2-Methoxy-Benzyl)-(2-Phenyl-Piperidin-3-Yl)-Amine",
      "SCHEMBL1282327",
      "GTPL2102",
      "DTQNEFOKTXXQKV-HKUYNNGSSA-N",
      "ZINC22441784",
      "BDBM50000041",
      "AKOS027453648",
      "NCGC00370844-01",
      "Cis-3-(2-Methoxybenzylamino)-2-Phenylpiperidine",
    ],
  },
  {
    primary_id: "D05ETN",
    names: ["4-(2-Methyl-8-Quinolinoxy)-3-Pyridinesulfonamide", "CHEMBL1165071"],
  },
  {
    primary_id: "D05EUH",
    names: ["H5N1 Influenza Virus Vaccine"],
  },
  {
    primary_id: "D05EUI",
    names: ["DeltaFLU"],
  },
  {
    primary_id: "D05EUR",
    names: ["LJN452"],
  },
  {
    primary_id: "D05EUS",
    names: ["Human Microbio Modulator"],
  },
  {
    primary_id: "D05EUV",
    names: ["FGGFTGARKSARKRRNQ"],
  },
  {
    primary_id: "D05EVF",
    names: ["Iodine-123-Iodophenylpentadecanoic Acid"],
  },
  {
    primary_id: "D05EVI",
    names: ["U-78875"],
  },
  {
    primary_id: "D05EWB",
    names: ["C[RGDf-(S)-Alpha-TfmV]", "CHEMBL203693"],
  },
  {
    primary_id: "D05EWD",
    names: [
      "JWH-167",
      "1-(1-Pentyl-1H-Indol-3-Yl)-2-Phenylethanone",
      "864445-37-4",
      "UNII-LQX1W3537N",
      "CHEMBL365878",
      "LQX1W3537N",
      "1-Pentyl-3-Phenylacetylindole",
      "SCHEMBL20552905",
      "DTXSID20235554",
      "JWH 167",
      "AMCPOEOUXQWESI-UHFFFAOYSA-N",
      "3543AC",
      "BDBM50170338",
      "ZINC28460304",
      "AKOS016000818",
      "AJ-83459",
      "KB-212442",
      "AX8250724",
    ],
  },
  {
    primary_id: "D05EWZ",
    names: [
      "SPIROINDANYLOXYMORPHONE",
      "7-Spiroindanyloxymorphone",
      "SIOM",
      "7-(Spiroindano)Oxymorphone",
      "CHEMBL399708",
      "150380-34-0",
      "SCHEMBL12013205",
      "7-(2'-Spiroindanyl)Oxymorphone",
      "7-(2''-Spiroindanyl)Oxymorphone",
      "BDBM50297170",
      "1',3',7,7a,8,9-Hexahydro-3,7a-Dihydroxy-12-Methylspiro(6H-8,9c-(Iminoethano)Phenanthro(4,5-Bcd)Furan-6,2'-(2H)Inden)-5(4aH)-One (4aR-(4aalpha,7aalpha,8alpha,9calpha))-",
      "Spiro(6H-8,9c-(Iminoethano)Phenanthro(4,5-Bcd)Furan-6,2'-(2H)Inden)-5(4aH)-One, 1',3',7,7a,8,9-Hexahydro-3,7a-Dihydroxy-12-Methyl-, (4aR-(4aalpha",
    ],
  },
  {
    primary_id: "D05EXN",
    names: ["CC-223"],
  },
  {
    primary_id: "D05EXX",
    names: ["AKNADILACTAM", "Aknadilactam", "CHEMBL1097185"],
  },
  {
    primary_id: "D05EYA",
    names: [
      "1,2-Bis-(4-Chloro-Phenyl)-Ethane-1,2-Dione",
      "4,4'-Dichlorobenzil",
      "3457-46-3",
      "1,2-Bis(4-Chlorophenyl)Ethane-1,2-Dione",
      "4,4'-Dichlorodibenzoyl",
      "BIS(4-CHLOROPHENYL)ETHANEDIONE",
      "Ethanedione, Bis(4-Chlorophenyl)-",
      "Bis(P-Chlorophenyl)Ethanedione",
      "1,2-Bis(4-Chlorophenyl)-1,2-Ethanedione",
      "Bis(4-Chlorophenyl)Ethane-1,2-Dione",
      "88372-92-3",
      "1,2-Ethanedione, 1,2-Bis(4-Chlorophenyl)-",
      "Benzil, 4,4'-Dichloro-",
      "Dichlorbenzil",
      "C14H8Cl2O2",
      "NSC274",
      "NSC 274",
      "EINECS 222-387-7",
      "PubChem3923",
      "P-P'-Dichloro-Benzil",
      "Benzil,4'-Dichloro-",
      "4,4'-Dichloro Benz",
    ],
  },
  {
    primary_id: "D05EYG",
    names: ["L-745337"],
  },
  {
    primary_id: "D05EZE",
    names: [
      "Dmt-Pro-Phe-Phe-NH2",
      "CHEMBL331325",
      "596792-36-8",
      "Dmt-Endomorphin-2",
      "CTK1E6813",
      "DTXSID10435187",
      "BDBM50149384",
      "AKOS030610408",
      "L-Phenylalaninamide, 2,6-Dimethyl-L-Tyrosyl-L-Prolyl-L-Phenylalanyl-",
      "(S)-1-[(S)-2-Amino-3-(4-Hydroxy-2,6-Dimethyl-Phenyl)-Propionyl]-Pyrrolidine-2-Carboxylic Acid [(S)-1-((S)-1-Carbamoyl-2-Phenyl-Ethylcarbamoyl)-2-Phenyl-Ethyl]-Amide",
    ],
  },
  {
    primary_id: "D05FBL",
    names: [
      "1-Deoxy-Ribofuranose-5'-Phosphate",
      "1-DEOXY-RIBOFURANOSE-5'-PHOSPHATE",
      "AC1NRD1H",
      "DB03936",
      "[(2R,3R,4S)-3,4-Dihydroxyoxolan-2-Yl]methyl Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D05FBZ",
    names: [
      "CPHPC",
      "Amyloid Beta Inhibitor",
      "SAP Binding Inhibitor, Roche",
      "Serum Amyloid P Binding Inhibitor, Roche",
      "Ro-63-8695",
    ],
  },
  {
    primary_id: "D05FCO",
    names: [
      "2-Cyclopent-1-Enylethynyl-Pyridine",
      "CHEMBL443487",
      "2-(Cyclopent-1-Enyl-Ethynyl)-Pyridine",
      "BDBM50172132",
    ],
  },
  {
    primary_id: "D05FCR",
    names: ["GT0918"],
  },
  {
    primary_id: "D05FEJ",
    names: ["9-(Biphenyl-4-Yloxy)-1,1,1-Trifluoro-Nonan-2-One"],
  },
  {
    primary_id: "D05FEO",
    names: ["LSN2463359"],
  },
  {
    primary_id: "D05FEQ",
    names: [
      "CYCLORPHAN",
      "17-(Cyclopropylmethyl)Morphinan-3-Ol",
      "4163-15-9",
      "UNII-U6Z73N36V0",
      "EINECS 224-007-5",
      "CHEMBL49269",
      "(-)-3-Hydroxy-N-Cyclopropylmethylmorphinan",
      "U6Z73N36V0",
      "Morphinan, (-)-3-Hydroxy-N-Cyclopropylmethyl-",
      "Morphinan-3-Ol, 17-(Cyclopropylmethyl)- (8CI,9CI)",
      "SCHEMBL159134",
      "CTK4I5050",
      "ZINC4215487",
      "PDSP2_000668",
      "BDBM50135800",
      "N-Cyclopropylmethyl 3-Hydroxymorphinan",
      "3-Hydroxy-N-Cyclopropylmethylmorphinan",
      "LS-91818",
      "(-)-17-(Cyclopropylmethyl)Morphinan-3-Ol",
      "(-)-3-Hydroxy-N-Cycloproypylmethylmorphinan Mandelate",
    ],
  },
  {
    primary_id: "D05FFB",
    names: [
      "5,11-Dimethyl-6H-Pyrido[4,3-B]carbazol-9-Ol",
      "9-Hydroxyellipticine",
      "9-Hydroxyellipticin",
      "51131-85-2",
      "Hydroxyellipticine",
      "ELLIPTICINE, 9-HYDROXY-",
      "UNII-9G4A3ET6XG",
      "IGIG 929",
      "Hydroxy-9 Ellipticine [French]",
      "EINECS 257-000-0",
      "NSC 237070",
      "NSC 210717",
      "9G4A3ET6XG",
      "CHEMBL26559",
      "CHEBI:88297",
      "C17H14N2O",
      "5,11-Dimethyl-6H-Pyrido(4,3-B)Carbazol-9-Ol",
      "6H-Pyrido(4,3-B)Carbazol-9-Ol, 5,11-Dimethyl-",
      "6H-Pyrido[4,3-B]carbazol-9-Ol, 5,11-Dimethyl-",
      "9-Hydroxy-5,11-Dimethyl-6H-Pyrido[4,3-B]carbazole",
      "Hydroxy-9 Ellipticine",
    ],
  },
  {
    primary_id: "D05FFR",
    names: ["MGBG"],
  },
  {
    primary_id: "D05FFT",
    names: ["HDS-029"],
  },
  {
    primary_id: "D05FFY",
    names: ["1-(4-Cyanobenzyl)-5-Methyl-1H-Imidazole", "4-(5-Methyl-Imidazol-1-Ylmethyl)-Benzonitrile"],
  },
  {
    primary_id: "D05FGG",
    names: ["Azilsartan"],
  },
  {
    primary_id: "D05FGP",
    names: ["DWP-418"],
  },
  {
    primary_id: "D05FGR",
    names: ["R-Flurbiprofen"],
  },
  {
    primary_id: "D05FGY",
    names: ["ISIS 343867"],
  },
  {
    primary_id: "D05FHZ",
    names: ["MGD009"],
  },
  {
    primary_id: "D05FIJ",
    names: ["GSK2816126"],
  },
  {
    primary_id: "D05FIN",
    names: ["ISIS 114184"],
  },
  {
    primary_id: "D05FIP",
    names: ["Tavoceptdimesna"],
  },
  {
    primary_id: "D05FJM",
    names: ["PUUPEHEDIONE"],
  },
  {
    primary_id: "D05FKE",
    names: ["6-(Piperazin-1-Yl)-9-Propyl-9H-Purine", "CHEMBL594489"],
  },
  {
    primary_id: "D05FKT",
    names: [
      "L-689065",
      "CHEMBL295761",
      "SCHEMBL9581325",
      "BDBM50045667",
      "3-[1-(4-Chloro-Benzyl)-4-Methyl-6-(5-Phenyl-Pyridin-2-Ylmethoxy)-4,5-Dihydro-1H-Thiopyrano[2,3,4-Cd]indol-2-Yl]-2,2-Dimethyl-Propionic Acid",
      "3-[1-(4-Chloro-Benzyl)-4-Methyl-6-(5-Phenyl-Pyridin-2-Ylmethoxy)-4,5-Dihydro-1H-3-Thia-1-Aza-Acenaphthylen-2-Yl]-2,2-Dimethyl-Propionic Acid",
    ],
  },
  {
    primary_id: "D05FKU",
    names: ["ETC-1922159"],
  },
  {
    primary_id: "D05FLL",
    names: [
      "CALOPOCARPIN",
      "Calopocarpin",
      "CHEMBL1096407",
      "MolPort-005-945-433",
      "ZINC15115178",
      "BDBM50317436",
      "MCULE-1067987266",
      "NCGC00347827-02",
      "NCGC00347827-02_C20H20O4_6H-Benzofuro[3,2-C][1]benzopyran-3,9-Diol, 6a,11a-Dihydro-2-(3-Methyl-2-Buten-1-Yl)-, (6aR,11aR)-",
    ],
  },
  {
    primary_id: "D05FMD",
    names: [
      "5-Chlorosalicylic Acid",
      "5-CHLOROSALICYLIC ACID",
      "5-Chloro-2-Hydroxybenzoic Acid",
      "321-14-2",
      "Benzoic Acid, 5-Chloro-2-Hydroxy-",
      "2-Hydroxy-5-Chlorobenzoic Acid",
      "5 CSA",
      "Salicylic Acid, 5-Chloro-",
      "5-Chlorosalicyclic Acid",
      "5-Chloro Salicylic Acid",
      "5-Chloro-2-Hydroxy-Benzoic Acid",
      "UNII-J2YFG68VCZ",
      "CCRIS 4485",
      "EINECS 206-283-9",
      "NSC 30111",
      "J2YFG68VCZ",
      "BRN 2046665",
      "CHEMBL1375",
      "AI3-22259",
      "CHEBI:420128",
      "NKBASRXWGAGQDP-UHFFFAOYSA-N",
      "5-Chloro-Salicylic Acid",
      "5-Chloro-Salicyclic Acid",
      "ACMC-209hr2",
      "Oprea1_451559",
      "5-Chloro-2-Hydroxy-Benzoate",
      "4-10-00-00207 (Beilstein Handb",
    ],
  },
  {
    primary_id: "D05FMK",
    names: ["2-N-Propargylamino-1-(4-Methylthiophenyl)Propane", "CHEMBL1079255"],
  },
  {
    primary_id: "D05FMZ",
    names: [
      "C-(1H-Indol-3-Yl)-Methylamine",
      "(1H-Indol-3-Yl)Methanamine",
      "22259-53-6",
      "1H-Indole-3-Methanamine",
      "3-(Aminomethyl)Indole",
      "1H-Indol-3-Ylmethanamine",
      "Indol-3-Ylmethylamine",
      "1H-Indol-3-Ylmethylamine",
      "3-Aminomethylindole",
      "CHEBI:65009",
      "CHEMBL6509",
      "3-Indolylmethylamine",
      "Indole-3-Methanamine",
      "3-Aminomethyl Indole",
      "AC1LAOER",
      "3-(Aminomethyl) Indole",
      "1H-Indol-3-Ylmethyl Amine",
      "Indole, 3-(Aminomethyl)-",
      "1H-Indol-3-Yl-Methyl-Amine",
      "SCHEMBL701395",
      "AC1Q548H",
      "C-(1H-Indol-3-Yl)Methylamine",
      "1-(1H-Indol-3-Yl)Methanamine",
      "JXYGLMATGAAIBU-UHFFFAOYSA-",
    ],
  },
  {
    primary_id: "D05FNF",
    names: ["Ciclosporin Intravenous"],
  },
  {
    primary_id: "D05FNN",
    names: ["Sulfamic Acid 7-Sulfamoyloxy-Heptyl Ester"],
  },
  {
    primary_id: "D05FOB",
    names: [
      "GenFusAb",
      "Anti-Fusion Oncoprotein Single-Strand Antibody Gene Therapy (Leukemia), Haplomic Technologies",
    ],
  },
  {
    primary_id: "D05FOF",
    names: ["CHS-0214"],
  },
  {
    primary_id: "D05FOV",
    names: ["2-Oleoyl Glycerol", "2-Monoolein", "2-Oleoyl-Glycerol", "2-Oleoylglycerol (2OG)"],
  },
  {
    primary_id: "D05FPU",
    names: ["4-(Butylthio)-2-Methoxybenzonitrile", "CHEMBL455566"],
  },
  {
    primary_id: "D05FPZ",
    names: ["M7824"],
  },
  {
    primary_id: "D05FQD",
    names: ["Metaiodobenzylguanidine I-131", "Azedra (TN)"],
  },
  {
    primary_id: "D05FQF",
    names: ["FLUNOPROST"],
  },
  {
    primary_id: "D05FQN",
    names: ["7-Methoxy-9H-Beta-Carboline"],
  },
  {
    primary_id: "D05FQX",
    names: ["N-Alkyl-4-Oxazolecar Boxamide Derivative 1"],
  },
  {
    primary_id: "D05FRM",
    names: ["ApoVax104-HPV"],
  },
  {
    primary_id: "D05FRP",
    names: ["Rindopepimut"],
  },
  {
    primary_id: "D05FRX",
    names: [
      "(3,5-Dibromo-4-Hexyloxy-Phenyl)-Acetic Acid",
      "CHEMBL192639",
      "SCHEMBL4900725",
      "BDBM18902",
      "ZINC13643202",
      "3,5-Dibromo-4-Alkoxyphenylalkanoic Acid, 9e",
      "2-[3,5-Dibromo-4-(Hexyloxy)Phenyl]acetic Acid",
    ],
  },
  {
    primary_id: "D05FSK",
    names: ["MSL-143438"],
  },
  {
    primary_id: "D05FSX",
    names: ["Antihyperlipidemia Agent", "Antihyperlipidemia Agent (Oral/BA Technology)"],
  },
  {
    primary_id: "D05FTJ",
    names: [
      "Mefenamic Acid",
      "ApoMefenamic",
      "Bonabol",
      "Contraflam",
      "Coslan",
      "Dysman",
      "Lysalgo",
      "Mefac",
      "Mefacit",
      "Mefedolo",
      "Mefenacid;Mefenamate",
      "Mefenaminsaeure",
      "Mefic",
      "Mycasaal",
      "Namphen",
      "NuMefenamic",
      "Parkemed",
      "Pinalgesic",
      "Ponalar",
      "Ponalgic",
      "Ponmel",
      "Ponstan",
      "Ponstel",
      "Ponstil",
      "Ponstyl",
      "Ponsyl",
      "Pontal",
      "Rolan",
      "Tanston",
      "Vialidon",
      "APS Brand Of Mefenamic Acid",
      "Acide Mefenamique",
      "Acide Mefenamique [French]",
      "Acido Mefenamico",
      "Acidum Mefenamicum",
      "Antigen Brand Of Mefenamic Acid",
      "Apo Mefenamic",
      "Apotex Brand Of Mefenamic Acid",
      "Ashbourne Brand Of Mefenamic Acid",
      "Chemidex Brand Of Mefenamic Acid",
      "Clonmel Brand Of Mefenamic Acid",
      "Elan Brand Of Mefenamic Acid",
      "Farmasierra Brand Of Mefenamic Acid",
      "First Horizon Brand Of Mefenamic Acid",
      "Godecke Brand Of Mefenamic Acid",
      "Mefanamic Acid",
      "Mefenaminic Acid",
      "Mefenaminsaeure [German]",
      "Mephenamic Acid",
      "Mephenaminic Acid",
      "Methenamic Acid",
      "Nu Mefenamic",
      "Nu Pharm Brand Of Mefenamic Acid",
      "PMS Mefenamic Acid",
      "Parke Davis Brand Of Mefenamic Acid",
      "Pfizer Brand Of Mefenamic Acid",
      "Pharmascience Brand Of Mefenamic Acid",
      "Pinewood Brand Of Mefenamic Acid",
      "Ponstan Forte",
      "Rowa Brand Of Mefenamic Acid",
      "Tamany Bonsan",
      "Warner Lambert Brand Of Mefenamic Acid",
      "CL 473",
      "CN 35355",
      "HL 1",
      "ID8",
      "INF 3355",
      "M1782",
      "AGN-1255",
      "Ac. Mefenamico",
      "Ac. Mefenamico [Italian]",
      "Acid, Mefenamic",
      "Acid, Mefenaminic",
      "Acide Mefenamique [INN-French]",
      "Acido Mefenamico [INN-Spanish]",
      "Acidum Mefenamicum [INN-Latin]",
      "Apo-Mefenamic",
      "Bafameritin-M",
      "Bafhameritin-M",
      "CN-35355",
      "Dyfenamic (TN)",
      "F0850-6853",
      "Forte, Ponstan",
      "INF-3355",
      "In-M",
      "Mafepain (TN)",
      "Meftal (TN)",
      "Mephadolor (TN)",
      "Nu-Mefenamic",
      "Nu-Pharm Brand Of Mefenamic Acid",
      "PMS-Mefenamic Acid",
      "Parkemed (TN)",
      "Ponstal (TN)",
      "Ponstan (TN)",
      "Ponstel (TN)",
      "Potarlon (TN)",
      "Warner-Lambert Brand Of Mefenamic Acid",
      "Mefenamic Acid (JP15/USP/INN)",
      "Mefenamic Acid [USAN:INN:BAN:JAN]",
      "N-2,3-Xylylanthranilic Acid",
      "N-(2,3-Dimethylphenyl)Anthranilic Acid",
      "N-(2,3-Xylyl)Anthranilic Acid",
      "N-(2,3-Xylyl)-2-Aminobenzoic Acid",
      "2-((2,3-Dimethylphenyl)Amino)Benzoic Acid",
      "2-(2,3-Dimethylanilino)Benzoic Acid",
      "2-(2,3-Xylidino)Benzoic Acid",
      "2-(2,3-Dimethylphenylamino)Benzoic Acid",
      "2-[(2,3-Dimethylphenyl)Amino]benzoic Acid",
    ],
  },
  {
    primary_id: "D05FTY",
    names: ["ES-1005"],
  },
  {
    primary_id: "D05FUP",
    names: ["CAR-T Cells Targeting Mesothelin"],
  },
  {
    primary_id: "D05FUU",
    names: ["Pratosartan"],
  },
  {
    primary_id: "D05FUY",
    names: [
      "1,8-Dichloro-6-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL192876",
      "SCHEMBL4456831",
      "YTFVBUBYMJJWQZ-UHFFFAOYSA-N",
      "ZINC13645103",
      "BDBM50168322",
    ],
  },
  {
    primary_id: "D05FVK",
    names: [
      "RPI-78M Injectable",
      "Antiviral Agent, Receptogen",
      "RPI-78M",
      "Receptin (Injectable), ReceptoPharm",
      "Receptin (Oral), ReceptoPharm",
      "RPI-78M (Injectable), ReceptoPharm",
      "RPI-78M (Oral), ReceptoPharm",
    ],
  },
  {
    primary_id: "D05FVW",
    names: ["PMID27019002-Compound-41"],
  },
  {
    primary_id: "D05FVX",
    names: ["CDP-484", "Anti-IL-1 Beta FAb-PEG, Celltech"],
  },
  {
    primary_id: "D05FWF",
    names: ["Fostrap"],
  },
  {
    primary_id: "D05FWH",
    names: [
      "SCH-900776",
      "CHEMBL1643208",
      "MK-8776",
      "6-Bromo-3-(1-Methyl-1H-Pyrazol-4-Yl)-5-(Piperidin-3-Yl)Pyrazolo[1,5-A]pyrimidin-7-Amine",
      "GTPL7943",
      "SCHEMBL10380123",
      "CHEBI:131165",
      "HMS3656J19",
      "BCP02883",
      "BDBM50334854",
      "AKOS026750519",
      "MK 8776",
      "NCGC00387868-02",
      "DA-40779",
      "4CA-1150",
      "BCP0726000317",
      "J3.517.083I",
      "891494-63-6 Pound Not SCH900776 Pound Not SCH-900776",
      "6-Bromo-3-(1-Methylpyrazol-4-Yl)-5-Piperidin-3-Ylpyrazolo[5,1-B]pyrimidin-7-Amine",
      "6-Bromo-3-(1-Methylpyrazol-4-Yl)-5-[(3S)-Piperidin-3-Yl]pyrazolo[1,5-A]pyrimidin-7-Amine",
    ],
  },
  {
    primary_id: "D05FWW",
    names: ["Bis(2,3-Dibromo-4,5-Dihydroxyphenyl)Ether", "CHEMBL1269935", "SCHEMBL15511613", "BDBM50329693"],
  },
  {
    primary_id: "D05FXD",
    names: [
      "PMID19788238C66",
      "1192216-03-7",
      "GTPL8141",
      "ZINC45245466",
      "NSC752203",
      "BDBM50299218",
      "NSC-752203",
      "KB-65160",
    ],
  },
  {
    primary_id: "D05FYA",
    names: ["Thioperamide"],
  },
  {
    primary_id: "D05FYR",
    names: [
      "HK2p01 Derivative KLK2 Inhibitor",
      "CHEMBL2171880",
      "GTPL8672",
      "BDBM50396163",
      "2-[[(2S)-2-[[(2S)-2-[[(2S)-2-[[(2S)-2-[[(2S)-2-[[(2S)-6-Amino-2-[[(2S)-2-[[(2S)-2-[[(2S)-2-Aminopropanoyl]amino]-5-(Diaminomethylideneamino)Pentanoyl]amino]-3-Phenylpropanoyl]amino]hexanoyl]amino]-3-Methylbutanoyl]amino]-3-(1H-Indol-3-Yl)Propanoyl]amino]-3-(1H-Indol-3-Yl)Propanoyl]amino]propanoyl]amino]propanoyl]amino]acetic Acid",
    ],
  },
  {
    primary_id: "D05FZE",
    names: [
      "ATPA",
      "AC1Q6BQV",
      "AC1L1D9I",
      "3-(5-Tert-Butyl-3-Hydroxy-1,2-Oxazol-4-Yl)Alanine",
      "GTPL4140",
      "HMS3260K20",
      "Tox21_500139",
      "LP00139",
      "CCG-204234",
      "NCGC00093629-02",
      "NCGC00093629-01",
      "NCGC00015027-03",
      "NCGC00015027-02",
      "NCGC00260824-01",
      "NCGC00015027-04",
      "EU-0100139",
      "2-Azaniumyl-3-(5-Tert-Butyl-3-Oxo-1,2-Oxazol-4-Yl)Propanoate",
    ],
  },
  {
    primary_id: "D05FZM",
    names: ["CD19-UCART"],
  },
  {
    primary_id: "D05FZP",
    names: [
      "3-(Ethoxycarbonyl)Phenylboronic Acid",
      "3-Ethoxycarbonylphenylboronic Acid",
      "4334-87-6",
      "(3-Ethoxycarbonylphenyl)Boronic Acid",
      "3-Carbethoxyphenylboronic Acid",
      "(3-(Ethoxycarbonyl)Phenyl)Boronic Acid",
      "M-Ethoxycarbonylphenylboronic Acid",
      "3-Ethoxycarbonylbenzeneboronic Acid",
      "Ethyl 3-(Dihydroxyboranyl)Benzoate",
      "MFCD02179444",
      "[3-(Ethoxycarbonyl)Phenyl]boronic Acid",
      "3-(Ethoxycarbonyl)Benzeneboronic Acid",
      "3-(Ethoxycarbonyl)Phenyl Boronic Acid",
      "Akos Brn-0097",
      "BENZOIC ACID, 3-BORONO-, 1-ETHYL ESTER",
      "PubChem6092",
      "Phenylboronic Acid, 2",
    ],
  },
  {
    primary_id: "D05GAH",
    names: ["RNAi Molecules, Tekmira"],
  },
  {
    primary_id: "D05GAP",
    names: ["PMID29130358-Compound-Figure10(2a)"],
  },
  {
    primary_id: "D05GAT",
    names: ["H-Arg-Pro-Lys-Pro-Gln-Gln-Phe-OH"],
  },
  {
    primary_id: "D05GBE",
    names: ["3-(Octahydro-Quinolizin-3-Yl)-Phenol", "CHEMBL8120"],
  },
  {
    primary_id: "D05GBH",
    names: ["F4co Vaccine"],
  },
  {
    primary_id: "D05GBR",
    names: [
      "MVA CSP",
      "Circumsporozoite Protein Vaccine (Modified Vaccinia Ankar Virus Vector, Malaria)",
      "Circumsporozoite Protein Vaccine (Modified Vaccinia Ankar Virus Vector, Malaria), EMVI",
    ],
  },
  {
    primary_id: "D05GBV",
    names: ["RDX-5791"],
  },
  {
    primary_id: "D05GBY",
    names: [
      "3-Thiophen-3-Yl-Quinoline",
      "3-(3-Thienyl)Quinoline",
      "56421-85-3",
      "3-(Thiophen-3-Yl)Quinoline",
      "CHEMBL304310",
      "3-Thiophen-3-Ylquinoline",
      "3-(3-Thiophenyl)Quinoline",
      "SCHEMBL19180724",
      "ZINC2392505",
      "BDBM50039081",
      "AKOS004118779",
      "BB 0223675",
    ],
  },
  {
    primary_id: "D05GBZ",
    names: ["ABS-205", "ABS-205R", "ABS-205S"],
  },
  {
    primary_id: "D05GCI",
    names: ["ISIS 25082"],
  },
  {
    primary_id: "D05GCM",
    names: ["TriAb/TriGem"],
  },
  {
    primary_id: "D05GDD",
    names: ["NSC-645831"],
  },
  {
    primary_id: "D05GDP",
    names: [
      "7-Methoxy-2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "CHEMBL6310",
      "7-Methoxy-1,2,3,4-Tetrahydro-Beta-Carboline",
      "AC1LCCZT",
      "7-Methoxy-1,2,3,4-Tetrahydro-9H-Pyrido[3,4-B]indole",
      "SCIRNASYBOGBOO-UHFFFAOYSA-N",
      "BDBM50132097",
      "ZINC13587968",
      "AKOS004119616",
      "91566-22-2",
      "7-Methoxy-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
      "Methyl 2,3,4,9-Tetrahydro-1H-Beta-Carbolin-7-Yl Ether #",
    ],
  },
  {
    primary_id: "D05GDS",
    names: ["N-(Arylalkyl)-1H-Indole-2-Sulfonic Acid Amide Derivative 2"],
  },
  {
    primary_id: "D05GEF",
    names: ["KRN-5500"],
  },
  {
    primary_id: "D05GEL",
    names: ["Cyclic Cyanoethypypazole Derivative 1"],
  },
  {
    primary_id: "D05GEY",
    names: ["PMID25482888-Compound-21"],
  },
  {
    primary_id: "D05GFD",
    names: ["(Sar)WTLNSAGYLLGPKK(Lys-Decanoyl)K", "CHEMBL460706"],
  },
  {
    primary_id: "D05GFM",
    names: ["Tyr-(NMe)Ala-L-Phe-D-Pro-NH2"],
  },
  {
    primary_id: "D05GFQ",
    names: ["Fluzone QIV ID"],
  },
  {
    primary_id: "D05GGD",
    names: ["SYNVISC-ONE"],
  },
  {
    primary_id: "D05GGE",
    names: [
      "2-Phenethylisoquinoline-1,3,4-Trione",
      "CHEMBL382860",
      "SCHEMBL13442858",
      "Isoquinoline-1,3,4-Trione 2d",
      "BDBM10251",
      "CTK7H5068",
      "AKOS015966189",
      "2-PHENETHYL-ISOQUINOLINE-1,3,4-TRIONE",
    ],
  },
  {
    primary_id: "D05GGH",
    names: [
      "6-Chloro-N-(2-Morpholinoethyl)Nicotinamide",
      "CHEMBL596768",
      "446269-58-5",
      "6-Chloro-N-[2-(Morpholin-4-Yl)Ethyl]pyridine-3-Carboxamide",
      "AC1LNWHD",
      "Oprea1_139589",
      "MLS001010907",
      "CTK6H1374",
      "Cid_1228702",
      "6-Chloro-N-(2-Morpholin-4-Ylethyl)Pyridine-3-Carboxamide",
      "MolPort-000-431-678",
      "HMS2765K07",
      "ZINC19397174",
      "BDBM50307188",
      "AKOS000162940",
      "MCULE-8413919053",
      "SMR000352965",
      "ST50046456",
      "6-Chloro-N-(2-Morpholin-4-Ylethyl)Nicotinamide",
      "Z32410126",
      "F1077-0095",
    ],
  },
  {
    primary_id: "D05GGP",
    names: ["HEGF"],
  },
  {
    primary_id: "D05GHT",
    names: ["Thalassemia Therapeutics"],
  },
  {
    primary_id: "D05GIB",
    names: ["KNI-10033"],
  },
  {
    primary_id: "D05GIC",
    names: ["Manzamine Y"],
  },
  {
    primary_id: "D05GIO",
    names: [
      "ICT-037",
      "Anticancer MAbs, Immunocellular Therapeutics",
      "ICT-37",
      "Monoclonal Antibodies (Cancer), Immunocellular Therapeutics",
    ],
  },
  {
    primary_id: "D05GJJ",
    names: ["JR-051"],
  },
  {
    primary_id: "D05GJL",
    names: ["Benzothiazepine Analog 6"],
  },
  {
    primary_id: "D05GJP",
    names: ["LBVH-0101"],
  },
  {
    primary_id: "D05GJW",
    names: [
      "Cladribine",
      "Chlorodeoxyadenosine",
      "Cladarabine",
      "Cladaribine",
      "Cladribina",
      "Cladribinum",
      "CldAdo",
      "Leustat",
      "Leustatin",
      "Litak",
      "Movectro;Mylinax",
      "CL9",
      "RWJ 26251",
      "Leustatin (TN)",
      "RWJ-26251",
      "Cladribine [USAN:INN:BAN]",
      "RWJ-26251-000",
      "Cladribine (JAN/USAN/INN)",
      "Leustatin, 2-Chlorodeoxyadenosine, Cladribine",
      "Adenosine, 2-Chloro-2'-Deoxy",
      "(2R,3S,5R)-5-(6-Amino-2-Chloropurin-9-Yl)-2-(Hydroxymethyl)Oxolan-3-Ol",
      "2 Chlorodeoxyadenosine",
      "2'-Deoxy-2-Chloroadenosine",
      "2-CdA",
      "2-Chloro-2'-Deoxy-Beta-Adenosine",
      "2-Chloro-2'-Deoxyadenosine",
      "2-Chloro-6-Amino-9-(2-Deoxy-Beta-D-Erythropentofuranosyl)Purine",
      "2-Chlorodeoxyadenosine",
      "2-Chloro-6-Amino-9-(2-Deoxy-Beta-D-Erythro-Pentofuranosyl)Purine",
      "2-Chloro-Deoxyadenosine",
    ],
  },
  {
    primary_id: "D05GKC",
    names: ["ZEARALANONE"],
  },
  {
    primary_id: "D05GKD",
    names: ["Tramadol", "Conzip", "Ryzolt", "Tramadol Hydrochloride", "Ultram Er", "Rybix Odt", "Ultram"],
  },
  {
    primary_id: "D05GKN",
    names: ["SBC-106"],
  },
  {
    primary_id: "D05GKS",
    names: ["N-(Phenylamino)-Benzoate Derivative 1"],
  },
  {
    primary_id: "D05GKZ",
    names: ["Follistatin"],
  },
  {
    primary_id: "D05GLI",
    names: ["Cholinergic Therapeutics", "Cholinergic Therapeutics (Cognitive Disorders)"],
  },
  {
    primary_id: "D05GLT",
    names: ["Norisoboldine"],
  },
  {
    primary_id: "D05GMB",
    names: ["BIBF-1202", "BIBF 1202"],
  },
  {
    primary_id: "D05GMG",
    names: ["NNI-351"],
  },
  {
    primary_id: "D05GMT",
    names: ["Pyridinone Carboxamide Derivative 1"],
  },
  {
    primary_id: "D05GMW",
    names: ["4-Methylpyrrolo[3,4-C]carbazole-1,3(2H,6H)-Dione", "CHEMBL380940", "SCHEMBL5828673"],
  },
  {
    primary_id: "D05GNW",
    names: ["AZD-9164"],
  },
  {
    primary_id: "D05GOA",
    names: ["Vivia-1615"],
  },
  {
    primary_id: "D05GOC",
    names: [
      "PT-306",
      "149485-92-7",
      "Thiourea, N-(2-(2-Fluorophenyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(2-Fluorophenyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 2",
      "AC1MHDM9",
      "SCHEMBL6958232",
      "CHEMBL253994",
      "BDBM1895",
      "DTXSID60164293",
      "1-[2-(2-Fluorophenyl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "AKOS030030936",
      "1-(2-Thiazolyl)-3-[2-Fluorophenethyl]thiourea",
      "1-[2-(2-Fluorophenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "N-(2-(2-Fluorophenethyl))-N -(2-Thiazolyl)Thiourea",
    ],
  },
  {
    primary_id: "D05GOM",
    names: [
      "S-20928",
      "152302-33-5",
      "N-(2-Naphthalen-1-Ylethyl)Cyclobutanecarboxamide",
      "N-(2-(1-Naphthalenyl)Ethyl)Cyclobutanecarboxamide",
      "Cyclobutanecarboxamide,N-[2-(1-Naphthalenyl)Ethyl]-",
      "ACMC-20n6et",
      "AC1L2SR8",
      "S 20928",
      "CHEMBL12296",
      "ZINC5833",
      "GTPL1360",
      "SCHEMBL6254856",
      "CTK4C7390",
      "DTXSID00164994",
      "MolPort-009-303-106",
      "GLXSBZGTGMPDKH-UHFFFAOYSA-N",
      "AKOS030612657",
      "MCULE-2771118083",
      "N-[2-(1-Naphthyl)Ethyl]cyclobutanecarboxamide",
      "S20928",
      "N-[2-Naphth-1-Yl-Ethyl]-Cyclobutyl Carboxamide",
      "L000529",
      "N-[2-(Naphthalen-1-Yl)Ethyl]cyclobutanecarboxamide",
    ],
  },
  {
    primary_id: "D05GOP",
    names: ["EPPTB"],
  },
  {
    primary_id: "D05GPB",
    names: [
      "Eptacog Alfa Intravenous",
      "Alfa",
      "Eptacog Alfa (GlycoPEGylation, Intravenous, Hemophilia), Novo Nordisk",
      "Eptacog Alfa (GlycoPEGylation, Subcutaneous, Hemophilia), Novo Nordisk",
    ],
  },
  {
    primary_id: "D05GPI",
    names: ["3-[11-(Benzylmethylamino)Undecyloxy]xanthen-9-One", "CHEMBL224347"],
  },
  {
    primary_id: "D05GPK",
    names: [
      "Biphenyl-3-Ylboronic Acid",
      "3-Biphenylboronic Acid",
      "5122-95-2",
      "Biphenyl-3-Boronic Acid",
      "[1,1'-Biphenyl]-3-Ylboronic Acid",
      "(3-Phenylphenyl)Boronic Acid",
      "3-Biphenyl Boronic Acid",
      "(3-Phenylphenyl)Boranediol",
      "3-PHENYLBENZENEBORONIC ACID",
      "(1,1'-Biphenyl-3-Yl)Boronic Acid",
      "[1,1'-Diphenyl]-3-Ylboronic Acid",
      "MFCD01318102",
      "Boronic Acid, [1,1'-Biphenyl]-3-Yl-",
      "AK-35405",
      "Biphenyl-3-Boronicacid",
      "PubChem6404",
      "Phenylboronic Acid, 5",
      "Biphenyl-3-Bronic Acid",
      "3-Biphenyl-Boronic Acid",
      "ACMC-1AKSL",
      "AC1MC0UE",
      "Biphenyl-3-Ylbronic Acid",
    ],
  },
  {
    primary_id: "D05GPL",
    names: ["Isoxazole-Based Bicyclic Compound 4"],
  },
  {
    primary_id: "D05GPN",
    names: ["Recombinant Human CFH Protein"],
  },
  {
    primary_id: "D05GPO",
    names: [
      "Olopatadine",
      "Allelock",
      "Opatanol",
      "Pataday",
      "Patanase",
      "Patanol",
      "OLOPATADINE HCl",
      "OLOPATADINE HYDROCHLORIDE",
      "ALO4943A",
      "HCl Of KW 4679",
      "KW 4679",
      "O0361",
      "AL-4943A",
      "ALO-4943A",
      "Allelock (TN)",
      "KW-4679",
      "KW-4943A",
      "Olopatadine [INN:BAN]",
      "Pataday (TN)",
      "Patanase (TN)",
      "Patanol S (TN)",
      "{(11Z)-11-[3-(Dimethylamino)Propylidene]-6,11-Dihydrodibenzo[b,e]oxepin-2-Yl}acetic Acid",
      "11-((Z)-3-(Dimethylamino)Propylidene)-6,11-Dihydrodibenz(B,e)Oxepin-2-Acetic Acid",
      "11-((Z)-3-(Dimethylamino)Propylidene)-6,11-Dihydrodibenz(B,e)Oxepin-2-Acetic Acid Hydrochloride",
      "11-((Z)-3-(Dimethylamino)Propylidene)-6,11-Dihydrodibenz(B,e)Oxepin-2-Acetic Acid, Hydrochloride",
      "11-(3-(Dimethylamino)Propylidene)-6,11-Dihydrodibenz(B,e)Oxepin-2-Acetic Acid",
      "2-[(11Z)-11-[3-(Dimethylamino)Propylidene]-6H-Benzo[c][1]benzoxepin-2-Yl]acetic Acid",
      "2-[(11Z)-11-[3-(Dimethylamino)Propylidene]-6H-Benzo[c][1]benzoxepin-2-Yl]acetic Acid Hydrochloride",
    ],
  },
  {
    primary_id: "D05GPX",
    names: ["Y-26763", "127408-31-5"],
  },
  {
    primary_id: "D05GQD",
    names: [
      "CSA-13",
      "CSA-22",
      "CSA-46",
      "CSA-8",
      "CSAs, Ceragenix",
      "Cationic Steroid Antibiotics, Ceragenix",
      "Ceragenin (Bacterial Infection), Ceragenix",
    ],
  },
  {
    primary_id: "D05GQH",
    names: ["ADX-88178"],
  },
  {
    primary_id: "D05GQK",
    names: ["R-L3"],
  },
  {
    primary_id: "D05GQL",
    names: ["KNI-102"],
  },
  {
    primary_id: "D05GQQ",
    names: [
      "6-Bromo-2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "6-Bromo-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
      "23046-69-7",
      "CHEMBL6427",
      "6-Bromo-1,2,3,4-Tetrahydro-Beta-Carboline",
      "AO-800/41069736",
      "SCHEMBL1872944",
      "CTK8H7143",
      "MolPort-022-860-053",
      "WUTVTUWSFMYRJK-UHFFFAOYSA-N",
      "ZINC13587966",
      "BDBM50136495",
      "AKOS022471446",
      "FCH1329538",
      "AJ-64213",
      "KB-205649",
    ],
  },
  {
    primary_id: "D05GQV",
    names: ["RTA-801"],
  },
  {
    primary_id: "D05GRI",
    names: [
      "DEHYDROZINGERONE",
      "Dehydrozingerone",
      "1080-12-2",
      "Feruloylmethane",
      "4-(4-Hydroxy-3-Methoxyphenyl)-3-Buten-2-One",
      "4-(4-Hydroxy-3-Methoxyphenyl)But-3-En-2-One",
      "Vanillalacetone",
      "Dehydro(O)-Paradol",
      "MHSK",
      "Dehydrogingerone",
      "Vanylidenacetone",
      "3-BUTEN-2-ONE, 4-(4-HYDROXY-3-METHOXYPHENYL)-",
      "(O)-Paradol, Dehydro-",
      "Vanillidene Acetone",
      "3-Methoxy-4-Hydroxybenzalacetone",
      "(O)-Dehydroparadol",
      "(E)-4-(4-Hydroxy-3-Methoxyphenyl)But-3-En-2-One",
      "4-Hydroxy-3-Methoxystyryl Methyl Ketone",
      "NSC 5316",
      "NSC 4019",
      "Vanillylidene Acetone",
      "UNII-8CJX5I27B7",
      "Vanillylideneacetone",
    ],
  },
  {
    primary_id: "D05GRY",
    names: ["Nesiritide"],
  },
  {
    primary_id: "D05GRZ",
    names: ["N-(2,4-Dimethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1092032", "BDBM50314141"],
  },
  {
    primary_id: "D05GSF",
    names: ["PMID15955699C24"],
  },
  {
    primary_id: "D05GSI",
    names: ["LA-480", "Bispecific C-Met Monoclonal Antibody (Cancer), Lilly"],
  },
  {
    primary_id: "D05GSS",
    names: ["ISONEORAUTENOL"],
  },
  {
    primary_id: "D05GSZ",
    names: [
      "AR-HO29953XX",
      "CHEMBL117520",
      "AR-H-029953XX",
      "BDBM50149274",
      "2-[4-(3,4-Dichloro-Phenylcarbamoyl)-Benzylamino]-4-Nitro-Benzoic Acid",
    ],
  },
  {
    primary_id: "D05GUD",
    names: ["AX-048", "CHEMBL573332"],
  },
  {
    primary_id: "D05GUK",
    names: ["INTOPLICINE"],
  },
  {
    primary_id: "D05GVL",
    names: ["2-(6-Methoxy-Indole-1-Sulfonyl)-Benzoic Acid", "CHEMBL365508"],
  },
  {
    primary_id: "D05GVO",
    names: ["PAT-NM-1"],
  },
  {
    primary_id: "D05GVQ",
    names: ["Muromonab-Cd3"],
  },
  {
    primary_id: "D05GVV",
    names: ["Pyrimidine Derivative 5"],
  },
  {
    primary_id: "D05GVY",
    names: ["Thioacetic Acid S-(6-Phenylcarbamoyl-Hexyl) Ester", "CHEMBL111806", "SCHEMBL14812153"],
  },
  {
    primary_id: "D05GWF",
    names: ["PMID2153213C2d"],
  },
  {
    primary_id: "D05GWI",
    names: [
      "NSC-106547",
      "1,4-Dimethoxyanthracene",
      "13076-29-4",
      "Anthracene, 1,4-Dimethoxy-",
      "MLS002703923",
      "CHEMBL223575",
      "YCTBFSAWJWMRGO-UHFFFAOYSA-N",
      "NSC106547",
      "NSC 106547",
      "Anthracene,4-Dimethoxy-",
      "AC1L34PW",
      "Anthracene,1,4-Dimethoxy-",
      "SCHEMBL1061836",
      "DTXSID6065343",
      "CTK4B6912",
      "ZINC1697899",
      "BDBM50195861",
      "SMR001570635",
      "ST50825870",
    ],
  },
  {
    primary_id: "D05GWJ",
    names: ["PMID25399762-Compound-Figure1-Sarcaboside A"],
  },
  {
    primary_id: "D05GWV",
    names: [
      "2-(4-Cyano-2-Cyclohexylphenoxy)Acetic Acid",
      "CHEMBL245700",
      "(4-Cyano-2-Cyclohexylphenoxy)Acetic Acid",
      "SCHEMBL4881986",
      "BDBM50213915",
    ],
  },
  {
    primary_id: "D05GXA",
    names: ["F-G-G-F-T-G-A-R-K-S-Aib-R-K-L-A-N-Q-COOH"],
  },
  {
    primary_id: "D05GXK",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp26-]", "CHEMBL406680"],
  },
  {
    primary_id: "D05GYF",
    names: ["PONKORANOL"],
  },
  {
    primary_id: "D05GYG",
    names: ["BQ 3020", "BQ3020", "BQ-3020"],
  },
  {
    primary_id: "D05GYL",
    names: ["UR-63325", "DP-1", "Histamine H4 Antagonist (Allergic Rhinitis, Asthma), Palau"],
  },
  {
    primary_id: "D05GYO",
    names: ["RU85493"],
  },
  {
    primary_id: "D05GYU",
    names: [
      "EP-201",
      "Chissonox 201",
      "Unox Epoxide 201",
      "Unox 201",
      "Epoxide-201",
      "EP 201",
      "141-37-7",
      "CCRIS 279",
      "NSC 61273",
      "BRN 0246264",
      "MLS002638023",
      "CHEBI:82463",
      "3,4-Epoxy-6-Methylcyclohexylmethyl-3,4-Epoxy-6-Methylcyclohexanecarboxylate",
      "Epoxide 201",
      "6-Methyl-3,4-Epoxycyclohexylmethyl 6-Methyl-3,4-Epoxycyclohexane Carboxylate",
      "3,4-Epoxy-6-Methylcyclohexylmethyl-3,4-Epoxy-6-Methylcyclohexane Carboxylate",
      "3,4-Epoxy-6-Methylcyclohexylmethyl-3',4'-Epoxy-6'-Methylcyclohexane Carboxylate",
      "3,4-Epoxy-6-Methylcyclohexylmethyl 3,4-Epoxy-6-Methylcyclohexanec",
    ],
  },
  {
    primary_id: "D05GZB",
    names: ["Dupixent"],
  },
  {
    primary_id: "D05GZD",
    names: ["Rilapladib"],
  },
  {
    primary_id: "D05GZI",
    names: [
      "N-(4-Ethylphenyl)Benzo[d]oxazol-2-Amine",
      "CHEMBL1269619",
      "SCHEMBL11966141",
      "MolPort-009-215-967",
      "BDBM50329392",
      "ZINC32686273",
      "N-(4-Ethylphenyl)Benzoxazole-2-Amine",
      "AKOS034448323",
      "MCULE-8409538696",
      "SC-56930",
      "N-(4-Ethylphenyl)-1,3-Benzoxazol-2-Amine",
      "BENZOOXAZOL-2-YL-(4-ETHYL-PHENYL)-AMINE",
      "Z55890479",
    ],
  },
  {
    primary_id: "D05HAD",
    names: [
      "DP-4088",
      "DPOC-4088",
      "Anticoagulant Thrombin Inhibitor (Oral), Merck & Co",
      "Anticoagulant Thrombin Inhibitor (Oral, Deep Vein Thrombosis), Diakron/ Orchid",
    ],
  },
  {
    primary_id: "D05HAJ",
    names: ["Follitropin Alfa/Lutropin Alfa"],
  },
  {
    primary_id: "D05HBF",
    names: [
      "6,7-Dihydroxy-2-Phenyl-Chromen-4-One",
      "6,7-Dihydroxyflavone",
      "38183-04-9",
      "6,7-Dihydroxy-2-Phenyl-4H-Chromen-4-One",
      "6,7-Dihydroxy Flavone",
      "4H-1-Benzopyran-4-One, 6,7-Dihydroxy-2-Phenyl-",
      "CHEMBL300814",
      "6,7-Dihydroxy-2-Phenylchromen-4-One",
      "SR-05000002250",
      "PubChem9843",
      "6,7 - Dihydroxyflavone",
      "AC1NS43N",
      "SPECTRUM1500718",
      "SCHEMBL980197",
      "DivK1c_000421",
      "CTK4H9455",
      "KBio1_000421",
      "HMS501F03",
      "DTXSID60191569",
      "NINDS_000421",
      "GSAOUZGPXSGVRS-UHFFFAOYSA-N",
      "ZINC3871176",
      "BDBM50077318",
      "AKOS024283483",
      "CCG-208431",
      "MCULE-6698063887",
      "IDI1_000421",
      "ACM38183049",
    ],
  },
  {
    primary_id: "D05HBM",
    names: ["Benzenamine Derivative 2"],
  },
  {
    primary_id: "D05HBS",
    names: ["Hydroxamate Analog 3"],
  },
  {
    primary_id: "D05HBW",
    names: ["PMID25980951-Compound-18"],
  },
  {
    primary_id: "D05HBZ",
    names: [
      "E 7974",
      "26510-52-1",
      "Ethyl 3-Oxo-3-(Pyridin-2-Yl)Propanoate",
      "Ethyl Picolinoylacetate",
      "Ethyl 3-Oxo-3-(2-Pyridyl)Propionate",
      "Ethyl 3-Oxo-3-Pyridin-2-Ylpropanoate",
      "Ethyl 3-Oxo-3-(2-Pyridinyl)Propanoate",
      "3-Oxo-3-(2-Pyridyl)Propionic Acid Ethyl Ester",
      "Ethyl Picolinoylacetate, 95%",
      "Ethyl 3-Oxo-3-(2-Pyridyl)Propanoate",
      "PubChem11088",
      "Ethyl-2-Pyridoyl Acetate",
      "AC1MC5MX",
      "AC1Q34ND",
      "SCHEMBL601821",
      "CHEMBL2035673",
      "CTK4F8033",
      "DTXSID30371376",
      "MolPort-001-769-152",
      "FQHXWZMJALFSJJ-UHFFFAOYSA-N",
      "ZINC153764",
      "Ethyl Picolinoylacetate, AldrichCPR",
      "STL131872",
    ],
  },
  {
    primary_id: "D05HCO",
    names: ["1954U89"],
  },
  {
    primary_id: "D05HDJ",
    names: ["PMID25656651-Compound-20a"],
  },
  {
    primary_id: "D05HDX",
    names: [
      "GW-501516",
      "317318-70-0",
      "GW501516",
      "GW 501516",
      "Endurobol",
      "GSK-516",
      "UNII-7I2HA1NU22",
      "GW1516",
      "GW 1516",
      "2-(2-Methyl-4-(((4-Methyl-2-(4-(Trifluoromethyl)Phenyl)Thiazol-5-Yl)Methyl)Thio)Phenoxy)Acetic Acid",
      "GW-516",
      "GW-1516",
      "CHEMBL38943",
      "2-(2-Methyl-4-((4-Methyl-2-(4-(Trifluoromethyl)Phenyl)Thiazol-5-Yl)Methylthio)Phenoxy)Acetic Acid",
      "2-(4-((2-(4-(Trifluoromethyl)Phenyl)-4-Methylthiazol-5-Yl)Methylthio)-2-Methylphenoxy)Acetic Acid",
      "7I2HA1NU22",
    ],
  },
  {
    primary_id: "D05HEJ",
    names: ["Beta 1-6 Glucan-Conjugated Trastuzumab", "Beta 1-6 Glucan-Conjugated Trastuzumab (Breast Cancer)"],
  },
  {
    primary_id: "D05HFC",
    names: ["8-(1,2-Dimethyl-Propoxy)-Quinolin-2-Ylamine", "CHEMBL185095", "SCHEMBL5883706"],
  },
  {
    primary_id: "D05HFD",
    names: ["7-Methyl-1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One", "CHEMBL98738", "SCHEMBL10758549"],
  },
  {
    primary_id: "D05HFN",
    names: ["99mTc-Tetrofosmin"],
  },
  {
    primary_id: "D05HFQ",
    names: ["KNI-10342"],
  },
  {
    primary_id: "D05HFU",
    names: [
      "TGX-6B4",
      "Antiglycoprotein 1b Monoclonal Antibodies, CuraGen",
      "GPIb-Blocking MAb, Thromb-X",
      "GPIb-Blocking Fab Fragment (Thrombosis), Thromb-X",
    ],
  },
  {
    primary_id: "D05HFW",
    names: [
      "CENTAUREIDIN",
      "Centaureidin",
      "Desmethoxycentaureidine",
      "17313-52-9",
      "5,7,3'-Trihydroxy-3,6,4'-Trimethoxyflavone",
      "NSC-106969",
      "UNII-548R7290J9",
      "CHEMBL77552",
      "MLS002701956",
      "CHEBI:69356",
      "548R7290J9",
      "5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)-3,6-Dimethoxy-4H-Chromen-4-One",
      "Centaureidine",
      "NSC106969",
      "NSC 106969",
      "AC1NSTGP",
      "SCHEMBL9953",
      "Cid_5315773",
      "DTXSID50169530",
      "MolPort-023-274-268",
      "ZINC3871987",
      "ZINC03871987",
      "LMPK12113000",
      "BDBM50064891",
      "Quercetagetin 3,6,4'-Trimethyl Ether",
      "AKOS027326559",
      "MCULE-7507998415",
      "SMR001565538",
      "6-Hydroxyquercetin 3,6,4''-Trimethyl",
    ],
  },
  {
    primary_id: "D05HFY",
    names: [
      "Acenocoumarol",
      "Acenocoumarin",
      "Acenocoumarolum",
      "Acenocumarol",
      "Acenocumarolo",
      "Acenocumarolum",
      "Acenokumarin",
      "Ascumar",
      "Minisintrom",
      "Neositron;Nicoumalone",
      "Nicumalon",
      "Nitrovarfarian",
      "Nitrowarfarin",
      "Sincoumar",
      "Sinkumar",
      "Sinthrom",
      "Sinthrome",
      "Sintrom",
      "Sintroma",
      "Syncoumar",
      "Syncumar",
      "Synthrom",
      "Syntrom",
      "Zotil",
      "Acenocoumarol Alliance Brand",
      "Acenocoumarol Novartis Brand",
      "Acenocoumarol [INN]",
      "Acenocumarolo [DCIT]",
      "Acenokumarin [Czech]",
      "Alliance Brand Of Acenocoumarol",
      "Ciba Geigy Brand Of Acenocoumarol",
      "Mini Sintrom",
      "Novartis Brand Of Acenocoumarol",
      "G 23350",
      "Acenocoumarol (INN)",
      "Acenocoumarol Ciba-Geigy Brand",
      "Acenocoumarolum [INN-Latin]",
      "Ciba-Geigy Brand Of Acenocoumarol",
      "G-23350",
      "Mini-Sintrom",
      "Sinthrome (TN)",
      "Sintrom (TN)",
      "AB-014/25000129",
      "G-23,350",
      "Mini-Sintrom (TN)",
      "Nitrophenylacetylethyl-4-Hydroxycoumarine",
      "2-Hydroxy-3-[1-(4-Nitrophenyl)-3-Oxobutyl]-4h-Chromen-4-One",
      "2-Hydroxy-3-[1-(4-Nitrophenyl)-3-Oxobutyl]chromen-4-One",
      "3-(Alpha-(4'-Nitrophenyl)-Beta-Acetylethyl)-4-Hydroxycoumarin",
      "3-(Alpha-(P-Nitrophenol)-Beta-Acetylethyl)-4-Hydroxycoumarin",
      "3-(Alpha-Acetonyl-4-Nitrobenzyl)-4-Hydroxycoumarin",
      "3-(Alpha-Acetonyl-P-Nitrobenzyl)-4-Hydroxycoumarin",
      "3-(Alpha-Acetonyl-Para-Nitrobenzyl)-4-Hydroxy-Coumarin",
      "3-(Alpha-P-Nitrophenyl-Beta-Acetylethyl)-4-Hydroxycoumarin",
      "4-Hydroxy-3-(1-(4-Nitrophenyl)-3-Oxobutyl)-2H-1-Benzopyran-2-One;4-Hydroxy-3-[1-(4-Nitrophenyl)-3-Oxobutyl]-2H-Chromen-2-One",
    ],
  },
  {
    primary_id: "D05HGL",
    names: ["GW-695634"],
  },
  {
    primary_id: "D05HGU",
    names: ["IQM-95333"],
  },
  {
    primary_id: "D05HHG",
    names: ["PMID27977313-Compound-32"],
  },
  {
    primary_id: "D05HHP",
    names: ["Astenose"],
  },
  {
    primary_id: "D05HHZ",
    names: ["NX-002"],
  },
  {
    primary_id: "D05HID",
    names: ["RAAV1.tMCK.human-Alpha-Sarcoglycan", "Alpha-Sarcoglycan Adenoviral Gene Therapy"],
  },
  {
    primary_id: "D05HIG",
    names: ["6-Phenylhexylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL486493"],
  },
  {
    primary_id: "D05HIH",
    names: ["Linear Peptide Of RES-701-1"],
  },
  {
    primary_id: "D05HIJ",
    names: ["SAR260301"],
  },
  {
    primary_id: "D05HIP",
    names: ["Comvac4-HB"],
  },
  {
    primary_id: "D05HIX",
    names: ["MK-6406"],
  },
  {
    primary_id: "D05HJF",
    names: ["Boronic Acid Derivative 5"],
  },
  {
    primary_id: "D05HJG",
    names: [
      "2,3-Dihydro-1H-Benzo[de]isoquinolin-1-One",
      "CHEMBL594596",
      "2,3-Dihydro-Benzo[de]isoquinolin-1-One",
      "SCHEMBL832168",
      "ZINC24216",
      "BDBM50306285",
      "FCH1866210",
    ],
  },
  {
    primary_id: "D05HJO",
    names: ["PD-2024"],
  },
  {
    primary_id: "D05HJP",
    names: [
      "4-Inden-(1E)-Ylidenemethyl-Phenylamine",
      "4-(1H-Inden-1-Ylidenemethyl)Aniline",
      "NSC83092",
      "4-[(E)-Inden-1-Ylidenemethyl]aniline",
      "CHEMBL193376",
      "487-61-6",
      "1-(4-Aminobenzylidene)Indene",
      "P-Toluidine, .alpha.-Inden-1-Ylidene-",
      "4-(1H-Inden-1-Ylidenemethyl)Phenylamine",
      "Benzenamine, 4-(1H-Inden-1-Ylidenemethyl)-",
      "MLS002694146",
      "WLN: L56 BYJ BU1R DZ",
      "AC1O53IW",
      "AC1Q1HQ2",
      "Cid_98148",
      "BDBM50164749",
      "NSC-83092",
      "AKOS030547084",
      "ZINC100425076",
      "NCGC00186285-13",
      "NCGC00186285-07",
      "NCGC00186285-06",
      "NCGC00186285-01",
      "NCGC00186285-11",
      "NCGC00186285-09",
    ],
  },
  {
    primary_id: "D05HJR",
    names: ["Oncoprex"],
  },
  {
    primary_id: "D05HJT",
    names: [
      "Ethyl 4-(2-Oxo-2H-Chromene-3-Carboxamido)Benzoate",
      "111947-24-1",
      "Ethyl 4-{[(2-Oxo-2H-Chromen-3-Yl)Carbonyl]amino}benzoate",
      "AC1LHTSN",
      "CBMicro_028869",
      "3-Carboxamido Coumarin, 15",
      "Oprea1_481544",
      "CHEMBL470419",
      "BDBM29165",
      "MolPort-000-375-263",
      "MISJYZJMFQOBPG-UHFFFAOYSA-N",
      "ZINC434162",
      "STK401564",
      "AKOS001279407",
      "MCULE-7936571021",
      "LS-38062",
      "BIM-0028988.P001",
      "ST4083371",
      "Ethyl 4-(2-Oxo-2H-Chromene-3-Amido)Benzoate",
      "Ethyl 4-[(2-Oxochromen-3-Yl)Carbonylamino]benzoate",
      "Ethyl 4-[(2-Oxochromene-3-Carbonyl)Amino]benzoate",
      "Z30248586",
      "F1006-0090",
    ],
  },
  {
    primary_id: "D05HKB",
    names: ["RS 86"],
  },
  {
    primary_id: "D05HKC",
    names: ["PMID26651364-Compound-123"],
  },
  {
    primary_id: "D05HLC",
    names: ["NVC-727"],
  },
  {
    primary_id: "D05HLQ",
    names: ["Quinazoline Derivative 1"],
  },
  {
    primary_id: "D05HMW",
    names: ["2-(4-Dipropylamino-Cyclohexylidene)-Malononitrile", "CHEMBL164950", "ZINC13805533"],
  },
  {
    primary_id: "D05HNB",
    names: [
      "LY-282210",
      "CHEMBL88337",
      "BDBM50029482",
      "L009647",
      "6-[3-(4-Acetyl-2-Ethyl-5-Hydroxy-Phenoxy)-Propoxy]-5-(2-Carboxy-Ethyl)-9-Oxo-9H-Xanthene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05HOH",
    names: ["Tyr-Sar-Phe-D-2-Nal-NH2", "CHEMBL254926"],
  },
  {
    primary_id: "D05HOO",
    names: [
      "2-Bromophenol",
      "2-BROMOPHENOL",
      "95-56-7",
      "O-Bromophenol",
      "Phenol, 2-Bromo-",
      "BROMOPHENOL",
      "2-Bromfenol",
      "2-Bromo-Phenol",
      "Phenol, O-Bromo-",
      "O-Bromo-Phenol",
      "2-Bromo Phenol",
      "Phenol, Bromo-",
      "2-Bromfenol [Czech]",
      "NSC 6970",
      "Bromophenol, 2-",
      "UNII-A0UB206YF0",
      "2-Bromophenol, 98%",
      "EINECS 202-432-7",
      "1-Bromo-2-Hydroxybenzene",
      "BRN 1905115",
      "CHEMBL186007",
      "A0UB206YF0",
      "2-BROMO-1-HYDROXYBENZENE",
      "VADKRMSMGWJZCF-UHFFFAOYSA-N",
      "MFCD00002146",
      "32762-51-9",
      "Ortho-Bromophenol",
      "2BR",
      "Bromphenol",
      "Bromo-Phenol",
      "Bromo Phenol",
      "2-Bromanylphenol",
      "HSDB 7648",
      "Ortho-Bromo-Phenol",
      "EINECS 251-200-1",
    ],
  },
  {
    primary_id: "D05HOS",
    names: ["Hydroxyaminovaline"],
  },
  {
    primary_id: "D05HPB",
    names: [
      "2-(2-Cyclohexyl-4-Methoxyphenoxy)Acetic Acid",
      "CHEMBL245701",
      "(2-Cyclohexyl-4-Methoxyphenoxy)Acetic Acid",
      "SCHEMBL4885675",
      "BDBM50213921",
    ],
  },
  {
    primary_id: "D05HPI",
    names: [
      "Daptomycin",
      "Cidecin",
      "Cubicin",
      "Dapcin",
      "Daptomicina",
      "Daptomycine",
      "Daptomycinum",
      "Deptomycin",
      "Daptomicina [Spanish]",
      "Daptomycine [French]",
      "Daptomycinum [Latin]",
      "LY146032",
      "Cubicin (TN)",
      "LY-146032",
      "MK-3009",
      "Daptomycin [USAN:INN:BAN]",
      "Daptomycin (JAN/USAN/INN)",
      "N-Decanoyl-L-Tryptophyl-L-Asparaginyl-L-Aspartyl-L-Threonylglycyl-L-Ornithyl-L-Aspartyl-D-Alanyl-L-Aspartylglycyl-D-Seryl-Threo-3-Methyl-L-Glutamyl-3-Anthraniloyl-L-Alanine Epsilon1-Lactone",
      "N-Decanoyl-L-Tryptophyl-D-Asparaginyl-L-Aspartyl-L-Threonylglycyl-L-Ornithyl-L-Aspartyl-D-Alanyl-L-Aspartylglycyl-D-Seryl-Threo-3-Methyl-L-Glutamyl-3-Anthraniloyl-L-Alanine Epsilon(1)-Lactone",
      "N-Decanoyl-L-Tryptophyl-D-Asparaginyl-L-Aspartyl-L-Threonylglycyl-L-Ornithyl-L-Aspartyl-D-Alanyl-L-Aspartylglycyl-D-Seryl-Threo-3-Methyl-L-Glutamyl-3-Anthraniloyl-L-Alanine 1.13-3.4-Lactone",
      "N-Decanoyl-L-Tryptophyl-L-Asparaginyl-N-[(3S,6S,9R,15S,18R,21S,24S,30S,31R)-3-[2-(2-Aminophenyl)-2-Oxoethyl]-24-(3-Aminopropyl)-15,21-Bis(Carboxymethyl)-6-[(2R)-1-Carboxypropan-2-Yl]-9-(Hydroxymethyl)-18,31-Dimethyl-2,5,8,11,14,17,20,23,26,29-Decaoxo-1-Ox",
    ],
  },
  {
    primary_id: "D05HQO",
    names: ["TIBO"],
  },
  {
    primary_id: "D05HRK",
    names: [
      "2-Hexadecynoic Acid",
      "Hexadec-2-Ynoic Acid",
      "2834/3/9",
      "2-Hexadecynoate",
      "N-2-Hexadecynoic Acid",
      "AC1L45K9",
      "SCHEMBL2818253",
      "CTK4G1266",
      "DTXSID00182577",
      "MECFGCCEVOFCNS-UHFFFAOYSA-N",
      "NSC289580",
      "LMFA01030494",
    ],
  },
  {
    primary_id: "D05HRP",
    names: [
      "3-(4-Amino-Phenyl)-3-Methyl-Pyrrolidine-2,5-Dione",
      "CHEMBL149238",
      "2,5-Pyrrolidinedione, 3-(4-Aminophenyl)-3-Methyl-",
      "SCHEMBL19074638",
      "BDBM50025155",
    ],
  },
  {
    primary_id: "D05HRW",
    names: ["SKL-NP2"],
  },
  {
    primary_id: "D05HSC",
    names: [
      "Tubocurarine",
      "Amelizol",
      "Delacurarine",
      "Jexin",
      "Tubadil",
      "Tubaine",
      "Tubarine",
      "Tubocurarin",
      "Tubocurarinum",
      "Chlorure De Tubocurarine",
      "Cloruro De Tubocurarina",
      "Dextrotubocurarine Chloride",
      "Intocostrine T",
      "Isoquinoline Alkaloid",
      "Tubocurarina Cloruro",
      "Tubocurarina Cloruro [DCIT]",
      "Tubocurarine Chloride",
      "Tubocurarine Hydrochloride",
      "Tubocurarini Chloridum",
      "Chlorure De Tubocurarine [INN-French]",
      "Cloruro De Tubocurarina [INN-Spanish]",
      "Curarin-Haf",
      "D-Paracurarine Chloride",
      "D-Tubocurarine",
      "D-Tubocurarine Chloride",
      "D-Tubocurarine Dichloride",
      "D-Tubocurarine Hydrochloride",
      "Delacurarine (TN)",
      "Jex (TN)",
      "Metubine (TN)",
      "Tubaine (TN)",
      "Tubarine (TN)",
      "Tubocurarine Chloride (INN)",
      "Tubocurarine Chloride (TN)",
      "Tubocurarine Chloride (Anhydrous)",
      "Tubocurarini Chloridum [INN-Latin]",
      "Tubocurarinum (TN)",
      "D-(+)-Tubocurarine Chloride",
      "Tubocurarine, Chloride, Hydrochloride, (+)-(8CI)",
      "D-7',12'-Dihydroxy-6,6'-Dimethoxy-2,2',2'-Trimethyltubocuraranium Chloride",
      "Tubocuraranium, 7',12'-Dihydroxy-6,6'-Dimethoxy-2,2',2'-Trimethyl-, Chloride, Hydrochloride",
      "(+)-Tubocurarine",
      "(+)-Tubocurarine Chloride",
      "(+)-Tubocurarine Chloride Hydrochloride",
      "2,2',2'-Trimethyl-6,6'-Bis(Methyloxy)Tubocuraran-2,2'-Diium-7',12'-Diol Dichloride",
      "7',12'-Dihydroxy-6,6'-Dimethoxy-2,2',2'-Trimethyltubocuraranium",
      "7',12'-Dihydroxy-6,6'-Dimethoxy-2,2',2'-Trimethyltubocuraran-2'-Ium",
    ],
  },
  {
    primary_id: "D05HSI",
    names: ["T-0632"],
  },
  {
    primary_id: "D05HSW",
    names: ["EMBELIN"],
  },
  {
    primary_id: "D05HTS",
    names: ["PLX8394"],
  },
  {
    primary_id: "D05HUG",
    names: ["OMS-403"],
  },
  {
    primary_id: "D05HUP",
    names: ["ISIS 113908"],
  },
  {
    primary_id: "D05HUQ",
    names: ["BIOVANCE"],
  },
  {
    primary_id: "D05HUS",
    names: ["7-(3-Fluorophenyl)-2-Methylquinoline", "CHEMBL231764"],
  },
  {
    primary_id: "D05HUX",
    names: ["ICRF-154"],
  },
  {
    primary_id: "D05HUZ",
    names: ["TTI-314", "CD32a-Specific Monoclonal Antibody (Autoimmune Diseases), Trillium Therapeutics"],
  },
  {
    primary_id: "D05HVV",
    names: [
      "(R)-N2-{4-[(3-Fluorobenzyl)Oxy]benzyl}alaninamide",
      "CHEMBL82327",
      "174756-44-6",
      "(R)-2-((4-((3-Fluorobenzyl)Oxy)Benzyl)Amino)Propanamide",
      "AC1NUPG0",
      "SCHEMBL194169",
      "CHEBI:94774",
      "BDBM50080950",
      "ZINC26506755",
      "AKOS030631890",
      "AN-31998",
      "BRD-K84639753-066-01-9",
      "(R)-2-(4-(3-Fluorobenzyloxy)Benzylamino)Propanamide",
    ],
  },
  {
    primary_id: "D05HVY",
    names: ["GSK-644784", "AC1NQWGT"],
  },
  {
    primary_id: "D05HWC",
    names: ["PUKATEINE"],
  },
  {
    primary_id: "D05HWN",
    names: [
      "BIIB-021",
      "CNF-2024",
      "CNF-3647",
      "EC-129",
      "EC-137",
      "EC-138",
      "EC-141",
      "EC-144",
      "EC-146",
      "EC-147",
      "EC-151",
      "EC-78",
      "EC-89",
      "EC-145, Conforma",
      "EC-82, Conforma",
      "Hsp90 Inhibitor (Cancer), Biogen Idec",
      "Synthetic Hsp90 Inhibitors (Cancer), Conforma",
      "Hsp90 Inhibitors (Synthetic, Cancer), Conforma",
    ],
  },
  {
    primary_id: "D05HWX",
    names: ["99mTc-Depreotide"],
  },
  {
    primary_id: "D05HXE",
    names: ["Oleoyl-CoA"],
  },
  {
    primary_id: "D05HXX",
    names: [
      "Tranexamic Acid",
      "AMCA",
      "AMCHA",
      "AMH",
      "Amikapron",
      "Amstat",
      "Anvitoff",
      "Carxamin",
      "Cyclocapron",
      "Cyklokapron",
      "Emorhalt",
      "Exacyl",
      "Frenolyse",
      "Hexapromin",
      "Hexatron",
      "Mastop",
      "Rikavarin",
      "Spiramin",
      "Tamcha",
      "Tranex",
      "Tranexamsaeure",
      "Tranexan",
      "Transamin",
      "Transamlon",
      "Trasamlon",
      "Ugurol",
      "Acide Tranexamique",
      "Acido Tranexamico",
      "Acidum Tranexamicum",
      "Tranexmic Acid",
      "Tranhexamic Acid",
      "Trans AMCHA",
      "CL 65336",
      "DV 79",
      "DV79",
      "KABI 2161",
      "LT00159441",
      "ALBB-006013",
      "Acide Tranexamique [INN-French]",
      "Acido Tranexamico [INN-Spanish]",
      "Acidum Tranexamicum [INN-Latin]",
      "CL-65336",
      "Cis-AMCHA",
      "Cyklokapron (TN)",
      "DV-79",
      "RP 18,429",
      "Rikavarin (TN)",
      "Rikavarin-S",
      "T-AMCHA",
      "Tranexamic Acid Cis-Form",
      "Trans-Amcha",
      "Trans-Tranexamic Acid",
      "Cis-4-(Aminomethyl)Cyclohexanecarboxylic Acid",
      "Tranexamic Acid (JP15/USAN/INN)",
      "Tranexamic Acid [USAN:INN:BAN:JAN]",
      "Trans-4-(Aminomethyl)Cyclohexanecarboxylic Acid",
      "Trans-4-(Aminomethyl)Cyclohexanecarboxylic Acid Ester",
      "Trans-P-(Aminomethyl)Cyclohexanecarboxylic",
      "Trans-P-(Aminomethyl)Cyclohexanecarboxylic Acid",
      "Cis-4-Aminomethylcyclohexane-1-Carboxylic Acid",
      "Trans-1-Aminomethylcyclohexane-4-Carboxylic Acid",
      "Trans-4-(Aminomethyl)Cyclohexane-Carboxylic Acid",
      "Trans-4-Aminomethylcyclohexane-1-Carboxylic Acid",
      "Trans-1-(Aminomethyl)Cyclohexane-4-Carboxylic Acid",
      "Trans-4-(Aminomethyl)-1-Cyclohexanecarboxylic Acid",
      "Trans-4-(Aminomethyl)Cyclohexane-1-Carboxylic Acid",
      "4-(Aminomethyl)-Cyclohexanecarboxylic Acid",
      "4-(Aminomethyl)Cyclohexanecarboxylic Acid",
      "4-(Aminomethyl)Cyclohexane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05HYB",
    names: ["Calcium-Dependent Lipopeptide Antibiotics"],
  },
  {
    primary_id: "D05HYC",
    names: ["F-50077", "BBG2Na", "RSV Vaccine, Pierre Fabre", "Vaccine (RSV), Pierre Fabre"],
  },
  {
    primary_id: "D05HYJ",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 7"],
  },
  {
    primary_id: "D05HYL",
    names: [
      "YM-022",
      "145084-28-2",
      "YM022",
      "YM 022",
      "UNII-772CP7W12N",
      "CHEMBL115121",
      "C32H28N4O3",
      "772CP7W12N",
      "1-(2,3-Dihydro-1-(2'-Methylphenacyl)-2-Oxo-5-Phenyl-1H-1,4-Benzodiazepin-3-Yl)-3-(3-Methylphenyl)Urea",
      "(R)-N-[2,3-DIHYDRO-1-[2-(2-METHYLPHENYL)-2-OXOETHYL]-2-OXO-5-PHENYL-1H-1,4-BENZODIAZEPIN-3-YL]-N'-(3-METHYLPHENYL)-UREA",
      "Urea,N-[(3R)-2,3-Dihydro-1-[2-(2-Methylphenyl)-2-Oxoethyl]-2-Oxo-5-Phenyl-1H-1,4-Benzodiazepin-3-Yl]-N'-(3-Methylphenyl)-",
      "Urea, N-(2,3-Dihydro-1-(2-(2-Methylphenyl)-2-Oxoethyl)-2-Oxo-5-Phenyl-1H-1,4-Benzodia",
    ],
  },
  {
    primary_id: "D05HYT",
    names: ["IPAZILIDE FUMARATE"],
  },
  {
    primary_id: "D05HYV",
    names: ["SR48527"],
  },
  {
    primary_id: "D05HZI",
    names: ["MDL 101,731"],
  },
  {
    primary_id: "D05HZJ",
    names: ["GPC-3298306"],
  },
  {
    primary_id: "D05IAN",
    names: ["G3139 + Irinotecan"],
  },
  {
    primary_id: "D05IBA",
    names: ["Shanvac-B"],
  },
  {
    primary_id: "D05IBP",
    names: ["DFD-09"],
  },
  {
    primary_id: "D05IBQ",
    names: ["(D)Arg-Arg-Pro-Hyp-Gly-Thi-Ser-(D)Tic-Tic-Arg"],
  },
  {
    primary_id: "D05IBV",
    names: ["AR-12432"],
  },
  {
    primary_id: "D05IBW",
    names: ["2,7-Bis(3-Chloropropionamido)Anthraquinone", "SCHEMBL4294857", "CHEMBL494457"],
  },
  {
    primary_id: "D05ICC",
    names: ["2-Fluorophenyl 4-Butoxybenzylcarbamate", "CHEMBL596876", "SCHEMBL5223902"],
  },
  {
    primary_id: "D05ICE",
    names: ["SDMA"],
  },
  {
    primary_id: "D05ICF",
    names: [
      "1,3-Di-P-Tolyl-[1,3]diazetidine-2,4-Dione",
      "CHEMBL296771",
      "1,3-Bis(4-Methylphenyl)-1,3-Diazetidine-2,4-Dione",
      "AC1MRG54",
      "SCHEMBL19214040",
    ],
  },
  {
    primary_id: "D05ICS",
    names: ["ProSavin", "Dopamine Synthesis Pathway Gene Therapy (Parkinson's Disease), Oxford BioMedica"],
  },
  {
    primary_id: "D05ICT",
    names: ["ABBV-744"],
  },
  {
    primary_id: "D05IEN",
    names: ["Epstein Barr-Based Gene Therapy"],
  },
  {
    primary_id: "D05IGJ",
    names: ["2-(4-Phenoxy-Benzyl)-3H-Benzoimidazol-5-Ol", "CHEMBL63200", "SCHEMBL7453649"],
  },
  {
    primary_id: "D05IGL",
    names: ["3,4-Dichlorobenzamil", "3',4'-Dichlorobenzamil", "GNF-Pf-1778"],
  },
  {
    primary_id: "D05IHR",
    names: ["9-Phenyl-2,3-Dihydro-1H-Indeno[2,1-C]pyridine", "CHEMBL279297"],
  },
  {
    primary_id: "D05IHU",
    names: ["Tetrahydrozoline"],
  },
  {
    primary_id: "D05IHZ",
    names: ["VP-20621"],
  },
  {
    primary_id: "D05IIB",
    names: ["NoroVAXX"],
  },
  {
    primary_id: "D05IIN",
    names: ["SSR125543"],
  },
  {
    primary_id: "D05IIP",
    names: ["L-651582", "Carboxyamidotriazole (CAI)"],
  },
  {
    primary_id: "D05IJM",
    names: ["Recruitin"],
  },
  {
    primary_id: "D05IKY",
    names: ["14-(N-Hexylamino)-14-Oxotetradec-8(Z)-Enoic Acid", "CHEMBL561706"],
  },
  {
    primary_id: "D05ILN",
    names: ["Agalsidase Alfa"],
  },
  {
    primary_id: "D05ILO",
    names: ["(R)-N2-{4-[(3-Chlorobenzyl)Oxy]benzyl}serinamide", "CHEMBL244771", "SCHEMBL2017087"],
  },
  {
    primary_id: "D05ILZ",
    names: ["NN8209"],
  },
  {
    primary_id: "D05IML",
    names: ["NIGULDIPINE"],
  },
  {
    primary_id: "D05INV",
    names: ["PEGPH20"],
  },
  {
    primary_id: "D05IPF",
    names: ["Kombiglyze XR/Komboglyze FDC"],
  },
  {
    primary_id: "D05IPO",
    names: ["BTS-79018", "CHEMBL418854", "SCHEMBL6767394", "BDBM50079939", "L018296"],
  },
  {
    primary_id: "D05IQI",
    names: ["LX9211"],
  },
  {
    primary_id: "D05IQL",
    names: [
      "Methyl 2-(Benzylamino)-1H-Indole-3-Carboxylate",
      "CHEMBL483067",
      "BDBM31109",
      "2-Substituted Indole-3-Carboxylate, 27f",
    ],
  },
  {
    primary_id: "D05IQX",
    names: ["Belimumab", "Belimumab (USAN)", "LymphoStat-B", "LymphoStat-B (TN)"],
  },
  {
    primary_id: "D05IRT",
    names: ["P276-00", "CHEMBL2312181", "SCHEMBL1180418"],
  },
  {
    primary_id: "D05IRV",
    names: [
      "Elesclomol",
      "488832-69-5",
      "Elesclomol (STA-4783)",
      "STA-4783",
      "UNII-6UK191M53P",
      "CHEBI:79369",
      "6UK191M53P",
      "Propanedioic Acid, Bis[2-Methyl-2-(Phenylthioxomethyl)Hydrazide]",
      "1,3-Bis[2-Methyl-2-(Phenylthioxomethyl)Hydrazide]propanedioic Acid",
      "N'1,N'3-Dimethyl-N'1,N'3-Di(Phenylcarbonothioyl)Malonohydrazide",
      "J-503879",
      "C19H20N4O2S2",
      "1-N'-Benzenecarbothioyl-3-(2-Benzenecarbothioyl-2-Methylhydrazinyl)-N'-Methyl-Oxopropanehydrazidide",
      "Elesclomol [USAN:INN]",
      "Elesclomolum",
    ],
  },
  {
    primary_id: "D05ISO",
    names: ["Bemarituzumab"],
  },
  {
    primary_id: "D05ISV",
    names: ["Live Attenuated Human Parainfluenza Virus Type 2 Vaccine"],
  },
  {
    primary_id: "D05ITH",
    names: [
      "Duocarmycin",
      "AC1L1FCB",
      "CTK7A0066",
      "Methyl 4-Hydroxy-2,8-Dimethyl-1-Oxo-6-[(5,6,7-Trimethoxy-1H-Indol-2-Yl)Carbonyl]-1,2,3,6,7,8-Hexahydropyrrolo[3,2-E]indole-2-Carboxylate",
      "Methyl 4-Hydroxy-2,8-Dimethyl-1-Oxo-6-(5,6,7-Trimethoxy-1H-Indole-2-Carbonyl)-7,8-Dihydro-3H-Pyrrolo[3,2-E]indole-2-Carboxylate",
    ],
  },
  {
    primary_id: "D05ITS",
    names: ["ALN-VEG01"],
  },
  {
    primary_id: "D05IUQ",
    names: [
      "Anti-PECAM-1 Mab",
      "Anti-CD31 MAb (Cancer), Genomic Systems",
      "Anti-PECAM-1 MAb (Cancer)",
      "Anti-PECAM-1 MAb (Cancer), Genomic Systems",
    ],
  },
  {
    primary_id: "D05IVD",
    names: ["PREUSSOMERIN", "CHEMBL318853", "BDBM50059849"],
  },
  {
    primary_id: "D05IVZ",
    names: ["RU-33965"],
  },
  {
    primary_id: "D05IWE",
    names: ["Alloferon"],
  },
  {
    primary_id: "D05IWY",
    names: ["Gamma-Mangostin"],
  },
  {
    primary_id: "D05IXH",
    names: ["KUR-CXCR4"],
  },
  {
    primary_id: "D05IXU",
    names: ["3-(3-Benzylnaphthalen-2-Yl)Pyridine", "CHEMBL493485"],
  },
  {
    primary_id: "D05IYA",
    names: ["MEDI3902"],
  },
  {
    primary_id: "D05IZC",
    names: ["RGI-1001", "Cedar Pollen Allergy (ReVax), REGiMMUNE"],
  },
  {
    primary_id: "D05IZG",
    names: ["BAY-Z-4305"],
  },
  {
    primary_id: "D05IZN",
    names: ["Azole Derivative 4"],
  },
  {
    primary_id: "D05JAA",
    names: ["ARRY-382"],
  },
  {
    primary_id: "D05JAH",
    names: [
      "4-Carbazol-9-Yl-Butyric Acid",
      "CARBAZOLE BUTANOIC ACID",
      "4-(9H-CARBAZOL-9-YL)BUTANOIC ACID",
      "CHEMBL185595",
      "CRZ",
      "90053-09-1",
      "1tow",
      "AC1L9MBO",
      "4-Carbazol-9-Ylbutanoic Acid",
      "9H-Carbazole-9-Butanoic Acid",
      "SCHEMBL4314440",
      "4-(N-Carbazolyl) Butyric Acid",
      "CTK3I5050",
      "DTXSID50332287",
      "BDBM50152851",
      "AKOS000153937",
    ],
  },
  {
    primary_id: "D05JAJ",
    names: ["4-(Quinolinoxy)-3-Pyridinesulfonamide", "CHEMBL1165726", "BDBM50320501", "NSC743333", "NSC-743333"],
  },
  {
    primary_id: "D05JAS",
    names: ["Biphenyl Mannoside Derivative 5"],
  },
  {
    primary_id: "D05JAZ",
    names: [
      "2-Amino-2-(3-Chloro-4-Fluorophenyl)Acetic Acid",
      "261762-99-6",
      "3-Chloro-4-Fluoro-DL-Phenylglycine",
      "C8H7ClFNO2",
      "CHEMBL203905",
      "Amino(3-Chloro-4-Fluorophenyl)Acetic Acid",
      "AC1MCND8",
      "SCHEMBL8196588",
      "KS-00000ZXX",
      "CTK8F0591",
      "DTXSID40378674",
      "MolPort-000-153-073",
      "JRD-1093",
      "SBB093187",
      "BDBM50179692",
      "8653AD",
      "2-(3-Chloro-4-Fluorophenyl)Glycine",
      "MFCD01631397",
      "AKOS032962888",
      "AKOS015907725",
      "MCULE-4108995551",
      "RTR-011871",
      "PS-6305",
      "SY044823",
      "TR-011871",
      "ST51041478",
      "FT-0676194",
      "MFCD01631397 (95%)",
      "C-5924",
    ],
  },
  {
    primary_id: "D05JBP",
    names: [
      "IPI-194",
      "Bcl-2 Protein Inhibitors (Cancer)",
      "Bcl-2 Gene Inhibitor (Cancer), Infinity",
      "Bcl-2 Protein Inhibitor (Cancer), Infinity;Bcl-2 Protein Inhibitors (Cancer), Infinity/Novartis",
    ],
  },
  {
    primary_id: "D05JBZ",
    names: ["[HYDROXY(3-PHENYLPROPYL)AMINO]METHANOL", "SB-485345", "2ai7", "2ai8", "AC1NRD35", "DB08523"],
  },
  {
    primary_id: "D05JCG",
    names: ["CBF-BS2"],
  },
  {
    primary_id: "D05JDR",
    names: [
      "Ceftibuten",
      "CETB",
      "Cedax",
      "Ceftem",
      "Ceftibutene",
      "Ceftibuteno",
      "Ceftibutenum",
      "Cephem",
      "Ceprifran",
      "Isocef",
      "Keimax",
      "Antibiotic 7432S",
      "S 7432",
      "Sch 39720",
      "Cedax (TN)",
      "Ceftibutene [INN-French]",
      "Ceftibuteno [INN-Spanish]",
      "Ceftibutenum [INN-Latin]",
      "Cephalosporin 7432-S",
      "Cis-Ceftibutin",
      "Cis-Ceftibuten",
      "Sch-39720",
      "Trans-Ceftibuten",
      "Ceftibuten(USAN/INN)",
      "Ceftibuten [USAN:INN:BAN]",
      "(+)-(6R,7R)-7-((Z)-2-(2-Amino-4-Thiazolyl)-4-Carboxycrotonamido)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(E)-2-(2-Amino-1,3-Thiazol-4-Yl)-5-Hydroxy-5-Oxopent-2-Enoyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-5-Hydroxy-5-Oxopent-2-Enoyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[2-(2-Amino-1,3-Thiazol-4-Yl)-5-Hydroxy-5-Oxopent-2-Enoyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-4-Carboxybut-2-Enoyl]amino}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-[[(E)-2-(2-Amino-1,3-Thiazol-4-Yl)-5-Hydroxy-5-Oxopent-2-Enoyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-[[(Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-5-Hydroxy-5-Oxopent-2-Enoyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-[[2-(2-Amino-1,3-Thiazol-4-Yl)-5-Hydroxy-5-Oxopent-2-Enoyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7432-S",
      "7beta-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-4-Carboxybut-2-Enoyl]amino}-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05JEG",
    names: ["BMN-701"],
  },
  {
    primary_id: "D05JEL",
    names: ["Onzeald"],
  },
  {
    primary_id: "D05JET",
    names: ["Remacemide"],
  },
  {
    primary_id: "D05JFA",
    names: ["INTERCEPT"],
  },
  {
    primary_id: "D05JFI",
    names: ["AKT Protein Kinase Inhibitors", "AKTi", "AKT Protein Kinase Inhibitors, Merck & Co"],
  },
  {
    primary_id: "D05JFY",
    names: ["Hydralazine Hydrochloride/ Isosorbide Dinitrate", "Bidil XR (TN)"],
  },
  {
    primary_id: "D05JGR",
    names: ["TBDT"],
  },
  {
    primary_id: "D05JGX",
    names: ["4-[4-(Benzylamino)Benzoyl]benzoic Acid"],
  },
  {
    primary_id: "D05JHA",
    names: ["H-Tyr-C[cys-Gly-Phe(P-NO2)-Cys]NH2", "CHEMBL396356"],
  },
  {
    primary_id: "D05JHJ",
    names: ["LB-10522"],
  },
  {
    primary_id: "D05JIO",
    names: ["(2S)-1-(6-Fluoro-1H-Indazol-1-Yl)Propan-2-Amine", "CHEMBL256623"],
  },
  {
    primary_id: "D05JIS",
    names: ["L-365,209"],
  },
  {
    primary_id: "D05JJC",
    names: ["PF-14"],
  },
  {
    primary_id: "D05JJL",
    names: ["Furin Inhibitor Peptide", "CHEMBL502642", "GTPL8668"],
  },
  {
    primary_id: "D05JJO",
    names: ["MOB-015"],
  },
  {
    primary_id: "D05JKC",
    names: ["6-Chloro-2-(4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D05JKG",
    names: ["Talinolol"],
  },
  {
    primary_id: "D05JKQ",
    names: ["L-796,778"],
  },
  {
    primary_id: "D05JLG",
    names: ["ACS-6"],
  },
  {
    primary_id: "D05JMJ",
    names: ["BN-81,674", "BN 81,674", "BN81,674", "BN-81674", "BN81674", "BN 81674"],
  },
  {
    primary_id: "D05JMP",
    names: ["IDR-803"],
  },
  {
    primary_id: "D05JMW",
    names: ["RGA-1512", "DC-Cholesterol (Leukemia)"],
  },
  {
    primary_id: "D05JNI",
    names: ["Framycetin"],
  },
  {
    primary_id: "D05JPG",
    names: ["[2-(3-Benzyl-3H-Inden-1-Yl)-Ethyl]-Methyl-Amine", "CHEMBL194174"],
  },
  {
    primary_id: "D05JPJ",
    names: ["ELND-004"],
  },
  {
    primary_id: "D05JPV",
    names: ["4-(4-(Dec-1-Ynyl)Phenyl)-4-Oxobutanoic Acid", "CHEMBL373275", "BDBM50180608"],
  },
  {
    primary_id: "D05JPW",
    names: ["2-(4-Hydroxy-Phenyl)-7-Methoxy-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D05JQD",
    names: ["M-6040", "Analgesic (Pain), Maruho"],
  },
  {
    primary_id: "D05JQF",
    names: ["Tipredane"],
  },
  {
    primary_id: "D05JRA",
    names: ["ARC-1028", "CHEMBL525385", "BDBM27227", "ARC-1028, 22"],
  },
  {
    primary_id: "D05JRG",
    names: ["Antithrombin Alfa", "Atryn (TN)"],
  },
  {
    primary_id: "D05JRZ",
    names: ["NSC-27236"],
  },
  {
    primary_id: "D05JSU",
    names: ["Edivoxetine"],
  },
  {
    primary_id: "D05JSY",
    names: ["MCL-145"],
  },
  {
    primary_id: "D05JTJ",
    names: ["IC-50"],
  },
  {
    primary_id: "D05JXE",
    names: ["Phytanic Acid"],
  },
  {
    primary_id: "D05JXF",
    names: ["YD-501"],
  },
  {
    primary_id: "D05JXM",
    names: ["PF-3932295"],
  },
  {
    primary_id: "D05JXR",
    names: ["SC-52012"],
  },
  {
    primary_id: "D05JXT",
    names: ["Antifungal Vaccine"],
  },
  {
    primary_id: "D05JYE",
    names: [
      "Butylphosphonate",
      "Dibutyl Phosphite",
      "Dibutyl Phosphonate",
      "Phosphonic Acid, Dibutyl Ester",
      "Dibutoxyphosphine Oxide",
      "Mobil DBHP",
      "Dibutyl Hydrogen Phosphonate",
      "Di-N-Butyl Hydrogen Phosphite",
      "Dibutylfosfit",
      "Phosphorous Acid, Dibutyl Ester",
      "Butyl Phosphonate ((BuO)2HPO)",
      "Butyl Alcohol, Hydrogen Phosphite",
      "Di-N-Butylphosphite",
      "Dibutylfosfit [Czech]",
      "NSC 2668",
      "Dibutyl Phosphite (VAN)",
      "Phosphonic Acid Dibutyl Ester",
      "UNII-R149712Z1F",
      "HSDB 2597",
      "Dibutyl Hydrogen Phosphite (VAN)",
      "EINECS 217-316-1",
      "R149712Z1F",
      "Phosphorous Acid, Dibutyl Ester (VAN)",
      "BRN 10997",
    ],
  },
  {
    primary_id: "D05JYW",
    names: ["Metallo-Zanamivir"],
  },
  {
    primary_id: "D05JZQ",
    names: ["2-(2-(Pyrrolidin-1-Yl)Ethyl)Phenol", "CHEMBL273002"],
  },
  {
    primary_id: "D05JZS",
    names: [
      "3-Hydroxyhippuric Acid",
      "M-Hydroxyhippuric Acid",
      "1637-75-8",
      "(3-Hydroxybenzoylamino)Acetic Acid",
      "2-[(3-Hydroxyphenyl)Formamido]acetic Acid",
      "Glycine, N-(3-Hydroxybenzoyl)-",
      "UNII-17HCX0HNRT",
      "N-M-Hydroxylbenzoylglycine",
      "3-Hydroxybenzoylglycine",
      "17HCX0HNRT",
      "CHEMBL447627",
      "Glycine,N-(3-Hydroxybenzoyl)-",
      "(3-Hydroxybenzamido)Acetic Acid",
      "CHEBI:70824",
      "M-Hydroxyhippurate",
      "3-Hydroxyhippurate",
      "AC1L9OTS",
      "(3-Hydroxybenzoyl)Glycine",
      "AC1Q75XZ",
      "N-(3-Hydroxybenzoyl)Glycine",
      "SCHEMBL600772",
      "CTK4D1647",
      "DTXSID30167650",
      "MolPort-004-292-678",
    ],
  },
  {
    primary_id: "D05KAC",
    names: ["PG-102"],
  },
  {
    primary_id: "D05KBP",
    names: ["INO-8875"],
  },
  {
    primary_id: "D05KBU",
    names: [
      "Gabapentin Enacarbil",
      "1838262",
      "ASP8825",
      "Gabapentin-XP",
      "GSK 1838262",
      "Horizant",
      "Regnite",
      "Solzira",
      "XP13512",
    ],
  },
  {
    primary_id: "D05KCJ",
    names: ["ANZ-521"],
  },
  {
    primary_id: "D05KCN",
    names: [
      "2-Pyridin-2-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "CHEMBL3144580",
      "CHEMBL8231",
      "BDBM50009010",
      "ZINC107084651",
      "AKOS023543918",
    ],
  },
  {
    primary_id: "D05KED",
    names: ["Rocilinostat"],
  },
  {
    primary_id: "D05KEE",
    names: [
      "AFD(R)",
      "GTPL2937",
      "CHEMBL382739",
      "SCHEMBL12084862",
      "Phosphoric Acid (R)-2-Methyl-2-Amino-4-(4-Heptyloxyphenyl)Butyl Ester",
      "[(2R)-2-Amino-4-(4-Heptoxyphenyl)-2-Methylbutyl] Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D05KEF",
    names: ["LUF-5978"],
  },
  {
    primary_id: "D05KEZ",
    names: [
      "Ethyl Chloride",
      "Chloroethane",
      "ETHYL CHLORIDE",
      "Ethane, Chloro-",
      "Monochloroethane",
      "75-00-3",
      "Chlorethyl",
      "Chlorene",
      "Chelen",
      "Muriatic Ether",
      "Monochlorethane",
      "Cloretilo",
      "Anodynon",
      "Dublofix",
      "Chloryl",
      "Ether Chloratus",
      "Ether Muriatic",
      "Hydrochloric Ether",
      "Ether Hydrochloric",
      "Aethylis Chloridum",
      "Chloryl Anesthetic",
      "Narcotile",
      "Kelene",
      "Aethylchlorid",
      "Aethylis",
      "Chloridum",
      "1-Chloroethane",
      "C2H5Cl",
      "Chloorethaan",
      "Chloroaethan",
      "Cloroetano",
      "Ether Chloridum",
      "Etylu Chlorek",
      "Ethane, Chloro Derivs.",
      "Cloruro Di Etile",
      "Chlorure D'ethyle",
      "NCI-C06224",
      "Cloroetano [Italian]",
    ],
  },
  {
    primary_id: "D05KFA",
    names: ["BL-1832"],
  },
  {
    primary_id: "D05KFG",
    names: [
      "ADIPATE",
      "Adipic Acid",
      "Hexanedioic Acid",
      "124-04-9",
      "Adipinic Acid",
      "1,4-Butanedicarboxylic Acid",
      "Adilactetten",
      "Acifloctin",
      "Acinetten",
      "1,6-Hexanedioic Acid",
      "Molten Adipic Acid",
      "Kyselina Adipova",
      "Adipinsaure [German]",
      "Acide Adipique [French]",
      "Kyselina Adipova [Czech]",
      "Hexanedioate",
      "Adipinsaeure",
      "FEMA Number 2011",
      "Adi-Pure",
      "UNII-76A0JE0FKJ",
      "NSC 7622",
      "CCRIS 812",
      "Adipic Acid [NF]",
      "FEMA No. 2011",
      "HSDB 188",
      "Adipic Acid, 99%",
      "Hexan-1,6-Dicarboxylate",
      "EINECS 204-673-3",
      "BRN 1209788",
      "76A0JE0FKJ",
      "AI3-03700",
      "1,6-HEXANE-DIOIC ACID",
      "CHEBI:30832",
      "NSC7622",
    ],
  },
  {
    primary_id: "D05KFH",
    names: ["CD171 Specific CAR T Cells Expressing EGFRt"],
  },
  {
    primary_id: "D05KGG",
    names: ["IMA101"],
  },
  {
    primary_id: "D05KGK",
    names: ["JY-0691"],
  },
  {
    primary_id: "D05KHN",
    names: [
      "ICI-169369",
      "2-Detpq",
      "Ici 169369",
      "2-((2-(Dimethylamino)Ethyl)Thio)-3-Phenylquinoline",
      "85273-95-6",
      "Ici-169,369",
      "UNII-4CT4CS5BA7",
      "4CT4CS5BA7",
      "N,N-Dimethyl-2-(3-Phenylquinolin-2-Yl)Sulfanylethanamine",
      "Ethanamine, N,N-Dimethyl-2-((3-Phenyl-2-Quinolinyl)Thio)-",
      "Ethanamine, N,N-Dimethyl-2-[(3-Phenyl-2-Quinolinyl)Thio]-",
      "AC1L3UA4",
      "GTPL273",
      "AC1Q7E25",
      "SCHEMBL2640021",
      "CHEMBL1191534",
      "CTK2I4265",
      "BDBM84945",
      "DTXSID40234468",
      "85273-96-7 (Hydrochloride)",
      "N,n-Dimethyl-2-[(3-Phenylquinolin-2-Yl)Sulfanyl]ethanamine",
      "ZINC5140456",
      "PDSP2_001405",
    ],
  },
  {
    primary_id: "D05KHP",
    names: ["ABC-3"],
  },
  {
    primary_id: "D05KHS",
    names: ["PCMPS"],
  },
  {
    primary_id: "D05KHT",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 19"],
  },
  {
    primary_id: "D05KIF",
    names: [
      "(-)-Cubebininolide",
      "CHEMBL479314",
      "Cordigerin",
      "(-)-Cordigerine",
      "VUNCHONBJWJYID-DLBZAZTESA-N",
      "BDBM50259868",
      "(3R,4R)-3,4-Bis(3,4,5-Trimethoxybenzyl)Dihydrofuran-2(3H)-One",
      "2(3H)-Furanone, Dihydro-3,4-Bis[(3,4,5-Trimethoxyphenyl)Methyl]-, (3R-Trans)-",
      "2(3H)-Furanone, Dihydro-3,4-Bis[(3,4,5-Trimethoxyphenyl)Methyl]-, (3R,4R)-",
    ],
  },
  {
    primary_id: "D05KIQ",
    names: ["H-Dmt-Tic-Gly-N(Me)-Ph", "CHEMBL500937"],
  },
  {
    primary_id: "D05KIS",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-D-Phe-D-Asp-D-Nle-Trp-H", "CHEMBL375359"],
  },
  {
    primary_id: "D05KIV",
    names: [
      "Ecopipam Hydrochloride",
      "Ecopipam HCl",
      "UNII-5X1J3190JI",
      "Ecopipam Hydrochloride [USAN]",
      "190133-94-9",
      "5X1J3190JI",
      "Ecopipam Hydrochloride (USAN)",
      "(-)-(6aS-13bR)-11-Chloro-6,6a,7,8,9,13b-Hexahydro-7-Methyl-5H-Benzo(D)Naphth(2,1-B)Azepin-12-Ol Hydrochloride",
      "(6aS-Trans)-11-Chloro-6,6a,7,8,9,13b-Hexahydro-7-Methyl-5H-Benzo(D)Naphth(2,1-B)Azepin-12-Ol Hydrochloride",
      "5H-Benzo(D)Naphth(2,1-B)Azepin-12-Ol, 11-Chloro-6,6a,7,8,9,13b-Hexahydro-7-Methyl-, Hydrochloride, (6aS-Trans)-",
      "5H-Benzo(D)Naphth(2,1-B)Azepin-12-Ol, 11-Chloro-",
    ],
  },
  {
    primary_id: "D05KJH",
    names: [
      "COCLAURINE",
      "(S)-Coclaurine",
      "486-39-5",
      "UNII-CW1576313Y",
      "(1S)-1-(4-Hydroxybenzyl)-6-Methoxy-1,2,3,4-Tetrahydroisoquinolin-7-Ol",
      "CHEMBL446211",
      "CHEBI:15950",
      "CW1576313Y",
      "1-(P-Hydroxybenzyl)-6-Methoxy-7-Hydroxy-1,2,3,4-Tetrahydroisoquinoline",
      "Coclaurin",
      "(S)-1,2,3,4-Tetrahydro-1-[(4-Hydroxyphenyl)Methyl]-6-Methoxy-7-Isoquinolinol",
      "7-Isoquinolinol,1,2,3,4-Tetrahydro-1-[(4-Hydroxyphenyl)Methyl]-6-Methoxy-, (1S)-",
      "AC1L4NPG",
      "AC1Q7A6I",
      "SCHEMBL20683184",
      "CTK4J0916",
      "DTXSID60197561",
      "MolPort-019-904-720",
      "ZINC896120",
    ],
  },
  {
    primary_id: "D05KJX",
    names: [
      "8-(2,4,6-Trimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL103474",
      "AC1LAR8X",
      "CTK6J8980",
      "BDBM50059931",
      "2,6-Diamino-8-(2',4',6'-Trimethoxyphenyl)Purine",
      "8-(2,4,6-Trimethoxyphenyl)-7H-Purine-2,6-Diamine",
      "8-(2,4,6-Trimethoxyphenyl)-3h-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D05KJY",
    names: [
      "BETULIN",
      "Betulin",
      "473-98-3",
      "Betulinol",
      "Betuline",
      "Trochol",
      "Lup-20(29)-Ene-3b,28-Diol",
      "Betulol",
      "Lup-20(29)-Ene-3beta,28-Diol",
      "UNII-6W70HN7X7O",
      "NSC 4644",
      "C30H50O2",
      "EINECS 207-475-5",
      "Lup-20(30)-Ene-3beta,28-Diol",
      "AI3-62999",
      "CHEBI:3086",
      "6W70HN7X7O",
      "Lup-20(29)-Ene-3,28-Diol, (3beta)-",
      "(1R,3aS,5aR,5bR,7aR,9S,11aR,11bR,13aR,13bR)-3a-(Hydroxymethyl)-5a,5b,8,8,11a-Pentamethyl-1-(Prop-1-En-2-Yl)Icosahydro-1H-Cyclopenta[a]chrysen-9-Ol",
      "Betulinic Alcohol",
      "Lup-20(29)-Ene-3 Beta,28-Diol",
    ],
  },
  {
    primary_id: "D05KKB",
    names: ["Sulfamic Acid 3-(4-Methoxy-Benzoyl)-Phenyl Ester", "CHEMBL434211"],
  },
  {
    primary_id: "D05KKC",
    names: ["CHML"],
  },
  {
    primary_id: "D05KKF",
    names: ["NC-2500"],
  },
  {
    primary_id: "D05KKP",
    names: ["PF-06252616"],
  },
  {
    primary_id: "D05KKV",
    names: ["Dicaffeoylquinic Acids"],
  },
  {
    primary_id: "D05KKZ",
    names: ["PMID29334795-Compound-67"],
  },
  {
    primary_id: "D05KLE",
    names: ["KB-74935"],
  },
  {
    primary_id: "D05KLQ",
    names: ["HIV Vaccines"],
  },
  {
    primary_id: "D05KME",
    names: ["ORADUR-ADHD"],
  },
  {
    primary_id: "D05KNG",
    names: ["ALB-109564(A)", "ALB-109564", "Vinca Alkaloid Analogs (Cancer), Albany Molecular Research"],
  },
  {
    primary_id: "D05KNT",
    names: ["Peptide Boronic Acid Analogue"],
  },
  {
    primary_id: "D05KOA",
    names: ["Prophylactic DNA Vaccine"],
  },
  {
    primary_id: "D05KON",
    names: ["Felbamate"],
  },
  {
    primary_id: "D05KOP",
    names: ["Ii-Key Hybrid Peptide Vaccine"],
  },
  {
    primary_id: "D05KOY",
    names: ["Stem Cell Therapy, Spinal Cord Injury"],
  },
  {
    primary_id: "D05KPH",
    names: ["Endothelin-3"],
  },
  {
    primary_id: "D05KQC",
    names: [
      "DNQX",
      "Dnqx",
      "2379-57-9",
      "6,7-Dinitro-1,4-Dihydroquinoxaline-2,3-Dione",
      "FG-9041",
      "FG 9041",
      "UNII-62T278S1MX",
      "6,7-Dinitroquinoxaline-2,3-Dione (DNQX)",
      "2,3-Quinoxalinedione, 1,4-Dihydro-6,7-Dinitro-",
      "6,7-Dinitroquinoxaline-2,3(1H,4H)-Dione",
      "62T278S1MX",
      "6,7-Dinitro-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "SR-01000075442",
      "1ftl",
      "Tocris-0189",
      "AC1MZKE9",
      "Lopac-D-0540",
      "Biomol-NT_000182",
      "Oprea1_127694",
      "Lopac0_000345",
      "MLS000705107",
      "SCHEMBL594575",
      "SCHEMBL8128055",
      "BPBio1_001176",
      "CHEMBL155265",
      "C8H4N4O6",
      "CHEBI:93492",
      "CTK8F7031",
      "DTXSID60178476",
      "MolPort-003-846-931",
    ],
  },
  {
    primary_id: "D05KQE",
    names: ["MK-287"],
  },
  {
    primary_id: "D05KQF",
    names: ["UDP-Xylose"],
  },
  {
    primary_id: "D05KRC",
    names: ["Emopamil"],
  },
  {
    primary_id: "D05KRH",
    names: ["CNDO-113", "CNDO-123", "Bcl-2 Inhibitors (Cancer), Coronado Biosciences"],
  },
  {
    primary_id: "D05KRS",
    names: ["CT-340"],
  },
  {
    primary_id: "D05KRX",
    names: ["Quinoline Derivative 10"],
  },
  {
    primary_id: "D05KSC",
    names: ["A-850002", "Compound 28b [PMID: 16279797]", "A 850002", "A850002"],
  },
  {
    primary_id: "D05KSO",
    names: ["HCV Therapeutics"],
  },
  {
    primary_id: "D05KSW",
    names: ["N-Phenethylquinoxaline-2-Carboxamide"],
  },
  {
    primary_id: "D05KSY",
    names: ["LIM-0746"],
  },
  {
    primary_id: "D05KTE",
    names: ["SODIUM CITRATE"],
  },
  {
    primary_id: "D05KTK",
    names: ["CJC-1004", "CCI-1004", "DAC-TI", "DAC-Thrombin Inhibitor", "Thrombin Inhibitor, Conjuchem"],
  },
  {
    primary_id: "D05KTV",
    names: ["NGD-93-1"],
  },
  {
    primary_id: "D05KUM",
    names: ["DESMETHYLOLANZAPINE"],
  },
  {
    primary_id: "D05KUP",
    names: ["ERB-257"],
  },
  {
    primary_id: "D05KVC",
    names: ["Paliroden"],
  },
  {
    primary_id: "D05KVN",
    names: [
      "2-(8-Imidazol-1-Yl-Octyl)-Isoindole-1,3-Dione",
      "CHEMBL166834",
      "SCHEMBL10839307",
      "UYNFUMGZMJRXFD-UHFFFAOYSA-N",
      "BDBM50024111",
    ],
  },
  {
    primary_id: "D05KWF",
    names: ["Cudraxanthone M", "CHEMBL371024", "BDBM50175018"],
  },
  {
    primary_id: "D05KWN",
    names: ["KNI-10737"],
  },
  {
    primary_id: "D05KWY",
    names: ["ZD-9063P", "Colorectal Cancer Therapy (ADEPT, Backup), Zeneca"],
  },
  {
    primary_id: "D05KXF",
    names: ["Tetra-Hydro-Naphthyridine Derivative 1"],
  },
  {
    primary_id: "D05KXJ",
    names: ["GSK233705"],
  },
  {
    primary_id: "D05KXP",
    names: ["8-N-Undecylnaringenin"],
  },
  {
    primary_id: "D05KYE",
    names: ["2'-Epi-Guianin", "CHEMBL570978", "2''-Epi-Guianin"],
  },
  {
    primary_id: "D05KZH",
    names: ["(5-Bromo-Pentyl)-Trimethyl-Ammonium"],
  },
  {
    primary_id: "D05KZK",
    names: ["SMT-D002", "DL-06003"],
  },
  {
    primary_id: "D05KZM",
    names: ["Dimesna"],
  },
  {
    primary_id: "D05KZV",
    names: ["VR-461"],
  },
  {
    primary_id: "D05LBC",
    names: ["VU591"],
  },
  {
    primary_id: "D05LBH",
    names: ["PMID28621580-Compound-WO2013112959C68"],
  },
  {
    primary_id: "D05LBM",
    names: ["Azole Derivative 5"],
  },
  {
    primary_id: "D05LBU",
    names: ["Ticlopidine"],
  },
  {
    primary_id: "D05LCN",
    names: [
      "BMP-7 Bone Morphogenetic Protein",
      "BMP-2",
      "BMP-7",
      "Vascular Calcification Therapeutics, Johnson & Johnson",
      "BMP-7 (Kidney Disease), Curis",
      "BMP-7 (Kidney Disease), Stryker",
      "Bone Morphogenetic Protein (BioChaperone Technology, Bone Repair), Adocia",
      "Bone Morphogenetic Protein (BioChaperone Technology, Degenerative Disc Disease/Spinal Fusion),Adocia",
      "Bone Morphogenic Protein Modulators (Renal Osteodystrophy/ Vascular Calcification), Johnson & Johnson",
      "BMP-Based Therapeutics (Renal Osteodystrophy/ Vascular Calcification), Johnson & Johnson",
      "BMP-Based Therapeutics (Renal Osteodystrophy/ Vascular Calcification), Ortho Biotech/Centocor",
    ],
  },
  {
    primary_id: "D05LCX",
    names: ["A-70276", "A-71134"],
  },
  {
    primary_id: "D05LDI",
    names: ["2-Isoursolic Acid", "CHEMBL1080966"],
  },
  {
    primary_id: "D05LDK",
    names: ["IPX203"],
  },
  {
    primary_id: "D05LDQ",
    names: ["BAY 94-9027"],
  },
  {
    primary_id: "D05LEO",
    names: [
      "Fluorouracil",
      "5-Fluorouracil",
      "51-21-8",
      "5-FU",
      "Fluoroplex",
      "Adrucil",
      "Efudex",
      "Carac",
      "Fluracil",
      "Fluoroblastin",
      "5-Fluoropyrimidine-2,4(1H,3H)-Dione",
      "Kecimeton",
      "Timazin",
      "Carzonal",
      "Efudix",
      "Arumel",
      "Fluril",
      "Queroplex",
      "Fluracilum",
      "Ulup",
      "5-Fluoracil",
      "Phthoruracil",
      "Fluro Uracil",
      "5-Fluoro-2,4(1H,3H)-Pyrimidinedione",
      "Ftoruracil",
      "Fluorouracilum",
      "Efurix",
      "Fluri",
      "5 Fluorouracil",
      "Effluderm (Free Base)",
      "5-Fluoro-1H-Pyrimidine-2,4-Dione",
      "Fluorouracilo",
      "Fluroblastin",
      "Phtoruracil",
      "2,4-Dihydroxy-5-Fluoropyrimidine",
      "2,4(1H,3H)-Pyrimidinedione, 5-Fluoro-",
      "Effluderm",
      "Fluorouracile",
      "Fluoruracil",
      "Fluracedyl",
      "Flurodex",
      "Neofluor",
      "Onkofluor",
      "Ribofluor",
      "Tetratogen",
      "URF",
      "Allergan Brand Of Fluorouracil",
      "Biosyn Brand Of Fluorouracil",
      "CSP Brand Of Fluorouracil",
      "Cinco FU",
      "Dakota Brand Of Fluorouracil",
      "Dermatech Brand Of Fluorouracil",
      "Dermik Brandof Fluorouracil",
      "Ferrer Brand Of Fluorouracil",
      "Fluoro Uracile ICN",
      "Fluorouracil GRY",
      "Fluorouracil Mononitrate",
      "Fluorouracil Monopotassium Salt",
      "Fluorouracil Monosodium Salt",
      "Fluorouracil Potassium Salt",
      "Fluorouracil Teva Brand",
      "Fluorouracile Dakota",
      "Fluorouracile [DCIT]",
      "Fluorouracilo Ferrer Far",
      "Gry Brand Of Fluorouracil",
      "Haemato Brand Of Fluorouracil",
      "Haemato Fu",
      "Hexal Brand Of Fluorouracil",
      "ICN Brand Of Fluorouracil",
      "Inhibits Thymilidate Synthetase",
      "Medac Brand Of Fluorouracil",
      "Neocorp Brand Of Fluorouracil",
      "Onkoworks Brand Of Fluorouracil",
      "Ribosepharm Brand Of Fluorouracil",
      "Riemser Brand Of Fluorouracil",
      "Roche Brand Of Fluorouracil",
      "Teva Brand Of Fluorouracil",
      "F 6627",
      "F0151",
      "IN1335",
      "U 8953",
      "Adrucil (TN)",
      "Carac (TN)",
      "Dakota, Fluorouracile",
      "Efudex (TN)",
      "Fluoro-Uracile ICN",
      "Fluoro-Uracile",
      "Fluoro-Uracilo",
      "Fluoroplex (TN)",
      "Fluorouracil-GRY",
      "Fluorouracilo [INN-Spanish]",
      "Fluorouracilum [INN-Latin]",
      "Haemato-Fu",
      "Ro 2-9757",
      "U-8953",
      "Ro-2-9757",
      "Fluorouracil (JP15/USP/INN)",
      "Fluorouracil [USAN:INN:BAN:JAN]",
      "1-Fluoro-1h-Pyrimidine-2,4-Dione",
      "2,4-Dioxo-5-Fluoropryimidine",
      "2,4-Dioxo-5-Fluoropyrimidine",
      "5 FU Lederle",
      "5 FU Medac",
      "5 Fluorouracil Biosyn",
      "5 HU Hexal",
      "5-FU (TN)",
      "5-FU Lederle",
      "5-FU Medac",
      "5-Faracil",
      "5-Fluor-2,4(1H,3H)-Pyrimidindion",
      "5-Fluor-2,4(1H,3H)-Pyrimidindion [Czech]",
      "5-Fluor-2,4-Dihydroxypyrimidin",
      "5-Fluor-2,4-Dihydroxypyrimidin [Czech]",
      "5-Fluor-2,4-Pyrimidindiol",
      "5-Fluor-2,4-Pyrimidindiol [Czech]",
      "5-Fluoracil [German]",
      "5-Fluoracyl",
      "5-Fluoro-2,4-Pyrimidinedione",
      "5-Fluoropyrimidin-2,4-Diol",
      "5-Fluoropyrimidine-2,4-Dione",
      "5-Fluorouracil-Biosyn",
      "5-Fluoruracil",
      "5-Fluoruracil [German]",
      "5-Ftouracyl",
      "5-HU Hexal",
      "5-Fluoro Uracil",
      "5FU",
    ],
  },
  {
    primary_id: "D05LFV",
    names: [
      "S-(4-Methylbenzyl)Isothiourea Hydrochloride",
      "940-63-6",
      "CHEMBL1224315",
      "DTXSID80501388",
      "MolPort-019-952-918",
      "S-(4-Methylbenzyl)Isothiourea HCl",
      "NSC77403",
      "NSC-77403",
      "SR-01000635084-1",
      "(4-Methylphenyl)Methyl Carbamimidothioate--Hydrogen Chloride (1/1)",
    ],
  },
  {
    primary_id: "D05LGX",
    names: ["Fipamezole"],
  },
  {
    primary_id: "D05LIE",
    names: ["Macrolide Derivative"],
  },
  {
    primary_id: "D05LIH",
    names: ["Anti-CD19 CAR-T Cells"],
  },
  {
    primary_id: "D05LJC",
    names: ["4-(2-(Benzyloxy)-3-Fluorophenyl)Piperidine", "CHEMBL583750"],
  },
  {
    primary_id: "D05LJD",
    names: ["5-Oxo-C20:3", "5-Oxo-6e,8z,11z-Eicosatrienoic Acid", "5-Oxo-ETrE"],
  },
  {
    primary_id: "D05LJF",
    names: ["N-(1-(4-Bromobenzyl)Piperidin-4-Yl)-2-Naphthamide", "CHEMBL234472"],
  },
  {
    primary_id: "D05LJK",
    names: ["Fluorovinyloxyacetamide Anti-Inflammatory Drugs"],
  },
  {
    primary_id: "D05LJQ",
    names: ["Peptide Analog 48"],
  },
  {
    primary_id: "D05LJY",
    names: ["Ac-I[CV(2Nal)QDWGAHRC]T"],
  },
  {
    primary_id: "D05LKO",
    names: [
      "1-(2-(2-Methoxyphenyl)-1-Phenylethyl)Piperazine",
      "CHEMBL214335",
      "SCHEMBL5440547",
      "BDBM50188048",
      "(-)-1-(2-(2-Methoxyphenyl)-1-Phenylethyl)Piperazine",
    ],
  },
  {
    primary_id: "D05LKP",
    names: [
      "Sulfadiazine",
      "Adiazin",
      "Adiazine",
      "CocoDiazine",
      "Codiazine",
      "Cremodiazine",
      "Cremotres",
      "Debenal",
      "Deltazina",
      "Diazin",
      "Diazolone",
      "Diazovit",
      "Diazyl",
      "Eskadiazine",
      "Liquadiazine",
      "Microsulfon",
      "Neazine",
      "Neotrizine",
      "Palatrize",
      "Piridisir",
      "Pirimal",
      "Pyrimal",
      "Quadetts",
      "Quadramoid",
      "Sanodiazine",
      "Solfadiazina",
      "Spofadrizine",
      "Sterazine",
      "Sulfadiazene",
      "Sulfadiazin",
      "Sulfadiazina",
      "Sulfadiazinum",
      "Sulfanilamidopyrimidine",
      "Sulfapirimidin",
      "Sulfapyrimidin",
      "Sulfapyrimidine",
      "Sulfatryl",
      "Sulfazine",
      "Sulfolex",
      "Sulfonsol",
      "Sulfose",
      "Sulphadiazine",
      "Terfonyl",
      "Theradiazine",
      "Trifonamide",
      "Trisem",
      "Truozine",
      "Honey Diazine",
      "Solfadiazina [DCIT]",
      "Sulfapyrimidin [German]",
      "Sulfonamides Duplex",
      "Sulphadiazine E",
      "Triple Sulfas",
      "Zinc Sulfadiazine",
      "A 306",
      "RP 2616",
      "A-306",
      "Coco-Diazine",
      "Lantrisul (TN)",
      "Lipo-Diazine",
      "Lipo-Levazine",
      "Metha-Meridiazine",
      "Neotrizine (TN)",
      "Pecta-Diazine",
      "RBPI21 & Sulfa",
      "Sulfadiazina [INN-Spanish]",
      "Sulfadiazine (TN)",
      "Sulfadiazine, Zinc",
      "Sulfadiazinum [INN-Latin]",
      "Sulfaloid (TN)",
      "Sulfonamides Duplex (TN)",
      "Sulfose (TN)",
      "Terfonyl (TN)",
      "Tri-Sulfameth",
      "Triple Sulfa (TN)",
      "Triple Sulfas (TN)",
      "Triple Sulfoid (TN)",
      "Trisulfapyrimidine, Oral Suspension",
      "A-306 (VAN)",
      "CRL-8131 & Sulfadiazine",
      "Di-Azo-Mul",
      "Mixture Of Sulfadiazine, Sulfamerazine, And Sulfamethazine",
      "Pecta-Diazine, Suspension",
      "S. N. 112",
      "S.N. 112",
      "Sulfadiazine [USAN:INN:JAN]",
      "Thi-Di-Mer",
      "N(1)-2-Pyrimidinylsulfanilamide",
      "N(1)-2-Pyrimidylsulfanilamide",
      "N(Sup 1)-2-Pyrimidinylsulfanilamide",
      "N(Sup1)-2-Pyrimidinylsulfanilamide",
      "N(Sup1)-2-Pyrimidylsulfanilamide",
      "N-(2-Pyrimidinyl)Sulfanilamide",
      "Recombinant Bactericidal/Permeability-Increasing Protein & Sulfadiazine",
      "Sulfa-Triple #2 (TN)",
      "Sulfadiazine (JAN/USP/INN)",
      "N1-(Pyrimidin-2-Yl)Sulfanilamide",
      "Sulfanilamide, N1-2-Pyrimidinyl-(8CI)",
      "Adiazin, Adiazine, Debenal, Liquadiazine, Microsulfon, Sulfazine, Sulfolex, Sulfadiazine",
      "2-Sulfanilamido-Pyrimidine",
      "2-Sulfanilamidopyrimidin",
      "2-Sulfanilamidopyrimidin [German]",
      "2-Sulfanilamidopyrimidine",
      "2-Sulfanilylaminopyrimidine",
      "2-Sulfapyrimidine",
      "4-AMINO-N-2-PYRIMIDINYLBENZENESULFONAMIDE",
      "4-Amino-N-(2-Pyrimidinyl)Benzenesulfonamide",
      "4-Amino-N-2-Pyrimidinyl-Benzenesulfonamide",
      "SDZ",
    ],
  },
  {
    primary_id: "D05LKZ",
    names: ["HPP-607"],
  },
  {
    primary_id: "D05LLI",
    names: ["C[Nle-Gln-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL204310", "BDBM50184355"],
  },
  {
    primary_id: "D05LLJ",
    names: ["MK-4815", "Antimalarial Compound (Oral), Merck & Co/MMV"],
  },
  {
    primary_id: "D05LLP",
    names: ["2-N-Hydroxyamino-1-(4-Ethylthiophenyl)Propane", "CHEMBL1077874", "1204749-41-6"],
  },
  {
    primary_id: "D05LLV",
    names: ["18S-HEPE", "18S-Hydroxyeicosapentaenoate"],
  },
  {
    primary_id: "D05LLX",
    names: [
      "4-Butyrylamino-N-Hydroxy-Benzamide",
      "CHEMBL142254",
      "656261-22-2",
      "Benzamide, N-Hydroxy-4-[(1-Oxobutyl)Amino]-",
      "SCHEMBL675234",
      "CTK1J6159",
      "DTXSID90461262",
    ],
  },
  {
    primary_id: "D05LMB",
    names: ["Alpha-Sulfanyl(2-Methoxybenzyl)Phosphonic Acid"],
  },
  {
    primary_id: "D05LMC",
    names: ["AMG 595"],
  },
  {
    primary_id: "D05LMD",
    names: [
      "VTHRLAGLLSRSGGVVKNNFVPTNVGSKAF-NH2",
      "CHEMBL525571",
      "Alpha-Cgrp (8-37)",
      "Val(8)-Phe(37)-Cgrp",
      "CGRP8-37",
      "CGRP-(8-37)",
      "Calcitonin Gene-Related Peptide (8-37)",
      "H Alpha-CGRP8-37",
      "8-Val-37-Phe-Calcitonin Gene-Related Peptide",
      "Calcitonin Gene-Related Peptide, Val(8)-Phe(37)-",
      "Calcitonin Gene-Related Peptide, Valyl(8)-Phenylalanine(37)-",
      "BDBM50000743",
      "NCGC00167246-01",
      "CALCITONIN GENE RELATED PEPTIDE (8-37)",
      "[Thr-His-Arg-Leu-Ala-Gly-Leu-Leu-Ser-Arg-Ser-Gly-Gly-Val-Val-Lys-Asn-Asn-Phe-Val-Pro-Thr-Asn-Val-Gly-Ser-Lys-Ala-Phe-NH2] HC",
      "Alpha-Calcitonin Gene-R",
    ],
  },
  {
    primary_id: "D05LMT",
    names: ["2-Phenyl-Cyclopropylamine Hydrochloride"],
  },
  {
    primary_id: "D05LOB",
    names: ["FP-1201"],
  },
  {
    primary_id: "D05LOV",
    names: ["ADV-1002401"],
  },
  {
    primary_id: "D05LOY",
    names: [
      "ACH-702",
      "UNII-35QF8GE1L1",
      "35QF8GE1L1",
      "CHEMBL1256993",
      "ACH702",
      "ACH 702",
      "SCHEMBL728226",
      "BDBM50330327",
      "Isothiazolo(5,4-B)Quinoline-3,4(2H,9H)-Dione, 7-((3R)-3-(1-Amino-1-Methylethyl)-1-Pyrrolidinyl)-9-Cyclopropyl-6-Fluoro-8-Methoxy-",
      "922491-46-1",
      "(R)-7-[3-(1-Amino-1-Methyl-Ethyl)-Pyrrolidin-1-Yl]-9-Cyclopropyl-6-Fluoro-8-Methoxy-9H Isothiazolo[5,4-B]quinoline-3,4-Dione",
      "(R)-7-(3-(2-Aminopropan-2-Yl)Pyrrolidin-1-Yl)-9-Cyclopropyl-6-Fluoro-8-Methoxyisothiazolo[5,4-B]quinoline-3,4(2h,9h)-Dione",
    ],
  },
  {
    primary_id: "D05LPK",
    names: [
      "5-Methyl-Oxazolidin-(2Z)-Ylideneamine",
      "CHEMBL107413",
      "2-Imino-5-Methyloxazolidine",
      "5-Methyl-2-Iminooxazolidine",
      "SCHEMBL8968765",
      "SCHEMBL6592796",
      "BDBM50138201",
      "AKOS006350070",
      "AKOS013498557",
      "5-Methyl-4,5-Dihydro-Oxazol-2-Ylamine",
    ],
  },
  {
    primary_id: "D05LPP",
    names: ["ABH001"],
  },
  {
    primary_id: "D05LPW",
    names: ["DA-1229", "DPP-IV Inhibitor (Tablet, Type II Diabetes), Dong-A"],
  },
  {
    primary_id: "D05LQD",
    names: ["3-Hydroxy-4,10-Dimethyl-6H-Benzo[c]chromen-6-One", "CHEMBL205988", "SCHEMBL6828383"],
  },
  {
    primary_id: "D05LQX",
    names: ["BOL-303259-X"],
  },
  {
    primary_id: "D05LRD",
    names: ["BMS-933043"],
  },
  {
    primary_id: "D05LRV",
    names: ["[2,2':5',2'']Terthiophene-4,5''-Dicarbaldehyde", "CHEMBL74983", "BDBM50217036"],
  },
  {
    primary_id: "D05LSB",
    names: [
      "COX-2 Inhibitors",
      "Anti-Inflammatories, FAES Farma",
      "COX-2 Inhibitors, FAES Farma",
      "Cyclooxygenase-2 Inhibitor, FAES Farma",
    ],
  },
  {
    primary_id: "D05LSD",
    names: ["ISIS 19211"],
  },
  {
    primary_id: "D05LSF",
    names: [
      "16-HETE",
      "(5Z,8Z,11Z,14Z)-16-Hydroxyicosa-5,8,11,14-Tetraenoic Acid",
      "16-Hydroxy-5Z,8Z,11Z,14Z-Eicosatetraenoic Acid",
      "(All-Cis)-16-Hydroxy-5,8,11,14-Icosatetraenoic Acid",
      "(All-Cis)-16-Hydroxy-5,8,11,14-Eicosatetraenoic Acid",
      "(5Z,8Z,11Z,14Z)-16-Hydroxyeicosa-5,8,11,14-Tetraenoic Acid",
      "128914-46-5",
      "(+/-)16-HETE",
      "AC1O5XSP",
      "16-Hydroxyarachidonic Acid",
      "GTPL6923",
      "CHEBI:63994",
      "JEKNPVYFNMZRJG-UFINWASNSA-N",
      "LMFA03060105",
      "J-005634",
      "5,8,11,14-Eicosatetraenoic Acid, 16-Hydroxy-, (5Z,8Z,11Z,14Z)-",
    ],
  },
  {
    primary_id: "D05LST",
    names: ["Myrcludex B"],
  },
  {
    primary_id: "D05LSY",
    names: [
      "AKP-020",
      "BEOV",
      "Bis(Ethylmaltolato)Oxovanadium IV",
      "Insulin Enhancer (Oral, Type 2 Diabetes), Akesis Pharmaceuticals",
    ],
  },
  {
    primary_id: "D05LTB",
    names: ["Pyrazole And Thiophene Derivative 4"],
  },
  {
    primary_id: "D05LTJ",
    names: ["ISIS 325568", "BDBM231666", "N-(2-(1H-Indol-3-Yl)Ethyl)-3-(3-Thiazol-2-Ylureido)Benzenesulfonamide (1b)"],
  },
  {
    primary_id: "D05LTW",
    names: ["1,2-Bis(2,3-Fluorophenyl)Ethane-1,2-Dione", "CHEMBL395018", "SCHEMBL3184940"],
  },
  {
    primary_id: "D05LTY",
    names: ["Enbrel"],
  },
  {
    primary_id: "D05LUT",
    names: ["Molecule 23"],
  },
  {
    primary_id: "D05LVL",
    names: [
      "AVR-147",
      "CTK-100147 Series",
      "Proteasome Inhibitors (Cancer), Advanced Viral Research",
      "Proteasome Inhibitors (Cancer), Cetek",
    ],
  },
  {
    primary_id: "D05LWE",
    names: ["[125I]BOP", "[125I]-I-BOP"],
  },
  {
    primary_id: "D05LWK",
    names: ["[6,4''']biflavone", "CHEMBL205326", "[6,4'''''']biflavone", "BDBM50183245"],
  },
  {
    primary_id: "D05LXA",
    names: ["1-(1-Biphenyl-4-Yl-2-Methyl-Propyl)-1H-Imidazole", "CHEMBL496468"],
  },
  {
    primary_id: "D05LXC",
    names: ["QA-241"],
  },
  {
    primary_id: "D05LXG",
    names: ["RHC80267"],
  },
  {
    primary_id: "D05LXH",
    names: [
      "Beta-Interferon",
      "Beta-Interferon (Multiple Sclerosis)",
      "Beta-Interferon (Multiple Sclerosis), Phage Biotechnology",
      "Beta-Interferon (Multiple Sclerosis), Phage Pharmaceuticals",
    ],
  },
  {
    primary_id: "D05LXP",
    names: [
      "7,12-Dihydro-7,12-Diaza-Indeno[1,2-A]fluorene",
      "5,12-Dihydroindolo[3,2-A]carbazole",
      "111296-91-4",
      "5,12-Dihydroindolo[3,2-C]carbazole",
      "Indolo[3,2-]arbazole, 5,12-Ihydro-",
      "Indolo[3,2-A]carbazole, 5,12-Dihydro-",
      "5H,12H-Indolo[3,2-A]carbazole",
      "AC1NFSZ0",
      "SCHEMBL224017",
      "CHEMBL53230",
      "DTXSID80405771",
      "QQWXDAWSMPLECU-UHFFFAOYSA-N",
      "MolPort-027-835-190",
      "ZINC13490703",
      "2059AA",
      "AKOS016009131",
      "5,12-Dihydro-Indolo[3,2-A]carbazole",
      "Indolo[3,2-A]carbazole,5,12-Dihydro-",
      "SC-48298",
      "AJ-63955",
      "AX8227529",
    ],
  },
  {
    primary_id: "D05LYC",
    names: ["KR-62436"],
  },
  {
    primary_id: "D05LYL",
    names: [
      "SC-51146",
      "141059-52-1",
      "CHEMBL328492",
      "2H-1-Benzopyran-2-Propanoicacid,7-[3-[2-(Cyclopropylmethyl)-3-Methoxy-4-[(Methylamino)Carbonyl]phenoxy]propoxy]-3,4-Dihydro-8-Propyl-",
      "ACMC-1C1U2",
      "7-(3-(2-(Cyclopropylmethyl)-3-Methoxy-4-((Methylamino)Carbonyl)Phenoxy)Propoxy)-3,4-Dihydro-8-Propyl-2H-1-Benzopyran-2-Propanoic Acid",
      "AC1L52J5",
      "SCHEMBL1890696",
      "CTK4C2448",
      "BDBM50052017",
      "2H-1-Benzopyran-2-Propanoic Acid, 7-(3-(2-(Cyclopropylmethyl)-3-Methoxy-4-((Methylamino)Carbonyl)Phenoxy)Propoxy)-3,4-Dihydro-8-P",
    ],
  },
  {
    primary_id: "D05LYX",
    names: [
      "Glibenclamide",
      "Abbenclamide",
      "Adiab",
      "Azuglucon",
      "Bastiverit",
      "Benclamin",
      "Betanase",
      "Calabren",
      "Cytagon",
      "Daonil",
      "Debtan",
      "Diabeta",
      "Diabiphage",
      "Dibelet",
      "Duraglucon",
      "Euclamin",
      "Euglucan",
      "Euglucon",
      "Euglykon",
      "Gewaglucon",
      "Gilemal",
      "Glamide",
      "Glibadone",
      "Gliban",
      "Gliben",
      "Glibenbeta",
      "Glibenclamida",
      "Glibenclamidum",
      "Glibenil",
      "Glibens",
      "Glibesyn",
      "Glibet",
      "Glibetic",
      "Glibil",
      "Gliboral",
      "Glicem",
      "Glidiabet",
      "Glimel",
      "Glimide",
      "Glimidstata",
      "Glisulin",
      "Glitisol",
      "Glubate",
      "Gluben",
      "Glucobene",
      "Glucohexal",
      "Glucolon",
      "Glucomid",
      "Glucoremed",
      "Glucoven",
      "Glyben",
      "Glybenclamide",
      "Glybenzcyclamide",
      "Glyburide",
      "Glycolande",
      "Glycomin",
      "Glynase",
      "Hexaglucon",
      "Humedia",
      "Lederglib",
      "Libanil",
      "Lisaglucon",
      "Maninil",
      "Melix",
      "Micronase",
      "Miglucan",
      "Nadib",
      "Neogluconin",
      "Normoglucon",
      "Orabetic",
      "Pira",
      "Praeciglucon",
      "PresTab",
      "Prodiabet",
      "Renabetic",
      "Sugril",
      "Suraben",
      "Tiabet",
      "Yuglucon",
      "Euglucon N",
      "Glibenclamid AL",
      "Glibenclamid Basics",
      "Glibenclamid Fabra",
      "Glibenclamid Genericon",
      "Glibenclamid Heumann",
      "Glibenclamid Riker M",
      "Glyburide [USAN]",
      "Micronized Glyburide",
      "Betanese 5",
      "Euglucon 5",
      "G 0639",
      "GBN 5",
      "HB 419",
      "HB 420",
      "HB419",
      "HB420",
      "Norglicem 5",
      "U 26452",
      "UR 606",
      "Apo-Glibenclamide",
      "Daonil (TN)",
      "Dia-Basan",
      "Diabeta (TN)",
      "Euglucon (TN)",
      "Gen-Glybe",
      "Gliben-Puren N",
      "Glibenclamid Riker M.",
      "Glibenclamid-Cophar",
      "Glibenclamid-Ratiopharm",
      "Glibenclamida [INN-Spanish]",
      "Glibenclamidum [INN-Latin]",
      "Gluco-Tablimen",
      "Glyburide (USP)",
      "Glyburide (Micronized)",
      "Glynase (TN)",
      "HB-419",
      "HB-420",
      "Hemi-Daonil",
      "Med-Glionil",
      "Micronase (TN)",
      "Novo-Glyburide",
      "Semi-Euglucon",
      "Semi-Daonil",
      "U-26452",
      "Glibenclamide (JP15/INN)",
      "Semi-Daonil (TN)",
      "Semi-Gliben-Puren N",
      "N-P-[2-(5-Chloro-2-Methoxybenzamido)Ethyl]benzenesulfonyl-N'-Cyclohexylurea",
      "N-P-[2-(5-Chloro-2-Methoxybenzamido)-Ethyl]benzene-Sulfonyl-N-Cyclohexylurea",
      "N-(4-(2-(5-Chloro-2-Methoxybenzamido)Ethyl)Phenylsulfonyl)-N'-Cyclohexylurea",
      "1-((P-(2-(5-Chloro-O-Anisamido)Ethyl)Phenyl)Sulfonyl)-3-Cyclohexylurea",
      "1-(P-(2-(5-Chloro-2-Methoxybenzamido)Ethyl)Benzenesulfonyl)-3-Cyclohexylurea",
      "5-Chloro-N-[4-(Cyclohexylureidosulfonyl)Phenethyl]-2-Methoxybenzamide",
      "5-Chloro-N-[2-[4-(Cyclohexylcarbamoylsulfamoyl)Phenyl]ethyl]-2-Methoxybenzamide",
    ],
  },
  {
    primary_id: "D05LZG",
    names: [
      "R-Type Pyocins",
      "Avidocin Protiens (Pseudomonas Aeruginosa Infections), AvidBiotics",
      "R-Type Pyocins (Pseudomonas Aeruginosa Infections)",
      "R-Type Pyocins (Pseudomonas Aeruginosa Infections), AvidBiotics",
    ],
  },
  {
    primary_id: "D05-Mar",
    names: ["PMID28394193-Compound-35"],
  },
  {
    primary_id: "D05MAT",
    names: [
      "1-Adamantan-1-Yl-3-(3-Hydroxypropyl)Urea",
      "CHEMBL242592",
      "SMR000144635",
      "AC1M3CD5",
      "MLS000538598",
      "MolPort-001-628-514",
      "HMS2462I06",
      "ZINC2882943",
      "1-(3-Hydroxypropyl)-3-Tricyclo[3.3.1.1~3,7~]dec-1-Ylurea",
      "STK207014",
      "BDBM50223385",
      "AKOS016321670",
      "AKOS003366277",
      "AKOS002318484",
      "MCULE-4878985284",
      "N-1-Adamantyl-N'-(3-Hydroxypropyl)Urea",
      "1-(1-Adamantyl)-3-(3-Hydroxypropyl)Urea",
      "ST50766260",
    ],
  },
  {
    primary_id: "D05MBE",
    names: ["NK-102"],
  },
  {
    primary_id: "D05MBG",
    names: ["Vaccine, Chagas Disease"],
  },
  {
    primary_id: "D05MBJ",
    names: ["1-(4-Acetyloxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL461543", "SCHEMBL2162586"],
  },
  {
    primary_id: "D05MBZ",
    names: ["Tamsulosin"],
  },
  {
    primary_id: "D05MCK",
    names: [
      "7-Chloro-2-Phenyl-[1,8]naphthyridin-4-Ol",
      "7-Chloro-4-Hydroxy-2-Phenyl-1,8-Naphthyridine",
      "CHEMBL97760",
      "286411-09-4",
      "NCGC00015250-01",
      "Lopac-C-5982",
      "AC1O0Y6J",
      "Lopac0_000305",
      "SCHEMBL378998",
      "CTK8F7271",
      "CTK8E5517",
      "HMS3260N12",
      "ZINC8582290",
      "Tox21_500305",
      "BDBM50090697",
      "AKOS028113015",
      "TRA0054236",
      "LP00305",
      "CCG-204400",
      "NCGC00093754-02",
      "NCGC00015250-03",
      "NCGC00260990-01",
      "NCGC00093754-01",
      "NCGC00015250-05",
      "NCGC00015250-02",
      "NCGC00015250-04",
      "7-Chloro-2-Phenyl-1,8-Naphthyridin-4-Ol",
      "TX-017366",
      "KB-270267",
      "2-Phenyl-7-Chloro-1,8-Naphthyridine-4-Ol",
    ],
  },
  {
    primary_id: "D05MCS",
    names: [
      "GSK2190915",
      "1093403-33-8",
      "Sirtuin Modulator",
      "SRT 2104",
      "SRT2104 (GSK2245840)",
      "SRT2104",
      "SRT-2104",
      "UNII-4521NR0J09",
      "4521NR0J09",
      "5-Thiazolecarboxamide, 4-Methyl-N-[2-[3-(4-Morpholinylmethyl)Imidazo[2,1-B]thiazol-6-Yl]phenyl]-2-(3-Pyridinyl)-",
      "C26H24N6O2S2",
      "4-Methyl-N-(2-{3-[(Morpholin-4-Yl)Methyl]imidazo[2,1-B][1,3]thiazol-6-Yl}phenyl)-2-(Pyridin-3-Yl)-1,3-Thiazole-5-Carboxamide",
      "4-Methyl-N-(2-(3-(Morpholinomethyl)Imidazo[2,1-B]thiazol-6-Yl)Phenyl)-2-(Pyridin-3-Yl)Thiazole-5-Carboxamide",
    ],
  },
  {
    primary_id: "D05MDB",
    names: ["N,N-Dimethyl-2,2,2-Triphenylacetamide"],
  },
  {
    primary_id: "D05MDE",
    names: ["Cbz-Ile-Pro-Ala-LeuVSMe", "CHEMBL207670"],
  },
  {
    primary_id: "D05MDJ",
    names: ["ORG-34517", "11beta-(1,3-Benzodioxol-5-Yl)-17beta-Hydroxy-17-(1-Propynyl)Estra-4,9-Dien-3-One"],
  },
  {
    primary_id: "D05MDQ",
    names: [
      "CG-701338",
      "CG-701446",
      "CG-701448",
      "CG-9",
      "GSK-3 Inhibitors (Cancer), CrystalGenomics",
      "GSK-3 Inhibitor (Cancer/Schizophrenia/Alzheimers Disease)",
      "GSK-3 Inhibitor (Cancer/Schizophrenia/Alzheimers Disease), CrystalGenomics",
    ],
  },
  {
    primary_id: "D05MDY",
    names: [
      "2,8-Dimethyl-1-Oxa-8-Aza-Spiro[4.5]decan-3-One",
      "2,8-Dimethyl-1-Oxa-8-Azaspiro[4.5]decan-3-One",
      "CHEMBL40121",
      "123319-03-9",
      "ARL-14995",
      "SCHEMBL1326512",
      "1-Oxa-8-Azaspiro[4.5]decan-3-One, 2,8-Dimethyl-",
      "MolPort-039-242-339",
      "OODDNJQTVFMGCF-UHFFFAOYSA-N",
      "BDBM50034651",
      "AKOS033633463",
      "2,8-Dimethyl-1-Oxa-8-Azaspiro[4,5]decan-3-One",
      "L010200",
      "2,8-Dimethyl-1-Oxa-8-Azaspiro[4.5]-Decan-3-One",
      "2,8-Dimethyl-1-Oxa-8-Azaspiro[4,5]-Decan-3-One",
    ],
  },
  {
    primary_id: "D05MEL",
    names: ["PV-705"],
  },
  {
    primary_id: "D05MEP",
    names: ["AE-O"],
  },
  {
    primary_id: "D05MEW",
    names: [
      "N-(1-Benzylpiperidin-4-Yl)-3,5-Dimethoxybenzamide",
      "CHEMBL214684",
      "MolPort-008-305-779",
      "STK491511",
      "BDBM50192158",
      "ZINC16603986",
      "AKOS003382300",
      "MCULE-7382461624",
      "ST50942897",
    ],
  },
  {
    primary_id: "D05MEY",
    names: ["Epitinib"],
  },
  {
    primary_id: "D05MFA",
    names: ["Valacyclovir Hydrochloride"],
  },
  {
    primary_id: "D05MFG",
    names: ["Spirapril"],
  },
  {
    primary_id: "D05MFO",
    names: ["MDL-11939"],
  },
  {
    primary_id: "D05MGQ",
    names: ["MDX-1342", "Anti-CD19 (Cancer), MDX/BMS", "Anti-CD19 Antibody (Cancer), Medarex/BMS"],
  },
  {
    primary_id: "D05MHI",
    names: ["AC-607"],
  },
  {
    primary_id: "D05MHL",
    names: ["Atropisomer 1", "CHEMBL584052", "Atropisomer 2", "SCHEMBL2601123", "BDBM50300196"],
  },
  {
    primary_id: "D05MIG",
    names: ["PMID25726713-Compound-31"],
  },
  {
    primary_id: "D05MIL",
    names: ["Iopentol"],
  },
  {
    primary_id: "D05MIQ",
    names: ["O-SPC/RBRU Vaccine"],
  },
  {
    primary_id: "D05MIV",
    names: ["SB 202474", "AG-J-84442"],
  },
  {
    primary_id: "D05MJG",
    names: ["E[c(RGDyK)]2", "CHEMBL414385"],
  },
  {
    primary_id: "D05MJO",
    names: [
      "2-(3-Methyl-Piperazin-1-Yl)-6-Nitro-Quinoline",
      "CHEMBL43526",
      "Quinoline,2-(3-Methyl-1-Piperazinyl)-6-Nitro-",
      "SCHEMBL6365197",
      "WIRIBEHWECMIRC-UHFFFAOYSA-N",
      "BDBM50090211",
      "AKOS011768382",
      "2-(3-Methylpiperazino)-6-Nitroquinoline",
      "296759-20-1",
    ],
  },
  {
    primary_id: "D05MJS",
    names: ["PRO22 Topical Gel"],
  },
  {
    primary_id: "D05MJU",
    names: [
      "4-Iodobenzo[b]thiophene 2-Carboxamidine",
      "Amino(4-Iodo-1-Benzothiophen-2-Yl)Methaniminium",
      "ESI",
      "AC1L1C5C",
      "CTK7C3490",
      "BDBM14169",
      "APC-6860",
      "CRA-6860",
      "DB03136",
      "[amino-(4-Iodo-1-Benzothiophen-2-Yl)Methylidene]azanium",
    ],
  },
  {
    primary_id: "D05MKA",
    names: ["Lixisenatide"],
  },
  {
    primary_id: "D05MKG",
    names: ["Nalmefene Prodrugs"],
  },
  {
    primary_id: "D05MKN",
    names: ["Azetidine Urea Derivative 3"],
  },
  {
    primary_id: "D05MLT",
    names: ["2-(4-Aminophenoxy)-5-Hexylphenol", "CHEMBL264175"],
  },
  {
    primary_id: "D05MLX",
    names: [
      "ONYX-411",
      "Anticancer Adenoviruses, Onyx",
      "Armed Virus Therapy, Onyx",
      "ONYX-201",
      "ONYX-203",
      "ONYX-443",
      "ONYX-740",
      "ONYX-741",
      "ONYX-742",
      "ONYX-838",
      "RB Virus, Onyx",
      "E1A CR1-And CR2-Deleted Adenoviruses, ONYX",
    ],
  },
  {
    primary_id: "D05MNV",
    names: ["Fosclin"],
  },
  {
    primary_id: "D05MNW",
    names: ["Virginiamycin"],
  },
  {
    primary_id: "D05MOZ",
    names: ["G-202"],
  },
  {
    primary_id: "D05MPS",
    names: ["MYOSEVERIN"],
  },
  {
    primary_id: "D05MPX",
    names: [
      "Bornaprine",
      "20448-86-6",
      "KR 339 [German]",
      "Kr 339",
      "Bornaprinum [INN-Latin]",
      "Bornaprino [INN-Spanish]",
      "Sormodren",
      "Bornaprine [BAN:INN]",
      "Bornaprin",
      "2-Phenyl-2-(Gamma-Diethylaminocarbopropoxy)-Bicyclo(2.2.1)Heptane",
      "2-Norbornanecarboxylic Acid, 2-Phenyl-, 3-(Diethylamino)Propyl Ester",
      "2-Phenylbicyclo(2.2.1)Heptane-2-Carboxylic Acid, 3-(Diethylamino)Propyl Ester",
      "2-Phenylbicyclo(2.2.1)Heptan-2-Carbonsaeure-(Gamma-Diaethylaminopropyl) Ester [German]",
      "Bornaprine (INN)",
      "AC1L1IXI",
      "3-(Diethylamino)Propyl 2-Phenyl-2-Norbornanecarboxy",
    ],
  },
  {
    primary_id: "D05MQE",
    names: [
      "3-[4-Chloro-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 25b",
      "CHEMBL369623",
      "SCHEMBL4231092",
      "BDBM8609",
      "AC1O705L",
      "3-[(Z)-(4-Chloro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D05MQK",
    names: [
      "Tadalafil",
      "ADCIRCA",
      "Acdirca",
      "Cialis",
      "Tadanafil",
      "Lilly Brand Of Tadalafil",
      "Tadalafil [USAN]",
      "GF 196960",
      "IC 351",
      "ICOS 351",
      "Ic351",
      "Cialis (TN)",
      "GF-196960",
      "IC-351",
      "KS-1117",
      "Tadalafil (JAN/USAN/INN)",
      "Cialis, GF 196960, IC 351, ICOS 351, Tadalafil",
      "(6R,12aR)-2,3,6,7,12,12a-Hexahydro-2-Methyl-6-(3,4-(Methylenedioxy)Phenyl) Pyrazino(1',2':1,6)Pyrido(3,4-B)Indole-1,4-Dione",
      "(6R,12aR)-2,3,6,7,12,12a-Hexahydro-2-Methyl-6-(3,4-Methylenedioxyphenyl)Pyrazino(1',2':1,6)Pyrido(3,4-B)Indole-1,4-Dione",
      "(6R-Trans)-6-(1,3-Benzodioxol-5-Yl)-2,3,6,7,12,12a-Hexahydro-2-Methyl-Pyrazino(1',2':1,6)Pyrido(3,4-B)Indole-1,4-Dione",
    ],
  },
  {
    primary_id: "D05MQN",
    names: ["Fluoroquinolones"],
  },
  {
    primary_id: "D05MQO",
    names: ["BEA-005"],
  },
  {
    primary_id: "D05MQU",
    names: ["N-{4'-[(Trifluoromethyl)Sulfonyl]-4-Biphenyl}urea", "CHEMBL389588", "SCHEMBL5434550", "BDBM50220177"],
  },
  {
    primary_id: "D05MRY",
    names: ["Six-Membered Heterocyclic Benzamide Derivative 7"],
  },
  {
    primary_id: "D05MSG",
    names: [
      "BMS-694153",
      "UNII-69MB5VHL5J",
      "69MB5VHL5J",
      "CHEMBL450668",
      "(R)-4-(8-Fluoro-2-Oxo-1,2-Dihydroquinazolin-3(4H)-Yl)-N-(3-(7-Methyl-1H-Indazol-5-Yl)-1-Oxo-1-(4-(Piperidin-1-Yl)Piperidin-1-Yl)Propan-2-Yl)Piperidine-1-Carboxamide",
      "BMS 694153",
      "1050381-35-5",
      "BDBM50268484",
      "1-Piperidinecarboxamide, N-((1R)-2-(1,4'-Bipiperidin)-1'-Yl-1-((7-Methyl-1H-Indazol-5-Yl)Methyl)-2-Oxoethyl)-4-(8-Fluoro-1,4-Dihydro-2-Oxo-3(2H)-Quinazolinyl)-",
    ],
  },
  {
    primary_id: "D05MSL",
    names: [
      "DC-756",
      "(3S,4S)-7-[3-Amino-4-(Fluoromethyl)Pyrrolidin-1-Yl]-1-Cyclopropyl-6-Fluoro-8-Methoxy-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05MSM",
    names: [
      "CK-37",
      "Choline Kinase Inhibitors (Cancer)",
      "Choline Kinase Inhibitors (Cancer), Advanced Cancer Therapeutics",
      "Choline Kinase Inhibitors (Cancer), University Of Louisville/Advanced Cancer Therapeutics",
    ],
  },
  {
    primary_id: "D05MST",
    names: ["PMID30185082-Compound-27"],
  },
  {
    primary_id: "D05MTA",
    names: [
      "1-Adamantan-1-Yl-3-(4-Pentyloxycylclohexyl)Urea",
      "CHEMBL439577",
      "CHEMBL2031926",
      "1-(1-Adamantyl)-3-(4-Pentoxycyclohexyl)Urea",
      "SCHEMBL3178050",
      "BDBM50383478",
    ],
  },
  {
    primary_id: "D05MTD",
    names: ["Pro-Lys-Pro-Gln-Gln-Phe-Phe-Gly-Leu-Met-NH2", "CHEMBL238168", "53749-61-4", "Substance P(2-11)"],
  },
  {
    primary_id: "D05MTY",
    names: ["H2L5186303"],
  },
  {
    primary_id: "D05MTZ",
    names: ["PMID26882240-Compound-7"],
  },
  {
    primary_id: "D05MUA",
    names: ["NOX 2149"],
  },
  {
    primary_id: "D05MUN",
    names: ["1,1,1,2,2-Pentafluoro-9-Phenyl-Nonan-3-One"],
  },
  {
    primary_id: "D05MVA",
    names: ["RGD-891"],
  },
  {
    primary_id: "D05MVF",
    names: ["Peptide Analog 34"],
  },
  {
    primary_id: "D05MVX",
    names: ["ATA520"],
  },
  {
    primary_id: "D05MWB",
    names: ["UR-10324", "136408-45-2"],
  },
  {
    primary_id: "D05MWY",
    names: ["RF-1051"],
  },
  {
    primary_id: "D05MXI",
    names: ["PPTN", "4-((Piperindin-4-Yl)-Phenyl)-(7-(4-(Trifluoromethyl)-Phenyl)-2-Naphthoic Acid"],
  },
  {
    primary_id: "D05MXJ",
    names: ["Thrombopoietin Antagonist"],
  },
  {
    primary_id: "D05MXK",
    names: ["GW-473178", "473178"],
  },
  {
    primary_id: "D05MXL",
    names: ["CHDI-003940246"],
  },
  {
    primary_id: "D05MXM",
    names: [
      "3-(Heptyloxy)Benzoic Acid",
      "3-(Heptyloxy)Benzoate",
      "3-Heptyloxybenzoate",
      "3-Heptyloxybenzoic Acid",
      "3-N-Heptyloxybenzoic Acid",
      "SCHEMBL7047692",
      "CHEMBL1161949",
      "FOFZVIUYGPBWLV-UHFFFAOYSA-N",
      "ZINC14980578",
      "AKOS005288423",
      "AS01084",
      "DB07355",
    ],
  },
  {
    primary_id: "D05MXO",
    names: ["19(R)-OH-PGE2", "19(R)-Hydroxy-PGE2", "19R-(OH)-PGE2"],
  },
  {
    primary_id: "D05MYH",
    names: ["KYS-05065"],
  },
  {
    primary_id: "D05MYL",
    names: ["PMID25399762-Compound-Figure1-Sarcaboside B"],
  },
  {
    primary_id: "D05MZI",
    names: ["Nusinersen", "BIIB 058", "IONIS-SMNRx", "ISIS-396443", "Spinraza"],
  },
  {
    primary_id: "D05NAR",
    names: [
      "Allyl 4-(Aminosulfonyl)Benzoate",
      "CHEMBL381455",
      "103204-31-5",
      "Benzoic Acid, 4-(Aminosulfonyl)-, 2-Propenyl Ester",
      "ACMC-20m632",
      "SCHEMBL6705334",
      "CTK0G7183",
      "DTXSID90468211",
      "VWKMDRWBZTWKNM-UHFFFAOYSA-N",
      "BDBM50175049",
      "4-Sulfamoyl-Benzoic Acid Allyl Ester",
    ],
  },
  {
    primary_id: "D05NBB",
    names: ["Pyrrolo-Pyridinone Derivative 3"],
  },
  {
    primary_id: "D05NBD",
    names: ["MONTIRELIN TETRAHYDRATE"],
  },
  {
    primary_id: "D05NBK",
    names: ["1-Aminomethyl-3-Cyclohexyl-Isochroman-5,6-Diol", "CHEMBL100572", "SCHEMBL10380336", "BDBM50008832"],
  },
  {
    primary_id: "D05NBR",
    names: ["PMID28270010-Compound-Figure16-A"],
  },
  {
    primary_id: "D05NBU",
    names: [
      "2-Benzylsulfanyl-6-Chloro-Pyrimidin-4-Ylamine",
      "4-Amino-2-(Benzylthio)-6-Chloropyrimidine",
      "99983-92-3",
      "CHEMBL124578",
      "U-31,355",
      "2-Benzylmercapto-4-Amino-6-Chloropyrimidine",
      "2-(Benzylthio)-6-Chloropyrimidin-4-Amine",
      "AC1Q3RY2",
      "AC1L3VC9",
      "U 31355",
      "SCHEMBL489860",
      "4-Pyrimidinamine, 6-Chloro-2-((Phenylmethyl)Thio)-",
      "U 31,355",
      "CTK5I0853",
      "ZINC21246",
      "DTXSID20244368",
      "LGTXUPUULSWTNA-UHFFFAOYSA-N",
      "BDBM50066933",
      "AKOS030548304",
      "KB-239927",
      "2-Benzylsulfanyl-6-Chloropyrimidin-4-Amine",
      "2-Benzylsulfanyl-6-Chloro-Pyrimidin-4-Amine",
    ],
  },
  {
    primary_id: "D05NCT",
    names: ["DPC-1528"],
  },
  {
    primary_id: "D05NDN",
    names: [
      "Alovudine",
      "25526-93-6",
      "FddT",
      "3'-DEOXY-3'-FLUOROTHYMIDINE",
      "FddThD",
      "3'-Fluoro-3'-Deoxythymidine",
      "Thymidine, 3'-Deoxy-3'-Fluoro-",
      "3'F-TdR",
      "3'-FddT",
      "Alovudine [USAN:INN]",
      "UNII-PG53R0DWDQ",
      "3'-Fluorothymidine",
      "3'-FLT",
      "DRG-0097",
      "PG53R0DWDQ",
      "NSC 140025",
      "BRN 0754299",
      "3'-Fluorodeoxythymidine",
      "CL 184824",
      "CHEMBL105318",
      "FLT",
      "1-((2R,4S,5R)-4-Fluoro-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl)-5-Methylpyrimidine-2,4(1H,3H)-Dione",
      "C10H13FN2O4",
      "MIV-310",
      "1-(3'-Deoxy-3'-Fluoro-Beta-D-Pentofuranosyl)Thymine",
      "CL-184824",
      "DSSTox_RID_81738",
      "DSSTox_CID_26579",
    ],
  },
  {
    primary_id: "D05NDR",
    names: [
      "Sifalimumab",
      "Cci 779",
      "CHEBI:79699",
      "Torisel (TN)",
      "42-[3-Hydroxy-2-(Hydroxymethyl)-2-Methylpropanoate]rapamycin",
      "Temsirolimus (JAN/USAN/INN)",
      "162635-04-3",
      "DB06287",
      "C15182",
      "D06068",
      "J-524319",
      "(1R,2R,4S)-4-[(2R)-2-[(1R,9S,12S,15R,18R,19R,21R,23S,30S,32S,35R)-1,18-Dihydroxy-19,30-Dimethoxy-15,17,21,23,29,35-Hexamethyl-2,3,10,14,20-Pentaoxo-11,36-Dioxa-4-Azatricyclo[30.3.1.0",
      "{4,9}]hexatriaconta-16,24,26,28-Tetraen-12-Yl]propyl]-2-Methoxycyclohexyl 3-Hydroxy-2-(Hydroxymethyl)-2-Methylpropanoate",
    ],
  },
  {
    primary_id: "D05NDY",
    names: ["OAP-189"],
  },
  {
    primary_id: "D05NEC",
    names: ["PMID23639540C13r"],
  },
  {
    primary_id: "D05NED",
    names: ["D-190", "CHEMBL467767", "BDBM50261335"],
  },
  {
    primary_id: "D05NEG",
    names: ["N-Arachidonyl-Glycine"],
  },
  {
    primary_id: "D05NEM",
    names: [
      "1-Phenyl-3-(2-(Pyridin-3-Yl)Quinazolin-4-Yl)Urea",
      "CHEMBL305512",
      "BDBM50088462",
      "1-[2-(3-Pyridyl)-4-Quinazolinyl]-3-Phenylurea",
    ],
  },
  {
    primary_id: "D05NEN",
    names: ["RNA Synthesis Inhibitors"],
  },
  {
    primary_id: "D05NET",
    names: ["PD-137821"],
  },
  {
    primary_id: "D05NFR",
    names: ["TPX-0005"],
  },
  {
    primary_id: "D05NFU",
    names: [
      "4-Butylphenylboronic Acid",
      "145240-28-4",
      "(4-Butylphenyl)Boronic Acid",
      "4-N-Butylphenylboronic Acid",
      "4-Butylphenylboronicacid",
      "4-Butylbenzeneboronic Acid",
      "4-N-BUTYLBENZENEBORONIC ACID",
      "(4-Butylphenyl)Boranediol",
      "CHEMBL555064",
      "Boronic Acid, (4-Butylphenyl)-",
      "MFCD02093926",
      "AK-47631",
      "4-N-Butylphenylboronic Acid, 95%",
      "Boronic Acid, B-(4-Butylphenyl)-",
      "PubChem18523",
      "ACMC-209cue",
      "AC1N2RDA",
      "4-Butylphenylboranic Acid",
      "4-N-Butylphenylboronicacid",
      "4-Butylphenyl Boronic Acid",
      "4-Butyl-Phenylboronic Acid",
      "AC1Q2VE3",
      "4-Butyl Phenyl Boronic Acid",
    ],
  },
  {
    primary_id: "D05NHA",
    names: ["Resorcinol Compound 20"],
  },
  {
    primary_id: "D05NHR",
    names: ["FGGFTGARKSARKKRNQ"],
  },
  {
    primary_id: "D05NHT",
    names: ["BP-C1"],
  },
  {
    primary_id: "D05NIE",
    names: ["4-(2-(Aminomethyl)Phenyl)-1-Benzylpiperidin-4-Ol", "CHEMBL234581"],
  },
  {
    primary_id: "D05NIO",
    names: ["NP-17"],
  },
  {
    primary_id: "D05NIR",
    names: ["ALX-0061"],
  },
  {
    primary_id: "D05NJG",
    names: ["PH Sensitive Micelles"],
  },
  {
    primary_id: "D05NJI",
    names: [
      "STX 64",
      "BN-83495",
      "STX-64",
      "(6-Oxo-8,9,10,11-Tetrahydro-7H-Cyclohepta[c]chromen-3-Yl) Sulfamate",
      "6-OXO-8,9,10,11-TETRAHYDRO-7H-CYCLOHEPTA[C][1]BENZOPYRAN-3-O-SULFAMATE",
      "667-Coumate",
    ],
  },
  {
    primary_id: "D05NJL",
    names: ["ALSE-100"],
  },
  {
    primary_id: "D05NKG",
    names: ["Alsterpaullone 2-Cyanoethyl", "Alsterpaullone Derivative7"],
  },
  {
    primary_id: "D05NKX",
    names: ["1DMe", "[D.Tyr1,(N.Me)Phe3]NPFF", "[D-Tyr1,(NMe)Phe3]neuropeptide FF"],
  },
  {
    primary_id: "D05NLD",
    names: ["ARRY-300"],
  },
  {
    primary_id: "D05NLR",
    names: ["Adva-33c", "Difluoro-Carboplatin"],
  },
  {
    primary_id: "D05NMG",
    names: ["PMID27376512-Compound-CROs"],
  },
  {
    primary_id: "D05NMJ",
    names: ["Biphasic Porcine Insulin"],
  },
  {
    primary_id: "D05NMK",
    names: ["CU201 (B9870)"],
  },
  {
    primary_id: "D05NMU",
    names: ["DasKloster 0274-01"],
  },
  {
    primary_id: "D05NOG",
    names: [
      "Targeted Ranpirnase Conjugates",
      "AC RN-321",
      "RN-321",
      "RRNP-EGF",
      "Targeted Ranpirnase Conjugates (Cancer)",
      "Polyanhydride-Bound Onconase, Alfacell",
      "Polyanhydride-Bound Ranpirnase, Alfacell",
      "Targeted Onconase Conjugates (Cancer), Alfacell",
      "Targeted Ranpirnase Conjugates (Cancer), Alfacell",
      "Onconase-MAb Conjugate, Alfacell/NCI",
      "Ranpirnase-MAb Conjugate, Alfacell/NCI",
    ],
  },
  {
    primary_id: "D05NOS",
    names: [
      "Triflupromazine",
      "Adazine",
      "Fluopromazine",
      "Fluorofen",
      "Neoprin",
      "Nivoman",
      "Psyquil",
      "Siquil",
      "Trifluopromazine",
      "Triflupromazina",
      "Triflupromazinum;Vesprin",
      "Vetame",
      "Fluopromazine Monohydrochloride",
      "Trifluopromazine Hydrochloride",
      "Triflupromazine [INN]",
      "Triflupromazina [INN-Spanish]",
      "Triflupromazinum [INN-Latin]",
      "Vesprin (TN)",
      "Triflupromazine (USP/INN)",
      "N,N-Dimethyl-2-(Trifluoromethyl)-10H-Phenothiazine-10-Propanamine",
      "N,N-Dimethyl-3-[2-(Trifluoromethyl)Phenothiazin-10-Yl]propan-1-Amine",
      "N,N-Dimethyl-3-[2-(Trifluoromethyl)Phenothiazin-10-Yl]propan-1-Amine Hydrochloride",
      "N,N-Dimethyl-3-[2-(Trifluoromethyl)-10H-Phenothiazin-10-Yl]propan-1-Amine",
      "N,N-Dimethyl-3-(2-(Trifluoromethyl)-10H-Phenothiazin-10-Yl)-1-Propanamine",
      "10-(3-(Dimethylamino)Propyl)-2-(Trifluoromethyl)Phenothiazine",
      "10-3-(Dimethylamino)Propyl-2-(Trifluoromethyl)Phenothiazine",
      "2-(Trifluoromethyl)Promazine",
      "2-Trifluoromethyl-10-(Gamma-Dimethylaminopropyl)Phenothiazine",
    ],
  },
  {
    primary_id: "D05NPD",
    names: ["TYrosine Kinase 2 Inhibitor"],
  },
  {
    primary_id: "D05NQR",
    names: ["LyphoDerm"],
  },
  {
    primary_id: "D05NQX",
    names: ["PD-152255", "164917-23-1"],
  },
  {
    primary_id: "D05NQZ",
    names: ["Benzodihydroisofuran Derivative 3"],
  },
  {
    primary_id: "D05NRG",
    names: ["Isoflavone Derivative 8"],
  },
  {
    primary_id: "D05NRI",
    names: ["Ceronapril", "Ceranapril", "SQ-29852"],
  },
  {
    primary_id: "D05NRX",
    names: ["VG-102"],
  },
  {
    primary_id: "D05NRZ",
    names: ["N4-(N,N-DIPHENYLCARBAMOYL)-AMINOGUANIDINE"],
  },
  {
    primary_id: "D05NSB",
    names: [
      "Adamantan-2-Yl-Piperidin-1-Yl-Methanone",
      "CHEMBL245393",
      "MolPort-027-725-528",
      "BDBM50207799",
      "AKOS017280695",
    ],
  },
  {
    primary_id: "D05NSF",
    names: ["4SC-207", "Cell Cycle Inhibitors (Cancer), 4SC"],
  },
  {
    primary_id: "D05NSP",
    names: ["GNF-PF-4599"],
  },
  {
    primary_id: "D05NTO",
    names: [
      "6'-Iodononivamide",
      "859171-97-4",
      "CHEMBL177809",
      "Tocris-1975",
      "NCGC00025334-01",
      "AC1NP3A0",
      "CTK8E7732",
      "DTXSID70408796",
      "MolPort-003-983-501",
      "CHEBI:107654",
      "HMS3268B17",
      "ZINC8036052",
      "MFCD09878233",
      "BN0036",
      "BDBM50170053",
      "AKOS024456886",
      "RT-010669",
      "N-(4-Hydroxy-2-Iodo-5-Methoxybenzyl)Nonanamide",
      "N-(2-Iodo-4-Hydroxy-5-Methoxybenzyl)Nonanamide",
      "6-Iodonordihydrocapsaicin, &gt",
      "SR-01000597523",
      "SR-01000597523-1",
      "BRD-K49236613-001-01-5",
      "Nonanoic Acid 4-Hydroxy-2-Iodo-5-Methoxy-Benzylamide",
    ],
  },
  {
    primary_id: "D05NTQ",
    names: ["PRO-015"],
  },
  {
    primary_id: "D05NTV",
    names: ["(S)-TBPG"],
  },
  {
    primary_id: "D05NTY",
    names: ["NPC-18166"],
  },
  {
    primary_id: "D05NUC",
    names: ["SP4160"],
  },
  {
    primary_id: "D05NUH",
    names: ["Hm61713"],
  },
  {
    primary_id: "D05NUN",
    names: ["Pyrazole Derivative 36"],
  },
  {
    primary_id: "D05NUP",
    names: ["SBS-1000"],
  },
  {
    primary_id: "D05NUT",
    names: ["ALS-00T2-0501"],
  },
  {
    primary_id: "D05NWF",
    names: [
      "Adenosine Diphosphate",
      "58-64-0",
      "ADP",
      "Adenosine 5'-(Trihydrogen Diphosphate)",
      "Adenosine Pyrophosphate",
      "Adenosine 5'-Pyrophosphate",
      "ADP (Nucleotide)",
      "Adenosine-Diphosphate",
      "5'-Adenylphosphoric Acid",
      "5'-Adp",
      "Adenosindiphosphorsaeure",
      "Adenosine 5'-Pyrophosphoric Acid",
      "Adenosine-5'-Diphosphat",
      "Adenosine 5'-Diphosphoric Acid",
      "Adenosine Diphosphoric Acid",
      "Ado-5'-P-P",
      "UNII-61D2G4IYVH",
      "H3adp",
      "BRN 0067722",
      "CHEBI:16761",
      "EINECS 200-392-5",
      "61D2G4IYVH",
      "CHEMBL14830",
    ],
  },
  {
    primary_id: "D05NWH",
    names: ["SB 228357"],
  },
  {
    primary_id: "D05NWJ",
    names: ["SLV-337"],
  },
  {
    primary_id: "D05NWM",
    names: [
      "Pyraclostrobin",
      "175013-18-0",
      "Pyraclostrobine",
      "Methyl (2-(((1-(4-Chlorophenyl)-1H-Pyrazol-3-Yl)Oxy)Methyl)Phenyl)(Methoxy)Carbamate",
      "Headline",
      "Cabrio",
      "UNII-DJW8M9OX1H",
      "Pyraclostrobin [ISO:BSI]",
      "DJW8M9OX1H",
      "CHEBI:78780",
      "Pyrachlostrobin",
      "Methyl N-[2-[[1-(4-Chlorophenyl)Pyrazol-3-Yl]oxymethyl]phenyl]-N-Methoxy-Carbamate",
      "Methyl [2-({[1-(4-Chlorophenyl)-1H-Pyrazol-3-Yl]oxy}methyl)Phenyl]methoxycarbamate",
      "Methyl N-[2-[[1-(4-Chlorophenyl)Pyrazol-3-Yl]oxymethyl]phenyl]-N-Methoxycarbamate",
      "Stamina",
      "HSDB 7497",
      "F 500",
      "AC1O4YK4",
    ],
  },
  {
    primary_id: "D05NWQ",
    names: [
      "4-Chloro-N-(2,5-Dichlorophenyl)-3-Nitrobenzamide",
      "CHEMBL560605",
      "AC1LEF7J",
      "Oprea1_805046",
      "Oprea1_782408",
      "MolPort-001-927-690",
      "ZINC102805",
      "BDBM50297549",
      "STK201761",
      "AKOS000609413",
      "MCULE-5386572276",
      "2',4,5'-Trichloro-3-Nitrobenzanilide",
      "BAS 00368876",
      "91822-09-2",
      "ST011043",
      "AG-690/09694020",
      "4-Chloro-N-(2,5-Dichloro-Phenyl)-3-Nitro-Benzamide",
    ],
  },
  {
    primary_id: "D05NXQ",
    names: ["(S)-2-((4-Phenoxyphenoxy)Methyl)Piperidine", "CHEMBL1099182", "SCHEMBL13809079"],
  },
  {
    primary_id: "D05NXX",
    names: ["ML221"],
  },
  {
    primary_id: "D05NYF",
    names: ["HuMax-TF", "Anti-Tissue Factor Human Antibody (Cancer), Genmab"],
  },
  {
    primary_id: "D05NYK",
    names: ["BEL-0222"],
  },
  {
    primary_id: "D05NYO",
    names: ["Imab-027"],
  },
  {
    primary_id: "D05NYR",
    names: ["H-Dmt-Tic-(2R,3R)-Beta-MeCha-Phe-OH", "CHEMBL375094"],
  },
  {
    primary_id: "D05NZH",
    names: ["Methyl 4-(3-Cyclohexylureido)Butanoate"],
  },
  {
    primary_id: "D05NZI",
    names: ["BRL-26175", "CHEMBL122533", "BDBM50042732"],
  },
  {
    primary_id: "D05NZV",
    names: [
      "BRL 35135",
      "Brl35135A",
      "Methyl 4-(2-(2-Hydroxy-2-(3-Chlorophenyl)Ethylamino)Propyl)Phenoxyacetate",
      "Brl-35135",
      "91097-81-3",
      "C20H24ClNO4",
      "Acetic Acid, (4-(2-((2-(3-Chlorophenyl)-2-Hydroxyethyl)Amino)Propyl)Phenoxy)-, Methyl Ester",
      "AC1Q3M3M",
      "AC1L3U5A",
      "CHEMBL49289",
      "SCHEMBL4611391",
      "CTK5G8940",
      "ZFLBZHXQAMUEFS-UHFFFAOYSA-N",
      "LS-178136",
      "L003499",
      "Methyl 4-[2-[(3-Chloro-Beta-Hydroxyphenethyl) Amino]propyl]phenoxyacetate",
      "[4-[2-[[2-(3-Chlorophenyl)-2-Hydroxyethyl]amino]propyl]phenoxy]acetic Acid Methyl Ester",
      "Methyl 2-[4-[2-[[2-(3-Chloroph",
    ],
  },
  {
    primary_id: "D05OAE",
    names: ["(12E,20Z,18S)-8-Hydroxyvariabilin", "GTPL4072"],
  },
  {
    primary_id: "D05OAI",
    names: ["CART-19/22"],
  },
  {
    primary_id: "D05OAJ",
    names: ["AW-814141"],
  },
  {
    primary_id: "D05OBK",
    names: ["DwLIP-GCGRrx"],
  },
  {
    primary_id: "D05ODA",
    names: ["AP811", "AP-811", "AP 811"],
  },
  {
    primary_id: "D05ODE",
    names: ["CC-90001"],
  },
  {
    primary_id: "D05ODU",
    names: ["Belerofon"],
  },
  {
    primary_id: "D05ODW",
    names: ["MAACKIAIN"],
  },
  {
    primary_id: "D05OEG",
    names: [
      "E-2001",
      "E-2001 Free",
      "Ethanone, 2-[4-(4-Fluorobenzoyl)-1-Piperidinyl]-1-(2-Naphthalenyl)-, Hydrochloride (1:1)",
      "107025-81-0",
      "AC1L2W18",
      "SCHEMBL10339587",
      "WCHMXQYBLWIZCL-UHFFFAOYSA-N",
      "2-[4-(P-Fluorobenzoyl)-1-Piperidinyl]-2'-Acetonaphthone Hydrochloride",
      "Ethanone, 2-(4-(4-Fluorobenzoyl)-1-Piperidinyl)-1-(2-Naphthalenyl)-, Hydrochlorde",
      "2-[4-(4-Fluorobenzoyl)Piperidin-1-Yl]-1-Naphthalen-2-Ylethanone Hydrochloride",
    ],
  },
  {
    primary_id: "D05OEO",
    names: ["ET-1"],
  },
  {
    primary_id: "D05OEU",
    names: ["Technetium Tc-99m Succimer Kit"],
  },
  {
    primary_id: "D05OEV",
    names: ["Phenamil"],
  },
  {
    primary_id: "D05OEZ",
    names: ["Hexahydro Azepinone Derivative 1"],
  },
  {
    primary_id: "D05OFK",
    names: ["Vaccine, CMV Infection"],
  },
  {
    primary_id: "D05OFO",
    names: ["TC-2696"],
  },
  {
    primary_id: "D05OFR",
    names: ["N-(5-Methylthiazol-2-Yl)-2-Phenylacetamide"],
  },
  {
    primary_id: "D05OFX",
    names: ["Lacosamide"],
  },
  {
    primary_id: "D05OHF",
    names: [
      "(R)-(+)-Coclaurine",
      "(R)-Coclaurine",
      "D-Coclaurine",
      "2196-60-3",
      "Sanjoinine K",
      "CHEMBL256448",
      "CHEBI:27482",
      "(1R)-1-(4-Hydroxybenzyl)-6-Methoxy-1,2,3,4-Tetrahydroisoquinolin-7-Ol",
      "6-Methoxy-7-Hydroxy-(1R)-[(4-Hydroxyphenyl)Methyl]-1,2,3,4-Tetrahydroisoquinoline",
      "(1R)-1-[(4-Hydroxyphenyl)Methyl]-6-Methoxy-1,2,3,4-Tetrahydroisoquinolin-7-Ol",
      "7-Isoquinolinol, 1,2,3,4-Tetrahydro-1-((4-Hydroxyphenyl)Methyl)-6-Methoxy-, (R)-",
      "(R)-1,2,3,4-Tetrahydro-1-[(4-Hydroxyphenyl)Methyl]-6-Methoxy-7-Isoquinolinol",
      "(R)-D-Coclaurine",
    ],
  },
  {
    primary_id: "D05OHM",
    names: ["PMID9216829C5j"],
  },
  {
    primary_id: "D05OHN",
    names: ["4-Isopropyl-2-(Phenoxymethyl)Morpholine", "CHEMBL510654", "BDBM50293194"],
  },
  {
    primary_id: "D05OHW",
    names: ["TVX-008"],
  },
  {
    primary_id: "D05OHY",
    names: ["CAR-T Cells Targeting EphA2"],
  },
  {
    primary_id: "D05OIF",
    names: [
      "N-Ethylhistamine",
      "479408-49-6",
      "2-(1-Ethyl-1h-Imidazol-4-Yl)Ethanamine",
      "1-ETHYL-1H-IMIDAZOLE-4-ETHANAMINE",
      "2-(1-Ethyl-1H-Imidazol-4-Yl)Ethan-1-Amine",
      "2-(1-Ethyl-1H-Imidazol-4-Yl)Ethylamine",
      "CHEMBL46296",
      "SCHEMBL589342",
      "GTPL1271",
      "CTK4J0489",
      "DTXSID90658170",
      "LIXGKSRWGHVUHT-UHFFFAOYSA-N",
      "ZINC29412524",
      "2-(1-Ethylimidazol-4-Yl)Ethanamine",
      "AKOS006337816",
    ],
  },
  {
    primary_id: "D05OIL",
    names: ["Protein-Silencing Drugs"],
  },
  {
    primary_id: "D05OIO",
    names: ["Granexin Gel"],
  },
  {
    primary_id: "D05OIS",
    names: ["Benzyl Alcohol"],
  },
  {
    primary_id: "D05OIU",
    names: ["PMID27998201-Compound-5"],
  },
  {
    primary_id: "D05OJY",
    names: ["Lobatamide A"],
  },
  {
    primary_id: "D05OKZ",
    names: ["Sulfonamide Derivative 16"],
  },
  {
    primary_id: "D05OLW",
    names: ["PMID21692504C8"],
  },
  {
    primary_id: "D05OML",
    names: [
      "5-(3-Hydroxyphenyl)-1,3,4-Oxadiazole-2(3H)-Thione",
      "3-(5-Mercapto-[1,3,4]oxadiazol-2-Yl)-Phenol",
      "3-(5-Mercapto-1,3,4-Oxadiazol-2-Yl)Phenol",
      "299465-12-6",
      "3-(5-Sulfanyl-1,3,4-Oxadiazol-2-Yl)Phenol",
      "CHEMBL595356",
      "3-(5-Mercapto-1,3,4-Oxadiazol-2-Yl)-Phenol",
      "AO-365/08835025",
      "5-[3-Hydroxyphenyl]-1,3,4-Oxadiazole-2(3H)-Thione",
      "NSC524085",
      "AC1LF1GV",
      "Oprea1_836042",
      "CTK8F4715",
      "CTK8A0072",
      "MolPort-000-932-334",
      "ZX-AN016552",
      "ZINC1605604",
      "BB_SC-06098",
      "CCG-15357",
      "STK506749",
      "FCH842308",
      "BDBM50320729",
      "BBL000571",
      "AKOS000564154",
      "FCH2245445",
      "NSC-524085",
    ],
  },
  {
    primary_id: "D05OMO",
    names: ["PMID26651364-Compound-11b"],
  },
  {
    primary_id: "D05OMR",
    names: ["Methyl 9H-Carbazole-2-Carboxylate"],
  },
  {
    primary_id: "D05OMT",
    names: ["MK-8033"],
  },
  {
    primary_id: "D05ONA",
    names: ["Cyt-1010"],
  },
  {
    primary_id: "D05ONZ",
    names: ["PMID17975902C5e"],
  },
  {
    primary_id: "D05OOA",
    names: ["6-Ethyl-3-Pentoxycarbonyl-4-Quinolone", "CHEMBL205252"],
  },
  {
    primary_id: "D05OPF",
    names: [
      "4-Nitro-N-(Quinolin-8-Yl)Benzenesulfonamide",
      "CHEMBL540966",
      "33757-62-9",
      "AC1NAKSM",
      "SCHEMBL13485922",
      "CTK1B1556",
      "DTXSID90403445",
      "MolPort-007-041-512",
      "ZINC5681580",
      "BDBM50372514",
      "AKOS024374275",
      "MCULE-8526334160",
      "NCGC00161718-01",
      "4-Nitro-N-Quinolin-8-Ylbenzenesulfonamide",
      "N-(8-Quinolyl)-4-Nitrobenzenesulfonamide",
      "4-Nitro-N-(8-Quinolyl)Benzenesulfonamide",
      "[(4-Nitrophenyl)Sulfonyl]-8-Quinolylamine",
      "ST51013795",
      "Benzenesulfonamide, 4-Nitro-N-8-Quinolinyl-",
      "4-Nitro-N-(Quinolin-8-Yl)Benzene-1-Sulfonamide",
      "CU-00000000141-1",
    ],
  },
  {
    primary_id: "D05OPY",
    names: [
      "(3-Phenyl-Propyl)-Quinazolin-4-Yl-Amine",
      "CHEMBL42565",
      "N-(3-Phenylpropyl)Quinazolin-4-Amine",
      "MolPort-011-928-362",
      "BDBM50282106",
      "ZINC13476428",
      "AKOS009045718",
      "MCULE-1024483265",
      "Z127145812",
    ],
  },
  {
    primary_id: "D05OQF",
    names: ["N-(3,5-Dichlorophenyl)-2-Nitrobenzamide"],
  },
  {
    primary_id: "D05OQG",
    names: ["ICARIIN"],
  },
  {
    primary_id: "D05OQJ",
    names: [
      "Methyprylon",
      "Dimerin",
      "Methprylon",
      "Methyprolon",
      "Methyprylone",
      "Methyprylonum",
      "Metiprilon",
      "Metiprilona",
      "Metiprilone",
      "Noctan",
      "Nodular",
      "Noludar",
      "Methyprylon [INN]",
      "Methyprylone [INN-French]",
      "Methyprylonum [INN-Latin]",
      "Metiprilona [INN-Spanish]",
      "Noludar (TN)",
      "Ro 1-6463",
      "Methyprylon (JAN/INN)",
      "2,4-Dioxo-3,3-Diethyl-5-Methylpiperidine",
      "2,4-Dioxy-3,3-Diethyl-5-Methylpiperidine",
      "3,3-Diethyl-2,4-Dioxo-5-Methylpiperidine",
      "3,3-Diethyl-5-Methyl-2,4-Piperidinedione",
      "3,3-Diethyl-5-Methylpiperidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D05OQX",
    names: ["NCX-4016"],
  },
  {
    primary_id: "D05ORA",
    names: ["MOR-103"],
  },
  {
    primary_id: "D05ORI",
    names: ["AGI-350"],
  },
  {
    primary_id: "D05ORO",
    names: ["Anti-N3pG-Abeta Antibody"],
  },
  {
    primary_id: "D05OSR",
    names: ["Human Immune Globulin"],
  },
  {
    primary_id: "D05OTD",
    names: ["Heterocyclic Derivative 4"],
  },
  {
    primary_id: "D05OTL",
    names: ["NW-3509"],
  },
  {
    primary_id: "D05OTR",
    names: [
      "(S)-N2-[4-(Benzyloxy)Benzyl]alaninamide",
      "CHEMBL352004",
      "SCHEMBL4456855",
      "BDBM50221295",
      "(S)-2-(4-Benzyloxy-Benzylamino)-Propionamide",
    ],
  },
  {
    primary_id: "D05OTZ",
    names: ["SMP-986", "Afacifenacin"],
  },
  {
    primary_id: "D05OUG",
    names: [
      "1-Amino-2-Phenoxyanthracene-9,10-Dione",
      "CHEMBL601856",
      "AC1MDASQ",
      "1-Amino-2-Phenoxy-Anthraquinone",
      "MolPort-001-914-416",
      "ZINC3878658",
      "BDBM50306773",
      "AKOS000668569",
      "MCULE-3398092458",
      "BAS 00096273",
    ],
  },
  {
    primary_id: "D05OUI",
    names: [
      "Anti-Aging Glycopeptides",
      "AAGP (Inflammation), ProtoKinetix",
      "Anti-Aging Glycopeptides (Inflammation)",
      "Anti-Aging Glycopeptides (Inflammation), ProtoKinetix",
    ],
  },
  {
    primary_id: "D05OUP",
    names: ["1-Propyl-5'-O-Tritylinosine", "CHEMBL213890", "1-Propyl-5''-O-Tritylinosine"],
  },
  {
    primary_id: "D05OUQ",
    names: ["CCK-33", "CCRIS 3307", "Cholecystokinin 33"],
  },
  {
    primary_id: "D05OUY",
    names: ["(R)-2-(4-Isobutyl-Phenyl)-Propionamide"],
  },
  {
    primary_id: "D05OVC",
    names: [
      "Atipamezole",
      "104054-27-5",
      "Antisedan",
      "MPV-1248",
      "MPV 1248",
      "4-(2-Ethyl-2-Indanyl)Imidazole",
      "4-(2-Ethyl-2,3-Dihydro-1h-Inden-2-Yl)-1h-Imidazole",
      "Atipamezolum [Latin]",
      "Atipamezol [Spanish]",
      "UNII-03N9U5JAF6",
      "1H-Imidazole, 4-(2-Ethyl-2,3-Dihydro-1H-Inden-2-Yl)-",
      "03N9U5JAF6",
      "5-(2-Ethyl-2,3-Dihydro-1H-Inden-2-Yl)-1H-Imidazole",
      "NCGC00182707-01",
      "Zeolite, Type: ZSM-5",
      "DSSTox_CID_29061",
      "DSSTox_RID_83274",
      "DSSTox_GSID_49135",
      "5-(2-Ethyl-1,3-Dihydroinden-2-Yl)-1H-Imidazole",
      "Antipamezole",
      "Atipamezolum",
      "Atipamezol",
      "C14H16N2",
    ],
  },
  {
    primary_id: "D05OVR",
    names: ["ALP-242", "Antileukoproteinases, Grunenthal"],
  },
  {
    primary_id: "D05OVV",
    names: ["ST-2741"],
  },
  {
    primary_id: "D05OWB",
    names: ["Mab-216"],
  },
  {
    primary_id: "D05OXR",
    names: ["N-Phenyl-1-Methyl-1H-Indole-2-Carboxamide", "CHEMBL464333", "SCHEMBL6017856", "BDBM50273487"],
  },
  {
    primary_id: "D05OXZ",
    names: ["BIWA 4"],
  },
  {
    primary_id: "D05OZG",
    names: ["BTSCAN"],
  },
  {
    primary_id: "D05OZQ",
    names: ["Efpeglenatide"],
  },
  {
    primary_id: "D05OZR",
    names: [
      "4-Aminobenzoic Acid Hydrazide",
      "4-Aminobenzohydrazide",
      "5351-17-7",
      "4-Aminobenzhydrazide",
      "Amben Hydrazide",
      "P-Aminobenzhydrazide",
      "Aminostimil",
      "P-Aminobenzoic Acid Hydrazide",
      "4-Aminobenzoylhydrazine",
      "P-Aminobenzoyl Hydrazide",
      "Benzoic Acid, 4-Amino-, Hydrazide",
      "P-Aminobenzoic Hydrazide",
      "P-Aminobenzoylhydrazine",
      "Para-Aminobenzhydrazide",
      "NSC 640",
      "BENZOIC ACID, P-AMINO-, HYDRAZIDE",
      "EINECS 226-324-4",
      "Myeloperoxidase Inhibitor-I",
      "BRN 0639053",
      "AI3-52435",
      "CHEMBL2170241",
      "(4-Aminobenzoyl)Hydrazide, 95%",
      "WPBZMCGPFHZRHJ-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D05OZZ",
    names: ["N-Oxo-2-[(4-Phenylphenyl)Sulfonylamino]ethanamide"],
  },
  {
    primary_id: "D05PBN",
    names: ["(R)-2-Methyl-1-(2-P-Tolyl-Ethyl)-Pyrrolidine", "CHEMBL403052"],
  },
  {
    primary_id: "D05PCM",
    names: ["PF-04965842"],
  },
  {
    primary_id: "D05PDF",
    names: ["Sulfonamide-01"],
  },
  {
    primary_id: "D05PDI",
    names: ["Tetra-Cyclic Compound 1"],
  },
  {
    primary_id: "D05PDJ",
    names: ["SAGE-217b"],
  },
  {
    primary_id: "D05PDW",
    names: ["ISIS 133206"],
  },
  {
    primary_id: "D05PFE",
    names: ["HC-15"],
  },
  {
    primary_id: "D05PFH",
    names: ["Isolagen Process", "Autologous Fibroblast Transplant"],
  },
  {
    primary_id: "D05PFS",
    names: ["LTI-291"],
  },
  {
    primary_id: "D05PGG",
    names: ["Prostvac"],
  },
  {
    primary_id: "D05PGM",
    names: ["AdV-TK"],
  },
  {
    primary_id: "D05PGU",
    names: ["Clostridium Difficile Toxoid Vaccine"],
  },
  {
    primary_id: "D05PHH",
    names: ["Gabexate"],
  },
  {
    primary_id: "D05PHI",
    names: [
      "2-(4-Methoxy-Phenyl)-5-Phenyl-Thiazol-4-Ol",
      "CHEMBL65940",
      "2-(4-Methoxyphenyl)-5-Phenylthiazol-4-Ol",
      "Oprea1_607545",
      "SCHEMBL9629437",
      "YQAKORGQQCUKEJ-UHFFFAOYSA-N",
      "BDBM50012392",
    ],
  },
  {
    primary_id: "D05PHX",
    names: ["SKA-121"],
  },
  {
    primary_id: "D05PIP",
    names: ["PA-640"],
  },
  {
    primary_id: "D05PIX",
    names: [
      "CG-400549",
      "CG-400329",
      "CG-400462",
      "CG-400587",
      "Bacterial Enoyl-ACP Reductase Inhibitors, CrystalGenomics",
      "FabI Inhibitors (Antibacterial), CrystalGenomics",
      "FabK Inhibitors (Bacterial Infections, Oral), CrystalGenomics",
    ],
  },
  {
    primary_id: "D05PIZ",
    names: ["Levomethadyl Acetate"],
  },
  {
    primary_id: "D05PJJ",
    names: ["NOX 2137a/B"],
  },
  {
    primary_id: "D05PKF",
    names: ["SOM-0720"],
  },
  {
    primary_id: "D05PKR",
    names: ["L-817,818"],
  },
  {
    primary_id: "D05PKT",
    names: ["4-[4-(6-Methoxynaphthalen-2-Yl)Benzyl]pyridine", "CHEMBL1214738", "SCHEMBL18770313"],
  },
  {
    primary_id: "D05PLF",
    names: [
      "2-(N-Tert-Butylamino)-4'-Chloropropiophenone",
      "CHEMBL566000",
      "2-(Tert-Butylamino)-1-(4-Chlorophenyl)Propan-1-One",
      "AC1MHR9U",
      "SCHEMBL770391",
      "BDBM50302911",
      "AKOS022330116",
      "4'-Chloro-Alpha-(Tert-Butylamino)Propiophenone",
    ],
  },
  {
    primary_id: "D05PLH",
    names: [
      "Carisoprodol",
      "78-44-4",
      "Isomeprobamate",
      "Carisoprodate",
      "Carisoprodatum",
      "Isoprotane",
      "Isobamate",
      "Isoprothane",
      "Carisoma",
      "Sanoma",
      "Isopropyl Meprobamate",
      "SOMA",
      "Somalgit",
      "Atonalyt",
      "Mioratrina",
      "Mioartrina",
      "Flexartal",
      "Skutamil",
      "Miolisodal",
      "Mioril",
      "Apesan",
      "Relasom",
      "Nospasm",
      "Flexal",
      "Arusal",
      "Somanil",
      "Carlsoprol",
      "Carisoprodolum",
      "Domarax",
      "Stialgin",
      "Somadril",
      "Carsodal",
      "Mioriodol",
      "Mediquil",
      "Izoprotan",
      "Isoprotan",
      "Carisol",
      "Caprodat",
      "Calenfa",
      "Diolene",
      "Carlsoma",
      "Carlsodol",
      "Brianil",
      "Flexidon",
      "Flexagilt",
      "Caridolin",
      "Meprodat",
      "Listaflex",
      "Flexagit",
      "Fibrosona",
      "Chinchen",
      "Carsodol",
    ],
  },
  {
    primary_id: "D05PMU",
    names: ["Dicaffeoyltartaric Acids"],
  },
  {
    primary_id: "D05PMY",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 26"],
  },
  {
    primary_id: "D05PNI",
    names: ["Phenyl-Uridine-5'-Diphosphate"],
  },
  {
    primary_id: "D05PNO",
    names: [
      "1-(2-Iodoethyl)-1H-Indole-2,3-Dione",
      "1-(2-Iodoethyl)Indole-2,3-Dione",
      "AC1N4VNK",
      "Isatin-Based Compound, 6",
      "CHEMBL376518",
      "BDBM22786",
      "ZINC5738958",
      "AKOS024343322",
      "MCULE-9804545910",
      "1-(2-IODO-ETHYL)-1H-INDOLE-2,3-DIONE",
    ],
  },
  {
    primary_id: "D05PNP",
    names: ["TUPICHINOL C"],
  },
  {
    primary_id: "D05PQF",
    names: ["ONO-4127Na", "Allergic Rhinitis Agent, Ono"],
  },
  {
    primary_id: "D05PQW",
    names: ["AZD-5180", "Anticancer Monoclonal Antibody (Solid Tumor), AstraZeneca/Abgenix/Amgen"],
  },
  {
    primary_id: "D05PRC",
    names: ["ISIS 116631"],
  },
  {
    primary_id: "D05PRG",
    names: ["Quinazoline Derivative 15"],
  },
  {
    primary_id: "D05PRQ",
    names: ["CCACSSKWCRDHSRCC", "CHEMBL508113"],
  },
  {
    primary_id: "D05PRS",
    names: [
      "4-Thiocyanato-Benzenesulfonamide",
      "CHEMBL363086",
      "NSC166291",
      "AC1L6PWF",
      "(4-Sulfamoylphenyl) Thiocyanate",
      "SCHEMBL16407107",
      "ZINC1655602",
      "BDBM50155502",
      "NSC-166291",
    ],
  },
  {
    primary_id: "D05PRV",
    names: ["PerCvax"],
  },
  {
    primary_id: "D05PSD",
    names: ["WIN 51,708"],
  },
  {
    primary_id: "D05PTG",
    names: ["EPD-Clofarabine", "Clofarabine (Enhanced Prodrug), Heidelberg Pharma", "HDP-15-0001", "HDP-15-0022"],
  },
  {
    primary_id: "D05PTH",
    names: ["MK-8175A"],
  },
  {
    primary_id: "D05PTL",
    names: ["NMB-T-BMX-OS01"],
  },
  {
    primary_id: "D05PTT",
    names: ["ROSMARINIC ACID"],
  },
  {
    primary_id: "D05PUD",
    names: ["Aldesleukin"],
  },
  {
    primary_id: "D05PVW",
    names: ["CX-2007"],
  },
  {
    primary_id: "D05PVX",
    names: ["Thiophene-2-Ylboronic Acid"],
  },
  {
    primary_id: "D05PWA",
    names: ["PNT-600"],
  },
  {
    primary_id: "D05PWU",
    names: ["PRo Antigen Cancer Vaccine"],
  },
  {
    primary_id: "D05PWX",
    names: [
      "Sulfatinib",
      "Anticancer Agents, Hutchison",
      "HMPL-012",
      "Kinase Inhibitors (Cancer), Hutchison",
      "VEGFR-1, 2, 3 And FGFR 1 Inhibitor (Oral, Cancer), Hutchison",
    ],
  },
  {
    primary_id: "D05PWZ",
    names: ["Ecromeximab"],
  },
  {
    primary_id: "D05PXA",
    names: ["BTX1503"],
  },
  {
    primary_id: "D05PXD",
    names: [
      "2-[(Dioxidophosphino)Oxy]Benzoate",
      "2-[(DIOXIDOPHOSPHINO)OXY]BENZOATE",
      "2-Phosphooxybenzoate",
      "2-(Phosphooxy)Benzoate",
      "AC1NR9RL",
      "AC1O4QG1",
      "2-Oxidophosphonoyloxybenzoate",
      "2-[(Dioxidophosphanyl)Oxy]benzoate",
      "2-[(Dioxidophosphoranyl)Oxy]benzoate",
    ],
  },
  {
    primary_id: "D05PXK",
    names: [
      "4'-Cyano-3-(Imidazolylmethyl)-7-Methoxyflavone",
      "CHEMBL377626",
      "BDBM50191601",
      "4''-Cyano-3-(Imidazolylmethyl)-7-Methoxyflavone",
    ],
  },
  {
    primary_id: "D05PXL",
    names: ["PL-3394"],
  },
  {
    primary_id: "D05PXW",
    names: ["Disulfiram Analog 1"],
  },
  {
    primary_id: "D05PYB",
    names: ["Elstree-BN"],
  },
  {
    primary_id: "D05PYF",
    names: ["N-Hydroxy-5-(Pyridin-2-Yl)Thiophene-2-Carboxamide", "CHEMBL216509", "SCHEMBL5903774", "BDBM50198477"],
  },
  {
    primary_id: "D05PYO",
    names: ["Aspirin-Triggered Resolvin D1", "AT-Resolvin D1", "AT-RvD1"],
  },
  {
    primary_id: "D05PZA",
    names: ["N-Benzylacetimidamide Hydrobromide"],
  },
  {
    primary_id: "D05PZB",
    names: ["AT9283"],
  },
  {
    primary_id: "D05PZG",
    names: ["Tetra-Hydro-Quinoline Derivative 4"],
  },
  {
    primary_id: "D05PZI",
    names: ["MB-CART20.1"],
  },
  {
    primary_id: "D05PZL",
    names: [
      "2,3-DIMETHYL-1,4-NAPHTHOQUINONE",
      "2,3-Dimethyl-1,4-Naphthoquinone",
      "2,3-Dimethylnaphthoquinone",
      "USAF SN-29",
      "2197-57-1",
      "1,4-Naphthalenedione, 2,3-Dimethyl-",
      "2,3-Dimethylnaphthalene-1,4-Dione",
      "UNII-SY9IO0B44O",
      "SY9IO0B44O",
      "1,4-NAPHTHOQUINONE, 2,3-DIMETHYL-",
      "NSC 36460",
      "BRN 2044697",
      "AI3-16112",
      "2,3-Dimethyl-1,4-Dihydronaphthalene-1,4-Dione",
      "AC1L28BU",
      "AC1Q2DM1",
      "SCHEMBL571026",
      "CHEMBL354201",
      "CTK4E8092",
      "DTXSID50176380",
      "MolPort-001-817-681",
      "LGFDNUSAWCHVJN-UHFFFAOYSA-N",
      "HMS1666O12",
      "NSC36460",
      "ZINC3063275",
      "WLN: L66 BV EVJ C1 D1",
      "2,3-Dimethyl-[1,4]naphthoquinone",
      "NSC-36460",
    ],
  },
  {
    primary_id: "D05PZN",
    names: ["TRO-19622"],
  },
  {
    primary_id: "D05PZV",
    names: ["4-(2,2'-Bithiophen-5-Ylmethyleneamino)Phenol"],
  },
  {
    primary_id: "D05PZZ",
    names: ["DDP-200"],
  },
  {
    primary_id: "D05QAA",
    names: [
      "PNU-248686A",
      "3-(4'-Chlorobiphenyl-4-Ylsulfonyl)-2(R)-Hydroxy-2-(Phenylsulfanylmethyl)Propionic Acid Sodium Salt",
    ],
  },
  {
    primary_id: "D05QAD",
    names: ["RO320-2947/001"],
  },
  {
    primary_id: "D05QAL",
    names: ["Hydroxy Amidine Derivative 1"],
  },
  {
    primary_id: "D05QBF",
    names: [
      "Devazepide",
      "DEVAZEPIDE",
      "103420-77-5",
      "MK-329",
      "Devazepidum",
      "Devazepida",
      "MK 329",
      "L-364,718",
      "UNII-JE6P7QY7NH",
      "L 364718",
      "(S)-N-(2,3-Dihydro-1-Methyl-2-Oxo-5-Phenyl-1H-1,4-Benzodiazepin-3-Yl)Indole-2-Carboxamide",
      "JE6P7QY7NH",
      "CHEMBL9506",
      "CHEBI:4460",
      "L-364718",
      "3(S)-(-)-1,3-Dihydro-3-(2-Indolecarbonylamino)-1-Methyl-5-Phenyl-2H-(1,4)Benzodiazepin-2-One",
      "NCGC00159551-01",
      "DSSTox_RID_81331",
      "DSSTox_CID_26092",
      "DSSTox_GSID_46092",
      "1H-Indole-2-Carboxamide,",
    ],
  },
  {
    primary_id: "D05QBH",
    names: ["LAS-41003"],
  },
  {
    primary_id: "D05QBX",
    names: [
      "Biphenyl-3-Carboxylic Acid Hydroxyamide",
      "CHEMBL154517",
      "3-Biphenyl Hydroxamic Acid",
      "3-Phenylbenzohydroximic Acid",
      "SCHEMBL10363097",
      "SEPGPMDDTZHYJV-UHFFFAOYSA-N",
      "ZINC27646665",
    ],
  },
  {
    primary_id: "D05QBZ",
    names: ["CP-673451", "CP-673,451"],
  },
  {
    primary_id: "D05QCE",
    names: [
      "KM-01221",
      "Chymase Inhibitors (Cardiovascular Disease)",
      "KM-06864",
      "MWP-00965",
      "TY-51076",
      "Chymase Inhibitors (Cardiovascular Disease), Toa Eiyo",
    ],
  },
  {
    primary_id: "D05QCG",
    names: ["N-[O-Phosphono-Pyridoxyl]-Isoleucine"],
  },
  {
    primary_id: "D05QCM",
    names: ["PMID25726713-Compound-60"],
  },
  {
    primary_id: "D05QDC",
    names: [
      "Acitretin",
      "Acitretina",
      "Acitretine",
      "Acitretinum",
      "Etretin",
      "Isoacitretin",
      "Isoetretin",
      "Neotigason",
      "Soriatane",
      "TMMP",
      "Acitretina [Spanish]",
      "Acitretine [French]",
      "Acitretinum [Latin]",
      "Retinoid Etretin",
      "U0279",
      "Ro 10-1670",
      "Ro 13-7652",
      "Soriatane (TN)",
      "Soriatane, Acitretin",
      "Acitretin (USAN/INN)",
      "Acitretin [USAN:INN:BAN]",
      "All-Trans-Acitretin",
      "Ro 10-1670/000",
      "Ro-10-1670",
      "Ro-13-7652",
      "Ro-10-1670/000",
      "All-Trans-3,7-Dimethyl-9-(4-Methoxy-2,3,6-Trimethylphenyl)-2,4,6,8-Nonatetraenoic Acid",
      "(2E,4E,6E,8E)-3,7-Dimethyl-9-[2,3,6-Trimethyl-4-(Methyloxy)Phenyl]nona-2,4,6,8-Tetraenoic Acid",
      "(2E,4E,6E,8E)-9-(4-Methoxy-2,3,6-Trimethylphenyl)-3,7-Dimethylnona-2,4,6,8-Tetraenoic Acid",
      "(All-E)-9-(4-Methoxy-2,3,6-Trimethylphenyl)-3,7-Dimethyl-2,4,6,8-Nonatetraenoic Acid",
      "13-Cis-Acitretin",
      "9-(4-Methoxy-2,3,6-Trimethylphenyl)-3,7-Dimethyl-2,4,6,8-Nonatetraenoic Acid",
      "9-(4-Methoxy-2,3,6-Trimethylphenyl)-3,7-Dimethylnona-2,4,6,8-Tetraenoic Acid",
    ],
  },
  {
    primary_id: "D05QEK",
    names: ["Aminobenzolamide Derivative", "CHEMBL435094"],
  },
  {
    primary_id: "D05QFA",
    names: ["R-71762"],
  },
  {
    primary_id: "D05QFO",
    names: ["RLY-106"],
  },
  {
    primary_id: "D05QFR",
    names: ["Isosorbide-2-(Cyclohexylcarbamate)-5-Mononitrate", "CHEMBL521852"],
  },
  {
    primary_id: "D05QGH",
    names: [
      "DXL-625",
      "DXLr-120",
      "Anti-CD20 Monoclonal Antibodies (DXL, Cancer), InNexus",
      "Autophilic Peptide Conjuagted Rituximab (Rheumatoid Arthritis/NHL/CLL), InNexus",
      "Anti-CD20 Monoclonal Antibodies (DXL, Non-Hodgkin's Lymphoma/ Rheumatoid Arthritis), InNexus",
    ],
  },
  {
    primary_id: "D05QHB",
    names: ["AMG 119"],
  },
  {
    primary_id: "D05QHL",
    names: ["Sivelestat Sodium Hydrate"],
  },
  {
    primary_id: "D05QHO",
    names: [
      "ACE-527",
      "ACAM-2023",
      "ACAM-2025",
      "ACAM-2027",
      "ACE-537",
      "ETEC Vaccine, Acambis",
      "Enterotoxigenic E Coli Vaccine, Acambis",
      "PTL-002",
      "PTL-003",
      "Travellers Vaccine, Acambis",
      "HolaVax-ETEC Vaccine, ACE BioSciences",
      "HolaVax-ETEC Vaccine, Acambis",
      "HolaVax-ETEC Vaccine, Cambridge Stability",
    ],
  },
  {
    primary_id: "D05QHU",
    names: ["CRS-3123"],
  },
  {
    primary_id: "D05QIM",
    names: ["TA-NIC"],
  },
  {
    primary_id: "D05QIS",
    names: [
      "VX-944",
      "AVN-944",
      "AVN944",
      "297730-17-7",
      "AVN 944",
      "UNII-I3NPL1V48Q",
      "I3NPL1V48Q",
      "501345-02-4",
      "VX 944",
      "SCHEMBL19784823",
      "CHEMBL3349001",
      "DTXSID80183921",
      "EX-A778",
      "AOB5557",
      "ZINC3963015",
      "AKOS027340045",
      "CS-3427",
      "NCGC00345803-01",
      "NCGC00345803-05",
      "HY-13560",
      "AS-16899",
      "SC-82478",
      "(R)-1-Cyanobutan-2-Yl ((S)-1-(3-(3-(3-Methoxy-4-(Oxazol-5-Yl)Phenyl)Ureido)Phenyl)Ethyl)Carbamate",
      "Carbamic Acid, ((1S)-1-(3-((((3-Methoxy-4-(5-  Oxazolyl)Phenyl)Amino)Carbonyl)Amino)Phenyl)Ethyl)-, (1R)-1-(Cyanomethyl)Propyl Ester",
      "(2R)-1-Cyanobutan-2-Yl N-[(1S)-1-[3-({[3-Metho",
    ],
  },
  {
    primary_id: "D05QIT",
    names: ["SEBACIC ACID"],
  },
  {
    primary_id: "D05QIU",
    names: ["Embeconazole", "CS-758", "R-120758", "Triazole Derivative (Oral, Fungal Infection), Daiichi Sankyo"],
  },
  {
    primary_id: "D05QIZ",
    names: ["GTx-822"],
  },
  {
    primary_id: "D05QJK",
    names: ["Benzyl-[2-(1H-Indazol-4-Yloxy)-Ethyl]-Amine", "CHEMBL87137", "SCHEMBL8612003", "BDBM50081109"],
  },
  {
    primary_id: "D05QJS",
    names: ["NORBINALTORPHIMINE"],
  },
  {
    primary_id: "D05QJT",
    names: ["GW274150"],
  },
  {
    primary_id: "D05QJY",
    names: ["PMID29649907-Compound-9"],
  },
  {
    primary_id: "D05QKI",
    names: ["LixiLan"],
  },
  {
    primary_id: "D05QKM",
    names: ["GLYCYLRYANODINE"],
  },
  {
    primary_id: "D05QKO",
    names: ["NBI-5788"],
  },
  {
    primary_id: "D05QKZ",
    names: ["Adipose-Derived Regenerative Cell Therapy"],
  },
  {
    primary_id: "D05QLQ",
    names: ["HIV Vaccine"],
  },
  {
    primary_id: "D05QLT",
    names: ["A438079", "A-438079", "A 438079"],
  },
  {
    primary_id: "D05QMA",
    names: ["Urea And Carbamate Bioisostere Derivative 18"],
  },
  {
    primary_id: "D05QMG",
    names: ["FR167653"],
  },
  {
    primary_id: "D05QMJ",
    names: ["RWJ-56423"],
  },
  {
    primary_id: "D05QML",
    names: ["PQ-1"],
  },
  {
    primary_id: "D05QMN",
    names: ["4-(3-Bromo-Phenoxy)-6,7-Dimethoxy-Quinoline", "CHEMBL101581"],
  },
  {
    primary_id: "D05QMQ",
    names: ["MLN-0905"],
  },
  {
    primary_id: "D05QNO",
    names: [
      "Decamethonium",
      "Decamethonum",
      "Syncurine",
      "DECAMETHONIUM ION",
      "AIDS351484",
      "AIDS-351484",
      "Decamethylenebis(Trimethylammonium)Bromide",
      "Syncurine (TN)",
      "Syncurine (*dibromide Salt*)",
      "Trimethyl-[10-(Trimethylazaniumyl)Decyl]azanium",
      "C 10 (VAN) (*dibromide Salt*)",
      "N,N,N,N',N',N'-Hexamethyldecane-1,10-Diaminium",
      "(DM)Br2",
      "1,10-Decanediaminium, N,N,N,N',N',N'-Hexamethyl-(9CI)",
      "1,10-Decanediaminium, N,N,N,N',N',N'-Hexamethyl-, Dibromide",
    ],
  },
  {
    primary_id: "D05QNR",
    names: ["N,N'-(1',9'-Nonylene)-Bis-(-)-Nor-MEP", "CHEMBL406645", "Bis(9)-(?)-Meptazinol"],
  },
  {
    primary_id: "D05QNV",
    names: ["ATL802", "SCHEMBL1243258", "GTPL5616"],
  },
  {
    primary_id: "D05QOF",
    names: [
      "1-Aminomethyl-3-Phenyl-Isochroman-5,6-Diol",
      "CHEMBL83080",
      "SCHEMBL7447363",
      "SUHGRZPINGKYNV-HUUCEWRRSA-N",
      "ZINC4637270",
      "BDBM50007136",
      "AJ-51787",
      "[1S,3R]1-Aminomethyl-3,4-Dihydro-5,6-Dihydroxy-3-Phenyl-1H-2-Benzopyran",
    ],
  },
  {
    primary_id: "D05QOL",
    names: ["Polatuzumab Vedotin"],
  },
  {
    primary_id: "D05QOR",
    names: ["SCOPOLETIN"],
  },
  {
    primary_id: "D05QPC",
    names: ["[L/D]-Aminopterin"],
  },
  {
    primary_id: "D05QPE",
    names: ["N-Homo-Gamma-Linolenoylethanolamine"],
  },
  {
    primary_id: "D05QPR",
    names: [
      "1-(4-Fluorobenzyl)-1H-Imidazole",
      "CHEMBL377770",
      "56643-73-3",
      "1-(4-Fluorobenzyl)Imidazole",
      "SCHEMBL11082155",
      "CTK1F4148",
      "DTXSID90481445",
      "ZINC5588657",
      "BDBM50188085",
      "AKOS003644474",
      "1H-Imidazole, 1-[(4-Fluorophenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D05QPW",
    names: [
      "Choline Salicylate",
      "2016-36-6",
      "Arthropan",
      "Syrap",
      "Mundisal",
      "Satibon",
      "Actasal",
      "Salicol",
      "Artrobione",
      "Arret",
      "Choline Salicylate B",
      "(2-Hydroxyethyl)Trimethylammonium Salicylate",
      "UNII-KD510K1IQW",
      "Choline Salicylic Acid Salt",
      "Salicylic Acid Choline Salt",
      "Cholini Salicylas [INN-Latin]",
      "Salicylic Acid, Ion(1-), Choline",
      "Salicylate De Choline [INN-French]",
      "Salicilato De Colina [INN-Spanish]",
      "EINECS 217-948-8",
      "KD510K1IQW",
      "Choline Salicylate [USAN:INN:BAN:JAN]",
      "Ethanaminium, 2-Hydroxy-N,N,N-Trimethyl-, Salt With 2-Hydroxybenzoic Acid (1:1)",
    ],
  },
  {
    primary_id: "D05QQI",
    names: ["ISIS 122971"],
  },
  {
    primary_id: "D05QTG",
    names: ["7-Cyclopentyloxy-3-(4-Hydroxyphenyl)Chromen-4-One"],
  },
  {
    primary_id: "D05QTJ",
    names: [
      "Chloroethylclonidine",
      "Chlorethylclonidine",
      "UNII-3X825O680H",
      "N-(2,6-Dichloro-4-(((2-Chloroethyl)Methylamino)Methyl)Phenyl)-4,5-Dihydro-1H-Imidazol-2-Amine",
      "3X825O680H",
      "98086-36-3",
      "1h-Imidazol-2-Amine, N-[2,6-Dichloro-4-[[(2-Chloroethyl)Methylamino]methyl]phenyl]-4,5-Dihydro-",
      "1H-Imidazol-2-Amine, N-(2,6-Dichloro-4-(((2-Chloroethyl)Methylamino)Methyl)Phenyl)-4,5-Dihydro-",
      "Lopac-B-003",
      "AC1Q3UQS",
      "AC1L2XTB",
      "Lopac0_000195",
      "C11H12Cl3N3",
      "SCHEMBL4107963",
      "CHEMBL326967",
      "SCHEMBL18154646",
      "CTK3I7576",
      "PDSP2_000070",
      "ZINC27089957",
    ],
  },
  {
    primary_id: "D05QTO",
    names: ["LF-801"],
  },
  {
    primary_id: "D05QTT",
    names: [
      "Tetralin_Urea Analogue (7o)",
      "CHEMBL384701",
      "3-(3-Chloro-4-Fluorophenyl)-1-(7-Cyano-1,2,3,4-Tetrahydronaphthalen-2-Yl)-1-[3-(4-Methylpiperazin-1-Yl)Propyl]urea",
      "3-(3-Chloro-4-Fluorophenyl)-1-(7-Cyano-1,2,3,4-Tetrahydronaphthalen-2-Yl)-1-(3-(4-Methylpiperazin-1-Yl)Propyl)Urea",
      "SCHEMBL3990193",
      "GTPL1319",
      "GQGGDNLJDZHJKN-UHFFFAOYSA-N",
      "BDBM50203352",
    ],
  },
  {
    primary_id: "D05QTX",
    names: ["Org27569"],
  },
  {
    primary_id: "D05QUG",
    names: ["7-Phenethoxy-2H-Chromen-2-One", "CHEMBL567544"],
  },
  {
    primary_id: "D05QUL",
    names: [
      "T89",
      "2-Propanone, 1-(5-Nitro-2H-Tetrazol-2-Yl)-",
      "131394-19-9",
      "1-(5-Nitro-1,2,3,4-Tetraazol-2-Yl)Acetone",
      "ZERO/000247",
      "AC1LOTQU",
      "ACMC-20mu2z",
      "2-Acetonyl-5-Nitrotetrazole",
      "CTK0C0970",
      "DTXSID40361521",
      "MolPort-001-904-996",
      "ZINC1084291",
      "STK760073",
      "SBB000169",
      "AKOS000558886",
      "MCULE-5436124308",
      "1-(5-Nitrotetrazol-2-Yl)Propan-2-One",
      "2-(2-Oxopropyl)-5-Nitro-2H-Tetrazole",
      "ST026974",
      "ST023819",
      "BAS 01313849",
      "1-(5-Nitro-Tetrazol-2-Yl)-Propan-2-One",
      "EU-0085349",
      "1-(5-Nitro-2H-Tetrazol-2-Yl)Propan-2-One",
      "SR-01000523987",
      "SR-01000523987-1",
    ],
  },
  {
    primary_id: "D05QVX",
    names: ["Tacrine-Indole Hybrid Derivative 3"],
  },
  {
    primary_id: "D05QWJ",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 2"],
  },
  {
    primary_id: "D05QWQ",
    names: ["XAC-BY630"],
  },
  {
    primary_id: "D05QWS",
    names: ["Epicardial Angiogenesis Patch", "Anginera (TN)"],
  },
  {
    primary_id: "D05QWZ",
    names: ["ISIS 109355"],
  },
  {
    primary_id: "D05QXT",
    names: ["BAY-38-1315", "BAY-19-4789"],
  },
  {
    primary_id: "D05QXU",
    names: ["1,3-Bis-(3-Imidazolidin-2-Yl-Phenyl)-Urea"],
  },
  {
    primary_id: "D05QXW",
    names: ["TZ-111"],
  },
  {
    primary_id: "D05QYN",
    names: ["PMID25726713-Compound-15"],
  },
  {
    primary_id: "D05QYR",
    names: ["AC55649", "AC 55649", "AC-55649"],
  },
  {
    primary_id: "D05RAB",
    names: [
      "1,4-Dihydro-1-Methyl-4-Oxo-3-Pyridinesulfonamide",
      "CHEMBL1240572",
      "182556-18-9",
      "SCHEMBL8320257",
      "BDBM50326118",
      "ZINC39054006",
      "NSC744701",
      "AKOS027401058",
      "NSC-744701",
      "KB-184852",
      "1,4-Dihydro-1-Methyl-4-Oxopyridine-3-Sulfonamide",
    ],
  },
  {
    primary_id: "D05RBF",
    names: ["PMID25666693-Compound-99"],
  },
  {
    primary_id: "D05RBH",
    names: ["N9-GP"],
  },
  {
    primary_id: "D05RBL",
    names: ["CL-184005"],
  },
  {
    primary_id: "D05RBO",
    names: ["OPM-3023 Oral"],
  },
  {
    primary_id: "D05RCG",
    names: ["2,7-Bis[2-(Piperazino)Acetamido]anthraquinone", "SCHEMBL4291737", "CHEMBL495308"],
  },
  {
    primary_id: "D05RCK",
    names: ["Miller-9E-Enolide"],
  },
  {
    primary_id: "D05RCZ",
    names: ["HSV2 DNA Vaccine"],
  },
  {
    primary_id: "D05RDE",
    names: [
      "TJN-505",
      "Contortumine",
      "AC1MIXJU",
      "20-Ethyl-1,16-Dimethoxyaconitane-8,13,14-Triol 14-(4-Methoxybenzoate), (1alpha,14alpha,16beta)-",
      "131653-96-8",
      "(1alpha,14alpha,16beta)-20-Ethyl-1,16-Dimethoxyaconitane-8,13,14-Triol 14-(4-Methoxybenzoate)",
      "Aconitane-8,13,14-Triol, 20-Ethyl-1,16-Dimethoxy-, 14-(4-Methoxybenzoate), (1alpha,14alpha,16beta)-",
    ],
  },
  {
    primary_id: "D05RDM",
    names: ["ISIS 122976"],
  },
  {
    primary_id: "D05RDO",
    names: ["3-(6-Amino-Purin-9-Yl)-4-Butoxy-Butan-2-Ol", "CHEMBL606665", "ZINC13835548"],
  },
  {
    primary_id: "D05RDP",
    names: [
      "UMI-77",
      "518303-20-3",
      "C18H14BrNO5S2",
      "MLS001196198",
      "SMR000558497",
      "2-[4-[(4-Bromophenyl)Sulfonylamino]-1-Hydroxynaphthalen-2-Yl]sulfanylacetic Acid",
      "[(4-{[(4-Bromophenyl)Sulfonyl]amino}-1-Hydroxy-2-Naphthyl)Thio]acetic Acid",
      "AC1LKK7D",
      "UMI77",
      "Cid_992586",
      "GTPL8105",
      "SCHEMBL14842934",
      "CHEMBL1331211",
      "BDBM53290",
      "EX-A762",
      "AOB6936",
      "MolPort-000-682-969",
      "BCP09696",
      "ZINC4149952",
      "STK238370",
      "2780AH",
      "S7531",
      "AKOS001057042",
      "SB19399",
      "CS-5046",
      "MCULE-2580729398",
      "NCGC00386409-01",
      "UMI-77, &gt",
      "HY-18628",
      "DA-42224",
      "BC600617",
      "AK547843",
      "EU-0015042",
    ],
  },
  {
    primary_id: "D05REB",
    names: [
      "ZT-1",
      "(5R,9R)-5-(5-Chloro-2-Hydroxy-3-Methoxybenzylideneamino)-11-Ethylidene-7-Methyl-1,2,5,6,9,10-Hexahydro-5,9-Methanocycloocta[b]pyridin-2-One",
      "180694-97-7",
    ],
  },
  {
    primary_id: "D05REH",
    names: ["Flosulide", "CGP-28238", "CGP-28328", "CGS-28238", "SH-459", "ZK-38997"],
  },
  {
    primary_id: "D05REL",
    names: ["GR-82334"],
  },
  {
    primary_id: "D05REP",
    names: ["Pyridine-Carboximide Derivative 2"],
  },
  {
    primary_id: "D05REQ",
    names: ["3-(3-Aminocyclopentyl)-1H-Indole-5-Carbonitrile", "CHEMBL1276297", "SCHEMBL6087331"],
  },
  {
    primary_id: "D05RFC",
    names: ["Tumor Vascular Thrombogen"],
  },
  {
    primary_id: "D05RFI",
    names: ["QBI-206"],
  },
  {
    primary_id: "D05RFO",
    names: ["A-928605"],
  },
  {
    primary_id: "D05RGO",
    names: ["H-Pro-Phe-Phe-NH2", "CHEMBL591489"],
  },
  {
    primary_id: "D05RGU",
    names: ["N-(4-Methoxyphenethyl)Oleamide"],
  },
  {
    primary_id: "D05RGV",
    names: ["MK-1496"],
  },
  {
    primary_id: "D05RGW",
    names: ["Methimepip"],
  },
  {
    primary_id: "D05RHI",
    names: ["Trifluridine"],
  },
  {
    primary_id: "D05RIE",
    names: [
      "3-Benzoyl-N-Hydroxy-Benzamide",
      "CHEMBL175908",
      "3-Benzoylbenzohydroximic Acid",
      "SCHEMBL14880296",
      "ZINC28108876",
    ],
  },
  {
    primary_id: "D05RII",
    names: [
      "3-(1-Benzylpiperidin-4-Yl)-5-Chloro-1H-Indole",
      "CHEMBL210770",
      "SCHEMBL14280723",
      "NRIVFQIOYWIUPX-UHFFFAOYSA-N",
      "BDBM50185792",
    ],
  },
  {
    primary_id: "D05RIM",
    names: ["SPN-808"],
  },
  {
    primary_id: "D05RIY",
    names: ["Perflubutane Lipid Microsphere-Encapsulated"],
  },
  {
    primary_id: "D05RJG",
    names: ["Schiff Base Compound 1"],
  },
  {
    primary_id: "D05RJP",
    names: ["Oblimersen"],
  },
  {
    primary_id: "D05RJQ",
    names: ["ISIS 11224"],
  },
  {
    primary_id: "D05RKH",
    names: ["6-Methyl-4-(Piperazin-1-Yl)Furo[2,3-D]pyrimidine", "CHEMBL596328"],
  },
  {
    primary_id: "D05RKS",
    names: [
      "N-Methyl-4,4-Diphenylbutan-1-Amine",
      "Diphenylalkylamine (DPA), 6b",
      "CHEMBL594138",
      "BDBM35936",
      "ZINC22008119",
      "AC-606",
      "AKOS016035533",
    ],
  },
  {
    primary_id: "D05RKT",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 18"],
  },
  {
    primary_id: "D05RKV",
    names: [
      "Sch-036",
      "Cannabinoid CB2 Inverse Agonists (Immune Disorders)",
      "Cannabinoid CB2 Inverse Agonists (Immune Disorders), Schering-Plough",
    ],
  },
  {
    primary_id: "D05RLR",
    names: [
      "1-((2-Chlorophenyl)Diphenylmethyl)-1H-Pyrazole",
      "TRAM-34",
      "289905-88-0",
      "Tram 34",
      "1-[(2-Chlorophenyl)Diphenylmethyl]-1H-Pyrazole",
      "AC1LCVHZ",
      "CHEMBL498270",
      "CHEBI:34990",
      "1-[(2-Chlorophenyl)-Diphenylmethyl]pyrazole",
      "1-(2-Chlorophenyl)Diphenyl)Methyl]-1H-Pyrazole",
      "SureCN41130",
      "TRAM34",
      "SCHEMBL41130",
      "GTPL2336",
      "KS-00000OJW",
      "CTK0J1643",
      "DTXSID70349650",
      "MolPort-003-850-592",
      "EX-A2316",
      "BCP01324",
      "ZINC3975392",
      "BDBM50279107",
      "ANW-72309",
      "S1160",
      "MFCD09842562",
      "AKOS005146304",
      "CS-1921",
      "ACN-048484",
      "MP-2041",
      "API0000868",
      "DS-2617",
      "NCGC00165909-03",
      "NCGC00165909-02",
    ],
  },
  {
    primary_id: "D05RLT",
    names: [
      "CXCR7 Pepducins",
      "CXCR7 Pepducins (Lipopetide, Cancer)",
      "CXCR7 Pepducins (Lipopetide, Cancer), Anchor Therapeutics",
      "Chemokine Receptor 7 Modulators (Lipopetide, Cancer), Anchor Therapeutics",
    ],
  },
  {
    primary_id: "D05RMW",
    names: ["ZD-3638"],
  },
  {
    primary_id: "D05RNF",
    names: ["D[Cha4,Orn8]VP", "CHEMBL263090"],
  },
  {
    primary_id: "D05ROI",
    names: [
      "Dabrafenib",
      "1195765-45-7",
      "Dabrafenib (GSK2118436)",
      "Tafinlar",
      "GSK2118436A",
      "UNII-QGP4HA4G1B",
      "GSK 2118436",
      "QGP4HA4G1B",
      "N-(3-(5-(2-Aminopyrimidin-4-Yl)-2-Tert-Butylthiazol-4-Yl)-2-Fluorophenyl)-2,6-Difluorobenzenesulfonamide",
      "CHEBI:75045",
      "N-[3-[5-(2-Amino-4-Pyrimidinyl)-2-(Tert-Butyl)-4-Thiazolyl]-2-Fluorophenyl]-2,6-Difluorobenzenesulfonamide",
      "N-{3-[5-(2-Aminopyrimidin-4-Yl)-2-Tert-Butyl-1,3-Thiazol-4-Yl]-2-Fluorophenyl}-2,6-Difluorobenzenesulfonamide",
      "GSK-2118436A",
      "Dabrafenib [USAN:INN]",
      "GSK2118436",
    ],
  },
  {
    primary_id: "D05ROV",
    names: [
      "GNF-PF-5411",
      "CINCHONIDINE",
      "Cinchonan-9-Ol",
      "Cinchonine",
      "Alpha-Quinidine",
      "485-71-2",
      "118-10-5",
      "MLS002637808",
      "NSC5364",
      ".alpha.-Quinidine",
      "NSC 5364",
      "Cinchonan-9-Ol, (8.alpha.,9R)-",
      "Cinchonan-9-Ol, (9S)-",
      "(8alpha,9R)Cinchonan-9-Ol",
      "A-Quinidine",
      "(8-Alpha,9R)-Cinchonan-9-Ol",
      "EINECS 207-622-3",
      "{5-Ethenyl-1-Azabicyclo[2.2.2]octan-2-Yl}(Quinolin-4-Yl)Methanol",
      "SMR001488502",
      "AI3-15317",
      "4-Quinolyl-(5-Vinylquinuclidin-2-Yl)Methanol",
      "2-Quinuclidinemethanol, Alpha-4-Quinolyl-5-Vinyl-",
      "(+)-Quinolin-4-Yl(5-Vinylquinuclidin-2-Yl)Methanol",
    ],
  },
  {
    primary_id: "D05RPN",
    names: ["BMS-488516"],
  },
  {
    primary_id: "D05RPT",
    names: ["MER-2101"],
  },
  {
    primary_id: "D05RQB",
    names: [
      "Aphidicolin",
      "Bis(Hydroxymethyl)-Dimethyl-[?]diol",
      "AC1LA8KN",
      "8,11a-Methano-11aH-Cyclohepta[a]naphthalene-4,9-Dimethanol, Tetradecahydro-3,9-Dihydroxy-4,11b-Dimethyl-, (3R,4R,9R,11bS)-",
    ],
  },
  {
    primary_id: "D05RQF",
    names: ["Benzoxazole Derivative 1"],
  },
  {
    primary_id: "D05RQW",
    names: ["Guglipid"],
  },
  {
    primary_id: "D05RQX",
    names: ["Cefaclidine", "Cefclidin", "Antibiotic E-1040", "E-1040", "E-1040 (Antibiotic)"],
  },
  {
    primary_id: "D05RQY",
    names: [
      "MM-093",
      "ABI-001",
      "Recombinant Human Alpha Fetoprotein (Autoimmune Diseases), Merrimack",
      "RhAFP (Autoimmune Diseases), Merrimack",
      "Recombinant Human Alpha-Fetoprotein (Autoimmune Disease), GTC Biotherapeutics",
    ],
  },
  {
    primary_id: "D05RRA",
    names: [
      "HQK-1004",
      "Arginine Butyrate",
      "VX-105",
      "VX-105)",
      "Arginine Butyrate (Hematological Malignancies), HemaQuest",
    ],
  },
  {
    primary_id: "D05RRF",
    names: ["Cyclic LVVYPWT", "CHEMBL397926"],
  },
  {
    primary_id: "D05RRI",
    names: ["Clodinafop"],
  },
  {
    primary_id: "D05RRK",
    names: ["Phe-Pro-Glu"],
  },
  {
    primary_id: "D05RRO",
    names: [
      "Adenosine Analog DNA Ligase Inhibitors",
      "Adenosine Analog DNA Ligase Inhibitors (Bacterial Infection)",
      "Adenosine Analog DNA Ligase Inhibitors (Bacterial Infection), AstraZeneca",
    ],
  },
  {
    primary_id: "D05RRU",
    names: [
      "IL-2/CD40L-Expressing Leukemia Vaccine",
      "Autologous Vaccine (Leukemia), Baylor College Of Medicine",
      "Autologous Vaccine (Leukemia), MaxCyte",
      "Autologous Vaccine (Leukemia), Baylor College Of Medicine/MaxCyte",
      "IL-2/CD40L-Expressing Leukemia Vaccine, Baylor College Of Medicine",
      "IL-2/CD40L-Expressing Leukemia Vaccine, MaxCyte",
      "IL-2/CD40L-Expressing Leukemia Vaccine, Baylor College Of Medicine/MaxCyte",
    ],
  },
  {
    primary_id: "D05RRZ",
    names: [
      "Glaxo-11p",
      "CHEMBL345869",
      "GTPL866",
      "CHEMBL1184355",
      "BDBM50422153",
      "N-Isopropyl-N-[4-(Dimethylamino)Phenyl]-2-[[2,3,4,5-Tetrahydro-2,4-Dioxo-5-Phenyl-3-(3-Phenylureido)-1H-1,5-Benzodiazepin]-1-Yl]acetamide",
    ],
  },
  {
    primary_id: "D05RSC",
    names: ["Sofinicline"],
  },
  {
    primary_id: "D05RSP",
    names: ["ETHYL (1E)-2-PHENYL-N-(SULFOOXY)ETHANIMIDOTHIOATE"],
  },
  {
    primary_id: "D05RSQ",
    names: ["3-Benzyl-5-Fluorobenzo[d]oxazol-2(3H)-One", "CHEMBL1256370", "SCHEMBL18805702"],
  },
  {
    primary_id: "D05RSS",
    names: ["PF-03772304"],
  },
  {
    primary_id: "D05RSX",
    names: ["ISIS 150454"],
  },
  {
    primary_id: "D05RTB",
    names: ["Anti-Staphylococcus Enterotoxin B Hyperimmune Globulin"],
  },
  {
    primary_id: "D05RTF",
    names: ["AME-527"],
  },
  {
    primary_id: "D05RTH",
    names: ["17-Desmethoxy-17-Aminogeldanamycin"],
  },
  {
    primary_id: "D05RTM",
    names: ["LEZ763"],
  },
  {
    primary_id: "D05RTV",
    names: ["Superoxide Dismutase"],
  },
  {
    primary_id: "D05RTX",
    names: ["RO-1752"],
  },
  {
    primary_id: "D05RUG",
    names: ["Ethynyl Compound 6"],
  },
  {
    primary_id: "D05RUH",
    names: ["FMX101"],
  },
  {
    primary_id: "D05RUJ",
    names: ["G-1128"],
  },
  {
    primary_id: "D05RUM",
    names: ["Melperone"],
  },
  {
    primary_id: "D05RVA",
    names: ["CFG920"],
  },
  {
    primary_id: "D05RWD",
    names: ["Research Programme: Farnesoid X Receptor Agonists, Roche"],
  },
  {
    primary_id: "D05RWL",
    names: ["NSD-788"],
  },
  {
    primary_id: "D05RWN",
    names: ["Lanthanum Polystyrene Sulfonate"],
  },
  {
    primary_id: "D05RWU",
    names: ["N-(4-Amino-4'-Vinylbiphenyl-3-Yl)Benzamide", "CHEMBL1096397", "BDBM50318002"],
  },
  {
    primary_id: "D05RWZ",
    names: [
      "2-Fluoronorepinehprine",
      "CHEMBL40317",
      "Lopac-B-012",
      "AC1O7G01",
      "CTK2G6131",
      "BDBM50087513",
      "NCGC00015118-01",
      "1,2-Benzenediol, 4-[(1R)-2-Amino-1-Hydroxyethyl]-5-Fluoro-",
      "4-[(1R)-2-Amino-1-Hydroxyethyl]-5-Fluorobenzene-1,2-Diol",
      "(R)4-(2-Amino-1-Hydroxy-Ethyl)-5-Fluoro-Benzene-1,2-Diol",
    ],
  },
  {
    primary_id: "D05RXG",
    names: [
      "5-Amino-3,4-Dihydroisoquinolin-1(2H)-One",
      "129075-53-2",
      "CHEMBL594759",
      "SCHEMBL7581623",
      "RTPKPVYTPRJRBY-UHFFFAOYSA-N",
      "ZINC45353622",
      "BDBM50306284",
      "AKOS023598631",
      "AB53700",
      "FCH1123505",
      "KS-9128",
      "CM10348",
      "AJ-110485",
      "5-Amino-3,4-Dihydro-1(2H)-Isoquinolinone",
      "EN300-254419",
    ],
  },
  {
    primary_id: "D05RXI",
    names: ["Hydrocortamate"],
  },
  {
    primary_id: "D05RXW",
    names: ["LAS-41002"],
  },
  {
    primary_id: "D05RZE",
    names: ["LY-2623091", "Chronic Renal Disease Therapy, Eli Lilly"],
  },
  {
    primary_id: "D05RZI",
    names: [
      "AA-193",
      "107804-48-8",
      "Furo[2,3-G]-1,2-Benzisoxazole-7-Carboxylicacid, 5-Chloro-7,8-Dihydro-3-Phenyl-",
      "AC1OCEWV",
      "ACMC-20mb6x",
      "CHEMBL93997",
      "SCHEMBL10512834",
      "CTK0H6407",
      "AKOS030601198",
      "5-Chloro-3-Phenyl-7,8-Dihydrofuro[2,3-G][1,2]benzoxazole-7-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05RZJ",
    names: [
      "Recombinant Human CC10",
      "Claragen-WH",
      "Recombinant Human CC10 (Intravaginal, Endometriosis/Infertility)",
      "Recombinant Human CC10 (Intravaginal, Endometriosis/Infertility), Clarassance",
      "Uteroglobin (Intravaginal, Endometriosis/Infertility), Clarassance",
    ],
  },
  {
    primary_id: "D05RZW",
    names: ["YM-934"],
  },
  {
    primary_id: "D05SAP",
    names: [
      "N-(3-(Aminomethyl)Phenyl)Benzenesulfonamide",
      "CHEMBL209909",
      "N-[3-(Aminomethyl)Phenyl]benzenesulfonamide",
      "CTK7E5754",
      "BDBM50193462",
      "ZINC22142484",
    ],
  },
  {
    primary_id: "D05SBI",
    names: ["TBC-395"],
  },
  {
    primary_id: "D05SBJ",
    names: ["1-Morpholin-4-Yl-Benzo[f]chromen-3-One", "CHEMBL178969"],
  },
  {
    primary_id: "D05SBO",
    names: [
      "LY2835219",
      "Abemaciclib",
      "1231929-97-7",
      "Verzenio",
      "LY-2835219",
      "UNII-60UAB198HK",
      "LY2835219 (Free Base);",
    ],
  },
  {
    primary_id: "D05SCE",
    names: [
      "CDDO",
      "Bardoxolone",
      "218600-44-3",
      "RTA-401",
      "2-Cyano-3,12-Dioxooleana-1,9(11)-Dien-28-Oic Acid",
      "UNII-7HT68L8941",
      "RTA 401",
      "7HT68L8941",
      "(4aS,6aR,6bS,8aR,12aS,14aR,14bS)-11-Cyano-2,2,6a,6b,9,9,12a-Heptamethyl-10,14-Dioxo-1,3,4,5,6,7,8,8a,14a,14b-Decahydropicene-4a-Carboxylic Acid",
      "C31H41NO4",
      "2-Cyano-3,12-Dioxooleana-1,9-Dien-28-Oic Acid",
      "RTA 402",
      "CCDO;(4aS,6aR,6bS,8aR,12aS,14aR,14bS)-11-Cyano-2,2,6a,6b,9,9,12a-Heptamethyl-10,14-Dioxo-1,3,4,5,6,7,8,8a,14a,14b-Decahydropicene-4a-Carboxylic Acid",
      "NSC711193",
      "Bardoxolone RTA 401",
    ],
  },
  {
    primary_id: "D05SCQ",
    names: ["TDI-0079"],
  },
  {
    primary_id: "D05SEV",
    names: [
      "3-(4,5-Bis-Biphenyl-4-Yl-1H-Imidazol-2-Yl)-Phenol",
      "296792-52-4",
      "CHEMBL443007",
      "3-[4,5-Bis(4-Phenylphenyl)-1H-Imidazol-2-Yl]phenol",
      "AC1M4HYZ",
      "AC1Q790A",
      "CTK8A0066",
      "MolPort-001-732-645",
      "ZINC6028400",
      "BDBM50113297",
      "STK758717",
      "AKOS001063669",
      "NE26785",
      "MCULE-4347250395",
      "UPCMLD0ENAT5224754:001",
      "ST4040901",
      "KB-335272",
      "EN300-07381",
      "3-[4,5-Bis(4-Phenylphenyl)Imidazol-2-Yl]phenol",
      "SR-01000521283",
      "J-510645",
      "SR-01000521283-1",
      "Z56935326",
      "3-[4,5-Di(Biphenyl-4-Yl)-1H-Imidazol-2-Yl]phenol",
      "3-(4,5-Bis-Biphenyl-4-Yl-1h-Imidazol-2-Yl)Phenol",
    ],
  },
  {
    primary_id: "D05SEW",
    names: ["OB-24"],
  },
  {
    primary_id: "D05SFA",
    names: ["Cicaprost"],
  },
  {
    primary_id: "D05SFD",
    names: [
      "DMP-961",
      "DPC-961",
      "(-)-6-Chloro-4(S)-(2-Cyclopropylethynyl)-4-(Trifluoromethyl)-3,4-Dihydro-1H-Quinazolin-2-One",
    ],
  },
  {
    primary_id: "D05SGI",
    names: ["BGB-3113"],
  },
  {
    primary_id: "D05SGJ",
    names: ["5-Hydroxy-Pentanoate"],
  },
  {
    primary_id: "D05SGO",
    names: ["MOTILIN"],
  },
  {
    primary_id: "D05SGV",
    names: [
      "3-Morpholino-1H-Benzo[f]chromen-1-One",
      "CHEMBL367537",
      "SCHEMBL13346489",
      "BDBM50159678",
      "3-Morpholin-4-Yl-Benzo[f]chromen-1-One",
    ],
  },
  {
    primary_id: "D05SHK",
    names: [
      "Nadolol",
      "Anabet",
      "Corgard",
      "Corgaretic",
      "Nadic",
      "Nadololum",
      "Solgol",
      "SQ 11725",
      "SQ11725",
      "Anabet (TN)",
      "Corgard (TN)",
      "Corzide (TN)",
      "Nadololum [INN-Latin]",
      "SQ-11725",
      "Solgol (TN)",
      "Alti-Nadolol (TN)",
      "Apo-Nadol (TN)",
      "Novo-Nadolol (TN)",
      "Nadolol (JP15/USP/INN)",
      "Nadolol [USAN:BAN:INN:JAN]",
      "(2R,3S)-5-(3-(Tert-Butylamino)-2-Hydroxypropoxy)-1,2,3,4-Tetrahydronaphthalene-2,3-Diol",
      "(2R,3S)-5-({3-[(1,1-Dimethylethyl)Amino]-2-Hydroxypropyl}oxy)-1,2,3,4-Tetrahydronaphthalene-2,3-Diol",
      "(2R,3S)-5-[3-(Tert-Butylamino)-2-Hydroxypropoxy]-1,2,3,4-Tetrahydronaphthalene-2,3-Diol",
      "2,3-Cis-1,2,3,4-Tetrahydro-5-((2-Hydroxy-3-Tert-Butylamino)Propoxy)-2,3-Naphthalenediol",
      "5-(3-((1,1-Dimethylethyl)Amino)-2-Hydroxypropoxy)-1,2,3,4-Tetrahydro-2,3-Naphthalenediol",
    ],
  },
  {
    primary_id: "D05SHQ",
    names: ["ASP-2002"],
  },
  {
    primary_id: "D05SHW",
    names: ["Terpyridineplatinum(II) Complexe 4"],
  },
  {
    primary_id: "D05SJB",
    names: ["7-Azaindole Derivative 1"],
  },
  {
    primary_id: "D05SJD",
    names: ["DasKloster 0501-01"],
  },
  {
    primary_id: "D05SJK",
    names: ["Bone Marrow Stem Cell Therapy", "Bone Marrow Stem Cell Therapy (Congestive Cardiomyopathy)"],
  },
  {
    primary_id: "D05SJW",
    names: [
      "Gallamine Triethiodide",
      "Benzkurin",
      "Flaxedil",
      "Gallaflex",
      "Parexyl",
      "Pirolakson",
      "Pyrolaxon",
      "Relaxan",
      "Remyolan",
      "Retensin",
      "Sincurarine",
      "Syncurarine",
      "Tricuran",
      "Benzcurine Iodide",
      "Gallamin Triethiodide",
      "Gallamina Triodoetilato",
      "Gallamina Triodoetilato [DCIT]",
      "Gallamine Iodide",
      "Gallamine Triethiodide [INN]",
      "Gallamine Triiodoethylate",
      "Gallamini Triethiodidum",
      "Gallaminii Iodidum",
      "Gallaminum Triaethjodidum",
      "Gallaminum Triaethoiodatum",
      "Gallamone Triethiodide",
      "Gallamoni Jodidum",
      "Miowas G",
      "Triethiodure De Gallamine",
      "Triethiodurode Galamina",
      "Triiodoethylate De Gallamine",
      "Triiodoethylate De Gallamine [French]",
      "F 2559",
      "Fourneau 2559",
      "G 8134",
      "HL 8583",
      "RP 3697",
      "Flaxedil (TN)",
      "Gallamine Triethiodide (USP)",
      "Gallamine-3ETI",
      "Gallamini Triethiodidum [INN-Latin]",
      "Triethiodure De Gallamine [INN-French]",
      "Triethioduro De Galamina [INN-Spanish]",
      "Pyrogallol 1,2,3-(Diethylaminoethyl Ether) Trisethyl Iodide",
      "Pyrogallol 1,2,3-(Diethylaminoethyl Ether) Tris(Ethyliodide)",
      "Tri(Iodoethylate) De Tri (Beta Diethylaminoethoxy)-1,2,3 Benzene",
      "Tri(Iodoethylate) De Tri (Beta Diethylaminoethoxy)-1,2,3 Benzene [French]",
      "Triiodoethylate Of Tri(Diethylaminoethyloxy)-1,2,3-Benzene",
      "Triiodure De Tri(Beta-Triethylammoniumethoxy)-1,2,3 Benzene",
      "Triiodure De Tri(Beta-Triethylammoniumethoxy)-1,2,3 Benzene [French]",
      "Ammonium, (V-Phenenyltris(Oxyethylene))Tris(Triethyl-, Triiodide",
      "Ammonium, (V-Phenenyltris(Oxyethylene)Tris(Triethyl-, Triiodide",
      "Tri(Beta-Diethylaminoethoxy)-1,2,3-Benzene Tri-Iodoethylate",
      "Ethanaminium, 2,2',2'-(1,2,3-Benzenetriyltris(Oxy))Tris(N,N,N-Triethyl)-, Triiodide",
      "Ethanaminium, 2,2',2''-(1,2,3-Benzenetriyltris(Oxy))Tris(N,N,N-Triethyl)-, Triiodide",
      "Ethanaminium, 2,2',2''-(1,2,3-Benzenetriyltris(Oxy))Tris(N,N,N-Triethyl-, Triiodide",
      "(V-Phenenyltris(Oxyethylene))Tris(Triethylammonium Iodide)",
      "(V-Phenenyltris(Oxyethylene))Tris(Triethylammonium) Triiodide",
      "(V-Phenenyltris(Oxyethylene))Tris(Triethylammoniumiodide)",
      "1,2,3-Tri(Beta-Diethylaminoethoxy)Benzene Triethiodide",
      "1,2,3-Tris(2-Diethylaminoethoxy)Benzene Triethiodide",
      "1,2,3-Tris(2-Diethylaminoethoxy)Benzene Tris(Ethyliodide)",
      "1,2,3-Tris(2-Triethylammonium Ethoxy)Benzene Triiodide",
      "1,2,3-Tris(Diethylaminoethoxy)Benzene Triethiodide",
      "2,2',2''-(1,2,3-Benzenetriyltris(Oxy))Tris(N,N,N-Triethylethanaminium) Triiodide",
      "2,2',2''-[benzene-1,2,3-Triyltris(Oxy)]tris(N,N,N-Triethylethanaminium) Triiodide",
      "2-[2,3-Bis[2-(Triethylazaniumyl)Ethoxy]phenoxy]ethyl-Triethylazanium Triiodide",
    ],
  },
  {
    primary_id: "D05SJY",
    names: ["WS-CoQ10"],
  },
  {
    primary_id: "D05SKE",
    names: ["6-Bromo-3-(Pyridin-4-Yl)-1H-Indole", "CHEMBL206257"],
  },
  {
    primary_id: "D05SLL",
    names: ["Diheptan-3-Yl 5-(Hydroxymethyl)Isophthalate", "CHEMBL494361", "SCHEMBL13625629"],
  },
  {
    primary_id: "D05SLM",
    names: ["LY-2590443"],
  },
  {
    primary_id: "D05SLY",
    names: ["SK1-I", "BML-258"],
  },
  {
    primary_id: "D05SLZ",
    names: ["7-Hydroxy-2-(4-Methoxy-Benzyl)-Chromen-4-One", "CHEMBL56447", "ZINC13797575", "BDBM50077321"],
  },
  {
    primary_id: "D05SMP",
    names: ["PMID25666693-Compound-116"],
  },
  {
    primary_id: "D05SMV",
    names: ["P2B-002"],
  },
  {
    primary_id: "D05SNG",
    names: ["7-Fluoro-3-Thiophen-3-Yl-Quinoline", "CHEMBL307093", "ZINC3834021"],
  },
  {
    primary_id: "D05SNR",
    names: ["5-(Pentylthio)Thiazolo[4,5-D]pyrimidin-7-Ol"],
  },
  {
    primary_id: "D05SNS",
    names: [
      "2-(2-Hydroxycarbamoyl-Ethyl)-Pentanedioic Acid",
      "CHEMBL63454",
      "475653-52-2",
      "SCHEMBL13996820",
      "CTK1C7211",
      "DTXSID40609753",
      "BDBM50129195",
      "2-[3-Oxo-3-(Hydroxyamino)Propyl]pentanedioic Acid",
      "2-[3-(Hydroxyamino)-3-Oxopropyl]pentanedioic Acid",
      "Pentanedioic Acid, 2-[3-(Hydroxyamino)-3-Oxopropyl]-",
    ],
  },
  {
    primary_id: "D05SNX",
    names: ["APT070"],
  },
  {
    primary_id: "D05SOF",
    names: ["SPM-3672"],
  },
  {
    primary_id: "D05SOH",
    names: ["CTAP-201"],
  },
  {
    primary_id: "D05SOU",
    names: ["All-Trans-Retinal"],
  },
  {
    primary_id: "D05SOW",
    names: ["SX-MTR1"],
  },
  {
    primary_id: "D05SOY",
    names: ["Hexahydro Naphthalene Derivative 3"],
  },
  {
    primary_id: "D05SPL",
    names: ["Monofer"],
  },
  {
    primary_id: "D05SPU",
    names: ["Infanrix-IPV"],
  },
  {
    primary_id: "D05SPX",
    names: ["VAP-1"],
  },
  {
    primary_id: "D05SQA",
    names: ["4'-Hydroxy-6,3',5'-Triprenylisoflavonone", "CHEMBL465773", "BDBM50292387", "AKOS025289213"],
  },
  {
    primary_id: "D05SQU",
    names: [
      "BIM-64152",
      "BIM-64158",
      "BIM-64159",
      "BIM-64170",
      "BIM-64171",
      "BIM-64173",
      "BIM-64177",
      "GIP Program (Metabolic Disorder)",
      "GIPanalogs (Metabolic Disorder), Ipsen",
      "GIP Program (Metabolic Disorder), Ipsen",
      "Glucose-Dependent Insulinotropic Polypeptide Analogs (Metabolic Disorder), Ipsen",
    ],
  },
  {
    primary_id: "D05SRQ",
    names: ["HC-056456"],
  },
  {
    primary_id: "D05SSB",
    names: [
      "2-Hydroxyisoquinoline-1,3(2H,4H)-Dione",
      "6890-08-0",
      "CHEMBL16755",
      "2-Hydroxy-4H-Isoquinoline-1,3-Dione",
      "1,3(2H,4H)-Isoquinolinedione, 2-Hydroxy-",
      "2-Hydroxy-1,2,3,4-Tetrahydroisoquinoline-1,3-Dione",
      "0N7",
      "AC1LAHYL",
      "N-Hydroxyisoquinolinedione, 2",
      "SCHEMBL3358267",
      "BDBM33410",
      "DTXSID70333610",
      "ZINC3626195",
      "0637AC",
      "AKOS016011468",
      "FCH1154656",
      "ACN-049309",
      "AJ-45130",
      "2-Hydroxy-1,3(2H,4H)-Isoquinolinedione",
      "KB-230940",
      "AX8246098",
    ],
  },
  {
    primary_id: "D05SSC",
    names: ["Dalcetrapib"],
  },
  {
    primary_id: "D05SSJ",
    names: ["Dotarizine", "Dotarazine", "FI-6020", "FI-6026"],
  },
  {
    primary_id: "D05STL",
    names: ["ARRY-502"],
  },
  {
    primary_id: "D05SUA",
    names: [
      "A-798",
      "A-429",
      "A-655",
      "A-665798",
      "A-748",
      "A-761",
      "A-780748",
      "MCHR-1 Antagonists (Obesity)",
      "Melanin Concentrating Hormone-1 Receptor Antagonists, Abbott",
      "MCH-1 Antagonists (Obesity), Abbott",
      "MCHR-1 Antagonists (Obesity), Abbott",
    ],
  },
  {
    primary_id: "D05SVA",
    names: ["5-(Piperidin-4-Yl)Isoxazol-3-Ol", "5-Piperidin-4-Yl-Isoxazol-3-Ol"],
  },
  {
    primary_id: "D05SVN",
    names: ["PdB-W433F"],
  },
  {
    primary_id: "D05SVQ",
    names: ["C-AFG"],
  },
  {
    primary_id: "D05SWA",
    names: ["Azetidinyl-Piperidine Derivative 3"],
  },
  {
    primary_id: "D05SWL",
    names: ["PMID25666693-Compound-28"],
  },
  {
    primary_id: "D05SWR",
    names: ["N-(2,5-Dichlorophenyl)-4-Methoxy-3-Nitrobenzamide"],
  },
  {
    primary_id: "D05SWY",
    names: ["3-Dimethylaminomethyl-1H-Indol-4-Ol", "CHEMBL194588", "BDBM50171272"],
  },
  {
    primary_id: "D05SXB",
    names: ["SMT-D004"],
  },
  {
    primary_id: "D05SXC",
    names: ["SCR-44001"],
  },
  {
    primary_id: "D05SXF",
    names: ["IM-12"],
  },
  {
    primary_id: "D05SXL",
    names: ["PLR-8"],
  },
  {
    primary_id: "D05SXT",
    names: ["INP-03", "Antidote (Pressurized Olfactory Delivery, Nerve Agent Exposure), Impel NeuroPharma"],
  },
  {
    primary_id: "D05SXU",
    names: [
      "2-(2-Naphthamido)Benzoic Acid",
      "SCHEMBL8044197",
      "CHEMBL200536",
      "BDBM16098",
      "2-(Naphthalene-2-Amido)Benzoic Acid",
      "AKOS000132452",
      "2-[(Naphthalen-2-Ylcarbonyl)Amino]benzoic Acid",
    ],
  },
  {
    primary_id: "D05SYL",
    names: ["CYM5181", "CYM-5181"],
  },
  {
    primary_id: "D05SYO",
    names: ["KR-33889"],
  },
  {
    primary_id: "D05SYY",
    names: [
      "7-Bromo-2-Phenyl-[1,8]naphthyridin-4-Ol",
      "CHEMBL97687",
      "BDBM50090700",
      "ZINC13579521",
      "7-Bromo-2-Phenyl-1,8-Naphthyridin-4-Ol",
    ],
  },
  {
    primary_id: "D05SZH",
    names: ["BMS-265246", "BMS 265246", "BMS265246"],
  },
  {
    primary_id: "D05SZP",
    names: [
      "WR-080539",
      "4'-Acetamidochalcone",
      "CCRIS 2216",
      "NSC 40307",
      "CHEMBL187926",
      "19337-19-0",
      "N-{4-[(2E)-3-Phenylprop-2-Enoyl]phenyl}acetamide",
      "AC1NS7QX",
      "N-(4-Cinnamoylphenyl)Acetamide",
      "MolPort-018-573-532",
      "NSC40307",
      "ZINC4612934",
      "STL426808",
      "BBL035434",
      "NSC-40307",
      "BDBM50154614",
      "AKOS003620252",
      "N-[4-(Trans-Cinnamoyl)Phenyl]acetamide",
      "LS-188491",
      "N-[4-(3-Phenyl-Acryloyl)-Phenyl]-Acetamide",
    ],
  },
  {
    primary_id: "D05TBI",
    names: [
      "DG-770",
      "Beta Cell Regeneration Factor, DeveloGen",
      "EVT-770",
      "Beta Cell Mass Regenerators (Diabetes), DeveloGen",
      "Beta Cell Regenerators (Diabetes), DeveloGen/ MedImmune",
    ],
  },
  {
    primary_id: "D05TBQ",
    names: ["SAR408701"],
  },
  {
    primary_id: "D05TBZ",
    names: ["PMID30273516-Compound-12"],
  },
  {
    primary_id: "D05TCC",
    names: ["TMX-202"],
  },
  {
    primary_id: "D05TDJ",
    names: ["TS01"],
  },
  {
    primary_id: "D05TDS",
    names: ["Nu-2"],
  },
  {
    primary_id: "D05TEW",
    names: [
      "(4e)-4-Aminohex-4-Enoic Acid",
      "(4E)-4-AMINOHEX-4-ENOIC ACID",
      "AC1NRBEN",
      "SCHEMBL4320657",
      "(E)-4-Aminohex-4-Enoic Acid",
    ],
  },
  {
    primary_id: "D05TFE",
    names: ["SIB-1757"],
  },
  {
    primary_id: "D05TFM",
    names: ["2-Ethyl-4-(Furan-2-Yl)Thieno[3,2-D]pyrimidine", "CHEMBL265695", "SCHEMBL3677848"],
  },
  {
    primary_id: "D05TFW",
    names: ["H1N1 HA Flu Vaccine"],
  },
  {
    primary_id: "D05TGQ",
    names: [
      "PMID25815140C48",
      "CNT2 Inhibitor-1",
      "GTPL8485",
      "BDBM50085527",
      "HY-112843",
      "CS-0066911",
      "880155-70-4",
    ],
  },
  {
    primary_id: "D05TGU",
    names: [
      "1,1,1,3-Tetrafluoro-6-Phenylhexan-2-One",
      "1071001-32-5",
      "SCHEMBL3487610",
      "CHEMBL1095335",
      "ZJWMBXPFXFSELY-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D05TGV",
    names: ["PEG-Infergen"],
  },
  {
    primary_id: "D05THD",
    names: ["2-(Biphenyl-4-Ylsulfonyl)N-Hydroxybenzamide", "CHEMBL574589"],
  },
  {
    primary_id: "D05THI",
    names: ["4-Chloro-N-(3-Chloro-Benzoyl)-Benzenesulfonamide", "CHEMBL359783"],
  },
  {
    primary_id: "D05THV",
    names: ["ISIS 222035"],
  },
  {
    primary_id: "D05TIB",
    names: ["Trospium"],
  },
  {
    primary_id: "D05TIP",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 2"],
  },
  {
    primary_id: "D05TIS",
    names: ["Glucose-6-Phosphate"],
  },
  {
    primary_id: "D05TJR",
    names: ["Fluvirin"],
  },
  {
    primary_id: "D05TKB",
    names: ["GS-9256"],
  },
  {
    primary_id: "D05TKO",
    names: [
      "Retagliptin",
      "DPP4 Inhibitors, Shanghai Hengrui Pharmaceutical Co Ltd",
      "SHR-1039",
      "SHR-1040",
      "DPPIV Inhibitors (Diabetes), Shanghai Hengrui Pharmaceutical Co Ltd",
      "Dipeptidyl Peptidase IV Inhibitors (Diabetes), Shanghai Hengrui Pharmaceutical Co Ltd",
    ],
  },
  {
    primary_id: "D05TKW",
    names: [
      "Alpha-Ketothiazole Analogue 36",
      "GTPL6549",
      "CHEMBL273653",
      "BDBM12976",
      "(2S)-2-[[(1R)-1-(4-Bromophenyl)Ethyl]carbamoylamino]-5-(Carbamoylamino)-N-[(2S)-1-[[(2S)-5-(Diaminomethylideneamino)-1-Oxo-1-(1,3-Thiazol-2-Yl)Pentan-2-Yl]amino]-3-Methyl-1-Oxobutan-2-Yl]pentanamide",
    ],
  },
  {
    primary_id: "D05TLB",
    names: ["Octyl Sulfamate"],
  },
  {
    primary_id: "D05TLM",
    names: [
      "E-5065",
      "7-(3-Aminoazetidin-1-Yl)-8-Chloro-1-Cyclopropyl-6-Fluoro-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05TLP",
    names: ["Carabersat"],
  },
  {
    primary_id: "D05TLQ",
    names: ["ETR-001"],
  },
  {
    primary_id: "D05TLR",
    names: ["Tetramune", "DTP-HBOC", "Haemophilus/DTP Vaccine, Lederle"],
  },
  {
    primary_id: "D05TMA",
    names: ["MAGE-A4 TCR"],
  },
  {
    primary_id: "D05TMJ",
    names: [
      "1-Phenyl-3-(2-(Pyridin-2-Yl)Quinazolin-4-Yl)Urea",
      "CHEMBL423311",
      "BDBM50088476",
      "1-[2-(2-Pyridyl)-4-Quinazolinyl]-3-Phenylurea",
    ],
  },
  {
    primary_id: "D05TMN",
    names: ["PMID25514969-Compound-8"],
  },
  {
    primary_id: "D05TMQ",
    names: [
      "Aprobarbital",
      "Allional",
      "Allonal",
      "Allylisopropylmalonylurea",
      "Allylpropymal",
      "Allypropymal",
      "Allypropymalum",
      "Alurate",
      "Aprobarbita",
      "Aprobarbitale",
      "Aprobarbitalum",
      "Aprobarbitone",
      "Aprozal",
      "Numal",
      "Allylisopropylbarbituric Acid",
      "Alurate Elixir Verdum",
      "Aprobarbitale [DCIT]",
      "Isopropylallylbarbituric Acid",
      "Alurate (TN)",
      "Aprobarbital (INN)",
      "Aprobarbital [INN:DCF]",
      "Aprobarbitalum [INN-Latin]",
      "Isonal (Swedish)",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5-(1-Methylethyl)-5-(2-Propenyl)-(9CI)",
      "5-(1-Methylethyl)-5-(2-Propenyl)-2,4,6(1H,3H,5H)-Pyrimidinetrione;5-(1-Methylethyl)-5-Prop-2-En-1-Ylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-(Propan-2-Yl)-5-(Prop-2-En-1-Yl)Pyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-Allyl-5-Isopropylbarbiturate",
      "5-Allyl-5-Isopropylbarbituricacid",
      "5-Isopropyl-5-Allylbarbituric Acid",
      "5-Allyl-5-Isopropylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-Propan-2-Yl-5-Prop-2-Enyl-1,3-Diazinane-2,4,6-Trione",
    ],
  },
  {
    primary_id: "D05TNI",
    names: ["FITC-LMNNAEHINQFYMFI"],
  },
  {
    primary_id: "D05TNT",
    names: ["ST074946"],
  },
  {
    primary_id: "D05TNX",
    names: ["Fluprostenol"],
  },
  {
    primary_id: "D05TNY",
    names: ["Generalized Glucocorticoid"],
  },
  {
    primary_id: "D05TOJ",
    names: ["Peretinoin"],
  },
  {
    primary_id: "D05TOY",
    names: ["Dmt-Pro-3,5Dmp-Phe-NH2", "CHEMBL389949"],
  },
  {
    primary_id: "D05TPA",
    names: ["SP-1062C-O"],
  },
  {
    primary_id: "D05TPI",
    names: [
      "EPICATECHIN",
      "(-)-Epicatechin",
      "Epicatechin",
      "490-46-0",
      "L-Epicatechin",
      "(-)-Epicatechol",
      "L-Acacatechin",
      "(2R,3R)-2-(3,4-Dihydroxyphenyl)Chroman-3,5,7-Triol",
      "Epicatechol",
      "L-Epicatechol",
      "(-)Epicatechin",
      "(2R,3R)-(-)-Epicatechin",
      "UNII-34PHS7TU43",
      "Epi-Catechin",
      "Epi-Catechol",
      "CCRIS 7097",
      "EINECS 207-710-1",
      "NSC 81161",
      "Epicatechol, (-)-",
      "CHEBI:90",
      "34PHS7TU43",
      "PFTAWBLQPZVEMU-UKRRQHHQSA-N",
      "(2R,3R)-2-(3,4-Dihydroxyphenyl)-3,4-Dihydro-2H-Chromene-3,5,7-Triol",
      "(-)-(2R:3R)-5,7,3',4'-Tetrahydroxyflavan-3-Ol",
      "AK105372",
      "C15H14O6",
    ],
  },
  {
    primary_id: "D05TPU",
    names: [
      "8-(2,4,5-Trimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "1H-Purine-2,6-Diamine, 8-(3,4,5-Trimethoxyphenyl)-",
      "CHEMBL105523",
      "194613-10-0",
      "8-(2,4,5-Trimethoxyphenyl)-3h-Purine-2,6-Diamine",
      "2,6-Diamino-8-(2',4',5'-Trimethoxyphenyl)Purine",
      "AC1Q4FDE",
      "AC1LAR90",
      "CTK4E1570",
      "DTXSID40173101",
      "BDBM50059923",
      "AKOS030559902",
      "8-(2,4,5-Trimethoxyphenyl)-7H-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D05TQG",
    names: ["Pyrazole Derivative 30"],
  },
  {
    primary_id: "D05TQP",
    names: ["NSC-89508"],
  },
  {
    primary_id: "D05TQX",
    names: ["O-Oleoyl-N-(2-Hydroxyethyl)Hydroxylamine", "CHEMBL380925", "ZINC36294908"],
  },
  {
    primary_id: "D05TRI",
    names: ["GeNOsyl"],
  },
  {
    primary_id: "D05TRJ",
    names: ["SB-728-HSPC"],
  },
  {
    primary_id: "D05TRN",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Cycloheptyl Ester",
      "CHEMBL349091",
      "BDBM50139113",
      "4-(Sulfamoyloxy)Benzoic Acid Cycloheptyl Ester",
    ],
  },
  {
    primary_id: "D05TRX",
    names: ["N-[3-(2-Ethyl-Benzooxazol-7-Yl)-Propyl]-Acetamide", "CHEMBL125665", "SCHEMBL6789609"],
  },
  {
    primary_id: "D05TSG",
    names: ["ANB-020"],
  },
  {
    primary_id: "D05TSJ",
    names: ["LY2784544"],
  },
  {
    primary_id: "D05TSP",
    names: ["Thiadiazolo[2,3-A]pyrimidine"],
  },
  {
    primary_id: "D05TUL",
    names: ["MB-11316"],
  },
  {
    primary_id: "D05TUQ",
    names: ["MAT-302", "Anti-CD160 Antibody (Solid Tumor/Ocular Disease), MAT Biopharma"],
  },
  {
    primary_id: "D05TUZ",
    names: ["Tasonermin", "Beromun (TN)"],
  },
  {
    primary_id: "D05TVG",
    names: ["[1-Bromo-(3S)-Hydrox-4-(Palmitoyloxy)Butyl]phosphate", "GTPL6991", "CHEMBL3621354"],
  },
  {
    primary_id: "D05TVI",
    names: ["TD-9594"],
  },
  {
    primary_id: "D05TVT",
    names: ["6-Ethyl-3-(3-Pentoxycarbonyl)-4-Quinolone", "CHEMBL205528"],
  },
  {
    primary_id: "D05TVU",
    names: [
      "A-224940",
      "CHEMBL364377",
      "635755-66-7",
      "SCHEMBL5884420",
      "CTK2A8845",
      "DTXSID90623944",
      "BDBM50152424",
      "8-(Cyclohexylmethoxy)Quinolin-2-Amine",
      "8-Cyclohexylmethoxy-Quinolin-2-Ylamine",
      "2-Quinolinamine, 8-(Cyclohexylmethoxy)-",
    ],
  },
  {
    primary_id: "D05TVW",
    names: ["DCVax-Head/Neck", "Dendritic Cell-Based Immunotherapy (Head/Neck Tumor), Northwest Biotherapeutics"],
  },
  {
    primary_id: "D05TWD",
    names: ["Autologous VEGF-And Angiopoietin-1-Expressing Endothelial And Smooth Muscle Cell Therapy"],
  },
  {
    primary_id: "D05TWV",
    names: [
      "(+/-)-Threo-N-Methyl-30-Methylmethylphenidate",
      "CHEMBL19686",
      "BDBM50291690",
      "(R)-((R)-1-Methyl-Piperidin-2-Yl)-M-Tolyl-Acetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D05TXS",
    names: ["BMN-168"],
  },
  {
    primary_id: "D05TYI",
    names: ["Azaindole Derivative 2"],
  },
  {
    primary_id: "D05TYV",
    names: ["CXCR3 Antagonists, GlaxoSmithKline", "CXCR3 Antagonists (Inflammatory Diseases), GlaxoSmithKline"],
  },
  {
    primary_id: "D05TZE",
    names: ["SKF-89124A"],
  },
  {
    primary_id: "D05TZJ",
    names: ["Ankinara"],
  },
  {
    primary_id: "D05TZK",
    names: ["NSC-693571"],
  },
  {
    primary_id: "D05TZN",
    names: [
      "Des-Arg10-Kallidin",
      "71800-36-7",
      "Kallidin, Des-Arg(10)-",
      "Lys-[Des-Arg9]Bradykinin",
      "CHEMBL264100",
      "[des-Arg10]kallidin",
      "(Des-Arg10)Kallidin",
      "Lys-[des-Arg9]-Bradykinin",
      "Lys-(Des-Arg9)-Bradykinin",
      "Bradykinin, Lys-Desarg(9)",
      "10-De-Arg-Kallidin",
      "Lys-Des-Arg(9)-BK",
      "Des-Arg(10)-Kallidin",
    ],
  },
  {
    primary_id: "D05TZP",
    names: ["Tert-Butyl 3-(2-Oxo-8-Phenyloctanamido)Propanoate", "CHEMBL488918"],
  },
  {
    primary_id: "D05UAA",
    names: ["SX-HIV1"],
  },
  {
    primary_id: "D05UAO",
    names: ["J591-Ac-225"],
  },
  {
    primary_id: "D05UAV",
    names: [
      "Bis(Olanzapine) Pamoate Monohydrate",
      "Bis[2-Methyl-4-(4-Methylpiperazin-1-Yl)-10H-Thieno[2,3-B][1,5]benzodiazepine] 4,4'-Methylenebis(3-Hydroxynaphthalen-2-Carboxylic Acid) Salt Monohydrate",
    ],
  },
  {
    primary_id: "D05UBB",
    names: [
      "(S)-4-CMTB",
      "CHEMBL594525",
      "GTPL3420",
      "SCHEMBL16369612",
      "BDBM50305980",
      "(S)-2-(4-Chlorophenyl)-N-(5-Fluorothiazol-2-Yl)-3-Methylbutanamide",
    ],
  },
  {
    primary_id: "D05UBX",
    names: [
      "Cefmetazole",
      "CMZ",
      "Cefmetazolo",
      "Cefmetazolum",
      "Cefmetazole Monosodium Salt",
      "CS 1170",
      "SKF 83088",
      "U 72791",
      "CS-1170",
      "Cefmetazole [USAN:INN]",
      "Cefmetazolo [INN-Spanish]",
      "Cefmetazolum [INN-Latin]",
      "U-72791A",
      "Cefmetazole (USP/INN)",
      "(6R,7S)-7-(2-((Cyanomethyl)Thio)Acetamido)-7-Methoxy-3-(((1-Methyl-1H-Tetrazol-5-Yl)Thio)Methyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7S)-7-(2-((Cyanomethyl)Thio)Acetamido)-7-Methoxy-3-(((1-Methyl-1H-Tetrazol-5-Yl)Thiomethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, Sodium Salt",
      "(6R,7S)-7-({[(Cyanomethyl)Sulfanyl]acetyl}amino)-7-Methoxy-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7S)-7-({[(Cyanomethyl)Thio]acetyl}amino)-7-(Methyloxy)-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Thio]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7S)-7-[[2-(Cyanomethylsulfanyl)Acetyl]amino]-7-Methoxy-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "6beta-{[(Cyanomethyl)Sulfanyl]acetamido}-6alpha-Methoxy-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}ceph-3-Em-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05UCW",
    names: ["MEDI-2338"],
  },
  {
    primary_id: "D05UDU",
    names: ["YK-3237", "Angiogenesis Inhibitors (Cancer)", "Angiogenesis Inhibitors (Cancer), Georgetown University"],
  },
  {
    primary_id: "D05UEF",
    names: ["RR-1"],
  },
  {
    primary_id: "D05UEI",
    names: ["99mTc-Besilesomab"],
  },
  {
    primary_id: "D05UFG",
    names: [
      "BIBW 2992",
      "Afatinib",
      "Tomtovok",
      "Tovok",
      "BIBW-2992",
      "Tovok (TN)",
      "Tovok, BIBW2992",
      "(2E)-N-{4-[(3-Chloro-4-Fluorophenyl)Amino]-7-[(3S)-Tetrahydrofuran-3-Yloxy]quinazolin-6-Yl}-4-(Dimethylamino)But-2-Enamide",
      "EGFR Inhibitor 2nd Gens",
    ],
  },
  {
    primary_id: "D05UFJ",
    names: ["Minnelide 001"],
  },
  {
    primary_id: "D05UFN",
    names: ["PN-202"],
  },
  {
    primary_id: "D05UFP",
    names: ["PEG-SN38"],
  },
  {
    primary_id: "D05UGC",
    names: ["Muramyl Dipeptide"],
  },
  {
    primary_id: "D05UGH",
    names: [
      "LEO 124249",
      "Delgocitinib",
      "UNII-9L0Q8KK220",
      "JTE-052",
      "9L0Q8KK220",
      "1263774-59-9",
      "Delgocitinib [USAN]",
      "Delgocitinib (JAN/USAN)",
      "JTE052",
      "JTE-052A",
      "GTPL9619",
      "SCHEMBL12547007",
      "LEO 124249A",
      "HY-109053",
      "CS-0031558",
      "D11046",
      "1,6-Diazaspiro(3.4)Octane-1-Propanenitrile, 3-Methyl-Beta-Oxo-6-(7H-Pyrrolo(2,3-D)Pyrimidin-4-Yl)-, (3S,4R)-",
      "3-((3S,4R)-3-Methyl-6-(7H-Pyrrolo(2,3-D)Pyrimidin-4-Yl)-1,6-Diazaspiro(3.4)Octan-1-Yl)-3-Oxopropanenitrile",
    ],
  },
  {
    primary_id: "D05UGJ",
    names: ["XL844"],
  },
  {
    primary_id: "D05UGL",
    names: ["1-Hydroxyl-3,5-Bis(4-Hydroxylstyryl)Benzene Derivative 1"],
  },
  {
    primary_id: "D05UGS",
    names: ["PMID27109571-Compound-11"],
  },
  {
    primary_id: "D05UHB",
    names: [
      "(Tert-Butyloxycarbonyl)-Alanyl-Alanyl-Amine",
      "(TERT-BUTYLOXYCARBONYL)-ALANYL-ALANYL-AMINE",
      "Boc-Ala-Ala-NH2",
      "AC1NUZE3",
      "SCHEMBL4327766",
      "CTK5J1322",
      "DB03757",
      "41036-24-2",
      "N-(Tert-Butoxycarbonyl)-L-Alanyl-L-Alaninamide",
      "Tert-Butyl N-[(2S)-1-[[(2S)-1-Amino-1-Oxopropan-2-Yl]amino]-1-Oxopropan-2-Yl]carbamate",
    ],
  },
  {
    primary_id: "D05UHF",
    names: [
      "Galsulfase",
      "Aryplase",
      "Aryplase (TN)",
      "Galsulfase (Genetical Recombination)",
      "Galsulfase (USAN/INN)",
      "Galsulfase (Genetical Recombination) (JAN)",
    ],
  },
  {
    primary_id: "D05UHJ",
    names: [
      "SM-10888",
      "AC1L52EB",
      "2,4-Methanoacridin-9-Amine, 8-Fluoro-1,2,3,4-Tetrahydro-, 2-Hydroxy-1,2,3-Propanetricarboxylate (3:2)",
    ],
  },
  {
    primary_id: "D05UHO",
    names: ["2-N-Ethylamino-1-(4-Ethylthiophenyl)Propane", "CHEMBL1077873"],
  },
  {
    primary_id: "D05UIL",
    names: ["TV-4710"],
  },
  {
    primary_id: "D05UJF",
    names: ["PBSVax"],
  },
  {
    primary_id: "D05UJQ",
    names: ["Alkyl Sulfone Derivative 1"],
  },
  {
    primary_id: "D05UJS",
    names: ["ADVAX"],
  },
  {
    primary_id: "D05UJT",
    names: ["GNF-2"],
  },
  {
    primary_id: "D05UJU",
    names: ["MV-2101"],
  },
  {
    primary_id: "D05UJV",
    names: ["N-N-Pentyl-7-Methoxytacrine Hydrochloride", "CHEMBL1257886"],
  },
  {
    primary_id: "D05UKJ",
    names: [
      "CYCLOPAMINE",
      "11-Deoxojervine",
      "4449-51-8",
      "UNII-ZH658AJ192",
      "CHEMBL254129",
      "CHEBI:4021",
      "11-Deoxyjervine",
      "ZH658AJ192",
      "HSDB 3505",
      "Jervine, 11-Deoxo-",
      "Spiro[9H-Benzo[a]fluorene-9,2'(3'H)-Furo[3,2-B]pyridin]-3-Ol, 1,2,3,3'a,4,4',5',6,6',6a,6b,7,7',7'a,8,11,11a,11b-Octadecahydro-3',6',10,11b-Tetramethyl-, (2'R,3S,3'R,3'aS,6'S,6aS,6bS,7'aR,11aS,11bR)- (9CI)",
      "(3beta,22S,23R)-17,23-Epoxyveratraman-3-Ol",
      "Veratraman-3-Ol, 17,23-Epoxy-, (3beta,23beta)-",
      "CY8",
      "[3H]-Cyclopamine",
      "AC1L9DRQ",
      "DSSTox_CID_23709",
      "DSSTox_RID_80067",
      "SCHEMBL29153",
    ],
  },
  {
    primary_id: "D05UKK",
    names: ["FK-888"],
  },
  {
    primary_id: "D05ULV",
    names: ["4-Methoxy-9-Aminomethyl-9,10-Dihydroanthracene", "CHEMBL454491", "BDBM50264950"],
  },
  {
    primary_id: "D05UMA",
    names: [
      "(R)-DULOXETINE",
      "(R)-Duloxetine",
      "Duloxetine, (R)-",
      "116539-60-7",
      "UNII-TK9VOT90JQ",
      "R-Duloxetine HCl",
      "TK9VOT90JQ",
      "CHEMBL336920",
      "CHEBI:36797",
      "(3R)-N-Methyl-3-(1-Naphthyloxy)-3-(2-Thienyl)Propan-1-Amine",
      "(3R)-N-Methyl-3-(Naphthalen-1-Yloxy)-3-(2-Thienyl)Propan-1-Amine",
      "Duloxetine Impurity A",
      "Ariclaim",
      "Duloxetine Hydrochloride Impurity A [EP]",
      "Duloxetine Hydrochloride Specified Impurity A [EP]",
      "SCHEMBL1200511",
      "(R)-N-Methyl-3-(1-Naphthoxy)-3-(2-Thienyl)Propylamine",
      "DTXSID40151424",
      "ZINC1536780",
      "PDSP1_000970",
    ],
  },
  {
    primary_id: "D05UMD",
    names: ["R-87366"],
  },
  {
    primary_id: "D05UMO",
    names: ["BAY-793"],
  },
  {
    primary_id: "D05UNM",
    names: ["PMID25399762-Compound-Table1-C12"],
  },
  {
    primary_id: "D05UNO",
    names: ["Imidazo Triazine Derivative 4"],
  },
  {
    primary_id: "D05UOU",
    names: ["CD19-CAR-T2 Cells"],
  },
  {
    primary_id: "D05UOW",
    names: [
      "DFMO",
      "96020-91-6",
      "Eflornithine ,eflornithine Hydrochloride Hydrate",
      "Difluoromethylornithine Hydrochloride Hydrate",
    ],
  },
  {
    primary_id: "D05UPB",
    names: ["CI-972"],
  },
  {
    primary_id: "D05UPM",
    names: [
      "4-Benzyloxy-2'-Hydroxychalcone",
      "CHEMBL242078",
      "(E)-3-(4-(Benzyloxy)Phenyl)-1-(2-Hydroxyphenyl)Prop-2-En-1-One",
      "AC1O2DDI",
      "SCHEMBL10768190",
      "4-Benzyloxy-2''-Hydroxychalcone",
      "2'-Hydroxy-4-(Benzyloxy)Chalcone",
      "ZINC4775332",
      "BDBM50241204",
      "AKOS024335342",
      "(E)-1-(2-Hydroxyphenyl)-3-(4-Phenylmethoxyphenyl)Prop-2-En-1-One",
    ],
  },
  {
    primary_id: "D05UPS",
    names: ["5-(4-Hydroxy-2,6-Dimethylstyryl)Nicotinic Acid", "CHEMBL216668"],
  },
  {
    primary_id: "D05UQC",
    names: ["PMID2296036C2t"],
  },
  {
    primary_id: "D05UQD",
    names: [
      "Omapatrilat",
      "BMS-186716",
      "Omapatrilat (JAN/USAN/INN)",
      "(4S,7S,10aS)-5-Oxo-4-[[(2S)-3-Phenyl-2-Sulfanylpropanoyl]amino]-2,3,4,7,8,9,10,10a-Octahydropyrido[2,1-B][1,3]thiazepine-7-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D05UQF",
    names: ["NCX-226"],
  },
  {
    primary_id: "D05URH",
    names: ["Zaragozic Acid D"],
  },
  {
    primary_id: "D05URN",
    names: ["Dimethyl"],
  },
  {
    primary_id: "D05URO",
    names: [
      "Quinvaxem",
      "DTPw-HepB-Hib",
      "DTP-HBV-Hib Vaccine, Berna/Chiron",
      "DTP-HBV-Hib Vaccine, Berna/Novartis",
      "DTP-HBV-Hib Vaccine, Crucell/Chiron",
      "DTP-HBV-Hib Vaccine, Crucell/Novartis",
      "DTPw-HBV-Hib Vaccine, Berna/Chiron",
      "DTPw-HBV-Hib Vaccine, Berna/Novartis",
      "DTPw-HBV-Hib Vaccine, Crucell/Chiron",
      "DTPw-HBV-Hib Vaccine, Crucell/Novartis",
      "DTwP-Hep B-Hib Vaccine, Crucell/Novartis",
      "Diphtheria/Tetanus/Pertussis/Haemophilus Influenzae Type B, Hepatitis B Pentavalent Vaccine, Crucell/Novartis",
    ],
  },
  {
    primary_id: "D05URT",
    names: ["MX-7091"],
  },
  {
    primary_id: "D05URZ",
    names: ["Carmeseal"],
  },
  {
    primary_id: "D05USH",
    names: [
      "4-(4-Fluoro-Benzyl)-Piperidine Hydrochloride",
      "4-(4-Fluorobenzyl)Piperidine Hydrochloride",
      "92822-03-2",
      "193357-52-7",
      "C12H17ClFN",
      "CHEMBL1204355",
      "4-(4'-FLUOROBENZYL)PIPERIDINE HYDROCHLORIDE",
      "Piperidine, 4-[(4-Fluorophenyl)Methyl]-, Hydrochloride",
      "4-(4-FLUOROBENZYL)PIPERIDINE HCL",
      "AK134910",
      "4-[(4-Fluorophenyl)Methyl]piperidine Hydrochloride",
      "4-(4-FLUOROBENZYL)PIPERIDINE HYDROCHLORIDE Salt",
      "SCHEMBL4247331",
      "KS-00000DOL",
      "CTK7C0359",
      "DTXSID30588780",
      "MolPort-001-778-599",
      "OJKWWANXBGLGQN-UHFFFAOYSA-N",
      "4NPA-Q05-1",
      "ZX-AV000292",
      "ZX-CM003409",
    ],
  },
  {
    primary_id: "D05USL",
    names: ["KU-55933"],
  },
  {
    primary_id: "D05UTC",
    names: ["PT-103"],
  },
  {
    primary_id: "D05UTF",
    names: ["SB-41947"],
  },
  {
    primary_id: "D05UTO",
    names: ["[D-Dap(CO-NH-OH)3]degarelix", "CHEMBL428484"],
  },
  {
    primary_id: "D05UTV",
    names: ["Albuterpenoids", "Albuterpenoids (Topical, HPV Infection/Shingles/Pox Virus Infection/Warts)"],
  },
  {
    primary_id: "D05UTW",
    names: ["ErythroMax"],
  },
  {
    primary_id: "D05UTX",
    names: [
      "9-Butyl-8-(4-Methoxybenzyl)-9h-Purin-6-Amine",
      "9-BUTYL-8-(4-METHOXYBENZYL)-9H-PURIN-6-AMINE",
      "CHEMBL112834",
      "9-Butyl-8-[(4-Methoxyphenyl)Methyl]purin-6-Amine",
      "376629-53-7",
      "PU4",
      "AC1NRCWI",
      "1uy7",
      "Purine-Based Inhibitor 2",
      "SCHEMBL4320278",
      "CTK1A9432",
      "BDBM15375",
      "DTXSID80415355",
      "AKOS030562187",
      "DB03899",
      "8-(4-Methoxybenzyl)-9-Butyl-9H-Purine-6-Amine",
      "9-Butyl-8-[(4-Methoxyphenyl)Methyl]-9H-Purin-6-Amine",
      "9H-Purin-6-Amine, 9-Butyl-8-[(4-Methoxyphenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D05UUA",
    names: [
      "(+/-)-6-(2-Chlorophenyl)Cyclohex-3-Enamine",
      "Cyclohexene Analog 6",
      "SCHEMBL3750136",
      "CHEMBL216578",
      "BDBM12649",
    ],
  },
  {
    primary_id: "D05UUW",
    names: ["1-Dibenzo[b,f]oxepin-10-Yl-4-Methyl-Piperazine"],
  },
  {
    primary_id: "D05UVD",
    names: [
      "Timolol",
      "Aquanil",
      "Betim",
      "Betimol",
      "Blocadren",
      "Istalol",
      "Optimol",
      "Proflax",
      "TIM",
      "Temserin",
      "Tenopt",
      "Timacar",
      "Timololum",
      "Timopic",
      "Timoptol",
      "Timolol GFS",
      "Timolol [USAN]",
      "Timoptic OcuDose",
      "Apo-Timol",
      "Apo-Timop",
      "Betimol (TN)",
      "Blocadren (TN)",
      "Istalol (TN)",
      "MK-950",
      "Novo-Timol",
      "Nu-Timolol",
      "Phoxal-Timolol",
      "Tim-AK",
      "Timolol (INN)",
      "Timolol (TN)",
      "Timololum [INN-Latin]",
      "Timoptic (TN)",
      "Timoptic-XE",
      "Apo-Timol (TN)",
      "Apo-Timop (TN)",
      "Gen-Timolol (TN)",
      "L-714,465",
      "Nu-Timolol (TN)",
      "PMS-Timolol (TN)",
      "Phoxal-Timolol (TN)",
      "Tim-AK (TN)",
      "Timoptic OcuDose. (TN)",
      "Timoptic-XE (TN)",
      "S(-)-Timolol Maleate",
      "Timolol Maleate, (1:1) Salt",
      "S-(-)-3-(3-Tert-Butylamino-2-Hydroxypropoxy)-4-Morpholino-1,2,5-Thiadiazole",
      "(-)-3-Morpholino-4-(3-Tert-Butylamino-2-Hydroxypropoxy)-1,2,5-Thiadiazole",
      "(2S)-1-((1,1-Dimethylethyl)Amino)-3-((4-(4-Morpholinyl)-1,2,5-Thiadiazol-3-Yl)Oxy)-2-Propanol",
      "(2S)-1-(Tert-Butylamino)-3-[(4-Morpholin-4-Yl-1,2,5-Thiadiazol-3-Yl)Oxy]propan-2-Ol",
      "(2S)-1-[(1,1-Dimethylethyl)Amino]-3-[(4-Morpholin-4-Yl-1,2,5-Thiadiazol-3-Yl)Oxy]propan-2-Ol",
      "(S)-1-(1,1-(Dimethylethyl)Amino)-3-((4-(4-Morpholinyl)-1,2,5-Thiadiazol-3-Yl)Oxy)-2-Propanol",
      "(S)-1-(Tert-Butylamino)-3-((4-Morpholino-1,2,5-Thiadiazol-3-Yl)Oxy)Propan-2-Ol",
      "(S)-Timolol",
    ],
  },
  {
    primary_id: "D05UVL",
    names: ["SND-121"],
  },
  {
    primary_id: "D05UWI",
    names: ["Parecoxib"],
  },
  {
    primary_id: "D05UWQ",
    names: [
      "Methionine Benzimidazole 6",
      "CHEMBL1770297",
      "N-[1-(1H-Benzimidazol-2-Yl)-3-Methylsulfanylpropyl]-5-Ethoxy-3-Methyl-1-Benzofuran-2-Carboxamide",
      "AC1N7MKZ",
      "GTPL5829",
    ],
  },
  {
    primary_id: "D05UWW",
    names: [
      "RM65",
      "CHEMBL61824",
      "GTPL7029",
      "SCHEMBL17767405",
      "BDBM50215479",
      "2-(9H-Xanthen-9-Ylsulfanyl)-N-{2-[2-(9H-Xanthen-9-Ylsulfanyl)Propanamido]ethyl}propanamide",
    ],
  },
  {
    primary_id: "D05UWZ",
    names: ["Mallotinic Acid"],
  },
  {
    primary_id: "D05UXF",
    names: ["Emapunil"],
  },
  {
    primary_id: "D05UXK",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 29"],
  },
  {
    primary_id: "D05UXU",
    names: ["INM-176"],
  },
  {
    primary_id: "D05UYC",
    names: ["1,2,3-Benzenetricarboxylic Acid", "1,2,3-Benzenetricarboxylate"],
  },
  {
    primary_id: "D05UYQ",
    names: ["Camicinal", "962040"],
  },
  {
    primary_id: "D05UYW",
    names: ["Dorzolamide"],
  },
  {
    primary_id: "D05UZO",
    names: ["MIVOBULIN ISETHIONATE"],
  },
  {
    primary_id: "D05UZY",
    names: [
      "7-Fluorochromone-2-Carboxamide",
      "CHEMBL214021",
      "GTPL1303",
      "SCHEMBL2669492",
      "BDBM50197139",
      "LS-193885",
      "N-[1-(1,3-Benzodioxol-5-Ylmethyl)Piperidin-4-Yl]-7-Fluoro-4-Oxo-4H-Chromene-2-Carboxamide",
      "N-[1-(1,3-Benzodioxol-5-Ylmethyl)Piperidin-4-Yl]-7-Fluoro-4-Oxochromene-2-Carboxamide",
    ],
  },
  {
    primary_id: "D05VAA",
    names: ["Isbufylline"],
  },
  {
    primary_id: "D05VAB",
    names: [
      "AR-C102222",
      "CHEMBL447183",
      "5-(4-Amino-5,8-Difluorospiro[1H-Quinazoline-2,4'-Piperidine]-1'-Carbonyl)Pyridine-2-Carbonitrile",
      "1-(6-CYANO-3-PYRIDYLCARBONYL)-5',8'-DIFLUOROSPIRO[PIPERIDINE-4,2'(1'H)-QUINAZOLINE]-4'-AMINE",
      "SCHEMBL7806912",
      "ZINC1489460",
      "BDBM50124535",
      "DB07405",
      "NCGC00371031-01",
      "5-[(4'-Amino-5',8'-Difluoro-1H,1'H-Spiro[piperidine-4,2'-Quinazolin]-1-Yl)Carbonyl]pyridine-2-Carbonitrile",
      "5-(4-Amino-5,8-Difluorospiro[1H-Quinazoline-2,4'-Piperidine]-1'-Carbonyl)Pyridine-2-Carbonitrile, 3",
    ],
  },
  {
    primary_id: "D05VAE",
    names: ["Posiphen R-Phenserine"],
  },
  {
    primary_id: "D05VAN",
    names: [
      "1-Ethyl-3-Imidazol-1-Ylmethyl-1H-Indole",
      "CHEMBL115899",
      "72818-64-5",
      "SCHEMBL11496228",
      "CTK2G2099",
      "DTXSID10438234",
      "BDBM50022117",
      "ZINC27096766",
      "1-Ethyl-3-[(1H-Imidazol-1-Yl)Methyl]-1H-Indole",
      "1H-Indole, 1-Ethyl-3-(1H-Imidazol-1-Ylmethyl)-",
    ],
  },
  {
    primary_id: "D05VAR",
    names: ["AE-H"],
  },
  {
    primary_id: "D05VBJ",
    names: ["Triazolo-Pyridine Derivative 4"],
  },
  {
    primary_id: "D05VBN",
    names: ["ISIS 6186"],
  },
  {
    primary_id: "D05VCA",
    names: [
      "IP10.C8-1",
      "DPIV/AAP Inhibitor (PETIR, Tranplant Rejection), IMTM",
      "IP10.C8-1 (PETIR, Tranplant Rejection), IMTM",
    ],
  },
  {
    primary_id: "D05VDI",
    names: [
      "Aggrecanase-2 Inhibitors",
      "Aggrecanase-2 Inhibitors (Osteoarthritis)",
      "Aggrecanase-2 Inhibitors (Osteoarthritis), Array BioPharma/Japan Tobacco",
    ],
  },
  {
    primary_id: "D05VEC",
    names: ["C-[-Arg-Gly-Asp-Acpca30-]", "CHEMBL540622"],
  },
  {
    primary_id: "D05VEH",
    names: ["Detirelix", "102583-46-0", "89662-30-6", "RS-68439", "Detirelix Acetate"],
  },
  {
    primary_id: "D05VEP",
    names: ["DX-2802"],
  },
  {
    primary_id: "D05VEU",
    names: ["Coagulin-B"],
  },
  {
    primary_id: "D05VEV",
    names: ["Ac-HPhe-Leu-Ala-LeuVSMe", "CHEMBL207579"],
  },
  {
    primary_id: "D05VFO",
    names: ["Rh-Erythropoietin-Alfa"],
  },
  {
    primary_id: "D05VFP",
    names: ["7-(Naphthalen-2-Yloxy)-1-Oxazol-2-Yl-Heptan-1-One", "CHEMBL127328"],
  },
  {
    primary_id: "D05VFQ",
    names: ["TriTAb"],
  },
  {
    primary_id: "D05VFZ",
    names: ["ABP-710"],
  },
  {
    primary_id: "D05VGC",
    names: ["PMID21185185C21"],
  },
  {
    primary_id: "D05VGL",
    names: ["Tavaborole"],
  },
  {
    primary_id: "D05VGS",
    names: ["ALB-127158(A)"],
  },
  {
    primary_id: "D05VHB",
    names: ["EMP-123"],
  },
  {
    primary_id: "D05VIL",
    names: [
      "Nalmefene",
      "Alcofene",
      "Arthene",
      "Cervene",
      "Cessal",
      "Incystene",
      "Nalmefeno",
      "Nalmefenum",
      "Nalmetrene",
      "Nalmetreno",
      "Nalmetrenum",
      "Revex",
      "Soberal",
      "JF 1",
      "ORF 11676",
      "JF-1",
      "NIH-10365",
      "Nalmefeno [INN-Spanish]",
      "Nalmefenum [INN-Latin]",
      "Nalmetreno [INN-Spanish]",
      "Nalmetrenum [INN-Latin]",
      "ORF-11676",
      "Revex (TN)",
      "Nalmefene (USAN/INN)",
      "Nalmefene [USAN:BAN:INN]",
      "(5alpha)-17-(Cyclopropylmethyl)-4,5-Epoxy-6-Methylenemorphinan-3,14-Diol",
      "(5alpha)-17-(Cyclopropylmethyl)-4,5-Epoxy-6-Methylenemorphinon-3,14-Diol",
      "(5alpha)-17-(Cyclopropylmethyl)-6-Methylidene-4,5-Epoxymorphinan-3,14-Diol",
      "17-(Cyclopropylmethyl)-4,5alpha-Epoxy-6-Methylenemorphinan-3,14-Diol",
      "6-Desoxy-6-Methylenenaltrexone",
      "9a-(Cyclopropylmethyl)-4,5alpha-Epoxy-6-Methylen-3,14-Morphinandiol",
    ],
  },
  {
    primary_id: "D05VIN",
    names: ["Imidazole Derivative 2"],
  },
  {
    primary_id: "D05VIX",
    names: [
      "Gemfibrozil",
      "ApoGemfibrozil",
      "Ausgem",
      "Bolutol",
      "Brozil",
      "Cholespid",
      "Clearol",
      "Decrelip",
      "Elmogan",
      "Fetinor",
      "Fibratol",
      "Fibrocit",
      "GFZ",
      "Gemd",
      "Gemfibril",
      "Gemfibromax",
      "Gemfibrosil",
      "Gemfibrozilo",
      "Gemfibrozilum",
      "Gemhexal",
      "Gemlipid",
      "Gemnpid",
      "GenGemfibrozil",
      "Genlip",
      "Gozid",
      "Hidil",
      "Hipolixan",
      "Innogem",
      "Innogen",
      "Ipolipid",
      "Jezil",
      "Lanaterom",
      "Lifibron",
      "Lipazil",
      "Lipigem",
      "Lipira",
      "Lipizyl",
      "Lipozid",
      "Lipur",
      "Litarek",
      "Lopid",
      "Micolip",
      "Normolip",
      "NuGemfibrozil",
      "Pilder",
      "Polyxit",
      "Progemzal",
      "Reducel",
      "Regulip",
      "Renabrazin",
      "Sinelip",
      "Synbrozil",
      "Taborcil",
      "Tentroc",
      "Trialmin",
      "Alphapharm Brand Of Gemfibrozil",
      "Apo Gemfibrozil",
      "Apotex Brand Of Gemfibrozil",
      "Bayvit Brand Of Gemfibrozil",
      "Bexal Brand Of Gemfibrozil",
      "Biochemie Brand Of Gemfibrozil",
      "Bull Brand Of Gemfibrozil",
      "Cantabria Brand Of Gemfibrozil",
      "Chem Mart Brand Of Gemfibrozil",
      "Chem Mart Gemfibrozil",
      "DBL Gemfibrozil",
      "Douglas Brand Of Gemfibrozil",
      "Farmasierra Brand Of Gemfibrozil",
      "Faulding Brand Of Gemfibrozil;Ferrer Brand Of Gemfibrozil",
      "Gemfibrozilo Bayvit",
      "Gemfibrozilo Bexal",
      "Gemfibrozilo Ur",
      "Gen Gemfibrozil",
      "GenRX Gemfibrozil",
      "Genpharm Brand Of Gemfibrozil",
      "Gevilon Uno",
      "Healthsense Brand Of Gemfibrozil",
      "Healthsense Gemfibrozil",
      "Hexal Brand Of Gemfibrozil",
      "Ipsen Brand Of Gemfibrozil",
      "Lipox Gemfi",
      "Lopid R",
      "Menarini Brand Of Gemfibrozil",
      "Novo Gemfibrozil",
      "Novopharm Brand Of Gemfibrozil",
      "Nu Gemfibrozil",
      "Nu Pharm Brand Of Gemfibrozil",
      "PMS Gemfibrozil",
      "Parke Davis Brand Of Gemfibrozil",
      "Pfizer Brand Of Gemfibrozil",
      "Pharmascience Brand Of Gemfibrozil",
      "Quimifar Brand Of Gemfibrozil",
      "SBPA Gemfibrozil",
      "Sigma Brand Of Gemfibrozil",
      "TAD Brand Of Gemfibrozil",
      "Terry White Chemists Brand Of Gemfibrozil",
      "Terry White Chemists Gemfibrozil",
      "United Drug Brand Of Gemfibrozil",
      "Warner Lambert Brand Of Gemfibrozil",
      "CI719",
      "Gemfi 1A Pharma",
      "Apo-Gemfibrozil",
      "Bayvit, Gemfibrozilo",
      "Gem-S",
      "Gemfibrozil, GenRX",
      "Gemfibrozil, Healthsense",
      "Gemfibrozil, SBPA",
      "Gemfibrozilo [INN-Spanish]",
      "Gemfibrozilum [INN-Latin]",
      "Gen-Fibro;Gen-Gemfibrozil",
      "Jezil (TN)",
      "Lopid (TN)",
      "Low-Lip",
      "Novo-Gemfibrozil",
      "Nu-Gemfibrozil",
      "Nu-Pharm Brand Of Gemfibrozil",
      "PMS-Gemfibrozil",
      "TEVA-A",
      "WL-Gemfibrozil",
      "Warner-Lambert Brand Of Gemfibrozil",
      "GEMFIBROZIL (SEE ALSO PEROXISOME PROJECT (GEMFIBROZIL))",
      "Gemfibrozil [USAN:BAN:INN]",
      "Gen-Fibro (TN)",
      "PEROXISOME PROJECT (GEMFIBROZIL) (SEE ALSO GEMFIBROZIL)",
      "Gemfibrozil (JAN/USP/INN)",
      "Gemcor, Lopid, Jezil,Gen-Fibro, Gemfibrozil",
      "1A Brand Of Gemfibrozil",
      "2,2-Dimethyl-5-(2,5-Dimethylphenoxy)Pentanoic Acid",
      "2,2-Dimethyl-5-(2,5-Dimethylphenoxy)Valeriansaeure",
      "2,2-Dimethyl-5-(2,5-Xylyloxy)Valeriansaeure",
      "2,2-Dimethyl-5-(2,5-Xylyloxy)Valeric Acid",
      "5-(2,5-Dimethyl-Phenoxy)-2,2-Dimethyl-Pentanoic Acid",
      "5-(2,5-Dimethylphenoxy)-2,2-Dimethylpentanoic Acid",
      "5-[(2,5-Dimethylphenyl)Oxy]-2,2-Dimethylpentanoic Acid",
    ],
  },
  {
    primary_id: "D05VJH",
    names: ["N-Methyl Ryanodine-Succinamidate"],
  },
  {
    primary_id: "D05VKS",
    names: ["VT-122"],
  },
  {
    primary_id: "D05VKW",
    names: ["Sideroxylonal C"],
  },
  {
    primary_id: "D05VLB",
    names: ["PEGylated Recombinant IFN-Alpha2b"],
  },
  {
    primary_id: "D05VLN",
    names: ["Isomazole"],
  },
  {
    primary_id: "D05VLS",
    names: [
      "Rofecoxib",
      "Ceoxx",
      "Refecoxib",
      "Vioxx",
      "Cahill May Roberts Brand Of Rofecoxib",
      "MSD Brand Of Rofecoxib",
      "Merck Frosst Brandof Rofecoxib",
      "Merck Brand Of Rofecoxib",
      "Vioxx Dolor",
      "MK 0966",
      "MK 0996",
      "MK 966",
      "MK0966",
      "Ceeoxx (TN)",
      "Ceoxx (TN)",
      "KS-1107",
      "MK-0966",
      "MK-966",
      "Merck Sharp & Dhome Brand Of Rofecoxib",
      "Vioxx (TN)",
      "Vioxx (Trademark)",
      "Rofecoxib (JAN/USAN/INN)",
      "3-(4-Methylsulfonylphenyl)-4-Phenyl-2H-Furan-5-One",
      "3-Phenyl-4-(4-(Methylsulfonyl)Phenyl))-2(5H)-Furanone",
      "3-Phenyl-4-[4-(Methylsulfonyl)Phenyl]-2(5H)-Furanone",
      "4-(4-(Methylsulfonyl)Phenyl)-3-Phenyl-2(5H)-Furanone",
      "4-(4-Methylsulfonylphenyl)-3-Phenyl-5H-Furan-2-One",
      "4-(P-(Methylsulfonyl)Phenyl)-3-Phenyl-2(5H)-Furanone",
      "4-[4-(Methylsulfonyl)Phenyl]-3-Phenyl-2(5H)-Furanone",
      "4-[4-(Methylsulfonyl)Phenyl]-3-Phenylfuran-2(5H)-One",
    ],
  },
  {
    primary_id: "D05VLU",
    names: ["2,3,4,5-Tetrafluoro-6-Pentafluorophenylazo-Phenol"],
  },
  {
    primary_id: "D05VLV",
    names: [
      "(10H-Phenothiazin-10-Yl)(M-Tolyl)Methanone",
      "CHEMBL239401",
      "AC1MRTR9",
      "Oprea1_358386",
      "BDBM50219209",
      "AKOS005197735",
      "AKOS002684869",
    ],
  },
  {
    primary_id: "D05VNG",
    names: ["BB-1101", "CHEMBL432079", "SCHEMBL1844243", "BDBM50070453"],
  },
  {
    primary_id: "D05VNP",
    names: [
      "TESTOSTERONE BUCICLATE",
      "CDB-1781",
      "Testosterone Buciclate",
      "Testosterone Trans-4-N-Butylcyclohexylcarboxylate",
      "[17beta(Trans)]-(4-Butylcyclohexylcarbonyloxy)Androst-4-En-3-One",
      "20 Aet-1",
    ],
  },
  {
    primary_id: "D05VNS",
    names: ["CD19 And CD22 CAR-T Cells"],
  },
  {
    primary_id: "D05VNX",
    names: ["IPC-003"],
  },
  {
    primary_id: "D05VOG",
    names: ["AL-8810", "AL8810", "AL 8810"],
  },
  {
    primary_id: "D05VOH",
    names: ["VITAMIN K2"],
  },
  {
    primary_id: "D05VOP",
    names: ["RAP-160"],
  },
  {
    primary_id: "D05VQD",
    names: ["BMS-183729"],
  },
  {
    primary_id: "D05VQI",
    names: [
      "Cyclandelate",
      "456-59-7",
      "Cyclolyt",
      "Cyclomandol",
      "Cyclospasmol",
      "Spasmocyclon",
      "Spasmocyclone",
      "Ciclospasmol",
      "Ciclandelato",
      "Sancyclan",
      "Saiclate",
      "Sepyron",
      "Perebral",
      "Dilatan",
      "Clandilon",
      "Capilan",
      "Spasmione",
      "Arto-Espasmol",
      "3,3,5-Trimethylcyclohexyl Mandelate",
      "Cyclandelatum",
      "3,5,5-Trimethylcyclohexyl Amygdalate",
      "Cyclobral",
      "Cyclergine",
      "Cyclandelatum [INN-Latin]",
      "Ciclandelato [INN-Spanish]",
      "3,5,5-Trimethylcyclohexyl Mandelate",
      "Novodil",
      "Natil",
      "Mandelic Acid 3,3,5-Trimethylcyclohexyl Ester",
      "Cyclandelic Acid",
      "BS 572",
      "UNII-4139O1OAY2",
      "HSDB 3046",
      "EINECS 207",
    ],
  },
  {
    primary_id: "D05VQJ",
    names: ["Isothiourea-1t"],
  },
  {
    primary_id: "D05VRO",
    names: ["PMID9003518C4"],
  },
  {
    primary_id: "D05VSH",
    names: ["REGN3767"],
  },
  {
    primary_id: "D05VSK",
    names: [
      "ETHOXYCOUMARIN",
      "7-ETHOXYCOUMARIN",
      "31005-02-4",
      "7-Ethoxy-2H-Chromen-2-One",
      "Ethylumbelliferone",
      "2H-1-Benzopyran-2-One, 7-Ethoxy-",
      "7-Ethoxychromen-2-One",
      "7-Ethoxy-2H-1-Benzopyran-2-One",
      "CHEMBL191528",
      "CHEBI:28184",
      "LIFAQMGORKPVDH-UHFFFAOYSA-N",
      "7-Ethoxycoumarine",
      "EINECS 250-429-4",
      "7 - Ethoxycoumarin",
      "PubChem8680",
      "Coumarin, 7-Ethoxy-",
      "ACMC-209hjx",
      "7-Ethoxy-Chromen-2-One",
      "7-Ethoxycoumarin, 99.5%",
      "SCHEMBL119072",
      "7-Ethoxy-1-Benzopyran-2-One",
      "AC1L1U91",
      "ZINC57719",
      "CTK4G6277",
      "DTXSID30184983",
      "MolPort-001-759-189",
      "HMS1722P19",
      "KS-000014EB",
    ],
  },
  {
    primary_id: "D05VTE",
    names: ["CAI Orotate", "CAI-O", "L-651582 Orotate", "1,2,3-Carboxyamido-Triazole Orotate"],
  },
  {
    primary_id: "D05VTN",
    names: [
      "CCX-354",
      "CCX-354-C",
      "CCR1 Antagonist (Rheumatoid Arthritis), ChemoCentryx/GlaxoSmithKline",
      "CCR1 Antagonists (Autoimmune Diseases), Forest Laboratories/ChemoCentryx",
    ],
  },
  {
    primary_id: "D05VTT",
    names: [
      "3-(4-Trifluoromethylphenyl)-2-Nortropene",
      "CHEMBL570871",
      "(+/-)-3-(4-Trifluoromethylphenyl)-8-Azabicyclo(3.2.1]oct-2-Ene",
      "(+/-)-3-(4-Trifluoromethylphenyl)-8-Azabicyclo[3.2.1]oct-2-Ene",
      "SCHEMBL6577750",
      "TVAPTTJJXRUWFI-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D05VUA",
    names: [
      "6-Acetyl-7-Methoxy-2H-Chromen-2-One",
      "6-Acetyl-7-Methoxychromen-2-One",
      "CHEMBL1288558",
      "16850-94-5",
      "AC1LGQHB",
      "MolPort-002-843-897",
      "ZINC384856",
    ],
  },
  {
    primary_id: "D05VUC",
    names: [
      "1,2-Bis(2-Fluorophenyl)-2-Hydroxyethanone",
      "CHEMBL242768",
      "SCHEMBL7414865",
      "XQFHNDNXOUWJPU-UHFFFAOYSA-N",
      "BDBM50209403",
    ],
  },
  {
    primary_id: "D05VUX",
    names: ["BHV-0223"],
  },
  {
    primary_id: "D05VVC",
    names: [
      "7-Chloro-3-Hydroxyquinazoline-2,4-Dione",
      "CHEMBL80594",
      "3-Hydroxy-7-Chloro-1H-Quinazoline-2,4-Dione",
      "705977-20-4",
      "BDBM50144806",
    ],
  },
  {
    primary_id: "D05VVT",
    names: ["PEG-PLA-TNP-470"],
  },
  {
    primary_id: "D05VVX",
    names: [
      "PTC299",
      "6-(4-Fluorophenyl)-2,3-Dihydro-5-(4-Pyridinyl)Imidazo(2,1-B)Thiazole",
      "Skf-86002",
      "72873-74-6",
      "Skf 86002",
      "F 86002",
      "F 86002-A(2)",
      "UNII-9R6QDF1UO7",
      "9R6QDF1UO7",
      "CHEMBL313417",
      "5-(4-Pyridyl)-6-(4-Fluorophenyl)-2,3-Dihydroimidazo(2,1-B)-Thiazole",
      "4-[6-(4-Fluorophenyl)-2H,3H-Imidazo[2,1-B][1,3]thiazol-5-Yl]pyridine",
      "6-(4-Fluorophenyl)-5-(4-Pyridyl)-2,3-Dihydroimidazo[2,1-B]thiazole",
      "6-(4-Fluorophenyl)-2,3-Dihydro-5-(4-Pyridyl)Imidazo[2,1-B]thiazole",
      "Imidazo(2,1-B)Thiazole,",
      "SK&F 86002",
    ],
  },
  {
    primary_id: "D05VVZ",
    names: ["PMID28594589-Compound-TABLE3C2"],
  },
  {
    primary_id: "D05VWD",
    names: ["Ethynyl Compound 5"],
  },
  {
    primary_id: "D05VWK",
    names: ["AXP-107-16"],
  },
  {
    primary_id: "D05VWX",
    names: ["CGEN-25008", "Chemosensitizer (Cancer), Compugen"],
  },
  {
    primary_id: "D05VWZ",
    names: [
      "2-(3-Carboxybenzyl)Succinic Acid",
      "Carboxylate, 39",
      "2-[(3-Carboxyphenyl)Methyl]butanedioic Acid",
      "Butanedioic Acid, 2-[(3-Carboxyphenyl)Methyl]-",
      "CHEMBL209190",
      "BDBM17782",
    ],
  },
  {
    primary_id: "D05VXF",
    names: ["YSCMA"],
  },
  {
    primary_id: "D05VXK",
    names: ["Benzimidazole Derivative 10"],
  },
  {
    primary_id: "D05VXQ",
    names: ["SRSC-355"],
  },
  {
    primary_id: "D05VXX",
    names: ["5-Butyl-2-(2,4-Dichlorophenoxy)Phenol", "Triclosan Derivative, 10", "CHEMBL405402"],
  },
  {
    primary_id: "D05VYJ",
    names: ["SUN-09"],
  },
  {
    primary_id: "D05VZE",
    names: ["Marimastat"],
  },
  {
    primary_id: "D05VZJ",
    names: ["J-104132"],
  },
  {
    primary_id: "D05WAM",
    names: ["ISIS 9008"],
  },
  {
    primary_id: "D05WBV",
    names: ["Ampligenrintatolimod"],
  },
  {
    primary_id: "D05WCI",
    names: [
      "ABT-384",
      "UNII-R5TH77F919",
      "CHEMBL222670",
      "ABT 384",
      "R5TH77F919",
      "SCHEMBL231595",
      "GTPL7357",
      "ABT384",
      "SCHEMBL20457214",
      "BDBM50195291",
      "DB12501",
      "868623-40-9",
      "4-{2-Methyl-2-[4-(5-Trifluoromethyl-Pyridin-2-Yl)-Piperazin-1-Yl]-Propionylamino}-Adamantane-1-Carboxylic Acid Amide",
    ],
  },
  {
    primary_id: "D05WCW",
    names: ["Quinoxaline Derivative 6"],
  },
  {
    primary_id: "D05WDD",
    names: ["Group B Streptococcal Vaccine"],
  },
  {
    primary_id: "D05WDG",
    names: ["Natroba"],
  },
  {
    primary_id: "D05WDJ",
    names: ["Proteolytic Mabs"],
  },
  {
    primary_id: "D05WDM",
    names: ["Ro5203280"],
  },
  {
    primary_id: "D05WDQ",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 12"],
  },
  {
    primary_id: "D05WDV",
    names: [
      "2-Amino-2-(3-Bromophenyl)Acetic Acid",
      "79422-73-4",
      "Amino(3-Bromophenyl)Acetic Acid",
      "3-Bromo-DL-Phenylglycine",
      "CHEMBL382371",
      "150174-93-9",
      "Amino-(3-Bromo-Phenyl)-Acetic Acid",
      "Benzeneacetic Acid, A-Amino-3-Bromo-",
      "3-Bromo-L-Phenylglycine",
      "(+)-2-Amino-2-(3-Bromophenyl)Acetic Acid",
      "(S)-2-(3-BROMOPHENYL)GLYCINE",
      "AC1MXE2V",
      "2-(3-Bromophenyl)Glycine",
      "SCHEMBL2927725",
      "AMGLY00125",
      "AC1Q505E",
      "CTK5E6784",
      "KS-00000MGC",
      "DTXSID80396791",
      "MolPort-000-000-540",
      "MJPMYLPJAVQUQA-UHFFFAOYSA-N",
      "N-(3-BROMOPHENYL)-GLYCINE",
      "ANW-43657",
      "MFCD00665358",
      "BDBM50179696",
    ],
  },
  {
    primary_id: "D05WEF",
    names: [
      "N-Benzyl-2-(5-Nitro-1H-Indol-3-Yl)-2-Oxoacetamide",
      "CHEMBL75307",
      "N-Benzyl-2-(5-Nitro-1H-Indol-3-Yl)-2-Oxo-Acetamide",
    ],
  },
  {
    primary_id: "D05WEP",
    names: ["Befloxatone"],
  },
  {
    primary_id: "D05WET",
    names: ["Anti-CD38 CAR-T Cells"],
  },
  {
    primary_id: "D05WFF",
    names: ["GNI-102"],
  },
  {
    primary_id: "D05WFQ",
    names: ["Norzotepine"],
  },
  {
    primary_id: "D05WGA",
    names: [
      "2-(4-Methoxyphenyl)-N-(Thiazol-2-Yl)Acetamide",
      "2-(4-Methoxyphenyl)-N-(1,3-Thiazol-2-Yl)Acetamide",
      "AC1LGGXP",
      "Cambridge Id 5286247",
      "Oprea1_702537",
      "Oprea1_294245",
      "CHEMBL227898",
      "MolPort-001-026-371",
      "ZINC253385",
      "STL503574",
      "AKOS000673322",
      "MCULE-3731631352",
      "BAS 00626097",
      "KB-115460",
      "ST50912475",
      "2-(4-Methoxy-Phenyl)-N-Thiazol-2-Yl-Acetamide",
      "2-(4-Methoxyphenyl)-N-1,3-Thiazol-2-Ylacetamide",
      "Z28173731",
    ],
  },
  {
    primary_id: "D05WHD",
    names: ["FR161282"],
  },
  {
    primary_id: "D05WHM",
    names: [
      "Edatrexate",
      "EDATREXATE",
      "80576-83-6",
      "TCMDC-137768",
      "GNF-Pf-63",
      "CHEMBL296373",
      "10-Ethyl-10-Deazaaminopterin",
      "CGP-30694",
      "Edatrexate [USAN:INN]",
      "Edatrexatum [INN-Latin]",
      "Edatrexato [INN-Spanish]",
      "Edatrexatum",
      "Edatrexato",
      "CGP 30694",
      "EDX",
      "Psylliumseed",
      "N-(P-(1-((2,4-Diamino-6-Pteridinyl)Methyl)Propyl)Benzoyl)-L-Glutamic Acid",
      "L-Glutamic Acid, N-(4-(1-((2,4-Diamino-6-Pteridinyl)Methyl)Propyl)Benzoyl)-",
      "AC1OCESK",
      "Edatrexate (USAN/INN)",
      "SCHEMBL4676",
      "10-Ethyl-10-Deazaaminopterine",
      "CHEBI:135757",
    ],
  },
  {
    primary_id: "D05WJI",
    names: ["ISIS 107792"],
  },
  {
    primary_id: "D05WJQ",
    names: ["Saredutant"],
  },
  {
    primary_id: "D05WKH",
    names: ["TVX-007"],
  },
  {
    primary_id: "D05WKI",
    names: [
      "UCM-454",
      "CHEMBL33185",
      "UCM454",
      "GTPL7783",
      "UCM 454",
      "BDBM50240442",
      "N-[[1-[(4-Chlorophenyl)Methyl]-4-Methoxyindol-2-Yl]methyl]propanamide",
      "N-[[1-(4-Chlorobenzyl)-4-Methoxy-1H-Indole-2-Yl]methyl]propionamide",
      "N-((1-(4-Chlorobenzyl)-4-Methoxy-1H-Indol-2-Yl)Methyl)Propionamide",
    ],
  },
  {
    primary_id: "D05WKQ",
    names: ["LAI287"],
  },
  {
    primary_id: "D05WKT",
    names: ["PK-44", "DPP-IV Inhibitors (Diabetes), PeakDale"],
  },
  {
    primary_id: "D05WLE",
    names: ["INO-1800"],
  },
  {
    primary_id: "D05WNC",
    names: ["C[Nle-Glu-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL383250", "BDBM50184354"],
  },
  {
    primary_id: "D05WON",
    names: ["SB-611812"],
  },
  {
    primary_id: "D05WOS",
    names: ["BMS-986226"],
  },
  {
    primary_id: "D05WPE",
    names: ["Peptide Therapy, Autoimmune Disease"],
  },
  {
    primary_id: "D05WPG",
    names: ["Tacrine-Caffeic Acid Hybrid Derivative 1"],
  },
  {
    primary_id: "D05WPH",
    names: ["Quinoline-3-Carboxylic Acid Adamantan-1-Ylamide", "CHEMBL253347", "SCHEMBL6085211"],
  },
  {
    primary_id: "D05WPQ",
    names: [
      "BW A4C",
      "BW4C",
      "Bwa-4C",
      "Bwa 4C",
      "106328-57-8",
      "BW-A4C",
      "BWA4C",
      "CHEMBL314360",
      "C17H17NO3",
      "N-(E)-3-(3-Phenoxyphenyl)Prop-2-Enylacetohydroxamine Acid",
      "Acetamide, N-Hydroxy-N-(3-(3-Phenoxyphenyl)-2-Propenyl)-",
      "N-[(E)-3-(3-Phenoxyphenyl)Prop-2-Enyl]acetohydroxamic Acid",
      "Acetohydroxamine Acid, N-(E)-3-(3-Phenoxyphenyl)Prop-2-Enyl-",
      "Acetamide, N-Hydroxy-N-(3-(3-Phenoxyphenyl)-2-Propenyl)-, (E)-",
      "BW-4AC",
      "AC1O5PJ5",
      "BW-A-4C",
      "BW-A 4C",
      "JMC515449 Compound 7",
      "BDBM22334",
      "ZINC5138195",
      "MFCD00869694",
      "N-(3-Phenoxycinnamyl)-N-Hydroxyacetamide",
    ],
  },
  {
    primary_id: "D05WQF",
    names: [
      "2-Hydroxycinnamic Acid",
      "2-Hydroxy Cinnamic Acid",
      "3-(2-Hydroxyphenyl)Prop-2-Enoic Acid",
      "Orthocumarsaure",
      "ACMC-1BBQC",
      "AC1L1YJR",
      "2-HYDROXYCINNAMICACID",
      "CTK1F7842",
      "CTK1H2007",
      "CTK1B5135",
      "PMOWTIHVNWZYFI-UHFFFAOYSA-N",
      "MolPort-006-109-312",
      "HMS3604C08",
      "KS-00000X9C",
      "EINECS 209-500-5",
      "AKOS026677434",
      "MCULE-7485332494",
      "SY048367",
      "FT-0612592",
      "FT-0612591",
      "MFCD00004379 (97%)",
      "2-Propenoic Acid, 3-(Hydroxyphenyl)-, (Z)-",
      "2-Propenoic Acid, 3-(Hydroxyphenyl)-, (2E)-",
      "38094-41-6",
    ],
  },
  {
    primary_id: "D05WQN",
    names: [
      "N-(2-Methyl-1H-Indol-5-Yl)Cyclohexanecarboxamide",
      "CHEMBL1253605",
      "MolPort-007-401-898",
      "ZINC43771600",
      "BDBM50326846",
    ],
  },
  {
    primary_id: "D05WQR",
    names: ["3-(2-Amino-Ethyl)-2-Methyl-1H-Indol-5-Ol"],
  },
  {
    primary_id: "D05WQT",
    names: ["Flanvotumab"],
  },
  {
    primary_id: "D05WRB",
    names: ["Galactofucan Sulphate Fraction"],
  },
  {
    primary_id: "D05WSD",
    names: ["NaAPR1M-74"],
  },
  {
    primary_id: "D05WSM",
    names: ["HESPERADIN"],
  },
  {
    primary_id: "D05WSP",
    names: ["UK-396082"],
  },
  {
    primary_id: "D05WSU",
    names: [
      "Beta-D-Glucopyranose Spirohydantoin",
      "BETA-D-GLUCOPYRANOSE SPIROHYDANTOIN",
      "CHEMBL510975",
      "GLS",
      "(5S,7R,8S,9S,10R)-8,9,10-Trihydroxy-7-(Hydroxymethyl)-6-Oxa-1,3-Diazaspiro[4.5]decane-2,4-Dione",
      "1a8i",
      "AC1L9FVJ",
      "BDBM50263768",
      "1beta,1-(Iminocarbonyliminocarbonyl)-1-Deoxy-D-Glucopyranose",
      "(5S,7R,8S,9S,10R)-8,9,10-Trihydroxy-7-Hydroxymethyl-6-Oxa-1,3-Diaza-Spiro[4.5]decane-2,4-Dione",
    ],
  },
  {
    primary_id: "D05WTI",
    names: [
      "(2,4-Dihydroindeno[1,2-C]pyrazol-3-Yl)Phenylamine",
      "CHEMBL175596",
      "872872-12-3",
      "SCHEMBL4045943",
      "CTK3C4931",
      "DTXSID20468426",
      "BDBM50179205",
      "AKOS030605024",
      "Indeno[1,2-C]pyrazol-3-Amine, 1,4-Dihydro-N-Phenyl-",
    ],
  },
  {
    primary_id: "D05WTS",
    names: ["Galavit"],
  },
  {
    primary_id: "D05WTT",
    names: ["Remune"],
  },
  {
    primary_id: "D05WTW",
    names: ["Carbamide Derivative 23"],
  },
  {
    primary_id: "D05WTZ",
    names: ["Galarubicin", "DA-125", "Metalloprotease Inhibitors (Cancer), Dong-A"],
  },
  {
    primary_id: "D05WUN",
    names: ["OTI-020"],
  },
  {
    primary_id: "D05WUQ",
    names: ["Omtriptolide"],
  },
  {
    primary_id: "D05WUZ",
    names: ["TT-113"],
  },
  {
    primary_id: "D05WVO",
    names: ["EDP-17"],
  },
  {
    primary_id: "D05WVU",
    names: ["131i-Chtnt"],
  },
  {
    primary_id: "D05WWJ",
    names: ["ALX-5407", "2-[[(3R)-3-(4-Fluorophenyl)-3-(4-Phenylphenoxy)Propyl]-Methylamino]acetic Acid"],
  },
  {
    primary_id: "D05WWX",
    names: ["Biphenyl Mannoside Derivative 14"],
  },
  {
    primary_id: "D05WXF",
    names: ["Thiochrome"],
  },
  {
    primary_id: "D05WXM",
    names: ["PMID27109571-Compound-18"],
  },
  {
    primary_id: "D05WYN",
    names: ["4-(5-Morpholin-4-Yl-Pentyl)-2H-Phthalazin-1-One", "CHEMBL194684"],
  },
  {
    primary_id: "D05WYW",
    names: ["L-373890"],
  },
  {
    primary_id: "D05WZA",
    names: ["4-[3-(4-Phenyl-Butylamino)-Propyl]-Phenol", "CHEMBL42414", "BDBM50066538"],
  },
  {
    primary_id: "D05WZF",
    names: [
      "P2,P3 Ketoamide Derivative",
      "CHEMBL186650",
      "SCHEMBL14260244",
      "AAYZJXOZLLKNGZ-DEOSSOPVSA-N",
      "BDBM50152530",
    ],
  },
  {
    primary_id: "D05WZK",
    names: ["Frescolat ML", "(-)-Menthyl Lactate"],
  },
  {
    primary_id: "D05WZL",
    names: ["((E)-3-Amino-Propenyl)-Methyl-Phosphinic Acid", "3-Aminoprop-1-Enyl(Methyl)Phosphinic Acid"],
  },
  {
    primary_id: "D05XAH",
    names: ["Aromatic Hydrazine Carboxyimidoamide Derivative 4"],
  },
  {
    primary_id: "D05XAN",
    names: ["7-Mercapto-Heptanoic Acid Biphenyl-3-Ylamide", "CHEMBL320323"],
  },
  {
    primary_id: "D05XAT",
    names: ["PMID27109571-Compound-28"],
  },
  {
    primary_id: "D05XCV",
    names: ["Ac-D-Pro-L-Phe-D-Trp-L-Phe-NH2", "CHEMBL450818"],
  },
  {
    primary_id: "D05XCZ",
    names: ["VPC-51299"],
  },
  {
    primary_id: "D05XDX",
    names: ["HuCAL-Derived Fully Human Antibodies"],
  },
  {
    primary_id: "D05XDZ",
    names: ["LMT-X"],
  },
  {
    primary_id: "D05XEA",
    names: ["RP5063"],
  },
  {
    primary_id: "D05XEF",
    names: ["5-Hexyl-5-Phenyl-Imidazolidine-2,4-Dione", "CHEMBL289499"],
  },
  {
    primary_id: "D05XFQ",
    names: ["VLN-244"],
  },
  {
    primary_id: "D05XGF",
    names: ["PSB-0788"],
  },
  {
    primary_id: "D05XGH",
    names: ["PMID26651364-Compound-6e"],
  },
  {
    primary_id: "D05XGI",
    names: ["Meta-Tyrosine"],
  },
  {
    primary_id: "D05XGO",
    names: [
      "Cariprazine",
      "839712-12-8",
      "RGH-188",
      "RGH 188",
      "Cariprazine(RGH188)",
      "UNII-F6RJL8B278",
      "F6RJL8B278",
      "CHEMBL3085826",
      "3-[4-[2-[4-(2,3-Dichlorophenyl)Piperazin-1-Yl]ethyl]cyclohexyl]-1,1-Dimethylurea",
      "Cariprazine [USAN:INN]",
      "Reagila",
      "MP-214",
      "MP 214",
      "Cariprazine (USAN/INN)",
      "Cariprazine (RGH-188)",
      "C21H32Cl2N4O",
      "SCHEMBL184342",
      "SCHEMBL184343",
      "GTPL7671",
      "SCHEMBL7861573",
      "CHEMBL2028019",
      "CHEBI:90933",
      "HSDB 8310",
      "DTXSID80232867",
      "KPWSJANDNDDRMB-QAQDUYKDSA-N",
      "MolPort-039-139-594",
      "BDBM263449",
      "BCP14691",
      "BDBM50443101",
      "BDBM50382290",
      "ZINC38309313",
      "3526AH",
    ],
  },
  {
    primary_id: "D05XGR",
    names: ["SB-214111", "209480-37-5"],
  },
  {
    primary_id: "D05XGZ",
    names: ["6-(3,5-Dimethoxy-Phenyl)-Naphthalen-2-Ol", "CHEMBL362997", "BDBM50186758"],
  },
  {
    primary_id: "D05XIA",
    names: ["SF-22"],
  },
  {
    primary_id: "D05XJW",
    names: ["Resiniferatoxin"],
  },
  {
    primary_id: "D05XKN",
    names: [
      "APG-101.10",
      "Allosteramer",
      "IL-1 Receptor Inhibitor (Oral, Gout/Type 2 Diabetes/Rheumatoid Arthritis), Allostera",
    ],
  },
  {
    primary_id: "D05XKT",
    names: ["1,2-Dihydro-2-Oxoquinazoline-4-Carboxyanilide", "CHEMBL279403", "BDBM50228374", "AKOS027611184"],
  },
  {
    primary_id: "D05XKW",
    names: ["VM-501"],
  },
  {
    primary_id: "D05XLD",
    names: ["Piridronic Acid"],
  },
  {
    primary_id: "D05XLH",
    names: ["Iroxanadine"],
  },
  {
    primary_id: "D05XLO",
    names: ["INCB13739"],
  },
  {
    primary_id: "D05XMG",
    names: [
      "AG-014376",
      "CHEMBL361489",
      "SCHEMBL7159231",
      "BDBM50154730",
      "6-(4-Dimethylaminomethyl-Phenyl)-3,4-Dihydro-2H-[1,4]diazepino[6,7,1-Hi]indol-1-One",
    ],
  },
  {
    primary_id: "D05XMM",
    names: ["4-(3-(Dimethylamino)Propanoyl)-N-Hexylbenzamide", "CHEMBL561648"],
  },
  {
    primary_id: "D05XMY",
    names: ["ACETOSIDE", "Acetoside"],
  },
  {
    primary_id: "D05XNF",
    names: ["IM21 CART"],
  },
  {
    primary_id: "D05XNR",
    names: ["2,3-Dihydro-1H-Indol-5-Ol"],
  },
  {
    primary_id: "D05XNS",
    names: [
      "N-(6-Methylpyridin-2-Yl)Biphenyl-3-Carboxamide",
      "CHEMBL377935",
      "BDBM50186331",
      "Biphenyl-3-Carboxylic Acid (6-Methyl-Pyridin-2-Yl)-Amide",
    ],
  },
  {
    primary_id: "D05XNX",
    names: ["NH-02D"],
  },
  {
    primary_id: "D05XPL",
    names: [
      "2-Methyl-Beta-Carboline-2-Ium Iodide",
      "2-Methylnorharmane Iodide",
      "CHEMBL455600",
      "MolPort-001-815-824",
      "AKOS024332881",
    ],
  },
  {
    primary_id: "D05XPT",
    names: ["(3-Methyl-4-(4-Phenoxybenzoyl)Phenyl)Acetic Acid", "CHEMBL201762"],
  },
  {
    primary_id: "D05XPU",
    names: ["[D-Ala2]Met-Enkephalinamide", "CHEMBL287480"],
  },
  {
    primary_id: "D05XPV",
    names: [
      "TL-77",
      "CHEMBL3261266",
      "GTPL7834",
      "SCHEMBL15124009",
      "SCHEMBL15124011",
      "HEBBDGMHRHUJRY-VOTSOKGWSA-N",
      "BDBM50011472",
      "(E)-N-(2-Methoxy-5-((2,4,6-Trimethoxystyrylsulfonyl)Methyl)Pyridin-3-Yl)Methanesulfonamide",
      "N-[5-[[(E)-2-(2,4,6-Trimethoxyphenyl)Ethenyl]sulfonyl]methyl-2-Methoxypyridine-3-Yl]methanesulfonamide",
    ],
  },
  {
    primary_id: "D05XPW",
    names: ["Isosorbide-Di-(Butylcarbamate)"],
  },
  {
    primary_id: "D05XPZ",
    names: ["JWCAR029"],
  },
  {
    primary_id: "D05XQD",
    names: ["EPZ015666"],
  },
  {
    primary_id: "D05XQE",
    names: ["Plaunotol"],
  },
  {
    primary_id: "D05XRI",
    names: ["Ac-R[CEHdFRWC]-NH2", "CHEMBL408257"],
  },
  {
    primary_id: "D05XRY",
    names: [
      "11,12-Dihydro-Dibenzo[a,e]cyclooctene-5,6-Dione",
      "AC1MBM6T",
      "CHEMBL235931",
      "SCHEMBL13712393",
      "ZINC3848035",
      "AKOS004901956",
    ],
  },
  {
    primary_id: "D05XRZ",
    names: ["HI-6"],
  },
  {
    primary_id: "D05XSJ",
    names: ["Asfotase Alfa"],
  },
  {
    primary_id: "D05XSV",
    names: ["7alpha-OAc-Ginkgolide B", "7alpha-OAc-GB"],
  },
  {
    primary_id: "D05XTO",
    names: ["ABT-100", "A-367074", "A-409100", "A-367074.1"],
  },
  {
    primary_id: "D05XTQ",
    names: [
      "DELTIBANT",
      "CP-0127",
      "Deltibant < Prop INN",
      "SB-238592",
      "Bradycor (Cortech, US)",
      "S,S'-(Hexane-1,6-Diyl)Bis(Succinimide-1,3-Diyl)Bis[D-Arginyl-L-Arginyl-L-Prolyl-(4-Hydroxy)-L-Prolyl-Glycyl-L-Phenylalanyl-L-Cysteinyl-D-Phenylalanyl-L-Leucyl-L-Arginine]",
    ],
  },
  {
    primary_id: "D05XTX",
    names: ["KNI-10095"],
  },
  {
    primary_id: "D05XTY",
    names: ["Glucan Synthase Inhibitors"],
  },
  {
    primary_id: "D05XUY",
    names: [
      "JM216",
      "129580-63-8",
      "JM 216",
      "Satraplatin(Jm216)",
      "Satraplatin (USAN/INN)",
      "C081294",
      "YHI-601",
      "C6H13N.C4H6Cl2O4Pt.H3N",
      "Platinum, Bis(Acetato-O)Amminedichloro(Cyclohexanamine)-, (OC-6-43)-",
      "4023AH",
      "AKOS015914237",
      "BMS-182751",
      "BC679462",
      "FT-0601550",
      "D05807",
      "Bis(Acetato-O)Amminedichloro(Cyclohexanamine)-Pt",
      "580S638",
      "I14-43013",
      "Platinum (LV) Cis-Dichloro-Trans-Bis(Acetato-O)Ammine(Cyclohexanamine)",
    ],
  },
  {
    primary_id: "D05XVM",
    names: [
      "1-Methoxy-6-Phenyl-6H-Benzo[c]chromen-8-Ylamine",
      "CHEMBL292721",
      "SCHEMBL7234473",
      "MVULNNIYAGEMCC-UHFFFAOYSA-N",
      "BDBM50144165",
    ],
  },
  {
    primary_id: "D05XWX",
    names: ["FE 999011"],
  },
  {
    primary_id: "D05XXE",
    names: ["Tetragastrin"],
  },
  {
    primary_id: "D05XXF",
    names: ["ORMD-0901"],
  },
  {
    primary_id: "D05XXQ",
    names: ["MLR-1326"],
  },
  {
    primary_id: "D05XXS",
    names: ["PMID26924192-Compound-103"],
  },
  {
    primary_id: "D05XYC",
    names: ["LY-231617", "2,6-Di-Tert-Butyl-4-(Ethylaminomethyl)Phenol Hydrochloride"],
  },
  {
    primary_id: "D05XYH",
    names: ["MIC-31", "Anti-Ulcer Agent (Gastric Ulcers), Medigreen"],
  },
  {
    primary_id: "D05XZA",
    names: ["Des-AA1,2,5-[(NalphaMe)Cys3,D-Trp8,IAmp9]SRIF", "CHEMBL265120"],
  },
  {
    primary_id: "D05XZF",
    names: [
      "BX 471",
      "217645-70-0",
      "BX471",
      "BX-471",
      "BX-471 Free Base",
      "BX-741",
      "UNII-76K17ZG4ZN",
      "ZK-811752",
      "CHEMBL232656",
      "76K17ZG4ZN",
      "CHEMBL535607",
      "(R)-1-(5-Chloro-2-(2-(4-(4-Fluorobenzyl)-2-Methylpiperazin-1-Yl)-2-Oxoethoxy)Phenyl)Urea",
      "Urea, N-[5-Chloro-2-[2-[(2R)-4-[(4-Fluorophenyl)Methyl]-2-Methyl-1-Piperazinyl]-2-Oxoethoxy]phenyl]-",
      "N-[5-Chloro-2-[2-[(2R)-4-[(4-Fluorophenyl)Methyl]-2-Methyl-1-Piperazinyl]-2-Oxoethoxy]phenyl]-Urea",
      "[5-Chloro-2-[2-[(2R)-4-[(4-Fluorophenyl)Methyl]-2-Methylpiperazin-1-Yl]-2-Oxoethoxy]phenyl]urea",
      "Urea, N-",
    ],
  },
  {
    primary_id: "D05XZM",
    names: ["4-(Hydroxymethyl)Benzenesulfonamide", "4-Hydroxymethyl-Benzenesulfonamide"],
  },
  {
    primary_id: "D05XZQ",
    names: ["HepaStem"],
  },
  {
    primary_id: "D05YAD",
    names: ["PMID25656651-Compound-11c"],
  },
  {
    primary_id: "D05YAG",
    names: ["Recombinant Human Endostatin"],
  },
  {
    primary_id: "D05YAR",
    names: ["Eucalyptol"],
  },
  {
    primary_id: "D05YAS",
    names: ["Tolafentrine", "BY-4070"],
  },
  {
    primary_id: "D05YBG",
    names: ["PMID25468267-Compound-51"],
  },
  {
    primary_id: "D05YBO",
    names: ["Nocardimicin C"],
  },
  {
    primary_id: "D05YBZ",
    names: ["Tolonium Chloride"],
  },
  {
    primary_id: "D05YCI",
    names: ["6,7,8-Trimethoxy-4-Methylquinolin-2(1H)-One", "Casimiroin Analogue, 1m", "CHEMBL454226", "BDBM29224"],
  },
  {
    primary_id: "D05YCQ",
    names: ["NSC-47729"],
  },
  {
    primary_id: "D05YDK",
    names: ["PENICILLIOL A"],
  },
  {
    primary_id: "D05YEI",
    names: ["SUN-K0706"],
  },
  {
    primary_id: "D05YEL",
    names: ["CH-3697"],
  },
  {
    primary_id: "D05YES",
    names: ["StaphVAX"],
  },
  {
    primary_id: "D05YFE",
    names: ["Diphyllin"],
  },
  {
    primary_id: "D05YFF",
    names: ["Benzamide Derivative 10"],
  },
  {
    primary_id: "D05YFL",
    names: [
      "AFTVac",
      "Autologous Fixed Tumor Vaccine (AFTVac) Therapy",
      "Autologous Vaccine (Cancer), Cell-Medicine",
      "GM-CSF Agonist/IL-2 Agonist Containing-Vaccine",
      "Fixed Autologous Tumor Derivatives + Microparticles Encapsulating GM-CSF And IL-2, Cell-Medicine",
    ],
  },
  {
    primary_id: "D05YFY",
    names: ["Anti-Inhibitor Coagulant Complex"],
  },
  {
    primary_id: "D05YFZ",
    names: ["9-(2-Aminoethyl)-9,10-Dihydroanthracene", "CHEMBL160893", "BDBM35923", "9,10-Dihydroanthracene(DHA), 2a"],
  },
  {
    primary_id: "D05YHE",
    names: ["D[Thr4,Lys8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D05YHP",
    names: ["2-N-Propargylamino-1-(4-Methylthiophenyl)Butane", "CHEMBL1077900"],
  },
  {
    primary_id: "D05YHT",
    names: ["Cyclo(-D-Tyr-D-Arg-L-Arg-L-Nal-Sar-)", "CHEMBL374862"],
  },
  {
    primary_id: "D05YHV",
    names: ["Mycophenolic Acid/Nucleotide Derivative 11"],
  },
  {
    primary_id: "D05YHW",
    names: ["Vapaliximab"],
  },
  {
    primary_id: "D05YIK",
    names: [
      "(1-Hydroxy-1-Phosphono-Heptyl)-Phosphonic Acid",
      "(1-Hydroxyheptane-1,1-Diyl)Bis(Phosphonic Acid)",
      "CHEMBL55264",
      "Phosphonic Acid, (1-Hydroxyheptylidene)Bis-",
      "(1-Hydroxy-1-Phosphonoheptyl)Phosphonic Acid",
      "Bisphosphonate, 10",
      "AC1NQOTY",
      "SCHEMBL10686529",
      "BDBM25298",
      "1-Hydroxyheptylidenebisphosphonic Acid",
      "AKOS028110532",
      "DB06830",
      "56375-74-7",
      "1-Hydroxy-1-Phosphonoheptylphosphonic Acid",
      "(1-Hydroxy-1-Phosphono-Heptyl)Phosphonic Acid",
    ],
  },
  {
    primary_id: "D05YIU",
    names: ["7-Chloro-2-(4-Hydroxy-Phenyl)-Benzofuran-5-Ol", "CHEMBL184448", "SCHEMBL3922992"],
  },
  {
    primary_id: "D05YIX",
    names: ["LymphoScan", "Bectumomab"],
  },
  {
    primary_id: "D05YJP",
    names: [
      "Ethyl 3-(Biphenyl-3-Ylamino)-2-Cyanoacrylate",
      "CHEMBL387254",
      "Ethyl (2z)-3-(Biphenyl-3-Ylamino)-2-Cyanoprop-2-Enoate",
    ],
  },
  {
    primary_id: "D05YJR",
    names: ["GARCINONE D"],
  },
  {
    primary_id: "D05YJU",
    names: ["ISIS 32021"],
  },
  {
    primary_id: "D05YKW",
    names: [
      "3,5,6,8-Tetramethyl-N-Methyl Phenanthrolinium",
      "1,3,5,6,8-Pentamethyl-1,10-Phenanthrolinium",
      "1,3,5,6,8-Pentamethyl-1,10-Phenanthrolin-1-Ium",
      "55247-91-1",
      "AC1Q4YDA",
      "AC1L519F",
      "CTK5A3287",
      "23624-78-4 (Iodide)",
      "DB02310",
      "3,4,6,8-Tetramethyl-N-Methyl Phenanthrolinium",
    ],
  },
  {
    primary_id: "D05YLL",
    names: ["FL-386", "Benzene Sulfonic Acid 2-(Trans-4-Isobutylcyclohexyl)-2-Oxoethyl Ester"],
  },
  {
    primary_id: "D05YLN",
    names: ["SAR256212 Companion Diagnostic"],
  },
  {
    primary_id: "D05YLO",
    names: ["Her-2-Bi-Armed ATC"],
  },
  {
    primary_id: "D05YLP",
    names: ["SP-333"],
  },
  {
    primary_id: "D05YMB",
    names: ["Eteplirsen", "AVI-4658"],
  },
  {
    primary_id: "D05YMD",
    names: ["MeBut-RYYRIK-NH2", "CHEMBL270394"],
  },
  {
    primary_id: "D05YMP",
    names: [
      "[3H]EM-TBPC",
      "1-Ethyl-2-Methyl-6-Oxo-4-(1,2,4,5-Tetrahydro-3-Benzazepin-3-Yl)Pyrimidine-5-Carbonitrile",
      "EM-TBPC",
      "GTPL3363",
      "GTPL1390",
      "SCHEMBL6952709",
      "E-165",
      "1-Ethyl-2-Methyl-6-Oxo-4-(1,2,4,5-Tetrahydro-Benzo[d]azepin-3-Yl)-1,6-Dihydro-Pyrimidine-5-Carbonitrile",
      "1-Ethyl-2-Methyl-6-Oxo-4-(2,3,4,5-Tetrahydro-1H-3-Benzazepin-3-Yl)-1,6-Dihydropyrimidine-5-Carbonitrile",
    ],
  },
  {
    primary_id: "D05YMT",
    names: ["Tricomin"],
  },
  {
    primary_id: "D05YNT",
    names: ["[3H]diprenorphine", "[15,16-3H]diprenorphine"],
  },
  {
    primary_id: "D05YNW",
    names: [
      "4-Indan-(1E)-Ylidenemethyl-Pyridine",
      "(4-Pyridylmethylene)Indane 2a",
      "SCHEMBL4230787",
      "BDBM8616",
      "CHEMBL175962",
      "SCHEMBL4230798",
      "AC1O705V",
      "ZINC13610822",
      "4-[(E)-2,3-Dihydroinden-1-Ylidenemethyl]pyridine",
      "4-[(1E)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine",
      "4-[(E)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine Oxalate",
    ],
  },
  {
    primary_id: "D05YNZ",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 30"],
  },
  {
    primary_id: "D05YOT",
    names: ["PPRT-321"],
  },
  {
    primary_id: "D05YQC",
    names: ["ISO24"],
  },
  {
    primary_id: "D05YQE",
    names: ["3-(2,2-Dimethyl-Propoxy)-9H-Beta-Carboline", "3-(Neopentyloxy)-9H-Pyrido[3,4-B]indole", "CHEMBL128348"],
  },
  {
    primary_id: "D05YQL",
    names: ["BM-Ca", "Anti-CD20 Antibodies (Lymphoma), BioMedics"],
  },
  {
    primary_id: "D05YQT",
    names: ["LBR-101"],
  },
  {
    primary_id: "D05YRG",
    names: ["TDI-0025"],
  },
  {
    primary_id: "D05YRP",
    names: ["C4.4a-ADC"],
  },
  {
    primary_id: "D05YRV",
    names: ["ETX-6107"],
  },
  {
    primary_id: "D05YTG",
    names: ["SLx-4090"],
  },
  {
    primary_id: "D05YUI",
    names: [
      "Ex Vivo Adenosine Deaminase-Transduced Hematopoietic Stem Cell Therapy",
      "Adenosine Deaminase-Transduced Hematopoietic Stem Cell Therapy",
      "Ex Vivo Adenosine Deaminase-Transduced Hematopoietic Stem Cell Therapy (ADA-SCID)",
    ],
  },
  {
    primary_id: "D05YUK",
    names: ["FOROPAFANT"],
  },
  {
    primary_id: "D05YUT",
    names: ["PMID8426367C18"],
  },
  {
    primary_id: "D05YWJ",
    names: ["IFX-1"],
  },
  {
    primary_id: "D05YWN",
    names: ["GS-168"],
  },
  {
    primary_id: "D05YXS",
    names: ["ORIPAVINE"],
  },
  {
    primary_id: "D05YYB",
    names: ["ACE-06X"],
  },
  {
    primary_id: "D05YYK",
    names: ["AP-401"],
  },
  {
    primary_id: "D05YYP",
    names: ["RG-7599", "DNIB-0600A", "Antibody-Drug Conjugate (NSCLC/Ovarian Cancer), Genentech"],
  },
  {
    primary_id: "D05YZC",
    names: ["[3H]devazepide"],
  },
  {
    primary_id: "D05YZE",
    names: ["Abyssinoflavanone VI", "CHEMBL389650"],
  },
  {
    primary_id: "D05ZAI",
    names: ["Grippol TC"],
  },
  {
    primary_id: "D05ZAV",
    names: ["PMID27607364-Compound-141"],
  },
  {
    primary_id: "D05ZBB",
    names: ["Phthalazine Ketone Derivative 1"],
  },
  {
    primary_id: "D05ZCQ",
    names: ["DAP-81"],
  },
  {
    primary_id: "D05ZCX",
    names: [
      "Thieno[3,2-E][1]benzothiophene-4,5-Dione",
      "Benzo[1,2-B:4,3-B']dithiophene-4,5-Dione",
      "24243-31-0",
      "CHEMBL398109",
      "4,5-Dihydrobenzo[1,2-B:4,3-B']dithiophene-4,5-Dione",
    ],
  },
  {
    primary_id: "D05ZCZ",
    names: [
      "Beifutai",
      "Beifutai (Injectable Formulation, Nervous Sytem Damage/Disease)",
      "Beifutai (Injectable Formulation, Nervous Sytem Damage/Disease), EssexBio",
      "Recombinant Human Basic Fibroblast Growth Factor (Injectable Formulation, Nervous System Damage/Disease), EssexBio",
      "Rh-BFGF (Injectable Formulation, Nervous System Damage/Disease), EssexBio",
    ],
  },
  {
    primary_id: "D05ZDA",
    names: ["JZAD-IV-22"],
  },
  {
    primary_id: "D05ZDD",
    names: ["Recombinant Human Cartilage-Derived Retinoic Acid-Sensitive Protein Depot"],
  },
  {
    primary_id: "D05ZDJ",
    names: ["THR-18"],
  },
  {
    primary_id: "D05ZDQ",
    names: [
      "1-(2-Hydroxyphenyl)-3-P-Tolylprop-2-En-1-One",
      "2'-Hydroxy-4-Methylchalcone",
      "1-(2-Hydroxyphenyl)-3-(4-Methylphenyl)Prop-2-En-1-One",
      "(2E)-1-(2-Hydroxyphenyl)-3-(4-Methylphenyl)Prop-2-En-1-One",
      "AC1NTAGW",
      "2-Propen-1-One, 1-(2-Hydroxyphenyl)-3-(4-Methylphenyl)-",
      "4-Methyl-2'-Hydroxychalcone",
      "MLS000777737",
      "CHEMBL476598",
      "SCHEMBL2807968",
      "MolPort-000-648-204",
      "OOEWKJHFFYCQBM-ZHACJKMWSA-N",
      "Chalcone, 2'-Hydroxy-4-Methyl-",
      "HMS2760H03",
      "ZX-AT026696",
      "ZINC4252606",
      "2'-Hydroxy-4-Methyl-Trans-Chalcone",
      "STK530118",
      "AKOS005463239",
      "SMR000414122",
      "ST098968",
      "2'-Hydroxy-4-Methylchalcone, AldrichCPR",
    ],
  },
  {
    primary_id: "D05ZEW",
    names: ["INO-3510"],
  },
  {
    primary_id: "D05ZFG",
    names: ["(Cis)-1,6-Diphenyl-3-Aza-Bicyclo[3.1.0]hexane", "CHEMBL488473"],
  },
  {
    primary_id: "D05ZGM",
    names: [
      "7-Phenoxy-Heptanoic Acid Hydroxyamide",
      "CHEMBL124322",
      "N-Hydroxy-7-Phenoxyheptanamide",
      "7-Phenoxyheptanehydroximic Acid",
    ],
  },
  {
    primary_id: "D05ZGQ",
    names: ["Dexchlorpheniramine Maleate"],
  },
  {
    primary_id: "D05ZGW",
    names: [
      "DPI-201-106",
      "4-{3-[4-(Diphenylmethyl)Piperazin-1-Yl]-2-Hydroxypropoxy}-1h-Indole-2-Carbonitrile",
      "Dpi 201-106",
      "97730-95-5",
      "SDZ-201106",
      "CHEMBL314639",
      "SDZ-201 106 (+/-)",
      "(+-)-4-(3-(4-(Diphenylmethyl)-1-Piperazinyl)-2-Hydroxypropoxy)-1H-Indole-2-Carbonitrile",
      "1H-Indole-2-Carbonitrile, 4-(3-(4-(Diphenylmethyl)-1-Piperazinyl)-2-Hydroxypropoxy-, (+-)-",
      "4(3-(4-Diphenylmethylpiperazine-1-Yl)-2-Hydroxypropoxy)-1H-Indol-2-Carbonitrile",
      "CBiol_001886",
      "DPI-201106",
      "4-(3-(4-Benzhydrylpiperazin-1-Yl)-2-Hydroxypropoxy)-1H-Indole-2-Carbonitrile",
    ],
  },
  {
    primary_id: "D05ZGX",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 8"],
  },
  {
    primary_id: "D05ZHA",
    names: ["Alilusem"],
  },
  {
    primary_id: "D05ZHQ",
    names: ["EHNA"],
  },
  {
    primary_id: "D05ZHR",
    names: ["PMID28766366-Compound-Scheme12-3"],
  },
  {
    primary_id: "D05ZHZ",
    names: ["PMID26651364-Compound-5e"],
  },
  {
    primary_id: "D05ZIF",
    names: ["DFN-02"],
  },
  {
    primary_id: "D05ZIH",
    names: [
      "6beta-Naltrexol HCl",
      "CHEMBL558140",
      "UNII-765G11MG24",
      "765G11MG24",
      "55488-86-3",
      "Naltrexone Metabolite 6",
      "A-Naltrexol (Hydrochloride)",
      "6beta-Naltrexol Hydrochloride",
      "HY-G0019",
      "6beta-Hydroxynaltrexone Hydrochloride",
      "Morphinan-3,6,14-Triol, 17-(Cyclopropylmethyl)-4,5-Epoxy-, Hydrochloride, (5alpha,6beta)-",
      "Naltrexone Metabolite 6AfAE'A Centa' NotA Inverted Exclamation MarkAfasA'A",
      "AfAE'Adaggeratrade MarkAfA CentA CentasA NotA Em LeaderA Inverted Exclamation Mark-Naltrexol (Hydrochloride)",
    ],
  },
  {
    primary_id: "D05ZIK",
    names: [
      "Mirtazapine",
      "Avanza",
      "Axit",
      "Azamianserin",
      "Mepirzapin",
      "Mepirzepine",
      "Mirtabene",
      "Mirtaz",
      "Mirtazapina",
      "Mirtazapinum",
      "Mirtazepine",
      "Mirtazipine",
      "Mirtazon",
      "Norset",
      "Promyrtil",
      "Remergil",
      "Remergon",
      "Remeron",
      "Rexer",
      "Zispin",
      "Celltech Brand Of Mirtazapine",
      "Mundogen Brand Of Mirtazapine",
      "Organon Brand Of Mirtazapine",
      "Remeron SolTab",
      "ORG 3770",
      "Afloyan (TN)",
      "Alphamirt (TN)",
      "Alphazagen (TN)",
      "Amirel (TN)",
      "Arintapin (TN)",
      "Arintapina (TN)",
      "Avanza (TN)",
      "Axit (TN)",
      "Bexzis (TN)",
      "Calixta (TN)",
      "Ciblex (TN)",
      "Combar (TN)",
      "Comenter (TN)",
      "Divaril (TN)",
      "Esprital (TN)",
      "Finmirtaza (TN)",
      "Finpharma (TN)",
      "Finscope (TN)",
      "Genamirt (TN)",
      "Hexazipin (TN)",
      "Loxozapin (TN)",
      "ME-2040",
      "Medizapin (TN)",
      "Miralix (TN)",
      "Mirap (TN)",
      "Mirazep (TN)",
      "Miro (TN)",
      "Miron (TN)",
      "Mirta (TN)",
      "Mirtabene (TN)",
      "Mirtachem (TN)",
      "Mirtacur (TN)",
      "Mirtadepi (TN)",
      "Mirtagamma (TN)",
      "Mirtal (TN)",
      "Mirtalich (TN)",
      "Mirtalphagen (TN)",
      "Mirtamed (TN)",
      "Mirtamerck (TN)",
      "Mirtapax (TN)",
      "Mirtapharm (TN)",
      "Mirtapin(TN)",
      "Mirtaratio (TN)",
      "Mirtaril (TN)",
      "Mirtaron (TN)",
      "Mirtascope (TN)",
      "Mirtasole (TN)",
      "Mirtastad (TN)",
      "Mirtastada (TN)",
      "Mirtatifi (TN)",
      "Mirtatsapiini (TN)",
      "Mirtawin (TN)",
      "Mirtaz (TN)",
      "Mirtazapin (TN);Mirtazapina (TN)",
      "Mirtazapina [INN-Spanish]",
      "Mirtazapinum [INN-Latin]",
      "Mirtazelon (TN)",
      "Mirtazen (TN)",
      "Mirtazep (TN)",
      "Mirtazepin (TN)",
      "Mirtazon (TN)",
      "Mirtazza (TN)",
      "Mirtel (TN)",
      "Mirtoral (TN)",
      "Mirzagen (TN)",
      "Mirzalux (TN)",
      "Mirzaten (TN)",
      "Mitrazen (TN)",
      "Mizapin (TN)",
      "Mizapin Sol (TN)",
      "Norset (TN)",
      "Noxibel (TN)",
      "Pharmasole (TN)",
      "Pharmazepine (TN)",
      "Promyrtil (TN)",
      "Remergil (TN)",
      "Remergon (TN)",
      "Remeron (TN)",
      "Remeron SolTab (TN)",
      "Remirta (TN)",
      "Rexer (TN)",
      "SolTab (TN)",
      "Tarzapine (TN)",
      "Tazamel (TN)",
      "Tazapin (TN)",
      "Tazascope (TN)",
      "Tirzamed (TN)",
      "Valdren (TN)",
      "Vastat (TN)",
      "Zapex (TN)",
      "Zicomber (TN)",
      "Zismirt (TN)",
      "Zispin (TN)",
      "Zispin SolTab (TN)",
      "Zuleptan (TN)",
      "Mirtazapine [USAN:BAN:INN]",
      "Mirtazapine [USAN:INN:BAN]",
      "Mirzaten Q-Tab (TN)",
      "Zistap. (TN)",
      "Mirtazapine (JAN/USAN/INN)",
      "(1)-1,2,3,4,10,14b-Hexahydro-2-Methylpyrazino(2,1-A)Pyrido(2,3-C)(2)Benzazepine",
      "(N-Methyl-11C)Mirtazapine",
      "1,2,3,4,10,14b-Hexahydro-2-Methylpyrazino(2,1-A)Pyrido(2,3-C)Benzazepine",
      "1,2,3,4,10,14b-Hexahydro-2-Methylpyrazino[2,1-A]pyrido[2,3-C][2]benzazepine",
      "2-Methyl-1,2,3,4,10,14b-Hexahydropyrazino[2,1-A]pyrido[2,3-C][2]benzazepine",
      "5-Methyl-2,5,19-Triazatetracyclo[13.4.0.0^{2,7}.0^{8,13}]nonadeca-1(15),8(13),9,11,16,18-Hexaene",
      "6-Azamianserin",
    ],
  },
  {
    primary_id: "D05ZIP",
    names: [
      "(4-Bromo-1H-Pyrazol-1-Yl)(P-Tolyl)Methanone",
      "AC1LDSCF",
      "N-Benzoylpyrazole Deriv., 7",
      "CHEMBL244909",
      "ZINC37188",
      "BDBM23705",
      "MolPort-002-174-815",
      "AKOS000576906",
      "4-Bromopyrazolyl 4-Methylphenyl Ketone",
      "MCULE-8194486011",
      "ST033225",
      "AB00100488-01",
      "(4-Bromopyrazol-1-Yl)-(4-Methylphenyl)Methanone",
      "SR-01000521427",
      "SR-01000521427-1",
    ],
  },
  {
    primary_id: "D05ZIS",
    names: ["SM-04690"],
  },
  {
    primary_id: "D05ZIT",
    names: ["EC-400"],
  },
  {
    primary_id: "D05ZJC",
    names: [
      "(3,5-Dibromo-4-Butoxy-Phenyl)-Acetic Acid",
      "Benzeneacetic Acid, 3,5-Dibromo-4-Butoxy-",
      "SCHEMBL4890007",
      "CHEMBL192490",
      "BDBM18899",
      "340697-16-7",
      "2-(3,5-Dibromo-4-Butoxyphenyl)Acetic Acid",
      "3,5-Dibromo-4-Alkoxyphenylalkanoic Acid, 9b",
    ],
  },
  {
    primary_id: "D05ZJG",
    names: [
      "Anisodine",
      "52646-92-1",
      "SCHEMBL474045",
      "AC1N31M8",
      "2,3-Dihydroxy-2-Phenylpropanoic Acid 9-Methyl-3-Oxa-9-Azatricyclo[3.3.1.02,4]nonane-7alpha-Yl Ester",
    ],
  },
  {
    primary_id: "D05ZKG",
    names: ["ISIS 111105"],
  },
  {
    primary_id: "D05ZKW",
    names: ["LY134046"],
  },
  {
    primary_id: "D05ZLH",
    names: ["IMP-731"],
  },
  {
    primary_id: "D05ZLY",
    names: ["AST-008"],
  },
  {
    primary_id: "D05ZNA",
    names: ["ESBA-903"],
  },
  {
    primary_id: "D05ZOT",
    names: ["BIND-014"],
  },
  {
    primary_id: "D05ZPJ",
    names: [
      "4-MA",
      "4-Methylbenzoic Acid",
      "P-TOLUIC ACID",
      "99-94-5",
      "4-Toluic Acid",
      "Crithminic Acid",
      "P-Methylbenzoic Acid",
      "P-Toluylic Acid",
      "Benzoic Acid, 4-Methyl-",
      "P-Carboxytoluene",
      "Para-Toluic Acid",
      "4-Methyl-Benzoic Acid",
      "Toluate",
      "Toluenecarboxylic Acid",
      "P-Toluate",
      "UNII-A26GBX5SSV",
      "Para Toluic Acid",
      "NSC 2215",
      "P-Toluic Acid, 98%",
      "EINECS 202-803-3",
      "A26GBX5SSV",
      "BRN 0507600",
      "AI3-15627",
      "CHEBI:36635",
      "2420-97-5 (Cadmium Salt)",
      "LPNBBFKOUUSUDB-UHFFFAOYSA-N",
      "P-Toluicacid",
      "4MA",
      "Crithmic Acid",
      "Rho-Toluic Acid",
      "4-Carboxytoluene",
      "4-MethylbenzoicAcid",
      "4-Methylbenzic Acid",
    ],
  },
  {
    primary_id: "D05ZPL",
    names: ["Polidocanol", "Asclera (TN)"],
  },
  {
    primary_id: "D05ZPO",
    names: [
      "7-Hydroxy-2-Phenylchroman-4-One",
      "7-Hydroxyflavanone",
      "6515-36-2",
      "7-Hydroxyflavanone, 98%",
      "CHEMBL97542",
      "CHEBI:34483",
      "SWAJPHCXKPCPQZ-UHFFFAOYSA-N",
      "7-Hydroxy-2-Phenyl-2,3-Dihydro-4H-Chromen-4-One",
      "MFCD00017487",
      "2,3-Dihydro-7-Hydroxy-2-Phenyl-4H-1-Benzopyran-4-One",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-7-Hydroxy-2-Phenyl-",
      "7-Hydroxy-2-Phenyl-4-Chromanone",
      "2545-13-3",
      "7-Hydroxy-Flavanone",
      "AC1L1CGO",
      "AC1Q6KJV",
      "ACMC-1B5B3",
      "Oprea1_401356",
      "MLS001181922",
      "SCHEMBL130266",
      "DTXSID0022430",
      "CTK4F5779",
      "MolPort-002-903-717",
      "HMS2865F07",
      "HMS1664L06",
    ],
  },
  {
    primary_id: "D05ZQC",
    names: ["PRISOTINOL", "CGS-5649B", "Prisotinol", "Rac-2-[2-(Isopropylamino)Propyl]-5-Hydroxypyridine"],
  },
  {
    primary_id: "D05ZQO",
    names: [
      "N-(3-Phenethoxybenzyl)-4-Hydroxybenzamide",
      "CHEMBL248875",
      "BDBM50220592",
      "CJ 036878",
      "Benzamide, 4-Hydroxy-N-((3-(2-Phenylethoxy)Phenyl)Methyl)-",
    ],
  },
  {
    primary_id: "D05ZQV",
    names: ["NK-611", "2''-Deoxy-2''-(Dimethylamino)Etoposide"],
  },
  {
    primary_id: "D05ZQZ",
    names: ["ISIS 134532"],
  },
  {
    primary_id: "D05ZRR",
    names: [
      "9-(3-Aminobenzyl)-6-(Furan-2-Yl)-9H-Purin-2-Amine",
      "CHEMBL260788",
      "SCHEMBL3268186",
      "XBESCJVITPKCJA-UHFFFAOYSA-N",
      "BDBM50377513",
    ],
  },
  {
    primary_id: "D05ZSB",
    names: ["Ovine Corticotropin-Releasing Hormone"],
  },
  {
    primary_id: "D05ZSX",
    names: ["NN-8828"],
  },
  {
    primary_id: "D05ZTH",
    names: [
      "Iloprost",
      "Ciloprost",
      "Endoprost",
      "Ilomedin",
      "Ilomedine",
      "Iloprostum",
      "Ventavis",
      "Iloprostum [Latin]",
      "ZK 36374",
      "E-1030",
      "Iloprost (INN)",
      "Iloprost [BAN:INN]",
      "SH-401",
      "Ventavis (TN)",
      "ZK-36374",
      "ZK-363775",
      "[3H]-Iloprost",
      "(16R,S)-Methyl-18,18,19,19-Tetradehydro-6a-Carbaprostaglandin I(Sub 2)",
      "(5E)-5-[(3aS,4R,5R,6aS)-5-Hydroxy-4-[(E,3S)-3-Hydroxy-4-Methyloct-1-En-6-Ynyl]-3,3a,4,5,6,6a-Hexahydro-1H-Pentalen-2-Ylidene]pentanoic Acid",
      "(5E)-5-[(3aS,5R,6aS)-5-Hydroxy-4-[(1E,3S)-3-Hydroxy-4-Methyloct-1-En-6-Yn-1-Yl]hexahydropentalen-2(1H)-Ylidene]pentanoic Acid",
      "(5E)-5-[(3aS,5R,6aS)-5-Hydroxy-4-[(E,3S)-3-Hydroxy-4-Methyloct-1-En-6-Ynyl]-3,3a,4,5,6,6a-Hexahydro-1H-Pentalen-2-Ylidene]pentanoic Acid",
      "(5Z)-5-[(3aS,5R,6aS)-5-Hydroxy-4-[(E,3R)-3-Hydroxy-4-Methyloct-1-En-6-Ynyl]-3,3a,4,5,6,6a-Hexahydro-1H-Pentalen-2-Ylidene]pentanoic Acid",
      "(E)-(3aS,4R,5R,6aS)-Hexahydro-5-Hydroxy-4-((E)-(3S,4RS)-3-Hydroxy-4-Methyl-1-Octen-6-Ynyl)-Delta(Sup 2(1H),delta)-Pentalenevaleric Acid",
      "(E)-5-(3aS,4R,5R,6aS)-5-Hydroxy-4((E)-(3S,4RS)-3-Hydroxy-4-Methyl-1-Octen-6-Inyl)Perhydropentalen-2-Yliden)Valeriansaeure",
    ],
  },
  {
    primary_id: "D05ZTI",
    names: [
      "3-Hydroxy-Benzamide",
      "3-Hydroxybenzamide",
      "618-49-5",
      "Benzamide, 3-Hydroxy-",
      "CHEMBL419424",
      "3-Hydroxy Benzamide",
      "NSC379289",
      "3-Hydroxybenzenecarboxamide",
      "AC1Q4ZB3",
      "AC1L7W2U",
      "ACMC-1B71B",
      "Oprea1_435073",
      "SCHEMBL161861",
      "3-Hydroxybenzamide, AldrichCPR",
      "CTK2F7291",
      "DTXSID90321635",
      "NGMMGKYJUWYIIG-UHFFFAOYSA-N",
      "MolPort-001-791-593",
      "ZINC1590754",
      "KM0548",
      "BDBM50068769",
      "9282AB",
      "ANW-33964",
      "3-HYDROXY-BENZOIC ACID,AMIDE",
      "SBB079277",
      "AKOS000207073",
      "VZ26952",
      "NSC-379289",
      "MB00281",
      "MCULE-9599926365",
      "NCGC00323509-01",
      "KB-32185",
      "CJ-25437",
      "AJ-27681",
      "CJ-05592",
      "SC-47787",
    ],
  },
  {
    primary_id: "D05ZTJ",
    names: ["Anileridine Hydrochloride", "Anileridine Phosphate", "Leritine"],
  },
  {
    primary_id: "D05ZTV",
    names: ["Dipeptide Analog 2"],
  },
  {
    primary_id: "D05ZUX",
    names: ["LuAE51090"],
  },
  {
    primary_id: "D05ZVR",
    names: ["Netropsin"],
  },
  {
    primary_id: "D05ZVU",
    names: ["ANG-3062"],
  },
  {
    primary_id: "D05ZWG",
    names: ["Senktide"],
  },
  {
    primary_id: "D05ZWR",
    names: ["PMID25666693-Compound-90"],
  },
  {
    primary_id: "D05ZXS",
    names: ["MAXY-G34"],
  },
  {
    primary_id: "D05ZXZ",
    names: ["BAY 57-1293"],
  },
  {
    primary_id: "D05ZYA",
    names: [
      "5-Bromomethyl-Oxazolidin-(2Z)-Ylideneamine",
      "5-Bromomethyl-4,5-Dihydro-Oxazol-2-Ylamine",
      "CHEMBL447832",
      "52886-60-9",
      "5-(Bromomethyl)-4,5-Dihydro-1,3-Oxazol-2-Amine",
      "AC1MJII5",
      "SCHEMBL11817450",
      "MolPort-001-940-341",
      "5-(Bromomethyl)Oxazolidine-2-Imine",
      "SBB010634",
      "BDBM50138186",
      "AKOS016344418",
      "AKOS000301813",
      "BAS 00615750",
      "5-(Bromomethyl)-1,3-Oxazoline-2-Ylamine",
      "BB 0261596",
    ],
  },
  {
    primary_id: "D05ZYM",
    names: [
      "N-Acetyl-D-Glucosamine",
      "Aflexa",
      "GlcNAc",
      "NAcGlc",
      "NDG",
      "Crab Shell Chitin",
      "Glucosamine Complex",
      "Maxi GS",
      "Natures Blend Glucosamine",
      "Pharmaceutical Aid",
      "JFD00446",
      "SB01966",
      "GS-500",
      "N-Acetylchitosamine",
      "N-Acetylchondrosamine",
      "N-Acetylgalactosamine",
      "N-Acetylhexosamine",
      "Glucosamine, N-Acetyl",
      "N-Acetyl-D-Allosamine",
      "N-Acetyl-D-Galactosamine",
      "N-Acetyl-D-Hexosamine",
      "N-Acetyl-D-Mannosamine",
      "N-Acetyl-D-Glucosamine-Agarose",
      "N-Acetyl-Alpha-D-Glucosamine",
      "N-Acetyl-Alpha-D-Glucosaminides",
      "N-[2,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-3-Yl]acetamide",
      "N-[(3R,4R,5S,6R)-2,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-3-Yl]acetamide",
      "N-[(2R,3R,4S,5S,6R)-2,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-3-Yl]acetamide",
      "N-[(2S,3R,4R,5S,6R)-2,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-3-Yl]acetamide",
      "2-(Acetylamino)-2-Deoxyhexopyranose",
      "2-ACETAMIDO-2-DEOXY-D-ALLOSE",
      "2-Acetamido-2-Deoxy-D-Glucose",
      "2-Acetamido-2-Deoxyglucose",
      "2-Acetamido-D-Glucose",
      "2-Acetamido-2-Deoxy-D-Glucopyranose",
      "2-Acetamido-2-Deoxyhexopyranose",
      "6-Acetamido-6-Deoxy-.alpha.-D-Glucopyranose",
      "6-Acetamido-6-Deoxy-Acpha-D-Glucopyranose",
    ],
  },
  {
    primary_id: "D05ZYN",
    names: [
      "Cyclopentyl-(9-Ethyl-9H-Purin-6-Yl)-Amine",
      "CHEMBL301574",
      "BDBM50009684",
      "6-Cyclopentylamino-9-Ethyl-9H-Purine",
    ],
  },
  {
    primary_id: "D05ZYZ",
    names: ["NalBzOH"],
  },
  {
    primary_id: "D05ZZH",
    names: ["ISIS 6581"],
  },
  {
    primary_id: "D06AAA",
    names: ["Cis-2-(4-Chlorophenyl)-2-Fluorocyclopropanamine", "CHEMBL460269"],
  },
  {
    primary_id: "D06AAG",
    names: ["Anthrax Immune Globulin (AIG)"],
  },
  {
    primary_id: "D06AAP",
    names: ["Ipodate"],
  },
  {
    primary_id: "D06ABF",
    names: [
      "Loratadine",
      "Aerotina",
      "Alarin",
      "Alavert",
      "Alerpriv",
      "Allertidin",
      "Anhissen",
      "Biloina",
      "Bonalerg",
      "Civeran",
      "Claratyne",
      "Clarinase",
      "Claritin",
      "Claritine",
      "Clarityn",
      "Clarityne",
      "Clarium",
      "Fristamin",
      "Histaloran",
      "Lergy",
      "Lertamine",
      "Lesidas",
      "Lisino",
      "Loracert",
      "Loradex",
      "Loradif",
      "Loranox",
      "Lorantis",
      "Lorastine",
      "Loratadina",
      "Loratadinum",
      "Loratidine",
      "Loratyne",
      "Loraver",
      "Lorfast",
      "Loritine",
      "Lowadina",
      "Nularef",
      "Optimin",
      "Polaratyne",
      "Pylor",
      "Restamine",
      "Rhinase",
      "Rinomex",
      "Roletra",
      "Sanelor",
      "Sensibit",
      "Sohotin",
      "Tadine",
      "Velodan",
      "Versal",
      "Zeos",
      "Bedix Loratadina",
      "Claratyne Cold",
      "Claratyne Decongestant",
      "Clarinase Reperabs",
      "Claritin D",
      "Claritin Hives Relief",
      "Claritin Hives Relief Reditab",
      "Claritin Reditabs",
      "Claritin Reditab",
      "Clarityne Dy Repetabs",
      "Loratadina [Spanish]",
      "Loratadine Redidose",
      "Loratadine Wyeth Brand",
      "Loratadinum [Latin]",
      "Sinhistan Dy",
      "Talorat Dy",
      "Wyeth Brand Of Loratadine",
      "L 9664",
      "Sch 29851",
      "Sch29851",
      "Alavert (TN)",
      "AllergyX (TN)",
      "Children's Claritin",
      "Claritin (TN)",
      "Claritine (TN)",
      "Clarityn (TN)",
      "Clarityne (TN)",
      "Clarityne-D",
      "Flonidan (TN)",
      "Fristamin (TN)",
      "Lomilan (TN)",
      "Lorfast (TN)",
      "Rinolan (TN)",
      "Roletra (TN)",
      "Sch-29851",
      "Symphoral (TN)",
      "Tidilor (TN)",
      "Alavert, Claritin, Loratadine",
      "Loratadine [USAN:BAN:INN]",
      "Loratadine (JAN/USAN/INN)",
      "Ethyl 4-(8-Chloro-5,6-Dihydro-11H-Benzo[5,6]cyclohepta[1,2-B]pyridin-11-Ylidene)Piperidine-1-Carboxylate",
      "Ethyl 4-(8-Chloro-5,6-Dihydro-11H-Benzo(5,6)Cyclohepta(1,2-B)Pyridin-11-Ylidene)-1-Piperidinecarboxylate",
      "1-Piperidenecarboxylic Acid, 4-(8-Chloro-5,6-Duhydro-11H-Benzo [5,6]cyclohepta[1,2-B]-Pyridin-11-Ylidene)-, Ethyl Ester",
      "1-Piperidinecarboxylic Acid, 4-(8-Chloro-5,6-Dihydro-11H-Benzo(5,6)Cyclohepta(1,2-B)Pyridin-11-Ylidene)-, Ethyl Ester",
      "1-Piperidinecarboxylic Acid, 4-(8-Chloro-5,6-Dihydro-11H-Benzo[5,6]cyclohepta[1,2-B]pyridin-11-Ylidene)-, Ethyl Ester",
      "1-Piperidinecarboxylic Acid,4-(8-Chloro-5,6-Dihydro-11H-Benzo(5,6)Cyclohepta(1,2-B)Pyridin-11-Ylidene)-,ethyl Ester",
      "4-(8-Chloro-5,6-Dihydro-11H-Benzo(5,6)Cyclohepta(1,2-B)Pyridin-11-Ylidene)-1-Piperidinecarboxylic Acid Ethyl Ester",
      "4-(8-Chloro-5,6-Dihydro-11H-Benzo[5,6]cyclohepta[1,2-B]pyridin-11-Ylidene-1-Piperidinecarboxylic Acidethyl Ester",
      "4-(8-Chloro-5,6-Dihydro-11H-Benzo[5,6]cycloheptal[1,2-B]pyridin-11-Ylidene-1-Piperidinecarboxylic Acid Ethyl Ester",
    ],
  },
  {
    primary_id: "D06ABJ",
    names: [
      "[2-(4-Fluoro-1H-Indol-3-Yl)-Ethyl]-Dimethyl-Amine",
      "CHEMBL197646",
      "SCHEMBL6108769",
      "ISJZKVWGUWBUFG-UHFFFAOYSA-N",
      "BDBM50171264",
      "1644-64-0",
      "4-Fluoro-3-(Dimethylaminoethyl)-1H-Indole",
    ],
  },
  {
    primary_id: "D06ABL",
    names: [
      "Rec 15/2615",
      "CHEMBL430717",
      "SCHEMBL5789541",
      "GTPL8459",
      "ZINC602525",
      "BDBM50057463",
      "NCGC00370846-01",
      "2-[4-(6-Isopropyl-2-Methoxyphenoxyacetyl)Piperazino]-6,7-Dimethoxyquinazoline-4-Amine",
      "1-[4-(4-Amino-6,7-Dimethoxy-Quinazolin-2-Yl)-Piperazin-1-Yl]-2-(2-Isopropyl-6-Methoxy-Phenoxy)-Ethanone",
    ],
  },
  {
    primary_id: "D06ABM",
    names: ["CAB-175", "BC-1175", "CB-181963", "Cephalosporin, Cubist"],
  },
  {
    primary_id: "D06ABW",
    names: [
      "CLIOQUINOL",
      "Clioquinol",
      "130-26-7",
      "5-Chloro-7-Iodoquinolin-8-Ol",
      "Iodochlorhydroxyquin",
      "Chinoform",
      "Chloroiodoquin",
      "Chloroiodoquine",
      "5-Chloro-8-Hydroxy-7-Iodoquinoline",
      "Iodochloroxyquinoline",
      "Cliquinol",
      "Vioform",
      "Iodochlorohydroxyquinoline",
      "Chlorojodochin",
      "Iodochloroxine",
      "Iodochloroquine",
      "Enteroquinol",
      "Iodoenterol",
      "7-Iodo-5-Chloroxine",
      "Entero-Vioform",
      "5-Chloro-7-Iodo-8-Quinolinol",
      "Iodochlorhydroxyquinoline",
      "Iodoxyquinoline",
      "Rheaform",
      "Quinoform",
      "Quinambicide",
      "Lekosept",
      "Dioquinol",
      "Dermaform",
      "Iodenterol",
      "Entrokin",
      "Enteroseptol",
      "Domeform",
      "Barquinol",
    ],
  },
  {
    primary_id: "D06ACI",
    names: ["BZYX", "Acetylcholinesterase Inhibitors (Neurodegenerative Disease), Zhejiang University"],
  },
  {
    primary_id: "D06ACW",
    names: [
      "Adenosine Monophosphate",
      "ASMP",
      "Adenovite",
      "Adenyl",
      "Adenylate",
      "Cardiomone",
      "Lycedan",
      "Monophosphadenine",
      "Muskeladenylsaeure",
      "Myoston",
      "PAdo",
      "Phosaden",
      "Phosphaden",
      "Phosphentaside",
      "AMP Dianion",
      "Adenine Polynucleotides",
      "Adenosine Phosphate",
      "Adenosini Phosphas",
      "Adenylic Acid",
      "Ergadenylic Acid",
      "Fosfato De Adenosina",
      "Muscle Adenylic Acid",
      "POLY A",
      "Polyadenylic Acids",
      "Vitamin B8",
      "A-5mp",
      "AMP (VAN)",
      "AMP (Nucleotide)",
      "Adenosine Monophosphate (Amp)",
      "Adenosine-Monophosphate",
      "Adenosine-Phosphate",
      "Adenosini Phosphas [INN-Latin]",
      "Adenyl (TN)",
      "Adenylic Acid (VAN)",
      "Ado5'P",
      "Fosfato De Adenosina [INN-Spanish]",
      "Muskeladenosin-Phosphorsaeure",
      "Phosphate D'adenosine",
      "Adenosine 5'-Monophosphate",
      "Adenosine 5'-Monophosphoric Acid",
      "Adenosine 5'-Phosphate",
      "Adenosine 5'-Phosphoric Acid",
      "Adenosine Phosphate (USAN/INN)",
      "Adenosine Phosphate [USAN:BAN:INN]",
      "Adenosine Phosphate [USAN:INN:BAN]",
      "Adenosine-5'P",
      "Adenosine-5-Monophosphate Dianion",
      "Adenosine-5-Monophosphoric Acid",
      "Adenosine-5-Phosphate",
      "Formycin 5'-Monophosphate Hydrate",
      "Formycin 5'-Phosphate",
      "Formycin 5'-Phosphate Hydrate",
      "Formycin A 5'-Monophosphate Hydrate",
      "My-B-Den",
      "Phosphate D'adenosine [INN-French]",
      "ADENOSINE-5'-PHOSPHATE",
      "Adenosine 5'-(Dihydrogen Phosphate)",
      "Adenosine, Mono(Dihydrogen Phosphate) (Ester)",
      "Adenosine-5'-Monophosphate",
      "Adenosine-5'-Monophosphoric Acid",
      "Adenosine-5-Monophosphate(2-)",
      "GAMMA-ARSONO-BETA, GAMMA-METHYLENEADENOSINE-5'-DIPHOSPHATE",
      "[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Dihydrogen Phosphate",
      "(S)-1-C-(7-Amino-1H-Pyrazolo(4,3-D)Pyrimidin-3-Yl)-1,4-Anhydro-D-Ribitol, 5-(Dihydrogen Phosphate)",
      "1H-Pyrazolo(4,3-D)Pyrimidine, 7-Amino-3-Beta-D-Ribofuranosyl-, 5'-(Dihydrogen Phosphate)-, Hydrate",
      "5'-Adenylic Acid, Homopolymer",
      "5'-Deoxy-5'-Thioadenosine 5'-Monophosphate",
      "5'-O-Phosphonatoadenosine",
      "5'-O-Phosphonoadenosine",
      "5'-Thio-5'-Adenylic Acid",
      "5'-Adenosine Monophosphate",
      "5'-Adenylic Acid",
      "7-Amino-3-Beta-D-Ribofuranosyl-1H-Pyrazolo(4,3-D)Pyrimidine 5'-(Dihydrogen Phosphate) Hydrate",
      "7A8E6D15-9136-44C1-88C9-E1A224638E56",
      "7gpb",
      "8-Aminoadenosine 5'-Monophosphate",
      "8gpb",
    ],
  },
  {
    primary_id: "D06ACX",
    names: ["HIMBACINE"],
  },
  {
    primary_id: "D06ADL",
    names: ["Sulfamic Acid 4-(3-Methoxy-Benzoyl)-Phenyl Ester", "CHEMBL63345"],
  },
  {
    primary_id: "D06AEB",
    names: ["Iodoquinol"],
  },
  {
    primary_id: "D06AEO",
    names: [
      "Cyproterone",
      "Ciproterona",
      "Ciproterone",
      "CyPat",
      "Cyproteron",
      "Cyproteronum",
      "Ciproterona Servycal",
      "Ciproterone [DCIT]",
      "SH 80881",
      "Apo-Cyproterone",
      "Ciproterona Servycal (TN)",
      "Ciproterona [INN-Spanish]",
      "Cyproterone (INN)",
      "Cyproterone [INN:BAN]",
      "Cyproteronum [INN-Latin]",
      "Dianette (TN)",
      "Gen-Cyproterone",
      "Novo-Cyproterone",
      "Diane-35 (TN)",
      "(1R,3aS,3bR,7aR,8aS,8bS,8cS,10aS)-1-Acetyl-5-Chloro-1-Hydroxy-8b,10a-Dimethyl-2,3,3a,3b,7a,8,8a,8b,8c,9,10,10a-Dodecahydrocyclopenta[a]cyclopropa[g]phenanthren-7(1H)-One",
      "6-Chlor-Delta(6)-1,2-Alpha-Methylen-17-Alpha-Hydroxyprogesteron",
      "6-Chlor-Delta(Sup 6)-1,2-Alpha-Methylen-17-Alpha-Hydroxyprogesteron",
      "6-Chlor-Delta(Sup 6)-1,2-Alpha-Methylen-17-Alpha-Hydroxyprogesteron [German]",
      "6-Chloro-17-Hydroxy-1beta,2beta-Dihydro-3'H-Cyclopropa[1,2]pregna-4,6-Diene-3,20-Dione",
    ],
  },
  {
    primary_id: "D06AES",
    names: ["BB-16000"],
  },
  {
    primary_id: "D06AFD",
    names: [
      "N-Hydroxy-3-Naphthalen-2-Yl-N-P-Tolyl-Acrylamide",
      "CHEMBL366714",
      "BDBM50015103",
      "(E)-3-(2-Naphthalenyl)-N-(4-Methylphenyl)-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D06AFP",
    names: [
      "N-Hydroxy-2-(Naphthalen-2-Ylsulfanyl)-Acetamide",
      "CHEMBL182879",
      "834918-57-9",
      "CTK3D2016",
      "DTXSID40465831",
      "BDBM50157325",
      "AKOS030565680",
      "Acetamide, N-Hydroxy-2-(2-Naphthalenylthio)-",
    ],
  },
  {
    primary_id: "D06AGD",
    names: ["MK-0963"],
  },
  {
    primary_id: "D06AGH",
    names: ["Trametinib + Dabrafenib"],
  },
  {
    primary_id: "D06AGN",
    names: ["Indirubin-3-Acetoxime"],
  },
  {
    primary_id: "D06AHQ",
    names: ["Ro-31-8830"],
  },
  {
    primary_id: "D06AIH",
    names: [
      "Relamorelin",
      "661472-41-9",
      "Relamorelin [INN]",
      "Relamorelin [WHO-DD]",
      "Relamorelin [USAN:INN]",
      "BIM28131",
      "BIM-28131",
      "Relamorelin (USAN/INN)",
      "SCHEMBL17513917",
      "4-Piperidinecarboxamide, 3-Benzo(B)Thien-3-Yl-N-(4-Piperidinylcarbonyl)-D-Alanyl-D-Tryptophyl-L-Phenylalanyl-4-Amino-",
      "D10660",
    ],
  },
  {
    primary_id: "D06AIQ",
    names: ["IBZM"],
  },
  {
    primary_id: "D06AJH",
    names: [
      "4-Methyl-N-(Naphthalen-1-Yl)Thiazol-2-Amine",
      "CHEMBL383736",
      "AC1LEV1N",
      "Maybridge1_006094",
      "SCHEMBL14433296",
      "HMS558M22",
      "CCG-716",
      "MolPort-000-432-043",
      "ZINC5730833",
      "BDBM50183094",
      "AKOS002285219",
      "MCULE-5807523116",
      "ST50046832",
      "(4-Methyl(1,3-Thiazol-2-Yl))Naphthylamine",
    ],
  },
  {
    primary_id: "D06AJL",
    names: ["Antacids"],
  },
  {
    primary_id: "D06AJN",
    names: ["PMID7629799C6", "Compound 3 [PMID: 8576905]", "GTPL3063", "BDBM50292846"],
  },
  {
    primary_id: "D06AJP",
    names: ["(E)-5-(3-Chlorostyryl)Isatin", "CHEMBL447307"],
  },
  {
    primary_id: "D06AKB",
    names: ["3-[(4'-Hydroxybiphenyl-4-Yl)Methyl]pyridine", "CHEMBL1215663", "BDBM50324610"],
  },
  {
    primary_id: "D06AKC",
    names: ["IVVS-1001", "Anticancer Drug (Breast Cancer), Invivis", "Anticancer Drug (Breast Cancer), Pharmalead"],
  },
  {
    primary_id: "D06AKJ",
    names: ["FR-111142"],
  },
  {
    primary_id: "D06AKW",
    names: ["N-(5-M-Tolyl-Furan-2-Carbonyl)-Guanidine", "CHEMBL364735", "SCHEMBL4936806"],
  },
  {
    primary_id: "D06ALB",
    names: ["BCH-2763"],
  },
  {
    primary_id: "D06ALC",
    names: ["Lithospermic Acid"],
  },
  {
    primary_id: "D06ALD",
    names: [
      "Empagliflozin",
      "864070-44-0",
      "JARDIANCE",
      "BI 10773",
      "UNII-HDC1R2M35U",
      "Empagliflozin (BI 10773)",
      "BI-10773",
      "BI10773",
      "HDC1R2M35U",
      "CHEBI:82720",
      "1-Chloro-4-(Glucopyranos-1-Yl)-2-(4-(Tetrahydrofuran-3-Yloxy)Benzyl)Benzene",
      "AK160980",
      "(1S)-1,5-Anhydro-1-(4-Chloro-3-{4-[(3S)-Tetrahydrofuran-3-Yloxy]benzyl}phenyl)-D-Glucitol",
      "(2S,3R,4R,5S,6R)-2-(4-Chloro-3-(4-(((S)-Tetrahydrofuran-3-Yl)Oxy)Benzyl)Phenyl)-6-(Hydroxymethyl)Tetrahydro-2H-Pyran-3,4,5-Triol",
    ],
  },
  {
    primary_id: "D06ALK",
    names: ["Xmab-2513"],
  },
  {
    primary_id: "D06ANS",
    names: [
      "CVBT-141H",
      "CVBT-141A",
      "CVBT-141C",
      "CVBT-141C)",
      "Cardio Vascu-Grow",
      "FGF-1-141",
      "Recombinant FGF-1 (Coronary Heart Disease), CardioVascular Therapeutics",
      "Recombinant FGF-1 (Peripheral Vascular Disease), CardioVascular Biotherapeutics",
    ],
  },
  {
    primary_id: "D06ANT",
    names: ["Grass Pollen Extract Sublingual Vaccine"],
  },
  {
    primary_id: "D06AOQ",
    names: ["ISIS 29160"],
  },
  {
    primary_id: "D06AOY",
    names: [
      "3-(Indole-3-Yl)-4-Phenyl-1H-Pyrrole-2,5-Dione",
      "CHEMBL380598",
      "SCHEMBL3148490",
      "HVQJGNALTWNDMX-UHFFFAOYSA-N",
      "BDBM50375058",
      "2-(1H-Indole-3-Yl)-3-Phenylmaleimide",
    ],
  },
  {
    primary_id: "D06APK",
    names: ["Rinfabate"],
  },
  {
    primary_id: "D06APO",
    names: ["PDC-748"],
  },
  {
    primary_id: "D06AQG",
    names: ["5,10-Dioxy-2-Phenyl-Benzo[g]pteridin-4-Ylamine"],
  },
  {
    primary_id: "D06AQI",
    names: ["NN344"],
  },
  {
    primary_id: "D06AQJ",
    names: ["TRK-530", "4-(Methylsulfanyl)Phenylsulfanylmethanediphosphonic Acid Disodium Salt"],
  },
  {
    primary_id: "D06AQP",
    names: ["EC-0531"],
  },
  {
    primary_id: "D06ARD",
    names: ["Quinoline And Quinazoline Derivative 7"],
  },
  {
    primary_id: "D06ARI",
    names: ["NM-001"],
  },
  {
    primary_id: "D06ARL",
    names: ["[des-His1,Tyr5,Glu9,D-Tyr10]glucagon-NH2", "CHEMBL413666"],
  },
  {
    primary_id: "D06ARV",
    names: [
      "Anti-IL-23",
      "Anti-IL-23 (Chronic Inflammation)",
      "Anti-IL-23 (Chronic Inflammation), Merck & Co",
      "Anti-IL-23 (Chronic Inflammation), Schering-Plough",
      "Anti-Interleukin-23 Antibody (Chronic Inflammation), Merck & Co",
    ],
  },
  {
    primary_id: "D06ATM",
    names: ["Sphinganine", "Dihydrosphingosine", "Dihydro-D-Erythrosphingosine"],
  },
  {
    primary_id: "D06AUB",
    names: ["1-Benzyl-4-(2,4-Dichlorophenyl)Pyrrolidin-3-Amine", "CHEMBL395208"],
  },
  {
    primary_id: "D06AUM",
    names: ["S26284"],
  },
  {
    primary_id: "D06AUX",
    names: ["Enantio PAF C-16"],
  },
  {
    primary_id: "D06AVE",
    names: ["A-317567", "A317567", "A 317567"],
  },
  {
    primary_id: "D06AVM",
    names: ["Beta3-Tetrapeptide"],
  },
  {
    primary_id: "D06AVN",
    names: ["CS-891B"],
  },
  {
    primary_id: "D06AWA",
    names: ["SCIB-1"],
  },
  {
    primary_id: "D06AWD",
    names: ["Anti-D Human Immunoglobulin"],
  },
  {
    primary_id: "D06AWE",
    names: [
      "Buprenorphine",
      "Buprel",
      "Buprenex",
      "Buprenophine",
      "Buprenorfina",
      "Buprenorphinum",
      "Probuphine",
      "Temgesic",
      "Buprenorphine Hcl",
      "RX 6029M",
      "Buprenex (TN)",
      "Buprenorfina [INN-Spanish]",
      "Buprenorphine [INN:BAN]",
      "Buprenorphinum [INN-Latin]",
      "Subutex (TN)",
      "Temgesic (TN)",
      "Buprenorphine (JAN/INN)",
      "RX-6029-M",
      "Suboxone? (TN)",
      "[5alpha,7alpha(S)]-17-(Cyclopropylmethyl)-Alpha-(1,1-Dimethylethyl)-4,5-Epoxy-18,19-Dihydro-3-Hydroxy-6-Methoxy-Alpha-Methyl-6,14-Ethenomorphinan-7-Methanol",
      "(-)-Buprenorphine",
      "(5alpha,6beta,14beta,18R)-17-(Cyclopropylmethyl)-18-[(1S)-1-Hydroxy-1,2,2-Trimethylpropyl]-6-(Methyloxy)-18,19-Dihydro-4,5-Epoxy-6,14-Ethenomorphinan-3-Ol",
      "(5alpha,6beta,14beta,18R)-17-(Cyclopropylmethyl)-18-[(2S)-2-Hydroxy-3,3-Dimethylbutan-2-Yl]-6-Methoxy-18,19-Dihydro-4,5-Epoxy-6,14-Ethenomorphinan-3-Ol",
      "17-Cyclopropylmethyl-4,5alpha-Epoxy-7alpha-((S)-1-Hydroxy-1,2,2-Trimethylpropyl-6-Methoxy-6,14-Endo-Ethanomorphinan-3-Ol",
      "17-Cyclopropylmethyl-4,5alpha-Epoxy-7alpha-((S)-1-Hydroxy-1,2,2-Trimethylpropyl)-6-Methoxy-6,14-Endo-Ethanomorphinan-3-Ol",
      "2-(N-Cyclopropylmethyl-4,5alpha-Epoxy-3-Hydroxy-6-Methoxy-6,14-Endo-Ethanomorphinan-7alpha-Yl)-3,3-Dimethyl-2-Butanol",
      "2-(N-Cyclopropylmethyl-4,5alpha-Epoxy-3-Hydroxy-6-Methoxy-6,14-Endo-Ethanomorphinan-6alpha-Yl)-3,3-Dimethyl-2-Butanol",
      "21-(Cyclopropyl-7alpha-((S)-1-Hydroxy-1,2,2-Trimethylpropyl-6,14-Endo-Ethano-6,7,8,14-Tetrahydrooripavine",
      "21-Cyclopropyl-7alpha-(2-Hydroxy-3,3-Dimethyl-2-Butyl)-6,14-Endo-Ethano-6,7,8,14-Tetrahydrooripavine",
      "21-Cyclopropyl-7alpha-[(S)-1-Hydroxy-1,2,2-Trimethylpropyl]-6,14-Endo-Ethano-6,7,8,14-Tetrahydrooripavine",
      "6,14-Ethenomorphinan-7-Methanol",
      "6,14-Ethenomorphinan-7-Methanol, 17-(Cyclopropylmethyl)-Alpha-(1,1-Dimethylethyl)-4,5-Epoxy-18,19-Dihydro-3-Hydroxy-6-Methoxy-Alpha-Methyl-, (AlphaS,5alpha,7alpha)-(9CI)",
      "6029-M",
    ],
  },
  {
    primary_id: "D06AWX",
    names: [
      "CAULERPIN",
      "Caulerpin",
      "26612-48-6",
      "CHEMBL377236",
      "CHEBI:80923",
      "Cycloocta(1,2-B:5,6-B')Diindole-6,13-Dicarboxylic Acid, 5,12-Dihydro-, Dimethyl Ester",
      "Cycloocta[1,2-B:5,6-B']diindole-6,13-Dicarboxylic Acid, 5,12-Dihydro-, Dimethyl Ester",
      "SCHEMBL13808752",
      "NSC719624",
      "BDBM50184688",
      "NSC-719624",
    ],
  },
  {
    primary_id: "D06AXY",
    names: [
      "DOV 21947",
      "AMITIFADINE HYDROCHLORIDE",
      "410074-74-7",
      "Amitifadine (Hydrochloride)",
      "UNII-R01R720TVG",
      "Amitifadine HCl",
      "R01R720TVG",
      "Amitifadine Hydrochloride [USAN]",
      "Amitifadine Hydrochloride (USAN)",
      "DOV 21,947",
      "DOV-21947 Hydrochloride",
      "DOV 21947 Hydrochloride",
      "SCHEMBL4187671",
      "CHEMBL1818442",
      "(+/-)-Amitifadine Hydrochloride",
      "MolPort-046-033-494",
      "BCP23800",
      "HY-18332A",
      "AKOS030526808",
      "SB17141",
      "CS-4638",
      "KB-47352",
      "D10089",
      "UNII-06S4712H0T Component KAGBHVBIOJBGBD-NINOIYOQSA-N",
      "(1R,5S)-1-(3,4-Dichlorophenyl)-3-Azabicyclo(3.1.0)Hexane Hydrochlor",
    ],
  },
  {
    primary_id: "D06AYL",
    names: ["N-(Pyridin-3-Ylmethyl) Estradiol-16-Carboxamide"],
  },
  {
    primary_id: "D06AYN",
    names: ["HAMI3379"],
  },
  {
    primary_id: "D06AZC",
    names: ["NSC-131736"],
  },
  {
    primary_id: "D06AZH",
    names: ["B-220", "2,3-Dimethyl-6-(Dimethylaminoethyl)-9-Hydroxy-6H-Indolo-[2,3-B]quinoxaline", "9-OH-B-220"],
  },
  {
    primary_id: "D06AZJ",
    names: [
      "4-Cyclohexylamino-Pyrimidine-2-Carbonitrile",
      "2-Cyano-Pyrimidine, 5",
      "4-(Cyclohexylamino)Pyrimidine-2-Carbonitrile",
      "CHEMBL221591",
      "BDBM19749",
      "927176-94-1",
      "2-Pyrimidinecarbonitrile, 4-(Cyclohexylamino)-",
    ],
  },
  {
    primary_id: "D06AZX",
    names: ["ISIS 29176"],
  },
  {
    primary_id: "D06BAC",
    names: ["8-Bromo-2,3,4,9-Tetrahydro-1H-Beta-Carboline", "CHEMBL6857", "BDBM50136494", "AKOS022804102"],
  },
  {
    primary_id: "D06BAU",
    names: ["CTAP"],
  },
  {
    primary_id: "D06BBB",
    names: ["BMS309403", "BMS-309403", "BMS 309403"],
  },
  {
    primary_id: "D06BBF",
    names: ["BLV-0801"],
  },
  {
    primary_id: "D06BBI",
    names: [
      "(R)-N-Hydroxy-2-(4-Isobutyl-Phenyl)-Propionamide",
      "CHEMBL190266",
      "ZINC5284667",
      "UNII-O3LD16O96Z Component BYPIURIATSUHDW-SNVBAGLBSA-N",
    ],
  },
  {
    primary_id: "D06BBO",
    names: [
      "Polymerized Vaccine",
      "CLUSTOID Grass Pollen",
      "Polymerized Vaccine (Immunotherapy, Grass Pollen Allergy)",
      "Cluster-Allergoid CLUSTOID Grasses/Rye, Roxall Medizin",
      "Polymerized Vaccine (Immunotherapy, Grass Pollen Allergy), Dr Beckmann Pharma/Roxall Medizin",
    ],
  },
  {
    primary_id: "D06BBV",
    names: ["XZK-Monascus"],
  },
  {
    primary_id: "D06BBZ",
    names: ["ZM-224832"],
  },
  {
    primary_id: "D06BCB",
    names: [
      "Vinpocetine",
      "Bravinton",
      "Cavinton",
      "Ceractin",
      "Vinpocetinum",
      "Apovincaminic Acid Ethyl Ester",
      "AY 27255",
      "RGH 4405",
      "TCV 3B",
      "V 6383",
      "V6383_SIGMA",
      "AY 27,255",
      "Apovincaminate D'ethyle",
      "Apovincaminate D'ethyle [French]",
      "Cavinton (TN)",
      "Cis-Apovincaminic Acid Ethyl Ester",
      "Intelectol (TN)",
      "RGH-4405",
      "TCV-3B",
      "Ultra-Vinca",
      "Vinpocetine-Ethyl Apovincaminate",
      "Vinpocetinum [INN-Latin]",
      "Eburnamenine-14-Carboxylic Acid Ethyl Ester",
      "Ethyl Apovincamin-22-Oate",
      "Vinpocetine [USAN:INN:JAN]",
      "Ethyl (+)-Apovincaminate",
      "Vinpocetine (JAN/USAN/INN)",
      "Ethyl(+)-Cis-Apovincaminate",
      "Ethyl Apovincaminate, (3alpha,16alpha)-Isomer",
      "Ethyl (3alpha,16alpha)-Eburnamenine-14-Carboxylate",
      "(+)-Apovincaminic Acid Ethyl Ester",
      "(+)-Cis-Apovincaminic Acid Ethyl Ester",
      "(3alpha, 16alpha)-Eburnamenine-14-Carboxylic Acid Ethyl Ester",
      "1H-Indolo[3,2,1-De]pyrido[3,2,1-Ij][1,5]naphthyridine, Eburnamenine-14-Carboxylic Acid Deriv.",
      "3-Alpha,16-Alpha-Apovincaminic Acid Ethyl Ester",
    ],
  },
  {
    primary_id: "D06BCG",
    names: ["EUSYNSTYELAMIDE B"],
  },
  {
    primary_id: "D06BCT",
    names: ["Renin Inhibitors (Hypertension/Congestive Heart Failure) Pfizer"],
  },
  {
    primary_id: "D06BCY",
    names: ["Quinones"],
  },
  {
    primary_id: "D06BDB",
    names: ["Isosorbide-2-Benzylcarbamate-5-Acetate", "CHEMBL493460"],
  },
  {
    primary_id: "D06BDC",
    names: ["PMID16539403C15b"],
  },
  {
    primary_id: "D06BDP",
    names: ["OtiVax"],
  },
  {
    primary_id: "D06BDY",
    names: [
      "6-Methoxy-4-Morpholin-4-Yl-Chromen-2-One",
      "CHEMBL175510",
      "NU-7031",
      "79105-88-7",
      "6-Methoxy-4-Morpholino-2H-Chromen-2-One",
      "SCHEMBL3666851",
      "BDBM50159679",
    ],
  },
  {
    primary_id: "D06BEP",
    names: [
      "Chlordiazepoxide",
      "Abboxide",
      "Balance",
      "Chloradiazepoxide",
      "Chlordiazachel",
      "Chlordiazepoxid",
      "Chlordiazepoxidum",
      "Chlordiazepoxydum",
      "Chloridazepoxide",
      "Chloridiazepide",
      "Chloridiazepoxide",
      "Chlorodiazepoxide",
      "Chlozepid",
      "Clopoxide",
      "Clordiazepossido",
      "Clordiazepoxido",
      "Control",
      "Decacil",
      "Disarim",
      "Eden",
      "Elenium",
      "Helogaphen",
      "Ifibrium",
      "Kalmocaps",
      "Librelease",
      "Librinin",
      "Libritabs",
      "Librium",
      "Lygen",
      "Menrium",
      "Mesural",
      "Methaminodiazepoxide",
      "Mildmen",
      "Multum",
      "Napoton",
      "Napton",
      "Psicosan",
      "Radepur",
      "Risolid",
      "Silibrin",
      "Sonimen",
      "Tropium",
      "Viopsicol",
      "Zeisin",
      "Zetran",
      "Chlordiazepoxide Base",
      "Clordiazepossido [Italian]",
      "A-Poxide",
      "Apo-Chlordiazepoxide",
      "Balance (Pharmaceutical)",
      "Chlordiazepoxidum [INN-Latin]",
      "Clordiazepoxido [INN-Spanish]",
      "Eden-Psich",
      "Libritabs (TN)",
      "Librium (TN)",
      "MENRIUM 10-4",
      "MENRIUM 5-4",
      "Novapam (TN)",
      "Novo-Poxide",
      "Tropium (TN)",
      "Chlordiazepoxide (JP15/USP/INN)",
      "Chlordiazepoxide [USAN:INN:BAN:JAN]",
      "3H-1,4-Benzodiazepin-2-Amine, 7-Chloro-N-Methyl-5-Phenyl, 4-Oxide",
      "3H-1,4-Benzodiazepin-2-Amine, 7-Chloro-N-Methyl-5-Phenyl-, 4-Oxide",
      "3H-1,4-Benzodiazepine, 7-Chloro-2-(Methylamino)-5-Phenyl-, 4-Oxide",
      "3H-1,4-Benzodiazepin-2-Amine,7-Chloro-N-Methyl-5-Phenyl-,4-Oxide",
      "7-Chlor-2-Methylamino-5-Phenyl-3H-1,4-Benzodiazepin-4-Oxid",
      "7-Chlor-2-Methylamino-5-Phenyl-3H-1,4-Benzodiazepin-4-Oxid [German]",
      "7-Chloro-2-(Methylamino)-5-Phenyl-3H-1,4-Benzodiazepine 4-Oxide",
      "7-Chloro-N-Methyl-5-Phenyl-3H-1,4-Benzodiazepin-2-Amine 4-Oxide",
      "7-Cloro-2-Metilamino-5-Fenil-3H-1,4-Benzodiazepina 4-Ossido",
      "7-Cloro-2-Metilamino-5-Fenil-3H-1,4-Benzodiazepina 4-Ossido [Italian]",
      "7-Chloro-4-Hydroxy-N-Methyl-5-Phenyl-3H-1,4-Benzodiazepin-2-Imine",
    ],
  },
  {
    primary_id: "D06BEX",
    names: ["Gene Therapy, TheraPlas, Congestive Heart Failure"],
  },
  {
    primary_id: "D06BFA",
    names: ["PMID25399762-Compound-Table1-C5"],
  },
  {
    primary_id: "D06BFB",
    names: ["3beta,6beta-Dihydroxyolean-12-En-27-Oic Acid", "CHEMBL377760"],
  },
  {
    primary_id: "D06BFH",
    names: [
      "5-Fluoro-1H-Indole-2-Carboxylic Acid",
      "5-Fluoroindole-2-Carboxylic Acid",
      "399-76-8",
      "2-Carboxy-5-Fluoroindole",
      "1H-Indole-2-Carboxylic Acid, 5-Fluoro-",
      "CHEMBL23507",
      "MLS000080089",
      "WTXBRZCVLDTWLP-UHFFFAOYSA-N",
      "MFCD00005612",
      "SMR000037735",
      "5-Fluoroindole-2-Carboxylic Acid, 98%",
      "Spectrum_001495",
      "EINECS 206-919-5",
      "PubChem1683",
      "SpecPlus_000678",
      "Spectrum5_001733",
      "Opera_ID_1340",
      "Spectrum4_001182",
      "Spectrum3_001043",
      "Spectrum2_001469",
      "ACMC-209j9k",
      "AC1Q4NE3",
      "Lopac-265128",
      "Cid_1820",
      "AC1Q73TI",
      "AC1L1CB9",
      "AC1Q73TJ",
      "Lopac0_000071",
      "Oprea1_012690",
    ],
  },
  {
    primary_id: "D06BFP",
    names: [
      "CGS-17867A",
      "Cgs-17867A",
      "CHEMBL116842",
      "2-(4-Chloro-Phenyl)-2,5,6,7,8,9-Hexahydro-Pyrazolo[4,3-C]quinolin-3-One",
      "AC1L2T4E",
      "SCHEMBL2287621",
      "OJVURBSORBSWLX-UHFFFAOYSA-N",
      "CGS-17867",
      "BDBM50148320",
      "2-P-Tolyl-2,5,6,7,8,9-Hexahydro-Pyrazolo[4,3-C]quinolin-3-One",
      "2-(4-Chlorophenyl)-6,7,8,9-Tetrahydro-1H-Pyrazolo[4,3-C]quinolin-3-One",
    ],
  },
  {
    primary_id: "D06BGK",
    names: ["FiloVax"],
  },
  {
    primary_id: "D06BGO",
    names: ["Telcyta Canfosfamide"],
  },
  {
    primary_id: "D06BGS",
    names: [
      "AIDSVAX",
      "AIDSVAX B/B",
      "AIDSVAX B/E",
      "Bivalent Gp120 HIV Vaccines, GSID",
      "Bivalent Gp120 HIV Vaccines, Global Solutions For Infectious Diseases",
      "Bivalent Gp120 HIV Vaccines, VaxGen",
    ],
  },
  {
    primary_id: "D06BGU",
    names: ["(+/-)-3-(But-3-Enyl)-1-Pent-4-Enoylazetidin-2-One", "CHEMBL454297"],
  },
  {
    primary_id: "D06BGZ",
    names: ["LY3200882"],
  },
  {
    primary_id: "D06BHB",
    names: ["Trastuzumab Deruxtecan"],
  },
  {
    primary_id: "D06BHC",
    names: [
      "4-Amino-N-(4-Iodophenyl)Benzenesulfonamide",
      "6965-75-9",
      "NSC63371",
      "4-Amino-N-(4-Iodo-Phenyl)-Benzenesulfonamide",
      "Benzenesulfonamide, 4-Amino-N-(4-Iodophenyl)-",
      "4-Amino-N-(4-Iodophenyl)Benzene-1-Sulfonamide",
      "Benzenesulfonamide,4-Amino-N-(4-Iodophenyl)-",
      "NSC 63371",
      "Enamine_003086",
      "AC1Q6VVM",
      "AC1Q51XD",
      "AC1L6LA2",
      "AC1Q51XC",
      "4-IODOSULFANILANILIDE",
      "CHEMBL227416",
      "CTK5D0764",
      "DTXSID00219913",
      "MolPort-001-833-979",
      "HMS1402M06",
      "ZINC537958",
      "NSC-63371",
      "STK063720",
      "AKOS000115972",
      "NE11209",
      "MCULE-7780071880",
      "KB-290010",
      "EN300-02869",
      "SR-01000033730",
    ],
  },
  {
    primary_id: "D06BHG",
    names: ["Safotibant"],
  },
  {
    primary_id: "D06BHI",
    names: ["BMS-986089"],
  },
  {
    primary_id: "D06BHJ",
    names: [
      "3-(3,4-Dimethylbenzyl)-4-Hydroxy-2H-Chromen-2-One",
      "CHEMBL576116",
      "BDBM35551",
      "4-Hydroxy-2H-Chromen-2-One Core, 28",
    ],
  },
  {
    primary_id: "D06BHK",
    names: [
      "ORG-9453",
      "1-[3alpha-Acetoxy-17beta-Butyryloxy-2beta-(Piperidin-1-Yl)-5alpha-Androstan-16beta-Yl]-1-Methylpiperidinium Bromide",
    ],
  },
  {
    primary_id: "D06BHQ",
    names: ["YM-17K"],
  },
  {
    primary_id: "D06BIB",
    names: ["Kinetochore-Microtubule Binding Inhibitors"],
  },
  {
    primary_id: "D06BIE",
    names: ["PanBlok"],
  },
  {
    primary_id: "D06BIF",
    names: ["PMID2153213C13b"],
  },
  {
    primary_id: "D06BJG",
    names: ["PMID24915291C38"],
  },
  {
    primary_id: "D06BJP",
    names: ["NXB-4221", "Antibacterial (Urinary Tract Infection), Nymox"],
  },
  {
    primary_id: "D06BKA",
    names: ["1,1,1-Trifluoro-8-(4-Phenoxy-Phenoxy)-Octan-2-One", "CHEMBL117916", "SCHEMBL7366611", "BDBM50217945"],
  },
  {
    primary_id: "D06BKF",
    names: ["PEGylated Recombinant EPO"],
  },
  {
    primary_id: "D06BKH",
    names: ["GR-MD-02"],
  },
  {
    primary_id: "D06BKK",
    names: ["NN9748"],
  },
  {
    primary_id: "D06BKP",
    names: ["SC-5003"],
  },
  {
    primary_id: "D06BLF",
    names: [
      "1,2-Dibromo-4-(1,2-Dibromoethyl)Cyclohexane",
      "1,2-DIBROMO-4-(1,2-DIBROMOETHYL)CYCLOHEXANE",
      "3322-93-8",
      "Citex BCL 462",
      "Saytex BCL 462",
      "Cyclohexane, 1,2-Dibromo-4-(1,2-Dibromoethyl)-",
      "1-(1,2-Dibromoethyl)-3,4-Dibromocyclohexane",
      "Vinylcyclohexene Tetrabromide",
      "1,2-DIBROMO-4-(1,2-DIBROMOETHYL)-CYCLOHEXANE",
      "4-(1,2-Dibromoethyl)-1,2-Dibromocyclohexane",
      "CCRIS 3743",
      "HSDB 6146",
      "EINECS 222-036-8",
      "BRN 1927455",
      "CHEMBL375107",
      "AK-78933",
      "C8H12Br4",
      "TBECH",
      "AC1L2COC",
      "DSSTox_CID_4947",
      "DSSTox_RID_77594",
      "DSSTox_GSID_24947",
      "MLS002454355",
      "3-05-00-00093 (Beilstein Handbook Reference)",
      "SCHEMBL332771",
    ],
  },
  {
    primary_id: "D06BLQ",
    names: ["Sofalcone"],
  },
  {
    primary_id: "D06BMN",
    names: ["1-Allyl-5'-O-Tritylinosine", "CHEMBL213891", "1-Allyl-5''-O-Tritylinosine"],
  },
  {
    primary_id: "D06BMO",
    names: ["BI 1026706"],
  },
  {
    primary_id: "D06BMZ",
    names: ["2-Morpholino-1,1,2-Triphenylethanol", "CHEMBL1091863"],
  },
  {
    primary_id: "D06BNI",
    names: [
      "(9H-Beta-Carbolin-3-Yl)-Methanol",
      "3-Hydroxymethyl-Beta-Carboline",
      "65474-79-5",
      "UNII-00QJ5EQT3E",
      "9H-Pyrido[3,4-B]indol-3-Ylmethanol",
      "00QJ5EQT3E",
      "3-HMC",
      "3-(Hydroxymethyl)-Beta-Carboline",
      "3-HYDROXYMETHYL-B-CARBOLINE",
      "9H-Pyrido[3,4-B]indole-3-Methanol",
      "9H-Pyrido(3,4-B)Indole-3-Methanol",
      "Prestwick_970",
      "Biomol-NT_000286",
      "AC1NS42Q",
      "DivK1c_000628",
      "9H-Beta-Carboline-3-Methanol",
      "CHEMBL417982",
      "SCHEMBL2216848",
      "BPBio1_001234",
      "KBio1_000628",
      "CTK2F5969",
      "HMS501P10",
      "DTXSID20215795",
      "MolPort-005-942-230",
      "CPBYHTDUBNSBQM-UHFFFAOYSA-N",
      "CHEBI:114188",
      "NINDS_000628",
      "ZINC11535632",
    ],
  },
  {
    primary_id: "D06BNK",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 5"],
  },
  {
    primary_id: "D06BPF",
    names: [
      "R-102557",
      "1H-1,2,4-Triazole-1-Ethanol, .alpha.-(2,4-Difluorophenyl)-.alpha.-[(1R)-1-[[2-[(1E,3E)-4-[4-(2,2,3,3-Tetrafluoropropoxy)Phenyl]-1,3-Butadienyl]-1,3-Dioxan-5-Yl]phosphino]ethyl]-, (A1R)-",
    ],
  },
  {
    primary_id: "D06BPG",
    names: ["Nomifensine"],
  },
  {
    primary_id: "D06BPU",
    names: ["SC-83157"],
  },
  {
    primary_id: "D06BQU",
    names: ["Salicin"],
  },
  {
    primary_id: "D06BRA",
    names: ["Tyr-D-Ala-Gly-D-Trp-Nle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D06BSD",
    names: ["Bo(15)PZ", "CHEMBL198982"],
  },
  {
    primary_id: "D06BSE",
    names: ["EDI1200"],
  },
  {
    primary_id: "D06BSG",
    names: ["Thieno[3,2-C]pyridine-7-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D06BSN",
    names: [
      "2-Deoxy-2,3-Dehydro-N-Acetyl-Neuraminic Acid",
      "24967-27-9",
      "Neu5Ac2en",
      "2-Deoxy-2,3-Dehydro-N-Acetylneuraminic Acid",
      "N-Acetyl-2,3-Dehydro-2-Deoxyneuraminic Acid",
      "2,3-Dehydro-2-Deoxy-N-Acetylneuraminic Acid",
      "N-Acetyl-2,3-Didehydro-2-Deoxyneuraminic Acid",
      "EINECS 246-550-7",
      "CHEMBL96712",
      "CHEBI:28062",
      "C11H17NO8",
      "(2R,3R,4S)-3-Acetamido-4-Hydroxy-2-[(1R,2R)-1,2,3-Trihydroxypropyl]-3,4-Dihydro-2H-Pyran-6-Carboxylic Acid",
      "1,3-Dehydro-2-Deoxy-N-Acetylneuraminic Acid",
    ],
  },
  {
    primary_id: "D06BSV",
    names: ["PMID30107136-Compound-Example46"],
  },
  {
    primary_id: "D06BTD",
    names: ["4Ab-028"],
  },
  {
    primary_id: "D06BTF",
    names: [
      "Almorexant",
      "Almorexant [INN]",
      "ACT-078573",
      "(2R)-2-((1S)-6,7-Dimethoxy-1-{2-(4-(Trifluoromethyl)Phenyl)Ethyl}-3,4-Dihydroisoquinolin-2(1H)-Yl)-N-Methyl-2-Phenylacetamide",
    ],
  },
  {
    primary_id: "D06BTN",
    names: ["Pyrimidine Derivative 14"],
  },
  {
    primary_id: "D06BUA",
    names: ["Nexopamil"],
  },
  {
    primary_id: "D06BUG",
    names: ["PMID29338548-Compound-41"],
  },
  {
    primary_id: "D06BUI",
    names: ["PN-355", "AndroVir", "Tyrosine Kinase Inhibitors (HIV Infection), Paracelsian"],
  },
  {
    primary_id: "D06BUM",
    names: [
      "4-Carboxyphenylboronic Acid",
      "62729-39-9",
      "4-(5,5-Dimethyl-1,3,2-Dioxaborinan-2-Yl)Benzoic Acid",
      "Benzoic Acid,4-(5,5-Dimethyl-1,3,2-Dioxaborinan-2-Yl)-",
      "SCHEMBL8083423",
      "CTK5B5787",
      "DTXSID00656960",
      "ZX-AT029551",
      "ZINC169807903",
      "AKOS027380718",
      "FCH2811826",
      "AB29398",
    ],
  },
  {
    primary_id: "D06BUS",
    names: ["ST-7"],
  },
  {
    primary_id: "D06BVH",
    names: ["4-Phenyl-2-(Phenylamino)Pyrimidine-5-Carbonitrile", "CHEMBL411829", "SCHEMBL6033918"],
  },
  {
    primary_id: "D06BVI",
    names: ["PMID30247903-Compound-General Structure44"],
  },
  {
    primary_id: "D06BVT",
    names: [
      "Affitope AD-02",
      "Alzheimer's Disease Vaccine 2 (AFFITOPE), AFFiRiS",
      "Alzheimer's Disease Vaccine 2 (AFFITOPE), GlaxoSmithKline Biologicals",
    ],
  },
  {
    primary_id: "D06BWZ",
    names: ["MEZILAMINE"],
  },
  {
    primary_id: "D06BXT",
    names: [
      "[3H]WIN55212-2",
      "131513-18-3",
      "(5-Methyl-3-(Morpholinomethyl)-2,3-Dihydro-[1,4]oxazino[2,3,4-Hi]indol-6-Yl)(Naphthalen-1-Yl)Methanone",
      "(5-Methyl-3-(Morpholinomethyl)-2,3-Dihydro-[1,4]oxazino-[2,3,4-Hi]indol-6-Yl)(Naphthalen-1-Yl)Methanone",
      "[5-Methyl-3-(Morpholin-4-Ylmethyl)-2,3-Dihydro[1,4]oxazino[2,3,4-Hi]indol-6-Yl](1-Naphthyl)Methanone",
      "134959-51-6",
      "AC1Q5GBM",
      "AC1L1KWZ",
      "GTPL736",
      "SCHEMBL3680086",
      "CHEMBL283206",
      "CTK4B9570",
      "HQVHOQAKMCMIIM-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D06BYG",
    names: ["Clostridium Butyricum MIYAIRI 588", "Clostridium Butyricum M588", "CBM-588", "CDACTIN-O", "MIYA-BM"],
  },
  {
    primary_id: "D06BYR",
    names: ["N-Hydroxy-4-Oxo-4-(4-Phenoxy-Phenyl)-Butyramide", "CHEMBL198055"],
  },
  {
    primary_id: "D06BYS",
    names: ["Imepitoin", "ADD-233089", "AWD-131-138"],
  },
  {
    primary_id: "D06BYV",
    names: ["Phensuximide"],
  },
  {
    primary_id: "D06BYY",
    names: ["S-14671"],
  },
  {
    primary_id: "D06CAL",
    names: ["G36"],
  },
  {
    primary_id: "D06CAS",
    names: ["Cerliponase Alfa", "Brineura"],
  },
  {
    primary_id: "D06CAV",
    names: ["KiroVAX-004"],
  },
  {
    primary_id: "D06CBI",
    names: ["PMID26882240-Compound-4"],
  },
  {
    primary_id: "D06CBR",
    names: [
      "2-Amino-5-Phenylthiophene-3-Carboxamide",
      "4815-35-4",
      "2-Amino-5-Phenyl-Thiophene-3-Carboxylic Acid Amide",
      "CHEMBL382565",
      "2-AMINO-5-PHENYL-3-THIOPHENECARBOXAMIDE",
      "EN300-04586",
      "AC1LDZKQ",
      "ACMC-209kch",
      "3-Thiophenecarboxamide, 2-Amino-5-Phenyl-",
      "AC1Q5DNT",
      "CBMicro_037044",
      "Cambridge Id 5914467",
      "AC1Q4Z6N",
      "Oprea1_326775",
      "Oprea1_445444",
      "SCHEMBL3510525",
      "ZINC87504",
      "CTK4J0691",
      "DTXSID60351580",
      "UHEGYTDIDBFUJD-UHFFFAOYSA-N",
      "MolPort-000-223-057",
      "HMS1757A19",
      "ZX-AL001522",
      "ANW-30639",
      "STK346852",
      "SBB015475",
      "BDBM50186655",
      "AKOS000274130",
      "ACM4815354",
    ],
  },
  {
    primary_id: "D06CCR",
    names: ["Fialuridine"],
  },
  {
    primary_id: "D06CDG",
    names: ["2-Acetylaminoethyl 18-Methoxycoronaridinate"],
  },
  {
    primary_id: "D06CDN",
    names: ["AG-TMB711"],
  },
  {
    primary_id: "D06CDO",
    names: ["Chlorphentermine Hydrochloride"],
  },
  {
    primary_id: "D06CDP",
    names: ["MT-176 Lead"],
  },
  {
    primary_id: "D06CDW",
    names: ["PPAHV"],
  },
  {
    primary_id: "D06CEA",
    names: ["Purine Derivative 3"],
  },
  {
    primary_id: "D06CED",
    names: ["3'-(5-Chloro-2-Thienyl)Biphenyl-3-Ol", "CHEMBL1097014", "BDBM50318419"],
  },
  {
    primary_id: "D06CFJ",
    names: [
      "DC-480",
      "Dihydroorotate Dehydrogenase Inhibitors (Plasmodium Infection)",
      "LDS-OZ78",
      "Dihydroorotate Dehydrogenase Inhibitors (Plasmodium Infection), University Of Leeds",
    ],
  },
  {
    primary_id: "D06CFT",
    names: ["PP-4001"],
  },
  {
    primary_id: "D06CFZ",
    names: ["ARM-AK-105"],
  },
  {
    primary_id: "D06CGB",
    names: [
      "Desogestrel",
      "Cerazette",
      "Desogen",
      "Desogestrelum",
      "Organon Brand Of Desogestrel",
      "ORG 2969",
      "Desogestrelum [INN-Latin]",
      "Desogestrel (USAN/INN)",
      "Desogestrel [USAN:BAN:INN]",
      "Alpha-Pregn-4-En-20-Yn-17-Ol, 13-Ethyl-11-Methylene-18,19-Dinor-17",
      "(17alpha)-13-Ethyl-11-Methylene-18,19-Dinorpregn-4-En-20-Yn-17-Ol",
      "(8S,9S,10R,13S,14S,17R)-13-Ethyl-17-Ethynyl-11-Methylidene-1,2,3,6,7,8,9,10,12,14,15,16-Dodecahydrocyclopenta[a]phenanthren-17-Ol",
      "13 Ethyl 11 Methylene 18,19 Dinor 17 Alpha Pregn 4 En 20 Yn 17 Ol",
      "13-Ethyl-11-Methylene-18,19-Dinor-17 Alpha-Pregn-4-En-20-Yn-17-Ol",
      "13-Ethyl-11-Methylene-18,19-Dinor-17alpha-Pregn-4-En-20-Yn-17-Ol",
      "17alpha-Ethynyl-11-Methylidene-18a-Homo-Estr-4-En-17beta-Ol",
    ],
  },
  {
    primary_id: "D06CGD",
    names: [
      "Acetoacetyl-Coenzyme A",
      "Acetoacetyl-CoA",
      "Acetoacetyl Coenzyme A",
      "3-Acetoacetyl-CoA",
      "Acetoacetyl Coa",
      "S-Acetoacetyl-CoA",
      "S-Acetoacetylcoenzyme A",
      "1420-36-6",
      "S-Acetoacetyl-Coenzym A",
      "S-Acetoacetyl-Coenzyme A",
      "OJFDKHTZOUZBOS-CITAKDKDSA-N",
      "Acetoacetyl-S-CoA",
      "S-[2-[3-[[(2R)-4-[[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-4-Hydroxy-3-Phosphonooxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl]oxy-Hydroxyphosphoryl]oxy-2-Hydroxy-3,3-Dimethylbutanoyl]amino]propanoylamino]ethyl] 3-Oxobutanethioate",
      "S-Acetoacetyl Coenzyme A",
      "Bofuranosyl]-",
      "1dub",
    ],
  },
  {
    primary_id: "D06CGQ",
    names: ["PMID26004420-Compound-US20140031349B"],
  },
  {
    primary_id: "D06CGS",
    names: ["Mercuribenzoic Acid"],
  },
  {
    primary_id: "D06CHF",
    names: ["IKKbeta Inhibitors"],
  },
  {
    primary_id: "D06CHV",
    names: ["NSC-77833"],
  },
  {
    primary_id: "D06CIE",
    names: ["Amifostine"],
  },
  {
    primary_id: "D06CIH",
    names: [
      "VL-1499",
      "CHEMBL377157",
      "BDBM50185260",
      "(+/-)-2-(2-Cinnamamidobenzamido)-3-Phenylpropanoic Acid",
      "3-Phenyl-2(R,S)-[2-(Cinnamoylamino)Benzoylamino]propionic Acid",
    ],
  },
  {
    primary_id: "D06CIJ",
    names: [
      "ISIS-CRP",
      "1-(Tert-Butyl)-3-(4-Chlorophenyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "172889-27-9",
      "Pp2",
      "PP 2",
      "AG 1879",
      "1-Tert-Butyl-3-(4-Chlorophenyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "PP2",
      "4-Amino-5-(4-Chlorophenyl)-7-(T-Butyl)Pyrazolo[3,4-D]pyrimidine",
      "AG-1879",
      "CHEMBL406845",
      "CHEBI:78331",
      "AK-60369",
      "1-Tert-Butyl-3-(4-Chlorophenyl)Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "3-(4-Chlorophenyl) 1-(1,1-Dimethylethyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "C15H16ClN5",
      "1qpe",
      "4few",
      "PBBRWFOVCUAONR-UHFFFAOYSA-N",
      "AG1879",
      "PP 2 (AG 1879)",
    ],
  },
  {
    primary_id: "D06CIR",
    names: ["Thymosin Beta-4"],
  },
  {
    primary_id: "D06CJN",
    names: ["INCAGN1876"],
  },
  {
    primary_id: "D06CJP",
    names: [
      "4-Chloro-1-Guanidino-7-Isoquinolinesulphonamide",
      "223671-02-1",
      "1-GUANIDINO-4-CHLORO-7-SULFAMOYL-ISOQUINOLINE",
      "2-(4-Chloro-7-Sulfamoylisoquinolin-1-Yl)Guanidine",
      "CHEMBL227782",
      "SCHEMBL6437735",
      "CTK4E9305",
      "BDBM16132",
      "DTXSID90586545",
      "FXVHAOFNNRNCRJ-UHFFFAOYSA-N",
      "AKOS015966420",
      "1-Guanidino-7-Sulfonamidoisoquinoline 6",
      "ACM223671021",
      "4-Chloro-1-Guanidino-7-Sulphamoylisoquinoline",
    ],
  },
  {
    primary_id: "D06CKX",
    names: [
      "SB-649868",
      "380899-24-1",
      "UNII-1L1V1K2M4V",
      "SB 649868",
      "1L1V1K2M4V",
      "SB649868",
      "GTPL4461",
      "N-[[(2S)-1-[5-(4-Fluorophenyl)-2-Methyl-1,3-Thiazole-4-Carbonyl]piperidin-2-Yl]methyl]-1-Benzofuran-4-Carboxamide",
      "SCHEMBL8045969",
      "CHEMBL1272307",
      "DTXSID90191491",
      "EX-A2570",
      "BDBM50417257",
      "AKOS027323765",
      "CS-7584",
      "SB19110",
      "HY-10806",
      "SB-649,868",
      "N-((1-((5-(4-Fluorophenyl)-2-Methyl-4-Thiazolyl)Carbonyl)-2-Piperidinyl)Methyl)-4-Benzofurancarboxamide",
      "4-Benzofurancarboxamide, N-(((2S)-1-((5-(4-Fluorophenyl)-2-M",
    ],
  },
  {
    primary_id: "D06CMA",
    names: ["Oral Controlled-Release Therapy"],
  },
  {
    primary_id: "D06CMG",
    names: ["LDN-214117", "Compound 10 [PMID: 25101911]"],
  },
  {
    primary_id: "D06CMJ",
    names: ["Para-Chloroamphetamine"],
  },
  {
    primary_id: "D06CML",
    names: ["VLF-02"],
  },
  {
    primary_id: "D06CMW",
    names: ["MVA-BN Polytope HIV Vaccine"],
  },
  {
    primary_id: "D06CNP",
    names: ["Abiraterone Acetate", "CB-7630", "JNJ-212082", "Zytiga"],
  },
  {
    primary_id: "D06CPD",
    names: ["ETHYLGALLATE"],
  },
  {
    primary_id: "D06CPL",
    names: ["1-Phenyl-4-(1-Phenyl-1H-Tetrazol-5-Yl)Butan-2-One", "CHEMBL1083423"],
  },
  {
    primary_id: "D06CQQ",
    names: ["4-Hydroxy-4-Phenyl-But-2-Enoic Acid", "4-Hydroxy-4-Phenylbut-2-Enoic Acid", "99389-54-5", "CHEMBL435474"],
  },
  {
    primary_id: "D06CSI",
    names: [
      "Alpha,beta-Methylene-DTTP",
      "Alpha,beta-CH2-DTTP",
      "Alpha,beta-Methylenedeoxythymidine 5'-Triphosphate",
      "CHEMBL1162299",
      "24716-71-0",
      "Thymidine, 5'-O-(1,3,5,5-Tetrahydroxy-4-Oxa-1,3,5-Triphosphapent-1-Yl)-, P,P',P''-Trioxide",
    ],
  },
  {
    primary_id: "D06CSK",
    names: ["BCT-194"],
  },
  {
    primary_id: "D06CSR",
    names: ["INP-1750"],
  },
  {
    primary_id: "D06CTD",
    names: [
      "IN-N01",
      "ANTISENILIN",
      "Beta-Amyloid-Specific Humanized Monoclonal Antibodies (Alzheimer's Disease/Glaucoma/Age-Related Macular Degeneration/Traumatic Brain Injury), Intellect Neurosciences/IBL",
    ],
  },
  {
    primary_id: "D06CTE",
    names: [
      "Imiquimod",
      "Aldara",
      "Beselna",
      "Zartra",
      "Zyclara",
      "Imiquimod Acetate",
      "R 837",
      "S 26308",
      "Aldara (TN)",
      "Aldara, Imiquimod",
      "Beselna (TN)",
      "DZ-2636",
      "Imiquimod [USAN:INN]",
      "MTD-39",
      "R-837",
      "S-26308",
      "TMX-101",
      "Imiquimod (JAN/USAN/INN)",
      "1-(2-Methylpropyl)-1H-Imidazole[4,5-C]quinoline-4-Amine",
      "1-(2-Methylpropyl)-1H-Imidazo[4,5-C]quinolin-4-Amine",
      "1-(2-Methylpropyl)Imidazo[4,5-C]quinolin-4-Amine",
      "1-Isobutyl-1H-Imidazo(4,5-C)Quinolin-4-Amine",
      "1-Isobutyl-1H-Imidazo[4,5-C]quinolin-4-Amine",
      "3M Brand Of Imiquimod",
      "4-Amino-1-Isobutyl-1H-Imidazo(4,5-C)Quinoline",
      "4-Amino-1-Isobutyl-1H-Imidazo[4,5-C]quinoline",
    ],
  },
  {
    primary_id: "D06CTM",
    names: ["Ferumoxytol"],
  },
  {
    primary_id: "D06CTS",
    names: [
      "5-Chloro-4-Nitrothiophene-2-Sulfonamide",
      "61714-46-3",
      "2-Chloro-3-Nitrothiophene-5-Sulfonamide",
      "CHEMBL1170927",
      "2-Thiophenesulfonamide,5-Chloro-4-Nitro-",
      "2-Chloro-3-Nitrothiophene-5-Sulphonamide",
      "C4H3ClN2O4S2",
      "AC1MC43M",
      "SCHEMBL1185703",
      "AC1Q558E",
      "CTK5B3617",
      "DTXSID50370942",
      "CKMLFNQCVNTKHN-UHFFFAOYSA-N",
      "MolPort-000-153-347",
      "ZINC4253283",
      "9258AB",
      "SBB099627",
      "BDBM50321449",
      "AKOS016010801",
      "MCULE-8003873982",
      "5-Chloro-4-Nitro-2-Thiophenesulfonamide",
      "AJ-49707",
      "5-Chloro-4-Nitro-Thiophene-2-Sulfonamide",
      "DB-053977",
      "KB-229486",
      "FT-0640779",
    ],
  },
  {
    primary_id: "D06CUF",
    names: ["Hydrolyzed Cephalothin"],
  },
  {
    primary_id: "D06CVN",
    names: ["EC0489", "EC-0489"],
  },
  {
    primary_id: "D06CVO",
    names: ["OrM3"],
  },
  {
    primary_id: "D06CVT",
    names: ["Tianeptine"],
  },
  {
    primary_id: "D06CWH",
    names: ["Betamethasone Benzoate"],
  },
  {
    primary_id: "D06CWM",
    names: [
      "AZD-7507",
      "CSF-1R Inhibitors (Cancer)",
      "CSF-1R Inhibitors (Cancer), AstraZeneca",
      "Colony Stimulating Factor-1 Receptor Inhibitors (Cancer), AstraZeneca",
    ],
  },
  {
    primary_id: "D06CWR",
    names: ["4-(6-Methoxynaphthalen-2-Yl)Isoquinoline", "CHEMBL500394", "BDBM50272368"],
  },
  {
    primary_id: "D06CWV",
    names: ["W-205GBA2ED"],
  },
  {
    primary_id: "D06CWX",
    names: [
      "1-Hydroxy-8-Methoxy-10H-Anthracen-9-One",
      "CHEMBL125592",
      "9(10H)-Anthracenone, 1-Hydroxy-8-Methoxy-",
      "138591-01-2",
      "ACMC-20mxto",
      "1-Hydroxy-8-Methoxyanthrone",
      "SCHEMBL12775753",
      "CTK0B8005",
      "DTXSID10568303",
      "ZINC13366515",
      "BDBM50060886",
      "AKOS030561671",
    ],
  },
  {
    primary_id: "D06CWZ",
    names: ["CAR-CLD18 T Cell"],
  },
  {
    primary_id: "D06CXG",
    names: ["PD-135540"],
  },
  {
    primary_id: "D06CXU",
    names: ["PMID23642479C17"],
  },
  {
    primary_id: "D06CYG",
    names: ["Glu-Pro-Gln-PTyr-Glu-Glu-Ile-Pro-Ile-Tyr-Leu", "CHEMBL414123", "BDBM50289749", "147612-86-0"],
  },
  {
    primary_id: "D06CYQ",
    names: ["LP-661438", "Deoxycytidine Kinase Inhibitors (Cancer), Lexicon"],
  },
  {
    primary_id: "D06CZJ",
    names: ["Idursulfase", "Elaprase (TN)"],
  },
  {
    primary_id: "D06CZS",
    names: ["V85546"],
  },
  {
    primary_id: "D06DAY",
    names: ["8-Furan-2-Yl-2-Morpholin-4-Ylchromen-4-One", "CHEMBL369990"],
  },
  {
    primary_id: "D06DBA",
    names: ["SB-416"],
  },
  {
    primary_id: "D06DBJ",
    names: ["REGN-846"],
  },
  {
    primary_id: "D06DCK",
    names: ["L-746530"],
  },
  {
    primary_id: "D06DCL",
    names: ["LY335979"],
  },
  {
    primary_id: "D06DDA",
    names: ["6-Cinnamoylhernandine", "CHEMBL1097804"],
  },
  {
    primary_id: "D06DDT",
    names: [
      "BROUSSONIN A",
      "Broussonin A",
      "73731-87-0",
      "CHEMBL465879",
      "Biooussonin A",
      "AC1NST4K",
      "2-[3-(4-Hydroxyphenyl)Propyl]-5-Methoxyphenol",
      "SCHEMBL774802",
      "MSNVBURPCQDLEP-UHFFFAOYSA-N",
      "MolPort-019-937-120",
      "BDBM50251012",
      "ZINC13341109",
      "MCULE-7164788732",
    ],
  },
  {
    primary_id: "D06DEL",
    names: [
      "Calcitriol-26,23-Lactone",
      "(23S,25R)-1alpha,25-(OH)2D3-26,23-Lactone",
      "1,25-Dihydroxyvitamin D3-26,23-Lactone",
      "1,25-Lactone",
    ],
  },
  {
    primary_id: "D06DET",
    names: ["AE-3763"],
  },
  {
    primary_id: "D06DFU",
    names: ["TP-023"],
  },
  {
    primary_id: "D06DHB",
    names: ["E7016"],
  },
  {
    primary_id: "D06DIH",
    names: [
      "Furo[2,3d]Pyrimidine Antifolate",
      "Dpmmabg",
      "Furo[2,3d]pyrimidine Antifolate",
      "CHEMBL104829",
      "156595-85-6",
      "N-[4-[(2,4-DIAMINOFURO[2,3D]PYRIMIDIN-5-YL)METHYL]METHYLAMINO]-BENZOYL]-L-GLUTAMATE",
      "MOT",
      "MTXO",
      "AC1L4JTH",
      "CTK4C9124",
      "DTXSID50166105",
      "2-{4-[(2,4-Diamino-Furo[2,3-D]pyrimidin-5-Ylmethyl)-Methyl-Amino]-Benzoylamino}-Pentanedioic Acid",
      "BDBM50091145",
      "AKOS030607152",
      "DB02026",
      "N-(4-(((2,4-Diaminofuro(2,3-D)Pyrimidin-5-Yl)Methyl)Methylamino)Benzoyl)-L-Glutamic Acid",
      "N-(4-(N-((2,4-Diamino-Furo(2,3-D)Pyrimidin-5-Yl)Methyl)Methylamino)Benzoyl)Glutamic Acid",
    ],
  },
  {
    primary_id: "D06DKB",
    names: ["GNF-PF-4478"],
  },
  {
    primary_id: "D06DKD",
    names: ["QBI-139"],
  },
  {
    primary_id: "D06DLI",
    names: [
      "MCI-186",
      "Edarabone",
      "Edaravone",
      "Methylphenylpyrazolone",
      "Monopyrazolone",
      "Norantipyrine",
      "Norphenazone",
      "Phenylmethylpyrazolone",
      "Radicut",
      "Developer Z",
      "Edaravone [INN]",
      "Phenyl Methyl Pyrazolone",
      "CDS1_000986",
      "CI Developer 1",
      "IN1263",
      "M0687",
      "MCI 186",
      "Edaravone(Jan)",
      "Radicut (TN)",
      "AE-641/00371017",
      "C.I. Developer 1",
      "Edaravone (JAN/INN)",
      "(MCI-186)",
      "1-Fenyl-3-Methyl-2-Pyrazolin-5-On",
      "1-Fenyl-3-Methyl-2-Pyrazolin-5-On [Czech]",
      "1-Phenyl-3-Methyl-5-Oxo-2-Pyrazoline",
      "1-Phenyl-3-Methyl-5-Pyrazolone",
      "1-Phenyl-3-Methylpyrazolone",
      "1-Phenyl-3-Methylpyrazolone-5",
      "2,4-Dihydro-5-Methyl-2-Phenyl-3H-Pyrazol-3-One",
      "3-METHYL-1-PHENYL-2-PYRAZOLIN-5-ONE",
      "3-Methyl-1-Phenyl-2-Pyrazoline-5-One",
      "3-Methyl-1-Phenyl-5-Pyrazolone",
      "3-Methyl-1-Phenylpyrazol-5-One",
      "5-Methyl-2-Phenyl-2,4-Dihydro-3H-Pyrazol-3-One",
      "5-Methyl-2-Phenyl-4H-Pyrazol-3-One",
    ],
  },
  {
    primary_id: "D06DLN",
    names: ["SHP621"],
  },
  {
    primary_id: "D06DLO",
    names: ["Ro-24-7341"],
  },
  {
    primary_id: "D06DLQ",
    names: ["CGP-37849", "CGP-39551", "CGP-40116", "CGP-43487", "Ethyl Ester Of CGP-37849", "4-Methyl-APPA"],
  },
  {
    primary_id: "D06DMI",
    names: ["ISIS 119286"],
  },
  {
    primary_id: "D06DMQ",
    names: ["VX-970"],
  },
  {
    primary_id: "D06DMT",
    names: ["ISIS 25074"],
  },
  {
    primary_id: "D06DMV",
    names: ["ER-21018", "5-HT 4 Receptor Agonist (Sustained Release/Tablet, Gastrointestinal Disease), Eurapharm"],
  },
  {
    primary_id: "D06DMY",
    names: ["2-Methyl-3-Oxopropanoate"],
  },
  {
    primary_id: "D06DNH",
    names: ["TGX-221"],
  },
  {
    primary_id: "D06DNN",
    names: ["4-[1,2-Bis(4-Hydroxyphenyl)Hex-1-Enyl]phenol", "CHEMBL38222"],
  },
  {
    primary_id: "D06DOJ",
    names: ["Seribantumab"],
  },
  {
    primary_id: "D06DOY",
    names: [
      "Sphinganine 1-Phosphate",
      "(2S,3R)-2-Azaniumyl-3-Hydroxyoctadecyl Phosphate",
      "DHS-1-P",
      "Sphinganine 1-Phosphate(1-)",
      "GTPL6621",
      "CHEBI:57939",
      "(2S,3R)-2-Ammonio-3-Hydroxyoctadecyl Phosphate",
    ],
  },
  {
    primary_id: "D06DPJ",
    names: ["HITOPK-032"],
  },
  {
    primary_id: "D06DPQ",
    names: ["AIM-101", "AIM-101 (Neutropel, Asthma/COPD), AIM Therapeutics"],
  },
  {
    primary_id: "D06DPS",
    names: ["PD-170292"],
  },
  {
    primary_id: "D06DQF",
    names: ["RO-5095932", "Diabetes Therapy (Subcutaneous), Hoffmann-La Roche"],
  },
  {
    primary_id: "D06DRC",
    names: ["[Sar1,Tdf3]AngII", "CHEMBL1076604"],
  },
  {
    primary_id: "D06DRD",
    names: ["Chrysamine -G"],
  },
  {
    primary_id: "D06DRL",
    names: ["N-(3-Phenylamino-4-Pyridinyl)Methanesulfonamide"],
  },
  {
    primary_id: "D06DRS",
    names: ["Piperidine", "Cyclopentimine", "Cypentil", "Hexazane"],
  },
  {
    primary_id: "D06DRU",
    names: ["ELP-10"],
  },
  {
    primary_id: "D06DRY",
    names: ["FP0429"],
  },
  {
    primary_id: "D06DSE",
    names: [
      "PD-83176",
      "CHEMBL217423",
      "SCHEMBL8713501",
      "BDBM50059876",
      "Cbz-His-Tyr(OBn)-Ser(OBn)-Trp-DAla-NH2",
      "[(S)-1-[(S)-1-{(S)-2-Benzyloxy-1-[(S)-1-((R)-1-Carbamoyl-Ethylcarbamoyl)-2-(1H-Indol-3-Yl)-Ethylcarbamoyl]-Ethylcarbamoyl}-2-(4-Benzyloxy-Phenyl)-Ethylcarbamoyl]-2-(3H-Imidazol-4-Yl)-Ethyl]-Carbamic Acid Benzyl Ester",
      "[(S)-1-[(S)-1-{(S)-2-Benzyloxy-1-[(S)-1-((R)-1-Carbamoyl-Ethylcarbamoyl)-2-(1H-Indol-3-Yl)-Ethylcarbamoyl]-Ethylcarbamoyl}-2-(4-Benzyloxy-Phenyl)-Ethylcarbamoyl]-2-(1H-Imidazol-4-Yl)-Ethyl]-Carbamic Acid Be",
    ],
  },
  {
    primary_id: "D06DSF",
    names: ["5-(2-Oxoindolin-5-Yl)-1H-Pyrrole-2-Carbonitrile", "CHEMBL270909"],
  },
  {
    primary_id: "D06DSQ",
    names: ["DPIV/CD26"],
  },
  {
    primary_id: "D06DSX",
    names: ["ESCHOLTZINE"],
  },
  {
    primary_id: "D06DTC",
    names: [
      "(2S)-N-(7'-(2-CHLOROPHENYL)-6'-(4-CHLOROPHENYL)-3',4'-DIHYDROSPIRO[CYCLOHEXANE-1,2'-PYRANO[2,3-B]PYRIDINE]-4'-YL)-2-HYDROXYPROPANAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1084820",
      "SCHEMBL1404587",
      "BDBM50320186",
      "(2S)-N-(7''-(2-CHLOROPHENYL)-6''-(4-CHLOROPHENYL)-3'',4''-DIHYDROSPIRO[CYCLOHEXANE-1,2''-PYRANO[2,3-B]PYRIDINE]-4''-YL)-2-HYDROXYPROPANAMIDE (ENANTIOMERIC MIX)",
    ],
  },
  {
    primary_id: "D06DTQ",
    names: [
      "2-Amino-6-(3-Bromo-Benzenesulfinyl)-Benzonitrile",
      "CHEMBL55998",
      "6-Amino-2-[(3-Bromophenyl)Sulfinyl]benzenecarbonitrile",
      "AC1LAE2H",
      "BDBM1763",
      "CTK7C6587",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 2h",
      "2-Amino-6-(3-Bromophenyl)Sulfinylbenzonitrile",
      "2-Amino-6-(3-Bromophenyl)Sulfinyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D06DTT",
    names: [
      "RO-054520",
      "CHEMBL13095",
      "844-11-1",
      "5-(2-Fluorophenyl)-1,3-Dihydro-1-Methyl-2H-1,4-Benzodiazepin-2-One",
      "AC1L4W0W",
      "SCHEMBL11858895",
      "CTK5F2279",
      "DTXSID00233371",
      "ZINC3650317",
      "BDBM50019250",
      "AKOS030555310",
      "Ro-05-4520",
      "5-(2-Fluorophenyl)-1-Methyl-3H-1,4-Benzodiazepin-2-One",
      "1-Methyl-5-(2-Fluorophenyl)-1H-1,4-Benzodiazepin-2(3H)-One",
    ],
  },
  {
    primary_id: "D06DUE",
    names: ["[3H]quisqualate"],
  },
  {
    primary_id: "D06DUO",
    names: ["SSR-182289"],
  },
  {
    primary_id: "D06DUS",
    names: ["PVX-410"],
  },
  {
    primary_id: "D06DVJ",
    names: ["Sulfamic Acid 3-(3-Hydroxy-Benzoyl)-Phenyl Ester", "CHEMBL63397"],
  },
  {
    primary_id: "D06DVW",
    names: ["ISIS 156451"],
  },
  {
    primary_id: "D06DWA",
    names: ["(Sar)WTLNSAGYLLGPKK(Lys-MPEG4)K", "CHEMBL505299"],
  },
  {
    primary_id: "D06DWP",
    names: ["2'-Deoxyinosine"],
  },
  {
    primary_id: "D06DWQ",
    names: ["TOPIXANTRONE HYDROCHLORIDE"],
  },
  {
    primary_id: "D06DWW",
    names: ["FETAV"],
  },
  {
    primary_id: "D06DYN",
    names: ["FTY720-Phosphate"],
  },
  {
    primary_id: "D06DYP",
    names: [
      "8-Methoxy-2-Oxo-2H-Chromene-3-Carboxylic Acid",
      "2555-20-6",
      "8-Methoxy-2-Oxochromene-3-Carboxylic Acid",
      "8-Methoxycoumarin-3-Carboxylic Acid",
      "CHEMBL568348",
      "SFAPWVZFUHJZIC-UHFFFAOYSA-N",
      "2H-1-Benzopyran-3-Carboxylic Acid, 8-Methoxy-2-Oxo-",
      "NSC34647",
      "AC1L5SHQ",
      "AC1Q5T4B",
      "3-Carboxy-8-Methoxycoumarin",
      "Oprea1_510137",
      "Oprea1_497732",
      "8-Methoxy-3-Carboxy-Coumarin",
      "SCHEMBL980310",
      "Methoxyoxochromenecarboxylicacid",
      "SCHEMBL3659230",
      "ZINC84532",
      "KS-00001VYM",
      "CTK4F6014",
      "DTXSID30283980",
      "MolPort-000-145-720",
      "ALBB-000306",
      "NSC-34647",
      "STK411116",
      "BBL013903",
    ],
  },
  {
    primary_id: "D06DZI",
    names: [
      "GR-127935",
      "148672-13-3",
      "GR 127935",
      "N-(4-Methoxy-3-(4-Methylpiperazin-1-Yl)Phenyl)-2'-Methyl-4'-(5-Methyl-1,2,4-Oxadiazol-3-Yl)-[1,1'-Biphenyl]-4-Carboxamide",
      "GR127935",
      "UNII-2LLH6CEB40",
      "2'-Methyl-4'-(5-Methyl-(1,2,4)-Oxadiazol-3-Yl)Biphenyl-4-Carboxylic Acid (4-Methoxy-3-(4-Methylpiperazin-1-Yl)Phenyl)Amide",
      "2LLH6CEB40",
      "CHEMBL15928",
      "GR 127935 Hydrochloride Hydrate",
      "N-[4-METHOXY-3-(4-METHYLPIPERAZIN-1-YL)-PHENYL]-4-[2-METHYL-4-(5-METHYL-1,2,4-OXADIAZOL-3-YL)PHENYL]BENZAMIDE",
      "CHEBI:64114",
      "GR 127935 HCl",
      "C29H31N5O3",
    ],
  },
  {
    primary_id: "D06DZO",
    names: ["Dodecanoic Acid Adamantan-1-Ylamide"],
  },
  {
    primary_id: "D06DZZ",
    names: ["NVX-188", "Alpha-V/Beta-3 Intergrin Antagonist (Anticancer), Novelix"],
  },
  {
    primary_id: "D06EBJ",
    names: [
      "CX-02",
      "CX-05",
      "CXCR4 Monoclonal Antibodies (Cancer)",
      "CXCR4 Monoclonal Antibodies (Cancer), Northwest Biotherapeutics",
      "CXCR4 Therapeutics (Cancer), Northwest Biotherapeutics",
    ],
  },
  {
    primary_id: "D06EBU",
    names: ["ALTU-242"],
  },
  {
    primary_id: "D06ECI",
    names: ["TD-101"],
  },
  {
    primary_id: "D06ECM",
    names: ["HBN-2"],
  },
  {
    primary_id: "D06EDH",
    names: ["PMID27172114-Compound-47"],
  },
  {
    primary_id: "D06EDP",
    names: ["DSP-0565"],
  },
  {
    primary_id: "D06EDT",
    names: ["ZOPOLRESTAT"],
  },
  {
    primary_id: "D06EEJ",
    names: ["4-Carboxycinnamic Acid"],
  },
  {
    primary_id: "D06EEL",
    names: ["Miokamycin"],
  },
  {
    primary_id: "D06EEM",
    names: ["JD-3000"],
  },
  {
    primary_id: "D06EES",
    names: ["Leucine-Enkephalin"],
  },
  {
    primary_id: "D06EFW",
    names: ["RG7304"],
  },
  {
    primary_id: "D06EGC",
    names: ["Cancer Therapy, Carigent Therapeutics Inc", "Cancer Therapy (Nanoparticle, Controlled Release)"],
  },
  {
    primary_id: "D06EGM",
    names: ["PD-307243"],
  },
  {
    primary_id: "D06EGV",
    names: ["LY2801653"],
  },
  {
    primary_id: "D06EHE",
    names: [
      "6-Hydroxy-1,6-Dihydro Purine Nucleoside",
      "PRH",
      "AC1NRCV9",
      "DB03015",
      "(6S)-6-Hydroxy-9-Beta-D-Ribofuranosyl-6,9-Dihydro-1H-Purin-7-Ium",
    ],
  },
  {
    primary_id: "D06EHU",
    names: ["JWH-268"],
  },
  {
    primary_id: "D06EHW",
    names: [
      "IAI-100",
      "ADAM Inhibitors (Cancer)",
      "IAI-102",
      "INCB-3531",
      "INCB-3619",
      "A Disintegrin And Metalloprotease Inhibitors (Cancer), Incyte;ADAM Inhibitors (Cancer), Incyte",
      "HER-2 Sheddase Inhibitors (Cancer), Incyte",
    ],
  },
  {
    primary_id: "D06EHX",
    names: ["TAK-935"],
  },
  {
    primary_id: "D06EIC",
    names: ["ASP-015K"],
  },
  {
    primary_id: "D06EIJ",
    names: ["RFT-5.dgA"],
  },
  {
    primary_id: "D06EIM",
    names: [
      "IPN-200",
      "Dengue Fever Vaccine (ICV), Arbovax/ImmunoBiosciences",
      "Dengue Fever Vaccine (Live Attenuated), Arbovax/ImmunoBiosciences",
    ],
  },
  {
    primary_id: "D06EIO",
    names: ["Trimelamol"],
  },
  {
    primary_id: "D06EIP",
    names: ["PMID25399762-Compound-Table1-C9"],
  },
  {
    primary_id: "D06EIY",
    names: ["Lm-Malaria"],
  },
  {
    primary_id: "D06EJS",
    names: [
      "BIS(12)-HUPERZINE B",
      "Bis(12)-Huperzine B",
      "Bis-Huperzine B 5c",
      "Bis(12)-HupB",
      "CHEMBL175975",
      "BDBM10943",
    ],
  },
  {
    primary_id: "D06EJX",
    names: ["KNI-10538"],
  },
  {
    primary_id: "D06ELK",
    names: ["PMID25726713-Compound-32"],
  },
  {
    primary_id: "D06EMC",
    names: ["PF-18298"],
  },
  {
    primary_id: "D06EMM",
    names: ["CERC-301"],
  },
  {
    primary_id: "D06EMQ",
    names: [
      "AM-112",
      "AM-113",
      "AM-114",
      "AM-115",
      "AM-116",
      "AM-117",
      "AM-118",
      "AM-119",
      "AM-120",
      "AM-121",
      "AM-122",
      "Antibacterials, Amura",
      "Oxapenem Beta-Lactamase Inhibitors (Bacterial Infection)",
      "Oxapenem Beta-Lactamase Inhibitors (Bacterial Infection), Amura",
    ],
  },
  {
    primary_id: "D06EMT",
    names: [
      "Chlormerodrin",
      "Chlormerodrina",
      "Chloromeridin",
      "Neogidrin",
      "Chlormerodrin Hg 197",
      "Chlormerodrin Hg 203",
      "Chlormerodrin (197Hg)",
      "Chlormerodrin (203Hg)",
      "Chlormerodrin Hg 197 (TN)",
      "Chlormerodrin Hg 197 (USAN)",
      "Chlormerodrin Hg 197 [USAN:USP XX]",
      "Chlormerodrin Hg 203 (USAN)",
      "Hg-203 Chlormerodrin",
      "Loro-}",
      "Neohydrin-203",
      "Chlormerodrin (197Hg) (INN)",
      "Neohydrin-203 (TN)",
      "Mercury, [3-[(Aminocarbonyl)Amino]-2-Methoxypropyl]chloro",
      "Urea, (2-Methoxypropyl)-, Mercury Complex",
      "[3-(Carbamoylamino)-2-Methoxypropyl](Chloro)Mercury",
      "[3-(Carbamoylamino)-2-Methoxypropyl]-Chloromercury",
      "{[3-(Chloromercuri)-2-Methoxypropyl]urea}",
      "{[3-[(Aminocarbonyl)Amino]-2-Hydroxypropyl]chloromercury}",
      "[3-(Carbamoylamino)-2-Methoxypropyl](Chloro)(203hg)Mercury",
      "[3-(Carbamoylamino)-2-Methoxypropyl]-Chloromercury-203",
      "Chloro(2-Methoxy-3-Ureidopropyl)((203)Hg)Mercury(II)",
      "Mercury, {[3-[(Aminocarbonyl)Amino]-2-Methoxypropyl]chloro-}",
      "[3-(Carbamoylamino)-2-Methoxypropyl](Chloro)((203)Hg)Mercury",
      "Mercury, {[3-[(Aminocarbonyl)Amino]-2-Methoxypropyl-C1,O3]ch",
      "(3-(Chloromercuri)-2-Methoxypropyl)Urea",
      "3-Chloromercuri-2-Methoxypropylurea",
      "3-Ureido-2-Methoxypropyl((203)Hg)Mercury(II) Chloride",
    ],
  },
  {
    primary_id: "D06EMW",
    names: ["AZD8566"],
  },
  {
    primary_id: "D06ENH",
    names: ["Prostacyclin Analog"],
  },
  {
    primary_id: "D06ENM",
    names: ["TRV130"],
  },
  {
    primary_id: "D06ENR",
    names: [
      "HG-1096",
      "BR1 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "CXCL11 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Chemokine CXC Motif Ligand 11 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "H174 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "IP9 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "SCYB11 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "SCYB9B Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Small Inducible Cytokine B11 Precursor Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Small Inducible Cytokine Subfamily B Member 11 Modulator (Inflammation/Immune Disorders),Human Genome Sciences",
      "Small Inducible Cytokine Subfamily B Member 9B Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Chemokine Alpha-4 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "I-TAC Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Interferon-Gamma-Inducible Protein 9 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D06EPF",
    names: [
      "Plicamycin",
      "A-2371",
      "NSC24559",
      "PA-144",
      "MTM A",
      "AC1O3EQZ",
      "CHEMBL413720",
      "SCHEMBL14066059",
      "NSC-24559",
      "AKOS030213136",
      "EC-7071",
      "CCG-208236",
      "NCGC00160390-01",
      "NCI60_004287",
      "AB01273958-01",
    ],
  },
  {
    primary_id: "D06EPO",
    names: ["PF-4136309"],
  },
  {
    primary_id: "D06EPP",
    names: ["CNB-001", "Curcumin Derivative (Traumatic Brain Injury/Stroke), Salk Institute"],
  },
  {
    primary_id: "D06EPV",
    names: ["ISIS 9022"],
  },
  {
    primary_id: "D06EPZ",
    names: ["KKHT-20718"],
  },
  {
    primary_id: "D06EQN",
    names: [
      "XR-510",
      "CHEMBL100119",
      "SCHEMBL243027",
      "BDBM50032353",
      "N-{3-[5-Ethyl-3-(3-Fluoro-2''-Isopentyloxycarbonylsulfamoyl-Biphenyl-4-Ylmethyl)-2-Propyl-3H-Imidazol-4-Yl]-3-Oxo-Propyl}-N-Pyridin-3-Yl-Butyramide(XR510)",
      "Isopentyloxy 2-[2-(4-{4-Ethyl-5-[3-[3-Pyridyl(Propyl)Carboxamidopropanoyl]-2-Propyl-1H-1-Imidazolylmethyl}-3-Fluorophenyl)Phenylsulfonyl]caboxamido",
    ],
  },
  {
    primary_id: "D06EQP",
    names: [
      "Aceprometazine",
      "Aceprometazina",
      "Aceprometazinum",
      "Acepromethazine",
      "Aceprometazina [INN-Spanish]",
      "Aceprometazine [INN:DCF]",
      "Aceprometazinum [INN-Latin]",
      "Mepronizine (TN)",
      "KETONE, 10-(2-(DIMETHYLAMINO)PROPYL)PHENOTHIAZIN-2-YL METHYL",
      "Ethanone, 1-(10-(2-(Dimethylamino)Propyl)-10H-Phenothiazin-2-Yl)-(9CI)",
      "1-(10-(2-(Dimethylamino)Propyl)-10H-Phenothiazin-2-Yl)Ethanone",
      "1-[10-[2-(Dimethylamino)Propyl]phenothiazin-2-Yl]ethanone",
      "1-{10-[2-(Dimethylamino)Propyl]-10H-Phenothiazin-2-Yl}ethanone",
      "10-(2-(Dimethylamino)Propyl)Phenothiazin-2-Yl Methyl Ketone",
      "1664 CB",
    ],
  },
  {
    primary_id: "D06ERA",
    names: ["AcNPY(25-36)"],
  },
  {
    primary_id: "D06ERH",
    names: [
      "CHALCONE",
      "Chalcone",
      "(E)-Chalcone",
      "Trans-Chalcone",
      "Benzalacetophenone",
      "614-47-1",
      "94-41-7",
      "Benzylideneacetophenone",
      "Chalkone",
      "Phenyl Styryl Ketone",
      "Cinnamophenone",
      "2-Benzalacetophenone",
      "2-Benzylideneacetophenone",
      "1,3-DIPHENYL-2-PROPEN-1-ONE",
      "1,3-Diphenylpropenone",
      "(2E)-1,3-Diphenylprop-2-En-1-One",
      "Styryl Phenyl Ketone",
      "3-Phenylacrylophenone",
      "1-Benzoyl-2-Phenylethene",
      "Trans-Benzalacetophenone",
      "Beta-Benzoylstyrene",
      "Benzylidenecetophenone",
      "Phenyl Trans-Styryl Ketone",
      "2-Propen-1-One, 1,3-Diphenyl-",
      "1-Benzoyl-1-Phenylethene",
    ],
  },
  {
    primary_id: "D06ERY",
    names: ["BMS-394136"],
  },
  {
    primary_id: "D06ESC",
    names: ["YGGWL-NH2", "CHEMBL504973"],
  },
  {
    primary_id: "D06ESU",
    names: ["AM-461"],
  },
  {
    primary_id: "D06ETD",
    names: ["LUF-5437"],
  },
  {
    primary_id: "D06ETG",
    names: [
      "4-HYDROXY-N-PROPARGYL-1(R)-AMINOINDAN",
      "AC1NUV4X",
      "DB08480",
      "(1R)-1-(Prop-2-Enylideneamino)-2,3-Dihydro-1H-Inden-4-Ol",
      "(1R)-1-[(E)-Prop-2-En-1-Ylideneamino]-2,3-Dihydro-1H-Inden-4-Ol",
    ],
  },
  {
    primary_id: "D06ETI",
    names: ["ABT-263"],
  },
  {
    primary_id: "D06EUA",
    names: ["PALASONIN", "CHEMBL17096", "BDBM50110675"],
  },
  {
    primary_id: "D06EUC",
    names: [
      "PMX-10098",
      "Defensin-Mimetics (Fungal Infections), Polymedix",
      "Defensin-Mimetics (Fungal/Viral Infections), PolyMedix",
    ],
  },
  {
    primary_id: "D06EUJ",
    names: [
      "3-Butoxycarbonyl-4-Quinolone",
      "CHEMBL205456",
      "AKOS017354707",
      "4-Hydroxyquinoline-3-Carboxylic Acid Butyl Ester",
    ],
  },
  {
    primary_id: "D06EUM",
    names: [
      "Rac-2-Amino-5-Cyclohexylpentanoic Acid",
      "5-Cyclohexylnorvaline",
      "CHEMBL380671",
      "5428/12/6",
      "NSC12805",
      "AC1Q5S7K",
      "AC1L5D9O",
      "SCHEMBL5843561",
      "2-Amino-5-Cyclohexyl-Valeric Acid",
      "2-Amino-5-Cyclohexylpentanoic Acid",
      "NSC-12805",
      "BDBM50179718",
    ],
  },
  {
    primary_id: "D06EVC",
    names: [
      "Dual Pgp/MRP1 Inhibitors, Xenova",
      "Dual Pgp/MRP1 Inhibitors (Quinazolinones)",
      "Dual Pgp/MRP1 Inhibitors (Quinazolinones), Xenova",
      "Quinazolinones (Dual Multidrug Resistance Protein/P-Glycoprotein Inhibitors), Xenova",
    ],
  },
  {
    primary_id: "D06EVZ",
    names: ["Anti-GD2 CART"],
  },
  {
    primary_id: "D06EWG",
    names: ["Formic Acid"],
  },
  {
    primary_id: "D06EWO",
    names: ["Gemtuzumab Ozogamicin"],
  },
  {
    primary_id: "D06EWT",
    names: ["CLR1404-I-124"],
  },
  {
    primary_id: "D06EWW",
    names: ["Sacrovir"],
  },
  {
    primary_id: "D06EXA",
    names: ["SALVINORIN A"],
  },
  {
    primary_id: "D06EXH",
    names: ["(4S,5S)-4,5-Diethyl-Oxazolidin-(2Z)-Ylideneamine", "CHEMBL105820", "BDBM50138195", "AKOS006377258"],
  },
  {
    primary_id: "D06EXI",
    names: ["FGLL"],
  },
  {
    primary_id: "D06EYC",
    names: [
      "BI-671800",
      "UNII-721F767LHB",
      "CHEMBL551813",
      "721F767LHB",
      "Actimis",
      "2-(4,6-Bis(Dimethylamino)-2-(4-(4-(Trifluoromethyl)Benzamido)Benzyl)Pyrimidin-5-Yl)Acetic Acid",
      "BI 671800",
      "1093108-50-9",
      "SCHEMBL525024",
      "XEOSTBFUCNZKGS-UHFFFAOYSA-N",
      "BDBM50296978",
      "DB12524",
      "NCGC00345797-01",
      "NCGC00345797-02",
      "5-Pyrimidineacetic Acid, 4,6-Bis(Dimethylamino)-2-((4-((4-(Trifluoromethyl)Benzoyl)Amino)Phenyl)Methyl)-",
      "HY-114141",
      "CS-0070031",
      "[4,6-Bis-Dimethylamino-2-[4-(4-Trifluoromethyl-Benzoyl-Amino)Benzyl]pyrimidin-5-Yl]acetic Acid",
    ],
  },
  {
    primary_id: "D06EYQ",
    names: ["TDI-0094"],
  },
  {
    primary_id: "D06EZI",
    names: ["Capreomycin Sulfate", "Capastat Sulfate"],
  },
  {
    primary_id: "D06EZQ",
    names: ["GRANULATIMIDE"],
  },
  {
    primary_id: "D06FAA",
    names: [
      "Metelimumab",
      "CAT-192",
      "SL15A-IgG4",
      "Anti-TGF Beta 1 Antibodies, Genzyme General/Cambridge Antibody Technologies",
    ],
  },
  {
    primary_id: "D06FAK",
    names: [
      "6-(9H-Carbazol-9-Yl)-N-Hydroxyhexanamide",
      "CHEMBL1290142",
      "A1-02262",
      "SCHEMBL1004139",
      "SOMDVJCUFVPZKM-UHFFFAOYSA-N",
      "BDBM50331109",
      "9H-Carbazole-9-Hexanamide, N-Hydroxy-",
      "6-Carbazol-9-Ylhexanoic Acid Hydroxyamide",
      "US8748451, 1",
    ],
  },
  {
    primary_id: "D06FBB",
    names: ["Azithromycin/Chloroquine", "Chloroquine (TN)"],
  },
  {
    primary_id: "D06FCP",
    names: ["Gold Trichloride Sodium Chloride"],
  },
  {
    primary_id: "D06FCR",
    names: [
      "CL-277082",
      "UNII-KOF50RA8PQ",
      "KOF50RA8PQ",
      "CHEMBL277986",
      "3-(2,4-Difluorophenyl)-1-[4-(2,2-Dimethylpropyl)Benzyl]-1-Heptylurea",
      "Ddpmhu",
      "AC1Q5MV2",
      "AC1L2PE2",
      "SCHEMBL408973",
      "CTK8D5698",
      "BDBM50022279",
      "ZINC36330844",
      "CL 277082",
      "N'-(2,4-DIFLUOROPHENYL)-N-HEPTYL-N-(4-NEOPENTYLBENZYL)UREA",
      "Urea, N'-(2,4-Difluorophenyl)-N-((4-(2,2-Dimethylpropyl)Phenyl)Methyl)-N-Heptyl-",
      "3-(2,4-Difluorophenyl)-1-[[4-(2,2-Dimethylpropyl)Phenyl]methyl]-1-Heptylurea",
      "3-(2,4-Difluoro-Phenyl)-1-[4-(2,2-Dimethyl-Propyl)-Benzyl]-1-Heptyl-Urea",
    ],
  },
  {
    primary_id: "D06FCT",
    names: ["AZD-7806", "AZD 7806", "AZD7806"],
  },
  {
    primary_id: "D06FDR",
    names: [
      "Didanosine",
      "69655-05-6",
      "DIDEOXYINOSINE",
      "Videx",
      "Videx EC",
      "Inosine, 2',3'-Dideoxy-",
      "DdIno",
      "Didanosina",
      "Didanosinum",
      "DDI",
      "BMY-40900",
      "UNII-K3GDH6OH08",
      "K3GDH6OH08",
      "Didanosinum [INN-Latin]",
      "Didanosina [INN-Spanish]",
      "CHEBI:490877",
      "9-(2,3-Dideoxy-Beta-D-Ribofuranosyl)-6-Oxopurine",
      "NSC 612049",
      "9-[(2R,5S)-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl]-1,9-Dihydro-6H-Purin-6-One",
      "9-[(2R,5R)-5-(HYDROXYMETHYL)TETRAHYDROFURAN-2-YL]-1,9-DIHYDRO-6H-PURIN-6-ONE",
      "NCGC00159514-02",
      "NCGC00090691-03",
      "DRG-0016",
      "BMY 40900",
      "DSSTox_CID_2927",
    ],
  },
  {
    primary_id: "D06FEA",
    names: [
      "Dinoprostone",
      "Cervidil",
      "Dinoproston",
      "Dinoprostona",
      "Dinoprostonum",
      "Prepidil",
      "Propess",
      "Prostenon",
      "Prostin",
      "Prepidil Gel",
      "Prostarmon E;Prostin E",
      "Dinoprostone Prostaglandin E2",
      "Minprositin E2",
      "Minprostin E2",
      "PGE2",
      "PGE2 Alpha",
      "PGE2alpha",
      "Prostaglandin E2",
      "Prostaglandin E2 Alpha",
      "Prostaglandin E2alpha",
      "Prostarmon E2",
      "Prostin E2",
      "U 12062",
      "[3H]PGE2",
      "Alpha, PGE2",
      "Alpha, Prostaglandin E2",
      "BML1-F07",
      "Cervidil (TN)",
      "Dinoprostona [INN-Spanish]",
      "Dinoprostone Beta-Cyclodextrin Clathrate",
      "Dinoprostonum [INN-Latin]",
      "E2 Alpha, Prostaglandin",
      "E2, Prostaglandin",
      "E2alpha, Prostaglandin",
      "Gel, Prepidil",
      "L-PGE2",
      "L-Prostaglandin E2",
      "Prepidil (TN)",
      "Propess (TN)",
      "Prostin E2 (TN)",
      "U-12062",
      "BMS-279654 & PGE2",
      "Dinoprostone (JAN/USP/INN)",
      "Dinoprostone [USAN:INN:BAN:JAN]",
      "L-7-(3-Hydroxy-2-(3-Hydroxy-1-Octenyl)-5-Oxocyclopentyl)-5-Heptenoic Acid",
      "(15S)-Prostaglandin E2",
      "(5Z,11-Alpha,13E,15S)-11,15-Dihydroxy-9-Oxoprosta-5,13-Dien-1-Oic Acid",
      "(5Z,11.alpha.,13E,15S)-11,15-Dihydroxy-9-Oxoprosta-5,13-Dien-1-Oic Acid",
      "(5Z,11alpha,13E,15S)-11,15-Dihydroxy-9-Oxoprosta-5,13-Dien-1-Oic",
      "(5Z,11alpha,13E,15S)-11,15-Dihydroxy-9-Oxoprosta-5,13-Dienoic Acid",
      "(5Z,11alpha,13E,15S)-11,15-Dihydroxy-9-Oxoprosta-5,13-Dien-1-Oic Acid",
      "(5Z,13E)-(15S)-11alpha,15-Dihydroxy-9-Oxoprost-13-Enoate",
      "(5Z,13E)-(15S)-11alpha,15-Dihydroxy-9-Oxoprosta-5,13-Dienoate",
      "(5Z,13E,15S)-11alpha,15-Dihydroxy-9-Oxoprosta-5,13-Dien-1-Oic Acid",
      "(E,Z)-(1R,2R,3R)-7-(3-Hydroxy-2-((3S)-(3-Hydroxy-1-Octenyl))-5-Oxocyclopentyl)-5-Heptenoic Acid",
      "(Z)-7-[(1R,2R,3R)-3-Hydroxy-2-[(E,3S)-3-Hydroxyoct-1-Enyl]-5-Oxocyclopentyl]hept-5-Enoic Acid",
      "5-Heptenoic Acid, 7-(3-Hydroxy-2-(3-Hydroxy-1-Octenyl)-5-Oxocyclopentyl)-(8CI)",
      "7-(3-Hydroxy-2-(3-Hydroxy-1-Octenyl)-5-Oxocyclopentyl)-5-Heptenoic Acid",
    ],
  },
  {
    primary_id: "D06FEF",
    names: [
      "Fedotozine",
      "AC1L1TVH",
      "SCHEMBL1649900",
      "AC1Q56G3",
      "(2s)-N,n-Dimethyl-2-Phenyl-1-[(3,4,5-Trimethoxybenzyl)Oxy]butan-2-Amine",
    ],
  },
  {
    primary_id: "D06FEQ",
    names: ["WIT-301"],
  },
  {
    primary_id: "D06FER",
    names: [
      "BMX-010",
      "Bmx-010",
      "UNII-BN93L2NT5I",
      "BN93L2NT5I",
      "Manganese(III)Meso-Tetrakis(N-Ethylpyridinium-2-Yl) Porphyrin Chloride",
      "Manganese(4+), Chloro((2,2',2'',2'''-(21H,23H-Porphine-5,10,15,20-Tetrayl-KappaN21,kappaN22,kappaN23,kappaN24)Tetrakis(1-Ethylpyridiniumato))(2-))-, Tetrachloride, (Sp-5-12)-",
      "219818-60-7",
    ],
  },
  {
    primary_id: "D06FES",
    names: ["Doxepin"],
  },
  {
    primary_id: "D06FFD",
    names: [
      "1,2-Bis-(4-Bromo-Phenyl)-Ethane-1,2-Dione",
      "4,4'-Dibromobenzil",
      "35578-47-3",
      "Ethanedione, Bis(4-Bromophenyl)-",
      "P,p'-Dibromobenzil",
      "1,2-Bis(4-Bromophenyl)Ethane-1,2-Dione",
      "Benzyl, 4,4'-Dibromo-",
      "UNII-O4TXV8A656",
      "Bis(4-Bromophenyl)Ethanedione",
      "4,4'-Dibromodibenzoyl",
      "O4TXV8A656",
      "1,2-Ethanedione, 1,2-Bis(4-Bromophenyl)-",
      "NYCBYBDDECLFPE-UHFFFAOYSA-N",
      "MFCD00000104",
      "W-110862",
      "NSC74075",
      "EINECS 252-628-1",
      "NSC 74075",
      "Benzyl,4'-Dibromo-",
      "4,4'-Dibromo-Benzil",
      "ACMC-209ihu",
      "AI3-25040",
      "AC1L3WFW",
      "Benzil-Based Compound, 11",
      "NCIOpen2_008898",
    ],
  },
  {
    primary_id: "D06FFP",
    names: ["MG-101", "110044-82-1"],
  },
  {
    primary_id: "D06FFZ",
    names: ["PMID26080733C7r"],
  },
  {
    primary_id: "D06FGE",
    names: ["Erythropoiesis Stimulants"],
  },
  {
    primary_id: "D06FGJ",
    names: ["Protexia"],
  },
  {
    primary_id: "D06FGM",
    names: [
      "Snake Venom Antisera",
      "ASV Project (Echis Coloratus/Vipera Palestinae), Kamada",
      "Anti Snake Venom Project (Echis Coloratus/Vipera Palestinae), Kamada",
      "Snake Venom Antisera (Horse Polyclonal Antibodies, Echis Coloratus/Vipera Palestinae Venom)",
      "Snake Venom Antisera (Horse Polyclonal Antibodies, Echis Coloratus/Vipera Palestinae Venom), Kamada",
    ],
  },
  {
    primary_id: "D06FHD",
    names: ["AKT Inhibitors [PMCID:PMC3086120]", "AKT Inhibitors (Cancer)", "AKT Inhibitors (Cancer), Pfizer"],
  },
  {
    primary_id: "D06FHE",
    names: ["1-Ethyl-5-(Imidazol-1-Yl-Phenyl-Methyl)-1H-Indole", "CHEMBL112074"],
  },
  {
    primary_id: "D06FHJ",
    names: ["Sulfonamide Derivative 8"],
  },
  {
    primary_id: "D06FHQ",
    names: ["BMS-936561"],
  },
  {
    primary_id: "D06FHW",
    names: [
      "Aminomethylcyclohexane",
      "Cyclohexanemethylamine",
      "Cyclohexylmethanamine",
      "3218/2/8",
      "(Aminomethyl)Cyclohexane",
      "Cyclohexanemethanamine",
      "1-Cyclohexylmethanamine",
      "Hexahydrobenzylamine",
      "1-Cyclohexylmethylamine",
      "4-Aminomethylcyclohexane",
      "C-Cyclohexyl-Methylamine",
      "CHEMBL1049",
      "DECARBOXYLATED DERIVATIVE",
      "AVKNGPAMCBSNSO-UHFFFAOYSA-N",
      "EINECS 221-741-8",
      "NSC 172991",
      "BRN 0635751",
      "1tng",
      "Cyclohexanemethyamine",
      "Cyclohexylmethyl Amine",
      "Cyclohexanemethylamirie",
      "CyclohexYl-Methanamine",
      "Cyclohexanemethyl Amine",
      "Cyclohexane Methylamine",
    ],
  },
  {
    primary_id: "D06FIF",
    names: [
      "CP-376395",
      "CP 376395",
      "175140-00-8",
      "UNII-5113G7FP34",
      "3,6-Dimethyl-N-(Pentan-3-Yl)-2-(2,4,6-Trimethylphenoxy)Pyridin-4-Amine",
      "5113G7FP34",
      "VIZBSVDBNLAVAW-UHFFFAOYSA-N",
      "1Q5",
      "CP376395",
      "4k5y",
      "SCHEMBL123421",
      "GTPL3496",
      "CHEMBL270948",
      "DTXSID0047298",
      "BDBM20966",
      "MolPort-009-194-134",
      "KS-00002WT7",
      "2-Aryloxy-4-Alkylaminopyridine, 3a",
      "ZINC14975027",
      "AKOS015994585",
      "4-Pyridinamine, N-(1-Ethylpropyl)-3,6-Dimethyl-2-(2,4,6-Trimethylphenoxy)-",
      "GD-0065",
      "CS-7833",
      "API0007847",
      "Compound 3a [PMID: 18288792]",
      "HY-14130",
      "KB-76174",
      "CP-376,395",
      "CP 376,395",
    ],
  },
  {
    primary_id: "D06FIH",
    names: ["Azaindazole Derivative 1"],
  },
  {
    primary_id: "D06FIK",
    names: ["JNJ-17156516"],
  },
  {
    primary_id: "D06FIQ",
    names: [
      "6-CYCLOHEXYLMETHOXY-2-(3'-CHLOROANILINO) PURINE",
      "N-(3-Chlorophenyl)-6-(Cyclohexylmethoxy)-7H-Purin-2-Amine",
      "444722-81-0",
      "1h1r",
      "AC1L1IG7",
      "CHEMBL341273",
      "SCHEMBL6790651",
      "BDBM5531",
      "CTK1D2420",
      "DTXSID10274437",
      "O6-Cyclohexylmethylguanine Deriv. 15",
      "DB07203",
      "2-(3 -Chloroanilino)-6-Cyclohexylmethoxypurine",
      "N-(3-Chlorophenyl)-6-(Cyclohexylmethoxy)-9H-Purin-2-Amine",
      "N-(3-Chlorophenyl)-6-(Cyclohexylmethoxy)-9H-Purine-2-Amine",
      "1H-Purin-2-Amine, N-(3-Chlorophenyl)-6-(Cyclohexylmethoxy)-",
    ],
  },
  {
    primary_id: "D06FIT",
    names: ["SB-728-T"],
  },
  {
    primary_id: "D06FJE",
    names: ["ISIS 32003"],
  },
  {
    primary_id: "D06FJL",
    names: ["Oxazolo[3,4-A]pyrazine Derivative 2"],
  },
  {
    primary_id: "D06FJO",
    names: [
      "Benzydamine",
      "Bencidamina",
      "Benzidamina",
      "Benzidamine",
      "Benzindamine",
      "Benzydaminum",
      "Benzidamina [DCIT]",
      "Benzydamine Monohydrochoride",
      "C 1523",
      "Apo-Benzydamine",
      "Bencidamina [INN-Spanish]",
      "Benzydamine (INN)",
      "Benzydamine [INN:BAN]",
      "Benzydaminum [INN-Latin]",
      "Apo-Benzydamine (TN)",
      "{3-[(1-Benzyl-1H-Indazol-3-Yl)Oxy]propyl}dimethylamine",
      "1-Benzyl-3-(3-(Dimethylamino)Propoxy)-1H-Indazole",
      "3-(1-Benzylindazol-3-Yl)Oxy-N,N-Dimethylpropan-1-Amine",
      "3-[(1-Benzyl-1H-Indazol-3-Yl)Oxy]-N,N-Dimethylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D06FKC",
    names: ["Eltoprazine"],
  },
  {
    primary_id: "D06FLB",
    names: ["PMID26004420-Compound-WO2014150132C"],
  },
  {
    primary_id: "D06FLP",
    names: ["AMG 811"],
  },
  {
    primary_id: "D06FLU",
    names: ["3,8-Dibromoboldine", "CHEMBL388564"],
  },
  {
    primary_id: "D06FMH",
    names: [
      "2'-Deoxycytidine-5'-Monophosphate",
      "1032-65-1",
      "DCMP",
      "Deoxycytidylic Acid",
      "2'-Deoxycytidine-5'-Monophosphoric Acid",
      "Deoxycytidine Monophosphate",
      "2'-DEOXYCYTIDINE-5'-MONOPHOSPHATE",
      "5'-Cytidylic Acid, 2'-Deoxy-",
      "2'-Deoxycytidine 5'-Monophosphate",
      "Deoxycytidine-5'-Monophosphoric Acid",
      "Deoxycytidylate",
      "Polydeoxycytidylic Acid",
      "UNII-W7A9174XQL",
      "2'-Deoxy-5'-Cytidylic Acid",
      "W7A9174XQL",
      "CHEBI:15918",
      "NCMVOABPESMRCP-SHYZEUOFSA-N",
      "Deoxycytidine-Phosphate",
      "2'-DEOXYCYTIDINE-5'-MONOPHOSPHATE FREE ACID",
    ],
  },
  {
    primary_id: "D06FMO",
    names: ["ABP 501"],
  },
  {
    primary_id: "D06FMY",
    names: ["CGP-70726"],
  },
  {
    primary_id: "D06FNA",
    names: ["Isoindoline Derivative 3"],
  },
  {
    primary_id: "D06FNK",
    names: ["Z-Pro-Prolinal"],
  },
  {
    primary_id: "D06FNT",
    names: [
      "VUF-8504",
      "CHEMBL130499",
      "GTPL475",
      "4-Methoxy-N-(2-Pyridin-2-Ylquinazolin-4-Yl)Benzamide",
      "VUF8504",
      "VUF 8504",
      "BDBM50067117",
      "4-Methoxy-N-[2-(2-Pyridinyl)Quinazoline-4-Yl]benzamide",
    ],
  },
  {
    primary_id: "D06FOA",
    names: [
      "Bone Morphogenetic Protein-2",
      "Bone Morphogenetic Protein-2 (Injection, Fracture)",
      "BMP-2 (Injection, Fracture), Affinergy",
      "Bone Morphogenetic Protein-2 (Injection, Fracture), Affinergy",
    ],
  },
  {
    primary_id: "D06FOF",
    names: ["Imidazole Derivative 5"],
  },
  {
    primary_id: "D06FOL",
    names: ["CALDARET HYDRATE"],
  },
  {
    primary_id: "D06FOU",
    names: ["Trifarotene"],
  },
  {
    primary_id: "D06FPF",
    names: [
      "Valortim",
      "Anthrax Antibody, Medarex",
      "MDX-1303",
      "Anthrax Antibody, Medarex/PharmAthene",
      "Anti-B Anthracis, Bristol Myers Squibb/PharmAthene",
      "Anti-B Anthracis PA (Anthrax), Medarex/BMS/PharmAthene",
    ],
  },
  {
    primary_id: "D06FPP",
    names: ["16-(Pyridin-4-Yl)Methyl-Estradiol"],
  },
  {
    primary_id: "D06FPQ",
    names: [
      "Frovatriptan",
      "Allegro",
      "Auradol",
      "Frova",
      "Frovelan",
      "Migard",
      "Miguard",
      "Rilamig",
      "Allergo Filmtabletten",
      "SB 209509",
      "Allergo Filmtabletten(TN)",
      "Frova (TN)",
      "Frovatriptan (INN)",
      "SB-209509",
      "(3R)-3-(Methylamino)-2,3,4,9-Tetrahydro-1H-Carbazole-6-Carboxamide",
      "(6R)-6-(Methylamino)-6,7,8,9-Tetrahydro-5H-Carbazole-3-Carboxamide",
      "(R)-5,6,7,8-Tetrahydro-6-(Methylamino)Carbazole-3-Carboxamide",
    ],
  },
  {
    primary_id: "D06FQS",
    names: ["TGOF02N"],
  },
  {
    primary_id: "D06FQU",
    names: ["INCB53914"],
  },
  {
    primary_id: "D06FRB",
    names: ["ACH-0137171"],
  },
  {
    primary_id: "D06FRE",
    names: ["PMID1656041C11dd"],
  },
  {
    primary_id: "D06FRZ",
    names: ["15-ACETOXY-EREMANTHOLIDE B", "15-Acetoxy-Eremantholide B"],
  },
  {
    primary_id: "D06FSC",
    names: [
      "NP-118809",
      "41332-24-5",
      "NP118809",
      "Z-160",
      "1-[4-(Diphenylmethyl)-1-Piperazinyl]-3,3-Diphenyl-1-Propanone",
      "UNII-TX3R141LEP",
      "TX3R141LEP",
      "CHEMBL604710",
      "Piperazine, 1-(Diphenylmethyl)-4-(1-Oxo-3,3-Diphenylpropyl)-",
      "1-(4-Benzhydrylpiperazin-1-Yl)-3,3-Diphenylpropan-1-One",
      "AC1NLRM8",
      "GTPL7765",
      "SCHEMBL4180792",
      "CTK1D3910",
      "DTXSID70407284",
      "VCPMZDWBEWTGNW-UHFFFAOYSA-N",
      "MolPort-001-545-055",
      "EX-A2422",
      "BCP08259",
      "KS-00000T6R",
      "ZINC20509316",
      "3934AH",
      "STK411255",
      "AKOS003297641",
      "MK-6721",
      "DB12743",
      "MCULE-3294187169",
      "CS-1361",
      "API0014038",
      "ACM41332245",
    ],
  },
  {
    primary_id: "D06FSD",
    names: ["NN-9161"],
  },
  {
    primary_id: "D06FSR",
    names: [
      "1-Methyl-1H-Indole-3-Carbonitrile",
      "24662-37-1",
      "1-Methylindole-3-Carbonitrile",
      "3-Cyano-1-Methylindole",
      "1H-Indole-3-Carbonitrile, 1-Methyl-",
      "1H-Indole-3-Carbonitrile,1-Methyl-",
      "CHEMBL205035",
      "F9995-1129",
      "AC1M4H5X",
      "SCHEMBL1778037",
      "3-Cyano(1-Methyl-1H-Indole)",
      "CTK4F4137",
      "FBAXZPMXGBNBPE-UHFFFAOYSA-",
      "DTXSID30367996",
      "MolPort-000-002-609",
      "FBAXZPMXGBNBPE-UHFFFAOYSA-N",
      "HMS1753J21",
      "ZINC3165608",
      "BDBM50182877",
      "STK386450",
      "MFCD00466602",
      "AKOS001015339",
      "MCULE-7170650231",
      "AS-9400",
      "AJ-44684",
      "KB-12784",
      "DA-07730",
      "ST50875554",
      "AM20040004",
      "FT-0719960",
    ],
  },
  {
    primary_id: "D06FSV",
    names: ["Onco-D", "Cancer Therapeutic, Onco-D Therapeutics"],
  },
  {
    primary_id: "D06FTI",
    names: [
      "2-(1,1'-Biphenyl-4-Yl)Propanoic Acid",
      "Alpha-Methyl-4-Biphenylacetic Acid",
      "6341-72-6",
      "BPPA",
      "NSC 16300",
      "Ly 71130",
      "2-(P-Biphenyl)Propionic Acid",
      "2-(4-Phenylphenyl)Propanoic Acid",
      "4-BIPHENYLACETIC ACID, Alpha-METHYL-",
      "CHEMBL317434",
      "2-Biphenyl-4-Yl-Propionic Acid",
      "Biprofen",
      "2-(4-Biphenylyl)Propionic Acid",
      "(1,1'-Biphenyl)-4-Acetic Acid, Alpha-Methyl-",
      "2-[1,1'-Biphenyl]-4-Ylpropanoic Acid",
      "[1,1'-Biphenyl]-4-Aceticacid, A-Methyl-",
      "[1,1'-Biphenyl]-4-Acetic Acid, Alpha-Methyl-",
      "Alpha-Methyl-4-Biphenyl-Acetic Acid",
      "P-Phenylhydratropic Acid",
      "2-(4-Biphenyl)Propionic Acid,",
    ],
  },
  {
    primary_id: "D06FTK",
    names: ["Cyclo(1,12)PenIYDTKGKNVLC-OH"],
  },
  {
    primary_id: "D06FTM",
    names: ["RG7221"],
  },
  {
    primary_id: "D06FTW",
    names: ["RP135"],
  },
  {
    primary_id: "D06FTY",
    names: ["CT-400", "Angiogenesis Inhibitor (Cancer), CisThera", "CT-400, CisThera"],
  },
  {
    primary_id: "D06FUE",
    names: ["CDX-3379"],
  },
  {
    primary_id: "D06FUK",
    names: ["Imidazo Pyridine Derivative 6"],
  },
  {
    primary_id: "D06FUM",
    names: ["PMID26815044-Compound-114"],
  },
  {
    primary_id: "D06FUQ",
    names: ["MIQ-003"],
  },
  {
    primary_id: "D06FUV",
    names: ["Preclamol"],
  },
  {
    primary_id: "D06FVQ",
    names: ["Bicarboxylic And Tricarboxylic Ethynyl Derivative 3"],
  },
  {
    primary_id: "D06FVX",
    names: ["Nedocromil Sodium"],
  },
  {
    primary_id: "D06FWC",
    names: ["Fludiazepam"],
  },
  {
    primary_id: "D06FWG",
    names: ["Oncomer"],
  },
  {
    primary_id: "D06FWM",
    names: ["N-(3-(1H-Indol-4-Yloxy)Propyl)Cyclohexanamine", "CHEMBL237790"],
  },
  {
    primary_id: "D06FWY",
    names: ["KYS-05070"],
  },
  {
    primary_id: "D06FXI",
    names: [
      "5-Methoxymethyl-3-Pyrrol-1-Yl-Oxazolidin-2-One",
      "CHEMBL18334",
      "BDBM50110719",
      "2-Oxazolidinone, 5-(Methoxymethyl)-3-(1H-Pyrrol-1-Yl)-, (5R)-",
      "406934-10-9",
    ],
  },
  {
    primary_id: "D06FXO",
    names: [
      "3-(Phenylsulfonyl)-1-(Piperidin-3-Yl)-1H-Indole",
      "CHEMBL1076583",
      "651335-52-3",
      "SCHEMBL4077273",
      "CTK1J9031",
      "DTXSID60434701",
      "BDBM50313130",
      "1H-Indole, 3-(Phenylsulfonyl)-1-(3-Piperidinyl)-",
    ],
  },
  {
    primary_id: "D06FYE",
    names: ["Atorvastatin + Aspirin + Ramipril"],
  },
  {
    primary_id: "D06FYY",
    names: ["(+/-)-Threo-3-Fluororitalinol", "CHEMBL1253340"],
  },
  {
    primary_id: "D06FZH",
    names: ["MazF Gene Therapy, HIV"],
  },
  {
    primary_id: "D06FZS",
    names: ["L-Lactic Acid"],
  },
  {
    primary_id: "D06FZX",
    names: [
      "Antazoline",
      "Analergine",
      "Antastan",
      "Antasten",
      "Antazolin",
      "Antazolina",
      "Antazolinum",
      "Antihistal",
      "Antistin",
      "Antistine",
      "Azalone",
      "Histostab;Imidamin",
      "Imidamine",
      "Phenazolin",
      "Phenazoline",
      "Antazolina [Spanish]",
      "Antazolinum [Latin]",
      "Antazolina [INN-Spanish]",
      "Antazoline (INN)",
      "Antazoline [INN:BAN]",
      "Antazolinum [INN-Latin]",
      "Ben-A-Hist",
      "N-Benzyl-N-(4,5-Dihydro-1H-Imidazol-2-Ylmethyl)Aniline",
      "N-(4,5-Dihydro-1H-Imidazol-2-Ylmethyl)-N-(Phenylmethyl)Aniline",
      "2-(N-Benzylanilinomethyl)-2-Imidazoline",
      "2-(N-Phenyl-N-Benzylaminomethyl)Imidazoline",
      "2-Phenyl-Benzyl-Amino-Methylimidazolin",
      "2-Phenyl-Benzyl-Amino-Methylimidazolin [German]",
      "4,5-Dihydro-N-Phenyl-N-Phenylmethyl-1H-Imidazole-2-Methanamine",
      "5512-M",
    ],
  },
  {
    primary_id: "D06GAF",
    names: [
      "N1-(4-Bromophenyl)-N2,N2-Dipentylphthalamide",
      "CHEMBL523749",
      "N'-(4-Bromophenyl)-N,N-Dipentylbenzene-1,2-Dicarboxamide",
      "AC1MDHAQ",
      "MolPort-002-139-379",
      "ZINC9510931",
      "BDBM50265685",
      "STK055903",
      "AKOS005386817",
      "MCULE-4023922974",
    ],
  },
  {
    primary_id: "D06GAI",
    names: ["1-(4-Chlorophenyl)-4-Phenyl-1H-Imidazole"],
  },
  {
    primary_id: "D06GAM",
    names: ["Fleroxacin"],
  },
  {
    primary_id: "D06GAP",
    names: ["SAR252067"],
  },
  {
    primary_id: "D06GAQ",
    names: ["Atu-111"],
  },
  {
    primary_id: "D06GBK",
    names: [
      "AG1295",
      "6,7-Dimethyl-2-Phenylquinoxaline",
      "71897-07-9",
      "Ag1295",
      "AG-1295",
      "AG 1295",
      "CHEMBL7724",
      "TYRPHOSTIN AG 1295",
      "BiomolKI_000019",
      "Tyrphostin AG-1295",
      "AC1Q4WCY",
      "AC1L1CSX",
      "ChemDiv2_000256",
      "BiomolKI2_000029",
      "AC1Q2O4Y",
      "Cid_2048",
      "BMK1-C7",
      "MLS001194710",
      "SCHEMBL925674",
      "IFLab1_005463",
      "GTPL5914",
      "AC1Q2E07",
      "CHEBI:94668",
      "BDBM33408",
      "CTK2H8610",
      "DTXSID10274351",
      "MolPort-000-182-258",
      "FQNCLVJEQCJWSU-UHFFFAOYSA-N",
      "2-Phenyl-6,7-Dimethylquinoxaline",
      "HMS1427I07",
      "HMS3229M19",
      "HMS2879L07",
      "HMS1369L14",
      "ZINC257983",
      "HMS3428I13",
      "API01003",
      "6,7-Dimethyl-2-Phenyl-Quinoxaline",
    ],
  },
  {
    primary_id: "D06GBQ",
    names: ["Enfuvirtide"],
  },
  {
    primary_id: "D06GCK",
    names: [
      "Eupatilin",
      "22368-21-4",
      "Euptailin",
      "CHEBI:4932",
      "5,7-Dihydroxy-3',4',6-Trimethoxyflavone",
      "UNII-4D58O05490",
      "CHEMBL312750",
      "DRRWBCNQOKKKOL-UHFFFAOYSA-N",
      "2-(3,4-Dimethoxyphenyl)-5,7-Dihydroxy-6-Methoxychromen-4-One",
      "4D58O05490",
      "2-(3,4-Dimethoxyphenyl)-5,7-Dihydroxy-6-Methoxy-Chromen-4-One",
      "2-(3,4-Dimethoxyphenyl)-5,7-Dihydroxy-6-Methoxy-4H-Chromen-4-One",
      "NSC 122413",
      "AC1NRVK5",
      "SCHEMBL1033509",
      "Eupatilin, &gt",
      "DTXSID30176904",
      "EBD1182",
      "MolPort-008-155-866",
      "ZINC6018691",
      "HY-N0783",
      "BCP09703",
      "NSC122413",
      "BDBM50060926",
      "LMPK12111228",
    ],
  },
  {
    primary_id: "D06GCO",
    names: ["Gonadoreline-6-D-Trp Acetate"],
  },
  {
    primary_id: "D06GCP",
    names: ["PSD-506"],
  },
  {
    primary_id: "D06GDH",
    names: [
      "DA-5018",
      "Capsavanil",
      "Davasaicin",
      "Da 5018",
      "KR-25018",
      "Benzeneacetamide, 4-(2-Aminoethoxy)-N-(3-(3,4-Dimethylphenyl)Propyl)-3-Methoxy-, Monohydrochloride",
      "2-[4-(2-Aminoethoxy)-3-Methoxyphenyl]-N-[3-(3,4-Dimethylphenyl)Propyl]acetamide",
      "2-[4-(2-Aminoethoxy)-3-Methoxyphenyl]-N-[3-(3,4-Dimethylphenyl)Propyl]acetamide Hydrochloride",
    ],
  },
  {
    primary_id: "D06GDM",
    names: ["Peptide Analog 16"],
  },
  {
    primary_id: "D06GDR",
    names: ["VAP-1 Monoclonal Antibody"],
  },
  {
    primary_id: "D06GDY",
    names: ["Paroxetine"],
  },
  {
    primary_id: "D06GFG",
    names: ["KD-3020"],
  },
  {
    primary_id: "D06GFH",
    names: ["VPC44116"],
  },
  {
    primary_id: "D06GFV",
    names: [
      "1-(2,3-Dihydro-1H-Indol-5-Ylmethyl)-Propylamine",
      "CHEMBL31171",
      "1H-Indole-5-Ethanamine, Alpha-Ethyl-2,3-Dihydro-",
      "BDBM50025077",
    ],
  },
  {
    primary_id: "D06GFZ",
    names: ["BIIE0246", "BIIE 0246", "BIIE-0246"],
  },
  {
    primary_id: "D06GGB",
    names: ["N-Ethyl-2-Phenylnorapomorphine Hydrochloride", "CHEMBL564909"],
  },
  {
    primary_id: "D06GGI",
    names: ["Tricyclic Isoxazoloquinazoline Derivative 2"],
  },
  {
    primary_id: "D06GGL",
    names: ["MSB11022"],
  },
  {
    primary_id: "D06GGT",
    names: [
      "MCL-1-Specific Antisense Oligonucleotide + Nilotinib (AMN107)",
      "641571-10-0",
      "UNII-F41401512X",
      "Nilotinib (AMN-107)",
      "CHEMBL255863",
      "CHEBI:52172",
      "C28H22F3N7O",
      "HHZIURLSWUIHRB-UHFFFAOYSA-N",
      "NSC747599",
    ],
  },
  {
    primary_id: "D06GHM",
    names: ["2,7-Bis[3-(Butylamino)Propionamido]anthraquinone", "SCHEMBL4289808", "CHEMBL479984"],
  },
  {
    primary_id: "D06GIL",
    names: ["JMV3008"],
  },
  {
    primary_id: "D06GIP",
    names: ["Thymol"],
  },
  {
    primary_id: "D06GIV",
    names: ["NSC-356483"],
  },
  {
    primary_id: "D06GJO",
    names: ["(2-Methoxy-Phenyl)-(5-Phenyl-Oxazol-2-Yl)-Amine"],
  },
  {
    primary_id: "D06GJP",
    names: [
      "Cibacron Blue",
      "Affi Gel Blue",
      "Cibacron Blue F 3GA",
      "Cibacron Blue 3G",
      "Cibacronblau F3G-A",
      "Procion Blue H-B",
      "C.I. Reactive Blue 2",
      "UNII-J0A052J6QF",
      "Basilen Blue",
      "CHEBI:34946",
      "EINECS 235-465-0",
      "NSC 328383",
      "J0A052J6QF",
      "C.I. 61211",
      "26763-68-8",
      "2-Anthracenesulfonic Acid, 1-Amino-4-((4-((4-Chloro-6-((3(Or 4)-Sulfophenyl)Amino)-1,3,5-Triazin-2-Yl)Amino)-3-Sulfophenyl)Amino)-9,10-Dihydro-9,10-Dioxo-",
      "Affi-Gel Blue",
    ],
  },
  {
    primary_id: "D06GJT",
    names: ["Alizapride"],
  },
  {
    primary_id: "D06GJW",
    names: [
      "Noradrenaline",
      "Dl-Arterenol",
      "Dl-Noradrenaline",
      "Dl-Norepinephrine",
      "4-(2-Amino-1-Hydroxyethyl)Benzene-1,2-Diol",
      "Noradrenalin, Dl-",
      "138-65-8",
      "NSC 294898",
      "EINECS 205-337-9",
      "1,2-Benzenediol, 4-(2-Amino-1-Hydroxyethyl)-",
      "[3H]NE",
      "CHEBI:33569",
      "SFLSHLFXELFNJZ-UHFFFAOYSA-N",
      "NSC294898",
      "(1)-4-(2-Amino-1-Hydroxyethyl)Pyrocatechol",
      "D-Arterenol",
      "D-Norepinephrine",
      "Xi-Norepinephrine",
      "1,2-Benzenediol, 4-(2-Amino-1-Hydroxyethyl)-, (.+/-.)-",
      "BENZYL ALCOHOL, Alpha-(AMINOMETHYL)-3,4-DIHYDROXY-, (+-)-",
      "Noradrop",
    ],
  },
  {
    primary_id: "D06GKE",
    names: ["GZ402665"],
  },
  {
    primary_id: "D06GKL",
    names: ["2-Methylamino-Succinic Acid(NMDA)"],
  },
  {
    primary_id: "D06GKN",
    names: ["Alosetron"],
  },
  {
    primary_id: "D06GLV",
    names: ["(2'Z,3'E)-5-Fluoro-5'-Fluoro-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D06GMJ",
    names: ["GSK945237"],
  },
  {
    primary_id: "D06GMM",
    names: ["FT-026"],
  },
  {
    primary_id: "D06GNE",
    names: [
      "IC86621",
      "DNA-PK Inhibitor III",
      "404009-40-1",
      "1-(2-HYDROXY-4-MORPHOLINOPHENYL)ETHANONE",
      "1-(2-Hydroxy-4-Morpholin-4-Yl-Phenyl)Ethanone",
      "CHEMBL1317546",
      "Ethanone, 1-[2-Hydroxy-4-(4-Morpholinyl)Phenyl]-",
      "SCHEMBL600210",
      "GTPL5960",
      "CTK1D4579",
      "CHEBI:95245",
      "DTXSID40431950",
      "MolPort-027-354-881",
      "HMS3229C13",
      "BCP08943",
      "ZINC3817773",
      "BDBM50398036",
      "AR2783",
      "4512AH",
      "IN1363",
      "AKOS017560291",
      "CCG-206744",
      "NCGC00165807-01",
      "AS-45440",
      "IC 86621",
      "RT-012395",
      "1-(2-Hydroxy-4-Morpholinophenyl)Ethan-1-One",
      "A-8048",
      "1-[2-Hydroxy-4-(Morpholin-4-Yl)Phenyl]ethan-1-One",
    ],
  },
  {
    primary_id: "D06GNF",
    names: ["TS-022"],
  },
  {
    primary_id: "D06GNR",
    names: [
      "N-(2-Methyl-2-Phenylpropyl)Pyrazine-2-Carboxamide",
      "CHEMBL246026",
      "923600-01-5",
      "CTK3F8796",
      "DTXSID50653166",
      "BDBM50197260",
      "AKOS027754505",
      "2-Pyrazinecarboxamide, N-(2-Methyl-2-Phenylpropyl)-",
    ],
  },
  {
    primary_id: "D06GOE",
    names: ["AS74", "AS 74", "AS-74"],
  },
  {
    primary_id: "D06GOF",
    names: ["IDP-123 Lotion"],
  },
  {
    primary_id: "D06GOK",
    names: [
      "2-Methyl-2,4-Pentanediol",
      "2-METHYL-2,4-PENTANEDIOL",
      "107-41-5",
      "2-Methylpentane-2,4-Diol",
      "Pinakon",
      "2,4-Dihydroxy-2-Methylpentane",
      "2,4-Pentanediol, 2-Methyl-",
      "Diolane",
      "Isol",
      "4-Methyl-2,4-Pentanediol",
      "1,1,3-Trimethyltrimethylenediol",
      "2-Methyl Pentane-2,4-Diol",
      "2-Methyl-2,4-Pentandiol",
      "Caswell No. 574",
      "Alpha,alpha,alpha'-Trimethyltrimethylene Glycol",
      "1,3-Dimethyl-3-Hydroxybutanol",
      "Hexylene Glycol [NF]",
      "HSDB 1126",
      "1,3,3-Trimethyl-1,3-Propanediol",
      "(+-)-2-Methyl-2,4-Pentanediol",
      "NSC 8098",
      "EINECS 203-489-0",
      "EPA Pesticide Chemical Code 068601",
      "BRN",
    ],
  },
  {
    primary_id: "D06GOO",
    names: ["ISIS 103624"],
  },
  {
    primary_id: "D06GOT",
    names: ["Pyrrolo[2,3-B]pyridine Derivative 2"],
  },
  {
    primary_id: "D06GPL",
    names: [
      "4-Methylphenyl-Difluoromethanesulfonamide",
      "CHEMBL199464",
      "803728-11-2",
      "CTK5E7727",
      "DTXSID60658763",
      "BDBM50175011",
      "AKOS027416710",
      "Benzenemethanesulfonamide,a,a-Difluoro-4-Methyl-",
      "1,1-Difluoro-1-(4-Methylphenyl)Methanesulfonamide",
    ],
  },
  {
    primary_id: "D06GPP",
    names: ["(E)-4,4-Dimethyl-1-Quinolin-3-Yl-Pent-1-En-3-One", "CHEMBL572128", "SCHEMBL5503755"],
  },
  {
    primary_id: "D06GQG",
    names: ["PMID25482888-Compound-16"],
  },
  {
    primary_id: "D06GQO",
    names: [
      "N-Butyl-N'-(4-Methyl-Benzoyl)-Guanidine",
      "CHEMBL432963",
      "SCHEMBL4629393",
      "BDBM50107019",
      "1-Butyl-2-(4-Methylbenzoyl)Guanidine",
    ],
  },
  {
    primary_id: "D06GQY",
    names: ["PSB-0963"],
  },
  {
    primary_id: "D06GRK",
    names: ["ARC029"],
  },
  {
    primary_id: "D06GRP",
    names: ["NSC-640583"],
  },
  {
    primary_id: "D06GRW",
    names: [
      "N-Hydroxy-N-Methyl-2,3,3-Triphenyl-Acrylamide",
      "CHEMBL174161",
      "BDBM50015154",
      "2,3,3-Triphenyl-N-Methyl-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D06GSA",
    names: ["CMV DNA Vaccine"],
  },
  {
    primary_id: "D06GSE",
    names: ["GPI-1337"],
  },
  {
    primary_id: "D06GSR",
    names: ["PMID27967267-Compound-52"],
  },
  {
    primary_id: "D06GSS",
    names: ["Calcifediol", "Calderol"],
  },
  {
    primary_id: "D06GSY",
    names: ["P-(2'-Iodo-5'-Thenoyl)Hydrotropic Acid"],
  },
  {
    primary_id: "D06GTI",
    names: ["AS96", "AS 96", "AS-96"],
  },
  {
    primary_id: "D06GTS",
    names: ["PMID24556381C10l"],
  },
  {
    primary_id: "D06GUA",
    names: ["GSK2269557"],
  },
  {
    primary_id: "D06GUG",
    names: ["BTT-1023"],
  },
  {
    primary_id: "D06GUJ",
    names: ["KURAIDIN"],
  },
  {
    primary_id: "D06GVN",
    names: ["Tormentic Acid Methyl Ester"],
  },
  {
    primary_id: "D06GWF",
    names: [
      "Valganciclovir",
      "Cymeval",
      "RS 79070",
      "RO1079070/194",
      "Valcyte (TN)",
      "Valganciclovir (INN)",
      "Valganciclovir (Oral)",
      "Valganciclovir [INN:BAN]",
      "L-Valine, Ester With Ganciclovir",
      "[2-[(2-Amino-6-Oxo-3H-Purin-9-Yl)Methoxy]-3-Hydroxypropyl] (2S)-2-Amino-3-Methylbutanoate",
      "L-Valine, 2-((2-Amino-1,6-Dihydro-6-Oxo-9H-Purin-9-Yl)Methoxy)-3-Hydroxypropyl Ester",
      "5-Amino-3-[1-(Hydroxymethyl)-2-(L-Valyloxy)Ethoxymethyl]-6,7-Dihydro-3H-Imidazo[4,5-D]pyrimidin-7-One",
    ],
  },
  {
    primary_id: "D06GWR",
    names: ["(24E)-3beta-Hydroxy-7,24-Euphadien-26-Oic Acid", "CHEMBL519411"],
  },
  {
    primary_id: "D06GXT",
    names: ["Acerogenin A", "CHEMBL516665", "SCHEMBL10048511"],
  },
  {
    primary_id: "D06GYH",
    names: ["1,2-Bis(2,3,4-Trifluorophenyl)-2-Hydroxyethanone", "CHEMBL244631"],
  },
  {
    primary_id: "D06GYI",
    names: ["N-2-Thiophen-2-Yl-Acetamide Boronic Acid", "AC1L9IFX"],
  },
  {
    primary_id: "D06GYJ",
    names: ["PD-01"],
  },
  {
    primary_id: "D06GYO",
    names: [
      "(9Z,12E)-12-Nitrooctadeca-9,12-Dienoic Acid",
      "12-Nitrolinoleic Acid",
      "12-Nitro-9Z,12Z-Octadecadienoic Acid",
      "CHEMBL554608",
      "CHEBI:34150",
      "AC1NQZV1",
      "C13958",
      "SCHEMBL2371323",
      "BDBM50295045",
      "LMFA01120002",
      "12-Nitro-9-Cis,12-Cis-Octadecadienoic Acid",
      "(9Z,12Z)-12-Nitrooctadeca-9,12-Dienoic Acid",
    ],
  },
  {
    primary_id: "D06GZW",
    names: ["PEGylated Leptin"],
  },
  {
    primary_id: "D06HBJ",
    names: ["BAY2306001"],
  },
  {
    primary_id: "D06HBQ",
    names: [
      "Gliquidone",
      "Beglynor",
      "Beglynora",
      "Glikvidon",
      "Gliquidona",
      "Gliquidonum",
      "Glurenorm",
      "Glycvidon",
      "ARDF 26",
      "ARDF 26 SE",
      "AR-DF 26",
      "Gliquidona [INN-Spanish]",
      "Gliquidone (INN)",
      "Gliquidone [BAN:INN]",
      "Gliquidonum [INN-Latin]",
      "Glurenorm (TN)",
      "N-[(Cyclohexylamino)Carbonyl]-4-{2-[4,4-Dimethyl-7-(Methyloxy)-1,3-Dioxo-3,4-Dihydroisoquinolin-2(1H)-Yl]ethyl}benzenesulfonamide",
      "1-Cyclohexyl-3-((P-(2-(3,4-Dihydro-7-Methoxy-4,4-Dimethyl-1,3-Dioxo-2(1H)-Isoquinolyl)Ethyl)Phenyl)Sulfonyl)Urea",
      "1-Cyclohexyl-3-(P-Beta-(4,4-Dimethyl-2-Methoxy-1,3-(2H,4H)-Isoquinolinedione-2-Yl)Phenethyl)Sulfonylurea",
      "1-Cyclohexyl-3-[4-[2-(7-Methoxy-4,4-Dimethyl-1,3-Dioxoisoquinolin-2-Yl)Ethyl]phenyl]sulfonylurea",
    ],
  },
  {
    primary_id: "D06HBT",
    names: ["LT-1951"],
  },
  {
    primary_id: "D06HCC",
    names: ["LANEPITANT"],
  },
  {
    primary_id: "D06HCD",
    names: ["SM-6586"],
  },
  {
    primary_id: "D06HCF",
    names: ["SPD-557"],
  },
  {
    primary_id: "D06HCP",
    names: ["MELARSOPROL"],
  },
  {
    primary_id: "D06HDW",
    names: [
      "BzATP",
      "3'-O-(4-Benzoyl)Benzoyl ATP",
      "3'-O-(4-Benzoylbenzoyl)ATP",
      "3'-O-(4-Benzoyl)Benzoyladenosine 5'-Triphosphate",
      "UNII-4P5DXU1F8Q",
      "81790-82-1",
      "4P5DXU1F8Q",
      "CHEBI:34316",
      "Adenosine 5'-(Tetrahydrogen Triphosphate), 3'-(4-Benzoylbenzoate)",
      "C24H24N5O15P3",
      "ATP, Bz",
      "AC1L3GPW",
      "AC1Q6S3N",
      "SCHEMBL3682844",
      "GTPL1757",
      "CHEMBL2407634",
      "BDBM86488",
      "ZINC8295154",
      "3'-O-(4-Benzoylbenzoyl)Adenosine 5'-(Tetrahydrogen Triphosphate)",
      "LS-177651",
      "CAS_81790-82-1",
      "C13744",
      "3'-O-(4-Benzoylbenzoyl)Adenosine 5'-Triphosphate",
    ],
  },
  {
    primary_id: "D06HEO",
    names: ["Pyrimidine Derivative 4"],
  },
  {
    primary_id: "D06HEY",
    names: ["CTL019"],
  },
  {
    primary_id: "D06HFB",
    names: [
      "(+)-BHFF",
      "(3S)-5,7-Ditert-Butyl-3-Hydroxy-3-(Trifluoromethyl)-1-Benzofuran-2-One",
      "AC1OE0QJ",
      "GTPL5504",
      "SCHEMBL2371847",
      "CHEMBL3600959",
      "ZINC2601326",
      "B7359",
    ],
  },
  {
    primary_id: "D06HFI",
    names: ["L-Ornithine Phenylacetate"],
  },
  {
    primary_id: "D06HFV",
    names: [
      "5-[5-Fluoro-Indan-(1E)-Ylidenemethyl]-Pyrimidine",
      "AC1O706P",
      "CHEMBL195248",
      "SCHEMBL4225537",
      "SCHEMBL4225546",
      "BDBM8631",
      "(5-Pyrimidylmethylene)Indane 28a",
      "ZINC13535842",
      "5-[(E)-(5-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyrimidine",
    ],
  },
  {
    primary_id: "D06HGM",
    names: ["LPO-1010CA"],
  },
  {
    primary_id: "D06HGO",
    names: ["MDY-1001"],
  },
  {
    primary_id: "D06HGY",
    names: ["RTA-403 Analog"],
  },
  {
    primary_id: "D06HHJ",
    names: [
      "(2-Bromo-Phenyl)-(1H-Imidazol-2-Yl)-Amine",
      "CHEMBL76457",
      "2-(2-Bromoanilino)-1H-Imidazole",
      "BDBM50055833",
    ],
  },
  {
    primary_id: "D06HIJ",
    names: ["PMID27607364-Compound-60"],
  },
  {
    primary_id: "D06HIO",
    names: [
      "Istaroxime",
      "Debio-0614",
      "PST-2107",
      "PST-2744",
      "PST-2915",
      "ST-2744",
      "Digitalis-Like Compounds, Sigma-Tau",
      "Sodium/Potassium-ATPase Inhibitors (Infusion, Cardiac Failure), Sigma-Tau/Debiopharm",
      "Na(+)-/K(+)-ATPase Inhibitors (Cardiac Failure), Sigma-Tau",
      "Na(+)-/K(+)-ATPase Inhibitors (Infusion, Cardiac Failure), Sigma-Tau/ Debiopharm",
    ],
  },
  {
    primary_id: "D06HIW",
    names: ["EUPATORIOPICRIN"],
  },
  {
    primary_id: "D06HJB",
    names: ["LUDARTIN"],
  },
  {
    primary_id: "D06HJX",
    names: ["Thiomorpholine Derivative 1"],
  },
  {
    primary_id: "D06HKB",
    names: ["Lodenosine"],
  },
  {
    primary_id: "D06HLH",
    names: ["2-Tert-Butyl-7-(Trifluoromethyl)-9H-Carbazole", "CHEMBL1171079", "ZINC53230612"],
  },
  {
    primary_id: "D06HLW",
    names: ["PMID28074661-Compound-US20120264760C82"],
  },
  {
    primary_id: "D06HLY",
    names: [
      "Pilocarpine",
      "Adsorbocarpine",
      "Almocarpine",
      "Epicar",
      "Isopilocarpine",
      "Isoptocarpine",
      "Miocarpine",
      "Ocusert",
      "Pilagan",
      "Pilocarpin",
      "Pilocarpol",
      "Pilokarpin",
      "Pilokarpol",
      "Pilostat",
      "Salagen",
      "Spersacarpine",
      "Syncarpine",
      "Amistura P",
      "Isopto Carpine",
      "Minims Pilocarpine",
      "Ocusert Pilo",
      "Pilocarpine HCl",
      "Pilocarpine Chloride",
      "Pilocarpine Nitrate",
      "Ocusert P 20;Beta-Pilocarpine Hydrochloride",
      "Diocarpine (TN)",
      "Isopto Carpine (TN)",
      "Mi-Pilo",
      "Miocarpine (TN)",
      "Ocu-Carpine",
      "Ocusert Pilo-40",
      "Ocusert Pilo-20",
      "Pilocarpine (TN)",
      "Pilopine HS (TN)",
      "Piloptic-1",
      "Piloptic-2",
      "Piloptic-3",
      "Piloptic-4",
      "Piloptic-6",
      "Salagen (TN)",
      "Scheinpharm (TN)",
      "Timpilo (TN)",
      "Ocusert Pilo-20 (TN)",
      "P.V. Carpine Liquifilm",
      "Pilocarpine (JAN/USP)",
      "Pilocarpine [USAN:BAN:JAN]",
      "Piloptic-1/2",
      "Pilocarpine Mononitrate, (3S-Cis)-Isomer",
      "Pilocarpine, Monohydrochloride, (3S-Cis)-Isomer",
      "Imidazole-5-Butyric Acid, Alpha-Ethyl-Beta-(Hydroxymethyl)-1-Methyl-, Gamma-Lactone",
      "(+)-Pilocarpine",
      "(3S,4R)-3-Ethyl-4-[(1-Methyl-1H-Imidazol-5-Yl)Methyl]dihydrofuran-2(3H)-One",
      "(3S,4R)-3-Ethyl-4-[(3-Methylimidazol-4-Yl)Methyl]oxolan-2-One",
      "(3S,4R)-3-Ethyldihydro-4-((1-Methyl-1H-Imidazol-5-Yl)Methyl)-2(3H)-Furanone",
      "(3S-Cis)-3-Ethyldihydro-4-((1-Methyl-1H-Imidazol-5-Yl)Methyl)-2(3H)-Furanone",
      "(3S-Cis)-3-Ethyldihydro-4-[(1-Methyl-1H-Imidazol-5-Yl)Methyl]-2(3H)-Furanone",
      "3-Ethyl-4-(3-Methyl-3H-Imidazol-4-Ylmethyl)Dihydrofuran-2-One",
      "3-Ethyl-4-[(1-Methyl-1H-Imidazol-5-Yl)Methyl]dihydro-2(3H)-Furanone",
      "3-Ethyl-4-[(1-Methyl-1H-Imidazol-5-Yl)Methyl]dihydrofuran-2(3H)-One",
      "3-Ethyl-4-((1-Methyl-1H-Imidazol-5-Yl)Methyl)Dihydro-2(3H)-Furanone",
      "3-Ethyl-4-[(3-Methylimidazol-4-Yl)Methyl]oxolan-2-One",
    ],
  },
  {
    primary_id: "D06HMN",
    names: ["BAY1251152"],
  },
  {
    primary_id: "D06HMO",
    names: ["CVBT-141B Topical"],
  },
  {
    primary_id: "D06HMY",
    names: ["Biphenyl Carboxamidopropanoic Acid Derivative 2"],
  },
  {
    primary_id: "D06HNA",
    names: ["PMID1875346C18"],
  },
  {
    primary_id: "D06HNO",
    names: ["MEDI4920"],
  },
  {
    primary_id: "D06HNS",
    names: [
      "Propargylglycine",
      "2-(Prop-2-Ynylamino)Acetic Acid",
      "Glycine,N-2-Propyn-1-Yl-",
      "58160-95-5",
      "Acetic Acid, 2-(Prop-2-Ynylamino)-",
      "NSC692226",
      "2-[(Prop-2-Yn-1-Yl)Amino]acetic Acid",
      "(2-Propynylamino)Acetic Acid",
      "Propargyl-Glycine",
      "AC1L4HR4",
      "SCHEMBL44356",
      "GTPL5247",
      "CHEMBL1993296",
      "CTK5A7939",
      "DTXSID90206892",
      "ZINC1858411",
      "AKOS009210257",
      "NSC-692226",
      "AC-6385",
      "SC-10419",
      "NCI60_033077",
      "KB-50364",
      "SC-65650",
      "TL8001634",
      "FT-0764746",
      "I04-0895",
      "I04-0888",
    ],
  },
  {
    primary_id: "D06HOA",
    names: ["Indazole Derivative 2"],
  },
  {
    primary_id: "D06HOI",
    names: ["BMS-269223", "CHEMBL551991", "Cyanoguanidine, 3"],
  },
  {
    primary_id: "D06HOS",
    names: ["PharmaPEG-GM-CSF"],
  },
  {
    primary_id: "D06HPA",
    names: ["Argidene Gel"],
  },
  {
    primary_id: "D06HPB",
    names: ["ISIS 112696"],
  },
  {
    primary_id: "D06HPP",
    names: ["AGT-182"],
  },
  {
    primary_id: "D06HPV",
    names: [
      "DIASTEREOMER 2",
      "571170-81-5",
      "2-(4-(4-Chlorobenzyl)-7-Fluoro-5-(Methylsulfonyl)-1,2,3,4-Tetrahydrocyclopenta[b]indol-3-Yl)Acetic Acid",
      "CHEMBL221007",
      "4-[(4-Chlorophenyl)Methyl]-7-Fluoro-1,2,3,4-Tetrahydro-5-(Methylsulfonyl)-Cyclopent[b]indole-3-Acetic Acid",
      "MK-0524 Laropiprant",
      "SCHEMBL1044552",
      "CTK8C2002",
      "DTXSID00436805",
      "CS-M1273",
      "KS-00001EH0",
      "ANW-67605",
      "BDBM50205278",
      "AKOS016006816",
      "KB-71920",
      "CS-13849",
      "AX8238513",
      "TC-156350",
      "126596-EP2295409A1",
      "1-(4-Chlorobenzyl)-5-Fluoro-7-Mesyl-2,3-Propano-1H-Indole-10-Ylacetic Acid",
    ],
  },
  {
    primary_id: "D06HQG",
    names: [
      "SPM-5185",
      "139146-66-0",
      "N-Nitratopivaloyl-S-(N'-Acetylalanyl)-Cysteine Ethyl Ester",
      "Spm 5185",
      "AC1NUPHX",
      "DTXSID80160934",
      "L-Cysteine, N-(2,2-Dimethyl-3-(Nitrooxy)-1-Oxopropyl)-, Ethyl Ester, Ester With N-Acetyl-L-Alanine",
      "(2S)-2-Acetamidopropanoic Acid",
      "[3-[[(2R)-1-Ethenoxy-3-Sulfanylidenepropan-2-Yl]amino]-2,2-Dimethyl-3-Oxopropyl] Nitrate",
    ],
  },
  {
    primary_id: "D06HQM",
    names: ["Apo805K1"],
  },
  {
    primary_id: "D06HRD",
    names: ["EUR-1100"],
  },
  {
    primary_id: "D06HRI",
    names: ["PMID28051882-Compound-XI"],
  },
  {
    primary_id: "D06HRX",
    names: [
      "AMENTOFLAVONE",
      "Amentoflavone",
      "1617-53-4",
      "3',8''-Biapigenin",
      "Didemethyl-Ginkgetin",
      "Amenthoflavone",
      "UNII-9I1VC79L77",
      "MLS000574827",
      "CHEBI:2631",
      "9I1VC79L77",
      "SMR000156235",
      "Q-100192",
      "8-[5-(5,7-Dihydroxy-4-Oxo-Chromen-2-Yl)-2-Hydroxy-Phenyl]-5,7-Dihydroxy-2-(4-Hydroxyphenyl)Chromen-4-One",
      "8-[5-(5,7-Dihydroxy-4-Oxo-4H-Chromen-2-Yl)-2-Hydroxyphenyl]-5,7-Dihydroxy-2-(4-Hydroxyphenyl)-4H-Chromen-4-One",
      "8-(5-(5,7-Dihydroxy-4-Oxo-4H-1-Benzopyran-2-Yl)-2-Hydroxyphenyl)-5,7-Dihydroxy-2-(4-Hydroxyphenyl)-4H-1-Benzopyran-4-One",
    ],
  },
  {
    primary_id: "D06HRY",
    names: ["SNAP-5089"],
  },
  {
    primary_id: "D06HSC",
    names: ["Folate Gamma-L-Phenylalaninehydroxamic Acid"],
  },
  {
    primary_id: "D06HTJ",
    names: [
      "D-264",
      "CHEMBL514885",
      "SCHEMBL10075564",
      "BDBM50273996",
      "(S)-N6-(2-(4-(Biphenyl-4-Yl)Piperazin-1-Yl)Ethyl)-N6-Propyl-4,5,6,7-Tetrahydrobenzo[d]thiazole-2,6-Diamine",
    ],
  },
  {
    primary_id: "D06HUF",
    names: ["AM-365", "AM-188"],
  },
  {
    primary_id: "D06HUH",
    names: ["Recombinant Human Butyrylcholinesterase"],
  },
  {
    primary_id: "D06HUQ",
    names: ["2-Phenethyl-1,2-Dihydro-Indazol-3-One", "CHEMBL3144586", "CHEMBL8415", "BDBM50009007", "ZINC138045731"],
  },
  {
    primary_id: "D06HUS",
    names: ["PF-06755347"],
  },
  {
    primary_id: "D06HUV",
    names: ["Antihypercholesterolemics", "Antihypercholesterolemics, Maxia", "MX-6046", "MX-7291"],
  },
  {
    primary_id: "D06HVH",
    names: ["PMID22136433C20"],
  },
  {
    primary_id: "D06HWG",
    names: ["Inflexal V"],
  },
  {
    primary_id: "D06HWI",
    names: ["GNF-PF-1967"],
  },
  {
    primary_id: "D06HWU",
    names: ["CHR-4487"],
  },
  {
    primary_id: "D06HXC",
    names: ["NPY-1 Antagonist"],
  },
  {
    primary_id: "D06HYB",
    names: ["ML-10302", "148868-55-7"],
  },
  {
    primary_id: "D06HYC",
    names: ["VLI-01B"],
  },
  {
    primary_id: "D06HYF",
    names: ["M2698"],
  },
  {
    primary_id: "D06HYG",
    names: [
      "Barucainide",
      "UNII-14NFL30YOH",
      "79784-22-8",
      "14NFL30YOH",
      "Barucainidum",
      "Barucainide [INN]",
      "Barucainid",
      "AC1Q1QCZ",
      "AC1L3TX5",
      "SCHEMBL2110723",
      "CHEMBL2105905",
      "DTXSID60229912",
      "ZINC3643459",
      "4-Benzyl-1,3-Dihydro-7-(4-(Isopropylamino)Butoxy)-6-Methylfuro(3,4-C)Pyridine",
      "4-[(4-Benzyl-6-Methyl-1,3-Dihydrofuro[3,4-C]pyridin-7-Yl)Oxy]-N-Propan-2-Ylbutan-1-Amine",
    ],
  },
  {
    primary_id: "D06HZA",
    names: ["GSK2586184"],
  },
  {
    primary_id: "D06HZY",
    names: [
      "Fructose",
      "D-(-)-Fructose",
      "57-48-7",
      "D(-)-Fructose",
      "(3S,4R,5R)-1,3,4,5,6-Pentahydroxyhexan-2-One",
      "53188-23-1",
      "D-(-)-Levulose",
      "D-Levulose",
      "Nevulose",
      "Furucton",
      "Arabino-Hexulose",
      "Sugar, Fruit",
      "Fructose, D-",
      "Krystar 300",
      "Hi-Fructo 970",
      "Keto-D-Fructose",
      "Fructose, Pure",
      "Fructose Solution",
      "CCRIS 3335",
      "30237-26-4",
      "EINECS 200-333-3",
      "AI3-23514",
      "DL-Fructose",
      "MFCD00148910",
      "D(-)-Fructose, 99%",
      "Fructon",
      "D-(-)-Fructose, &gt",
      "Methose",
      "Levulosa",
      "Levulosa Baxter",
      "Levulosa Braun",
      "Apir Levulosa",
      "Levulosa Mein",
      "Levulosa Ibys",
      "Levulosa Ife",
      "Levulosado Braun",
      "D-Fructose",
    ],
  },
  {
    primary_id: "D06IAA",
    names: ["LK-410"],
  },
  {
    primary_id: "D06IAE",
    names: [
      "4-Chloro-N-(3-Morpholinopropyl)Nicotinamide",
      "CHEMBL598586",
      "SCHEMBL1613255",
      "XYKBQKHBJFXZSH-UHFFFAOYSA-N",
      "BDBM50307197",
      "AKOS019797554",
    ],
  },
  {
    primary_id: "D06IAQ",
    names: [
      "Ambenonium",
      "Ambenonum",
      "Ambenonium Base",
      "Ambenonium Kation",
      "Mytelase (TN)",
      "(2-Chlorophenyl)Methyl-[2-[[2-[2-[(2-Chlorophenyl)Methyl-Diethylazaniumyl]ethylamino]-2-Oxoacetyl]amino]ethyl]-Diethylazanium",
      "2,2'-[(1,2-Dioxoethane-1,2-Diyl)Diimino]bis[n-(2-Chlorobenzyl)-N,n-Diethylethanaminium]",
    ],
  },
  {
    primary_id: "D06IAR",
    names: [
      "Adenosine",
      "Adenosine, Homopolymer",
      "Adenine 9-Beta-D-Arabinofuranoside",
      "Adenine-9-Beta-D-Ribofuranoside",
      "Beta-D-Ribofuranoside, Adenine-9",
      "EA6C60C2-6AFB-4264-A2F0-541373DB950E",
      "(2R,3R,4S,5R)-2-(6-Aminopurin-9-Yl)-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "(2R,3R,4S,5R)-2-(6-Amino-9H-Purin-9-Yl)-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "4-Aminopyrazolo[3,4-D]pyrimidine Ribonucleoside",
      "6-Amino-9-.beta.-Ribofuranosyl-9H-Purine",
      "6-Amino-9-Beta-D-Ribofuranosyl-9H-Purine",
      "6-Amino-9beta-D-Ribofuranosyl-9H-Purine",
      "9-(Beta-D-Arabinofuranosyl)Adenine",
      "9-Beta-D-Ribofuranosidoadenine",
      "9-Beta-D-Ribofuranosyl-9H-Purin-6-Amine",
      "9-Beta-D-Ribofuranosyladenine",
      "9beta-D-Ribofuranosyladenine",
    ],
  },
  {
    primary_id: "D06IAT",
    names: ["Diazacholesterol"],
  },
  {
    primary_id: "D06IBG",
    names: ["MK-3415A", "Actoxumab/Bezlotoxumab"],
  },
  {
    primary_id: "D06IBV",
    names: [
      "Heptyl-Beta-D-Glucopyranoside",
      "78617-12-6",
      "HEPTYL-BETA-D-GLUCOPYRANOSIDE",
      "Heptyl Beta-D-Glucopyranoside",
      "(2R,3R,4S,5S,6R)-2-(Heptyloxy)-6-(Hydroxymethyl)Tetrahydro-2H-Pyran-3,4,5-Triol",
      "Heptyl-B-D-Glucopyranoside",
      "Heptyl B-D-Glucopyranoside",
      "B7G",
      "N-Heptyl-I(2)-D-Glucopyranoside",
      "AC1L9LOI",
      "Heptyl-",
      "N-Heptyl-Ss-D-Glucopyranosid",
      "SCHEMBL333088",
      "MolPort-003-935-703",
      "C13H26O6",
      "ZINC48016017",
      "MFCD00063299",
      "GC5331",
      "AKOS027251086",
      "DB03338",
      "API0002901",
      "AK187162",
      "W0562",
      "FT-0626931",
      "N-Heptyl Beta-D-Glucopyranoside, ~98% (GC)",
      "A-7798",
      "N-Heptyl Beta-D-Gluc",
    ],
  },
  {
    primary_id: "D06ICE",
    names: ["Radiolabeled Anti-PSMA HuJ591 Minibodies"],
  },
  {
    primary_id: "D06ICW",
    names: [
      "MDI-P",
      "CHEMBL595840",
      "60986-80-3",
      "5-Methyl-3,6-Diphenylisoxazolo[4,5-C]pyridin-4(5H)-One",
      "3,6-Diphenyl-5-Methylisoxazolo[4,5-C]pyridin-4(5H)-One",
      "3,6-DIPHENYL-5-METHYLISOXAZOLO(4,5-C)PYRIDIN-4(5H)-ONE",
      "AC1Q3XQS",
      "AC1Q3XQT",
      "AC1N5X7T",
      "MLS002153017",
      "SCHEMBL3663640",
      "GTPL6218",
      "CTK5B2529",
      "Cid_4250909",
      "DTXSID10401226",
      "LCNDUGHNYMJGIW-UHFFFAOYSA-N",
      "HMS2206G07",
      "5-Methyl-3,6-Diphenyl-Isoxazolo[4,5-C]pyridine-4(5H)-One",
      "ZINC394853",
      "HMS3358C19",
      "BDBM50305859",
      "AKOS024323421",
      "MCULE-8467817198",
      "SMR001229079",
      "FT-0714059",
    ],
  },
  {
    primary_id: "D06IDO",
    names: ["GS4071"],
  },
  {
    primary_id: "D06IDT",
    names: [
      "Demecarium Bromide",
      "Demecarium",
      "Demecastigmine",
      "Frumtosnil",
      "Humorsol",
      "Tonilen",
      "Tosmicil",
      "Tosmilen",
      "Tosmilene",
      "Visumatic",
      "Visumiotic;Bromure De Demecarium",
      "Bromuro De Demecario",
      "Demecarii Bromidum",
      "Demekarium Bromide",
      "Demekastigmine Bromide",
      "BC 48",
      "Bromure De Demecarium [INN-French]",
      "Bromuro De Demecario [INN-Spanish]",
      "Demecarii Bromidum [INN-Latin]",
      "Demecarium Bromide [INN:BAN]",
      "Humorsol (TN)",
      "Decamethylenebis[m-Dimethylaminophenyl N-Methylcarbamate] Dimethobromide",
      "Demecarium Bromide (USP/INN)",
      "Decamethylenebis(N-Methylcarbamic Acid M-Dimethylaminophenyl Ester) Bromomethylate",
      "Decamethylenebis(M-Dimethylaminophenyl-N-Methylcarbamate) Dimethobromide",
      "Decamethylenebis(M-Dimethylaminophenyl-N-Methylcarbamate)Dimethobromide;Ammonium, (M-Hydroxyphenyl)Trimethyl-, Bromide, Decamethylenebis(Methylcarbamate)",
      "Trimethyl-[3-[methyl-[10-[methyl-[3-(Trimethylazaniumyl)Phenoxy]carbonylamino]decyl]carbamoyl]oxyphenyl]azanium Dibromide",
      "Ammonium, (M-Hydroxyphenyl)Trimethyl-, Bromide, Decamethylenebis(Methylcarbamate) (2:1)",
      "N,N'-Bis(3-Trimethylammoniumphenoxycarbonyl)-N,N'-Dimethyldecamethylenediamine Dibromide",
      "Benzenaminium,3,3'-(1,10-Decanediylbis((Methylimino)Carbonyloxy))Bis(N,N,N-Trimethyl-, Dibromide",
      "(M-Hydroxyphenyl)Trimethylammonium Bromide Decamethylenebis(Methylcarbamate)",
      "(M-Hydroxyphenyl)Trimethylammonium Bromide Decamethylenebis(Methylcarbamate) (2:1)",
      "(M-Hydroxyphenyl)Trimethylammonium Bromide Decamethylenebis(Methylcarbamate) (7CI)",
      "3,3'-{decane-1,10-Diylbis[(Methylcarbamoyl)Oxy]}bis(N,N,N-Trimethylanilinium) Dibromide",
    ],
  },
  {
    primary_id: "D06IEN",
    names: [
      "2-(Acetylamino)-2-Deoxy-A-D-Glucopyranose",
      "Beta-N-Acetylglucosamine",
      "BetaGlcNAc",
      "GlcNAc-Beta",
      "CHEBI:28009",
      "UNII-8P59336F68",
      "2-Acetamido-2-Deoxy-Beta-D-Glucose",
      "AI3-51898",
      "8P59336F68",
      "N-[(2R,3R,4R,5S,6R)-2,4,5-Trihydroxy-6-(Hydroxymethyl)Tetrahydropyran-3-Yl]acetamide",
      "Cheon Shim Bo Yun",
    ],
  },
  {
    primary_id: "D06IEX",
    names: ["Cardiotrophin-1", "Cardiotrophin-1 (Acute Liver Failure/Obesity/Interferon Stimulator)"],
  },
  {
    primary_id: "D06IFR",
    names: ["PF-3654764"],
  },
  {
    primary_id: "D06IGC",
    names: [
      "ACT-0932",
      "ACT-0971",
      "ACT-0981",
      "Cancer Cell Targeting Peptides (Glioma)",
      "Cancer Cell Targeting Peptides (Glioma), Arch Biopartners/ Arch Cancer Therapeutics",
    ],
  },
  {
    primary_id: "D06IGE",
    names: ["Factor IX-XTEN"],
  },
  {
    primary_id: "D06IGJ",
    names: [
      "3-(2-Adamantyl) Pyrrolidine",
      "3-(2-Adamantyl)Pyrrolidine",
      "AC1MHVT3",
      "CHEMBL67992",
      "SCHEMBL587443",
      "AKOS023848508",
      "Pyrrolidine, 3-Tricyclo[3.3.1.13,7]dec-2-Yl-",
    ],
  },
  {
    primary_id: "D06IGT",
    names: ["(3S,4R)-3-Benzyl-7-Isopropyl-Chroman-4-Ol", "CHEMBL311850"],
  },
  {
    primary_id: "D06IGU",
    names: [
      "Troleandomycin",
      "Aovine",
      "Cyclamycin",
      "Evramicina",
      "Matromicina",
      "Oleandocetin",
      "Oleandocetine",
      "TAO",
      "Treolmicina",
      "Triacetyloleandomycin",
      "Triacetyloleandomycinum",
      "Tribiocillina",
      "Triocetin",
      "Triolan",
      "Troleandomicina",
      "Troleandomycine",
      "Troleandomycinum",
      "Viamicina",
      "Wytrion",
      "Matromycin T",
      "Oleandomycin Triacetate",
      "Oleandomycin Triacetyl Ester",
      "Triacetyl Ester Of Oleandomycin",
      "WY 651",
      "Tao (TN)",
      "Tao (VAN)",
      "Tekmisin (TN)",
      "Treis-Micina",
      "Triacetyloleandomycin (JAN)",
      "Triocetin (TN)",
      "Troleandomicina [INN-Spanish]",
      "Troleandomycin (TAO)",
      "Troleandomycin [USAN:INN]",
      "Troleandomycine [INN-French]",
      "Troleandomycinum [INN-Latin]",
      "Oleandomycin, Triacetate (Ester)",
      "T.A.O",
      "Troleandomycin (USP/INN)",
      "T.A.O.",
    ],
  },
  {
    primary_id: "D06IGW",
    names: ["Coronary Artery Disease Gene Therapy"],
  },
  {
    primary_id: "D06IHB",
    names: ["PMID25435179-Compound-WO2012106509CAY10602"],
  },
  {
    primary_id: "D06IHG",
    names: [
      "(E)-10-Nitrooctadec-9-Enoic Acid",
      "10-Nitrooleic Acid",
      "10-Nitro-9E-Octadecenoic Acid",
      "10-Nitro Oleic Acid",
      "10-Nitroelaidic Acid",
      "875685-46-4",
      "UNII-1N19AGY57Y",
      "CHEMBL561371",
      "1N19AGY57Y",
      "CHEBI:86285",
      "(9E)-10-Nitrooctadecenoic Acid",
      "10-Nitrooleate",
      "10-Nitro-Oleic Acid",
      "10-Nitroelaidate",
      "88127-53-1",
      "9-Octadecenoic Acid, 10-Nitro-, (9E)-",
      "E-10-Nitrooleic Acid",
      "Oa-NO2",
      "10-Nitro-9E-Octadecenoate",
      "(9E)-10-Nitrooctadecenoate",
      "10-Nitro-9-Octadecenoic Acid",
      "SCHEMBL1018141",
      "CXA-10",
      "(E)-10-Nitrooctadec-9-Enoate",
      "WRADPCFZZWXOTI-BMRADRMJSA-N",
    ],
  },
  {
    primary_id: "D06IIB",
    names: ["Halcinonide"],
  },
  {
    primary_id: "D06IIF",
    names: ["1-(3,4-Dichlorophenyl)-3-Aza-Bicyclo[3.1.0]hexane", "1-(3,4-Dichlorophenyl)-3-Azabicyclo[3.1.0]hexane"],
  },
  {
    primary_id: "D06III",
    names: ["NE10790"],
  },
  {
    primary_id: "D06IIL",
    names: ["PMID28092474-Compound-32o"],
  },
  {
    primary_id: "D06IJG",
    names: ["Silyman DD"],
  },
  {
    primary_id: "D06IJI",
    names: ["SC-52073"],
  },
  {
    primary_id: "D06IJW",
    names: [
      "1-(4-Hexylphenyl)-3-Morpholinopropan-1-One",
      "Beta-Aminophenylketone, 3e",
      "CHEMBL236132",
      "BDBM18818",
      "AKOS022274318",
    ],
  },
  {
    primary_id: "D06ILK",
    names: ["Daunorubicin Citrate Liposome Injection"],
  },
  {
    primary_id: "D06ILL",
    names: ["BMS-986158"],
  },
  {
    primary_id: "D06IME",
    names: [
      "CCX-140",
      "CCX-140-B",
      "CCR2 Receptor Antagonist (Inflammation/Multiple Sclerosis/Metabolic Disease), ChemoCentryx",
      "CCR2 Receptor Antagonist (Inflammation/Multiple Sclerosis/Metabolic Disease/Chronic Kidney Disease), ChemoCentryx",
    ],
  },
  {
    primary_id: "D06IMK",
    names: [
      "3-(4-Cyclohexylthiosemicarbazono)Methyl-Phenol",
      "CHEMBL387708",
      "BDBM50216118",
      "(Z)-3-Hydroxybenzaldehyde 4-Cyclohexyl Thiosemicarbazone",
    ],
  },
  {
    primary_id: "D06IMY",
    names: ["N-Hydroxy-9H-Xanthene-9-Carboxamide"],
  },
  {
    primary_id: "D06IOI",
    names: [
      "4-Benzythiopyridine-3-Sulfonamide",
      "CHEMBL1164472",
      "4-Benzylsulfanylpyridine-3-Sulfonamide",
      "4-(Benzylsulfanyl)-3-Pyridinesulfonamide",
      "AC1LICQ3",
      "Oprea1_561801",
      "AJ-333/13050159",
      "MolPort-002-817-660",
      "ZINC481325",
      "BDBM50320515",
      "MCULE-9875266241",
    ],
  },
  {
    primary_id: "D06IOP",
    names: ["2-Methyl-1-(Thiophen-3-Yl)Pent-1-En-3-One Oxime"],
  },
  {
    primary_id: "D06IPN",
    names: ["Sunscreens"],
  },
  {
    primary_id: "D06IPT",
    names: [
      "N-(Biphenyl-4-Yl)-2-Cyano-3-Hydroxybut-2-Enamide",
      "(2z)-N-Biphenyl-4-Yl-2-Cyano-3-Hydroxybut-2-Enamide",
      "CHEMBL519160",
      "DB07443",
      "(2Z)-2-Cyano-3-Hydroxy-N-(4-Phenylphenyl)But-2-Enamide",
    ],
  },
  {
    primary_id: "D06IPV",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 2"],
  },
  {
    primary_id: "D06IPZ",
    names: ["MK-3281"],
  },
  {
    primary_id: "D06IQB",
    names: ["5-(4-Hydroxyphenyl)-3H-1,2-Dithiole-3-Thione"],
  },
  {
    primary_id: "D06IQE",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D06IRE",
    names: [
      "Trichloroethanol",
      "2,2,2-TRICHLOROETHANOL",
      "115-20-8",
      "Trichlorethanol",
      "Trichloroethyl Alcohol",
      "Ethanol, 2,2,2-Trichloro-",
      "2,2,2-Trichloro-1-Ethanol",
      "(Hydroxymethyl)Trichloromethane",
      "2,2,2-Trichloroethyl Alcohol",
      "2,2,2-Trichloroethan-1-Ol",
      "C2H3Cl3O",
      "UNII-AW835AJ62N",
      "NSC 66407",
      "Beta-Trichloroethanol",
      "CCRIS 6763",
      "CCl3CH2OH",
      "EINECS 204-071-0",
      "2,2,2-Trichloro-Ethanol",
      "2,2,2-Tris-Chloroethanol",
      "BRN 1697495",
      "AW835AJ62N",
      "CHEBI:28094",
      "KPWDGTGXUYRARH-UHFFFAOYSA-N",
      ".beta.,.beta.,.beta-Trichloroethanol",
      "2,2,2-Trichloroethanol, 99%",
      "4yas",
    ],
  },
  {
    primary_id: "D06IUC",
    names: [
      "FM1-10",
      "CHEMBL257643",
      "4-((E)-2-[4-(DIETHYLAMINO)PHENYL]ETHENYL)-1-[3-(TRIETHYLAMMONIO)PROPYL]PYRIDINIUM DIBROMIDE",
      "AC1O6V5O",
      "SCHEMBL1527145",
      "AKOS000814322",
      "ACM336185207",
      "(E)-4-(4-(Diethylamino)Styryl)-1-(3-(Triethylammonio)Propyl)Pyridinium Bromide",
      "336185-20-7",
      "3-[4-[(E)-2-[4-(Diethylamino)Phenyl]ethenyl]pyridin-1-Ium-1-Yl]propyl-Triethylazanium Dibromide",
    ],
  },
  {
    primary_id: "D06IVC",
    names: ["PMID30280939-Compound-WO2009082440"],
  },
  {
    primary_id: "D06IVH",
    names: ["Org-20781"],
  },
  {
    primary_id: "D06IVN",
    names: ["PolongaTab"],
  },
  {
    primary_id: "D06IVU",
    names: ["N-(4-Methylthiazol-2-Yl)-2-Phenylacetamide"],
  },
  {
    primary_id: "D06IWR",
    names: ["N-(4-Amino-3'-Methoxybiphenyl-3-Yl)Benzamide", "CHEMBL1097747", "BDBM50317991"],
  },
  {
    primary_id: "D06IXE",
    names: ["MCL-153"],
  },
  {
    primary_id: "D06IXI",
    names: ["BrP-LPA", "Alpha-Bromomethylene Phosphonate"],
  },
  {
    primary_id: "D06IXT",
    names: ["Propyphenazone"],
  },
  {
    primary_id: "D06IYU",
    names: ["PHENYLSULFAMIDE"],
  },
  {
    primary_id: "D06IYV",
    names: ["TAK-810"],
  },
  {
    primary_id: "D06IZM",
    names: [
      "Erythrityl Tetranitrate",
      "Cardilate",
      "Cardiloid",
      "Cardivell",
      "Cardiwell",
      "Erythritetranitrat",
      "Nitroerythrit",
      "Nitroerythrite",
      "Nitroerythrol",
      "Tetranitrin",
      "Tetranitrol",
      "Eritritile Tetranitrato",
      "Eritritile Tetranitrato [DCIT]",
      "Eritrityl Tetranitrate",
      "Eritrityli Tetranitras",
      "Erythritol Tetranitrate",
      "Erythrityl Tetranitrate [USAN]",
      "Erythrol Tetranitrate",
      "Tetranitrato De Eritritilo",
      "Eritrityli Tetranitras [INN-Latin]",
      "Erythritol, Tetranitrate",
      "Meso-Erythritol Tetranitrate",
      "Tetranitrate D'erithrityle",
      "Tetranitrate D'eritrityle",
      "Tetranitrato De Eritritilo [INN-Spanish]",
      "Erythritol, Tetranitrate (8CI)",
      "Tetranitrate D'eritrityle [INN-French]",
      "1,2,3,4-Butanetetralyl Tetranitrate",
      "1,3,4-Trinitrooxybutan-2-Yl Nitrate",
    ],
  },
  {
    primary_id: "D06IZQ",
    names: ["JKB-121"],
  },
  {
    primary_id: "D06IZS",
    names: ["XEL-004FP"],
  },
  {
    primary_id: "D06IZU",
    names: ["ISIS 32028"],
  },
  {
    primary_id: "D06IZZ",
    names: ["Arg-Pro-Lys-Pro-Ala-Gln-Phe-Phe-Gly-Leu-Met-NH2", "CHEMBL396426"],
  },
  {
    primary_id: "D06JAL",
    names: ["8H9"],
  },
  {
    primary_id: "D06JAT",
    names: ["L690330"],
  },
  {
    primary_id: "D06JBA",
    names: ["HGS-TR2J"],
  },
  {
    primary_id: "D06JBN",
    names: [
      "2-Arachidonoylglycerol",
      "2-AG",
      "2-Arachidonylglycerol",
      "2-Arachidonyl-Glycerol",
      "53847-30-6",
      "2-Arachidonoyl-Glycerol",
      "2-Arachidonoyl Glycerol",
      "2-Ara-Gl",
      "2-Monoarachidonoylglycerol",
      "UNII-8D239QDW64",
      "1,3-Dihydroxypropan-2-Yl (5Z,8Z,11Z,14Z)-Icosa-5,8,11,14-Tetraenoate",
      "CHEMBL122972",
      "CHEBI:52392",
      "RCRCTBLIHCHWDZ-DOFZRALJSA-N",
      "8D239QDW64",
      "MG(20:4)",
      "MG(0:0/20:4(5Z,8Z,11Z,14Z)/0:0)",
      "5,8,11,14-Eicosatetraenoic Acid, 2-Hydroxy-1-(Hydroxymethyl)Ethyl Ester, (5Z,8Z,11Z,14Z)-",
      "5,8,11,14-Eicosatetraenoic Acid, 2-Hydroxy-1-(Hydroxymethyl)Ethy",
    ],
  },
  {
    primary_id: "D06JBP",
    names: ["Benzyl-(9H-Beta-Carbolin-6-Yl)-Amine"],
  },
  {
    primary_id: "D06JBV",
    names: ["SGN LIV1A"],
  },
  {
    primary_id: "D06JCF",
    names: ["Carboxamide Derivative 7"],
  },
  {
    primary_id: "D06JCO",
    names: ["Palomid-529 Inhaled"],
  },
  {
    primary_id: "D06JCT",
    names: [
      "5-Thia-8,11,14,17-Eicosatetraenoic Acid",
      "CHEMBL460444",
      "BDBM50242353",
      "(8Z,11Z,14Z,17Z)-5-Thia-8,11,14,17-Icosatetraenoic Acid",
    ],
  },
  {
    primary_id: "D06JDK",
    names: ["Perflexane-Lipid Microsphere"],
  },
  {
    primary_id: "D06JDV",
    names: ["K-312"],
  },
  {
    primary_id: "D06JEA",
    names: ["PMID27109571-Compound-20"],
  },
  {
    primary_id: "D06JED",
    names: [
      "Subcuvia",
      "16% Liquid Immunoglobulin Therapy (Subcutaneous/Intramuscular, Primary Immunodeficieny), Baxter Healthcare",
    ],
  },
  {
    primary_id: "D06JEJ",
    names: ["ISIS 9002"],
  },
  {
    primary_id: "D06JFI",
    names: ["ODT-8"],
  },
  {
    primary_id: "D06JFN",
    names: [
      "N-Benzyl-4-Bromo-3-(Morpholinosulfonyl)Benzamide",
      "CHEMBL475493",
      "Cambridge Id 5211146",
      "AC1LL0O9",
      "Oprea1_380259",
      "CBDivE_007294",
      "SCHEMBL5623492",
      "MolPort-002-316-866",
      "BDBM50277135",
      "AKOS008461950",
      "MCULE-3410949273",
      "AB00075261-01",
      "SR-01000198591",
      "SR-01000198591-1",
    ],
  },
  {
    primary_id: "D06JFS",
    names: ["Pyrazolopyrimidine Derivative 2"],
  },
  {
    primary_id: "D06JGH",
    names: ["Pyridoxal Phosphate"],
  },
  {
    primary_id: "D06JHN",
    names: ["VG-WNV"],
  },
  {
    primary_id: "D06JHV",
    names: ["Flumatinib"],
  },
  {
    primary_id: "D06JHW",
    names: ["PMID25666693-Compound-37"],
  },
  {
    primary_id: "D06JKO",
    names: ["Dust Mite Allergen Extract", "Dust Mite Allergen Extract (Sublingual, Allergic Rhinitis)"],
  },
  {
    primary_id: "D06JKV",
    names: ["[111In]SRVII23"],
  },
  {
    primary_id: "D06JLG",
    names: ["AVX-601", "Anti-CMV Vaccine, AlphaVax", "Anti-CMV Vaccine (ArV Vector), AlphaVax"],
  },
  {
    primary_id: "D06JLN",
    names: ["C[-Arg-Gly-Asp-Acpca36-]", "CHEMBL557157"],
  },
  {
    primary_id: "D06JLQ",
    names: ["KIN-100"],
  },
  {
    primary_id: "D06JLX",
    names: ["Measles Vaccine"],
  },
  {
    primary_id: "D06JMS",
    names: ["3-Benzyl-3-Methyl-1-Phenylazetidine-2,4-Dione", "CHEMBL567138"],
  },
  {
    primary_id: "D06JMV",
    names: ["PB-27"],
  },
  {
    primary_id: "D06JNC",
    names: ["CRD-007"],
  },
  {
    primary_id: "D06JNW",
    names: [
      "NSC-106970",
      "33429-83-3",
      "Quercetin 3,4'-Dimethyl Ether",
      "5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)-3-Methoxy-4H-Chromen-4-One",
      "QUERCETIN-3,4'-DIMETHYL ETHER",
      "5,7,3'-Trihydroxy-3,4'-Dimethoxyflavone",
      "CHEBI:70008",
      "3,4'-Dimethoxy-5,7,3'-Trihydroxyflavone",
      "5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)-3-Methoxychromen-4-One",
      "5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxy-Phenyl)-3-Methoxy-Chromen-4-One",
      "4H-1-Benzopyran-4-One, 5,7-Dihydroxy-2-(3-Hydroxy-4-Methoxyphenyl)-3-Methoxy-",
      "NSC106970",
      "3-O-Methyltamarixetin",
      "AC1NTGVE",
    ],
  },
  {
    primary_id: "D06JOJ",
    names: ["PMID15027864C8d"],
  },
  {
    primary_id: "D06JPB",
    names: [
      "Ergocalciferol",
      "Calciferol",
      "Calciferolum",
      "Condacaps",
      "Condocaps",
      "Condol",
      "Crtron",
      "Crystallina",
      "Daral",
      "Davitin",
      "Decaps",
      "Deltalin",
      "Deratol",
      "Detalup",
      "Diactol",
      "Drisdol",
      "Ercalciol",
      "Ergocalciferolo",
      "Ergocalciferols",
      "Ergocalciferolum",
      "Ergorone",
      "Ertron",
      "Fortodyl",
      "Geltabs",
      "Haliver",
      "Hyperkil",
      "Infron",
      "Metadee",
      "Mulsiferol",
      "Mykostin",
      "Ostelin",
      "Radiostol",
      "Radstein",
      "Radsterin",
      "Rodinec",
      "Sterogyl",
      "Vigantol",
      "Viostdrol",
      "Viosterol",
      "CALCIFEROL IN A GELATIN MATRIX",
      "Component Of Geltabs Vitamin D",
      "Davitamon D",
      "Divit Urto",
      "Ergocalciferolo [DCIT]",
      "Ergosterol Activated",
      "Ergosterol Irradiated",
      "Geltabs Vitamin D",
      "Irradiated Ergosterol",
      "Oleovitamin D",
      "Rodine C",
      "Synthetic Vitamin D",
      "Viosterol In Oil",
      "Calciferon 2",
      "Mina D2",
      "Oleovitamin D2",
      "VITAMIN D2",
      "VITAMIN_D2",
      "Vitamina D2",
      "Buco-D",
      "Calciferol (TN)",
      "Calciferol (Vitamin D2)",
      "D-Arthin",
      "D-Tracetten",
      "De-Rat Concentrate",
      "Dee-Osterol",
      "Dee-Ron",
      "Dee-Ronal",
      "Dee-Roual",
      "Deltalin (TN)",
      "Drisdol (TN)",
      "Ergocalciferol (D2)",
      "Ergocalciferol: Vitamin D",
      "Ergocalciferolum [INN-Latin]",
      "Ergosterol, Irradiated",
      "Hi-Deratol",
      "Novovitamin-D",
      "Oleovitamin D, Synthetic",
      "Shock-Ferol",
      "Shock-Ferol Sterogyl",
      "Sorex C.R",
      "Uvesterol-D",
      "Vio-D",
      "Vitamin D-2",
      "Vitamin-D2",
      "Vitavel-D",
      "Ergocalciferol (JP15/USP)",
      "Ergocalciferol [INN:BAN:JAN]",
      "Sorex C.R.",
      "VITAMIN D2 WATER DISPERSABLE U.S.P.",
      "CALCIFEROL, U.S.P.",
      "Irradiated Ergosta-5,7,22-Trien-3-Beta-Ol",
      "Irradiated Ergosta-5,7,22-Trien-3.beta.-Ol",
      "(+)-Vitamin D2",
      "(3-Beta,5Z,7E,22E)-9,10-Secoergosta-5,7,10,(19),22-Tetraen-3-Ol",
      "(3S,5Z,7E,14xi,17alpha,22E)-9,10-Secoergosta-5,7,10,22-Tetraen-3-Ol",
      "(3S,5Z,7E,22E)-9,10-Secoergosta-5,7,10(19),22-Tetraen-3-Ol",
      "(3S,5Z,7E,22E)-9,10-Secoergosta-5,7,10,22-Tetraen-3-Ol",
      "(3beta,5Z,7E,22E)-9,10-Secoergosta-5,7,10(19),22-Tetraen-3-Ol",
      "(5Z,7E,22E)-(3S)-9,10-Seco-5,7,10(19),22-Ergostatetraen-3-Ol",
      "(5Z,7E,22E)-(3S)-9,10-Secoergosta-5,7,10(19),22-Tetraen-3-Ol",
      "7E677DC1-E1C4-4FC5-8F4A-BCE1857F7E87",
      "9,10,Secoergosta-5,7,10(19),22-Tetraen 3.beta.-Ol;9,10-Seco(5Z,7E,22E)-5,7,10(19),22-Ergostatetraen-3-Ol",
      "9,10-Secoergosta-5,7,10(19),22-Tetraen-3-Beta-Ol",
    ],
  },
  {
    primary_id: "D06JPG",
    names: ["Mutations In The Dystrophin Gene"],
  },
  {
    primary_id: "D06JPP",
    names: ["LG-121071"],
  },
  {
    primary_id: "D06JPQ",
    names: ["DY131", "DY-131", "GSK9089"],
  },
  {
    primary_id: "D06JPY",
    names: ["AZD2995"],
  },
  {
    primary_id: "D06JQM",
    names: ["SPP-800"],
  },
  {
    primary_id: "D06JQS",
    names: ["HuM195/RGel"],
  },
  {
    primary_id: "D06JRD",
    names: [
      "Metamizole",
      "Dipyrone",
      "Analgin",
      "Metamizole Sodium",
      "Methampyrone",
      "Novalgin",
      "Sulpyrine",
      "68-89-3",
      "Neomelurbrin",
      "Methylmelubrin",
      "Algocalmin",
      "Optalgin",
      "Novalgetol",
      "Sulpyrin",
      "Pyralgin",
      "Narone",
      "Novaminsulfone",
      "Novamidazophen",
      "Nevralgina",
      "Pharmalgine",
      "Algopyrine",
      "Farmolisina",
      "Pyretin",
      "Keypyrone",
      "Analgine",
      "Noveltex",
      "Fevonil",
      "Diprofarn",
      "Dimethone",
      "Alginodia",
      "Pyrojec",
      "Paralgin",
      "Metilon",
      "Metapyrin",
      "Gifaril",
      "Bonpyrin",
      "Sulpin",
      "Conmel",
      "Barone",
      "Neo-Melubrine",
      "Di-Podil",
      "Aminopyrine Sodium Sulfonate",
      "Novaminsulfonum",
      "Novaminsulfon",
      "Metamizolum Natricum",
    ],
  },
  {
    primary_id: "D06JRV",
    names: ["PMID25991433-Compound-F2"],
  },
  {
    primary_id: "D06JTW",
    names: ["FM-VP4"],
  },
  {
    primary_id: "D06JTZ",
    names: ["Contepo"],
  },
  {
    primary_id: "D06JUE",
    names: ["NN8555"],
  },
  {
    primary_id: "D06JUN",
    names: ["[Tyr4]Bombesin"],
  },
  {
    primary_id: "D06JUQ",
    names: ["ISIS 102629"],
  },
  {
    primary_id: "D06JUR",
    names: ["Rogletimide"],
  },
  {
    primary_id: "D06JVR",
    names: ["N-(5-Phenyl-1,3,4-Thiadiazol-2-Yl)Sulfamide", "CHEMBL475776"],
  },
  {
    primary_id: "D06JVU",
    names: ["A-68552"],
  },
  {
    primary_id: "D06JVV",
    names: ["ALD-151"],
  },
  {
    primary_id: "D06JVZ",
    names: ["SR-59026"],
  },
  {
    primary_id: "D06JWO",
    names: [
      "KT-362",
      "3-[2-(3,4-Dimethoxyphenyl)Ethylamino]-1-(2-Thia-6-Azabicyclo[5.4.0]undeca-7,9,11-Trien-6-Yl)Propan-1-One",
      "ACMC-20m87t",
    ],
  },
  {
    primary_id: "D06JWT",
    names: ["Des-AA1,2,5-[D-Trp8,IAmp9,(NalphaMe)Thr12]SRIF", "CHEMBL268448"],
  },
  {
    primary_id: "D06JXH",
    names: ["Met-Lys-Bradykinin"],
  },
  {
    primary_id: "D06JYM",
    names: ["Ac-WVTHQLAGLLSQSGGVVRKNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D06JYY",
    names: ["3-[3-(4-Methoxybenzyl)Naphthalen-2-Yl]pyridine", "CHEMBL493082"],
  },
  {
    primary_id: "D06JZQ",
    names: ["4-Methyl-6-(2-Methylprop-1-Enyl)Pyridin-2-Amine", "CHEMBL464556", "1146620-61-2", "SCHEMBL13040624"],
  },
  {
    primary_id: "D06KAD",
    names: ["CCNCASKWCRDHSRCC", "CHEMBL524481"],
  },
  {
    primary_id: "D06KBO",
    names: ["PGD2"],
  },
  {
    primary_id: "D06KBT",
    names: ["PMID25399762-Compound-Table 6-13"],
  },
  {
    primary_id: "D06KCF",
    names: ["Mavrilimumab"],
  },
  {
    primary_id: "D06KCM",
    names: [
      "CUV-3",
      "Anticancer Antibodies, Abiogen",
      "Anti HER2 Monoclonal Antibody (Cancer), Abiogen",
      "Anti ICAM-1 Monoclonal Antibody (Cancer), Abiogen;Anti CD-54 Monoclonal Antibody (Multiple Myeloma/Ocular Melanoma), Abiogen",
    ],
  },
  {
    primary_id: "D06KCU",
    names: ["MLN1202"],
  },
  {
    primary_id: "D06KCW",
    names: [
      "6-(2-HYDROXY-CYCLOPENTYL)-7-OXO-HEPTANAMIDINE",
      "GR157368",
      "1qhr",
      "AC1O43NS",
      "DB06861",
      "(6R)-6-[(1R,2S)-2-Hydroxycyclopentyl]-7-Oxoheptanimidamide",
    ],
  },
  {
    primary_id: "D06KDP",
    names: ["Ganglioside Gm1"],
  },
  {
    primary_id: "D06KDU",
    names: ["Tetrabromocinnamic Acid"],
  },
  {
    primary_id: "D06KEJ",
    names: ["PV903"],
  },
  {
    primary_id: "D06KFR",
    names: ["RP-73163", "CHEMBL434418", "BDBM50106700"],
  },
  {
    primary_id: "D06KFU",
    names: ["Isoxazoles And Isoxazoline Derivative 4"],
  },
  {
    primary_id: "D06KFW",
    names: ["Pokeweed Antiviral Protein"],
  },
  {
    primary_id: "D06KFX",
    names: ["C[L-Phe-D-Pro-L-Phe-L-Trp]", "CHEMBL506616", "CJ-15208", "SCHEMBL12368055"],
  },
  {
    primary_id: "D06KGC",
    names: ["AEZS-120", "Cancer Vaccine (Bacterial Vector), University Of Wurzburg/AEterna Zentaris"],
  },
  {
    primary_id: "D06KGI",
    names: ["TG-0054", "CXCR4 Binding Inhibitor/Cell Mobilizer (Iv, Stem Cell Transplant), TaiGen Biotechnology"],
  },
  {
    primary_id: "D06KGV",
    names: [
      "HUMAN CORTICOTROPIN-RELEASING HORMONE",
      "CRF",
      "Corticorelin",
      "HCRH",
      "Xerecept",
      "Corticorelin Acetate",
      "Corticorelin)",
      "Corticorelin (Human)",
      "Corticotropin-Releasing Factor",
      "NEU-3002",
      "CRF, NTI/Salk Institute",
      "Corticotropin-Releasing Factor (Human)",
      "Corticotropin-Releasing Factor, Neurobiological Laboratories",
      "H-Ser-Glu-Glu-Pro-Pro-Ile-Ser-Leu-Asp-Leu-Thr-Phe-His-Leu-Leu-Arg-Glu-Val-Leu-Glu-Met-Ala-Arg-Ala-Glu-Gln-Leu-Ala-Gln-Glu-Ala-His-Ser-Asn-Arg-Lys-Leu-Met-Glu-Ile-Ile-NH2",
    ],
  },
  {
    primary_id: "D06KHR",
    names: ["Decavanadate", "Decavanidate"],
  },
  {
    primary_id: "D06KHW",
    names: ["PMID21939274C1"],
  },
  {
    primary_id: "D06KHX",
    names: ["GPR109A Agonists, Merck"],
  },
  {
    primary_id: "D06KIJ",
    names: [
      "AG-858",
      "Chronic Myeloid Leukemia Therapy, Antigenics",
      "HSP-70 Cancer Vaccine, Antigenics",
      "HSPPC-70 (Cancer)",
      "HSPPC-70-C",
    ],
  },
  {
    primary_id: "D06KJG",
    names: ["CLX-0301"],
  },
  {
    primary_id: "D06KJQ",
    names: ["BI 201335"],
  },
  {
    primary_id: "D06KJR",
    names: [
      "AcAsp-Glu-Dif-Glu-Cha-Fab",
      "CHEMBL349186",
      "AC1L9WIG",
      "BDBM50110121",
      "Ac-L-Asp-L-Glu-3,3-Diphenyl-L-Ala-L-Glu-3-Cyclohexyl-L-Ala-[(3S)-2-Oxo-3-(2,2-Difluoroethyl)-BetaAla-]-OH",
      "3-[2-(2-{2-[2-(2-Acetylamino-3-Carboxy-Propionylamino)-4-Carboxy-Butyrylamino]-3,3-Diphenyl-Propionylamino}-4-Carboxy-Butyrylamino)-3-Cyclohexyl-Propionylamino]-5,5-Difluoro-2-Oxo-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D06KKL",
    names: [
      "PulmoXen",
      "Deoxyribonuclease (PolyXen, Cystic Fibrosis), Lipoxen/Pharmsynthez",
      "Polysialylated Dornase (PolyXen, Cystic Fibrosis), Lipoxen/Pharmsynthez",
    ],
  },
  {
    primary_id: "D06KKN",
    names: ["LY2109761"],
  },
  {
    primary_id: "D06KKO",
    names: ["PMID26815044-Compound-50"],
  },
  {
    primary_id: "D06KKS",
    names: [
      "Cefradine",
      "Anspor",
      "Cefradin",
      "Cefradina",
      "Cefradinum",
      "Cephradin",
      "Cephradine",
      "Eskacef",
      "Sefril",
      "Velosef",
      "CEPHRADINE SODIUM",
      "SKF D 39304",
      "SQ 11436",
      "VELOSEF 125",
      "VELOSEF 250",
      "VELOSEF 500",
      "Anspor (TN)",
      "Cefradina [INN-Spanish]",
      "Cefradinum [INN-Latin]",
      "Cephradine (USP)",
      "Cephradine (Anhydrous)",
      "Cephradine [USAN:BAN]",
      "SQ-11436",
      "SQ-22022",
      "Velosef (TN)",
      "Cefradine (JAN/INN)",
      "SK&F D-39304",
      "SK-D-39304",
      "(6R,7R)-7-((R)-2-Amino-2-(1,4-Cyclohexadien-1-Yl)Acetamido)-3-Methyl-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2R)-2-Amino-2-Cyclohexa-1,4-Dien-1-Ylacetyl]amino]-3-Methyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-Amino-2-Cyclohexa-1,4-Dien-1-Ylacetyl]amino}-3-Methyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-(D-2-Amino-2-(1,4-Cyclohexadien-1-Yl)Acetamido)-3-Methyl-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)-Oct-2-Ene-2-Caboxylic Acid",
      "7-(D-2-Amino-2-(1,4-Cyclohexadienyl)Acetamide)Desacetoxycephalosporanicacid",
      "7beta-[(2R)-2-(Cyclohexa-1,4-Dienyl)-2-Phenylacetamido]-3-Methyl-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D06KLD",
    names: ["HTS-519 Insert"],
  },
  {
    primary_id: "D06KLJ",
    names: ["STAMP"],
  },
  {
    primary_id: "D06KLQ",
    names: ["GNF-PF-1550"],
  },
  {
    primary_id: "D06KMH",
    names: [
      "APN-401",
      "APN-411",
      "E3 Ubiquitin Protein Ligase Cbl-B Silencing SiRNA Therapeutics (Cancer)",
      "Cbl-B Modulators (Cancer), Apeiron Biologics",
      "E3 Ubiquitin Ligase Casitas B Cell Lymphoma-B Silencing SiRNA Therapeutics (Cancer), Apeiron Biologics",
      "E3 Ubiquitin Protein Ligase Cbl-B Silencing SiRNA Therapeutics (Cancer), Apeiron Biologics",
    ],
  },
  {
    primary_id: "D06KMJ",
    names: ["MRS5151"],
  },
  {
    primary_id: "D06KMO",
    names: [
      "Brocresine",
      "Brocresin",
      "555-65-7",
      "3-(Aminooxymethyl)-6-Bromphenol",
      "Brocresinum [INN-Latin]",
      "Brocresina [INN-Spanish]",
      "NSD 1055",
      "Brocresine [USAN:INN:BAN]",
      "UNII-11F6O06WN0",
      "CL 54998",
      "NSD-1055",
      "Alpha-(Aminooxy)-6-Bromo-M-Cresol",
      "C7H8BrNO2",
      "5-((Aminooxy)Methyl)-2-Bromophenol",
      "O-)4-Brom-3-Hydroxybenzyl)Hydroxylamin",
      "BRN 1945736",
      "Phenol, 5-((Aminooxy)Methyl)-2-Bromo-",
      "4-Bromo-3-Hydroxybenzyloxyamine",
      "QNWOSJAGFSUDFE-UHFFFAOYSA-N",
      "11F6O06WN0",
      "5-[(Aminooxy)Methyl]-2-Bromophenol",
      "M-CRESOL, Alpha-(AMINOOXY)-6-BROMO-",
      "Brocresina",
    ],
  },
  {
    primary_id: "D06KMP",
    names: [
      "2,6-DICARBOXYNAPHTHALENE",
      "2,6-Naphthalenedicarboxylic Acid",
      "Naphthalene-2,6-Dicarboxylic Acid",
      "1141-38-4",
      "2,6-NAPHTHALIC ACID",
      "UNII-K3C4DYZ29O",
      "2,6-Naphthalene Dicarboxylic Acid",
      "EINECS 214-527-0",
      "NSC 96410",
      "K3C4DYZ29O",
      "CHEBI:44460",
      "RXOHFPCZGPKIRD-UHFFFAOYSA-N",
      "MFCD00004105",
      "FR-1318",
      "W-108606",
      "DSSTox_CID_9211",
      "ACMC-2099kj",
      "AC1Q73RL",
      "NCIOpen2_007124",
      "DSSTox_RID_78711",
      "DSSTox_GSID_29211",
      "SCHEMBL18758",
      "AC1L23O7",
      "2,6-Naphthalenedicarboxylicacid",
      "2,6-Naphthalendicarboxylic Acid",
      "Jsp001070",
      "CHEMBL1205452",
      "DTXSID5029211",
      "CTK0H6341",
    ],
  },
  {
    primary_id: "D06KNE",
    names: ["INCB15050"],
  },
  {
    primary_id: "D06KNJ",
    names: ["PB-127", "CardioSphere", "Ultrasound Contrast Agent (Myocardial Perfusion), Point Biomedical"],
  },
  {
    primary_id: "D06KNK",
    names: [
      "TDZD-8",
      "327036-89-5",
      "4-Benzyl-2-Methyl-1,2,4-Thiadiazolidine-3,5-Dione",
      "GSK-3beta Inhibitor I",
      "TDZD 8",
      "1,2,4-Thiadiazolidine-3,5-Dione, 2-Methyl-4-(Phenylmethyl)-",
      "MFCD04973552",
      "NP 01139",
      "AK-48153",
      "4-Benzyl-2-Methyl-[1,2,4]thiadiazolidine-3,5-Dione",
      "1,2,4-Thiadiazolidine-3,5-Dione,2-Methyl-4-(Phenylmethyl)-",
      "GSK-3   Inhibitor I",
      "SCHEMBL139834",
      "GTPL5977",
      "CHEMBL284861",
      "BDBM7781",
      "CTK4G9152",
      "ZINC27361",
      "AOB6176",
      "EX-A109",
      "DTXSID30399590",
      "JDSJDASOXWCHPN-UHFFFAOYSA-N",
      "MolPort-003-844-896",
      "HMS3229G12",
      "A Inhibitor I, TDZD-8",
    ],
  },
  {
    primary_id: "D06KNV",
    names: [
      "[3-(3,4-Dichloro-Phenyl)-Indan-1-Yl]-Methyl-Amine",
      "CHEMBL296602",
      "AC1L1GIZ",
      "SCHEMBL11128740",
      "BDBM50021226",
      "3-(3,4-Dichlorophenyl)-2,3-Dihydro-N-Methyl-1H-Inden-1-Amine",
    ],
  },
  {
    primary_id: "D06KOH",
    names: ["4-(2-Cyclohexylethoxy) Aniline Derivative 3"],
  },
  {
    primary_id: "D06KOJ",
    names: ["1,3,7-Trihydroxyacridone"],
  },
  {
    primary_id: "D06KOL",
    names: [
      "Napitane Mesilate",
      "A-75200",
      "ABT-200",
      "Napitane Mesilate < Prop INNM",
      "Rac-1-[5,6-(Methylenedioxy)-1,2,3,4-Tetrahydronaphth-1(R*)-Ylmethyl]-3(R*)-Phenylpyrrolidine Methanesulfonate",
      "Rac-3(R*)-Phenyl-1-[6,7,8,9-Tetrahydronaphtho[1,2-D]-1,3-Dioxol-6(R*)-Ylmethyl]pyrrolidine Methanesulfonate",
    ],
  },
  {
    primary_id: "D06KOM",
    names: ["DN24-02"],
  },
  {
    primary_id: "D06KOR",
    names: ["SWT-05104"],
  },
  {
    primary_id: "D06KOT",
    names: [
      "Delta-Amino Valeric Acid",
      "DELTA-AMINO VALERIC ACID",
      "4-Carboxybutylammonium",
      "AC1L9NAD",
      "4-Carboxybutan-1-Aminium",
      "(5-Hydroxy-5-Oxopentyl)Azanium",
      "DB02068",
    ],
  },
  {
    primary_id: "D06KPK",
    names: [
      "(R)-Mesopram",
      "(R)-MESOPRAM",
      "[R]-Mesopram",
      "(5R)-5-(4-Methoxy-3-Propoxyphenyl)-5-Methyl-1,3-Oxazolidin-2-One",
      "UNII-0RB5T277IY",
      "0RB5T277IY",
      "CHEMBL603830",
      "Daxalipram [INN]",
      "1xm6",
      "AC1L9MU1",
      "SCHEMBL450823",
      "PCCPERGCFKIYIS-AWEZNQCLSA-N",
      "MolPort-023-276-648",
      "ZINC3791675",
      "BDBM50308512",
      "AKOS024457404",
      "DB01647",
      "API0008048",
      "FT-0671033",
      "(R)-5-(4-Methoxy-3-Propoxyphenyl)-5-Methyl-2-Oxazolidinone",
    ],
  },
  {
    primary_id: "D06KPR",
    names: ["CER-002"],
  },
  {
    primary_id: "D06KPV",
    names: [
      "CreaVax-RCC",
      "Autologous DC Vaccines (Renal Cell Carcinoma), CreaGene",
      "Autologous Dendritic Cell Vaccines (Renal Cell Carcinoma), CreaGene",
    ],
  },
  {
    primary_id: "D06KPW",
    names: ["U-103017"],
  },
  {
    primary_id: "D06KQD",
    names: ["N-Hydroxy-N-Methyl-3-Naphthalen-2-Yl-Propionamide", "CHEMBL367401", "ZINC28086558"],
  },
  {
    primary_id: "D06KQG",
    names: ["HER-2-Targeting CAR T Cells"],
  },
  {
    primary_id: "D06KQH",
    names: ["4-[4-(Benzyloxy)Piperidino]butyl-3-Fluorobenzoate", "CHEMBL228359", "SCHEMBL4027386"],
  },
  {
    primary_id: "D06KQN",
    names: ["TPI-926"],
  },
  {
    primary_id: "D06KRZ",
    names: ["MC-4232"],
  },
  {
    primary_id: "D06KSA",
    names: ["TK-115339"],
  },
  {
    primary_id: "D06KSF",
    names: ["Tpl2 Kinase Inhibitor"],
  },
  {
    primary_id: "D06KSV",
    names: ["Omigapil"],
  },
  {
    primary_id: "D06KTD",
    names: [
      "2-Hydrazinocarbonyl-Benzenesulfonamide",
      "102169-52-8",
      "2-(Hydrazinecarbonyl)Benzenesulfonamide",
      "2-(Hydrazinocarbonyl)Benzenesulfonamide",
      "2-(Hydrazinecarbonyl)Benzene-1-Sulfonamide",
      "Benzoic Acid,2-(Aminosulfonyl)-, Hydrazide",
      "Hydrazide Deriv. 6",
      "AC1M8OJR",
      "ACMC-1BW2D",
      "AC1Q54UM",
      "Oprea1_607731",
      "CHEMBL361191",
      "CTK4A0755",
      "BDBM12153",
      "DTXSID30368807",
      "HMS1788K01",
      "ZX-AN012798",
      "ZINC3372954",
      "ALBB-014056",
      "7604AC",
      "STL301522",
      "AKOS000116142",
      "FCH1317207",
      "MCULE-6758898279",
      "2-(Hydrazinylcarbonyl)Benzenesulfonamide",
      "BBV-40193034",
      "Benzoic Acid, 2-(Aminosul",
    ],
  },
  {
    primary_id: "D06KTF",
    names: ["HL-029"],
  },
  {
    primary_id: "D06KTJ",
    names: ["TAK-659"],
  },
  {
    primary_id: "D06KUY",
    names: [
      "ACH-2684",
      "Deldeprevir",
      "UNII-5ZT4SBY0BD",
      "5ZT4SBY0BD",
      "ACH-0142684",
      "Neceprevir [USAN]",
      "Deldeprevir [USAN:INN]",
      "CHEMBL3040582",
      "SCHEMBL13291708",
      "UDMJANYPQWEDFT-ZAWFUYGJSA-N",
      "Cyclopropa(E)Pyrrolo(1,2-A)(1,4)Diazacyclopentadecine-14a(5H)-Carboxamide, N- (Cyclopropylsulfonyl)-6-(2-(3,3-Difluoro-1-Piperidinyl)-2-Oxoethyl)- 1,2,3,6,7,8,9,10,11,13a,14,15,16,16a-Tetradecahydro-2-((7-Methoxy-8-Methyl-2-(4-(1- Methylethyl)-2-Thiazolyl)-4-Quinolinyl)Oxy)-5,16-Dioxo-, (2R,6R,12Z,13aS,14aR,16aS)-",
    ],
  },
  {
    primary_id: "D06KVU",
    names: ["ABP 215"],
  },
  {
    primary_id: "D06KVV",
    names: [
      "2-Hydroxymethyl-Pyrrolidine-3,4-Diol",
      "2-(Hydroxymethyl)-[2R-(2a,3b,4b)]-3,4-Pyrrolidinediol",
      "2-(Hydroxymethyl)-(2R-(2a,3b,4b))-3,4-Pyrrolidinediol",
      "ACMC-20m9e3",
      "ACMC-20m1d3",
      "ACMC-20m9e4",
      "3,4-Pyrrolidinediol,2-(Hydroxymethyl)-, (2S,3S,4R)-",
      "3,4-Pyrrolidinediol,2-(Hydroxymethyl)-, (2R,3R,4S)-",
      "AC1L1B8Z",
      "3,4-Pyrrolidinediol, 2-(Hydroxymethyl)-, (2R,3S,4S)-",
      "SCHEMBL981088",
      "CTK8A3364",
      "1,4-Dideoxy-1,4-Iminopentitol",
      "OQEBIHBLFRADNM-UHFFFAOYSA-N",
      "HMS3373I18",
      "IMR",
      "97058-12-3",
    ],
  },
  {
    primary_id: "D06KVX",
    names: [
      "4-(2-Methoxyphenylethynyl)-2-Methylthiazole",
      "CHEMBL202002",
      "Thiazole, 4-[2-(2-Methoxyphenyl)Ethynyl]-2-Methyl-",
      "SCHEMBL4291588",
      "BDBM50181749",
      "878018-34-9",
    ],
  },
  {
    primary_id: "D06KWA",
    names: [
      "VL-0699",
      "CHEMBL208171",
      "BDBM50185263",
      "2(R,S)-{2-[(2-Acetylaminocinnamoyl)Amino]benzoylamino}-3-Phenylpropionic Acid",
    ],
  },
  {
    primary_id: "D06KWH",
    names: ["DAB-486-IL-2", "153641-26-0"],
  },
  {
    primary_id: "D06KWQ",
    names: ["3-Dodecyl-1,1-Dimethylthiourea", "CHEMBL251521"],
  },
  {
    primary_id: "D06KWU",
    names: ["DWP-09031", "DWJ-301"],
  },
  {
    primary_id: "D06KXA",
    names: ["Antimicrobials"],
  },
  {
    primary_id: "D06KXL",
    names: ["Quinazolinone Derivative 2"],
  },
  {
    primary_id: "D06KXS",
    names: [
      "PT-112",
      "167683-61-6",
      "Thiourea, N-(5-Bromo-2-Pyridinyl)-N'-(2-(2-Chloro-3-Ethoxy-6-Fluorophenyl)Ethyl)-",
      "1-(5-Bromopyridin-2-Yl)-3-[2-(2-Chloro-3-Ethoxy-6-Fluorophenyl)Ethyl]thiourea",
      "N-(2-(2-Chloro-3-Ethoxy-6-Fluorophenethyl))-N'-(2-(5-Bromopyridyl))Thiourea",
      "PETT Deriv. 9",
      "N-[2-(2-Chloro-3-Ethoxy-6-Fluorophenethyl)]-N'-[2-(5-Bromopyridyl)]thiourea",
      "Thiourea, N-(5-Bromo-2-Pyridinyl)-N'-[2-(2-Chloro-3-Ethoxy-6-Fluorophenyl)Ethyl]-",
      "AC1MHDLZ",
      "SCHEMBL6906082",
      "CHEMBL398891",
      "BDBM2859",
      "LY300046HCl Analog 10",
      "DTXSID70168348",
    ],
  },
  {
    primary_id: "D06KYM",
    names: [
      "N-Methyl-N-(Pyridin-3-Ylmethyl)Ethanamine",
      "CHEMBL60792",
      "3-(N-Ethyl-N-Methylaminomethyl)Pyridine",
      "90565-29-0",
      "SCHEMBL6674571",
      "Methylethyl 3-Pyridinylmethylamine",
      "BDBM50115825",
      "Ethyl-Methyl-Pyridin-3-Ylmethyl-Amine",
    ],
  },
  {
    primary_id: "D06KYN",
    names: [
      "Arbutamine",
      "Arbutamina",
      "Arbutaminum",
      "Genesa",
      "Arbutamina [INN-Spanish]",
      "Arbutamine [INN:BAN]",
      "Arbutaminum [INN-Latin]",
      "4-[(1R)-1-Hydroxy-2-[4-(4-Hydroxyphenyl)Butylamino]ethyl]benzene-1,2-Diol",
      "4-[(1R)-1-Hydroxy-2-{[4-(4-Hydroxyphenyl)Butyl]amino}ethyl]benzene-1,2-Diol",
      "4-[1-Hydroxy-2-[4-(4-Hydroxyphenyl)Butylamino]ethyl]benzene-1,2-Diol",
    ],
  },
  {
    primary_id: "D06KYP",
    names: ["8-Bromo-9-Isopropyl-9H-Adenine", "CHEMBL446264", "BDBM50256895"],
  },
  {
    primary_id: "D06KYU",
    names: ["Rycals"],
  },
  {
    primary_id: "D06KZB",
    names: ["Aminopyridines", "Aminopyridines (Malaria)"],
  },
  {
    primary_id: "D06KZL",
    names: ["AG140699"],
  },
  {
    primary_id: "D06LAN",
    names: ["BlockAide/CR"],
  },
  {
    primary_id: "D06LAX",
    names: ["PGI-02749"],
  },
  {
    primary_id: "D06LBL",
    names: ["Acetoacetic Acid", "Diacetic Acid", "Acetoacetate"],
  },
  {
    primary_id: "D06LBO",
    names: [
      "Croconazole",
      "Croconazole Hydrochloride",
      "Pilzcin",
      "77174-66-4",
      "Cloconazole HCl",
      "UNII-I0WFH323HZ",
      "Croconazole Hydrochloride [JAN]",
      "Croconazol Clorhidrato [Spanish]",
      "710674-S",
      "I0WFH323HZ",
      "1-(1-O-(M-(Chlorobenzyloxy)Phenyl)Vinyl)-1H-Imidazole Hydrochloride",
      "1-(1-(2-((3-Chlorobenzyl)Oxy)Phenyl)Vinyl)-1H-Imidazole Hydrochloride",
      "1-(1-(2-((3-Chlorophenyl)Methoxy)Phenyl)Ethenyl)-1H-Imidazole Hydrochloride",
      "1-(1-(2-((3-Clorobenzil)Oxi)Fenil)Vinil)-1H-Imidazol Monoclorhidrato",
      "1-(1-(2-((3-Clorofenil)Metoxi)Fenil)Etenil)-1H-Imidazol Monoclorhid",
    ],
  },
  {
    primary_id: "D06LBV",
    names: ["E-4767"],
  },
  {
    primary_id: "D06LBZ",
    names: ["MVA-BN JEV"],
  },
  {
    primary_id: "D06LCB",
    names: ["Thiazole Carboxamide Derivative 9"],
  },
  {
    primary_id: "D06LCK",
    names: ["2-(N-Pyrrolidinyl)-3'-Fluoropropiophenone", "CHEMBL599028", "BDBM50308116"],
  },
  {
    primary_id: "D06LCS",
    names: ["Legionella Pneumophila Vaccine"],
  },
  {
    primary_id: "D06LCW",
    names: ["KIN-4044"],
  },
  {
    primary_id: "D06LDC",
    names: ["Palonidipine Hydrochloride"],
  },
  {
    primary_id: "D06LDT",
    names: ["Segesterone Acetate And Ethinyl Estradiol Vaginal System"],
  },
  {
    primary_id: "D06LEA",
    names: ["MER-102"],
  },
  {
    primary_id: "D06LEC",
    names: ["LPD-608"],
  },
  {
    primary_id: "D06LED",
    names: [
      "7,8-Dihydroxy-4-Phenyl-2H-Chromen-2-One",
      "842-01-3",
      "7,8-Dihydroxy-4-Phenylcoumarin",
      "7,8-Dihydroxy-4-Phenylchromen-2-One",
      "2zvj",
      "AC1NTWI0",
      "AC1Q78GB",
      "Oprea1_803491",
      "IFLab1_001650",
      "SCHEMBL1181304",
      "CHEMBL1233867",
      "4-Phenyl-7,8-Dihydroxycoumarin",
      "CTK5F1973",
      "4-Phenyl-7,8-Dihydroxycoumarine",
      "DTXSID10419942",
      "MolPort-000-852-912",
      "JRVIIPJSVKTPBK-UHFFFAOYSA-N",
      "ZINC406883",
      "HMS1416K22",
      "DNDI1417012",
      "ALBB-015578",
      "BBL029555",
      "0375AF",
      "STL372126",
      "AKOS005175244",
      "MCULE-8639896630",
      "DB08049",
      "7,8-Dihydroxy-4-Phenyl-Chromen-2-One",
      "7,8-DIHYDROXY-4-PHENYL COUMARIN",
    ],
  },
  {
    primary_id: "D06LEF",
    names: ["Pyrazino[2,1-A]isoquinolin Derivative 2"],
  },
  {
    primary_id: "D06LEG",
    names: ["Influenza A Virus H5N1 Vaccine"],
  },
  {
    primary_id: "D06LEJ",
    names: ["Lu-AA44608"],
  },
  {
    primary_id: "D06LEZ",
    names: ["Benzene Sulfonamide Derivative 16"],
  },
  {
    primary_id: "D06LFV",
    names: ["NM-702"],
  },
  {
    primary_id: "D06LFZ",
    names: ["L-Glucose"],
  },
  {
    primary_id: "D06LGC",
    names: ["ZD-2486"],
  },
  {
    primary_id: "D06LGZ",
    names: ["ET-007"],
  },
  {
    primary_id: "D06LHE",
    names: ["Inactivated H7N7 Influenza Vaccine"],
  },
  {
    primary_id: "D06LHG",
    names: ["FENBUFEN"],
  },
  {
    primary_id: "D06LHH",
    names: [
      "L-756568",
      "CHEMBL96097",
      "BDBM50092097",
      "(S)-2-Benzenesulfonylamino-3-{[5-(Piperidin-4-Ylmethoxy)-1H-Indole-2-Carbonyl]-Amino}-Propionic Acid",
      "2-Benzenesulfonylamino-3-{[5-(Piperidin-4-Ylmethoxy)-1H-Indole-2-Carbonyl]-Amino}-Propionic Acid(L-756568)",
    ],
  },
  {
    primary_id: "D06LHI",
    names: ["GCH-01"],
  },
  {
    primary_id: "D06LHK",
    names: ["2X-111"],
  },
  {
    primary_id: "D06LHU",
    names: ["Iodamide"],
  },
  {
    primary_id: "D06LHV",
    names: ["PMID25656651-Compound-34a"],
  },
  {
    primary_id: "D06LIA",
    names: ["N,4-Dimethyl-3,4-Dihydroquinazolin-2-Amine"],
  },
  {
    primary_id: "D06LIQ",
    names: ["Cyclohexyl Carbamate Derivative 8"],
  },
  {
    primary_id: "D06LJG",
    names: ["BURKE-104", "BLU-104"],
  },
  {
    primary_id: "D06LJL",
    names: ["PMID16451062C46"],
  },
  {
    primary_id: "D06LKA",
    names: [
      "L2-05",
      "Alanine Racemase Inhibitors (Mycobacterium Tuberculosis Infection)",
      "L2-06",
      "L2-10",
      "L2-12",
      "L2-13",
      "L2-16",
      "Alanine Racemase Inhibitors (Mycobacterium Tuberculosis Infection), L2 Diagnostics",
      "L2-04, L2 Diagnostics",
      "D-Alanine Pathway Modulators (Microbial Infection), L2 Diagnostics",
    ],
  },
  {
    primary_id: "D06LKH",
    names: ["Gly-Arg-Gly-Asp-Ser"],
  },
  {
    primary_id: "D06LKM",
    names: [
      "PNU-282987",
      "Benzamide, N-(3R)-1-Azabicyclo[222]oct-3-Yl-4-Chloro-",
      "PNU282987",
      "123464-89-1",
      "711085-63-1",
      "PNU 282987",
      "UNII-27M563MU5U",
      "N-(3R)-1-Azabicyclo[222]oct-3-Yl-4-Chlorobenzamide",
      "CHEMBL177611",
      "27M563MU5U",
      "Benzamide, N-(3R)-1-Azabicyclo(222)Oct-3-Yl-4-Chloro-",
      "CHEMBL554984",
      "PNU-282,987",
      "PNU-282987 Free Base",
      "SCHEMBL677422",
      "PNU282987HCl",
      "GTPL3988",
      "CHEBI:92508",
      "CTK2H4027",
      "MolPort-023-219-197",
      "MolPort-003-983-664",
      "HMS3268J18",
      "ZINC3990028",
      "BDBM50161764",
      "BDBM50161753",
      "ABP000998",
      "AKOS016339643",
      "AKOS024457037",
      "KS-1399",
      "API0008100",
      "N-((3R)-1-Azabicyclo(222)Oct-3-Yl)-4-Chlorobenzamide Hydrochloride",
      "ISIS 25302",
    ],
  },
  {
    primary_id: "D06LKP",
    names: ["OTI-050"],
  },
  {
    primary_id: "D06LLF",
    names: ["6,7-Dimethoxy-3-((E)-Styryl)-Quinoline", "CHEMBL70149", "ZINC3834019", "BDBM50039041"],
  },
  {
    primary_id: "D06LLJ",
    names: ["ISIS 119283"],
  },
  {
    primary_id: "D06LLS",
    names: ["PMID25684022-Compound-WO2009153313"],
  },
  {
    primary_id: "D06LLT",
    names: ["PMID25399719-Compound-17"],
  },
  {
    primary_id: "D06LLY",
    names: ["Sulfonamide-Thiadiazole Derivative 2"],
  },
  {
    primary_id: "D06LMS",
    names: ["P-Coumaric Acid Derivative 4"],
  },
  {
    primary_id: "D06LNL",
    names: ["Iodyl-Benzene"],
  },
  {
    primary_id: "D06LNW",
    names: ["Fidaxomicin", "Dificid (TN)"],
  },
  {
    primary_id: "D06LNX",
    names: [
      "DL-Benzylsuccinic Acid",
      "2-Benzylsuccinic Acid",
      "884-33-3",
      "Benzylsuccinic Acid",
      "2-Benzylbutanedioic Acid",
      "Alpha-Benzylsuccinic Acid",
      "36092-42-9",
      "(Phenylmethyl)Butanedioic Acid",
      "Beta-Carboxybenzenebutanoic Acid",
      "D,L-Benzylsuccinic Acid",
      "Butanedioic Acid,2-(Phenylmethyl)-",
      "NSC20708",
      ".alpha.-Benzylsuccinic Acid",
      "CHEMBL151284",
      "CHEBI:16054",
      "GTOFKXZQQDSVFH-UHFFFAOYSA-N",
      "MFCD00055798",
      "Benzenebutanoic Acid, .beta.-Carboxy-",
      "2-Benzyl-Succinic Acid",
      "NSC-20708",
      "NSC 20708",
      "Mono Benzyl Succinic Acid",
      "AC1L1GVE",
      "2-Benzylsuccinic Acid #",
    ],
  },
  {
    primary_id: "D06LOB",
    names: ["ISIS 11223"],
  },
  {
    primary_id: "D06LOQ",
    names: [
      "ARZOXIFENE",
      "Arzoxifene",
      "182133-25-1",
      "UNII-E569WG6E60",
      "LY 353381",
      "E569WG6E60",
      "2-(4-Methoxyphenyl)-3-[4-(2-Piperidin-1-Ylethoxy)Phenoxy]-1-Benzothiophen-6-Ol",
      "2-(4-Methoxyphenyl)-3-(4-(2-(Piperidin-1-Yl)Ethoxy)Phenoxy)Benzo[b]thiophen-6-Ol",
      "Arzoxifene [INN]",
      "LY-353381",
      "SCHEMBL285277",
      "CHEMBL226267",
      "BDBM19442",
      "DTXSID10171255",
      "ZINC1544683",
      "AC1L4522",
      "AN-538",
      "AKOS030631785",
      "SB19713",
      "KB-05502",
      "FT-0751607",
      "124708-EP2295426A1",
      "124708-EP2295427A1",
      "124708-EP2292592A1",
      "124708-EP2292576A2",
      "Benzo(B)Thiophene-6-Ol, 2-(4-Methoxyphenyl)-3-(4-(2-(1-Piperidinyl)",
    ],
  },
  {
    primary_id: "D06LPR",
    names: [
      "(4-Nitro-1H-Pyrazol-1-Yl)(Phenyl)Methanone",
      "BAS 02052938",
      "AC1LDSCA",
      "N-Benzoylpyrazole Deriv., 5",
      "4-Nitropyrazolyl Phenyl Ketone",
      "CHEMBL244908",
      "1-Benzoyl-4-Nitro-1H-Pyrazole",
      "ZINC37183",
      "BDBM23703",
      "AKOS000577075",
      "(4-Nitropyrazol-1-Yl)-Phenylmethanone",
      "MCULE-3158742884",
      "ST033219",
      "(4-Nitro-Pyrazol-1-Yl)-Phenyl-Methanone",
      "SR-01000312627",
    ],
  },
  {
    primary_id: "D06LPT",
    names: ["STI-003"],
  },
  {
    primary_id: "D06LQF",
    names: ["131-I-MIP-1095"],
  },
  {
    primary_id: "D06LQH",
    names: ["N-Propylnorapomorphine"],
  },
  {
    primary_id: "D06LQY",
    names: [
      "BI-1",
      "AC1L2R2F",
      "145551-68-4",
      "SCHEMBL8964991",
      "CHEMBL209801",
      "1-(7-(2-Hydroxyethyl)Dodecahydro-3a-Methyl-1H-Benz(E)Inden-3-Yl)Ethanone",
      "3S-(3alpha,3aalpha,5abeta,7alpha,9aalpha,9bbeta)-1-(7-(2-Hydroxyethyl)Dodecahydro-3a-Methyl-1H-Benz(E)Inden-3-Yl)Ethanone",
      "1-[(3S,3aS,5aR,7R,9aR,9bS)-7-(2-Hydroxyethyl)-3a-Methyl-1,2,3,4,5,5a,6,7,8,9,9a,9b-Dodecahydrocyclopenta[a]naphthalen-3-Yl]ethanone",
      "Ethanone, 1-(Dodecahyro-7-(2-Hydroxyethyl)-3a-Methyl-1H-Benz(E)Inden-3-Yl)-, (3S-(3alpha,3aalpha,5abeta,7alpha,9aalpha,9bbeta))-",
    ],
  },
  {
    primary_id: "D06LRC",
    names: ["Isosorbide-Di-Phenylcarbamate"],
  },
  {
    primary_id: "D06LRG",
    names: ["TRV027"],
  },
  {
    primary_id: "D06LRQ",
    names: [
      "TKP-1001",
      "Amyloid-Beta Modulator (Alzheimer's Disease), EUSA Pharma",
      "Amyloid-Beta Modulator (Alzheimer's Disease), Talisker Pharma",
      "Amyloid-Beta Synthesis Modulator (Alzheimer's Disease), The Open University",
    ],
  },
  {
    primary_id: "D06LSC",
    names: [
      "WRR-204",
      "AC1NRDI1",
      "(3S)-5-Phenyl-3-[[(2S)-3-Phenyl-2-(Phenylmethoxycarbonylamino)Propanoyl]amino]pentane-1-Sulfonic Acid",
    ],
  },
  {
    primary_id: "D06LSZ",
    names: ["PMID25684022-Compound-US20120277229 40(1.2)"],
  },
  {
    primary_id: "D06LUH",
    names: ["ABX-201"],
  },
  {
    primary_id: "D06LUI",
    names: ["Ad-IL-12 DNA Therapeutic"],
  },
  {
    primary_id: "D06LUT",
    names: ["G(D-Pen)-G-H-R-G-D-L-R-C-A", "CHEMBL437813"],
  },
  {
    primary_id: "D06LUW",
    names: ["Lobatamide C"],
  },
  {
    primary_id: "D06LUX",
    names: ["A-315675"],
  },
  {
    primary_id: "D06LUZ",
    names: ["GS-5885"],
  },
  {
    primary_id: "D06LVE",
    names: [
      "4-Nitro-Benzenesulfonamide",
      "4-NITROBENZENESULFONAMIDE",
      "6325-93-5",
      "Benzenesulfonamide, 4-Nitro-",
      "P-Nitrobenzenesulfonamide",
      "4-Nitrobenzolesulfamide",
      "P-Nitrophenylsulfonamide",
      "4-Nitrobenzenesulphonamide",
      "Benzenesulfonamide, P-Nitro-",
      "UNII-TUF52O8Y8B",
      "EINECS 228-691-6",
      "NSC 31148",
      "BRN 2214218",
      "TUF52O8Y8B",
      "CHEMBL282157",
      "4-Nitrobenzenesulfonamide, 97%",
      "QWKKYJLAUWFPDB-UHFFFAOYSA-N",
      "NsNH2",
      "4-Nitrophenylsulfonamid",
      "3m8t",
      "4-Nitrobenzolsulfonamide",
      "P-Nitrophenyl Sulfonamide",
      "WLN: ZSWR DNW",
      "P-Nitrobenzene Sulphonamide",
      "AC1Q55AA",
      "Cambridge Id 5",
    ],
  },
  {
    primary_id: "D06LVG",
    names: ["17 Beta-N,N-Diethylcarbamoyl-4-Aza-5 Alpha-Androstan-3-One (4MA)"],
  },
  {
    primary_id: "D06LVJ",
    names: ["Mam-06.301"],
  },
  {
    primary_id: "D06LVL",
    names: ["ISIS 156473"],
  },
  {
    primary_id: "D06LWP",
    names: ["ISIS 16112"],
  },
  {
    primary_id: "D06LXP",
    names: ["POL-7080"],
  },
  {
    primary_id: "D06LYG",
    names: [
      "Prilocaine",
      "Citanest",
      "Prilocaina",
      "Prilocainum",
      "Propitocaine",
      "Prilocaine [USAN]",
      "Prilocaine Base",
      "Astra 1512",
      "Astra 1515",
      "L 67",
      "Citanest (TN)",
      "Prilocaina [INN-Spanish]",
      "Prilocainum [INN-Latin]",
      "Propitocaine (JAN)",
      "Prilocaine (USP/INN)",
      "O-Methyl-2-Propylaminopropionanilide",
      "O-Methyl-Alpha-Propylaminopropionanilide",
      "Alpha-N-Propylamino-2-Methylpropionanilide",
      "N-[2-Methylphenyl]-2-[propylamino]propanamide",
      "N-(2-Methylphenyl)-N2-Propylalaninamide",
      "N-(2-Methylphenyl)-2-(Propylamino)Propanamide",
      "N-(2-Methylphenyl)-N(2)-Propylalaninamide",
      "Propanamide, N-(2-Methylphenyl)-2-(Propylamino)-(9CI)",
      "2-(Propylamino)-O-Propionotoluidide",
      "2-Methyl-.alpha.-Propylaminopropionanilide",
      "2-Methyl-Alpha-Propylaminopropionanilide",
    ],
  },
  {
    primary_id: "D06LYI",
    names: ["BPX-501"],
  },
  {
    primary_id: "D06LYS",
    names: ["Prednimustine"],
  },
  {
    primary_id: "D06LYY",
    names: [
      "Di-Stearoyl-3-Sn-Phosphatidylcholine",
      "DI-STEAROYL-3-SN-PHOSPHATIDYLCHOLINE",
      "DSPC;L-,-Distearoyl--Lecithin",
      "Distearoylphosphatidylcholine",
      "AC1L3RPB",
      "SCHEMBL10001505",
      "ZINC85433138",
      "DB04178",
      "DB-027511",
      "816D944",
      "O-(1-O,2-O-Distearoyl-L-Glycero-3-Phospho)Choline",
    ],
  },
  {
    primary_id: "D06LYZ",
    names: [
      "A-841720",
      "869802-58-4",
      "A 841720",
      "UNII-U8C5C9D65S",
      "U8C5C9D65S",
      "CHEMBL386565",
      "SCHEMBL8237949",
      "GTPL3953",
      "CTK5F7481",
      "DTXSID10236030",
      "MolPort-023-276-650",
      "ZINC13676140",
      "BDBM50177080",
      "AKOS024457407",
      "3-(Azepan-1-Yl)-9-(Dimethylamino)Pyrido[3',2':4,5]thieno[3,2-D]pyrimidin-4(3H)-One",
      "NCGC00371083-02",
      "Pyrido(3',2':4,5)Thieno(3,2-D)Pyrimidin-4(3H)-One, 9-(Dimethylamino)-3-(Hexahydro-1H-Azepin-1-Yl)-",
      "RT-011135",
      "KB-270514",
      "A-440",
      "B7258",
      "3-(Azepan-1-Yl)-9-(Dimethylamino)Pyrido[1,2]thieno[3,4-D]pyrimidin-4-One",
    ],
  },
  {
    primary_id: "D06MAJ",
    names: ["Mizolastine"],
  },
  {
    primary_id: "D06MAL",
    names: ["ATRIPLICIOLIDTIGLATE", "Atripliciolidtiglate", "Atripliciolide Tiglate"],
  },
  {
    primary_id: "D06MBD",
    names: ["Isothiourea-1a"],
  },
  {
    primary_id: "D06MBI",
    names: ["TG003", "Clk Inhibitor", "TG 003", "TG-003"],
  },
  {
    primary_id: "D06MBR",
    names: ["ACI-636"],
  },
  {
    primary_id: "D06MCH",
    names: ["TG-6002"],
  },
  {
    primary_id: "D06MCJ",
    names: ["3-(1H-Indol-3-Yl)-6,7-Dimethoxy-Quinoline", "CHEMBL302545", "SCHEMBL8172242"],
  },
  {
    primary_id: "D06MDT",
    names: ["INV-155", "Alpha Lipoic Acid Derivative Based Therapeutics (Hypertension/Stroke), InVasc Therapeutics"],
  },
  {
    primary_id: "D06MDX",
    names: ["(2S)-2-(Biphenyl-4-Yloxy)-3-Phenylpropanoic Acid"],
  },
  {
    primary_id: "D06MEC",
    names: ["THA-901"],
  },
  {
    primary_id: "D06MFY",
    names: ["(+/-)-Threo-Benzylphenidate", "CHEMBL1254759"],
  },
  {
    primary_id: "D06MGI",
    names: ["Opioid Peptide [d-Ala(8)]Dynorphin Derivative"],
  },
  {
    primary_id: "D06MGJ",
    names: ["N-(Biphenyl-4-Ylsulfonyl)-D-Leucine"],
  },
  {
    primary_id: "D06MGO",
    names: ["(S)-1-(2-Methoxyphenethyl)-2-Methylpyrrolidine", "CHEMBL404912"],
  },
  {
    primary_id: "D06MGW",
    names: ["OrniFlu"],
  },
  {
    primary_id: "D06MHK",
    names: ["N-Oxalyl-D-Tyrosine Derivative 7"],
  },
  {
    primary_id: "D06MHZ",
    names: ["NLG919"],
  },
  {
    primary_id: "D06MIQ",
    names: ["MRE-0094"],
  },
  {
    primary_id: "D06MIU",
    names: [
      "Mycoprex",
      "BP-400",
      "BPI (Antifungal)",
      "XMP-127",
      "XMP-284",
      "XMP-293",
      "XMP-342",
      "XMP-353",
      "XMP-365",
      "XMP-366",
      "XMP-367",
      "XMP-373",
      "XMP-389",
      "XMP-391",
      "XMP-400",
      "XMP-445",
      "XMP-461",
      "XMP-620",
      "XMP-97",
      "Bactericidal/Permeability Increasing Protein (Antifungal)",
    ],
  },
  {
    primary_id: "D06MIW",
    names: ["Pyrazole Derivative 37"],
  },
  {
    primary_id: "D06MJD",
    names: ["SiG12D-LODER"],
  },
  {
    primary_id: "D06MJP",
    names: ["TNFQb Therapeutic Vaccines"],
  },
  {
    primary_id: "D06MKE",
    names: [
      "CRX-526",
      "UNII-8NLO017HHA",
      "8NLO017HHA",
      "CHEMBL505526",
      "BDBM50275658",
      "245515-64-4",
      "Hexanoic Acid, (1R)-1-(2-(((1S)-1-Carboxy-2-((2-Deoxy-3-O-((3R)-1-Oxo-3-((1-Oxohexyl)Oxy)Tetradecyl)-2-(((3R)-1-Oxo-3-((1-Oxohexyl)Oxy)Tetradecyl)Amino)-4-O-Phosphono-Beta-D-Glucopyranosyl)Oxy)Ethyl)Amino)-2-Oxoethyl)Dodecyl Ester",
      "N-[(3R)-3-(Hexanoyloxy)Myristoyl]-O-[2-[[(3R)-3-(Hexanoyloxy)Myristoyl]amino]-3-O-[(3R)-3-(Hexanoyloxy)Myristoyl]-4-O-Phosphono-2-Deoxy-Beta-D-Glucopyranosyl]-L-Serine",
    ],
  },
  {
    primary_id: "D06MKG",
    names: ["MBP(75-95)"],
  },
  {
    primary_id: "D06MLA",
    names: ["GET 73"],
  },
  {
    primary_id: "D06MLU",
    names: [
      "(-)-Dihydroclusin",
      "CHEMBL469916",
      "73149-51-6",
      "AC1L7BWU",
      "DTXSID10318502",
      "BDBM50259874",
      "NSC332042",
      "NSC-332042",
    ],
  },
  {
    primary_id: "D06MLZ",
    names: ["WAY-123223"],
  },
  {
    primary_id: "D06MMN",
    names: ["PMID7966163C4e"],
  },
  {
    primary_id: "D06MMU",
    names: ["LM-CD45", "Anti-CD45, Baxter", "Anti-CD45, Cantab"],
  },
  {
    primary_id: "D06MMW",
    names: ["ADCON-T/N"],
  },
  {
    primary_id: "D06MNC",
    names: [
      "PRS-050",
      "Angiocal",
      "PRS-050-PEG40",
      "VEGF Inhibitor (Intravenous/Anticalin/PEGylated Formulation, Solid Tumors), Pieris",
    ],
  },
  {
    primary_id: "D06MNF",
    names: ["Pardoprunox"],
  },
  {
    primary_id: "D06MNV",
    names: [
      "1-(1-Phenylethylidene)Thiosemicarbazide",
      "CHEMBL256868",
      "Acetophenone Thiosemicarbazone",
      "AC1NSE5V",
      "SCHEMBL17666972",
      "MolPort-000-248-706",
      "ACETOPHENONE-THIOSEMICARBAZONE",
      "BDBM50376200",
      "ZINC13655939",
      "AKOS002388568",
      "AKOS002238721",
      "[(Z)-1-Phenylethylideneamino]thiourea",
      "[(Z)-(1-Phenylethylidene)Amino]thiourea",
      "KB-106912",
      "ST50101590",
      "(Z)-2-(1-Phenylethylidene)Hydrazinecarbothioamide",
    ],
  },
  {
    primary_id: "D06MNW",
    names: [
      "1-(Oxazol-2-Yl)Octadec-9-En-1-One",
      "CHEMBL39412",
      "SCHEMBL13764125",
      "BDBM50100864",
      "AKOS030561890",
      "1-Oxazol-2-Yl-Nonadec-10-En-2-One",
      "(Z)-1-Oxazol-2-Yl-Octadec-9-En-1-One",
      "(Z)-1-(Oxazol-2-Yl)Octadec-9-En-1-One",
      "(9Z)-1-(2-Oxazolyl)-9-Octadecene-1-One",
    ],
  },
  {
    primary_id: "D06MOB",
    names: ["WSB-102"],
  },
  {
    primary_id: "D06MOY",
    names: ["DM-99"],
  },
  {
    primary_id: "D06MPF",
    names: [
      "Sch-40120",
      "AC1MIW6C",
      "SCHEMBL9535601",
      "10-(3-Chlorophenyl)-6,8,9,10-Tetrahydrobenzo(B)(1,8)-Naphthyridine-5(7H)-One",
      "113077-44-4",
      "5-(3-Chlorophenyl)-7,8,9,10-Tetrahydrobenzo[c][1,8]naphthyridin-6-One",
      "Benzo(C)(1,8)Naphthyridin-6(5H)-One, 5-(3-Chlorophenyl)-7,8,9,10-Tetrahydro-",
    ],
  },
  {
    primary_id: "D06MPH",
    names: ["Pyrazole Derivative 28"],
  },
  {
    primary_id: "D06MPJ",
    names: ["BD-NP", "ASBNP2.1"],
  },
  {
    primary_id: "D06MPP",
    names: ["KNI-10094"],
  },
  {
    primary_id: "D06MQB",
    names: ["Pyrrolo-Pyridone Derivative 1"],
  },
  {
    primary_id: "D06MQG",
    names: ["1-Benzhydryl-4-(3-Methoxyphenyl)Piperidin-4-Ol", "CHEMBL233494"],
  },
  {
    primary_id: "D06MQM",
    names: [
      "Lumefantrine",
      "Benflumetol",
      "Coartem",
      "Dl-Benflumelol",
      "Lumefantrine (INN)",
      "Lumefantrine [INN:BAN]",
      "(+-)-2,7-Dichloro-9-((Z)-P-Chlorobenzylidene)-Alpha((Dibutylamino)Methyl)Fluorene-4-Methanol",
      "(+-)-2,7-Dichloro-9-((Z)-P-Chlorobenzylidene)-Alpha-((Dibutylamino)Methyl)Fluorene-4-Methanol",
      "2-(Dibutylamino)-1-[(9Z)-2,7-Dichloro-9-(4-Chlorobenzylidene)-9H-Fluoren-4-Yl]ethanol",
      "2-(Dibutylamino)-1-[(9Z)-2,7-Dichloro-9-[(4-Chlorophenyl)Methylidene]fluoren-4-Yl]ethanol",
      "2-Dibutylamino-1-[2,7-Dichloro-9-(4-Chloro-Benzylidene)-9H-Fluoren-4-Yl]-Ethanol",
      "2-Dibutylamino-1-{2,7-Dichloro-9-[1-(4-Chloro-Phenyl)-Meth-(Z)-Ylidene]-9H-Fluoren-4-Yl}-Ethanol",
    ],
  },
  {
    primary_id: "D06MQW",
    names: ["GP-1002"],
  },
  {
    primary_id: "D06MRL",
    names: ["Antral"],
  },
  {
    primary_id: "D06MRM",
    names: [
      "17alpha-Hydroxypregnenolone",
      "17alpha Hydroxypregnenolone",
      "17-OH-Pregnenolone",
      "17-Hydroxypregnenolone",
    ],
  },
  {
    primary_id: "D06MRN",
    names: ["4-(Methyl(Octyl)Amino)-N-(Quinolin-3-Yl)Benzamide", "CHEMBL259017", "SCHEMBL846048"],
  },
  {
    primary_id: "D06MRR",
    names: ["WilGraf"],
  },
  {
    primary_id: "D06MRS",
    names: ["NSD-761"],
  },
  {
    primary_id: "D06MRT",
    names: ["Acitazanolast"],
  },
  {
    primary_id: "D06MRY",
    names: ["Enteric-Coated Mycophenolate Sodium"],
  },
  {
    primary_id: "D06MSZ",
    names: ["PMID27724045-Compound-19"],
  },
  {
    primary_id: "D06MTH",
    names: ["KIN-4050"],
  },
  {
    primary_id: "D06MTM",
    names: ["S-16276-1"],
  },
  {
    primary_id: "D06MTX",
    names: ["CRA_1144", "AC1O4QI2"],
  },
  {
    primary_id: "D06MUA",
    names: ["N-Hydroxy-4-Iodo-Benzamide"],
  },
  {
    primary_id: "D06MUB",
    names: ["SMOi2-17"],
  },
  {
    primary_id: "D06MUI",
    names: [
      "A-81988",
      "2-[Propyl-[[4-[2-(2H-Tetrazol-5-Yl)Phenyl]phenyl]methyl]amino]pyridine-3-Carboxylic Acid",
      "141887-34-5",
      "A81988",
      "A 81988",
      "CHEMBL416477",
      "Abbott-81988",
      "2-[propyl-[[4-[2-(2H-Tetrazol-5-Yl)Phenyl]phenyl]methyl]amino]pyridine-3-Carboxylic Acid",
      "3-Pyridinecarboxylicacid, 2-[propyl[[2'-(2H-Tetrazol-5-Yl)[1,1'-Biphenyl]-4-Yl]methyl]amino]-",
      "ACMC-20n0yv",
      "[3H]A81988",
      "[3H]-A81988",
      "AC1L30WD",
      "2-(N-Propyl-N-((2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Yl)Methyl)Amino)Pyridine-3-Carboxylic Acid",
      "Oprea1_714523",
      "SCHEMBL7048946",
      "GTPL3938",
      "CTK0H8555",
      "DTXSID40161846",
      "BDBM50003154",
      "HY-U00188",
      "CS-7281",
      "L007227",
    ],
  },
  {
    primary_id: "D06MUL",
    names: ["N-[2-(Indol-3-Yl)Ethyl]-S-Benzyl-Dithiocarbamate", "CHEMBL202881"],
  },
  {
    primary_id: "D06MUZ",
    names: ["SDZ-87-469"],
  },
  {
    primary_id: "D06MWG",
    names: ["Dmt-Pro-Mmp-Phe-NH2", "CHEMBL389948"],
  },
  {
    primary_id: "D06MWK",
    names: ["Aimpila"],
  },
  {
    primary_id: "D06MWU",
    names: [
      "LUF-5833",
      "A3 Adenosine Receptor Allosteric Enhancers (Ischemia)",
      "LUF-6000",
      "A3 Adenosine Receptor Allosteric Enhancers (Ischemia), NIH/University Of Leiden",
    ],
  },
  {
    primary_id: "D06MXB",
    names: ["IB-09C190"],
  },
  {
    primary_id: "D06MXI",
    names: ["PMID25726713-Compound-63"],
  },
  {
    primary_id: "D06MXY",
    names: ["BL-1827"],
  },
  {
    primary_id: "D06MYD",
    names: ["Ac-Cys-Ile-Tyr-Lys-Phe(4-NO2)-Phe(4-NO2)"],
  },
  {
    primary_id: "D06MYN",
    names: ["PMID18295483C2"],
  },
  {
    primary_id: "D06MYP",
    names: ["SK-MS10"],
  },
  {
    primary_id: "D06MYZ",
    names: ["N-(1-Benzylpiperidin-4-Yl)-4-Sulfanylbutanamide"],
  },
  {
    primary_id: "D06MZB",
    names: ["KNI-10081"],
  },
  {
    primary_id: "D06MZK",
    names: [
      "2,6-Di-T-Butylphenol",
      "2,6-DI-TERT-BUTYLPHENOL",
      "128-39-2",
      "2,6-Bis(Tert-Butyl)Phenol",
      "2,6-Bis(1,1-Dimethylethyl)Phenol",
      "Phenol, 2,6-Bis(1,1-Dimethylethyl)-",
      "2,6-Ditert-Butylphenol",
      "2,6 Di-Tert-Butylphenol",
      "Ethanox 701",
      "Isonox 103",
      "Ethyl 701",
      "Ethyl AN 701",
      "Hitec 4701",
      "Phenol, 2,6-Di-Tert-Butyl-",
      "2,6-Bis(T-Butyl)Phenol",
      "NSC 49175",
      "AN 701",
      "CCRIS 5828",
      "UNII-21294V58PF",
      "HSDB 5616",
      "2,6-(1,1-Dimethylethyl)Phenol",
      "EINECS 204-884-0",
      "MFCD00008820",
      "2,6-Di-Tert.-Butylphenol",
      "2,6-Di-Tert-Butyl-Phenol",
      "AI3-26293",
      "CHEMBL281071",
    ],
  },
  {
    primary_id: "D06NAG",
    names: ["Anti-CD137", "BMS-663513"],
  },
  {
    primary_id: "D06NAM",
    names: ["O-1602"],
  },
  {
    primary_id: "D06NBK",
    names: ["Sodium Iodide I-131 Capsules"],
  },
  {
    primary_id: "D06NBL",
    names: ["PMID25726713-Compound-23"],
  },
  {
    primary_id: "D06NCQ",
    names: ["3h-Pyrazolo[4,3-D]Pyrimidin-7-Ol"],
  },
  {
    primary_id: "D06NDP",
    names: ["CIGB-128"],
  },
  {
    primary_id: "D06NDR",
    names: ["2-(4-Chlorobenzyloxyamino)-N-Hydroxyhexanamide", "CHEMBL388309"],
  },
  {
    primary_id: "D06NDT",
    names: ["3-(3-Hydroxyphenyl)Quinolin-7-Ol"],
  },
  {
    primary_id: "D06NEB",
    names: ["Ac-HPhe-Leu-Phe-LeuVSMe", "CHEMBL379669", "BDBM50186702"],
  },
  {
    primary_id: "D06NEU",
    names: ["CJ-23423"],
  },
  {
    primary_id: "D06NEW",
    names: ["KNI-10217"],
  },
  {
    primary_id: "D06NFC",
    names: ["1-(4-Fluorobenzyl)-5-Phenyl-1H-Imidazole", "CHEMBL610501", "SCHEMBL3823466"],
  },
  {
    primary_id: "D06NGA",
    names: ["1,2,5-THIADIAZOLIDIN-3-ONE-1,1-DIOXIDE"],
  },
  {
    primary_id: "D06NGQ",
    names: [
      "5-Benzyloxytryptamine",
      "20776-45-8",
      "2-(5-Benzyloxy-1H-Indol-3-Yl)-Ethylamine",
      "5-Benzyloxytriptamine",
      "2-[5-(Benzyloxy)-1H-Indol-3-Yl]ethanamine",
      "3-(2-Aminoethyl)-5-Benzyloxyindole",
      "5-BT",
      "CHEMBL1288652",
      "2-(5-(Benzyloxy)-1H-Indol-3-Yl)Ethanamine",
      "WKPDXBXNJWWWGQ-UHFFFAOYSA-N",
      "5-Benzyloxy-3-(2-Ethylamino)Indole Hydrochloride",
      "1H-Indole-3-Ethanamine,5-(Phenylmethoxy)-",
      "1H-Indole-3-Ethanamine, 5-(Phenylmethoxy)-",
      "B-2100",
      "2-[5-(Benzyloxy)-1H-Indol-3-Yl]ethanamine Hydrochloride",
      "2-[5-(Phenylmethoxy)Indol-3-Yl]ethylamine",
      "EINECS 244-",
    ],
  },
  {
    primary_id: "D06NGR",
    names: ["7-Methyl-Gpppa"],
  },
  {
    primary_id: "D06NHB",
    names: ["Ethyl Esther Derivative"],
  },
  {
    primary_id: "D06NHI",
    names: ["SB-213068"],
  },
  {
    primary_id: "D06NHK",
    names: ["Sitagliptin + Atorvastatin"],
  },
  {
    primary_id: "D06NHS",
    names: ["TROX-1"],
  },
  {
    primary_id: "D06NHW",
    names: ["ISIS 222039"],
  },
  {
    primary_id: "D06NIP",
    names: ["Tyr-Pro-Gly-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D06NIS",
    names: [
      "4-[2-(4-Phenyl-Butoxy)-Ethyl]-Phenol",
      "CHEMBL119100",
      "4-[2-(4-Phenylbutoxy)Ethyl]phenol",
      "BDBM50066548",
    ],
  },
  {
    primary_id: "D06NIT",
    names: ["PF-4181366"],
  },
  {
    primary_id: "D06NJB",
    names: ["AM-802"],
  },
  {
    primary_id: "D06NKA",
    names: ["IRC-083927"],
  },
  {
    primary_id: "D06NKJ",
    names: ["8-Methoxy-2-Phenyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D06NLG",
    names: [
      "Antiviral Spray",
      "Antiviral Spray (VCCD Tech), Viroblock",
      "Antiviral Spray (Viroblock Cholesterol Catalytic Depletion Technology), Viroblock",
      "Antiviral Spray (Nasal, Respiratory Enveloped Virus Infection)",
      "Antiviral Spray (Nasal, Respiratory Enveloped Virus Infection), Viroblock",
    ],
  },
  {
    primary_id: "D06NLP",
    names: ["WTC-01"],
  },
  {
    primary_id: "D06NMB",
    names: ["Zimeldine"],
  },
  {
    primary_id: "D06NMP",
    names: ["CGP-29030A", "1-[2-(4-Chlorophenyl)Ethyl]-4-(3,5-Dimethoxybenzoyl)Piperazine Hydrochloride"],
  },
  {
    primary_id: "D06NMY",
    names: ["MDC-1231"],
  },
  {
    primary_id: "D06NMZ",
    names: ["NVP-ADW742"],
  },
  {
    primary_id: "D06NND",
    names: ["FPA144"],
  },
  {
    primary_id: "D06NNE",
    names: ["Ascorbate"],
  },
  {
    primary_id: "D06NNQ",
    names: ["VRC-HIVADV014-00-VP"],
  },
  {
    primary_id: "D06NNU",
    names: ["NSC-631570"],
  },
  {
    primary_id: "D06NOF",
    names: [
      "ATI-9242",
      "Antipsychotic Agent (Oral, Psychiatric Disorders), ARYx Therapeutics",
      "Antipsychotic Agent (Oral, Schizophrenia), ARYx Therapeutics",
    ],
  },
  {
    primary_id: "D06NOJ",
    names: ["Rp-Adenosine 3',5'-Cyclic Monophosphothioate Triethylamine (Rp-CAMPS)"],
  },
  {
    primary_id: "D06NOR",
    names: ["IVL745"],
  },
  {
    primary_id: "D06NOU",
    names: ["D-9120"],
  },
  {
    primary_id: "D06NPB",
    names: ["GLPG-0259"],
  },
  {
    primary_id: "D06NPS",
    names: ["Apoptosis-Inducing Peptide", "Apoptosis-Inducing Peptide (Cancer)"],
  },
  {
    primary_id: "D06NPZ",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 23"],
  },
  {
    primary_id: "D06NQA",
    names: ["Plasmid IL-12 Immunotherapy"],
  },
  {
    primary_id: "D06NQD",
    names: [
      "2-Amino-6-Phenyl-4-P-Tolylnicotinonitrile",
      "CHEMBL376920",
      "2-Amino-4-(4-Methylphenyl)-6-Phenylpyridine-3-Carbonitrile",
      "AC1LEZMJ",
      "BAS 00789881",
      "MolPort-000-994-656",
      "ZINC19818501",
      "STK696423",
      "BDBM50194811",
      "AKOS000286711",
      "MCULE-7406561707",
      "ST4039142",
      "2-Amino-6-Phenyl-4-P-Tolyl-Nicotinonitrile",
      "SR-01000520248",
      "SR-01000520248-1",
      "2-Amino-3-Cyano-4-(4-Methylphenyl)-6-Phenylpyridine",
      "2-Amino-4-(4-Methylphenyl)-6-Phenyl-3-Pyridyl Cyanide",
      "A1811/0076551",
    ],
  },
  {
    primary_id: "D06NQH",
    names: ["RG7155"],
  },
  {
    primary_id: "D06NQJ",
    names: ["Triproamylin Acetate"],
  },
  {
    primary_id: "D06NRQ",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 18"],
  },
  {
    primary_id: "D06NRU",
    names: [
      "C-(2'-Chloro-Biphenyl-2-Yl)-Methylamine",
      "CHEMBL206496",
      "2'-Chlorobiphenyl-2-Methylamine",
      "[2-(2-Chlorophenyl)Phenyl]methanamine",
      "AC1LRCRY",
      "SCHEMBL6862516",
      "BDBM50178431",
      "AKOS010258410",
      "876170-46-6",
    ],
  },
  {
    primary_id: "D06NRX",
    names: ["CV-1808", "2-Phenylaminoadenosine", "CV1808", "CV 1808"],
  },
  {
    primary_id: "D06NRZ",
    names: [
      "CIRSIMARITIN",
      "Cirsimaritin",
      "6601-62-3",
      "Scrophulein",
      "Skrofulein",
      "4',5-Dihydroxy-6,7-Dimethoxyflavone",
      "7-Methylcapillarisin",
      "Cirsimartin",
      "6,7-Dimethoxyscutellarein",
      "CHEMBL348436",
      "5-Hydroxy-2-(4-Hydroxyphenyl)-6,7-Dimethoxy-Chromen-4-One",
      "CHEBI:81337",
      "4H-1-Benzopyran-4-One, 5-Hydroxy-2-(4-Hydroxyphenyl)-6,7-Dimethoxy-",
      "6-Methoxygenkwanin",
      "Flavone, 4',5-Dihydroxy-6,7-Dimethoxy-",
      "AC1Q6AIG",
      "AC1L4MLD",
      "MLS000876998",
      "Cid_188323",
      "MEGxp0_000498",
      "SCHEMBL1663486",
      "CTK2F4258",
      "ACon1_000850",
      "DTXSID00216220",
      "PubChem SID: 26725076",
      "MolPort-000-779-119",
    ],
  },
  {
    primary_id: "D06NSA",
    names: ["Thiamylal"],
  },
  {
    primary_id: "D06NSL",
    names: ["PIC-060"],
  },
  {
    primary_id: "D06NSN",
    names: ["ISIS 102614"],
  },
  {
    primary_id: "D06NSS",
    names: [
      "Cromoglicate",
      "Crolom (TN)",
      "Intal (TN)",
      "Nasalcrom (TN)",
      "Opticrom (TN)",
      "Optrex Allergy (TN)",
      "Prevalin (TN)",
      "Rynacrom (TN)",
    ],
  },
  {
    primary_id: "D06NST",
    names: [
      "CYCLAZOCINE",
      "(-)-Cyclazocine",
      "7313-86-2",
      "L-Cyclazocine",
      "(-)-Cis-Cyclazocine",
      "A-Cyclazocine",
      "AC1L55DZ",
      "GTPL1604",
      "CTK8F1400",
      "DTXSID20617937",
      "MCV 4512",
      "(2R,6R,11R)-3-(Cyclopropylmethyl)-1,2,3,4,5,6-Hexahydro-6,11-Dimethyl-2,6-Methano-3-Benzazocin-8-Ol",
      "NIH 10450",
      "AKOS030242899",
      "FT-0665329",
    ],
  },
  {
    primary_id: "D06NTB",
    names: [
      "3-(4-Methyl-Piperidin-1-Ylmethyl)-1H-Indole",
      "21000-95-3",
      "BRN 0191400",
      "CHEMBL57478",
      "INDOLE, 3-((4-METHYLPIPERIDINO)METHYL)-",
      "3-((4-Methylpiperidino)Methyl)Indole",
      "3-[(4-Methylpiperidin-1-Yl)Methyl]-1H-Indole",
      "AC1L1JK7",
      "AC1Q2R9P",
      "Oprea1_731728",
      "Oprea1_382776",
      "AURORA 16336",
      "SCHEMBL13504100",
      "CTK8H5660",
      "DTXSID30175210",
      "MolPort-001-815-823",
      "ZINC142608",
      "BDBM50050469",
      "STK617550",
      "3-[(4-Methylpiperidyl)Methyl]indole",
      "AKOS002266584",
      "MCULE-4620579742",
      "ACM21000953",
      "3-(4-Methylpiperidinomethyl)-1H-Indole",
      "LS-83280",
      "BAS 02946824",
      "ST50190326",
    ],
  },
  {
    primary_id: "D06NUL",
    names: ["RWJ-68354"],
  },
  {
    primary_id: "D06NUU",
    names: ["1-Benzhydryl-4-(3,3-Diphenylpropyl)Piperazine", "CHEMBL1081018"],
  },
  {
    primary_id: "D06NVJ",
    names: [
      "Nicotinamide",
      "Aminicotin",
      "Amixicotyn",
      "Amnicotin",
      "Benicot",
      "Dipegyl",
      "Endobion",
      "Enduramide",
      "Hansamid",
      "Mediatric",
      "Niacevit",
      "Niacinamide",
      "Niacotinamide",
      "Niamide",
      "Nicamina",
      "Nicamindon",
      "Nicasir",
      "Nicobion",
      "Nicofort",
      "Nicogen",
      "Nicomidol",
      "Nicosylamide",
      "Nicota",
      "Nicotamide",
      "Nicotilamide",
      "Nicotililamido",
      "Nicotinamid",
      "Nicotinamida",
      "Nicotinamidum",
      "Nicotinsaeureamid",
      "Nicotinsaureamid",
      "Nicotol",
      "Nicotylamide",
      "Nicotylamidum",
      "Nicovit",
      "Nicovitina",
      "Nicovitol",
      "Nicozymin",
      "Nikasan",
      "Nikazan",
      "Nikotinamid",
      "Nikotinsaeureamid",
      "Niocinamide",
      "Niozymin",
      "Papulex",
      "Pelmin",
      "Pelmine",
      "Savacotyl",
      "Amid Kyseliny Nikotinove",
      "Amid Kyseliny Nikotinove [Czech]",
      "Amide PP",
      "Astra Brand Of Niacinamide",
      "Austrovit PP",
      "Delonin Amide",
      "Factor Pp",
      "Inovitan PP",
      "Jenapharm Brand Of Niacinamide",
      "Merck Brand Of Niacinamide",
      "Niacinamide Astra Brand",
      "Niacinamide Jenapharm Brand",
      "Niacinamide Merck Brand",
      "Niacinamide Pharmagenix Brand",
      "Niacinamide [USAN]",
      "Niavit PP",
      "Nicotine Acid Amide",
      "Nicotine Amide",
      "Nicotinic Acid Amide",
      "Nicotinic Amide",
      "Nicotinsaureamid Jenapharm",
      "Nicotinsaureamid [German]",
      "Nikotinsaeureamid [German]",
      "Pelonin Amide",
      "Pharmagenix Brand Of Niacinamide",
      "Vitamin B",
      "Vitamin PP",
      "Witamina PP",
      "Nicosan 2",
      "Vitamin H1",
      "B 3, Vitamin",
      "B3, Vitamin",
      "Beta-Pyridinecarboxamide",
      "Jenapharm, Nicotinsaureamid",
      "Nandervit-N",
      "Niacin-Vitamin B3",
      "Niacinamide (USP)",
      "Nicotinamida [INN-Spanish]",
      "Nicotinamide (Niacinamide)",
      "Nicotinamidum [INN-Latin]",
      "Niko-Tamin",
      "PP-Faktor",
      "Vi-Nicotyl",
      "Vitamin B (VAN)",
      "M-(Aminocarbonyl)Pyridine",
      "Niacinamide, Nicotinic Acid Amide, Nicotinamide",
      "Nicotinamide (JP15/INN)",
      "Nicotinamide, Niacin, Vitamin B3",
      "Nicotinamide-Carbonyl-14C",
      "Pyridine-3-Carboxamide",
      "Pyridine-3-Carboxylic Acid Amide",
      "3 Pyridinecarboxamide",
      "3-Carbamoylpyridine",
      "3-Pyridinecarboxamide",
      "3-Pyridinecarboxylic Acid Amide",
    ],
  },
  {
    primary_id: "D06NVL",
    names: ["Radiolabeled Octreotide Derivative"],
  },
  {
    primary_id: "D06NVN",
    names: [
      "BMS-599793",
      "DS-003",
      "BMS-378806 Analogs (HIV Infection)",
      "BMS-378806 Analogs (HIV Infection), IPM",
      "BMS-378806 Analogs(HIV Infection), International Partnership For Microbicides",
    ],
  },
  {
    primary_id: "D06NVX",
    names: ["N-Hydroxy-5-Phenylthiophene-2-Carboxamide", "CHEMBL217573", "SCHEMBL5903684"],
  },
  {
    primary_id: "D06NXC",
    names: [
      "N-[6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDINE-4-YL]-4,4,4-TRIFLUORO-3-HYDROXYBUTANAMIDE (DIASTEREOMERIC MIX)",
      "CHEMBL1097456",
      "SCHEMBL1404641",
      "BDBM50316519",
    ],
  },
  {
    primary_id: "D06NXD",
    names: ["Oral Paclitaxel"],
  },
  {
    primary_id: "D06NXG",
    names: ["PF-04236921"],
  },
  {
    primary_id: "D06NXK",
    names: ["(R)-6-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Quinoline", "CHEMBL1086032"],
  },
  {
    primary_id: "D06NXY",
    names: [
      "Ethinyl Estradiol",
      "Aethinyloestradiolum",
      "Aethinyoestradiol",
      "Amenoron",
      "Amenorone",
      "Anovlar",
      "Certostat",
      "Cyclosa",
      "Dicromil",
      "Diprol",
      "Dyloform",
      "Ertonyl",
      "Esteed",
      "Estigyn",
      "Estinyl",
      "Estopherol",
      "Estoral",
      "Estorals",
      "Ethidol",
      "Ethinoral",
      "Ethinylestradiolum",
      "Ethinylestriol",
      "Ethinyloestradiol",
      "Ethynylestradiol",
      "Ethynyloestradiol",
      "Eticyclin",
      "Eticyclol",
      "Eticylol",
      "Etinestrol",
      "Etinestryl",
      "Etinilestradiol",
      "Etinilestradiolo",
      "Etinoestryl",
      "Etistradiol",
      "Etivex",
      "Feminone",
      "Follicoral",
      "Ginestrene",
      "Inestra",
      "Kolpolyn",
      "Linoral",
      "Lynoral",
      "Menolyn",
      "Microfollin",
      "Novestrol",
      "Oradiol",
      "Orestralyn",
      "Orestrayln",
      "Palonyl",
      "Perovex",
      "Primogyn",
      "Prosexol",
      "Spanestrin",
      "Ylestrol",
      "Aethinyoestradiol [German]",
      "Component Of Demulen",
      "Component Of Oracon",
      "Component Of Ortrel",
      "Diogyn E",
      "Effik Brand Of Ethinyl Estradiol",
      "Estoral [Orion]",
      "Ethinyl Estradiol Hemihydrate",
      "Ethinyl Estradiol [USP]",
      "Ethinyl Oestradiol Effik",
      "Ethinylestradiol Jenapharm",
      "Ethinyloestradiol [Steroidal Oestrogens]",
      "Ethynyl Estradiol",
      "Etinilestradiolo [DCIT]",
      "Jenapharm Brand Of Ethinyl Estradiol",
      "Microfollin Forte",
      "Organon Brand Of Ethinyl Estradiol",
      "PUBERTAL ETHINYL ESTRADIOL STUDY",
      "Primogyn C",
      "Primogyn M",
      "Progynon C",
      "Progynon M",
      "Schering Brand Of Ethinyl Estradiol",
      "EE2",
      "Ethinyl E2",
      "Ethy 11",
      "Diognat-E",
      "Diogyn-E",
      "EE(Sub 2)",
      "Estinyl (TN)",
      "Eston-E",
      "Estoral (Orion)",
      "Estoral (VAN)",
      "Estradiol, Ethinyl",
      "Estradiol, Ethynyl",
      "Ethinyl Estradiol (USP)",
      "Ethinyl-Oestradiol Effik",
      "Ethinyl-Oestranol",
      "Ethinylestradiolum [INN-Latin]",
      "Ethynylestradiol, Ethinyl Estradiol",
      "Etinilestradiol [INN-Spanish]",
      "Hemihydrate, Ethinyl Estradiol",
      "Jenapharm, Ethinylestradiol",
      "Neo-Estrone",
      "Nogest-S",
      "OVULEN-21",
      "OVULEN-28",
      "Ortho-Cyclen",
      "Chee-O-Gen",
      "Chee-O-Genf",
      "Ethinylestradiol (JP15/INN)",
      "Ethinylestradiol [INN:BAN:JAN]",
      "Ethinyl Estradiol, (8 Alpha)-Isomer",
      "17 Alpha-Ethinylestradiol",
      "17 Alpha-Ethynylestradiol",
      "17 Alpha-Ethynyloestradiol",
      "17 Alpha-Ethinyestradiol",
      "17-Ethinyl-3,17-Estradiol",
      "17-Ethinyl-3,17-Oestradiol",
      "17-Ethinylestradiol",
      "17-Ethynylestradiol",
      "17-Ethynyloestradiol",
      "17-Alpha-Ethinyl-17-Beta-Estradiol",
      "17-Alpha-Ethynyl-17-Beta-Oestradiol",
      "17-Alpha-Ethynylestradiol",
      "17-Alpha-Ethynylestradiol-17-Beta",
      "17-Alpha-Ethynylestradiol-L7-Beta",
      "17-Alpha-Ethynyloestradiol-17-Beta",
      "17-Alpha-Ethynyl Estradiol",
      "17.alpha.-Ethinyl-17.beta.-Estradiol",
      "17.alpha.-Ethinylestradiol",
      "17.alpha.-Ethynyl-17.beta.-Oestradiol",
      "17.alpha.-Ethynylestradiol",
      "17.alpha.-Ethynyloestradiol",
      "17a-Ethynylestradiol",
      "17alpha-Ethinyl Estradiol",
      "17alpha-Ethinylestradiol",
      "17alpha-Ethinylestradiol-17beta",
      "17alpha-Ethynylestradiol",
      "17alpha-Ethynyloestradiol",
      "17alpha-Ethynyloestradiol-17beta",
    ],
  },
  {
    primary_id: "D06NYA",
    names: ["2-Methoxyestradiol"],
  },
  {
    primary_id: "D06NYB",
    names: [
      "5,7-Dichloro-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "CHEMBL282003",
      "5,7-Dichloroquinoxaline-2,3-Diol",
      "5,7-Dichloro-Quinoxaline-2,3-Diol",
      "BAS 01357242",
      "5,7-Dichloro-1,4-Dihydroquinoxaline-2,3-Dione",
      "5,7-Dichloro-1,4-Dihydro-2,3-Quinoxalinedione",
      "MLS000026787",
      "AC1LCHFV",
      "Oprea1_426848",
      "SCHEMBL7345532",
      "SCHEMBL2639494",
      "MolPort-002-737-915",
      "DBSHFABBWSZJLZ-UHFFFAOYSA-N",
      "HMS2397P18",
      "ZINC12428297",
      "STK782684",
      "BDBM50008757",
      "AKOS022421131",
      "AKOS005619834",
      "AKOS000543707",
      "MCULE-5094494417",
      "ST088493",
      "ST042190",
      "SMR000010423",
      "4029-54-3",
    ],
  },
  {
    primary_id: "D06NZY",
    names: ["PMID25435285-Compound-44"],
  },
  {
    primary_id: "D06OAH",
    names: ["6-Bromoindirubin-3-Oxime", "6-Bromoindirubin-3'-Oxime (BIO)"],
  },
  {
    primary_id: "D06OAL",
    names: ["7-Ethyl-2-(4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D06OAV",
    names: ["Aminohippurate Sodium"],
  },
  {
    primary_id: "D06OBP",
    names: [
      "1-Hydroxy-2-Amino-3-Cyclohexylpropane",
      "(S)-2-Amino-3-Cyclohexylpropan-1-Ol",
      "131288-67-0",
      "(S)-2-Amino-3-Cyclohexyl-Propan-1-Ol",
      "CHEMBL1231778",
      "(S)-2-AMINO-3-CYCLOHEXYL-1-PROPANOL",
      "(2S)-2-Amino-3-Cyclohexylpropan-1-Ol Hydrochloride",
      "205445-49-4",
      "(2S)-2-Amino-3-Cyclohexylpropan-1-Ol",
      "AC1L9GJG",
      "AC1Q4UB7",
      "SCHEMBL82667",
      "S-2-Amino-3-Cyclohexylpropanol",
      "KS-00000LNV",
      "QWDRYURVUDZKSG-VIFPVBQESA-N",
      "MolPort-000-000-987",
      "ZINC154757",
      "ZX-CM013052",
      "(S)-Cyclohexylalaninol, AldrichCPR",
      "BDBM50367060",
      "FCH835925",
      "AKOS022180681",
      "MB00862",
      "DB04387",
    ],
  },
  {
    primary_id: "D06OBQ",
    names: ["SGLT-2 Inhibitors"],
  },
  {
    primary_id: "D06OBX",
    names: ["Tirotundin"],
  },
  {
    primary_id: "D06ODM",
    names: ["Talsaclidine Fumarate"],
  },
  {
    primary_id: "D06OES",
    names: ["CART-38 Cells"],
  },
  {
    primary_id: "D06OFD",
    names: [
      "BAY-85-8102",
      "Benzodiazepine Receptor Agonist (Brain Disease Imaging), University Of Sydney",
      "DPA-714-[18F]",
      "F-18 DPA-714",
      "Fluorine-18-DPA-714",
      "Translocator Protein-Specific Ligand (Brain Disease Imaging), University Of Sydney",
      "18F-DPA-714",
    ],
  },
  {
    primary_id: "D06OFJ",
    names: ["NPI-3304"],
  },
  {
    primary_id: "D06OFS",
    names: ["Rilmenidine"],
  },
  {
    primary_id: "D06OFY",
    names: ["[4-(3-Adamantan-1-Yl-Ureido)-Phenyl]-Acetic Acid", "CHEMBL478147", "SCHEMBL12932182", "BDBM50267207"],
  },
  {
    primary_id: "D06OFZ",
    names: ["LEISH-F1"],
  },
  {
    primary_id: "D06OGZ",
    names: [
      "Degraded Cephaloridine",
      "DEGRADED CEPHALORIDINE",
      "5-METHYL-2-[2-OXO-1-(2-THIOPHEN-2-YL-ACETYLAMINO)-ETHYL]-3,6-DIHYDRO-2H-[1,3]THIAZINE-4-CARBOXYLIC ACID",
      "4ivk",
      "AC1NRAIF",
      "DB04133",
      "FT-0771180",
      "(2R)-5-Methyl-2-[(1R)-2-Oxo-1-[2-(Thiophen-2-Yl)Acetamido]ethyl]-3,6-Dihydro-2H-1,3-Thiazine-4-Carboxylic Acid",
      "(2R)-5-Methyl-2-{(1R)-2-Oxo-1-[(Thiophen-2-Ylacetyl)Amino]ethyl}-3,6-Dihydro-2H-1,3-Thiazine-4-Carboxylic Acid",
      "(2R)-5-Methyl-2-[(1R)-2-Oxo-1-[(2-Thiophen-2-Ylacetyl)Amino]ethyl]-3,6-Dihydro-2H-1,3-Thiazine-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D06OIG",
    names: ["Hib-MenCY-TT Vaccine"],
  },
  {
    primary_id: "D06OIU",
    names: ["Threo-N-Ethylritalinol Hydrochloride"],
  },
  {
    primary_id: "D06OIV",
    names: [
      "Tolbutamide",
      "Aglicid",
      "Arcosal",
      "Arkozal",
      "Artosin",
      "Artozin",
      "Beglucin",
      "Butamid",
      "Butamide",
      "Butamidum",
      "Diaben",
      "Diabesan",
      "Diabetamid",
      "Diabetol",
      "Diabuton",
      "Diasulfon",
      "Diaval",
      "Dirastan",
      "Dolipol",
      "Drabet",
      "Glyconon",
      "Ipoglicone",
      "Mobenol",
      "Orabet",
      "Oralin",
      "Oramide",
      "Orezan",
      "Orinase",
      "Orinaz",
      "Oterben",
      "Pramidex",
      "Rastinon",
      "Restinon",
      "Tarasina",
      "Tolbet;Tolbusal",
      "Tolbutamid",
      "Tolbutamida",
      "Tolbutamidum",
      "Tolbutone",
      "Toluina",
      "Tolumid",
      "Toluran",
      "Toluvan",
      "Tolylsulfonylbutylurea",
      "Willbutamide",
      "Apotex Brand Of Tolbutamide",
      "Aventis Brand Of Tolbutamide",
      "BerlinChemie Brand Of Tolbutamide",
      "Butamide Brand Of Tolbutamide",
      "Hoechst Brand Of Tolbutamide",
      "Pfizer Brand Of Tolbutamide",
      "TOLBUTAMIDE USP",
      "Tolbutamide Aventis Brand",
      "Tolbutamide Butamide Brand",
      "Tolbutamide Hoechst Brand",
      "Tolbutamide Pfizer Brand",
      "Valdecasas Brand Of Tolbutamide",
      "Yamanouchi Brand Of Tolbutamide",
      "D 860",
      "HLS 831",
      "T 0891",
      "U 2043",
      "Apo-Tolbutamide",
      "Berlin-Chemie Brand Of Tolbutamide",
      "Novo-Butamide",
      "Orinase (TN)",
      "Sk-Tolbutamide",
      "Tol-Tab",
      "Tolbutamida [INN-Spanish]",
      "Tolbutamidum [INN-Latin]",
      "Tolbutamide [INN:BAN:JAN]",
      "R.A.N. Brand Of Tolbutamide",
      "Tolbutamid R.A.N.",
      "Tolbutamide (JP15/USP/INN)",
      "N-4-Methylbenzolsulfonyl-N-Butylurea",
      "N-4-(Methylbenzolsulfonyl)-N-Butylurea",
      "N-Butyl-N'-P-Toluenesulfonylurea",
      "N-N-Butyl-N'-Tosylurea",
      "N-(4-Methylbenzenesulfonyl)-N'-Butylurea",
      "N-(4-Methylphenylsulfonyl)-N'-Butylurea",
      "N-(P-Tolylsulfonyl)-N'-Butylcarbamide",
      "N-Butyl-N'-(4-Methylphenylsulfonyl)Urea",
      "N-Butyl-N'-(P-Tolylsulfonyl)Urea",
      "N-Butyl-N'-Toluene-P-Sulfonylurea",
      "N-(P-Tolylsulfonyl)-N'-N-Butylurea",
      "1-Butyl-3-(4-Methylphenylsulfonyl)Urea",
      "1-Butyl-3-(P-Methylphenylsulfonyl)Urea",
      "1-Butyl-3-(P-Tolylsulfonyl)Urea",
      "1-Butyl-3-(Para-Tolylsulfonyl) Urea",
      "1-Butyl-3-Tosylurea",
      "1-Butyl-3-(4-Methylphenyl)Sulfonylurea",
      "1-P-Toluenesulfonyl-3-Butylurea",
      "3-(P-Tolyl-4-Sulfonyl)-1-Butylurea",
      "3-(P-Tolylsulfonyl)-1-Butylurea",
      "3-[p-Tolyl-4-Sulfonyl]-1-Butylurea",
    ],
  },
  {
    primary_id: "D06OJI",
    names: [
      "2-(2-Amino-Propyl)-5-Bromo-4-Methoxy-Phenol",
      "CHEMBL61002",
      "Phenol, 2-(2-Aminopropyl)-5-Bromo-4-Methoxy-",
      "BDBM50226139",
    ],
  },
  {
    primary_id: "D06OKE",
    names: ["MCL-433"],
  },
  {
    primary_id: "D06OLJ",
    names: [
      "AMG-7160",
      "CHEMBL1086588",
      "4-Bromo-N-[2,2,2-Trichloro-1-(4-Chloro-Phenylsulfanyl)-Ethyl]-Benzamide",
      "4-Bromo-N-{2,2,2-Trichloro-1-[(4-Chlorophenyl)Sulfanyl]ethyl}benzamide",
      "4-Bromo-N-(2,2,2-Trichloro-1-((4-Chlorophenyl)Sulfanyl)Ethyl)Benzamide",
      "BAS 00084574",
      "AC1MJD0X",
      "SCHEMBL19743234",
      "MolPort-001-818-003",
      "BDBM50318458",
      "AKOS016369423",
      "AKOS000619708",
      "MCULE-2387805859",
      "ST082345",
      "AG-690/32533021",
      "4-Bromo-N-(2,2,2-Trichloro-1-(4-Chlorophenylthio)Ethyl)Benzamide",
      "4-Bromo-N-[2,2,2-Trichloro-1-(4-Chlorophenyl)Sulfanylethyl]benzamide",
    ],
  },
  {
    primary_id: "D06OMD",
    names: ["7-(4-Methoxypyrimidin-2-Yl)-2-Methylquinoline", "CHEMBL395327"],
  },
  {
    primary_id: "D06OMH",
    names: [
      "Safflower Seed-Derived Apolipoprotein A1",
      "Apo A1 (Safflower), SemBioSys",
      "Apo AI (Atherosclerosis), SemBioSys",
      "Apo AI (Cardiovascular Disease), SemBioSys",
      "Apolipoprotein A1 (Atherosclerosis), SemBioSys",
      "Safflower Seed-Derived Apolipoprotein A1 (Cardiovascular Disease)",
      "Apo AI(Milano) (Safflower), SemBioSys",
      "Plant-Derived Apo A1 (Cardiovascular Disease), SemBioSys",
      "Safflower Seed-Derived Apolipoprotein A1 (Cardiovascular Disease), SemBioSys",
    ],
  },
  {
    primary_id: "D06OMK",
    names: [
      "Tacrolimus",
      "Advagraf",
      "Fujimycin",
      "Graceptor",
      "Modigraf",
      "Prograf",
      "Protopic",
      "Protopy",
      "Tacarolimus",
      "Tsukubaenolide",
      "Tacrolimus Anhydrous",
      "FK 506",
      "FK5",
      "FK506",
      "FR 900506",
      "FR900506",
      "K506",
      "L 679934",
      "Advagraf (TN)",
      "FR-900506",
      "Fk-506",
      "L-679934",
      "LCP-Tacro",
      "Prograf (TN)",
      "Protopic (TN)",
      "Tacrolimus (INN)",
      "Tacrolimus (Prograf)",
      "Tacrolimus (Anhydrous)",
      "(-)-FK 506",
      "15,19-Epoxy-3H-Pyrido[2,1-C][1,4]oxaazacyclotricosine-1,7,20,21(23H)-Tetrone",
      "8-DEETHYL-8-[BUT-3-ENYL]-ASCOMYCIN",
    ],
  },
  {
    primary_id: "D06OMW",
    names: ["Rasagiline"],
  },
  {
    primary_id: "D06ONB",
    names: ["AZ505", "AZ 505", "AZ-505"],
  },
  {
    primary_id: "D06ONC",
    names: [
      "Azithromycin-N-Benzyltriazolyldecahydroxamic Acid",
      "CHEMBL496761",
      "SCHEMBL14328159",
      "BDBM27184",
      "Triazole-Linked Azithromycin-Based Compound, 16h",
    ],
  },
  {
    primary_id: "D06OQI",
    names: [
      "Tilarginine Acetate",
      "53308-83-1",
      "L-NMMA Acetate",
      "UNII-2FL3530AF2",
      "2FL3530AF2",
      "DSSTox_RID_81122",
      "DSSTox_CID_25780",
      "DSSTox_GSID_45780",
      "SMR000449329",
      "CAS-53308-83-1",
      "N-Omega-Monomethyl-L-Arginine Monoacetate, 99%",
    ],
  },
  {
    primary_id: "D06ORM",
    names: ["KYS-05055"],
  },
  {
    primary_id: "D06ORU",
    names: [
      "Butacaine",
      "Butacaine Sulfate",
      "Butelline",
      "Butyn",
      "149-15-5",
      "Butacaine Sulphate",
      "Butacaine Sulfate [USP]",
      "UNII-PAU39W3CVB",
      "EINECS 205-733-1",
      "PAU39W3CVB",
      "P-Aminobenzoyldibutylaminopropanol Sulfate",
      "Dibutylaminopropyl P-Aminobenzoate Sulfate",
      "AI3-02405",
      "3'-Dibutylaminopropyl 4-Aminobenzoate Sulfate",
      "1-Propanol, 3-(Dibutylamino)-, 4-Aminobenzoate (Ester), Sulfate (2:1) (Salt)",
      "3-(P-Aminobenzoxy)-1-Di-N-Butylaminopropane Sulfate",
      "3-(Dibutylamino)-1-Propanol P-Aminobenzoate (Ester) Sulfate (2:1)",
    ],
  },
  {
    primary_id: "D06ORV",
    names: ["Phenethylboronic Acid"],
  },
  {
    primary_id: "D06OSB",
    names: ["6-Ethyl-3-I-Propoxycarbonyl-4-Quinolone", "CHEMBL205724"],
  },
  {
    primary_id: "D06OSE",
    names: ["PMID29338548-Compound-39"],
  },
  {
    primary_id: "D06OSM",
    names: ["Tandospirone"],
  },
  {
    primary_id: "D06OSN",
    names: ["Bhg-F-N-Y-Y-W", "CHEMBL414917"],
  },
  {
    primary_id: "D06OTA",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 29"],
  },
  {
    primary_id: "D06OUC",
    names: ["Combination Autologous Stem Cell Therapy"],
  },
  {
    primary_id: "D06OUL",
    names: [
      "Ceforanide",
      "Ceforanido",
      "Ceforanidum",
      "Precef",
      "Radacef",
      "Bl S786",
      "BL-S 786",
      "BL-S786",
      "Ceforanido [INN-Spanish]",
      "Ceforanidum [INN-Latin]",
      "Precef (TN)",
      "Ceforanide (USP/INN)",
      "Ceforanide [USAN:INN:BAN]",
      "(6R,7R)-7-(2-(Alpha-Amino-O-Tolyl)Acetamido)-3-(((1-(Carboxymethyl)-1H-Tetrazol-5-Yl)Thio)Methyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-({[2-(Aminomethyl)Phenyl]acetyl}amino)-3-({[1-(Carboxymethyl)-1H-Tetrazol-5-Yl]sulfanyl}methyl)-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-({[2-(Aminomethyl)Phenyl]acetyl}amino)-3-({[1-(Carboxymethyl)-1H-Tetrazol-5-Yl]thio}methyl)-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[2-[2-(Aminomethyl)Phenyl]acetyl]amino]-3-[[1-(Carboxymethyl)Tetrazol-5-Yl]sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R-Trans)-7-(((2-(Aminomethyl)Phenyl)Acetyl)Amino)-3-(((1-(Carboxymethyl)-1H-Tetrazol-5-Yl)Thio)Methyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "7-(O-(Aminomethyl)Phenylacetamido)-3-(((1-(Carboxymethyl)-1H-Tetrazol-5-Yl)Thio)Methyl)-3-Cephem-4-Carboxylic Acid",
      "7-[O-(Aminomethyl)Phenylacetamido]-3-[[[1-(Carboxymethyl)-1H-Tetrazol-5-Yl]thio]methyl]-3-Cephem-4-Carboxylic Acid",
      "7beta-[2-(Aminomethyl)Phenyl]acetamido-3-{[1-(Carboxymethyl)-1H-Tetrazol-5-Yl]sulfanyl}methyl-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D06OUR",
    names: ["4-(2-(Diethylamino)Ethylamino)-1-Naphthol", "CHEMBL576113", "Kinome_2771", "SCHEMBL12649369"],
  },
  {
    primary_id: "D06OUT",
    names: ["ANG-4011"],
  },
  {
    primary_id: "D06OUW",
    names: ["PBI-220"],
  },
  {
    primary_id: "D06OVA",
    names: ["1-Pentenyl-4-(Aminosulfonyl)Benzoate", "CHEMBL200074"],
  },
  {
    primary_id: "D06OVF",
    names: ["Santalum"],
  },
  {
    primary_id: "D06OVM",
    names: ["KM-3413", "Anti-Tn IgG1 Antibody (Cancer), Kyowa"],
  },
  {
    primary_id: "D06OVX",
    names: ["25-Hydroxycholesterol", "25-OHC"],
  },
  {
    primary_id: "D06OVY",
    names: [
      "Cefixime",
      "CFIX",
      "Cefixima",
      "Cefiximum",
      "Denvar",
      "Necopen",
      "Tricef",
      "CL-284635",
      "FK-027",
      "FR-17027",
      "Ofex (TN)",
      "Suprax (TN)",
      "Cefixime (JP15/USP/INN)",
      "(6R,7R)-7-({(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(Carboxymethoxy)Imino]acetyl}amino)-3-Ethenyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Carboxymethyloxyimino)Acetyl]amino]-3-Ethenyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-{[(Carboxymethyl)Oxy]imino}acetyl]amino}-3-Ethenyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6r,7r)-7-[-2-(2-Amino-Thiazol-4-Yl)-2-Carboxymethoxyimino-Acetylamino]-8-Oxo-3-Vinyl-5-Thia-1-Aza-B",
      "7beta-{(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(Carboxymethoxy)Imino]acetamido}-3-Ethenyl-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D06OWF",
    names: ["TBI-301"],
  },
  {
    primary_id: "D06OWM",
    names: ["PNRI-299"],
  },
  {
    primary_id: "D06OWS",
    names: ["WWL70"],
  },
  {
    primary_id: "D06OXA",
    names: ["Tert-Butyl 3-(2-Oxohexadecanamido)Propanoate", "CHEMBL488922"],
  },
  {
    primary_id: "D06OXO",
    names: ["Peptide Analog 33"],
  },
  {
    primary_id: "D06OZJ",
    names: [
      "RWJ-67657",
      "215303-72-3",
      "RWJ 67657",
      "RWJ67657",
      "4-[4-(4-Fluorophenyl)-1-(3-Phenylpropyl)-5-(4-Pyridinyl)-1H-Imidazol-2-Yl]-3-Butyn-1-Ol",
      "CHEMBL190333",
      "4-(4-(4-Fluorophenyl)-1-(3-Phenylpropyl)-5-(Pyridin-4-Yl)-1H-Imidazol-2-Yl)But-3-Yn-1-Ol",
      "4-(4-Fluorophenyl)-2-(4-Hydroxy-1-Butynyl)-1-(3-Phenylpropyl)-5-(4-Pyridyl)Imidazole",
      "4-[4-(4-Fluorophenyl)-1-(3-Phenylpropyl)-5-(4-Pyridyl)Imidazol-2-Yl]but-3-Yn-1-Ol",
      "4-(4-(4-Fluorophenyl)-1-(3-Phenylpropyl)-5-(4-Pyridinyl)-1H-Imidazol-2-Yl)-3-Butyn-1-Ol",
    ],
  },
  {
    primary_id: "D06OZW",
    names: [
      "Artemisone",
      "Artemifone",
      "Artemisone, Medicines For Malaria Venture",
      "MMV-00/1007",
      "Artemisinin Derviative, Bayer/WHO/Hong Kong University",
      "Artemisone, Bayer/WHO/Hong Kong University",
      "Semi-Synthetic Endoperoxide, Bayer/WHO/Hong Kong University",
    ],
  },
  {
    primary_id: "D06PAA",
    names: [
      "1-Thiazol-2-Yl-3-(2-P-Tolyl-Ethyl)-Thiourea",
      "CHEMBL149100",
      "149484-92-4",
      "Thiourea, N-[2-(4-Methylphenyl)Ethyl]-N'-2-Thiazolyl-",
      "AC1MHEHT",
      "ACMC-20n5ps",
      "SCHEMBL6284717",
      "CTK0B1828",
      "DTXSID70388246",
      "BDBM50097037",
      "AKOS030030944",
      "1-[2-(P-Tolyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "1-(4-Methylphenethyl)-3-(2-Thiazolyl)Thiourea",
      "[2-(4-Methylphenyl)Ethyl]-N''-[2-(Thiazolyl)]thiourea",
    ],
  },
  {
    primary_id: "D06PAK",
    names: [
      "LY-389795",
      "CHEMBL88999",
      "LY389795",
      "GTPL3349",
      "BDBM50089897",
      "LY 389795",
      "(4S,6S)-4-Amino-2-Thiabicyclo[3.1.0]hexane-4,6-Dicarboxylic Acid",
      "4-Amino-2-Thia-Bicyclo[3.1.0]hexane-4,6-Dicarboxylic Acid(LY389795)",
    ],
  },
  {
    primary_id: "D06PAU",
    names: ["5'-Methylthioadenosine"],
  },
  {
    primary_id: "D06PAW",
    names: [
      "Z-4105",
      "119737-52-9",
      "Butanoic Acid, 4-(((3-Methyl-5-Isoxazolyl)Carbonyl)Amino)-",
      "Butanoic Acid,4-[[(3-Methyl-5-Isoxazolyl)Carbonyl]amino]-",
      "ACMC-20cew8",
      "AC1Q5P7F",
      "SCHEMBL195378",
      "AC1L4P39",
      "CTK4B1474",
      "4-[(3-Methyl-1,2-Oxazole-5-Carbonyl)Amino]butanoic Acid",
      "DTXSID50152568",
      "4-{[(3-Methyl-1,2-Oxazol-5-Yl)Carbonyl]amino}butanoic Acid",
    ],
  },
  {
    primary_id: "D06PAZ",
    names: ["Encainide"],
  },
  {
    primary_id: "D06PBH",
    names: ["NN8640"],
  },
  {
    primary_id: "D06PCE",
    names: ["Maltosyl-Alpha (1,4)-D-Gluconhydroximo-1,5-Lactam"],
  },
  {
    primary_id: "D06PCU",
    names: ["Anti-TB Antibiotics", "Anti-TB Antibiotics (Mycobacterium Tuberculosis)"],
  },
  {
    primary_id: "D06PDK",
    names: ["PMID25482888-Compound-52"],
  },
  {
    primary_id: "D06PDQ",
    names: ["AOP-200704"],
  },
  {
    primary_id: "D06PDT",
    names: ["TARP Peptide-Pulsed Autologous Dendritic Cell Vaccine"],
  },
  {
    primary_id: "D06PEB",
    names: [
      "Cinalukast",
      "Cinalukast [USAN:INN]",
      "Ro 24-5913",
      "Cinalukast (USAN/INN)",
      "Ro-24-5913",
      "(E)-4-[[3-(2-(4-Cyclobutyl-2-Thiazolyl)Ethenyl)Phenyl]amino]-2,2-Diethyl-4-Oxobutanoic Acid",
      "3'-((E)-2-(4-Cyclobutyl-2-Thiazolyl)Vinyl)-2,2-Diethylsuccinanilic Acid",
      "4-({3-[(E)-2-(4-Cyclobutyl-1,3-Thiazol-2-Yl)Ethenyl]phenyl}amino)-2,2-Diethyl-4-Oxobutanoic Acid",
      "4-[3-[(E)-2-(4-Cyclobutyl-1,3-Thiazol-2-Yl)Ethenyl]anilino]-2,2-Diethyl-4-Oxobutanoic Acid",
    ],
  },
  {
    primary_id: "D06PEW",
    names: ["IT-101"],
  },
  {
    primary_id: "D06PFI",
    names: ["Vancomycin Inhalation Powder"],
  },
  {
    primary_id: "D06PFK",
    names: ["TDT-044"],
  },
  {
    primary_id: "D06PFL",
    names: ["4-Methyl-3,6-Dihydro-1H-Pyridin-(2Z)-Ylideneamine", "AKOS006358726"],
  },
  {
    primary_id: "D06PFT",
    names: ["Rhumab Beta7"],
  },
  {
    primary_id: "D06PGZ",
    names: ["Setrobuvir"],
  },
  {
    primary_id: "D06PHE",
    names: ["CPPG", "Cyclopropyl-4-Phosphonophenylglycine"],
  },
  {
    primary_id: "D06PHH",
    names: ["Rimacalib"],
  },
  {
    primary_id: "D06PHO",
    names: ["E-3030", "Dual PPAR Alpha/Gamma Agonists, Eisai"],
  },
  {
    primary_id: "D06PHS",
    names: ["EVT302"],
  },
  {
    primary_id: "D06PHT",
    names: ["1-Benzhydryl-4-Phenylpiperidin-4-Ol", "CHEMBL228690"],
  },
  {
    primary_id: "D06PII",
    names: ["SULTHIAME"],
  },
  {
    primary_id: "D06PIO",
    names: ["Indoline Derivative 6"],
  },
  {
    primary_id: "D06PIP",
    names: ["Aminopiperidine Derivative 2"],
  },
  {
    primary_id: "D06PIW",
    names: ["MRS4062"],
  },
  {
    primary_id: "D06PJD",
    names: [
      "3-(1H-Indol-1-Yl)-N-Methyl-3-Phenylpropan-1-Amine",
      "CHEMBL453838",
      "SCHEMBL2074980",
      "VDFAJYFMKVKZLC-UHFFFAOYSA-N",
      "BDBM50245797",
    ],
  },
  {
    primary_id: "D06PJK",
    names: ["Euro-Celtique 3"],
  },
  {
    primary_id: "D06PJW",
    names: ["GSK-1838705A"],
  },
  {
    primary_id: "D06PJZ",
    names: ["[Sar1,Bpa2]AngII", "CHEMBL1076601"],
  },
  {
    primary_id: "D06PKJ",
    names: ["1-(2,5-Dimethoxyphenyl)Propan-2-Amine", "2-(2,5-Dimethoxy-Phenyl)-1-Methyl-Ethylamine"],
  },
  {
    primary_id: "D06PKL",
    names: [
      "BPL-003",
      "BPL-003 Series (Pain)",
      "BPL-003 Series (Pain), Bradford Pharma",
      "BPL-003 Series (Pain), Redx Pharma",
    ],
  },
  {
    primary_id: "D06PKS",
    names: ["ONO-4007"],
  },
  {
    primary_id: "D06PLG",
    names: ["SB-203347", "169527-42-8"],
  },
  {
    primary_id: "D06PLP",
    names: ["Resten-MP"],
  },
  {
    primary_id: "D06PMR",
    names: ["PMID18754614C18"],
  },
  {
    primary_id: "D06PNN",
    names: ["Biaryl Mannoside Derivative 26"],
  },
  {
    primary_id: "D06PNU",
    names: ["Tic-D-Phe-Arg-2-Nal-NHCH3", "CHEMBL381357"],
  },
  {
    primary_id: "D06PPD",
    names: ["Imidazopyridine Acetamide Analog 2"],
  },
  {
    primary_id: "D06PPL",
    names: ["BIX-01294", "BIX01294", "BIX 01294"],
  },
  {
    primary_id: "D06PQR",
    names: ["OPC-8490"],
  },
  {
    primary_id: "D06PQT",
    names: [
      "Vitamin B1",
      "59-43-8",
      "Thiamine Monochloride",
      "Aneurine",
      "3-((4-Amino-2-Methylpyrimidin-5-Yl)Methyl)-5-(2-Hydroxyethyl)-4-Methylthiazol-3-Ium Chloride",
      "Tiamina",
      "Oryzanin",
      "Thiaminum",
      "Oryzanine",
      "Bethiamin",
      "Vitaneurin",
      "Beivon",
      "Apatate Drape",
      "Thiamine, Chloride",
      "B-Amin",
      "Thiamine [INN:BAN]",
      "Tiamina [INN-Spanish]",
      "Thiaminum [INN-Latin]",
      "UNII-X66NSO3N35",
      "CCRIS 5823",
      "HSDB 220",
      "Thiamine(1+) Chloride",
      "EINECS 200-425-3",
      "X66NSO3N35",
      "CHEBI:33283",
      "AK109288",
      "Thiamine (Vit B1)",
      "Thiazolium,",
    ],
  },
  {
    primary_id: "D06PRC",
    names: [
      "MANGOSTIN",
      "Alpha-Mangostin",
      "Mangostin",
      "6147/11/1",
      "1,3,6-Trihydroxy-7-Methoxy-2,8-Bis(3-Methylbut-2-En-1-Yl)-9H-Xanthen-9-One",
      "Mangostine",
      "NSC30552",
      "NSC27593",
      "NSC-30552",
      "UNII-U6RIV93RU1",
      "U6RIV93RU1",
      "CHEMBL323197",
      "CHEBI:67547",
      "GNRIZKKCNOBBMO-UHFFFAOYSA-N",
      "1,3,6-Trihydroxy-7-Methoxy-2,8-Diprenylxanthone",
      "TNP00140",
      "NSC 27593",
      "NSC 30552",
      "NSC 139154",
      "AK105375",
      "1,3,6-Trihydroxy-7-Methoxy-2,8-Bis(3-Methylbut-2-Enyl)Xanthen-9-One",
      "1,3,6-Trihydroxy-7-Methoxy-2,8-Bis(3-Methyl-2-Butenyl)-9H-Xanthen-9-One",
      "Q-100010",
    ],
  },
  {
    primary_id: "D06PRV",
    names: ["3-Chloro-4-(O-Tolylthio)Benzonitrile", "CHEMBL490956", "BDBM50248506"],
  },
  {
    primary_id: "D06PSM",
    names: ["PMID26666989-Compound-Figure9bottomright"],
  },
  {
    primary_id: "D06PSS",
    names: ["Nateglinide"],
  },
  {
    primary_id: "D06PSU",
    names: ["BAY1143572"],
  },
  {
    primary_id: "D06PTA",
    names: [
      "Levosalbutamol/Ipratropium",
      "8-Azoniabicyclo(3.2.1)Octane, 3-(3-Hydroxy-1-Oxo-2-Phenylpropoxy)-8-Methyl-8-(1-Methylethyl)-, (Endo,syn)-",
      "3-(3-Hydroxy-1-Oxo-2-Phenylpropoxy)-8-Methyl-8-(1-Methylethyl)-8-Azoniabicyclo(3.2.1)Octane (3-Endo,8-Syn)-",
    ],
  },
  {
    primary_id: "D06PTC",
    names: [
      "2-Methyl-6-(5-Phenylpent-1-Ynyl)Pyridine",
      "CHEMBL378040",
      "2-Methyl-6-(5-Phenylpent-1-Yn-1-Yl)Pyridine",
      "SCHEMBL5703524",
      "BDBM50191123",
    ],
  },
  {
    primary_id: "D06PTG",
    names: ["ARX788"],
  },
  {
    primary_id: "D06PTT",
    names: ["MS-424"],
  },
  {
    primary_id: "D06PTW",
    names: ["Thiazole Carboxamide Derivative 12"],
  },
  {
    primary_id: "D06PUS",
    names: ["3-(2-Bromophenyl)-2-Thioxo-(1H )-Quinazolin-4-One", "CHEMBL496735", "SCHEMBL18239972"],
  },
  {
    primary_id: "D06PVA",
    names: ["Volociximab"],
  },
  {
    primary_id: "D06PVF",
    names: [
      "(Hydroxyethyloxy)Tri(Ethyloxy)Octane",
      "19327-39-0",
      "3,6,9,12-Tetraoxaicosan-1-Ol",
      "3,6,9,12-Tetraoxaeicosan-1-Ol",
      "TETRAETHYLENE GLYCOL MONOOCTYL ETHER",
      "Tetraethylene Glycol Monoctyl Ether",
      "C8E4",
      "FEOZZFHAVXYAMB-UHFFFAOYSA-N",
      "C8E",
      "Octyltetraglycol",
      "Octyltetraethyleneoxide",
      "AC1L1KAZ",
      "AC1Q58VY",
      "SCHEMBL185557",
      "Octyl Tetraethylene Glycol Ether",
      "DTXSID3066479",
      "CHEBI:41323",
      "CTK0H5811",
      "Tetraethylene Glycol, Octyl Ether",
      "Tetraethyleneglycol Monooctyl Ether",
      "MolPort-003-939-472",
      "MFCD00043033",
      "ZINC14881140",
      "AKOS027320403",
      "DB04233",
      "KS-00000Y06",
    ],
  },
  {
    primary_id: "D06PVL",
    names: ["S-Adenosylmethionine"],
  },
  {
    primary_id: "D06PVN",
    names: ["R-65"],
  },
  {
    primary_id: "D06PVO",
    names: ["4-Phenyl-1-(1-Phenylpropyl)Piperidin-4-Ol", "CHEMBL433814", "SCHEMBL9520650"],
  },
  {
    primary_id: "D06PWA",
    names: ["Amidox"],
  },
  {
    primary_id: "D06PWI",
    names: ["CZC 24832", "CZC24832", "CZC-24832"],
  },
  {
    primary_id: "D06PWM",
    names: [
      "Acetic Acid 2,6-Diisopropyl-Phenyl Ester",
      "2,6-Diisopropylphenyl Acetate",
      "UVYYQAXNOURFPH-UHFFFAOYSA-N",
      "[2,6-Di(Propan-2-Yl)Phenyl] Acetate",
      "AC1LC89D",
      "CHEMBL56587",
      "SCHEMBL1927023",
      "UVYYQAXNOURFPH-UHFFFAOYSA-",
      "ZINC13779584",
      "InChI=1/C14H20O2/C1-9(2)12-7-6-8-13(10(3)4)14(12)16-11(5)15/H6-10H,1-5H3",
    ],
  },
  {
    primary_id: "D06PWQ",
    names: ["[14C]GlySar", "[14C]-GlySar", "Glycylsarcosine"],
  },
  {
    primary_id: "D06PXR",
    names: ["Hexadecanal"],
  },
  {
    primary_id: "D06PXT",
    names: ["CF602"],
  },
  {
    primary_id: "D06PXY",
    names: ["Alpha-Ergocryptine"],
  },
  {
    primary_id: "D06PYP",
    names: ["JTS-653"],
  },
  {
    primary_id: "D06PYV",
    names: [
      "2-Amino-3-(M-Tolylamino)Naphthalene-1,4-Dione",
      "CHEMBL598551",
      "MolPort-007-563-584",
      "ZINC27548840",
      "BDBM50306786",
      "AKOS001660486",
      "MCULE-4833073664",
      "EU-0076855",
    ],
  },
  {
    primary_id: "D06PYZ",
    names: ["XV454"],
  },
  {
    primary_id: "D06PZC",
    names: ["SAM-6"],
  },
  {
    primary_id: "D06PZE",
    names: ["TAMARIXETIN"],
  },
  {
    primary_id: "D06PZH",
    names: ["NSAIDs"],
  },
  {
    primary_id: "D06PZJ",
    names: ["[Gly8, Aib22]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D06PZK",
    names: ["GNF-PF-1399"],
  },
  {
    primary_id: "D06PZN",
    names: ["Mosapride"],
  },
  {
    primary_id: "D06PZO",
    names: ["ASC-101"],
  },
  {
    primary_id: "D06PZU",
    names: ["2-(2-Amino-Phenyl)-6-Methyl-Chromen-4-One", "CHEMBL132035"],
  },
  {
    primary_id: "D06PZZ",
    names: ["[14C]TEA"],
  },
  {
    primary_id: "D06QAJ",
    names: ["PS948115"],
  },
  {
    primary_id: "D06QAK",
    names: ["BMN-111", "C-Type Natriuretic Peptide Analog (Achondroplasia), BioMarin"],
  },
  {
    primary_id: "D06QBJ",
    names: ["PF-3644022"],
  },
  {
    primary_id: "D06QBK",
    names: ["KNI-10763"],
  },
  {
    primary_id: "D06QBV",
    names: ["BI 894999"],
  },
  {
    primary_id: "D06QBX",
    names: ["VDA-1102 Topical Ointment"],
  },
  {
    primary_id: "D06QBY",
    names: ["INT131", "C541917000"],
  },
  {
    primary_id: "D06QCC",
    names: [
      "Cefmenoxime",
      "Cefmax",
      "Cefmenoxima",
      "Cefmenoximum",
      "Tacef",
      "Cefmenoxime [INN]",
      "A 50912",
      "AB 50912",
      "SCE 1365",
      "Abbott-50192",
      "Cefmenoxima [INN-Spanish]",
      "Cefmenoxime (INN)",
      "Cefmenoximum [INN-Latin]",
      "Cefmenoxime Hydrochloride (2:1)",
      "(6R,7R)-(2-(2-Amino-4-Thiazolyl)Glyoxylamido)-3-(1-Methyl-1H-Tetrazol-5-Yl)Thiomethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2-Carbonsaeure-7-(Z)-(O-Methyloxim)",
      "(6R,7R)-7-((Z)-2-(2-Amino-4-Thiazolyl)-2-Methoxyiminoacetamido)-3-((1-Methyl-1H-5-Tetraazolylthio)Methyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2-Carbonsaeure",
      "(6R,7R)-7-({(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(Methyloxy)Imino]acetyl}amino)-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Thio]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2E)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-Methoxyiminoacetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid (IUPAC)",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetyl]amino}-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetyl]amino}-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D06QCE",
    names: ["MTKi-328"],
  },
  {
    primary_id: "D06QCS",
    names: ["GNF-156"],
  },
  {
    primary_id: "D06QDR",
    names: ["Choline Alfoscerate", "Delecit (TN)"],
  },
  {
    primary_id: "D06QDX",
    names: ["Bip-Tyr-Thr-Ala-Pro-Phe", "CHEMBL439332"],
  },
  {
    primary_id: "D06QEL",
    names: ["FARANOXI"],
  },
  {
    primary_id: "D06QEN",
    names: ["7-Methyl-7H-Pyrrolo[3,2-F]quinazoline-1,3-Diamine"],
  },
  {
    primary_id: "D06QEQ",
    names: ["OSI-75"],
  },
  {
    primary_id: "D06QER",
    names: ["Benzaldehyde O-4-(Nonyloxy)Phenylcarbamoyl Oxime", "CHEMBL597430"],
  },
  {
    primary_id: "D06QEW",
    names: ["CLT-003", "Chirovis", "DD-001"],
  },
  {
    primary_id: "D06QFO",
    names: [
      "(5-(Pyridin-3-Yl)Furan-2-Yl)Methanamine",
      "(5-(PYRIDIN-3-YL)FURAN-2-YL)METHANAMINE",
      "[5-(Pyridin-3-Yl)Furan-2-Yl]methanamine",
      "(5-Pyridin-3-Ylfuran-2-Yl)Methanamine",
      "1-(5-Pyridin-3-Ylfuran-2-Yl)Methanamine",
      "CHEMBL178090",
      "CHEBI:41804",
      "(5-Pyridin-3-Yl-2-Furyl)Methylamine",
      "837376-48-4",
      "2-Furanmethanamine, 5-(3-Pyridinyl)-",
      "SCHEMBL1791769",
      "GTPL8754",
      "BDBM12345",
      "MolPort-022-593-431",
      "ZINC13607134",
      "Nicotine 3-Heteroaromatic Analogue 2a",
      "AKOS022808436",
      "DB07621",
      "Compound 39a [PMID: 15634016]",
      "D3G",
      "US8609708, 2",
      "US8609708, 47",
      "F2167-0893",
    ],
  },
  {
    primary_id: "D06QGD",
    names: ["1-(4-Nitrophenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One", "CHEMBL379446", "SCHEMBL4938101"],
  },
  {
    primary_id: "D06QGH",
    names: [
      "Dukoral",
      "Colorvac",
      "SBL Cholera",
      "Vaccine (Cholera/ETEC), SBL Vaccin",
      "Vaccine (Oral, Cholera/Diarrhea), PowderJect",
      "Vaccine (Oral, Cholera/Diarrhea), SBL Vaccin",
    ],
  },
  {
    primary_id: "D06QGJ",
    names: [
      "3-(1-Benzylpiperidin-4-Yloxy)Benzamide",
      "3-((1-Benzylpiperidin-4-Yl)Oxy)Benzamide",
      "1254475-25-6",
      "CHEMBL1257457",
      "3-[(1-Benzylpiperidin-4-Yl)Oxy]benzamide",
      "DTXSID90680658",
      "ZINC64548741",
      "BDBM50327259",
      "AKOS022176132",
      "AJ-114866",
    ],
  },
  {
    primary_id: "D06QHT",
    names: ["Risotilide"],
  },
  {
    primary_id: "D06QID",
    names: ["2,6-Bis(4-Chlorophenyl)-9H-Purine", "CHEMBL426497"],
  },
  {
    primary_id: "D06QIP",
    names: ["AP-022"],
  },
  {
    primary_id: "D06QIQ",
    names: ["7-(Piperidin-4-Ylmethoxy)-2-Naphthonitrile", "CHEMBL444985"],
  },
  {
    primary_id: "D06QIW",
    names: ["EPO Peptide Mimetics"],
  },
  {
    primary_id: "D06QJE",
    names: ["F-992"],
  },
  {
    primary_id: "D06QJG",
    names: ["PG-1014491"],
  },
  {
    primary_id: "D06QJM",
    names: ["Ro 63-1908"],
  },
  {
    primary_id: "D06QJT",
    names: ["Z-Leu-Val-Agly-Val-OBzl"],
  },
  {
    primary_id: "D06QKD",
    names: [
      "4-(4-Isopropyl-Morpholin-2-Yl)-Benzene-1,2-Diol",
      "CHEMBL268881",
      "NSC622468",
      "4-(4-Isopropyl-2-Morpholinyl)-1,2-Benzenediol Hydrochloride",
      "AC1L7GLH",
      "AC1Q7ACF",
      "BDBM50000499",
      "AKOS022660109",
      "NCI60_006589",
      "4-(4-Isopropylmorpholin-2-Yl)Benzene-1,2-Diol",
      "4-(4-Propan-2-Ylmorpholin-2-Yl)Benzene-1,2-Diol",
      "1,2-Benzenediol, 4-[4-(1-Methylethyl)-2-Morpholinyl]-",
    ],
  },
  {
    primary_id: "D06QKV",
    names: ["Brodimoprim"],
  },
  {
    primary_id: "D06QKW",
    names: [
      "Cirazoline",
      "Cirazoline [INN]",
      "Cirazolinum [INN-Latin]",
      "Cirazolina [INN-Spanish]",
      "UNII-QK318GVY3Y",
      "59939-16-1",
      "LD-3098",
      "QK318GVY3Y",
      "CHEMBL13852",
      "NCGC00015196-04",
      "2-((O-Cyclopropylphenoxy)Methyl)-2-Imidazoline",
      "DSSTox_CID_25131",
      "DSSTox_RID_80692",
      "DSSTox_GSID_45131",
      "Cirazolina",
      "Cirazolinum",
      "1H-Imidazole, 2-((2-Cyclopropylphenoxy)Methyl)-4,5-Dihydro-",
      "2-[(2-Cyclopropylphenoxy)Methyl]-4,5-Dihydro-1H-Imidazole",
      "CAS-59939-16-1",
      "LD 3098",
      "Tocris-0888",
      "Lopac-C-223",
      "AC1L1EEZ",
      "Lopac0_000354",
      "KBioGR_000306",
      "BSPBio_001586",
      "KBioSS_000306",
      "GTPL515",
    ],
  },
  {
    primary_id: "D06QLG",
    names: ["Tyr-D-Ala-Gly-NMePhe", "CHEMBL583426"],
  },
  {
    primary_id: "D06QMA",
    names: ["MK-5286"],
  },
  {
    primary_id: "D06QMV",
    names: ["DWP-450"],
  },
  {
    primary_id: "D06QNN",
    names: [
      "N-Benzyl-2-Oxo-2H-Chromene-3-Carboxamide",
      "3-(Benzylcarbamoyl)Coumarin",
      "BRN 0257384",
      "Coumarin, 3-(Benzylcarbamoyl)-",
      "2H-1-Benzopyran-3-Carboxamide, 2-Oxo-N-(Phenylmethyl)-",
      "1846-90-8",
      "2-Oxo-2H-Chromene-3-Carboxylic Acid Benzylamide",
      "2-Oxo-N-(Phenylmethyl)-2H-1-Benzopyran-3-Carboxamide",
      "AC1L3R3J",
      "Oprea1_583472",
      "Oprea1_560940",
      "3-Carboxamido Coumarin, 36",
      "MLS001210650",
      "CHEMBL469976",
      "CTK0H5459",
      "BDBM29186",
      "DTXSID90171596",
      "MolPort-000-375-285",
      "ZINC130283",
      "HMS2837I12",
      "STK078064",
      "N-Benzyl-2-Oxochromene-3-Carboxamide",
      "AKOS002234224",
    ],
  },
  {
    primary_id: "D06QOA",
    names: ["PSI-938"],
  },
  {
    primary_id: "D06QOC",
    names: ["GX-301"],
  },
  {
    primary_id: "D06QOD",
    names: ["CT-120"],
  },
  {
    primary_id: "D06QOF",
    names: ["R7128"],
  },
  {
    primary_id: "D06QOJ",
    names: ["RS-8359", "CS-8359", "TUK-9261", "TUK-9262", "TUK-9508"],
  },
  {
    primary_id: "D06QOK",
    names: ["DW-2282"],
  },
  {
    primary_id: "D06QOM",
    names: ["TP-101"],
  },
  {
    primary_id: "D06QOX",
    names: [
      "1,3-Diphenyl-1H-Chromeno[4,3-C]pyrazol-4-One",
      "100008-84-2",
      "[1]Benzopyrano[4,3-C]pyrazol-4(1H)-One, 1,3-Diphenyl-",
      "ACMC-20m32m",
      "CHEMBL99633",
      "CTK0G9179",
      "DTXSID70541117",
      "AKOS030534409",
      "J3.620.041C",
      "1,3-Diphenyl[1]benzopyrano[4,3-C]pyrazole-4(1H)-One",
    ],
  },
  {
    primary_id: "D06QPI",
    names: ["NY-ESO-1 Tumour Vaccine"],
  },
  {
    primary_id: "D06QPW",
    names: ["Aniline Derivative 1"],
  },
  {
    primary_id: "D06QQH",
    names: ["T-1210"],
  },
  {
    primary_id: "D06QQS",
    names: [
      "Hydroxyclemastine",
      "AC1O52GA",
      "(2R)-2-[2-[(1R)-1-(4-Chlorophenyl)-1-Phenylethoxy]ethyl]-1-Methylpyrrolidin-1-Ium",
    ],
  },
  {
    primary_id: "D06QRI",
    names: ["7-Dimethylamino-2-Phenyl-[1,8]naphthyridin-4-Ol", "CHEMBL102429", "ZINC13579545", "BDBM50147858"],
  },
  {
    primary_id: "D06QRP",
    names: ["CP-481,715", "CP481715", "CP 481715", "CP-481715"],
  },
  {
    primary_id: "D06QRW",
    names: [
      "Lazabemide",
      "103878-84-8",
      "Ro-19-6327",
      "N-(2-Aminoethyl)-5-Chloropicolinamide",
      "CCRIS 7301",
      "UNII-420HD787N9",
      "N-(2-Aminoethyl)-5-Chloropyridine-2-Carboxamide",
      "Ro 19-6327 Hydrate",
      "CHEMBL279390",
      "Ro 19-6327/000",
      "420HD787N9",
      "C8H10ClN3O",
      "2-Pyridinecarboxamide, N-(2-Aminoethyl)-5-Chloro-",
      "N-(2-Aminoethyl)-5-Chloro-2-Pyridinecarboxamide Hydrate",
      "N-(2-Aminoethyl)-5-Chloro-2-Pyridinecarboxamide;N-(2-Aminoethyl)-5-Chloro-2-Pyridinecarboxamide",
      "NCGC00167737-01",
      "Lazabemide [USAN:INN]",
      "Lazabemide [USAN:INN:BAN]",
      "Lazabemine",
      "Pakio",
      "Tempium",
      "Ro-41-3776",
      "Ro-19-6327/000",
      "LAZEBEMIDE",
    ],
  },
  {
    primary_id: "D06QRY",
    names: ["IDN-5390"],
  },
  {
    primary_id: "D06QSN",
    names: ["Itolizumab"],
  },
  {
    primary_id: "D06QTC",
    names: ["FR230513"],
  },
  {
    primary_id: "D06QUJ",
    names: [
      "3-(3-Methoxybenzyl)-1H-Pyrrolo[2,3-B]pyridine",
      "CHEMBL1231606",
      "3c4f",
      "SCHEMBL857805",
      "BDBM50065455",
      "DB07525",
      "3-(3-Methoxybenzyl)-7-Aza-1H-Indole",
      "3-[(3-Methoxyphenyl)Methyl]-1H-Pyrrolo[2,3-B]pyridine",
    ],
  },
  {
    primary_id: "D06QUQ",
    names: [
      "CGP 77675",
      "234772-64-6",
      "CGP-77675",
      "UNII-EQH27E0WRV",
      "1-(4-(4-Amino-5-(3-Methoxyphenyl)-7H-Pyrrolo[2,3-D]pyrimidin-7-Yl)Phenethyl)Piperidin-4-Ol",
      "CGP77675",
      "EQH27E0WRV",
      "CHEMBL475584",
      "1-[2-[4-[4-Amino-5-(3-Methoxyphenyl)Pyrrolo[2,3-D]pyrimidin-7-Yl]phenyl]ethyl]piperidin-4-Ol",
      "4-Piperidinol, 1-(2-(4-(4-Amino-5-(3-Methoxyphenyl)-7H-Pyrrolo(2,3-D)Pyrimidin-7-Yl)Phenyl)Ethyl)-",
      "4-Piperidinol, 1-[2-[4-[4-Amino-5-(3-Methoxyphenyl)-7h-Pyrrolo[2,3-D]pyrimidin-7-Yl]phenyl]ethyl]-",
      "AC1NSKIU",
      "SCHEMBL6250604",
      "CTK8E3165",
      "DTXSID20178033",
    ],
  },
  {
    primary_id: "D06QUY",
    names: ["CO-18", "Anti-Obesity Compound, C&O Pharmaceutical"],
  },
  {
    primary_id: "D06QVB",
    names: ["4-(Naphthalene-1-Sulfonyl)-Phenylamine", "CHEMBL181382", "SCHEMBL10775871"],
  },
  {
    primary_id: "D06QWM",
    names: ["OI338GT"],
  },
  {
    primary_id: "D06QWO",
    names: [
      "TALAROFLAVONE",
      "CHEMBL408995",
      "NCGC00381160-01",
      "NCGC00381160-01_C14H12O6_Spiro[cyclopent-4-Ene-1,1'(3'H)-Isobenzofuran]-3,3'-Dione, 2,4'-Dihydroxy-6'-Methoxy-5-Methyl-",
    ],
  },
  {
    primary_id: "D06QWS",
    names: ["Fluarix Quadrivalent"],
  },
  {
    primary_id: "D06QWY",
    names: ["Benzene Sulfonamide Derivative 9"],
  },
  {
    primary_id: "D06QXI",
    names: [
      "NRX-4204",
      "AGN-190205",
      "AGN-191659",
      "AGN-191701",
      "AGN-192326",
      "AGN-192327",
      "AGN-192509",
      "AGN-192599",
      "AGN-192870",
      "AGN-194277",
      "AGN-195393",
      "AGN-4202",
      "ALRT-326",
      "ALRT-4204",
      "LGD-4204",
      "LGD-4326",
      "NRX-194202",
      "RXR Ligands, ALRT",
      "RXR Ligands, Allergan",
      "Retinoid X Receptor Agonists, ALRT",
      "Retinoid X Receptor Agonists, Allergan",
      "VTP-4204",
      "RXR Agonists, Allergan/Pfizer",
      "Rexinoid Receptor Agonist (Cancer), NuRx",
      "RXR Agonists (Cancer/ Diabetes), Vitae Pharmaceuticals",
      "RXR Agonists, Allergan/Parke-Davis",
    ],
  },
  {
    primary_id: "D06QXT",
    names: [
      "4-[2-(6-Phenyl-Hexylamino)-Ethyl]-Phenol",
      "CHEMBL420530",
      "765883-50-9",
      "CTK2G7588",
      "DTXSID10444870",
      "BDBM50066547",
      "4-[2-[(6-Phenylhexyl)Amino]ethyl]phenol",
      "Phenol, 4-[2-[(6-Phenylhexyl)Amino]ethyl]-",
    ],
  },
  {
    primary_id: "D06QYJ",
    names: ["Anticancer Glycosaminoglycans", "Anticancer Glycosaminoglycans, Matrix", "T-21"],
  },
  {
    primary_id: "D06QYM",
    names: ["PMID21310611C3"],
  },
  {
    primary_id: "D06QYR",
    names: ["PF-4800567"],
  },
  {
    primary_id: "D06QYV",
    names: ["FR167344"],
  },
  {
    primary_id: "D06QZK",
    names: ["ISIS 112005"],
  },
  {
    primary_id: "D06QZL",
    names: ["Lerisetron"],
  },
  {
    primary_id: "D06RAM",
    names: ["MM 11253"],
  },
  {
    primary_id: "D06RBE",
    names: ["Tricyclic Compound 7"],
  },
  {
    primary_id: "D06RBG",
    names: ["3-Keto-Lithocholic Acid", "Dehydrolithocholic Acid", "3-Keto-LCA"],
  },
  {
    primary_id: "D06RBJ",
    names: ["DIPROTEVERINE HYDROCHLORIDE"],
  },
  {
    primary_id: "D06RBL",
    names: ["PMID26394986-Compound-21"],
  },
  {
    primary_id: "D06RBN",
    names: ["Protein Vaccines"],
  },
  {
    primary_id: "D06RCB",
    names: [
      "Diethylcarbamazine",
      "Bitirazine",
      "Camin",
      "Caracide",
      "Carbamazine",
      "Carbilazine",
      "Caricide",
      "Cypip",
      "Decacide",
      "Diaethylcarbamazinum",
      "Diethylcarbamazinum",
      "Dietilcarbamazina",
      "Ethodryl",
      "Luxuran",
      "Notezine",
      "Spatonin",
      "Diethyl Carbamazine",
      "Ditrazine Base",
      "Banocide (TN)",
      "Camin (TN)",
      "Carbilazine (TN)",
      "Caricide (TN)",
      "Cypip (TN)",
      "Diethylcarbamazine (INN)",
      "Diethylcarbamazine [INN:BAN]",
      "Diethylcarbamazinum [INN-Latin]",
      "Dietilcarbamazina [INN-Spanish]",
      "Ethodryl (TN)",
      "FR-1031",
      "Filaribits (TN)",
      "Forte (TN)",
      "Hetrazan (TN)",
      "Spatonin (TN)",
      "N,N-Diethyl-4-Methyl-1-Piperazinecarboxamide",
      "N,N-Diethyl-4-Methylpiperazine-1-Carboxamide",
      "1-Diethylcarbamyl-4-Methylpiperazine",
      "84L",
    ],
  },
  {
    primary_id: "D06RCE",
    names: ["HER2-Specific T Cells"],
  },
  {
    primary_id: "D06RCI",
    names: ["L-731128"],
  },
  {
    primary_id: "D06RCS",
    names: [
      "Besonprodil",
      "Co 200461",
      "PD 0196860",
      "PD 196860",
      "PD196860",
      "Besonprodil (USAN)",
      "2(3H)-Benzoxazolone, 6-((2-(4-((4-Fluorophenyl)Methyl)-1-Piperidinyl)Ethyl)Sulfinyl)",
      "6-(2-(4-(4-Fluorobenzyl)-Piperidin-1-Yl)-Ethanesulfinyl)-3H-Benzoxazol-2-One",
      "6-[2-[4-[(4-Fluorophenyl)Methyl]piperidin-1-Yl]ethylsulfinyl]-3H-1,3-Benzoxazol-2-One",
    ],
  },
  {
    primary_id: "D06RCT",
    names: [
      "2-(3''-Indolylethyloxy)Adenosine",
      "CHEMBL223366",
      "SCHEMBL8111144",
      "2-(3'-Indolylethyloxy)Adenosine",
      "BDBM50208804",
      "2-(3''''-Indolylethyloxy)Adenosine",
      "2-[2-(1H-Indol-3-Yl)Ethoxy]adenosine",
    ],
  },
  {
    primary_id: "D06REI",
    names: ["ISIS 3246"],
  },
  {
    primary_id: "D06REJ",
    names: [
      "MX-7065",
      "Cdc25 Phosphatase Inhibitors, Maxia Pharmaceuticals",
      "MX-7102",
      "MX-7174",
      "MX-7214",
      "MX-7306",
    ],
  },
  {
    primary_id: "D06REO",
    names: ["Befunolol Hci", "Bentox (TN)"],
  },
  {
    primary_id: "D06RFG",
    names: [
      "Estradiol Valerate/Dienogest",
      "Climodien",
      "Climodiene",
      "Dienogest/Estradiol Valerate",
      "DNG/E2V",
      "E2V/DNG",
      "Klimodien",
      "Lafamme",
    ],
  },
  {
    primary_id: "D06RFK",
    names: [
      "DTaP-IPV-Hib",
      "Certiva-IPV-Hib",
      "DTaP-IPV-Hib, North American Vaccine",
      "Vaccine (Diphtheria/Tetanus/Pertussis/Polio/Haemophilus Influenzae), North American Vaccine",
    ],
  },
  {
    primary_id: "D06RFO",
    names: ["Benzofurans Derivative 1"],
  },
  {
    primary_id: "D06RFW",
    names: [
      "N,N'-Bis-(4-Isopropyl-Phenyl)-Guanidine",
      "CHEMBL81626",
      "SCHEMBL6154563",
      "BDBM50010754",
      "ZINC13733773",
      "1,3-Bis(4-Isopropylphenyl)Guanidine",
    ],
  },
  {
    primary_id: "D06RGG",
    names: ["Levothyroxine"],
  },
  {
    primary_id: "D06RGW",
    names: ["PMID25684022-Compound-WO2004087707"],
  },
  {
    primary_id: "D06RGX",
    names: [
      "NSC-319745",
      "61629-60-5",
      "HB 093",
      "BRN 2168571",
      "4-(2-((5-Chloro-2-Methoxybenzoyl)Amino)Ethyl)Hydrocinnamic Acid",
      "3-[4-[2-[(5-CHLORO-2-METHOXY-BENZOYL)AMINO]ETHYL]PHENYL]PROPANOIC ACID",
      "3-(4-(2-(5-Chlor-2-Methoxy-Benzamido)-Aethyl)Phenyl)-Propionsaeure [German]",
      "3-[4-[2-[(5-Chloro-2-Methoxybenzoyl)Amino]ethyl]phenyl]propanoic Acid",
      "HYDROCINNAMIC ACID, 4-(2-((5-CHLORO-2-METHOXYBENZOYL)AMINO)ETHYL)-",
      "AC1L2AFL",
      "CHEMBL597112",
      "SCHEMBL11481071",
      "CTK5B3505",
      "DTXSID00210642",
      "AIEFQKOARQRACO-UHFFFAOYSA-N",
      "ZINC1572211",
      "HB-093",
      "NSC319745",
    ],
  },
  {
    primary_id: "D06RHC",
    names: ["DS-1040"],
  },
  {
    primary_id: "D06RHG",
    names: ["ISIS 101952"],
  },
  {
    primary_id: "D06RID",
    names: ["4-(Cyclohexyloxy)Quinazoline", "CHEMBL471866"],
  },
  {
    primary_id: "D06RIK",
    names: ["N-(3,5-Dimethoxyphenethyl)Oleamide"],
  },
  {
    primary_id: "D06RIM",
    names: ["LIM-0705"],
  },
  {
    primary_id: "D06RIO",
    names: ["Piperazine Derivative 3"],
  },
  {
    primary_id: "D06RJQ",
    names: ["1-(3-Chloro-Phenyl)-3-(4-Hydroxy-Decyl)-Urea", "CHEMBL66498"],
  },
  {
    primary_id: "D06RKK",
    names: ["SEMIPLENAMIDE A"],
  },
  {
    primary_id: "D06RKS",
    names: [
      "1-(Phenylsulfonyl)Butan-2-One",
      "CHEMBL240761",
      "1-(Phenylsulfonyl)-2-Butanone",
      "33597-94-3",
      "SCHEMBL12925480",
      "CTK1B8309",
      "DTXSID40452605",
      "2-Butanone, 1-(Phenylsulfonyl)-",
      "BDBM50212545",
    ],
  },
  {
    primary_id: "D06RLF",
    names: [
      "(1-Amino-Ethyl)-Phosphinic Acid",
      "1-Aminoethylphosphinic Acid",
      "AMINOETHYLPHOSPHINIC ACID",
      "74333-44-1",
      "71937-28-5",
      "1-Aminoethylphosphinate",
      "(1-Aminoethyl)Phosphonous Acid",
      "Aminoethylphosphinic Acid [INCI]",
      "Phosphinic Acid, (1-Aminoethyl)-",
      "Phosphinic Acid, P-(1-Aminoethyl)-",
      "AC1O4DZP",
      "Phosphinic Acid, (1-Aminoethyl)-, (+/-)-",
      "1-Aminoethyl Phosphinic Acid",
      "SCHEMBL579981",
      "(1-Aminoethyl)Phosphinic Acid",
      "(1-Aminoethyl)-Phosphinic Acid",
      "CTK5D5277",
      "TVKUNRSARHGLNB-UHFFFAOYSA-N",
      "1-Aminoethyl-Hydroxy-Oxophosphanium",
      "AKOS006273172",
      "Phosphinic Acid, (1-Aminoethyl)-, (-)-",
    ],
  },
  {
    primary_id: "D06RLU",
    names: [
      "ACT-128800",
      "CHEMBL1095833",
      "(Z)-5-((Z)-3-Chloro-4-((S)-2,3-Dihydroxypropoxy)Benzylidene)-2-(Propylimino)-3-(O-Tolyl)Thiazolidin-4-One",
      "CS-B1656",
      "ZINC49784144",
      "BDBM50316769",
      "CS-15178",
      "854111-49-2",
    ],
  },
  {
    primary_id: "D06RNK",
    names: ["Hi8 HBV Vaccine"],
  },
  {
    primary_id: "D06RNM",
    names: ["Burimamide", "1-[4-(1H-Indazol-4-Yl)Butyl]-3-Methylthiourea", "AC1NSKDK", "GTPL1229"],
  },
  {
    primary_id: "D06RNU",
    names: ["Quinazoline Derivative 2"],
  },
  {
    primary_id: "D06ROA",
    names: ["UH-301"],
  },
  {
    primary_id: "D06RPY",
    names: ["2-[4-(1-Ethyl-Propyl)-Piperazin-1-Yl]-Quinoline", "CHEMBL360156", "SCHEMBL3971032"],
  },
  {
    primary_id: "D06RQD",
    names: ["Anti-GD2 Mab", "Anti-GD2 MAb (Cancer)", "Human MAb 1B7 (Cancer), MabVax", "Anti-GD2 MAb (Cancer), MabVax"],
  },
  {
    primary_id: "D06RQU",
    names: ["HC-Toxin"],
  },
  {
    primary_id: "D06RQV",
    names: ["2-Methyl-3-(Pyridin-4-Yl)-1H-Indol-6-Amine", "CHEMBL207502"],
  },
  {
    primary_id: "D06RRD",
    names: [
      "Fibrates",
      "Clofibric Acid",
      "882-09-7",
      "2-(4-Chlorophenoxy)-2-Methylpropanoic Acid",
      "Chlorofibrinic Acid",
      "Clofibrinic Acid",
      "Chlorfibrinic Acid",
      "Clofibrin",
      "2-(4-CHLOROPHENOXY)-2-METHYLPROPIONIC ACID",
      "Chlorophibrinic Acid",
      "PCIB",
      "Clofibrate Free Acid",
      "Clofibrinsaeure",
      "Regulipid",
      "Regadrin",
      "PCPIB",
      "2-(P-Chlorophenoxy)-2-Methylpropionic Acid",
      "2-(4-Chlorophenoxy)Isobutyric Acid",
      "4-CPIB",
      "2-(P-Chlorophenoxy)Isobutyric Acid",
      "Propanoic Acid, 2-(4-Chlorophenoxy)-2-Methyl-",
      "Alpha-(P-Chlorophenoxy)Isobutyric Acid",
      "Acido Clofibrico",
      "Acide Clofibrique",
      "Acidum C",
    ],
  },
  {
    primary_id: "D06RRT",
    names: ["Acetyl-Pro-Ala-Pro-Ala-Trifluoro Methane", "CHEMBL130718", "BDBM50014740"],
  },
  {
    primary_id: "D06RSD",
    names: ["1-(4-Cyanobenzyl)-5-(4-Pyridyl)-1H-Imidazole", "CHEMBL599602"],
  },
  {
    primary_id: "D06RSE",
    names: ["V935"],
  },
  {
    primary_id: "D06RSF",
    names: ["4-(3-Methoxybenzylthio)-2-Aminobutanoic Acid", "CHEMBL204963", "BDBM50179714"],
  },
  {
    primary_id: "D06RSR",
    names: ["Bz", "CHEMBL257921"],
  },
  {
    primary_id: "D06RTA",
    names: ["N,N'-(1',4'-Butylene)-Bis-(-)-Nor-MEP", "CHEMBL409858"],
  },
  {
    primary_id: "D06RTP",
    names: ["C[Nle-Val-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL413573", "BDBM50184361"],
  },
  {
    primary_id: "D06RUD",
    names: ["FT-011", "Antifibrotic Agent, Fibrotech"],
  },
  {
    primary_id: "D06RUK",
    names: ["Combotox"],
  },
  {
    primary_id: "D06RUL",
    names: ["Bitolterol"],
  },
  {
    primary_id: "D06RVQ",
    names: [
      "Xenon",
      "7440-63-3",
      "UNII-3H3U766W84",
      "EINECS 231-172-7",
      "UN2036",
      "UN2591",
      "XE",
      "Xenon Atom",
      "CHEBI:49957",
      "3H3U766W84",
      "Xenon Dihydride",
      "Xenon Hydride",
      "Xenon, Compressed",
      "Xenopure (TN)",
      "Xenon(0)",
      "Xenon (JAN)",
      "Xenon 99+%",
      "[Xe]",
      "54Xe",
      "AC1L2N4V",
      "CHEMBL1236802",
      "DTXSID5064700",
      "Xenon, &gt;=99.995%",
      "CHEBI:49956",
      "CTK2H8446",
      "MolPort-001-770-634",
      "AKOS025310455",
      "Xenon, 99.99%, Messer(R) CANGas",
      "LS-162568",
      "D01901",
      "C13373",
      "Xenon, Refrigerated Liquid (Cryogenic Liquids)",
      "Xenon, Compressed [UN2036] [Nonflammable Gas]",
      "Xenon, Compressed [UN2036]  [Nonflammable Gas]",
    ],
  },
  {
    primary_id: "D06RVY",
    names: ["SIR-1076", "Antioxidants (Topical, Retinal Oxidative Stress), Sirion Therapeutics"],
  },
  {
    primary_id: "D06RWH",
    names: ["2-Amino-N-[4-(Phenylmethoxy)Phenyl]-Acetamide"],
  },
  {
    primary_id: "D06RWL",
    names: ["PMID27109571-Compound-35"],
  },
  {
    primary_id: "D06RWM",
    names: ["Carmeseal-MD"],
  },
  {
    primary_id: "D06RYG",
    names: ["PRAME-SLP"],
  },
  {
    primary_id: "D06RYU",
    names: ["RLP-035"],
  },
  {
    primary_id: "D06RYY",
    names: ["ZD-9379"],
  },
  {
    primary_id: "D06RZQ",
    names: [
      "HG-1091",
      "ABCD 1 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "CCL22 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "Chemokine CC Motif Ligand 22 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "MDC Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "MGC34554 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "SCYA22 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "Small Inducible Cytokine A22 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "Stimulated T Cell Chemotactic Protein I Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "Macrophage-Derived Chemokine Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "STCP-1 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "Small Inducible Cytokine Subfamily A, Member 22 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "A-152E5.1 Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
      "DC/B-CK Modulator (Inflammation/Immune Diseases), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D06SAT",
    names: [
      "HZ-166",
      "Benzodiazepine Derivatives (Neuropathic Pain)",
      "Benzodiazepine Derivatives (Neuropathic Pain), Addiction Therapeutix",
    ],
  },
  {
    primary_id: "D06SBB",
    names: ["Basiliximab"],
  },
  {
    primary_id: "D06SBD",
    names: ["DS-2248"],
  },
  {
    primary_id: "D06SBK",
    names: ["Atocalcitol"],
  },
  {
    primary_id: "D06SBL",
    names: ["MM-121"],
  },
  {
    primary_id: "D06SBO",
    names: ["Zoster"],
  },
  {
    primary_id: "D06SBS",
    names: ["SSR-504734", "2-Chloro-N-[(R)-Phenyl-(2-Piperidyl)Methyl]-3-(Trifluoromethyl)Benzamide Hydrochloride"],
  },
  {
    primary_id: "D06SCF",
    names: [
      "Biliverdine Ix Alpha",
      "Biliverdine",
      "UNII-O9MIA842K9",
      "NSC 62793",
      "O9MIA842K9",
      "CHEBI:17033",
      "Biliverdin IX",
      "8,12-Bis(2-Carboxyethyl)-2,7,13,17-Tetramethyl-3,18-Divinylbilin-1(19)(21H,24H)-Dione",
      "Biliverdine Dihydrochloride",
      "QBUVFDKTZJNUPP-BBROENKCSA-N",
      "Protobiliverdin IX",
      "EINECS 204-042-2",
    ],
  },
  {
    primary_id: "D06SCK",
    names: ["MB-311"],
  },
  {
    primary_id: "D06SCP",
    names: ["VISION 5"],
  },
  {
    primary_id: "D06SCQ",
    names: ["QBM076"],
  },
  {
    primary_id: "D06SCS",
    names: ["WAY200070"],
  },
  {
    primary_id: "D06SCY",
    names: ["BM-32"],
  },
  {
    primary_id: "D06SDD",
    names: ["MGS0028"],
  },
  {
    primary_id: "D06SDG",
    names: ["D[Leu4,Dab8]VP", "CHEMBL412973"],
  },
  {
    primary_id: "D06SDP",
    names: ["Melanotetan II"],
  },
  {
    primary_id: "D06SDQ",
    names: ["UCB0599"],
  },
  {
    primary_id: "D06SDT",
    names: ["ISIS 2974"],
  },
  {
    primary_id: "D06SEA",
    names: ["CX-7000"],
  },
  {
    primary_id: "D06SEL",
    names: ["NNRTIs"],
  },
  {
    primary_id: "D06SEY",
    names: ["Etilevodopa"],
  },
  {
    primary_id: "D06SFA",
    names: ["3-(3,4-Dimethylphenyl)-1,5-Naphthyridine", "CHEMBL236264"],
  },
  {
    primary_id: "D06SFX",
    names: ["Soybean Oil"],
  },
  {
    primary_id: "D06SGU",
    names: [
      "CVT-7124",
      "CHEMBL251876",
      "SCHEMBL1945825",
      "BDBM50233088",
      "1-Ethyl-8-(1-(3-(Trifluoromethyl)Benzyl)-1H-Pyrazol-4-Yl)-1H-Purine-2,6(3H,7H)-Dione",
    ],
  },
  {
    primary_id: "D06SHZ",
    names: ["SHP616"],
  },
  {
    primary_id: "D06SIG",
    names: ["AR177"],
  },
  {
    primary_id: "D06SIH",
    names: [
      "Aspartate Semialdehyde",
      "(2S)-2-Ammonio-4-Oxobutanoate",
      "L-Aspartate 4-Semialdehyde",
      "Aspartate Semi-Aldehyde",
      "L-Aspartate Beta-Semialdehyde",
      "15106-57-7",
      "AC1NRA59",
      "L-2-Ammonio-4-Oxobutanoate",
      "HOSWPDPVFBCLSY-VKHMYHEASA-N",
      "CHEBI:537519",
      "(2S)-2-Azaniumyl-4-Oxobutanoate",
      "L-Aspartic Acid 4-Semialdehyde Betaine",
      "(2S)-2-Azaniumyl-4-Oxidanylidene-Butanoate",
    ],
  },
  {
    primary_id: "D06SJO",
    names: ["GSK-2282512A"],
  },
  {
    primary_id: "D06SJU",
    names: ["Peptide Analog 26"],
  },
  {
    primary_id: "D06SJZ",
    names: ["XB-947"],
  },
  {
    primary_id: "D06SKI",
    names: ["Sulfamic Acid Ester Derivative 1"],
  },
  {
    primary_id: "D06SKK",
    names: ["JWH-440"],
  },
  {
    primary_id: "D06SKN",
    names: [
      "SU5403",
      "189894-57-3",
      "1-((1S,2S)-1-Hydroxy-1-(4-Hydroxyphenyl)Propan-2-Yl)-4-Phenylpiperidin-4-Ol Methanesulfonate Trihydrate",
      "UNII-BD2A56I30W",
      "BD2A56I30W",
      "Traxoprodil Mesylate, CP-101606-27",
      "Traxoprodil Mesylate [USAN:INN]",
      "(1S,2S)-1-(4-HYDROXYPHENYL)-2-(4-HYDROXY-4-PHENYLPIPERIDIN-1-YL)-1-PROPANOL METHANESULFONATE TRIHYDRATE",
      "Traxoprodil Mesylate Hydrate",
      "CHEMBL3989673",
      "KS-00000REE",
      "MolPort-023-219-113",
      "AKOS025396623",
      "RL02411",
      "DS-5988",
      "BCP9000543",
    ],
  },
  {
    primary_id: "D06SME",
    names: ["5-Hydroxy-1-Tosyl-1H-Pyrrol-2(5H)-One", "CHEMBL1163554", "BDBM50321257"],
  },
  {
    primary_id: "D06SMJ",
    names: ["[Mpa1, D-Tyr(Et)2, Gly(But)3, Gly(But)7]OT", "CHEMBL232501"],
  },
  {
    primary_id: "D06SMN",
    names: [
      "(R)-2-(4-Benzylphenoxymethyl)Pyrrolidine",
      "CHEMBL571091",
      "(2r)-2-[(4-Benzylphenoxy)Methyl]pyrrolidine",
      "SCHEMBL4319733",
      "NXNMORHGFGYRDN-QGZVFWFLSA-N",
      "BDBM50303648",
    ],
  },
  {
    primary_id: "D06SMP",
    names: ["SMT-15000"],
  },
  {
    primary_id: "D06SMU",
    names: ["SMT-D003"],
  },
  {
    primary_id: "D06SMY",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Nonyl Ester",
      "CHEMBL80330",
      "BDBM50116813",
      "4-(Sulfamoyloxy)Benzoic Acid Nonyl Ester",
    ],
  },
  {
    primary_id: "D06SNS",
    names: ["Mirostipen"],
  },
  {
    primary_id: "D06SNV",
    names: ["MORAb-28"],
  },
  {
    primary_id: "D06SOQ",
    names: [
      "Alpha5IA",
      "215874-86-5",
      "3-(5-Methylisoxazol-3-Yl)-6-[(1-Methyl-1H-1,2,3-Triazol-4-Yl)Methoxy][1,2,4]triazolo[3,4-A]phthalazine",
      "UNII-1M7NI1A92L",
      "1M7NI1A92L",
      "CHEMBL306422",
      "L-822179",
      "Alpha-5IA",
      "3-(5-METHYLISOXAZOL-3-YL)-6-[(1-METHYL-1H-1,2,3-TRIAZOL-4-YL)METHOXY][1,2,4]TRIAZOLO[3,4-A]PHTHALAZI",
      "3-(5-Methylisoxazol-3-Yl)-6-[(1-Methyl-1,2,3-Triazol-4-Yl)Methyloxy]-1,2,4-Triazolo[3,4-A]phthalazine",
      "A5ia",
      "Alpha 5IA",
      "1,2,4-Triazolo[3,4-A]phthalazine, 3-(5-Methyl-3-Isoxazolyl)-6-[(1-Methyl-1H-1,2,3-Triazol-4-Yl)Methoxy]-",
    ],
  },
  {
    primary_id: "D06SPO",
    names: [
      "3,5-Dichlorosalicylic Acid",
      "3,5-DICHLOROSALICYLIC ACID",
      "3,5-Dichloro-2-Hydroxybenzoic Acid",
      "320-72-9",
      "Benzoic Acid, 3,5-Dichloro-2-Hydroxy-",
      "Salicylic Acid, 3,5-Dichloro-",
      "USAF DO-68",
      "2-Hydroxy-3,5-Dichlorobenzoic Acid",
      "3,5-Dichlorosalicylicacid",
      "UNII-O6PXR32G3V",
      "3,5-Dichlorosalicyclic Acid",
      "HSDB 5562",
      "EINECS 206-281-8",
      "NSC 30109",
      "3,5-Dichloro-2-Hydroxy-Benzoic Acid",
      "BRN 2210803",
      "O6PXR32G3V",
      "AI3-22601",
      "CHEMBL449129",
      "CNJGWCQEGROXEE-UHFFFAOYSA-N",
      "C2U",
      "ACMC-1CUK7",
      "Salicylic Acid,5-Dichloro-",
      "AC1Q3M9M",
      "WLN: QVR BQ CG EG",
      "DSSTox_RID_80582",
      "DSSTox_CID_24914",
    ],
  },
  {
    primary_id: "D06SRB",
    names: [
      "Brain-Derived Neurotrophic Factor Peptidomimetics",
      "Brain-Derived Neurotrophic Factor Peptidomimetics (Rett Syndrome)",
    ],
  },
  {
    primary_id: "D06SRX",
    names: ["MDA-19", "Cannabinoid CB2 Agonist (Neuropathic Pain), MD Anderson Cancer Center"],
  },
  {
    primary_id: "D06SSR",
    names: ["AVP-26452", "AZD2479"],
  },
  {
    primary_id: "D06STK",
    names: ["Purine Derivative 2"],
  },
  {
    primary_id: "D06STX",
    names: ["Thiadiazolyl Carboxamide Derivative 1"],
  },
  {
    primary_id: "D06SUS",
    names: ["AZD-3043"],
  },
  {
    primary_id: "D06SVC",
    names: ["M-0011"],
  },
  {
    primary_id: "D06SVW",
    names: [
      "Insulin Recombinant",
      "9004/10/8",
      "Exubera",
      "Endopancrine",
      "Humilin",
      "Dermulin",
      "Decurvon",
      "Musulin",
      "Iszilin",
      "Insulyl",
      "Insular",
      "Iletin",
      "Insulin-Human",
      "Dal-Insulinum",
      "Intesulin B",
      "Insulin, Dalanated",
      "Insulina Dalanatada",
      "AERx",
      "Insulinum Dalanatum",
      "Insulin (Recombinant)",
      "Imusay-131",
      "INSULIN INJECTION",
      "Insulini Injectio Neutralis",
      "Insulina Iniettabile Neutra",
      "Injectable Insulini Neutrale",
      "Inyectable Neutro De Insulina",
      "CHEBI:5931",
      "CCRIS 5464",
      "Insulinum Dalanatum [INN-Latin]",
      "HSDB 3102",
      "Insulin, Dalanated [USAN:INN]",
      "AERx [Insulin Management System]",
    ],
  },
  {
    primary_id: "D06SWS",
    names: ["NN-1952"],
  },
  {
    primary_id: "D06SWT",
    names: ["Aryl Mannoside Derivative 5"],
  },
  {
    primary_id: "D06SWV",
    names: [
      "Diethylphosphono Group",
      "Diethyl Phosphonate",
      "762-04-9",
      "Phosphonic Acid, Diethyl Ester",
      "Phosphonic Acid Diethyl Ester",
      "Diethoxyphosphine Oxide",
      "Hydrogen Diethyl Phosphite",
      "Diethyl Acid Phosphite",
      "O,O-Diethyl Phosphonate",
      "Phosphorous Acid, Diethyl Ester",
      "Ethyl Phosphonate ((EtO)2HPO)",
      "Diethylfosfit [Czech]",
      "UNII-U9X9YBA22W",
      "NSC 2665",
      "DIETHYLPHOSPHONO GROUP",
      "HSDB 2591",
      "Diethoxyphosphino-1-One",
      "Diethoxy(Oxo)Phosphonium",
      "Diethyl Phosphite, 98%",
      "EINECS 212-091-6",
      "BRN 0605759",
      "U9X9YBA22W",
      "MJUJXFBTEFXVKU-UHFFFAOYSA-N",
      "Diethylfosfit",
      "MFCD00044573",
    ],
  },
  {
    primary_id: "D06SXN",
    names: ["3-Methoxy-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL233417", "SCHEMBL4296310"],
  },
  {
    primary_id: "D06SYA",
    names: ["MT-061 Lead"],
  },
  {
    primary_id: "D06SYN",
    names: ["Ioflubenzamide (131I)"],
  },
  {
    primary_id: "D06SYS",
    names: ["METHYLNORADRENALINE"],
  },
  {
    primary_id: "D06SZG",
    names: ["6,7-Dichloro-3-Hydroxy-1H-Quinazoline-2,4-Dione", "CHEMBL77569"],
  },
  {
    primary_id: "D06SZI",
    names: ["MCL-457"],
  },
  {
    primary_id: "D06SZU",
    names: ["WVE-210201"],
  },
  {
    primary_id: "D06TAJ",
    names: ["Fusidic"],
  },
  {
    primary_id: "D06TAL",
    names: ["Tazofelone"],
  },
  {
    primary_id: "D06TAR",
    names: ["DM-512"],
  },
  {
    primary_id: "D06TAS",
    names: ["2-Pyrazinone Derivative 2"],
  },
  {
    primary_id: "D06TCC",
    names: ["KM11060"],
  },
  {
    primary_id: "D06TCX",
    names: [
      "4-Cyano-N-(1,3-Diphenyl-1H-Pyrazol-5-Yl)Benzamide",
      "CHEMBL378434",
      "MLS002153000",
      "SCHEMBL3677412",
      "HMS2198C07",
      "BDBM50187632",
    ],
  },
  {
    primary_id: "D06TEO",
    names: ["UCL-1390"],
  },
  {
    primary_id: "D06TFC",
    names: ["4,5,6,7-Tetrahydrofuro[3,4-C]pyridine-1(3H)-One Derivative 3"],
  },
  {
    primary_id: "D06TFE",
    names: ["Technetium Tc-99m Depreotide"],
  },
  {
    primary_id: "D06TFI",
    names: ["LY2157299"],
  },
  {
    primary_id: "D06TGE",
    names: ["Taranabant"],
  },
  {
    primary_id: "D06TGR",
    names: [
      "8-(3-Hydroxyphenyl)-2-Morpholin-4-Ylchromen-4-One",
      "CHEMBL200685",
      "SCHEMBL5258643",
      "WPUKKEMHTVGTFK-UHFFFAOYSA-N",
      "BDBM50178081",
    ],
  },
  {
    primary_id: "D06TGS",
    names: [
      "Cx-603",
      "Allogeneic Adipose-Derived Mesenchymal Stem Cells (Intra-Articular, Osteoarthritis), Cellerix",
      "Allogeneic Adipose-Derived Mesenchymal Stem Cells (Intra-Articular, Osteoarthritis), TiGenix",
    ],
  },
  {
    primary_id: "D06THG",
    names: ["DS-1205"],
  },
  {
    primary_id: "D06THS",
    names: [
      "Desclasinose Azithromycinarylalkyl Hydroxamate",
      "CHEMBL454025",
      "SCHEMBL14329692",
      "BDBM27176",
      "Desclasinose Azithromycinarylalkyl Hydroxamate, 10",
    ],
  },
  {
    primary_id: "D06THT",
    names: ["INGN-402"],
  },
  {
    primary_id: "D06TIU",
    names: ["PMID28766366-Compound-Scheme12-1"],
  },
  {
    primary_id: "D06TJC",
    names: ["3-Mercapto-2-(Piperidin-4-Yl)Propanoic Acid", "CHEMBL246978", "SCHEMBL2353967"],
  },
  {
    primary_id: "D06TJI",
    names: ["ISIS 140180"],
  },
  {
    primary_id: "D06TJJ",
    names: ["FLUORESCEIN"],
  },
  {
    primary_id: "D06TJO",
    names: ["Human Monoclonal Antibodies (GM-CSF)"],
  },
  {
    primary_id: "D06TJQ",
    names: ["CRLX301"],
  },
  {
    primary_id: "D06TKE",
    names: ["ZD-7288"],
  },
  {
    primary_id: "D06TKI",
    names: [
      "CHR-3996",
      "CCT-075453",
      "CHR-2504",
      "HDAC Inhibitors, Chroma Therapeutics",
      "Histone Deacetylase Inhibitors, Chroma",
    ],
  },
  {
    primary_id: "D06TKR",
    names: ["CART 19"],
  },
  {
    primary_id: "D06TKS",
    names: ["FluMist/Fluenz"],
  },
  {
    primary_id: "D06TLK",
    names: ["EPB-600"],
  },
  {
    primary_id: "D06TLL",
    names: ["Go 6983"],
  },
  {
    primary_id: "D06TLQ",
    names: [
      "Icariside II",
      "Baohuoside I",
      "113558-15-9",
      "CHEBI:82619",
      "Icarlin II",
      "Baohuoside-I",
      "Baohuoside I;",
      "Icarisid II;",
      "AC1NUQWY",
      "3,5,7-Trihydroxy-4'-Methoxyl-8-Prenylflavone-3-O-Rhamnopyranoside",
      "SCHEMBL4229321",
      "CHEMBL560116",
      "DTXSID40150457",
      "2h44",
      "MolPort-020-006-007",
      "HY-N0011",
      "ZINC70455423",
      "C27H30O10",
      "CS-3673",
      "4H-1-Benzopyran-4-One, 3-((6-Deoxy-Alpha-L-Mannopyranosyl)Oxy)-5,7-Dihydroxy-2-(4-Methoxyphenyl)-8-(3-Methyl-2-Butenyl)-",
      "AK169968",
      "Anhydroicaritin-3-O-Alpha-L-Rhamnopyranoside",
      "N2538",
      "Y0106",
      "FT-0686579",
      "Q-100071",
    ],
  },
  {
    primary_id: "D06TME",
    names: ["MEDI-546"],
  },
  {
    primary_id: "D06TMH",
    names: ["HG-1164"],
  },
  {
    primary_id: "D06TMM",
    names: ["PTeB"],
  },
  {
    primary_id: "D06TNG",
    names: [
      "Polythiazide",
      "Drenusil",
      "Nephril",
      "Politiazida",
      "Polythiazidum",
      "Renese",
      "P 2525",
      "P-2525",
      "Politiazida [INN-Spanish]",
      "Polythiazidum [INN-Latin]",
      "Renese (TN)",
      "Polythiazide (JAN/USAN/INN)",
      "Polythiazide [USAN:INN:BAN:JAN]",
      "2-Methyl-3-(Beta,beta,beta-Trifluoroethylthiomethyl)-6-Chloro-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3,4-Dihydro-2-Methyl-3-(((2,2,2-Trifluoroethyl)Thio)Methyl)-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3,4-Dihydro-2-Methyl-3-(((2,2,2-Trifluoroethyl)Thio)Methyl)-, 1,1-Dioxide (8CI, 9CI)",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3,4-Dihydro-2-Methyl-3-[[(2,2,2-Trifluoroethyl)Thio]methyl]-, 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-2-Methyl-3-(((2,2,2-Trifluoroethyl)Thio)Methyl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-2-Methyl-3-[[(2,2,2-Trifluoroethyl)Thio]methyl]-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-2-Methyl-7-Sulphamoyl-3-(2,2,2-Trifluoroethylthiomethyl)-2H-Benzo-1,2,4-Thiadiazine 1,1-Dioxide",
      "6-Chloro-2-Methyl-1,1-Dioxo-3-(2,2,2-Trifluoroethylsulfanylmethyl)-3,4-Dihydro-1",
      "6-Chloro-2-Methyl-3-{[(2,2,2-Trifluoroethyl)Thio]methyl}-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D06TNL",
    names: [
      "Ethacrynic Acid",
      "Crinuryl",
      "Edecril",
      "Edecrina",
      "Endecril",
      "Ethacrynate",
      "Hidromedin",
      "Hydromedin",
      "Mingit",
      "Otacril",
      "Reomax",
      "Taladren",
      "Uregit",
      "Acide Etacrynique",
      "Acido Etacrinico",
      "Acidum Etacrynicum",
      "Etacrinic Acid",
      "Etacrynic Acid",
      "Etakrinic Acid",
      "Ethacrinic Acid",
      "Ethacryinic Acid",
      "Kyselina Ethakrynova",
      "Kyselina Ethakrynova [Czech]",
      "Methylenebutyryl Phenoxyacetic Acid",
      "Methylenebutyrylphenoxyacetic Acid",
      "E0526",
      "MK 595",
      "Acide Etacrynique [INN-French]",
      "Acido Etacrinico [INN-Spanish]",
      "Acidum Etacrynicum [INN-Latin]",
      "Edecrin (TN)",
      "Ethacrinique (Acide)",
      "Ethacrynic Acid, Sodium Salt",
      "Ethacrynic Acid (USP)",
      "Ethacrynic Acid [USAN:BAN]",
      "MK-595",
      "Etacrynic Acid (JP15/INN)",
      "[2,3-Dichloro-4-(2-Methylenebutyryl)Phenoxy]acetic Acid",
      "[2,3-Dichloro-4-(2-Methylenebutanoyl)Phenoxy]acetic Acid",
      "[2,3-Dichloro-4-(2-Methylidenebutanoyl)Phenoxy]acetic Acid",
      "[4-(2-Methylenebutyryl)-2,3-Dichlorophenoxy]acetic Acid",
      "Kyselina 4-(2-(1-Butenyl)Karbonyl)-2,3-Dichlorfenoxyoctova",
      "Kyselina 4-(2-(1-Butenyl)Karbonyl)-2,3-Dichlorfenoxyoctova [Czech]",
      "[2,3-Dichloro-4-(2-Methylene-1-Oxobutyl)Phenoxy]acetic Acid",
      "(2,3-Dichloro-4-(2-Methylene-1-Oxobutyl)Phenoxy)Acetic Acid",
      "(2,3-Dichloro-4-(2-Methylenebutyryl)Phenoxy)Acetic Acid",
      "(2,3-Dichloro-4-[2-Methylenebutyryl]phenoxy)Acetic Acid",
      "(4-(2-Methylenebutyryl)-2,3-Dichlorophenoxy)Acetic Acid",
      "2,3-Dichloro-4(2-Methylene-Butyryl)Phenoxy] Acetic Acid",
      "2,3-Dichloro-4-(2-Methylenebutyl)Phenoxyacetic Acid",
      "2,3-Dichloro-4-(2-Methylenebutyryl)Phenoxy Acetic Acid",
      "2,3-Dichloro-4-(2-Methylenebutyryl)Phenoxyacetic Acid",
      "2-[2,3-Dichloro-4-(2-Methylidenebutanoyl)Phenoxy]acetic Acid",
    ],
  },
  {
    primary_id: "D06TNO",
    names: ["CD19 CAR T-Cells"],
  },
  {
    primary_id: "D06TOE",
    names: ["Micafungin"],
  },
  {
    primary_id: "D06TOI",
    names: ["RU26988"],
  },
  {
    primary_id: "D06TPB",
    names: ["PMID26004420-Compound-WO2013039802C"],
  },
  {
    primary_id: "D06TPF",
    names: ["PYROGALLOL"],
  },
  {
    primary_id: "D06TPL",
    names: ["PMID26666989-Compound-Figure11topright"],
  },
  {
    primary_id: "D06TPT",
    names: ["Drug 311951", "311951"],
  },
  {
    primary_id: "D06TQD",
    names: ["CMPD-167"],
  },
  {
    primary_id: "D06TQR",
    names: ["PF-06372865"],
  },
  {
    primary_id: "D06TQU",
    names: ["GR-45809", "125104-16-7"],
  },
  {
    primary_id: "D06TQZ",
    names: [
      "Meticillin",
      "Dimocillin",
      "MII",
      "Metacillin",
      "Methicillin",
      "Methicillinum",
      "Methycillin",
      "Meticilina",
      "Meticillina",
      "Meticilline",
      "Meticillinum",
      "Staphcillin",
      "Methicillin [BAN]",
      "Meticillin [INN]",
      "Meticillina [DCIT]",
      "BRL 1241",
      "Methicillin Monohydrate, Monosodium Salt",
      "Meticilina [INN-Spanish]",
      "Meticilline [INN-French]",
      "Meticillinum [INN-Latin]",
      "Penicillin, Dimethoxyphenyl",
      "(2,6-Dimethoxyphenyl)Penicillin",
      "(2S,5R,6R)-6-[(2,6-Dimethoxybenzoyl)Amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-{[(2,6-Dimethoxyphenyl)Carbonyl]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "6-(2,3-Dimethoxybenzamido)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "6-(2,6-Dimethoxybenzamido)Penicillanic Acid",
      "6beta-(2,6-Dimethoxybenzamido)-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
      "6beta-(2,6-Dimethoxybenzamido)Penicillanic Acid",
    ],
  },
  {
    primary_id: "D06TRS",
    names: [
      "3-(3-Methylthiophen-2-Yl)Pyridine",
      "CHEMBL179669",
      "Pyridine, 3-(3-Methyl-2-Thienyl)-",
      "837376-35-9",
      "SCHEMBL3606167",
      "BDBM12351",
      "CTK3D1375",
      "DTXSID20635313",
      "Nicotine 3-Heteroaromatic Analogue 4",
      "US8609708, 4",
    ],
  },
  {
    primary_id: "D06TSI",
    names: ["2,7-Bis[3-(Propylamino)Propionamido]anthraquinone", "SCHEMBL4298186", "CHEMBL481160"],
  },
  {
    primary_id: "D06TSQ",
    names: ["LUF5831"],
  },
  {
    primary_id: "D06TTS",
    names: ["LZ-8"],
  },
  {
    primary_id: "D06TTT",
    names: ["Recombinant Tat Protein Vaccine"],
  },
  {
    primary_id: "D06TUB",
    names: ["Pertechnetate"],
  },
  {
    primary_id: "D06TUU",
    names: ["NVS Therapy"],
  },
  {
    primary_id: "D06TVC",
    names: ["Tienoxolol"],
  },
  {
    primary_id: "D06TVG",
    names: ["Inaperisone"],
  },
  {
    primary_id: "D06TVS",
    names: ["N1-Benzyl-N3-Hydroxy-2-Isobutylmalonamide", "CHEMBL220253", "BDBM50197494"],
  },
  {
    primary_id: "D06TVU",
    names: ["Q-101"],
  },
  {
    primary_id: "D06TWA",
    names: ["TB-403"],
  },
  {
    primary_id: "D06TWE",
    names: ["CLX-0921", "CLX-090502", "CLX-090503", "CLX-090717", "CLX-092402"],
  },
  {
    primary_id: "D06TWS",
    names: ["CAR-T Cells Targeting CD2"],
  },
  {
    primary_id: "D06TXM",
    names: ["YS121"],
  },
  {
    primary_id: "D06TXR",
    names: ["PMID26560530-Compound-16"],
  },
  {
    primary_id: "D06TXS",
    names: [
      "1-Phenyl-3-(Quinazolin-4-Yl)Urea",
      "CHEMBL309974",
      "SCHEMBL4689838",
      "1-Phenyl-3-Quinazolin-4-Yl-Urea",
      "1-(4-Quinazolinyl)-3-Phenylurea",
      "BDBM50088472",
    ],
  },
  {
    primary_id: "D06TYA",
    names: ["PMID17929793C23c"],
  },
  {
    primary_id: "D06TZT",
    names: ["RIPISARTAN"],
  },
  {
    primary_id: "D06TZZ",
    names: ["CAT 1004"],
  },
  {
    primary_id: "D06UAX",
    names: ["KYS-05080"],
  },
  {
    primary_id: "D06UBB",
    names: ["SK&F-86466"],
  },
  {
    primary_id: "D06UBF",
    names: ["V180"],
  },
  {
    primary_id: "D06UBH",
    names: [
      "ALFAXALONE",
      "Alphaxalone",
      "Alfaxalone",
      "23930-19-0",
      "5alpha-Pregnan-3alpha-Ol-11,20-Dione",
      "GR 2/234",
      "Alfaxalonum [INN-Latin]",
      "Alfaxalona [INN-Spanish]",
      "3alpha-Hydroxy-5alpha-Pregnane-11,20-Dione",
      "UNII-BD07M97B2A",
      "BRN 3217240",
      "MLS001076262",
      "MLS000069684",
      "3alpha-Hydroxy-5alpha-Pregnan-11,20-Dion",
      "BD07M97B2A",
      "CHEMBL190279",
      "CHEBI:34531",
      "3-Alpha-Hydroxy-5-Alpha-Pregnane-11,20-Dione",
      "(3-Alpha,5-Alpha)-3-Hydroxypregnane-11,20-Dione",
      "Alfaxolone",
      "3-Hydroxypregnane-11,20-Dione",
      "NCGC00023100-03",
      "SMR000058494",
      "(3a,5a)-3-Hydroxypregnane-11,20-Dione",
    ],
  },
  {
    primary_id: "D06UCV",
    names: ["Z-335", "2-[2-(4-Chlorophenylsulfonamidomethyl)Indan-5-Yl]acetic Acid Sodium Salt"],
  },
  {
    primary_id: "D06UDG",
    names: ["Valsartan"],
  },
  {
    primary_id: "D06UDH",
    names: ["REMD-477"],
  },
  {
    primary_id: "D06UDN",
    names: ["JZL195"],
  },
  {
    primary_id: "D06UDO",
    names: ["Phenindamine"],
  },
  {
    primary_id: "D06UDU",
    names: ["4-(2-(4-Fluorophenoxy)-4-Methylphenyl)Piperidine", "CHEMBL574347"],
  },
  {
    primary_id: "D06UDV",
    names: ["AZD2516"],
  },
  {
    primary_id: "D06UEC",
    names: ["ADXS-HER2"],
  },
  {
    primary_id: "D06UEK",
    names: ["(E)-5-(4-Hydroxybenzylidene)-1-Phenethylhydantoin", "CHEMBL213560", "SCHEMBL16693054", "BDBM50187134"],
  },
  {
    primary_id: "D06UEP",
    names: ["GKT-136901"],
  },
  {
    primary_id: "D06UFH",
    names: ["CZ-112"],
  },
  {
    primary_id: "D06UGK",
    names: ["Uridine-Diphosphate-N-Acetylgalactosamine"],
  },
  {
    primary_id: "D06UHZ",
    names: ["2-Mercaptophenylphosphonic Acid", "CHEMBL1173336", "SCHEMBL108541", "(2-Mercaptophenyl)Phosphonic Acid"],
  },
  {
    primary_id: "D06UIS",
    names: ["LY-2828360"],
  },
  {
    primary_id: "D06UIU",
    names: ["CORYMBONE B", "Corymbone B"],
  },
  {
    primary_id: "D06UJA",
    names: ["MNI-136"],
  },
  {
    primary_id: "D06UJD",
    names: ["H-102"],
  },
  {
    primary_id: "D06UJO",
    names: ["Rhenex"],
  },
  {
    primary_id: "D06UKG",
    names: ["LY-377604"],
  },
  {
    primary_id: "D06UKM",
    names: ["PMID28074661-Compound-US20120264760C87"],
  },
  {
    primary_id: "D06UKS",
    names: ["PMID21982499C14k"],
  },
  {
    primary_id: "D06UKY",
    names: ["4-(2,4-Dichlorophenyl)-1-Phenylpyrrolidin-3-Amine", "CHEMBL396300"],
  },
  {
    primary_id: "D06ULO",
    names: [
      "Antifungal/Antibacterial Therapeutics",
      "Antifungal/Antibacterial Therapeutics (Ophthalmic/Otic)",
      "Antifungal/Antibacterial Therapeutics (Ophthalmic/Otic), Sephoris",
      "Small Molecule Combination Therapy (Opthalmic/Otic Formulation, Bacterial/Fungal Infections), Sephoris Pharmaceuticals",
    ],
  },
  {
    primary_id: "D06ULU",
    names: ["Sucralfate"],
  },
  {
    primary_id: "D06UMS",
    names: ["Antibiotic Combination", "Antibiotic Combination (Drug-Resistant Staphylococcus Aureus Infection)"],
  },
  {
    primary_id: "D06UNC",
    names: ["ISIS 23559"],
  },
  {
    primary_id: "D06UND",
    names: ["FP-85A"],
  },
  {
    primary_id: "D06UNI",
    names: ["RBx-10017609"],
  },
  {
    primary_id: "D06UNR",
    names: [
      "Unithiol",
      "DMPS",
      "2,3-DIMERCAPTO-1-PROPANESULFONIC ACID",
      "74-61-3",
      "DL-2,3-Dimercaptopropane-1-Sulfonic Acid",
      "2,3-Dimercaptopropanesulfonic Acid",
      "2,3-Dimercaptopropan-1-Sulfonsaeure",
      "(+-)-2,3-Dimercapto-1-Propanesulfonic Acid",
      "2,3-Disulfanylpropane-1-Sulfonic Acid",
      "2,3-Dimercaptopropane-1-Sulfonic Acid",
      "BRN 1763751",
      "CHEBI:888",
      "1-Propanesulfonic Acid, 2,3-Dimercapto-",
      "CHEMBL1624767",
      "2,3-Dimercaptopropan-1-Sulfonsaeure [German]",
      "2,3-Bis(Sulfanyl)Propane-1-Sulfonic Acid",
      "Dimercaptopropane-1-Sulfonate",
      "C3H8O3S3",
      "AC1L1M9O",
      "AC1Q6X2X",
    ],
  },
  {
    primary_id: "D06UNW",
    names: ["IVN-Mite"],
  },
  {
    primary_id: "D06UNX",
    names: ["Verdoheme"],
  },
  {
    primary_id: "D06UNY",
    names: ["SB204"],
  },
  {
    primary_id: "D06UOE",
    names: ["3-(1-Carbamoyl-1,1-Diphenylmethyl)-1-(4-Methoxyphenylethyl)Pyrrolidine (APP)"],
  },
  {
    primary_id: "D06UOL",
    names: ["PIROTIODECANE"],
  },
  {
    primary_id: "D06UOU",
    names: ["MGA271"],
  },
  {
    primary_id: "D06UPR",
    names: [
      "1-(4-Nitrophenyl)-2-(Phenylsulfonyl)Ethanone",
      "CHEMBL393284",
      "38488-17-4",
      "4-Nitrophenacylphenyl Sulfone",
      "SCHEMBL6908652",
      "CTK1B4829",
      "DTXSID80456743",
      "BDBM50212551",
      "AKOS003309631",
      "Ethanone, 1-(4-Nitrophenyl)-2-(Phenylsulfonyl)-",
    ],
  },
  {
    primary_id: "D06UPS",
    names: ["Rozrolimupab"],
  },
  {
    primary_id: "D06UPT",
    names: ["Sri-9439"],
  },
  {
    primary_id: "D06UPW",
    names: ["WSS-45"],
  },
  {
    primary_id: "D06UQR",
    names: [
      "5,6,8-Trimethoxy-4-Methylquinolin-2(1H)-One",
      "Casimiroin Analogue, 1o",
      "CHEMBL493181",
      "BDBM29226",
      "MolPort-028-745-485",
      "ZINC40957356",
      "4-Methyl-5,6,8-Trimethoxyquinolin-2(1H)-One",
    ],
  },
  {
    primary_id: "D06URI",
    names: ["ATL101"],
  },
  {
    primary_id: "D06URK",
    names: ["CKD-533"],
  },
  {
    primary_id: "D06URS",
    names: ["Arcitumomab", "CEA-Scan", "Arcitumomab (USAN/INN)", "Cea-Scan (TN)"],
  },
  {
    primary_id: "D06URX",
    names: ["CAR-T Cells Targeting MUCI"],
  },
  {
    primary_id: "D06USG",
    names: ["CS-560"],
  },
  {
    primary_id: "D06UTH",
    names: ["RDEA-684"],
  },
  {
    primary_id: "D06UTM",
    names: ["Anti-CD19-CAR T Cells"],
  },
  {
    primary_id: "D06UTT",
    names: ["Sphingosomal Topotecan"],
  },
  {
    primary_id: "D06UTW",
    names: ["Salvinorin B Butoxymethyl Ether", "CHEMBL255139"],
  },
  {
    primary_id: "D06UUE",
    names: [
      "AZ-AAV9",
      "Alzheimers Disease Gene Therapy, RegenX Biosciences",
      "Adeno-Associated Virus Vector-9 Based Gene Therapy (Injectable,AD), RegenX Biosciences",
    ],
  },
  {
    primary_id: "D06UUK",
    names: ["Gamma-Carboxy-Glutamic Acid"],
  },
  {
    primary_id: "D06UUP",
    names: ["NK-3 Antagonists"],
  },
  {
    primary_id: "D06UUU",
    names: [
      "BLX-1017",
      "BLX-1024",
      "BLX-1025",
      "BLX-1026",
      "BLX-1027",
      "BLX-1031",
      "BLX-1060",
      "TNF-Alpha Inhibitors (Inflammation)",
      "TNF-Alpha Inhibitors (Inflammation), BEXEL Pharmaceuticals",
    ],
  },
  {
    primary_id: "D06UVD",
    names: [
      "Bleomycin",
      "BLM",
      "Blenoxane",
      "Bleo",
      "Bleocin",
      "Bleogin",
      "Bleomicin",
      "Bleomicina",
      "Bleomycine",
      "Bleomycins",
      "Bleomycinum",
      "Bleomycin Sulfate",
      "Bleomycin A2",
      "Pingyangmyvin A2",
      "Zhengguangmycin A2",
      "Zhengguangmycin A2 [Chinese]",
      "Blenoxane (TN)",
      "Bleomicina [INN-Spanish]",
      "Bleomycin A(2)",
      "Bleomycin A2 & Bleomycin B2",
      "Bleomycin B(2)",
      "Bleomycine [INN-French]",
      "Bleomycinum [INN-Latin]",
      "NDC 0015-3010",
      "N(1)-[3-(Dimethylsulfonio)Propyl]bleomycinamide",
      "N1-(3-(Dimethylsulfonio)Propyl)Bleomycinamide",
    ],
  },
  {
    primary_id: "D06UVF",
    names: [
      "1-[(N-But-1-Ylamino)Ethyl]-1,1-Bisphosphonic Acid",
      "CHEMBL406087",
      "SCHEMBL7538729",
      "BDBM50374767",
      "[2-(Butylamino)Ethylidene]bisphosphonic Acid",
      "[2-(Butylamino)-1-Phosphono-Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D06UVO",
    names: [
      "DuP-654",
      "DUP-654",
      "2-Benzylnaphthalen-1-Ol",
      "2-Benzyl-1-Naphthol",
      "Dup 654",
      "36441-32-4",
      "DuP654",
      "2-(Phenylmethyl)-1-Naphthol",
      "UNII-96251IH9OJ",
      "2-Benzyl-1-Hydroxynaphthalene",
      "2-(Phenylmethyl)-1-Naphthalenol",
      "BRN 1965326",
      "CHEMBL85000",
      "96251IH9OJ",
      "1-Naphthalenol, 2-(Phenylmethyl)-",
      "AC1L4NCB",
      "AC1Q7AVK",
      "2-Phenylmethyl1-Naphthol",
      "2-Phenylmethyl-1-Naphthol",
      "2-Benzyl-Naphthalen-1-Ol",
      "4-06-00-04990 (Beilstein Handbook Reference)",
      "SCHEMBL664782",
      "DTXSID00189965",
      "CZTSOXCSVFEFIK-UHFFFAOYSA-N",
      "ZINC1485058",
      "BDBM50004679",
      "2-Benzyl-Naphthalen-1-Ol(DuP 654)",
    ],
  },
  {
    primary_id: "D06UVY",
    names: ["L-Tryptophan-L-Aspartic Acid"],
  },
  {
    primary_id: "D06UVZ",
    names: [
      "DPD-207",
      "DPD-201",
      "DPD-202",
      "DPD-204",
      "DPD-206",
      "DPD-241",
      "Antibiotic (Bacterial Infection), Destiny Pharma",
      "XF Compounds (Bacterial Infection), Destiny Pharma",
    ],
  },
  {
    primary_id: "D06UWD",
    names: [
      "Clobutinol",
      "Clobutinol Hydrochloride",
      "Silomat",
      "Biotertussin",
      "Petoxil",
      "Pertoxil",
      "Silom",
      "1215-83-4",
      "UNII-N2U6799DZQ",
      "Clobutinol HCl",
      "KAT 256",
      "EINECS 214-931-7",
      "Clobutinol Hydrochloride [JAN]",
      "N2U6799DZQ",
      "2-(P-Chlorobenzyl)-3-Dimethylaminomethyl-2-Butanol Hydrochloride",
      "1-P-Chlorophenyl-2,3-Dimethyl-4-Dimethylamino-2-Butanol Hydrochloride",
      "Benzeneethanol, 4-Chloro-Alpha-(2-(Dimethylamino)-1-Methylethyl)-Alpha-Methyl-, Hydrochloride",
      "P-Cloro-Alpha-(2-(Dimetilamino)-1-Metiletil)-Alpha-Metil Fenetil Alcool Cloridrato [Italian]",
    ],
  },
  {
    primary_id: "D06UWF",
    names: ["SOR-C13"],
  },
  {
    primary_id: "D06UXE",
    names: ["PMID27841036-Compound-V"],
  },
  {
    primary_id: "D06UXL",
    names: ["GI-248573"],
  },
  {
    primary_id: "D06UXW",
    names: ["METHOCTRAMINE"],
  },
  {
    primary_id: "D06UYC",
    names: ["4,7-Dioxosebacic Acid"],
  },
  {
    primary_id: "D06UYI",
    names: ["AM103"],
  },
  {
    primary_id: "D06UZK",
    names: [
      "4,5,6,7-Tetrahydro-1H-Indazole-3-Carboxylic Acid",
      "6076-13-7",
      "4,5,6,7-Tetrahydro-2H-Indazole-3-Carboxylic Acid",
      "714255-28-4",
      "CHEMBL338405",
      "LWXNHFZBFJMHGU-UHFFFAOYSA-N",
      "2H-Indazole-3-Carboxylicacid, 4,5,6,7-Tetrahydro-",
      "1H-Indazole-3-Carboxylic Acid, 4,5,6,7-Tetrahydro-",
      "2H-Indazole-3-Carboxylic Acid, 4,5,6,7-Tetrahydro-",
      "1H-4,5,6,7-Tetrahydroindazole-3-Carboxylic Acid",
      "MLS000074645",
      "AC1LDJ0A",
      "ChemDiv2_003320",
      "Oprea1_089683",
      "AC1Q748E",
      "SCHEMBL1808017",
      "AC1Q72Q0",
      "CTK2H6469",
      "DTXSID00349466",
      "MolPort-000-767-792",
      "MolPort-000-149-162",
    ],
  },
  {
    primary_id: "D06UZS",
    names: ["VDA-1124"],
  },
  {
    primary_id: "D06VAF",
    names: ["Alpha Amino Acids", "Alpha Amino Acids (Cancer/Infection)"],
  },
  {
    primary_id: "D06VAQ",
    names: ["EUK-189"],
  },
  {
    primary_id: "D06VBN",
    names: ["N-Hydroxy-4-(2-Phenyl-Butyrylamino)-Benzamide"],
  },
  {
    primary_id: "D06VBQ",
    names: ["GSK-0660"],
  },
  {
    primary_id: "D06VCC",
    names: [
      "REGN-421",
      "Dll4 Antibody",
      "Dll4-Fc",
      "Delta-Like Ligand 4 Antibody (VelociSuite), Regeneron, Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D06VCJ",
    names: ["Fusion Toxin Protein"],
  },
  {
    primary_id: "D06VCN",
    names: ["Denagliptin"],
  },
  {
    primary_id: "D06VDN",
    names: ["SB 243213"],
  },
  {
    primary_id: "D06VES",
    names: ["Inhaled Lipid-Complexed Cisplatin"],
  },
  {
    primary_id: "D06VFF",
    names: ["EGFR Antisense DNA", "Antisense Therapy, UPCI", "EGFR Antisense DNA, University Of Pittsburgh"],
  },
  {
    primary_id: "D06VFH",
    names: ["ISIS 12883"],
  },
  {
    primary_id: "D06VFI",
    names: ["PRTT-200"],
  },
  {
    primary_id: "D06VFK",
    names: ["Immunostimulant Patch"],
  },
  {
    primary_id: "D06VFO",
    names: [
      "Anisodamine",
      "Racanisodamine",
      "55869-99-3",
      "(6S)-6-Hydroxyhyoscyamine",
      "(-)-Anisodamine",
      "AC1O6SQS",
      "AC1L4C7W",
      "SCHEMBL620576",
      "ZINC2112569",
      "8053AH",
      "API0000388",
      "869A993",
      "Q-100648",
      "[(1S,3S,5S,6S)-6-Hydroxy-8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl] (2S)-3-Hydroxy-2-Phenylpropanoate",
      "(S)-((1S,3S,5S,6S)-6-Hydroxy-8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl) 3-Hydroxy-2-Phenylpropanoate",
      "[(1R,3S,5R,6R)-6-Hydroxy-8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl] (2R)-3-Hydroxy-2-Phenylpropanoate",
    ],
  },
  {
    primary_id: "D06VFP",
    names: ["SOTB07"],
  },
  {
    primary_id: "D06VGD",
    names: [
      "ACENAPHTHOQUINONE",
      "Acenaphthenequinone",
      "82-86-0",
      "Acenaphthoquinone",
      "Acenaphthylene-1,2-Dione",
      "1,2-ACENAPHTHYLENEDIONE",
      "Acenaphthenedione",
      "1,2-Acenaphthenequinone",
      "1,2-Acenaphthenedione",
      "Acenaphthaquinone",
      "Acenaphthylene-1,2-Quinone",
      "Acenaphthylenequinone",
      "Acenaphthodione",
      "1,2-Diketoacenaphthene",
      "Acenaphthene-1,2-Dione",
      "Diketoacenaphthene",
      "Acenaphthylenedione",
      "UNII-3950D6UEIQ",
      "Acenaphthene Quinone",
      "CCRIS 5318",
      "CHEBI:15342",
      "HSDB 2660",
      "NSC 7656",
      "EINECS 201-441-3",
      "1,2-Dihydroacenaphthylene-1,2-Dione",
      "BRN 0879172",
      "3950D6UEIQ",
      "AFPRJLBZLPBTPZ-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D06VGG",
    names: ["KT6528", "AC1L31GQ"],
  },
  {
    primary_id: "D06VHG",
    names: ["NSI-189"],
  },
  {
    primary_id: "D06VIP",
    names: ["N-(Tert-Butoxycarbonyl)-Valyl-Glycine-Nitrile"],
  },
  {
    primary_id: "D06VIZ",
    names: ["NVX-144", "EIF2AK3 Kinase Modulator (Cancer), Novelix"],
  },
  {
    primary_id: "D06VJK",
    names: ["Verpasep Caltespen"],
  },
  {
    primary_id: "D06VJM",
    names: ["Heteroaryl-Azepine Derivative 14"],
  },
  {
    primary_id: "D06VLI",
    names: ["HPPH Photodynamic Therapy"],
  },
  {
    primary_id: "D06VMJ",
    names: ["Melogliptin"],
  },
  {
    primary_id: "D06VML",
    names: [
      "Seroxat",
      "Aropax",
      "Casbol",
      "Frosinor",
      "Motivan",
      "Paroxetina",
      "Paroxetine",
      "Paroxetinum",
      "PaxPar",
      "Paxetil",
      "Paxil",
      "Pexeva",
      "Paroxetine Hcl",
      "Paxil CR;Seroxat CR",
      "BRL 29060",
      "FG 7051",
      "[3H]Paroxetine",
      "BRL-29060",
      "FG-7051",
      "Paroxetina [INN-Spanish]",
      "Paroxetine (TN)",
      "Paroxetinum [INN-Latin]",
      "Paxil (TN)",
      "NNC-20-7051",
      "Paroxetine (USP/INN)",
      "Paroxetine [USAN:INN:BAN]",
      "(-)-(3S,4R)-4-(P-Fluorophenyl)-3-((3,4-(Methylenedioxy)Phenoxy)Methyl)Piperidine",
      "(-)-(3S,4R)-4-(P-Fluorophenyl)-3-((3,4-Methylenedioxy)Phenoxy)Methyl)Piperidine",
      "(-)-Paroxetine",
      "(-)-Trans-4-(4-Fluorophenyl)-3-(3,4-Methylenedioxyphenoxymethyl)Piperidine",
      "(-)-Trans-4-(P-Fluorophenyl)-3-[[3,4-(Methylenedioxy)Phenoxy]methyl]-Piperidine",
      "(3S,4R)-3-(1,3-Benzodioxol-5-Yloxymethyl)-4-(4-Fluorophenyl)Piperidine",
      "(3S,4R)-3-[(1,3-Benzodioxol-5-Yloxy)Methyl]-4-(4-Fluorophenyl)Piperidine",
      "(3S-Trans)-3-((1,3-Benzodioxol-5-Yloxy)Methyl)-4-(4-Fluorophenyl)Piperidine",
      "(3S-Trans)-3-[(1,3-Benzodioxol-5-Yl-Oxy)Methyl]-4-(4-Fluorophenyl)Piperidine",
      "3-(Benzo[1,3]Dioxol-5-Yl-Oxymethyl)-4-(4-Fluoro-Phenyl)-Piperidine Hydrochloride",
    ],
  },
  {
    primary_id: "D06VMN",
    names: ["Heteroaryl-Cyclopropylamine Derivative 3"],
  },
  {
    primary_id: "D06VMV",
    names: [
      "BHF-177",
      "Allosteric GABA B Receptor Modulators, Novartis",
      "CGP-13501",
      "CGP-7930",
      "CPG-37656",
      "GS-39783",
    ],
  },
  {
    primary_id: "D06VNK",
    names: [
      "Succinic Acid",
      "Asuccin",
      "Bernsteinsaeure",
      "Bernsteinsaure",
      "Butandisaeure",
      "Katasuccin",
      "SIN",
      "Succ",
      "Succinellite",
      "Wormwood",
      "Acid Of Amber",
      "Acide Butanedioique",
      "Acide Succinique",
      "Acidum Succinicum",
      "Amber Acid",
      "Bernsteinsaure [German]",
      "Butanedioic Acid Diammonium Salt",
      "Butanedionic Acid",
      "Dihydrofumaric Acid",
      "Ethanedicarboxylic Acid",
      "Ethylene Dicarboxylic Acid",
      "Ethylene Succinic Acid",
      "Ethylenesuccinic Acid",
      "Kyselina Jantarova",
      "Kyselina Jantarova [Czech]",
      "Potassium Succinate",
      "Sal Succini",
      "Spirit Of Amber",
      "Succinicum Acidum",
      "Succinicun Acidum",
      "Wormwood Acid",
      "A 12084",
      "Butanedioic Acid (9CI)",
      "SUCCINIC ACID, ACS",
      "Succinate, 9",
      "Succinic Acid (8CI)",
      "Amber Acid, Butanedioic Acid, Ethylenesuccinic Acid",
      "1,2-Ethanedicarboxylic Acid",
      "1,4-BUTANEDIOIC ACID (SUCCINIC ACID)",
      "1,4-Butanedioic Acid",
    ],
  },
  {
    primary_id: "D06VNR",
    names: [
      "3-Benzyloxy-6,7-Dimethoxy-Quinoline",
      "CHEMBL66932",
      "SCHEMBL8172572",
      "ZJIOVLLLFWQBKA-UHFFFAOYSA-N",
      "3-Benzyloxy-6,7-Dimethoxyquinoline",
      "ZINC3834031",
    ],
  },
  {
    primary_id: "D06VNT",
    names: [
      "4-Hydroxy-3-Methyl-But-2-Enoic Acid",
      "44647-19-0",
      "(E)-4-Hydroxy-3-Methylbut-2-Enoic Acid",
      "CHEMBL353481",
      "SCHEMBL6282205",
      "SCHEMBL8370948",
      "4-Hydroxy-3-Methylcrotonic Acid",
      "3-(Hydroxymethyl)-2-Butenoic Acid",
      "4-Hydroxy-3-Methylbut-2-Enoic Acid",
      "AKOS013086986",
      "FCH4103824",
      "FCH2609833",
      "BBV-37850347",
      "(E)-4-Hydroxy-3-Methyl-2-Butenoic Acid",
      "(2E)-4-Hydroxy-3-Methylbut-2-Enoic Acid",
    ],
  },
  {
    primary_id: "D06VOJ",
    names: ["J-104118"],
  },
  {
    primary_id: "D06VOM",
    names: ["NEBO-174"],
  },
  {
    primary_id: "D06VON",
    names: ["(S)-2-Amino-2-Phenyl-Ethanethiol", "CHEMBL286865", "BDBM50078138"],
  },
  {
    primary_id: "D06VPE",
    names: ["Lu-AA38466"],
  },
  {
    primary_id: "D06VPR",
    names: ["WAY-466", "BDBM34144"],
  },
  {
    primary_id: "D06VPT",
    names: ["TDI-0028", "ALS-TDI-00846"],
  },
  {
    primary_id: "D06VRA",
    names: ["4,17-Dehydroxyriccardin C", "Riccardin C Derivative, 20f", "CHEMBL411912", "BDBM23853"],
  },
  {
    primary_id: "D06VRS",
    names: ["CRB-15", "Depletin-RA", "Immunotherapeutic Protein (Autoimmune Disease), Celonic", "MutIL15-FC, Cardion"],
  },
  {
    primary_id: "D06VSA",
    names: [
      "Methyl 2-(7-Phenylheptanoyl)Oxazole-5-Carboxylate",
      "CHEMBL220784",
      "SCHEMBL3140826",
      "Methyl 2-(7-Phenylheptanoyl)-1,3-Oxazole-5-Carboxylate",
      "BIKPWTFTYRRSPI-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D06VST",
    names: ["3-Methyl-7-Pyrrolidin-1-Yl-Hept-5-Yn-2-One", "CHEMBL353023"],
  },
  {
    primary_id: "D06VTI",
    names: ["Macrocycle Derivative 1"],
  },
  {
    primary_id: "D06VTL",
    names: ["ZO-Y49"],
  },
  {
    primary_id: "D06VTU",
    names: [
      "SJA-6017",
      "Calpain Inhibitor VI",
      "SJA6017",
      "N-(4-Fluorophenylsulfonyl)-L-Valyl-L-Leucinal",
      "CHEMBL288365",
      "SCHEMBL1907361",
      "ZINC1544687",
      "BDBM50114340",
      "J-012283",
      "(S)-2-(4-Fluorophenylsulfonamido)-3-Methyl-N-((S)-4-Methyl-1-Oxopentan-2-Yl)Butanamide",
      "(S)-2-(4-Fluoro-Benzenesulfonylamino)-N-((S)-1-Formyl-3-Methyl-Butyl)-3-Methyl-Butyramide",
    ],
  },
  {
    primary_id: "D06VTW",
    names: ["Palmitoyl-CoA"],
  },
  {
    primary_id: "D06VUC",
    names: ["4-(3,4-Dichlorobenzylthio)-2-Aminobutanoic Acid", "CHEMBL382682", "BDBM50179735"],
  },
  {
    primary_id: "D06VVH",
    names: ["GSK-2115160A"],
  },
  {
    primary_id: "D06VVV",
    names: ["NSC-348926"],
  },
  {
    primary_id: "D06VWA",
    names: ["FR-901469"],
  },
  {
    primary_id: "D06VWH",
    names: ["L-689,037"],
  },
  {
    primary_id: "D06VWL",
    names: ["VA-106483"],
  },
  {
    primary_id: "D06VXD",
    names: [
      "DOM-0215",
      "Dual Anti-TNFalpha/Unspecified Target Human DAb (Rheumatoid Arthritis), Domantis",
      "Dual-Targeting Domain Antibody Therapy (Rheumatoid Arthritis), Domantis",
    ],
  },
  {
    primary_id: "D06VXE",
    names: ["ALS-2160"],
  },
  {
    primary_id: "D06VXW",
    names: [
      "3-[2-(3,5-Dimethoxy-Phenyl)-Vinyl]-Furan",
      "CHEMBL43396",
      "SCHEMBL7047273",
      "SCHEMBL7047277",
      "3-[(E)-3,5-Dimethoxystyryl]furan",
      "ZINC13471767",
      "BDBM50108047",
      "AKOS015967552",
    ],
  },
  {
    primary_id: "D06VXY",
    names: ["SB-237376"],
  },
  {
    primary_id: "D06VYA",
    names: ["6-(2-Dipropylamino-Ethyl)-Biphenyl-2,3-Diol", "CHEMBL300995"],
  },
  {
    primary_id: "D06VYB",
    names: ["Pyrrolo-Pyridinone Derivative 6"],
  },
  {
    primary_id: "D06VYE",
    names: ["Belotecan Hydrocholoride"],
  },
  {
    primary_id: "D06VYF",
    names: ["PMID25666693-Compound-3"],
  },
  {
    primary_id: "D06VYT",
    names: ["O2-Sulfo-Glucuronic Acid"],
  },
  {
    primary_id: "D06VYU",
    names: ["(S)-3-Propyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL186431", "SCHEMBL7678842"],
  },
  {
    primary_id: "D06VZE",
    names: ["[3H]mibolerone"],
  },
  {
    primary_id: "D06VZN",
    names: ["SPK-843"],
  },
  {
    primary_id: "D06VZS",
    names: [
      "ONO-AE1-329",
      "2-[3-[(1R,2S,3R)-3-Hydroxy-2-[(E,3S)-3-Hydroxy-5-[2-(Methoxymethyl)Phenyl]pent-1-Enyl]-5-Oxocyclopentyl]sulfanylpropylsulfanyl]acetic Acid",
      "ONO-AE-329",
      "AC1NSK9H",
      "GTPL1933",
      "SCHEMBL14150400",
      "2-[3-[(1r,2s,3r)-3-Hydroxy-2-[(E,3s)-3-Hydroxy-5-[2-(Methoxymethyl)Phenyl]pent-1-Enyl]-5-Oxo-Cyclopentyl]sulfanylpropylsulfanyl]acetic Acid",
    ],
  },
  {
    primary_id: "D06VZX",
    names: ["PMID27841036-Compound-38"],
  },
  {
    primary_id: "D06WAA",
    names: ["CKBP-004"],
  },
  {
    primary_id: "D06WAL",
    names: ["Phlorizin"],
  },
  {
    primary_id: "D06WAM",
    names: ["Oradoxel"],
  },
  {
    primary_id: "D06WAN",
    names: ["Benzimidazole And Imidazopyridine Derivative 1"],
  },
  {
    primary_id: "D06WAW",
    names: ["PMID25991433-Compound-E5"],
  },
  {
    primary_id: "D06WBA",
    names: [
      "SQ-28603",
      "CHEMBL196902",
      "Beta-Alanine, N-(2-(Mercaptomethyl)-1-Oxo-3-Phenylpropyl)-",
      "100845-83-8",
      "AC1L3X7M",
      "SCHEMBL389731",
      "3-[(2-Benzyl-3-Sulfanylpropanoyl)Amino]propanoic Acid",
      "ZCTDTVUDURCGFX-UHFFFAOYSA-N",
      "HY-U00171",
      "BDBM50175499",
      "CS-7234",
      "LS-186925",
      "LS-187575",
      "3-(2-Benzyl-3-Mercapto-Propionylamino)-Propionic Acid",
      "(+/-)-3-[[2-(Mercaptomethyl)-1-Oxo-3-Phenylpropyl]amino]propanoic Acid",
    ],
  },
  {
    primary_id: "D06WBE",
    names: ["ERTIPROTAFIB"],
  },
  {
    primary_id: "D06WBH",
    names: ["17alpha-Hydroxyprogesterone", "17alpha Hydroxyprogesterone", "17-OH Progesterone", "17OHP"],
  },
  {
    primary_id: "D06WCI",
    names: ["SRIF-28"],
  },
  {
    primary_id: "D06WCR",
    names: ["JAIVAC-1"],
  },
  {
    primary_id: "D06WCT",
    names: ["7-Phenylethoxy-2-Morpholin-4-Yl-Chromen-4-One", "CHEMBL199127"],
  },
  {
    primary_id: "D06WCW",
    names: ["UCB-1350883", "Bcl-2 Inhibitors (Cancer), UCB"],
  },
  {
    primary_id: "D06WDT",
    names: ["PF-04094667"],
  },
  {
    primary_id: "D06WEB",
    names: [
      "LAF-389",
      "Bengamide B, Novartis",
      "Bengamide E, Novartis",
      "Bengamides, Novartis",
      "Anticancer Therapy (Ciclosporin Derivatives), Novartis",
      "Anticancer Therapy (Cyclosporin A Derivatives), Novartis",
      "Anticancer Therapy (Cyclosporine Derivatives), Novartis",
    ],
  },
  {
    primary_id: "D06WEK",
    names: [
      "Ammonium Chloride",
      "AMMONIUM CHLORIDE",
      "12125-02-9",
      "Salmiac",
      "Sal Ammoniac",
      "Ammonium Muriate",
      "Ammoniumchlorid",
      "Ammoneric",
      "Darammon",
      "Amchlor",
      "Ammonium Chloride ((NH4)Cl)",
      "Chlorammonic",
      "Salammonite",
      "Ammoniumklorid",
      "Elektrolyt",
      "Chloramon",
      "Ammonchlor",
      "Sal Ammonia",
      "Ammon Chlor",
      "Ammonii Chloridum",
      "Ammonium Chloratum",
      "Cloruro De Amonio",
      "Muriate Of Ammonia",
      "Gen-Diur (Spain)",
      "Chlorammonic [France]",
      "Ammonium Chloride Fume",
      "Chlorid Amonny [Czech]",
      "Ammoniumchloride",
      "Ammoniumchlorid [German]",
      "Azanium Chloride",
      "Chlorid Ammonia [Czech]",
      "Ammonium Chloride Tablets",
      "Ammonium C",
    ],
  },
  {
    primary_id: "D06WGK",
    names: ["Q-LAIV"],
  },
  {
    primary_id: "D06WGO",
    names: ["Resorcinol Compound 8"],
  },
  {
    primary_id: "D06WGQ",
    names: ["Anthrax DNA Vaccine"],
  },
  {
    primary_id: "D06WGX",
    names: [
      "A-770041",
      "869748-10-7",
      "UNII-U9855G2ZPR",
      "U9855G2ZPR",
      "CHEMBL197603",
      "KIN001-111",
      "N-(4-{1-[4-(4-Acetylpiperazin-1-Yl)-Trans-Cyclohexyl]-4-Amino-1h-Pyrazolo[3,4-D]pyrimidin-3-Yl}-2-Methoxyphenyl)-1-Methyl-1h-Indole-2-Carboxamide",
      "A 770041",
      "Kinome_3267",
      "2c0o",
      "MLS006010241",
      "CHEMBL1970879",
      "SCHEMBL15705796",
      "CHEBI:91457",
      "MolPort-039-139-535",
      "C34H39N9O3",
      "3413AH",
      "ZINC14210723",
      "BDBM50175196",
      "ABP001109",
      "AKOS030526954",
      "ZINC253387967",
      "CS-0057",
      "NCGC00386613-01",
      "NCGC00346955-01",
      "SMR004701317",
      "HY-11011",
      "L2G",
      "1H-Indole-2-Carboxamide, N-(4-(1-(Trans-4-(4-",
    ],
  },
  {
    primary_id: "D06WHL",
    names: ["NS-257"],
  },
  {
    primary_id: "D06WHN",
    names: ["KDT-500"],
  },
  {
    primary_id: "D06WHY",
    names: [
      "AZD-5438",
      "602306-29-6",
      "AZD5438",
      "AZD 5438",
      "4-(1-Isopropyl-2-Methyl-1H-Imidazol-5-Yl)-N-(4-(Methylsulfonyl)Phenyl)Pyrimidin-2-Amine",
      "CHEMBL488436",
      "UNII-276Z913G29",
      "MMV676604",
      "4-[2-METHYL-1-ISOPROPYL-1H-IMIDAZOL-5-YL]-N-[4-(METHYLSULFONYL)PHENYL]-2-PYRIMIDINAMINE",
      "2-Pyrimidinamine,4-[2-Methyl-1-(1-Methylethyl)-1H-Imidazol-5-Yl]-N-[4-(Methylsulfonyl)Phenyl]-",
      "276Z913G29",
      "4-(2-Methyl-3-Propan-2-Ylimidazol-4-Yl)-N-(4-Methylsulfonylphenyl)Pyrimidin-2-Amine",
    ],
  },
  {
    primary_id: "D06WIV",
    names: ["Biphenyl-2-Ylmethyl-(S)-Pyrrolidin-3-Yl-Amine", "CHEMBL478836", "BDBM50262725"],
  },
  {
    primary_id: "D06WJA",
    names: [
      "L-779450",
      "303727-31-3",
      "L-779,450",
      "2-Chloro-5-(2-Phenyl-5-(Pyridin-4-Yl)-1H-Imidazol-4-Yl)Phenol",
      "L779450",
      "CHEMBL373011",
      "2-Chloro-5-(2-Phenyl-5-Pyridin-4-Yl-1H-Imidazol-4-Yl)Phenol",
      "2-(Phenyl)-4-(3-Hydroxy-4-Chlorophenyl)-5-(4-Pyridyl)-1H-Imidazole",
      "2-Chloro-5-[2-Phenyl-5-(4-Pyridinyl)-1H-Imidazol-4-Yl]phenol",
      "C20H14ClN3O",
      "L 779450",
      "2-Chloro-5-[2-Phenyl-5-(Pyridin-4-Yl)-1H-Imidazol-4-Yl]phenol",
      "2-Chloro-5-[2-Phenyl-4-(Pyridin-4-Yl)-1H-Imidazol-5-Yl]phenol",
      "2-Chloro-5-(2-Phenyl-4-(Pyridin-4-Yl)-1H-Imidazol-5-Yl)Phenol",
    ],
  },
  {
    primary_id: "D06WJC",
    names: ["GC-24"],
  },
  {
    primary_id: "D06WJI",
    names: ["OPC-28326"],
  },
  {
    primary_id: "D06WKA",
    names: ["MD-1100"],
  },
  {
    primary_id: "D06WLB",
    names: [
      "Benzyl-[2-(1H-Indol-4-Yloxy)-Ethyl]-Amine",
      "CHEMBL64283",
      "SCHEMBL7206828",
      "ZINC1903142",
      "BDBM50077576",
    ],
  },
  {
    primary_id: "D06WLG",
    names: ["P53 Fusion Protein"],
  },
  {
    primary_id: "D06WLV",
    names: ["ANA-01", "Antibody Therapy (Bone Loss/Chronic Pain), AnaptysBio"],
  },
  {
    primary_id: "D06WLY",
    names: ["PN-951"],
  },
  {
    primary_id: "D06WML",
    names: [
      "5-Ethyl-3,4-Diphenyl-Isoxazole",
      "CHEMBL361504",
      "3,4-Diphenyl-5-Ethylisoxazole",
      "SCHEMBL2279510",
      "MXTWADCRLRQMDW-UHFFFAOYSA-N",
      "ZINC13583259",
    ],
  },
  {
    primary_id: "D06WMM",
    names: [
      "3-Butoxy-9H-Beta-Carboline",
      "CHEMBL66902",
      "3-Butoxy-9H-Pyrido[3,4-B]indole",
      "AC1NFSYC",
      "3-Butoxy-Beta-Carboline",
      "CHEMBL458012",
      "SCHEMBL10644773",
      "ZINC6869004",
    ],
  },
  {
    primary_id: "D06WNK",
    names: ["4-(2,6-Dimethylphenylthio)-2-Methoxybenzonitrile", "CHEMBL448454"],
  },
  {
    primary_id: "D06WNQ",
    names: ["TKM-PLK1"],
  },
  {
    primary_id: "D06WNW",
    names: [
      "Chemopreventive",
      "Chemopreventive (Bladder Cancer)",
      "Chemopreventative Agent (Bladder Cancer), Colotech",
      "Chemopreventive (Bladder Cancer), Colotech",
    ],
  },
  {
    primary_id: "D06WNY",
    names: ["NAVPNLRGDLQVLAQKVART"],
  },
  {
    primary_id: "D06WOF",
    names: ["Rova-T"],
  },
  {
    primary_id: "D06WOJ",
    names: ["JTH-601"],
  },
  {
    primary_id: "D06WOQ",
    names: ["RP-72540", "139088-45-2"],
  },
  {
    primary_id: "D06WPM",
    names: ["Coprexa"],
  },
  {
    primary_id: "D06WPP",
    names: [
      "4'-Bromo-3-(Imidazolylmethyl)-7-Methoxyflavone",
      "CHEMBL209047",
      "BDBM50191596",
      "4''-Bromo-3-(Imidazolylmethyl)-7-Methoxyflavone",
    ],
  },
  {
    primary_id: "D06WQP",
    names: ["PMID22686657C(R)-26"],
  },
  {
    primary_id: "D06WQT",
    names: ["Nolatrexed"],
  },
  {
    primary_id: "D06WRC",
    names: ["3-Cis-P-Coumaroyl Maslinic Acid", "CHEMBL463995"],
  },
  {
    primary_id: "D06WRF",
    names: ["KD019"],
  },
  {
    primary_id: "D06WRJ",
    names: ["Icotinib Hydrochloride", "Conmana", "Conmanna", "Icotinib", "BPI-2009-H"],
  },
  {
    primary_id: "D06WRY",
    names: ["NFTC-103"],
  },
  {
    primary_id: "D06WSG",
    names: ["AP-005"],
  },
  {
    primary_id: "D06WSN",
    names: ["Ala(1-Naph)-Pro-CN", "GTPL8607"],
  },
  {
    primary_id: "D06WSQ",
    names: ["AWD-140-190", "AWD-12-151"],
  },
  {
    primary_id: "D06WSW",
    names: [
      "1-3 Sugar Ring Of Pentamannosyl 6-Phosphate",
      "1-3 SUGAR RING OF PENTAMANNOSYL 6-PHOSPHATE",
      "P3M",
      "AC1L9GSL",
      "DB02755",
      "WURCS=2.0/2,3,2/[a1122h-1a_1-5][a1122h-1a_1-5_6*OPO/3O/3=O]/1-1-2/A3-B1_B3-C1",
      "6-O-Phosphono-Alpha-D-Mannopyranosyl-(1-&gt;3)-Alpha-D-Mannopyranosyl-(1-&gt;3)-Alpha-D-Mannopyranose",
      "[(2R,3S,4S,5S,6R)-6-[(2R,3R,4S,5S,6R)-3,5-Dihydroxy-2-(Hydroxymethyl)-6-[(2S,3S,4S,5R,6R)-2,3,5-Trihydroxy-6-(Hydroxymethyl)Oxan-4-Yl]oxyoxan-4-Yl]oxy-3,4,5-Trihydroxyoxan-2-Yl]methyl Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D06WTI",
    names: ["NRP290"],
  },
  {
    primary_id: "D06WTQ",
    names: ["TD-0714"],
  },
  {
    primary_id: "D06WTZ",
    names: ["Lovastatin"],
  },
  {
    primary_id: "D06WUE",
    names: ["2,5-Dichloro-N-P-Tolylthiophene-3-Sulfonamide", "CHEMBL1171972"],
  },
  {
    primary_id: "D06WUK",
    names: [
      "Carlumab",
      "CCL-2",
      "CNTO-888",
      "Anti-MCP1 Monoclonal Antibodies, Centocor",
      "CCL2 Monoclonal Antibody (Infusion, Cancer), Centocor",
    ],
  },
  {
    primary_id: "D06WUT",
    names: ["Quinoline Carboxamide Derivative 8"],
  },
  {
    primary_id: "D06WVV",
    names: ["1-(2-Hydroxy-3-Nitro-Phenyl)-3-Phenyl-Urea", "CHEMBL283736", "SCHEMBL7491596", "BDBM50140793"],
  },
  {
    primary_id: "D06WWN",
    names: ["AVE-1876", "AVE-1876A", "GABA-B Antagonists (Depression/Anxiety), Sanofi-Aventis"],
  },
  {
    primary_id: "D06WXJ",
    names: ["Thiazole Carboxamide Derivative 14"],
  },
  {
    primary_id: "D06WXN",
    names: ["PMID20615702C8f"],
  },
  {
    primary_id: "D06WXS",
    names: ["APX-002"],
  },
  {
    primary_id: "D06WYG",
    names: ["99mTc-MIP-1407"],
  },
  {
    primary_id: "D06WZG",
    names: ["8-Acetyl-7-Ethoxy-2H-Chromen-2-One", "CHEMBL1288595"],
  },
  {
    primary_id: "D06WZQ",
    names: ["LPO-1010UV"],
  },
  {
    primary_id: "D06XAA",
    names: ["RG7667"],
  },
  {
    primary_id: "D06XAE",
    names: [
      "Drotaverine",
      "Drotaverin",
      "14009-24-6",
      "Dihydroisoperparine",
      "Drotaverine [INN]",
      "No-Spa",
      "Drotaverinum [INN-Latin]",
      "Drotaverina [INN-Spanish]",
      "UNII-98QS4N58TW",
      "98QS4N58TW",
      "C24H31NO4",
      "Drotaverine (INN)",
      "Drotin",
      "Isodihydroperparine",
      "1-(3,4-Diethoxybenzylidene)-6,7-Diethoxy-1,2,3,4-Tetrahydroisoquinoline",
      "(1Z)-1-[(3,4-Diethoxyphenyl)Methylidene]-6,7-Diethoxy-3,4-Dihydro-2H-Isoquinoline",
      "Drotaverina",
      "Drotaverinum",
      "Nospanum",
      "1-Benzyl-3',4',6,7-Tetraethoxy-1,2,3,4-Tetrahydroisoquinoline",
      "No-Shpa",
      "985-12-6",
      "Drotin (TN)",
      "DIHYDROETHAVERINE",
    ],
  },
  {
    primary_id: "D06XAK",
    names: [
      "H-216/44",
      "108495-27-8",
      "1-Piperidinecarboxamide,N-[2-[[3-[4-[2-[2-(Cyclopropylmethoxy)Ethoxy]ethyl]phenoxy]-2-Hydroxypropyl]amino]ethyl]-4-Hydroxy-",
      "H 21644",
      "ACMC-20mbju",
      "AC1L2WUQ",
      "H 216-44",
      "H-216-44",
      "CHEMBL151651",
      "CTK4A6062",
      "L002328",
      "N-[2-[[3-[4-[2-[2-(Cyclopropylmethoxy)Ethoxy]ethyl]phenoxy]-2-Hydroxypropyl]amino]ethyl]-4-Hydroxypiperidine-1-Carboxamide",
      "1-Piperidinecarboxamide, N-(2-((3-(4-(2-(2-(Cyclopropylmethoxy)Ethoxy)Ethyl)Phenoxy)-2-Hydroxypropyl)Amino)Ethyl)-4-Hydroxy-",
    ],
  },
  {
    primary_id: "D06XAT",
    names: ["BCT-100"],
  },
  {
    primary_id: "D06XBG",
    names: [
      "MIP-1267",
      "Carbonic Anhydrase IX Inhibitors (Cancer, Imaging)",
      "MIP-11267-[99mTc]",
      "Carbonic Anhydrase IX Inhibitors (Cancer, Imaging), Molecular Insight",
      "Technetium-99m-MIP-1267",
      "99mTC-MIP-1355",
      "99mTc(CO)3-MIP-1267",
      "99mTc-MIP-1267",
    ],
  },
  {
    primary_id: "D06XBN",
    names: [
      "ACT-GRO-777",
      "AGRO-100",
      "AS-1411",
      "Anticancer Aptamers, Aptamera",
      "GRO-26B",
      "AGRO-100 Derivatives, Aptamera",
      "Nucleolin Inhibitors (Cancer), Aptamera",
      "Nucleolin Inhibitors (Iv, Acute Myeloid Leukemia), Advanced Cancer Therapeutics",
    ],
  },
  {
    primary_id: "D06XCD",
    names: ["PMID27998201-Compound-12"],
  },
  {
    primary_id: "D06XCF",
    names: ["EQ-917"],
  },
  {
    primary_id: "D06XCP",
    names: ["AZD5991"],
  },
  {
    primary_id: "D06XDA",
    names: ["Grandisine F"],
  },
  {
    primary_id: "D06XDI",
    names: ["QAW-039"],
  },
  {
    primary_id: "D06XDJ",
    names: [
      "Clemizole",
      "442-52-4",
      "Histacur",
      "Clemizol",
      "Histakool",
      "Lergopenin",
      "Clemizolum",
      "Depocural",
      "1-(P-Chlorobenzyl)-2-Pyrrolidylmethylenebenzimidazole",
      "1-(4-Chlorobenzyl)-2-(Pyrrolidin-1-Ylmethyl)-1H-Benzo[d]imidazole",
      "NSC 46261",
      "UNII-T97CB3796L",
      "CHEBI:52140",
      "CJXAEXPPLWQRFR-UHFFFAOYSA-N",
      "T97CB3796L",
      "1-[(4-Chlorophenyl)Methyl]-2-(Pyrrolidin-1-Ylmethyl)Benzimidazole",
      "1H-Benzimidazole, 1-[(4-Chlorophenyl)Methyl]-2-(1-Pyrrolidinylmethyl)-",
      "1-(4-Chlorobenzyl)-2-(Pyrrolidin-1-Ylmethyl)-1H-Benzimidazole",
    ],
  },
  {
    primary_id: "D06XDS",
    names: ["GSK-1070806", "Anti-IL-18 Monoclonal Antibody (Intravenous, Inflammatory Bowel Disease), GSK"],
  },
  {
    primary_id: "D06XEL",
    names: [
      "DG051",
      "929916-05-2",
      "DG-051",
      "(S)-4-(2-((4-(4-Chlorophenoxy)Phenoxy)Methyl)Pyrrolidin-1-Yl)Butanoic Acid",
      "CHEMBL565789",
      "4-[(2s)-2-{[4-(4-Chlorophenoxy)Phenoxy]methyl}pyrrolidin-1-Yl]butanoate",
      "Compound 20 [PMID: 19950900]",
      "SCHEMBL989668",
      "GTPL6548",
      "PVCTYSQBVIGZRU-KRWDZBQOSA-N",
      "BDBM50303649",
      "ZINC34884331",
      "AKOS030525381",
      "4-[(2S)-2-[4-(4-Chlorophenoxy)Phenoxymethyl]pyrrolidin-1-Yl]butanoic Acid",
      "4-{(S)-2-[4-(4-Chlorophenoxy)Phenoxymethyl]pyrrolidin-1-Yl}-Butyric Acid",
    ],
  },
  {
    primary_id: "D06XEU",
    names: ["PMID27019002-Compound-28a"],
  },
  {
    primary_id: "D06XFP",
    names: ["1,1,1-Trifluoro-6-(4-Hexyloxy-Phenyl)-Hexan-2-One"],
  },
  {
    primary_id: "D06XFS",
    names: ["2-Methyl-7-(Pyridin-3-Yl)Quinoline", "CHEMBL231763"],
  },
  {
    primary_id: "D06XFV",
    names: ["Neo-Kidney Augment"],
  },
  {
    primary_id: "D06XGB",
    names: ["Tissue Repair Cells (TRCs)"],
  },
  {
    primary_id: "D06XGC",
    names: [
      "BMS-986202",
      "AM-095",
      "AM-966",
      "LPA1 Antagonists (Fibrotic Disease), Amira",
      "Lysophosphatidic Acid Antagonists (Fibrotic Disease), Amira",
    ],
  },
  {
    primary_id: "D06XGK",
    names: ["[3H]pregnenolone Sulphate", "[3H]-Pregnenolone Sulfate"],
  },
  {
    primary_id: "D06XGL",
    names: ["3-Demethoxy-3-D-Xylopyranosylaminothiocolchicine", "CHEMBL213312"],
  },
  {
    primary_id: "D06XGW",
    names: ["Acetylcysteine", "Acetadone EF", "Acetadote ALF", "Acetylcysteine, Cumberland Pharmaceuticals"],
  },
  {
    primary_id: "D06XHC",
    names: [
      "Amcinonide",
      "Amcinonida",
      "Amcinonido",
      "Amcinonidum",
      "Cyclocort",
      "Cyclort",
      "Triamcinolonacetatcyclopentanonid",
      "Visderm",
      "CL 34699",
      "Amcinonido [INN-Spanish]",
      "Amcinonidum [INN-Latin]",
      "CL 34,699",
      "CL-34699",
      "Cyclocort (TN)",
      "Amcinonide (JAN/USP/INN)",
      "Amcinonide [USAN:INN:BAN:JAN]",
      "Triamcinolone 16,17-Cyclopentylidenedioxy-21-Acetate",
      "1,4-Pregnadiene-2,20-Dione-16,17-Cyclopentylidenedioxy-9-Fluoro-11,21-Dihydroxy-21-Acetate",
      "16alpha,17alpha-(Cyclopentane-1,1-Diyldioxy)-9-Fluoro-11beta-Hydroxy-3,20-Dioxopregna-1,4-Dien-21-Yl Acetate",
      "19alpha-Fluor-11beta,21-Dihydroxy-16alpha,17alpha-(Tetramethylen)Methylendioxy-1,4-Pregnadien-3,20-Dion 21-Acetat",
      "9-Fluoro-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione Cyclic 16,17-Acetal With Cyclopentanone, 21-Acetate",
    ],
  },
  {
    primary_id: "D06XHD",
    names: ["Trimesta"],
  },
  {
    primary_id: "D06XIC",
    names: ["VARIECOLIN"],
  },
  {
    primary_id: "D06XIT",
    names: ["ABT-414"],
  },
  {
    primary_id: "D06XIW",
    names: ["(2'Z,3'E)-5-Fluoro-5'-Chloro-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D06XIX",
    names: ["RHuA1AT"],
  },
  {
    primary_id: "D06XIZ",
    names: ["HM-2002"],
  },
  {
    primary_id: "D06XJG",
    names: ["Azole Benzene Derivative 2"],
  },
  {
    primary_id: "D06XJP",
    names: ["APT-222", "ABT-2384", "BRL-54912"],
  },
  {
    primary_id: "D06XJQ",
    names: ["SIRS-T"],
  },
  {
    primary_id: "D06XKM",
    names: ["Privigen"],
  },
  {
    primary_id: "D06XKX",
    names: ["N-Propyl-2-Phenylnorapomorphine Hydrochloride", "CHEMBL550792"],
  },
  {
    primary_id: "D06XLC",
    names: ["BMS-739562"],
  },
  {
    primary_id: "D06XLD",
    names: ["Luliconazole 1.3%"],
  },
  {
    primary_id: "D06XMB",
    names: ["(3-Tetradecylamino-Cyclohexyl)-Phosphonic Acid", "CHEMBL186921"],
  },
  {
    primary_id: "D06XME",
    names: ["TT-105"],
  },
  {
    primary_id: "D06XMF",
    names: ["KT&G-101", "Atrophy Therapy, KT&G/ Yungjin"],
  },
  {
    primary_id: "D06XMU",
    names: [
      "Testosterone",
      "AndroGel",
      "Androderm",
      "Androlin",
      "Andronaq",
      "Andropatch",
      "Androsorb",
      "Andrusol",
      "Depotest",
      "Halotensin",
      "Homosteron",
      "Homosterone",
      "Intrinsa",
      "LibiGel",
      "Malerone",
      "Mertestate",
      "Neotestis",
      "Oreton",
      "Orquisteron",
      "Perandren",
      "Primotest",
      "Primoteston",
      "Relibra",
      "Striant",
      "Sustanon",
      "Sustanone",
      "Teslen",
      "Testandrone",
      "Testaqua",
      "Testiculosterone",
      "Testim",
      "Testobase",
      "Testoderm",
      "Testogel",
      "Testolin",
      "Testopropon",
      "Testosteroid",
      "Testosteron",
      "Testosterona",
      "Testosteronum",
      "Testostosterone",
      "Testoviron",
      "Testrone",
      "Testryl",
      "Virormone",
      "Virosterone",
      "Beta Testosterone",
      "Cristerona T",
      "Cristerone T",
      "Malogen In Oil",
      "Oreton F",
      "Percutacrine Androgenique",
      "Synandrol F",
      "Testoderm Tts",
      "Testopel Pellets",
      "Testosterone And Its Esters",
      "Testosterone Hydrate",
      "Testosterone Solution",
      "Testoviron Schering",
      "Testoviron T",
      "Testro AQ",
      "Virilon IM",
      "AA 2500",
      "Andro 100",
      "Andronate 100",
      "Andronate 200",
      "Andropository 200",
      "Andryl 200",
      "CDB 111C",
      "CMC_13449",
      "COL 1621",
      "CP 601B",
      "Everone 200",
      "Sustason 250",
      "Testamone 100",
      "Testred Cypionate 200",
      "Androderm (TN)",
      "Androgel (TN)",
      "Geno-Cristaux Gremy",
      "Malestrone (Amps)",
      "Malogen, Aquaspension Injection",
      "Neo-Hombreol F",
      "Neo-Testis",
      "Oreton-F",
      "Scheinpharm Testone-Cyp",
      "Striant (TN)",
      "T-Cypionate",
      "Testim (TN)",
      "Testoject-50",
      "Testosterona [INN-Spanish]",
      "Testosterone [Androgenic Steroids, Anabolic]",
      "Testosterone [INN:BAN]",
      "Testosteronum [INN-Latin]",
      "Trans-Testosterone",
      "Testosterone (JAN/USP)",
      "Testrin-P.A",
      "Delta4-Androsten-17beta-Ol-3-One",
      "Delta4-Androsten-17b-Ol-3-One",
      "Androst-4-En-17beta-Ol-3-One",
      "Delta(Sup 4)-Androsten-17(Beta)-Ol-3-One",
      "(17beta)-17-Hydroxyandrost-4-En-3-One",
      "(8R,9S,10R,13S,14S,17S)-17-Hydroxy-10,13-Dimethyl-1,2,6,7,8,9,11,12,14,15,16,17-Dodecahydrocyclopenta[a]phenanthren-3-One",
      "17-Hydroxy-(17-Beta)-Androst-4-En-3-One",
      "17-Hydroxy-(17beta)-Androst-4-En-3-One",
      "17-Hydroxy-4-Androsten-3-One",
      "17-Beta-Hydroxy-Delta(Sup 4)-Androsten-3-One",
      "17-Beta-Hydroxyandrost-4-En-3-One",
      "17b-Hydroxy-4-Androsten-3-One",
      "17beta-Hydroxy-3-Oxo-4-Androstene",
      "17beta-Hydroxy-4-Androsten-3-One",
      "17beta-Hydroxy-Delta(Sup4)-Androsten-3-One",
      "17beta-Hydroxyandrost-4-En-3-One",
      "17beta-Hydroxyandrost-4-Ene-3-One",
      "4-Androsten-17-Ol-3-One",
      "4-Androsten-17beta-Ol-3-One",
      "4-Androstene-17beta-Ol-3-One",
      "7-Beta-Hydroxyandrost-4-En-3-One",
    ],
  },
  {
    primary_id: "D06XMW",
    names: [
      "DPK-060",
      "DP-9011",
      "DP-9012",
      "DP-9013",
      "DP-9011-DP-9013 Series (Atopic Dermatitis/Impetigo), DermaGen",
    ],
  },
  {
    primary_id: "D06XNH",
    names: ["GPCR Targeted Therapeutics"],
  },
  {
    primary_id: "D06XNP",
    names: ["4-Dimethylamino-N-(6-Mercapto-Hexyl)-Benzamide", "CHEMBL324126"],
  },
  {
    primary_id: "D06XNT",
    names: [
      "4-(2-Phenylacetamidomethyl)Benzenesulfonamide",
      "2-Phenyl-N-(4-Sulfamoylbenzyl)Acetamide",
      "41352-39-0",
      "4itp",
      "Sulfonamide Deriv., 7b",
      "AC1N3X6Y",
      "2-Phenyl-N-[(4-Sulfamoylphenyl)Methyl]acetamide",
      "Oprea1_692071",
      "ARONIS26431",
      "CHEMBL574783",
      "BDBM35734",
      "MolPort-010-990-071",
      "ZINC6864120",
      "STL261373",
      "AKOS003796845",
      "MCULE-9432670742",
      "4-Phenylacetamidomethyl-Benzenesulfonamide",
      "BB0294421",
      "4-(Phenylacetylaminomethyl)Benzenesulfonamide",
      "KS-00004825",
      "N-[4-(Aminosulfonyl)Benzyl]-2-Phenylacetamide",
      "Benzeneacetamide, N-[[4-(Aminosulfonyl)Phenyl]methy",
    ],
  },
  {
    primary_id: "D06XNW",
    names: ["NKP-3752"],
  },
  {
    primary_id: "D06XOI",
    names: [
      "BMY-41802",
      "7-[(3S,4R)-3-Amino-4-Methyl-1-Pyrrolidinyl]-1-(Tert-Butyl)-6-Fluoro-4-Oxo-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylic Acid Methanesulfonate",
    ],
  },
  {
    primary_id: "D06XOT",
    names: ["F18-Flutemetamol"],
  },
  {
    primary_id: "D06XPL",
    names: [
      "TOCA-511",
      "Combination Vector-Delivered Gene Therapy/5-Flurocytosine (5-FC) Prodrug (Glioblastoma Multiforme), Tocagen",
    ],
  },
  {
    primary_id: "D06XPX",
    names: [
      "CERE-135",
      "CERE-130",
      "Insulin Like Growth Factor-1 Gene Therapy, Ceregene",
      "IGF-1 Gene Therapy (ALS), Ceregene",
      "IGF-1 Gene Therapy (Amyotrophic Lateral Sclerosis), Ceregene",
      "AAV-IGF-1 Gene Therapy (ALS), Ceregene",
    ],
  },
  {
    primary_id: "D06XQX",
    names: ["(S)-3-Hydroxy-3-Methylglutaryl-CoA"],
  },
  {
    primary_id: "D06XRA",
    names: ["LU AA24493"],
  },
  {
    primary_id: "D06XRQ",
    names: [
      "AA-861",
      "Aa-861",
      "DOCEBENONE",
      "80809-81-0",
      "Docebenonum",
      "Docebenona",
      "AA861",
      "Docebenone [USAN:INN]",
      "Docebenonum [INN-Latin]",
      "Docebenona [INN-Spanish]",
      "UNII-2XRX3BD53M",
      "2-(12-Hydroxydodeca-5,10-Diyn-1-Yl)-3,5,6-Trimethylcyclohexa-2,5-Diene-1,4-Dione",
      "CHEBI:2340",
      "A-61589",
      "2-(12-Hydroxy-5,10-Dodecadiynyl)-3,5,6-Trimethyl-P-Benzoquinone",
      "2,3,5-Trimethyl-6-(12-Hydroxy-5,10-Dodecadiynyl)-1,4-Benzoquinone",
      "2XRX3BD53M",
      "MLS000028467",
      "A 61589",
      "NCGC00015053-05",
      "SMR000058412",
      "2-(12-Hydroxydodeca-5,10-Diynyl)-3,5,6-Trimethyl-1,4-Benzoquinone",
    ],
  },
  {
    primary_id: "D06XRS",
    names: [
      "N-(2-(4-Chlorobenzyloxy)-5-Bromobenzyl)Ethanamine",
      "CHEMBL233385",
      "N-[[5-Bromo-2-[(4-Chlorophenyl)Methoxy]phenyl]methyl]ethanamine",
      "AC1NFZZ9",
      "SCHEMBL5700011",
      "ZINC8227211",
      "BDBM50205773",
      "AKOS002615028",
      "MCULE-5007100183",
      "Benzenemethanamine, 5-Bromo-2-[(4-Chlorophenyl)Methoxy]-N-Ethyl-",
    ],
  },
  {
    primary_id: "D06XSB",
    names: ["870086 (Topical, Atopic Dermatitis), GlaxoSmithKline"],
  },
  {
    primary_id: "D06XSG",
    names: ["AZD8075"],
  },
  {
    primary_id: "D06XSU",
    names: ["Gluconate", "2,3,4,5,6-Pentahydroxyhexanoate", "AC1N4TE4", "GTPL4519", "MolPort-019-781-265"],
  },
  {
    primary_id: "D06XTA",
    names: ["PMID27998201-Compound-17"],
  },
  {
    primary_id: "D06XTT",
    names: ["FPL64176"],
  },
  {
    primary_id: "D06XVF",
    names: ["CBT-1"],
  },
  {
    primary_id: "D06XVN",
    names: ["Farletuzumab"],
  },
  {
    primary_id: "D06XVR",
    names: ["BAN2401"],
  },
  {
    primary_id: "D06XVV",
    names: [
      "BENZOYLENUREA",
      "Benzoyleneurea",
      "86-96-4",
      "Quinazoline-2,4(1H,3H)-Dione",
      "2,4(1H,3H)-Quinazolinedione",
      "2,4-Dihydroxyquinazoline",
      "Quinazolinedione",
      "Benzouracil",
      "Quinazoline-2,4-Diol",
      "Quinazoline-2,4-Dione",
      "1H-Quinazoline-2,4-Dione",
      "Urea, Benzoylene-",
      "1,2,3,4-Tetrahydroquinazoline-2,4-Dione",
      "2-Keto-4-Quinazolinone",
      "2,4-Dioxotetrahydroquinazoline",
      "NSC 2108",
      "(1H,3H)Quinazoline Dione-2,4",
      "(1H,3H)-Quinazoline-2,4-Dione",
      "EINECS 201-712-6",
      "(1H,3H)Quinazoline Dione-2,4 [French]",
      "AI3-28016",
      "CHEMBL421646",
      "1H,3H-Quinazoline-2,4-Dione",
      "UNII-18K00A531C",
    ],
  },
  {
    primary_id: "D06XVX",
    names: ["RP-5000", "Antipsychotic (Oral, Schizophrenia /Bipolar Disorder/ Major Depressive Disorder), Reviva"],
  },
  {
    primary_id: "D06XWB",
    names: ["Huperzine A"],
  },
  {
    primary_id: "D06XWC",
    names: ["PMID25435179-Compound-WO2014110399Splitomycin"],
  },
  {
    primary_id: "D06XWD",
    names: ["CAR-T Cells Targeting HER2"],
  },
  {
    primary_id: "D06XWF",
    names: ["Metallo-Topiramate"],
  },
  {
    primary_id: "D06XWQ",
    names: ["Benzothiazine-Carboxamide Compound 2"],
  },
  {
    primary_id: "D06XXA",
    names: ["AMA-1-Dico"],
  },
  {
    primary_id: "D06XXD",
    names: ["IG-AI-025"],
  },
  {
    primary_id: "D06XXG",
    names: [
      "(2-Fluorophenyl)-(4-Fluorophenyl)Phenylacetamide",
      "CHEMBL42205",
      "SCHEMBL1443821",
      "UFALWTGZIJAUPP-UHFFFAOYSA-N",
      "BDBM50099566",
      "ICA-15451",
      "(2-Fluorophenyl)-(4 Fluorophenyl)Phenylacetamide",
    ],
  },
  {
    primary_id: "D06XXH",
    names: ["Dacomitinib"],
  },
  {
    primary_id: "D06XXJ",
    names: ["PMID25468267-Compound-54"],
  },
  {
    primary_id: "D06XXW",
    names: ["NRX-1074"],
  },
  {
    primary_id: "D06XXZ",
    names: ["Sustained Release Incrementally Modified Drug"],
  },
  {
    primary_id: "D06XYL",
    names: ["DP-6001"],
  },
  {
    primary_id: "D06XYZ",
    names: ["10-Benzoyl-1,8-Dihydroxy-10H-Anthracen-9-One", "CHEMBL123724", "BDBM50060881"],
  },
  {
    primary_id: "D06XZM",
    names: ["GBL-100"],
  },
  {
    primary_id: "D06XZR",
    names: ["Pizotyline"],
  },
  {
    primary_id: "D06XZW",
    names: ["Troglitazone"],
  },
  {
    primary_id: "D06YAB",
    names: ["PMID28870136-Compound-51"],
  },
  {
    primary_id: "D06YAG",
    names: ["ABX-102", "Anticancer Antibody (2), Abxign"],
  },
  {
    primary_id: "D06YAI",
    names: ["SOM-0004"],
  },
  {
    primary_id: "D06YAK",
    names: ["MG-3", "Chloroquine Analog (Plasmodium Infection), University Of Milan"],
  },
  {
    primary_id: "D06YBF",
    names: ["Acyl Piperidine Derivative 2"],
  },
  {
    primary_id: "D06YCQ",
    names: [
      "Procyanidin B-2 3,3'-Di-O-Gallate",
      "CHEMBL506811",
      "Bis(3,4,5-Trihydroxybenzoic Acid)2alpha,2'alpha-Bis(3,4-Dihydroxyphenyl)-5,5',7,7'-Tetrahydroxy-4,8'-Bichroman-3alpha,3'alpha-Diyl Ester",
    ],
  },
  {
    primary_id: "D06YCR",
    names: ["Marburg Virus DNA Vaccine"],
  },
  {
    primary_id: "D06YCW",
    names: ["PMID26609882-Compound-58"],
  },
  {
    primary_id: "D06YDM",
    names: ["ISIS 107681"],
  },
  {
    primary_id: "D06YDQ",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Heptyl Ester",
      "CHEMBL420395",
      "BDBM50116818",
      "4-(Sulfamoyloxy)Benzoic Acid Heptyl Ester",
    ],
  },
  {
    primary_id: "D06YDW",
    names: [
      "23-Hydroxybetulinic Acid",
      "85999-40-2",
      "CHEBI:69580",
      "3,23-Dihydroxy-20(29)-Lupen-28-Oic Acid",
      "Anemosapogenin",
      "23-Hydroxybetulinicacid",
      "23-Hydroxy Betulic Acid",
      "CHEMBL251308",
      "23-Hydroxybetulinic Acid, 25",
      "BDBM23209",
      "MolPort-039-338-114",
      "HXWLKAXCQLXHML-WGOZWDAUSA-N",
      "HY-N0566",
      "EBD870843",
      "ZINC14980302",
      "AKOS032944876",
      "CS-5686",
      "SC-72875",
      "23-Hydroxybetulinic Acid, &gt",
      "3I(2),23-Dihydroxylup-20(29)-En-28-Oic Acid",
    ],
  },
  {
    primary_id: "D06YEB",
    names: ["1-(10H-Phenothiazin-10-Yl)-3-Phenylbutan-1-One", "CHEMBL240262"],
  },
  {
    primary_id: "D06YEE",
    names: ["Prodigiosin"],
  },
  {
    primary_id: "D06YEG",
    names: ["Indirab"],
  },
  {
    primary_id: "D06YEK",
    names: ["1-Hydroxyl-3,5-Bis(4-Hydroxylstyryl)Benzene Derivative 4"],
  },
  {
    primary_id: "D06YEV",
    names: [
      "Hydroxydimethylarsine Oxide",
      "Cacodylic Acid",
      "Dimethylarsinic Acid",
      "75-60-5",
      "Chexmate",
      "Silvisar",
      "Phytar",
      "Arsan",
      "Erase",
      "DIMETHYLARSENIC ACID",
      "Silvisar 510",
      "Arsinic Acid, Dimethyl-",
      "Phytar 560",
      "Ansar 138",
      "Sylvicor",
      "Moncide",
      "Dilic",
      "Rad-E-Cate 35",
      "Agent Blue",
      "Acide Cacodylique",
      "Alkargen",
      "Phytar 138",
      "Acide Dimethylarsinique",
      "Cotton Aide HC",
      "Kyselina Kakodylova",
      "RCRA Waste Number U136",
      "Phytar 600",
      "Caswell No 133",
      "Arsine Oxide, Hydroxydimethyl-",
      "Ansar",
      "UNII-AJ2HL7EU8K",
      "Acide Cacodylique [French]",
      "Kyselina Kakodylova [Czech]",
    ],
  },
  {
    primary_id: "D06YFA",
    names: [
      "Bromocriptine",
      "Bagren",
      "Bromergocryptine",
      "Bromocriptin",
      "Bromocriptina",
      "Bromocriptinum",
      "Bromocryptin",
      "Bromocryptine",
      "Bromoergocriptine",
      "Bromoergocryptine",
      "Ergoset",
      "Parlodel",
      "Bromocriptine [BAN]",
      "Bromocriptine Methanesulfonate",
      "Parlodel Snaptabs",
      "Alti-Bromocriptine",
      "Apo-Bromocriptine",
      "Bromocriptina [INN-Spanish]",
      "Bromocriptinum [INN-Latin]",
      "CB-154",
      "Parlodel (TN)",
      "Bromocriptine (USAN/INN)",
      "Bromocriptine [USAN:BAN:INN]",
      "Ergocryptine, 2-Bromo-(8CI)",
      "(5'alpha)-2-Bromo-12'-Hydroxy-2'-(1-Methylethyl)-5'-(2-Methylpropyl)-3',6',18-Trioxoergotaman",
      "(5'alpha)-2-Bromo-12'-Hydroxy-2'-(1-Methylethyl)-5'-(2-Methylpropyl)Ergotaman-3',6',18-Trione",
      "(5'alpha)-2-Bromo-12'-Hydroxy-5'-(2-Methylpropyl)-2'-(Propan-2-Yl)-3',6',18-Trioxoergotaman",
      "(5'alpha)-2-Bromo-12'-Hydroxy-5'-Isobutyl-2'-Isopropyl-3',6',18-Trioxoergotaman",
      "(6aR,9R)-5-Bromo-N-((2R,5S,10aS,10bS)-10b-Hydroxy-5-Isobutyl-2-Isopropyl-3,6-Dioxooctahydro-2H-Oxazolo[3,2-A]pyrrolo[2,1-C]pyrazin-2-Yl)-7-Methyl-4,6,6a,7,8,9-Hexahydroindolo[4,3-Fg]quinoline-9-Carboxamide",
      "2-Bromo-12'-Hydroxy-2'-(1-Methylethyl)-5'-Alpha-(2-Methylpropyl)Ergotamin-3',6',18-Trione",
      "2-Bromo-Alpha-Ergocryptine",
      "2-Bromo-Alpha-Ergokryptin",
      "2-Bromo-Alpha-Ergokryptine",
      "2-Bromoergocryptine Methanesulfonate",
      "2-Bromoergokryptine",
    ],
  },
  {
    primary_id: "D06YFO",
    names: ["AD-121"],
  },
  {
    primary_id: "D06YFT",
    names: ["1-Methyl-L-Tryptophan", "1-L-MT", "1-LMT"],
  },
  {
    primary_id: "D06YGF",
    names: [
      "Caffeic Acid Phenethyl Ester",
      "Phenethyl Caffeate",
      "104594-70-9",
      "CAPE",
      "Phenethyl 3-(3,4-Dihydroxyphenyl)Acrylate",
      "Capeee",
      "Phenylethyl Caffeate",
      "115610-29-2",
      "Caffeic Acid Phenylethyl Ester",
      "UNII-G960R9S5SK",
      "2-Phenylethyl Caffeate",
      "PHENETHYL CAFFEATE (CAPE)",
      "Caffeic Acid-Phenethyl Ester",
      "CHEBI:8062",
      "G960R9S5SK",
      "CHEMBL319244",
      "100981-80-4",
      "Caffeic Acid 2-Phenylethyl Ester",
      "SWUARLUWKZWEBQ-VQHVLOKHSA-N",
      "2-Phenylethyl 3-(3,4-Dihydroxyphenyl)-2-Propenoate",
      "Caffeic Acid Phenethyl Ester, Synthetic",
      "2-Phenylethyl (2e)-3-(3,4-Dihydroxyphenyl",
    ],
  },
  {
    primary_id: "D06YGP",
    names: ["BN-OD-026"],
  },
  {
    primary_id: "D06YGT",
    names: ["N-Tridecanoic Acid"],
  },
  {
    primary_id: "D06YHL",
    names: [
      "Cefamandole",
      "Cefadole",
      "Cefamandolum",
      "Cefamandole (USAN/INN)",
      "(6R,7R)-7-(R)-Mandelamido-3-(((1-Methyl-1H-Tetrazol-5-Yl)Thio)Methyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-Carboxylic Acid",
      "(6R,7R)-7-[[(2R)-2-Hydroxy-2-Phenylacetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-Hydroxy-2-Phenylacetyl]amino}-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-Hydroxy-2-Phenylacetyl]amino}-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Thio]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-[(2R)-2-Hydroxy-2-Phenylacetamido]-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}ceph-3-Em-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D06YIB",
    names: [
      "DDATHF",
      "DATHF",
      "95693-76-8",
      "5,10-Dideazatetrahydrofolate",
      "5,10-Dideaza-5,6,7,8-Tetrahydrofolic Acid",
      "5,10-Dideazatetrahydrofolic Acid",
      "L-Glutamic Acid,N-[4-[2-(2-Amino-3,4,5,6,7,8-Hexahydro-4-Oxopyrido[2,3-D]pyrimidin-6-Yl)Ethyl]benzoyl]-",
      "L-Glutamic Acid, N-(4-(2-(2-Amino-1,4,5,6,7,8-Hexahydro-4-Oxopyrido(2,3-D)Pyrimidin-6-Yl)Ethyl)Benzoyl)-",
      "C21H25N5O6",
      "AC1L2XXH",
      "Pyrido(2,3-D)Pyrimidine, L-Glutamic Acid Deriv",
      "CHEMBL142806",
      "SCHEMBL9045915",
      "ZUQBAQVRAURMCL-CVRLYYSRSA-N",
    ],
  },
  {
    primary_id: "D06YJN",
    names: [
      "1,3-Diallyl-7-Methyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL26406",
      "1,3-Diallyl-7-Methylxanthine",
      "BDBM50025574",
      "1,3-Di(2-Propenyl)-7-Methylxanthine",
    ],
  },
  {
    primary_id: "D06YJX",
    names: ["Enoxaprin"],
  },
  {
    primary_id: "D06YKH",
    names: [
      "2-Bromo-4-(3-Fluorophenylethynyl)Thiazole",
      "CHEMBL382491",
      "878018-74-7",
      "SCHEMBL4145773",
      "CTK2I2017",
      "DTXSID80468225",
      "ZINC13680939",
      "BDBM50181781",
      "AKOS015965444",
      "KB-228728",
      "Thiazole, 2-Bromo-4-[(3-Fluorophenyl)Ethynyl]-",
    ],
  },
  {
    primary_id: "D06YKN",
    names: ["Resorcinol Compound 22"],
  },
  {
    primary_id: "D06YKU",
    names: [
      "NSC-26745",
      "5-Hydroxyflavone",
      "491-78-1",
      "Primuletin",
      "5-Hydroxy-2-Phenyl-4H-Chromen-4-One",
      "5-Hydroxy-2-Phenylchromen-4-One",
      "5-Hydroxy-2-Phenyl-Chromen-4-One",
      "NSC 26745",
      "5-Hydroxy-2-Phenylchromone",
      "UNII-378AE9MHL3",
      "CHEMBL16807",
      "MLS002639146",
      "378AE9MHL3",
      "IYBLVRRCNVHZQJ-UHFFFAOYSA-N",
      "NSC26745",
      "MFCD00016944",
      "4H-1-Benzopyran-4-One,5-Hydroxy-2-Phenyl-",
      "Primuliten",
      "5-Hydroxy-Flavone",
      "5-Hydroxy-2-Phenyl-4-Benzopyrone",
      "EINECS 207-743-1",
      "5-Hydroxy-2-Phenyl-4h-Chromen-4-On",
      "AC1Q6AKC",
      "ACMC-209kf5",
      "Oprea1_688541",
      "5-Hydroxyflavone, &gt;=97%",
    ],
  },
  {
    primary_id: "D06YMS",
    names: [
      "DADLE",
      "Dadle",
      "63631-40-3",
      "H-TYR-D-ALA-GLY-PHE-D-LEU-OH",
      "DADL",
      "(D-Ala2,D-Leu5)-Enkephalin",
      "[D-Ala2, D-Leu5]-Enkephalin",
      "CHEMBL340032",
      "BW-180C",
      "Tyr-D-Ala-Gly-Phe-D-Leu",
      "(2R,5S,11R,14S)-14-Amino-5-Benzyl-15-(4-Hydroxyphenyl)-2-Isobutyl-11-Methyl-4,7,10,13-Tetraoxo-3,6,9,12-Tetraazapentadecan-1-Oic Acid",
      "(2R)-2-[[(2S)-2-[[2-[[(2R)-2-[[(2S)-2-Amino-3-(4-Hydroxyphenyl)Propanoyl]amino]propanoyl]amino]acetyl]amino]-3-Phenylpropanoyl]amino]-4-Methylpentanoic Acid",
      "UNII-HB4QD9GL6F",
      "HB4QD9GL6F",
      "DADLE-OH",
      "AC1OCEJN",
      "ZINC14952092",
      "H-Tyr-D-Ala-Gly-Phe-D-Leu",
    ],
  },
  {
    primary_id: "D06YMT",
    names: ["PLX-ORI3"],
  },
  {
    primary_id: "D06YMZ",
    names: ["N-(6-Phenethyl-1H-Indazol-3-Yl)Butyramide", "CHEMBL1084684", "SCHEMBL6489338"],
  },
  {
    primary_id: "D06YNK",
    names: ["TRM-88", "AEM-II"],
  },
  {
    primary_id: "D06YOD",
    names: ["ZD-7717"],
  },
  {
    primary_id: "D06YON",
    names: ["Pyrrolidinyl Urea Derivative 9"],
  },
  {
    primary_id: "D06YPQ",
    names: ["SPI-452"],
  },
  {
    primary_id: "D06YPU",
    names: [
      "Dexibuprofen",
      "(S)-(+)-Ibuprofen",
      "51146-56-6",
      "DEXIBUPROFEN",
      "S(+)-Ibuprofen",
      "(S)-2-(4-Isobutylphenyl)Propanoic Acid",
      "(S)-Ibuprofen",
      "D-Ibuproten",
      "(S)-(+)-2-(4-Isobutylphenyl)Propionic Acid",
      "(+)-(S)-P-Isobutylhydratropic Acid",
      "Seractil",
      "(2S)-2-[4-(2-Methylpropyl)Phenyl]propanoic Acid",
      "(S)-(+)-4-Isobutyl-Alpha-Methylphenylacetic Acid",
      "UNII-671DKG7P5S",
      "S-(+)-Ibuprofen",
      "(2S)-2-(4-Isobutylphenyl)Propanoic Acid",
      "CHEMBL175",
      "671DKG7P5S",
      "CHEBI:43415",
      "(S)-Alpha-Methyl-4-(2-Methylpropyl)Benzeneacetic Acid",
      "NCGC00016861-05",
    ],
  },
  {
    primary_id: "D06YQD",
    names: ["N-Isopentyl-5,6-Dip-Tolylpyrazine-2-Carboxamide", "CHEMBL246478"],
  },
  {
    primary_id: "D06YQR",
    names: ["(2R,3S,4S,5R)-2-Propylpiperidine-3,4,5-Triol", "CHEMBL1089185"],
  },
  {
    primary_id: "D06YQS",
    names: [
      "6-(2-Fluoro-Benzenesulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL198119",
      "SCHEMBL6205793",
      "BDBM16600",
      "AEDNYZQZIZUZTP-UHFFFAOYSA-N",
      "Phenyl-Substituted Sulfonylpyridazinone, 8f",
    ],
  },
  {
    primary_id: "D06YRR",
    names: ["GSK2254233A"],
  },
  {
    primary_id: "D06YSI",
    names: ["ISIS 222001"],
  },
  {
    primary_id: "D06YSK",
    names: ["HuMax-Her2"],
  },
  {
    primary_id: "D06YSM",
    names: ["PM01183"],
  },
  {
    primary_id: "D06YSP",
    names: ["AT13387", "AT-13387"],
  },
  {
    primary_id: "D06YSX",
    names: [
      "Pro-Erectile Agents",
      "BRSD-1025",
      "RSD-1026",
      "RSD-1029",
      "RSD-1034",
      "RSD-1052",
      "RSD-992",
      "Pro-Erectile Agents, Cardiome",
      "Pro-Erectile Agents, Nortran",
    ],
  },
  {
    primary_id: "D06YTE",
    names: ["NOX-D14"],
  },
  {
    primary_id: "D06YTN",
    names: [
      "Decyl-Phosphonic Acid",
      "Decylphosphonic Acid",
      "6874-60-8",
      "Decanephosphonic Acid",
      "Phosphonic Acid, Decyl-",
      "1-Decanephosphonic Acid",
      "N-Decylphosphonic Acid",
      "1-DECYLPHOSPHONIC ACID",
      "Phosphonic Acid, P-Decyl-",
      "EINECS 229-975-2",
      "NSC 407850",
      "BRN 1771572",
      "AI3-23065",
      "CHEMBL188083",
      "DZQISOJKASMITI-UHFFFAOYSA-N",
      "C10H23O3P",
      "DecylphosphonicAcid",
      "1-Decanephosphonicacid",
      "N-Decanephosphonic Acid",
      "ACMC-1CUHV",
      "Phosphonic Acid,P-Decyl-",
      "Decylphosphonic Acid, 97%",
      "AC1Q6RQ2",
      "10",
      "4-04-00-03564 (Beilstein Handbook Reference)",
      "SCHEMBL110008",
    ],
  },
  {
    primary_id: "D06YTV",
    names: [
      "Ad35",
      "Ad35HIV-EnvA",
      "Ad35 (HIV-1 Vaccine)",
      "Ad35 (HIV-1 Vaccine), NIAID/GenVec",
      "Immune Modulating (AAV, HIV-1 Vaccine), NIAID/GenVec",
    ],
  },
  {
    primary_id: "D06YUM",
    names: ["AZD6423"],
  },
  {
    primary_id: "D06YUR",
    names: [
      "Oxindole Derivative",
      "5-Fluorooxindole",
      "56341-41-4",
      "5-Fluoroindolin-2-One",
      "5-Fluoro-2-Oxindole",
      "5-Fluoro-1,3-Dihydro-2H-Indol-2-One",
      "5-Fluoro-1,3-Dihydroindol-2-One",
      "2H-Indol-2-One, 5-Fluoro-1,3-Dihydro-",
      "5-Fluoro-2,3-Dihydro-1H-Indol-2-One",
      "5-Fluoro-1,3-Dihydro-Indol-2-One",
      "5-Fluoro-2-Indolinone",
      "5-Fluoro-2,3-Dihydro-2-Oxoindole",
      "5-FLUORO-2-OXYINDOLE",
      "5-Fluoro-2-Oxindole, 97%",
      "MFCD02179598",
      "5-Fluoro Indoline-2-One",
      "5-Fluoroindolinone",
      "5-Fluoro-Oxindole",
      "5-Fluoro Oxindole",
      "Zlchem 713",
      "FLUOROOXINDOLE",
      "PubChem1687",
      "ACMC-209lrt",
      "AC1MVD5T",
    ],
  },
  {
    primary_id: "D06YVE",
    names: ["M3B6S", "CHEMBL209751"],
  },
  {
    primary_id: "D06YWA",
    names: ["BVA-101"],
  },
  {
    primary_id: "D06YWH",
    names: ["YM155"],
  },
  {
    primary_id: "D06YWZ",
    names: ["Y-39041"],
  },
  {
    primary_id: "D06YXD",
    names: ["Taprostene", "CG-4203"],
  },
  {
    primary_id: "D06YXT",
    names: ["NNC 711"],
  },
  {
    primary_id: "D06YYD",
    names: [
      "Dexlansoprazole",
      "(R)-Lansoprazole",
      "138530-94-6",
      "Kapidex",
      "Dexilant",
      "R-(+)-LANSOPRAZOLE",
      "Dexilant Solutab",
      "TAK 390",
      "UNII-UYE4T5I70X",
      "TAK-390",
      "(R)-(+)-Lansoprazole",
      "T 168390",
      "UYE4T5I70X",
      "AK170558",
      "TAK-390MR",
      "T-168390",
      "2-((R)-((3-Methyl-4-(2,2,2-Trifluoroethoxy)-2-Pyridinyl)Methyl)Sulfinyl)-1H-Benzimidazole",
      "2-[(R)-[3-Methyl-4-(2,2,2-Trifluoroethoxy)-2-Pyridyl]methylsulfinyl]-1H-Benzimidazole",
      "(R)-2-(((3-Methyl-4-(2,2,2-Trifluoroethoxy)Pyridin-2-Yl)Methyl)Sulfinyl)-1H-Benzo[d]imidazole",
      "Dexlansoprazole (INN/USAN)",
      "KS-1075",
      "Lansoprazole",
    ],
  },
  {
    primary_id: "D06YYJ",
    names: ["Bis-{[R-(-)-Apomorphine-2-Oxy]ethyl} Ether", "CHEMBL444149"],
  },
  {
    primary_id: "D06YYY",
    names: [
      "KAR-1880",
      "Anti-Inflammatory OS-HDI",
      "OS-HDI-2",
      "HDAC 2 Inhibitors (Topical, Dermatitis/Psoriasis), Karus",
    ],
  },
  {
    primary_id: "D06YZB",
    names: ["Inositol 2,4,5-Trisphosphate"],
  },
  {
    primary_id: "D06YZW",
    names: ["ISIS 191755"],
  },
  {
    primary_id: "D06ZAE",
    names: ["Pyrazolo[3,4-C]pyridine Derivative 1"],
  },
  {
    primary_id: "D06ZAK",
    names: ["CA-50040"],
  },
  {
    primary_id: "D06ZAM",
    names: ["MF268"],
  },
  {
    primary_id: "D06ZAP",
    names: ["P28 Peptide"],
  },
  {
    primary_id: "D06ZAS",
    names: ["Technetium Tc-99m Ferpentetate Kit"],
  },
  {
    primary_id: "D06ZAY",
    names: [
      "Haloprogin",
      "Aloprogen",
      "Haloprogina",
      "Haloprogine",
      "Haloproginum",
      "Halotex",
      "Mycanden",
      "Mycilan",
      "Polik",
      "Component Of Halotex",
      "M 1028",
      "Haloprogina [INN-Spanish]",
      "Haloprogine [INN-French]",
      "Haloproginum [INN-Latin]",
      "Halotex (TN)",
      "Mycanden (TN)",
      "Mycilan (TN)",
      "Polik (TN)",
      "Haloprogin [USAN:INN:JAN]",
      "M-1028 (Meiji)",
      "Haloprogin (JAN/USAN/INN)",
      "Ether, 3-Iodo-2-Propynyl 2,4,5-Trichlorophenyl",
      "Benzene,1,2,4-Trichloro-5-[(3-Iodo-2-Propynyl)Oxy]",
      "(3-Iod-2-Propinyl)-(2,4,5-Trichlorphenyl)Ether",
      "1,2,4-Trichloro-5-(3-Iodoprop-2-Ynoxy)Benzene",
      "1,2,4-Trichloro-5-[(3-Iodo-2-Propynyl) Oxy]benzene",
      "2,4,5-Trichlorophenyl .gamma.-Iodopropargil Ether",
      "2,4,5-Trichlorophenyl .gamma.-Iodopropargyl Ether",
      "2,4,5-Trichlorophenyl Gamma-Iodopropargil Ether",
      "2,4,5-Trichlorophenyl Iodopropargyl Ether",
      "2,4,5-Trichlorophenyl-Gamma-Iodopropargyl Ether",
      "3-Iodo-2-Propynyl 2,4,5-Trichlorophenyl Ether",
    ],
  },
  {
    primary_id: "D06ZBT",
    names: [
      "3-Bromo-5-[(2-Methyl-4-Thiazolyl)Ethynyl]pyridine",
      "CHEMBL202116",
      "329204-97-9",
      "SCHEMBL4144716",
      "SLLMZMSYBFFSLX-UHFFFAOYSA-N",
      "BDBM50181777",
      "2-Methyl-4-(5-Bromo-3-Pyridylethynyl)Thiazole",
    ],
  },
  {
    primary_id: "D06ZBV",
    names: [
      "CARCININE",
      "Carcinine",
      "56897-53-1",
      "N-(2-(1H-Imidazol-5-Yl)Ethyl)-3-Aminopropanamide",
      "Beta-Alanylhistamine",
      "UNII-WIV0W167TC",
      "WIV0W167TC",
      "N-[2-(1H-Imidazol-5-Yl)Ethyl]-Beta-Alaninamide",
      "SPBio_002343",
      "Beta-Alaninylhistamine",
      "N-Beta-Alanylhistamine",
      "AC1L1DZE",
      "Prestwick2_000422",
      "Prestwick1_000422",
      "Prestwick0_000422",
      "Prestwick3_000422",
      "Lopac-C-2321",
      "AC1Q5P6Q",
      "N-[2-(1h-Imidazol-5-Yl)Ethyl]-",
      "A-Alaninamide",
      "Lopac0_000210",
      "BSPBio_000404",
      "KSC921E6R",
      "MLS002153803",
      "SCHEMBL2819350",
      "CHEMBL461024",
      "BPBio1_000446",
      "SCHEMBL19051459",
      "CHEBI:95262",
      "CTK8C1268",
    ],
  },
  {
    primary_id: "D06ZCH",
    names: ["SA-ANP"],
  },
  {
    primary_id: "D06ZCP",
    names: ["Ischemic Tolerant Allogeneic Mesenchymal Stem Cell Therapy"],
  },
  {
    primary_id: "D06ZCX",
    names: ["LY-326449"],
  },
  {
    primary_id: "D06ZDF",
    names: ["ME67"],
  },
  {
    primary_id: "D06ZEC",
    names: ["Glucagon"],
  },
  {
    primary_id: "D06ZEE",
    names: [
      "Atovaquone",
      "Acuvel",
      "Atavaquone",
      "Mepron",
      "Pron",
      "Wellvone",
      "Atovaquone GlaxoSmithKline Brand",
      "Glaxo Wellcome Brand Of Atovaquone",
      "GlaxoSmithKline Brand Of Atovaquone",
      "Malarone Pediatric",
      "BW 566C",
      "BW 566C80",
      "Hydroxynaphthoquinone 566C80",
      "Atovaquone & Interleukin 12",
      "BW 556C-80",
      "BW 566C-80",
      "BW-A 566C",
      "DRG-0084",
      "Hydroxynaphthoquinone, 566C80",
      "Mepron (TN)",
      "Mepron (Antipneumocystic)",
      "ATO & IL-12",
      "Atovaquone (USP/INN)",
      "Atovaquone [USAN:BAN:INN]",
      "CRL-8131 & Atovaquone",
      "2-(4-(4'-Chlorophenyl)Cyclohexyl)-3-Hydroxy-1,4-Naphthoquinone",
      "2-(4-(4-Chlorophenyl)Cyclohexyl)-3-Hydroxy-1,4-Naphthoquinone",
      "2-(Trans-4-(4-Chlorophenyl)Cyclohexyl)-3-Hydroxy-1,4-Naphthoquinone",
      "2-(Trans-4-(P-Chlorophenyl)Cyclohexyl)-3-Hydroxy-1,4-Naphthoquinone",
      "2-[trans-4-(4-Chlorophenyl)Cyclohexyl]-3-Hydroxy-1,4-Naphthoquinone",
      "2-[trans-4-(4-Chlorophenyl)Cyclohexyl]-3-Hydroxynaphthalene-1,4-Dione",
      "2-[trans-4-(P-Chlorophenyl)Cyclohexyl]-3-Hydroxy-1,4-Naphthoquinone",
      "3-(4-(4-Chlorophenyl)Cyclohexyl)-4-Hydroxy-Naphthalene-1,2-Dione",
      "3-[4-(4-Chlorophenyl)Cyclohexyl]-4-Hydroxynaphthalene-1,2-Dione",
      "566C80 Hydroxynaphthoquinone",
      "566C80, Hydroxynaphthoquinone",
      "ATQ",
    ],
  },
  {
    primary_id: "D06ZEI",
    names: ["PMID25772215-Compound-US02014179750TG8-15"],
  },
  {
    primary_id: "D06ZEO",
    names: ["BAY 1021189"],
  },
  {
    primary_id: "D06ZFV",
    names: ["QPI-1007"],
  },
  {
    primary_id: "D06ZGS",
    names: [
      "1-Adamantan-1-Ylmethyl-3-Thiazol-2-Yl-Thiourea",
      "CHEMBL149161",
      "AC1MHEHW",
      "SCHEMBL6281905",
      "PHI-535",
      "BDBM50097038",
      "1-(1-Adamantylmethyl)-3-Thiazol-2-Yl-Thiourea",
      "1-(1-Adamantylmethyl)-3-(2-Thiazolyl)Thiourea",
      "1-(1-Adamantylmethyl)-3-(1,3-Thiazol-2-Yl)Thiourea",
    ],
  },
  {
    primary_id: "D06ZHN",
    names: ["Lactermin"],
  },
  {
    primary_id: "D06ZHZ",
    names: [
      "FX-125L",
      "BN-83250",
      "BN-83470",
      "Broad Spectrum Chemokine Inhibitors (Oral/Iv, Respiratory Disorders/Surgical Adhesions), Funxional",
    ],
  },
  {
    primary_id: "D06ZIE",
    names: ["SCANDENOLIDE"],
  },
  {
    primary_id: "D06ZII",
    names: ["Eszopiclone"],
  },
  {
    primary_id: "D06ZIL",
    names: ["CTA-001", "CTA-001.12D7", "Anti-NY-ESO-1 MAbs (Cancer), CT Atlantic"],
  },
  {
    primary_id: "D06ZIO",
    names: ["MRT67307"],
  },
  {
    primary_id: "D06ZIS",
    names: [
      "SIG-990",
      "AFC",
      "SIG-1191",
      "SIG-1192",
      "SIG-989",
      "IPC Analogs (Rosacea), Signum Biosciences",
      "Isoprenylcysteine Analogs (Rosacea), Signum Biosciences",
      "Anti-Inflammatory AFC (Rosacea), Signum Biosciences",
      "Anti-Inflammatory G-Protein Modulators (Rosacea), Signum",
      "Anti-Inflammatory N-Acetyl-Sfarnesylcysteine (Rosacea), Signum Biosciences",
      "N-Acetyl-S-Farnesyl-L-Cysteine",
    ],
  },
  {
    primary_id: "D06ZIT",
    names: ["Pegloticase"],
  },
  {
    primary_id: "D06ZJD",
    names: ["Melapuldencel-T"],
  },
  {
    primary_id: "D06ZJQ",
    names: ["KMI-172"],
  },
  {
    primary_id: "D06ZKG",
    names: ["SC-56525"],
  },
  {
    primary_id: "D06ZLJ",
    names: ["MRS1476"],
  },
  {
    primary_id: "D06ZLL",
    names: ["(E,E)-8-[4-(3-Bromophenyl)Butadien-1-Yl]caffeine", "CHEMBL522256"],
  },
  {
    primary_id: "D06ZME",
    names: ["RTL-551"],
  },
  {
    primary_id: "D06ZMH",
    names: [
      "3-(3-Phenyl-Ureido)-Benzenesulfonamide",
      "CHEMBL370526",
      "AC1MDJRG",
      "MLS000850240",
      "SCHEMBL1395590",
      "MolPort-002-901-146",
      "HMS2801K06",
      "ZINC4325112",
      "3-(3-Phenylureido)Benzenesulfonamide",
      "BDBM50174039",
      "1-Phenyl-3-(3-Sulfamoylphenyl)Urea",
      "AKOS008966131",
      "MCULE-8491785004",
      "SMR000456258",
    ],
  },
  {
    primary_id: "D06ZNI",
    names: ["16-(2',2'-Dimethyl)-Propylidene-Estradiol"],
  },
  {
    primary_id: "D06ZNU",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 16"],
  },
  {
    primary_id: "D06ZNX",
    names: ["2,2,2-Tris-(3-Fluorophenyl)-Acetamide", "CHEMBL260736", "SCHEMBL13950949"],
  },
  {
    primary_id: "D06ZOM",
    names: [
      "3-Methyl-2-Phenyl-2H-Chromeno[4,3-C]pyrazol-4-One",
      "86100-07-4",
      "CHEMBL102071",
      "CTK2I3740",
      "DTXSID80574491",
      "AKOS030534429",
      "3-Methyl-2-Phenyl[1]benzopyrano[4,3-C]pyrazol-4(2H)-One",
      "[1]Benzopyrano[4,3-C]pyrazol-4(2H)-One, 3-Methyl-2-Phenyl-",
    ],
  },
  {
    primary_id: "D06ZPF",
    names: ["Pyridine-2,4-Dicarboxylic Acid Analog 4"],
  },
  {
    primary_id: "D06ZPI",
    names: ["N-Methylnaloxonium"],
  },
  {
    primary_id: "D06ZPS",
    names: ["Oxyphenbutazone"],
  },
  {
    primary_id: "D06ZPX",
    names: ["BTL-TML-001"],
  },
  {
    primary_id: "D06ZQK",
    names: ["Nucleozin Analog 3061 (FA-2)"],
  },
  {
    primary_id: "D06ZQL",
    names: ["NP-2 Gene Therapy"],
  },
  {
    primary_id: "D06ZRL",
    names: ["Quinolinyl Pyrazinyl Urea Derivative 1"],
  },
  {
    primary_id: "D06ZRX",
    names: ["OXYRESVERATROL"],
  },
  {
    primary_id: "D06ZSX",
    names: [
      "3-Chloro-N-(3-Isobutyramidophenyl)Benzamide",
      "CHEMBL1081896",
      "3-Chloro-N-[3-(Isobutyrylamino)Phenyl]benzamide",
      "AC1LR77A",
      "Oprea1_474979",
      "3-Chloro-N-[3-(2-Methylpropanoylamino)Phenyl]benzamide",
      "MolPort-003-001-488",
      "ZINC1207329",
      "BDBM50312135",
      "STK319961",
      "AKOS000471367",
      "MCULE-7229085520",
      "AP-970/13572856",
    ],
  },
  {
    primary_id: "D06ZTM",
    names: ["ISIS 107652"],
  },
  {
    primary_id: "D06ZTU",
    names: ["FGGFTGARKSARKLLNQ"],
  },
  {
    primary_id: "D06ZUA",
    names: ["Ki-11502"],
  },
  {
    primary_id: "D06ZUK",
    names: [
      "Imipramine",
      "Antideprin",
      "Berkomine",
      "Censtim",
      "Censtin",
      "Declomipramine",
      "Dimipressin",
      "Dpid",
      "Dynaprin",
      "Eupramin",
      "Imavate",
      "Imidobenzyle",
      "Imipramin",
      "Imipramina",
      "Imipraminum",
      "Imiprin",
      "Imizin",
      "Imizinum",
      "Impramine",
      "Intalpram",
      "Iramil",
      "Irmin",
      "Janimine",
      "Melipramin",
      "Melipramine",
      "Nelipramin",
      "Norchlorimipramine",
      "Norfranil",
      "Pramine",
      "Prazepine",
      "Promiben;Psychoforin",
      "Surplix",
      "Timolet",
      "Tofranil",
      "Imipramina [Italian]",
      "Tofraniln A",
      "Antideprin (TN)",
      "Deprimin (TN)",
      "Deprinol (TN)",
      "Depsonil (TN)",
      "Dyna-Zina",
      "Dynaprin (TN)",
      "Eupramin (TN)",
      "G-22355",
      "Imipramil (TN)",
      "Imipramina [INN-Spanish]",
      "Imipramine (INN)",
      "Imipramine [INN:BAN]",
      "Imipraminum [INN-Latin]",
      "Irmin (TN)",
      "Janimine (TN)",
      "Janimine (Hydrochloride)",
      "Melipramin (TN)",
      "SK-Pramine",
      "Surplix (TN)",
      "Tofranil(TN)",
      "Tofranil (Free Base)",
      "Tofranil (Hydrochloride)",
      "Tofranil, Base",
      "N-(Gamma-Dimethylaminopropyl)Iminodibenzyl",
      "N-(Gamma.-Dimethylaminopropyl)Iminodibenzyl",
      "N-(3-Dimethylaminopropyl)-O-Iminodibenzyl",
      "1-(3-Dimethylaminopropyl)-4,5-Dihydro-2,3,6,7-Dibenzazepine",
      "10,11-Dihydro-5-(3-(Dimethylamino)Propyl)-5H-Dibenz[b,f]azepine",
      "10,11-Dihydro-N,N-Dimethyl-5H-Dibenz[b,f]azepine-5-Propanamine",
      "2,2'-(3-Dimethylaminopropylimino)Bibenzyl",
      "2,2'-(3-Dimethylaminopropylimino)Dibenzyl",
      "3-(10,11-Dihydro-5H-Dibenzo[b,f]azepin-5-Yl)-N,N-Dimethylpropan-1-Amine",
      "3-(5,6-Dihydrobenzo[b][1]benzazepin-11-Yl)-N,N-Dimethylpropan-1-Amine",
      "5,6-Dihydro-N-(3-(Dimethylamino)Propyl)-11H-Dibenz(B,e)Azepine",
      "5,6-Dihydro-N-[3-(Dimethylamino)Propyl]-11H-Dibenz[b,e]azepine",
      "5-(3-(Dimethylamino)Propyl)-10,11-Dihydro-5H-Dibenz(B,f)Azepine",
      "5-(3-Dimethylaminopropyl)-10,11-Dihydro-5H-Dibenzo(B,f)Azepine",
      "5H-Dibenz(B,f)Azepine,5-[3-(Dimethylamino)Propyl]-10,11-Dihydro-Mixed With Ethyl Alcohol",
      "5H-Dibenz(B,f)Azepine-5-Propanamine, 10,11-Dihydro-N,N-Dimethyl-(9CI)",
    ],
  },
  {
    primary_id: "D06ZUP",
    names: [
      "Dirithromycin",
      "Dirithromycine",
      "Dirithromycinum",
      "Diritromicina",
      "Divitross",
      "Dynabac",
      "Noriclan",
      "Valodin",
      "Dirithromycine [French]",
      "Dirithromycinum [Latin]",
      "Diritromicina [Spanish]",
      "LY 237216",
      "AS-E 136",
      "ASE-136",
      "Dynabac (TN)",
      "LY-237216",
      "Dirithromycin (USP/INN)",
      "Dirithromycin [USAN:BAN:INN]",
      "(9S)-9-Deoxo-11-Deoxy-9,11-[imino[2-(2-Methoxyethoxy)Ethylidene]erythromycin",
    ],
  },
  {
    primary_id: "D06ZVB",
    names: ["Isobutyric Acid"],
  },
  {
    primary_id: "D06ZVC",
    names: ["WAY-181187"],
  },
  {
    primary_id: "D06ZVG",
    names: ["AST-120"],
  },
  {
    primary_id: "D06ZVO",
    names: ["PX-478"],
  },
  {
    primary_id: "D06ZWG",
    names: ["AVE-5997EF"],
  },
  {
    primary_id: "D06ZWL",
    names: [
      "Amdoxovir",
      "DAPD",
      "145514-04-1",
      "DAPD Cpd",
      "UNII-54I81H0M9C",
      "(-)-DAPD",
      "Beta-D-2,6-Diaminopurine-Dioxolane",
      "54I81H0M9C",
      "[(2R,4R)-4-(2,6-Diaminopurin-9-Yl)-1,3-Dioxolan-2-Yl]methanol",
      "(2R-Cis)-4-(2,6-Diamino-9H-Purin-9-Yl)-1,3-Dioxolane-2-Methanol",
      "(2R,4R)-4-(2,6-Diamino-9H-Purin-9-Yl)-1,3-Dioxolane-2-Methanol",
      "(-)-(2R,4R)-2-Amino-(2-(Hydroxymethyl)-1,3-Dioxolan-4-Yl)Adenine",
      "1,3-Dioxolane-2-Methanol, 4-(2,6-Diamino-9H-Purin-9-Yl)-, (2R,4R)-",
      "1,3-Dioxolane-2-Methanol, 4-(2,6-Diamino-9H-Purin-9-Yl)-, (2R-Cis)-",
      "Amdoxovir [USAN]",
    ],
  },
  {
    primary_id: "D06ZWQ",
    names: [
      "2-Phenyl-2H-Pyrazolo[3,4-C]quinolin-4(5H)-One",
      "2-Phenyl-2,5-Dihydro-4H-Pyrazolo[3,4-C]quinolin-4-One",
      "109740-09-2",
      "CHEMBL241987",
      "2-Phenyl-2H,4H,5H-Pyrazolo[3,4-C]quinolin-4-One",
      "AC1NLR9L",
      "AC1Q6GQQ",
      "2-Phenyl-5H-Pyrazolo[3,4-C]quinolin-4-One",
      "MLS001176701",
      "SCHEMBL728937",
      "Cid_4962866",
      "CTK7H0251",
      "DTXSID10407182",
      "ISMLHIIGSRUCOQ-UHFFFAOYSA-N",
      "MolPort-002-469-503",
      "HMS2920J03",
      "HMS1784C01",
      "ZINC3888482",
      "BDBM50218922",
      "AKOS027391050",
      "NE38059",
      "MCULE-7237346858",
      "SMR000595674",
      "EN300-14160",
      "SR-01000074278",
      "SR-01000074278-1",
      "J-002324",
    ],
  },
  {
    primary_id: "D06ZWX",
    names: ["Cycloheximide"],
  },
  {
    primary_id: "D06ZXF",
    names: ["U3-1565"],
  },
  {
    primary_id: "D06ZXH",
    names: ["PHN-131"],
  },
  {
    primary_id: "D06ZXT",
    names: ["N-6547"],
  },
  {
    primary_id: "D06ZYC",
    names: ["MLN0415"],
  },
  {
    primary_id: "D06ZYM",
    names: ["Cholestyramine"],
  },
  {
    primary_id: "D06ZYQ",
    names: ["2beta-Methoxy-2-Deethoxyphantomolin"],
  },
  {
    primary_id: "D06ZZL",
    names: ["MEDI-500"],
  },
  {
    primary_id: "D06ZZM",
    names: ["3-Chloro-4-(O-Tolyloxy)Benzonitrile", "CHEMBL490955", "SCHEMBL4168825", "BDBM50248505"],
  },
  {
    primary_id: "D07AAK",
    names: ["M6G Thiosaccharide Analogue"],
  },
  {
    primary_id: "D07AAN",
    names: ["Sodium Iodide I-123"],
  },
  {
    primary_id: "D07AAZ",
    names: [
      "4-Phenyl-1,2,3,6-Tetrahydropyridine",
      "10338-69-9",
      "4-Phenyl-1,2,3,6-Tetrahydro-Pyridine",
      "1,2,3,6-Tetrahydro-4-Phenyl-Pyridine",
      "1,2,3,6-Tetrahydro-4-Phenylpyridine",
      "Pyridine, 1,2,3,6-Tetrahydro-4-Phenyl-",
      "CHEMBL6224",
      "OMPXTQYWYRWWPH-UHFFFAOYSA-N",
      "4-Phenyl-1,2,3,6-Tetrahydro Pyridine",
      "Oprea1_874761",
      "NSC 54451",
      "AC1Q1IN6",
      "AC1L33NE",
      "AC1Q1GX2",
      "SCHEMBL415545",
      "DTXSID2065040",
      "CTK4A2038",
      "MolPort-001-794-494",
      "43064-12-6 (Hydrochloride)",
      "ZINC1295150",
      "NSC54451",
      "4-Phenyl-1,3,6-Tetrahydropyridine",
      "STK535809",
      "NSC-54451",
      "BDBM50036718",
      "BBL027886",
    ],
  },
  {
    primary_id: "D07ABI",
    names: ["(Z)-3-[2-(9H-Fluoren-2-Yloxy)Ethylidene]-Quinuclidine Hydrochloride 31"],
  },
  {
    primary_id: "D07ABJ",
    names: ["[(D)MeSer]3-[(40-OH) MeLeu]4-Cyclosporin A", "CHEMBL405301"],
  },
  {
    primary_id: "D07ABV",
    names: ["Posaconazole"],
  },
  {
    primary_id: "D07ACF",
    names: ["Actinomycin D", "AC1O8OFK", "NCIMech_000599", "CCG-36086", "CCG-35952"],
  },
  {
    primary_id: "D07ACL",
    names: ["PNT-2258"],
  },
  {
    primary_id: "D07ACP",
    names: ["CWF-0902"],
  },
  {
    primary_id: "D07ACT",
    names: [
      "Ceftriaxone",
      "Biotrakson",
      "CTRX",
      "Cefatriaxone",
      "Ceftriaxon",
      "Ceftriaxona",
      "Ceftriaxonum",
      "Ceftriazone",
      "Longacef",
      "Longaceph",
      "Rocefin",
      "Rocephin",
      "Rocephine",
      "CEFTRIAXONE SODIUM",
      "Ceftriaxone Intravenous",
      "Ro 139904",
      "Ceftriaxona [INN-Spanish]",
      "Ceftriaxone (INN)",
      "Ceftriaxone (TN)",
      "Ceftriaxone [USAN:JAN]",
      "Ceftriaxone, Disodium Salt",
      "Ceftriaxonum [INN-Latin]",
      "DRG-0071",
      "Ro13-9904",
      "Rocephin (TN)",
      "Ceftriaxone, Disodium Salt, Hemiheptahydrate",
      "Ro-13-9904",
      "(6R,7R)-7-(2-(2-Amino-4-Thiazolyl)Glyoxylamido)-8-Oxo-3-(((1,2,5,6-Tetrahydro-2-Methyl-5,6-Dioxo-As-Triazin-3-Yl)Thio)Methyl)-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 7(Sup 2)-(Z)-(O-Methyloxime), Sesquaterhydrate",
      "(6R,7R)-7-({(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(Methyloxy)Imino]acetyl}amino)-3-{[(6-Hydroxy-2-Methyl-5-Oxo-2,5-Dihydro-1,2,4-Triazin-3-Yl)Thio]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-Methoxyiminoacetyl]amino]-3-[(2-Methyl-5,6-Dioxo-1H-1,2,4-Triazin-3-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetyl]amino}-3-{[(2-Methyl-5,6-Dioxo-1,2,5,6-Tetrahydro-1,2,4-Triazin-3-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetyl]amino}-3-{[(2-Methyl-5,6-Dioxo-1,2,5,6-Tetrahydro-1,2,4-Triazin-3-Yl)Sulfanyl]methyl}-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D07ACZ",
    names: ["CLR457"],
  },
  {
    primary_id: "D07ADR",
    names: [
      "BOHEMINE",
      "Bohemine",
      "189232-42-6",
      "3-((6-(Benzylamino)-9-Isopropyl-9H-Purin-2-Yl)Amino)Propan-1-Ol",
      "3-{[6-(Benzylamino)-9-(Propan-2-Yl)-9h-Purin-2-Yl]amino}propan-1-Ol",
      "CHEMBL83980",
      "CHEBI:86007",
      "2-(3-Hydroxypropylamino)-6-Benzylamino-9-Isopropylpurine",
      "[6-Benzylamino-2-(3-Hydroxypropylamino)-9-Isopropylpurine",
      "2-[(3-Hydroxypropyl)Amino]-6-Benzylamino-9-Isopropylpurine",
      "3-{[6-(Benzylamino)-9-Isopropyl-9H-Purin-2-Yl]amino}propan-1-Ol",
      "Purine Deriv. 1",
      "AC1L1DN2",
      "SCHEMBL1443403",
      "GTPL5938",
      "BDBM10633",
      "CTK8A4395",
      "DTXSID00274365",
      "EX-A931",
    ],
  },
  {
    primary_id: "D07ADU",
    names: ["AEM-28", "Apo-E Peptide Mimetic (Acute Coronary Syndrome), University Of Alabama At Birmingham/LipimetiX"],
  },
  {
    primary_id: "D07ADX",
    names: ["URMC-099", "Compound 1 [PMID 24044867]"],
  },
  {
    primary_id: "D07AEC",
    names: ["RESCOVITINE"],
  },
  {
    primary_id: "D07AEZ",
    names: ["AMPK Stimulators, Oral, Type 2 Diabetes", "AMPK Stimulators (Oral, Type 2 Diabetes)"],
  },
  {
    primary_id: "D07AFA",
    names: [
      "1-(4-(Methylsulfonyl)Phenyl)-1H-Indole",
      "CHEMBL1240964",
      "1-[4-(Methylsulfonyl)Phenyl]-1H-Indole",
      "ZINC64475267",
    ],
  },
  {
    primary_id: "D07AFK",
    names: ["ANA-502", "Antibody Program (SHM), AnaptysBio/Merck"],
  },
  {
    primary_id: "D07AGD",
    names: ["ISIS 150450"],
  },
  {
    primary_id: "D07AGM",
    names: [
      "4-Methylamino-Benzenesulfonamide",
      "4-(Methylamino)Benzenesulfonamide",
      "4-(Methylamino)Benzene-1-Sulfonamide",
      "16891-79-5",
      "CHEMBL174847",
      "AC1Q40XN",
      "SCHEMBL3521509",
      "P-Methylaminobenzene Sulphonamide",
      "CTK4D3153",
      "DTXSID40562172",
      "FJVAYUUWTJKKNC-UHFFFAOYSA-N",
      "MolPort-004-759-881",
      "Benzenesulfonamide,4-(Methylamino)-",
      "ZINC28098746",
      "BDBM50160668",
      "Benzenesulfonamide, 4-(Methylamino)-",
      "AKOS009323835",
      "MCULE-7909074599",
      "NE62186",
      "DA-09438",
      "KB-289209",
      "FT-0749810",
      "EN300-58198",
    ],
  },
  {
    primary_id: "D07AGR",
    names: ["SB-206284A"],
  },
  {
    primary_id: "D07AGU",
    names: ["Quinoline-8-Carboxamide"],
  },
  {
    primary_id: "D07AHC",
    names: ["LITOXETINE"],
  },
  {
    primary_id: "D07AHG",
    names: ["LG190178"],
  },
  {
    primary_id: "D07AHW",
    names: ["Vitamin C"],
  },
  {
    primary_id: "D07AIB",
    names: [
      "(4-Phenoxy-Phenyl)-Quinazolin-4-Yl-Amine",
      "CHEMBL304760",
      "N-(4-Phenoxyphenyl)Quinazolin-4-Amine",
      "AC1LEH7Z",
      "Neuro1_000298",
      "Oprea1_168469",
      "SCHEMBL5568872",
      "MolPort-000-668-533",
      "ZINC104659",
      "BDBM50092231",
      "STK918072",
      "(4-Phenoxyphenyl)Quinazolin-4-Ylamine",
      "AKOS002241807",
      "N-(4-Phenoxyphenyl)-4-Quinazolinamine",
      "MCULE-2087445269",
      "ST50197248",
      "EU-0074581",
      "SR-01000459365",
    ],
  },
  {
    primary_id: "D07AIE",
    names: ["BAL-2299", "Cyclohexanediol, Basilea"],
  },
  {
    primary_id: "D07AJC",
    names: ["HIV MAG PDNA Vaccine"],
  },
  {
    primary_id: "D07AJO",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 9"],
  },
  {
    primary_id: "D07AJR",
    names: ["NS3 Helicase Protein-Targeting Agents"],
  },
  {
    primary_id: "D07AKD",
    names: ["TOL-101"],
  },
  {
    primary_id: "D07AKF",
    names: ["RNA Stealth Nucleosides"],
  },
  {
    primary_id: "D07ALG",
    names: [
      "Bromosporine",
      "1619994-69-2",
      "Bromorsporine",
      "Bioomosporine",
      "GTPL8234",
      "SCHEMBL17359131",
      "CHEMBL3133807",
      "C17H20N6O4S",
      "Bromosporine, &gt",
      "AOB6807",
      "MolPort-035-395-798",
      "HMS3653C21",
      "KS-00000T5O",
      "ZINC95616589",
      "2286AH",
      "S7233",
      "AKOS024458332",
      "CS-3240",
      "NCGC00351471-11",
      "QC-10870",
      "HY-15815",
      "AK175193",
      "A4186",
      "SW219727-1",
      "J3.656.836D",
      "N-[(6-3-Methanesulfonamido-4-Methylphenyl)-3-Methyl-[1,2,4]triazolo[4,3-B]pyridazin-8-Yl]carbamate",
      "Ethyl (3-Methyl-6-(4-Methyl-3-(Methylsulfonamido)Phenyl)-[1,2,4]triazolo[4,3-B]pyridazin-8-Yl)Carbamate",
    ],
  },
  {
    primary_id: "D07ALM",
    names: [
      "N4-(4-Chlorobenzyl)-2,4-Diaminobutanoylpiperidine",
      "DNVZYSMOLXCOLY-HNNXBMFYSA-N",
      "UAMC00039",
      "CHEMBL98869",
      "SCHEMBL6824340",
      "GTPL6538",
      "ZINC13561228",
      "BDBM50146972",
      "NCGC00379239-01",
      "1-[(S)-2-Amino-4-[(4-Chlorobenzyl)Amino]butyryl]piperidine",
      "(S)-4-(4-Chlorobenzylamino)-2-Amino-1-(Piperidin-1-Yl)Butan-1-One",
      "(2S)-2-Amino-4-[(4-Chlorophenyl)Methylamino]-1-Piperidin-1-Ylbutan-1-One",
      "2-(S)-Amino-4-(4-Chloro-Benzylamino)-1-Piperidin-1-Yl-Butan-1-One",
    ],
  },
  {
    primary_id: "D07AMO",
    names: ["ISIS 2490"],
  },
  {
    primary_id: "D07AMZ",
    names: ["Spiroimidazolone Derivative 5"],
  },
  {
    primary_id: "D07ANB",
    names: [
      "1,2-Dioctanoyl-Sn-Glycerol",
      "60514-48-9",
      "Dicaprylglyceride",
      "Sn-1,2-Dioctanoylglycerol",
      "(S)-3-Hydroxypropane-1,2-Diyl Dioctanoate",
      "1,2-Dicapryloyl-Sn-Glycerol",
      "D-Alpha,beta-Dicaprylin",
      "BRN 1714754",
      "CHEMBL55267",
      "CHEBI:76979",
      "Octanoic Acid, 1-(Hydroxymethyl)-1,2-Ethanediyl Ester, (S)-",
      "DG(8:0/8:0/0:0)",
      "Sn-1,2-Dicaprilin",
      "A-Dicaprylin",
      "AC1L3XZK",
      "1,2-Octanoyl-Sn-Diglyceride",
      "CBiol_001868",
      "KBioGR_000011",
      "BSPBio_001291",
      "KBioSS_000011",
      "4-02-00-00990 (Beilstein Handbook Reference)",
      "SCHEMBL466152",
    ],
  },
  {
    primary_id: "D07ANO",
    names: ["Synthetic Neutrophil Inhibitor Peptide"],
  },
  {
    primary_id: "D07ANY",
    names: [
      "1H-1,2,3-Benzotriazol-1-Amine",
      "1-Aminobenzotriazole",
      "1614-12-6",
      "1H-Benzotriazol-1-Amine",
      "1H-Benzo[d][1,2,3]triazol-1-Amine",
      "1-Benzotriazolamine",
      "Benzotriazol-1-Amine",
      "1-Abtz",
      "1H-1,2,3-Benzotriazol-1-Ylamine",
      "UNII-9EFF75BJ1O",
      "NSC 114498",
      "9EFF75BJ1O",
      "CHEMBL101168",
      "Benzotriazolylamine",
      "MFCD00132902",
      "NSC656987",
      "1-Aminobenzotriazole, 98%",
      "AK-830/25033013",
      "Q-102063",
      "1-Benzotriazolylamine",
      "Benzotriazol-1-Ylamine",
      "PubChem19954",
      "Rarechem Aq Nn 0550",
      "ACMC-1BVFA",
      "AC1L1BBK",
      "Timtec-Bb Sbb004208",
      "Lopac-A-3940",
      "ABT",
    ],
  },
  {
    primary_id: "D07AOL",
    names: ["PEITC"],
  },
  {
    primary_id: "D07AOO",
    names: ["Rilotumumab"],
  },
  {
    primary_id: "D07AOY",
    names: ["Avarofloxacin"],
  },
  {
    primary_id: "D07APP",
    names: [
      "LY-292223",
      "2-(4-Morpholinyl)-4H-1-Benzopyran-4-One",
      "2-Morpholinochromone",
      "CHEMBL367315",
      "2-Morpholino-4H-Chromen-4-One",
      "130735-56-7",
      "U67154",
      "AC1L2YUR",
      "U-67154",
      "SCHEMBL3361567",
      "2-Morpholin-4-Ylchromen-4-One",
      "DTXSID30156701",
      "2-Morpholin-4-Yl-Chromen-4-One",
      "QNFWERYZJLBANZ-UHFFFAOYSA-N",
      "BDBM50098118",
      "2-(4-Morpholinyl)-4H-Benzopyran-4-One",
      "4H-1-Benzopyran-4-One, 2-(4-Morpholinyl)-",
    ],
  },
  {
    primary_id: "D07APS",
    names: ["TRC093"],
  },
  {
    primary_id: "D07APX",
    names: ["Neuromed 5"],
  },
  {
    primary_id: "D07AQC",
    names: ["Human Insulin Zn Suspension"],
  },
  {
    primary_id: "D07AQF",
    names: ["Foretinib"],
  },
  {
    primary_id: "D07ARK",
    names: ["5-Phenyl-Pentanoic Acid Benzyl-Hydroxy-Amide"],
  },
  {
    primary_id: "D07ARN",
    names: ["SAR-125844"],
  },
  {
    primary_id: "D07ARO",
    names: ["WIN-66306", "151928-32-4"],
  },
  {
    primary_id: "D07ATG",
    names: ["GSK2190914"],
  },
  {
    primary_id: "D07ATW",
    names: ["Hyacinthacine B3"],
  },
  {
    primary_id: "D07AUG",
    names: ["H-D-Trp-C[Cys-Tyr-D-Trp-Arg-Thr-Pen]-Thr-NH2", "CHEMBL384571"],
  },
  {
    primary_id: "D07AUI",
    names: ["Influenza A Virus Vaccine H5N1"],
  },
  {
    primary_id: "D07AUN",
    names: ["Methyl 4-(4-Hydroxybenzylideneamino)Benzoate"],
  },
  {
    primary_id: "D07AUS",
    names: ["KRL-901"],
  },
  {
    primary_id: "D07AUV",
    names: ["ISIS 9023"],
  },
  {
    primary_id: "D07AVU",
    names: ["PMID28621580-Compound-WO2015089220C70"],
  },
  {
    primary_id: "D07AVV",
    names: ["Ac-YVAD-Cmk"],
  },
  {
    primary_id: "D07AWM",
    names: ["Segard", "Afelimomab"],
  },
  {
    primary_id: "D07AXA",
    names: ["Fatty Acid Amide Hydrolase Inhibitors"],
  },
  {
    primary_id: "D07AXK",
    names: ["Peptide Analog 71"],
  },
  {
    primary_id: "D07AXL",
    names: ["Bis(5-Aminobenzo[b]furan-2-Yl)Methanone", "CHEMBL376787"],
  },
  {
    primary_id: "D07AXO",
    names: ["8-Methoxy-4-Morpholin-4-Yl-Chromen-2-One", "CHEMBL176325"],
  },
  {
    primary_id: "D07AYF",
    names: ["Methyl 10H-Phenothiazine-10-Carboxylate"],
  },
  {
    primary_id: "D07AYI",
    names: ["CWP232291"],
  },
  {
    primary_id: "D07AYN",
    names: ["Chikungunya Virus Vaccine"],
  },
  {
    primary_id: "D07AZB",
    names: ["ISIS-PTP1B"],
  },
  {
    primary_id: "D07BAE",
    names: ["SUN-0597"],
  },
  {
    primary_id: "D07BAH",
    names: ["R(-)Amphetamine"],
  },
  {
    primary_id: "D07BAJ",
    names: ["Clade D HIV Vaccine"],
  },
  {
    primary_id: "D07BAO",
    names: [
      "(R)-3-Prop-2-Ynylamino-Indan-5-Ol",
      "5-Hydroxy-N-Propargyl-1(R)-Aminoindan",
      "5-HYDROXY-N-PROPARGYL-1(R)-AMINOINDAN",
      "CHEMBL371428",
      "6-Hydroxy-N-Propargyl-1(R)-Aminoindan",
      "(3R)-3-(PROP-2-YNYLAMINO)INDAN-5-OL",
      "(3R)-3-(Prop-2-Yn-1-Ylamino)-2,3-Dihydro-1H-Inden-5-Ol",
      "R-HPAI",
      "AC1NRD0T",
      "Aminoindan Deriv (R)39u",
      "SCHEMBL4188273",
      "BDBM10799",
      "1H-Inden-5-Ol, 2,3-Dihydro-3-(2-Propyn-1-Ylamino)-, (3R)-",
      "DB04307",
      "479206-03-6",
    ],
  },
  {
    primary_id: "D07BBD",
    names: ["Zona Pellucida Contraceptive Vaccine"],
  },
  {
    primary_id: "D07BBR",
    names: [
      "9-Fluoro-6H,11H-Indeno[1,2-C]isoquinolin-5-One",
      "CHEMBL190895",
      "BDBM27513",
      "Indeno[1,2-C]isoquinolinone, 1e",
      "9-Fluoro-5,6-Dihydro-11H-Indeno[1,2-C]isoquinoline-5-One",
    ],
  },
  {
    primary_id: "D07BCH",
    names: ["[Cys-Trp-Arg-Nva-Arg-Tyr-NH2]2"],
  },
  {
    primary_id: "D07BCO",
    names: ["WAY-100635"],
  },
  {
    primary_id: "D07BCS",
    names: ["ISIS 2686"],
  },
  {
    primary_id: "D07BCT",
    names: [
      "Tobramycin",
      "Aktob",
      "Brulamycin",
      "Distobram",
      "Gotabiotic",
      "NEBRAMYCIN",
      "Nebcin",
      "Nebicin",
      "Obracin",
      "Obramycin",
      "Sybryx",
      "TOY",
      "Tenebrimycin;Tenemycin",
      "Tobacin",
      "Tobi",
      "Tobracin",
      "Tobradex",
      "Tobradistin",
      "Tobralex",
      "Tobramaxin",
      "Tobramicin",
      "Tobramicina",
      "Tobramitsetin",
      "Tobramycetin",
      "Tobramycine",
      "Tobramycinum",
      "Tobrased",
      "Tobrasone",
      "Tobrex",
      "Deoxykanamycin B",
      "Nebramycin VI",
      "TOBRAMYCIN SULFATE",
      "Tobramycin For Inhalation",
      "Tobramycin Solution For Inhalation",
      "A 12253A",
      "Lilly 47663",
      "NF 6",
      "Nebramycin 6",
      "Nebramycin Factir 6",
      "Nebramycin Factor 6",
      "Nebcin (Sulfate)",
      "SPRC-AB01",
      "TobraDex (TN)",
      "Tobracin (TN)",
      "Tobramicina [INN-Spanish]",
      "Tobramycin, Free Base",
      "Tobramycine [INN-French]",
      "Tobramycinum [INN-Latin]",
      "Tobrex (TN)",
      "Tobramycin (JP15/USP)",
      "Tobramycin[USAN:BAN:INN:JAN]",
      "TOA-(1-6)2TB-(4-1)TOC",
      "TOA-(1-6)TOB-(4-1)TOC",
      "1-Epitobramycin",
      "3'-Deoxykanamycin B",
    ],
  },
  {
    primary_id: "D07BCU",
    names: ["Eflornithine + Sulindac", "DFMO/Sulindac", "CPP-1X/Sul", "CPP-1X/Sulindac"],
  },
  {
    primary_id: "D07BDD",
    names: ["BAM-002"],
  },
  {
    primary_id: "D07BDQ",
    names: ["PD-151307"],
  },
  {
    primary_id: "D07BDW",
    names: ["OC-108"],
  },
  {
    primary_id: "D07BDY",
    names: ["Tisocalcitate"],
  },
  {
    primary_id: "D07BEE",
    names: [
      "AnergiX.RA",
      "AG-4263",
      "RA-AnergiX",
      "Antigen Specific Therapy (Rheumatoid Arthritis, AnergiX), Corixa/ Organon",
    ],
  },
  {
    primary_id: "D07BFA",
    names: [
      "2-(2-(4-Tert-Butylphenoxy)Acetamido)Benzoic Acid",
      "CHEMBL443733",
      "Oprea1_160526",
      "SCHEMBL3987191",
      "2-[[2-(4-Tert-Butylphenoxy)Acetyl]amino]benzoic Acid",
      "MolPort-001-494-161",
      "ZINC4546761",
      "AC1N7082",
      "STK000456",
      "BDBM50264188",
      "AKOS002944005",
      "MCULE-2716737007",
      "2-{[(4-Tert-Butylphenoxy)Acetyl]amino}benzoic Acid",
    ],
  },
  {
    primary_id: "D07BFF",
    names: ["8-Methyl-2-Morpholino-7-Phenyl-4H-Chromen-4-One", "CHEMBL95782"],
  },
  {
    primary_id: "D07BFP",
    names: ["PMID27215781-Compound-37"],
  },
  {
    primary_id: "D07BGB",
    names: ["Propan-2-One O-4-Propoxyphenylcarbamoyl Oxime", "CHEMBL609315"],
  },
  {
    primary_id: "D07BGC",
    names: ["GDC-0084"],
  },
  {
    primary_id: "D07BGQ",
    names: ["(+/-)-5'-Deoxy-4'-Fluoro-5'-Methylthio-DADMe-ImmH"],
  },
  {
    primary_id: "D07BHS",
    names: ["(3-Fluoro-4-(4-Phenoxybenzoyl)Phenyl)Acetic Acid", "CHEMBL382214"],
  },
  {
    primary_id: "D07BIF",
    names: [
      "[3H]CGP27492",
      "3-Aminopropylphosphinic Acid",
      "3-Aminopropanephosphinic Acid",
      "CGP-27492",
      "Cgp 27492",
      "3-Aminopropylphosphonous Acid",
      "Phosphinic Acid, (3-Aminopropyl)",
      "3-Aminopropyl-Hydroxy-Oxophosphanium",
      "CHEMBL112203",
      "[3H]3-APPA",
      "[3H]-3-APPA",
      "Cgp 27 492",
      "[3H]-CGP27492",
      "AC1O3S7O",
      "SCHEMBL341549",
      "GTPL5381",
      "GTPL1081",
      "BDBM24184",
      "CTK8G4752",
      "(3-Amino-Propyl)-Phosphinic Acid",
      "ZTHNRNOOZGJLRR-UHFFFAOYSA-N",
      "MolPort-019-939-257",
      "CGP27492",
      "3-Aminopropyl-Hydroxy-Oxo-Phosphonium",
      "BN0804",
      "PDSP2_001468",
      "PDSP1_001484",
      "FCH933647",
      "3-Aminopropylphosphinic",
    ],
  },
  {
    primary_id: "D07BIK",
    names: ["VEL-0230"],
  },
  {
    primary_id: "D07BIQ",
    names: ["PMID25726713-Compound-17"],
  },
  {
    primary_id: "D07BJC",
    names: ["13-N-Heptanamidotridec-5-Ynoic Acid", "CHEMBL550898"],
  },
  {
    primary_id: "D07BJZ",
    names: ["Bbs-Arg-(D-Pip)-Gly-S-(GS)11-Gly-Hir", "CHEMBL438400"],
  },
  {
    primary_id: "D07BKE",
    names: [
      "8-(3,4,5-Trimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL431257",
      "AC1LAR8R",
      "CTK6J4531",
      "BDBM50059933",
      "2,6-Diamino-8-(3',4',5'-Trimethoxyphenyl)Purine",
      "8-(3,4,5-Trimethoxyphenyl)-3h-Purine-2,6-Diamine",
      "8-(3,4,5-Trimethoxyphenyl)-7H-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D07BKH",
    names: ["(Hydroxymethylphenyl)Agomelatine", "Compound 21 [PMID: 18778943]"],
  },
  {
    primary_id: "D07BKL",
    names: ["KW-2170"],
  },
  {
    primary_id: "D07BKR",
    names: [
      "ARQ 621",
      "1095253-39-6",
      "ARQ-621",
      "UNII-UU55190C8S",
      "ARQ621",
      "UU55190C8S",
      "(R)-N-(3-AMINOPROPYL)-3-CHLORO-N-(1-(7-CHLORO-4-OXO-3-(PHENYLAMINO)-3,4-DIHYDROQUINAZOLIN-2-YL)BUT-3-YN-1-YL)-2-FLUOROBENZAMIDE",
      "Benzamide, N-(3-Aminopropyl)-3-Chloro-N-[(1R)-1-[7-Chloro-3,4-Dihydro-4-Oxo-3-(Phenylamino)-2-Quinazolinyl]-3-Butyn-1-Yl]-2-Fluoro-",
      "SCHEMBL361201",
      "C28H24Cl2FN5O2",
      "DTXSID80148974",
      "EX-A857",
      "UPJSUQWHUVLLNW-XMMPIXPASA-N",
      "MolPort-035-789-699",
      "BCP28395",
      "2122AH",
      "ZINC68203755",
      "S7355",
      "AKOS032945139",
      "SB19448",
      "NCGC00386362-01",
      "KB-74734",
    ],
  },
  {
    primary_id: "D07BKU",
    names: [
      "A-795614",
      "CHEMBL520995",
      "SCHEMBL1862020",
      "BDBM50264581",
      "(R)-1-(1H-Indazol-4-Yl)-3-(5-(Piperidin-1-Yl)-2,3-Dihydro-1H-Inden-1-Yl)Urea",
    ],
  },
  {
    primary_id: "D07BLC",
    names: [
      "AMT-090",
      "AAV Gene Therapy (GDNF, Parkinsons Disease), Amsterdam Molecular Therapeutics",
      "Glial-Derived Neurotrophic Factor Gene Therapy (Adeno-Associated Vector, Parkinsons Disease), AMT",
    ],
  },
  {
    primary_id: "D07BLJ",
    names: ["[1,5]Thiazocan-(4E)-Ylideneamine", "CHEMBL92422", "4-Iminohexahydro-2H-1,5-Thiazocine", "BDBM50104658"],
  },
  {
    primary_id: "D07BMA",
    names: ["Bbs-Arg-(D-Pip)-Gly-S-(GS)5-Gly-Hir", "CHEMBL265596"],
  },
  {
    primary_id: "D07BMT",
    names: ["N-(2-Amino-5-(Pyridin-4-Yl)Phenyl)Benzamide", "CHEMBL1095096"],
  },
  {
    primary_id: "D07BMX",
    names: ["(S)-2-Amino-3-(Benzylthio)Propanoic Acid"],
  },
  {
    primary_id: "D07BND",
    names: ["Imidazopyridazine Derivative 1"],
  },
  {
    primary_id: "D07BNG",
    names: ["RHIgM12B7"],
  },
  {
    primary_id: "D07BNL",
    names: ["NV.AT.08"],
  },
  {
    primary_id: "D07BNM",
    names: [
      "9-(5,5-Difluoro-5-Phosphonopentyl)Guanine",
      "9-(5,5-DIFLUORO-5-PHOSPHONOPENTYL)GUANINE",
      "CHEMBL236708",
      "HA1",
      "5-(2-Amino-6-Oxo-1,6-Dihydro-9H-Purin-9-Yl)-1,1-Difluoropentylphosphonate",
      "AC1L9MLH",
      "SCHEMBL2230046",
      "1v48",
      "BDBM50214705",
      "DB04260",
      "9-(5'',5''-Difluoro-5''-Phosphonopentyl)Guanine",
      "5-(2-Amino-6-Oxo-1H-Purin-9-Yl)-1,1-Difluoropentylphosphonic Acid",
      "[5-(2-Amino-6-Oxo-3H-Purin-9-Yl)-1,1-Difluoropentyl]phosphonic Acid",
      "[5-(2-Amino-6-Oxo-1,6-Dihydro-Purin-9-Yl)-1,1-Difluoro-Pentyl]-Phosphonic Acid",
      "9-(5',5'-Difluoro-5'-Phosphonopentyl)Guanine",
    ],
  },
  {
    primary_id: "D07BNP",
    names: ["BRL-36378"],
  },
  {
    primary_id: "D07BOF",
    names: ["PMID30247903-Compound-General Structure5"],
  },
  {
    primary_id: "D07BPA",
    names: ["1-[3-(4-Biphenyl)-(1,2,4-Triazol-4-Yl) ]-3-Phenol", "CHEMBL208801"],
  },
  {
    primary_id: "D07BPO",
    names: ["Ingavirin"],
  },
  {
    primary_id: "D07BPS",
    names: ["Suprofen"],
  },
  {
    primary_id: "D07BPT",
    names: ["8-Methyl-2-(4-Nitro-Phenyl)-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D07BQE",
    names: ["Alteplase", "Activase (TN)"],
  },
  {
    primary_id: "D07BQG",
    names: ["{4-[4-(4-Bromophenoxy)Benzoyl]phenyl}acetic Acid", "CHEMBL201138"],
  },
  {
    primary_id: "D07BQN",
    names: ["N-(3,3-Diphenyl-Propyl)-2-Pyridine-3-Ylacetamide", "CHEMBL563527", "SCHEMBL4619635"],
  },
  {
    primary_id: "D07BQP",
    names: ["Oncolytic Virus"],
  },
  {
    primary_id: "D07BRZ",
    names: ["LY-517717"],
  },
  {
    primary_id: "D07BSE",
    names: ["Hyaluronate Sodium"],
  },
  {
    primary_id: "D07BSQ",
    names: ["Progesterone"],
  },
  {
    primary_id: "D07BTU",
    names: [
      "6-Benzyloxy-5-Nitro-Pyrimidine-2,4-Diamine",
      "CHEMBL121479",
      "127116-64-7",
      "2,4-Pyrimidinediamine, 5-Nitro-6-(Phenylmethoxy)-",
      "ACMC-20msby",
      "SCHEMBL3309544",
      "CTK0F6457",
      "DTXSID40431990",
      "VLVHHGNPHBVEIG-UHFFFAOYSA-N",
      "BDBM50062813",
      "AKOS030620964",
      "6-Benzyloxy-5-Nitropyrimidine-2,4-Diamine",
      "2,4-Diamino-6-Benzyloxy-5-Nitropyrimidine",
    ],
  },
  {
    primary_id: "D07BUH",
    names: ["F-627"],
  },
  {
    primary_id: "D07BUO",
    names: ["AZD-3783", "Anxiolytic/Anti-Depressant, AstraZeneca"],
  },
  {
    primary_id: "D07BVI",
    names: ["Ipilimumab", "BMS-734016", "MDX-010", "MDX-101", "Yervoy (TN)"],
  },
  {
    primary_id: "D07BWC",
    names: ["PMID28766366-Compound-Scheme21Right"],
  },
  {
    primary_id: "D07BWU",
    names: ["PMX-10072"],
  },
  {
    primary_id: "D07BXC",
    names: ["IZONSTERIDE"],
  },
  {
    primary_id: "D07BXD",
    names: ["HI-281"],
  },
  {
    primary_id: "D07BXE",
    names: ["Daniquidone", "Batracylin"],
  },
  {
    primary_id: "D07BXN",
    names: ["Tyr-Pro-Dmt-Phe-NH2", "CHEMBL228512"],
  },
  {
    primary_id: "D07BXQ",
    names: ["VR632"],
  },
  {
    primary_id: "D07BXV",
    names: ["PUUPEHENONE"],
  },
  {
    primary_id: "D07BYG",
    names: [
      "[3H]LTB4",
      "Delta(6)-Trans,Delta(8)-Cis-Leukotriene B4",
      "(6E,8Z)-LTB4",
      "(5S,6E,8Z,10E,12R,14Z)-5,12-Dihydroxyicosa-6,8,10,14-Tetraenoic Acid",
      "Delta(6)-Trans,Delta(8)-Cis-LTB4",
      "5(S),12(R)-Dihydroxy-6(E),8(Z),10(E),14(Z)-Icosatetraenoic Acid",
      "(5S,12R,6E,8Z,10E,14Z)-5,12-Dihydroxy-6,8,10,14-Eicosatetraenoic Acid",
      "5(S),12(R)-Dihydroxy-6(E),8(Z),10(E),14(Z)-Eicosatetraenoic Acid",
      "(5S,6E,8Z,10E,12R,14Z)-5,12-Dihydroxyeicosa-6,8,10,14-Tetraenoic Acid",
      "SCHEMBL247274",
      "GTPL3413",
      "CHEBI:53027",
      "ZINC27642911",
    ],
  },
  {
    primary_id: "D07BYK",
    names: ["Penciclovir"],
  },
  {
    primary_id: "D07BZQ",
    names: [
      "C-Met Kinase Inhibitors",
      "C-Met Kinase Inhibitors (Cancer)",
      "C-Met Kinase Inhibitors (Cancer), UCB Celltech",
    ],
  },
  {
    primary_id: "D07BZW",
    names: ["PMID25666693-Compound-85"],
  },
  {
    primary_id: "D07CAB",
    names: ["NNC 11-1585"],
  },
  {
    primary_id: "D07CAQ",
    names: ["SAFINGOL"],
  },
  {
    primary_id: "D07CAW",
    names: ["DEOXYGALACTONOJIRIMYCIN", "CHEMBL534474", "Piperidine-2,3,4,5-Tetraol With HCl"],
  },
  {
    primary_id: "D07CBA",
    names: ["Sulfamic Acid 16-Sulfamoyloxy-Hexadecyl Ester", "CHEMBL181753"],
  },
  {
    primary_id: "D07CBH",
    names: ["SKL13865"],
  },
  {
    primary_id: "D07CBY",
    names: [
      "Cyclin A2",
      "Cardiac Regenerative Therapy, VentriNova",
      "Cyclin A2 (Myocardial Infarction)",
      "Cyclin A2 (Myocardial Infarction), VentriNova",
    ],
  },
  {
    primary_id: "D07CCC",
    names: ["ISIS 29159"],
  },
  {
    primary_id: "D07CCJ",
    names: ["Naloxone Intranasal"],
  },
  {
    primary_id: "D07CCV",
    names: ["MK-0431C"],
  },
  {
    primary_id: "D07CDS",
    names: ["Metal Complex Derivative 3"],
  },
  {
    primary_id: "D07CEB",
    names: ["Isoquinolin-1-Yl-(3-Phenyl-Propyl)-Amine", "CHEMBL289792", "BDBM50282107"],
  },
  {
    primary_id: "D07CEI",
    names: ["Zinc Sulfate"],
  },
  {
    primary_id: "D07CER",
    names: ["1-Biphenyl-4-Ylmethylmaleimide", "CHEMBL576859"],
  },
  {
    primary_id: "D07CEU",
    names: ["SOPHORICOSIDE"],
  },
  {
    primary_id: "D07CFJ",
    names: [
      "1-Propionyl-1H-Indole-2,3-Dione",
      "17529-69-0",
      "1-Propanoyl-2,3-Dihydro-1H-Indole-2,3-Dione",
      "Propionylisatin",
      "Isatin-Based Compound, 9",
      "AC1LBV75",
      "1-Propanoylindole-2,3-Dione",
      "CHEMBL386000",
      "BDBM22789",
      "MolPort-002-488-122",
      "IHNYAHBLLCMRCD-UHFFFAOYSA-N",
      "ZINC3086180",
      "ZX-AN080727",
      "ALBB-029917",
      "AKOS001849000",
      "1-Propionyl-1H-Indole-2,3-Dione #",
      "MCULE-1099895518",
      "CCG-139392",
      "1H-Indole-2,3-Dione, 1-(1-Oxopropyl)-",
      "EU-0084456",
      "1H-Isoindole-2,3(2H)-Dione, 1-Propionyl-",
      "SR-01000512691",
    ],
  },
  {
    primary_id: "D07CFT",
    names: ["6,7-Dimethoxy-3-(3-Methoxy-Phenyl)-Quinoline", "CHEMBL308466", "ZINC13737704"],
  },
  {
    primary_id: "D07CFW",
    names: [
      "Anti-CMET Mab",
      "Anti-CMET MAb (Undisclosed Indication)",
      "Anti-CMET MAb (Undisclosed Indication), PharmAbcine",
      "CMET Inhibitor (MAb, Undisclosed Indication), PharmAbcine",
    ],
  },
  {
    primary_id: "D07CGH",
    names: ["1D09C3"],
  },
  {
    primary_id: "D07CGI",
    names: ["M-M-R II"],
  },
  {
    primary_id: "D07CIB",
    names: ["CI-1013", "DIBA-4", "NCS-672594", "PD-159206", "PD-162401", "PD-178395"],
  },
  {
    primary_id: "D07CIN",
    names: [
      "(-)-Phenserine",
      "CHEMBL54727",
      "SCHEMBL5464366",
      "BDBM10622",
      "(3aS)-1,3a,8-Trimethyl-1H,2H,3H,3aH,8H,8aH-Pyrrolo[2,3-B]indol-5-Yl N-Phenylcarbamate",
    ],
  },
  {
    primary_id: "D07CIU",
    names: ["1-Benzhydryl-4-P-Tolylpiperidin-4-Ol", "CHEMBL393985"],
  },
  {
    primary_id: "D07CIW",
    names: ["[3H]ETB-TBOA", "[3H]-ETB-TBOA"],
  },
  {
    primary_id: "D07CIX",
    names: ["FM-101"],
  },
  {
    primary_id: "D07CJN",
    names: ["ST-101"],
  },
  {
    primary_id: "D07CJR",
    names: [
      "5S-HETE",
      "5-Hydroxyeicosatetraenoic Acid",
      "5(S)-HETE",
      "5-Hete",
      "5-Hydroxyeicosatetraenoate",
      "5-Hydroxy-6,8,11,14-Eicosatetraenoic Acid",
      "UNII-467RNW8T91",
      "70608-72-9",
      "CHEMBL164813",
      "CHEBI:28209",
      "5(S)-Hydroxyeicosatetraenoic Acid",
      "467RNW8T91",
      "6,8,11,14-Eicosatetraenoic Acid, 5-Hydroxy-, (S-(E,Z,Z,Z))-",
      "(5S,6E,8Z,11Z,14Z)-5-Hydroxyicosa-6,8,11,14-Tetraenoic Acid",
      "5S-Hydroxy-6E,8Z,11Z,14Z-Eicosatetraenoic Acid",
      "(6E,8Z,11Z,14Z)-(5S)-5-Hydroxyicosa-6,8,11,14-Tetraenoic Acid",
      "5-(S)-Hydroxyeicosatetraenoic Acid",
      "AC1NQXIH",
      "BSPBio_001443",
    ],
  },
  {
    primary_id: "D07CLO",
    names: ["EGT-0001442"],
  },
  {
    primary_id: "D07CLV",
    names: ["GDC-0152"],
  },
  {
    primary_id: "D07CLY",
    names: ["Heterocycle-Containing Compound 3"],
  },
  {
    primary_id: "D07CMM",
    names: [
      "Interferon Alfa-N3",
      "Alferon LDO",
      "Interferon Alfa-N3 (Oral)",
      "Interferon Alfa-N3 (Oral), Hemispherx",
      "Interferon Alfa-N3 (Oral), InterferonSciences",
    ],
  },
  {
    primary_id: "D07CMN",
    names: [
      "PD-200390",
      "AC1L4NQF",
      "SCHEMBL9494809",
      "YPPRSIHNTQEZCJ-UHFFFAOYSA-N",
      "3-(3,5-Ditert-Butyl-4-Hydroxyphenyl)Sulfanyl-N-Methyl-N-(2-Pyridin-2-Ylethyl)Propanamide",
      "3-[[3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl]thio]-N-[2-(2-Pyridyl)Ethyl]-N-Methylpropanamide",
      "3-[[3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl]thio]-N-Methyl-N-[2-(2-Pyridinyl)Ethyl]propanamide",
    ],
  },
  {
    primary_id: "D07CMZ",
    names: ["PMID26004420-Compound-WO2014099633E"],
  },
  {
    primary_id: "D07CNF",
    names: ["G-CSF"],
  },
  {
    primary_id: "D07CNL",
    names: [
      "Anisotropine Methylbromide",
      "Anisotropine",
      "Endovalpin",
      "Lytispasm",
      "Valpin",
      "Anisotropine Methobromide",
      "Anisotropine Methyl Bromide;Methyloctatropine Bromide",
      "Metilbromuro De Octatropina",
      "Octatropine Methylbromide",
      "Octatropini Methylbromidum",
      "Octatropone Bromide",
      "Valpin 50",
      "Anisotropine Methylbromide (USAN)",
      "Anisotropine Methylbromide [USAN:JAN]",
      "Endovalpin (TN)",
      "Lytispasm (TN)",
      "Methylbromure D'octatropine",
      "Methyloctatropine Bromide (JAN)",
      "Metilbromuro De Octatropina [INN-Spanish]",
      "Octatropine Methylbromide (INN)",
      "Octatropinimethylbromidum [INN-Latin]",
      "Valpin (TN)",
      "Valpin 50 (TN)",
      "Methylbromure D'octatropine [INN-French]",
      "Anisotropine Methylbromide, (Endo)-Isomer",
      "[(1R,5R)-8,8-Dimethyl-8-Azoniabicyclo[3.2.1]octan-3-Yl] 2-Propylpentanoate Bromide",
      "[(1R,5S)-8,8-Dimethyl-8-Azoniabicyclo[3.2.1]octan-3-Yl] 2-Propylpentanoate Bromide",
      "Endo-8,8-Dimethyl-3-((1-Oxo-2-Propylpentyl)Oxy)-8-Azoniabicyclo(3.2.1)Octane Bromide",
      "(1r,5r)-8,8-Dimethyl-3-[(2-Propylpentanoyl)Oxy]-8-Azoniabicyclo[3.2.1]octane Bromide",
      "(3-Endo)-8,8-Dimethyl-3-[(2-Propylpentanoyl)Oxy]-8-Azoniabicyclo[3.2.1]octane Bromide",
      "1-Alpha-H,5-Alpha-H-Tropanium,3-Alpha-Hydroxy-8-Methyl-, Bromide, 2-Propylvalerate",
      "1alphaH,5alphaH-Tropanium, 3alpha-Hydroxy-8-Methyl-, Bromide, 2-Propylvalerate",
      "2-Propylpentanoyltropinium Methylbromide",
      "3-Alpha-Hydroxy-8-Methyl-1-Alpha-H,5-Alpha-H-Tropanium Bromide 2-Propylvalerate",
      "3alpha-Hydroxy-8-Methyl-1alphaH,5alphaH-Tropanium Bromide 2-Propylvalerate",
      "8,8-Dimethyl-3-[(2-Propylpentanoyl)Oxy]-8-Azoniabicyclo[3.2.1]octane Bromide",
      "8-Methyl-3-(2-Propylpentanoyloxy)Tropinium Bromide",
      "8-Methyltropinium Bromide 2-Propylpentanoate",
      "8-Methyltropinium Bromide 2-Propylvalerate",
    ],
  },
  {
    primary_id: "D07CNY",
    names: ["Instiladrin"],
  },
  {
    primary_id: "D07COG",
    names: [
      "[3H]MDL105519",
      "AC1L1H8N",
      "GTPL4088",
      "CTK6G6780",
      "CTK0I1827",
      "HMS3370K17",
      "(E)-3-(2-Carboxy-2-Phenylvinyl)-4,6-Dichloro-1H-Indole-2-Carboxylic Acid",
      "3-(2-Carboxy-2-Phenylethenyl)-4,6-Dichloro-1H-Indole-2-Carboxylic Acid",
      "(Z)-3-(2-Carboxy-2-Phenylvinyl)-4,6-Dichloro-1H-Indole-2-Carboxylic Acid",
      "1H-Indole-2-Carboxylicacid, 3-[(1E)-2-Carboxy-2-Phenylethenyl]-4,6-Dichloro-",
    ],
  },
  {
    primary_id: "D07COV",
    names: ["XDR-TB RNA-Based Therapeutics"],
  },
  {
    primary_id: "D07COX",
    names: ["T-Kinin", "[Ile,Ser]bradykinin", "Ile-Ser-BK"],
  },
  {
    primary_id: "D07CPL",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 6"],
  },
  {
    primary_id: "D07CPM",
    names: ["GP2013"],
  },
  {
    primary_id: "D07CPU",
    names: [
      "Rosoxacin",
      "Acrosoxacin",
      "Eracine",
      "Eradacil",
      "Eradacin",
      "Rosoxacine",
      "Rosoxacino",
      "Rosoxacinum",
      "Roxadyl",
      "Winuron",
      "PD 107522",
      "WIN 35213;Eradacil (TN)",
      "Rosoxacin [USAN:INN]",
      "Rosoxacine [INN-French]",
      "Rosoxacino [INN-Spanish]",
      "Rosoxacinum [INN-Latin]",
      "Roxadyl (TN)",
      "Win 35,213",
      "Win-35123",
      "Rosoxacin (USAN/INN)",
      "1-Ethyl-1,4-Dihydro-4-Oxo-7-(4-Pyridinyl)-3-Quinolinecarboxylic Acid",
      "1-Ethyl-1,4-Dihydro-4-Oxo-7-(4-Pyridyl)-3-Quinolinecarboxylic Acid",
      "1-Ethyl-4-Oxo-7-(Pyridin-4-Yl)-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "1-Ethyl-4-Oxo-7-Pyridin-4-Ylquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D07CQD",
    names: [
      "1-(1-(Benzo[b]thiophen-2-Yl)Cyclohexyl)Piperidine",
      "BTCP",
      "112726-66-6",
      "1-Btcp",
      "GK 13",
      "1-[1-(1-Benzothiophen-2-Yl)Cyclohexyl]piperidine",
      "1-(1-Benzo(B)Thien-2-Ylcyclohexyl)Piperidine",
      "N-(1-(2-Benzo(B)Thiophenyl)Cyclohexyl)Piperidine",
      "GK-13",
      "UNII-Q1WR6UP7MW",
      "Q1WR6UP7MW",
      "CHEMBL279556",
      "CHEBI:64145",
      "1-(1-(2-Benzo(B)Thienyl)Cyclohexyl)Piperidine",
      "Piperidine, 1-(1-Benzo(B)Thien-2-Ylcyclohexyl)-",
      "1-(1-Benzo[b]thien-2-Ylcyclohexyl)Piperidine",
      "Benocyclidine",
      "C19H25NS",
      "Tocris-0702",
      "Lopac-B-138",
      "Biomol-NT_000007",
      "AC1L3X4G",
      "Lopac0_000226",
    ],
  },
  {
    primary_id: "D07CQI",
    names: [
      "2-Sulfamoyl-Benzoic Acid Methyl Ester",
      "57683-71-3",
      "Methyl 2-(Aminosulfonyl)Benzoate",
      "Methyl 2-Sulfamoylbenzoate",
      "2-Carbomethoxybenzenesulfonamide",
      "(2-Methoxycarbonyl)Benzene Sulfonamide",
      "2-Sulfamoylbenzoic Acid Methyl Ester",
      "Methyl O-Sulphamoylbenzoate",
      "Benzoic Acid, 2-(Aminosulfonyl)-, Methyl Ester",
      "EINECS 260-903-2",
      "BENZOIC ACID, 2-SULFAMOYL-, METHYL ESTER",
      "BRN 2728611",
      "2-Aminosulfonyl-Benzoic Acid Methyl Ester",
      "CHEMBL148680",
      "CHEBI:83512",
      "2-Methoxycarbonylphenylsulfonamide",
      "VSOOBQALJVLTBH-UHFFFAOYSA-N",
      "MFCD00009808",
      "2-(Methoxycarbonyl)Benzenesulphonamide",
    ],
  },
  {
    primary_id: "D07CQJ",
    names: ["F-50040"],
  },
  {
    primary_id: "D07CQW",
    names: ["Lomab B", "BCS-I-131 Construct"],
  },
  {
    primary_id: "D07CRG",
    names: [
      "2H-Isoquinolin-1-One",
      "1-Hydroxyisoquinoline",
      "491-30-5",
      "Isoquinolin-1(2H)-One",
      "Isoquinolin-1-Ol",
      "1-Isoquinolinol",
      "Isocarbostyril",
      "1(2H)-ISOQUINOLINONE",
      "Isoquinolin-1-One",
      "489453-23-8",
      "1(2H)-Isoquinolone",
      "1,2-Dihydroisoquinolin-1-One",
      "1-Hydroxyisoquinolin",
      "Isoquinolinol",
      "87602-67-3",
      "1(2H)-ISOQUINILONE",
      "Isocarbostyril, 98%",
      "UNII-95EG3HGG1P",
      "95EG3HGG1P",
      "Isoquinolinone",
      "CHEMBL339695",
      "CHEBI:18350",
      "VDBNYAPERZTOOF-UHFFFAOYSA-N",
      "1-Isoquinolone",
      "Oxidoisoquinolinium",
      "EINECS 207-732-1",
      "Isocarbostyril(1-Hydroxyisoquinoline)",
      "NSC 27273",
    ],
  },
  {
    primary_id: "D07CSA",
    names: [
      "Second Generation Taxoids",
      "CMPF",
      "Second Generation Taxoids (Cancer)",
      "SB-T-1102",
      "SB-T-1213",
      "SB-T-1214",
      "SB-T-12162",
      "Second Generation Taxoids (Cancer), State University Of New York Stony Brook",
      "KS-77-SBT-1214",
      "Me-SB-T-1214",
    ],
  },
  {
    primary_id: "D07CSE",
    names: ["Benzothiazepine Analog 12"],
  },
  {
    primary_id: "D07CTI",
    names: [
      "1-Imidazol-1-Ylmethyl-4-Nitro-Xanthen-9-One",
      "CHEMBL307160",
      "BDBM50097363",
      "1-(Imidazol-1-Ylmethyl)-4-Nitro-9h-9-Xanthenone",
      "4-Nitro-1-(1H-Imidazole-1-Ylmethyl)-9H-Xanthene-9-One",
    ],
  },
  {
    primary_id: "D07CTM",
    names: ["Carbol-Fuchsin Topical Solution"],
  },
  {
    primary_id: "D07CUI",
    names: ["Glycyl-L-Alpha-Amino-Epsilon-Pimelyl-D-Alanine"],
  },
  {
    primary_id: "D07CUR",
    names: ["Bb2121"],
  },
  {
    primary_id: "D07CUU",
    names: ["SC-02"],
  },
  {
    primary_id: "D07CUW",
    names: [
      "6-Bromo-2-(3-Nitro-Phenyl)-Chromen-4-One",
      "6-Bromo-2-(3-Nitrophenyl)Chromen-4-One",
      "AC1MVVK1",
      "6-Bromo-3'-Nitroflavone",
      "3'-Nitro-6-Bromoflavone",
      "CHEMBL54341",
    ],
  },
  {
    primary_id: "D07CVE",
    names: ["R396"],
  },
  {
    primary_id: "D07CVG",
    names: [
      "SQ-14603",
      "2-Benzyl-3-Mercaptopropanoic Acid",
      "CHEMBL58957",
      "2-Benzyl-3-Sulfanylpropanoic Acid",
      "69734-01-6",
      "SQ 14,603",
      "Benzenepropanoic Acid, Alpha-(Mercaptomethyl)-",
      "AC1Q5S0H",
      "AC1L4Y1D",
      "SCHEMBL1559014",
      "CTK2F3831",
      "ZUEBVBPVXLQMQR-UHFFFAOYSA-N",
      "2-Benzyl-3-Mercapto-Propionic Acid",
      "BDBM50109593",
      "AKOS022645473",
      "2-Mercaptomethyl-3-Phenylpropionic Acid",
      "2-Mercaptomethyl-3-Phenyl-Propionic Acid",
      "81110-55-6",
      "3-Phenyl-2-(Mercaptomethyl)Propionic Acid",
      "Benzenepropanoic Acid, Alpha-(Mercaptomethyl)-, (+-)-",
    ],
  },
  {
    primary_id: "D07CWD",
    names: [
      "Hydroxyurea",
      "Biosupressin",
      "Droxia",
      "Hidrix",
      "Hidroxicarbamida",
      "Hydrea",
      "Hydreia",
      "Hydroxicarbamidum",
      "Hydroxyaminomethanamide",
      "Hydroxycarbamid",
      "Hydroxycarbamide",
      "Hydroxycarbamidum",
      "Hydroxycarbamine",
      "Hydroxyharnstoff",
      "Hydroxylurea",
      "Hydura",
      "Hydurea",
      "Idrossicarbamide",
      "Litaler",
      "Litalir",
      "Mylocel",
      "NHY",
      "Oncocarbide",
      "Oxyurea",
      "Siklos",
      "Carbamide Oxide",
      "Carbamohydroxamic Acid",
      "Carbamohydroximic Acid",
      "Carbamohydroxyamic Acid",
      "Carbamoyl Oxime",
      "Carbamyl Hydroxamate",
      "Carrbamoyl Oxime",
      "Hydroxyharnstoff [German]",
      "Idrossicarbamide [DCIT]",
      "H 8627",
      "SK 22591",
      "SQ 1089",
      "DRG-0253",
      "Droxia (TM)",
      "Droxia (TN)",
      "HYDREA (TN)",
      "HYDROXY-UREA",
      "Hidroxicarbamida [INN-Spanish]",
      "Hydrea (TM)",
      "Hydroxycarbamidum [INN-Latin]",
      "Hydroxyurea (D4)",
      "Hydroxyurea (USP)",
      "Hydroxyurea [USAN:BAN]",
      "Hydroxyurea(D4)",
      "N-Carbamoylhydroxylamine",
      "N-HYDROXY UREA",
      "N-Hydroxymocovina",
      "N-Hydroxymocovina [Czech]",
      "N-Hydroxyurea",
      "Onco-Carbide",
      "SQ-1089",
      "Tetratogen: Inhibits Ribonucleoside Diphosphate Reductase",
      "Hydroxycarbamide (JAN/INN)",
      "N-(Aminocarbonyl) Hydroxyamine",
      "Hydrea, Biosupressin, Cytodrox, Hydroxyurea",
      "S-Phase/G-1 Interface Inhibitor",
      "1-HYDROXYUREA",
    ],
  },
  {
    primary_id: "D07CWH",
    names: ["INCB40093"],
  },
  {
    primary_id: "D07CWJ",
    names: [
      "5-Methyl-2-(Phenylsulfonamido)Benzoic Acid",
      "5-Methyl-2-[(Phenylsulfonyl)Amino]benzoic Acid",
      "5-METHYL-2-[(PHENYLSULFONYL)AMINO]BENZOIC ACID",
      "2-Benzenesulfonamido-5-Methylbenzoic Acid",
      "A41",
      "1yw7",
      "Sulfonamide Compound, 4",
      "AC1O0UU4",
      "SCHEMBL2009251",
      "CHEMBL378927",
      "Anthranilic Acid Sulfonamide, 4",
      "BDBM17477",
      "MolPort-004-356-849",
      "HMS3604F08",
      "STK978137",
      "ZINC14964411",
      "AKOS000202786",
      "MCULE-6409447208",
      "DB07313",
      "138964-56-4",
      "2-(Benzenesulfonamido)-5-Methylbenzoic Acid",
      "A444148",
    ],
  },
  {
    primary_id: "D07CXM",
    names: ["AVI-7288"],
  },
  {
    primary_id: "D07CYB",
    names: ["PMID25468267-Compound-56"],
  },
  {
    primary_id: "D07CYN",
    names: [
      "2-Amino-2,3-Dihydro-1H-Indene-5-Sulfonamide",
      "CHEMBL457996",
      "CHEMBL559179",
      "5-Sulfamyl-2-Indanamine",
      "SCHEMBL11452177",
      "FVWXUWAWIYLUJG-UHFFFAOYSA-N",
      "BDBM50155553",
      "2-Amino-Indan-5-Sulfonic Acid Amide",
      "AKOS015921614",
      "52069-27-9",
    ],
  },
  {
    primary_id: "D07CZI",
    names: ["PMID28394193-Compound-54"],
  },
  {
    primary_id: "D07CZJ",
    names: [
      "11-Valeryloxynoraporphine",
      "CHEMBL403550",
      "BDBM50202292",
      "Pentanoic Acid (R)-6-Propyl-5,6,6a,7-Tetrahydro-4H-Dibenzo[de,g]quinolin-11-Yl Ester",
    ],
  },
  {
    primary_id: "D07CZM",
    names: ["HTS-02876"],
  },
  {
    primary_id: "D07DAK",
    names: ["7-Epi-Ginkgolide C", "7-Epi-GC"],
  },
  {
    primary_id: "D07DAP",
    names: [
      "4,4'-(Ethane-1,2-Diyl)Dibenzene-1,3-Diol",
      "CHEMBL499124",
      "SCHEMBL1397158",
      "GTPL8785",
      "Bibenzyl-2,2',4,4'-Tetraol",
      "BDBM50263336",
      "Compound 7 [PMID: 21334791]",
      "4,4''-(Ethane-1,2-Diyl)Dibenzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D07DAU",
    names: ["Tafluposide"],
  },
  {
    primary_id: "D07DAX",
    names: [
      "2,7-Dinitroantraquinone",
      "2,7-Dinitroanthraquinone",
      "605-28-7",
      "EINECS 210-084-2",
      "2,7-Dinitro-9,10-Anthraquinone",
      "2,7- Dinitroanthrachinon",
      "AC1L2B0Y",
      "SCHEMBL2772220",
      "CHEMBL493255",
      "AC1Q21I1",
      "CTK5B1648",
      "DTXSID00209233",
      "XFLONXIGNOXKCG-UHFFFAOYSA-N",
      "ZINC5845160",
      "2,7-Dinitroanthracene-9,10-Dione",
    ],
  },
  {
    primary_id: "D07DBE",
    names: [
      "Amolimogene",
      "Bepiplasmid, Biotope-CD, Biotope-Diabetes, E-7101, HPV DNA Vaccine, Eisai HPV E6 E7 Plasmid, ZYC-00101, ZYC-101a, ZYC-101A",
    ],
  },
  {
    primary_id: "D07DBS",
    names: ["RP-64477"],
  },
  {
    primary_id: "D07DBU",
    names: ["Dihydrothieno [2,3-E]indazole Derivative 4"],
  },
  {
    primary_id: "D07DCG",
    names: ["Ivosidenib"],
  },
  {
    primary_id: "D07DCN",
    names: ["4-(3-Benzenesulfonamidophenyl)Piperazine", "CHEMBL213586", "BDBM50193466"],
  },
  {
    primary_id: "D07DCX",
    names: ["CDP-855"],
  },
  {
    primary_id: "D07DDF",
    names: ["Azapeptide Derivative"],
  },
  {
    primary_id: "D07DDK",
    names: ["HG-1177"],
  },
  {
    primary_id: "D07DEY",
    names: ["3-(3-Benzylamino)-Piperidin-2-One", "CHEMBL223197"],
  },
  {
    primary_id: "D07DFC",
    names: ["Aromatic Hydrazine Carboxyimidoamide Derivative 1"],
  },
  {
    primary_id: "D07DFD",
    names: ["MCL-434"],
  },
  {
    primary_id: "D07DFF",
    names: ["Huperaine A"],
  },
  {
    primary_id: "D07DGA",
    names: ["Imatinib And Nilotinib Derivative 2"],
  },
  {
    primary_id: "D07DGB",
    names: ["PMID28870136-Compound-55"],
  },
  {
    primary_id: "D07DGH",
    names: [
      "Pentanoic Acid (4,6-Diphenylpyrimidin-2-Yl)Amide",
      "CHEMBL221407",
      "820961-66-8",
      "N-(4,6-Diphenylpyrimidin-2-Yl)Pentanamide",
      "CTK3E2642",
      "DTXSID40466323",
      "MolPort-007-705-554",
      "BDBM50157679",
      "ZINC13586470",
      "AKOS001873650",
      "MCULE-5546422381",
      "Pentanamide, N-(4,6-Diphenyl-2-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D07DGI",
    names: ["Glycinamide Ribonucleotide"],
  },
  {
    primary_id: "D07DGM",
    names: [
      "BQ-518",
      "BQ 518",
      "CHEBI:2967",
      "CHEMBL2074702",
      "AC1L9EDN",
      "BQ518",
      "BDBM50390984",
      "136554-29-5",
      "C11589",
      "2-[(3R,6R,9S,12S,15S)-6-(1H-Indol-3-Ylmethyl)-9-(2-Methylpropyl)-2,5,8,11,14-Pentaoxo-12-Thiophen-2-Yl-1,4,7,10,13-Pentazabicyclo[13.3.0]octadecan-3-Yl]acetic Acid",
    ],
  },
  {
    primary_id: "D07DGQ",
    names: ["Porcine Lung Surfactant", "Curoserf (TN)"],
  },
  {
    primary_id: "D07DGR",
    names: ["SEL-113"],
  },
  {
    primary_id: "D07DGS",
    names: [
      "NSC-2113",
      "1,4-Diethoxybenzene",
      "122-95-2",
      "Benzene, 1,4-Diethoxy-",
      "P-Diethoxybenzene",
      "Hydroquinone Diethyl Ether",
      "Benzene, P-Diethoxy-",
      "1,4-Diethoxy-Benzen",
      "UNII-C6Y51501JB",
      "CHEMBL219898",
      "VWGNFIQXBYRDCH-UHFFFAOYSA-N",
      "C6Y51501JB",
      "Benzene,4-Diethoxy-",
      "NSC2113",
      "NSC 2113",
      "EINECS 204-585-5",
      "1,4-Diethoxybenzol",
      "Hydroquinonedlethylether",
      "ACMC-209ans",
      "AI3-09458",
      "AC1Q37UB",
      "AC1L26YB",
      "KSC174M8J",
      "SCHEMBL124015",
      "Benzene, P-Diethoxy- (8CI)",
      "Jsp001542",
      "DTXSID9059549",
      "CTK0H4684",
      "KS-00000WIN",
      "MolPort-001-759-994",
      "ZINC394919",
      "NSC68808",
      "SBB060362",
    ],
  },
  {
    primary_id: "D07DHN",
    names: [
      "5,7-Dibromo-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "CHEMBL336017",
      "5,7-Dibromo-1,4-Dihydro-2,3-Quinoxalinedione",
      "153504-78-0",
      "5,7-Dibromo-1,4-Dihydroquinoxaline-2,3-Dione",
      "Oprea1_150165",
      "SCHEMBL2638792",
      "SCHEMBL8557193",
      "KAMOVQVEAFPAHG-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D07DIJ",
    names: ["Cyclopropylamine Derivative 13"],
  },
  {
    primary_id: "D07DIM",
    names: ["Herbimycin A"],
  },
  {
    primary_id: "D07DIS",
    names: ["MG-1110"],
  },
  {
    primary_id: "D07DIX",
    names: ["ALO-1567", "AL01567", "2-Fluorospiro[9H-Fluorene-9,4'-Imidazolidine]-2',5'-Dione"],
  },
  {
    primary_id: "D07DJP",
    names: ["Avigen Parkinson"],
  },
  {
    primary_id: "D07DJQ",
    names: ["Emetine"],
  },
  {
    primary_id: "D07DJR",
    names: ["Pyrimidine Derivative 15"],
  },
  {
    primary_id: "D07DJS",
    names: ["QUERCITRIN"],
  },
  {
    primary_id: "D07DJT",
    names: [
      "LRHYLNLLTRQRY-NH2",
      "Ac(Leu-28,31)Npy(24-36)Amide",
      "NCGC00167292-01",
      "N-Acetyl(Leu(28),leu(31))Npy(24-36)-Amide",
      "Neuropeptide Y (24-36) Amide, N-Acetyl-(Leu(28,31))-",
      "L-Tyrosinamide, N-Acetyl-L-Leucyl-L-Arginyl-L-Histidyl-L-Tyrosyl-L-Leucyl-L-Asparaginyl-L-Leucyl-L-Leucyl-L-Threonyl-L-Arginyl-L-Glutaminyl-L-Arginyl-",
    ],
  },
  {
    primary_id: "D07DLA",
    names: ["ISF-402"],
  },
  {
    primary_id: "D07DLW",
    names: ["4-Methyl-N-Propyl-3,4-Dihydroquinazolin-2-Amine"],
  },
  {
    primary_id: "D07DMN",
    names: ["4-(1H-Indol-3-Yl)-N,N-Dimethylcyclohex-3-Enamine", "CHEMBL231809"],
  },
  {
    primary_id: "D07DMY",
    names: ["MK-3475"],
  },
  {
    primary_id: "D07DNK",
    names: [
      "3-(Oxalyl-Amino)-Naphthalene-2-Carboxylic Acid",
      "CHEMBL383570",
      "3-[(Carboxycarbonyl)Amino]naphthalene-2-Carboxylic Acid",
      "AC1L1BVL",
      "SCHEMBL4311266",
      "CTK7I3794",
      "1c84",
      "ZINC2007904",
      "BDBM50416040",
      "DB01734",
      "N-(3-Carboxy-2-Naphthyl)Oxamidic Acid",
      "3-(Oxaloamino)Naphthalene-2-Carboxylic Acid",
      "3-(Carboxyformamido)Naphthalene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D07DNV",
    names: [
      "ARN-2966",
      "ARN-4261",
      "Abeta Aggregation Inhibitors (Alzheimer's Disease)",
      "Abeta Aggregation Inhibitors (Alzheimer's Disease), New York University/Aria Neurosciences",
    ],
  },
  {
    primary_id: "D07DNX",
    names: ["PD-115199"],
  },
  {
    primary_id: "D07DOE",
    names: ["2'-Me-Tecadenoson", "CHEMBL400190", "2''-Me-Tecadenoson"],
  },
  {
    primary_id: "D07DOW",
    names: [
      "(1H-Pyrazol-1-Yl)(O-Tolyl)Methanone",
      "AC1LEF7F",
      "Methanone, (2-Methylphenyl)-1H-Pyrazol-1-Yl-",
      "N-Benzoylpyrazole Deriv., 26",
      "SCHEMBL5091107",
      "CHEMBL243419",
      "2-Methylphenyl Pyrazolyl Ketone",
      "ZINC52093",
      "BDBM23718",
      "MolPort-001-495-866",
      "STK458861",
      "AKOS003267448",
      "MCULE-5548346973",
      "(2-Methylphenyl)-Pyrazol-1-Ylmethanone",
      "ST073761",
      "312317-26-3",
      "1-[(2-Methylphenyl)Carbonyl]-1H-Pyrazole",
      "(2-Methylphenyl)(1H-Pyrazol-1-Yl)Methanone",
      "SR-01000520970",
      "SR-01000520970-1",
    ],
  },
  {
    primary_id: "D07DPC",
    names: ["IMGN-853"],
  },
  {
    primary_id: "D07DPI",
    names: ["Aspirin/ Esomeprazole Fixed-Dose Combination", "Axanum (TN)"],
  },
  {
    primary_id: "D07DPM",
    names: ["HP-011-101"],
  },
  {
    primary_id: "D07DPW",
    names: ["[D-Ncy(SO,isopropyl)7]acyline", "CHEMBL427269"],
  },
  {
    primary_id: "D07DPX",
    names: [
      "2-Amino-6-(3-Chloro-Benzenesulfonyl)-Benzonitrile",
      "AC1LA8EF",
      "BDBM1789",
      "CHEMBL291532",
      "CTK7C6575",
      "ZINC5933077",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3i",
      "2-Amino-6-(3-Chlorophenyl)Sulfonylbenzonitrile",
      "2-(3-Chlorophenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(3-Chlorophenyl)Sulfonyl-Benzonitrile",
      "2-Amino-6-[(3-Chlorophenyl)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D07DQD",
    names: ["Tetrahydroimidazo[4,5,1-Jk]-[1,4]benzodiazepin-2(1H)-One"],
  },
  {
    primary_id: "D07DQJ",
    names: ["1-(4-Bromobenzyl)-5-Phenyl-1H-Imidazole", "CHEMBL597173", "SCHEMBL3822956"],
  },
  {
    primary_id: "D07DQO",
    names: [
      "N-(6-Phenoxypyridin-3-Yl)-1H-Indole-3-Carboxamide",
      "CHEMBL481289",
      "SCHEMBL4239343",
      "KBQZTNRCKHEJQM-UHFFFAOYSA-N",
      "BDBM50251685",
      "1h-Indole-3-Carboxylic Acid(6-Phenoxy-Pyridine-3-Yl)-Amide",
    ],
  },
  {
    primary_id: "D07DQZ",
    names: ["CN-16"],
  },
  {
    primary_id: "D07DRJ",
    names: ["Quizalofop"],
  },
  {
    primary_id: "D07DRO",
    names: ["SF-106"],
  },
  {
    primary_id: "D07DRT",
    names: ["PMID23631440C29e"],
  },
  {
    primary_id: "D07DSK",
    names: [
      "BSI-302",
      "BSI-300 Series",
      "BSI-301",
      "Iodothyronine Series, BiPar Sciences",
      "Anti-Tubulin Series (Cancer), BiPar Sciences",
    ],
  },
  {
    primary_id: "D07DSN",
    names: ["BIIB-033"],
  },
  {
    primary_id: "D07DSQ",
    names: ["Vapreotide Acetate", "Docrised (TN)"],
  },
  {
    primary_id: "D07DTC",
    names: ["L-97-1 Intravenous"],
  },
  {
    primary_id: "D07DTM",
    names: ["Cetraxal Otic", "Ciprofloxacin Otic"],
  },
  {
    primary_id: "D07DTN",
    names: ["H-Tyr-C[D-Orn-(D Or L)Atc-Glu]-NH2"],
  },
  {
    primary_id: "D07DTV",
    names: ["AT7519"],
  },
  {
    primary_id: "D07DTZ",
    names: ["VK0612"],
  },
  {
    primary_id: "D07DUF",
    names: ["Des-AA1,2,5-[D-Nal8,IAmp9]SRIF", "CHEMBL410428"],
  },
  {
    primary_id: "D07DUU",
    names: ["Alglucosidase Alfa"],
  },
  {
    primary_id: "D07DUW",
    names: ["N-(3-Methanesulfonyl-4-Methoxy-Benzoyl)-Guanidine", "CHEMBL61273"],
  },
  {
    primary_id: "D07DUX",
    names: ["Roscovitine Derivative 1"],
  },
  {
    primary_id: "D07DVK",
    names: [
      "Beclomethasone",
      "Anceron",
      "Beclocort",
      "Beclometason",
      "Beclometasona",
      "Beclometasonum",
      "Becloval",
      "Becodisks",
      "Beconasol",
      "Becotide",
      "Inalone",
      "Viarex",
      "Beclometasondipropionat Mikron",
      "Beclometasone [INN]",
      "Vancenase AQ",
      "B 0385",
      "BMJ 5800",
      "Beclometason (TN)",
      "Beclometasona [INN-Spanish]",
      "Beclometasone (INN)",
      "Beclometasonum [INN-Latin]",
      "Beconase (TN)",
      "Clipper (TN)",
      "Propaderm (TN)",
      "Vancenase (TN)",
      "(11beta,16beta)-9-Chloro-11,17,21-Trihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
      "(8R,9S,10S,11S,13R,14R,16R,17R)-9-Chloro-11,17-Dihydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "(8S,9R,10S,11S,13S,14S,16S,17R)-9-Chloro-11,17-Dihydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "9-Chloro-11beta,17,21-Trihydroxy-16beta-Methylpregna-1,4-Diene-3,20-Dione",
      "9-Chloro-11,17-Dihydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "9alpha-Chloro-11beta,17alpha,21-Trihydroxy-16beta-Methylpregna-1,4-Diene-3,20-Dione",
      "9alpha-Chloro-16beta-Methyl-1,4-Pregnadiene-11beta,17alpha,21-Triol-3,20-Dione",
      "9alpha-Chloro-16beta-Methylprednisolone",
    ],
  },
  {
    primary_id: "D07DWB",
    names: ["Revascor"],
  },
  {
    primary_id: "D07DWC",
    names: ["SDX-7320"],
  },
  {
    primary_id: "D07DWF",
    names: ["ARQ 092"],
  },
  {
    primary_id: "D07DWH",
    names: ["Ac-DR[CEHdFRWC]-NH2", "CHEMBL267900"],
  },
  {
    primary_id: "D07DWS",
    names: ["1-(2-(2-Fluorobenzyloxy)Phenyl)Piperazine", "CHEMBL575899", "BDBM50299784"],
  },
  {
    primary_id: "D07DYJ",
    names: [
      "N-Aminoethylmorpholine",
      "4-(2-Aminoethyl)Morpholine",
      "2038/3/1",
      "2-Morpholinoethanamine",
      "4-Morpholineethanamine",
      "2-Morpholinoethylamine",
      "2-(Morpholin-4-Yl)Ethan-1-Amine",
      "N-(2-Aminoethyl)Morpholine",
      "Morpholine, 4-(2-Aminoethyl)-",
      "N-2-Aminoethylmorpholine",
      "2-Morpholin-4-Ylethanamine",
      "2-Morpholin-4-Ylethylamine",
      "2-Morpholin-4-Yl-Ethylamine",
      "N-2-Aminoethylmorfolin [Czech]",
      "N-Aminoethyl Morpholine",
      "N-2-Aminoethylmorfolin",
      "N-(Aminoethyl)Morpholine",
      "Beta-Aminoaethyl-Morpholin [German]",
      "2-Morpholino-1-Ethanamine",
    ],
  },
  {
    primary_id: "D07DZS",
    names: [
      "1,3-Bis(1,3-Benzothiazol-2-Ylthio)Acetone",
      "1,3-Bis(1,3-Benzothiazol-2-Ylsulfanyl)Propan-2-One",
      "MLS000533100",
      "AC1LOHTK",
      "CBMicro_026328",
      "CHEMBL1526448",
      "MolPort-002-158-967",
      "HMS2496A11",
      "ZINC1029672",
      "1,3-Dibenzothiazol-2-Ylthioacetone",
      "STK733322",
      "AKOS001750462",
      "MCULE-2850305009",
      "ST006263",
      "SMR000140538",
      "BIM-0026345.P001",
      "1,3-Bis(Benzo[d]thiazol-2-Ylthio)Propan-2-One",
      "SR-01000535355",
      "SR-01000535355-1",
      "1,3-Bis(1,3-Benzothiazol-2-Ylsulfanyl)Propane-2-One",
      "F1563-0006",
      "A2660/0113409",
    ],
  },
  {
    primary_id: "D07DZT",
    names: ["H2TFPC-SGlc"],
  },
  {
    primary_id: "D07EAB",
    names: [
      "1-(4-Nonylbenzyl)Pyrrolidine-3-Carboxylic Acid",
      "CHEMBL224703",
      "570423-46-0",
      "SCHEMBL6179612",
      "CTK1F3134",
      "DTXSID10436795",
      "BDBM50158343",
      "AKOS030543683",
      "3-Pyrrolidinecarboxylic Acid, 1-[(4-Nonylphenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D07EAJ",
    names: ["JASPAQUINOL"],
  },
  {
    primary_id: "D07EAV",
    names: ["PMID25666693-Compound-41"],
  },
  {
    primary_id: "D07EBF",
    names: ["CK-2017357"],
  },
  {
    primary_id: "D07EBU",
    names: ["AMG-221"],
  },
  {
    primary_id: "D07EBW",
    names: ["PMID27724045-Compound-17"],
  },
  {
    primary_id: "D07ECH",
    names: ["V-165"],
  },
  {
    primary_id: "D07EDB",
    names: [
      "Ozanimod",
      "1306760-87-1",
      "Ozanimod (RPC1063)",
      "UNII-Z80293URPV",
      "Z80293URPV",
      "(S)-5-(3-(1-((2-Hydroxyethyl)Amino)-2,3-Dihydro-1H-Inden-4-Yl)-1,2,4-Oxadiazol-5-Yl)-2-Isopropoxybenzonitrile",
      "Ozanimod [USAN:INN]",
      "Ozanimod (USAN/INN)",
      "SCHEMBL2195490",
      "GTPL8709",
      "CHEMBL3707247",
      "5-[3-[(1S)-1-(2-Hydroxyethylamino)-2,3-Dihydro-1H-Inden-4-Yl]-1,2,4-Oxadiazol-5-Yl]-2-Propan-2-Yloxybenzonitrile",
      "EX-A1316",
      "S7952",
      "ZINC116109867",
      "AKOS026674086",
      "DB12612",
      "CS-5070",
      "HY-12288",
      "AC-29883",
      "Benzonitrile, 5-(3-((1",
    ],
  },
  {
    primary_id: "D07EDG",
    names: [
      "1-(3,4-Dichlorobenzyl)-1H-Indole-2,3-Dione",
      "Apoptosis Activator 2",
      "79183-19-0",
      "Apoptosis Activator II",
      "1-(3,4-Dichlorobenzyl)Indoline-2,3-Dione",
      "MDK83190",
      "1-[(3,4-Dichlorophenyl)Methyl]indole-2,3-Dione",
      "J-503075",
      "1H-Indole-2,3-Dione,1-[(3,4-Dichlorophenyl)Methyl]-",
      "SR-01000411093",
      "ApoptosisActivator2",
      "AC1LYJSJ",
      "Tocris-2098",
      "AC1Q3JHQ",
      "CBMicro_042945",
      "Isatin-Based Compound, 16",
      "CHEMBL375126",
      "SCHEMBL5426477",
      "BDBM22796",
      "CHEBI:92273",
      "KS-00001DBG",
      "CTK5E6556",
      "Cid_1901244",
      "DTXSID30365533",
      "EX-A147",
      "AOB2639",
      "MolPort-002-181-853",
      "HMS3651L12",
    ],
  },
  {
    primary_id: "D07EDS",
    names: ["PMID29649907-Compound-29"],
  },
  {
    primary_id: "D07EEN",
    names: [
      "Lixivaptan",
      "168079-32-1",
      "VPA-985",
      "WAY-VPA-985",
      "UNII-8F5X4B082E",
      "VPA985",
      "CHEMBL49429",
      "CRTX-080",
      "VPA 985",
      "8F5X4B082E",
      "N-[3-Chloro-4-(6,11-Dihydropyrrolo[2,1-C][1,4]benzodiazepine-5-Carbonyl)Phenyl]-5-Fluoro-2-Methylbenzamide",
      "Lixar",
      "Lixivaptan [USAN:INN]",
      "Lixivaptan (USAN/INN)",
      "WAY VPA-985",
      "Lixivaptan (VPA-985)",
      "AC1L59LM",
      "WAY-VPA 985",
      "GTPL2238",
      "SCHEMBL1649340",
      "DTXSID00168472",
      "PPHTXRNHTVLQED-UHFFFAOYSA-N",
      "ZINC600399",
      "EX-A1129",
      "BCP09167",
      "BDBM50065115",
      "AKOS022181388",
      "AN-1842",
      "CS-7512",
      "API0009250",
      "NCGC00485402-01",
      "BF-Derm1",
    ],
  },
  {
    primary_id: "D07EFA",
    names: [
      "AST-915",
      "AST-914",
      "Movement Disorder Therapy (Oral), Ariston",
      "Movement Disorder Therapy (Oral, Tremor), Manhattan",
    ],
  },
  {
    primary_id: "D07EFB",
    names: ["LFn-P24-B-C"],
  },
  {
    primary_id: "D07EFI",
    names: ["1-(4-Methylpent-3-En-2-Ylidene)Thiosemicarbazide", "CHEMBL444251"],
  },
  {
    primary_id: "D07EFM",
    names: [
      "Arecoline",
      "Arecholine",
      "Arecaline",
      "63-75-2",
      "Methylarecaiden",
      "Methylarecaidin",
      "Arecolin",
      "Arekolin",
      "Arecoline Base",
      "Arecaidine Methyl Ester",
      "Arecholin",
      "Methyl 1-Methyl-1,2,5,6-Tetrahydropyridine-3-Carboxylate",
      "3-Pyridinecarboxylic Acid, 1,2,5,6-Tetrahydro-1-Methyl-, Methyl Ester",
      "Methyl N-Methyltetrahydronicotinate",
      "C8H13NO2",
      "N-Methyltetrahydronicotinic Acid, Methyl Ester",
      "Methyl 1,2,5,6-Tetrahydro-1-Methylnicotinate",
      "UNII-4ALN5933BH",
      "NSC 56321",
      "Methyl N-Methyl-1,2,5,6-Tetrahydronicotinate",
      "CCRIS 7688",
    ],
  },
  {
    primary_id: "D07EFO",
    names: ["ISIS 113902"],
  },
  {
    primary_id: "D07EFT",
    names: ["Ethynyl Compound 3"],
  },
  {
    primary_id: "D07EGB",
    names: ["4-Hexyl Resorcinol"],
  },
  {
    primary_id: "D07EGC",
    names: ["SC-50073"],
  },
  {
    primary_id: "D07EGF",
    names: ["PMID25666693-Compound-56"],
  },
  {
    primary_id: "D07EGS",
    names: [
      "CGS-23425",
      "CHEMBL46882",
      "N-[3,5-Dimethyl-4-(4'-Hydroxy-3'-Isopropylphenoxy)-Phenyl]-Oxamic Acid",
      "Cgs23425",
      "SCHEMBL281877",
      "DTXSID7040998",
      "NOCAS_40998",
      "CGS 23425",
      "BDBM50036402",
      "AKOS027327083",
      "N-[4-(4-Hydroxy-3-Isopropyl-Phenoxy)-3,5-Dimethyl-Phenyl]-Oxalamic Acid",
      "N-[3,5-Dimethyl-4-(4''-Hydroxy-3''-Isopropylphenoxy)Phenyl]oxamic Acid",
      "({4-[4-Hydroxy-3-(Propan-2-Yl)Phenoxy]-3,5-Dimethylphenyl}amino)(Oxo)Acetic Acid",
    ],
  },
  {
    primary_id: "D07EGW",
    names: [
      "AE-0047",
      "Calbren",
      "Vatanidipine",
      "Watanidipine Hydrochloride",
      "GJ-0956",
      "(R)-(-)-AE 0047",
      "(S)-(+)-AE 0047",
    ],
  },
  {
    primary_id: "D07EHB",
    names: ["Latrunculin B"],
  },
  {
    primary_id: "D07EHV",
    names: ["LGH-447"],
  },
  {
    primary_id: "D07EIJ",
    names: ["EPI-12323"],
  },
  {
    primary_id: "D07EJK",
    names: [
      "1-(4-Cyanobenzyl)-5-Formyl-1H-Imidazole",
      "183500-37-0",
      "CHEMBL611680",
      "4-(5-Formylimidazol-1-Ylmethyl)Benzonitrile",
      "4-[(5-Formyl-1H-Imidazol-1-Yl)Methyl]benzonitrile",
      "4-(5-FORMYL-IMIDAZOL-1-YLMETHYL)-BENZONITRILE",
      "4-((5-Formyl-1h-Imidazol-1-Yl)Methyl)Benzonitrile",
      "SCHEMBL1064258",
      "CTK4D8501",
      "DTXSID30442752",
      "AMUOEEKBBOOOCV-UHFFFAOYSA-N",
      "ZINC39118604",
      "BDBM50307239",
      "1-(4-Cyanobenzyl)-5-Formylimidazole",
      "AKOS015911901",
      "ACM183500370",
      "SC-55448",
      "KB-238611",
      "FT-0714561",
      "1-(4-Cyanobenzyl)-5-Imidazolecarboxaldehyde",
    ],
  },
  {
    primary_id: "D07EJY",
    names: ["AR-12286", "AR-11236", "AR-12080", "AR-12132", "CF-286-03", "CF-286-04"],
  },
  {
    primary_id: "D07EKK",
    names: ["PTC596"],
  },
  {
    primary_id: "D07ELC",
    names: ["ABI-2088"],
  },
  {
    primary_id: "D07ELT",
    names: ["NOX-700"],
  },
  {
    primary_id: "D07EMU",
    names: ["[3H]vesamicol", "[3H]-Vesamicol"],
  },
  {
    primary_id: "D07EMX",
    names: ["(1R)-1,2,2-TRIMETHYLPROPYL (S)-METHYLPHOSPHINATE"],
  },
  {
    primary_id: "D07ENB",
    names: ["NPI-32101"],
  },
  {
    primary_id: "D07ENH",
    names: ["IVV-001", "Cancer Biotherapy, InnaVirVax/INSERM/AP-HP/University Pierre Et Marie Curie"],
  },
  {
    primary_id: "D07EOD",
    names: ["S24773"],
  },
  {
    primary_id: "D07EPU",
    names: ["ADS-103254", "CHEMBL217712", "SCHEMBL6509918", "BDBM50200785"],
  },
  {
    primary_id: "D07EPV",
    names: ["Teneligliptin"],
  },
  {
    primary_id: "D07EQF",
    names: [
      "V-102862",
      "CO 102862",
      "UNII-0KN11H90GF",
      "181144-66-1",
      "CHEMBL287833",
      "0KN11H90GF",
      "4-(4-Fluorophenoxy)Benzaldehyde Semicarbazone",
      "(E)-2-(4-(4-Fluorophenoxy)Benz-Ylidene)Hydrazinecarboxamide",
      "V102862",
      "2-(4-(4-Fluorophenoxy)Benzylidene)Hydrazinecarboxamide",
      "2-((4-(4-Fluorophenoxy)Phenyl)Methylene)Hydrazinecarboxamide",
      "CO-102862",
      "2-[[4-(4-Fluorophenoxy)Phenyl]methylene]hydrazinecarboxamide",
      "Hydrazinecarboxamide, 2-((4-(4-Fluorophenoxy)Phenyl)Methylene)-",
      "CHEBI:92156",
      "MolPort-022-863-701",
      "BDBM50141073",
      "AKOS016004881",
      "NCGC00167807-01",
    ],
  },
  {
    primary_id: "D07EQJ",
    names: ["RG-7594"],
  },
  {
    primary_id: "D07ERV",
    names: ["Y-C[D-Pen-(3,5-DiI)Tyr-GSFC]KR-NH2", "CHEMBL216490"],
  },
  {
    primary_id: "D07ESC",
    names: [
      "Apremilast",
      "Apremilast (USAN)",
      "CC-10004",
      "N-[2-[1-(3-Ethoxy-4-Methoxy-Phenyl)-2-Methylsulfonyl-Ethyl]-1,3-Dioxo-Isoindol-4-Yl]acetamide",
    ],
  },
  {
    primary_id: "D07ESG",
    names: ["Pyrimidine Derivative 9"],
  },
  {
    primary_id: "D07ESH",
    names: ["PF-1913539"],
  },
  {
    primary_id: "D07ETD",
    names: ["N6-(4-Hydroxybenzyl)Adenine Riboside"],
  },
  {
    primary_id: "D07ETF",
    names: [
      "3-(1-Methyl-Piperidin-3-Yl)-2-Phenyl-1H-Indole",
      "CHEMBL43819",
      "SCHEMBL8525408",
      "BDBM50099264",
      "3-(1-Methylpiperidin-3-Yl)-2-Phenyl-1H-Indole",
    ],
  },
  {
    primary_id: "D07EUB",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 3"],
  },
  {
    primary_id: "D07EUY",
    names: [
      "H5N1 Influenza Vaccine",
      "Avian Influenza Vaccine, National Institute Of Allergy And Infectious Diseases",
      "H5N1 Influenza Vaccine, NIAID",
      "A/VietNam/1203/2004 X A/AnnArbor/6/60/Ca",
    ],
  },
  {
    primary_id: "D07EVI",
    names: [
      "[3H]nitrobenzylmercaptopurine Ribonucleoside",
      "[3H]NBTI",
      "[3H]-NBTI",
      "(2S,3R,4S,5R)-2-(6-Hydroxy-9H-Purin-9-Yl)-5-(Hydroxymethyl)-2-{[(2-Nitrophenyl)Methyl]sulfanyl}oxolane-3,4-Diol",
      "[3H]NBMPR",
      "SCHEMBL290700",
      "GTPL4511",
    ],
  },
  {
    primary_id: "D07EVV",
    names: [
      "Acamprosate Prodrugs",
      "Acamprosate Prodrugs (Alcohol Dependence",
      "Fragile X Syndrome), Xenoport",
      "Acamprosate Prodrugs (Alcohol Dependence/Fragile X Syndrome/Parkinsons Disease)",
      "Acamprosate Prodrugs (Alcohol Dependence/Fragile X Syndrome/Parkinsons Disease), Xenoport",
    ],
  },
  {
    primary_id: "D07EWF",
    names: ["PMID23412139C16", "0V6", "GTPL8698", "BDBM50392835"],
  },
  {
    primary_id: "D07EWK",
    names: ["[Sar1,Bpa3]AngII", "CHEMBL1076603"],
  },
  {
    primary_id: "D07EXH",
    names: ["Phloroglucinol"],
  },
  {
    primary_id: "D07EXV",
    names: ["XEMILOFIBAN"],
  },
  {
    primary_id: "D07EYJ",
    names: ["2-Fluorophenyl-2,2-Diphenylacetamide", "CHEMBL270071", "SCHEMBL1784375"],
  },
  {
    primary_id: "D07FBM",
    names: ["ASP1941"],
  },
  {
    primary_id: "D07FCA",
    names: [
      "BAMLET",
      "BAMLET (Liquid Formulation, Bladder Cancer)",
      "BAMLET Instillation (Bladder Cancer), NatImmune",
      "BAMLET (Liquid Formulation, Bladder Cancer), NatImmune",
      "Bovine Alpha-Lactalbumin-Lipid Complex (Liquid Formulation, Bladder Cancer), NatImmune",
    ],
  },
  {
    primary_id: "D07FCB",
    names: ["4-Phenyl-1-(1-Phenylethyl)Piperidin-4-Ol", "CHEMBL230249"],
  },
  {
    primary_id: "D07FCV",
    names: ["PMID27414413-Compound-Figure6right"],
  },
  {
    primary_id: "D07FDL",
    names: ["N-(2,3-Dihydro-1H-Phenalen-2-Yl)-Acetamide"],
  },
  {
    primary_id: "D07FDM",
    names: ["FR-901379"],
  },
  {
    primary_id: "D07FDV",
    names: ["Melaxin", "Autologous Dendritoma Vaccine (Sc, Melanoma), Oncolix"],
  },
  {
    primary_id: "D07FEC",
    names: ["Plecanatide"],
  },
  {
    primary_id: "D07FEF",
    names: ["PMID30247903-Compound-General Structure13"],
  },
  {
    primary_id: "D07FEJ",
    names: ["Amphotericin B Inhalation Powder"],
  },
  {
    primary_id: "D07FEO",
    names: [
      "N,N'-Di-Acenaphthen-5-Yl-Guanidine",
      "CHEMBL307395",
      "157970-69-9",
      "Cns 1145",
      "1,3-Di(1,2-Dihydroacenaphthylen-5-Yl)Guanidine",
      "AC1L4RHN",
      "AC1Q4SU4",
      "Cns-1145",
      "SCHEMBL6155294",
      "CTK4C9538",
      "N,N'-Bis(5-Acenaphthyl)Guanidine",
      "BDBM50285278",
      "N,N''-Di-Acenaphthen-5-Yl-Guanidine",
      "AKOS030602590",
      "1,2-Bis(1,2-Dihydroacenaphthylen-5-Yl)Guanidine",
    ],
  },
  {
    primary_id: "D07FFA",
    names: ["PMID28117607-Compound-7"],
  },
  {
    primary_id: "D07FFB",
    names: ["Carinatumins B (2)", "CHEMBL241838"],
  },
  {
    primary_id: "D07FFL",
    names: ["ADOCIAQUINONE B", "Adociaquinone B"],
  },
  {
    primary_id: "D07FGA",
    names: ["ZYC101a"],
  },
  {
    primary_id: "D07FGB",
    names: ["2,6-Bis(4-Tolyl)-9H-Purine", "CHEMBL205192", "BDBM50186598"],
  },
  {
    primary_id: "D07FGK",
    names: ["SAR-3419"],
  },
  {
    primary_id: "D07FGO",
    names: [
      "3,6-Dihydroxy-Xanthene-9-Propionic Acid",
      "3,6-DIHYDROXY-XANTHENE-9-PROPIONIC ACID",
      "HXP",
      "3-(3,6-Dihydroxy-9H-Xanthen-9-Yl)Propanoic Acid",
      "AC1L9MSN",
      "SCHEMBL4315610",
    ],
  },
  {
    primary_id: "D07FGU",
    names: ["SAR440067"],
  },
  {
    primary_id: "D07FGV",
    names: ["LLL-2011", "Amigra", "LL-2011"],
  },
  {
    primary_id: "D07FGX",
    names: [
      "2-Phenyl-3-Pyridin-4-Ylmethylene-Chroman-4-One",
      "CHEMBL10072",
      "AC1O1A27",
      "BDBM50111271",
      "3-[(Z)-4-Pyridinylmethylene]flavanone",
    ],
  },
  {
    primary_id: "D07FGY",
    names: ["XD14"],
  },
  {
    primary_id: "D07FHI",
    names: ["Chlamydia Vaccine"],
  },
  {
    primary_id: "D07FHW",
    names: ["PF-915275"],
  },
  {
    primary_id: "D07FHZ",
    names: [
      "Ro 20-1724",
      "4-(3-Butoxy-4-Methoxybenzyl)-2-Imidazolidinone",
      "RO 20-1724",
      "29925-17-5",
      "4-(3-Butoxy-4-Methoxybenzyl)Imidazolidin-2-One",
      "Ro-20-1724",
      "Ro 201724",
      "CHEMBL18701",
      "4-[(3-Butoxy-4-Methoxyphenyl)Methyl]imidazolidin-2-One",
      "2-Imidazolidinone, 4-((3-Butoxy-4-Methoxyphenyl)Methyl)-",
      "RO-201724",
      "2-Imidazolidinone, 4-((3-Butoxy-4-Methoxyphenyl)Methyl)-, (+-)-",
      "4-(3-Butoxy-4-Methoxy-Benzyl)Imidazolidin-2-One",
      "34185-37-0",
      "4-(3-Butoxy-4-Methoxyphenyl)Methyl-2-Imidazolidone",
      "SR-01000075647",
      "Keratan Sulfate I",
      "Keratan Sulphate I",
      "Naphthoquinone Analogs",
    ],
  },
  {
    primary_id: "D07FIC",
    names: ["CYT-6091"],
  },
  {
    primary_id: "D07FIF",
    names: [
      "APH-0701",
      "AMPI-109",
      "APH-M109",
      "MPI-105",
      "MPI-106",
      "MPI-107",
      "MPI-109",
      "Vitamin D Analog (Prostate Cancer), Boston University",
      "Vitamin D Analog (Nanosomal, Prostate Cancer), Aphios",
      "1,25(OH)2D3-3-BE",
      "25-Hydroxy-Vitamin D3-3-Bromoacetate",
    ],
  },
  {
    primary_id: "D07FIY",
    names: ["PMID25666693-Compound-50"],
  },
  {
    primary_id: "D07FJC",
    names: ["ALL-3"],
  },
  {
    primary_id: "D07FJI",
    names: ["ISIS 24477"],
  },
  {
    primary_id: "D07FJM",
    names: [
      "BRECANAVIR",
      "Brecanavir",
      "313682-08-5",
      "UNII-E367I8C7FI",
      "GW64085X",
      "GW640385",
      "E367I8C7FI",
      "CHEMBL206031",
      "VX-385",
      "GW0385",
      "(3R,3AS,6AR)-HEXAHYDROFURO[2,3-B]FURAN-3-YL [(1S,2R)-3-[(1,3-BENZODIOXOL-5-YLSULFONYL)(ISOBUTYL)AMINO]-2-HYDROXY-1-{4-[(2-METHYL-1,3-THIAZOL-4-YL)METHOXY]BENZYL}PROPYL]CARBAMATE",
      "[3H]GW0385",
      "Brecanavir [USAN:INN]",
      "Proteinase Inhibitor 640385",
      "2fde",
      "2fdd",
      "Brecanavir (USAN)",
      "GSK 640385",
      "GW 0385",
      "AC1NX470",
      "SCHEMBL1533991",
      "BDBM4685",
      "DTXSID10185296",
      "JORVRJNILJXMMG-OLNQLETPSA-N",
      "ZINC3994828",
      "VX 385",
      "AKOS005145761",
      "DB04887",
      "D03253",
    ],
  },
  {
    primary_id: "D07FJR",
    names: ["N-Butyl-ETAV"],
  },
  {
    primary_id: "D07FJU",
    names: [
      "7-Mercapto-Heptanoic Acid Pyridin-3-Ylamide",
      "CHEMBL332246",
      "Heptanamide, 7-Mercapto-N-3-Pyridinyl-",
      "BDBM50223653",
    ],
  },
  {
    primary_id: "D07FKO",
    names: ["N-(2,4-Dihydroxybenzyl)-3,4,5-Trihydroxybenzamide", "CHEMBL207146"],
  },
  {
    primary_id: "D07FKQ",
    names: ["BAF312"],
  },
  {
    primary_id: "D07FKX",
    names: ["HMPL-011"],
  },
  {
    primary_id: "D07FLB",
    names: [
      "N-(Tert-Butoxycarbonyl)-Norleucyl-Glycine-Nitrile",
      "Dipeptide-Derived Nitrile, 13",
      "CHEMBL383584",
      "BDBM20092",
      "Tert-Butyl N-[(1S)-1-[(Cyanomethyl)Carbamoyl]pentyl]carbamate",
    ],
  },
  {
    primary_id: "D07FLG",
    names: ["Dibutyl Phthalate", "Di-N-Butyl Phthalate", "N-Butyl Phthalate", "Nutyl Phthalate"],
  },
  {
    primary_id: "D07FLX",
    names: ["AGN 191976", "AGN-191976", "AGN191976"],
  },
  {
    primary_id: "D07FMW",
    names: ["PMID25684022-Compound-US20130053382 38(5-7)"],
  },
  {
    primary_id: "D07FND",
    names: ["Nabi-HB (Intramuscular)"],
  },
  {
    primary_id: "D07FNG",
    names: ["AM336"],
  },
  {
    primary_id: "D07FOH",
    names: [
      "Spiro[pyrrolidine-2,2-Adamantane]",
      "1'-Methylspiro[adamantane-2,2'-Pyrrolidine]",
      "AC1LA6HK",
      "CHEMBL45505",
      "SCHEMBL17352425",
      "SCHEMBL15922378",
      "ZINC3639976",
      "1-Methylspiro[pyrrolidine-2,2'-Adamantane]",
      "Spiro[pyrrolidine-2,2'-Tricyclo[3.3.1.13,7]decane], 1-Methyl-",
    ],
  },
  {
    primary_id: "D07FPJ",
    names: ["MK-4214"],
  },
  {
    primary_id: "D07FPQ",
    names: [
      "1-Methylcyclopropanecarboxylic Acid",
      "1-Methylcyclopropane-1-Carboxylic Acid",
      "6914-76-7",
      "1-Methyl-Cyclopropanecarboxylic Acid",
      "Cyclopropanecarboxylic Acid, 1-Methyl-",
      "DIZKLZKLNKQFGB-UHFFFAOYSA-N",
      "Cyclopropanecarboxylicacid, 1-Methyl-",
      "1-Methylcyclopropanecarboxylic Acid, 98%",
      "EINECS 230-020-7",
      "PubChem16037",
      "AC1Q5TAN",
      "AC1Q2CXA",
      "1-Methylcyclopropanoic Acid",
      "ACMC-209o6g",
      "SCHEMBL113962",
      "1-Carboxy-1-Methylcyclopropane",
      "GTPL6500",
      "DIZKLZKLNKQFGB-UHFFFAOYSA-",
      "CTK2F4732",
      "Methyl-Cyclopropanecarboxylic Acid",
      "DTXSID50219201",
    ],
  },
  {
    primary_id: "D07FPR",
    names: ["R4996"],
  },
  {
    primary_id: "D07FPS",
    names: ["7alpha-Cl-Ginkgolide B", "7alpha-Cl-GB"],
  },
  {
    primary_id: "D07FPY",
    names: ["SB-639"],
  },
  {
    primary_id: "D07FQL",
    names: ["Diannexin"],
  },
  {
    primary_id: "D07FQS",
    names: ["ZP-011"],
  },
  {
    primary_id: "D07FRU",
    names: ["PMID25980951-Compound-37"],
  },
  {
    primary_id: "D07FRX",
    names: ["6-Thiophen-3-Yl-Imidazo[2,1-B]thiazole", "163851-59-0", "CHEMBL273842", "AKOS005145119"],
  },
  {
    primary_id: "D07FSW",
    names: ["8-Hydroxyguanine"],
  },
  {
    primary_id: "D07FSX",
    names: ["ISIS 112002"],
  },
  {
    primary_id: "D07FTD",
    names: ["Sodium Monofluorophosphate"],
  },
  {
    primary_id: "D07FTI",
    names: ["Zinc Pyrithione", "BCP13412"],
  },
  {
    primary_id: "D07FUH",
    names: ["LPCN-1087"],
  },
  {
    primary_id: "D07FUJ",
    names: ["Fluorescein Sodium"],
  },
  {
    primary_id: "D07FUN",
    names: ["Spantide II"],
  },
  {
    primary_id: "D07FUO",
    names: ["TA-264", "Antioxidant (Thromboembolism), Sosei", "Antioxidant (Oral, Thromboembolism), Activus Pharma"],
  },
  {
    primary_id: "D07FUP",
    names: ["Dupilumab"],
  },
  {
    primary_id: "D07FVL",
    names: [
      "Oxazolidin-(2Z)-Ylideneamine",
      "24665-93-8",
      "Amino Oxazoline",
      "1,3-Oxazolidin-2-Imine",
      "4,5-Dihydrooxazol-2-Amine",
      "2-Oxazolamine, 4,5-Dihydro-",
      "4,5-Dihydro-1,3-Oxazol-2-Amine",
      "CHEMBL69446",
      "Aminooxazoline",
      "Iminooxazolidine",
      "NSC43138",
      "2-Amino-2-Oxazoline",
      "AC1Q4UHD",
      "AC1L61HW",
      "2-Amino-4,5-Dihydrooxazole",
      "2-Oxazolamine,4,5-Dihydro-",
      "CTK4F4142",
      "DTXSID50285885",
      "MolPort-023-198-771",
      "YAXGBZDYGZBRBQ-UHFFFAOYSA-N",
      "MolPort-006-013-450",
      "BDBM50078037",
      "NSC-43138",
      "ZINC13588690",
      "AKOS006221228",
      "AKOS009159127",
      "NE22687",
      "MCULE-9912511107",
      "AJ-64218",
      "AJ-64217",
    ],
  },
  {
    primary_id: "D07FVZ",
    names: [
      "CPPHA",
      "693288-97-0",
      "CHEMBL366217",
      "C22H15ClN2O4",
      "N-{4-Chloro-2-[(1,3-Dioxo-1,3-Dihydro-2H-Isoindol-2-Yl)Methyl]phenyl}-2-Hydroxybenzamide",
      "N-(4-Chloro-2-[(1,3-Dioxo-1,3-Dihydro-2H-Isoindol-2-Yl)Methyl]phenyl)-2-Hydroxybenzamide",
      "N-[4-Chloro-2-[(1,3-Dioxo-1,3-Dihydro-2H-Isoindol-2-Yl)Methyl]phenyl}-2-Hydroxybenzamide",
      "N-[4-Chloro-2-[(1,3-Dioxo-1,3-Dihydro-2H-Isoindol-2-Yl)Methyl]phenyl]-2-Hydroxybenzamide",
      "SCHEMBL1821580",
      "GTPL1423",
      "AOB4169",
      "DTXSID80433014",
      "UFOUABRZSDGGAZ-UHFFFAOYSA-N",
      "MolPort-028-748-388",
      "CPPHA, &gt",
    ],
  },
  {
    primary_id: "D07FWF",
    names: ["PMID25666693-Compound-117"],
  },
  {
    primary_id: "D07FWH",
    names: ["RU-59863", "Catechol Cephalosporins, Roussel"],
  },
  {
    primary_id: "D07FXC",
    names: ["2-(3-(3-Methoxyphenoxy)Prop-1-Ynyl)Pyridine", "CHEMBL212018", "SCHEMBL5698315"],
  },
  {
    primary_id: "D07FXF",
    names: ["CP-613"],
  },
  {
    primary_id: "D07FYK",
    names: ["HuMax-VEGF"],
  },
  {
    primary_id: "D07FYX",
    names: ["Pyz7-D-Trp8-SRIF"],
  },
  {
    primary_id: "D07FZA",
    names: ["AS99", "AS 99", "AS-99"],
  },
  {
    primary_id: "D07FZC",
    names: ["Recombinant Vaccine"],
  },
  {
    primary_id: "D07FZF",
    names: ["XMT-1522"],
  },
  {
    primary_id: "D07GAA",
    names: ["ACP-001"],
  },
  {
    primary_id: "D07GAN",
    names: ["Biaryl Mannoside Derivative 12"],
  },
  {
    primary_id: "D07GAP",
    names: ["N-Oxalylglycine Derivative 3"],
  },
  {
    primary_id: "D07GAU",
    names: ["Analog 3 (Vlattas Et Al., 1996)"],
  },
  {
    primary_id: "D07GBV",
    names: [
      "3-(1-Phenethyl-Piperidin-4-Yl)-2-Phenyl-1H-Indole",
      "CHEMBL40584",
      "SCHEMBL7948313",
      "BDBM50095049",
      "L018755",
      "3-(1-Phenethylpiperidin-4-Yl)-2-Phenyl-1H-Indole",
    ],
  },
  {
    primary_id: "D07GCA",
    names: [
      "2-(4-Chloro-2-Cycloheptylphenoxy)Acetic Acid",
      "CHEMBL398126",
      "SCHEMBL4875122",
      "JEPDINCGXLHLDF-UHFFFAOYSA-N",
      "BDBM50213919",
      "(4-Chloro-2-Cycloheptylphenoxy)Acetic Acid",
    ],
  },
  {
    primary_id: "D07GCK",
    names: ["ARC-100"],
  },
  {
    primary_id: "D07GCR",
    names: ["4-(Mesitylthio)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL462234"],
  },
  {
    primary_id: "D07GDN",
    names: ["Torcetrapib"],
  },
  {
    primary_id: "D07GDR",
    names: ["AMG 282"],
  },
  {
    primary_id: "D07GEL",
    names: ["SL422", "Analogue 55f [PMID: 11472217]"],
  },
  {
    primary_id: "D07GEN",
    names: [
      "4-Chloro-1H-Indazole",
      "13096-96-3",
      "4-CHLOROINDAZOLE",
      "1H-Indazole, 4-Chloro-",
      "4-Chloro-2H-Indazole",
      "NSC694315",
      "4-CHLORO(1H)INDAZOLE",
      "CHEMBL246533",
      "MFCD00211065",
      "4-Chloro-1H-Indazole, AldrichCPR",
      "4-Chloro-Indazole",
      "Zlchem 748",
      "NSC 694315",
      "PubChem21945",
      "AC1Q3RSH",
      "ACMC-1BUW0",
      "AC1L95AW",
      "SCHEMBL476854",
      "4-Chloro-1H-Indazole, 97%",
      "4-CHLORO (1H)INDAZOLE",
      "CTK0H7106",
      "DTXSID40156818",
      "ZLD0205",
      "CQTGQYVQJOJQCM-UHFFFAOYSA-N",
      "MolPort-001-767-756",
      "4-CHLORO-3-(1H)INDAZOLE",
      "ACT06078",
      "BCP27008",
      "ZINC5543816",
      "KS-00000O0T",
      "CS-M0440",
      "STL556259",
      "SBB054691",
      "QC-206",
    ],
  },
  {
    primary_id: "D07GET",
    names: ["ALTU-236"],
  },
  {
    primary_id: "D07GFF",
    names: ["PKI166"],
  },
  {
    primary_id: "D07GFI",
    names: ["Gallic Acid 5,6-Dihydroxy-3-Carboxyphenylester"],
  },
  {
    primary_id: "D07GFZ",
    names: ["VVZ-138"],
  },
  {
    primary_id: "D07GGP",
    names: ["JMV 1693"],
  },
  {
    primary_id: "D07GGQ",
    names: ["ND-801"],
  },
  {
    primary_id: "D07GGT",
    names: ["ANT-429"],
  },
  {
    primary_id: "D07GHA",
    names: ["PLX2853"],
  },
  {
    primary_id: "D07GHV",
    names: ["PF-05180999"],
  },
  {
    primary_id: "D07GHW",
    names: ["Bongkrek Acid", "Bongkrekic Acid", "Flavotoxin A"],
  },
  {
    primary_id: "D07GIB",
    names: [
      "AZD5672",
      "CHEMBL1951914",
      "SCHEMBL2767780",
      "GTPL7686",
      "QOSMEMHKXNNIGG-SSEXGKCCSA-N",
      "AZD 5672",
      "BDBM50364743",
      "N-[1-[(3R)-3-(3,5-Difluorophenyl)-3-(1-Methylsulfonylpiperidin-4-Yl)Propyl]piperidin-4-Yl]-N-Ethyl-2-(4-Methylsulfonylphenyl)Acetamide",
    ],
  },
  {
    primary_id: "D07GJH",
    names: ["PMID26161824-Compound-68"],
  },
  {
    primary_id: "D07GJZ",
    names: [
      "CQA 206-291",
      "Cqa 206-291",
      "Cqa-206-291",
      "93822-42-5",
      "UNII-9I4I0M95U7",
      "9I4I0M95U7",
      "N,N-Diethyl-N-(1-Ethyl-6-Methylergoline-8-Yl)Sulfamide",
      "AC1L3TWB",
      "SCHEMBL8195741",
      "Sulfamide, N,N-Diethyl-N'-((8alpha)-1-Ethyl-6-Methylergolin-8-Yl)-",
    ],
  },
  {
    primary_id: "D07GKK",
    names: ["1-[2-(2-Triphenylmethoxyethoxy)Ethyl]thymine", "CHEMBL219905"],
  },
  {
    primary_id: "D07GKS",
    names: ["MAGE-101", "Anti-HER-2 Antibody (Metastatic Breast Cancer), Eureka Therapeutics"],
  },
  {
    primary_id: "D07GLB",
    names: ["PEV-7"],
  },
  {
    primary_id: "D07GLF",
    names: ["Nemifitide"],
  },
  {
    primary_id: "D07GLO",
    names: ["4-Allyl-6-Nitro-2-Piperazin-1-Yl-Quinoline", "CHEMBL167358", "BDBM50110587"],
  },
  {
    primary_id: "D07GLX",
    names: ["CNS-1531"],
  },
  {
    primary_id: "D07GMF",
    names: [
      "MVA-85A",
      "AERAS-485",
      "MVA-AG85A",
      "MVA-85A Vaccine (Mycobacterium Tuberculosis Infection), Oxford-Emergent Tuberculosis Consortium/TB-VAC",
      "MVA-Ag85A Vaccine (Mycobacterium Tuberculosis Infection), Oxford-Emergent Tuberculosis Consortium/TB-VAC",
    ],
  },
  {
    primary_id: "D07GMY",
    names: [
      "BRL 52974",
      "145544-79-2",
      "Ethanone,2-(3,4-Dichlorophenyl)-1-[3,4,6,7-Tetrahydro-4-(1-Pyrrolidinylmethyl)-5H-Imidazo[4,5-C]pyridin-5-Yl]-",
      "Brl-52974",
      "Brl 52974",
      "ACMC-20n4kx",
      "SCHEMBL7641688",
      "CTK4C4597",
      "CJLJXCDVYJKDTR-UHFFFAOYSA-N",
      "AKOS030599156",
      "5-((3,4-Dichlorophenyl)Acetyl)-4-(1-Pyrrolidinylmethyl)-4,5,6,7-Tetrahydro-1H-Imidazo(4,5-C)Pyridine",
      "1H-Imidazo(4,5-C)Pyridine, 5-((3,4-Dichlorophenyl)Acetyl)-4,5,6,7-Tetrahydro-4-(1-Pyrrolidinylmethyl)-",
    ],
  },
  {
    primary_id: "D07GNL",
    names: ["GSK-523338"],
  },
  {
    primary_id: "D07GNO",
    names: ["KRN-330"],
  },
  {
    primary_id: "D07GNP",
    names: ["Iopromide"],
  },
  {
    primary_id: "D07GNR",
    names: ["Vivaglobin", "16% Subcutaneous Immunoglobulin Therapy (Primary Immunodeficienies/ITP), CSL Behring"],
  },
  {
    primary_id: "D07GOA",
    names: ["OL-92"],
  },
  {
    primary_id: "D07GOR",
    names: ["Dihydrocarveol"],
  },
  {
    primary_id: "D07GOY",
    names: ["CD59 Gene Therapy, AMD", "CD59 Gene Therapy (AMD)"],
  },
  {
    primary_id: "D07GPQ",
    names: ["Tripitramine"],
  },
  {
    primary_id: "D07GQA",
    names: ["ZD-7155"],
  },
  {
    primary_id: "D07GQE",
    names: ["Tyr-D-Ala-Phe-Glu-Val-Val-Gly-NH2", "CHEMBL29078", "Tyr-Ala-Phe-Asp-Val-Val-Gly", "GTPL1615"],
  },
  {
    primary_id: "D07GQP",
    names: ["PG-01037"],
  },
  {
    primary_id: "D07GRG",
    names: ["LY-379268"],
  },
  {
    primary_id: "D07GRH",
    names: [
      "Ciclopirox",
      "Ciclopiroxum",
      "Cyclopirox",
      "Cyclopyroxolamine",
      "Loprox",
      "Penlac",
      "Stieprox",
      "Terit",
      "Ciclopirox Olamin",
      "Ciclopirox Gel",
      "Loprox Gel",
      "Loprox Shampoo",
      "Loprox Cream",
      "Penlac Nail Lacquer",
      "HOE 296b",
      "Batrafen (TN)",
      "Ciclopirox-Olamin",
      "Ciclopiroxum [INN-Latin]",
      "Dafnegin-CSC",
      "HOE-296",
      "HOE-296b",
      "Loprox (TN)",
      "Penlac (TN)",
      "Stieprox (TN)",
      "Ciclopirox (USP/INN)",
      "Ciclopirox [USAN:BAN:INN]",
      "(6-Cyclohexyl-1-Hydroxy-4-Methyl-2(1H)-Pyridone)",
      "6-Cyclohexyl-1-Hydroxy-4-Methyl-2(1H)-Pyridinone",
      "6-Cyclohexyl-1-Hydroxy-4-Methyl-2(1H)-Pyridone",
      "6-Cyclohexyl-1-Hydroxy-4-Methyl-2-(1H)-Pyridone",
      "6-Cyclohexyl-1-Hydroxy-4-Methyl-2(1 H)-Pyridinone",
      "6-Cyclohexyl-1-Hydroxy-4-Methylpyridin-2(1H)-One",
      "6-Cyclohexyl-1-Hydroxy-4-Methylpyridin-2-One",
    ],
  },
  {
    primary_id: "D07GRM",
    names: ["2-Amino-3-Oxo-4-Sulfo-Butyric Acid"],
  },
  {
    primary_id: "D07GSH",
    names: ["PMID29338548-Compound-31"],
  },
  {
    primary_id: "D07GTB",
    names: ["L-660771"],
  },
  {
    primary_id: "D07GTF",
    names: ["Aipusheng"],
  },
  {
    primary_id: "D07GTW",
    names: [
      "Cyclic Pyranopterin Monophosphate",
      "CPMP",
      "CPMP (Molybdenum Cofactor Deficiency), Alexion",
      "CPMP (Molybdenum Cofactor Deficiency), Orphatec",
    ],
  },
  {
    primary_id: "D07GTX",
    names: ["(8-Hydroxy-2-Oxo-2H-Quinolin-1-Yl)-Acetic Acid", "CHEMBL64312", "BDBM50022245"],
  },
  {
    primary_id: "D07GUB",
    names: ["DL-TBOA", "Dl-Threo-Beta-Benzyloxyaspartate"],
  },
  {
    primary_id: "D07GUX",
    names: ["(E)-6-Styrylisatin", "CHEMBL450239"],
  },
  {
    primary_id: "D07GVC",
    names: ["ISIS 298745"],
  },
  {
    primary_id: "D07GVE",
    names: ["EDP-14"],
  },
  {
    primary_id: "D07GVM",
    names: ["JNJ-42491293"],
  },
  {
    primary_id: "D07GWS",
    names: ["Alanosine"],
  },
  {
    primary_id: "D07GWZ",
    names: ["NT-501 CNTF"],
  },
  {
    primary_id: "D07GXR",
    names: ["MK-1602"],
  },
  {
    primary_id: "D07GXY",
    names: ["Piribedil"],
  },
  {
    primary_id: "D07GYP",
    names: ["TYT-1"],
  },
  {
    primary_id: "D07GZG",
    names: ["Endomorphins"],
  },
  {
    primary_id: "D07GZL",
    names: ["R-701"],
  },
  {
    primary_id: "D07GZT",
    names: ["Deamido-Nad+"],
  },
  {
    primary_id: "D07GZU",
    names: ["PMID25399762-Compound-Table1-C22"],
  },
  {
    primary_id: "D07GZZ",
    names: ["Grass Allergy Vaccine"],
  },
  {
    primary_id: "D07HAD",
    names: ["Telbermin"],
  },
  {
    primary_id: "D07HAR",
    names: ["OMS-302"],
  },
  {
    primary_id: "D07HBI",
    names: ["ZD-2138"],
  },
  {
    primary_id: "D07HBQ",
    names: ["N,N,N-Triisobutyl-Pyrimidine-2,4,6-Triamine", "CHEMBL494706", "BDBM50253380"],
  },
  {
    primary_id: "D07HBR",
    names: ["PAS219"],
  },
  {
    primary_id: "D07HBX",
    names: ["Salicyclic Acid"],
  },
  {
    primary_id: "D07HCH",
    names: ["LY2812176"],
  },
  {
    primary_id: "D07HDC",
    names: ["JB-991 Ophthalmological"],
  },
  {
    primary_id: "D07HET",
    names: ["PMID25719566C1"],
  },
  {
    primary_id: "D07HEZ",
    names: [
      "2-Amino-6-Phenylsulfanyl-Benzonitrile",
      "Benzonitrile, 2-Amino-6-(Phenylthio)-",
      "123241-49-6",
      "2-Amino-6-Phenylsulfanylbenzonitrile",
      "AC1LADY2",
      "ACMC-20b9p7",
      "CHEMBL52068",
      "SCHEMBL1772509",
      "BDBM1731",
      "CTK0C2943",
      "DTXSID80333141",
      "MPKFSGFZCBOCIQ-UHFFFAOYSA-N",
      "ZINC5939158",
      "2-Amino-6-(Phenylthio)Benzonitrile",
      "6-Amino-2-Phenylthiobenzenecarbonitrile",
      "2-Amino-6-(Phenylsulfanyl)Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1a",
    ],
  },
  {
    primary_id: "D07HFF",
    names: ["7-Methoxy-2-P-Tolyl-4H-Chromene-4-Thione", "CHEMBL597716"],
  },
  {
    primary_id: "D07HFU",
    names: ["MCL-446"],
  },
  {
    primary_id: "D07HGR",
    names: [
      "Lisinopril",
      "Acerbon",
      "Acercomp",
      "Alapril",
      "Carace",
      "Cipral",
      "Cipril",
      "Coric",
      "Doneka",
      "Inhibril",
      "Inopril",
      "LPR",
      "Linopril",
      "Linvas",
      "Lipril",
      "Lisinal",
      "Lisinoprilum",
      "Lisipril",
      "Lisoril",
      "Lispril",
      "Longes",
      "Loril",
      "Lysinopril",
      "Noperten",
      "Novatec",
      "Presiten",
      "Prinil",
      "Prinivil",
      "Sinopril",
      "Sinopryl",
      "Tensopril",
      "Tensyn",
      "Tersif",
      "Vivatec",
      "Zestril",
      "Lisinopril Anhydrous",
      "Lisinoprilum [Latin]",
      "MK 521",
      "MK 522",
      "Hipril (TN)",
      "Lisinopril (INN)",
      "Lisinopril (Anhydrous)",
      "MK-521",
      "Prinivil (TN)",
      "Tensopril (TN)",
      "Zestril (TN)",
      "N-(1(S)-Carboxy-3-Phenylpropyl)-L-Lysyl-L-Proline",
      "N2-[(1S)-1-Carboxy-3-Phenylpropyl]-L-Lysyl-L-Proline",
      "[N2-[(S)-1-CARBOXY-3-PHENYLPROPYL]-L-LYSYL-L-PROLINE",
      "N(2)-[(1S)-1-Carboxy-3-Phenylpropyl]-L-Lysyl-L-Proline",
      "N2-((S)-1-Carboxy-3-Phenylpropyl)-L-Lysyl-L-Proline",
      "(2S)-1-[(2S)-6-Amino-2-[[(2S)-1-Hydroxy-1-Oxo-4-Phenylbutan-2-Yl]amino]hexanoyl]pyrrolidine-2-Carboxylic Acid",
      "(S)-1-(N(2)-(1-Carboxy-3-Phenylpropyl)-L-Lysyl)-L-Proline",
      "(S)-1-(N(Sup 2)-(1-Carboxy-3-Phenylpropyl)-L-Lysyl)-L-Proline",
      "(S)-1-(N2-(1-Carboxy-3-Phenylpropyl)-L-Lysyl)-L-Proline",
      "1-(N2-(1-Carboxy-3-Phenylpropyl)-L-Lysyl)-L-Proline",
      "1-[Nalpha-[(S)-1-Carboxy-3-Phenylpropyl]-L-Lysyl]-L-Proline",
    ],
  },
  {
    primary_id: "D07HHD",
    names: ["Resolvin D2"],
  },
  {
    primary_id: "D07HHU",
    names: ["TXA127"],
  },
  {
    primary_id: "D07HIJ",
    names: ["ISIS 103473"],
  },
  {
    primary_id: "D07HJS",
    names: ["Anti-BCMA CAR-T Cells"],
  },
  {
    primary_id: "D07HJT",
    names: ["Amidine Compound 3"],
  },
  {
    primary_id: "D07HKJ",
    names: [
      "CLEMATOMANDSHURICA SAPONIN A",
      "CHEMBL526701",
      "BDBM50241847",
      "6)-[(3-O-Isoferuloyl)-Beta-D-Glucopyranosyl]-(1-&gt",
      "3)-Alph-L-Rhamnopyranosyl-(1-&gt",
      "2)-Alpha-L-Arabinopyranosyloleanolicacid 28-O-Alph-L-Rhamnopyranosyl-(1-&gt",
    ],
  },
  {
    primary_id: "D07HKU",
    names: [
      "DEPX",
      "1,3-Diethyl-8-Phenylxanthine",
      "75922-48-4",
      "UNII-2KCV39GKGL",
      "2KCV39GKGL",
      "1,3-Dithyl-8-Phenylxanthine",
      "CHEMBL11348",
      "1,3-Diethyl-8-Phenyl-7H-Purine-2,6-Dione",
      "1H-Purine-2,6-Dione,1,3-Diethyl-3,9-Dihydro-8-Phenyl-",
      "Lopac-A-003",
      "AC1Q6LCB",
      "AC1L1B8H",
      "Lopac0_000125",
      "GTPL445",
      "8-Phenyl-1,3-Diethylxanthine",
      "SCHEMBL1321894",
      "AC1Q312F",
      "1,3-Diethyl-8-Phenyl-3,7-Dihydro-Purine-2,6-Dione",
      "BDBM81971",
      "CTK5E2202",
      "CAS_1328",
      "ZINC9229229",
      "NSC_1328",
      "PDSP2_000975",
      "PDSP1_000991",
      "PDSP2_000324",
      "PDSP2_000321",
      "PDSP1_000326",
      "PDSP1_000323",
      "AKOS027378654",
      "DPX",
    ],
  },
  {
    primary_id: "D07HKV",
    names: ["IMGN-388"],
  },
  {
    primary_id: "D07HLC",
    names: ["1-Benzyl-3,3-Dimethylazetidine-2,4-Dione", "CHEMBL556728"],
  },
  {
    primary_id: "D07HLS",
    names: ["Anacetrapib"],
  },
  {
    primary_id: "D07HLZ",
    names: ["GZ404477", "AAV-HAADC"],
  },
  {
    primary_id: "D07HNL",
    names: [
      "2-(2-Phenoxypyrimidin-4-Ylamino)Benzoic Acid",
      "4-Anilinopyrimidine 6g",
      "CHEMBL244626",
      "BDBM15985",
      "2-[(2-Phenoxypyrimidin-4-Yl)Amino]benzoic Acid",
    ],
  },
  {
    primary_id: "D07HNM",
    names: [
      "1,3-Diallyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL286785",
      "1.3-Diallylxanthin",
      "SCHEMBL516503",
      "1H-Purine-2,6-Dione, 3,9-Dihydro-1,3-Di-2-Propen-1-Yl-",
      "BDBM50022725",
      "31542-64-0",
    ],
  },
  {
    primary_id: "D07HNU",
    names: ["PMID27967267-Compound-42"],
  },
  {
    primary_id: "D07HNZ",
    names: [
      "Anti-AGX-020 Mabs",
      "Anti-AGX-020 MAbs (Rheumatoid Arthritis)",
      "Anti-AGX-020 MAbs (Rheumatoid Arthritis), Augurex",
      "Anti-14-3-3eta MAbs (Rheumatoid Arthritis), Augurex",
    ],
  },
  {
    primary_id: "D07HOA",
    names: ["Heterocyclic Derivative 9"],
  },
  {
    primary_id: "D07HOB",
    names: [
      "Irinotecan",
      "Biotecan",
      "Camptosar",
      "Irinotecanum",
      "IRINOTECAN HYDROCHLORIDE Trihydrate",
      "Irinotecan Hcl",
      "Irinotecan Hydrochloride",
      "CP0",
      "Biotecan (TN)",
      "Campto (TN)",
      "Camptosar (TN)",
      "Irinotecan (INN)",
      "Irinotecan [INN:BAN]",
      "Irinotecanum [INN-Latin]",
      "IRINOTECAN, CPT-11",
      "Camptosar, Campto, CPT-11, Irinotecan",
      "(+)-Irinotecan",
      "(4S)-4,11-DIETHYL-4-HYDROXY-3,14-DIOXO-3,4,12,14-TETRAHYDRO-1H-PYRANO[3',4':6,7]INDOLIZINO[1,2-B]QUINOLIN-9-YL 1,4'-BIPIPERIDINE-1'-CARBOXYLATE",
      "(4S)-4,11-Diethyl-4-Hydroxy-3,14-Dioxo-4,12-Dihydro-1H-Pyrano[3,4-F]quinolino[2,3-A]indolizin-9-Yl 4-Piperidylpiperidinecarboxylate",
      "Irinotecan (TOPO1 Inhibitor)",
      "Onivyde",
    ],
  },
  {
    primary_id: "D07HOF",
    names: ["Picibanil"],
  },
  {
    primary_id: "D07HOH",
    names: ["MM-398"],
  },
  {
    primary_id: "D07HOP",
    names: ["Alkyl Mannoside Derivative 9"],
  },
  {
    primary_id: "D07HOT",
    names: ["ALXN1007"],
  },
  {
    primary_id: "D07HPE",
    names: ["Cdk4 Inhibitor III"],
  },
  {
    primary_id: "D07HPH",
    names: ["MEDI5083"],
  },
  {
    primary_id: "D07HPJ",
    names: [
      "AEW-541",
      "AECDBHGVIIRMOI-UHFFFAOYSA-N",
      "NVP-AEW541",
      "475489-16-8",
      "475488-34-7",
      "AEW541",
      "NVP-AEW 541",
      "UNII-97QB5037VR",
      "AEW 541",
      "AVP-AEW541",
      "7-((1s,3s)-3-(Azetidin-1-Ylmethyl)Cyclobutyl)-5-(3-(Benzyloxy)Phenyl)-7H-Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "CHEMBL1614712",
      "97QB5037VR",
      "7-[TRANS-3-(1-AZETIDINYLMETHYL)CYCLOBUTYL]-5-[3-(PHENYLMETHOXY)PHENYL]-7H-PYRROLO[2,3-D]PYRIMIDIN-4-AMINE",
      "C27H29N5O",
      "7H-PYRROLO[2,3-D]PYRIMIDIN-4-AMINE, 7-[CIS-3-(1-AZETIDINYLMETHYL)CYCLOBUTYL]-5-[3-(PHENYLMETHOXY)PHENYL]-",
    ],
  },
  {
    primary_id: "D07HQC",
    names: ["Modafinil"],
  },
  {
    primary_id: "D07HQP",
    names: ["8-Pentyloxy-Quinolin-2-Ylamine", "CHEMBL186481", "SCHEMBL5883752"],
  },
  {
    primary_id: "D07HQZ",
    names: ["[3H]LSD", "[3H]N,N-Diethyllysergamide", "[3H]lysergic Acid Diethylamide", "[3H]lysergide"],
  },
  {
    primary_id: "D07HRP",
    names: ["3,4-Bis(Indol-3-Yl)Maleimide Derivative", "CHEMBL7364"],
  },
  {
    primary_id: "D07HRU",
    names: ["KRN633"],
  },
  {
    primary_id: "D07HSQ",
    names: ["1-Adamantan-1-Yl-3-((R)-1-Phenyl-Ethyl)-Urea", "CHEMBL222080"],
  },
  {
    primary_id: "D07HSR",
    names: ["GSK2798745"],
  },
  {
    primary_id: "D07HSU",
    names: ["ISIS 109524"],
  },
  {
    primary_id: "D07HTH",
    names: ["[Mpa1, D-Tyr(Et)2, D-Tic7]OT", "CHEMBL233328"],
  },
  {
    primary_id: "D07HTM",
    names: ["(-)-Ro 363"],
  },
  {
    primary_id: "D07HTX",
    names: ["GL-331"],
  },
  {
    primary_id: "D07HUH",
    names: ["7-(4-(Dimethylamino)Phenoxy)-N-Hydroxyheptanamide", "CHEMBL265479"],
  },
  {
    primary_id: "D07HUW",
    names: ["Oregovomab"],
  },
  {
    primary_id: "D07HVJ",
    names: ["2-(3''-(Benzoimidazole-1''-Yl)Ethyloxy)Adenosine", "CHEMBL221363", "BDBM50208818"],
  },
  {
    primary_id: "D07HVY",
    names: [
      "Efavirenz",
      "EFV",
      "EFZ",
      "Eravirenz",
      "Stocrin",
      "Sustiva",
      "DMP 266",
      "L 743726",
      "DMP-266",
      "L-741211",
      "L-743725",
      "L-743726",
      "Stocrin (TN)",
      "Strocin (TM)",
      "Sustiva (TM)",
      "Sustiva (TN)",
      "Efavirenz (JAN/INN)",
      "L-743,726",
      "Zoxazin-2-One",
      "Efavirenz, (S)-Isomer",
      "Met-SDF-1.beta. & Efavirenz",
      "Met-Stromal Cell-Derived Factor-1.beta. (Human) & Efavirenz",
      "(-)-Efavirenz",
      "(4S)-6-Chloro-4-(Cyclopropylethynyl)-1,4-Dihydro-4-(Trifluoromethyl)-2H-3,1-Benzoxazin-2-One",
      "(4S)-6-Chloro-4-Cyclopropylethynyl-4-Trifluoromethyl-1,4-Dihydro-Benzo[d][1,3]oxazin-2-One",
      "(4S)-6-Chloro-4-(2-Cyclopropylethynyl)-4-(Trifluoromethyl)-1H-3,1-Benzoxazin-2-One",
      "(4S)-6-Chloro-4-(Cyclopropylethynyl)-4-(Trifluoromethyl)-1,4-Dihydro-2H-3,1-Benzoxazin-2-One",
      "(S)-6-Chloro-4-(2-Cyclopropylethynyl)-1,4-Dihydro-4-(Trifluoromethyl)-2H-3,1-Ben",
      "(S)-6-Chloro-4-(Cyclopropylethynyl)-1,4-Dihydro-4-(Trifluoromethyl)-2H-3,1-Benzoxazin-2-One",
      "(S)-6-Chloro-4-Cyclopropylethynyl-4-Trifluoromethyl-1,4-Dihydro-Benzo[d][1,3]oxazin-2-One",
      "2H-3,1-Benzoxazin-2-One, 6-Chloro-4-(Cyclopropylethynyl)-1,4-Dihydro-4-(Trifluoromethyl)-, (4S)-(9",
      "6-Chloro-4-(2-Cyclopropyl-1-Ethynyl)-4-Trifluoromethyl-(4S)-1,4-Dihydro-2H-Benzo[d][1,3]oxazin-2-One",
    ],
  },
  {
    primary_id: "D07HWB",
    names: ["5-Desgalloylstachyurin", "CHEMBL488110"],
  },
  {
    primary_id: "D07HWI",
    names: ["(1R,2S)-1,2-Diphenylethane-1,2-Diamine"],
  },
  {
    primary_id: "D07HWJ",
    names: ["FGGFTGARKSARKRKNQ", "[Arg14,Lys15]Nociceptin", "236098-40-1"],
  },
  {
    primary_id: "D07HWV",
    names: ["13-(5-N-Pentylfuran-2-Yl)Tridec-8(Z)-Enoic Acid", "CHEMBL559279"],
  },
  {
    primary_id: "D07HXJ",
    names: ["Anticancer Therapy, Aurigene Discovery Technologies"],
  },
  {
    primary_id: "D07HXL",
    names: [
      "PD-81723",
      "132861-87-1",
      "PD 81723",
      "(2-Amino-4,5-Dimethylthiophen-3-Yl)(3-(Trifluoromethyl)Phenyl)Methanone",
      "(2-AMINO-4,5-DIMETHYL-3-THIENYL)-[3-(TRIFLUOROMETHYL)PHENYL]METHANONE",
      "CHEMBL59532",
      "PD 81,723",
      "PD81723",
      "Methanone, (2-Amino-4,5-Dimethyl-3-Thienyl)(3-(Trifluoromethyl)Phenyl)-",
      "(2-Amino-4,5-Dimethylthiophen-3-Yl)-[3-(Trifluoromethyl)Phenyl]methanone",
      "(2-Amino-4,5-Dimethylthiophen-3-Yl)-(3-(Trifluoromethyl)Phenyl)Methanone",
      "C14H12F3NOS",
      "PD-81,723",
      "Methanone, (2-Amino-4,5-Dimethyl-3-Thienyl)[3-(Trifluoromethyl)Phenyl]",
    ],
  },
  {
    primary_id: "D07HYL",
    names: [
      "2,4,3',5'-Tetrahydroxybibenzyl",
      "CHEMBL221291",
      "SCHEMBL19433107",
      "BDBM50193667",
      "2,4,3'',5''-Tetrahydroxybibenzyl",
      "4-[2-(3,5-Dihydroxyphenyl)Ethyl]benzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D07HYR",
    names: ["GCC-1290K"],
  },
  {
    primary_id: "D07HZG",
    names: ["PF-00734200"],
  },
  {
    primary_id: "D07HZQ",
    names: ["Selenazole-4-Carboxyamide-Adenine Dinucleotide"],
  },
  {
    primary_id: "D07HZU",
    names: ["FGGFTGARKSARKLVNQ"],
  },
  {
    primary_id: "D07HZY",
    names: [
      "Xylose",
      "D-Xylose",
      "D-Xylopyranose",
      "Xyloside",
      "D-Xylopentose",
      "(3R,4S,5R)-Oxane-2,3,4,5-Tetrol",
      "7261-26-9",
      "CHEBI:53455",
      "Xylopyranoside",
      "D-Xylopyranoside",
      "Xylomed",
      "D-Xyl",
      "10257-31-5",
      "(3R,4S,5R)-Tetrahydro-2H-Pyran-2,3,4,5-Tetrol",
      "(3R,4S,5R)-Tetrahydro-2H-Pyran-2,3,4,5-Tetraol",
      "D-(+)-Xylose, &gt",
      "Xyl",
      "Xylose (USP)",
      "Xylo-Pfan (TN)",
      "DSSTox_CID_3745",
      "Epitope ID:114701",
      "AC1L35YW",
      "DSSTox_RID_77180",
      "SCHEMBL39891",
      "DSSTox_GSID_23745",
      "MLS001361339",
      "Cid_135191",
      "CHEMBL502135",
      "DTXSID0023745",
      "CTK0H1735",
      "BDBM16234",
      "MolPort-003-934-029",
    ],
  },
  {
    primary_id: "D07IAK",
    names: ["PD 168368"],
  },
  {
    primary_id: "D07IAO",
    names: ["Aryl Cyclopropylamine Derivative 5"],
  },
  {
    primary_id: "D07IAU",
    names: ["Anti-GnRH Spiegelmer"],
  },
  {
    primary_id: "D07IBF",
    names: ["Ac-YR[CE(1-Me-H)DFRWC]-NH2", "CHEMBL264132"],
  },
  {
    primary_id: "D07IBU",
    names: ["GR-133686"],
  },
  {
    primary_id: "D07IBY",
    names: ["STA-9584"],
  },
  {
    primary_id: "D07ICD",
    names: ["PMID12238936C3a"],
  },
  {
    primary_id: "D07ICV",
    names: [
      "GNF-PF-3878",
      "143069-08-3",
      "TCMDC-124255",
      "9-Acridinamine, N-(4-(4-Ethyl-1-Piperazinyl)Phenyl)-",
      "CHEMBL217366",
      "N-(4-(4-Ethyl-1-Piperazinyl)Phenyl)-9-Acridinamine",
      "N-[4-(4-Ethylpiperazin-1-Yl)Phenyl]acridin-9-Amine",
      "9-Acridinamine,N-[4-(4-Ethyl-1-Piperazinyl)Phenyl]-",
      "N-(4-(4-Ethylpiperazin-1-Yl)Phenyl)Acridin-9-Amine",
      "CBDivE_005028",
      "AC1MILKO",
      "ACMC-20n23a",
      "CTK4C3465",
      "DTXSID10162300",
      "BDBM50196122",
      "AKOS030561802",
    ],
  },
  {
    primary_id: "D07ICW",
    names: ["Tyr-D-Ala-Gly-D-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D07IEC",
    names: ["GSK2340273A"],
  },
  {
    primary_id: "D07IEF",
    names: [
      "Bisacodyl",
      "603-50-9",
      "Dulcolax",
      "Bicol",
      "Durolax",
      "Brocalax",
      "Stadalax",
      "Sanvacual",
      "Hillcolax",
      "Fenilaxan",
      "Endokolat",
      "Theralax",
      "Dulcolan",
      "Nigalax",
      "Laxorex",
      "Laxadin",
      "Neolax",
      "Laxine",
      "Ivilax",
      "Eulaxan",
      "Ulcolax",
      "Telemin",
      "Laxans",
      "Godalax",
      "Zetrax",
      "Pyrilax",
      "Sk-Bisacodyl",
      "Laxanin N",
      "Bisacodylum",
      "Bisacodilo",
      "Deficol",
      "LACO",
      "LA96a",
      "Phenol, 4,4'-(2-Pyridinylmethylene)Bis-, Diacetate (Ester)",
      "HalfLytely",
      "Bisacodylum [INN-Latin]",
      "Bisacodilo [INN-Spanish]",
      "(Pyridin-2-Ylmethylene)Bis(4,1-Phenylene) Diacetate",
      "Bis(P-Acetoxyphenyl)-2-Pyridylmethane",
    ],
  },
  {
    primary_id: "D07IEM",
    names: ["AMG-8562"],
  },
  {
    primary_id: "D07IEU",
    names: ["MLN2480"],
  },
  {
    primary_id: "D07IFS",
    names: ["Omadacycline Intravenous"],
  },
  {
    primary_id: "D07IFZ",
    names: ["VIA-3196"],
  },
  {
    primary_id: "D07IGC",
    names: ["DSP-8658"],
  },
  {
    primary_id: "D07IGD",
    names: ["2-(1-Adamantyl)-2-Methyl-Pyrrolidine"],
  },
  {
    primary_id: "D07IGE",
    names: ["Glyfoline"],
  },
  {
    primary_id: "D07IGQ",
    names: ["C-Immune"],
  },
  {
    primary_id: "D07IHD",
    names: [
      "2-(5,6-Dihydroxy-Indan-1-Ylidene)-Malononitrile",
      "133550-11-5",
      "Propanedinitrile, (2,3-Dihydro-5,6-Dihydroxy-1H-Inden-1-Ylidene)-",
      "Tyrphostin Deriv. 15",
      "ACMC-20muzh",
      "AC1NS59I",
      "SCHEMBL6285970",
      "CHEMBL293584",
      "BDBM4284",
      "CTK0C0328",
      "DTXSID30416170",
      "2-(5,6-Dihydroxy-2,3-Dihydroinden-1-Ylidene)Propanedinitrile",
      "(5,6-Dihydroxyindanylidene)Malononitrile",
      "[(2,3-Dihydro-5,6-Dihydroxy-1H-Inden)-1-Ylidene]malononitrile",
    ],
  },
  {
    primary_id: "D07IHM",
    names: [
      "CEP-6331",
      "2-Isopropoxy-12-(2-Hydroxyethyl)-13,14-Dihydronaphthol[2,1-A]pyrrolo[3,4-C]carbazole-5-One",
      "CHEMBL460990",
      "BDBM24949",
      "MolPort-027-637-287",
      "ZINC33970297",
      "5-Oxo Dihydronaphthylcarbazole Analogue, 16",
      "X7365",
    ],
  },
  {
    primary_id: "D07IHP",
    names: ["Sulfamic Acid 3-Sulfamoyloxy-Phenyl Ester", "CHEMBL182429", "SCHEMBL9451263"],
  },
  {
    primary_id: "D07IHT",
    names: ["N,N-Methylenebis-2-Phenylacetamide And Benzenesulfonamide Derivative 1"],
  },
  {
    primary_id: "D07IHY",
    names: [
      "BRL-48482",
      "SCHEMBL1850299",
      "ZYKPNHFCNSVFNF-UHFFFAOYSA-N",
      "5-[4-[2-[N-(2-Benzoxazolyl)-N-Methylamino]ethoxy]benzyl]-2,4-Thiazolidinedione",
      "5-[4-[2-(Benzoxazol-2-Yl-Methylamino)Ethoxy] Benzyl]thiazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D07IIX",
    names: ["AMELTOLIDE", "ADD-75073", "Ameltolide < Prop INN", "LY-201116", "4-Amino-N-(2,6-Dimethylphenyl)Benzamide"],
  },
  {
    primary_id: "D07IJU",
    names: [
      "(S)-Pyrrolidin-1-Yl(Pyrrolidin-2-Yl)Methanone",
      "CHEMBL403882",
      "L-Prolin-Pyrrolidid",
      "(S)-1-Prolylpyrrolidine",
      "1-(L-Prolyl)Pyrrolidine",
      "41721-00-0",
      "SCHEMBL1383434",
      "HDLWINONZUFKQV-QMMMGPOBSA-N",
      "ZINC11754367",
      "BDBM50228295",
      "AKOS022180332",
      "AJ-60015",
      "Pyrrolidine, 1-[(2S)-2-Pyrrolidinylcarbonyl",
    ],
  },
  {
    primary_id: "D07ILP",
    names: [
      "10-Hydroxycamptothecin",
      "19685-09-7",
      "(S)-10-Hydroxycamptothecin",
      "Hydroxycamptothecin",
      "10-Hydroxycamptothecine",
      "10-Hydroxy Camptothecin",
      "Hydroxycamptothecine",
      "Camptothecin, Hydroxy-",
      "10-Hydroxy-Camptothecin",
      "(S)-4-Ethyl-4,9-Dihydroxy-1H-Pyrano[3',4':6,7]indolizino[1,2-B]quinoline-3,14(4H,12H)-Dione",
      "Camptothecin, 10-Hydroxy-",
      "Camptothecine, 10-Hydroxy-",
      "UNII-9Z01632KRV",
      "NSC 107124",
      "HCPT",
      "(4S)-4-Ethyl-4,9-Dihydroxy-1H-Pyrano[3',4':6,7]indolizino[1,2-B]quinoline-3,14(4H,12H)-Dione",
      "(S)-10-Hydroxycamptothecin Hydrate",
      "NSC107124",
    ],
  },
  {
    primary_id: "D07ILQ",
    names: ["Guanfacine Extended Release"],
  },
  {
    primary_id: "D07IMX",
    names: [
      "BMS-844421",
      "BMS-844421 (IV)",
      "BMS-844421 (SC)",
      "BMS-PCSK9-1",
      "BMS-PCSK9Rx-1",
      "BMS-PCSK9-1 (IV)",
      "BMS-PCSK9-1 (SC)",
      "BMS-PCSK9Rx-1 (IV)",
      "BMS-PCSK9Rx-1 (SC)",
    ],
  },
  {
    primary_id: "D07IND",
    names: ["SRD-285"],
  },
  {
    primary_id: "D07INL",
    names: ["SCY-078"],
  },
  {
    primary_id: "D07INO",
    names: ["Octahydro-Isoindol-(1Z)-Ylideneamine", "CHEMBL185441", "SCHEMBL9117580", "BDBM50150929"],
  },
  {
    primary_id: "D07INU",
    names: ["LDN-22684"],
  },
  {
    primary_id: "D07INV",
    names: ["Ethoxzolamide"],
  },
  {
    primary_id: "D07IOB",
    names: ["VU0092145"],
  },
  {
    primary_id: "D07IOO",
    names: ["Urea And Carbamate Bioisostere Derivative 11"],
  },
  {
    primary_id: "D07IOT",
    names: ["TPI 287"],
  },
  {
    primary_id: "D07IOX",
    names: [
      "2-(N-Tert-Butylamino)-3'-Chlorooctanophenone",
      "CHEMBL565593",
      "SCHEMBL633368",
      "HHZNTCLXZVSLGW-UHFFFAOYSA-N",
      "BDBM50302909",
    ],
  },
  {
    primary_id: "D07IOY",
    names: ["Pyridine-2,4-Dicarboxylic Acid"],
  },
  {
    primary_id: "D07IPB",
    names: ["Valrubicin"],
  },
  {
    primary_id: "D07IPZ",
    names: ["2-(4-Hydroxypent-1-Yl)-N6-Methoxyadenosine", "CHEMBL219888", "SCHEMBL4381152"],
  },
  {
    primary_id: "D07IQE",
    names: ["Heterocyclic Derivative 10"],
  },
  {
    primary_id: "D07IQG",
    names: [
      "4-Bromo-1-Imidazol-1-Ylmethyl-Xanthen-9-One",
      "CHEMBL78322",
      "331684-13-0",
      "CTK1B8690",
      "DTXSID70440555",
      "BDBM50097370",
      "AKOS030562752",
      "1-(Imidazol-1-Ylmethyl)-4-Bromo-9h-9-Xanthenone",
      "1-(1H-Imidazole-1-Ylmethyl)-4-Bromo-9H-Xanthene-9-One",
      "9H-Xanthen-9-One, 4-Bromo-1-(1H-Imidazol-1-Ylmethyl)-",
    ],
  },
  {
    primary_id: "D07IQK",
    names: [
      "Beta III Tubulin Targeting DdRNAi Therapeutic",
      "Beta III Tubulin Targeting DdRNAi Therapeutic (Lung Cancer)",
      "Beta III Tubulin Targeting RNAi Therapeutic (Lung Cancer), Benitec/Children's Cancer Institute Australia For Medical Research",
      "Beta III Tubulin Targeting DdRNAi Therapeutic (Lung Cancer), Benitec/Children's Cancer Institute Australia For Medical Research",
    ],
  },
  {
    primary_id: "D07IQM",
    names: ["PMID30273516-Compound-38"],
  },
  {
    primary_id: "D07IQR",
    names: [
      "3-[3-(3-Benzyloxy-Pyridin-2-Yl)-Ureido]-Benzamide",
      "CHEMBL201394",
      "BDBM50181479",
      "3-[(3-Benzyloxy-2-Pyridyl)Carbamoylamino]benzamide",
      "Benzamide, 3-[[[[3-(Phenylmethoxy)-2-Pyridinyl]amino]carbonyl]amino]-",
    ],
  },
  {
    primary_id: "D07IQS",
    names: [
      "Atazanavir",
      "198904-31-3",
      "Latazanavir",
      "Zrivada",
      "Reyataz",
      "BMS-232632",
      "BMS 232632",
      "Atazanavir [INN:BAN]",
      "CGP 73547",
      "Atazanavir Base",
      "UNII-QZU4H47A3S",
      "CGP-73547",
      "HSDB 7339",
      "Reyataz (TN)",
      "ATV",
      "QZU4H47A3S",
      "CHEMBL1163",
      "CHEBI:37924",
      "(3S,8S,9S,12S)-3,12-BIS(1,1-DIMETHYLETHYL)-8-HYDROXY-4,11-DIOXO-9-(PHENYLMETHYL)-6-[[4-(2-PYRIDINYL)PHENYL]METHYL]-2,5,6,10,13-PENTAAZATETRADECANEDIOIC ACID DIMETHYL ESTER",
      "NCGC00182552-01",
      "AK174307",
      "DSSTox_CID_28617",
      "DSSTox_RID_82887",
      "DSSTox_GSID_48691",
      "DR7",
      "Atazanavirum",
      "ATZ",
      "Atazanavir (INN)",
      "Reyataz, BMS-232632, Atazanavir",
      "Reyataz(TM) (*1:1 Sulfate*)",
      "Dimethyl (3S,8S,9S,12S)-9-Benzyl-3,12-Di-Tert-Butyl-8-Hydroxy-4,11-Dioxo-6-[4-(2-Pyridyl)Benzyl]-2,5,6,10,13-Pentaazatetradecanedioate",
      "METHYL [(1S,4S,5S,10S)-4-BENZYL-1,10-DI-TERT-BUTYL-5-HYDROXY-2,9,12-TRIOXO-7-(4-PYRIDIN-2-YLBENZYL)-13-OXA-3,7,8,11-TETRAAZATETRADEC-1-YL]CARBAMATE",
      "Methyl N-[(2S)-1-[2-[(2S,3S)-2-Hydroxy-3-[[(2S)-2-(Methoxycarbonylamino)-3,3-Dimethylbutanoyl]amino]-4-Phenylbutyl]-2-[(4-Pyridin-2-Ylphenyl)Methyl]hydrazinyl]-3,3-Dimethyl-1-Oxobutan-2-Yl]carbamate",
      "(2S)-N-(3-{[(2S)-2-(Methoxycarbonylamino)-3,3-Dimethylbutanoylamino][(4-(2-Pyridyl)Phenyl)Methyl]amino}(1S,2S)-2-Hydroxy-1-Benzylpropyl)-2-(Methoxycarbonylamino)-3,3-Dimethylbutanamide",
    ],
  },
  {
    primary_id: "D07IQU",
    names: ["Micrococcin"],
  },
  {
    primary_id: "D07IRF",
    names: ["Prasugrel", "Effient (TN)"],
  },
  {
    primary_id: "D07IRG",
    names: ["Calcium Polycarbophil", "Colonel", "Polyful", "HSR-237"],
  },
  {
    primary_id: "D07IRQ",
    names: [
      "P-Anisic Acid",
      "4-METHOXYBENZOIC ACID",
      "100-09-4",
      "ANISIC ACID",
      "P-Methoxybenzoic Acid",
      "Draconic Acid",
      "4-Anisic Acid",
      "Benzoic Acid, 4-Methoxy-",
      "ANISIC ACID, PARA",
      "Para-Anisic Acid",
      "Anisic Acid, P-Isomer",
      "4-Methoxy-Benzoic Acid",
      "Kyselina 4-Methoxybenzoova",
      "UNII-4SB6Y7DMM3",
      "P-Methoxy Benzoic Acid",
      "4-Methoxy Benzoic Acid",
      "Methoxybenzoic Acid",
      "Kyselina 4-Methoxybenzoova [Czech]",
      "EINECS 202-818-5",
      "NSC 32742",
      "1335-08-6",
      "BRN 0508910",
      "4SB6Y7DMM3",
      "AI3-00893",
      "CHEBI:40813",
      "ZEYHEAKUIGZSGI-UHFFFAOYSA-N",
      "P-Methoxybenzoate",
      "P-Anisic Acid, 98%",
    ],
  },
  {
    primary_id: "D07IRZ",
    names: ["1,1-Diphenyl-3-(Phenylsulfonyl)Propan-2-One", "CHEMBL239469"],
  },
  {
    primary_id: "D07ISD",
    names: ["AFPep", "AFPep (Oral/ Intravail, Breast Cancer)"],
  },
  {
    primary_id: "D07ISE",
    names: ["Interferon Alpha"],
  },
  {
    primary_id: "D07ISL",
    names: [
      "D-210",
      "CHEMBL468016",
      "BDBM50261278",
      "N6-(2-(4-(2-Methoxyphenyl)Piperazin-1-Yl)Ethyl)-N6-Propyl-4,5,6,7-Tetrahydrobenzo[d]thiaxole-2,6-Diamine",
      "(+)-N6-(2-(4-(2-Methoxyphenyl)Piperazin-1-Yl)Ethyl)-N6-Propyl-4,5,6,7-Tetrahydrobenzo[d]thiazole-2,6-Diamine",
    ],
  },
  {
    primary_id: "D07ISO",
    names: ["VariZIG"],
  },
  {
    primary_id: "D07ITH",
    names: ["4SCAR19 Cells"],
  },
  {
    primary_id: "D07ITP",
    names: ["AMG7703", "AMG 7703", "AMG-7703"],
  },
  {
    primary_id: "D07IUE",
    names: ["8-Cyclopentylmethoxy-Quinolin-2-Ylamine", "CHEMBL186770", "SCHEMBL5883835"],
  },
  {
    primary_id: "D07IUN",
    names: ["HyperAcute Vaccine"],
  },
  {
    primary_id: "D07IUX",
    names: [
      "2-Amino-6-(3-Methoxy-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(3-Methoxyphenylthio)Benzenecarbonitrile",
      "AC1LAE02",
      "CHEMBL51738",
      "BDBM1733",
      "SCHEMBL8640275",
      "ZINC20465",
      "CTK7A9945",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1c",
      "2-Amino-6-(3-Methoxyphenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(3-Methoxyphenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D07IVD",
    names: ["N-(6-Benzyl-1H-Indazol-3-Yl)Butyramide", "CHEMBL1077260", "SCHEMBL6490520"],
  },
  {
    primary_id: "D07IVS",
    names: ["Gerilimzumab"],
  },
  {
    primary_id: "D07IWD",
    names: [
      "2-(2-Methoxy-Benzoyl)-Cyclohexane-1,3-Dione",
      "CHEMBL167062",
      "2-(2-Methoxybenzoyl)Cyclohexane-1,3-Dione",
      "SCHEMBL10192397",
    ],
  },
  {
    primary_id: "D07IWK",
    names: ["9-Methylguanine"],
  },
  {
    primary_id: "D07IWZ",
    names: ["(R)-2,11-Diaminoaporphine", "CHEMBL455051"],
  },
  {
    primary_id: "D07IXD",
    names: ["P-0654"],
  },
  {
    primary_id: "D07IXP",
    names: ["Example 20 (WO2010128058)"],
  },
  {
    primary_id: "D07IYC",
    names: ["TDI-0067"],
  },
  {
    primary_id: "D07IYH",
    names: [
      "NSC-407228",
      "5-Hydroxy-2-(4-Hydroxyphenyl)-7-Methoxychroman-4-One",
      "2957-21-3",
      "520-29-6",
      "CHEMBL74852",
      "NSC407228",
      "5-Hydroxy-2-(4-Hydroxy-Phenyl)-7-Methoxy-Chroman-4-One",
      "5-Hydroxy-2-(4-Hydroxyphenyl)-7-Methoxy-2,3-Dihydrochromen-4-One",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-5-Hydroxy-2-(4-Hydroxyphenyl)-7-Methoxy-",
      "Sakauranetin",
      "AC1L88VP",
      "MLS000876793",
      "SCHEMBL555541",
      "MEGxp0_000557",
      "ACon1_000001",
      "CTK7A0392",
      "DJOJDHGQRNZXQQ-UHFFFAOYSA-N",
      "MolPort-000-165-376",
      "HMS2271F13",
      "BDBM50049387",
      "ANW-46713",
    ],
  },
  {
    primary_id: "D07IYX",
    names: ["PMID26924192-Compound-20"],
  },
  {
    primary_id: "D07IYZ",
    names: ["[3H](R)-NPTS", "[3H]-(R)-NPTS"],
  },
  {
    primary_id: "D07IZC",
    names: ["Ring-Fused Compound 1"],
  },
  {
    primary_id: "D07IZF",
    names: ["Sch-57790"],
  },
  {
    primary_id: "D07IZG",
    names: ["GSK4112"],
  },
  {
    primary_id: "D07IZP",
    names: ["ND-401"],
  },
  {
    primary_id: "D07IZR",
    names: ["AMG-126737"],
  },
  {
    primary_id: "D07IZS",
    names: ["ITE"],
  },
  {
    primary_id: "D07JAG",
    names: ["ABT-494"],
  },
  {
    primary_id: "D07JAI",
    names: ["NSC-639829"],
  },
  {
    primary_id: "D07JAN",
    names: ["SCH 206272"],
  },
  {
    primary_id: "D07JBE",
    names: [
      "CG-200745",
      "CG-0006",
      "CG-005",
      "HDAC Inhibitors (Cancer, Oral/Intravenous), CrystalGenomics",
      "Histone Deacetylase Inhibitors (Cancer, Oral/Intravenous), CrystalGenomics",
    ],
  },
  {
    primary_id: "D07JCA",
    names: [
      "7-O-B-D-Glucopyranosyl-A-Homonojirimycin",
      "CHEMBL478209",
      "NSC630254",
      "104343-33-1",
      "MDL-25637",
      "AC1L9X0M",
      "SCHEMBL305712",
      "BDBM50263042",
      "2,6-Dideoxy-2,6-Imino-7-O-.beta.-D-Glucopyranosyl-D-Glycero-L-Gulo-Heptitol Hydrochloride Hydrate",
      "(2R,3R,4S,5S,6R)-2-(Hydroxymethyl)-6-[[(2R,3R,4S,5S,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxymethyl]piperidine-3,4,5-Triol",
      ".beta.-D-Glucopyranoside, [(2R,3S,4S,5R,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)-2-Piperidinyl]methyl",
    ],
  },
  {
    primary_id: "D07JCI",
    names: ["MCHR1 Antagonists"],
  },
  {
    primary_id: "D07JCO",
    names: [
      "3-Imidazol-1-Yl-Quinoline",
      "MLS003269485",
      "SCHEMBL4514468",
      "CHEMBL193556",
      "BDBM8919",
      "3-(1h-Imidazol-1-Yl)Quinoline",
      "3-(1H-Imidazole-1-Yl)Quinoline",
      "Imidazole-Substituted Quinoline 26",
      "ZINC13674486",
      "Quinoline, 3-(1H-Imidazol-1-Yl)-",
      "63822-85-5",
    ],
  },
  {
    primary_id: "D07JCU",
    names: ["PRS-040"],
  },
  {
    primary_id: "D07JDC",
    names: [
      "Carvacrol",
      "CARVACROL",
      "5-Isopropyl-2-Methylphenol",
      "499-75-2",
      "Isopropyl-O-Cresol",
      "Karvakrol",
      "O-Thymol",
      "Antioxine",
      "5-Isopropyl-O-Cresol",
      "2-P-Cymenol",
      "2-Hydroxy-P-Cymene",
      "Isothymol",
      "Phenol, 2-Methyl-5-(1-Methylethyl)-",
      "P-Cymen-2-Ol",
      "2-Methyl-5-Isopropylphenol",
      "3-Isopropyl-6-Methylphenol",
      "P-Cymene, 2-Hydroxy-",
      "5-Isopropyl-2-Methyl-Phenol",
      "2-Methyl-5-(Propan-2-Yl)Phenol",
      "2-Methyl-5-(1-Methylethyl)Phenol",
      "O-Cresol, 5-Isopropyl-",
      "1-Hydroxy-2-Methyl-5-Isopropylbenzene",
      "6-Methyl-3-Isopropylphenol",
      "Phenol, 5-Isopropyl-2-Methyl-",
      "Cymene-2-Ol, P-",
    ],
  },
  {
    primary_id: "D07JDD",
    names: ["ARTORIGIDIN A", "Artorigidin A", "CHEMBL1094756"],
  },
  {
    primary_id: "D07JDR",
    names: ["LRN-672"],
  },
  {
    primary_id: "D07JDU",
    names: [
      "SB-706375",
      "733734-61-7",
      "SB 706375",
      "CHEMBL522770",
      "Benzenesulfonamide,2-Bromo-4,5-Dimethoxy-N-[3-[[(3R)-1-Methyl-3-Pyrrolidinyl]oxy]-4-(Trifluoromethyl)Phenyl]-",
      "SCHEMBL5835165",
      "GTPL2165",
      "MolPort-035-765-788",
      "ZINC1553794",
      "BDBM50249878",
      "AKOS024458280",
      "NCGC00379177-01",
      "SB706375",
      "KB-75256",
      "B5677",
      "2-Bromo-4,5-Dimethoxy-N-[3-[[(3R)-1-Methyl-3-Pyrrolidinyl]oxy]-4-(Trifluoromethyl)Phenyl]benzenesulfonamide",
      "2-Bromo-4,5-Dimethoxy-N-[3-[(3R)-1-Methylpyrrolidin-3-Yl]oxy-4-(Trifluoromethyl)Phenyl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D07JDY",
    names: [
      "3-((E)-Styryl)-Quinoline",
      "CHEMBL65858",
      "(E)-3-Styrylquinoline",
      "3-[(E)-Styryl]quinoline",
      "SCHEMBL3164863",
      "ZINC3832235",
      "BDBM50039053",
    ],
  },
  {
    primary_id: "D07JEW",
    names: ["Human Coagulation Factor X"],
  },
  {
    primary_id: "D07JEY",
    names: ["IDD552"],
  },
  {
    primary_id: "D07JFD",
    names: ["ISIS 119263"],
  },
  {
    primary_id: "D07JFE",
    names: ["CVT-427"],
  },
  {
    primary_id: "D07JFU",
    names: ["CGEN-712"],
  },
  {
    primary_id: "D07JFY",
    names: ["PMID29473428-Compound-43"],
  },
  {
    primary_id: "D07JGH",
    names: ["UR-13756"],
  },
  {
    primary_id: "D07JGT",
    names: [
      "Nalidixic Acid",
      "Betaxina",
      "Cybis",
      "Dixiben",
      "Dixinal",
      "Eucistin",
      "Innoxalon",
      "Jicsron",
      "Kusnarin",
      "Nalidicron",
      "Nalidixan",
      "Nalidixane",
      "Nalidixate",
      "Nalidixic",
      "Nalidixin",
      "Nalitucsan",
      "Nalix",
      "Nalurin",
      "Narigix",
      "Naxuril",
      "NegGram",
      "Negram",
      "Nevigramon",
      "Nicelate",
      "Nogram",
      "Poleon",
      "Sicmylon",
      "Specifen",
      "Specifin",
      "Unaserus",
      "Uralgin",
      "Uriben",
      "Uriclar",
      "Urisal",
      "Urodixin",
      "Uroman",
      "Uroneg",
      "Uronidix",
      "Uropan",
      "Wintomylon",
      "Wintron",
      "Acide Nalidixico",
      "Acide Nalidixico [Italian]",
      "Acide Nalidixique",
      "Acide Nalidixique [French]",
      "Acido Nalidissico",
      "Acido Nalidissico [DCIT]",
      "Acido Nalidixico",
      "Acidum Nalidixicum",
      "NALIDIXATE SODIUM",
      "Naladixic Acid",
      "Naldixic Acid",
      "Nalidic Acid",
      "Nalidixinic Acid",
      "Nalidixic Acid USP27",
      "WIN 183203",
      "Acid, Nalidixic",
      "Acide Nalidixique [INN-French]",
      "Acido Nalidixico [INN-Spanish]",
      "Acidum Nalidixicum [INN-Latin]",
      "N-1200",
      "NegGram (TN)",
      "Neggram (TN)",
      "Sodium Nalidixic Acid, Anhydrous",
      "Sodium Nalidixic Acid, Monohydrate",
      "Sodium,Nalidixate",
      "WIN 18,320",
      "WIN-18320",
      "Wil 18,320",
      "Wintomylon (TN)",
      "ZERO/002632",
      "WIN-18320 (TN)",
      "Nalidixic Acid (JP15/USP/INN)",
      "Nalidixic Acid [USAN:INN:BAN:JAN]",
      "Acide 1-Etil-7-Metil-1,8-Naftiridin-4-One-3-Carbossilico",
      "Acide 1-Etil-7-Metil-1,8-Naftiridin-4-One-3-Carbossilico [Italian]",
      "1,4-Dihydro-1-Ethyl-7-Methyl-1,8-Naphthyridin-4-One-3-Carboxylic Acid",
      "1,4-Dihydro-1-Ethyl-7-Methyl-4-Oxo-1,8-Naphthyridine-3-Carboxylic Acid",
      "1-Aethyl-7-Methyl-1,8-Naphthyridin-4-On-3-Karbonsaeure",
      "1-Aethyl-7-Methyl-1,8-Naphthyridin-4-On-3-Karbonsaeure [German]",
      "1-Ethyl-1,4-Dihydro-7-Methyl-4-Oxo-1,8-Naphthyridine-3-Carboxilic Acid",
      "1-Ethyl-1,4-Dihydro-7-Methyl-4-Oxo-1,8-Naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-7-Methyl-1,4-Dihydro-1,8-Naphthyridin-4-One-3-Carboxylic Acid",
      "1-Ethyl-7-Methyl-4-Oxo-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-7-Methyl-4-Oxo-1,4-Dihydro-[1,8]naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-7-Methyl-1,4-Dihydro-1,8-Naphthyridin-4-One-3-Ca Rboxylic Acid",
      "1-Ethyl-7-Methyl-4-Oxo-1,8-Naphthyridine-3-Carboxylic Acid",
      "3-Carboxy-1-Ethyl-7-Methyl-1,8-Naphthidin-4-One",
      "3-Carboxy-1-Ethyl-7-Methyl-1,8-Naphthyridin-4-One",
    ],
  },
  {
    primary_id: "D07JHD",
    names: ["VUF5391"],
  },
  {
    primary_id: "D07JHG",
    names: ["6-(3-Amino-Benzyloxy)-9H-Purin-2-Ylamine"],
  },
  {
    primary_id: "D07JHH",
    names: ["Meclocycline"],
  },
  {
    primary_id: "D07JHJ",
    names: ["IGANIDIPINE HYDROCHLORIDE"],
  },
  {
    primary_id: "D07JHK",
    names: ["AMG 151"],
  },
  {
    primary_id: "D07JHV",
    names: ["Carimune NF"],
  },
  {
    primary_id: "D07JHY",
    names: ["ISIS 7853"],
  },
  {
    primary_id: "D07JID",
    names: ["JCAR014"],
  },
  {
    primary_id: "D07JIN",
    names: ["FMX103"],
  },
  {
    primary_id: "D07JIQ",
    names: ["H-Aba-Gly-Gly-Phe-Leu-OH", "CHEMBL247759"],
  },
  {
    primary_id: "D07JJC",
    names: ["DPC-684"],
  },
  {
    primary_id: "D07JJS",
    names: [
      "Sulfamethizole",
      "Ayerlucil",
      "Famet",
      "Lucosil",
      "Methazol",
      "Microsul",
      "Proklar",
      "Renasul",
      "Rufol",
      "SULFAMETHIAZOLE",
      "Salimol",
      "Solfametizolo",
      "Sulamethizole",
      "Sulfamethazole",
      "Sulfamethizol",
      "Sulfamethizolum",
      "Sulfamethylthiadiazole",
      "Sulfametizol",
      "Sulfapyelon",
      "Sulfstat",
      "Sulfurine",
      "Sulphamethizole",
      "Sulphamethyltiadiazole",
      "Tetracid",
      "Thidicur;Thiosulfil",
      "Ultrasul",
      "Urocydal",
      "Urodiaton",
      "Urolucosil",
      "Urosulfin",
      "Uroz",
      "Solfametizolo [DCIT]",
      "Sulfa Gram",
      "Thiosulfil Forte",
      "RP 2145",
      "VK 53",
      "Sulfamethizolum [INN-Latin]",
      "Sulfametizol [INN-Spanish]",
      "Thiosulfil (TN)",
      "Sulfamethizole [USAN:INN:JAN]",
      "Thiosulfil-A-Forte",
      "Sulfamethizole (JP15/USP/INN)",
      "N1-(5-Methyl-1,3,4-Thiadiazol-2-Yl)Sulfanilamide",
      "N(1)-(5-Methyl-1,3,4-Thiadiazol-2-Yl)Sulfanilamide",
      "N(Sup 1)-(5-Methyl-1,3,4-Thiadiazol-2-Yl)Sulfanilamide",
      "N(Sup 1)-(5-Methyl-1,3,4-Thiadiazol-2-Yl)-Sulfanilamide",
      "2-(P-Aminobenzenesulfonamido)-5-Methylthiadiazole",
      "2-Methyl-5-Sulfanilamido-1,3,4-Thiadiazole",
      "2-Sulfanilamido-5-Methyl-1,3,4-Thiadiazole",
      "4-Amino-N-(5-Methyl-1,3,4-Thiadiazol-2-Yl)Benzene-1-Sulfonamide",
      "4-Amino-N-(5-Methyl-1,3,4-Thiadiazol-2-Yl)Benzenesulfonamide",
      "4-Amino-N-[5-Methyl-1,3,4-Thiadiazol-2-Yl]benzenesulfonamide",
      "5-Methyl-2-Sulfanilamido-1,3,4-Thiadiazole",
    ],
  },
  {
    primary_id: "D07JJT",
    names: ["Pyrimidine Derivative 31"],
  },
  {
    primary_id: "D07JKA",
    names: ["SEN-1500"],
  },
  {
    primary_id: "D07JKB",
    names: ["2-(6-Morpholino-9H-Purin-2-Yl)Phenol"],
  },
  {
    primary_id: "D07JKK",
    names: ["3,4-Bis(3,4-Dimethoxyphenyl)Furan-2(5H)-One", "CHEMBL1098382"],
  },
  {
    primary_id: "D07JKY",
    names: ["KP-496"],
  },
  {
    primary_id: "D07JLC",
    names: ["3-Iodo-N-(6-Methylpyridin-2-Yl)Benzamide", "CHEMBL210550", "AC1MPEXW", "Oprea1_504239", "BDBM50186325"],
  },
  {
    primary_id: "D07JLM",
    names: ["Alagebrium Chloride"],
  },
  {
    primary_id: "D07JLS",
    names: ["E5700", "E 5700", "E-5700"],
  },
  {
    primary_id: "D07JLV",
    names: ["Z-Ala-Leu-Lle-Agly-Ile-Val-NHBzl"],
  },
  {
    primary_id: "D07JMB",
    names: [
      "8-Aza-11-Deoxyprostaglandin E1",
      "CHEMBL42027",
      "BDBM50142481",
      "7-[(R)-2-((E)-(S)-3-Hydroxy-Oct-1-Enyl)-5-Oxo-Pyrrolidin-1-Yl]-Heptanoic Acid",
    ],
  },
  {
    primary_id: "D07JMG",
    names: ["Boronic Acid Derivative 4"],
  },
  {
    primary_id: "D07JMJ",
    names: ["PMID15724976C1", "AC1NQPIE", "GTPL7507"],
  },
  {
    primary_id: "D07JMN",
    names: ["1-(Pyridin-2-Yl)Piperazine", "1-Pyridin-2-Yl-Piperazine"],
  },
  {
    primary_id: "D07JMO",
    names: [
      "1-(3-Bromobenzyl)-1H-Imidazole",
      "72459-47-3",
      "1-(3-Bromobenzyl)Imidazole",
      "CHEMBL598391",
      "1H-Imidazole,1-[(3-Bromophenyl)Methyl]-",
      "1-[(3-Bromophenyl)Methyl]imidazole",
      "SCHEMBL1848631",
      "CTK5D6138",
      "[(3-Bromophenyl)Methyl]imidazole",
      "DTXSID00436926",
      "BGYZLOIBJCHCTI-UHFFFAOYSA-N",
      "MolPort-000-143-256",
      "SBB098740",
      "ZINC12370275",
      "BDBM50307216",
      "AKOS013124377",
      "RP05747",
      "KB-63888",
      "1-[(3-Bromophenyl)Methyl]-1H-Imidazole",
      "AJ-61784",
      "DB-074567",
      "ST24037191",
      "Y9919",
      "FT-0708606",
      "1-(3-Bromobenzyl)-1H-Imidazole, AldrichCPR",
      "J-503102",
      "Z1532751877",
    ],
  },
  {
    primary_id: "D07JMX",
    names: ["XEN-600"],
  },
  {
    primary_id: "D07JNJ",
    names: ["PMID25666693-Compound-17"],
  },
  {
    primary_id: "D07JNQ",
    names: ["NSC-73410"],
  },
  {
    primary_id: "D07JOS",
    names: ["NS-004"],
  },
  {
    primary_id: "D07JOW",
    names: ["XTL-Pseudomonas-Mab"],
  },
  {
    primary_id: "D07JOZ",
    names: ["1,1':4',1''-Terphenyl-3,3''-Diol", "[1,1':4',1'']Terphenyl-3,3''-Diol"],
  },
  {
    primary_id: "D07JPC",
    names: ["Arbekacin"],
  },
  {
    primary_id: "D07JPK",
    names: ["ETX-1153b", "Amersib", "Antiviral+antifungal (Intravenous, MRSA Infection), E-Therapeutics"],
  },
  {
    primary_id: "D07JPL",
    names: ["RT-401", "BoNTA Protein (Topical, Rhinitis), Revance"],
  },
  {
    primary_id: "D07JQO",
    names: ["SB 249417"],
  },
  {
    primary_id: "D07JQX",
    names: ["PMID25991433-Compound-D2"],
  },
  {
    primary_id: "D07JRA",
    names: ["3-Isopropyl-4-Phenylnaphthalene-1,2-Dione", "CHEMBL207833"],
  },
  {
    primary_id: "D07JRF",
    names: ["Influenza A Virus H1N1 Vaccine"],
  },
  {
    primary_id: "D07JRL",
    names: ["Epithalon"],
  },
  {
    primary_id: "D07JRV",
    names: ["2-(Biphenyl-2-Ylthio)-N,N-Dimethylethanamine", "CHEMBL607584", "ZINC45387798"],
  },
  {
    primary_id: "D07JRW",
    names: [
      "Jaspic Acid",
      "(-)-Jaspic Acid",
      "CHEMBL180947",
      "BDBM50240937",
      "4-Hydroxy-3-[(1S,4aS,5R,8aS)-2,5,8a-Trimethyl-5-(4-Methyl-Pent-3-Enyl)-1,4,4a,5,6,7,8,8a-Octahydro-Naphthalen-1-Ylmethyl]-Benzoic Acid",
    ],
  },
  {
    primary_id: "D07JSI",
    names: ["Salicylihalamide A"],
  },
  {
    primary_id: "D07JSK",
    names: ["Sulotroban", "BM-13177", "SKF-95587"],
  },
  {
    primary_id: "D07JSX",
    names: ["IL-2 PDNA"],
  },
  {
    primary_id: "D07JTD",
    names: ["NSC-645809"],
  },
  {
    primary_id: "D07JTE",
    names: ["Tetra-Hydro-Pyrazolopyrazine Derivative 1"],
  },
  {
    primary_id: "D07JTH",
    names: ["MM-Q01", "Bothdel"],
  },
  {
    primary_id: "D07JTJ",
    names: ["Pyrazole Derivative 87"],
  },
  {
    primary_id: "D07JTZ",
    names: ["UCM-5600"],
  },
  {
    primary_id: "D07JUL",
    names: ["JR-032"],
  },
  {
    primary_id: "D07JVL",
    names: [
      "Diazepam",
      "Valium",
      "439-14-5",
      "Diazemuls",
      "Ansiolisina",
      "Sibazon",
      "Relanium",
      "Faustan",
      "Apaurin",
      "Stesolid",
      "Seduxen",
      "Methyldiazepinone",
      "Valitran",
      "Tranqdyn",
      "Seduksen",
      "Relaminal",
      "Quiatril",
      "Quetinil",
      "Dipezona",
      "Diazetard",
      "Diazepan",
      "Calmpose",
      "Tranimul",
      "Tensopam",
      "Serenack",
      "Paranten",
      "Kiatrium",
      "Domalium",
      "Condition",
      "Bialzepam",
      "Ansiolin",
      "Unisedil",
      "Stesolin",
      "Serenamin",
      "Ruhsitus",
      "Renborin",
      "Quievita",
      "Tranquirit",
      "Serenzin",
      "Neurolytril",
      "Liberetas",
      "Diacepan",
      "Ceregulart",
      "Calmocitene",
      "Bensedin",
      "Atensine",
      "Apozepam",
      "Umbrium",
      "Sonacon",
      "Sedipam",
      "Levium",
      "Diapam",
      "Atilen",
      "Assival",
      "Amiprol",
      "Diazepam (Intranasal, Epilepsy)",
    ],
  },
  {
    primary_id: "D07JVO",
    names: ["Fosbretabulin"],
  },
  {
    primary_id: "D07JVS",
    names: ["Cephalosporin", "Cephalothin (TN)"],
  },
  {
    primary_id: "D07JVT",
    names: ["GWN323"],
  },
  {
    primary_id: "D07JWO",
    names: ["IL-21", "BMS-982470"],
  },
  {
    primary_id: "D07JWT",
    names: ["Perazine"],
  },
  {
    primary_id: "D07JXC",
    names: ["[18F]fluoroethylflumazenil", "[18F]fluoroethylflumazenil (PET Ligand)"],
  },
  {
    primary_id: "D07JXN",
    names: ["SPP-600"],
  },
  {
    primary_id: "D07JXR",
    names: ["IMD-4852"],
  },
  {
    primary_id: "D07JYF",
    names: [
      "6-Nitro-Benzothiazole-2-Sulfonic Acid Amide",
      "CHEMBL186335",
      "6-Nitro-2-Benzothiazolesulfonamide",
      "SCHEMBL10347981",
      "VDPWQQORTOWPSP-UHFFFAOYSA-N",
      "6-Nitrobenzothiazole-2-Sulfonamide",
      "BDBM50155503",
    ],
  },
  {
    primary_id: "D07JZB",
    names: ["RwFwLL-NH2", "CHEMBL500703"],
  },
  {
    primary_id: "D07JZF",
    names: ["Netilmicin"],
  },
  {
    primary_id: "D07JZS",
    names: ["H-Tyr-C[D-Allylgly-Gly-Phe-L-Allylgly]NH2", "CHEMBL220333"],
  },
  {
    primary_id: "D07JZT",
    names: ["LA-EP2006"],
  },
  {
    primary_id: "D07KAB",
    names: ["AST-VAC1"],
  },
  {
    primary_id: "D07KAK",
    names: [
      "OSH-101",
      "Alopecia Treatment, Neosil",
      "Alopecia Treatment, OsteoScreen",
      "Alopecia Treatment, Peplin",
      "NEOSH-101",
    ],
  },
  {
    primary_id: "D07KAQ",
    names: [
      "L19-IL-2 Fusion Protein",
      "Darleukin",
      "Immunocytokine Fusion Protein, Bayer Schering",
      "Immunocytokine Fusion Protein, Philogen",
      "L19-IL-2 Fusion Protein, Bayer Schering",
      "L19-IL-2 Fusion Protein, Philogen",
    ],
  },
  {
    primary_id: "D07KBF",
    names: [
      "N5-[4-(2-Methylphenoxy)Phenyl]-L-Glutamamide",
      "CHEMBL478983",
      "Modified Amino Acid Analog, 9c",
      "BDBM24280",
    ],
  },
  {
    primary_id: "D07KBJ",
    names: ["Renin Inhibitors"],
  },
  {
    primary_id: "D07KBM",
    names: [
      "AZD4573",
      "AVIWDYSJSPOOAR-LSDHHAIUSA-N",
      "2057509-72-3",
      "SCHEMBL18338402",
      "HY-112088",
      "CS-0043316",
      "AZD-4573,AZD4573,AZD 4573",
      "(1S,3R)-3-Acetamido-N-(5-Chloro-4-(5,5-Dimethyl-5,6-Dihydro-4H-Pyrrolo[1,2-B]pyrazol-3-Yl)Pyridin-2-Yl)Cyclohexanecarboxamide",
    ],
  },
  {
    primary_id: "D07KBZ",
    names: ["Dioleoylphosphatidic Acid", "DoPA", "Dioleoyl Phosphatidic Acid"],
  },
  {
    primary_id: "D07KCA",
    names: ["Aryl Mannoside Derivative 22"],
  },
  {
    primary_id: "D07KCC",
    names: ["Butyl-Methyl-Prop-2-Ynyl-Amine Hydrochloride"],
  },
  {
    primary_id: "D07KDB",
    names: ["4-Methyl-5,6-Dihydro-Pyrido[1,2-A]quinolin-3-One", "CHEMBL124849", "SCHEMBL6942608", "ZINC27211803"],
  },
  {
    primary_id: "D07KDE",
    names: ["Fusafungine"],
  },
  {
    primary_id: "D07KDH",
    names: ["[Sar9,Met(O2)11]-SP"],
  },
  {
    primary_id: "D07KDI",
    names: [
      "AR-42",
      "935881-37-1",
      "OSU-HDAC42",
      "AR 42",
      "(S)-HDAC-42",
      "(S)-N-Hydroxy-4-(3-Methyl-2-Phenylbutanamido)Benzamide",
      "UNII-E0GG29V0AQ",
      "(S)-HDAC 42",
      "E0GG29V0AQ",
      "AR42",
      "HDAC-42",
      "AR-42 (HDAC-42)",
      "N-Hydroxy-4-{[(2S)-3-Methyl-2-Phenylbutanoyl]amino}benzamide",
      "N-Hydroxy-4-[[(2S)-3-Methyl-2-Phenylbutanoyl]amino]benzamide",
      "AR-42 (OSU-HDAC42)",
      "AC1OCFYN",
      "Cc-20",
      "OSU-HDAC 42",
      "MLS006011089",
      "CHEMBL191482",
      "SCHEMBL2348844",
      "OSU-42",
      "DTXSID4042678",
      "NOCAS_42678",
      "CTK8C1969",
      "AOB3995",
      "N-Hydroxy-4-(3-Methyl-2-(S)Phenyl-Butyrylamino)Benzamide",
      "MolPort-009-019-571",
    ],
  },
  {
    primary_id: "D07KEH",
    names: ["AKB-6548"],
  },
  {
    primary_id: "D07KER",
    names: ["Tetra-Hydro-Pyrazolopyrazine Derivative 3"],
  },
  {
    primary_id: "D07KEV",
    names: [
      "5PAM523",
      "SCHEMBL600403",
      "GTPL6414",
      "JOBPCEDWVKYVKZ-OCCSQVGLSA-N",
      "5-Fluoro-2-{3-[(3S,6R)-1-[(4-Fluorophenyl)Carbonyl]-6-Methylpiperidin-3-Yl]-1,2,4-Oxadiazol-5-Yl}pyridine",
      "4-Fluorophenyl((2r,5s)-5-[5-(5-Fluoropyridin-2-Yl)-1,2,4-Oxadiazol-3-Yl]-2-Methylpiperidin-1-Yl)Methanone",
    ],
  },
  {
    primary_id: "D07KFB",
    names: [
      "Varespladib Methyl",
      "A-002",
      "LY-333013",
      "S-3013",
      "Varespladib Methyl (Oral Formulation, Coronary Artery Disease)",
      "PLA2 Inhibitors (Inflammation), Lilly/Shionogi",
      "Varespladib (Oral Formulation, Coronary Artery Disease), Anthera",
      "Varespladib Methyl (Oral Formulation, Coronary Artery Disease), Anthera",
    ],
  },
  {
    primary_id: "D07KFG",
    names: ["Human Prothrombin Complex Concentrate"],
  },
  {
    primary_id: "D07KGC",
    names: [
      "Immune Globulin",
      "Anthrax Immune Globulin, Cangene",
      "Immune Globulin (Anthrax)",
      "NP-015",
      "Immune Globulin (Anthrax), Cangene",
    ],
  },
  {
    primary_id: "D07KGR",
    names: ["VI-1121"],
  },
  {
    primary_id: "D07KGV",
    names: ["Anti-HCV Agent", "Anti-HCV Agent, UAI", "Anti-Hepatitis C Virus Agent, UAI"],
  },
  {
    primary_id: "D07KGW",
    names: ["PF-3392455"],
  },
  {
    primary_id: "D07KHH",
    names: [
      "LAS-34273",
      "Bretaris",
      "Eklira",
      "Aclidinium Bromide",
      "LAS-W-330",
      "[1-(3-Phenoxypropyl)-1-Azoniabicyclo[2.2.2]oct-8-Yl] 2-Hydroxy-2,2-Dithiophen-2-Yl-Acetate Bromide",
    ],
  },
  {
    primary_id: "D07KHR",
    names: [
      "PSMA-VRP",
      "Alphavirus Vector Vaccine (Prostate Cancer), Progenics/Cytogen",
      "PSMA Antigen Vaccine (Prostate Cancer), PSMA Co LLC/AlphaVax;PSMA Viral Vector Vaccine (Prostate Cancer), PSMA Co LLC/AlphaVax",
      "PSMA Viral Vector Vaccine (Prostate Cancer), Progenics/CYTOGEN",
      "PSMA Antigen Vaccine (Prostate Cancer), CYTOGEN/Progenics/AlphaVax",
    ],
  },
  {
    primary_id: "D07KIB",
    names: ["4-Fluoroselegiline", "CH-175", "SR-96516", "P-Fluoro-Selegiline", "P-Fluoro-Selegiline Hydrochloride"],
  },
  {
    primary_id: "D07KIE",
    names: ["LYC-30937"],
  },
  {
    primary_id: "D07KIJ",
    names: ["INCB3344"],
  },
  {
    primary_id: "D07KJH",
    names: ["LYMErix"],
  },
  {
    primary_id: "D07KJI",
    names: ["PMID7966163C3f"],
  },
  {
    primary_id: "D07KKQ",
    names: ["Hepatitis A Vaccine"],
  },
  {
    primary_id: "D07KKZ",
    names: [
      "Gallium Ga 68 Dotatate",
      "68Ga-DOTA-TATE",
      "68Ga-DOTA-Tyr3-Octreotate",
      "DOTATATE-Ga68",
      "GA-68 DOTATATE",
      "GalioMedix",
      "NETSPOT",
      "Somakit-TATE",
    ],
  },
  {
    primary_id: "D07KMA",
    names: ["T-Oligos"],
  },
  {
    primary_id: "D07KMI",
    names: ["ACI-518", "AMPA Glutamate Receptor Agonist (Alzheimer's Disease), AC Immune"],
  },
  {
    primary_id: "D07KMK",
    names: ["7-Azaindole Derivative 5"],
  },
  {
    primary_id: "D07KMN",
    names: ["Trimetoquinol"],
  },
  {
    primary_id: "D07KNG",
    names: [
      "Costunolide",
      "Costunolid",
      "Costus Lactone",
      "Costunlide",
      "AC1NSPGS",
      "HRYLQFBHBWLLLL-GYIATTAWSA-N",
      "Cyclodeca[b]furan-2(3H)-One, 3a,4,5,8,9,11a-Hexahydro-6,10-Dimethyl-3-Methylene-, [3aS-(3aR*,6E,10E,11aS*)]-",
      "BRD-A49888826-001-01-8",
      "(6E,10E)-3a,4,5,8,9,11a-Hexahydro-6,10-Dimethyl-3-Methylenecyclodeca[b]furan-2(3H)-One",
      "(E,E)-6-.alpha.-Hydroxygermacra-1(10),4,11(13)-Trien-12-Oic Acid .gamma.-Lactone",
      "Germacra-1(10),4,11(13)-Trien-12-Oic Acid, 6.alpha.-Hydroxy-, .gamma.-Lactone, (E,E)-",
    ],
  },
  {
    primary_id: "D07KNI",
    names: ["Benzodihydroisofuran Derivative 4"],
  },
  {
    primary_id: "D07KNS",
    names: ["FGGFTGARKSARKLKNQ"],
  },
  {
    primary_id: "D07KON",
    names: ["PMID30273516-Compound-22b"],
  },
  {
    primary_id: "D07KOR",
    names: ["INOC-002", "Chemotherapy Potentiator (Colon Cancer), Innovate Oncology"],
  },
  {
    primary_id: "D07KOY",
    names: [
      "RU-59063",
      "155180-53-3",
      "4-(3-(4-Hydroxybutyl)-4,4-Dimethyl-5-Oxo-2-Thioxoimidazolidin-1-Yl)-2-(Trifluoromethyl)Benzonitrile",
      "4-[3-(4-Hydroxybutyl)-4,4-Dimethyl-5-Oxo-2-Thioxoimidazolidin-1-Yl]-2-(Trifluoromethyl)Benzonitrile",
      "RU 59063",
      "CHEMBL331820",
      "AK134187",
      "AC1L53SM",
      "SCHEMBL1714908",
      "CTK4C8567",
      "DTXSID50165850",
      "MolPort-027-950-495",
      "FIDNKDVRTLFETI-UHFFFAOYSA-N",
      "ZINC1538191",
      "BDBM50091400",
      "6064AJ",
      "AKOS016845838",
      "TRA0008920",
      "RU59063",
      "DS-4755",
      "SY008557",
      "CJ-05279",
      "AJ-26835",
    ],
  },
  {
    primary_id: "D07KQB",
    names: ["PMID29671355-Compound-47c"],
  },
  {
    primary_id: "D07KSG",
    names: ["Tamoxifen"],
  },
  {
    primary_id: "D07KSR",
    names: ["11-Cis-Retinal", "11-Cis-Retinaldehyde"],
  },
  {
    primary_id: "D07KTA",
    names: [
      "Ab-01",
      "Ab-02",
      "IL-21 Receptor-Targeted Human Monoclonal Antibodies (Inflammation)",
      "Anti-IL-21R MAb (Inflammation), Pfizer",
      "IL-21 Receptor-Targeted Human Monoclonal Antibodies (Inflammation), Pfizer",
    ],
  },
  {
    primary_id: "D07KTF",
    names: ["Salvinorin B Ethoxymethyl Ether", "CHEMBL272939"],
  },
  {
    primary_id: "D07KTM",
    names: ["Quinoline Carboxamide Derivative 9"],
  },
  {
    primary_id: "D07KTP",
    names: [
      "SCH79687",
      "CHEMBL366977",
      "224585-45-9",
      "SCHEMBL7860131",
      "BDBM86188",
      "DTXSID20430941",
      "SCH 79687",
      "PDSP1_001301",
      "SCH-79687",
      "PDSP2_001285",
      "L018853",
    ],
  },
  {
    primary_id: "D07KUN",
    names: ["RAD-1901"],
  },
  {
    primary_id: "D07KVD",
    names: ["ARGX-109"],
  },
  {
    primary_id: "D07KVF",
    names: [
      "Cyclohexylamine",
      "CYCLOHEXYLAMINE",
      "Cyclohexanamine",
      "108-91-8",
      "Aminocyclohexane",
      "Hexahydroaniline",
      "Hexahydrobenzenamine",
      "Aminohexahydrobenzene",
      "1-Cyclohexylamine",
      "Cyclohexyl Amine",
      "1-Aminocyclohexane",
      "Aniline, Hexahydro-",
      "Benzenamine, Hexahydro-",
      "Cyclohexylamines",
      "Aminocylcohexane",
      "Cyclohexyl-Amine",
      "UNII-I6GH4W7AEG",
      "1-AMINO-CYCLOHEXANE",
      "CCRIS 3645",
      "HSDB 918",
      "Cyclohexylamine.HCl",
      "EINECS 203-629-0",
      "UN2357",
      "I6GH4W7AEG",
      "BRN 0471175",
      "Cyclohexylamin",
      "AI3-15323",
      "CHEBI:15773",
      "PAFZNILMFXTMIY-UHFFFAOYSA-N",
      "Cyclohexylamine [UN2357] [Corrosive]",
      "Cyclohexylamine, 99%",
    ],
  },
  {
    primary_id: "D07KVL",
    names: ["PMID28270021-Compound-WO2010077680 811"],
  },
  {
    primary_id: "D07KVU",
    names: ["PMID25991433-Compound-C1"],
  },
  {
    primary_id: "D07KWN",
    names: ["Isosteviol"],
  },
  {
    primary_id: "D07KWO",
    names: [
      "RU-43044",
      "CHEMBL78704",
      "SCHEMBL14289561",
      "136959-96-1",
      "BDBM50113782",
      "RU43044",
      "17-Hydroxy-13-Methyl-10-(4-Methyl-Benzyl)-17-Prop-1-Ynyl-1,2,6,7,8,9,10,11,12,13,14,15,16,17-Tetradecahydro-Cyclopenta[a]phenanthren-3-One(RU-43044)",
    ],
  },
  {
    primary_id: "D07KWZ",
    names: ["L-367,773"],
  },
  {
    primary_id: "D07KXE",
    names: ["Carboron Cluster With Phenol"],
  },
  {
    primary_id: "D07KXK",
    names: [
      "GALLOCATECHIN GALLATE",
      "(-)-Gallocatechin Gallate",
      "4233-96-9",
      "Gallocatechin Gallate",
      "(-)-Gallocatechol Gallate",
      "UNII-IRW3C4Y31Q",
      "(2S,3R)-5,7-Dihydroxy-2-(3,4,5-Trihydroxyphenyl)Chroman-3-Yl 3,4,5-Trihydroxybenzoate",
      "Gallocatechin Gallate (Gcg)",
      "IRW3C4Y31Q",
      "CHEMBL264938",
      "(-)-Gallocatechin-3-O-Gallate",
      "(2S,3R)-2-(3,4,5-Trihydroxyphenyl)-3,4-Dihydro-1(2H)-Benzopyran-3,5,7-Triol 3-(3,4,5-Trihydroxybenzoate)",
      "[(2S,3R)-5,7-Dihydroxy-2-(3,4,5-Trihydroxyphenyl)-3,4-Dihydro-2H-Chromen-3-Yl] 3,4,5-Trihydroxybenzoate",
      "Benzoic Acid, 3,4,5-Trihydroxy-, 3,",
    ],
  },
  {
    primary_id: "D07KXZ",
    names: [
      "5-Phenyl-1,3,4-Oxadiazole-2(3H)-Thione",
      "3004-42-0",
      "5-Phenyl-1,3,4-Oxadiazole-2-Thiol",
      "1,3,4-Oxadiazole-2(3H)-Thione, 5-Phenyl-",
      "2-Phenyl-1,3,4-Oxadiazole-5-Thiol",
      "1,3,4-Oxadiazole-2-Thiol, 5-Phenyl-",
      "EINECS 221-103-9",
      "5-Phenyl-[1,3,4]oxadiazole-2-Thiol",
      "2-Fenil-5-Mercapto-1,3,4-Ossadiazolo",
      "CHEMBL1164316",
      "1,3,4-OXADIAZOLE-5-THIOL, 2-PHENYL-",
      "FOHWXVBZGSVUGO-UHFFFAOYSA-N",
      "2-Fenil-5-Mercapto-1,3,4-Ossadiazolo [Italian]",
      "5-Phenyl-1,3,4-Oxadiazole-2-Thione",
      "2-Phenyl-1,3,4--Oxadiazol-5-Thiol",
    ],
  },
  {
    primary_id: "D07KYI",
    names: ["TBI-4000"],
  },
  {
    primary_id: "D07KYK",
    names: [
      "NK-111",
      "Cardioprotectant (Cardiac Ischemia), Nara Biosciences",
      "Myokine (Cardiac Ischemia), Nara Biosciences",
    ],
  },
  {
    primary_id: "D07KYT",
    names: ["AC220"],
  },
  {
    primary_id: "D07KYU",
    names: [
      "Benactyzine",
      "BENACTYZINE",
      "Benactyzin",
      "Benzactyzine",
      "302-40-9",
      "Diethylaminoethyl Benzilate",
      "Benactizina",
      "Benactizina [DCIT]",
      "Benactizina [Italian]",
      "2-(Diethylamino)Ethyl Benzilate",
      "Benactyzine [INN:BAN]",
      "Benactyzinum [INN-Latin]",
      "Benacticina [INN-Spanish]",
      "2-Diethylaminoethyl Benzilate",
      "UNII-595EG71R3F",
      "2-(Diethylamino)Ethyl Diphenylglycolate",
      "HSDB 3292",
      "Benzilic Acid, 2-(Diethylamino)Ethyl Ester",
      "EINECS 206-123-8",
      "Diphenylglycolic Acid 2-(Diethylamino)Ethyl Ester",
      "BRN 2156821",
      "Benzilic Acid Beta-Diethylaminoethyl Ester",
      "CHEMBL70352",
    ],
  },
  {
    primary_id: "D07KYZ",
    names: [
      "GABA-B Receptor PAM",
      "ADX-1",
      "ADX-1a",
      "ADX-1b",
      "ADX-71441",
      "ADX-1 Program (GABA B Positive Allosteric Modulators), Addex",
      "GABA-B Receptor PAM (Pain/Fragile X Syndrome/Urinary Incontinence/GERD)",
      "GABA-B Receptor PAM (Pain/Fragile X Syndrome/Urinary Incontinence/GERD), Addex",
    ],
  },
  {
    primary_id: "D07KZA",
    names: ["2-(N-Morpholino)-Ethanesulfonic Acid"],
  },
  {
    primary_id: "D07KZL",
    names: ["PMID24900526C5"],
  },
  {
    primary_id: "D07KZR",
    names: ["Heteroaryl-Azepine Derivative 2"],
  },
  {
    primary_id: "D07KZW",
    names: ["JWH-364"],
  },
  {
    primary_id: "D07LAP",
    names: [
      "BMS-184476",
      "10,11,12,12a,12b-Dodecahydro-1H-7,11-Methanocyclodeca[3,4]benz[1,2-B]oxet-9-Yl Ester",
      "7-O-(Methylsulfanylmethyl)Paclitaxel",
    ],
  },
  {
    primary_id: "D07LBP",
    names: ["RabiCide"],
  },
  {
    primary_id: "D07LBQ",
    names: ["GSK2398852", "2398852"],
  },
  {
    primary_id: "D07LCA",
    names: ["AMG0714"],
  },
  {
    primary_id: "D07LCC",
    names: ["4-Methoxymethyl-3,6-Dipropoxy-9H-Beta-Carboline", "CHEMBL83581"],
  },
  {
    primary_id: "D07LCE",
    names: ["1,3,9,9a-Tetrahydro-Imidazo[4,5-B]quinolin-2-One"],
  },
  {
    primary_id: "D07LCF",
    names: ["Naloxegol"],
  },
  {
    primary_id: "D07LCG",
    names: ["4-Butyl-N-Phenylbenzamide", "CHEMBL260465", "BDBM50376283"],
  },
  {
    primary_id: "D07LCM",
    names: [
      "1-(3-Methoxyphenethyl)Pyrrolidine",
      "CHEMBL404911",
      "SCHEMBL10669091",
      "JLMYWUJQHKXCOU-UHFFFAOYSA-N",
      "3-(2-Pyrrolidin-1-Ylethyl)Anisole",
    ],
  },
  {
    primary_id: "D07LCP",
    names: ["Ad5Ag85A", "Adenovirus Tuberculosis Vaccine, McMaster University"],
  },
  {
    primary_id: "D07LCU",
    names: ["N-(3-Phenylpropyl)-1H-Indole-2-Carboxamide", "CHEMBL460609", "BDBM50273530", "STL028133", "AKOS005679593"],
  },
  {
    primary_id: "D07LCW",
    names: ["5-Azidomethyl-3-Pyrrol-1-Yl-Oxazolidin-2-One", "CHEMBL18019", "BDBM50110717"],
  },
  {
    primary_id: "D07LCY",
    names: ["RVSVIN HIV-1 Gag Vaccine"],
  },
  {
    primary_id: "D07LDP",
    names: ["Hexahydrosiladifenidol"],
  },
  {
    primary_id: "D07LER",
    names: ["Nonanoic Acid Biphenyl-2-Ylamide", "CHEMBL270041", "SCHEMBL15472054"],
  },
  {
    primary_id: "D07LEW",
    names: [
      "BIBS-39",
      "Bibs 39",
      "133085-33-3",
      "BIBS39",
      "CHEMBL130896",
      "2-[4-[[2-Butyl-6-(Cyclohexylcarbamoylamino)Benzimidazol-1-Yl]methyl]phenyl]benzoic Acid",
      "AC1L2ZGG",
      "SCHEMBL6567316",
      "DTXSID60157943",
      "BCP17735",
      "BDBM50035429",
      "ZINC11687040",
      "AKOS030526411",
      "CS-5552",
      "HY-19732",
      "4'-((2-N-Butyl-6-Cyclohexylaminocarbonylaminobenzimidazole-1-Yl)Methyl)Biphenyl-2-Carboxylic Acid",
      "KB-227067",
      "FT-0700165",
      "4''-[2-Butyl-6-(3-Cyclohexyl-Ureido)-Benzoimidazol-1-Ylmethyl]-Biphenyl-2-Carboxylic Acid",
      "(1,1'-Biphenyl)-2-Carboxylic Acid, 4'-((2-Butyl-6-(((Cyclohexylamino)C",
    ],
  },
  {
    primary_id: "D07LFJ",
    names: ["BU-32"],
  },
  {
    primary_id: "D07LFR",
    names: [
      "3-(Benzo[b]thiophen-5-Yl)-3-Benzylpyrrolidine",
      "CHEMBL486537",
      "SCHEMBL984632",
      "VVROCSNBKQTQEO-UHFFFAOYSA-N",
      "BDBM50275440",
    ],
  },
  {
    primary_id: "D07LFZ",
    names: [
      "4-(5-(4-Aminophenyl)Thiophen-2-Yl)Benzenamine",
      "CHEMBL382007",
      "2,5-Bis(4-Aminophenyl)Thiophene",
      "SCHEMBL6751854",
      "ZINC28571226",
      "BDBM50178547",
      "70010-49-0",
    ],
  },
  {
    primary_id: "D07LGU",
    names: ["MMPT"],
  },
  {
    primary_id: "D07LHD",
    names: [
      "1-(2-Dimethylamino-Ethyl)-1H-Indol-4-Ol",
      "CHEMBL194203",
      "1-(2-Dimethylamino-Ethyl)-Indol-4-Ol",
      "BDBM50171268",
    ],
  },
  {
    primary_id: "D07LIE",
    names: ["2-(3-Benzenesulfonyl)Phenyl-1-Aminoethane", "CHEMBL209383", "ZINC36093365"],
  },
  {
    primary_id: "D07LJH",
    names: ["[125I]DOI"],
  },
  {
    primary_id: "D07LJR",
    names: ["CDR-267F018"],
  },
  {
    primary_id: "D07LKA",
    names: ["Pyrimidine Derivative 17"],
  },
  {
    primary_id: "D07LLI",
    names: ["Mucin Type Glycoproteins"],
  },
  {
    primary_id: "D07LMB",
    names: ["PMID20809633C29b"],
  },
  {
    primary_id: "D07LMD",
    names: ["Glucocorticoid Receptor Ligands"],
  },
  {
    primary_id: "D07LMI",
    names: [
      "1-Benzyl-2-Methyl-1H-Imidazole",
      "1-Benzyl-2-Methylimidazole",
      "13750-62-4",
      "N-Benzyl-2-Methylimidazole",
      "Imidazole, 1-Benzyl-2-Methyl-",
      "1H-Imidazole, 2-Methyl-1-(Phenylmethyl)-",
      "EINECS 237-333-8",
      "1H-Imidazole, Methyl(Phenylmethyl)-",
      "2-Methyl-1-Benzylimidazole",
      "PubChem9442",
      "ACMC-209ccp",
      "SCHEMBL81625",
      "1-Benzyl-2-Methyl Imidazole",
      "Labotest-Bb Lt00455538",
      "KSC427M1B",
      "MLS001074953",
      "BDBM7885",
      "AC1Q4X69",
      "AC1L35Z6",
      "CHEMBL1269154",
      "DTXSID4065604",
      "FBHPRUXJQNWTEW-UHFFFAOYSA-N",
      "MolPort-003-931-164",
      "HMS3372N18",
      "ZINC156633",
      "HMS2231D21",
      "SBB058028",
    ],
  },
  {
    primary_id: "D07LMK",
    names: [
      "2-(Trifluoromethyl)Benzaldehyde Thiosemicarbazone",
      "3-Bromobenzaldehyde Thiosemicarbazone",
      "CHEMBL91844",
      "(E)-2-(3-Bromobenzylidene)Hydrazinecarbothioamide",
      "1346145-39-8",
      "[(E)-(3-Bromophenyl)Methylideneamino]thiourea",
      "AC1OR2L9",
      "MLS000679007",
      "SCHEMBL8443935",
      "MolPort-000-665-235",
      "MolPort-000-517-617",
      "FGGWIXJNLRDBPW-VZUCSPMQSA-N",
      "MolPort-020-322-421",
      "HMS1608D07",
      "STK409371",
      "SBB015558",
      "ITH000389",
      "BDBM50114665",
      "AKOS002349799",
      "AKOS000304365",
      "3-Bromobenzaldehyde Thiosemicarbazone #",
      "FCH4917890",
      "FCH3689419",
      "SMR000323398",
      "BBV-39869959",
      "[(E)-(3-Bromophenyl)Methyleneamino]thiourea",
      "ST50169421",
    ],
  },
  {
    primary_id: "D07LML",
    names: ["Tosyl-D-Proline"],
  },
  {
    primary_id: "D07LMO",
    names: ["Drug 2862277", "2862277"],
  },
  {
    primary_id: "D07LMV",
    names: ["SK&F 107649"],
  },
  {
    primary_id: "D07LMZ",
    names: [
      "Dimethylamine",
      "N-Methylmethanamine",
      "124-40-3",
      "N,N-Dimethylamine",
      "Methanamine, N-Methyl-",
      "Dimethylamin",
      "Dimethyl-Amine",
      "Dimethyl Amine",
      "Dimethylamine Anhydrous",
      "Dimethylamine (Anhydrous)",
      "RCRA Waste Number U092",
      "Dimethylamine Solution",
      "(CH3)2NH",
      "Dimethylamine  Aq",
      "NSC 8650",
      "UNII-ARQ8157E0Q",
      "CCRIS 981",
      "HSDB 933",
      "Me2NH",
      "HNMe2",
      "Dimethylamine, In Aqueous Solution",
      "EINECS 204-697-4",
      "Ai3-15638-X",
      "UN1160",
      "UN1032",
      "RCRA Waste No. U092",
      "Dimethylamine Aqueous Solution",
      "ARQ8157E0Q",
      "CHEBI:17170",
      "ROSDSFDQCJNGOL-UHFFFAOYSA-N",
      "Dimethylamine Solution (Ov",
    ],
  },
  {
    primary_id: "D07LNY",
    names: ["Prostaglandin A2"],
  },
  {
    primary_id: "D07LOF",
    names: ["ARN-6039"],
  },
  {
    primary_id: "D07LON",
    names: [
      "Antibacterial Compounds, Cumbre",
      "371253-89-3",
      "C-0340703",
      "CBR-4830",
      "CBR-703",
      "CBR-703 Series, Cumbre",
      "CBR-9379",
      "CBR-9393",
    ],
  },
  {
    primary_id: "D07LPT",
    names: ["EP-7041"],
  },
  {
    primary_id: "D07LQQ",
    names: ["Tyr-D-Nle-Gly-Trp-Nle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D07LQX",
    names: ["2-Fluorophenyl 4-(Undecyloxy)Phenylcarbamate", "CHEMBL609024"],
  },
  {
    primary_id: "D07LRB",
    names: ["Factor VIII-XTEN"],
  },
  {
    primary_id: "D07LRJ",
    names: [
      "SB-271046",
      "209481-20-9",
      "SB271046",
      "Sb 271046",
      "5-Chloro-N-(4-Methoxy-3-(Piperazin-1-Yl)Phenyl)-3-Methylbenzo[b]thiophene-2-Sulfonamide",
      "UNII-L3SK5KX24S",
      "L3SK5KX24S",
      "CHEMBL431298",
      "SB-271,046",
      "C20H22ClN3O3S2",
      "5-Chloro-N-[4-Methoxy-3-(1-Piperazinyl)Phenyl]-3-Methylbenzo[b]thiophene-2-Sulfonamide",
      "5-Chloro-N-(4-Methoxy-3-Piperazin-1-Ylphenyl)-3-Methyl-1-Benzothiophene-2-Sulfonamide",
      "5-Chloro-N-(4-Methoxy-3-(1-Piperazinyl)Phenyl)-3-Methylbenzo(B)Thiophene-2-Sulfonamide",
      "AC1NSM3X",
      "GTPL276",
      "SCHEMBL194700",
      "SB271046 HCl",
      "BDBM28583",
      "SB",
    ],
  },
  {
    primary_id: "D07LRT",
    names: [
      "A-425619",
      "AC1OYT5J",
      "T5512318",
      "LS-192912",
      "1-Isoquinolin-5-Yl-3-(4-Trifluoromethyl-Benzyl)-Urea",
      "1-Isoquinolin-5-Yl-3-[[4-(Trifluoromethyl)Phenyl]methyl]urea",
    ],
  },
  {
    primary_id: "D07LRZ",
    names: [
      "(+/-)-Threo-N-(6-Phenylhexyl)Methylphenidate",
      "CHEMBL1254008",
      "BDBM50327119",
      "(AlphaR)-Alpha-[(2R)-1-(6-Phenylhexyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D07LSC",
    names: [
      "1-(3-Fluoro-Phenyl)-Piperazine",
      "1-(3-Fluorophenyl)Piperazine",
      "3801-89-6",
      "Piperazine, 1-(3-Fluorophenyl)-",
      "1-(3-Fluorophenyl)-Piperazine",
      "CHEMBL269784",
      "Piperazine,1-(3-Fluorophenyl)-",
      "EINECS 223-271-9",
      "AC1Q4NBT",
      "3-Fluorophenyl Piperazine",
      "(3-Fluorophenyl)Piperazine",
      "AC1L2T0A",
      "4-(3-Fluorophenyl)Piperazine",
      "N-(3-Fluorophenyl)Piperazine",
      "SCHEMBL315894",
      "CTK4H9144",
      "DTXSID40191426",
      "MolPort-000-155-895",
      "KIFCSMQTGWVMOD-UHFFFAOYSA-N",
      "ZINC2529848",
      "SBB089693",
      "ANW-61692",
      "4877AB",
      "BDBM50017455",
      "AKOS000101159",
      "FS-1513",
      "KB-85106",
      "AS-10296",
    ],
  },
  {
    primary_id: "D07LSP",
    names: [
      "CB-3717",
      "CB3717",
      "TCMDC-131823",
      "AC1L9EKK",
      "CHEBI:3258",
      "CHEMBL389051",
      "PDDF",
      "CB3",
      "SCHEMBL6579718",
      "SCHEMBL9224063",
      "BDBM18771",
      "ZINC8655373",
      "2-[[4-[(2-Amino-4-Oxo-1H-Quinazolin-6-Yl)Methyl-Prop-2-Ynylamino]benzoyl]amino]pentanedioic Acid",
      "DB03541",
      "C11737",
      "N-(4-(N-(2-Amino-4-Hydroxy-6-Quinazolinyl)Methyl)Prop-2-Ynylamino)Benzoyl-L-Glutamic Acid",
      "(2S)-2-[[4-[(2-Amino-4-Oxo-1H-Quinazolin-6-Yl)Methyl-Prop-2-Ynylamino]benzoyl]amino]pentanedioic Acid",
    ],
  },
  {
    primary_id: "D07LSU",
    names: ["CDRI-S002-333", "Antithrombotic Compounds, CDRI", "S-000-20", "S-001-556", "S-002-329", "S-002-333"],
  },
  {
    primary_id: "D07LSV",
    names: ["Recombinant Galectin-3"],
  },
  {
    primary_id: "D07LTB",
    names: [
      "5-(4-Phenoxy-Phenyl)-Pyrimidine-2,4,6-Trione",
      "CHEMBL176517",
      "219311-20-3",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5-(4-Phenoxyphenyl)-",
      "SCHEMBL6380971",
      "CTK0J6997",
      "DTXSID60470422",
      "NNRYJLARUIVRRO-UHFFFAOYSA-N",
      "5-(4'-Phenoxyphenyl)Barbituric Acid",
      "5-(4-Phenoxyphenyl)Pyrimidine-2,4,6-Trione",
    ],
  },
  {
    primary_id: "D07LTR",
    names: ["1-(Pentyloxy)-4-Phenoxybenzene", "CHEMBL472892", "SCHEMBL1335892"],
  },
  {
    primary_id: "D07LUA",
    names: ["PfSPZ-GA1"],
  },
  {
    primary_id: "D07LUH",
    names: ["HCV NS3/4A Protease Inhibitors"],
  },
  {
    primary_id: "D07LUI",
    names: [
      "6-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL191974",
      "6-(4-Hydroxyphenyl)-2-Naphthol",
      "SCHEMBL2699234",
      "SKPPZWVFPOQPOT-UHFFFAOYSA-N",
      "ZINC13644983",
    ],
  },
  {
    primary_id: "D07LUJ",
    names: ["URSOLIC ACID"],
  },
  {
    primary_id: "D07LUR",
    names: [
      "Alpha-D-Mannose",
      "Alpha-D-Mannopyranose",
      "Alpha-Mannose",
      "Alpha-D-Man",
      "UNII-W3F28J9G0W",
      "CHEBI:28729",
      "7296-15-3",
      "W3F28J9G0W",
      "3h-Mannose",
      "Manalpha1,",
      "1rdl",
      "1rin",
      "29696-75-1",
      "Epitope ID:130701",
      "AC1Q59RC",
      "AC1L4HD7",
      "SCHEMBL76882",
      "CHEMBL365590",
      "WQZGKKKJIJFFOK-PQMKYFCFSA-N",
      "ZINC3860903",
      "FT-0773891",
      "C00936",
      "WURCS=2.0/1,1,0/[a1122h-1a_1-5]/1/",
    ],
  },
  {
    primary_id: "D07LVE",
    names: ["Aminocoumarin"],
  },
  {
    primary_id: "D07LVT",
    names: [
      "(RS)-PPCC",
      "CHEMBL222301",
      "GTPL6676",
      "SCHEMBL18261107",
      "BDBM50206578",
      "Methyl 2-[(4-Hydroxy-4-Phenylpiperidin-1-Yl)Methyl]-1-(4-Methylphenyl)Cyclopropane-1-Carboxylate",
      "Methyl (1R,2S/1S,2R)-2-[(4-Hydroxy-4-Phenylpiperidin-1-Yl)-Methyl]-1-(4-Methylphenyl)Cyclopropanecarboxylate",
    ],
  },
  {
    primary_id: "D07LWK",
    names: ["AVE0118"],
  },
  {
    primary_id: "D07LWL",
    names: ["WIN-34B"],
  },
  {
    primary_id: "D07LWT",
    names: ["FE-202767"],
  },
  {
    primary_id: "D07LXZ",
    names: [
      "Tyr-D-Met-Phe-His-Leu-Met-Asp-NH2",
      "CHEMBL32202",
      "BDBM50009180",
      "3-(2-{2-[2-(2-{2-[2-Amino-3-(4-Hydroxy-Phenyl)-Propionylamino]-4-Methylsulfanyl-Butyrylamino}-3-Phenyl-Propionylamino)-3-(1H-Imidazol-4-Yl)-Propionylamino]-4-Methyl-Pentanoylamino}-4-Methylsulfanyl-Butyrylamino)-Succinamic Ac",
    ],
  },
  {
    primary_id: "D07LYC",
    names: [
      "SCYX-7158",
      "AN-2920",
      "AN-3520",
      "AN-5568",
      "SCYX-6759",
      "Trypanocidal Compounds (Human African Trypanosomiasis)",
      "Oxaborole Series (Human African Trypanosomiasis), SCYNEXIS/DNDi/Pace University/Anacor Pharmaceuticals",
      "Trypanocidal Compounds (Human African Trypanosomiasis), SCYNEXIS/DNDi/Pace University/Anacor Pharmaceuticals",
    ],
  },
  {
    primary_id: "D07LYJ",
    names: [
      "BAS-00672722",
      "CHEMBL207360",
      "AC1LKX7K",
      "BDBM50187572",
      "4-(3-(Benzyloxy)Benzylidene)-1-(3,4-Dichlorophenyl)Pyrazolidine-3,5-Dione",
    ],
  },
  {
    primary_id: "D07LYU",
    names: ["N4-Phenylethoxycytidine-5'-Triphosphate"],
  },
  {
    primary_id: "D07LYW",
    names: ["2-Valproylamido-5-Sulfonamidoindane", "CHEMBL381730", "Indanesulfonamide Derivative 12a", "BDBM11031"],
  },
  {
    primary_id: "D07LZH",
    names: ["ERB-041", "2-(3-Fluoro-4-Hydroxyphenyl)-7-Vinylbenzoxazol-5-Ol"],
  },
  {
    primary_id: "D07LZP",
    names: ["ISIS 13741"],
  },
  {
    primary_id: "D07LZY",
    names: [
      "Rose Bengal (Photoactivated)",
      "CHEMBL1208422",
      "CHEBI:87202",
      "2,3,4,5-Tetrachloro-6-(3-Hydroxy-2,4,5,7-Tetraiodo-6-Oxo-Xanthen-9-Yl)Benzoic Acid",
      "2,3,4,5-Tetrachloro-6-(3-Hydroxy-2,4,5,7-Tetraiodo-6-Oxoxanthen-9-Yl)Benzoic Acid",
      "NSC45732",
      "NSC10463",
      "NSC270091",
      "C.I.45440",
      "Bengalrosa",
      "Rose Bengal Free Acid",
      "Ak-Rose Liq 1%",
      "Tetraiodotetrachlorofluorescein",
      "GTPL2393",
      "SCHEMBL1464825",
      "AC1L196L",
      "ZINC150338646",
      "AN-20423",
      "LS-194354",
      "SR-01000939637",
      "SR-01000939637-2",
    ],
  },
  {
    primary_id: "D07MAF",
    names: ["3-(Dimethylamino)-1-(4-Heptylphenyl)Propan-1-One", "CHEMBL561850"],
  },
  {
    primary_id: "D07MBC",
    names: ["N-Oleoylethanolamide"],
  },
  {
    primary_id: "D07MBQ",
    names: ["CNTO-736"],
  },
  {
    primary_id: "D07MCA",
    names: ["FK949E"],
  },
  {
    primary_id: "D07MCK",
    names: ["Salicylamide"],
  },
  {
    primary_id: "D07MCN",
    names: ["CMP-001"],
  },
  {
    primary_id: "D07MCT",
    names: ["Anti-Arthritis Drug"],
  },
  {
    primary_id: "D07MDY",
    names: ["Elsulfavirine"],
  },
  {
    primary_id: "D07MED",
    names: ["RG7716"],
  },
  {
    primary_id: "D07MEE",
    names: ["PMID25399762-Compound-Figure4-Phyllanthoid A"],
  },
  {
    primary_id: "D07MEH",
    names: ["Meglitinides"],
  },
  {
    primary_id: "D07MEL",
    names: ["Amiprilose"],
  },
  {
    primary_id: "D07MEX",
    names: ["CLR-1401", "COLD (Cancer), Novelos", "18-(P-Iodophenyl)Octadecyl Phosphocholine"],
  },
  {
    primary_id: "D07MFG",
    names: ["CBOBNEA", "Carboxybiphenyloxy-Butoxy-Naphthalen-Ethylacetamide"],
  },
  {
    primary_id: "D07MFH",
    names: ["3-(2-Amino-Ethyl)-5-Imino-[1,4]oxazepane", "CHEMBL89735", "SCHEMBL8551346", "BDBM50104651"],
  },
  {
    primary_id: "D07MFY",
    names: [
      "STX-200",
      "Alpha-V/Beta-5 Antagonist, Stromedix",
      "Alpha-V/Beta-5 Antagonist, University Of California San Francisco",
      "Alpha-V/Beta-5 MAb, Stromedix",
      "Alpha-V/Beta-5 MAb, University Of California San Francisco",
      "Alpha-V/Beta-5 Monoclonal Antibody, Stromedix",
      "Alpha-V/Beta-5 Monoclonal Antibody, University Of California San Francisco",
    ],
  },
  {
    primary_id: "D07MGA",
    names: ["Hesperetin"],
  },
  {
    primary_id: "D07MGC",
    names: ["Talacotuzumab"],
  },
  {
    primary_id: "D07MGN",
    names: ["NSC-224124"],
  },
  {
    primary_id: "D07MGX",
    names: ["ISIS 150451"],
  },
  {
    primary_id: "D07MHL",
    names: ["Biphenylindanone A"],
  },
  {
    primary_id: "D07MHM",
    names: ["PP-405", "Boron Polymer Conjugated Paclitaxel (Cancer), Psimei"],
  },
  {
    primary_id: "D07MHZ",
    names: [
      "N-Cyclohexyl-N'-(Propyl)Phenyl Urea",
      "1-Cyclohexyl-3-(3-Phenylpropyl)Urea",
      "1cr6",
      "AC1L1HZG",
      "Cambridge Id 7261612",
      "SCHEMBL4955112",
      "CHEMBL1231909",
      "MolPort-001-541-502",
      "HMS3604O05",
      "BDBM129287",
      "ZINC2047156",
      "STK432683",
      "AKOS003282138",
      "DB04213",
      "MCULE-7021945009",
      "ST50924514",
      "(Cyclohexylamino)-N-(3-Phenylpropyl)Carboxamide",
      "US8815951, 104",
      "SR-01000883953",
    ],
  },
  {
    primary_id: "D07MIE",
    names: ["UDP-Glucose"],
  },
  {
    primary_id: "D07MIY",
    names: ["Aryl Urea Derivative 3"],
  },
  {
    primary_id: "D07MJD",
    names: ["[2-(Biphenyl-4-Sulfonyl)Phenyl]acetic Acid", "CHEMBL573935"],
  },
  {
    primary_id: "D07MJJ",
    names: ["PMID25522065-Compound-8"],
  },
  {
    primary_id: "D07MKJ",
    names: [
      "7-(4-Methoxyphenyl)Pteridine-2,4-Diol",
      "CHEMBL511249",
      "AC1NTQMH",
      "7-(4-Methoxyphenyl)-2,4-Pteridinediol",
      "7-(4-Methoxyphenyl)-1H-Pteridine-2,4-Dione",
      "SCHEMBL13401704",
      "MolPort-002-799-584",
      "ZINC6493631",
      "BDBM50262040",
      "MCULE-1306601038",
    ],
  },
  {
    primary_id: "D07MKL",
    names: [
      "ABT-229",
      "Alemcinal",
      "UNII-5DS173ODI4",
      "5DS173ODI4",
      "ABBOTT-81229.0",
      "150785-53-8",
      "A-81229",
      "Alemcinal (USAN/INN)",
      "CHEMBL300049",
      "ABT229",
      "GTPL1444",
      "BDBM86685",
      "ABT 229",
      "D02801",
      "8,9-Didehydro-N-Demethyl-9-Deoxo-4', 6, 12-Trideoxy-6,9-Epoxy-N-Ethylerythromycin",
    ],
  },
  {
    primary_id: "D07MLA",
    names: ["CIGB-200"],
  },
  {
    primary_id: "D07MLP",
    names: [
      "APN-601",
      "DREAM Modulators (Pain), Evotec/ Apeiron Biologics",
      "Modulators Of Downstream Regulatory Element Antagonistic Modulator (Pain), Evotec/ Apeiron Biologics",
    ],
  },
  {
    primary_id: "D07MMC",
    names: ["STP-702"],
  },
  {
    primary_id: "D07MME",
    names: [
      "Dexamethasone Palmitate",
      "Cortiject",
      "Dexamethasone Palmitate, Novagali",
      "NOVA-22026",
      "NOVA-22032",
      "NOVA-63035",
      "Corticosteroid Therapy (Ophthalmic Emulsion, Novasorb), Novagali",
      "Retinopathy Therapy (Ophthalmic Emulsion, Novasorb), Novagali",
    ],
  },
  {
    primary_id: "D07MMG",
    names: ["AdoC(Dpr)2AlaArg6", "CHEMBL611120"],
  },
  {
    primary_id: "D07MNG",
    names: ["Ethyl Dihydrogen Phosphate"],
  },
  {
    primary_id: "D07MNI",
    names: ["PMID25666693-Compound-127"],
  },
  {
    primary_id: "D07MNT",
    names: ["P52-/P36- GAP Vaccine"],
  },
  {
    primary_id: "D07MOK",
    names: ["KANAb-071"],
  },
  {
    primary_id: "D07MON",
    names: ["ONAPRISTONE"],
  },
  {
    primary_id: "D07MOX",
    names: ["Norepinephrine"],
  },
  {
    primary_id: "D07MPI",
    names: ["MEDI-0639"],
  },
  {
    primary_id: "D07MPJ",
    names: ["A-75925", "A-74704", "A-76928", "A-77003", "Protease Inhibitors, Abbott"],
  },
  {
    primary_id: "D07MPL",
    names: [
      "SNX-5422",
      "908115-27-5",
      "PF-04929113",
      "SNX 5422",
      "SNX5422",
      "UNII-BF52J69Q8T",
      "PF 04929113",
      "PF-04929113 (SNX-5422)",
      "BF52J69Q8T",
      "PF04929113",
      "PF-4929113",
      "Cc-40",
      "MLS006011083",
      "SCHEMBL1220791",
      "SCHEMBL1220790",
      "CHEMBL1195136",
      "SCHEMBL15604989",
      "DTXSID50238270",
      "C25H30F3N5O4",
      "MolPort-023-293-502",
      "BCPP000065",
      "HMS3656B09",
      "EX-A2343",
      "BCP02427",
      "S2656",
      "ZINC95616595",
      "ABP000737",
      "ZINC100071931",
      "ZINC252517142",
      "AKOS027276395",
      "SB16643",
      "DB06070",
      "API0008143",
      "RL05706",
      "SNX-5422 /",
      "CS-0272",
      "NCGC00346640-01",
      "NCGC00386184-02",
      "Glycine, Trans-4-((2-(Aminocarbonyl)-5-(4,5,6,7-Tetrah",
    ],
  },
  {
    primary_id: "D07MQB",
    names: [
      "4-Amino-3-Fluoro-5-Iodobenzenesulfonamide",
      "CHEMBL69813",
      "4-Amino-3-Fluoro-5-Iodobenzene-1-Sulfonamide",
      "Halogenosulfanilamide Deriv. 8",
    ],
  },
  {
    primary_id: "D07MQC",
    names: ["PS-1145"],
  },
  {
    primary_id: "D07MQD",
    names: ["PMID27019002-Compound-21b"],
  },
  {
    primary_id: "D07MQF",
    names: ["MYRIOCIN"],
  },
  {
    primary_id: "D07MQJ",
    names: ["(Z)-1-(4-Phenyloxazol-2-Yl)Octadec-9-En-1-One", "CHEMBL177471", "SCHEMBL3185614", "BDBM50163160"],
  },
  {
    primary_id: "D07MQY",
    names: ["HIV-LAMP-Vax"],
  },
  {
    primary_id: "D07MRF",
    names: [
      "3-Nitro-9H-Beta-Carboline",
      "9H-Pyrido[3,4-B]indole, 3-Nitro-",
      "AC1NFSZR",
      "3-Nitro-Beta-Carboline",
      "CHEMBL53229",
      "CHEMBL487006",
      "3-Nitro-9H-Pyrido[3,4-B]indole",
    ],
  },
  {
    primary_id: "D07MRK",
    names: ["Tlouidine Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D07MSB",
    names: ["Thiazole Carboxamide Derivative 11"],
  },
  {
    primary_id: "D07MSJ",
    names: ["1-(5-Pyridin-2-Yl-Oxazol-2-Yl)-Octadec-9-Yn-1-One", "CHEMBL174397", "SCHEMBL3202757"],
  },
  {
    primary_id: "D07MSL",
    names: ["TL-1836"],
  },
  {
    primary_id: "D07MTB",
    names: [
      "PMID:23368907C16",
      "BPTU",
      "870544-59-5",
      "GTPL5808",
      "BMS-646786",
      "MolPort-046-033-632",
      "BCP29138",
      "EX-A2593",
      "ZINC95588286",
      "BDBM50429537",
      "AKOS032962872",
      "CS-8114",
      "HY-13831",
      "DS-20243",
      "AK689499",
      "1-(2-[2-",
    ],
  },
  {
    primary_id: "D07MTL",
    names: ["SRSC-371", "Anti-Epileptic (Oral, CK Polymer), Sirus"],
  },
  {
    primary_id: "D07MTM",
    names: [
      "4-AMINOPHENOL",
      "4-Aminophenol",
      "123-30-8",
      "P-Aminophenol",
      "4-Hydroxyaniline",
      "P-Hydroxyaniline",
      "Phenol, 4-Amino-",
      "Paranol",
      "Certinal",
      "Citol",
      "Azol",
      "4-Aminobenzenol",
      "Fouramine P",
      "Ursol P Base",
      "Rodinal",
      "P-Hydroxyphenylamine",
      "Benzofur P",
      "Fourrine P Base",
      "Pelagol P Base",
      "Tertral P Base",
      "Ursol P",
      "Furro P Base",
      "Nako Brown R",
      "Durafur Brown RB",
      "4-Amino-1-Hydroxybenzene",
      "Renal AC",
      "Fourrine 84",
      "Phenol, P-Amino-",
      "Zoba Brown P Base",
      "4-AMINO-PHENOL",
      "UNAL",
      "C.I. Oxidation Base 6",
      "1-Amino-4-Hydroxybenzene",
      "Pelagol Grey P Base",
      "BASF Ursol P Base",
      "P-Aminofenol",
      "Para-Aminophenol",
    ],
  },
  {
    primary_id: "D07MTT",
    names: ["HP-831-064"],
  },
  {
    primary_id: "D07MTU",
    names: [
      "3alpha-Hydroxyurs-12-En-28-Oic Acid",
      "989-30-0",
      "CHEMBL491715",
      "AC1OG92I",
      "SCHEMBL19714770",
      "MolPort-039-338-864",
      "ZINC12890906",
      "BDBM50346612",
      "9.89E+302",
      "(1S,2R,4aS,6aR,6aS,6bR,8aR,10R,12aR,14bS)-10-Hydroxy-1,2,6a,6b,9,9,12a-Heptamethyl-2,3,4,5,6,6a,7,8,8a,10,11,12,13,14b-Tetradecahydro-1H-Picene-4a-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D07MUI",
    names: ["Albitiazolium Bromide Prodrugs", "Albitiazolium Bromide Prodrugs (Plasmodium Infection)"],
  },
  {
    primary_id: "D07MUN",
    names: [
      "Vitamin B6",
      "Aderoxin",
      "Alestrol",
      "Becilan",
      "Beesix",
      "Benadon",
      "Bonasanit",
      "Godabion",
      "Hexavibex",
      "Hexermin",
      "Hexermine",
      "Hexobion",
      "Nestrex",
      "Paxadon",
      "Pydox",
      "Pyridipca",
      "Pyridox",
      "Spondylonal",
      "Vitaped",
      "Adermin Hydrochloride",
      "Adermine Hydrochloride",
      "Aderomine Hydrochloride",
      "Component Of Alestrol",
      "Hexabione Hydrochloride",
      "PN HCl",
      "PYRIDOXINE HYDROCHLORIDE",
      "Pyridoxin Hydrochloride",
      "Pyridoxine HCl",
      "Pyridoxine Chloride",
      "Pyridoxine Hydrogen Chloride",
      "Pyridoxine Monohydrochloride",
      "Pyridoxinium Chloride",
      "Pyridoxinum Hydrochloricum",
      "Pyridoxol Hydrochloride",
      "Rodex TD",
      "AIDS006784",
      "Campoviton 6",
      "Vitamin V6",
      "AIDS-006784",
      "Cernevit-12",
      "Pyridoxine (INN)",
      "Pyridoxine Hydrochloride (B6)",
      "Pyridoxine Hydrochloride[USAN:JAN]",
      "Pyridoxine, Hydrochloride",
      "Pyridoxine-HCl Microencapsulated",
      "Pyridoxinum Hydrochloricum (Hungarian)",
      "Pyridoxol, Hydrochloride",
      "Rodex (R)",
      "Vitamin B6-Hydrochloride",
      "Vitamin V6 (TN)",
      "Vitamin-B6 Hydrochloride",
      "Hexa-Betalin (R)",
      "Hexa-Betalin (TN)",
      "Pyridoxine Hydrochloride (JP15/USP)",
      "Tex Six T.R.",
      "PYRIDOXINE HYDROCHLORIDE, U.S.P.",
      "(5-Hydroxy-4,6-Dimethylpyridin-3-Yl)Methyl Phosphate",
      "2-Methyl-3-Hydroxy-4,5-Bis(Hydroxymethyl)Pyridine",
      "2-Methyl-3-Hydroxy-4,5-Di(Hydroxymethyl)Pyridine",
      "2-Methyl-3-Hydroxy-4,5-Dihydroxymethyl-Pyridin",
      "2-Methyl-3-Hydroxy-4,5-Dihydroxymethyl-Pyridin [German]",
      "2-Methyl-4,5-Bis(Hydroxymethyl)-3-Hydroxypyridine",
      "2-Methyl-3-Hydroxy-4,5-Bis(Hydroxy-Methyl) Pyridine",
      "2-Methyl-3-Hydroxy-4,5-Dihydroxymethylpyridine",
      "2-Methyl-4,5-Dimethylol-Pyridin-3-Ol",
      "3,4-Pyridinedimethanol, 5-Hydroxy-6-Methyl-, Hydrochloride",
      "3-Hydroxy-4,5-Dihydroxymethyl-2-Methylpyridine Hydrochloride",
      "3-Hydroxy-4,5-Dimethylol-.alpha.-Picoline Hydrochloride",
      "3-Hydroxy-4,5-Dimethylol-Alpha-Picoline Hydrochloride",
      "4,5-Bis(Hydroxymethyl)-2-Methyl-3-Pyridinol",
      "4,5-Bis(Hydroxymethyl)-2-Methylpyridin-3-Ol Hydrochloride",
      "4,5-Bis(Hydroxymethyl)-2-Methyl-3-Pyridinol Hydrochloride",
      "4,5-Bis(Hydroxymethyl)-2-Methylpyridine-3-Ol",
      "4-Deoxypyridoxine 5'-Phosphate",
      "5-Hydroxy-6-Methyl-3,4-Pyridinedicarbinol Hydrochloride",
      "5-Hydroxy-6-Methyl-3,4-Pyridinedimethanol Hydrochloride",
      "5-Hydroxy-6-Methyl-3,4-Pyridinedimethanol, Hydrochloride",
    ],
  },
  {
    primary_id: "D07MUS",
    names: ["CPA-7"],
  },
  {
    primary_id: "D07MUT",
    names: ["TDI-0032"],
  },
  {
    primary_id: "D07MVE",
    names: ["PD-151832"],
  },
  {
    primary_id: "D07MVK",
    names: ["Halofantrine"],
  },
  {
    primary_id: "D07MVL",
    names: ["43781"],
  },
  {
    primary_id: "D07MWD",
    names: ["MDL-28161"],
  },
  {
    primary_id: "D07MWY",
    names: ["XR-1853"],
  },
  {
    primary_id: "D07MXA",
    names: [
      "C75",
      "C75 Trans",
      "C75 (Trans)",
      "191282-48-1",
      "3-Carboxy-4-Octyl-2-Methylenebutyrolactone",
      "CHEMBL449993",
      "Trans-4-Carboxy-5-Octyl-3-Methylenebutyrolactone",
      "Fatty Acid Synthase Inhibitor, C75",
      "(+)-Trans-C75",
      "C75 (Racemic)",
      "SCHEMBL3007085",
      "CTK8E7727",
      "MolPort-005-933-439",
      "HMS3649D16",
      "ZINC2009913",
      "BCP11074",
      "HY-12364A",
      "BDBM50256128",
      "Trans-Tetrahydro-3-Methylene-2-Oxo-5-N-Octyl-4-Furancarboxylic Acid",
      "AKOS015960616",
      "VC30664",
      "CS-3561",
      "AC-11808",
      "RT-011885",
      "SR-01000946704",
      "SR-01000946704-1",
      "J-012362",
      "UNII-8E9A8CTX2H Component VCWLZDVWHQVAJU-NEPJUHHUSA-",
    ],
  },
  {
    primary_id: "D07MXF",
    names: ["Zabofloxacin", "DW-224a", "DW-224aa", "PB-101", "Zabofloxacin (Oral), IASO Pharma/DHP"],
  },
  {
    primary_id: "D07MYE",
    names: [
      "N-Hydroxy-3-Phenyl-Acrylamide",
      "Cinnamoylhydroxamic Acid",
      "CINNAMOHYDROXAMIC ACID",
      "Hydroxylamine, N-Cinnamoyl-",
      "2-Propenamide, N-Hydroxy-3-Phenyl-",
      "N-Hydroxycinnamamide",
      "UNII-434STJ4H11",
      "N-Hydroxy-3-Phenyl-2-Propenamide",
      "NSC 42128",
      "NSC-42128",
      "NSC-44617",
      "NSC 44617",
      "CHEMBL154574",
      "3669-32-7",
      "434STJ4H11",
      "WLN: QMV1U1R",
      "Cinnamohydroxamic Acid, (E)-",
      "Cinnamoylhydroxamic Acid [INCI]",
      "MAZ 1704",
      "MAZ-1704",
      "2-Propenamide, N-Hydroxy-3-Phenyl-, (E)-",
      "2-Propenamide, N-Hydroxy-3-Phenyl-, (2E)-",
      "AC1Q5CS7",
      "AC1NS7U4",
      "SCHEMBL559917",
    ],
  },
  {
    primary_id: "D07MZC",
    names: ["Vasoflux"],
  },
  {
    primary_id: "D07MZD",
    names: ["(2'Z,3'E)-7-Azaindirubin-3'-Oxime"],
  },
  {
    primary_id: "D07NAA",
    names: ["Sulfonylated Piperazine Derivative 4"],
  },
  {
    primary_id: "D07NAH",
    names: ["5-Naphthalen-2-Yl-1H-Imidazole"],
  },
  {
    primary_id: "D07NAJ",
    names: [
      "Chloroprocaine",
      "Chloroprocain",
      "Chloroprocainum",
      "Chlorprocaine",
      "Chlorprocainum",
      "Cloroprocaina",
      "Halestyn",
      "Nesacaine",
      "Piocaine",
      "Chloroprocaine [INN]",
      "Chloroprocaine Hydrochloride",
      "Nesacaine MPF",
      "Chlor-Procaine",
      "Chloroprocaine (INN)",
      "Chloroprocainum [INN-Latin]",
      "Cloroprocaina [INN-Spanish]",
      "Nesacaine (TN)",
      "Nesacaine-Ce",
      "Nesacaine-MPF (TN)",
      "Benzoic Acid, 4-Amino-2-Chloro-, 2-(Diethylamino)Ethyl Ester",
      "2-(Diethylamino)Ethyl 4-Amino-2-Chlorobenzoate",
      "2-(Diethylaminoethyl)-4-Amino-2-Chlorobenzoate",
      "2-Chloroprocaine",
      "2-Diethylaminoethyl 4-Amino-2-Chlorobenzoate",
      "4-Amino-2-Chlorobenzoic Acid 2-(Diethylamino)Ethyl Ester",
    ],
  },
  {
    primary_id: "D07NAT",
    names: ["ISIS 32039"],
  },
  {
    primary_id: "D07NAY",
    names: ["ROBINETIN"],
  },
  {
    primary_id: "D07NBA",
    names: ["Veltuzumab"],
  },
  {
    primary_id: "D07NBB",
    names: ["8-(3-Bromobenzyloxy)Caffeine", "CHEMBL602464"],
  },
  {
    primary_id: "D07NBK",
    names: ["AZALINE B"],
  },
  {
    primary_id: "D07NCC",
    names: ["VTT-201"],
  },
  {
    primary_id: "D07NCJ",
    names: ["PMID25553724-Compound-EP20132578214 2"],
  },
  {
    primary_id: "D07NCN",
    names: ["Doxofylline", "Ansimar (TN)"],
  },
  {
    primary_id: "D07NCV",
    names: ["BXT-51072", "ALT-2074", "SYI-2074"],
  },
  {
    primary_id: "D07NDK",
    names: ["WAY-644"],
  },
  {
    primary_id: "D07NDL",
    names: ["Short-Acting Etomidate Analogue"],
  },
  {
    primary_id: "D07NDT",
    names: ["PMID28074661-Compound-US20100022547C80"],
  },
  {
    primary_id: "D07NDW",
    names: ["PMID18752940C9n"],
  },
  {
    primary_id: "D07NEE",
    names: ["AEZS-131"],
  },
  {
    primary_id: "D07NEI",
    names: ["PMID30280939-Compound-US20179642901"],
  },
  {
    primary_id: "D07NFJ",
    names: ["PS-444035", "CHEMBL74943", "BDBM50071556"],
  },
  {
    primary_id: "D07NFQ",
    names: ["PMID29473428-Compound-16"],
  },
  {
    primary_id: "D07NFT",
    names: ["P-2281"],
  },
  {
    primary_id: "D07NFW",
    names: [
      "BMS-196085",
      "UNII-FM0D5L3W03",
      "FM0D5L3W03",
      "CHEMBL322862",
      "170686-10-9",
      "SCHEMBL7042488",
      "BDBM50106829",
      "Methanesulfonamide, N-(5-((1R)-2-(((1R)-1-(4-(Difluoromethoxy)Phenyl)-2-Phenylethyl)Amino)-1-Hydroxyethyl)-2-Hydroxyphenyl)-",
      "Methanesulfonamide, N-(5-(2-((1-(4-(Difluoromethoxy)Phenyl)-2-Phenylethyl)Amino)-1-Hydroxyethyl)-2-Hydroxyphenyl)-, (R-(R*,R*))-",
      "N-(5-{(R)-2-[(R)-1-(4-Difluoromethoxy-Phenyl)-2-Phenyl-Ethylamino]-1-Hydroxy-Ethyl}-2-Hydroxy-Phenyl)-Methanesulfonamide",
    ],
  },
  {
    primary_id: "D07NGJ",
    names: ["S-23906-1"],
  },
  {
    primary_id: "D07NGT",
    names: ["Azathioprine Delayed-Release Minitablets"],
  },
  {
    primary_id: "D07NGZ",
    names: ["Insulin-Glargine"],
  },
  {
    primary_id: "D07NHZ",
    names: ["CD19 CAR T Cells"],
  },
  {
    primary_id: "D07NIM",
    names: ["ISIS 16010"],
  },
  {
    primary_id: "D07NIT",
    names: ["SEH Inhibitors"],
  },
  {
    primary_id: "D07NJI",
    names: ["Treximet"],
  },
  {
    primary_id: "D07NJN",
    names: ["Siltuximab"],
  },
  {
    primary_id: "D07NKO",
    names: ["5-[(1R)-1-Ammonioethyl]isoxazol-3-Olate", "CHEMBL283019", "(R)-5-(1-Aminoethyl)-3-Isoxazolol"],
  },
  {
    primary_id: "D07NKZ",
    names: ["VVP-808"],
  },
  {
    primary_id: "D07NLI",
    names: ["Vismed"],
  },
  {
    primary_id: "D07NMH",
    names: ["1-Benzhydryl-4-(4-Bromophenyl)Piperidin-4-Ol", "CHEMBL232067", "BDBM50210228"],
  },
  {
    primary_id: "D07NMV",
    names: ["ISIS 24448"],
  },
  {
    primary_id: "D07NNN",
    names: ["NEUROKININ B"],
  },
  {
    primary_id: "D07NOI",
    names: ["BAY-57-9352"],
  },
  {
    primary_id: "D07NOJ",
    names: ["[Sar1Ala]GAL-B2", "CHEMBL578910"],
  },
  {
    primary_id: "D07NPH",
    names: ["Colostrinin", "Colostrinin (TN)"],
  },
  {
    primary_id: "D07NPS",
    names: ["Zarnestra"],
  },
  {
    primary_id: "D07NPZ",
    names: ["[Mpa1, D-Tyr(Et)2, Gly(But)7]OT", "CHEMBL441128"],
  },
  {
    primary_id: "D07NQG",
    names: ["Methylsulfonylbenzamide Derivative 2"],
  },
  {
    primary_id: "D07NQQ",
    names: ["Tagetitoxin"],
  },
  {
    primary_id: "D07NRL",
    names: ["CLL442 Topical"],
  },
  {
    primary_id: "D07NRP",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 4"],
  },
  {
    primary_id: "D07NRU",
    names: ["ISIS 138649"],
  },
  {
    primary_id: "D07NSR",
    names: ["4-Morpholin-4-Yl-Benzo[g]chromen-2-One", "CHEMBL177279"],
  },
  {
    primary_id: "D07NSU",
    names: [
      "Glucosamine",
      "Chitosamine",
      "GCS",
      "Glucosamina",
      "Glucosaminum",
      "D-Glucosamine",
      "Glucosamina [INN-Spanish]",
      "Glucosamine [USAN:INN]",
      "Glucosaminum [INN-Latin]",
      "Viartril-S",
      "Beta-D-Glucosamine",
      "Glucosamine (USAN/INN)",
      "Partially N-Deacetylated Poly-Beta-1,6-N-Acetyl-D-Glucosamine",
      "(2R,3R,4R,5S,6R)-3-Amino-6-(Hydroxymethyl)Oxane-2,4,5-Triol",
      "(2S,5R)-3-Amino-6-(Hydroxymethyl)Oxane-2,4,5-Triol",
      "(3R,4R,5S,6R)-3-Amino-6-(Hydroxymethyl)Oxane-2,4,5-Triol",
      "2-Amino-2-Deoxy-D-Glucose",
      "2-Amino-2-Deoxy-Beta-D-Glucopyranose",
      "2-Amino-2-Deoxy-D-Glucopyranose",
      "2-Amino-2-Deoxyglucose",
    ],
  },
  {
    primary_id: "D07NSZ",
    names: [
      "S-(3-Chlorobenzyl)Isothiourea Hydrochloride",
      "2-(3-Chlorobenzyl)-2-Thiopseudourea Hydrochloride",
      "CHEMBL1224311",
      "MolPort-003-912-538",
      "S-(3-Chlorobenzyl)Isothiourea HCl",
      "NSC201820",
      "GEO-03858",
      "AKOS024333709",
    ],
  },
  {
    primary_id: "D07NTA",
    names: ["Heteroaryl-Cyclopropylamine Derivative 1"],
  },
  {
    primary_id: "D07NTC",
    names: ["WCK-771"],
  },
  {
    primary_id: "D07NTN",
    names: ["CERM-11956"],
  },
  {
    primary_id: "D07NTP",
    names: ["PMID29649907-Compound-14"],
  },
  {
    primary_id: "D07NUH",
    names: ["IDX-15971"],
  },
  {
    primary_id: "D07NUL",
    names: ["PF-06650833"],
  },
  {
    primary_id: "D07NUO",
    names: ["JNJ-39588146"],
  },
  {
    primary_id: "D07NUZ",
    names: ["Phospho-Aspirins"],
  },
  {
    primary_id: "D07NVF",
    names: ["WAS Gene Therapy"],
  },
  {
    primary_id: "D07NVH",
    names: ["CCG-977", "CCG977", "CCG 977"],
  },
  {
    primary_id: "D07NVI",
    names: [
      "CPI-613",
      "95809-78-2",
      "6,8-Bis(Benzylthio)Octanoic Acid",
      "CPI 613",
      "MLS006010202",
      "SCHEMBL1062218",
      "6,8-Bis(Benzylsulfanyl)Octanoic Acid",
      "Octanoic Acid, 6,8-Bis((Phenylmethyl)Thio)-",
      "Octanoic Acid, 6,8-Bis[(PhenylMethyl)Thio]-",
      "6,8-Bis[(Phenylmethyl)Thio]octanoic Acid",
      "CPI613",
      "CHEMBL3186849",
      "QCR-193",
      "AOB1058",
      "MolPort-023-219-128",
      "HMS3656L06",
      "C22H28O2S2",
      "BCP04663",
      "EX-A2043",
      "S2776",
      "AKOS025142095",
      "BCP9000552",
      "DB12109",
      "RL06062",
      "CS-0961",
      "NCGC00344764-01",
      "SMR004701300",
      "AS-16613",
      "BC261916",
      "AK174899",
      "HY-15453",
      "BCP0726000030",
      "KB-293127",
      "AB0035874",
    ],
  },
  {
    primary_id: "D07NVK",
    names: ["1-(1-(3-Nitrophenyl)Propylidene)Thiosemicarbazide", "CHEMBL373586", "SCHEMBL6337198"],
  },
  {
    primary_id: "D07NVU",
    names: ["Midostaurin"],
  },
  {
    primary_id: "D07NWC",
    names: ["SC-241"],
  },
  {
    primary_id: "D07NWZ",
    names: ["CL-301", "CLP-301", "CL-301 Series, Chlorion"],
  },
  {
    primary_id: "D07NYF",
    names: ["MLN-2201"],
  },
  {
    primary_id: "D07NYQ",
    names: ["Cinchoninic Acid"],
  },
  {
    primary_id: "D07NYR",
    names: ["YM-96765"],
  },
  {
    primary_id: "D07NYX",
    names: [
      "BENZOTHIAZOLE",
      "95-16-9",
      "BENZO[D]THIAZOLE",
      "1,3-Benzothiazole",
      "Benzosulfonazole",
      "1-Thia-3-Azaindene",
      "Vangard BT",
      "Benzothiazol",
      "USAF EK-4812",
      "Benzthiazole",
      "CHEBI:45993",
      "FEMA Number 3256",
      "UNII-G5BW2593EP",
      "O-2857",
      "FEMA No. 3256",
      "CCRIS 7893",
      "HSDB 2796",
      "NSC 8040",
      "EINECS 202-396-2",
      "BRN 0109468",
      "AI3-05742",
      "G5BW2593EP",
      "IOJUPLGTWVMSFF-UHFFFAOYSA-N",
      "MFCD00005775",
      "Benzothiazole, 97%",
      "DSSTox_CID_4586",
      "DSSTox_RID_77458",
      "DSSTox_GSID_24586",
      "CAS-95-16-9",
      "BOT",
      "Benzothiazole, 96%",
      "1,3-Benzothiazole #",
      "ACMC-209rv5",
      "Epitope ID:138946",
      "EC 202-396-2",
      "SCHEMBL8430",
    ],
  },
  {
    primary_id: "D07NZC",
    names: ["AMG 581"],
  },
  {
    primary_id: "D07OAC",
    names: [
      "GP515",
      "1H-Pyrazolo(3,4-D)Pyrimidin-4-Amine, 1-(5-Amino-5-Deoxy-Beta-D-Ribofuranosyl)-3-Bromo-",
      "GP 1-515",
      "CHEMBL356141",
      "144928-48-3",
      "GP-515",
      "Adenosine Kinase Inhibitor GP515",
      "GP-1-515",
      "SCHEMBL2053010",
      "AC1L31E2",
      "DTXSID30162847",
      "BDBM50134744",
      "1-(5-Amino-5-Deoxy-Beta-D-Ribofuranosyl)-3-Bromo-1H-Pyrazolo(3,4-D)Pyrimidin-4-Amine",
      "4-Amino-1-(5-Amino-5-Deoxy-1-Beta-D-Ribofuranosyl)-3-Bromopyrazolo(3,4-D)Pyrimidine",
      "(2R,3R,4S,5R)-2-(4-Amino-3-Bromopyrazolo[3,4-D]pyrimidin-1-Yl)-5-(Aminomethyl)Oxolane-3,4-Diol",
      "19-Nor-10-Azasteroid Skeleton",
    ],
  },
  {
    primary_id: "D07OAD",
    names: ["GO-203-2c"],
  },
  {
    primary_id: "D07OAF",
    names: ["A61603", "A-61603", "A 61603"],
  },
  {
    primary_id: "D07OAH",
    names: ["NSC-78021"],
  },
  {
    primary_id: "D07OAK",
    names: ["Go7874"],
  },
  {
    primary_id: "D07OAO",
    names: ["IONIS-HTTRX"],
  },
  {
    primary_id: "D07OAR",
    names: ["(CH3)CCO-His-Trp-Ala-Val-Gly-His-Leu-Met-NH2", "CHEMBL440986"],
  },
  {
    primary_id: "D07OAT",
    names: ["Purvalanol A"],
  },
  {
    primary_id: "D07OAV",
    names: ["5-(2-1H-Indenyl)-1,3-Benzodioxole", "CHEMBL229139", "BDBM50217118"],
  },
  {
    primary_id: "D07OBG",
    names: ["5-Chloro-2-(4-Phenylbutyl)Isoindoline-1,3-Dione", "CHEMBL397849"],
  },
  {
    primary_id: "D07OBI",
    names: ["H-Gly-D-DmP-Glu-OH", "CHEMBL212694"],
  },
  {
    primary_id: "D07OBJ",
    names: ["PHX-1766"],
  },
  {
    primary_id: "D07OBK",
    names: ["SL-201"],
  },
  {
    primary_id: "D07OCA",
    names: [
      "Cdk1/2 Inhibitor III",
      "443798-55-8",
      "CDK 1/2 INHIBITOR",
      "UNII-LFR1253W75",
      "LFR1253W75",
      "5-AMINO-3-{[4-(AMINOSULFONYL)PHENYL]AMINO}-N-(2,6-DIFLUOROPHENYL)-1H-1,2,4-TRIAZOLE-1-CARBOTHIOAMIDE",
      "5-Amino-N-(2,6-Difluorophenyl)-3-[(4-Sulfamoylphenyl)Amino]-1H-1,2,4-Triazole-1-Carbothioamide",
      "5-Amino-3-((4-(Aminosulfonyl)Phenyl)Amino)-N-(2,6-Difluorophenyl)-1H-1,2,4-Triazole-1-Carbothioamide",
      "2wu6",
      "AC1NS9OB",
      "CHEMBL261720",
      "GTPL5946",
      "BDBM6878",
      "SCHEMBL1394721",
      "CTK8E9250",
      "DTXSID60416209",
      "MolPort-044-561-528",
      "HMS3229C16",
      "ZINC12355112",
      "DB07664",
    ],
  },
  {
    primary_id: "D07OCC",
    names: ["JSM-10292", "Bradykinin B2 Receptor Antagonist (Pain/Inflammation), Jerini"],
  },
  {
    primary_id: "D07OCM",
    names: [
      "Dendritic Cell-Based Immunotherapy",
      "Dendritic Cell-Based Immunotherapy (Rheumatoid Arthritis)",
      "Dendritic Cell-Based Immunotherapy (Rheumatoid Arthritis), Dendright",
      "Dendritic Cell-Based Immunotherapy (Rheumatoid Arthritis, Injectable Formulation), Dendright",
    ],
  },
  {
    primary_id: "D07OCV",
    names: ["HT-0712"],
  },
  {
    primary_id: "D07ODC",
    names: ["VBY- 036"],
  },
  {
    primary_id: "D07ODD",
    names: ["DC-015"],
  },
  {
    primary_id: "D07ODE",
    names: ["N-Phenyl-1H-Pyrazole-3-Carboxamide"],
  },
  {
    primary_id: "D07OED",
    names: ["BL-5040"],
  },
  {
    primary_id: "D07OEY",
    names: ["ZK-118182"],
  },
  {
    primary_id: "D07OFM",
    names: ["GSK525762"],
  },
  {
    primary_id: "D07OGR",
    names: ["YM-26734"],
  },
  {
    primary_id: "D07OGZ",
    names: [
      "Darexaban Maleate",
      "UNII-03RTP2436R",
      "365462-24-4",
      "03RTP2436R",
      "YM150",
      "Darexaban Maleate (JAN)",
      "SCHEMBL5406349",
      "CHEMBL1922345",
      "D09817",
      "N-[2-[[4-(Hexahydro-4-Methyl-1H-1,4-Diazepin-1-Yl)Benzoyl]amino]-3-Hydroxyphenyl]-4-Methoxybenzamide (2Z)-2-Butenedioate",
    ],
  },
  {
    primary_id: "D07OHO",
    names: ["Ampiroxicam"],
  },
  {
    primary_id: "D07OHT",
    names: ["ABT-450"],
  },
  {
    primary_id: "D07OIO",
    names: [
      "AZD-2461",
      "AZD2461",
      "1174043-16-3",
      "UNII-3K288XF5XJ",
      "3K288XF5XJ",
      "AZD 2461",
      "4-(4-Fluoro-3-(4-Methoxypiperidine-1-Carbonyl)Benzyl)Phthalazin-1(2H)-One",
      "4-[4-Fluoro-3-[(4-Methoxypiperidin-1-Yl)Carbonyl]benzyl]phthalazin-1(2H)-One",
      "1(2H)-Phthalazinone, 4-[[4-Fluoro-3-[(4-Methoxy-1-Piperidinyl)Carbonyl]phenyl]methyl]-",
      "4-(4-Fluoro-3-((4-Methoxypiperidin-1-Yl)Carbonyl)Benzyl)Phthalazin-1(2H)-One",
      "1(2H)-Phthalazinone, 4-((4-Fluoro-3-((4-Methoxy-1-Piperidinyl)Carbonyl)Phenyl)Methyl)-",
      "HYNBNUYQTQIHJK-UHFFFAOYSA-N",
      "SCHEMBL77012",
    ],
  },
  {
    primary_id: "D07OIR",
    names: ["HF-10"],
  },
  {
    primary_id: "D07OIX",
    names: [
      "Iodipamide",
      "Adipiodon",
      "Adipiodona",
      "Adipiodone",
      "Adipiodonum",
      "Bilignost",
      "Bilignostum",
      "Biligrafin",
      "Cholografin",
      "Cholospect",
      "IDB",
      "Transbilix",
      "Iodipamic Acid",
      "Iodipamide [USAN]",
      "Adipiodona [INN-Spanish]",
      "Adipiodonum [INN-Latin]",
      "Iodipamide (USP)",
      "Adipiodone (JAN/INN)",
      "N,N''-Adipoyl-Bis(3-Amino-2,4,6-Triiodbenzoesaeure)",
      "3,3'(Adipoyldiimino)Bis(2,4,6-Triiodobenzoic Acid)",
      "3,3'-(Adipoyldiimino)Bis(2,4,6-Triiodobenzoic Acid)",
      "3,3'-Adipoyldiiminobis(2,4,6-Triiodobenzoic Acid)",
      "3,3'-[(1,6-DIOXOHEXANE-1,6-DIYL)DIIMINO]BIS(2,4,6-TRIIODOBENZOIC ACID)",
      "3-[5-[(3-CARBOXY-2,4,6-TRIIODO-PHENYL)CARBAMOYL]PENTANOYLAMINO]-2,4,6-TRIIODO-BENZOIC ACID",
      "3-[[6-(3-Carboxy-2,4,6-Triiodoanilino)-6-Oxohexanoyl]amino]-2,4,6-Triiodobenzoic Acid",
    ],
  },
  {
    primary_id: "D07OIZ",
    names: [
      "R-84",
      "AT-001, Affitech",
      "VEGF Receptor 2 Modulator (Human Monoclonal Antibody, Cancer), Affitech",
      "VEGF Receptor 2 Modulator (Human Monoclonal Antibody, Cancer), Peregrine Pharmaceuticals",
    ],
  },
  {
    primary_id: "D07OJB",
    names: ["Hemporfin Photodynamic Therapy"],
  },
  {
    primary_id: "D07OJZ",
    names: [
      "INCB28060",
      "Capmatinib",
      "1029712-80-8",
      "INC-280",
      "INC280",
      "UNII-TY34L4F9OZ",
      "2-Fluoro-N-Methyl-4-(7-(Quinolin-6-Ylmethyl)Imidazo[1,2-B][1,2,4]triazin-2-Yl)Benzamide",
      "INC28060",
      "INCB-28060",
      "INCB28060(Capmatinib)",
      "NVP-INC280",
      "TY34L4F9OZ",
      "Capmatinib (INCB28060)",
      "INCB 28060",
      "2-Fluoro-N-Methyl-4-[7-[(Quinolin-6-Yl)Methyl]imidazo[1,2-B]-[1,2,4]triazin-2-Yl]benzamide",
      "BenzaMide, 2-Fluoro-N-Methyl-4-[7-(6-QuinolinylMethyl)IMidazo[1,2-B][1,2,4]triazin-2-Yl]-",
      "C23H17FN6O",
    ],
  },
  {
    primary_id: "D07OKS",
    names: ["1,8,9-Trimethoxy-9,10-Dihydro-Anthracene", "CHEMBL125328"],
  },
  {
    primary_id: "D07OKT",
    names: [
      "3-Carbazol-9-Yl-Propionic Acid",
      "6622-54-4",
      "3-(9H-Carbazol-9-Yl)Propanoic Acid",
      "3-Carbazol-9-Ylpropanoic Acid",
      "CHEMBL184817",
      "Carbazole-9-Propionic Acid",
      "NSC400",
      "Carbazol-N-Propionsaure",
      "AC1Q75LO",
      "AC1Q5WE7",
      "TimTec1_004366",
      "Oprea1_037143",
      "Oprea1_183633",
      "SCHEMBL150033",
      "9H-Carbazole-9-Propionic Acid",
      "AC1L56H9",
      "DTXSID30277021",
      "3-(Carbazol-9-Yl)Propanoic Acid",
      "NSC-400",
      "MolPort-000-474-043",
      "3-(Carbazol-9-Yl)Propionic Acid",
      "HMS1546G10",
      "NSC54636",
      "ZINC1841595",
      "STK387004",
      "BDBM50152856",
      "7615AE",
      "BBL007862",
      "SBB010078",
    ],
  },
  {
    primary_id: "D07OLL",
    names: ["4-((Pyridin-4-Ylthio)Methyl)Benzene-1,2-Diol", "CHEMBL199691"],
  },
  {
    primary_id: "D07OLO",
    names: ["Milacemide"],
  },
  {
    primary_id: "D07OLQ",
    names: ["Pyrrolo-Pyrimidine Derivative 6"],
  },
  {
    primary_id: "D07OME",
    names: [
      "1-Methyloxy-4-Sulfone-Benzene",
      "1-METHYLOXY-4-SULFONE-BENZENE",
      "4-Methoxybenzenesulfinate",
      "AC1MZTZ7",
      "SCHEMBL13838953",
      "YVZWQPOTHRMEQW-UHFFFAOYSA-M",
      "SBB082464",
      "1-(Dioxidosulfanyl)-4-Methoxybenzene",
      "ZINC150339282",
      "AKOS004898433",
      "DB03033",
    ],
  },
  {
    primary_id: "D07OMK",
    names: [
      "RO-48-6791",
      "3-[5-(Dipropylaminomethyl)-1,2,4-Oxadiazol-3-Yl]-8-Fluoro-5-Methyl-5,6-Dihydro-4H-Imidazo[1,5-A][1,4]benzodiazepin-6-One",
    ],
  },
  {
    primary_id: "D07OMS",
    names: ["PD-163140"],
  },
  {
    primary_id: "D07OMY",
    names: ["3-Phenyl-5-Sulfamoyl-1H-Indole-2-Carboxamide", "CHEMBL512633"],
  },
  {
    primary_id: "D07ONP",
    names: [
      "Phenacemide",
      "Acetylureum",
      "Carbanmide",
      "Cetylureum",
      "Comitiadone",
      "Eferon",
      "Efron",
      "Epheron",
      "Epiclase",
      "Felurea",
      "Fenacemid",
      "Fenacemida",
      "Fenacemide;Fenacetamide",
      "Fenilep",
      "Fenised",
      "Fenostenyl",
      "Fenural",
      "Fenurea",
      "Fenurone",
      "Fenylacetylmocovina",
      "Fenytan",
      "Neophedan",
      "Neophenal",
      "Phacetur",
      "Phenacalum",
      "Phenacemidum",
      "Phenacereum",
      "Phenacerum",
      "Phenacetur",
      "Phenacetylcarbamide",
      "Phenacetylurea",
      "Phenarone",
      "Phenicarb",
      "Phenuron",
      "Phenurone",
      "Phenutal",
      "Phenylacetylurea",
      "Phenylacetyluree",
      "Phenyrit",
      "Phetylureum",
      "Carbamide Phenylacetate",
      "Fenacemide [DCIT]",
      "Fenylacetylmocovina [Czech]",
      "Phenylacetyluree [French]",
      "A-1348",
      "Alpha-Phenylacetylurea",
      "Fenacemida [INN-Spanish]",
      "Fenacetil-Karbamide",
      "Phenacemide [INN:BAN]",
      "Phenacemidum [INN-Latin]",
      "Phenurone (TN)",
      "Sym-PHENYLACETYLUREA",
      "N-(Aminocarbonyl)Benzeneacetamide",
      "Phenacemide (JAN/INN)",
      "N-Carbamoyl-2-Phenylacetamide",
      "N-(Aminocarbonyl)-2-Phenylacetamide",
      "(2-Phenylacetyl)Urea",
      "(Phenylacetyl)Urea",
    ],
  },
  {
    primary_id: "D07ONX",
    names: [
      "Beta-D-Glucose",
      "Beta-D-Glucopyranose",
      "492-61-5",
      "Glucoside",
      "B-D-Glucopyranose",
      "Beta-Glucose",
      "Beta-Dextrose",
      "B-Glucose",
      "B-D-Glucose",
      "UNII-J4R00M814D",
      "28905-12-6",
      ".beta.-D-Glucopyranose",
      "9001-37-0",
      "(2R,3R,4S,5S,6R)-6-(Hydroxymethyl)Tetrahydro-2H-Pyran-2,3,4,5-Tetraol",
      "CHEMBL1614854",
      "CHEBI:15903",
      "WQZGKKKJIJFFOK-VFUOTHLCSA-N",
      "J4R00M814D",
      "Curdlan",
      "(2R,3R,4S,5S,6R)-6-(Hydroxymethyl)Oxane-2,3,4,5-Tetrol",
      ".beta.-D-Glucose",
      "BGC",
      "ZYMOSAN",
      "Glucose, (Beta-D)-Isomer",
      "Callose",
      "B-Dextrose",
      "Beta-D-Glucopyranose, Anhydrous",
    ],
  },
  {
    primary_id: "D07ONY",
    names: [
      "5-(3-Chloro-Benzyl)-1H-Pyrazole-3-Carboxylic Acid",
      "CHEMBL394441",
      "595610-55-2",
      "1H-Pyrazole-3-Carboxylic Acid, 5-[(3-Chlorophenyl)Methyl]-",
      "SCHEMBL569462",
      "CTK1D9284",
      "DTXSID30472377",
      "BDBM50216550",
      "AKOS030620128",
      "5-Meta-Chlorobenzyl-3-Carboxyl-Pyrazole",
      "5-(3-Chlorobenzyl)-1H-Pyrazole-3-Carboxylic Acid",
      "3-(3-Chlorobenzyl)-1H-Pyrazole-5-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D07OOA",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 2"],
  },
  {
    primary_id: "D07OOG",
    names: ["Sub[-Trp-Arg-Nva-Arg-Tyr-NH2]2"],
  },
  {
    primary_id: "D07OOI",
    names: [
      "[3H]GBR12935",
      "1-[2-(Diphenylmethoxy)Ethyl]-4-(3-Phenylpropyl)Piperazine",
      "Gbr 12935",
      "76778-22-8",
      "GBR-12935",
      "GBR12935",
      "UNII-9J9974WIBA",
      "CHEMBL26320",
      "9J9974WIBA",
      "CHEBI:64093",
      "1-[2-(Benzhydryloxy)Ethyl]-4-(3-Phenylpropyl)Piperazine",
      "1-(2-(Benzhydryloxy)Ethyl)-4-(3-Phenylpropyl)Piperazine",
      "Piperazine, 1-(2-(Diphenylmethoxy)Ethyl)-4-(3-Phenylpropyl)-",
      "Piperazine, 1-[2-(Diphenylmethoxy)Ethyl]-4-(3-Phenylpropyl)-",
      "1-(2-(Diphenylmethoxy)Ethyl)-4-(3-Phenylpropyl)Piperazine",
      "[3H]-GBR12935",
    ],
  },
  {
    primary_id: "D07OON",
    names: ["3-Ethyl-3-Isopropyl-Dihydro-Furan-2-One", "CHEMBL95665"],
  },
  {
    primary_id: "D07OOW",
    names: [
      "(South)-Methanocarba-Thymidine",
      "(SOUTH)-METHANOCARBA-THYMIDINE",
      "South-Methanocarbathymidine",
      "1-[(1S,3S,4R,5S)-3-Hydroxy-4-(Hydroxymethyl)Bicyclo[3.1.0]hex-1-Yl]-5-Methylpyrimidine-2,4(1H,3H)-Dione",
      "SCT",
      "1-[(1S,3S,4R,5S)-3-Hydroxy-4-(Hydroxymethyl)Bicyclo[3.1.0]hexan-1-Yl]thymine",
      "(1S,3S,4R,5S)-3-Hydroxy-4-Hydroxymethyl-1-(5-Methyl-2,4-Dioxo-1,2,3,4-Tetrahydropyrimidin-1-Yl)Bicyclo[3.1.0]hexane",
      "[(S)-Methanocarba-T]",
      "South-Methanocarba-Thymine",
      "AC1L9L1U",
      "2'-Exo-Methanocarbathymidine",
      "CHEBI:45586",
      "XRMLXZVSFIBRRJ-PEFMBERDSA-N",
      "DB02921",
    ],
  },
  {
    primary_id: "D07OQF",
    names: ["PMID25489658C4d"],
  },
  {
    primary_id: "D07OQJ",
    names: ["GM-CAIX"],
  },
  {
    primary_id: "D07OQX",
    names: ["4-Butyl-Thiazolidin-(2E)-Ylideneamine", "CHEMBL363049", "SCHEMBL7090223"],
  },
  {
    primary_id: "D07OQZ",
    names: ["Adenosine A2b Antagonists", "Adenosine A2b Antagonists, Hoffman-La Roche"],
  },
  {
    primary_id: "D07ORE",
    names: ["TVGV-1 Vaccine"],
  },
  {
    primary_id: "D07ORL",
    names: ["Il-94", "CHEMBL2386715", "GTPL8132", "SCHEMBL15216595", "BDBM50434330"],
  },
  {
    primary_id: "D07ORO",
    names: ["Solamargine", "Curaderm (TN)"],
  },
  {
    primary_id: "D07ORY",
    names: ["1-(3,5-Dichlorobenzyl)-1H-Imidazole", "CHEMBL210125", "SCHEMBL3980802"],
  },
  {
    primary_id: "D07OSA",
    names: ["RS67506"],
  },
  {
    primary_id: "D07OSF",
    names: ["Des-AA1,2,5-[D-Nal8,IAmp9,(NalphaMe)Phe11]SRIF", "CHEMBL415143"],
  },
  {
    primary_id: "D07OSK",
    names: ["VEGF Receptor 2 Kinase Inhibitor I"],
  },
  {
    primary_id: "D07OSS",
    names: ["9cUAB-30"],
  },
  {
    primary_id: "D07OSW",
    names: ["2-(Sulfanylmethyl)Phenylphosphonic Acid", "CHEMBL1173338"],
  },
  {
    primary_id: "D07OTU",
    names: ["NRX-1050"],
  },
  {
    primary_id: "D07OTW",
    names: ["WT2725"],
  },
  {
    primary_id: "D07OUE",
    names: ["AUX-202", "AUX-201", "Bone Metastases Therapy, Auxeris", "AUX-202.1", "AUX-202.2", "AUX-202.3"],
  },
  {
    primary_id: "D07OVM",
    names: ["Benzothiazine-Carboxamide Compound 4"],
  },
  {
    primary_id: "D07OVY",
    names: [
      "5-Iodo-1H-Indazole",
      "55919-82-9",
      "5-IODOINDAZOLE",
      "5-IODO (1H)INDAZOLE",
      "5-Iodanyl-1H-Indazole",
      "1H-INDAZOLE, 5-IODO-",
      "CHEMBL391348",
      "AK-27902",
      "5-Iodo-1H-Indazole, 95%",
      "5-Iodo(1H)Indazole",
      "5-Iodio-1H-Indazole",
      "PubChem20575",
      "SCHEMBL264176",
      "5-IODO-(1H)-INDAZOLE",
      "CTK5A4375",
      "DTXSID00619590",
      "CGCHCLICSHIAAM-UHFFFAOYSA-N",
      "MolPort-000-004-505",
      "BCP27249",
      "CS-D1249",
      "ZX-AT020681",
      "KS-000003HB",
      "OR2756",
      "ANW-50835",
      "ZINC14983580",
      "CI-264",
      "BDBM50209243",
      "RW3195",
      "AKOS007930518",
      "QC-2142",
      "CM10802",
      "RP05949",
      "PB10471",
      "VI20054",
      "TRA0057528",
      "FCH1323980",
      "AS-8203",
      "CJ-14773",
    ],
  },
  {
    primary_id: "D07OWC",
    names: ["OntecizumabAmatuximab"],
  },
  {
    primary_id: "D07OXC",
    names: ["AM7"],
  },
  {
    primary_id: "D07OXW",
    names: ["FVh1 DNA-Based Influenza Vaccine"],
  },
  {
    primary_id: "D07OYT",
    names: ["NPB-2"],
  },
  {
    primary_id: "D07OZO",
    names: [
      "BMP-4",
      "BMP-4 (Stem Cell Therapy, Glioblastoma)",
      "Bone Morphogenic Protein (Stem Cell Therapy, Glioblastoma), MabPrex",
      "BMP-4 (Stem Cell Therapy, Glioblastoma), Mabprex",
    ],
  },
  {
    primary_id: "D07OZR",
    names: [
      "TKI258",
      "Dovitinib",
      "405169-16-6",
      "CHIR-258",
      "TKI-258",
      "Chir 258",
      "4-Amino-5-Fluoro-3-[5-(4-Methylpiperazin-1-Yl)-1H-Benzimidazol-2-Yl]quinolin-2(1H)-One",
      "CHIR258",
      "Dovitinib (TKI-258, CHIR-258)",
      "UNII-I35H55G906",
      "CHEMBL522892",
      "804551-71-1",
      "I35H55G906",
      "TKI 258",
      "1027263-12-2",
      "(3Z)-4-Amino-5-Fluoro-3-[5-(4-Methyl-1-Piperazinyl)-1,3-Dihydro-2H-Benzimidazol-2-Ylidene]-2(3H)-Quinolinone",
      "C21H21FN6O",
      "4-Amino-5-Fluoro-3-(5-(4-Methylpiperazin-1-Yl)-1H-Benzimidazol-2-Yl)Quinolin-2(1H)-One",
    ],
  },
  {
    primary_id: "D07PAF",
    names: ["PHENMEDIPHAM"],
  },
  {
    primary_id: "D07PAG",
    names: ["U-97456"],
  },
  {
    primary_id: "D07PAO",
    names: [
      "Sulphadoxine",
      "2447-57-6",
      "Sulforthomidine",
      "Fanasil",
      "Sulphormethoxine",
      "4-Amino-N-(5,6-Dimethoxypyrimidin-4-Yl)Benzenesulfonamide",
      "Sulfadoxinum",
      "Sulfadoxina",
      "Sulfadoxin",
      "Fanzil",
      "Solfadossina",
      "Ro 4-4393",
      "Solfadossina [DCIT]",
      "Sulfadoxinum [INN-Latin]",
      "Sulfadoxina [INN-Spanish]",
      "4-Sulfanilamido-5,6-Dimethoxypyrimidine",
      "4-Amino-N-(5,6-Dimethoxy-4-Pyrimidinyl)Benzenesulfonamide",
      "Benzenesulfonamide, 4-Amino-N-(5,6-Dimethoxy-4-Pyrimidinyl)-",
      "WR 4873",
      "N'-(5,6-Dimethoxy-4-Pyrimidyl)Sulfanilamide",
      "UNII-88463U4SM5",
      "Orthosulfin",
      "Sulformethoxine",
      "Sulformetoxine",
      "Sulphormetoxin",
      "Sulphorthodimethoxine",
      "WR 4073",
      "Ro-4-4393",
      "Sanasil: Sulfadoxine: Sulformetoxin",
      "Sulfadoxine (JAN/USP/INN)",
      "Sulfadoxine [USAN:INN:BAN:JAN]",
      "N1-(5,6-Dimethoxy-4-Pyrimidinyl)Sulfanilamide",
      "N(Sup 1)-(5,6-Dimethoxy-4-Pyrimidinyl)Sulfanilamide",
      "4-Amino-N-[5,6-Bis(Methyloxy)Pyrimidin-4-Yl]benzenesulfonamide",
      "6-(4-Aminobenzenesulfonamido)-4,5-Dimethoxypyrimidine",
      "RS-1653",
    ],
  },
  {
    primary_id: "D07PAT",
    names: ["(RS/SR)-2-[1-(4-Chlorophenyl)Pentyl]piperidine", "CHEMBL218849", "BDBM50202388"],
  },
  {
    primary_id: "D07PAY",
    names: ["B-956", "B-1086", "B-957"],
  },
  {
    primary_id: "D07PBZ",
    names: ["Epoetin Alfa"],
  },
  {
    primary_id: "D07PCI",
    names: ["Carboprost Tromethamine"],
  },
  {
    primary_id: "D07PCS",
    names: ["N-Hydroxy-N-Methyl-2-Naphthalen-2-Yl-Propionamide", "CHEMBL146477"],
  },
  {
    primary_id: "D07PCZ",
    names: ["AR20.5"],
  },
  {
    primary_id: "D07PEH",
    names: ["AZD-4316"],
  },
  {
    primary_id: "D07PFG",
    names: ["HL-009", "Adenosylcobamide", "Adenosylcobalamin (Vitamin B12) (Nanoliposome, Allergy), HanAll"],
  },
  {
    primary_id: "D07PFU",
    names: ["JNJ-63733657"],
  },
  {
    primary_id: "D07PHS",
    names: ["CXCL8"],
  },
  {
    primary_id: "D07PHT",
    names: [
      "NSC-289311",
      "CHEMBL459502",
      "AC1L8A89",
      "SCHEMBL11697991",
      "BDBM50254015",
      "1-[2-(3,4-Dichlorophenyl)Sulfanyl-2-(4-Methoxyphenyl)Ethyl]imidazole",
    ],
  },
  {
    primary_id: "D07PHV",
    names: [
      "1-Bromo-4-Imidazol-1-Ylmethyl-Xanthen-9-One",
      "CHEMBL421829",
      "BDBM50097367",
      "4-((1H-Imidazol-1-Yl)Methyl)-1-Bromo-9H-Xanthen-9-One",
    ],
  },
  {
    primary_id: "D07PHW",
    names: ["(2S)-Aminobutyryl-L-Prolinamide", "CHEMBL224063"],
  },
  {
    primary_id: "D07PIA",
    names: ["AG-1350"],
  },
  {
    primary_id: "D07PIH",
    names: ["Pyrazole Derivative 60"],
  },
  {
    primary_id: "D07PJR",
    names: ["Cyclic Compound 2"],
  },
  {
    primary_id: "D07PJS",
    names: ["JNJ-68284528"],
  },
  {
    primary_id: "D07PKH",
    names: ["GSK931145"],
  },
  {
    primary_id: "D07PKU",
    names: ["4-Aryl Quinol Derivative 3"],
  },
  {
    primary_id: "D07PLJ",
    names: ["PMID27109571-Compound-17"],
  },
  {
    primary_id: "D07PLK",
    names: ["WIN-64821"],
  },
  {
    primary_id: "D07PLS",
    names: ["2-Fluorophenyl 4-(Heptyloxy)Phenylcarbamate", "CHEMBL610774"],
  },
  {
    primary_id: "D07PMG",
    names: [
      "Afeletecan",
      "Afeletecan Hydrochloride",
      "Camptothecin Glycoconjugate, Bayer",
      "Topoisomerase I Inhibitors, Bayer",
    ],
  },
  {
    primary_id: "D07PMH",
    names: ["VH-0445 Based Conjugated Therapeutics"],
  },
  {
    primary_id: "D07PML",
    names: ["Ublituximab + Umbralisib"],
  },
  {
    primary_id: "D07PMP",
    names: ["PKI-402"],
  },
  {
    primary_id: "D07PMU",
    names: ["111In-OC-125 F(Ab1/2)-DTPA"],
  },
  {
    primary_id: "D07PNA",
    names: [
      "2-Nonylamido-5-Sulfonamidoindane",
      "CHEMBL208532",
      "N-(5-Sulfamoyl-2,3-Dihydro-1H-Inden-2-Yl)Decanamide",
      "Indanesulfonamide Derivative 12g",
    ],
  },
  {
    primary_id: "D07PNC",
    names: [
      "Organon",
      "Allylestrenol",
      "Allyloestrenol",
      "Allyloestrenolum",
      "Gestanin",
      "Gestanol",
      "Gestanon",
      "Gestanyn",
      "Orageston",
      "Perselin",
      "Turinal",
      "Allilestrenolo [DCIT]",
      "Alilestrenol [INN-Spanish]",
      "Allylestrenol [INN:JAN]",
      "Allylestrenol(Jan)",
      "Allylestrenolum [INN-Latin]",
      "Gestanon-R",
      "Perselin (TN)",
      "Allylestrenol (JAN/INN)",
      "(17beta)-17-Prop-2-En-1-Ylestr-4-En-17-Ol",
      "(8R,9S,10R,13S,14S,17R)-13-Methyl-17-Prop-2-Enyl-2,3,6,7,8,9,10,11,12,14,15,16-Dodecahydro-1H-Cyclopenta[a]phenanthren-17-Ol",
      "17-(2-Propenyl)Estr-4-En-17-Ol",
      "17-Hydroxy-17-Alpha-Allyl-4-Estrene",
      "17-Alpha-Allyl-17-Beta-Hydroxy-4-Estrene",
      "17-Alpha-Allyl-17-Beta-Hydroxy-Delta(Sup 4)-Estren",
      "17-Alpha-Allyl-3-Deoxy-19-Nortestosterone",
      "17-Alpha-Allyl-4-Oestrene-17-Beta-Ol",
      "17-Alpha-Allylestr-4-En-17-Beta-Ol",
      "17-Alpha-Allylhydroxy-19-Nor-4-Androstene",
      "17.alpha.-Allyl-17-.beta.-Hydroxy-.delta.(Sup 4)-Estren",
      "17.alpha.-Allyl-17.beta.-Hydroxy-4-Estrene",
      "17.alpha.-Allyl-3-Deoxy-19-Nortestosterone",
      "17.alpha.-Allyl-4-Destrene-17.beta.-Ol",
      "17.alpha.-Allyl-4-Estren-17.beta.-Ol",
      "17.alpha.-Allyl-4-Oestrene-17.beta.-Ol",
      "17.alpha.-Allylestr-4-En-17.beta.-Ol",
      "17.alpha.-Allylestrenol",
      "17alpha-Allyl-17beta-Hydroxy-4-Estrene",
      "17alpha-Allyl-19-Nor-Delta-4-Androstene-17beta-Ol",
      "17alpha-Allyl-3-Deoxy-19-Nortestosterone",
      "17alpha-Allyl-4-Destrene-17beta-Ol",
      "17alpha-Allyl-4-Estren-17beta-Ol",
      "17alpha-Allyl-4-Oestrene-17beta-Ol",
      "17alpha-Allylestr-4-En-17beta-Ol",
      "17alpha-Allylestrenol",
      "21-Methylene-19-Nor-17-Alpha-Preg-4-En-17-Ol",
      "3-Deoxy-17-Alpha-Allyl-19-Nortestosterone",
    ],
  },
  {
    primary_id: "D07PND",
    names: ["Diphenyl Purine Derivative 1"],
  },
  {
    primary_id: "D07PNM",
    names: ["Methyl Isocyanate"],
  },
  {
    primary_id: "D07PNQ",
    names: [
      "CD200Fc",
      "CD200tr",
      "Truncated CD200, TTI",
      "Truncated OX-2, TTI",
      "Soluble CD200 (OX-2) Fusion Protein, Trillium",
    ],
  },
  {
    primary_id: "D07PNT",
    names: ["XL999"],
  },
  {
    primary_id: "D07PNX",
    names: ["PMID26815044-Compound-115"],
  },
  {
    primary_id: "D07POC",
    names: ["Erlotinib"],
  },
  {
    primary_id: "D07POD",
    names: ["PMID26666989-Compound-Figure9middle05"],
  },
  {
    primary_id: "D07POS",
    names: ["Alkyl Mannoside Derivative 12"],
  },
  {
    primary_id: "D07POY",
    names: ["Peptagon"],
  },
  {
    primary_id: "D07PPK",
    names: ["4-Phenyl-4-[1H-Imidazol-2-Yl]-Piperidine", "CHEMBL382326", "SCHEMBL14173644"],
  },
  {
    primary_id: "D07PPM",
    names: ["PSB-10"],
  },
  {
    primary_id: "D07PQJ",
    names: ["ARQ-087"],
  },
  {
    primary_id: "D07PQO",
    names: ["RAP-164"],
  },
  {
    primary_id: "D07PRA",
    names: ["UX-005"],
  },
  {
    primary_id: "D07PRC",
    names: ["BAL-30072"],
  },
  {
    primary_id: "D07PRH",
    names: ["DE-098", "ARG-098", "Anti-Fas Antibody, Santen", "Anti-APO-1 Antibody, Santen"],
  },
  {
    primary_id: "D07PSF",
    names: [
      "Aspirin Triggered Lipoxin A4",
      "Aspirin-Triggered Lipoxin A4",
      "ATL",
      "Aspirin Triggered 15-Epi Lipoxin A4",
    ],
  },
  {
    primary_id: "D07PSG",
    names: ["5-Phenyl-2-(4-(Piperidin-1-Yl)Butyl)Oxazole", "CHEMBL1257573"],
  },
  {
    primary_id: "D07PSJ",
    names: ["Resorcinol Compound 24"],
  },
  {
    primary_id: "D07PSL",
    names: ["PLX7486"],
  },
  {
    primary_id: "D07PSP",
    names: ["U-75875"],
  },
  {
    primary_id: "D07PSW",
    names: [
      "N-(4-Phenyl-Pyridin-2-Yl)-Guanidine",
      "CHEMBL54467",
      "Guanidine, N-(4-Phenyl-2-Pyridinyl)-",
      "2-(4-Phenyl-2-Pyridinyl)Guanidine",
      "BDBM50108355",
    ],
  },
  {
    primary_id: "D07PSX",
    names: ["ABBV-084"],
  },
  {
    primary_id: "D07PTD",
    names: ["N-(6-Phenyl-1H-Indazol-3-Yl)Butyramide", "CHEMBL1086174", "SCHEMBL4493121"],
  },
  {
    primary_id: "D07PTH",
    names: ["13-Cis-Retinoic Acid, UCSD", "CRA, UCSD"],
  },
  {
    primary_id: "D07PTU",
    names: ["Imidazo[4,5-C]pyridine Derivative 1"],
  },
  {
    primary_id: "D07PUS",
    names: ["DSM1"],
  },
  {
    primary_id: "D07PVB",
    names: ["Squalamine"],
  },
  {
    primary_id: "D07PVS",
    names: ["ISIS 173848"],
  },
  {
    primary_id: "D07PVT",
    names: ["NVC-638"],
  },
  {
    primary_id: "D07PVX",
    names: ["Aryl Mannoside Derivative 21"],
  },
  {
    primary_id: "D07PWA",
    names: ["IkappaB-AlphaM"],
  },
  {
    primary_id: "D07PWY",
    names: [
      "Anti-RON Receptor Mabs",
      "AV-368 Series",
      "Hu-07F01",
      "Hu-29B06",
      "Anti-RON Receptor MAbs (Cancer)",
      "Anti-RON Receptor MAbs (Cancer), AVEO Pharmaceuticals",
      "Anti-RON Receptor MAbs (Cancer), Centocor Ortho Biotech",
    ],
  },
  {
    primary_id: "D07PXQ",
    names: ["ASP-2151"],
  },
  {
    primary_id: "D07PXR",
    names: [
      "5-Fluoro-2-(Pyridin-2-Yl)-1H-Benzo[d]imidazole",
      "875468-81-8",
      "CHEMBL199982",
      "5-FLUORO-2-(2-PYRIDYL)-1H-BENZIMIDAZOLE",
      "1H-Benzimidazole, 6-Fluoro-2-(2-Pyridinyl)-",
      "6-Fluoro-2-(Pyridin-2-Yl)-1H-1,3-Benzodiazole",
      "SCHEMBL124265",
      "DTXSID90470258",
      "MolPort-009-677-335",
      "MolPort-027-941-496",
      "ZINC13679485",
      "BDBM50180735",
      "AKOS010206775",
      "NE40467",
      "PC450144",
      "KB-87704",
      "BC4242268",
      "5-Fluoro-2-Pyridin-2-Yl-1H-Benzoimidazole",
      "TL80090069",
      "US8748618, LD-1-31",
    ],
  },
  {
    primary_id: "D07PYC",
    names: [
      "3-Indan-(1Z)-Ylidenemethyl-Pyridine",
      "(3-Pyridylmethylene)Indane 1b",
      "BDBM8583",
      "SCHEMBL4221374",
      "CHEMBL363621",
      "AC1O7048",
      "3-[(Z)-2,3-Dihydroinden-1-Ylidenemethyl]pyridine",
      "3-[(1Z)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine",
      "3-[(Z)-2,3-Dihydro-1H-Inden-1-Ylidenemethyl]pyridine Hydrochloride",
    ],
  },
  {
    primary_id: "D07PYV",
    names: [
      "2-Aminopyridine",
      "2-AMINOPYRIDINE",
      "504-29-0",
      "Pyridin-2-Amine",
      "2-Pyridinamine",
      "2-Pyridylamine",
      "Aminopyridine",
      "Pyridinamine",
      "O-Aminopyridine",
      "2-Aminopyridin",
      "2-Aminopryidine",
      "Amino-2 Pyridine",
      "Pyridine, 2-Amino-",
      "1,2-Dihydro-2-Iminopyridine",
      "Alpha-Pyridylamine",
      "Alpha-Pyridinamine",
      "2-Pyridinylnitrene",
      "Alpha-Aminopyridine",
      "Pyridin-2-Ylamine",
      "NSC 431",
      "UNII-WSX981HEWU",
      "2-Pyridinylamine",
      "2-Amino Pyridine",
      "Pyridine, Amino-",
      "Pyridin-2-Yl-Amine",
      ".beta.-Pyridylamine",
      ".alpha.-Pyridylamine",
      ".alpha.-Pyridinamine",
      "CCRIS 4747",
      ".alpha.-Aminopyridine",
      "26445-05-6",
      "HSDB 206",
    ],
  },
  {
    primary_id: "D07PZF",
    names: [
      "1-(Bis(3-Bromophenyl)Methylene)Thiosemicarbazide",
      "CHEMBL384296",
      "SCHEMBL2675409",
      "DLNPHAFNMNLYRY-UHFFFAOYSA-N",
      "BDBM50189279",
      "Bis(3-Bromophenyl) Ketone Thiosemicarbazone",
    ],
  },
  {
    primary_id: "D07PZQ",
    names: ["L-Serine-O-Phosphate"],
  },
  {
    primary_id: "D07QAA",
    names: [
      "BRL-46470",
      "CHEMBL285999",
      "SCHEMBL634827",
      "BDBM50060257",
      "BC600835",
      "N-(8-Methyl-8-Azabicyclo[3.2.1]oct-3-Yl)-2,3-Dihydro-3,3-Dimethyl-1H-Indole-1-Carboxamide",
      "3,3-Dimethyl-2,3-Dihydro-Indole-1-Carboxylic Acid (8-Methyl-8-Aza-Bicyclo[3.2.1]oct-3-Yl)-Amide",
      "Endo-N-(8-Methyl-8-Azabicyclo [3.2.1]oct-3-Yl)2,3-Dihydro-3,3 Dimethyl-Indole-1-Carboxamide",
    ],
  },
  {
    primary_id: "D07QAF",
    names: [
      "BI 207127",
      "Deleobuvir",
      "UNII-58BU988K90",
      "58BU988K90",
      "863884-77-9",
      "Deleobuvir [USAN:INN]",
      "SCHEMBL900174",
      "SCHEMBL900176",
      "CHEMBL2403318",
      "DTXSID40235516",
      "BMAIGAHXAJEULY-UKTHLTGXSA-N",
      "SB16520",
      "BI-207127",
      "1221574-24-8",
      "(E)-3-(2-(1-(2-(5-Bromopyrimidin-2-Yl)-3-Cyclopentyl-1-Methyl-1H-Indole-6-Carboxamido)Cyclobutyl)-1-Methyl-1H-Benzo[d]imidazol-6-Yl)Acrylic",
      "(E)-3-(2-(1-(2-(5-Bromopyrimidin-2-Yl)-3-Cyclopentyl-1-Methyl-1H-Indole-6-Carboxamido)Cyclobutyl)-1-Methyl-1H-Benzo[d]imidazol-6-Yl)Acrylic Acid",
      "2-Propenoic Acid, 3-(2-(1-(((2-(5-Bromo-2-Pyrimidinyl)-3-Cyclopentyl-1-Methyl-1H-Indol-6-Yl)Carbonyl)Amino)Cyclobutyl)-1-Methyl-1H-Benzimidazol-6-Yl)-, (2E)-",
      "(E)-3-(2-(1",
    ],
  },
  {
    primary_id: "D07QAH",
    names: ["Biaryl Mannoside Derivative 17"],
  },
  {
    primary_id: "D07QAK",
    names: ["Xanomeline Tartrate"],
  },
  {
    primary_id: "D07QAR",
    names: ["Dalbavancin"],
  },
  {
    primary_id: "D07QAX",
    names: ["PMID15686906C17"],
  },
  {
    primary_id: "D07QBF",
    names: ["PMID28460551-Compound-6"],
  },
  {
    primary_id: "D07QBJ",
    names: ["HJP-272"],
  },
  {
    primary_id: "D07QBL",
    names: ["PMID27539678-Compound-12"],
  },
  {
    primary_id: "D07QBQ",
    names: [
      "6-Chloro-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "6-Chloroquinoxaline-2,3-Diol",
      "6639-79-8",
      "2,3-Dihydroxy-6-Chloroquinoxaline",
      "6-Chloro-1,4-Dihydroquinoxaline-2,3-Dione",
      "6-Chloroquinoxaline-2,3(1H,4H)-Dione",
      "6-Chloro-2,3-Dihydroxyquinoxaline",
      "6-Chloro-1,2,3,4-Tetrahydroquinoxaline-2,3-Dione",
      "169-14-2",
      "2,3-Quinoxalinedione, 6-Chloro-1,4-Dihydro-",
      "CHEMBL21641",
      "6-CHLORO-2,3-DIOXO-1,2,3,4-TETRAHYDROQUINOXALINE",
      "6-Chloro-1,4-Dihydro-2,3-Quinoxalinedione",
      "Oprea1_205811",
      "2,3-Quinoxalinediol, 6-Chloro-",
      "EINECS 229-647-9",
      "NSC 48940",
      "PubChem15426",
      "2, 6-Chloro-",
      "AC1Q3ORE",
    ],
  },
  {
    primary_id: "D07QBZ",
    names: ["Arylpropenamides", "Arylpropenamides (HBV Infection)", "Arylpropenamides (HBV Infection), Pharmasset"],
  },
  {
    primary_id: "D07QCA",
    names: ["GDNF-Producing Adult Stem Cell Therapy"],
  },
  {
    primary_id: "D07QCC",
    names: ["SK&F-106686"],
  },
  {
    primary_id: "D07QCE",
    names: [
      "Azathioprine",
      "Azamun",
      "Azanin",
      "Azasan",
      "Azathioprin",
      "Azathioprinum",
      "Azathiopurine",
      "Azatioprin",
      "Azatioprina",
      "Azothioprine",
      "Ccucol",
      "Cytostatics",
      "Immuran",
      "Imuran",
      "Imurek",
      "Imurel",
      "Methylnitroimidazolylmercaptopurine",
      "Muran",
      "Azamun [Czech]",
      "Azathioprine Sodium",
      "Azatiopr In",
      "A 4638",
      "BW 57322",
      "Azamun (TN)",
      "Azasan (TN)",
      "Azathioprinum [INN-Latin]",
      "Azatioprina [INN-Spanish]",
      "BW 57-322",
      "Imuran (TN)",
      "Imurel (TN)",
      "[Methyl(Nitroimidazolyl)Mercaptopurine]",
      "AI-981/34845012",
      "BW-57-322",
      "Azathioprine (JP15/USP/INN)",
      "Azathioprine [USAN:INN:BAN:JAN]",
      "B. W. 57-322",
      "Thiopurine 6-(1-Methyl-4-Nitroimidazol-5-Yl)",
      "Azasan, Imuran, Azamun, BW-57-322, NSC-39084, Azathioprine",
      "6-((1-Methyl-4-Nitro-1H-Imidazol-5-Yl)Thio)-1H-Purine",
      "6-((1-Methyl-4-Nitroimidazol-5-Yl)Thio)Purine",
      "6-(1'-Methyl-4'-Nitro-5'-Imidazolyl)-Mercaptopurine",
      "6-(1'-Methyl-4'-Nitro-5'-Imidazolyl)Mercaptopurine",
      "6-(1-Methyl-4-Nitroimidazol-5-Yl)Thiopurine",
      "6-(1-Methyl-4-Nitroimidazol-5-Ylthio)Purin",
      "6-(1-Methyl-4-Nitroimidazol-5-Ylthio)Purin [Czech]",
      "6-(1-Methyl-P-Nitro-5-Imidazolyl)-Thiopurine",
      "6-(1-Methyl-P-Nitro-5-Imidazolyl)Thiopurine",
      "6-(3-Methyl-5-Nitro-3H-Imidazol-4-Ylsulfanyl)-7H-Purine",
      "6-(3-Methyl-5-Nitroimidazol-4-Yl)Sulfanyl-7H-Purine",
      "6-(Methyl-P-Nitro-5-Imidazolyl)-Thiopurine",
      "6-(Methyl-P-Nitro-5-Imidazolyl)Thiopurine",
      "6-({4-Nitro-1-Methyl-1H-Imidazol-5-Yl}sulfanyl)-7H-Purine",
      "6-1'-Methyl,4'-Nitro,5'-Imidazolyl Mercaptopurine",
      "6-[(1-Methyl-4-Nitroimidazol-5-Yl)-Thio] Purine",
      "6-[(1-Methyl-4-Nitroimidazol-5-Yl)Thio]purine",
      "6-[(1-Methyl-4-Nitro-1H-Imidazol-5-Yl)Sulfanyl]-7H-Purine",
      "6-[(1-Methyl-4-Nitro-1H-Imidazol-5-Yl)Thio]-1H-Purine",
      "6-[(1-Methyl-4-Nitro-1H-Imidazol-5-Yl)Thio]-7H-Purine",
      "6-[(1-Methyl-4-Nitro-1H-Imidazol-5-Yl)Thio]-9H-Purine",
    ],
  },
  {
    primary_id: "D07QCW",
    names: ["ISIS 13805"],
  },
  {
    primary_id: "D07QDT",
    names: ["MA-0211"],
  },
  {
    primary_id: "D07QDU",
    names: ["1-Benzhydryl-4-(4,4-Diphenylbutyl)Piperazine", "CHEMBL1081016"],
  },
  {
    primary_id: "D07QEH",
    names: ["Ginkgolide X"],
  },
  {
    primary_id: "D07QFM",
    names: ["TP10"],
  },
  {
    primary_id: "D07QFN",
    names: ["3-Methoxy-4-(P-Tolyloxy)Benzonitrile", "CHEMBL464929"],
  },
  {
    primary_id: "D07QFP",
    names: ["Teriflunomide"],
  },
  {
    primary_id: "D07QFU",
    names: ["T-607"],
  },
  {
    primary_id: "D07QGN",
    names: ["CGS-13767"],
  },
  {
    primary_id: "D07QGQ",
    names: ["Noccardimicin E"],
  },
  {
    primary_id: "D07QGX",
    names: [
      "2-Amino-2-(2,4-Difluorophenyl)Acetic Acid",
      "240409-02-3",
      "2,4-DIFLUORO-DL-PHENYLGLYCINE",
      "Amino(2,4-Difluorophenyl)Acetic Acid",
      "CHEMBL382622",
      "Amino-(2,4-Difluoro-Phenyl)-Acetic Acid",
      "Alpha-Amino-2,4-Difluorobenzeneacetic Acid",
      "AC1MC6PH",
      "SCHEMBL1115280",
      "CTK7I4174",
      "DTXSID70371799",
      "MolPort-000-165-848",
      "COIWYFIMAXMSKX-UHFFFAOYSA-N",
      "AC1Q5058",
      "KS-00003QV7",
      "BDBM50179695",
      "SBB090539",
      "AKOS016051288",
      "AKOS000170674",
      "PS-6697",
      "QC-3701",
      "AB11996",
      "RP03495",
      "SC-59456",
      "FT-0691426",
      "BB 0249675",
      "2-AMino-2-(2;4-Difluorophenyl)Acetic Acid",
      "Y8397",
      "EN300-88022",
    ],
  },
  {
    primary_id: "D07QHF",
    names: ["SAR-260093"],
  },
  {
    primary_id: "D07QHM",
    names: ["4-(Hydroxymercury)Benzoic Acid"],
  },
  {
    primary_id: "D07QIP",
    names: ["4-(Thiazol-5-Yl)-Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D07QIQ",
    names: ["Anti-BCMA-CAR-Transduced T Cells"],
  },
  {
    primary_id: "D07QIT",
    names: [
      "GPX-150D",
      "CNDO-101",
      "CNDO-101)",
      "GPX-150",
      "Doxorubicin Analog (Cancer), Coronado",
      "5-Imino-13-Deoxy-Doxorubicin",
    ],
  },
  {
    primary_id: "D07QIU",
    names: [
      "4-(4-Methoxy-Benzylideneamino)-Benzenesulfonamide",
      "CHEMBL271911",
      "N-(4-Methoxybenzylidene)-4-Sulfamoylaniline",
      "66667-56-9",
      "AC1LCE5G",
      "CBDivE_014997",
      "SCHEMBL1517619",
      "4-[(4-Methoxyphenyl)Methylideneamino]benzenesulfonamide",
      "CTK1J4463",
      "DTXSID70347792",
      "MolPort-000-519-122",
      "MLUHDVSELBSKPI-UHFFFAOYSA-N",
      "MLUHDVSELBSKPI-MHWRWJLKSA-N",
      "ZINC131008",
      "BDBM50371762",
      "STK790787",
      "AKOS001583663",
      "MCULE-9167737347",
      "MCULE-3795342438",
      "ST016434",
      "4-(4-Methoxybenzylideneamino)Benzenesulfonamide",
      "Benzenesulfonamide, 4-(4-Methoxybenzylideneamino)-",
      "4-((4-METHOXYBENZYLIDENE)AMINO)BENZENESULFONAMIDE",
    ],
  },
  {
    primary_id: "D07QJD",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp25-]", "CHEMBL270690"],
  },
  {
    primary_id: "D07QJJ",
    names: ["CATECHOL"],
  },
  {
    primary_id: "D07QJK",
    names: ["Plitidepsin", "Aplidin (TN)"],
  },
  {
    primary_id: "D07QJQ",
    names: [
      "2-Methyl-4-Phenylethynyl-Thiazole",
      "CHEMBL63042",
      "Thiazole, 2-Methyl-4-(2-Phenylethynyl)-",
      "SCHEMBL5653485",
      "NZHWBWVBWDOSJY-UHFFFAOYSA-N",
      "2-Methyl-4-(Phenylethynyl)Thiazole",
      "BDBM50122759",
      "329203-01-2",
    ],
  },
  {
    primary_id: "D07QKK",
    names: [
      "CHLORIDE",
      "Chloride Ion",
      "16887-00-6",
      "Chloride(1-)",
      "Chloride Anion",
      "Chlorine, Ion",
      "UNII-Q32ZN48698",
      "Hydrochloric Acid, Ion(1-)",
      "Chlorid",
      "CHEMBL19429",
      "Cl-",
      "CHEBI:17996",
      "Q32ZN48698",
      "Chlorides",
      "Chloride Ions",
      "Chloride (Ion)",
      "Perchloride",
      "Chlorine Anion",
      "Chlorine Ion",
      "Chloride (Cl-)",
      "Chlorine(1-)",
      "Chlorine Ion(1-)",
      "Chloride Ion (1-)",
      "Chlorine, Ion (Cl1-)",
      "AC1L18YJ",
      "Cl(-)",
      "CTK0H5734",
      "BDBM26979",
      "VEXZGXHMUGYJMC-UHFFFAOYSA-M",
      "C0884",
      "AKOS015903642",
      "FT-0755769",
      "3-Carbamoyl-1-Ethoxymethylpyridinium Chloride",
      "1-Butoxymethyl-3-Carbamoyl-Pyridinium",
    ],
  },
  {
    primary_id: "D07QKM",
    names: ["HL-1225"],
  },
  {
    primary_id: "D07QKN",
    names: ["Terpin Hydrate"],
  },
  {
    primary_id: "D07QKP",
    names: ["Pyrrolo-Pyridinone Derivative 4"],
  },
  {
    primary_id: "D07QLD",
    names: ["Pyrazole Derivative 7"],
  },
  {
    primary_id: "D07QLG",
    names: ["Oxilofrine"],
  },
  {
    primary_id: "D07QLZ",
    names: ["AwFwLL-NH2", "CHEMBL509976"],
  },
  {
    primary_id: "D07QMC",
    names: ["3-Benzimidazol-2-Ylhydroquinolin-2-One"],
  },
  {
    primary_id: "D07QMR",
    names: ["N-(1-(4-Phenylbutyl)Piperidin-4-Yl)-1-Naphthamide", "CHEMBL387952"],
  },
  {
    primary_id: "D07QMY",
    names: ["ML 10375"],
  },
  {
    primary_id: "D07QNH",
    names: ["SAIT301"],
  },
  {
    primary_id: "D07QNN",
    names: ["N-Cyclohexyl-2-Phenyl-Acetamide"],
  },
  {
    primary_id: "D07QOG",
    names: ["2-(7-Phenylheptanoyl)Oxazole-4-Carbaldehyde", "CHEMBL461315", "SCHEMBL2162538"],
  },
  {
    primary_id: "D07QPA",
    names: ["Vitamin K", "12001-79-5", "MBWXNTAXLNYFJB-LKUDQCMESA-N", "81818-54-4"],
  },
  {
    primary_id: "D07QPM",
    names: ["Potassium Citrate"],
  },
  {
    primary_id: "D07QPV",
    names: ["KU-58684"],
  },
  {
    primary_id: "D07QQD",
    names: ["Aescin"],
  },
  {
    primary_id: "D07QQP",
    names: [
      "INS-37217",
      "Denufosol Tetrasodium",
      "DCp4U",
      "INS 37217",
      "INS37217",
      "Up4dC",
      "Denufosol Tetrasodium (USAN)",
      "Uridine 5'-(Pentahydrogen Tetraphosphate), P'''-5'-Ester With 2'-Deoxycytidine, Tetrasodium Salt",
      "Tetrasodium [[(3S,5R)-5-(4-Amino-2-Oxopyrimidin-1-Yl)-3-Hydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] [[[(2R,3S,4R,5R)-5-(2,4-Dioxopyrimidin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl]oxy-Hydroxyphosphoryl] Hydrogen Phosphate",
    ],
  },
  {
    primary_id: "D07QQY",
    names: ["[11C]DTBZ", "[11C]-DTBZ", "[11C]-DTBZ (PET Ligand)", "Dihydrotetrabenazine"],
  },
  {
    primary_id: "D07QRB",
    names: ["APT Project"],
  },
  {
    primary_id: "D07QRJ",
    names: ["C-Adenosine Analogs"],
  },
  {
    primary_id: "D07QSA",
    names: [
      "CP-83101",
      "AC1O5PZP",
      "SCHEMBL1660808",
      "SCHEMBL1660809",
      "3,5-Dihydroxy-9,9-Diphenyl-6,8-Nonadienoate",
      "Methyl (3R,5S,6E)-3,5-Dihydroxy-9,9-Diphenylnona-6,8-Dienoate",
      "6,8-Nonadienoic Acid, 3,5-Dihydroxy-9,9-Diphenyl-, Methyl Ester, (R*,S*-(E))-(+-)-",
    ],
  },
  {
    primary_id: "D07QSM",
    names: ["Methyl 2-(2-Oxohexadecanamido)Acetate"],
  },
  {
    primary_id: "D07QSV",
    names: [
      "4-Hydroxy-Pentanoic Acid",
      "4-Hydroxypentanoic Acid",
      "4-Hydroxyvaleric Acid",
      "Gamma-Hydroxyvaleric Acid",
      "13532-37-1",
      "4-Hydroxy-Valeric Acid",
      "Pentanoic Acid, 4-Hydroxy-",
      "103712-26-1",
      "Pentanoic Acid, 4-Hydroxy-, (S)-",
      "EINECS 236-884-1",
      "ACMC-20m6jh",
      "AC1Q5VUF",
      "Pentanoic Acid,4-Hydroxy-",
      "AC1L3FK5",
      "AC1Q2BV5",
      "SCHEMBL27394",
      "CHEMBL169219",
      "CTK4B9757",
      "FMHKPLXYWVCLME-UHFFFAOYSA-N",
      "MolPort-006-120-502",
      "LMFA01050009",
      "AKOS008148154",
      "MCULE-6352217997",
      "TX-017030",
    ],
  },
  {
    primary_id: "D07QTB",
    names: ["AF-243", "Dendrogenin B", "Dendrogenin B (Deafness), Affichem"],
  },
  {
    primary_id: "D07QTG",
    names: [
      "JNJ-479655",
      "1428327-31-4",
      "N-((4-(4-Phenylpiperazin-1-Yl)Tetrahydro-2H-Pyran-4-Yl)Methyl)-2-(Phenylthio)Nicotinamide",
      "JNJ-47965567",
      "JNJ 47965567",
      "N-{[4-(4-Phenylpiperazin-1-Yl)Oxan-4-Yl]methyl}-2-(Phenylsulfanyl)Pyridine-3-Carboxamide",
      "P2X Antagonist III",
      "Antagonist JNJ47965567",
      "GTPL7538",
      "CHEMBL2338352",
      "MolPort-035-941-198",
      "ZINC95590396",
      "AKOS025142079",
      "JNJ47965567",
      "NCGC00387264-01",
      "J-115",
      "JNJ-47965567, &gt",
      "Z2235332565",
      "N-[[4-(4-Phenylpiperazin-1-Yl)Oxan-4-Yl]methyl]-2-Phenylsulfanylpyridine-3-Carboxamide",
    ],
  },
  {
    primary_id: "D07QUV",
    names: ["JWH-345"],
  },
  {
    primary_id: "D07QUY",
    names: ["C[L-Phe-D-Pro-L-Tyr(OMe)-D-Trp]", "CHEMBL506211"],
  },
  {
    primary_id: "D07QVR",
    names: ["ISIS 7855"],
  },
  {
    primary_id: "D07QVY",
    names: ["DBT-066"],
  },
  {
    primary_id: "D07QWW",
    names: ["NBI-34041"],
  },
  {
    primary_id: "D07QWZ",
    names: ["AZT-P-DDI"],
  },
  {
    primary_id: "D07QXG",
    names: ["EF-27", "Amelorad", "SO-1100", "SO-5407"],
  },
  {
    primary_id: "D07QXM",
    names: ["AVI-4020"],
  },
  {
    primary_id: "D07QXU",
    names: ["NS 2359"],
  },
  {
    primary_id: "D07QYU",
    names: ["PPADS"],
  },
  {
    primary_id: "D07QZA",
    names: ["ISIS 107642"],
  },
  {
    primary_id: "D07QZO",
    names: ["MPS-AAV8"],
  },
  {
    primary_id: "D07RAA",
    names: ["PMID26651364-Compound-119"],
  },
  {
    primary_id: "D07RDB",
    names: ["2,4-Difluorophenyl 4-Butoxybenzylcarbamate", "CHEMBL596875", "SCHEMBL5222747"],
  },
  {
    primary_id: "D07RDG",
    names: ["AMG 416"],
  },
  {
    primary_id: "D07RDQ",
    names: ["LMB-100"],
  },
  {
    primary_id: "D07RDU",
    names: ["IDP-107", "Acne Vulgaris Treatment, Dow Pharmaceutical Sciences"],
  },
  {
    primary_id: "D07RDV",
    names: ["Z-Ala-Leu-Phe-Agly-Ile-Val-OMe"],
  },
  {
    primary_id: "D07REK",
    names: [
      "4-Ethoxynaphthalene-1,2-Dione",
      "4-Ethoxy-1,2-Naphthoquinone",
      "CHEMBL50620",
      "4-Ethoxy-[1,2]naphthoquinone",
      "7473-19-0",
      "NSC400247",
      "AC1L7Z1H",
      "4-Ethoxy-1,2-Naphthalenedione",
      "SCHEMBL5595641",
      "DTXSID70322029",
      "XPXODXIULRDWNT-UHFFFAOYSA-N",
      "1,2-Naphthalenedione, 4-Ethoxy-",
      "ZINC1593117",
      "BDBM50099736",
    ],
  },
  {
    primary_id: "D07RFM",
    names: [
      "4-[2-(3-Phenyl-Propylamino)-Ethyl]-Phenol",
      "CHEMBL333339",
      "SCHEMBL12009648",
      "BDBM50066543",
      "AKOS010487382",
    ],
  },
  {
    primary_id: "D07RGP",
    names: ["ASKENDOSIDE B", "Askendoside B", "CHEMBL411981"],
  },
  {
    primary_id: "D07RGV",
    names: ["ANNULIN B"],
  },
  {
    primary_id: "D07RGW",
    names: ["Mephenytoin"],
  },
  {
    primary_id: "D07RHF",
    names: ["GDC 695"],
  },
  {
    primary_id: "D07RHP",
    names: ["INCB50465"],
  },
  {
    primary_id: "D07RIE",
    names: ["BC-PN-02"],
  },
  {
    primary_id: "D07RIJ",
    names: ["Autologous Melanoma Cell Vaccine", "Autologous Melanoma Cell Vaccine (Neoplasm)"],
  },
  {
    primary_id: "D07RIQ",
    names: [
      "ADRENOGLOMERULOTROPIN",
      "Adrenoglomerulotropin",
      "MMTC",
      "1210-56-6",
      "BRN 0747865",
      "1-Methyl-6-Methoxy-1,2,3,4-Tetrahydro-Beta-Carboline",
      "CHEMBL221811",
      "RDUORFDQRFHYBF-UHFFFAOYSA-N",
      "6-Methoxy-1-Methyl-1,2,3,4-Tetrahydro-Beta-Carboline",
      "9H-Pyrido(3,4-B)Indole, 1,2,3,4-Tetrahydro-6-Methoxy-1-Methyl-",
      "6-Methoxy-1-Methyl-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
      "1H-Pyrido[3,4-B]indole, 2,3,4,9-Tetrahydro-6-Methoxy-1-Methyl-",
      "1-Methyl-6-Methoxy-1,2,3,4-Tetrahydro-2-Carboline",
      "1H-Pyrido(3,4-B)Indole, 2,3,4,9-Tetrahydro-6-Methoxy-1-Methyl-",
      "Mmthc",
    ],
  },
  {
    primary_id: "D07RIZ",
    names: ["Piperidinyl Triazole Derivative 4"],
  },
  {
    primary_id: "D07RJD",
    names: ["Resorcinol Compound 2"],
  },
  {
    primary_id: "D07RJW",
    names: ["ISIS 104211"],
  },
  {
    primary_id: "D07RKN",
    names: ["GGTI-2418"],
  },
  {
    primary_id: "D07RKO",
    names: [
      "MRX-6",
      "CXCL10 Gene Inhibitor/Phospholipase A2/ MIP 1 Ligand Inhibitor (Contact Dermatitis), Morria Biopharmaceuticals",
      "IL 1 Beta/IL-6/PGE 2 Modulator (Dermatological Emulsion, Contact Dermatitis), Morria Biopharmaceuticals",
      "Monocyte Chemotactic Protein 1/GM-CSF/TNF Alpha Ligand Inhibitor (Dermatological Emulsion, Contact Dermatitis), Morria Biopharmaceuticals",
      "Non-Steroidal Multifunctional Anti-Inflammatory Drug (MFAID) (Topical Formulation, Contact Dermatitis), Morria",
    ],
  },
  {
    primary_id: "D07RLB",
    names: ["PMID28270010-Compound-Figure24-B"],
  },
  {
    primary_id: "D07RLD",
    names: ["5'-DAMP", "DAMP", "2'-Deoxyadenosine 5'-Monophosphate"],
  },
  {
    primary_id: "D07RLW",
    names: [
      "5-Aminomethyl-Naphthalen-2-Ol",
      "5-(Aminomethyl)Naphthalen-2-Ol",
      "CHEMBL131174",
      "170244-98-1",
      "SCHEMBL15345565",
      "CTK4D3537",
      "DTXSID00658388",
      "BDBM50061315",
      "ZINC13766764",
      "1-(Aminomethyl)-6-Hydroxynaphthalene",
      "AKOS027292253",
      "SC-29498",
      "1,2-Anthracenediol,10-(Aminomethyl)-9,10-Dihydro-",
    ],
  },
  {
    primary_id: "D07RME",
    names: ["GX-401"],
  },
  {
    primary_id: "D07RML",
    names: ["ISIS 9133"],
  },
  {
    primary_id: "D07RMX",
    names: ["ZK-Thiazolidinone"],
  },
  {
    primary_id: "D07ROK",
    names: ["N-(3-(1H-Indol-4-Yloxy)Propyl)Cyclopentanamine", "CHEMBL392392"],
  },
  {
    primary_id: "D07RPB",
    names: ["HT-90B"],
  },
  {
    primary_id: "D07RPC",
    names: ["RAP-103"],
  },
  {
    primary_id: "D07RPK",
    names: [
      "NIP-151",
      "Acetylcholine-Activated Potassium Current Channel Blocker, Nissan Chemical",
      "IKACh Channel Blocker (Atrial Fibrillation), Nissan Chemical Industries",
    ],
  },
  {
    primary_id: "D07RPR",
    names: [
      "Bropirimine",
      "56741-95-8",
      "2-Amino-5-Bromo-4-Hydroxy-6-Phenylpyrimidine",
      "ABPP",
      "2-AMINO-5-BROMO-6-PHENYLPYRIMIDIN-4-OL",
      "Bropiriminum [Latin]",
      "2-Amino-5-Bromo-6-Phenyl-4(3H)-Pyrimidinone",
      "2-Amino-5-Bromo-6-Phenylpyrimidin-4(3H)-One",
      "U-54461",
      "Bropirimina [Spanish]",
      "UNII-J57CTF25XJ",
      "C10H8BrN3O",
      "CCRIS 3730",
      "2-Amino-5-Bromo-6-Phenyl-1H-Pyrimidin-4-One",
      "2-Amino-5-Bromo-6-Phenyl-4(1H)-Pyrimidinone",
      "4(1H)-Pyrimidinone, 2-Amino-5-Bromo-6-Phenyl-",
      "BRN 0651807",
      "J57CTF25XJ",
      "5-Bromo-6-Phenylisocytosine",
      "CHEBI:31307",
      "2-Amino-5-Bromo-6-Phenylpyrimidin-4-Ol",
    ],
  },
  {
    primary_id: "D07RPT",
    names: ["Lentiviral RNAi Therapy"],
  },
  {
    primary_id: "D07RPV",
    names: ["HALOPEMIDE"],
  },
  {
    primary_id: "D07RQA",
    names: ["Alkyl Mannoside Derivative 8"],
  },
  {
    primary_id: "D07RQB",
    names: [
      "7-(Naphthalen-2-Yl)Benzo[e][1,2,4]triazin-3-Amine",
      "CHEMBL385112",
      "1,2,4-Benzotriazin-3-Amine,7-(2-Naphthalenyl)-",
      "BDBM50193895",
    ],
  },
  {
    primary_id: "D07RQR",
    names: ["SYN-120"],
  },
  {
    primary_id: "D07RQT",
    names: [
      "8-Methyl-3-P-Tolyl-8-Aza-Bicyclo[3.2.1]octane",
      "CHEMBL82027",
      "130342-81-3",
      "ACMC-20mtlb",
      "SCHEMBL12959169",
      "8-Methyl-3-P-Tolyl-8-Aza-Bicyclo[3.2.1]octane-2-Carboxylic Acid Methyl Ester",
      "8-Azabicyclo[3.2.1]octane-2-Carboxylicacid, 8-Methyl-3-(4-Methylphenyl)-, Methyl Ester, (1R,2S,3S,5S)-",
    ],
  },
  {
    primary_id: "D07RRA",
    names: ["2-[(2-Methyl-4-Thiazolyl)Ethynyl]pyrazine", "CHEMBL371884", "SCHEMBL4154142"],
  },
  {
    primary_id: "D07RRC",
    names: ["BioHib", "Conjugate Vaccine (Haemophilus Influenza Type B (Hib), Injectable), Bharat"],
  },
  {
    primary_id: "D07RRH",
    names: ["Beta-1-C-Butyl-1-Deoxygalactonojirimycin", "CHEMBL1163255"],
  },
  {
    primary_id: "D07RRL",
    names: ["PMID27599163-Compound-76"],
  },
  {
    primary_id: "D07RRN",
    names: ["C[Thr-Tyr-Thr-His-DNaf-Arg-Trp-Thr-Ile-Pro]", "CHEMBL267492"],
  },
  {
    primary_id: "D07RRV",
    names: ["Tetra-Hydro-Triazolopyrimidine Derivative 2"],
  },
  {
    primary_id: "D07RRZ",
    names: [
      "2,6-Diisopropyl-4-Nitro-Phenol",
      "2,6-Diisopropyl-4-Nitrophenol",
      "4-Nitro Propofol",
      "1576-14-3",
      "Phenol, 2,6-Bis(1-Methylethyl)-4-Nitro-",
      "4-Nitro-2,6-Diisopropylphenol",
      "SCHEMBL6978784",
      "CHEMBL299111",
      "DTXSID20443430",
      "BDOVQBSHXBPOLJ-UHFFFAOYSA-N",
      "ZINC13779578",
      "2,6-Bis(1-Methylethyl)-4-Nitrophenol",
      "2,6-Bis(1-Methylethyl)-4-Nitro-Phenol",
      "FT-0672927",
    ],
  },
  {
    primary_id: "D07RSA",
    names: ["N-(4-Phenylbenzoyl)-Phenylalanyl-Glycine-Nitrile"],
  },
  {
    primary_id: "D07RSH",
    names: [
      "MRS923",
      "CHEMBL75169",
      "7555-80-8",
      "4H-1-Benzopyran-4-One, 2-(2,4-Dimethoxyphenyl)-3,5,7-Trimethoxy-",
      "2-(2,4-Dimethoxyphenyl)-3,5,7-Trimethoxychromen-4-One",
      "Flavone, 2',3,4',5,7-Pentamethoxy-",
      "Pentamethyl Morin",
      "AC1LCIM3",
      "GTPL401",
      "SCHEMBL1664225",
      "CTK2G8883",
      "MRS-923",
      "DTXSID80348154",
      "KPJGABLCRGWDRB-UHFFFAOYSA-N",
      "3,5,7,2',4'-Pentamethoxyflavone",
      "BDBM50049399",
      "2',3,4',5,7-Pentamethoxy Flavone",
      "AKOS030552861",
      "2-(2,4-Dimethoxy-Phenyl)-3,5,7-Trimethoxy-Chromen-4-One",
      "2-(2,4-Dimethoxyphenyl)-3,5,7-Trimethoxy-4H-Chromen-4-One #",
    ],
  },
  {
    primary_id: "D07RSI",
    names: ["Lipobean"],
  },
  {
    primary_id: "D07RST",
    names: ["Ibuprofenamine"],
  },
  {
    primary_id: "D07RTQ",
    names: ["NAADP"],
  },
  {
    primary_id: "D07RTV",
    names: ["5-(4-Methoxyphenyl)-4-P-Tolyl-1,2-Selenazole", "CHEMBL452730"],
  },
  {
    primary_id: "D07RUC",
    names: [
      "E-6087",
      "E-6231 ((R)-(+)-Isomer)",
      "E-6232 ((S)-(-)-Isomer)",
      "Rac-4-[5-(2,4-Difluorophenyl)-3-(Trifluoromethyl)-4,5-Dihydro-1H-Pyrazol-1-Yl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D07RUF",
    names: ["ISIS 112023"],
  },
  {
    primary_id: "D07RUH",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 14"],
  },
  {
    primary_id: "D07RUR",
    names: ["HB-1345"],
  },
  {
    primary_id: "D07RUV",
    names: ["Terflavoxate"],
  },
  {
    primary_id: "D07RVP",
    names: [
      "GR-63799X",
      "(-)-[1(R)-[1alpha(Z),2beta(R*),3alpha]-7-[3-Hydroxy-2-(2-Hydroxy-3-Phenoxypropoxy)-5-Oxocyclopentyl]-4-Heptenoic Acid 4-(Benzoylamino)Phenyl Ester",
      "(Z)-4,5-Didehydro-13-Oxa-16-Phenoxy-13,14-Dihydro-17,18,19,20-Tetranor-PGE1 4-(Benzoylamino)Phenyl Ester",
      "119906-28-4",
    ],
  },
  {
    primary_id: "D07RVX",
    names: ["ISIS 103471"],
  },
  {
    primary_id: "D07RWQ",
    names: ["Imidazo[1,2-B]pyridazine Acetamide Derivative 5"],
  },
  {
    primary_id: "D07RWY",
    names: ["LMI1195"],
  },
  {
    primary_id: "D07RXJ",
    names: [
      "2-(4-Benzoylpiperidine-1-Carbonyl)Benzoic Acid",
      "CHEMBL571233",
      "ASN 06584077",
      "AC1LSF7A",
      "HMS1695L15",
      "ZINC1336805",
      "BDBM50296427",
      "AKOS000753095",
      "SR-01000350200",
      "2-(4-Benzoyl-Piperidine-1-Carbonyl)-Benzoic Acid",
    ],
  },
  {
    primary_id: "D07RXM",
    names: ["HM-90822"],
  },
  {
    primary_id: "D07RXX",
    names: [
      "HG-1051",
      "CD136 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "CD137 Antigen Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "CDw137 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Homolog Of Mouse Ly63 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "ILA Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "MGC2172 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "T Cell Antigen ILA Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "TNFRSF9 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Tumor Necrosis Factor Receptor Superfamily Member 9 Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Homolog Of Mouse 4-1BB Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Human 4-1BB Receptor Splicing Varifant Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Induced By Lymphocyte Activation (ILA) Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "Interleukin-Activated Receptor Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "4-1BB Ligand Receptor Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
      "4-1BB Modulator (Inflammation/Immune Disorders), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D07RYI",
    names: ["Etelcalcetide"],
  },
  {
    primary_id: "D07RYM",
    names: ["PMID25666693-Compound-131"],
  },
  {
    primary_id: "D07RYR",
    names: ["1-[2-(2-Benzyl-Phenoxy)-Ethyl]-Pyrrolidine", "CHEMBL435505", "BDBM50151045"],
  },
  {
    primary_id: "D07RYX",
    names: ["Antide"],
  },
  {
    primary_id: "D07RZN",
    names: ["N-Benzyl-4-Sulfamoyl-Benzamide"],
  },
  {
    primary_id: "D07RZW",
    names: ["AZD9150"],
  },
  {
    primary_id: "D07SAA",
    names: [
      "2-(N-Cyclopropylamino)-3-Chloropropiophenone",
      "CHEMBL569700",
      "2-(N-Cyclopropylamino)-3'-Chloropropiophenone",
      "SCHEMBL634169",
      "BDBM50302907",
    ],
  },
  {
    primary_id: "D07SAF",
    names: ["2-Methyl-1-Phenyl-2-Pyridin-3-Yl-Propan-1-Ol", "CHEMBL55378"],
  },
  {
    primary_id: "D07SAI",
    names: ["Protoporphyrin Ix Containing Co"],
  },
  {
    primary_id: "D07SAQ",
    names: ["Tie-2 Inhibitors"],
  },
  {
    primary_id: "D07SBP",
    names: ["AZD4831"],
  },
  {
    primary_id: "D07SBX",
    names: ["Anti-HCV Peptides"],
  },
  {
    primary_id: "D07SCN",
    names: ["KC-11458"],
  },
  {
    primary_id: "D07SCP",
    names: ["MMP-12 Inhibitors"],
  },
  {
    primary_id: "D07SCW",
    names: ["3,4-Dihydroxyphenylacetaldehyde", "DOPAL"],
  },
  {
    primary_id: "D07SDE",
    names: ["Pranidipine", "Acalas", "OPC-13340", "OPC-13463"],
  },
  {
    primary_id: "D07SDQ",
    names: [
      "Curcumin",
      "458-37-7",
      "Diferuloylmethane",
      "Natural Yellow 3",
      "Turmeric Yellow",
      "Turmeric",
      "Curcuma",
      "Kacha Haldi",
      "Gelbwurz",
      "Indian Saffron",
      "Curcumin I",
      "Souchet",
      "Halud",
      "Halad",
      "Haidr",
      "Haldar",
      "Merita Earth",
      "Yellow Ginger",
      "Terra Merita",
      "Yellow Root",
      "Safran D'Inde",
      "Yo-Kin",
      "Golden Seal",
      "Curcuma Oil",
      "Orange Root",
      "Oils, Curcuma",
      "CI Natural Yellow 3",
      "Curcumine",
      "Hydrastis",
      "Indian Turmeric",
      "Yellow Puccoon",
      "Turmeric Extract",
      "Diferaloylmethane",
      "Kurkumin [Czech]",
      "(1E,6E)-1,7-Bis(4-Hydroxy-3-Methoxyphenyl)Hepta-1,6-Diene-3,5-Dione",
      "Tumeric Yellow",
      "Turmeric Oil",
    ],
  },
  {
    primary_id: "D07SDT",
    names: ["HPP-376"],
  },
  {
    primary_id: "D07SEB",
    names: ["BM-17.0505"],
  },
  {
    primary_id: "D07SEG",
    names: ["Oxindole Derivative 2"],
  },
  {
    primary_id: "D07SEU",
    names: ["L-659,699"],
  },
  {
    primary_id: "D07SEZ",
    names: ["HG-1072"],
  },
  {
    primary_id: "D07SFD",
    names: ["C[Nle-Arg-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL263822"],
  },
  {
    primary_id: "D07SFI",
    names: [
      "Benzamidine",
      "Benzimidamide",
      "Benzenecarboximidamide",
      "618-39-3",
      "Phenylamidine",
      "UNII-KUE3ZY3J1F",
      "NSC 243704",
      "CCRIS 2952",
      "Benzamidine (Protonated)",
      "EINECS 210-546-3",
      "KUE3ZY3J1F",
      "CHEMBL20936",
      "CHEBI:41033",
      "PXXJHWLDUBFPOL-UHFFFAOYSA-N",
      "BDN",
      "1oss",
      "2ast",
      "1bra",
      "Benzenecarboxamidine",
      "1v2m",
      "1v2j",
      "1h4w",
      "1ce5",
      "1c5o",
      "2j9n",
      "1f5k",
      "1c5p",
      "1v2v",
      "1v2s",
      "1v2l",
      "1c5z",
      "1v2u",
      "AC1L1DFX",
      "Lopac-B-6506",
      "ACMC-1B9LG",
      "SCHEMBL9207",
      "Lopac0_000203",
      "MLS001066369",
      "GTPL7566",
      "AC1Q1U98",
      "DTXSID8045012",
      "1670-14-0 (Hydrochloride)",
      "ZINC36634",
      "CTK2F5055",
      "1w80",
      "1j16",
      "1j15",
      "MolPort-000-001-395",
    ],
  },
  {
    primary_id: "D07SFV",
    names: ["C[RGDf-(3S)-Carboxymorpholine]", "CHEMBL452724"],
  },
  {
    primary_id: "D07SGB",
    names: ["ABT-299", "A-85783"],
  },
  {
    primary_id: "D07SGS",
    names: [
      "N-(4-(Benzyloxy)Phenethyl)Pyridin-4-Amine",
      "CHEMBL48029",
      "212506-01-9",
      "CTK0I9588",
      "DTXSID00658166",
      "ZINC29412450",
      "BDBM50070788",
      "AKOS030550684",
      "N-{2-[4-(Benzyloxy)Phenyl]ethyl}pyridin-4-Amine",
      "[2-(4-Benzyloxy-Phenyl)-Ethyl]-Pyridin-4-Yl-Amine",
      "4-Pyridinamine, N-[2-[4-(Phenylmethoxy)Phenyl]ethyl]-",
    ],
  },
  {
    primary_id: "D07SGT",
    names: [
      "5-Hydroxynaphthalene-1-Sulfonamide",
      "17286-26-9",
      "5-Hydroxynaphthalene-1-Sulphonamide",
      "NFVBVKHGDDDCEA-UHFFFAOYSA-N",
      "5-Hydroxy-1-Naphthalenesulfonamide",
      "5-Hydroxy-Naphthalene-1-Sulfonic Acid Amide",
      "2vth",
      "EINECS 241-319-7",
      "AC1L3CSX",
      "AC1Q6ULI",
      "SCHEMBL588923",
      "CHEMBL457047",
      "CTK4D4388",
      "NFVBVKHGDDDCEA-UHFFFAOYSA-",
      "BDBM24628",
      "DTXSID50169414",
      "ZINC2242749",
      "5-Hydroxy-1-Naphthalenesulphonamide",
      "1-Naphthalenesulfonamide,5-Hydroxy-",
      "1-Naphthalenesulfonamide, 5-Hydroxy-",
      "5-Hydroxynaphthalene-1-Sulfonamide, 7",
      "AKOS030618521",
      "DB08132",
      "TX-017211",
    ],
  },
  {
    primary_id: "D07SHM",
    names: [
      "Phenix Project",
      "BVD-PHX",
      "Phenix Project (Inflammation/Chagas Disease/Diabetes)",
      "Phenix Project (Inflammation/Chagas Disease/Diabetes), BioMed Valley Discoveries",
    ],
  },
  {
    primary_id: "D07SHZ",
    names: ["COTI-58"],
  },
  {
    primary_id: "D07SIA",
    names: ["PHENYLMETHANESULFONAMIDE"],
  },
  {
    primary_id: "D07SIV",
    names: ["[des-His1,Tyr5,Glu9]glucagon-NH2"],
  },
  {
    primary_id: "D07SJT",
    names: [
      "Pantothenic Acid",
      "D-Pantothenic Acid",
      "Vitamin B5",
      "79-83-4",
      "Pantothenate",
      "Chick Antidermatitis Factor",
      "(+)-Pantothenic Acid",
      "PANTOTHENOIC ACID",
      "(R)-Pantothenate",
      "(D)-(+)-Pantothenic Acid",
      "Kyselina Pantothenova [Czech]",
      "HSDB 1020",
      "Pantothenic Acid [BAN]",
      "D(+)-N-(2,4-Dihydroxy-3,3-Dimethylbutyryl)-Beta-Alanine",
      "BRN 1727064",
      "UNII-19F5HK2737",
      "(R)-Pantothenic Acid",
      "Beta-Alanine, N-(2,4-Dihydroxy-3,3-Dimethyl-1-Oxobutyl)-, (R)-",
      "EINECS 201-229-0",
      "(D,+)-N(Alpha-Gamma-Dihydroxy-Beta,beta-Dimethylbutyryl)-Beta-Alanine",
    ],
  },
  {
    primary_id: "D07SKJ",
    names: ["LY3015014"],
  },
  {
    primary_id: "D07SKL",
    names: ["Oximidine II"],
  },
  {
    primary_id: "D07SKN",
    names: ["PF-885706"],
  },
  {
    primary_id: "D07SLJ",
    names: ["JCAR024"],
  },
  {
    primary_id: "D07SMD",
    names: [
      "Dual Norepinephrine Reuptake Inhibitors/5-HT1A Partial Agonists",
      "Dual Norepinephrine Reuptake Inhibitors/5-HT1A Partial Agonists (Neuropsychiatric Disorders)",
      "Dual Norepinephrine Reuptake Inhibitors/5-HT1A Partial Agonists (Neuropsychiatric Disorders), Pfizer",
    ],
  },
  {
    primary_id: "D07SMH",
    names: ["MK-5172"],
  },
  {
    primary_id: "D07SNG",
    names: [
      "DF-098",
      "ActHIB",
      "OmniHIB",
      "Haemophilus Influenzae B (Hib) Vaccine, Aventis Pasteur",
      "Haemophilus Influenzae Typeb Vaccine, Daiichi/Aventis Pasteur",
      "Hib Conjugate, Pasteur Merieux/SB",
      "Hib Vaccine, Aventis Pasteur-Daiichi Vaccines",
    ],
  },
  {
    primary_id: "D07SNN",
    names: ["STxB-E7"],
  },
  {
    primary_id: "D07SNO",
    names: ["Dabrafenib + Trametinib"],
  },
  {
    primary_id: "D07SNW",
    names: [
      "1,6-Bis(4'-Dimethylaminophenyl)-Hexa-1,3,5-Triene",
      "CHEMBL377929",
      "BDBM50185990",
      "1,6-Bis[4-(Dimethylamino)Phenyl]-1,3,5-Hexatriene",
      "1,6-Bis(4''-Dimethylaminophenyl)-Hexa-1,3,5-Triene",
    ],
  },
  {
    primary_id: "D07SOJ",
    names: [
      "Adenovirus/Semliki Forest Virus Hybrid Vector Delivered IL-12 Immunotherapy",
      "Adenovirus/Semliki Forest Virus Hybrid Vector Delivered IL-12 Immunotherapy (Hepatitis B Virus Infection, Hepatocellular Carcinoma)",
      "Adenovirus/Semliki Forest Virus Hybrid Vector Delivered IL-12 Immunotherapy (Hepatocellular Carcinoma), Digna Biotech",
      "Adenovirus/Semliki Forest Virus Hybrid Vector Delivered IL-12 Immunotherapy (Hepatitis B Virus Infection, Hepatocellular Carcinoma), Digna Biotech",
    ],
  },
  {
    primary_id: "D07SOM",
    names: ["3-(Imidazolylmethyl)Flavone", "CHEMBL213587", "BDBM50191599"],
  },
  {
    primary_id: "D07SOO",
    names: [
      "Methoxyflurane",
      "Analgizer",
      "Anecotan",
      "Ingalan",
      "Inhalan",
      "Methofane",
      "Methoflurane",
      "Methofluranum",
      "Methoxane",
      "Methoxiflurane",
      "Methoxifluranum;Methoxyfluoran",
      "Methoxyfluorane",
      "Methoxyfluran",
      "Methoxyfluranum",
      "Metofane",
      "Metossiflurano",
      "Metoxfluran",
      "Metoxifluran",
      "Metoxiflurano",
      "Penthrane",
      "Pentran",
      "Pentrane",
      "Metossiflurano [DCIT]",
      "Methoxyflurane [Anaesthetics, Volatile]",
      "Methoxyfluranum [INN-Latin]",
      "Metofane (VAN)",
      "Metoxiflurano [INN-Spanish]",
      "Penthrane (TN)",
      "Penthrane (VAN)",
      "Methoxyflurane (USP/INN)",
      "Methoxyflurane [USAN:INN:BAN]",
      "Methyl 1,1-Difluoro-2,2-Dichloroethyl Ether",
      "Ether, 2,2-Dichloro-1,1-Difluoroethyl Methyl",
      "(2,2-Dichloro-1,1-Difluoroethyl) Methyl Ether",
      "2,2-Dichloro-1,1-Difluoro-1-Methoxyethane",
      "2,2-Dichloro-1,1-Difluoroethyl Methyl Ether",
    ],
  },
  {
    primary_id: "D07SOQ",
    names: [
      "N-(2-Naphthylsulfonyl)-Glycyl-Glycine-Nitrile",
      "Dipeptide-Derived Nitrile, 38",
      "CHEMBL200338",
      "BDBM20117",
    ],
  },
  {
    primary_id: "D07SPC",
    names: ["N-Hydroxy-2,3-Bis(Phenylsulfonamido)Propanamide", "CHEMBL496717"],
  },
  {
    primary_id: "D07SPG",
    names: [
      "ARGX-110",
      "ARGX-110 Program, ArGEN-X BV",
      "Human MAb (SIMPLE Antibody, Autoimmune Disease/Inflammatory Disease/Cancer), ArGEN-X",
    ],
  },
  {
    primary_id: "D07SQT",
    names: ["Ferroportin Mab"],
  },
  {
    primary_id: "D07SQW",
    names: ["LIVIDOMYCIN A"],
  },
  {
    primary_id: "D07SRJ",
    names: ["2,8-Dimethyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D07SRN",
    names: ["Debio-1036"],
  },
  {
    primary_id: "D07SRW",
    names: [
      "His-DPhe-Arg-Trp",
      "CHEMBL397413",
      "4289/2/5",
      "Hisetal",
      "Characteristic MSH-Tetrapeptide",
      "ZINC28707608",
      "BDBM50211182",
      "(MSH Fragment H-His-Phe-Arg-Trp-OH)2-(2-{2-[2-Amino-3-(3H-Imidazol-4-Yl)-Propionylamino]-3-Phenyl-Propionylamino}-5-Guanidino-Pentanoylamino)-3-(1H-Indol-3-Yl)-Propionic Acid",
    ],
  },
  {
    primary_id: "D07SRX",
    names: ["AG-E-85378"],
  },
  {
    primary_id: "D07SSO",
    names: ["PMID27109571-Compound-39"],
  },
  {
    primary_id: "D07SSU",
    names: ["Peptide Analog 31"],
  },
  {
    primary_id: "D07STB",
    names: ["EG-1962"],
  },
  {
    primary_id: "D07STC",
    names: ["2-(N-(2-Fluorophenyl)Pyrrol-2-Yl) Acetic Acid", "CHEMBL236238", "BDBM50213563"],
  },
  {
    primary_id: "D07SUG",
    names: [
      "Busulfan",
      "Busulfano",
      "Busulfanum",
      "Busulfex",
      "Busulphan",
      "Busulphane",
      "Butanedioldimethanesulfonate",
      "Buzulfan",
      "Citosulfan",
      "Glyzophrol",
      "Leucosulfan",
      "Mablin",
      "Methanesulfonic",
      "Mielevcin",
      "Mielosan",
      "Mielucin",
      "Milecitan",
      "Mileran",
      "Misulban",
      "Mitosan",
      "Mitostan",
      "Myeleukon",
      "Myeloleukon",
      "Myelosan",
      "Myelosanum",
      "Mylecytan",
      "Myleran",
      "Mylerlan",
      "Sulfabutin",
      "Sulphabutin",
      "Busulfan GlaxoSmithKline Brand",
      "Busulfan Orphan Brand",
      "Busulfan Wellcome",
      "Busulfan Wellcome Brand",
      "Glaxo Wellcome Brand Of Busulfan",
      "GlaxoSmithKline Brand Of Busulfan",
      "Myleran Tablets",
      "Orphan Brand Of Busulfan",
      "Tetramethylene Bis[methanesulfonate]",
      "Tetramethylene Dimethane Sulfonate",
      "Tetramethylenester Kyseliny Methansulfonove",
      "Tetramethylenester Kyseliny Methansulfonove[Czech]",
      "Wellcome Brand Of Busulfan",
      "AN 33501",
      "CB 2041",
      "GT 2041",
      "GT 41",
      "X 149",
      "Acid, Tetramethylene Ester",
      "Alkylating Agent: Crosslinks Guanine Residues",
      "Busulfan [INN:JAN]",
      "Busulfano [INN-Spanish]",
      "Busulfanum [INN-Latin]",
      "MYLERAN (TN)",
      "Methanesulfonic Acid, Tetram Ethylene Ester",
      "Methanesulfonic Acid, Tetramethylene Ester",
      "Myleran (TN)",
      "Sulfabutin (VAN)",
      "Tetramethylene Bis(Methanesulfonate)",
      "Tetramethylene {bis[methanesulfonate]}",
      "Wellcome, Busulfan",
      "C.B. 2041",
      "G.T. 41",
      "Myleran, Busulfex, Busulfan",
      "Busulfan (JP15/USP/INN)",
      "Butane-1,4-Diyl Dimethanesulfonate",
      "BUSULFAN (1,4-BUTANEDIOL, DIMETHANESULFONATE)",
      "N-Butane-1,3-Di(Methylsulfonate)",
      "1,4-BUTANEDIOL DIMETHANESULFONATE",
      "1,4-Bis(Methanesulfonoxy)Butane",
      "1,4-Bis(Methanesulfonyloxy)Butane",
      "1,4-Bis[methanesulfonoxy]butane",
      "1,4-Butanedi Yl Dimethanesulfonate",
      "1,4-Butanediol Dimethanesulphonate",
      "1,4-Butanediol Dimethylsulfonate",
      "1,4-Butanediol, Dimethanesulfonate",
      "1,4-Butanediol, Dimethanesulphonate",
      "1,4-Butanediyl Dimethanesulfonate",
      "1,4-Di(Methylsulfonoxy)Butane",
      "1,4-Dimesyloxybutane",
      "1,4-Dimethane Sulfonyl Oxybutane",
      "1,4-Dimethanesulfonoxybutane",
      "1,4-Dimethanesulfonoxylbutane",
      "1,4-Dimethanesulfonyloxybutane",
      "1,4-Dimethanesulphonyloxybutane",
      "1,4-Dimethylsulfonoxybutane",
      "1,4-Dimethylsulfonyloxybutane",
      "1,{4-Bis[methanesulfonoxy]butane}",
      "4-((Methylsulfonyl)Oxy)Butyl Methanesulfonate",
      "4-Methylsulfonyloxybutyl Methanesulfonate",
    ],
  },
  {
    primary_id: "D07SUN",
    names: ["(+/-)-Threo-3',4'-Dichlororitalinol", "CHEMBL1188904", "ZINC28645525", "BDBM50327166", "BDBM50212387"],
  },
  {
    primary_id: "D07SVE",
    names: ["ARRY-886", "Akt-1 Inhibitors (Cancer)", "Akt-1 Inhibitors (Cancer), Array BioPharma/Genetech"],
  },
  {
    primary_id: "D07SWU",
    names: ["TM30338"],
  },
  {
    primary_id: "D07SXG",
    names: [
      "PRT-061103",
      "Acetylsalicylic Acid Replacement Therapy (Cardiovascular Diseases), Portola",
      "Aspirin Replacement Therapy (Cardiovascular Diseases), Portola",
      "Thromboxane Receptor Antagonist (Cardiovascular Disease), Portola",
    ],
  },
  {
    primary_id: "D07SXO",
    names: [
      "AG-041R",
      "LS-83786",
      "199800-49-2",
      "GTPL901",
      "MLS006010838",
      "AG041R",
      "CTK8E8139",
      "DTXSID40430968",
      "AG 041R",
      "MolPort-009-019-290",
      "ZINC3930890",
      "NCGC00263225-01",
      "SMR004701765",
      "RT-011212",
      "FT-0773348",
      "J-012912",
    ],
  },
  {
    primary_id: "D07SYG",
    names: ["2,3-Dihydro-1H-Isoindole"],
  },
  {
    primary_id: "D07SYJ",
    names: ["Sulfameter"],
  },
  {
    primary_id: "D07SYN",
    names: ["FX-007"],
  },
  {
    primary_id: "D07SYO",
    names: ["Stromal Stem Cell Therapy"],
  },
  {
    primary_id: "D07SYP",
    names: ["L 691121"],
  },
  {
    primary_id: "D07SYY",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 3"],
  },
  {
    primary_id: "D07SYZ",
    names: ["RX-0201"],
  },
  {
    primary_id: "D07SZF",
    names: ["2-N-Ethylamino-1-(4-Methylthiophenyl)Butane", "CHEMBL1077897"],
  },
  {
    primary_id: "D07SZJ",
    names: ["A-75169", "A-74324", "A-74325"],
  },
  {
    primary_id: "D07TAJ",
    names: ["Ethyl 7-Methoxy-2-Oxo-2H-Chromene-3-Carboxylate"],
  },
  {
    primary_id: "D07TAL",
    names: ["PMID21536438C20f"],
  },
  {
    primary_id: "D07TAS",
    names: [
      "CI-959",
      "UNII-1A8130P4IY",
      "CI 959",
      "CI 159",
      "104795-68-8",
      "1A8130P4IY",
      "Benzo(B)Thiophene-2-Carboxamide, 5-Methoxy-3-(1-Methylethoxy)-N-1H-Tetrazol-5-Yl-, Monosodium Salt",
      "DSSTox_RID_82225",
      "DSSTox_CID_27268",
      "DSSTox_GSID_47268",
      "DTXSID8047268",
      "CHEMBL3183386",
      "Tox21_300435",
      "NCGC00254320-01",
      "LS-41129",
    ],
  },
  {
    primary_id: "D07TBD",
    names: ["DT-2228"],
  },
  {
    primary_id: "D07TBJ",
    names: ["NuThrax"],
  },
  {
    primary_id: "D07TBR",
    names: ["Ac-YR[CEH(D-2alpha-Nal)RWC]-NH2"],
  },
  {
    primary_id: "D07TCK",
    names: ["Tert-Butyl 2-(2-Oxohexadecanamido)Acetate", "CHEMBL487220"],
  },
  {
    primary_id: "D07TCS",
    names: ["CDP-6038"],
  },
  {
    primary_id: "D07TCV",
    names: ["Anti-CD19 Anti-CD20 Bispecific CAR-T"],
  },
  {
    primary_id: "D07TCX",
    names: ["Triplex"],
  },
  {
    primary_id: "D07TDE",
    names: ["Olterodine"],
  },
  {
    primary_id: "D07TDM",
    names: ["PHASEOLIN"],
  },
  {
    primary_id: "D07TDT",
    names: [
      "L-733560",
      "AC1L4318",
      "L 733560",
      "Pneumocandin B0, 1-((4R,5R)-5-(2-Aminoethoxy)-N2-(10,12-Dimethyl-1-Oxotetradecyl)-4-Hydroxy-L-Ornithine)-5-((3R)-3-Hydroxy-L-Ornithine)",
    ],
  },
  {
    primary_id: "D07TDV",
    names: ["Bendavia"],
  },
  {
    primary_id: "D07TDZ",
    names: ["ISIS 14144"],
  },
  {
    primary_id: "D07TEE",
    names: ["Gene Therapy, Mucopolysaccharidosis Type I"],
  },
  {
    primary_id: "D07TEJ",
    names: ["1-(1-Phenyl-2-(Pyridin-4-Yl)Ethyl)Piperazine", "CHEMBL214004", "SCHEMBL7967173"],
  },
  {
    primary_id: "D07TEP",
    names: ["SCH 420814"],
  },
  {
    primary_id: "D07TEZ",
    names: ["Benzo[d]oxazole Derivative 2"],
  },
  {
    primary_id: "D07TFL",
    names: ["Blosozumab"],
  },
  {
    primary_id: "D07TGN",
    names: [
      "Deslanoside",
      "Ceglunat",
      "Desace",
      "Deslanosido",
      "Deslanosidum",
      "Glucodigoxin",
      "Lekozid",
      "Sediranido",
      "Deacetyllanatoside C",
      "DesacetylLanatoside",
      "Desacetyldigilanide C",
      "Desacetyllanatoside C",
      "Deslanatoside C",
      "Deslanosidum C",
      "Lanatosid C",
      "Cedilanid-D",
      "Desacetyl-Lanatoside C",
      "Deslanosido [INN-Spanish]",
      "Deslanosidum [INN-Latin]",
      "Cedilanid-D (TN)",
      "Deslanoside (JP15/USP/INN)",
      "Deslanoside [USAN:BAN:INN:JAN]",
      "(3beta,5beta,12beta)-3-{[beta-D-Glucopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl]oxy}-12,14-Dihydroxycard-20(22)-Enolide",
      "3-[(O-Beta-D-Glucopyranosyl-(1->4)-O-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-O-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-O-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl)Oxy]-12,14-Dihydroxy-3beta,5beta,12beta-Card-20(22)-Enolide",
      "3beta-(O-Beta-D-Glucopyranosyl-(1-4)-O-Beta-D-Digitoxosyl-(1-4)-O-Beta-D-Digitoxosyl-(1-4)-Beta-D-Digitoxosyloxy-12beta,14-Dihydroxy-5beta,14beta-Card-20(22)-Enolid",
      "3beta-(O-Beta-D-Glucopyranosyl-(1-4)-O-Beta-D-Digitoxosyl-(1-4)-O-Beta-D-Digitoxosyl-(1-4)-Beta-D-Digitoxosyloxy=12beta.14=dihydroxy-5beta,14beta-Card-20(22)-Enolid",
      "3beta-{[beta-D-Glucopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl]oxy}-12beta,14-Dihydroxy-5beta-Card-20(22)-Enolide",
    ],
  },
  {
    primary_id: "D07TGS",
    names: [
      "4-[1-(4-Hydroxyphenyl)-2-Phenylvinyl]phenol",
      "CHEMBL415864",
      "1,1-Bis(4-Hydroxyphenyl)-2-Phenylethylene",
      "4,4'-(2-Phenylethene-1,1-Diyl)Diphenol",
      "66422-18-2",
      "Bhpe-1,1",
      "SCHEMBL641985",
      "AC1MI565",
      "CTK5C4724",
      "4-(1,2-Diphenyl-Vinyl)-Phenol",
      "BDBM50023749",
      "ZINC13492380",
      "Phenol, 4,4'-(Phenylethenylidene)Bis-",
      "1,1-Bis(4-Hydroxyphenyl)-2-Phenylethene",
      "4-[1-(4-Hydroxyphenyl)-2-Phenylethenyl]phenol",
    ],
  },
  {
    primary_id: "D07TGW",
    names: ["MPrP"],
  },
  {
    primary_id: "D07TGY",
    names: ["Indeloxazine", "Elen", "Noin", "Indeloxazine Hydrochloride", "YM-08054"],
  },
  {
    primary_id: "D07THJ",
    names: ["IPH-3102"],
  },
  {
    primary_id: "D07THV",
    names: [
      "CMP-Sialic Acid",
      "CMP-N-Acetylneuraminate",
      "Cytidine Monophosphate N-Acetylneuraminic Acid",
      "CMP-NeuNAc",
      "CYTIDINE-5'-MONOPHOSPHATE-5-N-ACETYLNEURAMINIC ACID",
      "Cmp-D-N-Acetylneuraminic Acid",
      "CHEBI:16556",
      "Cmp-Nana",
      "CMP Acetylneuraminic Acid",
      "3063-71-6",
      "CMP-N-Acetyl-Beta-Neuraminate",
      "NCC",
      "(2R,4S,5R,6R)-2-[({[(2R,3S,4R,5R)-5-(4-Amino-2-Oxo-1,2-Dihydropyrimidin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy}(Hydroxy)Phosphoryl)Oxy]-5-Acetamido-4-Hydroxy-6-[(1R,2R)-1,2,3-Trihydroxypropyl]oxane-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D07TIH",
    names: ["RES-701-1-C-Terminal Analogue"],
  },
  {
    primary_id: "D07TIJ",
    names: [
      "1-(9H-Fluoren-9-Yl)-1H-Imidazole",
      "CHEMBL225447",
      "35214-35-8",
      "Fluorenyl Deriv. 13",
      "AC1MY9SZ",
      "SCHEMBL9633309",
      "BDBM9464",
      "1-(9H-Fluoren-9-Yl)Imidazole",
      "MolPort-002-538-233",
      "ZINC4645069",
      "STL321422",
      "AKOS022141595",
      "MCULE-5499035128",
      "DA-06525",
    ],
  },
  {
    primary_id: "D07TIX",
    names: ["TDI-0018"],
  },
  {
    primary_id: "D07TJT",
    names: ["Imilecleucel-T"],
  },
  {
    primary_id: "D07TKE",
    names: ["4-Phenyl-1-(3-Phenylpropyl)Piperidin-4-Ol", "CHEMBL115508", "SCHEMBL8096481", "BDBM50007398"],
  },
  {
    primary_id: "D07TKG",
    names: ["NSC 665564"],
  },
  {
    primary_id: "D07TKZ",
    names: ["BIOD-123"],
  },
  {
    primary_id: "D07TLT",
    names: ["11-Methyl-6,11-Dihydro-5H-Benzo[a]carbazol-9-Ol", "CHEMBL207706"],
  },
  {
    primary_id: "D07TMI",
    names: ["ISIS 6442"],
  },
  {
    primary_id: "D07TMJ",
    names: ["EGFRt/19-28z/4-1BBL CAR T Cells"],
  },
  {
    primary_id: "D07TMR",
    names: ["ISIS 23722"],
  },
  {
    primary_id: "D07TMZ",
    names: ["L-Lysine"],
  },
  {
    primary_id: "D07TNE",
    names: ["GSK126"],
  },
  {
    primary_id: "D07TNU",
    names: ["DACTIMICIN"],
  },
  {
    primary_id: "D07TOI",
    names: ["1-Adamantan-1-Yl-3-(4-Pentyloxybutyl)Urea", "CHEMBL191767", "SCHEMBL12931855", "BDBM50167050"],
  },
  {
    primary_id: "D07TOK",
    names: ["Larotrectinib"],
  },
  {
    primary_id: "D07TPL",
    names: ["Ro-40-0655"],
  },
  {
    primary_id: "D07TPT",
    names: ["Toujeo"],
  },
  {
    primary_id: "D07TPU",
    names: ["LEUCOSCEPTOSIDE A"],
  },
  {
    primary_id: "D07TQF",
    names: ["7alpha-F-Ginkgolide B", "7alpha-F-GB"],
  },
  {
    primary_id: "D07TQN",
    names: [
      "Asimadoline",
      "Asimadoline [INN]",
      "EMD 61753",
      "N-[(1S)-2-[(3S)-3-Hydroxypyrrolidin-1-Yl]-1-Phenylethyl]-N-Methyl-2,2-Diphenylacetamide",
    ],
  },
  {
    primary_id: "D07TQU",
    names: ["FibroStem"],
  },
  {
    primary_id: "D07TQV",
    names: ["Lamivudine"],
  },
  {
    primary_id: "D07TRF",
    names: ["F18-Florbetaben"],
  },
  {
    primary_id: "D07TRP",
    names: ["APTO-253"],
  },
  {
    primary_id: "D07TRV",
    names: [
      "Anthrax Vaccine",
      "Anthrax Vaccine, DynPort/AVANT",
      "Injectable Anthrax Vaccine, DynPort/AVANT",
      "Anthrax Vaccine (Injectable), DynPort/AVANT",
    ],
  },
  {
    primary_id: "D07TSE",
    names: [
      "2-Thio-UDP",
      "CHEMBL216011",
      "Thiouridine 5'-Diphosphate",
      "GTPL6202",
      "SCHEMBL9397187",
      "BDBM50194160",
      "[(2R,3S,4R,5R)-3,4-Dihydroxy-5-(4-Oxo-2-Sulfanylidenepyrimidin-1-Yl)Oxolan-2-Yl]methyl Phosphono Hydrogen Phosphate",
      "(2R,3R,4S,5R)-1-(3,4-Dihydroxy-5-(Diphosphoryloxymethyl)-Tetrahydrofuran-2-Yl)-2-Thioxo-2,3-Dihydropyrimidin-4(1H)-One Ammonium Salt",
    ],
  },
  {
    primary_id: "D07TSS",
    names: ["MSC-100-IV"],
  },
  {
    primary_id: "D07TST",
    names: ["4-(2-(Cyclohexylamino)Ethyl)-1H-Imidazole", "CHEMBL1096513", "BDBM50317872"],
  },
  {
    primary_id: "D07TSW",
    names: ["SALACINOL"],
  },
  {
    primary_id: "D07TTI",
    names: ["AZD-8309"],
  },
  {
    primary_id: "D07TTR",
    names: ["N-(4-Fluorobenzyl)-N-(Pyridin-4-Yl)-2-Naphthamide", "CHEMBL1091737"],
  },
  {
    primary_id: "D07TTT",
    names: ["PMID27841045-Compound-137"],
  },
  {
    primary_id: "D07TUD",
    names: ["PF-2391677"],
  },
  {
    primary_id: "D07TUN",
    names: ["PMID26004420-Compound-WO2014085210B"],
  },
  {
    primary_id: "D07TUT",
    names: ["ND-07"],
  },
  {
    primary_id: "D07TUX",
    names: ["7-(2-(1H-Imidazol-1-Yl)Ethoxy)-2H-Chromen-2-One", "Coumarin Deriv. 29", "CHEMBL390152", "BDBM9480"],
  },
  {
    primary_id: "D07TVA",
    names: ["1-Butoxy-4-Phenoxybenzene", "CHEMBL475731", "4-Butoxydiphenyl Ether", "SCHEMBL13545912", "BDBM50246008"],
  },
  {
    primary_id: "D07TVE",
    names: ["PMID20363624C(+)17a"],
  },
  {
    primary_id: "D07TVI",
    names: ["PF-0998425"],
  },
  {
    primary_id: "D07TVO",
    names: ["PMID25726713-Compound-49"],
  },
  {
    primary_id: "D07TWK",
    names: ["TCN-P"],
  },
  {
    primary_id: "D07TWN",
    names: ["Tafenoquine", "106635-80-7", "WR-238605", "WR 238605", "Etaquine", "WR238605", "Tafenoquine [INN:BAN"],
  },
  {
    primary_id: "D07TWQ",
    names: ["ONO-AE5-599"],
  },
  {
    primary_id: "D07TWV",
    names: [
      "2-(Benzyloxy)Naphthalene",
      "Benzyl 2-Naphthyl Ether",
      "613-62-7",
      "2-Benzyloxynaphthalene",
      "Naphthalene, 2-(Phenylmethoxy)-",
      "2-(Phenylmethoxy)Naphthalene",
      "2-Benzyloxy-Naphthalene",
      "2-Phenylmethoxynaphthalene",
      "CHEMBL146439",
      "Q-200695",
      "2-(Phenylmethoxy)-Naphthalene",
      "Benzyl2-Naphthylether",
      "2-Phenylmethoxy Naphthalenen",
      "B-Naphthylbenzyl Ether",
      "Ss-Naphtholbenzylaether",
      "Benzyl-2-Naphthylether",
      "ACMC-209mru",
      "AI3-00945",
      "AC1L3VTE",
      "Beta-Naphthyl Benzyl Ether",
      "EC 405-490-3",
      "SCHEMBL51460",
      "KSC493E6R",
      "CTK3J3268",
      "DTXSID80210201",
      "MolPort-002-476-516",
    ],
  },
  {
    primary_id: "D07TWZ",
    names: ["TDI-0053"],
  },
  {
    primary_id: "D07TXF",
    names: ["5-(6-Chloro-2-Hexyl-1H-Indol-1-Yl)-5-Oxo-Valeric Acid", "Compound 39 [PMID: 23581530]"],
  },
  {
    primary_id: "D07TXJ",
    names: ["Dextofisopam"],
  },
  {
    primary_id: "D07TYE",
    names: ["GS-9857"],
  },
  {
    primary_id: "D07TYJ",
    names: ["PRO-98498"],
  },
  {
    primary_id: "D07TYK",
    names: ["2'-3'-Dideoxy-7-Deaza-Guaninetriphosphate", "CHEMBL195111", "BDBM50164639"],
  },
  {
    primary_id: "D07TYL",
    names: ["[3H]thiamine"],
  },
  {
    primary_id: "D07TYM",
    names: [
      "Autologous T-Lymphocyte Cell Therapy",
      "Autologous T-Lymphocyte Cell Therapy (G250-ScFV-Transduced, Renal Cell Carcinoma)",
    ],
  },
  {
    primary_id: "D07TYV",
    names: ["BAY1862864"],
  },
  {
    primary_id: "D07TZM",
    names: ["SC-58125"],
  },
  {
    primary_id: "D07TZT",
    names: [
      "A-007",
      "Bis(4-Hydroxyphenyl)Methanone (2,4-Dinitrophenyl)Hydrazone",
      "4,4'-Dihydrobenzophenone-2,4-Dinitrophenylhydrazone",
    ],
  },
  {
    primary_id: "D07UAJ",
    names: ["CMB-401", "CDP-671"],
  },
  {
    primary_id: "D07UAR",
    names: ["Idhifaenasidenib"],
  },
  {
    primary_id: "D07UAS",
    names: ["PEG Arginine Deiminase", "ADI-PEG 20"],
  },
  {
    primary_id: "D07UBB",
    names: ["ITI-003"],
  },
  {
    primary_id: "D07UBG",
    names: [
      "Olmesartan Medoxomil",
      "Benevas",
      "Benicar",
      "Olmetec",
      "Olvance",
      "Forest Brand Of Olmesartan Medoxomil",
      "Olmesartan Medoximil",
      "Sankyo Brand Of Olmesartan Medoxomil",
      "Cs 866",
      "Benicar (TN)",
      "Berlin-Chemie Brand Of Olmesartan Medoxomil",
      "CS-866",
      "CS-866DM",
      "CS-866RN",
      "DE-092",
      "KS-1182",
      "Olmetec (TN)",
      "Benicar, Olmetec,Olmesartan",
      "Olmesartan Medoxomil (JAN/USAN)",
      "(5-Methyl-2-Oxo-1,3-Dioxol-4-Yl)Methyl 5-(2-Hydroxypropan-2-Yl)-2-Propyl-3-[[4-[2-(2H-Tetrazol-5-Yl)Phenyl]phenyl]methyl]imidazole-4-Carboxylate",
      "(5-Methyl-2-Oxo-1,3-Dioxolen-4-Yl)Methoxy-4-(1-Hydroxy-1-Methylethyl)-2-Propyl-1-(4-(2-(Tetrazol-5-Yl)Phenyl)Phenyl)Methylimidazol-5-Carboxylate",
      "1H-Imidazole-5-Carboxylic Acid, 4-(1-Hydroxy-1-Methylethyl)-2-Propyl-1-((2'-(1H-Tetrazol-5-Yl) (1,1'-Biphenyl)-4-Yl)Methyl)-, (5-Methyl-2-Oxo-1,3-Dioxol-4-Yl) Methyl Ester",
      "1H-Imidazole-5-Carboxylic Acid, 4-(1-Hydroxy-1-Methylethyl)-2-Propyl-1-((2'-(1H-Tetrazol-5-Yl)(1,1'-Biphenyl)-4-Yl)Methyl)-, (5-Methyl-2-Oxo-1,3-Dioxol-4-Yl)Methyl Ester",
      "1H-Imidazole-5-Carboxylic Acid, 4-(1-Hydroxy-1-Methylethyl)-2-Propyl-1-((2'-(1H-Tetrazol-5-Yl)(1,1'-Biphenyl)-4-Yl)Methyl)-,(5-Methyl-2-Oxo-1,3-Dioxol-4-Yl)Methyl Ester",
    ],
  },
  {
    primary_id: "D07UBK",
    names: ["AS-1892802"],
  },
  {
    primary_id: "D07UBR",
    names: ["Cis-N,5-Dibenzyl-6-Oxomorpholine-3-Carboxamide", "CHEMBL215688"],
  },
  {
    primary_id: "D07UBZ",
    names: ["Thiazoline Derivative 2"],
  },
  {
    primary_id: "D07UCE",
    names: ["4-Benzyl-1-[3-Phenylpropoxy)Propyl]piperidine", "CHEMBL513620"],
  },
  {
    primary_id: "D07UCF",
    names: [
      "LY266500",
      "CHEMBL510900",
      "AC1LDAWE",
      "2-(3-Chloro-4-Fluorophenyl)-3(2H)-Isothiazolone",
      "SCHEMBL4700504",
      "OOOJWRSQJXGEDS-UHFFFAOYSA-N",
      "MolPort-002-906-771",
      "ZINC12367067",
      "BDBM50247868",
      "AKOS022310026",
      "MCULE-1464379671",
      "2-(3-Chloro-4-Fluorophenyl)-1,2-Thiazol-3-One",
      "2-(3-Chloro-4-Fluorophenyl)Isothiazole-3(2H)-One",
    ],
  },
  {
    primary_id: "D07UCQ",
    names: ["Sodium Phenylaminomethanesulfonate"],
  },
  {
    primary_id: "D07UDI",
    names: ["HO/04/08"],
  },
  {
    primary_id: "D07UDO",
    names: ["DNX-3000", "DNX-03012", "DNX-03013", "DNX-03023", "PKM2 Modulator (Cancer), Dynamix"],
  },
  {
    primary_id: "D07UDT",
    names: ["L-798,106"],
  },
  {
    primary_id: "D07UDW",
    names: ["COG-112", "COG-68"],
  },
  {
    primary_id: "D07UEJ",
    names: ["Empagli Ozin"],
  },
  {
    primary_id: "D07UFK",
    names: ["PMID26061392C2", "Caged Vemurafenib Prodrug", "GTPL8548"],
  },
  {
    primary_id: "D07UFO",
    names: ["20-HETE", "20-Hydroxyeicosatetraenoic Acid"],
  },
  {
    primary_id: "D07UFS",
    names: ["SPI-5557"],
  },
  {
    primary_id: "D07UGA",
    names: ["Sphingosine-1-Phosphate"],
  },
  {
    primary_id: "D07UGI",
    names: ["PPS14 PCMV"],
  },
  {
    primary_id: "D07UHG",
    names: ["Fullerene-Encapsulated MRI Imaging Agents"],
  },
  {
    primary_id: "D07UHS",
    names: ["Fingolimod", "2-Amino-2-(4-Octylphenethyl)Propane-1,3-Diol"],
  },
  {
    primary_id: "D07UHX",
    names: ["9-AMINOCAMPTOTHECIN"],
  },
  {
    primary_id: "D07UHY",
    names: ["NIK-616"],
  },
  {
    primary_id: "D07UIO",
    names: ["13-(3-N-Pentylureido)Tridec-8(E)-Enoic Acid", "CHEMBL559573"],
  },
  {
    primary_id: "D07UIS",
    names: ["CORT125281"],
  },
  {
    primary_id: "D07UJA",
    names: ["DRL-17822", "CETP Inhibitor (Dyslipidemia/Atherosclerosis/Cardiovascular Diseases), Dr Reddy's"],
  },
  {
    primary_id: "D07UJJ",
    names: ["Quinoline And Quinazoline Derivative 4"],
  },
  {
    primary_id: "D07UJV",
    names: ["DM3A6S", "CHEMBL437682"],
  },
  {
    primary_id: "D07UKF",
    names: ["VBY-129", "Cathepsin S Inhibitor (Oral, Psoriasis), Virobay"],
  },
  {
    primary_id: "D07UKJ",
    names: ["Enavatuzumab"],
  },
  {
    primary_id: "D07UKR",
    names: ["ISIS 19220"],
  },
  {
    primary_id: "D07UKT",
    names: ["ALRN-6924"],
  },
  {
    primary_id: "D07ULI",
    names: ["PMID27215781-Compound-25"],
  },
  {
    primary_id: "D07ULK",
    names: ["OX-23", "Analgesic (Sublingual Tablet), Orexo"],
  },
  {
    primary_id: "D07ULQ",
    names: [
      "N-Methyl-N-(Prop-2-Ynyl)-1H-Pyrrole-2-Carboxamide",
      "CHEMBL353159",
      "Pyrrole Inhibitor 33",
      "MLS004256862",
      "BDBM15608",
      "AKOS017413443",
      "SMR003081937",
      "N-Methyl,N-Propargyl-1H-Pyrrole-2-Carboxamide",
    ],
  },
  {
    primary_id: "D07ULS",
    names: ["DDG-39"],
  },
  {
    primary_id: "D07UMR",
    names: ["Vaccine, Prostate Tumor"],
  },
  {
    primary_id: "D07UNH",
    names: ["Pyridine And Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D07UOF",
    names: ["C(RGDfMeF)", "CHEMBL383747"],
  },
  {
    primary_id: "D07UOS",
    names: ["MK-434", "17beta-Benzoyl-4-Aza-5alpha-Androst-1-En-3-One"],
  },
  {
    primary_id: "D07UPJ",
    names: ["NMDA NR2B Antagonists"],
  },
  {
    primary_id: "D07UQQ",
    names: ["ACE-536"],
  },
  {
    primary_id: "D07UQR",
    names: ["Keyhole Limpet Hemocyanin"],
  },
  {
    primary_id: "D07URD",
    names: ["PRX-102"],
  },
  {
    primary_id: "D07URH",
    names: ["AP-004"],
  },
  {
    primary_id: "D07USR",
    names: ["SAVX-1"],
  },
  {
    primary_id: "D07USV",
    names: ["MM-302"],
  },
  {
    primary_id: "D07UTC",
    names: ["QNL-111"],
  },
  {
    primary_id: "D07UTU",
    names: ["BXQ-350"],
  },
  {
    primary_id: "D07UTV",
    names: [
      "2-Nitro-N-(2,4,5-Trichlorophenyl)Benzamide",
      "CHEMBL570080",
      "AC1LEFM1",
      "CBMicro_048760",
      "Oprea1_298416",
      "MolPort-001-513-202",
      "ZINC103157",
      "STL255954",
      "BDBM50297534",
      "AKOS003265549",
      "MCULE-2760222088",
      "ST011169",
      "BIM-0048873.P001",
    ],
  },
  {
    primary_id: "D07UTZ",
    names: ["[3H]histidine", "[3H]-Histidine"],
  },
  {
    primary_id: "D07UUB",
    names: ["4SC-203"],
  },
  {
    primary_id: "D07UUQ",
    names: ["1-Benzyl-4-(2-Ethynyl-Pyrrol-1-Yl)-Piperidine", "CHEMBL325104", "BDBM50082164"],
  },
  {
    primary_id: "D07UVN",
    names: [
      "3-Methoxyl-4'-Amino-Trans-Stilbene",
      "4-Amino-3'-Methoxystilbene",
      "CCRIS 6787",
      "CHEMBL1173573",
      "4-(2-(3-Methoxyphenyl)Ethenyl)Benzenamine",
      "Benzenamine, 4-(2-(3-Methoxyphenyl)Ethenyl)-",
      "154028-32-7",
      "AC1O5THI",
      "3'-Methoxystilben-4-Amine",
      "SCHEMBL3365675",
      "SCHEMBL3365670",
      "KQIPLUOUWQXDOT-AATRIKPKSA-N",
      "BDBM50322058",
      "3-Methoxyl-4''-Amino-Trans-Stilbene",
      "AKOS030582815",
      "LS-28359",
      "4-[(E)-2-(3-Methoxyphenyl)Ethenyl]aniline",
    ],
  },
  {
    primary_id: "D07UWD",
    names: ["Ustiloxin D"],
  },
  {
    primary_id: "D07UWJ",
    names: [
      "Bromhexine",
      "Bromhexine Hydrochloride",
      "611-75-6",
      "Bromhexine HCl",
      "Auxit",
      "Bromohexine Hydrochloride",
      "Viscolyt",
      "Bisolvon",
      "Broncokin",
      "Bromhexine Chloride",
      "Quentan",
      "UNII-YC2ZOM3Z8V",
      "Bromhexine (Hydrochloride)",
      "Bromohexine Monohydrochloride",
      "2,4-Dibromo-6-((Cyclohexyl(Methyl)Amino)Methyl)Aniline Hydrochloride",
      "YC2ZOM3Z8V",
      "CHEBI:31303",
      "NCGC00095005-01",
      "AK163668",
      "N-(2-Amino-3,5-Dibromobenzyl)-N-Methyl-Cyclohexylammonium Chloride",
      "2-Amino-3,5-Dibromo-N-Cyclohexyl-N-Methylbenzylamine Hydrochloride",
    ],
  },
  {
    primary_id: "D07UWV",
    names: [
      "Argatroban",
      "Acova",
      "Argatrobanum",
      "MMTQAP",
      "MPQA",
      "Novastan",
      "Argatrobanum [Latin]",
      "MITSUBISHI INHIBITOR",
      "MCI 9038",
      "MD 805",
      "MD805",
      "Argatroban (Anhydrous)",
      "Argatroban [INN:JAN]",
      "GN-1600",
      "MCI-9038",
      "MD-805",
      "MQI-ARG-MCP",
      "(2R,4R)-1-[(2S)-5-(Diaminomethylideneamino)-2-[(3-Methyl-1,2,3,4-Tetrahydroquinolin-8-Yl)Sulfonylamino]pentanoyl]-4-Methylpiperidine-2-Carboxylic Acid",
      "(2R,4R)-1-[(2S)-5-(Diaminomethylideneamino)-2-[[(3R)-3-Methyl-1,2,3,4-Tetrahydroquinolin-8-Yl]sulfonylamino]pentanoyl]-4-Methylpiperidine-2-Carboxylic Acid",
      "(2R,4R)-1-[(2S)-5-(Diaminomethylideneamino)-2-[[(3S)-3-Methyl-1,2,3,4-Tetrahydroquinolin-8-Yl]sulfonylamino]pentanoyl]-4-Methylpiperidine-2-Carboxylic Acid",
      "(2R,4R)-1-[5-(Diaminomethylideneamino)-2-[(3-Methyl-1,2,3,4-Tetrahydroquinolin-8-Yl)Sulfonylamino]pentanoyl]-4-Methylpiperidine-2-Carboxylic Acid",
      "(2R,4R)-4-Methyl-1-((S)-N(Sup 2)-(((RS)-1,2,3,4-Tetrahydro-3-Methyl-8-Quinolyl)Sulfonyl)Arginyl)Pipecolic Acid",
      "(2R,4R)-4-Methyl-1[Nalpha-[(3-Methyl-1,2,3,4-Tetrahydro-8-Quinolinyl)Sulfonyl]-L-Arginyl)]-2-Piperadinecarboxylic Acid",
      "(2r,4r)-4-Methyl-1-[n2-(3-Methyl-1,2,3,4-Tetrahydro-8-Quinolinesulfonyl)-L-Arginyl]-2-Piperidinecarboxylic Acid",
      "21R-Argatroban",
      "4-Methyl-1-(N(2)-(3-Methyl-1,2,3,4-Tetrahydro-8-Quinolinesulfonyl)-L-Arginyl)-2-Piperidinecarboxylic Acid",
    ],
  },
  {
    primary_id: "D07UXB",
    names: ["1-OH-PGE1", "19-Hydroxy-PGE1", "19-Hydroxyprostaglandin E1", "19-OH PGE1"],
  },
  {
    primary_id: "D07UXF",
    names: ["AR19"],
  },
  {
    primary_id: "D07UXJ",
    names: ["Aom-0785"],
  },
  {
    primary_id: "D07UXP",
    names: [
      "Cinoxacin",
      "Cinobac",
      "Cinobactin",
      "Cinoxacine",
      "Cinoxacino",
      "Cinoxacinum",
      "Cinx",
      "Clinoxacin",
      "Uronorm",
      "Azolinic Acid",
      "C 8645",
      "Lilly 64716",
      "TNP00246",
      "Cinobac (TN)",
      "Cinoxacine [INN-French]",
      "Cinoxacino [INN-Spanish]",
      "Cinoxacinum [INN-Latin]",
      "Cinoxacin (JAN/USP/INN)",
      "Cinoxacin [USAN:BAN:INN:JAN]",
      "1-Ethyl-1,4-Dihydro-4-Oxo(1,3)Dioxolo(4,5-G)Cinnoline-3-Carboxylic Acid",
      "1-Ethyl-1,4-Dihydro-4-Oxo[1,3]dioxolo[4,5-G]cinnoline-3-Carboxylic Acid",
      "1-Ethyl-4-Oxo-1,4-Dihydro[1,3]dioxolo[4,5-G]cinnoline-3-Carboxylic Acid",
      "1-Ethyl-6,7-Methylenedioxy-4(1H)-Oxocinnoline-3-Carboxylic Acid",
      "1-Ethyl-4-Oxo-[1,3]dioxolo[4,5-G]cinnoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D07UXW",
    names: ["C[Nle-Asp-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL409144", "BDBM50184350"],
  },
  {
    primary_id: "D07UYO",
    names: ["Linezolid"],
  },
  {
    primary_id: "D07UYV",
    names: ["PMID8568816C56"],
  },
  {
    primary_id: "D07UZL",
    names: ["PMID29324067-Compound-40"],
  },
  {
    primary_id: "D07UZX",
    names: ["Nerve Growth Factor Conjugated RAP Peptide"],
  },
  {
    primary_id: "D07UZY",
    names: [
      "Lpathomab",
      "Anti-Lysophosphatidic Acid (LPA) Monoclonal Antibody (Cancer/Neuropathic Pain/Fibrosis/Ocular Inflammation), Lpath",
    ],
  },
  {
    primary_id: "D07VAI",
    names: ["ALZ-801"],
  },
  {
    primary_id: "D07VAQ",
    names: ["MG-1109"],
  },
  {
    primary_id: "D07VBA",
    names: ["Estradiol Valerate", "Delestrogen"],
  },
  {
    primary_id: "D07VBS",
    names: ["A412997", "A-412,997", "A-412997"],
  },
  {
    primary_id: "D07VCG",
    names: [
      "Anti-VEGFR 3 Mab",
      "Anti-VEGFR 3 MAb (Undisclosed Indication)",
      "Anti-VEGFR 3 MAb (Undisclosed Indication), PharmAbcine",
      "Vascular Endothelial Growth Factor Receptor 3 Inhibitor (MAb, Undisclosed Indication), PharmAbcine",
    ],
  },
  {
    primary_id: "D07VCN",
    names: [
      "4-Ethoxy-7-((E)-Styryl)-Furo[3,2-G]chromen-5-One",
      "CHEMBL76874",
      "MRS1066",
      "GTPL396",
      "MRS-1066",
      "BDBM50051338",
      "AKOS030557417",
    ],
  },
  {
    primary_id: "D07VDF",
    names: ["CXCR4 Gene Disrupted T Cells", "CXCR4 Gene Disrupted T Cells (HIV Infection)"],
  },
  {
    primary_id: "D07VDJ",
    names: [
      "(2s)-Pyrrolidin-2-Ylmethylamine",
      "(S)-Pyrrolidin-2-Ylmethanamine",
      "69500-64-7",
      "(S)-(+)-2-(Aminomethyl)Pyrrolidine",
      "(2S)-PYRROLIDIN-2-YLMETHYLAMINE",
      "(S)-2-(Aminomethyl)Pyrrolidine",
      "(S)-2-Aminomethylpyrrolidine",
      "(S)-Pyrrolidin-2-Ylmethylamine",
      "[(2S)-Pyrrolidin-2-Yl]methanamine",
      "1-[(2S)-Pyrrolidin-2-Yl]methanamine",
      "2-Pyrrolidinemethanamine, (2S)-",
      "(S)-(+)-2-(Aminomethyl)Pyrrolidine, 98%",
      "AC1MC09B",
      "SCHEMBL83314",
      "CHEBI:44632",
      "CTK5D0186",
      "(2S)-Pyrrolidin-2-Ylmethanamine",
      "MolPort-003-932-494",
      "AUKXFNABVHIUAC-YFKPBYRVSA-N",
      "(2S)-Pyrrolidin-2-Yl-Methylamine",
      "ZINC39357503",
    ],
  },
  {
    primary_id: "D07VDZ",
    names: ["Topiramate"],
  },
  {
    primary_id: "D07VEA",
    names: ["Ac-ICV(1MeW)QDWGAHRCT-NH2"],
  },
  {
    primary_id: "D07VEB",
    names: ["PMID8246234C3h"],
  },
  {
    primary_id: "D07VEP",
    names: ["USIMINE A"],
  },
  {
    primary_id: "D07VEW",
    names: ["DADMe-ImmG"],
  },
  {
    primary_id: "D07VFC",
    names: [
      "6-(2-Fluoropropyl)-4-Methylpyridin-2-Amine",
      "CHEMBL450726",
      "1146620-35-0",
      "SCHEMBL13040574",
      "BDBM50267272",
      "2-Pyridinamine, 6-(2-Fluoropropyl)-4-Methyl-",
    ],
  },
  {
    primary_id: "D07VFD",
    names: ["Mirogabalin"],
  },
  {
    primary_id: "D07VFQ",
    names: ["Six-Membered Heterocyclic Benzamide Derivative 3"],
  },
  {
    primary_id: "D07VGG",
    names: [
      "(Z)-5-Benzylidene-2-Thioxothiazolidin-4-One",
      "5-Benzylidenrodanin",
      "5-Benzylidenerhodanine",
      "Rhodanine, 5-Benzylidene-",
      "NSC 43398",
      "5-Benzylidene-2-Thioxo-4-Thiazolidinone",
      "CHEMBL305763",
      "5-(Phenylmethylene)-2-Thioxo-4-Thiazolidinone",
      "(5Z)-5-Benzylidene-2-Sulfanyl-1,3-Thiazol-4(5H)-One",
      "4-Thiazolidinone, 5-(Phenylmethylene)-2-Thioxo-",
      "3806-42-6",
      "MLS002608541",
      "5-Benzyliden-Rhodanin",
      "AC1LOXMG",
      "Rhodanine, 5-Benzylidene",
      "SCHEMBL3491175",
      "MolPort-001-579-311",
      "MolPort-000-420-057",
      "HMS3087E14",
      "HMS1786A04",
      "ZINC1088586",
      "174813-23-1",
      "STK441367",
    ],
  },
  {
    primary_id: "D07VGT",
    names: ["6-[4-(Pyridin-4-Ylmethyl)Phenyl]naphthalen-2-Ol", "CHEMBL1214739", "SCHEMBL18770333"],
  },
  {
    primary_id: "D07VHB",
    names: [
      "1-(4-Butoxy-Phenyl)-1H-Imidazole",
      "CHEMBL112532",
      "SCHEMBL14129854",
      "1-(4-Butoxyphenyl)-1H-Imidazole",
      "ZINC13530153",
    ],
  },
  {
    primary_id: "D07VHR",
    names: [
      "Phenylbutazone",
      "Alindor",
      "Alkabutazona",
      "Alqoverin",
      "Anerval",
      "Anpuzone",
      "Antadol",
      "Anuspiramin",
      "Arthrizon",
      "Artizin",
      "Artrizin",
      "Artrizone",
      "Artropan",
      "Azdid",
      "Azobutyl",
      "Azolid",
      "Benzone",
      "Betazed",
      "Bizolin",
      "Bunetzone",
      "Busone",
      "Butacompren",
      "Butacote",
      "Butadion",
      "Butadiona",
      "Butadione",
      "Butadionum",
      "Butagesic",
      "Butalgina",
      "Butalidon",
      "Butaluy",
      "Butaphen",
      "Butapirazol",
      "Butapirazole",
      "Butapyrazole",
      "Butarecbon",
      "Butartril",
      "Butartrina",
      "Butatron",
      "Butazina",
      "Butazolidin",
      "Butazolidine",
      "Butazona",
      "Bute",
      "Butidiona",
      "Butone",
      "Butoz",
      "Butylpyrin",
      "Buvetzone",
      "Buzon;Chembutazone",
      "Cotylbutazone",
      "Digibutina",
      "Diossidone",
      "Diozol",
      "Diphebuzol",
      "Diphenylbutazone",
      "Ecobutazone",
      "Elmedal",
      "Equipalazone",
      "Equiphen",
      "Eributazone",
      "Esteve",
      "Febuzina",
      "Fenartil",
      "Fenibutal",
      "Fenibutasan",
      "Fenibutazona",
      "Fenibutol",
      "Fenilbutazon",
      "Fenilbutazona",
      "Fenilbutazone",
      "Fenilbutina",
      "Fenilbutine",
      "Fenilidina",
      "Fenotone",
      "Fenylbutazon",
      "Flexazone",
      "Intalbut",
      "Intrabutazone",
      "Intrazone",
      "Ipsoflame",
      "Kadol",
      "Lingel",
      "Malgesic",
      "Mephabutazon",
      "Mephabutazone",
      "Merizone",
      "Nadazone",
      "Nadozone",
      "Novophenyl",
      "Phebuzin",
      "Phebuzine",
      "Phenbutazol",
      "Phenbutazone",
      "Phenopyrine",
      "Phenylbutaz",
      "Phenylbutazon",
      "Phenylbutazonum",
      "Phenyzene",
      "Phenyzone",
      "Praecirheumin",
      "Pyrabutol",
      "Pyrazolidin",
      "Rectofasa",
      "Reudo",
      "Reudox",
      "Reumasyl",
      "Reumazin",
      "Reumazol",
      "Reumune",
      "Reumuzol",
      "Reupolar",
      "Robizone",
      "Rubatone",
      "Scanbutazone",
      "Schemergen",
      "Schemergin",
      "Shigrodin",
      "Tazone",
      "Tencodyne",
      "Tetnor",
      "Tevcodyne",
      "Therazone",
      "Ticinil",
      "Todalgil",
      "Uzone",
      "Wescozone",
      "Zolaphen",
      "Zolidinum",
      "Alka Butazolidin",
      "Art Rizin",
      "Buta Phen",
      "Butyl Pyrin",
      "Equi Bute",
      "Exrheudon N",
      "Fe Nilbutine",
      "Fenilbutazone [DCIT]",
      "Phenyl Butazone",
      "Phenylbutazon [German]",
      "Pirarreumol B",
      "A 7514",
      "Bizolin 200",
      "G 13871",
      "P 8386",
      "P1686",
      "P1Z",
      "AZOLID (TN)",
      "Apo-Phenylbutazone",
      "Butiwas-Simple",
      "Component Of Azolid-A",
      "DA-192",
      "Fenilbutazona [INN-Spanish]",
      "G 13,871",
      "Ia-But",
      "Mepha-Butazon",
      "Neo-Zoline",
      "Phenyl-Mobuzon",
      "Phenylbutazonum [INN-Latin]",
      "Robizon-V",
      "Robizone-V",
      "Usaf Ge-15",
      "VAC-10",
      "B.T.Z",
      "Phen-Buta-Vet",
      "R-3-ZON",
      "B.t.z.",
      "Bute, Butazolidin, Butatron, Phenylbutazone",
      "Phenylbutazone (JP15/USP/INN)",
      "Phenylbutazone [USAN:INN:BAN:JAN]",
      "1,2-Diphenyl-2,3-Dioxo-4-N-Butylpyrazoline",
      "1,2-Diphenyl-3,5-Dioxo-4-Butylpyrazolidine",
      "1,2-Diphenyl-4-Butyl-3,5-Dioxopyrazolidine",
      "1,2-Diphenyl-4-Butyl-3,5-Pyrazolidinedione",
      "1,2-Diphenyl-3,5-Dioxo-4-N-Butylpyrazoline",
      "3,5-Dioxe-4 Buty-1, Diphenyl-Pyrazolidine",
      "3,5-Dioxo-1,2-Diphenyl-4-N-Butylpyrazolidine",
      "3,5-Dioxo-1,2-Diphenyl-4-N-Butyl-Pyrazolidin",
      "3,5-Dioxo-4-Butyl-1-Diphenyl-Pyrazolidine",
      "4-BUTYL-1,2-DIPHENYL-PYRAZOLIDINE-3,5-DIONE",
      "4-Butyl-1,2-Diphenyl-3,5-Dioxopyrazolidine",
      "4-Butyl-1,2-Diphenyl-3,5-Pyrazolidinedione",
      "4-Butyl-1,2-Diphenylpyrazolidine-3,5-Dione",
      "4-N-Butyl-1,2-Diphenyl-3,5-Pyrazolidinedione",
    ],
  },
  {
    primary_id: "D07VHS",
    names: ["Isavuconazole"],
  },
  {
    primary_id: "D07VHU",
    names: ["NIS793"],
  },
  {
    primary_id: "D07VHX",
    names: ["Augmab"],
  },
  {
    primary_id: "D07VIB",
    names: ["Tecarfarin", "ATI-5923"],
  },
  {
    primary_id: "D07VIK",
    names: [
      "Arsenic Trioxide",
      "Arsenox",
      "Arsentrioxide",
      "Naonobin",
      "Trisenox",
      "Trixenox",
      "Acide Arsenieux [French]",
      "Anhydride Arsenieux [French]",
      "Arseni Trioxydum",
      "Arsenic Blanc [French]",
      "Arsenic Trioxide [JAN]",
      "Arsenigen Saure [German]",
      "Arsenious Acid Anhydride",
      "Arsenious Trioxide",
      "Arsenous Oxide [ISO]",
      "Oxyde Arsenieux",
      "Arsenic Trioxide [UN1561] [Poison]",
      "Arsenic Oxide (As2O3)",
      "Arsenic(III) Trioxide",
      "Oxyde Arsenieux [ISO-French]",
      "Trisenox (TN)",
      "Arsenic Trioxide (JP15/USAN)",
      "Arsenic(3+)",
    ],
  },
  {
    primary_id: "D07VIO",
    names: ["3-(2-Diethylamino-Acetamino)-Rutaecarpine", "CHEMBL1095603"],
  },
  {
    primary_id: "D07VJI",
    names: ["Hookworm Vaccine"],
  },
  {
    primary_id: "D07VJK",
    names: ["IDN-6384"],
  },
  {
    primary_id: "D07VJT",
    names: ["[6,3''']biflavone", "CHEMBL383336", "[6,3'''''']biflavone", "BDBM50183246"],
  },
  {
    primary_id: "D07VKT",
    names: ["Ch14.18 Mab"],
  },
  {
    primary_id: "D07VLC",
    names: ["Fluorophenyl-Substituted Heterocyclic Amide Analog 1"],
  },
  {
    primary_id: "D07VLY",
    names: [
      "Doxorubicin",
      "23214-92-8",
      "Doxil",
      "Doxorubicine",
      "Adriablastin",
      "Doxorubicinum",
      "14-Hydroxydaunomycin",
      "14-Hydroxydaunorubicine",
      "Doxorubicina",
      "Adriamycin Semiquinone",
      "Doxorubicinum [INN-Latin]",
      "Doxorubicine [INN-French]",
      "Doxorubicina [INN-Spanish]",
      "Myocet",
      "FI 106",
      "Doxorubicin [USAN:INN:BAN]",
      "CCRIS 739",
      "NDC 38242-874",
      "HSDB 3070",
      "UNII-80168379AG",
      "NCI-C01514",
      "EINECS 245-495-6",
      "CHEMBL53463",
      "CHEBI:28748",
      "5,12-Naphthacenedione,",
      "ADM",
      "ADR",
      "ThermoDox",
      "Aerosolized Doxorubicin",
      "Doxorubicin Citrate",
      "RDF Rubex",
      "Conjugate Of Doxorubicin With Humanized Monoclonal Antibody LL1 Against CD74",
      "DM2",
      "JT9100000",
      "Adiblastine (Hydrochloride Salt)",
      "Adr Iablatina (Hydrochloride Salt)",
      "Adriablastine (Hydrochloride Salt)",
      "Adriablatina (Hydrochloride Salt)",
      "Adriacin (Hydrochloride Salt)",
      "Adriamycin PFS (TN)",
      "Adriamycin PFS (Hydrochloride Salt)",
      "Adriamycin RDF (TN)",
      "Adriamycin RDF (Hydrochloride Salt)",
      "Adriblastina (TN)",
      "Adriblastina (Hydrochloride Salt)",
      "Adriblatina (Hydrochloride Salt)",
      "Caelyx (TN)",
      "Conjugate Of Doxorubicin With Monoclonal Antibody P4/D10 Against GP120",
      "DOX-SL",
      "Doxorubicin Hydrochloride (Hydrochloride Salt)",
      "Doxorubicin-HLL1",
      "Doxorubicin-HLL1 Conjugate",
      "Farmablastina (Hydrochloride Salt)",
      "Hydroxydaunomycin Hydrochlor Ide (Hydrochloride Salt)",
      "Hydroxydaunomycin Hydrochloride (Hydrochloride Salt)",
      "Hydroxydaunorubicin Hydrochloride (Hydrochloride Salt)",
      "Myocet (TN)",
      "Rubex (TN)",
      "Rubex (Hydrochloride Salt)",
      "TLC D-99",
      "Doxorubicin (USAN/INN)",
      "Doxorubicin-P4/D10",
      "Doxorubicin-P4/D10 Conjugate",
      "Cantide + Adriamycin",
    ],
  },
  {
    primary_id: "D07VMC",
    names: ["LY-243062"],
  },
  {
    primary_id: "D07VNH",
    names: ["Acebilustat"],
  },
  {
    primary_id: "D07VNW",
    names: ["Enol Carbamate Derivative 1"],
  },
  {
    primary_id: "D07VOF",
    names: ["THA-904", "Amiloride Sensitive Cation Channel 1a/1b Antagonist (Neuropathic Pain), Theralpha"],
  },
  {
    primary_id: "D07VPG",
    names: [
      "BW-773U82",
      "BW-A773U",
      "2-(3-Fluoranthenylmethylamino)-2-Methyl-1,3-Propanediol Hydrochloride",
      "3-[2-Hydroxy-1-(Hydroxymethyl)-1-Methylethylaminomethyl]fluoranthene Hydrochloride",
      "773U82.HCl",
    ],
  },
  {
    primary_id: "D07VPM",
    names: ["2-(4-Hydroxy-Phenyl)-7-Phenyl-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D07VPQ",
    names: ["Pyrazinylpyridine Derivative 1"],
  },
  {
    primary_id: "D07VQW",
    names: [
      "Sant7",
      "Beta-Methylene Tad",
      "BETA-METHYLENE-THIAZOLE-4-CARBOXYAMIDE-ADENINE DINUCLEOTIDE",
      "102977-57-1",
      "Beta-Tad",
      "NSC617998",
      "1lrt",
      "NSC 617998",
      "Beta-Methylene Thiazole-4-Carboxamide Adenine Dinucleotide",
      ".beta.-Methylene TAD",
      "AC1L2TER",
      "Adenosine, 5'-(Hydrogen (Phosphonomethyl)Phosphonate), 5'-Ester With 2-Beta-D-Ribofuranosyl-4-Thiazolecarboxamide",
    ],
  },
  {
    primary_id: "D07VRO",
    names: ["Benzalkonium Chloride"],
  },
  {
    primary_id: "D07VRP",
    names: ["EG-Mirotin", "EGT-022"],
  },
  {
    primary_id: "D07VSA",
    names: ["KRX-0403", "Anhydrovinblastine (AVLB)"],
  },
  {
    primary_id: "D07VTD",
    names: ["HBV-MF59"],
  },
  {
    primary_id: "D07VUD",
    names: ["ZD-3980"],
  },
  {
    primary_id: "D07VUL",
    names: ["PMID26004420-Compound-WO2013039802B"],
  },
  {
    primary_id: "D07VWC",
    names: ["NSC-401077"],
  },
  {
    primary_id: "D07VWP",
    names: ["S-9947"],
  },
  {
    primary_id: "D07VWT",
    names: ["Platelet Activating Factor Receptor Inhibitors"],
  },
  {
    primary_id: "D07VXQ",
    names: ["3,3'-Pyridine-2,5-Diyldiphenol"],
  },
  {
    primary_id: "D07VXT",
    names: ["INCB8696"],
  },
  {
    primary_id: "D07VXX",
    names: ["2-(Benzyloxyamino)-N-Hydroxy-3-Methylpentanamide", "CHEMBL228231"],
  },
  {
    primary_id: "D07VYO",
    names: ["Recombinant Human Alkaline Phosphatase"],
  },
  {
    primary_id: "D07VYP",
    names: ["FT-2102"],
  },
  {
    primary_id: "D07VYX",
    names: [
      "4-((+/-)-(1H-Imidazol-1-Yl)-(E)-Methylretinoate",
      "CHEMBL224828",
      "BDBM50158415",
      "4-(1-Imidazolyl)Retinoic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D07VZG",
    names: [
      "BRL-55473",
      "CHEMBL336590",
      "SCHEMBL3133982",
      "SCHEMBL3133975",
      "SCHEMBL12390534",
      "BRL-57269A",
      "BDBM50212570",
    ],
  },
  {
    primary_id: "D07VZV",
    names: ["N-0861"],
  },
  {
    primary_id: "D07WAA",
    names: ["Personalized Peptide Vaccine"],
  },
  {
    primary_id: "D07WAF",
    names: [
      "Anti-Fas Mabs",
      "Anti-Fas MAbs (Misfolded Proteins, Cancer)",
      "Anticancer Monoclonal Antibodies (Misfolded Fas Receptor-Targeting), Amorfix/Apexigen",
      "Anticancer Monoclonal Antibodies (Misfolded Fas Receptor-Targeting), Amorfix/Epitomics",
      "Anti-Fas MAbs (Misfolded Proteins, Cancer), Amorfix/Apexigen",
    ],
  },
  {
    primary_id: "D07WAT",
    names: ["PMID29671355-Compound-62"],
  },
  {
    primary_id: "D07WBD",
    names: ["ISIS 10310"],
  },
  {
    primary_id: "D07WCT",
    names: ["SAR-100842"],
  },
  {
    primary_id: "D07WCX",
    names: ["ISIS 2502"],
  },
  {
    primary_id: "D07WDB",
    names: ["RGD"],
  },
  {
    primary_id: "D07WDQ",
    names: ["GNF-PF-67"],
  },
  {
    primary_id: "D07WDS",
    names: ["NNC-14-0189"],
  },
  {
    primary_id: "D07WDW",
    names: ["VR496"],
  },
  {
    primary_id: "D07WEN",
    names: ["Venglustat"],
  },
  {
    primary_id: "D07WEP",
    names: [
      "Diazoxide",
      "Aroglycem",
      "Diazossido",
      "Diazoxido",
      "Diazoxidum",
      "Dizoxide",
      "Eudemine",
      "Hyperstat",
      "Hypertonalum",
      "Mutabase",
      "Proglicem",
      "Proglycem",
      "Diazossido [DCIT]",
      "Diazossido [Italian]",
      "D 9035",
      "SRG 95213",
      "Sch 6783",
      "Diazoxido [INN-Spanish]",
      "Diazoxidum [INN-Latin]",
      "Hyperstat (TN)",
      "SRG-95213",
      "Sch-6783",
      "Diazoxide [USAN:INN:BAN]",
      "Diazoxide (JAN/USP/INN)",
      "2H-1,2, 4-Benzothiadiazine, 7-Chloro-3-Methyl-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine, 7-Chloro-3-Methyl-, 1,1-Dioxide",
      "7-Chloro-3-Methyl-1lambda~4~,2,4-Benzothiadiazin-1-Ol 1-Oxide",
      "7-Chloro-3-Methyl-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "7-Cloro-3-Metil-2H-1,2,4-Benzotiodiazina-1,1-Diossido",
      "7-Cloro-3-Metil-2H-1,2,4-Benzotiodiazina-1,1-Diossido [Italian]",
      "7-Chloro-3-Methyl-4H-1",
    ],
  },
  {
    primary_id: "D07WFF",
    names: ["ISIS 103708"],
  },
  {
    primary_id: "D07WFG",
    names: ["BB-78484"],
  },
  {
    primary_id: "D07WFH",
    names: ["2-Phenyl-N,N-Bis(O-Tolylthiomethyl)Ethanamine", "CHEMBL398687", "AC1MRYJJ", "BDBM50232470"],
  },
  {
    primary_id: "D07WFK",
    names: [
      "Acetohexamide",
      "Acetohexamid",
      "Acetohexamida",
      "Acetohexamidum",
      "Dimelin",
      "Dimelor",
      "Dymelor",
      "Gamadiabet",
      "Hypoglicil",
      "Metaglucina",
      "Minoral",
      "Ordimel",
      "Tsiklamid",
      "Acetohexamide Lilly Brand",
      "Acetohexamide Salvat Brand",
      "Lilly Brand Of Acetohexamide",
      "Ord Imel",
      "Salvat Brand Of Acetohexamide",
      "U 14812",
      "A-178",
      "Acetohexamida [INN-Spanish]",
      "Acetohexamidum [INN-Latin]",
      "Dimelin (Antidiabetic)",
      "Dymelor (TN)",
      "U-14812",
      "Acetohexamide (JP15/USP/INN)",
      "Acetohexamide [USAN:INN:BAN:JAN]",
      "N-(P-Acetylbenzenesulfonyl)-N'-Cyclohexylurea",
      "N-(P-Acetylphenylsulfonyl)-N'-Cyclohexylurea",
      "1-((P-Acetylphenyl)Sulfonyl)-3-Cyclohexylurea",
      "1-(4-Acetylphenyl)Sulfonyl-3-Cyclohexylurea",
      "1-(P-Acetylbenzenesulfonyl)-3-Cyclohexylurea",
      "3-Cyclohexyl-1-(P-Acetylphenylsulfonyl)Urea",
      "4-Acetyl-N-((Cyclohexylamino)Carbonyl)Benzenesulfonamide",
      "4-Acetyl-N-[(Cyclohexylamino)-Carbonyl]benzenesulfonamide",
      "4-Acetyl-N-[(Cyclohexylamino)Carbonyl]benzenesulfonamide",
      "4-Acetyl-N-((Cyclohexylamino)Carbonyl)Benzenesulf Onamide",
    ],
  },
  {
    primary_id: "D07WFQ",
    names: ["VBP 926 Topical Solution"],
  },
  {
    primary_id: "D07WFU",
    names: ["Trans-(+/-)-2-Fluoro-1,2-Diphenylcyclopropylamine", "CHEMBL511601"],
  },
  {
    primary_id: "D07WGE",
    names: [
      "C-KAD Ophthalmic Solution",
      "C-KAD Ophthalmic Solution (Cataract/Asteroid Hyalosis/Glaucoma)",
      "C-KAD Ophthalmic Solution (Cataract/Asteroid Hyalosis/Glaucoma), Chakshu",
      "Chelating Agent (Ophthalmic Solution, Cataract/Asteroid Hyalosis/Glaucoma), Chakshu",
    ],
  },
  {
    primary_id: "D07WGN",
    names: ["DP9"],
  },
  {
    primary_id: "D07WGW",
    names: ["WJ-9708011"],
  },
  {
    primary_id: "D07WHN",
    names: ["FASORACETAM"],
  },
  {
    primary_id: "D07WHS",
    names: ["CL-316,243"],
  },
  {
    primary_id: "D07WHV",
    names: [
      "4-Piperidino-Piperidine",
      "4-Piperidinopiperidine",
      "4897-50-1",
      "1,4'-Bipiperidine",
      "4-PIPERIDINO-PIPERIDINE",
      "1,4'-Bipiperidyl",
      "1,4-Bipiperidine",
      "[1,4']BIPIPERIDINYL",
      "4-(1-Piperidinyl)Piperidine",
      "1-(Piperidin-4-Yl)Piperidine",
      "4-(Piperidin-1-Yl)Piperidine",
      "4-Piperidino Piperidine",
      "4-Piperidin-1-Ylpiperidine",
      "1-(4-PIPERIDYL)PIPERIDINE",
      "CHEMBL174391",
      "CHEBI:40117",
      "QDVBKXJMLILLLB-UHFFFAOYSA-N",
      "N-(4-PIPERIDINO)PIPERIDINE",
      "MFCD00006475",
      "4-Piperidylpiperidine",
      "{1,4'}bipiperidinyl",
      "4PN",
      "1,4'-Bipiperidin",
      "4-Piperidinopiperidine, 95%, Tech.",
    ],
  },
  {
    primary_id: "D07WIB",
    names: ["Hydroxycitric Acid"],
  },
  {
    primary_id: "D07WIC",
    names: [
      "CP-640186",
      "591778-68-6",
      "UNII-04L1E4J3ZT",
      "CP640186",
      "(R)-Anthracen-9-Yl(3-(Morpholine-4-Carbonyl)-[1,4'-Bipiperidin]-1'-Yl)Methanone",
      "CHEMBL208943",
      "04L1E4J3ZT",
      "CHEBI:45293",
      "(3R)-1'-(9-Anthrylcarbonyl)-3-(Morpholin-4-Ylcarbonyl)-1,4'-Bipiperidine",
      "(3R)-1'-(Anthracen-9-Ylcarbonyl)-3-(Morpholin-4-Ylcarbonyl)-1,4'-Bipiperidine",
      "RCP",
      "CP 640186",
      "{(3R)-1'-(Anthracen-9-Ylcarbonyl)[1,4'-Bipiperidin]-3-Yl}(Morpholin-4-Yl)Methanone",
      "[(3R)-1-[1-(Anthracene-9-Carbonyl)Piperidin-4-Yl]piperidin-3-Yl]-Morpholin-4-Ylmethanone",
      "591778-70-0",
    ],
  },
  {
    primary_id: "D07WIJ",
    names: ["(R)(+)-7-Fluoro-2-(4-Fluorophenyl)Chroman-4-One", "CHEMBL599762"],
  },
  {
    primary_id: "D07WJE",
    names: [
      "NVC-422 Nasal",
      "AgaNase",
      "AL-46383A",
      "NVC-422",
      "Aganocides (Infection), NovaBay",
      "NVC-422 (Skin Infections), NovaBay",
      "Aganocides (Lavage Solution, Urinary Tract Infections/Bacterial Infections), NovaBay",
      "NVC-422 (Lavage Solution, Urinary Tract Infections), NovaBay",
      "NVC-422 (Nasal, Infection Prevention), NovaBay",
      "NVC-422 (Nasal, Infection Prevention), NovaCal",
      "Aganocides (Ear/Eye/Sinus Infection), NovaBay/Alcon",
      "N,N-Dichloro-2-2-Dimethyltaurine",
      "NVC-422 (Topical, Skin Infections), NovaBay/Galderma",
      "NVC-422 (Ophthalmic/Otic, Ear/Eye/Sinus Infection), NovaBay",
      "NVC-422 (Ophthalmic/Otic, Ear/Eye/Sinus Infection), NovaBay/Alcon",
    ],
  },
  {
    primary_id: "D07WJU",
    names: ["SUVN-911", "Alpha 4 Beta 2 Nicotinic Acetylcholine Receptor (NAChR) Antagonist (Depression), Suven"],
  },
  {
    primary_id: "D07WJX",
    names: [
      "C16-Fatty-Acyl-Substrate-Mimic",
      "C16-FATTY-ACYL-SUBSTRATE-MIMIC",
      "TRANS-2-HEXADECENOYL-(N-ACETYL-CYSTEAMINE)-THIOESTER",
      "AC1MP1RZ",
      "S-Palmitoyl-N-Acetylcysteamine",
      "SCHEMBL5088794",
      "DB02990",
      "S-(2-Acetamidoethyl) Hexadecanethioate",
      "S-[2-(Acetylamino)Ethyl] Hexadecanethioate",
    ],
  },
  {
    primary_id: "D07WKC",
    names: ["Filgrastim-SNDZ"],
  },
  {
    primary_id: "D07WLI",
    names: ["99mTc-Fanolesomab"],
  },
  {
    primary_id: "D07WLT",
    names: [
      "6-Pyridin-3-Yl-3,4-Dihydronaphthalen-2(1H)-One",
      "CHEMBL462093",
      "2(1H)-Naphthalenone,3,4-Dihydro-6-(3-Pyridinyl)-",
      "BDBM50273703",
    ],
  },
  {
    primary_id: "D07WLU",
    names: [
      "1,2,6-Tri-O-Galloyl-Beta-D-Glucose",
      "AC1L3XRG",
      "1,2,6-Tri-O-Galloyl-.beta.-D-Glucose",
      "1,2,6-Tri-O-Galloyl-.beta.-D-Glucopyranose",
      ".beta.-D-Glucopyranose, 1,2,6-Tris(3,4,5-Trihydroxybenzoate)",
      "[(5R)-4,5-Dihydroxy-3-(3,4,5-Trihydroxybenzoyl)Oxy-4-[(3,4,5-Trihydroxybenzoyl)Oxymethyl]tetrahydrofuran-2-Yl]methyl 3,4,5-Trihydroxybenzoate",
      "[(5R)-4,5-Dihydroxy-3-(3,4,5-Trihydroxybenzoyl)Oxy-4-[(3,4,5-Trihydroxybenzoyl)Oxymethyl]oxolan-2-Yl]methyl 3,4,5-Trihydroxybenzoate",
    ],
  },
  {
    primary_id: "D07WLV",
    names: [
      "Phosphoric Acid Mono-((E)-Dodec-9-Enyl) Ester",
      "CHEMBL190349",
      "SCHEMBL194116",
      "BDBM50170858",
      "9-Dodecen-1-Ol, Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D07WMD",
    names: ["MK-5478"],
  },
  {
    primary_id: "D07WMH",
    names: ["GT102-279"],
  },
  {
    primary_id: "D07WNG",
    names: [
      "Atrimer",
      "Atrimer (Autoimmune Diseases)",
      "Atrimer (Autoimmune Diseases), Anaphore",
      "Engineered Fully-Human Serum Trimeric Protein (Autoimmune Diseases), Anaphore",
      "Atrimer (Autoimmune Diseases), Anaphore/ Mitsubishi Tanabe/ Tanabe Research Labs",
    ],
  },
  {
    primary_id: "D07WNO",
    names: ["Tetra-Azabenzo[e]azulene Derivative 1"],
  },
  {
    primary_id: "D07WON",
    names: ["Bronchostat"],
  },
  {
    primary_id: "D07WOT",
    names: ["Betais-Sulfonylamino Derivative 1"],
  },
  {
    primary_id: "D07WPE",
    names: ["Epothilon"],
  },
  {
    primary_id: "D07WPL",
    names: [
      "5-Aminoisoquinolin-1(2H)-One",
      "93117-08-9",
      "5-Amino-2H-Isoquinolin-1-One",
      "5-AMINOISOQUINOLIN-1-OL",
      "5-Amino-1,2-Dihydroisoquinolin-1-One",
      "5-AIQ",
      "CHEMBL446240",
      "5-Amino-2H-Isoquinoin-1-One",
      "5-AMINO-1(2H)-ISOQUINOLINONE",
      "5-Aminoisoquinolinone",
      "4pnq",
      "32X",
      "AC1Q6DVG",
      "AC1L1CUR",
      "5-Amino-1-Isoquinolinol",
      "SCHEMBL215327",
      "5-Amino-1-Hydroxyisoquinoline",
      "BDBM27503",
      "5-Amino-Isoquinolin-1(2h)-One",
      "5-Amino Isoquinolin-1(2h)-One",
      "CTK5H2122",
      "DTXSID90274354",
      "1(2H)-Isoquinolinone,5-Amino-",
      "MolPort-008-423-043",
      "MolPort-004-803-197",
    ],
  },
  {
    primary_id: "D07WPM",
    names: [
      "6-Thia-10b-Aza-Benzo[e]azulen-4-One",
      "80008-53-3",
      "NSC363827",
      "Pyrrolo(2,1-D)(1,5)Benzothiazepin-7(6H)-One",
      "Pyrrolo[2,1-D][1,5]benzothiazepin-7-One",
      "CHEMBL286138",
      "Pyrrolo[2,1-D][1,5]benzothiazepin-7(6H)-One",
      "NSC 363827",
      "AC1L7OX1",
      "CTK3F0749",
      "DTXSID30230010",
      "ZINC1585392",
      "BDBM50041484",
    ],
  },
  {
    primary_id: "D07WPQ",
    names: ["Oxycodone Hydrochloride"],
  },
  {
    primary_id: "D07WQA",
    names: ["Naphthalene Trisulfonate"],
  },
  {
    primary_id: "D07WQG",
    names: ["FGGFTGARKSARKLADE"],
  },
  {
    primary_id: "D07WQV",
    names: ["AH23848", "(-)AH23848", "(-)-AH23848", "AH 23848", "AH-23848"],
  },
  {
    primary_id: "D07WRP",
    names: ["L-054,522"],
  },
  {
    primary_id: "D07WRW",
    names: ["LADIRUBICIN"],
  },
  {
    primary_id: "D07WSB",
    names: ["N-(4-Amino-3'-Methylbiphenyl-3-Yl)Benzamide", "CHEMBL1094108", "BDBM50317995"],
  },
  {
    primary_id: "D07WSR",
    names: ["TS-032"],
  },
  {
    primary_id: "D07WTG",
    names: [
      "Recombinant Acidic Fibroblast Growth Factor",
      "AFGF",
      "Rh-AFGF",
      "Recombinant Acidic Fibroblast Growth Factor (Ulcer/Burns)",
      "Recombinant Acidic Fibroblast Growth Factor (Ulcer/Burns), SinoBiomed",
      "Recombinant Acidic Fibroplast Growth Factor (Ulcer/Burns), SinoBiomed",
    ],
  },
  {
    primary_id: "D07WTH",
    names: ["UltraCD40L"],
  },
  {
    primary_id: "D07WUJ",
    names: [
      "H5N1 Influenza Vaccine",
      "DeltaFLU-H5N1",
      "GHB-04L1",
      "H5N1 Influenza Vaccine, AVIR Green Hills Biotechnology",
    ],
  },
  {
    primary_id: "D07WXE",
    names: ["L-Alanyl-L-Glutamine", "Dipeptiven (TN)"],
  },
  {
    primary_id: "D07WXH",
    names: ["Pandemrix"],
  },
  {
    primary_id: "D07WXI",
    names: [
      "Maribavir",
      "176161-24-3",
      "Benzimidavir",
      "(2S,3S,4R,5S)-2-(5,6-Dichloro-2-(Isopropylamino)-1H-Benzo[d]imidazol-1-Yl)-5-(Hydroxymethyl)Tetrahydrofuran-3,4-Diol",
      "1263W94",
      "UNII-PTB4X93HE1",
      "PTB4X93HE1",
      "5,6-Dichloro-2-(Isopropylamino)-1-(Beta-L-Ribofuranosyl)-1H-Benzimidazole",
      "5,6-Dichloro-N-(1-Methylethyl)-1-Beta-L-Ribofuranosyl-1H-Benzimidazol-2-Amine",
      "(2S,3S,4R,5S)-2-[5,6-Dichloro-2-(Propan-2-Ylamino)Benzimidazol-1-Yl]-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "Maribavir [USAN:INN:BAN]",
      "Camvia",
      "C15H19Cl2N3O4",
      "Camvia(TM)",
      "Camvia (TN)",
      "Bzurea",
    ],
  },
  {
    primary_id: "D07WXQ",
    names: ["CKD-404"],
  },
  {
    primary_id: "D07WXS",
    names: ["Homoserine Lactone"],
  },
  {
    primary_id: "D07WXT",
    names: ["Lithium Chloride"],
  },
  {
    primary_id: "D07WXU",
    names: ["Urea And Carbamate Bioisostere Derivative 6"],
  },
  {
    primary_id: "D07WYI",
    names: [
      "SPI-1620",
      "142569-99-1",
      "Suc[Glu9,Ala11,15]ET-110-21",
      "GTPL3886",
      "IRL1620",
      "IRL2620",
      "AKOS024456459",
    ],
  },
  {
    primary_id: "D07WYO",
    names: ["JI-3292"],
  },
  {
    primary_id: "D07WYT",
    names: ["Indolocarbazole Analogue"],
  },
  {
    primary_id: "D07WZH",
    names: [
      "Cephaloglycin",
      "CEG",
      "Cefaloglicina",
      "Cefaloglycin",
      "Cefaloglycine",
      "Cefaloglycinum",
      "Cephaloglycine",
      "Kafocin",
      "Kefglycin",
      "Cephaloglycinanhdyous",
      "Cephaloglycin Anhydrous",
      "Cephaoglycin Acid",
      "Lilly 39435",
      "Cefaloglicina [INN-Spanish]",
      "Cefaloglycin (JAN)",
      "Cefaloglycine [INN-French]",
      "Cefaloglycinum [INN-Latin]",
      "Cephaloglycin (Anhydrous)",
      "D-Cephaloglycine",
      "D-(-)-Cephaloglycin",
      "(6R,7R)-3-(Acetoxymethyl)-7-{[(2R)-2-Amino-2-Phenylacetyl]amino}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-(Acetyloxymethyl)-7-[[(2R)-2-Amino-2-Phenylacetyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-[(Acetyloxy)Methyl]-7-{[(2R)-2-Amino-2-Phenylacetyl]amino}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "3-((Acetyloxy)Methyl)-7-((Aminophenylacetyl)Amino)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "3-Acetoxymethyl-7beta-[(2R)-2-Amino-2-Phenylacetamido]-3,4-Didehydrocepham-4-Carboxylic Acid",
      "7-(2-Amino-2-Phenylacetamido)-3-(Hydroxymethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid Acetate (Ester)",
      "7-(2-Amino-2-Phenylacetamido)-3-(Hydroxymethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Octane-2-Carboxylic Acid, Acetate Inner Salt",
      "7-(2-D-Alpha-Aminophenylacetamido)Cephalosporanic Acid",
      "7-(D-2-Amino-2-Phenylacetamido)-3-Acetoxymethyl-Delta(Sup 3)-Cephem-4-Carboxylic Acid",
      "7-(D-2-Amino-2-Phenylacetamido)-3-Acetoxymethyl-Delta(Sup3)-Cephem-4-Carboxylic Acid",
      "7-(D-Alpha-Aminophenyl-Acetamido)Cephalosporanic Acid",
    ],
  },
  {
    primary_id: "D07XAX",
    names: ["AEP-924"],
  },
  {
    primary_id: "D07XAY",
    names: ["ZYH-7"],
  },
  {
    primary_id: "D07XBA",
    names: ["PMID25684022-Compound-US20130053382 36(3-5)"],
  },
  {
    primary_id: "D07XBD",
    names: [
      "6-(4-Hydroxy-Phenyl)-1-Methyl-Naphthalen-2-Ol",
      "CHEMBL372030",
      "SCHEMBL3927148",
      "FGIUXCLAHODWKV-UHFFFAOYSA-N",
      "BDBM50168320",
      "ZINC13645024",
    ],
  },
  {
    primary_id: "D07XBE",
    names: ["Lentinan"],
  },
  {
    primary_id: "D07XBI",
    names: ["AVR118"],
  },
  {
    primary_id: "D07XBW",
    names: ["N-(2,4-Dichloro-Benzoyl)-Benzenesulfonamide"],
  },
  {
    primary_id: "D07XBZ",
    names: ["ARC-200"],
  },
  {
    primary_id: "D07XCA",
    names: [
      "A-286982",
      "280749-17-9",
      "UNII-5I8WFS075A",
      "A 286982",
      "CHEMBL19677",
      "5I8WFS075A",
      "C24H27N3O4S",
      "(2E)-1-(4-Acetyl-1-Piperazinyl)-3-[4-[[2-(1-Methylethyl)Phenyl]thio]-3-Nitrophenyl]-2-Propen-1-One",
      "SCHEMBL5829786",
      "GTPL6592",
      "SCHEMBL12063498",
      "AOB1772",
      "SYN5060",
      "MolPort-023-277-110",
      "ZINC602716",
      "BDBM50092956",
      "AKOS024458044",
      "API0010263",
      "Piperazine, 1-Acetyl-4-((2E)-3-(4-((2-(1-Methylethyl)Phenyl)Thio)-3-Nitrophenyl)-1-Oxo-2-Propenyl)-",
      "(E)-1-(4-Acetylpiperazin-1-Yl)-3-[3-Nitro-4-(2-Propan-2-Ylphenyl)Sulfanylphenyl]prop-2-En-1-One",
      "AS-16426",
    ],
  },
  {
    primary_id: "D07XCB",
    names: ["1-Benzyl-3-Phenylquinazoline-2,4(1H,3H)-Dione", "CHEMBL520083", "ZINC9926727", "BDBM50251699"],
  },
  {
    primary_id: "D07XCF",
    names: [
      "Benfluorex",
      "Benfluorex Hydrochloride",
      "23642-66-2",
      "Mediaxal",
      "Lipophoral",
      "Balans",
      "Benfluorex (Hydrochloride)",
      "EINECS 245-801-8",
      "2-((1-(3-(Trifluoromethyl)Phenyl)Propan-2-Yl)Amino)Ethyl Benzoate Hydrochloride",
      "NCGC00094832-01",
      "2-(Alpha-Methyl-3-(Trifluoromethyl)Phenylethylamino)Ethyl Benzoat",
      "DSSTox_CID_25854",
      "DSSTox_RID_81175",
      "DSSTox_GSID_45854",
      "W-110575",
      "2-((Alpha-Methyl-M-(Trifluoromethyl)Phenethyl)Amino)Ethanol Benzoate (Ester)",
      "Benfluorex HCl",
      "2-{(1-METHYL-2-[3-(TRIFLUOROMETHYL)PHENYL]ETHYL}AMINO)ETHYL BENZOATE HYDROCHLORIDE",
    ],
  },
  {
    primary_id: "D07XCG",
    names: ["Apligraf"],
  },
  {
    primary_id: "D07XCQ",
    names: ["MDX-1414", "Anti-Glypican 3 Antibodies (Cancer), Medarex"],
  },
  {
    primary_id: "D07XDL",
    names: [
      "4-(1-Naphthalen-1-Yl-Vinyl)-1H-Imidazole",
      "CHEMBL105487",
      "SCHEMBL3751615",
      "RADFITYEEZIPNJ-UHFFFAOYSA-N",
      "ZINC13757201",
    ],
  },
  {
    primary_id: "D07XDQ",
    names: ["Figopitant", "BIIF-1149", "BIIF-1149CL", "NK1 Antagonists, Boehringer Ingelheim"],
  },
  {
    primary_id: "D07XDS",
    names: ["Tomopenem"],
  },
  {
    primary_id: "D07XEA",
    names: ["8-Cycloheptyloxy-Quinolin-2-Ylamine", "CHEMBL184394", "SCHEMBL5884483"],
  },
  {
    primary_id: "D07XEB",
    names: ["Nonsteroidal Anti-Inflammatory Drugs (NSAIDs)"],
  },
  {
    primary_id: "D07XEV",
    names: ["WPP-201"],
  },
  {
    primary_id: "D07XFF",
    names: ["[4Aph(CO-NH-OH)5]degarelix", "CHEMBL429527"],
  },
  {
    primary_id: "D07XFL",
    names: ["FR-226928"],
  },
  {
    primary_id: "D07XFT",
    names: ["Ribavirin-TP"],
  },
  {
    primary_id: "D07XGH",
    names: ["Pristinamycin"],
  },
  {
    primary_id: "D07XGR",
    names: [
      "Fenofibrate",
      "Ankebin",
      "Antara",
      "Controlip",
      "Durafenat",
      "Elasterate",
      "Elasterin",
      "FNF",
      "Fenobeta",
      "Fenobrate",
      "Fenofanton",
      "Fenofibrato",
      "Fenofibratum",
      "Fenogal",
      "Fenoglide",
      "Fenomax",
      "Fenotard",
      "Finofibrate",
      "Fulcro",
      "Lipanthyl",
      "Lipantil",
      "Liparison",
      "Lipidex",
      "Lipidil",
      "Lipifen",
      "Lipirex",
      "Lipoclar",
      "Lipofen",
      "Lipofene",
      "Liposit",
      "Lipsin",
      "Lofibra",
      "Luxacor",
      "Nolipax",
      "Pharmavit",
      "Phenofibrate",
      "Procetofen",
      "Procetofene",
      "Proctofene",
      "Protolipan",
      "Secalip",
      "Sedufen",
      "Supralip",
      "Tricor",
      "Triglide",
      "AbZ Brand Of Procetofen",
      "Abbott Brand Of Procetofen",
      "Aliud Brand Of Procetofen",
      "Antara Micronized Procetofen",
      "Anto Brand Of Procetofen",
      "Apo Feno Micro",
      "Apo Fenofibrate",
      "Apotex Brand Of Procetofen",
      "Azupharma Brand Of Procetofen",
      "Betapharm Brand Of Procetofen",
      "Bouchara Brand Of Procetofen",
      "Ct Arzneimittel Brand Of Procetofen",
      "Fenofibrat AL",
      "Fenofibrat AZU",
      "Fenofibrat AbZ",
      "Fenofibrat FPh",
      "Fenofibrat Heumann",
      "Fenofibrat Hexal",
      "Fenofibrat Stada",
      "Fenofibrat Ratiopharm",
      "Fenofibrat Von Ct",
      "Fenofibrate Debat",
      "Fenofibrate MSD",
      "Fournier Brand Of Procetofen",
      "GNR Pharma Brand Of Procetofen",
      "Gate Brand Of Procetofen",
      "Gen Fenofibrate",
      "Genpharm Brand Of Procetofen",
      "Heumann Brand Of Procetofen",
      "Hexal Brand Of Procetofen",
      "Knoll Brand Of Procetofen",
      "Lichtenstein Brand Of Procetofen",
      "Lipidil Micro",
      "Lipidil Supra",
      "Lipidil Ter",
      "MTW Brand Of Procetofen",
      "MTW Fenofibrat",
      "Merck Dura Brand Of Procetofen",
      "Novartis Brand Of Procetofen",
      "Novo Fenofibrate",
      "Novopharm Brand Of Procetofen",
      "Nu Fenofibrate",
      "Nu Pharm Brand Of Procetofen",
      "PMS Fenofibrate Micro;Pharmascience Brand Of Procetofen",
      "Procetofen Reliant Brand",
      "Q Pharm Brand Of Procetofen",
      "Ratiopharm Brand Of Procetofen",
      "Reliant Brand Of Procetofen",
      "Schering Plough Brand Of Procetofen",
      "Stadapharm Brand Of Procetofen",
      "United Drug Brand Of Procetofen",
      "F 6020",
      "LF 178",
      "LF178",
      "AZU, Fenofibrat",
      "Antara (TN)",
      "Antara (Micronized)",
      "Apo-Fenofibrate",
      "CIP-Fenofibrate",
      "Ct-Arzneimittel Brand Of Procetofen",
      "Debat, Fenofibrate",
      "FENOFIBRATE (MICRONIZED)",
      "Fenofibrat-Ratiopharm",
      "Fenofibrate IDD-P",
      "Fenofibrate [INN:BAN]",
      "Fenofibrato [INN-Spanish]",
      "Fenofibratum [INN-Latin]",
      "Fenogal (TN)",
      "GNR-Pharma Brand Of Procetofen",
      "GRS-027",
      "Gen-Fenofibrate",
      "Heumann, Fenofibrat",
      "Hexal, Fenofibrat",
      "LCP-Feno",
      "LCP-FenoChol",
      "LF-178",
      "Lipanthyl (TN)",
      "Lipantil (TN)",
      "Lipidil (TN)",
      "Lipidil-Ter",
      "Lipofen (TN)",
      "Lofibra (TN)",
      "MTW-Fenofibrat",
      "Micronized Procetofen, Antara",
      "Novo-Fenofibrate",
      "Nu-Fenofibrate",
      "Nu-Pharm Brand Of Procetofen",
      "PMS-Fenofibrate Micro",
      "Procetofen, Antara Micronized",
      "Q-Pharm Brand Of Procetofen",
      "Schering-Plough Brand Of Procetofen",
      "Stada, Fenofibrat",
      "TRICOR (MICRONIZED)",
      "Tricor (TN)",
      "Triglide (TN)",
      "Trilipix (TN)",
      "Apo-Feno-Micro",
      "Fenocor-67 (TN)",
      "Fenofibrate (JAN/INN)",
      "Isopropyl 2-[4-(4-Chlorobenzoyl)Phenoxy]-2-Methylpropanoate",
      "Isopropyl 2-(4-(4-Chlorobenzoyl)Phenoxy)-2-Methylpropionate",
      "Isopropyl 2-(P-(P-Chlorobenzoyl)Phenoxy)-2-Methylpropionate",
    ],
  },
  {
    primary_id: "D07XGT",
    names: ["AM-577"],
  },
  {
    primary_id: "D07XHJ",
    names: [
      "TAN-950A",
      "CHEMBL317234",
      "2-Amino-3-(3-Oxo-1,2-Oxazol-4-Yl)Propanoic Acid",
      "3-(3-Hydroxy-1,2-Oxazol-4-Yl)Alanine",
      "AC1Q6BSY",
      "AC1L2ZPH",
      "AC1Q50A9",
      "SCHEMBL5678957",
      "BDBM50107954",
      "AKOS030549352",
      "3-Hydroxy-Alpha-Aminoisoxazole-4-Propionic Acid",
      "2-Amino-3-(3-Hydroxy-Isoxazol-4-Yl)-Propionic Acid",
      "4-Isoxazolepropanoic Acid, Alpha-Amino-2,3-Dihydro-3-Oxo-",
    ],
  },
  {
    primary_id: "D07XHO",
    names: [
      "Nafamostat",
      "81525-10-2",
      "FUT-175",
      "Nafamostat [INN]",
      "Nafamostatum [Latin]",
      "UNII-Y25LQ0H97D",
      "Nafamstat",
      "Nafamstat Mesilate",
      "6-Amidino2-Naphthyl 4-Guanidinobenzoate",
      "Y25LQ0H97D",
      "CHEMBL273264",
      "C19H17N5O2",
      "Nafamostat (INN)",
      "P-Guanidinobenzoic Acid Ester With 6-Hydroxy-2-Naphthamidine",
      "6-(Aminoiminomethyl)-2-Naphthalenyl 4-((Aminoiminomethyl)Amino)Benzoate",
      "Nafamostatum",
      "6-[amino(Imino)Methyl]-2-Naphthyl 4-{[amino(Imino)Methyl]amino}benzoate Dimethanesulfonate",
      "Benzoic Acid, 4-((Aminoiminomethyl)Amino)-,",
      "CytoFab",
    ],
  },
  {
    primary_id: "D07XJB",
    names: ["PHENSTATIN"],
  },
  {
    primary_id: "D07XJC",
    names: ["7-Ethyl-6H-Chromeno[4,3-B]quinoline-3,9-Diol"],
  },
  {
    primary_id: "D07XJJ",
    names: ["M118", "Adomiparin"],
  },
  {
    primary_id: "D07XJM",
    names: [
      "Dicyclomine",
      "Atumin",
      "Bentomine",
      "Bentylol",
      "Dicicloverina",
      "Dicycloverin",
      "Dicycloverine",
      "Dicycloverinum",
      "Dicymine",
      "Diocyl",
      "Dyspas",
      "Formulex",
      "Mamiesan",
      "Merbentyl",
      "Procyclomin",
      "Sawamin",
      "Spasmoban",
      "Wyovin",
      "Bentyl Hydrochloride",
      "Bentylol Hydrochloride",
      "Dicyclomine Hcl",
      "Dicycloverin Hydrochloride",
      "Diethylaminocarbethoxybicyclohexyl Hydrochloride",
      "Diocyl Hydrochloride",
      "Kolantyl Hydrochloride",
      "Wyovin Hydrochloride",
      "GU8471000",
      "Bentyl (TN)",
      "Bentylol (TN)",
      "Byclomine (TN)",
      "Di-Syntramine",
      "Dibent (TN)",
      "Dicicloverina [INN-Spanish]",
      "Dicycloverine (INN)",
      "Dicycloverine [INN:BAN]",
      "Dicycloverinum [INN-Latin]",
      "Dicymine (TN)",
      "Dilomine (TN)",
      "Formulex (TN)",
      "Lomine (TN)",
      "Merbentyl (TN)",
      "Oxityl-P",
      "Beta-Diethylaminoethyl 1-Cyclohexylcyclohexanecarboxylate Hydrochloride",
      "Di-Spaz (TN)",
      "J.L. 998",
      "Beta-Diethylaminoethyl-1-Cyclohexylhexahydrobenzoate Hydrochloride",
      "Bicyclohexyl-1-Carbonsaeure-2'diethylaminoethylester",
      "[Bicyclohexyl]-1-Carboxylic Acid, 2-(Diethylamino)Ethyl Ester Hydrochloride",
      "Cyclohexanecarboxylic Acid, 1-Cyclohexyl-, 2-(Diethylamino)Ethyl Ester",
      "[1,1'-Bicyclohexyl]-1-Carboxylic Acid, 2-(Diethylamino)Ethyl Ester",
      "(1,1'-Bicyclohexyl)-1-Carboxylic Acid, 2-(Diethylamino)Ethyl Ester",
      "(BICYCLOHEXYL)-1-CARBOXYLIC ACID, 2-(DIETHYLAMINO)ETHYL ESTER, HYDROCHLORIDE",
      "(Bicyclohexyl)-1-Carboxylic Acid, 2-(Diethylamino)Ethyl Ester",
      "1-Cyclohexylcyclohexanecarboxylic Acid 2-(Diethylamino)Ethyl Ester",
      "2-(Diethylamino)Ethyl 1,1'-Bi(Cyclohexyl)-1-Carboxylate",
      "2-(Diethylamino)Ethyl 1-Cyclohexylcyclohexane-1-Carboxylate",
      "2-Diethylaminoethyl 1-Cyclohexylcyclohexane-1-Carboxylate",
      "2-Diethylaminoethyl 1-Cyclohexylcyclohexane-1-Carboxylate Hydrochloride",
    ],
  },
  {
    primary_id: "D07XKC",
    names: ["ISIS 7851"],
  },
  {
    primary_id: "D07XKF",
    names: ["[Mpa1, L-Tic7]OT", "CHEMBL415418"],
  },
  {
    primary_id: "D07XKG",
    names: ["2-(4-Phenylbutyl)Pyrido[2,3-D]pyrimidin-4(3H)-One"],
  },
  {
    primary_id: "D07XLA",
    names: ["GM04-001"],
  },
  {
    primary_id: "D07XLJ",
    names: [
      "N*6*-Benzyl-Quinazoline-2,4,6-Triamine",
      "CHEMBL115474",
      "AC1LA5GO",
      "ZINC5974540",
      "BDBM50066486",
      "N6-Benzylquinazoline-2,4,6-Triamine",
      "6-N-Benzylquinazoline-2,4,6-Triamine",
      "2,4-Diamino-6-(Benzylamino)Quinazoline",
      "6-(Benzylamino)-2,4-Quinazolinediamine",
    ],
  },
  {
    primary_id: "D07XLQ",
    names: ["N-{4-[2-(4-Methoxyphenyl)Ethyl]phenyl}phthalimide", "CHEMBL575856", "BDBM50298668"],
  },
  {
    primary_id: "D07XLU",
    names: ["Functional Peptides"],
  },
  {
    primary_id: "D07XMG",
    names: ["IFN-Alpha 2B-XL"],
  },
  {
    primary_id: "D07XMM",
    names: ["N-(4-Ethylphenyl)-5-Methylbenzo[d]oxazol-2-Amine", "CHEMBL1269648"],
  },
  {
    primary_id: "D07XMT",
    names: ["SiRNA Therapy, Breast Cancer"],
  },
  {
    primary_id: "D07XMV",
    names: ["YM-202074"],
  },
  {
    primary_id: "D07XNG",
    names: ["ISIS 110067"],
  },
  {
    primary_id: "D07XNJ",
    names: ["(R)-5-Phosphomevalonate"],
  },
  {
    primary_id: "D07XNO",
    names: ["ISIS 9060"],
  },
  {
    primary_id: "D07XOV",
    names: ["EFT508"],
  },
  {
    primary_id: "D07XPE",
    names: [
      "Phenformin",
      "Azucaps",
      "Cronoformin",
      "DBI",
      "Debeone",
      "Debinyl",
      "Diabis",
      "Dibein",
      "Dibiraf",
      "Dibotin",
      "Dipar",
      "Feguanide",
      "Fenfoduron",
      "Fenformin",
      "Fenformina",
      "Fenormin",
      "Glukopostin",
      "Glyphen",
      "Insoral",
      "Lentobetic",
      "Meltrol",
      "Normoglucina",
      "Pedg",
      "Phenethyldiguanide",
      "Phenformine",
      "Phenforminum",
      "Phenformix",
      "Phenylethylbiguanide",
      "Retardo",
      "D Bretard",
      "DB Comb",
      "DBI Monohydrochloride",
      "Phenformine HCl",
      "Phenoformine Hydrochloride",
      "W 32",
      "Beta-PEBG",
      "Beta-Phenethybiguanide",
      "Beta-Phenethylbiguanide",
      "Db-Retard",
      "Debeone (TN)",
      "Fenformina [INN-Spanish]",
      "Meltrol-50",
      "N-Phenethylbiguanide Hydrochloride",
      "Phenformin (BAN)",
      "Phenformin [INN:BAN]",
      "Phenformine [INN-French]",
      "Phenforminum [INN-Latin]",
      "N'-Beta-Fenetilformamidiniliminourea",
      "N'-Beta-Fenetilformamidiniliminourea [Italian]",
      "N'-Beta-Phenethylformamidinylliminourea",
      "N-(2-Phenylethyl)Imidodicarbonimidic Diamide",
      "PHENFORMIN (SEE ALSO PHENFORMIN HYDROCHLORIDE 834-28-6)",
      "1-(Diaminomethylidene)-2-Phenethylguanidine",
      "1-Phenethylbiguanide",
    ],
  },
  {
    primary_id: "D07XPI",
    names: ["PMID26099857-Compound-WO2011037610Formula(I)"],
  },
  {
    primary_id: "D07XPT",
    names: ["Ronacaleret"],
  },
  {
    primary_id: "D07XQR",
    names: [
      "N1,N4-Bis(5-Chloro-2-Methylphenyl)Succinamide",
      "CHEMBL566931",
      "N,N'-Bis(5-Chloro-2-Methylphenyl)Butanediamide",
      "AC1LK29V",
      "Cambridge Id 7313591",
      "Oprea1_225764",
      "MLS000535625",
      "Cid_1010578",
      "MolPort-001-547-717",
      "ZINC670958",
      "HMS2360E14",
      "STK435362",
      "BDBM50303376",
      "AKOS003303190",
      "MCULE-2700246651",
      "SMR000143061",
      "ST50923735",
      "N,N'-Bis(5-Chloro-2-Methylphenyl)Succinamide",
    ],
  },
  {
    primary_id: "D07XQS",
    names: ["ONC201"],
  },
  {
    primary_id: "D07XRC",
    names: ["ISIS 113176"],
  },
  {
    primary_id: "D07XSD",
    names: [
      "SUN-13333",
      "Cytosolic Phospholipase A2 Alpha Inhibitors (Inflammation)",
      "Cytosolic Phospholipase A2 Alpha Inhibitors (Inflammation), Asubio",
    ],
  },
  {
    primary_id: "D07XSN",
    names: [
      "Cytarabine",
      "Alexan",
      "AraC",
      "Arabinocytidine",
      "Arabinofuranosylcytosine",
      "Arabinosylcytosine",
      "Arabitin",
      "Aracytidine",
      "Aracytin",
      "Aracytine",
      "Arafcyt",
      "Citarabina",
      "Cytarabin",
      "Cytarabina",
      "Cytarabinoside",
      "Cytarabinum",
      "Cytarbel",
      "Cytonal",
      "Cytosar",
      "Cytosinearabinoside",
      "DepoCyte",
      "Depocyt",
      "Erpalfa",
      "Iretin",
      "Spongocytidine",
      "Tarabine",
      "Udicil",
      "Arabinosyl Cytosine",
      "Cytarabine Liposome Injection",
      "Cytosine Arabinofuranoside",
      "Cytosine Arabinose",
      "Cytosine Arabinoside",
      "AR3",
      "BTB15125",
      "CHX 3311",
      "U 19920A",
      "Ara-C",
      "Ara-Cytidine",
      "Beta-Ara C",
      "Beta-Arabinosylcytosine",
      "Beta-Cytosine Arabinoside",
      "Citarabina [INN-Spanish]",
      "Cytarabinum [INN-Latin]",
      "Cytosar-U",
      "Cytosine Arabinoside (VAN)",
      "Depocyt (TN)",
      "Depocyt (Liposomal)",
      "Intrathecal (Injected Into The Spinal Fluid) DepoCyt",
      "U-19920",
      "Beta-D-Arabinosylcytosine",
      "Cytosar-U (TN)",
      "Cytosine Beta-D-Arabinofuranoside",
      "Cytosine Beta-D-Arabinofuranoside Hydrochloride",
      "Cytosine Beta-D-Arabinoside",
      "Cytosine-Beta-Arabinoside",
      "Intrathecal Cytarabine (Also Known As Ara-C)",
      "U-19,920",
      "CYTARABINE (SEE ALSO CYTARABINE HYDROCHLORIDE 69-74-9)",
      "Cytarabine (JP15/USP/INN)",
      "Cytarabine [USAN:INN:BAN:JAN]",
      "Cytosine 1-Beta-D-Arabinofuranoside",
      "Cytosine, Beta-D-Arabinoside",
      "Cytosine-Beta-D-Arabinofuranoside",
      "Cytosine-1-Beta-D-Arabinofuranoside",
      "Ara-C, Cytosine Arabinoside, Cytosar-U, Cytarabine",
      "(Beta-D-Arabinofuranosyl)Cytosine",
      "1-.beta.-D-Arabinofuranosyl-Cytosine",
      "1-Arabinofuranosylcytosine",
      "1-Beta-D-Arabinofaranosylcytosine",
      "1-Beta-D-Arabinofuranosyl-4-Amino-2(1H)Pyrimidinone",
      "1-Beta-D-Arabinofuranosylcytosine",
      "1-Beta-D-Arabinofuranosylcytosine, Cytosine Arabinoside",
      "1-Beta-D-Arabinosylcytosine",
      "1beta-Arabinofuranasylcytosine",
      "1beta-D-Arabinofuranosylcytosine",
      "1beta-D-Arabinosylcytosine",
      "2(1H)-Pyrimidinone, 4-Amino-1-D-Arabinofuranosyl-[CAS]",
      "4-Amino-1-Arabinofuranosyl-2-Oxo-1,2-Dihydropyrimidin",
      "4-Amino-1-Arabinofuranosyl-2-Oxo-1,2-Dihydropyrimidin [Czech]",
      "4-Amino-1-Arabinofuranosyl-2-Oxo-1,2-Dihydropyrimidine",
      "4-Amino-1-B-D-Arabinofuranosyl-2-(1H)-Pyrimidinone",
      "4-Amino-1-Beta-D-Arabinofuranosyl-2(1H)-Pyrimidinon",
      "4-Amino-1-Beta-D-Arabinofuranosyl-2(1H)-Pyrimidinon [Czech]",
      "4-Amino-1-Beta-D-Arabinofuranosyl-2(1H)-Pyrimidinone",
      "4-Amino-1-[(2R,3S,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]pyrimidin-2-One",
      "4-Amino-1-Beta-D-Arabinofuranosylpyrimidin-2(1H)-One",
    ],
  },
  {
    primary_id: "D07XTU",
    names: [
      "Racemic DOI",
      "1-(4-Iodo-2,5-Dimethoxyphenyl)Propan-2-Amine",
      "4-DOI",
      "64584-34-5",
      "2,5-Dimethoxy-4-Iodoamphetamine",
      "DOI-P",
      "2,5-Dimethoxy-4-Iodophenylisopropylamine",
      "1-(2,5-Dimethoxy-4-Iodophenyl)-2-Aminopropane",
      "1-(4-Iodo-2,5-Dimethoxyphenyl)-2-Aminopropane",
      "4-Iodo-2,5-Dimethoxyamphetamine",
      "C11H16INO2",
      "2-(4-Iodo-2,5-Dimethoxyphenyl)-1-Methylethylamine",
      "CHEMBL6616",
      "CHEBI:64629",
      "4-Iodo-2,5-Dimethoxy-Alpha-Methylbenzeneethanamine",
      "Benzeneethanamine, 4-Iodo-2,5-Dimethoxy-Alpha-Methyl-",
      "82830-53-3",
    ],
  },
  {
    primary_id: "D07XUM",
    names: ["FG-5893"],
  },
  {
    primary_id: "D07XUY",
    names: [
      "8-Methylnaringenin",
      "CHEMBL426154",
      "MolPort-039-339-073",
      "ZINC14814929",
      "5,7,4'-Trihydroxy-8-Methylflavanone",
    ],
  },
  {
    primary_id: "D07XVL",
    names: ["PMID25666693-Compound-119"],
  },
  {
    primary_id: "D07XVN",
    names: [
      "Dexrazoxane",
      "24584-09-6",
      "Zinecard",
      "(S)-4,4'-(Propane-1,2-Diyl)Bis(Piperazine-2,6-Dione)",
      "Cardioxane",
      "ICRF-187",
      "Dexrazoxano",
      "Dexrazoxanum",
      "Dextrorazoxane",
      "Dexrazoxanum [INN-Latin]",
      "Dexrazoxano [INN-Spanish]",
      "Desrazoxane",
      "Eucardion",
      "ADR 529",
      "ICRF 187",
      "(+)-(S)-4,4'-Propylenedi-2,6-Piperazinedione",
      "Dexrazone",
      "ADR-529",
      "(+)-1,2-Bis(3,5-Dioxo-1-Piperazinyl)Propane",
      "HSDB 7319",
      "UNII-048L81261F",
      "NSC169780",
      "Dyzoxane",
      "BRN 5759131",
      "CHEBI:50223",
      "4-[(2S)-2-(3,5-Dioxopiperazin-1-Yl)Propyl]piperazine-2,6-Dione",
      "NSC 169780",
      "AK-72797",
      "Razoxanum",
      "Savene",
      "TopoTect",
      "Totect",
      "Dexrazoxane HCl",
      "Dexrazoxane Hydrochloride",
      "ICRF 187 Hydrochloride",
      "Cardioxane (TN)",
      "Dexrazoxane (TN)",
      "Totect (TN)",
      "Zinecard (TN)",
      "Dexrazoxane (USAN/INN)",
      "Dexrazoxane [USAN:BAN:INN]",
      "Soluble ICRF (L-Isosomer)",
      "Razoxane, (S)-Isomer",
      "Totect, ICRF-187, Zinecard, Cardioxane, Dexrazoxane Hydrochloride;(+)-(S)-4,4'-Propylenedi-2,6-Piperazinedione",
      "(+)-1,2-Bis(3,5-Dioxopiperazin-1-Yl)Propane",
      "(S)-(+)-1,2-Bis(3,5-Dioxopiperazin-1-Yl)Propane",
      "2,6-Piperazinedione, 4,4'-(1-Methyl-1,2-Ethanediyl)Bis-, (+)-(9CI)",
      "4,4'-(2S)-Propane-1,2-Diyldipiperazine-2,6-Dione",
      "4-[(2S)-2-(3,5-Dioxopiperazin-1-Yl)Propyl]piperazine-2,6-Dione Hydrochloride",
      "Icrf-187",
    ],
  },
  {
    primary_id: "D07XVQ",
    names: ["Aryl Alkanolamine Derivative 2"],
  },
  {
    primary_id: "D07XVR",
    names: ["Sodium Nitroprusside"],
  },
  {
    primary_id: "D07XWB",
    names: ["4-(Thiazol-2-Yl)Phenyl Butylcarbamate", "CHEMBL376748"],
  },
  {
    primary_id: "D07XWC",
    names: [
      "PMID19831390C14",
      "1116571-01-7",
      "GTPL8151",
      "ZINC45254830",
      "BDBM50299583",
      "AKOS027339246",
      "KB-66522",
      "AS-40572",
    ],
  },
  {
    primary_id: "D07XXK",
    names: [
      "6-(Biphenyl-2-Sulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL72752",
      "SCHEMBL6207186",
      "BDBM16448",
      "6-Phenylsulfonylpyridazin-2H-3-One, 10",
      "Phenyl-Substituted Sulfonylpyridazinone, 8j",
    ],
  },
  {
    primary_id: "D07XXT",
    names: ["DEDN-6526A"],
  },
  {
    primary_id: "D07XXX",
    names: ["Tyr-Pro-Phe-Hfe-NH2", "CHEMBL211147"],
  },
  {
    primary_id: "D07XYI",
    names: ["1,2,4-Triazolo[1,5a]pyridine Derivative 1"],
  },
  {
    primary_id: "D07XYQ",
    names: ["R-N-PROPYLNORAPOMORPHINE"],
  },
  {
    primary_id: "D07XYT",
    names: ["Medusa IL-2"],
  },
  {
    primary_id: "D07XYV",
    names: [
      "Bambuterol",
      "Bambec",
      "Bambuterolum",
      "Oxeol",
      "Bambuterolum [Latin]",
      "Terbutaline Bisdimethylcarbamate",
      "Bambec (TN)",
      "Bambuterol (INN)",
      "Bambuterol [BAN:INN]",
      "KWD-2183",
      "Oxeol (TN)",
      "Terbutaline Bis(Dimethylcarbamate)",
      "[3-[2-(Tert-Butylamino)-1-Hydroxyethyl]-5-(Dimethylcarbamoyloxy)Phenyl] N,N-Dimethylcarbamate",
      "(+-)-5-(2-(Tert-Butylamino)-1-Hydroxyethyl)-M-Phenylene Bis(Dimethylcarbamate)",
      "5-(2-(Tert-Butylamino)-1-Hydroxyethyl)-3-Phenylene Bis(Dimethylcarbamate)",
      "5-[2-(Tert-Butylamino)-1-Hydroxyethyl]benzene-1,3-Diyl Bis(Dimethylcarbamate)",
    ],
  },
  {
    primary_id: "D07XZP",
    names: ["N-(1,4-Diphenyl-1H-Pyrazol-5-Yl)Benzamide", "CHEMBL206574"],
  },
  {
    primary_id: "D07XZR",
    names: ["PMID25666693-Compound-134"],
  },
  {
    primary_id: "D07XZT",
    names: [
      "Cytidine-5'-Diphosphate",
      "CDP Trianion",
      "Cytidine 5'-Diphosphate(2-)",
      "Cytidine 5'-Pyrophosphate(2-)",
      "CHEBI:58069",
      "CDP(3-)",
      "5'-O-[(Phosphonatooxy)Phosphinato]cytidine",
      "A834354",
      "[[(2R,3S,4R,5R)-5-(4-Azanyl-2-Oxidanylidene-Pyrimidin-1-Yl)-3,4-Bis(Oxidanyl)Oxolan-2-Yl]methoxy-Oxidanidyl-Phosphoryl] Phosphate",
      "[[(2R,3S,4R,5R)-5-(4-Amino-2-Oxo-1-Pyrimidinyl)-3,4-Dihydroxy-2-Oxolanyl]methoxy-Oxidophosphoryl] Phosphate",
    ],
  },
  {
    primary_id: "D07YAC",
    names: ["Anticalin", "Anticalin (Alzheimer's Disease)"],
  },
  {
    primary_id: "D07YAR",
    names: ["NX-1207"],
  },
  {
    primary_id: "D07YCR",
    names: ["S-16020-2"],
  },
  {
    primary_id: "D07YDE",
    names: ["Nitroglycerin"],
  },
  {
    primary_id: "D07YDN",
    names: ["ISORHAMNETIN"],
  },
  {
    primary_id: "D07YDO",
    names: ["GTx-186"],
  },
  {
    primary_id: "D07YEK",
    names: ["NV-52"],
  },
  {
    primary_id: "D07YES",
    names: ["MPL-Containing Pollinex Allergy Desensitization Sublingual Vaccine"],
  },
  {
    primary_id: "D07YFL",
    names: ["EFPPEA"],
  },
  {
    primary_id: "D07YFM",
    names: ["Oligonucleotides"],
  },
  {
    primary_id: "D07YFY",
    names: [
      "Ac-Phe-Phe-NH2",
      "CHEMBL464178",
      "Ac-(Phe)2-CONH2",
      "BDBM50308387",
      "N-Acetyl-L-Phenylalanyl-L-Phenylalanine Amide",
    ],
  },
  {
    primary_id: "D07YGH",
    names: [
      "GSK239512",
      "720691-69-0",
      "GSK-239512",
      "1-(6-((3-CYCLOBUTYL-2,3,4,5-TETRAHYDRO-1H-BENZO[D]AZEPIN-7-YL)OXY)PYRIDIN-3-YL)PYRROLIDIN-2-ONE",
      "UNII-4I7U5C459M",
      "CHEMBL3092650",
      "4I7U5C459M",
      "YFRBKEVUUCQYOW-UHFFFAOYSA-N",
      "SCHEMBL167578",
      "MolPort-035-776-189",
      "ZINC3961802",
      "BDBM50444496",
      "AKOS025291102",
      "SB16754",
      "KS-0000063Q",
      "AS-42474",
      "AK171368",
      "2-Pyrrolidinone, 1-(6-((3-Cyclobutyl-2,3,4,5-Tetrahydro-1H-3-Benzazepin-7-Yl)Oxy)-3-Pyridinyl)-",
      "J3.497.402K",
    ],
  },
  {
    primary_id: "D07YGK",
    names: ["Vilaprisan"],
  },
  {
    primary_id: "D07YGQ",
    names: ["Anti-MUC1 AR20.5"],
  },
  {
    primary_id: "D07YGR",
    names: [
      "(+/-)-Threo-N-(4-Methylpyridine)Methylphenidate",
      "CHEMBL1254930",
      "BDBM50327128",
      "(AlphaR)-Alpha-[(2R)-1-(4-Pyridinylmethyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D07YHM",
    names: ["LY-2456302"],
  },
  {
    primary_id: "D07YHT",
    names: ["ENRASENTAN"],
  },
  {
    primary_id: "D07YHY",
    names: [
      "CR-2093",
      "CR 2093",
      "136374-73-7",
      "AC1L303W",
      "4-(3-Chlorobenzamido)-5-(3,3-Dimethylbutylamino)-5-Oxopentanoic Acid",
      "(R)-4-(3-Chlorobenzoylamino)-5-Oxo-5-(3,3-Dimethylbutylamino)Valeric Acid",
      "(4R)-4-[(3-Chlorobenzoyl)Amino]-5-(3,3-Dimethylbutylamino)-5-Oxopentanoic Acid",
      "Pentanoic Acid, 4-((3-Chlorobenzoyl)Amino)-5-((3,3-Dimethylbutyl)Amino)-5-Oxo-, (R)-",
    ],
  },
  {
    primary_id: "D07YIN",
    names: ["Drug Combination", "Drug Combination (Cardiovascular Disease)"],
  },
  {
    primary_id: "D07YIX",
    names: ["NmVac4"],
  },
  {
    primary_id: "D07YJG",
    names: [
      "1,11-Bis(Pyridinium)-Undecane Dibromide",
      "CHEMBL430886",
      "SCHEMBL6354341",
      "RCBQYFFDMCXGIU-UHFFFAOYSA-L",
      "N,N'-Undecane-1,11-Diyl-Bis-Pyridinium Dibromide",
      "1,11-Di(1-Pyridiniumyl)Undecane With Dibromide Ions",
    ],
  },
  {
    primary_id: "D07YJK",
    names: [
      "ShRNA Apolipoprotein B100 Gene Siliencer",
      "AAV-ShApoB",
      "ShRNA Apolipoprotein B100 Gene Siliencer (Hypercholesterolemia/Cardiovascular Disease)",
      "ShRNA Apolipoprotein B100 Gene Siliencer (Hypercholesterolemia/Cardiovascular Disease), AMT",
      "ApoB100 ShRNA AAV-Based Gene Therapy (Hypercholesterolemia/Cardiovascular Disease), Amsterdam Molecular Therapeutics",
    ],
  },
  {
    primary_id: "D07YKJ",
    names: ["EVT-103"],
  },
  {
    primary_id: "D07YKQ",
    names: ["EPX-100"],
  },
  {
    primary_id: "D07YKU",
    names: ["2-Ethyl-4-(Thiophen-2-Yl)Thieno[3,2-D]pyrimidine", "CHEMBL402817", "SCHEMBL3677642"],
  },
  {
    primary_id: "D07YLK",
    names: ["LE-300"],
  },
  {
    primary_id: "D07YLO",
    names: ["SDF-1"],
  },
  {
    primary_id: "D07YMP",
    names: ["TAB001"],
  },
  {
    primary_id: "D07YMX",
    names: ["RPC-1063"],
  },
  {
    primary_id: "D07YNC",
    names: ["NCI-4650"],
  },
  {
    primary_id: "D07YNF",
    names: [
      "D-Phenylalanyl-N-(3-Fluorobenzyl)-L-Prolinamide",
      "DB07027",
      "37U",
      "(2S)-1-[(2R)-2-Amino-3-Phenylpropanoyl]-N-[(3-Fluorophenyl)Methyl]pyrrolidine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D07YNH",
    names: ["SAN-61"],
  },
  {
    primary_id: "D07YNM",
    names: ["NN8765"],
  },
  {
    primary_id: "D07YNX",
    names: ["Syk Kinase Inhibitors"],
  },
  {
    primary_id: "D07YOP",
    names: ["JBD-401"],
  },
  {
    primary_id: "D07YOX",
    names: [
      "DPP-IV Inhibitors, Phenomix",
      "DPP IV Inhibitors (Diabetes Mellitus), Phenomix",
      "Dipeptidyl Peptidase IV Inhibitors (Diabetes Mellitus), Phenomix",
    ],
  },
  {
    primary_id: "D07YPA",
    names: ["CGEN-920"],
  },
  {
    primary_id: "D07YPB",
    names: ["Hu-CART Meso Cells"],
  },
  {
    primary_id: "D07YPE",
    names: ["Benzimidazole Derivative 13"],
  },
  {
    primary_id: "D07YPP",
    names: [
      "BreMel/TNF Alpha",
      "BreMel/TNF Alpha (Melanoma/Lobular Breast Cancer)",
      "Aka ScFvMel/TNF (Melanoma/Lobular Breast Cancer), Targa Therapeutics",
      "BreMel/TNF Alpha (Melanoma/ Breast Cancer), Targa Therapeutics",
      "BreMel/TNF Alpha (Melanoma/Lobular Breast Cancer), Targa Therapeutics",
      "ScFvMel/TNF Alpha (Melanoma/ Breast Cancer), Targa Therapeutics",
      "BreMel/TNF-A (Melanoma/Lobular Breast Cancer), Targa Therapeutics",
      "ScFvMel/TNF-A (Melanoma/Lobular Breast Cancer), Targa Therapeutics",
      "ZME-018 Antibody Fragment/TNF Alpha (Melanoma/ Breast Cancer), Targa Therapeutics",
    ],
  },
  {
    primary_id: "D07YPS",
    names: [
      "(2-Hydroxyphenyl)(4-Hydroxyphenyl)Methanone",
      "2,4'-Dihydroxybenzophenone",
      "606-12-2",
      "Benzophenone, 2,4'-Dihydroxy-",
      "Methanone, (2-Hydroxyphenyl)(4-Hydroxyphenyl)-",
      "CHEMBL510074",
      "HUYKZYIAFUBPAQ-UHFFFAOYSA-N",
      "(2-Hydroxyphenyl)-(4-Hydroxyphenyl)Methanone",
      "Methanone,(2-Hydroxyphenyl)(4-Hydroxyphenyl)-",
      "NSC2832",
      "ACMC-209mky",
      "AC1Q5FLY",
      "AC1L58KX",
      "Benzophenone,4'-Dihydroxy-",
      "Oprea1_236403",
      "4,2'-Dihydroxy-Benzophenone",
      "SCHEMBL1568564",
      "CTK5B1906",
      "DTXSID80277556",
      "MolPort-003-809-367",
      "ZINC340411",
      "KS-000014KT",
      "NSC-2832",
      "BDBM50262042",
      "ANW-33536",
    ],
  },
  {
    primary_id: "D07YPV",
    names: ["AbelaDrug200"],
  },
  {
    primary_id: "D07YPW",
    names: [
      "Shigamabs",
      "C-Stx1",
      "C-Stx2",
      "Ca-Stx1",
      "Ca-Stx2",
      "Anti-Shiga Toxin Monoclonal Antibodies, Thallion",
      "Anti-Shiga Toxin Monoclonals, Sunol;Monoclonal Antibodies (HUS), Sunol",
      "Monoclonal Antibodies (HUS), Thallion",
    ],
  },
  {
    primary_id: "D07YQG",
    names: ["NADA"],
  },
  {
    primary_id: "D07YQY",
    names: ["N-Benzylnorlitebamine"],
  },
  {
    primary_id: "D07YRJ",
    names: [
      "AERAS-405",
      "AERAS-Capsid",
      "AERAS-X05",
      "Tuberculosis Vaccine (Oral, Shigella RNA Capsid Delivery System), Aeras",
    ],
  },
  {
    primary_id: "D07YRV",
    names: ["AER-002", "Aerolytic", "Bikunin", "Pulmolytic", "Bikunin, Aerovance", "Bikunin, Bayer"],
  },
  {
    primary_id: "D07YRW",
    names: ["ASP7991"],
  },
  {
    primary_id: "D07YSJ",
    names: [
      "(3-Amino-1-Ethyl-Propyl)-Methyl-Phosphinic Acid",
      "CHEMBL113092",
      "133345-70-7",
      "Phosphinic Acid, (3-Amino-1-Ethylpropyl)Methyl-",
      "ACMC-20muwd",
      "SCHEMBL341182",
      "CTK0C0402",
      "DTXSID30435583",
      "CCGFQGUALPHBIC-UHFFFAOYSA-N",
      "BDBM50032974",
      "P-(5-Aminopent-3-Yl)-P-Methylphosphinic Acid",
      "P-(5-Aminopent-3-Yl)-P-Methyl-Phosphinic Acid",
      "Phosphinic Acid, P-(3-Amino-1-Ethylpropyl)-P-Methyl-",
    ],
  },
  {
    primary_id: "D07YSQ",
    names: [
      "N-(4-Sulfamoylphenylethyl)-4-Sulfamoylbenzamide",
      "CHEMBL51668",
      "Benzamide, 4-(Aminosulfonyl)-N-[2-[4-(Aminosulfonyl)Phenyl]ethyl]-",
      "361438-75-7",
      "4-Sulfamoyl-N-[2-(4-Sulfamoylphenyl)Ethyl]benzamide",
      "CTK1B0192",
      "BDBM11635",
      "DTXSID30439107",
      "AKOS001421782",
    ],
  },
  {
    primary_id: "D07YTL",
    names: ["BMS753", "BMS-753", "BMS 753"],
  },
  {
    primary_id: "D07YUH",
    names: ["HybriCell", "Dendritic Cell-Tumor Cell Hybrid Vaccine (Melanoma, RCC), Genoa"],
  },
  {
    primary_id: "D07YUV",
    names: ["Phosphoric Acid Mono-((E)-Tetradec-9-Enyl) Ester", "CHEMBL190430", "SCHEMBL193819", "ZINC13648500"],
  },
  {
    primary_id: "D07YUY",
    names: ["MDL-29311"],
  },
  {
    primary_id: "D07YVK",
    names: ["Sumanirole"],
  },
  {
    primary_id: "D07YVM",
    names: ["CLX-090700", "CLX-0100", "CLX-0101", "Plant-Derived Anti-Diabetic, Calyx"],
  },
  {
    primary_id: "D07YWE",
    names: ["PMID29053063-Compound-5"],
  },
  {
    primary_id: "D07YWM",
    names: ["Pbi-ShRNA STMN1 LP"],
  },
  {
    primary_id: "D07YWQ",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 1"],
  },
  {
    primary_id: "D07YWY",
    names: ["Nafronyl"],
  },
  {
    primary_id: "D07YXD",
    names: ["HL-024"],
  },
  {
    primary_id: "D07YXL",
    names: [
      "Difluoromethionine",
      "DIFLUOROMETHIONINE",
      "S-(Difluoromethyl)Homocysteine",
      "2FM",
      "Difluoro-L-Methionine",
      "AC1L9LA0",
      "S-Difluoromethyl-L-Homocysteine",
      "SCHEMBL2977180",
      "DB03816",
      "(2S)-2-Amino-4-(Difluoromethylsulfanyl)Butanoic Acid",
    ],
  },
  {
    primary_id: "D07YXP",
    names: ["9-[5-(Beta-Carboline-9-Yl)Pentyl]-Beta-Carboline", "CHEMBL1098470", "SCHEMBL17502362"],
  },
  {
    primary_id: "D07YZD",
    names: [
      "2-(3-Nitrophenyl)-4,5-Dihydro-1H-Imidazole",
      "31659-42-4",
      "1h-Imidazole, 4,5-Dihydro-2-(3-Nitrophenyl)-",
      "CHEMBL457101",
      "4,5-Dihydro-2-(3-Nitrophenyl)-1H-Imidazole",
      "EINECS 250-754-1",
      "AC1LEYVJ",
      "AC1Q1ZUC",
      "2-(M-Nitrophenyl)Imidazoline",
      "IMI021",
      "SCHEMBL12643294",
      "CTK4G7541",
      "DTXSID30185551",
      "MolPort-006-126-327",
      "ZINC121568",
      "2-(3-Nitrophenyl)-2-Imidazoline",
      "STK367845",
      "BDBM50266032",
      "BBL001160",
      "AKOS005444754",
      "MCULE-9494328291",
      "T7684",
    ],
  },
  {
    primary_id: "D07YZK",
    names: ["CAMI-103"],
  },
  {
    primary_id: "D07YZQ",
    names: ["Melanoma DNA Vaccine"],
  },
  {
    primary_id: "D07YZZ",
    names: ["Octanedioic Acid Bis-Hydroxyamide"],
  },
  {
    primary_id: "D07ZAH",
    names: ["ACH-1625"],
  },
  {
    primary_id: "D07ZAJ",
    names: ["PIP2"],
  },
  {
    primary_id: "D07ZAK",
    names: ["RGX 501"],
  },
  {
    primary_id: "D07ZAO",
    names: ["OVI-123"],
  },
  {
    primary_id: "D07ZAV",
    names: [
      "2-(Butylthiomethyl)-5-Hydroxy-4H-Pyran-4-One",
      "CHEMBL1269398",
      "204503-10-6",
      "CTK0J0428",
      "DTXSID10680655",
      "BDBM50330110",
      "AKOS030554091",
      "4H-Pyran-4-One, 2-[(Butylthio)Methyl]-5-Hydroxy-",
    ],
  },
  {
    primary_id: "D07ZBE",
    names: ["Iobitridol"],
  },
  {
    primary_id: "D07ZBH",
    names: [
      "4-Hydroxybenzylamine",
      "4-(Aminomethyl)Phenol",
      "696-60-6",
      "4-Aminomethyl-Phenol",
      "Para-Hydroxybenzylamine",
      "UNII-7J7F85B7BI",
      "4-Aminomethylphenol",
      "CHEMBL202519",
      "7J7F85B7BI",
      "(4-HYDROXYPHENYL)METHANAMINIUM BROMIDE",
      "4-Hydroxybenzylamine Hydrate, 97%",
      "A-Amino-P-Cresol",
      "P-Hydroxybenzylamine",
      "4-Hyroxybenzylamine",
      "4-Hydroxylbenzylamine",
      "NSC 125720",
      "4-Hydroxybenzyl Amine",
      "4-Hydroxy Benzylamine",
      "4-(Amino Methyl)Phenol",
      "4-(Aminomethyl)-Phenol",
      "ACMC-1AXW9",
      "Phenol, 4-(Aminomethyl)-",
      "AC1Q7A8B",
      "AC1L3YL9",
      "SCHEMBL63123",
      "(4-Hydroxyphenyl)Methylamine",
    ],
  },
  {
    primary_id: "D07ZBO",
    names: [
      "ADepVac",
      "DNA Vaccine (Alzheimer's Disease), University Of California Irvine",
      "DNA Vaccine (TriGrid Electroporation, Alzheimers), Ichor/UCI/IMM",
    ],
  },
  {
    primary_id: "D07ZCD",
    names: [
      "4-[6-Methyl-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 22b",
      "AC1O706N",
      "CHEMBL192077",
      "BDBM8630",
      "4-[(Z)-(6-Methyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D07ZCK",
    names: ["ZK-93423"],
  },
  {
    primary_id: "D07ZCP",
    names: ["Carbamide Derivative 4"],
  },
  {
    primary_id: "D07ZCU",
    names: [
      "BKT-120",
      "BKT-130",
      "Chemokine Antagonists (Inflammation, Cancer)",
      "Chemokine Antagonists (Inflammation, Cancer), Biokine",
    ],
  },
  {
    primary_id: "D07ZDG",
    names: ["EDN-OL1", "Amyloid Beta Oligonucleotide (Alzheimer's Disease/Down Syndrome), Edunn"],
  },
  {
    primary_id: "D07ZDJ",
    names: ["Aromatic Ring Compound 4"],
  },
  {
    primary_id: "D07ZEK",
    names: ["Intravenous Immunostimulant"],
  },
  {
    primary_id: "D07ZES",
    names: ["T2CA"],
  },
  {
    primary_id: "D07ZEZ",
    names: ["Topotecan Liposomes Injection"],
  },
  {
    primary_id: "D07ZFH",
    names: ["FGGFTRKRKSARKLANQ"],
  },
  {
    primary_id: "D07ZFN",
    names: [
      "5-Bromo-6-P-Tolylamino-1H-Pyrimidine-2,4-Dione",
      "CHEMBL353575",
      "5-Bromo-6-(P-Toluidino)Uracil",
      "100763-66-4",
    ],
  },
  {
    primary_id: "D07ZGF",
    names: ["Biothrax", "Biothrax (TN)"],
  },
  {
    primary_id: "D07ZGV",
    names: ["ASN04450772", "ASN 04450772", "ASN-04450772"],
  },
  {
    primary_id: "D07ZHE",
    names: [
      "3-Nitro-Benzenesulfonamide",
      "3-Nitrobenzenesulfonamide",
      "121-52-8",
      "Benzenesulfonamide, 3-Nitro-",
      "M-Nitrobenzenesulfonamide",
      "3-Nitrobenzolesulfamide",
      "M-Nitrobenzenesulphonamide",
      "3-Nitrobenzenesulphonamide",
      "Benzenesulfonamide, M-Nitro-",
      "3-Nitrobenzene-1-Sulfonamide",
      "CHEMBL367035",
      "TXTQURPQLVHJRE-UHFFFAOYSA-N",
      "EINECS 204-477-8",
      "NSC 407487",
      "M-Nitrobenzolsulfonamid",
      "3-Nitrophenylsulfonamide",
      "AI3-50018",
      "AC1Q1GYY",
      "3-Nitro-Benzenesulfonamid",
      "M-Nitrobenzene Sulfonamide",
      "3-Nitrophenyl Sulphonamide",
      "3-Nitrobenzene Sulfonamide",
      "AC1L26XH",
    ],
  },
  {
    primary_id: "D07ZHM",
    names: [
      "ATX-S10",
      "ATX-S10 Na",
      "ATX-S10 Na(II)",
      "Photodynamic Therapy (Cancer, AMD)",
      "Photodynamic Therapy, Allergan/Hamamatsu",
      "Photodynamic Therapy, Allergan/Photochemical",
      "Photodynamic Therapy (Cancer, AMD), Photochemical",
    ],
  },
  {
    primary_id: "D07ZHV",
    names: ["CB1954"],
  },
  {
    primary_id: "D07ZIG",
    names: [
      "H-Tyr-Tic-OH",
      "CHEMBL485576",
      "ZINC13765438",
      "BDBM50060080",
      "2-({2-[(R)-2-Amino-3-(4-Hydroxy-2,6-Dimethyl-Phenyl)-Propionyl]-1,2,3,4-Tetrahydro-Isoquinoline-3-Carbonyl}-Amino)-Propionic Acid",
    ],
  },
  {
    primary_id: "D07ZJC",
    names: ["INOC-005", "Capridine Beta (Prostate Cancer), Prostagenics"],
  },
  {
    primary_id: "D07ZJM",
    names: ["2-Oxoolean-12-En-28-Oic Acid", "CHEMBL1081876", "2-Oxooleana-12-Ene-28-Oic Acid"],
  },
  {
    primary_id: "D07ZJS",
    names: ["(R/S)-2-((4-Benzylphenoxy)Methyl)Piperidine", "CHEMBL1098351", "SCHEMBL7332735", "BDBM50317491"],
  },
  {
    primary_id: "D07ZKE",
    names: ["PMID25684022-Compound-US20120277229 45(1.5)"],
  },
  {
    primary_id: "D07ZKK",
    names: ["131I-81C6"],
  },
  {
    primary_id: "D07ZMG",
    names: ["Thiazole Carboxamide Derivative 29"],
  },
  {
    primary_id: "D07ZMQ",
    names: ["HD-003"],
  },
  {
    primary_id: "D07ZMW",
    names: ["4-Pyridin-2-Yl-5-Quinolin-2-Yl-Thiazol-2-Ylamine", "CHEMBL186966"],
  },
  {
    primary_id: "D07ZNF",
    names: ["IMC-EB10", "Anti-Flt-3 Mab"],
  },
  {
    primary_id: "D07ZNI",
    names: [
      "STX-140",
      "(9beta,13alpha,14beta,17alpha)-2-Methoxyestra-1,3,5(10)-Triene-3,17-Diyl Disulfamate",
      "STX140",
      "CHEMBL218382",
      "2-MeOE2bisMATE",
      "2-Methoxyestradiol-3,17-O,O-Bis-Sulfamate",
      "STX 140",
      "2-MeOEbisMATE",
      "2gd8",
      "2-Methoxyestradiol 3,17-O,O-Bis-Sulfamate",
      "2-MbM",
      "2-Methoxyestradiol-3,17-O,O-Bis(Sulfamate)",
      "SCHEMBL446846",
      "BDBM50200936",
      "DB08416",
      "(9beta,17beta)-2-Methoxyestra-1,3,5(10)-Triene-3,17-Diyl Disulfamate",
      "Estra-1,3,5(10)-Triene-3,17-Diol, 2-Methoxy-, Disulfamate, (17beta)-",
      "401600-86-0",
      "Angiomates",
      "Estrone Sulphatase Inhibitors, Ipsen",
      "STX-213",
      "STX-641",
      "Steroid Sulfatase Inhibitors, Sterix Ltd",
      "2-MeOE2-Bis MATE",
      "2-MeSEMATE",
      "2-Ethyl-Estradiol Bis-Sulfamate",
      "2-Methoxyestradiol, Sterix",
      "2-Methoxyestradiol-Bis-Sulfamate",
      "2-Methoxyestradiol-Bis-Sulfamate, Sterix",
    ],
  },
  {
    primary_id: "D07ZNO",
    names: ["Squalene", "All-Trans-Squalene", "Spinacene", "Trans-Squalene"],
  },
  {
    primary_id: "D07ZNP",
    names: ["GB110"],
  },
  {
    primary_id: "D07ZNR",
    names: ["2,3,5,6-Tetrafluoro-4-Pentafluorophenylazo-Phenol"],
  },
  {
    primary_id: "D07ZOP",
    names: ["1-(3-Methylphenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One", "CHEMBL204254", "SCHEMBL4939486"],
  },
  {
    primary_id: "D07ZQB",
    names: [
      "MPC-MECA",
      "CHEMBL2113610",
      "GTPL460",
      "BNXGNSTUSRFBCI-KSVNGYGVSA-N",
      "BDBM50453540",
      "Adenosine, N(6)-4-Methoxybenzyl-4'-N-Ethylcarbamoyl-4'-Dehydroxymethyl-",
      "5'-Ethylamino-N-(4-Methoxybenzyl)-5'-Oxo-5'-Deoxyadenosine",
    ],
  },
  {
    primary_id: "D07ZQS",
    names: ["Macrocycle Derivative 9"],
  },
  {
    primary_id: "D07ZQU",
    names: ["N-(3-(4-Hydroxy-3-Methoxyphenyl)Propyl)Acetamide", "CHEMBL1092047"],
  },
  {
    primary_id: "D07ZRG",
    names: ["PMID21741839C5"],
  },
  {
    primary_id: "D07ZRI",
    names: ["Technetium Tc-99m Oxidronate Kit"],
  },
  {
    primary_id: "D07ZRU",
    names: ["2,7-Bis(Chloroacetamido)Anthraquinone", "SCHEMBL4294197", "CHEMBL492429"],
  },
  {
    primary_id: "D07ZSU",
    names: ["Freeze-Dried Bee Venom"],
  },
  {
    primary_id: "D07ZTB",
    names: [
      "HG-1068",
      "COCA2 Modulator, Human Genome Sciences",
      "DNA Mismatch Repair Protein Mlh1 Modulator, Human Genome Sciences",
      "FCC2 Modulator, Human Genome Sciences",
      "HMLH1 Modulator, Human Genome Sciences",
      "HNPCC Modulator, Human Genome Sciences",
      "HNPCC2 Modulator, Human Genome Sciences",
      "Homolog Of E Coli MutL1 Modulator, Human Genome Sciences",
      "MGC5172 Modulator, Human Genome Sciences",
      "MLH1 Modulator, Human Genome Sciences",
      "MutL Protein Homolog 1 Modulator, Human Genome Sciences",
    ],
  },
  {
    primary_id: "D07ZTD",
    names: ["MB-07811", "CHEMBL471897", "SCHEMBL3147495", "BDBM50242404"],
  },
  {
    primary_id: "D07ZTG",
    names: ["L-739750"],
  },
  {
    primary_id: "D07ZTO",
    names: [
      "Bethanechol",
      "Amidopropyldimethylbetaine",
      "Bethanecol",
      "Urabeth",
      "Myotonine Chloride",
      "Duvoid (TN)",
      "Myotonachol (TN)",
      "Urecholine (TN)",
      "Carbamoyl-Beta-Methylcholine",
      "Carbamyl-Beta-Methylcholine",
      "Ammonium, (2-Hydroxypropyl)Trimethyl-, Carbamate (Ester)",
      "(2-Hydroxypropyl)Trimethylammonium Carbamate",
      "1-Propanaminium, 2-((Aminocarbonyl)Oxy)-N,N,N-Trimethyl-(9CI)",
      "2-((Aminocarbonyl)Oxy)-N,N,N-Trimethyl-1-Propanaminium",
      "2-(Carbamoyloxy)-N,N,N-Trimethylpropan-1-Aminium",
      "2-Carbamoyloxypropyl(Trimethyl)Azanium",
      "2-Carbamoyloxypropyl-Trimethylazanium",
    ],
  },
  {
    primary_id: "D07ZTT",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 5"],
  },
  {
    primary_id: "D07ZUI",
    names: [
      "Ac-His-Trp-Ala-Val-Gly-His-Leu-Met-NH2",
      "CHEMBL274874",
      "Acetyl-GRP(20-27)",
      "BDBM50012306",
      "ZINC169366208",
    ],
  },
  {
    primary_id: "D07ZUO",
    names: ["PMID25726713-Compound-28"],
  },
  {
    primary_id: "D07ZUW",
    names: ["Nanoemulsion"],
  },
  {
    primary_id: "D07ZUX",
    names: ["CR-3465"],
  },
  {
    primary_id: "D07ZUZ",
    names: ["CK-2127107"],
  },
  {
    primary_id: "D07ZVO",
    names: ["PT1.2"],
  },
  {
    primary_id: "D07ZVP",
    names: ["1-(2,5-Dimethyl-1H-Pyrrol-1-Yl)Thiourea", "CHEMBL466982", "70436-68-9", "BDBM50268203", "ZINC40413177"],
  },
  {
    primary_id: "D07ZWN",
    names: ["5,7-Dimethyl-3-Thiophen-3-Yl-Quinoline", "CHEMBL304333", "SCHEMBL8501282", "ZINC3832218"],
  },
  {
    primary_id: "D07ZWS",
    names: ["ISIS 25126"],
  },
  {
    primary_id: "D07ZXE",
    names: ["Wetimicin", "Aminoglycoside Antibiotic (Infection), Jiangsu Institute Of Microbiology"],
  },
  {
    primary_id: "D07ZXR",
    names: ["Sulfamic Acid 12-Sulfamoyloxy-Dodecyl Ester", "CHEMBL361421"],
  },
  {
    primary_id: "D07ZYE",
    names: ["PMID20690647C4b", "Cbz-Leu-D,L-Abu-CONH-(CH(2))(3)-2-Methoxyadenin-9-Yl", "GTPL8614", "BDBM50326300"],
  },
  {
    primary_id: "D07ZYL",
    names: [
      "4-[5-Methoxy-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "CHEMBL175609",
      "BDBM8623",
      "(4-Pyridylmethylene)Indane 12b",
      "AC1O7069",
      "4-[(Z)-(5-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D07ZZD",
    names: [
      "4-Benzoxazo-2-Yl-1,4-Diazabicyclo[3.2.2]nonane",
      "CHEMBL611082",
      "SCHEMBL373021",
      "CXJLWJAYGMWLRR-UHFFFAOYSA-N",
      "BDBM50309862",
    ],
  },
  {
    primary_id: "D07ZZG",
    names: ["Cicloprolol"],
  },
  {
    primary_id: "D07ZZI",
    names: ["SLV-341"],
  },
  {
    primary_id: "D07ZZS",
    names: ["AGS-22ME"],
  },
  {
    primary_id: "D07ZZX",
    names: ["MDX-1110"],
  },
  {
    primary_id: "D07ZZZ",
    names: ["FV-Tic-TDVGPFAF"],
  },
  {
    primary_id: "D08AAB",
    names: ["VLX600"],
  },
  {
    primary_id: "D08AAP",
    names: ["NSC-75308"],
  },
  {
    primary_id: "D08AAV",
    names: ["AZD-3514"],
  },
  {
    primary_id: "D08ABU",
    names: [
      "18alpha-Glycyrrhetic Acid",
      "18a-Glycyrrhetic Acid",
      "18a-Glycyrrhetinic Acid",
      "1449-05-4",
      "CHEMBL378653",
      "18ss -Glycyrrhetinic Acid",
      "SCHEMBL18539",
      "MolPort-027-835-537",
      "BDBM50185128",
      "AKOS016009638",
      "DS-5150",
      "3A-Hydroxy-11-Oxo-18ss20ss -Olean-12-En-29-Oic Acid",
    ],
  },
  {
    primary_id: "D08ABX",
    names: ["CAR-T Cells Targeting CD34"],
  },
  {
    primary_id: "D08ACB",
    names: ["Sabadinine"],
  },
  {
    primary_id: "D08ADI",
    names: ["3-(3-(3-Chlorobenzyloxy)-5-Methylphenyl)Pyridine", "CHEMBL208506"],
  },
  {
    primary_id: "D08ADK",
    names: ["Glycoferon"],
  },
  {
    primary_id: "D08AES",
    names: ["L-Mannitol Derivative", "CHEMBL410049", "BDBM50173733"],
  },
  {
    primary_id: "D08AFD",
    names: [
      "NP-252",
      "3-(Cyclopropylcarbonyl)-2,6-Dimethyl-4-(2-Nitrophenyl)-1,4-Dihydropyridine-5-Carboxylic Acid Octyl Ester",
    ],
  },
  {
    primary_id: "D08AFZ",
    names: ["(4-Benzylpiperidin-1-Yl)(P-Tolyl)Methanone"],
  },
  {
    primary_id: "D08AGA",
    names: ["3-((S)-Azetidin-2-Yloxy)-5-Iodo-Pyridine", "CHEMBL55138"],
  },
  {
    primary_id: "D08AGH",
    names: ["[18F]altanserin", "[18F]altanserin (PET Ligand)"],
  },
  {
    primary_id: "D08AHA",
    names: [
      "PARA-(BENZOYL)-PHENYLALANINE",
      "104504-45-2",
      "L-4-Benzoylphenylalanine",
      "(S)-2-Amino-3-(4-Benzoylphenyl)Propanoic Acid",
      "H-Bpa-OH H-Phe(4-Bz)-OH",
      "Para-(Benzoyl)-Phenylalanine",
      "4-Benzoyl-L-Phenylalanine",
      "4-Benzoylphenylalanine",
      "H-Bpa-OH",
      "H-P-Bz-Phe-OH",
      "L-P-Benzoylphenylalanine",
      "P-Benzoyl-L-Phenylalanine",
      "UNII-9RU6KQ9WYD",
      "9RU6KQ9WYD",
      "Phenylalanine, 4-Benzoyl-",
      "AK117086",
      "(2S)-2-Amino-3-(4-Benzoylphenyl)Propanoic Acid",
      "H-L-Bpa-OH",
      "Para-Benzoyl-L-Phenylalanine",
      "SCHEMBL44097",
      "L-Phenylalanine, 4-Benzoyl-",
      "L - 4 - Benzoylphenylalanine",
      "BIBA1027",
      "CTK0G6271",
      "KS-00000GGS",
    ],
  },
  {
    primary_id: "D08AHE",
    names: ["ISIS 9070"],
  },
  {
    primary_id: "D08AHH",
    names: ["LHW090"],
  },
  {
    primary_id: "D08AID",
    names: ["6-Ethyl-3-(2-Methylbutoxycarbonyl)-4-Quinolone", "CHEMBL377719"],
  },
  {
    primary_id: "D08AIJ",
    names: [
      "ERIODICTYOL",
      "(+/-)-Eriodictyol",
      "4049-38-1",
      "MLS000877024",
      "5,7,3',4'-Tetrahydroxyflavanone",
      "2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxychroman-4-One",
      "2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-Chroman-4-One",
      "SMR000440624",
      "4H-1-Benzopyran-4-One, 2-(3,4-Dihydroxyphenyl)-2,3-Dihydro-5,7-Dihydroxy-",
      "2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-2,3-Dihydrochromen-4-One",
      "Eriodictyol, (+/-)-",
      "Eriodicytol",
      "ERIODYCTOL",
      "Flavanone, 3',4',5,7-Tetrahydroxy-",
      "AC1L1WLT",
    ],
  },
  {
    primary_id: "D08AJF",
    names: ["PI-0824"],
  },
  {
    primary_id: "D08AJS",
    names: ["3-(3-Chloro-1H-Pyrazol-5-Yl)Quinoxalin-2(1H)-One"],
  },
  {
    primary_id: "D08AKP",
    names: ["13-(N-Isopropylheptanamido)Tridec-8(Z)-Enoic Acid", "CHEMBL560026", "SCHEMBL15645552"],
  },
  {
    primary_id: "D08ALG",
    names: [
      "Azepan-(2Z)-Ylideneamine",
      "3,4,5,6-Tetrahydro-2H-Azepin-7-Amine",
      "CHEMBL315857",
      "2214-67-7",
      "Iminohomopiperidine",
      "2-Iminohexahydro-1H-Azepine",
      "SCHEMBL1575752",
      "MolPort-007-996-393",
      "GTLJSJNKRLFVSJ-UHFFFAOYSA-N",
      "STL163828",
      "ZINC13746433",
      "BBL012958",
      "BDBM50049257",
      "AKOS002684184",
      "AKOS006375172",
      "MCULE-7781415779",
    ],
  },
  {
    primary_id: "D08ALK",
    names: [
      "Cisatracurium",
      "Cisatracurium Besylate",
      "Cisatracurium Besylate Preservative Free",
      "Nimbex",
      "Nimbex Preservative Free",
    ],
  },
  {
    primary_id: "D08ALQ",
    names: ["RPR-101821"],
  },
  {
    primary_id: "D08ALW",
    names: ["MMI-175"],
  },
  {
    primary_id: "D08AMH",
    names: [
      "1,4-Diethylene Dioxide",
      "1,4-DIOXANE",
      "Dioxane",
      "123-91-1",
      "P-Dioxane",
      "Diethylene Ether",
      "Dioxan",
      "1,4-Dioxacyclohexane",
      "Dioxanne",
      "Tetrahydro-P-Dioxin",
      "Di(Ethylene Oxide)",
      "Tetrahydro-1,4-Dioxin",
      "Dioxane-1,4",
      "Diethylene Dioxide",
      "Glycol Ethylene Ether",
      "1,4-Dioxan",
      "P-Dioxan",
      "Dioxan-1,4",
      "Dioxyethylene Ether",
      "Dioksan",
      "Diokan",
      "Para-Dioxane",
      "Tetrahydro-Para-Dioxin",
      "Diossano-1,4",
      "Dioxaan-1,4",
      "P-Dioxin, Tetrahydro-",
      "Glycolethylenether",
      "Dioksan [Polish]",
      "Dioxanne [French]",
      "RCRA Waste Number U108",
      "P-Dioxan [Czech]",
      "1,4-Dioxanne",
      "NCI-C03689",
    ],
  },
  {
    primary_id: "D08AMJ",
    names: ["TK-DLI"],
  },
  {
    primary_id: "D08AMQ",
    names: [
      "SB-234551",
      "2-[2-[1-Butyl-4-[2-Carboxy-3-(6-Methoxy-1,3-Benzodioxol-5-Yl)-1(E)-Propenyl]pyrazol-5-Yl]-5-Methoxyphenoxymethyl]benzoic Acid",
    ],
  },
  {
    primary_id: "D08ANE",
    names: ["Opdivo Nivolumab +Yervoy Ipilimumab"],
  },
  {
    primary_id: "D08ANF",
    names: ["Cerebral EDV"],
  },
  {
    primary_id: "D08ANJ",
    names: ["AP-30663"],
  },
  {
    primary_id: "D08ANZ",
    names: ["Pembrolizumab"],
  },
  {
    primary_id: "D08AOA",
    names: [
      "2-Ethylamido-5-Sulfonamidoindane",
      "CHEMBL204740",
      "N-(5-Sulfamoyl-2,3-Dihydro-1H-Inden-2-Yl)Propanamide",
      "Indanesulfonamide Derivative 12d",
    ],
  },
  {
    primary_id: "D08AOD",
    names: [
      "6-(Dihydroxy-Isobutyl)-Thymine",
      "6-(DIHYDROXY-ISOBUTYL)-THYMINE",
      "DHBT",
      "6-(1,3-Dihydroxyisobutyl)Thymine",
      "6-[3-Hydroxy-2-(Hydroxymethyl)Propyl]-5-Methylpyrimidine-2,4(1H,3H)-Dione",
      "6-[3-Hydroxy-2-(Hydroxymethyl)Propyl]-5-Methyl-2,4(1h,3h)-Pyrimidinedione",
      "6-[3-Hydroxy-2-(Hydroxymethyl)Propyl]thymine",
      "CCV",
      "AC1L1CE0",
      "CTK8A1595",
      "CHEBI:41485",
      "DB02500",
      "6-[3-Hydroxy-2-(Hydroxymethyl)Propyl]-5-Methyl-1H-Pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D08AOG",
    names: [
      "2,2-Dimethoxy-1,2-Diphenyl-Ethanone",
      "24650-42-8",
      "2,2-Dimethoxy-2-Phenylacetophenone",
      "2,2-Dimethoxy-1,2-Diphenylethanone",
      "Benzil Dimethyl Ketal",
      "Kayacure BDMK",
      "Irgacure 651",
      "Ethanone, 2,2-Dimethoxy-1,2-Diphenyl-",
      "Lucirin BDK",
      "Photomer 51",
      "Esacure KB 1",
      "Irgacure 641",
      "Irgacure 621",
      "Irgacure I 651",
      "Irgacure E 651",
      "Benzil Dimethylketal",
      "Irgacure 951",
      "Benzil Mono(Dimethyl Ketal)",
      "2,2-Dimethoxy-1,2-Diphenylethan-1-One",
      "Benzil Mono(Dimethyl Acetal)",
      "Benzil Dimethyl Acetal",
      "Alpha,alpha-Dimethoxy-Alpha-Phenylacetophenone",
      "KB 1",
    ],
  },
  {
    primary_id: "D08AOV",
    names: ["INO-1002"],
  },
  {
    primary_id: "D08AOZ",
    names: ["BMS-986142"],
  },
  {
    primary_id: "D08APZ",
    names: ["NT-100"],
  },
  {
    primary_id: "D08ARY",
    names: ["NSC-368272"],
  },
  {
    primary_id: "D08ATQ",
    names: [
      "Nitracrine",
      "4533-39-5",
      "Nitracrine [INN]",
      "Nitracrinum [INN-Latin]",
      "Nitracrina [INN-Spanish]",
      "UNII-712MLZ30SB",
      "CCRIS 1973",
      "712MLZ30SB",
      "1-Nitro-9-(Dimethylaminopropylamino)Acridine",
      "C18H20N4O2",
      "1-Nitro-9-(3'-Dimethylaminopropylamino)-Acridine",
      "9-((3-(Dimethylamino)Propyl)Amino)-1-Nitroacridine",
      "Nitracrinum",
      "Nitracrina",
      "N',N'-Dimethyl-N-(1-Nitroacridin-9-Yl)Propane-1,3-Diamine",
      "ACRIDINE, 9-(3-(DIMETHYLAMINO)PROPYLAMINO)-1-NITRO-",
      "1,3-Propanediamine, N,N-Dimethyl-N'-",
    ],
  },
  {
    primary_id: "D08ATZ",
    names: [
      "QO-58",
      "CHEMBL1689654",
      "GTPL7669",
      "SCHEMBL10062354",
      "MolPort-042-624-524",
      "QO 58",
      "BDBM50339281",
      "ZINC101404854",
      "AKOS027470159",
      "1259536-62-3",
      "5-(2,6-Dichloro-5-Fluoro-3-Pyridinyl)-3-Phenyl-2-(Trifluoromethyl)-Pyrazolo[1,5-A]pyrimidin-7(4H)-One",
      "5-(2,6-Dichloro-5-Fluoropyridin-3-Yl)-3-Phenyl-2-(Trifluoromethyl)Pyrazolo[1,5-A]pyrimidin-7(4H)-One",
    ],
  },
  {
    primary_id: "D08AWI",
    names: ["PRX-00023"],
  },
  {
    primary_id: "D08AWL",
    names: ["5-Phenyl-6-Thia-10b-Aza-Benzo[e]azulen-4-One"],
  },
  {
    primary_id: "D08AWN",
    names: [
      "(S)-Norfluoxetine",
      "126924-38-7",
      "SEPROXETINE",
      "UNII-25CO3X0R31",
      "(S)-3-PHENYL-3-(4-TRIFLUOROMETHYL-PHENOXY)-PROPYLAMINE",
      "CHEMBL465123",
      "(3S)-3-Phenyl-3-[4-(Trifluoromethyl)Phenoxy]propan-1-Amine",
      "25CO3X0R31",
      "(S)-3-Phenyl-3-(4-(Trifluoromethyl)Phenoxy)Propan-1-Amine",
      "Seproxetine [INN]",
      "S-Norfluoxetine",
      "AC1MHW5Z",
      "SCHEMBL272873",
      "ZINC4531",
      "CTK8E9555",
      "MolPort-005-941-621",
      "9173AH",
      "BDBM50254790",
      "AKOS027384574",
      "DB06731",
      "AJ-08214",
      "RT-014029",
      "FT-0673089",
      "NFL_296.1257_14.5",
      "J-005451",
    ],
  },
  {
    primary_id: "D08AWZ",
    names: ["Ac-Cys-Ile-Phe(4-NO2)-Lys-Phe(4-NO2)-Tyr"],
  },
  {
    primary_id: "D08AXA",
    names: [
      "2-(Pyridin-2-Yl)-1H-Imidazo[4,5-C]pyridine",
      "CHEMBL202826",
      "14060-62-9",
      "2-(2-Pyridinyl)-1H-Imidazo[4,5-C]pyridine",
      "AC1LJB5G",
      "3H-Imidazo[4,5-C]pyridine, 2-(2-Pyridinyl)-",
      "Oprea1_672867",
      "SCHEMBL11397558",
      "2-Pyridylimidazo[4,5-C]pyridine",
      "MolPort-002-623-631",
      "ZINC5819379",
      "BDBM50180747",
      "STL346357",
      "AKOS022141602",
      "AKOS012572462",
      "MCULE-6118149650",
      "NCGC00375714-01",
      "NCGC00375714-02",
      "2-Pyridin-2-Yl-3H-Imidazo[4,5-C]pyridine",
    ],
  },
  {
    primary_id: "D08AXB",
    names: [
      "Ac-Glu-Cha-Cys",
      "CHEMBL303340",
      "AcGlu-Cha-Cys",
      "AC1NRQFL",
      "AcGlu-.beta.-Cyclohexylalanine-Cys",
      "BDBM50096406",
      "Ac-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "(4S)-4-Acetamido-5-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-5-Oxopentanoic Acid",
      "(4S)-4-Acetamido-5-[[(1S)-1-(Cyclohexylmethyl)-2-[[(1R)-2-Hydroxy-2-Oxo-1-(Sulfanylmethyl)Ethyl]amino]-2-Oxo-Ethyl]amino]-5-Oxo-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D08AXM",
    names: ["VALERGENT-BT (Subcutaneous)"],
  },
  {
    primary_id: "D08AXT",
    names: ["9-(4-Nitrobenzyl)-6-(Furan-2-Yl)-9H-Purin-2-Amine", "CHEMBL410445", "SCHEMBL3269368"],
  },
  {
    primary_id: "D08AYM",
    names: [
      "[3H]NFPS",
      "405225-21-0",
      "CHEMBL26512",
      "A1-01880",
      "N-[3-(4'-Fluorophenyl)-3-(4'-Phenylphenoxy)Propyl]sarcosine",
      "N-[3-([1,1-Biphenyl]-4-Yloxy)-3-(4-Fluorophenyl)Propyl]-N-Methylglycine",
      "2-((3-([1,1'-Biphenyl]-4-Yloxy)-3-(4-Fluorophenyl)Propyl)(Methyl)Amino)Acetic Acid",
      "[3H]-NFPS",
      "AC1NSKCW",
      "SCHEMBL427134",
      "GTPL4620",
      "GTPL4619",
      "CTK8E8383",
      "DTXSID30415522",
      "MolPort-023-276-580",
      "KS-000000NE",
      "BCP21210",
      "BCP17140",
      "( Inverted Exclamation",
    ],
  },
  {
    primary_id: "D08AZK",
    names: [
      "3-[5-Bromo-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 9a",
      "SCHEMBL4231367",
      "BDBM8590",
      "SCHEMBL4231363",
      "AC1O704L",
      "CHEMBL194440",
      "3-[(E)-(5-Bromo-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D08AZT",
    names: ["1-Adamantan-1-Yl-3-(2-Hydroxy-Phenyl)-Urea", "CHEMBL477670", "SCHEMBL6565549", "BDBM50267092"],
  },
  {
    primary_id: "D08BAW",
    names: ["Bu-RYYRIK-NH2", "CHEMBL428076"],
  },
  {
    primary_id: "D08BBC",
    names: ["GSK-114814"],
  },
  {
    primary_id: "D08BBL",
    names: ["Iomab-B"],
  },
  {
    primary_id: "D08BBU",
    names: ["GW-42003"],
  },
  {
    primary_id: "D08BCF",
    names: ["QUIPAZINE"],
  },
  {
    primary_id: "D08BCH",
    names: ["TCPA"],
  },
  {
    primary_id: "D08BCK",
    names: ["PMID26924192-Compound-24"],
  },
  {
    primary_id: "D08BCU",
    names: ["U-90042"],
  },
  {
    primary_id: "D08BDI",
    names: ["N-(5-O-Tolyl-Furan-2-Carbonyl)-Guanidine", "CHEMBL192634"],
  },
  {
    primary_id: "D08BDT",
    names: ["Halopredone Acetate"],
  },
  {
    primary_id: "D08BEA",
    names: ["Ipamorelin"],
  },
  {
    primary_id: "D08BED",
    names: [
      "CERE-140",
      "AAV-NT4",
      "AAV-GFAP-NT4",
      "NT4/5 AAV Gene Therapy (Ocular Disorder), Ceregene",
      "Neurotrophic Factor 4 Adeno-Associated Virus Gene Therapy (Ocular Disorder), Ceregene",
    ],
  },
  {
    primary_id: "D08BEO",
    names: [
      "Talampanel",
      "Ampanel",
      "Kinampa",
      "GYKI 53773",
      "LY 300164",
      "GYKI-53773",
      "IDR-53773",
      "LY-300164",
      "Talampanel (INN)",
      "(8R)-7-Acetyl-5-(4-Aminophenyl)-8,9-Dihydro-8-Methyl-7H-1,3-Dioxolo[4,5-H][2,3]benzodiazepine",
      "(R)-(-)-1-(4-Aminophenyl)-3-Acetyl-4-Methyl-7,8-Methylenedioxy-3,4-Dihydro-5H-2,3-Benzodiazepine",
      "(R)-7-Acetyl-5-(P-Aminophenyl)-8,9-Dihydro-8-Methyl-7H-1,3-Dioxolo(4,5-H)(2,3)Benzodiazepine",
      "(R)-7-Acetyl-5-(P-Aminophenyl)-8,9-Dihydro-8-Methyl-7H-1,3-Dioxolo[4,5-H][2,3]benzodiazepine",
    ],
  },
  {
    primary_id: "D08BFI",
    names: ["Z-LEHD-Fmk"],
  },
  {
    primary_id: "D08BFU",
    names: [
      "ASTRAGALIN",
      "Astragalin",
      "480-10-4",
      "Astragaline",
      "Kaempferol-3-Glucoside",
      "Kaempferol 3-O-Glucoside",
      "Kaempferol 3-Glucoside",
      "Kaempferol-3-O-Glucoside",
      "Asragalin",
      "Kaempferol-3-Beta-Monoglucoside",
      "Kaempferol-3-D-Glucoside",
      "Kaempferol-3-Beta-Glucopyranoside",
      "UNII-APM8UQ3Z9O",
      "3,4',5,7-Tetrahydroxyflavone-3-Glucoside",
      "Kaempferol 3-O-Beta-D-Glucopyranoside",
      "3-Glucosylkaempferol",
      "Kaempferol 3-O-Beta-D-Glucoside",
      "APM8UQ3Z9O",
      "4H-1-Benzopyran-4-One, 3-(Beta-D-Glucopyranosyloxy)-5,7-Dihydroxy-2-(4-Hydroxyphenyl)-",
      "CHEMBL233930",
      "CHEBI:30200",
    ],
  },
  {
    primary_id: "D08BGK",
    names: ["N-Chloromethyl-Brucine"],
  },
  {
    primary_id: "D08BGZ",
    names: ["JWH-315"],
  },
  {
    primary_id: "D08BIH",
    names: ["N-Acylpyrrolidine Ether Derivative 2"],
  },
  {
    primary_id: "D08BIJ",
    names: ["IL-1 Monoclonal Antibody"],
  },
  {
    primary_id: "D08BIN",
    names: ["Munumbicins"],
  },
  {
    primary_id: "D08BIP",
    names: [
      "Dihydro-Quinolinone",
      "553-03-7",
      "3,4-Dihydro-2(1H)-Quinolinone",
      "3,4-Dihydroquinolin-2(1H)-One",
      "Hydrocarbostyril",
      "1,2,3,4-Tetrahydroquinolin-2-One",
      "3,4-Dihydrocarbostyril",
      "3,4-Dihydro-1H-Quinolin-2-One",
      "2-Oxo-1,2,3,4-Tetrahydroquinoline",
      "3,4-Dihydro-2-Quinolinol",
      "O-Aminohydrocinnamic Acid Lactam",
      "UNII-2CKG6TX32F",
      "3,4-Dihydro-2(1H)Quinolinone",
      "2(1H)-Quinolinone, 3,4-Dihydro-",
      "NSC 49170",
      "2CKG6TX32F",
      "CHEMBL388582",
      "TZOYXRMEFDYWDQ-UHFFFAOYSA-N",
      "3,4-DIHYDRO-2-QUINOLONE",
      "MFCD00016722",
      "3,4-Dihydro-2(1H)-Quinolinone, 98%",
    ],
  },
  {
    primary_id: "D08BJR",
    names: ["Ki-20227"],
  },
  {
    primary_id: "D08BKE",
    names: ["Isosorbide-2-Benzylcarbamate-5-Triflate", "CHEMBL492636"],
  },
  {
    primary_id: "D08BKH",
    names: ["18-Methoxycoronaridine", "308123-60-6", "DTXSID40437331"],
  },
  {
    primary_id: "D08BKM",
    names: ["Porcine Isophane Insulin"],
  },
  {
    primary_id: "D08BLM",
    names: ["9-Benzyl-6-(4-Methylpiperazin-1-Yl)-9H-Purine", "CHEMBL594468"],
  },
  {
    primary_id: "D08BLV",
    names: ["Isopropyl 3-(Phenylthio)-1H-Indole-2-Carboxylate"],
  },
  {
    primary_id: "D08BLZ",
    names: ["Aryl Mannoside Derivative 23"],
  },
  {
    primary_id: "D08BMC",
    names: ["Olea Europaea-Containing Vaccine"],
  },
  {
    primary_id: "D08BMM",
    names: ["OROIDIN"],
  },
  {
    primary_id: "D08BMV",
    names: ["PEG-HGH"],
  },
  {
    primary_id: "D08BNB",
    names: ["Biaryl Mannoside Derivative 5"],
  },
  {
    primary_id: "D08BNJ",
    names: ["DTP-HepB-Polio-Hib"],
  },
  {
    primary_id: "D08BNM",
    names: ["NAA-004"],
  },
  {
    primary_id: "D08BNV",
    names: [
      "4,5-Dimethoxy-10H-Anthracen-9-One",
      "CHEMBL125822",
      "76403-03-7",
      "AC1NRZIP",
      "CTK2G0639",
      "DTXSID90416070",
      "ZINC2540909",
      "BDBM50060856",
      "4,5-Dimethoxyanthracen-9(10H)-One",
      "9(10H)-Anthracenone, 4,5-Dimethoxy-",
    ],
  },
  {
    primary_id: "D08BNY",
    names: ["(E)-N-(4-Methoxyphenyl)Undec-2-Enamide", "CHEMBL198132"],
  },
  {
    primary_id: "D08BOD",
    names: ["D-Phe-Cys-Tyr-D-Trp-Arg-Thr-Pen-Thr-NH2(CTAP)", "CHEMBL413950"],
  },
  {
    primary_id: "D08BOH",
    names: ["(-)-Thujaplicatintrimethyl Ether"],
  },
  {
    primary_id: "D08BOL",
    names: ["3-(Aminomethyl)-4-(Thiophen-2-Yl)Butanoic Acid", "CHEMBL208302", "SCHEMBL6377686", "BDBM50171789"],
  },
  {
    primary_id: "D08BOP",
    names: [
      "3-Propoxy-9H-Beta-Carboline",
      "3-Propoxy-9H-Pyrido[3,4-B]indole",
      "3-Propyloxy-Beta-Carboline",
      "CHEMBL52750",
      "CHEMBL499814",
      "AC1NFSYF",
      "3-Propoxy-Beta-Carboline",
      "SCHEMBL2347434",
      "LPAODQAWVMEXKR-UHFFFAOYSA-N",
      "ZINC6869122",
      "BDBM50001473",
      "PDSP1_001752",
      "BDBM50244037",
      "PDSP2_001735",
    ],
  },
  {
    primary_id: "D08BPD",
    names: ["EC-70124"],
  },
  {
    primary_id: "D08BPE",
    names: ["(-)-3-O-Acetylspectaline"],
  },
  {
    primary_id: "D08BPS",
    names: ["JCAR017"],
  },
  {
    primary_id: "D08BQI",
    names: ["(3,5-Dibromo-4-Pentyloxy-Phenyl)-Acetic Acid"],
  },
  {
    primary_id: "D08BQJ",
    names: ["Brain Dendritic Cell-Based Vaccine"],
  },
  {
    primary_id: "D08BRJ",
    names: ["Pro-His-Pro-His-Leu-Phe-Val-Tyr", "CHEMBL267446"],
  },
  {
    primary_id: "D08BRR",
    names: ["2-[3-(2-Methyl-Butyl)-Phenyl]-Propionic Acid", "CHEMBL190956"],
  },
  {
    primary_id: "D08BRZ",
    names: [
      "2-(4-Fluorophenyl)-6-Morpholino-4H-Pyran-4-One",
      "CHEMBL105521",
      "SCHEMBL3537099",
      "BDBM50132370",
      "2-Morpholino-6-(4-Fluorophenyl)-4H-Pyran-4-One",
    ],
  },
  {
    primary_id: "D08BSG",
    names: ["IODOPROXYFAN"],
  },
  {
    primary_id: "D08BSQ",
    names: [
      "RK-682",
      "CHEMBL426373",
      "150627-37-5",
      "(5R)-3-Hexadecanoyl-5-Hydroxymethyltetronic Acid",
      "(R)-3-Hexadecanoyl-5-Hydroxymethyltetronic Acid",
      "SCHEMBL4403663",
      "DTXSID50715652",
      "BDBM50093526",
      "ZINC100031544",
      "RK-682, &gt",
      "(5R)-3-Hexadecanoyl-4-Hydroxy-5-(Hydroxymethyl)Furan-2(5H)-One",
    ],
  },
  {
    primary_id: "D08BST",
    names: ["N-Isobutylnoroxymorphone"],
  },
  {
    primary_id: "D08BTB",
    names: ["Protirelin", "24305-27-9", "Hirtonin", "TRH-SR, Takeda"],
  },
  {
    primary_id: "D08BTV",
    names: ["2-(4-Cyclohexylthiosemicarbazono)Methyl-Phenol"],
  },
  {
    primary_id: "D08BTX",
    names: ["X-387"],
  },
  {
    primary_id: "D08BTY",
    names: ["AMG 701"],
  },
  {
    primary_id: "D08BUB",
    names: ["N2-{4-[(4-Nitrobenzyl)Oxy]benzyl}glycinamide", "CHEMBL242409"],
  },
  {
    primary_id: "D08BVM",
    names: ["TTP-355"],
  },
  {
    primary_id: "D08BVR",
    names: [
      "Cyclosporin-Based Therapies",
      "Cyclosporin-Based Therapies (G-Technology, Acute Neurodegenerative Disorders)",
    ],
  },
  {
    primary_id: "D08BVX",
    names: ["PMID27977313-Compound-27"],
  },
  {
    primary_id: "D08BXD",
    names: ["4-MPPTS"],
  },
  {
    primary_id: "D08BXK",
    names: [
      "3-(3-Methyl-Butoxy)-9H-Beta-Carboline",
      "CHEMBL87641",
      "3-(Isopentyloxy)-9H-Pyrido[3,4-B]indole",
      "SCHEMBL10644963",
      "BDBM50001483",
    ],
  },
  {
    primary_id: "D08BXO",
    names: ["MRS2950"],
  },
  {
    primary_id: "D08BXT",
    names: ["Spermidine"],
  },
  {
    primary_id: "D08BYJ",
    names: ["WIN-902A"],
  },
  {
    primary_id: "D08BYK",
    names: [
      "Hypericum",
      "6-Formamidopenicillanic Acid",
      "UNII-2TC14ZH3A5",
      "CHEBI:59004",
      "CTK2F8101",
      "6-Formamido-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
      "2TC14ZH3A5",
      "64527-04-4",
      "(2S,5R,6R)-6-Formamido-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-Formamido-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "PAB 141",
      "141a/2A",
      "HIS-1201",
      "Epitope ID:119700",
      "SCHEMBL11176911",
      "DTXSID90429524",
      "6beta-Formylaminopenicillanic Acid",
      "4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylicacid, 6-(Formylamino)-3,3-D",
    ],
  },
  {
    primary_id: "D08BZZ",
    names: ["Omburtamab I-131"],
  },
  {
    primary_id: "D08CAB",
    names: [
      "D-Aspartic Acid",
      "1783-96-6",
      "(R)-2-Aminosuccinic Acid",
      "D-Aspartate",
      "H-D-Asp-OH",
      "(-)-Aspartic Acid",
      "(R)-Aspartic Acid",
      "Aspartic Acid D-Form",
      "(2R)-2-Aminobutanedioic Acid",
      "Aspartic Acid, D-",
      "D-(-)-Aspartic Acid",
      "(R)-(-)-Aminosuccinic Acid",
      "D-Asp",
      "D-Aminosuccinic Acid",
      "NSC 97922",
      "D-Asparaginsaeure",
      "UNII-4SR0Q8YD1X",
      "BRN 1723529",
      "Tocris-0213",
      "EINECS 217-234-6",
      "4SR0Q8YD1X",
      "(R)-2-Aminobutanedioic Acid",
      "CHEMBL29757",
      "CHEBI:17364",
      "CKLJMWTZIZZHCS-UWTATZPHSA-N",
      "MFCD00063081",
      "NCGC00024498-02",
      "(R)-2-Aminobutanedioate",
      "D(-)-Aspartic Acid, 99+%",
      "[3H]D-Aspartic Acid",
    ],
  },
  {
    primary_id: "D08CBB",
    names: [
      "D-Tryptophan",
      "153-94-6",
      "H-D-Trp-OH",
      "D(+)-Tryptophan",
      "(R)-Tryptophan",
      "D-TRYPTOPHANE",
      "(2R)-2-Amino-3-(1H-Indol-3-Yl)Propanoic Acid",
      "D-Trytophane",
      "H-D-Typ-OH",
      "Tryptophan, D-",
      "D-Trp",
      "UNII-7NS97N9H1G",
      "D-(+)-Tryptophan",
      "(R)-2-Amino-3-(3-Indolyl)Propionic Acid",
      "EINECS 205-819-9",
      "NSC 97942",
      "(R)-(+)-2-Amino-3-(3-Indolyl)Propionic Acid",
      "7NS97N9H1G",
      "CHEMBL292303",
      "DTR",
      "CHEBI:16296",
      "QIVBCDIJIAJPQS-SECBINFHSA-N",
      "MFCD00005647",
      "D-Alpha-Amino-3-Indolepropionic Acid",
      "NCGC00093372-02",
      "DSSTox_RID_82038",
      "DSSTox_CID_26989",
      "D(+)-Tryptophan,",
    ],
  },
  {
    primary_id: "D08CCC",
    names: ["AFP-Scan"],
  },
  {
    primary_id: "D08CCE",
    names: ["Guaimesal", "Bronteril", "Brontermil", "MR-693", "ZAMI-635"],
  },
  {
    primary_id: "D08CCF",
    names: ["IPX-159"],
  },
  {
    primary_id: "D08CDE",
    names: [
      "Celgosivir",
      "BuCast",
      "MDL 28574",
      "B-CAST",
      "MBI-3253",
      "MDL 28,574",
      "MDL 28,574A",
      "MDL-28574",
      "MX-3253",
      "VIR-222",
      "Butanoic Acid, (1S,6S,7S,8R,8aR)-Octahydro-1,7,8-Trihydroxy-6-Indolizinyl Ester",
      "[(1S,6S,7S,8R,8aR)-1,7,8-Trihydroxy-1,2,3,5,6,7,8,8a-Octahydroindolizin-6-Yl] Butanoate",
      "(1S,6S,7S,8R,8aR)-Octahydro-1,7,8-Trihydroxy-6-Indolizinyl Butyrate;6-O-Butanoylcastanospermine",
    ],
  },
  {
    primary_id: "D08CDI",
    names: ["Lapatinib"],
  },
  {
    primary_id: "D08CEA",
    names: ["122-0551 Foam"],
  },
  {
    primary_id: "D08CEE",
    names: [
      "L-696418",
      "CHEMBL8494",
      "BDBM50057083",
      "(R)-2-[(S)-1-(3-Methyl-1-Phenylcarbamoyl-Butylcarbamoyl)-3-Phenyl-Propylamino]-Propionic Acid",
      "(R)-2-((S)-1-((S)-4-Methyl-1-Oxo-1-(Phenylamino)Pentan-2-Ylamino)-1-Oxo-4-Phenylbutan-2-Ylamino)Propanoic Acid",
      "(R)-2-[(S)-1-((S)-3-Methyl-1-Phenylcarbamoyl-Butylcarbamoyl)-3-Phenyl-Propylamino]-Propionic Acid",
    ],
  },
  {
    primary_id: "D08CEK",
    names: ["Imidazo Triazine Derivative 5"],
  },
  {
    primary_id: "D08CEU",
    names: ["Recombinant Factor X"],
  },
  {
    primary_id: "D08CEW",
    names: ["L902688", "8-Aza-1-Decarboxy-11-Deoxy-16,16-Difluoro-16-Phenyl-Omega-Tetranor-1-(5-Tetrazolo) PGE1"],
  },
  {
    primary_id: "D08CFL",
    names: ["Fumagillin"],
  },
  {
    primary_id: "D08CFM",
    names: ["C[RGDf-(R)-Alpha-TfmV]", "CHEMBL203077"],
  },
  {
    primary_id: "D08CGE",
    names: [
      "Integrin Alpha 5/Beta 1",
      "CD29 Antagonist (Metastasis/Neuronal Disease), InnoPharmaScreen",
      "Integrin Alpha 5/Beta 1 (Metastatsis/Neuronal Disorder)",
      "Integrin Alpha 5/Beta 1 (Metastatsis/Neuronal Disorder), InnoPharmaScreen",
    ],
  },
  {
    primary_id: "D08CGG",
    names: ["VRC-EBOADV018-00-VP"],
  },
  {
    primary_id: "D08CGI",
    names: ["Epoxysuccinate Derivative 7"],
  },
  {
    primary_id: "D08CGU",
    names: ["Bevacizumab + Rituximab"],
  },
  {
    primary_id: "D08CHK",
    names: ["WIN-64745", "150881-28-0", "Q-20547C"],
  },
  {
    primary_id: "D08CID",
    names: ["RTI-118"],
  },
  {
    primary_id: "D08CIN",
    names: ["Semaphorin-3E Anti-Platelet Agent"],
  },
  {
    primary_id: "D08CIY",
    names: [
      "2-(2-Furyl)-6-(1H-Pyrazol-1-Yl)Pyrimidin-4-Amine",
      "CHEMBL399529",
      "2-(2-Furyl)-6-(1H-Pyrazol-1-Yl)Pyrimidin-4-Ylamine",
      "856171-18-1",
      "SCHEMBL1489577",
      "SSPIGFDAVQVRCH-UHFFFAOYSA-N",
      "BDBM50232155",
    ],
  },
  {
    primary_id: "D08CJB",
    names: ["H-Dmt-Tic-NH-(CH2)6-NH-Dmt-H", "CHEMBL372130"],
  },
  {
    primary_id: "D08CJK",
    names: ["TRYPTAMINE"],
  },
  {
    primary_id: "D08CJM",
    names: ["Peptide YY 3-36"],
  },
  {
    primary_id: "D08CKE",
    names: ["Alpha-Monofluoromethyl-3,4-Dehydroornithine Methyl Ester"],
  },
  {
    primary_id: "D08CKJ",
    names: ["Fonsartan"],
  },
  {
    primary_id: "D08CKM",
    names: ["SB-258741"],
  },
  {
    primary_id: "D08CLA",
    names: ["PMID25666693-Compound-123"],
  },
  {
    primary_id: "D08CLB",
    names: [
      "2-(2-Cyclohexylphenoxy)Acetic Acid",
      "CHEMBL247739",
      "82506-00-1",
      "NSC405039",
      "SCHEMBL4883839",
      "AC1L85O4",
      "DTXSID50323877",
      "ZINC1597753",
      "BDBM50213918",
    ],
  },
  {
    primary_id: "D08CLN",
    names: ["Suritozole"],
  },
  {
    primary_id: "D08CMF",
    names: ["Angeloxin"],
  },
  {
    primary_id: "D08CMQ",
    names: ["3-Isobutyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL185919"],
  },
  {
    primary_id: "D08CMT",
    names: ["ISIS 17252"],
  },
  {
    primary_id: "D08CMW",
    names: [
      "6-Phenylaminomethyl-Quinazoline-2,4-Diamine",
      "CHEMBL80746",
      "6-(Anilinomethyl)Quinazoline-2,4-Diamine",
      "27133-39-7",
      "AC1LAG5J",
      "SCHEMBL4788363",
      "CTK0I5781",
      "DTXSID30333156",
      "ZINC3814839",
      "BDBM50101637",
      "[(2,4-Diaminoquinazolin-6-Yl)Methyl]phenylamine",
      "2,4-Quinazolinediamine, 6-[(Phenylamino)Methyl]-",
    ],
  },
  {
    primary_id: "D08CNG",
    names: [
      "AL5300",
      "AC1L1BUF",
      "SCHEMBL5080690",
      "BDBM11932",
      "CTK8A0654",
      "4-Hydroxy-2-(Thiophen-2-Ylmethyl)-3,4-Dihydro-2H-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide 1,1-Dioxide",
      "4-Hydroxy-1,1-Dioxo-2-(Thiophen-2-Ylmethyl)-2H,3H,4H-1$l^{6},7,2-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide",
    ],
  },
  {
    primary_id: "D08CNN",
    names: ["1-(3-(Phenylthio)Propyl)-4-M-Tolylpiperazine", "CHEMBL239159"],
  },
  {
    primary_id: "D08CNP",
    names: ["AC430", "152-97-6", "SCHEMBL11862489", "ZINC21981448", "AKOS025402073"],
  },
  {
    primary_id: "D08CNS",
    names: ["IPRONIAZIDE"],
  },
  {
    primary_id: "D08COD",
    names: ["N-BENZOYL-D-ALANINE"],
  },
  {
    primary_id: "D08COK",
    names: ["1,10-Bis-(Dmt-Tic-Amino)Decane", "CHEMBL373192"],
  },
  {
    primary_id: "D08COR",
    names: ["ARC-520"],
  },
  {
    primary_id: "D08COU",
    names: ["FSH Patch"],
  },
  {
    primary_id: "D08CPL",
    names: ["MIST-B03", "Anti-Inflammatory Therapy, Mistral Pharma"],
  },
  {
    primary_id: "D08CPT",
    names: ["AC 137-164594"],
  },
  {
    primary_id: "D08CQE",
    names: ["AVE-9940"],
  },
  {
    primary_id: "D08CQL",
    names: ["SKF-107260"],
  },
  {
    primary_id: "D08CQQ",
    names: ["NSC-679036"],
  },
  {
    primary_id: "D08CQR",
    names: ["ISIS 103771"],
  },
  {
    primary_id: "D08CQY",
    names: ["Brilacidin"],
  },
  {
    primary_id: "D08CRO",
    names: ["PNH-192", "Deazapurine Ribonucleosides (Cancer), Gilead"],
  },
  {
    primary_id: "D08CRS",
    names: ["Chemoprotectant", "Chemoprotectant (Toxicity)"],
  },
  {
    primary_id: "D08CSE",
    names: ["IP-880"],
  },
  {
    primary_id: "D08CSK",
    names: ["PMID18800762C16", "Bisphosphonate, 16", "GTPL3195", "BDBM25260", "ZINC49881424"],
  },
  {
    primary_id: "D08CSZ",
    names: [
      "HMR-3004",
      "CP-426027",
      "RU-004",
      "RU-306",
      "RU-399",
      "RU-469",
      "RU-56006",
      "RU-59616",
      "RU-64004",
      "RU-66252",
      "RU-69874",
    ],
  },
  {
    primary_id: "D08CTL",
    names: ["CMI-936", "AKOS014858886"],
  },
  {
    primary_id: "D08CTT",
    names: [
      "Dimethindene",
      "Dimethpyrindene",
      "Dimethylpyrindene",
      "Dimetindene",
      "Dimetindeno",
      "Dimetindenum",
      "Fengel",
      "Fenistil",
      "Forhistal",
      "Foristal",
      "Pecofenil",
      "Triten",
      "Z 2001",
      "Dimetindene (INN)",
      "Dimetindene [INN:BAN]",
      "Dimetindeno [INN-Spanish]",
      "Dimetindenum [INN-Latin]",
      "Fenistil (TN)",
      "Dimethyl{2-[3-(2-Pyridylethyl)Inden-2-Yl]ethyl}amine",
      "N,N-Dimethyl-3-(1-(2-Pyridyl)Ethyl)Inden-2-Ethylamin",
      "N,N-Dimethyl-N-(3-(1-(2-Pyridyl)Ethyl)-2-Indenylethyl)Amin",
      "N,N-Dimethyl-2-[3-(1-Pyridin-2-Ylethyl)-1H-Inden-2-Yl]ethanamine",
      "1H-Indene-2-Ethanamine, N,N-Dimethyl-3-(1-(2-Pyridinyl)Ethyl)-(9CI)",
      "2-(1-(2-(2-Dimethylaminoethyl)Inden-3-Yl)Ethyl)Pyridine",
    ],
  },
  {
    primary_id: "D08CUE",
    names: ["BI-661051"],
  },
  {
    primary_id: "D08CUF",
    names: ["PMID28766366-Compound-Scheme9EHT1610"],
  },
  {
    primary_id: "D08CVC",
    names: ["Tocofersolan"],
  },
  {
    primary_id: "D08CVQ",
    names: ["Mipomersen"],
  },
  {
    primary_id: "D08CWE",
    names: ["PMID27828716-Compound-19"],
  },
  {
    primary_id: "D08CWP",
    names: ["Fenretinide Intravenous"],
  },
  {
    primary_id: "D08CWX",
    names: [
      "BVX-20-CD20",
      "BVX-20",
      "Anti-CD20 MAb (NHL), Biocon",
      "Anti-CD20 Monoclonal Antibody (Non-Hodgkin's Lymphoma), Biocon",
    ],
  },
  {
    primary_id: "D08CXO",
    names: ["N-Isopropyl Estradiol-16-Carboxamide"],
  },
  {
    primary_id: "D08CXZ",
    names: ["H-Dmt-Tic-NH-CH2-Bta", "CHEMBL258704"],
  },
  {
    primary_id: "D08CYC",
    names: ["PMID29671355-Compound-70"],
  },
  {
    primary_id: "D08CYT",
    names: ["ISIS 103701"],
  },
  {
    primary_id: "D08CYU",
    names: ["2-Mercapto-Pentanedioic Acid"],
  },
  {
    primary_id: "D08CZD",
    names: [
      "4-(2-Methoxyethoxy)-6-Methylpyrimidin-2-Amine",
      "4-(2-Methoxy-Ethoxy)-6-Methyl-Pyrimidin-2-Ylamine",
      "75435-54-0",
      "2wi1",
      "AC1MWPG2",
      "Pyrimidin-2-Amine, 11",
      "CHEMBL538937",
      "SCHEMBL10752783",
      "BDBM33223",
      "CCG-774",
      "VCJHOFUOIQHNBC-UHFFFAOYSA-N",
      "AKOS017529730",
      "DB08786",
      "2-Amino-4-(2-Methoxyethoxy)-6-Methylpyrimidine",
      "4-Methyl-6-(2-Methoxyethoxy)Pyrimidine-2-Amine",
      "4-(2-Methoxy-Ethoxy)-6-Methyl-Pyrimidine-2-Ylamine",
    ],
  },
  {
    primary_id: "D08CZE",
    names: [
      "Dihydropyrone",
      "Butopyronoxyl",
      "INDALONE",
      "532-34-3",
      "BMOO",
      "Indalon",
      "Butyl Mesityl Oxide Oxalate",
      "ENT 9",
      "N-Butylmesityloxid Oxalate",
      "Butyl Mesityl Oxide",
      "N-Butyl Mesityl Oxide Oxalate",
      "Caswell No. 128",
      "Butylmesityl Oxide Oxalate",
      "UNII-4I5PG5VZ0V",
      "NSC 404420",
      "HSDB 1526",
      "EINECS 208-535-3",
      "Butyl 3,4-Dihydro-2,2-Dimethyl-4-Oxo-2H-Pyran-6-Carboxylate",
      "EPA Pesticide Chemical Code 046801",
      "2,2-Dimethyl-6-Carbobutoxy-2,3-Dihydro-4-Pyrone",
      "BRN 0160461",
      "4I5PG5VZ0V",
      "Butyl Dimethyl Dihydro-Pyrone Carboxylate",
      "2H-Pyran-6-Carboxylic Acid, 3,4-Dihyd",
    ],
  },
  {
    primary_id: "D08CZT",
    names: [
      "2-Phenyl-3-Piperidin-4-Yl-1H-Indole",
      "221109-26-8",
      "2-PHENYL-3-(PIPERIDIN-4-YL)-1H-INDOLE",
      "2-Phenyl-3-(4-Piperidinyl)-1H-Indole",
      "CHEMBL295559",
      "SCHEMBL7962369",
      "CTK4E8621",
      "DTXSID60432328",
      "FWPAVENSJPMGJO-UHFFFAOYSA-N",
      "ZINC2580888",
      "CP-220",
      "ANW-46494",
      "BDBM50095050",
      "AKOS015898489",
      "AJ-42917",
      "3-(Piperidin-4-Yl)-2-Phenyl-1H-Indole",
      "1H-Indole,2-Phenyl-3-(4-Piperidinyl)-",
      "TC-135239",
      "ST2404535",
      "KB-231980",
      "AX8078102",
      "W4568",
      "FT-0707553",
      "AM20030371",
      "P13451",
    ],
  },
  {
    primary_id: "D08CZY",
    names: ["PMID28270021-Compound-WO2016054807Example71"],
  },
  {
    primary_id: "D08DAE",
    names: [
      "CHOLINE",
      "Choline",
      "Choline Ion",
      "Bilineurine",
      "Choline Cation",
      "Cholinum",
      "2-Hydroxy-N,N,N-Trimethylethanaminium",
      "62-49-7",
      "(2-Hydroxyethyl)Trimethylammonium",
      "Trimethylethanolamine",
      "N-Trimethylethanolamine",
      "Ethanaminium, 2-Hydroxy-N,N,N-Trimethyl-",
      "CCRIS 5847",
      "UNII-N91BDP6H0X",
      "AI3-24208",
      "BRN 1736748",
      "N,N,N-Trimethylethanol-Ammonium",
      "CHEBI:15354",
      "(Beta-Hydroxyethyl)Trimethylammonium",
      "EINECS 200-535-1",
      "CHEMBL920",
      "N91BDP6H0X",
      "2-Hydroxyethyl(Trimethyl)Ammonium",
    ],
  },
  {
    primary_id: "D08DBE",
    names: ["R-343"],
  },
  {
    primary_id: "D08DCP",
    names: ["BI-847325"],
  },
  {
    primary_id: "D08DCT",
    names: ["ISIS 25079"],
  },
  {
    primary_id: "D08DEG",
    names: ["5-(3,4-Dichlorophenyl)-1H-Pyrazol-3-Ol"],
  },
  {
    primary_id: "D08DET",
    names: ["Valerenic Acid Amide"],
  },
  {
    primary_id: "D08DFF",
    names: ["AM-001", "AM-001 (Topical)", "AM-001 (Topical), DermAct"],
  },
  {
    primary_id: "D08DFU",
    names: ["CMX-157"],
  },
  {
    primary_id: "D08DFX",
    names: [
      "Canagliflozin",
      "842133-18-0",
      "Invokana",
      "Canagliflozin Anhydrous",
      "Canagliflozin Hemihydrate",
      "UNII-6S49DGR869",
      "JNJ-28431754",
      "JNJ 24831754ZAE",
      "Canagliflozin Hydrate",
      "TA-7284",
      "1-(Glucopyranosyl)-4-Methyl-3-(5-(4-Fluorophenyl)-2-Thienylmethyl)Benzene",
      "CHEBI:73274",
      "6S49DGR869",
      "(2S,3R,4R,5S,6R)-2-(3-((5-(4-FLUOROPHENYL)THIOPHEN-2-YL)METHYL)-4-METHYLPHENYL)-6-(HYDROXYMETHYL)TETRAHYDRO-2H-PYRAN-3,4,5-TRIOL",
      "TA 7284",
      "(1S)-1,5-Anhydro-1-(3-{[5-(4-Fluorophenyl)-2-Thienyl]methyl}-4-Methylphenyl)-D-Glucitol",
      "928672-86-0",
    ],
  },
  {
    primary_id: "D08DFY",
    names: ["SQ-27986"],
  },
  {
    primary_id: "D08DGC",
    names: [
      "CPL-7075",
      "Ajulemic Acid",
      "Lenabasum",
      "IP-751",
      "CT-3",
      "137945-48-3",
      "IP 751",
      "UNII-OGN7X90BT8",
      "CPL7075",
      "JBT-101",
      "OGN7X90BT8",
      "Resunab",
      "(6aR,10aR)-1-Hydroxy-6,6-Dimethyl-3-(2-Methyloctan-2-Yl)-6a,7,10,10a-Tetrahydrobenzo[c]chromene-9-Carboxylic Acid",
      "(6ar,10ar)-3-(1,1-Dimethylheptyl)-1-Hydroxy-6,6-Dimethyl-6a,7,10,10a-Tetrahydro-6h-Benzo[c]chromene-9-Carboxylic Acid",
      "DMH-THC-11-OIC",
      "AB-III-56",
      "CT 3",
      "CPL 7075",
      "Lenabasum [USAN]",
      "HU-239",
      "AC1MJ0SY",
      "SCHEMBL26441",
      "CHEMBL456341",
      "GTPL9772",
      "IP751",
      "Mixed CB Agonist/Sodim Channel Blocker (Pain), Cervelo",
    ],
  },
  {
    primary_id: "D08DGI",
    names: [
      "AZT Nucleotide Mimics",
      "AZT Nucleotide Mimics, Biota",
      "B-108 Series, Biota",
      "B-561",
      "HIV Therapeutics (N-MAX), Biota",
      "IDDBCP164420",
      "P3Ms",
    ],
  },
  {
    primary_id: "D08DGW",
    names: [
      "CXL",
      "CYCLOHEXANOL",
      "108-93-0",
      "Cyclohexyl Alcohol",
      "Hexahydrophenol",
      "Hydrophenol",
      "Hydroxycyclohexane",
      "Hexalin",
      "1-Cyclohexanol",
      "Hydralin",
      "Adronal",
      "Naxol",
      "Adronol",
      "Anol",
      "Cykloheksanol",
      "Cicloesanolo",
      "Phenol, Hexahydro-",
      "Cicloesanolo [Italian]",
      "Cykloheksanol [Polish]",
      "Cyclohexanone Cyclohexanol Mixture",
      "Cyclohexan-1-Ol",
      "HSDB 61",
      "NSC 403656",
      "UNII-8E7S519M3P",
      "CCRIS 5896",
      "EINECS 203-630-6",
      "BRN 0906744",
      "AI3-00040",
      "CHEBI:18099",
      "HPXRVTGHNJAIIH-UHFFFAOYSA-N",
      "8E7S519M3P",
      "MFCD00003855",
      "Cyclohexanol, 98%",
      "Cyclohexanols",
      "DSSTox_CID_1894",
      "DSSTox_RID_76390",
    ],
  },
  {
    primary_id: "D08DHC",
    names: ["4-(O-Tolyloxy)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL499137", "SCHEMBL4163678", "BDBM50248552"],
  },
  {
    primary_id: "D08DHN",
    names: ["VX-787"],
  },
  {
    primary_id: "D08DHR",
    names: ["EDP-22"],
  },
  {
    primary_id: "D08DIJ",
    names: ["PMID28766366-Compound-Scheme9EHT5372"],
  },
  {
    primary_id: "D08DIU",
    names: ["LN-144"],
  },
  {
    primary_id: "D08DJE",
    names: ["K-133W"],
  },
  {
    primary_id: "D08DJH",
    names: ["Juvenile Chondrocyte Grafts"],
  },
  {
    primary_id: "D08DJP",
    names: ["AE-298p"],
  },
  {
    primary_id: "D08DJV",
    names: ["TAK-195"],
  },
  {
    primary_id: "D08DJY",
    names: ["Pyrimidine Derivative 26"],
  },
  {
    primary_id: "D08DKA",
    names: ["Levcromakalim"],
  },
  {
    primary_id: "D08DLP",
    names: ["3-Chloroboldine", "CHEMBL524439"],
  },
  {
    primary_id: "D08DMH",
    names: ["BAY2402234"],
  },
  {
    primary_id: "D08DMM",
    names: ["Imidazopyridine And Triazolopyridine Compound 3"],
  },
  {
    primary_id: "D08DMY",
    names: ["Bria-IMT"],
  },
  {
    primary_id: "D08DNA",
    names: ["PA-517"],
  },
  {
    primary_id: "D08DND",
    names: ["C[CO-(CH2)2-CO-Nle-D-Phe-Arg-Trp-Lys]-NH2", "CHEMBL411391"],
  },
  {
    primary_id: "D08DNK",
    names: [
      "N-(Chlorophenyl)-N'-Hydroxyguanidine",
      "130974-86-6",
      "N-(CHLOROPHENYL)-N'-HYDROXYGUANIDINE",
      "Guanidine, N-(4-Chlorophenyl)-N'-Hydroxy-",
      "N-(4-Chloro)Phenyl-N'-Hydroxyguanidine",
      "Guanidine,N-(4-Chlorophenyl)-N'-Hydroxy-",
      "C7H8ClN3O",
      "ACMC-1CA1V",
      "SCHEMBL2213727",
      "CHEMBL323202",
      "AC1L490B",
      "CTK4B7016",
      "DTXSID90156823",
      "AKOS017474904",
      "AKOS011771250",
      "AKOS030602610",
      "1-(4-Chlorophenyl)-3-Hydroxyguanidine",
      "DB04559",
      "2-(4-Chlorophenyl)-1-Hydroxyguanidine",
    ],
  },
  {
    primary_id: "D08DNV",
    names: ["Molecularly Imprinted Polymers"],
  },
  {
    primary_id: "D08DNZ",
    names: ["PMID25991433-Compound-P6"],
  },
  {
    primary_id: "D08DOE",
    names: [
      "ML-04",
      "BTL-Cg",
      "HP-4",
      "ML-04A",
      "Urological Therapeutics, Milkhaus",
      "Human Chorionic Gonadotropin (Benign Prostate Hyperplasia/Prostatitis/Cancer), Beech Tree Labs",
    ],
  },
  {
    primary_id: "D08DOG",
    names: ["A-846714"],
  },
  {
    primary_id: "D08DOQ",
    names: ["IGN301"],
  },
  {
    primary_id: "D08DOY",
    names: ["S-(N-Hydroxy-N-Iodophenylcarbamoyl)Glutathione"],
  },
  {
    primary_id: "D08DPH",
    names: ["(E,E)-8-[4-(3-Chlorophenyl)Butadien-1-Yl]caffeine", "CHEMBL489390"],
  },
  {
    primary_id: "D08DPN",
    names: ["GSK-625433"],
  },
  {
    primary_id: "D08DPU",
    names: ["Benzimidazolone Acetamide Derivative 1"],
  },
  {
    primary_id: "D08DPV",
    names: ["PRO-052"],
  },
  {
    primary_id: "D08DQL",
    names: [
      "NPPB",
      "5-Nitro-2-(3-Phenylpropylamino)Benzoic Acid",
      "107254-86-4",
      "UNII-3A35O9G3YZ",
      "5-Nitro-2-[(3-Phenylpropyl)Amino]benzoic Acid",
      "3A35O9G3YZ",
      "CHEBI:34457",
      "Benzoic Acid,5-Nitro-2-[(3-Phenylpropyl)Amino]-",
      "Benzoic Acid, 5-Nitro-2-((3-Phenylpropyl)Amino)-",
      "5-NITRO-2-PHENYLPROPYLAMINOBENZOIC ACID [NPPB]",
      "5-NITRO-2-(3-PHENYLPROPYLAMINO)-BENZOIC ACID",
      "5-Nitro-2-(3-Phenylpropylamino)Benzoic Acid (NPPB)",
      "SR-01000075336",
      "HOE-144",
      "HOE 144",
      "Tocris-0593",
      "Spectrum_001814",
      "ACMC-20c9kv",
      "AC1L1IEV",
      "Spectrum4_000338",
      "Spectrum2_001477",
      "5-Nitro-2-(3-Phenylpropylamino) Benzoic Acid",
    ],
  },
  {
    primary_id: "D08DQZ",
    names: [
      "4-Methyl-Oxazolidin-(2Z)-Ylideneamine",
      "CHEMBL105823",
      "4-Methyl-4,5-Dihydro-1,3-Oxazol-2-Amine",
      "68210-17-3",
      "4-Methyloxazolidine-2-Imine",
      "SCHEMBL6593982",
      "SCHEMBL11825446",
      "MolPort-004-802-126",
      "BDBM50138202",
      "AKOS006363611",
      "NE17732",
    ],
  },
  {
    primary_id: "D08DRD",
    names: ["Patuletin 3-O-Beta-D-Robinobioside", "CHEMBL442920", "6-Methoxyquercetin 3-Robinobioside"],
  },
  {
    primary_id: "D08DRL",
    names: ["Research Programme: Erythropoietin Oral - HanAll Biopharma"],
  },
  {
    primary_id: "D08DRU",
    names: ["Influenza A Virus H5N1 Cell Culture-Based Vaccine"],
  },
  {
    primary_id: "D08DSC",
    names: [
      "BST-1005",
      "BST-1004",
      "Anti-Laminin Gamma2 MAbs (Cancer), BioStratum",
      "Anti-Laminin Gamma2 Monoclonal Antibodies (Cancer), BioStratum",
    ],
  },
  {
    primary_id: "D08DSF",
    names: ["MEDI-557"],
  },
  {
    primary_id: "D08DSG",
    names: ["C(Arg-Gly-Asp-D-Phe-Val)", "CHEMBL383412"],
  },
  {
    primary_id: "D08DSJ",
    names: ["ML-3163"],
  },
  {
    primary_id: "D08DTD",
    names: [
      "Biostrophin",
      "Duchenne Muscular Dystrophy Therapy, Asklepios",
      "Adeno-Associated Virus Gene Therapy (Dystrophin), AskBio",
      "Adeno-Associated Virus Gene Therapy (Dystrophin), Asklepios",
    ],
  },
  {
    primary_id: "D08DTR",
    names: ["RHPIV1 84/Del/170/942A"],
  },
  {
    primary_id: "D08DTT",
    names: [
      "Androgen Receptor Modulators",
      "Androgen Receptor Modulators (Osteoporosis)",
      "Androgen Agonists (Osteoporosis), Merck & Co",
      "Androgen Receptor Modulators (Osteoporosis), Merck & Co",
      "SARMs (Osteoporosis), Merck & Co",
    ],
  },
  {
    primary_id: "D08DUF",
    names: [
      "GL-0817",
      "912762-70-0",
      "MolPort-002-506-765",
      "Tert-Butyl N-[(2Z)-2-(2,4-Dimethoxyphenyl)-2-(Hydroxyimino)Ethyl]carbamate",
      "ZINC103473003",
      "AKOS005256100",
      "[2-(2,4-DIMETHOXY-PHENYL)-2-HYDROXYIMINO-ETHYL]-CARBAMIC ACID TERT-BUTYL ESTER",
    ],
  },
  {
    primary_id: "D08DUM",
    names: ["N-Methyl-ETAV"],
  },
  {
    primary_id: "D08DUY",
    names: ["T-1249"],
  },
  {
    primary_id: "D08DVE",
    names: ["BI-660848"],
  },
  {
    primary_id: "D08DVL",
    names: [
      "3-(4-(O-Toluidino)Pyrimidin-2-Ylamino)Benzamide",
      "2,4-Dianilino Pyrimidine, 8",
      "CHEMBL400798",
      "BDBM26151",
    ],
  },
  {
    primary_id: "D08DVR",
    names: ["Fentanyl MDTS"],
  },
  {
    primary_id: "D08DVV",
    names: ["1-Benzhydryl-4-Hexylpiperidin-4-Ol", "CHEMBL234773"],
  },
  {
    primary_id: "D08DWC",
    names: ["MSC2015103B"],
  },
  {
    primary_id: "D08DWG",
    names: ["Phenylpyridine Derivative 1"],
  },
  {
    primary_id: "D08DWH",
    names: ["Idrapril"],
  },
  {
    primary_id: "D08DWK",
    names: ["TRPC4 And TRPC5 Inhibitor"],
  },
  {
    primary_id: "D08DWM",
    names: ["Gp100:209-217(210M) Peptide Vaccine"],
  },
  {
    primary_id: "D08DXA",
    names: [
      "Acid Blue 25",
      "6408-78-2",
      "C.I. ACID BLUE 25",
      "UNII-S2E15W6FSN",
      "S2E15W6FSN",
      "W-110136",
      "CHEMBL256057",
      "2-Anthracenesulfonic Acid, 1-Amino-9,10-Dihydro-9,10-Dioxo-4-(Phenylamino)-, Sodium Salt (1:1)",
      "EINECS 229-068-1",
      "EC 229-068-1",
      "2-Anthracenesulfonic Acid, 1-Amino-9,10-Dihydro-9,10-Dioxo-4-(Phenylamino)-, Monosodium Salt",
      "C20H13N2NaO5S",
      "SCHEMBL790822",
      "DTXSID2044711",
      "CTK8F7553",
      "Sodium 1-Amino-9,10-Dioxo-4-Phenylaminoanthracene-2-Sulphonate",
      "MFCD00001214",
      "Acid Blue 25, Dye Content 45 %",
      "AKOS015894441",
      "AN-19165",
      "Q394",
      "I04-8965",
      "Sodium 1",
    ],
  },
  {
    primary_id: "D08DZK",
    names: ["N-(2,4-Dimethoxyphenethyl)Oleamide"],
  },
  {
    primary_id: "D08DZN",
    names: ["Evinacumab"],
  },
  {
    primary_id: "D08DZO",
    names: ["Malaria DNA Vaccine"],
  },
  {
    primary_id: "D08DZZ",
    names: [
      "HE2100",
      "Androstenediol",
      "Hermaphrodiol",
      "5-Androstenediol",
      "Androst-5-Enediol",
      "521-17-5",
      "Androst-5-Ene-3beta,17beta-Diol",
      "Neumune",
      "3beta,17beta-Dihydroxyandrost-5-Ene",
      "Delta(Sup 5)-Androstenediol",
      "Androstenediol [JAN]",
      "UNII-95PS51EMXY",
      "Androst-5-Enediol (VAN)",
      "(3beta,17beta)-Androst-5-Ene-3,17-Diol",
      "5-AED",
      "3beta,17beta-Dihydroxy-5-Androstene",
      "Androst-5-En-3beta,17beta-Diol",
      "EINECS 208-306-8",
      "NSC 12163",
      "95PS51EMXY",
      "ANDROST-5-ENE-3-Beta,17-Beta-DIOL",
      "CHEMBL440283",
      "CHEBI:2710",
      "(3-Beta,17-Beta)-Androst-5-Ene-3,17-Diol",
      "Delta(Sup",
      "ANDROSTENEDIOL",
    ],
  },
  {
    primary_id: "D08EAI",
    names: ["AS-101"],
  },
  {
    primary_id: "D08EAR",
    names: ["HE2200"],
  },
  {
    primary_id: "D08EBC",
    names: [
      "(2-BROMOETHYL)(2-'FORMYL-4'-AMINOPHENYL) ACETATE",
      "2-Bromoethyl 2-(4-Amino-2-Formylphenyl)Acetate",
      "AC1NE0SL",
      "DB07955",
      "2-Bromoethyl (4-Amino-2-Formylphenyl)Acetate",
    ],
  },
  {
    primary_id: "D08EBN",
    names: [
      "Nitazoxanide",
      "Alinia",
      "Colufase",
      "Cryptaz",
      "Daxon",
      "Heliton",
      "Nitazoxanid",
      "Nitazoxanida",
      "Nitazoxanidum",
      "Taenitaz",
      "Tizoxanide Glucuronide",
      "AZT + Nitazoxanide",
      "Alinia (TN)",
      "Annita (TN)",
      "Azt+ Nitazoxanide",
      "Daxon (TN)",
      "Dexidex (TN)",
      "Kidonax (TN)",
      "Nitax (TN)",
      "Nitazox (TN)",
      "Nitazoxanida [INN-Spanish]",
      "Nitazoxanide [USAN:INN]",
      "Nitazoxanidum [INN-Latin]",
      "Pacovanton (TN)",
      "Paramix (TN)",
      "Phavic-1",
      "Zox (TN)",
      "Nitazoxanide (USAN/INN)",
      "N-(5-Nitrothiazol-2-Yl)Salicylamide Acetate Ester",
      "N-(5-Nitro-2-Thiazolyl)Salicylamide Acetate (Ester)",
      "Daxon, Dexidex, Kidonax, Pacovanton, Paramix, Nitax, Zox, Nitazoxanide",
      "O-(N-(5-Nitrothiazol-2-Yl)Carbamoyl)Phenyl Acetate",
      "Salicylamide, N-(5-Nitro-2-Thiazolyl)-Acetate (Ester)",
      "[2-[(5-Nitro-1,3-Thiazol-2-Yl)Carbamoyl]phenyl] Acetate",
      "Salicylamide, N-(5-Nitro-2-Thiazolyl)-, Acetate (Ester)",
      "(2-((5-Nitro-2-Thiazolyl)Carbamoyl)Phenyl)Acetat",
      "2-(Acetolyloxy)-N-(5-Nitro-2-Thiazolyl)Benzamide",
      "2-Acetyloxy-N-[(5-Nitro-2-Thiazolyl)]benzamide",
      "2-[(5-Nitro-1,3-Thiazol-2-Yl)Carbamoyl]phenyl Acetate",
    ],
  },
  {
    primary_id: "D08EBT",
    names: ["[3H]HEMADO"],
  },
  {
    primary_id: "D08EBV",
    names: ["C[Ser-Tyr-Thr-His-Dphe-Arg-Trp-Thr-Ile-Pro]", "CHEMBL438596"],
  },
  {
    primary_id: "D08ECB",
    names: ["LAS-101057"],
  },
  {
    primary_id: "D08ECP",
    names: ["N-[3H]alpha-Methylhistamine"],
  },
  {
    primary_id: "D08ECW",
    names: ["Racotumomab"],
  },
  {
    primary_id: "D08EDE",
    names: ["MIRNA-Rx07"],
  },
  {
    primary_id: "D08EDN",
    names: ["SSR128129E"],
  },
  {
    primary_id: "D08EDV",
    names: ["N-Benzyl-N-(Phenylsulfonyl)Benzamide"],
  },
  {
    primary_id: "D08EEA",
    names: ["PMID30247903-Compound-General Structure36"],
  },
  {
    primary_id: "D08EEM",
    names: ["PV-701"],
  },
  {
    primary_id: "D08EEO",
    names: ["Demobesin", "Bombesin Antagonist (Prostate/Breast/NSCL), Biomedica Lifesciences", "99mTc-Demobesin-6"],
  },
  {
    primary_id: "D08EES",
    names: ["XR-5000", "DACA", "SN-22995"],
  },
  {
    primary_id: "D08EEU",
    names: ["Englitazone Sodium"],
  },
  {
    primary_id: "D08EFH",
    names: ["2-(5-Methoxy-1H-Indol-3-Yl)-1-Methyl-Ethylamine", "5-Methoxy-Alpha-Methyltryptamine"],
  },
  {
    primary_id: "D08EFL",
    names: ["Pancreatic Cancer Vaccine"],
  },
  {
    primary_id: "D08EGA",
    names: ["N-(Cis-9-Cis-12-Octadecadienyl)Sulfamide", "CHEMBL570988", "SCHEMBL3107035"],
  },
  {
    primary_id: "D08EGT",
    names: ["PMID25684022-Compound-WO2008005457"],
  },
  {
    primary_id: "D08EHL",
    names: ["Macrocycle Derivative 2"],
  },
  {
    primary_id: "D08EHR",
    names: ["16-(Pyridin-3-Yl)Methylene-Estradiol"],
  },
  {
    primary_id: "D08EIA",
    names: ["Carbamide Derivative 6"],
  },
  {
    primary_id: "D08EID",
    names: ["Salicylic Acid Derivative 5"],
  },
  {
    primary_id: "D08EIK",
    names: ["LY3016859"],
  },
  {
    primary_id: "D08EIX",
    names: ["T-686"],
  },
  {
    primary_id: "D08EJQ",
    names: ["1-Hydroxy-3-(4-(Trifluoromethyl)Phenyl)Urea", "CHEMBL260172", "SCHEMBL12491371"],
  },
  {
    primary_id: "D08EKQ",
    names: [
      "1-(1-(4-FLUOROPHENYL)-2-(2-(TRIFLUOROMETHOXY)PHENYL)ETHYL)PIPERAZINE (ENANTIOMERIC MIX)",
      "CHEMBL1084033",
      "SCHEMBL5447674",
      "BDBM50319830",
      "1-(1-(4-Fluorophenyl)-2-(2-(Trifluoromethoxy)Phenyl)Ethyl)Piperazine",
      "(-)-1-(1-(4-Fluorophenyl)-2-(2-(Trifluoromethoxy)Phenyl)Ethyl)Piperazine",
    ],
  },
  {
    primary_id: "D08ELL",
    names: ["TBE-80E"],
  },
  {
    primary_id: "D08ELM",
    names: ["RWJ-58259"],
  },
  {
    primary_id: "D08ELN",
    names: ["Guanidine Oseltamivir Prodrug"],
  },
  {
    primary_id: "D08ELY",
    names: ["3-Benzenesulfonyl-Heptanoic Acid Hydroxyamide", "CHEMBL263501", "BDBM50089196"],
  },
  {
    primary_id: "D08EME",
    names: ["4-(2,4-Dichlorophenoxy)-4'-Methylbiphenyl-3-Ol"],
  },
  {
    primary_id: "D08EMG",
    names: ["(S)APOMORPHINE"],
  },
  {
    primary_id: "D08EMW",
    names: ["Carbamate Derivative 15"],
  },
  {
    primary_id: "D08ENB",
    names: ["GSK1995057"],
  },
  {
    primary_id: "D08ENJ",
    names: [
      "Anti-CGEN-153 Mab",
      "CGEN-153",
      "Anti-CGEN-153 MAb (Ovarian Cancer)",
      "CGEN-153 (Ovarian Cancer), Compugen",
      "Anti-CGEN-153 MAb (Ovarian Cancer), Compugen",
    ],
  },
  {
    primary_id: "D08ENK",
    names: ["Insulin Susp Isophane Recombinant Human"],
  },
  {
    primary_id: "D08EOD",
    names: [
      "Methsuximide",
      "Celontin",
      "Mesuximid",
      "Mesuximida",
      "Mesuximide",
      "Mesuximidum",
      "Methsuximid",
      "Metosuccimmide",
      "Metsuccimide",
      "Petinutin",
      "Methsuximide [BAN]",
      "Metosuccimmide [DCIT]",
      "PM 396",
      "Alpha-Methylphensuximide",
      "Celontin (TN)",
      "Mesuximida [INN-Spanish]",
      "Mesuximide (INN)",
      "Mesuximidum [INN-Latin]",
      "Methsuximide (USP)",
      "Petinutin (TN)",
      "Alpha-Methyl-Alpha-Phenyl N-Methyl Succinimide",
      "N,2-Dimethyl-2-Phenylsuccinimide",
      "N-Methyl-Alpha-Methyl-Alpha-Phenylsuccinimide",
      "(+-)-N,2-Dimethyl-2-Phenylsuccinimide",
      "(RS)-1,3-Dimethyl-3-Phenyl-2,5-Pyrrolidindion",
      "1,3-Dimethyl-3-Phenyl-2,5-Dioxopyrrolidine",
      "1,3-Dimethyl-3-Phenyl-2,5-Pyrrolidinedione",
      "1,3-Dimethyl-3-Phenyl-Pyrrolidin-2,5-Dione",
      "1,3-Dimethyl-3-Phenylsuccinimide",
      "1,3-Dimethyl-3-Phenylpyrrolidine-2,5-Dione",
    ],
  },
  {
    primary_id: "D08EOW",
    names: ["RG7112"],
  },
  {
    primary_id: "D08EPK",
    names: [
      "UBP-551",
      "Allosteric NMDA Agonists (Psychiatric Disorders)",
      "Allosteric NMDA Agonists (Psychiatric Disorders), University Of Nebraska Medical Center/University Of Bristol",
      "Naphthalene/Phenanthrene Derivatives (Psychiatric Disorders), University Of Nebraska Medical Center/University Of Bristol",
    ],
  },
  {
    primary_id: "D08EQD",
    names: ["FGF401"],
  },
  {
    primary_id: "D08EQQ",
    names: ["LPD-1050"],
  },
  {
    primary_id: "D08ERB",
    names: [
      "Dabigatran Etexilate Neutralizer",
      "Dabigatran Etexilate Neutralizer, Boehringer Ingelheim",
      "Anti-Dabigatran Antibody (Bloodclotting Disorder), Boehringer Ingelheim",
    ],
  },
  {
    primary_id: "D08ERD",
    names: ["(R)-5-Diphosphomevalonate"],
  },
  {
    primary_id: "D08ESA",
    names: [
      "CI-966",
      "1-[2-[Bis[4-(Trifluoromethyl)Phenyl]methoxy]ethyl]-1,2,5,6-Tetrahydropyridine-3-Carboxylic Acid Hydrochloride",
    ],
  },
  {
    primary_id: "D08ETA",
    names: ["PMID26394986-Compound-20"],
  },
  {
    primary_id: "D08EUH",
    names: [
      "D-Phenylalanyl-N-Benzyl-L-Prolinamide",
      "CHEMBL116597",
      "BDBM50133518",
      "DB07143",
      "(2S)-1-[(2R)-2-Amino-3-Phenylpropanoyl]-N-Benzylpyrrolidine-2-Carboxamide",
      "(S)-1-((R)-2-Amino-3-Phenyl-Propionyl)-Pyrrolidine-2-Carboxylic Acid Benzylamide",
      "(2S)-1-((2R)-2-Amino-3-Phenyl-Propanoyl)-N-(Phenylmethyl)Pyrrolidine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D08EUS",
    names: ["CGC-11047"],
  },
  {
    primary_id: "D08EVF",
    names: ["Anti-Notch-1 Mabs"],
  },
  {
    primary_id: "D08EVM",
    names: ["FR-128998"],
  },
  {
    primary_id: "D08EVN",
    names: ["Tyropanoate Sodium"],
  },
  {
    primary_id: "D08EWM",
    names: ["UCB-0942", "Anticonvulsant (Epilepsy), UCB"],
  },
  {
    primary_id: "D08EXA",
    names: [
      "2-Amino-6-(3-Cyano-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(3-Cyanophenylthio)Benzenecarbonitrile",
      "AC1LAE12",
      "BDBM1745",
      "CHEMBL293806",
      "CTK7C8632",
      "ZINC5933129",
      "6-Amino[2,3'-Thiobisbenzonitrile]",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1o",
      "2-Amino-6-(3-Cyanophenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(3-Cyanophenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D08EXR",
    names: [
      "Beta-Ergocriptine",
      "Beta-Ergocryptine",
      "Beta-Ergokryptine",
      "EINECS 243-728-6",
      "5'alpha(S)-Sec-Butyl-12'-Hydroxy-2'-Isopropylergotaman-3',6',18-Trione",
      "AC1L4P6P",
      "GTPL988",
      "YYWXOXLDOMRDHW-SGVWFJRMSA-N",
      "PDSP2_001492",
      "AKOS027307800",
      "LS-64294",
    ],
  },
  {
    primary_id: "D08EYN",
    names: ["Acrylamide Compound 2"],
  },
  {
    primary_id: "D08EYQ",
    names: ["ORE-1001", "ACE2 Enzyme Inhibitor (Inflammatory Bowel Disease), Ore Pharmaceuticals"],
  },
  {
    primary_id: "D08EYZ",
    names: [
      "PD-140548",
      "CHEMBL356898",
      "CHEMBL2111204",
      "GTPL904",
      "PDSP2_000896",
      "PDSP1_000910",
      "BDBM50033600",
      "BDBM50073729",
      "140677-01-6",
      "(3R)-3-[[(2S)-2-(2-Adamantyloxycarbonylamino)-3-(1H-Indol-3-Yl)-2-Methylpropanoyl]amino]-4-Phenylbutanoic Acid",
    ],
  },
  {
    primary_id: "D08EZA",
    names: [
      "3-(Imidazolylmethyl)-4'-Nitroflavone",
      "CHEMBL213588",
      "BDBM50191598",
      "3-(Imidazolylmethyl)-4''-Nitroflavone",
    ],
  },
  {
    primary_id: "D08EZI",
    names: [
      "N-(2,6-Diphenylpyrimidin-4-Yl)-3-Methylbutyramide",
      "CHEMBL387322",
      "820961-45-3",
      "SCHEMBL4829224",
      "CTK3E2658",
      "DTXSID90471528",
      "BDBM50157665",
      "AKOS030569392",
      "Butanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-3-Methyl-",
    ],
  },
  {
    primary_id: "D08EZR",
    names: [
      "5-Iodouracil",
      "696-07-1",
      "5-Iodopyrimidine-2,4(1H,3H)-Dione",
      "2,4(1H,3H)-Pyrimidinedione, 5-Iodo-",
      "5-Iodopyrimidine-2,4-Diol",
      "Uracil, 5-Iodo-",
      "2,4-Dihydroxy-5-Iodopyrimidine",
      "5-Iodo-1H-Pyrimidine-2,4-Dione",
      "5-Iodo Uracil",
      "UNII-H59BRK500M",
      "NSC 57848",
      "CHEBI:43636",
      "EINECS 211-788-2",
      "CHEMBL1173",
      "AI3-50390",
      "H59BRK500M",
      "KSNXJLQDQOIRIP-UHFFFAOYSA-N",
      "5-Iodo-1,2,3,4-Tetrahydropyrimidine-2,4-Dione",
      "NSC57848",
      "MFCD00006020",
      "5-Iodouracil, 99%",
      "5-Iodo-1,3-Dihydropyrimidine-2,4-Dione",
      "IUR",
      "5909-21-7",
      "5-Iodo-Uracil",
      "5- Iodouracil",
      "Zlchem 530",
    ],
  },
  {
    primary_id: "D08FAB",
    names: [
      "3-(Naphthalen-2-Yl)Pyridine",
      "3-(2-Naphthyl)Pyridine",
      "CHEMBL365735",
      "2-(3-Pyridyl)-Naphthalene",
      "SCHEMBL4510935",
      "BDBM8904",
      "Pyridine-Substituted Naphthalene 1",
    ],
  },
  {
    primary_id: "D08FAC",
    names: ["CaCP-29"],
  },
  {
    primary_id: "D08FAT",
    names: ["RP-170"],
  },
  {
    primary_id: "D08FBB",
    names: [
      "8-Ethyl-3-Hydroxy-1H-Benzo[b]azepine-2,5-Dione",
      "1H-1-Benzazepine-2,5-Dione, 8-Ethyl-3-Hydroxy-",
      "SCHEMBL9389409",
      "CHEMBL143292",
      "183663-81-2",
    ],
  },
  {
    primary_id: "D08FBM",
    names: [
      "2-(2,4-Dichlorophenoxy)-5-Phenethylphenol",
      "2-(2,4-Dichlorophenoxy)-5-(2-Phenylethyl)Phenol",
      "JPJ",
      "Triclosan Derivative, 21",
      "CHEMBL260061",
      "BDBM25420",
    ],
  },
  {
    primary_id: "D08FCA",
    names: ["Guanilib"],
  },
  {
    primary_id: "D08FDD",
    names: ["HL-4130"],
  },
  {
    primary_id: "D08FDI",
    names: ["3-(2-Pyridin-2-Yl-2H-Tetrazol-5-Yl)-Benzonitrile", "CHEMBL366288", "SCHEMBL4703496", "ZINC13582347"],
  },
  {
    primary_id: "D08FDW",
    names: ["Diabetes (Type I) Vaccine"],
  },
  {
    primary_id: "D08FDX",
    names: ["MGCD265"],
  },
  {
    primary_id: "D08FDY",
    names: ["YGRKKRRQRRR-KLSSIESDV"],
  },
  {
    primary_id: "D08FEV",
    names: [
      "3-[(2-Methyl-4-Thiazolyl)Ethynyl]benzamide",
      "CHEMBL414673",
      "Benzamide, 3-[2-(2-Methyl-4-Thiazolyl)Ethynyl]-",
      "SCHEMBL4157102",
      "BDBM50181774",
    ],
  },
  {
    primary_id: "D08FFH",
    names: [
      "7-Acetonyloxy-3,4-Cyclohexene-8-Methylcoumarin",
      "307550-03-4",
      "CHEMBL488073",
      "4-METHYL-3-(2-OXOPROPOXY)-7,8,9,10-TETRAHYDRO-6H-BENZO[C]CHROMEN-6-ONE",
      "CHEMBRDG-BB 6148717",
      "AC1LDV7A",
      "CBMicro_031517",
      "ZINC83523",
      "CTK4G5861",
      "DTXSID00351504",
      "MolPort-000-688-897",
      "ZX-CM016547",
      "STL303255",
      "BDBM50246265",
      "AKOS002377920",
      "MCULE-4345433569",
      "AJ-10643",
      "ACM307550034",
      "BIM-0031568.P001",
      "W-8890",
      "SR-01000229174",
      "SR-01000229174-1",
      "4-Methyl-3-(2-Oxopropoxy)-7,8,9,10-Tetrahydrobenzo[c]chromen-6-One",
      "6H-Dibenzo[b,d]pyran-6-One, 7,8,9,10-Tetrahydro-4-Methyl-3-",
    ],
  },
  {
    primary_id: "D08FGQ",
    names: ["Tyr-Pro-D-Phg-Phe-NH2", "CHEMBL210247"],
  },
  {
    primary_id: "D08FGR",
    names: ["Galmic"],
  },
  {
    primary_id: "D08FGX",
    names: ["PALOSURAN"],
  },
  {
    primary_id: "D08FHP",
    names: ["BFKB8488A"],
  },
  {
    primary_id: "D08FHU",
    names: ["6,7,8-Trimethoxy-1,4-Dimethylquinolin-2(1H)-One", "Casimiroin Analogue, 1n", "CHEMBL492382", "BDBM29225"],
  },
  {
    primary_id: "D08FHV",
    names: ["NY-008", "3-Octylisobenzofuran-1(3H)-One"],
  },
  {
    primary_id: "D08FHZ",
    names: [
      "ATL-313",
      "DE-112",
      "ATL-313 (Ocular Disease), Adenosine Therapeutics",
      "ATL-313 (Ocular Disease), Clinical Data",
    ],
  },
  {
    primary_id: "D08FIB",
    names: ["LY3316531"],
  },
  {
    primary_id: "D08FIX",
    names: ["OTSSP167"],
  },
  {
    primary_id: "D08FJG",
    names: ["EHT/AGN-0002", "EHT/AGN-002"],
  },
  {
    primary_id: "D08FJL",
    names: [
      "Carbetocin",
      "Carbetocino [INN-Spanish]",
      "Carbetocinum [INN-Latin]",
      "Duratocin (TN)",
      "1-Buttersaeure-2-(3-(4-Methoxyphenyl)-L-Alanin)Oxytocin",
      "1-{[(3r,6s,9s,12s,15s)-6-(2-Amino-2-Oxoethyl)-9-(3-Amino-3-Oxopropyl)-12-[(2s)-Butan-2-Yl]-15-(4-Hydroxybenzyl)-16-Methyl-5,8,11,14,17-Pentaoxo-1-Thia-4,7,10,13,16-Pentaazacycloicosan-3-Yl]carbonyl}-L-Prolyl-L-Leucylglycinamide",
    ],
  },
  {
    primary_id: "D08FKD",
    names: ["Autologous Renal Cell Carcinoma Vaccine"],
  },
  {
    primary_id: "D08FKE",
    names: ["Aminoglycosides"],
  },
  {
    primary_id: "D08FKH",
    names: ["KLH-2109"],
  },
  {
    primary_id: "D08FKL",
    names: ["ACHN-975"],
  },
  {
    primary_id: "D08FKX",
    names: ["SDZ-MAR-327"],
  },
  {
    primary_id: "D08FLT",
    names: ["Pyrrole-Imidazole Polyamides"],
  },
  {
    primary_id: "D08FLV",
    names: ["3-(4-Hydroxy-Phenyl)-Benzo[d]isoxazol-5-Ol"],
  },
  {
    primary_id: "D08FMC",
    names: ["Sterol Derivative 1"],
  },
  {
    primary_id: "D08FMK",
    names: ["LY2944876"],
  },
  {
    primary_id: "D08FMR",
    names: ["ARC-1905", "Aptamer C5 Inhibitors (Age-Related Macular Degeneration), Archemix/Ophthotech"],
  },
  {
    primary_id: "D08FNJ",
    names: ["RO-14"],
  },
  {
    primary_id: "D08FOG",
    names: ["PMID27376512-Compound-PG-11048"],
  },
  {
    primary_id: "D08FOJ",
    names: [
      "2-Phenyl-2,3-Dihydrobenzo[h]chromen-4-One",
      "ALPHA-NAPHTHOFLAVANONE",
      "2-Phenyl-2H-Benzo[h]chromen-4(3H)-One",
      "CHEMBL404225",
      "6051-86-1",
      "SCHEMBL14027636",
      "BDBM50345186",
      "FT-0758804",
      "2-Phenyl-2H-Naphtho[1,2-B]pyran-4(3H)-One",
    ],
  },
  {
    primary_id: "D08FOR",
    names: ["Tecovirimat"],
  },
  {
    primary_id: "D08FOT",
    names: ["PSB-1115"],
  },
  {
    primary_id: "D08FOV",
    names: ["MRS2279"],
  },
  {
    primary_id: "D08FOX",
    names: ["Piroximone"],
  },
  {
    primary_id: "D08FPM",
    names: ["Dhaq Diacetate"],
  },
  {
    primary_id: "D08FPY",
    names: [
      "10-Acetyl-1,8-Dihydroxy-10H-Anthracen-9-One",
      "10-Acetyl Dithranol",
      "CCRIS 2039",
      "CHEMBL339332",
      "3022-61-5",
      "9(10H)-Anthracenone, 10-Acetyl-1,8-Dihydroxy-",
      "10-Acetylanthraline",
      "AC1MIVB6",
      "SCHEMBL10630399",
      "DTXSID30184322",
      "1,8-Dihydroxy-10-Acetylanthrone",
      "JLVFGQSHNYEUIN-UHFFFAOYSA-N",
      "1,8-Dihydroxy-10-Acetyl Anthrone",
      "ZINC13606543",
      "BDBM50060870",
    ],
  },
  {
    primary_id: "D08FQW",
    names: ["M-Tolyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL481162"],
  },
  {
    primary_id: "D08FQX",
    names: ["KNI-10526"],
  },
  {
    primary_id: "D08FRG",
    names: [
      "EphA2-Targeting BiTE Molecules",
      "BscEphA2xCD3",
      "MEDI-544",
      "Anticancer BiTE, Micromet/MedImmune",
      "EphA2-Targeting BiTE Molecules, Micromet",
      "Bispecific T-Cell Engager (Cancer), MedImmune/Micromet",
    ],
  },
  {
    primary_id: "D08FSC",
    names: ["L-731,988"],
  },
  {
    primary_id: "D08FSD",
    names: ["SER-100"],
  },
  {
    primary_id: "D08FSF",
    names: [
      "1-Allyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL66899",
      "1H-Purine-2,6-Dione, 3,9-Dihydro-1-(2-Propen-1-Yl)-",
      "146830-80-0",
      "1-Allylxanthine",
      "SCHEMBL6464355",
      "BDBM50042206",
    ],
  },
  {
    primary_id: "D08FSR",
    names: ["QLT-091382", "Acetyl-CoA Carboxylase/Sebum Inhibitor (Sebaceous Gland Disease), Valocor"],
  },
  {
    primary_id: "D08FTG",
    names: [
      "Phenindione",
      "Athrombon",
      "Bindan",
      "Cronodione",
      "Danedion",
      "Danilon",
      "Danilone",
      "Diadilan",
      "Dindevan",
      "Dineval",
      "Diophindane",
      "Emandion",
      "Emandione",
      "Eridione",
      "Fenhydren",
      "Fenilin",
      "Fenindion",
      "Fenindiona",
      "Hedulin",
      "Hemolidione",
      "Indema",
      "Indion",
      "Indon",
      "Phenhydren",
      "Phenillin",
      "Phenindionum",
      "Phenylen",
      "Phenylin",
      "Phenylindanedione",
      "Phenylindione",
      "Phenyline",
      "Phenyllin",
      "Pindione",
      "Rectadione",
      "Theradione",
      "Thrombasal",
      "Tromazal",
      "Trombol",
      "Boots Brand Of Phenindione",
      "Goldshield Brand Of Phenindione",
      "Merck Lipha Sante Brand Of Phenindione",
      "P1029",
      "P26406_ALDRICH",
      "Fenindiona [INN-Spanish]",
      "Hedulin (TN)",
      "Phenindione (INN)",
      "Phenindione [INN:BAN]",
      "Phenindionum [INN-Latin]",
      "2 Phenyl 1,3 Indandione",
      "2-Fenyloindandion-1,3",
      "2-Fenyloindandion-1,3 [Polish]",
      "2-Phenyl-1,3-Diketohydrindene",
      "2-Phenyl-1,3-Indandione",
      "2-Phenyl-1,3-Indanedione",
      "2-Phenyl-1H-Indene-1,3(2H)-Dione",
      "2-Phenylindan-1,3-Dione",
      "2-Phenylindandione",
      "2-Phenyl-1,3(2H)-Indenedione",
      "2-Phenyl-2,3-Dihydro-1H-Indene-1,3-Dione",
      "2-Phenylindene-1,3-Dione",
    ],
  },
  {
    primary_id: "D08FTP",
    names: ["99mTc-MIP-1404"],
  },
  {
    primary_id: "D08FUK",
    names: ["(R,S)-1-(5-Methyl-1H-Indol-1-Yl)Propan-2-Amine", "CHEMBL93850", "SCHEMBL9233990", "BDBM50321882"],
  },
  {
    primary_id: "D08FVH",
    names: ["4SCAR19/22 T Cells"],
  },
  {
    primary_id: "D08FVO",
    names: ["ISIS 102450"],
  },
  {
    primary_id: "D08FVR",
    names: ["PMID27019002-Compound-31b"],
  },
  {
    primary_id: "D08FVS",
    names: ["ISIS 19647"],
  },
  {
    primary_id: "D08FWE",
    names: [
      "3-(6-Amino-Purin-9-Yl)-Non-5-En-2-Ol",
      "CHEMBL81390",
      "BDBM50288072",
      "(Z)-(2S,3R)-3-(6-Amino-Purin-9-Yl)-Non-5-En-2-Ol",
    ],
  },
  {
    primary_id: "D08FWJ",
    names: ["GSK3377794"],
  },
  {
    primary_id: "D08FWK",
    names: ["Oxypurinol"],
  },
  {
    primary_id: "D08FWO",
    names: ["Lixte-1.2"],
  },
  {
    primary_id: "D08FWW",
    names: ["Gammaplex"],
  },
  {
    primary_id: "D08FXD",
    names: ["2-Isopropyl-9H-Carbazole", "CHEMBL1170460", "SCHEMBL10892550"],
  },
  {
    primary_id: "D08FXE",
    names: ["MD-920"],
  },
  {
    primary_id: "D08FXH",
    names: ["(+/-)-2-(4'-Propoxyphenyl)Thiomorpholine", "CHEMBL608141", "BDBM50307366", "AKOS022549334"],
  },
  {
    primary_id: "D08FXW",
    names: ["6-(3-Fluoropropyl)-4-Methylpyridin-2-Amine", "CHEMBL443862", "1146620-54-3", "SCHEMBL13040676"],
  },
  {
    primary_id: "D08FXZ",
    names: ["RP-525"],
  },
  {
    primary_id: "D08FYH",
    names: ["PF-04776548"],
  },
  {
    primary_id: "D08FYQ",
    names: ["KNT-63", "CHEMBL593980"],
  },
  {
    primary_id: "D08FZC",
    names: [
      "DIETHOXYFLOURESCEIN",
      "CHEMBL1257567",
      "Spiro(Isobenzofuran-1(3H),9'-(9H)Xanthen)-3-One, 3',6'-Diethoxy-",
      "21934-70-3",
      "Spiro[isobenzofuran-1(3H),9'-[9H]xanthen]-3-One, 3',6'-Diethoxy-",
      "AC1Q6MMX",
      "AC1L4PB7",
      "3',6'-Diethoxyfluorescein",
      "SCHEMBL6404797",
      "XAPFGEPPWZRGFN-UHFFFAOYSA-N",
      "BDBM50328442",
      "AKOS028111348",
    ],
  },
  {
    primary_id: "D08FZK",
    names: ["WR-203581"],
  },
  {
    primary_id: "D08FZN",
    names: ["6-N-Propyl -4-Trifluoromethylquinolin-2(1H)-One"],
  },
  {
    primary_id: "D08FZQ",
    names: ["E-EBU-DM", "132774-45-9", "136011-43-3", "E-EPU", "E-EPU-S"],
  },
  {
    primary_id: "D08FZR",
    names: ["2-Methoxy-4-(2-Methoxyphenylthio)Benzonitrile", "CHEMBL510145"],
  },
  {
    primary_id: "D08FZT",
    names: ["Corus 1030"],
  },
  {
    primary_id: "D08GAH",
    names: ["EV-075"],
  },
  {
    primary_id: "D08GBB",
    names: ["Valopicitabine Dihydrochloride"],
  },
  {
    primary_id: "D08GBR",
    names: ["PMID28092474-Compound-33g"],
  },
  {
    primary_id: "D08GBT",
    names: ["SAR-131675"],
  },
  {
    primary_id: "D08GDJ",
    names: ["ISOTHIAZOLIDINONE ANALOG"],
  },
  {
    primary_id: "D08GEN",
    names: ["LICOAGROCHACONE A"],
  },
  {
    primary_id: "D08GEX",
    names: ["TriGem"],
  },
  {
    primary_id: "D08GFG",
    names: [
      "Monoctanoin Component C",
      "DICAPRYLIN",
      "MONOCTANOIN COMPONENT C",
      "1069-87-0",
      "3-Hydroxypropane-1,2-Diyl Dioctanoate",
      "36354-80-0",
      "DiC8",
      "Octanoic Acid, 1-(Hydroxymethyl)-1,2-Ethanediyl Ester",
      "1,2-Dicapryloylglycerol",
      "1,2-Dioctanoyl-Rac-Glycerol",
      "1,2-Dioctanoin",
      "Octanoin, 1,2-Di-",
      "Glycerol 1,2-Dicaprylate",
      "AC1Q5YA4",
      "SCHEMBL825310",
      "CHEMBL24609",
      "AC1L1B82",
      "CTK4A4899",
      "ZQBULZYTDGUSSK-UHFFFAOYSA-N",
      "HSCI1_000084",
      "IN1353",
      "AKOS027383004",
      "ACM36354800",
      "(3-Hydroxy-2-Octanoyloxypropyl) Octanoate",
      "FT-0748382",
      "1-Hydroxy-3-(Octanoyloxy)Propan-2-Yl Octa",
    ],
  },
  {
    primary_id: "D08GFN",
    names: ["MVA-BN Breast"],
  },
  {
    primary_id: "D08GFY",
    names: [
      "Methyl Methylsulfinylmethyl Sulfide",
      "33577-16-1",
      "Methyl (Methylsulfinyl)Methyl Sulfide",
      "FAMSO",
      "Methanesulfinyl-Methylsulfanyl-Methane",
      "Methane, (Methylsulfinyl)(Methylthio)-",
      "Formaldehyde Dimethyl Mercaptal S-Oxide",
      "Methyl (Methylthio)Methyl Sulfoxide",
      "(Methylsulfinyl)(Methylthio)Methane",
      "Formaldehyde Methyl Mercaptal S-Oxide",
      "Sulfide, Methyl (Methylsulfinyl)Methyl",
      "Methyl Methylthiomethyl Sulfoxide",
      "Methyl Methylthiomethyl Sulphoxide",
      "NSC 181492",
      "(Methylthio)Dimethyl Sulfoxide",
      "OTKFCIVOVKCFHR-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D08GHB",
    names: [
      "Fluvastatin",
      "Canef",
      "Cranoc",
      "Fluindostatin",
      "Fluvas",
      "Fluvastatina",
      "Fluvastatine",
      "Fluvastatinum",
      "Lescol",
      "Lescol XL",
      "XU 62320",
      "Canef(TN)",
      "Fluvas (TN)",
      "Fluvastatin & Primycin",
      "Fluvastatin (INN)",
      "Fluvastatin [INN:BAN]",
      "Fluvastatina [INN-Spanish]",
      "Fluvastatine [INN-French]",
      "Fluvastatinum [INN-Latin]",
      "Lescol (TN)",
      "Vastin (TN)",
      "XU-62320",
      "(+)-(3R,5S)-Fluvastatin",
      "(-)-(3S,5R)-Fluvastatin",
      "(3R,5R,6E)-7-[3-(4-Fluorophenyl)-1-(Propan-2-Yl)-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3R,5R,6E)-7-[3-(4-Fluorophenyl)-1-Isopropyl-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3R,5S)-7-[3-(4-Fluorophenyl)-1-Propan-2-Ylindol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3R,5S,6E)-7-[3-(4-Fluorophenyl)-1-(1-Methylethyl)-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3R,5S,6E)-7-[3-(4-Fluorophenyl)-1-(Propan-2-Yl)-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3R,5S,6E)-7-[3-(4-Fluorophenyl)-1-Isopropyl-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3R,5S,6E)-Rel-7-[3-(4-Fluorophenyl)-1-(1-Methylethyl)-1H-Indol-2-Yl]-3,5-Dihydroxy-6-Heptenoic Acid",
      "(3S,5R,6E)-7-[3-(4-Fluorophenyl)-1-(Propan-2-Yl)-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3S,5R,6E)-7-[3-(4-Fluorophenyl)-1-Isopropyl-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(6E)-7-[3-(4-Fluorophenyl)-1-(Propan-2-Yl)-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(6E)-7-[3-(4-Fluorophenyl)-1-Isopropyl-1H-Indol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(E)-7-[3-(4-Fluorophenyl)-1-Propan-2-Ylindol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(E,3R,5S)-7-[3-(4-Fluorophenyl)-1-Propan-2-Ylindol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(E,3S,5R)-7-[3-(4-Fluorophenyl)-1-Propan-2-Ylindol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(Z,3R,5S)-7-[3-(4-Fluorophenyl)-1-Propan-2-Ylindol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "7-(3-(4-Fluorophenyl)-1-(1-Methylethyl)-1H-Indol-2-Yl)-3,5-Dihydroxy-6-Heptenoate",
      "7-[3-(4-Fluorophenyl)-1-Propan-2-Ylindol-2-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
    ],
  },
  {
    primary_id: "D08GHI",
    names: ["18F-AV-45 Dimer", "AV-45 Dimer"],
  },
  {
    primary_id: "D08GHX",
    names: ["TA-104"],
  },
  {
    primary_id: "D08GIG",
    names: ["AdoC(Aun)Arg6", "CHEMBL611121"],
  },
  {
    primary_id: "D08GIH",
    names: [
      "NGR-HTNF",
      "Arenegyr",
      "NGR-HTNF (Solid Tumors)",
      "Tumor Necrosis Factor Therapy (Recombinant Protein), MolMed",
      "CD13 TNF-Alpha Conjugate (Solid Tumors), MolMed",
      "NGR-HTNF (Solid Tumors), MolMed",
      "Vascular-Targeted TNF (NGR-TNF), MolMed SpA",
      "Vascular-Targeted TNF (Solid Tumors, Iv/Infusion/Biological Therapeutic), MolMed",
    ],
  },
  {
    primary_id: "D08GIM",
    names: ["Elisidepsin"],
  },
  {
    primary_id: "D08GJH",
    names: ["Blonanserin"],
  },
  {
    primary_id: "D08GJO",
    names: [
      "Benorilate",
      "5003-48-5",
      "BENORYLATE",
      "4-Acetamidophenyl 2-Acetoxybenzoate",
      "Benoral",
      "Salipran",
      "Benortan",
      "Fenasprate",
      "Fenasparate",
      "Aspirin Acetaminophen Ester",
      "Benorilato",
      "Benorilato [Spanish]",
      "P-Acetamidophenyl Acetylsalicylate",
      "Benorilatum [INN-Latin]",
      "Benorilato [INN-Spanish]",
      "UNII-W1QX9DV96G",
      "P-N-Acetylaminophenylacetylsalicylate",
      "TO 125",
      "WIN 11450",
      "4'-(Acetamido)Phenyl-2-Acetoxybenzoate",
      "2-Acetoxy-4'-(Acetamino)Phenylbenzoate",
      "CCRIS 1739",
      "Benzoic Acid, 2-(Acetyloxy)-, 4-(Acetylamino)Phenyl Ester",
      "4-Acetamidophenyl Salicylate Acet",
    ],
  },
  {
    primary_id: "D08GJS",
    names: ["3-(2-Dimethylamino-Propyl)-1H-Indol-4-Ol", "CHEMBL194202"],
  },
  {
    primary_id: "D08GKD",
    names: ["AZD-2315"],
  },
  {
    primary_id: "D08GKL",
    names: [
      "6-Iodo-4'-Methylaminoflavone",
      "871101-30-3",
      "CHEMBL224643",
      "6-Iodo-2-(4-Methylaminophenyl)-Chromen-4-One",
      "SCHEMBL4991516",
      "6-Iodo-4''-Methylaminoflavone",
      "DTXSID20469771",
      "QIZWRTNISFPGTO-UHFFFAOYSA-N",
      "ZINC13675690",
      "BDBM50176896",
      "KB-248886",
    ],
  },
  {
    primary_id: "D08GKT",
    names: ["KF24345"],
  },
  {
    primary_id: "D08GKU",
    names: ["BriaVax"],
  },
  {
    primary_id: "D08GLH",
    names: ["DBP-118", "Anti-Emetic (Oradisc), BioProgress/Uluru", "Anti-Emetic (Oradisc), Meldex/Uluru"],
  },
  {
    primary_id: "D08GLJ",
    names: [
      "1,3,8-Trimethyl-3,7-Dihydro-Purine-2,6-Dione",
      "830-65-9",
      "8-Methyltheophylline",
      "1,3,8-Trimethyl-1H-Purine-2,6(3H,7H)-Dione",
      "Theophylline, 8-Methyl-",
      "Xanthine, 1,3,8-Trimethyl-",
      "1,3,8-Trimethyl-2,6-Dioxopurine",
      "1,3,8-Trimethylxanthine",
      "NSC 14289",
      "1,3,8-Trimethyl-3,9-Dihydro-1h-Purine-2,6-Dione",
      "CHEMBL89062",
      "3,7-Dihydro-1,3,8-Trimethyl-1H-Purine-2,6-Dione",
      "1,3,8-Trimethyl-2,3,6,9-Tetrahydro-1H-Purine-2,6-Dione",
      "8-Methyltheophyllin",
      "NSC14289",
      "8-Methyltheophyline",
      "AC1Q6LAN",
      "AC1L3TFC",
      "Xanthine,3,8-Trimethyl-",
      "SCHEMBL461786",
    ],
  },
  {
    primary_id: "D08GLU",
    names: ["4'-Amino-4-(1,1-Dimethyl-Heptyl)-Biphenyl-2-Ol", "CHEMBL391136", "BDBM50213140"],
  },
  {
    primary_id: "D08GLX",
    names: ["MDT-2004"],
  },
  {
    primary_id: "D08GMO",
    names: ["3-(4-Aminophenyl)Quinoxaline-5-Carboxamide", "CHEMBL443077", "Quinoxaline Analogue, 3f", "SCHEMBL7112515"],
  },
  {
    primary_id: "D08GNA",
    names: ["SKF-106610"],
  },
  {
    primary_id: "D08GNX",
    names: ["FPL-14294"],
  },
  {
    primary_id: "D08GNY",
    names: ["Lactococcus Lactis Anti-IL-27"],
  },
  {
    primary_id: "D08GOT",
    names: [
      "2-(2-(2-Chlorophenylamino)Phenyl)Acetic Acid",
      "CHEMBL77823",
      "488862-17-5",
      "SCHEMBL6243911",
      "CTK1D1110",
      "DTXSID00548437",
      "RXRLPBIKRNGLJD-UHFFFAOYSA-N",
      "ZINC26400000",
      "BDBM50295275",
      "AKOS030583290",
      "[2-(2-Chloroanilino)Phenyl]acetic Acid",
      "2-(2-Chlorophenylamino)Benzeneacetic Acid",
      "2-[(2-Chlorophenyl)Amino]phenylacetic Acid",
      "Benzeneacetic Acid, 2-[(Chlorophenyl)Amino]-",
      "[2-(2-Chloro-Phenylamino)-Phenyl]-Acetic Acid",
    ],
  },
  {
    primary_id: "D08GPU",
    names: [
      "5-Phenethyl-Quinazoline-2,4-Diamine",
      "CHEMBL341703",
      "5-Phenethylquinazoline-2,4-Diamine",
      "AC1LALFL",
      "CTK7E0789",
      "BDBM50036483",
      "ZINC13740535",
      "5-(2-Phenylethyl)Quinazoline-2,4-Diamine",
      "5-(2-Phenylethyl)-2,4-Quinazolinediamine",
      "2,4-Diamino-5-(2-Phenylethyl)-Quinazoline",
      "(2-Amino-5-Phenethyl-Quinazolin-4-Yl)-Amine",
      "2,4-Quinazolinediamine, 5-(2-Phenylethyl)-",
    ],
  },
  {
    primary_id: "D08GQK",
    names: ["WS-75624B"],
  },
  {
    primary_id: "D08GQL",
    names: ["PMID28092474-Compound-33j"],
  },
  {
    primary_id: "D08GRF",
    names: [
      "LG-1550",
      "ALRT-1550",
      "ALRT-550",
      "LGD-1550",
      "Pre-Registered",
      "(2E,4E,6E)-7-(3,5-Di-Tert-Butylphenyl)-3-Methylocta-2,4,6-Trienoic Acid",
    ],
  },
  {
    primary_id: "D08GRY",
    names: [
      "DUP 697",
      "Dup 697",
      "Dup-697",
      "88149-94-4",
      "MRWLZPOFPA",
      "UNII-MRWLZPOFPA",
      "CHEMBL42485",
      "CHEBI:4720",
      "5-Bromo-2-(4-Fluorophenyl)-3-(4-Methysulfonylphenyl)Thiophene",
      "5-Bromo-2-(4-Fluorophenyl)-3-(4-Methylsulfonylphenyl)Thiophene",
      "CPD000466299",
      "Thiophene, 5-Bromo-2-(4-Fluorophenyl)-3-(4-(Methylsulfonyl)Phenyl)-",
      "Thiophene, 5-Bromo-2-(4-Fluorophenyl)-3-[4-(Methylsulfonyl)Phenyl]- [CAS]",
      "5-Bromo-2-(4-Fluorophenyl)-3-[4-(Methylsulfonyl)Phenyl]thiophene",
      "5-Bromo-2-(4-Fluorophenyl)-3-(4-(Methylsulfonyl)Phenyl)Thiophene",
      "SMR000466299",
    ],
  },
  {
    primary_id: "D08GSC",
    names: ["KPE-02001003"],
  },
  {
    primary_id: "D08GSF",
    names: ["Proflavine"],
  },
  {
    primary_id: "D08GSM",
    names: ["AG-014"],
  },
  {
    primary_id: "D08GSN",
    names: ["PMID26666989-Compound-Figure9middle12"],
  },
  {
    primary_id: "D08GSX",
    names: ["MOR-104"],
  },
  {
    primary_id: "D08GTE",
    names: ["IGF-1R Inhibitors"],
  },
  {
    primary_id: "D08GTH",
    names: ["WFwLL-NH2", "CHEMBL450217", "BDBM50260269"],
  },
  {
    primary_id: "D08GUX",
    names: ["TD-4001"],
  },
  {
    primary_id: "D08GUZ",
    names: ["Somatrem"],
  },
  {
    primary_id: "D08GVB",
    names: ["AMR-001"],
  },
  {
    primary_id: "D08GWQ",
    names: ["3-Isopropyl-4-(Phenylthio)Naphthalene-1,2-Dione", "CHEMBL205381"],
  },
  {
    primary_id: "D08GWS",
    names: ["Tamoxifen Isopropyl Bromide"],
  },
  {
    primary_id: "D08GXR",
    names: ["PMID29338548-Compound-18"],
  },
  {
    primary_id: "D08GYO",
    names: ["Procarbazine"],
  },
  {
    primary_id: "D08GZA",
    names: ["Epileptic Therapeutics"],
  },
  {
    primary_id: "D08GZE",
    names: ["AbGn-107"],
  },
  {
    primary_id: "D08GZJ",
    names: ["MK-2918", "CGRP Antagonists (Migraine)", "CGRP Antagonists (Migraine), Merck & Co"],
  },
  {
    primary_id: "D08GZK",
    names: ["EXIGUAMINE B", "CHEMBL1221412"],
  },
  {
    primary_id: "D08GZX",
    names: [
      "PF-613322",
      "Beta 2 Adrenoceptor Agonists (COPD)",
      "UK-503590",
      "Beta 2 Adrenoceptor Agonists (COPD),Pfizer",
    ],
  },
  {
    primary_id: "D08HAM",
    names: ["5-Bromo-4,9-Dihydro-3H-Beta-Carboline", "CHEMBL267599", "BDBM50136504"],
  },
  {
    primary_id: "D08HBF",
    names: ["PMID26004420-Compound-U2014S0288042A"],
  },
  {
    primary_id: "D08HBH",
    names: [
      "N-(2,6-Diphenylpyrimidin-4-Yl)Butyramide",
      "CHEMBL222384",
      "820961-42-0",
      "SCHEMBL4827120",
      "CTK3E2661",
      "DTXSID60463536",
      "BDBM50157659",
      "AKOS030569388",
      "Butanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D08HBN",
    names: ["Kallidin"],
  },
  {
    primary_id: "D08HBW",
    names: ["Peptide Analog 64"],
  },
  {
    primary_id: "D08HCF",
    names: ["2-(3-Chloro-4-Hydroxy-Phenyl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D08HCY",
    names: ["4-(1H-Indol-6-Ylamino)-5-Styrylnicotinonitrile", "CHEMBL578348", "SCHEMBL2935272"],
  },
  {
    primary_id: "D08HDD",
    names: ["RP-58802B"],
  },
  {
    primary_id: "D08HDR",
    names: ["Alkaloid Staurosporin"],
  },
  {
    primary_id: "D08HDY",
    names: [
      "Pivmecillinam",
      "Coactabs",
      "PMPC",
      "Pivamdinocillin",
      "Pivmecilinamo",
      "Pivmecillinamum",
      "Selexid",
      "Amdinocillin Pivoxil",
      "Amdinocillin Pivoxil [USAN]",
      "Mecillinam Pivaloyl Ester",
      "Amdinocillin Pivoxil (USAN)",
      "Amdinocillin, Pivaloyloxymethyl Ester",
      "Coactabs (TN)",
      "Penomax (TN)",
      "Pivmecilinamo [INN-Spanish]",
      "Pivmecillinam (INN)",
      "Pivmecillinamum [INN-Latin]",
      "Ro 10-9071",
      "Selexid (TN)",
      "[(2,2-Dimethylpropanoyl)Oxy]methyl 6beta-[(Azepan-1-Ylmethylidene)Amino]-2,2-Dimethylpenam-3alpha-Carboxylate",
      "[(2,2-Dimethylpropanoyl)Oxy]methyl (2S,5R,6R)-6-[(Azepan-1-Ylmethylidene)Amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
      "[(2,2-Dimethylpropanoyl)Oxy]methyl(2s,5r,6r)-6-{[(E)-Azepan-1-Ylmethylidene]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
      "Hydroxymethyl (2S,5R,6R)-6-(((Hexahydro-1H-Azepin-1-Yl)Methylene)Amino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylate Pivalate (Ester)",
      "2,2-Dimethylpropanoyloxymethyl (2S,5R,6R)-6-(Azepan-1-Ylmethylideneamino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
      "2,2-Dimethylpropanoyloxymethyl (5R,6R)-6-(Azepan-1-Ylmethylideneamino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylate",
    ],
  },
  {
    primary_id: "D08HES",
    names: ["MC-02331"],
  },
  {
    primary_id: "D08HFA",
    names: ["Y-27152"],
  },
  {
    primary_id: "D08HFG",
    names: ["PMID20873740C18"],
  },
  {
    primary_id: "D08HFH",
    names: ["Fluoroquinolone"],
  },
  {
    primary_id: "D08HFI",
    names: ["Cystathionine"],
  },
  {
    primary_id: "D08HFK",
    names: [
      "5-Chloro-N-Phenylbenzo[d]oxazol-2-Amine",
      "CHEMBL1269620",
      "79558-94-4",
      "2-Benzoxazolamine, 5-Chloro-N-Phenyl-",
      "AC1NKL9S",
      "5-Chloro-N-Phenyl-1,3-Benzoxazol-2-Amine",
      "SCHEMBL3474865",
      "CTK2G4049",
      "DTXSID50406795",
      "BDBM50329393",
      "ZINC33559155",
      "N-Phenyl-5-Chlorobenzoxazole-2-Amine",
      "VU0404023-1",
      "J3.559.656I",
    ],
  },
  {
    primary_id: "D08HFW",
    names: ["TAK-901"],
  },
  {
    primary_id: "D08HGK",
    names: ["N-Benzyl-2-(1H-Indol-3-Yl)-2-Oxo-Acetamide"],
  },
  {
    primary_id: "D08HGR",
    names: ["LLL-3348", "Desoris", "LL-3348", "Herbal Anti-Inflammatory (Psoriasis), Lupin"],
  },
  {
    primary_id: "D08HGY",
    names: ["Naronapride"],
  },
  {
    primary_id: "D08HHA",
    names: [
      "1-(3-Fluorobenzyl)-1H-Imidazole",
      "109485-43-0",
      "1H-Imidazole, 1-[(3-Fluorophenyl)Methyl]-",
      "CHEMBL598390",
      "ACMC-20mcbr",
      "1-(3-Fluorobenzyl)Imidazole",
      "N-(3-Fluorobenzyl)-Imidazole",
      "SCHEMBL9747422",
      "CTK0D5768",
      "DTXSID10601148",
      "CJNXVRZKOSWWND-UHFFFAOYSA-N",
      "BDBM50307214",
      "ZINC41391477",
      "AKOS009098433",
    ],
  },
  {
    primary_id: "D08HHT",
    names: [
      "Procervix",
      "Cervical Cancer Vaccine, BT Pharma",
      "CyaAE5-HPV16E7",
      "HPV E7 Cervical Cancer Vaccine (CyaA Protein Vector)",
      "HPV E7 Cervical Cancer Vaccine (CyaA Protein Vector), Genticel",
    ],
  },
  {
    primary_id: "D08HIH",
    names: ["ERB-196"],
  },
  {
    primary_id: "D08HJI",
    names: ["WT2527"],
  },
  {
    primary_id: "D08HJT",
    names: ["Peplomycin"],
  },
  {
    primary_id: "D08HKC",
    names: ["7-Methyl-2-P-Tolyl-4H-Chromene-4-Thione", "CHEMBL592642"],
  },
  {
    primary_id: "D08HKK",
    names: ["CD10-CART"],
  },
  {
    primary_id: "D08HKT",
    names: ["BEMORADAN"],
  },
  {
    primary_id: "D08HMK",
    names: ["ETC-276"],
  },
  {
    primary_id: "D08HNA",
    names: [
      "Buccal SMT-D001",
      "Buccal DL-06001",
      "Buccal Xerostomia-Inducing Drug Combination (Sialorrheal), Summit/Orient",
    ],
  },
  {
    primary_id: "D08HNJ",
    names: ["N-(4-Phenylbutyl)-1H-Indole-2-Carboxamide", "CHEMBL459543"],
  },
  {
    primary_id: "D08HOJ",
    names: [
      "1,2,3,4-Tetrahydro-Naphthalen-2-Ylamine",
      "1,2,3,4-Tetrahydronaphthalen-2-Amine",
      "2954-50-9",
      "1,2,3,4-Tetrahydro-2-Naphthylamine",
      "2-Aminotetralin",
      "2-Naphthalenamine, 1,2,3,4-Tetrahydro-",
      "1,2,3,4-Tetrahydro-2-Naphthalenamine",
      "CHEMBL30294",
      "LCGFVWKNXLRFIF-UHFFFAOYSA-N",
      "617700-25-1",
      "2-1,2,3,4-Tetrahydronaphthylamine",
      "2-Amino-Tetralin",
      "Tetralin-2-Amine",
      "ACMC-20ac6z",
      "AC1Q53DS",
      "AC1L1S2C",
      "SCHEMBL42952",
      ".beta.-Tetrahydronaphthylamine",
      "BDBM23981",
      "Tetrahydronaphthalen-2-Amine, 3a",
      "MolPort-001-779-767",
    ],
  },
  {
    primary_id: "D08HOK",
    names: ["PMID21444206C3a"],
  },
  {
    primary_id: "D08HOM",
    names: ["ABT-348"],
  },
  {
    primary_id: "D08HOV",
    names: ["PMID25399762-Compound-Figure3-Aspeverin"],
  },
  {
    primary_id: "D08HPL",
    names: ["3S,4R-293B"],
  },
  {
    primary_id: "D08HPR",
    names: ["PMID29865878-Compound-44"],
  },
  {
    primary_id: "D08HPU",
    names: [
      "3-(3-Methyl-3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "Dihydronaphthalene 8",
      "AC1O70HF",
      "SCHEMBL4505804",
      "BDBM8894",
    ],
  },
  {
    primary_id: "D08HPZ",
    names: [
      "Tocilizumab",
      "Actemra",
      "Actemra (TN)",
      "Tocilizumab (Genetical Recombination)",
      "Tocilizumab (USAN/INN)",
      "Tocilizumab (Genetical Recombination) (JAN)",
    ],
  },
  {
    primary_id: "D08HQB",
    names: ["(Z)-5-Octylidene-2-Thioxothiazolidin-4-One"],
  },
  {
    primary_id: "D08HQI",
    names: ["ISIS-EIF4E"],
  },
  {
    primary_id: "D08HQK",
    names: [
      "Tetracaine",
      "Amethocaine",
      "Anetain",
      "Contralgin",
      "Dicain",
      "Dicaine",
      "Dikain",
      "Fissucain",
      "Intercain",
      "Laudocaine",
      "Medicaine",
      "Meethobalm",
      "Metraspray",
      "Mucaesthin",
      "Niphanoid",
      "Pontocaine",
      "Rexocaine",
      "Tetracaina",
      "Tetracainum",
      "Tetrakain",
      "Uromucaesthin",
      "Tetrakain [Czech]",
      "Amethocaine (TN)",
      "Diaethylaminoaethanol Ester Der P-Butylaminobenzoesaeure;Diaethylaminoaethanol Ester Der P-Butylaminobenzoesaeure [German]",
      "Medihaler-Tetracaine",
      "Pontocaine (TN)",
      "Tetracaina [INN-Spanish]",
      "Tetracaine [USAN:INN]",
      "Tetracainum [INN-Latin]",
      "Dimethylaminoethyl P-Butyl-Aminobenzoate",
      "Tetracaine (USP/INN)",
      "P-Butylaminobenzoyl-2-Dimethylaminoethanol",
      "BENZOIC ACID,4-BUTYLAMINO,2-DIMETHYLAMINOETHYL ESTER PANTOCAIN BASE",
      "P-(Butylamino)Benzoic Acid, 2-(Dimethylamino)Ethyl Ester",
      "BENZOIC ACID, P-(BUTYLAMINO)-, 2-(DIMETHYLAMINO)ETHYL ESTER",
      "Benzoic Acid, 4-(Butylamino)-, 2-(Dimethylamino)Ethyl Ester",
      "2-(Dimethylamino)Ethyl 4-(Butylamino)Benzoate",
      "2-(Dimethylamino)Ethyl P-(Butylamino)Benzoate",
      "2-Dimethylaminoethylester Kyseliny P-Butylaminobenzoove",
      "2-Dimethylaminoethylester Kyseliny P-Butylaminobenzoove [Czech]",
      "2-Dimethylaminoethyl 4-(Butylamino)Benzoate",
      "4-(Butylamino)Benzoic Acid 2-(Dimethylamino)Ethyl Ester",
      "4-[Butylamino]benzoic Acid-2-[dimethylamino]ethyl Ester",
    ],
  },
  {
    primary_id: "D08HQT",
    names: ["ZK-005"],
  },
  {
    primary_id: "D08HRA",
    names: ["(E)-2-(4-(Methylsulfonyl)Styryl)Thiophene", "CHEMBL1288781"],
  },
  {
    primary_id: "D08HRD",
    names: ["Plasmodium Vivax Vaccine"],
  },
  {
    primary_id: "D08HRJ",
    names: [
      "Cyclizine",
      "Ciclizina",
      "Cyclizinum",
      "Emoquil",
      "Marezine",
      "Nautazine",
      "Valoid",
      "Wellcome",
      "Cyclizine Chloride",
      "Cyclizine Hydrochloride",
      "Marezine Hydrochloride",
      "BW 47-83",
      "Ciclizina [INN-Spanish]",
      "Cyclizine (INN)",
      "Cyclizinum [INN-Latin]",
      "Emoquil (TN)",
      "Marezine (TN)",
      "Marzine (TN)",
      "Ne-Devomit",
      "Neo-Devomit",
      "Reis-Fit",
      "Valoid (TN)",
      "Wellcome Preparation 47-83",
      "Wellcome Prepn 47-83",
      "Cyclizine [INN:BAN:DCF]",
      "Wellcome Prepn. 47-83",
      "N-Benzhydryl-N'-Methylpiperazine",
      "N-Methyl-N'-Benzhydrylpiperazine",
      "N-Methyl-N'-Benzyhydrylpiperazine",
      "(+-)-1-Diphenylmethyl-4-Methylpiperazine",
      "(-)-1-Diphenylmethyl-4-Methylpiperazine",
      "(N-Benzhydryl)(N'-Methyl)Diethylenediamine",
      "1-(Diphenylmethyl)-4-Methylpiperazine",
      "1-Benzhydryl-4-Methylpiperazin",
      "1-Benzhydryl-4-Methylpiperazine",
      "1-Diphenylmethyl-4-Methylpiperazine",
    ],
  },
  {
    primary_id: "D08HSQ",
    names: ["C[Glu22-Lys26][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D08HSS",
    names: ["LY3300054"],
  },
  {
    primary_id: "D08HTF",
    names: ["JNJ-1930942"],
  },
  {
    primary_id: "D08HTJ",
    names: [
      "2-Methoxyapomorphine",
      "CHEMBL53445",
      "126874-79-1",
      "(6ar)-2-Methoxy-6-Methyl-5,6,6a,7-Tetrahydro-4h-Dibenzo[de,g]quinoline-10,11-Diol",
      "SCHEMBL8097389",
      "AC1L503G",
      "(R)-(-)-2-Methoxyapomorphine",
      "CTK4B5400",
      "4H-Dibenzo[de,g]quinoline-10,11-Diol,5,6,6a,7-Tetrahydro-2-Methoxy-6-Methyl-, (6aR)-",
      "2-Methoxy-6-Methyl-5,6,6a,7-Tetrahydro-4H-Dibenzo[de,g]quinoline-10,11-Diol",
      "AC1Q5802",
      "BDBM50010697",
      "(R)-2-Methoxy-6-Methyl-5,6,6a,7-Tetrahydro-4H-Dibenzo[de,g]quinoline-10,11-Diol",
      "(6aR)-5,6,6abeta,7-Tetrahydro-2-Methoxy-6-Methyl 4H-Dibenzo",
    ],
  },
  {
    primary_id: "D08HTR",
    names: ["Tricyclic Isoxazoloquinazoline Derivative 1"],
  },
  {
    primary_id: "D08HTW",
    names: ["Drug 7684380", "7684380"],
  },
  {
    primary_id: "D08HUB",
    names: ["ANG-2004", "ANG-Leptin", "EPiC-Peptide Conjugated Leptin (Obesity), AngioChem"],
  },
  {
    primary_id: "D08HUC",
    names: [
      "Isoetharine",
      "Bronkosol",
      "Dilabron",
      "Etyprenaline",
      "Etyprenalinum",
      "Isoetarin",
      "Isoetarina",
      "Isoetarine",
      "Isoetarinum",
      "Neoisuprel",
      "Isoetharine Hcl",
      "Win 3406",
      "Bronkometer (TN)",
      "Bronkosol (TN)",
      "Dey-Lute Isoetharine",
      "Isoetarina [INN-Spanish]",
      "Isoetarine (INN)",
      "Isoetarinum [INN-Latin]",
      "Isoetharine (USP)",
      "Isoetharine HCl S/F",
      "Isoetharine [USAN:BAN]",
      "3,4-Dihydroxy-Alpha-(1-(Isopropylamino)Propyl)Benzyl Alcohol",
      "4-[1-Hydroxy-2-(Propan-2-Ylamino)Butyl]benzene-1,2-Diol",
    ],
  },
  {
    primary_id: "D08HUJ",
    names: ["PEG-TGF Peptantagonist"],
  },
  {
    primary_id: "D08HUX",
    names: ["N-(3,3-Diphenyl-Propyl)-Nicotinamide"],
  },
  {
    primary_id: "D08HVD",
    names: [
      "NRLU-10",
      "Avicidin",
      "Nofetumomab",
      "Verluma",
      "Versaluma",
      "OncoTrac NSCLC",
      "OncoTrac SCLC",
      "Nofetumomab)",
      "Pretarget Technology (Avicidin)",
      "Technetium-99m Nofetumomab",
      "Technetium-99m NRLU-10",
      "99mTc-NRLU-10",
      "99mTc-Nofetumomab",
    ],
  },
  {
    primary_id: "D08HVE",
    names: ["Dimethyl Sulfoxide"],
  },
  {
    primary_id: "D08HVG",
    names: [
      "BAL-8557",
      "Drochloride",
      "Monohydrochloride",
      "RO-0098557",
      "N-Methylglycine [2-[N-[1-[1-[(2R,3R)-3-[4-(4-Cyanophenyl)-2-Thiazolyl]-2-(2,5-Difluorophenyl)-2-Hydroxybutyl]-1H-1,2,4-Triazolium-4-Yl]ethoxy]carbonyl]-N-Methylamino]-3-Pyridinyl]methyl Ester Chloride",
      "1-[3(R)-[4-(4-Cyanophenyl)Thiazol-2-Yl]-2(R)-(2,5-Difluorophenyl)-2-Hydroxybutyl]-4-[1-[N-Methyl-N-[3-[2-(Methylamino)Acetoxymethyl]pyridin-2-Yl]carbamoyloxy]ethyl]-1H-1,2,4-Triazol-4-Ium Chloride, Hy",
    ],
  },
  {
    primary_id: "D08HVR",
    names: ["Levodopa"],
  },
  {
    primary_id: "D08HWV",
    names: ["BGB-A317"],
  },
  {
    primary_id: "D08HXG",
    names: ["PMX-60056"],
  },
  {
    primary_id: "D08HYS",
    names: ["PMID25514969-Compound-10"],
  },
  {
    primary_id: "D08HYX",
    names: ["4-Hex-3-Ynyl-1H-Imidazole", "CHEMBL14721", "SCHEMBL7627737", "4-(3-Hexynyl)-1H-Imidazole", "BDBM50070219"],
  },
  {
    primary_id: "D08HZC",
    names: [
      "Penicillamine",
      "D-Penicillamine",
      "52-67-5",
      "Cuprimine",
      "D-(-)-Penicillamine",
      "3-Mercapto-D-Valine",
      "Depen",
      "Cuprenil",
      "D-Penamine",
      "(-)-Penicillamine",
      "(2S)-2-Amino-3-Methyl-3-Sulfanylbutanoic Acid",
      "D-Mercaptovaline",
      "Mercaptovaline",
      "Perdolat",
      "Penicillamin",
      "Pendramine",
      "Kuprenil",
      "Depamine",
      "Mercaptyl",
      "Trolovol",
      "Metalcaptase",
      "Artamine",
      "Cupripen",
      "(S)-3,3-Dimethylcysteine",
      "D-Valine, 3-Mercapto-",
      "Penicillaminum",
      "Penicilamina",
      "Sufirtan",
      "Beta-Thiovaline",
      "Dimethylcysteine",
      "D-Beta,beta-Dimethylcysteine",
      "D-3-Mercaptovaline",
      "Beta,beta-Dimethylcysteine",
      "Penicillamina",
      "Penicilllamine",
      "Sufortan",
      "Copper Penicillaminate",
      "D Penicillamine",
      "Penicillamina [DCIT]",
      "Reduced Penicillamine",
      "D 3 Mercaptovaline",
      "TBB068824",
      "Beta,beta Dimethylcysteine",
      "Cuprimine (TN)",
      "D-Penicilamine",
      "D-Penicyllamine",
      "Depen (TN)",
      "P-1280",
      "Penicilamina [INN-Spanish]",
      "Penicillaminate, Copper",
      "Penicillaminum [INN-Latin]",
      "Reduced D-Penicillamine",
      "D,3-Mercaptovaline",
      "D-Beta-Mercaptovaline",
      "Distamine (*Hydrochloride*)",
      "Metalcaptase (*Hydrochloride*)",
      "Penicillamine (JAN/USP/INN)",
      "Penicillamine [USAN:INN:BAN:JAN]",
      "Alpha-Amino-Beta-Methyl-Beta-Mercaptobutyric Acid",
      "D-(-)-2-Amino-3-Mercapto-3-Methylbutanoic Acid",
      "(2S)-2-Amino-3-Methyl-3-Sulfanyl-Butanoic Acid",
      "(D)-PENICILLAMINE",
      "(S)-Penicillamin",
      "(S)-Penicillamine",
      "2-Amino-3-Mercapto-3-Methylbutanoic Acid",
      "3,3-Dimethyl-D-Cysteine",
      "3-Mercaptovaline",
      "3-Sulfanyl-D-Valine",
    ],
  },
  {
    primary_id: "D08HZD",
    names: ["N~3~-(3-PYRIDIN-3-YLBENZYL)PYRIDINE-2,3-DIAMINE"],
  },
  {
    primary_id: "D08HZL",
    names: [
      "Trimeprazine",
      "Alimemazina",
      "Alimemazine",
      "Alimemazinum",
      "Alimezine",
      "Isobutrazine",
      "Methylpromazine",
      "Oxomemazin",
      "Repetin",
      "Teralen",
      "Trimeperazine",
      "Trimeprazine Tartrat",
      "Bayer 1219",
      "Alimemazina [INN-Spanish]",
      "Alimemazine (INN)",
      "Alimemazine [INN:BAN]",
      "Alimemazinum [INN-Latin]",
      "Dl-Trimeprazine",
      "Nedeltran (TN)",
      "Oxomemazine [DCF:INN]",
      "Panectyl (TN)",
      "Repeltin (TN)",
      "Temaril (TN)",
      "Therafene (TN)",
      "Theralen (TN)",
      "Theralene (TN)",
      "Trimeprazine (BAN)",
      "Vallergan (TN)",
      "Vanectyl (TN)",
      "Trimeprazine 5,5-Dioxide",
      "Trimeprazine Hemi-(+)-Tartrate",
      "N,N,beta-Trimethyl-10H-Phenothiazine-10-Propanamine",
      "N,N,2-Trimethyl-3-Phenothiazin-10-Ylpropan-1-Amine",
      "N,N,2-Trimethyl-3-(10H-Phenothiazin-10-Yl)Propan-1-Amine",
      "(+-)-Alimemazine",
      "(+-)-Trimeprazine",
      "10-(2-Methyl-3-Dimethylaminopropyl)-Phenothiazine",
      "10-(3-(Dimethylamino)-2-Methylpropyl)Phenothiazine",
      "10-(3-Dimethylamino-2-Methylpropyl)Phenothiazine",
    ],
  },
  {
    primary_id: "D08HZN",
    names: ["ABC-4"],
  },
  {
    primary_id: "D08IAK",
    names: ["DPPA-1"],
  },
  {
    primary_id: "D08IAQ",
    names: ["ARC-AAT"],
  },
  {
    primary_id: "D08IBD",
    names: [
      "N-(3-Chloro-Phenyl)-2-Cyclohexyl-Acetamide",
      "CHEMBL192470",
      "SCHEMBL12931994",
      "ZINC13644475",
      "BDBM50167059",
    ],
  },
  {
    primary_id: "D08IBR",
    names: ["ISIS 103619"],
  },
  {
    primary_id: "D08IBS",
    names: [
      "Adenine",
      "ADE",
      "ANE",
      "Adenin",
      "Adeninimine",
      "Adenine [JAN]",
      "Pedatisectine B",
      "AD2",
      "Vitamin B 4",
      "Vitamin B4",
      "ALBB-005925",
      "Adenine (8CI)",
      "Adenine-Ring",
      "B 4, Vitamin",
      "Leuco-4",
      "Leucon (TN)",
      "Purine, 6",
      "USAF CB-18",
      "Vitamin-B4",
      "Adenine (JAN/USP)",
      "Adenine-8-14C",
      "1,6-Dihydro-6-Imnopurine",
      "1,6-Dihydro-6-Iminopurine",
      "1H-Purin-6-Amine",
      "1H-Purin-6-Amine (9CI)",
      "1H-Purine, 6-Amino",
      "2'-DEOXY-ADENOSINE-3'-5'-DIPHOSPHATE",
      "3,6-Dihydro-6-Iminopurine",
      "4, Vitamin B",
      "6-Amino-1H-Purine",
      "6-Amino-3H-Purine",
      "6-Amino-7H-Purine",
      "6-Amino-9H-Purine",
      "6-Aminopurine",
      "7H-Purin-6-Amine",
      "9H-Purin-6-Amine",
    ],
  },
  {
    primary_id: "D08ICI",
    names: ["N-Hydroxy-3-(6-Methoxy-2-Oxo-2H-Chromen-3-Yl)", "CHEMBL402990"],
  },
  {
    primary_id: "D08ICT",
    names: ["PMID25666693-Compound-13"],
  },
  {
    primary_id: "D08ICY",
    names: ["N-(4-Ethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093042", "BDBM50314139"],
  },
  {
    primary_id: "D08IDB",
    names: [
      "HuA33",
      "CDP-833",
      "A33-Radionuclides, American Cyanamid",
      "A33-Radionuclides, Celltech",
      "HuA33, Ludwig Institute For Cancer Research/Life Science Pharmaceuticals",
    ],
  },
  {
    primary_id: "D08IDC",
    names: [
      "3-Methoxy-5-(6-Methoxynaphthalen-2-Yl)Pyridine",
      "CHEMBL447591",
      "SCHEMBL3004402",
      "SEXSHORPOJSDHK-UHFFFAOYSA-N",
      "BDBM50272250",
      "ZINC40979781",
    ],
  },
  {
    primary_id: "D08IDJ",
    names: [
      "3-Phenyl-9H-Indeno[1,2-E][1,2,4]triazin-9-One",
      "CHEMBL399620",
      "Aza-Heterocyclic Derivative, 4a",
      "BDBM19181",
      "AC1O4770",
      "3-Phenylindeno[1,2-E][1,2,4]triazin-9-One",
    ],
  },
  {
    primary_id: "D08IDM",
    names: ["ISIS 109111"],
  },
  {
    primary_id: "D08IDQ",
    names: ["BILA-2157BS"],
  },
  {
    primary_id: "D08IEE",
    names: ["3-Hydroxy-Piperidine-4-Carboxylic Acid", "CHEMBL16132", "BDBM50223877"],
  },
  {
    primary_id: "D08IFG",
    names: [
      "Interferon-Alpha Dimers",
      "A2b-457",
      "IMP-413",
      "IMP-362, IBC",
      "PEG-Alpha2b-362",
      "PEG-Alpha2b-413",
      "Interferon-Alpha Dimers (Cancer/Viral Infection)",
      "Interferon-Alpha Dimers (Cancer/Viral Infection), IBC",
      "20-C2-2b",
      "22-2b",
    ],
  },
  {
    primary_id: "D08IFL",
    names: [
      "Meclofenamic Acid",
      "Arquel",
      "Meclofenamate",
      "Acide Meclofenamique",
      "Acido Meclofenamico",
      "Acidum Meclofenamicum",
      "Meclophenamic Acid",
      "CL 583",
      "INF 4668",
      "Acide Meclofenamique [INN-French]",
      "Acido Meclofenamico [INN-Spanish]",
      "Acidum Meclofenamicum [INN-Latin]",
      "INF-4668",
      "Meclomen (Free Acid)",
      "Meclofenamic Acid (USAN/INN)",
      "Meclofenamic Acid [USAN:INN:BAN]",
      "N-(2,6-Dichloro-3-Methylphenyl)Anthranilic Acid",
      "N-(2,6-Dichloro-M-Tolyl)Anthranilic Acid",
      "N-(3-Methyl-2,6-Dichlorophenyl)Anthranilic Acid",
      "2-((2,6-Dichloro-3-Methylphenyl)Amino)Benzoic Acid",
      "2-(2,6-Dichloro-3-Methylphenyl)Aminobenzoic Acid",
      "2-(2,6-Dichloro-3-Methylanilino)Benzoic Acid",
      "2-[(2,6-Dichloro-3-Methylphenyl)Amino]benzoic Acid",
    ],
  },
  {
    primary_id: "D08IFQ",
    names: [
      "3-Phenyl-2-Nortropene",
      "CHEMBL576092",
      "SCHEMBL2881954",
      "BDBM50303305",
      "3-Phenyl-8-Azabicyclo[3.2.1]oct-2-Ene",
    ],
  },
  {
    primary_id: "D08IGF",
    names: ["L-685,818"],
  },
  {
    primary_id: "D08IHF",
    names: [
      "A-74932",
      "A 74932",
      "3H-Pyrido(3,2,1-Kl)Phenoxazine-2-Carboxylic Acid, 6-(2-((2-Amino-1-Oxopentyl)Amino)-1-Pyrrolidinyl)-5-Fluoro-3-Oxo-, Monohydrochloride",
      "3H-Pyrido(3,2,1-K1)Phenoxazine-2-Carboxylic Acid, 6-(2-((2-Amino-1-Oxopentyl)Amino)-1-Pyrrolidinyl)-5-Fluoro-3-Oxo-, Monohydrochloride",
      "AC1L31MU",
      "148201-60-9",
    ],
  },
  {
    primary_id: "D08IHM",
    names: ["Adenosine Monotungstate", "ADENOSINE MONOTUNGSTATE"],
  },
  {
    primary_id: "D08IID",
    names: ["TNO155"],
  },
  {
    primary_id: "D08IIV",
    names: ["PMID27109571-Compound-22"],
  },
  {
    primary_id: "D08IIW",
    names: ["MR-16089"],
  },
  {
    primary_id: "D08IKV",
    names: ["58-1066"],
  },
  {
    primary_id: "D08ILW",
    names: ["Cyclohexyl Biphenyl-4-Ylcarbamate", "CHEMBL541244", "SCHEMBL12166742", "URB602, 3k"],
  },
  {
    primary_id: "D08ILX",
    names: ["N-(2-Methyl-1H-Indol-5-Yl)Benzamide", "CHEMBL1253578", "BDBM50326842"],
  },
  {
    primary_id: "D08IMD",
    names: [
      "AZD-6495",
      "CHEMBL481806",
      "SCHEMBL1276777",
      "GTPL7711",
      "AOB4022",
      "AZD6495",
      "AZD 6495",
      "ZINC40934036",
      "BDBM50255811",
      "953798-95-3",
      "4-[(2,4-Difluorophenyl)Amino]-7-Ethoxy-6-(4-Methylpiperazin-1-Yl)Quinoline-3-Carboxamide",
    ],
  },
  {
    primary_id: "D08IMP",
    names: ["AAV-HAADC Gene Therapy"],
  },
  {
    primary_id: "D08INO",
    names: ["WX-UK1"],
  },
  {
    primary_id: "D08INY",
    names: [
      "2,6-Diphenyl-Pyrimidin-4-Ylamine",
      "4-Amino-2,6-Diphenylpyrimidine",
      "2,6-Diphenylpyrimidin-4-Amine",
      "CHEMBL406788",
      "PLXFRDQUOPPVHF-UHFFFAOYSA-N",
      "2,6-Diphenyl-4-Pyrimidinamine",
      "AC1LDIB7",
      "SCHEMBL1490059",
      "2,6-Diphenyl-4-Pyrimidinamine #",
      "ZINC29062470",
      "BDBM50375487",
      "AKOS011055092",
      "(2,6-Diphenyl-Pyrimidin-4-Yl)-Amine",
    ],
  },
  {
    primary_id: "D08INZ",
    names: ["Pyridine Derivative 5"],
  },
  {
    primary_id: "D08IOP",
    names: ["N-(2-Phenylcyclopropyl) Amino Acid Derivative 3"],
  },
  {
    primary_id: "D08IOV",
    names: [
      "2-(2-Butoxypyrimidin-4-Ylamino)Benzoic Acid",
      "2-[(2-Butoxypyrimidin-4-Yl)Amino]benzoic Acid",
      "4-Anilinopyrimidine 1",
      "AC1LZD2G",
      "CHEMBL242488",
      "BDBM15977",
      "MolPort-002-549-430",
      "ZINC2272106",
      "STL297947",
      "AKOS003630983",
      "MCULE-6330252818",
    ],
  },
  {
    primary_id: "D08IOW",
    names: ["ISIS 19219"],
  },
  {
    primary_id: "D08IPU",
    names: [
      "RNAi Therapy, Sirna",
      "Asthma Therapy, Sirna",
      "RNAi Therapy (Asthma)",
      "RNAi Therapy (Asthma), Sirna/GSK",
      "RNAi Therapy (Asthma), Merck & Co/GSK",
      "SiRNAs (IL-4Ralpha, Naoparticle), Sirna/GSK",
      "SiRNAs (IL-4Ralpha, IL-13), Sirna/GSK",
    ],
  },
  {
    primary_id: "D08IRG",
    names: ["BIIB 015"],
  },
  {
    primary_id: "D08IRN",
    names: [
      "2,4,5-Triarylimidazole Analogue",
      "CHEMBL16925",
      "SCHEMBL5137478",
      "JVMAHSCWJIZBJE-UHFFFAOYSA-N",
      "BDBM50284516",
      "N-Hydroxy-N-[1-[4-[4-(4-Fluorophenyl)-5-(4-Pyridyl)-1H-Imidazol-2-Yl]phenyl]ethyl]urea",
    ],
  },
  {
    primary_id: "D08IRR",
    names: ["Org-10490"],
  },
  {
    primary_id: "D08ISK",
    names: [
      "6,8-Dimethoxy-4-Methylquinolin-2(1H)-One",
      "Casimiroin Analogue, 1i",
      "2(1H)-Quinolinone, 6,8-Dimethoxy-4-Methyl-",
      "CHEMBL476126",
      "BDBM29220",
      "MolPort-028-745-488",
      "ZINC40429907",
      "249737-05-1",
    ],
  },
  {
    primary_id: "D08ISP",
    names: ["5-Methyl-4-Propyl-Pyrrolidin-(2Z)-Ylideneamine", "CHEMBL362366", "BDBM50150912"],
  },
  {
    primary_id: "D08IST",
    names: [
      "SB-509",
      "ALS Therapy, Sangamo",
      "Diabetic Neuropathy Therapy, Sangamo",
      "AdV-ZFP-VEGF",
      "VEGF ZFP TF, Sangamo-2",
      "VEGF Activation, Sangamo-2",
    ],
  },
  {
    primary_id: "D08IUE",
    names: ["PMID25656651-Compound-37c"],
  },
  {
    primary_id: "D08IUV",
    names: ["ALN-TTR01"],
  },
  {
    primary_id: "D08IUW",
    names: ["VLI-03B"],
  },
  {
    primary_id: "D08IVF",
    names: ["BP1001"],
  },
  {
    primary_id: "D08IWD",
    names: [
      "Finasteride",
      "Andozac",
      "Eucoprost",
      "FIT",
      "Finasterida",
      "Finasteridum",
      "Finastid",
      "Finpecia",
      "Propecia",
      "Propeshia",
      "Proscar",
      "Prostide",
      "Cahill May Roberts Brand Of Finasteride",
      "Chibro Proscar",
      "Frosst Iberica Brand Of Finasteride",
      "Lipha Brand Of Finasteride",
      "MSD Brand Of Finasteride",
      "MSD Chibropharm Brand Of Finasteride",
      "MK 0906",
      "MK 906",
      "MK906",
      "Merck Brand 1 Of Finasteride",
      "Merck Brand 2 Of Finasteride",
      "Merck Frosst Brand 1 Of Finasteride",
      "Merck Frosst Brand 2 Of Finasteride",
      "Alternova (TN)",
      "Appecia (TN)",
      "Chibro-Proscar",
      "Finalo (TN)",
      "Finara (TN)",
      "Finast (TN)",
      "Finasterid (TN)",
      "Finasterid IVAX (TN)",
      "Finasterida [INN-Spanish]",
      "Finasteridum [INN-Latin]",
      "Finax (TN)",
      "Fincar (TN)",
      "Finpecia (TN)",
      "Gefina (TN)",
      "KS-1058",
      "MK-0906",
      "MK-906",
      "Merck Sharp & Dhome Brand 2 Of Finasteride",
      "Merck Sharp & Dohme Brand 1 Of Finasteride",
      "Propecia (TN)",
      "Proscar (TN)",
      "Prosteride (TN)",
      "YM-152",
      "Finasteride (USP/INN)",
      "Finasteride [USAN:INN:BAN]",
      "L-652,931",
      "Proscar, Propecia, Finasteride",
      "N-Tert-Butyl-3-Oxo-4-Aza-5alpha-Androst-1-En-17beta-Carboxamide",
      "N-Tert-Butyl-3-Oxo-4-Aza-5alpha-Androst-1-Ene-17beta-Carboxamide",
      "N-(2-Methyl-2-Propyl)-3-Oxo-4-Aza-5alpha-Androst-1-Ene-17beta-Carboxamide",
      "N-(2-Methyl-2-Propyl)-3-Oxo-4-Aza-5-Alpha-Androst-1-Ene-17-Beta-Carboxamide",
      "(1S,3aS,3bS,5aR,9aR,9bS,11aS)-N-Tert-Butyl-9a,11a-Dimethyl-7-Oxo-1,2,3,3a,3b,4,5,5a,6,9b,10,11-Dodecahydroindeno[5,4-F]quinoline-1-Carboxamide",
      "(4aR,4bS,6aS,7S,9aS,9bS,11aR)-N-(1,1-Dimethylethyl)-4a,6a-Dimethyl-2-Oxo-2,4a,4b,5,6,6a,7,8,9,9a,9b,10,11,11a-Tetradecahydro-1H-Indeno[5,4-F]quinoline-7-Carboxamide",
      "(4aR,4bS,6aS,7S,9aS,9bS,11aR)-N-(Tert-Butyl)-4a,6a-Dimethyl-2-Oxo-2,4a,4b,5,6,6a,7,8,9,9a,9b,10,11,11a-Tetradecahydro-1H-Indeno[5,4-F]quinoline-7-Carboxamide",
      "(5alpha,17beta)-(1,1-Dimethylethyl)-3-Oxo-4-Azaandrost-1-Ene-17-Carboxamide",
      "17beta-(N-Tert-Butylcarbamoyl)-4-Aza-5 Alpha-Androst-1-En-3-One",
    ],
  },
  {
    primary_id: "D08IWF",
    names: ["MCL-435"],
  },
  {
    primary_id: "D08IWT",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 27"],
  },
  {
    primary_id: "D08IXA",
    names: [
      "BQ788",
      "(2R)-2-[[(2R)-2-Amino-3-(1-Methoxycarbonylindol-3-Yl)Propanoyl]-[(2S)-2-[[(2R,6S)-2,6-Dimethylpiperidine-1-Carbonyl]amino]-4,4-Dimethylpentanoyl]amino]hexanoic Acid",
      "BQ 788",
      "AC1MIWQ3",
      "GTPL1010",
      "SCHEMBL18429752",
    ],
  },
  {
    primary_id: "D08IXN",
    names: ["[3H]naltrindole"],
  },
  {
    primary_id: "D08IXO",
    names: ["KNI-10232"],
  },
  {
    primary_id: "D08IXW",
    names: ["S-Hexylglutathione"],
  },
  {
    primary_id: "D08IYF",
    names: ["GSK-692342"],
  },
  {
    primary_id: "D08IZA",
    names: ["Isoxazole-Based Bicyclic Compound 17"],
  },
  {
    primary_id: "D08IZY",
    names: ["2-(Carboxymethylamino)-2-Oxoacetic Acid"],
  },
  {
    primary_id: "D08JAC",
    names: ["Fenretinide"],
  },
  {
    primary_id: "D08JAH",
    names: ["TPM-001"],
  },
  {
    primary_id: "D08JAN",
    names: ["Cipatinib"],
  },
  {
    primary_id: "D08JAP",
    names: ["Robalzotan"],
  },
  {
    primary_id: "D08JAT",
    names: ["Skeletal Targeted Radiotherapy"],
  },
  {
    primary_id: "D08JBL",
    names: ["PF-03446962"],
  },
  {
    primary_id: "D08JCA",
    names: [
      "Silver Sulfadiazine",
      "Brandiazin",
      "Dermazin",
      "Dermazine",
      "Flamazine",
      "Flammazine",
      "Geben",
      "Sicazine",
      "Silbertone",
      "Silvederma",
      "Silver",
      "Sulfargen",
      "Abbott Brand Of Silver Sulfadiazine",
      "Aldo Brand Of Silver Sulfadiazine",
      "Major Brand Of Silver Sulfadiazine",
      "Medphano Brand Of Silver Sulfadiazine",
      "MonarchBrand Of Silver Sulfadiazine",
      "Pharmascience Brand Of Silver Sulfadiazine",
      "Rhone Poulenc Rorer Brand Of Silver Sulfadiazine",
      "SSD AF",
      "SULFADIAZINE SILVER",
      "Sherwood Brand Of Silver Sulfadiazine",
      "Silver Sulfafdiazine",
      "Silver Sulfadiazinate",
      "Silver Sulphadiazine",
      "Solvay Brand Of Silver Sulfadiazine",
      "Sulfadiazin Silber",
      "Sulfadiazine Silver Salt",
      "Sulfadiazine SilverSilvadene",
      "Zenith Brand Of Silver Sulfadiazine",
      "Par Brand 1 Of Silver Sulfadiazine",
      "Par Brand 2 Of Silver Sulfadiazine",
      "Par Brand 3 Of Silver Sulfadiazine",
      "Flamazine (TN)",
      "Rhone-Poulenc Rorer Brand Of Silver Sulfadiazine",
      "Silvadene (TN)",
      "Silver(I) Sulfadiazine",
      "Smith & Nephew Brand Of Silver Sulfadiazine",
      "Sulfadiazin, Silbersalz",
      "Sulfadiazine Silver (JP15)",
      "Sulfadiazine, Silver [USAN]",
      "Sulfadiazine, Silver",
      "Sulfafdiazine, Silver",
      "Thermazene (TN)",
      "SSD (1% Silver Sulfadiazine Cream USP)",
      "Sulfadiazine, Silver (USP)",
      "N1-2-Pyrimidinylsulfanilamide Monosilver(1+) Salt",
      "N(Sup 1)-2-Pyrimidinylsulfanilamide Monosilver(1+) Salt",
      "Silver (4-Aminophenyl)Sulfonyl-Pyrimidin-2-Ylazanide",
      "Silver 4-Amino-N-Pyrimidin-2-Ylbenzenesulfonamide",
      "Benzenesulfonamide,4-Amino-N-2-Pyrimidinyl-, Silver Complex",
      "Silver(1+) [(4-Aminophenyl)Sulfonyl](Pyrimidin-2-Yl)Azanide",
      "Sulfanilamide,N1-2-Pyrimidinyl-, Monosilver(1+) Salt",
      "Sulfanilamide, N(Sup 1)-2-Pyrimidinyl-, Monosilver(1+) Salt",
      "Benzenesulfonamide, 4-Amino-N-2-Pyrimidinyl-, Monosilver(1+) Salt",
      "(4-Amino-N-Pyrimidin-2-Ylbenzenesulphonamidato-NN,O1)Silver",
      "4-Amino-N-(2-Pyrimidinyl)Benzenesulfonamide Silver Salt",
    ],
  },
  {
    primary_id: "D08JCC",
    names: ["4-(4-Fluorophenoxy)-3-Pyridinesulfonamide", "CHEMBL1163599", "BDBM50320502"],
  },
  {
    primary_id: "D08JCJ",
    names: [
      "17-METHYL-17-ALPHA-DIHYDROEQUILENIN",
      "17-Methyl-17-Alpha-Dihydroequilenin",
      "2b1z",
      "ZINC5048688",
      "DB06871",
      "17beta-Methyl-17alpha-Dihydroequilenin",
      "17M",
      "(13alpha,17beta)-17-Methylestra-1(10),2,4,6,8-Pentaene-3,17-Diol",
    ],
  },
  {
    primary_id: "D08JCK",
    names: ["1-Phenyl-1-(Piperidin-2-Yl)Propan-2-One", "CHEMBL219540"],
  },
  {
    primary_id: "D08JCL",
    names: ["ONO-3805"],
  },
  {
    primary_id: "D08JCU",
    names: ["(E)-2-(4-Fluorostyryl)-5-(Phenylsulfinyl)Pyridine", "CHEMBL240886"],
  },
  {
    primary_id: "D08JCV",
    names: ["JWH-204"],
  },
  {
    primary_id: "D08JEA",
    names: ["AKB-4924"],
  },
  {
    primary_id: "D08JEI",
    names: ["6-(Naphthalene-2-Sulfonyl)-2H-Pyridazin-3-One", "CHEMBL369911"],
  },
  {
    primary_id: "D08JEK",
    names: ["Org-30850", "136208-71-4", "Org-300850"],
  },
  {
    primary_id: "D08JES",
    names: ["Piperazine Urea Derivative 1"],
  },
  {
    primary_id: "D08JFL",
    names: ["3-Benzoyl-1-Pentyl-1,4-Dihydroquinolin-4-One", "CHEMBL237166", "BDBM50224060"],
  },
  {
    primary_id: "D08JGB",
    names: ["Otlertuzumab"],
  },
  {
    primary_id: "D08JGE",
    names: ["SonoLysis Prolyse"],
  },
  {
    primary_id: "D08JGM",
    names: ["AVID100 IV"],
  },
  {
    primary_id: "D08JGS",
    names: ["6,7-Dimethoxy-3-(4-Nitro-Phenyl)-Quinoline", "CHEMBL69585", "ZINC3834037"],
  },
  {
    primary_id: "D08JHA",
    names: ["Protein Kinase Therapeutic"],
  },
  {
    primary_id: "D08JHG",
    names: [
      "ChimeriVax-Dengue",
      "CYD Dengue Vaccine",
      "ChimeriVax-DEN",
      "ChimeriVax-DEN2",
      "Chimerivax-DEN1-4",
      "Dengue Vaccine, Peptide Therapeutics/Aventis Pasteur",
      "ChimeriVax (Dengue), Acambis/Aventis Pasteur",
      "ChimeriVax (Dengue), Peptide Therapeutics/Aventis Pasteur",
      "Dengue Vaccine (Recombinant Chimeric), Acambis/Aventis Pasteur",
      "ChimeriVax (Dengue), Acambis/Sanofi-Aventis",
      "Dengue Vaccine (Recombinant Chimeric), Acambis/Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D08JHQ",
    names: ["Aminocyclopentenone Compound 2"],
  },
  {
    primary_id: "D08JHR",
    names: ["N-(2-Adamantyl)-N'-Propylsulfamide", "CHEMBL218643", "SCHEMBL3117688", "BDBM50202584", "ZINC35091103"],
  },
  {
    primary_id: "D08JIG",
    names: ["ISIS 111086"],
  },
  {
    primary_id: "D08JII",
    names: ["Phenylpyrrolidinone Derivative 1"],
  },
  {
    primary_id: "D08JIP",
    names: ["Rintatolimod"],
  },
  {
    primary_id: "D08JIS",
    names: ["PMID25991433-Compound-N3"],
  },
  {
    primary_id: "D08JIV",
    names: [
      "Amlodipine",
      "Amlocard",
      "Amlodipino",
      "Amlodipinum",
      "Amlodis",
      "Amlopres",
      "Amlor",
      "Coroval",
      "Intervask",
      "Lipinox",
      "AMLODIPINE BASE",
      "Amlodipine Free Base",
      "Amlodipino [Spanish]",
      "Amlodipinum [Latin]",
      "Racemic Amlodipine",
      "Amlodipine (INN)",
      "Amlodipine [INN:BAN]",
      "Dailyvasc (TN)",
      "Istin (TN)",
      "Norvasc (TN)",
      "Perivasc (TN)",
      "UK-4834011",
      "R,S)-Amlodipine",
      "2-[(2-Aminoethoxy)Methyl]-4-(2-Chlorophenyl)",
      "3,5-Pyridinedicarboxylic Acid, 2-((2-Aminoethoxy)Methyl)-4-(2-Chlorophenyl)-1,4-Dihydro-6-Methyl-, 3-Ethyl 5-Methyl Ester",
      "3,5-Pyridinedicarboxylic Acid, 2-[(2-Aminoethoxy)Methyl]-4-(2-Chlorophenyl)-1,4-Dihydro-6-Methyl-, 3-Ethyl 5-Methyl Ester",
      "3-Ethyl-5-Methyl (+-)-2-(2-Aminoethoxymethyl)-4-(O-Chlorophenyl)-1,4-Dihydro-6-Methyl-3,5-Pyridinedicarboxylate",
      "3-Ethyl-5-Methyl (+/-)-2-[(2-Aminoethoxy)Methyl]-4-(O-Chlorophenyl)-1,4-Dihydro-6-Methyl-3,5-Pyridinedicarboxylate",
      "3-Ethyl-5-Methyl (-)-2-((2-Aminoethoxy)Methyl)-4-(2-Chlorphenyl)-1,4-Dihydro-6-Methyl-3,5-Pyridindicarboxylat",
      "3-Ethyl-5-Methyl (-)-2-((2-Aminoethoxymethyl)-4-(O-Chlorophenyl)-1,4-Dihydro-6-Methyl-3,5-Pyridinedicarboxylate",
      "3-O-Ethyl 5-O-Methyl 2-(2-Aminoethoxymethyl)-4-(2-Chlorophenyl)-6-Methyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "3-Ethyl 5-Methyl 2-[(2-Aminoethoxy)Methyl]-4-(2-Chlorophenyl)-6-Methyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "3-Ethyl 5-Methyl 2-{[(2-Aminoethyl)Oxy]methyl}-4-(2-Chlorophenyl)-6-Methyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
    ],
  },
  {
    primary_id: "D08JKK",
    names: ["RWJ-63556"],
  },
  {
    primary_id: "D08JKR",
    names: ["PMID25522065-Compound-9"],
  },
  {
    primary_id: "D08JKW",
    names: ["PMID20409708C34"],
  },
  {
    primary_id: "D08JLB",
    names: ["4-(2-(Benzyloxy)-6-Fluorophenyl)Piperidine", "CHEMBL575930"],
  },
  {
    primary_id: "D08JLC",
    names: ["J-607Y"],
  },
  {
    primary_id: "D08JLH",
    names: ["Spartalizumab"],
  },
  {
    primary_id: "D08JLV",
    names: ["TH-337"],
  },
  {
    primary_id: "D08JLW",
    names: [
      "GR-113808",
      "CHEMBL518682",
      "GTPL259",
      "[3H]GR 113808",
      "GR 125487 [H3]",
      "[1-[2-(Methanesulfonamido)Ethyl]piperidin-4-Yl]methyl 1-(Tritritiomethyl)Indole-3-Carboxylate",
    ],
  },
  {
    primary_id: "D08JMI",
    names: ["ALN-VSP"],
  },
  {
    primary_id: "D08JML",
    names: ["Microxine"],
  },
  {
    primary_id: "D08JMO",
    names: ["Iron Proteinsuccinylate"],
  },
  {
    primary_id: "D08JMZ",
    names: ["PMID25522065-Compound-5"],
  },
  {
    primary_id: "D08JNE",
    names: ["RG1578"],
  },
  {
    primary_id: "D08JNG",
    names: ["Malonic Acid"],
  },
  {
    primary_id: "D08JNI",
    names: ["MEDI5872"],
  },
  {
    primary_id: "D08JNK",
    names: [
      "D(CH2)5[D-Ile2,Ile4]AVP",
      "Beta-Mercapto-Beta,beta,cyclopentamethylenepropionyl[D-Ile2,Ile4]AVP",
      "D(CH2)5[D-Ile2,Ile4]VP",
    ],
  },
  {
    primary_id: "D08JNO",
    names: ["8-Acetyl-7-Butoxy-2H-Chromen-2-One", "CHEMBL1288620"],
  },
  {
    primary_id: "D08JOC",
    names: ["PMID25991433-Compound-J3"],
  },
  {
    primary_id: "D08JOE",
    names: ["ANA971"],
  },
  {
    primary_id: "D08JOR",
    names: ["(3-Hydroxy-2-Methyl-Phenyl)-Trimethyl-Ammonium"],
  },
  {
    primary_id: "D08JOS",
    names: [
      "D 2343",
      "D-2343",
      "AC1OCEM5",
      "SCHEMBL11327703",
      "4-[1-Hydroxy-2-[[4-(2-Methoxyphenyl)-2-Methylbutan-2-Yl]amino]ethyl]phenol Hydrochloride",
    ],
  },
  {
    primary_id: "D08JOY",
    names: ["Avrina"],
  },
  {
    primary_id: "D08JPU",
    names: [
      "G6976",
      "12H-Indolo[2,3-A]pyrrolo[3,4-C]carbazole-12-Propanenitrile, 5,6,7,13-Tetrahydro-13-Methyl-5-Oxo-",
      "Go 6976",
      "136194-77-9",
      "GO6976",
      "Go-6976",
      "Goe 6976",
      "UNII-B9IQO7JZ16",
      "6976",
      "Go 6976, Solution",
      "B9IQO7JZ16",
      "5,6,7,13-Tetrahydro-13-Methyl-5-Oxo-12H-Indolo[2,3-A]pyrrolo[3,4-C]carbazole-12-Propanenitrile",
      "CHEMBL302449",
      "CHEBI:51913",
      "12-(2-Cyanoethyl)-6,7,12,13-Tetrahydro-13-Methyl-5-Oxo-5H-Indolo[2,3-A]pyrrolo[3,4-C]carbazole",
      "12-(2-Cyanoethyl)-6,7,12,13-Tetrahydro-13-Methyl-5-Oxo-5H-Indolo(2,3-A)Pyrrolo(3,4-C)-Carbazole",
      "Go6976",
    ],
  },
  {
    primary_id: "D08JPW",
    names: ["N-Substituted Pyrazole Derivative 1"],
  },
  {
    primary_id: "D08JRT",
    names: ["RhApo2L/TRAIL"],
  },
  {
    primary_id: "D08JSA",
    names: [
      "SB208",
      "Amicarbazone",
      "129909-90-6",
      "4-Amino-N-(Tert-Butyl)-3-Isopropyl-5-Oxo-4,5-Dihydro-1H-1,2,4-Triazole-1-Carboxamide",
      "Amicarbazone [ISO]",
      "UNII-7FU145I60E",
      "BAY 314666",
      "4-Amino-N-Tert-Butyl-4,5-Dihydro-3-Isopropyl-5-Oxo-1H-1,2,4-Triazole-1-Carboxamide",
      "7FU145I60E",
      "1H-1,2,4-Triazole-1-Carboxamide, 4-Amino-N-(1,1-Dimethylethyl)-4,5-Dihydro-3-(1-Methylethyl)-5-Oxo-",
      "4-Amino-N-Tert-Butyl-5-Oxo-3-Propan-2-Yl-1,2,4-Triazole-1-Carboxamide",
      "4-Amino-N-(1,1-Dimethylethyl)-4,5-Dihydro-3-(1-Methylethyl)-5-Oxo-1H-1,2,4-Triazole-1-Carbox",
    ],
  },
  {
    primary_id: "D08JSE",
    names: ["JNJ-42041935"],
  },
  {
    primary_id: "D08JSJ",
    names: [
      "4-(4-P-Tolylamino-Phthalazin-1-Yl)-Benzamide",
      "CHEMBL194747",
      "BAS 02999388",
      "AC1LR1JT",
      "Oprea1_289700",
      "Oprea1_093859",
      "SCHEMBL3021915",
      "MolPort-000-702-961",
      "MolPort-001-909-424",
      "ZINC1297142",
      "BDBM50173017",
      "STL148635",
      "STL483208",
      "AKOS005748394",
      "AKOS000665050",
      "MCULE-9763616884",
      "4-(4-(P-Tolylamino)Phthalazin-1-Yl)Benzamide",
      "4-[4-(4-Methylanilino)Phthalazin-1-Yl]benzamide",
      "4-{4-[(4-Methylphenyl)Amino]phthalazin-1-Yl}benzamide",
    ],
  },
  {
    primary_id: "D08JSQ",
    names: ["DS-3032"],
  },
  {
    primary_id: "D08JSR",
    names: [
      "Tetrahydro-Pyrimidin-2-Ylideneamine",
      "1,4,5,6-Tetrahydropyrimidin-2-Amine",
      "41078-65-3",
      "1,4,5,6-Tetrahydro-2-Pyrimidinamine",
      "1,4,5,6-Tetrahydro-Pyrimidin-2-Ylamine",
      "2-Amino-1,4,5,6-Tetrahydropyrimidine",
      "CHEMBL158626",
      "Tetrahydropyrimidinimin",
      "2(1h)-Pyrimidinimine",
      "AC1MMKIU",
      "Tetrahydro-2(1H)-Pyrimidinimine",
      "DTXSID90391075",
      "PEHDFSFYZKSKGH-UHFFFAOYSA-N",
      "ZINC1728936",
      "Hexahydro-Pyrimidin-2-Ylidene-Amine",
      "BDBM50049248",
      "AKOS024618750",
      "AKOS011559938",
      "MCULE-1407334516",
      "2-Amino-3,4,5,6-Tetrahydropyrimidine",
    ],
  },
  {
    primary_id: "D08JTI",
    names: ["Anti-CD19/22-CAR Vector-Transduced T Cells"],
  },
  {
    primary_id: "D08JTN",
    names: ["AFM-20", "Anti-EpCAM IgG (Solid Tumors), Affimed"],
  },
  {
    primary_id: "D08JTU",
    names: ["R-944"],
  },
  {
    primary_id: "D08JUG",
    names: [
      "Alpha-Aminoisobutyric Acid",
      "2-Aminoisobutyric Acid",
      "62-57-7",
      "2-Methylalanine",
      "2-Amino-2-Methylpropanoic Acid",
      "H-Aib-OH",
      "Alanine, 2-Methyl-",
      "ALPHA-AMINOISOBUTYRIC ACID",
      "2-Amino-2-Methylpropionic Acid",
      "Alpha-Methylalanine",
      "Alpha-Aminoisobutanoic Acid",
      "A-Aminoisobutyric Acid",
      "2-Amino Isobutyric Acid",
      "NSC 16590",
      "Alpha,alpha-Dimethylglycine",
      "AIB",
      "Propionic Acid, 2-Amino-2-Methyl-",
      "2-Amino-Isobutyric Acid",
      "2-Amino-2-Methyl-Propanoic Acid",
      "UNII-1E7ZW41IQU",
      "A-Aminoisobutyrate",
      "2-Amino-2-Methylpropanoate",
      ".alpha.-Aminoisobutanoic Acid",
      "2,2-Dimethylglycine",
    ],
  },
  {
    primary_id: "D08JUO",
    names: ["CR-4056"],
  },
  {
    primary_id: "D08JUQ",
    names: ["2-Tolyl-6-Phenyl-9H-Purine", "CHEMBL205194"],
  },
  {
    primary_id: "D08JUW",
    names: ["PCNOTAXIME GROUP"],
  },
  {
    primary_id: "D08JVS",
    names: ["NU-206"],
  },
  {
    primary_id: "D08JWC",
    names: ["GS-9620"],
  },
  {
    primary_id: "D08JWJ",
    names: ["PF-1228305"],
  },
  {
    primary_id: "D08JWM",
    names: ["In-111-DAC Imaging Agent"],
  },
  {
    primary_id: "D08JWY",
    names: ["PMID30107136-Compound-Example43"],
  },
  {
    primary_id: "D08JXN",
    names: ["SRTCX1003"],
  },
  {
    primary_id: "D08JXR",
    names: [
      "HG-1202",
      "BBIR II Modulator (Cancer/Neurological/Immune Diseases), Human Genome Sciences",
      "BTNL3 Modulator (Cancer/Neurological/Immune Diseases), Human Genome Sciences",
      "BTNLR Modulator (Cancer/Neurological/Immune Diseases), Human Genome Sciences",
      "Butyrophilin-Like 3 Modulator (Cancer/Neurological/Immune Diseases), Human Genome Sciences",
      "Butyrophilin-Like Protein 3 Modulator (Cancer/Neurological/Immune Diseases), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D08JXW",
    names: ["INS-117548"],
  },
  {
    primary_id: "D08JYT",
    names: ["2-Arachidonyl Glyceryl Ether"],
  },
  {
    primary_id: "D08JYV",
    names: ["PSMA Subunit Vaccine"],
  },
  {
    primary_id: "D08JYW",
    names: ["CZ-ON002", "CZ-ON002 Series (Cancer)", "CZ-ON002 Series (Cancer), Chemizon"],
  },
  {
    primary_id: "D08JYX",
    names: ["2-[(4-Acetylphenyl)Ethynyl]-N6-Methoxyadenosine", "CHEMBL385770", "SCHEMBL4383259"],
  },
  {
    primary_id: "D08JZS",
    names: [
      "Mepyramine",
      "Anhistabs",
      "Anhistol",
      "Antalergan",
      "Antallergan",
      "Antamine",
      "Anthisan",
      "Copsamine",
      "Coradon",
      "Dipane",
      "Dorantamin",
      "Harvamine",
      "Histacap",
      "Histalon",
      "Histapyran",
      "Histasan",
      "Isamin",
      "Kriptin",
      "Maranhist",
      "Mepiramina",
      "Mepiramine",
      "Mepyramin",
      "Mepyraminum",
      "Mepyren",
      "Neoantergan",
      "Neobridal",
      "Nyscaps",
      "PYRA",
      "Paraminyl",
      "Parmal",
      "Pyramal",
      "Pyranisamine",
      "Pyrilamide",
      "Pyrilamine",
      "Stamine",
      "Stangen",
      "Statomin",
      "Thylogen",
      "Mepyramin [German]",
      "Pyrilamine Salt With Fine Resin",
      "RP 2786",
      "[3H]mepyramine",
      "[3H]pyrilamine",
      "Afko-Hist",
      "Mepiramina [INN-Spanish]",
      "Mepyramine (INN)",
      "Mepyramine [INN:BAN]",
      "Mepyraminum [INN-Latin]",
      "Neo-Bridal",
      "Wait's Green Mountain Antihistamine",
      "R.d. 2786",
      "P-Methoxybenzyl-Alpha-Pyridyl-Dimethyl-Aethylendiamin",
      "P-Methoxybenzyl-Alpha-Pyridyl-Dimethyl-Aethylendiamin [German]",
      "P-Methoxy-Benzyl-.alpha.-Pyridyl-Dimethyl-Aethylendiamin",
      "N-[2-(Dimethylamino)Ethyl]-N-[(4-Methoxyphenyl)Methyl]pyridin-2-Amine",
      "N-P-Methoxybenzyl-N',N'-Dimethyl-N-Alpha-Pyridylethylenediamine",
      "N-Para-Methoxybenzyl-N',N'-Dimethyl-N-Alpha-Pyridylethylenediamine",
      "N-(P-Methoxybenzyl)-N',N'-Dimethyl-N-2-Pyridylethylenediamine",
      "N-(Para-Methoxybenzyl)-N',N'-Dimethyl-N-2-Pyridylethylenediamine",
      "N',N'-Dimethyl-N-(P-Methoxybenzyl)-N-(2-Pyridyl)Ethylenediamine",
      "N-(P-Methoxybenzyl)-N',N'-Dimethyl-N-(Alpha-Pyridyl)Ethylenediamine",
      "N-(P-Methoxybenzyl)-N',N'-Dimethyl-N-2-(Pyridylethylene)Diamine",
      "N-Dimethylamino-Aethyl-N-P-Methoxy-Benzyl-.alpha.-Amino-Pyridin-Maleat",
      "N-(P-Methoxybenzyl)-N',N'-Dimethyl-N-(Alpha.-Pyridyl)Ethylenediamine",
      "N-(P-Methoxybenzyl)-N',N'-Dimethyl-N-2-Pyridyl-1,2-Ethanediamine",
      "N-[(4-Methoxyphenyl)Methyl]-N',N'-Dimethyl-N-2-Pyridinyl-1,2-Ethanediamine",
      "N-((4-Methoxyphenyl)Methyl)-N',N'-Dimethyl-N-2-Pyridinyl-1,2-Ethanediamine",
      "N-(4-Methoxybenzyl)-N',N'-Dimethyl-N-Pyridin-2-Ylethane-1,2-Diamine",
      "N,N-Dimethyl-N'-{[4-(Methyloxy)Phenyl]methyl}-N'-Pyridin-2-Ylethane-1,2-Diamine",
      "2-((2-(Dimethylamino)Ethyl)-(P-Methoxybenzyl)Amino)Pyridine",
      "2-((P-Methoxybenzyl)(2-(Dimethylamino)Ethyl)Amino)Pyridine",
      "2-[(P-Methoxybenzyl)[2-(Dimethylamino)Ethyl]amino]pyridine",
      "2-[[2-(Dimethylamino)Ethyl](P-Methoxybenzyl)Amino]pyridine",
      "2-[[2-(Dimethylamino)Ethyl]-(P-Methoxybenzyl)Amino]pyridine",
      "2-Dimethylaminoethyl-P-Anisyl-(2-Pyridyl)Amine",
    ],
  },
  {
    primary_id: "D08KAD",
    names: ["CYT-004-MelQbG10"],
  },
  {
    primary_id: "D08KAH",
    names: ["Quinazolinedione Derivative 3"],
  },
  {
    primary_id: "D08KAL",
    names: [
      "MEDI-552",
      "B-Cell Depleting Anti-CD20 Monoclonal Antibody, Cellective",
      "B-Cell Depleting Anti-CD20 Monoclonal Antibody, MedImmune",
    ],
  },
  {
    primary_id: "D08KAT",
    names: ["Fused Heterocyclic Compound 9"],
  },
  {
    primary_id: "D08KBB",
    names: ["DT-310"],
  },
  {
    primary_id: "D08KBK",
    names: ["Tedizolid"],
  },
  {
    primary_id: "D08KBV",
    names: ["4-Biphenyl-4-Yl-2-Cyclohexylmethyl-1H-Imidazole", "CHEMBL334104", "SCHEMBL4821982"],
  },
  {
    primary_id: "D08KCA",
    names: [
      "Leupeptin",
      "2-(2-Acetamido-4-Methylvaleramido)-N-(1-Formyl-4-Guanidinobutyl)-4-Methylvaleramide",
      "AC1L97IT",
      "Valeramide, 2-(2-Acetamido-4-Methylvaleramido)-N-(1-Formyl-4-Guanidinobutyl)-4-Methyl-",
      "24365-55-7",
      "(2S)-2-Acetamido-N-[1-[[5-(Diaminomethylideneamino)-1-Oxopentan-2-Yl]amino]-4-Methyl-1-Oxopentan-2-Yl]-4-Methylpentanamide",
    ],
  },
  {
    primary_id: "D08KCJ",
    names: ["ABY-025"],
  },
  {
    primary_id: "D08KCK",
    names: ["ART621"],
  },
  {
    primary_id: "D08KCW",
    names: ["PMID27109571-Compound-37"],
  },
  {
    primary_id: "D08KCZ",
    names: [
      "(2S)-2-(BUTYRYLOXY)-3-HYDROXYPROPYL NONANOATE",
      "(2s)-2-(Butyryloxy)-3-Hydroxypropyl Nonanoate",
      "(2S)-2-(Butanoyloxy)-3-Hydroxypropyl Nonanoate",
      "B3H",
      "AC1L9LSC",
      "DB07416",
      "[(2S)-2-Butanoyloxy-3-Hydroxypropyl] Nonanoate",
    ],
  },
  {
    primary_id: "D08KDK",
    names: [
      "T-2007",
      "DPB Sodium",
      "Diphenylbarbituric Sodium",
      "Diphenylbarbituric Acid (Epilepsy), Taro",
      "5,5-Diphenylbarbituric Sodium",
    ],
  },
  {
    primary_id: "D08KDL",
    names: ["TES-23-NCS"],
  },
  {
    primary_id: "D08KDP",
    names: ["AS-8112"],
  },
  {
    primary_id: "D08KDU",
    names: ["PT-201"],
  },
  {
    primary_id: "D08KEC",
    names: ["AG-NPN803"],
  },
  {
    primary_id: "D08KEZ",
    names: ["Canaline"],
  },
  {
    primary_id: "D08KGY",
    names: ["PRX-302"],
  },
  {
    primary_id: "D08KHD",
    names: ["Leonurine"],
  },
  {
    primary_id: "D08KIB",
    names: ["SAR-114137"],
  },
  {
    primary_id: "D08KIG",
    names: ["Ro01-6128"],
  },
  {
    primary_id: "D08KJM",
    names: [
      "9-Methyl-5H-6-Thia-4,5-Diaza-Chrysene 6,6-Dioxide",
      "CHEMBL271669",
      "SCHEMBL11973219",
      "CHEBI:94994",
      "KYPLTYNYSYVGGA-UHFFFAOYSA-N",
      "BDBM50372501",
      "NCGC00163045-01",
      "CU-00000000359-1",
    ],
  },
  {
    primary_id: "D08KJN",
    names: ["Phenethylcarbamic Acid Biphenyl-3-Yl Ester"],
  },
  {
    primary_id: "D08KKC",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 9"],
  },
  {
    primary_id: "D08KLH",
    names: [
      "[3H]RX821002",
      "2-Methoxyidazoxan",
      "RX-821002",
      "RX 821002",
      "102575-24-6",
      "RX821002",
      "Alpha-Methoxyidazoxan",
      "RX 821001",
      "CHEMBL10332",
      "CHEBI:73287",
      "2-(2,3-Dihydro-2-Methoxy-1,4-Benzodioxin-2-Yl)-4,5-Dihydro-1H-Imidazole",
      "1H-Imidazole, 2-(2,3-Dihydro-2-Methoxy-1,4-Benzodioxin-2-Yl)-4,5-Dihydro-",
      "2-[2-(2-Methoxy-1,4-Benzodioxanyl)]imidazoline",
      "2-(2-Methoxy-2,3-Dihydro-1,4-Benzodioxin-2-Yl)-4,5-Dihydro-1H-Imidazole",
      "[3H]2-Methoxyidazoxan",
      "2-(2-Methoxy-3H-1,4-Benzodioxin-2-Yl)-4,5-Dihydro-1H-Imidazole",
      "2-Ethoxy-Idazoxan",
      "PubChem2676",
      "Spectrum_001808",
    ],
  },
  {
    primary_id: "D08KLL",
    names: [
      "2-(2,3-Bis(2-Chlorobenzyloxy)Phenyl)Acetic Acid",
      "CHEMBL212860",
      "SCHEMBL5664165",
      "JDODANGOYUAOQT-UHFFFAOYSA-N",
      "BDBM50192463",
      "2,3-Bis[(2-Chlorophenyl)Methoxy]benzeneacetic Acid",
    ],
  },
  {
    primary_id: "D08KLN",
    names: ["3,4'-(Ethane-1,2-Diyl)Dibenzenamine", "CHEMBL1172396", "3,4'-Ethylenedianiline", "BDBM50322062"],
  },
  {
    primary_id: "D08KMR",
    names: ["PMID26666989-Compound-Figure12bottomright"],
  },
  {
    primary_id: "D08KMU",
    names: ["Gca-186"],
  },
  {
    primary_id: "D08KNG",
    names: ["VAN-10-4-Eluting Stent"],
  },
  {
    primary_id: "D08KNJ",
    names: ["4-(2,4-Dichlorophenoxy)-3'-Methylbiphenyl-3-Ol"],
  },
  {
    primary_id: "D08KNK",
    names: ["MPL-Containing Pollinex Allergy Desensitization Vaccine"],
  },
  {
    primary_id: "D08KNP",
    names: ["N-Benzyl Brucine", "BNO"],
  },
  {
    primary_id: "D08KOD",
    names: ["ISIS 15770"],
  },
  {
    primary_id: "D08KPA",
    names: ["BZL-101"],
  },
  {
    primary_id: "D08KPK",
    names: ["ONO-AE3-240"],
  },
  {
    primary_id: "D08KPQ",
    names: ["M8-B"],
  },
  {
    primary_id: "D08KQM",
    names: ["LGD-5552"],
  },
  {
    primary_id: "D08KRX",
    names: [
      "4-Nonyl-N-(Quinolin-3-Yl)Benzamide",
      "CHEMBL261848",
      "AC1N8TZO",
      "SCHEMBL846134",
      "4-Nonyl-N-Quinolin-3-Ylbenzamide",
    ],
  },
  {
    primary_id: "D08KSJ",
    names: ["H-Tyr-D-Ala-Gly Phe-Pro-Leu-Trp-O-3,5-Bzl(CF3)2"],
  },
  {
    primary_id: "D08KSQ",
    names: ["MERIOLIN 1"],
  },
  {
    primary_id: "D08KSU",
    names: ["AP12009"],
  },
  {
    primary_id: "D08KSV",
    names: ["RG6146"],
  },
  {
    primary_id: "D08KTO",
    names: ["AD04"],
  },
  {
    primary_id: "D08KTQ",
    names: [
      "CJC-1295",
      "DAC:GRF",
      "GHRH Derivative, ConjuChem",
      "GRF (Adult Growth Hormone Deficiency), ConjuChem",
      "Growth Hormone-Releasing Hormone Derivative,ConjuChem",
      "GRF 1-29-Albumin Conjugate (Sc, Cachexia), ConjuChem",
    ],
  },
  {
    primary_id: "D08KUO",
    names: [
      "1-Aminopropane-1,2,3-Tricarboxylic Acid",
      "2393-15-9",
      "NSC206248",
      "AC1L7BSJ",
      "SCHEMBL2774681",
      "CHEMBL1190722",
    ],
  },
  {
    primary_id: "D08KVK",
    names: ["CYT-009-GhrQb"],
  },
  {
    primary_id: "D08KVW",
    names: ["Alpha1-Proteinase Inhibitor (Human) Modified Process"],
  },
  {
    primary_id: "D08KVZ",
    names: [
      "Sibutramine",
      "Butramin",
      "Medaria",
      "Meridia",
      "Reductil",
      "Sibutramina",
      "Sibutraminum",
      "Sibutramina [Spanish]",
      "Sibutraminum [Latin]",
      "BTS-54524",
      "Butramin (TN)",
      "Ectiva (TN)",
      "Meridia (TN)",
      "Reductil (TN)",
      "Sibutramine (INN)",
      "Sibutramine [INN:BAN]",
      "N-1-(1-(4-Chlorophenyl)Cyclobutyl)-3-Methylbutyl-N,N-Dimethylamine HCl",
      "1-[1-(4-Chlorophenyl)Cyclobutyl]-N,N,3-Trimethylbutan-1-Amine",
    ],
  },
  {
    primary_id: "D08KWL",
    names: [
      "3-Cyclohexylethynyl-6,7-Dimethoxy-Quinoline",
      "CHEMBL63575",
      "SCHEMBL8172563",
      "MBQNQJQVMOFJOD-UHFFFAOYSA-N",
      "ZINC8552727",
      "BDBM50039040",
    ],
  },
  {
    primary_id: "D08KWQ",
    names: ["Sn-117m-DTPA"],
  },
  {
    primary_id: "D08KWS",
    names: [
      "5-Hexyl-2-Phenoxyphenol",
      "5-Hexyl-2-Phenoxy-Phenol",
      "6PP",
      "AIDS227446",
      "AC1NRXEM",
      "2-Phenoxy-5-Hexylphenol",
      "5-Hexyl-2-Phenoxylphenol",
      "SCHEMBL2878546",
      "PT04",
      "CHEMBL264682",
      "BDBM16297",
      "SXGQGHHNOWYMRT-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D08KWV",
    names: ["NXT-1021", "Antibacterial (Staphylococcus), Nymox"],
  },
  {
    primary_id: "D08KXC",
    names: ["SGX201"],
  },
  {
    primary_id: "D08KXE",
    names: ["RP-67580"],
  },
  {
    primary_id: "D08KXO",
    names: ["CCP-020"],
  },
  {
    primary_id: "D08KXX",
    names: ["JBD-411"],
  },
  {
    primary_id: "D08KYB",
    names: ["2-(3,5-Dimethoxyphenyl)-1,8-Naphthyridine", "CHEMBL391755"],
  },
  {
    primary_id: "D08KYH",
    names: ["4-(Hexyl(Methyl)Amino)-N-(Quinolin-3-Yl)Benzamide", "CHEMBL408637", "SCHEMBL846538"],
  },
  {
    primary_id: "D08KYM",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 22"],
  },
  {
    primary_id: "D08KYN",
    names: ["INNO-105"],
  },
  {
    primary_id: "D08KYP",
    names: ["Hydroxyqunoline Analog 5"],
  },
  {
    primary_id: "D08KYQ",
    names: ["Sulopenem", "CP-70,429", "PF-03709270", "PF-3,709,270"],
  },
  {
    primary_id: "D08KZJ",
    names: ["CAY 10471", "CAY10471", "CAY-10471"],
  },
  {
    primary_id: "D08KZM",
    names: [
      "CC49 Humanized Radioimmunoconjugates",
      "CC49 Humanized Radioimmunoconjugates, NCI, UAB",
      "CC49 Humanized Radioimmunoconjugates, National Cancer Institute, University Of Alabama At Birmingham",
      "HuCC49deltaCH2, NCI, UAB",
      "HuCC49deltaCH2, National Cancer Institute, University Of Alabama At Birmingham",
    ],
  },
  {
    primary_id: "D08KZR",
    names: ["BXL-746"],
  },
  {
    primary_id: "D08LAE",
    names: ["LGD-4033"],
  },
  {
    primary_id: "D08LAY",
    names: [
      "1-(2-(3-Methoxyphenyl)-1-Phenylethyl)Piperazine",
      "CHEMBL212763",
      "61311-72-6",
      "1-[2-(3-Methoxyphenyl)-1-Phenylethyl]piperazine",
      "SCHEMBL11504855",
      "CTK2E2732",
      "DTXSID40552210",
      "BDBM50188044",
      "Piperazine, 1-[2-(3-Methoxyphenyl)-1-Phenylethyl]-",
    ],
  },
  {
    primary_id: "D08LBC",
    names: ["N-Butylnorlitebamine"],
  },
  {
    primary_id: "D08LBZ",
    names: ["PMID25726713-Compound-56"],
  },
  {
    primary_id: "D08LCB",
    names: ["AS-15 Adjuvant"],
  },
  {
    primary_id: "D08LCD",
    names: ["JMV 1803"],
  },
  {
    primary_id: "D08LCQ",
    names: ["Benzo(B)Carbazol-11-One Compound 1"],
  },
  {
    primary_id: "D08LDB",
    names: [
      "GSK1265744",
      "Cabotegravir",
      "1051375-10-0",
      "UNII-HMH0132Z1Q",
      "GSK744",
      "GSK1265744A",
      "GSK-1265744",
      "HMH0132Z1Q",
      "Cabotegravir (GSK744, GSK1265744)",
      "CAB",
      "Oxazolo[3,2-A]pyrido[1,2-D]pyrazine-8-Carboxamide, N-[(2,4-Difluorophenyl)Methyl]-2,3,5,7,11,11a-Hexahydro-6-Hydroxy-3-Methyl-5,7-Dioxo-, (3S,11aR)-;Oxazolo[3,2-A]pyrido[1,2-D]pyrazine-8-Carboxamide, N-[(2,4-Difluorophenyl)Methyl]-2,3,5,7,11,11a-Hexahydro-6-Hydroxy-3-Methyl-5,7-Dioxo-, (3S,11aR)-",
      "Cabotegravir [USAN:INN]",
      "GSK744 LAP",
      "GSK744 LA",
      "S/GSK1265744",
      "GSK 744",
      "744 LA",
      "GSK 1265",
    ],
  },
  {
    primary_id: "D08LDG",
    names: ["Org-31433", "ACTH Fragment Analog"],
  },
  {
    primary_id: "D08LDS",
    names: ["LUBELUZOLE"],
  },
  {
    primary_id: "D08LDV",
    names: ["PA-1050040"],
  },
  {
    primary_id: "D08LEB",
    names: ["RO5488608"],
  },
  {
    primary_id: "D08LEY",
    names: ["Lanadelumab"],
  },
  {
    primary_id: "D08LEZ",
    names: ["APX-005"],
  },
  {
    primary_id: "D08LFC",
    names: [
      "CGP-35024",
      "3-Aminopropyl(Methyl)Phosphinic Acid",
      "3-Apmpa",
      "Skf-97541",
      "127729-35-5",
      "F 97541",
      "CGP 35024",
      "SKF 97541",
      "(3-(Aminopropyl)Methyl)Phosphinic Acid",
      "CHEMBL112710",
      "SKF 97541 Hydrochloride",
      "CGP35024",
      "SKF-97,541",
      "(3-Aminopropyl)(Methyl)Phosphinic Acid",
      "Phosphinic Acid, (3-(Aminopropyl)Methyl)-",
      "(3-Ammoniopropyl)Methylphosphinate",
      "SR-01000076225",
      "(3-Aminopropyl)Methylphosphinic Acid",
      "Tocris-0379",
      "Phosphinic Acid, (3-Aminopropyl)Methyl-",
      "Lopac-A-196",
      "AC1L1JVX",
      "Biomol-NT_000257",
      "AC1Q6RJ4",
      "Lopac0_000098",
      "C4H12NO2P",
      "SKF-97541",
    ],
  },
  {
    primary_id: "D08LFT",
    names: [
      "Benzenesulfonyl",
      "Benzenesulfinate",
      "16722-50-2",
      "BENZENESULFONYL",
      "Phenylsulfinate",
      "Phenyl Sulfinate",
      "Phenylsulfinsaure",
      "(Phenylsulfonyl)Radical",
      "AC1MYJCD",
      "Benzenethiol S,S-Dioxide",
      "C6H5O2S",
      "SCHEMBL13707414",
      "CHEBI:38100",
      "MolPort-019-857-533",
      "JEHKKBHWRAXMCH-UHFFFAOYSA-M",
      "STL483233",
      "HTS027703",
      "ZINC150345494",
      "AKOS015890258",
      "AB00990983-01",
    ],
  },
  {
    primary_id: "D08LFX",
    names: ["MB-300"],
  },
  {
    primary_id: "D08LFZ",
    names: [
      "Diflunisal",
      "Adomal",
      "Algobid",
      "Citidol",
      "Difludol",
      "Diflunisalum",
      "Dolisal",
      "Dolobid",
      "Dolobil",
      "Dolobis",
      "Dolocid",
      "Flovacil",
      "Fluniget",
      "Fluodonil",
      "Flustar",
      "Noaldol",
      "Reuflos",
      "Unisal",
      "Apotex Brand Of Diflunisal",
      "Cahill May Roberts Brand Of Diflunisal",
      "Diflunisal Novopharm Brand",
      "Frosst SA Brand Of Diflunisal",
      "Merck Brand Of Diflunisal",
      "Novopharm Brand Of Diflunisal",
      "Nu Pharm Brand Of Diflunisal",
      "MK 647",
      "MK647",
      "Apo-Diflunisal",
      "Diflunisalum [INN-Latin]",
      "Dolobid (TN)",
      "MK-647",
      "Merck Sharp & Dohme Brand Of Diflunisal",
      "Novo-Diflunisal",
      "Nu-Diflunisal",
      "Nu-Pharm Brand Of Diflunisal",
      "Diflunisal (JAN/USP/INN)",
      "Diflunisal [USAN:INN:BAN:JAN]",
      "2',4'-Difluoro-4-Hydroxy-(1,1'-Biphenyl)-3-Carboxylic Acid",
      "2',4'-Difluoro-4-Hydroxy-3-Biphenylcarboxylic Acid",
      "2',4'-Difluoro-4-Hydroxybiphenyl-3-Carboxylic Acid",
      "2-(Hydroxy)-5-(2,4-Difluorophenyl)Benzoic Acid",
      "5-(2,4-DIFLUOROPHENYL)-2-HYDROXY-BENZOIC ACID",
      "5-(2,4-Difluorophenyl)Salicylic Acid",
      "5-(2,4-Difluorophenyl)-2-Hydroxybenzoic Acid",
      "5-[2,4-Difluorophenyl]salicylic Acid",
    ],
  },
  {
    primary_id: "D08LHE",
    names: ["Prinoxodan"],
  },
  {
    primary_id: "D08LIB",
    names: ["PMID29649907-Compound-INT767"],
  },
  {
    primary_id: "D08LIF",
    names: ["Propiram Fumarate", "Algeril", "Diramax", "Dirame", "Diramine", "Diratab", "Propiram", "FBA-4503"],
  },
  {
    primary_id: "D08LIG",
    names: ["L-738167"],
  },
  {
    primary_id: "D08LIT",
    names: ["SB-0304"],
  },
  {
    primary_id: "D08LIU",
    names: ["Amooranin"],
  },
  {
    primary_id: "D08LJJ",
    names: ["6-Bromo-2-(2-Nitro-Phenyl)-Chromen-4-One", "CHEMBL56906"],
  },
  {
    primary_id: "D08LJP",
    names: ["Rasburicase"],
  },
  {
    primary_id: "D08LJY",
    names: ["MB-312"],
  },
  {
    primary_id: "D08LLB",
    names: ["Human CD19 Targeted T Cells"],
  },
  {
    primary_id: "D08LLM",
    names: ["NS-8"],
  },
  {
    primary_id: "D08LLR",
    names: ["UK-51656"],
  },
  {
    primary_id: "D08LLS",
    names: [
      "4-(Carboxyvin-2-Yl)Phenylboronic Acid",
      "159896-15-8",
      "(E)-3-(4-Boronophenyl)Acrylic Acid",
      "4-(2-Carboxyvinyl)Phenylboronic Acid",
      "3-(4-Boronophenyl)Acrylic Acid",
      "4-Boronocinnamic Acid",
      "4-(CARBOXYVIN-2-YL)PHENYLBORONIC ACID",
      "151169-68-5",
      "4-(E-2-Carboxyvinyl)Phenylboronic Acid",
      "4-(2-CARBOXYVINYL)BENZENEBORONIC ACID",
      "C9H9BO4",
      "CHEMBL139928",
      "4-Phenylpropylester Boronic Acid",
      "(2E)-3-[4-(DIHYDROXYBORANYL)PHENYL]PROP-2-ENOIC ACID",
      "4-(E-2-Carboxyvinyl)Benzeneboronic Acid",
      "AC1NRANQ",
      "3-[4-(Dihydroxyboranyl)Phenyl]prop-2-Enoic Acid",
      "MolPort-000-931-601",
    ],
  },
  {
    primary_id: "D08LMS",
    names: [
      "CG201",
      "Bevonium Methyl Sulfate",
      "BEVONIUM METILSULFATE",
      "Bevonium Methylsulfate",
      "5205-82-3",
      "Bevonium Methylsulphate",
      "Piribenzil Methyl Sulfate",
      "Bevonii Metilsulfas [INN-Latin]",
      "EINECS 226-001-8",
      "CG 201",
      "Metilsulfato De Bevonio [INN-Spanish]",
      "Metilsulfate De Bevonium [INN-French]",
      "Bevonium Metilsulfate [INN]",
      "L-99",
      "2-(Hydroxymethyl)-1,1-Dimethylpiperidinium Methyl Sulfate Benzilate",
      "Piperidinium, 2-(Hydroxymethyl)-1,1-Dimethyl-, Methyl Sulfate, Benzilate",
      "Alpha-Phenylmandelic Acid N,N-Dimethylpiperidinium-2-Methyl Ester Methylsulfa",
    ],
  },
  {
    primary_id: "D08LMT",
    names: [
      "3,6,9,12,15-PENTAOXATRICOSAN-1-OL",
      "3,6,9,12,15-Pentaoxatricosan-1-Ol",
      "N-Octylpentaoxyethylene",
      "19327-40-3",
      "PENTAETHYLENE GLYCOL MONOOCTYL ETHER",
      "Pentaoxyethylene Monooctyl Ether",
      "Polyoxyethylene 5 Octyl Ether",
      "C;Pentaethyleneglycolmonooctylether",
      "2-[2-[2-[2-(2-Octoxyethoxy)Ethoxy]ethoxy]ethoxy]ethanol",
      "Octylpentaglycol",
      "N8E",
      "C18H38O6",
      "AC1L4MMQ",
      "SCHEMBL61744",
      "C8E5",
      "Octyl Pentaethylene Glycol Ether",
      "DTXSID6075192",
      "CTK0H9802",
      "Pentaethylene Glycol, Octyl Ether",
      "MJELOWOAIAAUJT-UHFFFAOYSA-N",
      "AC1Q5919",
      "ZINC16051619",
      "7571AH",
      "MFCD00043032",
      "AKOS027320404",
      "DB08249",
      "LS-102023",
    ],
  },
  {
    primary_id: "D08LNT",
    names: [
      "Butyrylthiocholine",
      "BUTYRYLTHIOCHOLINE",
      "4555-00-4",
      "CHEMBL139908",
      "CHEBI:41055",
      "2-(BUTYRYLSULFANYL)-N,N,N-TRIMETHYLETHANAMINIUM",
      "CHEMBL148530",
      "(Propylcarbonylthioethyl)Trimethylammonium Iodide",
      "Butanoylthiocholine",
      "S-Butyrylthiocholine",
      "S-Butanoylthiocholine",
      "2ha7",
      "AC1L2GSR",
      "SCHEMBL570209",
      "Ethanaminium, N,N,N-Trimethyl-2-((1-Oxobutyl)Thio)-",
      "BDBM8978",
      "CTK4I8879",
      "AWBGQVBMGBZGLS-UHFFFAOYSA-",
      "DTXSID00196553",
      "ZINC1592591",
      "MCULE-2436087085",
      "DB04250",
      "2-Butanoylsulfanylethyl(Trimethyl)Azanium",
      "AN-22197",
      "AJ-27734",
      "CJ-25507",
    ],
  },
  {
    primary_id: "D08LPX",
    names: ["PMID28870136-Compound-69"],
  },
  {
    primary_id: "D08LQD",
    names: [
      "RESEQUINIL",
      "AVE-3933",
      "SX-3933",
      "Resequinil",
      "5-(3-Methoxyphenyl)-3-(5-Methyl-1,2,4-Oxadiazol-3-Yl)-1,6-Naphthyridin-2(1H)-One",
    ],
  },
  {
    primary_id: "D08LQS",
    names: ["ETBX-021"],
  },
  {
    primary_id: "D08LRU",
    names: ["Disorazol Z-LHRH Conjugates", "Disorazol Z-LHRH Conjugates (Ovarian Cancer)"],
  },
  {
    primary_id: "D08LRV",
    names: [
      "BGP-15",
      "66611-37-8",
      "BGP-15 2HCl",
      "3-Pyridinecarboximidamide, N-(2-Hydroxy-3-(1-Piperidinyl)Propoxy)-, Hydrochloride (1:2)",
      "BGP 15",
      "BGP15",
      "SCHEMBL3067994",
      "MolPort-039-099-291",
      "MolPort-027-834-918",
      "S8370",
      "AKOS016007888",
      "AKOS026745444",
      "SB17119",
      "CS-6045",
      "3-Pyridinecarboximidamide, N-(2-Hydroxy-3-(1-Piperidinyl)Propoxy)-, Dihydrochloride",
      "BGP-15, &gt",
      "AS-16983",
      "TC-162219",
      "AX8217132",
      "KB-258115",
      "HY-100828",
      "(Z)-N'-(2-Hydroxy-3-(Piperidin-1-Yl)Propoxy)Nicotinimidamide Dihydrochloride",
    ],
  },
  {
    primary_id: "D08LSA",
    names: ["SCY-524"],
  },
  {
    primary_id: "D08LTU",
    names: [
      "Minocycline",
      "Borymycin",
      "MINO",
      "MIY",
      "Minociclina",
      "Minociclinum",
      "Minocin",
      "Minocline",
      "Minocyclin",
      "Minocyclinum",
      "Minocycline Monohydrochloride",
      "CL 59806",
      "Lactoferrin B & Minocycline",
      "Lactoferrin H & Minocycline",
      "Minociclina [INN-Spanish]",
      "Minocin (Hydrochloride)",
      "Minocin (TN)",
      "Minocyclinum [INN-Latin]",
      "Vectrin (Hydrochloride)",
      "CRL-1605 & Minocycline",
      "Minocycline (USAN/INN)",
      "Minocycline [USAN:BAN:INN]",
      "(2Z,4S,4aS,5aR,12aS)-2-[amino(Hydroxy)Methylidene]-4,7-Bis(Dimethylamino)-10,11,12a-Trihydroxy-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(4S,4AS,5AR,12AS)-4,7-BIS(DIMETHYLAMINO)-3,10,12,12A-TETRAHYDROXY-1,11-DIOXO-1,4,4A,5,5A,6,11,12A-OCTAHYDROTETRACENE-2-CARBOXAMIDE",
      "4,7-Bis(Dimethylamino)-1,4,4a,5,5a,6,11,12a-Octahydro-3,10,12,12a-Tetrahydroxy-1,11-Dioxo-2-Naphthacenecarboxamide",
      "7-Dimethylamino-6-Demethyl-6-Deoxytetracycline",
    ],
  },
  {
    primary_id: "D08LUJ",
    names: ["Ryanodine"],
  },
  {
    primary_id: "D08LUT",
    names: [
      "ABT-702",
      "Abt-702",
      "Abt 702",
      "ABT702",
      "CHEMBL66089",
      "UNII-2P206WS5B3",
      "2P206WS5B3",
      "5-(3-Bromophenyl)-7-[6-(Morpholin-4-Yl)Pyridin-3-Yl]pyrido[2,3-D]pyrimidin-4-Amine",
      "5-(3-Bromo-Phenyl)-7-(6-Morpholin-4-Yl-Pyridin-3-Yl)-Pyrido[2,3-D]pyrimidin-4-Ylamine",
      "AC1L1CN5",
      "SCHEMBL1914142",
      "GTPL5131",
      "CTK5I8409",
      "MolPort-035-936-400",
      "CHEBI:104129",
      "ZINC21288919",
      "BDBM50094703",
      "AKOS030589605",
      "SMP2_000264",
      "NCGC00092381-03",
      "NCGC00092381-02",
      "LS-134028",
      "BRD-K36021395-300-01-6",
      "5-(3-Bromophenyl)-7-(6-Morpholin-4-Ylpyridin-3-Yl)Pyrido[2,3-D]pyrimidin-4-Ylamine",
    ],
  },
  {
    primary_id: "D08LVF",
    names: ["Nitecapone"],
  },
  {
    primary_id: "D08LVH",
    names: ["Medica 16"],
  },
  {
    primary_id: "D08LVT",
    names: ["4-Hydroxy-N-Neopentyl-N-Phenylbenzenesulfonamide", "CHEMBL203515"],
  },
  {
    primary_id: "D08LXJ",
    names: ["Heterologous Liver Cell Therapy"],
  },
  {
    primary_id: "D08LXU",
    names: ["LP99", "Compound 60 [PMID: 25864491]"],
  },
  {
    primary_id: "D08LYB",
    names: ["SB 239063"],
  },
  {
    primary_id: "D08LYZ",
    names: ["VesiGel"],
  },
  {
    primary_id: "D08LZI",
    names: ["NS-220"],
  },
  {
    primary_id: "D08LZK",
    names: ["ISIS 1753"],
  },
  {
    primary_id: "D08LZN",
    names: ["Cyclohexyl-(2-Phenylsulfanyl-9H-Purin-6-Yl)-Amine", "CHEMBL191270"],
  },
  {
    primary_id: "D08LZZ",
    names: ["DPO-1"],
  },
  {
    primary_id: "D08MAR",
    names: ["CP-230821"],
  },
  {
    primary_id: "D08MBD",
    names: ["PMID29649907-Compound-24"],
  },
  {
    primary_id: "D08MCC",
    names: ["NRX-101"],
  },
  {
    primary_id: "D08MCE",
    names: ["2-(4-Phenyl-Butyl)-1,2-Dihydro-Indazol-3-One", "CHEMBL3144714", "CHEMBL269654", "BDBM50009016"],
  },
  {
    primary_id: "D08MCI",
    names: ["L1Q-001"],
  },
  {
    primary_id: "D08MCL",
    names: ["Recombinant Human Biglycan"],
  },
  {
    primary_id: "D08MCO",
    names: ["SPD-703"],
  },
  {
    primary_id: "D08MCS",
    names: [
      "ADS-5102",
      "Amantadine Hydrochloride",
      "1-Adamantanamine Hydrochloride",
      "665-66-7",
      "1-Aminoadamantane Hydrochloride",
      "Amantadine HCl",
      "Mydantane",
      "Midantan",
      "Midantane",
      "Amazolon",
      "Adamantan-1-Amine Hydrochloride",
      "1-Adamantylamine Hydrochloride",
      "Adamantanamine Hydrochloride",
      "Virofral",
      "Influenol",
      "Trivaline",
      "Viregyt",
      "Virasol",
      "Amantan",
      "Virosol",
      "Adamantylamine Hydrochloride",
      "Aminoadamantane Hydrochloride",
      "Adamantine Hydrochloride",
      "1-Adamantanamine, Hydrochloride",
      "NSC 83653",
      "EXP 105-1",
      "Tricyclodecane",
      "Mantadix",
      "Exp-105-1",
      "Amantadine (Hydrochloride)",
      "UN",
    ],
  },
  {
    primary_id: "D08MCT",
    names: ["BMS-192548"],
  },
  {
    primary_id: "D08MDL",
    names: ["INS49266"],
  },
  {
    primary_id: "D08MDV",
    names: ["CT-3501"],
  },
  {
    primary_id: "D08MDX",
    names: ["ED-001"],
  },
  {
    primary_id: "D08MEC",
    names: ["Melogatran"],
  },
  {
    primary_id: "D08MEF",
    names: ["BAY-86-4884"],
  },
  {
    primary_id: "D08MEN",
    names: ["KF-A5"],
  },
  {
    primary_id: "D08MFC",
    names: [
      "L-Myo-Inositol-1-Phosphate",
      "(1R,2S,3R,4R,5S,6S)-2,3,4,5,6-Pentahydroxycyclohexyl Phosphate",
      "AC1NRBXQ",
      "CHEBI:58401",
      "1D-Myo-Inositol 3-Phosphate(2-)",
      "DB03542",
      "[(2S,3R,5S,6S)-2,3,4,5,6-Pentahydroxycyclohexyl]",
    ],
  },
  {
    primary_id: "D08MFI",
    names: ["Hsp 90 Inhibitors"],
  },
  {
    primary_id: "D08MGU",
    names: ["SLIT TR-03"],
  },
  {
    primary_id: "D08MHD",
    names: ["E-2609", "Beta Secretase Inhibitor (Alzheimer's Disease), Eisai"],
  },
  {
    primary_id: "D08MHM",
    names: ["PF-3758309"],
  },
  {
    primary_id: "D08MHU",
    names: ["6-Hydroxy-2-(4-Hydroxy-Benzyl)-Chromen-4-One", "CHEMBL56345", "BDBM50077322"],
  },
  {
    primary_id: "D08MID",
    names: ["T-115"],
  },
  {
    primary_id: "D08MIG",
    names: ["RPA-102"],
  },
  {
    primary_id: "D08MJE",
    names: [
      "(R,)-2-(2-Naphthamido)-5-Phenylpent-4-Enoic Acid",
      "CHEMBL1090714",
      "(2R,4E)-2-[(Naphthalen-2-Ylcarbonyl)Amino]-5-Phenylpent-4-Enoic Acid",
      "SCHEMBL16550527",
      "SCHEMBL16550525",
      "BDBM50314705",
    ],
  },
  {
    primary_id: "D08MJO",
    names: ["CDP323"],
  },
  {
    primary_id: "D08MJX",
    names: ["R-56865"],
  },
  {
    primary_id: "D08MKF",
    names: ["Nickel Chloride"],
  },
  {
    primary_id: "D08MKR",
    names: ["DT388IL-3"],
  },
  {
    primary_id: "D08MLG",
    names: [
      "CNI-H0294",
      "CNI-1194",
      "CNI-H1194",
      "ITI-011",
      "ITI-367",
      "PPI-367",
      "ITI-002, International Therapeutics Inc",
      "Nuclear Importation Inhibition Compounds (HIV-1)",
      "Nuclear Importation Inhibition Compounds (HIV-1), Cytokine PharmaSciences",
      "Nuclear Importation Inhibition Compounds (HIV-1), Presidio",
      "Viral Genome Nuclear Entry Inhibitors (HIV-1 Infection), Cytokine PharmaSciences",
      "Viral Genome Nuclear Entry Inhibitors (HIV-1 Infection), ITI",
      "Viral Genome Nuclear Entry Inhibitors (HIV-1 Infection), International Therapeutics",
      "Viral Genome Nuclear Entry Inhibitors (HIV-1 Infection), Presidio",
      "Viral Genome Nuclear Entry Inhibitors (HIV-1 Infection), Presidion",
    ],
  },
  {
    primary_id: "D08MLH",
    names: ["TUG-469"],
  },
  {
    primary_id: "D08MMP",
    names: ["ENDG-8010"],
  },
  {
    primary_id: "D08MNG",
    names: [
      "C-(5-Bromo-4,7-Dimethoxyindan-1-Yl)Methylamine",
      "2CB-Ind",
      "CHEMBL424890",
      "912342-23-5",
      "DTXSID70582080",
      "BDBM50194751",
      "1H-Indene-1-Methanamine, 5-Bromo-2,3-Dihydro-4,7-Dimethoxy-",
    ],
  },
  {
    primary_id: "D08MNO",
    names: ["Butaprost (Free Acid Form)", "Butaprost Acid"],
  },
  {
    primary_id: "D08MOG",
    names: ["4-(Pent-3-Ylamino)-1-Naphthol", "CHEMBL571980", "SCHEMBL12649391"],
  },
  {
    primary_id: "D08MOJ",
    names: [
      "3-Bromopyruvate",
      "Bromopyruvate",
      "3-Bromo-2-Oxopropanoate",
      "AC1ODWIN",
      "3-Bromo-2-Oxo-Propionate",
      "GTPL4517",
      "CHEBI:131592",
      "PRRZDZJYSJLDBS-UHFFFAOYSA-M",
    ],
  },
  {
    primary_id: "D08MOL",
    names: [
      "Anti-CMV Mabs",
      "Anti-CMV MAbs (Cytomegalovirus Infection)",
      "Human Monoclonal Antibodies (Cytomegalovirus Infection), Trellis Bioscience",
      "Anti-CMV MAbs (Cytomegalovirus Infection), Trellis Bioscience",
    ],
  },
  {
    primary_id: "D08MON",
    names: ["3-Benzyl-5-Methoxy-7-Methylchromen-2-One", "CHEMBL470199"],
  },
  {
    primary_id: "D08MOZ",
    names: ["PTI-221"],
  },
  {
    primary_id: "D08MPA",
    names: ["AMA-76"],
  },
  {
    primary_id: "D08MPL",
    names: ["MRS2211"],
  },
  {
    primary_id: "D08MRB",
    names: [
      "1H-Imidazo[4,5-C]quinolin-4-Ylamine HCl",
      "3H-Imidazo[4,5-C]quinolin-4-Amine",
      "1H-Imidazo[4,5-C]quinolin-4-Amine",
      "CHEMBL19191",
      "4-Amino-1h-Imidazo[4,5-C]quinoline",
      "132207-04-6",
      "SCHEMBL482926",
      "SCHEMBL212131",
      "Imidazo[4,5-C]quinolin-4-Amine",
      "Imidazo(4,5-C)Quinolin 4-Amine",
      "WUUCVVFSWNXYRD-UHFFFAOYSA-N",
      "HQBUPOAKJGJGCD-UHFFFAOYSA-N",
      "CS-B1117",
      "BDBM50011593",
      "ZINC28222586",
      "AKOS027322300",
      "1h-Imidazo-[4,5-C]quinolin-4-Amine",
      "SB22857",
      "1H-Imidazo[4,5-C]quinolin-4-Ylamine",
    ],
  },
  {
    primary_id: "D08MRL",
    names: ["4-(Propylamino)Naphthalen-1-Ol", "CHEMBL572064", "SCHEMBL12599065"],
  },
  {
    primary_id: "D08MRN",
    names: ["Hexylcaine"],
  },
  {
    primary_id: "D08MRV",
    names: ["Male Erectile Dysfunction Therapy"],
  },
  {
    primary_id: "D08MTA",
    names: ["Tetanolic Acid"],
  },
  {
    primary_id: "D08MTG",
    names: [
      "Abafungin",
      "Abasol",
      "W-634",
      "YP-006",
      "YP-007",
      "Abafungin (Gel Formulation), York Pharma",
      "Abafungin (Cream, Dermatomycosis), York Pharma",
      "Abafungin (Gel Formulation, Onychomycosis), York Pharma",
      "Abafungin (Pessary, Vaginal Candidiasis), York Pharma",
    ],
  },
  {
    primary_id: "D08MTN",
    names: ["Glucose Analogue"],
  },
  {
    primary_id: "D08MTW",
    names: ["[Pip7]OT", "CHEMBL395290"],
  },
  {
    primary_id: "D08MTZ",
    names: ["ISOFORMONENTIN"],
  },
  {
    primary_id: "D08MUX",
    names: ["S33005"],
  },
  {
    primary_id: "D08MUZ",
    names: ["2-Pentenal"],
  },
  {
    primary_id: "D08MVK",
    names: ["GCC-AP0341", "AP-0341"],
  },
  {
    primary_id: "D08MVV",
    names: ["PRX-111"],
  },
  {
    primary_id: "D08MVY",
    names: ["R-116712"],
  },
  {
    primary_id: "D08MWC",
    names: ["MK-1308"],
  },
  {
    primary_id: "D08MXB",
    names: ["NCX-125"],
  },
  {
    primary_id: "D08MXD",
    names: ["[3H]SB-674042", "CHEMBL2413521", "GTPL1706"],
  },
  {
    primary_id: "D08MXO",
    names: ["PMID28394193-Compound-24"],
  },
  {
    primary_id: "D08MXP",
    names: ["LEE011"],
  },
  {
    primary_id: "D08MYA",
    names: ["PMID29649907-Compound-31"],
  },
  {
    primary_id: "D08MYF",
    names: ["Bio-Hep-B"],
  },
  {
    primary_id: "D08MYL",
    names: ["NIM-811"],
  },
  {
    primary_id: "D08MYO",
    names: ["LAG-078"],
  },
  {
    primary_id: "D08MYP",
    names: ["ISIS 183897"],
  },
  {
    primary_id: "D08MYW",
    names: ["Human M-SAA3 Peptide"],
  },
  {
    primary_id: "D08MZY",
    names: [
      "Eckol",
      "88798-74-7",
      "4-(3,5-Dihydroxyphenoxy)Dibenzo-P-Dioxin-1,3,6,8-Tetrol",
      "1-(3,5-Dihydroxyphenoxy)-2,4,7,9-Tetrahydroxydibenzo-1,4-Dioxin",
      "UNII-4A5E8354UB",
      "CHEMBL471187",
      "CHEBI:65819",
      "4A5E8354UB",
      "4-(3,5-Dihydroxyphenoxy)Oxanthrene-1,3,6,8-Tetrol",
      "4-(3,5-Dihydroxyphenoxy)-Dibenzo(B,e)(1,4)Dioxin-1,3,6,8-Tetrol",
      "AC1L3S7Z",
      "SCHEMBL2562621",
      "DTXSID40237333",
      "ZINC6091359",
      "BDBM50259982",
      "Dibenzo[b,e][1,4]dioxin-1,3,6,8-Tetrol, 4-(3,5-Dihydroxyphenoxy)-",
      "Dibenzo(B,e)(1,4)-Dioxin-1,3,6,8-Tetrol, 4-(3,5-Dihydroxyphenoxy)-",
    ],
  },
  {
    primary_id: "D08NAB",
    names: ["Chymotrypsin", "Alpha Chymar", "Catarase", "Zolyse"],
  },
  {
    primary_id: "D08NAD",
    names: ["Batabulin"],
  },
  {
    primary_id: "D08NAI",
    names: ["SAR438584"],
  },
  {
    primary_id: "D08NCO",
    names: [
      "Vanadate",
      "Ammonium Metavanadate",
      "7803-55-6",
      "Ammonium Vanadate(V)",
      "UNII-FL85PX638G",
      "FL85PX638G",
      "Ammonium Metavanadate, ACS Reagent",
      "Ammoniummetavanadate",
      "Ammonium Metavanadate, 99.5%, For Analysis",
      "Ammonium Metavanadate, 99.996%, (Trace Metal Basis)",
      "Ammonium Meta-Vanadate",
      "Vanadate (VO31-), Ammonium (1:1)",
      "AC1LANGP",
      "Ammonium Vanadiumoylolate",
      "H4NO3V",
      "EC 232-261-3",
      "Azanium Oxido(Dioxo)Vanadium",
      "KS-00000WUM",
      "UNTBPXHCXVWYOI-UHFFFAOYSA-O",
      "MolPort-044-723-987",
      "MolPort-003-925-733",
      "MFCD00011430",
      "AKOS030228605",
      "AMMONIUM VANADATE (META), NH4VO3",
    ],
  },
  {
    primary_id: "D08NCP",
    names: ["NSC-660839"],
  },
  {
    primary_id: "D08NDA",
    names: ["ISIS-HBV"],
  },
  {
    primary_id: "D08NDE",
    names: ["BLZ-945", "CSF-1R Inhibitor (Bone Cancer)", "CSF-1R Inhibitor (Bone Cancer), Novartis"],
  },
  {
    primary_id: "D08NDP",
    names: ["Cyclo[Cys7,Cys10]N/OFQ(1-13)NH2", "CHEMBL1162359"],
  },
  {
    primary_id: "D08NDV",
    names: [
      "4-(1H-Indol-3-Yl)-1-Morpholinobutan-1-One",
      "CHEMBL395709",
      "4-(1H-Indol-3-Yl)-1-(Morpholin-4-Yl)Butan-1-One",
      "AC1LDAKL",
      "Oprea1_287687",
      "MLS000073378",
      "SCHEMBL14882056",
      "MolPort-001-888-170",
      "ZINC280650",
      "HMS2314G24",
      "BDBM50207035",
      "STK110153",
      "AKOS000614077",
      "MCULE-8725603794",
      "SMR000013452",
      "313532-54-6",
      "ST50900693",
      "4-Indol-3-Yl-1-Morpholin-4-Ylbutan-1-One",
      "4-(1H-Indol-3-Yl)-1-Morpholin-4-Ylbutan-1-One",
      "SR-01000362492",
      "4-(1H-Indol-3-Yl)-1-Morpholin-4-Yl-Butan-1-One",
      "SR-01000362492-1",
    ],
  },
  {
    primary_id: "D08NES",
    names: ["S-3536"],
  },
  {
    primary_id: "D08NFA",
    names: ["N-(4'-Methyl-Piperazinyl) Estradiol-16-Carboxamide"],
  },
  {
    primary_id: "D08NFH",
    names: ["Alpha-Phosphoribosylpyrophosphoric Acid"],
  },
  {
    primary_id: "D08NFP",
    names: ["3-(6-(Furan-2-Yl)-4-Phenylpyridin-2-Yl)-Phenol", "CHEMBL1095141"],
  },
  {
    primary_id: "D08NGV",
    names: ["PMID25553724-Compound-US2012820715110"],
  },
  {
    primary_id: "D08NHF",
    names: ["SR-11335"],
  },
  {
    primary_id: "D08NIG",
    names: ["9-Cis-Retinal", "9-Cis-Retinaldehyde"],
  },
  {
    primary_id: "D08NIN",
    names: ["NN-9926"],
  },
  {
    primary_id: "D08NIT",
    names: ["FUKUGETIN", "CHEMBL446790", "BDBM50292533"],
  },
  {
    primary_id: "D08NJB",
    names: ["Trans-H-Tyr-C[D-AllylGly-Gly-Phe-D-Allylgly]-OH"],
  },
  {
    primary_id: "D08NJD",
    names: ["T134"],
  },
  {
    primary_id: "D08NJE",
    names: ["CR-845 Iv Infusion"],
  },
  {
    primary_id: "D08NJQ",
    names: ["TJ-8117"],
  },
  {
    primary_id: "D08NJT",
    names: ["3-Bromo-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL396177"],
  },
  {
    primary_id: "D08NKJ",
    names: ["Guanine"],
  },
  {
    primary_id: "D08NKL",
    names: ["Isorhamnetin 3,7-Disulfate"],
  },
  {
    primary_id: "D08NLC",
    names: ["Cu64-CNND1-B"],
  },
  {
    primary_id: "D08NLN",
    names: ["Biolimus A9", "Biomatrix (TN)"],
  },
  {
    primary_id: "D08NLQ",
    names: ["Licarbazepine"],
  },
  {
    primary_id: "D08NLU",
    names: ["ISIS 6748"],
  },
  {
    primary_id: "D08NMM",
    names: ["AMG520", "CNP520"],
  },
  {
    primary_id: "D08NMS",
    names: ["PMID20684603C24dd"],
  },
  {
    primary_id: "D08NMV",
    names: ["Ixekizumab"],
  },
  {
    primary_id: "D08NNF",
    names: ["N-Cyclobutyl-1'H-Phenothiazine-1'-Carboxamide", "CHEMBL591233", "BDBM50308398"],
  },
  {
    primary_id: "D08NNH",
    names: ["PMID27551786-Compound-106"],
  },
  {
    primary_id: "D08NNR",
    names: ["Testosterone Glucoside"],
  },
  {
    primary_id: "D08NNW",
    names: ["Boric Acid", "Boron Oxide Hydroxide", "(HBO2)N", "CHEBI:30172"],
  },
  {
    primary_id: "D08NOP",
    names: ["2-Methyl-7-M-Tolyl-1,6-Naphthyridine", "CHEMBL236431"],
  },
  {
    primary_id: "D08NOS",
    names: ["AcAsp-Glu-Dif-Ile-Cha-Cys", "CHEMBL61390"],
  },
  {
    primary_id: "D08NPB",
    names: ["TO-195", "4-[N-[5-(6-Amidinonaphth-2-Yloxycarbonyl)Furan-2-Ylmethyl]carbamoyl]butyric Acid Hydrochloride"],
  },
  {
    primary_id: "D08NPG",
    names: ["MBX-2044"],
  },
  {
    primary_id: "D08NQA",
    names: ["MRS5698"],
  },
  {
    primary_id: "D08NQG",
    names: ["VB2-011"],
  },
  {
    primary_id: "D08NQM",
    names: [
      "5-QUINOXALIN-6-YLMETHYLENE-THIAZOLIDINE-2,4-DIONE",
      "5-Quinoxalin-6-Ylmethylene-Thiazolidine-2,4-Dione",
      "AS-605240",
      "648450-29-7",
      "AS605240",
      "AS 605240",
      "CHEMBL568150",
      "(5Z)-5-(Quinoxalin-6-Ylmethylidene)-1,3-Thiazolidine-2,4-Dione",
      "(5E)-5-(QUINOXALIN-6-YLMETHYLENE)-1,3-THIAZOLIDINE-2,4-DIONE",
      "5-(6-QUINOXALINYLMETHYLENE)-2,4-THIAZOLIDINEDIONE",
      "QYT",
      "(Z)-5-(Quinoxalin-6-Ylmethylene)Thiazolidine-2,4-Dione",
      "PI 3-Kg Inhibitor",
      "2a5u",
      "AC1NRCY0",
      "5-(Quinoxalin-6-Ylmethylene)Thiazolidine-2,4-Dione",
      "SCHEMBL668616",
      "GTPL6023",
      "AOB3848",
      "MolPort-006-169-944",
      "HMS3650I15",
    ],
  },
  {
    primary_id: "D08NQS",
    names: ["CLR 131 Radiopharmaceutical"],
  },
  {
    primary_id: "D08NQW",
    names: ["CHPG", "2-Chloro-5-Hydroxyphenylglycine"],
  },
  {
    primary_id: "D08NQZ",
    names: [
      "Tetracycline",
      "Amycin",
      "Biocycline",
      "Bristaciclin",
      "Bristaciclina",
      "Bristacycline",
      "Ciclibion",
      "Copharlan",
      "Cyclomycin",
      "Cytome",
      "Dumocyclin",
      "Enterocycline",
      "Medocycline",
      "Resteclin",
      "Robitet",
      "Sanclomycine",
      "Tetrachel",
      "Veracin",
      "Bristaciclin Alpha",
      "Cefracycline Suspension",
      "Component Of Tetrastatin",
      "Sumycin Syrup",
      "Tetracycline Free Base",
      "Tetracycline I",
      "Tetracycline II",
      "Tetracycline Monohydrochloride",
      "Achromycin (Naphthacene Derivative)",
      "Achromycin, Naphthacene Derivative",
      "Centet (Base)",
      "Lemtrex (Base)",
      "Liquamycin (Veterinary)",
      "Liquamycin, Veterinary",
      "Panmycin (TN)",
      "Piracaps (Base)",
      "Polycycline (VAN)",
      "Polycycline (Antibiotic)",
      "Polycycline, Antibiotic",
      "SK-Tetracycline",
      "Sumycin (TN)",
      "T-125",
      "Tetra-Co",
      "Tetraciclina [INN-Spanish]",
      "Tetracycline & VRC3375",
      "Tetracycline (Internal Use)",
      "Tetracyclinum [INN-Latin]",
      "Tetracyn (TN)",
      "Vetquamycin-324 (Free Base)",
      "Tetracycline (JAN/USP/INN)",
      "Tetracycline [USAN:INN:BAN:JAN]",
      "Methyl-1,11-Dioxo-2-Naphthacenecarboxamide",
      "6-Methyl-1,11-Dioxy-2-Naphthacenecarboxamide",
    ],
  },
  {
    primary_id: "D08NRG",
    names: ["HYP-10"],
  },
  {
    primary_id: "D08NRH",
    names: [
      "Co 101244",
      "Co 101244 Hydrochloride",
      "193359-26-1",
      "193356-17-1",
      "1-[2-(4-Hydroxyphenoxy)Ethyl]-4-[(4-Methylphenyl)Methyl]-4-Piperidinol Mono Hydrochloride",
      "AC1OCFF0",
      "SCHEMBL1994218",
      "CTK8E7402",
      "WFFZHKKSIDENAJ-UHFFFAOYSA-N",
      "MolPort-023-276-440",
      "AKOS024457111",
      "MB06546",
      "API0009710",
      "KB-216981",
      "RT-012075",
      "J-012517",
      "1-[2-(4-Hydroxyphenoxy)Ethyl]-4hydroxy-4-(4-Methylbenzyl)Piperidine Hydrochloride",
      "1-[2-(4-Hydroxyphenoxy)Ethyl]-4-[(4-Methylphenyl)Methyl]piperidin-4-Ol Hydrochloride",
    ],
  },
  {
    primary_id: "D08NRK",
    names: [
      "Cyclopropyl",
      "Cyclopropyl Radical",
      "Cyclopropan-1-Yl",
      "2417-82-5",
      "AC1L3VZL",
      "AC1Q1GU5",
      "DTXSID30178882",
      "16084-EP2281563A1",
      "16084-EP2275401A1",
      "16084-EP2316459A1",
    ],
  },
  {
    primary_id: "D08NSS",
    names: ["SC-74020"],
  },
  {
    primary_id: "D08NTB",
    names: ["LFM-A13"],
  },
  {
    primary_id: "D08NTD",
    names: ["Trappsol Cyclo", "Cyclodextrin (Niemann Pick Type C), CTD Holdings"],
  },
  {
    primary_id: "D08NTW",
    names: ["Antineoplaston A10 + Antineoplaston AS2-2"],
  },
  {
    primary_id: "D08NUF",
    names: ["3-Oxoolean-12-En-27-Oic Acid"],
  },
  {
    primary_id: "D08NUS",
    names: ["XEL-005OP"],
  },
  {
    primary_id: "D08NVD",
    names: ["ISIS 2919"],
  },
  {
    primary_id: "D08NWC",
    names: ["Peginterferon Alfa-2b"],
  },
  {
    primary_id: "D08NWF",
    names: ["Crisaborole", "Crisaborole Ointment 2%", "PF-06930164"],
  },
  {
    primary_id: "D08NWU",
    names: ["NSC-660840"],
  },
  {
    primary_id: "D08NWY",
    names: [
      "ALS-2158",
      "ALS-2200",
      "Hepatitis C Virus NS5B Polymerase Inhibitors, Vertex Pharmaceuticals",
      "Nucleotide Analogs (Hepatitis C Virus Infection)",
      "Nucleotide Analogs (Hepatitis C Virus Infection), Alios BioPharma",
      "Nucleotide Analogs (Hepatitis C Virus Infection), Vertex Pharmaceuticals",
      "Phosphate-Protected Nucleotide Prodrugs (HCV Infection), Alios",
    ],
  },
  {
    primary_id: "D08NXB",
    names: ["Juvenon Energy Formula"],
  },
  {
    primary_id: "D08NXC",
    names: ["1-[(Furan-2(5H)-One)-4-Methyl]-Desloratadine", "CHEMBL597381"],
  },
  {
    primary_id: "D08NXP",
    names: ["ABBV-951"],
  },
  {
    primary_id: "D08NXW",
    names: ["GLP-1"],
  },
  {
    primary_id: "D08NXX",
    names: [
      "BMY-20844",
      "Bmy-20844",
      "124886-01-7",
      "UNII-ALR3F45M10",
      "CHEMBL88583",
      "ALR3F45M10",
      "1,3-Dihydro-7,8-Dimethyl-2H-Imidazo(4,5-B)Quinolin-2-One",
      "1,3-Dihydro-7,8-Dimethyl-2H-Imidazo[4,5-B]quinolin-2-One",
      "2H-Imidazo[4,5-B]quinolin-2-One,1,3-Dihydro-7,8-Dimethyl-",
      "2H-Imidazo[4,5-B]quinolin-2-One, 1,3-Dihydro-7,8-Dimethyl-",
      "2H-Imidazo(4,5-B)Quinolin-2-One, 1,3-Dihydro-7,8-Dimethyl-",
      "AC1NUPC3",
      "ACMC-1C2JD",
      "SCHEMBL2457579",
      "7,8-Dimethyl-1,3-Dihydroimidazo[4,5-B]quinolin-2-One",
      "CTK4B4100",
      "DTXSID40154516",
      "ODCKPUDNMNCWMR-UHFFFAOYSA-N",
      "BDBM50000925",
    ],
  },
  {
    primary_id: "D08NYF",
    names: [
      "5-(4-Bromophenyl)-2-Furaldehyde Thiosemicarbazone",
      "AC1LF4XJ",
      "CTK7D4016",
      "AKOS017263438",
      "MCULE-1426418015",
    ],
  },
  {
    primary_id: "D08NYJ",
    names: ["FluVac Project"],
  },
  {
    primary_id: "D08NYL",
    names: ["AGN211745"],
  },
  {
    primary_id: "D08NYN",
    names: ["Cytosporone B", "Dothiorelone G"],
  },
  {
    primary_id: "D08NYO",
    names: ["PDGF Gene Therapy"],
  },
  {
    primary_id: "D08NYZ",
    names: ["ASM-024"],
  },
  {
    primary_id: "D08NZE",
    names: ["Indatraline"],
  },
  {
    primary_id: "D08NZG",
    names: ["Ifabotuzumab"],
  },
  {
    primary_id: "D08OAQ",
    names: ["4-(1,1-Dimethyl-Heptyl)-3'-Methoxy-Biphenyl-2-Ol", "CHEMBL234845", "BDBM50213171"],
  },
  {
    primary_id: "D08OAS",
    names: ["Flavimun", "BERNA-YF", "Yellow Fever Vaccine, Berna", "Yellow Fever Vaccine, Crucell"],
  },
  {
    primary_id: "D08OBF",
    names: ["MYRISTIC ACID"],
  },
  {
    primary_id: "D08OBO",
    names: ["Rp-CAMPS", "Adenosine- 3', 5'- Cyclic Monophosphorothioate, Rp- Isomer"],
  },
  {
    primary_id: "D08OBT",
    names: ["Peldesine"],
  },
  {
    primary_id: "D08OCG",
    names: [
      "PTBE",
      "CHEMBL182371",
      "SCHEMBL6245198",
      "GTPL1403",
      "BDBM50153757",
      "1-[2-Hydroxy-3-Propyl-4-[4-[4-(2H-Tetrazol-5-Yl)Phenoxy]butoxy]phenyl]ethanone",
      "1-(2-Hydroxy-3-Propyl-4-{4-[4-(2H-Tetrazol-5-Yl)-Phenoxy]-Butoxy}-Phenyl)-Ethanone",
      "1-(2-Hydroxy-3-Propyl-4-{4-[4-(1H-Tetrazol-5-Yl)-Phenoxy]-Butoxy}-Phenyl)-Ethanone",
    ],
  },
  {
    primary_id: "D08OCL",
    names: ["4-(5-Nitro-Indol-1-Ylmethyl)-Benzamidine", "CHEMBL100296"],
  },
  {
    primary_id: "D08OCS",
    names: [
      "(3-Nitro-1H-Pyrazol-1-Yl)(P-Tolyl)Methanone",
      "(4-Methylphenyl)(3-Nitro-1H-Pyrazol-1-Yl)Methanone",
      "AC1LF99H",
      "N-Benzoylpyrazole Deriv., 18",
      "CHEMBL244939",
      "ZINC78678",
      "BDBM23710",
      "A2012/0084499",
      "MolPort-002-705-255",
      "STK760276",
      "AKOS001746005",
      "4-Methylphenyl 3-Nitropyrazolyl Ketone",
      "MCULE-9242110239",
      "ST073763",
      "(4-Methylphenyl)-(3-Nitropyrazol-1-Yl)Methanone",
      "SR-01000524341",
      "1-[(4-Methylphenyl)Carbonyl]-3-Nitro-1H-Pyrazole",
    ],
  },
  {
    primary_id: "D08ODH",
    names: ["Atiratecan", "CH-0793011", "CH-0793076", "CH-4556300", "TP-300"],
  },
  {
    primary_id: "D08ODP",
    names: ["CSC-700008", "CSC-700 Series, Cascade/NPS", "Aryl-Amides (Epilepsy/Pain/Anxiety), Cascade/NPS"],
  },
  {
    primary_id: "D08OES",
    names: ["NiCord"],
  },
  {
    primary_id: "D08OFD",
    names: [
      "1-(1-P-Tolylethylidene)Thiosemicarbazide",
      "CHEMBL256866",
      "7410-54-0",
      "NSC8380",
      "AC1NS5IF",
      "DTXSID70417787",
      "MolPort-020-322-439",
      "ZINC502706",
      "1-P-Tolylethanone Thiosemicarbazone",
      "NSC-8380",
      "BDBM50376198",
      "AKOS002388601",
      "AKOS008939485",
    ],
  },
  {
    primary_id: "D08OFG",
    names: ["BARETTIN", "(-)-Barettine", "GBB 1-Z", "104311-70-8", "AC1O5Z3U", "606933-69-1"],
  },
  {
    primary_id: "D08OFS",
    names: ["5-Fluoro-6-Azido-UMP"],
  },
  {
    primary_id: "D08OGB",
    names: ["YourDex"],
  },
  {
    primary_id: "D08OHK",
    names: ["AKP-501"],
  },
  {
    primary_id: "D08OHU",
    names: ["1-(3,5-Bis(Trifluoromethyl)Benzyl)-1H-Imidazole", "CHEMBL213596"],
  },
  {
    primary_id: "D08OIT",
    names: ["Viroprev"],
  },
  {
    primary_id: "D08OJB",
    names: ["Benzoic Acid Linked Peptide Analog 4"],
  },
  {
    primary_id: "D08OJE",
    names: ["Femoxetine"],
  },
  {
    primary_id: "D08OJF",
    names: ["ICA-105665"],
  },
  {
    primary_id: "D08OJQ",
    names: ["PMID25991433-Compound-L2"],
  },
  {
    primary_id: "D08OJU",
    names: ["SOU-001", "AA-10020", "DRP-001"],
  },
  {
    primary_id: "D08OKB",
    names: ["Bicyclic Heteroaryl Amide Derivative 2"],
  },
  {
    primary_id: "D08OKJ",
    names: [
      "Iron Dextran",
      "Chinofer",
      "Dexferrum",
      "Dextrofer",
      "Eisendextran",
      "Fenate",
      "Ferridextran",
      "Ferrodextran",
      "Ferroglucin",
      "Hematran",
      "Icar",
      "Imfergen",
      "Imferon",
      "Imperon",
      "Imposil",
      "Infed",
      "Norferan",
      "Polyfer",
      "Proferdex",
      "Prolongal",
      "Ursoferran",
      "Dextran Iron Complex",
      "Eisendextran [German]",
      "Fero Gradumet",
      "Ferric Dextran",
      "Iron Dextran Complex",
      "Iron Dextran Injection",
      "Iron Hydrogenated Dextran",
      "Ironorm Injection",
      "B 75",
      "Dextrofer 75",
      "Ferdex 100",
      "Ferroglukin 75",
      "Myofer 100",
      "A 100 (Pharmaceutical)",
      "American Regent Brand Of Iron-Dextran Complex",
      "Dextran-Iron Complex",
      "Fe-Dextran",
      "GlaxoSmithKline Brand Of Iron-Dextran Complex",
      "Goldline Brand Of Iron-Dextran Complex",
      "Hauck Brand Of Iron-Dextran Complex",
      "Hawthron Brand Of Iron-Dextran Complex",
      "Iro-Jex",
      "Iron-Dextran Complex",
      "Sanofi Brand Of Iron-Dextran Complex",
      "Sulfuric Acid, Iron Salt",
      "Sulphuric Acid, Iron Salt",
      "Vortech Brand Of Iron-Dextran Complex",
      "Watson Brand Of Iron-Dextran Complex",
      "Sulfuric Acid Iron Salt (1:1)",
      "Sulfuric Acid, Iron Salt (1:)",
      "Sulfuric Acid Iron(2+) Salt (1:1)",
    ],
  },
  {
    primary_id: "D08OKP",
    names: ["4-[5-(3-Hydroxyphenyl)-2-Thienyl]benzene-1,2-Diol", "CHEMBL573003", "SCHEMBL1180066"],
  },
  {
    primary_id: "D08OKW",
    names: ["Metalfullerene"],
  },
  {
    primary_id: "D08OLB",
    names: ["CH-5015765", "CH-5164840", "HSP90 Inhibitors (Cancer), Chugai"],
  },
  {
    primary_id: "D08OLG",
    names: ["ISIS 29239"],
  },
  {
    primary_id: "D08OLL",
    names: [
      "BRL 44408",
      "Brl-44408",
      "118343-19-4",
      "BRL44408",
      "2-((4,5-Dihydro-1H-Imidazol-2-Yl)Methyl)-2,3-Dihydro-1-Methyl-1H-Isoindole",
      "1H-Isoindole, 2-((4,5-Dihydro-1H-Imidazol-2-Yl)Methyl)-2,3-Dihydro-1-Methyl-",
      "Brl 48553",
      "C13H17N3",
      "2-(4,5-Dihydro-1H-Imidazol-2-Ylmethyl)-1-Methyl-1,3-Dihydroisoindole",
      "Brl 48962",
      "2-[(4,5-Dihydro-1H-Imidazol-2-Yl)Methyl]-2,3-Dihydro-1-Methyl-1H-Isoindole",
      "BRL-48553",
      "BRL-48962",
      "AC1L3TIM",
      "GTPL525",
      "SCHEMBL7596949",
      "CHEMBL216097",
      "CTK8E7463",
      "BDBM82520",
      "PDSP2_001727",
      "PDSP1_001744",
      "NSC_121850",
      "CAS_121850",
    ],
  },
  {
    primary_id: "D08OLP",
    names: [
      "2-(2-Hydroxy-Phenyl)-1H-Indole-5-Carboxamidine",
      "2-(2-Hydroxy-Phenyl)-1h-Indole-5-Carboxamidine",
      "Amino[2-(2-Hydroxyphenyl)-1H-Indol-5-Yl]methaniminium",
      "AC1L1BMI",
      "BDBM13942",
      "CTK8A0256",
      "APC-8328",
      "DB02463",
      "[amino-[2-(2-Hydroxyphenyl)-1H-Indol-5-Yl]methylidene]azanium",
    ],
  },
  {
    primary_id: "D08OLR",
    names: [
      "ART-144",
      "Anti-Inflammatory Agent (Intra-Articular/Injectable, Sustained Release, Osteoarthritis), Artyx Pharmaceuticals",
    ],
  },
  {
    primary_id: "D08OLU",
    names: ["AVP-5"],
  },
  {
    primary_id: "D08OLZ",
    names: ["Vitronectin Antagonists"],
  },
  {
    primary_id: "D08OMB",
    names: ["NVP-AAM077"],
  },
  {
    primary_id: "D08OMM",
    names: ["BL-1040"],
  },
  {
    primary_id: "D08ONJ",
    names: ["MPC-3100"],
  },
  {
    primary_id: "D08OOJ",
    names: ["CB-5083"],
  },
  {
    primary_id: "D08OPS",
    names: ["ATYR 1940"],
  },
  {
    primary_id: "D08OPW",
    names: ["ARRY-575"],
  },
  {
    primary_id: "D08OPX",
    names: ["4SC-205"],
  },
  {
    primary_id: "D08OQI",
    names: ["NSAID Prodrugs"],
  },
  {
    primary_id: "D08OQO",
    names: [
      "8-Isopropoxy-Quinolin-2-Ylamine",
      "CHEMBL365254",
      "2-Quinolinamine, 8-(1-Methylethoxy)-",
      "SCHEMBL5884565",
      "BDBM50152417",
    ],
  },
  {
    primary_id: "D08OQQ",
    names: ["Lavoltidine"],
  },
  {
    primary_id: "D08OQX",
    names: [
      "3,3'-Thiene-2,4-Diyldiphenol",
      "SCHEMBL1180152",
      "CHEMBL461027",
      "BDBM25856",
      "FIVXNZZSAFLVFQ-UHFFFAOYSA-N",
      "ZINC40391436",
      "Hydroxyphenyl Substituted Thiophene, 28",
    ],
  },
  {
    primary_id: "D08ORD",
    names: ["2-(4-Tert-Butylbenzylideneamino)Ethanesulfonamide", "CHEMBL575785"],
  },
  {
    primary_id: "D08ORG",
    names: [
      "1-(4-Cyanobenzyl)-5-Hydroxymethyl-1H-Imidazole",
      "183500-36-9",
      "CHEMBL599601",
      "4-(5-HYDROXYMETHYLIMIDAZOL-1-YLMETHYL)BENZONITRILE",
      "1-(4-Cyanobenzyl)-5-Hydroxymethylimidazole",
      "SCHEMBL3630524",
      "CTK4D8500",
      "DTXSID40436662",
      "ACFSBQWDVYWNRT-UHFFFAOYSA-N",
      "ZINC45368822",
      "BDBM50307240",
      "ACM183500369",
      "KB-238618",
      "1-(4-Cyanobenzyl)-5-Hydroxymethyl-Imidazole",
      "1-(4-Cyanobenzyl)-5-(Hydroxymethyl)Imidazole",
      "1-(4-Cyanobenzyl)-5-(Hydroxymethyl) Imidazole",
      "1-(4-Cyanobenzyl)-5-(Hydroxymethyl)-Imidazole",
      "3-(4-Cyanobenzyl)-3H-Imidazol-4-Ylmethyl Alcohol",
    ],
  },
  {
    primary_id: "D08ORM",
    names: [
      "Ac-VEID-CHO",
      "CHEMBL478081",
      "Ac-Val-Glu-Ile-Asp-CHO",
      "Acetyl-Val-Ile-Asp-Aldehyde",
      "GTPL8566",
      "SCHEMBL20257260",
      "Acetyl-Val-Glu-Ile-Asp-Aldehyde",
      "N-Acetyl-Val-Glu-Ile-Asp-Aldehyde",
      "ZINC14947179",
      "BDBM50340548",
      "319494-39-8",
      "N-Acetyl-Val-Glu-Ile-Asp-Al, ~99% (HPLC), Powder",
      "(4S)-4-[[(2S)-2-Acetamido-3-Methylbutanoyl]amino]-5-[[(2S,3S)-1-[[(2S)-1-Carboxy-3-Oxopropan-2-Yl]amino]-3-Methyl-1-Oxopentan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D08ORO",
    names: [
      "OPC-21268",
      "Acetamide, N-(3-(4-((4-(3,4-Dihydro-2-Oxo-1(2H)-Quinolinyl)-1-Piperidinyl)Carbonyl)Phenoxy)Propyl)-",
      "Opc 21268",
      "131631-89-5",
      "Fuscoside",
      "1-(1-(4-(3-Acetylaminopropoxy)Benzoyl)-4-Piperidyl)-3,4-Dihydro-2(1H)-Quinolinone",
      "CHEMBL296908",
      "OPC-1268",
      "N-[3-[4-[4-(2-Oxo-3,4-Dihydroquinolin-1-Yl)Piperidine-1-Carbonyl]phenoxy]propyl]acetamide",
      "N-(3-(4-(4-(2-Oxo-3,4-Dihydroquinolin-1(2H)-Yl)Piperidine-1-Carbonyl)Phenoxy)Propyl)Acetamide",
      "ACMC-20mu6h",
      "AC1L3G6H",
      "SCHEMBL3504368",
      "GTPL2196",
      "CTK0H6673",
      "EX-A593",
    ],
  },
  {
    primary_id: "D08ORS",
    names: ["6-(Allyl-Methyl-Amino)-4,4-Diphenyl-Heptan-3-Ol", "CHEMBL171118"],
  },
  {
    primary_id: "D08ORV",
    names: ["AZD-4992"],
  },
  {
    primary_id: "D08OSM",
    names: [
      "3-(Furan-3-Yl)-1H-Indole",
      "CHEMBL381132",
      "155440-58-7",
      "3-(3-Furyl)Indole",
      "3-(3-Furanyl)-1H-Indole",
      "BDBM50182886",
    ],
  },
  {
    primary_id: "D08OTA",
    names: ["Folic"],
  },
  {
    primary_id: "D08OTE",
    names: ["RO-4995819"],
  },
  {
    primary_id: "D08OTG",
    names: ["Acteoside"],
  },
  {
    primary_id: "D08OUL",
    names: ["EGT-0001474"],
  },
  {
    primary_id: "D08OUR",
    names: [
      "2-Phenylpyrazolo-[1,5-A]pyridine-7-Carboxamide",
      "2-Phenylpyrazolo[1,5-A]pyridine-7-Carboxamide",
      "1196713-16-2",
      "ZINC64337832",
      "DA-47424",
    ],
  },
  {
    primary_id: "D08OUU",
    names: ["Genevx", "Ad5-VEGF145", "Restenosis Therapy, Collateral", "VEGF Gene Therapy, Collateral"],
  },
  {
    primary_id: "D08OUV",
    names: [
      "CHF-4227",
      "CHF-3316",
      "CHF-4056",
      "SERMs, Chiesi",
      "Selective Estrogen Receptor Modulators, Chiesi",
      "CHF-3316.01",
    ],
  },
  {
    primary_id: "D08OUX",
    names: ["IODOPRIDE"],
  },
  {
    primary_id: "D08OVG",
    names: ["CKD-516"],
  },
  {
    primary_id: "D08OVR",
    names: ["AMG 357"],
  },
  {
    primary_id: "D08OWA",
    names: ["NVC-704"],
  },
  {
    primary_id: "D08OWQ",
    names: ["SK&F-104078"],
  },
  {
    primary_id: "D08OWW",
    names: ["SLP-0906"],
  },
  {
    primary_id: "D08OXC",
    names: ["INDUS-82030"],
  },
  {
    primary_id: "D08OXD",
    names: ["Mannoside Derivative 8"],
  },
  {
    primary_id: "D08OXH",
    names: ["Quinoline And Quinazoline Derivative 1"],
  },
  {
    primary_id: "D08OXU",
    names: ["IMO-3100"],
  },
  {
    primary_id: "D08OYF",
    names: [
      "Ad/PSA",
      "Adenovirus/Prostate Specific Antigen Vaccine (Prostate Cancer), University Of Iowa/US Department Of Defense",
    ],
  },
  {
    primary_id: "D08OYH",
    names: [
      "O5-Acetyl-O7-Nitrooxyethyl Chrysin",
      "CHEMBL1099063",
      "1227681-47-1",
      "DTXSID50677009",
      "BDBM50318058",
      "7-[2-(Nitrooxy)Ethoxy]-4-Oxo-2-Phenyl-4H-1-Benzopyran-5-Yl Acetate",
    ],
  },
  {
    primary_id: "D08PAC",
    names: [
      "L-371257",
      "162042-44-6",
      "L-371,257",
      "CHEMBL24781",
      "L 371257",
      "1-[1-[4-(1-Acetylpiperidin-4-Yl)Oxy-2-Methoxybenzoyl]piperidin-4-Yl]-4H-3,1-Benzoxazin-2-One",
      "AC1OCFAC",
      "NCGC00159562-01",
      "SCHEMBL4954712",
      "GTPL2252",
      "CTK8E8747",
      "DTXSID30426072",
      "MolPort-023-276-417",
      "ZINC3824080",
      "BDBM50029649",
      "ABP001011",
      "AKOS024457081",
      "API0009977",
      "KB-78045",
      "1-[4-[(1-ACETYL-4-PIPERIDINYL)OXY]-2-METHOXYBENZOYL]-4-(2-OXO-2H-3,1-BENZOXAZIN-1(4H)-YL)PIPERIDINE",
      "RT-013479",
      "B7045",
      "L014161",
      "L371,257",
    ],
  },
  {
    primary_id: "D08PAV",
    names: ["CDP-860"],
  },
  {
    primary_id: "D08PBB",
    names: [
      "L-694,458",
      "DMP 777",
      "157341-41-8",
      "DMP-777",
      "UNII-3Q0469283P",
      "(S)-N-((R)-1-(Benzo[d][1,3]dioxol-5-Yl)Butyl)-3,3-Diethyl-2-(4-(4-Methylpiperazine-1-Carbonyl)Phenoxy)-4-Oxoazetidine-1-Carboxamide",
      "DMP777",
      "CHEMBL310871",
      "L-694458",
      "3Q0469283P",
      "(S)-N-((R)-1-(Benzo[d][1,3]dioxol-5-Yl)Butyl)-3,3-Diethyl-2-(4-(4methyl-Piperazine-1-Carbonyl)Phenoxy)-4-Oxoazetidine-1-Carboxamide (3.5%  W/W Solution)",
      "L 694458",
      "SCHEMBL6538142",
      "AC1L433D",
      "CTK4C9348",
      "DTXSID00166251",
      "MolPort-023-332-820",
      "C31H40N4O6",
      "ZINC3936307",
      "KS-00001E6Q",
      "BCP02269",
    ],
  },
  {
    primary_id: "D08PBQ",
    names: ["Pancreas Algenpantucel-L"],
  },
  {
    primary_id: "D08PCE",
    names: ["Oxantel Pamoate"],
  },
  {
    primary_id: "D08PCH",
    names: ["Javelin"],
  },
  {
    primary_id: "D08PCR",
    names: ["CM3.1-AC100", "CM-3, CellMed/AstraZeneca", "GLP-1 Analog (Diabetes), CellMed/AstraZeneca"],
  },
  {
    primary_id: "D08PDF",
    names: [
      "5-(Phenylethynyl)Pyrimidine",
      "71418-88-7",
      "CHEMBL486244",
      "5-(2-Phenylethynyl)Pyrimidine",
      "5-Phenylethynylpyrimidine",
      "SCHEMBL4860070",
      "DTXSID50399052",
      "ZINC198515",
      "AC1N4263",
      "BDBM50243108",
      "AKOS001674343",
      "MCULE-6083144775",
      "FCH1171193",
      "AJ-17844",
      "AX8280340",
      "SR-01000501737",
      "SR-01000501737-1",
    ],
  },
  {
    primary_id: "D08PDK",
    names: [
      "1-Bromo-6-(3-Hydroxyphenyl)-2-Naphthol",
      "CHEMBL467151",
      "SCHEMBL3009237",
      "WIVQIQQCUPQEGH-UHFFFAOYSA-N",
      "ZINC40876206",
      "BDBM50261887",
      "1-Bromo-6-(3-Hydroxyphenyl)Naphthalene-2-Ol",
    ],
  },
  {
    primary_id: "D08PEM",
    names: [
      "5-Phosphoribosyl-1-(Beta-Methylene) Pyrophosphate",
      "5-PHOSPHORIBOSYL-1-(BETA-METHYLENE) PYROPHOSPHATE",
      "AC1L9LOK",
      "DB04294",
      "1-O-[(R)-Hydroxy(Phosphonomethyl)Phosphoryl]-5-O-Phosphono-Alpha-D-Ribofuranose",
      "[[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Phosphonooxymethyl)Oxolan-2-Yl]oxy-Hydroxyphosphoryl]methylphosphonic Acid",
    ],
  },
  {
    primary_id: "D08PET",
    names: ["P70S6 Kinase Inhibitors"],
  },
  {
    primary_id: "D08PEW",
    names: ["N-(1H-Indol-2-Ylmethyl)-N-(4-Phenylbutyl)Amine", "CHEMBL455981"],
  },
  {
    primary_id: "D08PFA",
    names: [
      "Rivoglitazone",
      "CS-011",
      "DE-101",
      "Rivoglitazone (Ophthalmic)",
      "Rivoglitazone (Ophthalmic), Santen",
      "CS-011 (Ophthalmic), Santen",
    ],
  },
  {
    primary_id: "D08PGC",
    names: [
      "Andolast",
      "Dizolast",
      "CR-2039",
      "Calcium-Activated Potassium Channel Opener (Inhaled Powder, Asthma), Rottapharm",
    ],
  },
  {
    primary_id: "D08PHD",
    names: ["DR-5001"],
  },
  {
    primary_id: "D08PHH",
    names: ["PMID25435285-Compound-45"],
  },
  {
    primary_id: "D08PHY",
    names: ["Trans-2-Fluoro-2-(4-Fluorophenyl)Cyclopropanamine", "CHEMBL447729"],
  },
  {
    primary_id: "D08PHZ",
    names: ["Pyrazole Series, Malaria DrexelMed/University Of Washington/Medicines For Malaria Venture"],
  },
  {
    primary_id: "D08PIE",
    names: [
      "Brigatinib",
      "1197953-54-0",
      "UNII-HYW8DB273J",
      "Brigatinib [USAN]",
      "ALUNBRIG",
      "HYW8DB273J",
      "Brigatinib (USAN)",
      "Brigatiib",
      "Alunbrig (TN)",
      "Brigatinib [USAN:INN]",
      "Brigatinib (AP26113)",
      "SCHEMBL11916361",
      "KS-00000TSQ",
      "EX-A775",
      "MolPort-044-561-640",
      "BDBM50185140",
      "ZINC148723177",
      "AKOS030257612",
      "CS-4278",
      "DB12267",
      "HY-12857",
      "AC-29958",
    ],
  },
  {
    primary_id: "D08PIF",
    names: [
      "4-Methoxybenzenemethanethiol",
      "6258-60-2",
      "4-Methoxy-Alpha-Toluenethiol",
      "4-Methoxybenzyl Mercaptan",
      "4-Methoxybenzylmercaptan",
      "(4-Methoxyphenyl)Methanethiol",
      "4-Methoxy Benzylmercaptan",
      "Benzenemethanethiol, 4-Methoxy-",
      "P-Methoxybenzyl Mercaptan",
      "4-Methoxy Benzyl Mercaptan",
      "4-Methoxy-",
      "P-Methoxybenzylthiol",
      "P-Methoxybenzylmercaptan",
      "P-Methoxy-.alpha.-Toluenethiol",
      "4-Methoxy-.alpha.-Toluenethiol",
      "CHEMBL1224560",
      "PTDVPWWJRCOIIO-UHFFFAOYSA-N",
      "4-Methoxy-Alpha-Toluenethiol, 95%",
      "4-Methoxybenzylthiol",
      "EINECS 228-393-6",
    ],
  },
  {
    primary_id: "D08PIJ",
    names: ["JTV-506"],
  },
  {
    primary_id: "D08PIK",
    names: [
      "11C-GSK-215083",
      "11C-5-HT 6 Receptor Antagonist (Neurodegenerative Disease), GlaxoSmithKline",
      "11C-GSK-215083 PET Maging Agent (Neurodegenerative Disease), GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D08PIQ",
    names: ["Methylprednisolone Acetate"],
  },
  {
    primary_id: "D08PJK",
    names: ["[3H]5-CT"],
  },
  {
    primary_id: "D08PJN",
    names: ["ISIS 24453"],
  },
  {
    primary_id: "D08PJV",
    names: [
      "Boron-Based Compounds",
      "Boron-Based Compounds (Antimalarial Agent)",
      "Boron-Based Compounds (Antimalarial Agent), Anacor",
      "Boron-Based Compounds (Antimalarial Agent), GSK",
    ],
  },
  {
    primary_id: "D08PKF",
    names: ["AMI-HS"],
  },
  {
    primary_id: "D08PKG",
    names: ["LY2541546"],
  },
  {
    primary_id: "D08PKI",
    names: ["EV-077"],
  },
  {
    primary_id: "D08PKR",
    names: ["6-Imidazol-1-Yl-Isoquinoline", "6-(1H-Imidazol-1-Yl)Isoquinoline"],
  },
  {
    primary_id: "D08PKS",
    names: [
      "N-Guanyl-1,7-Diaminoheptane",
      "2-(7-Aminoheptyl)Guanidine",
      "150333-69-0",
      "CHEMBL229457",
      "Guanidine, N-(7-Aminoheptyl)-",
      "1-(7-Aminoheptyl)Guanidine",
      "N1-Guanyl-1,7-Diaminoheptane (GC7)",
      "AC1L9LWV",
      "N-(7-Aminoheptyl)Guanidine",
      "N-Guanyl-1,7-Heptanediamine",
      "SCHEMBL433609",
      "N-(7-Amino-Heptyl)-Guanidine",
      "CTK8H0316",
      "CHEBI:133366",
    ],
  },
  {
    primary_id: "D08PLK",
    names: ["Fremanezumab"],
  },
  {
    primary_id: "D08PLU",
    names: ["1-(9-Ethyl-9H-Carbazol-3-Yl)-N-Methylmethanamine"],
  },
  {
    primary_id: "D08PMB",
    names: [
      "Imeglimin",
      "775351-65-0",
      "UNII-UU226QGU97",
      "UU226QGU97",
      "Imeglimin [INN]",
      "Emd 387008 (R-Imeglimin) HCl",
      "SCHEMBL2158106",
      "SCHEMBL14868241",
      "C6H13N5",
      "DTXSID50228237",
      "BCP11085",
      "ZINC34380900",
      "3733AH",
      "AKOS027338676",
      "DB12509",
      "CS-1751",
      "HY-14771",
    ],
  },
  {
    primary_id: "D08PMD",
    names: [
      "N-(5-Hydroxycarbamoyl-Pentyl)-Benzamide",
      "CHEMBL143849",
      "SCHEMBL1232912",
      "BDBM50082658",
      "ZINC13801954",
      "M-354",
    ],
  },
  {
    primary_id: "D08PMW",
    names: ["BMS-223131", "4-(5-Chloro-2-Hydroxyphenyl)-3-(2-Hydroxyethyl)-6-(Trifluoromethyl)Quinolin-2(1H)-One"],
  },
  {
    primary_id: "D08PNF",
    names: [
      "3-[5-Methoxy-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 11b",
      "AC1O704R",
      "SCHEMBL4226034",
      "CHEMBL176101",
      "BDBM8594",
      "3-[(Z)-(5-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D08PNQ",
    names: ["Trombodipine"],
  },
  {
    primary_id: "D08PNW",
    names: ["JPH-101"],
  },
  {
    primary_id: "D08POF",
    names: ["ALX-0081"],
  },
  {
    primary_id: "D08POH",
    names: ["Invaplex 50"],
  },
  {
    primary_id: "D08PQU",
    names: ["PMID27724045-Compound-33"],
  },
  {
    primary_id: "D08PRP",
    names: ["IMGX003"],
  },
  {
    primary_id: "D08PSC",
    names: ["BI 860585"],
  },
  {
    primary_id: "D08PSI",
    names: [
      "H5N1 Influenza Vaccine",
      "Avian Influenza Vaccine (VLP), Medicago",
      "H5N1 Influenza Vaccine (Injectable/Virus-Like Particle)",
      "Influenza Vaccine (Hemagglutinin-Based), Medicago",
      "H5N1 Influenza Vaccine (Injectable/Virus-Like Particle), Medicago",
    ],
  },
  {
    primary_id: "D08PSU",
    names: ["Lmw Heparin"],
  },
  {
    primary_id: "D08PSV",
    names: ["CIGB-428"],
  },
  {
    primary_id: "D08PUJ",
    names: [
      "2(S)-Amino-6-Boronohexanoic Acid",
      "222638-65-5",
      "6-Borono-L-Norleucine",
      "6-(Dihydroxyboryl)-L-Norleucine",
      "(2S)-2-Amino-6-(Dihydroxyboranyl)Hexanoic Acid",
      "CHEBI:40520",
      "(2S)-2-Amino-6-Boronohexanoic Acid",
      "L-Norleucine, 6-Borono-",
      "SCHEMBL215523",
      "GTPL5107",
      "CTK1A1729",
      "DTXSID20430749",
      "BDBM130378",
      "(S)-2-Amino-6-Boronohexanoic Acid",
      "(R)-2-Amino-6-Boronohexanoic Acid",
      "ZINC169946036",
      "AKOS006284705",
      "2(S)-Amino-6-Boronohexanoic Acid (ABH)",
      "(2S)-2-Amino-6-(Dihydroxyboryl)Hexanoic Acid",
    ],
  },
  {
    primary_id: "D08PUW",
    names: [
      "BAICALIN",
      "Baicalin",
      "21967-41-9",
      "Baicalein 7-O-Glucuronide",
      "7-D-Glucuronic Acid-5,6-Dihydroxyflavone",
      "CHEBI:2981",
      "CHEMBL485818",
      "UNII-347Q89U4M5",
      "5,6-Dihydroxy-4-Oxo-2-Phenyl-4H-Chromen-7-Yl Beta-D-Glucopyranosiduronic Acid",
      "Baicalin Hydrate",
      "347Q89U4M5",
      "(2S,3S,4S,5R,6S)-6-((5,6-Dihydroxy-4-Oxo-2-Phenyl-4H-Chromen-7-Yl)Oxy)-3,4,5-Trihydroxytetrahydro-2H-Pyran-2-Carboxylic Acid",
      "5,6,7-Trihydroxyflavone 7-O-Beta-D-Glucuronide",
      "BAICAILIN",
      "Beta-D-Glucopyranosiduronic Acid, 5,6-Dihydroxy-4-Oxo-2-Phenyl-4H-1-Benzopyran-7-Yl",
      "31564-28-0",
    ],
  },
  {
    primary_id: "D08PVL",
    names: ["Ibulocydine"],
  },
  {
    primary_id: "D08PVY",
    names: ["Ribostamycin"],
  },
  {
    primary_id: "D08PWL",
    names: ["N-N-Propyl-7-Methoxytacrine Hydrochloride", "CHEMBL1257996"],
  },
  {
    primary_id: "D08PXF",
    names: ["Aminopyrimidine Derivative 1"],
  },
  {
    primary_id: "D08PXL",
    names: ["MK-8457"],
  },
  {
    primary_id: "D08PXM",
    names: ["MDX-214", "CD89 Monoclonal Antibody (Cancer, Autoimmune Disease), Medarex"],
  },
  {
    primary_id: "D08PXR",
    names: [
      "ABT-761",
      "Atreleuton",
      "Atreleuton [USAN]",
      "ABT 761",
      "A-85761",
      "Atreleuton (USAN/INN)",
      "1-[(2R)-4-[5-[(4-Fluorophenyl)Methyl]thiophen-2-Yl]but-3-Yn-2-Yl]-1-Hydroxyurea",
    ],
  },
  {
    primary_id: "D08PXU",
    names: [
      "4-Amino-3-(5-Bromo-Thiophen-2-Yl)-Butyric Acid",
      "CHEMBL314177",
      "SCHEMBL340904",
      "Beta-(5-Bromo-2-Thienyl)GABA",
      "BDBM50007108",
      "AKOS022983047",
      "4-Amino-3-(5-Bromothien-2-Yl)Butanoic Acid",
    ],
  },
  {
    primary_id: "D08PYC",
    names: ["AL-38583"],
  },
  {
    primary_id: "D08PYJ",
    names: ["MAGGIEMYCIN"],
  },
  {
    primary_id: "D08PYO",
    names: ["KW-2478"],
  },
  {
    primary_id: "D08PYQ",
    names: ["EDL-2000"],
  },
  {
    primary_id: "D08PZF",
    names: ["Liposomal Disintergrin"],
  },
  {
    primary_id: "D08PZX",
    names: ["(R)-3-Propyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL186985"],
  },
  {
    primary_id: "D08QAC",
    names: [
      "Ferumoxides",
      "AMI-25",
      "119683-68-0",
      "Feridex",
      "Feridex IV",
      "Superparamagnetic Iron Oxide",
      "UNII-G6N3J05W84",
      "Ferumoxides [USAN:USP:BAN]",
      "CCRIS 6722",
      "HSDB 8072",
      "AC1O5DID",
      "G6N3J05W84",
      "Iron(2+)",
      "Iron(3+)",
      "Iron Oxide Crystal Is Inverse Spinel (X-Ray Data)",
      "Fe(II) And Fe(III) Are Present (Mossbauer Spectroscopy",
      "Physical Form Is A Colloidal Particle Of Nonstoichiometric",
    ],
  },
  {
    primary_id: "D08QAH",
    names: [
      "2-(3'-Ethyl-Biphenyl-4-Yl)-Propionic Acid",
      "CHEMBL106203",
      "BDBM50074929",
      "2-(3'-Ethyl-4-Biphenylyl)Propanoic Acid",
      "2-(3''-Ethyl-Biphenyl-4-Yl)-Propionic Acid",
    ],
  },
  {
    primary_id: "D08QBO",
    names: ["PMID28460551-Compound-10"],
  },
  {
    primary_id: "D08QBS",
    names: ["MK-1925", "CHEMBL560667", "SCHEMBL3540877", "BDBM50296579"],
  },
  {
    primary_id: "D08QBU",
    names: ["1-Benzhydryl-4-(3-Phenylpropyl)Piperidin-4-Ol", "CHEMBL231664"],
  },
  {
    primary_id: "D08QCI",
    names: [
      "3-Hydroxy-3-Methyl-6-Phosphohexanoic Acid",
      "3-Hydroxy-3-Methyl-6-Phosphonohexanoic Acid",
      "63370-13-8",
      "GTPL3202",
      "SCHEMBL11310701",
      "CTK2A9401",
      "DTXSID80634286",
      "Hexanoic Acid, 3-Hydroxy-3-Methyl-6-Phosphono-",
    ],
  },
  {
    primary_id: "D08QCJ",
    names: [
      "Thiabendazole",
      "Biogard",
      "Bioguard",
      "Bovizole",
      "Cropasal",
      "Drawipas",
      "Eprofil",
      "Equizole",
      "Hymush",
      "Lombristop",
      "Mertec",
      "Mertect",
      "Mintesol",
      "Mintezol",
      "Mintezole",
      "Minzolum",
      "Mycozol",
      "Nemacin",
      "Nemapan",
      "Omnizole",
      "Ormogal",
      "Pitrizet",
      "Polival",
      "RPH",
      "Sistesan",
      "Storite",
      "Tbdz",
      "Tebuzate",
      "Tecto",
      "Testo",
      "Thiaben",
      "Thiabendazol",
      "Thiabendazolum",
      "Thiabendole",
      "Thiabenzazole",
      "Thiabenzole",
      "Thibendole",
      "Thibenzol",
      "Thibenzole",
      "Thiprazole",
      "Tiabenda",
      "Tiabendazol",
      "Tiabendazole",
      "Tiabendazolum",
      "Tobaz",
      "Tresaderm",
      "Triasox",
      "Tubazole",
      "Captan T",
      "Equivet TZ",
      "Equizole A",
      "Helmindrax Octelmin",
      "Hokustar HP",
      "Mertect Lsp",
      "RTU Flowable Fungicide",
      "Tecto B",
      "Tecto Rph",
      "Thibenzole Att",
      "Top Form Wormer",
      "Chemviron TK 100",
      "G 491",
      "MK 360",
      "Mertect 160",
      "Mertect 340f",
      "Metasol TK 100",
      "Metasol Tk 10",
      "PS1057_SUPELCO",
      "Sanaizol 100",
      "Syntol M100",
      "TBZ 6",
      "TBZ 60W",
      "Tecto 10P",
      "Tecto 40F",
      "Tecto 60",
      "Thibenzole 200",
      "Tibimix 20",
      "APL-Luster",
      "Arbotect (TN)",
      "MK-360",
      "Metasol TK-100",
      "Mintezol (TN)",
      "TBZ-6",
      "Thiabendazole (USP)",
      "Thiabendazole [BSI:ISO]",
      "Thiabendazole [USAN:BAN]",
      "Tiabendazolum [INN-Latin]",
      "Tresaderm (TN)",
      "E-Z-Ex",
      "Mintezol, Equizole, Thiabendazole",
      "Tiabendazol [INN-Spanish, French]",
      "Tiabendazole (JAN/INN)",
      "2-(1,3-Thiazol-4-Yl)-1H-Benzimidazole",
      "2-(1,3-Thiazol-4-Yl)Benzimidazole",
      "2-(1,3-Thiazol-4-Yl)-1H-1,3-Benzodiazole",
      "2-(4'-Thiazolyl)Benzimidazole",
      "2-(4-Thiazolyl)-1H-Benzimidazole",
      "2-(4-Thiazolyl)Benzimidazole",
      "2-(Thiazol-4-Yl)Benzimidazole",
      "2-Thiazol-4-Yl-1H-Benzoimidazole",
      "2-Thiazole-4-Ylbenzimidazole",
      "2-[4-Thiazoly]benzimidazole",
      "4-(1H-Benzimidazol-2-Yl)-1,3-Thiazole",
      "4-(2-Benzimidazolyl)Thiazole",
    ],
  },
  {
    primary_id: "D08QCQ",
    names: [
      "4-(5-Tert-Butyl-[1,3]dithian-2-Yl)-Benzonitrile",
      "CHEMBL90342",
      "SCHEMBL8950639",
      "ZINC584599047",
      "ZINC584599044",
    ],
  },
  {
    primary_id: "D08QDB",
    names: ["KSI-4088"],
  },
  {
    primary_id: "D08QDF",
    names: ["Systebryl"],
  },
  {
    primary_id: "D08QDL",
    names: ["[11C]GlySar", "[11C]-GlySar", "[11C]glycylsarcosine"],
  },
  {
    primary_id: "D08QDM",
    names: ["HyperAcute Pancreas"],
  },
  {
    primary_id: "D08QED",
    names: ["TAK-850"],
  },
  {
    primary_id: "D08QEF",
    names: [
      "CEP-37250",
      "ART-104",
      "KHK-2804",
      "SC-104, Arana",
      "SC-104, Peptech",
      "SC-104, Scancell",
      "Anti-Tumor-Associated Glycoprotein/Glucolipid Epitope Monoclonal Antibody (Cancer), Scancell",
    ],
  },
  {
    primary_id: "D08QGD",
    names: [
      "Ammonium Lactate",
      "Ammonium Lactate)",
      "BMS-186091",
      "Lac-Hydrin",
      "Ammonium Lactate, Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D08QGE",
    names: ["SI-614"],
  },
  {
    primary_id: "D08QGR",
    names: ["PMID26560530-Compound-34"],
  },
  {
    primary_id: "D08QGT",
    names: ["GDC0941"],
  },
  {
    primary_id: "D08QHJ",
    names: ["UPAR-Lock"],
  },
  {
    primary_id: "D08QHS",
    names: ["DG041", "DTSI", "D-000151746", "DG-041"],
  },
  {
    primary_id: "D08QHX",
    names: ["PRX00933"],
  },
  {
    primary_id: "D08QIP",
    names: [
      "Pranlukast",
      "Azlaire",
      "Azlaire (TN)",
      "Pranlukast (INN)",
      "N-[4-Oxo-2-(2H-Tetrazol-5-Yl)Chromen-8-Yl]-4-(4-Phenylbutoxy)Benzamide",
    ],
  },
  {
    primary_id: "D08QIR",
    names: [
      "H2S-Releasing Latanoprost",
      "ACS-67",
      "H2S-Releasing Latanoprost (Ocular Disease)",
      "H2S-Releasing Latanoprost (Ocular Disease), CTG Pharma",
    ],
  },
  {
    primary_id: "D08QJD",
    names: ["IGN-311"],
  },
  {
    primary_id: "D08QJS",
    names: ["Carbazochrome Salicylate"],
  },
  {
    primary_id: "D08QKF",
    names: ["NTzDpa"],
  },
  {
    primary_id: "D08QKJ",
    names: ["Stanozolol"],
  },
  {
    primary_id: "D08QKT",
    names: ["Tyr-D-Nle-Gly-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D08QKY",
    names: ["IXS-312"],
  },
  {
    primary_id: "D08QLC",
    names: [
      "2-Amino-6-(2-Cyano-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(2-Cyanophenylthio)Benzenecarbonitrile",
      "AC1LAE0Z",
      "CHEMBL59586",
      "BDBM1744",
      "CTK7C8878",
      "ZINC5933866",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1n",
      "2-Amino-6-(2-Cyanophenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(2-Cyanophenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D08QLL",
    names: ["AG-NDT802"],
  },
  {
    primary_id: "D08QME",
    names: [
      "Ethambutol",
      "Aethambutolum",
      "Diambutol",
      "EMB",
      "Etambutol",
      "Etambutolo",
      "Ethambutolum",
      "Etibi",
      "Miambutol",
      "Myambutol",
      "Purderal",
      "Servambutol",
      "Tibutol",
      "Etambutolo [DCIT]",
      "CL 40881 (Dihydrochloride)",
      "D-Ethambutol",
      "Etambutol [INN-Spanish]",
      "Ethambutol & EEP",
      "Ethambutol & Propolis",
      "Ethambutol (INN)",
      "Ethambutol [INN:BAN]",
      "Ethambutol, Meso Form",
      "Ethambutol, Racemic Mixture",
      "Ethambutolum [INN-Latin]",
      "Myambutol (TN)",
      "Myambutol (Dihydrochloride)",
      "Servambutol (TN)",
      "Ethambutol, (-)-Isomer",
      "D,N,N'-Bis(1-Hydroxymethylpropyl)Ethylenediamine",
      "D-N,N'-Bis(1-Hydroxymethylpropyl)Ethylenediamine",
      "D-2,2'-(Ethylenediimino)Bis(1-Butanol)",
      "D-2,2'-(Ethylenediimino)Di-1-Butanol",
      "(+)-(S,S)-2,2'-(1,2-Ethylenediimino)-Di-1-Butanol",
      "(+)-2,2'-(Ethylenediimino)Di-1-Butanol",
      "(+)-N,N'-Bis(1-(Hydroxymethyl)Propyl)Ethylenediamine",
      "(+)-S,S-Ethambutol",
      "(+)-Ethambutol",
      "(-)-(R,R)-2,2'-(1,2-Ethylenediimino)-Di-1-Butanol",
      "(2'S)-2,2'-[Ethane-1,2-Diyldi(Imino)]dibutan-1-Ol",
      "(2R)-2-[2-(1-Hydroxybutan-2-Ylamino)Ethylamino]butan-1-Ol",
      "(2R)-2-[2-[[(2R)-1-Hydroxybutan-2-Yl]amino]ethylamino]butan-1-Ol",
      "(2S)-2-[2-(1-Hydroxybutan-2-Ylamino)Ethylamino]butan-1-Ol",
      "(2S)-2-[2-[[(2R)-1-Hydroxybutan-2-Yl]amino]ethylamino]butan-1-Ol",
      "(2S,2'S)-2,2'-(Ethane-1,2-Diyldiimino)Dibutan-1-Ol",
      "(2S,7S)-2,7-Diethyl-3,6-Diazaoctane-1,8-Diol",
      "(R)-2,2'-(1,2-Ethanediyldiimino)Bis-1-Butanol",
      "(S,R)-2,2'-(1,2-Ethylenediimino)-Di-1-Butanol",
      "(S,S)-Ethambutol",
      "2,2'-(1,2-Ethylenediimino)-Di-1-Butanol",
      "2,2'-(Ethane-1,2-Diyldiimino)Dibutan-1-Ol",
      "2-[2-(1-Hydroxybutan-2-Ylamino)Ethylamino]butan-1-Ol",
    ],
  },
  {
    primary_id: "D08QMJ",
    names: ["Heterocyclic Derivative 26"],
  },
  {
    primary_id: "D08QMX",
    names: [
      "Estradiol",
      "Aerodiol",
      "Alora",
      "Altrad",
      "Aquadiol",
      "Bardiol",
      "Climaderm",
      "Climara",
      "Compudose",
      "Corpagen",
      "Dermestril",
      "Destradiol",
      "Dihydrofolliculin",
      "Dihydromenformon",
      "Dihydrotheelin",
      "Dihydroxyesterin",
      "Dihydroxyestrin",
      "Dihydroxyoestrin",
      "Dimenformon",
      "Diogyn",
      "Diogynets",
      "Divigel",
      "Elestrin",
      "Encore",
      "Esclim",
      "Estrace",
      "Estraderm",
      "Estradiolo",
      "Estradiolum",
      "Estradot",
      "Estraldine",
      "Estrasorb",
      "Estreva",
      "Estrifam",
      "Estring",
      "Estroclim",
      "Estrodiolum",
      "Estrogel",
      "Estrovite",
      "Evamist",
      "Evorel",
      "Extrasorb",
      "Femanest",
      "Femestral",
      "Femestrol",
      "Fempatch",
      "Femtran",
      "Follicyclin",
      "Gelestra",
      "Ginedisc",
      "Ginosedol",
      "GynPolar",
      "Gynergon",
      "Gynestrel",
      "Gynodiol",
      "Gynoestryl",
      "Innofem",
      "Lamdiol",
      "Macrodiol",
      "Macrol",
      "Menest",
      "Menorest",
      "Microdiol",
      "Nordicol",
      "Oesclim",
      "Oestergon",
      "Oestradiol",
      "Oestradiolum",
      "Oestrogel",
      "Oestroglandol",
      "Oestrogynal",
      "Ovahormon",
      "Ovasterol",
      "Ovastevol",
      "Ovociclina",
      "Ovocyclin",
      "Ovocycline",
      "Ovocylin",
      "Perlatanol",
      "Polyestradiol",
      "Primofol",
      "Profoliol",
      "Progynon",
      "Syndiol",
      "Systen",
      "Tradelia",
      "Trocosone",
      "Vagifem",
      "Vivelle",
      "Zerella",
      "Zesteem",
      "Zesteen",
      "Zumenon",
      "Climara Forte",
      "Component Of Menrium",
      "Estraderm MX",
      "Estraderm TTS",
      "Estradiolo [DCIT]",
      "Estradiolum [INN]",
      "Estring Vaginal Ring",
      "Estrofem Forte",
      "Oestradiol Berco",
      "Oestradiol R",
      "Progynon DH",
      "Sandrena Gel",
      "Sisare Gel",
      "Trial SAT",
      "CMC_11154",
      "Compudose 200",
      "Compudose 365",
      "E 2",
      "E 8875",
      "E0025",
      "Epiestriol 50",
      "Estraderm TTS 100",
      "Estraderm TTS 50",
      "Estrapak 50",
      "Estroclim 50",
      "Estrofem 2",
      "Sandrena 1",
      "[3H]]estradiol",
      "Activella (TN)",
      "Alora (TN)",
      "Alpha-Oestradiol",
      "AngeliQ (TN)",
      "B-Estradiol",
      "Beta-Estradiol",
      "Beta-Oestradiol",
      "Cis-Estradiol",
      "Cis-Oestradiol",
      "Climara (TN)",
      "D-Estradiol",
      "D-Oestradiol",
      "Divigel (TN)",
      "E(Sub 2)",
      "Elestrin (TN)",
      "Estrace (TN)",
      "Estraderm (TN)",
      "Estraderm TTS (TN)",
      "Estradiol [USAN:INN]",
      "Estradiol Acetate (TN)",
      "Estradiol Cypionate (TN)",
      "Estradiol Valerate (TN)",
      "Estradiol-17 Beta",
      "Estradiol-17beta",
      "Estrasorb (TN)",
      "Estrasorb Topical (TN)",
      "Estring (TN)",
      "Estrofem (TN)",
      "Estrogel (TN)",
      "EvaMist (TN)",
      "Femring (TN)",
      "Innofem (TN)",
      "Menostar (TN)",
      "Oestradiol-17beta",
      "Progynon-DH",
      "Progynova (TN)",
      "S-21400",
      "SK-Estrogens",
      "SL-1100",
      "VIVELLE-DOT",
      "Vagifem (TN)",
      "Vivelle (TN)",
      "[3H]-Estradiol",
      "Estradiol-17-Beta",
      "Estradiol-3,17beta",
      "Oestradiol-17-Beta",
      "Vivelle-Dot (TN)",
      "D-3,17beta-Estradiol",
      "[2,4,6,7-3H]-E2",
      "17 Beta-Estradiol",
      "17-.BETA.-Estradiol",
      "17-Beta-OH-Estradiol",
      "17-Beta-Estradiol",
      "17.beta.-Estradiol",
      "17.beta.-Oestradiol",
      "17b-Oestradiol",
      "17beta Oestradiol",
      "17beta-Estradiol",
      "17beta-Oestradiol",
      "3,17-Beta-Estradiol",
      "3,17-Beta-Oestradiol",
      "3,17.beta.-Estradiol",
      "3,17beta-Estradiol",
    ],
  },
  {
    primary_id: "D08QPJ",
    names: ["MBI-2401"],
  },
  {
    primary_id: "D08QPU",
    names: ["ML398"],
  },
  {
    primary_id: "D08QQR",
    names: [
      "Beta-L-Fucose",
      "BETA-L-FUCOSE",
      "Beta-L-Fucopyranose",
      "6-Deoxy-Beta-L-Galactopyranose",
      "Beta-L-Fuc",
      "6-DEOXY-BETA-L-GALACTOSE",
      "UNII-3MLV1G4S4M",
      "3MLV1G4S4M",
      "13224-93-6",
      "(2S,3S,4R,5S,6S)-6-Methyloxane-2,3,4,5-Tetrol",
      "FUL",
      "MFB",
      "1rdj",
      "1ofz",
      "AC1L9H0R",
      "SCHEMBL1259620",
      "CHEBI:42589",
      "Beta-L-Galactopyranose, 6-Deoxy-",
      "ZINC1532813",
      "AKOS024286278",
      "DB03283",
      "CJ-24261",
      "ST092737",
      "AJ-26816",
      "C20836",
      "A817260",
      "WURCS=2.0/1,1,0/[a1221m-1b_1-5]/1/",
    ],
  },
  {
    primary_id: "D08QRE",
    names: ["MB-11319", "Anti-Inflammatory/Anticancer Agent (Prostatitis/Allergy/Prostate Cancer), Mazence"],
  },
  {
    primary_id: "D08QRO",
    names: ["BL-6020"],
  },
  {
    primary_id: "D08QRT",
    names: ["99mTc-Siboroxime"],
  },
  {
    primary_id: "D08QSN",
    names: ["(+/-)-TRANS-U-50488 METHANESULFONATE"],
  },
  {
    primary_id: "D08QTK",
    names: ["Anti-EGFL7"],
  },
  {
    primary_id: "D08QTP",
    names: [
      "BMS-433771",
      "AIDS161903",
      "Bms 433771",
      "1-Cyclopropyl-3-[[1-(3-Hydroxypropyl)Benzimidazol-2-Yl]methyl]imidazo[4,5-C]pyridin-2-One",
      "1-Cyclopropyl-3-[[1-(4-Hydroxybutyl)Benzimidazol-2-Yl]methyl]imidazo[4,5-C]pyridin-2-One",
    ],
  },
  {
    primary_id: "D08QUC",
    names: ["OT-121"],
  },
  {
    primary_id: "D08QUJ",
    names: ["Histone Deacetylase Inhibitors"],
  },
  {
    primary_id: "D08QUV",
    names: [
      "Amineptine",
      "Amineptin",
      "Amineptino",
      "Amineptinum",
      "Amineptine [INN]",
      "S 1694",
      "Amineptine (INN)",
      "Amineptino [INN-Spanish]",
      "Amineptinum [INN-Latin]",
      "Survector (TN)",
      "10,11-Dihydrodibenzo(A,d)Cyclohept-5-Enyl-7-Aminoheptanoic Acid",
      "7-((10,11-Dihydro-5H-Dibenzo(A,d)Cyclohepten-5-Yl)Amino)Heptanoic Acid",
      "7-(10,11-Dihydro-5H-Dibenzo[a,d]cyclohepten-5-Ylamino)Heptanoic Acid",
    ],
  },
  {
    primary_id: "D08QVM",
    names: ["NU-400"],
  },
  {
    primary_id: "D08QVY",
    names: [
      "5-Hydroxymethyl-3-Pyrrol-1-Yl-Oxazolidin-2-One",
      "CHEMBL17653",
      "406934-09-6",
      "2-Oxazolidinone, 5-(Hydroxymethyl)-3-(1H-Pyrrol-1-Yl)-, (5R)-",
      "CTK1C9350",
      "DTXSID00452964",
      "BDBM50110714",
      "BDBM50370193",
    ],
  },
  {
    primary_id: "D08QWQ",
    names: ["Bimatoprost (Free Acid Form)", "17-Phenyl-Omega-Trinor-PGF2alpha"],
  },
  {
    primary_id: "D08QWR",
    names: [
      "4-Benzylphthalazin-1(2H)-One",
      "4-Benzyl-1(2H)-Phthalazinone",
      "32003-14-8",
      "4-Benzyl-2H-Phthalazin-1-One",
      "4-Benzyl-1,2-Dihydrophthalazin-1-One",
      "CHEMBL66761",
      "JUCCMEHWBGPJKS-UHFFFAOYSA-N",
      "Benzylphthalazinone",
      "Phthalazinone, 1",
      "4-Benzyl-Phthalazone",
      "AC1LDDNC",
      "SMR000135223",
      "AC1Q6GZZ",
      "ChemDiv2_000142",
      "Cambridge Id 5241846",
      "Oprea1_151142",
      "Oprea1_623913",
      "CBDivE_015258",
      "MLS000530246",
      "SCHEMBL863462",
      "CTK4G8063",
      "BDBM27660",
      "DTXSID30346948",
      "MolPort-001-796-654",
      "HMS2379K10",
      "HMS1369G10",
      "4-Benzyl-2-Hydrophthalazin-1-One",
      "4-Benzyl-1(2H)-Phthalazinone #",
    ],
  },
  {
    primary_id: "D08QXD",
    names: ["L-783483"],
  },
  {
    primary_id: "D08QXE",
    names: ["HP-161"],
  },
  {
    primary_id: "D08QYJ",
    names: ["SORETOLIDE", "D-2916", "Soretolide", "2,6-Dimethyl-N-(5-Methylisozaxol-3-Yl)Benzamide"],
  },
  {
    primary_id: "D08QYL",
    names: ["ISV-102"],
  },
  {
    primary_id: "D08QYS",
    names: ["Heat Shock Protein Vaccine"],
  },
  {
    primary_id: "D08QYW",
    names: ["CAR-T Cells Targeting EpCAM"],
  },
  {
    primary_id: "D08QZD",
    names: [
      "Y-40613",
      "CHEMBL281124",
      "SCHEMBL7152045",
      "BDBM50098847",
      "2-(2-{2-[5-Amino-2-(4-Fluoro-Phenyl)-6-Oxo-6H-Pyrimidin-1-Yl]-Acetylamino}-3-Phenyl-Propionyl)-Benzooxazole-5-Carboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D08QZH",
    names: ["Anti-HER2 CAR-T"],
  },
  {
    primary_id: "D08QZP",
    names: [
      "N-((1H-Indol-2-Yl)Methyl)(Phenyl)Methanamine",
      "CHEMBL45511",
      "Oprea1_729586",
      "SCHEMBL4104078",
      "MolPort-003-806-990",
      "ZINC13807947",
      "BDBM50087789",
      "AKOS006041743",
      "2-[(Phenylmethylamino)-Methyl]-Indole",
      "Benzyl-(1H-Indol-2-Ylmethyl)-Amine (FA4)",
    ],
  },
  {
    primary_id: "D08RAK",
    names: ["Japanese Cedar Pollen Allergy Sublingual Immunotherapy"],
  },
  {
    primary_id: "D08RAL",
    names: ["ANA-504", "Antibody Program (SHM), AnaptyBio/Roche"],
  },
  {
    primary_id: "D08RAZ",
    names: [
      "N-Methylbicuculline",
      "(5s)-6,6-Dimethyl-5-[(6r)-6-Methyl-8-Oxo-6,8-Dihydrofuro[3,4-E][1,3]benzodioxol-6-Yl]-5,6,7,8-Tetrahydro[1,3]dioxolo[4,5-G]isoquinolin-6-Ium Chloride",
      "71538-43-7",
      "AC1Q1SMD",
      "C21H20NO6.Cl",
      "AC1L3X24",
      "CTK8E0474",
      "DTXSID20276265",
      "LS-174735",
      "(6R)-6-[(5S)-6,6-Dimethyl-7,8-Dihydro-5H-[1,3]dioxolo[4,5-G]isoquinolin-6-Ium-5-Yl]-6-Methylfuro[3,4-G][1,3]benzodioxol-8-One Chloride",
    ],
  },
  {
    primary_id: "D08RBC",
    names: ["Escitalopram"],
  },
  {
    primary_id: "D08RBS",
    names: ["WX-037"],
  },
  {
    primary_id: "D08RBV",
    names: ["Autologous Dendritic Cell Vaccine, St Anna Children's Hospital"],
  },
  {
    primary_id: "D08RBW",
    names: ["LY900014"],
  },
  {
    primary_id: "D08RCH",
    names: ["KF-A6"],
  },
  {
    primary_id: "D08RCI",
    names: ["CRTX-072"],
  },
  {
    primary_id: "D08RCQ",
    names: ["5-(4-Methoxyphenyl)-4-P-Tolylisothiazole", "CHEMBL514950"],
  },
  {
    primary_id: "D08RCS",
    names: ["2-Methoxy-4-(Propylthio)Benzonitrile", "CHEMBL443970"],
  },
  {
    primary_id: "D08RCV",
    names: ["Pyrido[2,3-D]pyrimidine Derivative 2"],
  },
  {
    primary_id: "D08RDB",
    names: ["Polyamine", "Antizyme Modulators, Oridigm", "Polyamine Analogs, Mediquest", "Polyamine Analogs, Oridigm"],
  },
  {
    primary_id: "D08RDC",
    names: ["R-765"],
  },
  {
    primary_id: "D08RDD",
    names: ["PF-06823859"],
  },
  {
    primary_id: "D08RDG",
    names: ["PBI-4419", "Antifibrotic Agents, ProMetic"],
  },
  {
    primary_id: "D08RDI",
    names: ["OF-1"],
  },
  {
    primary_id: "D08RDK",
    names: ["ISIS 114371"],
  },
  {
    primary_id: "D08RET",
    names: ["PMID30107136-Compound-Example47"],
  },
  {
    primary_id: "D08RFA",
    names: ["TAK-816"],
  },
  {
    primary_id: "D08RFH",
    names: [
      "Cis-1-Adamantan-1-Yl-3-(4-Methoxycyclohexyl)Urea",
      "CHEMBL397669",
      "CHEMBL395987",
      "SCHEMBL3262525",
      "SCHEMBL3262523",
      "BDBM50217464",
      "BDBM50217445",
    ],
  },
  {
    primary_id: "D08RFZ",
    names: ["RSV MAb", "D25", "SP0232"],
  },
  {
    primary_id: "D08RGA",
    names: ["AZD6370"],
  },
  {
    primary_id: "D08RGW",
    names: ["3-Benzyl-7-Methyl-[1,8]naphthyridine-4-Thiol", "CHEMBL427178"],
  },
  {
    primary_id: "D08RHR",
    names: ["Optaflu"],
  },
  {
    primary_id: "D08RHU",
    names: ["Cissampeline"],
  },
  {
    primary_id: "D08RIB",
    names: [
      "Edetate Disodium",
      "EDTA Disodium Dihydrate",
      "Disodium EDTA Dihydrate",
      "Disodium Edetate Dihydrate",
      "Disodium (Ethylenedinitrilo)Tetraacetate Dihydrate",
      "Ethylenediaminetetraacetic Acid Disodium Salt Dihydrate",
      "6381-92-6",
      "Na2H2EDTA.2H2O",
      "CHEBI:64758",
      "Disodium 2,2',2'',2'''-(Ethane-1,2-Diyldiammonio)Tetraacetate--Water (1/2)",
    ],
  },
  {
    primary_id: "D08RID",
    names: ["MK-8616"],
  },
  {
    primary_id: "D08RIL",
    names: ["GKT-03"],
  },
  {
    primary_id: "D08RIW",
    names: ["F-G-G-F-T-G-Aib-R-K-S-A-R-K-L-A-N-Q-CONH2"],
  },
  {
    primary_id: "D08RJC",
    names: ["PD-32577"],
  },
  {
    primary_id: "D08RJM",
    names: ["SD-101"],
  },
  {
    primary_id: "D08RJY",
    names: ["Anti-EGFRvIII CAR Transduced PBL"],
  },
  {
    primary_id: "D08RKK",
    names: ["IODIDE"],
  },
  {
    primary_id: "D08RKZ",
    names: ["IN0-3106"],
  },
  {
    primary_id: "D08RLR",
    names: ["SC-54701A"],
  },
  {
    primary_id: "D08RLS",
    names: ["2-M-Tolyl-1,8-Naphthyridine", "CHEMBL395902"],
  },
  {
    primary_id: "D08RMH",
    names: ["APC-2059", "BAY-44-3965"],
  },
  {
    primary_id: "D08RMJ",
    names: ["GSK461364"],
  },
  {
    primary_id: "D08RMK",
    names: [
      "N-(4'-Methyl-4-Biphenylyl)Urea",
      "CHEMBL244125",
      "SCHEMBL5428805",
      "N-(4''-Methyl-4-Biphenylyl)Urea",
      "ZINC28712009",
    ],
  },
  {
    primary_id: "D08RNV",
    names: ["Aryl Oxyanilide Derivative 2"],
  },
  {
    primary_id: "D08RNW",
    names: ["2-Hydroxy-2-Phenyl-Nonanoic Acid Amide", "CHEMBL287713"],
  },
  {
    primary_id: "D08ROP",
    names: [
      "Leflunomide",
      "Arava",
      "Leflunomid",
      "Leflunomida",
      "Leflunomidum",
      "Lefunamide",
      "Aventis Behring Brand Of Leflunomide",
      "Aventis Brand Of Leflunomide",
      "Aventis Pharma Brand Of Leflunomide",
      "Hoechst Brand Of Leflunomide",
      "HWA 486",
      "L 5025",
      "SU 101",
      "SU101",
      "Arava (TN)",
      "Arava, Leflunomide",
      "HWA-486",
      "Leflunomida [INN-Spanish]",
      "Leflunomide [USAN:INN]",
      "Leflunomidum [INN-Latin]",
      "Lefunomide [Inn-Spanish]",
      "RS-34821",
      "SU 101 (Pharmaceutical)",
      "SU-101",
      "AP-501/42475599",
      "Leflunomide (JAN/USAN/INN)",
      "N-(4-Trifluoromethyphenyl)-5-Methylisoxazole-4-Carboxamide",
      "N-(4'-Trifluoromethylphenyl)-5-Methylisoxazole-4-Carboxamide",
      "Alpha,alpha,alpha-Trifluoro-5-Methyl-4-Isoxazolecarboxy-P-Toluidide",
      "4-Isoxazolecarboxamide, 5-Methyl-N-(4-(Trifluoromethyl)Phenyl",
      "4-Isoxazolecarboxamide,5-Methyl-N-(4-(Trifluoromethyl)Phenyl)",
      "5-Methyl-N-(4-(Trifluoromethyl)Phenyl)-4-Isoxazolecarboxamide",
      "5-Methylisoxazole-4-(4-Trifluoromethyl)Carboxanilide",
      "5-Methylisoxazole-4-(4-Trifluoromethylcarboxanilide)",
      "5-Methylisoxazole-4-Carboxylic Acid (4-Trifluoromethyl)Anilide",
      "5-Methyl-N-[4-(Trifluoromethyl)Phenyl]-1,2-Oxazole-4-Carboxamide",
      "5-Methyl-N-[4-(Trifluoromethyl)Phenyl]-4-Isoxazolecarboxamide",
      "5-Methyl-N-[4-(Trifluoromethyl)Phenyl]isoxazole-4-Carboxamide",
    ],
  },
  {
    primary_id: "D08ROQ",
    names: ["S-25585"],
  },
  {
    primary_id: "D08ROT",
    names: ["N-(1-PHENYL-PROPYL)-FORMAMIDE"],
  },
  {
    primary_id: "D08RPE",
    names: ["Sulfonamide Derivative 12"],
  },
  {
    primary_id: "D08RPH",
    names: ["MCL-182"],
  },
  {
    primary_id: "D08RPN",
    names: [
      "(4-Bromo-1H-Pyrazol-1-Yl)(M-Tolyl)Methanone",
      "(4-Bromo-1H-Pyrazol-1-Yl)(3-Methylphenyl)Methanone",
      "AC1LDSCC",
      "N-Benzoylpyrazole Deriv., 22",
      "CHEMBL397270",
      "BDBM23714",
      "ZINC37185",
      "MolPort-002-705-233",
      "STK662514",
      "4-Bromopyrazolyl 3-Methylphenyl Ketone",
      "AKOS005526022",
      "MCULE-2046425213",
      "ST033221",
      "(4-Bromopyrazol-1-Yl)-(3-Methylphenyl)Methanone",
      "SR-01000524314",
      "4-Bromo-1-[(3-Methylphenyl)Carbonyl]-1H-Pyrazole",
      "SR-01000524314-1",
    ],
  },
  {
    primary_id: "D08RPV",
    names: ["IDM-4"],
  },
  {
    primary_id: "D08RPW",
    names: ["1-Cyclohexyl-3-Phenethyl-Urea"],
  },
  {
    primary_id: "D08RQE",
    names: ["DepoVax"],
  },
  {
    primary_id: "D08RQW",
    names: ["MK-5046"],
  },
  {
    primary_id: "D08RSL",
    names: ["Genz-644470", "Advanced Phosphate Binder", "Phosphate Binding Agent (Hyperphosphatemia), Genzyme"],
  },
  {
    primary_id: "D08RSU",
    names: ["[3H]DPCPX", "[3H]-DPCPX"],
  },
  {
    primary_id: "D08RTN",
    names: ["Liposomal Encapsulated Paclitaxel (LEP)"],
  },
  {
    primary_id: "D08RTR",
    names: ["[des-His1,Tyr5,Glu9,D-Ala10]glucagon-NH2"],
  },
  {
    primary_id: "D08RTY",
    names: ["Imidazo[1,2-B]pyridazine Acetamide Derivative 3"],
  },
  {
    primary_id: "D08RUG",
    names: ["1,2,4-Triazole Derivative 4"],
  },
  {
    primary_id: "D08RUK",
    names: ["PMID25553724-Compound-WO2011127048 31"],
  },
  {
    primary_id: "D08RUN",
    names: ["2-Bromopalmitate (Autoimmune/Inflammatory Disease), MSKCC", "2-Bromopalmitate"],
  },
  {
    primary_id: "D08RUW",
    names: ["PD-176931"],
  },
  {
    primary_id: "D08RUX",
    names: [
      "Z-321",
      "1-[3-(2-Indanylacetyl)Thiazolidin-4(R)-Ylcarbonyl]pyrrolidine",
      "1-[N-(2-Indanylacetyl)-4-Thia-L-Prolyl]pyrrolidine",
    ],
  },
  {
    primary_id: "D08RVB",
    names: ["LY233053"],
  },
  {
    primary_id: "D08RWO",
    names: ["CAB-ROR2-ADC"],
  },
  {
    primary_id: "D08RXW",
    names: ["MT-062", "EGFR Inhibitor (Melanoma), Medisyn Technologies"],
  },
  {
    primary_id: "D08RXZ",
    names: ["Peptide Analog 24"],
  },
  {
    primary_id: "D08RYB",
    names: ["5-(4-Chlorophenyl)-4-P-Tolyl-1,2-Selenazole", "CHEMBL513405"],
  },
  {
    primary_id: "D08RYE",
    names: ["Efomycine M"],
  },
  {
    primary_id: "D08RYN",
    names: ["RQ-00202730", "Cannabinoid CB2 Agonists (IBS/IBD, Oral), RaQualia"],
  },
  {
    primary_id: "D08RZB",
    names: ["PMID15546730C2"],
  },
  {
    primary_id: "D08RZV",
    names: ["HSR-609"],
  },
  {
    primary_id: "D08SAG",
    names: ["Ohioensin F", "CHEMBL403094"],
  },
  {
    primary_id: "D08SAO",
    names: ["ZN-1014"],
  },
  {
    primary_id: "D08SAX",
    names: ["DB-772d"],
  },
  {
    primary_id: "D08SAZ",
    names: [
      "Dual PI3K-Alpha/MTOR Inhibitors",
      "Dual PI3K-Alpha/MTOR Inhibitors (Cancer)",
      "Dual PI3K-Alpha/MTOR Inhibitors (Cancer), Amgen",
    ],
  },
  {
    primary_id: "D08SBS",
    names: [
      "DL-Guanidinoethylmercaptosuccinic Acid",
      "77482-44-1",
      "Gemsa",
      "Guanidinoethylmercaptosuccinic Acid",
      "CHEMBL560871",
      "GUANIDINOETHYLMERCAPTO-SUCCINIC ACID",
      "Butanedioic Acid,2-[[2-[(Aminoiminomethyl)Amino]ethyl]thio]-",
      "2-[2-(Diaminomethylideneamino)Ethylsulfanyl]butanedioic Acid",
      "Guanidinoethyl-Mercaptosuccinic Acid",
      "AC1L1FZW",
      "AC1Q5SXD",
      "2-[(2-Carbamimidamidoethyl)Sulfanyl]succinic Acid",
      "AC1Q50E8",
      "SCHEMBL1765999",
      "CTK5E4534",
      "2-Guanidinoethylthiosuccinic Acid",
      "MolPort-004-964-125",
      "(2-Guanidinoethylthio)Succinic Acid",
      "BDBM50296412",
    ],
  },
  {
    primary_id: "D08SCF",
    names: ["Philanthotoxin-343"],
  },
  {
    primary_id: "D08SCG",
    names: ["PS-222036"],
  },
  {
    primary_id: "D08SCH",
    names: ["2-Amino-1-(4-Methylthiophenyl)Propane", "1-(6-(Methylthio)Naphthalen-2-Yl)Propan-2-Amine"],
  },
  {
    primary_id: "D08SCK",
    names: ["H-Dmt-Aba-Gly-NH-CH2-Ph", "CHEMBL208683", "BDBM50189920"],
  },
  {
    primary_id: "D08SCL",
    names: ["MK-3697"],
  },
  {
    primary_id: "D08SCQ",
    names: ["Poractant Alfa"],
  },
  {
    primary_id: "D08SDI",
    names: ["House Dust Mite Allergy Vaccine"],
  },
  {
    primary_id: "D08SEI",
    names: ["Levocabastine"],
  },
  {
    primary_id: "D08-Sep",
    names: ["PMID26394986-Compound-43"],
  },
  {
    primary_id: "D08SFW",
    names: ["PMID27724045-Compound-15"],
  },
  {
    primary_id: "D08SGE",
    names: ["M7583"],
  },
  {
    primary_id: "D08SGF",
    names: ["GR100679"],
  },
  {
    primary_id: "D08SGV",
    names: ["5-HEAT", "5-Hydroxyethoxy-N-Acetyltryptamine"],
  },
  {
    primary_id: "D08SHE",
    names: ["SEP-228432"],
  },
  {
    primary_id: "D08SHI",
    names: ["SG-1311"],
  },
  {
    primary_id: "D08SHK",
    names: [
      "Dexloxiglumide",
      "Dexloxiglumida",
      "Dexloxiglumidum",
      "CR-2017",
      "Dexloxiglumida [INN-Spanish]",
      "Dexloxiglumidum [INN-Latin]",
      "N2-(3,4-Dichlorobenzoyl)-N1-(3-Methoxypropyl)-N1-Pentyl-D-A-Glutamine",
      "(4R)-4-[(3,4-Dichlorobenzoyl)Amino]-5-[3-Methoxypropyl(Pentyl)Amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D08SHM",
    names: [
      "NB-007",
      "Antiviral (Mucosal Vaccine, HBV) NanoBio",
      "Hepatitis B Virus Mucosal Vaccine (NanoStat Nasal Spray), NanoBio",
    ],
  },
  {
    primary_id: "D08SHP",
    names: ["Fluzone QIV HD"],
  },
  {
    primary_id: "D08SHY",
    names: ["ISIS 122985"],
  },
  {
    primary_id: "D08SIC",
    names: ["BDB001"],
  },
  {
    primary_id: "D08SIF",
    names: ["Milatuzumab"],
  },
  {
    primary_id: "D08SIJ",
    names: ["1,1,1-Trifluoro-4-(4-Hexyloxy-Phenyl)-Butan-2-One", "CHEMBL462596"],
  },
  {
    primary_id: "D08SIL",
    names: ["Surrobodies"],
  },
  {
    primary_id: "D08SIR",
    names: ["CL-314698"],
  },
  {
    primary_id: "D08SIT",
    names: [
      "NKP-1339",
      "197723-00-5",
      "KP-1339",
      "EX-A1916",
      "BCP26252",
      "Z-3126",
      "1H-Indazole",
      "Tetrachlororuthenium(1-) Sodium Salt",
    ],
  },
  {
    primary_id: "D08SJF",
    names: ["5-Azido-6-Benzyl-2-Methyl-[1,8]naphthyridine", "CHEMBL194401"],
  },
  {
    primary_id: "D08SJL",
    names: ["4-Ethynyl-2-(4-Hydroxy-Phenyl)-Quinolin-6-Ol", "4-Ethynyl-2-(4-Hydroxyphenyl)Quinolin-6-Ol"],
  },
  {
    primary_id: "D08SJY",
    names: ["MRX34"],
  },
  {
    primary_id: "D08SJZ",
    names: [
      "Ibandronate",
      "BFQ",
      "Acid Ibandronico",
      "Ibandronic Acid",
      "Bisphosphonate 2",
      "R484",
      "RPR 102289A",
      "Bondronat (TN)",
      "Boniva (TN)",
      "Bonviva (TN)",
      "Ibandronic Acid (INN)",
      "Ibandronic Acid [INN:BAN]",
      "RPR-102289A",
      "Ibandronic Acid, Sodium Salt, Monohydrate",
      "Roche Brand Of Ibandronic Acid, Sodium Salt, Monohydrate",
      "[1-Hydroxy-3-[methyl(Pentyl)Amino]-1-Phosphonopropyl]phosphonic Acid",
      "[1-HYDROXY-3-(METHYL-PENTYL-AMINO)-1-PHOSPHONO-PROPYL]-PHOSPHONIC ACID",
      "(1-Hydroxy-3-(Methylpentylamino)Propylidene)Diphosphonic Acid",
      "(1-Hydroxy-3-(Methylpentylamino)Propylidene)Bisphosphonate",
      "1-Hydroxy-3-(Methylpentylamino)Propylidenebisphosphonate",
    ],
  },
  {
    primary_id: "D08SKH",
    names: [
      "Methoxsalen",
      "Ammodin",
      "Ammoidin",
      "Deltasoralen",
      "Dermox",
      "Geroxalen",
      "Meladinin",
      "Meladinina",
      "Meladinine",
      "Meladoxen",
      "Meloxine",
      "Methoxalen",
      "Methoxaten",
      "Oxoralen",
      "Oxsoralen",
      "Oxypsoralen",
      "Puvalen",
      "Puvamet",
      "Ultramop",
      "Uvadex",
      "XANTHOTOXIN",
      "Xanthotoxine",
      "Zanthotoxin",
      "Boehringer Ingelheim Brand Of Methoxsalen",
      "Canderm Brand Of Methoxsalen",
      "Chinoin Brand Of Methoxsalen",
      "DB Brand Of Methoxsalen",
      "Delta Brand Of Methoxsalen",
      "Dermatech Brand Of Methoxsalen",
      "Galderma Brand Of Methoxsalen",
      "Methoxa Dome",
      "Methoxsalen Canderm Brand",
      "Methoxsalen Chinoin Brand",
      "Methoxsalen Delta Brand",
      "Methoxsalen Dermatech Brand",
      "Methoxsalen Plus Ultraviolet Radiation",
      "Mex America Brand Of Methoxsalen",
      "Oxsoralen Ul Tra",
      "Oxsoralen Ultra",
      "Oxsoralen Lotion",
      "Sanofi Synthelabo Brand Of Methoxsalen",
      "Ultra Mop",
      "Ultramop Lotion",
      "ICN Brand 1 Of Methoxsalen",
      "ICN Brand 2 Of Methoxsalen",
      "ICN Brand 3 Of Methoxsalen",
      "X0009",
      "An 8-Methoxyfurocoumarin",
      "Meladinin (VAN)",
      "Methoxa-Dome",
      "Methoxsalen Mex-America Brand",
      "Methoxsalen Sanofi-Synthelabo Brand",
      "Methoxsalen [BAN:JAN]",
      "Methoxsalen With Ultra-Violet A Theraphy",
      "Mex-America Brand Of Methoxsalen",
      "New-Meladinin",
      "O-Methylxanthotoxol",
      "OXSORALEN (TN)",
      "Oxsoralen (TN)",
      "Oxsoralen-Ultra",
      "Proralone-Mop",
      "Psoralen-Mop",
      "Psoralon-MOP",
      "Sanofi-Synthelabo Brand Of Methoxsalen",
      "Methoxsalen (JP15/USP)",
      "Methoxy-8-Psoralen",
      "5-Benzofuranacrylic Acid, 6-Hydroxy-7-Methoxy-, .delta.-Lactone",
      "5-Benzofuranacrylic Acid, 6-Hydroxy-7-Methoxy-, Delta-Lactone",
      "6-Hydroxy-7-Methoxy-5-Benzofuranacrylic Acid Delta-Lactone",
      "7-Furocoumarin",
      "8 Methoxypsoralen",
      "8-METHOXYPSORALEN + UVA (SEE ALSO C55903)",
      "8-MOP",
      "8-Methoxy",
      "8-Methoxy(Furano-3'.2':6.7-Coumarin)",
      "8-Methoxy-(Furano-3'.2':6.7-Coumarin)",
      "8-Methoxy-2',3',6,7-Furocoumarin",
      "8-Methoxy-4',5',6,7-Furocoumarin",
      "8-Methoxy-4',5':6,7-Furocoumarin",
      "8-Methoxy-[furano-3'.2':6.7-Coumarin]",
      "8-Methoxyfuranocoumarin",
      "8-Methoxypsoralen",
      "8-Methoxypsoralen With Ultraviolet A Therapy",
      "8-Methoxypsoralene",
      "8-Methoxyfuranocoumarins",
      "8-Methoxyfurocoumarins",
      "8MO",
      "8MOP",
      "9-(Methyloxy)-7H-Furo[3,2-G]chromen-7-One",
      "9-Methoxy-7H-Furo(3,2-G)(1)Benzopyran-7-One",
      "9-Methoxy-7H-Furo(3,2-G)Benzopyran-7-One",
      "9-Methoxy-7H-Furo[3,2-G][1]benzopyran-7-One",
      "9-Methoxy-7H-Furo[3,2-G]chromen-7-One",
      "9-Methoxyfuro(3,2-G)Chromen-7-One",
      "9-Methoxyfuro[3,2-G][1]benzopyran-7-One",
      "9-Methoxypsoralen",
      "9-Metho Xy-7H-Furo(3,2-G)Benzopyran-7-One",
      "9-Methoxyfuro[3,2-G]chromen-7-One",
    ],
  },
  {
    primary_id: "D08SKU",
    names: [
      "3-(1,3 Dodecadiynyl)-6-Oxiranebutanoic Acid",
      "4-[3-(Dodeca-1,3-Diyn-1-Yl)Oxiran-2-Yl]butanoic Acid",
      "CHEMBL483819",
      "FA(18:4)(Ep,cyclo)",
      "GTPL2958",
      "LMFA01070030",
      "BDBM50292404",
      "5,6-Epoxyoctadeca-7,9-Diynoic Acid",
      "5,6-Epoxy-7,9-Octadecadiynoic Acid",
      "Compound 9a [Patil Et Al, 1989]",
      "4-[3-(Dodeca-1,3-Diyn-1-Yl)Oxiran-2-Yl]butanoic",
      "Compound 9a (Patil Et Al., 1989)",
    ],
  },
  {
    primary_id: "D08SKX",
    names: [
      "RecAAT, PPL Therapeutics/Bayer",
      "AAT Therapy, PPLTherapeutics/Bayer",
      "Alpha-1 Antitrypsin, PPL Therapeutics/Bayer",
      "RAAT, PPL",
    ],
  },
  {
    primary_id: "D08SLP",
    names: ["PMID27109571-Compound-9"],
  },
  {
    primary_id: "D08SLS",
    names: [
      "Arpromidine",
      "106669-71-0",
      "Arpromidinum",
      "Arpromidina",
      "Arpromidine [INN]",
      "Arpromidinum [INN-Latin]",
      "Arpromidina [INN-Spanish]",
      "AC1Q4NMJ",
      "SCHEMBL138015",
      "CHEMBL293802",
      "SCHEMBL9710060",
      "GTPL1221",
      "BDBM86171",
      "AC1L2472",
      "PDSP2_001296",
      "PDSP1_001312",
      "NSC_65895",
      "CAS_65895",
      "L000115",
      "Guanidine, N-(3-(4-Fluorophenyl)-3-(2-Pyridinyl)Propyl)-N'-(3-(1H-Imidazol-4-Yl)Propyl)-",
      "1-[3-(4-Fluorophenyl)-3-(2-Pyridyl)Propyl]-N2-[3-(1h-Imidazol-4-Yl)Propyl]guanidine",
      "(+-)-1-(3-(P-Fluorophenyl)-3-(2-Pyridyl)Propyl)-3-(3-Imidazol-4-Ylpropyl)Guanidine",
    ],
  },
  {
    primary_id: "D08SLZ",
    names: ["(RS/SR)-2-[1-(3,4-Dichlorophenyl)Butyl]piperidine", "CHEMBL219649", "BDBM50202396"],
  },
  {
    primary_id: "D08SMF",
    names: ["ISIS 32005"],
  },
  {
    primary_id: "D08SMK",
    names: [
      "2-(1-Tosyl-1H-Indol-3-Yl)Ethanamine",
      "CHEMBL394303",
      "SCHEMBL15250049",
      "BDBM21349",
      "1-Tosyl-1H-Indole-3-Ethaneamine",
      "Substituted Phenylsulfonyltryptamine, 11h",
    ],
  },
  {
    primary_id: "D08SMN",
    names: ["POP-1"],
  },
  {
    primary_id: "D08SMT",
    names: ["Carbamate Derivative 17"],
  },
  {
    primary_id: "D08SND",
    names: ["PF-543"],
  },
  {
    primary_id: "D08SNH",
    names: ["PMID27109571-Compound-15"],
  },
  {
    primary_id: "D08SNJ",
    names: ["PMID28092474-Compound-33p"],
  },
  {
    primary_id: "D08SNL",
    names: ["JTC-801"],
  },
  {
    primary_id: "D08SNT",
    names: ["Heteroaryl-Carboxamide Derivative 2"],
  },
  {
    primary_id: "D08SNX",
    names: ["V-11294A"],
  },
  {
    primary_id: "D08SOE",
    names: ["PMID17125263C17"],
  },
  {
    primary_id: "D08SOF",
    names: [
      "Terfenadine",
      "Aldaban",
      "Allerplus",
      "Cyater",
      "Hisfedin",
      "Rapidal",
      "Seldane",
      "Teldane",
      "Teldanex",
      "Terdin",
      "Terfedura",
      "Terfemundin",
      "Terfen",
      "Terfenadina",
      "Terfenadinum",
      "Terfenidine",
      "Terfex",
      "Ternadin",
      "Triludan",
      "Aliud Brand Of Terfenadine",
      "Balkis Saft Spezial",
      "Bial Brand Of Terfenadine",
      "Cantabria Brand Of Terfenadine",
      "Ct Arzneimittel Brand Of Terfenadine",
      "Dolorgiet Brand Of Terfenadine",
      "Heumann Brand Of Terfenadine",
      "Hoechst Brand Of Terfenadine",
      "Merck Dura Brand Of Terfenadine",
      "Mundipharma Brand Of Terfenadine",
      "Ratiopharm Brand Of Terfenadine",
      "Sigma Tau Brand Of Terfenadine",
      "Stadapharm Brand Of Terfenadine",
      "Terfenadin AL",
      "Terfenadin Heumann",
      "Terfenadin Stada",
      "Terfenadin Von Ct",
      "Wolff Brand Of Terfenadine",
      "MDL 9918",
      "RMI 9918",
      "RMI9918",
      "T 9652",
      "Ct-Arzneimittel Brand Of Terfenadine",
      "MDL-9918",
      "RMI-9918",
      "Seldane (TN)",
      "Sigma-Tau Brand Of Terfenadine",
      "Teldane (TN)",
      "Terfenadin-Ratiopharm",
      "Terfenadina [INN-Spanish]",
      "Terfenadinum [INN-Latin]",
      "Triludan (TN)",
      "Terfenadine (JAN/USAN/INN)",
      "Terfenadine [USAN:BAN:INN:JAN]",
      "Alpha-(4-[1,1-Dimethylethyl]phenyl)-4-[hydroxydiphenylmethyl]-1-Piperidinebutanol",
      "Alpha-(P-Tert-Butylphenyl)-4-(Hydroxydiphenylmethyl)-1-Piperidinebutanol",
      "Alpha-[4-(1,1-Dimethylethyl)Phenyl]-4-(Hydroxydiphenylmethyl)-1-Piperidinebutanol",
      "Alpha-(4-(1,1-Dimethylethyl)Phenyl)-4-(Hydroxydiphenylmethyl)-1-Piperdinebutanol",
      "Alpha-(4-(1,1-Dimethylethyl)Phenyl)-4-(Hydroxydiphenylmethyl)-1-Piperidinebutanol",
      "Alpha-(4-(1,1-Dimethylethyl)Phenyl)-4-(Hydroxydiphenyl-Methyl)-1-Piperidine Butanol",
      "1-(4-Tert-Butylphenyl)-4-(4-(Alpha-Hydroxybenzhydryl)Piperidino)-Butan-1-Ol",
      "1-(4-Tert-Butylphenyl)-4-[4-(Hydroxydiphenylmethyl)Piperidin-1-Yl]butan-1-Ol",
      "1-(4-Tert-Butylphenyl)-4-[4-[hydroxy(Diphenyl)Methyl]piperidin-1-Yl]butan-1-Ol",
      "1-(P-Tert-Butylphenyl)-4-(4'-(Alpha-Hydroxydiphenylmethyl)-1'-Piperidyl)Butanol",
      "1-[4-(1,1-Dimethylethyl)Phenyl]-4-{4-[hydroxy(Diphenyl)Methyl]piperidin-1-Yl}butan-1-Ol",
    ],
  },
  {
    primary_id: "D08SPJ",
    names: [
      "3,4,5,6-Tetrahydrobenzo[c]quinolizin-3-(4aH)-One",
      "CHEMBL99448",
      "SCHEMBL6940070",
      "LNTWPSKEEYNUKK-UHFFFAOYSA-N",
      "BDBM50072192",
      "4,4a,5,6-Tetrahydro-Pyrido[1,2-A]quinolin-3-One",
      "4,4a,5,6-Tetrahydro-3H-Benzo[c]quinolizine-3-One",
    ],
  },
  {
    primary_id: "D08SPM",
    names: ["ISIS 10303"],
  },
  {
    primary_id: "D08SPP",
    names: ["TG-44", "Antimicrobial Agent (H.pylori Infection/Peptic Ulcer), Nagase ChemteX"],
  },
  {
    primary_id: "D08SRB",
    names: ["Phenyl 4-(4,5-Dihydro-1H-Imidazol-2-Yl)Benzoate", "CHEMBL451230"],
  },
  {
    primary_id: "D08SSG",
    names: ["3-[(2,5-Dimethyl-4-Thiazolyl)Ethynyl]pyridine", "CHEMBL201857", "SCHEMBL4149620"],
  },
  {
    primary_id: "D08SSO",
    names: [
      "CNSB-006",
      "CNSB-007",
      "Drug Combination (Inflammatory Pain), CNSbio",
      "Drug Combination (Inflammatory Pain), Relevare",
    ],
  },
  {
    primary_id: "D08SSP",
    names: ["Lersivirine", "Cc-718", "Lersivirine (UK-453061)"],
  },
  {
    primary_id: "D08SSS",
    names: ["(S)-2-Amino-1-(4-Propylthiophenyl)-Propane", "CHEMBL229472"],
  },
  {
    primary_id: "D08STA",
    names: ["CAL-203"],
  },
  {
    primary_id: "D08STR",
    names: [
      "2'-5'dideoxyuridine",
      "2',5'-Dideoxyuridine",
      "35959-50-3",
      "AC1L9G4G",
      "Uridine, 2',5'-Dideoxy-",
      "SCHEMBL2919639",
      "CTK1C1122",
      "DTXSID00332151",
      "FDCFKLBIAIKUKB-GKROBHDKSA-N",
      "ZINC3870698",
      "DB03274",
    ],
  },
  {
    primary_id: "D08STW",
    names: ["Tyr-Pro-L-(NMe)Phe-Pro-NH2"],
  },
  {
    primary_id: "D08SUH",
    names: ["3-(3,4-Dimethoxy-Phenyl)-6,7-Dimethoxy-Quinoline", "CHEMBL66100", "BDBM50039089"],
  },
  {
    primary_id: "D08SUQ",
    names: ["ES-285"],
  },
  {
    primary_id: "D08SVH",
    names: ["Tacalcitol", "Bonalfa (TN)"],
  },
  {
    primary_id: "D08SWN",
    names: ["(+/-)-Threo-3',5'-Dimethylmethylphenidate", "CHEMBL1253692", "BDBM50327134"],
  },
  {
    primary_id: "D08SXS",
    names: ["Polyflu"],
  },
  {
    primary_id: "D08SXV",
    names: ["DWP-422"],
  },
  {
    primary_id: "D08SYL",
    names: ["[des-His1,Tyr5,Glu9,D-Phe10]glucagon-NH2"],
  },
  {
    primary_id: "D08SYN",
    names: ["3-Cyano-N-(6-Ethylpyridin-2-Yl)-5-Fluorobenzamide", "CHEMBL1209330"],
  },
  {
    primary_id: "D08SYX",
    names: ["Piriqualone"],
  },
  {
    primary_id: "D08SZO",
    names: [
      "F-12458",
      "CAAX Tetrapeptide Analogs, Pierre Fabre",
      "Farnesyl Transferase Inhibitors, Pierre Fabre",
      "Ras Inhibitor, Pierre Fabre",
    ],
  },
  {
    primary_id: "D08SZP",
    names: ["TA-103"],
  },
  {
    primary_id: "D08SZR",
    names: [
      "T-Cell Therapy",
      "Cytovir",
      "T-Cell Therapy (Cytomegalovirus)",
      "T-Cell Therapy (Cytomegalovirus), Cell Medica/UCL",
    ],
  },
  {
    primary_id: "D08TAB",
    names: ["XL-499"],
  },
  {
    primary_id: "D08TAN",
    names: ["(+)-HA966", "(R)-(+)-HA-966"],
  },
  {
    primary_id: "D08TBF",
    names: ["BOMPPA", "Benzyloxy-Methoxyphenyl-Propylamide", "Compound 10 [PMID: 23228808]"],
  },
  {
    primary_id: "D08TBI",
    names: ["Cyclopentyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL520588"],
  },
  {
    primary_id: "D08TBK",
    names: ["Opdivo + Yervoynivolumab + Ipilimumab"],
  },
  {
    primary_id: "D08TBP",
    names: ["2-Acetylamino-Indan-5-Sulfonic Acid Hydrate"],
  },
  {
    primary_id: "D08TBU",
    names: [
      "Diphenyl(Piperidin-4-Yl)Methanol",
      "Azacyclonol",
      "115-46-8",
      "Alpha,alpha-Diphenyl-4-Piperidinomethanol",
      "Frenquel",
      "Alpha,alpha-Diphenyl-4-Piperidinemethanol",
      "Alpha-(4-Piperidyl)Benzhydrol",
      "Gamma-Pipradol",
      "Calmeran",
      "Ataractan",
      "Diphenyl-Piperidin-4-Yl-Methanol",
      "Diphenyl(4-Piperidinyl)Methanol",
      "4-Piperidinemethanol, Alpha,alpha-Diphenyl-",
      "Azaciclonolo [DCIT]",
      "Azacyklonol",
      "Psychosan",
      "MER 17",
      "Frenoton",
      "Azacyclonol [INN:BAN]",
      "UNII-2MMR990PEM",
      "Azacyclonolum [INN-Latin]",
      "EINECS 204-092-5",
      "Diphenyl-4-Piperidylmethanol",
      "BRN 0230221",
      "2MMR990PEM",
    ],
  },
  {
    primary_id: "D08TCU",
    names: ["GT-4001"],
  },
  {
    primary_id: "D08TCV",
    names: ["Sucrose"],
  },
  {
    primary_id: "D08TCW",
    names: [
      "Calpastatin",
      "Acacetin-7-O-Glucuronide Methyl Ester",
      "AC1NUQS7",
      "79076-87-2",
      "Beta-D-Glucopyranosiduronic Acid, 5-Hydroxy-2-(4-Methoxyphenyl)-4-Oxo-4H-1-Benzopyran-7-Yl, Methyl Ester",
      "Methyl (2S,3S,4S,5R)-3,4,5-Trihydroxy-6-[5-Hydroxy-2-(4-Methoxyphenyl)-4-Oxochromen-7-Yl]oxyoxane-2-Carboxylate",
    ],
  },
  {
    primary_id: "D08TCX",
    names: ["(2-Benzyl-Phenyl)-(2-Pyrrolidin-1-Yl-Ethyl)-Amine", "CHEMBL187420", "BDBM50151051"],
  },
  {
    primary_id: "D08TDE",
    names: ["2-(4-Mercapto-Butyl)-Pentanedioic Acid", "CHEMBL60317", "SCHEMBL6383425"],
  },
  {
    primary_id: "D08TDJ",
    names: ["PMID26004420-Compound-WO2014126944A"],
  },
  {
    primary_id: "D08TDK",
    names: ["ANA-011"],
  },
  {
    primary_id: "D08TDQ",
    names: ["Morphinan Cyclic Imine Analogue"],
  },
  {
    primary_id: "D08TEJ",
    names: [
      "CB-03-01",
      "Cortexolone 17alpha-Propionate",
      "Androgen Receptor Antagonist (Alopecia, Acne, Seborrhea, Hirsuitism), Cosmo",
    ],
  },
  {
    primary_id: "D08TFH",
    names: ["Indan-2-Ylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL485886"],
  },
  {
    primary_id: "D08TFN",
    names: [
      "(+/-)-2-(4'-Methoxyphenyl)Thiomorpholine",
      "2-(4-Methoxyphenyl)Thiomorpholine",
      "CHEMBL598395",
      "AC1Q4AWZ",
      "2-(4-Methoxyphenyl) Thiomorpholine Hydrochloride",
      "SCHEMBL1636984",
      "AC1NM467",
      "YAPWTYUHWOWUHY-UHFFFAOYSA-N",
      "2-(4-Methoxyphenyl)-Thiomorpholine",
      "2-(4'-Methoxyphenyl)Thiomorpholine",
      "BDBM50307365",
      "AKOS022304555",
      "MCULE-4309621221",
      "1001940-39-1",
    ],
  },
  {
    primary_id: "D08TFY",
    names: ["1-Benzhydryl-4-Cyclohexylpiperidin-4-Ol", "CHEMBL394695"],
  },
  {
    primary_id: "D08TGB",
    names: ["Interleukin-2, Roussel Uclaf"],
  },
  {
    primary_id: "D08TGC",
    names: ["GUANOSINE-2',3'-O-ETHYLIDENEPHOSPHONATE"],
  },
  {
    primary_id: "D08TGG",
    names: ["Insulin Transdermal"],
  },
  {
    primary_id: "D08TGQ",
    names: ["S-Tubercidinylhomocysteine"],
  },
  {
    primary_id: "D08TGY",
    names: ["S-(Dimethylarsenic)Cysteine", "[[(S)-2-Amino-2-Carboxyethyl]thio]dimethylarsenic"],
  },
  {
    primary_id: "D08TIA",
    names: ["IC-200214"],
  },
  {
    primary_id: "D08TIJ",
    names: ["N-(3,5-Dimethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093713", "BDBM50314133"],
  },
  {
    primary_id: "D08TJM",
    names: ["Binodenoson", "CorVue", "MRE-0470", "MRE-0479", "MRE-470", "SHA-174", "WRC-0470"],
  },
  {
    primary_id: "D08TJY",
    names: ["D-257A", "CHEMBL409881"],
  },
  {
    primary_id: "D08TKB",
    names: ["1-(2-Phenoxybenzyl)-1H-Imidazole", "CHEMBL1082760", "SCHEMBL11704850"],
  },
  {
    primary_id: "D08TKV",
    names: ["SKF-97426"],
  },
  {
    primary_id: "D08TLI",
    names: ["E-4716"],
  },
  {
    primary_id: "D08TLJ",
    names: ["Alpha-Monofluoromethyl-3,4-Dehydroornithine"],
  },
  {
    primary_id: "D08TME",
    names: [
      "2,5-Bis(4-Nitrophenyl)Thiophene",
      "CHEMBL202898",
      "AC1NA972",
      "SCHEMBL12491620",
      "ZINC3107517",
      "BDBM50178545",
      "70010-47-8",
    ],
  },
  {
    primary_id: "D08TMM",
    names: ["K-777"],
  },
  {
    primary_id: "D08TNG",
    names: ["RG7775"],
  },
  {
    primary_id: "D08TNQ",
    names: [
      "N5-[4-(Phenylmethoxy)Phenyl]-L-Glutamine",
      "(2S)-2-Amino-4-{[4-(Benzyloxy)Phenyl]carbamoyl}butanoic Acid",
      "(2S)-2-Amino-5-Oxo-5-[(4-Phenylmethoxyphenyl)Amino]pentanoic Acid",
      "Amino Acid Analog, 4f",
      "CHEMBL478381",
      "BDBM24256",
      "N-[4-(Benzyloxy)Phenyl]-L-Glutamine",
      "DB07102",
      "N-[4-(Phenylmethoxy)Phenyl]-L-Glutamine",
    ],
  },
  {
    primary_id: "D08TOM",
    names: ["PMID30280939-Compound-WO2015150568"],
  },
  {
    primary_id: "D08TPH",
    names: ["TC-5653"],
  },
  {
    primary_id: "D08TPS",
    names: [
      "Epidermal Growth Factor",
      "62229-50-9",
      "Urogastrone",
      "Uroenterone",
      "Uroanthelone",
      "Urogastron",
      "Kutrol",
      "Beta-Urogastrone",
      "Anthelone U",
      "Urogastrone [JAN]",
      "Gastrone, Gamma-Uro-",
      "Gastrone, Beta-Uro- (Human)",
      "Epidermal Growth Factor (Egf)",
      "CCRIS 7234",
      "EINECS 263-468-7",
      "EPIDERMAL GROWTH FACTOR-UROGASTRONE",
      "Gastrone, Uro- (9CI)",
      "9010-53-1",
      "Gentel (TN)",
      "EGF",
    ],
  },
  {
    primary_id: "D08TQA",
    names: ["VALIOLAMINE"],
  },
  {
    primary_id: "D08TQK",
    names: ["NM-9405"],
  },
  {
    primary_id: "D08TQP",
    names: ["INX-189"],
  },
  {
    primary_id: "D08TRA",
    names: ["RS-61980"],
  },
  {
    primary_id: "D08TRD",
    names: ["Hexocyclium"],
  },
  {
    primary_id: "D08TRG",
    names: ["AZD-3241"],
  },
  {
    primary_id: "D08TRN",
    names: ["(2-Ethoxy-Ethyl)-Trimethyl-Ammonium Iodide"],
  },
  {
    primary_id: "D08TRX",
    names: ["CST-101"],
  },
  {
    primary_id: "D08TSA",
    names: ["MRS1177"],
  },
  {
    primary_id: "D08TSE",
    names: [
      "1-Acetoxy-2-Tert-Butyldimethylsilyl-Oxylycorine",
      "CHEMBL564408",
      "1-Acetoxy-2-TBS-Lycorine",
      "BDBM50293601",
      "(1S,2S,3a1S,12bS)-2-(Tert-Butyldimethylsilyloxy)-2,3a1,4,5,7,12b-Hexahydro-1H-[1,3]dioxolo[4,5-J]pyrrolo[3,2,1-De]phenanthridin-1-Yl Acetate",
    ],
  },
  {
    primary_id: "D08TSK",
    names: ["SC-58272"],
  },
  {
    primary_id: "D08TVA",
    names: ["MANIDIPINE"],
  },
  {
    primary_id: "D08TVG",
    names: [
      "PT-307",
      "149486-26-0",
      "Thiourea, N-(2-(2,6-Difluorophenyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(2,6-Difluorophenyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 23",
      "PETT Di-F Deriv.",
      "AC1MHDKC",
      "CHEMBL252136",
      "SCHEMBL6961866",
      "BDBM1916",
      "DTXSID50164303",
      "1-[2-(2,6-Difluorophenyl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "1-(2-Thiazolyl)-3-[2,6-Difluorophenethyl]thiourea",
      "N-(2-(2,6-Difluorophenethyl))-N -(2-Thiazolyl)Thiourea",
      "1-[2-(2,6-Difluorophenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
    ],
  },
  {
    primary_id: "D08TVI",
    names: ["HPP-854"],
  },
  {
    primary_id: "D08TVU",
    names: ["PMID20483621C5n"],
  },
  {
    primary_id: "D08TWI",
    names: ["12-(3-N-Hexylureido)Dodec-8(Z)-Enoic Acid", "CHEMBL560029"],
  },
  {
    primary_id: "D08TWJ",
    names: [
      "Peptide Alpha-Keto-Beta-Aldehyde-Based Inhibitors",
      "CHEMBL48605",
      "BDBM50090643",
      "(3S)-3-[(Ac-L-Leu-L-Leu-)Amino]-6-Guanidino-2-Oxohexanal",
      "(S)-2-Acetylamino-4-Methyl-Pentanoic Acid {(S)-1-[(S)-4-Guanidino-1-(2-Oxo-Acetyl)-Butylcarbamoyl]-3-Methyl-Butyl}-Amide",
    ],
  },
  {
    primary_id: "D08TWQ",
    names: ["NTHi"],
  },
  {
    primary_id: "D08TWT",
    names: ["AGN192093", "AGN-192093", "AGN 192093"],
  },
  {
    primary_id: "D08TXL",
    names: [
      "A-796260",
      "895155-26-7",
      "(1-(2-Morpholinoethyl)-1H-Indol-3-Yl)(2,2,3,3-Tetramethylcyclopropyl)Methanone",
      "UNII-5N69DXA53Z",
      "CHEMBL262865",
      "5N69DXA53Z",
      "A 796260",
      "A-796,260",
      "ZCFHOMLAFTWDFM-UHFFFAOYSA-N",
      "A796260",
      "Morpholino-Ethyl Analogue, 32",
      "SCHEMBL2076954",
      "CTK8B4187",
      "BDBM21311",
      "MolPort-023-278-969",
      "ZINC14975818",
      "ANW-44204",
      "AKOS015999103",
      "(1-(2-Morpholin-4-Yl-Ethyl)-1H-Indol-3-Yl)-(2,2,3,3-Tetramethylcyclopropyl)Methanone",
      "KB-74376",
      "AJ-65531",
      "AS-19178",
      "AX8228482",
      "TC-132949",
      "ST24020055",
      "X5014",
      "J3.566.431I",
    ],
  },
  {
    primary_id: "D08TZA",
    names: ["(R)-2-(2-Naphthamido)-3-P-Tolylpropanoic Acid", "CHEMBL1093715"],
  },
  {
    primary_id: "D08TZZ",
    names: ["Oleuropein"],
  },
  {
    primary_id: "D08UAT",
    names: ["DPS-151"],
  },
  {
    primary_id: "D08UCB",
    names: ["PMID25470667-Compound-BAY744113"],
  },
  {
    primary_id: "D08UDB",
    names: ["GSK-2263167"],
  },
  {
    primary_id: "D08UDC",
    names: [
      "[3H]CGP39653",
      "(E)-2-Amino-4-(Phosphonomethyl)Hept-3-Enoic Acid",
      "132472-31-2",
      "ACMC-20muiw",
      "GTPL4078",
      "CTK0H6852",
      "2-Amino-4-(Phosphonomethyl)Hept-3-Enoic Acid",
    ],
  },
  {
    primary_id: "D08UDE",
    names: ["Peptide-Conjugated Phosphorodiamidate Morpholino Oligomers"],
  },
  {
    primary_id: "D08UDF",
    names: ["PIBAXIZINE"],
  },
  {
    primary_id: "D08UDG",
    names: ["MEDI4276"],
  },
  {
    primary_id: "D08UDY",
    names: [
      "1',2',3',6'-Tetrahydro-[2,4']bipyridinyl",
      "50461-51-3",
      "CHEMBL278934",
      "1',2',3',6'-Tetrahydro-2,4'-Bipyridine",
      "2-(1,2,3,6-Tetrahydropyridin-4-Yl)Pyridine",
      "2,4'-Bipyridine, 1',2',3',6'-Tetrahydro-",
      "SCHEMBL169400",
      "CTK4J2646",
      "DTXSID50569450",
      "IVRPDZRVHKIBBG-UHFFFAOYSA-N",
      "ZINC19811349",
      "BDBM50026630",
      "AKOS000205268",
    ],
  },
  {
    primary_id: "D08UDZ",
    names: ["MK-6105"],
  },
  {
    primary_id: "D08UEC",
    names: ["PMID25666693-Compound-135"],
  },
  {
    primary_id: "D08UEJ",
    names: ["Ajinomoto 2"],
  },
  {
    primary_id: "D08UET",
    names: ["Propidium"],
  },
  {
    primary_id: "D08UEU",
    names: [
      "4-(ACETYLAMINO)-5-AMINO-3-HYDROXYBENZOIC ACID",
      "4-Acetamido-3-Amino-5-Hydroxybenzoic Acid",
      "162252-46-2",
      "4-(Acetylamino)-3-Amino-5-Hydroxybenzoic Acid",
      "ST2",
      "BANA106",
      "AC1L9JL3",
      "CHEMBL109005",
      "BANA-106",
      "SCHEMBL8375025",
      "CTK8H1571",
      "DTXSID70332224",
      "ZINC3581100",
      "8225AA",
      "AKOS016012208",
      "DB08571",
      "AJ-45035",
      "3-Amino-4-Acetamido-5-Hydroxybenzoic Acid",
      "KB-239845",
      "AX8247088",
      "4-Acetamido-3-Amino-5-Hydroxy-Benzoic Acid",
      "3-Amino-4-(Acetylamino)-5-Hydroxybenzoic Acid",
    ],
  },
  {
    primary_id: "D08UFW",
    names: ["PMID26560530-Compound-48"],
  },
  {
    primary_id: "D08UGB",
    names: ["VRX806"],
  },
  {
    primary_id: "D08UGJ",
    names: ["Vincamine"],
  },
  {
    primary_id: "D08UGS",
    names: ["JNJ 63723283"],
  },
  {
    primary_id: "D08UGX",
    names: ["LIAROZOLE"],
  },
  {
    primary_id: "D08UHC",
    names: ["PMA-104R"],
  },
  {
    primary_id: "D08UHN",
    names: ["Irtemazole"],
  },
  {
    primary_id: "D08UHS",
    names: [
      "1-(1,4-Diacetylphenyl)Dithiosemicarbazide",
      "CHEMBL256865",
      "NSC627218",
      "AC1NTSUI",
      "ZINC5883102",
      "BDBM50376201",
      "[(Z)-1-[4-[(Z)-N-(Carbamothioylamino)-C-Methylcarbonimidoyl]phenyl]ethylideneamino]thiourea",
      "Hydrazinecarbothioamide, 2,2'-[1,4-Phenylenedi(1Z)Ethylidyne]bis-, (2Z,2'Z)-",
    ],
  },
  {
    primary_id: "D08UHV",
    names: ["AMG 780"],
  },
  {
    primary_id: "D08UIO",
    names: [
      "2-[4-(2-Azepan-1-Ylethoxy)Phenoxy]benzothiazole",
      "Benzthiazole Compound, 11c",
      "SCHEMBL2168218",
      "CHEMBL520120",
      "BDBM24207",
      "ZINC40919150",
    ],
  },
  {
    primary_id: "D08UJH",
    names: ["Toca 511 + Toca FC Combination"],
  },
  {
    primary_id: "D08UKF",
    names: ["N-(3,5-Dimethoxyphenethyl)Docos-13-Enamide", "CHEMBL199870"],
  },
  {
    primary_id: "D08ULI",
    names: [
      "Org-4094",
      "3alpha-Hydroxytibolone",
      "3A-HYDROXYTIBOLONE",
      "Org 4094",
      "100239-44-9",
      "UNII-37T303O94A",
      "37T303O94A",
      "3-Hydroxytibolone",
      "A-Hydroxy Tibolone",
      "SCHEMBL7019917",
      "ORG-4094",
      "ZINC22061266",
      "A)-7-Methyl-19-Norpregn-5(10)-En-20-Yne-3,17-Diol",
      "19-Norpregn-5(10)-En-20-Yne-3,17-Diol, 7-Methyl-, (3alpha,7alpha,17alpha)-",
      "(3alpha,7alpha,17alpha)-7-Methyl-19-Norpregn-5(10)-En-20-Yne-3,17-Diol",
    ],
  },
  {
    primary_id: "D08UMH",
    names: ["Methylphenobarbital"],
  },
  {
    primary_id: "D08UML",
    names: [
      "1-(Butan-2-Ylidene)Thiosemicarbazide",
      "CHEMBL458405",
      "1752-40-5",
      "AC1NSGXA",
      "WLN: SUYZMNUY2&amp",
      "2-Butanone Thiosemicarbazone",
      "NSC709",
      "NSC-709",
      "[(Z)-Butan-2-Ylideneamino]thiourea",
      "ZINC13115335",
      "BDBM50268198",
    ],
  },
  {
    primary_id: "D08UMZ",
    names: ["STD-07"],
  },
  {
    primary_id: "D08UNC",
    names: ["GPC3 Targeting CAR-T Cells"],
  },
  {
    primary_id: "D08UNQ",
    names: ["NSD-644"],
  },
  {
    primary_id: "D08UNZ",
    names: ["PXVX-0103", "Ad4-H5-Vtn"],
  },
  {
    primary_id: "D08UOB",
    names: ["1,2-Bis(2,3,5-Trifluorophenyl)Ethane-1,2-Dione", "CHEMBL242930"],
  },
  {
    primary_id: "D08UOW",
    names: ["MMPIP"],
  },
  {
    primary_id: "D08UOX",
    names: ["FALCARINDIOL"],
  },
  {
    primary_id: "D08UOY",
    names: ["Vadimezan", "DMXAA", "AS-1404", "ASA-404", "R-1564", "5,6-MeXAA", "5,6-Dimethylxanthenone-4-Acetic Acid"],
  },
  {
    primary_id: "D08UPA",
    names: [
      "4-Hydroxy-2-Nonenal",
      "4-Hydroxynonenal",
      "4-Hydroxynon-2-Enal",
      "Trans-4-Hydroxy-2-Nonenal",
      "4-Hydroxy-2,3-Nonenal",
      "4-HNE",
      "75899-68-2",
      "4-Hydroxy-2E-Nonenal",
      "(E)-4-Hydroxynon-2-Enal",
      "CCRIS 9028",
      "CCRIS 1781",
      "29343-52-0",
      "128946-65-6",
      "2-NONENAL, 4-HYDROXY-",
      "HNE",
      "(E)-4-Hydroxy-2-Nonenal",
      "(2E)-4-Hydroxynon-2-Enal",
      "(2E)-4-Hydroxy-2-Nonenal",
      "CHEMBL454280",
      "CHEBI:58968",
      "C9H16O2",
      "(+/-)4-HYDROXYNON-2-ENAL",
      "NCGC00161254-02",
      "4-Hydroxynonen-2-Al",
      "CHEBI:32585",
      "CCRIS 6927",
      "4 Hydroxynonenal",
      "(E)-4-Hydroxynonenal",
      "AC1Q2VOQ",
      "4HNE",
      "SCHEMBL3920",
      "AC1Q6PP5",
      "AC1NR22M",
    ],
  },
  {
    primary_id: "D08UPQ",
    names: ["PMID25980951-Compound-30"],
  },
  {
    primary_id: "D08UQC",
    names: ["Oshadi R"],
  },
  {
    primary_id: "D08UQH",
    names: [
      "2-(1H-Imidazol-1-Yl)-1-(4-Nitrophenyl)Ethanone",
      "37910-79-5",
      "N-(4-Nitrophenacyl)Imidazole",
      "CHEMBL162549",
      "Ethanone, 2-(1H-Imidazol-1-Yl)-1-(4-Nitrophenyl)-",
      "AC1Q5DPC",
      "2-Imidazol-1-Yl-1-(4-Nitrophenyl)Ethanone",
      "AC1Q1XG1",
      "SCHEMBL5730977",
      "AC1L1F83",
      "CTK1A9243",
      "DTXSID00275594",
      "BDBM50240895",
      "ZINC19595447",
      "AKOS009984680",
      "2-Imidazol-1-Yl-1-(4-Nitro-Phenyl)-Ethanone",
      "I90114",
      "Alpha-(1H-Imidazole-1-Yl)-4'-Nitroacetophenone",
      "2-(1H-Imidazol-1-Yl)-1-(4-Nitrophenyl)-Ethanone",
    ],
  },
  {
    primary_id: "D08UQK",
    names: ["PMID29338548-Compound-29"],
  },
  {
    primary_id: "D08UQS",
    names: ["MEM-1003"],
  },
  {
    primary_id: "D08UQU",
    names: [
      "(R)-(-)-2-Methoxy-11-Hydroxyaporphine",
      "CHEMBL270426",
      "2-Methoxy-11-Hydroxyaporphine",
      "SCHEMBL1204517",
      "BDBM50234265",
    ],
  },
  {
    primary_id: "D08URJ",
    names: ["CGNLSTCMLGTYTQDFNKFHTFPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D08URT",
    names: ["24(S)-Hydroxycholesterol", "24s-OHC"],
  },
  {
    primary_id: "D08URY",
    names: [
      "Alpha-Methylphenylalanine",
      "Alpha-Methyl-DL-Phenylalanine",
      "1132-26-9",
      "2-Amino-2-Methyl-3-Phenylpropanoic Acid",
      "2-Amino-2-Methyl-3-Phenylpropionic Acid",
      "H--Me-DL-Phe-OH",
      "HYOWVAAEQCNGLE-UHFFFAOYSA-N",
      "Alpha-Methyl-DL-Phenylalanine, 98%",
      "MFCD00010512",
      "Phenylalanine, Alpha-Methyl-",
      "2-Amino-2-Methyl-3-Phenyl-Propanoic Acid",
      "2-Methylphenylalanine #",
      "D,L-Phe(AlphaMe)-OH",
      "A-Methyl-Dl-Phenylalanine",
      "Alpha-Me-Dl-Phenylalanine",
      "Methylphenylalanine, Alpha",
      "H-DL-(ME)PHE-OH",
      "Dl-Alpha-Methylphenylalanine",
      "ACMC-1AH59",
    ],
  },
  {
    primary_id: "D08USC",
    names: ["7-Bromo-N-(3-Bromophenyl)Isoquinolin-1-Amine", "CHEMBL1080277"],
  },
  {
    primary_id: "D08USJ",
    names: ["Neostigmine"],
  },
  {
    primary_id: "D08USK",
    names: ["N-(3'-Acetyl-4-Aminobiphenyl-3-Yl)Benzamide", "CHEMBL1097746", "BDBM50317992"],
  },
  {
    primary_id: "D08UST",
    names: ["(Z)-2,2-Dimethyl-1-Oxazol-2-Yl-Octadec-9-En-1-One", "CHEMBL36655", "BDBM50100875"],
  },
  {
    primary_id: "D08USU",
    names: ["Ticarcillin"],
  },
  {
    primary_id: "D08USX",
    names: ["N-(4,4-Diphenyl-Butyl)-Nicotinamide", "CHEMBL561479"],
  },
  {
    primary_id: "D08UUD",
    names: ["R-1663"],
  },
  {
    primary_id: "D08UUK",
    names: ["Rostafuroxin"],
  },
  {
    primary_id: "D08UUL",
    names: ["LY2606368"],
  },
  {
    primary_id: "D08UVN",
    names: ["Anti-CEA CAR-T Cells"],
  },
  {
    primary_id: "D08UVX",
    names: ["2-(2-(Phenylamino)Pyrimidin-4-Ylamino)Benzamide", "4-Anilinopyrimidine 2a", "CHEMBL428213", "BDBM15995"],
  },
  {
    primary_id: "D08UVZ",
    names: ["E004"],
  },
  {
    primary_id: "D08UWE",
    names: ["(4S,5R)-4,5-Dimethyl-Oxazolidin-(2Z)-Ylideneamine", "CHEMBL110789", "BDBM50138184"],
  },
  {
    primary_id: "D08UWI",
    names: [
      "2-Methyl-1-Phenyl-2-Pyridin-3-Yl-Propan-1-One",
      "87372-75-6",
      "2-Methyl-1-Phenyl-2-(3-Pyridinyl)-1-Propanone",
      "CHEMBL294460",
      "2-Mppp",
      "AC1L37C5",
      "SCHEMBL12260655",
      "CTK5F8302",
      "DTXSID50236312",
      "ZINC5138212",
      "BDBM50028170",
      "KB-231336",
      "2-Methyl-1-Phenyl-2-Pyridin-3-Ylpropan-1-One",
    ],
  },
  {
    primary_id: "D08UWO",
    names: ["RF9", "1-Adamantanecarbonyl-RF-NH2"],
  },
  {
    primary_id: "D08UWZ",
    names: [
      "BMS-687453",
      "1000998-59-3",
      "UNII-39TL5L7XDX",
      "BMS 687453",
      "39TL5L7XDX",
      "2-{[(3-{[2-(4-Chlorophenyl)-5-Methyl-1,3-Oxazol-4-Yl]methoxy}phenyl)Methyl](Methoxycarbonyl)Amino}acetic Acid",
      "SCHEMBL2742714",
      "CHEMBL1089501",
      "BDBM28800",
      "EX-A592",
      "BCP14808",
      "ZINC44460341",
      "AKOS030526188",
      "CS-5523",
      "DA-48472",
      "HY-10678",
      "FT-0749275",
      "J-690001",
      "2-[[3-[[2-(4-Chlorophenyl)-5-Methyl-1,3-Oxazol-4-Yl]methoxy]phenyl]methyl-Methoxycarbonylamino]acetic Acid",
      "7HA",
      "N-(3-{[2-(4-Chlorophenyl)-5-Methyl-1,3-Oxazol-4-Yl]methoxy}benzyl)-N-(Methoxycarbonyl)Glycine",
    ],
  },
  {
    primary_id: "D08UXL",
    names: ["SunitinibMalate"],
  },
  {
    primary_id: "D08UXQ",
    names: ["FHT-1106"],
  },
  {
    primary_id: "D08UXR",
    names: ["PMID26004420-Compound-WO2014150132B"],
  },
  {
    primary_id: "D08UXT",
    names: [
      "3-(4-Butoxy-Phenyl)-N-Hydroxy-N-Methyl-Acrylamide",
      "CHEMBL446362",
      "BDBM50015130",
      "(E)-3-[4-Butoxyphenyl]-N-Methyl-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D08UYM",
    names: ["ONO-2333Ms", "Corticotropin-Releasing Factor-1 Antagonist (Oral, Anxiety/Depression), Ono"],
  },
  {
    primary_id: "D08UYP",
    names: [
      "DIECKOL",
      "Dieckol",
      "UNII-ZU0ESU4399",
      "CHEMBL508791",
      "ZU0ESU4399",
      "CHEBI:65769",
      "88095-77-6",
      "4-(4-{[6-(3,5-Dihydroxyphenoxy)-4,7,9-Trihydroxyoxanthren-2-Yl]oxy}-3,5-Dihydroxyphenoxy)Oxanthrene-1,3,6,8-Tetrol",
      "4-[4-[[6-(3,5-Dihydroxyphenoxy)-4,7,9-Trihydroxydibenzo[b,e][1,4]dioxin-2-Yl]oxy]-3,5-Dihydroxyphenoxy]-Dibenzo[b,e][1,4]dioxin-1,3,6,8-Tetrol",
      "Dibenzo(B,E)(1,4)Dioxin-1,3,6,8-Tetrol, 4-(4-((6-(3,5-Dihydroxyphenoxy)-4,7,9-Trihydroxydibenzo(B,E)(1,4)Dioxin-2-Yl)Oxy)-3,5-Dihydroxyphenoxy)-",
    ],
  },
  {
    primary_id: "D08UZA",
    names: ["Chitosan/IL-12", "Chitosan/IL-12 Immunotherapy (Intravesical, Cancer), National Cancer Institute"],
  },
  {
    primary_id: "D08UZD",
    names: ["Viraplex"],
  },
  {
    primary_id: "D08UZJ",
    names: [
      "3-(2-Methyl-2-Aza-Bicyclo[3.3.1]non-5-Yl)-Phenol",
      "CHEMBL285250",
      "27107-68-2",
      "CTK0I5787",
      "DTXSID80494706",
      "BDBM50000570",
      "AKOS022657931",
      "3-(2-Methyl-2-Azabicyclo[3.3.1]nonan-5-Yl)Phenol",
      "Phenol, 3-(2-Methyl-2-Azabicyclo[3.3.1]non-5-Yl)-",
      "2-Methyl-5-(3-Hydroxyphenyl)-2-Azabicyclo[3.3.1]nonane",
    ],
  },
  {
    primary_id: "D08UZL",
    names: ["TMPyP4"],
  },
  {
    primary_id: "D08UZP",
    names: ["MPL-Containing Pollinex Allergy Desensitization Injectable Vaccine"],
  },
  {
    primary_id: "D08VAR",
    names: ["APH-0812", "Bryostatin-1 + HDAC Inhibitor Combination (HIV-1 Infection), Aphios"],
  },
  {
    primary_id: "D08VAT",
    names: ["Undecylenic Acid Derivative 1"],
  },
  {
    primary_id: "D08VBB",
    names: ["Selective Androgen Receptor Degraders"],
  },
  {
    primary_id: "D08VBE",
    names: ["LB42908"],
  },
  {
    primary_id: "D08VBG",
    names: ["PMID28117607-Compound-5"],
  },
  {
    primary_id: "D08VBV",
    names: ["TAP311"],
  },
  {
    primary_id: "D08VDJ",
    names: ["IDN-13389", "Anticancer Agent, Conatus"],
  },
  {
    primary_id: "D08VDM",
    names: ["AZD4694"],
  },
  {
    primary_id: "D08VDV",
    names: ["PMID30107136-Compound-Example15"],
  },
  {
    primary_id: "D08VDX",
    names: [
      "8-Chloro-6-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL363367",
      "SCHEMBL3923941",
      "IVJIWGTXXHDRCU-UHFFFAOYSA-N",
      "BDBM50168384",
      "ZINC13645099",
    ],
  },
  {
    primary_id: "D08VFX",
    names: ["Full-Length Factor VIII Molecule, Hemophilia"],
  },
  {
    primary_id: "D08VFY",
    names: ["Batimistat"],
  },
  {
    primary_id: "D08VGJ",
    names: [
      "5,6-Dichloro-3,4-Dihydroquinazolin-2-Amine",
      "Anagrelide Impurity 5",
      "2-Amino-5,6-Dichloro-3,4-Dihydroquinazoline",
      "444904-63-6",
      "CHEMBL1548",
      "2-Quinazolinamine, 5,6-Dichloro-1,4-Dihydro-",
      "W-202785",
      "SCHEMBL1569300",
      "CTK1D2410",
      "DTXSID80432648",
      "VBKOTIVQMCTTAQ-UHFFFAOYSA-N",
      "ZINC29130869",
      "BDBM50371434",
      "AKOS030254941",
      "5,6-Dichloro-1,4-Dihydro-2-Quinazolinamine",
      "2-Amino-5,6-Dichloro-3,4dihydroquinazoline",
      "FT-0722369",
    ],
  },
  {
    primary_id: "D08VGL",
    names: ["KT6006"],
  },
  {
    primary_id: "D08VGM",
    names: ["Gamma-Glutamyl[S-(2-Iodobenzyl)Cysteinyl]Glycine"],
  },
  {
    primary_id: "D08VGS",
    names: ["Recombinant Protein Subunit Vaccine  (Seasonal Influenza)"],
  },
  {
    primary_id: "D08VGT",
    names: [
      "BMY-30129",
      "BMS-181162",
      "4-(3-Carboxyphenyl)-3,7-Dimethyl-9-(2,6,6-Trimethyl-1-Cyclohexenyl)-2(Z),4(E),6(E),8(E)-Nonatetraenoicacid",
    ],
  },
  {
    primary_id: "D08VHG",
    names: ["RPL-554"],
  },
  {
    primary_id: "D08VHO",
    names: ["5-Hexyl-2-(Pyridin-3-Yloxy)Phenol", "CHEMBL405655"],
  },
  {
    primary_id: "D08VIS",
    names: ["AKB-9778"],
  },
  {
    primary_id: "D08VJC",
    names: ["PSMA ADC"],
  },
  {
    primary_id: "D08VJI",
    names: ["F-14413", "Alpha-2 Adrenoceptor Antagonist (Alzheimer's Disease), Pierre Fabre"],
  },
  {
    primary_id: "D08VKN",
    names: ["CP 4010", "CP-4010"],
  },
  {
    primary_id: "D08VKQ",
    names: ["INGN-007"],
  },
  {
    primary_id: "D08VKT",
    names: ["EX5"],
  },
  {
    primary_id: "D08VKZ",
    names: [
      "Edaglitazone",
      "Edaglitazone Sodium",
      "BM-131258",
      "BM-152054",
      "R-483",
      "Ro-205",
      "BM-13.258",
      "BM-15.2054",
      "Ro-205-2349",
      "Ro-2052349-602",
    ],
  },
  {
    primary_id: "D08VLD",
    names: ["Racemic DOTFM"],
  },
  {
    primary_id: "D08VLG",
    names: ["4-(1,1-Dimethyl-Heptyl)-2'-Methoxy-Biphenyl-2-Ol", "CHEMBL234678", "BDBM50213141"],
  },
  {
    primary_id: "D08VLN",
    names: ["CAT-2000"],
  },
  {
    primary_id: "D08VLT",
    names: [
      "4-Amino-6-(4-Octylphenyl)Hexanoic Acid",
      "CHEMBL325050",
      "SCHEMBL5462898",
      "BDBM50147701",
      "4-Amino-6-(4-Octyl-Phenyl)-Hexanoic Acid",
    ],
  },
  {
    primary_id: "D08VMO",
    names: ["JTT-811"],
  },
  {
    primary_id: "D08VMW",
    names: ["MP-529"],
  },
  {
    primary_id: "D08VOC",
    names: ["3,6-Bis(Dmt-Tic-NH-Propyl)-2(1H)-Pyrazinone", "CHEMBL381874"],
  },
  {
    primary_id: "D08VON",
    names: ["PMID26882240-Compound-15"],
  },
  {
    primary_id: "D08VPK",
    names: ["Suberic Bishydroxamic Acid"],
  },
  {
    primary_id: "D08VPN",
    names: ["UDP-Beta-S"],
  },
  {
    primary_id: "D08VQM",
    names: [
      "3-Amino-5-(4-Chlorophenyl)Thiophene-2-Carboxamide",
      "175137-05-0",
      "CHEMBL444884",
      "515142-45-7",
      "2-Thiophenecarboxamide,3-Amino-5-(4-Chlorophenyl)-",
      "AC1MBTGN",
      "3-Amino-5-(4-Chlorophenyl)-2-Thiophenecarboxamide",
      "SCHEMBL4097325",
      "CTK4D5319",
      "DTXSID90373351",
      "MQYJPACEBWKWEH-UHFFFAOYSA-N",
      "MolPort-000-150-990",
      "ZX-AT016871",
      "ZINC2556119",
      "SBB101177",
      "BDBM50186632",
      "5704AD",
      "AKOS022181810",
      "AJ-39853",
      "ACM175137050",
      "RT-019539",
      "FT-0704094",
      "3-Amino-5-(4-Chloro-Phenyl)-Thiophene-2-Carboxylic Acid Amide",
    ],
  },
  {
    primary_id: "D08VQU",
    names: ["Metal Complex Derivative 1"],
  },
  {
    primary_id: "D08VRJ",
    names: ["PD-149164"],
  },
  {
    primary_id: "D08VRM",
    names: ["RO-0534"],
  },
  {
    primary_id: "D08VRO",
    names: ["Ketanserin"],
  },
  {
    primary_id: "D08VRQ",
    names: ["EVIRNA"],
  },
  {
    primary_id: "D08VRV",
    names: ["Kuwanon V", "CHEMBL570609"],
  },
  {
    primary_id: "D08VRX",
    names: ["GHRP-2"],
  },
  {
    primary_id: "D08VSI",
    names: ["Revolade/Promacta", "AC1OC7GM"],
  },
  {
    primary_id: "D08VSQ",
    names: [
      "BMS-816336",
      "11B-HSD-1 Inhibitors (Non-Insulin Dependent Diabetes/Obesity/Metabolic Syndrome), BMS",
      "11B-HSD-1 Inhibitors (Non-Insulin Dependent Diabetes/Obesity/Metabolic Syndrome), Bristol-Myers Squibb",
      "11beta-Hydroxysteroid Dehydrogenase-Type 1 Inhibitors (Non-Insulin Dependent Diabetes/Obesity/Metabolic Syndrome), BMS",
      "11beta-Hydroxysteroid Dehydrogenase-Type 1 Inhibitors (Non-Insulin Dependent Diabetes/Obesity/Metabolic Syndrome), Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D08VTA",
    names: [
      "EXP-6803",
      "114773-44-3",
      "1H-Imidazole-5-Aceticacid, 2-Butyl-1-[[4-[(2-Carboxybenzoyl)Amino]phenyl]methyl]-4-Chloro-, 5-Methylester, Sodium Salt (1:1)",
      "Exp 6803",
      "AC1Q1VXO",
      "ACMC-20d225",
      "CTK4A8893",
      "Sodium 2-(2-Butyl-1-{4-[(2-Carboxybenzoyl)Amino]benzyl}-4-Chloro-1h-Imidazol-5-Yl)Propanoate",
      "Methyl 2-N-Butyl-1-(4-(2-Carboxybenzamido)Benzyl)-4-Chloroimidazole-5-Acetate",
      "1H-Imidazole-5-Acetic Acid, 2-Butyl-1-((4-((2-Carboxybenzoyl)Amino)Phenyl)Methyl)-4-Chloro-, Alpha-Methyl Ester, Monosodium Salt",
    ],
  },
  {
    primary_id: "D08VTS",
    names: [
      "MI-1148",
      "4-Guanidinomethyl-Phenylacteyl-Arg-Tle-Arg-4-Amidinobenzylamide",
      "Para-Guanidinomethyl-Phac-R-Tle-R-Amba",
    ],
  },
  {
    primary_id: "D08VTV",
    names: ["PMID28350212-Compound-7"],
  },
  {
    primary_id: "D08VUJ",
    names: ["ABT-202", "A-429202"],
  },
  {
    primary_id: "D08VUM",
    names: ["2-Hexyl-4-(4-Isobutyl-Phenyl)-1H-Imidazole", "CHEMBL331942", "SCHEMBL4812369"],
  },
  {
    primary_id: "D08VUO",
    names: ["Ethyl 2beta-Hydroxyolean-12-En-28-Oate", "CHEMBL1077973"],
  },
  {
    primary_id: "D08VUQ",
    names: ["1-(2-Methoxyphenyl)-4-Pentylpiperazine", "CHEMBL1269289", "SCHEMBL13875119", "BDBM50329677"],
  },
  {
    primary_id: "D08VVC",
    names: ["N,N-Dibenzyl[D-Pro-10]Dyn A-(1-11)", "CHEMBL267215"],
  },
  {
    primary_id: "D08VVI",
    names: ["AR-54"],
  },
  {
    primary_id: "D08VVR",
    names: ["TY-51924"],
  },
  {
    primary_id: "D08VVZ",
    names: [
      "J-104870",
      "145-13-1",
      "3-Hydroxypregn-5-En-20-One",
      "1-(3-Hydroxy-10,13-Dimethyl-2,3,4,7,8,9,10,11,12,13,14,15,16,17-Tetradecahydro-1h-Cyclopenta[a]phenanthren-17-Yl)Ethanon",
      "1-(3-Hydroxy-10,13-Dimethyl-2,3,4,7,8,9,10,11,12,13,14,15,16,17-Tetradecahydro-1H-Cyclopenta[a]phenanthren-17-Yl)Ethanone",
      ".DELTA.5-Pregnenolone",
      "5-Pregnene-3-Ol-20-One",
      "5-Pregnen-3.beta.-Ol-20-One",
      "Delta5-Pregnen-3beta-Ol-20-One",
      "Pregn-5-En-20-One, 3-Hydroxy-",
      "3.beta.-Hydroxypregn-5-En-20-One",
      "Pregn-5-En-20-One, 3.beta.-Hydroxy-",
      "AC1L1AKE",
      "AC1Q1K1J",
      "CHEMBL14116",
    ],
  },
  {
    primary_id: "D08VWF",
    names: ["GSK-932121A"],
  },
  {
    primary_id: "D08VWJ",
    names: ["TD-1770"],
  },
  {
    primary_id: "D08VWK",
    names: ["Vaxchora"],
  },
  {
    primary_id: "D08VWL",
    names: [
      "BRL-42715",
      "Brl 42715",
      "Brl-42715",
      "C6-(N1-Methyl-1,2,3-Trazolylmethylene)Penem",
      "4-Thia-1-Azabicyclo(3.2.0)Hept-2-Ene-2-Carboxylic Acid, 6-((1-Methyl-1H-1,2,3-Triazol-4-Yl)Methylene)-7-Oxo-, Sodium Salt, (R-(Z))-",
      "102209-75-6",
    ],
  },
  {
    primary_id: "D08VWM",
    names: ["QUAN-0806"],
  },
  {
    primary_id: "D08VWO",
    names: ["LFF571"],
  },
  {
    primary_id: "D08VXF",
    names: [
      "Cyclohexyl-(9-Methyl-9H-Purin-6-Yl)-Amine",
      "CHEMBL95332",
      "109292-93-5",
      "9H-Purin-6-Amine, N-Cyclohexyl-9-Methyl-",
      "N6-Cyclohexyl-9-Methyladenine",
      "ACMC-20mc61",
      "SCHEMBL14564552",
      "CTK0D5868",
      "DTXSID10576963",
      "BDBM50009683",
      "6-Cyclohexylamino-9-Methyl-9H-Purine",
      "AKOS030562210",
    ],
  },
  {
    primary_id: "D08VXL",
    names: ["Molsidomine"],
  },
  {
    primary_id: "D08VXO",
    names: [
      "Brompheniramine",
      "Bromfed",
      "Bromfenex",
      "Bromfeniramina",
      "Brotane",
      "Parabromdylamine",
      "BROMFED-DM",
      "Bromfeniramina [INN-Spanish]",
      "Brompheniramine (INN)",
      "Brompheniramine [INN:BAN]",
      "Brompheniraminum [INN-Latin]",
      "Brotane (TN)",
      "DIMETANE-DX",
      "P-Bromdylamine",
      "Para-Bromdylamine",
      "Brompheniramine Maleate (1:1)",
      "[3-(4-Bromophenyl)-3-(2-Pyridyl)Propyl]dimethylamine",
      "Gamma-(4-Bromophenyl)-N,N-Dimethyl-2-Pyridinepropanamine",
      "1-(P-Bromophenyl)-1-(2-Pyridyl)-3-Dimethylaminopropane",
      "2-(P-Bromo-Alpha-(2-Dimethylaminoethyl)Benzyl)Pyridine",
      "3-(4-Bromophenyl)-N,N-Dimethyl-3-(2-Pyridinyl)-1-Propanamine",
      "3-(4-Bromophenyl)-N,N-Dimethyl-3-(Pyridin-2-Yl)Propan-1-Amine",
      "3-(4-Bromophenyl)-N,N-Dimethyl-3-Pyridin-2-Ylpropan-1-Amine",
      "3-(P-Bromophenyl)-3-(2-Pyridyl)-N,N-Dimethylpropylamine",
    ],
  },
  {
    primary_id: "D08VYC",
    names: ["Phosphate"],
  },
  {
    primary_id: "D08VYV",
    names: [
      "Metoclopramide",
      "Cerucal",
      "Clopra",
      "Clopromate",
      "Duraclamid",
      "Elieten",
      "Emetid",
      "Emitasol",
      "Emperal",
      "Eucil",
      "Gastrese",
      "Gastrobid",
      "Gastromax",
      "Gastronerton",
      "Gastrosil",
      "Gastrotablinen",
      "Gastrotem",
      "Imperan",
      "Maxeran",
      "Maxolon",
      "Meclopran",
      "Metaclopramide",
      "Metaclopromide",
      "Metamide",
      "Methochlopramide",
      "Methoclopramide",
      "Metochlopramide",
      "Metoclol",
      "Metoclopramida",
      "Metoclopramidum",
      "Metocobil",
      "Metramid",
      "Moriperan",
      "Mygdalon",
      "Octamide",
      "Parmid",
      "Paspertin",
      "Peraprin",
      "Plasil",
      "Pramidin",
      "Pramiel",
      "Pramin",
      "Primperan",
      "Reclomide",
      "Reglan",
      "Reliveran",
      "Terperan",
      "Metoclopramide Omega",
      "DEL 1267",
      "Apo-Metoclop",
      "CLOPRA-YELLOW",
      "Clopra-Yellow",
      "Degan (TN)",
      "Elieten (TN)",
      "Gastro-Timelets",
      "Maxeran (TN)",
      "Maxolon (TN)",
      "Metoclopramida [INN-Spanish]",
      "Metoclopramide Monohydrochloride, Monohydrate",
      "Metoclopramidum [INN-Latin]",
      "Neu-Sensamide",
      "Nu-Metoclopramide",
      "Plasil (Pharmaceutical)",
      "Pms-Metoclopramide",
      "Primperan (TN)",
      "Pylomid (TN)",
      "Reglan (TN)",
      "Terperan (TN)",
      "Metoclopramide (JP15/INN)",
      "Metoclopramide [INN:BAN:JAN]",
      "N-(Diethylaminoethyl)-2-Methoxy-4-Amino-5-Chlorobenzamide",
      "O-Anisamide, 4-Amino-5-Chloro-N-(2-(Diethylamino)Ethyl)-(8CI)",
      "Benzamide, 4-Amino-5-Chloro-N-(2-(Diethylamino)Ethyl)-2-Methoxy-(9CI)",
      "(Metaclopramide)4-Amino-5-Chloro-N-(2-Diethylamino-Ethyl)-2-Methoxy-Benzamide",
      "(Metoclopramide)4-Amino-5-Chloro-N-(2-Diethylamino-Ethyl)-2-Methoxy-Benzamide",
      "1,5-Dimethyl-2-Phenyl-1,2-Dihydro-Pyrazol-3-One",
      "2-Methoxy-4-Amino-5-Chloro-N,N-Dimethylaminoethyl)Benzamide",
      "2-Methoxy-5-Chloroprocainamide",
      "2-Methoxy-4-Amino-5-Chloro-N,N-(Dimethylaminoethyl)Benzamide",
      "4 Amino-5-Chloro-N-(2-(Diethylamino)Ethyl)-2-Methoxybenzamide",
      "4-Amino-5-Chloro-2-Methoxy-N-(Beta-Diethylaminoethyl)Benzamide",
      "4-Amino-5-Chloro-N-(2-(Diethylamino)Ethyl)-2-Methoxybenzamide",
      "4-Amino-5-Chloro-N-(2-(Diethylamino)Ethyl)-O-Anisamide",
      "4-Amino-5-Chloro-N-(2-Diethylamino-Ethyl)-2-Methoxy-Benzamide",
      "4-Amino-5-Chloro-N-(2-Diethylamino-Ethyl)-2-Methoxy-Benzamide (Mcp)",
      "4-Amino-5-Chloro-N-(2-Diethylamino-Ethyl)-2-Methoxy-Benzamide (Metoclopramide)",
      "4-Amino-5-Chloro-N-(2-Diethylamino-Ethyl)-2-Methoxy-Benzamide(Metoclopramide)",
      "4-Amino-5-Chloro-N-(2-Diethylaminoethyl)-2-Methoxybenzamide",
      "4-Amino-5-Chloro-N-[2-(Diethylamino)Ethyl]-2-Methoxybenzamide",
      "5-Chloro-2-Methoxyprocainamide",
    ],
  },
  {
    primary_id: "D08VZH",
    names: ["ESS"],
  },
  {
    primary_id: "D08WAN",
    names: ["Lu-AF11167"],
  },
  {
    primary_id: "D08WAW",
    names: [
      "4-Pentyl-N-Pyridin-3-Yl Benzamide",
      "CHEMBL321932",
      "AC1MDVHQ",
      "4-Pentyl-N-Pyridin-3-Ylbenzamide",
      "MolPort-002-889-076",
      "ZINC3128742",
      "4-Pentyl-N-Pyridin-3-Yl-Benzamide",
      "BDBM50147079",
    ],
  },
  {
    primary_id: "D08WBB",
    names: [
      "BAY-73-1449",
      "SCHEMBL5906729",
      "GTPL5851",
      "(2S)-3-Phenyl-2-[[6-[4-(Phenylmethoxy)Phenyl]pyrimidin-4-Yl]amino]propanoic Acid",
    ],
  },
  {
    primary_id: "D08WBF",
    names: ["BB-3644"],
  },
  {
    primary_id: "D08WBJ",
    names: ["KOS-2187"],
  },
  {
    primary_id: "D08WCU",
    names: [
      "BMS-180448",
      "C20H18ClN5O2",
      "BMS 180448",
      "CHEMBL11802",
      "144264-47-1",
      "Trans-N-(4-Chlorophenyl)-N'-Cyano-N''-(6-Cyano-3,4-Dihydro-3-Hydroxy-2,2-Dimethyl-2H-1-Benzopyran-4-Yl)Guanidine",
      "Guanidine, N-(4-Chlorophenyl)-N'-Cyano-N''-(6-Cyano-3,4-Dihydro-3-Hydroxy-2,2-Dimethyl-2H-1-Benzopyran-4-Yl)-, Trans-",
      "Bms 180426",
      "AC1L3TUP",
      "DTXSID90162661",
      "BDBM50409908",
      "BMS-180426",
      "LS-173206",
      "1-(4-Chlorophenyl)-3-Cyano-2-[(3R,4S)-6-Cyano-3-Hydroxy-2,2-Dimethyl-3,4-Dihydrochromen-4-Yl]guanidine",
    ],
  },
  {
    primary_id: "D08WCY",
    names: ["Seysara"],
  },
  {
    primary_id: "D08WDH",
    names: ["HXTC-901"],
  },
  {
    primary_id: "D08WDN",
    names: ["4-Benzo[d]isoxazol-3-Yl-Benzene-1,3-Diol"],
  },
  {
    primary_id: "D08WDP",
    names: ["AM-3840", "Cathepsin S Inihibitors (Oral, Autoimmune Disease/Pain), Amura"],
  },
  {
    primary_id: "D08WDT",
    names: ["VDA , HIV-1"],
  },
  {
    primary_id: "D08WDW",
    names: ["DYSIDENIN"],
  },
  {
    primary_id: "D08WDY",
    names: ["Equilenin"],
  },
  {
    primary_id: "D08WEI",
    names: ["3-Methyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL364999", "BDBM50155779"],
  },
  {
    primary_id: "D08WEV",
    names: [
      "BAS-0338868",
      "CHEMBL378903",
      "N-(5-Phenethyl-1,3,4-Thiadiazol-2-Yl)Benzamide",
      "N-(5-Phenethyl-[1,3,4]thiadiazol-2-Yl)-Benzamide",
      "AC1LGHJD",
      "BAS 00338868",
      "Oprea1_674367",
      "Oprea1_387539",
      "MLS001208166",
      "Cid_821478",
      "MolPort-001-925-654",
      "ZINC338374",
      "HMS2838C05",
      "BDBM50187578",
      "AKOS000542350",
      "MCULE-7705926672",
      "SMR000513539",
    ],
  },
  {
    primary_id: "D08WFC",
    names: [
      "(5-Amino-1-Phenyl-1H-Pyrazol-4-Yl)Phenylmethanone",
      "54606-37-0",
      "(5-Amino-1-Phenyl-1H-Pyrazol-4-Yl)Phenyl Methanone",
      "(5-AMINO-1-PHENYL-1H-PYRAZOL-4-YL)(PHENYL)METHANONE",
      "4-Benzoyl-5-Aminopyrazole 1",
      "CHEMBL203333",
      "(5-Amino-1-Phenyl-1H-Pyrazol-4-Yl)-Phenyl-Methanone",
      "SCHEMBL13662120",
      "BDBM15714",
      "5-Amino-4-Benzoyl-1-Phenylpyrazole",
      "ZINC12365464",
      "1-Phenyl-4-Benzoyl-1H-Pyrazol-5-Amine",
      "4-Benzoyl-1-Phenyl-1H-Pyrazol-5-Amine",
      "KB-208557",
      "FT-0728731",
      "(5-Amino-1-Phenyl-1H-Pyrazol-4-Yl)(Phenyl)Methanone, AldrichCPR",
    ],
  },
  {
    primary_id: "D08WFH",
    names: ["DNA-HIV-C/NYVAC-HIV-C"],
  },
  {
    primary_id: "D08WGI",
    names: ["AK-2", "Actokine-2"],
  },
  {
    primary_id: "D08WGZ",
    names: ["TS-033"],
  },
  {
    primary_id: "D08WHC",
    names: [
      "(3-((1H-Imidazol-1-Yl)Methyl)Phenyl)Methanol",
      "[3-(1H-Imidazol-1-Ylmethyl)Phenyl]methanol",
      "151055-79-7",
      "CHEMBL597368",
      "W-205714",
      "[3-(Imidazol-1-Ylmethyl)Phenyl]methanol",
      "Benzenemethanol, 3-(1H-Imidazol-1-Ylmethyl)-",
      "SCHEMBL3092795",
      "DTXSID60439216",
      "MolPort-000-143-255",
      "ZX-AT016572",
      "BDBM50307217",
      "ZINC12370274",
      "SBB090815",
      "FCH921386",
      "AKOS006343964",
      "RP03595",
      "[3-(Imidazolylmethyl)Phenyl]methan-1-Ol",
      "DB-063877",
      "FT-0703495",
      "Y7599",
      "Benzenemethanol,3-(1H-Imidazol-1-Ylmethyl)-",
    ],
  },
  {
    primary_id: "D08WHU",
    names: ["4-(3-Adamantan-1-Yl-Ureido)-Benzoic Acid", "CHEMBL478321", "SCHEMBL12932181", "BDBM50267174"],
  },
  {
    primary_id: "D08WHV",
    names: [
      "5-N-Allyl-Arginine",
      "5-N-ALLYL-ARGININE",
      "ARV",
      "AC1NRA56",
      "DB03892",
      "N-[(1Z)-Amino{[(4S)-4-Amino-4-Carboxybutyl]amino}methylidene]prop-2-En-1-Aminium",
    ],
  },
  {
    primary_id: "D08WHX",
    names: ["AGS-348"],
  },
  {
    primary_id: "D08WII",
    names: ["Anti-MUC1 CAR T Cells"],
  },
  {
    primary_id: "D08WIJ",
    names: ["8-Methyl-2-Morpholino-7-Phenoxy-4H-Chromen-4-One", "CHEMBL203942"],
  },
  {
    primary_id: "D08WIN",
    names: ["MK-869"],
  },
  {
    primary_id: "D08WJG",
    names: ["Naphthyridine And Isoquinoline Derivative 1"],
  },
  {
    primary_id: "D08WJY",
    names: ["ISIS 114531"],
  },
  {
    primary_id: "D08WKQ",
    names: ["NSC-640566"],
  },
  {
    primary_id: "D08WLY",
    names: ["ME-401"],
  },
  {
    primary_id: "D08WMK",
    names: ["Benzenamine Derivative 3"],
  },
  {
    primary_id: "D08WNN",
    names: ["[33P]2MeSADP", "[33P]2-Methylthio-ADP"],
  },
  {
    primary_id: "D08WNO",
    names: ["GGF"],
  },
  {
    primary_id: "D08WNR",
    names: ["HIV-1 RNase H/HIV-1 Integrase Inhibitors"],
  },
  {
    primary_id: "D08WNU",
    names: ["[125I]RTI55", "[3H]iometopane", "[3H]beta-CIT", "[125I]-RTI55"],
  },
  {
    primary_id: "D08WOE",
    names: ["MC-3002"],
  },
  {
    primary_id: "D08WOG",
    names: [
      "4-(3-Chloro-Phenoxy)-6,7-Dimethoxy-Quinazoline",
      "CHEMBL135853",
      "SCHEMBL6182199",
      "XDUSWBSGDKRQAF-UHFFFAOYSA-N",
      "BDBM50291080",
    ],
  },
  {
    primary_id: "D08WOJ",
    names: ["OBE-101"],
  },
  {
    primary_id: "D08WOV",
    names: ["2-[125I]melatonin", "2-(125I)Iodomelatonin", "2[125I]iodomelatonin", "2-Iodo-[125I]-Melatonin"],
  },
  {
    primary_id: "D08WOW",
    names: ["LY3039478"],
  },
  {
    primary_id: "D08WPB",
    names: ["Five-And-Six-Membered Heterocyclic Compound 1"],
  },
  {
    primary_id: "D08WQH",
    names: ["BI 1361849", "CV-9202"],
  },
  {
    primary_id: "D08WQJ",
    names: [
      "(3-Nitro-1H-Pyrazol-1-Yl)(Phenyl)Methanone",
      "AC1LDSPA",
      "3-Nitropyrazolyl Phenyl Ketone",
      "N-Benzoylpyrazole Deriv., 19",
      "CHEMBL244940",
      "1-Benzoyl-3-Nitro-1H-Pyrazole",
      "BDBM23711",
      "ZINC79624",
      "MolPort-002-705-611",
      "STK760577",
      "AKOS001756309",
      "(3-Nitropyrazol-1-Yl)-Phenylmethanone",
      "MCULE-2233615430",
      "ST073764",
      "SR-01000524822",
      "SR-01000524822-1",
    ],
  },
  {
    primary_id: "D08WQM",
    names: [
      "O-Sialic Acid",
      "Alpha-Neu5Ac",
      "N-Acetyl-Alpha-Neuraminic Acid",
      "CHEBI:49026",
      "N-Acetyl-A-Neuraminic Acid",
      "UNII-04A90EXP8V",
      "Sialic Acid",
      "04A90EXP8V",
      "N-Acetyl-Alpha-Neuraminate",
      "NANA",
      "5-Acetamido-3,5-Dideoxy-D-Glycero-Alpha-D-Galacto-Non-2-Ulopyranosonic Acid",
      "131-48-6",
      "SIA",
      "(2R,4S,5R,6R)-5-Acetamido-2,4-Dihydroxy-6-[(1R,2R)-1,2,3-Trihydroxypropyl]oxane-2-Carboxylic Acid",
      "Polysialic Acid",
      "Alpha-Sialoside",
      "4lkh",
      "2qwb",
      "N-Acetyl-A-Neuraminate",
      "N-Acetyl-A-D-Neuraminate",
      "Epitope ID:136794",
      "SCHEMBL79085",
      "N-Acetyl-A-D-Neuraminic Acid",
    ],
  },
  {
    primary_id: "D08WQO",
    names: ["2-(1-Pentyl-Hexyl)-4-Phenyl-1H-Imidazole", "CHEMBL119494", "SCHEMBL4814520"],
  },
  {
    primary_id: "D08WQS",
    names: ["LAM-003"],
  },
  {
    primary_id: "D08WQU",
    names: ["AEB1102"],
  },
  {
    primary_id: "D08WQV",
    names: ["Glycocholic Acid"],
  },
  {
    primary_id: "D08WQW",
    names: ["2-Biphenyl-4-Yl-6-Morpholin-4-Yl-Pyran-4-One", "CHEMBL426894"],
  },
  {
    primary_id: "D08WRG",
    names: ["3-(4-O-Tolylpiperazine-1-Carbonyl)Coumarin", "CHEMBL509131", "AC1PLLS0", "BDBM50244558", "AKOS002932058"],
  },
  {
    primary_id: "D08WRI",
    names: ["Lantibiotic-Based Recombinant Protein"],
  },
  {
    primary_id: "D08WRU",
    names: [
      "N-Octadecyl-N'-Propylsulfamide",
      "CHEMBL219156",
      "SCHEMBL3119510",
      "N-Octadecyl-N''-Propylsulfamide",
      "VOJRCUXNIZFQKR-UHFFFAOYSA-N",
      "MolPort-009-019-252",
      "ZINC38151177",
    ],
  },
  {
    primary_id: "D08WSG",
    names: ["AZD-8683"],
  },
  {
    primary_id: "D08WSW",
    names: [
      "BRL-32872A",
      "BRL-32872 (Free Base)",
      "N-(3,4-Dimethoxyphenyl)-N-[3-[N'-Methyl-N'-[2-(3,4-Dimethoxyphenyl)Ethyl]amino]propyl]-4-Nitrobenzamide Hydrochloride",
    ],
  },
  {
    primary_id: "D08WSY",
    names: [
      "Denosumab",
      "Denosumab (USAN)",
      "Denosumab (Genetical Recombination)",
      "Prolia (TN)",
      "Denosumab (Genetical Recombination) (JAN)",
    ],
  },
  {
    primary_id: "D08WTE",
    names: ["Vorsetuzumab Mafodotin"],
  },
  {
    primary_id: "D08WTR",
    names: ["6-Iodo-Nordihydrocapsaicin"],
  },
  {
    primary_id: "D08WTW",
    names: ["Pyrazole Derivative 12"],
  },
  {
    primary_id: "D08WUF",
    names: ["Influenza DNA Vaccine"],
  },
  {
    primary_id: "D08WVA",
    names: ["UK-224671"],
  },
  {
    primary_id: "D08WVJ",
    names: [
      "[N-[2-[(3'-N'-PROPYL-3''ALPHA-(BIS(4-FLUORORPHENYL)METHOXY)TROPANE-2''BETA-CARBOXYLIC ACID METHYL ESTER)(2-MERCAPTOETHYL)AMINO]ACETYL]-2-AMINOETHANETHIOLATO]RHENIUM(V) OXIDE (DIASTEREOMERIC MIX)",
    ],
  },
  {
    primary_id: "D08WVK",
    names: ["Oxindole Derivative 3"],
  },
  {
    primary_id: "D08WVL",
    names: ["VM1500a"],
  },
  {
    primary_id: "D08WWN",
    names: ["RG7853"],
  },
  {
    primary_id: "D08WXD",
    names: ["ISIS 134609"],
  },
  {
    primary_id: "D08WYH",
    names: ["LY-2228820"],
  },
  {
    primary_id: "D08WYK",
    names: ["OJP-2028", "Antihypertensive, Jeil Pharmaceutical"],
  },
  {
    primary_id: "D08WYM",
    names: ["Nafarelin"],
  },
  {
    primary_id: "D08WYO",
    names: [
      "6F4",
      "Anti-JAM-A MAb (Cancer), Pierre Fabre",
      "JAM-A-Targeting Monoclonal Antibody (Cancer), Pierre Fabre",
    ],
  },
  {
    primary_id: "D08WZB",
    names: [
      "N5-[4-(1H-Pyrrol-1-Yl)Phenyl]-L-Glutamamide",
      "CHEMBL478549",
      "Modified Amino Acid Analog, 8b",
      "BDBM24267",
    ],
  },
  {
    primary_id: "D08WZC",
    names: ["PMID26651364-Compound-48"],
  },
  {
    primary_id: "D08WZQ",
    names: ["MRG-201"],
  },
  {
    primary_id: "D08WZV",
    names: ["Ac-VTHRLAGLLSRSGGVVKNNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D08XAC",
    names: [
      "Nystatin",
      "Biofanal",
      "Comycin",
      "Diastatin",
      "Fungicidin",
      "Herniocid",
      "Korostatin",
      "Moronal",
      "Myconystatin",
      "Mycostatin",
      "Mykinac",
      "Mykostatyna",
      "Nilstat",
      "Nistatin",
      "Nistatina",
      "Nyamyc",
      "Nyotran",
      "Nystaform",
      "Nystan",
      "Nystatine",
      "Nystatinum",
      "Nystatyna",
      "Nystavescent",
      "Nystex",
      "Nystop",
      "Stamicin",
      "Stamycin",
      "Terrastatin",
      "Candex Lotion",
      "Mycostatin Pastilles",
      "Nystatin A",
      "Nystatin G",
      "Nystatin Preparation",
      "Nystatyna [Polish]",
      "Zydin E",
      "Barstatin 100",
      "Mycostatin 20",
      "Nystatin A1",
      "Nystatin A2",
      "Nystatin A3",
      "Animax (TN)",
      "Candio-Hermal",
      "Flagystatin II (TN)",
      "Infestat (TN)",
      "Korostatin (TN)",
      "Lystin (TN)",
      "Moronal (Antibiotic)",
      "Mycostatin (TN)",
      "Mykacet (TN)",
      "Mykinac (TN)",
      "Mytrex (TN)",
      "Nidoflor (TN)",
      "Nilstat (TN)",
      "Nistatina (TN)",
      "Nistatina [INN-Spanish]",
      "Nysert (TN);Nystaform (TN)",
      "Nystalocal (TN)",
      "Nystamont (TN)",
      "Nystan (TN)",
      "Nystatin LF (Liposome)",
      "Nystatine [INN-French]",
      "Nystatinum [INN-Latin]",
      "Nystex (TN)",
      "Nystop (TN)",
      "O-V Statin",
      "Myco-Triacet II (TN)",
      "Mycolog-II (TN)",
      "Nystatin A1, A2, A3 Complex",
      "Nystatin [INN:BAN:JAN]",
      "(1S,15S,16R,17R,18S,19E,21E,25E,27E,29E,31E)-33-[(3-Amino-3,6-Dideoxy-A-D-Mannopyranosyl)Oxy]-1,3,4,7,9,11,17,37-Octahydroxy-15,16,18-Trimethyl-13-Oxo-14,39-Dioxabicyclo[33.3.1]nonatriaconta-19,21,25,27,29,31-Hexaene-36-Carboxylic Acid",
      "(1S,15S,16R,17R,18S,19E,21E,25E,27E,29E,31E)-33-[(3-Amino-3,6-Dideoxy-Alpha-D-Mannopyranosyl)Oxy]-1,3,4,7,9,11,17,37-Octahydroxy-15,16,18-Trimethyl-13-Oxo-14,39-Dioxabicyclo[33.3.1]nonatriaconta-19,21,25,27,29,31-Hexaene-36-Carboxylic Acid",
      "(4E,6E,8E,10E,14E,16E)-3-[(2R,3S,4S,5S,6R)-4-Amino-3,5-Dihydroxy-6-Methyloxan-2-Yl]oxy-19,25,27,29,32,33,35,37-Octahydroxy-18,20,21-Trimethyl-23-Oxo-22,39-Dioxabicyclo[33.3.1]nonatriaconta-4,6,8,10,14,16-Hexaene-38-Carboxylic Acid",
      "(4E,6E,8E,10E,14E,16E,18S,19R,20R,21S,35S)-3-[(2S,3S,4S,5S,6R)-4-Amino-3,5-Dihydroxy-6-Methyloxan-2-Yl]oxy-19,25,27,29,32,33,35,37-Octahydroxy-18,20,21-Trimethyl-23-Oxo-22,39-Dioxabicyclo[33.3.1]nonatriaconta-4,6,8,10,14,16-Hexaene-38-Carboxylic Acid",
      "(4E,6E,8E,10E,14E,16E,18S,19R,21S,35S)-3-[(3S,4S,5S,6R)-4-Amino-3,5-Dihydroxy-6-Methyloxan-2-Yl]oxy-19,25,27,29,32,33,35,37-Octahydroxy-18,20,21-Trimethyl-23-Oxo-22,39-Dioxabicyclo[33.3.1]nonatriaconta-4,6,8,10,14,16-Hexaene-38-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D08XAW",
    names: ["Cyclopentylcytosine"],
  },
  {
    primary_id: "D08XAY",
    names: ["Antibodie Derivative 6"],
  },
  {
    primary_id: "D08XBF",
    names: [
      "AMEP",
      "(Aminomethyl)Phosphonic Acid",
      "1066-51-9",
      "AMINOMETHYLPHOSPHONIC ACID",
      "Aminomethanephosphonic Acid",
      "AMeP",
      "1-Aminomethylphosphonic Acid",
      "1-Aminomethylphosphonate",
      "Amino Methane Phosphoric Acid",
      "Caswell No. 037C",
      "Phosphaglycine",
      "(1-Aminomethyl)Phosphonic Acid",
      "Phosphonic Acid, Aminomethyl-",
      "UNII-90825O5C1U",
      "Phosphonic Acid, (Aminomethyl)-",
      "Aminomethylphosphonic Acid (AMPA)",
      "NSC 30076",
      "EPA Pesticide Chemical Code 207800",
      "CHEBI:28812",
      "MGRVRXRGTBOSHW-UHFFFAOYSA-N",
      "(Aminomethyl)Phosphonic Acid, 99%",
      "MFCD00008105",
      "GEO-00169",
      "90825O5C1U",
    ],
  },
  {
    primary_id: "D08XBQ",
    names: ["NNC 11-1607"],
  },
  {
    primary_id: "D08XCD",
    names: [
      "2-(Cyclohexylamino)Benzoic Acid",
      "10286-53-0",
      "2-CYCLOHEXYLAMINO-BENZOIC ACID",
      "Oprea1_006076",
      "SCHEMBL3274851",
      "DTXSID60600368",
      "MolPort-005-940-199",
      "KS-00003TL8",
      "ZINC21999434",
      "AKOS009313819",
      "DB07038",
      "TS-03143",
      "AJ-80046",
      "Z1945984208",
    ],
  },
  {
    primary_id: "D08XCL",
    names: ["ISIS 330031"],
  },
  {
    primary_id: "D08XDA",
    names: ["Carbamide Derivative 15"],
  },
  {
    primary_id: "D08XDH",
    names: ["[4Aph(CO-(C2H4O)3-C2H4-NH-CO-NH-OCH3)5]degarelix", "CHEMBL409044"],
  },
  {
    primary_id: "D08XDL",
    names: ["KHK2455"],
  },
  {
    primary_id: "D08XDO",
    names: ["Glial Progenitor Cell Therapy"],
  },
  {
    primary_id: "D08XDV",
    names: ["ACAM-Cdiff"],
  },
  {
    primary_id: "D08XEM",
    names: ["PI3K Pathway Inhibitors"],
  },
  {
    primary_id: "D08XEQ",
    names: [
      "N,N-Dihexyl-2-(2-Phenyl-1H-Indol-3-Yl)-Acetamide",
      "CHEMBL98081",
      "142720-26-1",
      "1H-Indole-3-Acetamide,N,N-Dihexyl-2-Phenyl-",
      "ACMC-20n1pu",
      "N,N-DIHEXYL-2-PHENYLINDOLE-3-ACETAMIDE",
      "CTK4C3254",
      "DTXSID30440213",
      "ZINC13833112",
      "BDBM50045894",
      "AKOS015965050",
    ],
  },
  {
    primary_id: "D08XES",
    names: ["PMID27215781-Compound-4"],
  },
  {
    primary_id: "D08XFA",
    names: ["IL-2 XL"],
  },
  {
    primary_id: "D08XFJ",
    names: ["NN-1218"],
  },
  {
    primary_id: "D08XFR",
    names: [
      "AEZS-115",
      "D-85108",
      "LHRH Antagonist, Solvay",
      "LHRH Antagonist, Zentaris",
      "ZEN-019",
      "LHRH Antagonists, Solvay/AEterna Zentaris",
    ],
  },
  {
    primary_id: "D08XFV",
    names: [
      "Flurazepam",
      "Dalmadorm",
      "Dalmane",
      "Felison",
      "Felmane",
      "Flunox",
      "Flurazepamum",
      "Insumin",
      "Noctosom",
      "Paxane",
      "Somnol",
      "Stauroderm",
      "Valdorm",
      "Flurazepam HCL",
      "Flurazepam Hydrochloride",
      "Dalmadorm (TN)",
      "Dalmane (TN)",
      "Dalmane-R",
      "Flurazepamum [INN-Latin]",
      "Insumin (TN)",
      "Flurazepam (JP15/INN)",
      "Flurazepam [INN:BAN:JAN]",
      "Ro-5-6901/3",
      "2H-1,4-Benzodiazepin-2-One,7-Chloro-1-[2-(Diethylamino)Ethyl]-5-(2-Fluorophenyl)-1,3-Dihydro-, Dihydrochloride",
      "7-Chloro-1-(2-(Diethylamino)Ethyl)-5-(2-Fluorophenyl)-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-1-(2-(Diethylamino)Ethyl)-5-(2-Fluorophenyl)-1H-1,4-Benzodiazepin-2(3H)-One",
      "7-Chloro-1-[2-(Diethylamino)Ethyl]-5-(2-Fluorophenyl)-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-1-(2-Diethylaminoethyl)-5-(2-Fluorophenyl)-3H-1,4-Benzodiazepin-2-One",
    ],
  },
  {
    primary_id: "D08XGH",
    names: ["Reglitazar"],
  },
  {
    primary_id: "D08XGI",
    names: [
      "(1-Phenethyl-Piperidin-4-Yl)-Phenyl-Methanone",
      "CHEMBL19589",
      "SCHEMBL7839803",
      "GEFOLRNTTIQKBZ-UHFFFAOYSA-N",
      "ZINC13740924",
      "BDBM50034203",
      "Phenyl(1-Phenethyl-4-Piperidinyl) Ketone",
      "Phenyl-[1-(2-Phenylethyl)-4-Piperidyl]-Ketone",
    ],
  },
  {
    primary_id: "D08XGL",
    names: ["(+/-)-Threo-N-(3-Phenylpropyl)Ritalinol", "CHEMBL1254359"],
  },
  {
    primary_id: "D08XHN",
    names: ["AL-59412C"],
  },
  {
    primary_id: "D08XIF",
    names: ["GSK-0862"],
  },
  {
    primary_id: "D08XJX",
    names: [
      "3-Benzyl-4-Hydroxy-6,7-Dimethyl-2H-Chromen-2-One",
      "CHEMBL573693",
      "BDBM35542",
      "4-Hydroxy-2H-Chromen-2-One Core, 18",
    ],
  },
  {
    primary_id: "D08XKL",
    names: ["SPI-014"],
  },
  {
    primary_id: "D08XKO",
    names: [
      "8-Chloro-1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One",
      "CHEMBL98373",
      "SCHEMBL10757832",
      "2H-Imidazo[4,5-B]quinolin-2-One, 8-Chloro-1,3-Dihydro-",
      "BDBM50229559",
    ],
  },
  {
    primary_id: "D08XLK",
    names: ["PMID28092474-Compound-33m"],
  },
  {
    primary_id: "D08XLL",
    names: ["HGCGR Antagonist", "Acylated Aminothiophene Nitrile", "Glucagon Receptor Antagonist I"],
  },
  {
    primary_id: "D08XLM",
    names: ["PS-725074", "CHEMBL427946", "BDBM50071551"],
  },
  {
    primary_id: "D08XLN",
    names: ["Apomorphine Infusion"],
  },
  {
    primary_id: "D08XLV",
    names: ["Sharon-5000"],
  },
  {
    primary_id: "D08XML",
    names: [
      "Androstenol",
      "5alpha-Androst-16-En-3alpha-Ol",
      "1153-51-1",
      "Androst-16-En-3alpha-Ol",
      "16,17-ANDROSTENE-3-OL",
      "Androst-16-En-3-Ol",
      "3alpha-Hydroxyandrost-16-Ene",
      "Alpha-Androstenol",
      "UNII-48K9VAM062",
      "(3alpha,5alpha)-Androst-16-En-3-Ol",
      "Androst-16-En-3-Ol, (3alpha,5alpha)-",
      "CHEMBL142348",
      "CHEBI:40933",
      "KRVXMNNRSSQZJP-PHFHYRSDSA-N",
      "48K9VAM062",
      "Androst-16-En-3-Ol, (3a,5a)-",
      "16,17-Androstene-3-Ol",
    ],
  },
  {
    primary_id: "D08XMM",
    names: ["NicVAX"],
  },
  {
    primary_id: "D08XMS",
    names: ["N1-(Biphenyl-3-Yl)-N8-Hydroxyoctanediamide", "CHEMBL473270"],
  },
  {
    primary_id: "D08XNH",
    names: ["GSK221149"],
  },
  {
    primary_id: "D08XNQ",
    names: ["SHU9119"],
  },
  {
    primary_id: "D08XNW",
    names: ["WHIP-154"],
  },
  {
    primary_id: "D08XOA",
    names: ["Benzothiazepine Analog 2"],
  },
  {
    primary_id: "D08XOF",
    names: ["P-13"],
  },
  {
    primary_id: "D08XOT",
    names: ["LY467711", "BDBM86756", "LY 467711"],
  },
  {
    primary_id: "D08XOV",
    names: ["PMID25980951-Compound-16"],
  },
  {
    primary_id: "D08XOY",
    names: ["ABT-126"],
  },
  {
    primary_id: "D08XPI",
    names: [
      "CMV-Specific STAR Fusions",
      "CMV-Specific STAR Fusions, Altor Bioscience",
      "STAR Fusions Agents (Cytomegalo Virus Infection), Altor",
    ],
  },
  {
    primary_id: "D08XPN",
    names: ["Mab 1-7F9"],
  },
  {
    primary_id: "D08XPP",
    names: ["9-Hydrazino-1,2,3,4-Tetrahydroacridine", "CHEMBL219388", "ZINC40576784", "AKOS011534842"],
  },
  {
    primary_id: "D08XPW",
    names: [
      "CYTISINE",
      "485-35-8",
      "Sophorine",
      "Baptitoxine",
      "(-)-Cytisine",
      "Laburnin",
      "Baptitoxin",
      "Tabex",
      "Ulexin",
      "Cytiton",
      "Ulexine",
      "Tsitizin",
      "Cytitone",
      "Cystisine",
      "Cytizin",
      "Citizin",
      "Tabax",
      "(1R,5S)-3,4,5,6-Tetrahydro-1H-1,5-Methanopyrido[1,2-A][1,5]diazocin-8(2H)-One",
      "Baphitoxine",
      "UNII-53S5U404NU",
      "Cytisine (-)",
      "HSDB 3560",
      "EINECS 207-616-0",
      "NSC 407282",
      "BRN 0083882",
      "Cytisin",
      "(1r,5s)-1,2,3,4,5,6-Hexahydro-8h-1,5-Methanopyrido[1,2-A][1,5]diazocin-8-One",
      "CHEBI:4055",
      "CHEMBL497939",
      "53S5U404NU",
      "Cytisine, 98%",
    ],
  },
  {
    primary_id: "D08XPZ",
    names: ["3-Methylquinoline-8-Carboxamide", "CHEMBL504998", "8-Quinolinecarboxamide, 3-Methyl-", "BDBM50255267"],
  },
  {
    primary_id: "D08XQA",
    names: ["RSD-921"],
  },
  {
    primary_id: "D08XQG",
    names: ["PMID29473428-Compound-59"],
  },
  {
    primary_id: "D08XQI",
    names: ["PARAOXON"],
  },
  {
    primary_id: "D08XQV",
    names: ["USL311"],
  },
  {
    primary_id: "D08XRD",
    names: ["SF-108"],
  },
  {
    primary_id: "D08XRH",
    names: [
      "DMPP",
      "Dimethylphenylpiperazinium",
      "UNII-2FLS86GK2A",
      "2FLS86GK2A",
      "CHEMBL134752",
      "1,1-Dimethyl-4-Phenylpiperazin-1-Ium",
      "114-28-3",
      "Piperazinium, 1,1-Dimethyl-4-Phenyl-",
      "Lopac-D-5891",
      "AC1L1B7H",
      "Cid_5911",
      "Lopac0_000400",
      "GTPL3967",
      "SCHEMBL3453554",
      "DTXSID10150626",
      "CHEBI:124985",
      "ZINC155006",
      "BDBM50061567",
      "MCULE-7122222999",
      "CCG-204493",
      "MRF-0000344",
      "NCGC00021930-03",
      "NCGC00015356-03",
      "NCGC00015356-02",
      "NCGC00163236-01",
      "NCGC00015356-05",
      "NCGC00015356-04",
      "NCGC00015356-06",
      "NCGC00015356-01",
      "1,1-Dimethyl-4-Phenyl-Piperazin-1-Ium",
      "ZB005924",
      "AJ-13412",
    ],
  },
  {
    primary_id: "D08XRM",
    names: ["Cyclo(-D-Tyr-L-Arg-L-Arg-L-Ala-Sar-)", "CHEMBL219075"],
  },
  {
    primary_id: "D08XRX",
    names: ["ANGX-3227"],
  },
  {
    primary_id: "D08XTL",
    names: ["RWJ-69736"],
  },
  {
    primary_id: "D08XUP",
    names: ["GE-37468A"],
  },
  {
    primary_id: "D08XVR",
    names: [
      "Baminercept",
      "BG-9924",
      "Soluble Lymphotoxin Beta Receptor, Biogen",
      "Soluble Lymphotoxin Beta Receptor, Biogen Idec",
    ],
  },
  {
    primary_id: "D08XVU",
    names: ["MVA-BN Anthrax", "Anthrax Vaccine, Bavarian Nordic"],
  },
  {
    primary_id: "D08XVZ",
    names: ["SCH772984"],
  },
  {
    primary_id: "D08XWS",
    names: ["TCS2314"],
  },
  {
    primary_id: "D08XWZ",
    names: [
      "ALX-0646",
      "ALC-2527XX",
      "ALC-900XX",
      "ALC-901XX",
      "ALC-902XX",
      "ALC-905XX",
      "ALC-907XX",
      "ALC-913",
      "ALC-918",
      "ALC-918XX",
      "ALC-947XX",
      "ALC-948XX",
      "ALC-962XX",
      "ALC-963XX",
      "ALC-973XX",
      "ALC-974XX",
      "ALX-2324",
      "ALX-2325",
      "ALX-2326",
      "ALX-2354XX",
      "ALX-2355XX",
      "ALX-2356XX",
      "ALX-2357XX",
      "ALX-2436XX",
      "ALX-2437XX",
      "ALX-2458XX",
      "ALX-2459XX",
      "ALX-2476XX",
      "ALX-2477XX",
      "ALX-2503XX",
      "ALX-2504XX",
      "ALX-2581",
      "ALX-2582",
      "ALX-2592",
      "ALX-646CL",
      "ALX-912",
    ],
  },
  {
    primary_id: "D08XXD",
    names: [
      "4-Aminonaphthalen-1-Ol",
      "4-Amino-1-Naphthol",
      "2834-90-4",
      "4-Amino-1-Naphthalenol",
      "1-Naphthalenol, 4-Amino-",
      "1-AMINO-4-NAPHTHOL",
      "UNII-02W29959D9",
      "4-Amino-Naphthalen-1-Ol",
      "CHEMBL576321",
      "ABJQKDJOYSQVFX-UHFFFAOYSA-N",
      "4-Amino-1-Naphtholhydrochloride",
      "02W29959D9",
      "PCMD-CC-PAB-253",
      "PC-0685415",
      "4-Amino1-Naphthol",
      "4-Amino-1-Naphtol",
      "4-Azanylnaphthalen-1-Ol",
      "AC1L2AQW",
      "1-Amino-4-Hydroxynaphthalene",
      "SCHEMBL367092",
      "AC1Q523Y",
      "Cid_2723858",
      "BDBM43339",
      "DTXSID30182580",
      "ZINC119431",
    ],
  },
  {
    primary_id: "D08XXE",
    names: ["2-Phosphoglycolic Acid"],
  },
  {
    primary_id: "D08XYK",
    names: ["Lobatamide D"],
  },
  {
    primary_id: "D08XYR",
    names: [
      "DOM",
      "2,5-Dimethoxy-4-Methylamphetamine",
      "STP (Hallucinogen)",
      "(RS)-DOM",
      "15588-95-1",
      "Dl-2,5-Dimethoxy-4-Methylamphetamine",
      "2,5-Dimethoxymethylamphetamine",
      "4-Methyl-2,5-Dimethoxyamphetamine",
      "(+-)-DOM",
      "Dl-4-Methyl-2,5-Dimethoxyamphetamine",
      "2',5'-Dimethoxy-4'-Methylamphetamine",
      "CHEMBL8600",
      "(+-)-2,5-Dimethoxy-4-Methylamphetamine",
      "2,5-Dimethoxy-4-Methylphenylisopropylamine",
      "2,5-Dimethoxy-4,alpha-Dimethylphenethylamin",
      "DEA No. 7395",
      "2,5-Dimethoxy-Alpha,4-Dimethylphenylethylamine",
      "2-(2,5-Dimethoxy-4-Methylphenyl)-2-Aminopropane",
    ],
  },
  {
    primary_id: "D08XYV",
    names: ["9-(3-N-Pentylureido)Non-4(Z)-Enoic Acid", "CHEMBL551899"],
  },
  {
    primary_id: "D08XYX",
    names: ["Aprotinin"],
  },
  {
    primary_id: "D08XZQ",
    names: ["PF-4287881"],
  },
  {
    primary_id: "D08YAD",
    names: ["Disarmin"],
  },
  {
    primary_id: "D08YAF",
    names: ["PLSQETFSDLWKLLPEN-NH2", "CHEMBL267823"],
  },
  {
    primary_id: "D08YAL",
    names: ["PMID25666693-Compound-111"],
  },
  {
    primary_id: "D08YAY",
    names: ["Certolizumab Pegol", "CDP-870", "Cimzia", "PHA-738144", "Simziya"],
  },
  {
    primary_id: "D08YCD",
    names: ["1-(1-Biphenyl-4-Yl-Ethyl)-1H-Imidazole", "CHEMBL497499", "SCHEMBL10649315", "BDBM50272506"],
  },
  {
    primary_id: "D08YCE",
    names: ["8-(3-Chlorobenzyloxy)Caffeine", "CHEMBL602260"],
  },
  {
    primary_id: "D08YCI",
    names: ["ISIS 5876"],
  },
  {
    primary_id: "D08YCS",
    names: ["TQ-1017"],
  },
  {
    primary_id: "D08YDT",
    names: ["KAI-4169"],
  },
  {
    primary_id: "D08YFD",
    names: ["PF-05212372"],
  },
  {
    primary_id: "D08YGD",
    names: ["Haempatch"],
  },
  {
    primary_id: "D08YGE",
    names: ["PMID28048944-Compound-21"],
  },
  {
    primary_id: "D08YGM",
    names: ["SKF89976A"],
  },
  {
    primary_id: "D08YGW",
    names: [
      "1-Benzyl-5-Phenyl-1H-Imidazole",
      "53704-79-3",
      "1-Benzyl-5-Phenylimidazole",
      "SCHEMBL1501929",
      "CHEMBL463582",
      "CTK1G0360",
      "BDBM24655",
      "DTXSID30440036",
      "AWZYHJQWJWUOHS-UHFFFAOYSA-N",
      "AKOS015905520",
      "4-Phenylimidazole (4-PI) Derivative, 27",
      "1H-Imidazole, 5-Phenyl-1-(Phenylmethyl)-",
    ],
  },
  {
    primary_id: "D08YGY",
    names: ["MK-6302"],
  },
  {
    primary_id: "D08YHA",
    names: ["2,2-Dimethyl-2H-Benzo[g]chromene-5,10-Dione"],
  },
  {
    primary_id: "D08YHB",
    names: ["BIIB 023"],
  },
  {
    primary_id: "D08YHD",
    names: ["1,1,1-Trifluoro-7-Phenylheptan-2-One", "1,1,1-Trifluoro-7-Phenyl-Heptan-2-One"],
  },
  {
    primary_id: "D08YHE",
    names: ["NKR-2 CAR-T Cells"],
  },
  {
    primary_id: "D08YHS",
    names: ["Linopirdine", "AVIVA", "Linopirine", "DuP-996"],
  },
  {
    primary_id: "D08YIN",
    names: ["Dalfampridine", "Ampyra (TN)"],
  },
  {
    primary_id: "D08YJE",
    names: [
      "(+/-)-Threo-N-(4-Phenylbutyl)Methylphenidate",
      "CHEMBL1254100",
      "BDBM50327117",
      "(AlphaR)-Alpha-[(2R)-1-(4-Phenylbutyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D08YJF",
    names: [
      "2-Cyclohexyl-N-Phenyl-Acetamide",
      "2-Cyclohexyl-N-Phenylacetamide",
      "CHEMBL191663",
      "4-Cyclohexylacetanilide",
      "CBMicro_047799",
      "AC1LH2Y4",
      "Cambridge Id 6369179",
      "SCHEMBL3822162",
      "WIFWWZGKYZCASF-UHFFFAOYSA-N",
      "MolPort-002-194-779",
      "ZINC451519",
      "BDBM50167040",
      "STL384376",
      "AKOS008916535",
      "BIM-0047893.P001",
      "SR-01000232777",
    ],
  },
  {
    primary_id: "D08YJN",
    names: ["BRT-FC-83C", "Dermatological Agent (Topical, Atopic Dermatitis), Biomed Research & Technologies"],
  },
  {
    primary_id: "D08YJP",
    names: [
      "1-(2-Fluoro-Phenyl)-Piperazine",
      "1-(2-Fluorophenyl)Piperazine",
      "1011-15-0",
      "Piperazine, 1-(2-Fluorophenyl)-",
      "1-(2-Fluorophenyl)-Piperazine",
      "CHEMBL9429",
      "IVTZRJKKXSKXKO-UHFFFAOYSA-N",
      "1-(2-Fluorophenyl)Piperazine, 97%",
      "MFCD00040726",
      "(2-Fluorophenyl)Piperazine",
      "EINECS 213-780-4",
      "OFPP",
      "PubChem8573",
      "1-(2-Fluorophenyl)Piperazinehydrochloride",
      "2-Fluorophenylpiperazine",
      "2-Fluorophenyl Piperazine",
      "ACMC-1BX8H",
      "Ortho-Fluorophenylpiperazine",
      "4-(Fluorophenyl)Piperazine",
      "AC1Q4O5O",
      "AC1Q4L3C",
      "AC1L2EA5",
      "1-(2-Flurophenyl)Piperazine",
    ],
  },
  {
    primary_id: "D08YKD",
    names: [
      "CLT-011",
      "CLT-011 Binding Compounds (Inherited Blinding Diseases)",
      "CLT-011 Binding Compounds (Inherited Blinding Diseases), Charlesson",
    ],
  },
  {
    primary_id: "D08YKN",
    names: ["3-(2-Phenyl-6-(Thiophen-2-Yl)Pyridin-4-Yl)-Phenol", "CHEMBL1096659"],
  },
  {
    primary_id: "D08YLC",
    names: ["ONO-01210"],
  },
  {
    primary_id: "D08YLO",
    names: ["N-(4-Amino-4'-Fluorobiphenyl-3-Yl)Benzamide", "CHEMBL1098337", "SCHEMBL15398027", "BDBM50317988"],
  },
  {
    primary_id: "D08YLW",
    names: ["3-Demethoxy-3-L-Fucopyranosylaminothiocolchicine", "CHEMBL214151"],
  },
  {
    primary_id: "D08YMD",
    names: ["Quinoxaline Derivative 5"],
  },
  {
    primary_id: "D08YNC",
    names: ["MU-1140-S"],
  },
  {
    primary_id: "D08YNI",
    names: ["Somatoprim"],
  },
  {
    primary_id: "D08YOM",
    names: ["Sulopenem Etzadroxil"],
  },
  {
    primary_id: "D08YPQ",
    names: ["Thiazole Carboxamide Derivative 7"],
  },
  {
    primary_id: "D08YPU",
    names: ["4-Carboxamido-Isoindolinone Derivative 2"],
  },
  {
    primary_id: "D08YPZ",
    names: [
      "Ammoniated Mercury",
      "White Precipitate",
      "Mercuric Chloride, Ammoniated",
      "Mercury Ammoniated",
      "Ammoniated Mercuric Chloride",
      "Mercury, Ammoniated",
      "Aminomercury Chloride",
      "Aminomercuric Chloride",
      "Hydrargyrum Ammoniatum",
      "Mercury Amine Chloride",
      "MERCURIC AMMONIUM CHLORIDE",
      "White Mercuric Precipitate",
      "White Mercury Precipitated",
      "Quecksilber(II)-Amid-Chlorid",
      "Hydrargyrum Precipitatum Album",
      "Hydrargyrum Praecipitatum Album",
      "UNII-JD546Z56F0",
      "Mercury(II) Chloride Ammonobasic",
      "Mercury, Ammonobasic (HgNH2Cl)",
      "HSDB 1175",
      "JD546Z56F0",
      "EINECS 233-3",
    ],
  },
  {
    primary_id: "D08YQG",
    names: ["Entelon"],
  },
  {
    primary_id: "D08YQT",
    names: [
      "AM251",
      "183232-66-8",
      "AM-251",
      "AM 251",
      "UNII-3I4FA44MAI",
      "N-(Piperidin-1-Yl)-5-(4-Iodophenyl)-1-(2,4-Dichlorophenyl)-4-Methyl-1H-Pyrazole-3-Carboxamide",
      "1-(2,4-Dichlorophenyl)-5-(4-Iodophenyl)-4-Methyl-N-(Piperidin-1-Yl)-1H-Pyrazole-3-Carboxamide",
      "3I4FA44MAI",
      "CHEMBL285932",
      "CHEBI:90724",
      "MFCD01861181",
      "CPD000466284",
      "1-(2,4-Dichlorophenyl)-5-(4-Iodophenyl)-4-Methyl-N-Piperidin-1-Ylpyrazole-3-Carboxamide",
      "N-(Piperidin-1-Yl)-5-(4-Iodophenyl)-1-(2, 4-Dichlorophenyl)-4-Methyl-1H-Pyrazole-3-Carboxamide",
    ],
  },
  {
    primary_id: "D08YRD",
    names: ["A-717"],
  },
  {
    primary_id: "D08YRM",
    names: ["Long-Acting Interferon Beta Conjugate"],
  },
  {
    primary_id: "D08YRO",
    names: ["Ad VEGF-C"],
  },
  {
    primary_id: "D08YSY",
    names: ["VGX-200"],
  },
  {
    primary_id: "D08YTF",
    names: ["RG6013"],
  },
  {
    primary_id: "D08YTQ",
    names: ["M2e Influenza Vaccine"],
  },
  {
    primary_id: "D08YUP",
    names: ["SB-213698"],
  },
  {
    primary_id: "D08YUQ",
    names: ["Ac-Cys-Ile-Tyr-Lys-Phe(4-N3)-Tyr"],
  },
  {
    primary_id: "D08YVP",
    names: ["Vericigiuat"],
  },
  {
    primary_id: "D08YVT",
    names: ["SCH-211803"],
  },
  {
    primary_id: "D08YVZ",
    names: ["Flavonoids"],
  },
  {
    primary_id: "D08YWE",
    names: ["Pim[-Trp-Arg-Nva-Arg-Tyr-NH2]2"],
  },
  {
    primary_id: "D08YWF",
    names: ["BMS-986253"],
  },
  {
    primary_id: "D08YWK",
    names: ["FX-006"],
  },
  {
    primary_id: "D08YWN",
    names: ["HIV Vaccine"],
  },
  {
    primary_id: "D08YWY",
    names: ["SCH-900117"],
  },
  {
    primary_id: "D08YXP",
    names: ["ISIS 113020"],
  },
  {
    primary_id: "D08YXS",
    names: ["CAP-3.2"],
  },
  {
    primary_id: "D08YYV",
    names: ["Cyclo(-D-Tyr-D-Arg-L-MeArg-L-Nal-Gly-)", "CHEMBL376219"],
  },
  {
    primary_id: "D08YYW",
    names: ["PMID25399762-Compound-Table1-C15"],
  },
  {
    primary_id: "D08YYX",
    names: ["6-(2-Amino-Ethyl)-Biphenyl-2,3-Diol", "CHEMBL299511", "BDBM50025204"],
  },
  {
    primary_id: "D08YYY",
    names: [
      "Ocinaplon",
      "CL 273547",
      "Ocinaplon [USAN:INN]",
      "Ocinaplon (USAN/INN)",
      "Pyridin-2-Yl-(7-Pyridin-4-Ylpyrazolo[1,5-A]pyrimidin-3-Yl)Methanone",
      "Methanone, 2-Pyri(Dinyl(7-(4-Pyridinyl)Pyrazolo(1,5-A)Pyrimidin-3-Yl)",
      "2-Pyridinyl(7-(4-Pyridinyl)Pyrazolo(1,5-A)Pyrimidin-3-Yl)Methanone",
      "2-Pyridyl 7-(4-Pyridyl)Pyrazolo(1,5-A)Pyrimidin-3-Yl Ketone",
    ],
  },
  {
    primary_id: "D08YZM",
    names: [
      "4-(Piperazin-1-Yl)-7H-Pyrrolo[2,3-D]pyrimidine",
      "252722-52-4",
      "4-(Piperazin-1-Yl)-1H-Pyrrolo[2,3-D]pyrimidine",
      "4-(1-Piperazinyl)-1H-Pyrrolo[2,3-D]pyrimidine",
      "CHEMBL595554",
      "1-{7H-Pyrrolo[2,3-D]pyrimidin-4-Yl}piperazine",
      "6J9",
      "SCHEMBL2251760",
      "DTXSID20592472",
      "MolPort-028-913-269",
      "BDBM50304819",
      "AKOS022175220",
      "AKOS016374229",
      "MCULE-3871238079",
      "ACM252722524",
      "NS-02114",
      "DB-067363",
      "AX8159612",
      "KB-186079",
      "AJ-103002",
    ],
  },
  {
    primary_id: "D08ZAC",
    names: ["ICAR30 T Cells"],
  },
  {
    primary_id: "D08ZAK",
    names: ["L-366,682", "Cyclo(Pro-Trp-Ile-Pip-Pip-His)", "L 366682"],
  },
  {
    primary_id: "D08ZAO",
    names: ["Tricarballylic Acid"],
  },
  {
    primary_id: "D08ZAW",
    names: ["PMID29649907-Compound-12"],
  },
  {
    primary_id: "D08ZAX",
    names: [
      "RS-93522",
      "2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylic Acid Methyl 2-[4-(2,3-Dihydroxypropoxy)Phenyl]ethyl Diester",
      "2-[4-(2,3-Dihydroxypropoxy)Phenyl]ethyl 2,6-Dimethyl-3-(Methoxycarbonyl)-4-(3-Nitrophenyl)-1,4-Dihydropyridine-5-Carboxylate",
    ],
  },
  {
    primary_id: "D08ZBB",
    names: ["PMID25666693-Compound-84"],
  },
  {
    primary_id: "D08ZBD",
    names: [
      "9-Fluoro-5H-6-Thia-4,5-Diaza-Chrysene 6,6-Dioxide",
      "CHEMBL270799",
      "SCHEMBL11972977",
      "KNMYXLXOQAHLHK-UHFFFAOYSA-N",
      "BDBM50372499",
      "NCGC00163040-01",
    ],
  },
  {
    primary_id: "D08ZBT",
    names: ["SDZ-WAG-994"],
  },
  {
    primary_id: "D08ZBU",
    names: ["Cyanocobalamin Co-60"],
  },
  {
    primary_id: "D08ZBV",
    names: ["L-732138"],
  },
  {
    primary_id: "D08ZCC",
    names: ["MTC-896"],
  },
  {
    primary_id: "D08ZCI",
    names: ["ALN-AT3SC"],
  },
  {
    primary_id: "D08ZCM",
    names: [
      "5-Octyl-2-Phenoxyphenol",
      "5-OCTYL-2-PHENOXYPHENOL",
      "5-Octyl-2-Phenoxy-Phenol",
      "CHEMBL505886",
      "8PS",
      "8PP",
      "AC1NRXEO",
      "2-Phenoxy-5-Octylphenol",
      "5-Octyl-2-Phenoxy Phenol",
      "5-Heptyl-2-Phenoxylphenol",
      "SCHEMBL2873469",
      "PT05",
      "BDBM16298",
      "JOWYBLIPWAMIHM-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D08ZCW",
    names: ["ZD-8321"],
  },
  {
    primary_id: "D08ZDD",
    names: ["N-Hydroxy-4-(4-Methoxy-Phenyl)-4-Oxo-Butyramide", "CHEMBL366010"],
  },
  {
    primary_id: "D08ZDI",
    names: ["Anti-Factor D"],
  },
  {
    primary_id: "D08ZDM",
    names: ["Nh-125", "EEF-2K Inhibitor, UMDNJ", "Eukaryotic Elongation Factor-2 Kinase Inhibitor, UMDNJ"],
  },
  {
    primary_id: "D08ZDP",
    names: ["1-Benzhydryl-4-(4-Butylphenyl)Piperidin-4-Ol", "CHEMBL233287"],
  },
  {
    primary_id: "D08ZDR",
    names: ["PMID28870136-Compound-67"],
  },
  {
    primary_id: "D08ZEB",
    names: [
      "Chlorzoxazone",
      "Biomioran",
      "CLW",
      "Chloroxazone",
      "Chlorsoxazone",
      "Chlorzoxane",
      "Chlorzoxazon",
      "Chlorzoxazona",
      "Chlorzoxazonum",
      "Clorzoxazona",
      "Escoflex",
      "Klorzoxazon",
      "Mioran",
      "Miotran",
      "Myoflexin",
      "Myoflexine",
      "Neoflex",
      "Nyoflex",
      "Paraflex",
      "Parafon",
      "Pathorysin",
      "Relaxazone",
      "Remofleks",
      "Remular",
      "Solaxin",
      "Component Of Parafon Forte",
      "McNeil Brand Of Chlorzoxazone",
      "Ortho Brand Of Chlorzoxazone",
      "Parafon Forte",
      "Parafon Forte DSC",
      "Strifon Forte Dsc",
      "C 4397",
      "Chlorzoxazonum [INN-Latin]",
      "Clorzoxazona [INN-Spanish]",
      "EZE-DS",
      "Muscol (TN)",
      "Paraflex (TN)",
      "Parafon Forte (TN)",
      "Remular-S",
      "Usaf Ma-10",
      "Chlorzoxazone [INN:BAN:JAN]",
      "Chlorzoxazone (JAN/USP/INN)",
      "2-Hydroxy-5-Chlorobenzoxazole",
      "5-Chlorbenzoxazolin-2-On",
      "5-Chloro-1,3-Benzoxazol-2(3H)-One",
      "5-Chloro-2(3H)-Benzoxazolone",
      "5-Chloro-2-Benzoxazolinone",
      "5-Chloro-2-Benzoxazolol",
      "5-Chloro-2-Benzoxazolone",
      "5-Chloro-2-Hydroxybenzoxazole",
      "5-Chloro-3(H)-2-Benzoxazolone",
      "5-Chlorobenzoksazolinon-2",
      "5-Chlorobenzoksazolinon-2 [Polish]",
      "5-Chlorobenzoksazolon-2",
      "5-Chlorobenzoksazolon-2 [Polish]",
      "5-Chlorobenzoxazol-2-One",
      "5-Chlorobenzoxazolidone",
      "5-Chlorobenzoxazolone",
      "5-Chloro-1,3-Benzoxazol-2-Ol",
      "5-Chloro-3H-1,3-Benzoxazol-2-One",
      "5-Chlorobenzoxazolin-2-One",
    ],
  },
  {
    primary_id: "D08ZEM",
    names: ["Quinazoline Derivative 11"],
  },
  {
    primary_id: "D08ZFC",
    names: ["IGN-312"],
  },
  {
    primary_id: "D08ZFJ",
    names: ["Colestilan", "Cholebine", "Cholestilan", "Cholestimide", "Colestimide", "Colestilan Chloride", "MCI-196"],
  },
  {
    primary_id: "D08ZGG",
    names: [
      "P-218",
      "BT-1",
      "Dihydrofolate Reductase Inhibitors (Plasmodium Infection)",
      "Dihydrofolate Reductase Inhibitors (Plasmodium Infection), MMV",
    ],
  },
  {
    primary_id: "D08ZGW",
    names: [
      "2-Amino-2-(3-Chlorophenyl)Acetic Acid",
      "7292-71-9",
      "Amino-(3-Chloro-Phenyl)-Acetic Acid",
      "Amino(3-Chlorophenyl)Acetic Acid",
      "Dl-3-Chlorophenylglycine",
      "3-Chlorophenylglycine",
      "Amino-(3-Chlorophenyl)Acetic Acid",
      "DL-(3-Chlorophenyl)Glycine",
      "DL-2-(3-Chlorophenyl)Glycine",
      "CHEMBL204655",
      "Amino(3-Chloro-Phenyl)Acetic Acid",
      "(R)-2-(3-Chlorophenyl)Glycine",
      "NSC154922",
      "Benzeneacetic Acid, A-Amino-3-Chloro-, (AS)-",
      "PubChem20741",
      "ACMC-20en3l",
      "(3-Chlorophenyl)Glycine",
      "AC1L6EDK",
      "3-Chloro-DL-Phenylglycine",
      "AC1Q3M3E",
      "2-(3-Chlorophenyl)Glycine",
    ],
  },
  {
    primary_id: "D08ZHA",
    names: ["PMID27599163-Compound-78"],
  },
  {
    primary_id: "D08ZHL",
    names: ["FGF-1"],
  },
  {
    primary_id: "D08ZHQ",
    names: ["C4X-301"],
  },
  {
    primary_id: "D08ZHT",
    names: ["Diazaborines"],
  },
  {
    primary_id: "D08ZIA",
    names: ["2-Isooleanolic Acid", "CHEMBL1080965", "BDBM50310507", "2beta-Hydroxyoleana-12-Ene-28-Oic Acid"],
  },
  {
    primary_id: "D08ZIH",
    names: ["CGX-1160"],
  },
  {
    primary_id: "D08ZIR",
    names: ["FGGFTGARKSARKLFNQ"],
  },
  {
    primary_id: "D08ZIS",
    names: [
      "4-(2-Thienyl)Benzene-1,2-Diamine",
      "4-(Thiophen-2-Yl)Benzene-1,2-Diamine",
      "471239-63-1",
      "4-(2-Thienyl)-1,2-Benzenediamine",
      "CHEMBL495702",
      "SCHEMBL2873589",
      "XWTMGMKBRLLEFX-UHFFFAOYSA-N",
      "MolPort-042-605-637",
      "ZINC40977399",
      "AKOS033921192",
      "DA-05644",
      "FT-0738633",
    ],
  },
  {
    primary_id: "D08ZJJ",
    names: ["H1N1 Pandemic Influenza Vaccine"],
  },
  {
    primary_id: "D08ZJM",
    names: ["Isoamyl-Agomelatine"],
  },
  {
    primary_id: "D08ZJR",
    names: ["FGGFTGARKSARKWKNQ"],
  },
  {
    primary_id: "D08ZJX",
    names: ["LY3076226"],
  },
  {
    primary_id: "D08ZJZ",
    names: ["PMID28092474-Compound-32v"],
  },
  {
    primary_id: "D08ZKJ",
    names: ["Ontazolast"],
  },
  {
    primary_id: "D08ZKN",
    names: ["PG-530742"],
  },
  {
    primary_id: "D08ZLL",
    names: ["Quick-Acting Insulin"],
  },
  {
    primary_id: "D08ZLS",
    names: [
      "N-Benzyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL589078",
      "N-Benzyl-10H-Phenothiazine-10-Carboxamide",
      "ChemDiv3_004499",
      "AC1LE3H3",
      "Oprea1_734190",
      "ZINC91218",
      "MolPort-000-807-945",
      "HMS1485M11",
      "N-Benzylphenothiazine-10-Carboxamide",
      "STK825822",
      "BDBM50308407",
      "N-Benzylphenothiazin-10-Ylcarboxamide",
      "AKOS001676383",
      "MCULE-7023337069",
      "CCG-113838",
      "IDI1_022409",
      "ST51040739",
      "N-Benzyl-1''H-Phenothiazine-1''-Carboxamide",
      "AB00121381-01",
      "SR-01000506160",
      "SR-01000506160-1",
      "BRD-K82322069-001-01-0",
    ],
  },
  {
    primary_id: "D08ZMX",
    names: ["FR239087", "AC1NRB3Z"],
  },
  {
    primary_id: "D08ZNS",
    names: ["DIGITOXIGENIN"],
  },
  {
    primary_id: "D08ZOV",
    names: ["Methyl 7-Methoxy-4-Phenylbenzofuran-2-Ylcarbamate", "CHEMBL598251"],
  },
  {
    primary_id: "D08ZOY",
    names: ["Tecogalan Sodium"],
  },
  {
    primary_id: "D08ZPO",
    names: [
      "H-1152",
      "451462-58-1",
      "(S)-2-METHYL-1-[(4-METHYL-5-ISOQUINOLINE)SULFONYL]-HOMOPIPERAZINE",
      "CHEBI:88220",
      "H-1152P",
      "(S)-H-1152 (Hydrochloride)",
      "H52",
      "Dimethyl Fasudil",
      "4-Methyl-5-{[(2S)-2-Methyl-1,4-Diazepan-1-Yl]sulfonyl}isoquinoline",
      "4-Methyl-5-[[(2S)-2-Methyl-1,4-Diazepan-1-Yl]sulfonyl]isoquinoline",
      "2gnh",
      "2gnl",
      "1q8u",
      "AC1L9LJA",
      "H 1152",
      "SCHEMBL124161",
      "CHEMBL406821",
      "ZINC22706",
      "BDBM14028",
      "BCP16935",
      "3714AH",
      "AKOS027263830",
      "DB07876",
      "CS-1536",
      "HY-15720",
      "LS-193100",
      "W-5343",
      "4-Methyl-5-[(2S)-2-Methyl-1,4-Diazepane-1-Sulfonyl]isoquinoline",
    ],
  },
  {
    primary_id: "D08ZPX",
    names: ["Dicarba-Closo-Dodecaborane Analogue"],
  },
  {
    primary_id: "D08ZQI",
    names: ["FPA-008"],
  },
  {
    primary_id: "D08ZQV",
    names: [
      "Cytidine-5'-Triphosphate",
      "CTP Trianion",
      "Cytidine 5'-Triphosphate(3-)",
      "CHEBI:58231",
      "CTP(3-)",
      "Cytidine 5'-Triphosphate Trianion",
    ],
  },
  {
    primary_id: "D08ZRA",
    names: [
      "C-Naphthalen-1-Yl-Methylamine",
      "1-Naphthalenemethylamine",
      "118-31-0",
      "Naphthalen-1-Ylmethanamine",
      "1-Naphthylmethylamine",
      "1-NAPHTHALENEMETHANAMINE",
      "1-(Aminomethyl)Naphthalene",
      "1-Naphthalenemethyl Amine",
      "1-Naphthylmethanamine",
      "(1-Naphthylmethyl)Amine Hydrochloride",
      "CHEMBL128633",
      "1-AMINOMETHYLNAPHTHALENE",
      "Naphthylmethylamine",
      "NVSYANRBXPURRQ-UHFFFAOYSA-N",
      "MFCD00004048",
      "1-Naphthalenemethylamine, 97%",
      "Naphthalene-1-Methylamine",
      "Naphtalenemethylamine",
      "EINECS 204-244-0",
      "Naphth-1-Ylmethylamine",
      "1-Naphthylmethyl-Amine",
    ],
  },
  {
    primary_id: "D08ZRC",
    names: ["AcPYY(25-36)"],
  },
  {
    primary_id: "D08ZRN",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 27"],
  },
  {
    primary_id: "D08ZRP",
    names: ["NSFQ-105", "154269-12-2", "NSFQ-104"],
  },
  {
    primary_id: "D08ZRR",
    names: ["KW-5092"],
  },
  {
    primary_id: "D08ZRW",
    names: [
      "Cantide + Cisplatin",
      "Cis-Diaminedichloroplatinum",
      "CPDC",
      "Cis-Diamminedichlorplatine",
      "Cis-Dichlorodiamine Platinum",
      "Diamminedichloroplatinum",
      "Platinum Diamine Dichloride",
      "Platinum, Diamminedichloro-",
      "Trans-Diaminedichloroplatinum",
      "NCGC00090759-01",
      "DSSTox_CID_4983",
      "DSSTox_RID_77611",
      "DSSTox_GSID_24983",
      "CAS-15663-27-1",
      "Platiblastin",
      "Cisplatin Complex",
      "DDPt",
      "Cis-Platinum(II)",
      "Pt(II) Complex",
      "Cis Pt II",
      "CPDD",
      "Dichloroplatinumdiamine",
      "Platinum(IV) Complex",
      "Platinum (II) Complex",
      "Neuro_000055",
      "Cis-Platinum Diamminedichloride",
      "DTXSID4024983",
      "MolPort-003-983-464",
    ],
  },
  {
    primary_id: "D08ZSQ",
    names: ["Parke-Davis 1"],
  },
  {
    primary_id: "D08ZTA",
    names: ["6-Dimethylamino-2-Methyl-Hex-4-Ynal Oxime"],
  },
  {
    primary_id: "D08ZTH",
    names: ["SLP-1002"],
  },
  {
    primary_id: "D08ZTK",
    names: ["Ro 10-4548"],
  },
  {
    primary_id: "D08ZTZ",
    names: [
      "3-(5-Chloro-6-Methoxy-Naphthalen-2-Yl)-Pyridine",
      "CHEMBL197576",
      "BDBM8910",
      "SCHEMBL4509209",
      "Pyridine-Substituted Naphthalene 9",
      "ZINC13674461",
      "3-(5-Chloro-6-Methoxy-2-Naphthyl)Pyridine",
    ],
  },
  {
    primary_id: "D08ZUC",
    names: ["TRK-950"],
  },
  {
    primary_id: "D08ZUG",
    names: ["RBT-05"],
  },
  {
    primary_id: "D08ZVM",
    names: [
      "2-(4-Chlorophenyl)-5-Quinoxalinecarboxamide",
      "3-(4-CHLOROPHENYL)QUINOXALINE-5-CARBOXAMIDE",
      "2-(4-CHLOROPHENYL)-5-QUINOXALINECARBOXAMIDE",
      "4tju",
      "1wok",
      "AC1LCVX7",
      "Quinoxaline Analogue, 3b",
      "SCHEMBL424209",
      "3-(4-Chloro-Phenyl)-Quinoxaline-5-Carboxylic Acid Amide",
      "CTK8F4675",
      "BDBM27720",
      "ZINC1489510",
      "DB03509",
      "489457-67-2",
    ],
  },
  {
    primary_id: "D08ZVO",
    names: ["IRX-2 Immunotherapy"],
  },
  {
    primary_id: "D08ZVY",
    names: ["Technetium Tc-99m Lidofenin Kit"],
  },
  {
    primary_id: "D08ZWA",
    names: ["ISIS 114232"],
  },
  {
    primary_id: "D08ZWJ",
    names: [
      "9-Bromo-5,11-Dimethyl-6H-Pyrido[4,3-B]carbazole",
      "9-Bromoellipticine",
      "Ellipticine, 9-Bromo-",
      "18073-34-2",
      "NSC 98927",
      "BRN 0543507",
      "CHEMBL194474",
      "NSC98927",
      "9-Bromo-5,11-Dimethyl-6H-Pyrido(4,3-B)Carbazole",
      "6H-Pyrido(4,3-B)Carbazole, 9-Bromo-5,11-Dimethyl-",
      "AC1L3XSF",
      "5-23-09-00418 (Beilstein Handbook Reference)",
      "DTXSID80171005",
      "ZINC13284221",
      "NSC-98927",
      "BDBM50174409",
      "ACM18073342",
      "6H-Pyrido[4, 9-Bromo-5,11-Dimethyl-",
    ],
  },
  {
    primary_id: "D08ZWM",
    names: ["PSILOCIN"],
  },
  {
    primary_id: "D08ZXG",
    names: ["INT-2500"],
  },
  {
    primary_id: "D08ZXH",
    names: ["AX-208"],
  },
  {
    primary_id: "D08ZXM",
    names: ["REG2"],
  },
  {
    primary_id: "D08ZXP",
    names: ["Dengue"],
  },
  {
    primary_id: "D08ZXQ",
    names: ["Neublastin"],
  },
  {
    primary_id: "D08ZYC",
    names: ["GW9508"],
  },
  {
    primary_id: "D08ZYZ",
    names: ["Chikungunya Vaccine"],
  },
  {
    primary_id: "D08ZZJ",
    names: ["R-87027"],
  },
  {
    primary_id: "D08ZZO",
    names: ["NS-696"],
  },
  {
    primary_id: "D08ZZP",
    names: ["EchoGen", "DZ-2600", "QW-3600"],
  },
  {
    primary_id: "D08ZZX",
    names: [
      "4-(3-Methoxy-Phenyl)-Isoxazolidine-3,5-Dione",
      "3,5-Isoxazolidinedione, 4-(3-Methoxyphenyl)-",
      "CHEMBL165286",
    ],
  },
  {
    primary_id: "D0939G",
    names: ["Quisinostat"],
  },
  {
    primary_id: "D09AAB",
    names: ["Imidazolyl Urea Derivative 1"],
  },
  {
    primary_id: "D09AAK",
    names: ["DCC-2618"],
  },
  {
    primary_id: "D09AAM",
    names: ["ICI-164384"],
  },
  {
    primary_id: "D09AAZ",
    names: [
      "PTAC",
      "CHEMBL146240",
      "139148-95-1",
      "N-Hydroxy-N-(1,2,3,4-Tetrahydro-6-(Phenylmethoxy)-1-Naphthalenyl)Urea",
      "F 107649",
      "F-107649",
      "AC1L53EW",
      "SCHEMBL9488819",
      "CTK4C1649",
      "BDBM50055115",
      "6-(Benzyloxy)-1-(1-Hydroxyureido)Tetralin",
      "N-Hydroxy-N-[1,2,3,4-Tetrahydro-6-(Benzyloxy)-1-Naphthalenyl]urea",
      "1-Hydroxy-1-(6-Phenylmethoxy-1,2,3,4-Tetrahydronaphthalen-1-Yl)Urea",
    ],
  },
  {
    primary_id: "D09ABM",
    names: [
      "5-PENTYL-2-PHENOXYPHENOL",
      "5-Pentyl-2-Phenoxy-Phenol",
      "5PP",
      "AC1NRXEL",
      "5-Pentyl-2-Phenoxylphenol",
      "SCHEMBL2873530",
      "PT03",
      "CHEMBL1224745",
      "BDBM16296",
      "AIDS227445",
    ],
  },
  {
    primary_id: "D09ACC",
    names: ["CNTO-0007", "42037788"],
  },
  {
    primary_id: "D09ACO",
    names: [
      "(2E,4S)-4-Ammoniopent-2-Enoate",
      "CHEMBL31541",
      "SCHEMBL3063088",
      "(4S)-4-Amino-2-Pentenoic Acid",
      "[S,E,(?)]-4-Amino-2-Pentenoic Acid",
    ],
  },
  {
    primary_id: "D09ACU",
    names: ["PMID27977313-Compound-8"],
  },
  {
    primary_id: "D09ADN",
    names: ["VOXERGOLIDE HYDROCHLORIDE"],
  },
  {
    primary_id: "D09ADS",
    names: ["Immuncell-LC"],
  },
  {
    primary_id: "D09ADT",
    names: [
      "ABT-418",
      "Abt-418",
      "Abt 418",
      "147402-53-7",
      "3-Methyl-5-(1-Methyl-2-Pyrrolidinyl)Isoxazole",
      "CHEMBL274525",
      "(S)-3-Methyl-5-(1-Methyl-2-Pyrrolidinyl)Isoxazole",
      "(S)-3-Methyl-5-(1-Methylpyrrolidin-2-Yl)Isoxazole",
      "C9H14N2O",
      "Isoxazole, 3-Methyl-5-((2S)-1-Methyl-2-Pyrrolidinyl)-",
      "Isoxazole, 3-Methyl-5-(1-Methyl-2-Pyrrolidinyl)-, (S)-",
      "Isoxazole, 3-Methyl-5-[(2S)-1-Methyl-2-Pyrrolidinyl]-",
      "3-Methyl-5-[(2S)-1-Methylpyrrolidin-2-Yl]-1,2-Oxazole",
      "AC1L3OPH",
      "SCHEMBL194161",
      "DTXSID10163711",
      "ILLGYRJAYAAAEW-QMMMGPOBSA-N",
      "ZINC3786099",
      "BDBM50035398",
    ],
  },
  {
    primary_id: "D09ADW",
    names: ["PMID21802293C66"],
  },
  {
    primary_id: "D09AED",
    names: [
      "2-Benzylisoquinoline-1,3,4-Trione",
      "21640-35-7",
      "2-BENZYL-ISOQUINOLINE-1,3,4-TRIONE",
      "CHEMBL382859",
      "SCHEMBL13442853",
      "Isoquinoline-1,3,4-Trione 2c",
      "BDBM10250",
      "CTK4E7388",
      "DTXSID80463477",
      "ZINC13683129",
      "AKOS015966149",
      "FCH2821819",
      "ACM21640357",
      "2-Benzylisoquinoline-1,3,4(2H)-Trione",
      "2-Benzylisoquinoline-1,3,4(2H,4H)-Trione",
      "1,3,4(2H)-Isoquinolinetrione,2-(Phenylmethyl)-",
    ],
  },
  {
    primary_id: "D09AEV",
    names: ["Neo-Kauluamine"],
  },
  {
    primary_id: "D09AFE",
    names: ["AG-NDP803"],
  },
  {
    primary_id: "D09AFI",
    names: ["INCB039110"],
  },
  {
    primary_id: "D09AFJ",
    names: ["SR-121787"],
  },
  {
    primary_id: "D09AFM",
    names: ["AMI-1", "AMI1", "AMI 1"],
  },
  {
    primary_id: "D09AFV",
    names: ["GLG-101"],
  },
  {
    primary_id: "D09AGG",
    names: ["GSK2890457"],
  },
  {
    primary_id: "D09AGZ",
    names: ["CEE-04-421"],
  },
  {
    primary_id: "D09AHK",
    names: ["ZYN002-CBD Gel"],
  },
  {
    primary_id: "D09AHL",
    names: ["N-Pyridoxyl-Glycine-5-Monophosphate"],
  },
  {
    primary_id: "D09AHN",
    names: [
      "N-(Tert-Butoxycarbonyl)-Leucyl-Glycine-Nitrile",
      "Dipeptide-Derived Nitrile, 7",
      "CHEMBL200160",
      "SCHEMBL6257676",
      "BDBM20087",
      "Tert-Butyl N-[(1S)-1-[(Cyanomethyl)Carbamoyl]-3-Methylbutyl]carbamate",
    ],
  },
  {
    primary_id: "D09AHZ",
    names: [
      "Celivarone",
      "UNII-K45001587E",
      "401925-43-7",
      "K45001587E",
      "Celivarone [INN]",
      "SSR 149744",
      "SCHEMBL3251183",
      "CHEMBL3707403",
      "SSR-149744C",
      "SB17436",
      "SSR-149744",
      "5-Benzofurancarboxylic Acid, 2-Butyl-3-(4-(3-(Dibutylamino)Propyl)Benzoyl)-, 1-Methylethyl Ester",
    ],
  },
  {
    primary_id: "D09AID",
    names: [
      "PMID22564207C25b",
      "CEP-28122",
      "1022958-60-6",
      "GTPL8139",
      "EX-A1541",
      "ZINC84654307",
      "BDBM50389154",
      "CS-5176",
      "NCGC00387821-03",
      "HY-18030",
    ],
  },
  {
    primary_id: "D09AIH",
    names: ["PCUR-101"],
  },
  {
    primary_id: "D09AIJ",
    names: ["ISIS 13740"],
  },
  {
    primary_id: "D09AIT",
    names: ["BHT-3201"],
  },
  {
    primary_id: "D09AJU",
    names: ["TS-111"],
  },
  {
    primary_id: "D09AKT",
    names: ["Imidazo[1,2-B]pyridazine Acetamide Derivative 6"],
  },
  {
    primary_id: "D09AKX",
    names: [
      "4-(4-M-Tolylamino-Phthalazin-1-Yl)-Benzamide",
      "CHEMBL196025",
      "AC1LR1ZB",
      "Oprea1_451204",
      "MolPort-002-560-372",
      "MolPort-000-703-859",
      "ZINC1297754",
      "BDBM50173029",
      "STK846512",
      "AKOS030511153",
      "AKOS005626717",
      "MCULE-6513504515",
      "4-(4-(M-Tolylamino)Phthalazin-1-Yl)Benzamide",
      "AB00109646-01",
      "4-[4-(3-Methylanilino)Phthalazin-1-Yl]benzamide",
    ],
  },
  {
    primary_id: "D09ALE",
    names: ["Enadoline"],
  },
  {
    primary_id: "D09ALF",
    names: ["Aminotriazolopyridine Derivative 1"],
  },
  {
    primary_id: "D09ALJ",
    names: ["Rutin"],
  },
  {
    primary_id: "D09ALM",
    names: ["BIIB061"],
  },
  {
    primary_id: "D09ALR",
    names: ["MM36", "CHEMBL225555"],
  },
  {
    primary_id: "D09AMZ",
    names: ["Enprofylline"],
  },
  {
    primary_id: "D09ANC",
    names: ["MC-02,479"],
  },
  {
    primary_id: "D09ANG",
    names: [
      "Misoprostol",
      "Cytotec",
      "GyMiso",
      "Isprelor",
      "Misopess",
      "Misoprostolum",
      "Misotol",
      "SC 29333",
      "Cytotec (TN)",
      "Misoprostolum [INN-Latin]",
      "SC-29333",
      "XP-16J",
      "Misoprostol (JAN/USAN/INN)",
      "Methyl (11alpha,13E)-11,16-Dihydroxy-16-Methyl-9-Oxoprost-13-En-1-Oate",
      "Methyl (+-)-11-Alpha,16-Dihydroxy-16-Methyl-9-Oxoprost-13-En-1-Oate",
      "Methyl 7-[(1R,2R,3R)-3-Hydroxy-2-[(E)-4-Hydroxy-4-Methyloct-1-Enyl]-5-Oxocyclopentyl]heptanoate",
      "(+/-)-15-Deoxy-(16RS)-16-Hydroxy-16-Methylprostaglandin E1 Methyl Ester",
      "(11-Alpha,13E)-(+-)-11,16-Dihydroxy-16-Methyl-9-Oxoprost-13-En-1-Oic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D09AOO",
    names: ["ANAVEX 2-73"],
  },
  {
    primary_id: "D09APA",
    names: ["Plasma Derived Factor VIII PEGylated Liposomal"],
  },
  {
    primary_id: "D09APB",
    names: ["IDX-189"],
  },
  {
    primary_id: "D09AQJ",
    names: ["D-203", "CHEMBL468022", "BDBM50261280"],
  },
  {
    primary_id: "D09AQK",
    names: [
      "(R,S)-4-Phosphonophenylglycine",
      "(RS)-PPG",
      "120667-15-4",
      "2-Amino-2-(4-Phosphonophenyl)Acetic Acid",
      "CHEMBL277475",
      "AC1ND7MS",
      "(RS)PPG",
      "(RS) PPG",
      "C8H10NO5P",
      "(RS)-4-Phosphonophenylglycine",
      "GTPL1406",
      "SCHEMBL9150881",
      "CTK8F0597",
      "(R,S)-4-PPG",
      "MolPort-003-983-668",
      "BN0429",
      "BDBM50004881",
      "AKOS015911887",
      "Amino-(4-Phosphono-Phenyl)-Acetic Acid",
      "NCGC00025051-02",
      "RT-015313",
      "V2034",
      "Amino-(4-Phosphono-Phenyl)-Acetic Acid(RS-PPG)",
      "SR-01000597406",
      "667P154",
      "L000025",
      "SR-01000597406-1",
      "J-004376",
    ],
  },
  {
    primary_id: "D09AQZ",
    names: ["PMID29334795-Compound-57"],
  },
  {
    primary_id: "D09ATD",
    names: ["PMID29130358-Compound-Figure17(10)"],
  },
  {
    primary_id: "D09ATQ",
    names: ["PMID19191557C19"],
  },
  {
    primary_id: "D09ATR",
    names: ["PF-06804103"],
  },
  {
    primary_id: "D09AUL",
    names: [
      "Benzyl-(2-Imidazol-1-Yl-Quinazolin-4-Yl)-Amine",
      "CHEMBL118944",
      "SCHEMBL7280806",
      "FNTPEBIGZTYDQK-UHFFFAOYSA-N",
      "BDBM50033290",
      "4-Phenylmethylamino-2-(1-Imidazolyl)Quinazoline",
    ],
  },
  {
    primary_id: "D09AUP",
    names: ["GW-215864"],
  },
  {
    primary_id: "D09AUU",
    names: ["Dimemorfan", "Dimemorfan (INN)", "36309-01-0", "SCHEMBL509122"],
  },
  {
    primary_id: "D09AVG",
    names: [
      "5-Amino-3-(N-Methylpiperidin-4-Yl)-1H-Indole",
      "3-(1-Methyl-4-Piperidyl)Indole-5-Ylamine",
      "CHEMBL521235",
      "3-(1-Methylpiperidin-4-Yl)-1h-Indol-5-Amine",
      "SCHEMBL427406",
      "CTK6I2276",
      "CKBJZDHVHHKARV-UHFFFAOYSA-N",
      "BDBM50271135",
      "AKOS024098950",
      "182564-38-1",
      "5-Amino-3-(1-Methylpiperidin-4-Yl)-1H-Indole",
      "3-(1-Methyl-4-Piperidinyl)-5-Amino-1H-Indole",
    ],
  },
  {
    primary_id: "D09AVL",
    names: ["4-Benzyl-5-(4-Piperidyl)Isothiazol-3-Ol", "CHEMBL203718"],
  },
  {
    primary_id: "D09AVU",
    names: ["PMID27788040-Compound-6b"],
  },
  {
    primary_id: "D09AVX",
    names: ["WIB-901H"],
  },
  {
    primary_id: "D09AVZ",
    names: ["E1/E4-Deleted Adenoviral Vector"],
  },
  {
    primary_id: "D09AWB",
    names: ["[123I]iodobenzovesamicol", "[123I]-Iodobenzovesamicol", "[123I]-IBVM"],
  },
  {
    primary_id: "D09AWJ",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D09AWM",
    names: [
      "ODSH",
      "Cardiac Ischemia Reperfusion Injury Therapy, ParinGenix",
      "ODSH (Iv)",
      "PGX-100",
      "PGX-200",
      "ODSH (Inhaled Formulation), ParinGenix",
      "ODSH (Iv), ParinGenix",
      "2-O, 3-O Desulfated Heparin (Inhaled), ParinGenix",
      "2-O, 3-O Heparin (Intravenous Formulation), ParinGenix",
    ],
  },
  {
    primary_id: "D09AXB",
    names: [
      "SB-201076",
      "CHEMBL324097",
      "154566-05-9",
      "SCHEMBL2622309",
      "ZINC13781097",
      "BDBM50066692",
      "KB-145947",
      "(S)-2-((S)-8-(2,4-Dichlorophenyl)-2-Hydroxyoctyl)-2-Hydroxysuccinic Acid",
      "(S)-2-[(S)-8-(2,4-Dichloro-Phenyl)-2-Hydroxy-Octyl]-2-Hydroxy-Succinic Acid",
    ],
  },
  {
    primary_id: "D09AXQ",
    names: ["CD22-CART"],
  },
  {
    primary_id: "D09AXR",
    names: ["Triazole Derivative 3"],
  },
  {
    primary_id: "D09AXV",
    names: [
      "Dengue Virus Methyltransferase Inhibitors",
      "Dengue Virus Methyltransferase Inhibitors, Novartis Institute For Tropical Diseases",
      "NS5 RNA Methytransferase Inhibitors (Dengue Virus Infection), NITD",
      "S-Adenosylhomocysteine Derivatives (Dengue Virus Infection), Novartis Institute For Tropical Diseases",
    ],
  },
  {
    primary_id: "D09AYA",
    names: ["IB-08C175"],
  },
  {
    primary_id: "D09AYK",
    names: ["3-Ethoxycarbonyl-4-Quinolone"],
  },
  {
    primary_id: "D09AYM",
    names: ["MABp1"],
  },
  {
    primary_id: "D09AYQ",
    names: ["PIRITREXIM"],
  },
  {
    primary_id: "D09AYR",
    names: [
      "3,5-Dinitrocatechol",
      "3,5-Dinitrobenzene-1,2-Diol",
      "7659-29-2",
      "3,5-Dinitro-1,2-Benzenediol",
      "OR-486",
      "3,5-Dinitropyrocatechol",
      "OR486",
      "UNII-VK0VA22GY2",
      "Pyrocatechol, 3,5-Dinitro-",
      "1,2-Benzenediol, 3,5-Dinitro-",
      "BRN 2217246",
      "VK0VA22GY2",
      "3,5-DINITROCATECHOL (OR-486)",
      "CHEMBL168276",
      "3,5-DINITRO-BENZENE-1,2-DIOL",
      "DNC",
      "SR-01000075610",
      "OR 486",
      "Ro 12812",
      "Ro 01-2812",
      "Tocris-0483",
      "Spectrum_000187",
      "Lopac-D-131",
      "AC1MZC4M",
      "Spectrum4_000747",
      "Spectrum2_001109",
      "Spectrum5_001163",
      "Entacapone EP Impurity E",
      "3,5-Dinitrocatechol, Solid",
      "Lopac0_000477",
    ],
  },
  {
    primary_id: "D09AYS",
    names: ["Antineoplaston-A5", "Antineoplaston-A5, Burzynski Institute"],
  },
  {
    primary_id: "D09AZM",
    names: ["TEI-9063"],
  },
  {
    primary_id: "D09AZO",
    names: ["4-(3-Methylpyridin-2-Yl)-N-P-Tolylbenzamide", "CHEMBL215015"],
  },
  {
    primary_id: "D09BAP",
    names: ["KUR-212"],
  },
  {
    primary_id: "D09BBA",
    names: ["TAK-071"],
  },
  {
    primary_id: "D09BBW",
    names: ["Sodium Stibogluconate"],
  },
  {
    primary_id: "D09BCC",
    names: ["ImmuDyn"],
  },
  {
    primary_id: "D09BCJ",
    names: ["PMID30124346-Compound-34TABLE4"],
  },
  {
    primary_id: "D09BCL",
    names: ["DCB-AD1"],
  },
  {
    primary_id: "D09BDD",
    names: ["1,2,4-Triazole [4,3-A]quinoxaline Derivative 1"],
  },
  {
    primary_id: "D09BDE",
    names: ["PAC-14028"],
  },
  {
    primary_id: "D09BDH",
    names: ["Rabeximod"],
  },
  {
    primary_id: "D09BDK",
    names: ["SNG-163"],
  },
  {
    primary_id: "D09BEG",
    names: [
      "CNS-5161",
      "CNS5161",
      "2-(2-Chloro-5-Methylsulfanylphenyl)-1-Methyl-1-(3-Methylsulfanylphenyl)Guanidine",
      "2-[2-Chloro-5-(Methylsulfanyl)Phenyl]-1-Methyl-1-[3-(Methylsulfanyl)Phenyl]guanidine",
    ],
  },
  {
    primary_id: "D09BEJ",
    names: ["AQST-305"],
  },
  {
    primary_id: "D09BEO",
    names: ["DBP-192"],
  },
  {
    primary_id: "D09BEW",
    names: ["N-Methyl-4-Pentyl-N-(Pyridin-3-Yl)Benzamide", "CHEMBL259101"],
  },
  {
    primary_id: "D09BGB",
    names: ["Aryl Mannoside Derivative 1"],
  },
  {
    primary_id: "D09BGI",
    names: ["JCF 109"],
  },
  {
    primary_id: "D09BGX",
    names: ["Thiamine Monophosphate"],
  },
  {
    primary_id: "D09BHA",
    names: ["LY-353433"],
  },
  {
    primary_id: "D09BHB",
    names: ["Tolmetin"],
  },
  {
    primary_id: "D09BHF",
    names: ["WF-2421"],
  },
  {
    primary_id: "D09BID",
    names: ["PRO-206"],
  },
  {
    primary_id: "D09BIH",
    names: ["ISIS 100935"],
  },
  {
    primary_id: "D09BIV",
    names: [
      "TRACIZOLINE",
      "65295-26-3",
      "AC1O5AS5",
      "2-[(E)-2-Phenylethenyl]-4,5-Dihydro-1H-Imidazole",
      "AKOS030531161",
      "1H-Imidazole, 4,5-Dihydro-2-(2-Phenylethenyl)-, (R-(R*,R*))-2,3-Dihydroxybutanedioate (1:1)",
    ],
  },
  {
    primary_id: "D09BIW",
    names: ["99mTc-Sestamibi"],
  },
  {
    primary_id: "D09BIX",
    names: ["Nexagon"],
  },
  {
    primary_id: "D09BJH",
    names: ["Retroviral MGMT-Transduced Hematopoietic Cells"],
  },
  {
    primary_id: "D09BJN",
    names: [
      "(+/-)-Threo-3',4'-Dichloromethylphenidate Amide",
      "CHEMBL1253379",
      "BDBM50327177",
      "(+/-)-Threo-3'',4''-Dichloromethylphenidate Amide",
    ],
  },
  {
    primary_id: "D09BJW",
    names: ["9-Methoxy-1,2,3,4-Tetrahydro-Anthracen-2-Ylamine", "CHEMBL64303", "SCHEMBL8406908"],
  },
  {
    primary_id: "D09BKE",
    names: ["NS398"],
  },
  {
    primary_id: "D09BKM",
    names: [
      "Alkylbenzene Sulfonate",
      "4-Dodecan-6-Ylbenzenesulfonic Acid",
      "AC1Q6WLV",
      "AC1L28EL",
      "6-(P-Sulfophenyl) Dodecane",
      "SCHEMBL6128184",
      "GTPL2362",
      "DTXSID30860093",
      "4-(Dodecan-6-Yl)Benzenesulfonic Acid",
      "4-(1-Hexylhexyl)Benzenesulfonic Acid",
      "4-(Dodecan-6-Yl)Benzene-1-Sulfonic Acid",
    ],
  },
  {
    primary_id: "D09BKS",
    names: ["ARPFAQK-FAM", "CHEMBL441156"],
  },
  {
    primary_id: "D09BKV",
    names: ["3-Propyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL186969", "SCHEMBL7675949"],
  },
  {
    primary_id: "D09BKX",
    names: ["[3H]L-Aspartic Acid", "[3H]-L-Aspartate", "[3H]-L-Aspartic Acid"],
  },
  {
    primary_id: "D09BKY",
    names: ["Bevacizumab + Erlotinib"],
  },
  {
    primary_id: "D09BLB",
    names: ["Methylcarbamyl PAF"],
  },
  {
    primary_id: "D09BLW",
    names: ["TgAAV-CFTR"],
  },
  {
    primary_id: "D09BNP",
    names: ["Heterocyclic Derivative 11"],
  },
  {
    primary_id: "D09BNR",
    names: ["Gazyva"],
  },
  {
    primary_id: "D09BNS",
    names: ["5-Hydroxy-L-Tryptophan", "5-Hydroxytryptophan"],
  },
  {
    primary_id: "D09BNZ",
    names: ["LDE225"],
  },
  {
    primary_id: "D09BOH",
    names: [
      "Verubulin",
      "Azixa",
      "Verubulin Hydrochloride",
      "EP-128495",
      "MPC-6827",
      "Apoptosis Inducer (Cancer), Myriad",
      "Caspase Inducer (Cancer), Myriad",
      "Cell Cycle Arrest Inducer (Cancer), Myriad",
      "Tubulin Inhibitor (Cancer), Myriad",
    ],
  },
  {
    primary_id: "D09BOL",
    names: ["PMID26394986-Compound-50"],
  },
  {
    primary_id: "D09BPC",
    names: ["N5-(1-Imino-3-Butenyl)-L-Ornithine", "1-{[(4S)-4-Amino-4-Carboxybutyl]amino}butan-1-Iminium"],
  },
  {
    primary_id: "D09BPN",
    names: [
      "Anatibant",
      "Anatibant [INN]",
      "(2S)-N-(3-(4-Carbamimidoylbenzamido)Propyl)-1-(2,4-Dichloro-3-((2,4-Dimethyl-8-Quinolyloxy)Methyl)Phenylsulfonyl)Pyrrolidine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D09BPT",
    names: [
      "Anti-CD9 Mab",
      "ALB-6",
      "Anti-CD9 MAb (Gastric Cancer)",
      "Anti-CD9 MAb (Gastric Cancer), Osaka University",
    ],
  },
  {
    primary_id: "D09BQO",
    names: ["ATD Transdermal Gel"],
  },
  {
    primary_id: "D09BQY",
    names: ["(E)-N-(6-(Prop-1-Enyl)-1H-Indazol-3-Yl)Butyramide", "CHEMBL1086176"],
  },
  {
    primary_id: "D09BRE",
    names: ["U-78517F"],
  },
  {
    primary_id: "D09BSE",
    names: ["3-Bromoboldine", "CHEMBL225853", "BDBM50202340"],
  },
  {
    primary_id: "D09BSI",
    names: ["TDI-0039"],
  },
  {
    primary_id: "D09BTH",
    names: ["GSK2696266"],
  },
  {
    primary_id: "D09BTK",
    names: ["NCX-1000"],
  },
  {
    primary_id: "D09BUG",
    names: [
      "Aloe-Emodin",
      "Aloe Emodin",
      "481-72-1",
      "Aloeemodin",
      "Aloe-Emodine",
      "Rhabarberone",
      "EMODINE",
      "3-Hydroxymethylchrysazin",
      "1,8-Dihydroxy-3-(Hydroxymethyl)Anthracene-9,10-Dione",
      "3-Hydroxymethylchrysazine",
      "1,8-Dihydroxy-3-Hydroxymethylanthraquinone",
      "1,8-Dihydroxy-3-(Hydroxymethyl)Anthraquinone",
      "NSC 38628",
      "1,8-Dihydroxy-3-(Hydroxymethyl)-9,10-Anthracenedione",
      "UNII-C8IYT9CR7C",
      "9,10-Anthracenedione, 1,8-Dihydroxy-3-(Hydroxymethyl)-",
      "CCRIS 3526",
      "EINECS 207-571-7",
      "C8IYT9CR7C",
      "3-(Hydroxymethyl)Chrysazin",
      "BRN 2059062",
      "CHEMBL40275",
      "CHEBI:2607",
    ],
  },
  {
    primary_id: "D09BUM",
    names: ["4-(4-Cyclohexylthiosemicarbazono)Methyl-Phenol"],
  },
  {
    primary_id: "D09BVA",
    names: [
      "3-Acetyl-7-Hydroxy-2H-Chromen-2-One",
      "10441-27-7",
      "3-Acetyl-7-Hydroxy-Chromen-2-One",
      "3-Acetyl-Umbelliferone",
      "3-Acetylumbelliferone",
      "CHEMBL153064",
      "2H-1-Benzopyran-2-One, 3-Acetyl-7-Hydroxy-",
      "BRQZHMHHZLRXOO-UHFFFAOYSA-N",
      "3-Acetyl-7-Hydroxy-2-Oxochromene",
      "3-Acetyl-7-Hydroxychromen-2-One",
      "3-Acetyl-7-Hydroxy-1H-Chromen-2-One",
      "3-Acethyl-Umbelliferone",
      "AC1NU0NR",
      "3-Acetyl-7-Hydroxycoumarin",
      "CBDivE_005922",
      "MLS000777662",
      "SCHEMBL592707",
      "ZINC47516",
      "Cid_5392139",
      "ASIS-0097",
      "CTK4A3026",
      "DTXSID20419817",
      "MolPort-000-820-976",
      "BB_NC-01216",
      "STK365153",
    ],
  },
  {
    primary_id: "D09BVH",
    names: ["Naphthalene-1,4-Diol"],
  },
  {
    primary_id: "D09BVL",
    names: ["NS-1619"],
  },
  {
    primary_id: "D09BWC",
    names: [
      "E-4080",
      "E 4080",
      "AC1O5RBK",
      "E4080",
      "127404-34-6",
      "E-N-(3-((N'-(2-(3,5-Dimethoxyphenyl)Ethyl)-N'-Methyl)Amino)Propyl)-4-(4-(1H-Imidazol-1-Yl)Phenyl)-3-Butenamide Dihydrochloride Dihydrate",
      "E-40-80",
      "3-Butenamide, N-(3-((2-(3,5-Dimethoxyphenyl)Ethyl)Methylamino)Propyl)-4-(4-(1H-Imidazol-1-Yl)Phenyl)-, Dihydrochloride, (E)-",
      "(E)-N-[3-[2-(3,5-Dimethoxyphenyl)Ethyl-Methylamino]propyl]-4-(4-Imidazol-1-Ylphenyl)But-3-Enamide Dihydrochloride",
    ],
  },
  {
    primary_id: "D09BWD",
    names: ["Undecyl-Phosphinic Acid Butyl Ester"],
  },
  {
    primary_id: "D09BWE",
    names: [
      "1-[4-(Benzothiazol-2-Yloxy)Benzyl]piperidin-4-Ol",
      "Benzthiazole Compound, 33e",
      "CHEMBL479752",
      "SCHEMBL4692452",
      "BDBM24232",
    ],
  },
  {
    primary_id: "D09BWG",
    names: ["CAR-T Cells Targeting CD19"],
  },
  {
    primary_id: "D09BWP",
    names: ["[HO1][Orn8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D09BWX",
    names: ["C[L-Phe-D-Pro-L-Phe-D-Trp]", "CHEMBL502411", "SCHEMBL7980647"],
  },
  {
    primary_id: "D09BXA",
    names: ["PMID24900824C2-1-1"],
  },
  {
    primary_id: "D09BXF",
    names: ["CLS003"],
  },
  {
    primary_id: "D09BXH",
    names: [
      "2-(Cycloheptylamino)-2-Oxoethyl 2-Aminonicotinate",
      "CHEMBL550694",
      "AC1P0KT3",
      "MLS002166893",
      "MolPort-004-113-473",
      "HMS3049I09",
      "ZINC25561600",
      "BDBM50295159",
      "AKOS033614947",
      "MCULE-1392821852",
      "SMR001249231",
      "SR-01000074735",
      "SR-01000074735-1",
      "Z18385694",
      "(Cycloheptylcarbamoyl)Methyl 2-Aminopyridine-3-Carboxylate",
      "[2-(Cycloheptylamino)-2-Oxoethyl] 2-Aminopyridine-3-Carboxylate",
    ],
  },
  {
    primary_id: "D09BXJ",
    names: ["PF-5177624"],
  },
  {
    primary_id: "D09BXO",
    names: ["Steroid Derivative 2"],
  },
  {
    primary_id: "D09BYA",
    names: ["(3S,4R)-3-Butyl-1-Phenylpiperidin-4-Amine", "CHEMBL394662"],
  },
  {
    primary_id: "D09BYB",
    names: [
      "2-(Tert-Butylamino)-1-P-Tolylpropan-1-One",
      "CHEMBL566207",
      "SCHEMBL769850",
      "BDBM50302941",
      "AKOS022329818",
      "4'-Methyl-Alpha-(Tert-Butylamino)Propiophenone",
    ],
  },
  {
    primary_id: "D09BYD",
    names: ["1-Benzhydryl-4-O-Tolylpiperidin-4-Ol", "CHEMBL232273"],
  },
  {
    primary_id: "D09BYN",
    names: ["PMID30247903-Compound-General Structure12"],
  },
  {
    primary_id: "D09BYO",
    names: [
      "N-(6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDIN-4-YL)-2-HYDROXYACETAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1083014",
      "SCHEMBL1404548",
      "BDBM50320174",
    ],
  },
  {
    primary_id: "D09BYW",
    names: ["N-[4-(4-Phenyl-Piperazin-1-Yl)-Butyl]-Benzamide", "CHEMBL27393", "BDBM50022717"],
  },
  {
    primary_id: "D09BZJ",
    names: ["MEHD-7945A"],
  },
  {
    primary_id: "D09BZS",
    names: ["CK-101"],
  },
  {
    primary_id: "D09BZV",
    names: [
      "4-[2-(4-Benzyl-Piperidin-1-Yl)-Ethoxy]-Phenol",
      "CHEMBL54778",
      "SCHEMBL7402131",
      "BDBM50079386",
      "Co-101071",
      "4-[2-(4-Benzyl-Piperidin-1-Yl)-Ethoxy]-Phenol(Co 101071)",
    ],
  },
  {
    primary_id: "D09BZY",
    names: ["Nibentan"],
  },
  {
    primary_id: "D09CAC",
    names: ["U-96988"],
  },
  {
    primary_id: "D09CAI",
    names: ["TAUTOMYCIN"],
  },
  {
    primary_id: "D09CAM",
    names: ["Central Azetidine Derivative 1"],
  },
  {
    primary_id: "D09CBI",
    names: ["Anti-Her-2 Mab", "Anti-Her-2 MAb (Cancer)"],
  },
  {
    primary_id: "D09CCZ",
    names: ["Triciribine Prodrug"],
  },
  {
    primary_id: "D09CDR",
    names: ["PMID28766366-Compound-Scheme25-2"],
  },
  {
    primary_id: "D09CEJ",
    names: ["CYT003"],
  },
  {
    primary_id: "D09CER",
    names: ["PMID25522065-Compound-44"],
  },
  {
    primary_id: "D09CEY",
    names: [
      "SKL-PSY",
      "5-HT 1a Receptor Modulator (Psychiatric Disorder), SK Life Science",
      "5-HT 1a Receptor Modulator (Schizophrenia/Depression), SK Life Science",
    ],
  },
  {
    primary_id: "D09CFD",
    names: ["M372049"],
  },
  {
    primary_id: "D09CFM",
    names: [
      "DANOFLOXACIN",
      "CP-76136",
      "Danofloxacin < Rec INN",
      "CP-76136-27 (Mesylate)",
      "1-Cyclopropyl-6-Fluoro-7-[(1S,4S)-5-Methyl-2,5-Diazabicyclo[2.2.1]hept-2-Yl]-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09CFW",
    names: ["ML321", "Compound 65 [PMID 24666157]"],
  },
  {
    primary_id: "D09CGE",
    names: ["Florbetapir F-18", "Amyvid (TN)"],
  },
  {
    primary_id: "D09CGG",
    names: ["Carboxamide Derivative 10"],
  },
  {
    primary_id: "D09CGH",
    names: ["BD-1047", "BD1047", "BD 1047"],
  },
  {
    primary_id: "D09CGL",
    names: [
      "Daio-Orengedokuto (DOT)",
      "26159-34-2",
      "Miranax",
      "Naproxen Sodium Salt",
      "Synflex",
      "Naprodol",
      "Naprodil",
      "Leniartril",
      "Gibixen",
      "Floxalin",
      "Flogogin",
      "Aprowell",
      "Anapran",
      "Primeral",
      "Pactens",
      "Naprovite",
      "Gibinap",
      "Floneks",
      "Anaprotab",
      "Monarit",
      "Karoksen",
      "Sodimax",
      "Natrioxen",
      "Floginex",
      "Opraks",
      "Kapnax",
      "Tandax",
      "Flogen",
      "Nixal",
      "Aprol",
      "Naprux Gesic",
      "Causalon Pro",
      "Naproxen Natrium",
      "Axer Alfa",
      "Dysmenalgit",
      "Veradol",
      "Naprium",
      "Diocodal",
      "Proxen",
      "Prexan",
      "Naprux",
      "A-Nox",
      "Xenar",
      "Laser",
      "Apo-Napro-NA",
      "UNII-9TN87S3A3C",
      "Sodium Na",
    ],
  },
  {
    primary_id: "D09CGV",
    names: ["6-Hydroxy-1,2-Dimethyl-9H-Beta-Carbolin-2-Ium", "CHEMBL375862"],
  },
  {
    primary_id: "D09CHG",
    names: ["Activated Protein C Concn", "Anact C (TN)"],
  },
  {
    primary_id: "D09CHX",
    names: ["AMR-SIX-1", "5-HT 6 Modulators (Obesity), Albany Molecular Research"],
  },
  {
    primary_id: "D09CIB",
    names: ["BC-22"],
  },
  {
    primary_id: "D09CIC",
    names: ["Tricyclic Heterocycle Derivative 4"],
  },
  {
    primary_id: "D09CIF",
    names: ["TH-9402", "174230-05-8", "ATIR"],
  },
  {
    primary_id: "D09CIQ",
    names: ["Cadralazine", "CGP-18684", "CGP-22639", "DC-826", "ISF-2405", "ISF-2469"],
  },
  {
    primary_id: "D09CIX",
    names: ["8-Phenyloctylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL486701"],
  },
  {
    primary_id: "D09CJO",
    names: ["CXB-029", "CB1 Antagonist (Pain/Obesity/Glaucoma/CNS-Related Disease), CeNeRx"],
  },
  {
    primary_id: "D09CKF",
    names: ["Licostinel"],
  },
  {
    primary_id: "D09CKI",
    names: [
      "CORYNANTHEINE",
      "Corynantheine",
      "Hirsuteine",
      "BRN 0096586",
      "18904-54-6",
      "17,18-Secoyohimban-16-Carboxylic Acid, 16,17,18,19-Tetradehydro-17-Methoxy-, Methyl Ester, (E)-",
      "AC1MHD3Z",
      "SCHEMBL3084853",
      "MolPort-039-338-502",
      "ZINC85909641",
      "LS-144745",
      "(16E)-16,17,18,19-Tetradehydro-17-Methoxycorynan-16-Carboxylic Acid Methyl Ester",
      "Methyl (Z)-2-[(2S,3R,12bS)-3-Vinyl-1,2,3,4,6,7,12,12b-Octahydroindolo[2,3-A]quinolizin-2-Yl]-3-Methoxy-Prop-2-Enoate",
      "Methyl (Z)-2-[(2S,3R,12bS)-3-Ethenyl-1,2,3,4,6,7,12,12b-Octahydroindolo[2,3-A]quinolizin-2-Yl]-3-Methox",
    ],
  },
  {
    primary_id: "D09CKP",
    names: [
      "3,3-Dimethyl-Dihydro-Furan-2-One",
      "3709/8/8",
      "Alpha,alpha-Dimethyl-Gamma-Butyrolactone",
      "3,3-Dimethyldihydrofuran-2(3h)-One",
      "3,3-Dimethyloxolan-2-One",
      "2(3H)-Furanone, Dihydro-3,3-Dimethyl-",
      "Dihydro-3,3-Dimethyl-2(3H)-Furanone",
      "2,2-Dimethyl-4-Butyrolactone",
      "UPVAIJPDWVTFKT-UHFFFAOYSA-N",
      "Butyric Acid, 2,2-Dimethyl-4-Hydroxy-, G-Lactone",
      "Dimethylbutanolid",
      "Alpha-DMgBL",
      "NSC1094",
      "AC1L3STF",
      "AC1Q6HQD",
      "2,2-Dimethylbutyrolactone",
      "Furanone, Dihydrodimethyl-",
      "A,a-Dimethyl-?-Butyrolactone",
      "SCHEMBL149596",
      "CHEMBL33962",
      "CTK2A6366",
      "DTXSID30190584",
      "MolPort-009-198-442",
    ],
  },
  {
    primary_id: "D09CKT",
    names: ["LY433222"],
  },
  {
    primary_id: "D09CKU",
    names: ["PMID26394986-Compound-44"],
  },
  {
    primary_id: "D09CKV",
    names: ["4-(3,4-Dimethylbenzylthio)-2-Aminobutanoic Acid", "CHEMBL204870", "BDBM50179737"],
  },
  {
    primary_id: "D09CKZ",
    names: [
      "AM-411",
      "CHEMBL434351",
      "BDBM50169951",
      "(6aR,10aR)-3-Adamantan-1-Yl-6,6,9-Trimethyl-6a,7,10,10a-Tetrahydro-6H-Benzo[c]chromen-1-Ol",
    ],
  },
  {
    primary_id: "D09CLC",
    names: ["C188-9"],
  },
  {
    primary_id: "D09CLH",
    names: ["Samarium SM-153 Lexidronam Injection"],
  },
  {
    primary_id: "D09CLP",
    names: ["(L-)-S-Adenosyl-L-Homocysteine"],
  },
  {
    primary_id: "D09CLX",
    names: ["MDT-006"],
  },
  {
    primary_id: "D09CMJ",
    names: ["FR122047"],
  },
  {
    primary_id: "D09CMK",
    names: ["R-Salbutamol Sulphate", "ASF-1096"],
  },
  {
    primary_id: "D09CNO",
    names: ["Grass Pollen Allergy Vaccine Tablet"],
  },
  {
    primary_id: "D09CNR",
    names: ["KLS-Npk"],
  },
  {
    primary_id: "D09CNT",
    names: [
      "1-(2-Furan-2-Yl-Ethyl)-3-Thiazol-2-Yl-Thiourea",
      "172505-85-0",
      "Thiourea, N-(2-(2-Furanyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(2-Furanyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 29",
      "PETT Furyl Deriv.",
      "AC1MHDKI",
      "CHEMBL359410",
      "BDBM1922",
      "1-[2-(Furan-2-Yl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "DTXSID70169335",
      "N-(2-(2-Furyl)Ethyl)-N-(2-Thiazolyl)Thiou",
      "1-[2-(2-Furyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "1-(2-Thiazolyl)-3-[2-(Furan-2-Yl)Ethyl]thiourea",
    ],
  },
  {
    primary_id: "D09CNV",
    names: ["EDG-005"],
  },
  {
    primary_id: "D09COD",
    names: ["Romurtide"],
  },
  {
    primary_id: "D09CON",
    names: ["Triple Action Antibiotic"],
  },
  {
    primary_id: "D09CPC",
    names: ["6-Pyridin-3-Yl-3,4-Dihydro-1H-Quinolin-2-One", "6-Pyridin-3-Yl-3,4-Dihydroquinolin-2(1H)-On"],
  },
  {
    primary_id: "D09CPR",
    names: [
      "Penicillin V",
      "Apocillin",
      "Apopen",
      "Beromycin",
      "Calcipen",
      "Fenacilin",
      "Fenospen",
      "Fenossimetilpenicillina",
      "Fenoximetilpenicilina",
      "Fenoxymethylpenicillin",
      "Fenoxypen",
      "Meropenin",
      "Oracillin",
      "Oratren",
      "Ospen",
      "PNV",
      "PVA",
      "Phenocillin",
      "Phenomycilline",
      "Phenopenicillin",
      "Phenoximethylpenicillinum",
      "Phenoxomethylpenicillanyl",
      "Phenoxomethylpenicillin",
      "Phenoxymethylpenicillanyl",
      "Phenoxymethylpenicillin",
      "Phenoxymethylpenicilline",
      "Phenoxymethylpenicillinum",
      "Robicillin",
      "Rocilin",
      "Stabicillin",
      "Vebecillin",
      "Vegacillin",
      "Acipen V",
      "Compocillin V",
      "Crystapen V",
      "Distaquaine V",
      "Eskacillian V",
      "Eskacillin V",
      "Fenossimetilpenicillina [DCIT]",
      "Penicillin V [USAN]",
      "Penicillin Phenoxymethyl",
      "Phenoxymethyl Penicillin",
      "Phenoxymethylenepenicillinic Acid",
      "Phenoxymethylpenicillinic Acid",
      "Phenoxymethylpenicillinic Acid Potassium Salt",
      "Acipen-V",
      "Beromycin, Penicillin",
      "Berromycin, Penicillin",
      "Fenoximetilpenicilina [INN-Spanish]",
      "Pen-Oral",
      "Pen-V",
      "Pen-Vee",
      "Penicillin V (USP)",
      "Penicillin, Phenoxymethyl",
      "Phenoxymethylpenicillin (INN)",
      "Phenoxymethylpenicilline [INN-French]",
      "Phenoxymethylpenicillinum [INN-Latin]",
      "V-Cil",
      "V-Cillin",
      "V-Cylina",
      "V-Cyline",
      "V-Tablopen",
      "P-Mega-Tablinen",
      "V-Cillin (TN)",
      "(2S,5R,6R)-3,3-DIMETHYL-7-OXO-6-(2-PHENOXYACETAMIDO)-4-THIA-1-AZABICYCLO(3.2.0)HEPTANE-2-CARBOXYLIC ACID",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-(2-Phenoxyacetamido)-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-[(Phenoxyacetyl)Amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-[(2-Phenoxyacetyl)Amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-{[(Phenyloxy)Acetyl]amino}-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "2,2-Dimethyl-6beta-[(Phenoxyacetyl)Amino]penam-3alpha-Carbonyl",
      "2,2-Dimethyl-6beta-[(Phenoxyacetyl)Amino]penam-3alpha-Carboxylic Acid",
      "3,3-Dimethyl-7-Oxo-6-((Phenyloxyacetyl)Amino)-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "3,3-Dimethyl-6beta-[(Phenoxyacetyl)Amino]penam-2alpha-Carboxylic Acid (PIN)",
      "6-Phenoxyacetamidopenicillanic Acid",
    ],
  },
  {
    primary_id: "D09CQB",
    names: ["GPs-0193", "Antisense Oligonucleotide, Chugai"],
  },
  {
    primary_id: "D09CQC",
    names: ["2-(4-Hydroxy-Phenyl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D09CQO",
    names: ["2-Thiophen-2-Yl-3H-Imidazo[4,5-C]quinoline", "CHEMBL319249", "ZINC13756973"],
  },
  {
    primary_id: "D09CRQ",
    names: ["RPK-739"],
  },
  {
    primary_id: "D09CSB",
    names: [
      "Crotylaldehyde",
      "Crotonaldehyde",
      "CROTONALDEHYDE",
      "2-Butenal",
      "Crotonal",
      "(2E)-But-2-Enal",
      "Trans-Crotonaldehyde",
      "123-73-9",
      "Crotonic Aldehyde",
      "(E)-Crotonaldehyde",
      "(E)-But-2-Enal",
      "Trans-2-Butenal",
      "Methylpropenal",
      "Propylene Aldehyde",
      "1-Formylpropene",
      "Beta-Methylacrolein",
      "4170-30-3",
      "2-Butenal, (2E)-",
      "Aldehyde Crotonique",
      "2-Butenaldehyde",
      "But-2-Enal",
      "Topanel",
      "Trans- Crotonal",
      "2-Butenal, (E)-",
      "Topanel CA",
      "Trans-But-2-Enal",
      "RCRA Waste Number U053",
      "E-2-Butenal",
      "Beta-Methyl Acrolein",
      "Trans-2-Butenaldehyde",
      "Krotonaldehyd [Czech]",
      "(E)-2-Butenal",
      "CROTONALDEHYDE, (E)-",
    ],
  },
  {
    primary_id: "D09CSP",
    names: [
      "3-(4-Sulfamoylphenyl)Propanoic Acid",
      "90610-69-8",
      "3-(4-Sulfamoyl-Phenyl)-Propionic Acid",
      "3-[4-(Aminosulfonyl)Phenyl]propanoic Acid",
      "CHEMBL451332",
      "4-(2-Carboxyethyl)-Benzenesulfonamide",
      "M28",
      "BAS 12384209",
      "AC1MKR5Y",
      "AC1Q55BB",
      "4-Carboxyethylbenzenesulfonamide",
      "SCHEMBL4964212",
      "CTK7J3040",
      "BDBM29277",
      "MolPort-001-769-253",
      "JUEONDBIBADVGD-UHFFFAOYSA-N",
      "HMS3604J11",
      "ZINC4362893",
      "4-(Aminosulphonyl)Hydrocinnamic Acid",
      "STK802680",
      "ANW-44559",
      "SBB011591",
      "BBL002635",
      "4-(2-Carboxyethyl)Benzenesulphonamide",
      "P-CARBOXYETHYLBENZENESULFONAMIDE",
      "3-[4-(AMINOSULFONYL)PHENYL]PROPANOIC ACID",
    ],
  },
  {
    primary_id: "D09CSQ",
    names: [
      "DFGYVAE",
      "CHEMBL1163804",
      "GTPL3023",
      "BDBM50321104",
      "(2S)-2-[(2S)-2-[(2S)-2-[(2S)-2-{2-[(2S)-2-[(2S)-2-Amino-3-Carboxypropanamido]-3-Phenylpropanamido]acetamido}-3-(4-Hydroxyphenyl)Propanamido]-3-Methylbutanamido]propanamido]pentanedioic Acid",
    ],
  },
  {
    primary_id: "D09CTF",
    names: ["Human Recombinant Factor VIII"],
  },
  {
    primary_id: "D09CTG",
    names: ["Ac-RYYRIK-K-(NH2)-YRFB", "CHEMBL412939"],
  },
  {
    primary_id: "D09CTH",
    names: ["RP-701"],
  },
  {
    primary_id: "D09CTY",
    names: ["OPHD-002"],
  },
  {
    primary_id: "D09CUJ",
    names: ["6-Fluoro-Noradrenaline", "6-Fluoronorepinephrine", "6-Fluoro-NE"],
  },
  {
    primary_id: "D09CUR",
    names: ["Trans-Sodium Crocetinate"],
  },
  {
    primary_id: "D09CVD",
    names: ["1,1,1,3-Tetrafluoro-Heptadecan-2-One", "CHEMBL462389"],
  },
  {
    primary_id: "D09CVG",
    names: [
      "4-[3-(4-CHLOROPHENYL)-1H-PYRAZOL-5-YL]PIPERIDINE",
      "156336-70-8",
      "4-[5-(4-Chlorophenyl)-1H-Pyrazol-3-Yl]piperidine",
      "CHEMBL75691",
      "4-(5-(4-Chlorophenyl)-1H-Pyrazol-3-Yl)Piperidine",
      "L02",
      "1way",
      "AC1L9MRB",
      "Bionet2_000947",
      "Oprea1_423246",
      "MLS000706783",
      "SCHEMBL2598428",
      "KS-00001ZEP",
      "MolPort-001-684-207",
      "GELALLNTKKLQLM-UHFFFAOYSA-N",
      "HMS2660H19",
      "HMS1366L01",
      "ZINC5974200",
      "BDBM50059237",
      "AKOS015868501",
      "AKOS023968186",
      "DB08061",
      "MCULE-8931017804",
      "SMR000334280",
      "FT-0741308",
      "7L-015",
      "3-(4-Piperidinyl)-5-(4-Chlorophenyl)Pyrazole",
    ],
  },
  {
    primary_id: "D09CVV",
    names: ["NORSTEPHALAGINE"],
  },
  {
    primary_id: "D09CXE",
    names: ["Norovirus Bivalent Vaccine"],
  },
  {
    primary_id: "D09CXQ",
    names: [
      "Acanthus Ilicifolius Linn",
      "AC1L1B2B",
      "CBiol_001885",
      "KBioGR_000483",
      "KBioSS_000483",
      "BSPBio_001143",
      "(-)-Huperzine A-D5 (Major)",
      "CHEMBL3184073",
      "KBio3_000886",
      "CTK8E6796",
      "KBio2_003051",
      "BDBM10441",
      "KBio2_005619",
      "KBio2_000483",
      "KBio3_000885",
      "Bio2_000402",
      "Bio1_001149",
      "Bio1_000660",
      "HMS1990I05",
      "Bio2_000882",
      "Bio1_000171",
      "AKOS032962044",
      "AN-1052",
      "KS-0000000G",
      "IDI1_002157",
      "NCGC00163246-01",
      "FT-0642946",
      "N1881",
      "Acarviostatin III03",
      "Acarviostatin IV03",
    ],
  },
  {
    primary_id: "D09CXX",
    names: ["N-Hydroxysulfamide"],
  },
  {
    primary_id: "D09CYJ",
    names: ["4-Hydroxy-3-(1-Naphthylmethyl)-2H-Chromen-2-One"],
  },
  {
    primary_id: "D09CYK",
    names: ["ENDG-3010"],
  },
  {
    primary_id: "D09CZA",
    names: ["Travoprost"],
  },
  {
    primary_id: "D09CZS",
    names: [
      "DRF-1042",
      "4(S)-Ethyl-4-Hydroxy-12-(2-Hydroxyethoxy)-3,4,12,14-Tetrahydro-1H-Pyrano[3',4':6,7]indolizino[2,1-B]quinoline-3,14-Dione",
    ],
  },
  {
    primary_id: "D09CZV",
    names: ["Methyl Mercury Ion"],
  },
  {
    primary_id: "D09DAC",
    names: ["Des-AA1,2,4,12,13-[D-Trp8]SRIF", "CHEMBL263587"],
  },
  {
    primary_id: "D09DAJ",
    names: ["FG-6874"],
  },
  {
    primary_id: "D09DAM",
    names: ["H-Dmt-Tic-Lys(Ac)-NH-CH2-Ph"],
  },
  {
    primary_id: "D09DAP",
    names: ["AG-09/1", "AG-09/1 [benzimidazole Derivative]", "ST024252"],
  },
  {
    primary_id: "D09DAQ",
    names: ["ISIS 353512"],
  },
  {
    primary_id: "D09DBH",
    names: [
      "2-Phenyl-N-(Thiazol-2-Yl)Acetamide",
      "2-Phenyl-N-(1,3-Thiazol-2-Yl)Acetamide",
      "2-Phenyl-N-Thiazol-2-Yl-Acetamide",
      "292051-66-2",
      "Benzeneacetamide, N-2-Thiazolyl-",
      "BAS 15431504",
      "ChemDiv2_003476",
      "AC1LBQ73",
      "MLS001211116",
      "CHEMBL227897",
      "SCHEMBL4687548",
      "ZINC29573",
      "HYRZQLIFVQELLP-UHFFFAOYSA-N",
      "MolPort-001-012-138",
      "HMS2829M16",
      "HMS1378N22",
      "STK012295",
      "AKOS000714854",
      "MCULE-4931151694",
      "ST042627",
      "SMR000516648",
      "2-Phenyl-N-(1,3-Thiazol-2-Yl)Acetamide #",
      "2-Phenyl-N~1~-(1,3-Thiazol-2-Yl)Acetamide",
      "SR-01000415900",
      "SR-01000415900-1",
      "BRD-K98487211-001-07-7",
      "Z28173686",
    ],
  },
  {
    primary_id: "D09DBR",
    names: ["Micronomicin Sulfate"],
  },
  {
    primary_id: "D09DBY",
    names: ["Rilonacept"],
  },
  {
    primary_id: "D09DCJ",
    names: ["ISIS 298743"],
  },
  {
    primary_id: "D09DDQ",
    names: ["FUB-130"],
  },
  {
    primary_id: "D09DEK",
    names: [
      "7-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL191715",
      "7-(4-Hydroxyphenyl)-2-Naphthol",
      "SCHEMBL3932603",
      "NSCZTZNRMFWAIY-UHFFFAOYSA-N",
      "ZINC13645002",
      "BDBM50168330",
    ],
  },
  {
    primary_id: "D09DER",
    names: ["QR-336"],
  },
  {
    primary_id: "D09DEU",
    names: ["HMPL-342"],
  },
  {
    primary_id: "D09DFB",
    names: [
      "EFEGATRAN SULFATE HYDRATE",
      "Efegatran Sulfate Hydrate < Rec INNM",
      "N-Methyl-D-Phenylalanyl-L-Prolyl-L-Arginal Sulfate Hydrate",
      "N-Methyl-D-Phenylalanyl-N-[4-Guanidino-1(S)-Formylbutyl]-L-Prolinamide Sulfate Hydrate",
    ],
  },
  {
    primary_id: "D09DFD",
    names: ["CP-4497", "CP-47497"],
  },
  {
    primary_id: "D09DGA",
    names: [
      "11H-Benzo[a]carbazole",
      "Benzocarbazole",
      "239-01-0",
      "1,2-Benzocarbazole",
      "1,2-Benzcarbazole",
      "11-Azachrysofluorene",
      "Benzo[a]carbazole",
      "11H-Benzo(A)Carbazole",
      "BENZO(A)CARBAZOLE",
      "UNII-3Y3SPE26QX",
      "EINECS 205-945-4",
      "NSC 403640",
      "BRN 0155940",
      "3Y3SPE26QX",
      "CHEMBL1173622",
      "MYKQKWIPLZEVOW-UHFFFAOYSA-N",
      "67526-84-5",
      "WLN: T D6 B566 CMJ",
      "Benzo[3,4]carbazole",
      "Benzo[1,2]carbazole",
      "1,2-Benzo-Carbazole",
      "AC1L1SJA",
      "AC1Q4W1R",
      "AC1Q1IZ3",
      "SCHEMBL70791",
      "5-20-08-00456 (Beilstein Handbook Reference)",
      "CTK1A2947",
      "MolPort-001-815-051",
      "ZINC1690207",
      "NSC60421",
      "NSC403640",
    ],
  },
  {
    primary_id: "D09DGE",
    names: ["LD-101", "AKT-SI-1"],
  },
  {
    primary_id: "D09DHL",
    names: ["TDI-0090"],
  },
  {
    primary_id: "D09DHT",
    names: ["Phorbol 12-Myristate 13-Acetate"],
  },
  {
    primary_id: "D09DHY",
    names: [
      "Colchicine",
      "Colchicin",
      "Colchicina",
      "Colchicinum",
      "Colchineos",
      "Colchisol",
      "Colchysat",
      "Colcin",
      "Colcrys",
      "Colsaloid",
      "Colstat",
      "Condylon",
      "Goutnil",
      "Kolkicin",
      "LOC",
      "Binds To Tubulin",
      "Colchicin [German]",
      "Colchicina [Italian]",
      "Colchicine [JAN]",
      "Inhibits Microtubular Assembly",
      "Spindle Poison",
      "C 9754",
      "Colchicine (TN)",
      "Colchicine, Colchicum Autumnale",
      "MPC-004",
      "N-Acetyl Trimethylcolchicinic Acid Methylether",
      "Colchicine (JP15/USP)",
      "Colchicine, (R)-Isomer",
      "Benzo(A)Heptalen-9(5H)-One",
      "Colchicine, (+-)-Isomer",
      "N-(5,6,7,9-Tetrahydro-1,2,3,10-Tetramethoxy-9-Oxobenzo(A)Heptalen-7-Yl)Acetamide",
      "N-[(7S)-1,2,3,10-Tetramethoxy-9-Oxo-5,6,7,9-Tetrahydrobenzo[a]heptalen-7-Yl]acetamide",
      "N-[(7S)-1,2,3,10-Tetramethoxy-9-Oxo-6,7-Dihydro-5H-Benzo[a]heptalen-7-Yl]acetamide",
      "N-[(7S)-5,6,7,9-Tetrahydro-1,2,3,10-Tetramethoxy-9-Oxobenzo[a]heptalen-7-Yl]acetamide",
      "N-(5,6,7,9-Tetrahydro-1,2,3,10-Tetramethoxy-9-Oxobenzo[.alpha.]heptalen-7-Yl)-Acetamide",
      "N-((7S)-5,6,7,9-Tetrahydro-1,2,3,10-Tetramethoxy-9-Oxobenzo(A)Heptalen-7-Yl)-Acetamide",
      "(S)-N-(5,6,7,9-Tetrahydro-1,2,3,10-Tetramethoxy-9-Oxobenzo[a]heptalen-7-Yl)Acetamide",
      "7-Alpha-H-Colchicine",
      "7.alpha.H-Colchicine",
      "7alphaH-Colchicine",
    ],
  },
  {
    primary_id: "D09DIB",
    names: ["RG-4929"],
  },
  {
    primary_id: "D09DIG",
    names: ["Ablukast", "Ablukast Sodium", "RO-23-3544"],
  },
  {
    primary_id: "D09DIQ",
    names: ["CART22 Cells Expressing Anti-CD22 ScFv TCRz:41BB"],
  },
  {
    primary_id: "D09DJC",
    names: [
      "IRX4204",
      "220619-73-8",
      "CHEMBL75133",
      "UNII-877M97Z38Y",
      "VTP-194204",
      "877M97Z38Y",
      "KB-145960",
      "SCHEMBL3437269",
      "MolPort-042-665-869",
      "ZINC1550770",
      "IRX-4204",
      "3-Methyl-5-[2-Methyl-2-(5,5,8,8-Tetramethyl-5,6,7,8-Tetrahydro-Naphthalen-2-Yl)-Cyclopropyl]-Penta-2,4-Dienoic Acid",
      "BDBM50101445",
      "DB11806",
      "VTP 194204",
      "(+)-VTP-194204",
      "AGN 4204",
      "(2E,4E)-3-Methyl-5-[(1S,2S)-2-Methyl-2-(5,5,8,8-Tetramethyl-5,6,7,8-Tetrahydro-Naphthalen-2-Yl)-Cyclopropyl]-Penta-2,4-Dienoic Acid",
    ],
  },
  {
    primary_id: "D09DJO",
    names: ["Pafuramidine"],
  },
  {
    primary_id: "D09DJS",
    names: ["INV-400"],
  },
  {
    primary_id: "D09DJU",
    names: ["SU-14813"],
  },
  {
    primary_id: "D09DJX",
    names: [
      "(S,S)-(-)-Bis(12)-Hupyridone",
      "E12",
      "(S,s)-(-)-N,n'-Di-5'-[5',6',7',8'-Tetrahydro- 2'(1'h)-Quinolynyl]-1,12-Diaminododecane Dihydrochloride",
      "AC1L9IVX",
      "CHEMBL519154",
      "BDBM10440",
      "1h23",
      "5,5'-(1,12-Dodecanediylbisimino)Bis[(5S)-5,6,7,8-Tetrahydro-2(1H)-Quinolinone]",
      "(5S,5'S)-5,5'-(Dodecane-1,12-Diyldiimino)Di(5,6,7,8-Tetrahydroquinolin-2(1H)-One)",
      "(5S)-5-[12-[[(5S)-2-Oxo-5,6,7,8-Tetrahydro-1H-Quinolin-5-Yl]amino]dodecylamino]-5,6,7,8-Tetrahydro-1H-Quinolin-2-One",
    ],
  },
  {
    primary_id: "D09DKC",
    names: ["[125I]HEAT", "[125I]-HEAT"],
  },
  {
    primary_id: "D09DKY",
    names: ["AEZS-123"],
  },
  {
    primary_id: "D09DLN",
    names: [
      "L-739749",
      "CHEMBL99645",
      "BDBM50059867",
      "(S)-2-{(S)-2-[(S)-2-((R)-2-Amino-3-Mercapto-Propylamino)-3-Methyl-Pentyloxy]-3-Phenyl-Propionylamino}-4-Methanesulfonyl-Butyric Acid Methyl Ester",
      "(S)-Methyl 2-((S)-2-((2S,3R)-2-((R)-2-Amino-3-Mercaptopropylamino)-3-Methylpentyloxy)-3-Phenylpropanamido)-4-(Methylsulfonyl)Butanoate",
    ],
  },
  {
    primary_id: "D09DLP",
    names: [
      "Tramiprosate",
      "Alzhemed",
      "Cerebril",
      "Homotaurine",
      "Vivimind",
      "LU-02659",
      "NC-531",
      "NC-758",
      "Tramiprosate (Stroke), Neurochem",
      "Tramiprosate (Alzheimer's Disease), Neurochem",
      "3APS",
    ],
  },
  {
    primary_id: "D09DLY",
    names: [
      "1-(3,4-Dichlorophenyl)-3-(3,5-Dichlorophenyl)Urea",
      "CHEMBL255505",
      "AC1LKCSZ",
      "CBMicro_019879",
      "AC1Q3IF4",
      "MolPort-000-644-777",
      "ZINC639843",
      "CCG-8060",
      "1-(3,4-DICHLORO-PHENYL)-3-(3,5-DICHLORO-PHENYL)-UREA",
      "BDBM50373332",
      "AKOS002934710",
      "MCULE-5556566180",
      "BIM-0020045.P001",
      "ST50982277",
      "SJ000146676",
    ],
  },
  {
    primary_id: "D09DMQ",
    names: ["Human Growth Hormone"],
  },
  {
    primary_id: "D09DMR",
    names: ["4-Phenyliminomethyl-Benzenesulfonamide", "CHEMBL407019", "SCHEMBL5311443"],
  },
  {
    primary_id: "D09DND",
    names: ["Anti-GD2-CAR Engineered T Cells"],
  },
  {
    primary_id: "D09DNJ",
    names: [
      "L-692289",
      "CHEMBL437438",
      "AC1MHQQ8",
      "BDBM50285493",
      "N-{(2R,6S,9S,11R,14aS,15S,20S,23S,25aS)-20-[(1R)-3-Amino-1-Hydroxy-3-Oxopropyl]-2,11,15-Trihydroxy-6-[(1R)-1-Hydroxyethyl]-23-[(1R)-1-Hydroxy-2-(4-Hydroxyphenyl)Ethyl]-5,8,14,19,22,25-Hexaoxotetracosahydro-1H-Dipyrrolo[2,1-C:2',1'-L][1,4,7,10,13,16]hexaazacyclohenicosin-9-Yl}-10,12-Dimethyltetradecanamide",
    ],
  },
  {
    primary_id: "D09DNT",
    names: ["KNI-10061"],
  },
  {
    primary_id: "D09DPC",
    names: [
      "1-Adamantan-1-Yl-3-(2-Methoxy-Phenyl)-Urea",
      "CHEMBL445531",
      "Oprea1_425301",
      "Oprea1_433459",
      "MolPort-001-590-368",
      "ZINC5676460",
      "STL504055",
      "BDBM50267095",
      "AKOS003326568",
      "MCULE-4523135214",
      "1-(1-Adamantyl)-3-(2-Methoxyphenyl)Urea",
      "ST50933621",
      "N-Adamantanyl[(2-Methoxyphenyl)Amino]carboxamide",
    ],
  },
  {
    primary_id: "D09DQV",
    names: [
      "(1S)-MENTHYL HEXYL PHOSPHONATE GROUP",
      "AC1L9KBO",
      "DB08201",
      "Hexyl[(1S,2R,5S)-2-Isopropyl-5-Methylcyclohexyl]oxyphosphinic Acid",
      "Hexyl-[(1S,2R,5S)-5-Methyl-2-Propan-2-Ylcyclohexyl]oxyphosphinic Acid",
      "(1S,2R,5S)-5-Methyl-2-(1-Methylethyl)Cyclohexyl Hydrogen (S)-Hexylphosphonate",
    ],
  },
  {
    primary_id: "D09DRF",
    names: [
      "3-(Benzyloxy)Pyridin-2-Amine",
      "2-Amino-3-Benzyloxypyridine",
      "24016-03-3",
      "3-(BENZYLOXY)PYRIDIN-2-AMINE",
      "3-Benzyloxy-2-Pyridylamine",
      "2-Pyridinamine, 3-(Phenylmethoxy)-",
      "3-Benzyloxypyridin-2-Amine",
      "3-Benzyloxy-2-Aminopyridine",
      "2-Amino-3-Benzyloxy Pyridine",
      "3-Phenylmethoxypyridin-2-Amine",
      "2-Amino-3-(Benzyloxy)Pyridine",
      "3-(Phenylmethoxy)-2-Pyridinamine",
      "MFCD00006316",
      "3IP",
      "3-(Phenylmethoxy)-2-Pyridylamine",
      "2-Amino-3-Benzyloxypyridine, 98.5%",
      "3-(Benzyloxy)Pyridin-2-Amin",
      "3fty",
      "EINECS 245-983-9",
      "PubChem2575",
      "1w7h",
      "ACMC-1COKO",
      "AC1L3JRI",
      "Maybridge1_001801",
      "AC1Q52WQ",
    ],
  },
  {
    primary_id: "D09DRG",
    names: ["NE-100"],
  },
  {
    primary_id: "D09DRM",
    names: ["NT-1635"],
  },
  {
    primary_id: "D09DRS",
    names: [
      "6,8-Dimethoxy-1,4-Dimethylquinolin-2(1H)-One",
      "Casimiroin Analogue, 1j",
      "CHEMBL493173",
      "BDBM29221",
      "MolPort-028-745-489",
      "ZINC40955907",
      "1,4-Dimethyl-6,8-Dimethoxyquinoline-2(1H)-One",
    ],
  },
  {
    primary_id: "D09DSK",
    names: ["JWH-051", "CB2 Receptor Agonists (Pain)", "CB2 Receptor Agonists (Pain), Merck"],
  },
  {
    primary_id: "D09DSZ",
    names: [
      "2,3-Dihydrobenzo[d]thiazole-2-Thiol",
      "CHEMBL570797",
      "2,3-Dihydrobenzothiazole-2-Thiol",
      "2,3-Dihydro-1,3-Benzothiazole-2-Thiol",
      "AC1MMT8Q",
      "2-Benzo[d]thiazolinethiol",
      "2(3H)-Benzothiazolethiol",
      "SCHEMBL1405549",
      "BDBM50303907",
      "AKOS006278847",
      "94495-79-1",
    ],
  },
  {
    primary_id: "D09DTY",
    names: [
      "DABO-1047",
      "DABOs Universita Di Roma",
      "DABOs Universita Di Siena",
      "DABO Derivatives, Idenix",
      "DABO-1220",
      "DABOs, Idenix",
      "DABOs, Novirio",
      "DABOs, Universita Di Cagliari",
      "Dihydroalkoxybenzyloxopyrimidines, Idenix",
      "Dihydroalkoxybenzyloxopyrimidines, Novirio",
      "HIV Reverse Transcriptase Inhibitors, Idenix",
      "MC-1008",
      "MC-1047",
      "MC-1145",
      "MC-1220",
      "MC-442",
      "MC-849",
      "MC-867",
      "MC-922",
      "MeSMe-DABO Universita Di Cagliari",
      "Anti-HIV Therapeutics, Idenix",
    ],
  },
  {
    primary_id: "D09DUE",
    names: ["MCL-447"],
  },
  {
    primary_id: "D09DUQ",
    names: ["AN-2718", "Boron-Based Leucyl-TRNA Synthetase Inhibitor (Topical, Fungal Infections), Anacor"],
  },
  {
    primary_id: "D09DVB",
    names: ["Y-C[D-Pen-(2R,3R)-2-Me-(2')Nal-GSFC]KR-NH2", "CHEMBL412218", "BDBM50064828"],
  },
  {
    primary_id: "D09DWA",
    names: ["(10H-Phenothiazin-10-Yl)(O-Tolyl)Methanone", "CHEMBL397099", "AC1MRTR1", "Oprea1_791821", "BDBM50219215"],
  },
  {
    primary_id: "D09DWL",
    names: ["Zaleplon"],
  },
  {
    primary_id: "D09DWM",
    names: ["Isobutyl-(4-Methyl-Benzyl)-Piperidin-4-Yl-Amine", "CHEMBL380191", "SCHEMBL5996834", "BDBM50183166"],
  },
  {
    primary_id: "D09DXN",
    names: [
      "D-Kynurenine",
      "13441-51-5",
      "(R)-2-Amino-4-(2-Aminophenyl)-4-Oxobutanoic Acid",
      "UNII-DK8PQD0WC5",
      "DK8PQD0WC5",
      "CHEBI:86262",
      "(2R)-2-Amino-4-(2-Aminophenyl)-4-Oxobutanoic Acid",
      "K-8980",
      "Kynurenine, D-",
      "D-2-Amino-4-[2-Aminophenyl]-4-Oxobutanoic Acid",
      "D-2-Amino-4-(2-Aminophenyl)-4-Oxobutanoic Acid",
      "D-KYN",
      "D-Kynurenine, Free Base",
      "AC1LMC0V",
      "GTPL5799",
      "SCHEMBL13969610",
      "CHEMBL1233899",
      "Benzenebutanoic Acid, Alpha,2-Diamino-Gamma-Oxo-, (R)-",
      "CTK8C4802",
      "DTXSID50360767",
      "Benzenebutanoic Acid, Alpha,2-Diamino-Gamma-Oxo-, (AlphaR)-",
      "ZINC901103",
    ],
  },
  {
    primary_id: "D09DXX",
    names: ["PF-06282999"],
  },
  {
    primary_id: "D09DYB",
    names: ["CHDI-00316226"],
  },
  {
    primary_id: "D09DYT",
    names: ["EP-5001", "Avidin (Antithrombotic Antidote), Endotis Pharma"],
  },
  {
    primary_id: "D09DZI",
    names: ["Indandione Derivative 2"],
  },
  {
    primary_id: "D09EAE",
    names: ["SU-6689"],
  },
  {
    primary_id: "D09EAO",
    names: [
      "LY214352",
      "8-Chloro-4-(2-Chloro-4-Fluorophenoxy)Quinoline",
      "124495-31-4",
      "Quinoline,8-Chloro-4-(2-Chloro-4-Fluorophenoxy)-",
      "AC1L2WYE",
      "ACMC-1C12P",
      "SCHEMBL7044612",
      "CTK4B3930",
      "DTXSID10154407",
      "ZXLMSTBJMZJAAH-UHFFFAOYSA-N",
      "LY-214352",
      "8-Chloro-4-(2-Chloro-4-Fluorophenoxy) Quinoline",
    ],
  },
  {
    primary_id: "D09EBJ",
    names: ["MC-02306"],
  },
  {
    primary_id: "D09EBL",
    names: ["Antegren"],
  },
  {
    primary_id: "D09EBS",
    names: ["Oxymetazoline"],
  },
  {
    primary_id: "D09EBV",
    names: [
      "7,9-Tetradecadiynoic Acid",
      "CHEMBL501307",
      "7,9-Tetradecadiyneoic Acid",
      "Tetradecan-7,9-Diynoic Acid",
      "LMFA01031094",
      "BDBM50292399",
    ],
  },
  {
    primary_id: "D09EDA",
    names: ["PRM-151"],
  },
  {
    primary_id: "D09EDP",
    names: [
      "3-Hydroxyisoxazole-4-Carboxylic Acid",
      "3-HYDROXYISOXAZOLE-4-CARBOXYLIC ACID",
      "178316-78-4",
      "GAG",
      "AC1L9M6X",
      "3-Isoxazolol Carboxylic Acid",
      "SCHEMBL353410",
      "CHEMBL1232960",
      "CTK0H1143",
      "BDBM23242",
      "DTXSID30332285",
      "1,2(1,5)-Isoxazole, IOA1",
      "AKOS022719234",
      "AKOS006383552",
      "3-Oxo-1,2-Oxazole-4-Carboxylic Acid",
      "DB02111",
      "3-Hydroxy-1,2-Oxazole-4-Carboxylic Acid",
      "3-Oxo-2,3-Dihydroisoxazole-4-Carboxylic Acid",
      "2429-EP2311823A1",
      "2429-EP2308960A1",
      "4-Isoxazolecarboxylicacid, 2,3-Dihydro-3-Oxo-",
    ],
  },
  {
    primary_id: "D09EDW",
    names: ["Deligoparin Sodium"],
  },
  {
    primary_id: "D09EED",
    names: ["SYM-009"],
  },
  {
    primary_id: "D09EEV",
    names: ["Declopramide"],
  },
  {
    primary_id: "D09EFC",
    names: ["CAP-232"],
  },
  {
    primary_id: "D09EFW",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 13"],
  },
  {
    primary_id: "D09EFX",
    names: ["Diphtheria And Tetanus Toxoids"],
  },
  {
    primary_id: "D09EGD",
    names: ["PMID29865878-Compound-54"],
  },
  {
    primary_id: "D09EGQ",
    names: ["MRS2905"],
  },
  {
    primary_id: "D09EGZ",
    names: [
      "Chloroquine",
      "Amokin",
      "Aralen",
      "Arechin",
      "Arechine",
      "Arequin",
      "Arolen",
      "Arthrochin",
      "Artrichin",
      "Avlochlor",
      "Avloclor",
      "Bemaco",
      "Bemaphate",
      "Bemasulph",
      "Benaquin",
      "Bipiquin",
      "Capquin",
      "Chemochin",
      "Chingamin",
      "Chloraquine",
      "Chlorochin",
      "Chlorochine",
      "Chlorochinum",
      "Chloroin",
      "Chloroquin",
      "Chloroquina",
      "Chloroquinium",
      "Chloroquinum",
      "Chlorquin",
      "Cidanchin",
      "Clorochina",
      "Cloroquina",
      "Cocartrit",
      "Delagil",
      "Dichinalex",
      "Elestol",
      "Gontochin",
      "Heliopar",
      "Imagon",
      "Iroquine",
      "Khingamin",
      "Klorokin",
      "Lapaquin",
      "Malaquin",
      "Malaren",
      "Malarex",
      "Mesylith",
      "Miniquine",
      "Neochin",
      "Nivachine",
      "Pfizerquine",
      "Quinachlor",
      "Quinagamin",
      "Quinagamine",
      "Quinercyl",
      "Quingamine",
      "Quinilon",
      "Quinoscan",
      "Resochen",
      "Resochin",
      "Resoquina",
      "Resoquine",
      "Reumachlor",
      "Reumaquin",
      "Rivoquine",
      "Ronaquine",
      "Roquine",
      "Sanoquin",
      "Silbesan;Siragan",
      "Solprina",
      "Sopaquin",
      "Tanakan",
      "Tanakene",
      "Tresochin",
      "Trochin",
      "Aralen HCl",
      "Chloroquine Phosphate",
      "Clorochina [DCIT]",
      "Gontochin Phosphate",
      "Nivaquine B",
      "Ipsen 225",
      "RP 3377",
      "SN 6718",
      "ST 21",
      "W 7618;WIN 244",
      "Chloroquine (VAN)",
      "Chloroquine Bis-Phosphoric Acid",
      "Chloroquine FNA (TN)",
      "Chloroquinum [INN-Latin]",
      "Cloroquina [INN-Spanish]",
      "Dawaquin (TN)",
      "RP-3377",
      "Resochin (TN)",
      "SN-7618",
      "ST 21 (Pharmaceutical)",
      "CU-01000012392-2",
      "Chloroquine (USP/INN)",
      "Chloroquine [USAN:INN:BAN]",
      "Ro 01-6014/N2",
      "Malaquin (*Diphosphate*)",
      "{4-[(7-Chloroquinolin-4-Yl)Amino]pentyl}diethylamine",
      "N4-(7-Chloro-4-Quinolinyl)-N1,N1-Diethyl-1,4-Pentanediamine",
      "N4-(7-Chloroquinolin-4-Yl)-N1,N1-Diethylpentane-1,4-Diamine",
      "(+)-Chloroquine",
      "(-)-Chloroquine",
      "(R)-(-)-Chloroquine",
      "3377 RP Opalate",
      "7-Chloro-4-((4-(Diethylamino)-1-Methylbutyl)Amino)Quinoline",
      "7-Chloro-4-[[4-(Diethylamino)-1-Methylbutyl]amino]quinoline",
    ],
  },
  {
    primary_id: "D09EHO",
    names: [
      "ABT-751",
      "141430-65-1",
      "ABT 751",
      "E-7010",
      "ABT751",
      "ABT-751 (E7010)",
      "UNII-WDT5V5OB9F",
      "E 7010",
      "WDT5V5OB9F",
      "N-[2-[(4-HYDROXYPHENYL)AMINO]-3-PYRIDYL]-4-METHOXYBENZENESULFONAMIDE",
      "CHEMBL20684",
      "N-(2-((4-Hydroxyphenyl)Amino)-3-Pyridinyl)-4-Methoxybenzenesulfonamide",
      "N-{2-[(4-Hydroxyphenyl)Amino]pyridin-3-Yl}-4-Methoxybenzenesulfonamide",
      "Benzenesulfonamide,N-[2-[(4-Hydroxyphenyl)Amino]-3-Pyridinyl]-4-Methoxy-",
      "N-(2-(4-Hydroxyphenylamino)Pyridin-3-Yl)-4-Methoxybenzenesulfonamide",
    ],
  },
  {
    primary_id: "D09EHR",
    names: ["5-Fluoro-2-Sulfanyl-Phenylphosphonic Acid", "CHEMBL1170425"],
  },
  {
    primary_id: "D09EIG",
    names: ["Pyz6-D-Trp8-SRIF"],
  },
  {
    primary_id: "D09EIQ",
    names: ["Biaryl Mannoside Derivative 18"],
  },
  {
    primary_id: "D09EIX",
    names: ["PMID26936077-Compound-13"],
  },
  {
    primary_id: "D09EJC",
    names: ["IRX-03", "Anti-S100 Antibody (Neutropenia), Inflammatorx"],
  },
  {
    primary_id: "D09EJG",
    names: ["Ac-WVTHRLAGLLSRSGGVVRKNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D09ELC",
    names: ["AVI-7537"],
  },
  {
    primary_id: "D09ELP",
    names: [
      "Clevidipine Butyrate",
      "Clevidipine",
      "Cleviprex",
      "167221-71-8",
      "Clevelox",
      "Rac-Clevidipine",
      "H 324/38",
      "Methyl (1-Oxobutoxy)Methyl 4-(2,3-Dichlorophenyl)-1,4-Dihydro-2,6-Dime Thyl-3,5-Pyridinedicarboxylate",
      "Methyl (1-Oxobutoxy)Methyl 4-(2,3-Dichlorophenyl)-1,4-Dihydro-2,6-Dimethyl-3,5-Pyridinedicarboxylate",
      "Cleviprex (TN)",
      "3-((Butyryloxy)Methyl) 5-Methyl 4-(2,3-Dichlorophenyl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "METHYL 5-{[(BUTANOYLOXY)METHOXY]CARBONYL}-4-(2,3-DICHLOROPHENYL)-2,6-DIMETHYL-1,4-DIHYDROPYRIDINE-3",
    ],
  },
  {
    primary_id: "D09ELW",
    names: ["Enalkiren"],
  },
  {
    primary_id: "D09EMH",
    names: ["GLPG-0778"],
  },
  {
    primary_id: "D09EMR",
    names: ["Tyr-D-Phe-Gly-D-Trp-Nle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D09EMS",
    names: ["CO-8", "Antidiabetic Compound, C&O Pharmaceutical"],
  },
  {
    primary_id: "D09ENA",
    names: ["Org-30035"],
  },
  {
    primary_id: "D09ENF",
    names: ["PNT-300"],
  },
  {
    primary_id: "D09ENR",
    names: ["Itarnafloxin"],
  },
  {
    primary_id: "D09ENS",
    names: ["Quinazolinone Derivative 3"],
  },
  {
    primary_id: "D09ENZ",
    names: [
      "EPZ-004777",
      "DOT1L Inhibitors (Mixed Lineage Leukemia)",
      "DOT1L Inhibitors (Leukemia), Epizyme/GSK",
      "DOT1L Inhibitors (Mixed Lineage Leukemia), Epizyme/GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D09EOZ",
    names: ["CX-99"],
  },
  {
    primary_id: "D09EPG",
    names: ["Heteroaryl-Azepine Derivative 12"],
  },
  {
    primary_id: "D09EPU",
    names: ["18F-BAY-85-8050"],
  },
  {
    primary_id: "D09EPX",
    names: ["MitoLinoleic"],
  },
  {
    primary_id: "D09EPZ",
    names: ["4-Pyridone Derivative 1"],
  },
  {
    primary_id: "D09EQA",
    names: ["SpirH"],
  },
  {
    primary_id: "D09EQN",
    names: ["4-(3,5-Dimethylbenzylthio)-2-Aminobutanoic Acid", "CHEMBL380950", "BDBM50179702"],
  },
  {
    primary_id: "D09EQS",
    names: ["ISIS 16123"],
  },
  {
    primary_id: "D09EQZ",
    names: ["XN-0502"],
  },
  {
    primary_id: "D09ERP",
    names: ["3-Methoxytyramine", "3-Methoxy-4-Hydroxyphenethylamine"],
  },
  {
    primary_id: "D09ESF",
    names: ["GNF-PF-78"],
  },
  {
    primary_id: "D09ESW",
    names: ["Anipamil"],
  },
  {
    primary_id: "D09ETC",
    names: ["Cyclohexyl-(9-Ethyl-9H-Purin-6-Yl)-Amine", "CHEMBL321753", "BDBM50009690"],
  },
  {
    primary_id: "D09ETH",
    names: ["E-7050", "C-Met And VEGF-2 Tyrosine Kinase Inhibitor (Oral, Cancer), Eisai"],
  },
  {
    primary_id: "D09ETU",
    names: ["N-(3-(5-Methoxy-2-Propoxyphenyl)Propyl)Acetamide", "CHEMBL1091894"],
  },
  {
    primary_id: "D09EUH",
    names: ["FUTOENONE"],
  },
  {
    primary_id: "D09EVP",
    names: ["NNZ-4921"],
  },
  {
    primary_id: "D09EVY",
    names: [
      "4-Methyl-5-Piperidin-4-Yl-Isoxazol-3-Ol",
      "CHEMBL144416",
      "CHEMBL553129",
      "439944-42-0",
      "3(2H)-Isoxazolone, 4-Methyl-5-(4-Piperidinyl)-",
      "BDBM50113803",
      "BDBM50020029",
      "AKOS022705739",
      "4-Methyl-5-(4-Piperidinyl)Isoxazole-3-Ol",
    ],
  },
  {
    primary_id: "D09EVZ",
    names: [
      "BMS-488043",
      "AIDS171276",
      "BMS043",
      "BMS488043",
      "BMS-043",
      "1-(4-Benzoylpiperazin-1-Yl)-2-(4,7-Dimethoxy-1H-Pyrrolo[2,3-C]pyridin-3-Yl)Ethane-1,2-Dione",
    ],
  },
  {
    primary_id: "D09EWC",
    names: ["PMID25991433-Compound-L"],
  },
  {
    primary_id: "D09EWK",
    names: ["PRO-053"],
  },
  {
    primary_id: "D09EXD",
    names: ["Rituximab"],
  },
  {
    primary_id: "D09EXQ",
    names: ["PMID26651364-Compound-8a"],
  },
  {
    primary_id: "D09EYJ",
    names: ["IPS Cell Program"],
  },
  {
    primary_id: "D09EYN",
    names: ["RSV F Protein Vaccine"],
  },
  {
    primary_id: "D09EYO",
    names: [
      "2-(4-Fluoro-3-Phenoxy-Benzoylamino)-Benzoic Acid",
      "CHEMBL180428",
      "BDBM23580",
      "2-(4-Fluoro-3-Phenoxybenzoylamino)Benzoic Acid",
      "(3-Phenoxybenzoylamino)Benzoic Acid Deriv., 23a",
      "2-[(4-Fluoro-3-Phenoxybenzene)Amido]benzoic Acid",
    ],
  },
  {
    primary_id: "D09EZU",
    names: ["Myrrhanol A"],
  },
  {
    primary_id: "D09FAD",
    names: ["N-Isobutyl-2-Oxo-2H-Chromene-3-Carboxamide"],
  },
  {
    primary_id: "D09FAF",
    names: ["N-Unsubstituted Carbamate Esters"],
  },
  {
    primary_id: "D09FAO",
    names: ["PD 154740"],
  },
  {
    primary_id: "D09FAU",
    names: ["(RS)-Tacrine(10)-Hupyridone", "CHEMBL76658", "BDBM50080157"],
  },
  {
    primary_id: "D09FAZ",
    names: [
      "Azacitidine",
      "Azacitidina",
      "Azacitidinum",
      "Azacytidine",
      "Ladakamycin",
      "Mylosar",
      "Vidaza",
      "Mylo Sar",
      "Pharmion Brand Of Azacitidine;A 2385",
      "Antibiotic U 18496",
      "U 18496",
      "Wr 183027",
      "Azacitidina [INN-Spanish]",
      "Azacitidine [USAN:INN]",
      "Azacitidinum [INN-Latin]",
      "NS-17",
      "Pyrimidine Antimetabolite: Inhibits Nucleic Acid Replication",
      "U-18496",
      "Vidaz (TN)",
      "Vidaza (TN)",
      "WR-183027",
      "Azacitidine (JAN/USAN/INN)",
      "S-Triazin-2(1H)-One, 4-Amino-1-Beta-D-Ribofuranosyl-(8CI)",
      "2-(Beta-D-Ribofuranosyl)-4-Amino-1,3,5-Triazin-2-One",
      "4-Amino-1-(Beta-D-Ribofuranosyl)-1,3,5-Triazin-2(1H)-One",
      "4-Amino-1-Beta-D-Ribofuranosyl-1,3,5-Traizin-2(1H)-One",
      "4-Amino-1-Beta-D-Ribofuranosyl-1,3,5-Triazine-2(1H)-One",
      "4-Amino-1-Beta-D-Ribofuranosyl-S-Triazin-2(1H)-One",
      "4-Amino-1-Beta-D-Ribofuranosyl-1,3,5-Triazin-2(1H)-One",
      "4-Amino-1-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-1,3,5-Triaz",
      "4-Amino-1-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-1,3,5-Triazin-2-One",
      "4-Amino-1-Beta-L-Ribofuranosyl-1,3,5-Triazin-2(1H)-One",
      "5 AZC",
      "5 Azacytidine",
      "5-AC",
      "5-AZAC",
      "5-AZCR",
      "5-Aza-CR",
      "5-Azacitidine",
      "5-Azacytidine",
      "5-Azacytidine, Mylosar, Ladakamycin, Vidaza, Azacitidine",
      "5AzaC",
    ],
  },
  {
    primary_id: "D09FBD",
    names: ["MOLEPHANTIN"],
  },
  {
    primary_id: "D09FBJ",
    names: ["Pyrrolo-Pyrimidine Derivative 2"],
  },
  {
    primary_id: "D09FBN",
    names: ["4-(6-Morpholino-9H-Purin-2-Yl)Phenol"],
  },
  {
    primary_id: "D09FDN",
    names: ["IMA201"],
  },
  {
    primary_id: "D09FDV",
    names: ["[35S]ACPPB", "[35S]-ACPPB"],
  },
  {
    primary_id: "D09FDZ",
    names: [
      "7,8,4'-Trihydroxyisoflavone",
      "8-Hydroxydaidzein",
      "75187-63-2",
      "4',7,8-Trihydroxyisoflavone",
      "7,8-Dihydroxy-3-(4-Hydroxyphenyl)Chromen-4-One",
      "7,8-Dihydroxy-3-(4-Hydroxy-Phenyl)-Chromen-4-One",
      "7,8-Dihydroxy-3-(4-Hydroxyphenyl)-4H-Chromen-4-One",
      "NSC678112",
      "CHEMBL242739",
      "NSC 678112",
      "4H-1-Benzopyran-4-One, 7,8-Dihydroxy-3-(4-Hydroxyphenyl)-",
      "7,4'-Trihydroxyisoflavone",
      "AC1NV1M1",
      "SCHEMBL571127",
      "7,8,4''-Trihydroxyisoflavone",
      "4'',7,8-Trihydroxyisoflavone",
      "CTK5E1244",
      "DTXSID20226169",
      "MolPort-000-165-583",
      "BMZFZTMWBCFKSS-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D09FEC",
    names: [
      "1-Cyclohexyl-3-(4-Methoxy-Phenyl)-Urea",
      "1-Cyclohexyl-3-(4-Methoxyphenyl)Urea",
      "CHEMBL191968",
      "N-Cyclohexyl-N'-(4-Methoxyphenyl)Urea",
      "148806-87-5",
      "Urea, N-Cyclohexyl-N'-(4-Methoxyphenyl)-",
      "AC1MQ7FX",
      "ACMC-20n5j8",
      "AC1Q4DC4",
      "SCHEMBL12196154",
      "CTK0E8825",
      "DTXSID20392116",
      "ZINC395455",
      "STL268801",
      "BDBM50167053",
      "AKOS003408864",
      "N-(4-Methoxyphenyl)-N'-Cyclohexylurea",
      "MCULE-4247297698",
      "KB-104482",
      "US8815951, 67",
    ],
  },
  {
    primary_id: "D09FEN",
    names: ["PRO-515"],
  },
  {
    primary_id: "D09FFE",
    names: ["H-89"],
  },
  {
    primary_id: "D09FFS",
    names: ["FT-071"],
  },
  {
    primary_id: "D09FGD",
    names: ["Tavokinogene Telsaplasmid"],
  },
  {
    primary_id: "D09FGL",
    names: ["MCC950"],
  },
  {
    primary_id: "D09FGM",
    names: ["ZP-10719"],
  },
  {
    primary_id: "D09FGQ",
    names: [
      "E-64",
      "66701-25-5",
      "CHEMBL189382",
      "AC1NEEQI",
      "SCHEMBL13283063",
      "HMS3369L13",
      "Trans-Epoxysuccinyl-Leucylamido-[4-Guanidino]butane",
      "[L-3-Trans-Carboxy-Oxiran-2-Carbonyl]-Leu-Agmatin",
      "Trans-Epoxysuccinyl-Leucylamido- [4-Guanidino]butane",
      "[L -3-Trans-Carboxy- Oxiran-2-Carbonyl]-Leu-Agmatin",
      "3-({1-[(4-Carbamimidamidobutyl)Carbamoyl]-3-Methylbutyl}carbamoyl)Oxirane-2-Carboxylic Acid",
      "3-[[1-[4-(Diaminomethylideneamino)Butylamino]-4-Methyl-1-Oxopentan-2-Yl]carbamoyl]oxirane-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09FGW",
    names: ["Pyrimidinyl Compound 4"],
  },
  {
    primary_id: "D09FGX",
    names: [
      "ELACRIDAR",
      "Elacridar Hydrochloride",
      "143851-98-3",
      "Elacridar HCl",
      "Elacridar (Hydrochloride)",
      "Gf 120918a",
      "UNII-NX2BHH1A5B",
      "NX2BHH1A5B",
      "Elacridar Hydrochloride [USAN]",
      "GF-120918A",
      "Elacridar Hydrochloride (USAN)",
      "GF 120918",
      "AC1Q3EOG",
      "AC1L55DX",
      "C34H34ClN3O5",
      "SCHEMBL15847793",
      "CHEMBL2074730",
      "AOB5938",
      "MolPort-023-332-877",
      "BCP14056",
      "7066AA",
      "AKOS016005297",
      "CS-1113",
      "ACN-041487",
      "4CA-0489",
      "HY-50880",
      "AC-30266",
      "FT-0696337",
      "W-5457",
      "D03968",
      "N-[4-[2-(3,4-Dihydro-6,7-Dimethoxy-2(1H)-Isoquinolinyl)Ethyl]phenyl]-9,10-Dihydro-5-Methoxy-9-Oxo-4-Acridinecarboxamide Hydro",
    ],
  },
  {
    primary_id: "D09FHA",
    names: [
      "(R)-2-(4-Isobutyl-Phenyl)-N-Methoxy-Propionamide",
      "CHEMBL190899",
      "SCHEMBL13952025",
      "Benzeneacetamide,N-Methoxy-Alpha-Methyl-4-(2-Methylpropyl)-,(AlphaR)-",
    ],
  },
  {
    primary_id: "D09FHE",
    names: ["NBTGR"],
  },
  {
    primary_id: "D09FHM",
    names: ["Fluzone QIV IM"],
  },
  {
    primary_id: "D09FHY",
    names: ["HIV Multiantigen Vaccine"],
  },
  {
    primary_id: "D09FIC",
    names: [
      "1-(2-(2-(DIFLUOROMETHOXY)PHENYL)-1-PHENYLETHYL)PIPERAZINE (ENANTIOMERIC MIX)",
      "CHEMBL1083708",
      "SCHEMBL5447686",
      "BDBM50319818",
      "1-(2-(2-(Difluoromethoxy)Phenyl)-1-Phenylethyl)Piperazine",
      "(-)-1-(2-(2-(Difluoromethoxy)Phenyl)-1-Phenylethyl)Piperazine",
    ],
  },
  {
    primary_id: "D09FJB",
    names: [
      "Tolazamide",
      "Diabewas",
      "Norglycin",
      "Tolamide",
      "Tolanase",
      "Tolazamida",
      "Tolazamidum",
      "Tolazolamide",
      "Tolinase",
      "Pharmacia Brand Of Tolazamide",
      "T 2408",
      "U 17835",
      "Tolazamida [INN-Spanish]",
      "Tolazamidum [INN-Latin]",
      "Tolinase (TN)",
      "U-17835",
      "Tolazamide (JP15/USP/INN)",
      "Tolazamide [USAN:INN:BAN:JAN]",
      "N-(P-Toluenesulfonyl)-N'-Hexamethyleniminourea",
      "N-[(Azepan-1-Ylamino)Carbonyl]-4-Methylbenzenesulfonamide",
      "N-(((Hexahydro-1H-Azepin-1-Yl)Amino)Carbonyl)-4-Methylbenzenesulfonamide",
      "Benzenesulfonamide, {N-[[(Hexahydro-1H-Azepin-1-Yl)Amino]carbonyl]-4-Methyl-}",
      "N-(((Hexahydro-1H-Azepin-1-Yl)-Amino)Carbonyl)-4-Methylbenzenesulfonamide",
      "1-(((((4-Methylphenyl)Sulfonyl)Amino)Carbonyl)Amino)Azepane",
      "1-(Hexahydro-1-Azepinyl)-3-P-Tolylsulfonylurea",
      "1-(Hexahydro-1H-Azepin-1-Yl)-3-(P-Toluenesulfonyl)Urea",
      "1-(Hexahydro-1H-Azepin-1-Yl)-3-(P-Tolylsulfonyl)Urea",
      "1-(Azepan-1-Yl)-3-(4-Methylphenyl)Sulfonylurea",
      "3-Azepan-1-Yl-1-(4-Methylphenyl)Sulfonyl-Urea",
      "4-(P-Tolylsulfonyl)-1,1-Hexamethylene",
      "4-(P-Tolylsulfonyl)-1,1-Hexamethylenesemicarbazide",
      "59866P",
    ],
  },
  {
    primary_id: "D09FJH",
    names: ["CFC-240", "CR-240"],
  },
  {
    primary_id: "D09FJW",
    names: [
      "Carpropamid",
      "104030-54-8",
      "2,2-Dichloro-N-[1-(4-Chlorophenyl)Ethyl]-1-Ethyl-3-Methylcyclopropanecarboxamide",
      "2,2-Dichloro-N-[1-(4-Chlorophenyl)Ethyl]-1-Ethyl-3-Methylcyclopropane-1-Carboxamide",
      "Carpropamid [ISO]",
      "AC1L4B6R",
      "SCHEMBL22262",
      "CHEBI:3434",
      "DTXSID4057922",
      "RXDMAYSSBPYBFW-UHFFFAOYSA-N",
      "KTU-3616",
      "AKOS015888200",
      "Cyclopropanecarboxamide, 2,2-Dichloro-N-(1-(4-Chlorophenyl)Ethyl)-1-Ethyl-3-Methyl-",
      "AN-34090",
      "TR-000928",
      "Carpropamid, PESTANAL(R), Analytical Standard",
      "C10932",
      "SR-01000883725",
      "SR-01000883725-1",
      "I01-10136",
    ],
  },
  {
    primary_id: "D09FJY",
    names: ["4-Phenyl-1-(Phenyl(O-Tolyl)Methyl)Piperidin-4-Ol", "CHEMBL388123"],
  },
  {
    primary_id: "D09FKC",
    names: ["Hypoglycemic Agent"],
  },
  {
    primary_id: "D09FKY",
    names: ["GED-0301"],
  },
  {
    primary_id: "D09FLC",
    names: ["17-Methylmorphinan-3-Yl 4-Iodophenyl Carbamate", "CHEMBL568989"],
  },
  {
    primary_id: "D09FMK",
    names: [
      "AM-630",
      "164178-33-0",
      "AM630",
      "Iodopravadoline",
      "(6-Iodo-2-Methyl-1-(2-Morpholinoethyl)-1H-Indol-3-Yl)(4-Methoxyphenyl)Methanone",
      "AM 630",
      "UNII-U1LNJ6NBKA",
      "U1LNJ6NBKA",
      "CHEMBL181633",
      "[6-Iodo-2-Methyl-1-(2-Morpholin-4-Ylethyl)Indol-3-Yl]-(4-Methoxyphenyl)Methanone",
      "6-Iodopravadoline",
      "1-(2-(Morpholin-4-Yl)Ethyl)-2-Methyl-3-(4-Methoxybenzoyl)-6-Iodoindole",
      "1-[2-(Morpholin-4-Yl)Ethyl]-2-Methyl-3-(4-Methoxybenzoyl)-6-Iodoindole",
      "Tocris-1120",
      "AC1N7ICI",
      "Iodopravadoline(AM-630)",
      "Iodopravadoline (AM630)",
      "GTPL750",
      "MLS006010265",
    ],
  },
  {
    primary_id: "D09FMO",
    names: ["Cavatak RNA", "Coxsackievirus A21 (CVA21) RNA (Cancer), Viralytics"],
  },
  {
    primary_id: "D09FMS",
    names: ["PMID27376512-Compound-Table1Example5"],
  },
  {
    primary_id: "D09FMZ",
    names: [
      "3-Benzyl-4-Hydroxy-2H-Chromen-2-One",
      "4-Hydroxy-3-Benzylcoumarin",
      "15074-18-7",
      "3-Benzyl-4-Hydroxy-Chromen-2-One",
      "3-Benzyl-4-Hydroxycoumarin",
      "3-Benzyl-4-Hydroxy-2h-1-Benzopyran-2-One",
      "3Bz-4OHBzpyran2one",
      "4-Hydroxy-Coumarin 8",
      "CBMicro_020160",
      "AC1L9T1B",
      "3-Benzyl-4-Hydroxy-Cumarin",
      "Oprea1_155335",
      "3-Benzyl-4-Hydroxy-Coumarin",
      "Oprea1_579559",
      "3-Benzyl-4 -Hydroxycoumarin",
      "SCHEMBL1648186",
      "CHEMBL574145",
      "BDBM2566",
      "JMC527142 Compound 27",
      "3-Benzyl-2-Hydroxychromen-4-One",
      "DTXSID60715769",
      "4-Hydroxy-3-Benzylchromen-2-One",
      "MolPort-001-017-230",
    ],
  },
  {
    primary_id: "D09FNI",
    names: ["Nonocog Beta Pegol"],
  },
  {
    primary_id: "D09FOD",
    names: [
      "D-Arginine",
      "157-06-2",
      "H-D-Arg-OH",
      "(R)-2-Amino-5-Guanidinopentanoic Acid",
      "D-Arginin",
      "D(-)-Arginine",
      "D-Arg",
      "D-(-)-Arginine",
      "D-2-Amino-5-Guanidinovaleric Acid",
      "UNII-R54Z304Z7C",
      "(-)-D-Arginine Hydrate",
      "EINECS 205-866-5",
      "(2R)-2-Amino-5-Carbamimidamidopentanoic Acid",
      "CHEBI:15816",
      "R54Z304Z7C",
      "MFCD00063116",
      "(2R)-2-Amino-5-Guanidinopentanoic Acid",
      "ST069338",
      "(2R)-2-Amino-5-(Carbamimidamido)Pentanoic Acid",
      "(2R)-2-Amino-5-[(Diaminomethylidene)Amino]pentanoic Acid",
      "C6H14N4O2",
      "1-Deamino-D-Arginine",
      "PubChem7932",
      "D-Arginine, Free Ba",
    ],
  },
  {
    primary_id: "D09FOM",
    names: ["PMID30259754-Compound-Pyrrole[2,3-D]azepines"],
  },
  {
    primary_id: "D09FOP",
    names: ["MCP-202", "Delta Receptor Agonist (Overactive Bladder, Oral), Mt Cook Pharma"],
  },
  {
    primary_id: "D09FOQ",
    names: ["Bantag-1", "GTPL6176"],
  },
  {
    primary_id: "D09FPV",
    names: ["PMID29473428-Compound-4"],
  },
  {
    primary_id: "D09FQB",
    names: ["UR-12460"],
  },
  {
    primary_id: "D09FQL",
    names: ["ENDIANDRIN A"],
  },
  {
    primary_id: "D09FQP",
    names: ["PMID25684022-Compound-US20120277229 39(1.1)"],
  },
  {
    primary_id: "D09FQS",
    names: ["FG-3622"],
  },
  {
    primary_id: "D09FQT",
    names: ["7alpha-N3-Ginkgolide B", "7alpha-N3-GB"],
  },
  {
    primary_id: "D09FQW",
    names: ["THVD-201"],
  },
  {
    primary_id: "D09FRL",
    names: ["PD123177"],
  },
  {
    primary_id: "D09FRY",
    names: ["ANA-06", "Complement System Modulator, AnaptysBio"],
  },
  {
    primary_id: "D09FSG",
    names: ["Golimumab"],
  },
  {
    primary_id: "D09FTJ",
    names: ["TTP-054"],
  },
  {
    primary_id: "D09FTL",
    names: [
      "(+/-)-Threo-N-(3-Methylfuran)Methylphenidate",
      "CHEMBL1254842",
      "BDBM50327132",
      "(AlphaR)-Alpha-[(2R)-1-(3-Furanylmethyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D09FTX",
    names: ["Respiratory System Therapeutics"],
  },
  {
    primary_id: "D09FTZ",
    names: ["(4-Phenylethynyl-Cyclohex-3-Enyl)-Dipropyl-Amine", "CHEMBL166325"],
  },
  {
    primary_id: "D09FUO",
    names: ["(1-Phenyl-Propyl)-(9-Phenyl-9H-Purin-6-Yl)-Amine", "CHEMBL96014"],
  },
  {
    primary_id: "D09FVG",
    names: ["AKUAMMINE"],
  },
  {
    primary_id: "D09FVR",
    names: ["Pyrrolo-Pyrrolone Derivative 3"],
  },
  {
    primary_id: "D09FWK",
    names: [
      "E-003",
      "Bcl-XL Modulators (Oral, Cancer)",
      "Ensemblin Macrocycle (Oral, Cancer), Ensemble Therapeutics",
      "Bcl-XL Modulators (Oral, Cancer), Ensemble",
    ],
  },
  {
    primary_id: "D09FWP",
    names: [
      "6-(2-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL195058",
      "6-(2-Hydroxyphenyl)-2-Naphthol",
      "2-Methyl-6-O-Tolylnaphthalene",
      "SCHEMBL3016027",
      "SMFVAKMYNVSVKJ-UHFFFAOYSA-N",
      "BDBM50168378",
    ],
  },
  {
    primary_id: "D09FWS",
    names: ["4-Ethyl-2-(4-Hydroxy-Phenyl)-Quinolin-6-Ol", "4-Ethyl-2-(4-Hydroxyphenyl)Quinolin-6-Ol"],
  },
  {
    primary_id: "D09FXD",
    names: ["K-Ras(G12C) Inhibitor 9"],
  },
  {
    primary_id: "D09FYQ",
    names: ["Sabcomeline"],
  },
  {
    primary_id: "D09FYW",
    names: ["PF-06743649"],
  },
  {
    primary_id: "D09FZD",
    names: [
      "N-(1-Phenethylpiperidin-4-Yl)-2-Naphthamide",
      "CHEMBL388620",
      "MolPort-027-676-242",
      "ZINC27939121",
      "BDBM50203471",
      "AKOS034266810",
      "MCULE-8266051883",
      "Z384294180",
      "N-[1-(2-Phenylethyl)Piperidin-4-Yl]naphthalene-2-Carboxamide",
    ],
  },
  {
    primary_id: "D09FZF",
    names: ["Diterpenoid Derivative 1"],
  },
  {
    primary_id: "D09FZI",
    names: ["TALTOBULIN"],
  },
  {
    primary_id: "D09FZM",
    names: [
      "BreMel/RGel",
      "BreMel/RGel (Melanoma/Lobular Breast Cancer)",
      "Aka ScFvMel/RGel (Melanoma/Lobular Breast Cancer), Targa Therapeutics",
      "BreMel/RGel (Melanoma/Lobular Breast Cancer), Targa Therapeutics",
      "ScFvMel/RGel (Melanoma/ Breast Cancer), Targa Therapeutics",
      "ScFvMel/RGel (Melanoma/Lobular Breast Cancer), Targa Therapeutics",
      "ZME-018 Antibody Fragment/RGel (Melanoma/ Breast Cancer), Targa Therapeutics",
    ],
  },
  {
    primary_id: "D09FZW",
    names: ["2-(Trifluoromethoxy)-9H-Carbazole", "CHEMBL1171980"],
  },
  {
    primary_id: "D09GAG",
    names: ["DC-159a", "8-Methoxyfluoroquinolone (Oral, Bacterial Infection), Daiichi Sankyo"],
  },
  {
    primary_id: "D09GBA",
    names: ["Ethyl 1-[(1H-Benzotriazol-5(6)-Yl)Sulfonyl]-1H-Pyrrole-2-Carboxylate"],
  },
  {
    primary_id: "D09GBL",
    names: ["Isopulegol", "Coolact P"],
  },
  {
    primary_id: "D09GBM",
    names: ["GD-AAV8"],
  },
  {
    primary_id: "D09GBZ",
    names: ["[3H]SCH-23390", "[3H]-SCH23390"],
  },
  {
    primary_id: "D09GCE",
    names: ["(S)-N2-{4-[(4-Nitrobenzyl)Oxy]benzyl}serinamide", "CHEMBL243079"],
  },
  {
    primary_id: "D09GDD",
    names: ["Regorafenib"],
  },
  {
    primary_id: "D09GDY",
    names: [
      "GNI-103",
      "ED-002",
      "EP-2220",
      "Theradigm-HCV",
      "Hepatitis C Virus Vaccine (Therapeutic), GENimmune",
      "HCV Vaccine (Therapeutic), IDM/Genencor",
      "Hepatitis C Virus Vaccine (Therapeutic), IDM/Genencor",
      "HCV Vaccine (Therapeutic), Innogenetics/ IDM /Pharmexa",
      "Hepatitis C Virus Vaccine (Therapeutic), Innogenetics/ IDM/ Pharmexa",
    ],
  },
  {
    primary_id: "D09GEH",
    names: ["1-(1-Biphenyl-4-Yl-Propyl)-1H-Imidazole", "CHEMBL497500"],
  },
  {
    primary_id: "D09GEK",
    names: [
      "ASP3026",
      "1097917-15-1",
      "ASP-3026",
      "ASP 3026",
      "N2-[2-Methoxy-4-[4-(4-Methyl-1-Piperazinyl)-1-Piperidinyl]phenyl]-N4-[2-[(1-Methylethyl)Sulfonyl]phenyl]-1,3,5-Triazine-2,4-Diamine",
      "UNII-HP4L6MXF10",
      "HP4L6MXF10",
      "2-N-[2-Methoxy-4-[4-(4-Methylpiperazin-1-Yl)Piperidin-1-Yl]phenyl]-4-N-(2-Propan-2-Ylsulfonylphenyl)-1,3,5-Triazine-2,4-Diamine",
      "MGGBYMDAPCCKCT-UHFFFAOYSA-N",
      "MLS006011176",
      "GTPL7740",
      "SCHEMBL2827739",
      "CHEMBL3545360",
      "C29H40N8O3S",
      "QCR-144",
      "EX-A140",
      "DTXSID90149038",
      "AOB6601",
      "MolPort-028-720-342",
      "BCP06436",
      "2229AH",
      "ZINC68120928",
      "S8054",
    ],
  },
  {
    primary_id: "D09GFF",
    names: ["PMID28394193-Compound-27"],
  },
  {
    primary_id: "D09GFL",
    names: ["Trihexyphenidyl"],
  },
  {
    primary_id: "D09GGJ",
    names: [
      "CVT-124",
      "Adentri",
      "Naxifylline",
      "ENX Cpd",
      "BG 9719",
      "BG9719",
      "CVT 124",
      "BG-9719",
      "1,3-ENX",
      "1,3-Dipropyl-8-(2-(5,6-Epoxy)Norbornyl)Xanthine",
      "8-((2S,5,6-Exo)-5,6-Epoxy-2-Norbonyl)-1,3-Dipropylxanthine",
    ],
  },
  {
    primary_id: "D09GGO",
    names: ["A-252444.0", "A-155918", "A-205804", "A-241548"],
  },
  {
    primary_id: "D09GGY",
    names: ["Zinc Acetate Vaginal Gel"],
  },
  {
    primary_id: "D09GHB",
    names: ["RO-4987655", "CH-4987655", "RO-04927350", "MEK Inhibitors (Solid Tumor), Roche"],
  },
  {
    primary_id: "D09GHO",
    names: ["LY-334370"],
  },
  {
    primary_id: "D09GIA",
    names: ["SAR391786"],
  },
  {
    primary_id: "D09GIL",
    names: ["5-Methyl-5-Phenyl-Pyrimidine-2,4,6-Trione"],
  },
  {
    primary_id: "D09GIP",
    names: ["QRS-5-005"],
  },
  {
    primary_id: "D09GJI",
    names: ["7-(1-(1H-Imidazol-1-Yl)Ethyl)-9H-Fluoren-2-Ol", "CHEMBL505550"],
  },
  {
    primary_id: "D09GJQ",
    names: ["Tricyclic Phytocannabinoid Derivative 1"],
  },
  {
    primary_id: "D09GKA",
    names: [
      "2-(4-Methoxy-Benzyl)Isoquinoline-1,3,4-Trione",
      "CHEMBL205983",
      "SCHEMBL13442854",
      "Isoquinoline-1,3,4-Trione 2e",
      "CTK7A3832",
      "BDBM10252",
      "AKOS015966190",
      "2-(4-METHOXY-BENZYL)-ISOQUINOLINE-1,3,4-TRIONE",
    ],
  },
  {
    primary_id: "D09GKY",
    names: [
      "N-Cyclohexyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL591234",
      "N-Cyclohexyl-10H-Phenothiazine-10-Carboxamide",
      "N-Cyclohexylphenothiazine-10-Carboxamide",
      "AC1LE3I3",
      "Oprea1_381015",
      "ZINC91236",
      "MolPort-002-173-208",
      "BDBM50308400",
      "STK225048",
      "AKOS001676630",
      "MCULE-5781141750",
      "CCG-113839",
      "N-Cyclohexylphenothiazin-10-Ylcarboxamide",
      "371937-04-1",
      "ST51023365",
      "AB00099848-01",
      "N-Cyclohexyl-1''H-Phenothiazine-1''-Carboxamide",
      "SR-01000506168",
    ],
  },
  {
    primary_id: "D09GLA",
    names: ["WAY-151693"],
  },
  {
    primary_id: "D09GLS",
    names: ["GSK1614235"],
  },
  {
    primary_id: "D09GMC",
    names: ["GX-101"],
  },
  {
    primary_id: "D09GMG",
    names: [
      "3-Methoxy-N-(6-Methylpyridin-2-Yl)Benzamide",
      "CHEMBL211801",
      "AC1LBN1X",
      "TimTec1_005849",
      "Oprea1_873046",
      "Oprea1_343584",
      "ZINC92708",
      "RGDXTZSZZQXINX-UHFFFAOYSA-N",
      "MolPort-002-713-956",
      "HMS1550J19",
      "BDBM50186321",
      "STK696160",
      "AKOS003855494",
      "MCULE-9890451107",
      "NCGC00173451-01",
      "ST090431",
      "EU-0053201",
      "3-Methoxy-N-(6-Methyl-2-Pyridinyl)Benzamide #",
      "3-Methoxy-N-(6-Methyl-Pyridin-2-Yl)-Benzamide",
      "(3-Methoxyphenyl)-N-(6-Methyl(2-Pyridyl))Carboxamide",
    ],
  },
  {
    primary_id: "D09GMH",
    names: [
      "Hepatitis B Virus Vaccine",
      "Antigen, Combiotech",
      "HBV Vaccine, Combiotech",
      "Hepatitis B Virus Vaccine, Combiotech",
      "Vaccine (HBV), Combiotech",
      "Vaccine (Hepatitis B Virus), Combiotech",
    ],
  },
  {
    primary_id: "D09GNM",
    names: ["PFI-3"],
  },
  {
    primary_id: "D09GOS",
    names: [
      "Clotrimazole",
      "Canesten",
      "Canestene",
      "Canestine",
      "Canifug",
      "Chlotrimazole",
      "Cimitidine",
      "Clomatin",
      "Clotrimaderm",
      "Clotrimazol",
      "Clotrimazolum",
      "Clotrimeizol",
      "Cutistad",
      "Empecid",
      "Esparol",
      "FemCare",
      "Gynix",
      "Kanesten",
      "Klotrimazole",
      "Lotrimax",
      "Lotrimin",
      "Monobaycuten",
      "Mycelax",
      "Mycelex",
      "Mycofug",
      "Mycosporin",
      "Mykosporin",
      "Nalbix",
      "Pedisafe",
      "Rimazole",
      "Stiemazol",
      "Tibatin",
      "Trimysten",
      "Canesten Cream",
      "Canesten Solution",
      "Clotrimazole Schering Brand",
      "Desamix F",
      "Fem Care",
      "Gyne Lotrimin",
      "Lotrimin AF Cream",
      "Lotrimin AF Lotion",
      "Lotrimin AF Solution",
      "Lotrimin Af",
      "Lotrimin Cream",
      "Lotrimin Lotion",
      "Lotrimin Solution",
      "Mycelex Cream",
      "Mycelex G",
      "Mycelex OTC",
      "Mycelex Solution",
      "Mycelex Troches",
      "Mycelex Twin Pack",
      "Myclo Cream",
      "Myclo Solution",
      "Myclo Spray Solution",
      "Schering Brand Of Clotrimazole",
      "B 5097",
      "Bay B 9057",
      "Bayer Brand 1 Of Clotrimazole",
      "Bayer Brand 2 Of Clotrimazole",
      "C 6019",
      "FB 5097",
      "FB B 5097",
      "Mycelex 7",
      "Trivagizole 3",
      "Bay-B 5097",
      "Candid Vaginal (TN)",
      "Candinil (TN)",
      "Canesten (TN)",
      "Canesten 1-Day Therapy",
      "Canesten 3-Day Therapy",
      "Canesten 6-Day Therapy",
      "Clobrate VT (TN)",
      "Clotrimazol [INN-Spanish]",
      "Clotrimazolum [INN-Latin]",
      "DRG-0072",
      "Gino-Lotremine",
      "Gyne-Lotrimin",
      "Gyne-Lotrimin 3",
      "Gyne-Lotrimin 3 Combination Pack",
      "Gyne-Lotrimin Combination Pack",
      "Lotrimin (TN)",
      "Lotrimin AF Jock-Itch Cream",
      "Mono-Baycuten",
      "Mycelex (TN)",
      "Mycelex-7",
      "Mycelex-7 Combination Pack",
      "Mycelex-G",
      "Mycelex: MycosporinRimazole",
      "Myclo-Gyne",
      "Neo-Zol Cream",
      "Pan-Fungex",
      "Cancap-VT (TN)",
      "Candid - V Gel (TN)",
      "Canesten 1-Day Cream Combi-Pak",
      "Canesten Combi-Pak 1-Day Therapy",
      "Canesten Combi-Pak 3-Day Therapy",
      "Clotrimazole (JP15/USP/INN)",
      "Clotrimazole [USAN:INN:BAN:JAN]",
      "(Chlorotrityl)Imidazole",
      "1-(O-Chlorotrityl)Imidazole",
    ],
  },
  {
    primary_id: "D09GPB",
    names: [
      "AZD2066",
      "AZD-2066",
      "UNII-MQ908Y1ZB2",
      "MQ908Y1ZB2",
      "934282-55-0",
      "AZD 2066",
      "SXWHYTICXCLKDG-GFCCVEGCSA-N",
      "SCHEMBL1848325",
      "CHEMBL3545164",
      "MolPort-042-624-538",
      "ZINC34885049",
      "AKOS027470229",
      "DB12644",
      "Pyridine, 4-(5-((1R)-1-(5-(3-Chlorophenyl)-3-Isoxazolyl)Ethoxy)-4-Methyl-4H-1,2,4-Triazol-3-Yl)-",
      "J3.560.339E",
      "5-(3-Chlorophenyl)-3-[(1r)-1-[[4-Methyl-5-(4-Pyridyl)-1,2,4-Triazol-3-Yl]oxy]ethyl]isoxazole",
      "4-[5-[(1R)-1-[5-(3-Chlorophenyl)-3-Isoxazolyl]ethoxy]-4-Methyl-4H-1,2,4-Triazol-3-Yl]pyridine",
    ],
  },
  {
    primary_id: "D09GPI",
    names: ["FRM-6308"],
  },
  {
    primary_id: "D09GPV",
    names: ["Spathodic Acid"],
  },
  {
    primary_id: "D09GPZ",
    names: ["Multi-Targeted Therapeutics"],
  },
  {
    primary_id: "D09GQE",
    names: ["3-[5-(4-Hydroxyphenyl)-2-Thienyl]-5-Methylphenol", "CHEMBL583519", "SCHEMBL1180575"],
  },
  {
    primary_id: "D09GQF",
    names: [
      "5,N-Dihydroxythalidomide",
      "5,1'-Dihydroxythalidomide",
      "5,1'-Dihydroxy Thalidomide",
      "5,1'-Dihydroxy Thalidomide, (+/-)-",
      "CHEMBL479546",
      "1H-Isoindole-1,3(2H)-Dione, 5-Hydroxy-2-(1-Hydroxy-2,6-Dioxo-3-Piperidinyl)-",
      "478785-12-5",
    ],
  },
  {
    primary_id: "D09GQI",
    names: ["2-(1H-Imidazol-4-Yl)-Cyclopropylamine", "CHEMBL59389", "SCHEMBL8846403"],
  },
  {
    primary_id: "D09GQO",
    names: [
      "3-(1-NAPHTHYLMETHOXY)PYRIDIN-2-AMINE",
      "3-(Naphthalen-1-Ylmethoxy)Pyridin-2-Amine",
      "2-Amino-3-(1-Naphthylmethyloxy)Pyridine",
      "LI4",
      "1wbw",
      "CHEMBL195393",
      "AC1NS185",
      "SCHEMBL6080116",
      "BDBM13340",
      "AKOS009255557",
      "DB08093",
      "Pyridine Derived Fragment Based Inhibitor 5",
    ],
  },
  {
    primary_id: "D09GQP",
    names: [
      "H-2',6'-Dimethyltyrosine-Tic-OH",
      "CHEMBL571492",
      "2,6-Dimethyl-L-Tyr-L-Tic-OH",
      "BDBM50299565",
      "H-2'',6''-Dimethyltyrosine-Tic-OH",
      "(S)-2-((S)-2-Amino-3-(4-Hydroxy-2,6-Dimethylphenyl)Propanoyl)-1,2,3,4-Tetrahydroisoquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09GQS",
    names: ["JD-0100"],
  },
  {
    primary_id: "D09GRB",
    names: ["NNC-90-0270"],
  },
  {
    primary_id: "D09GRF",
    names: ["ISIS 103582"],
  },
  {
    primary_id: "D09GRH",
    names: ["O-Vax", "AC Vaccine (Ovarian Cancer), AVAX"],
  },
  {
    primary_id: "D09GRT",
    names: ["ITCA 650"],
  },
  {
    primary_id: "D09GRX",
    names: ["Tucatinib"],
  },
  {
    primary_id: "D09GSD",
    names: ["H-Dmt-Aba-Gly-NH-CH2-Bid", "CHEMBL211543"],
  },
  {
    primary_id: "D09GSO",
    names: ["PMID27599163-Compound-82"],
  },
  {
    primary_id: "D09GSV",
    names: ["FBT-A05"],
  },
  {
    primary_id: "D09GTJ",
    names: ["Bis(2,4-Dinitrophenyl)Sulfane"],
  },
  {
    primary_id: "D09GTN",
    names: [
      "7-Fluoro-1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One",
      "CHEMBL323071",
      "SCHEMBL10757077",
      "2H-Imidazo[4,5-B]quinolin-2-One, 7-Fluoro-1,3-Dihydro-",
      "BDBM50007234",
    ],
  },
  {
    primary_id: "D09GUI",
    names: ["PTL-68001"],
  },
  {
    primary_id: "D09GUJ",
    names: ["Omadacycline"],
  },
  {
    primary_id: "D09GVB",
    names: ["OL-135"],
  },
  {
    primary_id: "D09GVI",
    names: ["VSF-173"],
  },
  {
    primary_id: "D09GWR",
    names: ["Antibodie Derivative 3"],
  },
  {
    primary_id: "D09GXF",
    names: ["RB-105"],
  },
  {
    primary_id: "D09GXL",
    names: ["NORBOLDINE"],
  },
  {
    primary_id: "D09GXM",
    names: ["3-[7-(Benzylmethylamino)-Heptyloxy]xanthen-9-One", "CHEMBL224433"],
  },
  {
    primary_id: "D09GXN",
    names: ["Benzanilide Compound 2"],
  },
  {
    primary_id: "D09GYC",
    names: [
      "2-Amino-6-(2-Cyano-Benzenesulfinyl)-Benzonitrile",
      "CHEMBL294212",
      "6-Amino-2-[(2-Cyanophenyl)Sulfinyl]benzenecarbonitrile",
      "AC1LAE2N",
      "BDBM1765",
      "CTK7C8877",
      "6-Amino[2,2'-Sulfinylbisbenzonitrile]",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 2j",
      "2-Amino-6-(2-Cyanophenyl)Sulfinylbenzonitrile",
      "2-Amino-6-(2-Cyanophenyl)Sulfinyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D09GYH",
    names: ["3-Methyl-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL232189", "SCHEMBL4291326"],
  },
  {
    primary_id: "D09GYT",
    names: ["Methoxamine"],
  },
  {
    primary_id: "D09GZH",
    names: ["(R)-ONDANSETRON"],
  },
  {
    primary_id: "D09GZQ",
    names: ["Ac-HPhe-Leu-Gly-LeuVSMe", "CHEMBL209243"],
  },
  {
    primary_id: "D09HAD",
    names: ["Drug 311383", "311383"],
  },
  {
    primary_id: "D09HAL",
    names: ["JTT-651"],
  },
  {
    primary_id: "D09HAM",
    names: [
      "2-Phenyl-2H-Pyrazolo[3,4-C]quinolin-4-Amine",
      "CHEMBL320805",
      "BDBM50091117",
      "2-Phenyl-2H-Pyrazolo[3,4-C]quinoline-4-Amine",
    ],
  },
  {
    primary_id: "D09HAX",
    names: ["SIGMOIDIN B"],
  },
  {
    primary_id: "D09HBR",
    names: ["MS436"],
  },
  {
    primary_id: "D09HBX",
    names: ["Trans-2-Fluoro-2-Phenylcyclopropylamin", "CHEMBL455782", "SCHEMBL15542348", "ZINC13559954"],
  },
  {
    primary_id: "D09HBZ",
    names: ["ML-3375"],
  },
  {
    primary_id: "D09HCE",
    names: ["Long-Acting FSH Conjugate"],
  },
  {
    primary_id: "D09HCR",
    names: ["FM-401"],
  },
  {
    primary_id: "D09HDQ",
    names: ["ISIS 144379"],
  },
  {
    primary_id: "D09HDR",
    names: [
      "Deserpidine",
      "Canescin",
      "Canescine",
      "Deresperine",
      "Desepridine",
      "Deserpidin",
      "Deserpidina",
      "Deserpidinum",
      "Desmethoxyreserpine",
      "Halmonyl",
      "Harmonyl",
      "Raunormin",
      "Raunormine",
      "Recanescin",
      "Recanescine",
      "Reserpidine",
      "Tranquinil",
      "Lilly 22641",
      "ORETICYL 50",
      "A-11025",
      "Deserpidina [INN-Spanish]",
      "Deserpidine (INN)",
      "Deserpidine [INN:BAN]",
      "Deserpidinum [INN-Latin]",
      "Halmonyl (TN)",
      "MD-0232",
      "Deserpidic Acid, Methyl Ester, 3,4,5-Trimethoxybenzoate",
      "Methyl 17alpha-Methoxy-18beta-(3,4,5-Trimethoxybenzoyloxy)-3beta,20alpha-Yohimban-16beta-Carboxylat",
      "Methyl 17alpha-Methoxy-18beta-((3,4,5-Trimethoxybenzoyl)Oxy)-3beta,20alpha-Yohimban-16beta-Carboxylate",
      "Methyl 18beta-Hydroxy-17alpha-Methoxy-3beta,20alpha-Yohimban-16beta-Carboxylate, 3,4,5-Trimethoxybenzoate (Ester)",
      "Methyl (3beta,16beta,17alpha,18beta,20alpha)-17-Methoxy-18-[(3,4,5-Trimethoxybenzoyl)Oxy]yohimban-16-Carboxylate",
      "Methyl (3beta,16beta,17alpha,18beta,20alpha)-17-(Methyloxy)-18-({[3,4,5-Tris(Methyloxy)Phenyl]carbonyl}oxy)Yohimban-16-Carboxylate",
      "Benz[g]indolo[2,3-A]quinolizine-1-Carboxylic Acid, 1,2,3,4,4a,5,7,8,-13,13b,14,14a-Dodecahydro-3-Hydroxy-2-Methoxy-, Methyl Ester, 3,4,5-Trimethoxybenzoate",
      "(3beta,16beta,17alpha,18beta,20alpha)-17-Methoxy-18-[(3,4,5-Trimethoxybenzoyl)Oxy]yohimban-16-Carboxylic Acid Methyl Ester",
      "11-Demethoxyreserpine",
      "11-Desmethoxyreserpine",
      "17.alpha.-Methoxy-18.beta.-[(3,4,5-Trimethoxybenzoyl)Oxy]-3.beta.,20.alpha.-Yohimban-16.beta.-Carboxylic Acid Methyl Ester",
      "3-Beta,20-Alpha-Yohimban-16-Beta-Carboxylic Acid, 18-Beta-Hydroxy-17-Alpha-Methoxy-, Methyl Ester, 3,4,5-Trimethoxybenzoate (Ester)",
      "3-Beta,20-Alpha-Yohimban-16-Beta-Carboxylic Acid, 18-Beta-Hydroxy-17-Alpha-Methoxy-,methyl Ester, 3,4,5-Trimethoxybenzoate (Ester)",
    ],
  },
  {
    primary_id: "D09HDV",
    names: ["AD-529"],
  },
  {
    primary_id: "D09HEA",
    names: [
      "4-((1H-Imidazol-1-Yl)Methyl)Benzonitrile",
      "1-(4-Cyanobenzyl)-1H-Imidazole",
      "4-Imidazol-1-Ylmethyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D09HET",
    names: ["L-Beta-BA"],
  },
  {
    primary_id: "D09HEV",
    names: ["GS-9005"],
  },
  {
    primary_id: "D09HFC",
    names: ["Bbs-Arg-(D-Pip)-Gly-S-(GS)13-Gly-Hir", "CHEMBL415370"],
  },
  {
    primary_id: "D09HFJ",
    names: ["NM-3"],
  },
  {
    primary_id: "D09HFL",
    names: ["GSK2398852 + GSK2315698"],
  },
  {
    primary_id: "D09HFS",
    names: ["AFP464"],
  },
  {
    primary_id: "D09HFT",
    names: ["(E)-2-(4-Fluorostyryl)-5-(Phenylsulfonyl)Pyridine", "CHEMBL394729"],
  },
  {
    primary_id: "D09HGK",
    names: [
      "4-[5-Methoxy-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 12a",
      "CHEMBL359942",
      "BDBM8622",
      "AC1O7067",
      "4-[(E)-(5-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D09HHA",
    names: ["MTNF-K90R"],
  },
  {
    primary_id: "D09HHL",
    names: ["Quinoline Derivative 19"],
  },
  {
    primary_id: "D09HHW",
    names: [
      "ML-253764",
      "CHEMBL40711",
      "SCHEMBL3728758",
      "ZINC3938671",
      "BDBM50142895",
      "2-[2-[2-(5-Bromo-2-Methoxyphenyl)-Ethyl]-3-Fluorophenyl]-4,5-Dihydro-1h-Imidazole",
      "2-{2-[2-(5-Bromo-2-Methoxy-Phenyl)-Ethyl]-3-Fluoro-Phenyl}-4,5-Dihydro-1H-Imidazole",
    ],
  },
  {
    primary_id: "D09HIC",
    names: [
      "BF-388",
      "AC1OCF67",
      "SCHEMBL9596312",
      "OWJSEESROFTAQV-UHFFFAOYSA-N",
      "1-(3,5-Ditert-Butyl-4-Hydroxyphenyl)Pyrrolidin-2-One",
      "1-(3,5-Di-Tert-Butyl-4-Hydroxyphenyl)Pyrrolidin-2-One",
    ],
  },
  {
    primary_id: "D09HIM",
    names: ["XR-9504"],
  },
  {
    primary_id: "D09HJE",
    names: ["ISIS 12884"],
  },
  {
    primary_id: "D09HJL",
    names: ["AL-37350A", "AL37350A", "AL 37350A"],
  },
  {
    primary_id: "D09HJS",
    names: [
      "BIZELESIN",
      "Bizelesin < Rec INN",
      "U-77779",
      "(S,S)-1,3-Bis[2-[1-(Chloromethyl)-5-Hydroxy-8-Methyl-1,2,3,6-Tetrahydrobenzo[1,2-B:4,3-B']dipyrrol-3-Ylcarbonyl]-1H-Indol-5-Yl]urea",
    ],
  },
  {
    primary_id: "D09HJU",
    names: ["JNJ-64041757", "ADU-214"],
  },
  {
    primary_id: "D09HKD",
    names: [
      "2-Naphthalen-1-Yl-1H-[1,8]naphthyridin-4-One",
      "CHEMBL423845",
      "NSC676180",
      "AC1L8OZ9",
      "SCHEMBL7925077",
      "CTK8D2144",
      "ZINC5647446",
      "BDBM50059969",
      "NSC-676180",
      "NCI60_026907",
      "2-(1-Naphthyl)-1,8-Naphthyridin-4(1H)-One",
      "2-Naphthalen-1-Yl-1H-1,8-Naphthyridin-4-One",
    ],
  },
  {
    primary_id: "D09HKR",
    names: ["PMID20299227C12"],
  },
  {
    primary_id: "D09HKX",
    names: ["Cefoselis"],
  },
  {
    primary_id: "D09HLX",
    names: ["MPP+", "1-Methyl-4-Phenylpyridinium", "Cyperquat"],
  },
  {
    primary_id: "D09HMT",
    names: ["[HO1][Orn8(5/6C-Rhm)]VT"],
  },
  {
    primary_id: "D09HNO",
    names: [
      "CaMKII Inhibitors",
      "CaMKII Inhibitors (Inflammation)",
      "CaMKII Inhibitors (Inflammation), Dainippon Sumitomo Pharma",
    ],
  },
  {
    primary_id: "D09HNP",
    names: [
      "AL4623",
      "UNII-1M7AKN51DU",
      "1M7AKN51DU",
      "(R)-4-Ethylamino-3,4-Dihydro-2-(2-Methoylethyl)-2h-Thieno[3,2-E]-1,2-Thiazine-6-Sulfonamide-1,1-Dioxide",
      "1bnq",
      "AC1N9ZNF",
      "AL-4623A Free Base",
      "SCHEMBL7508613",
      "BDBM11934",
      "AL-4623",
      "DB03877",
      "138890-59-2",
      "(4R)-4-(Ethylamino)-2-(2-Methoxyethyl)-3,4-Dihydro-2H-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide 1,1-Dioxide",
      "2H-Thieno(3,2-E)-1,2-Thiazine-6-Sulfonamide, 4-(Ethylamino)-3,4-Dihydro-2-(2-Methoxyethyl)-, 1,1-Dioxide, (4R)-",
    ],
  },
  {
    primary_id: "D09HNR",
    names: ["Telithromycin"],
  },
  {
    primary_id: "D09HNV",
    names: ["Intedanib"],
  },
  {
    primary_id: "D09HOE",
    names: [
      "4-[5-Fluoro-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 6b",
      "SCHEMBL4231108",
      "CHEMBL179144",
      "AC1O705T",
      "BDBM8615",
      "ZINC100056302",
      "4-[(Z)-(5-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D09HOF",
    names: [
      "Antiviral Iminosugars",
      "Alkovirs, United Therapeutics",
      "Antiviral Iminosugars, IgX Corp",
      "Antiviral Iminosugars, United Therapeutics",
      "Antiviral Imminosugars, Synergy",
      "Glycobiology Antivirals, United Therapeutics",
      "UT-4",
      "Antiviral Iminosugars, Oxford University/Monsanto",
    ],
  },
  {
    primary_id: "D09HOJ",
    names: ["NPB-01"],
  },
  {
    primary_id: "D09HOW",
    names: ["S-364735"],
  },
  {
    primary_id: "D09HQH",
    names: [
      "Boldenone",
      "Dehydrotestosterone",
      "846-48-0",
      "1,2-Dehydrotestosterone",
      "1-Dehydrotestosterone",
      "1,2-Didehydrotestosterone",
      "17beta-Boldenone",
      "Delta1-Testosterone",
      "Boldenonum",
      "Boldenona",
      "17beta-Hydroxyandrosta-1,4-Dien-3-One",
      "1,4-Androstadien-17beta-Ol-3-One",
      "Boldenonum [INN-Latin]",
      "Boldenona [INN-Spanish]",
      "Boldenone [INN:BAN]",
      "UNII-5H7I2IP58X",
      "17beta-Hydroxyandrosta-1,4-Diene-3-One",
      "17-Beta-Hydroxyandrosta-1,4-Dien-3-One",
      "EINECS 212-686-0",
      "NSC 79102",
      "17-Beta-Hydroxy-17-Alpha-1,4-Androstadien-3-One",
      "5H7I2IP58X",
      "CHEBI:34584",
    ],
  },
  {
    primary_id: "D09HRH",
    names: ["Anti-CD133-CAR Vector-Transduced T Cells"],
  },
  {
    primary_id: "D09HRS",
    names: ["GHB-16L2"],
  },
  {
    primary_id: "D09HRX",
    names: ["VU-1545"],
  },
  {
    primary_id: "D09HSI",
    names: [
      "4'-Phosphopantetheine",
      "Phosphopantetheine",
      "Psh-4'-P",
      "Pantetheine-4'-Phosphate",
      "D-Pantetheine 4'-Phosphate",
      "UNII-NM39WU8OFM",
      "2226-71-3",
      "NM39WU8OFM",
      "O(4)-Phosphono-D-Pantethein",
      "(2R)-2-Hydroxy-N-(3-((2-Mercaptoethyl)Amino)-3-Oxopropyl)-3,3-Dimethyl-4-(Phosphonooxy)Butanamide",
      "PNS",
      "N(3)-[(2R)-2-Hydroxy-3,3-Dimethyl-4-(Phosphonooxy)Butanoyl]-N-(2-Sulfanylethyl)-Beta-Alaninamide",
      "P-PantSH",
      "AC1L3GTE",
      "SCHEMBL106050",
      "CHEBI:4222",
      "JDMUPRLRUUMCTL-VIFPVBQESA-N",
      "AKOS027326509",
      "DB03912",
      "C01134",
      "Butanamide, 2-Hydroxy-N-(3-((2-Mercaptoethyl)A",
    ],
  },
  {
    primary_id: "D09HTB",
    names: ["SAR153191"],
  },
  {
    primary_id: "D09HTE",
    names: ["HA20"],
  },
  {
    primary_id: "D09HTS",
    names: ["Lanatoside A"],
  },
  {
    primary_id: "D09HTY",
    names: ["Urea And Carbamate Bioisostere Derivative 1"],
  },
  {
    primary_id: "D09HUF",
    names: ["UR-60427"],
  },
  {
    primary_id: "D09HUU",
    names: ["EC1456"],
  },
  {
    primary_id: "D09HUV",
    names: ["3-Oxo-2,3-Dihydro-1H-Indazole-4-Carboxamide Derivative 3"],
  },
  {
    primary_id: "D09HVD",
    names: ["Ramucirumab"],
  },
  {
    primary_id: "D09HVH",
    names: ["NMS-E973"],
  },
  {
    primary_id: "D09HVL",
    names: [
      "Bivalirudin",
      "128270-60-0",
      "Angiomax",
      "Hirulog",
      "Bivalirudinum",
      "Bivalirudina",
      "Bivalirudin Trifluoroacetate",
      "BG-8967",
      "UNII-TN9BEX005G",
      "BG8967",
      "Bivalirudin Trifluoacetate",
      "TN9BEX005G",
      "D-Phenylalanyl-L-Prolyl-L-Arginyl-L-Prolylglycylglycylglycylglycyl-L-Asparaginylglycyl-L-Alpha-Aspartyl-L-Phenylalanyl-L-Alpha-Glutamyl-L-Alpha-Glutamyl-L-Isoleucyl-L-Prolyl-L-Alpha-Glutamyl-L-Alpha-Glutamyl-L-Tyrosyl-L-Leucine",
      "CHEBI:59173",
      "Hirulog-1",
      "Angiox",
      "Bivalirudine",
      "The Medicines Company Brand Of Bivalirudin",
      "BG 8967",
      "Angiomax (TN)",
      "HS-2004",
      "Bivalirudin [USAN:BAN:INN]",
      "Phe-Pro-Arg-Pro-(Gly)4 Desulfato-Tyr63'-Hirugen",
      "Phe-Pro-Arg-Pro-(Gly)4-Desulfohirudin-(53-64)",
      "Phe-Pro-Arg-Pro-(Gly)4-Asn-Gly-Asp-Phe-Glu-Glu-Ile-Pro-Glu-Glu-Tyr-Leu",
    ],
  },
  {
    primary_id: "D09HVV",
    names: ["PMID29473428-Compound-58"],
  },
  {
    primary_id: "D09HVY",
    names: ["Cystatin C", "Cystatin C (Alzheimer's Disease)"],
  },
  {
    primary_id: "D09HWQ",
    names: [
      "3-(4-Methanesulfonyl-Phenyl)-1-Phenyl-Propynone",
      "CHEMBL193856",
      "BDBM22344",
      "ZINC13683316",
      "1,3-Diarylprop-2-Yn-1-One, 13a",
    ],
  },
  {
    primary_id: "D09HWZ",
    names: ["[125I]S70254"],
  },
  {
    primary_id: "D09HXG",
    names: ["RR(17)PZ", "CHEMBL220095"],
  },
  {
    primary_id: "D09HXU",
    names: [
      "6-O-Tolylquinazolin-2-Amine",
      "CHEMBL215952",
      "SCHEMBL5494575",
      "RAWAQXBVMDKSIO-UHFFFAOYSA-N",
      "ZINC35049815",
    ],
  },
  {
    primary_id: "D09HXX",
    names: ["TCN-201"],
  },
  {
    primary_id: "D09HYG",
    names: ["NP16"],
  },
  {
    primary_id: "D09HYI",
    names: [
      "M-2001",
      "36457-20-2",
      "Butylparaben Sodium Salt",
      "Butyl 4-Hydroxybenzoate Sodium Salt",
      "Sodium Butylparaben",
      "Butylparaben Sodium",
      "Butyl P-Hydroxybenzoate Sodium",
      "Sodium Butyl 4-Hydroxybenzoate",
      "Sodium 4-(Butoxycarbonyl)Phenolate",
      "UNII-MAR76J77VS",
      "EINECS 253-049-7",
      "MAR76J77VS",
      "BENZOIC ACID, P-HYDROXY-, BUTYL ESTER, SODIUM SALT",
      "W-106612",
      "Sodium Butyl P-Hydroxybenzoate",
      "Sodium Butyl Hydroxybenzoate",
      "Butyl-4-Hydroxybenzoate Sodium Salt",
      "SCHEMBL475966",
      "C11H13O3.Na",
      "DTXSID10189971",
      "MolPort-006-107-117",
      "Sodium P-(Butoxycarbonyl)Phenolate",
    ],
  },
  {
    primary_id: "D09HZE",
    names: [
      "Morpholino(1'H-Phenothiazin-1'-Yl)Methanone",
      "CHEMBL590511",
      "10-(4-Morpholinylcarbonyl)-10H-Phenothiazine",
      "Morpholin-4-Yl-Phenothiazin-10-Yl-Methanone",
      "AC1LE3IO",
      "Morpholin-4-Yl(Phenothiazin-10-Yl)Methanone",
      "Oprea1_836979",
      "MLS000557099",
      "ARONIS009572",
      "ZINC91251",
      "MolPort-019-748-219",
      "MolPort-001-510-993",
      "HMS2479C10",
      "BDBM50308416",
      "STK144997",
      "AKOS000498790",
      "MCULE-6825355538",
      "KS-0000415F",
      "BAS 04087810",
      "SMR000178119",
      "Morpholin-4-Yl Phenothiazin-10-Yl Ketone",
      "BB0286549",
      "ST45046313",
      "Morpholino(1''H-Phenothiazin-1''-Yl)Methanone",
      "AN-329/40200546",
    ],
  },
  {
    primary_id: "D09IAN",
    names: ["Anti-CD19 Chimeric Antigen Receptor T Cells"],
  },
  {
    primary_id: "D09IBZ",
    names: ["Aminoglutethemide"],
  },
  {
    primary_id: "D09ICC",
    names: ["SB220025"],
  },
  {
    primary_id: "D09ICH",
    names: ["CD7.CAR/28zeta CAR T Cells"],
  },
  {
    primary_id: "D09ICL",
    names: ["PD-118057"],
  },
  {
    primary_id: "D09IEE",
    names: [
      "Prednicarbate",
      "Dermatop",
      "Peitel",
      "Dermatop (TN)",
      "Hoe-777",
      "S-77 0777",
      "S-770777",
      "Prednicarbate (USP/INN)",
      "[2-[(8S,9S,10R,11S,13S,14S,17R)-17-Ethoxycarbonyloxy-11-Hydroxy-10,13-Dimethyl-3-Oxo-7,8,9,11,12,14,15,16-Octahydro-6H-Cyclopenta[a]phenanthren-17-Yl]-2-Oxoethyl] Propanoate",
    ],
  },
  {
    primary_id: "D09IEG",
    names: [
      "6-Methyl-2-M-Tolyl-1H-[1,8]naphthyridin-4-One",
      "CHEMBL49016",
      "NSC676187",
      "AC1L8OZO",
      "SCHEMBL7921815",
      "CTK8D2137",
      "ZINC5504117",
      "BDBM50059977",
      "NSC-676187",
      "NCI60_026914",
      "2-(3-Methylphenyl)-6-Methyl-1,8-Naphthyridin-4(1H)-One",
      "6-Methyl-2-(3-Methylphenyl)-1H-1,8-Naphthyridin-4-One",
    ],
  },
  {
    primary_id: "D09IEN",
    names: ["UVI3003"],
  },
  {
    primary_id: "D09IEO",
    names: ["[des-Arg9]bradykinin", "[des-Arg9]BK"],
  },
  {
    primary_id: "D09IFW",
    names: ["Cruentaren"],
  },
  {
    primary_id: "D09IGF",
    names: ["MG-28"],
  },
  {
    primary_id: "D09IGN",
    names: ["Pyrrolidine Derivative 8"],
  },
  {
    primary_id: "D09IGR",
    names: ["AZX-100"],
  },
  {
    primary_id: "D09IHE",
    names: ["MRK016"],
  },
  {
    primary_id: "D09IHN",
    names: ["Gamma-Acetylenic GABA"],
  },
  {
    primary_id: "D09IIH",
    names: ["4-(3-Fluoro-2-Phenoxyphenyl)Piperidine", "CHEMBL583751"],
  },
  {
    primary_id: "D09IIX",
    names: ["HMAG-1 Targeting GRSA"],
  },
  {
    primary_id: "D09IJN",
    names: ["NSC-745886"],
  },
  {
    primary_id: "D09IJP",
    names: [
      "A-71378",
      "A 71378",
      "Desamino-Tyr(SO3H)-Nle-Gly-Trp-Nle-(N-Methyl)Asp-Phe-NH2",
      "(3S)-4-[[(2R)-1-[2-[[(2S)-2-[[1H-Indol-3-Ylmethyl-[2-[[(2S)-2-[3-(4-Sulfooxyphenyl)Propanoylamino]hexanoyl]amino]acetyl]carbamoyl]amino]hexanoyl]carbamoyl]phenyl]propan-2-Yl]amino]-3-(Methylamino)-4-Oxobutanoic Acid",
      "(3S)-4-[[(2S)-1-Amino-1-Oxo-3-Phenylpropan-2-Yl]amino]-3-[[(2S)-2-[[(2S)-3-(1H-Indol-3-Yl)-2-[[2-[[(2S)-2-[3-(4-Sulfooxyphenyl)Propanoylamino]hexanoyl]amino]acetyl]amino]propanoyl]amino]hexanoyl]-Methylamino]-4-Oxobutanoic Acid",
    ],
  },
  {
    primary_id: "D09IKN",
    names: ["Imidazopyrimidinone Derivative 1"],
  },
  {
    primary_id: "D09IKZ",
    names: ["AS-1949490"],
  },
  {
    primary_id: "D09IMK",
    names: ["Cbz-Ile-T-ButylGln-Ala-LeuVSMe", "CHEMBL438612"],
  },
  {
    primary_id: "D09IMN",
    names: ["SAR153192"],
  },
  {
    primary_id: "D09IMS",
    names: [
      "Mequitazine",
      "Butix",
      "Instotal",
      "Kitazemin",
      "Mequitazina",
      "Mequitazinum",
      "Metaplexan",
      "Mircol",
      "Primalan",
      "Quitadrill",
      "Vigigan",
      "Virginan",
      "Zesulan",
      "Italfarmaco Brand Of Mequitazine",
      "Mequitazina [Spanish]",
      "Mequitazine Hydrochloride",
      "Pierre Fabre Brand Of Mequitazine",
      "LM 209",
      "Kitazemin (TN)",
      "LM-209",
      "Mequitazina [INN-Spanish]",
      "Mequitazinum [INN-Latin]",
      "Mequitazine (JP15/INN)",
      "Mequitazine [INN:BAN:DCF:JAN]",
      "Mequitazine Tartrate, (R-(R*,R*))-Isomer",
      "10-(1-Azabicyclo[2.2.2]oct-3-Ylmethyl)-10H-Phenothiazine",
      "10-(1-Azabicyclo(2.2.2)Oct-8-Ylmethyl)Phenothiazine",
      "10-(1-Azabicyclo[2.2.2]octan-3-Ylmethyl)Phenothiazine",
      "10-(3-Quinuclidinylmethyl)Phenothiazine",
      "10H-Phenothiazine, 10-(1-Azabicyclo(2.2.2)Oct-3-Ylmethyl)-(9CI)",
      "3-Methylquinuclidinyl-10-Phenothiazine",
    ],
  },
  {
    primary_id: "D09IMY",
    names: ["SLP-0905"],
  },
  {
    primary_id: "D09INA",
    names: ["HD-148"],
  },
  {
    primary_id: "D09INF",
    names: ["Laquinamod"],
  },
  {
    primary_id: "D09INX",
    names: ["DSP-3905"],
  },
  {
    primary_id: "D09IOA",
    names: ["EM-3106B", "18F-FBEM-EM-3106B"],
  },
  {
    primary_id: "D09IOF",
    names: ["Troplasminogen Alfa"],
  },
  {
    primary_id: "D09IOI",
    names: [
      "Amoxapine",
      "Amoxan",
      "Amoxapina",
      "Amoxapinum",
      "Amoxepine",
      "Ascendin",
      "Asendin",
      "Asendis",
      "Defanyl",
      "Demolox",
      "Desmethylloxapin",
      "Desmethylloxapine",
      "Moxadil",
      "CL 67772",
      "A-129",
      "Amoxapina [INN-Spanish]",
      "Amoxapinum [INN-Latin]",
      "Asendin (TN)",
      "Asendis (TN)",
      "CL-67772",
      "Defanyl (TN)",
      "Demolox (TN)",
      "KS-1197",
      "Moxadil (TN)",
      "CL-67,772",
      "Amoxapine (JP15/USP/INN)",
      "Amoxapine [USAN:BAN:INN:JAN]",
      "2-Chlor-11-(1-Piperazinyl)Dibenz(B,f)(1,4)Oxazepin",
      "2-Chloro-11-(1-Piperazinyl)-Dibenz[b,f][1,4]oxazepine",
      "2-Chloro-11-(1-Piperazinyl)Dibenz(B,f)(1,4)Oxazepine",
      "2-Chloro-11-(1-Piperazinyl)Dibenz[b,f][1,4]oxazepine",
      "2-Chloro-11-(Piperazin-1-Yl)Dibenzo[b,f][1,4]oxazepine",
      "2-Chloro-11-Piperazin-1-Yldibenzo[b,f][1,4]oxazepine",
      "8-Chloro-6-Piperazin-1-Ylbenzo[b][1,4]benzoxazepine",
    ],
  },
  {
    primary_id: "D09IOO",
    names: ["ISIS 11061"],
  },
  {
    primary_id: "D09IOW",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 23"],
  },
  {
    primary_id: "D09IPV",
    names: [
      "Danazol",
      "Anatrol",
      "Chronogyn",
      "Cyclomen",
      "Danatrol",
      "Danazant",
      "Danazole",
      "Danazolum",
      "Danocrine",
      "Danogen",
      "Danokrin",
      "Danol",
      "Danoval",
      "Danzol",
      "Ladogal",
      "Norciden",
      "Panacrine",
      "Winobanin",
      "Alphapharm Brand Of Danazol",
      "Antigen Brand Of Danazol",
      "Kendrick Brand Of Danazol",
      "Ratiopharm Brand Of Danazol",
      "Sanofi Brand Of Danazol",
      "Sanofi Synthelabo Brand OfDanazol",
      "Sanofi Winthrop Brand Of Danazol",
      "WIN 17757",
      "Danazol-Ratiopharm",
      "Danazolum [INN-Latin]",
      "Danocrine (TN)",
      "WIN 17,757",
      "WIN-17757",
      "Win 17, 757",
      "Danazol (JAN/USP/INN)",
      "Danazol [USAN:BAN:INN:JAN];[1,2]oxazolo[4',5':2,3]-17alpha-Pregn-4-En-20-Yn-17-Ol",
      "(1R,3aS,3bR,10aR,10bS,12aS)-1-Ethynyl-10a,12a-Dimethyl-2,3,3a,3b,4,5,10,10a,10b,11,12,12a-Dodecahydro-1H-Cyclopenta[7,8]phenanthro[3,2-D]isoxazol-1-Ol",
      "(1S,2R,13R,14S,17R,18S)-17-Ethynyl-2,18-Dimethyl-7-Oxa-6-Azapentacyclo[11.7.0.0^{2,10}.0^{4,8}.0^{14,18}]icosa-4(8),5,9-Trien-17-Ol",
      "1-Ethynyl-10a,12a-Dimethyl-2,3,3a,3b,4,5,10,10a,10b,11,12,12a-Dodecahydro-1H-Cyclopenta[7,8]phenanthro[3,2-D]isoxazol-1-Ol",
      "17-Alpha-2,4-Pregnadien-20-Yno(2,3-D)Isoxazol-17-Ol",
      "17-Alpha-Pregn-4-En-20-Yno(2,3-D)Isoxazol-17-Ol",
      "17-Alpha-Pregna-2,4-Dien-20-Yno(2,3-D)Isoxazol-17-Ol",
      "17.Alpha.-Pregna-2, {4-Dien-20-Yno[2,3-D]isoxazol-17-Ol}",
      "17alpha-Pregna-2,4-Dien-20-Yno(2,3-D)Isoxazol-17-Ol",
    ],
  },
  {
    primary_id: "D09IQG",
    names: ["PMID7966163C6g"],
  },
  {
    primary_id: "D09IQS",
    names: [
      "4-Methyl-Pyridine",
      "4-METHYLPYRIDINE",
      "4-Picoline",
      "108-89-4",
      "Pyridine, 4-Methyl-",
      "P-Picoline",
      "Gamma-Picoline",
      "P-Methylpyridine",
      "Gamma-Methylpyridine",
      "Ba 35846",
      "UNII-TJD6V9SSO7",
      ".gamma.-Picoline",
      "NSC 18252",
      "4-Mepy",
      "CCRIS 1723",
      ".gamma.-Methylpyridine",
      "HSDB 5386",
      "EINECS 203-626-4",
      "TJD6V9SSO7",
      "AI3-24111",
      "CHEBI:32547",
      "FKNQCJSGGFJEIZ-UHFFFAOYSA-N",
      "4-Picoline, 99%",
      "DSSTox_CID_1892",
      "DSSTox_RID_76389",
      "DSSTox_GSID_21892",
      "4-Methyl Pyridine",
      "CAS-108-89-4",
      "Gamma-Picolin",
      "4-Pyridylmethyl",
      "4-Pi-Coline",
      "Para-Methylpyridine",
      "Methyl, 4-Pyridinyl-",
    ],
  },
  {
    primary_id: "D09IRF",
    names: ["[125I]PTA-OH"],
  },
  {
    primary_id: "D09IRN",
    names: ["LU AE58054"],
  },
  {
    primary_id: "D09IRQ",
    names: [
      "Ataluren",
      "775304-57-9",
      "PTC124",
      "3-(5-(2-Fluorophenyl)-1,2,4-Oxadiazol-3-Yl)Benzoic Acid",
      "PTC-124",
      "3-[5-(2-Fluorophenyl)-1,2,4-Oxadiazol-3-Yl]benzoic Acid",
      "PTC124 (Ataluren)",
      "PTC 124",
      "UNII-K16AME9I3V",
      "Ataluren (PTC124)",
      "K16AME9I3V",
      "NCGC00168759-02",
      "Translarna",
      "AK-31904",
      "Benzoic Acid, 3-[5-(2-Fluorophenyl)-1,2,4-Oxadiazol-3-Yl]-",
      "DSSTox_CID_26776",
      "DSSTox_RID_81895",
      "DSSTox_GSID_46776",
      "Benzoic Acid, 3-(5-(2-Fluorophenyl)-1,2,4-Oxadiazol-3-Yl)-",
      "CAS-775304-57-9",
      "Ataluren [USAN:INN]",
      "Translarna (TN)",
      "PTC124,Ataluren",
      "Ataluren(Ptc124)",
    ],
  },
  {
    primary_id: "D09IRV",
    names: ["LUF-5957"],
  },
  {
    primary_id: "D09ISB",
    names: [
      "(R)-2-Amino-5-Phosphono-Pentanoic Acid",
      "76326-31-3",
      "5-Phosphononorvaline",
      "DL-AP5",
      "2-Amino-5-Phosphonopentanoic Acid",
      "2-Amino-5-Phosphovaleric Acid",
      "DL-2-Amino-5-Phosphonopentanoic Acid",
      "2-Amino-5-Phosphopentanoic Acid",
      "2-Amino-5-Phosphonovaleric Acid",
      "2-AMINO-5-PHOSPHONOVALERATE",
      "DL-2-Amino-5-Phosphonovaleric Acid",
      "Dl-APV",
      "2-Amino-5-Phosphonopentanoate",
      "AP-5",
      "Norvaline, 5-Phosphono-",
      "76726-92-6",
      "CHEMBL28862",
      "CHEBI:138644",
      "2-Amino-5-Phosphono-Pentanoic Acid",
      "(+/-)-Amino-5-Phosphono-Pentanoic Acid",
      "(+/-)-2-Amino-5-Phosphonopentanoic Acid",
      "App Acid",
      "C5H12NO5P",
      "Apv Acid",
    ],
  },
  {
    primary_id: "D09ISI",
    names: ["Fluorinated Compound 1"],
  },
  {
    primary_id: "D09ISJ",
    names: ["SOM-0856"],
  },
  {
    primary_id: "D09ISS",
    names: ["CZEN-001"],
  },
  {
    primary_id: "D09ITH",
    names: ["Botulism Immune Globulin", "Botulinum Toxin Polyclonal Antibodies"],
  },
  {
    primary_id: "D09ITI",
    names: [
      "8-OH-MANZAMINE A",
      "8-Hydroxymanzamine A",
      "154466-37-2",
      "(4aR,7S,7aR,13Z,14aR,15aR,18Z)-5-(8-Hydroxy-9H-Beta-Carbolin-1-Yl)-4,4a,9,10,11,12,14a,15-Octahydro-3H-7,2-Oct[3]enoazocino[1',2':1,5]pyrrolo[2,3-I]isoquinolin-7(1H,7aH)-Ol",
      "(+)8-Hydroxymanzamine A",
      "CHEBI:66669",
    ],
  },
  {
    primary_id: "D09ITX",
    names: ["2-Benzyl-1-Hydroxy-3H-Benzo[f]chromen-3-One", "CHEMBL573922"],
  },
  {
    primary_id: "D09IUG",
    names: ["F-3796"],
  },
  {
    primary_id: "D09IUR",
    names: ["Midismase"],
  },
  {
    primary_id: "D09IVP",
    names: ["Anti-Folate Receptor 1 Humanized Mab", "Anti-Folate Receptor 1 Humanized MAb (Cancer)"],
  },
  {
    primary_id: "D09IVQ",
    names: [
      "Adenosine-5-Diphosphoribose",
      "[(2r,3s,4r,5r)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxy-Oxolan-2-Yl]methyl [hydroxy-[[(2r,3s,4r,5s)-3,4,5-Trihydroxyoxolan-2-Yl]methoxy]phosphoryl] Hydrogen Phosphate",
      "2bfq",
      "AR6",
      "AC1L9KEO",
      "ZINC14880207",
      "DB02059",
      "[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] [(2R,3S,4R,5S)-3,4,5-Trihydroxyoxolan-2-Yl]methyl Hydrogen Phosphate",
      "[(2R,3S,4R,5R)-5-(6-Amino-9H-Purin-9-Yl)-3,4-Dihydroxytetrahydrofuran-2-Yl]methyl [(2R,3S,4R,5S)-3,4,5-Trihydroxytetrahydrof",
    ],
  },
  {
    primary_id: "D09IVS",
    names: [
      "((Z)-3-Amino-Propenyl)-Methyl-Phosphinic Acid",
      "CGP-70523",
      "SCHEMBL6679937",
      "PDSP2_001466",
      "PDSP1_001482",
    ],
  },
  {
    primary_id: "D09IWI",
    names: ["N-(Propylamide-Acetophenone)-1-Deoxynojirimycin", "CHEMBL589341"],
  },
  {
    primary_id: "D09IXX",
    names: [
      "Delta-9-Tetrahydrocannabinol Prodrugs",
      "Cannabinoid-2 Modulator (Gel, Pain/Osteoarthritis), Alltranz",
      "Cannabinoid Prodrugs (Transdermal Patch/Gel, Pain/Osteoarthritis/Cannabis Dependence), AllTranz",
      "Delta-9-Tetrahydrocannabinol Prodrugs (Transdermal Patch/Gel, Pain/Osteoarthritis/Cannabis Dependence)",
      "Delta-9-Tetrahydrocannabinol Prodrugs (Transdermal Patch/Gel, Pain/Osteoarthritis/Cannabis Dependence), AllTranz",
    ],
  },
  {
    primary_id: "D09IYH",
    names: ["Pyrazole Derivative 58"],
  },
  {
    primary_id: "D09IYM",
    names: ["AT13148"],
  },
  {
    primary_id: "D09IZX",
    names: ["GW-275919"],
  },
  {
    primary_id: "D09JAB",
    names: [
      "3-(2,5-Dioxo-Pyrrolidin-1-Yl)-Benzoic Acid",
      "3-(2,5-Dioxopyrrolidin-1-Yl)Benzoic Acid",
      "60693-31-4",
      "3-(2,5-Dioxo-1-Pyrrolidinyl)Benzoic Acid",
      "3-(2,5-Dioxoazolidinyl)Benzoic Acid",
      "AC1LETYJ",
      "BAS 16153444",
      "Enamine_005131",
      "3-Succinimidobenzoic Acid",
      "3-Succinimidylbenzoic Acid",
      "Cambridge Id 5631823",
      "AC1Q5U3M",
      "AC1Q73IP",
      "Oprea1_343380",
      "Oprea1_386853",
      "MLS000061625",
      "SCHEMBL20053775",
      "ZINC63844",
      "CTK5B2080",
      "DTXSID80351143",
      "MolPort-000-385-168",
      "HMS1408J05",
      "ANW-46498",
      "STK155926",
      "SBB011404",
      "AKOS000112869",
      "MCULE-3726251625",
      "IDI1_007718",
      "SMR000069955",
    ],
  },
  {
    primary_id: "D09JAK",
    names: ["3052230"],
  },
  {
    primary_id: "D09JAO",
    names: [
      "1-Deoxy-D-Xylulose-5-Phosphate",
      "1-Deoxy-Xylulose 5-Phosphate",
      "Deoxyxylulose-5-Phosphate",
      "D-1-Deoxyxylulose-5-P",
      "1-Deoxy-D-Threo-Pentulose 5-Phosphate",
      "DXP",
      "CHEBI:57792",
      "1-Deoxy-5-O-Phosphonato-D-Xylulose",
      "1-Deoxy-D-Xylulose 5-Phosphate(2-)",
      "1-Deoxy-D-Xylulose 5-Phosphate Dianion",
    ],
  },
  {
    primary_id: "D09JAR",
    names: [
      "BICARBONATE",
      "Bicarbonate",
      "Hydrogen Carbonate",
      "Bicarbonate Ion",
      "Carbonate, Hydrogen",
      "Hydrogencarbonate",
      "Bicarbonate Ions",
      "71-52-3",
      "UNII-HN1ZRA3Q20",
      "HCO3-",
      "HN1ZRA3Q20",
      "Bicarbonate Anion",
      "Hydrocarbonate(1-)",
      "Monohydrogen Carbonate",
      "Hydrogen Carbonate Ion",
      "Hydrogen Carbonate Anion",
      "Carbonate (HCO31-)",
      "Bicarbonates",
      "Carbonate Ion (HCO31-)",
      "Carboxyolate",
      "Acid Carbonate",
      "Hydrogen-Carbonate",
      "HYDROXYFORMATE",
      "Hydrogencarbonate(1-)",
      "HCO3",
      "Carbonic Acid, Ion(1-)",
      "Hydrogentrioxocarbonate(IV)",
      "Hydrogentrioxocarbonate(1-)",
      "AC1L19ZH",
    ],
  },
  {
    primary_id: "D09JBP",
    names: ["Paramethadione"],
  },
  {
    primary_id: "D09JCF",
    names: ["Huprine-Tacrine Heterodimer"],
  },
  {
    primary_id: "D09JCH",
    names: ["CDNA Vaccine", "CDNA Vaccine (Prostate Cancer)"],
  },
  {
    primary_id: "D09JCT",
    names: [
      "AcAsp-Glu-Met-Glu-Glu-Cys",
      "CHEMBL62717",
      "AC1LA8TV",
      "Ac-Asp-Glu-Met-Glu-Glu-Cys-OH",
      "BDBM50084634",
      "4-(2-Acetylamino-3-Carboxy-Propionylamino)-4-(1-{3-Carboxy-1-[3-Carboxy-1-(1-Carboxy-2-Mercapto-Ethylcarbamoyl)-Propylcarbamoyl]-Propylcarbamoyl}-3-Methylsulfanyl-Propylcarbamoyl)-Butyric Acid(NS4A-NS4B)",
    ],
  },
  {
    primary_id: "D09JDE",
    names: ["NDV-3"],
  },
  {
    primary_id: "D09JDH",
    names: ["Septide"],
  },
  {
    primary_id: "D09JDV",
    names: ["PMID26651364-Compound-5a"],
  },
  {
    primary_id: "D09JDX",
    names: ["RCD-2"],
  },
  {
    primary_id: "D09JEJ",
    names: [
      "CX-4945",
      "1009820-21-6",
      "Silmitasertib",
      "5-((3-Chlorophenyl)Amino)Benzo[c][2,6]naphthyridine-8-Carboxylic Acid",
      "CX4945",
      "CX-4945 (Silmitasertib)",
      "CX 4945",
      "UNII-C6RWP0N0L2",
      "5-[(3-Chlorophenyl)Amino]-Benzo[c]-2,6-Naphthyridine-8-Carboxylic Acid",
      "5-(3-Chlorophenylamino)Benzo[c][2,6]naphthyridine-8-Carboxylic Acid",
      "C6RWP0N0L2",
      "CHEMBL1230165",
      "AK-82006",
      "5-[(3-Chlorophenyl)Amino]benzo[c][2,6]naphthyridine-8-Carboxylic Acid",
      "5-((3-Chlorophenyl)Amino)Benzo-[c][2,6]naphthyridine-8-Carboxylic Acid",
      "C19H12ClN3O2",
      "W-204393",
    ],
  },
  {
    primary_id: "D09JER",
    names: ["JWH-344"],
  },
  {
    primary_id: "D09JES",
    names: [
      "Sampatrilat",
      "AC1L2YMI",
      "SCHEMBL49065",
      "C26H40N4O9S",
      "UK 81252",
      "LS-172743",
      "(3R)-3-[[(2S)-6-Amino-2-(Methanesulfonamido)Hexanoyl]amino]-4-[1-[[(2S)-1-Hydroxy-3-(4-Hydroxyphenyl)-1-Oxopropan-2-Yl]carbamoyl]cyclopentyl]butanoic Acid",
    ],
  },
  {
    primary_id: "D09JET",
    names: ["NGD 96-3"],
  },
  {
    primary_id: "D09JFR",
    names: ["4,5,6,7-Tetrahydrofuro[3,4-C]pyridine-1(3H)-One Derivative 4"],
  },
  {
    primary_id: "D09JFY",
    names: ["Dutasteride + Tamsulosin"],
  },
  {
    primary_id: "D09JGH",
    names: ["EC-145", "EC-0255", "EC-0260"],
  },
  {
    primary_id: "D09JGK",
    names: ["TAK-960"],
  },
  {
    primary_id: "D09JGO",
    names: ["PMID19743866C51", "Ac-RLTbaLAR-H", "GTPL8653", "BDBM50314023"],
  },
  {
    primary_id: "D09JHA",
    names: ["Azide"],
  },
  {
    primary_id: "D09JHM",
    names: ["N-(3-Chlorophenyl)-6-Methoxyquinazolin-4-Amine", "CHEMBL1080998", "SCHEMBL2101452", "BDBM50311878"],
  },
  {
    primary_id: "D09JHT",
    names: ["Ro26-4550"],
  },
  {
    primary_id: "D09JHY",
    names: ["N-(4-(4-Hexyl-2-Hydroxyphenoxy)Phenyl)Acetamide", "CHEMBL262360"],
  },
  {
    primary_id: "D09JII",
    names: ["L-796449"],
  },
  {
    primary_id: "D09JJF",
    names: ["ISIS 109113"],
  },
  {
    primary_id: "D09JJG",
    names: ["MCT-465"],
  },
  {
    primary_id: "D09JJU",
    names: [
      "EchiTAb",
      "Carpet Viper Antivenom (Polyclonal Sheep Antibodies), MicroPharm",
      "Echis Ocellatus Antivenom (Polyclonal Sheep Antibodies), MicroPharm",
      "Ovine Fab Antivenom (Carpet Viper), MicroPharm",
      "Ovine Fab Antivenom (Carpet Viper), Protherics",
      "Ovine Fab Antivenom (Carpet Viper), Therapeutic Antibodies Plc",
      "Carpet Viper Sheep-Derivedantivenom (Nigeria), MicroPharm",
    ],
  },
  {
    primary_id: "D09JKN",
    names: ["P-BCMA-101"],
  },
  {
    primary_id: "D09JKS",
    names: ["GRASPA"],
  },
  {
    primary_id: "D09JKT",
    names: ["2-Acetylaminoquinazoline-4-Carboxyanilide", "CHEMBL239193"],
  },
  {
    primary_id: "D09JLI",
    names: ["Vicriviroc"],
  },
  {
    primary_id: "D09JLL",
    names: ["PMID27774822-Compound-Figure6Example3"],
  },
  {
    primary_id: "D09JLP",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 10"],
  },
  {
    primary_id: "D09JMW",
    names: ["IHV001"],
  },
  {
    primary_id: "D09JNN",
    names: [
      "3-(6-Amino-Purin-9-Yl)-7-Phenyl-Heptan-2-Ol",
      "CHEMBL295003",
      "SCHEMBL7458479",
      "BDBM50070646",
      "ZINC13835546",
      "(2S,3R)-3-(6-Amino-Purin-9-Yl)-7-Phenyl-Heptan-2-Ol",
    ],
  },
  {
    primary_id: "D09JNP",
    names: ["MVax"],
  },
  {
    primary_id: "D09JOE",
    names: ["1-(2,5-Dimethoxy-4-Methyl-Phenyl)-Piperazine", "CHEMBL160344", "ZINC27714862", "BDBM50226210"],
  },
  {
    primary_id: "D09JOM",
    names: ["S-(2,4-Dichlorobenzyl)Isothiourea Hydrobromide"],
  },
  {
    primary_id: "D09JPC",
    names: [
      "Hexane-1,6-Diamine",
      "1,6-Hexanediamine",
      "1,6-Diaminohexane",
      "Hexamethylenediamine",
      "124-09-4",
      "HEXANE-1,6-DIAMINE",
      "HMDA",
      "1,6-Hexylenediamine",
      "1,6-Hexamethylenediamine",
      "1,6-Diamino-N-Hexane",
      "HEXAMETHYLENE DIAMINE",
      "Hexylenediamine",
      "1,6-Hexanediamine (Solution)",
      "Hexane, 1,6-Diamino-",
      "UNII-ZRA5J5B2QW",
      "NCI-C61405",
      "NSC 9257",
      "H2N(CH2)6NH2",
      "CCRIS 6224",
      "HSDB 189",
      "EINECS 204-679-6",
      "HEX-NH2",
      "UN2280",
      "UN1783",
      "ZRA5J5B2QW",
      "Hexamethylenediamine Solution",
      "BRN 1098307",
      "AI3-37283",
      "CHEMBL303004",
      "CHEBI:39618",
      "NAQMVNRVTILPCV-UHFFFAOYSA-N",
      "MFCD00008243",
      "DSSTox_CID_4922",
    ],
  },
  {
    primary_id: "D09JPL",
    names: [
      "Protein Misfolding Therapies",
      "CBM-03350",
      "CBM-44282",
      "CBM-50381",
      "Protein Misfolding Therapies (ALS)",
      "Protein Misfolding Therapies (ALS), Cambria",
      "Protein Misfolding Therapies (Amyotrophic Lateral Sclerosis), Cambira",
      "TC-11-202",
    ],
  },
  {
    primary_id: "D09JPT",
    names: ["CB-183872"],
  },
  {
    primary_id: "D09JQU",
    names: ["BayCysLT2", "CysLT2cpd"],
  },
  {
    primary_id: "D09JRL",
    names: ["Oral Peptide Cancer Vaccine"],
  },
  {
    primary_id: "D09JRN",
    names: ["3-(2-Phenyl-2-(Piperazin-1-Yl)Ethyl)Benzonitrile", "CHEMBL386223"],
  },
  {
    primary_id: "D09JSA",
    names: [
      "1-(3-(3-Phenylpropoxy)Propyl)Piperidine",
      "CHEMBL462606",
      "1-[3-(3-Phenylpropoxy)Propyl]piperidine",
      "SCHEMBL491655",
      "UYLNHGRGPLPMFD-UHFFFAOYSA-N",
      "BDBM50247055",
      "3-Phenylpropyl 3-Piperidinopropyl Ether",
    ],
  },
  {
    primary_id: "D09JSD",
    names: ["USNIC ACID"],
  },
  {
    primary_id: "D09JSK",
    names: ["4-Oxobutanoate"],
  },
  {
    primary_id: "D09JSM",
    names: ["IMC-3C5"],
  },
  {
    primary_id: "D09JTE",
    names: ["BIOO-6"],
  },
  {
    primary_id: "D09JTT",
    names: ["Quinazoline Derivative 3"],
  },
  {
    primary_id: "D09JUD",
    names: [
      "IRAK4 Inhibitor 4b",
      "CHEMBL256713",
      "N-[2-Methoxy-4-(Morpholin-4-Yl)Phenyl]-6-(1H-Pyrazol-5-Yl)Pyridine-2-Carboxamide",
      "DL1",
      "GTPL8080",
      "SCHEMBL18427794",
      "BDBM50373415",
      "IRAK4 Inhibitor 4b [PMID: 18474425]",
    ],
  },
  {
    primary_id: "D09JUG",
    names: ["Zileuton"],
  },
  {
    primary_id: "D09JUL",
    names: ["Naltrexone Prodrugs"],
  },
  {
    primary_id: "D09JVJ",
    names: ["Aminopyrimidine Derivative 10"],
  },
  {
    primary_id: "D09JVK",
    names: ["LY3090106"],
  },
  {
    primary_id: "D09JVV",
    names: ["Physostigmine"],
  },
  {
    primary_id: "D09JXH",
    names: ["LUM001"],
  },
  {
    primary_id: "D09JXL",
    names: ["SEGLITIDE"],
  },
  {
    primary_id: "D09JXM",
    names: ["Phenylpropanolamine"],
  },
  {
    primary_id: "D09JXO",
    names: ["2-(3-Bromophenyl)-7-Methylpyrido[2,3-D]pyrimidine", "CHEMBL396346"],
  },
  {
    primary_id: "D09JXR",
    names: ["PMID23589301C1"],
  },
  {
    primary_id: "D09JXT",
    names: ["N-Allyl[D-Pro-10]Dyn A-(1-11)", "CHEMBL406338"],
  },
  {
    primary_id: "D09JXY",
    names: ["ISIS 2920"],
  },
  {
    primary_id: "D09JYF",
    names: ["Epocarbazolin-A"],
  },
  {
    primary_id: "D09JYL",
    names: ["Hu5F9-G4"],
  },
  {
    primary_id: "D09JYY",
    names: [
      "F-1013",
      "CV-1013",
      "EP-1013",
      "MX-1013",
      "Caspase Inhibitors (Hepatitis), GNI",
      "Caspase Inhibitors (Hepatitis), Gene Networks International",
    ],
  },
  {
    primary_id: "D09JZA",
    names: ["Quinoline Derivative 8"],
  },
  {
    primary_id: "D09JZG",
    names: [
      "1,2,3,4,5,6-Hexahydro-Benzo[c]azocine",
      "CHEMBL116945",
      "SCHEMBL4503618",
      "BDBM50052889",
      "7124-94-9",
      "1,2,3,4,5,6-Hexahydro-2-Benzazocine",
      "2-Benzazocine, 1,2,3,4,5,6-Hexahydro-",
    ],
  },
  {
    primary_id: "D09JZY",
    names: ["Cis-2,6-Dimethyl-1-Methyl Sulfonyl Piperidine", "CHEMBL576005"],
  },
  {
    primary_id: "D09KAK",
    names: ["NOX-F37"],
  },
  {
    primary_id: "D09KBQ",
    names: ["FLUPERAMIDE"],
  },
  {
    primary_id: "D09KCN",
    names: ["PMID26004420-Compound-WO2015017305E"],
  },
  {
    primary_id: "D09KCU",
    names: [
      "Citraconic Acid",
      "2-Methylmaleic Acid",
      "METHYLMALEIC ACID",
      "498-23-7",
      "2-Methyl-2-Butenedioic Acid",
      "Cis-Methylbutenedioic Acid",
      "Citraconate",
      "(Z)-2-Methyl-2-Butenedioic Acid",
      "Maleic Acid, Methyl-",
      "Citraconsaeure",
      "Methyl-Maleinsaeure",
      "Kyselina Citrakonova",
      "Kyselina Citrakonova [Czech]",
      "Citraconsaure",
      "UNII-0RQ6CXO9KD",
      "(2Z)-2-Methylbut-2-Enedioic Acid",
      "BRN 1722679",
      "2-Butenedioic Acid, 2-Methyl-, (Z)-",
      "2-Methylmaleate",
      "Alpha-Methylmaleic Acid",
      "EINECS 207-858-7",
      "NSC 32949",
      "0RQ6CXO9KD",
      "Cis-2-Methylbutenedioic Acid",
      "2-Methyl-2Z-Butenedioic Ac",
    ],
  },
  {
    primary_id: "D09KDC",
    names: ["ISIS 341881"],
  },
  {
    primary_id: "D09KDI",
    names: ["THRX160209"],
  },
  {
    primary_id: "D09KDN",
    names: [
      "Cefazolin",
      "CEZ",
      "Cefamezin",
      "Cefamezine",
      "Cefazolina",
      "Cefazoline",
      "Cefazolinum",
      "Cephamezine",
      "Cephazolidin",
      "Cephazolin",
      "Cephazoline",
      "Elzogram",
      "Cephazolin Sodium",
      "Ancef (TN)",
      "Cefacidal (TN)",
      "Cefamezin (TN)",
      "Cefazolin (USP)",
      "Cefazolin [USAN:INN]",
      "Cefazolin(Usp)",
      "Cefazolina [INN-Spanish]",
      "Cefazoline [INN-French]",
      "Cefazolinum [INN-Latin]",
      "Cefrina (TN)",
      "Elzogram (TN)",
      "Faxilen (TN)",
      "Gramaxin (TN)",
      "Kefazol (TN)",
      "Kefol (TN)",
      "Kefzol (TN)",
      "Kefzolan (TN)",
      "Kezolin (TN)",
      "Novaporin (TN)",
      "Zolicef (TN)",
      "(6R, 7R)-3-[[(5-Methyl-1,3,4-Thiadiazol-2-Yl)Thio]methyl]-8-Oxo-7-[[1H-Tetrazol-1-Yl)Acetyl]amino]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-(((5-Methyl-1,3,4-Thiadiazol-2-Yl)Thio)Methyl)-8-Oxo-7-(2-(1H-Tetrazol-1-Yl)Acetamido)-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-[(5-Methyl-1,3,4-Thiadiazol-2-Yl)Sulfanylmethyl]-8-Oxo-7-[[2-(Tetrazol-1-Yl)Acetyl]amino]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-{[(5-Methyl-1,3,4-Thiadiazol-2-Yl)Sulfanyl]methyl}-8-Oxo-7-[(1H-Tetrazol-1-Ylacetyl)Amino]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-{[(5-Methyl-1,3,4-Thiadiazol-2-Yl)Thio]methyl}-8-Oxo-7-[(1H-Tetrazol-1-Ylacetyl)Amino]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R-Trans)-3-(((5-Methyl-1,3,4-Thiadiazol-2-Yl)Thio)Methyl)-8-Oxo-7-(((1H-Tetrazol-1-Yl)Acetyl)-Amino)-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "3-{[(5-Methyl-1,3,4-Thiadiazol-2-Yl)Sulfanyl]methyl}-7beta-[(1H-Tetrazol-1-Ylacetyl)Amino]-3,4-Didehydrocepham-4-Carboxylic Acid",
      "7-(1-(1H-)-Tetrazolylacetamido)-3-(2-(5-Methyl-1,3,4-Thiadiazolyl)Thiomethyl)Delta3-Cephem-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09KDS",
    names: [
      "Adamantan-1-Yl-(4-Ethyl-Piperazin-1-Yl)-Methanone",
      "CHEMBL396928",
      "AC1N8RTC",
      "1-Adamantyl-(4-Ethylpiperazin-1-Yl)Methanone",
      "Oprea1_784934",
      "MolPort-005-510-679",
      "BDBM50207776",
    ],
  },
  {
    primary_id: "D09KDV",
    names: [
      "Dihydroxyacetone",
      "1,3-Dihydroxyacetone",
      "96-26-4",
      "1,3-Dihydroxypropan-2-One",
      "Chromelin",
      "1,3-Dihydroxy-2-Propanone",
      "Glycerone",
      "Triulose",
      "Viticolor",
      "Soleal",
      "Oxatone",
      "Dihyxal",
      "Oxantin",
      "Otan",
      "2-Propanone, 1,3-Dihydroxy-",
      "1,3-Dihydroxypropanone",
      "1,3-Dihydroxydimethyl Ketone",
      "NSC-24343",
      "Ketochromin",
      "Bis(Hydroxymethyl) Ketone",
      "UNII-O10DDW6JOO",
      "Dihydroxy-Acetone",
      "2-Propanone, 1,3-Dihydroxy",
      "BRN 1740268",
      "CCRIS 4899",
      "AI3-24477",
      "Dihydroxyacetone [USP]",
      "EINECS 202-494-5",
      "O10DDW6JOO",
      "1,2-Dihydroxy-2-Propanone",
      "CHEBI:16016",
      "RXKJFZQQPQGTFL-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D09KEB",
    names: ["PRT-060318"],
  },
  {
    primary_id: "D09KEV",
    names: ["TVX-010"],
  },
  {
    primary_id: "D09KEW",
    names: ["KPL-716"],
  },
  {
    primary_id: "D09KFJ",
    names: ["JWH-229"],
  },
  {
    primary_id: "D09KFR",
    names: ["(S)-Dimethylenastron"],
  },
  {
    primary_id: "D09KGN",
    names: ["PMID18800762C51", "Bisphosphonate, 51", "GTPL3186", "BDBM25304"],
  },
  {
    primary_id: "D09KGQ",
    names: ["Menadiol Sodium Diphosphate"],
  },
  {
    primary_id: "D09KGZ",
    names: ["QG-AGC1"],
  },
  {
    primary_id: "D09KHE",
    names: ["EN3488"],
  },
  {
    primary_id: "D09KHY",
    names: ["Dicarba-Closo-Dodecaborane Derivative"],
  },
  {
    primary_id: "D09KIG",
    names: ["ETX201"],
  },
  {
    primary_id: "D09KIP",
    names: ["Chondrogel", "Chondrocyte-Alginate Gel Suspension"],
  },
  {
    primary_id: "D09KKD",
    names: ["AZD-3118", "Anti-Arrhythmic, AstraZeneca"],
  },
  {
    primary_id: "D09KKF",
    names: ["2-Amino-5-Hydroxy-Benzimidazole"],
  },
  {
    primary_id: "D09KKH",
    names: ["Mepartricin"],
  },
  {
    primary_id: "D09KKU",
    names: ["Lefaxin"],
  },
  {
    primary_id: "D09KLO",
    names: ["AZD-5106"],
  },
  {
    primary_id: "D09KLR",
    names: [
      "Alendronate",
      "Arendal",
      "Fosamax",
      "ALENDRONATE SODIUM",
      "ALENDRONIC ACID",
      "Acide Alendronique",
      "Acido Alendronico",
      "Acidum Alendronicum",
      "MK 217",
      "Acide Alendronique [INN-French]",
      "Acido Alendronico [INN-Spanish]",
      "Acidum Alendronicum [INN-Latin]",
      "Alendronic Acid (INN)",
      "Alendronic Acid [INN:BAN]",
      "Bisphosphonate, 65",
      "Fosamax (TN)",
      "MK-217",
      "Phosphonic Acid, (4-Amino-1-Hydroxybutylidene)Bis",
      "(4-Amino-1-Hydroxybutylidene)Bisphosphonic Acid",
      "(4-Amino-1-Hydroxybutylidene)Diphosphonic Acid",
      "(4-Amino-1-Hydroxy-1-Phosphonobutyl)Phosphonic Acid",
      "(4-Amino-1-Hydroxybutane-1,1-Diyl)Bis(Phosphonic Acid)",
      "4-Amino-1-Hydroxybutane-1,1-Diphosphonate",
      "4-Amino-1-Hydroxybutane-1,1-Diphosphonic Acid",
      "4-Amino-1-Hydroxybutylidene-1,1-Bis(Phosphonic Acid)",
      "4-Amino-1-Hydroxybutylidene-1,1-Bisphosphonate",
    ],
  },
  {
    primary_id: "D09KLX",
    names: ["Cartistem-Adipo"],
  },
  {
    primary_id: "D09KMA",
    names: ["Zofroxia"],
  },
  {
    primary_id: "D09KNG",
    names: [
      "RSV-604",
      "A-60444",
      "A-60445",
      "RSV Therapeutics, Arrow",
      "RSV Therapeutics, Novartis",
      "Respiratory Syncytial Virus Therapeutics, Novartis",
      "Respiratory Syncytial Virus Therapeutics, Arrow/Virogen",
      "Antivirals (RSV), Arrow/ Virogen",
    ],
  },
  {
    primary_id: "D09KQC",
    names: ["Lipid Soluble Antioxidants"],
  },
  {
    primary_id: "D09KQM",
    names: ["PYK2 Inhibitors"],
  },
  {
    primary_id: "D09KQT",
    names: [
      "Ado-P-Ch2-P-Ps-Ado",
      "ADO-P-CH2-P-PS-ADO",
      "P1-P2-Methylene-P3-Thio-Diadenosine Triphosphate",
      "IB2",
      "AC1OAHZQ",
      "DB04389",
      "[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-[[[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphinothioyl]oxy-Hydroxyphosphoryl]methyl]phosphinic Acid",
      "Adenosine 5'-[[alpha,beta-Methylene]diphosphoric Acid Beta-[5'-Adenosyloxy(Mercapto)Phosphinyl]] Ester",
    ],
  },
  {
    primary_id: "D09KQX",
    names: ["SPRMs, Oral, Uterine Fibroids, Tokai Pharmaceuticals"],
  },
  {
    primary_id: "D09KQZ",
    names: [
      "Alpha-Ketoglutaric Acid",
      "2-Ketoglutarate",
      "2-Oxoglutarate",
      "Alpha-Ketoglutarate",
      "2-Ketoglutaric Acid",
      "Oxoglutarate",
    ],
  },
  {
    primary_id: "D09KRA",
    names: ["[125I]SCH23982", "[125I]-SCH23982"],
  },
  {
    primary_id: "D09KRE",
    names: [
      "Bezlotoxumab",
      "CDB1",
      "Clostridium Difficile Toxin B Monoclonal Antibody",
      "MBL-CDB1",
      "MDX-1388",
      "MK 6072",
      "Zinplava",
    ],
  },
  {
    primary_id: "D09KRH",
    names: ["Domoic Acid", "Domoate", "NSC 288031"],
  },
  {
    primary_id: "D09KRU",
    names: ["BI-831266"],
  },
  {
    primary_id: "D09KRV",
    names: ["Adhexil"],
  },
  {
    primary_id: "D09KRX",
    names: [
      "AVR-168",
      "CTK-000168",
      "Eg5 Inhibitor (Cancer), ADVR",
      "Eg5 Inhibitor (Cancer), Advanced Viral Research Corp",
      "Eg5 Inhibitor (Cancer), Cetek",
    ],
  },
  {
    primary_id: "D09KSW",
    names: ["3-CLA", "Anticancer Protein, Adamed Sp Zoo"],
  },
  {
    primary_id: "D09KTE",
    names: ["BCL-004"],
  },
  {
    primary_id: "D09KTF",
    names: ["5-Butyl-2-(Phenylsulfonamido)Benzoic Acid"],
  },
  {
    primary_id: "D09KTI",
    names: ["GS-5816"],
  },
  {
    primary_id: "D09KTN",
    names: [
      "Asp-D-Glu-Leu-Glu-Cha-Cys",
      "CHEMBL303541",
      "AC1LAAJ7",
      "BDBM50096402",
      "Asp-D-Glu-Leu-Glu-Cha(Beta-Cyclohexylanine)-Cys",
      "L-Asp-D-Glu-L-Leu-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "(R)-2-[(S)-2-((S)-2-{(S)-2-[(R)-2-((S)-2-Amino-3-Carboxy-Propanoylamino)-4-Carboxy-Butanoylamino]-4-Methyl-Pentanoylamino}-4-Carboxy-Butanoylamino)-3-Cyclohexyl-Propanoylamino]-3-Mercapto-Propionic Acid",
    ],
  },
  {
    primary_id: "D09KTP",
    names: ["Pseudopeptide Derivative"],
  },
  {
    primary_id: "D09KTS",
    names: ["Ibrutinib"],
  },
  {
    primary_id: "D09KUE",
    names: [
      "NSC-88915",
      "3,20-Dioxopregn-4-En-21-Yl 4-Bromobenzenesulfonate",
      "21170-34-3",
      "NSC88915",
      "CHEMBL387152",
      "AC1L60NG",
      "AC1Q267H",
      "SCHEMBL13856957",
      "CTK1A5243",
      "ZINC4901407",
      "BDBM50158383",
      "AKOS028112969",
      "NCI60_041972",
      "170D343",
      "4-Pregnen-21-Ol-3,20-Dione-21-(4-Bromobenzenesufonate)",
      "Pregn-4-Ene-3,20-Dione, 21-[[(4-Bromophenyl)Sulfonyl]oxy]-",
      "2-((8S,9S,10R,13S,14S,17S)-10,13-Dimethyl-3-Oxo-2,3,6,7,8,9,10,11,12,13,14,15,16,17-Tetradecahydro-1H-Cyclopenta[a]phenanthren-17-Yl)-2-Oxoethyl 4-Bromobenzenesulfonate",
    ],
  },
  {
    primary_id: "D09KUP",
    names: ["(S)-2,3-Epoxysqualene"],
  },
  {
    primary_id: "D09KUY",
    names: [
      "4-Chloro-7-Methyl-2-Phenyl-[1,8]naphthyridine",
      "CHEMBL97778",
      "4-Chloro-7-Methyl-2-Phenyl-1,8-Naphthyridine",
      "SCHEMBL1536334",
      "BDBM50090689",
      "ZINC13579501",
    ],
  },
  {
    primary_id: "D09KVB",
    names: ["ISIS 19221"],
  },
  {
    primary_id: "D09KVO",
    names: [
      "Atypical Antipsychotics",
      "Atypical Antipsychotics (Schizophrenia)",
      "Atypical Antipsychotics (Schizophrenia), Daya Drug Discoveries",
      "5-HT Modulators (Schizophrenia), Daya Drug Discoveries",
    ],
  },
  {
    primary_id: "D09KWM",
    names: ["PMID27454349-Compound-93"],
  },
  {
    primary_id: "D09KWN",
    names: ["Anti-MUC1 CAR-T Cells"],
  },
  {
    primary_id: "D09KWS",
    names: ["Imaging-Theranostic Nanoemulsion Agents"],
  },
  {
    primary_id: "D09KWX",
    names: ["Hl-Con1"],
  },
  {
    primary_id: "D09KXA",
    names: [
      "PD-131112",
      "7-[(3S)-3-Aminopyrrolidin-1-Yl]-1-Cyclopropyl-6-Fluoro-4-Oxo-1,8-Naphthyridine-3-Carboxylic Acid",
      "1,8-Naphthyridine-3-Carboxylic Acid, 7-[(3S)-3-Amino-1-Pyrrolidinyl]-1-Cyclopropyl-6-Fluoro-1,4-Dihydro-4-Oxo-",
      "(S)-7-(3-Amino-1-Pyrrolidinyl)-1-Cyclopropyl-6-Fluoro-1,4-Dihydro-4-Oxo-1,8-Naphthyridine-3-Carboxylic Acid",
      "127967-03-7",
      "Ci-990",
      "1,8-Naphthyridine-3-Carboxylic Acid, 7-((3S)-3-Amino-1-Pyrrolidinyl)-1-Cyclopropyl-6-Fluoro-1,4-Dihydro-4-Oxo-",
      "CI-990 (Hydrochloride)",
      "AC1L2I3J",
      "CHEMBL51678",
      "PD131628",
      "CI-990",
      "[S-(R*,R*)]-7-[3-(2-Aminopropanamido)-1-Pyrrolidinyl]-1-Cyclopropyl-6-Fluoro-4-Oxo-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylic Acid",
      "(S,S)-7-[3-(Alanylamino)-1-Pyrrolidinyl]-1-Cyclopropyl-6-Fluoro-4-Oxo-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylic Acid",
      "122536-20-3",
      "PD-131628",
    ],
  },
  {
    primary_id: "D09KYB",
    names: ["Pyrimidine Derivative 7"],
  },
  {
    primary_id: "D09KYR",
    names: ["RP-49356", "Aprikalim", "Aprim", "Aprikalim)", "RP-52891"],
  },
  {
    primary_id: "D09KYZ",
    names: ["V-24343", "CB1 Antagonists, Vernalis", "Cannabinoid CB1 Receptor Antagonists, Vernalis"],
  },
  {
    primary_id: "D09KZA",
    names: [
      "1-Methyl-5-(4-Phenylazo-Phenyl)-Piperidin-2-One",
      "CHEMBL56908",
      "BDBM50091710",
      "1-Methyl-5-[(E)-Azobenzene-4-Yl]piperidine-2-One",
    ],
  },
  {
    primary_id: "D09KZQ",
    names: ["Zwitterionic Sulfobetaine Derivative"],
  },
  {
    primary_id: "D09LAD",
    names: ["Piperazine Carbamic Compound 3"],
  },
  {
    primary_id: "D09LAQ",
    names: ["Cyclic Guanosine Monophosphate"],
  },
  {
    primary_id: "D09LBS",
    names: ["Sennoside B"],
  },
  {
    primary_id: "D09LDM",
    names: [
      "2-Methyl-4-(M-Tolylethynyl)Thiazole",
      "CHEMBL182950",
      "SCHEMBL4150883",
      "2-Methyl-4-M-Tolylethynyl-Thiazole",
      "ZINC13680880",
    ],
  },
  {
    primary_id: "D09LDR",
    names: [
      "Oxazepam",
      "Abboxampam",
      "Abboxapam",
      "Adumbran",
      "Alepam",
      "Ansioxacepam",
      "Anxiolit",
      "Aplakil",
      "Astress",
      "Azutranguil",
      "Azutranquil",
      "Bonare",
      "Drimuel",
      "Droxacepam",
      "Durazepam",
      "Hilong",
      "Isodin",
      "Lederpam",
      "Limbial",
      "Murelax",
      "Nesontil",
      "Noctazepam",
      "Nortemazepam",
      "Nozepam",
      "Ossazepam",
      "Oxanid",
      "Oxazepamum",
      "Oxazipam",
      "Oxozepam",
      "Pacienx",
      "Praxiten",
      "Propax",
      "Psicopax",
      "Psiquiwas",
      "QUEN",
      "Quilibrex",
      "Rondar",
      "Sedigoa",
      "Serax",
      "Serenal",
      "Serenid",
      "Serepax",
      "Seresta",
      "Serpax",
      "Sigacalm",
      "Sobril",
      "Tacepam",
      "Tarchomin",
      "Tazepam",
      "Uskan",
      "Vaben",
      "Zaxopam",
      "Anxiolit Retard",
      "Ossazepam [DCIT]",
      "P Acienx",
      "CB 8092",
      "DF2371850",
      "O5254_SIGMA",
      "Wy 3498",
      "Z 10 Tr",
      "Alepam (TN)",
      "Apo-Astress",
      "Hi-Long",
      "Medopam (TN)",
      "Murelax (TN)",
      "N-Desmethyltemazepam",
      "Noripam (TN)",
      "Oxa-Puren",
      "Oxazepamum [INN-Latin]",
      "Purata (TN)",
      "Ro 5-6789",
      "Serax (TN)",
      "Serenid-D",
      "Serepax (TN)",
      "WY-3498",
      "Wy-3498 Stic",
      "Z10-Tr",
      "Ox-Pam (TN)",
      "Tranquo-Buscopan-Wirkstoff",
      "Oxazepam (JAN/USP/INN)",
      "Oxazepam [USAN:INN:BAN:JAN]",
      "(+-)-7-Chloro-1,3-Dihydro-3-Hydroxy-5-Phenyl-2H-1,4-Benzodiazepin-2-One",
      "(+-)-Oxazepam",
      "(RS)-Oxazepam",
      "1,3-Dihydro-7-Chloro-3-Hydroxy-5-Phenyl-2H-1,4-Benzodiazepin-2-One",
      "1,3-Dihydro-7-Chloro-3-Hydroxy-5-Phenyl-3H-1,4-Benzodiazepin-2-One",
      "7-Chloro-1,3-Dihydro-3-Hydroxy-5-Phenyl-1,4(2H)-Benzodiazepin-2-One",
      "7-Chloro-1,3-Dihydro-3-Hydroxy-5-Phenyl-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-1,3-Dihydro-3-Hydroxy-5-Phenyl-2H-1,4-Benzodiazepine-2-One",
      "7-Chloro-3-Hydroxy-5-Phenyl-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-3-Hydroxy-5-Phenyl-1,3-Dihydro-1,4-Benzodiazepin-2-One",
    ],
  },
  {
    primary_id: "D09LEB",
    names: ["RG7641"],
  },
  {
    primary_id: "D09LEK",
    names: ["4-(5-Phenyl-Oxazol-2-Ylamino)-Benzenesulfonamide"],
  },
  {
    primary_id: "D09LEN",
    names: ["Pneumococcal 13-Valent Conjugate Vaccine"],
  },
  {
    primary_id: "D09LEP",
    names: ["Pyrethroids"],
  },
  {
    primary_id: "D09LFY",
    names: ["Il-8((3-73))K11R"],
  },
  {
    primary_id: "D09LGT",
    names: ["Kallstroemin D"],
  },
  {
    primary_id: "D09LGV",
    names: ["MT-204", "Anti-IL-2 Antibody (Inflammation/Leukemia/Transplant Rejection), Micromet"],
  },
  {
    primary_id: "D09LHF",
    names: ["C(His-D-Phe-Arg-Trp-Aoc)", "CHEMBL264120"],
  },
  {
    primary_id: "D09LHG",
    names: ["YY-162"],
  },
  {
    primary_id: "D09LHN",
    names: ["Des-AA1,2,5-[IAmp9,Tyr11]-SRIF", "CHEMBL436887", "BDBM50100745"],
  },
  {
    primary_id: "D09LIE",
    names: ["ASIbcl Vaccine"],
  },
  {
    primary_id: "D09LIZ",
    names: ["KI-0502"],
  },
  {
    primary_id: "D09LKE",
    names: ["ISIS 25113"],
  },
  {
    primary_id: "D09LKM",
    names: ["Pyrazolo[1,5-A]pyrimidine 3G"],
  },
  {
    primary_id: "D09LKN",
    names: ["PMID29473428-Compound-53"],
  },
  {
    primary_id: "D09LKR",
    names: [
      "TS-011",
      "339071-18-0",
      "N-(3-Chloro-4-Morpholinophenyl)-N'-Hydroxyformimidamide",
      "AK-59782",
      "Methanimidamide, N-[3-Chloro-4-(4-Morpholinyl)Phenyl]-N'-Hydroxy-",
      "SCHEMBL3983851",
      "CTK1B8099",
      "BDBM86694",
      "DTXSID00431234",
      "MolPort-044-723-707",
      "MolPort-035-681-438",
      "ZINC6746071",
      "AKOS022180267",
      "DS-16349",
      "HY-15603",
      "AJ-56824",
      "CS-0007825",
      "N'-(3-Chloro-4-Morpholinophenyl)-N-Hydroxyformamidine",
      "N-(3-Chloro-4-Morpholinophenyl)-N-Hydroxyformimidamide",
    ],
  },
  {
    primary_id: "D09LKU",
    names: ["NOX-E36"],
  },
  {
    primary_id: "D09LKZ",
    names: ["ISIS 25554"],
  },
  {
    primary_id: "D09LLO",
    names: [
      "[14C]glycine",
      "2-Azaniumylacetate",
      "Glycine Zwitterion",
      "AC1NQXJD",
      "CHEBI:57305",
      "[14C]-Glycine",
      "[3H]glycine",
    ],
  },
  {
    primary_id: "D09LLS",
    names: ["Havrix"],
  },
  {
    primary_id: "D09LMH",
    names: [
      "AL6528",
      "2-(3-Methoxyphenyl)-2h-Thieno-[3,2-E]-1,2-Thiazine-6-Sulfinamide-1,1-Dioxide",
      "2-(3-Methoxyphenyl)-2h-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide 1,1-Dioxide",
      "1bn3",
      "AC1L1BMR",
      "CTK7A9592",
      "BDBM11938",
      "DB04371",
      "2-(3-Methoxyphenyl)-1,1-Dioxothieno[3,2-E]thiazine-6-Sulfonamide",
      "2-(3-Methoxyphenyl)-1,1-Dioxo-2H-1$l^{6},7,2-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide",
      "2-(3-Methoxyphenyl)-6-Sulfamoyl-2H-Thieno[3,2-E]-1,2-Thiazine 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D09LMQ",
    names: ["Vaginal Ring"],
  },
  {
    primary_id: "D09LMX",
    names: ["Revatropate"],
  },
  {
    primary_id: "D09LNI",
    names: [
      "Fluconazole",
      "Afungil",
      "Alflucoz",
      "Baten",
      "Beagyne",
      "Biocanol",
      "Biozolene",
      "Canzol",
      "Cryptal",
      "Diflazon",
      "Diflucan",
      "Dimycon",
      "Elazor",
      "FCZ",
      "FLCZ",
      "Flucazol",
      "FlucoLich",
      "Flucobeta",
      "Fluconazol",
      "Fluconazolum",
      "Flucostat",
      "Flukezol",
      "Flunazul",
      "Flunizol",
      "Fluzone",
      "Forcan",
      "Fuconal",
      "Fungata",
      "Lavisa",
      "Loitin",
      "Mutum",
      "Neofomiral",
      "Oxifugol",
      "Oxifungol",
      "Pritenzol",
      "Solacap",
      "Syscan",
      "TPF",
      "Triflucan",
      "Zemyc",
      "Zoltec",
      "Zonal",
      "AbZ Brand Of Fluconazole",
      "Aliud Brand Of Fluconazole",
      "Alpharma Brand Of Fluconazole",
      "Apo Fluconazole",
      "Apotex Brand Of Fluconazole",
      "Armstrong Brand Of Fluconazole",
      "Betapharm Brand Of Fluconazole",
      "Chemia Brand Of Fluconazole",
      "Ct Arzneimittel Brand Of Fluconazole",
      "Effik Brand Of Fluconazole",
      "Fluc Hexal",
      "Fluconazol AL",
      "Fluconazol AbZ",
      "Fluconazol Isis",
      "Fluconazol Stada",
      "Fluconazol [Spanish]",
      "Fluconazol Ratiopharm",
      "Fluconazol Von Ct",
      "Fluconazolum [Latin]",
      "Hexal Brand Of Fluconazole",
      "Lesvi Brand Of Fluconazole",
      "Lichtenstein Brand Of Fluconazole",
      "Mack Brand Of Fluconazole",
      "Pfizer Brand Of Fluconazole",
      "Pfleger Brand Of Fluconazole",
      "Ratiopharm Brand Of Fluconazole",
      "SAT Brand Of Fluconazole",
      "Silanes Brand Of Fluconazole",
      "Stada Brand Of Fluconazole",
      "Vita Brand Of Fluconazole",
      "F0677",
      "Fluconazole In Combination With MGCD290",
      "UK 49858",
      "UK49858",
      "Alfumet (TN)",
      "Apo-Fluconazole",
      "Ct-Arzneimittel Brand Of Fluconazole",
      "DIFLUCAN IN DEXTROSE 5% IN PLASTIC CONTAINER",
      "DRG-0005",
      "Diflucan (TN)",
      "Fluconazol-Isis",
      "Fluconazol-Ratiopharm",
      "Fluconazole & Bovine Lactoferrin",
      "Fluconazole & Human Recombinant Granulocyte Colony Stimulating Factor",
      "Fluconazole & HGCSF",
      "Fluconazole In Dextrose 5% In Plastic Container",
      "Flucytosine & Nyotran",
      "GL663142 & Fluconazole",
      "KS-1059",
      "Trican (TN)",
      "UK-49858",
      "DIFLUCAN IN SODIUM CHLORIDE 0.9%",
      "DIFLUCAN IN SODIUM CHLORIDE 0.9%IN PLASTIC CONTAINER",
      "Fluconazole In Sodium Chloride 0.9%",
      "Fluconazole In Sodium Chloride 0.9% In Plastic Container",
      "Flucytosine & Nyotran(Liposomal Nystatin)",
      "XMP.284 & Fluconazole",
      "XMP.366 & Fluconazole",
      "XMP.391 & Fluconazole",
      "Diflucan, Trican, Alfumet, Fluconazole",
      "Fluconazole & MC-510,011",
      "Fluconazole (JAN/USAN/INN)",
      "Fluconazole [USAN:INN:BAN:JAN]",
      "2,4-Difluoro-Alpha,alpha-Bis(1H-1,2,4-Triazol-1-Ylmethyl)Benzyl Alcohol",
      "2,4-Difluoro-,1-Bis(1H-1,2,4-Triazol-1-Ylmethyl)Benzyl Alcohol",
      "2-(2,4-DIFLUOROPHENYL)-1,3-DI(1H-1,2,4-TRIAZOL-1-YL)PROPAN-2-OL",
      "2-(2,4-Difluoro-Phenyl)-1,3-Bis-[1,2,4]triazol-1-Yl-Propan-2-Ol",
      "2-(2,4-Difluorophenyl)-1,3-Bis(1H-1,2,4-Triazol-1-Yl)-2-Propanol",
      "2-(2,4-Difluorophenyl)-1,3-Bis(1H-1,2,4-Triazol-1-Yl)Propan-2-Ol",
      "2-(2,4-Difluorophenyl)-1,3-Di-1H-1,2,4-Triazol-1-Ylpropan-2-Ol",
      "2-(2,4-Difluorophenyl)-1,3-Bis(1,2,4-Triazol-1-Yl)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D09LOZ",
    names: ["TTAC-0001"],
  },
  {
    primary_id: "D09LPG",
    names: ["GR 128107"],
  },
  {
    primary_id: "D09LPI",
    names: ["BCG65-E7"],
  },
  {
    primary_id: "D09LPL",
    names: ["SU-11652"],
  },
  {
    primary_id: "D09LPN",
    names: ["Lepirudin"],
  },
  {
    primary_id: "D09LPV",
    names: ["N6022"],
  },
  {
    primary_id: "D09LQF",
    names: ["N-(3,5-Dimethoxyphenyl)-4-Methyl-2-Nitrobenzamide", "CHEMBL558660"],
  },
  {
    primary_id: "D09LQK",
    names: [
      "BQ-123",
      "136553-81-6",
      "BQ123",
      "BQ 123",
      "Cyclo(D-Trp-D-Asp-Pro-D-Val-Leu)",
      "UNII-S2A8YZM151",
      "CHEBI:2965",
      "S2A8YZM151",
      "CHEMBL314691",
      "Cyclo[D-Trp-D-Asp-Pro-D-Val-Leu]",
      "Cyclo(D-Asp-Pro-D-Val-Leu-D-Trp)",
      "Cyclo(-D-Trp-D-Asp-Pro-D-Val-Leu)",
      "Cyclo-[D-Asp-Pro-D-Val-Leu-D-Trp]",
      "Cyclo-(D-Asp-Pro-D-Val-Leu-D-Trp)",
      "Cyclo(D-Trp-D-Asp-L-Pro-D-Val-L-Leu)",
      "Cyclo[D-Trp-D-Asp-L-Pro-D-Val-L-Leu]",
      "Cyclo[(D)Trp-(D)Asp-Pro-(D)Val-Leu]",
      "Cyclo[D-Asp-L-Pro-D-Val-L-Leu-D-Trp]",
      "Cyclo(D-Asp-L-Pro-D-Val-L-Leu-D-Trp)",
    ],
  },
  {
    primary_id: "D09LQP",
    names: ["RG-14893"],
  },
  {
    primary_id: "D09LRA",
    names: ["Omega-Conotoxin GVIA"],
  },
  {
    primary_id: "D09LRO",
    names: ["1-Benzhydryl-4-(3-Fluorophenyl)Piperidin-4-Ol", "CHEMBL395838"],
  },
  {
    primary_id: "D09LSR",
    names: [
      "1,2-Bis(3,5-Difluorophenyl)Ethane-1,2-Dione",
      "CHEMBL193229",
      "223707-22-0",
      "Benzil-Based Compound, 12",
      "SCHEMBL2156965",
      "BDBM22734",
    ],
  },
  {
    primary_id: "D09LSX",
    names: [
      "KU-0058948",
      "CHEMBL380648",
      "4-[3-(1,4-Diazepan-1-Ylcarbonyl)-4-Fluorobenzyl]phthalazin-1(2H)-One",
      "4-(3-(1,4-Diazepane-1-Carbonyl)-4-Fluorobenzyl)Phthalazin-1(2H)-One",
      "Homopiperazine Analogue, 14",
      "SCHEMBL864319",
      "BDBM27533",
      "HGEPGGJUGUMFHT-UHFFFAOYSA-N",
      "ZINC3821234",
      "DB08058",
      "NCGC00386677-01",
      "KU-58948",
      "FT-0670691",
      "TL80090044",
      "4-[3-([1,4]diazepane-1-Carbonyl)-4 -Fluorobenzyl]-2H-Phthalazin-1-One",
      "4-[3-([1,4]diazepane-1-Carbonyl)-4-Fluorobenzyl]-2H-Phthalazin-1-One",
    ],
  },
  {
    primary_id: "D09LSY",
    names: ["EDP-322"],
  },
  {
    primary_id: "D09LTC",
    names: ["Non GC-C"],
  },
  {
    primary_id: "D09LTO",
    names: ["A-76341"],
  },
  {
    primary_id: "D09LUD",
    names: [
      "4-[2-(3-Phenyl-Ureido)-Ethyl]-Benzenesulfonamide",
      "CHEMBL22374",
      "ST50982048",
      "1-Phenyl-3-[2-(4-Sulfamoylphenyl)Ethyl]urea",
      "10080-04-3",
      "AC1N65GX",
      "CTK0G8621",
      "Aromatic Sulfonamide Compound 21",
      "BDBM16656",
      "DTXSID80401056",
      "ZINC6344454",
      "AKOS024355000",
      "1-(4-Sulfamoylphenethyl)-3-Phenylurea",
      "MCULE-8492301896",
      "N-(4-Aminosulfonylphenylethyl)-N'-Phenyl-Urea",
      "N-Phenyl{[2-(4-Sulfamoylphenyl)Ethyl]amino}carboxamide",
      "Benzenesulfonamide, 4-[2-[[(Phenylamino)Carbonyl]amino]ethyl]-",
    ],
  },
  {
    primary_id: "D09LVD",
    names: ["ISIS-APOARx"],
  },
  {
    primary_id: "D09LVG",
    names: ["4-(1H-Inden-1-Ylmethyl)-Phenylamine", "CHEMBL193637"],
  },
  {
    primary_id: "D09LVK",
    names: ["TDI-0026"],
  },
  {
    primary_id: "D09LVP",
    names: ["O-1812"],
  },
  {
    primary_id: "D09LVX",
    names: [
      "Bis(4-Fluorophenyl)-2-Fluorophenylacetamide",
      "CHEMBL272039",
      "SCHEMBL1443968",
      "VDPKWHHLYDETTO-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D09LVY",
    names: ["9-Mercapto-8-Oxo-N-Phenylnonanamide", "CHEMBL402451"],
  },
  {
    primary_id: "D09LWP",
    names: ["PMID26651364-Compound-105"],
  },
  {
    primary_id: "D09LWR",
    names: [
      "4-(2-Phenylacetamidoethyl)Benzenesulfonamide",
      "AC1MPEEB",
      "Sulfonamide Deriv., 7c",
      "Oprea1_837732",
      "CHEMBL573859",
      "BDBM35735",
      "AKOS027674184",
    ],
  },
  {
    primary_id: "D09LWS",
    names: ["Ezetimibe"],
  },
  {
    primary_id: "D09LWZ",
    names: ["Mannoside Derivative 7"],
  },
  {
    primary_id: "D09LXC",
    names: ["ISIS 23414"],
  },
  {
    primary_id: "D09LXI",
    names: ["[Mpa1, D-Tyr(Et)2, D-Tic7, D-Tic9]OT", "CHEMBL434193"],
  },
  {
    primary_id: "D09LXL",
    names: ["Ac-His-DPhe(PBr)-Arg-Trp-NH2", "CHEMBL502093"],
  },
  {
    primary_id: "D09LXQ",
    names: [
      "(S)-5-Fluorowillardiine",
      "5-Fluorowillardiine",
      "(S)-(-)-5-Fluorowillardiine",
      "140187-23-1",
      "FLUORO-WILLARDIINE",
      "(S)-F-Willardiine",
      "(S)-5-FLUOROWILLARDIINE",
      "2-AMINO-3-(5-FLUORO-2,4-DIOXO-3,4-DIHYDRO-2H-PYRIMIDIN-1-YL)-PROPIONIC ACID",
      "CHEMBL123132",
      "CHEBI:42549",
      "3-(5-Fluoro-2,4-Dioxo-3,4-Dihydropyrimidin-1(2H)-Yl)-L-Alanine",
      "(S)-Alpha-Amino-5-Fluoro-3,4-Dihydro-2,4-Dioxo-1(2H)-Pyrimmidinepropanoic Acid",
      "3-(5-Fluorouracil-1-Yl)-L-Alanine",
      "(2S)-2-Amino-3-(5-Fluoro-2,4-Dioxo-1,2,3,4-Tetrahydropyrimidin-1-Yl)Propanoic Acid",
      "(S)-(-)-5-Fluorowillardine",
    ],
  },
  {
    primary_id: "D09LXU",
    names: ["ISIS 6739"],
  },
  {
    primary_id: "D09LZA",
    names: ["CS-4771"],
  },
  {
    primary_id: "D09LZN",
    names: ["ACER-001"],
  },
  {
    primary_id: "D09LZT",
    names: ["Levomepromazine"],
  },
  {
    primary_id: "D09MAB",
    names: ["Anti-CD5 Humanized Mab", "Anti-CD5 Humanized MAb (Cancer)"],
  },
  {
    primary_id: "D09MAS",
    names: ["FR-901483"],
  },
  {
    primary_id: "D09MAZ",
    names: ["SX-517"],
  },
  {
    primary_id: "D09MBJ",
    names: ["North Chicago"],
  },
  {
    primary_id: "D09MBV",
    names: ["H-Dmt-Tic-NH-(S)CH(CH2-COOH)-Bid(N1-Me)"],
  },
  {
    primary_id: "D09MCN",
    names: ["Haloxysterol D"],
  },
  {
    primary_id: "D09MDB",
    names: ["4-(4-Chlorophenyl)-5-P-Tolyl-1,2-Selenazole", "CHEMBL471171"],
  },
  {
    primary_id: "D09MDO",
    names: [
      "4-Amino-N-[4-(Benzyloxy)Phenyl]butanamide",
      "CHEMBL478718",
      "Modified Amino Acid Analog, 7a",
      "BDBM24263",
      "AKOS010509844",
      "DB07104",
      "N-[4-(Benzyloxy)Phenyl]-4-Aminobutanamide",
      "J3603388F",
      "4-Amino-N-[4-(Phenylmethoxy)Phenyl]butanamide",
    ],
  },
  {
    primary_id: "D09MED",
    names: ["Toca 511/Toca FC"],
  },
  {
    primary_id: "D09MEU",
    names: ["Debio-0721"],
  },
  {
    primary_id: "D09MEV",
    names: ["JES-9501", "DHED", "Dehydroevodiamine", "Dehydroevodiamine Hydrochloride"],
  },
  {
    primary_id: "D09MFV",
    names: ["KC-7F2"],
  },
  {
    primary_id: "D09MGF",
    names: [
      "1-(Benzyloxy)-2-(2-Phenylethyl)Benzene",
      "CHEMBL215010",
      "SCHEMBL4183309",
      "1-(Benzyloxy)-2-Phenethylbenzene",
    ],
  },
  {
    primary_id: "D09MGR",
    names: [
      "Etoricoxib",
      "Algix",
      "Arcoxia",
      "Etoricoxibe",
      "Nucoxia",
      "Tauxib",
      "L791456",
      "MK 0663",
      "MK 663",
      "Algix (TN)",
      "Arcoxia (TN)",
      "L-791456",
      "MK-0663",
      "MK-663",
      "Merck Sharp & Dohme Brand Of Etoricoxib",
      "Tauxib (TN)",
      "Etoricoxib (USAN/INN)",
      "Etoricoxib [USAN:INN:BAN]",
      "5-Chloro-6'-Methyl-3-(P-(Methylsulfonyl)Phenyl)-2,3'-Bipyridine",
      "5-Chloro-2-(6-Methylpyridin-3-Yl)-3-(4-Methylsulfonylphenyl)Pyridine",
      "5-Chloro-6'-Methyl-3-[4-(Methylsulfonyl)Phenyl]-2,3'-Bipyridine",
      "5CH",
    ],
  },
  {
    primary_id: "D09MHC",
    names: ["CJC-1134-PC"],
  },
  {
    primary_id: "D09MHN",
    names: [
      "Ixmyelocel-T",
      "Bone Marrow-Derived Stem Cell Therapy, Aastrom",
      "Bone Marrow-Derived Stem Cell Therapy (Limb Ischemia), Aastrom",
      "Bone Marrow-Derived CRCs (Cardiac Regeneration/Dilated Cardiomyopathy), Aastrom",
      "Bone Marrow-Derived Cardiac Repair Cells (Cardiac Regeneration/Dilated Cardiomyopathy), Aastrom",
      "Bone Marrow-Derived Stem Cell Therapy (Cardiac Regeneration/Dilated Cardiomyopathy), Aastrom",
      "Bone Marrow-Derived TRC's (Cardiac Regeneration/Dilated Cardiomyopathy), Aastrom",
      "Bone Marrow-Derived Tissue Repair Cells, (Cardiac Regeneration/Dilated Cardiomyopathy), Aastrom",
    ],
  },
  {
    primary_id: "D09MHS",
    names: ["3-Demethoxy-3D-Glucopyranosylaminothiocolchicine", "CHEMBL438794"],
  },
  {
    primary_id: "D09MHU",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 6"],
  },
  {
    primary_id: "D09MIN",
    names: ["LCL161"],
  },
  {
    primary_id: "D09MJH",
    names: ["SEA-CD40"],
  },
  {
    primary_id: "D09MJJ",
    names: ["RP4010"],
  },
  {
    primary_id: "D09MJO",
    names: ["HL-004"],
  },
  {
    primary_id: "D09MJX",
    names: ["PMID30247903-Compound-General Structure41"],
  },
  {
    primary_id: "D09MKH",
    names: ["TRI-102"],
  },
  {
    primary_id: "D09MKL",
    names: ["TD-1607"],
  },
  {
    primary_id: "D09MKQ",
    names: ["AMG 317"],
  },
  {
    primary_id: "D09MLQ",
    names: ["PMID22153340C20"],
  },
  {
    primary_id: "D09MOD",
    names: ["NNC-252504"],
  },
  {
    primary_id: "D09MON",
    names: ["A-75998", "CHEMBL263839"],
  },
  {
    primary_id: "D09MPD",
    names: ["ALX-0651", "Anti-CXCR4 Nanobodies (Cancer), Ablynx"],
  },
  {
    primary_id: "D09MPN",
    names: ["TDI-0087"],
  },
  {
    primary_id: "D09MPO",
    names: ["HLD100"],
  },
  {
    primary_id: "D09MPU",
    names: ["Astromycin Sulfate"],
  },
  {
    primary_id: "D09MPX",
    names: ["SPF66"],
  },
  {
    primary_id: "D09MQF",
    names: [
      "3,4,5-Trimethoxy-4'-Amino-Trans-Stilbene",
      "CHEMBL87477",
      "SCHEMBL4263050",
      "MWXBWYJFAGYFKS-SNAWJCMRSA-N",
      "ZINC26575008",
      "BDBM50322046",
      "(E)-4'-Amino-3,4,5-Trimethoxystilbene",
      "3,4,5-Trimethoxy-4''-Amino-Trans-Stilbene",
      "4-[2-(3,4,5-Trimethoxy-Phenyl)-Vinyl]-Phenylamine",
    ],
  },
  {
    primary_id: "D09MQQ",
    names: ["CDD-0199-J"],
  },
  {
    primary_id: "D09MQU",
    names: ["OGX-225"],
  },
  {
    primary_id: "D09MQY",
    names: ["CKD-403"],
  },
  {
    primary_id: "D09MQZ",
    names: ["4-(5-(4-Methoxyphenyl)Thiophen-2-Yl)Phenol", "CHEMBL381991"],
  },
  {
    primary_id: "D09MRD",
    names: ["MGN-4220"],
  },
  {
    primary_id: "D09MRL",
    names: ["Pidilizumab", "CT-011"],
  },
  {
    primary_id: "D09MRY",
    names: ["ACU-0101979"],
  },
  {
    primary_id: "D09MSA",
    names: [
      "4-Chloro-N-(4-Chloro-Benzoyl)-Benzenesulfonamide",
      "BAS 00692952",
      "AC1LHFP5",
      "Oprea1_686071",
      "Oprea1_072837",
      "CHEMBL365275",
      "SCHEMBL10989349",
      "MolPort-000-293-593",
      "ZINC364345",
      "AKOS001022179",
      "MCULE-2798437162",
      "4-Chloro-N-(4-Chlorophenyl)Sulfonylbenzamide",
      "4-Chloro-N-((4-Chlorophenyl)Sulfonyl)Benzamide",
      "SR-01000319886",
      "SR-01000319886-1",
    ],
  },
  {
    primary_id: "D09MSE",
    names: ["JNJ-38224342"],
  },
  {
    primary_id: "D09MSF",
    names: [
      "2-Tert-Butyl-9H-Carbazole",
      "2-TERT-BUTYL-9H-CARBAZOLE",
      "69386-36-3",
      "CHEMBL1173703",
      "2-(Tert-Butyl)-9H-Carbazole",
      "2-Tert-Butylcarbazole",
      "SCHEMBL10312973",
      "CTK2F2146",
      "DTXSID90499227",
      "MolPort-005-941-197",
      "ZINC22003756",
      "BDBM50322580",
      "AKOS015967050",
    ],
  },
  {
    primary_id: "D09MSN",
    names: ["1-Phenyl-1,3,4-Triazole Derivative 1"],
  },
  {
    primary_id: "D09MTC",
    names: ["ADL-5945"],
  },
  {
    primary_id: "D09MTV",
    names: [
      "4-(Dimethylamino)-N-(Thiazol-2-Yl)Benzamide",
      "4-(Dimethylamino)-N-(1,3-Thiazol-2-Yl)Benzamide",
      "AC1M7A6S",
      "CHEMBL425124",
      "MolPort-004-070-500",
      "ZINC3346941",
      "AKOS017059191",
      "MCULE-5265697892",
      "N-(Thiazole-2-Yl)-4-(Dimethylamino)Benzamide",
      "Z28173761",
    ],
  },
  {
    primary_id: "D09MUM",
    names: ["Bisquaternary Salt"],
  },
  {
    primary_id: "D09MUS",
    names: ["JBD-201"],
  },
  {
    primary_id: "D09MVE",
    names: [
      "1-Biphenyl-2-Ylmethanamine",
      "2-Phenylbenzylamine",
      "1924-77-2",
      "[1,1'-Biphenyl]-2-Ylmethanamine",
      "[1,1'-Biphenyl]-2-Methanamine",
      "(2-Phenylphenyl)Methanamine",
      "Biphenyl-2-Methanamine",
      "CHEMBL257036",
      "(2-Phenylphenyl)Methylamine",
      "1-(Biphenyl-2-Yl)Methanamine",
      "Biphenyl-2-Ylmethanamine Hydrochloride",
      "NSC97774",
      "2-Phenylbenzyl Amine",
      "2-Aminomethylbiphenyl",
      "Biphenyl-2-Ylmethylamine",
      "Biphenyl-2-Ylmethanamine",
      "AC1Q1IHD",
      "(2-Biphenylylmethyl)Amine",
      "2-Phenylbenzylamine ,98%",
      "2-Phenyl-Phenylmethyl Amine",
      "C-Biphenyl-2-Yl-Methylamine",
      "SCHEMBL469051",
    ],
  },
  {
    primary_id: "D09MVZ",
    names: ["Mab17-1a"],
  },
  {
    primary_id: "D09MWG",
    names: ["ETIOCHOLANOLONE"],
  },
  {
    primary_id: "D09MWJ",
    names: [
      "Alfuzosin",
      "Alfusosine",
      "Alfuzosina",
      "Alfuzosine",
      "Alfuzosinum",
      "Alphuzosine",
      "Alfuzosina [Spanish]",
      "Alfuzosine [French]",
      "Alfuzosinum [Latin]",
      "Alfuzosin (INN)",
      "Alfuzosin [INN:BAN]",
      "Xatral (TN)",
      "N-[3-[(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)-Methylamino]propyl]oxolane-2-Carboxamide",
      "N-(3-((4-Amino-6,7-Dimethoxy-2-Quinazolinyl)Methylamino)Propyl)Tetrahydro-2-Furancarboxamide",
      "N-{3-[(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)(Methyl)Amino]propyl}tetrahydrofuran-2-Carboxamide",
    ],
  },
  {
    primary_id: "D09MXE",
    names: ["TCK-1"],
  },
  {
    primary_id: "D09MXK",
    names: [
      "3-(Trifluoromethyl)-9H-Carbazole",
      "2467-83-6",
      "CHEMBL1170444",
      "3-Trifluoromethyl-9H-Carbazole",
      "SCHEMBL4130017",
      "JGFJDDMRCMCZMU-UHFFFAOYSA-N",
      "CS-B1171",
      "9H-Carbazole, 3-(Trifluoromethyl)-",
      "BDBM50322575",
      "ZINC53243063",
    ],
  },
  {
    primary_id: "D09MXM",
    names: [
      "SEMORPHONE HYDROCHLORIDE",
      "(-)-4,5alpha-Epoxy-3,14-Dihydroxy-17-(2-Methoxyethyl)Morphinan-6-One Hydrochloride",
      "(-)-4,5alpha-Epoxy-3,14-Dihydroxy-N-(2-Methoxyethyl)Morphinan-6-One Hydrochloride",
      "MR-2264 (Free Base)",
      "MRZ-2264 (Free Base)",
      "N-(2-Methoxyethyl)Noroxymorphone Hydrochloride",
      "Semorphone Hydrochloride",
    ],
  },
  {
    primary_id: "D09MXS",
    names: [
      "Sorbitol",
      "D-Sorbitol",
      "D-Glucitol",
      "50-70-4",
      "Glucitol",
      "L-Gulitol",
      "(-)-Sorbitol",
      "Glucarine",
      "Sorbilande",
      "Diakarmon",
      "Sorbostyl",
      "Multitol",
      "Nivitin",
      "Karion",
      "Esasorb",
      "Neosorb",
      "D-(-)-Sorbitol",
      "Sorbol",
      "Cholaxine",
      "Sorbite",
      "Sionit",
      "Sionite",
      "Siosan",
      "Sionon",
      "Karion Instant",
      "Sorbitol F",
      "Sorbitol FP",
      "Sorbex Rp",
      "Sorbitol Syrup C",
      "Sorbex X",
      "Sorbex R",
      "Sorbex M",
      "Sorbex S",
      "Sionit K",
      "D-Sorbol",
      "Hexahydric Alcohol",
      "Sorbicolan",
      "Sorvilande",
      "Neosorb P 60",
      "D-Sorbite",
      "Foodol D 70",
      "Neosorb 20/60DC",
      "Neosorb 70/70",
      "Neosorb 70/02",
      "Neosorb P 20/60",
      "D-Sorbit",
      "Karion (Carbohydrate)",
    ],
  },
  {
    primary_id: "D09MYF",
    names: ["N-Acetylalanine"],
  },
  {
    primary_id: "D09MYJ",
    names: [
      "3-Isobutoxy-9H-Beta-Carboline",
      "CHEMBL87334",
      "CHEMBL1269093",
      "ZINC13728526",
      "BDBM50001470",
      "3-Isobutoxy-9H-Pyrido[3,4-B]indole",
    ],
  },
  {
    primary_id: "D09MYQ",
    names: [
      "NSC-93358",
      "CHEMBL583076",
      "NCIOpen2_006063",
      "AC1L655A",
      "CTK7H3397",
      "BDBM50298490",
      "3a,5b,10-Trimethyl-1,2,4,5,5a,6,7,10,10a,10b-Decahydrocyclopenta[a]fluorene-3,8-Dione",
      "3a,5b,10-Trimethyl-1,2,3a,4,5,5a,5b,6,7,10,10a,10b-Dodecahydrocyclopenta[a]fluorene-3,8-Dione",
    ],
  },
  {
    primary_id: "D09NAF",
    names: ["AV 133"],
  },
  {
    primary_id: "D09NAJ",
    names: ["Phosphoramidon"],
  },
  {
    primary_id: "D09NAW",
    names: ["PF-05105679"],
  },
  {
    primary_id: "D09NBH",
    names: ["ICT-121"],
  },
  {
    primary_id: "D09NBM",
    names: ["SB-435495"],
  },
  {
    primary_id: "D09NBN",
    names: [
      "PD-161182",
      "CHEMBL44229",
      "GTPL5767",
      "CAM 5187",
      "BDBM50050670",
      "PD161182",
      "PD 161182",
      "[(S)-2-(2,3-Difluoro-Phenyl)-1-Methyl-1-(7-Ureido-Heptylcarbamoyl)-Ethyl]-Carbamic Acid (S)-2-Methyl-1-Phenyl-Propyl Ester",
      "[(1S)-2-Methyl-1-Phenylpropyl] N-[(2S)-1-[7-(Carbamoylamino)Heptylamino]-3-(2,3-Difluorophenyl)-2-Methyl-1-Oxopropan-2-Yl]carbamate",
    ],
  },
  {
    primary_id: "D09NBP",
    names: ["(3-Bromobenzoylpyridine)Thiosemicarbazone", "CHEMBL1269715"],
  },
  {
    primary_id: "D09NCH",
    names: [
      "AM-230",
      "DAR Program (Rheumatoid Arthritis/Osteoarthritis)",
      "Anti-Inflammatory (Rheumatoid Arthritis/Osteoarthritis), AnaMar",
      "DAR Program (Rheumatoid Arthritis/Osteoarthritis), AnaMar Medical/AcurePharma",
    ],
  },
  {
    primary_id: "D09NCT",
    names: ["PMID25468267-Compound-46"],
  },
  {
    primary_id: "D09NCU",
    names: ["GS-6624"],
  },
  {
    primary_id: "D09NCW",
    names: ["Sulfanilamide Derivative"],
  },
  {
    primary_id: "D09NDM",
    names: ["IN-001", "Dermatological Agent (Gel Formulation, Vasculogenic Erectile Dysfunction), Inpharma"],
  },
  {
    primary_id: "D09NEE",
    names: ["BVT-115959", "Adenosine A2a Agonists, Biovitrum", "CBT-1008", "Pain Therapy, Cambridge Biotechnology"],
  },
  {
    primary_id: "D09NEH",
    names: ["6-Methyl-4-(4-Benzoylpiperazin-1-Yl)Coumarin", "CHEMBL461382"],
  },
  {
    primary_id: "D09NEL",
    names: ["CWF-0804"],
  },
  {
    primary_id: "D09NEP",
    names: ["MRS1088", "3,6-Dichloroflavone", "MRS-1088", "NSC-74876"],
  },
  {
    primary_id: "D09NEW",
    names: ["Vaccine, Gastrointestinal Cancers"],
  },
  {
    primary_id: "D09NFD",
    names: ["GSK-5182"],
  },
  {
    primary_id: "D09NFI",
    names: ["Steroid Derivative 3"],
  },
  {
    primary_id: "D09NFN",
    names: [
      "Pentetate Calcium Trisodium",
      "Calcium Trisodium Pentetate",
      "Trisodium Calcium Diethylenetriaminepentaacetate",
      "Ca-DTPA",
    ],
  },
  {
    primary_id: "D09NFR",
    names: ["ISIS 29257"],
  },
  {
    primary_id: "D09NFW",
    names: [
      "Dendritic Cell Vaccine",
      "Dendritic Cell Vaccine, University Of Bonn",
      "Human Telomerase Reverse Transcriptase (HTERT)-Pulsed Dendritic Cells (Cancer), University Of Bonn",
    ],
  },
  {
    primary_id: "D09NFX",
    names: ["Nicotinaldehyde O-4-Propoxyphenylcarbamoyl Oxime", "CHEMBL598545"],
  },
  {
    primary_id: "D09NFY",
    names: [
      "[125I]SQ-29548",
      "GTPL1983",
      "(5Z)-7-[(1S,2R,3R,4R)-3-{[({[4-(125I)Iodophenyl]carbamoyl}amino)Amino]methyl}-7-Oxabicyclo[2.2.1]heptan-2-Yl]hept-5-Enoic Acid",
    ],
  },
  {
    primary_id: "D09NGF",
    names: [
      "Bis-(Indole) Antibacterials",
      "BIAI Antibacterials, Microbiotix",
      "MBX-1066",
      "MBX-1090",
      "MBX-1113",
      "MBX-1128",
      "MBX-1158",
      "MBX-1162",
      "MBX-1196",
      "MBX-1339",
      "MBX-1634",
      "MBX-1643",
      "MBX-1816",
      "MBX-1857",
      "MBX-1886",
      "MBX-1888",
      "MBX-1906",
      "MBX-1912",
      "MBX-1914",
      "Bis-Imidazolinylindole Antibacterials, Microbiotix",
      "Bis-(Azolylindole) Antibacterials, Microbiotix",
      "Bis-(Indole) Antibacterials, Microbiotix",
    ],
  },
  {
    primary_id: "D09NGN",
    names: [
      "RU79256",
      "D-Sulfophenylacetic Acid",
      "PHENYL(SULFO)ACETIC ACID",
      "39925-38-7",
      "1o4q",
      "AC1L9KY4",
      "(R)-Phenyl(Sulfo)Acetic Acid",
      "(2R)-Phenyl(Sulfo)Ethanoic Acid",
      "ZINC1681752",
      "(2R)-2-Phenyl-2-Sulfoacetic Acid",
      "DB01866",
      "[R,(?)]-Alpha-Sulfobenzeneacetic Acid",
      "AJ-29699",
      "Benzeneacetic Acid, Alpha-Sulfo-, (AlphaR)-",
    ],
  },
  {
    primary_id: "D09NHS",
    names: ["PfCS-102"],
  },
  {
    primary_id: "D09NIA",
    names: [
      "Chlorthalidone",
      "Chlorothalidone",
      "Chlorphthalidolone",
      "Chlorphthalidone",
      "Chlortalidone",
      "Chlortalidonum",
      "Chlorthalidon",
      "Clortalidona",
      "Clortalidone",
      "Famolin",
      "Higroton",
      "Hygroton",
      "Igroton",
      "Isoren",
      "Natriuran",
      "Oradil",
      "Oxodolin",
      "Oxodoline",
      "Phthalamodine",
      "Phthalamudine",
      "Renon",
      "Saluretin",
      "Thalitone",
      "Urolin",
      "Zambesil",
      "Clortalidone [DCIT]",
      "Racemic Chlorthalidone",
      "G 33182",
      "Chlortalidonum [INN-Latin]",
      "Chlorthalidone (USP)",
      "Clortalidona [INN-Spanish]",
      "G-33182",
      "Hydro-Long",
      "Hygroton (TN)",
      "Oksodolin (Oxodolin)",
      "Thalitone (TN)",
      "Chlortalidone (JAN/INN)",
      "Chlorthalidone [USAN:INN:BAN]",
      "(+-)-Chlorthalidone",
      "(+-)-Hygroton",
      "1-Keto-3-(3'-Sulfamyl-4'-Chlorophenyl)-3-Hydroxyisoindoline",
      "1-Oxo-3-(3-Sulfamyl-4-Chlorophenyl)-3-Hydroxyisoindoline",
      "2-Chloro-5-(1-Hydroxy-3-Oxo-1-Isoindolinyl)Benzenesulfonamide",
      "2-Chloro-5-(1-Hydroxy-3-Oxo-2,3-Dihydro-1H-Isoindol-1-Yl)Benzenesulfonamide",
      "2-Chloro-5-(1-Hydroxy-3-Oxo-2H-Isoindol-1-Yl)Benzenesulfonamide",
      "2-Chloro-5-(2,3-Dihydro-1-Hydroxy-3-Oxo-1H-Isoindol-1-Yl)Benzenesulfonamide",
      "3-(4'-Chloro-3'-Sulfamoylphenyl)-3-Hydroxyphthalimidine",
      "3-Hydroxy-3-(4-Chloro-3-Sulfamylphenyl)Phthalimidine",
    ],
  },
  {
    primary_id: "D09NIB",
    names: [
      "Epipodophyllotoxin",
      "4375/7/9",
      "BRN 0099162",
      "Furo(3',4':6,7)Naphtho(2,3-D)-1,3-Dioxol-6(5aH)-One, 5,8,8a,9-Tetrahydro-9-Hydroxy-5-(3,4,5-Trimethoxyphenyl)-, (5R-(5-Alpha,5a-Beta,8a-Alpha,9-Beta))-",
      "Furo(3',4':6,7)Naphtho(2,3-D)-1,3-Dioxol-6(5aH)-One, 5,8,8a,9-Tetrahydro-9-Hydroxy-5-(3,4,5-Trimethoxyphenyl)-, (5R,5aR,8aR,9S)-",
      "Etopside",
      "C22H22O8",
      "(-)-Epipodophyllotoxin",
      "AC1L2Y2E",
      "SCHEMBL5792464",
      "CHEMBL282256",
      "ZINC2572657",
      "LS-70861",
      "AJ-42064",
      "137513-EP2272827A1",
      "137513-EP2292088A1",
      "Podophyllotoxin, (5R-(5 Alpha,5a",
    ],
  },
  {
    primary_id: "D09NIM",
    names: ["Benzyl 2-Hydroxyiminoolean-12-En-28-Oate", "2-(Hydroxyimino)Oleana-12-Ene-28-Oic Acid Benzyl Ester"],
  },
  {
    primary_id: "D09NIY",
    names: ["Trolamine Polypeptide Oleate Condensate", "Cerumenex"],
  },
  {
    primary_id: "D09NJA",
    names: ["HL-157"],
  },
  {
    primary_id: "D09NJF",
    names: [
      "Trifluoroethanol",
      "2,2,2-TRIFLUOROETHANOL",
      "75-89-8",
      "Ethanol, 2,2,2-Trifluoro-",
      "Trifluoroethyl Alcohol",
      "2,2,2-Trifluoroethan-1-Ol",
      "Fluorinol 85",
      "2,2,2-Trifluoroethyl Alcohol",
      "Trifluoro Ethanol",
      "TFE",
      "Perfluoro-1,1-Dihydroethanol",
      "Tfetoh",
      "NSC 451",
      "CF3CH2OH",
      "2,2,2-Trifluoro-Ethanol",
      "Beta,beta,beta-Trifluoroethyl Alcohol",
      "UNII-8T8I76KYF1",
      "TFEA",
      "2,2,2-TRIFLUORETHANOL",
      "1h,1h-Trifluoroethanol",
      "EINECS 200-913-6",
      "BRN 1733203",
      "Ethanol, 2,2,2-Trifluoro",
      "AI3-25486",
      "8T8I76KYF1",
      "CHEBI:42330",
      "RHQDFWAXVIIEBN-UHFFFAOYSA-N",
      "MFCD00004672",
    ],
  },
  {
    primary_id: "D09NKU",
    names: ["HG-1384", "Chemokine Modulator, Human Genome Sciences"],
  },
  {
    primary_id: "D09NKX",
    names: ["ANA-246"],
  },
  {
    primary_id: "D09NLC",
    names: ["Guar Gum"],
  },
  {
    primary_id: "D09NLK",
    names: ["Minokine"],
  },
  {
    primary_id: "D09NLT",
    names: ["ISIS 188763"],
  },
  {
    primary_id: "D09NMD",
    names: ["Lasofoxifene"],
  },
  {
    primary_id: "D09NNA",
    names: [
      "Dromostanolone",
      "Drostanolo",
      "Drostanolon",
      "Drostanolona",
      "Drostanolone",
      "Drostanolonum",
      "Medrosteron",
      "Medrotestron",
      "Methalone",
      "Metholone",
      "Prometholone",
      "Drostanolo [DCIT]",
      "SKI 27719",
      "Drolban (TN)",
      "Drostanolona [INN-Spanish]",
      "Drostanolone [INN:BAN]",
      "Drostanolonum [INN-Latin]",
      "Dihydro-2alpha-Methyltestosterone",
      "Dihydro-2-Alpha-Methyltestosterone",
      "Dihydro-2.alpha.-Methyltestosterone",
      "Androstan-3-One, 17-Hydroxy-2-Methyl-, (2alpha,5alpha,17beta)-(9CI)",
      "(2R,5S,8R,9S,10S,13S,14S,17S)-17-Hydroxy-2,10,13-Trimethyl-1,2,4,5,6,7,8,9,11,12,14,15,16,17-Tetradecahydrocyclopenta[a]phenanthren-3-One",
      "(2alpha,5alpha,17beta)-17-Hydroxy-2-Methylandrostan-3-One",
      "17 Beta-Hydroxy-2 Alpha-Methyl-5 Alpha-Androstan-3-One",
      "17-Beta-Hydroxy-2-Alpha-Methyl-5-Alpha-Androstan-3-One",
      "17beta-Hydroxy-2alpha-Methyl-5alpha-Androstan-3-One",
      "2-Alpha-Methyl-17-Beta-Hydroxy-5-Alpha-Androstan-3-One",
      "2-Alpha-Methyldihydrotestosterone",
      "2.alpha.-Methyldihydrotestosterone",
      "2a-Methylandrostan-17b-Ol-3-One",
      "2alpha-Methyldihydrotestosterone",
    ],
  },
  {
    primary_id: "D09NNH",
    names: ["Dihydroergocristine"],
  },
  {
    primary_id: "D09NNI",
    names: [
      "3-Methoxy-9H-Beta-Carboline",
      "3-Methoxy-9H-Pyrido[3,4-B]indole",
      "NSC627681",
      "91985-82-9",
      "9H-Pyrido[3,4-B]indole, 3-Methoxy-",
      "AC1Q4YMW",
      "3-Methoxy-Beta-Carboline",
      "AC1L7M1C",
      "3-Methoxy-9H-",
      "CHEMBL54228",
      "CHEMBL496741",
      "3-Methoxy-9H-.beta.-Carboline",
      "SCHEMBL10646617",
      "NSC-627681",
      "9H-.Beta.-Carbolin-3-Yl Methyl Ether",
    ],
  },
  {
    primary_id: "D09NNS",
    names: ["Piretanide"],
  },
  {
    primary_id: "D09NNZ",
    names: ["3,4-Dichloro-N-(2-Methyl-1H-Indol-5-Yl)Benzamide", "CHEMBL1254552"],
  },
  {
    primary_id: "D09NOH",
    names: ["8-(3-Methylbutyl)Naringenin"],
  },
  {
    primary_id: "D09NQD",
    names: ["CIGB-55"],
  },
  {
    primary_id: "D09NQX",
    names: ["HBV-002"],
  },
  {
    primary_id: "D09NRH",
    names: ["Quinoline Derivative 5"],
  },
  {
    primary_id: "D09NRX",
    names: ["3-Ethyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL185833"],
  },
  {
    primary_id: "D09NSE",
    names: ["Neuromyelitis Optica Therapeutic"],
  },
  {
    primary_id: "D09NSH",
    names: ["JB 98064"],
  },
  {
    primary_id: "D09NST",
    names: [
      "Afimoxifene",
      "4-Hydroxytamoxifen",
      "(Z)-4-Hydroxytamoxifen",
      "Hydroxytamoxifen",
      "4-Monohydroxytamoxifen",
      "Tamogel",
      "68047-06-3",
      "Trans-4-Hydroxytamoxifen",
      "Ici 79280",
      "68392-35-8",
      "65213-48-1",
      "4-OH-TAM",
      "(Z)-4-(1-(4-(2-(Dimethylamino)Ethoxy)Phenyl)-2-Phenylbut-1-En-1-Yl)Phenol",
      "Z-4-Hydroxytamoxifen",
      "UNII-95K54647BZ",
      "CHEMBL489",
      "ICI 79,280",
      "TAMOXIFEN, 4-HYDROXY-, (Z)-",
      "BRN 4910749",
      "(E/Z)-4-Hydroxy Tamoxifen",
      "MLS000069742",
      "C26H29NO2",
      "CHEBI:44616",
      "95K54647BZ",
      "SMR000058939",
      "4-(1-(4-(2-(Dimethylamino)Ethoxy)Phenyl)-2-Phenylbut-1-Enyl)Phenol",
    ],
  },
  {
    primary_id: "D09NTC",
    names: [
      "CC-486",
      "AG-14361",
      "AG14361",
      "328543-09-5",
      "UNII-48N0U0K50I",
      "AG 14361",
      "CHEMBL65892",
      "48N0U0K50I",
      "Imidazo[4,5,1-Jk][1,4]benzodiazepin-7(4H)-One, 2-[4-[(Dimethylamino)Methyl]phenyl]-5,6-Dihydro-",
      "AG-014361",
      "1-(4-((Dimethylamino)Methyl)Phenyl)-8,9-Dihydro-2,7,9a-Triazabenzo[cd]azulen-6(7H)-One",
      "Imidazo(4,5,1-Jk)(1,4)Benzodiazepin-7(4H)-One, 2-(4-((Dimethylamino)Methyl)Phenyl)-5,6-Dihydro-",
      "2-[4-[(Dimethylamino)Methyl]phenyl]-5,6-Dihydroimidazo[4,5,1-Jk][1,4]benzodiazepin-7(4H)-One",
      "SMR000486393",
      "MLS006011157",
      "MLS001065917",
      "Nucleoside Analogue",
    ],
  },
  {
    primary_id: "D09NTG",
    names: ["Taurocholic Acid"],
  },
  {
    primary_id: "D09NTM",
    names: ["VR506"],
  },
  {
    primary_id: "D09NUH",
    names: ["4SCAR-FRa"],
  },
  {
    primary_id: "D09NVR",
    names: ["PRN2246"],
  },
  {
    primary_id: "D09NVT",
    names: ["4-Benzyl-6-Nitro-2-Piperazin-1-Yl-Quinoline", "CHEMBL422203", "BDBM50110580"],
  },
  {
    primary_id: "D09NVU",
    names: ["ZP-1848"],
  },
  {
    primary_id: "D09NWL",
    names: ["PTI-320"],
  },
  {
    primary_id: "D09NWW",
    names: [
      "AMG-247",
      "AMG-369",
      "Multiple Sclerosis Therapeutics, Amgen",
      "S1P1 Receptor Antagonists (Multiple Sclerosis/Inflammation)",
      "S1P1 Receptor Antagonists (Multiple Sclerosis/Inflammation), Amgen",
    ],
  },
  {
    primary_id: "D09NXU",
    names: [
      "4-(2,3-Dihydro-1H-Phenalen-1-Yl)-1H-Imidazole",
      "CHEMBL105328",
      "178556-61-1",
      "CTK0A6889",
      "DTXSID50441101",
      "4-[(2,3-Dihydro-1H-Phenalen)-1-Yl]-1H-Imidazole",
      "1H-Imidazole, 4-(2,3-Dihydro-1H-Phenalen-1-Yl)-",
    ],
  },
  {
    primary_id: "D09NXZ",
    names: ["HL-038"],
  },
  {
    primary_id: "D09NYB",
    names: ["CASTANOSPERMINE"],
  },
  {
    primary_id: "D09NYS",
    names: ["Methylene Blue"],
  },
  {
    primary_id: "D09NYU",
    names: ["Polaprezinc"],
  },
  {
    primary_id: "D09OAA",
    names: ["PDE 4 Inhibitor (Respiratory)"],
  },
  {
    primary_id: "D09OAH",
    names: ["Fascaplysin"],
  },
  {
    primary_id: "D09OAQ",
    names: ["TNP-470"],
  },
  {
    primary_id: "D09OAV",
    names: ["Syk Kinase Silencer"],
  },
  {
    primary_id: "D09OBB",
    names: [
      "Dextromethorphan",
      "Albutussin",
      "Canfodion",
      "Cosylan",
      "Demorphine",
      "Destrometerfano",
      "Dextromethorfan",
      "Dextromethorphane",
      "Dextromethorphanum",
      "Dextrometorfano",
      "Dextrometorphan",
      "Dextromorphan",
      "Dexyromethorphan",
      "Dormetan",
      "Levomethorphan",
      "Levomethorphane",
      "Levomethorphanum",
      "Levometorfano",
      "Methorphan",
      "RACEMETHORPHAN",
      "Trocal",
      "Balminil DM",
      "Balminil DM Children",
      "Benylin Adult Formula Cough Suppressant",
      "Benylin DM For Children",
      "Benylin Pediatric Cough Suppressant",
      "Delsym Cough Formula",
      "Demorphan Hydrobromide",
      "Destrometerfano [DCIT]",
      "Dextromethorfan [Czech]",
      "Dextromethorphan Bromhydrate",
      "Hold DM",
      "Koffex DM",
      "Novahistex DM",
      "Novahistine DM",
      "Pertussin DM Extra Strength",
      "Robitussin Maximum Strength Cough Suppressant",
      "Robitussin Pediatric",
      "Robitussin Pediatric Cough Suppressant",
      "Triaminic DM Long Lasting For Children",
      "BA 2666",
      "Benylin DM 12 Hour",
      "Benylin DM For Children 12 Hour",
      "Sucrets 4 Hour Cough Suppressant",
      "Vicks 44 Cough Relief",
      "Calmylin #1",
      "Cough-X",
      "Creo-Terpin",
      "D-Methorphan",
      "Delta-Methorphan",
      "Dextromethorphan (USP)",
      "Dextromethorphane [INN-French]",
      "Dextromethorphanum [INN-Latin]",
      "Dextrometorfano [INN-Spanish]",
      "Diabe-Tuss DM Syrup",
      "Hihustan M.",
      "L-Methorphan",
      "Levomethorphane [INN-French]",
      "Levomethorphanum [INN-Latin]",
      "Levometorfano [INN-Spanish]",
      "Neurodex (TN)",
      "Pertussin CS Children's Strength",
      "Romilar (TN)",
      "Dextromethorphan [USP:INN:BAN]",
      "Levomethorphan [BAN:DCF:INN]",
      "Levomethorphan [INN:BAN:DCF]",
      "Morphinan, 3-Methoxy-17-Methyl-, (9-Alpha,13-Alpha,14-Alpha)-(9CI)",
      "Cis-1,3,4,9,10,10a-Hexahydro-6-Methoxy-11-Methyl-2H-10,4alpha-Iminoethanophenanthren",
      "(+)-3-Methoxy-17-Methylmorphinan",
      "(-)-3-Methoxy-N-Methylmorphinan",
      "(9alpha,13alpha,14alpha)-17-Methyl-3-(Methyloxy)Morphinan",
      "3-Methoxy-17-Methyl-9alpha,13alpha,14alpha-Morphinan",
      "3-Methoxy-17-Methylmorphinan",
      "3-Methoxy-N-Methylmorphinon",
      "9alpha,13alpha,14alpha-Morphinan, 3-Methoxy-17-Methyl-(8CI)",
    ],
  },
  {
    primary_id: "D09OBF",
    names: ["CMUS-100"],
  },
  {
    primary_id: "D09OBJ",
    names: ["RWJ-50353"],
  },
  {
    primary_id: "D09OCK",
    names: ["UK-371800"],
  },
  {
    primary_id: "D09OCY",
    names: ["Example 41 (WO2012028563)"],
  },
  {
    primary_id: "D09ODP",
    names: ["Ophthalmological Agents"],
  },
  {
    primary_id: "D09ODU",
    names: ["XL475"],
  },
  {
    primary_id: "D09OFM",
    names: ["MiR-375"],
  },
  {
    primary_id: "D09OGV",
    names: ["PMID25553724-Compound-WO2011127048 34"],
  },
  {
    primary_id: "D09OGW",
    names: ["4'-(4-Nitrobenzensulfonamide)-4-Hydroxychalcone", "CHEMBL1087324", "SCHEMBL3484304", "BDBM50316855"],
  },
  {
    primary_id: "D09OHH",
    names: ["OS-440", "CNS Modulator (Spasticity), Osmotica"],
  },
  {
    primary_id: "D09OHP",
    names: ["Indeglitazar"],
  },
  {
    primary_id: "D09OHR",
    names: ["SY-1365"],
  },
  {
    primary_id: "D09OHS",
    names: ["4alpha-PDD", "4alpha-Phorbol 12,13-Didecanoate", "4alpha-Phorbol-Didecanoate"],
  },
  {
    primary_id: "D09OHW",
    names: [
      "Dendritic Cell Therapy",
      "Dendritic Cell Therapy (Cancer)",
      "Cell-Based Cancer Immunotherapies, Binex",
      "DC-Vac/IR Injection",
      "DC-Vac/EP-B Injection",
      "DC-Vac/EP-L",
      "Dendritic Cell Therapy (Cancer), Binex/ KunWha",
    ],
  },
  {
    primary_id: "D09OIO",
    names: [
      "(+)-Myristinin A",
      "Myristinin A",
      "CHEMBL465365",
      "1-[2,4,6-Trihydroxy-3-[(2S,4R)-7-Hydroxy-2-(4-Hydroxyphenyl)-3,4-Dihydro-2H-Chromen-4-Yl]phenyl]dodecan-1-One",
      "1-(2,4,6-TRIHYDROXY-3-((2S,4R)-7-HYDROXY-2-(4-HYDROXYPHENYL)-3,4-DIHYDRO-2H-CHROMEN-4-YL)PHENYL)DODECAN-1-ONE",
      "AC1LAXHL",
      "SCHEMBL3486855",
      "LMPK12020229",
      "BDBM50250426",
      "1-[2,4,6-Trihydroxy-3-[(2S,4R)-7-Hydroxy-2-(4-Hydroxyphenyl)Chroman-4-Yl]phenyl]dodecan-1-One",
      "1-Dodecanone, 1-[3-[(2S,4R)-3,4-Dihydro-7-Hydroxy-2-(4-Hydroxyphenyl)-2H-1-Benzopyran-4-Yl]-2,4,6-Trihydr",
    ],
  },
  {
    primary_id: "D09OJQ",
    names: [
      "Methadone",
      "Adanon",
      "Algovetin",
      "Althose",
      "Amidon",
      "Amidone",
      "Biscuits",
      "Dextromethadone",
      "Diaminon",
      "Dollies",
      "Dolly",
      "Dolophin",
      "Dolophine",
      "Heptadone",
      "Heptanon",
      "Ketalgin",
      "Levometadona",
      "Levomethadonum",
      "Levothyl",
      "Metadona",
      "Metadone",
      "Metasedin",
      "Methadon",
      "Methadona",
      "Methadonum",
      "Methaquaione",
      "Phenadone",
      "Polamidone",
      "Polamivet",
      "Westadone",
      "Dolophine HCL",
      "Levomethadone [INN]",
      "Metadona [Spanish]",
      "Metadone [DCIT]",
      "Methadona [Spanish]",
      "Methadone HCL Intensol",
      "Methadone M",
      "Methadonum [Latin]",
      "Racemic Methadone",
      "A 4624",
      "AN 148",
      "Hoechst 10820",
      "K 174",
      "Amidone (TN)",
      "D-Methadone",
      "Dl-Methadone",
      "Dolophine (TN)",
      "Heptadon (TN)",
      "Heptanon (Pharmaceutical)",
      "L-Methadone",
      "L-Polamidon",
      "L-Polamivet",
      "Levometadona [INN-Spanish]",
      "Levomethadonum [INN-Latin]",
      "Metadona [INN-Spanish]",
      "Methadone (BAN)",
      "Methadone [INN:BAN]",
      "Methadonum [INN-Latin]",
      "Methadose (TN)",
      "Physeptone (TN)",
      "Sedo-Rapide",
      "Symoron (TN)",
      "Dolophine (*Hydrochloride*)",
      "Fenadone (*Hydrochloride*)",
      "Heptadone (*Hydrochloride*)",
      "Heptanon (*Hydrochloride*)",
      "Hoescht 10820 (*Hydrochloride*)",
      "Phenadone (*Hydrochloride*)",
      "AN-148 (*Hydrochloride*)",
      "L-(+)-Methadone",
      "S-(+)-Methadone",
      "D-6-(Dimethylamino)-4,4-Diphenyl-3-Heptanone",
      "L-6-(Dimethylamino)-4,4-Diphenyl-3-Heptanone",
      "(+)-Methadone",
      "(+-)-Methadone",
      "(+/-)-Methadone",
      "(+/-)-Tussal",
      "(-)-(R)-6-(Dimethylamino)-4,4-Diphenyl-3-Heptanone",
      "(-)-Methadone",
      "(6R)-Methadone",
      "(R)-6-(Dimethylamino)-4,4-Diphenyl-3-Heptanone",
      "(R)-6-(Dimethylamino)-4,4-Diphenyl-3-Hetpanone",
      "(S)-6-(Dimethylamino)-4,4-Diphenyl-3-Heptanone",
      "3-Hetpanone, 6-(Dimethylamino)-4,4-Diphenyl-, (R)-(9CI)",
      "6-(Dimethylamino)-4,4-Diphenyl-3-Heptanone Dl-Mixture",
      "6-(Dimethylamino)-4,4-Diphenylheptan-3-One",
      "6-Dimethylamino-4,4-Diphenyl-3-Heptanone",
      "6-Dimethylamino-4,4-Di(Phenyl)Heptan-3-One",
      "6S-Methadone",
    ],
  },
  {
    primary_id: "D09OKK",
    names: ["LG-120838"],
  },
  {
    primary_id: "D09OKU",
    names: ["PMID22521646C12"],
  },
  {
    primary_id: "D09OKZ",
    names: ["CA4P"],
  },
  {
    primary_id: "D09OLH",
    names: [
      "(1H-Indol-2-Yl)(5-Methoxy-1H-Indol-2-Yl)Methanone",
      "CHEMBL7296",
      "Bis(1H-2-Indolyl)Methanone 11",
      "AC1NS9BZ",
      "BDBM6573",
      "ZINC8571",
      "SCHEMBL6865103",
      "5-Methoxy-[2,2'-Carbonylbis(1H-Indole)]",
      "2-(1H-Indol-2-Ylcarbonyl)-5-Methoxy-1H-Indole",
      "1H-Indol-2-Yl-(5-Methoxy-1H-Indol-2-Yl)Methanone",
    ],
  },
  {
    primary_id: "D09OLR",
    names: ["Debio-1141"],
  },
  {
    primary_id: "D09OLZ",
    names: [
      "Cetrorelix",
      "Cetrorelixum",
      "Cetrorelix (INN)",
      "Cetrotide (TN)",
      "HS-2008",
      "Ac-D-Nal(2)-D-Phe(PCl)-D-Pal(3)-Ser-Tyr-D-Cit-Leu-Arg-Pro-D-Ala-NH2",
      "N-Acetyl-3-(2-Naphthyl)-D-Alanyl-4-Chloro-D-Phenylalanyl-3-(3-Pyridyl)-D-Alanyl-L-Seryl-L-Tyrosyl-N5-Carbomoyl-D-Ornithyl-L-Leucyl-L-Prolyl-D-Alaninamide",
      "N-Acetyl-3-(Naphthalen-2-Yl)-D-Alanyl-4-Chloro-D-Phenylalanyl-3-(Pyridin-3-Yl)-D-Alanyl-L-Seryl-L-Tyrosyl-N(5)-Carbamoyl-D-Ornithyl-L-Leucyl-L-Arginyl-L-Prolyl-D-Alaninamide",
    ],
  },
  {
    primary_id: "D09OMI",
    names: ["Ac-YK[CEHdFRWC]-NH2", "CHEMBL412495"],
  },
  {
    primary_id: "D09OMK",
    names: ["Tarvacin Anti-Viral"],
  },
  {
    primary_id: "D09OML",
    names: [
      "D[D-3-Pal2]AVP",
      "CHEMBL414074",
      "136105-89-0",
      "D3PVP",
      "[deamino-Cys1, D-3-Pyridyl-Ala2, Arg8]-Vasopressin",
      "(Deamino-Cys1,b-(3-Pyridyl)-D-Ala2,Arg8)-Vasopressin",
      "MFCD00214667",
    ],
  },
  {
    primary_id: "D09OMM",
    names: ["PMID27019002-Compound-48"],
  },
  {
    primary_id: "D09ONL",
    names: ["SAR439794"],
  },
  {
    primary_id: "D09ONV",
    names: ["VU0359516"],
  },
  {
    primary_id: "D09OOC",
    names: [
      "Gp100",
      "DNA Vaccine (Melanoma), NCI",
      "Gp100 (209-2M)",
      "Vaccine (Cancer), NCI",
      "DNA Vaccine (Melanoma), Vical/NCI",
    ],
  },
  {
    primary_id: "D09OOO",
    names: ["(+/-)-Threo-N-(4-Methoxybenzyl)Methylphenidate", "CHEMBL1255100"],
  },
  {
    primary_id: "D09OOQ",
    names: ["TGFK08AA-ER"],
  },
  {
    primary_id: "D09OOV",
    names: [
      "Polymyxin B Sulfate",
      "Aerosporin",
      "Polimixina B",
      "Polymixin B Sulfate",
      "Polymxin B Sulfate",
      "Polymyxin B",
      "Polymyxine B",
      "Polymyxinum B",
      "Terramycin Ophthalmic",
      "Polimixina B [INN-Spanish]",
      "Polymyxin B Sulfate [USAN:JAN]",
      "Polymyxin B, Sulfate",
      "Polymyxine B [INN-French]",
      "Polymyxinum B [INN-Latin]",
      "Aerosporin, PMB, Poly-RX,Polymyxin B Sulphate",
      "4-Amino-N-[1-[[4-Amino-1-Oxo-1-[[6,9,18-Tris(2-Aminoethyl)-15-Benzyl-3-(2-Hydroxyethyl)-12-(2-Methylpropyl)-2,5,8,11,14,17,20-Heptaoxo-1,4,7,10,13,16,19-Heptazacyclotricos-21-Yl]amino]butan-2-Yl]amino]-4-Hydroxy-1-Oxobutan-2-Yl]-2-Formamidobutanamide",
    ],
  },
  {
    primary_id: "D09OPA",
    names: ["(2S)-Euchrenone A7", "CHEMBL457686", "JJOUBYOHNYJCOU-IBGZPJMESA-N"],
  },
  {
    primary_id: "D09OPH",
    names: ["NPB-3"],
  },
  {
    primary_id: "D09OPL",
    names: [
      "(1'H-Phenothiazin-1'-Yl)(Piperidin-1-Yl)Methanone",
      "CHEMBL589252",
      "Phenothiazin-10-Yl-Piperidin-1-Yl-Methanone",
      "AC1LE3IL",
      "ChemDiv3_004237",
      "Oprea1_668518",
      "ZINC91249",
      "MolPort-001-995-166",
      "HMS1485A13",
      "STK004206",
      "BDBM50308415",
      "AKOS000643159",
      "CCG-113845",
      "MCULE-9139922844",
      "IDI1_022147",
      "BAS 04087808",
      "Phenothiazin-10-Yl(Piperidin-1-Yl)Methanone",
      "SR-01000506180",
      "10H-Phenothiazin-10-Yl(Piperidin-1-Yl)Methanone",
      "SR-01000506180-1",
    ],
  },
  {
    primary_id: "D09OPS",
    names: ["SN-28049", "DNA-Intercalating Topoisomerase Inhibitor (Cancer), University Of Auckland"],
  },
  {
    primary_id: "D09OQV",
    names: ["N-0923"],
  },
  {
    primary_id: "D09OQZ",
    names: ["PMID25435285-Compound-49"],
  },
  {
    primary_id: "D09ORA",
    names: ["Myocardial Perfusion Imaging Agent"],
  },
  {
    primary_id: "D09ORU",
    names: ["ZELANDOPAM HYDROCHLORIDE"],
  },
  {
    primary_id: "D09OSC",
    names: ["Anti-EGFRvIII CAR T Cells"],
  },
  {
    primary_id: "D09OSF",
    names: ["L17"],
  },
  {
    primary_id: "D09OSM",
    names: ["TGFTX-1"],
  },
  {
    primary_id: "D09OSN",
    names: ["Biphenyl-4-Ylboronic Acid", "Biphenylboronic Acid"],
  },
  {
    primary_id: "D09OSS",
    names: ["Dihydropyrido Phthalazinone Derivative 4"],
  },
  {
    primary_id: "D09OTF",
    names: ["ACEA-1031"],
  },
  {
    primary_id: "D09OTG",
    names: ["MS-377"],
  },
  {
    primary_id: "D09OTM",
    names: ["S-Allylglycine"],
  },
  {
    primary_id: "D09OTU",
    names: [
      "SSR-180575",
      "2-(7-Chloro-5-Methyl-4-Oxo-3-Phenyl-4,5-Dihydro-3H-Pyridazino[4,5-B]indol-1-Yl)-N,N-Dimethylacetamide",
    ],
  },
  {
    primary_id: "D09OTX",
    names: ["RG7800"],
  },
  {
    primary_id: "D09OUT",
    names: ["NN-818", "Beta-Amyloid Precursor Protein Modulator (CNS Disorders), NewNeural"],
  },
  {
    primary_id: "D09OWC",
    names: ["SKLB-010"],
  },
  {
    primary_id: "D09OWE",
    names: ["UVAOL"],
  },
  {
    primary_id: "D09OWF",
    names: [
      "N5-(4-Phenoxyphenyl)-L-Glutamine",
      "CHEMBL442816",
      "Modified Amino Acid Analog, 8e",
      "BDBM24270",
      "(2S)-2-Amino-4-[(4-Phenoxyphenyl)Carbamoyl]butanoic Acid",
    ],
  },
  {
    primary_id: "D09OWI",
    names: ["HDV-Insulin"],
  },
  {
    primary_id: "D09OWK",
    names: [
      "AGN-2979",
      "BTG-1501",
      "SC-48274",
      "AGN-2979 (Free Base)",
      "3-[3-(Dimethylamino)Propyl]-3-(3-Methoxyphenyl)-4,4-Dimethylpiperidine-2,6-Dione",
      "3-[3-(Dimethylamino)Propyl]-3-(3-Methoxyphenyl)-4,4-Dimethylpiperidine-2,6-Dione Monohydrochloride",
    ],
  },
  {
    primary_id: "D09OXG",
    names: ["Phenylpropionic Acid Derivative 1"],
  },
  {
    primary_id: "D09OXR",
    names: ["Diffistat-G", "Difficilin GP"],
  },
  {
    primary_id: "D09OYA",
    names: ["Alpha-Methyl-5-HT", "Alpha-Me-5-HT", "Alpha-Methylserotonin"],
  },
  {
    primary_id: "D09OZC",
    names: ["Lucanthone"],
  },
  {
    primary_id: "D09PAU",
    names: ["M87o"],
  },
  {
    primary_id: "D09PAZ",
    names: ["PMID26666989-Compound-Figure9middle08"],
  },
  {
    primary_id: "D09PBW",
    names: ["(R)(+)-7-Fluoro-2-Phenylchroman-4-One", "CHEMBL599761", "SCHEMBL10360819"],
  },
  {
    primary_id: "D09PCL",
    names: ["JNJ-64565111"],
  },
  {
    primary_id: "D09PCZ",
    names: ["PMID16439120C20"],
  },
  {
    primary_id: "D09PDD",
    names: [
      "N-Hydroxy-3-Naphthalen-2-Yl-Acrylamide",
      "CHEMBL155443",
      "N-Hydroxy-2-Naphthaleneacrylamide",
      "BDBM50015170",
      "ZINC27654302",
      "(E)-3-(2-Naphthalenyl)-2-Propenehydroximic Acid",
    ],
  },
  {
    primary_id: "D09PDK",
    names: ["F-9TG"],
  },
  {
    primary_id: "D09PDT",
    names: ["C[CO-O-C6H4-CO-Pro-D-Nal(2)-Arg-Trp-Lys]-NH2", "CHEMBL253788"],
  },
  {
    primary_id: "D09PEQ",
    names: ["Gentamicine Sulfate"],
  },
  {
    primary_id: "D09PEV",
    names: ["Itazigrel"],
  },
  {
    primary_id: "D09PEZ",
    names: ["6,8-Dinitro-2-Piperazin-1-Yl-Quinoline", "CHEMBL37739", "2-Piperazino-6,8-Dinitroquinoline"],
  },
  {
    primary_id: "D09PFL",
    names: ["ISIS 133208"],
  },
  {
    primary_id: "D09PFR",
    names: ["LAMB3-Transduced Autologous Epidermal Stem Cells"],
  },
  {
    primary_id: "D09PGC",
    names: [
      "2-[4-(2-Morpholin-4-Ylethoxy)Phenoxy]benzooxazole",
      "Benzoxazole Compound, 10d",
      "SCHEMBL2168507",
      "CHEMBL482256",
      "BDBM24209",
    ],
  },
  {
    primary_id: "D09PGG",
    names: ["MVI-816"],
  },
  {
    primary_id: "D09PGH",
    names: ["PA-508"],
  },
  {
    primary_id: "D09PGL",
    names: ["Capromab"],
  },
  {
    primary_id: "D09PGM",
    names: [
      "BY-1949",
      "90158-59-1",
      "3-Methoxy-11-Methyldibenz(B,f)Oxazepine-8-Carboxylate",
      "AC1L3SM5",
      "SCHEMBL194184",
      "DTXSID60238049",
      "JTZGDJSJVQONAV-UHFFFAOYSA-N",
      "11-Methyl-3-Methoxydibenz[b,f][1,4]oxazepine-8-Carboxylic Acid",
      "Dibenz(B,f)(1,4)Oxazepine-8-Carboxylic Acid, 3-Methoxy-11-Methyl-",
      "9-Methoxy-6-Methylbenzo[b][1,4]benzoxazepine-3-Carboxylic Acid",
      "3-Methoxy-11-Methyldibenzo[b,f][1,4]oxazepin-8-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09PGP",
    names: ["Phenylpyridine Derivative 2"],
  },
  {
    primary_id: "D09PGT",
    names: ["T2c-002"],
  },
  {
    primary_id: "D09PGW",
    names: ["Spiroquinolone Derivative 1"],
  },
  {
    primary_id: "D09PHB",
    names: ["Mefenamic"],
  },
  {
    primary_id: "D09PHV",
    names: [
      "AURASPERONE A",
      "Aurasperone A",
      "Aurasperone",
      "UNII-B9PTQ8Z021",
      "B9PTQ8Z021",
      "15085-74-2",
      "(7,10'-Bi-4H-Naphtho(2,3-B)Pyran)-4,4'-Dione, 5,5'-Dihydroxy-6,6',8,8'-Tetramethoxy-2,2'-Dimethyl-",
      "AC1MJ24O",
      "CHEMBL450763",
      "DTXSID30164629",
      "CHEBI:133760",
      "5-HYDROXY-10-{5-HYDROXY-6,8-DIMETHOXY-2-METHYL-4-OXO-4H-BENZO[G]CHROMEN-7-YL}-6,8-DIMETHOXY-2-METHYL-4H-BENZO[G]CHROMEN-4-ONE",
      "5,5'-Dihydroxy-6,6',8,8'-Tetramethoxy-2,2'-Dimethyl-7,10'-Bi-4H-Naphtho[2,3-B]pyran-4,4'-Dione, 9CI",
    ],
  },
  {
    primary_id: "D09PIF",
    names: [
      "Imidazolidin-2-Ylidene-O-Tolyl-Amine",
      "36318-56-6",
      "Imidazolidine, 2-(2-Methylphenylimino)-",
      "N-(2-Methylphenyl)-4,5-Dihydro-1H-Imidazol-2-Amine",
      "CHEMBL50430",
      "785724-64-3",
      "(4,5-Dihydro-1H-Imidazol-2-Yl)-O-Tolyl-Amine",
      "Imidazolidine,2-(2-Methylphenylimino)-",
      "AC1L3KDH",
      "Benzenamine,N-2-Imidazolidinylidene-2-Methyl-",
      "SCHEMBL8020019",
      "CTK5E5913",
      "CTK1C4679",
      "DTXSID30189852",
      "WLQWOUULTDRZTQ-UHFFFAOYSA-N",
      "ZINC13835980",
      "BDBM50213213",
      "2-(2-Methylphenylimino)Imidazolidine",
      "2-(2-Methylphenylimino)-Imidazolidine",
      "AKOS027415754",
      "KB-298713",
    ],
  },
  {
    primary_id: "D09PIH",
    names: ["NK-1007"],
  },
  {
    primary_id: "D09PIN",
    names: [
      "SB 224289",
      "180083-23-2",
      "(2'-Methyl-4'-(5-Methyl-1,2,4-Oxadiazol-3-Yl)-[1,1'-Biphenyl]-4-Yl)(1'-Methyl-6,7-Dihydrospiro[furo[2,3-F]indole-3,4'-Piperidin]-5(2H)-Yl)Methanone",
      "SB224289",
      "SB-224289",
      "UNII-F95C648W4N",
      "CHEMBL281350",
      "CHEBI:64069",
      "F95C648W4N",
      "(4-(2-Methyl-4-(5-Methyl-1,2,4-Oxadiazol-3-Yl)Phenyl)Phenyl)-(1'-Methylspiro(6,7-Dihydro-2H-Furo(2,3-F)Indole-3,4'-Piperidine)-5-Yl)Methanone",
    ],
  },
  {
    primary_id: "D09PIQ",
    names: ["JWH-367"],
  },
  {
    primary_id: "D09PIS",
    names: ["Pyrazolo[4,3-C]pyridine Derivative 2"],
  },
  {
    primary_id: "D09PJB",
    names: ["ARG301"],
  },
  {
    primary_id: "D09PJF",
    names: [
      "D-315",
      "CHEMBL456825",
      "SCHEMBL10075562",
      "BDBM50273937",
      "(R)-7-((2-(4-Phenylpiperazin-1-Yl)Ethyl)(Propyl)Amino)-5,6,7,8-Tetrahydronaphthalen-2-Ol",
    ],
  },
  {
    primary_id: "D09PJK",
    names: [
      "ACK-1 Inhibitors",
      "ACK-1 Inhibitors (Cancer)",
      "ACK-1 Inhibitors (Cancer), Amgen",
      "Activated Cdc42Hs-Associated Tyrosine Kinase-1 Inhibitors (Cancer), Amgen",
    ],
  },
  {
    primary_id: "D09PJX",
    names: ["Tetrabenazine"],
  },
  {
    primary_id: "D09PKJ",
    names: ["2-Isoxazol-3-Yl-3H-Imidazo[4,5-C]quinoline"],
  },
  {
    primary_id: "D09PKU",
    names: ["ERBULOZOLE"],
  },
  {
    primary_id: "D09PKZ",
    names: [
      "NM-9308",
      "Bikaciomab",
      "Bikaciomab (AMD), NovelMed Therapeutics",
      "Complement Factor B Inhibitor (Age Related Maculardegeneration), NovelMed Therapeutics",
      "Anti-Factor B Monoclonal Antibody (Age Related Macular Degenration), NovelMed Therapeutics",
    ],
  },
  {
    primary_id: "D09PLA",
    names: ["C-[-Arg-Gly-Asp-Acpca31-]", "CHEMBL534934"],
  },
  {
    primary_id: "D09PLF",
    names: ["Org-30029"],
  },
  {
    primary_id: "D09PLI",
    names: ["Efalith"],
  },
  {
    primary_id: "D09PLJ",
    names: [
      "6-Phenyl-Thieno[3,2-D]pyrimidin-4-Ylamine",
      "CHEMBL311108",
      "CTK7E1320",
      "ZINC13588156",
      "BDBM50137196",
      "AKOS015965750",
      "6-Phenylthieno[3,2-D]pyrimidin-4-Ylamine",
    ],
  },
  {
    primary_id: "D09PLT",
    names: [
      "3-Tert-Butyl-9H-Carbazole",
      "3-(Tert-Butyl)-9H-Carbazole",
      "22401-74-7",
      "CHEMBL1170621",
      "SCHEMBL10090582",
      "CTK0J6436",
      "DTXSID30477842",
      "MolPort-044-724-355",
      "KS-000017WT",
      "BDBM50322591",
      "AKOS030561970",
      "9H-Carbazole, 3-(1,1-Dimethylethyl)-",
    ],
  },
  {
    primary_id: "D09PLZ",
    names: ["NI-1201", "Anti-Interleukin-6 Receptor MAb, NovImmune"],
  },
  {
    primary_id: "D09PMC",
    names: ["PMID27321640-Compound-75"],
  },
  {
    primary_id: "D09PMV",
    names: ["Interferon Alfa-2a, Recombinant"],
  },
  {
    primary_id: "D09PNQ",
    names: [
      "2,3-Dihydroxypropanal",
      "DL-Glyceraldehyde",
      "Glyceraldehyde",
      "Glyceric Aldehyde",
      "Glycerose",
      "Glycerinaldehyde",
      "Glycerinformal",
      "56-82-6",
      "Propanal, 2,3-Dihydroxy-",
      "2,3-Dihydroxypropionaldehyde",
      "DL-GLYC",
      "Aldotriose",
      "Alpha,beta-Dihydroxypropionaldehyde",
      "367-47-5",
      "Glyceraldehyde, (+-)-",
      "Propionaldehyde, 2,3-Dihydroxy-",
      "Aldose",
      "U 1188",
      "D,L-Glyceraldehyde",
      "NSC 67934",
      "BRN 0635844",
      "AI3-24475",
      "(+/-)-Glyceraldehyde",
      "EINECS 206-695-9",
      "EINECS 200-290-0",
      "DLG",
      ".alpha.,.beta.-Dihydroxypropionaldehyde",
      "2,3-Dihydroxy-Propionaldehyde",
      "CHEBI:5445",
    ],
  },
  {
    primary_id: "D09PNS",
    names: ["UGP-281"],
  },
  {
    primary_id: "D09PNV",
    names: [
      "KSG-504",
      "4(R)-[N-(3-Methoxypropyl)-N-Pentylcarbamoyl]-5-(2-Naphthylsulfonyl)Pentanoic Acid L-Arginine Salt Monohydrate",
    ],
  },
  {
    primary_id: "D09PNY",
    names: [
      "Tropicamide",
      "Bistropamide",
      "Epitromina",
      "Mydral",
      "Mydriacyl",
      "Mydriafair",
      "Mydriaticum",
      "Mydrum",
      "OcuTropic",
      "Opticyl",
      "Paremyd",
      "Tropicacyl",
      "Tropicamida",
      "Tropicamidum",
      "Tropikamid",
      "Tropimil",
      "Visumidriatic",
      "Akorn Brand Of Tropicamide",
      "Alcon Brand Of Tropicamide",
      "Bournonville Brand Of Tropicamide",
      "Cahill May Roberts Brand Of Tropicamide",
      "Chauvin Brand Of Tropicamide",
      "Colircusi Tropicamida",
      "Medical Ophthalmics Brand Of Tropicamide",
      "Minims Tropicamide",
      "Novartis Brand Of Tropicamide",
      "Ocu Tropic",
      "Ocumed Brand Of Tropicamide",
      "Ocusoft Brand Of Tropicamide",
      "Pharmafair Brand Of Tropicamide",
      "Rivex Brand Of Tropicamide",
      "Triaminic DM",
      "Tropicamide Alcon Brand",
      "Tropicamide Chauvin Brand",
      "Tropicamide Faure",
      "Tropicamide Minims",
      "Tropicamide Monofree",
      "Tropicamide Novartis Brand",
      "Tropicamide Ocumed Brand",
      "Tropicamide Pharmafair Brand",
      "Tropicamide Rivex Brand",
      "Stulln Brand 1 Of Tropicamide",
      "Stulln Brand 2 Of Tropicamide",
      "T 9778",
      "I-Picamide",
      "Mydriacyl (TN)",
      "Ocu-Tropic",
      "Ro 1-7683",
      "Spectro-Cyl",
      "Tropicamida [INN-Spanish]",
      "Tropicamidum [INN-Latin]",
      "Tropicamide [USAN:INN:BANJAN]",
      "Tropicamide (JP15/USP/INN)",
      "Tropicamide Monohydrochloride, (R)-Isomer",
      "Tropicamide Monohydrochloride, (S)-Isomer",
      "Tropicamide, (R)-Isomer",
      "Tropicamide, (S)-Isomer",
      "N-Ethyl-N-(4-Pyridylmethyl)Tropamid",
      "N-Ethyl-N-(4-Pyridylmethyl)Tropamide",
      "N-Ethyl-N-(G-Picolyl)Tropamide",
      "Tropicamide, (+-)-Isomer",
      "N-Ethyl-2-Phenyl-N-(4-Pyridylmethyl)Hydracrylamide",
      "N-Ethyl-2-Phenyl-N-4-[-Pyridyl-Methyl]hydracrylamide",
      "N-Ethyl-Alpha-(Hydroxymethyl)-N-(4-Pyridinylmethyl)Benzeneacetamide",
      "N-Ethyl-3-Hydroxy-2-Phenyl-N-(Pyridin-4-Ylmethyl)Propanamide",
      "(+-)-N-Ethyl-2-Phenyl-N-(4-Pyridylmethyl)Hydracrylamide",
    ],
  },
  {
    primary_id: "D09POE",
    names: ["N-(6)-Methyl-2'-Deoxyadenosine-3':5'-Bisphosphate"],
  },
  {
    primary_id: "D09POH",
    names: ["AEG-33773"],
  },
  {
    primary_id: "D09PON",
    names: ["MRLP-098"],
  },
  {
    primary_id: "D09POP",
    names: ["FGGFTGARKRKRKLANQ"],
  },
  {
    primary_id: "D09POQ",
    names: ["OM-174"],
  },
  {
    primary_id: "D09PPF",
    names: ["CNS-1067"],
  },
  {
    primary_id: "D09PPG",
    names: [
      "Metformin Arginine-Hemisuccinimide",
      "Diap-2",
      "Metformin Arginine-Hemisuccinate-Hemisuccinamide",
      "Metformin Arginine-Hemisuccinimide (Type 2 Diabetes)",
      "Diap-2 (Type 2 Diabetes), Arteria",
      "Metformin Arginine-Hemisuccinimide (Type 2 Diabetes), Arteria",
    ],
  },
  {
    primary_id: "D09PPU",
    names: [
      "CA-170",
      "3-Aminopyrrolidine Dihydrochloride",
      "103831-11-4",
      "Pyrrolidin-3-Amine Dihydrochloride",
      "3-Pyrrolidinamine, Dihydrochloride",
      "3-Aminopyrrolidine 2HCl",
      "3-Aminopyrrolidine DiHCl",
      "SCHEMBL555933",
      "ACMC-2099s1",
      "ACMC-2099s3",
      "ACMC-20989g",
      "3-Pyrrolidinamine Dihydrochloride",
      "CTK0H7226",
      "DTXSID00583661",
      "MolPort-002-343-989",
      "NJPNCMOUEXEGBL-UHFFFAOYSA-N",
      "3-Amino-Pyrrolidine Dihydrochloride",
      "KS-00000JI6",
      "ACT01710",
      "ANW-14978",
      "SBB003982",
      "(?-3-Aminopyrrolidine Dihydrochloride",
      "AKOS015844825",
      "VP60158",
      "AM85320",
      "VP60228",
      "TRA0055207",
      "TRA0000843",
      "TRA0097",
    ],
  },
  {
    primary_id: "D09PQP",
    names: ["SGN-70"],
  },
  {
    primary_id: "D09PQQ",
    names: ["PMID28394193-Compound-25"],
  },
  {
    primary_id: "D09PQZ",
    names: [
      "Acalabrutinib",
      "Calquence",
      "UNII-I42748ELQW",
      "I42748ELQW",
      "Acalabrutinib [INN]",
      "Acalabrutinib [USAN:INN]",
      "Calquence (TN)",
      "Acalabrutinib(ACP196)",
      "Acalabrutinib (ACP-196)",
      "GTPL8912",
      "Acalabrutinib (JAN/USAN/INN)",
      "SCHEMBL14637368",
      "EX-A881",
      "WDENQIQQYWYTPO-IBGZPJMESA-N",
      "KS-000006AT",
      "KS-000006AD",
      "S8116",
      "BDBM50175583",
      "AKOS030526094",
      "ZINC208774715",
      "DB11703",
      "CS-5356",
      "DS-3326",
    ],
  },
  {
    primary_id: "D09PRN",
    names: ["TROVIRDINE HYDROCHLORIDE"],
  },
  {
    primary_id: "D09PRY",
    names: [
      "CB-28",
      "CB 28",
      "P-Chlorocarbanilic Acid 2-Chloroethyl Ester",
      "2-Chloroethyl N-(4-Chlorophenyl)Carbamate",
      "2-Chloroethyl P-Chlorocarbanilate",
      "CARBANILIC ACID, P-CHLORO-, 2-CHLOROETHYL ESTER",
      "25217-18-9",
      "BRN 2806080",
      "AC1L1OIU",
      "DTXSID90179875",
      "ZJFHLTWUBUCUGP-UHFFFAOYSA-N",
      "ZINC2014239",
      "AKOS024334733",
      "MCULE-9599972644",
      "2-Chloroethyl 4-Chlorophenylcarbamate #",
      "2-Chloroethyl (P-Chlorophenyl)Carbamate",
    ],
  },
  {
    primary_id: "D09PSB",
    names: [
      "DPC-082",
      "(E)-4(S)-(2-Cyclopropylvinyl)-5,6-Difluoro-4-(Trifluoromethyl)-3,4-Dihydro-1H-Quinazolin-2-One",
      "214287-98-6",
    ],
  },
  {
    primary_id: "D09PSY",
    names: ["ANS-6637"],
  },
  {
    primary_id: "D09PSZ",
    names: ["Deferitrin"],
  },
  {
    primary_id: "D09PTA",
    names: ["HP-290"],
  },
  {
    primary_id: "D09PTD",
    names: ["BMS-986168"],
  },
  {
    primary_id: "D09PTL",
    names: ["SRI-3072"],
  },
  {
    primary_id: "D09PTP",
    names: ["CEP-28122", "ALK Inhibitor (Cancer), Cephalon"],
  },
  {
    primary_id: "D09PTR",
    names: ["FR-900452", "101706-33-6"],
  },
  {
    primary_id: "D09PTY",
    names: ["V-212"],
  },
  {
    primary_id: "D09PUF",
    names: [
      "2-Methoxyestrrone-3-O-Sulfamate",
      "CHEMBL372231",
      "2-MeOEMATE",
      "2-Methoxyestrone Sulfamate",
      "SCHEMBL307890",
      "BTQHAWWINOTNQQ-QPWUGHHJSA-N",
      "BDBM50171450",
      "2-Methoxy-17-Oxo-Estra-1,3,5(10)-Trien-3-Yl Sulfamate",
      "(8R,9S,13S,14S)-2-Methoxy-13-Methyl-17-Oxo-7,8,9,11,12,13,14,15,16,17-Decahydro-6H-Cyclopenta[a]phenanthrene-3-Sulfonic Acid Amide",
    ],
  },
  {
    primary_id: "D09PUL",
    names: [
      "L-Alanine",
      "ALA",
      "Alaninum",
      "LPG",
      "Polyalanine",
      "A-Alanine",
      "A-Aminopropionic Acid",
      "Alanina [DCIT,Spanish]",
      "Alanine (USP)",
      "L-A-Aminopropionicacid",
      "L-Alanine (9CI)",
      "L-Alanine (JAN)",
      "L-Alanine, Homopolymer",
      "L-Alanine, Labeled With Tritium",
      "L-A-Alanine",
      "L-A-Aminopropionic Acid",
      "Poly-DL-Alanine",
      "L-Alanine, Labeled With Carbon-14",
      "Alanine, L-(7CI,8CI)",
      "F4F207FF-8FF8-4789-99A1-147AE0A36673",
      "L-Alanine, N-Coco Alkyl Derivs.",
      "Propanoic Acid, 2-Amino-, (S)",
      "(C14)L-Alanine",
      "(S)-2-Aminopropionic Acid",
      "14C-L-Alanine",
    ],
  },
  {
    primary_id: "D09PUW",
    names: ["Bis(5-Methoxybenzo[b]furan-2-Yl)Methanone", "CHEMBL225772"],
  },
  {
    primary_id: "D09PVH",
    names: ["PMID29671355-Compound-24"],
  },
  {
    primary_id: "D09PVO",
    names: ["Aramchol"],
  },
  {
    primary_id: "D09PWJ",
    names: ["ML218"],
  },
  {
    primary_id: "D09PWS",
    names: ["KI-0902"],
  },
  {
    primary_id: "D09PWX",
    names: ["Carzenide"],
  },
  {
    primary_id: "D09PXU",
    names: ["4,7-Dimethyl-6H-Benzo[c]chromene-3,8-Diol", "CHEMBL378798", "SCHEMBL6830103"],
  },
  {
    primary_id: "D09PYD",
    names: [
      "CGS-25462",
      "Cgs-25462",
      "UNII-UP2R15WBF4",
      "UP2R15WBF4",
      "Cgs 25462",
      "147862-03-1",
      "AC1L31LU",
      "SCHEMBL7312208",
      "CHEMBL147342",
      "JTQWXNZXTRVPHN-LJAQVGFWSA-N",
      "Beta-Alanine, N-(3-(1,1'-Biphenyl)-4-Yl-N-((Diphenoxyphosphinyl)Methyl)-L-Alanyl)-",
      "3-(N-(2-(((Diphenylphosphono)Methyl)Amino)-3-(4-Biphenylyl)Propionyl)Amino)Propionic Acid",
      "(S)-N-[2-(Diphenylphosphonomethylamino)-3-(4-Biphenylyl)-Propionyl]-3-Aminopropionic Acid",
      "(S)-N-[2-(Diphenylphosphonomethylamino)-3-(4-Biphenylyl)Propionyl]-3-Aminopropionic Acid",
    ],
  },
  {
    primary_id: "D09PYV",
    names: ["AZ-40140"],
  },
  {
    primary_id: "D09PYZ",
    names: ["MK-3328"],
  },
  {
    primary_id: "D09PZD",
    names: [
      "Viral Macrophage Inflammatory Protein-II",
      "CFLTKRGRQVC",
      "VMIP-II",
      "AC1LAF0N",
      "GTPL768",
      "VMIP-II (RESIDUE 41-51, CYCLIC)",
      "(4R,7S,10S,13S,19S,22S,25S,28S,31S,34R)-34-Amino-22-(4-Aminobutyl)-10-(3-Amino-3-Oxopropyl)-31-Benzyl-13,19-Bis[3-(Diaminomethylideneamino)Propyl]-25-[(1R)-1-Hydroxyethyl]-28-(2-Methylpropyl)-6,9,12,15,18,21,24,27,30,33-Decaoxo-7-Propan-2-Yl-1,2-Dithia-5,8,11,14,17,20,23,26,29,32-Decazacyclopentatriacontane-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09PZO",
    names: ["Idoxuridine"],
  },
  {
    primary_id: "D09PZT",
    names: ["Iva-His-Pro-Phe-His-Sta-Leu-Phe-NH2"],
  },
  {
    primary_id: "D09PZV",
    names: ["ITV-4"],
  },
  {
    primary_id: "D09PZZ",
    names: ["Lypressin"],
  },
  {
    primary_id: "D09QAD",
    names: ["CGS-15873A"],
  },
  {
    primary_id: "D09QBA",
    names: [
      "CEP-5104",
      "C25H22N2O3",
      "2-Methoxy-12-(2-Hydroxyethyl)-13,14-Dihydronaphthol[2,1-A]pyrrolo[3,4-C]carbazole-5-One",
      "CHEMBL460989",
      "SCHEMBL3264941",
      "BDBM24947",
      "ZINC40861910",
      "5-Oxo Dihydronaphthylcarbazole Analogue, 14",
      "X7364",
    ],
  },
  {
    primary_id: "D09QBG",
    names: [
      "Cangrelor",
      "163706-06-7",
      "Kengreal",
      "UNII-6AQ1Y404U7",
      "AR-C69931XX",
      "CHEMBL334966",
      "6AQ1Y404U7",
      "Kengrexal",
      "5'-O-[({[dichloro(Phosphono)Methyl](Hydroxy)Phosphoryl}oxy)(Hydroxy)Phosphoryl]-N-[2-(Methylsulfanyl)Ethyl]-2-[(3,3,3-Trifluoropropyl)Sulfanyl]adenosine",
      "5'-Adenylicacid,N-[2-(Methylthio)Ethyl]-2-[(3,3,3-Trifluoropropyl)Thio]-,anhydridewithP,P'-(Dichloromethylene)Bis[phosphonicacid](1:1)",
    ],
  },
  {
    primary_id: "D09QCB",
    names: ["PMID29334795-Compound-56"],
  },
  {
    primary_id: "D09QCD",
    names: ["KYS-05056"],
  },
  {
    primary_id: "D09QDC",
    names: ["Iso-OMPA"],
  },
  {
    primary_id: "D09QDP",
    names: [
      "Clorgyline",
      "Chlorgyline",
      "Clorgilin",
      "Clorgilina",
      "Clorgiline",
      "Clorgilinum",
      "Clorgiline [INN]",
      "M And B 9302",
      "Clorgilina [INN-Spanish]",
      "Clorgiline (INN)",
      "Clorgilinum [INN-Latin]",
      "M & B 9302",
      "N-Methyl-N-Propargyl-3-(2,4-Dichlorophenoxy)Propylamine",
      "N-(3-(2,4-Dichlorophenoxy)Propyl)-N-Methyl-2-Propynylamine",
      "N-[3-(2,4-DICHLOROPHENOXY)PROPYL]-N-METHYL-N-PROP-2-YNYLAMINE",
      "2-Propyn-1-Amine, N-(3-(2,4-Dichlorophenoxy)Propyl)-N-Methyl-(9CI)",
      "3-(2,4-Dichlorophenoxy)-N-Methyl-N-Prop-2-Ynylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D09QDY",
    names: ["5-Indan-(1Z)-Ylidenemethyl-1H-Imidazole", "AC1O70GD", "SCHEMBL4236847", "CHEMBL178036", "SCHEMBL4235344"],
  },
  {
    primary_id: "D09QEI",
    names: [
      "Pentoxifylline",
      "Agapurin",
      "Azupentat",
      "Dimethyloxohexylxanthine",
      "Durapental",
      "Hemovas",
      "Ikomio",
      "Oxpentifylline",
      "PENTOXYPHYLINE;PNX",
      "Pentoxifilina",
      "Pentoxifyllin",
      "Pentoxifyllinum",
      "Pentoxil",
      "Pentoxiphyllin",
      "Pentoxiphylline",
      "Pentoxiphyllium",
      "Pentoxyfylline",
      "Pentoxyphyllin",
      "Pentoxyphylline",
      "Ralofect",
      "Rentylin",
      "Torental",
      "Trental;Vasofirin",
      "Vazofirin",
      "Agapurin Retard",
      "BL 191",
      "BL191",
      "EHT 0201",
      "EHT0201",
      "IN1161",
      "P 1784",
      "Artal (TN)",
      "BL-191",
      "EHT-0201",
      "EHT-201",
      "Flexital (TN)",
      "Pentox (TN)",
      "Pentoxifilina [INN-Spanish]",
      "Pentoxifyllinum [INN-Latin]",
      "Pentoxil (TN)",
      "Pentoxin (TN)",
      "Trental (TN)",
      "Cis-9-Octadecenoyl Coenzyme A",
      "Pentoxifylline [USAN:INN:JAN]",
      "Pentoxifylline (JAN/USP/INN)",
      "1,2,3,6-Tetrahydro-3,7-Dimethyl-1-(5-Oxohexyl)-2,6-Purindion",
      "1-(5-Oxohexyl)-3,7-Dimethylxanthine",
      "1-(5-Oxohexyl)Theobromine",
      "3,7-Dihydro-3,7-Dimethyl-1-(5-Oxohexyl)-1H-Purine-2,6-Dione",
      "3,7-Dimethyl-1-(5-Oxo-Hexyl)-3,7-Dihydro-Purine-2,6-Dione",
      "3,7-Dimethyl-1-(5-Oxohexyl) Xantine",
      "3,7-Dimethyl-1-(5-Oxohexyl)-1H,3H-Purin-2,6-Dione",
      "3,7-Dimethyl-1-(5-Oxohexyl)-3,7-Dihydro-1H-Purine-2,6-Dione",
      "3,7-Dimethyl-1-(5-Oxohexyl)Xanthine",
      "3,7-Dimethyl-1-(5-Oxohexyl)Xantine",
      "3,7-Dimethyl-1-(5-Oxohexyl)Purine-2,6-Dione",
    ],
  },
  {
    primary_id: "D09QHC",
    names: [
      "BENZENESULFONAMIDE",
      "1998/10/2",
      "Benzenesulphonamide",
      "Benzosulfonamide",
      "Benzolsulfonamide",
      "Phenyl Sulfonamide",
      "Phenylsulfonamide",
      "C6H7NO2S",
      "M And B 7973",
      "NSC 5341",
      "EINECS 202-637-1",
      "BRN 1100566",
      "CHEMBL27601",
      "AI3-04492",
      "KHBQMWCZKVMBLN-UHFFFAOYSA-N",
      "MFCD00007930",
      "Benzenesulfonamide, 98+%",
      "Benzolsulfonamid",
      "Benzensulfonamide",
      "Phenylsulphonamide",
      "4jsa",
      "4jsz",
      "2wej",
      "Benzene Sulphonamide",
      "1-Benzenesulfonamide",
      "Benzenesulfonyl Amine",
      "WLN: ZSWR",
      "AC1Q6VDE",
      "AC1L1OLV",
      "Benzenesulfonic Acid Amide",
      "SCHEMBL729",
      "EC 202",
    ],
  },
  {
    primary_id: "D09QHP",
    names: ["PS-873266"],
  },
  {
    primary_id: "D09QHU",
    names: ["SD-560", "Deuterated Pirfenidone Analog (Idiopathic Pulmonary Fibrosis), Auspex"],
  },
  {
    primary_id: "D09QIA",
    names: ["9-Octadecynoic Acid"],
  },
  {
    primary_id: "D09QIC",
    names: ["N-Phenylpropyl-ETAV"],
  },
  {
    primary_id: "D09QIJ",
    names: ["PF-3491165"],
  },
  {
    primary_id: "D09QJS",
    names: ["SC-53229"],
  },
  {
    primary_id: "D09QJT",
    names: ["KITE-718"],
  },
  {
    primary_id: "D09QKG",
    names: ["PMID25991433-Compound-E1"],
  },
  {
    primary_id: "D09QKH",
    names: [
      "BAS-0338872",
      "CHEMBL207986",
      "2-Iodo-N-(5-Phenethyl-1,3,4-Thiadiazol-2-Yl)Benzamide",
      "BAS 00338872",
      "AC1LM5IX",
      "Oprea1_394225",
      "Oprea1_118842",
      "ZINC820816",
      "BDBM50187565",
      "AKOS000542403",
      "MCULE-8600906341",
      "ST50224059",
      "2-Iodo-N-(5-Phenethyl-[1,3,4]thiadiazol-2-Yl)-Benzamide",
    ],
  },
  {
    primary_id: "D09QKI",
    names: ["PPL-100"],
  },
  {
    primary_id: "D09QKJ",
    names: ["Cavatak"],
  },
  {
    primary_id: "D09QLB",
    names: [
      "8-Sulfophenyl Theophylline",
      "8-(P-Sulfophenyl)Theophylline",
      "8-(4-Sulfophenyl)Theophylline",
      "80206-91-3",
      "8-SPT",
      "4-(1,3-Dimethyl-2,6-Dioxo-2,3,6,9-Tetrahydro-1H-Purin-8-Yl)Benzenesulfonic Acid",
      "CHEMBL8488",
      "Psp-Theophylline",
      "8-Sulphophenyltheophylline",
      "Lopac-A-013",
      "AC1Q6X0T",
      "AC1L1CI3",
      "Lopac0_000135",
      "8-(P-Sulfophenyl)Thiophylline",
      "SCHEMBL1320226",
      "8-(P-Sulfophenyl)-Theophylline",
      "CTK6I1750",
      "BDBM82023",
      "CTK3E8290",
      "DTXSID90230152",
      "LXJSJIXZOAMHTG-UHFFFAOYSA-N",
      "MolPort-006-068-692",
      "HMS3260K12",
      "ALBB-009371",
      "ZX-AN008233",
      "ZINC5322904",
      "NSC_1908",
      "Tox21_500135",
      "PDSP1_000449",
    ],
  },
  {
    primary_id: "D09QLR",
    names: [
      "Dibenzothiazepines",
      "Cannabinoid CB1 Inverse Agonists (CNS Disorder)",
      "Cannabinoid CB1 Inverse Agonists (CNS Disorder), ACADIA",
      "CB1 Inverse Agonists (Obesity, Substance Abuse), ACADIA",
    ],
  },
  {
    primary_id: "D09QMX",
    names: ["Q-301"],
  },
  {
    primary_id: "D09QNH",
    names: ["PMID28870136-Compound-50"],
  },
  {
    primary_id: "D09QOB",
    names: [
      "ME Bromodomain Inhibitor",
      "CHEMBL3769729",
      "Methyl (2r)-2-[(4s)-6-(4-Chlorophenyl)-8-Methoxy-1-Methyl-4h-[1,2,4]triazolo[4,3-A][1,4]benzodiazepin-4-Yl]propanoate",
      "GTPL7807",
      "SCHEMBL16771925",
    ],
  },
  {
    primary_id: "D09QOE",
    names: ["CGP 55845", "CGP55845", "CGP-55845A", "CGP-55845"],
  },
  {
    primary_id: "D09QOF",
    names: ["EM-2487"],
  },
  {
    primary_id: "D09QOR",
    names: ["Eniluracil"],
  },
  {
    primary_id: "D09QPD",
    names: ["HPP-515"],
  },
  {
    primary_id: "D09QPE",
    names: ["AP-1189", "AP-1089", "Melanocortin Receptor-1 Modulator (Oral, Inflammation), Action Pharma"],
  },
  {
    primary_id: "D09QPH",
    names: ["Sch 202596"],
  },
  {
    primary_id: "D09QPT",
    names: ["3-Methyl-2H-Benzo[f]quinazolin-1-One"],
  },
  {
    primary_id: "D09QPV",
    names: ["QwFwLL-NH2", "CHEMBL499371"],
  },
  {
    primary_id: "D09QQE",
    names: ["Crodox", "Crodox (Liposome/Injectable, Ovarian/Breast Cancer), GP Pharm"],
  },
  {
    primary_id: "D09QQS",
    names: ["Diazepinone Derivative 1"],
  },
  {
    primary_id: "D09QRI",
    names: ["NX-001"],
  },
  {
    primary_id: "D09QRL",
    names: [
      "2-Methyl-8-Phenyl-1,2,3,4-Tetrahydro-Isoquinoline",
      "AC1MHU5K",
      "2-Methyl-8-Phenyl-3,4-Dihydro-1H-Isoquinoline",
      "CHEMBL13101",
      "SCHEMBL11488388",
      "Isoquinoline, 1,2,3,4-Tetrahydro-2-Methyl-8-Phenyl-",
    ],
  },
  {
    primary_id: "D09QRO",
    names: ["CGS-13080"],
  },
  {
    primary_id: "D09QRS",
    names: ["Chondrocelect", "Chondrocelect (TN)"],
  },
  {
    primary_id: "D09QSC",
    names: ["PSMA Protein Vaccine"],
  },
  {
    primary_id: "D09QSH",
    names: ["Aminocyclopentenone Compound 5"],
  },
  {
    primary_id: "D09QSO",
    names: [
      "(+/-)-Threo-N-(2-Phenylethyl)Ritalinol",
      "CHEMBL1254280",
      "BDBM50327113",
      "(BetaR)-Beta-[(2R)-1-Phenethylpiperidine-2beta-Yl]phenethyl Alcohol",
    ],
  },
  {
    primary_id: "D09QSY",
    names: ["Frescolat MGA"],
  },
  {
    primary_id: "D09QTC",
    names: [
      "6,7-Dimethoxy-4-(3-Methoxy-Phenoxy)-Quinoline",
      "CHEMBL98583",
      "6,7-Dimethoxy-4-(3-Methoxyphenoxy)Quinoline",
      "SCHEMBL7481076",
      "SORFICSDHDADIC-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D09QTL",
    names: ["Oxygen Carrier"],
  },
  {
    primary_id: "D09QTS",
    names: ["LC-01"],
  },
  {
    primary_id: "D09QUE",
    names: ["MVA-BN HIV Polytope"],
  },
  {
    primary_id: "D09QUN",
    names: [
      "VU0404251",
      "1276013-77-4",
      "CHEMBL2151817",
      "(R)-6-(3-Fluorobenzyloxy)-N-(1,2,2-Trimethylpropyl)Nicotinamide",
      "SCHEMBL6928852",
      "GTPL6410",
      "ZINC68267021",
      "BDBM50392432",
      "AKOS027446878",
      "KB-186257",
      "(R)-6-(3-Fluoro-Benzyloxy)-N-(1,2,2-Trimethyl-Propyl)-Nicotinamide",
    ],
  },
  {
    primary_id: "D09QUQ",
    names: ["Levobupivacaine"],
  },
  {
    primary_id: "D09QVD",
    names: ["4-(2-Bromobenzylthio)-2-Aminobutanoic Acid", "CHEMBL204646", "BDBM50179711"],
  },
  {
    primary_id: "D09QVI",
    names: ["LQB-118", "Antiparasitic (Cutaneous Leishmaniasis), Universidade Federal Do Rio De Janeiro/FIOCRUZ"],
  },
  {
    primary_id: "D09QVR",
    names: ["BMS-986218"],
  },
  {
    primary_id: "D09QVT",
    names: ["Streptococcus Vaccines"],
  },
  {
    primary_id: "D09QVU",
    names: ["APH-0703"],
  },
  {
    primary_id: "D09QVV",
    names: ["Vincristine"],
  },
  {
    primary_id: "D09QWD",
    names: [
      "CEP-37251",
      "ART-010",
      "EGX-010",
      "OVP (Bone Loss), Peptech",
      "Osteoprotegerin Variant Protein (Bone Loss), Arana",
      "Osteoprotegerin Variant Protein (Bone Loss), EvoGenix",
      "Osteoprotegerin Variant Protein (Bone Loss), Peptech",
    ],
  },
  {
    primary_id: "D09QWK",
    names: [
      "AMG-327",
      "Bradykinin B1 Receptor Antagonists (Inflammatory Pain), Amgen",
      "Bradykinin B1 Receptor Antagonists (Pain/Inflammation), Amgen",
    ],
  },
  {
    primary_id: "D09QWN",
    names: [
      "CB4211",
      "2-(4-Bromophenyl)Propan-2-Amine",
      "17797-12-5",
      "1-(4-BROMOPHENYL)-1-METHYLETHYLAMINE",
      "4-Bromo-Alpha,alpha-Dimethylbenzylamine",
      "1-(4-Bromo-Phenyl)-1-Methyl-Ethylamine",
      "4-Bromocumylamine",
      "AC1Q1NMD",
      "SCHEMBL756324",
      "AMOT0675",
      "CTK6A4853",
      "DTXSID10629746",
      "MolPort-009-685-068",
      "IQKKTIWXHJDLFL-UHFFFAOYSA-N",
      "2-(4-Bromophenyl)-2-Propanamine",
      "5952AD",
      "ZINC19673395",
      "AKOS004119571",
      "MCULE-7805384456",
      "AN-3637",
      "CB-4211",
      "QC-8287",
      "AB29702",
      "KS-000009M3",
      "1-(4-Bromophenyl)-1-Methyl-Ethylamine",
      "BS-13505",
      "AJ-74161",
      "SC-44400",
      "2-(4-BROMOPHENYL)PROP-2-YLAMINE",
      "2-(4-B",
    ],
  },
  {
    primary_id: "D09QXC",
    names: ["PMID25666693-Compound-79"],
  },
  {
    primary_id: "D09QXR",
    names: [
      "3-Phenyl-1,2-Propandiol",
      "3-Phenylpropane-1,2-Diol",
      "17131-14-5",
      "3-Phenyl-1,2-Propanediol",
      "AC1L59FY",
      "AC1Q77AE",
      "SCHEMBL25158",
      "1,2-Propanediol,3-Phenyl-",
      "CTK4D3895",
      "NSC4322",
      "DTXSID80277814",
      "NSC-4322",
      "AKOS017514819",
      "DA-09349",
    ],
  },
  {
    primary_id: "D09QYD",
    names: ["ILS920"],
  },
  {
    primary_id: "D09QZB",
    names: ["Resorcinol Compound 32"],
  },
  {
    primary_id: "D09QZI",
    names: ["Norgestimate"],
  },
  {
    primary_id: "D09QZJ",
    names: [
      "AC-(D)PHE-PRO-BOROHOMOLYS-OH",
      "AC1NRARZ",
      "DB07659",
      "N-Acetyl-D-Phenylalanyl-N-[(1R)-6-Amino-1-(Dihydroxyboranyl)Hexyl]-L-Prolinamide",
      "(1R)-6-Amino-1-{[(2S)-1-[(2R)-2-Acetamido-3-Phenylpropanoyl]pyrrolidin-2-Yl]formamido}hexylboronic Acid",
      "[(1R)-1-[[(2S)-1-[(2R)-2-Acetamido-3-Phenylpropanoyl]pyrrolidine-2-Carbonyl]amino]-6-Aminohexyl]boronic Acid",
    ],
  },
  {
    primary_id: "D09QZK",
    names: ["RIG-I Adjuvant"],
  },
  {
    primary_id: "D09QZL",
    names: ["3-Chloro-4-(4-Chloro-2-Hydroxyphenoxy)Benzamide", "CHEMBL198887"],
  },
  {
    primary_id: "D09QZM",
    names: ["2-(Aminomethyl)-5-(2'-Naphthyl)Tetrahydrofuran", "CHEMBL504028", "SCHEMBL2690271", "BDBM50276457"],
  },
  {
    primary_id: "D09QZS",
    names: ["Olaratumab"],
  },
  {
    primary_id: "D09RAB",
    names: ["PRO-300"],
  },
  {
    primary_id: "D09RAM",
    names: ["4-(5-(4-Aminophenyl)Thiophen-2-Yl)Phenol", "CHEMBL205824", "SCHEMBL12973258"],
  },
  {
    primary_id: "D09RBQ",
    names: ["Zutectra", "Anti-HBV Human Immunoglobulins (HBV Re-Infection Prevention), Biotest"],
  },
  {
    primary_id: "D09RCL",
    names: ["(2R,3S)-2-[(3-Iodophenoxy)Phenylmethyl]morpholine", "CHEMBL569927"],
  },
  {
    primary_id: "D09RDT",
    names: ["GT-3021"],
  },
  {
    primary_id: "D09RED",
    names: ["Prostat"],
  },
  {
    primary_id: "D09REF",
    names: [
      "1-Acetoxylycorine",
      "1-O-Acetyllycorine",
      "1-Acetyllycorine",
      "CHEMBL251077",
      "CHEBI:31045",
      "C12166",
      "AC1L9EZ8",
      "SCHEMBL12319390",
      "BDBM50221063",
      "Acetic Acid (1S,2S,12bS,12cS)-2-Hydroxy-1,2,4,5,12b,12c-Hexahydro-7H-[1,3]dioxolo[4,5-J]pyrrolo[3,2,1-De]phenanthridin-1-Yl Ester",
      "(1S,2S,12bS,12cS)-1,2-Diol-2,4,5,7, 12b,12c-Hexahydro-1H-[1,3]dioxolo[4,5-J]pyrrolo[3,2,1-De]phenanthridine-1-Acetate",
    ],
  },
  {
    primary_id: "D09RES",
    names: ["N1-Methyl-1,9-Pyrazoloanthrone"],
  },
  {
    primary_id: "D09REZ",
    names: ["PMID27724045-Compound-14"],
  },
  {
    primary_id: "D09RFD",
    names: ["ISIS 7948"],
  },
  {
    primary_id: "D09RFE",
    names: [
      "4-(3-Phenoxylphenyl)-5-Cyano-2H-1,2,3-Triazole",
      "CHEMBL428206",
      "SCHEMBL17969160",
      "CTK8F9477",
      "BDBM50200766",
    ],
  },
  {
    primary_id: "D09RGE",
    names: ["Carbamide Derivative 25"],
  },
  {
    primary_id: "D09RGM",
    names: [
      "N,N-Bis(Tosylmethyl)Hexan-1-Amine",
      "CHEMBL252145",
      "AC1MWCYZ",
      "N,N-Bis[(4-Methylphenyl)Sulfonylmethyl]hexan-1-Amine",
      "N,N-Bis(Tosylmethyl)Hexane-1-Amine",
    ],
  },
  {
    primary_id: "D09RGS",
    names: [
      "Anti-CD97 Mab",
      "Anti-CD97 Monoclonal Antibody (Rheumatoid Arthritis)",
      "Anti-CD97 MAb (RA), Organon/AMC",
      "Anti-CD97 Monoclonal Antibody (Rheumatoid Arthritis), Organon/AMC",
      "Anti-CD97 MAb (RA), Schering-Plough/AMC",
      "Anti-CD97 Monoclonal Antibody (Rheumatoid Arthritis), Merck & Co/AMC",
      "Anti-CD97 Monoclonal Antibody (Rheumatoid Arthritis), Schering-Plough/AMC",
    ],
  },
  {
    primary_id: "D09RGW",
    names: [
      "AZD-3778",
      "CHEMBL2158814",
      "SCHEMBL5418656",
      "GTPL7679",
      "AZD3778",
      "QVLZVRFIGXNZMN-UHFFFAOYSA-N",
      "AZD 3778",
      "BDBM50394155",
      "N-[[4-(3,4-Dichlorophenoxy)[1,4'-Bipiperidin]-1'-Yl]carbonyl]-4-Methyl-Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D09RGZ",
    names: ["Up3U"],
  },
  {
    primary_id: "D09RHQ",
    names: ["Ropivacaine"],
  },
  {
    primary_id: "D09RHS",
    names: [
      "Arilvax",
      "Arilax",
      "Vaccine (Yellow Fever), PowderJect",
      "Vaccine (Yellow Fever), Medeva/Peptide Therapeutics Group",
    ],
  },
  {
    primary_id: "D09RIC",
    names: ["PI1", "CHEMBL1801361", "GTPL5814", "SCHEMBL2383164", "BDBM50348617"],
  },
  {
    primary_id: "D09RIE",
    names: ["D[Cha4]AVP", "CHEMBL265858", "BDBM50205312"],
  },
  {
    primary_id: "D09RII",
    names: ["GBT440"],
  },
  {
    primary_id: "D09RJA",
    names: ["RELVARTM Or BREOTM ELLIPTATM"],
  },
  {
    primary_id: "D09RJH",
    names: ["Huprine X"],
  },
  {
    primary_id: "D09RJN",
    names: ["S-17092-1"],
  },
  {
    primary_id: "D09RJT",
    names: ["Grandisine C"],
  },
  {
    primary_id: "D09RKA",
    names: ["Santacruzamate A", "CAY10683"],
  },
  {
    primary_id: "D09RKU",
    names: ["SCH-1518291"],
  },
  {
    primary_id: "D09RKX",
    names: ["PSB-11"],
  },
  {
    primary_id: "D09RLQ",
    names: ["CDX-1127"],
  },
  {
    primary_id: "D09RMS",
    names: ["AMT-070"],
  },
  {
    primary_id: "D09RMU",
    names: ["GPI-0100"],
  },
  {
    primary_id: "D09RMZ",
    names: ["HibTITER"],
  },
  {
    primary_id: "D09RND",
    names: ["EZN-4176"],
  },
  {
    primary_id: "D09RNF",
    names: ["BMS641", "BMS-641", "BMS 641"],
  },
  {
    primary_id: "D09RNI",
    names: ["ESN-YY"],
  },
  {
    primary_id: "D09RNK",
    names: ["5-Biphenyl-4-Ylethynyl-Pyrimidine", "CHEMBL447469"],
  },
  {
    primary_id: "D09RNP",
    names: ["GSK 679769"],
  },
  {
    primary_id: "D09ROG",
    names: ["UCL-2138"],
  },
  {
    primary_id: "D09RON",
    names: ["LY-2300559"],
  },
  {
    primary_id: "D09ROS",
    names: ["PMID21444206C23"],
  },
  {
    primary_id: "D09ROV",
    names: [
      "Nortropinyl-Arylsulfonylurea 3",
      "CHEMBL333727",
      "BDBM50134335",
      "Benzeneacetic Acid (1alpha,5alpha)-8-[3-(4-Chlorophenylsulfonyl)Ureido]-8-Azabicyclo[3.2.1]octane-3beta-Yl Ester",
      "8-[N-(4-Chlorophenylsulfonamido)-Carbonyl Amino]-8-Azabicyclo[3.2.1]oct-3-Yl 2-Phenylacetate",
    ],
  },
  {
    primary_id: "D09ROW",
    names: ["IMPERATORIN"],
  },
  {
    primary_id: "D09RPB",
    names: ["DFD-06"],
  },
  {
    primary_id: "D09RPP",
    names: ["CAR-GPC3 T Cell"],
  },
  {
    primary_id: "D09RPR",
    names: ["SP203"],
  },
  {
    primary_id: "D09RPU",
    names: ["4'-GALACTOSYLLACTOSE"],
  },
  {
    primary_id: "D09RPY",
    names: [
      "Benzoic Acid 2,6-Diisopropyl-Phenyl Ester",
      "CHEMBL55916",
      "SCHEMBL8380667",
      "ZINC13779585",
      "Benzoic Acid 2,6-Diisopropylphenyl Ester",
    ],
  },
  {
    primary_id: "D09RQD",
    names: ["2-Cyclopropylmethylenepropanal", "AC1NRALT", "(2S)-3-Cyclopropyl-2-Methylpropanal"],
  },
  {
    primary_id: "D09RRF",
    names: ["AC261066", "AC 261066", "AC-261066"],
  },
  {
    primary_id: "D09RRG",
    names: ["BK-4SP"],
  },
  {
    primary_id: "D09RSL",
    names: ["JMV458"],
  },
  {
    primary_id: "D09RSN",
    names: ["MediPhage MRPA", "Bacteriophage Cocktail (Pseudomonas Aeruginosa Infection), Special Phage Holdings"],
  },
  {
    primary_id: "D09RSU",
    names: ["N-CPM[D-Pro-10]Dyn A-(1-11)", "CHEMBL442323"],
  },
  {
    primary_id: "D09RSY",
    names: ["Onyvax-P"],
  },
  {
    primary_id: "D09RTF",
    names: ["VT-1"],
  },
  {
    primary_id: "D09RTK",
    names: ["Tapet"],
  },
  {
    primary_id: "D09RTQ",
    names: ["NanoEmulsion NSAID"],
  },
  {
    primary_id: "D09RTT",
    names: ["ISIS 113022"],
  },
  {
    primary_id: "D09RTV",
    names: ["PMID25514969-Compound-9"],
  },
  {
    primary_id: "D09RTW",
    names: ["Aromatic Bicyclic Compound 6"],
  },
  {
    primary_id: "D09RUJ",
    names: ["SX-GLP1"],
  },
  {
    primary_id: "D09RUN",
    names: ["Pyrazolopyridine Derivative 4"],
  },
  {
    primary_id: "D09RUO",
    names: ["SGN-CD352A"],
  },
  {
    primary_id: "D09RUW",
    names: ["Iomeprol"],
  },
  {
    primary_id: "D09RUY",
    names: ["N-Benzyl-17-(Cyclopropylmethyl)Morphinan-3-Amine", "CHEMBL571998", "MCL-610"],
  },
  {
    primary_id: "D09RVI",
    names: ["Tegobuvir"],
  },
  {
    primary_id: "D09RWM",
    names: ["PMID27321640-Compound-59"],
  },
  {
    primary_id: "D09RWW",
    names: ["3-Cyclopentoxycarbonyl-6-Ethyl-4-Quinolone", "CHEMBL202724"],
  },
  {
    primary_id: "D09RXG",
    names: ["EA-230"],
  },
  {
    primary_id: "D09RXJ",
    names: [
      "2-Hydroxy-6-(3-Phenoxy-Benzoylamino)-Benzoic Acid",
      "CHEMBL193840",
      "BDBM23612",
      "3-Phenoxybenzoylamino Deriv., 28f",
      "2-Hydroxy-6-(3-Phenoxybenzoylamino)Benzoic Acid",
      "2-Hydroxy-6-[(3-Phenoxybenzene)Amido]benzoic Acid",
    ],
  },
  {
    primary_id: "D09RXU",
    names: ["2-Benzyl-4-Piperazin-1-Yl-1H-Benzimidazole", "CHEMBL578411"],
  },
  {
    primary_id: "D09RXX",
    names: ["Anti-EGFR Mab"],
  },
  {
    primary_id: "D09RYG",
    names: ["6-Deoxymanzamine X"],
  },
  {
    primary_id: "D09RYU",
    names: ["Tractinostat"],
  },
  {
    primary_id: "D09RYV",
    names: ["Ro-0505124"],
  },
  {
    primary_id: "D09RZB",
    names: [
      "BBI-11008",
      "DOR Agonists (Oral, Chronic Inflammatory Pain), Biousian Biosystems",
      "Delta Opioid Receptor Agonists (Oral, Chronic Inflammatory Pain), Biousian Biosystems",
    ],
  },
  {
    primary_id: "D09RZH",
    names: [
      "AdRTVP-1",
      "AdRTVP-1, Baylor College Of Medicine",
      "Gene Therapy (RTVP-1)",
      "Gene Therapy (RTVP-1), Baylor College Of Medicine",
      "Related To Testes-Specific Vespid And Pathogenesis Protein-1 Adenoviral Gene Therapy, BCM",
    ],
  },
  {
    primary_id: "D09RZM",
    names: ["E-1101", "E-1100"],
  },
  {
    primary_id: "D09RZQ",
    names: [
      "CBiPES",
      "LY-566332",
      "856702-40-4",
      "GTPL3372",
      "DTXSID70432071",
      "ZINC43201133",
      "NCGC00370754-01",
      "LY566332",
    ],
  },
  {
    primary_id: "D09RZX",
    names: ["CNICIN", "Cnicin", "24394-09-0", "CTK8F8750"],
  },
  {
    primary_id: "D09SAH",
    names: ["PZ01 CAR-T Cells"],
  },
  {
    primary_id: "D09SAU",
    names: ["IMM-160"],
  },
  {
    primary_id: "D09SAV",
    names: ["Dendritic Cell PEG Fusion Cancer Vaccine"],
  },
  {
    primary_id: "D09SBE",
    names: ["Anti-CD7 CAR-T Cells"],
  },
  {
    primary_id: "D09SBU",
    names: ["C[L-Ala-D-Pro-L-Phe-D-Trp]", "CHEMBL499595", "SCHEMBL7991248"],
  },
  {
    primary_id: "D09SBY",
    names: ["Hebervis", "CIGB-400", "EGF (Buccal Local, Mucositis), CIGB", "EGF (Rectal, Proctitis), CIGB"],
  },
  {
    primary_id: "D09SCI",
    names: [
      "SKF-104351",
      "111908-94-2",
      "Skf 104351",
      "F 104351",
      "5H-Pyrrolo[1,2-A]imidazole,2-(4-Fluorophenyl)-6,7-Dihydro-3-(4-Pyridinyl)-",
      "2-(4-Fluorophenyl)-3-(4-Pyridyl)-6,7-Dihydro-5H-Pyrrolo[1,2-A]imidazole",
      "F-104351",
      "AC1L3V0R",
      "SCHEMBL7378515",
      "DTXSID90149775",
      "GJFVAEMLAFFGDZ-UHFFFAOYSA-N",
      "ZINC3815711",
      "5H-Pyrrolo(1,2-A)Imidazole, 2-(4-Fluorophenyl)-6,7-Dihydro-3-(4-Pyridinyl)-",
      "KB-292516",
      "2-P-Fluorophenyl-3-(4-Pyridyl)-6,7-Dihydro[5H]-Pyrrolo[1,2-A]imidazole",
    ],
  },
  {
    primary_id: "D09SCO",
    names: ["Hemagglutinin Inhibitors"],
  },
  {
    primary_id: "D09SCP",
    names: [
      "ADXS-PSA",
      "Advaxis PSA",
      "Lovaxin P",
      "Lm?CLLO?CPSA",
      "ADXS31-142",
      "PSA/Listeria Vector (Cancer), Advaxis",
      "Prostate Specific Antigen/Listeria Vector (Cancer), Advaxis",
    ],
  },
  {
    primary_id: "D09SCS",
    names: ["CP55,244", "CP 55244", "CP-55,244", "CP 55,244", "CP55244", "CP-55244"],
  },
  {
    primary_id: "D09SDZ",
    names: ["GWTLNSAGYLLGPHAV-NH2", "CHEMBL508083"],
  },
  {
    primary_id: "D09SFH",
    names: ["R-82150"],
  },
  {
    primary_id: "D09SFJ",
    names: ["AR-C126313", "Compound 71a [PMID: 15078212]", "Compound 52 [PMID: 18600475]"],
  },
  {
    primary_id: "D09SFV",
    names: [
      "LY294002",
      "2-(4-Morpholinyl)-8-Phenyl-4H-1-Benzopyran-4-One",
      "154447-36-6",
      "LY 294002",
      "LY-294002",
      "2-Morpholino-8-Phenyl-4H-Chromen-4-One",
      "2-Morpholin-4-Yl-8-Phenyl-4H-Chromen-4-One",
      "UNII-31M2U1DVID",
      "LY-294,002",
      "2-(Morpholin-4-Yl)-8-Phenyl-4H-Chromen-4-One",
      "31M2U1DVID",
      "2-MORPHOLIN-4-YL-7-PHENYL-4H-CHROMEN-4-ONE",
      "CHEMBL98350",
      "4H-1-Benzopyran-4-One, 2-(4-Morpholinyl)-8-Phenyl-",
      "CHEBI:65329",
      "2-Morpholino-8-Phenyl-Chromen-4-One",
      "AK162301",
      "2-(Morpholin-4-Yl)-8-Phenylchromen-4-One",
      "LY2",
      "15447-36-6",
      "IN1266",
      "IN1268",
      "Lys 294002",
      "BMK1-D5",
      "H-1-Benzopyran-4-One, 2-(4-Morpholinyl)-8-Phenyl-(9CI)",
      "2-(4-Morpholino)-8-Phenyl-4H-1-Benzopyran-4-One",
      "2-Morpholin-4-Yl-8-Phenylchromen-4-One",
      "4H-1-Benzopyran-4-One, 2-(4-Morpholinyl)-8-Phenyl",
      "4H-1-Benzopyran-4-One, 2-(4-Morpholinyl)-8-Phenyl-(9CI)",
      "8-Phenyl-2-(Morpholin-4-Yl)-Chromen-4-One",
      "2-(4-Morpholinyl)-8-Phenyl-4h-1-Benzopyran-4-One",
    ],
  },
  {
    primary_id: "D09SFX",
    names: ["AGEN1884"],
  },
  {
    primary_id: "D09SFY",
    names: ["2-(4-Hydroxybenzylideneamino)Ethanesulfonamide"],
  },
  {
    primary_id: "D09SGL",
    names: ["BTP-114"],
  },
  {
    primary_id: "D09SGU",
    names: ["Biphenyl Carboxylic Acid Derivative 2"],
  },
  {
    primary_id: "D09SGV",
    names: [
      "Daclatasvir",
      "1009119-64-5",
      "Daclatasvir (BMS-790052)",
      "1214735-16-6",
      "CHEBI:82977",
      "C40H50N8O6",
      "Methyl N-[(1S)-1-[(2S)-2-[5-[4-[4-[2-[(2S)-1-[(2S)-2-(Methoxycarbonylamino)-3-Methyl-Butanoyl]pyrrolidin-2-Yl]-1H-Imidazol-5-Yl]phenyl]phenyl]-1H-Imidazol-2-Yl]pyrrolidine-1-Carbonyl]-2-Methyl-Propyl]carbamate",
      "Daclatasvir (USAN)",
      "Cc-39",
      "Daclatasvir BMS 790052",
      "MLS006011140",
      "SCHEMBL2756027",
      "CHEMBL2023898",
      "SCHEMBL17897804",
      "KS-00000XPC",
      "EX-A410",
    ],
  },
  {
    primary_id: "D09SHC",
    names: ["ALT-804"],
  },
  {
    primary_id: "D09SHH",
    names: ["BAX-817"],
  },
  {
    primary_id: "D09SHL",
    names: ["S. Epidermidis Vaccine"],
  },
  {
    primary_id: "D09SHX",
    names: ["GM-CSF Cancer Vaccine"],
  },
  {
    primary_id: "D09SIK",
    names: [
      "Azaribine",
      "AZARIBINE",
      "Triazure",
      "Triacetyl-6-Azauridine",
      "Azaribinum",
      "Azaribina",
      "2169-64-4",
      "6-Azaribine",
      "TA-Azur",
      "Azaribin",
      "2',3',5'-Tri-O-Acetyl-6-Azauridine",
      "6-Azauridine Triacetate",
      "6-Azauridin-Triacetat",
      "6-Azauridine 2',3',5'-Triacetate",
      "Azaribinum [INN-Latin]",
      "6-AzUR-TA",
      "Azaribina [INN-Spanish]",
      "2',3',5'-Triacetyl-6-Azauridine",
      "CB-304",
      "UNII-K1U80DO9EB",
      "6-Azauracilribosid-Triacetat",
      "K1U80DO9EB",
      "Azaribine [USAN:INN:BAN]",
      "MLS000069488",
      "EINECS 218-515-6",
      "Azauridine Triacetate",
      "2-Beta-D-Ribofuranosyl-As-Triazine-3,5(2H,4H)-Dione 2',3',5'-Triace",
    ],
  },
  {
    primary_id: "D09SIR",
    names: [
      "Lonidamine",
      "DICA",
      "Doridamina",
      "Lonidamin",
      "Lonidamina",
      "Lonidaminum",
      "Dichlondazolic Acid",
      "Diclondazolic Acid",
      "AF 1890",
      "L 4900",
      "AF-1890",
      "Doridamina (TN)",
      "KN-228",
      "Lonidamina [INN-Spanish]",
      "Lonidamine (INN)",
      "Lonidamine [BAN:INN]",
      "Lonidaminum [INN-Latin]",
      "TH-070",
      "1-(2,4-Dichlorbenzyl)-Indazole-3-Carboxylic Acid",
      "1-(2,4-Dichlorobenzyl)-1H-Indazole-3-Carboxylic Acid",
      "1-(2,4-Dichlorobenzyl)Indazole-3-Carboxylic Acid",
      "1-[(2,4-Dichlorophenyl)Methyl]-1H-Indazole-3-Carboxylic Acid",
      "1-[(2,4-Dichlorophenyl)Methyl]indazole-3-Carboxylic Acid",
      "1H-Indazole-3-Carboxylic Acid, 1-((2,4-Dichlorophenyl)Methyl)-(9CI)",
    ],
  },
  {
    primary_id: "D09SIW",
    names: ["2-(Hexylthiomethyl)-5-Hydroxy-4H-Pyran-4-One", "CHEMBL1269400", "1257846-10-8"],
  },
  {
    primary_id: "D09SJE",
    names: ["KB002/003"],
  },
  {
    primary_id: "D09SJK",
    names: ["Metformin Delayed-Release Formulation"],
  },
  {
    primary_id: "D09SJY",
    names: ["2-Methoxy-4-(O-Tolyloxy)Benzonitrile", "CHEMBL491493", "SCHEMBL4178171"],
  },
  {
    primary_id: "D09SLC",
    names: ["Inhaled Supergeneric"],
  },
  {
    primary_id: "D09SLI",
    names: ["Aryl Mannoside Derivative 13"],
  },
  {
    primary_id: "D09SLU",
    names: ["LG-101280"],
  },
  {
    primary_id: "D09SMA",
    names: ["PMID25666693-Compound-128"],
  },
  {
    primary_id: "D09SMG",
    names: ["Hepatyrix"],
  },
  {
    primary_id: "D09SOA",
    names: [
      "Clonixin",
      "CLONIXIN",
      "17737-65-4",
      "Clonixic Acid",
      "Clonixine",
      "Chlonixin",
      "Deltar",
      "Sch 10304",
      "Clonixinum",
      "Clonixino",
      "2-(3-Chloro-O-Toluidino)Nicotinic Acid",
      "2-(3-Chloro-2-Methylanilino)Nicotinic Acid",
      "Clonixin [USAN:INN]",
      "Clonixine [INN-French]",
      "Clonixinum [INN-Latin]",
      "Clonixino [INN-Spanish]",
      "UNII-V7DXN0M42R",
      "Nicotinic Acid, 2-(3-Chloro-O-Toluidino)-",
      "CBA 93626",
      "NSC 335505",
      "Sch-10304",
      "CHEBI:76200",
      "2-(2'-Methyl-3'-Chloro)Anilinonicotinic Acid",
      "EINECS 241-730-1",
      "BRN 0483212",
      "V7DXN0M42R",
      "C13H11ClN2O2",
    ],
  },
  {
    primary_id: "D09SOD",
    names: ["RAP-102"],
  },
  {
    primary_id: "D09SOY",
    names: [
      "5,5-Diphenyl-2-Iminohydantoin",
      "CHEMBL291784",
      "SCHEMBL4237550",
      "2-Amino-3-Methyl-5,5-Diphenyl-3,5-Dihydro-Imidazol-4-One",
      "BDBM50300229",
      "26975-80-4",
      "2-Imino-3-Methyl-5,5-Diphenylimidazolidin-4-One",
      "2-Imino-3-Methyl-5,5-Diphenyl-Imidazolidin-4-One",
      "1-Methyl-4,4-Diphenyl-4,5-Dihydro-1H-Imidazol-2-Amine",
    ],
  },
  {
    primary_id: "D09SQM",
    names: ["VAY736"],
  },
  {
    primary_id: "D09SRR",
    names: ["Unoprostone"],
  },
  {
    primary_id: "D09SRZ",
    names: ["Recombinant Factor IX-Fc"],
  },
  {
    primary_id: "D09SSC",
    names: ["Nipradilol"],
  },
  {
    primary_id: "D09SSG",
    names: ["PMID28350212-Compound-24"],
  },
  {
    primary_id: "D09SSY",
    names: ["FoscarnetAndOrCidofovir"],
  },
  {
    primary_id: "D09STY",
    names: ["PMID29334795-Compound-55"],
  },
  {
    primary_id: "D09SUO",
    names: ["Splitomicin"],
  },
  {
    primary_id: "D09SUP",
    names: ["Peptide III-BTD"],
  },
  {
    primary_id: "D09SUZ",
    names: ["2-Aminoquinazoline-4-Carboxyanilide", "CHEMBL238091", "SCHEMBL3086622"],
  },
  {
    primary_id: "D09SVB",
    names: ["SEB-Humab", "Anti-Staphylococcus Enterotoxin B Monoclonal Antibody, Immunobiotherapeutics"],
  },
  {
    primary_id: "D09SVH",
    names: ["N8,2-Dihydroxy-N1-Phenyloctanediamide", "CHEMBL251009", "SCHEMBL8144564"],
  },
  {
    primary_id: "D09SVQ",
    names: ["Dibenzoxazepine", "CR Gas"],
  },
  {
    primary_id: "D09SWE",
    names: ["PMID25399762-Compound-Table 7-4-Methoxybenzaldehyde"],
  },
  {
    primary_id: "D09SWG",
    names: ["Azole Derivative 1"],
  },
  {
    primary_id: "D09SWM",
    names: ["Epostane"],
  },
  {
    primary_id: "D09SXJ",
    names: [
      "NC-0604",
      "Bleomycin Analogue (Antibacterial, Antitumoral), Institute Of Medicinial Biotechnology Chinese Academy Of Medical Sciences",
    ],
  },
  {
    primary_id: "D09SXM",
    names: [
      "(R)-1-(1H-Indazol-4-Yl)-3-(1-P-Tolylethyl)Urea",
      "CHEMBL397459",
      "Indazole Antagonist, (R)-16b",
      "BDBM20407",
    ],
  },
  {
    primary_id: "D09SXN",
    names: ["MAP4"],
  },
  {
    primary_id: "D09SXQ",
    names: ["IRX-02", "Anti-S100 Antibody (Leukemia), Inflammatorx"],
  },
  {
    primary_id: "D09SXR",
    names: ["KRH-102053"],
  },
  {
    primary_id: "D09SYB",
    names: ["J5dLPS/OMP"],
  },
  {
    primary_id: "D09SYD",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 26"],
  },
  {
    primary_id: "D09SYK",
    names: ["Etifoxine"],
  },
  {
    primary_id: "D09SYW",
    names: ["N-Hydroxycarbamoylmethyl-4-Phenoxy-Benzamide", "CHEMBL198543"],
  },
  {
    primary_id: "D09SYX",
    names: ["PMID26394986-Compound-51"],
  },
  {
    primary_id: "D09SZG",
    names: [
      "[2(R,S)-2-Sulfanylheptanoyl]-Phe-Ala",
      "[2(R,S)-2-SULFANYLHEPTANOYL]-PHE-ALA",
      "TI1",
      "AC1NRDCJ",
      "1qf1",
      "DB02597",
      "N-[(2S)-2-Sulfanylheptanoyl]-L-Phenylalanyl-L-Alanine",
      "N-[N-[(S)-2-Mercaptoheptanoyl]-L-Phenylalanyl]-L-Alanine",
      "(2S)-2-[[(2S)-3-Phenyl-2-[[(2S)-2-Sulfanylheptanoyl]amino]propanoyl]amino]propanoic Acid",
      "(2S)-2-[(2S)-3-Phenyl-2-[(2S)-2-Sulfanylheptanamido]propanamido]propanoic",
    ],
  },
  {
    primary_id: "D09SZJ",
    names: ["Pycnogenol"],
  },
  {
    primary_id: "D09SZL",
    names: ["NV-31"],
  },
  {
    primary_id: "D09SZY",
    names: [
      "IQ-DAA",
      "Anti-Anthrax Toxin MAb, IQ",
      "Human Monoclonal Antibodies (Anthrax), IQ",
      "IQN-Protective Antigen (Human MAb), IQ Corporation",
      "MAb-Based Immunotherapy (Anthrax), IQ",
      "IQNLF + IQNPA (Human MAbs, Bacillus Anthracis Infection), IQ Therapeutics",
    ],
  },
  {
    primary_id: "D09TAB",
    names: ["PLX-3397"],
  },
  {
    primary_id: "D09TBA",
    names: [
      "5-Benzyl-6-Chloropyrimidine-2,4(1H,3H)-Dione",
      "18493-82-8",
      "Oprea1_007440",
      "SCHEMBL2672214",
      "CHEMBL394673",
      "BDBM20060",
      "LWRITIARHSTBLU-UHFFFAOYSA-N",
      "5-Substituted-6-Chlorouracil, 5g",
      "5-Benzyl-6-Chloro-1H-Pyrimidine-2,4-Dione",
      "SR-01000516321",
      "SR-01000516321-1",
    ],
  },
  {
    primary_id: "D09TBD",
    names: [
      "Sulfisoxazole",
      "Accuzole",
      "Alphazole",
      "Amidoxal",
      "Astrazolo",
      "Azosulfizin",
      "Bactesulf",
      "Barazae",
      "Chemouag",
      "Cosoxazole",
      "Dorsulfan",
      "ERYZOLE",
      "Entusil",
      "Entusul",
      "Ganda",
      "Gantrisin",
      "Gantrisine",
      "Gantrisona",
      "Gantrosan",
      "Isoxamin",
      "Neazolin",
      "Neoxazoi",
      "Neoxazol",
      "Novazolo",
      "Novosaxazole",
      "Pancid",
      "Renosulfan",
      "Resoxol",
      "Roxosul",
      "Roxoxol",
      "SOXO",
      "Saxosozine",
      "Sodizole",
      "Solfafurazolo",
      "Sosol",
      "Soxamide",
      "Soxazole",
      "Soxisol",
      "Soxitabs",
      "Soxomide",
      "Stansin",
      "Sulbio",
      "Sulfadimethylisoxazole",
      "Sulfafurazol",
      "Sulfafurazole",
      "Sulfafurazolum",
      "Sulfagan",
      "Sulfagen",
      "Sulfaisoxazole",
      "Sulfalar",
      "Sulfapolar",
      "Sulfasol",
      "Sulfasoxazole",
      "Sulfasoxizole",
      "Sulfazin",
      "Sulfisin",
      "Sulfisonazole",
      "Sulfisoxasole",
      "Sulfisoxazol",
      "Sulfisoxazolum",
      "Sulfizin",
      "Sulfizol",
      "Sulfizole",
      "Sulfofurazole",
      "Sulfoxol",
      "Suloxsol",
      "Sulphadimethylisoxazole",
      "Sulphafuraz",
      "Sulphafurazol",
      "Sulphafurazole",
      "Sulphafurazolum",
      "Sulphaisoxazole",
      "Sulphisoxazol",
      "Sulphisoxazole",
      "Sulphofurazole",
      "Sulsoxin",
      "Thiasin",
      "Unisulf",
      "Urisoxin",
      "Uritrisin",
      "Urogan",
      "Vagilia",
      "Azo Gantrisin",
      "Component Of Azo Gantrisin",
      "Component Of Azo Gantrisin Accuzole",
      "Dorsulfan Warthausen",
      "Roxosul Tablets",
      "Solfafurazolo [DCIT]",
      "Sulfafuraz Ole",
      "Sulfisoxazole [USAN]",
      "Sulfisoxazole Dialamine",
      "NU 445",
      "Component Of Azo-Sulfizin",
      "G-Sox",
      "Gantrisin (TN)",
      "J-Sul",
      "Koro-Sulf",
      "Norilgan-S",
      "SK-Soxazole",
      "Sulfafurazole (INN)",
      "Sulfafurazolum [INN-Latin]",
      "Sulfazin (VAN)",
      "Sulfisoxazole-Carc",
      "Tl-Azole",
      "US-67",
      "V-Sul",
      "Sulfisoxazole (JP15/USP)",
      "U.S.-67",
      "3,4-Dimethyl-5-Sulfanilamidoisoxazole",
      "3,4-Dimethyl-5-Sulfonamidoisoxazole",
      "3,4-Dimethyl-5-Sulphanilamidoisoxazole",
      "3,4-Dimethyl-5-Sulphonamidoisoxazole",
      "3,4-Dimethylisoxale-5-Sulfanilamide",
      "3,4-Dimethylisoxazole-5-Sulfanilamide",
      "3,4-Dimethylisoxazole-5-Sulphanilamide",
      "3,4-Dimethylisoaxazole-5-Sulfanilimide",
      "5-Sulfanilamido-3,4-Dimethyl-Isoxazole",
      "5-Sulfanilamido-3,4-Dimethylisoxazole",
      "5-Sulphanilamido-3,4-Dimethyl-Isoxazole",
    ],
  },
  {
    primary_id: "D09TBE",
    names: ["MRS1065"],
  },
  {
    primary_id: "D09TBM",
    names: ["PRX-12261"],
  },
  {
    primary_id: "D09TBY",
    names: ["Next-Generation Lipid-Based Agents"],
  },
  {
    primary_id: "D09TCT",
    names: ["YGWFL-NH2", "CHEMBL455502"],
  },
  {
    primary_id: "D09TCY",
    names: ["CDX-1401"],
  },
  {
    primary_id: "D09TDG",
    names: ["NFTB-104"],
  },
  {
    primary_id: "D09TDI",
    names: [
      "CARAMIPEN",
      "Caramiphen",
      "Parpanil",
      "77-22-5",
      "Pentaphen (Pharmaceutical)",
      "Caramiphen [INN:BAN]",
      "Caramiphenum [INN-Latin]",
      "Caramiphene [INN-French]",
      "UNII-97J7NP0XJY",
      "Caramifenio [INN-Spanish]",
      "EINECS 201-013-6",
      "BRN 2144901",
      "97J7NP0XJY",
      "CYCLOPENTANECARBOXYLIC ACID, 1-PHENYL-, 2-(DIETHYLAMINO)ETHYL ESTER",
      "CHEMBL61946",
      "1-Phenylcyclopentanecarboxylic Acid 2-(Diethylamino)Ethyl Ester",
      "2-Diethylaminoethyl 1-Phenylcyclopentancarboxylat",
      "2-(Diethylamino)Ethyl 1-Phenylcyclopentanecarboxylate",
      "2-Diethylaminoethyl 1-Phenylcyclopentane-1-Carboxylate",
    ],
  },
  {
    primary_id: "D09TDK",
    names: ["DPN-205-734", "5-(4-Cyanophenyl)-6-Methyl-2-Oxo-1,2-Dihydropyridine-3-Carbonitrile"],
  },
  {
    primary_id: "D09TDY",
    names: [
      "EPIMER A",
      "CHEMBL564088",
      "BDBM50296787",
      "N-((S)-2''-Oxo-1,1'',2'',3-Tetrahydrospiro[indene-2,3''-Pyrrolo[2,3-B]pyridine]-5-Yl)-2-(4-Oxo-2a,3,4,5-Tetrahydropyrrolo[4,3,2-De]quinolin-1(2H)-Yl)Acetamide 2",
      "N-((S)-2''-Oxo-1,1'',2'',3-Tetrahydrospiro[indene-2,3''-Pyrrolo[2,3-B]pyridine]-5-Yl)-2-(4-Oxo-2a,3,4,5-Tetrahydropyrrolo[4,3,2-De]quinolin-1(2H)-Yl)Acetamide 1",
    ],
  },
  {
    primary_id: "D09TED",
    names: [
      "L-162234",
      "CHEMBL292150",
      "SCHEMBL9377635",
      "IVFMKMCSJZZCKV-UHFFFAOYSA-N",
      "BDBM50035451",
      "L008869",
      "5-N-Butyl-4-[[2''-[N-(Tert-Butoxycarbonyl)Sulfamoyl]biphenyl-4yl]methyl]-2-[2-Trifluoromethyl-Phenyl]-2,4-Dihydro-3H-1,2,4-Triazol-3-One",
      "4''-[3-Butyl-5-Oxo-1-(2-Trifluoromethyl-Phenyl)-1,5-Dihydro-[1,2,4]triazol-4-Ylmethyl]-Biphenyl-2-Sulfonic Acid Amide-N-Carbonyoxylterbutyl",
      "4''-[3-Butyl-5-Oxo-1-(2-Trifluoromethyl-Phenyl)-1,5-Dihydro-[1,2,4]triazol-4-Ylmethyl]-Biphenyl-2-Sulfonic Acid (2-Tert-Butoxycarbonyl)Amide",
    ],
  },
  {
    primary_id: "D09TEI",
    names: ["ReN-1869"],
  },
  {
    primary_id: "D09TES",
    names: [
      "2-Acetamido-2,3-Dihydro-1H-Indene-5-Sulfonic Acid",
      "CHEMBL454461",
      "CHEMBL363489",
      "BDBM50155551",
      "2-Acetylamino-Indan-5-Sulfonic Acid",
    ],
  },
  {
    primary_id: "D09TFB",
    names: [
      "TOFIMILAST",
      "CP-325366",
      "Tofimilast < Prop INN",
      "9-Cyclopentyl-7-Ethyl-3-(2-Thienyl)-5,6-Dihydro-9H-Pyrazolo[3,4-C][1,2,4]triazolo[4,3-A]pyridine",
    ],
  },
  {
    primary_id: "D09TFL",
    names: ["PCI-27483"],
  },
  {
    primary_id: "D09TFN",
    names: ["LDC1267"],
  },
  {
    primary_id: "D09TGC",
    names: ["MK-1903"],
  },
  {
    primary_id: "D09TGD",
    names: ["KUR-112"],
  },
  {
    primary_id: "D09TGO",
    names: ["Autologous ET1402L1-CART Cells"],
  },
  {
    primary_id: "D09TGT",
    names: ["Atu-027"],
  },
  {
    primary_id: "D09TGX",
    names: ["Valspodar"],
  },
  {
    primary_id: "D09TGZ",
    names: ["Examorelin"],
  },
  {
    primary_id: "D09THN",
    names: ["IMGN901"],
  },
  {
    primary_id: "D09THX",
    names: ["WBI-1001"],
  },
  {
    primary_id: "D09TIM",
    names: ["ISIS 122991"],
  },
  {
    primary_id: "D09TIW",
    names: ["SLP-0904"],
  },
  {
    primary_id: "D09TJJ",
    names: ["Pamrevlumab"],
  },
  {
    primary_id: "D09TJK",
    names: ["3-(6-Morpholino-4-Oxo-4H-Pyran-2-Yl)Benzonitrile", "CHEMBL223034"],
  },
  {
    primary_id: "D09TJV",
    names: ["PGC-Lysostaphin"],
  },
  {
    primary_id: "D09TKC",
    names: ["PMID18754614C8"],
  },
  {
    primary_id: "D09TKG",
    names: ["4-(Allylamino)-3-Pyridinesulfonamide", "CHEMBL1164966", "76254-81-4", "Oprea1_825745"],
  },
  {
    primary_id: "D09TKN",
    names: ["PMID2153213C13g"],
  },
  {
    primary_id: "D09TKO",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 15"],
  },
  {
    primary_id: "D09TKY",
    names: ["Biaryl Mannoside Derivative 7"],
  },
  {
    primary_id: "D09TLE",
    names: [
      "Tefinostat",
      "CHR-2845",
      "CHR-2847",
      "CHR-2875",
      "Small Molecule Inhibitor (Hematological Malignancies), Chroma",
    ],
  },
  {
    primary_id: "D09TLF",
    names: ["MIF Antagonists"],
  },
  {
    primary_id: "D09TLJ",
    names: [
      "ATOFLUDING",
      "A-OT",
      "1-Acetyl-5-Fluoro-3-(2-Methylbenzoyl)Pyrimidine-2,4(1H,3H)-Dione",
      "1-Acetyl-5-Fluoro-3-(2-Methylphenyl)Uracil",
    ],
  },
  {
    primary_id: "D09TLT",
    names: ["BZF961"],
  },
  {
    primary_id: "D09TMV",
    names: [
      "AZD-4619",
      "GTPL7821",
      "SCHEMBL14064995",
      "AZD4619",
      "AZD 4619",
      "AZ11942571",
      "2-[2-(4-Methylphenyl)Ethylsulfanyl]-3-[4-[2-(4-Methylsulfonyloxyphenoxy)Ethyl]phenyl]propanoic Acid",
    ],
  },
  {
    primary_id: "D09TNA",
    names: ["1-(3-Amino-Benzyl)-1H-Indole-5-Carboxamidine", "CHEMBL319122", "ZINC26750418"],
  },
  {
    primary_id: "D09TNC",
    names: ["RB-400"],
  },
  {
    primary_id: "D09TNR",
    names: ["Ki-23057"],
  },
  {
    primary_id: "D09TOK",
    names: ["PF-4531083"],
  },
  {
    primary_id: "D09TOL",
    names: ["RG-7816"],
  },
  {
    primary_id: "D09TOS",
    names: ["ST-669", "Broad Spectrum Antiviral, SIGA Technologies"],
  },
  {
    primary_id: "D09TPF",
    names: ["Molindone"],
  },
  {
    primary_id: "D09TPW",
    names: ["PF-04475270"],
  },
  {
    primary_id: "D09TQE",
    names: ["2-(3-Fluoro-4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D09TQI",
    names: ["VT-401"],
  },
  {
    primary_id: "D09TQO",
    names: [
      "Conantokin-G",
      "Conantokin G",
      "UNII-32CFR9L3TD",
      "93438-65-4",
      "32CFR9L3TD",
      "Con-G",
      "GTPL4161",
      "Conantokin G, &gt;=75% (HPLC)",
    ],
  },
  {
    primary_id: "D09TQP",
    names: [
      "3-Phenyl-1,4-Dihydroindeno[1,2-C]pyrazole",
      "CHEMBL215971",
      "SCHEMBL6968182",
      "NZNCUNCNFCUBOK-UHFFFAOYSA-N",
      "BDBM50188284",
      "ZINC36338882",
    ],
  },
  {
    primary_id: "D09TQS",
    names: ["Flulaval QIV"],
  },
  {
    primary_id: "D09TQY",
    names: ["Dusigitumab"],
  },
  {
    primary_id: "D09TRM",
    names: ["D-5410", "CH-138", "CH-263"],
  },
  {
    primary_id: "D09TRN",
    names: [
      "Dihydropyridines",
      "1,2-Dihydropyridine",
      "UNII-YA306KG67U",
      "22694-45-7",
      "YA306KG67U",
      "Pyridine, 1,2-Dihydro-",
      "AC1L8RRH",
      "CTK1A1117",
      "MMWRGWQTAMNAFC-UHFFFAOYSA-N",
      "AKOS006352484",
      "27183-EP2311806A2",
      "27183-EP2308562A2",
      "27183-EP2301911A1",
      "27183-EP2292593A2",
      "27183-EP2314575A1",
      "27183-EP2295427A1",
      "27183-EP2270010A1",
      "27183-EP2269990A1",
      "27617-EP2270006A1",
      "27183-EP2371811A2",
      "27183-EP2308510A1",
      "27183-EP2295426A1",
      "27183-EP2275404A1",
      "27617-EP2314587A1",
      "27183-EP2292611A1",
      "27183-EP2284149A1",
    ],
  },
  {
    primary_id: "D09TSB",
    names: ["N-Ethyl-2,2,2-Triphenylacetamide"],
  },
  {
    primary_id: "D09TSC",
    names: ["Ro-32-0557", "CHEMBL538718"],
  },
  {
    primary_id: "D09TSD",
    names: ["I-Vation"],
  },
  {
    primary_id: "D09TSM",
    names: ["Imidazoleisoindoles Derivative 2"],
  },
  {
    primary_id: "D09TSX",
    names: ["CEL-011"],
  },
  {
    primary_id: "D09TTI",
    names: [
      "2,4-Diamino-Butyric Acid(GABA)",
      "2,4-Diaminobutanoic Acid",
      "2,4-Diaminobutyric Acid",
      "305-62-4",
      "2,4-Diamino-Butyric Acid",
      "Alpha,gamma-Diaminobutyric Acid",
      "2,4-Diamino-Butanoic Acid",
      "DL-2,4-Diaminobutyric Acid",
      "Butanoic Acid, 2,4-Diamino-",
      "CHEBI:64307",
      "EINECS 206-166-2",
      "Butyric Acid, 2,4-Diamino-",
      "CHEMBL307931",
      "OGNSCSPNOLGXSM-UHFFFAOYSA-N",
      "Butanoic Acid, 2,4-Diamino-, (+)-",
      "Butanoic Acid, 2,4-Diamino-, Dihydrochloride",
      "6970-28-1",
      "2,4-Diaminobutyrate",
      "2,4-Diaminobutanoate",
      "NSC41117",
      "2,4-Diamino-Butanoate",
      "DL-2,4-Diaminobutanoate",
      "AC1Q28UL",
      "SCHEMBL34705",
    ],
  },
  {
    primary_id: "D09TTR",
    names: [
      "1-(3-Phenylpropyl)-1H-Imidazole",
      "CHEMBL380271",
      "1-(3-Phenylpropyl)Imidazole",
      "1H-Imidazole, 1-(3-Phenylpropyl)-",
      "SCHEMBL3160306",
      "DDGQVFKVBFAACE-UHFFFAOYSA-N",
      "BDBM50191796",
      "AKOS009137296",
    ],
  },
  {
    primary_id: "D09TUB",
    names: ["LICOAGROCHALCONE A"],
  },
  {
    primary_id: "D09TUW",
    names: ["Hepatitis C Vaccine"],
  },
  {
    primary_id: "D09TVH",
    names: ["TCH-013"],
  },
  {
    primary_id: "D09TVP",
    names: [
      "Cacodylate Ion",
      "Dimethylarsinate",
      "Arsinic Acid, Dimethyl-, Ion(1-)",
      "Bolate",
      "Bolls",
      "15132-04-4",
      "Dimethylarsonic Acid",
      "Rad-E-Cat 25",
      "Cacodylate Ions",
      "Cacodylic Acid, Free Acid",
      "Kakodylat",
      "DMAV",
      "Dimethylarsinic Acidanion",
      "AC1L4ZLM",
      "CAC",
      "Me2AsO2(-)",
      "AC1Q1WC3",
      "CHEBI:16223",
      "CTK4C7002",
      "BDBM92451",
      "DTXSID60164758",
      "OGGXGZAMXPVRFZ-UHFFFAOYSA-M",
      "Scotts Stop Weeds After They Start",
      "Scotts Spot Grass And Weed Control",
      "[As(CH3)2O2](-)",
      "AKOS032954710",
      "Arsinic Acid,dimethyl-, Ion(1-) (9CI)",
    ],
  },
  {
    primary_id: "D09TWH",
    names: ["Zinc Finger Protein Transcription Factors"],
  },
  {
    primary_id: "D09TWW",
    names: ["EP 171"],
  },
  {
    primary_id: "D09TXA",
    names: ["HelicoVax"],
  },
  {
    primary_id: "D09TXG",
    names: ["CEMADOTIN HYDROCHLORIDE"],
  },
  {
    primary_id: "D09TXO",
    names: ["Humanin"],
  },
  {
    primary_id: "D09TXU",
    names: ["DT-831j"],
  },
  {
    primary_id: "D09TYS",
    names: ["Loviride"],
  },
  {
    primary_id: "D09TYX",
    names: ["GC-501"],
  },
  {
    primary_id: "D09UAI",
    names: ["TB-402", "Anti-FVIII MAb 2E9", "MAb-LE2E9", "TGX-LE2E9"],
  },
  {
    primary_id: "D09UBE",
    names: ["Pyrrole Six-Membered Heteroaryl Ring Derivative 1"],
  },
  {
    primary_id: "D09UBS",
    names: ["Ro64-5229"],
  },
  {
    primary_id: "D09UCO",
    names: ["BMS-986046"],
  },
  {
    primary_id: "D09UCU",
    names: ["APT3111"],
  },
  {
    primary_id: "D09UCV",
    names: ["SRW-LAMP-Vax"],
  },
  {
    primary_id: "D09UDF",
    names: [
      "2-Chloro-4-(Thiophen-2-Yl)Thieno[3,2-D]pyrimidine",
      "CHEMBL253753",
      "Thieno[3,2-D]pyrimidine, 2-Chloro-4-(2-Thienyl)-",
      "SCHEMBL3682427",
      "BDBM50377508",
    ],
  },
  {
    primary_id: "D09UDL",
    names: ["LTX-315"],
  },
  {
    primary_id: "D09UFV",
    names: [
      "AZD-5423",
      "1034148-04-3",
      "UNII-641H0Q518W",
      "641H0Q518W",
      "2,2,2-Trifluoro-N-[(1R,2S)-1-[1-(4-Fluorophenyl)Indazol-5-Yl]oxy-1-(3-Methoxyphenyl)Propan-2-Yl]acetamide",
      "2,2,2-Tris(Fluoranyl)-~{n}-[(1~{r},2~{s})-1-[1-(4-Fluorophenyl)Indazol-5-Yl]oxy-1-(3-Methoxyphenyl)Propan-2-Yl]ethanamide",
      "Acetamide, 2,2,2-Trifluoro-N-[(1S,2R)-2-[[1-(4-Fluorophenyl)-1H-Indazol-5-Yl]oxy]-2-(3-Methoxyphenyl)-1-Methylethyl]-;Acetamide, 2,2,2-Trifluoro-N-[(1S,2R)-2-[[1-(4-Fluorophenyl)-1H-Indazol-5-Yl]oxy]-2-(3-Methoxyphenyl)-1-Methylethyl]-",
    ],
  },
  {
    primary_id: "D09UFZ",
    names: ["CD38 CAR T Cells"],
  },
  {
    primary_id: "D09UGG",
    names: ["Zemab"],
  },
  {
    primary_id: "D09UGP",
    names: ["RPR-106541"],
  },
  {
    primary_id: "D09UGQ",
    names: ["PMID25522065-Compound-36"],
  },
  {
    primary_id: "D09UHY",
    names: ["SMK-01/03"],
  },
  {
    primary_id: "D09UIM",
    names: ["LXR-0151"],
  },
  {
    primary_id: "D09UJB",
    names: ["PMID28350212-Compound-19"],
  },
  {
    primary_id: "D09UJD",
    names: ["D-Amphetamine Transdermal"],
  },
  {
    primary_id: "D09UKH",
    names: ["XL550"],
  },
  {
    primary_id: "D09UKS",
    names: ["MCL-183"],
  },
  {
    primary_id: "D09ULA",
    names: ["OPC 4392"],
  },
  {
    primary_id: "D09ULU",
    names: [
      "N-Ethyl-4-Hydroxy-N-Phenylbenzenesulfonamide",
      "CHEMBL379234",
      "Enamine_001944",
      "Oprea1_552384",
      "AC1M697W",
      "SCHEMBL10904389",
      "MolPort-004-000-970",
      "HMS1399I08",
      "ZINC3251814",
      "BDBM50177761",
      "AKOS008967166",
      "MCULE-9434872926",
      "N-Ethyl-4-Hydroxy-N-Phenylbenzene-1-Sulfonamide",
      "Z56821525",
    ],
  },
  {
    primary_id: "D09ULW",
    names: ["Non-Steroidal Anti-Inflammatory Drug"],
  },
  {
    primary_id: "D09UMA",
    names: ["IMAZODAN"],
  },
  {
    primary_id: "D09UMN",
    names: ["LEQ-506"],
  },
  {
    primary_id: "D09UMP",
    names: ["MN-305"],
  },
  {
    primary_id: "D09UMR",
    names: ["UV1 + GM-CSF Vaccine"],
  },
  {
    primary_id: "D09UND",
    names: ["12,17-Dehydroxyriccardin C", "Riccardin C Derivative, 20e", "CHEMBL429324", "BDBM23852"],
  },
  {
    primary_id: "D09UNF",
    names: ["PMID28766366-Compound-Scheme24Paprotrain"],
  },
  {
    primary_id: "D09UQM",
    names: [
      "1-(3,4-Dichlorophenyl)-3-Hydroxyurea",
      "N-(3,4-Dichlorophenyl)-N'-Hydroxyurea",
      "31225-17-9",
      "Dichlorphenyloxyurea",
      "Urea, N-(3,4-Dichlorophenyl)-N'-Hydroxy-",
      "Urea, 1-(3,4-Dichlorophenyl)-3-Hydroxy-",
      "EINECS 250-519-3",
      "NSC125191",
      "NSC 125191",
      "BRN 2806578",
      "AI3-63029",
      "CHEMBL213348",
      "AC1L3YKI",
      "AC1Q3OJ7",
      "SCHEMBL6791055",
      "DTXSID8067602",
      "CTK4G6577",
      "Urea,4-Dichlorophenyl)-3-Hydroxy-",
      "ZINC4809416",
      "Urea,4-Dichlorophenyl)-N'-Hydroxy-",
      "BDBM50188774",
      "AKOS024368418",
      "NSC-125191",
      "MCULE-3584918333",
      "1-(3,4-Dichlorophenyl)-3-Hydroxy-Urea",
      "LS-159754",
    ],
  },
  {
    primary_id: "D09UQS",
    names: ["LPG-1030I"],
  },
  {
    primary_id: "D09UQU",
    names: ["JNJ-19398990"],
  },
  {
    primary_id: "D09URP",
    names: ["DABIS MALEATE", "E-83-041", "1,4-Bis(2-Chloroethyl)-1,4-Diazoniabicyclo[2.2.1]heptane Dimaleate"],
  },
  {
    primary_id: "D09URT",
    names: ["OCO-1112"],
  },
  {
    primary_id: "D09USN",
    names: ["IC-485"],
  },
  {
    primary_id: "D09USO",
    names: [
      "(Biphenyl-3-Ylamino)Methylenediphosphonic Acid",
      "CHEMBL55371",
      "AC1NQQUY",
      "BPH-218",
      "NSC725581",
      "BDBM50138040",
      "ZINC13532369",
      "NSC-725581",
      "[(3-Phenylanilino)-Phosphonomethyl]phosphonic Acid",
      "[(3-Phenylanilino)-Phosphono-Methyl]phosphonic Acid",
      "[(Biphenyl-3-Ylamino)-Phosphono-Methyl]-Phosphonic Acid",
      "(Biphenyl-3-Yl)- Aminomethylene-1,1-Bisphosphonate (Dihydrate)",
      "Phosphonic Acid, [([1,1'-Biphenyl]-3-Ylamino)Methylene]bis-",
    ],
  },
  {
    primary_id: "D09UTQ",
    names: ["GC-7101"],
  },
  {
    primary_id: "D09UTR",
    names: [
      "2-Amino-6-(4-Chloro-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(4-Chlorophenylthio)Benzenecarbonitrile",
      "AC1LAE0N",
      "CHEMBL55747",
      "BDBM1740",
      "CTK7C6432",
      "ZINC5932913",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1j",
      "2-Amino-6-(4-Chlorophenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(4-Chlorophenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D09UUC",
    names: ["MK-10"],
  },
  {
    primary_id: "D09UVB",
    names: ["HTMT"],
  },
  {
    primary_id: "D09UVF",
    names: ["Bicarboxylic And Tricarboxylic Ethynyl Derivative 2"],
  },
  {
    primary_id: "D09UVK",
    names: ["MK-8835A"],
  },
  {
    primary_id: "D09UVX",
    names: ["PRX-12247"],
  },
  {
    primary_id: "D09UWI",
    names: [
      "2,5-Bis(4-Hydroxyphenyl)Thiophene",
      "CHEMBL204010",
      "SCHEMBL1181642",
      "BDBM25849",
      "ZINC28571224",
      "4,4'-(Thiophene-2,5-Diyl)Bisphenol",
      "Hydroxyphenyl Substituted Thiophene, 21",
    ],
  },
  {
    primary_id: "D09UXA",
    names: [
      "Trans-Dimethyl Gababutin",
      "ATAGABALIN",
      "223445-75-8",
      "UNII-JT7957Q2FB",
      "CHEMBL593430",
      "JT7957Q2FB",
      "PD-0200390",
      "2-[(3S,4S)-1-(Aminomethyl)-3,4-Dimethylcyclopentyl]acetic Acid",
      "Atagabalin [USAN:INN]",
      "2-((3S,4S)-1-(Aminomethyl)-3,4-Dimethylcyclopentyl)Acetic Acid",
      "Atagabalin (USAN/INN)",
      "SCHEMBL121610",
      "CTK1A0230",
      "BDBM50305887",
      "AKOS030591721",
      "DB12032",
      "AN-5147",
      "CS-0003598",
      "PD 0200390",
      "D09581",
      "((3S,4S)-1-(Aminomethyl)-3,4-Dimethylcyclopentyl)Acetic Acid",
      "3,4-Trans-2-(1-(Aminomethyl)-3,4-Dimethylcyclopentyl)Acetic Acid",
    ],
  },
  {
    primary_id: "D09UXE",
    names: ["Fosmidomycin"],
  },
  {
    primary_id: "D09UXI",
    names: ["MGN-5804"],
  },
  {
    primary_id: "D09UXO",
    names: ["[D-Dab(CO-NH-OCH3)3]degarelix", "CHEMBL411337"],
  },
  {
    primary_id: "D09UXP",
    names: [
      "AZD-1744",
      "Dual CCR3/H-1 Receptor Antagonist (Chronic Obstructive Pulmonary Disease (COPD)), AstraZeneca",
    ],
  },
  {
    primary_id: "D09UXU",
    names: ["T1-1081", "Anti-Inflammatory Ophthalmological Agent (Wet-AMD), Calvert"],
  },
  {
    primary_id: "D09UXZ",
    names: [
      "5-Phenethyl-1H-Pyrazole-3-Carboxylic Acid",
      "595610-56-3",
      "CHEMBL129385",
      "3-(2-Phenylethyl)-1H-Pyrazole-5-Carboxylic Acid",
      "1H-Pyrazole-3-Carboxylic Acid, 5-(2-Phenylethyl)-",
      "SCHEMBL1149172",
      "SCHEMBL17672395",
      "CTK7I6521",
      "DTXSID50474116",
      "MolPort-022-664-580",
      "MolPort-022-231-591",
      "BDBM50132146",
      "STL426411",
      "ZINC13526610",
      "BBL035265",
      "AKOS016013666",
      "AKOS022947847",
      "AJ-64058",
      "3-Phenethyl-1H-Pyrazole-5-Carboxylic Acid",
      "AX8249620",
      "5-(2-Phenylethyl)-1h-Pyrazole-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09UZB",
    names: ["Glucopyranoside Derivatives"],
  },
  {
    primary_id: "D09UZL",
    names: ["TAK-063"],
  },
  {
    primary_id: "D09UZO",
    names: ["Mercaptopurine"],
  },
  {
    primary_id: "D09VAB",
    names: [
      "BPR1K-0224",
      "Aurora Kinase Inhibitors (Cancer)",
      "BPR1K-0025",
      "BPR1K-0432",
      "BPR1K-0648",
      "BPR1K-0724",
      "Aurora Kinase Inhibitors (Cancer), National Health Research Institutes",
    ],
  },
  {
    primary_id: "D09VAD",
    names: ["Angiopoietin 2 MAb"],
  },
  {
    primary_id: "D09VAS",
    names: [
      "M281",
      "Diethyl Chloromalonate",
      "14064-10-9",
      "Diethyl 2-Chloromalonate",
      "Ethyl Chloromalonate",
      "Ethylmonochloromalonate",
      "Propanedioic Acid, Chloro-, Diethyl Ester",
      "Chloromalonic Acid, Diethyl Ester",
      "Diethyl Chloropropanedioate",
      "Malonic Acid, Chloro-, Diethyl Ester",
      "UNII-8Y5J45M964",
      "EINECS 237-913-0",
      "NSC 227952",
      "Diethyl 2-Chloropropanedioate",
      "WLWCQKMQYZFTDR-UHFFFAOYSA-N",
      "1,3-Diethyl 2-Chloropropanedioate",
      "Propanedioic Acid, 2-Chloro-, 1,3-Diethyl Ester",
      "8Y5J45M964",
      "Q-200972",
      "Diethylchloromalonate",
      "DIethyl-Chloromalonate",
      "ACMC-20a5gq",
      "ClCH(C",
    ],
  },
  {
    primary_id: "D09VAX",
    names: ["PMID25980951-Compound-6"],
  },
  {
    primary_id: "D09VAZ",
    names: ["TRIENTINE"],
  },
  {
    primary_id: "D09VBC",
    names: [
      "Carbetapentane",
      "Carbetapentane Citrate",
      "Pentoxyverine Citrate",
      "23142-01-0",
      "Loucarbate",
      "Pentoxiverine Citrate",
      "Carbetapentane Citrate Salt",
      "Toclase",
      "Toclase Citrate",
      "Carbetapentone Citrate",
      "Carbetapentane (Citrate)",
      "UNII-4SH0MFJ5HJ",
      "Carbetapentane Citrate [NF]",
      "EINECS 245-449-5",
      "4SH0MFJ5HJ",
      "2-(2-Diethylaminoethoxy)Ethyl 1-Phenylcyclopentanecarboxylate Citrate",
      "2-(2(Diethylamino)Ethoxy)Ethyl 1-Phenyl-Cyclopentanecarboxylate Citrate (1:1)",
      "2-(2-(Diethylamino)Ethoxy)Ethyl 1-Phenylcyclopentyl-1-Carboxylate Dihydrogen Citrate",
      "Ethanol, 2-(2-(Die",
    ],
  },
  {
    primary_id: "D09VBE",
    names: [
      "Elinogrel",
      "936500-94-6",
      "UNII-915Y8E749J",
      "915Y8E749J",
      "5-CHLORO-N-[[[4-[6-FLUORO-1,4-DIHYDRO-7-(METHYLAMINO)-2,4-DIOXO-3(2H)-QUINAZOLINYL]PHENYL]AMINO]CARBONYL]-2-THIOPHENESULFONAMIDE",
      "PRT-060128",
      "Elinogrel [USAN:INN]",
      "PRT 060128",
      "Elinogrel (USAN/INN)",
      "SCHEMBL160663",
      "CHEMBL2103828",
      "MolPort-035-941-202",
      "ZINC43153259",
      "BDBM50397204",
      "AKOS025142086",
      "NCGC00387478-01",
      "AN-26210",
      "FT-0724857",
      "D09607",
      "500C946",
      "1-(5-Chlorothiophen-2-Yl)Sulfonyl-3-[4-[6-Fluoro-7-(Methylamino)-2,4-Dioxo-1H-Quinazolin-3-Yl]phenyl]urea",
    ],
  },
  {
    primary_id: "D09VCC",
    names: ["PL-6983"],
  },
  {
    primary_id: "D09VDQ",
    names: ["Pyrimidinone Derivative 4"],
  },
  {
    primary_id: "D09VEY",
    names: ["Spirocyclic Diamine Urea Derivative 2"],
  },
  {
    primary_id: "D09VFA",
    names: ["Live Attenuated Cholera Vaccine"],
  },
  {
    primary_id: "D09VFQ",
    names: ["NSC-745794"],
  },
  {
    primary_id: "D09VFU",
    names: ["MST-400"],
  },
  {
    primary_id: "D09VFX",
    names: ["SAR342434"],
  },
  {
    primary_id: "D09VGC",
    names: ["Milnacipran"],
  },
  {
    primary_id: "D09VHA",
    names: ["BDF-9148", "BDF-9167", "BDF-9196"],
  },
  {
    primary_id: "D09VHQ",
    names: ["Gd Contrast Agents"],
  },
  {
    primary_id: "D09VHS",
    names: ["EM-101"],
  },
  {
    primary_id: "D09VHW",
    names: [
      "T-1105",
      "3-Hydroxypyrazine-2-Carboxamide",
      "55321-99-8",
      "2-Oxo-1H-Pyrazine-3-Carboxamide",
      "3-Oxo-3,4-Dihydropyrazine-2-Carboxamide",
      "Pyrazinecarboxamide, 3,4-Dihydro-3-Oxo-",
      "NSC163503",
      "SZPBAPFUXAADQV-UHFFFAOYSA-N",
      "Pyrazine-2-Carboxamide, 3-Hydroxy-",
      "T6mv Dnj Cvz[wln]",
      "3-HYDROXYPYRAZINE-2-CARBOXAMINE",
      "AC1Q6FTJ",
      "AC1L6MD7",
      "KSC273I6F",
      "SCHEMBL1338425",
      "ZINC8657",
      "3-Hydroxy-2-Pyrazinecarboxamide",
      "CTK1H3462",
      "DTXSID10203888",
      "3-Hydroxy-Pyrazine-2-Carboxamide",
      "MolPort-005-980-549",
      "3-Hydroxy-2-Pyrazinecarboxamide #",
    ],
  },
  {
    primary_id: "D09VIE",
    names: ["Isoflavone Derivative 9"],
  },
  {
    primary_id: "D09VIX",
    names: ["VRC01LS"],
  },
  {
    primary_id: "D09VJI",
    names: [
      "[3H]methyllycaconitine",
      "21019-30-7",
      "MLK",
      "2byr",
      "[(1S,2R,3R,4S,5R,6S,8R,9S,10S,13S,16S,17R,18S)-11-Ethyl-8,9-Dihydroxy-4,6,16,18-Tetramethoxy-11-Azahexacyclo[7721^{2,5}0^{1,10}0^{3,8}0^{13,17}]nonadecan-13-Yl]methyl 2-[(3S)-3-Methyl-2,5-Dioxopyrrolidin-1-Yl]benzoate",
      "GTPL3980",
      "GTPL4005",
      "ZINC24707797",
      "BRD-K43192255-048-03-6",
      "[(1beta,5alpha,6alpha,8alpha,9alpha,13alpha,14beta,16alpha)-20-Ethyl-7,8-Dihydroxy-1,6,14,16-Tetramethoxyaconitan-4-Yl]methyl",
    ],
  },
  {
    primary_id: "D09VKI",
    names: ["Hepoxilin A3"],
  },
  {
    primary_id: "D09VKP",
    names: ["C-Cure", "Autologous Stem Cell/Cardiopoietic Cell Therapy (C-Cath, Chronic Heart Failure), Mayo/Cardio3"],
  },
  {
    primary_id: "D09VLE",
    names: [
      "4,12,17-Dehydroxyriccardin C",
      "Deoxyriccardin C",
      "Riccardin C Derivative, 22",
      "CHEMBL410486",
      "BDBM23854",
    ],
  },
  {
    primary_id: "D09VLQ",
    names: ["VP-50406"],
  },
  {
    primary_id: "D09VLX",
    names: ["ISIS 18473"],
  },
  {
    primary_id: "D09VMI",
    names: ["Cediranib"],
  },
  {
    primary_id: "D09VMS",
    names: ["(-)-Clusin", "CHEMBL479701", "BDBM50259867"],
  },
  {
    primary_id: "D09VNK",
    names: ["Heterocyclic Derivative 2"],
  },
  {
    primary_id: "D09VNY",
    names: ["Neurological Therapeutics"],
  },
  {
    primary_id: "D09VOD",
    names: ["PMID27109571-Compound-12"],
  },
  {
    primary_id: "D09VOI",
    names: ["DOTA"],
  },
  {
    primary_id: "D09VOK",
    names: ["Trientine Hydrochloride"],
  },
  {
    primary_id: "D09VOP",
    names: [
      "2-(3''-(5''-Methoxy-Indolyl)Ethyloxy)Adenosine",
      "CHEMBL374028",
      "BDBM50208796",
      "2-(3''''-(5''''-Methoxy-Indolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D09VOS",
    names: ["ETI-204"],
  },
  {
    primary_id: "D09VPA",
    names: [
      "4-[5-Bromo-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 10a",
      "CHEMBL366773",
      "BDBM8620",
      "AC1O7063",
      "4-[(E)-(5-Bromo-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D09VPJ",
    names: ["2-(3,5-Difluorophenyl)-7-Methyl-1,8-Naphthyridine", "CHEMBL235838"],
  },
  {
    primary_id: "D09VPP",
    names: [
      "11-Keto-Ursolic Acid",
      "Obtusilin",
      "CHEMBL603710",
      "105870-59-5",
      "MolPort-039-339-172",
      "ZINC45385190",
      "BDBM50307108",
      "AKOS016036263",
      "3beta-Hydroxy-11-Oxours-12-En-28-Oic Acid",
    ],
  },
  {
    primary_id: "D09VQM",
    names: ["Naringin"],
  },
  {
    primary_id: "D09VQO",
    names: ["CLR-901"],
  },
  {
    primary_id: "D09VQW",
    names: ["MCTP-39"],
  },
  {
    primary_id: "D09VQZ",
    names: [
      "4-((3',4'-Difluorobiphenyl-4-Yl)Methyl)Pyridine",
      "CHEMBL1214660",
      "SCHEMBL18770342",
      "BDBM50324617",
      "ZINC58563870",
    ],
  },
  {
    primary_id: "D09VRO",
    names: ["Cyclohexyl Carbamate Derivative 6"],
  },
  {
    primary_id: "D09VRT",
    names: ["1-(1,2-Diphenylethyl)-4-Phenylpiperidin-4-Ol", "CHEMBL397945"],
  },
  {
    primary_id: "D09VRX",
    names: [
      "2-(4-Methyl-1H-Benzo[d]imidazol-2-Yl)Quinoxaline",
      "CHEMBL198779",
      "Benzimidazole-Quinoxaline, C3",
      "SCHEMBL12834347",
      "BDBM21217",
      "ZINC13678106",
    ],
  },
  {
    primary_id: "D09VRZ",
    names: ["AZM-131"],
  },
  {
    primary_id: "D09VSK",
    names: [
      "4-(1H-Indol-3-Ylmethyl)-Phenylamine",
      "Benzenamine, 4-(1H-Indol-3-Ylmethyl)-",
      "CHEMBL364248",
      "134627-70-6",
      "4-(1H-Indol-3-Ylmethyl)Aniline",
      "ACMC-20mvfl",
      "AC1N7FQI",
      "SCHEMBL4224120",
      "CTK0F4358",
      "DTXSID30401708",
      "4-[(1H-Indol-3-Yl)Methyl]aniline",
      "BDBM50164747",
    ],
  },
  {
    primary_id: "D09VSL",
    names: ["1-Adamantan-1-Yl-3-Piperidin-4-Ylmethyl-Urea", "CHEMBL216287", "SCHEMBL8925408", "BDBM50191866"],
  },
  {
    primary_id: "D09VSV",
    names: ["PH80-PMD"],
  },
  {
    primary_id: "D09VTB",
    names: ["IDX-320"],
  },
  {
    primary_id: "D09VTE",
    names: [
      "Quinolone",
      "2-Hydroxyquinoline",
      "59-31-4",
      "Quinolin-2-Ol",
      "2-Quinolinol",
      "Carbostyril",
      "2(1H)-Quinolinone",
      "2-Quinolone",
      "Quinolin-2(1H)-One",
      "Quinolinone",
      "Quinolinol",
      "Alpha-Quinolone",
      "1H-Quinolin-2-One",
      "2-Quinolinone",
      "O-Aminocinnamic Acid Lactam",
      "2(1H)-Quinolone",
      "2-OXOQUINOLINE",
      "Alpha-Hydroxyquinoline",
      "2-(1H)-QUINOLINONE",
      "104534-80-7",
      "Hydroxyquinoleine [French]",
      "UNII-803BHY7QWU",
      "70254-42-1",
      "1321-40-0",
      "1,2-Dihydroquinolin-2-One",
      ".alpha.-Quinolone",
      "Hydroquinolin-2-One",
      "NSC 156783",
      "CCRIS 4327",
      ".alpha.-Hydroxyquinoline",
      "EINECS 200-420-6",
    ],
  },
  {
    primary_id: "D09VTJ",
    names: ["Hyperforin"],
  },
  {
    primary_id: "D09VTR",
    names: ["4-(4-Tert-Butylbenzylthio)-2-Aminobutanoic Acid", "CHEMBL383441", "BDBM50179698"],
  },
  {
    primary_id: "D09VTS",
    names: ["Dermatological Agent", "Dermatological Agent (Topical, Acne Vulgaris)"],
  },
  {
    primary_id: "D09VTV",
    names: ["VPC32179"],
  },
  {
    primary_id: "D09VTY",
    names: ["FMS586"],
  },
  {
    primary_id: "D09VWI",
    names: ["BRX-005", "Arimoclomol Maleate", "BRX-220", "Bimoclomol Derivatives, Biorex"],
  },
  {
    primary_id: "D09VWK",
    names: [
      "3,3-Dimethyl-5-M-Tolyl-2,3-Dihydro-1H-Inden-1-One",
      "CHEMBL566982",
      "SCHEMBL4421269",
      "IQXQUXAACBOTRO-UHFFFAOYSA-N",
      "ZINC45245855",
      "BDBM50299884",
    ],
  },
  {
    primary_id: "D09VXA",
    names: ["Quinoxaline Derivative 1"],
  },
  {
    primary_id: "D09VXM",
    names: ["Crofelemer"],
  },
  {
    primary_id: "D09VYB",
    names: ["UBP-302"],
  },
  {
    primary_id: "D09VYF",
    names: ["RDEN1delta30"],
  },
  {
    primary_id: "D09VYS",
    names: ["BXL-1H5"],
  },
  {
    primary_id: "D09VYT",
    names: ["CD19-Targeted CAR-T Cells"],
  },
  {
    primary_id: "D09VYW",
    names: [
      "11-Keto-Beta-Boswellicacid",
      "11-Keto-Beta-Boswellic Acid",
      "UNII-0S3BIF6H0Q",
      "11-Keto Boswellic Acid",
      "0S3BIF6H0Q",
      "17019-92-0",
      "CHEMBL437964",
      "11-Oxo-Beta-Boswellic Acid",
      "(4R)-3alpha-Hydroxy-11-Oxo-Urs-12-En-23-Oic Acid",
      "Keto-B-Boswellic Acid",
      "3alpha-Hydroxy-11-Oxours-12-En-24-Oic Acid",
      "11-Oxo-",
      "11-Keto-",
      "BETA-BOSWELLIC ACID,11-KETO",
      "A-Hydroxy-11-Oxours-12-En-24-Oic Acid",
      "11-Oxo-B-Boswellic Acid",
      "11-Keto-B-Boswellic Acid",
      "SCHEMBL4388405",
      "(3alpha,4beta)-3-Hydroxy-11-Oxours-12-En-23-Oic Acid",
    ],
  },
  {
    primary_id: "D09VZR",
    names: ["SGI-1252"],
  },
  {
    primary_id: "D09WAY",
    names: ["OPN-501", "Anti-Inflammatory (IBD, Oral), Opsona"],
  },
  {
    primary_id: "D09WBJ",
    names: ["FAUC213"],
  },
  {
    primary_id: "D09WBM",
    names: ["GC-14"],
  },
  {
    primary_id: "D09WBQ",
    names: ["DSM2"],
  },
  {
    primary_id: "D09WCC",
    names: ["MP-0270"],
  },
  {
    primary_id: "D09WCE",
    names: [
      "IS-159",
      "133790-13-3",
      "2-(3-(2-Aminoethyl)-1h-Indol-5-Yloxy)Acetyl-L-Tyrosyl-Glycinamide",
      "2-[3-(2-Aminoethyl)-1h-Indol-5-Yloxy]acetyl-L-Tyrosyl-Glycinamide",
      "SCHEMBL6905927",
      "AC1L52G2",
      "CTK4B8797",
      "Glycinamide,N-[[[3-(2-Aminoethyl)-1H-Indol-5-Yl]oxy]acetyl]-L-Tyrosyl- (9CI)",
      "AKOS030608101",
    ],
  },
  {
    primary_id: "D09WCM",
    names: ["Spinosad"],
  },
  {
    primary_id: "D09WCN",
    names: ["Intetumumab"],
  },
  {
    primary_id: "D09WDK",
    names: ["Tbo-Filgrastim"],
  },
  {
    primary_id: "D09WDO",
    names: ["BNZ-1"],
  },
  {
    primary_id: "D09WDS",
    names: ["MRNA-2416"],
  },
  {
    primary_id: "D09WED",
    names: ["Kuwanon R", "CHEMBL570526"],
  },
  {
    primary_id: "D09WEJ",
    names: [
      "Bromopride",
      "Bromopride Hydrochloride",
      "UNII-B7C7WX94Z6",
      "B7C7WX94Z6",
      "52423-56-0",
      "4-Amino-5-Bromo-N-(2-Diethylaminoethyl)-2-Methoxybenzamide Hydrochloride",
      "Cascapride",
      "Opridan",
      "Bromopride HCl",
      "Bromoprid Hydrochlorid",
      "EINECS 257-906-6",
      "AC1MI3LY",
      "SCHEMBL8385045",
      "CTK8J0119",
      "AKOS027380919",
      "KB-289931",
      "4-Amino-5-Bromo-N-(2-(Diethylamino)Ethyl)-2-Methoxybenzamide Monohydrochloride",
    ],
  },
  {
    primary_id: "D09WEK",
    names: ["4-(1-Benzyl-7-Chloro-1H-Indazol-3-Yl)Phenol"],
  },
  {
    primary_id: "D09WFG",
    names: ["Alpha-Acarviosinyl-(1"],
  },
  {
    primary_id: "D09WFI",
    names: ["Tetramethylpyrazine"],
  },
  {
    primary_id: "D09WFK",
    names: ["Pyrazole Derivative 75"],
  },
  {
    primary_id: "D09WFL",
    names: ["MP-12"],
  },
  {
    primary_id: "D09WGH",
    names: ["Ropidoxuridine"],
  },
  {
    primary_id: "D09WGU",
    names: ["PEG-VM-501"],
  },
  {
    primary_id: "D09WHM",
    names: ["DU-29894"],
  },
  {
    primary_id: "D09WIL",
    names: [
      "SB-656104",
      "CHEMBL95104",
      "CHEMBL538029",
      "SCHEMBL6810550",
      "GTPL3237",
      "BDBM50130261",
      "SB656104",
      "6-[(2R)-2-[2-[4-(4-Chlorophenoxy)Piperidin-1-Yl]ethyl]pyrrolidin-1-Yl]sulfonyl-1H-Indole",
      "6-(2-{2-[4-(4-Chloro-Phenoxy)-Piperidin-1-Yl]-Ethyl}-Pyrrolidine-1-Sulfonyl)-1H-Indole",
    ],
  },
  {
    primary_id: "D09WIM",
    names: ["AviFluCide-I"],
  },
  {
    primary_id: "D09WIR",
    names: ["CC-90009"],
  },
  {
    primary_id: "D09WIW",
    names: ["ISIS 32008"],
  },
  {
    primary_id: "D09WJO",
    names: [
      "4-Amino-3-Thiophen-2-Yl-Butyric Acid",
      "4-Amino-3-(2-Thienyl)Butanoic Acid",
      "133933-76-3",
      "CHEMBL315476",
      "4-Amino-3-(Thien-2-Yl)Butanoic Acid",
      "2-Thiophenepropanoicacid, B-(Aminomethyl)-",
      "98593-59-0",
      "2-BTG",
      "4-Amino-3-Thiophen-2-Ylbutanoic Acid",
      "Beta-(2-Thienyl)-Gamma-Aminobutyric Acid",
      "ACMC-1C0HP",
      "AC1L2ZM2",
      "SCHEMBL341042",
      "CTK4B8884",
      "BDBM50007117",
      "4-Amino-3-(2-Thienyl)Butyric Acid",
      "4-Amino-3-(2-Thienyl) Butyric Acid",
      "AKOS014313933",
    ],
  },
  {
    primary_id: "D09WJT",
    names: ["Toleragens"],
  },
  {
    primary_id: "D09WKB",
    names: [
      "Anisindione",
      "Anisindiona",
      "Anisindionum",
      "Miradon",
      "Unidone",
      "Anisin Indandione",
      "SPE 2792",
      "Anisindiona [INN-Spanish]",
      "Anisindione (INN)",
      "Anisindione [INN:BAN]",
      "Anisindionum [INN-Latin]",
      "Miradon (TN)",
      "2-(4-Methoxy-Phenyl)-Indan-1,3-Dione",
      "2-(4-Methoxyphenyl)-1H-Indene-1,3(2H)-Dione",
      "2-(4-Methoxyphenyl)Indan-1,3-Dione",
      "2-(4-Methoxyphenyl)Indene-1,3-Dione",
      "2-(P-Methoxyphenyl)-1,3-Indandione",
      "2-(P-Methoxyphenyl)Indane-1,3-Dione",
      "2-[4-(Methyloxy)Phenyl]-1H-Indene-1,3(2H)-Dione",
      "2-P-Anisyl-1,3-Indandione",
      "2-Para-Anisyl-1,3-Indandione",
    ],
  },
  {
    primary_id: "D09WKF",
    names: ["PMID25656651-Compound-36d"],
  },
  {
    primary_id: "D09WKH",
    names: ["Zosyn/Tazocin"],
  },
  {
    primary_id: "D09WKM",
    names: ["BI-836826"],
  },
  {
    primary_id: "D09WKT",
    names: ["ARD-412"],
  },
  {
    primary_id: "D09WMV",
    names: ["BMS-753951"],
  },
  {
    primary_id: "D09WND",
    names: [
      "TETRAPLATIN TETRANITRATE",
      "BBR-3464",
      "Tetraplatin Tetranitrate",
      "Hexaamminedichlorobis[mu-(1,6-Hexanediamine-KappaN:kappaN')]triplatinum(4+) Tetranitrate",
    ],
  },
  {
    primary_id: "D09WNK",
    names: [
      "Fenfluramine",
      "Acino",
      "Adifax",
      "Adipomin",
      "Fenfluramina",
      "Fenfluraminum",
      "Isomeride",
      "Obedrex",
      "Pesos",
      "Ponderex",
      "Rotondin",
      "Dexfenfluramina [Spanish]",
      "Dexfenfluraminum [Latin]",
      "Fenfluramina [DCIT]",
      "Fenfluramine Hydrochloride",
      "Levofenfluramine [INN]",
      "Ponderax PA",
      "S 768",
      "D-Fenfluramine",
      "Dexfenfluramine [INN:BAN]",
      "Fenfluramine (INN)",
      "Fenfluramine [INN:BAN]",
      "Fenfluraminum [INN-Latin]",
      "Ponderax (TN)",
      "Fenfluramine, (+-)-Isomer",
      "N-Ethyl-Alpha-Methyl-3-Trifluoromethylphenethylamine",
      "D-N-Ethyl-Alpha-Methyl-M-Trifluoromethylphenethylamine",
      "N-Ethyl-Alpha-Methyl-3-(Trifluoromethyl)Benzeneethanamine",
      "N-Ethyl-Alpha-Methyl-M-(Trifluoromethyl)Phenethylamine",
      "N-Ethyl-1-[3-(Trifluoromethyl)Phenyl]propan-2-Amine",
      "Benzeneethanamine, N-Ethyl-Alpha-Methyl-3-(Trifluoromethyl)-(9CI)",
      "Benzeneethanamine, N-Ethyl-Alpha-Methyl-3-(Trifluoromethyl)-, (S)-(9CI);Benzeneethanamine, N-Ethyl-Alpha-Methyl-3-(Trifluoromethyl)-, (+-)-(9CI)",
      "(+)-Fenfluramine",
      "(+-)-Fenfluramine",
      "(+/-)-Fenfluramine Hydrochloride",
      "(S)-Fenfluramine",
      "1-(M-Trifluoromethyl-Phenyl)-2-Ethylaminopropane",
      "1-(Meta-Trifluoromethyl-Phenyl)-2 Ethylaminopropane",
      "2-Ethylamino-1-(3-Trifluoromethylphenyl)Propane",
      "3-(Trifluoromethyl)-N-Ethyl-Alpha-Methylphenethylamine",
    ],
  },
  {
    primary_id: "D09WNM",
    names: ["PASBN"],
  },
  {
    primary_id: "D09WNQ",
    names: ["NCTX"],
  },
  {
    primary_id: "D09WOI",
    names: ["NZ-39000"],
  },
  {
    primary_id: "D09WOX",
    names: ["2-Amino-6-(Naphthalene-1-Sulfonyl)-Benzonitrile", "CHEMBL297956", "BDBM50422688", "ZINC12354034"],
  },
  {
    primary_id: "D09WOY",
    names: ["SB-559457"],
  },
  {
    primary_id: "D09WPA",
    names: ["Phytocannabinoid/Aminoalkylindole Derivative 2"],
  },
  {
    primary_id: "D09WPI",
    names: ["GOVX-B11"],
  },
  {
    primary_id: "D09WPN",
    names: ["AP22408"],
  },
  {
    primary_id: "D09WPX",
    names: [
      "VU0366058",
      "CHEMBL3759200",
      "1369344-82-0",
      "2-(Benzooxazol-2-Ylamino)-4-(4-Fluorophenyl)Pyrimidine-5-Carbonitrile",
      "GTPL6438",
      "BDBM50143543",
      "ZINC200480443",
      "KB-267312",
    ],
  },
  {
    primary_id: "D09WQL",
    names: ["Immethridine"],
  },
  {
    primary_id: "D09WQW",
    names: [
      "6-{[(CYCLOHEXYLAMINO)CARBONYL]AMINO}HEXANOIC ACID",
      "6-[(Cyclohexylcarbamoyl)Amino]hexanoic Acid",
      "CHEMBL434374",
      "6-(3-Cyclohexylureido)Hexanoic Acid",
      "4-(3-CYCLOHEXYLURIEDO)-HEXANOIC ACID",
      "NC6",
      "479413-50-8",
      "1zd4",
      "AC1O4WJZ",
      "MolPort-004-305-491",
      "HMS3604D15",
      "ZINC13493677",
      "BDBM50192966",
      "AKOS000144586",
      "DB08258",
      "NE37451",
      "MCULE-4614457618",
      "6-(Cyclohexylcarbamoylamino)Hexanoic Acid",
      "EN300-77810",
    ],
  },
  {
    primary_id: "D09WRG",
    names: ["CEP-41750"],
  },
  {
    primary_id: "D09WRI",
    names: ["ELINAFIDE MESILATE"],
  },
  {
    primary_id: "D09WRS",
    names: ["Ac-WVTH[Cit]LAGLLSRSGGVVRKNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D09WRT",
    names: ["PMID26004420-Compound-WO2013039802A"],
  },
  {
    primary_id: "D09WSG",
    names: ["Salvinorin B Benzyloxymethyl Ether", "CHEMBL271061"],
  },
  {
    primary_id: "D09WSJ",
    names: [
      "Decyl Sulfamate",
      "CHEMBL153094",
      "Sulfamic Acid, Decyl Ester",
      "N-Decyl Sulfamate",
      "3ibu",
      "Aliphatic Sulfamate, 2",
      "BDBM33280",
      "475562-13-1",
    ],
  },
  {
    primary_id: "D09WSR",
    names: ["PCA062"],
  },
  {
    primary_id: "D09WSW",
    names: ["BTK Inhibitor"],
  },
  {
    primary_id: "D09WTI",
    names: ["Pyrimidinyl Compound 7"],
  },
  {
    primary_id: "D09WTQ",
    names: [
      "RC-8800",
      "24-Hydroxylase Inhibitor (Prostate Cancer), Rejuvenon",
      "24-Hydroxylase Inhibitor (Prostate Cancer), Sapphire",
    ],
  },
  {
    primary_id: "D09WUA",
    names: ["7-Azaindole Derivative 8"],
  },
  {
    primary_id: "D09WUL",
    names: ["ISIS 116414"],
  },
  {
    primary_id: "D09WUP",
    names: [
      "3,7-Bis(2-Hydroxyethyl)Icaritin",
      "UNII-5U58D99D1L",
      "CHEMBL498486",
      "5U58D99D1L",
      "SCHEMBL18533069",
      "BDBM50272529",
      "3,7-Bis(2-Hydroxyethoxy)-5-Hydroxy-4'-Methoxy-8-(3-Methyl-2-Butenyl)Flavone",
      "1067198-74-6",
      "4H-1-Benzopyran-4-One, 5-Hydroxy-3,7-Bis(2-Hydroxyethoxy)-2-(4-Methoxyphenyl)-8-(3-Methyl-2-Buten-1-Yl)-",
    ],
  },
  {
    primary_id: "D09WUW",
    names: ["PMID29865878-Compound-43"],
  },
  {
    primary_id: "D09WUY",
    names: ["TP-104"],
  },
  {
    primary_id: "D09WVP",
    names: ["N-Benzyl-ETAV"],
  },
  {
    primary_id: "D09WVR",
    names: ["FX-107"],
  },
  {
    primary_id: "D09WVS",
    names: ["10R-Hydroxylobelane", "CHEMBL213148"],
  },
  {
    primary_id: "D09WVV",
    names: [
      "N1-(2-Aminoethyl)Ethane-1,2-Diamine",
      "DIETHYLENETRIAMINE",
      "111-40-0",
      "Bis(2-Aminoethyl)Amine",
      "2,2'-Diaminodiethylamine",
      "Diethylene Triamine",
      "Barsamide 115",
      "Epicure T",
      "Ancamine DETA",
      "1,4,7-Triazaheptane",
      "2,2'-Iminodiethylamine",
      "N,N-Bis(2-Aminoethyl)Amine",
      "Aminoethylethandiamine",
      "3-Azapentane-1,5-Diamine",
      "1,2-Ethanediamine, N-(2-Aminoethyl)-",
      "Imino-Bis-Ethylamine",
      "ChS-P 1",
      "N-(2-Aminoethyl)-1,2-Ethanediamine",
      "N-(2-Aminoethyl)Ethane-1,2-Diamine",
      "1,5-Diamino-3-Azapentane",
      "2,2'-Iminobis(Ethanamine)",
      "Bis(Beta-Aminoethyl)Amine",
      "2-(2-Aminoethylamino)Ethylamine",
      "Epon 3223",
    ],
  },
  {
    primary_id: "D09WWB",
    names: ["MenABCWY"],
  },
  {
    primary_id: "D09WWY",
    names: ["RU28318"],
  },
  {
    primary_id: "D09WXH",
    names: ["PMID25435285-Compound-25"],
  },
  {
    primary_id: "D09WXY",
    names: ["CDX-014"],
  },
  {
    primary_id: "D09WYC",
    names: [
      "Y-26611",
      "7-[2-(Aminomethyl)-4-Morpholinyl]-1-Cyclopropyl-6,8-Difluoro-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09WYE",
    names: ["NST-001"],
  },
  {
    primary_id: "D09WYF",
    names: ["2-(4-Pentylphenyl)-N-(Pyridin-3-Yl)Acetamide", "CHEMBL260464"],
  },
  {
    primary_id: "D09WYL",
    names: ["GSK-557296"],
  },
  {
    primary_id: "D09WYM",
    names: [
      "3-Biphenyl-3-Yl-N-Hydroxy-N-Methyl-Acrylamide",
      "CHEMBL177549",
      "SCHEMBL10876301",
      "BDBM50015161",
      "(E)-3-[3-Phenylphenyl]-N-Methyl-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D09WYX",
    names: [
      "Deflazacort",
      "C25H33NO6",
      "Deflazacort (Calcort)",
      "Deflazacortum [INN-Latin]",
      "EINECS 238-483-7",
      "Deflan (TN)",
      "D09WYX",
      "AC1L1BQ2",
      "Deflazacort [USAN:BAN:INN]",
      "CTK8F9001",
      "MolPort-035-394-927",
    ],
  },
  {
    primary_id: "D09WYY",
    names: ["2'-Deoxythymidine Triphosphate"],
  },
  {
    primary_id: "D09WZG",
    names: ["Anascorp", "Alacramyn", "Alacramyn NAMO", "Scorpion Antivenom, Rare Disease Therapeutics/ Bioclon"],
  },
  {
    primary_id: "D09WZU",
    names: ["BCI-838"],
  },
  {
    primary_id: "D09WZV",
    names: ["GMA-161"],
  },
  {
    primary_id: "D09XAM",
    names: ["GTPL8662", "43574"],
  },
  {
    primary_id: "D09XCH",
    names: ["Ac-YR[CEHFRWC]-NH2", "CHEMBL410672"],
  },
  {
    primary_id: "D09XCX",
    names: ["PIRINIXIC ACID"],
  },
  {
    primary_id: "D09XDR",
    names: ["Granulosin"],
  },
  {
    primary_id: "D09XED",
    names: ["MK-0668"],
  },
  {
    primary_id: "D09XEM",
    names: ["NGN-9080"],
  },
  {
    primary_id: "D09XFO",
    names: ["Semuloparin"],
  },
  {
    primary_id: "D09XFQ",
    names: ["KNI-10125"],
  },
  {
    primary_id: "D09XGB",
    names: ["VER 50589"],
  },
  {
    primary_id: "D09XGK",
    names: ["Testetrol"],
  },
  {
    primary_id: "D09XHA",
    names: [
      "Nomega-Hydroxyarginine",
      "CHEBI:7101",
      "N-(O)-Hydroxyarginine",
      "Nomega-Hydroxyl-L-Arginine",
      "AC1L9A1Y",
      "GTPL5227",
      "SCHEMBL2509691",
      "CHEMBL323186",
      "CHEBI:47825",
      "CHEBI:47827",
      "CHEBI:47826",
      "N(5)-(N-Hydroxycarbamimidoyl)Ornithine",
      "N(5)-[amino(Hydroxyimino)Methyl]ornithine",
      "N(5)-[(Hydroxyamino)(Imino)Methyl]ornithine",
      "5-(2-Hydroxyguanidino)-2-Aminovaleric Acid",
      "N(5)-[(Z)-Amino(Hydroxyimino)Methyl]ornithine",
      "N(5)-[(E)-Amino(Hydroxyimino)Methyl]ornithine",
      "2-Amino-5-{[amino(Hydroxyamino)Methylidene]amino}pentanoic Ac",
    ],
  },
  {
    primary_id: "D09XHI",
    names: [
      "AZD-6538",
      "CHEMBL2164552",
      "453566-30-8",
      "SCHEMBL4186520",
      "GTPL6440",
      "AZD6538",
      "AZD 6538",
      "BDBM50395922",
      "6-(5-(3-Cyano-5-Fluorophenyl)-1,2,4-Oxadiazol-3-Yl)Nicotinonitrile",
    ],
  },
  {
    primary_id: "D09XHQ",
    names: ["TA-CD"],
  },
  {
    primary_id: "D09XHU",
    names: ["HER-2 Protein AutoVac"],
  },
  {
    primary_id: "D09XIE",
    names: ["3-(4-Hydroxy-Phenyl)-2,3-Diphenyl-Acrylonitrile", "CHEMBL303801"],
  },
  {
    primary_id: "D09XIL",
    names: ["Ro-4396686"],
  },
  {
    primary_id: "D09XIQ",
    names: ["ISIS 188761"],
  },
  {
    primary_id: "D09XIZ",
    names: [
      "JNJ-26481585",
      "875320-29-9",
      "N-Hydroxy-2-(4-((((1-Methyl-1H-Indol-3-Yl)Methyl)Amino)Methyl)Piperidin-1-Yl)Pyrimidine-5-Carboxamide",
      "UNII-9BJ85K1J8S",
      "Quisinostat (JNJ-26481585)",
      "JNJ26481585",
      "9BJ85K1J8S",
      "N-Hydroxy-2-[4-[[(1-Methylindol-3-Yl)Methylamino]methyl]piperidin-1-Yl]pyrimidine-5-Carboxamide",
      "2-[4-[[(1-Methylindol-3-Yl)Methylamino]methyl]piperidin-1-Yl]-~{N}-Oxidanyl-Pyrimidine-5-Carboxamide",
      "N-Hydroxy-2-(4-((((1-Methyl-1H-Indol-3-Yl)Methyl)Amino)-Methyl)Piperidin-1-Yl)Pyrimidine-5-Carboxamide",
    ],
  },
  {
    primary_id: "D09XJF",
    names: ["EPI 589", "(R)-Troloxamide Quinone", "EPI-589"],
  },
  {
    primary_id: "D09XJH",
    names: ["Ezetimibe-Glucuronide"],
  },
  {
    primary_id: "D09XJJ",
    names: [
      "N-Bis-(3-Phenyl-Propyl)9-Oxo-Fluorene-2,7-Diamide",
      "9H-Fluorene-2,7-Dicarboxamide, 9-Oxo-N,N'-Bis(3-Phenylpropyl)-",
      "443794-40-9",
      "SCHEMBL4158879",
      "CTK1C7933",
    ],
  },
  {
    primary_id: "D09XKC",
    names: ["PT-113"],
  },
  {
    primary_id: "D09XKH",
    names: ["(2S)-5,7,2',4'-Tetrahydroxyflavanone"],
  },
  {
    primary_id: "D09XKO",
    names: ["KD-101"],
  },
  {
    primary_id: "D09XKT",
    names: ["KI-1003"],
  },
  {
    primary_id: "D09XKW",
    names: [
      "Castor Oil",
      "CASTOR OIL",
      "Ricinus Oil",
      "8001-79-4",
      "Olio Di Ricino",
      "Xenaderm",
      "Venelex",
      "Castor Oil, Specified According To The Requirements Of Ph.Eur.",
      "Optase",
      "Trypsin Complex",
      "UNII-D5340Y2I9G",
      "Castor Oil [USP:JAN]",
      "SCHEMBL12939325",
      "D5340Y2I9G",
      "1-O,2-O,3-O-Tris[(Z)-12-Hydroxy-9-Octadecenoyl]glycerol",
      "89958-32-7",
      "8013-56-7",
      "8041-95-0",
      "8041-22-3",
      "151438-72-1",
      "898831-11-3",
      "8036/8/6",
      "8015-57-4",
    ],
  },
  {
    primary_id: "D09XLS",
    names: [
      "FR139317",
      "D-Alanine, N-((Hexahydro-1H-Azepin-1-Yl)Carbonyl)-L-Leucyl-1-Methyl-D-Tryptophyl-3-(2-Pyridinyl)-",
      "FR 139317",
      "142375-60-8",
      "CHEMBL352396",
      "2-((1-(Hexahydro-1H-Azepinyl)Carbonyl)Amino-4-Methylpentanoyl)-3-(-(1-Methyl-1H-Indolyl)Propionyl)Amino-3-(2-Pyridyl)Propionic Acid",
      "D-Alanine, N-(N-(N-((Hexahydro-1H-Azepin-1-Yl)Carbonyl)-L-Leucyl)-1-Methyl-D-Tryptophyl)-3-(2-Pyridinyl)-",
      "GSK424887",
    ],
  },
  {
    primary_id: "D09XLT",
    names: ["SQ 26655"],
  },
  {
    primary_id: "D09XLY",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 31"],
  },
  {
    primary_id: "D09XLZ",
    names: ["4-[4-(Benzoylamino)Benzoyl]benzoic Acid"],
  },
  {
    primary_id: "D09XMP",
    names: ["SC-114"],
  },
  {
    primary_id: "D09XMX",
    names: ["SaxaDapa FDC"],
  },
  {
    primary_id: "D09XNZ",
    names: ["CT-2408R"],
  },
  {
    primary_id: "D09XOC",
    names: [
      "3-Bromo-1-(4-Hexylphenyl)Propan-1-One",
      "Beta-Bromo Ketone, 8e",
      "CHEMBL395602",
      "BDBM18857",
      "ZINC14969063",
    ],
  },
  {
    primary_id: "D09XOF",
    names: [
      "NY-ESO-1 Vaccine",
      "Cancer Vaccine (Peptides), Ludwig Institute",
      "NY-ESO-1b",
      "NY-ESO-1 OLP-4",
      "NY-ESO-1 Peptide Immunotherapy, Ludwig Institute",
      "NY-ESO-1 Vaccine (Peptides)",
      "NY-ESO-1 DP4 Peptide (157-170)",
      "NY-ESO-1 Vaccine (Peptides), Ludwig Institute",
    ],
  },
  {
    primary_id: "D09XOR",
    names: ["Palladium Pd 103"],
  },
  {
    primary_id: "D09XPD",
    names: [
      "GivaRex",
      "AIT (Gastrointestinal Cancer), AltaRex",
      "AIT (Gastrointestinal Cancer), ViRexx",
      "AR44.6 (Anticancer MAb)",
      "Anti-Idiotype Induction Therapy (GI Cancer), AltaRex",
      "Anti-Idiotype Induction Therapy (GI Cancer), Paladin",
      "Anti-Idiotype Induction Therapy (GI Cancer), United",
      "Anti-Idiotype Induction Therapy (GI Cancer), ViRexx",
    ],
  },
  {
    primary_id: "D09XPJ",
    names: ["Micacocidin A"],
  },
  {
    primary_id: "D09XQF",
    names: [
      "Isoniazid",
      "Abdizide",
      "Andrazide",
      "Anidrasona",
      "Antimicina",
      "Antituberkulosum",
      "Armacide",
      "Armazid",
      "Armazide",
      "Atcotibine",
      "Azuren",
      "Bacillen",
      "Bacillin",
      "Cedin",
      "Cemidon",
      "Chemiazid",
      "Chemidon",
      "Continazine",
      "Cortinazine",
      "Cotinazin",
      "Cotinizin",
      "Defonin",
      "Dibutin",
      "Diforin",
      "Dinacrin",
      "Ditubin",
      "Ebidene",
      "Eralon",
      "Ertuban",
      "Eutizon",
      "Evalon",
      "Fetefu",
      "Fimalene",
      "HIA",
      "Hidranizil",
      "Hidrasonil",
      "Hidrulta",
      "Hidrun",
      "Hycozid",
      "Hydra",
      "Hydrazid",
      "Hydrazide",
      "Hyozid",
      "Hyzyd",
      "INH",
      "Idrazil",
      "Inah",
      "Inizid",
      "Iscotin",
      "Isidrina",
      "Ismazide",
      "Isobicina",
      "Isocid",
      "Isocidene",
      "Isocotin",
      "Isohydrazide",
      "Isokin",
      "Isolyn",
      "Isonerit",
      "Isonex",
      "Isoniacid",
      "Isoniazida",
      "Isoniazide",
      "Isoniazidum",
      "Isonicazide",
      "Isonicid",
      "Isonico",
      "Isonicotan",
      "Isonicotil",
      "Isonicotinhydrazid",
      "Isonicotinohydrazide",
      "Isonide",
      "Isonidrin",
      "Isonikazid",
      "Isonilex",
      "Isonin",
      "Isonindon",
      "Isonirit",
      "Isoniton",
      "Isonizida",
      "Isonizide",
      "Isotamine",
      "Isotebe",
      "Isotebezid",
      "Isotinyl",
      "Isozid",
      "Isozide",
      "Isozyd",
      "LANIZID",
      "Laniazid",
      "Laniozid",
      "Mybasan",
      "Neoteben",
      "Neoxin",
      "Neumandin",
      "Nevin",
      "Niadrin",
      "Nicazide",
      "Nicetal",
      "Nicizina",
      "Niconyl",
      "Nicotibina",
      "Nicotibine",
      "Nicotisan",
      "Nicozide",
      "Nidaton",
      "Nidrazid",
      "Nikozid",
      "Niplen",
      "Nitadon",
      "Niteban",
      "Nydrazid",
      "Nyscozid",
      "Pelazid",
      "Percin",
      "Phthisen",
      "Pycazide",
      "Pyreazid",
      "Pyricidin",
      "Pyridicin",
      "Pyrizidin",
      "Raumanon",
      "Razide",
      "Retozide",
      "Rimicid",
      "Rimifon",
      "Rimiphone",
      "Rimitsid",
      "Robiselin",
      "Robisellin",
      "Roxifen",
      "Sanohidrazina",
      "Sauterazid",
      "Sauterzid",
      "Stanozide",
      "Tebecid",
      "Tebemid",
      "Tebenic",
      "Tebexin",
      "Tebilon",
      "Tebos",
      "Teebaconin",
      "Tekazin",
      "Tibazide",
      "Tibemid",
      "Tibiazide",
      "Tibinide",
      "Tibison",
      "Tibivis",
      "Tibizide",
      "Tibusan",
      "Tisin",
      "Tisiodrazida",
      "Tizide",
      "Tubazid",
      "Tubazide",
      "Tubeco",
      "Tubecotubercid",
      "Tubercid",
      "Tuberian",
      "Tubicon",
      "Tubilysin",
      "Tubizid",
      "Tubomel",
      "Tyvid",
      "Unicocyde",
      "Unicozyde",
      "Vazadrine",
      "Vederon",
      "Zidafimia",
      "Zinadon",
      "Zonazide",
      "Hid Rasonil",
      "Isoco Tin",
      "Isoniazid SA",
      "Isozid E",
      "Nidra Zid",
      "Rimif On",
      "BP 5015",
      "Bp 5 015",
      "FSR 3",
      "I0138",
      "INHd20",
      "L 1945",
      "Nitebannsc 9659",
      "Preparation 6424",
      "RP 5015",
      "AZT + Isoniazid",
      "Cedin (Aerosol)",
      "Dow-Isoniazid",
      "FRS-3",
      "FSR-3",
      "Ido-Tebin",
      "In-73",
      "Inh-Burgthal",
      "Isoniazid & EEP",
      "Isoniazid & Propolis",
      "Laniazid (TN)",
      "Neo-Tizide",
      "Nydrazid (TN)",
      "RP-5015",
      "TB-Phlogin",
      "TB-Razide",
      "TB-Vis",
      "Usaf Cb-2",
      "I.A.I",
      "RU-EF-Tb",
      "RY-EF-Tb",
      "I.A.I.",
    ],
  },
  {
    primary_id: "D09XQR",
    names: ["Erythromycin Salnacedin", "Actagen", "G 101"],
  },
  {
    primary_id: "D09XQU",
    names: ["Tirapazamine"],
  },
  {
    primary_id: "D09XRD",
    names: ["Comvax"],
  },
  {
    primary_id: "D09XRQ",
    names: ["HCBE-11"],
  },
  {
    primary_id: "D09XSB",
    names: ["(E)-5-Styrylisatin", "CHEMBL466872"],
  },
  {
    primary_id: "D09XSH",
    names: ["Cytoreg", "Anticancer Therapy, Cytorex"],
  },
  {
    primary_id: "D09XSI",
    names: ["9-O-[5-(Phenylol-1-Yloxy)Pentyl]berberine Bromide", "CHEMBL598528"],
  },
  {
    primary_id: "D09XSX",
    names: ["KPT-XXX"],
  },
  {
    primary_id: "D09XTN",
    names: ["Emicizumab"],
  },
  {
    primary_id: "D09XUB",
    names: ["Viramidine"],
  },
  {
    primary_id: "D09XUH",
    names: ["GASTRAZOLE"],
  },
  {
    primary_id: "D09XUI",
    names: ["1,5-Diphenylpyrrolidin-2-One Derivative 2"],
  },
  {
    primary_id: "D09XUJ",
    names: ["PMID28870136-Compound-59"],
  },
  {
    primary_id: "D09XUQ",
    names: ["3-(4-Methoxy-Benzenesulfonyl)-Hexane-1-Thiol", "CHEMBL175113", "SCHEMBL7212134"],
  },
  {
    primary_id: "D09XUY",
    names: ["Icofungipen"],
  },
  {
    primary_id: "D09XVQ",
    names: ["PT-14"],
  },
  {
    primary_id: "D09XVS",
    names: ["Tc-99m Tilmanocept"],
  },
  {
    primary_id: "D09XVU",
    names: ["NSC-306843"],
  },
  {
    primary_id: "D09XVY",
    names: ["N-(4-Methyl-Benzyl)-4-Phenoxy-Benzamide", "CHEMBL199174", "AC1OVMY0", "BDBM50174096"],
  },
  {
    primary_id: "D09XWA",
    names: ["D[Cha4,Dap8]VP", "CHEMBL375187"],
  },
  {
    primary_id: "D09XWD",
    names: ["Teprenone"],
  },
  {
    primary_id: "D09XWL",
    names: ["2-(N-Cyclopentylamino)-3'-Fluoropropiophenone", "CHEMBL605501", "BDBM50308112"],
  },
  {
    primary_id: "D09XWR",
    names: ["PMID27744724-Compound-26"],
  },
  {
    primary_id: "D09XWS",
    names: ["1-(4-Methoxyphenyl)-3-(1,3,4-Thiadiazol-2-Yl)Urea", "CHEMBL389884", "ZINC28225822"],
  },
  {
    primary_id: "D09XWT",
    names: ["AZP-03"],
  },
  {
    primary_id: "D09XXH",
    names: ["VCH-286"],
  },
  {
    primary_id: "D09XXN",
    names: [
      "2-Hydroxy-N-(3-Phenyl-Propyl)-Benzamide",
      "2-Hydroxy-N-(3-Phenylpropyl)Benzamide",
      "CHEMBL290426",
      "153810-66-3",
      "MLS000112389",
      "AC1M4REE",
      "Cambridge Id 6816004",
      "Cid_2287872",
      "MolPort-001-028-867",
      "HMS2472G06",
      "ZINC2989649",
      "AC1Q7881",
      "BDBM50282105",
      "AKOS008913729",
      "NE30179",
      "MCULE-4556826159",
      "JJ-3297",
      "ST069062",
      "SMR000108303",
      "EN300-70444",
      "AB00120250-01",
      "(2-Hydroxyphenyl)-N-(3-Phenylpropyl)Carboxamide",
      "SR-01000522099",
      "SR-01000522099-1",
    ],
  },
  {
    primary_id: "D09XXW",
    names: ["Co-2-1068"],
  },
  {
    primary_id: "D09XYA",
    names: ["SB 203106"],
  },
  {
    primary_id: "D09XYU",
    names: ["XR-12890"],
  },
  {
    primary_id: "D09XYZ",
    names: ["Acetic Acid Salicyloyl-Amino-Ester"],
  },
  {
    primary_id: "D09XZB",
    names: ["Gefitinib"],
  },
  {
    primary_id: "D09XZL",
    names: ["LX-7101"],
  },
  {
    primary_id: "D09XZM",
    names: ["FGGFTCARKCARK", "CHEMBL442113"],
  },
  {
    primary_id: "D09YAL",
    names: ["6-Tert-Butyl-2-Morpholin-4-Yl-4H-Thiopyran-4-One", "CHEMBL224827"],
  },
  {
    primary_id: "D09YAS",
    names: ["CYT-013-IL1bQb"],
  },
  {
    primary_id: "D09YBA",
    names: ["15-Deoxy-LXA4", "GTPL3402", "(5S,6R,7E,9E,11E,13E)-5,6-Dihydroxyicosa-7,9,11,13-Tetraenoic Acid"],
  },
  {
    primary_id: "D09YBC",
    names: ["NOCARDIMICIN A"],
  },
  {
    primary_id: "D09YBF",
    names: ["Gamma-Tocotrienol"],
  },
  {
    primary_id: "D09YBN",
    names: [
      "6-Phenoxy-Hexane-1-Thiol",
      "CHEMBL109796",
      "6-Phenoxyhexane-1-Thiol",
      "1-Hexanethiol, 6-Phenoxy-",
      "SCHEMBL5679745",
      "MolPort-020-180-823",
      "BDBM50223652",
      "AKOS018584222",
      "MCULE-9521857089",
    ],
  },
  {
    primary_id: "D09YBP",
    names: ["ISIS 113209"],
  },
  {
    primary_id: "D09YBZ",
    names: ["CO-12", "Antituberculosis Compound, C&O Pharmaceutical"],
  },
  {
    primary_id: "D09YCW",
    names: [
      "4-Amino-3-(5-Methyl-Thiophen-2-Yl)-Butyric Acid",
      "133933-77-4",
      "CHEMBL86144",
      "4-Amino-3-(5-Methylthiophen-2-Yl)Butanoic Acid",
      "4-Amtba",
      "4-Amino-3-(5-Methyl-2-Thienyl)Butyric Acid",
      "AC1L2PFE",
      "ACMC-1C1U4",
      "SCHEMBL341475",
      "CTK0H6620",
      "Beta-(5-Methyl-2-Thienyl)GABA",
      "BDBM50007107",
      "AKOS022919013",
      "4-Amino-3-(5-Methylthien-2-Yl)Butanoic Acid",
      "2-Thiophenepropanoic Acid, Beta-(Aminomethyl)-5-Methyl-, (+-)-",
    ],
  },
  {
    primary_id: "D09YDC",
    names: ["HL-142"],
  },
  {
    primary_id: "D09YDM",
    names: [
      "Camptothecin",
      "Camptothecine",
      "7689/3/4",
      "(S)-(+)-Camptothecin",
      "Campathecin",
      "D-Camptothecin",
      "(+)-Camptothecine",
      "(+)-Camptothecin",
      "20(S)-Camptothecine",
      "21,22-Secocamptothecin-21-Oic Acid Lactone",
      "NSC94600",
      "(S)-Camptothecin",
      "Camptothecine (8CI)",
      "20(S)-Camptothecin",
      "Camptothecine (S,+)",
      "CHEMBL65",
      "UNII-XT3Z54Z28A",
      "MLS000766223",
      "(4S)-4-Ethyl-4-Hydroxy-1H-Pyrano[3',4':6,7]indolizino[1,2-B]quinoline-3,14(4H,12H)-Dione",
      "XT3Z54Z28A",
      "CHEBI:27656",
      "VSJKWCGYPAHWDS-FQEVSTJZSA-N",
      "Camptothecin, Camptotheca Acuminata",
      "NSC-94600",
      "Camptothecin Derivat",
    ],
  },
  {
    primary_id: "D09YDO",
    names: ["Quinotolast", "Aslock", "Quinotalast", "FK-021", "FR-71021"],
  },
  {
    primary_id: "D09YDQ",
    names: [
      "STX-107",
      "2-Methyl-4-(Pyridin-3-Ylethynyl)Thiazole",
      "MTEP",
      "MGluR5 Antagonist, MTEP",
      "329205-68-7",
      "CHEMBL292065",
      "3-((2-Methyl-1,3-Thiazol-4-Yl)Ethynyl)Pyridine",
      "3-[(2-Methyl-1,3-Thiazol-4-Yl)Ethynyl]pyridine",
      "3-[2-(2-Methyl-1,3-Thiazol-4-Yl)Ethynyl]pyridine",
      "3-(2-(2-Methylthiazol-4-Yl)Ethynyl)Pyridine",
      "3-[2-(2-Methylthiazol-4-Yl)Ethynyl]pyridine",
      "3-(2-Methyl-Thiazol-4-Ylethynyl)-Pyridine",
      "SCHEMBL187254",
      "GTPL3336",
      "CTK1B8867",
      "DTXSID30430775",
      "ZINC1489321",
      "IN1238",
      "BDBM50122758",
      "FCH3417361",
      "API0006876",
      "ACN-027361",
      "KB-274670",
    ],
  },
  {
    primary_id: "D09YEH",
    names: ["PMID25991433-Compound-A11"],
  },
  {
    primary_id: "D09YEQ",
    names: ["Ro4491533"],
  },
  {
    primary_id: "D09YEY",
    names: ["Botulinum Toxin Type F"],
  },
  {
    primary_id: "D09YFD",
    names: ["Selenium Compound 2"],
  },
  {
    primary_id: "D09YFJ",
    names: ["PMID27336223-Compound-12"],
  },
  {
    primary_id: "D09YGD",
    names: [
      "4'-(4-Aminobenzensulfonamide)-4-Hydroxychalcone",
      "CHEMBL1087578",
      "SCHEMBL3484498",
      "BDBM50316856",
      "4''-(4-Aminobenzensulfonamide)-4-Hydroxychalcone",
    ],
  },
  {
    primary_id: "D09YGK",
    names: ["Ultragrivak"],
  },
  {
    primary_id: "D09YGZ",
    names: ["OCT-002"],
  },
  {
    primary_id: "D09YHI",
    names: [
      "SX5",
      "4-[(4-Bromo-2-{[(3R,5S)-3,5-Dimethylpiperidin-1-Yl]carbonyl}phenyl)Amino]-4-Oxobutanoic Acid",
      "SCHEMBL4882718",
      "CHEMBL256528",
      "DB08581",
      "4-[4-Bromo-2-[(3S,5R)-3,5-Dimethylpiperidine-1-Carbonyl]anilino]-4-Oxo-Butanoic Acid",
      "4-[4-Bromo-2-[(3R,5S)-3,5-Dimethylpiperidine-1-Carbonyl]anilino]-4-Oxo-Butanoic Acid",
      "3-({4-Bromo-2-[(3R,5S)-3,5-Dimethylpiperidine-1-Carbonyl]phenyl}carbamoyl)Propanoic Acid",
    ],
  },
  {
    primary_id: "D09YHJ",
    names: ["Ivermectin"],
  },
  {
    primary_id: "D09YHL",
    names: ["MKG-02"],
  },
  {
    primary_id: "D09YHQ",
    names: [
      "9-Chlorobenzo[h]isoquinolin-1(2H)-One",
      "CHEMBL248988",
      "919290-40-7",
      "SCHEMBL4296798",
      "CTK3H3900",
      "DTXSID50475406",
      "XSQDWQSZUGGKPF-UHFFFAOYSA-N",
      "ZINC28955758",
      "BDBM50223471",
      "Benz[h]isoquinolin-1(2H)-One, 9-Chloro-",
    ],
  },
  {
    primary_id: "D09YHS",
    names: ["1-Methyl-2-(4-Phenylsulfanyl-Phenyl)-Ethylamine", "CHEMBL1078716", "BDBM50310835"],
  },
  {
    primary_id: "D09YJK",
    names: ["BMS-766"],
  },
  {
    primary_id: "D09YJR",
    names: ["S 18204"],
  },
  {
    primary_id: "D09YJS",
    names: ["2-Hydroxyphenethyl 3,4,5-Trihydroxybenzoate", "CHEMBL399354", "Gallic Acid 2-Hydroxyphenethyl Ester"],
  },
  {
    primary_id: "D09YJT",
    names: ["Abilify Maintena", "Aripiprazole For Extended Release"],
  },
  {
    primary_id: "D09YJV",
    names: ["Pyrrolo-Pyridinone Derivative 5"],
  },
  {
    primary_id: "D09YKH",
    names: [
      "BURTTINONE",
      "Burttinone",
      "CHEMBL515837",
      "5,7-Dihydroxy-4'-Methoxy-3'-Prenyl-5'-[(3-Methyl-2-Butenyl)Phenyl]flavanone",
      "LMPK12140350",
    ],
  },
  {
    primary_id: "D09YKI",
    names: ["Polyhydroxy Benzamide Derivative 1"],
  },
  {
    primary_id: "D09YLF",
    names: ["N-(7-Benzyloxy-Isoquinolin-1-Yl)-Guanidine", "CHEMBL107872"],
  },
  {
    primary_id: "D09YLY",
    names: ["Isosorbide-2-Benzylcarbamate-5-Isonicotinate", "CHEMBL492481"],
  },
  {
    primary_id: "D09YNJ",
    names: ["IQP-0410"],
  },
  {
    primary_id: "D09YOU",
    names: ["MK-0343"],
  },
  {
    primary_id: "D09YOY",
    names: ["Cyclopentane Amide Derivatives 2"],
  },
  {
    primary_id: "D09YPJ",
    names: ["SM08502"],
  },
  {
    primary_id: "D09YPM",
    names: ["Gene Vectors"],
  },
  {
    primary_id: "D09YQM",
    names: [
      "BMS-262084",
      "UNII-I0IR71971G",
      "CHEMBL71037",
      "I0IR71971G",
      "253174-92-4",
      "(-)-BMS-262084",
      "BDBM50120368",
      "BDBM50220841",
      "SB18716",
      "2-Azetidinecarboxylic Acid, 3-(3-((Aminoiminomethyl)Amino)Propyl)-1-((4-(((1,1-Dimethylethyl)Amino)Carbonyl)-1-Piperazinyl)Carbonyl)-4-Oxo-, (2S,3R)-",
      "(R)-1-(4-Tert-Butylcarbamoyl-Piperazine-1-Carbonyl)-3-(3-Guanidino-Propyl)-4-Oxo-Azetidine-2-Carboxylic Acid",
      "(3S,4R)-1-(4-Tert-Butylcarbamoyl-Piperazine-1-Carbonyl)-3-(3-Guanidino-Propyl)-4-Oxo-Azetidine-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D09YQO",
    names: ["PMID26666989-Compound-Figure12topmiddile"],
  },
  {
    primary_id: "D09YRA",
    names: ["SSTarbaclofen"],
  },
  {
    primary_id: "D09YRL",
    names: ["N-Glycylglycinehydroxamic Acid"],
  },
  {
    primary_id: "D09YRP",
    names: ["ISIS 147788"],
  },
  {
    primary_id: "D09YSV",
    names: ["9H-Beta-Carboline-3-Carboxylic Acid Butyl Ester"],
  },
  {
    primary_id: "D09YTH",
    names: ["CPU-228", "Dofetilide Analog (Cardiac Failure), China Pharmaceutical University"],
  },
  {
    primary_id: "D09YTX",
    names: ["Ch14.18,", "Ch14.18, (Intravenous)"],
  },
  {
    primary_id: "D09YTY",
    names: ["Osutidine"],
  },
  {
    primary_id: "D09YUH",
    names: ["Anti-HIV Peptides"],
  },
  {
    primary_id: "D09YUK",
    names: [
      "SS1(DsFv)-PE38",
      "CAT-5001",
      "SS (DsFv) PE38 Immunotoxin)",
      "SS1-PE38",
      "SS (DsFv) PE38 Immunotoxin, NCI",
      "SS1P, Enzon/NCI",
    ],
  },
  {
    primary_id: "D09YUL",
    names: [
      "Bromoenol Lactone",
      "HELSS",
      "88070-98-8",
      "6-Btnpo",
      "BTNP",
      "C16H13BrO2",
      "CHEMBL6206",
      "BEL",
      "6-(Bromomethylene)Tetrahydro-3-(1-Naphthaleneyl)-2H-Pyran-2-One",
      "Haloenol Lactone Suicide Substrate",
      "BYUCSFWXCMTYOI-ZRDIBKRKSA-N",
      "(E)-6-(Bromomethylene)Tetrahydro-3-(1-Naphthalenyl)-2H-Pyran-2-One",
      "(E)-6-(Bromomethylene)-3-(1-Naphthalenyl)Tetrahydro-2H-Pyran-2-One",
      "Bromoenolactone",
      "E-6-(Bromoethylene)Tetrahydro-3-(1-Naphthyl)-2H-Pyran-2-One",
      "2H-Pyran-2-One, 6-(Bromomethylene)Tetrahydro-3-(1-Naphthalenyl)-, (E)-",
      "SR-01000075707",
    ],
  },
  {
    primary_id: "D09YUQ",
    names: ["SR-11237"],
  },
  {
    primary_id: "D09YUS",
    names: ["KRG-3332"],
  },
  {
    primary_id: "D09YVA",
    names: [
      "8-Methoxy-2-(4-Methyl-Piperazin-1-Yl)-Quinoline",
      "CHEMBL80775",
      "8-Methoxy-N-Methylquipazine",
      "BDBM50025469",
    ],
  },
  {
    primary_id: "D09YVN",
    names: ["Dihydrofolic Acid"],
  },
  {
    primary_id: "D09YVQ",
    names: ["ChondrogenTM", "Chondrogen (TN)"],
  },
  {
    primary_id: "D09YVT",
    names: ["HERBACETIN"],
  },
  {
    primary_id: "D09YVU",
    names: ["6-(3-Aminopropyl)Benzo[h]isoquinolin-1(2H)-One", "CHEMBL250222", "SCHEMBL13659560"],
  },
  {
    primary_id: "D09YVX",
    names: ["Etofenamate"],
  },
  {
    primary_id: "D09YVZ",
    names: ["Benzaldehyde O-4-(Heptyloxy)Phenylcarbamoyl Oxime", "CHEMBL597228"],
  },
  {
    primary_id: "D09YWX",
    names: ["2-Quinolines Series, Leishmaniasis, DNDi/CDRI/Advinus Therapeutics"],
  },
  {
    primary_id: "D09YXN",
    names: ["CAR-T Cells Targeting Mesothelin"],
  },
  {
    primary_id: "D09YXS",
    names: ["GX-E2"],
  },
  {
    primary_id: "D09YXT",
    names: ["LY3321367"],
  },
  {
    primary_id: "D09YXV",
    names: [
      "3-[5-Methoxy-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "CHEMBL176609",
      "AC1O704P",
      "SCHEMBL4226031",
      "SCHEMBL4226036",
      "BDBM8593",
      "(3-Pyridylmethylene)Indane 11a",
      "3-[(E)-(5-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D09YYQ",
    names: ["Aponidin", "Aponidin (Autoimmune Disease/Cancer)"],
  },
  {
    primary_id: "D09ZAJ",
    names: ["Isothiazolone Derivative 1"],
  },
  {
    primary_id: "D09ZAL",
    names: ["6,6-Dimethyl-1-Phenyl-3-Aza-Bicyclo[3.1.0]hexane", "CHEMBL488639"],
  },
  {
    primary_id: "D09ZAT",
    names: ["1-(3,4-Dichloro-Phenyl)-3-Methylamino-Indan-5-Ol", "CHEMBL301514"],
  },
  {
    primary_id: "D09ZAV",
    names: [
      "RG-8803",
      "Angiogenesis Inhibitors, Repligen",
      "BFGF Inhibitors, Repligen",
      "RG-8803B11",
      "RGEN-1009",
      "VEGF Inhibitors, Repligen",
      "8803B11",
    ],
  },
  {
    primary_id: "D09ZAX",
    names: ["Asp-Tyr(SO3H)-Met-Gly-Trp-Met-Asp-Phe-NH2", "CHEMBL410985"],
  },
  {
    primary_id: "D09ZBJ",
    names: ["RAGE-Immunoglobulin Fusion Protein"],
  },
  {
    primary_id: "D09ZCA",
    names: ["Biphenyl Mannoside Derivative 24"],
  },
  {
    primary_id: "D09ZCH",
    names: ["HRC-201"],
  },
  {
    primary_id: "D09ZCV",
    names: ["Salicylic Acid Derivative 3"],
  },
  {
    primary_id: "D09ZDQ",
    names: [
      "2-Furan-2-Yl-4,5-Dihydro-1H-Imidazole",
      "2-(Furan-2-Yl)-4,5-Dihydro-1H-Imidazole",
      "40029-93-4",
      "CHEMBL14410",
      "2-(2-Furyl)-4,5-Dihydro-1H-Imidazole",
      "AC1LHNFC",
      "BAS 00087895",
      "2-(2-Furyl)Imidazoline",
      "2-(2-Imidazolin-2-Yl)Furan",
      "2-(1-Imidazoline-2-Yl)Furan",
      "CTK5J7407",
      "DTXSID40357040",
      "MolPort-001-914-212",
      "HMS1698J05",
      "ZINC4992560",
      "BDBM50138501",
      "2536AE",
      "AKOS000301450",
      "MCULE-8474767921",
      "ST072486",
      "TR-043590",
    ],
  },
  {
    primary_id: "D09ZDY",
    names: ["CAR138 T Cells"],
  },
  {
    primary_id: "D09ZFF",
    names: ["HRC-101"],
  },
  {
    primary_id: "D09ZFJ",
    names: ["AMG 529"],
  },
  {
    primary_id: "D09ZFM",
    names: ["PMID25656651-Compound-36b"],
  },
  {
    primary_id: "D09ZGK",
    names: ["BCMA-CART"],
  },
  {
    primary_id: "D09ZGN",
    names: [
      "2-Hex-5-Enyl-5-Non-8-Enyl-3,4-Dihydro-2H-Pyrrole",
      "CHEMBL14196",
      "AC1LBGT2",
      "2-(1-Non-8-Enyl)-5-(1-Hex-5-Enyl)-Pyrroline",
      "CTK5J4228",
      "JRNMZRPLKGPMHB-UHFFFAOYSA-N",
      "BDBM50284426",
      "2-(5-Hexenyl)-5-(8-Nonenyl)-3,4-Dihydro-2H-Pyrrole #",
      "2-(1-Non-8-Enyl)-5-(1-Hex-5-Enyl).delta.1--Pyrroline",
    ],
  },
  {
    primary_id: "D09ZHX",
    names: [
      "4'-Bromo-3-(Imidazolylmethyl)Flavone",
      "CHEMBL377766",
      "BDBM50191597",
      "4''-Bromo-3-(Imidazolylmethyl)Flavone",
    ],
  },
  {
    primary_id: "D09ZHZ",
    names: ["Mecobalamin"],
  },
  {
    primary_id: "D09ZIC",
    names: ["N-Hydroxy-Nor-L-Arginine"],
  },
  {
    primary_id: "D09ZIM",
    names: ["3-Oxo-2,3-Dihydro-1H-Indazole-4-Carboxamide Derivative 5"],
  },
  {
    primary_id: "D09ZIO",
    names: ["Eptifibatide"],
  },
  {
    primary_id: "D09ZIS",
    names: ["Zonisamide"],
  },
  {
    primary_id: "D09ZIU",
    names: [
      "1-Phenyl-3-Aza-Bicyclo[3.1.0]hexane",
      "1-Phenyl-3-Azabicyclo[3.1.0]hexane",
      "67644-21-7",
      "CHEMBL503383",
      "1-PHENYL-3-AZABICYCLO(3.1.0)HEXANE",
      "66505-14-4",
      "SCHEMBL4557697",
      "CTK5C4827",
      "HYXPTPHIWQWOQF-UHFFFAOYSA-N",
      "BDBM50243891",
      "AKOS006370964",
      "1-Phenyl-3-Azabicyclo-[3.1.0]hexane",
      "1-Phenyl-3-Azabicyclo[3.1.0]-Hexane",
    ],
  },
  {
    primary_id: "D09ZIW",
    names: ["Isogemichalcone C"],
  },
  {
    primary_id: "D09ZJQ",
    names: ["Seocalcitol"],
  },
  {
    primary_id: "D09ZJW",
    names: [
      "4-Chlorobenzenemethanethiol",
      "4-Chlorobenzyl Mercaptan",
      "6258-66-8",
      "4-Chlorobenzylmercaptan",
      "(4-Chlorophenyl)Methanethiol",
      "P-Chlorobenzyl Mercaptan",
      "Benzenemethanethiol, 4-Chloro-",
      "P-Chlorotoluene-Alpha-Thiol",
      "4-Chloro Benzyl Mercaptan",
      "4-Chloro-Alpha-Toluenethiol",
      "CHEMBL1224555",
      "GKQXPTHQTXCXEV-UHFFFAOYSA-N",
      "EINECS 228-395-7",
      "4-Chlor-Benzylthiol",
      "4-Chlorobenzyl Thiol",
      "P-Chlorobenzylmercaptan",
      "PubChem6838",
      "NSC 108735",
      "4-Chloro-Benzylmercaptan",
      "AC1L2ZDM",
      "4-Chlorophenylmethanethiol",
      "ACMC-1BE9W",
      "4-Chlorophenyl Methanethiol",
      "AC1Q3NX5",
    ],
  },
  {
    primary_id: "D09ZKB",
    names: [
      "Ad35-GRIN/ENV",
      "Ad35 Vector-Delivered Gag/Rt/Int/Nef + Ad35 Vector-Delivered Env Combination Vaccine (HIV Infection), International AIDS Vaccine Initiative",
    ],
  },
  {
    primary_id: "D09ZKS",
    names: ["HP-228"],
  },
  {
    primary_id: "D09ZKU",
    names: ["DISBA-01"],
  },
  {
    primary_id: "D09ZKY",
    names: ["GW853606"],
  },
  {
    primary_id: "D09ZLZ",
    names: ["RAD-140"],
  },
  {
    primary_id: "D09ZMS",
    names: [
      "2',3'-Dideoxycytidine-5'-Monophosphate",
      "104086-76-2",
      "((2S,5R)-5-(4-Amino-2-Oxopyrimidin-1(2H)-Yl)Tetrahydrofuran-2-Yl)Methyl Dihydrogen Phosphate",
      "5'-Cytidylic Acid, 2',3'-Dideoxy-",
      "DdCMP",
      "2',3'-DIDEOXYCYTIDINE-5'-MONOPHOSPHATE",
      "BRN 0556994",
      "2',3'-Dideoxycytidine 5'-Monophosphate",
      "CHEMBL1232305",
      "C9H14N3O6P",
      "[(2S,5R)-5-(4-Amino-2-Oxopyrimidin-1(2H)-Yl)Tetrahydrofuran-2-Yl]methyl Dihydrogen Phosphate",
      "AC1L9JZG",
      "SCHEMBL454264",
      "Dideoxycytidine 5'-Monophosphate",
      "ZINC1611080",
      "0954AA",
      "BDBM50349537",
      "AKOS016009455",
      "DB02883",
      "LS-59086",
      "AJ-28234",
      "AK112219",
      "KB-204945",
      "AX8123167",
    ],
  },
  {
    primary_id: "D09ZMV",
    names: ["Votucalis Ophthalmic"],
  },
  {
    primary_id: "D09ZNL",
    names: [
      "ChronSeal",
      "Antibiotic-Free Recombinant HGF, Linkoping",
      "Recombinant HGF, Kringle/ChronTech",
      "Recombinant HGF, Kringle/Tripep",
    ],
  },
  {
    primary_id: "D09ZNQ",
    names: [
      "Recombinant L-Asparaginase",
      "ASPAREC",
      "AZP-02",
      "Recombinant L-Asparaginase (Acute Lymphoblastic Leukemia), OPi/EUSA",
      "Recombinant L-Asparaginase (PEGylated, Long-Acting, Acute Lymphoblastic Leukemia)",
      "Recombinant L-Asparaginase (PEGylated, Long-Acting, Acute Lymphoblastic Leukemia), Alize Pharma",
    ],
  },
  {
    primary_id: "D09ZNS",
    names: ["Paramyxovirus Therapy, PiKe"],
  },
  {
    primary_id: "D09ZNU",
    names: ["4-Benzoylamino-N-Hydroxy-Benzamide"],
  },
  {
    primary_id: "D09ZNV",
    names: [
      "CGP-029482",
      "CHEMBL92361",
      "(5-OXO-5,6-DIHYDRO-INDOLO[1,2-A]QUINAZOLIN-7-YL)-ACETIC ACID",
      "(5-Oxo-5,6-Dihydroindolo[1,2-A]quinazolin-7-Yl)Acetic Acid",
      "IQA",
      "1om1",
      "AC1L9L3Y",
      "SCHEMBL263731",
      "BDBM11319",
      "MolPort-006-167-937",
      "HMS3229D05",
      "ZINC2047511",
      "CGP029482",
      "AKOS005266257",
      "DB01765",
      "{5-Oxo-6H-Indolo[1,2-A]quinazolin-7-Yl}acetic Acid",
      "2-(5-Oxo-6H-Indolo[1,2-A]quinazolin-7-Yl)Acetic Acid",
      "[5-Oxo-5,6-Dihydroindolo-(1,2-A)Quinazolin-7-Yl]acetic Acid",
    ],
  },
  {
    primary_id: "D09ZOA",
    names: ["2-(Biphenyl-4-Ylsulfonamido)-N-Hydroxyacetamide", "CHEMBL550148", "3f17", "BDBM50294120"],
  },
  {
    primary_id: "D09ZOQ",
    names: ["Incruse Ellipta"],
  },
  {
    primary_id: "D09ZPM",
    names: [
      "PMID25408830C1",
      "BAS 08314227",
      "4uyd",
      "AC1LLXEX",
      "ChemDiv2_002770",
      "MLS000100042",
      "GTPL7809",
      "MolPort-001-912-793",
      "HMS2483G18",
      "HMS1376N20",
      "ZINC812404",
      "BDBM50032913",
      "AKOS001788033",
      "MCULE-4972680634",
      "SMR000081748",
      "V1T",
    ],
  },
  {
    primary_id: "D09ZPN",
    names: ["MK-6892"],
  },
  {
    primary_id: "D09ZPX",
    names: ["HSR-6071"],
  },
  {
    primary_id: "D09ZQN",
    names: [
      "Dienestrol",
      "Agaldog",
      "Cycladiene",
      "Dehydrostilbestrol",
      "Dehydrostilboestrol",
      "Dienesterol",
      "Dienestrolo",
      "Dienestrolum",
      "Dienoestrol",
      "Dienol",
      "Dinestrol",
      "Dinovex",
      "Estragard",
      "Estraguard",
      "Estrodienol",
      "Estroral",
      "Follidiene",
      "Follormon",
      "Gynefollin",
      "Hormofemin",
      "Isodienestrol",
      "Oestrasid",
      "Oestrodiene",
      "Oestrodienol",
      "Oestroral",
      "Oestrovis",
      "Restrol",
      "Retalon",
      "Sexadien",
      "Synestrol",
      "Teserene",
      "Willnestrol",
      "Dienestrolo [DCIT]",
      "Dienoestrol [Nonsteroidal Oestrogens]",
      "Dienoestrol Bp",
      "Alpha-Dienestrol",
      "DV (TN)",
      "Dienestrolum [INN-Latin]",
      "Para-Dien",
      "Restrol, Dienestrol",
      "Dienestrol (E,E)",
      "Dienestrol (USP/INN)",
      "Dienestrol, (E,E)-Isomer",
      "P,p'-(Diethylideneethylene)Diphenol",
      "Para,para'-(Diethylideneethylene)Diphenol",
      "Di(P-Oxyphenyl)-2,4-Hexadiene",
      "Di(Para-Oxyphenyl)-2,4-Hexadiene",
      "Phenol, 4,4'-(1,2-Diethylidene-1,2-Ethanediyl)Bis-, (E,E-(9CI)",
      "(E,E)-Dienestrol",
      "3,4-Bis(4-Hydroxyphenyl)-2,4-Hexadiene",
      "3,4-Bis(P-Hydroxyphenyl)-2,4-Hexadiene",
      "3,4-Bis(Para-Hydroxyphenyl)-2,4-Hexadiene",
      "4,4'-(1,2-Diethylidene-1,2-Ethanediyl)Bisphenol",
      "4,4'-(2E,4E)-Hexa-2,4-Diene-3,4-Diyldiphenol",
      "4,4'-(Diethylideneethylene)Diphenol",
      "4,4'-Dihydroxy-Gamma,delta-Diphenyl-Beta,delta-Hexadiene",
      "4,4'-Hydroxy-Gamma,delta-Diphenyl-Beta,delta-Hexadiene",
      "4,4'-Hexa-2,4-Diene-3,4-Diyldiphenol",
      "4-[(2E,4E)-4-(4-Hydroxyphenyl)Hexa-2,4-Dien-3-Yl]phenol",
    ],
  },
  {
    primary_id: "D09ZQW",
    names: ["(+/-)-Threo-3',5'-Dichloromethylphenidate", "CHEMBL1253691", "BDBM50327135"],
  },
  {
    primary_id: "D09ZQX",
    names: [
      "SL65.0155",
      "Capeserod",
      "769901-96-4",
      "5-(8-Amino-7-Chloro-2,3-Dihydrobenzo[b][1,4]dioxin-5-Yl)-3-(1-Phenethylpiperidin-4-Yl)-1,3,4-Oxadiazol-2(3H)-One",
      "UNII-8163770L8P",
      "8163770L8P",
      "5-(8-AMINO-7-CHLORO-2,3-DIHYDRO-1,4-BENZODIOXIN-5-YL)-3-[1-(2-PHENYLETHYL)PIPERIDIN-4-YL]-1,3,4-OXADIAZOL-2(3H)-ONE",
      "Capeserod [INN]",
      "GTPL29",
      "SCHEMBL4707724",
      "CHEMBL2027925",
      "BDBM86038",
      "ZINC603773",
      "PDSP2_001247",
      "PDSP1_001263",
      "2009AC",
      "AKOS016011606",
      "SL650155",
      "AJ-23687",
      "DB-030409",
      "KB-243722",
      "FT-0735661",
    ],
  },
  {
    primary_id: "D09ZRB",
    names: ["PUR0200"],
  },
  {
    primary_id: "D09ZSA",
    names: [
      "WR85915",
      "Azasetron Hydrochloride",
      "123040-16-4",
      "Azasetron HCl",
      "Serotone",
      "Azasetron (Hydrochloride)",
      "Y-25130 Hydrochloride",
      "141922-90-9",
      "C17H20ClN3O3.HCl",
      "Azasetron Hydrochloride [JAN]",
      "6-Chloro-4-Methyl-3-Oxo-N-(Quinuclidin-3-Yl)-3,4-Dihydro-2H-Benzo[b][1,4]oxazine-8-Carboxamide Hydrochloride",
      "Azasetron Hydrochloride (JAN)",
      "N-(1-Azabicyclo[2.2.2]octan-3-Yl)-6-Chloro-4-Methyl-3-Oxo-1,4-Benzoxazine-8-Carboxamide Hydrochloride",
      "2H-1,4-Benzoxazine-8-Carboxamide, 3,4-Dihydro-N-1-Azabicyclo(2.2.2)Oct-3-Yl-6-Chloro-",
    ],
  },
  {
    primary_id: "D09ZSK",
    names: ["N-(5-Phenyl-Furan-2-Carbonyl)-Guanidine", "CHEMBL192690", "SCHEMBL4943707"],
  },
  {
    primary_id: "D09ZSO",
    names: ["Alefacept", "Amevive (TN)"],
  },
  {
    primary_id: "D09ZSV",
    names: [
      "2-(4-Bromo-2-Formylphenoxy)Acetic Acid",
      "24589-89-7",
      "(4-Bromo-2-Formylphenoxy)Acetic Acid",
      "CHEMBL385009",
      "AC1LJ5IQ",
      "Bromoformylphenoxyaceticacid",
      "Oprea1_723931",
      "AC1Q764A",
      "SCHEMBL2879909",
      "KS-00000LHU",
      "CTK1A1564",
      "DTXSID80359528",
      "MolPort-000-886-040",
      "KXRYNWDCFUKVNN-UHFFFAOYSA-N",
      "ZINC586295",
      "SP656",
      "4-Bromo-2-Formylphenoxyacetic Acid",
      "ALBB-007850",
      "4-Bromo-2-Formylphenoxy Acetic Acid",
      "2-Formyl-4-Bromophenoxy Acetic Acid",
      "STK201324",
      "SBB020100",
      "BDBM50200251",
      "BBL023190",
      "8265AD",
      "AKOS000113320",
      "MCULE-3263948503",
      "AC-0818",
      "RP14657",
      "ACN-052850",
    ],
  },
  {
    primary_id: "D09ZSY",
    names: ["CI-1012"],
  },
  {
    primary_id: "D09ZTU",
    names: ["FGGFTGARKSARKWRNQ"],
  },
  {
    primary_id: "D09ZUJ",
    names: ["Peptide Analog 61"],
  },
  {
    primary_id: "D09ZUM",
    names: ["CR-4892"],
  },
  {
    primary_id: "D09ZUN",
    names: [
      "2,6-Di-Tert-Butyl-4-Methoxyphenol",
      "2,6-DI-TERT-BUTYL-4-METHOXYPHENOL",
      "489-01-0",
      "3,5-Di-Tert-Butyl-4-Hydroxyanisole",
      "Topanol 354",
      "Phenol, 2,6-Bis(1,1-Dimethylethyl)-4-Methoxy-",
      "3,5-Di-T-Butyl-4-Hydroxyanisole",
      "UNII-616072TMXY",
      "CCRIS 5219",
      "2,6-Bis(1,1-Dimethylethyl)-4-Methoxyphenol",
      "EINECS 207-693-0",
      "NSC 14451",
      "Phenol, 2,6-Di-Tert-Butyl-4-Methoxy-",
      "CHEMBL98253",
      "2,6-Di-T-Butyl-4-Methoxyphenol",
      "SLUKQUGVTITNSY-UHFFFAOYSA-N",
      "2,6-Ditert-Butyl-4-Methoxyphenol",
      "616072TMXY",
      "2,6-Bis(Tert-Butyl)-4-Methoxyphenol",
      "2,6-Di-Tert-Butyl-4-Methoxyphenol, 97%",
      "DTBHA",
      "ACMC-1ALDH",
    ],
  },
  {
    primary_id: "D09ZUW",
    names: ["L-765314"],
  },
  {
    primary_id: "D09ZUX",
    names: ["1,1,1,2,2,4-Hexafluoro-7-Phenylheptan-3-One", "CHEMBL1096057"],
  },
  {
    primary_id: "D09ZVC",
    names: ["Begacestat"],
  },
  {
    primary_id: "D09ZVJ",
    names: ["R-954"],
  },
  {
    primary_id: "D09ZVR",
    names: ["MDL-43291"],
  },
  {
    primary_id: "D09ZWE",
    names: [
      "Anti-IGFR 1 Mab",
      "Anti-IGFR 1 MAb (Unidentified Indication)",
      "Anti-IGFR 1 MAb (Unidentified Indication), PharmAbcine",
      "Insulin Like Growth Factor Receptor 1 Antagonist (MAb, Unidentified Indication), PharmAbcine",
    ],
  },
  {
    primary_id: "D09ZWH",
    names: ["LX2931"],
  },
  {
    primary_id: "D09ZWI",
    names: ["KUR-521"],
  },
  {
    primary_id: "D09ZWU",
    names: ["PMID25666693-Compound-40"],
  },
  {
    primary_id: "D09ZXO",
    names: [
      "Cilengitide",
      "188968-51-6",
      "EMD-121974",
      "Cilengitide [USAN:INN]",
      "UNII-4EDF46E4GI",
      "EMD121974",
      "EMD-12192",
      "EMD 121974",
      "4EDF46E4GI",
      "CHEMBL429876",
      "2-[(2S,5R,8S,11S)-5-Benzyl-11-[3-(Diaminomethylideneamino)Propyl]-7-Methyl-3,6,9,12,15-Pentaoxo-8-Propan-2-Yl-1,4,7,10,13-Pentazacyclopentadec-2-Yl]acetic Acid",
      "Cyclo(L-Arginylglycyl-L-Aspartyl-D-Phenylalanyl-N-Methyl-L-Valyl)",
      "Cyclo(L-Arginylglycyl-L-Alpha-Aspartyl-D-Phenylalanyl-N-Methyl-L-Valyl)",
      "Cilengitide (TFA Salt)",
    ],
  },
  {
    primary_id: "D09ZXR",
    names: ["Eluxadoline"],
  },
  {
    primary_id: "D09ZXW",
    names: ["Atorvastatin Lactole Derivative 1"],
  },
  {
    primary_id: "D09ZXX",
    names: [
      "Photodynamic Immunotherapy",
      "Anticancer Therapeutics, Procept",
      "PDIT, Procept",
      "Photo Dynamic Immunotherapy, Procept",
      "Photodynamic Immunotherapy (Cancer)",
      "Galactochitosan (PDIT), Procept",
      "Photodynamic Immunotherapy (Cancer), Paligent",
    ],
  },
  {
    primary_id: "D09ZYW",
    names: ["P53"],
  },
  {
    primary_id: "D09ZZB",
    names: ["EV71 Vaccine"],
  },
  {
    primary_id: "D09ZZF",
    names: ["IPH-41XX"],
  },
  {
    primary_id: "D09ZZI",
    names: [
      "2-Chlorophenol",
      "2-CHLOROPHENOL",
      "O-Chlorophenol",
      "95-57-8",
      "Phenol, 2-Chloro-",
      "2-Hydroxychlorobenzene",
      "O-Chlorphenol",
      "O-Chlorophenic Acid",
      "Phenol, O-Chloro-",
      "2-Chloro-1-Hydroxybenzene",
      "CHLOROPHENOL",
      "Septi-Kleen",
      "2-Chloro-Phenol",
      "RCRA Waste Number U048",
      "Pine-O Disinfectant",
      "Caswell No. 203",
      "O-Chlorphenol [German]",
      "UNII-K9KAV4K6BN",
      "Phenol, Chloro-",
      "NSC 2870",
      "CCRIS 640",
      "HSDB 1415",
      "EINECS 202-433-2",
      "K9KAV4K6BN",
      "RCRA Waste No. U048",
      "1-Chloro-2-Hydroxybenzene",
      "EPA Pesticide Chemical Code 062204",
      "AI3-09060",
      "CHEBI:47083",
      "ISPYQTSUDJAMAB-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D09ZZW",
    names: [
      "VX-759",
      "CHEMBL352911",
      "3-{isopropyl[(Trans-4-Methylcyclohexyl)Carbonyl]amino}-5-Phenylthiophene-2-Carboxylic Acid",
      "3-[Isopropyl-(4-Methyl-Cyclohexanecarbonyl)-Amino]-5-Phenyl-Thiophene-2-Carboxylic Acid",
      "3-[isopropyl-(4-Methylcyclohexanecarbonyl)Amino]-5-Phenyl-Thiophene-2-Carboxylic Acid",
      "VX759",
      "NN3",
      "VX 759",
      "Thiophene Scaffold, 21",
      "AC1L9XFB",
      "NNI-2",
      "478025-29-5",
      "SCHEMBL102322",
      "CHEMBL561360",
      "SCHEMBL1666872",
      "SCHEMBL14376078",
      "SCHEMBL13249635",
      "BDBM35554",
      "BDBM50139678",
      "DB08279",
    ],
  },
  {
    primary_id: "D0A0BK",
    names: ["Cal-1", "Cal-1-Transduced Hematopoietic"],
  },
  {
    primary_id: "D0A0CC",
    names: ["Viperfav"],
  },
  {
    primary_id: "D0A0CG",
    names: ["16-(Thiophen-2-Yl)Methylene-Estrone"],
  },
  {
    primary_id: "D0A0CY",
    names: ["CNDO-109"],
  },
  {
    primary_id: "D0A0CZ",
    names: [
      "2-Tosylnaphthalene-1,4-Diol",
      "CHEMBL509952",
      "4-Tolyl-2-[1,4-Dihydroxynaphthyl]sulfone",
      "AC1LC4IM",
      "CYYDOKQRZQTSOL-UHFFFAOYSA-N",
      "BDBM50245828",
      "2-(4-Methylphenyl)Sulfonylnaphthalene-1,4-Diol",
      "2-[(4-Methylphenyl)Sulfonyl]-1,4-Naphthalenediol #",
    ],
  },
  {
    primary_id: "D0A0DN",
    names: [
      "NSC-637994",
      "CCRIS 7540",
      "138154-43-5",
      "5-((3-Diethylamino)Propyl)Amino-8-Hydroxy-6H-Imidazo(4,5,1-De)Acridin-6-One",
      "CHEMBL123853",
      "AC1L4CDL",
      "SCHEMBL13526642",
      "DTXSID80160510",
      "ZINC5114744",
      "NCI60_012643",
      "LS-189188",
    ],
  },
  {
    primary_id: "D0A0DO",
    names: ["Des-AA1,2,5-[D-Nal8,IAmp9,(NalphaMe)Cys14]SRIF", "CHEMBL438576"],
  },
  {
    primary_id: "D0A0DS",
    names: [
      "[3H]bag-2",
      "2-[4-[2-[5-(Cyclopentylmethyl)-1H-Imidazol-2-Yl]ethyl]phenyl]benzoic Acid",
      "Bag-2",
      "CHEMBL1084942",
      "GTPL6189",
      "SCHEMBL6982673",
      "GTPL8506",
      "BDBM50313741",
      "4''-(2-(5-(Cyclopentylmethyl)-1H-Imidazol-2-Yl)Ethyl)Biphenyl-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0A0DV",
    names: ["Phenylpropylamine Derivative 4"],
  },
  {
    primary_id: "D0A0ED",
    names: ["FGIN-1-27"],
  },
  {
    primary_id: "D0A0EI",
    names: ["MP-0210"],
  },
  {
    primary_id: "D0A0FB",
    names: ["ZYOG1"],
  },
  {
    primary_id: "D0A0FG",
    names: ["NICANARTINE"],
  },
  {
    primary_id: "D0A0FI",
    names: ["FHT-401"],
  },
  {
    primary_id: "D0A0FK",
    names: ["Huprine Y", "CHEMBL140476", "Rac-Huprine H3", "BDBM10592", "BDBM50200340"],
  },
  {
    primary_id: "D0A0FL",
    names: [
      "Bupivacaine",
      "Anekain",
      "Bloqueina",
      "Bucaine",
      "Bupivacaina",
      "Bupivacainum",
      "Bupivan",
      "CBupivacaine",
      "Carbostesin",
      "DepoBupivacaine",
      "Marcaina",
      "Marcaine",
      "Sensorcaine",
      "Bupivacaine Carbonate",
      "Bupivacaine HCL",
      "Bupivacaine HCL KIT",
      "Marcaine HCL",
      "Marcaine Spinal",
      "AH 250",
      "Win 11318",
      "Win 11318 HCl",
      "Bucaine (TN)",
      "Bupivacaina [INN-Spanish]",
      "Bupivacaine (INN)",
      "Bupivacaine Monohydrochloride, Monohydrate",
      "Bupivacaine [INN:BAN]",
      "Bupivacainum [INN-Latin]",
      "DL-Bupivacaine",
      "DUR-843",
      "Marcain (TN)",
      "Marcaine (TN)",
      "Sensorcaine (TN)",
      "Sensorcaine-MPF",
      "Sensorcaine-MPFSpinal",
      "Transdur-Bupivacaine",
      "Vivacaine (TN)",
      "Dl-1-Butyl-2',6'-Pipecoloxylidide",
      "(1)-1-Butyl-N-(2,6-Dimethylphenyl)Piperidine-2-Carboxamide",
      "(Inverted Exclamation MarkA)-Bupivacaine",
      "1-Butyl-2',6'-Pipecoloxylidide",
      "1-Butyl-N-(2,6-Dimethylphenyl)-2-Piperidinecarboxamide",
      "1-Butyl-N-(2,6-Dimethylphenyl)Piperidine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0A0FY",
    names: ["N-Hydroxy-2-(4-Methoxy-Benzenesulfonyl)Benzamide", "CHEMBL575895"],
  },
  {
    primary_id: "D0A0GA",
    names: [
      "2,6-Bis(4-Methoxyphenyl)-9H-Purine",
      "CHEMBL437123",
      "889673-61-4",
      "CTK3A4136",
      "DTXSID00467553",
      "BDBM50186600",
      "1H-Purine, 2,6-Bis(4-Methoxyphenyl)-",
    ],
  },
  {
    primary_id: "D0A0GD",
    names: [
      "3-Hydroxy-Myristic Acid",
      "3-Hydroxymyristate",
      "3-Hydroxytetradecanoate",
      "Beta-Hydroxymyristate",
      "3-Hydroxy Myristic Acid",
      "3-Hydroxytetradecanoate(1-)",
    ],
  },
  {
    primary_id: "D0A0JH",
    names: ["Nafcillin"],
  },
  {
    primary_id: "D0A0JL",
    names: ["RG-50810"],
  },
  {
    primary_id: "D0A0JM",
    names: ["Carotegrast"],
  },
  {
    primary_id: "D0A0JU",
    names: [
      "A-315456",
      "CHEMBL362863",
      "N-[3-(Cyclohexylidene(1H-Imidazol-4-Ylmethyl)Phenyl]ethanesulfonamide",
      "NCGC00015076-01",
      "AC1O7FZN",
      "Lopac-A-6351",
      "Lopac0_000059",
      "MLS002153477",
      "CTK8F7477",
      "HMS3370M22",
      "HMS3260K19",
      "HMS2235H14",
      "ZINC9229227",
      "Tox21_500059",
      "BDBM50160163",
      "LP00059",
      "CCG-204154",
      "NCGC00093573-02",
      "NCGC00015076-03",
      "NCGC00093573-01",
      "NCGC00015076-02",
      "NCGC00260744-01",
      "NCGC00015076-04",
      "SMR001230830",
      "EU-0100059",
      "A-315456, &gt",
      "A 6351",
      "SR-01000076218",
      "SR-01000076218-1",
    ],
  },
  {
    primary_id: "D0A0KE",
    names: ["Norzimelidine"],
  },
  {
    primary_id: "D0A0KP",
    names: ["Zinterol"],
  },
  {
    primary_id: "D0A0KS",
    names: ["C(RGDfV)"],
  },
  {
    primary_id: "D0A0LE",
    names: ["3-(2-Methylbenzo[d]thiazol-5-Yl)Benzonitrile", "CHEMBL244585"],
  },
  {
    primary_id: "D0A0LZ",
    names: ["(+/-)-Threo-N-Benzylmethylphenidate Amide", "CHEMBL1254596"],
  },
  {
    primary_id: "D0A0MJ",
    names: ["Nerve Growth Factor"],
  },
  {
    primary_id: "D0A0MS",
    names: ["BI 655066"],
  },
  {
    primary_id: "D0A0MT",
    names: ["KRL-103"],
  },
  {
    primary_id: "D0A0MU",
    names: ["FOZIVUDINE TIDOXIL"],
  },
  {
    primary_id: "D0A0NZ",
    names: ["LP-02"],
  },
  {
    primary_id: "D0A0OJ",
    names: ["4,5,-IP2", "1D-Myo-Inositol 4,5-Bisphosphate"],
  },
  {
    primary_id: "D0A0OO",
    names: [
      "Amprenavir",
      "AMV",
      "Agenerase",
      "Amprenavir[usan]",
      "Prozei",
      "Vertex",
      "Amprenavir [USAN]",
      "VX 478",
      "VX478",
      "Vertex VX478",
      "Agenerase (TM)",
      "Agenerase (TN)",
      "DRG-0258",
      "GNA & Amprenavir",
      "HHA & Amprenavir",
      "KVX-478",
      "VX-478",
      "Amprenavir (JAN/USAN/INN)",
      "Tetrahydro-3-Furyl N-(3-(4-Amino-N-Isobutylbenzenesulfonamido)-1-Benzyl-2-Hydroxypropyl)Carbamate",
      "{3-[(4-AMINO-BENZENESULFONYL)-ISOBUTYL-AMINO]-1-BENZYL-2-HYDROXY-PROPYL}-CARBAMIC ACID TETRAHYDRO-FURAN-3-YL ESTER",
      "[(3S)-Oxolan-3-Yl] N-[(2S,3R)-4-[(4-Aminophenyl)Sulfonyl-(2-Methylpropyl)Amino]-3-Hydroxy-1-Phenylbutan-2-Yl]carbamate",
      "Carbamic Acid, ((1S,2R)-3-(((4-Aminophenyl)Sulfonyl)(2-Methylpropyl)Amino)-2-Hydroxy-1-(Phenylmethyl)Propyl)-, (3S)-Tetrahydro-3-Furanyl Ester",
      "Carbamic Acid, ((1S,2R)-3-(((4-Aminophenyl)Sulfonyl)(2-Methylpropyl)Amino)-2-Hydroxy-1-(Phenylmethyl)Propyl)-, (3S)-Tetrahydro-3-Furanyl Ester & Galanthus Nivalis Agglutinin (GNA)",
      "Carbamic Acid, ((1S,2R)-3-(((4-Aminophenyl)Sulfonyl)(2-Methylpropyl)Amino)-2-Hydroxy-1-(Phenylmethyl)Propyl)-, (3S)-Tetrahydro-3-Furanyl Ester & Hippeastrum Hybrid Agglutinin(HHA)",
      "(3S)-Tetrahydro-3-Furanyl ((1S,2R)-3-(((4-Aminophenyl)Sulfonyl)(2-Methylpropyl)Amino)-2-Hydroxy-1-(Phenylmethyl)Propyl)Carbamate",
      "(3S)-Tetrahydro-3-Furyl ((AlphaS)-Alpha-((1R-1-Hydroxy-2-(N(Sup 1)-Isobutylsulfanilamido)Ethyl)Phenethyl)Carbamate",
      "(3S)-Tetrahydro-3-Furyl N-[(1S,2R)-3-(4-Amino-N-Isobutylbenzenesulfonamido)-1-Benzyl-2-Hydroxy-Propyl]carbamate",
      "(3S-(3R*(1R*,2S*)))-(3-(((4-Aminophenyl)Sulfonyl)(2-Methylpropyl)Amino)-2-Hydroxy-1-(Phenylmethyl)Propyl) Tetrahydro-3-Furanyl Carbamate",
      "4-Amino-N-((2 Syn,3S)-2-Hydroxy-4-Phenyl-3-((S)-Tetrahydrofuran-3-Yloxycarbonylamino)-Butyl)-N-Isobutyl-Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0A0QH",
    names: ["Stapuldencel-T"],
  },
  {
    primary_id: "D0A0QQ",
    names: ["Piperidinyl Triazole Derivative 5"],
  },
  {
    primary_id: "D0A0RM",
    names: ["PMID26815044-Compound-51"],
  },
  {
    primary_id: "D0A0RO",
    names: ["N~3~-BENZYLPYRIDINE-2,3-DIAMINE"],
  },
  {
    primary_id: "D0A0RR",
    names: [
      "ADENOPHOSTIN A",
      "Adenophostin A",
      "149091-92-9",
      "Adenophostin",
      "CHEMBL204385",
      "AC1L3X4M",
      "CHEBI:34524",
      "2'-Adenylic Acid, 3'-O-(3,4-Di-O-Phosphono-Alpha-D-Glucopyranosyl)-",
      "C16H26N5O18P3",
      "GTPL4124",
      "DTXSID10164167",
      "BDBM50184325",
      "LS-173343",
      "C13727",
      "2-[5-(6-Amino-9H-9-Purinyl)-2-Hydroxymethyl-4-Oxyphosphate-(2R,3R,4R,5R)-Tetrahydro-3-Furanyloxy]-6-Hydroxymethyl-4,5-Dioxyphosphate-(2R,3R,4S,5R,6R)-Tetrahydro-2H-3-Pyranol",
    ],
  },
  {
    primary_id: "D0A0RT",
    names: [
      "Di(2,6-Diisopropylphenol)",
      "3,3',5,5'-Tetraisopropylbiphenyl-4,4'-Diol",
      "2416-95-7",
      "Dipropofo",
      "UNII-H9GE6HX42A",
      "H9GE6HX42A",
      "CHEMBL478518",
      "3,3',5,5'-Tetrakis(1-Methylethyl)Biphenyl-4,4'-Diol",
      "AK-51092",
      "3,3',5,5'-Tetraisopropyl-[1,1'-Biphenyl]-4,4'-Diol",
      "Propofol Related Compound A",
      "Dipropofol",
      "Propofol Related Compound A [USP]",
      "Propofol Specified Impurity E [EP]",
      "Propofol Impurity E",
      "Propofol Related Compound A RS [USP]",
      "3,3',5,5'-Tetraisopropyl-4,4'-Dihydroxybiphenyl",
      "SCHEMBL275427",
      "CTK4F3066",
      "DTXSID30178867",
    ],
  },
  {
    primary_id: "D0A0SI",
    names: ["BAY 85-8501"],
  },
  {
    primary_id: "D0A0SK",
    names: ["HPH-116", "Azocarbonamide", "Azodicarbonamide", "ADA, Hubriphar", "Azodicarbonamide, H-Phar"],
  },
  {
    primary_id: "D0A0SP",
    names: ["Pyrvinium"],
  },
  {
    primary_id: "D0A0TF",
    names: ["Tc-99m Ethylenedicysteine Glucosamine", "EC-G"],
  },
  {
    primary_id: "D0A0TI",
    names: [
      "TgAAC-09",
      "AAV-HIV Vaccine, Targeted Genetics",
      "AIDS Vaccine, Children Hospital/Targeted Genetics",
      "AIDS Vaccine, IAVI/Targeted Genetics",
      "HIV Vaccine, Targeted Genetics/IAVI/CCRI",
      "RAAV-Based Vaccine, Targeted Genetics/IAVI",
      "Vaccine (HIV), Childrens Hospital/Targeted Genetics",
      "Vaccine (HIV), Targeted Genetics/IAVI",
    ],
  },
  {
    primary_id: "D0A0TS",
    names: ["VesiculoVax"],
  },
  {
    primary_id: "D0A0TT",
    names: ["IONIS-AGT-LRX"],
  },
  {
    primary_id: "D0A0UC",
    names: ["Cyclopentyl-(3,4-Dichloro-Phenyl)-Acetonitrile", "CHEMBL39241", "SCHEMBL5903239", "BDBM50126930"],
  },
  {
    primary_id: "D0A0UL",
    names: ["15(S)-15-Methyl-PGD2", "BRN 5989341"],
  },
  {
    primary_id: "D0A0UR",
    names: ["1-Deoxygalactonojirimycin"],
  },
  {
    primary_id: "D0A0UU",
    names: ["RG7828"],
  },
  {
    primary_id: "D0A0UW",
    names: [
      "6-Methoxy-5-Oxazol-5-Yl-2-Phenyl-1H-Indole",
      "CHEMBL23590",
      "569352-88-1",
      "SCHEMBL5832036",
      "CTK1F3437",
      "DTXSID60623678",
      "BDBM50126004",
      "2-Phenyl-5-(5-Oxazolyl)-6-Methoxy-1H-Indole",
      "1H-Indole, 6-Methoxy-5-(5-Oxazolyl)-2-Phenyl-",
    ],
  },
  {
    primary_id: "D0A0UX",
    names: ["PMID28350212-Compound-35"],
  },
  {
    primary_id: "D0A0VB",
    names: ["ISIS 111118"],
  },
  {
    primary_id: "D0A0VH",
    names: ["PMID28394193-Compound-50"],
  },
  {
    primary_id: "D0A0VT",
    names: ["7-Propyl-7H-Adenine", "CHEMBL449108", "7H-Purin-6-Amine, 7-Propyl-", "BDBM50256892"],
  },
  {
    primary_id: "D0A0VU",
    names: ["PMID29865878-Compound-56"],
  },
  {
    primary_id: "D0A0YI",
    names: ["PMID25666693-Compound-34"],
  },
  {
    primary_id: "D0A0YQ",
    names: ["CX-8998"],
  },
  {
    primary_id: "D0A0YX",
    names: ["SACCHARIN"],
  },
  {
    primary_id: "D0A0YZ",
    names: ["PYZD-4409"],
  },
  {
    primary_id: "D0A0ZW",
    names: ["ARN-6039", "BOS172767"],
  },
  {
    primary_id: "D0A0ZX",
    names: ["Glutathionarsenoxide"],
  },
  {
    primary_id: "D0A0ZY",
    names: [
      "ALD-901",
      "ALD-916",
      "Pain Pathway Antagonist MAb, Alder Biopharmaceuticals",
      "Anti-NGF Monoclonal Antibody (Pain), Alder Biopharmaceuticals",
    ],
  },
  {
    primary_id: "D0A1AQ",
    names: ["Rosiglitazone + Metformin"],
  },
  {
    primary_id: "D0A1AS",
    names: ["Palivizumab"],
  },
  {
    primary_id: "D0A1CB",
    names: [
      "Cefepime",
      "Axepim",
      "CFPM",
      "Cefepima",
      "Cefepimum",
      "Cepimax",
      "Cepimex",
      "Maxcef",
      "Maxipime",
      "Cefepima [Spanish]",
      "Cefepimum [Latin]",
      "BMY 28142",
      "Axepim (TN)",
      "BMY-28142",
      "Cefepime [USAN:INN]",
      "Cepimax (TN)",
      "Cepimex (TN)",
      "Maxcef (TN)",
      "Maxipime (TN)",
      "Cefepime (USAN/INN)",
      "(6R,7R)-7-[[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-Methoxyiminoacetyl]amino]-3-[(1-Methylpyrrolidin-1-Ium-1-Yl)Methyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetyl]amino}-3-[(1-Methylpyrrolidinium-1-Yl)Methyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "1-(((6R,7R)-7-(2-(2-Amino-4-Thiazolyl)Glyoxylamido)-2-Carboxy-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-3-Yl)Methyl)-1-Methylpyrrolidinium Hydroxide, Inner Salt, 7(Sup 2)-(Z)-(O-Methyloxime)",
      "7beta-[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetamido]-3-[(1-Methylpyrrolidinium-1-Yl)Methyl]-3,4-Didehydrocepham-4-Carboxylate",
    ],
  },
  {
    primary_id: "D0A1CM",
    names: ["ELLAGIC ACID"],
  },
  {
    primary_id: "D0A1DE",
    names: ["MDL-28163"],
  },
  {
    primary_id: "D0A1DG",
    names: ["KO-947"],
  },
  {
    primary_id: "D0A1DH",
    names: [
      "Nitrendipine",
      "Baylotensin",
      "Bayotensin",
      "Baypress",
      "Bylotensin",
      "Deiten",
      "Gericin",
      "Jutapress",
      "Nidrel",
      "Niprina",
      "NitrePuren",
      "Nitregamma",
      "Nitrendepat",
      "Nitrendidoc",
      "Nitrendimerck",
      "Nitrendipin",
      "Nitrendipincorax",
      "Nitrendipino",
      "Nitrendipinum",
      "Nitrensal",
      "Nitrepin",
      "Nitrepress",
      "Tensogradal",
      "Trendinol",
      "Vastensium",
      "Nitre AbZ",
      "Nitre Puren",
      "Nitren Lich;Nitren Acis",
      "Nitrend KSK",
      "Nitrendi Biochemie",
      "Nitrendipin AL",
      "Nitrendipin Apogepha",
      "Nitrendipin Atid",
      "Nitrendipin Basics",
      "Nitrendipin Heumann",
      "Nitrendipin Lindo",
      "Nitrendipin Stada",
      "Nitrendipin Beta",
      "Nitrendipin Corax",
      "Nitrendipin Von Ct",
      "Nitrendipino Bayvit",
      "Nitren 1A Pharma",
      "AL, Nitrendipin",
      "AbZ, Nitre",
      "Acis, Nitren",
      "Atid, Nitrendipin",
      "Basics, Nitrendipin",
      "Baypress (TN)",
      "Bayvit, Nitrendipino",
      "Beta, Nitrendipin",
      "Biochemie, Nitrendi",
      "Ct, Nitrendipin Von",
      "Heumann, Nitrendipin",
      "KSK, Nitrend",
      "Lich, Nitren",
      "Lindo, Nitrendipin",
      "N-144",
      "Nitre-Puren",
      "Nitrendipin-Corax",
      "Pharma, Nitren 1A",
      "Stada, Nitrendipin",
      "Von Ct, Nitrendipin",
      "Bay-E-5009",
      "1A Pharma, Nitren",
    ],
  },
  {
    primary_id: "D0A1DT",
    names: ["ICI-245991"],
  },
  {
    primary_id: "D0A1DU",
    names: ["Pyrophosphate 2-"],
  },
  {
    primary_id: "D0A1DV",
    names: ["PMID30185082-Compound-28"],
  },
  {
    primary_id: "D0A1FB",
    names: [
      "RPA Gene Based Adenovirus 4 Vector Vaccine",
      "Anthrax Vaccine (Oral/Capsule), PaxVax",
      "RPA Gene Based Adenovirus 4 Vector Vaccine (Oral, Capsule, Anthrax)",
      "RPA Gene Based Adenovirus 4 Vector Vaccine (Oral, Capsule, Anthrax), PaxVax",
    ],
  },
  {
    primary_id: "D0A1FE",
    names: ["PMID25497965C17c"],
  },
  {
    primary_id: "D0A1FG",
    names: ["MTL-005"],
  },
  {
    primary_id: "D0A1FL",
    names: [
      "2-Phosphonooxy-Pentanedioic Acid",
      "CHEMBL302413",
      "2-Phosphonopentanedioic Acid",
      "SCHEMBL2201753",
      "BDBM50127620",
    ],
  },
  {
    primary_id: "D0A1GE",
    names: ["4-[(5-Methoxy-2-Methylphenoxy)Methyl]pyridine", "DB07428"],
  },
  {
    primary_id: "D0A1GJ",
    names: ["CY-9652"],
  },
  {
    primary_id: "D0A1GQ",
    names: ["Meso-DAP"],
  },
  {
    primary_id: "D0A1GR",
    names: ["CALAA-01"],
  },
  {
    primary_id: "D0A1HH",
    names: ["1-(1-Phenyl-2-O-Tolylethyl)Piperazine", "CHEMBL210028", "SCHEMBL5453605"],
  },
  {
    primary_id: "D0A1IL",
    names: ["VAL-083"],
  },
  {
    primary_id: "D0A1IR",
    names: ["DABCO-C16"],
  },
  {
    primary_id: "D0A1JP",
    names: [
      "3'-Methoxy-4'Hydroxyclomiphene",
      "CHEMBL955",
      "3-Methoxy-4-Hydroxyclomiphene",
      "3'-METHOXY-4'HYDROXYCLOMIPHENE",
      "AC1MIWC3",
      "SCHEMBL20193223",
      "3''-Methoxy-4''Hydroxyclomiphene",
      "BDBM50020285",
      "4-{2-Chloro-1-[4-(2-Diethylamino-Ethoxy)-Phenyl]-2-Phenyl-Vinyl}-2-Methoxy-Phenol",
      "Phenol, 4-(2-Chloro-1-(4-(2-(Diethylamino)Ethoxy)Phenyl)-2-Phenylethenyl)-2-Methoxy-",
      "4-[(Z)-2-Chloro-1-[4-(2-Diethylaminoethyloxy)Phenyl]-2-Phenylethenyl]-2-Methoxyphenol",
      "4-(2-Chloro-1-(4-(2-(Diethylamino)Ethoxy)Phenyl)-2-Phenylethenyl)-2-Methoxyphenol",
    ],
  },
  {
    primary_id: "D0A1KP",
    names: ["SWT-05105"],
  },
  {
    primary_id: "D0A1KR",
    names: ["JTK-652"],
  },
  {
    primary_id: "D0A1KU",
    names: ["PF-3274167"],
  },
  {
    primary_id: "D0A1LS",
    names: ["Genz-644282"],
  },
  {
    primary_id: "D0A1MK",
    names: ["HL-027"],
  },
  {
    primary_id: "D0A1MW",
    names: [
      "Aconitine",
      "302-27-2",
      "CHEBI:2430",
      "Acetylbenzoylaconine",
      "NSC56464",
      "20-Ethyl-3alpha,13,15alpha-Trihydroxy-1alpha,6alpha,16beta-Trimethoxy-4-(Methoxymethyl)Aconitane-8,14alpha-Diyl 8-Acetate 14-Benzoate",
      "16-Ethyl-1alpha,6alpha,19beta-Trimethoxy-4-(Methoxymethyl)-Aconitane-3alpha,8,10alpha,11,18alpha-Pentol, 8-Acetate 10-Benzoate",
      "NCGC00163146-01",
      "CAS-302-27-2",
      "DSSTox_RID_81535",
      "DSSTox_CID_26319",
      "SCHEMBL73323",
      "DSSTox_GSID_46319",
      "GTPL2617",
      "DTXSID4046319",
      "CHEMBL1979562",
      "MolPort-002-527-310",
      "Tox21_112015",
      "NSC-56464",
      "AKOS032428256",
    ],
  },
  {
    primary_id: "D0A1MX",
    names: [
      "Bapineuzumab",
      "AAB-001",
      "Monoclonal Antibody (Alzheimer's Disease), Elan/Pfizer",
      "Monoclonal Antibody (Alzheimer's Disease), Elan/Wyeth",
      "Monoclonal Antibody (Alzheimer's Disease), Wyeth/Elan",
    ],
  },
  {
    primary_id: "D0A1NU",
    names: ["ABT-279"],
  },
  {
    primary_id: "D0A1PS",
    names: ["PRT-105"],
  },
  {
    primary_id: "D0A1PW",
    names: ["Laevulinic Acid"],
  },
  {
    primary_id: "D0A1PX",
    names: [
      "Flurbiprofen",
      "Adfeed",
      "Adofeed",
      "Anmetarin",
      "Ansaid",
      "Anside",
      "Antadys",
      "Cebutid",
      "FLP",
      "Flubiprofen",
      "Flugalin",
      "Flurbiprofene",
      "Flurbiprofeno",
      "Flurbiprofenum",
      "Fluriproben",
      "Flurofen",
      "Froben",
      "Ocufen",
      "Ocuflur",
      "Stayban",
      "Yakuban",
      "Zepolas",
      "FLURBIPROFEN SODIUM",
      "Froben Sr",
      "BTS 18322",
      "F0371",
      "FP 70",
      "IN1332",
      "U 27182",
      "Ansaid (TN)",
      "Apo-Flurbiprofen",
      "BTS-18322",
      "Flurbiprofene [INN-French]",
      "Flurbiprofeno [INN-Spanish]",
      "Flurbiprofenum [INN-Latin]",
      "Froben (TN)",
      "MKS-11",
      "Novo-Flurprofen",
      "Nu-Flurbiprofen",
      "U 27,182",
      "U-27182",
      "L-790,330",
      "Flurbiprofen (JP15/USP/INN)",
      "Flurbiprofen [USAN:INN:BAN:JAN]",
      "[+/-]-2-Fluoro-Alpha-Methyl-4-Biphenylacetic Acid",
      "(+-)-2-(2-Fluoro-4-Biphenylyl)Propionic Acid",
      "(+-)-2-Fluoro-Alpha-Methyl-4-Biphenylacetic Acid",
      "(+/-)-2-Fluoro-Alpha-Methyl-4-Biphenylacetic Acid",
      "(+/-)-2-Fluoro-Alpha-Methyl[1,1&prime",
      "-Biphenyl]-4-Acetic Acid",
      "(1)-2-Fluoro-Alpha-Methyl(1,1'-Biphenyl)-4-Acetic Acid",
      "2-(2-Fluorobiphenyl-4-Yl)Propionic Acid",
      "2-(2-Fluoro-[1,1'-Biphenyl-4-Yl])Propanoic Acid",
      "2-(2-Fluorobiphenyl-4-Yl)Propanoic Acid",
      "2-(3-Fluoro-4-Phenylphenyl)Propanoic Acid",
      "2-Fluoro-Alpha-Methyl-(1,1'-Biphenyl)-4-Acetic Acid",
      "2-Fluoro-Alpha-Methyl-4-Biphenylacetic Acid",
      "3-Fluoro-4-Phenylhydratropic Acid",
    ],
  },
  {
    primary_id: "D0A1QC",
    names: ["Heparan Sulfate"],
  },
  {
    primary_id: "D0A1QJ",
    names: ["Tellurium"],
  },
  {
    primary_id: "D0A1QK",
    names: [
      "Isochroman Mono-Carboxylic Acid",
      "CHEMBL564124",
      "BDBM50296382",
      "(3S)-7-{4-[(3R)-1,2-Dithiolan-3-Yl]butylcarboxamido}-Isochroman-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0A1QQ",
    names: ["GalR3ant"],
  },
  {
    primary_id: "D0A1RE",
    names: [
      "4-Methyl-7-(2-Oxocyclopentyloxy)-2H-Chromen-2-One",
      "CHEMBL372943",
      "CTK7H3804",
      "BDBM50181856",
      "AKOS015965448",
      "4-Methyl-7-(2-Oxo-Cyclopentyloxy)-C",
    ],
  },
  {
    primary_id: "D0A1RG",
    names: [
      "RIP",
      "Beta-D-Ribopyranose",
      "Ribose(Pyranose Form)",
      "7296-60-8",
      "UNII-49X539P7H4",
      "CHEBI:27476",
      "49X539P7H4",
      "(2R,3R,4R,5R)-Oxane-2,3,4,5-Tetrol",
      "B-Ribopyranose",
      "115794-07-5",
      "Beta-D-Ribopyranose (9CI)",
      "1ogd",
      "1drk",
      "2dri",
      "1drj",
      "Ribopyranose, Beta-D-",
      "SCHEMBL625216",
      "AC1L9B91",
      "CHEMBL1159662",
      "CTK4A9488",
      "ZINC4097544",
      "DB04286",
      "1577-EP2301533A1",
      "1577-EP2292630A1",
      "1577-EP2316459A1",
      "1577-EP2305808A1",
      "1577-EP2281563A1",
      "C08353",
      "1577-EP2270015A1",
      "31308-EP2311817A1",
      "31308-EP2308858A1",
      "31308-EP2274983A1",
      "31308-EP2311816A1",
      "31308-EP2292088A1",
    ],
  },
  {
    primary_id: "D0A1RP",
    names: [
      "18F-Fluorothymidine",
      "3'-Deoxy-3'-[18F]fluorothymidine",
      "AC1L9P5W",
      "SCHEMBL10017180",
      "MOLI001306",
      "NSC743144",
      "NSC-743144",
      "Radiolabeled Form Of 3'-Deoxy-3'-Fluorothymidine (FLT) (NSC 140025)",
    ],
  },
  {
    primary_id: "D0A1SJ",
    names: ["ETHOXYCLUSIN"],
  },
  {
    primary_id: "D0A1SO",
    names: [
      "Benzaldehyde O-Benzoyloxime",
      "CHEMBL178391",
      "SCHEMBL3222745",
      "SCHEMBL3222751",
      "(Z)-Benzaldehyde O-Benzoyloxime",
    ],
  },
  {
    primary_id: "D0A1SP",
    names: ["COOH"],
  },
  {
    primary_id: "D0A1TG",
    names: ["Acetyl-L-Carnitine"],
  },
  {
    primary_id: "D0A1TK",
    names: [
      "VALERGEN-DS (Sublingual)",
      "Dermatophagoides Siboney Allergen Immunotherapy (Sublingual, Asthma), National Biopreparations Center",
    ],
  },
  {
    primary_id: "D0A1UB",
    names: ["N-Ethyl-1-Deoxynojirimycin"],
  },
  {
    primary_id: "D0A1UL",
    names: ["RQ-00203078"],
  },
  {
    primary_id: "D0A1VL",
    names: ["AR-R-1589"],
  },
  {
    primary_id: "D0A1VS",
    names: ["Safflower Oil"],
  },
  {
    primary_id: "D0A1WN",
    names: ["PF-03715455"],
  },
  {
    primary_id: "D0A1WQ",
    names: ["VU0364289"],
  },
  {
    primary_id: "D0A1WR",
    names: ["SR9238"],
  },
  {
    primary_id: "D0A1WS",
    names: ["ABBV-176"],
  },
  {
    primary_id: "D0A1XG",
    names: ["YRFB", "CHEMBL215513"],
  },
  {
    primary_id: "D0A1XH",
    names: ["2-(2,4-Dichlorophenoxy)-5-(2-Methylbutyl)Phenol", "CHEMBL259403", "Triclosan Derivative, 12", "BDBM25411"],
  },
  {
    primary_id: "D0A1XS",
    names: [
      "Colfosceril Palmitate",
      "DPPC",
      "Exosurf",
      "Exosurf Neonatal",
      "Exosurf Paedriatic",
      "Surfexo Neonatal",
      "BW-129Y83",
    ],
  },
  {
    primary_id: "D0A1XU",
    names: ["EVP-6308"],
  },
  {
    primary_id: "D0A1XX",
    names: ["Des-AA1,5-[Tyr2,D-Trp8,(NalphaMe)IAmp9]Cbm-SRIF", "CHEMBL262790"],
  },
  {
    primary_id: "D0A1YF",
    names: ["ABBV-3067"],
  },
  {
    primary_id: "D0A1YQ",
    names: [
      "SB216763",
      "280744-09-4",
      "SB 216763",
      "3-(2,4-Dichlorophenyl)-4-(1-Methyl-1H-Indol-3-Yl)-1H-Pyrrole-2,5-Dione",
      "SB-216763",
      "3-(2,4-Dichlorophenyl)-4-(1-Methylindol-3-Yl)Pyrrole-2,5-Dione",
      "1H-Pyrrole-2,5-Dione, 3-(2,4-Dichlorophenyl)-4-(1-Methyl-1H-Indol-3-Yl)-",
      "CHEMBL102714",
      "3-(2,4-Dichlorophenyl)-4-(1-Methyl-1H-Indol-3-Yl)-2,5-Dihydro-1H-Pyrrole-2,5-Dione",
      "SR-01000076052",
      "Tocris-1616",
      "Lopac-S-3442",
      "AC1L41YI",
      "Lopac0_000934",
      "SCHEMBL30251",
      "MLS001074894",
      "Cid_176158",
      "BDBM8296",
      "GTPL8015",
      "CTK8B4807",
      "CHEBI:91421",
      "AOB5004",
      "SYN1075",
      "EX-A291",
    ],
  },
  {
    primary_id: "D0A2AD",
    names: ["Prodrug Antibiotics"],
  },
  {
    primary_id: "D0A2AF",
    names: [
      "N-Methyl-N-Propargyl-1(R)-Aminoindan",
      "124192-87-6",
      "N-METHYL-N-PROPARGYL-1(R)-AMINOINDAN",
      "(R)-N-Methyl-N-(Prop-2-Yn-1-Yl)-2,3-Dihydro-1H-Inden-1-Amine",
      "(R)-2,3-Dihydro-N-Methyl-N-2-Propynyl-1H-Inden-1-Amine",
      "(R)-N-Methyl-N-(2-Propynyl)-2,3-Dihydroinden-1-Amine",
      "UNII-1Y7XS87IZR",
      "1Y7XS87IZR",
      "CHEMBL436947",
      "(1R)-N-Methyl-N-(Prop-2-Yn-1-Yl)-2,3-Dihydro-1H-Inden-1-Amine",
      "N-[(1S)-2,3-DIHYDRO-1H-INDEN-1-YL]-N-METHYL-N-PROP-2-YNYLAMINE",
      "R-MPAI",
      "N-Methyl-N-2-Propynyl-1-Indanamine, (R)-",
      "AC1NRD15",
      "1H-Inden-1-Amine,2,3-Dihydro-N-Methyl-N-2-Propyn-1-Yl-, (1R)-",
    ],
  },
  {
    primary_id: "D0A2AJ",
    names: ["Arglabin", "84692-91-1", "AC1LCSV4", "SCHEMBL15527919", "DTXSID30233687", "ZINC6634471", "ACN-035850"],
  },
  {
    primary_id: "D0A2BC",
    names: ["JWH-385"],
  },
  {
    primary_id: "D0A2BE",
    names: [
      "CLEBOPRIDE",
      "Clebopride",
      "55905-53-8",
      "Cleboril",
      "Clebopridum [INN-Latin]",
      "Cleboprida [INN-Spanish]",
      "4-Amino-N-(1-Benzylpiperidin-4-Yl)-5-Chloro-2-Methoxybenzamide",
      "UNII-I0A84520Y9",
      "LAS 9273",
      "EINECS 259-885-9",
      "C20H24ClN3O2",
      "BRN 0493934",
      "CHEMBL325109",
      "I0A84520Y9",
      "4-Amino-N-(1-Benzyl-4-Piperidyl)-5-Chloro-O-Anisamide",
      "Clebopride Malate",
      "4-Amino-5-Chloro-2-Methoxy-N-(1-Benzyl-4-Piperidyl)Benzamide",
      "Clebopridum",
      "Cleboprida",
      "N-(1'-Benzyl-4'-Piperidyl)-2-Methoxy-4-Amino-5-Chlorobenzamide",
      "BENZAMIDE, 4-AMINO-5-CHLORO-2-METHOXY-N-(1-(PHENYLMETHYL)-4",
    ],
  },
  {
    primary_id: "D0A2BK",
    names: ["KM-391"],
  },
  {
    primary_id: "D0A2BO",
    names: ["S-110", "DNA Demethylating Agent (Myelodysplastic Syndrome), Supergen"],
  },
  {
    primary_id: "D0A2BS",
    names: ["SelG2"],
  },
  {
    primary_id: "D0A2BX",
    names: ["Amidopyrazole Derivative 3"],
  },
  {
    primary_id: "D0A2CP",
    names: [
      "Emtricitabine/Tenofovir Alafenamide",
      "Descovy",
      "Emtricitabine / Tenofovir Alafenamide",
      "Tenofovir Alafenamide / Emtricitabine",
      "Emtricitabine And Tenofovir Alafenamide",
      "Tenofovir Alafenamide And Emtricitabine",
      "Emtricitabine Mixture With Tenofovir Alafenamide",
      "731772-56-8",
    ],
  },
  {
    primary_id: "D0A2EE",
    names: [
      "3-Hydroxy-4-Phenyl-5H-Furan-2-One",
      "CHEMBL295478",
      "6362-76-1",
      "3-Hydroxy-4-Phenylfuran-2(5H)-One",
      "CTK2A8725",
      "DTXSID60446001",
      "3-Hydroxy-4-Phenyl-2(5h)-Furanone",
      "2(5H)-Furanone, 3-Hydroxy-4-Phenyl-",
    ],
  },
  {
    primary_id: "D0A2FM",
    names: ["6-(1H-Indole-2-Sulfonyl)-2H-Pyridazin-3-One"],
  },
  {
    primary_id: "D0A2FO",
    names: ["(+/-)-7-Methoxy-2-Phenylchroman-4-One", "7-Methoxy-2-Phenylchroman-4-One"],
  },
  {
    primary_id: "D0A2GB",
    names: ["3-(4-Amino-Phenyl)-3-Pentyl-Piperidine-2,6-Dione", "CHEMBL25735"],
  },
  {
    primary_id: "D0A2GI",
    names: ["LY334362"],
  },
  {
    primary_id: "D0A2GM",
    names: ["Heparin Calcium", "Calciparine"],
  },
  {
    primary_id: "D0A2GN",
    names: ["3-[5-(3-Fluorophenyl)-2-Thienyl]phenol", "CHEMBL576318", "SCHEMBL1179817"],
  },
  {
    primary_id: "D0A2HK",
    names: [
      "Beta-Catenin/Tcf-4 Pathway Inhibitors",
      "Beta-Catenin/Tcf-4 Pathway Inhibitors (Colorectal Cancer)",
      "Beta-Catenin/Tcf-4 Pathway Inhibitors (Colorectal Cancer), Pfizer",
    ],
  },
  {
    primary_id: "D0A2HR",
    names: ["Silver Nitrate"],
  },
  {
    primary_id: "D0A2HS",
    names: ["T-3.5"],
  },
  {
    primary_id: "D0A2IH",
    names: [
      "KU-1257",
      "Dalcotidine",
      "120958-90-9",
      "UNII-9968S2UKFJ",
      "KU 1257",
      "9968S2UKFJ",
      "NCGC00182982-01",
      "Dalcotidine [INN]",
      "Dalcotidine (JAN/INN)",
      "AC1L2VE9",
      "DSSTox_RID_83001",
      "DSSTox_CID_28732",
      "DSSTox_GSID_48806",
      "CHEMBL311206",
      "SCHEMBL1815082",
      "N-Ethyl-N'-(3-(3-(Piperidinomethyl)Phenoxy)Propyl)Urea",
      "DTXSID4048806",
      "CHEBI:31454",
      "1-Ethyl-3-[3-[3-(Piperidin-1-Ylmethyl)Phenoxy]propyl]urea",
      "ZINC1889603",
      "Tox21_113283",
      "BDBM50406670",
      "CAS-120958-90-9",
      "D01698",
      "L002431",
      "A1-04840",
      "1-Ethyl-3-(3-((Alpha-Piperidino-M-Tolyl)Oxy)Propyl)Urea",
      "Urea, N-Ethyl-N'-(3-(3-(1-P",
    ],
  },
  {
    primary_id: "D0A2IK",
    names: ["NPS-846"],
  },
  {
    primary_id: "D0A2IQ",
    names: [
      "3'-Azido-3'-Deoxythymidine-5'-Monophosphate",
      "AZTMP",
      "Zidovudine 5'-Monophosphate",
      "UNII-97YMU05VPJ",
      "ZDVMP",
      "AZT-MP",
      "97YMU05VPJ",
      "Zidovudine Monophosphate",
      "3'-Azido-3'-Deoxythymidine 5'-Phosphate",
      "29706-85-2",
      "5'-Thymidylic Acid, 3'-Azido-3'-Deoxy-",
      "Zdv 5'-Monophosphate",
      "AzddTMP",
      "3'-Azido-3'-Deoxythymidine Monophosphate",
      "AZT-Monophosphate",
      "3'-Azido-2',3'-Deoxythymidine 5'-Monophosphate",
      "NSC742230",
      "3'-Azido-3'-Deoxythymidine 5'phosphate",
      "AC1Q6RVL",
      "[(2S,3S,5R)-3-Azido-5-(5-Methyl-2,4-Dioxo-Pyrimidin-1-Yl)Tetrahydrofuran-2-Yl]methyl D",
    ],
  },
  {
    primary_id: "D0A2JV",
    names: ["NSC-26699"],
  },
  {
    primary_id: "D0A2KH",
    names: ["TRK-170"],
  },
  {
    primary_id: "D0A2KL",
    names: ["OXS-1550"],
  },
  {
    primary_id: "D0A2KN",
    names: ["[125I]L750667", "[125I]-L750667"],
  },
  {
    primary_id: "D0A2KT",
    names: [
      "4-DAMP",
      "4-Diphenylacetoxy-1,1-Dimethylpiperidinium",
      "CHEMBL168067",
      "81405-11-0",
      "CHEBI:73467",
      "CHEMBL76897",
      "4-Damp Methobromide",
      "C21H26NO2",
      "Tocris-0482",
      "Lopac-D-104",
      "AC1Q60WY",
      "AC1L1C4C",
      "4-[(Diphenylacetyl)Oxy]-1,1-Dimethylpiperidinium",
      "Lopac0_000407",
      "GTPL307",
      "SCHEMBL2730650",
      "1952-15-4 (Iodide)",
      "CTK3E9884",
      "DTXSID70231086",
      "4-DAMP(1+)",
      "ZINC2555356",
      "BDBM50176065",
      "CCG-204500",
      "NCGC00024611-02",
      "NCGC00015304-04",
      "NCGC00015304-03",
      "NCGC00163244-01",
      "NCGC00024611-01",
      "NCGC00015304-02",
      "NCGC00015304-01",
      "LS-177624",
      "N,N-Dimethyl-4-(Diphenylacetoxy)Piperidinium",
    ],
  },
  {
    primary_id: "D0A2KW",
    names: ["1-(Bis(4-Bromophenyl)Methyl)-3-Phenylurea", "CHEMBL224748"],
  },
  {
    primary_id: "D0A2LP",
    names: ["2-Methyl-7-M-Tolyl-1,8-Naphthyridine", "CHEMBL235629"],
  },
  {
    primary_id: "D0A2LX",
    names: ["Y-C[D-Pen-(3-I)Tyr-GSFC]KR-NH2", "CHEMBL436961"],
  },
  {
    primary_id: "D0A2MA",
    names: ["FR-104", "Anti-CD28 (PEGylated Formulation, Transplant Rejection/Autoimmune Disease), TcL Pharma"],
  },
  {
    primary_id: "D0A2NA",
    names: ["PMID27998201-Compound-11"],
  },
  {
    primary_id: "D0A2NH",
    names: ["IDEC-131"],
  },
  {
    primary_id: "D0A2NK",
    names: ["PYRIPYROPENE A"],
  },
  {
    primary_id: "D0A2NT",
    names: ["PMID25522065-Compound-46"],
  },
  {
    primary_id: "D0A2OH",
    names: [
      "Cudraxanthone L",
      "135541-40-1",
      "CHEMBL197916",
      "MolPort-035-706-469",
      "BDBM50175013",
      "ZINC13412042",
      "AKOS032948133",
      "W2632",
      "1,3,6,7-Tetrahydroxy-5-(3-Methylbut-2-Enyl)-2-(2-Methylbut-3-En-2-Yl)-9H-Xanthen-9-One",
    ],
  },
  {
    primary_id: "D0A2OL",
    names: ["ISIS 114390"],
  },
  {
    primary_id: "D0A2OW",
    names: ["Otenzepad"],
  },
  {
    primary_id: "D0A2PA",
    names: ["IMF-001"],
  },
  {
    primary_id: "D0A2PD",
    names: [
      "3-Mercuri-4-Aminobenzenesulfonamide",
      "(2-Amino-5-Sulfamoylphenyl)Mercury",
      "AC1L4WFQ",
      "SCHEMBL678556",
      "CTK7D7985",
      "CHEBI:49484",
      "[2-Amino-5-(Sulfamoyl)Phenyl]mercury",
      "DB04203",
    ],
  },
  {
    primary_id: "D0A2PN",
    names: ["ISIS 9606"],
  },
  {
    primary_id: "D0A2PY",
    names: ["Pyrazole Derivative 35"],
  },
  {
    primary_id: "D0A2QP",
    names: ["N-Hydroxy-N-Methyl-Benzamide"],
  },
  {
    primary_id: "D0A2RA",
    names: ["TAK-491"],
  },
  {
    primary_id: "D0A2RF",
    names: ["KI-0906"],
  },
  {
    primary_id: "D0A2RG",
    names: ["Conjugated Estrogens A", "Conestin (TN)"],
  },
  {
    primary_id: "D0A2RI",
    names: ["PMID26815044-Compound-127"],
  },
  {
    primary_id: "D0A2RX",
    names: ["19-28z T CELLS"],
  },
  {
    primary_id: "D0A2SA",
    names: [
      "10-Allyl-10H-Dibenzo[b,f][1,4]oxazepin-11-One",
      "10Allyl-DBOA-11one",
      "10-Ally-Dibenz(B,f)(1,4)Oxazepin-11-(10H)-One",
      "135810-46-7",
      "10-Ally-Dibenz[b,f][1,4]oxazepin-11-(10H)-One",
      "Dibenzoxazepinone 11",
      "AC1L9R9L",
      "CHEMBL301443",
      "BDBM1972",
      "DTXSID50159537",
      "5-Allylbenzo[b][1,4]benzoxazepin-6-One",
      "5-Prop-2-Enylbenzo[b][1,4]benzoxazepin-6-One",
      "10-(2-Propenyl)Dibenz[b,f][1,4]oxazepin-11(10H)-One",
    ],
  },
  {
    primary_id: "D0A2SK",
    names: [
      "(Z)-1-(Pyridazin-3-Yl)Octadec-9-En-1-One",
      "CHEMBL405089",
      "SCHEMBL3967504",
      "BDBM50374371",
      "(9z)-1-Pyridazin-3-Yloctadec-9-En-1-One",
    ],
  },
  {
    primary_id: "D0A2SY",
    names: ["[3H]GR 125,743", "[3H]GR125743"],
  },
  {
    primary_id: "D0A2TJ",
    names: ["PBOX-15"],
  },
  {
    primary_id: "D0A2UR",
    names: ["PMID29130358-Compound-Figure10(2)"],
  },
  {
    primary_id: "D0A2UV",
    names: [
      "Ustekinumab",
      "6,6-Dimethyl-1-[3-(2,4,5-Trichlorophenoxy)Propoxy]-1,6-Dihydro-1,3,5-Triazine-2,4-Diamine",
      "WR99210",
      "WR-99210",
      "6,6-DIMETHYL-1-[3-(2,4,5-TRICHLOROPHENOXY)PROPOXY]-1,6-DIHYDRO-1,3,5-TRIAZINE-2,4-DIAMINE",
      "47326-86-3",
      "BRN 0629517",
      "WR 99210",
      "1,3,5-Triazine-2,4-Diamine, 1,6-Dihydro-6,6-Dimethyl-1-[3-(2,4,5-Trichlorophenoxy)Propoxy]-",
      "WR-99,210",
      "CHEMBL129788",
      "BRL 6231",
      "1,6-Dihydro-6,6-Dimethyl-1-(3-(2,4,5-Trichlorophenoxy)-Propoxy)-1,3,5-Triazine-2 ,4-Diamine",
      "1-(2',4',5'-Trichlorophenoxypropoxy)-1,2-Dihydro-2,2-Dimethyl-4,6-Diamino-S-Triazine",
      "Stelara (TN)",
    ],
  },
  {
    primary_id: "D0A2UX",
    names: ["SITS", "4'-Isothiocyanostilbene-2,2'-Disulphonic Acid"],
  },
  {
    primary_id: "D0A2VQ",
    names: ["PMID25772215-Compound-US02014179750M2"],
  },
  {
    primary_id: "D0A2VZ",
    names: ["D-3252"],
  },
  {
    primary_id: "D0A2WE",
    names: ["Syntocinon Nasal Spray"],
  },
  {
    primary_id: "D0A2WH",
    names: ["1-Butyl-3,7-Dihydro-Purine-2,6-Dione", "1-Butylxanthine", "CHEMBL68278", "SCHEMBL6233166"],
  },
  {
    primary_id: "D0A2WT",
    names: [
      "4-((2R,3S)-2-Methyl-Pyrrolidin-3-Yl)-1H-Imidazole",
      "CHEMBL18735",
      "Immepyr",
      "1H-Imidazole, 4-[(2R,3S)-2-Methyl-3-Pyrrolidinyl]-",
      "BDBM50034708",
    ],
  },
  {
    primary_id: "D0A2XD",
    names: ["TAK-065"],
  },
  {
    primary_id: "D0A2XV",
    names: ["HL-162"],
  },
  {
    primary_id: "D0A2XY",
    names: ["AGB101"],
  },
  {
    primary_id: "D0A2YG",
    names: ["N-Methyl-1-(2-Thiophen-2-Ylphenyl)Methanamine"],
  },
  {
    primary_id: "D0A2ZH",
    names: ["NSC-106084"],
  },
  {
    primary_id: "D0A2ZO",
    names: ["Phenoxypiperidine Derivative 2"],
  },
  {
    primary_id: "D0A2ZX",
    names: [
      "Metronidazole",
      "APMN",
      "Acromona",
      "Anagiardil",
      "Arilin",
      "Atrivyl",
      "Bexon",
      "CONT",
      "Clont",
      "Danizol",
      "Deflamon",
      "Efloran",
      "Elyzol",
      "Entizol",
      "Eumin",
      "Flagemona",
      "Flagesol",
      "Flagil",
      "Flagyl",
      "Flegyl",
      "Florazole",
      "Fossyol",
      "Giatricol",
      "Gineflavir",
      "Giniflavir",
      "Klion",
      "Klont",
      "Meronidal",
      "Methronidazole",
      "Metric",
      "MetroCream",
      "MetroGel",
      "MetroLotion",
      "Metrodzhil",
      "Metrogyl",
      "Metrolag",
      "Metrolyl",
      "Metromidol",
      "Metronidaz",
      "Metronidazol",
      "Metronidazolo",
      "Metronidazolum",
      "Metrotop",
      "Metrozine",
      "Metryl",
      "Mexibol",
      "Monagyl",
      "Monasin",
      "NIDA",
      "Nalox",
      "Nidagel",
      "Noritate",
      "Novonidazol",
      "Orvagil",
      "Protostat",
      "Rathimed",
      "Rosased",
      "Sanatrichom",
      "Satric",
      "Takimetol",
      "Trichazol",
      "Trichex",
      "Trichocide",
      "Trichomol",
      "Trichopal",
      "Trichopol",
      "Tricocet",
      "Tricom",
      "Trikacide",
      "Trikamon",
      "Trikojol",
      "Trikozol",
      "Trimeks",
      "Trivazol",
      "Vagilen",
      "Vagimid",
      "Vandazole",
      "Vertisal",
      "Wagitran",
      "Zadstat",
      "Zidoval",
      "Flagyl Er",
      "Ginefla Vir",
      "METRONIDAZOLE USP",
      "Metro Cream",
      "Metro Gel",
      "Metronidazole Monohydrochloride",
      "Metronidazole Phosphoester",
      "Metronidazole In Plastic Container",
      "Metronidazolo [DCIT]",
      "Tricho Cordes",
      "Trichomonacid Pharmachim",
      "Tricowas B",
      "Bayer 5360",
      "Flagyl 375",
      "M0924",
      "Metric 21",
      "RP 8823",
      "SC 10295",
      "Anabact (TN)",
      "Apo-Metronidazole",
      "Deflamon-Wirkstoff",
      "Flagyl (TN)",
      "IDR-90105",
      "METRO I.V",
      "MetroGel (TN)",
      "MetroGel-Vaginal",
      "Metronidazol [INN-Spanish]",
      "Metronidazolum [INN-Latin]",
      "Mexibol (Silanes)",
      "Neo-Tric",
      "Noritate (TN)",
      "RP-8823",
      "Rozex (TN)",
      "Tricho-Gynaedron",
      "ZERO/004064",
      "CB-01-14 MMX",
      "FLAGYL I.V. RTU IN PLASTIC CONTAINER",
      "Flagyl I.V.",
      "Flagyl I.V. RTU",
      "Metro I.V.",
      "Metro I.V. In Plastic Container",
      "Metrogel-Vaginal (TN)",
      "Metronidazole (JP15/USP/INN)",
      "Metronidazole [USAN:INN:BAN:JAN]",
      "Methyl-5-Nitroimidazole-1-Ethanol",
      "1-Hydroxyethyl-2-Methyl-5-Nitroimidazole",
      "2-(2-Methyl-5-Nitroimidazol-1-Yl)Ethanol",
      "2-Methyl-5-Nitro-1-Imidazoleethanol",
      "2-Methyl-5-Nitroimidazole-1-Ethanol",
      "MNZ",
    ],
  },
  {
    primary_id: "D0A3AB",
    names: ["NCY-118"],
  },
  {
    primary_id: "D0A3AJ",
    names: ["4SC-301"],
  },
  {
    primary_id: "D0A3CB",
    names: [
      "2-Amino-6-(2-Chloro-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(2-Chlorophenylthio)Benzenecarbonitrile",
      "AC1LAE0H",
      "CHEMBL300757",
      "BDBM1738",
      "CTK7C6441",
      "ZINC5933966",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1h",
      "2-Amino-6-[(2-Chlorophenyl)Thio]benzonitrile",
      "2-Amino-6-(2-Chlorophenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(2-Chlorophenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D0A3CF",
    names: ["MK-1248"],
  },
  {
    primary_id: "D0A3CH",
    names: ["GRN-1201"],
  },
  {
    primary_id: "D0A3CL",
    names: ["AZD1979"],
  },
  {
    primary_id: "D0A3CW",
    names: ["Oleylphosphocholine"],
  },
  {
    primary_id: "D0A3DE",
    names: ["[3H]meclinertant", "[3H]-SR48692", "[3H]reminertant", "[3H]SR-48692"],
  },
  {
    primary_id: "D0A3EA",
    names: ["Mu5B3"],
  },
  {
    primary_id: "D0A3ES",
    names: [
      "OX-51",
      "Alfentanil Hydrochloride",
      "Rapifen",
      "Alfentanil HCl",
      "69049-06-5",
      "UNII-333JTI7A2M",
      "Alfentanil Hydrochloride [USAN]",
      "EINECS 273-846-3",
      "R 39209",
      "333JTI7A2M",
      "MLS002320667",
      "Propanamide, N-(1-(2-(4-Ethyl-4,5-Dihydro-5-Oxo-1H-Tetrazol-1-Yl)Ethyl)-4-(Methoxymethyl)-4-Piperidinyl)-N-Phenyl-, Monohydrochloride",
      "N-(1-(2-(4-Ethyl-4,5-Dihydro-5-Oxo-1H-Tetrazol-1-Yl)Ethyl)-4-(Methoxymethyl)Piperidin-4-Yl)-N-Phenylpropionamide Hydrochloride",
      "NCGC00247354-02",
      "SMR001338813",
      "Fentalim",
      "Alfentanyl Hydrochloride",
      "AC1L21VB",
      "DSSTox_RID_83122",
    ],
  },
  {
    primary_id: "D0A3EX",
    names: ["GRC-15300"],
  },
  {
    primary_id: "D0A3FF",
    names: ["Alpha-MDG", "Alpha-Methyl-D-Glucose Pyranoside", "Methyl Alpha-D-Glucopyranoside"],
  },
  {
    primary_id: "D0A3FQ",
    names: ["Rabies Vaccine"],
  },
  {
    primary_id: "D0A3FZ",
    names: ["Rilimogene Galvacirepvec"],
  },
  {
    primary_id: "D0A3HB",
    names: [
      "Propofol",
      "Ampofol",
      "Aquafol",
      "Diisopropylphenol",
      "Dipravan",
      "Diprivan",
      "Disoprivan",
      "Disoprofol",
      "Fresofol",
      "Ivofol",
      "PFL",
      "Propofolum",
      "Rapinovet",
      "Recofol",
      "Abbott Brand Of Propofol",
      "Alpha Brand Of Propofol",
      "Astra Brand Of Propofol",
      "AstraZeneca Brand Of Propofol",
      "Braun Brand Of Propofol",
      "Curamed Brand Of Propofol",
      "Diprivan Injectable Emulsion",
      "Fresenius Brand Of Propofol",
      "Fresenius Kabi Brand Of Propofol",
      "Juste Brand Of Propofol",
      "Parnell Brand Of Propofol",
      "Pisa Brand Of Propofol",
      "Propofol Abbott",
      "Propofol Fresenius",
      "Propofol MCT",
      "Propofol Rovi",
      "Propofolum [Latin]",
      "Rovi Brand Of Propofol",
      "Schering Brand Of Propofol",
      "Zeneca Brand Of Propofol",
      "AM-149",
      "DDS-04F",
      "Diprivan (TN)",
      "Propofol IDD-D",
      "Propofol-Lipuro",
      "ZD-0859",
      "Ghl.PD_Mitscher_Leg0.558",
      "Propofol [USAN:INN:BAN]",
      "Propofol (JAN/USAN/INN)",
      "Propofol(2,6-Diisopropylphenol)",
      "2, 6-Diisopropylphenol",
      "2,6 Diisopropylphenol",
      "2,6-Bis(1-Methylethyl)Phenol",
      "2,6-Bis(Isopropyl)-Phenol",
      "2,6-DIISOPROPYLPHENOL",
      "2,6-Diisopropyl Phenol",
      "2,6-Diisopropylphenol",
      "2,6-Bis(1-Methylethyl)-Phenol",
      "2,6-Di(Propan-2-Yl)Phenol",
    ],
  },
  {
    primary_id: "D0A3HF",
    names: ["VE-16084"],
  },
  {
    primary_id: "D0A3HQ",
    names: ["Antibiotics"],
  },
  {
    primary_id: "D0A3JC",
    names: ["DNA Vaccines", "DNA Vaccines (Cancer)"],
  },
  {
    primary_id: "D0A3JJ",
    names: ["Pentacea"],
  },
  {
    primary_id: "D0A3JR",
    names: [
      "4-(2-Phenylacetamido)-3-Chlorobenzenesulfonamide",
      "BRN 2157920",
      "N-(4-(Aminosulfonyl)-2-Chlorophenyl)Benzeneacetamide",
      "3-Chloro-4-Phenacetamido-Benzenesulfonamide",
      "Benzeneacetamide, N-(4-(Aminosulfonyl)-2-Chlorophenyl)-",
      "68252-75-5",
      "AC1MHICQ",
      "Sulfonamide Deriv., 7e",
      "SCHEMBL8222515",
      "CHEMBL583971",
      "CTK5C7613",
      "BDBM35737",
      "DTXSID70218418",
      "LS-28479",
      "3-Chloro-4-(Phenylacetylamino)Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0A3KA",
    names: ["Salvinorin B 2,2,2-Trifluoroethoxymethyl Ether", "CHEMBL272482"],
  },
  {
    primary_id: "D0A3LJ",
    names: ["Sul-Pro-Phe-N-MeHis-LVA-Ile-Amp"],
  },
  {
    primary_id: "D0A3LQ",
    names: ["Ar5Y_3"],
  },
  {
    primary_id: "D0A3MJ",
    names: ["XEN007"],
  },
  {
    primary_id: "D0A3MV",
    names: [
      "AR-A014418",
      "487021-52-3",
      "GSK-3beta Inhibitor VIII",
      "1-(4-Methoxybenzyl)-3-(5-Nitrothiazol-2-Yl)Urea",
      "1-[(4-Methoxyphenyl)Methyl]-3-(5-Nitro-1,3-Thiazol-2-Yl)Urea",
      "A Inhibitor VIII",
      "N-(4-METHOXYBENZYL)-N'-(5-NITRO-1,3-THIAZOL-2-YL)UREA",
      "UNII-87KSH90Q6D",
      "AR-AO 14418",
      "SN 4521",
      "AR-A 014418",
      "CHEMBL259850",
      "87KSH90Q6D",
      "N-[(4-Methoxyphenyl)Methyl]-N'-(5-Nitro-2-Thiazolyl)Urea",
      "AK175829",
      "C12H12N4O4S",
      "N-(4-Methoxybenzyl)-N&amp",
      "-(5-Nitro-1,3-Thiazol-2-Yl)Urea",
      "AR 014418",
      "GSK 3be",
    ],
  },
  {
    primary_id: "D0A3NN",
    names: ["Aroplatin"],
  },
  {
    primary_id: "D0A3OA",
    names: ["TGX-003"],
  },
  {
    primary_id: "D0A3OE",
    names: ["Anti-C-Met CAR-T Cells"],
  },
  {
    primary_id: "D0A3OF",
    names: [
      "GMC-283",
      "CHEMBL430798",
      "MolPort-042-665-651",
      "GMC-2-83",
      "ZINC14299682",
      "BDBM50078062",
      "Trifluoro-Methanesulfonic Acid 11-(4-Methyl-Piperazin-1-Yl)-Dibenzo[b,f][1,4]oxazepin-2-Yl Ester",
    ],
  },
  {
    primary_id: "D0A3OS",
    names: ["FLUINHIBIT"],
  },
  {
    primary_id: "D0A3PB",
    names: ["ISIS 29256"],
  },
  {
    primary_id: "D0A3PF",
    names: ["TVX-001"],
  },
  {
    primary_id: "D0A3PP",
    names: ["Biphenyl Mannoside Derivative 4"],
  },
  {
    primary_id: "D0A3QB",
    names: ["Polybrominated Diphenyl Ether Derivative", "Bastadin 7"],
  },
  {
    primary_id: "D0A3QM",
    names: [
      "1-(1-(Pyridin-3-Yl)Ethylidene)Thiosemicarbazide",
      "CHEMBL272957",
      "3-Acetylpyridine Thiosemicarbazone",
      "AC1NT9I3",
      "ZINC4535030",
      "13370-86-0",
      "BDBM50376203",
      "AKOS008939674",
    ],
  },
  {
    primary_id: "D0A3QO",
    names: ["Guanidinoethane Sulfonic Acid"],
  },
  {
    primary_id: "D0A3RM",
    names: ["ISIS 150452"],
  },
  {
    primary_id: "D0A3SQ",
    names: ["3-Benzofuran-2-Yl-2-Benzothiazol-2-Yl-3-Oxo-Propanenitrile"],
  },
  {
    primary_id: "D0A3SX",
    names: ["PTHr Pepducins"],
  },
  {
    primary_id: "D0A3TE",
    names: ["5-Phenyl-6-Thia-10b-Aza-Benzo[e]azulene", "CHEMBL358133", "ZINC13603874", "BDBM50029752"],
  },
  {
    primary_id: "D0A3VX",
    names: [
      "Glucocorticoid Mimetics",
      "BI-115",
      "BI-306",
      "BI-310",
      "Glucocorticoid Mimetics (Inflammation)",
      "Glucocorticoid Mimetics (Inflammation), Boehringer Ingelheim",
    ],
  },
  {
    primary_id: "D0A3WJ",
    names: ["Relatlimab"],
  },
  {
    primary_id: "D0A3WN",
    names: ["Synthetic Surfactants", "CHF-5633", "Synthetic Surfactants, Chiesi"],
  },
  {
    primary_id: "D0A3WQ",
    names: ["Org-36764", "175890-65-0", "ORG-38793", "Org-36653", "Org-36654", "Org-36708"],
  },
  {
    primary_id: "D0A3WZ",
    names: ["KBRV-201"],
  },
  {
    primary_id: "D0A3XA",
    names: ["CNS-1169"],
  },
  {
    primary_id: "D0A3XB",
    names: ["PD-135188"],
  },
  {
    primary_id: "D0A3XC",
    names: ["PMID25666693-Compound-25"],
  },
  {
    primary_id: "D0A3YH",
    names: ["H-Tyr-NMe-D-Ala-Phe-Sar-NH2", "CHEMBL260120"],
  },
  {
    primary_id: "D0A3YK",
    names: ["1-(2-(Naphthalen-2-Yl)-1-Phenylethyl)Piperazine", "CHEMBL425961"],
  },
  {
    primary_id: "D0A3YN",
    names: ["FR191413"],
  },
  {
    primary_id: "D0A3ZU",
    names: ["Pomalidomide", "Actimid (TN)", "Pomalidomide (Immunomodulator)"],
  },
  {
    primary_id: "D0A4AB",
    names: [
      "2-(4,5-Dihydro-1H-Imidazol-2-Yl)Quinoline",
      "BU 224",
      "CHEMBL13698",
      "2-(4,5-Dihydroimidaz-2-Yl)-Quinoline",
      "BU-224",
      "NCGC00015130-01",
      "Tocris-0725",
      "Lopac-B-154",
      "AC1L1DPZ",
      "Lopac0_000167",
      "SCHEMBL1526637",
      "CHEBI:92967",
      "CTK5J7405",
      "ZINC2565858",
      "BDBM50240365",
      "CCG-204262",
      "NCGC00024750-02",
      "NCGC00015130-02",
      "NCGC00024750-01",
      "NCGC00015130-03",
      "NCGC00015130-04",
      "2-(4,5-Dihydro-1H-Imidazol-2-Yl)-Quinoline",
    ],
  },
  {
    primary_id: "D0A4AL",
    names: [
      "C2-MAD",
      "C2-MYCOPHENOLIC ADENINE DINUCLEOTIDE",
      "CHEMBL410744",
      "{[5-(6-AMINO-PURIN-7-YL)-3,4-DIHYDROXY-TETRAHYDRO-FURAN-2-YLMETHOXY]-HYDROXY-PHOSPHORYLMETHYL}-PHOSPHONIC ACID MONO-[2-(4-HYDROXY-6-METHOXY-7-METHYL-3-OXO-1,3-DIHYDRO-ISOBENZOFURAN-5-YL)-ETHYL] ESTER",
      "AC1L9X62",
      "BDBM19265",
      "C2-Mycophenolic Adenine Dinucleotide (C2-MDA)",
      "MYD",
      "4-Methyl-5-Methoxy-6-[2-(5'-Adenylylmethylphosphonyloxy)Ethyl]-7-Hydroxyisobenzofuran-1(3H)-One",
    ],
  },
  {
    primary_id: "D0A4BR",
    names: ["Aurothioglucose", "AUROTHIOGLUCOSE", "HMS3262I11", "LP00685"],
  },
  {
    primary_id: "D0A4DP",
    names: ["LCS 16"],
  },
  {
    primary_id: "D0A4DR",
    names: [
      "Alpha-Methyl-Alpha-Phenylsuccinimide (MPS)",
      "2-Methyl-2-Phenylsuccinimide",
      "1497-17-2",
      "3-Methyl-3-Phenylpyrrolidine-2,5-Dione",
      "Alpha-Methyl-Alpha-Phenylsuccinimide",
      "N-Desmethylmethsuximide",
      "Normesuximide",
      "2,5-Pyrrolidinedione, 3-Methyl-3-Phenyl-",
      "A-Methyl-A-Phenylsuccinimide",
      "N-Demethylmethsuximide",
      "UDESUGJZUFALAM-UHFFFAOYSA-N",
      "3-Methyl-3-Phenyl-2,5-Pyrrolidinedione",
      ".alpha.-Methyl-.alpha.-Phenylsuccinimide",
      "Normethsuximide",
      "N-Normethsuximide",
      "Desmethylmethsuximide",
      "EINECS 216-091-7",
      "Mesuximide, M(Nor-)",
      "AC1Q6LV6",
      "AC1L3NJ7",
      "SCHEMBL1568276",
      "UDESUGJZUFALAM-UHFFFAOYSA-",
    ],
  },
  {
    primary_id: "D0A4EA",
    names: ["OLEIC ACID"],
  },
  {
    primary_id: "D0A4FA",
    names: ["N-Benzyl-1H-Pyrrole-2-Carboxamide"],
  },
  {
    primary_id: "D0A4FE",
    names: ["OS-320"],
  },
  {
    primary_id: "D0A4FU",
    names: [
      "1-(2-Allylphenoxy)-3-Morpholinopropan-2-Ol",
      "CHEMBL1083667",
      "1-Morpholin-4-Yl-3-(2-Prop-2-Enylphenoxy)Propan-2-Ol",
      "BAS 00134198",
      "AC1MCHI5",
      "Oprea1_670642",
      "Oprea1_634560",
      "MLS001143833",
      "MolPort-001-916-053",
      "HMS2803P24",
      "STK843731",
      "BDBM50318978",
      "AKOS016050431",
      "AKOS000525780",
      "MCULE-7265762065",
      "SMR000473272",
      "ST50000343",
      "EN300-241692",
      "1-(2-Allylphenoxy)-3-Morpholin-4-Ylpropan-2-Ol",
      "1-(2-Allyl-Phenoxy)-3-Morpholin-4-Yl-Propan-2-Ol",
    ],
  },
  {
    primary_id: "D0A4GE",
    names: ["1,2,3,7,12,12a-Hexahydro-1-Aza-Pleiadene-5,6-Diol", "CHEMBL253948"],
  },
  {
    primary_id: "D0A4GQ",
    names: ["Tisotumab Vedotin"],
  },
  {
    primary_id: "D0A4HD",
    names: ["Allotrap-2702", "B-2702", "Allotrap-2702.75-84"],
  },
  {
    primary_id: "D0A4HF",
    names: ["2,2-Bis(3-Fluorophenyl)-N-Hydroxyacetamide", "CHEMBL574594", "SCHEMBL4536216"],
  },
  {
    primary_id: "D0A4HS",
    names: [
      "4-Methoxyphenylboronic Acid",
      "5720-07-0",
      "(4-Methoxyphenyl)Boronic Acid",
      "4-Methoxybenzeneboronic Acid",
      "4-Methoxyphenyl Boronic Acid",
      "P-Anisylboronic Acid",
      "P-Methoxyphenylboronic Acid",
      "45713-46-0",
      "P-Methoxybenzeneboronic Acid",
      "(4-Methoxyphenyl)Boranediol",
      "Benzeneboronic Acid, P-Methoxy-",
      "4-Boronoanisole",
      "Boronic Acid, P-Methoxyphenyl-",
      "4-Methoxy Boronic Acid",
      "BRN 2936912",
      "CHEMBL21427",
      "BORONIC ACID, (4-METHOXYPHENYL)-",
      "4-Methoxy-Phenyl-Boronic Acid",
      "AI3-61385",
      "(4-METHOXYPHENYL)BORANE",
      "MFCD00039139",
      "4-Methoxyphenylboronic Acid, 97%",
    ],
  },
  {
    primary_id: "D0A4IJ",
    names: ["Abacavir"],
  },
  {
    primary_id: "D0A4IY",
    names: [
      "HYDRAZINECARBOXAMIDE",
      "Aminourea",
      "57-56-7",
      "Carbamylhydrazine",
      "Carbazamide",
      "Semikarbazid",
      "Urea, Amino-",
      "Aminoharnstoff",
      "Aminomocovina",
      "Hydrazine, Carbamoyl-",
      "Carbamoylhydrazine",
      "Carbamic Acid, Hydrazide",
      "Carbamidsaeurehydrazid",
      "Semikarbazid [Czech]",
      "Aminomocovina [Czech]",
      "UNII-37QUC23K2X",
      "EINECS 200-339-6",
      "CHEMBL903",
      "BRN 0506319",
      "37QUC23K2X",
      "CHEBI:28306",
      "DUIOPKIIICUYRZ-UHFFFAOYSA-N",
      "4426-72-6",
      "Hydrazinecarboxamide, Hydrochloride (1:1)",
      "Isosemicarbazide",
      "Amino-Urea",
      "1-Azanylurea",
      "Carbamic Acid Hydrazide",
      "Lopac-S-2201",
    ],
  },
  {
    primary_id: "D0A4JB",
    names: ["JDP-202", "Antihypertensive, JDP"],
  },
  {
    primary_id: "D0A4JI",
    names: ["NanoDisks"],
  },
  {
    primary_id: "D0A4JK",
    names: [
      "Butabarbital",
      "Butabarb",
      "Butabarbitone",
      "Butalan",
      "Butatab",
      "Butatal",
      "Buticaps",
      "Butisol",
      "Butrate",
      "Medarsed",
      "Nilox",
      "Sarisol",
      "Secbubarbital",
      "Secbutabarbital",
      "Secbutabarbitale",
      "Secbutabarbitalum",
      "Secbutobarbital",
      "Secbutobarbitone",
      "Unicelles",
      "Butabarbital [USAN]",
      "Butisol Sodium",
      "Secbutabarbital [INN]",
      "Secbutabarbitale [DCIT]",
      "Sodium Butabarbital;Butabarbital (USP)",
      "Butabarbital (VAN)",
      "Butisol (TN)",
      "Sec-Butobarbitone",
      "Secbutabarbitalum [INN-Latin]",
      "5-(Butan-2-Yl)-5-Ethylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-Ethyl-5-(1-Methylpropyl)-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Ethyl-5-(1-Methylpropyl)Barbiturate",
      "5-Ethyl-5-(1-Methylpropyl)Barbituric Acid",
      "5-Ethyl-5-Isobutylbarbituric Acid",
      "5-SEC-BUTYL-5-ETHYL-BARBITURIC ACID",
      "5-Sec-Butyl-5-Ethyl-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Butan-2-Yl-5-Ethyl-1,3-Diazinane-2,4,6-Trione",
      "5-Ethyl-5-(1-Methylpropyl)Pyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-Sec-Butyl-5-Ethylbarbituric Acid",
      "5-Sec-Butyl-5-Ethylmalonyl Urea",
      "5-Sec-Butyl-5-Ethylpyrimidine-2,4,6(1H,3H,5H)-Trione",
    ],
  },
  {
    primary_id: "D0A4KP",
    names: ["Tuberculosis Therapeutics"],
  },
  {
    primary_id: "D0A4KW",
    names: [
      "N-(2-Amino-5-(Furan-3-Yl)Phenyl)Benzamide",
      "CHEMBL1095095",
      "Benzamide, N-[2-Amino-5-(3-Furanyl)Phenyl]-",
      "BDBM50318000",
    ],
  },
  {
    primary_id: "D0A4LH",
    names: [
      "5-Tosyl-5H-Dibenzo[b,d]azepin-7(6H)-One",
      "CHEMBL498086",
      "24127-35-3",
      "KCIOJLFKVQHRPU-UHFFFAOYSA-N",
      "5,6-Dihydro-5-(P-Tolylsulfonyl)-7h-Dibenz[b,d]azepin-7-One",
      "AC1LCKZQ",
      "TimTec1_004880",
      "Oprea1_188679",
      "SCHEMBL3034555",
      "CTK8H7702",
      "HMS1547N18",
      "7H-Dibenz[b,d]azepin-7-One, 5,6-Dihydro-5-(P-Tolylsulfonyl)-",
      "BDBM50279063",
      "KB-310270",
      "5-(4-Methylphenyl)Sulfonyl-6H-Benzo[d][1]benzazepin-7-One",
      "5-(Toluene-4-Sulfonyl)-5,6-Dihydro-Dibenzo[b,d]azepin-7-One",
      "5-[(4-Methylphenyl)Sulfonyl]-5,6-Dihydro-7H-Dibenzo[b,d]azepin-7-One #",
    ],
  },
  {
    primary_id: "D0A4LL",
    names: ["PMID29649907-Compound-25"],
  },
  {
    primary_id: "D0A4LM",
    names: [
      "EPALRESTATE",
      "CHEMBL449204",
      "AC1Q1J87",
      "[5-(2-Methyl-3-Phenyl-Allylidene)-4-Oxo-2-Thioxo-Thiazolidin-3-Yl]-Acetic Acid",
      "ZINC967120",
    ],
  },
  {
    primary_id: "D0A4LW",
    names: [
      "(R)-2-(4-Isobutyl-Phenyl)-N-Phenyl-Propionamide",
      "AC1LGDRI",
      "SCHEMBL4372856",
      "CHEMBL190952",
      "ZINC250026",
      "(2R)-2-(4-Isobutylphenyl)-N-Phenylpropionamide",
    ],
  },
  {
    primary_id: "D0A4MI",
    names: ["KPC-34"],
  },
  {
    primary_id: "D0A4MK",
    names: ["AS100", "AS 100", "AS-100"],
  },
  {
    primary_id: "D0A4ML",
    names: [
      "4'-(2-Butylbenzofuran-3-Yl)Biphenyl-4-Ol",
      "CHEMBL286007",
      "SCHEMBL7499937",
      "JIBMWJCMDPEYQP-UHFFFAOYSA-N",
      "BDBM50086976",
      "4''-(2-Butylbenzofuran-3-Yl)Biphenyl-4-Ol",
      "4'-(2-Butyl-Benzofuran-3-Yl)-Biphenyl-4-Ol",
      "4''-(2-Butyl-Benzofuran-3-Yl)-Biphenyl-4-Ol",
    ],
  },
  {
    primary_id: "D0A4MM",
    names: ["NE-10575"],
  },
  {
    primary_id: "D0A4MW",
    names: ["ISIS 7453"],
  },
  {
    primary_id: "D0A4NO",
    names: ["L-162313"],
  },
  {
    primary_id: "D0A4OT",
    names: ["TAMOLARIZINE HYDROCHLORIDE"],
  },
  {
    primary_id: "D0A4PJ",
    names: ["KTE-C19 CAR-T Cell Therapy"],
  },
  {
    primary_id: "D0A4QD",
    names: ["Quinoline Derivative 14"],
  },
  {
    primary_id: "D0A4RF",
    names: ["PMID17935984C1", "CHEBI:45030", "1you", "AC1LCVYP", "GTPL6526", "BDBM50224854", "DB08388"],
  },
  {
    primary_id: "D0A4RM",
    names: [
      "DBO-83",
      "EU-0100452",
      "CHEMBL1527419",
      "Tox21_500452",
      "LP00452",
      "CCG-221756",
      "NCGC00261137-01",
      "NCGC00093868-01",
      "D 7938",
      "SR-01000075832",
    ],
  },
  {
    primary_id: "D0A4RP",
    names: ["Isoxazole-Based Bicyclic Compound 19"],
  },
  {
    primary_id: "D0A4SU",
    names: ["WinRho SDF"],
  },
  {
    primary_id: "D0A4TC",
    names: [
      "MMI270",
      "CGS-27023A",
      "CGS-27023",
      "UNII-80AXY59IT2",
      "80AXY59IT2",
      "N-HYDROXY-2(R)-[[(4-METHOXYPHENYL)SULFONYL](3-PICOLYL)AMINO]-3-METHYLBUTANAMIDE HYDROCHLORIDE",
      "CHEMBL514138",
      "(2R)-N-Hydroxy-2-[(4-Methoxyphenyl)Sulfonyl-(Pyridin-3-Ylmethyl)Amino]-3-Methylbutanamide",
      "CGS",
      "1eub",
      "MMI270B Free Base",
      "Hydroxamate Analogue 1",
      "2w0d",
      "1bm6",
      "MMI-270B Free Base",
      "AC1L9JQY",
      "3MP-HAV-MSB",
      "CGS-27023A Free Base",
      "BMCL16311 Compound 1a",
      "BDBM8465",
      "SCHEMBL3468445",
      "GTPL8846",
      "CHEMBL267178",
      "BSIZUMJRKYHEBR-QGZVFWFLSA-N",
      "CGS 27023",
      "BDBM50066658",
      "DB07556",
      "161314-70-1",
    ],
  },
  {
    primary_id: "D0A4TF",
    names: ["XR-9544"],
  },
  {
    primary_id: "D0A4TS",
    names: ["WS-5"],
  },
  {
    primary_id: "D0A4TU",
    names: ["MEDI-545"],
  },
  {
    primary_id: "D0A4UC",
    names: [
      "GS-6615",
      "Eleclazine",
      "1443211-72-0",
      "UNII-PUY08529FK",
      "PUY08529FK",
      "4-(Pyrimidin-2-Ylmethyl)-7-(4-(Trifluoromethoxy)Phenyl)-3,4-Dihydrobenzo[f][1,4]oxazepin-5(2H)-One",
      "Eleclazine [INN]",
      "Eleclazine [USAN:INN]",
      "Eleclazine (USAN/INN)",
      "Eleclazine(GS-6615)",
      "GTPL8413",
      "CHEMBL3707392",
      "SCHEMBL14480722",
      "MolPort-044-723-855",
      "YNUAEEJQYHYLMS-UHFFFAOYSA-N",
      "GS6615",
      "ZINC206191652",
      "AKOS030627706",
      "DB12394",
      "SB19622",
      "KS-000006B4",
      "1,4-Benzoxazepin-5(2H)-One, 3,4-Dihydro-4-(2-Pyrimidinylmethyl)-7-(4-(Trifluoromethoxy)Phenyl)-",
      "AS-35245",
      "J3.563.176C",
    ],
  },
  {
    primary_id: "D0A4UQ",
    names: [
      "6-Bromo-2-(3-Bromo-Phenyl)-Chromen-4-One",
      "CHEMBL54878",
      "6,3'-Dibromoflavone",
      "3',6-Dibromoflavone",
      "SCHEMBL6652108",
    ],
  },
  {
    primary_id: "D0A4UW",
    names: ["BAY-86-7548", "Bombesin-68Ga-Labeled Tracer (PET/CT Cancer Imaging), Bayer"],
  },
  {
    primary_id: "D0A4VI",
    names: ["AZD-2914"],
  },
  {
    primary_id: "D0A4VJ",
    names: ["CYT-500"],
  },
  {
    primary_id: "D0A4VS",
    names: ["1-Adamantan-1-Yl-3-[4-(4-Fluorophenoxy)Butyl]urea", "CHEMBL387810", "BDBM50217475"],
  },
  {
    primary_id: "D0A4VW",
    names: ["TP-110"],
  },
  {
    primary_id: "D0A4WL",
    names: ["Creaferon"],
  },
  {
    primary_id: "D0A4XA",
    names: [
      "3,3'-(1,2,4-Thiadiazole-3,5-Diyl)Diphenol",
      "CHEMBL503399",
      "SCHEMBL12871356",
      "BDBM25839",
      "Hydroxyphenyl Substituted Thiadiazole, 11",
    ],
  },
  {
    primary_id: "D0A4XJ",
    names: ["SU5402"],
  },
  {
    primary_id: "D0A4XL",
    names: [
      "N-[3H]methylhistamine",
      "1-Methylhistamine",
      "Tele-Methylhistamine",
      "501-75-7",
      "N1-Methylhistamine",
      "N-Telle-Methylhistamine",
      "2-(1-Methyl-1H-Imidazol-4-Yl)Ethanamine",
      "2-(1-Methyl-1H-Imidazol-4-Yl)Ethan-1-Amine",
      "1-Methyl-1H-Imidazole-4-Ethanamine",
      "1H-Imidazole-4-Ethanamine, 1-Methyl-",
      "1-Methyl-4-Histamine",
      "UNII-KCB81T4EOF",
      "N(Sup 1)-Methylhistamine",
      "NSC 66736",
      "N-Tele-Methylhistamine",
      "CHEBI:29009",
      "1-Methyl-4-(B-Aminoethyl)Imidazole",
      "1-Methyl-4-(2-Aminoethyl)Imidazole",
      "KCB81T4EOF",
      "1-Methyl-4-(Beta-Aminoethyl)Imidazole",
      "BRN 0110757",
    ],
  },
  {
    primary_id: "D0A4XN",
    names: ["PRS-639058"],
  },
  {
    primary_id: "D0A4YE",
    names: ["Sulfaphenazole"],
  },
  {
    primary_id: "D0A4YK",
    names: ["PBI-05204"],
  },
  {
    primary_id: "D0A4YR",
    names: ["Pyrrolidinyl Urea Derivative 8"],
  },
  {
    primary_id: "D0A4ZR",
    names: ["2-(Cyclooctylamino)-5,5-Diethyloxazol-4(5H)-One"],
  },
  {
    primary_id: "D0A4ZZ",
    names: ["OHR/AVR118"],
  },
  {
    primary_id: "D0A5AT",
    names: ["KNI-10079"],
  },
  {
    primary_id: "D0A5BH",
    names: ["Hydrohalisulfate 1"],
  },
  {
    primary_id: "D0A5BJ",
    names: ["EndoAngio-GT"],
  },
  {
    primary_id: "D0A5BV",
    names: ["AS-02 Adjuvant"],
  },
  {
    primary_id: "D0A5CD",
    names: ["N,N-Diethyl Estrone-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0A5CH",
    names: ["Integrin Alpha-V/Beta-3 Receptor Mab"],
  },
  {
    primary_id: "D0A5CL",
    names: ["ABI-200"],
  },
  {
    primary_id: "D0A5CM",
    names: ["Trolamine Salicylate"],
  },
  {
    primary_id: "D0A5CN",
    names: ["(Z)-5-Hexylidene-2-Thioxothiazolidin-4-One"],
  },
  {
    primary_id: "D0A5CU",
    names: ["Ro-24-1882"],
  },
  {
    primary_id: "D0A5CZ",
    names: ["DW-403"],
  },
  {
    primary_id: "D0A5DJ",
    names: ["Pancrecarb"],
  },
  {
    primary_id: "D0A5EF",
    names: ["GW-427353"],
  },
  {
    primary_id: "D0A5EN",
    names: ["AMG-714"],
  },
  {
    primary_id: "D0A5ET",
    names: [
      "4-[3-(5-Phenyl-Pentylamino)-Propyl]-Phenol",
      "CHEMBL334003",
      "771459-50-8",
      "CTK2G6876",
      "DTXSID00441232",
      "BDBM50066546",
      "4-[3-[(5-Phenylpentyl)Amino]propyl]phenol",
      "Phenol, 4-[3-[(5-Phenylpentyl)Amino]propyl]-",
    ],
  },
  {
    primary_id: "D0A5FD",
    names: ["AR30"],
  },
  {
    primary_id: "D0A5FM",
    names: ["Nitric Oxide"],
  },
  {
    primary_id: "D0A5FO",
    names: [
      "1,2-Di(10H-Phenothiazin-10-Yl)Ethane-1,2-Dione",
      "1,2-Ethanedione, 1,2-Di-10h-Phenothiazin-10-Yl-",
      "CHEMBL391727",
      "6283-46-1",
      "NSC7508",
      "AC1L5BDH",
      "1,2-Di(Phenothiazin-10-Yl)Ethane-1,2-Dione",
      "AC1Q5KI6",
      "CTK5B6284",
      "DTXSID40278468",
      "NSC-7508",
    ],
  },
  {
    primary_id: "D0A5FR",
    names: ["Benzazepine Derivative 1"],
  },
  {
    primary_id: "D0A5FV",
    names: ["ISIS 102558"],
  },
  {
    primary_id: "D0A5GJ",
    names: ["GB88"],
  },
  {
    primary_id: "D0A5GM",
    names: [
      "8-(2,5-Dimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL106070",
      "AC1LAR93",
      "CTK7A0688",
      "BDBM50059939",
      "2,6-Diamino-8-(2',5'-Dimethoxyphenyl)Purine",
      "8-(2,5-Dimethoxyphenyl)-3h-Purine-2,6-Diamine",
      "8-(2,5-Dimethoxyphenyl)-7H-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D0A5HC",
    names: ["RV-568"],
  },
  {
    primary_id: "D0A5HS",
    names: ["Pain Therapeutic"],
  },
  {
    primary_id: "D0A5IL",
    names: [
      "18F-NST-ML-10",
      "ApoSense-PET",
      "ApoSense [18F]-ML-10",
      "ML-10-[18F]",
      "Fluorine-18-ML-10",
      "Fluorine-18-NST-ML-10",
      "18F-ML-10",
    ],
  },
  {
    primary_id: "D0A5IN",
    names: ["NP-001", "Amyotrophic Lateral Sclerosis/Neurodegenerative Disease Therapy, Neuraltus"],
  },
  {
    primary_id: "D0A5IW",
    names: ["Ac-Cys-Ile-Phe(4-NO2)-Lys-Tyr-Tyr"],
  },
  {
    primary_id: "D0A5IX",
    names: [
      "(RS)-5-Diphosphomevalonate",
      "5-Diphosphomevalonic Acid",
      "AC1L19ES",
      "AC1Q6T4D",
      "SCHEMBL183248",
      "GTPL3055",
      "CTK0I1968",
      "3-Hydroxy-5-{[hydroxy(Phosphonooxy)Phosphoryl]oxy}-3-Methylpentans",
      "1492-08-6",
      "4872-34-8",
      "5-(Phosphonooxyphosphonyloxy)-3-Hydroxy-3-Methylpentanoic Acid",
      "3-Hydroxy-5-(Hydroxy-Phosphonooxyphosphoryl)Oxy-3-Methylpentanoic Acid",
      "3-Hydroxy-5-[hydroxy(Phosphonooxy)Phosphoryl]oxy-3-Methylpentanoic Acid",
      "6,8-Dioxa-7,9-Diphosphanonanoicacid, 3,7,9,9-Tetrahydroxy-3-Methyl-, 7,9-Dioxide",
    ],
  },
  {
    primary_id: "D0A5JP",
    names: ["Iopanoic Acid"],
  },
  {
    primary_id: "D0A5JU",
    names: ["Piperidine-1-Carboxylic Acid Adamantan-2-Yl Ester", "CHEMBL1269124", "SCHEMBL19248187"],
  },
  {
    primary_id: "D0A5KF",
    names: ["IPN-120"],
  },
  {
    primary_id: "D0A5KG",
    names: ["2-Azido-N6-Methyl-9-(Beta-D-Ribofuranosyl)Adenine", "CHEMBL384425"],
  },
  {
    primary_id: "D0A5KO",
    names: ["TD-3633"],
  },
  {
    primary_id: "D0A5KP",
    names: ["2-Morpholino-1,1-Di(Pyridin-3-Yl)Hexan-1-Ol", "CHEMBL1088803", "SCHEMBL1242083"],
  },
  {
    primary_id: "D0A5KS",
    names: ["Hemoxin (Niprisan)"],
  },
  {
    primary_id: "D0A5LC",
    names: ["ISIS 25563"],
  },
  {
    primary_id: "D0A5LH",
    names: ["Penicillin G Procaine", "Duracillin A.S.", "Pfizerpen-As"],
  },
  {
    primary_id: "D0A5LS",
    names: [
      "4-Chloro-N-(5-Hydroxycarbamoyl-Pentyl)-Benzamide",
      "CHEMBL143734",
      "NSC718168",
      "AC1L8L82",
      "SCHEMBL13039735",
      "ZINC5579677",
      "BDBM50082664",
      "NSC-718168",
      "NCI60_040737",
      "6-(4-Chlorobenzoylamino)Hexanehydroxamic Acid",
    ],
  },
  {
    primary_id: "D0A5LY",
    names: ["RN1747"],
  },
  {
    primary_id: "D0A5LZ",
    names: ["ISIS 32006"],
  },
  {
    primary_id: "D0A5MA",
    names: ["Alpha-Acarviosinyl-(1", "156715-37-6"],
  },
  {
    primary_id: "D0A5MC",
    names: ["MLN8054"],
  },
  {
    primary_id: "D0A5MD",
    names: ["PHA-568487"],
  },
  {
    primary_id: "D0A5MG",
    names: [
      "BAS-09534324",
      "BAS 09534324",
      "CHEMBL376993",
      "AC1LM0SZ",
      "MolPort-002-015-109",
      "ZINC815475",
      "BDBM50187571",
      "STK175770",
      "AKOS000654857",
      "MCULE-5021008908",
      "2-Methoxy-4-Methylsulfanyl-N-(5-Phenethyl-1,3,4-Thiadiazol-2-Yl)Benzamide",
      "2-Methoxy-4-(Methylthio)-N-(5-Phenethyl-1,3,4-Thiadiazol-2-Yl)Benzamide",
      "2-Methoxy-4-(Methylsulfanyl)-N~1~-(5-Phenethyl-1,3,4-Thiadiazol-2-Yl)Benzamide",
      "2-Methoxy-4-Methylsulfanyl-N-(5-Phenethyl-[1,3,4]thiadiazol-2-Yl)-Benzamide",
      "2-Methoxy-4-(Methylthio)-N-[5-(2-Phenylethyl)-1,3,4-Thiadiazol-2-Yl]benzamide",
    ],
  },
  {
    primary_id: "D0A5MI",
    names: ["LANIQUIDAR"],
  },
  {
    primary_id: "D0A5MR",
    names: ["MK-0354"],
  },
  {
    primary_id: "D0A5MU",
    names: ["Deprotected Cogener Of M6G", "CHEMBL362202"],
  },
  {
    primary_id: "D0A5MV",
    names: ["3-(4-Phenylethynylbenzoyl)Nonanoic Acid", "CHEMBL201298"],
  },
  {
    primary_id: "D0A5NC",
    names: ["Carbamide Derivative 18"],
  },
  {
    primary_id: "D0A5NL",
    names: ["HS-HIV"],
  },
  {
    primary_id: "D0A5NN",
    names: [
      "ECO-0501",
      "ECO-00501",
      "Antibacterial (MRSA), Ecopia",
      "Antibacterial (VRE), Ecopia",
      "Antibacterial (Drug Resistant Bacteria), Ecopia",
    ],
  },
  {
    primary_id: "D0A5OG",
    names: ["ReN-002"],
  },
  {
    primary_id: "D0A5OS",
    names: [
      "Phenyl-1,4-Bismaleimide",
      "3278-31-7",
      "N,N'-1,4-Phenylenedimaleimide",
      "N,N'-P-Phenylenedimaleimide",
      "N,N'-4-Phenylenedimaleimide",
      "UNII-BEC7P1E6J1",
      "1,1'-(1,4-Phenylene)Bis(1h-Pyrrole-2,5-Dione)",
      "BEC7P1E6J1",
      "N,n-1,4-Phenylenedimaleimide",
      "CHEMBL576594",
      "1,1'-Benzene-1,4-Diylbis(1H-Pyrrole-2,5-Dione)",
      "1-[4-(2,5-Dioxopyrrol-1-Yl)Phenyl]pyrrole-2,5-Dione",
      "NSC81257",
      "EINECS 221-910-6",
      "NSC 81257",
      "P-PDM",
      "1,4-Dimaleimidobenzene",
      "ACMC-209hvr",
      "AC1Q6MZN",
      "1,4-Phenylene Dimaleimide",
      "1,4-Bis(Maleimido)Benzene",
      "AC1L2RM9",
      "SCHEMBL95324",
    ],
  },
  {
    primary_id: "D0A5OT",
    names: ["Aromatic Ethylene Derivative 1"],
  },
  {
    primary_id: "D0A5PM",
    names: ["PPRT-550"],
  },
  {
    primary_id: "D0A5QP",
    names: ["N-(2-Phenylimidazo[1,2-A]pyridin-3-Yl)Benzamide"],
  },
  {
    primary_id: "D0A5QV",
    names: ["PMID25482888-Compound-34"],
  },
  {
    primary_id: "D0A5RA",
    names: ["Epipregnanolone Sulphate"],
  },
  {
    primary_id: "D0A5RD",
    names: ["Radafaxine"],
  },
  {
    primary_id: "D0A5RG",
    names: ["PMID27607364-Compound-58"],
  },
  {
    primary_id: "D0A5RM",
    names: ["4-[(3,5-Diamino-1H-Pyrazol-4-Yl)Diazenyl]phenol"],
  },
  {
    primary_id: "D0A5SA",
    names: ["TAK-272"],
  },
  {
    primary_id: "D0A5SE",
    names: [
      "Febuxostat",
      "Adenuric",
      "TEI",
      "Uloric",
      "Febuxostat [USAN]",
      "S1547",
      "TMX 67",
      "Tei 6720",
      "TMX-67",
      "Tei-6720",
      "Uloric (TN)",
      "Febuxostat (JAN/USAN/INN)",
      "TMX-67, Adenuric, Uloric, Febuxostat",
      "111GE013",
      "2-(3-CYANO-4-ISOBUTOXY-PHENYL)-4-METHYL-5-THIAZOLE-CARBOXYLIC ACID",
      "2-(3-Cyano-4-(2-Methylpropoxy)Phenyl)-4-Methylthiazole-5-Carboxylic Acid;2-(3-Cyano-4-Isobutoxyphenyl)-4-Methyl-5-Thiazolecarboxylic Acid",
      "2-[3-Cyano-4-(2-Methylpropoxy)Phenyl]-4-Methyl-1,3-Thiazole-5-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0A5SJ",
    names: ["B-Cell Lymphoma Vaccine", "BiovaxID (TN)"],
  },
  {
    primary_id: "D0A5SR",
    names: ["Lenapenem Hydrochloride Hydrate", "BO-2727", "L-739428"],
  },
  {
    primary_id: "D0A5TD",
    names: ["NSC-621351"],
  },
  {
    primary_id: "D0A5TM",
    names: ["SGB-1534"],
  },
  {
    primary_id: "D0A5TS",
    names: ["EVT-101"],
  },
  {
    primary_id: "D0A5WN",
    names: ["PTosyl-Glu(OtBu)-Ala-LeuVSMe", "CHEMBL207016"],
  },
  {
    primary_id: "D0A5WQ",
    names: ["MRF-008"],
  },
  {
    primary_id: "D0A5XB",
    names: ["Sulfamic Acid Benzo[1,3]dioxol-2-Ylmethyl Ester", "CHEMBL175786"],
  },
  {
    primary_id: "D0A5XG",
    names: ["NH-3"],
  },
  {
    primary_id: "D0A5XN",
    names: ["Sprodiamide", "DyDTPA-BMA", "S-043", "WIN-59080"],
  },
  {
    primary_id: "D0A5XY",
    names: [
      "N-(2-Bromobenzoyloxy)-4-Chlorobenzamide",
      "CHEMBL1253383",
      "N-{[(2-Bromophenyl)Carbonyl]oxy}-4-Chlorobenzamide",
      "AC1LQE9K",
      "CBMicro_012071",
      "MolPort-002-189-016",
      "ZINC1186119",
      "SMSF0017521",
      "STK185858",
      "BDBM50326572",
      "AKOS005412260",
      "MCULE-4091237725",
      "CB15272",
      "[(4-Chlorobenzoyl)Amino] 2-Bromobenzoate",
      "BIM-0012056.P001",
      "SR-01000230575",
    ],
  },
  {
    primary_id: "D0A5YE",
    names: ["PMID27321640-Compound-74"],
  },
  {
    primary_id: "D0A5YR",
    names: ["AdoCGlyArg6", "CHEMBL611123"],
  },
  {
    primary_id: "D0A5ZF",
    names: ["3,3-Diethyl-1-(Pyridin-3-Yl)Azetidine-2,4-Dione", "CHEMBL566906", "BDBM50303356"],
  },
  {
    primary_id: "D0A5ZJ",
    names: ["DW-1350", "DW-1352", "Osteoblast Modulator (Oral, Osteoporosis), Dong Wha/ Teijin/ Procter & Gamble"],
  },
  {
    primary_id: "D0A5ZL",
    names: ["Anti-LINGO"],
  },
  {
    primary_id: "D0A6AA",
    names: ["7-Thiophen-3-Yl-[1,3]dioxolo[4,5-G]quinoline", "CHEMBL69378", "ZINC3832255"],
  },
  {
    primary_id: "D0A6AJ",
    names: ["Peptide Analog 65"],
  },
  {
    primary_id: "D0A6AP",
    names: [
      "Indigotindisulfonate",
      "Ceruleinum",
      "Indicarminum",
      "Indigotine",
      "Blue X",
      "Indigotindisulfonate Sodium",
      "Sodium Indigotindisulfonate",
      "Disodium Indigotin-5,5'-Disulfonate",
    ],
  },
  {
    primary_id: "D0A6AX",
    names: ["(9H-Beta-Carbolin-3-Yl)-Carbamic Acid Ethyl Ester"],
  },
  {
    primary_id: "D0A6BH",
    names: ["5,10-Methylene-6-Hydrofolic Acid"],
  },
  {
    primary_id: "D0A6CB",
    names: [
      "8-(Biphenyl-4-Yl)-N-Hydroxy-8-Oxooctanamide",
      "CHEMBL95313",
      "SCHEMBL1520838",
      "BDBM50114816",
      "8-Biphenyl-4-Yl-8-Oxo-Octanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0A6CC",
    names: ["Depatuxizumab"],
  },
  {
    primary_id: "D0A6CQ",
    names: ["Levobetaxolol"],
  },
  {
    primary_id: "D0A6CS",
    names: [
      "Bromo-WR99210",
      "BROMO-WR99210",
      "1-[3-(4-BROMO-PHENOXY)-PROPOXY]-6,6-DIMETHYL-1.6-DIHYDRO-[1,3,5]TRIAZINE-2,4-DIAMINE",
      "WRB",
      "1-[3-(4-Bromophenoxy)Propoxy]-6,6-Dimethyl-1,6-Dihydro-1,3,5-Triazine-2,4-Diamine",
      "AC1L1DNN",
      "SCHEMBL8934292",
      "CHEMBL126726",
      "CTK7D5371",
      "DB04007",
    ],
  },
  {
    primary_id: "D0A6DI",
    names: ["RG-1662"],
  },
  {
    primary_id: "D0A6DK",
    names: [
      "Ruthenium (II) Phosphine/Picolinate Complexes",
      "Cis-[Ru(Pic)(Dppe)2]PF6",
      "Ruthenium (II) Phosphine/Picolinatecomplexes (Tuberculosis Infection), FAPESP",
    ],
  },
  {
    primary_id: "D0A6DY",
    names: ["ATM AVI"],
  },
  {
    primary_id: "D0A6EX",
    names: ["PMID26004420-Compound-WO2013062892D"],
  },
  {
    primary_id: "D0A6FK",
    names: ["D[Leu4]AVP", "CHEMBL265859"],
  },
  {
    primary_id: "D0A6FX",
    names: ["(E)-Octadecan-9-Ynoic Acid", "CHEMBL482803", "7E-Octadecen-9-Ynoic Acid", "LMFA01031047"],
  },
  {
    primary_id: "D0A6FZ",
    names: ["Peptide 18", "Ac-LLLLRVK-Amba"],
  },
  {
    primary_id: "D0A6GK",
    names: ["(2,6-Dichloro-Phenyl)-(1H-Imidazol-2-Yl)-Amine", "CHEMBL1022", "SCHEMBL13767327"],
  },
  {
    primary_id: "D0A6GY",
    names: ["ISIS 114442"],
  },
  {
    primary_id: "D0A6HS",
    names: [
      "6-HYDROXY-1,3-BENZOTHIAZOLE-2-SULFONAMIDE",
      "6-Hydroxy-2-Benzothiazolesulfonamide",
      "CHEMBL6685",
      "6-Hydroxybenzothiazole-2-Sulfonamide",
      "ZEC",
      "1zfq",
      "AC1OA9RN",
      "SCHEMBL160105",
      "BDBM10874",
      "NOOBQTYVTDBXTL-UHFFFAOYSA-N",
      "AKOS022657291",
      "Aromatic/Heteroaromatic Sulfonamide 19",
      "DB08765",
    ],
  },
  {
    primary_id: "D0A6IO",
    names: [
      "MRE 3008F20",
      "CJRNHKSLHHWUAB-UHFFFAOYSA-N",
      "MRE 3008-F20",
      "252979-43-4",
      "CHEMBL302765",
      "MCP-NECA",
      "3-[4-(Furan-2-Yl)-11-Propyl-3,5,6,8,10,11-Hexaazatricyclo[7300^{2,6}]dodeca-1(12),2,4,7,9-Pentaen-7-Yl]-1-(4-Methoxyphenyl)Urea",
      "[3H]MRE 3008F20",
      "GTPL477",
      "GTPL459",
      "SCHEMBL958405",
      "BDBM85618",
      "MolPort-023-277-046",
      "ZINC602413",
      "MRE-3008F-20",
      "AKOS024457944",
      "NCGC00378820-01",
      "KB-274663",
      "J1251181G",
      "L000411",
      "N-[2-(2-Furanyl)-8-Propyl-8H-Pyrazolo[4,3-E][1,2,4]triazolo[1,5-C]pyrimidin-5-Yl]-N'-(4-Methoxyphenyl)Urea",
    ],
  },
  {
    primary_id: "D0A6IQ",
    names: [
      "2-(3-Iodophenyl)Histamine",
      "2-[2-(3-Iodophenyl)-3H-Imidazol-4-Yl]ethanamine",
      "CHEMBL287367",
      "GTPL1196",
      "BDBM86173",
      "PDSP2_001293",
      "PDSP1_001309",
    ],
  },
  {
    primary_id: "D0A6JX",
    names: [
      "9-Cyclobutyl-9H-Adenine",
      "9-Cyclobutyladenine",
      "9-CBuA",
      "9H-Purin-6-Amine, 9-Cyclobutyl-",
      "132406-73-6",
      "9-Cyclobutylpurin-6-Amine",
      "CHEMBL455493",
      "AC1L9QF6",
      "9-Cyclobutyl-9H-Purin-6-Amine",
      "CTK4B7898",
      "DTXSID80157526",
      "BDBM50257000",
    ],
  },
  {
    primary_id: "D0A6KA",
    names: ["CLL Immunotherapeutic Vaccine"],
  },
  {
    primary_id: "D0A6KR",
    names: [
      "Balsalazide",
      "Balsalazida",
      "Balsalazido",
      "Balsalazidum",
      "Colazal",
      "Balsalazida [Spanish]",
      "Balsalazide Disodium",
      "Balsalazido [Spanish]",
      "Balsalazidum [Latin]",
      "Balsalazide (INN)",
      "Balsalazide [INN:BAN]",
      "Colazal (TN)",
      "Colazide (TN)",
      "(3Z)-3-[[4-(2-Carboxyethylcarbamoyl)Phenyl]hydrazinylidene]-6-Oxocyclohexa-1,4-Diene-1-Carboxylic Acid",
      "(E)-5-((4-(((2-Carboxyethyl)Amino)Carbonyl)Phenyl)Azo)-2-Hydroxybenzoic Acid",
      "(E)-5-({p-[(2-Carboxyethyl)Carbamoyl]phenyl}azo)-2-Salicylic Acid",
      "3-(2-{4-[(2-Carboxyethyl)Carbamoyl]phenyl}hydrazinylidene)-6-Oxocyclohexa-1,4-Diene-1-Carboxylic Acid",
      "5-[(E)-{4-[(2-Carboxyethyl)Carbamoyl]phenyl}diazenyl]-2-Hydroxybenzoic Acid",
      "5-[4-(2-Carboxy-Ethylcarbamoyl)-Phenylazo]-2-Hydroxy-Benzoic Acid",
    ],
  },
  {
    primary_id: "D0A6LB",
    names: [
      "2-(Piperazin-1-Yl)-5,6,7,8-Tetrahydroquinoline",
      "CHEMBL593547",
      "1207671-23-5",
      "ZINC45354604",
      "BDBM50304820",
      "AKOS023594147",
    ],
  },
  {
    primary_id: "D0A6LI",
    names: ["Aminopiperidine Derivative 1"],
  },
  {
    primary_id: "D0A6LT",
    names: ["PEG-BHb-CO"],
  },
  {
    primary_id: "D0A6LX",
    names: [
      "6-Benzylamino-1H-Pyrimidine-2,4-Dione",
      "CHEMBL86482",
      "6-(Benzylamino)Uracil",
      "6-(Benzylamino)Pyrimidine-2,4(1H,3H)-Dione",
      "5759-80-8",
      "6-Benzylaminouracil",
      "NSC210509",
      "6-(Benzylamino)-1H-Pyrimidine-2,4-Dione",
      "AC1L7DCT",
      "ChemDiv2_000645",
      "Oprea1_763542",
      "Oprea1_040277",
      "MLS001143323",
      "SCHEMBL7563397",
      "DTXSID80308975",
      "MolPort-002-324-866",
      "ZINC169533",
      "HMS2799M09",
      "HMS1370N07",
      "STK385017",
      "BDBM50022154",
      "AKOS000627145",
      "MCULE-8157405299",
      "NSC-210509",
      "CCG-107711",
      "SMR000473158",
      "ST50859108",
      "EU-0040004",
      "6-(Benzylamino)-2,4(1H,3H)-Pyrimidinedione",
    ],
  },
  {
    primary_id: "D0A6MF",
    names: ["MESUAGENIN B"],
  },
  {
    primary_id: "D0A6MN",
    names: ["SB773812"],
  },
  {
    primary_id: "D0A6MS",
    names: ["Parke-Davis 9"],
  },
  {
    primary_id: "D0A6NK",
    names: ["ISIS 16009"],
  },
  {
    primary_id: "D0A6NP",
    names: ["A35566-A", "A35566-B"],
  },
  {
    primary_id: "D0A6NQ",
    names: ["AHPN"],
  },
  {
    primary_id: "D0A6OF",
    names: [
      "BMS-181321",
      "BMS-181032 (Ligand)",
      "[[3',3'-[(1,3-Propanediyl)Diimino]bis[3-Methyl-1-(2-Nitroimidazol-1-Yl)-2-Butanone]dioximato](3-)-N,N',N'',N''']oxotechnetium[99mTc]",
      "[[[3,3,9,9-Tetramethyl-1-(2-Nitroimidazol-1-Yl)-4,8-Diazaundecane-2,10-Dione]dioximato](3-)-N,N',N'',N''']oxotechnetium[99mTc]",
    ],
  },
  {
    primary_id: "D0A6OJ",
    names: ["TP-102", "Bacteriophage Lytic Enzyme Based Formulation (Bacterial Infection), Technophage"],
  },
  {
    primary_id: "D0A6OO",
    names: ["PSI 7851"],
  },
  {
    primary_id: "D0A6OS",
    names: ["TAK-379"],
  },
  {
    primary_id: "D0A6PD",
    names: ["Oralgen Tree Pollen"],
  },
  {
    primary_id: "D0A6PL",
    names: ["S-14506"],
  },
  {
    primary_id: "D0A6QI",
    names: ["Proline Amide Derivative 1"],
  },
  {
    primary_id: "D0A6RG",
    names: ["PMID27774824-Compound-Figure2Example4"],
  },
  {
    primary_id: "D0A6RI",
    names: ["Meganucleases"],
  },
  {
    primary_id: "D0A6RL",
    names: ["Benzylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL485689"],
  },
  {
    primary_id: "D0A6RM",
    names: ["Trans-3-ACPBPA"],
  },
  {
    primary_id: "D0A6RR",
    names: ["Dihydropyridine Compound 1"],
  },
  {
    primary_id: "D0A6RU",
    names: [
      "7-Chloro-1H-Indazole",
      "37435-12-4",
      "7-Chloroindazole",
      "1H-Indazole, 7-Chloro-",
      "CHEMBL247367",
      "AK-24465",
      "7-Chlor-1H-Indazol",
      "ACMC-1AG86",
      "SCHEMBL104360",
      "SCHEMBL19152469",
      "CTK1C1806",
      "DTXSID80455690",
      "WIYOYUQVNPTVQG-UHFFFAOYSA-N",
      "MolPort-001-767-757",
      "7-Chloro-1H-Indazole, AldrichCPR",
      "ZX-AT002536",
      "BCP27378",
      "BDBM50209244",
      "ZINC12336533",
      "STL554161",
      "SBB054730",
      "MFCD07368214",
      "ANW-28635",
      "OR3680",
      "FCH921470",
      "BBL100367",
      "AKOS024124979",
      "AKOS005072025",
      "VI20076",
      "RP01758",
      "MCULE-5657266195",
      "CB-0833",
      "PB17411",
      "ACM37435124",
      "KB-46237",
      "SC-27673",
      "QC-11238",
      "BL000561",
      "AN-17144",
    ],
  },
  {
    primary_id: "D0A6RX",
    names: ["Anti-HTfR IgG3-Av", "Antibody-Avidin Fusion Protein (Cancer), UCLA"],
  },
  {
    primary_id: "D0A6SD",
    names: ["CLS008"],
  },
  {
    primary_id: "D0A6TU",
    names: ["PMID27109571-Compound-40"],
  },
  {
    primary_id: "D0A6UQ",
    names: ["ETX-1153a"],
  },
  {
    primary_id: "D0A6VA",
    names: ["PVP-ILH Liposomes"],
  },
  {
    primary_id: "D0A6VD",
    names: ["VS-4718"],
  },
  {
    primary_id: "D0A6VE",
    names: [
      "FIDUXOSIN HYDROCHLORIDE",
      "ABT-980",
      "Fiduxosin Hydrochloride < USAN",
      "A-185980.1",
      "(3aR,9bR)-Cis-3-[4-(9-Methoxy-1,2,3,3a,4,9b-Hexahydro-[1]benzopyrano[3,4-C]pyrrol-2-Yl)Butyl]-8-Phenylpyrazino[2',3':4,5]thieno[3,2-D]pyrimidine-2,4(1H,3H)-Dione Hydrochloride",
    ],
  },
  {
    primary_id: "D0A6VM",
    names: ["VCL-HB01"],
  },
  {
    primary_id: "D0A6WA",
    names: ["GAM-501"],
  },
  {
    primary_id: "D0A6WQ",
    names: ["AG-4070"],
  },
  {
    primary_id: "D0A6XC",
    names: ["CD19-Targeted CART Cells"],
  },
  {
    primary_id: "D0A6XW",
    names: ["HIV Integrase Inhibitors"],
  },
  {
    primary_id: "D0A6YN",
    names: [
      "SUN-C5174",
      "5-[3-[4-(4-Fluorophenyl)Piperazin-1-Yl]propyl]-8(S)-Hydroxy-1-Methyl-1,4,5,6,7,8-Hexahydropyrrolo[3,2-C]azepin-4-One",
    ],
  },
  {
    primary_id: "D0A6YP",
    names: [
      "3-(3-Fluoro-Phenyl)-6,7-Dimethoxy-Quinoline",
      "CHEMBL302491",
      "SCHEMBL8502238",
      "ZINC3834024",
      "BDBM50039084",
    ],
  },
  {
    primary_id: "D0A6YS",
    names: ["Oral Immunoglobulin"],
  },
  {
    primary_id: "D0A6YW",
    names: ["WR-289009"],
  },
  {
    primary_id: "D0A6ZC",
    names: ["[11C]butylthio-TZTP", "[11C]butylthio-TZTP (PET Ligand)"],
  },
  {
    primary_id: "D0A6ZK",
    names: [
      "2-Hydroxy-3-Isopropyl-2,4,6-Cycloheptatrien-1-One",
      "2,4,6-Cycloheptatrien-1-One, 2-Hydroxy-3-(1-Methylethyl)-",
      "1946-74-3",
      "CHEMBL1275969",
      "A-Thujaplicine",
      "Thujaplicin",
      ".alpha.-Thujaplicin",
      "AC1Q6BZY",
      "SCHEMBL355645",
      "AC1L2Z64",
      "CTK1B5134",
      "DTXSID70173098",
      "TUFYVOCKVJOUIR-UHFFFAOYSA-N",
      "ZINC2041733",
      "BDBM50330793",
      "AKOS025402371",
      "AC-8489",
      "LS-56188",
      "2-Hydroxy-3-Isopropyl-Cyclohepta-2,4,6-Trien-1-One",
      "2-Hydroxy-3-Propan-2-Ylcyclohepta-2,4,6-Trien-1-One",
      "2,4,6-Cycloheptatrien-1-One, 2-Hydroxy(1-Methylethyl)-",
      "38094-79-0",
    ],
  },
  {
    primary_id: "D0A7AG",
    names: [
      "CP51-2705",
      "CPSI-1306",
      "CPSI-2705",
      "MIF Antagonists, Cytokine",
      "Macrophage Inhibitory Factor Antagonists, Cytokine",
    ],
  },
  {
    primary_id: "D0A7AK",
    names: ["3-{[2-(2-Phenylethyl)Phenoxy]methyl}pyridine", "CHEMBL217425", "3-((2-Phenethylphenoxy)Methyl)Pyridine"],
  },
  {
    primary_id: "D0A7AV",
    names: ["ISIS 5875"],
  },
  {
    primary_id: "D0A7CE",
    names: ["PMID28766366-Compound-Scheme15-1"],
  },
  {
    primary_id: "D0A7CH",
    names: ["WIP-901"],
  },
  {
    primary_id: "D0A7CZ",
    names: ["PTX-007"],
  },
  {
    primary_id: "D0A7ER",
    names: [
      "N-(Tert-Butoxycarbonyl)-Norvalyl-Glycine-Nitrile",
      "Dipeptide-Derived Nitrile, 6",
      "CHEMBL383816",
      "BDBM20086",
      "Tert-Butyl N-[(1S)-1-[(Cyanomethyl)Carbamoyl]butyl]carbamate",
    ],
  },
  {
    primary_id: "D0A7FI",
    names: ["PMID18754614C19"],
  },
  {
    primary_id: "D0A7FQ",
    names: [
      "CGP-57380",
      "CGP 57380",
      "522629-08-9",
      "MNK1 Inhibitor",
      "N3-(4-Fluorophenyl)-1h-Pyrazolo[3,4-D]pyrimidine-3,4-Diamine",
      "CGP57380",
      "CHEMBL1240885",
      "C11H9FN6",
      "4-Amino-5-(4-Fluoroanilino)-Pyrazolo[3,4-D]pyrimidine",
      "SCHEMBL987991",
      "GTPL6010",
      "CHEMBL576817",
      "SCHEMBL16714452",
      "CHEBI:92749",
      "DTXSID50469941",
      "MolPort-006-725-822",
      "HMS3653G22",
      "HMS3269P13",
      "HMS3263L14",
      "HMS3229K20",
      "HMS3648M14",
      "Tox21_501256",
      "BDBM50130693",
      "2314AH",
      "NSC741567",
      "MFCD03861062",
      "ZINC13816313",
      "S7421",
      "IN1236",
      "BDBM50298223",
      "AKOS024457265",
      "CCG-206868",
      "NSC-741567",
      "LP01256",
      "NCGC00162380-06",
    ],
  },
  {
    primary_id: "D0A7GA",
    names: [
      "AMT-2003",
      "Auron Misheil Therapy (AMT)",
      "Anticancer Therapy (Intramuscular, Cancer), Auron Healthcare GmbH",
    ],
  },
  {
    primary_id: "D0A7GE",
    names: ["Avanz"],
  },
  {
    primary_id: "D0A7GH",
    names: [
      "ATI-2341",
      "ATI-2346",
      "ATI-2756",
      "ATI-2766",
      "CXCR4 Agonists (Cancer/Bone Marrow Transplantation), Ascent",
      "Pepducins (Cancer/Bone Marrow Transplantation), Ascent",
    ],
  },
  {
    primary_id: "D0A7GI",
    names: ["BPZE-1", "Bordetella Pertussis Vaccine (Live Attenuated, Nasal), INSERM"],
  },
  {
    primary_id: "D0A7GO",
    names: ["PMID1738140C9"],
  },
  {
    primary_id: "D0A7GW",
    names: ["N-(Piperidin-4-Yl)-N-Propyl-2-Naphthamide", "CHEMBL565638"],
  },
  {
    primary_id: "D0A7GX",
    names: ["Echovist"],
  },
  {
    primary_id: "D0A7HM",
    names: [
      "N,N-Dimethyl-2-(Pyridin-3-Yloxy)Ethanamine",
      "CHEMBL61616",
      "228272-02-4",
      "SCHEMBL12827198",
      "CTK0J6066",
      "DTXSID40436622",
      "BDBM50115808",
      "AKOS030595283",
      "Dimethyl 2-(3-Pyridinyloxy)Ethylamine",
      "Dimethyl-[2-(Pyridin-3-Yloxy)-Ethyl]-Amine",
      "N,N-Dimethyl-2-(Pyridin-3-Yloxy)Ethanaminium",
      "Ethanamine, N,N-Dimethyl-2-(3-Pyridinyloxy)-",
    ],
  },
  {
    primary_id: "D0A7HY",
    names: ["TAT-120"],
  },
  {
    primary_id: "D0A7IF",
    names: ["N-Hydroxy-5-(Pyridin-4-Yl)Thiophene-2-Carboxamide", "CHEMBL217816", "SCHEMBL5903934"],
  },
  {
    primary_id: "D0A7JH",
    names: ["WY-50295-Tromethamine"],
  },
  {
    primary_id: "D0A7KI",
    names: ["Abaperidone"],
  },
  {
    primary_id: "D0A7LL",
    names: ["PSMA ADC Therapeutic"],
  },
  {
    primary_id: "D0A7MY",
    names: ["TECFIDERA", "DIMETHYL FUMARATE"],
  },
  {
    primary_id: "D0A7NB",
    names: ["Siplizumab"],
  },
  {
    primary_id: "D0A7NH",
    names: ["4-(Cyclohexylamino)-1-Naphthol", "CHEMBL571437"],
  },
  {
    primary_id: "D0A7NI",
    names: ["LIM-5310"],
  },
  {
    primary_id: "D0A7NX",
    names: ["Pyrazolopyrimidine Derivative 6"],
  },
  {
    primary_id: "D0A7NZ",
    names: ["CNTO-6785"],
  },
  {
    primary_id: "D0A7PA",
    names: ["2,4-Dimethoxy-3'-Amino-Trans-Stilbene", "CHEMBL1173641", "BDBM50322048"],
  },
  {
    primary_id: "D0A7PU",
    names: ["3-[4-(5-Chloro-2-Thienyl)Pyridin-2-Yl]phenol", "CHEMBL1096375"],
  },
  {
    primary_id: "D0A7PW",
    names: ["CP-903397"],
  },
  {
    primary_id: "D0A7QN",
    names: ["Moxisylyte"],
  },
  {
    primary_id: "D0A7RT",
    names: [
      "Cholecystokinin-9",
      "CHEMBL414345",
      "Cholecystokinin 9",
      "AC1L4TL1",
      "CCK-9",
      "BDBM50154437",
      "108050-84-6",
      "Caerulein, 1-De(5-Oxo-L-Proline)-2-L-Arginine-5-L-Methionine-",
    ],
  },
  {
    primary_id: "D0A7SE",
    names: ["ISIS 3804"],
  },
  {
    primary_id: "D0A7SR",
    names: ["H-Leu-Phe-NH2"],
  },
  {
    primary_id: "D0A7UK",
    names: ["PDS-0103"],
  },
  {
    primary_id: "D0A7US",
    names: ["2-(2,4-Dichlorophenoxy)-5-Isopentylphenol"],
  },
  {
    primary_id: "D0A7UT",
    names: ["PF-04605412"],
  },
  {
    primary_id: "D0A7VF",
    names: ["CP-79328"],
  },
  {
    primary_id: "D0A7WB",
    names: ["7-(3,5-Dihydroxyphenyl)Naphthalene-1,3-Diol", "CHEMBL392209"],
  },
  {
    primary_id: "D0A7WK",
    names: ["BIOO-7"],
  },
  {
    primary_id: "D0A7WO",
    names: ["NT-501"],
  },
  {
    primary_id: "D0A7WT",
    names: ["Dornase Alpha"],
  },
  {
    primary_id: "D0A7XQ",
    names: ["ON-044580"],
  },
  {
    primary_id: "D0A7YE",
    names: ["Octanoic Acid Biphenyl-2-Ylamide", "CHEMBL408322", "SCHEMBL15472053"],
  },
  {
    primary_id: "D0A7YH",
    names: ["Palodesangren C"],
  },
  {
    primary_id: "D0A7ZB",
    names: ["HR24 Antibody"],
  },
  {
    primary_id: "D0A7ZH",
    names: ["N-(2,3-DIFLUORO-BENZYL)-4-SULFAMOYL-BENZAMIDE"],
  },
  {
    primary_id: "D0A7ZK",
    names: ["E2006"],
  },
  {
    primary_id: "D0A7ZX",
    names: ["Pretargeting TF2"],
  },
  {
    primary_id: "D0A8AL",
    names: ["PFK-015"],
  },
  {
    primary_id: "D0A8AU",
    names: ["MSB2311"],
  },
  {
    primary_id: "D0A8AX",
    names: ["Ad-RTS-HIL12"],
  },
  {
    primary_id: "D0A8BJ",
    names: [
      "2-Amino-6-(Toluene-3-Sulfinyl)-Benzonitrile",
      "CHEMBL53893",
      "6-Amino-2-[(3-Methylphenyl)Sulfinyl]benzenecarbonitrile",
      "AC1LAE28",
      "BDBM1760",
      "CTK7C6588",
      "2-Amino-6-(M-Tolylsulfinyl)Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 2e",
      "2-Amino-6-(3-Methylphenyl)Sulfinylbenzonitrile",
    ],
  },
  {
    primary_id: "D0A8BK",
    names: ["MEN-10979"],
  },
  {
    primary_id: "D0A8BV",
    names: ["PMID27774822-Compound-Figure10Compound12"],
  },
  {
    primary_id: "D0A8CJ",
    names: ["Tiopronin"],
  },
  {
    primary_id: "D0A8CS",
    names: [
      "3-ETHYL-2-(4-HYDROXYPHENYL)-2H-INDAZOL-5-OL",
      "3-Ethyl-2-(4-Hydroxyphenyl)-2H-Indazol-5-Ol",
      "CHEMBL180517",
    ],
  },
  {
    primary_id: "D0A8EG",
    names: ["TAK-591"],
  },
  {
    primary_id: "D0A8EK",
    names: [
      "APNEA",
      "89705-21-5",
      "CHEMBL1256714",
      "N-[2-(4-Aminophenyl)Ethyl]-Adenosine",
      "SMR000326823",
      "MLS000859964",
      "MLS002153195",
      "SCHEMBL1230660",
      "DTXSID40433272",
      "XTPOZVLRZZIEBW-SCFUHWHPSA-N",
      "HMS2233B08",
      "ZINC8660412",
      "BDBM50453442",
      "AKOS030526973",
      "CS-4923",
      "Adenosine, N6-2-(4-Aminophenyl)Ethyl",
      "HY-18687",
      "LS-15099",
      "N6-2-(4-Aminophenyl)Ethyladenosine, &gt",
    ],
  },
  {
    primary_id: "D0A8EN",
    names: ["PMID26077642-Compound-Figure3A"],
  },
  {
    primary_id: "D0A8ES",
    names: ["EC-0845"],
  },
  {
    primary_id: "D0A8EU",
    names: [
      "(4R)-4-Ammoniopentanoate",
      "(R)-4-Aminopentanoic Acid",
      "4R-Aminopentanoic Acid",
      "5937-83-7",
      "UNII-UPX83HFN1B",
      "UPX83HFN1B",
      "(4R)-4-Aminopentanoic Acid",
      "(R)-(+)-4-Aminovaleric Acid",
      "AC1L9GD1",
      "4-Aminovaleric Acid, (R)-",
      "CHEMBL281098",
      "SCHEMBL15177649",
      "Pentanoic Acid, 4-Amino-, (R)-",
      "ZINC1706201",
      "Pentanoic Acid, 4-Amino-, (4R)-",
      "LMFA01100021",
      "AKOS006337463",
      "Valeric Acid, 4-Amino-, (R)-(+)-",
      "UNII-CDM626IVRT Component ABSTXSZPGHDTAF-SCSAIBSYSA-N",
    ],
  },
  {
    primary_id: "D0A8EV",
    names: ["Triazolo-Pyridine Derivative 6"],
  },
  {
    primary_id: "D0A8FB",
    names: ["Trimethobenzamide"],
  },
  {
    primary_id: "D0A8FD",
    names: ["S-18327"],
  },
  {
    primary_id: "D0A8GM",
    names: ["Ublituximab"],
  },
  {
    primary_id: "D0A8GW",
    names: ["PMID26651364-Compound-9e"],
  },
  {
    primary_id: "D0A8HL",
    names: ["DMP-811"],
  },
  {
    primary_id: "D0A8HN",
    names: ["Iboctadekin"],
  },
  {
    primary_id: "D0A8HW",
    names: ["4-Chloro-N-(5-Sulfamoyl-Indan-2-Yl)-Benzamide", "CHEMBL364127", "BDBM50155552"],
  },
  {
    primary_id: "D0A8HX",
    names: [
      "6-M-Tolyl-Pteridine-2,4,7-Triamine",
      "6-(3-Methylphenyl)Pteridine-2,4,7-Triamine",
      "2853-70-5",
      "UNII-Y75RNL3DWL",
      "Y75RNL3DWL",
      "CHEMBL48189",
      "NSC19443",
      "NSC 19443",
      "NSC 639358",
      "AC1Q4WBT",
      "AC1L5FLS",
      "CTK4G1672",
      "DTXSID00182754",
      "ZINC1566881",
      "6-Meta-Tolylpteridine-2,7-Triamine",
      "NSC639358",
      "NSC-19443",
      "BDBM50127144",
      "6-(M-Tolyl)Pteridine-2,4,7-Triamine",
    ],
  },
  {
    primary_id: "D0A8IE",
    names: [
      "AM-831",
      "199330-66-0",
      "METHYL 4-AMINOTETRAHYDRO-2H-PYRAN-4-CARBOXYLATE HYDROCHLORIDE",
      "Methyl 4-Aminooxane-4-Carboxylate Hydrochloride",
      "METHYL 4-AMINOTETRAHYDROPYRAN-4-CARBOXYLATE HCL",
      "Methyl 4-Aminotetrahydropyran-4-Carboxylate Hydrochloride",
      "2H-Pyran-4-Carboxylic Acid, 4-Aminotetrahydro-, Methyl Ester, Hydrochloride (1:1)",
      "SCHEMBL1818830",
      "MolPort-009-013-807",
      "VNPBPEQYIQVYDP-UHFFFAOYSA-N",
      "AM831",
      "KS-000008CU",
      "SBB091929",
      "AKOS025147281",
      "PB10899",
      "AS-36521",
      "DB-065487",
      "CS-0049053",
      "FT-0768000",
      "Q-1506",
      "Methyl 4-Aminooxane-4-Carboxylate,hydroch",
    ],
  },
  {
    primary_id: "D0A8IM",
    names: ["CF-238", "Cell Adhesion Molecule Inhibitor (Infection), Cernofina"],
  },
  {
    primary_id: "D0A8IQ",
    names: ["ATX-MS2"],
  },
  {
    primary_id: "D0A8JB",
    names: ["VAX-125"],
  },
  {
    primary_id: "D0A8JM",
    names: [
      "RDP58",
      "Delmitide",
      "RDP-58",
      "UNII-5759XTJ706",
      "5759XTJ706",
      "Delmitide [INN]",
      "Allotrap-1258",
      "Allotrap 1258",
      "287096-87-1",
      "AC1L55WS",
      "AC1Q5JM9",
      "(2r)-2-[[(2r)-2-[[(2r)-2-[[(2r)-2-Amino-5-Guanidino-Pentanoyl]amino]hexanoyl]amino]hexanoyl]amino]-N-[(1r)-1-[[(1r)-1-[[(1r)-1-[[(1r)-1-[[2-[[(1r)-2-Amino-1-[(4-Hydroxyphenyl)Methyl]-2-Oxo-Ethyl]amino]-2-Oxo-Ethyl]carbamoyl]pentyl]carbamoyl]pentyl]carbamoyl]pentyl]carbamoyl]-4-Guanidino-Butyl]hexanamide",
      "RDP 58",
      "RSP-58",
      "SCHEMBL18758870",
      "D-Tyrosinamide, D-Arginyl-D-Norleucyl-D-Norleucyl-D-N",
    ],
  },
  {
    primary_id: "D0A8JP",
    names: ["PARABEN"],
  },
  {
    primary_id: "D0A8KL",
    names: ["Benzoheterocyclic Compound 1"],
  },
  {
    primary_id: "D0A8KQ",
    names: ["GSK2256294"],
  },
  {
    primary_id: "D0A8KX",
    names: ["M-518101"],
  },
  {
    primary_id: "D0A8LP",
    names: ["AG2034"],
  },
  {
    primary_id: "D0A8LU",
    names: ["PMID25666693-Compound-113"],
  },
  {
    primary_id: "D0A8LZ",
    names: ["BCG Vaccine"],
  },
  {
    primary_id: "D0A8MD",
    names: [
      "2-HYDROXY-3,5-DIIODOBENZOIC ACID",
      "3,5-DIIODOSALICYLIC ACID",
      "133-91-5",
      "2-Hydroxy-3,5-Diiodobenzoic Acid",
      "3,5-Diiodo-2-Hydroxybenzoic Acid",
      "Benzoic Acid, 2-Hydroxy-3,5-Diiodo-",
      "2-Hydroxy-3,5-Diiodobenzoate",
      "3,5-Diiodosalicylate",
      "2-Hydroxy-3,5-Diiodobenzenecarboxylic Acid",
      "2-HYDROXY-3,5-DIIODO-BENZOIC ACID",
      "Salicylic Acid, 3,5-Diiodo-",
      "NSC 6303",
      "Benzoic Acid, 3,5-Diiodo-2-Hydroxy-",
      "UNII-1496OH15B6",
      "3,5-Diiodosalicylicacid",
      "EINECS 205-124-0",
      "BRN 2615358",
      "AI3-33355",
      "NSC6303",
      "DHZVWQPHNWDCFS-UHFFFAOYSA-N",
      "MFCD00002444",
      "1496OH15B6",
      "DIU",
      "Rarechem Al Be 0656",
      "ACMC-1BPSO",
    ],
  },
  {
    primary_id: "D0A8ME",
    names: ["Ac-[CEHdFRWC]-NH2", "CHEMBL385556"],
  },
  {
    primary_id: "D0A8MH",
    names: ["M-516102"],
  },
  {
    primary_id: "D0A8MN",
    names: ["3,3-Bis-(4-Methoxy-Phenyl)-2-Phenyl-Acrylonitrile", "CHEMBL304867"],
  },
  {
    primary_id: "D0A8NB",
    names: [
      "CP-94,253",
      "CP-94253",
      "Tocris-1317",
      "NCGC00025108-01",
      "AC1N1FU8",
      "SCHEMBL9695044",
      "GTPL3221",
      "CHEMBL1570196",
      "CHEBI:92591",
      "ZINC1894220",
      "BCP28082",
      "AKOS022184337",
      "CP94253",
      "CP94253NIEaNI/CP94253NIEaNI",
      "AJ-32224",
      "LS-139500",
      "L003950",
      "BRD-K33860217-003-01-6",
      "4-{5-Propoxypyrrolo[3,2-B]pyridin-3-Yl}-1,2,3,6-Tetrahydropyridine",
    ],
  },
  {
    primary_id: "D0A8NN",
    names: ["CT-637"],
  },
  {
    primary_id: "D0A8NW",
    names: ["PC-1005"],
  },
  {
    primary_id: "D0A8NZ",
    names: ["Cyclo[DAsp7,Lys10]N/OFQ(1-13)NH2", "CHEMBL1162358"],
  },
  {
    primary_id: "D0A8OV",
    names: ["N-Acetyl-Phenylalanyl-Glycine-Nitrile"],
  },
  {
    primary_id: "D0A8OW",
    names: [
      "Subersic Acid",
      "(+)-Subersic Acid",
      "CHEMBL455101",
      "CHEBI:66532",
      "4-Hydroxy-3-{(2E)-3-Methyl-5-[(4aS,8aS)-2,5,5,8a-Tetramethyl-3,4,4a,5,6,7,8,8a-Octahydronaphthalen-1-Yl]pent-2-En-1-Yl}benzoic Acid",
      "BDBM50242189",
    ],
  },
  {
    primary_id: "D0A8OX",
    names: ["N,N'-(1',5'-Pentylene)-Bis-(-)-Nor-MEP", "CHEMBL408463"],
  },
  {
    primary_id: "D0A8PC",
    names: ["Influenza Cell Culture Vaccine"],
  },
  {
    primary_id: "D0A8PD",
    names: [
      "2-O-Methyl-PAF C-18",
      "2-O-Methyl-Platelet-Activating Factor C-18",
      "2-Methyl-C18-PAF",
      "2-Methyloctadecyl PAF",
    ],
  },
  {
    primary_id: "D0A8PI",
    names: ["Boc-Agly-Val-Agly-OEt", "CHEMBL135413"],
  },
  {
    primary_id: "D0A8PU",
    names: [
      "CDK5/P25 Inhibitor",
      "CDK5/P25 Inhibitor (Neurodegeneration)",
      "CDK5/P25 Inhibitor (Neurodegeneration), Pfizer",
      "Cyclin Dependent Kinase 5/P25 Inhibitors (Neurodegeneration), Pfizer",
    ],
  },
  {
    primary_id: "D0A8PV",
    names: [
      "NBI-74330",
      "473722-68-8",
      "855527-92-3",
      "(Plusmn)-NBI 74330",
      "GTPL839",
      "SCHEMBL15064525",
      "MolPort-035-765-750",
      "NBI74330",
      "AKOS024458204",
      "NCGC00379149-01",
      "KB-79291",
      "( Inverted Exclamation MarkA)-NBI 74330",
      "(+/-)-NBI 74330",
      "N-1-[(3-4(-Ethoxyphenyl)-3,4-Dihydro-4-Oxopyrido[2,3-D]pyrimidin-2-Yl]ethyl]-4-Fluoro-N-(3-Pyridinylmethyl)-3-(Trifluoromethyl)Benzeneacetamide",
    ],
  },
  {
    primary_id: "D0A8QA",
    names: ["2-Chloro-5-(2-Methylquinolin-7-Yl)Nicotinonitrile", "CHEMBL1096196"],
  },
  {
    primary_id: "D0A8QM",
    names: ["Lu-AA34893"],
  },
  {
    primary_id: "D0A8QZ",
    names: ["HDAC4 Inhibtors"],
  },
  {
    primary_id: "D0A8RP",
    names: ["INDOPY-1"],
  },
  {
    primary_id: "D0A8RV",
    names: ["Biaryl Mannoside Derivative 4"],
  },
  {
    primary_id: "D0A8RX",
    names: ["Schizophyllan"],
  },
  {
    primary_id: "D0A8SH",
    names: ["Indoline Derivative 21"],
  },
  {
    primary_id: "D0A8SI",
    names: ["Lactam Derivative 4"],
  },
  {
    primary_id: "D0A8TA",
    names: ["Volanesorsen"],
  },
  {
    primary_id: "D0A8UJ",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 9"],
  },
  {
    primary_id: "D0A8UP",
    names: ["PMID15026062C41"],
  },
  {
    primary_id: "D0A8VU",
    names: ["PMID29334795-Compound-58"],
  },
  {
    primary_id: "D0A8VZ",
    names: ["CGNLSTCBLGTYTQDF[DKFHO]YPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D0A8WJ",
    names: ["Kuwanon L"],
  },
  {
    primary_id: "D0A8WP",
    names: [
      "6-(N-Phenylcarbamyl)-2-Naphthalenecarboxamidine",
      "6-(N-PHENYLCARBAMYL)-2-NAPHTHALENECARBOXAMIDINE",
      "CHEMBL104937",
      "6-[(Z)-AMINO(IMINO)METHYL]-N-PHENYL-2-NAPHTHAMIDE",
      "6-Carbamimidoyl-N-Phenylnaphthalene-2-Carboxamide",
      "1owe",
      "UPa_7",
      "AC1L9L6A",
      "SCHEMBL4324160",
      "ZINC2047486",
      "BDBM50138670",
      "6-Carbamimidoyl-N-Phenyl-2-Naphthamide",
      "DB01977",
      "6-Carbamimidoyl-Naphthalene-2-Carboxylic Acid Phenylamide",
    ],
  },
  {
    primary_id: "D0A8WR",
    names: ["LUpartumab Amadotin"],
  },
  {
    primary_id: "D0A8XN",
    names: [
      "Labetalol",
      "Albetol",
      "Ibidomide",
      "Labetalolum",
      "Labetolol",
      "NORMOZIDE",
      "AH 5158",
      "AH-5158",
      "Labetalol (INN)",
      "Labetalol [INN:BAN]",
      "Labetalolum [INN-Latin]",
      "Normodyne (TN)",
      "Sch-19927",
      "Trandate (TN)",
      "2-Hydroxy-5-(1-Hydroxy-2-((1-Methyl-3-Phenylpropyl)Amino)Ethyl)Benzamide",
      "2-Hydroxy-5-[1-Hydroxy-2-(4-Phenylbutan-2-Ylamino)Ethyl]benzamide",
      "2-Hydroxy-5-{1-Hydroxy-2-[(1-Methyl-3-Phenylpropyl)Amino]ethyl}benzamide",
      "3-Carboxamido-4-Hydroxy-Alpha-((1-Methyl-3-Phenylpropylamino)Methyl)Benzyl Alcohol",
      "5-(1-Hydroxy-2-(1-Methyl-3-Phenylpropylamino)Ethyl)Salicylamide",
    ],
  },
  {
    primary_id: "D0A8XY",
    names: ["Altropane", "Carbon-11-Altropane", "Iodine-123-Altropane", "11C-Altropane", "123I-Altropane"],
  },
  {
    primary_id: "D0A8ZD",
    names: ["SR-33805"],
  },
  {
    primary_id: "D0A8ZM",
    names: ["S-(2-Boronoethyl)-L-Cysteine"],
  },
  {
    primary_id: "D0A8ZS",
    names: ["EtBut-RYYRIK-NH2", "CHEMBL443765"],
  },
  {
    primary_id: "D0A9AJ",
    names: ["H-MCpa-Ser-Gly-Phe-Leu-Thr-OH", "CHEMBL393867"],
  },
  {
    primary_id: "D0A9BI",
    names: ["Hi-8 PrimeBoost Therapeutic Melanoma Vaccine"],
  },
  {
    primary_id: "D0A9BK",
    names: [
      "1-Methyl-2-Oxy-5,5-Dimethyl Pyrrolidine",
      "1,5,5-Trimethylpyrrolidin-2-One",
      "119715-71-8",
      "AC1L9GJJ",
      "SCHEMBL4329315",
      "1,5,5-Trimethylpyrrolid-2one",
      "CHEBI:43533",
      "1,5,5-Trimethyl-2-Pyrrolidone",
      "1,5,5-Trimethylpyrrolid-2-One",
      "YARDEGUIPATLSG-UHFFFAOYSA-N",
      "1,5,5-Trimethyl-Pyrrolidin-2-One",
      "2-Pyrrolidinone, 1,5,5-Trimethyl-",
      "DB03968",
    ],
  },
  {
    primary_id: "D0A9BO",
    names: [
      "PG-120",
      "CD40 Receptor Agonist Antibody (B-Cell Lymphoma), PanGenetics",
      "CD40 Receptor Agonist Antibody (B-Cell Mediated Autoimmune Disease), PanGenetics",
    ],
  },
  {
    primary_id: "D0A9BU",
    names: ["CB-24"],
  },
  {
    primary_id: "D0A9BX",
    names: ["L-158282"],
  },
  {
    primary_id: "D0A9BZ",
    names: [
      "PSB-601",
      "CHEMBL212625",
      "MolPort-003-983-848",
      "BN0659",
      "BDBM50190709",
      "ZINC35923133",
      "8-[4-(4-Benzylpiperazide-1-Sulfonyl)Phenyl]-1-Propylxanthine",
    ],
  },
  {
    primary_id: "D0A9CK",
    names: ["S-4048"],
  },
  {
    primary_id: "D0A9CV",
    names: ["INX-3280", "BW 4003W94", "LR-3280", "TA-3280"],
  },
  {
    primary_id: "D0A9CY",
    names: ["Pentisomide"],
  },
  {
    primary_id: "D0A9CZ",
    names: ["PHASEOLLIDIN"],
  },
  {
    primary_id: "D0A9EA",
    names: ["PD-138917"],
  },
  {
    primary_id: "D0A9ED",
    names: [
      "Carazolol",
      "57775-29-8",
      "Conducton",
      "Suacron",
      "Corazolol",
      "Carazolol [INN:BAN]",
      "1-((9H-Carbazol-4-Yl)Oxy)-3-(Isopropylamino)Propan-2-Ol",
      "Carazololum [INN-Latin]",
      "CCRIS 1047",
      "Conducton Hydrochloride",
      "1-(Carbazol-4-Yloxy)-3-(Isopropylamino)-2-Propanol",
      "1-(9H-Carbazol-4-Yloxy)-3-(Isopropylamino)Propan-2-Ol",
      "EINECS 260-945-1",
      "BRN 3620576",
      "BM-51052",
      "BM 51052",
      "CHEMBL324665",
      "1-(9H-Carbazol-4-Yloxy)-3-(Propan-2-Ylamino)Propan-2-Ol",
      "1-(4-Carbazolyloxy)-3-Isopropylamino-2-Propanol",
    ],
  },
  {
    primary_id: "D0A9EI",
    names: ["ISIS 109114"],
  },
  {
    primary_id: "D0A9GE",
    names: ["FATE-NK100"],
  },
  {
    primary_id: "D0A9GI",
    names: ["ILV-095"],
  },
  {
    primary_id: "D0A9HL",
    names: [
      "1-Methyl-1,2-Dihydro-Indazol-3-One",
      "1006-19-5",
      "1-METHYL-1H-INDAZOL-3(2H)-ONE",
      "1H-Indazol-3-Ol, 1-Methyl-",
      "1,2-Dihydro-1-Methyl-3H-Indazol-3-One",
      "CHEMBL412905",
      "3H-Indazol-3-One, 1,2-Dihydro-1-Methyl-",
      "100922-97-2",
      "1-Methyl-2,3-Dihydro-1H-Indazol-3-One",
      "N-Methylindazolone",
      "1,2-Dihydro-1-Methylindazol-3-One",
      "NSC512755",
      "ACMC-1BUMW",
      "AC1L6WKF",
      "1-Methyl-3-Hydroxyindazole",
      "1-Methyl-2H-Indazol-3-One",
      "1-Methyl-1H-Indazole-3-Ol",
      "SCHEMBL2270654",
      "SCHEMBL12998307",
      "CTK0D9874",
      "KS-00000SDI",
      "ONNIFDMRZCMQQM-UHFFFAOYSA-N",
      "MolPort-035-688-834",
    ],
  },
  {
    primary_id: "D0A9HM",
    names: ["1-(2-Oxo-2-P-Tolyl-Ethyl)-1H-Indole-2,3-Dione", "CHEMBL84747", "AKOS009237487"],
  },
  {
    primary_id: "D0A9HR",
    names: ["Phthalazine Derivative 1"],
  },
  {
    primary_id: "D0A9HT",
    names: ["Avastin"],
  },
  {
    primary_id: "D0A9HU",
    names: [
      "2-(4-Chloro-2-Hydroxyphenoxy)Benzenaminium",
      "2-(2-Aminophenoxy)-5-Chlorophenol",
      "CHEMBL206178",
      "832734-14-2",
      "CTK3D3158",
      "DTXSID60658783",
      "BDBM50182380",
      "Phenol, 2-(2-Aminophenoxy)-5-Chloro-",
    ],
  },
  {
    primary_id: "D0A9IB",
    names: [
      "Methyl[4'-(Trifluoromethyl)-4-Biphenylyl]amine",
      "CHEMBL243640",
      "SCHEMBL5428301",
      "KPEUWXDPXPGBFC-UHFFFAOYSA-N",
      "ZINC28711877",
      "BDBM50220158",
      "AKOS023382952",
      "N-Methyl-4'-(Trifluoromethyl)-4-Biphenylamine",
    ],
  },
  {
    primary_id: "D0A9IM",
    names: [
      "(R)-Tacrine(10)-Hupyridone",
      "(R)-TACRINE(10)-HUPYRIDONE",
      "(5R)-5-{[10-(1,2,3,4-TETRAHYDROACRIDIN-9-YLAMINO)DECYL]AMINO}-5,6,7,8-TETRAHYDROQUINOLIN-2(1H)-ONE",
      "A1E",
      "1zgb",
      "AC1NS1ED",
      "DB04614",
      "(R)-N-9 -(1 ,2 ,3 ,4 -TETRAHYDROACRIDINYL)-N'-5 -[5 ,6 ,7 ,8 -TETRAHYDRO-2'(1'H)-QUINOLINONYL]-1,10-DIAMINODECANE",
      "(5R)-5-[10-(1,2,3,4-Tetrahydroacridine-9-Ylamino)Decylamino]-1,2,5,6,7,8-Hexahydroquinoline-2-One",
    ],
  },
  {
    primary_id: "D0A9IY",
    names: ["PF-4447943", "BCP16255"],
  },
  {
    primary_id: "D0A9JA",
    names: ["Anti-CD44 Mab"],
  },
  {
    primary_id: "D0A9JW",
    names: ["GPG", "AlfaHGA"],
  },
  {
    primary_id: "D0A9KF",
    names: ["Calcium Carbimide"],
  },
  {
    primary_id: "D0A9KH",
    names: ["PMID27539678-Compound-3"],
  },
  {
    primary_id: "D0A9KI",
    names: [
      "SB-242235",
      "193746-75-7",
      "SB242235",
      "SB 242235",
      "4-(4-(4-Fluorophenyl)-1-(Piperidin-4-Yl)-1H-Imidazol-5-Yl)-2-Methoxypyrimidine",
      "CHEMBL95692",
      "4-[5-(4-Fluorophenyl)-3-Piperidin-4-Ylimidazol-4-Yl]-2-Methoxypyrimidine",
      "4-[4-(4-Fluorophenyl)-1-(Piperidin-4-Yl)-1H-Imidazol-5-Yl]-2-Methoxypyrimidine",
      "Kinome_3169",
      "SCHEMBL2267209",
      "BDBM15458",
      "SYN1076",
      "PDTYLGXVBIWRIM-UHFFFAOYSA-N",
      "MolPort-028-720-427",
      "HMS3244I18",
      "HMS3244J17",
      "HMS3244I17",
      "EX-A1881",
      "BCP05992",
      "ZINC1487129",
      "3254AH",
      "RS0056",
      "AKOS027323444",
      "CS-2097",
      "NCGC00345831-01",
      "NCGC00345831-03",
    ],
  },
  {
    primary_id: "D0A9LB",
    names: ["ISIS 140153"],
  },
  {
    primary_id: "D0A9LX",
    names: ["Deoxyfluorothymidine"],
  },
  {
    primary_id: "D0A9ML",
    names: ["SelG1"],
  },
  {
    primary_id: "D0A9MY",
    names: [
      "N-(6-(4-Aminophenyl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1086638",
      "N-[6-(4-Aminophenyl)-1H-Indazol-3-Yl]butanamide",
      "SCHEMBL6478145",
      "DCCRABAZYMVLJU-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0A9NE",
    names: ["2-(3,4-Dihydroxy-Benzyl)-7-Hydroxy-Chromen-4-One", "CHEMBL56890", "ZINC13797584", "BDBM50077314"],
  },
  {
    primary_id: "D0A9OC",
    names: ["PMID26560530-Compound-4"],
  },
  {
    primary_id: "D0A9OR",
    names: ["SLV319"],
  },
  {
    primary_id: "D0A9OV",
    names: ["TAK165"],
  },
  {
    primary_id: "D0A9PD",
    names: ["Lu AF20513"],
  },
  {
    primary_id: "D0A9PN",
    names: ["GDC-0853"],
  },
  {
    primary_id: "D0A9PR",
    names: ["JNJ-61187191"],
  },
  {
    primary_id: "D0A9PS",
    names: [
      "Anandamide",
      "Arachidonylethanolamide",
      "Arachidonoyl Ethanolamide",
      "N-Arachidonoylethanolamine",
      "Anandamide (20.4, N-6)",
      "N-Arachidonoyl-2-Hydroxyethylamide",
      "94421-68-8",
      "AEA",
      "N-Arachidonoyl Ethanolamine",
      "N-(2-Hydroxyethyl)Anachidonamide",
      "5,8,11,14-Eicosatetraenoylethanolamide",
      "Arachidonoylethanolamide",
      "(5Z,8Z,11Z,14Z)-N-(2-Hydroxyethyl)Icosa-5,8,11,14-Tetraenamide",
      "Arachidonoyl-EA",
      "UNII-UR5G69TJKH",
      "Arachidonic Acid N-(Hydroxyethyl)Amide",
      "Anandamide(20:4, N-6)",
      "Anandamide (20:4, N-6)",
    ],
  },
  {
    primary_id: "D0A9PW",
    names: ["AMT-060", "AAV Gene Therapy (Hemophilia B), Amsterdam Molecular Therapeutics/St Jude"],
  },
  {
    primary_id: "D0A9QL",
    names: ["Ber-H2/Saporin"],
  },
  {
    primary_id: "D0A9QT",
    names: ["JNJ-28583867"],
  },
  {
    primary_id: "D0A9RM",
    names: ["Prunetin", "4',5-Dihydroxy-7-Methoxyisoflavone"],
  },
  {
    primary_id: "D0A9RY",
    names: ["Pyrimidine Derivative 10"],
  },
  {
    primary_id: "D0A9RZ",
    names: ["1,1-Dimethyl-4-Phenyl-Piperazin-1-Ium Iodide"],
  },
  {
    primary_id: "D0A9SA",
    names: ["Canfosfamide"],
  },
  {
    primary_id: "D0A9SS",
    names: ["Platinum-Based Chemotherapy Or PARP Inhibition"],
  },
  {
    primary_id: "D0A9TA",
    names: ["MTL-007", "CPF-31"],
  },
  {
    primary_id: "D0A9TR",
    names: ["XT-101"],
  },
  {
    primary_id: "D0A9UF",
    names: ["1,2,3,7,12,12a-Hexahydro-1-Aza-Pleiaden-5-Ol", "CHEMBL253530"],
  },
  {
    primary_id: "D0A9VC",
    names: ["Anticachexia Therapeutic"],
  },
  {
    primary_id: "D0A9VO",
    names: ["GYKI-52466"],
  },
  {
    primary_id: "D0A9VZ",
    names: ["F-G-G-F-T-G-A-R-K-S-A-R-K-L-Aib-N-Q-COOH"],
  },
  {
    primary_id: "D0A9WC",
    names: [
      "PT-319",
      "149485-99-4",
      "Thiourea, N-(2-(3-Methoxyphenyl)Ethyl)-N'-2-Thiazolyl-",
      "PETT Analog 6",
      "Thiourea, N-[2-(3-Methoxyphenyl)Ethyl]-N'-2-Thiazolyl-",
      "AC1MHDMC",
      "SCHEMBL6963302",
      "BDBM1899",
      "CHEMBL400733",
      "DTXSID80164295",
      "ZINC13744847",
      "AKOS030030940",
      "1-(2-Thiazolyl)-3-[3-Methoxyphenethyl]thiourea",
      "N-(2-(3-Methoxyphenethyl))-N -(2-Thiazolyl)Thiourea",
      "1-[2-(3-Methoxyphenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "1-[2-(3-Methoxyphenyl)Ethyl]-3-1,3-Thiazol-2-Ylthiourea",
    ],
  },
  {
    primary_id: "D0A9WP",
    names: ["Immucillin-G"],
  },
  {
    primary_id: "D0A9WT",
    names: ["5-Formyl-6-Hydrofolic Acid"],
  },
  {
    primary_id: "D0A9WW",
    names: ["FE-999040"],
  },
  {
    primary_id: "D0A9YA",
    names: [
      "Dutasteride",
      "Avodart",
      "Avolve",
      "Duagen",
      "Dutasteride [USAN]",
      "GG 745",
      "GI 198745",
      "Avidart (TN)",
      "Avodart (TN)",
      "Avodart, Dutasteride",
      "Avolve (TN)",
      "Duagen (TN)",
      "Duprost (TN)",
      "Dutagen (TN)",
      "Dutas (TN)",
      "GG-745",
      "GI-198745",
      "GI-198745X",
      "Dutasteride (JAN/USAN/INN)",
      "N-[2,5-Bis(Trifluoromethyl)Phenyl]-3-Oxo-4-Aza-5alpha-Androst-1-Ene-17beta-Carboxamide",
      "Alpha,alpha,alpha,alpha',alpha',alpha'-Hexafluoro-3-Oxo-4-Aza-5alpha-Androst-1-Ene-17beta-Carboxy-2',5'-Xylidide",
      "(1S,3aS,3bS,5aR,9aR,11aS)-N-[2,5-Bis(Trifluoromethyl)Phenyl]-9a,11a-Dimethyl-7-Oxo-1,2,3,3a,3b,4,5,5a,6,9b,10,11-Dodecahydroindeno[5,4-F]quinoline-1-Carboxamide",
      "(1S,3aS,3bS,5aR,9aR,9bS,11aS)-N-[2,5-Bis(Trifluoromethyl)Phenyl]-9a,11a-Dimethyl-7-Oxo-1,2,3,3a,3b,4,5,5a,6,9b,10,11-Dodecahydroindeno[5,4-F]quinoline-1-Carboxamide",
      "(5-Alpha,17-Beta)-N-{2,5 Bis(Trifluoromethyl)Phenyl}-3-Oxo-4-Azaandrost-1-Ene-17-Carboxamide;dutasteride",
      "(5alpha,17beta)-N-(2,5-Bis(Trifluoromethyl)Phenyl)-3-Oxo-4-Azaandrost-1-Ene-17-Carboxamide",
    ],
  },
  {
    primary_id: "D0A9YZ",
    names: ["APT-102"],
  },
  {
    primary_id: "D0A9ZA",
    names: ["NasoVac"],
  },
  {
    primary_id: "D0A9ZH",
    names: ["SPL-108"],
  },
  {
    primary_id: "D0A9ZP",
    names: ["IM-094882"],
  },
  {
    primary_id: "D0A9ZQ",
    names: ["(3-Phenoxy-Phenyl)-(5-Phenyl-Oxazol-2-Yl)-Amine"],
  },
  {
    primary_id: "D0A9ZW",
    names: ["PMID29130358-Compound-Figure16(9b)"],
  },
  {
    primary_id: "D0A9ZX",
    names: ["Pentamycin Vaginal Tablets"],
  },
  {
    primary_id: "D0AA0L",
    names: ["Pancuronium"],
  },
  {
    primary_id: "D0AA2D",
    names: [
      "Bifonazole",
      "Amycor",
      "Azolmen",
      "Bifokey",
      "Bifomyk",
      "Bifon",
      "Bifonazol",
      "Bifonazolum",
      "Moldina",
      "Mycospor",
      "Trifonazole",
      "Bayer Brand Of Bifonazole",
      "Bioglan Brand Of Bifonazole",
      "Canesten Extra Bifonazol",
      "Canesten Brand Of Bifonazole",
      "Dermapharm Brand Of Bifonazole",
      "Inkeysa Brand Of Bifonazole",
      "Juventus Brand Of Bifonazole",
      "Merck Lipha Sante Brand Of Bifonazole",
      "Bay H-4502",
      "Bifonazol [INN-Spanish]",
      "Bifonazolum [INN-Latin]",
      "Canespor (TN)",
      "Mycospor (TN)",
      "Bay-H-4502",
      "Bifonazole (JP15/USAN/INN)",
      "Bifonazole [USAN:BAN:INN:JAN]",
      "(+-)-1-([1,1'-Biphenyl]-4-Ylphenylmethyl)-1H-Imidazole",
      "(+-)-1-(P,alpha-Diphenylbenzyl)Imidazole",
      "1-((4-Biphenylyl)Phenylmethyl)-1H-Imidazole",
      "1-(Alpha-(4-Biphenylyl)Benzyl)Imidazole",
      "1-(P,alpha-Diphenylbenzyl)Imidazole",
      "1-[4,alpha-Diphenylbenzyl]-Imidazole",
      "1-[biphenyl-4-Yl(Phenyl)Methyl]-1H-Imidazole",
      "1-[phenyl-(4-Phenylphenyl)Methyl]imidazole",
    ],
  },
  {
    primary_id: "D0AA2X",
    names: ["PNT-500"],
  },
  {
    primary_id: "D0AA3P",
    names: ["PMID29649907-Compound-8"],
  },
  {
    primary_id: "D0AA4O",
    names: ["6H05"],
  },
  {
    primary_id: "D0AA5K",
    names: ["ACT-101"],
  },
  {
    primary_id: "D0AA6Z",
    names: ["Rambazole"],
  },
  {
    primary_id: "D0AA7U",
    names: ["PMID29757691-Compound-4"],
  },
  {
    primary_id: "D0AA8E",
    names: [
      "4,6-Dinitrobenzo[c][1,2,5]thiadiazole",
      "4,6-Dinitro-2,1,3-Benzothiadiazole",
      "CHEMBL206140",
      "16408-06-3",
      "NSC202427",
      "AC1L76UW",
      "AC1Q1Y7D",
      "C6H2N4O4S",
      "DTXSID90308123",
      "MolPort-000-820-133",
      "BYLOIWCTRDPRTP-UHFFFAOYSA-N",
      "ZINC1736331",
      "STK744333",
      "BDBM50182130",
      "5,7-Dinitro-2,1,3-Benzothiadiazole",
      "AKOS000544790",
      "NSC-202427",
      "MCULE-2301273439",
      "4,6-Dinitrobenzo[c]1,2,5-Thiadiazole",
      "4,6-Dinitro-Benzo[1,2,5]thiadiazole",
      "2,1,3-Benzothiadiazole, 4,6-Dinitro-",
      "4,6-Dinitro-2,1,3-Benzothiadiazole #",
      "NCI60_001699",
      "ST032375",
      "BAS 00731156",
    ],
  },
  {
    primary_id: "D0AA8R",
    names: ["Sotirimod"],
  },
  {
    primary_id: "D0AA9A",
    names: ["N-(4-Hydroxyphenylpropanyl)-Spermine", "CHEMBL1096289"],
  },
  {
    primary_id: "D0AA9F",
    names: ["Tricyclic Pyrrolidine Derivative 3"],
  },
  {
    primary_id: "D0AA9K",
    names: ["(+/-)-Threo-N-Ethylritalinol", "CHEMBL1195111", "ZINC28645416", "BDBM50212358"],
  },
  {
    primary_id: "D0AB2G",
    names: ["HL-022"],
  },
  {
    primary_id: "D0AB3D",
    names: ["Imidazo Bicyclic Iminium Derivative 5"],
  },
  {
    primary_id: "D0AB4Q",
    names: ["Ispronicline"],
  },
  {
    primary_id: "D0AB5X",
    names: ["PMID28460551-Compound-11"],
  },
  {
    primary_id: "D0AB7G",
    names: [
      "MCI-826",
      "(E)-2,2-Diethyl-3'-[2-[2-(4-Isopropyl)Thiazolyl]ethenyl]succinanilic Acid",
      "(E)-2,2-Diethyl-N-[3-[2-(4-Isopropylthiazol-2-Yl)Vinyl]phenyl]succinamic Acid",
    ],
  },
  {
    primary_id: "D0AC0A",
    names: ["SB-612111"],
  },
  {
    primary_id: "D0AC2H",
    names: ["Isonipecotic Acid"],
  },
  {
    primary_id: "D0AC4P",
    names: ["Benzimidazolone Acetamide Derivative 2"],
  },
  {
    primary_id: "D0AC6O",
    names: ["OBP-AI2"],
  },
  {
    primary_id: "D0AC7C",
    names: ["IPS-01003", "EGFR Kinase Antagonist (Cancer), InnoPharmaScreen"],
  },
  {
    primary_id: "D0AC8H",
    names: ["PMID25980951-Compound-36"],
  },
  {
    primary_id: "D0AC8L",
    names: ["Proxinium"],
  },
  {
    primary_id: "D0AC8W",
    names: ["RC-3095"],
  },
  {
    primary_id: "D0AC9S",
    names: ["CART-19/BCMA"],
  },
  {
    primary_id: "D0AD0B",
    names: ["HIV Vaccine Ad4-Clade C"],
  },
  {
    primary_id: "D0AD1L",
    names: ["PMBA", "3-[2'-Phosphonomethyl[1,1'-Biphenyl]-3-Yl]alanine"],
  },
  {
    primary_id: "D0AD3U",
    names: ["2-(3-Fluoro-4-Hydroxy-Phenyl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D0AD3V",
    names: ["PF-06647020"],
  },
  {
    primary_id: "D0AD4D",
    names: ["CXL-1020"],
  },
  {
    primary_id: "D0AD4H",
    names: ["Cethrin"],
  },
  {
    primary_id: "D0AD4Z",
    names: ["Fasinumab"],
  },
  {
    primary_id: "D0AD5C",
    names: [
      "Acarbose",
      "56180-94-0",
      "Glucobay",
      "Precose",
      "Prandase",
      "C25H43NO18",
      "CHEBI:2376",
      "CHEMBL3734896",
      "BAY-G 5421",
      "4,6-Dideoxy-4-{[(1S,4R,5S,6S)-4,5,6-Trihydroxy-3-(Hydroxymethyl)Cyclohex-2-En-1-Yl]amino}-Alpha-D-Glucopyranosyl-(1-&gt;4)-Alpha-D-Glucopyranosyl-(1-&gt;4)-D-Glucopyranose",
      "SMR000466376",
      "SR-01000759407",
      "Acarbose/",
      "Acarbose [USAN:BAN:INN:JAN]",
      "Acarbose,(S)",
      "Precose (TN)",
      "AC1L26GM",
      "Acarbose (JAN/USAN/INN)",
      "MLS006011898",
      "MLS000759506",
      "MLS001424056",
      "SPECTRUM1505172",
      "SCHEMBL5316305",
      "CHEMBL404271",
      "BDBM23406",
      "MolPort-002-507-369",
    ],
  },
  {
    primary_id: "D0AD5S",
    names: ["SRP-4053"],
  },
  {
    primary_id: "D0AD6Z",
    names: ["Mab 224G11"],
  },
  {
    primary_id: "D0AD7L",
    names: ["ISIS 103631"],
  },
  {
    primary_id: "D0AD7P",
    names: ["LY303336"],
  },
  {
    primary_id: "D0AD8G",
    names: ["N-Allyl-4-Hydroxy-N-Phenylbenzenesulfonamide", "CHEMBL426849"],
  },
  {
    primary_id: "D0AD8L",
    names: ["Vivia-577"],
  },
  {
    primary_id: "D0AD9Y",
    names: ["Kifunensine"],
  },
  {
    primary_id: "D0AE0M",
    names: ["PMID27774822-Compound-Figure10Compound4"],
  },
  {
    primary_id: "D0AE1E",
    names: ["Insulin Molecules, Novo", "B10Asp", "B28Asp"],
  },
  {
    primary_id: "D0AE1X",
    names: ["GS-9411"],
  },
  {
    primary_id: "D0AE3U",
    names: ["SAR-113945"],
  },
  {
    primary_id: "D0AE3X",
    names: [
      "Brimonidine",
      "59803-98-4",
      "Bromoxidine",
      "UK 14,304",
      "5-Bromo-N-(4,5-Dihydro-1H-Imidazol-2-Yl)-6-Quinoxalinamine",
      "5-Bromo-6-(2-Imidazolin-2-Ylamino)Quinoxaline",
      "UK-14304",
      "AGN 190342",
      "UNII-E6GNX3HHTE",
      "Brimonidine [INN:BAN]",
      "UK 14304",
      "C11H10BrN5",
      "MLS000069370",
      "6-Quinoxalinamine, 5-Bromo-N-(4,5-Dihydro-1H-Imidazol-2-Yl)-",
      "E6GNX3HHTE",
      "CHEMBL844",
      "AGN-190342",
      "BRN 0751629",
      "SMR000058355",
      "CHEBI:3175",
      "LK 14304-18",
      "Brimonidine, 98%",
      "NCGC00016069-09",
      "EN300-50880",
      "DSSTox_RID_80758",
      "Brimonidine (Bioerodible, Extended Release)",
      "[3H]brimonidine",
    ],
  },
  {
    primary_id: "D0AE5G",
    names: ["Phthalazine Derivative 2"],
  },
  {
    primary_id: "D0AE5K",
    names: ["Oxyntomodulin"],
  },
  {
    primary_id: "D0AE6Q",
    names: ["MBG453"],
  },
  {
    primary_id: "D0AE6Y",
    names: ["PMID26924192-Compound-106"],
  },
  {
    primary_id: "D0AE8D",
    names: ["Benzothiazole Analog 4"],
  },
  {
    primary_id: "D0AE9M",
    names: ["Butyl-Indol-1-Yl-Pyridin-4-Yl-Amine", "CHEMBL155109", "SCHEMBL7616007", "BDBM50048576"],
  },
  {
    primary_id: "D0AF0O",
    names: ["Cyclohexane Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0AF1J",
    names: [
      "PMID22795084C1",
      "Compound 1 (Allosteric)",
      "GTPL7032",
      "MolPort-019-906-830",
      "BDBM50427768",
      "ZINC75343937",
      "AKOS022046525",
      "MCULE-7514836384",
    ],
  },
  {
    primary_id: "D0AF3A",
    names: ["Protoporphyrin IX"],
  },
  {
    primary_id: "D0AF4A",
    names: ["PMID28870136-Compound-40"],
  },
  {
    primary_id: "D0AF4F",
    names: ["HO-4073"],
  },
  {
    primary_id: "D0AF4V",
    names: ["PMID30280939-Compound-WO200606195"],
  },
  {
    primary_id: "D0AF5P",
    names: ["RVT-505"],
  },
  {
    primary_id: "D0AF6O",
    names: ["Lorlatinib"],
  },
  {
    primary_id: "D0AF7D",
    names: ["APL-180", "Cardiovascular Agent (Coronary Heart Disease), Novartis"],
  },
  {
    primary_id: "D0AF7J",
    names: ["PMID25399762-Compound-Table1-C16"],
  },
  {
    primary_id: "D0AF7N",
    names: ["Fibrimage"],
  },
  {
    primary_id: "D0AF8E",
    names: ["BXCL101"],
  },
  {
    primary_id: "D0AF8R",
    names: ["P-216CM"],
  },
  {
    primary_id: "D0AG1N",
    names: ["Corplex Donepezil"],
  },
  {
    primary_id: "D0AG1Q",
    names: ["PMID15686941C13"],
  },
  {
    primary_id: "D0AG2A",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 4"],
  },
  {
    primary_id: "D0AG4J",
    names: ["IRX-01", "Anti-100S Antibody (Inflammatory Disease), Inflammatorx"],
  },
  {
    primary_id: "D0AG6K",
    names: ["MT201"],
  },
  {
    primary_id: "D0AG7M",
    names: ["Benzoic Acid Linked Peptide Analog 6"],
  },
  {
    primary_id: "D0AG7N",
    names: ["MEDI-575"],
  },
  {
    primary_id: "D0AG8B",
    names: ["2-Thiomorpholino-4H-Benzo[h]chromen-4-One", "CHEMBL360038", "SCHEMBL3537842", "BDBM50159639"],
  },
  {
    primary_id: "D0AG8L",
    names: ["BMS-986231"],
  },
  {
    primary_id: "D0AH1A",
    names: ["Benzamide Derivative 16"],
  },
  {
    primary_id: "D0AH1B",
    names: [
      "3-[4-Methyl-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 23b",
      "AC1O705F",
      "SCHEMBL4224796",
      "BDBM8606",
      "CHEMBL176009",
      "3-[(Z)-(4-Methyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0AH1H",
    names: ["Guanidoacetic Acid"],
  },
  {
    primary_id: "D0AH2V",
    names: ["RM-6427"],
  },
  {
    primary_id: "D0AH3R",
    names: ["Fluorinated Donepezil Derivative 2"],
  },
  {
    primary_id: "D0AH5P",
    names: ["ISIS 29201"],
  },
  {
    primary_id: "D0AH5T",
    names: [
      "TAM-67",
      "CHEMBL16410",
      "UNII-929J96FO8T",
      "82668-33-5",
      "JPC-211",
      "U-99194A Maleate",
      "929J96FO8T",
      "PNU-99194A Free Base",
      "5,6-Dimethoxy-N,n-Dipropylindan-2-Amine",
      "JPC 211",
      "Tocris-1357",
      "NCGC00016073-01",
      "2-Di-N-Propylamino-5,6-Dimethoxyindane",
      "Lopac-U-116",
      "1H-Inden-2-Amine, 2,3-Dihydro-5,6-Dimethoxy-N,N-Dipropyl-",
      "AC1L1KRQ",
      "Biomol-NT_000031",
      "AC1Q56MX",
      "Lopac0_001207",
      "ZINC7459",
      "SCHEMBL2640137",
      "BPBio1_001223",
      "CHEBI:93232",
      "DTXSID50274471",
      "BDBM50107876",
      "CCG-205281",
      "PNU-99,194",
      "API0008965",
      "NCGC00025123-03",
      "NCGC00025123-01",
      "NCGC00016073-02",
    ],
  },
  {
    primary_id: "D0AH6V",
    names: ["BN-2629"],
  },
  {
    primary_id: "D0AH8N",
    names: ["Imidamide Derivative 1"],
  },
  {
    primary_id: "D0AH8Q",
    names: ["4-(2-(Thiophen-2-Yl)-9H-Purin-6-Yl)Morpholine", "CHEMBL604876", "SCHEMBL4439490"],
  },
  {
    primary_id: "D0AH9P",
    names: ["PMID29324067-Compound-25"],
  },
  {
    primary_id: "D0AI1F",
    names: ["SiRNA Therapy, Bladder Cancer"],
  },
  {
    primary_id: "D0AI1I",
    names: ["Fused Triazoloamino-Phenyl Analog 1"],
  },
  {
    primary_id: "D0AI1L",
    names: ["ISIS 23416"],
  },
  {
    primary_id: "D0AI3G",
    names: [
      "HG-1224",
      "BSF3 Modulator (Immune/Neurological Diseases), Human Genome Sciences",
      "CLC Modulator (Immune/Neurological Diseases), Human Genome Sciences",
      "CLCF1 Modulator (Immune/Neurological Diseases), Human Genome Sciences",
      "Neutrophin 1 Modulator (Immune/Neurological Diseases), Human Genome Sciences",
      "B-Cell Stimulating Factor 3 Modulator (Immune/Neurological Diseases), Human Genome Sciences",
      "Cardiotrophin-Like Cytokine Factor 1 Modulator (Immune/Neurological Diseases), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0AI6J",
    names: ["PMID25399762-Compound-Table 6-10"],
  },
  {
    primary_id: "D0AI7H",
    names: ["Anti-MUC1 CAR T Cells"],
  },
  {
    primary_id: "D0AI8F",
    names: ["8-Bromo-9-Cyclopentyl-9H-Adenine", "CHEMBL449924", "SCHEMBL5732373"],
  },
  {
    primary_id: "D0AI8S",
    names: ["Heteroaryl-Azepine Derivative 1"],
  },
  {
    primary_id: "D0AI8V",
    names: ["DSP-1200"],
  },
  {
    primary_id: "D0AJ0H",
    names: ["ISIS 114527"],
  },
  {
    primary_id: "D0AJ0O",
    names: ["Heteroaryl-Azepine Derivative 5"],
  },
  {
    primary_id: "D0AJ2T",
    names: ["PMID28454500-Compound-13"],
  },
  {
    primary_id: "D0AJ3C",
    names: [
      "(R)-Zacopride",
      "CHEMBL27846",
      "UNII-38126EQF7A",
      "38126EQF7A",
      "ZACOPRIDE,S",
      "R-Zacopride",
      "(R)ZACOPRIDE",
      "(R)-(+)-Zacopride",
      "ZINC848",
      "GTPL2288",
      "SCHEMBL5387843",
      "BDBM50031475",
      "PDSP2_001683",
      "PDSP1_001700",
      "UNII-9GN3OT4156 Component FEROPKNOYKURCJ-ZDUSSCGKSA-N",
      "4-Amino-N-(R)-1-Aza-Bicyclo[2.2.2]oct-3-Yl-5-Chloro-2-Methoxy-Benzamide",
      "4-Amino-N-[(8R)-1-Azabicyclo[2.2.2]octan-8-Yl]-5-Chloro-2-Methoxybenzamide",
    ],
  },
  {
    primary_id: "D0AJ3F",
    names: ["Spiroimidazolone Derivative 7"],
  },
  {
    primary_id: "D0AJ3H",
    names: ["PMID26666989-Compound-Figure9middle07"],
  },
  {
    primary_id: "D0AJ4T",
    names: ["RU78262"],
  },
  {
    primary_id: "D0AJ5E",
    names: ["Rifaximin DR"],
  },
  {
    primary_id: "D0AJ5G",
    names: ["SID7969543"],
  },
  {
    primary_id: "D0AJ5L",
    names: [
      "Oxatomide",
      "Celtect",
      "Oxatimide",
      "Oxatomida",
      "Oxatomidum",
      "Oxetal",
      "Tinset",
      "CBMicro_024634",
      "KW 4354",
      "O 9387",
      "R 35443",
      "R35443",
      "Celtect (TN)",
      "KW-4354",
      "McN-JR 35443",
      "Oxatomida [INN-Spanish]",
      "Oxatomide (Tinset)",
      "Oxatomidum [INN-Latin]",
      "R 35,443",
      "R-35443",
      "Oxatomide (JAN/USAN/INN)",
      "Oxatomide [USAN:BAN:INN:JAN]",
      "1-(3-(4-(Diphenylmethyl)-1-Piperazinyl)Propyl)-1,3-Dihydro-2H-Benzimidazol-2-One",
      "1-(3-(4-(Diphenylmethyl)-1-Piperazinyl)Propyl)-2-Benzimidazolinone",
      "1-[3-(4-Benzhydryl-Piperazin-1-Yl)-Propyl]-1,3-Dihydro-Benzoimidazol-2-One",
      "1-[3-[4-(Diphenylmethyl)-1-Piperazinyl]propyl]-1,3-Dihydro-2H-Benzimidazol-2-One",
      "1-[3-[4-(Diphenylmethyl)-1-Piperazinyl]propyl]-2-Benzimidazolinone",
      "3-[3-(4-Benzhydrylpiperazin-1-Yl)Propyl]-1H-Benzimidazol-2-One",
    ],
  },
  {
    primary_id: "D0AJ5N",
    names: ["ISIS 23544"],
  },
  {
    primary_id: "D0AJ5T",
    names: ["PMID27841045-Compound-144"],
  },
  {
    primary_id: "D0AJ7H",
    names: ["TG-2001"],
  },
  {
    primary_id: "D0AJ9D",
    names: ["H-Dmt-Tic-Lys(Z)-NH-Ph"],
  },
  {
    primary_id: "D0AJ9V",
    names: ["ZW25"],
  },
  {
    primary_id: "D0AK0Z",
    names: ["ICT-69"],
  },
  {
    primary_id: "D0AK1F",
    names: ["Pyridine Derivative 18"],
  },
  {
    primary_id: "D0AK2V",
    names: ["AEH-10p", "AE-114", "AE-235", "AE-381", "AE-382"],
  },
  {
    primary_id: "D0AK2Y",
    names: ["PMID26651364-Compound-7b"],
  },
  {
    primary_id: "D0AK4H",
    names: ["AMG-429"],
  },
  {
    primary_id: "D0AK4K",
    names: [
      "ONO-AE3-208",
      "402473-54-5",
      "4-Cyano-2-[[2-(4-Fluoro-1-Naphthalenyl)-1-Oxopropyl]amino]benzenebutanoic Acid",
      "ONO AE3 208",
      "SCHEMBL679969",
      "GTPL1942",
      "CTK1D4873",
      "KS-00001DVO",
      "DTXSID20435810",
      "MolPort-035-765-695",
      "3952AH",
      "AKOS024457689",
      "4-[4-Cyano-2-[2-(4-Fluoronaphthalen-1-Yl)Propanoylamino]phenyl]butanoic Acid",
      "CS-0315",
      "NCGC00378671-02",
      "HY-50901",
      "AK547789",
      "DB-030557",
      "FT-0715398",
      "ONO-AE3-208, &gt",
      "W-5560",
      "473O545",
      "4-(4-Cyano-2-(2-(4-Fluoronaphthalen-1-Yl)Propanamido)Phenyl)Butanoic Acid",
    ],
  },
  {
    primary_id: "D0AK4X",
    names: ["Tetra-Hydro-Oxazolopyridine Derivative 5"],
  },
  {
    primary_id: "D0AK5G",
    names: ["RPAF-AH"],
  },
  {
    primary_id: "D0AK5R",
    names: ["Floctafenine"],
  },
  {
    primary_id: "D0AK5Y",
    names: ["Oxindole Derivative 1"],
  },
  {
    primary_id: "D0AK6L",
    names: ["Amidine Compound 4"],
  },
  {
    primary_id: "D0AK8E",
    names: [
      "K-101",
      "Antifungal Topical Drug Combination (Kaprolac, Onychomycosis), Moberg",
      "Urea + PEG/Propylene Glycol Formulation (Onychomycosis), Moberg",
    ],
  },
  {
    primary_id: "D0AK8Q",
    names: ["N-Hydroxy-E-3-(Biphenyl-4-Yl)-Acrylamide", "CHEMBL556532", "SCHEMBL3292226", "SCHEMBL3290139"],
  },
  {
    primary_id: "D0AK9S",
    names: [
      "3-[1-(4-Bromobenzyl)-1H-Imidazol-5-Yl]-1-Propanol",
      "CHEMBL598810",
      "SCHEMBL3823053",
      "HNOJMJNDVRPLSW-UHFFFAOYSA-N",
      "BDBM50307229",
      "1-P-Bromobenzyl-5-(3-Hydroxypropyl)-1H-Imidazole",
    ],
  },
  {
    primary_id: "D0AK9V",
    names: ["VRX-03011", "5-HT4 Agonist (Cognition/Neuroprotectant), EPIX"],
  },
  {
    primary_id: "D0AK9X",
    names: [
      "4-Amino-3-(5-Chloro-Thiophen-2-Yl)-Butyric Acid",
      "133933-81-0",
      "4-Amino-3-(5-Chlorothiophen-2-Yl)Butanoic Acid",
      "CHEMBL87482",
      "4-AMINO-3- (5-CHLOROTHIEN-2-YL)BUTANOIC ACID",
      "2-Thiophenepropanoicacid, B-(Aminomethyl)-5-Chloro-",
      "(+/-)-4-AMINO-3-(5-CHLORO-2-THIENYL)-BUTANOIC ACID",
      "4-Actba",
      "KBio3_002901",
      "ACMC-20mv5p",
      "AC1L2PFH",
      "Spectrum2_001171",
      "Spectrum3_001889",
      "Spectrum4_000231",
      "Spectrum5_001875",
      "4-Amino-3-(5-Chloro-2-Thienyl)Butyric Acid",
      "Biomol-NT_000250",
      "BSPBio_003398",
      "KBioGR_000921",
      "SCHEMBL342009",
      "SPBio_001181",
      "BPBio1_000706",
    ],
  },
  {
    primary_id: "D0AL0S",
    names: ["GSK-1362885", "1362885"],
  },
  {
    primary_id: "D0AL2D",
    names: ["4-{4-[(Aminosulfonyl)Oxy]benzoyl}phenyl Sulfamate", "CHEMBL67175", "SCHEMBL2501164"],
  },
  {
    primary_id: "D0AL2E",
    names: ["3-(Pyridin-4-Yl)-1H-Indol-7-Amine", "CHEMBL207262", "SCHEMBL3500355"],
  },
  {
    primary_id: "D0AL2R",
    names: ["ISIS 28465"],
  },
  {
    primary_id: "D0AL3G",
    names: [
      "1-(3,4-Dichlorobenzyl)-1H-Imidazole",
      "CHEMBL379436",
      "56643-72-2",
      "1-(3,4-Dichloro-Benzyl)-1H-Imidazole",
      "1-(3,4-Dichlorobenzyl)Imidazole",
      "BAS 11721008",
      "AC1MGEX9",
      "CTK1F4149",
      "DTXSID40388096",
      "MolPort-000-511-619",
      "ZINC4385934",
      "BDBM50188095",
      "STK951804",
      "AKOS000586982",
      "[(3,4-Dichlorophenyl)Methyl]imidazole",
      "MCULE-5938366655",
      "1-[(3,4-Dichlorophenyl)Methyl]imidazole",
      "ST50162194",
      "1H-Imidazole, 1-[(3,4-Dichlorophenyl)Methyl]-",
      "SR-01000301759",
    ],
  },
  {
    primary_id: "D0AL3I",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 16"],
  },
  {
    primary_id: "D0AL3O",
    names: ["VX-366"],
  },
  {
    primary_id: "D0AL8M",
    names: ["IMRECOXIB"],
  },
  {
    primary_id: "D0AL8P",
    names: ["ISIS 107772"],
  },
  {
    primary_id: "D0AL8T",
    names: ["Darbufelone"],
  },
  {
    primary_id: "D0AM0A",
    names: ["Aryl Mannoside Derivative 15"],
  },
  {
    primary_id: "D0AM1D",
    names: ["Diaryl Piperazine Derivative 2"],
  },
  {
    primary_id: "D0AM1S",
    names: [
      "CCD-3693",
      "CCD 3000 Series, CoCensys",
      "CCD-3393",
      "Epalon Series Member (3693), CoCensys",
      "Insomnia Therapy, CoCensys/Searle",
    ],
  },
  {
    primary_id: "D0AM2B",
    names: ["PMID25991433-Compound-A8"],
  },
  {
    primary_id: "D0AM2D",
    names: ["Heterocyclic Derivative 8"],
  },
  {
    primary_id: "D0AM2Z",
    names: ["3-(4-Amino-Phenyl)-3-Propyl-Piperidine-2,6-Dione", "CHEMBL29864", "BDBM50025059"],
  },
  {
    primary_id: "D0AM4P",
    names: ["Interferon Gamma-N1"],
  },
  {
    primary_id: "D0AM6F",
    names: [
      "ETX-1153c",
      "Clostriban",
      "Antivira L+ Antifungal (Oral, Clostridium Difficile Infection), E-Therapeutics",
    ],
  },
  {
    primary_id: "D0AM8W",
    names: ["Zanapezil"],
  },
  {
    primary_id: "D0AN1Y",
    names: [
      "L-670596",
      "121083-05-4",
      "L-670,596",
      "L 670596",
      "2-(6,8-Difluoro-9-(4-(Methylsulfonyl)Benzyl)-2,3,4,9-Tetrahydro-1H-Carbazol-1-Yl)Acetic Acid",
      "(-)-6-8-DIFLUORO-2,3,4,9-TETRAHYDRO-9-[[4-(METHYLSULFONYL)PHENYL]METHYL]-1H-CARBAZOLE-1-ACETIC ACID",
      "AC1L2VGU",
      "SCHEMBL3044967",
      "CHEBI:91930",
      "CTK8F0755",
      "MolPort-003-983-606",
      "HMS3268M18",
      "BCP28232",
      "BN0288",
      "AKOS022180202",
      "RT-013483",
      "FT-0757831",
      "SR-01000597550",
      "L002392",
      "C-35484",
      "SR-01000597550-1",
      "J-004444",
      "BRD-A85472596-001-01-4",
    ],
  },
  {
    primary_id: "D0AN2Q",
    names: ["ISOTETRANDRINE"],
  },
  {
    primary_id: "D0AN3N",
    names: ["SGT-001"],
  },
  {
    primary_id: "D0AN4K",
    names: ["NMS-1286937"],
  },
  {
    primary_id: "D0AN6L",
    names: ["A 137491", "ABT 491"],
  },
  {
    primary_id: "D0AN7B",
    names: [
      "Melatonin",
      "73-31-4",
      "Melatonine",
      "N-Acetyl-5-Methoxytryptamine",
      "Circadin",
      "5-Methoxy-N-Acetyltryptamine",
      "N-[2-(5-Methoxy-1H-Indol-3-Yl)Ethyl]acetamide",
      "N-(2-(5-Methoxy-1H-Indol-3-Yl)Ethyl)Acetamide",
      "Melatol",
      "Melovine",
      "Melatonex",
      "N-[2-(5-Methoxyindol-3-Yl)Ethyl]acetamide",
      "Acetamide, N-[2-(5-Methoxy-1H-Indol-3-Yl)Ethyl]-",
      "UNII-JL5DK93RCL",
      "N-(2-(5-Methoxyindol-3-Yl)Ethyl)Acetamide",
      "N-Acetyl-5-Methoxy-Tryptamine",
      "NSC 113928",
      "CCRIS 3472",
      "CHEMBL45",
      "EINECS 200-797-7",
      "JL5DK93RCL",
      "Acetamide, N-(2-(5-Methoxyindol-3-Yl)Ethyl)-",
      "BRN 0205542",
      "Melapure",
      "Melatonina",
      "Posidorm",
      "Vivitas",
      "Night Rest",
      "Pineal Hormone",
      "Revital Melatonin",
      "Rx Balance",
      "Sleep Right",
      "IN1244",
      "M 5250",
      "M1105",
      "ML1",
      "MT6",
      "TNP00300",
      "M-1200",
      "M-1250",
      "Mela-T",
      "Melatonex, Melatonin",
      "Melatonina (TN)",
      "NMR/14327425",
      "Nature'S Harmony",
      "PREVENTION 2 (MELATONIN)",
      "PREVENTION 3 (MELATONIN)",
      "PREVENTION 4 (MELATONIN)",
      "PREVENTION 5 (MELATONIN)",
      "PREVENTION 1 (MELATONIN) (PREVENTION 1)",
      "Acetamide, {N-[2-(5-Methoxyindol-3-Yl)Ethyl]-}",
      "Acetamide, N-[2-(5-Methoxyindol-3-Yl)Ethyl]-(6CI,8CI)",
      "N-[2-(5-Methoxy-1H-Indol-3-Yl)-Ethyl]-Acetamide",
      "Acetamide, N-[2-(5-Methoxy-1H-Indol-3-Yl)Ethyl]-(9CI)",
      "Acetamide, {N-[2-(5-Methoxy-1H-Indol-3-Yl)Ethyl]-}",
      "Acetamide, N-(2-(5-Methoxy-1H-Indol-3-Yl)Ethyl)-(9CI)",
      "4-ACETAMIDO-4'-ISOTHIO-CYANATOSTILBENE-2,2'-DISULFONIC ACID",
      "[3H]melatonin",
    ],
  },
  {
    primary_id: "D0AN7U",
    names: ["NSC-316158"],
  },
  {
    primary_id: "D0AN7Z",
    names: ["Phthalazine Ketone Derivative 3"],
  },
  {
    primary_id: "D0AO0K",
    names: ["CNT0-5"],
  },
  {
    primary_id: "D0AO5D",
    names: ["1400W"],
  },
  {
    primary_id: "D0AO5F",
    names: ["JOT101"],
  },
  {
    primary_id: "D0AO5H",
    names: [
      "Trimethoprim",
      "Abaprim",
      "Alprim",
      "Anitrim",
      "Antrima",
      "Antrimox",
      "Bacdan",
      "Bacidal",
      "Bacide",
      "Bacin",
      "Bacta",
      "Bacterial",
      "Bacticel",
      "Bactin",
      "Bactoprim",
      "Bactramin",
      "Bencole",
      "Bethaprim",
      "Biosulten",
      "Briscotrim",
      "Chemotrin",
      "Cidal",
      "Colizole",
      "Conprim",
      "Cotrimel",
      "Deprim",
      "Duocide",
      "Esbesul",
      "Espectrin",
      "Euctrim",
      "Exbesul",
      "Fermagex",
      "Fortrim",
      "Futin",
      "Idotrim",
      "Ikaprim",
      "Instalac",
      "Kombinax",
      "Lagatrim",
      "Lastrim",
      "Methoprim",
      "Metoprim",
      "Monoprim",
      "Monotrim",
      "Monotrimin",
      "Novotrimel",
      "Omstat",
      "Oraprim",
      "Pancidim",
      "Polytrim",
      "Priloprim",
      "Primosept",
      "Primsol",
      "Proloprim",
      "Protrin",
      "Purbal",
      "Resprim",
      "Roubac",
      "Roubal",
      "Salvatrim",
      "Setprin",
      "Sinotrim",
      "Stopan",
      "Streptoplus",
      "Sugaprim",
      "Sulfamar",
      "Sulfamethoprim",
      "Sulfoxaprim",
      "Sulmeprim",
      "Sulthrim",
      "Sultrex",
      "Syraprim",
      "Tiempe",
      "Toprim",
      "Trimanyl",
      "Trimethioprim",
      "Trimethoprime",
      "Trimethoprimum",
      "Trimethopriom",
      "Trimetoprim",
      "Trimetoprima",
      "Trimexazole",
      "Trimexol",
      "Trimezol",
      "Trimogal",
      "Trimono",
      "Trimopan",
      "Trimpex",
      "Triprim",
      "Trisul",
      "Trisulcom",
      "Trisulfam",
      "Trisural",
      "Uretrim",
      "Urobactrim",
      "Utetrin",
      "Velaten",
      "Wellcoprim",
      "Wellcoprin",
      "Xeroprim",
      "Zamboprim",
      "Bacterial [Antibiotic]",
      "Colizole DS",
      "Component Of Bactrim",
      "Component Of Septra",
      "Lagatrim Forte",
      "ResprimForte",
      "Septrin DS",
      "Septrin Forte",
      "Septrin S",
      "Trimetoprim [DCIT]",
      "Trimetoprim [Polish]",
      "BW 5672",
      "KUC103659N",
      "NIH 204",
      "T 7883",
      "Trimpex 200",
      "WR 5949",
      "Alcorim-F",
      "Apo-Sulfatrim",
      "BW 56-72",
      "Co-Trimoxizole",
      "Monotrim (TN)",
      "NIH 204 (VAN)",
      "Proloprim (TN)",
      "Smz-Tmp",
      "Sulfamethoxazole & Trimethoprim",
      "TCMDC-125538",
      "Tmp-Ratiopharm",
      "Trimeth/Sulfa",
      "Trimethopim(TMP)",
      "Trimethoprim & VRC3375",
      "Trimethoprime [INN-French]",
      "Trimethoprimum [INN-Latin]",
      "Trimetoprima [INN-Spanish]",
      "Trimez-IFSA",
      "Trimpex (TN)",
      "Triprim (TN)",
      "U-Prin",
      "Uro-D S",
      "BW-56-72",
      "KSC-4-158",
      "AZT + TMP/SMX (Mixture) Combination",
      "Trimethoprim (JAN/USP/INN)",
      "Trimethoprim [USAN:BAN:INN:JAN]",
    ],
  },
  {
    primary_id: "D0AO6B",
    names: ["Piperacetazine"],
  },
  {
    primary_id: "D0AO6T",
    names: ["3-(3,4-Difluoro-Phenyl)-6,7-Dimethoxy-Quinoline", "CHEMBL67424", "ZINC3834040"],
  },
  {
    primary_id: "D0AO8L",
    names: ["NY-ESO-1 T-Cell Receptor Therapy"],
  },
  {
    primary_id: "D0AO9Q",
    names: ["SYNT001"],
  },
  {
    primary_id: "D0AO9S",
    names: [
      "Enflurane",
      "Alyrane",
      "Efrane",
      "Enflurano",
      "Enfluranum",
      "Enfran",
      "Enlirane",
      "Ethrane",
      "Etran",
      "Methylflurether",
      "Abbott Brand Of Enflurane",
      "AstraZeneca Brand Of Enflurane",
      "Baxter Anaesthesia Brand Of Enflurane",
      "Pisa Brand Of Enflurane",
      "Zeneca Brand Of Enflurane",
      "Anesthetic 347",
      "C 347",
      "OHIO 347",
      "Anesthetic Compound No. 347",
      "Enflurane [Anaesthetics,volatile]",
      "Enflurano [INN-Spanish]",
      "Enfluranum [INN-Latin]",
      "Ethrane (TN)",
      "Enflurane (JP15/USP/INN)",
      "Enflurane [USAN:BAN:INN:JAN]",
      "Ether, 2-Chloro-1,1,2-Trifluoroethyl Difluoromethyl",
      "(+-)-2-Chloro-1,1,2-Trifluoroethyl Difluoromethyl Ether",
      "2-Chloro-1,1,2-Trifluoroethyl Difluoromethyl Ether",
      "2-Chloro-1-(Difluoromethoxy)-1,1,2-Trifluoroethane",
    ],
  },
  {
    primary_id: "D0AP1F",
    names: ["PMID27599163-Compound-81"],
  },
  {
    primary_id: "D0AP4O",
    names: ["Gem 92"],
  },
  {
    primary_id: "D0AP4V",
    names: ["Pyridoindole Derivative 2"],
  },
  {
    primary_id: "D0AP5M",
    names: ["PMID19191557C3"],
  },
  {
    primary_id: "D0AP6N",
    names: ["1-Butyl-3-(2-Dimethylamino-Ethyl)-1H-Indol-4-Ol", "CHEMBL198124"],
  },
  {
    primary_id: "D0AP6V",
    names: ["Poly-Substituted Azoles Statin Lactone Derivative 2"],
  },
  {
    primary_id: "D0AP7A",
    names: ["BMS-986258"],
  },
  {
    primary_id: "D0AP9E",
    names: ["Trilaciclib"],
  },
  {
    primary_id: "D0AP9T",
    names: [
      "Cefotetan",
      "Cefotetanum",
      "Apatef (TN)",
      "Cefotan (TN)",
      "Cefotetan (JP15/USP/INN)",
      "(6R,7S)-7-(4-(Carbamoylcarboxymethylene)-1,3-Dithiethane-2-Carboxamido)-7-Methoxy-3-(((1-Methyl-1H-Tetrazol-5-Yl)Thio)Methyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7S)-7-({[4-(2-Amino-1-Carboxy-2-Oxoethylidene)-1,3-Dithietan-2-Yl]carbonyl}amino)-7-Methoxy-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7S)-7-[[4-(1-Amino-3-Hydroxy-1,3-Dioxopropan-2-Ylidene)-1,3-Dithietane-2-Carbonyl]amino]-7-Methoxy-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-({[4-(2-Amino-1-Carboxy-2-Oxoethylidene)-1,3-Dithietan-2-Yl]carbonyl}amino)-7alpha-Methoxy-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0AQ1T",
    names: ["Pyrazole Derivative 73"],
  },
  {
    primary_id: "D0AQ3H",
    names: ["PF-610355"],
  },
  {
    primary_id: "D0AQ3K",
    names: ["3-[9-(Benzylmethylamino)Nonyloxy]xanthen-9-One", "CHEMBL427090"],
  },
  {
    primary_id: "D0AQ3U",
    names: ["PMID25666693-Compound-153"],
  },
  {
    primary_id: "D0AQ4J",
    names: ["Keytruda"],
  },
  {
    primary_id: "D0AQ8F",
    names: ["ETV-005"],
  },
  {
    primary_id: "D0AR0B",
    names: ["ACAM-2000", "Acambis 2000", "Smallpox Vaccine, Acambis/Baxter"],
  },
  {
    primary_id: "D0AR0H",
    names: [
      "FM1-43",
      "149838-22-2",
      "UNII-83IGZ86I9D",
      "83IGZ86I9D",
      "CHEBI:52077",
      "N-(3-(Triethylammonium)Propyl)-4-(4-(Dibutylamino)Styryl)Pyridinium",
      "Fm1 43",
      "CHEMBL257431",
      "FM 1-43",
      "SynaptoGreen&amp",
      "C4",
      "FM 1-43 Dye",
      "AC1O6V5E",
      "SCHEMBL1527120",
      "N-(3-(Triethylammonium)Propyl)-4-(2-(4-Dibutylaminophenyl)Vinyl)Pyridinium",
      "AKOS000814323",
      "SynaptoGreen(TM) C4, &gt",
      "=95% (HPLC), Solid",
      "N-(3-Triethylammoniumpropyl)-4-(4-(Dibutylamino)Styryl)Pyridinium Dibromide",
      "4-[2-[4-(Dibutylamino)Phenyl]ethenyl]-1-[3-(Triethylammonio)Propyl]-Pyridiniumbromide",
    ],
  },
  {
    primary_id: "D0AR0L",
    names: ["1-Phenyl-1,3,4-Triazole Derivative 2"],
  },
  {
    primary_id: "D0AR1Y",
    names: ["1,4-Bis(Malimido)Xylene", "CHEMBL565711", "SCHEMBL146412", "BDBM50300349"],
  },
  {
    primary_id: "D0AR3J",
    names: [
      "Ouabain",
      "Acocantherin",
      "Acocantherine",
      "Astrobain",
      "Gratibain",
      "Kombetin",
      "OBN",
      "Ouabaine",
      "Oubain",
      "Purostrophan",
      "Quabain",
      "Rectobaina",
      "Solufantina",
      "Strodival",
      "Strophalen",
      "Strophoperm",
      "Strophosan",
      "Uabaina",
      "Uabanin",
      "Acolongifloroside K",
      "Gratus Strophanthin",
      "Ouabain Anhydrous",
      "Ouabain Octahydrate",
      "Strophanthin G",
      "O 3125",
      "G-Strophanthin",
      "G-Strophicor",
      "Ouabagenin L-Rhamnoside",
      "Ouabain (Anhydrous)",
      "Ouabain, Octahydrate",
      "Strophanthin-G",
      "G-Strophanthin (JAN)",
      "Ouabagenin-L-Rhamnosid",
      "Ouabagenin-L-Rhamnosid [German]",
      "3-((6-Deoxyhexopyranosyl)Oxy)-1,5,11,14,19-Pentahydroxycard-20(22)-Enolide",
      "3-[(6-Deoxyhexopyranosyl)Oxy]-1,5,11,14,19-Pentahydroxycard-20(22)-Enolide",
    ],
  },
  {
    primary_id: "D0AR4P",
    names: ["2-(9-Benzyl-9H-Purin-6-Ylamino)-Ethanol"],
  },
  {
    primary_id: "D0AR5N",
    names: ["BMS-824383"],
  },
  {
    primary_id: "D0AR5O",
    names: ["PMID27539678-Compound-4"],
  },
  {
    primary_id: "D0AR6B",
    names: ["Peptide Analog 13"],
  },
  {
    primary_id: "D0AR7L",
    names: ["SAR-389644"],
  },
  {
    primary_id: "D0AR8G",
    names: ["Habeo"],
  },
  {
    primary_id: "D0AS0V",
    names: ["5,8-Dihydroxy-1,4-Naphthoquinone"],
  },
  {
    primary_id: "D0AS2P",
    names: [
      "Spheron-Based Therapeutics",
      "Alzheimers Therapeutics, Nymox",
      "NXD-1191",
      "NXD-2858",
      "NXD-3109",
      "NXD-3702",
      "Spheron-Based Therapeutics (Alzheimers)",
      "Spheron-Based Therapeutics (Alzheimers), Nymox",
    ],
  },
  {
    primary_id: "D0AS4C",
    names: ["Arylimidamides", "Arylimidamides (Leishimaniasis),UNC/Georgia State University/CPDD)"],
  },
  {
    primary_id: "D0AS4D",
    names: ["Unituxindinutuximab"],
  },
  {
    primary_id: "D0AS9Q",
    names: ["Glycoursodeoxycholic Acid"],
  },
  {
    primary_id: "D0AT0A",
    names: ["PMID27788040-Compound-5a"],
  },
  {
    primary_id: "D0AT2Z",
    names: ["3-Phenylsulfanylmethyl-Quinoxaline-5,7-Diamine", "CHEMBL141548"],
  },
  {
    primary_id: "D0AT3U",
    names: ["N-(4-Amino-2-Propylquinolin-6-Yl)Cinnamamide", "CHEMBL385639"],
  },
  {
    primary_id: "D0AT5L",
    names: [
      "[125I]AB-MECA",
      "[125I]5-[6-[(4-Aminophenyl)Methylamino]purin-9-Yl]-3,4-Dihydroxy-N-Methyl-Oxolane-2-Carboxamide",
      "[125I]N(6)-(4-Amino-3-Iodobenzyl)Adenosine-5'-N-Methyluronamide",
      "[125I]-AB-MECA",
    ],
  },
  {
    primary_id: "D0AT5N",
    names: ["4-Arylphthalazin-1(2H)-3,4-Di-F", "CHEMBL1934835", "GTPL7753", "BDBM50360858"],
  },
  {
    primary_id: "D0AT5Y",
    names: ["Radiolabeled VEGF"],
  },
  {
    primary_id: "D0AT6Z",
    names: ["16-(3-Ethylureido)Hexadec-11(Z)-Enoic Acid", "CHEMBL560030", "SCHEMBL3702468", "BDBM50295084"],
  },
  {
    primary_id: "D0AT8C",
    names: ["Pegfilgrastim"],
  },
  {
    primary_id: "D0AT9E",
    names: ["Piperazinyl Methyl Quinazolinone Derivative 1"],
  },
  {
    primary_id: "D0AU0F",
    names: ["UR-PG146", "CHEMBL513056", "GTPL1200", "BDBM50413173"],
  },
  {
    primary_id: "D0AU0M",
    names: ["P-Coumaric Acid"],
  },
  {
    primary_id: "D0AU2T",
    names: ["REGN1979"],
  },
  {
    primary_id: "D0AU3O",
    names: ["Aminocyclopentenone Compound 6"],
  },
  {
    primary_id: "D0AU4C",
    names: ["AEKKDEGPYRMEHFRWGSPPKD"],
  },
  {
    primary_id: "D0AU4G",
    names: ["3-Amino-5-Benzyl-Substituted Indazole Derivative 1"],
  },
  {
    primary_id: "D0AU4H",
    names: ["Thiamin Diphosphate"],
  },
  {
    primary_id: "D0AU6T",
    names: ["8-(3-Methoxy-3-Methyl-Butoxy)-Quinolin-2-Ylamine", "CHEMBL361288", "SCHEMBL5884407"],
  },
  {
    primary_id: "D0AU7Y",
    names: ["2-P-Tolyl-6H-Pyrazolo[1,5-C]quinazolin-5-One"],
  },
  {
    primary_id: "D0AU8E",
    names: ["PMID27841045-Compound-133"],
  },
  {
    primary_id: "D0AU9U",
    names: ["IONIS-GCGRRX"],
  },
  {
    primary_id: "D0AV0O",
    names: ["OPC-51803"],
  },
  {
    primary_id: "D0AV1B",
    names: ["Fused Heterocyclic Compound 2"],
  },
  {
    primary_id: "D0AV1O",
    names: ["Oral Contraceptive"],
  },
  {
    primary_id: "D0AV1T",
    names: ["N-(2-Iodethyl)Arachidonylamide"],
  },
  {
    primary_id: "D0AV3G",
    names: [
      "Domperidone",
      "Domperidon",
      "Domperidona",
      "Domperidonum",
      "Motilium",
      "Nauzelin",
      "KW 5338",
      "NCI299589",
      "Costi (TN)",
      "D-122",
      "Domperidona [INN-Spanish]",
      "Domperidonum [INN-Latin]",
      "HS-0067",
      "KW-5338",
      "Motilium (TN)",
      "Motillium (TN)",
      "Motinorm (TN)",
      "R 33,812",
      "R-33812",
      "R-33,812",
      "Domperidone (JAN/USAN/INN)",
      "Domperidone [USAN:BAN:INN:JAN]",
      "4-(5-Chloro-2-Oxo-1-Benzimidazolinyl)-1-[3-(2-Oxobenzimidazolinyl)Propyl]piperidine",
      "5-Chloro-1-(1-(3-(2-Oxo-1-Benzimidazolinyl)Propyl)-4-Piperidyl)-2-Benzimidazolinone",
      "5-Chloro-1-[1-[3-(2,3-Dihydro-2-Oxo-1H-Benzimidazol-1-Yl)Propyl]-4-Piperidinyl]-1,3-Dihydro-2H-Benzimidazol-2-One",
      "5-Chloro-1-[1-[3-(2-Oxo-1-Benzimidazolinyl)Propyl]-4-Piperidyl]-2-Benzimidazolinone",
      "5-Chloro-1-(1-(3-(2-Oxo-2,3-Dihydro-1H-Benzimidazol-1-Yl)Propyl)-4-Piperidinyl)-1,3-Dihydro-2H-Benzimidazol-2-One",
      "5-Chloro-1-{1-[3-(2-Oxo-2,3-Dihydro-1H-Benzimidazol-1-Yl)Propyl]piperidin-4-Yl}-1,3-Dihydro-2H-Benzimidazol-2-One",
      "6-Chloro-3-[1-[3-(2-Oxo-3H-Benzimidazol-1-Yl)Propyl]piperidin-4-Yl]-1H-Benzimidazol-2-One",
    ],
  },
  {
    primary_id: "D0AV3M",
    names: ["N-(4'-Isopropyl-4-Biphenylyl)Urea", "CHEMBL397249", "SCHEMBL5427819", "BDBM50220192", "ZINC28712010"],
  },
  {
    primary_id: "D0AV8R",
    names: ["Tricyclic Pyrrolidine Derivative 2"],
  },
  {
    primary_id: "D0AW3F",
    names: ["PMID27336223-Compound-5"],
  },
  {
    primary_id: "D0AW4G",
    names: [
      "4-Cyanophenylboronic Acid",
      "126747-14-6",
      "(4-Cyanophenyl)Boronic Acid",
      "4-Cyanobenzeneboronic Acid",
      "4-Cyanophenyl Boronic Acid",
      "4-Cyano-Phenyl-Boronic Acid",
      "4-Boronobenzonitrile",
      "4-(Dihydroxyboranyl)Benzonitrile",
      "P-Cyanophenylboronic Acid",
      "4-Cyanobenzene Boronic Acid",
      "(4-Cyano-Phenyl)Boronic Acid",
      "CEBAHYWORUOILU-UHFFFAOYSA-N",
      "MFCD01318968",
      "BORONIC ACID, (4-CYANOPHENYL)-",
      "4-Cyanophenylboronic Acid, 97%",
      "ACMC-209bbp",
      "AC1MC0VB",
      "4-Cyanoplienylboronic Acid",
      "4-Cyano Phenylboronic Acid",
      "4-Cyano-Phenylboronic Acid",
      "4-Cyanophenyl-Boronic",
    ],
  },
  {
    primary_id: "D0AW4P",
    names: [
      "1,2-Bis(3-Fluorophenyl)-2-Hydroxyethanon",
      "CHEMBL396977",
      "1,2-Bis(3-Fluorophenyl)-2-Hydroxy-Ethanone",
      "SCHEMBL11382156",
      "BDBM50209422",
    ],
  },
  {
    primary_id: "D0AW4T",
    names: ["CART-123 Cells"],
  },
  {
    primary_id: "D0AW4W",
    names: ["4,3'',5''-Trimethoxy-[1,1':2',1'']-Terphenyl", "CHEMBL379687", "BDBM50186763"],
  },
  {
    primary_id: "D0AW4X",
    names: ["N-(1-(3-Bromobenzyl)Piperidin-4-Yl)-1-Naphthamide", "CHEMBL395631"],
  },
  {
    primary_id: "D0AW5T",
    names: ["PX-866"],
  },
  {
    primary_id: "D0AW6C",
    names: [
      "N'-(Phenylsulfonyl)Benzofuran-2-Carbohydrazide",
      "CHEMBL205220",
      "SCHEMBL6240233",
      "AMGAILKMHSFVNJ-UHFFFAOYSA-N",
      "BDBM50172929",
      "N''-(Phenylsulfonyl)Benzofuran-2-Carbohydrazide",
      "Benzofuran-2-Carboxylic Acid 2-(Phenylsulfonyl)Hydrazide",
    ],
  },
  {
    primary_id: "D0AW7G",
    names: [
      "6-(3-Bromo-Phenylamino)-1H-Pyrimidine-2,4-Dione",
      "CHEMBL291461",
      "72255-49-3",
      "2,4(1H,3H)-Pyrimidinedione, 6-[(3-Bromophenyl)Amino]-",
      "6-[(3-Bromophenyl)Amino]pyrimidine-2,4(1H,3H)-Dione",
      "AC1LFXXC",
      "Oprea1_136416",
      "Oprea1_839686",
      "MLS000713738",
      "CTK2H2676",
      "DTXSID30355636",
      "MolPort-001-933-580",
      "ZINC322347",
      "HMS3363I15",
      "HMS2635L06",
      "HMS1673K06",
      "BDBM50028343",
      "STK385018",
      "AKOS000541603",
      "MCULE-5935337788",
      "SMR000273219",
      "BAS 00450422",
      "ST50002707",
      "6-(3-Bromoanilino)-1H-Pyrimidine-2,4-Dione",
      "AB00091424-01",
    ],
  },
  {
    primary_id: "D0AW7H",
    names: ["StromaCel"],
  },
  {
    primary_id: "D0AW7Q",
    names: ["2-Imidazol-1-Ylmethylxanthen-9-One", "CHEMBL1083354"],
  },
  {
    primary_id: "D0AW8E",
    names: ["Tezosentan"],
  },
  {
    primary_id: "D0AW8P",
    names: ["Quinoline Carboxamide Derivative 7"],
  },
  {
    primary_id: "D0AW9T",
    names: ["ATryn Antithrombin"],
  },
  {
    primary_id: "D0AX0K",
    names: ["U101958"],
  },
  {
    primary_id: "D0AX0V",
    names: ["SOM-1033"],
  },
  {
    primary_id: "D0AX1N",
    names: ["OGX-427"],
  },
  {
    primary_id: "D0AX3M",
    names: ["KW-2581"],
  },
  {
    primary_id: "D0AX4E",
    names: ["Bertosamil", "126825-36-3", "SCHEMBL379798", "CHEMBL2104579", "AOIVZQPSIHOHMP-HDICACEKSA-N"],
  },
  {
    primary_id: "D0AX4H",
    names: ["GSK3179106"],
  },
  {
    primary_id: "D0AX4Q",
    names: ["PMID28454500-Compound-12"],
  },
  {
    primary_id: "D0AX7B",
    names: ["8-(Biphenyl-4-Yloxy)-1,1,1-Trifluoro-Octan-2-One", "CHEMBL112148", "SCHEMBL7364383", "BDBM50218532"],
  },
  {
    primary_id: "D0AX9N",
    names: ["Biphenyl Derivative 4"],
  },
  {
    primary_id: "D0AX9S",
    names: ["GW-409544"],
  },
  {
    primary_id: "D0AY0F",
    names: ["PMID23414845C30", "4i4f", "GTPL7864", "BDBM50430287", "1BR"],
  },
  {
    primary_id: "D0AY0H",
    names: ["PMID26651364-Compound-10c"],
  },
  {
    primary_id: "D0AY0U",
    names: ["Kadcyla"],
  },
  {
    primary_id: "D0AY1I",
    names: ["GSK-2315698"],
  },
  {
    primary_id: "D0AY2N",
    names: ["Tricyclic Compound 10"],
  },
  {
    primary_id: "D0AY4E",
    names: [
      "3-[6-Methyl-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "CHEMBL175550",
      "BDBM8604",
      "AC1O705B",
      "(3-Pyridylmethylene)Indane 21a",
      "3-[(E)-(6-Methyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0AY4N",
    names: ["Lactol Derivative 1"],
  },
  {
    primary_id: "D0AY5G",
    names: ["N-Phenyl-Pyrimidin-4-Amine Derivative 1"],
  },
  {
    primary_id: "D0AY5T",
    names: ["VU0400195"],
  },
  {
    primary_id: "D0AY6U",
    names: ["MT-003"],
  },
  {
    primary_id: "D0AY7K",
    names: ["Celiprolol Hcl"],
  },
  {
    primary_id: "D0AY8A",
    names: ["CA-4948"],
  },
  {
    primary_id: "D0AY8D",
    names: ["NBXT-001+Nobilis Inhalation Device"],
  },
  {
    primary_id: "D0AY8W",
    names: ["Benzanilide Compound 1"],
  },
  {
    primary_id: "D0AY9Q",
    names: ["Hexyl Aminolevulinate"],
  },
  {
    primary_id: "D0AZ0T",
    names: ["NP-003"],
  },
  {
    primary_id: "D0AZ0Y",
    names: [
      "5-(6-Hydroxynaphthalen-2-Yl)Benzene-1,3-Diol",
      "CHEMBL197892",
      "SCHEMBL1741908",
      "ZINC28527773",
      "BDBM50186767",
      "5-(6-Hydroxy-2-Naphthyl)Benzene-1,3-Diol",
      "5-(6-Hydroxy-Naphthalen-2-Yl)-Benzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D0AZ1N",
    names: ["BSK01"],
  },
  {
    primary_id: "D0AZ3C",
    names: [
      "Imatinib",
      "152459-95-5",
      "Imatinib (STI571)",
      "Cgp 57148",
      "Imatinib [INN:BAN]",
      "Imatinib Free Base",
      "N-(4-Methyl-3-((4-(Pyridin-3-Yl)Pyrimidin-2-Yl)Amino)Phenyl)-4-((4-Methylpiperazin-1-Yl)Methyl)Benzamide",
      "Imatinib Methansulfonate",
      "UNII-BKJ8M8G5HI",
      "CCRIS 9076",
      "CGP-57148",
      "BKJ8M8G5HI",
      "4-(4-METHYL-PIPERAZIN-1-YLMETHYL)-N-[4-METHYL-3-(4-PYRIDIN-3-YL-PYRIMIDIN-2-YLAMINO)-PHENYL]-BENZAMIDE",
      "CHEMBL941",
      "4-[(4-Methylpiperazin-1-Yl)Methyl]-N-[4-Methyl-3-[(4-Pyridin-3-Ylpyrimidin-2-Yl)Amino]phenyl]benzamide",
      "Glamox",
      "STI",
      "Glamox (TN)",
      "Gleevec (TN)",
      "Glivec (TN)",
      "Imatinib (INN)",
      "Benzamide, 4-[(4-Methyl-1-Piperazinyl)Methyl]-N-[4-Methyl-3-[[4-(3-Pyridinyl)-2-Pyrimidinyl]amino]phenyl]-(9CI)",
      "Alpha-(4-Methyl-1-Piperazinyl)-3'-((4-(3-Pyridyl)-2-Pyrimidinyl)Amino)-P-Tolu-P-Toluidide",
      "N-(3-(4-(Pyridin-3-Yl)Pyrimidin-2-Ylamino)-4-Methylphenyl)-4-((4-Methylpiperazin-1-Yl)Methyl)Benzamide",
      "112GI019",
      "4-[(4-Methyl-1-Piperazinyl)Methyl]-N-[4-Methyl-3-[[4-(3-Pyridinyl)-2-Pyrimidinyl]amino]-Phenyl]benzamide",
      "4-[(4-Methyl-1-Piperazinyl)Methyl]-N-[4-Methyl-3-[[4-(3-Pyridinyl)-2-Pyrimidinyl]amino]phenyl]-Benzamide Methanesulfonate",
      "4-[(4-Methylpiperazin-1-Yl)Methyl]-N-(4-Methyl-3-{[4-(Pyridin-3-Yl)Pyrimidin-2-Yl]amino}phenyl)Benzamide",
      "4-[(4-Methylpiperazin-1-Yl)Methyl]-N-{4-Methyl-3-[(4-Pyridin-3-Ylpyrimidin-2-Yl)Amino]phenyl}benzamide",
      "MCL-1-Specific Antisense Oligonucleotide + Imatinib (STI571)",
    ],
  },
  {
    primary_id: "D0AZ3Y",
    names: [
      "CTCE-0214",
      "CTCE-0013",
      "CTCE-0021",
      "CXCR4 Agonists, Chemokine Therapeutics",
      "Stem Cell Transplant Therapy, Chemokine Therapeutics",
    ],
  },
  {
    primary_id: "D0AZ4S",
    names: ["VT1021"],
  },
  {
    primary_id: "D0AZ4T",
    names: [
      "Dihydroorotate",
      "Dihydroorotic Acid",
      "Hydroorotic Acid",
      "4,5-Dihydroorotic Acid",
      "DL-Dihydroorotic Acid",
      "155-54-4",
      "5,6-Dihydroorotate",
      "2,6-Dioxohexahydropyrimidine-4-Carboxylic Acid",
      "2,6-Dioxo-1,3-Diazinane-4-Carboxylic Acid",
      "Hexahydro-2,6-Dioxo-4-Pyrimidinecarboxylic Acid",
      "6202/10/4",
      "CHEBI:30865",
      "L-Hydroorotic Acid",
      "4,5-Dihydroorotate",
      "2,6-Dioxohexahydro-4-Pyrimidinecarboxylic Acid",
      "Orotic Acid, 4,5-Dihydro-",
      "L-Hydroorotate",
      "R,S-Hydroorotate",
      "2,6-Dioxohexahydro-4-Pyrimidincarbons",
      "DL-Dihydroortotic Acid",
      "AC1Q6GGT",
    ],
  },
  {
    primary_id: "D0AZ5O",
    names: [
      "LHD-4",
      "Conjugated Low Molecular Weight Heparin Derivatives (Cancer)",
      "Conjugated Low Molecular Weight Heparin Derivatives (Cancer), Seoul National University",
      "LHD-1.5",
    ],
  },
  {
    primary_id: "D0AZ5V",
    names: [
      "L-698544",
      "7-Chloro-3-Nitro-3,4-Dihydroquinolin-2(1H)-One",
      "147778-05-0",
      "7-Chloro-3-Nitro-3,4-Dihydro-1H-Quinolin-2-One",
      "7-CHLORO-3-NITRO-3,4-DIHYDROQUINOLIN-2(1H)-ONE",
      "CHEMBL102574",
      "2(1H)-Quinolinone,7-Chloro-3,4-Dihydro-3-Nitro-",
      "ACMC-1C9KO",
      "SCHEMBL8271408",
      "CTK4C5567",
      "DTXSID50436916",
      "BDBM50038176",
      "3697AJ",
      "AKOS024260378",
      "AB06846",
      "AK153524",
      "DB-063735",
      "AX8026622",
      "FT-0703007",
      "Z-7553",
      "CNDQ",
    ],
  },
  {
    primary_id: "D0AZ6X",
    names: ["Pyridine Derivative 16"],
  },
  {
    primary_id: "D0AZ7F",
    names: ["NLX-E201"],
  },
  {
    primary_id: "D0AZ7V",
    names: [
      "PT-309",
      "149485-98-3",
      "Thiourea, N-(2-(2-Chloro-6-Fluorophenyl)Ethyl)-N'-2-Thiazolyl-",
      "PETT Analog 24",
      "Thiourea, N-[2-(2-Chloro-6-Fluorophenyl)Ethyl]-N'-2-Thiazolyl-",
      "AC1MHDKD",
      "PETT Cl, F Deriv.",
      "SCHEMBL6362179",
      "CHEMBL252137",
      "BDBM1917",
      "DTXSID20164294",
      "1-[2-(2-Chloro-6-Fluorophenyl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "1-(2-Thiazolyl)-3-[2-Chloro-6-Fluorophenethyl]thiourea",
      "N-(2-(2-Chloro-6-Fluorophenethyl))-N -(2-Thiazolyl)Thiourea",
      "1-[2-(2-Chloro-6-Fluoro-Phenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
    ],
  },
  {
    primary_id: "D0AZ8C",
    names: ["Silymarin"],
  },
  {
    primary_id: "D0AZ8T",
    names: ["Pyrazino[2,1-A]isoquinolin Derivative 1"],
  },
  {
    primary_id: "D0AZ8Y",
    names: ["Biciromab"],
  },
  {
    primary_id: "D0B0AS",
    names: ["PMID28766366-Compound-Scheme27LeucettineL41derivatives"],
  },
  {
    primary_id: "D0B0AX",
    names: ["PD166866"],
  },
  {
    primary_id: "D0B0BE",
    names: ["PR-5-I"],
  },
  {
    primary_id: "D0B0BJ",
    names: ["Pyridoindole Derivative 1"],
  },
  {
    primary_id: "D0B0BQ",
    names: ["Lipopeptide Antibiotic Program, Bacterial Infections"],
  },
  {
    primary_id: "D0B0CE",
    names: ["Cardiospheres", "Cardiospheres (Injectable, Heart Failure)"],
  },
  {
    primary_id: "D0B0CP",
    names: [
      "Chlorprothixene",
      "Chloroprothixene",
      "Chlorprothixen",
      "Chlorprothixenum",
      "Chlorprothixine",
      "Chlorprotixen",
      "Chlorprotixene",
      "Chlorprotixine",
      "Chlothixen",
      "Clorprotisene",
      "Clorprotixeno",
      "Iaractan",
      "Paxyl",
      "Rentovet",
      "Tactaran",
      "Taractan",
      "Tarasan",
      "Tardan",
      "Traquilan",
      "Trictal",
      "Truxal",
      "Truxaletten",
      "Truxil",
      "Vetacalm",
      "Clorprotisene [DCIT]",
      "MK 184",
      "N 714",
      "N 714C",
      "NCI56378",
      "Alpha-Chlorprothixene",
      "Chlorprothixenum [INN-Latin]",
      "Cis-Chlorprothixene",
      "Clorprotixeno [INN-Spanish]",
      "N-714",
      "Ro 4-0403",
      "Taractan (TN)",
      "Truxal (TN)",
      "Ro-4-0403;Trans(E)-Chlorprothixen",
      "Chlorprothixene (JAN/USAN/INN)",
      "Chlorprothixene [USAN:INN:BAN:JAN]",
      "Cloxan, Taractan, Truxal, Chlorprothixene",
      "Cis-2-Chloro-9-(3-Dimethylaminopropylidene)Thioxanthene",
      "{3-[2-Chloro-Thioxanthen-(9Z)-Ylidene]-Propyl}-Dimethyl-Amine",
      "Thioxanthene-Delta9,gamma-Propylamine, 2-Chloro-N,N-Dimethyl-, (Z)-(8CI)",
      "(3E)-3-(2-Chloro-9H-Thioxanthen-9-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "(3E)-3-(2-Chlorothioxanthen-9-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "(3Z)-3-(2-Chloro-9H-Thioxanthen-9-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "(3Z)-3-(2-Chlorothioxanthen-9-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "(Z)-2-Chloro-9-(Omega-Dimethylaminopropylidene)Thioxanthene",
      "(Z)-2-Chloro-N,N-Dimethylthioxanthene-.DELTA.(Sup9),(Sup.gamma.)-Propylamine",
      "(Z)-2-Chloro-N,N-Dimethylthioxanthene-Delta(Sup 9,gamma)-Propylamine",
      "(Alpha.-2-Chloro-9-.omega.-Dimethylamino-Propylamine)Thioxanthene",
      "2-Chloro-9-[.omega.-(Dimethylamino)Propylidene]thioxanthene",
      "2-Chloro-9-[3-(Dimethylamino)Propylidene]thioxanthene",
      "2-Chloro-N,N-Dimethylthioxanthene-.delta.(Sup 9), .gamma.-Propylamine",
      "3-(2-Chloro-9H-Thioxanthen-9-Ylidene)-N,N-Dimethyl-1-Propanamine",
      "3-(2-Chloro-9H-Thioxanthen-9-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "3-(2-Chlorothioxanthen-9-Ylidene)-N,N-Dimethylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D0B0DP",
    names: [
      "N6-[4-(4-Methylphenoxy)Phenyl]-L-Homoglutamine",
      "CHEMBL478298",
      "Modified Amino Acid Analog, 10",
      "BDBM24285",
      "(3S)-3-Amino-5-[(4-Phenoxyphenyl)Carbamoyl]pentanoic Acid",
    ],
  },
  {
    primary_id: "D0B0EB",
    names: ["LY-929"],
  },
  {
    primary_id: "D0B0EL",
    names: ["AP18"],
  },
  {
    primary_id: "D0B0EO",
    names: ["CPG23STEM"],
  },
  {
    primary_id: "D0B0ES",
    names: [
      "1-(3-Chloro-Phenyl)-3-Cyclohexyl-Urea",
      "1-(3-Chlorophenyl)-3-Cyclohexylurea",
      "CHEMBL192293",
      "N-(3-Chlorophenyl)-N'-Cyclohexylurea",
      "72802-45-0",
      "SMR000102236",
      "AC1Q3IFF",
      "AC1LFC0I",
      "CBDivE_015961",
      "MLS000105355",
      "ARONIS24036",
      "SCHEMBL12196175",
      "REGID_For_CID_751320",
      "DTXSID50353755",
      "MolPort-001-819-362",
      "ZINC201053",
      "HMS2404F16",
      "KS-000046HM",
      "BDBM50167035",
      "STK903181",
      "3-(3-Chlorophenyl)-1-Cyclohexylurea",
      "AKOS002956586",
      "MCULE-2109883694",
      "1-(3-Chlorophenyl)-3-Cyclohexyl-Urea",
      "KB-101580",
      "ST45053223",
      "N-(3-Chlorophenyl)(Cyclohexylamino)Carboxamide",
    ],
  },
  {
    primary_id: "D0B0EZ",
    names: ["DWJ-424", "DW-Ab1"],
  },
  {
    primary_id: "D0B0FU",
    names: ["Apramycin", "AC1O533Z", "XZNUGFQTQHRASN-PHTOHMOASA-N"],
  },
  {
    primary_id: "D0B0GC",
    names: ["E-0722"],
  },
  {
    primary_id: "D0B0GG",
    names: ["Dipeptide Analog 1"],
  },
  {
    primary_id: "D0B0HK",
    names: ["G3139 + G-CSF"],
  },
  {
    primary_id: "D0B0IJ",
    names: ["Pyrrolidine Dithiocarbamate"],
  },
  {
    primary_id: "D0B0IO",
    names: ["Nicotinaldehyde O-4-Ethoxyphenylcarbamoyl Oxime", "CHEMBL598343"],
  },
  {
    primary_id: "D0B0IU",
    names: ["Hemoparatide"],
  },
  {
    primary_id: "D0B0JK",
    names: [
      "DECYL(DIMETHYL)PHOSPHINE OXIDE",
      "Dimethyldecylphosphine Oxide",
      "APO-10",
      "Decyl(Dimethyl)Phosphine Oxide",
      "Decyldimethylphosphine Oxide",
      "2190-95-6",
      "1-(Dimethylphosphoryl)Decane",
      "Decyl(Dimethyl)Phosphane Oxide",
      "Phosphine Oxide, Decyldimethyl-",
      "Decyldimethylphosphino-1-One",
      "DCX",
      "AC1MRFCU",
      "1-Dimethylphosphoryldecane",
      "Dimethyldecyl Phosphine Oxide",
      "Phosphine Oxide,decyldimethyl-",
      "SCHEMBL158223",
      "CTK4E7952",
      "DTXSID90176309",
      "MolPort-000-474-112",
      "ZINC3130911",
      "STK954185",
      "SBB039895",
      "MFCD00226006",
      "AKOS000271378",
      "MCULE-7993897252",
      "DB07641",
      "ST50109137",
      "Dimethyldecylphosphine Oxide,",
    ],
  },
  {
    primary_id: "D0B0KP",
    names: [
      "1-Oxazolo[4,5-B]pyridin-2-Yl-Octadec-9-Yn-1-One",
      "1-Oxazolo[4,5-B]pyridin-2-Yl-9-Octadecyn-1-One",
      "288862-89-5",
      "CHEMBL175580",
      "SCHEMBL1811297",
      "CTK8E3290",
      "DTXSID50433602",
      "HMS3649E20",
      "BDBM50163152",
      "ZINC34803574",
      "ACM288862895",
      "RT-006406",
      "SR-01000946605",
      "SR-01000946605-1",
      "J-017317",
    ],
  },
  {
    primary_id: "D0B0LC",
    names: ["2-(4-Imidazol-1-Yl-Phenoxymethyl)-Pyridine", "CHEMBL112592", "SCHEMBL14129997", "BDBM50138232"],
  },
  {
    primary_id: "D0B0LG",
    names: ["Apomab", "Apomab (Cancer)"],
  },
  {
    primary_id: "D0B0LU",
    names: ["[2-(Imidazol-2-Yl-Thio)Ethyl]-Bisphosphonic Acid", "CHEMBL504429"],
  },
  {
    primary_id: "D0B0MA",
    names: [
      "F-2692",
      "AC1L2OTQ",
      "F 2692",
      "ZINC6069558",
      "AKOS022768604",
      "3-Amino-6-Methyl-1-[4-(Trifluoromethyl)Phenyl]pyridazin-4-One",
    ],
  },
  {
    primary_id: "D0B0MQ",
    names: ["KP-303"],
  },
  {
    primary_id: "D0B0MU",
    names: ["12-(3-N-Pentylureidooxy)Dodec-8(Z)-Enoic Acid", "CHEMBL560419"],
  },
  {
    primary_id: "D0B0NH",
    names: ["IK-5001"],
  },
  {
    primary_id: "D0B0NO",
    names: ["RO116-4875/608"],
  },
  {
    primary_id: "D0B0NQ",
    names: ["LY293558"],
  },
  {
    primary_id: "D0B0NW",
    names: ["LY233536"],
  },
  {
    primary_id: "D0B0OA",
    names: ["LEO-27847"],
  },
  {
    primary_id: "D0B0PI",
    names: [
      "2-(2-Adamantyl) Piperidine",
      "2-(2-Adamantyl)Piperidine",
      "AC1LADJJ",
      "SCHEMBL587506",
      "2-(Adamantan-2-Yl)Piperidine",
      "CHEMBL117334",
    ],
  },
  {
    primary_id: "D0B0PP",
    names: [
      "3,3-Diphenylpropan-1-Amine",
      "3,3-Diphenylpropylamine",
      "5586-73-2",
      "UNII-C31E561S64",
      "KISZTEOELCMZPY-UHFFFAOYSA-N",
      "Benzenepropanamine, .gamma.-Phenyl-",
      "MFCD00008202",
      "C31E561S64",
      "3-3-Diphenylpropylamine",
      "3,3-Diphenylpropylamine E",
      "3,3-Diphenylpropan-1-Amin",
      "EINECS 226-984-3",
      "NSC137832",
      "NSC 137832",
      "ACMC-209lpx",
      "3.3-Diphenylpropylamine",
      "3,3 Diphenylpropylamine",
      "3,3-Diphenyl-Propylamine",
      "3,3-Diphenyl Propylamine",
      "EC 226-984-3",
      "(3,3-Diphenylpropyl)Amine",
      "AC1Q1HZ2",
      "AC1L2XY3",
      "TimTec1_004202",
      "3,3-Diphenyl-1-Propanamine",
      "Propylamine, 3,3-Dipheny",
    ],
  },
  {
    primary_id: "D0B0PW",
    names: ["Pyrimidinyl Acylthiourea"],
  },
  {
    primary_id: "D0B0PX",
    names: ["ISIS 10582"],
  },
  {
    primary_id: "D0B0QS",
    names: ["2-[1,4]Oxazepan-4-Yl-Benzo[h]chromen-4-One", "CHEMBL367141"],
  },
  {
    primary_id: "D0B0QW",
    names: ["N6-Methoxy-2-[(4-Pentylphenyl)Ethynyl]adenosine", "CHEMBL374320", "SCHEMBL4381145"],
  },
  {
    primary_id: "D0B0QX",
    names: ["MSC-204"],
  },
  {
    primary_id: "D0B0QY",
    names: ["5(E)-(5'-Deoxyadenosin-5'-Ylidene)Pentanoic Acid", "CHEMBL496927"],
  },
  {
    primary_id: "D0B0SD",
    names: ["Propionate"],
  },
  {
    primary_id: "D0B0SH",
    names: [
      "Oxtriphylline",
      "Choledyl",
      "Cholegyl",
      "Cholinophyllin",
      "Cholinophylline",
      "Filoral",
      "Oxtrimethylline",
      "Sabidal",
      "Soliphylline",
      "Teofilcolina",
      "Teokolin",
      "Theoxylline",
      "Choline Theophylline Salt",
      "Cholini Theophyllinas",
      "Colina Teofillinato",
      "Colina Teofillinato [DCIT]",
      "Oxtriphylline [USAN]",
      "Oxtriphylline Pediatric",
      "Teofilinato De Colina",
      "Theophyllinate De Choline",
      "Theophylline Cholinate",
      "Theophylline Salt Of Choline",
      "Choledyl SA 400",
      "Zy 15061",
      "Choledyl (TN)",
      "Cholini Theophyllinas [INN-Latin]",
      "Teofilinato De Colina [INN-Spanish]",
      "Theophyllinate De Choline[INN-French]",
      "Choline Salt With Theophylline (1:1)",
      "Theophylline, Compd. With Choline",
      "Choline, Compd. With Theophylline (1:1)",
      "Choline, Hydroxide, Compd. With Theophylline (1:1)",
      "Ethanaminium, 2-Hydroxy-N,N,N-Trimethyl-, Salt With 3,7-Dihydro-1,3-Dimethylpurine-2,6-Dione",
      "Ethanaminium, 2-Hydroxy-N,N,N-Trimethyl-, Salt With 3,7-Dihydro-1,3-Dimethyl-1H-Purine-2,6-Dione",
      "Ethanaminium, 2-Hydroxy-N,N,N-Trimethyl-, Salt With 3,7-Dihydro-1,3-Dimethyl-1H-Purine-2,6-Dione (1:1)",
      "(2-Hydroxyethyl)Trimethylammonium Compound With Theophylline",
      "1,3-Dimethyl-7H-Purine-2,6-Dione",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1,3-Dimethyl-, Ion(1-), 2-Hydroxy-N,N,N-Trimethylethanaminium",
    ],
  },
  {
    primary_id: "D0B0SV",
    names: ["N,O6-Disulfo-Glucosamine"],
  },
  {
    primary_id: "D0B0TK",
    names: [
      "Bilobalide",
      "33570-04-6",
      "Bilobalid",
      "(-)-Bilobalide",
      "UNII-M81D2O8H7U",
      "CHEBI:3103",
      "M81D2O8H7U",
      "Bilobalide A",
      "4H,5aH,9H-Furo(2,3-B)Furo(3',2':2,3)Cyclopenta(1,2-C)Furan-2,4,7(3H,8H)-Trione, 9-(1,1-Dimethylethyl)-10,10a-Dihydro-8,9-Dihydroxy-, (5aR-(3aS*,5aalpha,8beta,8aS*,9alpha,10aalpha))-",
      "(3aS,8R,8aS,9R,10aS)-9-Tert-Butyl-8,9-Dihydroxydihydro-9H-Furo[2,3-B]furo[3',2':2,3]cyclopenta[1,2-C]furan-2,4,7(3H,8H)-Trione",
      "Tert-Butyl(Dihydroxy)[?]trione",
      "C15H18O8",
      "Bilobalide;",
      "Bilobalide A;",
      "(?)-Bilobalide",
      "AC1L2K4G",
      "MLS000563448",
    ],
  },
  {
    primary_id: "D0B0TO",
    names: ["L-768277"],
  },
  {
    primary_id: "D0B0TW",
    names: ["SSJ-183", "Antimalarial Agent, Synstar Japan/Medicines For Malaria Venture"],
  },
  {
    primary_id: "D0B0TX",
    names: ["8-Bromo-9-Isobutyl-9H-Purin-6-Amine", "CHEMBL474207", "8-Bromo-9-Isobutyl-9H-Adenine"],
  },
  {
    primary_id: "D0B0UF",
    names: ["IBI-308"],
  },
  {
    primary_id: "D0B0UI",
    names: ["Methotrexate Gamma-Hydroxamic Acid"],
  },
  {
    primary_id: "D0B0UN",
    names: ["R7232"],
  },
  {
    primary_id: "D0B0UR",
    names: ["8-PCPT-2'-O-Me-CAMP", "CPT-2'OMe-CAMP", "8CPT-2MecAMP"],
  },
  {
    primary_id: "D0B0UZ",
    names: [
      "A-425444",
      "CHEMBL219179",
      "SCHEMBL4809015",
      "CBDXXUZXRDBOGH-UHFFFAOYSA-N",
      "BDBM50200029",
      "LS-193223",
      "6-[4-(1H-Benzimidazol-2-Ylmethyl)Piperazin-1-Yl]pyridin-3-Ol",
      "6-[4-(1H-Benzoimidazole-2-Ylmethyl)Piperazino]-3-Pyridinol",
    ],
  },
  {
    primary_id: "D0B0VD",
    names: ["MORAb-022", "Antibody (Inflammation/Autoimmune Disease), Morphotek"],
  },
  {
    primary_id: "D0B0VK",
    names: ["Peptide Analog 32"],
  },
  {
    primary_id: "D0B0WJ",
    names: ["PMID25666693-Compound-51"],
  },
  {
    primary_id: "D0B0WU",
    names: ["Cis-3-ACPBPA", "3-Amino-Cyclopentenylbutylphosphonic Acid"],
  },
  {
    primary_id: "D0B0WW",
    names: ["Physostigmine/Scopolamine"],
  },
  {
    primary_id: "D0B0XK",
    names: ["1-Furan-2-Yl-3-Pyridin-2-Yl-Propenone (FPP-3)"],
  },
  {
    primary_id: "D0B0XX",
    names: ["KH-164", "115305-61-8", "141109-86-6", "KH-161"],
  },
  {
    primary_id: "D0B0ZM",
    names: [
      "N,N-Dipropyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL589063",
      "N,N-Dipropyl-10H-Phenothiazine-10-Carboxamide",
      "81225-62-9",
      "N,N-Dipropylphenothiazine-10-Carboxamide",
      "AC1M3CI8",
      "SCHEMBL8428712",
      "ARONIS009768",
      "MolPort-001-593-661",
      "ZINC2883002",
      "KS-000041AV",
      "BDBM50308412",
      "STL064303",
      "AKOS000499526",
      "MCULE-9250306994",
      "Phenothiazin-10-Yl-N,N-Dipropylcarboxamide",
      "BB0286744",
      "ST45046508",
      "N,N-Dipropyl-1''H-Phenothiazine-1''-Carboxamide",
    ],
  },
  {
    primary_id: "D0B0ZQ",
    names: [
      "RB 2",
      "CHEMBL223344",
      "Cibacron Blue 3GA Agarose",
      "Cibacron Blue 3GA Agarose, Saline Suspension",
      "Cibacron Blue 3GA Agarose, Type 300, Saline Suspension",
      "Cibacron Blue 3GA Agarose, Type 100, Saline Suspension",
      "Cibacron Blue 3GA Agarose, Saline Suspension, Fast Flow",
      "Cibacron Blue 3GA Agarose, Type 1000, Saline Suspension",
      "Cibacron Blue 3GA Agarose, Type 3000, Saline Suspension",
      "Cibacron Blue 3GA Agarose, Type 3000-CL, Saline Suspension",
      "Cibacron Blue 3GA Agarose, Type 3000-L, Lyophilized Powder",
    ],
  },
  {
    primary_id: "D0B0ZR",
    names: ["AF-05", "AF-5", "Anxiolytic (Anxiety), Institute Of Materia Medica"],
  },
  {
    primary_id: "D0B1AK",
    names: ["Thieno[2,3-B]Pyridine-2-Carboxamidine"],
  },
  {
    primary_id: "D0B1BQ",
    names: [
      "1,1,1-Trifluoro-Nonadecan-2-One",
      "2-Nonadecanone, 1,1,1-Trifluoro-",
      "CHEMBL89946",
      "1,1,1-Trifluorononadecan-2-One",
      "81124-01-8",
      "AC1L4IKT",
      "CTK5E8478",
      "DTXSID90230910",
      "1,1,1-Trifluoro-2-Nonadecanone",
      "2-Nonadecanone,1,1,1-Trifluoro-",
    ],
  },
  {
    primary_id: "D0B1CF",
    names: ["PharmaPEG-EPO"],
  },
  {
    primary_id: "D0B1CJ",
    names: ["Amphiregulin Targeting Human Mabs", "Amphiregulin Targeting Human MAbs (Psoriasis)"],
  },
  {
    primary_id: "D0B1CL",
    names: [
      "5-Methoxy-2-(4-Aminophenyl)Benzofuran",
      "CHEMBL205221",
      "CTK7A8117",
      "ZINC13686334",
      "BDBM50185946",
      "AKOS015965959",
      "KB-238624",
      "4-(5-Methoxybenzo[b]furan-2-Yl)Phenylamine",
    ],
  },
  {
    primary_id: "D0B1CM",
    names: [
      "2-Methoxy-5-(3,4,5-Trimethoxy-Benzyl)-Phenol",
      "CHEMBL10121",
      "2-Methoxy-5-(3,4,5-Trimethoxybenzyl)Phenol",
      "NSC648581",
      "Neuro_000355",
      "AC1Q56F6",
      "AC1L85N6",
      "CTK6J6605",
      "ZINC1630963",
      "BDBM50212289",
      "NSC-648581",
      "NCI60_016862",
    ],
  },
  {
    primary_id: "D0B1CU",
    names: ["CD19 CAR T-Cells"],
  },
  {
    primary_id: "D0B1DA",
    names: ["SAND-5"],
  },
  {
    primary_id: "D0B1EM",
    names: ["Nateplase"],
  },
  {
    primary_id: "D0B1EQ",
    names: [
      "Taspoglutide",
      "BIM-51077",
      "BIM-51079",
      "BIM-51182",
      "Glucagon Agonists, Ipsen",
      "ITM-077",
      "R-1583",
      "RG-1583",
      "RO-5073031",
      "Glucagon Agonists, Beaufour-Ipsen",
    ],
  },
  {
    primary_id: "D0B1ET",
    names: ["Streptococcal B Conjugated Vaccine"],
  },
  {
    primary_id: "D0B1EV",
    names: [
      "Cyclopentolate",
      "Ciclopentolato",
      "Cyclopentoiate",
      "Cyclopentolatum",
      "Cyclopentylate",
      "Cylate",
      "Diopentolate",
      "Mydrilate",
      "Bell Pentolate",
      "Minims Cyclopentolate",
      "Bell Pentolate (TN)",
      "Ciclopentolato [INN-Spanish]",
      "Cyclopentolate (INN)",
      "Cyclopentolate [INN:BAN]",
      "Cyclopentolatum [INN-Latin]",
      "Ocu-Pentolate",
      "B-Dimethylaminoethyl(1-Hydroxycyclopentyl)Phenylacetate",
      "Beta-Dimethylaminoethyl (1-Hydroxycyclopentyl)Phenylacetate",
      "Beta-(Dimethylamino)Ethyl (1-Hydroxycyclopentyl)Phenylacetate",
      "Alpha-(1-Hydroxycyclopentyl)Benzeneacetic Acid 2-(Dimethylamino)Ethyl Ester",
      "Benzeneacetic Acid, Alpha-(1-Hydroxycyclopentyl)-, 2-(Dimethylamino)Ethyl Ester",
      "Benzeneacetic Acid, .alpha.-(1-Hydroxycyclopentyl)-, 2-(Dimethylamino)Ethyl Ester",
      "(+-)-Cyclopentolate",
      "1-Hydroxy-Alpha-Phenylcyclopentaneacetic Acid 2-(Dimethylamino)Ethyl Ester",
      "2-(Dimethylamino)Ethyl (1-Hydroxycyclopentyl)(Phenyl)Acetate",
      "2-(Dimethylamino)Ethyl 1-Hydroxy-Alpha-Phenylcyclopentaneacetate",
      "2-(Dimethylamino)Ethyl 2-(1-Hydroxycyclopentyl)-2-Phenylacetate",
      "2-Dimethylaminoethyl 2-(1-Hydroxycyclopentyl)-2-Phenylacetat",
      "2-Dimethylaminoethyl 2-(1-Hydroxycyclopentyl)-2-Phenylacetate",
      "2-Phenyl-2-(1-Hydroxycyclopentyl)Ethanoic Acid Beta-(Dimethylamino)Ethyl Ester",
    ],
  },
  {
    primary_id: "D0B1FE",
    names: [
      "Ebselen",
      "Ebselene",
      "Ebseleno",
      "Ebselenum",
      "Harmokisane",
      "Ebselen [INN]",
      "Ebselene [French]",
      "Ebseleno [Spanish]",
      "Ebselenum [Latin]",
      "DR 3305",
      "E 3520",
      "PZ 51",
      "PZ51",
      "RP 60931",
      "DR-3305",
      "PZ-51",
      "SPI-1005",
      "SPI-3005",
      "2-Phenyl-1,2-Benzisoselenazol-3(2H)-One",
      "2-Phenyl-1,2-Benzisoselenazolin-3-One",
      "2-Phenyl-1,2-Benzoisoselenazol-3(2H)-One",
      "2-Phenyl-1,2-Benzoselenazol-3-One",
    ],
  },
  {
    primary_id: "D0B1FH",
    names: [
      "Benzothiazinones",
      "BTZ-043",
      "Benzothiazinones (Tuberculosis)",
      "Benzothiazinones (Tuberculosis), Hans Knoell Institute",
    ],
  },
  {
    primary_id: "D0B1FM",
    names: [
      "3-(3,4-Dichlorophenyl)-2-Nortropene",
      "CHEMBL578130",
      "SCHEMBL5194296",
      "WNTMTTVEDFGEMS-UHFFFAOYSA-N",
      "BDBM50303309",
    ],
  },
  {
    primary_id: "D0B1FV",
    names: ["(S,R)-Isotetrandrine Hydrochloride", "CHEMBL506025", "(S,R)-Isotetrandrine HCl"],
  },
  {
    primary_id: "D0B1GB",
    names: ["Mabs Targeting Human Growth Hormone"],
  },
  {
    primary_id: "D0B1GE",
    names: ["KB103"],
  },
  {
    primary_id: "D0B1GJ",
    names: ["11-Propionyloxy-N-N-Propylnoraporphine", "CHEMBL515187"],
  },
  {
    primary_id: "D0B1GX",
    names: ["Iferanserin-Ventrus"],
  },
  {
    primary_id: "D0B1HP",
    names: ["3-(4-Hydroxy-Phenyl)-Benzo[d]isoxazol-6-Ol"],
  },
  {
    primary_id: "D0B1HT",
    names: ["Biaryl Mannoside Derivative 20"],
  },
  {
    primary_id: "D0B1IC",
    names: ["CR8020 Mab"],
  },
  {
    primary_id: "D0B1IP",
    names: ["Etretinate"],
  },
  {
    primary_id: "D0B1IV",
    names: ["Vancomycin"],
  },
  {
    primary_id: "D0B1JC",
    names: ["Tyr-Pro-Phe-Phe-N(CH3)2", "CHEMBL505975"],
  },
  {
    primary_id: "D0B1JE",
    names: ["TVX-005"],
  },
  {
    primary_id: "D0B1JM",
    names: ["Sulfamic Acid 4-(2-Methoxy-Benzoyl)-Phenyl Ester", "CHEMBL65446"],
  },
  {
    primary_id: "D0B1KA",
    names: ["Radretumab"],
  },
  {
    primary_id: "D0B1KJ",
    names: ["(+/-)-Threo-4'-Ethylmethylphenidate", "CHEMBL1253519", "BDBM50327106"],
  },
  {
    primary_id: "D0B1KK",
    names: [
      "AZD0837",
      "Atecegatran Metoxil",
      "Atecegatran Fexenetil",
      "UNII-4GU1D587JV",
      "4GU1D587JV",
      "AZD 0837",
      "433937-93-0",
      "Atecegatran Metoxil [INN]",
      "Atecegatran Fexenetil [INN]",
      "SCHEMBL9914214",
      "DB12507",
      "2-Azetidinecarboxamide, 1-((2R)-(3-Chloro-5-  (Difluoromethoxy)Phenyl)Hydroxyacetyl)-N-((4-(Imino(Methoxyamino)Methyl)Phenyl)Methyl)-, (2S)-",
    ],
  },
  {
    primary_id: "D0B1KT",
    names: ["AF353", "AF-353", "AF 353"],
  },
  {
    primary_id: "D0B1LM",
    names: ["(2'Z,3'E)-5-Chloro-5'-Fluoro-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0B1LN",
    names: ["Endothelial Lipase Inhibitors"],
  },
  {
    primary_id: "D0B1LR",
    names: [
      "2-(Thiazol-4-Yl)-1H-Benzo[d]imidazol-5-Amine",
      "1H-Benzimidazol-5-Amine, 2-(4-Thiazolyl)-",
      "CHEMBL370420",
      "25893-06-5",
      "5-Aminothiabendazole",
      "5-Amino-2-(Thiazol-4-Yl)-1H-Benzimidazole",
      "2-(4-Thiazolyl)-1H-Benzimidazol-5-Amine",
      "AC1Q4XVD",
      "AC1L35H8",
      "SCHEMBL4367564",
      "CTK7D8516",
      "CUDHOGCHOXYXRZ-UHFFFAOYSA-N",
      "MolPort-004-293-518",
      "ZINC13679482",
      "BDBM50180749",
      "AKOS000129404",
      "MCULE-2929987645",
      "5-Amino-2-Thiazol-4-Yl-1H-Benzoimidazole",
      "2-Thiazol-4-Yl-3H-Benzoimidazol-5-Ylamine",
      "2-(1,3-Thiazol-4-Yl)-3H-Benzimidazol-5-Amine",
    ],
  },
  {
    primary_id: "D0B1MJ",
    names: ["Dexniguldipine", "Dexniguldipine Hydrochloride", "BY-935", "B-8509-035", "B-859-35"],
  },
  {
    primary_id: "D0B1NA",
    names: [
      "PVAC",
      "DDMV, Corixa/ Genesis/ Zenyaku Kogyo",
      "Delipidated, Deglycolipidated Mycobacterium Vaccae, Corixa/ Genesis/ Zenyaku Kogyo",
    ],
  },
  {
    primary_id: "D0B1NY",
    names: ["Cyclopentane Amide Derivatives 3"],
  },
  {
    primary_id: "D0B1OH",
    names: ["ASB17061"],
  },
  {
    primary_id: "D0B1PC",
    names: ["OXECLOSPORIN"],
  },
  {
    primary_id: "D0B1PD",
    names: ["NPC-16377"],
  },
  {
    primary_id: "D0B1PE",
    names: [
      "Ro 5-4864",
      "4'-Chlorodiazepam",
      "14439-61-3",
      "4-Chlorodiazepam",
      "RO5-4864",
      "UNII-2QW0IK1742",
      "HSDB 6958",
      "BRN 0685202",
      "Ro-05-4864",
      "MLS000069627",
      "CHEMBL286346",
      "2QW0IK1742",
      "Ro-5-4864",
      "SMR000058205",
      "4 Inverted Exclamation Marka-Chlorodiazepam",
      "7-Chloro-5-(4-Chlorophenyl)-1-Methyl-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-1,3-Dihydro-1-Methyl-5-(P-Chlorophenyl)-2H-1,4-Benzodiazepin-2-One",
      "2H-1,4-Benzodiazepin-2-One, 7-Chloro-5-(4-Chlorophenyl)-1,3-Dihydro-1-Methyl-",
      "7-Chloro-5-(4-Chlorophenyl)-1-Methyl-3H-1,4-Benzodiazepin-2-One",
      "NB-3040",
    ],
  },
  {
    primary_id: "D0B1PU",
    names: [
      "CGEN-856",
      "CGEN-857",
      "Mas G-Protein-Coupled Receptor Agonists (Peptide, Cardiovascular Disorders)",
      "Mas G-Protein-Coupled Receptor Agonists (Peptide, Cardiovascular Disorders), Compugen",
    ],
  },
  {
    primary_id: "D0B1QM",
    names: ["HC067047"],
  },
  {
    primary_id: "D0B1RM",
    names: ["LP-226A1"],
  },
  {
    primary_id: "D0B1RV",
    names: ["2-Cyclopentyl-1H-Imidazo[4,5-C]quinolin-4-Ylamine", "CHEMBL19970", "ZINC13821060", "BDBM50011595"],
  },
  {
    primary_id: "D0B1RX",
    names: ["ZYI-1"],
  },
  {
    primary_id: "D0B1RY",
    names: [
      "Aom-0977",
      "Aom-0977 (Obesity/Type 2 Diabetes)",
      "Aom-0977 (Obesity/Type 2 Diabetes), Hangzhou Adamerck Pharmlabs",
      "Gastric Lipase Inhibitor (Oral, Obesity/Type 2 Diabetes), Hangzhou Adamerck Pharmlabs",
    ],
  },
  {
    primary_id: "D0B1SA",
    names: ["IDC-G103"],
  },
  {
    primary_id: "D0B1SG",
    names: [
      "BMS-813160",
      "BMS-585059",
      "CCR2/CCR5 Chemokine Antagonist (Immune Disorders, Cardiovascular Disorders), BMS",
      "CCR2/CCR5 Dual Antagonist (Cardiovascular Disease), Bristol-Myers Squibb",
      "CCR2/CCR5 Dual Antagonist (Immunology), Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D0B1SO",
    names: ["TANNIN"],
  },
  {
    primary_id: "D0B1SW",
    names: [
      "Redoxal",
      "52962-95-5",
      "MLS000736810",
      "NSC73735",
      "UNII-Q0VK2156M5",
      "N,N'-BIS(O-CARBOXYPHENYL)-O-DIANISIDINE",
      "Q0VK2156M5",
      "SMR000528333",
      "2,2'-((3,3'-Dimethoxy(1,1'-Biphenyl)-4,4'-Diyl)Diimino)Bis-Benzoic Acid",
      "Benzoic Acid, 2,2'-((3,3'-Dimethoxy(1,1'-Biphenyl)-4,4'-Diyl)Diimino)Bis-",
      "2-[4-[4-(2-Carboxyanilino)-3-Methoxyphenyl]-2-Methoxyanilino]benzoic Acid",
      "2-[4-[4-(2-Carboxyanilino)-3-Methoxy-Phenyl]-2-Methoxy-Anilino]benzoic Acid",
      "2-[(4-{4-[(2-Carboxyphenyl)Amino]-3-Methoxyphenyl}-2-Methoxyphenyl)Amino]benzoic Acid",
      "Mini-Antibody(ScFv)",
    ],
  },
  {
    primary_id: "D0B1TI",
    names: ["MPSK3169A"],
  },
  {
    primary_id: "D0B1TL",
    names: ["Her2-Targeted Autologous T-Cell Therapy"],
  },
  {
    primary_id: "D0B1TN",
    names: ["VLIM-88"],
  },
  {
    primary_id: "D0B1TQ",
    names: [
      "4-Chloro-7-Methyl-1H-Indole-2,3-Dione",
      "61258-72-8",
      "4-Chloro-7-Methyl Isatin",
      "4-Chloro-7-Methylisatin",
      "4-Chloro-7-Methylindoline-2,3-Dione",
      "4-Chloro-7-Methyl-2,3-Dihydro-1H-Indole-2,3-Dione",
      "1H-Indole-2,3-Dione,4-Chloro-7-Methyl-",
      "1H-Indole-2,3-Dione, 4-Chloro-7-Methyl-",
      "AC1LXYLJ",
      "AC1Q2GPB",
      "Isatin-Based Compound, 55",
      "Cambridge Id 5226020",
      "MLS002473344",
      "SCHEMBL950949",
      "CHEMBL373715",
      "CTK5B2947",
      "BDBM22835",
      "DTXSID20365244",
      "MolPort-002-111-842",
      "MWCJCUFHPFXQLS-UHFFFAOYSA-N",
      "HMS2228G20",
      "ZINC2169011",
      "ALBB-024400",
      "SBB066723",
      "BBL005527",
    ],
  },
  {
    primary_id: "D0B1TS",
    names: [
      "Orotate",
      "73-97-2",
      "Orotic Acidanion",
      "AC1LSPNG",
      "CHEBI:30839",
      "CTK5D9013",
      "DTXSID80363448",
      "PXQPEWDEAKTCGB-UHFFFAOYSA-M",
      "AG-G-93379",
      "2,4-Dioxo-1H-Pyrimidine-6-Carboxylate",
      "CJ-15820",
      "21317-EP2314295A1",
      "21317-EP2284172A1",
      "21317-EP2281559A1",
      "2,6-Dioxo-1,2,3,6-Tetrahydropyrimidine-4-Carboxylate",
      "4-Pyrimidinecarboxylicacid, 1,2,3,6-Tetrahydro-2,6-Dioxo-, Ion(1-)",
    ],
  },
  {
    primary_id: "D0B1TT",
    names: ["AdCh63-MSP1"],
  },
  {
    primary_id: "D0B1TX",
    names: [
      "PVAXrcPSAv53l",
      "DNA Vaccine (Derma Vax, Prostate Cancer)",
      "DNA Vaccine (Derma Vax, Prostate Cancer), Cellectis /Karolinska Institute",
      "DNA Vaccine (Derma Vax, Prostate Cancer), Cyto Pulse/Karolinska Institute",
    ],
  },
  {
    primary_id: "D0B1US",
    names: ["ISIS 140157"],
  },
  {
    primary_id: "D0B1VQ",
    names: ["GR-133347", "AH-023"],
  },
  {
    primary_id: "D0B1VZ",
    names: ["ALG-1001"],
  },
  {
    primary_id: "D0B1WB",
    names: ["(2R,3S,4S,5R)-2-Nonylpiperidine-3,4,5-Triol", "CHEMBL1091214", "SCHEMBL19831655", "BDBM50315250"],
  },
  {
    primary_id: "D0B1WD",
    names: ["N-(4-Ethylphenyl)-2-Oxo-2H-Chromene-3-Carboxamide"],
  },
  {
    primary_id: "D0B1WF",
    names: ["N-Benzylmethylphenidate"],
  },
  {
    primary_id: "D0B1WU",
    names: ["C-101"],
  },
  {
    primary_id: "D0B1XN",
    names: ["5-(4'-Benzoylbiphenyl-4-Yl)-2-Furoic Acid", "CHEMBL222717"],
  },
  {
    primary_id: "D0B1XZ",
    names: ["(+/-)-2-(4-Fluorophenyl)-7-Methoxychroman-4-One", "CHEMBL598746"],
  },
  {
    primary_id: "D0B1YM",
    names: ["Gymnochrome E"],
  },
  {
    primary_id: "D0B1YW",
    names: [
      "8-Phenyl-1-Propyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL274855",
      "Propyl-8-Phenyl Xanthine",
      "1-Propyl-8-Phenylxanthine",
    ],
  },
  {
    primary_id: "D0B2AE",
    names: ["PMID25553724-Compound-US20130237529 36"],
  },
  {
    primary_id: "D0B2AH",
    names: ["DMP-695/696"],
  },
  {
    primary_id: "D0B2BD",
    names: [
      "1-Furan-2-Ylmethyl-3-Thiazol-2-Yl-Thiourea",
      "CHEMBL148698",
      "AC1MHEHZ",
      "PHI-536",
      "BDBM50097046",
      "AKOS030027701",
      "1-(2-Furylmethyl)-3-Thiazol-2-Yl-Thiourea",
      "1-(2-Furanylmethyl)-3-(2-Thiazolyl)Thiourea",
      "1-(Furan-2-Ylmethyl)-3-(1,3-Thiazol-2-Yl)Thiourea",
    ],
  },
  {
    primary_id: "D0B2BO",
    names: [
      "6,7,8,9-Tetrahydro-5-Thia-8-Aza-Benzocycloheptene",
      "2,3,4,5-Tetrahydrobenzo[f][1,4]thiazepine",
      "58980-39-5",
      "2,3,4,5-Tetrahydro-1,4-Benzothiazepine",
      "CHEMBL333343",
      "1,4-Benzothiazepine, 2,3,4,5-Tetrahydro-",
      "SCHEMBL2589907",
      "CTK1D9777",
      "DTXSID50483283",
      "SUBDEKBXSIKCSA-UHFFFAOYSA-N",
      "MolPort-022-153-088",
      "BDBM50052886",
      "8761AB",
      "ANW-63665",
      "ZINC13757904",
      "AKOS015856597",
      "AJ-64367",
      "AS-36127",
      "KB-224927",
      "TR-072578",
      "AB0052636",
      "ST24021452",
      "FT-0733802",
      "2,3,4,5-Tetrahydrobenzo[f]-1,4-Thiazepine",
      "X-3109",
      "I14-16090",
    ],
  },
  {
    primary_id: "D0B2BQ",
    names: ["Icopezil Maleate"],
  },
  {
    primary_id: "D0B2BY",
    names: ["TAK-661", "3-(7-Isopropyl[1,2,4]triazolo[1,5-B]pyridazin-6-Yloxy)-2,2-Dimethylpropanesulfonamide"],
  },
  {
    primary_id: "D0B2CQ",
    names: ["GR-131663", "AH-002", "AH-017", "GR-131665"],
  },
  {
    primary_id: "D0B2DP",
    names: ["Triclofos Sodium"],
  },
  {
    primary_id: "D0B2EL",
    names: ["ISIS 156449"],
  },
  {
    primary_id: "D0B2FE",
    names: ["4-Phenyl-6-Propylpyrimidine-2-Carbonitrile", "CHEMBL1077366", "SCHEMBL4442260"],
  },
  {
    primary_id: "D0B2FM",
    names: ["GLPTGG", "CHEMBL261334"],
  },
  {
    primary_id: "D0B2GI",
    names: [
      "Glimepiride",
      "Amarel",
      "Amaryl",
      "Endial",
      "Glimepirid",
      "Glimepirida",
      "Glimepiridum",
      "Glimepride",
      "Glimer",
      "Glymepirid",
      "Roname",
      "Solosa",
      "Glimepirida [Spanish]",
      "Glimepiridum [Latin]",
      "Sandoz Glimepiride",
      "HOE 490",
      "Amaryl (TN)",
      "Hoe-490",
      "Novo-Glimepiride",
      "PMS-Glimepiride",
      "Ratio-Glimepiride",
      "Amaryl, Glista OD, Glimepiride",
      "Glimepiride [USAN:BAN:INN]",
      "Glimepiride (JAN/USP/INN)",
      "1-((P-(2-(3-Ethyl-4-Methyl-2-Oxo-3-Pyrroline-1-Carboxamido)Ethyl)Phenyl)Sulfonyl)-3-(Trans-4-Methylcyclohexyl)Urea",
      "1-{[4-(2-{[(3-Ethyl-4-Methyl-2-Oxo-2,5-Dihydro-1H-Pyrrol-1-Yl)Carbonyl]amino}ethyl)Phenyl]sulfonyl}-3-(Trans-4-Methylcyclohexyl)Urea",
      "3-Ethyl-4-Methyl-N-[2-(4-{[(Trans-4-Methylcyclohexyl)Carbamoyl]sulfamoyl}phenyl)Ethyl]-2-Oxo-2,5-Dihydro-1H-Pyrrole-1-Carboxamide",
      "3-Ethyl-4-Methyl-N-(4-(N-((1r,4r)-4-Methylcyclohexylcarbamoyl)Sulfamoyl)Phenethyl)-2-Oxo-2,5-Dihydro",
      "4-Ethyl-3-Methyl-N-[2-[4-[(4-Methylcyclohexyl)Carbamoylsulfamoyl]phenyl]ethyl]-5-Oxo-2H-Pyrrole-1-Carboxamide",
      "64598P",
    ],
  },
  {
    primary_id: "D0B2GK",
    names: ["PMID17723296C18"],
  },
  {
    primary_id: "D0B2GM",
    names: ["Thiazole Carboxamide Derivative 20"],
  },
  {
    primary_id: "D0B2GS",
    names: ["3-Amino-1-(2-Amino-5-Methoxy-Phenyl)-Propan-1-One", "CHEMBL156974", "5-Methoxykynuramine"],
  },
  {
    primary_id: "D0B2HC",
    names: ["GSK-2302032A"],
  },
  {
    primary_id: "D0B2HE",
    names: ["RypVax"],
  },
  {
    primary_id: "D0B2HF",
    names: ["OI287GT"],
  },
  {
    primary_id: "D0B2IB",
    names: ["KBPA-103"],
  },
  {
    primary_id: "D0B2IR",
    names: ["Nemolizumab"],
  },
  {
    primary_id: "D0B2JC",
    names: ["[(3-Bromophenyl)-P-Tolyl-Ketone]thiosemicarbazone", "CHEMBL590497"],
  },
  {
    primary_id: "D0B2JG",
    names: ["4-(M-Tolyloxy)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL464764", "BDBM50248553"],
  },
  {
    primary_id: "D0B2JL",
    names: [
      "JWB-1-84-1",
      "Choline Analogs (Neurodegenerative Diseases)",
      "Choline Analogs (Neurodegenerative Diseases), Medical College Of Georgia",
      "JWB-1-B4-1",
    ],
  },
  {
    primary_id: "D0B2JV",
    names: ["N-Propylnorlitebamine"],
  },
  {
    primary_id: "D0B2LC",
    names: ["HP3070"],
  },
  {
    primary_id: "D0B2LF",
    names: ["R-253"],
  },
  {
    primary_id: "D0B2LP",
    names: ["PMID15482908C8", "507269-27-4", "GTPL6448", "CTK1G6178", "DTXSID80439334", "BDBM50151910"],
  },
  {
    primary_id: "D0B2MN",
    names: ["Helodermin"],
  },
  {
    primary_id: "D0B2NE",
    names: [
      "2-Phenylethylyl-Adenosine Derivative",
      "CHEMBL574602",
      "GTPL5600",
      "BDBM50299701",
      "N6-Methyl-2-Phenylethynyl-5''-N-Methylcarboxamidoadenosine",
    ],
  },
  {
    primary_id: "D0B2NF",
    names: ["AEB701"],
  },
  {
    primary_id: "D0B2NG",
    names: ["Bis(3-Bromo-4,5-Dihydroxyphenyl)Methanone", "CHEMBL1269421", "SCHEMBL15511901", "BDBM50329695"],
  },
  {
    primary_id: "D0B2NV",
    names: ["PMID29334795-Compound-22"],
  },
  {
    primary_id: "D0B2OC",
    names: ["R-Sibutramine Metabolite"],
  },
  {
    primary_id: "D0B2OL",
    names: [
      "AA-2379",
      "7-Butyl-3-(Methylamino)-4,6-Dioxo-5-Propyl-4,5,6,7-Tetrahydro-2H-Pyrazolo[3,4-D]pyrimidine-2-Carboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0B2OT",
    names: [
      "Isoflurophate",
      "DFP",
      "DIFP",
      "Diflupyl",
      "Diflurophate",
      "Diisopropylfluorfosfat",
      "Diisopropylfluorophosphate",
      "Diisopropylfluorphosphorsaeureester",
      "Diisopropylphosphofluoridate",
      "Diisopropylphosphorofluoridate",
      "Dyflos",
      "Floropryl",
      "Fluoropryl",
      "Fluorostigmine",
      "Fluostigmine",
      "Fluropryl",
      "Isofluorophate",
      "Isofluorphate",
      "Isoflurophosphate",
      "Neoglaucit",
      "Di Isopropylphosphorofluoridate",
      "Diisopropoxyphosphoryl Fluoride",
      "Diisopropyl Fluoridophosphate",
      "Diisopropyl Fluorophosphate",
      "Diisopropyl Fluorophosphonate",
      "Diisopropyl Phosphofluoridate",
      "Diisopropyl Phosphorofluoridate",
      "Diisopropylfluorfosfat [Czech]",
      "Diisopropylfluorophosphoric Acid Ester",
      "Diisopropylfluorphosphorsaeureester [German]",
      "Disiopropyl Fluorophosphonate",
      "Fluorodiisopropyl Phosphate",
      "Isoflurophate [USP]",
      "Isopropyl Fluophosphate",
      "Isopropyl Phosphorofluoridate",
      "Merck Brand Of Isoflurophate",
      "Phosphoric Acid Diisopropyl Ester Fluoride",
      "EA 1152",
      "TL 466",
      "DIISOPROPYL-FLUOROPHOSPHATE",
      "Di-Isopropylphosphorofluoridate",
      "Floropryl (TN)",
      "Fluophosphoric Acid, Diisopropyl Ester",
      "Fluorophosphoric Acid, Diisopropyl Ester",
      "Isoflurophate (USP)",
      "PF-3",
      "Phosphorofluoridic Acid, Diisopropyl Ester",
      "T-1703",
      "Bis(1-Methylethyl) Phosphorofluoridate",
      "Dipropan-2-Yl Phosphorofluoridoate",
      "O,O-Diisopropyl Fluorophosphate",
      "Bis(Propan-2-Yl) Fluorophosphate",
      "O,O'-Diisopropyl Phosphoryl Fluoride",
      "Phosphorofluoridic Acid, Bis(1-Methylethyl) Ester",
      "2-[fluoro(Propan-2-Yloxy)Phosphoryl]oxypropane",
    ],
  },
  {
    primary_id: "D0B2PW",
    names: ["Antarelix"],
  },
  {
    primary_id: "D0B2RW",
    names: ["(S)-Ethyl 6-(2-Oxohexadecanamido)Decanoate", "CHEMBL462543"],
  },
  {
    primary_id: "D0B2RZ",
    names: ["ADX-71943"],
  },
  {
    primary_id: "D0B2SW",
    names: ["ASP-3291"],
  },
  {
    primary_id: "D0B2TV",
    names: ["Cis-2-Fluoro-2-(4-Methoxyphenyl)Cyclopropylamine", "CHEMBL468854"],
  },
  {
    primary_id: "D0B2UF",
    names: ["ISIS 105990"],
  },
  {
    primary_id: "D0B2UM",
    names: ["3-(6-Methoxynaphthalen-2-Yl)-4-Methylpyridine", "CHEMBL500664", "ZINC40976977"],
  },
  {
    primary_id: "D0B2UZ",
    names: [
      "Prochlorperazine",
      "CHLOPERAZINE",
      "Capazine",
      "Chlormeprazine",
      "Chlorperazine",
      "Combid",
      "Compazine",
      "Compro",
      "Emelent",
      "Eskatrol",
      "Kronocin",
      "Meterazin",
      "Meterazine",
      "Nipodal",
      "Novamin",
      "Prochloroperazine",
      "Prochlorpemazine",
      "Prochlorperazin",
      "Prochlorperazinum",
      "Prochlorpermazine",
      "Prochlorpromazine",
      "Procloperazine",
      "Proclorperazina",
      "Proclorperazine",
      "Stemetil",
      "Tementil",
      "Temetid",
      "Vertigon",
      "Compazine Suppositories",
      "Prochlorperazine Edisylate Salt",
      "Prochlorperazine Dihydrobromide",
      "Prochlorperazine Dimaleate",
      "Prochlorperazine Maleate",
      "Prochlorperazine Mesilate",
      "Bayer A 173",
      "P77",
      "RP 6140",
      "SKF 4657",
      "Buccastem (TN)",
      "Compazine (TN)",
      "Compro (TN)",
      "Phenotil (TN)",
      "Prochlorperazinum [INN-Latin]",
      "Proclorperazina [INN-Spanish]",
      "Stemetil (TN)",
      "Stemzine (TN)",
      "Compazine (*Maleate*)",
      "Prochlorperazine (JAN/USP/INN)",
      "Prochlorperazine [USAN:INN:BAN:JAN]",
      "Chloro-3 (N-Methylpiperazinyl-3 Propyl)-10 Phenothiazine",
      "Chloro-3 (N-Methylpiperazinyl-3 Propyl)-10 Phenothiazine [French]",
      "N-(Gamma-(4'-Methylpiperazinyl-1')Propyl)-3-Chlorophenothiazine",
      "2-Chloro-10-(3-(1-Methyl-4-Piperazinyl)Propyl)-Phenothiazine",
      "2-Chloro-10-(3-(4-Methyl-1-Piperazinyl)Propyl)-10H-Phenothiazine",
      "2-Chloro-10-(3-(4-Methyl-1-Piperazinyl)Propyl)Phenothiazine",
      "2-Chloro-10-[3-(4-Methyl-1-Piper-Azinyl)Propyl]-10H-Pheno-Thiazine Dimaleate",
      "2-Chloro-10-[3-(4-Methylpiperazin-1-Yl)Propyl]-10H-Phenothiazine",
      "2-Chloro-10-[3-(4-Methylpiperazin-1-Yl)Propyl]phenothiazine",
      "3-Chloro-10-(3-(1-Methyl-4-Piperazinyl)Propyl)Phenothiazine",
      "3-Chloro-10-(3-(4-Methyl-1-Piperazinyl)Propyl)Phenothiazine",
      "6140 RP",
    ],
  },
  {
    primary_id: "D0B2VS",
    names: ["Benzyl Phenyl Ether Derivative 1"],
  },
  {
    primary_id: "D0B2VW",
    names: [
      "2S,4R-4-METHYLGLUTAMATE",
      "(2S,4R)-4-Methylglutamic Acid",
      "31137-74-3",
      "(2S,4R)-2-Amino-4-Methylpentanedioic Acid",
      "2S,4R-4-Methylglutamate",
      "CHEMBL288166",
      "4-Methylglutamic Acid",
      "4-Methylglutamate",
      "(4r)-4-Methyl-L-Glutamic Acid",
      "Threo-Gamma-Methylglutamate",
      "2-Amino-4-Methyl-Pentanedioic Acid",
      "14561-55-8",
      "59685-03-9",
      "NSC 41355",
      "Tocris-0903",
      "AC1L3VAT",
      "SYM",
      "Biomol-NT_000218",
      "AC1Q5QI2",
      "SCHEMBL594187",
      "BPBio1_001308",
      "CTK5B0310",
      "KRKRAOXTGDJWNI-DMTCNVIQSA-N",
      "MolPort-003-848-893",
      "ZINC896091",
      "Glutamic Acid, 4-Methyl- (VAN)",
      "(2S,4R)-4-Methyl Gluta",
    ],
  },
  {
    primary_id: "D0B2WJ",
    names: ["Flufenamic Acid"],
  },
  {
    primary_id: "D0B2WK",
    names: ["Ifetroban Sodium", "BMS-180291 Sodium Salt", "BMS-180291-02"],
  },
  {
    primary_id: "D0B2WQ",
    names: ["Lomeguatrib"],
  },
  {
    primary_id: "D0B2WU",
    names: ["CAR-T Cells Targeting BCMA"],
  },
  {
    primary_id: "D0B2WX",
    names: ["2-(4-Methoxy-Phenyl)-8-Methyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0B2WZ",
    names: ["Daminozide", "DMASA", "SADH", "B 995", "Alar", "Kylar", "B-NINE"],
  },
  {
    primary_id: "D0B2XN",
    names: [
      "Sulfamic Acid 4-Benzoyl-Phenyl Ester",
      "CHEMBL304708",
      "SCHEMBL9451248",
      "4-(Aminosulfonyloxy)Benzophenone",
      "LWUJBMNPWUQTGS-UHFFFAOYSA-N",
      "Sulfamic Acid 4-Benzoylphenyl Ester",
    ],
  },
  {
    primary_id: "D0B2XS",
    names: [
      "Autologous Myoblast Cell Therapy",
      "Autologous Myoblast Cell Therapy (Myocardial Infarction)",
      "FB-1001",
      "Autologous Myoblast Cell Therapy (Myocardial Infarction), Fina Biotech",
    ],
  },
  {
    primary_id: "D0B2YF",
    names: ["2,7-Bis[2-(Piperidino)Acetamido]anthraquinone", "SCHEMBL4299285", "CHEMBL492640"],
  },
  {
    primary_id: "D0B2YJ",
    names: ["PMID25399762-Compound-Table1-C11"],
  },
  {
    primary_id: "D0B2YN",
    names: [
      "1-[(Cyclohexylamino)Ethyl]-1,1-Bisphosphonicacid",
      "CHEMBL261432",
      "[2-(Cyclohexylamino)Ethane-1,1-Diyl]bisphosphonic Acid",
      "2-(Cyclohexylamino)Ethylidene-1,1-Bisphosphonic Acid",
      "SCHEMBL9564518",
      "BDBM50374762",
      "146829-50-7",
      "[2-(Cyclohexylamino)-1-Phosphono-Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0B2ZB",
    names: [
      "(8R,8'R)-4-Hydroxycubebinone",
      "CHEMBL482034",
      "AC1MHYPL",
      "(8R,8''R)-4-Hydroxycubebinone",
      "BDBM50259848",
      "2(3H)-Furanone, Dihydro-4-[(4-Hydroxy-3,5-Dimethoxyphenyl)Methyl]-3-[(7-Methoxy-1,3-Benzodioxol-5-Yl)Methyl]-, (3R,4R)-",
    ],
  },
  {
    primary_id: "D0B2ZJ",
    names: [
      "7-(Biphenyl-3-Yloxy)-1-Oxazol-2-Yl-Heptan-1-One",
      "CHEMBL127351",
      "SCHEMBL7365180",
      "HWZHDGRMABBYOV-UHFFFAOYSA-N",
      "BDBM50222367",
      "7-((1,1'-Biphenyl)-3-Yloxy)-1-(1 ,3-Oxazol-2-Yl)-1-Heptanone",
    ],
  },
  {
    primary_id: "D0B2ZM",
    names: ["GB-2542"],
  },
  {
    primary_id: "D0B2ZZ",
    names: ["Esomeprazole Magnesium + Aspirin"],
  },
  {
    primary_id: "D0B3AR",
    names: ["TRIM"],
  },
  {
    primary_id: "D0B3BJ",
    names: ["ICI-174864"],
  },
  {
    primary_id: "D0B3BS",
    names: ["C[Asp22-Lys26][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0B3CC",
    names: ["AlloStim", "AlloStim (TN)"],
  },
  {
    primary_id: "D0B3CG",
    names: ["Pyrazolopyrimidine Derivative 4"],
  },
  {
    primary_id: "D0B3CT",
    names: ["BE-13793C"],
  },
  {
    primary_id: "D0B3CU",
    names: ["C-Linked Disaccharide Biphenyl Mannoside Derivative 5"],
  },
  {
    primary_id: "D0B3CY",
    names: [
      "L-692429",
      "3-Amino-3-Methyl-N-[2-Oxo-1-[2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Ylmethyl]-2,3,4,5-Tetrahydro-1H-1-Benzazepin-3(R)-Yl]butyramide",
    ],
  },
  {
    primary_id: "D0B3DE",
    names: ["AVN-628"],
  },
  {
    primary_id: "D0B3DV",
    names: ["KTE-C19"],
  },
  {
    primary_id: "D0B3DX",
    names: ["Norelin"],
  },
  {
    primary_id: "D0B3FI",
    names: ["Boc-Ile-Leu-L-(Difluoro)Aminobutyric Aid", "CHEMBL175479"],
  },
  {
    primary_id: "D0B3FN",
    names: ["PMID25726713-Compound-35"],
  },
  {
    primary_id: "D0B3FV",
    names: [
      "NVP-LAQ824",
      "Dacinostat",
      "404951-53-7",
      "LAQ824",
      "LAQ-824",
      "LAQ824 (Dacinostat)",
      "UNII-V10P524501",
      "(E)-N-Hydroxy-3-[4-[[2-Hydroxyethyl-[2-(1H-Indol-3-Yl)Ethyl]amino]methyl]phenyl]prop-2-Enamide",
      "CHEMBL356066",
      "V10P524501",
      "(2E)-N-Hydroxy-3-[4-({(2-Hydroxyethyl)[2-(1H-Indol-3-Yl)Ethyl]amino}methyl)Phenyl]prop-2-Enamide",
      "Dacinostat [INN]",
      "(E)-N-Hydroxy-3-[4-[[2-Hydroxyethyl-[2-(1H-Indol-3-Yl)Ethyl]amino]meth Yl]phenyl]prop-2-Enamide",
      "(2E)-N-Hydroxy-3-(4-{[(2-Hydroxyethyl)[2-(1H-Indol-3-Yl)Ethyl]amino]methyl}phenyl)Prop-2-Enamide",
      "NVP-LAQ 824",
      "NVP-LAQ824, Dacinostat, LAQ824",
      "LBH539",
    ],
  },
  {
    primary_id: "D0B3GF",
    names: ["JNJ-26854165"],
  },
  {
    primary_id: "D0B3GP",
    names: ["6-Amino-N-(2,4-Dichlorobenzyl)Nicotinamide", "CHEMBL567109"],
  },
  {
    primary_id: "D0B3GX",
    names: ["Quinazoline Derivative 14"],
  },
  {
    primary_id: "D0B3HD",
    names: [
      "Caffeine",
      "CFF",
      "Cafamil",
      "Cafecon",
      "Cafeina",
      "Cafeine",
      "Caffedrine",
      "Caffein",
      "Caffeina",
      "Caffenium",
      "Caffine",
      "Cafipel",
      "Coffein",
      "Coffeine",
      "Coffeinum",
      "DHCplus",
      "Dasin",
      "Dexitac",
      "Diurex",
      "Durvitan",
      "Enerjets",
      "Ercatab",
      "Guaranine",
      "Hycomine",
      "Kofein",
      "Koffein",
      "Mateina",
      "Methyltheobromide",
      "Methyltheobromine",
      "Miudol",
      "Nodaca",
      "Organex",
      "Percutafeine",
      "Phensal",
      "Stim",
      "Teina",
      "Thein",
      "Theine",
      "Tirend",
      "Vivarin",
      "Anacin Maximum Strength",
      "Anhydrous Caffeine",
      "Caffedrine Caplets",
      "Caffeina [Italian]",
      "Caffeine Pure",
      "Caffeine Solution",
      "Coffein [German]",
      "Coffeinum N",
      "Coffeinum Purrum",
      "Component Of Cafergot",
      "DHC Plus",
      "Dexitac Stay Alert Stimulant",
      "Eldiatric C",
      "GlaxoSmithKline Brand Of Caffeine",
      "Hycomine Compound",
      "Keep Alert",
      "Kofein [Czech]",
      "Koffein [German]",
      "Merck Dura Brand Of Caffeine",
      "Methylxanthine Theophylline",
      "Midol Maximum Strength",
      "Monomethyl Derivative Of Theophylline",
      "Natural Caffeinum",
      "Nix Nap",
      "No Doz",
      "Nodoz Maximum Strength Caplets",
      "Passauer Brand Of Caffeine",
      "Percoffedrinol N",
      "Pierre Fabre Brand Of Caffeine",
      "Quick Pep",
      "Republic Drug Brand Of Caffeine",
      "Seid Brand Of Caffeine",
      "Theobromine Me",
      "Theophylline Me",
      "C 0750",
      "Propoxyphene Compound 65",
      "SK 65 Compound",
      "TNP00310",
      "Thompson Brand 1 Of Caffeine",
      "Thompson Brand 2 Of Caffeine",
      "Alert-Pep",
      "Anhydrous Caffeine (JP15)",
      "Anhydrous Caffeine (TN)",
      "Berlin-Chemie Brand Of Caffeine",
      "Bristol-Myers Squibb Brand Of Caffeine",
      "Cafcit (TN)",
      "Caffeine (USP)",
      "Caffeine (Natural)",
      "Caffeine [BAN:JAN]",
      "Caffeine, Monohydrate",
      "Caffeine, Anhydrous",
      "Caffeine, Synthetic",
      "No-Doz",
      "Pep-Back",
      "Propoxyphene Compound-65",
      "Quick-Pep",
      "Refresh'n",
      "SK-65 Compound",
      "Tri-Aqua",
      "Ultra Pep-Back",
      "Wake-Up",
      "CU-01000012617-3",
      "P-A-C Analgesic Tablets",
      "Theophylline, 7-Methyl",
      "Xanthine, 1,3,7-Trimethyl",
      "1,3,7-Trimethyl-2,6-Dioxopurine",
      "1,3,7-Trimethylpurine-2,6-Dione",
      "1,3,7-Trimethylxanthine",
      "1-3-7-TRIMETHYLXANTHINE",
      "1-Methyltheobromine",
      "3,7-Dihydro-1,3,7-Trimethyl-1H-Purine",
      "7-Methyltheophylline",
    ],
  },
  {
    primary_id: "D0B3HF",
    names: ["SCRI-HuCAR19v1"],
  },
  {
    primary_id: "D0B3HH",
    names: ["JNJ-16567083"],
  },
  {
    primary_id: "D0B3HP",
    names: ["SGC-1061"],
  },
  {
    primary_id: "D0B3IC",
    names: ["Dopropidil", "CERM-4205"],
  },
  {
    primary_id: "D0B3ID",
    names: ["Medi-NK"],
  },
  {
    primary_id: "D0B3IZ",
    names: ["AMPSL"],
  },
  {
    primary_id: "D0B3JB",
    names: ["4-Tert-Butyl-N-(Naphthalen-1-Yl)Oxazol-2-Amine", "CHEMBL206878"],
  },
  {
    primary_id: "D0B3KI",
    names: ["YD-101"],
  },
  {
    primary_id: "D0B3LN",
    names: [
      "D-Phenylalanine",
      "673-06-3",
      "H-D-Phe-OH",
      "(2R)-2-Amino-3-Phenylpropanoic Acid",
      "Sabiden",
      "Phenylalanine D-Form",
      "D-Phe",
      "(R)-2-AMINO-3-PHENYLPROPANOIC ACID",
      "Alanine, Phenyl-, D-",
      "NCI-C60195",
      "UNII-032K16VRCU",
      "(R)-2-Amino-3-Phenylpropionic Acid",
      "D-Alpha-Amino-Beta-Phenylpropionic Acid",
      "CCRIS 6267",
      "EINECS 211-603-5",
      "NSC 25005",
      "032K16VRCU",
      "CHEBI:16998",
      "COLNVLDHVKWLRT-MRVPVSSYSA-N",
      "MFCD00004270",
      "D-Phenylalanine, 99+%",
      "D-Phenylalanin",
      "D-.beta.-Phenylalanine",
      "Phenylalanine,d-",
      "DPhe",
      "L-3-Phenylalanine",
      "HDPheOH",
      "(D)-Phenylalanine",
      "Phenylalanine,(S)",
    ],
  },
  {
    primary_id: "D0B3LP",
    names: [
      "Ro-16-6028",
      "Bretazenil",
      "84379-13-5",
      "Ro 16-6028",
      "UNII-OSZ0E9DGOJ",
      "Bretazenil [USAN:INN]",
      "1-Bretazenil",
      "Bretazenilum [INN-Latin]",
      "OSZ0E9DGOJ",
      "BRN 4765855",
      "C19H20BrN3O3",
      "CHEMBL366947",
      "NCGC00160640-01",
      "DSSTox_RID_81493",
      "DSSTox_CID_26266",
      "DSSTox_GSID_46266",
      "Tert-Butyl (S)-8-Bromo-11,12,13,13a-Tetrahydro-9-Oxo-9H-Imidazo(1,5-A)Pyrrolo(2,1-C)(1,4)Benzodiazepine-1-Carboxylate",
      "Bretazenilum",
      "9H-Imidazo[1,5-A]pyrrolo[2,1-C][1,4]benzodiazepine-1-Carboxylic Acid",
      "9H-Imidazo(1,5-A)Pyrrolo(2,1-C)(1,4)Benzodiazepine-1-Carboxylic Acid, 8-Bro",
    ],
  },
  {
    primary_id: "D0B3MG",
    names: [
      "Carbodine",
      "Carbocyclic Cytidine",
      "4-Amino-1-[(1R,2S,3R,4R)-2,3-Dihydroxy-4-(Hydroxymethyl)Cyclopentyl]pyrimidin-2-One",
      "71184-20-8",
      "(-)-Carbodine",
      "(+/-)-Carbodine",
      "AC1LA1FO",
      "C-ARC-C",
      "SCHEMBL3333453",
      "CHEMBL1089302",
      "2(1H)-Pyrimidinone, 4-Amino-1-[2,3-Dihydroxy-4-(Hydroxymethyl)Cyclopentyl]-, (2:1),(1alpha,2beta,3beta, 4alpha)-(+/-)-",
    ],
  },
  {
    primary_id: "D0B3MV",
    names: ["SSR-69071"],
  },
  {
    primary_id: "D0B3MX",
    names: [
      "NBI-6024",
      "Diabetes Therapeutics, Neurocrine/Taisho",
      "APL (Diabetes), Neurocrine/Taisho",
      "Applied Peptide Ligand(Diabetes), Neurocrine/Taisho",
      "Insulin B9-23 Peptide Analog, Taisho/Neurocrine",
    ],
  },
  {
    primary_id: "D0B3NZ",
    names: ["N-(5-Benzoyl-4-Phenylthiazol-2-Yl)Benzamide"],
  },
  {
    primary_id: "D0B3PC",
    names: ["UCB35625"],
  },
  {
    primary_id: "D0B3QI",
    names: ["Dihydropyrido Phthalazinone Derivative 1"],
  },
  {
    primary_id: "D0B3QM",
    names: [
      "Metyrosine",
      "Demser",
      "Methyltyrosine",
      "Metirosina",
      "Metirosine",
      "Metirosinum",
      "Racemetirosina",
      "Racemetirosine",
      "Racemetirosinum",
      "Metyrosine[USAN]",
      "Racemetirosine [INN]",
      "M 8131",
      "MK 781",
      "Alpha-MPT",
      "Alpha-Methyltyrosine",
      "Demser (TN)",
      "H 9/88",
      "L 588357-0",
      "L-AMPT",
      "M-5185",
      "MK-781",
      "Metirosina [INN-Spanish]",
      "Metirosine (INN)",
      "Metirosinum [INN-Latin]",
      "Metyrosine (USP)",
      "Racemetirosina [INN-Spanish]",
      "Racemetirosinum [INN-Latin]",
      "DL-Alpha-Methyltyrosine",
      "L-588357-0",
      "Alpha-Methyl-L-Tyrosine",
      "Alpha-Methyl-Dl-Tyrosine",
      "Alpha-Methyl-P-Tyrosine",
      "Alpha-Methyl-Para-Tyrosine",
      "A-Methyl-L-P-Tyrosine",
      "Alpha-Methyl-L-P-Tyrosine",
      "Alpha-Methyltyrosine, (L)-Isomer",
      "DL-Alpha-Methyl-P-Tyrosine",
      "L-Alpha-Methyl-P-Tyrosine",
      "Alpha-Methyltyrosine, (D,L)-Isomer",
      "DL-2-Methyl-3-(4-Hydroxyphenyl)Alanine",
      "Alpha-Methyltyrosine, (+,-)-Isomer",
      "L-2-Methyl-3-(4-Hydroxy-Phenyl)Alanine",
      "(+-)-Alpha-Methyl-DL-Tyrosine",
      "(-)-(S)-2-Amino-3-(4-Hydroxyphenyl)-2-Methylpropionsaeure",
      "(-)-Alpha-Methyl-DL-Tyrosine",
      "(-)-Alpha-Methyl-L-Tyrosine",
      "(2R)-2-Amino-3-(4-Hydroxyphenyl)-2-Methylpropanoic Acid",
      "(2S)-2-Amino-3-(4-Hydroxyphenyl)-2-Methylpropanoic Acid",
      "(R,S)-2-Amino-3-(4-Hydroxyphenyl)-2-Methylpropionsaeure",
      "(S)-Alpha-Methyltyrosine",
      "2-Amino-3-(4-Hydroxyphenyl)-2-Methylpropanoic Acid",
      "4-Hydroxy-Alpha-Methylphenylalanine",
    ],
  },
  {
    primary_id: "D0B3QN",
    names: [
      "(R)(+)-7-Methyl-2-Phenylchroman-4-One",
      "CHEMBL601844",
      "SCHEMBL1895390",
      "FNOKOVWLKVWYRY-OAHLLOKOSA-N",
      "BDBM50310170",
      "(R)-7-Methyl-2-Phenylchroman-4-One",
    ],
  },
  {
    primary_id: "D0B3RK",
    names: ["Indole-Based Analog 9"],
  },
  {
    primary_id: "D0B3RL",
    names: ["R-IFN-1a"],
  },
  {
    primary_id: "D0B3SL",
    names: ["RG7321"],
  },
  {
    primary_id: "D0B3SV",
    names: ["Meningococcal Vaccine Group B Quadrivalent Recombinant Vaccine", "4CMenB"],
  },
  {
    primary_id: "D0B3TA",
    names: ["TD-1414"],
  },
  {
    primary_id: "D0B3UE",
    names: ["9-Cyclopentyl-9H-Adenine", "9-Cyclopentyl-9H-Purin-6-Ylamine"],
  },
  {
    primary_id: "D0B3UG",
    names: [
      "4-(2-Thienyl)-1-(4-Methylbenzyl)-1h-Imidazole",
      "4-(2-THIENYL)-1-(4-METHYLBENZYL)-1H-IMIDAZOLE",
      "Disubstituted Imidazole 1",
      "AC1L9J9C",
      "CHEMBL66526",
      "SCHEMBL4319860",
      "BDBM8738",
      "DB03030",
      "1-(4-Methylbenzyl)-4-(2-Thienyl)-1h-Imidazole",
      "1-(4-Methylbenzyl)-4-Thiophen-2-Yl-1H-Imidazole",
      "1-[(4-Methylphenyl)Methyl]-4-Thiophen-2-Ylimidazole",
      "1-[(4-Methylphenyl)Methyl]-4-(Thiophen-2-Yl)Imidazole",
    ],
  },
  {
    primary_id: "D0B3UJ",
    names: ["Paliperidone"],
  },
  {
    primary_id: "D0B3UN",
    names: ["RXP470.1"],
  },
  {
    primary_id: "D0B3US",
    names: ["Cbz-Ile-MetO2-Ala-LeuVSMe", "CHEMBL383730"],
  },
  {
    primary_id: "D0B3UU",
    names: ["Antiviral BMS-1"],
  },
  {
    primary_id: "D0B3VR",
    names: ["H5N1 Avian Influenza Vaccine"],
  },
  {
    primary_id: "D0B3VU",
    names: ["TPO Therapy Program, Thrombocytopenia"],
  },
  {
    primary_id: "D0B3WF",
    names: ["YVAD"],
  },
  {
    primary_id: "D0B3WM",
    names: ["FluMist"],
  },
  {
    primary_id: "D0B3WX",
    names: [
      "CINANSERIN",
      "Cinanserin [INN]",
      "1166-34-3",
      "Cinanserine [INN-French]",
      "Cinanserinum [INN-Latin]",
      "UNII-KI6J9OY7A3",
      "Cinanserina [INN-Spanish]",
      "KI6J9OY7A3",
      "CHEMBL18786",
      "NCGC00024599-02",
      "2-Propenamide, N-(2-((3-(Dimethylamino)Propyl)Thio)Phenyl)-3-Phenyl-",
      "DSSTox_RID_81031",
      "DSSTox_CID_25653",
      "DSSTox_GSID_45653",
      "Cinanserinum",
      "Cinanserina",
      "Cinanserine",
      "CAS-1166-34-3",
      "Cinanserin Monohydrochloride",
      "Cinaserin",
      "SQ 16167",
      "SQ-16,167",
      "SQ-10,643",
      "AC1NUDS4",
      "Biomol-NT_000092",
      "2'-((3-Dimethylaminopropyl)Thio)Cinnamanilide",
      "SCHEMBL571561",
      "BPBio1_001097",
    ],
  },
  {
    primary_id: "D0B3XG",
    names: ["Recombinant Von Willebrand Factor/Recombinant Factor VIII Complex"],
  },
  {
    primary_id: "D0B3XP",
    names: ["NSC-37173"],
  },
  {
    primary_id: "D0B3XW",
    names: ["Cyclic Sulfonamide Derivative 2"],
  },
  {
    primary_id: "D0B3YB",
    names: ["Zinc Finger Protein Transcripition Factors"],
  },
  {
    primary_id: "D0B3YK",
    names: ["AZD8601"],
  },
  {
    primary_id: "D0B3YW",
    names: ["Isoquinoline Alkaloids"],
  },
  {
    primary_id: "D0B3ZB",
    names: ["Indirubin Deriv. 8a"],
  },
  {
    primary_id: "D0B3ZC",
    names: ["Claudiximab"],
  },
  {
    primary_id: "D0B3ZF",
    names: [
      "ORM-12741",
      "Alpha 2c Adrenoceptor Antagonist (Neurological Diseases), Orion",
      "Alpha 2c Adrenoceptor Antagonist (Psychiatric Disorders), Orion",
    ],
  },
  {
    primary_id: "D0B3ZM",
    names: [
      "6-Mercapto-Hexanoic Acid Phenylamide",
      "CHEMBL109654",
      "Hexanamide, 6-Mercapto-N-Phenyl-",
      "SCHEMBL14254925",
      "BDBM50027600",
    ],
  },
  {
    primary_id: "D0B4AB",
    names: ["ACEA-2085", "AMPA/Glycine Antagonist, CoCensys"],
  },
  {
    primary_id: "D0B4AH",
    names: ["RECALL-VAX"],
  },
  {
    primary_id: "D0B4BN",
    names: ["Ro-60-0175"],
  },
  {
    primary_id: "D0B4CA",
    names: [
      "NSC-656158",
      "CHM-1",
      "154554-41-3",
      "CHM 1",
      "CHM-1 Hydrate",
      "CHEMBL280099",
      "6-(2-Fluorophenyl)[1,3]dioxolo[4,5-G]quinolin-8(5h)-One",
      "1,3-Dioxolo[4,5-G]quinolin-8(5H)-One, 6-(2-Fluorophenyl)-",
      "NSC656158",
      "AC1Q4OOR",
      "AC1L8C3G",
      "Neuro_000375",
      "SCHEMBL338729",
      "CTK7C2351",
      "AOB2618",
      "DTXSID80327429",
      "MolPort-009-194-114",
      "HMS3262E04",
      "ZINC5581788",
      "KS-00002WS1",
      "Tox21_500741",
      "BDBM50041119",
      "AKOS015994545",
      "LP00741",
      "CE-0108",
      "NCGC00261426-01",
      "NCGC00186026-01",
      "NCI60_019422",
      "J-009113",
      "1,5-G]quinolin-8(5H)-One, 6-(2-Fluorophenyl)-",
      "BRD-K94145482-001-02-8",
    ],
  },
  {
    primary_id: "D0B4CD",
    names: ["GSK-2018682"],
  },
  {
    primary_id: "D0B4CH",
    names: ["Peptide Analog 45"],
  },
  {
    primary_id: "D0B4CJ",
    names: ["2-Morpholin-4-Yl-6-Phenyl-Chromen-4-One", "CHEMBL369146", "SCHEMBL3542251"],
  },
  {
    primary_id: "D0B4CO",
    names: ["NNC-14-0185"],
  },
  {
    primary_id: "D0B4CQ",
    names: ["6-Benzyl-3-Propylaminocarbonyl-4-Quinolone", "CHEMBL380906"],
  },
  {
    primary_id: "D0B4CV",
    names: ["N-[(CYCLOHEXYLAMINO)CARBONYL]GLYCINE"],
  },
  {
    primary_id: "D0B4DB",
    names: ["Interleukin-1-Alpha - Amgen/Roche"],
  },
  {
    primary_id: "D0B4DC",
    names: [
      "Butenafine",
      "Butenafina",
      "Butenafinum",
      "Mentax",
      "Butenafine HCL",
      "Butenafine [INN]",
      "Butenafine Hydrochloride",
      "KP 363",
      "Butenafina [INN-Spanish]",
      "Butenafine (INN)",
      "Butenafinum [INN-Latin]",
      "Butop (TN)",
      "KP-363",
      "Mentax (TN)",
      "N-4-Tert-Butylbenzyl-N-Methyl-1-Naphthalene Methylamine Hydrochloride",
      "N-P-Tert-Butylbenzyl-N-Methyl-1-Naphthalenemethylamine",
      "N-(P-Tert-Butylbenzyl)-N-Methyl-1-Naphthalenemethylamine",
      "1-(4-Tert-Butylphenyl)-N-Methyl-N-(Naphthalen-1-Ylmethyl)Methanamine",
      "4-Tert-Butylbenzyl(Methyl)(1-Naphthalenemethyl)Amine",
    ],
  },
  {
    primary_id: "D0B4DK",
    names: ["ChronVac-B"],
  },
  {
    primary_id: "D0B4DL",
    names: ["CRS-100"],
  },
  {
    primary_id: "D0B4DP",
    names: [
      "2-Pyridin-4-Yl-1,2,3,4-Tetrahydro-Isoquinoline",
      "CHEMBL51594",
      "SCHEMBL6441852",
      "CIOBWNSCGKJAQU-UHFFFAOYSA-N",
      "ZINC13489832",
      "BDBM50127824",
      "AKOS027557951",
    ],
  },
  {
    primary_id: "D0B4DT",
    names: ["3-[8-(Benzylmethylamino)Octyloxy]xanthen-9-One", "CHEMBL224469"],
  },
  {
    primary_id: "D0B4EG",
    names: ["MBX-500"],
  },
  {
    primary_id: "D0B4ES",
    names: ["Atamestane-Plus- Toremifene", "Atamestane-Plus-Toremifene"],
  },
  {
    primary_id: "D0B4EV",
    names: ["IPENOXAZONE"],
  },
  {
    primary_id: "D0B4FN",
    names: [
      "2pyrrolidin-1-Yl-1-Phenylpentan-1-One",
      "14530-33-7",
      "Alpha-PVP",
      "1-Phenyl-2-(Pyrrolidin-1-Yl)Pentan-1-One",
      "Alpha-Pyrrolidinovalerophenone",
      "Desmethyl Pyrovalerone",
      "Alpha-Pyrrolidinopentiophenone",
      "CHEMBL205082",
      "1-Pentanone, 1-Phenyl-2-(1-Pyrrolidinyl)-",
      "Flakka",
      "Beta-Ketone-Prolintane",
      "Prolintanone",
      "Gravel",
      "Beta-Keto-Prolintane",
      "A-PVP",
      "1-Phenyl-2-(1-Pyrrolidinyl)-1-Pentanone",
      "A-Pyrrolidinopentiophenone",
      "O-2387",
      "SCHEMBL4936204",
      "DTXSID70456954",
      "YDIIDRWHPFMLGR-UHFFFAOYSA-N",
      "MFCD24386810",
      "BDBM50182584",
      "Alpha-Pyrrolidinopentiophenone, A-PVP",
    ],
  },
  {
    primary_id: "D0B4FV",
    names: ["Phthalazine Ketone Derivative 2"],
  },
  {
    primary_id: "D0B4GJ",
    names: ["3-(Dodecylsulfinyl)-1,1,1-Trifluoropropan-2-One", "CHEMBL273138"],
  },
  {
    primary_id: "D0B4GP",
    names: ["Propan-2-One O-4-(Nonyloxy)Phenylcarbamoyl Oxime", "CHEMBL597014"],
  },
  {
    primary_id: "D0B4GV",
    names: [
      "4-Amino-2H-Chromen-2-One",
      "4-Amino-Chromen-2-One",
      "4-Aminocoumarin",
      "53348-92-8",
      "4-Amino-2H-1-Benzopyran-2-One",
      "2H-1-Benzopyran-2-One, 4-Amino-",
      "UNII-SCI2054E4F",
      "CHEMBL240482",
      "SCI2054E4F",
      "Coumarin, 4-Amino-",
      "4-Aminochromen-2-One",
      "AC1LGGAS",
      "Coumarin, 4-Amino- (7CI)",
      "SCHEMBL4396062",
      "AC1Q69X0",
      "CTK7H2649",
      "DTXSID90201509",
      "MolPort-000-000-981",
      "AHZAKFLOHIRCDU-UHFFFAOYSA-N",
      "ZINC337346",
      "STL371135",
      "BDBM50226833",
      "4-[Amino]-2H-1-Benzopyran-2-One",
      "AKOS004909244",
      "FCH1162846",
      "MCULE-3726196442",
      "MB00371",
      "LS-39436",
      "DB-071668",
      "KB-189290",
      "FT-0740005",
      "A58124",
    ],
  },
  {
    primary_id: "D0B4GX",
    names: ["LY456066"],
  },
  {
    primary_id: "D0B4GZ",
    names: ["E6014"],
  },
  {
    primary_id: "D0B4IF",
    names: ["Ketoconazole"],
  },
  {
    primary_id: "D0B4IH",
    names: ["PMID25991433-Compound-D1"],
  },
  {
    primary_id: "D0B4IN",
    names: [
      "NSC-654077",
      "DIBA-1",
      "PD 22551",
      "PD-22551",
      "PD022551",
      "CHEMBL418037",
      "171744-39-1",
      "2,2'-Dithiobis-(N-(4-Sulfamoylphenyl)-Benzamide)",
      "NSC654077",
      "DIBA (Antineoplastic)",
      "N-(4-Sulfamoylphenyl)-2-[[2-[(4-Sulfamoylphenyl)Carbamoyl]phenyl]disulfanyl]benzamide",
      "2,2'-Dithiobis-[N-(4-Sulfamoylphenyl)-Benzamide]",
      "NSC 654077",
      "N-(4-Sulfamoylphenyl)-2-({2-[(4-Sulfamoylphenyl)Carbamoyl]phenyl}disulfanyl)Benzamide",
      "AC1L8BKK",
      "AC1Q6VC8",
      "SCHEMBL6223450",
      "BDBM11052",
      "Disulfide-Containing Sulfonamide 5a",
    ],
  },
  {
    primary_id: "D0B4IU",
    names: ["Heterocyclic Derivative 1"],
  },
  {
    primary_id: "D0B4JL",
    names: [
      "SERAZAPINE HYDROCHLORIDE",
      "CGS-15040A",
      "Serazapine Hydrochloride < Rec INNM",
      "Rac-2-Methyl-1,3,4,16b-Tetrahydro-2H,10H-Indolo[2,1-C]pyrazino[1,2-A][1,4]benzodiazepine-16-Carboxylic Acid Methyl Ester Monohydrochloride",
    ],
  },
  {
    primary_id: "D0B4JO",
    names: ["Ac-I[CVWQDWGAHRC]T"],
  },
  {
    primary_id: "D0B4JQ",
    names: [
      "Tripelennamine",
      "Benzoxale",
      "Cizaron",
      "Dehistin",
      "Piribenzil",
      "Pyribenzamin",
      "Pyribenzamine",
      "Pyridbenzamine",
      "Resistamine",
      "Tonaril",
      "Tripelannamine",
      "Tripelenamina",
      "Tripelenamine",
      "Tripelennamin",
      "Tripelennamina",
      "Tripelennaminum",
      "Tripellenamine",
      "Triplennamine",
      "Vetibenzamina",
      "Pyrinamine Base",
      "Tripelennamina [Italian]",
      "Tripelennamine [BAN]",
      "Tripelennamine [INN]",
      "Ts And Blues",
      "Pyribenzamine (TN)",
      "Pyristine (Piristina)",
      "Tripelenamina [INN-Spanish]",
      "Tripelennamine (INN)",
      "Tripelennaminum [INN-Latin]",
      "Tripelennamine Citrate (1:1)",
      "Beta-Dimethylaminoethyl-2-Pyridylaminotoluene",
      "Beta-Dimethylaminoethyl-2-Pyridylbenzylamine",
      "Benzyl-(Alpha-Pyridyl)-Dimethylaethylendiamin",
      "Benzyl-(Alpha-Pyridyl)-Dimethylaethylendiamin [German]",
      "Benzyl-(Alpha.-Pyridyl)-Dimethylaethylendiamin",
      "Benzyl-.alpha.-Pyridyl-Dimethyl-Aethylendiamin",
      "N-Benzyl-N-[2-(Dimethylamino)Ethyl]pyridin-2-Amine",
      "N-Benzyl-N',N'-Dimethyl-N-2-Pyridylethylenediamine",
      "N,N-Dimethyl-N'-Benzyl-N'-(2-Pyridyl)Ethylenediamine",
      "N,N-Dimethyl-N'-Benzyl-N'-(Alpha-Pyridyl)Ethylenediamine",
      "N-Benzyl-N',N'-Dimethyl-N-(2-Pyridyl)Ethylenediamine",
      "N-Benzyl-N',N'-Dimethyl-N-2-(Pyridylethylene)Diamine",
      "N-Benzyl-N-(2-Pyridyl)-N',N'-Dimethyl Ethylenediamine",
      "N,N-Dimethyl-N'-Benzyl-N'-(Alpha.-Pyridyl)Ethylenediamine",
      "N'-Benzyl-N,N-Dimethyl-N'-Pyridin-2-Ylethane-1,2-Diamine",
      "N,N-Dimethyl-N'-(Phenylmethyl)-N'-2-Pyridinyl-1,2-Ethanediamine",
      "N,N-Dimethyl-N'-(Phenylmethyl)-N'-Pyridin-2-Ylethane-1,2-Diamine",
      "N-Benzyl-N',N'-Dimethyl-N-Pyridin-2-Yl-Ethane-1,2-Diamine Hydrochloride",
      "1,2-Ethanediamine, N,N-Dimethyl-N'-(Phenylmethyl)-N'-2-Pyridinyl-(9CI)",
      "2-(Benzyl(2-Dimethylaminoethyl)Amino)Pyridine",
      "2-(N-Benzyl-N-(2-Dimethylaminoethyl)Amino)Pyridine",
      "2-(Benzyl(2-(Dimethylamino)Ethyl)Amino)Pyridine",
      "2-[Benzyl(2-Dimethylaminoethyl)Amino]pyridine",
      "2-[N-Benzyl-N-(2-Dimethylaminoethyl)Amino]pyridine",
    ],
  },
  {
    primary_id: "D0B4JS",
    names: ["1-(1,3-Diphenylpropylidene)Thiosemicarbazide", "CHEMBL429858", "BDBM50377591"],
  },
  {
    primary_id: "D0B4JU",
    names: ["C(-GRGDfL-)", "CHEMBL235999"],
  },
  {
    primary_id: "D0B4KG",
    names: ["NCI-58425"],
  },
  {
    primary_id: "D0B4KH",
    names: ["Technetium TC 99M Medronate"],
  },
  {
    primary_id: "D0B4KU",
    names: ["PF-06273340"],
  },
  {
    primary_id: "D0B4LD",
    names: ["(2-Biphenyl-3-Yl-Ethyl)-Dimethyl-Amine", "CHEMBL234882"],
  },
  {
    primary_id: "D0B4MB",
    names: ["PRT-300"],
  },
  {
    primary_id: "D0B4ME",
    names: [
      "CX-1942",
      "AMPAKINE Prodrug (Respiratory Depression), Biovail Laboratories",
      "AMPAKINE Prodrug (Respiratory Depression), Valeant",
      "AMPAKINE Prodrug (Respiratory Depression/Sleep Apnea), Cortex",
    ],
  },
  {
    primary_id: "D0B4MH",
    names: ["Cytorex-EBV"],
  },
  {
    primary_id: "D0B4MK",
    names: ["ISIS-STAT3"],
  },
  {
    primary_id: "D0B4ML",
    names: [
      "Corticosteroids",
      "Corticosteroids (Iontophoresis, Acne/Psoriasis)",
      "Corticosteroids (Iontophoresis, Acne/Psoriasis), NB Therapeutics;Corticosteroids (Iontophoresis, Acne/Psoriasis), Nitric Biotherapeutics",
      "Corticosteroids (SoloRx/Topical, Acne/Psoriasis), Transport Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0B4NI",
    names: ["PMID25666693-Compound-77"],
  },
  {
    primary_id: "D0B4NU",
    names: ["PGlu-His-Trp-Ser-Tyr-Gly-Leu-Arg-Pro-Gly-NH2"],
  },
  {
    primary_id: "D0B4OF",
    names: ["123I-Iometopane"],
  },
  {
    primary_id: "D0B4OK",
    names: ["Sym015"],
  },
  {
    primary_id: "D0B4OL",
    names: ["Ac-YRMEHdFRWGSPPKD-NH2"],
  },
  {
    primary_id: "D0B4PH",
    names: [
      "N*4*-(3-Bromo-Phenyl)-Quinazoline-4,6,7-Triamine",
      "Bpdq",
      "CHEMBL328216",
      "4-N-(3-Bromophenyl)Quinazoline-4,6,7-Triamine",
      "4-[(3-Bromophenyl)Amino]-6,7-Diaminoquinazoline",
      "169205-87-2",
      "AC1L1DNE",
      "N4-(3-Bromophenyl)-4,6,7-Quinazolinetriamine",
      "K00041",
      "SCHEMBL7221302",
      "BDBM3303",
      "4-Anilinoquinazoline Deriv. 54",
      "CTK8F8323",
      "ADXSZLCTQCWMTE-UHFFFAOYSA-N",
      "IN1401",
      "HSCI1_000040",
      "AKOS025149794",
      "4-(3-Bromoanilino)-6,7-Diaminoquinazoline",
      "N-(3-Bromophenyl)Quinazoline-4,6,7-Triamine",
      "4[ (3-Bromophenyl)Aminol-6,7-Diaminoquinazoline",
      "J-010514",
    ],
  },
  {
    primary_id: "D0B4PI",
    names: ["Y-C[D-Pen-(2R,3S)-2-Me-(2')Nal-GSFC]KR-NH2", "CHEMBL262212", "BDBM50064837"],
  },
  {
    primary_id: "D0B4RU",
    names: ["Pregnenolone"],
  },
  {
    primary_id: "D0B4SF",
    names: ["Impromidine"],
  },
  {
    primary_id: "D0B4SJ",
    names: ["VRC3375"],
  },
  {
    primary_id: "D0B4SL",
    names: ["D-218", "CHEMBL513352", "BDBM50261302"],
  },
  {
    primary_id: "D0B4SQ",
    names: ["H-Arg-Arg-Pro-Tyr-Ile-Aac-OH", "CHEMBL507382"],
  },
  {
    primary_id: "D0B4SU",
    names: ["PQ401"],
  },
  {
    primary_id: "D0B4SZ",
    names: [
      "BMS 790052",
      "Daclatasvir [USAN:INN]",
      "SCHEMBL13337981",
      "Dimethyl N,N'-(Biphenyl-4,4'-Diylbis{1H-Imidazole-5,2-Diyl-((2S)-Pyrrolidine-2,1- Diyl)((1S)-1-(1-Methylethyl)-2-Oxoethane-2,1-Diyl)})Dicarbamate",
      "Carbamic Acid, N,N'-((1,1'-Biphenyl)-4,4'-Diylbis(1H-Imidazole-5,2-Diyl-(2S)-2,1-Pyrrolidinediyl((1S)-1-(1-Methylethyl)-2-Oxo-2,1-Ethanediyl)))Bis-, C,C'-Dimethyl Ester",
    ],
  },
  {
    primary_id: "D0B4TN",
    names: [
      "Biperiden",
      "Akineton",
      "Beperiden",
      "Biperidene",
      "Biperideno",
      "Biperidenum",
      "Biperidine",
      "Biperidene Hydrochloride",
      "KL 373",
      "Akineton (TN);Biperidene [INN-French]",
      "Biperideno [INN-Spanish]",
      "Biperidenum [INN-Latin]",
      "Biperiden (JAN/USP/INN)",
      "Biperiden [USAN:BAN:INN:JAN]",
      "Biperiden [USAN:INN:BAN:JAN]",
      "Alpha-5-Norbornen-2-Yl-Alpha-Phenyl-1-Piperidinepropanol",
      "Alpha-Bicyclo[2.2.1]hept-5-En-2-Yl-Alpha-Phenyl-1-Piperidinepropanol",
      "Alpha-Bicyclo(2.2.1)Hept-5-En-2-Yl-Alpha-Phenyl-1-Piperidinepropanol",
      "Alpha-(Bicyclo(2.2.1)Hept-5-En-2-Yl)-Alpha-Phenyl-1-Piperidino Propanol",
      "1-(5-Bicyclo[2.2.1]hept-2-Enyl)-1-Phenyl-3-Piperidin-1-Ylpropan-1-Ol",
      "1-(Bicyclo(2.2.1)Hept-5-En-2alpha-Yl)-1-Phenyl-3-Piperidinopropanol",
      "1-(Bicyclo[2.2.1]hept-5-En-2-Yl)-1-Phenyl-3-(Piperidin-1-Yl)Propan-1-Ol",
      "1-Bicycloheptenyl-1-Phenyl-3-Piperidino-Propanol-1",
      "1-Piperidinepropanol, .alpha.-Bicyclo[2.2.1]hept-5-En-2-Yl-.alpha.-Phenyl-, Hydrochloride",
      "3-Piperidino-1-Phenyl-1-Bicyclo(2.2.1)Hepten-(5)-Yl-Propanol-(1)",
      "3-Piperidino-1-Phenyl-1-Bicyclo(2.2.1)Hepten-(5)-Yl-Propanol-(1) [German]",
      "3-Piperidino-1-Phenyl-1-Bicycloheptenyl-1-Propanol",
    ],
  },
  {
    primary_id: "D0B4TT",
    names: ["GSK1004723", "CHEMBL1767168", "SCHEMBL2819051"],
  },
  {
    primary_id: "D0B4TX",
    names: ["PMID28394193-Compound-47"],
  },
  {
    primary_id: "D0B4VE",
    names: ["Flavanone"],
  },
  {
    primary_id: "D0B4VH",
    names: ["CDR-134F194"],
  },
  {
    primary_id: "D0B4VO",
    names: ["PMID25468267-Compound-48"],
  },
  {
    primary_id: "D0B4VV",
    names: ["Trans-Hydroxytamoxifen"],
  },
  {
    primary_id: "D0B4WH",
    names: ["Islet Neogenesis Therapy (I.N.T.)"],
  },
  {
    primary_id: "D0B4WR",
    names: ["2-Isoxazol-5-Yl-3H-Imidazo[4,5-C]quinoline"],
  },
  {
    primary_id: "D0B4WW",
    names: ["C[Glu18-Lys22][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0B4WZ",
    names: ["3-Benzyl-3-Ethyl-1-Phenylazetidine-2,4-Dione", "CHEMBL578583"],
  },
  {
    primary_id: "D0B4XU",
    names: ["TGP-580", "CS-23", "HbFGF, Takeda"],
  },
  {
    primary_id: "D0B4XX",
    names: ["QR-333"],
  },
  {
    primary_id: "D0B4ZQ",
    names: ["SL-90.0571"],
  },
  {
    primary_id: "D0B4ZT",
    names: ["WS-23"],
  },
  {
    primary_id: "D0B5AG",
    names: ["Cis-3-Phenoxy-2,3-Dihydro-1H-Inden-1-Amine", "CHEMBL530334"],
  },
  {
    primary_id: "D0B5AZ",
    names: ["CytotoxinPeptide Conjugate"],
  },
  {
    primary_id: "D0B5BG",
    names: [
      "6-Benzyloxy-9H-Purin-2-Ylamine",
      "6-O-Benzylguanine",
      "19916-73-5",
      "6-(Benzyloxy)-7H-Purin-2-Amine",
      "2-Amino-6-Benzyloxypurine",
      "6-(Benzyloxy)-9H-Purin-2-Amine",
      "2-Amino-6-(Benzyloxy)Purine",
      "O(6)-Bgua",
      "6-(Benzyloxy)Guanine",
      "6-Benzyloxyguanine",
      "6-Benzyloxy Guanine",
      "6-(Phenylmethoxy)-1H-Purin-2-Amine",
      "2-Amino-6-(Phenylmethoxy)-9H-Purine",
      "O6-BG",
      "UNII-01KC87F8FE",
      "1H-Purin-2-Amine, 6-(Phenylmethoxy)-",
      "6-BENZYLGUANINE",
      "Purine, 2-Amino-6-(Benzyloxy)-",
      "NSC 637037",
      "CHEMBL407874",
      "01KC87F8FE",
      "6-Benzyloxy-7H-Purin-2-Amine",
    ],
  },
  {
    primary_id: "D0B5BT",
    names: ["Azetidinyl-Piperazine Derivative 1"],
  },
  {
    primary_id: "D0B5CN",
    names: ["Rh-MBL"],
  },
  {
    primary_id: "D0B5CR",
    names: ["1-(3-(4-Chlorobenzyl)Quinoxalin-2-Yl)Hydrazine", "CHEMBL381487"],
  },
  {
    primary_id: "D0B5DX",
    names: ["Aminopyrimidine Derivative 3"],
  },
  {
    primary_id: "D0B5EB",
    names: ["Ponezumab"],
  },
  {
    primary_id: "D0B5FA",
    names: ["Benzimidazole And Imadazopyridine Carboximidamide Compound 2"],
  },
  {
    primary_id: "D0B5FC",
    names: ["T0901317"],
  },
  {
    primary_id: "D0B5FZ",
    names: [
      "Cefozopran",
      "CZOP",
      "Cefozopran [INN]",
      "SCE 2787",
      "Cefozopran (INN)",
      "(-)-1-(((6R,7R)-7-(2-(5-Amino-1,2,4-Thiadiazol-3-Yl)Glyoxylamido)-2-Carboxy-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-3-Yl)Methyl)-1H-Imidazo(1,2-B)Pyridazin-4-Ium Hydroxide Inner Salt, 7(Sup 2)-(Z)-(O-Methyloxime)",
      "Imidazo(1,2-B)Pyridazinium, 1-((6R,7R)-7-(((2Z)-(5-Amino-1,2,4-Thiadiazol-3-Yl)(Methoxyimino)Acetyl)Amino)-2-Carboxy-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-3-Yl)Methyl)-, Inner Salt",
    ],
  },
  {
    primary_id: "D0B5GC",
    names: ["Flavonols"],
  },
  {
    primary_id: "D0B5GS",
    names: [
      "Vancomycin Hydrochloride",
      "AeroVanc",
      "Vancomycin HCL",
      "VANCOMYCIN HYDROCHLORIDE",
      "Vancomycin Monohydrochloride",
      "1404-93-9",
      "UNII-71WO621TJD",
      "VANCOR",
      "Vancocine Hydrochloride",
      "71WO621TJD",
      "Vancocin Hydrochloride",
      "Vancomycin, Hydrochloride",
      "Vancomycin Hydrochloride From Streptomyces Orientalis",
      "1404-90-6 (Parent)",
      "Vancomycin, Monohydrochloride",
      "SR-01000076194",
      "Prestwick_816",
      "Vancomycin Hydrochloride [USP:JAN]",
      "Cancocin Hydrochloride",
      "Vancocin Hydrochloride In Plastic Container",
      "Vancomycin HCl (Vancocin)",
      "CHEMBL3747636",
      "CHEMBL1200628",
      "LCTORFDMHNKUSG-XTTLPDOESA-N",
      "M",
    ],
  },
  {
    primary_id: "D0B5HA",
    names: ["ISIS-5320"],
  },
  {
    primary_id: "D0B5HD",
    names: ["Fipronil"],
  },
  {
    primary_id: "D0B5HF",
    names: ["BI-135585"],
  },
  {
    primary_id: "D0B5HS",
    names: [
      "NLG8189",
      "1-Methyl-D-Tryptophan",
      "Indoximod",
      "110117-83-4",
      "D-Tryptophan, 1-Methyl-",
      "D-1MT",
      "Indoximod (NLG-8189)",
      "D-1-Methyltryptophan",
      "UNII-TX5CYN1KMZ",
      "D-(+)-1-Methyltryptophan",
      "TX5CYN1KMZ",
      "(R)-2-Amino-3-(1-Methyl-1H-Indol-3-Yl)Propanoic Acid",
      "(2R)-2-Amino-3-(1-Methylindol-3-Yl)Propanoic Acid",
      "NSC-721782",
      "(2R)-2-Amino-3-(1-Methyl-3-Indolyl)Propanoic Acid",
      "1-MT",
      "(2R)-2-Azanyl-3-(1-Methylindol-3-Yl)Propanoic Acid",
      "(2R)-2-Amino-3-(1-Methyl-1H-Indol-3-Yl)Propanoic Acid",
      "D-L-Methyltryptophan",
      "Indoximod [USAN:INN]",
      "NLG-8189",
      "NLG 8189",
    ],
  },
  {
    primary_id: "D0B5IL",
    names: ["WRR-99"],
  },
  {
    primary_id: "D0B5JH",
    names: ["ACU-4429"],
  },
  {
    primary_id: "D0B5JV",
    names: ["Labeled LH Superagonist"],
  },
  {
    primary_id: "D0B5KD",
    names: [
      "Aminopurvalanol",
      "(2S)-2-({6-[(3-AMINO-5-CHLOROPHENYL)AMINO]-9-ISOPROPYL-9H-PURIN-2-YL}AMINO)-3-METHYLBUTAN-1-OL",
      "AP9",
      "2f2c",
      "AC1OCAB3",
      "DB07379",
      "(2S)-2-[[6-(3-Amino-5-Chloroanilino)-9-Propan-2-Ylpurin-2-Yl]amino]-3-Methylbutan-1-Ol",
      "(2S)-2-({6-[(3-Amino-5-Chlorophenyl)Amino]-9-Isopropylpurin-2-Yl}amino)-3-Methylbutan-1-Ol",
      "(2S)-2-({6-[(3-Amino-5-Chlorophenyl)Amino]-9-Isopropyl-9H-Purin-2-Yl}amino)-3-Methyl-1-Butanol",
    ],
  },
  {
    primary_id: "D0B5KE",
    names: [
      "EB-1053",
      "EB 1053",
      "125946-92-1",
      "Disodium 1-Hydroxy-3-(1-Pyrrolidinyl)Propylidene-1,1-Bisphosphonate",
      "AC1L2Y2V",
      "SCHEMBL3067217",
      "DTXSID20154998",
      "Phosphonic Acid, (1-Hydroxy-3-(1-Pyrrolidinyl)Propylidene)Bis-, Disodium Salt",
      "Disodium (1-Hydroxy-1-Phosphonato-3-Pyrrolidin-1-Ylpropyl)Phosphonic Acid",
    ],
  },
  {
    primary_id: "D0B5LF",
    names: [
      "Ximelegatran",
      "Cyclohexanecarboxamide, 4-[(1R)-1-Aminoethyl]-N-4-Pyridinyl-, Trans-",
      "Y-27632",
      "146986-50-7",
      "Y-27632 Dihydrochloride",
      "Y27632",
      "UNII-0X370ROP6H",
      "Y 27632",
      "0X370ROP6H",
      "CHEBI:75393",
      "4-[(1R)-1-Aminoethyl]-N-(Pyridin-4-Yl)Cyclohexane-1-Carboxamide",
      "(R)-TRANS-4-(1-AMINOETHYL)-N-(4-PYRIDYL) CYCLOHEXANECARBOXAMIDE",
      "4-[(1R)-1-Aminoethyl]-N-(4-Pyridyl)Cyclohexanecarboxamide",
      "4-[(1R)-1-Aminoethyl]-N-Pyridin-4-Ylcyclohexane-1-Carboxamide",
      "(R)-(+)-Trans-N-(4-Pyridyl)-4-(1-Aminoethyl)-Cyclohexanecarboxamide",
    ],
  },
  {
    primary_id: "D0B5LQ",
    names: ["ISIS 298702"],
  },
  {
    primary_id: "D0B5MF",
    names: [
      "2-Aminoethanimidic Acid",
      "2-AMINOETHANIMIDIC ACID",
      "2-Iminoethanamine",
      "2-Aminoethylimine",
      "2-Aminoethanimine",
      "AC1NBQ0B",
      "(2E)-2-Iminoethanamine",
      "AKOS006363845",
    ],
  },
  {
    primary_id: "D0B5MP",
    names: [
      "Aciclovir",
      "Acyclovir",
      "Acycloguanosine",
      "59277-89-3",
      "Zovirax",
      "Vipral",
      "Virorax",
      "Wellcome-248U",
      "9-[(2-Hydroxyethoxy)Methyl]guanine",
      "Aciclovirum",
      "Zovir",
      "Aciclovirum [Latin]",
      "Sitavig",
      "Aciclovier",
      "Zyclir",
      "9-HYROXYETHOXYMETHYLGUANINE",
      "Aciclovirum [INN-Latin]",
      "2-Amino-9-((2-Hydroxyethoxy)Methyl)-1H-Purin-6(9H)-One",
      "W-248-U",
      "9-(2-Hydroxyethoxy)Methylguanine",
      "Acyclovir-Side Chain-2-3H",
      "ACYCLOVIR SODIUM",
      "9-((2-Hydroxyethoxy)Methyl)Guanine",
      "Acicloftal",
      "Cargosil",
      "BW-248U",
      "UNII-X4HES1O11F",
      "DRG-0008",
      "NSC 645011",
      "CHEBI:2453",
      "CCRIS 1953",
      "Iontophoretic Acyclovir",
    ],
  },
  {
    primary_id: "D0B5MU",
    names: ["SB-207710"],
  },
  {
    primary_id: "D0B5MZ",
    names: ["3-(4-Amino-Phenyl)-3-Butyl-Piperidine-2,6-Dione", "CHEMBL29863"],
  },
  {
    primary_id: "D0B5NJ",
    names: ["PMID29338548-Compound-46"],
  },
  {
    primary_id: "D0B5OK",
    names: [
      "6-Phenylethynyl-Nicotinic Acid Methyl Ester",
      "CHEMBL400735",
      "BDBM50231751",
      "6-(Phenylethynyl)Pyridine-3-Carboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0B5OL",
    names: ["ISIS 156474"],
  },
  {
    primary_id: "D0B5PK",
    names: ["TEN010"],
  },
  {
    primary_id: "D0B5PU",
    names: [
      "BMS-193884",
      "UNII-7ON53PV45J",
      "CHEMBL24461",
      "7ON53PV45J",
      "176960-47-7",
      "SCHEMBL4204130",
      "BDBM50091105",
      "(1,1'-Biphenyl)-2-Sulfonamide, N-(3,4-Dimethyl-5-Isoxazolyl)-4'-(2-Oxazolyl)-",
      "LS-44539",
      "L001532",
      "4''-Oxazol-2-Yl-Biphenyl-2-Sulfonicacid(3,4-Dimethyl-Isoxazol-5-Yl)-Amide",
      "4''-Oxazol-2-Yl-Biphenyl-2-Sulfonic Acid (3,4-Dimethyl-Isoxazol-5-Yl)-Amide",
      "4''''-Oxazol-2-Yl-Biphenyl-2-Sulfonic Acid (3,4-Dimethyl-Isoxazol-5-Yl)-Amide",
    ],
  },
  {
    primary_id: "D0B5PV",
    names: ["CART-EGFRvIII T Cells"],
  },
  {
    primary_id: "D0B5QK",
    names: ["Activated Adult Marrow Stromal Cells", "Activated Adult Marrow Stromal Cells (Immune Disease)"],
  },
  {
    primary_id: "D0B5QU",
    names: ["2-Pyrazinone Derivative 5"],
  },
  {
    primary_id: "D0B5QZ",
    names: ["PMID27841045-Compound-132"],
  },
  {
    primary_id: "D0B5RO",
    names: [
      "REN-1654",
      "Antineurodegeneratives, Centaur",
      "CPI-1160",
      "CPI-1189",
      "Parkinsons Therapy, Centaur",
      "REN-1189",
    ],
  },
  {
    primary_id: "D0B5RU",
    names: ["CS1-CAR T Therapy"],
  },
  {
    primary_id: "D0B5RX",
    names: ["(E)-1-Adamantan-1-Yl-3-Quinolin-3-Yl-Propenone", "CHEMBL554700", "SCHEMBL5511163"],
  },
  {
    primary_id: "D0B5RZ",
    names: ["GS-6620"],
  },
  {
    primary_id: "D0B5SC",
    names: ["Silvestrol"],
  },
  {
    primary_id: "D0B5SF",
    names: ["4-(2-(3,4-Dimethylphenylamino)Ethyl)-1H-Imidazole", "CHEMBL1097658"],
  },
  {
    primary_id: "D0B5ST",
    names: ["2-(3-Bromophenylthio)-N,N-Dimethylethanamine", "CHEMBL609323", "144085-61-0", "BDBM50307505"],
  },
  {
    primary_id: "D0B5TM",
    names: ["AMP-110"],
  },
  {
    primary_id: "D0B5TO",
    names: ["Onyvax-CR"],
  },
  {
    primary_id: "D0B5UH",
    names: ["ALX-0761"],
  },
  {
    primary_id: "D0B5VA",
    names: ["SAN-300"],
  },
  {
    primary_id: "D0B5VN",
    names: ["ICI 200,355"],
  },
  {
    primary_id: "D0B5VV",
    names: ["IDegLira"],
  },
  {
    primary_id: "D0B5WF",
    names: ["Pyridine-2,4-Dicarboxylic Acid Derivative 2"],
  },
  {
    primary_id: "D0B5YS",
    names: ["T83193"],
  },
  {
    primary_id: "D0B5ZA",
    names: ["Homoquinolinic Acid"],
  },
  {
    primary_id: "D0B6BE",
    names: ["OT-417"],
  },
  {
    primary_id: "D0B6BQ",
    names: ["ZD-6003"],
  },
  {
    primary_id: "D0B6BW",
    names: ["ZIO-102"],
  },
  {
    primary_id: "D0B6CA",
    names: [
      "4-(2-(Pyrrolidin-1-Yl)Ethyl)Pyridine",
      "67580-65-8",
      "4-[2-(Pyrrolidin-1-Yl)Ethyl]pyridine",
      "4-(2-Tetrahydro-1H-Pyrrol-1-Ylethyl)Pyridine",
      "4-(2-Pyrrolidin-1-Ylethyl)Pyridine",
      "MLS000737070",
      "CHEMBL405070",
      "4-(2-Pyrrolidin-1-Yl-Ethyl)-Pyridine",
      "NSC42776",
      "AC1Q4WU8",
      "AC1Q28PY",
      "AC1L60Z5",
      "SCHEMBL3993201",
      "4-(2-Pyrrolidinylethyl)Pyridine",
      "CTK2F7988",
      "DTXSID30285782",
      "MolPort-000-145-638",
      "ZINC163336",
      "HMS2752A23",
      "SBB089201",
      "BDBM50372345",
      "STL324986",
      "NSC-42776",
      "AKOS006227946",
      "VP14627",
      "MCULE-2911571696",
      "4-(2-PYRROLIDINOETHYL)PYRIDINE",
      "SMR000038089",
    ],
  },
  {
    primary_id: "D0B6CC",
    names: ["Trimethaphan"],
  },
  {
    primary_id: "D0B6DN",
    names: ["Ethinyl-Pyrazole Derivative 3"],
  },
  {
    primary_id: "D0B6DV",
    names: ["CT-10"],
  },
  {
    primary_id: "D0B6EA",
    names: ["NV-1020"],
  },
  {
    primary_id: "D0B6EG",
    names: ["Simoctocog Alfa"],
  },
  {
    primary_id: "D0B6ER",
    names: ["Ro-31-6930"],
  },
  {
    primary_id: "D0B6EU",
    names: [
      "AS-35",
      "AS 35",
      "108427-72-1",
      "9-((4-Acetyl-3-Hydroxy-2-Propylphenoxy)Methyl)-3-(1H-Tetrazol-5-Yl)-4H-Pyrido(1,2-A)Pyrimidin-4-One",
      "C21H20N6O4",
      "4H-Pyrido[1,2-A]pyrimidin-4-One,9-[(4-Acetyl-3-Hydroxy-2-Propylphenoxy)Methyl]-3-(2H-Tetrazol-5-Yl)-",
      "4H-Pyrido(1,2-A)Pyrimidin-4-One, 9-((4-Acetyl-3-Hydroxy-2-Propylphenoxy)Methyl)-3-(1H-Tetrazol-5-Yl)-",
      "RVRGDCZGEKSIRW-UHFFFAOYSA-N",
      "ACMC-20mbio",
      "As35",
      "AC1L2WU8",
      "SCHEMBL159227",
      "CTK4A6033",
      "DTXSID90148546",
      "CS-7624",
      "LS-134160",
      "HY-101946",
      "L003846",
    ],
  },
  {
    primary_id: "D0B6FM",
    names: ["SKF 89748"],
  },
  {
    primary_id: "D0B6GA",
    names: ["PMID25726713-Compound-37"],
  },
  {
    primary_id: "D0B6GJ",
    names: ["TT-62"],
  },
  {
    primary_id: "D0B6GK",
    names: ["Cyclopropylamine Derivative 1"],
  },
  {
    primary_id: "D0B6GW",
    names: ["Diphtheria-Tetanus Vaccine", "Diphtheria-Tetanus Vaccine (Im Suspension), Finlay Institute"],
  },
  {
    primary_id: "D0B6HE",
    names: ["SKI-2852"],
  },
  {
    primary_id: "D0B6HS",
    names: ["HRT"],
  },
  {
    primary_id: "D0B6IQ",
    names: ["N,N-Dimethyl-1'H-Phenothiazine-1'-Carboxamide"],
  },
  {
    primary_id: "D0B6IZ",
    names: ["R1678"],
  },
  {
    primary_id: "D0B6JV",
    names: ["SB-201993"],
  },
  {
    primary_id: "D0B6KC",
    names: ["PMID25980951-Compound-27"],
  },
  {
    primary_id: "D0B6KT",
    names: ["PF-06454589", "Compound 28 [PMID: 25353650]"],
  },
  {
    primary_id: "D0B6LL",
    names: ["[4Aph(CO-NH-OCH3)5]degarelix", "CHEMBL407661"],
  },
  {
    primary_id: "D0B6LP",
    names: ["Neo-Urinary Conduit"],
  },
  {
    primary_id: "D0B6LT",
    names: ["OPNT002"],
  },
  {
    primary_id: "D0B6MQ",
    names: ["PolyIC"],
  },
  {
    primary_id: "D0B6MV",
    names: [
      "8-(3-Methoxybenzyloxy)Caffeine",
      "CHEMBL602880",
      "AC1LCY7O",
      "Oprea1_481626",
      "MLS000038017",
      "MolPort-002-545-851",
      "ZINC497710",
      "HMS2326N19",
      "BDBM50306702",
      "AKOS030507253",
      "MCULE-4574508539",
      "NCGC00020867-02",
      "NCGC00020867-01",
      "SMR000039153",
      "8-[(3-Methoxyphenyl)Methoxy]-1,3,7-Trimethylpurine-2,6-Dione",
      "8-((3-Methoxybenzyl)Oxy)-1,3,7-Trimethyl-1H-Purine-2,6(3H,7H)-Dione",
    ],
  },
  {
    primary_id: "D0B6NP",
    names: ["IHV001"],
  },
  {
    primary_id: "D0B6NT",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 11"],
  },
  {
    primary_id: "D0B6OG",
    names: ["BBT-007"],
  },
  {
    primary_id: "D0B6OH",
    names: ["COMBETASTATIN"],
  },
  {
    primary_id: "D0B6PX",
    names: ["N,N-Dimethylaminoethyl 18-Methoxycoronaridinate", "CHEMBL607047"],
  },
  {
    primary_id: "D0B6PZ",
    names: ["FORMESTANE"],
  },
  {
    primary_id: "D0B6QO",
    names: ["NPI-1999"],
  },
  {
    primary_id: "D0B6QP",
    names: [
      "Apomorphine SL",
      "Apomorphine HCl",
      "Apomorphine Chloride",
      "(R)-(-)-Apomorphine Hydrochloride",
      "Apomorphinium Chloride",
      "Apomorphin Hydrochlorid",
      "314-19-2",
      "Pomorphini Hydrochloridum",
      "Apomorphinum Hydrochloricum",
      "N-Methylnorapomorphine Hydrochloride",
      "UNII-9K13MD7A0D",
      "(-)-Apomorphinium Hydrochloride",
      "Apomorphine Hydrochloride Anhydrous",
      "EINECS 206-243-0",
      "NSC 11442",
      "9K13MD7A0D",
      "6a-Beta-Aporphine-10,11-Diol, Hydrochloride",
      "6abeta-Noraporphine-10,11-Diol, 6-Methyl-, Hyd",
    ],
  },
  {
    primary_id: "D0B6QZ",
    names: ["AVE-2268"],
  },
  {
    primary_id: "D0B6RA",
    names: ["LY404039"],
  },
  {
    primary_id: "D0B6SA",
    names: ["N-Isopropyl Estradiol-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0B6SF",
    names: ["3,3'-(3-Phenylthiene-2,5-Diyl)Diphenol", "CHEMBL569885", "BDBM50299649"],
  },
  {
    primary_id: "D0B6SO",
    names: [
      "BRL-44154",
      "6beta-[2-(2-Aminothiazol-4-Yl)-2(Z)-(Cyclopentyloxyimino)Acetamido]penicillanic Acid Sodium Salt",
    ],
  },
  {
    primary_id: "D0B6TW",
    names: ["Leucovorin Calcium"],
  },
  {
    primary_id: "D0B6UU",
    names: ["8R-Hydroxylobel-9-Ene", "CHEMBL425617", "SCHEMBL13845050"],
  },
  {
    primary_id: "D0B6VF",
    names: ["GR-91272", "132537-23-6"],
  },
  {
    primary_id: "D0B6VI",
    names: [
      "5-(Azetidin-1-Ylsulfonyl)Indoline-2,3-Dione",
      "5-[1-(Azetidinyl)Sulfonyl]isatin",
      "Isatin Sulfonamide 19",
      "1H-Indole-2,3-Dione, 5-(1-Azetidinylsulfonyl)-",
      "CHEMBL64789",
      "SCHEMBL6535690",
      "BDBM10308",
      "220510-08-7",
      "5-(Azetidine-1-Ylsulfonyl)Indoline-2,3-Dione",
    ],
  },
  {
    primary_id: "D0B6VN",
    names: ["TZI-41078", "3,5-Di-Tert-Butyl-4-Hydroxybenzophenone Oxime"],
  },
  {
    primary_id: "D0B6VQ",
    names: ["D-20450"],
  },
  {
    primary_id: "D0B6WH",
    names: ["[3H]eletriptan", "GTPL118"],
  },
  {
    primary_id: "D0B6WN",
    names: ["AVE0675"],
  },
  {
    primary_id: "D0B6WP",
    names: ["Improved Influenza Vaccine"],
  },
  {
    primary_id: "D0B6WV",
    names: ["TNFmab"],
  },
  {
    primary_id: "D0B6WX",
    names: ["GDP-Fucose"],
  },
  {
    primary_id: "D0B6XE",
    names: ["ASP9226"],
  },
  {
    primary_id: "D0B6YB",
    names: [
      "LE-SN38",
      "7-Ethyl-10-Hydroxycamptothecin",
      "86639-52-3",
      "SN-38",
      "SN 38 Lactone",
      "7-Ethyl-10-Hydroxy-Camptothecin",
      "SN 38",
      "10-Hydroxy-7-Ethylcamptothecin",
      "7-Ethyl-10-Hydroxycamptothecine",
      "SN38",
      "7-Ethyl-10-Hydroxy-20(S)-Camptothecin",
      "UNII-0H43101T0J",
      "Captothecin, 7-Ethyl-10-Hydroxy-",
      "CHEBI:8988",
      "(S)-4,11-Diethyl-4,9-Dihydroxy-1H-Pyrano[3',4':6,7]indolizino[1,2-B]quinoline-3,14(4H,12H)-Dione",
      "7-Ethyl-10-Hydroxy Camptothecin",
      "NSC673596",
      "0H43101T0J",
      "(+)-7-ETHYL-10-HYDROXYCAMPTOTHECIN",
      "113015-38-6",
    ],
  },
  {
    primary_id: "D0B6YK",
    names: ["Combination Therapy, Prostate Cancer, Molecular Express Inc", "Combination Therapy (Prostate Cancer)"],
  },
  {
    primary_id: "D0B6YR",
    names: ["HuMax-CD32b", "CD32b-Targeting Human IgG1-K Antibody (Cancer), Genmab A/S"],
  },
  {
    primary_id: "D0B6YY",
    names: ["Nmers"],
  },
  {
    primary_id: "D0B6ZI",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0B7AB",
    names: ["R-TPR-013"],
  },
  {
    primary_id: "D0B7AF",
    names: ["KLS-Bfr"],
  },
  {
    primary_id: "D0B7AH",
    names: ["INT-777"],
  },
  {
    primary_id: "D0B7BX",
    names: [
      "E-6276",
      "1-(2-Benzhydrylideneaminooxy-Ethyl)-1,2,5,6-Tetrahydro-Pyridine-3-Carboxylic Acid",
      "UNII-BGU9MZ2G30",
      "BGU9MZ2G30",
      "CHEMBL473104",
      "159094-94-7",
      "Tocris-1779",
      "Lopac-N-142",
      "Biomol-NT_000271",
      "AC1L1IC7",
      "Lopac0_000880",
      "SCHEMBL5969062",
      "GTPL4669",
      "BPBio1_000948",
      "CTK0H8642",
      "CHEBI:92744",
      "DTXSID30166582",
      "ZINC1534967",
      "BDBM50426075",
      "BDBM50080344",
      "AKOS030543350",
      "CCG-204962",
      "3-Pyridinecarboxylic Acid, 1-(2-(((Diphenylmethylene)Amino)Oxy)Ethyl)-1,2,5,6-Tetrahydro-",
      "NCGC00015712-05",
      "NCGC00015712-02",
      "NCGC00025293-03",
      "NCGC00025293-02",
      "NCGC00015712-04",
      "NCGC00025293-01",
      "NCGC00015712-03",
      "NCGC00015712-01",
      "LS-187291",
    ],
  },
  {
    primary_id: "D0B7CH",
    names: [
      "Phenylbutyrate",
      "Benzenebutyric Acid",
      "Phenyl Butanoate",
      "Phenyl Butyrate",
      "HDInhib_000004",
      "Butanoic Acid, Phenyl Ester",
      "Butyric Acid, Phenyl Ester",
      "FR-2080",
      "Gamma-Phenylbutyric Acid",
      "Omega-Phenylbutanoic Acid",
      "GAMMA-PHENYL-BUTYRIC ACID",
      "Butyric Acid, 4-Phenyl-(8CI)",
      "1-Phenylbutyric Acid",
      "4-PHENYL-BUTANOIC ACID",
      "4-PHENYLBUTYRIC ACID",
      "4-Phenylbutanoic Acid",
      "4-Phenylbutans",
      "4-Phenylbutyrate",
    ],
  },
  {
    primary_id: "D0B7CW",
    names: ["PMID26413912-Compound-84"],
  },
  {
    primary_id: "D0B7CZ",
    names: ["Teloxantrone"],
  },
  {
    primary_id: "D0B7DW",
    names: ["Hyqvia + Kiovia"],
  },
  {
    primary_id: "D0B7EB",
    names: ["Etoposide"],
  },
  {
    primary_id: "D0B7EF",
    names: ["MNT-03-01"],
  },
  {
    primary_id: "D0B7EP",
    names: ["1beta-Methoxy-Miller-9Z-Enolide", "CHEMBL425948"],
  },
  {
    primary_id: "D0B7FH",
    names: ["PMID28074661-Compound-US20120264760C88"],
  },
  {
    primary_id: "D0B7GE",
    names: ["OB-28"],
  },
  {
    primary_id: "D0B7GQ",
    names: ["2,2-Bipyridyl Derivative 5"],
  },
  {
    primary_id: "D0B7GU",
    names: [
      "Dihydromorphine",
      "Paramorphan",
      "7,8-DIHYDROMORPHINE",
      "6-Alpha-Hydromorphol",
      "509-60-4",
      "Dihydromorfin [Czech]",
      "UNII-C3S5FRP6JW",
      "Hydromorphine",
      "Paramorfan",
      "Morphine, Dihydro-",
      "EINECS 208-100-8",
      "C3S5FRP6JW",
      "NSC 117865",
      "Dihydromorfin",
      "CHEBI:4575",
      "DEA No. 9145",
      "Morphinan-3,6alpha-Diol, 4,5alpha-Epoxy-17-Methyl-",
      "Morphinan-3,6-Alpha-Diol, 4,5-Alpha-Epoxy-17-Methyl-",
      "(4R,4aR,7S,7aR,12bS)-3-Methyl-2,4,4a,5,6,7,7a,13-Octahydro-1H-4,12-Methanobenzofuro[3,2-E]isoquinoline-7,9-Diol",
      "7,8-Dihydromorphin",
      "1421-28-9",
      "Dihydromorphin",
    ],
  },
  {
    primary_id: "D0B7HH",
    names: ["HTERT-VISA-BikDD Nanoparticles"],
  },
  {
    primary_id: "D0B7HJ",
    names: [
      "U-54494A",
      "C18H24Cl2N2O",
      "U 54494A",
      "U 54494",
      "U-54494",
      "Cis-3,4-Dichloro-N-Methyl-N-(2-(1-Pyrrolidinyl)Cyclohexyl)Benzamide",
      "Benzamide, 3,4-Dichloro-N-Methyl-N-(2-(1-Pyrrolidinyl)Cyclohexyl)-, Trans-",
      "AC1L3TTM",
      "92953-41-8",
      "AC1Q3O5Y",
      "SCHEMBL11610023",
      "3,4-Dichloro-N-Methyl-N-[(1r,2r)-2-(Pyrrolidin-1-Yl)Cyclohexyl]benzamide",
      "LS-178194",
      "A845921",
      "UNII-I3NE36R0XJ Component JUBNVWGVNWIXMB-IAGOWNOFSA-N",
      "Trans-3,4-Dichloro-N-Methyl-N-[2-(1-Pyrrolidinyl)Cyclohexyl]benzamide",
    ],
  },
  {
    primary_id: "D0B7HX",
    names: ["Levosemotiadil", "DS-4824", "SA-3212", "SD-3212"],
  },
  {
    primary_id: "D0B7JJ",
    names: ["Arthritis Therapeutics"],
  },
  {
    primary_id: "D0B7JY",
    names: ["Phenylmethanesulfonamide Derivative 1"],
  },
  {
    primary_id: "D0B7JZ",
    names: ["CuraVac MG"],
  },
  {
    primary_id: "D0B7KF",
    names: ["RG-7152", "5-[3-[3-(2-Quinolinylmethoxy)Phenoxy]propyl]-1H-Tetrazole"],
  },
  {
    primary_id: "D0B7KM",
    names: ["Quinoline Derivative 13"],
  },
  {
    primary_id: "D0B7KP",
    names: ["PMID28447479-Compound-21"],
  },
  {
    primary_id: "D0B7LK",
    names: ["CY-1503", "Cylexin"],
  },
  {
    primary_id: "D0B7LR",
    names: ["Biphenyl Derivative 3"],
  },
  {
    primary_id: "D0B7LU",
    names: ["CX-5461"],
  },
  {
    primary_id: "D0B7LZ",
    names: ["NDD-094"],
  },
  {
    primary_id: "D0B7MO",
    names: ["PF-06886992"],
  },
  {
    primary_id: "D0B7MU",
    names: ["SYNSORB-Cd"],
  },
  {
    primary_id: "D0B7MW",
    names: ["NF023"],
  },
  {
    primary_id: "D0B7ND",
    names: ["UR-8225"],
  },
  {
    primary_id: "D0B7NG",
    names: [
      "Chlorpheniramine",
      "Allergican",
      "Allergisan",
      "Antagonate",
      "Chloropheniramine",
      "Chlorophenylpyridamin",
      "Chlorophenylpyridamine",
      "Chloropiril",
      "Chloroprophenpyridamine",
      "Chlorphenamine",
      "Chlorphenaminum",
      "Chlorpheniraminum",
      "Chlorprophenpyridamine",
      "Clofeniramina",
      "Clorfenamina",
      "Clorfeniramina",
      "Cloropiril",
      "Haynon",
      "Hayon",
      "Histadur",
      "ISOCLOR",
      "Kloromin",
      "Phenetron",
      "PiriIton",
      "Piriton",
      "Polaronil",
      "Telachlor",
      "Teldrin",
      "Chlorphenamine [INN]",
      "Clorfeniramina [Italian]",
      "Pediacare Allergy Formula",
      "[3H]Chlorpheniramine",
      "Aller-Chlor",
      "Chlo-Amine",
      "Chlor-Pro",
      "Chlor-Trimeton Repetabs",
      "Chlor-Trimeton",
      "Chlorphenamine (INN)",
      "Chlorphenaminum [INN-Latin]",
      "Clofeniramina (TN)",
      "Clorfenamina [INN-Spanish]",
      "Comakin (TN)",
      "Gen-Allerate",
      "Novo-Pheniram",
      "Piriton (TN)",
      "Chlor-Trimeton (TN)",
      "Chlor-Tripolon (TN)",
      "CHLORPHENIRAMINE (SEE ALSO: CHLORPHENIRAMINE MALEATE (CAS113-92-8))",
      "Gamma-(4-Chlorophenyl)-Gamma-(2-Pyridyl)Propyldimethylamine",
      "Gamma-(4-Chlorophenyl)-N,N-Dimethyl-2-Pyridinepropanamine",
      "1-(P-Chlorophenyl)-1-(2-Pyridyl)-3-N,N-Dimethylpropylamine",
      "1-(P-Chlorophenyl)-1-(2-Pyridyl)-3-Dimethylaminopropane",
      "2-(P-Chloro-Alpha-(2-(Dimethylamino)Ethyl)Benzyl)Pyridine",
      "3-(4-Chlorophenyl)-N,N-Dimethyl-3-(Pyridin-2-Yl)Propan-1-Amine",
      "3-(4-Chlorophenyl)-N,N-Dimethyl-3-Pyridin-2-Ylpropan-1-Amine",
      "3-(P-Chlorophenyl)-3-(2-Pyridyl)-N,N-Dimethylpropylamine",
      "4-Chloropheniramine",
    ],
  },
  {
    primary_id: "D0B7NS",
    names: ["Pyrimidine Derivative 23"],
  },
  {
    primary_id: "D0B7OD",
    names: [
      "Amfepramone",
      "Diethylpropion",
      "DIETHYLPROPION",
      "Diethylpropione",
      "Amfepramon",
      "Tepanil",
      "Anorex",
      "Nopropiophenone",
      "Frekentine",
      "Amphepramon",
      "Amfepramonum",
      "Amphepramone",
      "Adiposon",
      "Tylinal",
      "Magrene",
      "Dobesin",
      "Neobes",
      "Danylen",
      "Alpha-Benzoyltriethylamine",
      "Anfepramona",
      "Cegramine",
      "Obesitex",
      "Silutin",
      "Regenon",
      "Prefamone",
      "Derfon",
      "Alpha-Diethylaminopropiophenone",
      "90-84-6",
      "Diethylcathinone",
      "Amfepramonum [INN-Latin]",
      "Anfepramona [INN-Spanish]",
      "Amfepramone [INN]",
      "Parabolin",
      "Modulor",
      "Keramik",
      "Keramin",
      "Anfamon",
      "2-(Diethylamino)Propiophenone",
      "1-Phenyl-2-Diethylamino-1-Propanone",
      "Danylen;Derfon",
      "Phepranon",
      "Reginon",
      "Amfepramone HCL",
      "Amfepramone Hydrochloride",
      "Amphepramonum Hydrochloride",
      "Diethylpropion HCL",
      "Diethylpropione Hydrochloride",
      "Tenuate Hydrochloride",
      "UR 1423",
      "Amfepramone (INN)",
      "Diethylpropion (BAN)",
      "Fenyl-(1-Diethylaminoethyl)Keton",
      "Fenyl-(1-Diethylaminoethyl)Keton [Czech]",
      "(+-)-Diethylpropion",
      "2-(Diethylamino)-1-Phenyl-1-Propanone",
      "2-(Diethylamino)-1-Phenylpropan-1-One",
      "2-Diethylaminopropiophenone",
    ],
  },
  {
    primary_id: "D0B7OM",
    names: ["F-15441", "Antipsychotic (Schizophrenia), Pierre Fabre"],
  },
  {
    primary_id: "D0B7OW",
    names: ["Sakuranetin"],
  },
  {
    primary_id: "D0B7OY",
    names: ["CT 327"],
  },
  {
    primary_id: "D0B7PY",
    names: ["Prostate Cancer Vaccine"],
  },
  {
    primary_id: "D0B7QP",
    names: ["TM30339"],
  },
  {
    primary_id: "D0B7QU",
    names: ["AIM-102"],
  },
  {
    primary_id: "D0B7SP",
    names: [
      "GSK163090",
      "844903-58-8",
      "GSK-163090",
      "UNII-7XI927X82B",
      "CHEMBL1631540",
      "7XI927X82B",
      "1-(3-(2-(4-(2-Methyl-5-Quinolinyl)-1-Piperazinyl)Ethyl)Phenyl)-2-Imidazolidinone",
      "1-(3-{2-[4-(2-Methyl-5-Quinolinyl)-1-Piperazinyl]ethyl}phenyl)-2-Imidazolidinone",
      "1-[3-[2-[4-(2-Methyl-5-Quinolinyl)-1-Piperazinyl]ethyl]phenyl]-2-Imidazolidinone",
      "ANGUXJDGJCHGOG-UHFFFAOYSA-N",
      "GSK 163090",
      "SCHEMBL2881345",
      "DTXSID30233480",
      "EX-A1027",
      "BCP16941",
      "ZINC34451922",
      "BDBM50333015",
      "AKOS030526918",
      "CS-5315",
      "HY-14348",
    ],
  },
  {
    primary_id: "D0B7TH",
    names: ["99mTc-Biciromab"],
  },
  {
    primary_id: "D0B7TM",
    names: ["BI-137882"],
  },
  {
    primary_id: "D0B7UD",
    names: ["TP-103", "Bacteriophage Lytic Enzyme Based Formulation (Bacterial Skin Infections) Technophage"],
  },
  {
    primary_id: "D0B7VC",
    names: ["Menactra"],
  },
  {
    primary_id: "D0B7VH",
    names: ["Remoxy ER"],
  },
  {
    primary_id: "D0B7VK",
    names: ["Tifacogin"],
  },
  {
    primary_id: "D0B7WF",
    names: [
      "(4-Hydroxy-Phenoxy)-Acetic Acid",
      "1878-84-8",
      "4-Hydroxyphenoxyacetic Acid",
      "(4-Hydroxyphenoxy)Acetic Acid",
      "2-(4-Hydroxyphenoxy)Acetic Acid",
      "4-Hydroxypheoxyacetate",
      "Acetic Acid, (4-Hydroxyphenoxy)-",
      "Acetic Acid, 2-(4-Hydroxyphenoxy)-",
      "CHEBI:1881",
      "CHEMBL297343",
      "P-HYDROXYPHENOXYACETIC ACID",
      "PKGWLCZTTHWKIZ-UHFFFAOYSA-N",
      "MFCD00014362",
      "(4-Hydroxyphenoxy)Acetic Acid, 98%",
      "Acetic Acid, (P-Hydroxyphenoxy)-",
      "EINECS 217-525-8",
      "4-(Carboxymethoxy)Phenol",
      "AC1L26TI",
      "ACMC-1CC13",
      "(4-Hydroxyphenoxy)AceticAcid",
      "SCHEMBL274850",
      "AC1Q766A",
    ],
  },
  {
    primary_id: "D0B7WO",
    names: ["5-Propenyl-Arabinofuranosyluracil 5'-Triphosphate"],
  },
  {
    primary_id: "D0B7XF",
    names: ["Lymphozene"],
  },
  {
    primary_id: "D0B7YT",
    names: [
      "Scopolamine",
      "Atroscine",
      "Euscopol",
      "Hyosceine",
      "Hysco",
      "Isoscopil",
      "Kwells",
      "SEE",
      "Scop",
      "Scopamin",
      "Scopolaminhydrobromid",
      "Scopos",
      "Sereen",
      "Tranaxine",
      "Transcop",
      "Triptone",
      "Atroscine Hydrobromide",
      "Hydroscine Hydrobromide",
      "Hyocine F Hydrobromide",
      "Hyoscine Bromide",
      "Hyoscyine Hydrobromide",
      "SCOPOLAMINE BROMIDE",
      "Scopolamine Hyoscine",
      "Scopolaminium Bromide",
      "Scopolammonium Bromide",
      "Tropane Alkaloid",
      "MOLI001381",
      "NCI61806",
      "Borrachero (TN)",
      "Burundanga (TN)",
      "Hyoscine (TN)",
      "L-Hyoscine Hydrobromide",
      "Scopoderm-TTS",
      "Scopolamine (INN)",
      "Transderm Scop (TN)",
      "Levo-Duboisine (TN)",
      "Xy-, (-)-Tropate (Ester)",
      "(-)-Scopolamine Hydrobromide Trihydrate",
      "1alphaH,5alphaH-Tropan-3alpha-Ol, 6beta,7beta-Epo",
      "1alphaH,5alphaH-Tropan-3alpha-Ol, 6beta,7beta-Epoxy-, (-)-Tropate (Ester)",
      "3-Oxa-9-Azatricyclo(3.3.1.O(Sup 2,4))Nonan-7-Ol, 9-Methyl-, Tropate (Ester)",
      "9-Methyl-3-Oxa-9-Azatricyclo[3.3.1.0~2,4~]non-7-Yl Tropate",
    ],
  },
  {
    primary_id: "D0B7ZB",
    names: ["4-(4-Isopropylphenyl)-5-Cyano-2H-1,2,3-Triazole", "CHEMBL239895", "SCHEMBL17969081"],
  },
  {
    primary_id: "D0B8AN",
    names: ["JWH-347"],
  },
  {
    primary_id: "D0B8AQ",
    names: [
      "7-Pyrrolidin-1-Yl-Hept-5-Yn-2-One",
      "CHEMBL167419",
      "7-Pyrrolidin-1-Ylhept-5-Yn-2-One",
      "AC1Q5CHA",
      "AC1L2EX8",
      "BDBM50212578",
    ],
  },
  {
    primary_id: "D0B8BC",
    names: [
      "6-Chloro-2-Piperazin-1-Yl-Quinoline",
      "78060-46-5",
      "6-Chloro-2-(Piperazin-1-Yl)Quinoline",
      "6-Chloro-2-Piperazin-1-Ylquinoline",
      "6-Chloro-2-(1-Piperazinyl)Quinoline",
      "CHEMBL290537",
      "AC1Q3JZ5",
      "2-Piperazino-6-Chloroquinoline",
      "SCHEMBL4182196",
      "CTK5E5334",
      "DTXSID60437575",
      "GSFXEINORRSYJW-UHFFFAOYSA-N",
      "ZINC2527450",
      "3506AJ",
      "BDBM50090216",
      "AKOS022401900",
      "AB19847",
      "6-Chloranyl-2-Piperazin-1-Yl-Quinoline",
      "Quinoline,6-Chloro-2-(1-Piperazinyl)-",
      "AJ-83656",
      "AX8029614",
      "KB-248323",
      "DB-075405",
      "FT-0719429",
      "Z-5014",
    ],
  },
  {
    primary_id: "D0B8BY",
    names: ["INdoximod + Temozolomide"],
  },
  {
    primary_id: "D0B8BZ",
    names: ["CD19CART"],
  },
  {
    primary_id: "D0B8CK",
    names: ["PF-05335810"],
  },
  {
    primary_id: "D0B8CU",
    names: [
      "AL7182",
      "3,,4-Dihydro-2-(3-Methoxyphenyl)-2h-Thieno-[3,2-E]-1,2-Thiazine-6-Sulfonamide-1,1-Dioxide",
      "1bnn",
      "AC1L1BTX",
      "SCHEMBL678649",
      "CHEMBL331858",
      "BDBM11935",
      "DB02602",
      "AL-7182",
      "2-(3-Methoxyphenyl)-6-Sulfamoyl-3,4-Dihydro-2H-Thieno[3,2-E]-1,2-Thiazine 1,1-Dioxide",
      "2-(3-Methoxyphenyl)-3,4-Dihydro-2H-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide 1,1-Dioxide",
      "2-(3-Methoxyphenyl)-1,1-Dioxo-3,4-Dihydrothieno[3,2-E]thiazine-6-Sulfonamide",
      "2-(3-Methoxyphenyl)-1,1-Dioxo-3H,4H-1$l^{6},7,2-Thieno[3,2-E][1$l^{6},2]thiazine-6-Sulfonamide",
    ],
  },
  {
    primary_id: "D0B8DE",
    names: ["3-Pyridin-4-Yl-Quinoline-6,7-Diol"],
  },
  {
    primary_id: "D0B8DR",
    names: ["MRS1093", "3,7-Diethoxy-5-Hydroxyflavone", "MRS-1093"],
  },
  {
    primary_id: "D0B8EP",
    names: ["1-[bis(4-Chlorophenyl)Methyl]-3-(4-Chlorophenyl)-", "CHEMBL224113", "ZINC13676240"],
  },
  {
    primary_id: "D0B8ES",
    names: ["AA-123", "BMP Agonist (Prostate Cancer), Thrasos Therapeutics"],
  },
  {
    primary_id: "D0B8FZ",
    names: ["1-(3-Phenyl-Propyl)-3-Thiazol-2-Yl-Thiourea"],
  },
  {
    primary_id: "D0B8GA",
    names: ["Isoxazole-Based Bicyclic Compound 5"],
  },
  {
    primary_id: "D0B8GI",
    names: ["5-Heptyl-5-Phenyl-Imidazolidine-2,4-Dione", "CHEMBL417155", "SCHEMBL13946578"],
  },
  {
    primary_id: "D0B8GN",
    names: ["TM-30089"],
  },
  {
    primary_id: "D0B8GR",
    names: [
      "Ac-His-D-Phe-Arg-2-Nal-NHCH3",
      "CHEMBL211699",
      "BDBM50189018",
      "(S)-2-{(R)-2-[(S)-2-Acetylamino-3-(3H-Imidazol-4-Yl)-Propionylamino]-3-Phenyl-Propionylamino}-5-Guanidino-Pentanoic Acid ((S)-1-Methylcarbamoyl-2-Naphthalen-2-Yl-Ethyl)-Amide",
    ],
  },
  {
    primary_id: "D0B8GY",
    names: ["N-Methyl-5,6-Dip-Tolylpyrazine-2-Carboxamide", "CHEMBL246273"],
  },
  {
    primary_id: "D0B8HD",
    names: ["FVPTDVGPFAF", "CHEMBL264010"],
  },
  {
    primary_id: "D0B8HR",
    names: [
      "DNA Intercalators",
      "DNA Intercalators (Cancer)",
      "DNA Intercalators (Cancer), East China University Of Science & Technology",
      "B-1 (Cancer), East China University Of Science & Technology",
      "7-B (Cancer), East China University Of Science & Technology",
    ],
  },
  {
    primary_id: "D0B8HU",
    names: [
      "2-Allylisoquinoline-1,3,4-Trione",
      "807334-89-0",
      "1,3,4(2H)-Isoquinolinetrione, 2-(2-Propenyl)-",
      "CHEMBL381777",
      "Isoquinoline-1,3,4-Trione 2b",
      "SCHEMBL13442855",
      "CTK2I7309",
      "BDBM10249",
      "DTXSID20469711",
      "ZINC13683126",
      "AKOS015966148",
    ],
  },
  {
    primary_id: "D0B8IN",
    names: ["PSP-D-CD40L"],
  },
  {
    primary_id: "D0B8IQ",
    names: ["PMID28766366-Compound-Scheme2WO2012/098065upper"],
  },
  {
    primary_id: "D0B8IZ",
    names: ["Analog Insulin-PH20"],
  },
  {
    primary_id: "D0B8JD",
    names: ["Pyridoxine-5'-Phosphate"],
  },
  {
    primary_id: "D0B8JG",
    names: ["ATI-0409"],
  },
  {
    primary_id: "D0B8JQ",
    names: ["KRP-101"],
  },
  {
    primary_id: "D0B8KE",
    names: ["MDX-1204"],
  },
  {
    primary_id: "D0B8LN",
    names: ["Iotrolan"],
  },
  {
    primary_id: "D0B8MB",
    names: [
      "[99MTC]-CBPAO",
      "1,1'-Cyclobutylidene-Bis(3-Methyl-2-Aza-4-Pentanone Oxime)Oxotechnetium[99mTc]",
      "3,3'-[(2,2-Trimethylene-1,3-Propanediyl)Diimino]bis[2-Butanone]dioximato](3-)-N,N',N'',N''']oxotechnetium[99mTc]",
    ],
  },
  {
    primary_id: "D0B8MS",
    names: ["MEDI-547"],
  },
  {
    primary_id: "D0B8MY",
    names: ["MCL-144"],
  },
  {
    primary_id: "D0B8MZ",
    names: ["Careram"],
  },
  {
    primary_id: "D0B8NH",
    names: ["Nanogam"],
  },
  {
    primary_id: "D0B8NW",
    names: ["ICA-1"],
  },
  {
    primary_id: "D0B8OB",
    names: ["PMID25684022-Compound-WO2011137219 36(3-5)"],
  },
  {
    primary_id: "D0B8PD",
    names: ["6-Cl-MLT", "6-Cl-Melatonin", "6-Chloromelatonin"],
  },
  {
    primary_id: "D0B8PE",
    names: [
      "2-(3-Hydroxyphenyl)-7-Methoxychroman-4-One",
      "CHEMBL256407",
      "32274-72-9",
      "CTK1B9323",
      "DTXSID30438650",
      "BDBM50374258",
      "AKOS030552772",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-2-(3-Hydroxyphenyl)-7-Methoxy-",
    ],
  },
  {
    primary_id: "D0B8QA",
    names: ["RP-66055", "CHEMBL405225"],
  },
  {
    primary_id: "D0B8QB",
    names: ["Magnesium"],
  },
  {
    primary_id: "D0B8RC",
    names: ["N-(2,5-Dimethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093357", "BDBM50314144"],
  },
  {
    primary_id: "D0B8RJ",
    names: [
      "AP Vaccine",
      "AP Vaccine, North American Vaccine",
      "Acellular Pertussis Vaccine (Monocomponent), North American Vaccine",
      "Vaccine (Pertussis), North American Vaccine",
    ],
  },
  {
    primary_id: "D0B8RN",
    names: [
      "4beta,15-Epoxy-Miller-9E-Enolide",
      "CHEMBL364742",
      "(4R,5Z,10R,11R)-2,3,3abeta,4,7,8,9,10,11,11aalpha-Decahydro-2-Oxo-3-Methylene-11-Hydroxy-4-(Methacryloyloxy)Spiro[cyclodeca[b]furan-10,2'-Oxirane]-6-Carbaldehyde",
    ],
  },
  {
    primary_id: "D0B8RQ",
    names: [
      "LUF-5767",
      "CHEMBL223977",
      "820961-47-5",
      "SCHEMBL4829114",
      "CTK3E2656",
      "DTXSID70465602",
      "BDBM50157656",
      "AKOS030569390",
      "L023253",
      "N-(2,6-Diphenylpyrimidin-4-Yl)-2-Methylbutyramide",
      "Butanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-2-Methyl-",
    ],
  },
  {
    primary_id: "D0B8RU",
    names: ["Aryl Mannoside Derivative 14"],
  },
  {
    primary_id: "D0B8SC",
    names: ["BOF-4272"],
  },
  {
    primary_id: "D0B8SE",
    names: ["CGP 46381", "CGP-46381", "CGP46381"],
  },
  {
    primary_id: "D0B8SS",
    names: ["1-Stearoyl-Lysophosphatidylcholine"],
  },
  {
    primary_id: "D0B8SV",
    names: [
      "NADH",
      "DPNH",
      "Dihydrodiphosphopyridine Nucleotide",
      "Dihydronicotinamide Adenine Dinucleotide",
      "Diphosphopyridine Nucleotide Reduced",
      "NADH Dianion",
      "Nicotinamide Adenine Dinucleotide Reduced",
      "Reduced Nicotinamide Adenine Dinucleotide",
      "NADH2",
      "Beta-DPNH",
      "Beta-NADH",
      "Coenzyme I, Reduced",
      "Cozymase I, Reduced",
      "Diphosphopyridine Nucleotide,reduced Form",
      "NAD-Reduced",
      "Nicotinamide Adenine Dinucleotide, Reduced Form",
      "Reduced Nicotinamide-Adenine Dinucleotide",
      "Beta-Nicotinamide Adenine Dinucleotide, Reduced Dipotassium Salt",
      "NADH+H+",
      "Nicotinaminde-Adenine-Dinucleotide",
      "Adenosine Pyrophosphate, 5'->5'-Ester With 1,4-Dihydro-1-Beta-D-Ribofuranosylnicotinamide (7CI)",
      "Adenosine 5'-(Trihydrogen Pyrophosphate), 5'->5'-Ester With 1,4-Dihydro-1-Beta-D-Ribofuranosylnicotinamide (8CI)",
      "Adenosine 5'-(Trihydrogen Diphosphate), P'->5'-Ester With 1,4-Dihydro-1-Beta-D-Ribofuranosyl-3-Pyridinecarboxamide (9CI)",
      "Adenosine 5'-{3-[1-(3-Carbamoyl-1,4-Dihydropyridin-1-Yl)-1,4-Anhydro-D-Ribitol-5-Yl] Dihydrogen Diphosphate}",
      "Adenosine 5'-{3-[1-(3-Carbamoyl-1,4-Dihydropyridin-1-Yl)-1,4-Anhydro-D-Ribitol-5-Yl] Diphosphate}",
      "[5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl [[5-(3-Carbamoyl-4H-Pyridin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] Hydrogen Phosphate",
      "[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] [(2R,3S,4R,5R)-5-(3-Carbamoyl-4H-Pyridin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Hydrogen Phosphate",
      "[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxy-Oxolan-2-Yl]methoxy-[[(2R,3S,4R,5R)-5-(3-Carbamoyl-4H-Pyridin-1-Yl)-3,4-Dihydroxy-Oxolan-2-Yl]methoxy-Hydroxy-Phosphoryl]oxy-Phosphinic Acid",
    ],
  },
  {
    primary_id: "D0B8SY",
    names: [
      "Calcium Gluceptate",
      "Calcii Glucoheptonas",
      "CALCIUM GLUCEPTATE",
      "Glucoheptonate De Calcium",
      "Glucoheptonato Calcico",
      "Calcium Glucoheptonate",
      "Calcium Glucoheptonate (1:2)",
      "Calcii Glucoheptonas [INN-Latin]",
      "EINECS 249-383-8",
      "Glucoheptonato Calcico [INN-Spanish]",
      "Calcium Gluceptate [USP]",
      "Glucoheptonate De Calcium [INN-French]",
      "Calcihept",
      "CHEBI:3314",
      "Glucoheptonate",
      "29039-00-7",
      "Calcium Gluceptate (USP)",
      "Calcium Glucoheptonate (INN)",
      "Calcium Glucoheptonate [INN]",
      "Calcii Glucoheptonas [Latin]",
      "Calcium Gluceptate [USAN]",
      "Calcium Stanley",
      "UNII-L11651398J",
    ],
  },
  {
    primary_id: "D0B8TJ",
    names: [
      "Diminazene",
      "DIMINAZENE",
      "536-71-0",
      "Berenil",
      "Diminazenum",
      "Diminazeno",
      "Diminazine",
      "Azidin",
      "Pirocide",
      "Diminazene [INN:BAN]",
      "Diminazenum [INN-Latin]",
      "Diminazeno [INN-Spanish]",
      "UNII-Y5G36EEA5Z",
      "4,4'-(Diazoamino)Benzamidine",
      "CHEBI:81724",
      "4,4'-(1-Triazene-1,3-Diyl)Bis-Benzenecarboximidamide",
      "Benzenecarboximidamide, 4,4'-(1-Triazene-1,3-Diyl)Bis-",
      "EINECS 208-644-6",
      "Y5G36EEA5Z",
      "C14H15N7",
      "Bevenil",
      "1,3-TRIS-(4'AMIDINOPHENYL)TRIAZINE",
      "4,4'-(Triaz-1-Ene-1,3-Diyl)Dibenzimidamide",
      "4,4'-(1-Triazene-1,3-Diyl)Bisbenzenecarboximidamide",
    ],
  },
  {
    primary_id: "D0B8TK",
    names: ["LX-6171", "CNS Therapeutics, Lexicon", "LG617 Program, Lexicon"],
  },
  {
    primary_id: "D0B8UJ",
    names: [
      "Nelarabine",
      "Arranon",
      "ArranonG",
      "Atriance",
      "Nelzarabine",
      "Nelarabine [USAN]",
      "Nelzarabine [USAN]",
      "GW 506U78",
      "GW506U78",
      "U78",
      "Arranon (GlaxoSmithKline)",
      "Arranon (TN)",
      "Arranon, Nelarabine",
      "Atriance (TN)",
      "GW-506U",
      "GW-506U78",
      "Nelarabine (INN)",
      "Nelzarabine (USAN)",
      "O-Methoxydeoxyguanosine",
      "O-Methylguanosine",
      "Nelarabine (JAN/USAN)",
      "(2R,3R,4S,5R)-2-(2-Amino-6-Methoxypurin-9-Yl)-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "(2R,3S,4S,5R)-2-(2-Amino-6-Methoxypurin-9-Yl)-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "(3S,4S,5R)-2-(2-Amino-6-Methoxypurin-9-Yl)-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "2-(2-Amino-6-Methoxy-Purin-9-Yl)-5-Hydroxymethyl-Tetrahydro-Furan-3,4-Diol",
      "2-(2-Amino-6-Methoxypurin-9-Yl)-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "2-Amino-6-Methoxypurine Arabinoside",
      "2-Amino-9-Beta-D-Arabinofuranosyl-6-Methoxy-9H-Purine",
      "6'-O-Methylguanosine",
      "9-Beta-D-Arabinofuranosyl-6-Methoxy-9H-Purin-2-Amine",
      "9beta-D-Arabinofuranosyl-6-Methoxy-9H-Purin-2-Amine",
    ],
  },
  {
    primary_id: "D0B8UW",
    names: ["AUTO2"],
  },
  {
    primary_id: "D0B8VW",
    names: ["Cyclo(-D-Tyr-L-Arg-L-Arg-L-MeNal-Gly-)", "CHEMBL375850"],
  },
  {
    primary_id: "D0B8WN",
    names: [
      "Ranitidine",
      "Achedos",
      "Acidex",
      "Alquen",
      "Atural",
      "Axoban",
      "Biotidin",
      "Coralen",
      "Curan",
      "Duractin",
      "Ezopta",
      "Fendibina",
      "Gastrial",
      "Gastridina",
      "Gastrolav",
      "Gastrosedol",
      "Istomar",
      "Kuracid",
      "Logast",
      "Mauran",
      "Melfax",
      "Microtid",
      "Noctone",
      "Ptinolin",
      "Quantor",
      "Quicran",
      "RND",
      "Radinat",
      "Randin",
      "Raniben",
      "Raniberl",
      "Ranibloc",
      "Ranidine",
      "Ranifur",
      "Ranin",
      "Raniogas",
      "Raniplex",
      "Ranisen",
      "Raniter",
      "Ranitidin",
      "Ranitidina",
      "Ranitidinum",
      "Ranitiget",
      "Ranitin",
      "Rantacid",
      "Rantidine",
      "Ratic",
      "Raticina",
      "Sampep",
      "Sostril",
      "Taural",
      "Terposen",
      "Ulceranin",
      "Ulcex",
      "Ultidine",
      "Urantac",
      "Verlost",
      "Vesyca",
      "Vizerul",
      "Weichilin",
      "Weidos",
      "Xanidine",
      "ZANTAC",
      "Zantab",
      "Zantadin",
      "Zantic",
      "Ranitidine HCL",
      "Rantidine HCL",
      "Nu-Ranit",
      "Rani-Q",
      "Rani-Nerton",
      "Ranitidina [INN-Spanish]",
      "Ranitidine (TN)",
      "Ranitidinum [INN-Latin]",
      "Ul-Pep",
      "Zantac (TN)",
      "Ranitidine (USAN/INN)",
      "Ranitidine [USAN:BAN:INN]",
      "N-(2-((5-((Dimethylamino)Methyl)Furfuryl)Thio)Ethyl)-N'-Methyl-2-Nitro-1,1-Ethenediamine",
      "N (2-(((5-((Dimethylamino)Methyl)-2-Furanyl)Methyl)Thio)Ethyl)-N'-Methyl-2-Nitro-1,1-Ethenediamine",
      "(E)-1-N'-[2-[[5-(Dimethylaminomethyl)Furan-2-Yl]methylsulfanyl]ethyl]-1-N-Methyl-2-Nitroethene-1,1-Diamine",
      "(E)-N-{2-[({5-[(Dimethylamino)Methyl]-2-Furyl}methyl)Sulfanyl]ethyl}-N'-Methyl-2-Nitroethene-1,1-Diamine",
      "(E)-N-{2-[({5-[(Dimethylamino)Methyl]furan-2-Yl}methyl)Sulfanyl]ethyl}-N'-Methyl-2-Nitroethene-1,1-Diamine",
    ],
  },
  {
    primary_id: "D0B8WQ",
    names: [
      "Lisinopril-Tryptophan",
      "(S)-1-N2-(1-Carboxy-3-Phenylpropyl)-L-Lysyl-L-Tryptophan",
      "GTPL7893",
      "N~2~-[(1S)-1-Carboxy-3-Phenylpropyl]-L-Lysyl-D-Tryptophan",
      "X95",
      "(2S)-2-[[(2S)-6-Amino-1-[[(1R)-1-Carboxy-2-(1H-Indol-3-Yl)Ethyl]amino]-1-Oxohexan-2-Yl]amino]-4-Phenylbutanoic Acid",
    ],
  },
  {
    primary_id: "D0B8WS",
    names: ["Zostavax"],
  },
  {
    primary_id: "D0B8WU",
    names: ["JTZ-951"],
  },
  {
    primary_id: "D0B8XK",
    names: ["SC-01"],
  },
  {
    primary_id: "D0B8YX",
    names: ["1-Phenyl-2-P-Tolyl-Ethane-1,2-Dione"],
  },
  {
    primary_id: "D0B8YZ",
    names: ["ELTANOLONE"],
  },
  {
    primary_id: "D0B8ZJ",
    names: ["OMP-336B11"],
  },
  {
    primary_id: "D0B8ZR",
    names: ["NTO-1151"],
  },
  {
    primary_id: "D0B9AO",
    names: ["Saprisartan"],
  },
  {
    primary_id: "D0B9AQ",
    names: ["WYE-130600", "B-Raf Kinase Inhibitors (Cancer), Pfizer", "B-Raf Kinase Inhibitors (Cancer), Wyeth"],
  },
  {
    primary_id: "D0B9BU",
    names: ["CI-1040"],
  },
  {
    primary_id: "D0B9CA",
    names: ["Tuvirumab"],
  },
  {
    primary_id: "D0B9CC",
    names: ["3-Methyl-1-Phenethyl-3,7-Dihydro-Purine-2,6-Dione", "CHEMBL67228"],
  },
  {
    primary_id: "D0B9DI",
    names: ["Lu-AE04621"],
  },
  {
    primary_id: "D0B9DM",
    names: ["S-555739"],
  },
  {
    primary_id: "D0B9DR",
    names: [
      "1-Benzyl-4-(2-Iodo-Pyrrol-1-Yl)-Piperidine",
      "1-Benzyl-4-(2-Iodo-1H-Pyrrol-1-Yl)Piperidine",
      "CHEMBL106635",
      "254115-95-2",
      "CTK7C3535",
      "BDBM50082171",
      "ZINC20358060",
      "SBB054419",
      "AKOS027449108",
      "4-(2-Iodopyrrolyl)-1-Benzylpiperidine",
    ],
  },
  {
    primary_id: "D0B9EH",
    names: ["Dermolastin"],
  },
  {
    primary_id: "D0B9EJ",
    names: [
      "Mitomycin A",
      "Azirino[2',3':3,4]pyrrolo[1,2-A]indole-4,7-Dione, 1,1a,2,8,8a,8b-Hexahydro-8-(Hydroxymethyl)-6,8a-Dimethoxy-5-Methyl-, Carbamate",
      "Azirino(2',3':3,4)Pyrrolo(1,2-A)Indole-4,7-Dione, 1,1a,2,8,8a,8b-Hexahydro-8-(Hydroxymethyl)-6,8a-Dimethoxy-5-Methyl-, Carbamate (Ester)",
      "[(1ar,8r,8as,8br)-6,8a-Dimethoxy-5-Methyl-4,7-Dioxo-1,1a,2,4,7,8,8a,8b-Octahydroazireno[2',3':3,4]pyrrolo[1,2-A]indol-8-Yl]methyl Carbamate",
      "Azirino(2',3':3,4)Pyrrolo(1,2-A)Indole-4,7-Dione, 8-(((Aminocarbonyl)Oxy)Methyl)-1,1a,2,8,8a,8b-Hexahydro-6,8a-Dimethoxy-5-Methyl-, (1aR-(1aalpha,8beta,8aalpha,8balpha))-(9CI)",
    ],
  },
  {
    primary_id: "D0B9EN",
    names: ["Aleglitazar"],
  },
  {
    primary_id: "D0B9EV",
    names: ["PMID19456099C15", "Compound 1j [PMID:19456099]", "GTPL3120"],
  },
  {
    primary_id: "D0B9EZ",
    names: ["2-(Piperidin-1-Yl)-4H-Benzo[h]chromen-4-One"],
  },
  {
    primary_id: "D0B9FP",
    names: [
      "2-[1-(4-CHLORO-PHENYL)-ETHYL]-4,6-DINITRO-PHENOL",
      "2-[1-(4-Chlorophenyl)Ethyl]-4,6-Dinitrophenol",
      "DNP-19",
      "AC1MRP5X",
      "CHEBI:74325",
      "2-[1-(P-Chlorophenyl)Ethyl]-4,6-Dinitrophenol",
      "2-[1-(P-Chlorophenyl)Ethyl] 4,6-Dinitrophenol",
    ],
  },
  {
    primary_id: "D0B9GH",
    names: ["Sodium Chromoglycate"],
  },
  {
    primary_id: "D0B9GM",
    names: ["Oxibendazole"],
  },
  {
    primary_id: "D0B9HH",
    names: ["MN-8001"],
  },
  {
    primary_id: "D0B9HX",
    names: ["16-Beta-Ethoxymethyl-Estrone"],
  },
  {
    primary_id: "D0B9IA",
    names: [
      "ANNULIN C",
      "Annulin C",
      "CHEMBL518688",
      "SCHEMBL10013628",
      "BDBM50241707",
      "Methyl 7-Ethyl-8-Hydroxy-1-Methoxy-3,3,6-Trimethyl-4,9-Dioxo-1,3,4,9-Tetrahydronaphtho[2,3-C]furan-1-Carboxylate",
    ],
  },
  {
    primary_id: "D0B9IV",
    names: ["XEL-002LG", "Analgesic (Gel Formulation, Pain), Xel Pharmaceuticals"],
  },
  {
    primary_id: "D0B9IX",
    names: [
      "1-(3-Phenylallylidene)Thiosemicarbazide",
      "Cinnamaldehyde Thiosemicarbazone",
      "CHEMBL388025",
      "5351-70-2",
      "AC1NVG31",
      "Cinnamaldehydethiosemicarbazone",
      "3-Phenylpropenalthiosemicarbazone",
      "BDBM50241213",
      "ZINC11536050",
      "3-Phenylacrylaldehyde Thiosemicarbazone",
      "AKOS023092594",
      "(Z)-1-((E)-3-Phenylallylidene)Thiosemicarbazide",
      "AF-960/00472016",
      "[(Z)-[(E)-3-Phenylprop-2-Enylidene]amino]thiourea",
      "F3111-2831",
      "N'-[(3-Phenylprop-2-En-1-Ylidene)Amino]carbamimidothioic Acid",
      "(1Z,N'Z)-N'-((E)-3-Phenylallylidene)Carbamohydrazonothioic Acid",
    ],
  },
  {
    primary_id: "D0B9JC",
    names: ["Bavencio Avelumab"],
  },
  {
    primary_id: "D0B9JO",
    names: ["Telavancin"],
  },
  {
    primary_id: "D0B9LI",
    names: [
      "(+/-)-Threo-N-(2-Methylpyridine)Methylphenidate",
      "CHEMBL1255015",
      "BDBM50327126",
      "(AlphaR)-Alpha-[(2R)-1-(2-Pyridinylmethyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0B9LM",
    names: ["PMID26651364-Compound-8b"],
  },
  {
    primary_id: "D0B9ML",
    names: ["PMID25726713-Compound-24"],
  },
  {
    primary_id: "D0B9MR",
    names: ["GSK-2256098"],
  },
  {
    primary_id: "D0B9MY",
    names: [
      "8-Pyridin-4-Yl-9H-Purine-2,6-Diamine",
      "CHEMBL317618",
      "9H-Purine-2,6-Diamine, 8-(4-Pyridinyl)-",
      "AC1L9X4Y",
      "BDBM50059928",
      "2,6-Diamino-8-(4'-Pyridinyl)Purine",
      "8-Pyridin-4-Yl-7H-Purine-2,6-Diamine",
      "8-(4-Pyridyl)-9H-Purine-2,6-Diamine",
      "194613-23-5",
    ],
  },
  {
    primary_id: "D0B9MZ",
    names: ["ISIS 106008"],
  },
  {
    primary_id: "D0B9NA",
    names: ["ICI-192605"],
  },
  {
    primary_id: "D0B9NG",
    names: ["Thioureidobutyronitrile"],
  },
  {
    primary_id: "D0B9NH",
    names: ["C[Nle-Glu-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL382369"],
  },
  {
    primary_id: "D0B9NU",
    names: ["Avian H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0B9OG",
    names: ["Adipiplon"],
  },
  {
    primary_id: "D0B9RD",
    names: ["ALK-001"],
  },
  {
    primary_id: "D0B9RF",
    names: ["DW-953"],
  },
  {
    primary_id: "D0B9RG",
    names: ["Cabotegravir LA + Rilpivirine LA Combination"],
  },
  {
    primary_id: "D0B9RI",
    names: ["3-Benzyl-5-Methoxychromen-2-One", "CHEMBL471247"],
  },
  {
    primary_id: "D0B9SJ",
    names: ["HTS01037"],
  },
  {
    primary_id: "D0B9SR",
    names: ["SB-273005"],
  },
  {
    primary_id: "D0B9SS",
    names: ["7-(3-Methoxyphenyl)-2-Methylquinoline", "CHEMBL394876"],
  },
  {
    primary_id: "D0B9TH",
    names: ["PMID27774822-Compound-Figure8Example5"],
  },
  {
    primary_id: "D0B9TI",
    names: ["PMID29757691-Compound-8d"],
  },
  {
    primary_id: "D0B9TN",
    names: ["2-(2-Bromophenylthio)-N,N-Dimethylethanamine", "CHEMBL610515", "1222866-87-6", "BDBM50307491"],
  },
  {
    primary_id: "D0B9TV",
    names: [
      "(+/-)-Cis-H2-PAT",
      "(Plusmn)-Cis-H2-PAT",
      "GTPL1206",
      "CHEMBL340199",
      "SCHEMBL12998410",
      "BDBM86030",
      "ZINC5965164",
      "N,N-Dimethyl-4beta-Phenyltetralin-2beta-Amine",
    ],
  },
  {
    primary_id: "D0B9TX",
    names: ["Darodipine", "Dazodipine", "Plimo", "PY-108-068"],
  },
  {
    primary_id: "D0B9UB",
    names: ["PB-200a"],
  },
  {
    primary_id: "D0B9UU",
    names: [
      "BW-202W92",
      "SCHEMBL136398",
      "CHEMBL448556",
      "QEDNOUAODRFJQN-UHFFFAOYSA-N",
      "BW202W92",
      "2.4-Diamino-5-(2,3,5-Trichlorophenyl)-6-Fluoromethylpyrimidine",
      "2,4-Diamino-5-(2,3,5-Trichlorophenyl)-6-Fluoromethylpyrimidine",
    ],
  },
  {
    primary_id: "D0B9UW",
    names: [
      "N5-[4-(4-(3-Furyl)Phenoxy)Phenyl]-L-Glutamamide",
      "CHEMBL515470",
      "Modified Amino Acid Analog, 9g",
      "BDBM24284",
    ],
  },
  {
    primary_id: "D0B9VV",
    names: ["CACOSPONGIONOLIDE B", "Cacospongionolide B", "CHEMBL111739"],
  },
  {
    primary_id: "D0B9XD",
    names: ["SP-1000", "Antihypercholesterolemic Therapy, Samaritan", "Hypercholesterolemia Therapeutics, Samaritan"],
  },
  {
    primary_id: "D0B9XL",
    names: ["1-(1-(4-Thiophen-3-Yl-Phenyl)Propyl)-1Himidazole", "CHEMBL403475", "SCHEMBL18770345"],
  },
  {
    primary_id: "D0B9XM",
    names: ["Nano-Steroid"],
  },
  {
    primary_id: "D0B9XW",
    names: ["Betalutin"],
  },
  {
    primary_id: "D0B9YO",
    names: ["2-Benzyl-5-Mercaptopentanoic Acid"],
  },
  {
    primary_id: "D0B9YR",
    names: ["MERIOLIN 4"],
  },
  {
    primary_id: "D0B9ZE",
    names: ["PMID10602705C40"],
  },
  {
    primary_id: "D0B9ZK",
    names: [
      "3-Methoxy-9-Aminomethyl-9,10-Dihydroanthracene",
      "3-METHOXY-9-AMINOMETHYL-9,10-DIHYDROANTHRACENE",
      "CHEMBL83081",
      "1075741-20-6",
      "DTXSID10648836",
      "BDBM50131086",
      "3-Methoxy-9,10-Dihydroanthracene-9-Methanamine",
      "1-(3-Methoxy-9,10-Dihydroanthracen-9-Yl)Methanamine",
    ],
  },
  {
    primary_id: "D0B9ZZ",
    names: [
      "1-[(N-Oct-1-Ylamino)Ethyl]-1,1-Bisphosphonic Acid",
      "CHEMBL260601",
      "SCHEMBL11418940",
      "BDBM50341916",
      "2-(Octylamino)Ethane-1,1-Diyldiphosphonic Acid",
      "[2-(Octylamino)-1-Phosphono-Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0BA0O",
    names: ["Syn-1002"],
  },
  {
    primary_id: "D0BA1Y",
    names: ["SYL-045001"],
  },
  {
    primary_id: "D0BA5E",
    names: ["ZYDPLA 1"],
  },
  {
    primary_id: "D0BA5J",
    names: ["Pyridine Derivative 7"],
  },
  {
    primary_id: "D0BA5K",
    names: ["Apaxifylline", "BIIP-20", "U-98950", "BIIP-20-XX"],
  },
  {
    primary_id: "D0BA6T",
    names: [
      "Methyldopa",
      "AMD",
      "Aldometil",
      "Aldomin",
      "Alphamethyldopa",
      "Baypresol",
      "Becanta",
      "Dopamet",
      "Dopamethyperpax",
      "Dopatec",
      "Dopegit",
      "Dopegyt",
      "Dopergit",
      "Grospisk",
      "Hyperpax",
      "Hypolag",
      "Medomet",
      "Medopa",
      "Medopal",
      "Medopren",
      "Methoplain",
      "Methyldopum",
      "Metildopa",
      "Novomedopa",
      "Presinol",
      "Presolisin",
      "Sedometil",
      "Sembrina",
      "Alpha Medopa",
      "Methyl Dopa",
      "Methyldopa Anhydrous",
      "Aldoril D30",
      "Aldoril D50",
      "Bayer 1440 L",
      "LT00847269",
      "MK 351",
      "Aldomet (TN)",
      "Aldoril (TN)",
      "Alpha-Methyl Dopa",
      "Alpha-Methyldihydroxyphenylalanine",
      "Alpha-Methyldopa",
      "Apo-Methyldopa",
      "Dopamet (TN)",
      "Dopegyt (TN)",
      "L-Methyl Dopa",
      "L-Methyldopa",
      "METHYL DOPA (SEE ALSO METHYL DOPA SESQUIHYDRATE)",
      "MK-351",
      "Methyldopa (INN)",
      "Methyldopa (Anhydrous)",
      "Methyldopum [INN-Latin]",
      "Metildopa [INN-Spanish]",
      "Mk. B51",
      "Nr.C 2294",
      "Nu-Medopa",
      "Alpha-Methyldopa (VAN)",
      "L-Alpha-Methyl DOPA",
      "L-Alpha-Methyldopa",
      "Methyl-L-Dopa",
      "Aldoril, Dopamet, Dopegyt, Methyldopa",
      "L-(Alpha-Md)",
      "Methyldopa (L,-)",
      "Alpha-Methyl-L-3,4-Dihydroxyphenylalanine",
      "L-Alpha-Methyl-3,4-Dihydroxyphenylalanine",
      "L-3-(3,4-Dihydroxyphenyl)-2-Methylalanine",
      "Levo-3-(3,4-Dihydroxyphenyl)-2-Methylalanine",
      "Alpha-Methyl-Beta-(3,4-Dihydroxyphenyl)-L-Alanine",
      "L(-)-Beta-(3,4-Dihydroxyphenyl)-Alpha-Methylalanine",
      "L-2-Amino-2-Methyl-3-(3,4-Dihydroxyphenyl)Propionic Acid",
      "L-(-)-3-(3,4-Dihydroxyphenyl)-2-Methylalanine",
      "L-(-)-Alpha-Methyl-Beta-(3,4-Dihydroxyphenyl)Alanine",
      "(-)-3-(3,4-Dihydroxyphenyl)-2-Methyl-L-Alanine Sesqui-Hydrate",
      "(2S)-2-Amino-3-(3,4-Dihydroxyphenyl)-2-Methylpropanoic Acid",
      "(S)-(-)-Alpha-Methyldopa",
      "3-(3,4-DIHYDROXYPHENYL)-2-METHYL-L-ALANINE",
      "3-Hydroxy-.alpha.-Methyl-L-Tyrosine",
      "3-Hydroxy-Alpha-Methyl-L-Tyrosine",
    ],
  },
  {
    primary_id: "D0BA7K",
    names: ["Recombinant Human Relaxin"],
  },
  {
    primary_id: "D0BA7Q",
    names: [
      "1-Methyl-8-Phenyl-3,7-Dihydro-Purine-2,6-Dione",
      "2850-37-5",
      "CHEMBL9069",
      "1-Methyl-8-Phenylxanthine",
      "NSC282736",
      "CBMicro_029762",
      "Cambridge Id 5733876",
      "AC1L88FA",
      "Oprea1_619029",
      "Oprea1_215826",
      "SCHEMBL515920",
      "1-Methyl-8-Phenyl-3,9-Dihydro-1H-Purine-2,6-Dione",
      "SCHEMBL13977442",
      "CTK8I0059",
      "DTXSID40314411",
      "MolPort-002-516-863",
      "MolPort-000-816-326",
      "STK169388",
      "ZINC18270697",
      "BDBM50018157",
      "AKOS005409446",
      "NSC-282736",
      "MCULE-2769568078",
      "BIM-0029837.P001",
      "1-Methyl-8-Phenyl-3,7-Dihydropurine-2,6-Dione",
      "1-Methyl-8-Phenyl-1H-Purine-2,6(3H,9H)-Dione",
    ],
  },
  {
    primary_id: "D0BA8H",
    names: [
      "HemoTech",
      "Blood Substitute (Hematological Disease), HemoBiotech",
      "Bovine Hemoglobin + Adenosine 5'-Triphosphate + O-Adenosine + Reduced Glutathione (Hemotological Disease), HemoBiotech",
    ],
  },
  {
    primary_id: "D0BA8M",
    names: ["LY-295501"],
  },
  {
    primary_id: "D0BA9U",
    names: ["Levonorgestrel"],
  },
  {
    primary_id: "D0BB0O",
    names: [
      "2MeSADP",
      "2-Methylthio-Adenosine-5'-Diphosphate",
      "2-MeSADP",
      "2-Methylthio-ADP",
      "2-Mes-ADP",
      "CHEMBL435402",
      "2-(Methylsulfanyl)Adenosine 5'-(Trihydrogen Diphosphate)",
      "Methylthio-ADP",
      "[(2R,3S,4R,5R)-5-(6-Amino-2-Methylsulfanylpurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Phosphono Hydrogen Phosphate",
      "2-(Methylthio)Adenosine 5'-Diphosphate",
      "[3H]2-Methylthio-ADP",
      "[3H]2MeSADP",
      "(3H)2-Mes-ADP",
      "AC1L3TRY",
      "SCHEMBL195864",
      "GTPL1763",
      "GTPL1710",
      "AC1Q6S18",
      "Adenosine, 2-(Methylthio)-, 5'-(Trihydrogen Diphosphate)",
      "2-(Methylthio)-Adenosinediphosphate",
    ],
  },
  {
    primary_id: "D0BB1T",
    names: ["Semparatide"],
  },
  {
    primary_id: "D0BB1X",
    names: ["KRP-105"],
  },
  {
    primary_id: "D0BB2F",
    names: ["Pyrazole Derivative 26"],
  },
  {
    primary_id: "D0BB2U",
    names: [
      "4-(2-Oxo-2-Phenyl-Acetyl)-Benzoic Acid",
      "4-(2-Oxo-2-Phenylacetyl)Benzoic Acid",
      "72857-25-1",
      "MLS000775407",
      "SMR000368487",
      "Opera_ID_465",
      "Benzil-Based Compound, 14",
      "AC1M5V1J",
      "CHEMBL370059",
      "SCHEMBL2156860",
      "4-(Oxophenylacetyl)Benzoic Acid",
      "BDBM22736",
      "CTK5D6959",
      "DTXSID10368385",
      "MolPort-000-510-282",
      "HMS2753L08",
      "4-[oxo(Phenyl)Acetyl]benzoic Acid",
      "ZINC3269660",
      "ZX-AL000632",
      "STK246909",
      "SBB039312",
      "AKOS000267727",
      "MCULE-7710064147",
      "Benzoic Acid,4-(2-Oxo-2-Phenylacetyl)-",
      "KB-237683",
      "ST50160330",
    ],
  },
  {
    primary_id: "D0BB2V",
    names: ["ABBV-838"],
  },
  {
    primary_id: "D0BB7F",
    names: ["4-Butyl-N-(Isoquinolin-5-Yl)Benzamide", "CHEMBL407762"],
  },
  {
    primary_id: "D0BB9M",
    names: ["NE-0080"],
  },
  {
    primary_id: "D0BC0F",
    names: ["OSE-300"],
  },
  {
    primary_id: "D0BC2E",
    names: ["Hydroxyflutamide"],
  },
  {
    primary_id: "D0BC2N",
    names: ["Gly-Pip-Glu"],
  },
  {
    primary_id: "D0BC4Q",
    names: ["Lifibrol"],
  },
  {
    primary_id: "D0BC6M",
    names: ["ASG-5ME"],
  },
  {
    primary_id: "D0BC6Q",
    names: ["AGN-193080", "CHEMBL298936", "SCHEMBL8348700", "BDBM50052882", "L012655"],
  },
  {
    primary_id: "D0BC7P",
    names: [
      "2-Amino-6-Phenyl-4-Thiophen-2-Yl-Nicotinonitrile",
      "CHEMBL476298",
      "AC1MNMTO",
      "ZINC33468133",
      "BDBM50262440",
      "AKOS000286735",
    ],
  },
  {
    primary_id: "D0BD0K",
    names: ["3-(4'-Hydroxyphenyl)Diazenylbenzenesulfonamide", "4-((4-Aminophenyl)Diazenyl)Benzenesulfonamide"],
  },
  {
    primary_id: "D0BD0Q",
    names: ["Proglumetacin"],
  },
  {
    primary_id: "D0BD3G",
    names: [
      "4-(2-(4-Chlorobenzyloxy)-5-Bromobenzyl)Morpholine",
      "CHEMBL391379",
      "Morpholine, 4-[[5-Bromo-2-[(4-Chlorophenyl)Methoxy]phenyl]methyl]-",
      "851546-84-4",
      "SCHEMBL5699765",
      "CTK3C9230",
      "DTXSID80458181",
      "4-[[5-Bromo-2-[(4-Chlorophenyl)Methoxy]phenyl]methyl]morpholine",
    ],
  },
  {
    primary_id: "D0BD3M",
    names: ["Heteroaryl-Azepine Derivative 4"],
  },
  {
    primary_id: "D0BD3U",
    names: ["SDZ-64-412"],
  },
  {
    primary_id: "D0BD5F",
    names: ["Vaccine, Antigen-Specific Immune Cell Activation Technology, HIV Infection"],
  },
  {
    primary_id: "D0BD5J",
    names: ["GSK2838497A"],
  },
  {
    primary_id: "D0BD6A",
    names: [
      "GT-1282",
      "DDVVRHNNOPQPGB-UHFFFAOYSA-N",
      "NIBR-1282",
      "CHEMBL255858",
      "SCHEMBL619811",
      "BDBM50234723",
      "SB16769",
      "(2,4-Dimethyl-1-Oxy-Pyridin-3-Yl)-[4''-Methyl-4-(Phenyl-Pyridin-3-Yl-Amino)-[1,4'']bipiperidinyl-1''-Yl]-Methanone",
    ],
  },
  {
    primary_id: "D0BD8D",
    names: [
      "Cisapride",
      "Propulsid",
      "Prepulsid",
      "Syspride",
      "Pridesia",
      "Risamal",
      "Acenalin",
      "Alimix",
      "Propulsin",
      "Propulsid Quicksolv",
      "Enteropride",
      "Kinestase",
      "81098-60-4",
      "Cisawal",
      "Vomiprid",
      "Colinorm",
      "Cisapron",
      "Vomipride",
      "Unipride",
      "Kaudalit",
      "Alipride",
      "Acpulsif",
      "Rapulid",
      "Cipride",
      "Pulsid",
      "Esorid",
      "Dispep",
      "Presid",
      "Unamol",
      "Guptro",
      "Alimix Forte",
      "Cisapridum [Latin]",
      "Cisaprida [Spanish]",
      "R-51619",
      "Cisapridum [INN-Latin]",
      "R 51619",
      "Cisaprida [INN-Spanish]",
      "104860-73-3",
      "DCSUBABJRXZOMT-UHFFFAOYSA-N",
      "T 1341",
      "DSSTox_CID_2825",
      "Cisaprida",
      "Cisapridum",
      "Risamol",
      "Prepulsid (TN)",
      "Propulsid (TN)",
      "Cisapride (USAN/INN)",
      "Cisapride [USAN:BAN:INN:JAN]",
      "Cis-4-Amino-5-Chloro-N-(1-(3-(P-Fluorophenoxy)Propyl)-3-Methoxy-4-Piperidyl)-O-Anisamide",
      "(+-)-Cisapride",
      "4-Amino-5-Chloro-N-[1-[3-(4-Fluorophenoxy)Propyl]-3-Methoxypiperidin-4-Yl]-2-Methoxybenzamide",
      "4-Amino-5-Chloro-N-[1-{3-[(4-Fluorophenyl)Oxy]propyl}-3-(Methyloxy)Piperidin-4-Yl]-2-(Methyloxy)Benzamide",
      "4-Amino-5-Chloro-N-{1-[3-(4-Fluorophenoxy)Propyl]-3-Methoxypiperidin-4-Yl}-2-Methoxybenzamide",
      "E-1454",
    ],
  },
  {
    primary_id: "D0BD9K",
    names: [
      "Gilotrif",
      "Afatinib Dimaleate",
      "AFATINIB DIMALEATE",
      "850140-73-7",
      "Afatinib (DiMaleate)",
      "UNII-V1T5K7RZ0B",
      "BIBW 2992MA2",
      "Afatinib Dimaleate [USAN]",
      "V1T5K7RZ0B",
      "CHEBI:76003",
      "Afatinib (BIBW2992) Dimaleate",
      "Afatinib Maleate",
      "Afatinib Dimaleate (USAN)",
      "Giotrif",
      "Gilotrif (TN)",
      "Giotrif (TN)",
      "Afatinib Maleate (JAN)",
      "(2E)-N-(4-((3-Chloro-4-Fluorophenyl)Amino)-7-(((3S)-Tetrahydrofuran-3-Yl)Oxy)Quinazolin- 6-Yl)-4-(Dimethylamino)But-2-Enamide Bis(Hydrogen (2Z)-But-2-Enedioate)",
      "BIBW2992 MA2",
      "CHEMBL2105712",
      "USNRYVNRPYXCSP-JUGPPOIOSA-N",
      "MolPort-028-744-902",
      "AOB873",
    ],
  },
  {
    primary_id: "D0BE0S",
    names: [
      "1-(1-Naphthyl)-2-Aminopropane",
      "CHEMBL471838",
      "1-Napthyl-2-Aminopropane",
      "SCHEMBL266931",
      "AC1L96M0",
      "1-(1-Naphthyl)Propane-2-Amine",
      "CTK6A7125",
      "1-Naphthalen-1-Ylpropan-2-Amine",
      "BDBM50276188",
      "AKOS022488614",
      "AKOS000151865",
      "12687-37-5",
      "1-(NAPHTHALEN-1-YL)PROPAN-2-AMINE",
    ],
  },
  {
    primary_id: "D0BE0Z",
    names: ["Contezolid Acefosamil"],
  },
  {
    primary_id: "D0BE1A",
    names: ["R-209130"],
  },
  {
    primary_id: "D0BE3D",
    names: ["Des-AA1,2,5-[D-Trp8,IAmp9,Tyr11]Cbm-SRIF", "CHEMBL414717"],
  },
  {
    primary_id: "D0BE4Z",
    names: ["N6-Cyclopropyllydine Derivative 1"],
  },
  {
    primary_id: "D0BE5U",
    names: ["PMID30247903-Compound-General Structure30"],
  },
  {
    primary_id: "D0BE6M",
    names: ["Anti-Mesothelin CAR-T Cells"],
  },
  {
    primary_id: "D0BE8H",
    names: ["INCB62079"],
  },
  {
    primary_id: "D0BE9U",
    names: ["Pyrimidinyl Pyrazolamine Derivative 1"],
  },
  {
    primary_id: "D0BF3Y",
    names: ["4-Fluoro-1,1':4',1''-Terphenyl-3,3''-Diol", "CHEMBL570102", "BDBM50299654"],
  },
  {
    primary_id: "D0BF4Z",
    names: ["GSK2981278"],
  },
  {
    primary_id: "D0BF8G",
    names: [
      "Pamidronate",
      "AHPrBP",
      "Amidronate",
      "Aminomux",
      "Aminopropanehydroxydiphosphonate",
      "Aredia",
      "Ribodroat",
      "Acide Pamidronique",
      "Acido Pamidronico",
      "Acidum Pamidronicum",
      "Aminohydroxypropylidene Diphosphonate",
      "Novartis Brand Of Pamidronate Disodium Salt",
      "PAMIDRONATE DISODIUM",
      "PAMIDRONIC ACID",
      "Pamidronate Calcium",
      "Pamidronate Monosodium",
      "Bisphosphonate 6",
      "Acide Pamidronique [INN-French]",
      "Acido Pamidronico [INN-Spanish]",
      "Acidum Pamidronicum [INN-Latin]",
      "Pamidronic Acid (INN)",
      "Pamidronic Acid [INN:BAN]",
      "Ribodroat (TN)",
      "Amino-1-Hydroxypropane-1,1-Diphosphonate",
      "(3-AMINO-1-HYDROXY-1-PHOSPHONO-PROPYL)PHOSPHONIC ACID",
      "(3-Amino-1-Hydroxypropylidene)Bisphosphonic Acid",
      "(3-Amino-1-Hydroxypropylidene)Diphosphonic Acid",
      "(3-Amino-1-Hydroxy-1-Phosphonopropyl)Phosphonic Acid",
      "(3-Amino-1-Hydroxypropane-1,1-Diyl)Bis(Phosphonic Acid)",
      "(3-Amino-1-Hydroxypropylidene)-1,1-Biphosphonate",
      "(3-Amino-1-Hydroxypropylidene)-1,1-Bisphosphonate",
      "1-Hydroxy-3-Aminopropane-1,1-Diphosphonic Acid",
      "3-Amino-1-Hydroxypropylidene-1,1-Diphosphonate",
    ],
  },
  {
    primary_id: "D0BF8Y",
    names: ["Pelareorep"],
  },
  {
    primary_id: "D0BF9K",
    names: ["ABX-IL8"],
  },
  {
    primary_id: "D0BG2L",
    names: ["ABC-7"],
  },
  {
    primary_id: "D0BG3B",
    names: ["VX-135"],
  },
  {
    primary_id: "D0BG4W",
    names: [
      "Cupric Sulfate",
      "Copper Sulfate",
      "7758-98-7",
      "CUPRIC SULFATE",
      "Copper(II) Sulfate",
      "Cupric Sulfate Anhydrous",
      "Copper Sulphate",
      "Copper(2+) Sulfate",
      "Cupricsulfate",
      "Blue Stone",
      "Copper Monosulfate",
      "Copper II Sulfate",
      "Trinagle",
      "Hylinec",
      "Delcup",
      "Cupric Sulphate",
      "Copper(Ii) Sulfate, Anhydrous",
      "Monocopper Sulfate",
      "Copper Sulfate (1:1)",
      "Incracide 10A",
      "Incracide E 51",
      "Sulfuric Acid Copper(2+) Salt (1:1)",
      "BCS Copper Fungicide",
      "Blue Copper (VAN)",
      "Copper Sulfate Powder",
      "Bonide Root Destroyer",
      "CuSO4",
      "Kupfersulfat [German]",
      "All Clear Root Destroyer",
      "Copper Sulfate (CuSO",
    ],
  },
  {
    primary_id: "D0BG5H",
    names: ["SB 269970-A"],
  },
  {
    primary_id: "D0BG7B",
    names: ["Tarnylcypromine Derivative 1"],
  },
  {
    primary_id: "D0BH2M",
    names: ["4SCAR19 And 4SCAR123"],
  },
  {
    primary_id: "D0BH2T",
    names: [
      "2,2',4,4'-Tetrahydroxychalcone",
      "2,4,2',4'-Tetrahydroxychalcone",
      "CHEMBL394855",
      "SCHEMBL675094",
      "2,4,2'',4''-Tetrahydroxychalcone",
      "LMPK12120128",
      "2,2'',4,4''-Tetrahydroxychalcone",
      "BDBM50203985",
    ],
  },
  {
    primary_id: "D0BH3Y",
    names: ["FGGFTGARKSARKLRNQ"],
  },
  {
    primary_id: "D0BH6B",
    names: [
      "Apigenin-7-O-Beta-D-Glucuronide Methyl Ester",
      "53538-13-9",
      "(2S,3S,4S,5R,6S)-Methyl 3,4,5-Trihydroxy-6-((5-Hydroxy-2-(4-Hydroxyphenyl)-4-Oxo-4H-Chromen-7-Yl)Oxy)Tetrahydro-2H-Pyran-2-Carboxylate",
      "CHEMBL464868",
      "Apigenin 7-O-Methylglucuronide",
      "MolPort-035-684-098",
      "Apigenin 7-(6-O-Methylglucuronide)",
      "ZINC40874044",
      "BDBM50251270",
      "AKOS024258585",
      "AJ-103820",
      "ST24034139",
    ],
  },
  {
    primary_id: "D0BH7S",
    names: ["6-Benzylsulfanyl-9H-Purine"],
  },
  {
    primary_id: "D0BH7X",
    names: ["Isoxazole-Based Bicyclic Compound 6"],
  },
  {
    primary_id: "D0BH7Z",
    names: ["Girentuximab I-124"],
  },
  {
    primary_id: "D0BH8C",
    names: ["MGVWN-1"],
  },
  {
    primary_id: "D0BH8K",
    names: ["Cyclo(-D-Tyr-D-Arg-L-Arg-L-Nal-L-Ala-)", "CHEMBL376811"],
  },
  {
    primary_id: "D0BH9I",
    names: [
      "INN-108",
      "5-(4-Carboxymethyl-Phenylazo)-2-Hydroxy-Benzoic Acid",
      "UNII-QIU74LKJ78",
      "QIU74LKJ78",
      "Nobex-AA-004",
      "SCHEMBL1636172",
      "5-((E)-(4-(Carboxymethyl)Phenyl)Azo)-2-Hydroxy-Benzoic Acid",
      "Benzeneacetic Acid, 4-(2-(3-Carboxy-4-Hydroxyphenyl)Diazenyl)-",
      "402934-69-4",
    ],
  },
  {
    primary_id: "D0BI0F",
    names: ["Imidazole Derivative 3"],
  },
  {
    primary_id: "D0BI0Z",
    names: [
      "4,4-Diphenyl-N-(Pyridin-3-Yl)-Butyramide",
      "CHEMBL565028",
      "SCHEMBL4617484",
      "CEQRPUYSSDGEML-UHFFFAOYSA-N",
      "BDBM50297404",
    ],
  },
  {
    primary_id: "D0BI1C",
    names: ["Live Attenuated Influenza Vaccine"],
  },
  {
    primary_id: "D0BI1O",
    names: ["PMID28051882-Compound-Figure9"],
  },
  {
    primary_id: "D0BI3K",
    names: ["Omburtamab I-124"],
  },
  {
    primary_id: "D0BI3N",
    names: ["Cerdulatinib"],
  },
  {
    primary_id: "D0BI4H",
    names: ["Hydroxyalanine"],
  },
  {
    primary_id: "D0BI4T",
    names: [
      "UNC0642",
      "1481677-78-4",
      "UNC 0642",
      "UNC-0642",
      "CHEMBL2441082",
      "2-(4,4-Difluoro-1-Piperidinyl)-6-Methoxy-N-[1-(1-Methylethyl)-4-Piperidinyl]-7-[3-(1-Pyrrolidinyl)Propoxy]-4-Quinazolinamine",
      "Barrett",
      "GTPL7017",
      "SCHEMBL17372593",
      "AOB2595",
      "MolPort-035-765-953",
      "EX-A2241",
      "BCP08266",
      "ZINC96285772",
      "BDBM50442103",
      "AKOS024458509",
      "SB19046",
      "CS-5269",
      "NCGC00189140-01",
      "NCGC00189140-02",
      "AS-16721",
      "HY-13980",
      "BC600721",
      "AK547424",
      "UNC0642, &gt",
      "KB-146019",
      "J-008448",
    ],
  },
  {
    primary_id: "D0BI5I",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 15"],
  },
  {
    primary_id: "D0BI5Y",
    names: [
      "C-(9H-Thioxanthen-9-Yl)-Methylamine",
      "CHEMBL152509",
      "(9H-Thioxanthen-9-Ylmethyl)Amine",
      "9H-Thioxanthene-9-Methanamine",
      "SCHEMBL7001557",
      "ZINC6928031",
      "BDBM50097225",
    ],
  },
  {
    primary_id: "D0BI7J",
    names: ["FP-045"],
  },
  {
    primary_id: "D0BI8T",
    names: ["RS-39066"],
  },
  {
    primary_id: "D0BI9W",
    names: ["Lck Tyrosine Kinase Inhibitors"],
  },
  {
    primary_id: "D0BJ0Q",
    names: [
      "DDD-64558",
      "DDD-100097",
      "DDD-73234",
      "DDD-85602",
      "DDD-85635",
      "DDD-85646",
      "DDD-99742",
      "Myristoyl Transferase Inhibitors (HumanAfrican Trypanosomiasis Infection)",
      "Myristoyl Transferase Inhibitors (Human African Trypanosomiasis Infection), University Of Dundee",
    ],
  },
  {
    primary_id: "D0BJ0W",
    names: ["ISIS 25566"],
  },
  {
    primary_id: "D0BJ2W",
    names: ["DAS-431 IV"],
  },
  {
    primary_id: "D0BJ2X",
    names: ["PMID25482888-Compound-37"],
  },
  {
    primary_id: "D0BJ3B",
    names: ["Soluble CD83"],
  },
  {
    primary_id: "D0BJ5O",
    names: ["ASP-4178"],
  },
  {
    primary_id: "D0BJ6M",
    names: ["PMID25656651-Compound-25"],
  },
  {
    primary_id: "D0BJ7G",
    names: ["WSB-961"],
  },
  {
    primary_id: "D0BJ7W",
    names: ["GLPG-0492"],
  },
  {
    primary_id: "D0BJ8Q",
    names: ["INO-8000"],
  },
  {
    primary_id: "D0BK2I",
    names: ["Pyridine Derivative 1"],
  },
  {
    primary_id: "D0BK2Y",
    names: ["SF-21"],
  },
  {
    primary_id: "D0BK3Z",
    names: [
      "ProstaRex",
      "AIT (Prostate Cancer), AltaRex",
      "Protate Cancer Therapy, ViRexx/United Therapeutics",
      "AIT (Prostate Cancer), ViRexx/United Therapeutics",
      "Anti-Idiotype Induction Therapy (Prostate Cancer), Paladin",
      "Anti-Idiotype Induction Therapy (Prostate Cancer), United Therapeutics",
      "Anti-Idiotype Induction Therapy (Prostate Cancer), AltaRex/United Therapeutics",
    ],
  },
  {
    primary_id: "D0BK4X",
    names: ["Cinryze"],
  },
  {
    primary_id: "D0BK5N",
    names: ["Pyrido[1,2-A]indole-1.-Carboxylic Acid Analog 5"],
  },
  {
    primary_id: "D0BK6B",
    names: [
      "CI-930",
      "CI 930",
      "86798-59-6",
      "4,5-Dihydro-6-(4-(Imidazol-1-Yl)Phenyl)-5-Methyl-3(2H)-Pyridazinone",
      "3(2H)-Pyridazinone, 4,5-Dihydro-6-(4-(1H-Imidazol-1-Yl)Phenyl)-5-Methyl-",
      "AC1MIBTH",
      "CHEMBL440593",
      "SCHEMBL6052113",
      "CTK5F7194",
      "BDBM14362",
      "AEZZPAQOEUQNBB-UHFFFAOYSA-N",
      "90791-23-4 (Hydrochloride)",
      "BDBM50225945",
      "LS-187480",
      "LS-186812",
      "6-[4-(1-Imidazolyl)Phenyl]-5-Methyl-3-Oxo-2,3,4,5-Tetrahydropyridazine",
      "3-(4-Imidazol-1-Ylphenyl)-4-Methyl-4,5-Dihydro-1H-Pyridazin-6-One",
    ],
  },
  {
    primary_id: "D0BK6R",
    names: ["PMID25656651-Compound-36c"],
  },
  {
    primary_id: "D0BK7D",
    names: [
      "Autologous Dendritic Cell-Tumor Cell Fusion Vaccine",
      "Cell Fusion Vaccine",
      "Autologous Dendritic Cell-Tumor Cell Fusion Vaccine (Gastrointestinal Cancer)",
    ],
  },
  {
    primary_id: "D0BK8V",
    names: [
      "2',3'-DdATP",
      "2',3'-Dideoxyadenosine Triphosphate",
      "DdATP",
      "UNII-9MCI2H1EJ6",
      "2',3'-Dideoxyadenosine-5'-Triphosphate",
      "24027-80-3",
      "Dideoxyadenosine Triphosphate",
      "CHEMBL1383",
      "9MCI2H1EJ6",
      "2',3'-DIDEOXYADENOSINE 5'-TRIPHOSPHATE",
      "Adenosine 5'-(Tetrahydrogen Triphosphate), 2',3'-Dideoxy-",
      "2',3'-Dideoxy-ATP",
      "DdA-TP",
      "ATP,2',3'-Dideoxy",
      "SCHEMBL79815",
      "GTPL1709",
      "AC1L230G",
      "DTXSID90178767",
      "MolPort-044-561-436",
      "ZINC12501706",
      "BDBM50164644",
      "AKOS030589611",
      "2'',3''-Dideoxyadenosine Triphosphate",
      "DB02189",
      "2',3'-Dideoxyadenosine-5-Triphosphate",
      "2',3'-Dideoxyadenosine Triphosphate (Ddatp)",
    ],
  },
  {
    primary_id: "D0BL0G",
    names: ["TAK-233"],
  },
  {
    primary_id: "D0BL0O",
    names: ["PMID23521020C7k"],
  },
  {
    primary_id: "D0BL3X",
    names: ["PMID25553724-Compound-US2011799419010"],
  },
  {
    primary_id: "D0BL4H",
    names: ["Quinoline And Quinazoline Derivative 6"],
  },
  {
    primary_id: "D0BL5U",
    names: ["PL-3994 NPR-A"],
  },
  {
    primary_id: "D0BL6E",
    names: ["KUR-113"],
  },
  {
    primary_id: "D0BL6W",
    names: ["THA-902"],
  },
  {
    primary_id: "D0BL8S",
    names: ["[Gly8,Glu22]GLP-1(7,37)-NH2"],
  },
  {
    primary_id: "D0BL8W",
    names: ["PMID28394193-Compound-21"],
  },
  {
    primary_id: "D0BM0M",
    names: ["N,Ndimethyl Milnacipran"],
  },
  {
    primary_id: "D0BM2T",
    names: ["(4-Sulfamoylphenylethylthioureido)Fluorescein", "CHEMBL501130", "SCHEMBL13263774"],
  },
  {
    primary_id: "D0BM3R",
    names: ["LY-307853"],
  },
  {
    primary_id: "D0BM4F",
    names: ["Phenyl-(5-Phenyl-Oxazol-2-Yl)-Amine"],
  },
  {
    primary_id: "D0BM4T",
    names: ["CaPre"],
  },
  {
    primary_id: "D0BM5G",
    names: [
      "Tazarotene",
      "Avage",
      "Suretin",
      "Tazaroteno",
      "Tazarotenum",
      "Tazorac",
      "Tazoral",
      "Zorac",
      "AGN 190168",
      "AGN-190168",
      "Avage (TN)",
      "Tazarotene [USAN:INN]",
      "Tazorac (TN)",
      "Zorac (TN)",
      "Tazarotene (JAN/USAN/INN)",
      "Tazorac, Avage, Zora, Tazarotene",
      "Ethyl 6-((4,4-Dimethylthiochroman-6-Yl)Ethynyl)Nicotinate",
      "Ethyl 6-(2-(4,4-Dimethylthiochroman-6-Yl)Ethynyl)Nicotinate",
      "Ethyl 6-[(4,4-Dimethyl-3,4-Dihydro-2H-Thiochromen-6-Yl)Ethynyl]nicotinate",
      "Ethyl 6-[2-(4,4-Dimethyl-2,3-Dihydrothiochromen-6-Yl)Ethynyl]pyridine-3-Carboxylate",
      "3-Pyridinecarboxylic Acid, 6-((3,4-Dihydro-4,4-Dimethyl-2H-1-Benzothiopyran-6-Yl)Ethynyl)-, Ethyl Ester",
      "3-Pyridinecarboxylic Acid, 6-((3,4-Dihydro-4,4-Dimethyl-2H-1-Benzothiopyran-6-Yl)Ethynyl)-,ethyl Ester",
    ],
  },
  {
    primary_id: "D0BM6S",
    names: [
      "IPI-493",
      "[(3R,5R,6S,7R,8E,10R,11R,12Z,14E)-21-Amino-6-Hydroxy-5,11-Dimethoxy-3,7,9,15-Tetramethyl-16,20,22-Trioxo-17-Azabicyclo[16.3.1]docosa-1(21),8,12,14,18-Pentaen-10-Yl] Carbamate",
      "AC1NS08X",
      "SCHEMBL16226496",
      "SCHEMBL16225851",
    ],
  },
  {
    primary_id: "D0BM7M",
    names: ["AZD8931"],
  },
  {
    primary_id: "D0BM7O",
    names: ["Duo3", "DUO3"],
  },
  {
    primary_id: "D0BM8Z",
    names: ["SOM-0633"],
  },
  {
    primary_id: "D0BN1U",
    names: ["M&B 28767"],
  },
  {
    primary_id: "D0BN2C",
    names: ["Elamipretide"],
  },
  {
    primary_id: "D0BN4A",
    names: ["Pyrimido[4,5-D] Pyrimidines And Pyrido[4,3-D] Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0BN4U",
    names: ["MSVIII-19", "8,9-Dideoxyneodysiherbaine"],
  },
  {
    primary_id: "D0BN4Y",
    names: ["Ebalzotan"],
  },
  {
    primary_id: "D0BN7E",
    names: ["5-Isopropyl-2-(Phenylamino)Thiazol-4(5H)-One"],
  },
  {
    primary_id: "D0BN7S",
    names: ["Azetidine Urea Derivative 1"],
  },
  {
    primary_id: "D0BN7W",
    names: ["Roclatan"],
  },
  {
    primary_id: "D0BN9C",
    names: ["(S)-Secoantioquine Hydrochloride", "CHEMBL506299", "(S)-Secoantioquine HCl"],
  },
  {
    primary_id: "D0BN9E",
    names: ["PMID29649907-Compound-13"],
  },
  {
    primary_id: "D0BN9R",
    names: ["GelVac H5N1 Influenza Virus Vaccine Intranasal"],
  },
  {
    primary_id: "D0BN9X",
    names: [
      "Tirofiban",
      "AGG",
      "Aggrastat",
      "Agrastat",
      "Tirofibanum",
      "Aggrastat (TN)",
      "Agrastat (TN)",
      "L-700462",
      "MK-383",
      "Tirofiban (INN)",
      "Tirofiban[BAN:INN]",
      "Tirofiban [INN:BAN]",
      "L-700,462",
      "N-(BUTYLSULFONYL)-O-[4-(4-PIPERIDINYL)BUTYL]-L-TYROSINE",
      "N-(Butylsulfonyl)-O-(4-(4-Piperidyl)Butyl)-L-Tyrosine",
      "N-(Butylsulfonyl)-O-(4-Piperidin-4-Ylbutyl)-L-Tyrosine",
      "N-(Butylsulfonyl)-O-[4-(Piperidin-4-Yl)Butyl]-L-Tyrosine",
      "(2S)-2-(Butylsulfonylamino)-3-[4-(4-Piperidin-4-Ylbutoxy)Phenyl]propanoic Acid",
    ],
  },
  {
    primary_id: "D0BO0K",
    names: ["INCSHR1210", "Camrelizumab"],
  },
  {
    primary_id: "D0BO1H",
    names: ["PMID28270021-Compound-WO2016054807Example80"],
  },
  {
    primary_id: "D0BO6C",
    names: ["N-(1,3-Diphenyl-1H-Pyrazol-5-Yl)Benzamide"],
  },
  {
    primary_id: "D0BO6I",
    names: ["EHT-899"],
  },
  {
    primary_id: "D0BO7P",
    names: ["Bictegravir + Emtricitabine  +  Tenofovir Alafenamide", "Bictegravir/F/TAF"],
  },
  {
    primary_id: "D0BO7U",
    names: [
      "BMX-001",
      "Bmx-001",
      "UNII-MIJ02RO47F",
      "MIJ02RO47F",
      "Manganese(5+), ((2,2',2'',2'''-(21H,23H-Porphine-5,10,15,20-Tetrayl-KappaN21,kappaN22,kappaN23,kappaN24)Tetrakis(1-(2-Butoxyethyl)Pyridiniumato))(2-))-, Chloride (1:5), (Sp-4-1)-",
      "1379783-91-1",
    ],
  },
  {
    primary_id: "D0BO9W",
    names: [
      "N-(3-Phenoxy-4-Pyridinyl)Ethanesulfonamide",
      "CHEMBL376143",
      "833455-54-2",
      "CTK3D2870",
      "DTXSID30457028",
      "BDBM50158446",
      "AKOS030595655",
      "Ethanesulfonamide, N-(3-Phenoxy-4-Pyridinyl)-",
    ],
  },
  {
    primary_id: "D0BP1S",
    names: ["AcAsp-Glu-Dif-Ile-Cha-Cys-Pro-Nle-Asp-ValNH2"],
  },
  {
    primary_id: "D0BP3E",
    names: [
      "(1,1-Difluoro-Pentadecyl)-Phosphonic Acid",
      "CHEMBL188859",
      "SCHEMBL193245",
      "YGXPAGSHLFJKFV-UHFFFAOYSA-N",
      "BDBM50170861",
      "ZINC36178965",
      "(1,1-Difluoro-Pentadecyl)Phosphonic Acid",
      "(1,1-Difluoro-Pentadecyl) Phosphonic Acid",
    ],
  },
  {
    primary_id: "D0BP4Y",
    names: ["Technetium Tc-99m Sodium Pertechnetate Generator"],
  },
  {
    primary_id: "D0BP5T",
    names: ["Aza-C-Nucleosides"],
  },
  {
    primary_id: "D0BP6I",
    names: ["PM-102"],
  },
  {
    primary_id: "D0BP6J",
    names: ["PMID28766366-Compound-Scheme7WO2012/098070bottom"],
  },
  {
    primary_id: "D0BP6R",
    names: ["Ethinyl-Pyrazole Derivative 2"],
  },
  {
    primary_id: "D0BP6T",
    names: ["ISIS 150464"],
  },
  {
    primary_id: "D0BP7C",
    names: ["J-104135"],
  },
  {
    primary_id: "D0BP7W",
    names: ["VBY-106"],
  },
  {
    primary_id: "D0BP8I",
    names: ["PMID29671355-Compound-43"],
  },
  {
    primary_id: "D0BP9B",
    names: ["N,N-Bis(5-Pyrazoyl)Urea Derivative 1"],
  },
  {
    primary_id: "D0BP9C",
    names: ["Etravirine"],
  },
  {
    primary_id: "D0BQ0C",
    names: ["CD19 CAR-T Cells"],
  },
  {
    primary_id: "D0BQ4Q",
    names: ["Colostrinin-Derived Peptides"],
  },
  {
    primary_id: "D0BQ5X",
    names: ["Diphtheria, Tetanus, Pertussis, HBV, Haemophilus Influenzae Vaccine"],
  },
  {
    primary_id: "D0BQ7K",
    names: ["PMID25522065-Compound-33"],
  },
  {
    primary_id: "D0BQ8A",
    names: ["PMID25553724-Compound-US2011796857710"],
  },
  {
    primary_id: "D0BQ8M",
    names: ["CY-208243"],
  },
  {
    primary_id: "D0BQ8Y",
    names: [
      "Allyl Isothiocyanate",
      "ALLYL ISOTHIOCYANATE",
      "1957/6/7",
      "3-Isothiocyanatoprop-1-Ene",
      "MUSTARD OIL",
      "Redskin",
      "Oil Of Mustard",
      "Allylsenevol",
      "Allylsenfoel",
      "Allyl Mustard Oil",
      "Oleum Sinapis",
      "Allylsevenolum",
      "Senfoel",
      "Volatile Mustard Oil",
      "Allyl Isosulfocyanate",
      "Synthetic Mustard Oil",
      "AITC",
      "2-Propenyl Isothiocyanate",
      "Carbospol",
      "1-Propene, 3-Isothiocyanato-",
      "Allyspol",
      "Volatile Oil Of Mustard",
      "ALLYLISOTHIOCYANATE",
      "Senf Oel",
      "Oleum Sinapis Volatile",
      "Isothiocyanate D'allyle",
      "3-Isothiocyanato-1-Propene",
      "Allyl Sevenolum",
      "Oils, Mustard",
      "Mustard Seed Oil",
      "Isothiocyanic Acid All",
    ],
  },
  {
    primary_id: "D0BQ9A",
    names: ["Bacteriophage", "Bacteriophage (S Aureus)"],
  },
  {
    primary_id: "D0BR0D",
    names: ["MGB-101"],
  },
  {
    primary_id: "D0BR1D",
    names: [
      "4,6-Dinitro Salicylic Acid",
      "4,6-Dinitrosalicylic Acid",
      "CHEMBL447810",
      "65841-03-4",
      "SCHEMBL8633874",
      "6-Hydroxy-2,4-Dinitrobenzoic Acid",
      "2-Hydroxy-4,6-Dinitrobenzoic Acid",
    ],
  },
  {
    primary_id: "D0BR1S",
    names: ["ProscaVax"],
  },
  {
    primary_id: "D0BR1V",
    names: [
      "4'-(Benzensulfonamide)-4-Hydroxychalcone",
      "CHEMBL1087322",
      "SCHEMBL3483693",
      "BDBM50316853",
      "4''-(Benzensulfonamide)-4-Hydroxychalcone",
    ],
  },
  {
    primary_id: "D0BR6D",
    names: ["PMID27998201-Compound-10"],
  },
  {
    primary_id: "D0BR7G",
    names: ["Imidazopyridine Derivative 5"],
  },
  {
    primary_id: "D0BR7M",
    names: [
      "Breceptin",
      "B-10206",
      "B-10396",
      "B-10410",
      "B-10458",
      "B-199",
      "B-201 Acetate",
      "B-202",
      "B-9340",
      "B-9430",
      "B-9698",
      "B-9870",
      "CU-202",
      "CU-201, Apoplogic",
    ],
  },
  {
    primary_id: "D0BR7X",
    names: ["ISIS 16179"],
  },
  {
    primary_id: "D0BR8N",
    names: ["BVA-201"],
  },
  {
    primary_id: "D0BR9C",
    names: ["Biphenyl Mannoside Derivative 2"],
  },
  {
    primary_id: "D0BS0K",
    names: ["Tetra-Hydro-Pyrazolopyrazine Derivative 5"],
  },
  {
    primary_id: "D0BS2Q",
    names: ["[Thr4,Gly7]OT", "[Thr4,Gly7]oxytocin"],
  },
  {
    primary_id: "D0BS2S",
    names: ["TPU-002"],
  },
  {
    primary_id: "D0BS3E",
    names: ["Biphenyl Mannoside Derivative 21"],
  },
  {
    primary_id: "D0BS5E",
    names: ["AMO-02"],
  },
  {
    primary_id: "D0BS6B",
    names: ["TP-801", "C-Met Tyrosine Kinase Inhibitor (Oral, Cancer), Tiger Pharmatech"],
  },
  {
    primary_id: "D0BS6F",
    names: ["JNJ-64400141"],
  },
  {
    primary_id: "D0BS6Q",
    names: [
      "Distamycin A",
      "Stallimycin",
      "Distamycin",
      "Herperetin",
      "636-47-5",
      "DST-A",
      "UNII-80O63P88IS",
      "NSC82150",
      "FI 6426",
      "CHEMBL11252",
      "80O63P88IS",
      "NCGC00018292-04",
      "NSC 150528",
      "Stallimycinum",
      "Stallimycine",
      "Estalimicina",
      "Stallimicina",
      "Distamicina A",
      "DISTAMYCIN HYDROCHLORIDE",
      "NSC150528",
      "Dst-3",
      "AC1Q5M1Q",
      "AC1L1F7B",
      "DSSTox_RID_81018",
      "DSSTox_CID_25637",
      "DSSTox_GSID_45637",
      "DISTAMYCIN",
      "STALLIMYCIN",
      "SCHEMBL108585",
      "DTXSID9045637",
      "Cid_6602691",
      "UPBAOYRENQEPJO-UHFFFAOYSA-N",
      "ZINC3872327",
      "Tox21_110857",
      "6576-51-8 (Mono-Hydrochloride)",
      "NSC-82150",
      "CCG-36393",
      "BDBM50055659",
    ],
  },
  {
    primary_id: "D0BS6R",
    names: [
      "A-420983",
      "CHEMBL314627",
      "N-(4-{4-Amino-1-[4-(4-Methylpiperazin-1-Yl)-Trans-Cyclohexyl]-1h-Pyrazolo[3,4-D]pyrimidin-3-Yl}-2-Methoxyphenyl)-1-Methyl-1h-Indole-2-Carboxamide",
      "SCHEMBL6221049",
      "SCHEMBL5803074",
      "SCHEMBL19999345",
      "SCHEMBL14106773",
      "SCHEMBL16752208",
      "STVKLDUINKMZFE-RQNOJGIXSA-N",
      "STVKLDUINKMZFE-PSWAGMNNSA-N",
      "BDBM50145571",
      "L1G",
      "N-(4-(4-Amino-1-((Trans)-4-(4-Methylpiperazin-1-Yl)Cyclohexyl)-1H-Pyrazolo[3,4-D]pyrimidin-3-Yl)-2-Methoxyphenyl)-1-Methyl-1H-Indole-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0BS7G",
    names: ["L-759,633"],
  },
  {
    primary_id: "D0BS7T",
    names: [
      "VALERGEN-BT (Sublingual)",
      "Blomia Tropicalis Allergen Immunotherapy (Sublingual, Asthma), National Biopreparations Center",
    ],
  },
  {
    primary_id: "D0BS8I",
    names: ["Di-Substituted Piperidine Derivative 1"],
  },
  {
    primary_id: "D0BT0Z",
    names: ["PF-05241328"],
  },
  {
    primary_id: "D0BT1A",
    names: [
      "2-Pyridylethylamine",
      "2-(2-Aminoethyl)Pyridine",
      "2706-56-1",
      "2-(Pyridin-2-Yl)Ethanamine",
      "2-(2-Pyridyl)Ethylamine",
      "2-Pyridineethanamine",
      "2-Pyridin-2-Ylethanamine",
      "Demethylbetahistine",
      "Alpha-Pyridylethylamine",
      "2-(Pyridin-2-Yl)Ethan-1-Amine",
      "Lilly 04432",
      "Pyridine, 2-(2-Aminoethyl)-",
      "UNII-ATW1AH7OJ5",
      "Pyridineethanamine",
      "2-(2'-Aminoethyl)Pyridine",
      "NSC 71989",
      ".alpha.-Pyridylethylamine",
      "EINECS 220-295-1",
      "ATW1AH7OJ5",
      "2-AMINOETHYLPYRIDINE",
      "2-(2-Pyridinyl)Ethanamine",
      "2-(2-Aminoethyl)-Pyridine",
      "2-(2-Aminoethyl) Pyridine",
    ],
  },
  {
    primary_id: "D0BT1W",
    names: [
      "2-(P-Tolylthio)Naphthalene-1,4-Dione",
      "CHEMBL501551",
      "89478-03-5",
      "1,4-Naphthalenedione, 2-[(4-Methylphenyl)Thio]-",
      "ACMC-20lmns",
      "CTK2J5214",
      "DTXSID70567231",
      "BDBM50245831",
      "AKOS030617287",
      "2-(4-Methylphenylthio)-1,4-Naphthoquinone",
    ],
  },
  {
    primary_id: "D0BT2P",
    names: ["MBP(82-98)"],
  },
  {
    primary_id: "D0BT3Y",
    names: ["PMID25399762-Compound-Table 7-Coumaric Acid"],
  },
  {
    primary_id: "D0BT4P",
    names: ["TDI-0060"],
  },
  {
    primary_id: "D0BT5E",
    names: [
      "PT-314",
      "149485-07-4",
      "Thiourea, N-(2-Phenylethyl)-N'-(4-(Trifluoromethyl)-2-Thiazolyl)-",
      "PETT Analog 43",
      "Thiourea, N-(2-Phenylethyl)-N'-[4-(Trifluoromethyl)-2-Thiazolyl]-",
      "PETT CF3 Deriv.",
      "AC1MHDKU",
      "CHEMBL410771",
      "SCHEMBL6962348",
      "BDBM1876",
      "DTXSID70164284",
      "ZINC13744903",
      "1-Phenethyl-3-[4-(Trifluoromethyl)Thiazol-2-Yl]thiourea",
      "1-Phenethyl-3-[4-(Trifluoromethyl)-1,3-Thiazol-2-Yl]thiourea",
      "N-(2-Phenethyl)-N -(2-(4-(Trifluoromethyl)Thiazolyl))Thiourea",
    ],
  },
  {
    primary_id: "D0BT6B",
    names: ["CLT-009"],
  },
  {
    primary_id: "D0BT6G",
    names: ["Plovamer Acetate"],
  },
  {
    primary_id: "D0BT6H",
    names: ["IW-2143"],
  },
  {
    primary_id: "D0BT6Y",
    names: ["PMID28394193-Compound-41"],
  },
  {
    primary_id: "D0BT7R",
    names: ["ASP-4901"],
  },
  {
    primary_id: "D0BU0J",
    names: [
      "Azanidazole",
      "Nitromidine",
      "Azanidazol",
      "Azanidazolum",
      "Triclose",
      "62973-76-6",
      "UNII-YP2Y0DRX4S",
      "Azanidazolum [INN-Latin]",
      "Azanidazol [INN-Spanish]",
      "YP2Y0DRX4S",
      "BRN 4495385",
      "C10H10N6O2",
      "(E)-2-Amino-4-(2-(1-Methyl-5-Nitroimidazol-2-Yl)Vinyl)Pyrimidine",
      "4-((E)-2-(1-Methyl-5-Nitro-1H-Imidazol-2-Yl)-Ethenyl)-2-Pyrimidinamine",
      "Pyrimidine, 2-Amino-4-((E)-2-(1-Methyl-5-Nitro-1H-Imidazol-2-Yl)Ethenyl)-",
      "4-((E)-2-(1-Methyl-5-Nitro-1H-Imidazol-2-Yl)-Aethenyl)-2-Pyrimidinamin",
    ],
  },
  {
    primary_id: "D0BU1L",
    names: ["Indole-Based Analog 12"],
  },
  {
    primary_id: "D0BU2V",
    names: ["PMID30247903-Compound-General Structure22"],
  },
  {
    primary_id: "D0BU3L",
    names: ["Pyrrolidine Derivative 13"],
  },
  {
    primary_id: "D0BU5G",
    names: ["BiovaxlD"],
  },
  {
    primary_id: "D0BU5K",
    names: ["TINGENIN B"],
  },
  {
    primary_id: "D0BU5N",
    names: ["3-Chloro-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL396135", "SCHEMBL4297824"],
  },
  {
    primary_id: "D0BU5X",
    names: ["4-(Cyclobutylmethylthio)-2-Methoxybenzonitrile", "CHEMBL453244"],
  },
  {
    primary_id: "D0BU5Z",
    names: ["Argyrin B", "Argyrin A", "A-21459A", "A-21459B"],
  },
  {
    primary_id: "D0BU6J",
    names: [
      "2beta,3alpha-Dihydroxyurs-12-En-28-Oic Acid",
      "CHEMBL574993",
      "SCHEMBL4456541",
      "(2beta,3alpha)-2,3-Dihydroxy-Urs-12-En-28-Oic Acid",
      "BDBM50346702",
      "ZINC49708319",
      "856012-03-8",
      "2beta,3alpha-Dihydroxyurs-12-Ene-28-Oic Acid",
      "012A038",
      "Adbeta,3a)-2,3-Dihydroxy-Urs-12-En-28-Oic Acid",
    ],
  },
  {
    primary_id: "D0BU6K",
    names: ["Aryl Piperazine Derivative 9"],
  },
  {
    primary_id: "D0BU6T",
    names: [
      "CGP-62464",
      "CHEMBL169757",
      "5,7-Diphenyl-7H-Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "NSC606429",
      "AC1Q4VCQ",
      "5,7-Diphenyl-4-Aminopyrrolo[2,3-D]pyrimidine",
      "AC1L74EC",
      "SCHEMBL5936176",
      "ZINC1609260",
      "BDBM50088900",
      "NSC-606429",
      "121405-24-1",
      "FT-0761196",
      "5,7-Diphenylpyrrolo[2,3-D]pyrimidin-4-Amine",
      "5,7-Diphenyl-7H-Pyrrolo[2,3-D]pyrimidine-4-Amine",
    ],
  },
  {
    primary_id: "D0BU8L",
    names: ["Celmoleukin", "Celeuk (TN)"],
  },
  {
    primary_id: "D0BU8W",
    names: [
      "BMS Pyrazole Inhibitor 7f",
      "CHEMBL468927",
      "ASIS-P021",
      "GTPL7030",
      "SCHEMBL14565559",
      "ZINC40891759",
      "BDBM50258790",
      "(2S)-2-Amino-N-[(3-{5-[5-(1,3-Benzothiazol-7-Yl)-1,3,4-Oxadiazol-2-Yl]-3-(Trifluoromethyl)-1H-Pyrazol-1-Yl}phenyl)Methyl]propanamide",
      "(S)-2-Amino-N-(3-(5-(5-(Benzo[d]thiazol-7-Yl)-1,3,4-Oxadiazol-2-Yl)-3-(Trifluoromethyl)-1H-Pyrazol-1-Yl)Benzyl)Propanamide",
    ],
  },
  {
    primary_id: "D0BU9N",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 2"],
  },
  {
    primary_id: "D0BV0U",
    names: ["Lintuzumab Ac-226"],
  },
  {
    primary_id: "D0BV3B",
    names: ["SCH-602539"],
  },
  {
    primary_id: "D0BV3J",
    names: ["Macimorelin"],
  },
  {
    primary_id: "D0BV4M",
    names: ["PMID29671355-Compound-71b"],
  },
  {
    primary_id: "D0BV8A",
    names: ["7alpha-NHMe-Ginkgolide B", "7alpha-NHMe-GB"],
  },
  {
    primary_id: "D0BV8M",
    names: ["(D)-Phe-(L)-Phe-NH2", "CHEMBL592133", "AC1OEWDU", "BDBM50308390"],
  },
  {
    primary_id: "D0BV8Y",
    names: ["LU-53439", "153420-96-3"],
  },
  {
    primary_id: "D0BW0V",
    names: [
      "3-(1H-Indol-3-Yl)-N-Methyl-3-Phenylpropan-1-Amine",
      "CHEMBL487763",
      "SCHEMBL2074218",
      "HYXMIUGYZHSSJS-UHFFFAOYSA-N",
      "BDBM50275378",
    ],
  },
  {
    primary_id: "D0BW1H",
    names: ["2-HEPTYL-4-HYDROXY QUINOLINE N-OXIDE"],
  },
  {
    primary_id: "D0BW2B",
    names: ["PMID30280939-Compound-WO2005070959"],
  },
  {
    primary_id: "D0BW2C",
    names: ["NNC-26-0194", "171094-42-1"],
  },
  {
    primary_id: "D0BW2N",
    names: ["GZ402674"],
  },
  {
    primary_id: "D0BW3P",
    names: ["Aerovant"],
  },
  {
    primary_id: "D0BW4G",
    names: ["PDGF Receptor Tyrosine Kinase Inhibitor III"],
  },
  {
    primary_id: "D0BW4W",
    names: ["Sutezolid"],
  },
  {
    primary_id: "D0BW6F",
    names: ["GS-9876"],
  },
  {
    primary_id: "D0BW7P",
    names: ["SM-131", "CHEMBL514738"],
  },
  {
    primary_id: "D0BW9R",
    names: ["BMS-181184"],
  },
  {
    primary_id: "D0BW9V",
    names: [
      "[125I]LSD",
      "[125I]N,N-Diethyllysergamide",
      "[125I]lysergamid",
      "[125I]lysergic Acid Diethylamide",
      "[125I]lysergide",
    ],
  },
  {
    primary_id: "D0BX0V",
    names: ["Sel-K1", "Anti-PSGL-1 Antibodies (Inflammation), Selexys"],
  },
  {
    primary_id: "D0BX1S",
    names: ["CD-832"],
  },
  {
    primary_id: "D0BX5L",
    names: ["LZM009"],
  },
  {
    primary_id: "D0BX6Z",
    names: ["PMID30247903-Compound-General Structure20"],
  },
  {
    primary_id: "D0BX7G",
    names: ["Anti-PrP Mabs", "Anti-PrP MAbs (ProMIS, Cancer)"],
  },
  {
    primary_id: "D0BX8E",
    names: ["Helicase Inhibitors"],
  },
  {
    primary_id: "D0BY0Y",
    names: ["227Th-Trastuzumab (Cancer), Algeta"],
  },
  {
    primary_id: "D0BY1R",
    names: ["PMID28092474-Compound-34b"],
  },
  {
    primary_id: "D0BY2H",
    names: ["Peptide Analog 50"],
  },
  {
    primary_id: "D0BY6E",
    names: [
      "4-Hydroxy-8-Nitro-3-Phenylquinolin-2(1H)-One",
      "CHEMBL377646",
      "SCHEMBL4456699",
      "BDBM50189477",
      "2(1H)-Quinolinone, 4-Hydroxy-8-Nitro-3-Phenyl-",
    ],
  },
  {
    primary_id: "D0BY6W",
    names: ["PSMA VRP Therapeutic Vaccine"],
  },
  {
    primary_id: "D0BY9R",
    names: ["ISIS 2918"],
  },
  {
    primary_id: "D0BY9X",
    names: ["A778317", "A-778317", "A 778317"],
  },
  {
    primary_id: "D0BZ0N",
    names: ["Benzo[d]oxazol-2(3H)-One Derivative 2"],
  },
  {
    primary_id: "D0BZ0T",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 16"],
  },
  {
    primary_id: "D0BZ1D",
    names: ["CGX1321"],
  },
  {
    primary_id: "D0BZ1L",
    names: ["PS386113"],
  },
  {
    primary_id: "D0BZ1Q",
    names: ["CRx-401"],
  },
  {
    primary_id: "D0BZ3S",
    names: ["Phenyl-(9-Phenyl-9H-Purin-6-Yl)-Amine", "CHEMBL96015", "6-Phenylamino-9-Phenyl-9H-Purine", "ZINC13824421"],
  },
  {
    primary_id: "D0BZ7V",
    names: ["Matuzumab"],
  },
  {
    primary_id: "D0BZ7W",
    names: ["Tolterodine"],
  },
  {
    primary_id: "D0C0AA",
    names: ["DTS-108"],
  },
  {
    primary_id: "D0C0AC",
    names: ["GIT27-NO"],
  },
  {
    primary_id: "D0C0AS",
    names: ["PD-171729"],
  },
  {
    primary_id: "D0C0BE",
    names: ["ST-148"],
  },
  {
    primary_id: "D0C0BP",
    names: ["PMID28766366-Compound-Scheme4Upper"],
  },
  {
    primary_id: "D0C0CE",
    names: [
      "Activated Recombinant FVII-Albumin Fusion Protein",
      "CSL-689",
      "Activated Recombinant FVII-Albumin Fusion Protein (Hemophilia)",
      "Activated Recombinant FVII-Albumin Fusion Protein (Hemophilia), CSL Behring",
      "RVIIa-FP (Hemophilia), CSL Behring",
      "Recombinant Factor VIIa/Human Albumin Fusion Protein (Hemophilia), CSL Behring",
    ],
  },
  {
    primary_id: "D0C0CF",
    names: ["CRD-103"],
  },
  {
    primary_id: "D0C0DM",
    names: [
      "5,5'-Methylenebis(3,4-Dibromobenzene-1,2-Diol)",
      "CHEMBL253690",
      "65487-76-5",
      "CTK1J6652",
      "DTXSID90466393",
      "BDBM50329688",
      "3,4-Dibromo-5-[(2,3-Dibromo-4,5-Dihydroxy-Phenyl)Methyl]benzene-1,2-Diol",
      "5,5'-Methylenebis(3,4-Dibromopyrocatechol)",
      "1,2-Benzenediol, 4,4'-Methylenebis[5,6-Dibromo-",
      "5,5''-Methylenebis(3,4-Dibromobenzene-1,2-Diol)",
      "2,2',3,3'-Tetrabromo-4,4',5,5'-Tetrahydroxy-Diphenylmethane",
    ],
  },
  {
    primary_id: "D0C0DY",
    names: ["Influenza A Virus H7N9 Vaccine"],
  },
  {
    primary_id: "D0C0EE",
    names: ["ISIS 9005"],
  },
  {
    primary_id: "D0C0EO",
    names: [
      "CP-105696",
      "UNII-Z7354TW4BM",
      "CP 105696",
      "CP-105,696",
      "CP105696",
      "CHEMBL51770",
      "Z7354TW4BM",
      "158081-99-3",
      "Pfizer 105696",
      "1-(3-(4-Phenylbenzyl)-4-Hydroxychroman-7-Yl)Cyclopentane-1-Carboxylic Acid",
      "DSSTox_RID_82258",
      "DSSTox_CID_27308",
      "DSSTox_GSID_47308",
      "Cyclopentanecarboxylic Acid, 1-((3S,4R)-3-((1,1'-Biphenyl)-4-Ylmethyl)-3,4-Dihydro-4-Hydroxy-2H-1-Benzopyran-7-Yl)-",
      "SCHEMBL1898224",
      "GTPL3368",
      "DTXSID7047308",
      "Tox21_300468",
      "BDBM50037218",
      "1-[(3S,4R)-4-Hydroxy-3-[(4-Phenylphenyl)Methyl]chroman-7-Yl]cyclopentane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0C0EV",
    names: [
      "ChimeriVax-West Nile",
      "ChimeriVax-WN01",
      "ChimeriVax-WN02",
      "West Nile Virus Encephalitis Vaccine, Acambis",
      "ChimeriVax (West Nile Virus), Acambis",
      "ChimeriVax (West Nile Virus), Acambis/Sanofi Pasteur",
    ],
  },
  {
    primary_id: "D0C0EX",
    names: ["SDZ-MTH-958"],
  },
  {
    primary_id: "D0C0FA",
    names: ["FRAX-120"],
  },
  {
    primary_id: "D0C0FG",
    names: ["NRT-300"],
  },
  {
    primary_id: "D0C0FH",
    names: ["2-Cyclohex-1-Enylethynyl-Pyridine", "CHEMBL196643", "2-(1-Cyclohexenyl)Ethynylpyridine"],
  },
  {
    primary_id: "D0C0FK",
    names: ["3-Amino-2-(Benzylideneamino)-5-Mercaptophenol", "CHEMBL468589"],
  },
  {
    primary_id: "D0C0FO",
    names: ["Plant-Based Oncology Candidate"],
  },
  {
    primary_id: "D0C0FU",
    names: ["Methyl 2-(4-Chloro-2-Cyclohexylphenoxy)Acetate", "CHEMBL247131", "BDBM50213924"],
  },
  {
    primary_id: "D0C0GL",
    names: [
      "1-(3-Bromophenyl)-2-(Tert-Butylamino)Propan-1-One",
      "CHEMBL565396",
      "AC1MHR9I",
      "SCHEMBL769683",
      "BDBM50302930",
    ],
  },
  {
    primary_id: "D0C0HF",
    names: ["GM-MD-01"],
  },
  {
    primary_id: "D0C0HN",
    names: [
      "6-Chloro-5-(2-Thienyl)Pyrimidine-2,4(1H,3H)-Dione",
      "1000680-81-8",
      "CHEMBL397364",
      "BDBM20069",
      "5-Substituted-6-Chlorouracil, 10e",
    ],
  },
  {
    primary_id: "D0C0HP",
    names: ["IN-3"],
  },
  {
    primary_id: "D0C0JT",
    names: ["Nimesulide"],
  },
  {
    primary_id: "D0C0KI",
    names: ["Aminoazetidine Derivative 7"],
  },
  {
    primary_id: "D0C0KJ",
    names: ["LTE4"],
  },
  {
    primary_id: "D0C0KP",
    names: ["AllerB"],
  },
  {
    primary_id: "D0C0LG",
    names: ["N-(1-(1-Phenylethyl)Piperidin-4-Yl)-2-Naphthamide", "CHEMBL229023"],
  },
  {
    primary_id: "D0C0LO",
    names: ["IMGN529"],
  },
  {
    primary_id: "D0C0LQ",
    names: ["2-PAM Derivative 1"],
  },
  {
    primary_id: "D0C0ME",
    names: ["RO-26-2853"],
  },
  {
    primary_id: "D0C0MH",
    names: ["BHH-0492", "BH-0492"],
  },
  {
    primary_id: "D0C0MN",
    names: ["Irbesartan/ Trichlormethiazide Fixed-Dose Combination"],
  },
  {
    primary_id: "D0C0MP",
    names: [
      "2-(3,4-Dimethoxyphenyl)-4,5-Dihydro-1H-Imidazole",
      "CHEMBL503049",
      "192988-59-3",
      "SCHEMBL9575182",
      "DTXSID80571566",
      "BDBM50265991",
      "ZINC34530543",
      "AKOS022491775",
      "2-(3,4-Dimethoxyphenyl)-2-Imidazoline",
    ],
  },
  {
    primary_id: "D0C0NH",
    names: ["Avastin+/-Tarceva"],
  },
  {
    primary_id: "D0C0NS",
    names: [
      "4ALPHA-(HYDROXYMETHYL)-4ALPHA-DEMETHYLTERRITREM B",
      "CHEMBL464537",
      "4alpha-(Hydroxymethyl)-4alpha-Demethylterritrem B",
      "BDBM50292394",
      "4beta-Hyroxymethyl-4beta-Demethylterritrem B",
    ],
  },
  {
    primary_id: "D0C0OB",
    names: ["LOU064"],
  },
  {
    primary_id: "D0C0ON",
    names: ["Human Embryonic Stem Cell-Derived Osteoblasts"],
  },
  {
    primary_id: "D0C0OT",
    names: ["1-(3,4-Dihydroxy-2-Nitrophenyl)-2-Phenylethanone", "CHEMBL197115", "SCHEMBL7431618"],
  },
  {
    primary_id: "D0C0PK",
    names: ["Cyclopropylamine Derivative 7"],
  },
  {
    primary_id: "D0C0PL",
    names: [
      "PT-115",
      "149488-76-6",
      "Thiourea, N-(5-Bromo-2-Pyridinyl)-N'-(2-(2,6-Difluoro-3-Methoxyphenyl)Ethyl)-",
      "1-(5-Bromopyridin-2-Yl)-3-[2-(2,6-Difluoro-3-Methoxyphenyl)Ethyl]thiourea",
      "N-(2-(2,6-Difluoro-3-Methoxyphenethyl))-N'-(2-(5-Bromopyridyl))Thiourea",
      "N-[2-(2,6-Difluoro-3-Methoxyphenethyl)]-N'-[2-(5-Bromopyridyl)]thiourea",
      "Thiourea, N-(5-Bromo-2-Pyridinyl)-N'-[2-(2,6-Difluoro-3-Methoxyphenyl)Ethyl]-",
      "AC1MHDLW",
      "LY300046HCl Analog 7",
      "SCHEMBL6963627",
      "CHEMBL398890",
      "BDBM2123",
      "DTXSID50164323",
    ],
  },
  {
    primary_id: "D0C0QK",
    names: ["LIM-0744"],
  },
  {
    primary_id: "D0C0QT",
    names: ["PPI-461"],
  },
  {
    primary_id: "D0C0SK",
    names: [
      "Pyrimethamine",
      "Chloridin",
      "Chloridine",
      "Chloridyn",
      "Darachlor",
      "Daraclor",
      "Darapram",
      "Daraprim",
      "Daraprime",
      "Daraprin",
      "Diaminopyritamin",
      "Erbaprelina",
      "Ethylpyrimidine",
      "Khloridin",
      "Malacid",
      "Malocid",
      "Malocide",
      "Maloprim",
      "Pirimecidan",
      "Pirimetamin",
      "Pirimetamina",
      "Primethamine",
      "Pyremethamine",
      "Pyrimethamin",
      "Pyrimethaminum",
      "Tindurin",
      "Tindurine",
      "Tinduring",
      "Aventis Brand Of Pyrimethamine",
      "Glaxo Wellcome Brand Of Pyrimethamine",
      "GlaxoSmithKline Brand Of Pyrimethamine",
      "M Alocid",
      "Pirimetamina [Spanish]",
      "Pyrimethamine Hcl",
      "Wellcome Brand Of Pyrimethamine",
      "BW 5063",
      "RP 4753",
      "WR 2978",
      "AZT + Pyrimethamine Combination",
      "BW 50-63",
      "Daraprim (TN)",
      "EXR-101",
      "Lactoferrin B & Pyrimethamine",
      "Lactoferrin H & Pyrimethamine",
      "Pirimetamina [INN-Spanish]",
      "Pyrimethamine (Pyr)",
      "Pyrimethaminum [INN-Latin]",
      "TCMDC-123831",
      "TCMDC-125860",
      "CRL-8131 & Pyrimethamine",
      "CRL-8142 & Pyrimethamine",
      "Fansidar (Pyrimethamine/Sulfadoxine)",
      "Pyrimethamine (JAN/USP/INN)",
      "Pyrimethamine [USAN:INN:BAN:JAN]",
      "2,4-Diamino-5-(4-Chlorophenyl)-6-Ethylpyrimidine",
      "2,4-Diamino-5-(P-Chlorophenyl)-6-Ethylpyrimidine",
      "2,4-Diamino-5-Chlorophenyl-6-Ethylpyrimidine",
      "5-(4'-Chlorophenyl)-2,4-Diamino-6-Ethylpyrimidine",
      "5-(4-CHLORO-PHENYL)-6-ETHYL-PYRIMIDINE-2,4-DIAMINE",
      "5-(4-CHLOROPHENYL)-6-ETHYL-2,4-PYRIMIDINEDIAMINE",
      "5-(4-Chlorophenyl)-6-Ethyl-2,4-Diaminopyrimidine",
      "5-(4-Chlorophenyl)-6-Ethyl-2,4-Pyrimidi Nediamine",
      "5-(4-Chlorophenyl)-2,4-Diamino-6-Ethylpyrimidine",
      "5-(4-Chlorophenyl)-6-Ethylpyrimidine-2,4-Diamine",
      "5-(P-Chlorophenyl)-6-Ethyl-2,4-Diaminopyrimidine",
      "5-[4-Chlorophenyl]-6-Ethyl-2,4-Pyrimidinediamine",
    ],
  },
  {
    primary_id: "D0C0SX",
    names: ["SCH-900353"],
  },
  {
    primary_id: "D0C0SZ",
    names: ["PRT6207"],
  },
  {
    primary_id: "D0C0TI",
    names: ["2,7-Bis[3-(Piperazino)Propionamido]anthraquinone", "SCHEMBL4302435", "CHEMBL520095"],
  },
  {
    primary_id: "D0C0TJ",
    names: [
      "PBI-230",
      "Botulinium Neurotoxin A Neutralizing Antibodies (Iintoxication), Planet Biotechnology",
      "BoNT/A Neutralizing Abs (Intoxication), Planet Biotechnology",
    ],
  },
  {
    primary_id: "D0C0TN",
    names: ["NSC-66811"],
  },
  {
    primary_id: "D0C0TT",
    names: ["MLN0128"],
  },
  {
    primary_id: "D0C0TU",
    names: ["SI-613"],
  },
  {
    primary_id: "D0C0TV",
    names: ["Autologous Stem Cell-Based Cancer Immunotherapy"],
  },
  {
    primary_id: "D0C0UB",
    names: [
      "5-Methyl-2-(Pyridin-2-Yl)-1H-Benzo[d]imidazole",
      "CHEMBL352049",
      "5-Methyl-2-Pyridin-2-Yl-1H-Benzoimidazole",
      "7471/12/7",
      "NSC403547",
      "AC1L83HW",
      "TimTec1_008296",
      "SCHEMBL124267",
      "SCHEMBL12119033",
      "DTXSID20323304",
      "HMS1557J02",
      "CCG-24525",
      "BDBM50180733",
      "ZINC12481420",
      "AKOS002999038",
      "AKOS000636609",
      "NSC-403547",
      "BAS 06347449",
      "6-Methyl-2-Pyridin-2-Yl-1H-Benzimidazole",
      "6-Methyl-2-(2-Pyridyl)-1H-Benzimidazole",
      "KB-249126",
      "US8748618, LD-2-11",
      "6-Methyl-2-Pyridin-2-Yl-1h-Benzo[d]imidazole",
    ],
  },
  {
    primary_id: "D0C0UM",
    names: ["(4-Dipropylamino-Cyclohexylidene)-Acetonitrile", "CHEMBL350161"],
  },
  {
    primary_id: "D0C0VP",
    names: ["Cyclostellettamine Derivative", "CHEMBL88332"],
  },
  {
    primary_id: "D0C0VW",
    names: ["Anticonvulsants"],
  },
  {
    primary_id: "D0C0WE",
    names: ["FdG"],
  },
  {
    primary_id: "D0C0WI",
    names: ["PNB-05"],
  },
  {
    primary_id: "D0C0XU",
    names: ["N-N-Heptyl-7-Methoxytacrine Hydrochloride", "CHEMBL1257767"],
  },
  {
    primary_id: "D0C0YG",
    names: ["Youerjian"],
  },
  {
    primary_id: "D0C0YR",
    names: ["UltraBAFF", "4-Trimer SP-D-BAFF (Adjuvant), Multimeric Biotherapeutics"],
  },
  {
    primary_id: "D0C0ZC",
    names: ["AZ-628"],
  },
  {
    primary_id: "D0C0ZN",
    names: ["VXA-A1.1"],
  },
  {
    primary_id: "D0C1AN",
    names: [
      "H-Tyr-D-Ala-(R Or S)Atc-Asp-Val-Val-Gly-NH2",
      "CHEMBL124941",
      "BDBM50001459",
      "3-[(2-{2-[2-Amino-3-(4-Hydroxy-Phenyl)-Propionylamino]-Propionylamino}-1,2,3,4-Tetrahydro-Naphthalene-2-Carbonyl)-Amino]-N-{1-[1-(Carbamoylmethyl-Carbamoyl)-2-Methyl-Propylcarbamoyl]-2-Methyl-Propyl}-Succinamic Acid",
    ],
  },
  {
    primary_id: "D0C1AU",
    names: [
      "1,6-Bis(4'-Nitrophenyl)-Hexa-1,3,5-Triene",
      "CHEMBL205899",
      "1,6-Bis(4-Nitrophenyl)-1,3,5-Hexatriene",
      "AC1O1Q5Y",
      "SCHEMBL9750443",
      "ZINC3080662",
      "BDBM50185989",
      "AKOS024339803",
      "1,6-Bis(4''-Nitrophenyl)-Hexa-1,3,5-Triene",
      "(1E,3E,5E)-1,6-Bis(4-Nitrophenyl)-1,3,5-Hexatriene",
    ],
  },
  {
    primary_id: "D0C1AY",
    names: ["GW-796406"],
  },
  {
    primary_id: "D0C1BE",
    names: ["6,7-Dimethoxy-3-P-Tolyl-Quinoline", "CHEMBL67219", "ZINC13737715"],
  },
  {
    primary_id: "D0C1BG",
    names: ["Lactitol"],
  },
  {
    primary_id: "D0C1BL",
    names: ["Mab 37.1"],
  },
  {
    primary_id: "D0C1CA",
    names: ["ISIS 9007"],
  },
  {
    primary_id: "D0C1CU",
    names: ["SUN-9221"],
  },
  {
    primary_id: "D0C1CV",
    names: ["Pyridine-2,4-Dicarboxylic Acid Derivative 3"],
  },
  {
    primary_id: "D0C1DG",
    names: ["2-(Carbazole-9-Sulfonyl)-Benzoic Acid", "CHEMBL364141"],
  },
  {
    primary_id: "D0C1DQ",
    names: ["ASN-11124542", "AC1O5VPR", "ASN 11124542"],
  },
  {
    primary_id: "D0C1EF",
    names: [
      "5-(4-Methoxyphenoxy)-2,4-Quinazolinediamine",
      "5-(4-METHOXYPHENOXY)-2,4-QUINAZOLINEDIAMINE",
      "5-(4-Methoxyphenoxy)Quinazoline-2,4-Diamine",
      "AC1L9KGI",
      "SCHEMBL2128429",
    ],
  },
  {
    primary_id: "D0C1EO",
    names: ["Melanoma Vaccine (ALVAC)"],
  },
  {
    primary_id: "D0C1EP",
    names: ["N-(Biphenyl-3-Yl)Benzo[d]isoxazol-3-Amine", "CHEMBL565950"],
  },
  {
    primary_id: "D0C1EQ",
    names: ["2-(2-(Biphenyl-4-Yl)Ethylthio)Acetic Acid", "CHEMBL572980"],
  },
  {
    primary_id: "D0C1FY",
    names: [
      "D-Glucose",
      "D-Glucopyranose",
      "Glucopyranose",
      "D-Glc",
      "D-Glcp",
      "Glucodin",
      "Meritose",
      "Clintose L",
      "Roferose ST",
      "CPC Hydrate",
      "Clearsweet 95",
      "2280-44-6",
      "Staleydex 95M",
      "CHEBI:4167",
      "Glucopyranoside",
      "D-Glucopyranoside",
      "Glc",
      "(3R,4S,5S,6R)-6-(Hydroxymethyl)Oxane-2,3,4,5-Tetrol",
      "Glucopyranose, D-",
      "DSSTox_CID_2910",
      "Glucose Solution",
      "D-Glucopyranose, Anhydrous",
      "Glc-Ring",
      "D-Glucose-Ring",
      "Cartose Cerelose",
      "Staleydex 130",
      "Glucose 40",
      "EINECS 218-914-5",
      "Meritose 200",
      "Glc-OH",
    ],
  },
  {
    primary_id: "D0C1GE",
    names: ["Fusolin"],
  },
  {
    primary_id: "D0C1GO",
    names: ["SC-558", "CHEMBL602951", "BDBM50308509"],
  },
  {
    primary_id: "D0C1HS",
    names: ["Dihydropyrido Pyrazinone Compound 1"],
  },
  {
    primary_id: "D0C1IG",
    names: ["CUDC427"],
  },
  {
    primary_id: "D0C1IW",
    names: [
      "Ergonovine",
      "Basergin",
      "Ergobasin",
      "Ergobasine",
      "Ergoklinine",
      "Ergometrin",
      "Ergometrina",
      "Ergometrine",
      "Ergometrinum",
      "Ergostetrine",
      "Ergotocine",
      "Ergotrate",
      "Margonovine",
      "Neofemergen",
      "Secacornin",
      "Secometrin",
      "Ergotrate Maleate",
      "Lysergic Acid Propanolamide",
      "Ergometrina [INN-Spanish]",
      "Ergometrine (INN)",
      "Ergometrine [INN:BAN]",
      "Ergometrinum[INN-Latin]",
      "D-Lysergic Acid 1-Hydroxymethylethylamide",
      "D-Lysergic Acid-L-Propanolamide",
      "L-Lysergic-L(Beta-Hydroxyisopropylamide)",
      "N-(1-(Hydroxymethyl)Ethyl)-D-Lysergamide",
      "N-(Alpha-(Hydroxymethyl)Ethyl)-D-Lysergamide",
      "N-(2-Hydroxy-1-Methylethyl)-D(+)-Lysergamide",
      "N-[(2S)-1-Hydroxypropan-2-Yl]-6-Methyl-9,10-Didehydroergoline-8beta-Carboxamide",
      "[8beta(S)]-9,10-Didehydro-N-(2-Hydroxy-1-Methylethyl)-6-Methylergoline-8-Carboxamide",
      "9,10-Didehydro-N-(2-Hydroxy-1-Methylethyl)-6-Methylergoline-8beta(S)-Carboxamide",
      "9,10-Didehydro-N-(Alpha-(Hydroxymethyl)Ethyl)-6-Methylergoline-8-Beta-Carboxamide",
    ],
  },
  {
    primary_id: "D0C1JP",
    names: ["AEG35156"],
  },
  {
    primary_id: "D0C1JV",
    names: ["QGC-001"],
  },
  {
    primary_id: "D0C1LF",
    names: ["VLD-01"],
  },
  {
    primary_id: "D0C1LH",
    names: ["DS-8273"],
  },
  {
    primary_id: "D0C1LI",
    names: [
      "ASS234",
      "CHEMBL1929421",
      "GTPL7369",
      "SCHEMBL6847307",
      "ASS-234",
      "BDBM153654",
      "BDBM50359391",
      "US8999994, 6",
      "N-[[5-[3-(1-Benzylpiperidin-4-Yl)Propoxy]-1-Methylindol-2-Yl]methyl]-N-Methylprop-2-Yn-1-Amine",
    ],
  },
  {
    primary_id: "D0C1LW",
    names: ["5,8-Hydroxy-Naphthoquinone"],
  },
  {
    primary_id: "D0C1MB",
    names: ["FR-180102"],
  },
  {
    primary_id: "D0C1MZ",
    names: ["PMID25666693-Compound-10"],
  },
  {
    primary_id: "D0C1NA",
    names: ["ISIS 31982"],
  },
  {
    primary_id: "D0C1NP",
    names: ["PMID22595175C4g"],
  },
  {
    primary_id: "D0C1NU",
    names: ["N-(3,5-Dichlorophenyl)-2-Methyl-3-Nitrobenzamide"],
  },
  {
    primary_id: "D0C1OB",
    names: [
      "Pyrrolidin-(2Z)-Ylideneamine",
      "3,4-Dihydro-2H-Pyrrol-5-Amine",
      "872-34-4",
      "Pyrrolidin-2-Imine",
      "4,5-DIHYDRO-3H-PYRROL-2-YLAMINE",
      "CHEMBL161318",
      "2-Amino-1-Pyrroline",
      "Iminopyrrolidine",
      "2-Iminopyrrolidine",
      "2-Imino Pyrrolidine",
      "2-Imino-Pyrrolidine",
      "2-Amino-1-Pyrrolin",
      "PYR040",
      "CTK5F8124",
      "DTXSID60509645",
      "NJBMZYSKLWQXLJ-UHFFFAOYSA-N",
      "MolPort-004-968-713",
      "2-Amino-4,5-Dihydro-3H-Pyrrole",
      "2H-Pyrrol-5-Amine,3,4-Dihydro-",
      "BDBM50049251",
      "BBL031757",
      "ZINC12946088",
      "STL283815",
      "ANW-72957",
      "3788AC",
      "2H-Pyrrol-5-Amine, 3,4-Dihydro-",
      "AKOS009098397",
      "AKOS002684118",
    ],
  },
  {
    primary_id: "D0C1OC",
    names: [
      "2,3,17BETA-TRIHYDROXY-1,3,5(10)-ESTRATRIENE",
      "AC1L1TL4",
      "NCIOpen2_002733",
      "2-Hydroxyestradiol-17 Alpha",
      "TPC-S001",
      "SCHEMBL13123058",
      "DTXSID20859350",
      "LS-64784",
      "Estra-1(10),2,4-Triene-2,3,17-Triol",
      "15-Methyltetracyclo[8.7.0.0^{2,7}.0^{11,15}]heptadeca-2(7),3,5-Triene-4,5,14-Triol",
      "13-Methyl-6,7,8,9,11,12,14,15,16,17-Decahydrocyclopenta[a]phenanthrene-2,3,17-Triol",
    ],
  },
  {
    primary_id: "D0C1OJ",
    names: ["Debio-0930"],
  },
  {
    primary_id: "D0C1OZ",
    names: ["Ro092210"],
  },
  {
    primary_id: "D0C1PB",
    names: ["PMID28454500-Compound-41"],
  },
  {
    primary_id: "D0C1PC",
    names: [
      "3-Fluoro-2-(Phosphonooxy)Propanoic Acid",
      "3-FLUORO-2-(PHOSPHONOOXY)PROPANOIC ACID",
      "AC1NRB38",
      "DB03113",
      "(2R)-3-Fluoro-2-Phosphonooxypropanoic Acid",
      "(2R)-3-Fluoro-2-(Phosphonooxy)Propanoic Acid",
    ],
  },
  {
    primary_id: "D0C1PS",
    names: [
      "9-(3-Hydroxypropyl)-9H-Adenine",
      "711-64-8",
      "9H-Purine-9-Propanol, 6-Amino-",
      "CHEMBL445974",
      "3-(6-Amino-9H-Purin-9-Yl)Propan-1-Ol",
      "9-(3-Hydroxypropyl)Adenine",
      "Oprea1_108729",
      "9-(3'-Hydroxypropyl)Adenine",
      "SCHEMBL1635576",
      "CTK2H3970",
      "DTXSID10500041",
      "MolPort-014-160-140",
      "ZINC5391161",
      "BDBM50257052",
      "3-(6-Aminopurin-9-Yl)Propan-1-Ol",
      "AKOS010941287",
      "3-(6-Amino-9H-Purine-9-Yl)-1-Propanol",
    ],
  },
  {
    primary_id: "D0C1PY",
    names: ["Potassium Acetate"],
  },
  {
    primary_id: "D0C1QB",
    names: [
      "LOSIGAMONE",
      "ADD-137022",
      "AO-33",
      "Losigamone",
      "(R*,S*)-Rac-5-[(2-Chlorophenyl)Hydroxymethyl]-4-Methoxy-2(5H)-Furanone",
    ],
  },
  {
    primary_id: "D0C1QS",
    names: ["Iprodione"],
  },
  {
    primary_id: "D0C1QZ",
    names: [
      "Choline C-11",
      "CHOLINE C-11",
      "Choline C11",
      "(11)C-Choline",
      "UNII-M4AS4XGD4Q",
      "M4AS4XGD4Q",
      "94793-58-5",
      "[N-Methyl-(11)C]choline",
      "AC1L9NEL",
      "Choline C-11 (TN)",
      "SCHEMBL14330721",
      "Ethanaminium, 2-Hydroxy-N,N-Dimethyl-N-(Methyl-11C)-",
      "CHEBI:72322",
      "DTXSID10241624",
      "MOLI001374",
      "MOLI000071",
      "DB09563",
    ],
  },
  {
    primary_id: "D0C1RN",
    names: ["Japanese Cedar Peptide Allergy Desensitization Agent"],
  },
  {
    primary_id: "D0C1RY",
    names: ["GFPEGG", "CHEMBL261621"],
  },
  {
    primary_id: "D0C1SE",
    names: [
      "1,2,3,4-Tetrahydro-Pyrazino[1,2-A]indole",
      "1,2,3,4-Tetrahydropyrazino[1,2-A]indole",
      "41838-39-5",
      "CHEMBL6393",
      "A1-02013",
      "Pyrazino[1,2-A]indole, 1,2,3,4-Tetrahydro-",
      "SCHEMBL2356686",
      "AEIDURNZWCNQIM-UHFFFAOYSA-N",
      "MolPort-022-905-760",
      "2,3-Dihydropyrazino[1,2-A]indole",
      "ZINC13586839",
      "STL290420",
      "BDBM50108307",
      "AKOS015996057",
      "FCH1167619",
      "OR70091",
      "AJ-64212",
      "AX8245433",
      "KB-216229",
      "FT-0682685",
      "BB 0256492",
    ],
  },
  {
    primary_id: "D0C1SF",
    names: [
      "Griseofulvin",
      "Amudane",
      "Delmofulvina",
      "Fulcin",
      "Fulcine",
      "Fulvicin",
      "FulvicinUF",
      "Fulvina",
      "Fulvinil",
      "Fulvistatin",
      "Fungivin",
      "Greosin;Gresfeed",
      "Gricin",
      "Grifulin",
      "Grifulvin",
      "GrisPEG",
      "Grisactin",
      "Griscofulvin",
      "Grisefuline",
      "Griseo",
      "Griseofulvina",
      "Griseofulvine",
      "Griseofulvinum",
      "Griseomix",
      "Griseostatin",
      "Grisetin",
      "Grisofulvin",
      "Grisovin",
      "Grizeofulvin",
      "Grysio",
      "Guservin",
      "Lamoryl",
      "Likuden",
      "Likunden",
      "Murfulvin",
      "Poncyl",
      "Spirofulvin",
      "Sporostatin",
      "Xuanjing",
      "Curling Fact Or",
      "Curling Factor",
      "Fulvican Grisactin",
      "Fulvicin U F",
      "Grifulvin V",
      "Gris PEG",
      "Grisactin Ultra",
      "Grise Ostatin",
      "Griseofulvin Forte",
      "Grisovin FP",
      "Interacts With Polymerized Microtubules And Associated Proteins",
      "Sporostatin Xan",
      "Biogrisin-FP",
      "FULVICIN P/G 165",
      "FULVICIN P/G 330;Fulvicin Bolus (Veterinary)",
      "Fulvicin P/G",
      "Fulvicin U/F",
      "Fulvidex (Veterinary)",
      "GRISEOFULVIN, MICROCRYSTALLINE",
      "GRISEOFULVIN, ULTRAMICROCRYSTALLINE",
      "Gris-PEG",
      "Griseofulvin And Alpha-IFN",
      "Griseofulvin-Forte",
      "Griseofulvina [INN-Spanish]",
      "Griseofulvine [INN-French]",
      "Griseofulvinum [INN-Latin]",
      "Grisovin (TN)",
      "NP-002466",
      "Neo-Fulcin",
      "ULTRAGRIS-165",
      "ULTRAGRIS-330",
      "USAF SC-2",
      "Fulvicin-P/G",
      "Fulvicin-U-F",
      "Fulvicin-U/F",
      "Fulvicin-U/F (Veterinary)",
      "Griseofulvin [USAN:INN:BAN:JAN]",
      "(+)-Griseofulvin",
      "7-Chloro-4,6,2'-Trimethoxy-6'-Methylgris-2'-En-3,4'-Dione",
    ],
  },
  {
    primary_id: "D0C1SI",
    names: ["MK-386", "CHEMBL25448"],
  },
  {
    primary_id: "D0C1TH",
    names: ["Aryl Cyclopropylamine Derivative 4"],
  },
  {
    primary_id: "D0C1TJ",
    names: ["HN-10200"],
  },
  {
    primary_id: "D0C1TO",
    names: ["EQ-818-1"],
  },
  {
    primary_id: "D0C1TR",
    names: ["ASP9853"],
  },
  {
    primary_id: "D0C1TS",
    names: ["CVS-2359", "CHEMBL431292", "BDBM50111730"],
  },
  {
    primary_id: "D0C1TT",
    names: ["SPI-017"],
  },
  {
    primary_id: "D0C1UA",
    names: ["Tacrine-Dihydropyridine Hybrid Derivative 1"],
  },
  {
    primary_id: "D0C1UF",
    names: ["MDX-1100"],
  },
  {
    primary_id: "D0C1UM",
    names: [
      "Tylophorinidine",
      "AC1LCEK3",
      "3,7-Dimethoxy-9,11,12,13,13a,14-Hexahydrophenanthro[10,9-F]indolizine-6,14-Diol",
    ],
  },
  {
    primary_id: "D0C1UP",
    names: [
      "SX-ARPC",
      "ADR-L09",
      "Androgen Receptor Antagonists (Small Peptide Mimetic, Androgen Independent Prostate Cancer),Serometrix",
    ],
  },
  {
    primary_id: "D0C1US",
    names: ["FGGFTGARKSARKLWNQ"],
  },
  {
    primary_id: "D0C1VA",
    names: [
      "6-(Indan-5-Ylamino)-1H-Pyrimidine-2,4-Dione",
      "CHEMBL54950",
      "64054-69-9",
      "6-[(5-Indanyl)Amino]uracil",
      "SCHEMBL3971654",
      "CTK2A7431",
      "DTXSID80591769",
      "BDBM50022163",
      "ZINC13798089",
      "6-[(3,4-Trimethylene)Anilino]uracil",
      "2,4(1H,3H)-Pyrimidinedione, 6-[(2,3-Dihydro-1H-Inden-5-Yl)Amino]-",
    ],
  },
  {
    primary_id: "D0C1WH",
    names: ["Letrozole"],
  },
  {
    primary_id: "D0C1WL",
    names: ["C-2507"],
  },
  {
    primary_id: "D0C1WP",
    names: ["SCH-1595241"],
  },
  {
    primary_id: "D0C1WR",
    names: ["AMG 232"],
  },
  {
    primary_id: "D0C1XE",
    names: ["(R)-2-((4-Benzylphenoxy)Methyl)Piperidine", "CHEMBL1099180", "SCHEMBL13809077"],
  },
  {
    primary_id: "D0C1XI",
    names: [
      "Acetic Acid 2-Phenyl-5-Propyl-Thiazol-4-Yl Ester",
      "CHEMBL413975",
      "SCHEMBL9629413",
      "BDBM50012407",
      "Acetic Acid 2-Phenyl-5-Propyl-4-Thiazolyl Ester",
    ],
  },
  {
    primary_id: "D0C1XS",
    names: ["ZIMELIDINE"],
  },
  {
    primary_id: "D0C1YA",
    names: ["ISIS 15998"],
  },
  {
    primary_id: "D0C1YW",
    names: ["Phenylpropylamine Derivative 3"],
  },
  {
    primary_id: "D0C1ZO",
    names: [
      "[N-Methyl-3H(3)]AZ-10419369",
      "AR-A2",
      "AZ-01134",
      "AZ-10419369",
      "AZ-12320927",
      "AZ-12333053",
      "AZ-12426308",
      "AZ-12426792",
      "AZD-8129",
      "M-519351",
      "M-549715",
      "M-555601",
      "AR-A-000002",
      "AR-A2 Analogs, AstraZeneca",
    ],
  },
  {
    primary_id: "D0C1ZQ",
    names: ["Estrogen Receptor Beta Modulators"],
  },
  {
    primary_id: "D0C1ZW",
    names: ["ISIS 29232"],
  },
  {
    primary_id: "D0C2AR",
    names: ["Gadocoletic Acid"],
  },
  {
    primary_id: "D0C2BP",
    names: ["AG-270"],
  },
  {
    primary_id: "D0C2CH",
    names: ["OGT-719"],
  },
  {
    primary_id: "D0C2CP",
    names: [
      "Conjugated-Trastuzumab",
      "Conjugated-Trastuzumab (Breast Cancer)",
      "Immunoconjugates (Cancer), Biocon/IATRICa",
      "Conjugated-Trastuzumab (Breast Cancer), Biocon/IATRICa",
    ],
  },
  {
    primary_id: "D0C2CY",
    names: ["WAY-254011"],
  },
  {
    primary_id: "D0C2DM",
    names: ["LY593093"],
  },
  {
    primary_id: "D0C2DN",
    names: ["2-Arachidonoylglycerolphosphoinositol", "2-Arachidonoyl-Glycerol-Phosphoinositol", "Arachidonoyl LPI"],
  },
  {
    primary_id: "D0C2DV",
    names: ["Indole-Based Analog 2"],
  },
  {
    primary_id: "D0C2EP",
    names: ["Remikiren"],
  },
  {
    primary_id: "D0C2ET",
    names: ["(R)-Mequitazine (Incontinence/Respiratory Disease), Pierre Fabre"],
  },
  {
    primary_id: "D0C2EW",
    names: ["PMID25399762-Compound-Table1-C7"],
  },
  {
    primary_id: "D0C2FJ",
    names: [
      "CL-275838",
      "4-[2-(4-Benzyl-1-Piperazinyl)Acetyl]-7-[3-(Trifluoromethyl)Phenyl]-4,5-Dihydropyrazolo[1,5-A]pyrimidine-3-Carbonitrile",
    ],
  },
  {
    primary_id: "D0C2FM",
    names: [
      "[3H]M-MPEP",
      "2-Methyl-6-((3-Methoxyphenyl)Ethynyl)-Pyridine",
      "M-MPEP",
      "CHEMBL332397",
      "2-[2-(3-Methoxyphenyl)Ethynyl]-6-Methylpyridine",
      "2-[2-(3-Methoxyphenyl)Ethynyl]-6-Methyl-Pyridine",
      "[3H]-M-MPEP",
      "Methoxy-MPEP",
      "AC1NSKOR",
      "GTPL1425",
      "GTPL3344",
      "SCHEMBL1020513",
      "PDSP1_000362",
      "ZINC13487233",
      "PDSP2_000360",
      "BDBM50123005",
      "2-Methyl-6-(3-Methoxyphenylethynyl)Pyridine",
      "2-(3-Methoxy-Phenylethynyl)-6-Methyl-Pyridine",
    ],
  },
  {
    primary_id: "D0C2FS",
    names: [
      "2-(2-Mercapto-Ethyl)-Pentanedioic Acid",
      "2-(2-Sulfanylethyl)Pentanedioic Acid",
      "CHEMBL292562",
      "254737-18-3",
      "Pentanedioic Acid, 2-(2-Mercaptoethyl)-",
      "SCHEMBL2058227",
      "CTK0J4031",
      "DTXSID80430765",
      "SMGZBRVSYVJHCB-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0C2GE",
    names: ["PMID22765894C8h"],
  },
  {
    primary_id: "D0C2GN",
    names: ["SLP-1001"],
  },
  {
    primary_id: "D0C2GR",
    names: ["CCG-50014", "CCG 50014", "Compound 1a", "CCG50014"],
  },
  {
    primary_id: "D0C2GW",
    names: ["SC-52569"],
  },
  {
    primary_id: "D0C2HF",
    names: ["GR-253035"],
  },
  {
    primary_id: "D0C2HG",
    names: ["BYK-324677"],
  },
  {
    primary_id: "D0C2HI",
    names: ["PegCNTF"],
  },
  {
    primary_id: "D0C2HR",
    names: [
      "ORG-9273",
      "1-[(2beta,3alpha,5alpha,16beta,17beta)-17-Acetoxy-3-Hydroxy-2-Morpholinoandrostan-16-Yl]-1-Methylpiperidinium Bromide",
      "17beta-Acetoxy-3alpha-Hydroxy-16beta-(1-Methylhexahydropyridinio-1-Yl)-2beta-Morpholino-5alpha-Androstane Bromide",
    ],
  },
  {
    primary_id: "D0C2IA",
    names: ["DPC-168"],
  },
  {
    primary_id: "D0C2IE",
    names: [
      "N-(2-Phenylpropyl)Quinoxaline-2-Carboxamide",
      "CHEMBL241699",
      "SCHEMBL6083525",
      "UWOAOBCILHACGX-UHFFFAOYSA-N",
      "BDBM50197265",
    ],
  },
  {
    primary_id: "D0C2IG",
    names: ["ISIS 298700"],
  },
  {
    primary_id: "D0C2II",
    names: ["Methazolamide"],
  },
  {
    primary_id: "D0C2IO",
    names: ["CXL-1427"],
  },
  {
    primary_id: "D0C2IR",
    names: ["MP-AA-1"],
  },
  {
    primary_id: "D0C2JJ",
    names: ["[D-4Aph(CO-NH-OCH3)6]degarelix", "CHEMBL435141"],
  },
  {
    primary_id: "D0C2JS",
    names: ["CPI-203", "CPI203", "CPI 203"],
  },
  {
    primary_id: "D0C2KD",
    names: [
      "N-(5-Hydroxycarbamoyl-Pentyl)-4-Nitro-Benzamide",
      "CHEMBL139999",
      "SCHEMBL1232700",
      "BDBM50082661",
      "ZINC13472309",
    ],
  },
  {
    primary_id: "D0C2KF",
    names: ["JNJ-26489112", "Anticonvulsant Agent (Epilepsy), J&J PRD"],
  },
  {
    primary_id: "D0C2KK",
    names: ["4-(3,5-Dimethoxy-Phenoxy)-6,7-Dimethoxy-Quinoline", "CHEMBL101733"],
  },
  {
    primary_id: "D0C2KS",
    names: ["CI-1015", "CI 1015", "PD 144598", "CI1015"],
  },
  {
    primary_id: "D0C2KV",
    names: ["RGLP-1"],
  },
  {
    primary_id: "D0C2MH",
    names: ["PMID29865878-Compound-46"],
  },
  {
    primary_id: "D0C2MS",
    names: ["NP-07"],
  },
  {
    primary_id: "D0C2NK",
    names: ["Norfluoxetine"],
  },
  {
    primary_id: "D0C2NQ",
    names: ["L-Val-L-BoroPro"],
  },
  {
    primary_id: "D0C2NT",
    names: [
      "NP-G2-044",
      "1ER2O3UZ4W",
      "UNII-1ER2O3UZ4W",
      "SCHEMBL17038863",
      "3-Furancarboxamide, 2-Methyl-N-(1-((4-(Trifluoromethyl)Phenyl)Methyl)-1H-Indazol-3-Yl)-",
      "2-Methyl-N-(1-((4-(Trifluoromethyl)Phenyl)Methyl)-1H-Indazol-3-Yl)-3-Furancarboxamide",
      "1807454-59-6",
    ],
  },
  {
    primary_id: "D0C2OR",
    names: ["MIM-D3"],
  },
  {
    primary_id: "D0C2PD",
    names: ["PMID25974391C34"],
  },
  {
    primary_id: "D0C2PH",
    names: ["CCT301-59 (Targeting ROR2)"],
  },
  {
    primary_id: "D0C2QF",
    names: [
      "LY-25582",
      "3-[1-(3-Cyclohexyl-3-Hydroxy-Propyl)-3,4-Dimethyl-Piperidin-4-Yl]-Phenol",
      "LY-255582",
      "LY255582",
      "CHEMBL300662",
      "119193-09-8",
      "LY 255582",
      "SCHEMBL2740453",
      "AOB5354",
      "(3R,4R)3-[1-(3-Cyclohexyl-3-Hydroxy-Propyl)-3,4-Dimethyl-Piperidin-4-Yl]-Phenol",
      "ZINC3783617",
      "BDBM50045775",
      "NCGC00408804-01",
      "AS-16869",
      "LY255582, &gt",
      "3-[(3R,4R)-1-((S)-3-Cyclohexyl-3-Hydroxy-Propyl)-3,4-Dimethyl-Piperidin-4-Yl]-Phenol",
      "3-((3R,4R)-1-((S)-3-Cyclohexyl-3-Hydroxypropyl)-3,4-Dimethylpiperidin-4-Yl)Phenol",
      "1-Piperidinepropanol,",
    ],
  },
  {
    primary_id: "D0C2QJ",
    names: ["GR-270773"],
  },
  {
    primary_id: "D0C2QS",
    names: ["MEDI-3617"],
  },
  {
    primary_id: "D0C2QU",
    names: ["PMID26560530-Compound-25"],
  },
  {
    primary_id: "D0C2SF",
    names: [
      "4-(4-Chlorophenyl)-5-Cyano-2H-1,2,3-Triazole",
      "CHEMBL393014",
      "5-(4-CHLORO-PHENYL)-3H-[1,2,3]TRIAZOLE-4-CARBONITRILE",
      "50710-06-0",
      "SCHEMBL17969183",
      "CTK1G7781",
      "BDBM50200758",
      "ZINC22004877",
      "AKOS015967718",
      "FCH1390661",
      "ACM50710060",
    ],
  },
  {
    primary_id: "D0C2UH",
    names: ["V930/V932"],
  },
  {
    primary_id: "D0C2UJ",
    names: ["2-Chloroadenosine", "2Cl-Ado", "CADO"],
  },
  {
    primary_id: "D0C2UP",
    names: ["PMID28870136-Compound-42"],
  },
  {
    primary_id: "D0C2UQ",
    names: ["MCL-428"],
  },
  {
    primary_id: "D0C2UY",
    names: ["MANUMYCIN A"],
  },
  {
    primary_id: "D0C2VM",
    names: [
      "Cell-Based TIV",
      "Cell-Based TIV (Microneedle Injectable Device)",
      "Cell-Based TIV (Microneedle Intradermal Device), FluGen",
      "Cell-Based Trivalent Influenza Vaccine (Microneedle Intradermal Device), FluGen",
    ],
  },
  {
    primary_id: "D0C2VP",
    names: ["[3H]N5-Methylfolate"],
  },
  {
    primary_id: "D0C2WV",
    names: ["DPT-HBV"],
  },
  {
    primary_id: "D0C2WX",
    names: [
      "2-(2-Chloro-Benzoyl)-Cyclohexane-1,3-Dione",
      "2-(2-Chlorobenzoyl)Cyclohexane-1,3-Dione",
      "CHEMBL166951",
      "88562-09-8",
      "1,3-Cyclohexanedione, 2-(2-Chlorobenzoyl)-",
      "ACMC-20lbdq",
      "SCHEMBL10192439",
      "CTK3A9587",
      "DTXSID30558701",
    ],
  },
  {
    primary_id: "D0C2XI",
    names: ["Claritin/Singulair"],
  },
  {
    primary_id: "D0C2XT",
    names: ["PMID24000170C36"],
  },
  {
    primary_id: "D0C2YB",
    names: [
      "Cefoperazone",
      "Cefobid",
      "Cefoperazon",
      "Cefoperazono",
      "Cefoperazonum",
      "Cefoperazone Sodium Salt",
      "Peracef [veterinary]",
      "CP 52640",
      "Cefazone (TN)",
      "Cefobid (TN)",
      "Cefoperazone (INN)",
      "Cefoperazone [INN:BAN]",
      "Cefoperazono [INN-Spanish]",
      "Cefoperazonum [INN-Latin]",
      "Peracef [veterinary] (TN)",
      "(6R,7R)-7-((R)-2-(4-Ethyl-2,3-Dioxo-1-Piperazinylcarboxamido)-2-(4-Hydroxyphenyl)Acetamido)-3-((1-Methyl-1H-Tetrazol-5-Yl)Thiomethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2-Carbonsaeure",
      "(6R,7R)-7-[[(2R)-2-[(4-Ethyl-2,3-Dioxopiperazine-1-Carbonyl)Amino]-2-(4-Hydroxyphenyl)Acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2S)-2-[(4-Ethyl-2,3-Dioxopiperazine-1-Carbonyl)Amino]-2-(4-Hydroxyphenyl)Acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[2-[(4-Ethyl-2,3-Dioxopiperazine-1-Carbonyl)Amino]-2-(4-Hydroxyphenyl)Acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-{[(4-Ethyl-2,3-Dioxopiperazin-1-Yl)Carbonyl]amino}-2-(4-Hydroxyphenyl)Acetyl]amino}-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Thio]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[{[(4-Ethyl-2,3-Dioxopiperazin-1-Yl)Carbonyl]amino}(4-Hydroxyphenyl)Acetyl]amino}-3-{[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7S)-7-[[(2R)-2-[(4-Ethyl-2,3-Dioxopiperazine-1-Carbonyl)Amino]-2-(4-Hydroxyphenyl)Acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7S)-7-[[(2S)-2-[(4-Ethyl-2,3-Dioxopiperazine-1-Carbonyl)Amino]-2-(4-Hydroxyphenyl)Acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6S,7R)-7-[[(2S)-2-[(4-Ethyl-2,3-Dioxopiperazine-1-Carbonyl)Amino]-2-(4-Hydroxyphenyl)Acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-[[2-[(4-Ethyl-2,3-Dioxopiperazine-1-Carbonyl)Amino]-2-(4-Hydroxyphenyl)Acetyl]amino]-3-[(1-Methyltetrazol-5-Yl)Sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-{[{[(4-Ethyl-2,3-Dioxopiperazin-1-Yl)Carbonyl]amino}(4-Hydroxyphenyl)Acetyl]amino}-3-[(1-Methyl-1H-Tetrazol-5-Yl)Sulfanyl]methyl-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0C2YI",
    names: ["Alkynyl-Heteroaromatic Ring Compound 1"],
  },
  {
    primary_id: "D0C2YL",
    names: [
      "2,5-Anhydroglucitol-1,6-Biphosphate",
      "2,5-Anhydro-D-Glucitol-1,6-Diphosphate",
      "2,5-Anhydro-D-Glucitol 1,6-Diphosphate",
      "4429-47-4",
      "AHG",
      "2,5-Anhydroglucitol 1,6-Bisphosphate",
      "AC1L9I6X",
      "SCHEMBL20154711",
      "ZINC3870733",
      "AKOS032954084",
      "DB02778",
      "2,5-Anhydro-1,6-Di-O-Phosphono-D-Glucitol",
      "2,5-Anhydro-D-Glucitol 1,6-Bisphosphoric Acid",
      "W-202770",
      "[(2R,3S,4S,5S)-3,4-Dihydroxy-5-(Phosphonooxymethyl)Oxolan-2-Yl]methyl Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0C2ZI",
    names: ["PSN-842"],
  },
  {
    primary_id: "D0C2ZJ",
    names: ["Tetra-Hydro-Imidazo[1,5-D][1,4]oxazepin-3-Yl Derivative 3"],
  },
  {
    primary_id: "D0C2ZL",
    names: [
      "Ataciguat",
      "Ataciguat [INN]",
      "HMR 1766",
      "HMR-1766",
      "5-Chloro-2-((5-Chloro-2-Thienyl)Sulfonylamino)-N-(4-(Morpholin-4-Ylsulfonyl)Phenyl)Benzamide",
      "5-Chloro-2-(5-Chlorothiophene-2-Sulfonylamino)-N-(4-(Morpholine-4-Sulfonyl)Phenyl)Benzamide",
      "5-Chloro-2-[(5-Chlorothiophen-2-Yl)Sulfonylamino]-N-(4-Morpholin-4-Ylsulfonylphenyl)Benzamide",
    ],
  },
  {
    primary_id: "D0C2ZW",
    names: ["Tyr-Pro-Phg-Pro-NH2", "CHEMBL425421"],
  },
  {
    primary_id: "D0C3AA",
    names: ["CKD-712"],
  },
  {
    primary_id: "D0C3AD",
    names: [
      "GW-788388",
      "452342-67-5",
      "GW788388",
      "4-(4-(3-(Pyridin-2-Yl)-1H-Pyrazol-4-Yl)Pyridin-2-Yl)-N-(Tetrahydro-2H-Pyran-4-Yl)Benzamide",
      "GW 788388",
      "UNII-N14114957J",
      "CHEMBL202887",
      "N14114957J",
      "4-[4-[3-(Pyridin-2-Yl)-1H-Pyrazol-4-Yl]pyridin-2-Yl]-N-(Tetrahydropyran-4-Yl)Benzamide",
      "N-(Oxan-4-Yl)-4-{4-[3-(Pyridin-2-Yl)-1H-Pyrazol-4-Yl]pyridin-2-Yl}benzamide",
      "4-(4-(3-(Pyridin-2-Yl)-1H-Pyrazol-4-Yl)Pyridin-2-Yl)-N-(Tetrahydropyran-4-Yl)Benzamide",
      "Cc-69",
      "SCHEMBL373524",
      "SCHEMBL17926774",
      "CTK8C0589",
      "DTXSID70196444",
      "EX-A122",
      "AOB2606",
    ],
  },
  {
    primary_id: "D0C3AG",
    names: [
      "2,3,4-Tribromo-6-(2,4-Dibromophenoxy)Phenol",
      "CHEMBL462942",
      "80246-24-8",
      "SCHEMBL5531128",
      "CTK3E5867",
      "DTXSID70475089",
      "2-OH-BDE-123",
      "ZINC44386786",
      "BDBM50292444",
      "2-Hydroxy-2',3,4,4',5-Pentabromodiphenyl Ether",
      "Phenol, 2,3,4-Tribromo-6-(2,4-Dibromophenoxy)-",
    ],
  },
  {
    primary_id: "D0C3AJ",
    names: ["3alpha-(2'-Indolecarbonyloxy)-Nortropane", "CHEMBL596256", "BDBM50304333"],
  },
  {
    primary_id: "D0C3AN",
    names: ["SSR-146977"],
  },
  {
    primary_id: "D0C3AR",
    names: ["8-Chloro-3,4-Dihydroquinazolin-2-Amine", "CHEMBL272752"],
  },
  {
    primary_id: "D0C3BA",
    names: [
      "YM-358",
      "2,7-Diethyl-5-[2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Ylmethyl]-5H-Pyrazolo[1,5-B][1,2,4]triazole Potassium Salt Monohydrate",
    ],
  },
  {
    primary_id: "D0C3BS",
    names: ["DU-176b", "Edoxaban"],
  },
  {
    primary_id: "D0C3CF",
    names: ["SDZ-NVI-085"],
  },
  {
    primary_id: "D0C3CS",
    names: [
      "4,5,6,7-Tetrafluoro-3-Methyl-1H-Indazole",
      "220057-70-5",
      "CHEMBL593307",
      "SCHEMBL13122192",
      "ZINC34415295",
      "AKOS024258260",
      "FCH3137367",
      "AJ-88433",
      "AX8289182",
    ],
  },
  {
    primary_id: "D0C3CZ",
    names: [
      "Aldoxorubicin",
      "1361644-26-9",
      "ALDOXORUBICIN",
      "Doxorubicin-EMCH",
      "151038-96-9",
      "Aldoxorubicin (USAN)",
      "CHEMBL2107818",
      "SCHEMBL15221892",
      "OBMJQRLIQQTJLR-LBMCFUDOSA-N",
      "ZINC163337436",
      "AKOS030526452",
      "CS-1186",
      "HY-16261",
      "D10383",
      "W-5595",
      "(8S)-1-Methoxy-6,8alpha,11-Trihydroxy-8-[1-[2-[6-(2,5-Dioxo-3-Pyrroline-1-Yl)Hexanoyl]hydrazono]-2-Hydroxyethyl]-10alpha-(3-Amino-2,3,6-Trideoxy-Alpha-L-Lyxo-Hexopyranosyloxy)-7,8,9,10-Tetrahydronaphthacene-5,12-Dione",
    ],
  },
  {
    primary_id: "D0C3DA",
    names: ["CaroRx"],
  },
  {
    primary_id: "D0C3DH",
    names: ["RAAV Vaccine"],
  },
  {
    primary_id: "D0C3ET",
    names: ["ASB-16165"],
  },
  {
    primary_id: "D0C3EX",
    names: [
      "2-(5-Nonyloxy-1H-Indol-3-Yl)-Ethylamine",
      "5-Nonyloxytryptamine",
      "5-(Nonyloxy)Tryptamine",
      "O-Nonylserotonin",
      "5-(Nonyloxy)-1H-Indole-3-Ethanamine",
      "157798-12-4",
      "2-(5-Nonoxy-1H-Indol-3-Yl)Ethanamine",
      "CHEMBL97450",
      "CHEBI:64149",
      "3-(2-Aminoethyl)-5-Nonyloxyindole",
      "1H-Indole-3-Ethanamine,5-(Nonyloxy)-",
      "2-[5-(Nonyloxy)-1H-Indol-3-Yl]ethanamine",
      "5-(Nonyloxy)-Tryptamine",
      "Tocris-0901",
      "NCGC00024858-01",
      "Biomol-NT_000115",
      "AC1L1C9C",
      "GTPL106",
      "SCHEMBL2335932",
      "BPBio1_000133",
      "AC1Q56M5",
      "DTXSID4058653",
      "CTK4C9475",
      "ZINC1541570",
      "BDBM50039947",
      "AKOS030238973",
    ],
  },
  {
    primary_id: "D0C3FK",
    names: ["TA-CD Vaccine"],
  },
  {
    primary_id: "D0C3FN",
    names: ["AX-13057"],
  },
  {
    primary_id: "D0C3GW",
    names: [
      "TG-1022",
      "AMP Activated Protein Kinase Stimulator (Obesity), TG Biotech",
      "AMPK Activator (Obesity), TG Biotech",
    ],
  },
  {
    primary_id: "D0C3GZ",
    names: ["STS-T4"],
  },
  {
    primary_id: "D0C3HN",
    names: ["PMID27774822-Compound-Figure9Example15"],
  },
  {
    primary_id: "D0C3HQ",
    names: ["Quin-C1"],
  },
  {
    primary_id: "D0C3HS",
    names: ["CDP-1050"],
  },
  {
    primary_id: "D0C3ID",
    names: ["N-(4,6-Diphenylpyrimidin-2-Yl)Benzamide"],
  },
  {
    primary_id: "D0C3IO",
    names: [
      "Fluarix",
      "Alpharix",
      "Influsplit",
      "Influsplit SSW",
      "Influenza Vaccine, GlaxoSmithKline",
      "Influenza Vaccine, SB",
    ],
  },
  {
    primary_id: "D0C3IS",
    names: ["3-(2-N-Piperidyl-Propionamino)-Rutaecarpine", "CHEMBL1096951"],
  },
  {
    primary_id: "D0C3JB",
    names: ["MB-102"],
  },
  {
    primary_id: "D0C3JV",
    names: [
      "1,8-Bis-Maleimidodiethyleneglycol",
      "115597-84-7",
      "UNII-R20U86RE9F",
      "1,8-Bis(Maleimido)-3,6-Dioxaoctane",
      "CHEMBL575383",
      "R20U86RE9F",
      "2,2'-(Ethylenedioxy)Bis(Ethylmaleimide)",
      "Liqwd",
      "1H-Pyrrole-2,5-Dione, 1,1'-(1,2-Ethanediylbis(Oxy-2,1-Ethanediyl))Bis-",
      "Bis-(Maleimidoethoxy) Ethane",
      "BM(Peg)2",
      "1,1'-[ethane-1,2-Diylbis(Oxyethane-2,1-Diyl)]bis(1h-Pyrrole-2,5-Dione)",
      "1H-Pyrrole-2,5-Dione, 1,1'-[1,2-Ethanediylbis(Oxy-2,1-Ethanediyl)]bis-",
      "1,2-Bis(2-Maleimidoethoxy)Ethane",
      "Bis-(Maleimidoethoxy) Ethane [INCI]",
      "AC1MVX1G",
      "SCHEMBL349749",
    ],
  },
  {
    primary_id: "D0C3JX",
    names: ["MNI-137"],
  },
  {
    primary_id: "D0C3JZ",
    names: ["SiRNA Agent, Cancer, Respiratory/Metabolic/Liver Diseases"],
  },
  {
    primary_id: "D0C3KE",
    names: ["PMID21627121C2"],
  },
  {
    primary_id: "D0C3KW",
    names: ["H-Lys-Arg-Pro-Hyp-Gly-Igl-Ser-D-BT-OH(JMV1645)"],
  },
  {
    primary_id: "D0C3LL",
    names: ["HER1-VSSP Vaccine"],
  },
  {
    primary_id: "D0C3LM",
    names: ["RAS-111"],
  },
  {
    primary_id: "D0C3LP",
    names: ["Prezatide Copper Acetate"],
  },
  {
    primary_id: "D0C3LQ",
    names: ["Ulocuplumab"],
  },
  {
    primary_id: "D0C3LW",
    names: ["ISIS 19649"],
  },
  {
    primary_id: "D0C3MW",
    names: ["MBO7133"],
  },
  {
    primary_id: "D0C3OH",
    names: ["Carbamoyl Oxime Derivative 1"],
  },
  {
    primary_id: "D0C3OY",
    names: [
      "RPKPfQwFwLL-NH2",
      "96736-12-8",
      "Antagonist D",
      "[D-Arg1,D-Phe5,D-Trp7,9,Leu11]-Substance P",
      "CHEMBL501261",
      "D-Arg-Pro-Lys-Pro-D-Phe-Gln-D-Trp-Phe-D-Trp-Leu-Leu-NH2",
      "[D-Arg1, D-Phe5, D-Trp7,9, Leu11]-Substance P",
      "MFCD00076802",
      "BDBM50260268",
      "AKOS024456872",
      "CA-1583",
      "[D-Arg1, D-Phe5, D-Trp7,9, Leu11]-Substance P, &gt",
      "(D-Arg1,D-Phe5,D-Trp7 Inverted Exclamation Mark Currency9,Leu11)-Substance P",
    ],
  },
  {
    primary_id: "D0C3QE",
    names: [
      "N-(5-Benzyloxy-Isoquinolin-1-Yl)-Guanidine",
      "CHEMBL325094",
      "SCHEMBL6998909",
      "KMGPZVZUHUMNQX-UHFFFAOYSA-N",
      "BDBM50147411",
    ],
  },
  {
    primary_id: "D0C3RF",
    names: ["REP-9AC"],
  },
  {
    primary_id: "D0C3RK",
    names: ["N-(Phenylpyrazolyl)Benzamide Derivative 1"],
  },
  {
    primary_id: "D0C3RP",
    names: ["TDI-0074"],
  },
  {
    primary_id: "D0C3SJ",
    names: ["6-Isopropyl-2-Morpholin-4-Yl-4H-Thiopyran-4-One", "CHEMBL222929"],
  },
  {
    primary_id: "D0C3SL",
    names: [
      "Di(2,6-Di-T-Butylphenol)",
      "128-38-1",
      "Ethyl 712",
      "3,3',5,5'-Tetra-Tert-Butylbiphenyl-4,4'-Diol",
      "UNII-D85N5VOL1N",
      "3,3',5,5'-Tetra(Tert-Butyl)[1,1'-Biphenyl]-4,4'-Diol",
      "D85N5VOL1N",
      "CHEMBL1662",
      "4,4-Bis(2,6-Di-Tert-Butylphenol)",
      "[1,1'-Biphenyl]-4,4'-Diol, 3,3',5,5'-Tetrakis(1,1-Dimethylethyl)-",
      "3,3',5,5'-Tetra-Tert-Butyl-[1,1'-Biphenyl]-4,4'-Diol",
      "Inter008513,3',5,5'-Tetra-Tert-Butyl-4,4'-Dihydroxybiphenyl",
      "2,6-Bis(Tert-Butyl)-4-[3,5-Bis(Tert-Butyl)-4-Hydroxyphenyl]phenol",
      "4, 3,3',5,5'-Tetra-Tert-Butyl-",
      "[1,4'-Diol, 3,3',5,5'-Tetrakis(1,1-Dimethylethyl",
    ],
  },
  {
    primary_id: "D0C3SW",
    names: ["Lesinurad"],
  },
  {
    primary_id: "D0C3TE",
    names: ["TEI-9874"],
  },
  {
    primary_id: "D0C3TI",
    names: ["Resimmune", "A-DmDT390-BisFv Immunotoxin"],
  },
  {
    primary_id: "D0C3UC",
    names: ["Ladostigil"],
  },
  {
    primary_id: "D0C3UV",
    names: ["ISIS 25117"],
  },
  {
    primary_id: "D0C3VI",
    names: ["PD-167341"],
  },
  {
    primary_id: "D0C3VQ",
    names: ["Zadaxin/Lamivudine"],
  },
  {
    primary_id: "D0C3WS",
    names: ["EC-301"],
  },
  {
    primary_id: "D0C3WY",
    names: [
      "3,4-Diphenyl-1H-Pyrrole-2,5-Dione",
      "2,3-Diphenylmaleimide",
      "1H-Pyrrole-2,5-Dione, 3,4-Diphenyl-",
      "31295-36-0",
      "AC1MBL6S",
      "SCHEMBL114611",
      "CHEMBL201949",
      "CTK1B9880",
      "3,4-Diphenylpyrrole-2,5-Dione",
      "DTXSID70372903",
      "ZINC3847556",
    ],
  },
  {
    primary_id: "D0C3XD",
    names: ["BIO-11006"],
  },
  {
    primary_id: "D0C3XF",
    names: ["4-Decyl-N-(Pyridin-3-Yl)Benzamide", "CHEMBL259102"],
  },
  {
    primary_id: "D0C3XN",
    names: ["AG-1321002", "1321002"],
  },
  {
    primary_id: "D0C3XU",
    names: ["10-EPI-8-DEOXY-CUMAMBRIN B", "10-Epi-8-Deoxy-Cumambrin B", "CHEMBL1099308"],
  },
  {
    primary_id: "D0C3YJ",
    names: ["UBIQUINONE-2"],
  },
  {
    primary_id: "D0C3YN",
    names: ["Heparin Low Molecular Weight"],
  },
  {
    primary_id: "D0C3YQ",
    names: ["Mesna"],
  },
  {
    primary_id: "D0C3ZN",
    names: ["Imidazopyridine Derivative 6"],
  },
  {
    primary_id: "D0C3ZR",
    names: ["TG100-115"],
  },
  {
    primary_id: "D0C3ZZ",
    names: ["BNC375"],
  },
  {
    primary_id: "D0C4AE",
    names: [
      "3-(Phenylsulfonyl)-1-(Piperidin-4-Yl)-1H-Indole",
      "CHEMBL1080574",
      "651335-50-1",
      "SCHEMBL4077678",
      "CTK1J9032",
      "DTXSID00437312",
      "BDBM50313128",
      "1H-Indole, 3-(Phenylsulfonyl)-1-(4-Piperidinyl)-",
    ],
  },
  {
    primary_id: "D0C4AM",
    names: ["Pramlintide"],
  },
  {
    primary_id: "D0C4BD",
    names: [
      "KH-901",
      "Comfeagen",
      "GM-CSF Gene-Carrying Recombinant Adenovirus Vaccine (Solid Tumor), Kanghong Biotechnologies",
    ],
  },
  {
    primary_id: "D0C4BI",
    names: [
      "EX-1314",
      "BMS-317180",
      "BMS-604992",
      "BMS-606056",
      "Ghrelin Agonists, BMS",
      "Ghrelin Agonists, Elixir",
      "Growth Hormone Secretagogues, BMS",
      "Growthhormone Secretagogues, Elixir",
    ],
  },
  {
    primary_id: "D0C4BJ",
    names: ["PMID26004420-Compound-WO2014126944B"],
  },
  {
    primary_id: "D0C4BL",
    names: ["Coxsackievirus A21", "Cavatak (TN)"],
  },
  {
    primary_id: "D0C4CG",
    names: ["N-(2-Chloroethyl)Icosa-5,8,11,14-Tetraenamide", "AC1L1CNE", "CTK8E7690", "DTXSID60274346"],
  },
  {
    primary_id: "D0C4CI",
    names: ["Antihypertensive Agent", "Antihypertensive Agent (Oral/BA Technology)"],
  },
  {
    primary_id: "D0C4CJ",
    names: ["SF-105"],
  },
  {
    primary_id: "D0C4DE",
    names: ["PMID25742366C14b"],
  },
  {
    primary_id: "D0C4DH",
    names: ["Glecaprevir; Pibrentasvir"],
  },
  {
    primary_id: "D0C4DZ",
    names: ["ThermoProfen"],
  },
  {
    primary_id: "D0C4EQ",
    names: ["ATYR-0030"],
  },
  {
    primary_id: "D0C4ET",
    names: ["ISIS 112592"],
  },
  {
    primary_id: "D0C4FA",
    names: ["BAX-855"],
  },
  {
    primary_id: "D0C4FS",
    names: ["DT-200"],
  },
  {
    primary_id: "D0C4FT",
    names: ["HEXESTROL"],
  },
  {
    primary_id: "D0C4GE",
    names: [
      "4-Amino-2-Deoxy-2,3-Dehydro-N-Neuraminic Acid",
      "130525-62-1",
      "4-AM-DANA",
      "CHEMBL52270",
      "4-Amino-Neu5Ac2en",
      "5-(Acetylamino)-4-Amino-2,6-Anhydro-3,4,5-Trideoxy-D-Glycero-D-Galacto-Non-2-Enonic Acid",
      "4AM",
      "Zanamivir Amine",
      "(4S,5R,6R)-5-Acetylamino-4-Amino-6-((1R,2R)-1,2,3-Trihydroxy-Propyl)-5,6-Dihydro-4H-Pyran-2-Carboxylic Acid",
      "(2R,3R,4S)-3-Acetamido-4-Amino-2-[(1R,2R)-1,2,3-Trihydroxypropyl]-3,4-Dihydro-2H-Pyran-6-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0C4GN",
    names: ["CD33-CAR-T Cell"],
  },
  {
    primary_id: "D0C4GX",
    names: ["GBL-310", "Anti-Hyperproliferative (Topical, Actinic Keratosis), Grannus"],
  },
  {
    primary_id: "D0C4HG",
    names: ["Levosimendan"],
  },
  {
    primary_id: "D0C4HZ",
    names: ["PLR-9"],
  },
  {
    primary_id: "D0C4JI",
    names: ["DK-0141", "Anti-Inflammatory (Peptide/Infusion, Acute Lung Injury), MondoBIOTECH"],
  },
  {
    primary_id: "D0C4JQ",
    names: ["Peptide Analog 43"],
  },
  {
    primary_id: "D0C4JV",
    names: ["ISC-4"],
  },
  {
    primary_id: "D0C4JX",
    names: ["QX-314"],
  },
  {
    primary_id: "D0C4KD",
    names: ["N-(3-Bromophenyl)-5-Methoxybenzo[d]oxazol-2-Amine", "CHEMBL1269727"],
  },
  {
    primary_id: "D0C4KE",
    names: ["MIM-3Aa"],
  },
  {
    primary_id: "D0C4KT",
    names: ["XOMA 052"],
  },
  {
    primary_id: "D0C4LF",
    names: [
      "ARRY-162",
      "606143-89-9",
      "Binimetinib",
      "MEK162",
      "MEK-162",
      "ARRY-438162",
      "5-[(4-Bromo-2-Fluorophenyl)Amino]-4-Fluoro-N-(2-Hydroxyethoxy)-1-Methyl-1H-Benzimidazole-6-Carboxamide",
      "ARRY 162",
      "UNII-181R97MR71",
      "Binimetinib (MEK162, ARRY-162, ARRY-438162)",
      "181R97MR71",
      "MEK162 (ARRY-162, ARRY-438162)",
      "6-(4-Bromo-2-Fluorophenylamino)-7-Fluoro-N-(2-Hydroxyethoxy)-3-Methyl-3H-Benzo[d]imidazole-5-Carboxamide",
      "Binimetinib [USAN:INN]",
      "MEK 162",
      "ARRY 438162",
      "MEK162(Binimetinib)",
      "Binimetinib (JAN/USAN)",
      "D0C4LF",
      "NVP-ME",
    ],
  },
  {
    primary_id: "D0C4NA",
    names: ["NSC-154957"],
  },
  {
    primary_id: "D0C4NF",
    names: ["FMP-825"],
  },
  {
    primary_id: "D0C4NY",
    names: ["Promestriene", "BCP9000134", "VA11593", "A824465", "I06-0300"],
  },
  {
    primary_id: "D0C4OV",
    names: ["PMID14697765C16a"],
  },
  {
    primary_id: "D0C4PB",
    names: ["LY3381916"],
  },
  {
    primary_id: "D0C4PD",
    names: ["PMID25726713-Compound-38"],
  },
  {
    primary_id: "D0C4PT",
    names: [
      "29-Methylidene-2,3-Oxidosqualene",
      "29-Mos",
      "2,2-Dimethyl-3-[(3E,7E,11E,15E,19Z)-3,7,12-Trimethyl-16-Prop-2-Enylhenicosa-3,7,11,15,19-Pentaenyl]oxirane",
      "AC1O5QC1",
      "(3H)29-Mos",
      "GTPL6719",
      "Oxirane, 3-(16-Ethenyl-3,7,12,20-Tetramethyl-3,7,11,15,19-Heneicosapentaenyl)-2,2-Dimethyl-, (S-(All-E))-",
    ],
  },
  {
    primary_id: "D0C4QB",
    names: ["2-Amino-Indan-5-Sulfonic Acid", "2-Amino-2,3-Dihydro-1H-Indene-5-Sulfonic Acid"],
  },
  {
    primary_id: "D0C4QH",
    names: ["Tyr-Pro-Mmp-Phe-NH", "CHEMBL413676"],
  },
  {
    primary_id: "D0C4QN",
    names: ["ROTIGAPTIDE"],
  },
  {
    primary_id: "D0C4QO",
    names: ["ASP2215"],
  },
  {
    primary_id: "D0C4RB",
    names: [
      "Taxol",
      "C47H51NO14",
      "Weekly Paclitaxel",
      "Micellar Paclitaxel",
      "Paclitaxel [USAN:INN:BAN]",
      "SCHEMBL15000506",
      "Benzenepropanoic Acid, Beta-(Benzoylamino)-Alpha-Hydroxy-, 6,12b-Bis(Acetyloxy)-12-(Benzoyloxy)-2a,3,4,4a,5,6,9,10,11,12,12a,12b-Dodecahydro-4,11-Dihydroxy-4a,8,13,13-Tetramethyl-5-Oxo-7,11-Methano-1H-Cyclodeca(3,4)Benz(1,2-B)Oxet-9-Yl Ester, (2aR-(2aalpha,4beta,4abeta,6beta,9alpha(AlphaR*,betaS*),11alpha,12alpha,12aalpha,12balpha))-",
    ],
  },
  {
    primary_id: "D0C4SH",
    names: ["ZK-209614"],
  },
  {
    primary_id: "D0C4TB",
    names: ["Pyrazole Derivative 22"],
  },
  {
    primary_id: "D0C4UU",
    names: ["TDI-0099"],
  },
  {
    primary_id: "D0C4VG",
    names: [
      "4-Phospho-D-Erythronate",
      "4-Phosphoerythronate",
      "4-Phospho-D-Erythronic Acid",
      "4-Phospho D-Erythronate",
      "57229-25-1",
      "CHEBI:49003",
      "4-O-Phosphono-D-Erythronic Acid",
      "(2R,3R)-2,3-Dihydroxy-4-(Phosphonooxy)Butanoic Acid",
      "2bet",
      "AC1L9MYX",
      "SCHEMBL420471",
      "CHEMBL1160945",
      "CTK1E1186",
      "DTXSID70332305",
      "ZINC3869897",
      "DER",
      "AKOS030570437",
      "DB03108",
      "FT-0673861",
      "C03393",
      "(2R,3R)-2,3-Dihydroxy-4-Phosphonooxybutanoic Acid",
      "[R-(R*,R*)]-2,3-Dihydroxy-4-(Phosphonooxy)Butanoic Acid",
      "Butanoic Acid, 2,3-Dihydroxy-4-(Phosphonooxy)-, (2R,3R)-",
    ],
  },
  {
    primary_id: "D0C4VN",
    names: [
      "GM3 Ganglioside Vaccine",
      "Breast Tumor Vaccine, Center Of Molecular Immunology",
      "GM3 Ganglioside Vaccine, Center Of Molecular Immunology",
      "Melanoma Vaccine, Center Of Molecular Immunology",
      "GM3 Ganglioside Vaccine (Breast Cancer), Center Of Molecular Immunology",
      "GM3 Ganglioside Vaccine (Breast Tumor), Center Of Molecular Immunology",
      "GM3 Ganglioside Vaccine (Melanoma), Center Of Molecular Immunology",
      "GM3 Ganglioside Vaccine (NGcGM3/VSSP), Center Of Molecular Immunology",
      "N-Acetyl-GM3/VSSP ISA-51",
    ],
  },
  {
    primary_id: "D0C4VR",
    names: ["PMID25726713-Compound-16"],
  },
  {
    primary_id: "D0C4VX",
    names: ["4-(7-Chloro-1-Propyl-1H-Indazol-3-Yl)Phenol"],
  },
  {
    primary_id: "D0C4WC",
    names: ["Disitertide"],
  },
  {
    primary_id: "D0C4WY",
    names: ["SP-08"],
  },
  {
    primary_id: "D0C4XQ",
    names: ["GB-12819"],
  },
  {
    primary_id: "D0C4YA",
    names: ["AG-331"],
  },
  {
    primary_id: "D0C4YC",
    names: [
      "Mesalazine",
      "Apriso",
      "Asacol",
      "Asacolitin",
      "Asacolon",
      "Asalit",
      "Ascolitin",
      "Canasa",
      "Claversal",
      "Fisalamine",
      "Fivasa",
      "Iialda",
      "Ipocol",
      "Lialda",
      "Lixacol",
      "Mesacol",
      "Mesalamine",
      "Mesalazina",
      "Mesalazinum",
      "Mesasal",
      "Mesavance",
      "Mesavancol",
      "Mezavant",
      "Pentacol",
      "Pentasa",
      "Rowasa",
      "Salofalk",
      "Salozinal",
      "SfRowasa",
      "Allphar Brand Of Mesalamine",
      "Antigen Brand Of Mesalamine",
      "Asacol HD",
      "Axcan Brand Of Mesalamine",
      "Byk Brand Of Mesalamine",
      "Celltech Brand Of Mesalamine",
      "Falk Brand Of Mesalamine",
      "Farmasa Brand Of Mesalamine",
      "Ferring Brand Of Mesalamine",
      "GlaxoSmithKline Brand Of Mesalamine",
      "Henning Berlin Brand Of Mesalamine",
      "M Aminosalicylic Acid",
      "Merckle Brand Of Mesalamine",
      "Mesalamine Hydrochloride",
      "Mesalamine Monosodium Salt",
      "Mesalamine [USAN]",
      "Mesalazina[Spanish]",
      "Mesalazine MMX",
      "Mesalazinum [Latin]",
      "Meta Aminosalicylic Acid",
      "Mezavant XL",
      "Minosalicylic Acid",
      "Norgine Brand Of Mesalamine",
      "Novopharm Brand Of Mesalamine",
      "Provalis Brand Of Mesalamine",
      "Sanofi Synthelabo Brand Of Mesalamine",
      "Schering Plough Brand Of Mesalamine",
      "SmithKline Brand Of Mesalamine",
      "Solvay Brand Of Mesalamine",
      "Yamanouchi Brand Of Mesalamine",
      "Novo 5 ASA",
      "Novo5 ASA",
      "AJG-501",
      "Apriso (TN)",
      "Asacol (TN)",
      "Canasa (TN)",
      "Hydrochloride, Mesalamine",
      "Iialda (TN)",
      "Ipocal (TN)",
      "Lialda (TN)",
      "M-A",
      "M-Aminosalicylic Acid",
      "MAX-002",
      "MD-0901",
      "Masacol (TN)",
      "Mesalamine (USP)",
      "Meta-AminosalicylicAcid",
      "Monosodium Salt, Mesalamine",
      "Novo-5 ASA",
      "P-Aminosalicylsaeure",
      "P-Aminosalicylsaeure [German]",
      "Pentasa (TN)",
      "Procter & Gamble Brand Of Mesalamine",
      "Rowasa (TN)",
      "SPD-476",
      "SPD-480",
      "Salofalk (TN)",
      "Salofalk Granu-Stix",
      "Schering-Plough Brand Of Mesalamine",
      "Z-206",
      "Mesalazine (JAN/INN)",
      "Salicylic Acid, 5-Amino-(8CI)",
      "2-Hydroxy-5-Aminobenzoic Acid",
      "3-Carboxy-4-Hydroxyaniline",
      "5 Aminosalicylate",
      "5 Aminosalicylic Acid",
      "5-AS",
      "5-ASA",
      "5-Amino-2-Hydroxybenzoic Acid",
      "5-Aminosalicylate",
      "5-Aminosalicylic Acid",
      "5-Amino-2-Hydroxy-Benzoic Acid",
      "5-Aminosalicylic Acid, Mesalazine, Asacol, Pentasa, Canasa, Mesalamine",
    ],
  },
  {
    primary_id: "D0C4YF",
    names: ["TAN-1518A"],
  },
  {
    primary_id: "D0C4YQ",
    names: ["GSK315234"],
  },
  {
    primary_id: "D0C4YX",
    names: ["Carboxamide Derivative 11"],
  },
  {
    primary_id: "D0C5AD",
    names: ["ERK Inhibitor III", "AC1NSSSU", "1-Nitro-2-[(Z)-[5-(3-Nitrophenyl)Furan-2-Yl]methylideneamino]guanidine"],
  },
  {
    primary_id: "D0C5BL",
    names: ["ISIS 13730"],
  },
  {
    primary_id: "D0C5CE",
    names: ["Nabiximols"],
  },
  {
    primary_id: "D0C5DC",
    names: ["TT-063"],
  },
  {
    primary_id: "D0C5DM",
    names: ["SC-52150"],
  },
  {
    primary_id: "D0C5EG",
    names: ["A-366", "A 366", "A366"],
  },
  {
    primary_id: "D0C5FA",
    names: ["Duvortuxizumab"],
  },
  {
    primary_id: "D0C5FN",
    names: ["Rp-5-OMe-UDPalphaB", "5-OMe-Uridine-5'-O-(Alpha-Boranodiphosphate)", "Compound 18a [PMID: 22901672]"],
  },
  {
    primary_id: "D0C5FQ",
    names: ["BHT-3009"],
  },
  {
    primary_id: "D0C5FY",
    names: ["3,8-Dihydroxy-4-Methyl-6H-Benzo[c]chromen-6-One", "CHEMBL206600", "SCHEMBL6828078"],
  },
  {
    primary_id: "D0C5GF",
    names: [
      "MPDT",
      "BGC 20-761",
      "17375-63-2",
      "UNII-6G5F6ESV5I",
      "6G5F6ESV5I",
      "CHEMBL7318",
      "BGC-20-761",
      "5-Methoxy-N,N-Dimethyl-2-Phenyl-1H-Indole-3-Ethanamine",
      "2-(5-Methoxy-2-Phenyl-1H-Indol-3-Yl)-N,N-Dimethylethanamine",
      "AC1OCFJ3",
      "GTPL71",
      "SCHEMBL5763578",
      "N,N-Dimethyl-2-(5-Methoxy-2-Phenylindol-3-Yl)Ethylamine",
      "Indole, 3-(2-(Dimethylamino)Ethyl)-5-Methoxy-2-Phenyl-",
      "1H-Indole-3-Ethanamine, 5-Methoxy-N,N-Dimethyl-2-Phenyl-",
      "MolPort-023-276-754",
      "PDSP2_001402",
      "BDBM50085970",
      "PDSP1_001418",
      "ZINC13805807",
      "AKOS024457555",
      "NCGC00370860-01",
    ],
  },
  {
    primary_id: "D0C5GW",
    names: [
      "1-Cyclohexyl-3-Phenyl-Urea",
      "1-Cyclohexyl-3-Phenylurea",
      "N-CYCLOHEXYL-N'-PHENYLUREA",
      "CHEMBL365939",
      "Urea, N-Cyclohexyl-N'-Phenyl-",
      "886-59-9",
      "WPLYTRWMCWBZEN-UHFFFAOYSA-N",
      "(Cyclohexylamino)-N-Benzamide",
      "NSC80588",
      "CBMicro_019203",
      "3-Cyclohexyl-1-Phenylurea",
      "1-Phenyl-3-Cyclohexylurea",
      "Cambridge Id 5353538",
      "AC1Q5NF9",
      "1-Cyclohexyl 3-Phenyl Urea",
      "MLS000532450",
      "SCHEMBL812273",
      "N-Cyclohexyl-N'-Phenylurea #",
      "AC1L21R9",
      "ZINC65313",
      "MolPort-001-837-644",
      "HMS3323I20",
      "HMS2169M05",
      "HMS1775L19",
      "CCG-7124",
      "NSC-80588",
      "SBB056567",
      "BDBM50167056",
      "AKOS001021945",
    ],
  },
  {
    primary_id: "D0C5HP",
    names: [
      "2-Amino-6-(3-Bromo-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(3-Bromophenylthio)Benzenecarbonitrile",
      "AC1LAE0T",
      "CHEMBL56465",
      "BDBM1742",
      "SCHEMBL2638192",
      "ZINC20478",
      "CTK7C6433",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1l",
      "2-Amino-6-(3-Bromophenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(3-Bromophenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D0C5HR",
    names: ["Pyrrolo-Pyrimidine Derivative 10"],
  },
  {
    primary_id: "D0C5HV",
    names: ["R Zileuton"],
  },
  {
    primary_id: "D0C5IZ",
    names: ["H1N1 Vaccine"],
  },
  {
    primary_id: "D0C5JP",
    names: ["3-(2-Carboxy-3-Phosphonopropyl)Benzoic Acid"],
  },
  {
    primary_id: "D0C5JQ",
    names: ["JNJ-40346527"],
  },
  {
    primary_id: "D0C5KG",
    names: ["ORG-33628", "11beta-(4-Acetylphenyl)-3'-Methylenespiro[estra-4,9-Dien-17(R),2'-Tetrahydrofuran]-3-One"],
  },
  {
    primary_id: "D0C5LI",
    names: ["SL-91.0893"],
  },
  {
    primary_id: "D0C5LJ",
    names: ["ADC-1013"],
  },
  {
    primary_id: "D0C5LO",
    names: [
      "7,9-Octadecadiynoic Acid",
      "CHEMBL485858",
      "33128-25-5",
      "LMFA01030532",
      "AC1NSN9N",
      "Octadeca-7,9-Diynoic Acid",
      "CTK1B8718",
      "DTXSID00415579",
    ],
  },
  {
    primary_id: "D0C5MG",
    names: ["Methylphenidate Sustained Release"],
  },
  {
    primary_id: "D0C5ND",
    names: ["RO-17"],
  },
  {
    primary_id: "D0C5NP",
    names: ["AGG-523"],
  },
  {
    primary_id: "D0C5NT",
    names: ["PMID25666693-Compound-114"],
  },
  {
    primary_id: "D0C5OF",
    names: ["Ro-44-3949"],
  },
  {
    primary_id: "D0C5OK",
    names: ["Drug 2838232", "2838232"],
  },
  {
    primary_id: "D0C5PA",
    names: ["Sibenadet"],
  },
  {
    primary_id: "D0C5RC",
    names: [
      "N-Alpha-Amidino-Tyr(Me)-Pro-Trp-Phe-NH2",
      "CHEMBL390271",
      "BDBM50201414",
      "N Alpha-Amidino-Tyr(Me)-Pro-Trp-Phe-NH2",
    ],
  },
  {
    primary_id: "D0C5RD",
    names: ["PMID25522065-Compound-31"],
  },
  {
    primary_id: "D0C5RH",
    names: ["Des-AA1,2,4,13-[D-Trp8]SRIF", "CHEMBL410047"],
  },
  {
    primary_id: "D0C5RQ",
    names: ["SB-568849"],
  },
  {
    primary_id: "D0C5RZ",
    names: ["Tyramine"],
  },
  {
    primary_id: "D0C5SQ",
    names: ["Carbamate Derivative 13"],
  },
  {
    primary_id: "D0C5SV",
    names: ["Alpha-Linolenic Acid"],
  },
  {
    primary_id: "D0C5TE",
    names: ["Expanded-Spectrum Neoglycosides"],
  },
  {
    primary_id: "D0C5UE",
    names: ["Ipoxyn"],
  },
  {
    primary_id: "D0C5VI",
    names: ["ISIS 116360"],
  },
  {
    primary_id: "D0C5VP",
    names: ["AB-1003"],
  },
  {
    primary_id: "D0C5VQ",
    names: ["PRX-321"],
  },
  {
    primary_id: "D0C5WE",
    names: ["AGT-2000"],
  },
  {
    primary_id: "D0C5WK",
    names: [
      "LY-392098",
      "AMPA Modulators, Eli Lilly",
      "LY-404187",
      "LY-4516146",
      "LY-451616",
      "LY-451646",
      "LY-503430",
      "PF-4775874",
    ],
  },
  {
    primary_id: "D0C5WR",
    names: [
      "Cobalt Chelate Complex CTC-96 Dermatological",
      "Doxovir",
      "CTC-96",
      "Doxovir-L",
      "Cobalt Chelate Complex (Herpes Labialis), Redox",
      "CTC-96 (Dermatological), Redox",
      "Cobalt Chelate Complex (Keratoconjunctivitis/Keratitis), OPKO",
    ],
  },
  {
    primary_id: "D0C5XB",
    names: [
      "NSC-93394",
      "6-Fluoroflavone",
      "1218-82-2",
      "6-Fluoro-2-Phenyl-4h-Chromen-4-One",
      "6-Fluoro-2-Phenylchromen-4-One",
      "CHEMBL61745",
      "4H-1-Benzopyran-4-One,6-Fluoro-2-Phenyl-",
      "6-Fluoroflavanone",
      "NSC93394",
      "4l2g",
      "AC1Q4OEB",
      "SCHEMBL2427741",
      "AC1L657S",
      "CTK4B2868",
      "DTXSID80294001",
      "MolPort-001-776-694",
      "ZINC135415",
      "6-Fluoro-2-Phenyl-Chromen-4-One",
      "ZX-AP008247",
      "5994AH",
      "PC6548",
      "SBB099255",
      "AKOS003623542",
      "ZB004762",
      "DB-041635",
      "6-Fluoro-2-Phenyl-4H-1-Benzopyran-4-One",
      "KB-248702",
      "KB-199446",
      "FT-0633494",
      "4H-1-Benzopyran-4-One, 6-Fluoro-2-Phenyl-",
      "A-7636",
      "C-41230",
    ],
  },
  {
    primary_id: "D0C5XC",
    names: [
      "Clemastine",
      "Clemastina",
      "Clemastinum",
      "Meclastin",
      "Meclastine",
      "Mecloprodin",
      "Tavegyl",
      "Tavist",
      "CLEMASTINE FUMARATE",
      "HS 592",
      "Clemastina [INN-Spanish]",
      "Clemastine [USAN:BAN]",
      "Clemastinum [INN-Latin]",
      "HS-592",
      "Tavegyl (TN)",
      "Tavist (TN)",
      "Tavist (*Fumarate*)",
      "(+)-(2R)-2-(2-(((R)-P-Chloro-Alpha-Methyl-Alpha-Phenylbenzyl)Oxy)Ethyl)-1-Methylpyrrolidine",
      "(+)-(2R)-2-[2-[[(R)-P-Chloro-Alpha-Methyl-Alpha-Phenylbenzyl]oxy]ethyl]-1-Methylpyrrolidine",
      "(2R)-2-(2-{[(1R)-1-(4-Chlorophenyl)-1-Phenylethyl]oxy}ethyl)-1-Methylpyrrolidine",
      "(2R)-2-[2-[(1R)-1-(4-Chlorophenyl)-1-Phenylethoxy]ethyl]-1-Methylpyrrolidine",
      "(2R)-2-{2-[(1R)-1-(4-Chlorophenyl)-1-Phenylethoxy]ethyl}-1-Methylpyrrolidine",
      "2(R)-[2-[(1R)-(4-Chlorophenyl)-1-Phenyl-Ethoxy]ethyl-1-Methylpyrrolidine",
      "2-(2-(4-Chlor-Alpha-Methylbenzhydryloxy)Ethyl)-1-Methylpyrrolidin",
    ],
  },
  {
    primary_id: "D0C5XF",
    names: ["GSK-1344386B", "CCR2 Antagonist (Atherosclerosis)", "CCR2 Antagonist (Atherosclerosis), GlaxoSmithKline"],
  },
  {
    primary_id: "D0C5XQ",
    names: ["5-MEO-DMT"],
  },
  {
    primary_id: "D0C5YK",
    names: ["NMI-102"],
  },
  {
    primary_id: "D0C5YQ",
    names: ["SDZ-62-826"],
  },
  {
    primary_id: "D0C5ZP",
    names: [
      "N-(2-Benzyloxy-4-Nitrophenyl)Methanesulfonamide",
      "CHEMBL223108",
      "SCHEMBL3295792",
      "MolPort-028-745-458",
      "ZINC35324154",
    ],
  },
  {
    primary_id: "D0C6AP",
    names: [
      "2-(4-Hydroxy-Phenyl)-7-Methyl-Benzofuran-5-Ol",
      "CHEMBL365290",
      "SCHEMBL1119325",
      "FUWUZPRNKPGRGX-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0C6BJ",
    names: ["VII-7-B", "CNS Agent (Depression/Anxiety), Synvax Pharmaceuticals"],
  },
  {
    primary_id: "D0C6BP",
    names: ["1,6-Bis(4-Phenylpiperazin-1-Yl)Hexane", "CHEMBL1172205", "SCHEMBL4528457"],
  },
  {
    primary_id: "D0C6BU",
    names: [
      "ANTHRONE",
      "90-44-8",
      "9(10H)-Anthracenone",
      "Carbothrone",
      "Anthranone",
      "9-Oxoanthracene",
      "9,10-Dihydro-9-Oxoanthracene",
      "10H-Anthracen-9-One",
      "Anthracen-9(10H)-One",
      "Az-O",
      "Anthracene, 9,10-Dihydro-9-Oxo-",
      "UNII-FP0FJ7K744",
      "CCRIS 3175",
      "CHEBI:33835",
      "HSDB 2158",
      "NSC 1965",
      "EINECS 201-994-0",
      "AI3-11256",
      "FP0FJ7K744",
      "CHEMBL124440",
      "9-10-Dihydro-9-Oxoanthracene",
      "9,10-Dihydro-9-Ketoanthracene",
      "RJGDLRCDCYRQOQ-UHFFFAOYSA-N",
      "MFCD00001187",
      "Anthrone, ACS Reagent",
      "Anthrone, 95%, Pure",
      "10-Hydroanthracen-9-One",
      "A-8100",
      "9-Anthrone",
      "Anthrone, 96%",
      "AC1L1NTG",
    ],
  },
  {
    primary_id: "D0C6BY",
    names: ["PMID23434029C41"],
  },
  {
    primary_id: "D0C6CZ",
    names: ["GNF-PF-1694"],
  },
  {
    primary_id: "D0C6DT",
    names: ["Esomeprazole"],
  },
  {
    primary_id: "D0C6DU",
    names: ["TVX-003"],
  },
  {
    primary_id: "D0C6DX",
    names: ["CardiALLO"],
  },
  {
    primary_id: "D0C6EA",
    names: ["XZK"],
  },
  {
    primary_id: "D0C6ED",
    names: ["1,4-Phenylene Disulfamate", "CHEMBL461964"],
  },
  {
    primary_id: "D0C6EF",
    names: ["Flucelvax"],
  },
  {
    primary_id: "D0C6EW",
    names: ["Ustiloxin F"],
  },
  {
    primary_id: "D0C6FL",
    names: ["SPD-704"],
  },
  {
    primary_id: "D0C6FW",
    names: ["4-(3-Nitrobenzylthio)-2-Aminobutanoic Acid", "CHEMBL204478", "BDBM50179690"],
  },
  {
    primary_id: "D0C6GE",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 21"],
  },
  {
    primary_id: "D0C6GK",
    names: ["EPZ-5676"],
  },
  {
    primary_id: "D0C6HH",
    names: [
      "6-Fluoropyridin-3-Ylboronic Acid",
      "351019-18-6",
      "2-Fluoropyridine-5-Boronic Acid",
      "2-Fluoro-5-Pyridylboronic Acid",
      "(6-Fluoropyridin-3-Yl)Boronic Acid",
      "6-Fluoropyridine-3-Boronic Acid",
      "2-Fluoro-5-Pyridineboronic Acid",
      "2-Fluoropyridin-5-Ylboronic Acid",
      "2-Fluoropyridin-5-Boronic Acid",
      "6-Fluoro-3-Pyridinylboronic Acid",
      "6-Fluoro-3-Pyridylboronic Acid",
      "6-Fluoropyridin-3-Yl-3-Boronic Acid",
      "6-FLUORO-3-PYRIDINEBORONIC ACID",
      "(6-Fluoropyridin-3-Yl)Boranediol",
      "BORONIC ACID, (6-FLUORO-3-PYRIDINYL)-",
      "(6-Fluoro-3-Pyridyl)Boronic Acid",
    ],
  },
  {
    primary_id: "D0C6HM",
    names: ["Cebaracetam", "CGS-25248", "ZY-15119"],
  },
  {
    primary_id: "D0C6ID",
    names: ["Citatuzumab Bogatox"],
  },
  {
    primary_id: "D0C6IE",
    names: ["Taurine"],
  },
  {
    primary_id: "D0C6IQ",
    names: ["Stamulumab"],
  },
  {
    primary_id: "D0C6JG",
    names: [
      "2-Methyl-6-(3-(Phenylthio)Prop-1-Ynyl)Pyridine",
      "CHEMBL214874",
      "2-Methyl-6-[3-(Phenylthio)Prop-1-Yn-1-Yl]pyridine",
      "SCHEMBL5703368",
      "BDBM50191120",
    ],
  },
  {
    primary_id: "D0C6JO",
    names: ["DNA Nanoparticles"],
  },
  {
    primary_id: "D0C6KI",
    names: [
      "CB-676475",
      "690206-97-4",
      "ZM 306416",
      "ZM306416",
      "ZM-306416",
      "N-(4-Chloro-2-Fluorophenyl)-6,7-Dimethoxyquinazolin-4-Amine",
      "ZM-306416(CB 676475)",
      "CHEMBL150315",
      "AK174923",
      "CB 676475",
      "Anilinoquinazoline Deriv. 4",
      "AC1NS5UJ",
      "SCHEMBL3012896",
      "BDBM4621",
      "GTPL8245",
      "CTK8E8823",
      "CHEBI:92732",
      "DTXSID90416176",
      "AOB6790",
      "MolPort-035-395-727",
      "HMS3651N06",
      "BCP06445",
      "ZINC2583789",
      "HSCI1_000042",
      "S2897",
      "2820AH",
      "AKOS025404926",
      "SB16593",
      "CS-1349",
      "KS-00000T57",
      "NCGC00167747-01",
      "SC-95590",
      "DA-41680",
      "CB676475",
      "HY-13785",
      "AB0165814",
      "RT-016341",
      "KB-299318",
      "SW219943-1",
      "FT-0706412",
      "S-7773",
    ],
  },
  {
    primary_id: "D0C6KK",
    names: ["RT002 Injectable"],
  },
  {
    primary_id: "D0C6KQ",
    names: [
      "IODOSULPIRIDE",
      "CHEMBL307023",
      "BDBM50040082",
      "N-(1-Ethyl-Pyrrolidin-2-Ylmethyl)-2-Methoxy-5-Sulfamoyl-Benzamide(Iodosulpiride)",
      "N-(1-Ethyl-Pyrrolidin-2-Ylmethyl)-2-Iodo-6-Methoxy-3-Sulfamoyl-Benzamide (Iodosulpride)",
    ],
  },
  {
    primary_id: "D0C6KS",
    names: ["KiroVAX-002"],
  },
  {
    primary_id: "D0C6LS",
    names: [
      "Adenosine-5'-Rp-Alpha-Thio-Triphosphate",
      "ADENOSINE-5'-RP-ALPHA-THIO-TRIPHOSPHATE",
      "ATP-Alpha-S",
      "AC1OAHXZ",
      "SCHEMBL307389",
      "CHEMBL3774733",
      "Adenosine 5'-O-(1-Thiotriphosphate)",
      "DB02355",
      "T99",
      "[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphinothioyl] Phosphono Hydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0C6MS",
    names: ["PF-04991532"],
  },
  {
    primary_id: "D0C6ND",
    names: ["AIR-Insulin"],
  },
  {
    primary_id: "D0C6NM",
    names: [
      "Latanoprost",
      "Catioprost",
      "Xalatan",
      "Latanoprost Free Acid",
      "L1167_SIGMA",
      "PhXA41",
      "XA41",
      "AR-202",
      "Nova-21027",
      "PHXA-41",
      "XA-41",
      "Xalatan (TN)",
      "Latanoprost (JAN/USAN/INN)",
      "PhXA34 [as 15(R,S)-Isomer]",
      "Isopropyl (5Z,9alpha,11alpha,15R)-9,11,15-Trihydroxy-17-Phenyl-18,19,20-Trinorprost-5-En-1-Oate",
      "Isopropyl (5Z,9alpha,11alpha,15R)-9,11,15-Trihydroxy-17-Phenyl-18,19,20-Trinor-Prost-5-En-1-Oate",
      "Isopropyl (Z)-7-((1R,2R,3R,5S)-3,5-Dihydroxy-2-((3R)-3-Hydroxy-5-Phenylpentyl)Cyclopentyl)-5-Heptenoate",
      "Propan-2-Yl (Z)-7-[(1R,2R,3R,5S)-3,5-Dihydroxy-2-[(3R)-3-Hydroxy-5-Phenylpentyl]cyclopentyl]hept-5-Enoate",
      "Propan-2-Yl (5Z)-7-{(1R,2R,3R,5S)-3,5-Dihydroxy-2-[(3R)-3-Hydroxy-5-Phenylpentyl]cyclopentyl}hept-5-Enoate",
    ],
  },
  {
    primary_id: "D0C6NV",
    names: ["RUT58-60"],
  },
  {
    primary_id: "D0C6OD",
    names: [
      "4-Chloro-N-(4-Nitro-Benzoyl)-Benzenesulfonamide",
      "CHEMBL436085",
      "36965-16-9",
      "CTK1A9789",
      "DTXSID10465969",
      "ZINC16740685",
      "AKOS002221762",
      "Benzamide, N-[(4-Chlorophenyl)Sulfonyl]-4-Nitro-",
    ],
  },
  {
    primary_id: "D0C6OQ",
    names: [
      "Alclofenac",
      "22131-79-9",
      "Alclophenac",
      "Prinalgin",
      "Medifenac",
      "Reufenac",
      "Allopydin",
      "Mervan",
      "Alclofenacum",
      "Alclofenaco",
      "Zumaril",
      "Neoston",
      "Neosten",
      "2-(4-(Allyloxy)-3-Chlorophenyl)Acetic Acid",
      "[4-(Allyloxy)-3-Chlorophenyl]acetic Acid",
      "Desinflam",
      "Marvan Forte",
      "3-Chloro-4-(2-Propenyloxy)Benzeneacetic Acid",
      "(4-Allyloxy-3-Chlorphenyl)Essigsaeure",
      "MY 101",
      "Epinal",
      "Benzeneacetic Acid, 3-Chloro-4-(2-Propenyloxy)-",
      "Alclofenacum [INN-Latin]",
      "Alclofenaco [INN-Spanish]",
      "UNII-M9CP5H21N8",
      "W 7320",
      "CHEBI:31183",
      "EINECS 244-795-4",
    ],
  },
  {
    primary_id: "D0C6OW",
    names: ["PMID28350212-Compound-23"],
  },
  {
    primary_id: "D0C6PL",
    names: ["Sm-P80-VR1020"],
  },
  {
    primary_id: "D0C6PS",
    names: ["Muromonab"],
  },
  {
    primary_id: "D0C6QF",
    names: [
      "2-Hex-5-Enyl-5-Non-8-Enyl-Pyrrolidine",
      "CHEMBL14599",
      "2-(5-Hexenyl)-5-(8-Nonenyl)Pyrrolidine",
      "100594-86-3",
      "2-Hex-5-Enyl-5-Non-8-Enylpyr",
      "ACMC-20m3ob",
      "Pyrrolidine, 2-(5-Hexenyl)-5-(8-Nonenyl)-",
      "CTK0I4208",
      "YCQNSAJAZIVRFR-UHFFFAOYSA-N",
      "AC1L4819",
      "BDBM50284428",
    ],
  },
  {
    primary_id: "D0C6QG",
    names: ["Manganese Chloride Tetrahydrate"],
  },
  {
    primary_id: "D0C6QI",
    names: ["Acetyl-Podocarpic Dimer", "Acetyl Podocarpic Acid Anhydride"],
  },
  {
    primary_id: "D0C6QQ",
    names: ["Besipirdine", "Besipirdine Hydrochloride", "HP-749", "P86-7480"],
  },
  {
    primary_id: "D0C6QS",
    names: ["GERANIIN"],
  },
  {
    primary_id: "D0C6RD",
    names: ["Ypeginterferon Alfa-2b"],
  },
  {
    primary_id: "D0C6RM",
    names: ["BAY1436032"],
  },
  {
    primary_id: "D0C6RO",
    names: [
      "Sodium Orthovanadate",
      "13721-39-6",
      "Trisodium Vanadate",
      "Sodium Pervanadate",
      "Sodium O-Vanadate",
      "Sodium Vanadate (Ortho)",
      "Trisodium Tetraoxovanadate",
      "Sodium Tetraoxovanadate(3-)",
      "Vanadic Acid, Trisodium Salt",
      "TRISODIUM ORTHOVANADATE",
      "Vanadic Acid (H3VO4), Sodium Salt",
      "UNII-7845MV6C8V",
      "Trisodium Trioxido(Oxo)Vanadium",
      "CCRIS 9063",
      "Vanadic(II) Acid, Trisodium Salt",
      "EINECS 237-287-9",
      "NSC 79534",
      "Vanadate (VO43-), Trisodium, (T-4)-",
      "AI3-52157",
      "Vanadate(VO4(3-)), Trisodium",
      "CHEBI:35607",
      "7845MV6C8V",
      "Vanadic (II) Acid, Trisodium",
    ],
  },
  {
    primary_id: "D0C6RQ",
    names: [
      "H-Tyr-D-Ala-Gly-Phe-Pro-Leu-Trp-NMe-3,5-Bzl(CF3)2",
      "CHEMBL406030",
      "BDBM21013",
      "C-Terminal Modified Bifunctional Peptide, 6",
    ],
  },
  {
    primary_id: "D0C6RR",
    names: ["ACTR087 + Rituximab"],
  },
  {
    primary_id: "D0C6TL",
    names: ["Leptin"],
  },
  {
    primary_id: "D0C6UR",
    names: ["Tamoxifen Ethyl Bromide"],
  },
  {
    primary_id: "D0C6US",
    names: [
      "4-(6-Methoxy-3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "Dihydronaphthalene 11",
      "AC1O70HL",
      "SCHEMBL4513739",
      "CHEMBL425054",
      "BDBM8897",
    ],
  },
  {
    primary_id: "D0C6VK",
    names: ["Lm Prostate"],
  },
  {
    primary_id: "D0C6VO",
    names: ["StrataGraft"],
  },
  {
    primary_id: "D0C6VV",
    names: ["Prolarix"],
  },
  {
    primary_id: "D0C6VX",
    names: ["PMID25684022-Compound-WO2010127754"],
  },
  {
    primary_id: "D0C6WN",
    names: [
      "2,4-Dimethoxy-2'-Hydroxychalcone",
      "3-(2,4-Dimethoxyphenyl)-1-(2-Hydroxyphenyl)Prop-2-En-1-One",
      "36685-63-9",
      "AC1L5NE0",
      "NCIOpen2_004691",
      "CTK1C4388",
    ],
  },
  {
    primary_id: "D0C6WO",
    names: ["Sec-Butyl 9H-Pyrido[3,4-B]indole-3-Carboxylate", "CHEMBL1269061"],
  },
  {
    primary_id: "D0C6XD",
    names: ["BCL-005"],
  },
  {
    primary_id: "D0C6XF",
    names: ["Anxiolytic", "ABU", "Anxiolytic, Yamasa", "1-Amino-5-Bromouracil", "1-Amino-5-Bromouracil, Yamasa"],
  },
  {
    primary_id: "D0C6XR",
    names: ["Trimegestone/Ethinyl Estradiol"],
  },
  {
    primary_id: "D0C6YC",
    names: [
      "UCCF-339",
      "652138-15-3",
      "GTPL4337",
      "CTK1J8227",
      "DTXSID60799206",
      "6-(Furan-2-Yl)-2-(Pyridin-4-Yl)-4H-Benzo[h]chromen-4-One",
      "4H-Naphtho[1,2-B]pyran-4-One, 6-(2-Furanyl)-2-(4-Pyridinyl)-",
    ],
  },
  {
    primary_id: "D0C6YH",
    names: ["ARC-2022"],
  },
  {
    primary_id: "D0C6YQ",
    names: ["BCL201"],
  },
  {
    primary_id: "D0C6ZS",
    names: ["CpG-10101", "Actilon", "Anti-HCV Therapy, Coley"],
  },
  {
    primary_id: "D0C6ZX",
    names: [
      "KW-3357",
      "Coagulation Factor Inhibitor (Iv, Blood Clotting Disorder), Kyowa",
      "Recombinant Antithrombin (Iv, Blooding Clottingdisorder), Kyowa Hakko Kirin",
    ],
  },
  {
    primary_id: "D0C7AA",
    names: ["Ozagrel"],
  },
  {
    primary_id: "D0C7AS",
    names: ["N-Benzyl-4-(2,5-Dihydroxy-Benzylamino)-Benzamide", "CHEMBL343071", "BDBM50102429"],
  },
  {
    primary_id: "D0C7AY",
    names: ["Correloid Derivative"],
  },
  {
    primary_id: "D0C7CF",
    names: ["Immunotoxins"],
  },
  {
    primary_id: "D0C7CS",
    names: ["ALN-HPN"],
  },
  {
    primary_id: "D0C7CV",
    names: ["Atu-134"],
  },
  {
    primary_id: "D0C7DY",
    names: ["S. Pneumococcal Next-Generation Vaccine"],
  },
  {
    primary_id: "D0C7ED",
    names: ["PMID22490048C(R)-10h"],
  },
  {
    primary_id: "D0C7EI",
    names: ["GR-38414", "138155-21-2"],
  },
  {
    primary_id: "D0C7ET",
    names: ["Tetrodotoxin"],
  },
  {
    primary_id: "D0C7FL",
    names: [
      "2,2-Diphenyl-Ethylamine",
      "2,2-Diphenylethylamine",
      "3963-62-0",
      "2,2-Diphenylethanamine",
      "1-Amino-2,2-Diphenylethane",
      "2,2-Diphenylethan-1-Amine",
      "CHEMBL10780",
      "2,2-Diphenylethylamine, 96%",
      "RXMTUVIKZRXSSM-UHFFFAOYSA-N",
      "NSC27209",
      "EINECS 223-565-7",
      "PubChem7986",
      "ACMC-1CSWZ",
      "2,2-Diphenylethyl-Amine",
      "2,2- Diphenylethylamine",
      "AC1L2TCM",
      "Beta-Phenylbenzeneethanamine",
      "2,2-Diphenyl-Ethyl-Amine",
      "2,2-Diphenyl Ethyl Amine",
      "2,2-Di(Phenyl)Ethanamine",
      "2,2-Diphenylethanamine #",
      "2,2-Bis-Phenyl-Ethylamine",
      "AC1Q53LU",
      "Benzeneethanamine, B-Phenyl-",
    ],
  },
  {
    primary_id: "D0C7FN",
    names: [
      "BMY-40062",
      "1-(Tert-Butyl)-7-(1R,4R-2,5-Diazabicyclo[2.2.1]hept-2-Yl)-6-Fluoro-4-Oxo-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0C7GB",
    names: [
      "1-(Piperidin-3-Ylmethyl)Pyridin-2(1H)-One",
      "820231-74-1",
      "2(1H)-Pyridinone, 1-(3-Piperidinylmethyl)-",
      "CHEMBL205267",
      "SCHEMBL2503319",
      "CTK3E3069",
      "DTXSID80658785",
      "CRXGPVZZFUWGAF-UHFFFAOYSA-N",
      "AKOS010944098",
      "1-Piperidin-3-Ylmethyl-1H-Pyridin-2-One",
      "1-[(Piperidin-3-Yl)Methyl]pyridin-2(1H)-One",
    ],
  },
  {
    primary_id: "D0C7GJ",
    names: ["Long-Acting Factor VIII"],
  },
  {
    primary_id: "D0C7GT",
    names: ["N-Hydroxy-N-Methyl-3-Phenanthren-2-Yl-Acrylamide", "CHEMBL174557", "ZINC28089401"],
  },
  {
    primary_id: "D0C7HZ",
    names: ["PMID27841036-Compound-XI"],
  },
  {
    primary_id: "D0C7JC",
    names: ["5-Pyridin-3-Yl-Thiophene-2-Carbaldehyde Oxime"],
  },
  {
    primary_id: "D0C7JF",
    names: ["Testolactone"],
  },
  {
    primary_id: "D0C7JH",
    names: [
      "TP-9201",
      "Acetyl-L-Cysteinyl-L-Asparaginyl-L-Prolyl-L-Arginyl-Glycyl-L-Aspartyl-L-(4-O-Methyl)-Tyrosyl-L-Arginyl-L-Cysteinamide Cyclic 1-9-Disulfide",
    ],
  },
  {
    primary_id: "D0C7JT",
    names: ["YM-976", "4-(3-Chlorophenyl)-1,7-Diethylpyrido[2,3-D]pyrimidin-2(1H)-One"],
  },
  {
    primary_id: "D0C7KB",
    names: ["Nova-EPO"],
  },
  {
    primary_id: "D0C7KK",
    names: [
      "Zenarestat",
      "112733-06-9",
      "FR-74366",
      "FK-366",
      "Zenarestat [USAN:INN]",
      "Zenarestatum [INN-Latin]",
      "FR 74366",
      "UNII-180C9PJ8JT",
      "FK 366",
      "[3-(4-Bromo-2-Fluorobenzyl)-7-Chloro-2,4-Dioxo-3,4-Dihydroquinazolin-1(2h)-Yl]acetic Acid",
      "CI-1014",
      "CI 1014",
      "CHEMBL10413",
      "180C9PJ8JT",
      "2-(7-Chloro-3-(4-Bromo-2-Fluorobenzyl)-1,2,3,4-Tetrahydro-2,4-Dioxoquinazolin)-1-Acetic Acid",
      "Zenarestatum",
      "DSSTox_RID_82249",
      "3-(4-Bromo-2-Fluorobenzyl)-7-Chloro-3,4-Dihydro-2,4-Dioxo-1(2H)-Quinazolineacetic Acid",
      "DSSTox_CID_27296",
      "ZES",
      "FR74366",
      "Fk366",
      "Zenarestat (USAN/INN)",
      "[3-(4-BROMO-2-FLUORO-BENZYL)-7-CHLORO-2,4-DIOXO-3,4-DIHYDRO-2H-QUINAZOLIN-1-YL]-ACETIC ACID",
      "(3-(4-Bromo-2-Fluorobenzyl)-7-Chloro-2,4-Dioxo-1,2,3,4-Tetrahydroquinazolin-1-Yl)Acetic Acid",
      "2-[3-[(4-Bromo-2-Fluorophenyl)Methyl]-7-Chloro-2,4-Dioxoquinazolin-1-Yl]acetic Acid",
      "3-((4-Bromo-2-Fluorophenyl)Methyl)-7-Chloro-3,4-Dihydro-2,4-Dioxo-1(2H)-Quinazolineaceticacid",
    ],
  },
  {
    primary_id: "D0C7LA",
    names: ["36220", "BRN 5609020"],
  },
  {
    primary_id: "D0C7LB",
    names: ["Lazabemide Analog 1"],
  },
  {
    primary_id: "D0C7LD",
    names: ["Pemfexy"],
  },
  {
    primary_id: "D0C7LF",
    names: ["DACOPAFANT"],
  },
  {
    primary_id: "D0C7LR",
    names: ["LLZ16406"],
  },
  {
    primary_id: "D0C7LS",
    names: ["Guanosine-5'-Diphosphate"],
  },
  {
    primary_id: "D0C7LY",
    names: ["WAY-207024"],
  },
  {
    primary_id: "D0C7MP",
    names: ["MCL-448"],
  },
  {
    primary_id: "D0C7NU",
    names: ["(S)-8-Hydroxy Palmitic Acid"],
  },
  {
    primary_id: "D0C7NV",
    names: ["MC-1301", "134523-85-6"],
  },
  {
    primary_id: "D0C7ON",
    names: ["PMID28870136-Compound-43"],
  },
  {
    primary_id: "D0C7OQ",
    names: ["BIWB-1"],
  },
  {
    primary_id: "D0C7OT",
    names: [
      "CN54gp140-Hsp70 Conjugate Vaccine",
      "Conjugate Vaccine",
      "CN54gp140-Hsp70 Conjugate Vaccine (HIV Infection)",
    ],
  },
  {
    primary_id: "D0C7PD",
    names: [
      "6-Methoxy-4,9-Dihydro-3H-Beta-Carboline",
      "CHEMBL6717",
      "SCHEMBL8369162",
      "BDBM50136505",
      "AKOS023554679",
      "3,4-Dihydro-6-Methoxy-Beta-Carboline",
    ],
  },
  {
    primary_id: "D0C7PK",
    names: [
      "(2-Hydroxy-3-Phenoxypropyl)(Propan-2-Yl)Amine",
      "1-(Isopropylamino)-3-Phenoxypropan-2-Ol",
      "7695-63-8",
      "1-Phenoxy-3-(Propan-2-Ylamino)Propan-2-Ol",
      "1-Isopropylamino-3-Phenoxy-Propan-2-Ol",
      "1-(Isopropylamino)-3-Phenoxy-2-Propanol",
      "Metoprolol IMpurity 06",
      "CHEMBL7154",
      "H-9/64",
      "2-PROPANOL, 1-[(1-METHYLETHYL)AMINO]-3-PHENOXY-",
      "1-[(1-Methylethyl)Amino]-3-Phenoxy-2-Propanol",
      "2-Propanol, 1-((1-Methylethyl)Amino)-3-Phenoxy-",
      "(2RS)-1-((1-Methylethyl)Amino)-3-Phenoxypropan-2-Ol",
      "Desmethoxyethyl Metoprolol",
      "NIP",
    ],
  },
  {
    primary_id: "D0C7PT",
    names: [
      "5-Fluoro-6-Amino-UMP",
      "6-Amino-5-Fluorouridine 5'-(Dihydrogen Phosphate)",
      "FNU",
      "Uridine Derivative, 42",
      "SCHEMBL1363501",
      "CHEMBL474165",
      "BDBM27945",
      "{[(2R,3S,4R,5R)-5-(6-Amino-5-Fluoro-2,4-Dioxo-1,2,3,4-Tetrahydropyrimidin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy}phosphonic Acid",
    ],
  },
  {
    primary_id: "D0C7PY",
    names: [
      "Lauryl Dimethylamine-N-Oxide",
      "1643-20-5",
      "Lauramine Oxide",
      "Lauryldimethylamine Oxide",
      "Lauryldimethylamine N-Oxide",
      "Dodecyldimethylamine Oxide",
      "LDAO",
      "Empigen OB",
      "Ammonyx LO",
      "Ammonyx AO",
      "Aromox DMCD",
      "Conco XAL",
      "Dimethyldodecylamine Oxide",
      "Dimethylaurylamine Oxide",
      "1-Dodecanamine, N,N-Dimethyl-, N-Oxide",
      "DDNO",
      "N-Dodecyldimethylamine Oxide",
      "Dodecyldimethylamine N-Oxide",
      "Dodecyl(Dimethyl)Amine Oxide",
      "Dimethyldodecylamine N-Oxide",
      "N,N-Dimethyldodecan-1-Amine Oxide",
      "LAURYL DIMETHYLAMINE-N-OXIDE",
      "Aromox Dmmc-W",
      "Dimethyllaurylamine Oxide",
      "Amonyx AO",
      "Refan [Russian]",
    ],
  },
  {
    primary_id: "D0C7PZ",
    names: ["5-Me-IAA", "5-Methy-1H-Imidazole-4-Acetic Acid"],
  },
  {
    primary_id: "D0C7QW",
    names: ["XMetS"],
  },
  {
    primary_id: "D0C7RS",
    names: [
      "1-Anilinonaphthalene-8-Sulfonic Acid",
      "CHEMBL471741",
      "SCHEMBL3327539",
      "BDBM50243721",
      "8-(Phenylamino)Naphthalene-1-Ol Sulfate",
    ],
  },
  {
    primary_id: "D0C7RV",
    names: ["SR12813"],
  },
  {
    primary_id: "D0C7SC",
    names: ["YH-1238", "195889-55-5"],
  },
  {
    primary_id: "D0C7SE",
    names: ["SNAP-94847"],
  },
  {
    primary_id: "D0C7SF",
    names: ["PI3K Alpha Inhibitors"],
  },
  {
    primary_id: "D0C7TO",
    names: ["Tr-3"],
  },
  {
    primary_id: "D0C7TX",
    names: [
      "3-Cyano-N-(6-Methylpyridin-2-Yl)Benzamide",
      "CHEMBL208927",
      "900801-18-5",
      "Benzamide,3-Cyano-N-(6-Methyl-2-Pyridinyl)-",
      "MolPort-004-359-738",
      "ZINC12545036",
      "BDBM50186330",
      "AKOS000205559",
      "MCULE-1984522308",
      "SC-60782",
      "KB-270891",
    ],
  },
  {
    primary_id: "D0C7UR",
    names: [
      "AGI-1096",
      "4-[2,6-Di-Tert-Butyl-4-[1-(3,5-Di-Tert-Butyl-4-Hydroxyphenylsulfanyl)-1-Methylethylsulfanyl]phenoxy]butyric Acid",
    ],
  },
  {
    primary_id: "D0C7VI",
    names: ["Ac-YR[CEHdFRWC]SPPKD-NH2", "CHEMBL2373515", "CHEMBL437086"],
  },
  {
    primary_id: "D0C7WG",
    names: ["3-((4-Hydroxyphenyl)Diazenyl)Benzenesulfonamide"],
  },
  {
    primary_id: "D0C7WN",
    names: ["Acetaldehyde", "Acetic Aldehyde", "Ethanal", "Ethyl Aldehyde", "NSC 7594"],
  },
  {
    primary_id: "D0C7WO",
    names: ["1192U90"],
  },
  {
    primary_id: "D0C7WP",
    names: [
      "SB-431542",
      "301836-41-9",
      "SB 431542",
      "4-(4-(Benzo[d][1,3]dioxol-5-Yl)-5-(Pyridin-2-Yl)-1H-Imidazol-2-Yl)Benzamide",
      "SB431542",
      "UNII-E1557V1V0N",
      "CHEMBL440084",
      "E1557V1V0N",
      "4-[4-(1,3-BENZODIOXOL-5-YL)-5-(2-PYRIDINYL)-1H-IMIDAZOL-2-YL]BENZAMIDE",
      "4-(4-(1,3-Benzodioxole-5-Yl)-5-(2-Pyridyl)-1H-Imidazole-2-Yl)Benzamide",
      "SB 431542 Hydrate",
      "C22H16N4O3",
      "4-[4-(3,4-Methylenedioxyphenyl)-5-(2-Pyridyl)-1H-Imidazol-2-Yl]benzamide",
      "4-(4-Benzo[1,3]dioxol-5-Yl-5-Pyridin-2-Yl-1H-Imidazol-2-Yl)-Benzamide",
    ],
  },
  {
    primary_id: "D0C7XM",
    names: ["P2045"],
  },
  {
    primary_id: "D0C7YE",
    names: ["NKR-2"],
  },
  {
    primary_id: "D0C7YH",
    names: ["PMX-900", "Base Excision Repair Protein Inhibitors (Cancer), Pharminox"],
  },
  {
    primary_id: "D0C7YL",
    names: [
      "3-((1H-Imidazol-1-Yl)Methyl)Aniline",
      "3-(1H-Imidazol-1-Ylmethyl)Aniline",
      "120107-85-9",
      "3-(1-Imidazolylmethyl)Aniline",
      "3-(Imidazol-1-Ylmethyl)Aniline",
      "Benzenamine, 3-(1H-Imidazol-1-Ylmethyl)-",
      "3-Imidazol-1-Ylmethyl-Phenylamine",
      "Benzenamine,3-(1H-Imidazol-1-Ylmethyl)-",
      "CHEMBL597369",
      "3-(Imidazolylmethyl)Phenylamine",
      "3-[(1H-Imidazol-1-Yl)Methyl]aniline",
      "ACMC-20e0av",
      "AC1Q51NE",
      "1-(3-Aminobenzyl)Imidazole",
      "SCHEMBL860653",
      "3-(Imidazo-1-Ylmethyl)Aniline",
      "AC1O5C89",
      "CTK4B1687",
      "DTXSID20424305",
      "MolPort-000-143-257",
    ],
  },
  {
    primary_id: "D0C7YS",
    names: [
      "5-Propyl-2'-Deoxyuridine",
      "27826-74-0",
      "CHEMBL221982",
      "5-Propyldeoxyuridine",
      "2'-Deoxy-5-Propyluridine",
      "5-Propyl-DUrd",
      "5-Propyl-2'-Desoxyuridine",
      "AC1L4N4W",
      "2'-Deoxy-5-N-Propyluridine",
      "Uridine,2'-Deoxy-5-Propyl-",
      "Uridine, 2'-Deoxy-5-Propyl-",
      "SCHEMBL2396529",
      "CTK4G0305",
      "DTXSID30182141",
      "MBERTAKFBYOAHR-IVZWLZJFSA-N",
      "BDBM50375778",
      "1-[(2R,4S,5R)-4-Hydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-5-Propylpyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0C7ZB",
    names: ["GSK3196165"],
  },
  {
    primary_id: "D0C8AL",
    names: ["Fluoropeptide Vaccine"],
  },
  {
    primary_id: "D0C8AP",
    names: ["AG-348"],
  },
  {
    primary_id: "D0C8BV",
    names: ["N-(2-Aminoethyl)Isoquinoline-5-Sulfonamide"],
  },
  {
    primary_id: "D0C8CI",
    names: ["N-Oxalyl-D-Tyrosine Derivative 9"],
  },
  {
    primary_id: "D0C8CR",
    names: [
      "9H-Beta-Carbolin-6-Ylamine",
      "9H-Pyrido[3,4-B]indol-6-Amine",
      "6-Amino-Beta-Carboline",
      "6453-27-6",
      "NSC248004",
      "AC1L7VIB",
      "CHEMBL164120",
      "SCHEMBL10642567",
      "PPCJCNSRVOYTKC-UHFFFAOYSA-N",
      "AKOS022912596",
    ],
  },
  {
    primary_id: "D0C8DA",
    names: ["Actimmune Interferon Gamma-1b"],
  },
  {
    primary_id: "D0C8DC",
    names: ["Ki-1769"],
  },
  {
    primary_id: "D0C8DD",
    names: [
      "Losmapimod",
      "585543-15-3",
      "GSK-AHAB",
      "GW856553",
      "GW856553X",
      "UNII-F2DQF16BXE",
      "F2DQF16BXE",
      "GW-856553",
      "6-[5-(Cyclopropylcarbamoyl)-3-Fluoro-2-Methylphenyl]-N-(2,2-Dimethylpropyl)Pyridine-3-Carboxamide",
      "Losmapimod [USAN:INN]",
      "Losmapimod (GW856553X)",
      "GSKAHAB",
      "GW 856553X",
      "SB 856553",
      "Losmapimod (USAN/INN)",
      "SCHEMBL1070401",
      "GTPL7835",
      "CHEMBL1088752",
      "EX-A486",
      "CHEBI:131167",
      "KKYABQBFGDZVNQ-UHFFFAOYSA-N",
      "MolPort-009-194-138",
      "Losmapimod  Pound&gt",
      "HMS3653G19",
      "BCP09909",
      "AOB87105",
      "ZINC35793138",
      "S7215",
      "BDBM50418610",
      "2523AH",
      "AKOS015994587",
    ],
  },
  {
    primary_id: "D0C8EB",
    names: ["PMID26651364-Compound-45"],
  },
  {
    primary_id: "D0C8EO",
    names: [
      "5-[5-Methoxy-Indan-(1E)-Ylidenemethyl]-Thiazole",
      "(5-Thiazolemethylene)Indane 27a",
      "BDBM8632",
      "CHEMBL192155",
      "AC1O706R",
      "5-[(E)-(5-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]-1,3-Thiazole",
    ],
  },
  {
    primary_id: "D0C8ES",
    names: ["CBL0102"],
  },
  {
    primary_id: "D0C8EU",
    names: ["Temozolomide"],
  },
  {
    primary_id: "D0C8FX",
    names: ["Pyrithione Zinc"],
  },
  {
    primary_id: "D0C8FY",
    names: ["MRS1097"],
  },
  {
    primary_id: "D0C8GW",
    names: ["Quinolone Derivative 1"],
  },
  {
    primary_id: "D0C8HK",
    names: [
      "(E)-5-Octadecen-7,9-Diynoic Acid",
      "5E-Octadecene-7,9-Diynoic Acid",
      "CHEMBL489540",
      "LMFA01030737",
      "GTPL2963",
      "ACon1_001719",
      "BDBM50292398",
      "Compound 6a [Patil Et Al, 1989]",
      "(5E)-Octadec-5-En-7,9-Diynoic Acid",
      "NCGC00180210-01",
      "Compound 6a (Patil Et Al., 1989)",
    ],
  },
  {
    primary_id: "D0C8HN",
    names: ["13-(3-N-Pentylthioureido)Tridec-8(Z)-Enoic Acid", "CHEMBL551158"],
  },
  {
    primary_id: "D0C8HR",
    names: ["Halometasone"],
  },
  {
    primary_id: "D0C8IB",
    names: [
      "(R)-3-(1H-Imidazol-4-Yl)Propyl Sec-Butylcarbamate",
      "CHEMBL1080089",
      "BDBM50311765",
      "BDBM50074071",
      "[(1R)-1-Methylpropyl]carbamic Acid 3-(1H-Imidazole-4-Yl)Propyl Ester",
      "((R)-Sec-Butyl)-Carbamic Acid 3-(1H-Imidazol-4-Yl)-Propyl Ester",
    ],
  },
  {
    primary_id: "D0C8IF",
    names: ["LY3154207"],
  },
  {
    primary_id: "D0C8JW",
    names: ["Muscular Dystrophy Gene Therapy"],
  },
  {
    primary_id: "D0C8JY",
    names: ["Rociletinib"],
  },
  {
    primary_id: "D0C8KC",
    names: [
      "5,8-Dimethoxy-1,4-Dimethylquinolin-2(1H)-One",
      "5,8-Dimethoxy-1,4-Dimethylquinolin-2-One",
      "131451-78-0",
      "BAS 13027560",
      "Casimiroin Analogue, 1l",
      "AC1LT9OM",
      "5,8-Dimethoxy-1,4-Dimethyl-1H-Quinolin-2-One",
      "CHEMBL490867",
      "BDBM29223",
      "MolPort-002-024-473",
      "FTGZPMFPUDKJBX-UHFFFAOYSA-N",
      "HMS3604K14",
      "ZINC1423886",
      "STK736911",
      "AKOS000643970",
      "DB08228",
      "MCULE-6770591553",
      "ST4106238",
      "5,8-Dimethoxy-1,4-Dimethylhydroquinolin-2-One",
      "5,8-Dimethoxy-1,4-Dimethyl-2(1H)-Quinolinone",
      "1,4-Dimethyl-5,8-Dimethoxyquinolin-2(1H)-One",
      "1H-Quinolin-2-One, 5,8-Dimethoxy-1",
    ],
  },
  {
    primary_id: "D0C8KF",
    names: ["Resiquimod"],
  },
  {
    primary_id: "D0C8KP",
    names: ["Santalum Album Cream"],
  },
  {
    primary_id: "D0C8KY",
    names: ["SYN-1001"],
  },
  {
    primary_id: "D0C8LA",
    names: [
      "L-734115",
      "CHEMBL40502",
      "BDBM50058236",
      "N-(Butylsulfonyl)-3-[[4,5,6,7-Tetrahydro-4-Oxo-5-[2-(4-Piperidinyl)Ethyl]pyrazolo[1,5-A]pyrazin]-2-Ylcarbonylamino]-L-Alanine",
      "(S)-2-(Butane-1-Sulfonylamino)-3-{[4-Oxo-5-(2-Piperidin-4-Yl-Ethyl)-4,5,6,7-Tetrahydro-Pyrazolo[1,5-A]pyrazine-2-Carbonyl]-Amino}-Propionic Acid",
      "2-(Butane-1-Sulfonylamino)-3-{[4-Oxo-5-(2-Piperidin-4-Yl-Ethyl)-4,5,6,7-Tetrahydro-Pyrazolo[1,5-A]pyrazine-2-Carbonyl]-Amino}-Propionic Acid(L-734115)",
    ],
  },
  {
    primary_id: "D0C8LG",
    names: ["CLT-010"],
  },
  {
    primary_id: "D0C8MG",
    names: ["SELA-212"],
  },
  {
    primary_id: "D0C8MK",
    names: ["2-Morpholin-4-Yl-8-Phenoxathiin-4-Ylchromen-4-One", "CHEMBL197551"],
  },
  {
    primary_id: "D0C8MT",
    names: [
      "2-(7-(Benzyloxy)-1H-Indol-3-Yl)Ethanamine",
      "7-Benzyloxytryptamine",
      "31677-75-5",
      "CHEMBL460302",
      "2-[7-(Benzyloxy)-1h-Indol-3-Yl]ethanamine",
      "JOYGWYISRWPUIM-UHFFFAOYSA-N",
      "3-(2-AMINOETHYL)-7-BENZYLOXYINDOLE",
      "1H-Indole-3-Ethanamine,7-(Phenylmethoxy)-",
      "B-2160",
      "NSC92541",
      "7-Benzyloxytriptamine",
      "AC1Q57HL",
      "SCHEMBL5167328",
      "AC1L63X1",
      "CTK4G7564",
      "ZINC39096",
      "7-Benzyloxytryptamine, Free Base",
      "DTXSID60293835",
      "6038AH",
      "NSC-92541",
      "BDBM50247069",
      "AKOS024282335",
      "7-(Benzyloxy)-1H-Indole-3-Ethanamine",
      "MCULE-8072963757",
      "ACM31677755",
      "ST055630",
    ],
  },
  {
    primary_id: "D0C8NG",
    names: ["BI 655064"],
  },
  {
    primary_id: "D0C8NK",
    names: ["TG-21"],
  },
  {
    primary_id: "D0C8ON",
    names: [
      "1-Aminoanthracene-9,10-Dione",
      "1-AMINOANTHRAQUINONE",
      "82-45-1",
      "Diazo Fast Red AL",
      "1-Amino Anthraquinone",
      "Anthraquinone, 1-Amino-",
      "1-Aminoanthrachinon",
      "1-Amino-Anthraquinone",
      "9,10-Anthracenedione, 1-Amino-",
      "Alpha-Aminoanthraquinone",
      "1-Amino-9,10-Anthraquinone",
      "C.I. 37275",
      "1-Amino-9,10-Anthracenedione",
      "Alpha-Anthraquinonylamine",
      "UNII-N5YYY1NEUI",
      "NSC 458",
      "1-Aminoquinone",
      ".alpha.-Aminoanthraquinone",
      "NSC 30415",
      "1-Aminoanthrachinon [Czech]",
      "9,10-Anthracenedione, Amino-",
      "N5YYY1NEUI",
      "EINECS 201-423-5",
      ".alpha.-Anthraquinonylamine",
      "BRN 039636",
    ],
  },
  {
    primary_id: "D0C8PE",
    names: ["JNJ-37822681"],
  },
  {
    primary_id: "D0C8PI",
    names: [
      "Zoniporide Hydrochloride",
      "CP-597396",
      "EMD-392426",
      "Zoniporide Hydrochloride < Prop INNM",
      "N''-[5-Cyclopropyl-1-(5-Quinolyl)-1H-Pyrazol-4-Ylcarbonyl]guanidine Hydrochloride Monohydrate",
    ],
  },
  {
    primary_id: "D0C8PQ",
    names: ["CVS-3083"],
  },
  {
    primary_id: "D0C8PW",
    names: ["Albunex"],
  },
  {
    primary_id: "D0C8QA",
    names: ["KITE-585"],
  },
  {
    primary_id: "D0C8QS",
    names: ["Ragwitek"],
  },
  {
    primary_id: "D0C8RD",
    names: [
      "N-Benzyl,N-Methyl-1H-Pyrrole-2-Carboxamide",
      "CHEMBL352538",
      "N-Benzyl-N-Methyl-1H-Pyrrole-2-Carboxamide",
      "Pyrrole Inhibitor 10",
      "BDBM15584",
      "ZINC13493570",
    ],
  },
  {
    primary_id: "D0C8RH",
    names: ["Cericlamine"],
  },
  {
    primary_id: "D0C8RW",
    names: ["Rec-Antihemophilic Factor", "Advate (TN)"],
  },
  {
    primary_id: "D0C8SX",
    names: ["OG-L002"],
  },
  {
    primary_id: "D0C8TD",
    names: ["Thrombin-JMI"],
  },
  {
    primary_id: "D0C8UF",
    names: [
      "Rac-2q",
      "CHEMBL246964",
      "SCHEMBL1186511",
      "MZKGVXPHEXNJSG-UHFFFAOYSA-N",
      "BDBM50210099",
      "[4-Chloro-2-({1-[(4-Chlorophenyl)Sulfonyl]-3,7-Dioxo-1,4-Diazepan-6-Yl}methyl)Phenoxy]acetic Acid",
    ],
  },
  {
    primary_id: "D0C8UG",
    names: ["Enobosarm"],
  },
  {
    primary_id: "D0C8UN",
    names: ["KT-3799"],
  },
  {
    primary_id: "D0C8UV",
    names: [
      "4-(4-Benzenesulfonylphenyl)Piperazine",
      "CHEMBL211577",
      "1-[4-(Benzenesulfonyl)Phenyl]piperazine",
      "AC1NOI8E",
      "SCHEMBL4573254",
      "4-(1-Piperazinyl)Diphenyl Sulfone",
      "ZINC3716178",
      "BDBM50193458",
      "AKOS025215167",
      "MCULE-7301402628",
    ],
  },
  {
    primary_id: "D0C8VF",
    names: ["Galcanezumab"],
  },
  {
    primary_id: "D0C8WA",
    names: ["ISIS 133242"],
  },
  {
    primary_id: "D0C8WL",
    names: ["EPIDEPRIDE"],
  },
  {
    primary_id: "D0C8XT",
    names: ["DLO6001"],
  },
  {
    primary_id: "D0C8XX",
    names: ["D[Thr4,Orn8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D0C8YK",
    names: ["Cipro Oxacin Dry Powder"],
  },
  {
    primary_id: "D0C8ZD",
    names: [
      "A-71623",
      "130408-77-4",
      "CHEMBL323521",
      "A 71623",
      "A71623",
      "(3S)-4-[[(2S)-1-Amino-1-Oxo-3-Phenylpropan-2-Yl]-Methylamino]-3-[[(2S)-2-[[(2S)-3-(1H-Indol-3-Yl)-2-[(2-Methylpropan-2-Yl)Oxycarbonylamino]propanoyl]amino]-6-[(2-Methylphenyl)Carbamoylamino]hexanoyl]amino]-4-Oxobutanoic Acid",
      "PDSP1_000879",
      "AC1L3TQM",
      "GTPL858",
      "CHEMBL337366",
      "ZINC95610808",
      "PDSP2_000865",
      "BDBM50003666",
      "Boc-Trp-Lys(Epsilon-N-2-Methylphenylaminocarbonyl)-Asp-(N-Methyl)-Phe-NH2",
      "AKOS024457082",
      "KB-270509",
      "B7046",
      "J-005804",
    ],
  },
  {
    primary_id: "D0C8ZY",
    names: [
      "Beta-Naphthylboronic Acid",
      "2-Naphthaleneboronic Acid",
      "32316-92-0",
      "Naphthalen-2-Ylboronic Acid",
      "2-Naphthylboronic Acid",
      "Naphthalene-2-Boronic Acid",
      "2-Naphthalene Boronic Acid",
      "2-Naphthaleneylboronic Acid",
      "Naphthalenyl-2-Boronic Acid",
      "2-Naphtahaleneboronic Acid",
      "Naphthalen-2-Boronic Acid",
      "Naphthalen-2-Yl-Boranediol",
      "2-NAPHTHYLBORONIC CAID",
      "Boronic Acid, 2-Naphthalenyl-",
      "(Naphthalen-2-Yl)Boronic Acid",
      "CHEMBL141079",
      "MFCD00236051",
      "NAPHTHALEN-2-YL-2-BORONIC ACID",
      "2-Naphthaleneboronic Acid, 97+%",
      "Naphthalene-2-Boronicacid",
    ],
  },
  {
    primary_id: "D0C8ZZ",
    names: ["PMID25666693-Compound-12"],
  },
  {
    primary_id: "D0C9AD",
    names: [
      "1-(4-Chlorobenzyl)-1H-Indole-2,3-Dione",
      "26960-66-7",
      "1-(4-Chloro-Benzyl)-1h-Indole-2,3-Dione",
      "1-(4-Chlorobenzyl)Indoline-2,3-Dione",
      "NSC127077",
      "1-[(4-Chlorophenyl)Methyl]-2,3-Dihydro-1H-Indole-2,3-Dione",
      "1-[(4-Chlorophenyl)Methyl]indole-2,3-Dione",
      "1-[(4-Chlorophenyl)Methyl]benzo[d]azolidine-2,3-Dione",
      "4-Chlorobenzylisatin",
      "NSC 127077",
      "AC1Q3JHP",
      "AC1Q3NUX",
      "AC1Q3JHO",
      "CBMicro_042181",
      "AC1L5N5R",
      "Isatin-Based Compound, 15",
      "Cambridge Id 6046772",
      "1-(4-Chlorobenzyl)-Isatin",
      "CHEMBL376291",
      "SCHEMBL3027106",
      "BDBM22795",
      "CTK4F8812",
      "KS-00001RNX",
    ],
  },
  {
    primary_id: "D0C9AM",
    names: ["H-Gly-PMe-Glu-OH"],
  },
  {
    primary_id: "D0C9AN",
    names: ["Nitrite Inhalation", "Aironite (TN)"],
  },
  {
    primary_id: "D0C9AQ",
    names: ["MBI 1121"],
  },
  {
    primary_id: "D0C9AV",
    names: ["Mical (1)"],
  },
  {
    primary_id: "D0C9BH",
    names: [
      "Clorobiocin",
      "Chlorobiocin",
      "Antibiotic RP 18,631",
      "NSC 227186",
      "RP 18631",
      "18631 RP",
      "39868-96-7",
      "CHEMBL303984",
      "1H-Pyrrole-2-Carboxylic Acid, 5-Methyl-, 3'-Ester With N-(8-Chloro-7-((6-Deoxy-5-C-Methyl-4-O-Methyl-Alpha-L-Lyxo-Hexopyranosyl)Oxy)-4-Hydroxy-2-Oxo-2H-1-Benzopyran-3-Yl)-4-Hydroxy-3-(3-Methyl-2-Butenyl)Benzamide",
      "[(3R,4S,5R,6S)-6-[8-Chloro-4-Hydroxy-3-[[4-Hydroxy-3-(3-Methylbut-2-Enyl)Benzoyl]amino]-2-Oxo-Chromen-7-Yl]oxy-5-Hydroxy-3-Methoxy-2,2-Dimethyl-Tetrahydropyran-4-Yl] 5-Methyl-1H-Pyrrole-2-Carboxylate",
    ],
  },
  {
    primary_id: "D0C9BZ",
    names: ["N-Mesityl-4,6-Dimethyl-3-Tosylpyridin-2-Amine", "CHEMBL203609"],
  },
  {
    primary_id: "D0C9DA",
    names: ["Calaspargase Pegol"],
  },
  {
    primary_id: "D0C9DB",
    names: ["424C79", "BW-424C79"],
  },
  {
    primary_id: "D0C9DC",
    names: ["SK&F-64139"],
  },
  {
    primary_id: "D0C9DF",
    names: ["Buccal Fentanyl"],
  },
  {
    primary_id: "D0C9DP",
    names: ["ADS-103168", "CHEMBL424739", "SCHEMBL6532244", "BDBM50200783"],
  },
  {
    primary_id: "D0C9DS",
    names: ["Methyl Arachidonate"],
  },
  {
    primary_id: "D0C9EE",
    names: ["PAZELLIPTINE TRIHYDROCHLORIDE"],
  },
  {
    primary_id: "D0C9EN",
    names: ["AZD5312"],
  },
  {
    primary_id: "D0C9ET",
    names: ["Aflibercept"],
  },
  {
    primary_id: "D0C9FF",
    names: [
      "DNK-333",
      "DNK 333",
      "DNK333",
      "N-((R,R)-(E)-1-(3,4-Dichlorobenzyl)-3-(2-Oxoazepan-3-Yl)Carbamoyl)Allyl-N-Methyl-3,5-Bis(Trifluoromethyl)Benzamide",
    ],
  },
  {
    primary_id: "D0C9FH",
    names: ["N-[4-(3-Pyridylethynyl)-2-Thiazolyl]acetamide", "CHEMBL381482", "SCHEMBL4153643"],
  },
  {
    primary_id: "D0C9FK",
    names: ["TETRAHYDROQUINOLINE B"],
  },
  {
    primary_id: "D0C9FN",
    names: ["PMID25666693-Compound-87"],
  },
  {
    primary_id: "D0C9FO",
    names: [
      "2-(7-Phenylheptanoyl)Oxazole-5-Carboxylic Acid",
      "CHEMBL220729",
      "SCHEMBL1471352",
      "2-(7-Phenylheptanoyl)-1,3-Oxazole-5-Carboxylic Acid",
      "YYZORCCDLMGZTQ-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0C9FX",
    names: ["ISIS 330012"],
  },
  {
    primary_id: "D0C9GI",
    names: ["7-Fluoro-2-P-Tolyl-4H-Chromen-4-One", "CHEMBL599148"],
  },
  {
    primary_id: "D0C9GO",
    names: [
      "AcAsp-Glu-Leu-Glu-Cha-Cys",
      "CHEMBL305570",
      "AC1LAON7",
      "Ac-Asp-Glu-Leu-Glu-Cha-Cys-OH",
      "BDBM50096404",
      "AcAsp-Glu-Leu-Glu-.beta.-Cyclohexylalanine-Cys",
      "Ac-Asp-Glu-Leu-Glu-Beta-Cyclohexylalanine-Cys-OH",
      "Ac-L-Asp-Glu-L-Leu-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "(4S)-4-[[(2S)-2-[[(2S)-2-[[(2S)-2-Acetamido-4-Hydroxy-4-Oxobutanoyl]amino]-5-Hydroxy-5-Oxopentanoyl]amino]-4-Methylpentanoyl]amino]-5-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D0C9HB",
    names: ["2,7-Bis[2-(Isobutylamino)Acetamido]anthraquinone", "SCHEMBL4298304", "CHEMBL494456"],
  },
  {
    primary_id: "D0C9HE",
    names: ["Campyvax"],
  },
  {
    primary_id: "D0C9II",
    names: ["Salvinorin B 1-Ethoxyethyl Ether", "CHEMBL257922", "CHEMBL556996", "BDBM50374630"],
  },
  {
    primary_id: "D0C9IS",
    names: ["P-9808"],
  },
  {
    primary_id: "D0C9IW",
    names: ["Factor Viia"],
  },
  {
    primary_id: "D0C9JI",
    names: ["Zn-DPA-B"],
  },
  {
    primary_id: "D0C9JJ",
    names: [
      "AR-12",
      "2-Amino-N-(4-(5-(Phenanthren-2-Yl)-3-(Trifluoromethyl)-1H-Pyrazol-1-Yl)Phenyl)Acetamide",
      "OSU-03012",
      "742112-33-0",
      "OSU 03012",
      "OSU03012",
      "PDK1 Inhibitor AR-12",
      "OSU-03012 (AR-12)",
      "UNII-EX3O2Q61UV",
      "AR 12",
      "EX3O2Q61UV",
      "CHEMBL1650595",
      "2-Amino-N-[4-[5-Phenanthren-2-Yl-3-(Trifluoromethyl)Pyrazol-1-Yl]phenyl]acetamide",
      "2-Amino-N-[4-[5-(2-Phenanthrenyl)-3-(Trifluoromethyl)-1H-Pyrazol-1-Yl]phenyl]acetamide",
      "PubChem22592",
      "MLS006010170",
      "SCHEMBL570472",
      "GTPL8005",
      "C26H19F3N4O",
      "EX-A253",
      "DTXSID50225206",
      "CHEBI:131196",
      "MolPort-009-019-120",
    ],
  },
  {
    primary_id: "D0C9JL",
    names: ["Y-23684", "2-(4-Chlorophenyl)-2,3,5,6-Tetrahydro[1]benzothiepino[5,4-C]pyridazin-3-One 7-Oxide"],
  },
  {
    primary_id: "D0C9KC",
    names: [
      "3-(3-Chloro-Phenyl)-5,7-Dihydroxy-Chromen-4-One",
      "CHEMBL7827",
      "SCHEMBL3355884",
      "62845-10-7",
      "CTK2B1467",
      "ZINC23489",
      "DTXSID10445847",
      "BDBM50074745",
      "AKOS030553076",
      "5,7-Dihydroxy-3-(3-Chlorophenyl)-4H-1-Benzopyran-4-One",
      "4H-1-Benzopyran-4-One, 3-(3-Chlorophenyl)-5,7-Dihydroxy-",
    ],
  },
  {
    primary_id: "D0C9KD",
    names: ["HOMOEPIBATIDINE"],
  },
  {
    primary_id: "D0C9KQ",
    names: ["Genimune"],
  },
  {
    primary_id: "D0C9KW",
    names: ["AVN-457"],
  },
  {
    primary_id: "D0C9KX",
    names: ["TSD-0014"],
  },
  {
    primary_id: "D0C9KY",
    names: ["Pyridobenzimidazole"],
  },
  {
    primary_id: "D0C9KZ",
    names: [
      "1,1':4',1''-Terphenyl-3,4''-Diol",
      "CHEMBL459976",
      "SCHEMBL1180998",
      "BDBM25864",
      "RPJZTMNVHGSNCA-UHFFFAOYSA-N",
      "ZINC40394061",
      "Hydroxyphenyl Substituted Benzene, 36",
      "3-[4-(4-Hydroxyphenyl)Phenyl]phenol",
    ],
  },
  {
    primary_id: "D0C9LE",
    names: ["YPL-1101"],
  },
  {
    primary_id: "D0C9LF",
    names: ["PMID18800762C19", "Bisphosphonate, 19", "GTPL3193", "BDBM25263"],
  },
  {
    primary_id: "D0C9LQ",
    names: ["LentiGlobin"],
  },
  {
    primary_id: "D0C9MA",
    names: ["ACH-4471"],
  },
  {
    primary_id: "D0C9MJ",
    names: ["Anti-MUC1 CAR-T Cells"],
  },
  {
    primary_id: "D0C9NE",
    names: ["Biopterin"],
  },
  {
    primary_id: "D0C9NJ",
    names: [
      "STA-5326",
      "Apilimod",
      "STA 5326",
      "STA5326",
      "N-[(3-Methylphenyl)Methylideneamino]-6-Morpholin-4-Yl-2-(2-Pyridin-2-Ylethoxy)Pyrimidin-4-Amine",
    ],
  },
  {
    primary_id: "D0C9NX",
    names: ["PMID29671355-Compound-59"],
  },
  {
    primary_id: "D0C9OT",
    names: ["N-(6-(Trifluoromethyl)-1H-Indazol-3-Yl)Butyramide"],
  },
  {
    primary_id: "D0C9PZ",
    names: ["PT2385"],
  },
  {
    primary_id: "D0C9QM",
    names: ["TH-1011"],
  },
  {
    primary_id: "D0C9QV",
    names: ["F-G-G-F-T-G-MeA-R-K-S-A-R-K-L-A-N-Q-COOH"],
  },
  {
    primary_id: "D0C9QW",
    names: [
      "5-Hydroxy-2-(4-Dimethylaminophenyl)Benzofuran",
      "CHEMBL204414",
      "CTK7J9733",
      "ZINC13686356",
      "BDBM50185950",
      "AKOS015966036",
    ],
  },
  {
    primary_id: "D0C9RC",
    names: ["NPY Antagonist"],
  },
  {
    primary_id: "D0C9RF",
    names: ["CGEN-25007"],
  },
  {
    primary_id: "D0C9RM",
    names: ["Nano-PGE1"],
  },
  {
    primary_id: "D0C9RO",
    names: [
      "N-(2-Benzyl),N-(1-Methylpyrrol-2-Ylmethyl)Amine",
      "Benzyl-(1-Methyl-1H-Pyrrol-2-Ylmethyl)-Amine",
      "73325-58-3",
      "CHEMBL223300",
      "[(1-Methylpyrrol-2-Yl)Methyl]benzylamine",
      "N-Benzyl-1-(1-Methyl-1H-Pyrrol-2-Yl)Methanamine",
      "Pyrrole Inhibitor 18",
      "BAS 10796078",
      "AC1M3ZOX",
      "SCHEMBL11567290",
      "CTK6I2711",
      "BDBM15593",
      "MolPort-000-863-754",
      "ZINC2868930",
      "SBB010992",
      "STK511232",
      "BBL030414",
      "8940AE",
      "AKOS000285009",
      "MCULE-8292930001",
      "ST063683",
      "Benzyl[(1-Methylpyrrol-2-Yl)Methyl]amine",
      "TR-044376",
      "KB-250848",
      "Benzyl (1-Methyl-1h-Pyrrol-2-Ylmethyl)Amine",
    ],
  },
  {
    primary_id: "D0C9RQ",
    names: ["PMID26924192-Compound-22"],
  },
  {
    primary_id: "D0C9RV",
    names: ["SRSS-016"],
  },
  {
    primary_id: "D0C9RZ",
    names: ["BU-4601A", "BU-4601B", "BU-4601C"],
  },
  {
    primary_id: "D0C9SW",
    names: [
      "Somatropin Intranasal RhGH",
      "ALTU-238",
      "CP-024",
      "HGH-191",
      "Somatropin (Intranasal)",
      "Human Growth Hormone (Crystalline), Altus/Genentech",
      "RhGH (Oral, Eligen), Novartis",
      "Somatropin (Crystalline), Altus/Genentech",
      "Somatropin (Sustained Release, Subcutaneous), Althea",
      "Humatrope (Oral, CADDYS), Lilly/Emisphere",
      "Recombinant Human Growth Hormone (Oral, CADDYS), Lilly/Emisphere",
      "RhGH (Oral, CADDYS), Lilly/Emisphere",
      "RhGH (Oral, Eligen), Emisphere/Novartis",
      "Somatropin (Nasal Spray, CriticalSorb, Growth Hormone Deficiency), Critical Pharmaceuticals",
      "Somatropin (Oral, CADDYS), Lilly/Emisphere",
      "Somatropin (Sustained Release, Subcutaneous), Altus/Genentech",
      "Human Growth Hormone, (Sustained Release, Subcutaneous), Altus/Genentech",
    ],
  },
  {
    primary_id: "D0C9SY",
    names: ["Vardenafil"],
  },
  {
    primary_id: "D0C9TH",
    names: ["ALD-451"],
  },
  {
    primary_id: "D0C9TP",
    names: ["CaspaCIDe Donor Lymphocyte Infusion"],
  },
  {
    primary_id: "D0C9TY",
    names: ["PSAB-OFP"],
  },
  {
    primary_id: "D0C9TZ",
    names: ["3-(3-Methanesulfonyl-Phenyl)-1-Propyl-Pyrrolidine", "CHEMBL310745"],
  },
  {
    primary_id: "D0C9UF",
    names: ["Desmethylastemizole"],
  },
  {
    primary_id: "D0C9VH",
    names: [
      "N-(4-Dimethylamino-But-2-Ynyl)-N-Methyl-Acetamide",
      "CHEMBL75358",
      "18327-42-9",
      "Acetamide, N-[4-(Dimethylamino)-2-Butyn-1-Yl]-N-Methyl-",
      "AC1Q5IBV",
      "AC1L2EPN",
      "SCHEMBL11727547",
      "CTK0A6148",
      "DTXSID20274645",
      "BDBM50009291",
      "AKOS030565254",
      "N-Methyl-N-[4-Dimethylamino-2-Butynyl]acetamide",
      "N-[4-(Dimethylamino)But-2-Ynyl]-N-Methylacetamide",
      "N-[4-(Dimethylamino)But-2-Yn-1-Yl]-N-Methylacetamide",
      "Acetamide, N-[4-(Dimethylamino)-2-Butynyl]-N-Methyl-",
    ],
  },
  {
    primary_id: "D0C9VN",
    names: [
      "10-Propyl-10H-Dibenzo[b,f][1,4]oxazepin-11-One",
      "10Pr-DBOA-11one",
      "10-Propyl-Dibenz(B,f)(1,4)Oxazepin-11-(10H)-One",
      "135810-41-2",
      "5-Propylbenzo[b][1,4]benzoxazepin-6-One",
      "10-Propyl-Dibenz[b,f][1,4]oxazepin-11-(10H)-One",
      "Dibenzoxazepinone 1",
      "AC1L9R9M",
      "CHEMBL56451",
      "SCHEMBL9004262",
      "BDBM1973",
      "DTXSID50159532",
      "OGPOBJPKGVLINB-UHFFFAOYSA-N",
      "10-Propyl-10H-Dibenz[b,f][1.4]oxazepin-11-One",
      "10-Propyldibenz[b,f][1,4]oxazepin-11(10H)-One",
    ],
  },
  {
    primary_id: "D0C9WD",
    names: ["Thiazole-Fused Cycloalkyl Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0C9WN",
    names: ["MKC-1106-PP"],
  },
  {
    primary_id: "D0C9WW",
    names: [
      "7-(3-Chlorobenzyloxy)-4-Carboxaldehyde-Coumarin",
      "7-[(3-Chlorobenzyl)Oxy]-2-Oxo-2h-Chromene-4-Carbaldehyde",
      "C17",
      "CHEMBL239508",
      "BDBM19189",
      "DB07512",
      "7-(3-Chlorobenzyloxy)-4-Carboxaldehyde-Coumarin, 3",
      "7-[(3-Chlorophenyl)Methoxy]-2-Oxochromene-4-Carbaldehyde",
    ],
  },
  {
    primary_id: "D0C9XA",
    names: [
      "GSK683699",
      "4-Aminobutanoic Acid",
      "4-Aminobutyric Acid",
      "GABA",
      "Gamma-Aminobutyric Acid",
      "1956/12/2",
      "Piperidic Acid",
      "Piperidinic Acid",
      "Aminalon",
      "Mielogen",
      "Gammalon",
      "Gaballon",
      "Butanoic Acid, 4-Amino-",
      "Mielomade",
      "Gammalone",
      "Gamarex",
      "Gammasol",
      "Gammar",
      "Reanal",
      "Immu-G",
      "Gamma-Amino-N-Butyric Acid",
      "Gamma-Aminobutanoic Acid",
      "Butyric Acid, 4-Amino-",
      "Gamastan",
      "Gammagee",
      "Gamulin",
      "4-Aminobutyrate",
      "Omega-Aminobutyric Acid",
      "3-Carboxypropylamine",
      "Gamma-Aminobuttersaeure",
      "4-Amino-Butanoic Acid",
      "Gamma-Amino Butyric Acid",
      "GAMMA-AMINO-BUTANOIC ACID",
    ],
  },
  {
    primary_id: "D0C9XB",
    names: ["D-PhGly-Cys-Tyr-D-Trp-Lys-Thr-Pen-Thr-NH2", "CHEMBL384284"],
  },
  {
    primary_id: "D0C9XD",
    names: ["L-152,804"],
  },
  {
    primary_id: "D0C9XJ",
    names: ["Epirubicin"],
  },
  {
    primary_id: "D0C9XK",
    names: ["4-(6-Hydroxy-Benzooxazol-2-Yl)-Benzene-1,2-Diol"],
  },
  {
    primary_id: "D0C9XW",
    names: ["6-Butyl-2,4-Dipropylaminopyrimidine", "CHEMBL352024", "BDBM50138883", "ZINC13559144"],
  },
  {
    primary_id: "D0C9ZE",
    names: ["Benzoic Acid 8-Aza-Bicyclo[3.2.1]oct-6-Yl Ester", "CHEMBL84113"],
  },
  {
    primary_id: "D0C9ZN",
    names: ["3,5-Bis(2-Methylpyridin-4-Yl)-1H-1,2,4-Triazole", "CHEMBL1079001", "SCHEMBL11589338"],
  },
  {
    primary_id: "D0C9ZW",
    names: ["PMID25656651-Compound-26a"],
  },
  {
    primary_id: "D0C9ZY",
    names: [
      "3-Hydroxy-2-Methoxybenzaldehyde",
      "66495-88-3",
      "CHEMBL507918",
      "3-Hydroxy-2-Methoxy-Benzaldehyde",
      "Hydroxy-Methoxy-Benzaldehyde",
      "SCHEMBL470709",
      "2-Methoxy-3-Hydroxybenzaldehyde",
      "CTK1J4694",
      "DTXSID70473025",
      "GRIWJVSWLJHHEM-UHFFFAOYSA-N",
      "MolPort-002-462-004",
      "Benzaldehyde,3-Hydroxy-2-Methoxy-",
      "KS-00000B2L",
      "Benzaldehyde, 3-Hydroxy-2-Methoxy-",
      "ZINC12505037",
      "BDBM50266963",
      "5891AJ",
      "AKOS000119267",
      "MB03871",
      "SY025931",
      "CS-11591",
      "KB-236283",
      "MFCD06656119 (95%)",
    ],
  },
  {
    primary_id: "D0CA0P",
    names: ["Certolizumab"],
  },
  {
    primary_id: "D0CA2S",
    names: ["HYDAMTIQ"],
  },
  {
    primary_id: "D0CA3J",
    names: ["GSK568859"],
  },
  {
    primary_id: "D0CA3M",
    names: ["Anti-Meso-CAR T Cells"],
  },
  {
    primary_id: "D0CA6J",
    names: ["Tioconazole"],
  },
  {
    primary_id: "D0CA7B",
    names: ["BDM-I"],
  },
  {
    primary_id: "D0CA9D",
    names: ["Enediyne Antibiotics"],
  },
  {
    primary_id: "D0CA9H",
    names: ["N-Benzyl-4-(2-Diphenyl)-1-Piperazinehexanamide", "CHEMBL492871"],
  },
  {
    primary_id: "D0CB1C",
    names: ["Tacrine Heterodimer Derivative 1"],
  },
  {
    primary_id: "D0CB2B",
    names: ["Serlopitant"],
  },
  {
    primary_id: "D0CB6N",
    names: ["AVI-6003"],
  },
  {
    primary_id: "D0CB7L",
    names: ["Hepatitis B Hyperimmune"],
  },
  {
    primary_id: "D0CB8D",
    names: ["PMID26924192-Compound-31"],
  },
  {
    primary_id: "D0CB8W",
    names: [
      "DE-081",
      "Apafant",
      "105219-56-5",
      "WEB-2086",
      "Web 2086",
      "Apafant [USAN:INN]",
      "Apafantum [INN-Latin]",
      "Apafanto [INN-Spanish]",
      "WEB 2086BS",
      "WEB2086",
      "UNII-J613NI05SV",
      "WEB 2086 BS",
      "BRN 4302553",
      "C22H22ClN5O2S",
      "J613NI05SV",
      "CHEMBL280164",
      "NCGC00092377-03",
      "NCGC00092377-02",
      "Apafantum",
      "Apafanto",
      "DSSTox_CID_28900",
      "DSSTox_RID_83168",
      "DSSTox_GSID_48974",
      "4-(3-(4-(O-Chlorophenyl)-9-Methyl-6H-Thieno(3,2-F)-S-Triazolo(4,3-A)(1,4)Diazepin-2-Yl)Propionyl)Morpholine",
      "[3H]apafant",
      "Morpholine",
      "Apafant (Ophthalmic Formulation), Santen",
    ],
  },
  {
    primary_id: "D0CB9B",
    names: [
      "4-(Thiazol-2-Yl)Thieno[3,2-D]pyrimidin-2-Amine",
      "CHEMBL259111",
      "4-(2-Thiazolyl)Thieno[3,2-D]pyrimidine-2-Amine",
      "443148-62-7",
      "SCHEMBL3684455",
    ],
  },
  {
    primary_id: "D0CC0B",
    names: ["Lysine Nz-Carboxylic Acid"],
  },
  {
    primary_id: "D0CC1P",
    names: ["Des-AA1,2,5-[D-Nal8,(NalphaMe)IAmp9]SRIF", "CHEMBL427658"],
  },
  {
    primary_id: "D0CC1Y",
    names: ["VML-262"],
  },
  {
    primary_id: "D0CC3O",
    names: ["APG-232"],
  },
  {
    primary_id: "D0CC6Q",
    names: ["PMID25522065-Compound-3"],
  },
  {
    primary_id: "D0CC6R",
    names: [
      "6-(2-Fluoro-4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL195878",
      "SCHEMBL3926652",
      "MAFQDFZNSVYFEW-UHFFFAOYSA-N",
      "ZINC13645034",
      "BDBM50168350",
    ],
  },
  {
    primary_id: "D0CC7A",
    names: ["PMID26004420-Compound-WO2010129379C"],
  },
  {
    primary_id: "D0CC7E",
    names: ["PMID28766366-Compound-Scheme25-3"],
  },
  {
    primary_id: "D0CC8L",
    names: [
      "CRILVASTATIN",
      "Crilvastatin < Rec INN",
      "PMD-387",
      "L-Pyroglutamic Acid Rac-Cis-3,3,5-Trimethylcyclohexyl Ester",
      "5-Oxo-L-Proline Rac-Cis-3,3,5-Trimethylcyclohexyl Ester",
    ],
  },
  {
    primary_id: "D0CD6I",
    names: [
      "CNTF, Syntex-Synergen, Cell Therapy",
      "Ciliary Neurotrophic Factor (Human)",
      "CNTF, Syntex-Synergen",
      "Cell Therapy (Neurodegeneration, CRIB)",
      "Ciliary Neurotrophic Factor, Syntex-Synergen",
      "Drug Implant (CNTF), CytoTherapeutics",
      "Drug Implant (Ciliary Neurotrophic Factor), CytoTherapeutics",
      "RhCNTF, Syntex-Synergen",
      "Cell Therapy (Neorodegeneration, ECT), CytoTherapeutics",
      "Cell Therapy (Neurodegeneration, CRIB), CytoTherapeutics",
    ],
  },
  {
    primary_id: "D0CD6Y",
    names: ["Pyrimidopyridazinone Derivative 1"],
  },
  {
    primary_id: "D0CD7Q",
    names: ["Beta-Cyclodextrin Conjugate Derivative 4"],
  },
  {
    primary_id: "D0CD8G",
    names: ["PMID25726713-Compound-19"],
  },
  {
    primary_id: "D0CE0O",
    names: [
      "Alisporivir",
      "DEB-025",
      "Debio-025",
      "HIV Infection Therapy, Debiopharm",
      "HCV Infection Therapy, Debiopharm/Novartis",
    ],
  },
  {
    primary_id: "D0CE0Y",
    names: ["Anti-CD19-CAR-T Cells"],
  },
  {
    primary_id: "D0CE1R",
    names: ["Antibodie Derivative 7"],
  },
  {
    primary_id: "D0CE3R",
    names: ["SU 6656"],
  },
  {
    primary_id: "D0CE4H",
    names: ["ISIS 102599"],
  },
  {
    primary_id: "D0CE5B",
    names: [
      "135PAM1",
      "GTPL8343",
      "3-[3,5-Bis(Trifluoromethyl)Phenyl]-1-[(3,4-Dichlorophenyl)Methyl]-1-[2-(5-Methoxy-1H-Indol-3-Yl)Ethyl]urea",
    ],
  },
  {
    primary_id: "D0CE5S",
    names: ["PD-4074"],
  },
  {
    primary_id: "D0CE6C",
    names: [
      "LMI070",
      "Branaplam",
      "1562338-42-4",
      "NVS-SM1",
      "UNII-P12R69543A",
      "LMI-070",
      "P12R69543A",
      "5-(1H-Pyrazol-4-Yl)-2-(6-((2,2,6,6-Tetramethylpiperidin-4-Yl)Oxy)Pyridazin-3-Yl)Phenol",
      "Branaplam [INN]",
      "SCHEMBL15475826",
      "LMI070 (NVS-SM1)",
      "STWTUEAWRAIWJG-UHFFFAOYSA-N",
      "BCP19909",
      "ZINC146605125",
      "AKOS030526592",
      "CS-5319",
      "HY-19620",
      "Phenol, 5-(1H-Pyrazol-4-Yl)-2-(6-((2,2,6,6-Tetramethyl-4-Piperidinyl)Oxy)-3-Pyridazinyl)-",
    ],
  },
  {
    primary_id: "D0CE6T",
    names: ["Elisartan Potassium"],
  },
  {
    primary_id: "D0CE7G",
    names: ["Cyclohexyl-Ethyl-Substituted Diaza And Triaza-Tricyclic Compound 1"],
  },
  {
    primary_id: "D0CE7V",
    names: ["PET Radiotracer"],
  },
  {
    primary_id: "D0CE7X",
    names: ["4-Benzylidene-1-(Cyclobutylo)Piperidine Derivative 1"],
  },
  {
    primary_id: "D0CE8B",
    names: ["PMID30074415-Compound-19"],
  },
  {
    primary_id: "D0CE8J",
    names: ["Genaconazole"],
  },
  {
    primary_id: "D0CE9U",
    names: ["Asp-Tyr(OSO3H)-Met-Gly-Trp-Met-Asp-Phe", "Asp-Tyr(SO3Na)-Met-Gly-Trp-Met-Asp-Phe-NH2"],
  },
  {
    primary_id: "D0CF0Y",
    names: ["Rolapitant"],
  },
  {
    primary_id: "D0CF2Q",
    names: ["Levamisole"],
  },
  {
    primary_id: "D0CF3B",
    names: ["Cyclotheonamide E5", "CHEMBL502059"],
  },
  {
    primary_id: "D0CF3H",
    names: ["HG-1070"],
  },
  {
    primary_id: "D0CF3M",
    names: ["Sifuvirtide"],
  },
  {
    primary_id: "D0CF4R",
    names: ["Diterpene Triepoxide Derivative 1"],
  },
  {
    primary_id: "D0CF8T",
    names: ["Z-521"],
  },
  {
    primary_id: "D0CF8Y",
    names: ["Pyrazoline Derivative 3"],
  },
  {
    primary_id: "D0CF9B",
    names: ["6,7-Dichloro-3-Thiophen-3-Yl-Quinoline", "CHEMBL66718", "SCHEMBL8503219", "ZINC13737709"],
  },
  {
    primary_id: "D0CF9E",
    names: ["GSK-315234A", "315234"],
  },
  {
    primary_id: "D0CG0D",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 33"],
  },
  {
    primary_id: "D0CG0G",
    names: [
      "11beta-Hydroxysteroid Dehydrogenase-1 (HSD1) Inhibitors",
      "11beta-HSD1 Inhibitors (Metabolic Disorders), Merck",
    ],
  },
  {
    primary_id: "D0CG0K",
    names: ["ATI-502 Topical"],
  },
  {
    primary_id: "D0CG0N",
    names: ["PRO-140"],
  },
  {
    primary_id: "D0CG1A",
    names: ["EBV-CTL"],
  },
  {
    primary_id: "D0CG2Y",
    names: ["2-Phenoxy-3-(Piperidin-4-Yl)Pyridine", "CHEMBL608148", "SCHEMBL4013368"],
  },
  {
    primary_id: "D0CG3V",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 25"],
  },
  {
    primary_id: "D0CG7Q",
    names: ["PMID10395480C6"],
  },
  {
    primary_id: "D0CG8N",
    names: ["Tri-Substituted Urea Derivative 2"],
  },
  {
    primary_id: "D0CG8S",
    names: [
      "Oxazine 89",
      "CHEMBL2347208",
      "N-{3-[(4s,6s)-2-Amino-4-Methyl-6-(Trifluoromethyl)-5,6-Dihydro-4h-1,3-Oxazin-4-Yl]-4-Fluorophenyl}-5-Cyanopyridine-2-Carboxamide",
      "GTPL6944",
      "SCHEMBL15991481",
      "BDBM50432601",
      "1HL",
    ],
  },
  {
    primary_id: "D0CG9E",
    names: ["SL-11093", "CGC-11093", "Trans-1,2-Bis[4-(Ethylamino)Butylaminomethyl]cyclopropane Tetrahydrochloride"],
  },
  {
    primary_id: "D0CH0K",
    names: [
      "1-(5-(Pyridin-2-Yl)Oxazol-2-Yl)Octadec-9-En-1-One",
      "CHEMBL426230",
      "SCHEMBL3193600",
      "2-Oleoyl-5-(2-Pyridyl)Oxazole",
      "BDBM50157122",
      "AKOS030561894",
      "1-(5-Pyridin-2-Yl-Oxazol-2-Yl)-Nonadec-10-En-2-One",
      "(Z)-1-(5-Pyridin-2-Yl-Oxazol-2-Yl)-Octadec-9-En-1-One",
    ],
  },
  {
    primary_id: "D0CH1U",
    names: ["Interleukin-3"],
  },
  {
    primary_id: "D0CH3F",
    names: ["Resorcinol Compound 16"],
  },
  {
    primary_id: "D0CH4A",
    names: ["SBC-102"],
  },
  {
    primary_id: "D0CH4M",
    names: ["PMID28092474-Compound-33e"],
  },
  {
    primary_id: "D0CH4O",
    names: ["PMID28394193-Compound-53"],
  },
  {
    primary_id: "D0CH4T",
    names: ["Lu-02-750", "Dopamine Receptor Agonist (Parkinson's Disease), H Lundbeck"],
  },
  {
    primary_id: "D0CH6B",
    names: ["ALZT-OP1", "ALZT-OP1a+ALZT-OP1b", "Cromolyn + Ibuprofen"],
  },
  {
    primary_id: "D0CH6Z",
    names: ["BBR 3576"],
  },
  {
    primary_id: "D0CH7C",
    names: [
      "Basmisanil",
      "1159600-41-5",
      "UNII-788PET5SUA",
      "788PET5SUA",
      "(1,1-Dioxidothiomorpholino)(6-((3-(4-Fluorophenyl)-5-Methylisoxazol-4-Yl)Methoxy)Pyridin-3-Yl)Methanone",
      "Basmisanil [INN]",
      "Basmisanil [USAN:INN]",
      "Basmisani",
      "Basmisanil(RG1662)",
      "Basmisanil (USAN/INN)",
      "SCHEMBL2685527",
      "CHEMBL3681419",
      "MolPort-044-561-818",
      "VCGRFBXVSFAGGA-UHFFFAOYSA-N",
      "BDBM133427",
      "EX-A1272",
      "AKOS032947142",
      "ZINC145814743",
      "DB11877",
      "CS-6046",
      "HY-16716",
      "(1,1-Dioxo-4-Thiomorpholinyl)(6-((3-(4-Fluorophenyl)-5-Methylisoxazol-4-Yl)Methoxy)Pyridin-3-Yl)Metha",
    ],
  },
  {
    primary_id: "D0CH7U",
    names: [
      "2'-Monophosphoadenosine-5'-Diphosphate",
      "2'-MONOPHOSPHOADENOSINE-5'-DIPHOSPHATE",
      "Adenosine 2'-(Dihydrogen Phosphate) 5'-(Trihydrogen Diphosphate)",
      "2-Phospho-Adenosine Diphosphate",
      "1afl",
      "AC1L4WBL",
      "Phosphoadenosine Diphosphate",
      "AC1Q6S1C",
      "2'P-ADP",
      "SCHEMBL905455",
      "5'-Diphosphoadenosine 2'-Phosphate",
      "DB02363",
      "Adenosine 2'-Phosphoric Acid 5'-Diphosphoric Acid",
      "Adenosine 5'-(Trihydrogen Diphorphate), 2'-(Dihydrogen Phosphate)",
      "[(2R,3R,4R,5R)-5-(6-Aminopurin-9-Yl)-3-Hydroxy-4-Phosphonooxyoxolan-2-Yl]methyl Phosphono Hydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0CH7Z",
    names: ["PMID28870136-Compound-44"],
  },
  {
    primary_id: "D0CH8E",
    names: ["Macrocyclic Peptide Analog 2"],
  },
  {
    primary_id: "D0CH8Q",
    names: ["PMID29671355-Compound-56"],
  },
  {
    primary_id: "D0CH9G",
    names: ["Pepvax"],
  },
  {
    primary_id: "D0CI2I",
    names: ["PMID26004420-Compound-WO2012058116B"],
  },
  {
    primary_id: "D0CI2U",
    names: ["Benzoic Acid Linked Peptide Analog 3"],
  },
  {
    primary_id: "D0CI3A",
    names: [
      "3-Iodotyrosine",
      "2-Amino-3-(4-Hydroxy-3-Iodophenyl)Propanoic Acid",
      "3078-39-5",
      "3-Iodo-DL-Tyrosine",
      "C9H10INO3",
      "3-Iodo-L-Tyr",
      "AC1Q4PGG",
      "ACMC-209og3",
      "SCHEMBL159411",
      "AC1L1M60",
      "GTPL5117",
      "CTK7I4445",
      "UQTZMGFTRHFAAM-UHFFFAOYSA-N",
      "MolPort-006-167-934",
      "NSC210787",
      "AKOS022187633",
      "S(-)-3-Iodo-4-Hydroxy- Phenylamine",
      "TRA0009499",
      "MCULE-7084802066",
      "AN-8912",
      "NSC-210787",
      "FCH1320103",
      "SC-11023",
      "KB-32311",
      "AX8285033",
      "Z5788",
      "2-Amino-3-(3-Iodo-4-Hydroxyphenyl)Propionic Acid",
      "2-Amino-3-(4-Hydroxy-3-Iodophenyl)Propionic Acid",
      "2-Amino-3-(4-Hydroxy-3-Iodo-Phenyl)Propanoic Acid",
    ],
  },
  {
    primary_id: "D0CI3C",
    names: ["Deschloroflavopiridol"],
  },
  {
    primary_id: "D0CI3E",
    names: ["Bicyclic Heteroaryl Carboxamide Analog 1"],
  },
  {
    primary_id: "D0CI4B",
    names: ["MP-100"],
  },
  {
    primary_id: "D0CI4E",
    names: ["Pertuzumab"],
  },
  {
    primary_id: "D0CI5O",
    names: ["SPD-474"],
  },
  {
    primary_id: "D0CI6E",
    names: ["Correloid", "CHEMBL362198"],
  },
  {
    primary_id: "D0CI6Y",
    names: ["PMID26004420-Compound-US20140336177D"],
  },
  {
    primary_id: "D0CI9L",
    names: ["Pyridine Derivative 13"],
  },
  {
    primary_id: "D0CI9T",
    names: [
      "Ceftobiprole",
      "BAL 9141",
      "BAL 9141-000",
      "BAL-9141",
      "Ro 63-9141",
      "Ro-63-9141",
      "Ro-63-9141/000",
      "(6R,7R)-7-[[(2Z)-2-(5-Amino-1,2,4-Thiadiazol-3-Ylidene)-2-Nitrosoacetyl]amino]-8-Oxo-3-[(E)-[2-Oxo-1-[(3R)-Pyrrolidin-3-Yl]pyrrolidin-3-Ylidene]methyl]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0CI9W",
    names: ["GSK-334429"],
  },
  {
    primary_id: "D0CJ3R",
    names: ["MS201408-0005C"],
  },
  {
    primary_id: "D0CJ6P",
    names: ["2-(1-Dodecyl-1H-Indol-3-Yl)Acetic Acid", "CHEMBL521836"],
  },
  {
    primary_id: "D0CJ7F",
    names: ["PMID25656651-Compound-15c"],
  },
  {
    primary_id: "D0CJ7L",
    names: [
      "N-Hydroxy-8-(Naphthalen-2-Yl)Oct-7-Enamide",
      "CHEMBL451182",
      "SCHEMBL3382916",
      "SCHEMBL3382922",
      "PGGPUSMJSOKMEA-XBXARRHUSA-N",
      "ZINC13474418",
      "BDBM50114829",
      "(E)-N-Hydroxy-8-(2-Naphthyl)-7-Octenamide",
      "8-Naphthalen-2-Yl-Oct-7-Enoic Acid Hydroxyamide",
      "(E)-8-Naphthalen-2-Yl-Oct-7-Enoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0CJ7Z",
    names: ["Abicipar Pegol"],
  },
  {
    primary_id: "D0CK0K",
    names: ["Ac-Nle-C[Asp-His-DNal(2')-Pro-Trp-Lys]-NH2"],
  },
  {
    primary_id: "D0CK1D",
    names: ["3-Benzyloxyphenyloxoacetic Acid Derivative 1"],
  },
  {
    primary_id: "D0CK2C",
    names: ["ZP-10395"],
  },
  {
    primary_id: "D0CK3G",
    names: ["Ethinamate"],
  },
  {
    primary_id: "D0CK5J",
    names: ["SF-116"],
  },
  {
    primary_id: "D0CK6M",
    names: ["Fused Aryl Carbocycle Derivative 3"],
  },
  {
    primary_id: "D0CK7F",
    names: ["AOB103"],
  },
  {
    primary_id: "D0CK8C",
    names: ["H-Tyr-D-Ala-Gly-Phe-Pro-Leu-Trp-O-Bzl"],
  },
  {
    primary_id: "D0CK8H",
    names: ["Indazole Derivative 1"],
  },
  {
    primary_id: "D0CK9K",
    names: [
      "EDMT",
      "2-Ethyl-5-Methoxy-N,N-Dimethyltryptamine",
      "EMDT",
      "CHEMBL267615",
      "2-(2-Ethyl-5-Methoxy-1H-Indol-3-Yl)-N,N-Dimethylethanamine",
      "AC1OCFIZ",
      "GTPL12",
      "SCHEMBL2537573",
      "BDBM34142",
      "ZINC13805803",
      "PDSP1_001417",
      "PDSP2_001401",
      "AKOS027422828",
      "NCGC00387149-01",
      "N,N-Dimethyl-2-Ethyl-5-Methoxy-1H-Indole-3-Ethanamine",
      "[2-(2-Ethyl-5-Methoxy-1H-Indol-3-Yl)Ethyl]dimethylamine,",
    ],
  },
  {
    primary_id: "D0CK9Q",
    names: ["BMS-986299"],
  },
  {
    primary_id: "D0CL1D",
    names: ["Dihydroisoxazole Derivative 2"],
  },
  {
    primary_id: "D0CL2E",
    names: ["Iodophenyl-N-Methyl-N-Fluoroalkyl-3-Isoquinoline Carboxamide Derivative 3"],
  },
  {
    primary_id: "D0CL5D",
    names: ["Ragweed Allergy Vaccine"],
  },
  {
    primary_id: "D0CL8Q",
    names: ["PMID: 30247903_Peptide01"],
  },
  {
    primary_id: "D0CL9S",
    names: ["Telbivudine"],
  },
  {
    primary_id: "D0CM3O",
    names: ["[Au(CN)2]-"],
  },
  {
    primary_id: "D0CM3Z",
    names: ["ATON-009"],
  },
  {
    primary_id: "D0CM4R",
    names: ["Lactol Derivative 2"],
  },
  {
    primary_id: "D0CM5P",
    names: ["Quinazoline Derivative 10"],
  },
  {
    primary_id: "D0CM6T",
    names: ["A-674563", "A 674563", "A674563"],
  },
  {
    primary_id: "D0CN6Q",
    names: ["PMID26651364-Compound-5c"],
  },
  {
    primary_id: "D0CN7A",
    names: ["PMID26651364-Compound-7c"],
  },
  {
    primary_id: "D0CN7I",
    names: ["IMC-CS4"],
  },
  {
    primary_id: "D0CN8U",
    names: ["PF-06763809"],
  },
  {
    primary_id: "D0CN8Z",
    names: [
      "1,2-Bis(3,4,5-Trifluorophenyl)Ethane-1,2-Dione",
      "CHEMBL193180",
      "Benzil-Based Compound, 13",
      "SCHEMBL2156255",
    ],
  },
  {
    primary_id: "D0CN9D",
    names: ["Anetumab Ravtansine"],
  },
  {
    primary_id: "D0CN9Q",
    names: [
      "CP-724714",
      "CP 724714",
      "CP-724,714",
      "CP724,714, CP-724,714",
      "2-Methoxy-N-(3-(4-((3-Methyl-4-((6-Methyl-3-Pyridinyl)Oxy)Phenyl)Amino)-6-Quinazolinyl)-2-Propenyl)Acetamide",
      "2-Methoxy-N-[(2E)-3-[4-[[3-Methyl-4-[(6-Methyl-3-Pyridinyl)Oxy]phenyl]amino]-6-Quinazolinyl]-2-Propen-1-Yl]acetamide",
    ],
  },
  {
    primary_id: "D0CO2G",
    names: ["Carboxamide Derivative 8"],
  },
  {
    primary_id: "D0CO2M",
    names: ["S-9788"],
  },
  {
    primary_id: "D0CO2W",
    names: ["AMG 301"],
  },
  {
    primary_id: "D0CO3N",
    names: ["Esuberaprost", "APS-314D Free Acid", "BPS-314d"],
  },
  {
    primary_id: "D0CO3O",
    names: ["RAP-701"],
  },
  {
    primary_id: "D0CO4E",
    names: ["DMT210 Topical Gel"],
  },
  {
    primary_id: "D0CO5F",
    names: ["ALLOD-2"],
  },
  {
    primary_id: "D0CO5S",
    names: [
      "6-Chloro-5-Hexylpyrimidine-2,4(1H,3H)-Dione",
      "CHEMBL234997",
      "BDBM20058",
      "5-Substituted-6-Chlorouracil, 5e",
    ],
  },
  {
    primary_id: "D0CO5Y",
    names: [
      "Bowman Birk Inhibitor Concentrate",
      "BBIC, Pennsylvania University/California University/ProtoMed",
      "Bowman-Birk Inhibitor Concentrate, Pennsylvania University/California University/ProtoMed",
    ],
  },
  {
    primary_id: "D0CO6D",
    names: ["AMG 333"],
  },
  {
    primary_id: "D0CO6T",
    names: ["NAPHTHYRIDINONE"],
  },
  {
    primary_id: "D0CO9B",
    names: ["Ad-P53"],
  },
  {
    primary_id: "D0CO9Z",
    names: [
      "Edg5 Antagonists",
      "Edg5 Antagonists, Merck",
      "Sphingosine 1-Phosphate 2 Antagonist (Hypertension), Merck",
    ],
  },
  {
    primary_id: "D0CP1J",
    names: [
      "1G244",
      "CHEMBL1814633",
      "(2~{S})-2-Azanyl-4-[4-[bis(4-Fluorophenyl)Methyl]piperazin-1-Yl]-1-(1,3-Dihydroisoindol-2-Yl)Butane-1,4-Dione",
      "GTPL8551",
      "SCHEMBL19350285",
      "C29H30N4O2F2",
      "ZINC14949370",
      "BDBM50350169",
      "AKOS030528223",
      "847928-32-9",
      "(S)-2-Amino-4-(4-(Bis(4-Fluorophenyl)Methyl)Piperazin-1-Yl)-1-(Isoindolin-2-Yl)Butane-1,4-Dione",
      "Y0432",
    ],
  },
  {
    primary_id: "D0CP3M",
    names: [
      "4,5,6,7-Tetrabromo-1H-Benzimidazole",
      "4,5,6,7-Tetrabromobenzimidazole",
      "TBBz",
      "577779-57-8",
      "4,5,6,7-Tetrabromo-Benzimidazole",
      "4,5,6,7-Tetrabromo-1H-Benzo[d]imidazole",
      "CHEMBL373937",
      "2oxy",
      "AC1NP4E4",
      "SCHEMBL406945",
      "CTK5I6050",
      "DTXSID80408803",
      "MolPort-003-850-464",
      "LOEIRDBRYBHAJB-UHFFFAOYSA-N",
      "HMS3260K22",
      "ZINC2528524",
      "Tox21_500140",
      "BDBM50156669",
      "TBBz, &gt",
      "MFCD04116202",
      "4,5,6,7-Tetrabromo-1Hbenzimidazole",
      "LP00140",
      "CCG-221444",
      "NCGC00260825-01",
      "NCGC00165910-02",
      "NCGC00165910-01",
      "RT-009016",
      "FT-0674936",
    ],
  },
  {
    primary_id: "D0CP3Z",
    names: ["ALS-012117"],
  },
  {
    primary_id: "D0CP4E",
    names: [
      "Clonazepam",
      "Antelepsin",
      "Antilepsin",
      "Chlonazepam",
      "Cloazepam",
      "Clonazepamum",
      "Clonex",
      "Clonopin",
      "Iktorivil",
      "Kenoket",
      "Klonopin",
      "Landsen",
      "Lktorivil",
      "Lonazep",
      "Melzap",
      "Paxam",
      "Ravotril",
      "Rivatril",
      "Rivoril",
      "Rivotril",
      "Solfidin",
      "Klonopin Rapidly Disintegrating",
      "DF2374250",
      "RO4023",
      "Ro 4023",
      "Ro 54023",
      "Alti-Clonazepam",
      "Clonazepamum [INN-Latin]",
      "Klonopin (TN)",
      "Ravotril (TN)",
      "Rivatril (TN)",
      "Rivotril (TN)",
      "Ro 4-8180",
      "Ro 5-4023",
      "Ro-5-4023",
      "Clonazepam (JP15/USP/INN)",
      "Clonazepam [USAN:INN:BAN:JAN]",
      "Ro 5-4023/B-7",
      "1,3-Dihydro-5-(O-Chlorophenyl)-7-Nitro-3H-1,4-Benzodiazepin-2-One",
      "1,3-Dihydro-7-Nitro-5-(2-Chlorophenyl)-2H-1,4.benzodiazepin-2-One",
      "5-(2-Chloro-Phenyl)-7-Nitro-1,3-Dihydro-Benzo[e][1,4]diazepin-2-One",
      "5-(2-Chlorophenyl)-1,3-Dihydro-7-Nitro-2H-1,4-Benzodiazepin-2-One",
      "5-(2-Chlorophenyl)-7-Nitro-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "5-(2-Chlorophenyl)-7-Nitro-3H-1,4-Benzodiazepin-2(1H)-One",
      "5-(2-Chlorophenyl)-7-Nitro-1,3-Dihydro-1,4-Benzodiazepin-2-One",
      "5-(O-Chlorophenyl)-1,3-Dihydro-7-Nitro-2H-1,4-Benzodiazepin-2-One",
      "5-(O-Chlorophenyl)-7-Nitro-1H-1,4-Benzodiazepin-2(3H)-One",
      "5-(O-Chlorophenyl)-7-Nitro-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Nitro-5-(2-Chlorophenyl)-3H-1,4-Benzodiazepin-2(1H)-One",
    ],
  },
  {
    primary_id: "D0CP6B",
    names: ["3-(Phenoxymethyl) Benzylamine Derivative 5"],
  },
  {
    primary_id: "D0CQ0Z",
    names: ["Penfluridol"],
  },
  {
    primary_id: "D0CQ1J",
    names: ["2'-Aminoimidazolylmethyluracils"],
  },
  {
    primary_id: "D0CQ1M",
    names: ["Heterocyclic-Carboxamide Derivative 2"],
  },
  {
    primary_id: "D0CQ1X",
    names: ["HTERT RNA Vaccine"],
  },
  {
    primary_id: "D0CQ2O",
    names: ["Interferon Beta-1b", "Betaferon (TN)", "Betaseron (TN)"],
  },
  {
    primary_id: "D0CQ2P",
    names: ["SPL-7013"],
  },
  {
    primary_id: "D0CQ3A",
    names: ["Tributylstannanyl"],
  },
  {
    primary_id: "D0CQ5A",
    names: ["SKL20540"],
  },
  {
    primary_id: "D0CQ5F",
    names: ["3,4-Epoxydehydroleucodin", "Eminensin A"],
  },
  {
    primary_id: "D0CQ5J",
    names: ["Antifolate"],
  },
  {
    primary_id: "D0CQ6I",
    names: [
      "3-[(2-Methyl-4-Thiazolyl)Ethynyl]-5-Vinylpyridine",
      "CHEMBL202307",
      "SCHEMBL4160862",
      "BDBM50181753",
      "ZINC13680962",
      "US8609852, 78",
    ],
  },
  {
    primary_id: "D0CQ8D",
    names: ["AMR-103"],
  },
  {
    primary_id: "D0CR0H",
    names: ["PMID25666693-Compound-138"],
  },
  {
    primary_id: "D0CR0Y",
    names: ["PMID27215781-Compound-31"],
  },
  {
    primary_id: "D0CR1I",
    names: ["TJ-9"],
  },
  {
    primary_id: "D0CR2E",
    names: ["Thiadiazolidindione Derivative 3"],
  },
  {
    primary_id: "D0CR4Z",
    names: ["IPI-940"],
  },
  {
    primary_id: "D0CR6B",
    names: [
      "4'-Cyano-3-(Imidazolylmethyl)Flavone",
      "CHEMBL209731",
      "BDBM50191602",
      "4''-Cyano-3-(Imidazolylmethyl)Flavone",
    ],
  },
  {
    primary_id: "D0CR6M",
    names: [
      "6-Bromo-3-Ethoxycarbonyl-2-Methyl-4-Quinolone",
      "CHEMBL206762",
      "88960-40-1",
      "3-Quinolinecarboxylic Acid, 6-Bromo-4-Hydroxy-2-Methyl-, Ethyl Ester",
      "ACMC-20lfhk",
      "CTK3A4318",
      "DTXSID10469528",
    ],
  },
  {
    primary_id: "D0CR7L",
    names: ["GSK3326595"],
  },
  {
    primary_id: "D0CR8H",
    names: [
      "SYM-004",
      "Chimeric IgG1 Antibody 1024 (Cancer), Symphogen",
      "Chimeric IgG1 Antibody 992 (Cancer), Symphogen",
      "Chimeric IgG1 Antibodies992 + 1024 (Cancer), Symphogen",
    ],
  },
  {
    primary_id: "D0CR8J",
    names: ["Biphenyl Mannoside Derivative 26"],
  },
  {
    primary_id: "D0CR8V",
    names: ["HOMOERIODICTYOL"],
  },
  {
    primary_id: "D0CR9C",
    names: ["Moxetumomab Pasudotox"],
  },
  {
    primary_id: "D0CR9I",
    names: [
      "CX-5011",
      "1333382-30-1",
      "UNII-2F5JG09KS6",
      "2F5JG09KS6",
      "Sodium 5-(3-Ethynylphenylamino)Pyrimido[4,5-C]quinoline-8-Carboxylate",
      "AOB1816",
      "AKOS027422619",
      "Pyrimido(4,5-C)Quinoline-8-Carboxylic Acid, 5-((3-Ethynylphenyl)Amino)-, Sodium Salt (1:1)",
    ],
  },
  {
    primary_id: "D0CR9L",
    names: ["Daromun", "Darleukin + Fibromun"],
  },
  {
    primary_id: "D0CS0D",
    names: ["WAY-121898"],
  },
  {
    primary_id: "D0CS2C",
    names: [
      "AZD9056",
      "AZD-9056",
      "345304-65-6",
      "UNII-F13K378W4L",
      "N-(1-Adamantylmethyl)-2-Chloro-5-[3-(3-Hydroxypropylamino)Propyl]benzamide",
      "F13K378W4L",
      "AZD 9056",
      "GTPL7826",
      "SCHEMBL4126642",
      "CHEMBL3545108",
      "HSQAARMBHJCUOK-UHFFFAOYSA-N",
      "MolPort-044-723-510",
      "KS-000000WO",
      "BCP25185",
      "ZINC34356159",
      "AKOS030228502",
      "DB12594",
      "Benzamide, 2-Chloro-5-(3-((3-Hydroxypropyl)Amino)Propyl)-N-(Tricyclo(3.3.1.13,7)Dec-1-Ylmethyl)-",
      "2-Chloro-5-[3-[(3-Hydroxypropyl)Amino]propyl]-N-(Tricyclo[3.3.1.13,7]dec-1-Ylmethyl)-Benzamide",
    ],
  },
  {
    primary_id: "D0CS2F",
    names: ["Loperamide"],
  },
  {
    primary_id: "D0CS2L",
    names: [
      "DG-1-66",
      "DG-1-43",
      "U77 Helicase Inhibitors (HHV-6 Infection)",
      "U77 Helicase Inhibitors (HHV-6 Infection), Rega Institute",
    ],
  },
  {
    primary_id: "D0CS3A",
    names: ["CART-19"],
  },
  {
    primary_id: "D0CS4I",
    names: ["15(R)-15-Methyl-PGD2", "15R-15-Methyl PGD2"],
  },
  {
    primary_id: "D0CS4K",
    names: ["ISIS 29224"],
  },
  {
    primary_id: "D0CS6N",
    names: ["Benzodiazepine", "Benzodiazepine (Aversion, Anxiety)"],
  },
  {
    primary_id: "D0CS9R",
    names: ["PMID25991433-Compound-B1"],
  },
  {
    primary_id: "D0CT1F",
    names: ["Methoprene Acid"],
  },
  {
    primary_id: "D0CT1S",
    names: ["BB IND-6989"],
  },
  {
    primary_id: "D0CT3I",
    names: ["AgaDerm", "Antifungal Aganocide Compound (Topical, Dermatophytosis), NovaBay Pharmaceuticals"],
  },
  {
    primary_id: "D0CT4D",
    names: ["Brivaracetam"],
  },
  {
    primary_id: "D0CT5O",
    names: ["ALX-101"],
  },
  {
    primary_id: "D0CT5Z",
    names: ["SLP-0907"],
  },
  {
    primary_id: "D0CT7M",
    names: ["A-6", "Angstrom-6", "CD44 Binding Peptide (Cancer), Angstrom"],
  },
  {
    primary_id: "D0CT7P",
    names: ["2-(Pyridin-2-Yl)-4-(M-Tolylthio)Pyrimidine", "CHEMBL208730", "SCHEMBL6512812", "BDBM50182815"],
  },
  {
    primary_id: "D0CT8A",
    names: ["4-(3,4-Diethoxy-Benzylamino)-Benzamidine", "Benzylamine 5", "CHEMBL181265", "BDBM13579"],
  },
  {
    primary_id: "D0CT9C",
    names: [
      "Cyclophosphamide",
      "ASTA",
      "Ciclofosfamida",
      "Ciclophosphamide",
      "Clafen",
      "Claphene",
      "Cycloblastin",
      "Cyclophosphamid",
      "Cyclophosphamides",
      "Cyclophosphamidum",
      "Cyclophosphan",
      "Cyclophosphane",
      "Cyclophosphanum",
      "Cyclophosphoramide",
      "Cyclostin",
      "Cyklofosfamid",
      "Cytophosphan",
      "Cytophosphane",
      "Cytoxan",
      "Endoxan",
      "Endoxana",
      "Endoxanal",
      "Endoxane",
      "Enduxan",
      "Genoxal",
      "Mitoxan",
      "Neosar",
      "Procytox",
      "Revimmune",
      "Semdoxan",
      "Sendoxan",
      "Senduxan",
      "Zyklophosphamid",
      "Ciclophosphamide [INN]",
      "Cyclophosphamide Sterile",
      "Cyclophosphamide Anhydrous",
      "Cyklofosfamid [Czech]",
      "Cytoxan Lyoph",
      "Endoxan R",
      "Lyophilized Cytoxan",
      "Zyklophosphamid [German]",
      "ASTA B518",
      "Asta B 518",
      "B 518",
      "C 0768",
      "CB 4564",
      "SK 20501",
      "B-518",
      "CB-4564",
      "Ciclofosfamida [INN-Spanish]",
      "Cyclophosphamide (INN)",
      "Cyclophosphamide (TN)",
      "Cyclophosphamide (Anhydrous Form)",
      "Cyclophosphamide (Anhydrous)",
      "Cyclophosphamidum [INN-Latin]",
      "Cytoxan (TN)",
      "Endoxan (TN)",
      "Endoxan-Asta",
      "Neosar (TN)",
      "Occupation, Cyclophosphamide Exposure",
      "Procytox (TN)",
      "Revimmune (TN)",
      "Bis(2-Chloroethyl)Phosphami De Cyclic Propanolamide",
      "Bis(2-Chloroethyl)Phosphamide Cyclic Propanolamide Ester",
      "Bis(2-Chloroethyl)Phosphoramide Cyclic Propanolamide Ester",
      "D,L-Cyclophosphamide",
      "Cyclophosphamide, (+-)-Isomer",
      "N,N-Bis(2-Chloroethyl)-1,3,2-Oxazaphosphinan-2-Amine 2-Oxide",
      "(+-)-Cyclophosphamide",
      "(-)-Cyclophosphamide",
      "(RS)-Cyclophosphamide",
      "1-(Bis(2-Chloroethyl)Amino)-1-Oxo-2-Aza-5-Oxaphosphoridine",
      "1-Bis(2-Chloroethyl)Amino-1-Oxo-2-Aza-5-Oxaphosphoridin",
      "4-Hydroxy-Cyclophosphan-Mamophosphatide",
    ],
  },
  {
    primary_id: "D0CT9O",
    names: ["PMID27109571-Compound-26"],
  },
  {
    primary_id: "D0CT9V",
    names: [
      "Anthracene-2-Carboxylic Acid Hydroxyamide",
      "CHEMBL175319",
      "SCHEMBL10362855",
      "Anthracene-2-Carbohydroximic Acid",
      "BDBM50015189",
    ],
  },
  {
    primary_id: "D0CT9Y",
    names: [
      "Clomifene",
      "Androxal",
      "Chlomaphene",
      "Chloramifene",
      "Cisclomifenum",
      "Cisclomiphene",
      "Clomifen",
      "Clomifeno",
      "Clomifenum",
      "Clomifert",
      "Clomiphene",
      "Clostilbegit",
      "Enclomifene",
      "Enclomifeno",
      "Enclomifenum",
      "Enclomiphen",
      "Enclomiphene",
      "Klostilbegit",
      "Transclomifenum",
      "Transclomiphene",
      "Zuclomifene",
      "Zuclomifeno",
      "Zuclomifenum",
      "Zuclomiphene",
      "Clomiphene B;Enclomiphene [USAN]",
      "ISOMER B",
      "Zuclomiphene [USAN]",
      "Cis-Clomifene",
      "Cis-Clomiphene",
      "Clomid (TN)",
      "Clomifene (INN)",
      "Clomifene (TN)",
      "Clomifene [INN:BAN]",
      "Clomifeno [INN-Spanish]",
      "Clomifenum [INN-Latin]",
      "En-Clomiphene",
      "Enclomifeno [INN-Spanish]",
      "Enclomifenum [INN-Latin]",
      "Enclomiphene (USAN)",
      "Milophene (TN)",
      "RMI 16,289",
      "RMI-16289",
      "RMI-16312",
      "Serophene (TN)",
      "Trans-Clomifene",
      "Trans-Clomiphene",
      "Zuclomifeno [INN-Spanish]",
      "Zuclomifenum [INN-Latin]",
      "Zuclomiphene (USAN)",
      "Cis-2-(P-(2-Chloro-1,2-Diphenylvinyl)Phenoxy)Triethylamine",
      "Trans-2-(P-(2-Chloro-1,2-Diphenylvinyl)Phenoxy)Triethylamine",
      "Cis-2-(4-(2-Chloro-1,2-Diphenylethenyl)Phenoxy)-N,N-Diethylethanamine",
      "Trans-2-(4-(2-Chloro-1,2-Diphenylethenyl)Phenoxy)-N,N-Diethylethanamine",
      "(E)-2-(P-(2-Chloro-1,2-Diphenylvinyl)Phenoxy)Triethylamine",
      "(Z)-2-(P-(2-Chloro-1,2-Diphenylvinyl)Phenoxy)Triethylamine",
      "(Z)-Isomer",
      "1-(P-(Beta-Diethylaminoethoxy)-Phenyl)-1,2-Diphenylchloroethylene",
      "2-(4-(2-Chloro-1,2-Diphenylethenyl)Phenoxy)-N,N-Diethylethanamine",
      "2-(P-(2-Chloro-1,2-Diphenylvinyl)Phenoxy)Triethylamine",
      "2-(P-(Beta-Chloro-Alpha-Phenylstyryl)Phenoxy)-Triethylamine",
      "2-(P-(Beta-Chloro-Alpha-Phenylstyryl)Phenoxy)Triethylamine",
      "2-({4-[(Z)-2-Chloro-1,2-Diphenylethenyl]phenyl}oxy)-N,N-Diethylethanamine",
      "2-[4-(2-Chloro-1,2-Diphenylethenyl)Phenoxy]-N,N-Diethylethanamine",
      "2-[4-[(E)-2-Chloro-1,2-Diphenylethenyl]phenoxy]-N,N-Diethylethanamine",
      "2-[4-[(Z)-2-Chloro-1,2-Diphenylethenyl]phenoxy]-N,N-Diethylethanamine",
      "2-{4-[(E)-2-Chloro-1,2-Diphenylethenyl]phenoxy}-N,N-Diethylethanamine",
    ],
  },
  {
    primary_id: "D0CU1O",
    names: [
      "Dolutegravir + Rilpivirine",
      "Dolutegravir And Rilpivirine",
      "Juluca",
      "Dolutegravir / Rilpivirine",
      "Dolutegravir Mixture With Rilpivirine",
      "S900007770",
    ],
  },
  {
    primary_id: "D0CU1T",
    names: [
      "SB2",
      "Antimony",
      "7440-36-0",
      "Stibium Metallicum",
      "Antimony, Metallic",
      "UNII-9IT35J3UV3",
      "Antymonowodor [Polish]",
      "UNII-0VKZ97K3UB",
      "0VKZ97K3UB",
      "Antimonwasserstoffes [German]",
      "HSDB 785",
      "9IT35J3UV3",
      "UN2676",
      "Antymon",
      "Antimonio",
      "Antimoine",
      "Antimony Powder",
      "MFCD00011211",
      "Antimonyl Citrate",
      "Antimony, Ion(Sb2+)",
      "Sb2+",
      "Stibanylidyne",
      "Antimonide",
      "Antymonowodor",
      "Antimon",
      "Antimony Atom",
      "Antimony, Citric Acid Complexes",
      "Antimony(0)",
      "Antimonwasserstoffes",
      "EINECS 270-130-2",
      "ANTIMONOUS HYDRIDE",
      "ACMC-1BKT5",
      "51Sb",
      "EC 231-146-5",
      "AC1NS5Q0",
      "Sb(0)",
      "ANT",
    ],
  },
  {
    primary_id: "D0CU2G",
    names: ["Cannabidiol Oral Solution"],
  },
  {
    primary_id: "D0CU2Q",
    names: ["DHES0815A"],
  },
  {
    primary_id: "D0CU3I",
    names: ["(Z)-7-Octedecan-9-Ynoic Acid", "CHEMBL482601", "(Z)-7-Octadecene-9-Ynoic Acid"],
  },
  {
    primary_id: "D0CU4Q",
    names: [
      "S-Methylcysteine",
      "7728-98-5",
      "H-DL-Cys(Me)-OH",
      "H-D-HoCys-OH",
      "S-Methyl-DL-Cysteine",
      "USAF CB-24",
      "Methylcysteine",
      "2-Amino-3-(Methylsulfanyl)Propanoic Acid",
      "19651-44-6",
      "1-Carboxy-2-Methylthioethylamine",
      "Methylcysteine, L-",
      "ALANINE, 3-(METHYLTHIO)-, L-",
      "EINECS 231-787-0",
      "Methylcysteine #",
      "S-Methyl Cysteine",
      "DL-S-Methylcysteine",
      "DL-S-Methyl-Cys-OH",
      "DL-Cysteine, S-Methyl-",
      "AC1Q5S9H",
      "AC1L5E8F",
      "SCHEMBL110459",
      "ACMC-209a03",
      "IDIDJDIHTAOVLG-UHFFFAOYSA-",
      "CTK0E0884",
      "IDIDJDIHTAOVLG-UHFFFAOYSA-N",
      "MolPort-003-876-437",
      "NSC15387",
      "NSC-15387",
      "AKOS022154885",
    ],
  },
  {
    primary_id: "D0CU7V",
    names: ["Fullerene Therapeutics"],
  },
  {
    primary_id: "D0CU9Y",
    names: [
      "(S)-2-Amino-2-O-Tolylacetic Acid",
      "(S)-Amino-O-Tolyl-Acetic Acid",
      "339274-33-8",
      "CHEMBL204179",
      "(S)-2-AMINO-2-(O-TOLYL)ACETIC ACID",
      "(2S)-2-Amino-2-(2-Methylphenyl)Acetic Acid",
      "2-Methyl-L-Phenylglycine",
      "AC1LT3T3",
      "SCHEMBL3611200",
      "FLYIRERUSAMCDQ-QMMMGPOBSA-N",
      "ZINC4202377",
      "BDBM50179691",
      "AKOS006284304",
      "AB30505",
      "SC-21704",
      "AJ-48395",
      "AB1006782",
    ],
  },
  {
    primary_id: "D0CV1Q",
    names: ["PMID27977313-Compound-5"],
  },
  {
    primary_id: "D0CV2L",
    names: ["Infanrix"],
  },
  {
    primary_id: "D0CV4F",
    names: ["Bbs-Arg-(D-Pip)-Gly-S-(GS)1-Gly-Hir", "CHEMBL439881"],
  },
  {
    primary_id: "D0CV5F",
    names: ["PMID25522065-Compound-18"],
  },
  {
    primary_id: "D0CV7C",
    names: ["CFGGFTCARKSARK"],
  },
  {
    primary_id: "D0CV7Q",
    names: [
      "ASO-1",
      "ASO-11",
      "ASO-2",
      "Anti-MiR-122 Antisense Oligonucleotide (HCV)",
      "Antisense Oligonucleotides (MiR-122), Isis",
      "Antisense Oligonucleotides (MiR-122), Regulus",
      "Anti-MiR-122 Antisense Oligonucleotide (HCV), Regulus",
    ],
  },
  {
    primary_id: "D0CV7T",
    names: ["Patamostat", "E-3123"],
  },
  {
    primary_id: "D0CV7Z",
    names: ["Epothilone B"],
  },
  {
    primary_id: "D0CV9K",
    names: ["PMID25980951-Compound-20"],
  },
  {
    primary_id: "D0CW1J",
    names: ["Pyrrole Derivative 5"],
  },
  {
    primary_id: "D0CW1P",
    names: [
      "Betamethasone",
      "378-44-9",
      "Betadexamethasone",
      "Flubenisolone",
      "Betamethazone",
      "Rinderon",
      "Visubeta",
      "Celestene",
      "Betnelan",
      "Betapredol",
      "Betacortril",
      "Betacorlan",
      "Methazon",
      "Betsolan",
      "Betamamallet",
      "Hormezon",
      "Betasolon",
      "Betametasone",
      "Cidoten",
      "Bebate",
      "Bedifos",
      "Becort",
      "Beta-Methasone",
      "Desacort-Beta",
      "Rinderon A",
      "Beta-Methasone Alcohol",
      "Betametasona",
      "Betafluorene",
      "Betamethasonum",
      "Celestone",
      "Betamethasone Cream",
      "Betamethasone Alcohol",
      "Betametasone [DCIT]",
      "9alpha-Fluoro-16beta-Methylprednisolone",
      "Celestone Syrup And Tablets",
      "Celeston",
      "Celestona",
      "Cellestoderm",
      "Luxiqo",
      "Betamethasone Base",
      "Betamethasone Valearate",
      "Betamethasonvalerat Mikron",
      "LT00441022",
      "SCH 4831",
      "Betametasona [INN-Spanish]",
      "Betamethasonum [INN-Latin]",
      "Betnovate (TN)",
      "Celestone (TN)",
      "Diprolene (TN)",
      "Diprosone (TN)",
      "Lotrisone (TN)",
      "Rinderon (TN)",
      "SCH-4831",
      "Betamethasone (JP15/USP/INN)",
      "Betamethasone [USAN:BAN:INN:JAN]",
      "Betamethasone [USAN:INN:BAN:JAN]",
      "Celestone, Betadexamethasone, Flubenisolone, Sch-4831, NCS-39470, Betamethasone",
      "1,4-Pregnadiene-3,20-Dione-9alpha-Fluoro-16 Beta-Methyl-11 Beta,17alpha,21-Triol",
      "16beta-Methyl-1,4-Pregnadiene-9alpha-Fluoro-11beta,17alpha,21-Triol-3,20-Dione",
      "9-Fluoro-11,17,21-Trihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
      "9-Fluoro-11-Beta,17,21-Trihydroxy-16-Beta-Methylpregna-1,4-Diene-3,20-Dione",
      "9-Fluoro-11beta,17,21-Trihydroxy-16beta-Methylpregna-1,4-Diene-3,20-Dione",
      "9-Fluoro-16beta-Methylprednisolone",
      "9-Alpha-Fluoro-16-Beta-Methylprednisolone",
      "9alpha-Fluoro-16 Beta-Methyl-Prednisolone",
      "Betamethasone Sodium Phosphate",
    ],
  },
  {
    primary_id: "D0CW1Q",
    names: ["6,7-Dimethoxy-4-Phenoxy-Quinoline", "CHEMBL327534", "SCHEMBL17117826"],
  },
  {
    primary_id: "D0CW2B",
    names: ["ETX-101"],
  },
  {
    primary_id: "D0CW2F",
    names: ["Poly-Substituted Miazine Derivative 1"],
  },
  {
    primary_id: "D0CW2N",
    names: ["Reolysinpelareorep"],
  },
  {
    primary_id: "D0CW3A",
    names: ["Resveratrol Potassium3-Sulfate"],
  },
  {
    primary_id: "D0CW3B",
    names: ["M-101"],
  },
  {
    primary_id: "D0CW3U",
    names: ["Kanjinti"],
  },
  {
    primary_id: "D0CW4T",
    names: ["ONO-AE-248", "11,15-O-Dimethyl-PGE2"],
  },
  {
    primary_id: "D0CW6S",
    names: ["Monoxanes"],
  },
  {
    primary_id: "D0CW7X",
    names: ["ISIS 24475"],
  },
  {
    primary_id: "D0CW8S",
    names: ["ABX-1431"],
  },
  {
    primary_id: "D0CW9H",
    names: ["Pyrimidine Derivative 32"],
  },
  {
    primary_id: "D0CW9Q",
    names: ["PMID28048944-Compound-19"],
  },
  {
    primary_id: "D0CX0I",
    names: ["[Dcp1]Dyn A(1-11)-NH2", "CHEMBL412997"],
  },
  {
    primary_id: "D0CX1F",
    names: ["Sulfonylated Piperazine Derivative 1"],
  },
  {
    primary_id: "D0CX1Y",
    names: ["PMID28092474-Compound-32t"],
  },
  {
    primary_id: "D0CX2J",
    names: ["R-1315"],
  },
  {
    primary_id: "D0CX4C",
    names: ["PMID25666693-Compound-76"],
  },
  {
    primary_id: "D0CX4E",
    names: ["3-(3-Benzoyl-1H-Pyrrol-1-Yl)Propanoic Acid", "CHEMBL602309"],
  },
  {
    primary_id: "D0CX4W",
    names: ["UNC0321"],
  },
  {
    primary_id: "D0CX7I",
    names: ["4-Tert-Butyl-N-M-Tolylthiazol-2-Amine", "CHEMBL438248", "ZINC36159723"],
  },
  {
    primary_id: "D0CX7N",
    names: ["8-Bromo-9-(3-Hydroxypropyl)-9H-Adenine", "CHEMBL475455", "98411-75-7"],
  },
  {
    primary_id: "D0CY1B",
    names: [
      "CYC116",
      "693228-63-6",
      "CYC-116",
      "CHEMBL482967",
      "Kinome_636",
      "4-Methyl-5-(2-(4-Morpholinophenylamino)Pyrimidin-4-Yl)Thiazol-2-Amine",
      "4-Methyl-5-(2-((4-Morpholinophenyl)Amino)-Pyrimidin-4-Yl)Thiazol-2-Amine",
      "4-Methyl-5-[2-(4-Morpholin-4-Ylanilino)Pyrimidin-4-Yl]-1,3-Thiazol-2-Amine",
      "4-Methyl-5-(2-((4-Morpholinophenyl)Amino)Pyrimidin-4-Yl)Thiazol-2-Amine",
      "2uue",
      "CYC 116",
      "GPSZYOIFQZPWEJ-UHFFFAOYSA-N",
      "2c5t",
      "AC1O4WKE",
      "MLS006011244",
      "SCHEMBL2074998",
      "SYN1034",
      "EX-A753",
      "CYC-116/CYC116",
      "MolPort-009-679-476",
      "HMS3244O12",
      "HMS3244P11",
      "BCPP000273",
    ],
  },
  {
    primary_id: "D0CY2Q",
    names: ["CrohnsVax"],
  },
  {
    primary_id: "D0CY2Z",
    names: [
      "HuMax-HepC",
      "E2-HmAb",
      "HCV-Specific Antibody, Genmab",
      "Hepatitis C Virus-Specific Antibody, Connex/INSERM",
    ],
  },
  {
    primary_id: "D0CY3A",
    names: ["Idraparinux"],
  },
  {
    primary_id: "D0CY3F",
    names: ["Aom-0925"],
  },
  {
    primary_id: "D0CY3L",
    names: ["Isoxazole-Based Bicyclic Compound 8"],
  },
  {
    primary_id: "D0CY3U",
    names: ["Five Membered Heterocyclic Benzamide Derivative 3"],
  },
  {
    primary_id: "D0CY4V",
    names: ["Cetamolol", "Betacor", "Cetamolol Hydrochloride", "AI-27303", "AY-27303"],
  },
  {
    primary_id: "D0CY6E",
    names: ["2-(4-(Benzenesulfonyl)Phenyl)-1-Aminoethane", "CHEMBL213589", "BDBM50193459"],
  },
  {
    primary_id: "D0CY9O",
    names: ["ON-128"],
  },
  {
    primary_id: "D0CY9X",
    names: ["Isoxazole-Based Bicyclic Compound 7"],
  },
  {
    primary_id: "D0CZ0D",
    names: ["EF-12"],
  },
  {
    primary_id: "D0CZ1A",
    names: ["IRISQUINONE"],
  },
  {
    primary_id: "D0CZ1Q",
    names: [
      "Desoximetasone",
      "Desoximetasona",
      "Desoximetasonum",
      "Topicort",
      "HOE-304",
      "R-2113",
      "Topicort (TN)",
      "Topicort Emollient (TN)",
      "A-41-304",
      "Desoximetasone (USP/INN)",
      "(11beta,16alpha)-9-Fluoro-11,21-Dihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
      "(8S,9R,10S,11S,13S,14S,16R,17S)-9-Fluoro-11-Hydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-7,8,11,12,14,15,16,17-Octahydro-6H-Cyclopenta[a]phenanthren-3-One",
      "9-Fluoro-11beta,21-Dihydroxy-16alpha-Methylpregna-1,4-Diene-3,20-Dione",
      "9alpha-Fluoro-16alpha-Methyl-Delta(1)-Corticosterone",
    ],
  },
  {
    primary_id: "D0CZ3C",
    names: [
      "2'-Deoxyuridylic Acid",
      "DUMP",
      "Deoxyuridine Monophosphate",
      "Deoxyuridylic Acid",
      "2'-Deoxyuridine 5'-Monophosphate",
      "2'-Deoxy-5'-Uridylic Acid",
      "Deoxyuridine 5'-Phosphate",
      "2'-Deoxyuridine 5'-Phosphate",
      "Deoxy-UMP",
      "2'-Deoxyuridylate",
      "964-26-1",
      "2'-DEOXYURIDINE-5'-MONOPHOSPHATE",
      "Deoxyuridine 5'-Monophosphate",
      "5'-Uridylic Acid, 2'-Deoxy-",
      "Deoxyuridylate",
      "Uridine, 2'-Deoxy-, 5'-Phosphate",
      "EINECS 213-518-9",
      "Uridine, 2'-Deoxy-, 5'-(Dihydrogen Phosphate)",
      "BRN 0042143",
      "CHEMBL211312",
      "CHEBI:17622",
      "JSRLJPSBLDHEIO-SHYZEUOFSA-N",
      "42155-08-8",
      "1tsy",
      "1tsv",
    ],
  },
  {
    primary_id: "D0CZ5Z",
    names: ["SPD-465"],
  },
  {
    primary_id: "D0CZ6T",
    names: ["Tetra-Hydro-Imidazo[1,5-D][1,4]oxazepin-3-Yl Derivative 2"],
  },
  {
    primary_id: "D0D0AX",
    names: ["Antibodie Derivative 2"],
  },
  {
    primary_id: "D0D0BB",
    names: ["ARI-3037M0"],
  },
  {
    primary_id: "D0D0BE",
    names: ["Ornidazole"],
  },
  {
    primary_id: "D0D0BI",
    names: [
      "CHLORANIL",
      "Chloranil",
      "118-75-2",
      "P-Chloranil",
      "Tetrachloro-P-Benzoquinone",
      "Tetrachloro-1,4-Benzoquinone",
      "Tetrachlorobenzoquinone",
      "2,3,5,6-Tetrachlorocyclohexa-2,5-Diene-1,4-Dione",
      "Spergon",
      "Coversan",
      "Vulklor",
      "Reranil",
      "2,3,5,6-TETRACHLORO-1,4-BENZOQUINONE",
      "Psorisan",
      "Khloranil",
      "Tetrachloroparabenzoquinone",
      "Spergon Technical",
      "Spergon I",
      "Tetrachloroquinone",
      "Chloranile",
      "Quinone Tetrachloride",
      "Tetrachloro-P-Quinone",
      "Geigy-444E",
      "2,3,5,6-Tetrachlorobenzo-1,4-Quinone",
      "2,3,5,6-Tetrachloro-P-Benzoquinone",
      "Dow Seed Disinfectant No. 5",
    ],
  },
  {
    primary_id: "D0D0BJ",
    names: [
      "D-Galactose",
      "Alpha-D-Galactose",
      "Alpha-D-Galactopyranose",
      "Alpha D-Galactose",
      "Galactose [USAN:NF]",
      "3646-73-9",
      "Gal-Alpha",
      "8abp",
      "5abp",
      "Alpha-D-Gal",
      "UNII-7IOF6H4H77",
      "Galactose (NF)",
      "CHEBI:28061",
      "D-(+)-Galactose",
      "7IOF6H4H77",
      "(2S,3R,4S,5R,6R)-6-(Hydroxymethyl)Oxane-2,3,4,5-Tetrol",
      "A-D-Galactopyranose",
      "Hexose",
      "D-Hexose",
      "(+)-Galactose",
      "AlphaGal",
      "Alpha-Galactose",
      "Alpha-D-Galactoside",
      "PubChem23904",
      "6-(Hydroxymethyl)Tetrahydropyran-2,3,4,5-Tetraol",
      "Bmse001006",
      "Bmse000013",
      "Epitope ID:136906",
      "SCHEMBL18314",
      "Galactose (D-Galactopyranose)",
      "GTPL4646",
    ],
  },
  {
    primary_id: "D0D0BM",
    names: ["Corticotropin-Zinc Hydroxide", "Cortrophin-Zinc"],
  },
  {
    primary_id: "D0D0BO",
    names: [
      "2-Morpholin-4-Yl-N-(4-Sulfamoyl-Phenyl)-Acetamide",
      "91533-01-6",
      "2-Morpholin-4-Yl-N-(4-Sulfamoylphenyl)Acetamide",
      "CHEMBL180943",
      "Sulfanilamide, N4-(Alpha-Morpholinoacetyl)-",
      "2-(Morpholin-4-Yl)-N-(4-Sulfamoylphenyl)Acetamide",
      "N4-(Alpha-Morpholinoacetyl)Sulfanilamide",
      "AE-848/36321029",
      "AC1L47H0",
      "SCHEMBL15798186",
      "DTXSID70238616",
      "MolPort-000-225-319",
      "BDBM50157946",
      "ZINC19901027",
      "STL294445",
      "AKOS001028531",
      "MCULE-6306887577",
      "ST086382",
      "SR-01000467406",
      "SR-01000467406-1",
      "Z46180370",
      "4-Morpholineacetamide, N-(4-(Aminosulfonyl)Phenyl)-",
    ],
  },
  {
    primary_id: "D0D0DH",
    names: ["TLPLDC"],
  },
  {
    primary_id: "D0D0DR",
    names: ["1-(3-(4-Fluorophenyl)Propyl)-1H-Imidazole", "CHEMBL214932", "1-[3-(4-Fluorophenyl)Propyl]-1H-Imidazole"],
  },
  {
    primary_id: "D0D0DV",
    names: [
      "6-Benzylamino-5-Bromo-1H-Pyrimidine-2,4-Dione",
      "CHEMBL161517",
      "28484-80-2",
      "5-Bromo-6-(Benzylamino)Uracil",
      "NSC212354",
      "AC1L7G2Y",
      "6-(Benzylamino)-5-Bromo-1H-Pyrimidine-2,4-Dione",
      "DTXSID60309581",
      "BDBM50022146",
    ],
  },
  {
    primary_id: "D0D0DW",
    names: ["1-(4-Bromo-Benzyl)-3-Quinazolin-8-Yl-Urea"],
  },
  {
    primary_id: "D0D0ED",
    names: [
      "Cyclosporin A-Based Peptides",
      "CsA Based Anti-HCV Peptides, AureoGen",
      "Cyclosporin A-Based Peptides (Hepatitis C Virus Infection)",
      "Cyclosporin A-Based Peptides (Hepatitis C Virus Infection), AureoGen",
    ],
  },
  {
    primary_id: "D0D0FB",
    names: ["TV1001"],
  },
  {
    primary_id: "D0D0FQ",
    names: [
      "CHLOROCYCLINONE A",
      "CHEMBL253120",
      "BDBM50228358",
      "Methyl 2-Chloro-9-Ethyl-6,8-Dihydroxy-1-Methoxy-3-Methyl-7,12-Dioxo-7,12-Dihydrotetraphene-10-Carboxylate",
    ],
  },
  {
    primary_id: "D0D0FV",
    names: ["(R)-2-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Pyridine", "CHEMBL270807"],
  },
  {
    primary_id: "D0D0FX",
    names: [
      "1-(1,3,4-Oxadiazol-2-Yl)Octadec-9-En-1-One",
      "CHEMBL178791",
      "BDBM50161529",
      "1-[1,3,4]Oxadiazol-2-Yl-Octadec-9-En-1-One",
    ],
  },
  {
    primary_id: "D0D0GH",
    names: [
      "NsG-0202",
      "ECB-AD",
      "ECT-AD",
      "ECT-NGF",
      "ECT (Alzheimers Disease), NsGene",
      "Encapsulated Cell Technology (Alzheimers Disease), NsGene",
      "Encapsulated ARPE-19 Cells (Alzheimers Disease), NsGene",
    ],
  },
  {
    primary_id: "D0D0GS",
    names: ["FHT-2107", "EGFR-Targeting SiRNA Agent, ForHumanTech"],
  },
  {
    primary_id: "D0D0GV",
    names: ["Fenhexamid"],
  },
  {
    primary_id: "D0D0HB",
    names: ["DCVax"],
  },
  {
    primary_id: "D0D0IF",
    names: ["ScFv23/RGel"],
  },
  {
    primary_id: "D0D0JE",
    names: ["4,5-Bis(4-Methoxyphenyl)-1,2-Selenazole", "CHEMBL470304"],
  },
  {
    primary_id: "D0D0JU",
    names: ["S-Hexyl Hexane-1-Sulfinothioate"],
  },
  {
    primary_id: "D0D0JY",
    names: ["WNVax"],
  },
  {
    primary_id: "D0D0KT",
    names: ["N-(3-Phenyl-Propyl)-Nicotinamide"],
  },
  {
    primary_id: "D0D0KZ",
    names: ["AV4025"],
  },
  {
    primary_id: "D0D0LD",
    names: ["L-873724"],
  },
  {
    primary_id: "D0D0LH",
    names: [
      "NU-100",
      "BaroFeron",
      "Interferon Beta-1b (PreEMT, Multiple Sclerosis), BaroFold",
      "Interferon Beta-1b (PreEMT, Multiple Sclerosis), NuronBiotech",
    ],
  },
  {
    primary_id: "D0D0ML",
    names: ["Heterocyclic Derivative 6"],
  },
  {
    primary_id: "D0D0NA",
    names: ["KDT-600"],
  },
  {
    primary_id: "D0D0NG",
    names: ["4-(Cyclohexylmethylcarbamoyloxy)Benzoic Acid", "CHEMBL465214"],
  },
  {
    primary_id: "D0D0OC",
    names: ["G-Series Prophage Vaccine"],
  },
  {
    primary_id: "D0D0OD",
    names: ["N-(3,3-Dimethyl-Butyl)-4-Indol-1-Yl-Benzamide", "CHEMBL431682", "SCHEMBL7214961", "BDBM50106177"],
  },
  {
    primary_id: "D0D0OO",
    names: ["ISIS 191759"],
  },
  {
    primary_id: "D0D0OR",
    names: ["V520/V526 Combination Vaccine"],
  },
  {
    primary_id: "D0D0OT",
    names: ["GS-4977"],
  },
  {
    primary_id: "D0D0OX",
    names: ["2,7-Bis(Propionamido)Anthraquinone", "SCHEMBL4299908", "CHEMBL481176"],
  },
  {
    primary_id: "D0D0PB",
    names: ["AIC-292"],
  },
  {
    primary_id: "D0D0PF",
    names: ["PMID25772215-Compound-US02014179750TG7-112-2"],
  },
  {
    primary_id: "D0D0PV",
    names: ["Malonyl-CoA"],
  },
  {
    primary_id: "D0D0QA",
    names: [
      "AZD6482",
      "1173900-33-8",
      "AZD 6482",
      "(R)-2-(1-(7-Methyl-2-Morpholino-4-Oxo-4H-Pyrido[1,2-A]pyrimidin-9-Yl)Ethylamino)Benzoic Acid",
      "2-[[(1R)-1-[7-Methyl-2-(4-Morpholinyl)-4-Oxo-4H-Pyrido[1,2-A]pyrimidin-9-Yl]ethyl]amino]benzoic Acid",
      "AZD-6482",
      "CHEMBL2165191",
      "UNII-78G6MP5PZ5",
      "78G6MP5PZ5",
      "2-({(1R)-1-[7-Methyl-2-(Morpholin-4-Yl)-4-Oxo-4H-Pyrido[1,2-A]pyrimidin-9-Yl]ethyl}amino)Benzoic Acid",
      "2-{[(1R)-1-[7-Methyl-2-(Morpholin-4-Yl)-4-Oxopyrido[1,2-A]pyrimidin-9-Yl]ethyl]amino}benzoic Acid",
    ],
  },
  {
    primary_id: "D0D0QQ",
    names: ["Velac Gel", "Clindamycin/Tretinoin"],
  },
  {
    primary_id: "D0D0QT",
    names: ["Pyrido[1,2,4]triazolo[4,3-A]pyrazine Derivative 2"],
  },
  {
    primary_id: "D0D0RJ",
    names: ["LED-209"],
  },
  {
    primary_id: "D0D0RW",
    names: ["KMB-17"],
  },
  {
    primary_id: "D0D0SC",
    names: [
      "6-Hydroxy-N-(2-Morpholinoethyl)Nicotinamide",
      "CHEMBL596971",
      "BDBM50307189",
      "AKOS008932018",
      "AKOS027598195",
    ],
  },
  {
    primary_id: "D0D0SH",
    names: ["GMDP"],
  },
  {
    primary_id: "D0D0TO",
    names: ["Piperidin-(2E)-Ylideneamine"],
  },
  {
    primary_id: "D0D0TQ",
    names: ["AKN-028"],
  },
  {
    primary_id: "D0D0UP",
    names: ["1-Benzhydryl-4-(Thiophen-2-Yl)Piperidin-4-Ol", "CHEMBL233323"],
  },
  {
    primary_id: "D0D0US",
    names: ["OB-14"],
  },
  {
    primary_id: "D0D0WF",
    names: ["GGTI-298"],
  },
  {
    primary_id: "D0D0WH",
    names: ["Anti-CD73 MAb"],
  },
  {
    primary_id: "D0D0WP",
    names: ["V-0096 CR", "Broad Spectrum External Photoprotector (Idiopathic Solar Urticaria), Orfagen"],
  },
  {
    primary_id: "D0D0WU",
    names: ["Pyridine-2,4-Dicarboxylic Acid Analog 2"],
  },
  {
    primary_id: "D0D0XF",
    names: ["AG-702"],
  },
  {
    primary_id: "D0D0XP",
    names: ["C6S", "CHEMBL1417"],
  },
  {
    primary_id: "D0D0XX",
    names: ["LY-290154"],
  },
  {
    primary_id: "D0D0XY",
    names: ["ImmuneFx", "Cancer Vaccine, Morphogenesis", "C-LSC/Emm55", "Immunotherapy (Cancer), Morphogenesis"],
  },
  {
    primary_id: "D0D0YG",
    names: ["BN52021"],
  },
  {
    primary_id: "D0D0YK",
    names: ["GI-3000"],
  },
  {
    primary_id: "D0D0YM",
    names: ["Amosulalol"],
  },
  {
    primary_id: "D0D0ZD",
    names: [
      "Miglitol",
      "Diastabol",
      "Glyset",
      "Plumarol",
      "Seibule",
      "Glyset (TN)",
      "SK-983",
      "Bay-M-1099",
      "Miglitol (JAN/USAN/INN)",
      "(2R,3R,4R,5S)-1-(2-Hydroxyethyl)-2-(Hydroxymethyl)Piperidine-3,4,5-Triol",
    ],
  },
  {
    primary_id: "D0D0ZQ",
    names: ["MERIOLIN 6"],
  },
  {
    primary_id: "D0D1AL",
    names: [
      "Haloperidol",
      "Aldo",
      "Aloperidin",
      "Aloperidol",
      "Aloperidolo",
      "Aloperidon",
      "Bioperidolo",
      "Brotopon",
      "Dozic",
      "Dozix",
      "Eukystol",
      "Fortunan",
      "Galoperidol",
      "Haldol",
      "Halidol",
      "Halojust",
      "Halol",
      "Halopal",
      "Haloperido",
      "Haloperidolum",
      "Halopidol",
      "Halopoidol",
      "Halosten",
      "Keselan",
      "Linton",
      "Mixidol",
      "Pekuces",
      "Peluces",
      "Peridol",
      "Pernox",
      "Serenace",
      "Serenase",
      "Serenelfi",
      "Sernas",
      "Sernel",
      "Sigaperidol",
      "Ulcolind",
      "Uliolind",
      "Vesalium",
      "Aloperidolo [DCIT]",
      "Aloperidolo [Italian]",
      "Einalon S",
      "Haldol La",
      "Haldol Solutab",
      "Lealgin Compositum",
      "Pms Haloperidol",
      "H 1512",
      "R 1625",
      "Aloperidin (TN)",
      "Apo-Haloperidol",
      "Bioperidolo (TN)",
      "Brotopon (TN)",
      "Dozic (TN)",
      "Duraperidol (TN)",
      "Einalon S (TN)",
      "Eukystol (TN)",
      "Haldol (TN)",
      "Haloperidolum [INN-Latin]",
      "Halosten (TN)",
      "Keselan (TN)",
      "Linton (TN);Novo-Peridol",
      "Peluces (TN)",
      "R-1625",
      "Serenace (TN)",
      "Serenase (TN)",
      "Sigaperidol (TN)",
      "Haloperidol (JP15/USP)",
      "McN-JR-1625",
      "Haloperidol (JP15/USP/INN)",
      "Haloperidol [USAN:INN:BAN:JAN]",
    ],
  },
  {
    primary_id: "D0D1BC",
    names: ["Melanocortin-4 Receptor Antagonist"],
  },
  {
    primary_id: "D0D1BR",
    names: ["GS-7340"],
  },
  {
    primary_id: "D0D1CL",
    names: ["MB07133"],
  },
  {
    primary_id: "D0D1CV",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Hexyl Ester",
      "CHEMBL78282",
      "BDBM50116815",
      "4-(Sulfamoyloxy)Benzoic Acid Hexyl Ester",
    ],
  },
  {
    primary_id: "D0D1DI",
    names: [
      "Arformoterol",
      "67346-49-0",
      "Brovana",
      "UNII-F91H02EBWT",
      "Arformoterol Tartrate",
      "CHEMBL1363",
      "F91H02EBWT",
      "CHEBI:408174",
      "Brovana (TN)",
      "Formoterol/Fluticasone Propionate",
    ],
  },
  {
    primary_id: "D0D1EE",
    names: ["Ginkgolide J"],
  },
  {
    primary_id: "D0D1EI",
    names: ["Diphenyl Purine Derivative 5"],
  },
  {
    primary_id: "D0D1EU",
    names: [
      "PD-144550",
      "148553-51-9",
      "(R)-Pregabalin",
      "(3R)-3-(Aminomethyl)-5-Methylhexanoic Acid",
      "CHEMBL167003",
      "(R)-3-(Aminomethyl)-5-Methylhexanoic Acid",
      "Ent-Pregabalin",
      "R-Pregabalin",
      "AC1L2PDQ",
      "BSPBio_002316",
      "SCHEMBL263450",
      "SPECTRUM1505816",
      "DTXSID90276292",
      "HMS1922H16",
      "Pharmakon1600-01505816",
      "HMS2093F10",
      "ZINC5760031",
      "TOS-0910",
      "CCG-40213",
      "NSC759256",
      "AKOS027425242",
      "NSC-759256",
      "NCGC00178709-01",
      "SC-94803",
      "SBI-0206875.P001",
      "PD 144550",
      "FT-0674010",
      "FT-0600823",
      "K-4720",
      "Hexanoic Acid, 3-(Aminomethyl)-5-Methyl-, (3R)-",
    ],
  },
  {
    primary_id: "D0D1EV",
    names: ["NSC-364432"],
  },
  {
    primary_id: "D0D1FC",
    names: ["LY-362884"],
  },
  {
    primary_id: "D0D1FK",
    names: ["RU78299"],
  },
  {
    primary_id: "D0D1FT",
    names: ["EXP3174"],
  },
  {
    primary_id: "D0D1GF",
    names: ["XL880"],
  },
  {
    primary_id: "D0D1GI",
    names: [
      "3-(1-Pyridin-2-Yl-1H-Pyrrol-3-Yl)-Benzonitrile",
      "CHEMBL361120",
      "SCHEMBL5104615",
      "GXXRNNGNWUNPKZ-UHFFFAOYSA-N",
      "BDBM50151899",
      "ZINC13582310",
    ],
  },
  {
    primary_id: "D0D1GV",
    names: ["CNS-1237"],
  },
  {
    primary_id: "D0D1HA",
    names: [
      "Cefotaxime",
      "Cefotaxim",
      "Cefotaxima",
      "Cefotaximum",
      "Cephotaxim",
      "Cephotaxime",
      "Claforan",
      "Klaforan",
      "Cefotaxim Hikma",
      "Cefotaxime Acid",
      "CE3",
      "RU 24662",
      "Cefotaxim Hikma (TN)",
      "Cefotaxima [INN-Spanish]",
      "Cefotaxime (INN)",
      "Cefotaxime [INN:BAN]",
      "Cefotaximum [INN-Latin]",
      "Claforan (TN)",
      "Ru-24756",
      "Claforan (*Sodium Salt*)",
    ],
  },
  {
    primary_id: "D0D1HC",
    names: ["5-Oxo-1-Tosyl-2,5-Dihydro-1Hpyrrol-2-Yl Acetate", "CHEMBL1164744"],
  },
  {
    primary_id: "D0D1HI",
    names: ["Autologous T Cells Expressing MET ScFv CAR"],
  },
  {
    primary_id: "D0D1HR",
    names: ["TrkB NAM"],
  },
  {
    primary_id: "D0D1HS",
    names: ["Ki23819"],
  },
  {
    primary_id: "D0D1HW",
    names: [
      "Anagrelide",
      "Anagrelida",
      "Anagrelidum",
      "BL 416201",
      "Agrylin (TN)",
      "Anagrelida [INN-Spanish]",
      "Anagrelide [BAN:INN]",
      "Anagrelide [INN:BAN]",
      "Anagrelidum [INN-Latin]",
      "Xagrid (TN)",
      "Anagrelide (INN/BAN)",
      "6,7-Dichlor-1,5-Dihydroimidazo(2,1-B)Chinazolin-2(3H)-On",
      "6,7-Dichloro-1,5-Dihydroimidazo(2,1-B)Quinazolin-2(3H)-One",
      "6,7-Dichloro-1,5-Dihydroimidazo[2,1-]quinazolin-2(3H)-One",
      "6,7-Dichloro-5,10-Dihydro-3H-Imidazo[2,1-B]quinazolin-2-One",
    ],
  },
  {
    primary_id: "D0D1IR",
    names: ["HG-1438"],
  },
  {
    primary_id: "D0D1JP",
    names: ["WR-279396"],
  },
  {
    primary_id: "D0D1KA",
    names: [
      "3-Hydroxy-7-(3-Hydroxyphenyl)-1-Naphthonitrile",
      "CHEMBL508693",
      "SCHEMBL3015500",
      "PBXARVUJEROSCH-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0D1KK",
    names: ["(R)-1-((S)-Morpholin-2-Yl)-1,2-Diphenylethanol", "CHEMBL207712", "SCHEMBL14202964"],
  },
  {
    primary_id: "D0D1KZ",
    names: [
      "1-Methyl-1H-Indole-3-Carbaldehyde",
      "19012-03-4",
      "1-Methylindole-3-Carboxaldehyde",
      "1-Methylindole-3-Carbaldehyde",
      "3-Formyl-1-Methylindole",
      "1-Methyl-Indole-3-Carbaldehyde",
      "1H-Indole-3-Carboxaldehyde, 1-Methyl-",
      "1-Methyl-3-Formylindole",
      "N-Methyl-3-Formylindole",
      "Indole-3-Carboxaldehyde, 1-Methyl-",
      "3-Formyl-1-Methyl-1H-Indole",
      "CHEMBL210779",
      "N-Methyl-3-Indolecarboxaldehyde",
      "KXYBYRKRRGSZCX-UHFFFAOYSA-N",
      "MFCD00014570",
      "1-Methylindole-3-Carboxaldehyde, 97%",
      "NSC83042",
      "EINECS 242-750-3",
      "PubChem7503",
      "AI3-51477",
      "AC1L3EMK",
      "AC1Q6QCU",
      "AC1Q3YTB",
    ],
  },
  {
    primary_id: "D0D1LB",
    names: ["8-Benzylnaringenin"],
  },
  {
    primary_id: "D0D1LG",
    names: ["AZD-5099"],
  },
  {
    primary_id: "D0D1LI",
    names: ["PMID27977313-Compound-45"],
  },
  {
    primary_id: "D0D1LS",
    names: ["5-Fluoro-2-Deoxycytidine"],
  },
  {
    primary_id: "D0D1MA",
    names: [
      "Oxyphenonium",
      "Argicillin",
      "Atrenyl",
      "Hydroxymethylgramicidin",
      "Methacin",
      "Methocidin",
      "Methocidine",
      "Methocidinum",
      "Metocidina",
      "Oxiphenonum",
      "Spastrex;Oxyphenonium Chloride",
      "Oxyphenonium Iodide",
      "Methocidin [DCF:INN]",
      "Methocidine [INN-French]",
      "Methocidinum [INN-Latin]",
      "Metocidina [INN-Spanish]",
      "[2-(2-Cyclohexyl-2-Hydroxy-2-Phenyl-Acetoxy)-Ethyl]-Diethyl-Methyl-Ammonium",
      "2-((Cyclohexylhydroxyphenylacetyl)Oxy)-N,N-Diethyl-N-Methylethanaminium",
      "2-(2-Cyclohexyl-2-Hydroxy-2-Phenylacetyl)Oxyethyl-Diethyl-Methylazanium",
      "2-[2-Cyclohexyl(Hydroxy)Phenylacetoxy]-N,n-Diethyl-N-Methylethanaminium",
    ],
  },
  {
    primary_id: "D0D1NQ",
    names: ["7alpha-NH2-Ginkgolide B", "7alpha-NH2-GB"],
  },
  {
    primary_id: "D0D1ON",
    names: ["Sri-9662"],
  },
  {
    primary_id: "D0D1PE",
    names: [
      "CGS-19281A",
      "Cgs-19281A",
      "CHEMBL6409",
      "31652-37-6",
      "4,9-Dihydro-7-Methoxy-3H-Pyrido(3,4b)Indole",
      "3H-Pyrido(3,4-B)Indole, 4,9-Dihydro-7-Methoxy-",
      "AC1L53KC",
      "AC1Q56KK",
      "7-Methoxy-4,9-Dihydro-3H-Pyrido[3,4-B]indole",
      "SCHEMBL2930783",
      "CTK4G7530",
      "DTXSID90185537",
      "BDBM50079903",
      "7-Methoxy-4,9-Dihydro-3H-Beta-Carboline",
    ],
  },
  {
    primary_id: "D0D1PQ",
    names: ["EDP-21"],
  },
  {
    primary_id: "D0D1PR",
    names: [
      "(R)-(-)-2-Methoxy-N-Npropylnorapomorphine",
      "2-Methoxy-N-N-Propylnorapomorphine",
      "CHEMBL53294",
      "126874-82-6",
      "2-Mnpna",
      "AC1L2Y8J",
      "SCHEMBL12284193",
      "DTXSID70155319",
      "2-Methoxy-6-Propyl-5,6,6a,7-Tetrahydro-4H-Dibenzo[de,g]quinoline-10,11-Diol",
      "BDBM50010692",
      "(R)-2-Methoxy-6-Propyl-5,6,6a,7-Tetrahydro-4H-Dibenzo[de,g]quinoline-10,11-Diol",
      "4H-Dibenzo(De,g)Quinoline-10,11-Diol, 5,6,6a,7-Tetrahydro-2-Methoxy-6-Propyl-, (R)-",
    ],
  },
  {
    primary_id: "D0D1QD",
    names: ["PMID28394193-Compound-Figure5aVIII"],
  },
  {
    primary_id: "D0D1QH",
    names: ["Pyrazolopyridine Derivative 7"],
  },
  {
    primary_id: "D0D1RF",
    names: [
      "ABX-0401",
      "ALX-0071",
      "Nanobody Therapeutics (Inflammation/Rheumatoid Arthritis/Inflammatory Bowel Disease), Ablynx/Pfizer",
      "Nanobody Therapeutics (Inflammation/Rheumatoid Arthritis/Inflammatory Bowel Disease), Ablynx/Wyeth",
    ],
  },
  {
    primary_id: "D0D1RM",
    names: ["Botulinum Toxin Type A", "Botox", "Botulinum Toxin Type A (JAN)"],
  },
  {
    primary_id: "D0D1RU",
    names: [
      "Indol-1-Yl-Pyridin-4-Yl-Amine",
      "119257-33-9",
      "N-(4-Pyridinyl)-1H-Indol-1-Amine",
      "N-(PYRIDIN-4-YL)-1H-INDOL-1-AMINE",
      "CHEMBL154488",
      "1H-Indol-1-Amine,N-4-Pyridinyl-",
      "N-Pyridin-4-Ylindol-1-Amine",
      "N-4-Pyridinyl-1H-Indol-1-Amine",
      "AC1MIWH0",
      "ACMC-1C0H7",
      "SCHEMBL3139425",
      "ZINC24994",
      "CTK4B1164",
      "DTXSID90152360",
      "YFXZWVUZIPQSKX-UHFFFAOYSA-N",
      "BDBM50048608",
      "1H-Indol-1-Amine, N-4-Pyridinyl-",
      "AKOS015924421",
      "RP26505",
      "KB-139314",
      "J-523178",
    ],
  },
  {
    primary_id: "D0D1SG",
    names: ["Prednisolone"],
  },
  {
    primary_id: "D0D1TB",
    names: ["CPC-410"],
  },
  {
    primary_id: "D0D1TS",
    names: ["Isoquinoline Derivative 2"],
  },
  {
    primary_id: "D0D1TX",
    names: ["Recombinant Human Pro-Urokinase"],
  },
  {
    primary_id: "D0D1TZ",
    names: ["Anti-Inflammatory/Anti-Spasmodic Therapeutics"],
  },
  {
    primary_id: "D0D1UD",
    names: [
      "STI-001",
      "AP4-24H11",
      "STI-001 Program (MAbs Targeting S Aureus Auto-Inducing Peptides)",
      "Fully Human Anti-AIP Monoclonal Antibody (MRSA/Bacterial Infections), Sorrento Therapeutics",
      "Quorum Sensing-Targeting Antibodies (MRSA/Bacterial Infections), Sorrento Therapeutics",
      "STI-001 Program (MAbs Targeting S Aureus Auto-Inducing Peptides), Sorrento Therapeutics",
    ],
  },
  {
    primary_id: "D0D1UQ",
    names: ["Neu-120"],
  },
  {
    primary_id: "D0D1VB",
    names: ["Tyr-Pro-Phe-D-2-Nal-NH2", "CHEMBL375070"],
  },
  {
    primary_id: "D0D1VJ",
    names: ["SDZ-210-086"],
  },
  {
    primary_id: "D0D1WB",
    names: ["Dynorphin(1-8)"],
  },
  {
    primary_id: "D0D1WN",
    names: ["SBTI"],
  },
  {
    primary_id: "D0D1XE",
    names: ["NM-2023"],
  },
  {
    primary_id: "D0D1XG",
    names: [
      "CI-976",
      "CI 976",
      "114289-47-3",
      "2,2-Dimethyl-N-(2,4,6-Trimethoxyphenyl)Dodecanamide",
      "PD 128042",
      "BRN 5831010",
      "CHEMBL22373",
      "UNII-8653IQ20EA",
      "8653IQ20EA",
      "Dodecanamide,2,2-Dimethyl-N-(2,4,6-Trimethoxyphenyl)-",
      "Dodecanamide, 2,2-Dimethyl-N-(2,4,6-Trimethoxyphenyl)-",
      "2,2-Dimethyl-Dodecanoic Acid (2,4,6-Trimethoxy-Phenyl)-Amide",
      "C23H39NO4",
      "ACMC-1CUXF",
      "AC1L3UE4",
      "SCHEMBL691688",
      "CTK4A8660",
      "CHEBI:95040",
      "DTXSID00150690",
      "MolPort-002-018-232",
      "HMS3268D10",
      "HMS3262L19",
      "ZINC8022586",
      "Tox21_500859",
      "BN0150",
      "BDBM50005944",
      "AKOS022180044",
      "LP00859",
      "CCG-222163",
    ],
  },
  {
    primary_id: "D0D1XW",
    names: ["PEG-Interferon Lambda (IL-29)"],
  },
  {
    primary_id: "D0D1YW",
    names: ["HMS3229G08"],
  },
  {
    primary_id: "D0D1YY",
    names: ["IGN-2098"],
  },
  {
    primary_id: "D0D1ZF",
    names: ["Mavacamten"],
  },
  {
    primary_id: "D0D1ZS",
    names: ["CLP-1001", "Cross-Linked Polyelectrolyte Polymer (Renal Disease), Sorbent Therapeutics"],
  },
  {
    primary_id: "D0D2AR",
    names: ["SOM-0003"],
  },
  {
    primary_id: "D0D2BV",
    names: ["PMID28350212-Compound-25"],
  },
  {
    primary_id: "D0D2CF",
    names: ["PMID25666693-Compound-115"],
  },
  {
    primary_id: "D0D2CU",
    names: ["(R)-(-)-2-Methyl-Apomorphine Hydrochloride", "CHEMBL507567"],
  },
  {
    primary_id: "D0D2CY",
    names: ["DEX-M74"],
  },
  {
    primary_id: "D0D2EF",
    names: [
      "(R)-Nipecotic Acid",
      "(R)-Piperidine-3-Carboxylic Acid",
      "25137-00-2",
      "(R)-(-)-Nipecotic Acid",
      "(3R)-Piperidine-3-Carboxylic Acid",
      "(R)-(-)-3-Piperidinecarboxylic Acid",
      "(-)-Nipecotic Acid",
      "(3R)-Nipecotic Acid",
      "(3R)-(-)-Piperidine-3-Carboxylic Acid",
      "D(-)-Nipecotic Acid",
      "R-(-)-3-Piperidinecarboxylic Acid",
      "3-Piperidinecarboxylic Acid, (R)-",
      "(R)-Hexahydronicotinic Acid",
      "(R)-(-)-Piperidine-3-Carboxylic Acid",
      "(-)-Hexahydronicotinic Acid",
      "(3R)-Hexahydronicotinic Acid",
      "CHEMBL77575",
      "(R)-3-Piperidinecarboxylic Acid",
    ],
  },
  {
    primary_id: "D0D2ET",
    names: ["JMV 1813"],
  },
  {
    primary_id: "D0D2FB",
    names: [
      "2-(3-(Benzyloxy)Phenyl)Isoindoline-1,3-Dione",
      "CHEMBL205391",
      "N-(3-BENZYLOXYPHENYL)PHTHALIMIDE",
      "2-(3-Phenylmethoxyphenyl)Isoindole-1,3-Dione",
      "AC1MO1I5",
      "ZINC4826839",
      "BDBM50181082",
      "AKOS003508151",
    ],
  },
  {
    primary_id: "D0D2FC",
    names: ["CGP74514A", "CGP-74514A", "CGP 74514A"],
  },
  {
    primary_id: "D0D2FP",
    names: ["[3H]A778317", "GTPL4076"],
  },
  {
    primary_id: "D0D2FZ",
    names: ["Ragweed Allergy Vaccine Sublingual"],
  },
  {
    primary_id: "D0D2GL",
    names: ["MPPG"],
  },
  {
    primary_id: "D0D2GW",
    names: ["EHC18"],
  },
  {
    primary_id: "D0D2GZ",
    names: ["AL-408", "AL-108 Analog (Neurodegenerative Diseases), Allon"],
  },
  {
    primary_id: "D0D2HH",
    names: ["1,2-Bis(2,3,5-Trifluorophenyl)-2-Hydroxyethanone", "CHEMBL244630"],
  },
  {
    primary_id: "D0D2IU",
    names: ["Engerix B"],
  },
  {
    primary_id: "D0D2JA",
    names: [
      "4-Phenoxyphenylboronic Acid",
      "51067-38-0",
      "(4-Phenoxyphenyl)Boronic Acid",
      "4-Phenoxybenzeneboronic Acid",
      "(4-Phenoxyphenyl)Boranediol",
      "CHEMBL459151",
      "Boronic Acid, (4-Phenoxyphenyl)-",
      "MFCD00093312",
      "4-Phenoxyphenylboronic Acid, 95+%",
      "4-Phenoxyphenyl Boronic Acid",
      "PubChem7890",
      "Phenoxyphenylboronic Acid",
      "ACMC-209krn",
      "AC1MC0ZX",
      "SCHEMBL9285",
      "4-Phenyoxyphenylboronic Acid",
      "4-Phenoxy-Phenylboronic Acid",
      "4-Phenoxyphenyl-Boronic Acid",
      "KSC489K8J",
      "4-Phenoxy-Phenyl Boronic Acid",
      "AC1Q715Q",
      "(4-Phenoxyphenyl) Boronic Acid",
    ],
  },
  {
    primary_id: "D0D2JB",
    names: ["Kassinin"],
  },
  {
    primary_id: "D0D2JD",
    names: ["TH-0318"],
  },
  {
    primary_id: "D0D2JH",
    names: ["(+/-)-7-Fluoro-2-(4-Fluorophenyl)Chroman-4-One", "CHEMBL600390"],
  },
  {
    primary_id: "D0D2JW",
    names: ["Polyhydroxy Benzamide Derivative 3"],
  },
  {
    primary_id: "D0D2KE",
    names: [
      "5-Mercapto-Pentanoic Acid Phenylamide",
      "N-Phenyl-5-Mercaptovaleramide",
      "CHEMBL114344",
      "Pentanamide, 5-Mercapto-N-Phenyl-",
    ],
  },
  {
    primary_id: "D0D2LC",
    names: ["Pyrimidinyl Compound 1"],
  },
  {
    primary_id: "D0D2LE",
    names: ["Meso-CART"],
  },
  {
    primary_id: "D0D2LF",
    names: ["YM-116"],
  },
  {
    primary_id: "D0D2MN",
    names: ["5-Oxo-12-HETE", "5-Oxo-12S-Hydroxy-6E,8Z,10E,14Z-Eicosatetraenoic Acid"],
  },
  {
    primary_id: "D0D2MS",
    names: ["5-Methylenearisteromycin", "CHEMBL262063", "5'-Methylenearisteromycin", "BDBM50373100"],
  },
  {
    primary_id: "D0D2MV",
    names: [
      "2-(3-Guanidinophenyl)-3-Mercaptopropanoic Acid",
      "2-(3-GUANIDINOPHENYL)-3-MERCAPTOPROPANOIC ACID",
      "(2R)-2-(3-{[AMINO(IMINO)METHYL]AMINO}PHENYL)-3-SULFANYLPROPANOIC ACID",
      "L98",
      "AC1NA0C3",
      "ZINC16051731",
      "DB04723",
      "(2R)-2-(3-Carbamimidamidophenyl)-3-Sulfanylpropanoic Acid",
      "(2R)-2-[3-(Diaminomethylideneamino)Phenyl]-3-Sulfanylpropanoic Acid",
      "(2R)-2-{3-[(Diaminomethylidene)Amino]phenyl}-3-Sulfanylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0D2NV",
    names: [
      "Pyrrolidin-3-Yl-Acetic Acid",
      "89203-64-5",
      "2-(Pyrrolidin-3-Yl)Acetic Acid",
      "3-PYRROLIDINEACETIC ACID",
      "3-Pyrrolidineaceticacid",
      "2-Pyrrolidin-3-Ylacetic Acid",
      "Pyrrolidin-3-Ylacetic Acid",
      "CHEMBL351355",
      "2-(Pyrrolidin-3yl)Acetic Acid",
      "Homo-Beta-Proline",
      "ACMC-20liyx",
      "ACMC-20mq1s",
      "Pyrrolidine-3-Acetic Acid",
      "AC1N49FZ",
      "SCHEMBL374930",
      "CTK3E7957",
      "OUENRUZPZZFMCA-UHFFFAOYSA-N",
      "MolPort-001-729-044",
      "KS-000003FH",
      "BDBM50013224",
      "3-Pyrrolidineacetic Acid, AldrichCPR",
      "3-Pyrrolidineacetic Acid Hydrochloride",
      "AKOS005264421",
      "MCULE-3887627276",
      "RP19856",
    ],
  },
  {
    primary_id: "D0D2OM",
    names: [
      "3-(6-Amino-Purin-9-Yl)-4-P-Tolyl-Butan-2-Ol",
      "CHEMBL43261",
      "SCHEMBL7454006",
      "BDBM50070640",
      "ZINC13835542",
      "(2S,3R)-3-(6-Amino-Purin-9-Yl)-4-P-Tolyl-Butan-2-Ol",
    ],
  },
  {
    primary_id: "D0D2PJ",
    names: ["GNF-PF-4421"],
  },
  {
    primary_id: "D0D2PL",
    names: [
      "8-Methoxy-Quinolin-2-Ylamine",
      "2-Quinolinamine, 8-Methoxy-",
      "8-Methoxyquinolin-2-Amine",
      "104090-86-0",
      "CHEMBL77072",
      "ACMC-20m6vk",
      "SCHEMBL1655715",
      "CTK0G6598",
      "DTXSID90624077",
      "MolPort-020-915-686",
      "PMXFKGCLUAFLMH-UHFFFAOYSA-N",
      "2-AMINO-8-METHOXYQUINOLINE",
      "ZINC26399714",
      "BDBM50025466",
      "AKOS013465271",
    ],
  },
  {
    primary_id: "D0D2PQ",
    names: ["CT-2412"],
  },
  {
    primary_id: "D0D2PS",
    names: ["PMID29865878-Compound-52"],
  },
  {
    primary_id: "D0D2PU",
    names: ["Peptidomimetic Analog 6"],
  },
  {
    primary_id: "D0D2QE",
    names: ["PMID26651364-Compound-117b"],
  },
  {
    primary_id: "D0D2RN",
    names: [
      "Aminopterin",
      "APGA",
      "Aminopteridine",
      "Aminopterine",
      "Aminopterinum",
      "Aminotrexate",
      "Pteramina",
      "Pteramina [Czech]",
      "A 1784",
      "A-7170;A-Ninopterin",
      "ENT-26079",
      "Kyselina 4-Aminolistova",
      "Kyselina 4-Aminolistova [Czech]",
      "Kyselina 4-Aminopteroylglutamova",
      "Kyselina 4-Aminopteroylglutamova [Czech]",
      "L-Glutamic Acid, N-[4-[[(2,4-Diamino-6-Pteridinyl)Methyl]amino]benzoyl]",
      "N-(1-((2,4-Diamino-6-Pteridinylmethyl)Amino)Benzoyl)Glutaminsaeure",
      "N-[(4-{[(2,4-Diaminopteridin-6-Yl)Methyl]amino}phenyl)Carbonyl]glutamic Acid",
      "N-(4-{[(2,4-Diaminopteridin-6-Yl)Methyl]amino}benzoyl)-L-Glutamic Acid",
      "N-(4-(((2,4-Diamino-6-Pteridinyl)Methyl)Amino)Benzoyl)-L-Glutamic Acid",
      "Kyselina N-(P-((2,4-Diamino-6-Pteridinyl)Methyl)Benzoyl)-L(+)-Glutamova",
      "Kyselina N-(P-((2,4-Diamino-6-Pteridinyl)Methyl)Benzoyl)-L(+)-Glutamova [Czech]",
      "Kyselina N-(P-((2,4-Diamino-6-Pteridinylmethyl)Amino)Benzoyl)-L(+)-Glutamova",
      "Kyselina N-(P-((2,4-Diamino-6-Pteridinylmethyl)Amino)Benzoyl)-L(+)-Glutamova [Czech]",
      "N-(4-(((2,4-Diamino-6-Pteridyl)-Methyl)Amino)Benzoyl)-L-Glutamic Acid",
      "(2R)-2-[[4-[(2,4-Diaminopteridin-6-Yl)Methylamino]benzoyl]amino]pentanedioic Acid",
      "(2S)-2-[[4-[(2,4-Diaminopteridin-6-Yl)Methylamino]benzoyl]amino]pentanedioic Acid",
      "2-[[4-[(2,4-Diaminopteridin-6-Yl)Methylamino]benzoyl]amino]pentanedioic Acid",
      "4'-Amino-Folsaeure",
      "4'-Desoxy-4'-Aminofolsaeure",
      "4-Amino Pteroylglutamic Acid",
      "4-Amino-4-Deoxypteroylglutamate",
      "4-Amino-4-Desoxy-Pteroylglutaminsaeure",
      "4-Amino-PGA",
      "4-Aminofolic Acid",
      "4-Aminopteroyl-<R>glutamic Acid",
      "4-Aminopteroyl-L-Glutamic Acid",
      "4-Aminopteroyl-Glutamic Acid",
      "4-Aminopteroylglutamic Acid",
    ],
  },
  {
    primary_id: "D0D2RR",
    names: ["TgBSSL"],
  },
  {
    primary_id: "D0D2RZ",
    names: ["SDZ-267-489"],
  },
  {
    primary_id: "D0D2SE",
    names: ["(Z)-1,1,1,2,2,3,3-Heptafluorohenicos-12-En-4-One", "CHEMBL1098397"],
  },
  {
    primary_id: "D0D2SG",
    names: ["99mTc-Ciprofloxacin, DRAXIS"],
  },
  {
    primary_id: "D0D2ST",
    names: ["2-Methyl-3-(Pyridin-4-Yl)-1H-Indole", "CHEMBL207501", "ZINC36338566"],
  },
  {
    primary_id: "D0D2SU",
    names: ["DLX-521", "Anti-ALK Antibody Fragment, Delenex", "Anti-CD246 Antibody Fragment, Delenex"],
  },
  {
    primary_id: "D0D2SX",
    names: [
      "INNO-101",
      "E1 Therapeutic Vaccine, Innogenetics",
      "HCV Therapeutic Vaccine, GENimmune",
      "HCV Therapeutic Vaccine, Innogenetics",
      "Hepatitis C Vaccine, Innogenetics",
      "INNOVAX-C",
      "InnoVac-C",
      "HCV-E1 Vaccine, Innogenetics",
    ],
  },
  {
    primary_id: "D0D2TN",
    names: ["Rimexolone"],
  },
  {
    primary_id: "D0D2TT",
    names: ["TV-1106"],
  },
  {
    primary_id: "D0D2TZ",
    names: ["CMS-024-02"],
  },
  {
    primary_id: "D0D2UA",
    names: ["2-NAP"],
  },
  {
    primary_id: "D0D2UI",
    names: ["Amyotrophic Lateral Sclerosis Therapeutics"],
  },
  {
    primary_id: "D0D2US",
    names: ["2-(Allyloxy)-N8-Hydroxy-N1-Phenyloctanediamide", "CHEMBL402719", "SCHEMBL8150833"],
  },
  {
    primary_id: "D0D2UU",
    names: ["XEL-003FL", "Antifungal (Transdermal Patch, Onychomycosis), Xel Pharmaceuticals"],
  },
  {
    primary_id: "D0D2VH",
    names: ["Vaccine, Nanopatch, Influenza"],
  },
  {
    primary_id: "D0D2VI",
    names: ["HYG-114"],
  },
  {
    primary_id: "D0D2VS",
    names: [
      "Exemestane",
      "Aromasil",
      "Aromasin",
      "Aromasine",
      "EXE",
      "Exemestance",
      "Exemestano",
      "Exemestanum",
      "Nikidess",
      "Pfizer Brand Of Exemestane",
      "Curator_000009",
      "Fce 24304",
      "Aromasin (TN)",
      "Aromasin, Exemestane",
      "Exemestano [INN-Spanish]",
      "Exemestanum [INN-Latin]",
      "FCE-24304",
      "PNU-155971",
      "Exemestane [USAN:INN:BAN]",
      "Exemestane (JAN/USP/INN)",
      "(8R,9S,10R,13S,14S)-10,13-Dimethyl-6-Methylidene-7,8,9,11,12,14,15,16-Octahydrocyclopenta[a]phenanthrene-3,17-Dione",
      "6-Methylenandrosta-1,4-Diene-3,17-Dione",
      "6-Methyleneandrosta-1,4-Diene-3,17-Dione",
      "6-Methylideneandrosta-1,4-Diene-3,17-Dione",
    ],
  },
  {
    primary_id: "D0D2WC",
    names: ["5-Ethyl-3-Methyl-Pyrrolidin-(2Z)-Ylideneamine", "CHEMBL185585", "SCHEMBL12617784", "BDBM50150915"],
  },
  {
    primary_id: "D0D2WN",
    names: ["2-N-Methylamino-1-(4-Methylthiophenyl)Butane", "CHEMBL1078440", "1204749-45-0"],
  },
  {
    primary_id: "D0D2WV",
    names: ["Azapeptide"],
  },
  {
    primary_id: "D0D2WX",
    names: ["1614235 + 2330672", "AC1LUPX5"],
  },
  {
    primary_id: "D0D2XA",
    names: ["REIC Gene Therapy"],
  },
  {
    primary_id: "D0D2XH",
    names: ["4-(4-Methoxy-1H-Indol-1-Ylsulfonyl)Benzenamine", "CHEMBL379273"],
  },
  {
    primary_id: "D0D2XQ",
    names: ["ASN007"],
  },
  {
    primary_id: "D0D2YG",
    names: ["PMID29053063-Compound-7d"],
  },
  {
    primary_id: "D0D2YR",
    names: [
      "9-Methyl-2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "16502-02-6",
      "9-Methyl-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
      "1H-Pyrido[3,4-B]indole, 2,3,4,9-Tetrahydro-9-Methyl-",
      "SCHEMBL967629",
      "CHEMBL263883",
      "STMNLEHELVDDKF-UHFFFAOYSA-N",
      "AKOS022703460",
      "AS-53673",
      "9-Methyl-1,2,3,4-Tetrahydro-Beta-Carboline",
    ],
  },
  {
    primary_id: "D0D2YZ",
    names: ["BMTP-11"],
  },
  {
    primary_id: "D0D2ZH",
    names: ["[3H]dizocilpine", "[3H]MK-801"],
  },
  {
    primary_id: "D0D2ZI",
    names: ["SOM-1000"],
  },
  {
    primary_id: "D0D2ZM",
    names: ["PD-135158", "CAM 1028", "PD 135158"],
  },
  {
    primary_id: "D0D3AI",
    names: ["L-NAME"],
  },
  {
    primary_id: "D0D3AS",
    names: ["5-Nitro-2-(4-Phenoxy-Phenyl)-1H-Benzoimidazole", "CHEMBL175481"],
  },
  {
    primary_id: "D0D3BL",
    names: ["Pyrazole-3-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0D3BO",
    names: [
      "SOLPECAINOL HYDROCHLORIDE",
      "EGYT-2936",
      "Solpecainol Hydrochloride",
      "Rac-Threo-2-(1-Methyl-2-Phenoxyethylamino)-1-Phenyl-1,3-Propanediol Hydrochloride",
      "Rac-[1R*,2S*(S*)]-2-[(1-Methyl-2-Phenoxyethyl)Amino]-1-Phenyl-1,3-Propanediol Hydrochloride",
    ],
  },
  {
    primary_id: "D0D3BP",
    names: [
      "6-(3-BROMO-2-NAPHTHYL)-1,3,5-TRIAZINE-2,4-DIAMINE",
      "6-(3-Bromonaphthalen-2-Yl)-1,3,5-Triazine-2,4-Diamine",
      "2qf6",
      "HSP90 Inhibitor, 1",
      "SCHEMBL10822557",
      "BDBM81729",
      "DB07319",
    ],
  },
  {
    primary_id: "D0D3CG",
    names: ["GWTLNSAGYLLGPrPKPQQwFwLL-CONH2"],
  },
  {
    primary_id: "D0D3CJ",
    names: ["BYK-191023"],
  },
  {
    primary_id: "D0D3CS",
    names: ["Uric Acid"],
  },
  {
    primary_id: "D0D3DU",
    names: ["Methotrexate Sodium"],
  },
  {
    primary_id: "D0D3FB",
    names: ["PMID25656651-Compound-21a"],
  },
  {
    primary_id: "D0D3FF",
    names: [
      "2-(Phenylethynyl)Pyrimidine",
      "CHEMBL450465",
      "Phenylethynylpyrimidine",
      "SCHEMBL13841597",
      "ZINC39236614",
      "BDBM50278929",
    ],
  },
  {
    primary_id: "D0D3FP",
    names: ["Drug 2881078", "2881078"],
  },
  {
    primary_id: "D0D3FV",
    names: ["1-Tert-Butyl-9H-Carbazole", "CHEMBL1172393", "T-Butyl Carbazole", "SCHEMBL3866477"],
  },
  {
    primary_id: "D0D3GF",
    names: [
      "1-Adamantan-1-Yl-3-(1-Butyl-Piperidin-4-Yl)-Urea",
      "CHEMBL386820",
      "SCHEMBL5144023",
      "BDBM100356",
      "BDBM50191894",
      "US8501783, 1160",
    ],
  },
  {
    primary_id: "D0D3GL",
    names: ["Tetra-Hydro-Pyrazolopyrazine Derivative 2"],
  },
  {
    primary_id: "D0D3HB",
    names: ["N-(4-Sulfamoylphenyl)-1H-Indazole-3-Carboxamide"],
  },
  {
    primary_id: "D0D3HF",
    names: ["TBI-304"],
  },
  {
    primary_id: "D0D3HM",
    names: ["RG7212"],
  },
  {
    primary_id: "D0D3HV",
    names: ["PLR-21"],
  },
  {
    primary_id: "D0D3JQ",
    names: ["Flovagatran"],
  },
  {
    primary_id: "D0D3KG",
    names: ["RG-7236"],
  },
  {
    primary_id: "D0D3KS",
    names: ["Orochol"],
  },
  {
    primary_id: "D0D3KY",
    names: ["SC-106"],
  },
  {
    primary_id: "D0D3LZ",
    names: ["20D75"],
  },
  {
    primary_id: "D0D3MR",
    names: ["[2,2':5',2'']Terthiophene-4-Carbaldehyde", "CHEMBL73495", "BDBM50217040"],
  },
  {
    primary_id: "D0D3MV",
    names: ["ISIS 29255"],
  },
  {
    primary_id: "D0D3NV",
    names: [
      "Adamantan-1-Yl-Piperidin-1-Yl-Methanone",
      "Adamantanyl Piperidyl Ketone",
      "CHEMBL245590",
      "1-Adamantyl(Piperidino)Methanone",
      "22508-49-2",
      "(3r,5r,7r)-Adamantan-1-Yl(Piperidin-1-Yl)Methanone",
      "1-Adamantyl(Piperidin-1-Yl)Methanone",
      "SMR000117086",
      "AC1LBKY9",
      "Maybridge1_008125",
      "Oprea1_633750",
      "Oprea1_290126",
      "MLS000526612",
      "Piperidino(1-Adamantyl) Ketone",
      "SCHEMBL3457055",
      "SCHEMBL19841773",
      "CTK7F5375",
      "HMS564J07",
      "BURUIZXRHQWZQI-UHFFFAOYSA-N",
      "MolPort-001-930-823",
      "1-(1-Adamantylcarbonyl)Piperidine",
      "HMS2178P18",
      "ZINC4031797",
    ],
  },
  {
    primary_id: "D0D3OF",
    names: [
      "1-(4-Bromo-2,5-Dimethoxy-Phenyl)-Piperazine",
      "CHEMBL348140",
      "AC1NHEA9",
      "1-(2,5-Dimethoxy-4-Bromophenyl)Piperazine",
      "Oprea1_700068",
      "BDBM50226218",
      "AKOS003587698",
      "100939-87-5",
    ],
  },
  {
    primary_id: "D0D3OV",
    names: ["AcAsp-Glu-Cha-Val-Prb-Cpg", "CHEMBL360983"],
  },
  {
    primary_id: "D0D3OX",
    names: ["N-[5-(2-Nitro-Phenyl)-Furan-2-Carbonyl]-Guanidine", "CHEMBL425186"],
  },
  {
    primary_id: "D0D3PB",
    names: ["BAY 60-4552"],
  },
  {
    primary_id: "D0D3PF",
    names: ["VOROZOLE"],
  },
  {
    primary_id: "D0D3PX",
    names: ["SCH 727965", "Dinaciclib", "SCH-727965"],
  },
  {
    primary_id: "D0D3PY",
    names: [
      "1-(3,4-Dichlorophenyl)-2-(Phenylsulfonyl)Ethanone",
      "CHEMBL238835",
      "1-(3,4-Dichlorophenyl)-2-(Phenylsulfonyl)-1-Ethanone",
      "344266-52-0",
      "AC1LS77V",
      "MolPort-002-876-897",
      "ZINC1396961",
      "BDBM50212532",
      "AKOS005097772",
      "MCULE-8898035078",
      "7H-370S",
      "KS-00001Z97",
      "2-(Benzenesulfonyl)-1-(3,4-Dichlorophenyl)Ethanone",
    ],
  },
  {
    primary_id: "D0D3PZ",
    names: ["Dipropyl-5-CT", "DP-5-CT", "DP5CT", "N,N-Dipropylcarboxamidotryptamine"],
  },
  {
    primary_id: "D0D3QA",
    names: ["4-(Isoquinolin-5-Yl)-N-M-Tolylphthalazin-1-Amine", "CHEMBL202998", "BDBM50179884"],
  },
  {
    primary_id: "D0D3QE",
    names: ["4-(2-Pyrrolidin-1-Yl-Pentanoyl)Benzonitrile", "CHEMBL202518", "SCHEMBL4932714"],
  },
  {
    primary_id: "D0D3QG",
    names: ["ISIS 134608"],
  },
  {
    primary_id: "D0D3QJ",
    names: ["CRTX-067"],
  },
  {
    primary_id: "D0D3QN",
    names: ["CD11301"],
  },
  {
    primary_id: "D0D3RL",
    names: [
      "AP-24163",
      "CHEMBL539433",
      "926922-16-9",
      "3-{(E)-2-[6-(Cyclopropylamino)-9h-Purin-9-Yl]ethenyl}-4-Methyl-N-[3-(4-Methyl-1h-Imidazol-1-Yl)-5-(Trifluoromethyl)Phenyl]benzamide",
      "Benzamide,3-[(1E)-2-[6-(Cyclopropylamino)-9H-Purin-9-Yl]ethenyl]-4-Methyl-N-[3-(4-Methyl-1H-Imidazol-1-Yl)-5-(Trifluoromethyl)Phenyl]-",
      "3kfa",
      "SCHEMBL4129401",
      "SCHEMBL4129397",
      "ZINC42919869",
      "BDBM50294011",
      "AP24163",
      "KB-74929",
      "3-(2-(6-(Cyclopropylamino)-9H-Purin-9-Yl)Vinyl)-4-Methyl-N-(3-(4-Methyl-1H-Imidazol-1-Yl)-5-(Trifluoromethyl)Phenyl)Benzamide",
    ],
  },
  {
    primary_id: "D0D3RS",
    names: ["Biaryl Compound 1"],
  },
  {
    primary_id: "D0D3RY",
    names: ["Merozoite Surface Protein 3 Long Synthetic Peptide Vaccine"],
  },
  {
    primary_id: "D0D3SW",
    names: ["ABX-103", "Anticancer Antibody (3), Abxign"],
  },
  {
    primary_id: "D0D3TB",
    names: ["N-ALLYLNORGALANTHAMINE"],
  },
  {
    primary_id: "D0D3TG",
    names: ["5-Hex-5-Enyl-2-Nonyl-3,4-Dihydro-2H-Pyrrole", "CHEMBL14331"],
  },
  {
    primary_id: "D0D3TK",
    names: ["PNU 101017"],
  },
  {
    primary_id: "D0D3TO",
    names: ["PYN-22", "Aurantio-Obtusin + Obtusifolin"],
  },
  {
    primary_id: "D0D3TQ",
    names: ["GSK2330811"],
  },
  {
    primary_id: "D0D3TU",
    names: ["DWJ-207"],
  },
  {
    primary_id: "D0D3TX",
    names: ["EVP-6124"],
  },
  {
    primary_id: "D0D3UB",
    names: ["SN-23"],
  },
  {
    primary_id: "D0D3UI",
    names: ["ZY01"],
  },
  {
    primary_id: "D0D3UQ",
    names: [
      "Streptococcus Mutans Vaccine",
      "Dental Caries Vaccine, University Of Alabama At Birmingham",
      "Streptococcus Mutans Vaccine, University Of Alabama At Birmingham",
    ],
  },
  {
    primary_id: "D0D3VA",
    names: ["PAI-2"],
  },
  {
    primary_id: "D0D3VF",
    names: ["Ac-ICV(5fW)QDWGAHRCT-NH2"],
  },
  {
    primary_id: "D0D3VT",
    names: [
      "Dihydrexidine",
      "DIHYDREXIDINE",
      "(+)-Dihydrexidine",
      "UNII-Q3PJ4B4D0X",
      "Q3PJ4B4D0X",
      "CHEMBL25856",
      "Dihydrexidine Hydrochloride",
      "(6aR,12bS)-5,6,6a,7,8,12b-Hexahydrobenzo[a]phenanthridine-10,11-Diol",
      "(6aR,12bS)-5,6,6a,7,8,12b-Hexahydrobenzo(A)Phenanthridine-10,11-Diol",
      "Rel-Dihydrexidine",
      "Dihydrexidine, (+)-",
      "Lopac-D-5814",
      "Biomol-NT_000033",
      "AC1O7G2G",
      "Lopac0_000380",
      "5,6,6a,7,8,12b-Hexahydro-Benzo[a]phenanthridine-10,11-Diol",
      "BPBio1_001227",
      "SCHEMBL4614575",
      "CHEBI:124993",
      "DAR-0100",
      "IP-202",
      "BDBM50010686",
      "ZINC25758512",
      "CCG-204474",
      "NCGC00024845-04",
    ],
  },
  {
    primary_id: "D0D3WJ",
    names: [
      "5-Bromovinyldeoxyuridine",
      "Brivudine",
      "69304-47-8",
      "BVDU",
      "(E)-5-(2-Bromovinyl)-2'-Deoxyuridine",
      "Brivudin",
      "Helpin",
      "Bromovinyldeoxyuridine",
      "Zostex",
      "Brivudine [INN]",
      "5-BROMOVINYLDEOXYURIDINE",
      "Brivudinum [INN-Latin]",
      "Brivudina [INN-Spanish]",
      "C11H13BrN2O5",
      "CCRIS 2831",
      "UNII-2M3055079H",
      "5-(2-Bromovinyl)-2'-Deoxyuridine",
      "(E)-5-(2-Bromovinyl)-Deoxyuridine",
      "Uridine, 5-(2-Bromoethenyl)-2'-Deoxy-, (E)-",
      "NSC 633770",
      "Trans-5-(2-Bromovinyl)-2'-Deoxyuridine",
      "Uridine, 5-[(1E)-2-Bromoethenyl]-2'-Deoxy-",
      "Brivudine (INN)",
      "MFCD00058585",
      "(E)-5-(2-Bromovinyl)-2-Deoxyuridine",
    ],
  },
  {
    primary_id: "D0D3WR",
    names: [
      "9-Benzyl-6-(Furan-2-Yl)-9H-Purin-2-Amine",
      "CHEMBL196436",
      "SCHEMBL3268454",
      "2-Amino-6-Furyl-9-Benzyl Purine",
      "BDBM50165066",
    ],
  },
  {
    primary_id: "D0D3XC",
    names: [
      "N-Hydroxybenzo[b]thiophene-2-Carboxamide",
      "CHEMBL245946",
      "211172-97-3",
      "Benzo[b]thiophene-2-Carboxamide, N-Hydroxy-",
      "SCHEMBL999904",
      "CTK0J7987",
      "DTXSID40470925",
      "BDBM50216024",
      "Benzothiophene-2-Carbohydroxamic Acid",
    ],
  },
  {
    primary_id: "D0D3XM",
    names: ["TAK-137"],
  },
  {
    primary_id: "D0D3YL",
    names: ["(5-Phenyl-Oxazol-2-Yl)-M-Tolyl-Amine"],
  },
  {
    primary_id: "D0D3YN",
    names: [
      "OCTOCLOTHEPIN",
      "Clorotepine",
      "Octoclothepin",
      "Octoclothepine",
      "Clotepin",
      "Clothepin",
      "13448-22-1",
      "Clorotepine [INN]",
      "Clorotepinum [INN-Latin]",
      "Clorotepina [INN-Spanish]",
      "CHEMBL64249",
      "1-(8-Chloro-10,11-Dihydrodibenzo(B,f)Thiepin-10-Yl)-4-Methylpiperazine",
      "Piperazine, 1-(8-Chloro-10,11-Dihydrodibenzo(B,f)Thiepin-10-Yl)-4-Methyl-",
      "Clothepine",
      "Clorothepin",
      "Clorotepinum",
      "Clorotepina",
      "1-(8-Chloro-10,11-Dihydrodibenzo[b,f]thiepin-10-Yl)-4-Methylpiperazine",
      "VUFB-6281",
      "Octoclothepin Maleate",
      "CLOROTEPINE(-)",
      "AC1Q3SXZ",
      "CLOROTEPINE (+)",
      "OCTOCLOTHEPIN,(-)",
    ],
  },
  {
    primary_id: "D0D3ZP",
    names: [
      "NBI-42902",
      "352290-60-9",
      "UNII-3FWQ4MD98O",
      "3FWQ4MD98O",
      "CHEMBL179691",
      "NBI42902",
      "GTPL1187",
      "GTPL1178",
      "SCHEMBL5758034",
      "ZINC6716969",
      "BDBM50162007",
      "NBI 42902",
      "API0005260",
      "2,4(1H,3H)-Pyrimidinedione, 3-((2R)-2-Amino-2-Phenylethyl)-1-((2,6-Difluorophenyl)Methyl)-5-(2-Fluoro-3-Methoxyphenyl)-6-Methyl-",
      "KB-138000",
      "FT-0771447",
      "3-[(2R)-2-Amino-2-Phenylethyl]-1-[(2,6-Difluorophenyl)Methyl]-5-(2-Fluoro-3-Methoxyphenyl)-6-Methylpyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0D4AE",
    names: ["N-(3-(3-Cyanobenzamido)Phenyl)-2-Methoxybenzamide", "CHEMBL1081367"],
  },
  {
    primary_id: "D0D4AQ",
    names: [
      "PCI-45292",
      "Btk Inhibitors (Autoimmune Disease)",
      "Btk Inhibitors (Autoimmune Disease), Pharmacyclics",
      "Bruton's Tyrosine Kinase Inhibitors (Autoimmune Disease), Pharmacyclics",
    ],
  },
  {
    primary_id: "D0D4AU",
    names: ["PROSTEPHABYSSINE"],
  },
  {
    primary_id: "D0D4BP",
    names: ["Thiomorpholine Derivative 2"],
  },
  {
    primary_id: "D0D4BR",
    names: ["ISIS 102676"],
  },
  {
    primary_id: "D0D4CX",
    names: ["Pegamotecan"],
  },
  {
    primary_id: "D0D4CY",
    names: [
      "Sulfapyridine",
      "Adiplon",
      "Coccoclase",
      "Dagenan",
      "Eubasin",
      "Eubasinum",
      "Haptocil",
      "Piridazol",
      "Plurazol",
      "Pyriamid",
      "Pyridazol",
      "Relbapiridina",
      "Ronin;Septipulmon",
      "Solfapiridina",
      "Streptosilpyridine",
      "Sulfapiridina",
      "Sulfapyridinum",
      "Sulfidin",
      "Sulfidine",
      "Sulphapyridin",
      "Sulphapyridine",
      "Thioseptal",
      "Trianon",
      "Solfapiridina [DCIT]",
      "M And B 693",
      "A-499",
      "ALBB-006215",
      "M + B 693",
      "M&B 693",
      "M+B 693",
      "Sulfapiridina [INN-Spanish]",
      "Sulfapyridine (TN)",
      "Sulfapyridinum [INN-Latin]",
      "AO-801/41077453",
      "N(1)-Pyridylsulfanilamide",
      "N(Sup1)-Pyridylsulfanilamide",
      "N-2-Pyridylsulfanilamide",
      "N1-2-Pyridylsulfanilamide",
      "Sulfapyridine (USP/INN)",
      "Sulfapyridine [USAN:INN:BAN]",
      "N'-2-Pyridylsulfanilide",
      "N(1)-2-Pyridylsulfanilamide",
      "N(Sup 1)-2-Pyridylsulfanilamide",
      "N1-(Pyridin-2-Yl)Sulfanilamide",
      "Sulfanilamide, N1-2-Pyridyl-(8CI)",
      "2-(4-Aminobenzenesulfonamido)Pyridine",
      "2-(P-Aminobenzenesulphonamido)Pyridine",
      "2-Sulfanilamidopyridin",
      "2-Sulfanilamidopyridin [German]",
      "2-Sulfanilamidopyridine",
      "2-Sulfanilyl Aminopyridine",
      "2-Sulfanilylaminopyridine",
      "2-Sulfapyridine",
      "4-(2-Pyridinylsulfonyl)Aniline",
      "4-AMINO-N-2-PYRIDINYLBENZENESULFONAMIDE",
      "4-Amino-N,2-Pyridinylbenzenesulfonamide",
      "4-Amino-N-2-Pyridinyl-Benzenesulfonamide",
      "4-Amino-N-[2-Pyridyl]benzene Sulfonamide",
      "4-[(2-Pyridylamino)Sulfonyl]aniline",
      "4-Amino-N-(Pyridin-2-Yl)Benzenesulfonamide",
      "4-Amino-N-Pyridin-2-Yl-Benzenesulfonamide",
      "4-Amino-N-Pyridin-2-Ylbenzenesulfonamide",
    ],
  },
  {
    primary_id: "D0D4DY",
    names: ["Tetracel"],
  },
  {
    primary_id: "D0D4FR",
    names: ["Phenylpyrrolidinone Derivative 5"],
  },
  {
    primary_id: "D0D4FV",
    names: ["LEACHIANONE A"],
  },
  {
    primary_id: "D0D4HG",
    names: [
      "3-Cyano-N-(1,3-Diphenyl-1H-Pyrazol-5-Yl)Benzamide",
      "CDPPB",
      "781652-57-1",
      "MGluR5 Ligand, CDPPB",
      "CHEMBL190270",
      "GTPL1422",
      "SCHEMBL1823352",
      "CTK5E5464",
      "DTXSID80459996",
      "MolPort-009-199-287",
      "ZINC3986698",
      "BDBM50156071",
      "IN1239",
      "AKOS024457497",
      "RTC-070344",
      "NCGC00370800-01",
      "KB-75992",
      "EN002594",
      "TC-070344",
      "DB-075421",
      "FT-0763465",
      "B7324",
      "A9858",
      "3-Cyano-N-[2,5-Di(Phenyl)Pyrazol-3-Yl]benzamide",
      "3-Cyano-N-1,3-Diphenyl-1H-Pyrazol-5-Yl)Benzamide",
    ],
  },
  {
    primary_id: "D0D4HH",
    names: [
      "JWH-250",
      "864445-43-2",
      "2-(2-Methoxyphenyl)-1-(1-Pentyl-1H-Indol-3-Yl)Ethanone",
      "2-(2-Methoxyphenyl)-1-(1-Pentylindol-3-Yl)Ethanone",
      "UNII-IP9911R8A0",
      "CHEMBL188031",
      "FFLSJIQJQKDDCM-UHFFFAOYSA-N",
      "IP9911R8A0",
      "Q-200139",
      "1-Pentyl-3-(2-Methoxyphenylacetyl)Indole",
      "DEA No. 6250",
      "SCHEMBL11983322",
      "CTK8C5059",
      "JWH 250",
      "DTXSID40235556",
      "MolPort-009-019-586",
      "KS-000024QU",
      "ANW-73903",
      "ZINC28465020",
      "MFCD12911796",
      "BDBM50170335",
      "AKOS015999864",
      "DS-3158",
      "CCG-208724",
      "NCGC00386575-01",
      "AJ-83461",
      "CJ-17680",
      "AK-82232",
      "AN-26990",
      "KB-110367",
      "TC-162648",
      "ST24021676",
    ],
  },
  {
    primary_id: "D0D4HM",
    names: ["Alglucerase", "Ceredase (TN)"],
  },
  {
    primary_id: "D0D4HN",
    names: ["Podofilox"],
  },
  {
    primary_id: "D0D4HO",
    names: ["GC021109"],
  },
  {
    primary_id: "D0D4IG",
    names: [
      "5,6,7-Trichloro-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "5,6,7-Trichloro-1,4-Dihydroquinoxaline-2,3-Dione",
      "CHEMBL43846",
      "172215-97-3",
      "SCHEMBL8904175",
    ],
  },
  {
    primary_id: "D0D4IH",
    names: ["Oxitropium Bromide"],
  },
  {
    primary_id: "D0D4IJ",
    names: ["N-Hexylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL488348"],
  },
  {
    primary_id: "D0D4IL",
    names: ["AMT-021"],
  },
  {
    primary_id: "D0D4JI",
    names: ["SR-31747"],
  },
  {
    primary_id: "D0D4JO",
    names: [
      "Artesunate",
      "Arinate",
      "Arsumax",
      "Artesunato",
      "Artesunatum",
      "Artsuna",
      "Dihydroqinghaosusuccinate",
      "Nuartez",
      "Plasmotrim",
      "Plasmotrin",
      "Qinghaozhi",
      "Artesunic Acid",
      "Dihydroqinghasu Hemsuccinate",
      "Quinghaosu Reduced Succinate Ester",
      "Succinyl Dihydroartemisinin",
      "SM 804",
      "WR 256283",
      "Arsumax (TN)",
      "Artesunate (USAN)",
      "Artesunate (Superseded RN)",
      "Artesunato [INN-Spanish]",
      "Artesunatum [INN-Latin]",
      "WR-256283",
      "Dihydroartemisinine-12-Alpha-Succinate",
      "Butanedioic Acid, Mono(Decahydro-3,6,9-Trimethyl-3,12-Epoxy-12H-Pyrano(4,3-J)-1,2-Benzodioxepin-10-Yl) Ester",
      "Butanedioic Acid, Mono((3R,5aS,6R,8aS,9R,10S,12R,12aR)-Decahydro-3,6,9-Trimethyl-3,12-Epoxy-12H-Pyrano(4,3-J)-1,2-Benzodioxepin-10-Yl) Ester",
      "(3R,5aS,6R,8aS,9R,10S,12R,12aR)-Decahydro-3,6,9-Trimethyl-3,12-Epoxy-12H-Pyrano(4,3-J)-1,2-Benzodioxepin-10-Ol, Hydrogen Succinate",
      "4-Oxo-4-(((3R,5aS,6R,8aS,9R,10S,12R,12aR)-3,6,9-Trimethyldecahydro-3,12-Epoxypyrano(4,3-J)-1,2-Benzodioxepin-10-Yl Hydrogen Butanedioate",
      "4-Oxo-4-{[(3r,5as,6r,8as,9r,10r,12r,12ar)-3,6,9-Trimethyldecahydro-3,12-Epoxy[1,2]dioxepino[4,3-I]isochromen-10-Yl]oxy}butanoic Acid",
      "4-Oxo-4-{[(5aS,6R,8aS,9R,10S,12R,12aR)-3,6,9-Trimethyldecahydro-3,12-Epoxy[1,2]dioxepino[4,3-I]isochromen-10-Yl]oxy}butanoic Acid",
    ],
  },
  {
    primary_id: "D0D4JT",
    names: ["PND-1186"],
  },
  {
    primary_id: "D0D4JZ",
    names: ["Isoleucine Thiazolidide DPP IV"],
  },
  {
    primary_id: "D0D4LF",
    names: ["NS1738"],
  },
  {
    primary_id: "D0D4LP",
    names: ["JVRS-100"],
  },
  {
    primary_id: "D0D4LS",
    names: ["PUR003"],
  },
  {
    primary_id: "D0D4MM",
    names: [
      "Ethyl 6-Iodo-9H-Pyrido[3,4-B]indole-3-Carboxylate",
      "CHEMBL1269786",
      "Iodo-BetaCCE",
      "SCHEMBL7380021",
      "LTYUPBZLKIUGQX-UHFFFAOYSA-N",
      "BDBM50329629",
      "6-Iodo-Beta-Carboline-3-Carboxylic-Acid-Ethylester",
      "6-Iodo-Beta-Carbolin-3-Carboxylic Acid Ethyl Ester",
    ],
  },
  {
    primary_id: "D0D4NJ",
    names: ["CYT-005-AllQbG10", "CYT-003-QbG10 + Allergen Vaccine, Cytos Biotechnology"],
  },
  {
    primary_id: "D0D4NS",
    names: ["2-(1H-Imidazo[4,5-C]pyridin-2-Yl)Quinoxaline", "CHEMBL380770", "ZINC13678127"],
  },
  {
    primary_id: "D0D4NX",
    names: ["PMID25666693-Compound-108"],
  },
  {
    primary_id: "D0D4OL",
    names: ["SC-002"],
  },
  {
    primary_id: "D0D4OS",
    names: ["VM-202"],
  },
  {
    primary_id: "D0D4OV",
    names: ["Octane-1,8-Diyl Disulfamate"],
  },
  {
    primary_id: "D0D4OW",
    names: ["PMID25435285-Compound-15"],
  },
  {
    primary_id: "D0D4PB",
    names: [
      "Dextropropoxyphene",
      "Algafan",
      "Antalvic",
      "Destropropossifene",
      "Dextropropoxifeno",
      "Dextropropoxyphen",
      "Dextropropoxyphenum",
      "Dextroproxifeno",
      "Femadol",
      "Levopropoxyphene",
      "Propoxyphene",
      "Proxagesic",
      "Proxyphen",
      "Destropropossifene [DCIT]",
      "Dextroproxifeno [Spanish]",
      "SK 65",
      "D-Propoxyphene",
      "Dextropropoxifeno [INN-Spanish]",
      "Dextropropoxyphene[INN:BAN]",
      "Dextropropoxyphenum [INN-Latin]",
      "Bulk Dextropropoxyphene (Non-Dosage Forms)",
      "Alpha-4-Dimethylamino-1,2-Diphenyl-3-Methyl-2-Butanol Propionate",
      "Alpha-D-4-Dimethylamino-3-Methyl-1,2-Diphenyl-2-Butanol Propionate",
      "Alpha-D-4-Dimethylamino-3-Methyl-1,2-Diphenyl-2-Butanol-Propionat",
      "Alpha-D-4-Dimethylamino-3-Methyl-1,2-Diphenyl-2-Butanol-Propionat [German]",
      "Alpha-(+)-4-Dimethylamino-1,2-Diphenyl-3-Methyl-2-Butanol Propionate Ester",
      "Alpha-(+)-4-Dimethylamino-1,2-Diphenyl-3-Methyl-2-Propionoxybutane",
      "[(2S,3R)-4-(Dimethylamino)-3-Methyl-1,2-Diphenylbutan-2-Yl] Propanoate",
      "(+)-1,2-Diphenyl-2-Propionoxy-3-Methyl-4-Dimethylaminobutane",
      "(+)-4-Dimethylamino-1,2-Diphenyl-3-Methyl-2-Propionyloxybutane",
      "(1S,2R)-1-Benzyl-3-(Dimethylamino)-2-Methyl-1-Phenylpropyl Propanoate",
      "(D)-PROPOXYPHENE",
      "(S)-Alpha-(2-(Dimethylamino)-1-Methylethyl)-Alpha-Phenylbenzeneethanol Propanoate",
      "4-Dimethylamino-3-Methyl-1,2-Diphenyl-2-Propoxybutane",
    ],
  },
  {
    primary_id: "D0D4QL",
    names: [
      "Benzimidazole 5-Carboxamide",
      "116568-17-3",
      "1H-Benzo[d]imidazole-6-Carboxamide",
      "1H-Benzimidazole-5-Carboxamide",
      "1H-Benzoimidazole-5-Carboxylic Acid Amide",
      "1H-Benzimidazole-6-Carboxamide",
      "1H-Benzo[d]imidazole-5-Carboxamide",
      "1H-1,3-Benzodiazole-5-Carboxamide",
      "Benzimidazole-5-Carboxamide",
      "FNLQDVXHDNFXIY-UHFFFAOYSA-N",
      "ACMC-20mmnb",
      "Benzoimidazole-5-Carboxamide",
      "SCHEMBL476587",
      "1H-Benzoimidazole-5-Carboxamide",
      "CTK8E3251",
      "CTK0H3052",
      "DTXSID30572624",
      "MolPort-000-353-183",
      "BCP27970",
      "2711AA",
      "ZINC27986912",
      "STL200279",
      "AKOS002314950",
      "AKOS022171450",
      "FS-2220",
      "MCULE-9328613043",
    ],
  },
  {
    primary_id: "D0D4QZ",
    names: ["RGDechi"],
  },
  {
    primary_id: "D0D4RF",
    names: ["Sapacitabine", "CYC682"],
  },
  {
    primary_id: "D0D4RW",
    names: [
      "5-(Azepan-1-Ylsulfonyl)Indoline-2,3-Dione",
      "1H-Azepine, 1-[(2,3-Dihydro-2,3-Dioxo-1H-Indol-5-Yl)Sulfonyl]hexahydro-",
      "220510-14-5",
      "Isatin Sulfonamide 22",
      "5-(Azepan-1-Ylsulfonyl)-1H-Indole-2,3-Dione",
      "CHEMBL302461",
      "SCHEMBL6535562",
      "CTK0I8877",
      "BDBM10311",
      "DTXSID20431675",
      "5-(Azepane-1-Sulfonyl)-2,3-Dihydro-1H-Indole-2,3-Dione",
    ],
  },
  {
    primary_id: "D0D4SI",
    names: ["24-Hydroxyursolic Acid", "CHEMBL522373", "BDBM50253075", "3beta,24-Dihydroxyurs-12-En-28-Oic Acid"],
  },
  {
    primary_id: "D0D4SK",
    names: [
      "6,8-Dichloro-1,2,3,4-Tetrahydro-Acridin-9-Ylamine",
      "CHEMBL48643",
      "Tacrine Deriv. 2",
      "6,8-Dichloro-1,2,3,4-Tetrahydroacridin-9-Amine",
      "SCHEMBL15168338",
      "BDBM10516",
      "ZINC13492737",
      "AKOS011484747",
    ],
  },
  {
    primary_id: "D0D4SO",
    names: [
      "4-Octyl-N-(Quinolin-3-Yl)Benzamide",
      "CHEMBL406859",
      "AC1MZP51",
      "SCHEMBL846096",
      "4-Octyl-N-Quinolin-3-Ylbenzamide",
      "BDBM50376278",
    ],
  },
  {
    primary_id: "D0D4TC",
    names: ["Sym021"],
  },
  {
    primary_id: "D0D4TY",
    names: ["PMID22873688C3a"],
  },
  {
    primary_id: "D0D4UH",
    names: ["PMID28394193-Compound-46"],
  },
  {
    primary_id: "D0D4UO",
    names: ["DPK-DTSM-02"],
  },
  {
    primary_id: "D0D4US",
    names: [
      "4-Bromo-1H-Indazole",
      "186407-74-9",
      "4-Bromoindazole",
      "1H-Indazole, 4-Bromo-",
      "4-BROMO (1H)INDAZOLE",
      "4-Bromo(1H)Indazole",
      "CHEMBL246393",
      "4-Bromo-Indazole",
      "Bromo-1h-Indazole",
      "PubChem7834",
      "4-Bromo-2H-Indazole",
      "ACMC-1BZDS",
      "4-Bromanyl-1H-Indazole",
      "KSC173Q8P",
      "4-BROMO-7AH-INDAZOLE",
      "SCHEMBL155255",
      "4-Bromo-1H-Indazole, 95%",
      "IND084",
      "AC1Q24I6",
      "Jsp003814",
      "4-Bromo-1h-Indazole, 97+%",
      "SCHEMBL16799950",
      "CTK0H3887",
      "HID1021",
      "DTXSID30625169",
      "KJIODOACRIRBPB-UHFFFAOYSA-N",
      "MolPort-022-450-748",
      "MolPort-000-002-423",
      "BCP00013",
      "ACN-S003168",
      "SBB054645",
      "BDBM50209241",
    ],
  },
  {
    primary_id: "D0D4UT",
    names: ["GSK2849330"],
  },
  {
    primary_id: "D0D4VH",
    names: [
      "BM-212",
      "BM212",
      "UNII-U9HSU4GZWL",
      "146204-42-4",
      "U9HSU4GZWL",
      "1-{[1,5-Bis(4-Chlorophenyl)-2-Methylpyrrol-3-Yl]methyl}-4-Methylpiperazine",
      "1-[[1,5-Bis(4-Chlorophenyl)-2-Methylpyrrol-3-Yl]methyl]-4-Methylpiperazine",
      "BM 212",
      "AC1L9VRH",
      "CHEMBL321077",
      "SCHEMBL1452627",
      "BCP24821",
      "ZINC22445591",
      "AKOS032944899",
      "CS-5953",
      "Piperazine, 1-((1,5-Bis(4-Chlorophenyl)-2-Methyl-1H-Pyrrol-3-Yl)Methyl)-4-Methyl-",
      "HY-100725",
    ],
  },
  {
    primary_id: "D0D4VN",
    names: ["HPV-16 E7 Vaccine"],
  },
  {
    primary_id: "D0D4VV",
    names: ["LY2874455"],
  },
  {
    primary_id: "D0D4WA",
    names: ["PHY-777"],
  },
  {
    primary_id: "D0D4XF",
    names: ["Daniplestim"],
  },
  {
    primary_id: "D0D4YR",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 1"],
  },
  {
    primary_id: "D0D4YZ",
    names: [
      "PSI-7977",
      "1190307-88-0",
      "SOVALDI",
      "PSI 7977",
      "GS-7977",
      "GS 7977",
      "UNII-WJ6CA3ZU8B",
      "WJ6CA3ZU8B",
      "CHEBI:85083",
      "Sofosbuvir (PSI-7977, GS-7977)",
      "Hepcvir",
      "Hepcinat",
      "HSDB 8226",
      "2-((5-(2,4-Dioxo-3,4-Dihydro-2H-Pyrimidin-1-Yl)-4-Fluoro-3-Hydroxy-4-Methyltetrahydrofuran-2-Ylmethoxy)Phenoxyphosphorylamino)Propionic Acid Isopropyl Ester",
      "Sofosbuvir [USAN:INN]",
      "Resof",
      "SoviHep",
      "Sovaldi (TN)",
      "Sofosbuvir (JAN/USAN)",
      "Sofosbuvir(PSI-7977)",
      "GTPL7368",
      "SCHEMBL2010114",
      "CHEMBL1259059",
      "AMMD00019",
      "EX-A389",
      "MolPort-028-720-482",
      "Isopropyl (2S)-2-[[[(2R,",
    ],
  },
  {
    primary_id: "D0D4ZE",
    names: ["JP-8019"],
  },
  {
    primary_id: "D0D4ZL",
    names: ["Phorbol 12-Myristate"],
  },
  {
    primary_id: "D0D4ZM",
    names: ["ISRAPAFANT"],
  },
  {
    primary_id: "D0D4ZN",
    names: ["CAR-T Cells Targeting BCMA"],
  },
  {
    primary_id: "D0D4ZW",
    names: ["EGS-21"],
  },
  {
    primary_id: "D0D4ZY",
    names: ["WT1"],
  },
  {
    primary_id: "D0D5AH",
    names: ["PMID29334795-Compound-62"],
  },
  {
    primary_id: "D0D5AR",
    names: ["Diphtheria/Tetanus Toxoids"],
  },
  {
    primary_id: "D0D5AV",
    names: ["HPV 16/18 Vaccine"],
  },
  {
    primary_id: "D0D5BI",
    names: ["5,6-Epoxyeicosatrienoic Acid", "(+/-)5,6-EpETrE", "(+/-)5,6-Epoxy-8Z,11Z,14Z-Eicosatrienoic Acid"],
  },
  {
    primary_id: "D0D5BJ",
    names: ["BPX-201"],
  },
  {
    primary_id: "D0D5CJ",
    names: ["SDZ-LAP-977"],
  },
  {
    primary_id: "D0D5CQ",
    names: ["NVP-2"],
  },
  {
    primary_id: "D0D5CY",
    names: ["Pradefovir"],
  },
  {
    primary_id: "D0D5DE",
    names: [
      "ALPRENOXIME HYDROCHLORIDE",
      "CDDD-1815",
      "HGP-5",
      "Alprenoxime Hydrochloride",
      "1-(2-Allylphenoxy)-3-(Isopropylamino)-2-Propanone Oxime Monohydrochloride",
      "1-[(1-Methylethyl)Amino]-3-[2-(2-Propenyl)Phenoxy]-2-Propanone Oxime Monohydrochloride",
    ],
  },
  {
    primary_id: "D0D5EJ",
    names: ["Insulin Oral Sublingual"],
  },
  {
    primary_id: "D0D5EW",
    names: ["HKI-9924129"],
  },
  {
    primary_id: "D0D5FB",
    names: ["EPO-Derived Peptide"],
  },
  {
    primary_id: "D0D5FF",
    names: ["Non-Allosteric AKT Inhibitors"],
  },
  {
    primary_id: "D0D5FQ",
    names: ["Agatolimod"],
  },
  {
    primary_id: "D0D5FR",
    names: ["Organosphosphates"],
  },
  {
    primary_id: "D0D5GD",
    names: ["Flumazenil"],
  },
  {
    primary_id: "D0D5GG",
    names: ["Pemoline"],
  },
  {
    primary_id: "D0D5GN",
    names: ["KNI-10314"],
  },
  {
    primary_id: "D0D5HE",
    names: ["ISIS 113906"],
  },
  {
    primary_id: "D0D5HM",
    names: ["4-Bromo-5,7-Dichloro-3-Hydroxy-1H-Quinolin-2-One", "CHEMBL147398"],
  },
  {
    primary_id: "D0D5HQ",
    names: ["K00024"],
  },
  {
    primary_id: "D0D5IJ",
    names: ["LN-030", "Autologous Cell Vaccine (Prostate Cancer), LipoNova"],
  },
  {
    primary_id: "D0D5IS",
    names: ["SYNSORB-Pk"],
  },
  {
    primary_id: "D0D5IV",
    names: ["PMID24412111C9f"],
  },
  {
    primary_id: "D0D5KD",
    names: [
      "(+/-)-2-(4'-Ethoxyphenyl)Thiomorpholin-5-One",
      "CHEMBL597769",
      "2-(4'-Ethoxyphenyl)Thiomorpholin-5-One",
      "BDBM50307371",
      "1218947-79-5",
    ],
  },
  {
    primary_id: "D0D5KQ",
    names: ["PU24S"],
  },
  {
    primary_id: "D0D5KZ",
    names: ["PMID17280833C30"],
  },
  {
    primary_id: "D0D5LX",
    names: ["NKTT-120"],
  },
  {
    primary_id: "D0D5MQ",
    names: ["Pyrazole Derivative 27"],
  },
  {
    primary_id: "D0D5MU",
    names: ["V10153"],
  },
  {
    primary_id: "D0D5NI",
    names: ["GBP-1a"],
  },
  {
    primary_id: "D0D5NN",
    names: ["Urea, C-14"],
  },
  {
    primary_id: "D0D5NP",
    names: ["MRS2496"],
  },
  {
    primary_id: "D0D5NV",
    names: [
      "GC-1109",
      "Anthrax Vaccine (Intramuscular), Korea Green Cross/ KCDC",
      "Anthrax Vaccine (Intramuscular), Korea Green Cross/ Korea Center For Disease Control And Prevention",
    ],
  },
  {
    primary_id: "D0D5NZ",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0D5OG",
    names: ["Kazinol C"],
  },
  {
    primary_id: "D0D5OY",
    names: [
      "Alpha-Fluoromethylenephosphonate",
      "GTPL2915",
      "CHEMBL190717",
      "[(3S)-1-Fluoro-3-Methoxy-4-[(9E)-Octadec-9-Enoyloxy]butyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0D5QP",
    names: ["L-366,509"],
  },
  {
    primary_id: "D0D5QS",
    names: [
      "[3-(4-Nonyl-Benzylamino)-Propyl]-Phosphonic Acid",
      "CHEMBL118860",
      "569684-50-0",
      "SCHEMBL3846202",
      "CTK1E1410",
      "DTXSID10471181",
      "IYOGKTIRIRHPOH-UHFFFAOYSA-N",
      "BDBM50148394",
      "N-(4-Nonylbenzyl)-3-Aminopropylphosphonic Acid",
      "Phosphonic Acid, [3-[[(4-Nonylphenyl)Methyl]amino]propyl]-",
    ],
  },
  {
    primary_id: "D0D5QT",
    names: [
      "1-(3-Bromomethyl-Phenyl)-2,2,2-Trifluoro-Ethanone",
      "3-Trifluoroacetylbenzyl Bromide",
      "370104-05-5",
      "1-[3-(Bromomethyl)Phenyl]-2,2,2-Trifluoroethan-1-One",
      "CHEMBL322727",
      "SCHEMBL19745468",
      "BDBM10502",
      "MolPort-039-241-133",
      "ZINC13864123",
      "1-[3-(Bromomethyl)Phenyl]-2,2,2-Trifluoroethanone",
      "1-[3-(Bromomethyl)-Phenyl]-2,2,2-Trifluoro-Ethanone",
    ],
  },
  {
    primary_id: "D0D5QV",
    names: ["O-DESMETHYL TRAMADOL"],
  },
  {
    primary_id: "D0D5RH",
    names: ["TAK-442"],
  },
  {
    primary_id: "D0D5SK",
    names: ["Ethyketazocine"],
  },
  {
    primary_id: "D0D5SP",
    names: ["Semiplenamide G"],
  },
  {
    primary_id: "D0D5SQ",
    names: [
      "Candesartan",
      "139481-59-7",
      "Blopress",
      "Ratacand",
      "CV-11974",
      "1-((2'-(1H-Tetrazol-5-Yl)-[1,1'-Biphenyl]-4-Yl)Methyl)-2-Ethoxy-1H-Benzo[d]imidazole-7-Carboxylic Acid",
      "CV 11974",
      "UNII-S8Q36MD2XX",
      "2-Ethoxy-3-[[4-[2-(2H-Tetrazol-5-Yl)Phenyl]phenyl]methyl]benzimidazole-4-Carboxylic Acid",
      "CHEMBL1016",
      "S8Q36MD2XX",
      "2-Ethoxy-1-(P-(O-1H-Tetrazol-5-Ylphenyl)Benzyl)-7-Benzimidazolecarboxylic Acid",
      "CHEBI:3347",
      "C24H20N6O3",
      "2-Ethoxy-3-[[4-[2-(1h-Tetrazol-5-Yl)Phenyl]phenyl]methyl]-3h-Benzoimidazole-4-Carboxylic Acid",
      "NCGC00167474-01",
      "AK-57139",
      "Candesartan [BAN]",
      "CV11974",
      "Amias (TN)",
      "Atacand (TN)",
      "Blopress (TN)",
      "Candesartan [USAN:INN]",
      "KS-5003",
      "Ratacand (TN)",
      "Candesartan (USAN/INN)",
      "Atacand, Blopress, Amias, Ratacand,Candesartan",
      "2-(Ethyloxy)-1-{[2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Yl]methyl}-1H-Benzimidazole-7-Carboxylic Acid",
      "2-Ethoxy-3-[[4-[2-(1H-Tetrazol-5-Yl)Phenyl]phenyl] Methyl]-3H-Benzoimidazole-4-Carboxylic Acid",
      "2-Ethoxy-1-({2'-(1H-Tetrazol-5-Yl)[1,1'-Biphenyl]-4-Yl}methyl)-1H-Benzimidazole-7-Carboxylic Acid",
      "2-Ethoxy-1-{[2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Yl]methyl}-1H-Benzimidazole-7-Carboxylic Acid",
      "2-Ethoxy-1-{[2'-(1H-Tetrazol-5-Yl)Biphenyl-4ethyl}-1H-Benzimidazole-7-Carboxylic Acid",
      "2-Ethoxy-7-Carboxy-1-(2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Yl)Methylbenzimidazole",
      "[3H]candesartan",
    ],
  },
  {
    primary_id: "D0D5TN",
    names: [
      "TT-034",
      "BLT-HCV",
      "OBP-701",
      "RNAi Therapy (Hepatitis C Virus Infection)",
      "TT-033",
      "TT-033i",
      "DdRNAi Therapy (HCV), Benitec",
      "RNAi Therapy (HCV), Benitec",
      "RNAi Therapy (Hepatitis C Virus Infection), Tacere/ Pfizer",
    ],
  },
  {
    primary_id: "D0D5UI",
    names: ["Exenatide Suspension"],
  },
  {
    primary_id: "D0D5VD",
    names: ["C[L-Phe-D-Pro-L-Tyr-D-Trp]", "CHEMBL486388"],
  },
  {
    primary_id: "D0D5VJ",
    names: ["UK-432097", "Adenosine A2a Agonist (Asthma), Pfizer"],
  },
  {
    primary_id: "D0D5XC",
    names: ["Sodium Trithiocarbonate"],
  },
  {
    primary_id: "D0D5XV",
    names: ["APX-E", "AXP-A0", "AXP-D", "CCR3 Antagonists (Asthma)", "CCR3 Antagonists (Asthma), Axikin"],
  },
  {
    primary_id: "D0D5XX",
    names: [
      "Peptide 4",
      "CHEMBL263633",
      "GTPL8582",
      "BDBM50376756",
      "Peptide 4 [PMID: 18294843]",
      "(2S)-2-[[(2S)-2-[2-[[(2S)-2-[[(2S)-2-Amino-5-(Diaminomethylideneamino)Pentanoyl]amino]propanoyl]amino]prop-2-Enoylamino]-3-Methylbutanoyl]amino]propanoic Acid",
    ],
  },
  {
    primary_id: "D0D5YE",
    names: ["Imidazo Quinoline Derivative 1"],
  },
  {
    primary_id: "D0D5YG",
    names: ["PMID27414413-Compound-Figure8right"],
  },
  {
    primary_id: "D0D5YK",
    names: ["Urea And Carbamate Bioisostere Derivative 16"],
  },
  {
    primary_id: "D0D5YP",
    names: [
      "BERGAPTOL",
      "Bergaptol",
      "486-60-2",
      "5-Hydroxypsoralen",
      "4-Hydroxy-7H-Furo[3,2-G]chromen-7-One",
      "5-Hydroxyfuranocoumarin",
      "Psoralin, 5-Hydroxy-",
      "UNII-KTC8ANI30F",
      "4-Hydroxybergapten",
      "7H-Furo[3,2-G][1]benzopyran-7-One, 4-Hydroxy-",
      "NSC341958",
      "KTC8ANI30F",
      "4-Oxidanylfuro[3,2-G]chromen-7-One",
      "NSC 341958",
      "5-Hydroxy-6,7-Furanocoumarin",
      "CHEMBL242711",
      "CHEBI:17377",
      "GIJHDGJRTUSBJR-UHFFFAOYSA-N",
      "4-Hydroxyfuro[3,2-G]chromen-7-One",
      "AK158894",
      "Bergaptols",
      "5-Hydroxy-Psoralen",
      "5-Hydroxyfurocoumarin",
      "AC1NQWYH",
      "5-HYDROXY-PSORALIN",
      "7H-Furo[3, 4-Hydroxy-",
      "BSPBio_002390",
    ],
  },
  {
    primary_id: "D0D5ZJ",
    names: [
      "Momelotinib",
      "Cyt387",
      "1056634-68-4",
      "MOMELOTINIB",
      "Cyt-387",
      "N-(Cyanomethyl)-4-(2-((4-Morpholinophenyl)Amino)Pyrimidin-4-Yl)Benzamide",
      "CYT 387",
      "CYT 11387",
      "UNII-6O01GMS00P",
      "N-(Cyanomethyl)-4-(2-(4-Morpholinophenylamino)Pyrimidin-4-Yl)Benzamide",
      "6O01GMS00P",
      "CHEMBL1078178",
      "AK102858",
      "N-(Cyanomethyl)-4-(2-((4-(4-Morpholinyl)Phenyl)Amino)-4-Pyrimidinyl)Benzamide",
      "N-(Cyanomethyl)-4-(2-{[4-(Morpholin-4-Yl)Phenyl]amino}pyrimidin-4-Yl)Benzamide",
      "CYT387 Sulfate Salt",
      "N-(Cyanomethyl)-4-(2-((4-Morpholinophenyl)-Amino)Pyrimidin-4-Yl)Benzamide",
    ],
  },
  {
    primary_id: "D0D5ZL",
    names: ["PAULLONE"],
  },
  {
    primary_id: "D0D5ZR",
    names: ["TRO-40303"],
  },
  {
    primary_id: "D0D5ZU",
    names: ["QPI-1002"],
  },
  {
    primary_id: "D0D5ZV",
    names: ["CMI-392"],
  },
  {
    primary_id: "D0D5ZX",
    names: ["RG-12915"],
  },
  {
    primary_id: "D0D6AC",
    names: ["Cebutolol"],
  },
  {
    primary_id: "D0D6BH",
    names: [
      "4,5-Dibenzylbenzene-1,2-Diol",
      "4,5-Dibenzyl-1,2-Benzenediol",
      "NSC664154",
      "AC1L8EAW",
      "CHEMBL481409",
      "AC1Q79U2",
      "SCHEMBL18604567",
      "CTK7J8443",
      "BDBM34138",
      "4,5-Dibenzylbenzene-1,2-Diol, 9",
      "NSC-664154",
    ],
  },
  {
    primary_id: "D0D6BT",
    names: ["Parathyroid Hormone Analog"],
  },
  {
    primary_id: "D0D6DB",
    names: ["Phytosel"],
  },
  {
    primary_id: "D0D6DE",
    names: ["PMID25553724-Compound-US20138552206 4"],
  },
  {
    primary_id: "D0D6DH",
    names: [
      "A-432411",
      "43A",
      "(3Z)-6-(4-HYDROXY-3-METHOXYPHENYL)-3-(1H-PYRROL-2-YLMETHYLENE)-1,3-DIHYDRO-2H-INDOL-2-ONE",
      "2ayp",
      "Indolinone Analog 13",
      "CHEMBL202930",
      "SCHEMBL6674605",
      "BDBM14649",
      "DB07078",
      "29690-EP2311842A2",
      "29690-EP2289868A1",
      "29690-EP2289894A2",
      "(3Z)-6-(4-Hydroxy-3-Methoxyphenyl)-3-(1H-Pyrrol-2-Ylmethylidene)-1H-Indol-2-One",
      "(3Z)-6-(4-Hydroxy-3-Methoxyphenyl)-3-(1H-Pyrrol-2-Ylmethylidene)-2,3-Dihydro-1H-Indol-2-One",
    ],
  },
  {
    primary_id: "D0D6DN",
    names: ["Aminopyridine Derivative 3"],
  },
  {
    primary_id: "D0D6EK",
    names: ["RDX-002"],
  },
  {
    primary_id: "D0D6EW",
    names: ["Glypromate"],
  },
  {
    primary_id: "D0D6FC",
    names: ["N-(4-(2-Aminoethyl)Phenyl)Benzenesulfonamide"],
  },
  {
    primary_id: "D0D6FQ",
    names: ["CGP 36742", "CGP-36742", "CGP36742"],
  },
  {
    primary_id: "D0D6FR",
    names: [
      "2-Amino-6-(Naphthalene-2-Sulfonyl)-Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3ff",
      "AC1NS2PK",
      "CHEMBL53043",
      "BDBM1778",
      "ZINC12354040",
      "2-(2-Naphthylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-Naphthalen-2-Ylsulfonylbenzonitrile",
    ],
  },
  {
    primary_id: "D0D6GI",
    names: [
      "1-(3-CHLORO-4-FLUOROPHENYL)-2,2,2-TRIFLUORO-1-(1-(4-FLUOROPHENYL)-1H-INDAZOL-5-YL)ETHANOL (ENANTIOMERIC MIX)",
      "CHEMBL1094231",
      "SCHEMBL4285350",
      "BDBM50316561",
      "1-(3-Chloro-4-Fluorophenyl)-2,2,2-Trifluoro-1-(1-(4-Fluorophenyl)-1H-Indazol-5-Yl)Ethanol",
    ],
  },
  {
    primary_id: "D0D6GT",
    names: ["Iota-Carrageenan"],
  },
  {
    primary_id: "D0D6HB",
    names: [
      "Acetic",
      "10273-84-4",
      "BVPQUFPFMVPKTA-UHFFFAOYSA-N",
      "[10-(Acetyloxymethyl)Anthracen-9-Yl]methyl Acetate",
      "AC1LD6YM",
      "(10-[(Acetyloxy)Methyl]-9-Anthryl)Methyl Acetate",
      "BIDD:GT0241",
      "SCHEMBL4647196",
      "CTK4A1441",
      "DTXSID50346546",
      "ZINC393157",
      "9,10-Bis(Acetoxymethyl)Anthracene",
      "9,10-Anthracenedimethanol, Diacetate",
      "AKOS024331822",
      "MCULE-6648864211",
      "9,10-Anthracenedimethanol,9,10-Diacetate",
      "(10-[(Acetyloxy)Methyl]-9-Anthryl)Methyl Acetate #",
      "ACETIC ACID 10-ACETOXYMETHYL-ANTHRACEN-9-YLMETHYL ESTER",
    ],
  },
  {
    primary_id: "D0D6HI",
    names: ["4-(2,4-Diethyl-1H-Pyrrol-3-Yloxy)Benzonitrile", "CHEMBL1084705"],
  },
  {
    primary_id: "D0D6HS",
    names: ["Mannoside Derivative 12"],
  },
  {
    primary_id: "D0D6IK",
    names: [
      "N-(3-(3-Methoxyphenyl)Propyl)Propionamide",
      "CHEMBL297947",
      "N-[3-(3-Methoxyphenyl)Propyl]propionamide",
      "SCHEMBL8928118",
      "BDBM50049554",
      "ZINC13755749",
    ],
  },
  {
    primary_id: "D0D6IU",
    names: [
      "2-(1H-Indol-3-Yl)-2-Oxo-N-Phenethyl-Acetamide",
      "USGVPHMVQJFJJD-UHFFFAOYSA-N",
      "2-(1H-Indol-3-Yl)-2-Oxo-N-(2-Phenylethyl)Acetamide",
      "2-(1H-Indol-3-Yl)-2-Oxo-N-[2-Phenylethyl]acetamide",
      "AC1M2UTB",
      "MLS001212145",
      "CHEMBL68442",
      "SCHEMBL4760802",
      "MolPort-000-249-097",
      "HMS3430C11",
      "ZINC2815535",
      "STK710826",
      "AKOS000530675",
      "MCULE-7691246998",
      "94209-12-8",
      "BAS 04280191",
      "SMR000518992",
      "ST4091858",
      "MLS-0351990.0002",
      "2-(1H-Indol-3-Yl)-2-Oxo-N-Phenethylacetamide",
      "2-Indol-3-Yl-2-Oxo-N-(2-Phenylethyl)Acetamide",
      "Acetamide, 2-(1H-Indol-3-Yl)-2-Oxo-N-Phenethyl-",
    ],
  },
  {
    primary_id: "D0D6KW",
    names: ["ASIC3 Inhibitors", "ASIC3 Inhibitors (Pain)", "ASIC3 Inhibitors (Pain), Merck & Co"],
  },
  {
    primary_id: "D0D6KZ",
    names: ["KNT-62", "CHEMBL594654"],
  },
  {
    primary_id: "D0D6LF",
    names: ["PD-156"],
  },
  {
    primary_id: "D0D6LH",
    names: ["SN6"],
  },
  {
    primary_id: "D0D6MC",
    names: ["HC-030031"],
  },
  {
    primary_id: "D0D6MH",
    names: ["GW-2580"],
  },
  {
    primary_id: "D0D6MO",
    names: ["Pen(Acm)AQFRKEKETFC(Acm)-OH", "CHEMBL412929"],
  },
  {
    primary_id: "D0D6MV",
    names: ["PPI-668"],
  },
  {
    primary_id: "D0D6MZ",
    names: [
      "3-Amino-4-Oxybenzyl-2-Butanone",
      "3-AMINO-4-OXYBENZYL-2-BUTANONE",
      "AC1NRD4M",
      "SCHEMBL15172323",
      "CHEMBL1235900",
      "DB02148",
    ],
  },
  {
    primary_id: "D0D6NQ",
    names: ["HEV-239"],
  },
  {
    primary_id: "D0D6OA",
    names: ["DM6S", "CHEMBL385448"],
  },
  {
    primary_id: "D0D6OH",
    names: [
      "[3H]CP55940",
      "5-(1,1-Dimethylheptyl)-2-[5-Hydroxy-2-(3-Hydroxypropyl)Cyclohexyl]phenol",
      "CP55940",
      "CP-55940",
      "UNII-KFY70972J5",
      "CP55,940",
      "CP 55940",
      "CP 55,940",
      "KFY70972J5",
      "2-[(1r,2r,5r)-5-Hydroxy-2-(3-Hydroxypropyl)Cyclohexyl]-5-(2-Methyloctan-2-Yl)Phenol",
      "83002-04-4",
      "CP-55,940",
      "83003-12-7",
      "C24H40O3",
      "3-(2-Hydroxy-4-(1,1-Dimethylheptyl)Phenyl)-4-(3-Hydroxypropyl)Cyclohexanol",
      "Phenol, 5-(1,1-Dimethylheptyl)-2-((1R,2R,5R)-5-Hydroxy-2-(3-Hydroxypropyl)Cyclohexyl)-, Rel-",
    ],
  },
  {
    primary_id: "D0D6OM",
    names: ["Alkyl Mannoside Derivative 7"],
  },
  {
    primary_id: "D0D6OO",
    names: ["NP-500"],
  },
  {
    primary_id: "D0D6OR",
    names: ["(+/-)-2-(4'-Benzyloxyphenyl)Thiomorpholine", "CHEMBL597373", "BDBM50307362"],
  },
  {
    primary_id: "D0D6PC",
    names: [
      "Azidopine",
      "90523-31-2",
      "AC1LCUW1",
      "NSC617999",
      "CHEBI:2952",
      "CHEMBL1993397",
      "NSC-617999",
      "O5-[2-[(4-Azidobenzoyl)Amino]ethyl] O3-Ethyl 2,6-Dimethyl-4-[2-(Trifluoromethyl)Phenyl]-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "GTPL6980",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(2-(Trifluoromethyl)Phenyl)-, 2-((4-Azidobenzoyl)Amino)Ethyl Ethyl Ester",
      "NCI60_005410",
      "C11282",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-[2-(Trifluoromethyl)Phenyl]-2-[(4-Azidobenzoyl)Amino]ethyl Ethyl Ester (9CI)",
    ],
  },
  {
    primary_id: "D0D6PG",
    names: ["MBS-101", "Bismuth Thiol Based Anti-Infective Agent (Topical, Acute Wound Infection), Microbion"],
  },
  {
    primary_id: "D0D6PP",
    names: [
      "Vaccinia Virus Therapy",
      "Bladder Cancer Vaccine, Thomas Jefferson University",
      "Bladder Tumor Vaccine, Thomas Jefferson University",
      "Melanoma Vaccine, Thomas Jefferson University",
      "Vaccinia Virus Therapy, Thomas Jefferson",
      "Vaccinia/GM-CSF Recombinant, Thomas Jefferson",
    ],
  },
  {
    primary_id: "D0D6QH",
    names: ["Sci-DaPT-Vac", "DaPT Vaccine, Scitech Genetics"],
  },
  {
    primary_id: "D0D6QI",
    names: ["3-Chloro-N-(2-Methyl-1H-Indol-5-Yl)Benzamide", "CHEMBL1253604", "BDBM50326843"],
  },
  {
    primary_id: "D0D6QU",
    names: ["ADCON-L", "ADCON-L Adhesion Control In A Barrier Gel", "GL-402", "GT-1587"],
  },
  {
    primary_id: "D0D6QX",
    names: ["AD-P53 Gene Therapy"],
  },
  {
    primary_id: "D0D6SZ",
    names: ["TROXACITABINE"],
  },
  {
    primary_id: "D0D6TB",
    names: ["1-(Oxazol-2-Yl)-4-(Piperidin-4-Yl)Butan-1-One", "CHEMBL270404"],
  },
  {
    primary_id: "D0D6TN",
    names: ["CR-8020"],
  },
  {
    primary_id: "D0D6UC",
    names: [
      "(Z)-2'-Des-Methyl Sulindac Sulfide",
      "CHEMBL561959",
      "SCHEMBL2697270",
      "BDBM50293599",
      "ZINC43075511",
      "(Z)-2''-Des-Methyl Sulindac Sulfide",
    ],
  },
  {
    primary_id: "D0D6UF",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 21"],
  },
  {
    primary_id: "D0D6UL",
    names: [
      "AL5424",
      "3,4-Dihydro-4-Hydroxy-2-(4-Methoxyphenyl)-2h-Thieno[3,2-E]-1,2-Thiazine-6-Sulfonamide-1,1-Dioxide",
      "AC1L1BUI",
      "SCHEMBL678716",
      "CTK7A4444",
      "BDBM11933",
      "4-Hydroxy-2-(4-Methoxyphenyl)-1,1-Dioxo-3,4-Dihydrothieno[3,2-E]thiazine-6-Sulfonamide",
    ],
  },
  {
    primary_id: "D0D6UR",
    names: ["2-Oxo-2H-Thiochromene-3-Carboxylic Acid", "CHEMBL571519", "66253-08-5", "SCHEMBL7679158"],
  },
  {
    primary_id: "D0D6UT",
    names: ["Azaindole Derivative 3"],
  },
  {
    primary_id: "D0D6VE",
    names: ["Pegvisomant"],
  },
  {
    primary_id: "D0D6WF",
    names: ["NP-103"],
  },
  {
    primary_id: "D0D6XD",
    names: ["MNX-200"],
  },
  {
    primary_id: "D0D6XH",
    names: [
      "LY-203725",
      "DAVLB-Hydrazide,covalent Conjugate With Monoclonal Antibody KS1/4",
      "KS1/4-4-Desacetylvinblastine-3-Carboxyhydrazide",
    ],
  },
  {
    primary_id: "D0D6XS",
    names: ["LD-02GIFRO"],
  },
  {
    primary_id: "D0D6XT",
    names: ["H3B-6527"],
  },
  {
    primary_id: "D0D6XV",
    names: ["TEI-9647"],
  },
  {
    primary_id: "D0D6YO",
    names: ["ISIS 188778"],
  },
  {
    primary_id: "D0D6ZB",
    names: ["AX-9657"],
  },
  {
    primary_id: "D0D6ZK",
    names: ["15(S)-Hydroxyeicosatetraenoic Acid (Ophthalmic, Dry Eye), Alcon"],
  },
  {
    primary_id: "D0D6ZL",
    names: ["HMPL-002"],
  },
  {
    primary_id: "D0D6ZW",
    names: ["BMS-986020"],
  },
  {
    primary_id: "D0D7AI",
    names: ["PMID26004420-Compound-WO2013028474B"],
  },
  {
    primary_id: "D0D7AJ",
    names: ["Pyrazoline Derivative 11"],
  },
  {
    primary_id: "D0D7AM",
    names: ["MS-1819"],
  },
  {
    primary_id: "D0D7AS",
    names: ["(E)-(Thiophen-2-Ylmethylidene)Amino Benzoate", "CHEMBL362066", "SCHEMBL13431312"],
  },
  {
    primary_id: "D0D7BX",
    names: ["Rivoceranib"],
  },
  {
    primary_id: "D0D7CH",
    names: ["TAK-828"],
  },
  {
    primary_id: "D0D7CM",
    names: ["FR233623"],
  },
  {
    primary_id: "D0D7DC",
    names: ["2-Chloro-N-(3-(3-Chlorobenzamido)Phenyl)Benzamide", "CHEMBL1088311"],
  },
  {
    primary_id: "D0D7DH",
    names: ["Green Soap"],
  },
  {
    primary_id: "D0D7DU",
    names: ["VLTS-589", "Deltavasc", "Del-1 Gene Therapy, Valentis", "Gene Therapy (Del-1, PINC), Valentis"],
  },
  {
    primary_id: "D0D7EG",
    names: ["PMID28270010-Compound-Figure17-3"],
  },
  {
    primary_id: "D0D7EK",
    names: ["TPI-1361-17"],
  },
  {
    primary_id: "D0D7EL",
    names: [
      "CHR-3620",
      "Anti-Inflammatories, Chroma Therapeutics",
      "Kinase Inhibitors (Inflammation), Chroma Therapeutics",
    ],
  },
  {
    primary_id: "D0D7EN",
    names: ["Tiamenidine"],
  },
  {
    primary_id: "D0D7ES",
    names: ["1-(4-(Benzyloxy)Phenyl)-3-Hydroxyurea", "CHEMBL259768"],
  },
  {
    primary_id: "D0D7FB",
    names: ["L-5-(Bromovinyl)Deoxyuridine", "CHEMBL261850", "SCHEMBL4314668", "BDBM50375781"],
  },
  {
    primary_id: "D0D7FN",
    names: [
      "1-(4-Nitro-Phenyl)-2-Phenyl-Ethane-1,2-Dione",
      "4-Nitrobenzil",
      "1-(4-Nitrophenyl)-2-Phenylethane-1,2-Dione",
      "22711-24-6",
      "Benzil, 4-Nitro-",
      "P-Nitrobenzil",
      "Ethanedione, (4-Nitrophenyl)Phenyl-",
      "NSC139153",
      "Benzil-Based Compound, 27",
      "AC1Q5B1C",
      "AC1L5ZN0",
      "SCHEMBL1370034",
      "CHEMBL192589",
      "BDBM22749",
      "CTK4F0012",
      "4-Nitrobibenzyl-Alpha,beta-Dione",
      "DTXSID10300826",
      "GPDKREBNFFEDHW-UHFFFAOYSA-N",
      "MolPort-000-146-024",
      "ZX-AT021384",
      "ZINC2555760",
      "STK325617",
      "SBB101571",
      "AKOS004902243",
      "OR28786",
      "NSC-139153",
      "MCULE-2855880102",
      "ACM22711246",
      "KB-147450",
      "DB-045974",
      "FT-0605764",
      "ST51043016",
    ],
  },
  {
    primary_id: "D0D7FV",
    names: ["CD19/CD22 Chimeric Antigen Receptor T Cells"],
  },
  {
    primary_id: "D0D7FX",
    names: [
      "Rac-2-Amino-4-Phenylbutanoic Acid",
      "DL-Homophenylalanine",
      "1012-05-1",
      "2-Amino-4-Phenylbutanoic Acid",
      "H-Dl-Hophe-Oh",
      "2-Amino-4-Phenylbutyric Acid",
      "(-)-2-Amino-4-Phenylbutyric Acid",
      "H-DL-HOMOPHE-OH",
      "H-DL-HPH-OH",
      "2-Amino-4-Phenyl-Butyric Acid",
      "7636-28-4",
      "CHEMBL203873",
      "Benzylalanine",
      "JTTHKOPSMAVJFE-UHFFFAOYSA-N",
      "Benzenebutanoic Acid, Alpha-Amino-",
      "(RS)-2-AMINO-4-PHENYL-BUTYRIC ACID",
      "Dlhpa",
      "MFCD00063091",
      "D,l-Homophenylalanine",
      "ACMC-209tws",
      "AC1L2SZU",
      "DL-Homophenylalanine, 98%",
      "KSC492S1B",
      "SCHEMBL130567",
      "AC1Q5S74",
      "AC1Q50B0",
      "KS-00000FXF",
    ],
  },
  {
    primary_id: "D0D7GE",
    names: ["TAK-029"],
  },
  {
    primary_id: "D0D7GG",
    names: [
      "1-(1-Benzyl-2-Biphenyl-4-Yl-Ethyl)-1H-Imidazole",
      "CHEMBL491222",
      "AC1LAA5O",
      "BDBM50263812",
      "1-[1-Benzyl-2-(4-Phenylphenyl)Ethyl]imidazole",
      "1-[1-Phenyl-3-(4-Phenylphenyl)Propan-2-Yl]imidazole",
      "1H-Imidazole, 1-[2-[1,1'-Biphenyl]-4-Yl-1-(Phenylmethyl)Ethyl]-",
    ],
  },
  {
    primary_id: "D0D7GL",
    names: ["TROPOLONE"],
  },
  {
    primary_id: "D0D7GS",
    names: ["MultiStem"],
  },
  {
    primary_id: "D0D7HR",
    names: ["PMID18337095C27"],
  },
  {
    primary_id: "D0D7HU",
    names: [
      "7-Phenyl-1-(2H-Tetrazol-5-Yl)-Heptan-1-One",
      "CHEMBL456450",
      "SCHEMBL2161929",
      "ZINC40380109",
      "BDBM50274982",
    ],
  },
  {
    primary_id: "D0D7HZ",
    names: [
      "Para-Isopropylaniline",
      "4-ISOPROPYLANILINE",
      "99-88-7",
      "Cumidine",
      "4-Aminocumene",
      "P-Isopropylaniline",
      "P-Cumidine",
      "4-(Propan-2-Yl)Aniline",
      "4-Amino-1-Isopropylbenzene",
      "PARA-ISOPROPYLANILINE",
      "Cumene, P-Amino-",
      "Aniline, P-Isopropyl-",
      "Benzenamine, 4-(1-Methylethyl)-",
      "Cumidene",
      "UNII-0D54T7V7XL",
      "4-Propan-2-Ylaniline",
      "4-(2-Propyl)Aniline",
      "NSC 7198",
      "4-Isopropylaniline, 99%",
      "0D54T7V7XL",
      ".beta.-(4-Aminophenyl)Propane",
      "LRTFPLFDLJYEKT-UHFFFAOYSA-N",
      "4-(Methylethyl)Phenylamine",
      "Ss-(4-Aminophenyl)Propane",
      "Iso",
      "4-(1-Methylethyl)Aniline",
      "Cumidin",
      "Beta-(4-Aminophenyl)Propane",
    ],
  },
  {
    primary_id: "D0D7ID",
    names: ["N-Cyclohexyl-N'-Decylurea"],
  },
  {
    primary_id: "D0D7IQ",
    names: ["PMID18754614C4"],
  },
  {
    primary_id: "D0D7KC",
    names: [
      "Alvimopan",
      "Alvimopan Anhydrous",
      "LY 246736",
      "LY246736",
      "Adl 8-2698",
      "Entereg (TN)",
      "Trans-3,4-Dimethyl-4-(3-Hydroxyphenyl) Piperidine",
      "Glycine, N-[2-[[4-(3-Hydroxyphenyl)-3,4-Dimethyl-1-Piperidinyl]methyl]-1-Oxo-3-Phenylpropyl",
      "2-[[(2S)-2-Benzyl-3-[(3R,4R)-4-(3-Hydroxyphenyl)-3,4-Dimethylpiperidin-1-Yl]propanoyl]amino]acetic Acid",
    ],
  },
  {
    primary_id: "D0D7KE",
    names: ["4'-(4-Fluorobenzensulfonamide)-4-Hydroxychalcone", "CHEMBL1087448", "SCHEMBL3483870", "BDBM50316857"],
  },
  {
    primary_id: "D0D7KH",
    names: [
      "Azithromycin-N-Benzyltriazolyloctahydroxamic Acid",
      "SCHEMBL8976909",
      "CHEMBL455342",
      "BDBM27181",
      "Triazole-Linked Azithromycin-Based Compound, 16e",
    ],
  },
  {
    primary_id: "D0D7KR",
    names: ["CDD-0235-J"],
  },
  {
    primary_id: "D0D7KU",
    names: ["SNA-001"],
  },
  {
    primary_id: "D0D7LA",
    names: [
      "Metformin",
      "657-24-9",
      "1,1-Dimethylbiguanide",
      "N,N-Dimethylimidodicarbonimidic Diamide",
      "Metiguanide",
      "Dimethylbiguanide",
      "Glucophage",
      "Haurymelin",
      "Gliguanid",
      "Fluamine",
      "Glumetza",
      "Flumamine",
      "Melbin",
      "Diabex",
      "N,N-Dimethylbiguanide",
      "Metformina",
      "Metforminum",
      "Metformine",
      "Islotin",
      "Glifage",
      "Siofor",
      "N1,N1-Dimethylbiguanide",
      "DMGG",
      "NNDG",
      "Dimethyldiguanide",
      "N,N-Dimethyldiguanide",
      "Metformina [DCIT]",
      "Imidodicarbonimidic Diamide, N,N-Dimethyl-",
      "Metformina [Spanish]",
      "Metforminum [INN-Latin]",
      "Metformine [INN-French]",
      "Metformin [USAN:INN:BAN]",
      "1,1-Dimethyl",
      "Diabetosan",
      "Dimethylbiguanidine",
      "Dimethylguanylguanidine",
      "Glycon",
      "Diabex (TN)",
      "Diaformin (TN)",
      "Dianben (TN)",
      "Fortamet (TN)",
      "Gen-Metformin",
      "Glucophage (TN)",
      "Glumetza (TN)",
      "LA-6023",
      "Nu-Metformin",
      "Obimet (TN)",
      "Riomet (TN)",
      "Metformin (USAN/INN)",
      "1,1-Dimethyl Biguanide",
      "3-(Diaminomethylidene)-1,1-Dimethylguanidine",
      "[14C]metformin",
    ],
  },
  {
    primary_id: "D0D7LP",
    names: ["RQ-00201894"],
  },
  {
    primary_id: "D0D7MJ",
    names: ["CNSB-001"],
  },
  {
    primary_id: "D0D7MN",
    names: ["Naphthalen-1-Yl(10H-Phenothiazin-10-Yl)Methanone", "CHEMBL240899"],
  },
  {
    primary_id: "D0D7MP",
    names: ["(+/-)-Oxiran-2-Ylmethyl (9Z)-Octadec-9-Enoate"],
  },
  {
    primary_id: "D0D7MV",
    names: ["Quinoline Derivative 18"],
  },
  {
    primary_id: "D0D7MZ",
    names: ["UniGAM SD"],
  },
  {
    primary_id: "D0D7NA",
    names: ["ISIS 138647"],
  },
  {
    primary_id: "D0D7OE",
    names: ["RHAMLET"],
  },
  {
    primary_id: "D0D7OR",
    names: ["KM-233-M"],
  },
  {
    primary_id: "D0D7PF",
    names: [
      "3-((2-Pyrrolidine-1-Yl)-Ethyl)Uracil",
      "CHEMBL1275720",
      "3-((2-Pyrrolidin-1-Yl)Ethyl)Uracil",
      "1254329-95-7",
    ],
  },
  {
    primary_id: "D0D7PK",
    names: [
      "4-Methyl-Piperidin-(2E)-Ylideneamine",
      "CHEMBL6808",
      "2-Imino-4-Methylpiperidine",
      "2-PYRIDINAMINE, 3,4,5,6-TETRAHYDRO-4-METHYL-",
      "165383-71-1",
      "AC1MMWW1",
      "4-Methylpiperidin-2-Imine",
      "4-Methyl-3,4,5,6-Tetrahydropyridin-2-Amine",
      "4-Methyl-2-Piperidinimine",
      "SCHEMBL4103569",
      "CTK0A9019",
      "DTXSID00390857",
      "BDBM50062133",
      "4-Methyl-Piperidin-(2Z)-Ylideneamine",
      "AKOS006348873",
      "FT-0722141",
      "4-Methyl-2,3,4,5-Tetrahydropyridin-6-Amine",
    ],
  },
  {
    primary_id: "D0D7QH",
    names: ["Arvanil"],
  },
  {
    primary_id: "D0D7QO",
    names: ["Phenylephrine Hydrochloride Gel"],
  },
  {
    primary_id: "D0D7QY",
    names: [
      "BPL-004",
      "BPL-004 Series (Influenza)",
      "BPL-004 Series (Influenza), Bradford Pharma",
      "BPL-004 Series (Influenza),Redx Pharma",
    ],
  },
  {
    primary_id: "D0D7RE",
    names: ["BBI-8000"],
  },
  {
    primary_id: "D0D7SL",
    names: ["Pyridine Derivative 3"],
  },
  {
    primary_id: "D0D7TB",
    names: ["Opipramol"],
  },
  {
    primary_id: "D0D7TD",
    names: ["Cethromycin"],
  },
  {
    primary_id: "D0D7UA",
    names: ["3-(4-Phenoxy-Benzenesulfonyl)-Propane-1-Thiol", "CHEMBL301565", "SCHEMBL7208687", "BDBM50076589"],
  },
  {
    primary_id: "D0D7UD",
    names: ["AS-013", "Circulase", "Ecraprost", "Prostaglandin E1 (Liposomal), Mitsubishi Pharma/Asahi Glass"],
  },
  {
    primary_id: "D0D7VO",
    names: ["GK1-399"],
  },
  {
    primary_id: "D0D7VT",
    names: ["Therapy, Pre-Eclampsia, Aggamin"],
  },
  {
    primary_id: "D0D7WS",
    names: ["MTL-102"],
  },
  {
    primary_id: "D0D7XM",
    names: ["NC-4016"],
  },
  {
    primary_id: "D0D7XS",
    names: ["GPG-290"],
  },
  {
    primary_id: "D0D7XW",
    names: ["SINITRODIL"],
  },
  {
    primary_id: "D0D7YA",
    names: ["DSP-6952"],
  },
  {
    primary_id: "D0D7YF",
    names: ["NT-CC-017"],
  },
  {
    primary_id: "D0D7YL",
    names: ["3-(1,2-Dihydroacenaphthylen-3-Yl)Pyridine", "Acenaphthene Derivative A", "CHEMBL372747", "SCHEMBL4225082"],
  },
  {
    primary_id: "D0D7YR",
    names: ["AMPE4L"],
  },
  {
    primary_id: "D0D7YW",
    names: [
      "TC-5214",
      "Dexmecamylamine",
      "DEXMECAMYLAMINE",
      "UNII-5Y1141YI4U",
      "107538-05-6",
      "5Y1141YI4U",
      "TC 5214",
      "(1R,2S,4S)-N,2,3,3-Tetramethylbicyclo(2.2.1)Heptan-2-Amine",
      "Dexmecamylamine [USAN:INN]",
      "Dexmecamylamine (USAN)",
      "CHEMBL2103881",
      "BDBM50045047",
      "ZINC43763856",
      "DB11807",
      "D10111",
      "UNII-6EE945D3OK Component IMYZQPCYWPFTAG-NGZCFLSTSA-N",
      "Bicyclo(2.2.1)Heptan-2-Amine, N,2,3,3-Tetramethyl-, (1R,2S,4S)-",
    ],
  },
  {
    primary_id: "D0D7ZA",
    names: ["Ser-Tyr-Ser-Nle-Glu-His-Dphe-Arg"],
  },
  {
    primary_id: "D0D7ZL",
    names: [
      "(1H-Indol-2-Yl)(6-Methoxy-1H-Indol-2-Yl)Methanone",
      "CHEMBL7602",
      "Bis(1H-2-Indolyl)Methanone 12",
      "AC1NS9C2",
      "SCHEMBL6864570",
      "BDBM6574",
      "ZINC13472534",
      "6-Methoxy-[2,2'-Carbonylbis(1H-Indole)]",
      "2-(1H-Indol-2-Ylcarbonyl)-6-Methoxy-1H-Indole",
      "1H-Indol-2-Yl-(6-Methoxy-1H-Indol-2-Yl)Methanone",
    ],
  },
  {
    primary_id: "D0D7ZU",
    names: ["Pyrazole Derivative 23"],
  },
  {
    primary_id: "D0D8AK",
    names: ["Cyclotheonamide E", "CHEMBL507449"],
  },
  {
    primary_id: "D0D8AN",
    names: [
      "6-Desoxonaltrexone",
      "CHEMBL511816",
      "SCHEMBL3825815",
      "XBPQMXFKKJADMC-WOUAJJJCSA-N",
      "BDBM50278385",
      "3,14-Dihydroxy-17-Cyclopropylmethyl-4,5alpha-Epoxymorphinan",
    ],
  },
  {
    primary_id: "D0D8CE",
    names: ["EVP-0334"],
  },
  {
    primary_id: "D0D8CZ",
    names: ["DATP", "Deoxyadenosine Triphosphate"],
  },
  {
    primary_id: "D0D8DA",
    names: ["KAG-308"],
  },
  {
    primary_id: "D0D8DD",
    names: [
      "Sufentanil",
      "Chronogesic",
      "Sufentanilum",
      "Sufentanyl",
      "Sulfentanil",
      "Sulfentanyl",
      "R 30730",
      "R-30730",
      "Sufenta (TN)",
      "Sufentanilum [INN-Latin]",
      "Sufentil (TN)",
      "Sufentanil (USAN/INN)",
      "Sufentanil [USAN:BAN:INN]",
      "N-(4-(Methoxymethyl)-1-(2-(2-Thienyl)Ethyl)-4-Piperidyl)Propionanilide",
      "N-[4-(Methoxymethyl)-1-(2-Thiophen-2-Ylethyl)Piperidin-4-Yl]-N-Phenylpropanamide",
      "N-{4-(Methoxymethyl)-1-[2-(2-Thienyl)Ethyl]piperidin-4-Yl}-N-Phenylpropanamide",
      "N-{4-[(Methyloxy)Methyl]-1-[2-(2-Thienyl)Ethyl]piperidin-4-Yl}-N-Phenylpropanamide",
      "N-(4-(Methoxymethyl)-1-(2-(2-Thienyl)Ethyl)-4-Piperidinyl)-N-Phenylpropanamide",
    ],
  },
  {
    primary_id: "D0D8DV",
    names: ["PMID30247903-Compound-General Structure17"],
  },
  {
    primary_id: "D0D8DZ",
    names: ["H-Dmt-Tic-NH-CH[(CH2)4-NH-Z]-Bid"],
  },
  {
    primary_id: "D0D8EY",
    names: [
      "HLF 1-11",
      "Antimicrobial Peptides, AM-Pharma",
      "HLF(1-11)",
      "Antimicrobial Peptides (Bacterial Infections), AM-Pharma",
      "Antimicrobial Peptides (Fungal Infections), AM-Pharma",
      "HLF 1-11, AM-Pharma",
      "Human Lactoferrin 1-11, AM-Pharma",
      "Human Lactoferrin Peptides (Antibacterial), AM-Pharma",
    ],
  },
  {
    primary_id: "D0D8FE",
    names: ["Guttiferone I"],
  },
  {
    primary_id: "D0D8FN",
    names: ["LY-156735"],
  },
  {
    primary_id: "D0D8FW",
    names: ["PBMC"],
  },
  {
    primary_id: "D0D8FZ",
    names: ["2-Benzyl-N1-Hydroxy-N3-Phenethylmalonamide", "CHEMBL220434", "BDBM50206544"],
  },
  {
    primary_id: "D0D8GO",
    names: ["MLR-1045"],
  },
  {
    primary_id: "D0D8GU",
    names: [
      "4-[6-Methyl-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "CHEMBL371742",
      "BDBM8629",
      "AC1O706L",
      "(4-Pyridylmethylene)Indane 22a",
      "4-[(E)-(6-Methyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0D8GV",
    names: ["SNAP-7915"],
  },
  {
    primary_id: "D0D8IH",
    names: [
      "N-Phenyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL590537",
      "N-Phenyl-10H-Phenothiazine-10-Carboxamide",
      "AC1LFFQA",
      "Oprea1_595012",
      "Oprea1_517446",
      "MolPort-001-903-868",
      "ZINC267871",
      "N-Phenylphenothiazine-10-Carboxamide",
      "STK067361",
      "BDBM50308402",
      "10-(N'-Phenylcarbamoyl)Phenothiazine",
      "N-Phenylphenothiazin-10-Ylcarboxamide",
      "AKOS003441553",
      "MCULE-6952834566",
      "NCGC00280429-01",
      "BAS 01095156",
      "ST51030212",
      "Phenothiazine-10-Carboxylic Acid Phenylamide",
      "N-Phenyl-1''H-Phenothiazine-1''-Carboxamide",
    ],
  },
  {
    primary_id: "D0D8II",
    names: ["TMB-607"],
  },
  {
    primary_id: "D0D8IS",
    names: ["Glycochenodeoxycholic Acid"],
  },
  {
    primary_id: "D0D8IZ",
    names: ["PMID28350212-Compound-22"],
  },
  {
    primary_id: "D0D8JB",
    names: ["PF-05212384"],
  },
  {
    primary_id: "D0D8JU",
    names: ["2-Phenyl-1,1-Di(Pyridin-3-Yl)Ethanol", "CHEMBL1088805", "SCHEMBL4336663"],
  },
  {
    primary_id: "D0D8KI",
    names: [
      "Ad5-AC6",
      "Corgenic",
      "AC6 Gene Therapy, Collateral/UCSD",
      "Adenylyl Cyclase Gene Therapy, Collateral/UCSD",
      "CHF Gene Therapy, Collateral/UCSD",
      "Gene Therapy (CHF), Collateral/USCD",
      "Gene Therapy (Adenylate Cyclase), Collateral/USCD",
    ],
  },
  {
    primary_id: "D0D8KN",
    names: ["3-(1-Benzyl-1H-Imidazol-5-Yl)-1-Propanol", "CHEMBL610794"],
  },
  {
    primary_id: "D0D8KO",
    names: ["Thiazole Derivative 3"],
  },
  {
    primary_id: "D0D8KQ",
    names: ["Jakafi"],
  },
  {
    primary_id: "D0D8KU",
    names: ["Ferritarg P"],
  },
  {
    primary_id: "D0D8LK",
    names: ["Parvovirus B19 Vaccine"],
  },
  {
    primary_id: "D0D8LP",
    names: ["Phenylsulfonyl Derivative 3"],
  },
  {
    primary_id: "D0D8LQ",
    names: [
      "1-(2-Bromophenyl)-3-(4-Cyano-2-Hydroxyphenyl)Urea",
      "CHEMBL27863",
      "SCHEMBL3705632",
      "ZINC13559608",
      "BDBM50140796",
    ],
  },
  {
    primary_id: "D0D8MA",
    names: ["2-Chloro-N-(O-Tolylcarbamoyl)Benzamide", "CHEMBL1169825"],
  },
  {
    primary_id: "D0D8MD",
    names: ["ZM-230487"],
  },
  {
    primary_id: "D0D8MG",
    names: ["NOX-A12"],
  },
  {
    primary_id: "D0D8MH",
    names: ["9alpha,11beta-Prostaglandin F2alpha", "11-Epi-Prostaglandin F2alpha"],
  },
  {
    primary_id: "D0D8MK",
    names: ["PMID27774822-Compound-Figure10CompoundA"],
  },
  {
    primary_id: "D0D8MS",
    names: ["NSD-726"],
  },
  {
    primary_id: "D0D8NG",
    names: ["Sc Veltuzumab"],
  },
  {
    primary_id: "D0D8NM",
    names: ["2-(3-Carbamoylbenzyl)-5-Mercaptopentanoic Acid"],
  },
  {
    primary_id: "D0D8NX",
    names: ["Cyclopropyl-Spiro Piperidine Derivative 1"],
  },
  {
    primary_id: "D0D8OR",
    names: [
      "3-Cyanophenylboronic Acid",
      "150255-96-2",
      "(3-Cyanophenyl)Boronic Acid",
      "3-Cyanobenzeneboronic Acid",
      "3-Boronobenzonitrile",
      "3-(Dihydroxyboranyl)Benzonitrile",
      "3-Cyanophenyl Boronic Acid",
      "3-Cyanobenzene Boronic Acid",
      "3-Cyano-Phenyl-Boronic Acid",
      "Boronic Acid, B-(3-Cyanophenyl)-",
      "MFCD01318967",
      "BORONIC ACID, (3-CYANOPHENYL)-",
      "PubChem1804",
      "Phenylboronic Acid, 4",
      "M-Cyanophenyl Boronic Acid",
      "3-Cyano-Phenylboronic Acid",
      "3-Cyano Phenylboronic Acid",
      "3-Cyanophenyl-Boronic Acid",
      "3-Cyanophenylboronic  Acid",
      "3-Cyanophenylboronic Acid,",
    ],
  },
  {
    primary_id: "D0D8OY",
    names: [
      "AP-24226",
      "UNII-53D46B0TIH",
      "AP24226",
      "926922-22-7",
      "53D46B0TIH",
      "CHEMBL556874",
      "SCHEMBL4135035",
      "SCHEMBL4135027",
      "ZINC43058954",
      "BDBM50294010",
      "KB-74711",
      "Benzamide, 3-((1E)-2-(6-(Cyclopropylamino)-9H-Purin-9-Yl)Ethenyl)-4-Methyl-N-(4-(Trifluoromethyl)-2-Pyridinyl)-",
      "3-(2-(6-(Cyclopropylamino)-9H-Purin-9-Yl)Vinyl)-4-Methyl-N-(4-(Trifluoromethyl)Pyridin-2-Yl)Benzamide",
    ],
  },
  {
    primary_id: "D0D8PF",
    names: ["Thiazole Carboxamide Derivative 16"],
  },
  {
    primary_id: "D0D8PZ",
    names: ["Tetrahydrocannabivarin", "Delta9-Tetrahydrocannabivarin"],
  },
  {
    primary_id: "D0D8QN",
    names: ["ARN34"],
  },
  {
    primary_id: "D0D8QO",
    names: ["Kuwanon J", "CHEMBL570475"],
  },
  {
    primary_id: "D0D8QV",
    names: ["AXS-02"],
  },
  {
    primary_id: "D0D8QY",
    names: [
      "6-(4-Bromo-Phenylamino)-1H-Pyrimidine-2,4-Dione",
      "CHEMBL293964",
      "21333-03-9",
      "NSC212333",
      "Cambridge Id 5630405",
      "AC1L7G1A",
      "Oprea1_831727",
      "Oprea1_666298",
      "DTXSID70309572",
      "2,4(1H,3H)-Pyrimidinedione, 6-[(4-Bromophenyl)Amino]-",
      "MolPort-001-898-229",
      "ZINC245008",
      "BDBM50028349",
      "AKOS000541604",
      "MCULE-6634356270",
      "NSC-212333",
      "BAS 00450423",
      "6-(4-Bromoanilino)-1H-Pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0D8RR",
    names: ["Quinoline Carboxamide Derivative 3"],
  },
  {
    primary_id: "D0D8RT",
    names: ["Quattro"],
  },
  {
    primary_id: "D0D8SN",
    names: ["ALD-401", "ALD-401, Aldagen", "ALDH-Expressing Stem Cell Therapy (Ischemic Stroke), Aldagen"],
  },
  {
    primary_id: "D0D8TE",
    names: [
      "FBS-0701",
      "Desferrithiocin Analog (Oral, Iron Overload), FerroKin Biosciences",
      "Iron Chelator (Oral, Iron Overload), FerroKin Biosciences",
    ],
  },
  {
    primary_id: "D0D8UH",
    names: ["ABIO-08-01", "BTG-1640"],
  },
  {
    primary_id: "D0D8UM",
    names: ["Afabicin"],
  },
  {
    primary_id: "D0D8VE",
    names: [
      "Halothane",
      "Alotano",
      "Anestan",
      "Bromchlortrifluoraethanum",
      "Bromochlorotrifluoroethane",
      "Chalothane",
      "Fluktan",
      "Fluorotane",
      "Fluothane",
      "Ftorotan",
      "Ftuorotan",
      "Halan",
      "Halotan",
      "Halotano",
      "Halothan",
      "Halothanum",
      "Halsan",
      "Narcotan",
      "Narcotane",
      "Narkotan",
      "Phthorothanum",
      "Rhodialothan",
      "Alotano [DCIT]",
      "Ftorotan [Russian]",
      "CF3CHClBr",
      "Freon 123B1",
      "Fluothane (TN)",
      "Halotano [INN-Spanish]",
      "Halothane [Anaesthetics, Volatile]",
      "Halothanum [INN-Latin]",
      "Narcotann NE-Spofa [Russian]",
      "Narcotann Ne-Spofa",
      "Halothane [INN:BAN:JAN]",
      "Halothane (JP15/USP/INN)",
      "(+-)-2-Bromo-2-Chloro-1,1,1-Trifluoroethane",
      "1,1,1-Trifluoro-2-Bromo-2-Chloroethane",
      "1,1,1-Trifluoro-2-Chloro-2-Bromoethane",
      "1-Bromo-1-Chloro-2,2,2-Trifluoroethane",
      "2,2,2-Trifluoro-1-Chloro-1-Bromoethane",
      "2-BROMO-2-CHLORO-1,1,1-TRIFLUOROETHANE",
      "2-Brom-2-Chlor-1,1,1-Trifluorethan",
    ],
  },
  {
    primary_id: "D0D8VU",
    names: ["LGD-6972"],
  },
  {
    primary_id: "D0D8WN",
    names: [
      "(9-Methyl-9H-Purin-6-Yl)-Phenyl-Amine",
      "CHEMBL321749",
      "9H-Purin-6-Amine, 9-Methyl-N-Phenyl-",
      "SCHEMBL6660171",
      "ZINC4055",
      "6-Phenylamino-9-Methyl-9H-Purine",
      "BDBM50009703",
      "84602-82-4",
    ],
  },
  {
    primary_id: "D0D8WQ",
    names: [
      "2,4-Deoxy-4-Guanidino-5-N-Acetyl-Neuraminic Acid",
      "2,4-DEOXY-4-GUANIDINO-5-N-ACETYL-NEURAMINIC ACID",
      "AC1NRBJW",
      "4-Guanidino-2,4-Dideoxy-N-Acetylneuraminic Acid",
      "5-(Acetylamino)-2,6-Anhydro-4-Carbamimidamido-3,4,5-Trideoxy-D-Erythro-L-Manno-Nononic Acid",
      "(4S,5R,6R)-5-Acetamido-4-(Diaminomethylideneamino)-6-[(1R,2R)-1,2,3-Trihydroxypropyl]oxane-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0D8XF",
    names: ["Vanillylmandelic Acid"],
  },
  {
    primary_id: "D0D8XG",
    names: ["AeroLEF"],
  },
  {
    primary_id: "D0D8XY",
    names: [
      "ATOSIBAN",
      "Atosiban",
      "Tractocile",
      "90779-69-4",
      "Atosiban Acetate",
      "Antocin",
      "UNII-081D12SI0Z",
      "Rwj 22164",
      "Orf-22164",
      "RWJ-22164",
      "CHEMBL382301",
      "1-Deamino-2D-Tyr-(OEt)-4-Thr-8-Orn-Oxytocin",
      "DTVT",
      "081D12SI0Z",
      "1-Deamino-2-D-Tyr-(O-Ethyl)-4-Thr-8-Ornoxytocin",
      "1-(3-Mercaptopropanoic Acid)-2-(O-Ethyl-D-Tyrosine)-4-L-Threonine-8-L-Ornithineoxytocin",
      "NCGC00165718-01",
      "Atosibanum [INN-Latin]",
      "ORF 22164",
      "DeTVT",
      "Atosibanum",
      "Antocin II",
      "Atosiban [USAN:INN:BAN]",
      "RWJ22164",
      "1-(3-Mercaptopropionic Acid)-2-(3-(P-Ethoxyphenyl)-D-Alanine)-4-L-Threonine-8-L-Ornithineox",
    ],
  },
  {
    primary_id: "D0D8YE",
    names: ["3-(5-Methoxy-1H-Inden-2-Yl)Pyridine", "Indene 3", "AC1O70H7", "CHEMBL206300", "BDBM8890"],
  },
  {
    primary_id: "D0D8YG",
    names: ["3-(6-Amino-Purin-9-Yl)-4-Phenethyloxy-Butan-2-Ol", "CHEMBL606664"],
  },
  {
    primary_id: "D0D8YP",
    names: [
      "1-Benzyl-4-Methyl-Piperazine",
      "1-Benzyl-4-Methylpiperazine",
      "62226-74-8",
      "CHEMBL363603",
      "Piperazine, 1-Methyl-4-(Phenylmethyl)-",
      "4-Benzyl-1-Methylpiperazine",
      "1-Benzyl-4-Methylpiperazinehydrochloride",
      "PubChem10987",
      "AC1LG1B6",
      "Cambridge Id 5263722",
      "N-Benzyl-N'-Methylpiperazine",
      "SCHEMBL221139",
      "Methyl Piperazine, Polymer-Bound",
      "CTK2C4522",
      "DTXSID90354100",
      "Methyl Piperazinomethyl Polystyrene",
      "MolPort-000-882-836",
      "MLJOKPBESJWYGL-UHFFFAOYSA-N",
      "HMS1579O15",
      "BCP22253",
      "MFCD00976530",
      "ZINC19536830",
      "STK115879",
      "BDBM50174044",
    ],
  },
  {
    primary_id: "D0D8YY",
    names: ["N-Benzyl-N-Isobutylpiperidin-4-Amine", "CHEMBL207837", "SCHEMBL5994946", "ZINC36093165"],
  },
  {
    primary_id: "D0D8ZB",
    names: ["Ioxithalamate"],
  },
  {
    primary_id: "D0D8ZK",
    names: ["OBP-301"],
  },
  {
    primary_id: "D0D8ZN",
    names: [
      "BAS-01373578",
      "CHEMBL206575",
      "(4Z)-4-[[5-(3-Chloro-4-Methylphenyl)Furan-2-Yl]methylidene]-1-(3,4-Dimethylphenyl)Pyrazolidine-3,5-Dione",
      "(4Z)-4-{[5-(3-Chloro-4-Methylphenyl)Furan-2-Yl]methylidene}-1-(3,4-Dimethylphenyl)Pyrazolidine-3,5-Dione",
      "AC1LWF8S",
      "MolPort-030-043-249",
      "MolPort-001-963-180",
      "ZINC4471294",
      "STL387426",
      "STL383260",
      "BDBM50187574",
      "AKOS025249675",
      "AKOS000447221",
      "BAS 01373578",
      "4-((5-(3-Chloro-4-Methylphenyl)Furan-2-Yl)Methylene)-1-(3,4-Dimethylphenyl)Pyrazolidine-3,5-Dione",
    ],
  },
  {
    primary_id: "D0D8ZU",
    names: ["AX-205"],
  },
  {
    primary_id: "D0D9AA",
    names: ["NF449"],
  },
  {
    primary_id: "D0D9AD",
    names: ["YM-750", "ACAT Inhibitors, Yamanouchi"],
  },
  {
    primary_id: "D0D9AH",
    names: ["VX-702"],
  },
  {
    primary_id: "D0D9AL",
    names: ["ATON-005"],
  },
  {
    primary_id: "D0D9AS",
    names: ["Angiotensin III", "Ang III"],
  },
  {
    primary_id: "D0D9BB",
    names: ["1-Benzhydryl-4-(Pyridin-2-Yl)Piperidin-4-Ol", "CHEMBL393971"],
  },
  {
    primary_id: "D0D9BL",
    names: ["Anti-CD19 CAR-T Cells"],
  },
  {
    primary_id: "D0D9CK",
    names: ["PMID27019002-Compound-45"],
  },
  {
    primary_id: "D0D9CO",
    names: ["SB-221420-A"],
  },
  {
    primary_id: "D0D9DP",
    names: [
      "CRF-1 Antagonists",
      "CRF-1 Antagonists (Depression/Anxiety)",
      "CRF-1 Antagonists (Depression/Anxiety), Takeda",
      "Corticotropin-Releasing Factor-1 Antagonists (Depression/Anxiety), Takeda",
    ],
  },
  {
    primary_id: "D0D9ES",
    names: ["2-(2-((Benzylamino)Methyl)Phenoxy)-5-Chlorophenol"],
  },
  {
    primary_id: "D0D9FA",
    names: ["(2R,3S)-2-[(4-Iodophenoxy)Phenylmethyl]morpholine", "CHEMBL579221"],
  },
  {
    primary_id: "D0D9FR",
    names: [
      "4-[5-Bromo-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "CHEMBL179222",
      "BDBM8621",
      "(4-Pyridylmethylene)Indane 10b",
      "AC1O7065",
      "4-[(Z)-(5-Bromo-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0D9FV",
    names: [
      "Orphenadrine",
      "Antiflex",
      "Biorphen",
      "Brocadisipal",
      "Brocasipal",
      "Disipal",
      "Lysantin",
      "Mefenamine",
      "Mephenamine",
      "Methyldiphenylhydramine",
      "Myolin",
      "Myotrol",
      "ORP",
      "Orfenadrina",
      "Orfro",
      "Orphenadine",
      "Orphenadinum",
      "Orphenadrin",
      "Orphenadrinum",
      "Orphenate",
      "Orphenedrine",
      "Norflex Orphenadrine Citrate",
      "Sodium Mefenamine",
      "WS 2434",
      "Banflex (TN)",
      "Biorphen (TN)",
      "Brocasipal (TN)",
      "Disipal (TN)",
      "Flexon (TN)",
      "Mephenamin (TN)",
      "Mialgin (TN)",
      "Norflex (TN)",
      "O-Methyldiphenhydramine",
      "O-Monomethyldiphenhydramine",
      "Orfenadrina [INN-Spanish]",
      "Orphenadrine(INN)",
      "Orphenadrine [INN:BAN]",
      "Orphenadrinum [INN-Latin]",
      "Beta-Dimethylaminoethyl 2-Methylbenzhydryl Ether",
      "Phenyl-O-Tolylmethyl Dimethyaminoethyl Ether",
      "Beta-Dimethylaminoethyl-2-Methylbenzhydryl Ether",
      "N,N-Dimethyl-2-(Alpha-2-Tolylbenzoyloxy)Ethylamin",
      "N,N-Dimethyl-2-[(2-Methylphenyl)(Phenyl)Methoxy]ethanamine",
      "N,N-Dimethyl-2-(Alpha-2-Tolylbenzoyloxy)Ethylamine",
      "N,N-Dimethyl-2-[(2-Methylphenyl)-Phenylmethoxy]ethanamine",
      "N,N-Dimethyl-2-(O-Methyl-Alpha-Phenylbenzyl)Oxy)Ethylamine",
      "N,N-Dimethyl-2-[(O-Methyl-Alpha-Phenylbenzyl)Oxy]ethylamine",
      "N,N-Dimethyl-2-{[(2-Methylphenyl)(Phenyl)Methyl]oxy}ethanamine",
      "2-(Phenyl-O-Tolylmethoxy)Ethyldimethylamine",
      "2-Methyldiphenhydramine",
    ],
  },
  {
    primary_id: "D0D9FW",
    names: ["DP-VPA"],
  },
  {
    primary_id: "D0D9GS",
    names: ["1-(1,2,4-Oxadiazol-3-Yl)-7-Phenylheptan-1-One", "CHEMBL469050", "SCHEMBL2162025"],
  },
  {
    primary_id: "D0D9HA",
    names: ["PF-04634817"],
  },
  {
    primary_id: "D0D9HW",
    names: [
      "Tenofovir",
      "Apropovir",
      "PMPA",
      "TFV",
      "Tenefovir",
      "GS 1275",
      "GS 1278",
      "GS1278",
      "GNA & Tenofovir",
      "HHA & Tenofovir",
      "KS-5021",
      "Viread (TN)",
      "Viread, Tenofovir",
      "D,L-Tenofovir",
      "PMPA-(R)",
      "Phosphonic Acid, [[2-(6-Amino-9H-Purin-9",
      "[(2R)-1-(6-Aminopurin-9-Yl)Propan-2-Yl]oxymethylphosphonic Acid",
      "Phosphonic Acid, [[(1R)-2-(6-Amino-9H-Purin-9-Yl)-1-Methylethoxy]methyl]-(9CI)",
      "Phosphonic Acid, [[(1R)-2-(6-Amino-9H-Purin-9-Yl)-1-Methylethoxy]methyl]-& Galanthus Nivalis Agglutinin (GNA)",
      "Phosphonic Acid, [[(1R)-2-(6-Amino-9H-Purin-9-Yl)-1-Methylethoxy]methyl]-& Hippeastrum Hybrid Agglutinin(HHA)",
      "(R)-9-(2-Phosphonomethoxypropyl)Adenine",
      "(R)-9-(2-Phosphonylmethoxypropyl)Adenine",
      "(R)-PMPA",
    ],
  },
  {
    primary_id: "D0D9HZ",
    names: ["PF-06438179"],
  },
  {
    primary_id: "D0D9IQ",
    names: ["BGB-290"],
  },
  {
    primary_id: "D0D9JB",
    names: [
      "ALERT",
      "Azaya Liposomal Encapsulated Radiation Therapy",
      "Anticancer Radiotherapeutics (Liposomal, PSN), Azaya",
    ],
  },
  {
    primary_id: "D0D9JL",
    names: ["1-(2-M-Tolyl-Ethyl)-Pyrrolidine", "CHEMBL257919", "SCHEMBL14426311"],
  },
  {
    primary_id: "D0D9JW",
    names: ["Ketorolac"],
  },
  {
    primary_id: "D0D9KK",
    names: ["CD4-BFFI", "CD4 MAb-Based Bifunctional HIV Entry Inhibitor (HIV Infection), Roche"],
  },
  {
    primary_id: "D0D9KQ",
    names: [
      "E-6006 CITRATE",
      "E-6039",
      "N,N-Dimethyl-N-[2-[1-(1-Methyl-1H-Pyrazol-5-Yl)-1-(2-Thienyl)Methoxy]ethyl]amine Citrate",
    ],
  },
  {
    primary_id: "D0D9KV",
    names: ["SKL-18287"],
  },
  {
    primary_id: "D0D9LB",
    names: ["BIBV 308"],
  },
  {
    primary_id: "D0D9LJ",
    names: [
      "Atiprimod",
      "Atiprimod [INN]",
      "SKF 106615",
      "SKF-106615",
      "N,N-Diethyl-8,8-Dipropyl-2-Azaspiro(4.5)Decane-2-Propanamine",
      "3-(8,8-Dipropyl-3-Azaspiro[4.5]decan-3-Yl)-N,N-Diethylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D0D9LT",
    names: [
      "5-Methyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL182305",
      "CHEMBL555587",
      "5-Methylpyrrolidine-2-Imine",
      "BDBM50066782",
      "AKOS006365094",
      "5-Methyl-Pyrrolidin-(2E)-Ylideneamine",
    ],
  },
  {
    primary_id: "D0D9LU",
    names: ["ISIS-STAT3Rx"],
  },
  {
    primary_id: "D0D9MV",
    names: ["Aryl Mannoside Derivative 17"],
  },
  {
    primary_id: "D0D9MW",
    names: [
      "4-Propyl-N-(Quinolin-3-Yl)Benzamide",
      "CHEMBL259600",
      "ChemDiv2_003456",
      "AC1N8MZ4",
      "Oprea1_836743",
      "SCHEMBL846554",
      "4-Propyl-N-(3-Quinolyl)Benzamide",
      "ZINC188371",
      "HMS1378N02",
      "4-Propyl-N-Quinolin-3-Ylbenzamide",
      "BDBM50376280",
      "AKOS001583305",
      "MCULE-1509884615",
      "EU-0034321",
      "SR-01000396314",
    ],
  },
  {
    primary_id: "D0D9NA",
    names: [
      "Almitrine",
      "Almitrin",
      "Almitrina",
      "Almitrinum",
      "Vectarion",
      "Almitrine Bismesylate",
      "Almitrine Dimesylate",
      "S 2620",
      "Almitrina [INN-Spanish]",
      "Almitrine (INN)",
      "Almitrine Bis(Methanesulfonate)",
      "Almitrine [BAN:INN]",
      "Almitrine [INN:BAN]",
      "Almitrinum [INN-Latin]",
      "N,N'-Diallyl-6-{4-[bis(4-Fluorophenyl)Methyl]piperazin-1-Yl}-1,3,5-Triazine-2,4-Diamine",
      "2,4-Bis(Allylamino)-6-(4-(Bis(P-Fluorophenyl)Methyl)-1-Piperazinyl)-S-Triazine",
      "2,4-Bis[allylamino]-6-[4-[bis(P-Fluorophenyl)Methyl]-1-Piperazinyl]-S-Triazine",
      "6-(4-(Bis(4-Fluorphenyl)Methyl)-1-Piperazinyl)-N,N'-Di-2-Propenyl-1,3,5-Triazin-2,4-Diamin",
      "6-[4-[bis(4-Fluorophenyl)Methyl]piperazin-1-Yl]-2-N,4-N-Bis(Prop-2-Enyl)-1,3,5-Triazine-2,4-Diamine",
    ],
  },
  {
    primary_id: "D0D9NP",
    names: ["Nix-0699"],
  },
  {
    primary_id: "D0D9NQ",
    names: [
      "NSC-58046",
      "CHEMBL222994",
      "84-94-6",
      "1,5-Naphthalenedisulfonic Acid, 3-[(4-Amino-2-Methylphenyl)Azo]-",
      "1,5-Naphthalenedisulfonic Acid, 3-((4-Amino-2-Methylphenyl)Azo)-",
      "1,5-Naphthalenedisulfonic Acid, 3-(2-(4-Amino-2-Methylphenyl)Diazenyl)-",
      "1,5-Naphthalenedisulfonic Acid, 3-[2-(4-Amino-2-Methylphenyl)Diazenyl]-",
      "EINECS 201-575-2",
      "6629-26-1",
      "SCHEMBL4650840",
      "AC1L25M6",
      "SCHEMBL12559081",
      "DTXSID2058914",
      "CTK2F6804",
      "ZINC4707344",
      "3-((4-Amino-2-Methylphenyl)Azo)-1,5-Naphthalenedisulfonic Acid",
      "ZINC13586631",
      "BDBM50158380",
      "AKOS030539754",
    ],
  },
  {
    primary_id: "D0D9NT",
    names: [
      "4-TERT-BUTYLBENZENESULFONIC ACID",
      "1133-17-1",
      "AC1L8AOG",
      "SCHEMBL196750",
      "DTXSID80314995",
      "ZINC1565641",
      "4-Tert-Butylbenzene-1-Sulfonic Acid",
      "NSC290811",
      "AKOS022773375",
      "NSC-290811",
      "MCULE-2120234795",
      "DB07440",
      "AJ-27135",
      "BBS",
      "4-Tert-Butylbenzenesulfonic Acid, AldrichCPR",
    ],
  },
  {
    primary_id: "D0D9NX",
    names: ["(E,E)-5-Hydroxy-1,7-Diphenyl-4,6-Heptadien-3-One"],
  },
  {
    primary_id: "D0D9NY",
    names: ["ETC-1002"],
  },
  {
    primary_id: "D0D9NZ",
    names: ["Methoxyestradiol"],
  },
  {
    primary_id: "D0D9OC",
    names: [
      "2-Chloro-6(Methylamino)Purine",
      "82499-02-3",
      "2-CHLORO-N-METHYL-9H-PURIN-6-AMINE",
      "2-Chloro-N-Methyl-7H-Purin-6-Amine",
      "CHEMBL446449",
      "2-Chloro-6-(Methylamino)Purine",
      "2-CHLORO-6-(N-METHYLAMINO)-9H-PURINE",
      "ACMC-20anyt",
      "PubChem9784",
      "2-Chloro-6-Methylaminopurine",
      "SCHEMBL130696",
      "AC1NE105",
      "CTK5E9746",
      "DTXSID00404544",
      "RIAVUEFUPHOGJY-UHFFFAOYSA-N",
      "2-Chloro-6-Methylamino-9H-Purine",
      "NSC15416",
      "ZINC4429067",
      "NSC-15416",
      "BDBM50208873",
      "AKOS006283488",
      "AKOS015894684",
      "2-Chloro-6(Methylamino)Purine, 97%",
      "AB44277",
      "TC-170732",
    ],
  },
  {
    primary_id: "D0D9OL",
    names: ["ISIS 111992"],
  },
  {
    primary_id: "D0D9OM",
    names: ["V1A F/U"],
  },
  {
    primary_id: "D0D9ON",
    names: [
      "Iodo-Phenylalanine",
      "4-Iodo-L-Phenylalanine",
      "24250-85-9",
      "4-Iodophenylalanine",
      "L-4-Iodophenylalanine",
      "H-Phe(4-I)-OH",
      "IODO-PHENYLALANINE",
      "(2S)-2-Amino-3-(4-Iodophenyl)Propanoic Acid",
      "P-Iodo-L-Phenylalanine",
      "(S)-2-Amino-3-(4-Iodophenyl)Propanoic Acid",
      "Phenylalanine, 4-Iodo-",
      "P-IODOPHENYLALANINE",
      "L-Phenylalanine, 4-Iodo-",
      "UNII-J882Z73MPL",
      "L-4-Iodophe",
      "(L)-4-Iodophenylalanine",
      "1991-81-7",
      "J882Z73MPL",
      "MFCD00002602",
      "(2S)-2-Azanyl-3-(4-Iodophenyl)Propanoic Acid",
      "DL-4-Iodophenylalanine",
      "Para-Iodophenylalanine",
      "4-Iod-L-Phenylalanin",
      "4-Iodo-L-Phenylalaine",
    ],
  },
  {
    primary_id: "D0D9PD",
    names: ["SKS-927"],
  },
  {
    primary_id: "D0D9PH",
    names: ["Small Molecule 18a"],
  },
  {
    primary_id: "D0D9PI",
    names: ["Tetraethylene Glycol"],
  },
  {
    primary_id: "D0D9PL",
    names: ["ISIS 31963"],
  },
  {
    primary_id: "D0D9QN",
    names: ["ISIS 147919"],
  },
  {
    primary_id: "D0D9RF",
    names: ["RY024"],
  },
  {
    primary_id: "D0D9RL",
    names: ["(-)-Eseroline"],
  },
  {
    primary_id: "D0D9RN",
    names: [
      "7-Hydroxy-2-(Morpholin-4-Yl)Chromen-4-One",
      "CHEMBL197536",
      "SCHEMBL3533604",
      "ULTRFFGNTUDFJW-UHFFFAOYSA-N",
      "BDBM50178123",
    ],
  },
  {
    primary_id: "D0D9SF",
    names: ["BrevaRex Mab", "BrevaRex MAb", "Monoclonal Antibody"],
  },
  {
    primary_id: "D0D9TU",
    names: ["LAVENDUSTIN A"],
  },
  {
    primary_id: "D0D9VE",
    names: ["DCCR", "Diazoxide Choline Controlled-Release"],
  },
  {
    primary_id: "D0D9WM",
    names: ["ISIS 28407"],
  },
  {
    primary_id: "D0D9YB",
    names: ["DPX E7"],
  },
  {
    primary_id: "D0D9YL",
    names: [
      "1-Benzyl-3-Methyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL63944",
      "120642-81-1",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-3-Methyl-1-(Phenylmethyl)-",
      "Phenyltheophylline",
      "ACMC-20mp1i",
      "SCHEMBL7453870",
      "CTK0C3768",
      "DTXSID50473801",
    ],
  },
  {
    primary_id: "D0D9YU",
    names: ["PLATENSIMYCIN"],
  },
  {
    primary_id: "D0D9YW",
    names: ["SB 216641"],
  },
  {
    primary_id: "D0D9ZB",
    names: ["TAK-583"],
  },
  {
    primary_id: "D0DA0C",
    names: ["Peniel-3000"],
  },
  {
    primary_id: "D0DA0V",
    names: ["Bisquinolinium Derivative"],
  },
  {
    primary_id: "D0DA1E",
    names: ["A-867744", "A867744", "A 867744"],
  },
  {
    primary_id: "D0DA1U",
    names: ["C7R-GD2.CART Cells"],
  },
  {
    primary_id: "D0DA3D",
    names: ["MLR-1130"],
  },
  {
    primary_id: "D0DA3T",
    names: ["Prostate Dendritic Cell-Based Vaccine"],
  },
  {
    primary_id: "D0DA4B",
    names: ["JNJ-64407564"],
  },
  {
    primary_id: "D0DA5M",
    names: ["C 82"],
  },
  {
    primary_id: "D0DA6B",
    names: ["Pyridine Derivative 8"],
  },
  {
    primary_id: "D0DA6N",
    names: ["TEM-8-Fc"],
  },
  {
    primary_id: "D0DA6X",
    names: ["JNJ-47910382"],
  },
  {
    primary_id: "D0DA7Z",
    names: ["MBX-400/Cyclopropavir"],
  },
  {
    primary_id: "D0DA8D",
    names: ["Hexahydro Quinoline Derivative 1"],
  },
  {
    primary_id: "D0DA9T",
    names: ["D-Lactic Acid"],
  },
  {
    primary_id: "D0DB0Z",
    names: ["PMID29130358-Compound-SB-3CT"],
  },
  {
    primary_id: "D0DB2F",
    names: [
      "Concatameric LAG3Ig",
      "Concatameric LAG3Ig (Immunoadhesin)",
      "CD223Ig (Immunoadhesin), Medexgen",
      "Concatameric LAG3Ig (Immunoadhesin),Medexgen",
    ],
  },
  {
    primary_id: "D0DB2N",
    names: ["ANNULIN A", "Annulin A", "CHEMBL465019", "SCHEMBL10013625"],
  },
  {
    primary_id: "D0DB3L",
    names: ["Benzoimidazole Derivative 1"],
  },
  {
    primary_id: "D0DB7A",
    names: [
      "9-Isopropyl-9H-Adenine",
      "CHEMBL67199",
      "9-Isopropyl-9H-Purin-6-Amine",
      "31601-35-1",
      "9-Propan-2-Ylpurin-6-Amine",
      "SCHEMBL754007",
      "AC1L987T",
      "6-Amino-9-Isopropyl-9H-Purine",
      "9-Isopropyl-9H-Purin-6-Ylamine",
      "BDBM50013685",
      "AKOS022639673",
      "9H-Purin-6-Amine, 9-(1-Methylethyl)-",
    ],
  },
  {
    primary_id: "D0DB7W",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 9"],
  },
  {
    primary_id: "D0DB8G",
    names: ["PMID25522065-Compound-47"],
  },
  {
    primary_id: "D0DB8W",
    names: [
      "H-Tyr-Pro-Phe-Phe-OH",
      "CHEMBL589982",
      "213768-49-1",
      "CTK0J7647",
      "DTXSID40649492",
      "BDBM50308384",
      "AKOS030610604",
      "L-Tyrosyl-L-Prolyl-L-Phenylalanyl-L-Phenylalanine",
      "L-Phenylalanine, L-Tyrosyl-L-Prolyl-L-Phenylalanyl-",
    ],
  },
  {
    primary_id: "D0DB9T",
    names: [
      "N-Tert-Butyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL592431",
      "ChemDiv3_004014",
      "AC1LE08E",
      "Oprea1_837647",
      "MLS001196417",
      "ZINC88008",
      "BRD4081",
      "MolPort-019-755-363",
      "MolPort-002-224-545",
      "HMS2867C08",
      "HMS1484G10",
      "BRD-4081",
      "STK125446",
      "BDBM50308431",
      "AKOS001667641",
      "MCULE-9506670644",
      "CCG-113326",
      "IDI1_021924",
      "N-Tert-Butylphenothiazine-10-Carboxamide",
      "SMR000556301",
      "N-(Tert-Butyl)Phenothiazin-10-Ylcarboxamide",
      "ST50857670",
      "EU-0080142",
      "N-Tert-Butyl-10H-Phenothiazine-10-Carboxamide",
      "AB00120758-01",
      "N-(Tert-Butyl)-10H-Phenothiazine-10-Carboxamide",
      "N-Tert-Butyl-1''H-Phenothiazine-1''-Carboxamide",
    ],
  },
  {
    primary_id: "D0DC0S",
    names: ["Octaplas LG"],
  },
  {
    primary_id: "D0DC1I",
    names: ["RG-7356"],
  },
  {
    primary_id: "D0DC1P",
    names: ["1-(3-(4-(2-Fluoroethyl)Phenoxy)Propyl)Piperidine", "CHEMBL449484"],
  },
  {
    primary_id: "D0DC2P",
    names: ["PMID25684022-Compound-WO2012135799 42(1.4)"],
  },
  {
    primary_id: "D0DC3E",
    names: ["3,3'-Pyrazine-2,5-Diyldiphenol"],
  },
  {
    primary_id: "D0DC3H",
    names: ["FluDrugStrategy"],
  },
  {
    primary_id: "D0DC3N",
    names: [
      "Digeranyl Bisphosphonate",
      "[(6E,11E)-2,6,12,16-Tetramethyl-9-Phosphonoheptadeca-2,6,11,15-Tetraen-9-Yl]phosphonic Acid",
      "Bisphosphonate, 5",
      "CHEMBL258994",
      "GTPL3187",
      "SCHEMBL14408741",
    ],
  },
  {
    primary_id: "D0DC3S",
    names: ["Pyrazole Derivative 62"],
  },
  {
    primary_id: "D0DC4E",
    names: ["PMID16099654C3a"],
  },
  {
    primary_id: "D0DC4O",
    names: ["PMID25666693-Compound-74"],
  },
  {
    primary_id: "D0DC6U",
    names: [
      "Dapivirine",
      "Dapivirinum",
      "R 147681",
      "R147681",
      "TMC 120",
      "TMC120",
      "GEL-002",
      "R-147681",
      "TMC-120",
      "TMC120-R147681",
      "TMC-120/R-147681",
      "4-((4-((2,4,6-Trimethylphenyl)Amino)Pyrimidin-2-Yl)Amino)Benzonitrile",
      "4-({4-[(2,4,6-Trimethylphenyl)Amino]pyrimidin-2-Yl}amino)Benzenecarbonitrile",
      "4-[4-(2,4,6-TRIMETHYL-PHENYLAMINO)-PYRIMIDIN-2-YLAMINO]-BENZONITRILE",
      "4-[[4-(2,4,6-Trimethylanilino)Pyrimidin-2-Yl]amino]benzonitrile",
    ],
  },
  {
    primary_id: "D0DC7I",
    names: ["PMID25656651-Compound-11b"],
  },
  {
    primary_id: "D0DC8B",
    names: ["Indazole Amide Derivative 1"],
  },
  {
    primary_id: "D0DC8J",
    names: ["CYT-609", "Aurimune"],
  },
  {
    primary_id: "D0DD0K",
    names: ["Losartan"],
  },
  {
    primary_id: "D0DD0O",
    names: ["D-21805"],
  },
  {
    primary_id: "D0DD3F",
    names: ["(Sar)WTLNSAGYLLGPKK(Lys-Myristoyl)K", "CHEMBL524678"],
  },
  {
    primary_id: "D0DD4I",
    names: ["PMID27019002-Compound-13"],
  },
  {
    primary_id: "D0DD4N",
    names: ["GEH-120714"],
  },
  {
    primary_id: "D0DD4P",
    names: ["Cresemba"],
  },
  {
    primary_id: "D0DD7K",
    names: ["Aryl Urea Derivative 5"],
  },
  {
    primary_id: "D0DD7N",
    names: ["CH4474"],
  },
  {
    primary_id: "D0DD8I",
    names: ["J-109,390"],
  },
  {
    primary_id: "D0DD9E",
    names: ["N-Omega-Allyl-L-Arginine"],
  },
  {
    primary_id: "D0DD9F",
    names: ["HumaSPECT"],
  },
  {
    primary_id: "D0DD9T",
    names: ["HP-05"],
  },
  {
    primary_id: "D0DE0C",
    names: ["Diphenyl Purine Derivative 2"],
  },
  {
    primary_id: "D0DE0Y",
    names: ["Benzamide Derivative 5"],
  },
  {
    primary_id: "D0DE4E",
    names: ["Spiroimidazolone Derivative 2"],
  },
  {
    primary_id: "D0DE4U",
    names: ["Erythropoietin Beta"],
  },
  {
    primary_id: "D0DE5I",
    names: ["PMID25666693-Compound-78"],
  },
  {
    primary_id: "D0DE5K",
    names: ["PMID25666693-Compound-42"],
  },
  {
    primary_id: "D0DE8K",
    names: ["Lergotrile"],
  },
  {
    primary_id: "D0DE9D",
    names: ["Cavatakcoxsackievirus"],
  },
  {
    primary_id: "D0DE9L",
    names: [
      "JWH-368",
      "(5-(3-Fluorophenyl)-1-Pentyl-1H-Pyrrol-3-Yl)(Naphthalen-1-Yl)Methanone",
      "914458-31-4",
      "CHEMBL220304",
      "[5-(3-Fluorophenyl)-1-Pentyl-1H-Pyrrol-3-Yl](Naphthalen-1-Yl)Methanone",
      "CTK8B4185",
      "DTXSID60658824",
      "MolPort-023-278-971",
      "5344AC",
      "ZINC36294609",
      "BDBM50192608",
      "ANW-44202",
      "AKOS015999106",
      "AJ-92570",
      "AK-88831",
      "DS-18529",
      "TC-132947",
      "KB-208485",
      "AX8228512",
    ],
  },
  {
    primary_id: "D0DF1R",
    names: ["Ulinastatin"],
  },
  {
    primary_id: "D0DF1Z",
    names: [
      "M-Phenoxybenzoic Acid For Cis-Isomer",
      "3-Phenoxybenzoic Acid",
      "3739-38-6",
      "M-Phenoxybenzoic Acid",
      "Benzoic Acid, 3-Phenoxy-",
      "3-Carboxydiphenyl Ether",
      "UNII-69DC2655VH",
      "BENZOIC ACID, M-PHENOXY-",
      "3-Phenoxy-Benzoic Acid",
      "EINECS 223-121-2",
      "CHEMBL663",
      "BRN 2105574",
      "M-PHENOXYBENZOIC ACID FOR CIS-ISOMER",
      "CHEBI:72631",
      "NXTDJHZGHOFSQG-UHFFFAOYSA-N",
      "69DC2655VH",
      "VJJ",
      "3-Phenoxybenzoicacid",
      "M-Phenoxy Benzoic Acid",
      "M-Carboxydiphenyl Ether",
      "PubChem14424",
      "3-Phenoxy Benzoic Acid",
      "Meta-Phenoxybenzoic Acid",
      "ACMC-1CJQX",
      "Enamine_000396",
      "AC1L2EBV",
      "Benzoic Acid,3-Phenoxy-",
      "3-PhOC6H4COOH",
    ],
  },
  {
    primary_id: "D0DF2M",
    names: ["PMID28870136-Compound-48"],
  },
  {
    primary_id: "D0DF3Z",
    names: [
      "6,7-Dimethoxy-N-M-Tolylquinazolin-4-Amine",
      "CHEMBL56802",
      "AC1MDC5E",
      "Oprea1_570563",
      "SCHEMBL5482883",
      "ZINC5857",
      "4-Quinazolinamine,6,7-Dimethoxy-N-(3-Methylphenyl)-",
      "MolPort-001-734-434",
      "BDBM50102093",
      "MCULE-3929709513",
      "666839-07-2",
      "(6,7-Dimethoxy-Quinazolin-4-Yl)-M-Tolyl-Amine",
      "SR-02000000178",
      "SR-02000000178-1",
      "6,7-Dimethoxy-N-(3-Methylphenyl)Quinazolin-4-Amine",
    ],
  },
  {
    primary_id: "D0DF5Q",
    names: ["CPI-444"],
  },
  {
    primary_id: "D0DF6B",
    names: ["Azetidine-1-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0DF8Y",
    names: ["CTA-192"],
  },
  {
    primary_id: "D0DF9Z",
    names: ["Attapulgite"],
  },
  {
    primary_id: "D0DG0F",
    names: ["PMID27744724-Compound-Fomula2"],
  },
  {
    primary_id: "D0DG0W",
    names: ["ALX-0625"],
  },
  {
    primary_id: "D0DG4A",
    names: ["PMID26004420-Compound-WO2014015495B"],
  },
  {
    primary_id: "D0DG4X",
    names: ["AXS-05"],
  },
  {
    primary_id: "D0DG5A",
    names: [
      "4-(7-Methyl-Oct-3-Ynyl)-1H-Imidazole",
      "CHEMBL14497",
      "1H-Imidazole, 5-(7-Methyl-3-Octyn-1-Yl)-",
      "SCHEMBL7618441",
      "BDBM50070212",
      "4-(7-Methyl-3-Octynyl)-1H-Imidazole",
    ],
  },
  {
    primary_id: "D0DG6C",
    names: ["Encapsulated Transplanted Islets"],
  },
  {
    primary_id: "D0DG7E",
    names: ["PMID27539678-Compound-10"],
  },
  {
    primary_id: "D0DG8W",
    names: ["Foliglurax"],
  },
  {
    primary_id: "D0DG9F",
    names: ["PP-1420"],
  },
  {
    primary_id: "D0DG9M",
    names: ["AZD-9139"],
  },
  {
    primary_id: "D0DH0O",
    names: ["PMID25726713-Compound-30"],
  },
  {
    primary_id: "D0DH0Y",
    names: ["Aryl Mannoside Derivative 12"],
  },
  {
    primary_id: "D0DH1H",
    names: ["CCT-241533", "Chk2 Inhibitors (Cancer)", "Chk2 Inhibitors (Cancer), Cancer Research Technology"],
  },
  {
    primary_id: "D0DH1S",
    names: [
      "Aglycoristocetin Derivatives",
      "Aglycoristocetin Derivatives (Influenza Infection)",
      "Aglycoristocetin Derivatives (Influenza Infection), Rega Institute For Medical Research",
    ],
  },
  {
    primary_id: "D0DH5X",
    names: ["MK-4618"],
  },
  {
    primary_id: "D0DH7R",
    names: ["PMID28092474-Compound-32c"],
  },
  {
    primary_id: "D0DH8B",
    names: ["ISIS 138616"],
  },
  {
    primary_id: "D0DH9G",
    names: [
      "2-Phenylpiperidine Hydrochloride",
      "2-Phenylpiperidine",
      "3466-80-6",
      "2-Phenyl-Piperidine",
      "Piperidine, 2-Phenyl-",
      "2-Phenyl Piperidine",
      "EINECS 222-422-6",
      "PubChem8019",
      "(Piperidin-2-Yl)Benzene",
      "ACMC-1AG1G",
      "AC1L2U2P",
      "AC1Q1H0F",
      "SCHEMBL938781",
      "SCHEMBL11523711",
      "CTK1C4792",
      "MolPort-000-147-784",
      "WGIAUTGOUJDVEI-UHFFFAOYSA-N",
      "KS-00000N7T",
      "ALBB-006245",
      "SBB022661",
      "STK312087",
      "BBL022420",
      "AKOS000184053",
      "AKOS016344001",
      "FS-1688",
      "MCULE-8351203351",
      "ACN-027346",
      "KB-84880",
      "AM803744",
      "SC-48762",
      "TR-014336",
      "ST2404539",
      "AB0089977",
      "ST45091094",
      "P2005",
      "BB 0254270",
      "A6090",
      "FT-0656912",
      "CS-0028391",
      "K-7884",
      "J-019700",
    ],
  },
  {
    primary_id: "D0DI0B",
    names: ["OX 19"],
  },
  {
    primary_id: "D0DI1B",
    names: ["TTP273"],
  },
  {
    primary_id: "D0DI1R",
    names: ["PMID25666693-Compound-130"],
  },
  {
    primary_id: "D0DI1T",
    names: ["Biaryl Mannoside Derivative 16"],
  },
  {
    primary_id: "D0DI3E",
    names: [
      "DHP-218",
      "Dhp-218",
      "102097-78-9",
      "Dhp 218",
      "3-Pyridinecarboxylicacid,1,4-Dihydro-2,6-Dimethyl-4-(2-Nitrophenyl)-5-(2-Oxido-1,3,2-Dioxaphosphorinan-2-Yl)-,methyl Ester",
      "3-Pyridinecarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(2-Nitrophenyl)-5-(2-Oxido-1,3,2-Dioxaphosphorinan-2-Yl)-, Methyl Ester;3-Pyridinecarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(2-Nitrophenyl)-5-(2-Oxido-1,3,2-Dioxaphosphorinan-2-Yl)-, Methyl Ester",
      "AC1O3SAA",
      "ACMC-20m53m",
      "SCHEMBL1356340",
      "CTK4A0679",
      "AKOS030566270",
      "Methyl 2,6-Dimethyl-4-(2-Nitrophenyl)-5-(2-Oxo-1,",
    ],
  },
  {
    primary_id: "D0DI4I",
    names: [
      "N5-[4-(2-Oxo-3-Phenylpropoxy)Phenyl]-L-Glutamine",
      "CHEMBL518118",
      "Modified Amino Acid Analog, 8k",
      "BDBM24276",
      "(2S)-2-Amino-4-{[4-(2-Oxo-2-Phenylethoxy)Phenyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D0DI5S",
    names: ["Carbamide Derivative 19"],
  },
  {
    primary_id: "D0DI6L",
    names: ["Sivextro"],
  },
  {
    primary_id: "D0DI6U",
    names: ["Alkyl Mannoside Derivative 10"],
  },
  {
    primary_id: "D0DI7W",
    names: [
      "4-Methyl-5,6-Dihydro-1H-Pyridin-(2Z)-Ylideneamine",
      "CHEMBL75304",
      "479668-33-2",
      "5,6-Dihydro-4-Methyl-2-Pyridinamine",
      "ZINC2002913",
      "BDBM50116666",
      "AKOS006359115",
      "FCH1123804",
      "ACM479668332",
      "DB-070901",
      "FT-0749169",
      "2-Pyridinamine,5,6-Dihydro-4-Methyl-(9CI)",
    ],
  },
  {
    primary_id: "D0DI8J",
    names: ["BMS-275183", "BMS-198246", "Oral Taxane (Cancer), BMS", "Oral Taxane (Cancer), Bristol-Myers Squibb"],
  },
  {
    primary_id: "D0DI9S",
    names: ["PMID29671355-Compound-31"],
  },
  {
    primary_id: "D0DJ0S",
    names: ["NGM313"],
  },
  {
    primary_id: "D0DJ1B",
    names: ["Naproxen"],
  },
  {
    primary_id: "D0DJ2H",
    names: ["BION-1301"],
  },
  {
    primary_id: "D0DJ3R",
    names: [
      "7-((1H-Imidazol-1-Yl)Methyl)-4H-Chromen-4-One",
      "CHEMBL158984",
      "ZINC13861991",
      "BDBM50097369",
      "7-Imidazol-1-Ylmethyl-Chromen-4-One",
    ],
  },
  {
    primary_id: "D0DJ4A",
    names: ["Preverex", "Biostent", "Phomin", "Cytochalasin B", "Cytochalasin B Containing Stent"],
  },
  {
    primary_id: "D0DJ5T",
    names: ["Ecomustine", "Acomustine", "CY-233"],
  },
  {
    primary_id: "D0DJ6G",
    names: ["4-Butyl-N-(7-Hydroxynaphthalen-1-Yl)Benzamide", "CHEMBL259601"],
  },
  {
    primary_id: "D0DJ8D",
    names: ["GPI-16552"],
  },
  {
    primary_id: "D0DJ8S",
    names: ["CART-Muc1 Cells"],
  },
  {
    primary_id: "D0DJ9D",
    names: ["Burosumab", "Burosumab [USAN]", "UNII-G9WJT6RD29", "G9WJT6RD29"],
  },
  {
    primary_id: "D0DJ9Y",
    names: ["UCART123"],
  },
  {
    primary_id: "D0DK0R",
    names: ["Macrolactam Derivative 3"],
  },
  {
    primary_id: "D0DK2K",
    names: ["Antiinflammatory Therapeutics"],
  },
  {
    primary_id: "D0DK2X",
    names: ["GSK-280"],
  },
  {
    primary_id: "D0DK3R",
    names: ["Peptide Analog 25"],
  },
  {
    primary_id: "D0DK4I",
    names: ["PMID26560530-Compound-24"],
  },
  {
    primary_id: "D0DK5R",
    names: ["YESCARTA"],
  },
  {
    primary_id: "D0DK5X",
    names: ["PF-06263276"],
  },
  {
    primary_id: "D0DK8G",
    names: ["Technetium Tc-99m Etidronate Kit"],
  },
  {
    primary_id: "D0DK8V",
    names: [
      "Cetirizine",
      "Cetiderm",
      "Cetirizin",
      "Cetirizina",
      "Cetirizinum",
      "Cetryn",
      "Setir",
      "Virlix",
      "Ziptek",
      "Zirtek",
      "Zyrlex",
      "Cetirizina [Spanish]",
      "Cetirizinum [Latin]",
      "Cetrizine Hcl",
      "Hitrizin Film Tablet",
      "Cetiderm (TN)",
      "Cetirizine (INN)",
      "Cetirizine [INN:BAN]",
      "P-071",
      "Reactine (TN)",
      "Zyrtec (TN)",
      "[(2-{4-[(4-Chlorophenyl)(Phenyl)Methyl]piperazin-1-Yl}ethyl)Oxy]acetic Acid",
      "(2-(4-((4-Chlorophenyl)Phenylmethyl)-1-Piperazinyl)Ethoxy)Acetic Acid",
      "(2-{4-[(4-Chloro-Phenyl)-Phenyl-Methyl]-Piperazin-1-Yl}-Ethoxy)-Acetic Acid",
      "(2-{4-[(4-Chlorophenyl)(Phenyl)Methyl]piperazin-1-Yl}ethoxy)Acetic Acid",
      "2-(2-{4-[(4-Chlorophenyl)(Phenyl)Methyl]piperazin-1-Yl}ethoxy)Acetic Acid",
      "2-[2-[4-[(4-Chlorophenyl)-Phenylmethyl]piperazin-1-Yl]ethoxy]acetic Acid",
    ],
  },
  {
    primary_id: "D0DK8X",
    names: [
      "BMS-911543",
      "1271022-90-2",
      "BMS 911543",
      "BMS911543",
      "UNII-7N03P021J8",
      "7N03P021J8",
      "N,N-Dicyclopropyl-4-[(1,5-Dimethyl-1h-Pyrazol-3-Yl)Amino]-6-Ethyl-1-Methyl-1,6-Dihydroimidazo[4,5-D]pyrrolo[2,3-B]pyridine-7-Carboxamide",
      "N,N-Dicyclopropyl-4-((1,5-Dimethyl-1H-Pyrazol-3-Yl)Amino)-6-Ethyl-1-Methyl-1,6-Dihydroimidazo[4,5-D]pyrrolo[2,3-B]pyridine-7-Carboxamide",
      "N,N-Dicyclopropyl-4-((1,5-Dimethyl-1H-Pyrazol-3-Yl)Amino)-6-Ethyl-1-Methyl-1,6-Dihydroimidazo(4,5-D)Pyrrolo(2,3b)Pyridine-7-Carboxamide",
      "JCINBYQJBYJGDM-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0DK9S",
    names: ["2-(3-Butoxy-4-Hydroxy-Phenyl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D0DL1F",
    names: [
      "1-[(Tert-Butylamino)Ethyl]-1,1-Bisphosphonicacid",
      "CHEMBL405827",
      "SCHEMBL8677583",
      "BDBM50374763",
      "[2-(Tert-Butylamino)-1-Phosphono-Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0DL1O",
    names: ["Tetra-Hydro-Oxazolopyridine Derivative 2"],
  },
  {
    primary_id: "D0DL2S",
    names: ["MILFASARTAN"],
  },
  {
    primary_id: "D0DL3R",
    names: ["PMID25553724-Compound-US2011801300410"],
  },
  {
    primary_id: "D0DL3W",
    names: ["APX-001"],
  },
  {
    primary_id: "D0DL4Y",
    names: ["(5E,8E,11E,14E)-Icosa-5,8,11,14-Tetraenal", "CHEMBL373333", "SCHEMBL1800772"],
  },
  {
    primary_id: "D0DL5J",
    names: ["PMID27019002-Compound-49"],
  },
  {
    primary_id: "D0DL6J",
    names: [
      "CI-1044",
      "UNII-O4T475XIIY",
      "O4T475XIIY",
      "197894-84-1",
      "DSSTox_CID_27291",
      "DSSTox_RID_82245",
      "DSSTox_GSID_47291",
      "CAS-NOCAS_47291",
      "SCHEMBL6441242",
      "CHEMBL342285",
      "DTXSID5047291",
      "NOCAS_47291",
      "Tox21_300455",
      "NCGC00254387-01",
      "NCGC00248057-01",
      "PD-189659",
      "HY-100246",
      "CS-0018397",
      "(+)-CI-1044",
      "3-Pyridinecarboxamide, N-((3R)-9-Amino-3,4,6,7-Tetrahydro-4-Oxo-1-Phenylpyrrolo(3,2,1-Jk)(1,4)Benzodiazepin-3-Yl)-",
      "3-Pyridinecarboxamide, N-(9-Amino-3,4,6,7-Tetrahydro-4-Oxo-1-Phenylpyrrolo(3,2,1-Jk)(1,4)Benzodiazepin-3-Yl)-, (R)-",
    ],
  },
  {
    primary_id: "D0DL7P",
    names: ["Diazepinone Derivative 4"],
  },
  {
    primary_id: "D0DM2G",
    names: ["Bis-Indolylmaleimide Derivative 1"],
  },
  {
    primary_id: "D0DM4K",
    names: ["Heat-Killed Mycobacterium Vaccae"],
  },
  {
    primary_id: "D0DM5A",
    names: ["SIM-6080"],
  },
  {
    primary_id: "D0DM5S",
    names: ["IN1535", "220792-57-4", "CTK8F1119", "K00613a", "ZINC00582597", "NCGC00390411-01", "RT-011271"],
  },
  {
    primary_id: "D0DM6F",
    names: [
      "(1R,5R)-30-OXO-19-OXA-2,6,10,12-TETRAAZAHEXACYCLO[18.6.2.1^{2,5}.1^{14,18}.0^{8,12}.0^{23,27}]TRIACONTA-8,10,14(29),15,17,20(28),21,23(27)-OCTAENE-17-CARBONITRILE (STRUCTURAL MIX)",
      "CHEMBL525369",
      "BDBM14023",
      "Macrocyclic 3-Aminopyrrolidinone Analog 83",
    ],
  },
  {
    primary_id: "D0DM6I",
    names: ["DAPITANT", "CHEMBL2103951"],
  },
  {
    primary_id: "D0DM9L",
    names: ["PMID25399762-Compound-Table1-C6"],
  },
  {
    primary_id: "D0DN0W",
    names: [
      "Niraparib Tosylate",
      "1038915-73-9",
      "MK-4827 (Tosylate)",
      "MK-4827 Tosylate",
      "UNII-75KE12AY9U",
      "MK-4827(Niraparib) Tosylate",
      "75KE12AY9U",
      "MK-4827-Tosylate",
      "MK 4827 Tosylate",
      "Niraparib(MK-4827) Tosylate",
      "KS-00000TSH",
      "MolPort-044-556-849",
      "S7625",
      "HY-10619B",
      "AKOS030632785",
      "CS-2283",
      "AC-30383",
      "KB-335358",
      "AX8326059",
    ],
  },
  {
    primary_id: "D0DN1I",
    names: ["JTT-551"],
  },
  {
    primary_id: "D0DN3Q",
    names: ["Pyrazolo[1,5-A]pyridine Derivative 1"],
  },
  {
    primary_id: "D0DN4W",
    names: ["5-Bromo Orotate"],
  },
  {
    primary_id: "D0DN6A",
    names: ["1,4-Bis-(Dmt-Tic-Amino)Butane", "CHEMBL370197"],
  },
  {
    primary_id: "D0DN7K",
    names: ["RO-5267683"],
  },
  {
    primary_id: "D0DN7Y",
    names: ["SER-214"],
  },
  {
    primary_id: "D0DN8Z",
    names: ["PMID25522065-Compound-21"],
  },
  {
    primary_id: "D0DO0B",
    names: ["TT-111"],
  },
  {
    primary_id: "D0DO1T",
    names: ["MR-20492"],
  },
  {
    primary_id: "D0DO1U",
    names: ["PMID28048944-Compound-3"],
  },
  {
    primary_id: "D0DO4I",
    names: ["Imidazo[1,2-B]pyridazine Derivative 5"],
  },
  {
    primary_id: "D0DO4J",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 11"],
  },
  {
    primary_id: "D0DO4S",
    names: ["Prolactin Receptor Antagonist"],
  },
  {
    primary_id: "D0DO5J",
    names: ["MDAM (Y-Methylene-10-Deazaaminopterin)"],
  },
  {
    primary_id: "D0DO5U",
    names: ["SK-7068"],
  },
  {
    primary_id: "D0DO6G",
    names: ["ISIS 19677"],
  },
  {
    primary_id: "D0DO6K",
    names: ["Thiazole Carboxamide Derivative 23"],
  },
  {
    primary_id: "D0DO6P",
    names: ["MR-2034"],
  },
  {
    primary_id: "D0DO8E",
    names: ["CAR-T Cells Targeting CLL1"],
  },
  {
    primary_id: "D0DO9W",
    names: [
      "L-Tyrosinamide",
      "ACMC-20muar",
      "131948-41-9",
      "L-Tyrosinamide,(4R)-4-Hydroxy-1-(1H-Indol-3-Ylcarbonyl)-L-Prolyl-N-Methyl-N-(Phenylmethyl)-(9CI)",
    ],
  },
  {
    primary_id: "D0DP0J",
    names: ["PR-924"],
  },
  {
    primary_id: "D0DP1O",
    names: [
      "CTD-02",
      "CTD-09",
      "Creatine Derivatives (Creatine Transport Deficiency)",
      "Creatine Derivatives (Creatine Transport Deficiency), Avicena/University Of Cincinnati",
    ],
  },
  {
    primary_id: "D0DP3V",
    names: [
      "TG-C",
      "3-Thiaglutaryl-Coa",
      "S-[[(2-Hydroxy-2-Oxoethyl)Thio]acetyl]coenzyme A",
      "(3R,5S,9R)-1-[(2R,3S,4R,5R)-5-(6-Amino-9H-Purin-9-Yl)-4-Hydroxy-3-(Phosphonooxy)Tetrahydrofuran-2-Yl]-3,5,9-Trihydroxy-8,8-Dimethyl-10,14,19-Trioxo-2,4,6-Trioxa-18,21-Dithia-11,15-Diaza-3,5-Diphosphatricosan-23-Oic Acid 3,5-Dioxide",
      "TGC",
    ],
  },
  {
    primary_id: "D0DP5I",
    names: ["BMS-986205"],
  },
  {
    primary_id: "D0DP6L",
    names: [
      "Isoflurane",
      "Aerrane",
      "Forane",
      "Forene",
      "IsoFlo",
      "Isoflurano",
      "Isofluranum",
      "AErrane (Veterinary)",
      "Forane (TN)",
      "Isoflurane [Anaesthetics, Volatile]",
      "Isoflurano [INN-Spanish]",
      "Isofluranum [INN-Latin]",
      "R-E 235dal",
      "Isoflurane (JP15/USP/INN)",
      "Isoflurane [USAN:BAN:INN:JAN]",
      "Isoflurane [USAN:INN:BAN:JAN]",
      "Ether, 1-Chloro-2,2,2-Trifluoroethyl Difluoromethyl",
      "1-Chloro-2,2,2-Trifluoroethyl Difluoromethyl Ether",
      "2-Chloro-2-(Difluoromethoxy)-1,1,1-Trifluoroethane",
      "2-Chloro-2-Difluoromethoxy-1,1,1-Trifluoroethane",
    ],
  },
  {
    primary_id: "D0DP6U",
    names: ["Azaindazole Derivative 3"],
  },
  {
    primary_id: "D0DP7T",
    names: ["Human Embryonic Stem Cell-Derived Vascular Progenitors"],
  },
  {
    primary_id: "D0DP8E",
    names: ["PMID30107136-Compound-Example57"],
  },
  {
    primary_id: "D0DP8I",
    names: [
      "Cyclazosin",
      "(+)-Cyclazosin",
      "139953-73-4",
      "CHEBI:63937",
      "Cis-1-(4-Amino-6,7-Dimethoxy-2-Quinazolinyl)-4-(2-Furanylcarbonyl)Decahydroquinoxaline",
      "[(4aR,8aS)-4-(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)Octahydroquinoxalin-1(2H)-Yl](2-Furyl)Methanone",
      "AC1L30OJ",
      "GTPL486",
      "(4-(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)Octahydroquinoxalin-1-Yl)Furan-2-Ylmethanone Hydrochloride",
      "CHEMBL423294",
      "ZINC2385764",
      "BDBM50403649",
      "[(4aR,8aS)-4-(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)-2,3,4a,5,6,7,8,8a-Octahydroquinoxalin-1-Yl]-(Furan-2-Yl)Methanone",
    ],
  },
  {
    primary_id: "D0DP8P",
    names: [
      "BW755C",
      "66000-40-6",
      "BW-755C",
      "UNII-6V6JF56BXO",
      "6V6JF56BXO",
      "CHEMBL274642",
      "4,5-Dihydro-1-(3-(Trifluoromethyl)Phenyl)-1H-Pyrazol-3-Amine",
      "1-[3-(Trifluoromethyl)Phenyl]-4,5-Dihydro-1H-Pyrazol-3-Amine",
      "1-(3-Trifluoromethylphenyl)-4,5-Dihydro-1H-Pyrazol-3-Amine",
      "1-(3-Trifluoromethyl-Phenyl)-4,5-Dihydro-1H-Pyrazol-3-Ylamine",
      "4,5-DIHYDRO-1-[3-(TRIFLUOROMETHYL)PHENYL]-1H-PYRAZOL-3-AMINE",
      "1-(3-(Trifluoromethyl)Phenyl)-4,5-Dihydro-1H-Pyrazol-3-Amine",
      "BW 755C",
      "EINECS 266-051-8",
      "AC1L2IRR",
      "SCHEMBL2573191",
      "CTK5C3353",
      "DTXSID30216213",
    ],
  },
  {
    primary_id: "D0DP8U",
    names: ["IKH-02"],
  },
  {
    primary_id: "D0DP9F",
    names: ["Phenanthrene-2-Carboxylic Acid Hydroxyamide"],
  },
  {
    primary_id: "D0DP9O",
    names: ["Autologous Chondrocyte Implantation"],
  },
  {
    primary_id: "D0DQ2D",
    names: ["RG7625"],
  },
  {
    primary_id: "D0DQ2G",
    names: ["2-Tert-Butylbenzene-1,4-Diol"],
  },
  {
    primary_id: "D0DQ2N",
    names: ["SC-72393"],
  },
  {
    primary_id: "D0DQ2T",
    names: [
      "Cu(II)ATSM",
      "Copper(II)-ATSM",
      "Copper(II)-Diacetyl-Di(N4-Methylthiosemicarbazone)",
      "Cu-ASTM",
      "Cu-Diacetyl-Bis(N4-Methylthiosemicarbazonate)",
      "Diacetylbis(N(4)-Methylthiosemicarbazonato) Copper(II)",
    ],
  },
  {
    primary_id: "D0DQ3F",
    names: ["JWH-365"],
  },
  {
    primary_id: "D0DQ3H",
    names: ["Opicinumab"],
  },
  {
    primary_id: "D0DQ4D",
    names: [
      "(3r)-1-Acetyl-3-Methylpiperidine",
      "(3R)-1-ACETYL-3-METHYLPIPERIDINE",
      "1-[(3R)-3-Methylpiperidin-1-Yl]ethanone",
      "1P3",
      "1w8l",
      "AC1L9MR1",
      "SCHEMBL4309663",
      "ZINC389643",
      "DB01742",
    ],
  },
  {
    primary_id: "D0DQ5G",
    names: ["Pyrrolo[3,2-C]pyridine Derivative 1"],
  },
  {
    primary_id: "D0DQ6C",
    names: ["Fused Heterocyclic Compound 5"],
  },
  {
    primary_id: "D0DQ6N",
    names: ["PMID28394193-Compound-38"],
  },
  {
    primary_id: "D0DQ6Z",
    names: [
      "1,4-Naphthoquinone",
      "1,4-NAPHTHOQUINONE",
      "130-15-4",
      "Naphthalene-1,4-Dione",
      "P-Naphthoquinone",
      "NAPHTHOQUINONE",
      "Alpha-Naphthoquinone",
      "1,4-Naphthylquinone",
      "USAF CY-10",
      "1,4-Dihydronaphthalene-1,4-Dione",
      "1,4-Dihydro-1,4-Diketonaphthalene",
      "1,4-Naftochinon",
      "RCRA Waste Number U166",
      "UNII-RBF5ZU7R7K",
      "NSC 9583",
      "1,4-Naftochinon [Czech]",
      "1,4-Naphthaquinone",
      "CCRIS 5424",
      "HSDB 2037",
      "EINECS 204-977-6",
      "RBF5ZU7R7K",
      "RCRA Waste No U166",
      "CHEMBL55934",
      "AI3-24292",
      "NQ-1",
      "CHEBI:27418",
      "NSC9583",
      "FRASJONUBLZVQX-UHFFFAOYSA-N",
      "MFCD00001676",
      "1,4-Naphtho-Quinone",
    ],
  },
  {
    primary_id: "D0DQ7T",
    names: ["Sporidin-G"],
  },
  {
    primary_id: "D0DQ7V",
    names: ["Lutetium Lu 177 Dotatate"],
  },
  {
    primary_id: "D0DQ9N",
    names: [
      "Fibrovax",
      "Cancer Vaccine, Cytokine",
      "Fibrovax, Cytokine",
      "Ex-Vivo Cancer Therapy, Cytokine",
      "Vaccine (Cancer), Cytokine",
    ],
  },
  {
    primary_id: "D0DR0P",
    names: ["ARQ 761"],
  },
  {
    primary_id: "D0DR0S",
    names: ["Pyridine Derivative 15"],
  },
  {
    primary_id: "D0DR3I",
    names: ["S-33516"],
  },
  {
    primary_id: "D0DR3J",
    names: ["ISIS 103752"],
  },
  {
    primary_id: "D0DR3T",
    names: ["Ad.hIL-12"],
  },
  {
    primary_id: "D0DR3X",
    names: ["Ac-I[CV(2Igl)QDWGAHRC]T"],
  },
  {
    primary_id: "D0DR4O",
    names: ["SRF231"],
  },
  {
    primary_id: "D0DR4W",
    names: ["MyoCell SDF-1", "Autologous Skeletal Myoblast Therapy (SDF-1, Cardiovascular Disease), Bioheart"],
  },
  {
    primary_id: "D0DR6N",
    names: ["CART-19 Cells"],
  },
  {
    primary_id: "D0DR6Q",
    names: ["Diphtheria, Tetanus, Acellular Pertussis Vaccine"],
  },
  {
    primary_id: "D0DR7A",
    names: [
      "N-P-Tolyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL590538",
      "AC1LIMLV",
      "BAS 04087749",
      "Oprea1_350347",
      "MolPort-001-995-161",
      "ZINC527341",
      "STK144993",
      "BDBM50308404",
      "AKOS000643478",
      "MCULE-6324715644",
      "ST50274213",
      "Phenothiazine-10-Carboxylic Acid P-Tolylamide",
      "N-(4-Methylphenyl)Phenothiazine-10-Carboxamide",
      "N-P-Tolyl-1''H-Phenothiazine-1''-Carboxamide",
      "AB00120046-01",
      "N-(4-Methylphenyl)Phenothiazin-10-Ylcarboxamide",
      "N-(4-Methylphenyl)-10H-Phenothiazine-10-Carboxamide",
    ],
  },
  {
    primary_id: "D0DR7W",
    names: ["Seco-Exiguamine"],
  },
  {
    primary_id: "D0DR8N",
    names: [
      "AZAKENPAULLONE",
      "1-Azakenpaullone",
      "676596-65-9",
      "Azakenpaullone",
      "9-Bromo-7,12-Dihydropyrido[3',2':2,3]azepino[4,5-B]indol-6(5H)-One",
      "C15H10BrN3O",
      "Kinome_3492",
      "1-AKP",
      "SCHEMBL378920",
      "GTPL8018",
      "CHEMBL336961",
      "BDBM7497",
      "DTXSID8042686",
      "NOCAS_42686",
      "CTK8F0375",
      "MolPort-003-844-675",
      "CHEBI:131490",
      "HMS3653A17",
      "HMS3229B07",
      "BCP21061",
      "ZINC13588927",
      "S7193",
      "2138AH",
      "1-Azakenpaullone, &gt",
      "AKOS030240424",
      "SB19270",
      "TRA0006688",
      "NCGC00386322-01",
      "RT-006179",
      "FT-0662368",
      "SW220021-1",
      "KS-00001866",
    ],
  },
  {
    primary_id: "D0DS0O",
    names: ["1-Methoxy-3-(Oxiran-2-Ylmethoxy)-9H-Xanthen-9-One", "CHEMBL1242450", "SCHEMBL11029477"],
  },
  {
    primary_id: "D0DS1L",
    names: ["AK-20"],
  },
  {
    primary_id: "D0DS2M",
    names: ["PrLL-37"],
  },
  {
    primary_id: "D0DS2V",
    names: ["ISIS 6434"],
  },
  {
    primary_id: "D0DS3J",
    names: ["18-MC"],
  },
  {
    primary_id: "D0DS4P",
    names: [
      "CGS-26393",
      "Cgs 26393",
      "Cgs-26393",
      "154116-34-4",
      "AC1L2SSW",
      "Cgs26393",
      "CHEMBL36795",
      "SCHEMBL8921559",
      "CTK4C8116",
      "DTXSID00165542",
      "2-Biphenyl-4-Yl-1-(1H-Tetrazol-5-Yl)Ethylaminoethyl Phosphonic Acid",
      "(S)-Diphenyl (((2-(1,1'-Biphenyl)-4-Yl-1-(1H-Tetrazol-5-Yl)Ethyl)Amino)Methyl)Phosphonate",
      "(1S)-N-(Diphenoxyphosphorylmethyl)-2-(4-Phenylphenyl)-1-(2H-Tetrazol-5-Yl)Ethanamine",
      "Phosphonic Acid, (((2-(1,1'-Biphenyl)-4-Yl-1-(1H-Tetrazol-5-Yl)Ethyl)Amino)Methyl)-, Diphenyl Ester, (S)-",
      "Phosphonic Acid,P-[[[(1S)-2-[1,1'-Biphenyl]-4-Yl-1-(2H-Tetraz",
    ],
  },
  {
    primary_id: "D0DS5U",
    names: ["Sodium Polystyrene Sulfonate"],
  },
  {
    primary_id: "D0DS7F",
    names: ["(S)-2-Amino-4-Phenyl-Butane-1-Thiol", "CHEMBL34963", "BDBM50078121"],
  },
  {
    primary_id: "D0DS7W",
    names: [
      "1-(3-Chloropyridin-2-Yl)-4-Tosylpiperazine",
      "Arylsulfonylpiperazine, 25",
      "CHEMBL403685",
      "SCHEMBL3217790",
      "BDBM32553",
    ],
  },
  {
    primary_id: "D0DS9D",
    names: ["Acrylamide Compound 4"],
  },
  {
    primary_id: "D0DT1E",
    names: ["Rh-Thrombin"],
  },
  {
    primary_id: "D0DT1R",
    names: ["Triapine"],
  },
  {
    primary_id: "D0DT1Y",
    names: ["WTX-101"],
  },
  {
    primary_id: "D0DT2P",
    names: ["Nafagrel"],
  },
  {
    primary_id: "D0DT2S",
    names: ["TAK-202"],
  },
  {
    primary_id: "D0DT4R",
    names: [
      "Brodimoprim-4,6-Dicarboxylate",
      "(2S)-2-[3-[2-Bromo-5-[(2,4-Diaminopyrimidin-1-Ium-5-Yl)Methyl]-3-Methoxyphenoxy]propyl]pentanedioate",
      "(2S)-2-(3-{2-Bromo-5-[(2,4-Diaminopyrimidin-1-Ium-5-Yl)Methyl]-3-Methoxyphenoxy}propyl)Pentanedioate",
      "AC1NRA8B",
    ],
  },
  {
    primary_id: "D0DT5E",
    names: [
      "Cephalothin Sodium",
      "Cephalothin Sodium W/ Dextrose In Plastic Container",
      "Cephalothin Sodium W/ Sodium Chloride In Plastic Container",
      "Keflin In Plastic Container",
    ],
  },
  {
    primary_id: "D0DT5H",
    names: ["AZD4785"],
  },
  {
    primary_id: "D0DT5I",
    names: ["PMID28766366-Compound-Scheme9EHT3356"],
  },
  {
    primary_id: "D0DT5K",
    names: [
      "2-Methoxy-1-Naphthaldehyde Thiosemicarbazone",
      "CHEMBL90867",
      "65110-22-7",
      "NSC525522",
      "AC1OBL0Z",
      "ARONIS022059",
      "SCHEMBL13954727",
      "DTXSID80425959",
      "MolPort-009-657-950",
      "MolPort-001-023-825",
      "ZINC4483101",
      "BDBM50114597",
      "STK028739",
      "AKOS000488676",
      "NSC-525522",
      "KB-94431",
      "ST035230",
      "BB0276319",
      "AN-329/40200631",
      "[(E)-(2-Methoxynaphthalen-1-Yl)Methylideneamino]thiourea",
      "{[(1E)-2-(2-Methoxynaphthyl)-1-Azavinyl]amino}aminomethane-1-Thione",
    ],
  },
  {
    primary_id: "D0DT7M",
    names: ["PMID25666693-Compound-124"],
  },
  {
    primary_id: "D0DT8Y",
    names: ["MEDI5117"],
  },
  {
    primary_id: "D0DU3J",
    names: ["5-Deoxyabyssinin II", "CHEMBL229670"],
  },
  {
    primary_id: "D0DU4B",
    names: [
      "1-Propylidenethiosemicarbazide",
      "CHEMBL452107",
      "[(Z)-Propylideneamino]thiourea",
      "Propionaldehyde Thiosemicarbazone",
      "AC1O0DHJ",
      "22042-87-1",
      "ZINC5447671",
      "BDBM50268175",
    ],
  },
  {
    primary_id: "D0DU4H",
    names: ["Peptide Analog 12"],
  },
  {
    primary_id: "D0DU4T",
    names: ["IC9-CAR19 Cells"],
  },
  {
    primary_id: "D0DU5S",
    names: ["Quinapril Analogue", "CHEMBL1162799"],
  },
  {
    primary_id: "D0DU6X",
    names: ["R-41D"],
  },
  {
    primary_id: "D0DU9I",
    names: ["RT-100"],
  },
  {
    primary_id: "D0DV2Q",
    names: ["BMN-190"],
  },
  {
    primary_id: "D0DV3O",
    names: ["Epinastine"],
  },
  {
    primary_id: "D0DV3T",
    names: ["Epoetin Zeta"],
  },
  {
    primary_id: "D0DV4F",
    names: ["RO-5285119"],
  },
  {
    primary_id: "D0DV4J",
    names: ["Beriplast-P"],
  },
  {
    primary_id: "D0DV4X",
    names: ["Quinazoline Derivative 16"],
  },
  {
    primary_id: "D0DV6D",
    names: [
      "Darolutamide",
      "1297538-32-9",
      "BAY-1841788",
      "N-((S)-1-(3-(3-Chloro-4-Cyanophenyl)-1H-Pyrazol-1-Yl)Propan-2-Yl)-5-(1-Hydroxyethyl)-1H-Pyrazole-3-Carboxamide",
      "Darolutamide [USAN]",
      "Darolutamide (JAN/USAN/INN)",
      "SCHEMBL1814935",
      "SCHEMBL13733117",
      "KS-00000TSX",
      "EX-A759",
      "BLIJXOOIHRSQRB-PXYINDEMSA-N",
      "MolPort-046-033-692",
      "MolPort-044-560-277",
      "AKOS030526387",
      "CS-5174",
      "BAY1841788",
      "DB12941",
      "BAY 1841788",
      "HY-16985",
      "S7559",
      "FT-0700158",
      "J3.501.129C",
      "D11045",
      "J-690121",
      "1H-Pyrazole-3-Carboxamide, N-((1S)-2-(3-(3-Chloro-4-Cyanophen",
    ],
  },
  {
    primary_id: "D0DV8Z",
    names: ["Elezanumab"],
  },
  {
    primary_id: "D0DV9Z",
    names: ["6-Methoxy-2,9-Dimethyl-9H-Beta-Carbolin-2-Ium", "CHEMBL385778"],
  },
  {
    primary_id: "D0DW1D",
    names: ["PMID30185082-Compound-57"],
  },
  {
    primary_id: "D0DW2F",
    names: ["5-Fluoro-6-Iodo-UMP"],
  },
  {
    primary_id: "D0DW3Z",
    names: ["PMID26815044-Compound-93"],
  },
  {
    primary_id: "D0DW4J",
    names: ["Dibenzo-P-Dioxin-2-Carboxylic Acid"],
  },
  {
    primary_id: "D0DW4P",
    names: ["PMID29473428-Compound-15"],
  },
  {
    primary_id: "D0DW5P",
    names: ["Schiff Base Compound 2"],
  },
  {
    primary_id: "D0DW6V",
    names: ["Pyrazole Derivative 67"],
  },
  {
    primary_id: "D0DW7A",
    names: ["PMID26651364-Compound-6b"],
  },
  {
    primary_id: "D0DW8G",
    names: ["Estrogen Receptor Beta-Selective Phytoestrogenic Formulation"],
  },
  {
    primary_id: "D0DW9J",
    names: [
      "1,2-Bis(4-Fluorophenyl)Ethane-1,2-Dione",
      "4,4'-Difluorobenzil",
      "579-39-5",
      "Ethanedione, Bis(4-Fluorophenyl)-",
      "4,4'-Difluorodibenzoyl",
      "CHEMBL192410",
      "1,2-Ethanedione, 1,2-Bis(4-Fluorophenyl)-",
      "BRKULQOUSCHDGS-UHFFFAOYSA-N",
      "MFCD00134541",
      "1,2-Bis(4-Fluorophenyl)-1,2-Ethanedione",
      "Difluorobenzil",
      "C14H8F2O2",
      "4,4'difluorobenzil",
      "PubChem7683",
      "ACMC-1AYLX",
      "4,4'-Difluorobibenzoyl",
      "AC1L3VST",
      "Benzil-Based Compound, 8",
      "AC1Q5E6G",
      "SCHEMBL716295",
      "4,4'-Difluorobenzil, 98%",
      "DTXSID3060374",
      "BDBM22730",
      "MolPort-000-154-273",
      "ZX-AP007762",
      "ZINC2504724",
    ],
  },
  {
    primary_id: "D0DX0G",
    names: ["Tergenpumatucel-L + Indoximod + Chemotherapy"],
  },
  {
    primary_id: "D0DX0R",
    names: ["ISOPILINE"],
  },
  {
    primary_id: "D0DX1F",
    names: ["Leber's Congenital Amaurosis Gene Therapy"],
  },
  {
    primary_id: "D0DX3V",
    names: [
      "7-Ethoxy-1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One",
      "CHEMBL98812",
      "SCHEMBL10760128",
      "2H-Imidazo[4,5-B]quinolin-2-One, 7-Ethoxy-1,3-Dihydro-",
      "BDBM50229572",
    ],
  },
  {
    primary_id: "D0DX4J",
    names: ["P1446A-05"],
  },
  {
    primary_id: "D0DX4W",
    names: ["ISIS 11221"],
  },
  {
    primary_id: "D0DX6L",
    names: ["SDZ-PSD-958"],
  },
  {
    primary_id: "D0DX6U",
    names: ["PMID19931453C16m"],
  },
  {
    primary_id: "D0DX8A",
    names: [
      "6-Chloro-5-Propylpyrimidine-2,4(1H,3H)-Dione",
      "19030-76-3",
      "5-Propyl-6-Chloro-Uracil",
      "CHEMBL394425",
      "BDBM20055",
      "5-Substituted-6-Chlorouracil, 5b",
    ],
  },
  {
    primary_id: "D0DX8P",
    names: ["PMID26004420-Compound-WO2010129379B"],
  },
  {
    primary_id: "D0DX8T",
    names: ["GSK4716"],
  },
  {
    primary_id: "D0DX9T",
    names: ["Skin Tissue Replacement Therapy"],
  },
  {
    primary_id: "D0DY1C",
    names: ["9-Mercaptomethyl-10-Oxo-Azecane-2-Carboxylic Acid", "CHEMBL333720", "SCHEMBL7391643"],
  },
  {
    primary_id: "D0DY1N",
    names: [
      "AMT-080",
      "AAV-Gene Therapy (Duchenne Muscular Dystrophy), AMT",
      "Exon-Skipping Dystrophin Gene Therapy (Duchenne Muscular Dystrophy, SnRNA), AMT",
    ],
  },
  {
    primary_id: "D0DY3A",
    names: ["Peptide Analog 36"],
  },
  {
    primary_id: "D0DY3E",
    names: ["Indoline Derivative 3"],
  },
  {
    primary_id: "D0DY4E",
    names: ["JNJ-17203212"],
  },
  {
    primary_id: "D0DY5G",
    names: ["SB 221284"],
  },
  {
    primary_id: "D0DY5R",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 8"],
  },
  {
    primary_id: "D0DY5Y",
    names: ["Antithrombin III, Aventis Behring", "AT-III", "Kybernin-500", "Kybernin-P"],
  },
  {
    primary_id: "D0DY7W",
    names: ["Nebracetam"],
  },
  {
    primary_id: "D0DY9K",
    names: ["BAX335"],
  },
  {
    primary_id: "D0DY9U",
    names: ["3-Mercapto-N-(4-Sulfamoyl-Phenyl)-Propionamide"],
  },
  {
    primary_id: "D0DZ0M",
    names: ["Pyrrolidine Derivative 1"],
  },
  {
    primary_id: "D0DZ1K",
    names: ["PSB-0952", "CHEMBL590956"],
  },
  {
    primary_id: "D0DZ3X",
    names: [
      "L-Proline",
      "Carboxypyrrolidine",
      "Pro",
      "Prolina",
      "Prolinum",
      "Prolina [Spanish]",
      "Prolinum [Latin]",
      "CB 1707",
      "L-Prolin",
      "PRO (IUPACabbreviation)",
      "Proline (USP)",
      "Proline (VAN)",
      "Proline [USAN:INN]",
      "H-Pro-OH",
      "L-Proline (JAN)",
      "L-Proline-15N",
      "L-Alpha-Pyrrolidinecarboxylic Acid",
      "L-Proline, Labeled With Carbon-14",
      "L-Pyrrolidine-2-Carboxylic Acid",
      "L-(2,3-3H)Proline",
      "(-)-(S)-Proline",
      "(-)-2-Pyrrolidinecarboxylic Acid",
      "(-)-Proline (S)-2-Carboxypyrrolidine",
      "(2S)-Pyrrolidine-2-Carboxylic Acid",
      "(S)-2-Carboxypyrrolidine",
      "(S)-2-Pyrrolidinecarboxylic Acid",
      "(S)-Proline",
      "(S)-Pyrrolidine-2-Carboxylic Acid",
      "2-Pyrrolidinecarboxylic Acid",
    ],
  },
  {
    primary_id: "D0DZ5H",
    names: ["PMID30185082-Compound-54"],
  },
  {
    primary_id: "D0E0AA",
    names: [
      "N-(7-(2-CHLOROPHENYL)-6-(4-CHLOROPHENYL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDIN-4-YL)-2-HYDROXYACETAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1085261",
      "SCHEMBL1404507",
      "BDBM50320173",
    ],
  },
  {
    primary_id: "D0E0AS",
    names: ["(S)-Oxybutynin"],
  },
  {
    primary_id: "D0E0AX",
    names: ["AP-611074", "AP-611 Series (Condyloma), Anaconda"],
  },
  {
    primary_id: "D0E0BD",
    names: ["Glycerol Phenylbutyrate"],
  },
  {
    primary_id: "D0E0BE",
    names: ["Na-APR-1"],
  },
  {
    primary_id: "D0E0BU",
    names: [
      "6-(Oxalyl-Amino)-1h-Indole-5-Carboxylic Acid",
      "6-(OXALYL-AMINO)-1H-INDOLE-5-CARBOXYLIC ACID",
      "CHEMBL342478",
      "243967-44-4",
      "1H-Indole-5-Carboxylic Acid, 6-[(Carboxycarbonyl)Amino]-",
      "6-(Carboxyformamido)-1H-Indole-5-Carboxylic Acid",
      "OAI",
      "AC1L9GUL",
      "SCHEMBL4318827",
      "CTK0J4951",
      "DTXSID50332166",
      "1c83",
      "BDBM50118796",
      "DB04001",
      "N-(5-Carboxy-1H-Indole-6-Yl)Oxamidic Acid",
      "6-(Oxaloamino)-1H-Indole-5-Carboxylic Acid",
      "6-[(Carboxycarbonyl)Amino]-1H-Indole-5-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0E0CT",
    names: ["Trimebutine"],
  },
  {
    primary_id: "D0E0CV",
    names: [
      "(10r)-10-Formyl-5,8,10-Trideazafolic Acid",
      "(10R)-10-FORMYL-5,8,10-TRIDEAZAFOLIC ACID",
      "NHR",
      "2-{4-[2-(2-AMINO-4-HYDROXY-QUINAZOLIN-6-YL)-1-CARBOXY-ETHYL]-BENZOYLAMINO}-PENTANEDIOIC ACID",
      "1c3e",
      "AC1L9GSR",
      "DB04264",
      "8937-EP2308866A1",
      "8937-EP2308846A2",
      "8937-EP2305697A2",
      "8937-EP2298780A1",
      "8937-EP2277872A1",
      "8937-EP2316829A1",
      "8937-EP2308872A1",
      "8937-EP2308845A2",
      "8937-EP2305698A2",
      "8937-EP2305695A2",
      "8937-EP2301932A1",
      "8937-EP2287167A1",
      "8937-EP2283898A1",
      "8937-EP2281563A1",
      "8937-EP2316459A1",
      "8937-EP2308844A2",
      "8937-EP2277869A1",
      "8937-EP2270018A1",
      "8937-EP2308812A2",
      "8937-EP2305696A2",
    ],
  },
  {
    primary_id: "D0E0DL",
    names: ["ADX40-TB"],
  },
  {
    primary_id: "D0E0DQ",
    names: [
      "NSC-645808",
      "CHEMBL124374",
      "5-{[2-(Dimethylamino)Ethyl]amino}-8-Hydroxy-6H-Imidazo[4,5,1-De]acridin-6-One",
      "NCI60_015697",
      "AC1Q6J9E",
      "Neuro_000341",
      "AC1L832L",
      "SCHEMBL13526627",
      "CTK6I0981",
      "ZINC5502555",
      "SR-01000882454",
    ],
  },
  {
    primary_id: "D0E0DR",
    names: ["N-(3-(2-Aminoethyl)Phenyl)Benzenesulfonamide", "CHEMBL212063", "BDBM50193463"],
  },
  {
    primary_id: "D0E0DS",
    names: ["Box-5"],
  },
  {
    primary_id: "D0E0DY",
    names: ["Decyl Dihydrogen Phosphate"],
  },
  {
    primary_id: "D0E0EF",
    names: ["Terpyridineplatinum(II) Complexe 5"],
  },
  {
    primary_id: "D0E0EQ",
    names: [
      "2-Methylbut-3-Yn-2-Yl 4-Methoxybenzoate",
      "294619-87-7",
      "1,1-Dimethyl-2-Propyn-1-Yl 4-Methoxybenzoate",
      "ZERO/003209",
      "AC1LE8OG",
      "ARONIS020757",
      "CHEMBL244918",
      "ZINC96414",
      "BDBM23726",
      "KS-00004CEK",
      "MolPort-001-510-482",
      "BBL014263",
      "STK001780",
      "AKOS000487782",
      "MCULE-8443375339",
      "ST036671",
      "1,1-Dimethylprop-2-Ynyl 4-Methoxybenzoate",
      "BB0275130",
      "1,1-Dimethyl-2-Propynyl 4-Methoxybenzoate",
      "H2604",
      "EU-0085447",
      "SR-01000525786",
      "AG-205/12908103",
      "SR-01000525786-1",
      "1,1-Dimethylprop-2-Yn-1-Yl 4-Methoxybenzoate, 10",
      "4-Methoxybenzoic Acid 1,1-Dimethyl-2-Propynyl Es",
    ],
  },
  {
    primary_id: "D0E0FY",
    names: [
      "CTL-102-GDEPT",
      "CTL-102",
      "NTR-GDEPT",
      "GDEPT (CTL-102), ML Laboratories",
      "Gene Directed Chemotherapy (CTL-102), ML Laboratories",
      "Ad-NTR + CB-1954 GDEPT, ML Labs",
      "Human Adenovirus (HAdV5) GDEPT (E Coli Nitroreductase (NTR)/CB-1954, Aseptic Prosthetic Implant Loosening), Broadvector",
    ],
  },
  {
    primary_id: "D0E0GC",
    names: ["LIM-0723"],
  },
  {
    primary_id: "D0E0GD",
    names: ["SR-T100 Gel"],
  },
  {
    primary_id: "D0E0GW",
    names: ["BVT-3498", "AMG-311"],
  },
  {
    primary_id: "D0E0GZ",
    names: ["TyrosineKinaseInhibitor-Ns"],
  },
  {
    primary_id: "D0E0HE",
    names: [
      "Hexyl",
      "Dipicrylamine",
      "Hexanitrodiphenylamine",
      "Aurantia",
      "Hexite",
      "Hexil",
      "Bis(2,4,6-Trinitrophenyl)Amine",
      "Hexyl (Reagent)",
      "131-73-7",
      "Esanitrodifenilamina",
      "Hexanitrodifenylamine",
      "Diphenylamine, Hexanitro-",
      "Dipikrylamin",
      "Hexamine (Potassium Reagent)",
      "Hexyl (VAN)",
      "Dipikrylamin [Czech]",
      "Hexyl [German, Dutch]",
      "2,2',4,4',6,6'-HEXANITRODIPHENYLAMINE",
      "UNII-14STR4KG8T",
      "NSC 1786",
      "Hexanitrodifenylamine [Dutch]",
      "Esanitrodifenilamina [Italian]",
      "Hexanitrodiphenylamine [French]",
      "CCRIS 5347",
      "2,4,6,2',4',6'-Hexanitrodiphenylamine",
      "HSDB 2873",
      "C.I. 10360",
    ],
  },
  {
    primary_id: "D0E0HK",
    names: [
      "2-Mercaptomethyl-Pentanedioic Acid",
      "2-Mercaptomethylglutaric Acid",
      "CHEMBL60941",
      "106146-19-4",
      "Pentanedioic Acid,2-(Mercaptomethyl)-",
      "2-Mmga",
      "2-(Sulfanylmethyl)Pentanedioic Acid",
      "ACMC-1BUG7",
      "AC1L2VT3",
      "2-Mer-Captomethylglutaric Acid",
      "SCHEMBL1278279",
      "CTK4A4424",
      "Glutaric Acid, 2-Mercaptomethyl-",
      "LDFDXRPEWZHIML-UHFFFAOYSA-N",
      "NSC613827",
      "BDBM50127614",
    ],
  },
  {
    primary_id: "D0E0HT",
    names: [
      "2-(2-Allyl-4-Chlorophenoxy)Acetic Acid",
      "CHEMBL245707",
      "6964-26-7",
      "(2-Allyl-4-Chloro-Phenoxy)-Acetic Acid",
      "NSC66309",
      "[4-Chloro-2-(Prop-2-En-1-Yl)Phenoxy]acetic Acid",
      "AC1Q3LUZ",
      "2-(4-Chloro-2-Prop-2-Enylphenoxy)Acetic Acid",
      "AC1L6N6K",
      "SCHEMBL4879038",
      "CTK5D0709",
      "DTXSID60290061",
      "NSC-66309",
      "BDBM50213925",
      "AKOS005352797",
      "Acetic Acid,2-[4-Chloro-2-(2-Propen-1-Yl)Phenoxy]-",
    ],
  },
  {
    primary_id: "D0E0IG",
    names: [
      "TRX-385",
      "Anti-ILT3 MAb, TolerRx",
      "Anti-ILT3 MAb (Immune Disorder), Tolerx",
      "Anti-ILT3 Monoclonal Antibody (Immune Disorder), TolerRx",
      "Anti-ILT3 MAb (Cancer/Viral Infections), Tolerx",
      "Anti-ILT3 Monoclonal Antibody (Cancer/Viral Infections), Tolerx",
    ],
  },
  {
    primary_id: "D0E0IK",
    names: ["Adva-R46"],
  },
  {
    primary_id: "D0E0JC",
    names: [
      "7-Phenyl-1-(5-Phenyloxazol-2-Yl)Heptan-1-One",
      "CHEMBL173992",
      "7-Phenyl-1-(5-Phenyl-1,3-Oxazol-2-Yl)Heptan-1-One",
      "BDBM50163187",
    ],
  },
  {
    primary_id: "D0E0JF",
    names: ["Pirazolac"],
  },
  {
    primary_id: "D0E0JN",
    names: ["CEPHARANTINE", "Cepharantine", "CHEMBL500862", "BDBM50242977"],
  },
  {
    primary_id: "D0E0JO",
    names: ["GLPG-1837"],
  },
  {
    primary_id: "D0E0JU",
    names: ["JWH-401"],
  },
  {
    primary_id: "D0E0JV",
    names: ["CDRI-S007-867"],
  },
  {
    primary_id: "D0E0KG",
    names: [
      "(R)-3-Methyl-1,2,3,4-Tetrahydro-Isoquinoline",
      "(R)-3-Methyl-1,2,3,4-Tetrahydroisoquinoline",
      "179893-97-1",
      "CHEMBL293023",
      "(3R)-3-Methyl-1,2,3,4-Tetrahydroisoquinoline",
      "AC1OM1IT",
      "SCHEMBL1690519",
      "Isoquinoline, 1,2,3,4-Tetrahydro-3-Methyl-, (3R)-",
      "UEKQPSAKUNXFHL-MRVPVSSYSA-N",
      "ZINC19928405",
      "FCH952510",
      "BDBM50052885",
      "AKOS024259472",
      "AJ-75700",
    ],
  },
  {
    primary_id: "D0E0KQ",
    names: ["Pyronaridine"],
  },
  {
    primary_id: "D0E0LI",
    names: [
      "Amino-Propylphosphinic Acid",
      "Diadenosine Diphosphate",
      "AC1L4MLM",
      "[({[(2R,3S,4R,5R)-5-(6-Amino-9H-Purin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy}(Hydroxy)Phosphoryl)Oxy]({[(2R,3S,4R,5S)-5-(6-Amino-9H-Purin-9-Yl)-4-Hydroxy-2-(Hydroxymethyl)Oxolan-3-Yl]oxy})Phosphinic Acid",
      "[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] [(2R,3S,4R,5S)-5-(6-Aminopurin-9-Yl)-4-Hydroxy-2-(Hydroxymethyl)Oxolan-3-Yl] Hydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0E0LL",
    names: ["Haloxysterol C"],
  },
  {
    primary_id: "D0E0LQ",
    names: ["Thiothixene"],
  },
  {
    primary_id: "D0E0MB",
    names: ["TG-4040"],
  },
  {
    primary_id: "D0E0MH",
    names: ["PMID30259754-Compound-INT-767"],
  },
  {
    primary_id: "D0E0MU",
    names: ["AstraStim"],
  },
  {
    primary_id: "D0E0NL",
    names: ["TRX-1", "Anti-CD4 Mab, TRX-1(IV), TRX-1 (SC)"],
  },
  {
    primary_id: "D0E0NM",
    names: ["DCC-2036"],
  },
  {
    primary_id: "D0E0OF",
    names: ["Salvinorin B Tert-Butoxymethyl Ether", "CHEMBL271285"],
  },
  {
    primary_id: "D0E0OG",
    names: [
      "Lorazepam",
      "Almazine",
      "Alzapam",
      "Anxiedin",
      "Anxira",
      "Anzepam",
      "Aplacasse",
      "Aplacassee",
      "ApoLorazepam",
      "Aripax",
      "Ativan",
      "Azurogen",
      "Bonatranquan",
      "Bonton",
      "Delormetazepam",
      "Demethyllormetazepam",
      "Donix",
      "Duralozam",
      "Durazolam",
      "Efasedan",
      "Emotion",
      "Emotival",
      "Equitam",
      "Idalprem",
      "Kalmalin",
      "Larpose",
      "Laubeel",
      "Lomesta",
      "Lopam",
      "Lorabenz",
      "Lorafen",
      "Loram",
      "Lorans",
      "Lorapam",
      "Lorat",
      "Lorax",
      "Loraz",
      "Lorazene",
      "Lorazep",
      "Lorazepamum",
      "Lorazin",
      "Lorazon",
      "Lorenin",
      "Loridem",
      "Lorivan",
      "Lorsedal",
      "Lorsilan",
      "Lorzem",
      "Lozepam",
      "Merlit",
      "Nervistopl",
      "Norlormetazepam",
      "Novhepar",
      "Novolorazem",
      "NuLoraz",
      "Orfidal",
      "Punktyl",
      "Quait",
      "Renaquil",
      "Rocosgen",
      "Securit",
      "Sedatival",
      "Sedazin",
      "Sedicepan",
      "Sedizepan",
      "Sidenar",
      "Silence",
      "Sinestron",
      "Somagerol",
      "Stapam",
      "Tavor",
      "Temesta",
      "Tolid",
      "Tranqipam",
      "Trapax",
      "Upan",
      "Vigiten",
      "Wypax",
      "AHP Brand Of Lorazepam",
      "Apo Lorazepam",
      "Apotex Brand Of Lorazepam",
      "Baxter Brand Of Lorazepam",
      "Ct Arzneimittel Brand Of Lorazepam",
      "Desitin Brand Of Lorazepam",
      "Dolorgiet Brand Of Lorazepam",
      "Llorens Brand Of Lorazepam",
      "Lorazep Von Ct",
      "Lorazepam AHP Brand",
      "Lorazepam Apotex Brand",
      "Lorazepam Baxter Brand",
      "Lorazepam Desitin Brand",
      "Lorazepam Dolorgiet Brand",
      "Lorazepam Fabra",
      "Lorazepam Genericon",
      "Lorazepam Intensol",
      "Lorazepam Lannacher",
      "Lorazepam Llorens Brand",
      "Lorazepam Medical",
      "Lorazepam Medical Brand",
      "Lorazepam Medix Brand",
      "Lorazepam Novartis Brand",
      "Lorazepam Novopharm Brand",
      "Lorazepam Riemser Brand",
      "Lorazepam Wyeth Brand",
      "Lorazepam Neuraxpharm",
      "Lorazepam Neuraxpharm Brand",
      "Lorazepam Preservative Free",
      "Lorazepam Ratiopharm",
      "Lorazepam Ratiopharm Brand",
      "Lorazepan Chobet",
      "Lorazepan Richet",
      "Max Pax",
      "Medical Brand Of Lorazepam",
      "Medix Brand Of Lorazepam",
      "Merck Dura Brand Of Lorazepam",
      "Nervistop L",
      "Neuraxpharm Brand Of Lorazepam",
      "Novartis Brand Of Lorazepam",
      "Novo Lorazem",
      "Novopharm Brand Of Lorazepam",
      "Nu Loraz",
      "Nu Pharm Brand Of Lorazepam",
      "Orfidal Wyeth",
      "Pro Dorm",
      "Ratiopharm Brand Of Lorazepam",
      "Riemser Brand Of Lorazepam",
      "Serra Pamies Brand Of Lorazepam",
      "Wyeth Brand Of Lorazepam",
      "L1764_SIGMA",
      "WY4036",
      "Wy 4036",
      "Apo-Lorazepam",
      "Ativan (TN)",
      "Ct-Arzneimittel Brand Of Lorazepam",
      "Lorazepam (Ativan)",
      "Lorazepam Nu-Pharm Brand",
      "Lorazepam Ct-Arzneimittel Brand",
      "Lorazepam-Efeka",
      "Lorazepam-Neuraxpharm",
      "Lorazepam-Ratiopharm",
      "Lorazepamum [INN-Latin]",
      "Medical, Lorazepam",
      "Novo-Lorazem",
      "Nu-Loraz",
      "Nu-Pharm Brand Of Lorazepam",
      "O-Chlorooxazepam",
      "O-Chloroxazepam",
      "Temesta (TN)",
      "Wy-4036",
      "Wyeth, Orfidal",
      "Lorazepam (JP15/USP/INN)",
      "Lorazepam [USAN:INN:BAN:JAN]",
      "(+-)-7-Chloro-5-(O-Chlorophenyl)-1,3-Dihydro-3-Hydroxy-2H-1,4-Benzodiazepin-2-One",
      "(+/-)-Lorazepam",
      "7-Chloro-5-(2-Chlorophenyl)-1,3-Dihydro-3-Hydroxy-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-5-(2-Chlorophenyl)-3-Hydroxy-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-5-(2-Chlorophenyl)-3-Hydroxy-1H-1,4-Benzodiazepin-2(3H)-One",
      "7-Chloro-5-(O-Chlorophenyl)-1,3-Dihydro-3-Hydroxy-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-5-(2-Chlorophenyl)-3-Hydroxy-1,3-Dihydro-1,4-Benzodiazepin-2-One",
    ],
  },
  {
    primary_id: "D0E0OI",
    names: ["Memex"],
  },
  {
    primary_id: "D0E0OJ",
    names: [
      "DEOXYADENOSINE",
      "CHEMBL416340",
      "9H-Purin-6-Amine, 9-(2-Deoxy-.beta.-D-Ribofuranosyl)-",
      "9-(2-Deoxypentofuranosyl)-9H-Purin-6-Amine",
      ".beta.-D-Erythro-Pentofuranoside, Adenine-9 2-Deoxy-",
      ".beta.-D-Ribofuranose,2-Dideoxy-",
      "9H-Purin-6-Amine, 9-(2-Deoxy-.beta.-D-Erythro-Pentofuranosyl)-",
      "SR-01000397552",
      "13276-53-4",
      "DAdo",
    ],
  },
  {
    primary_id: "D0E0PD",
    names: ["Pyruvic"],
  },
  {
    primary_id: "D0E0QG",
    names: ["THIOCITRULLINE"],
  },
  {
    primary_id: "D0E0QS",
    names: ["VM-301"],
  },
  {
    primary_id: "D0E0QT",
    names: ["8-Nitro-6H,11H-Indeno[1,2-C]isoquinolin-5-One"],
  },
  {
    primary_id: "D0E0RP",
    names: ["CART20"],
  },
  {
    primary_id: "D0E0RW",
    names: ["ISOSCOPOLETIN"],
  },
  {
    primary_id: "D0E0RY",
    names: ["Melitracen"],
  },
  {
    primary_id: "D0E0SA",
    names: ["PMID25666693-Compound-110"],
  },
  {
    primary_id: "D0E0SH",
    names: ["CART-117 Cells"],
  },
  {
    primary_id: "D0E0ST",
    names: ["Tisokinase"],
  },
  {
    primary_id: "D0E0SW",
    names: ["Enoximone"],
  },
  {
    primary_id: "D0E0TA",
    names: ["N-(4-Hydroxybiphenyl-3-Yl)Benzamide", "CHEMBL269935", "SCHEMBL5724398", "BDBM50232005"],
  },
  {
    primary_id: "D0E0TD",
    names: [
      "4-(4-((Phenethylamino)Methyl)Phenoxy)Benzamide",
      "CHEMBL238317",
      "SCHEMBL3692341",
      "FARQYUHOSNLRKV-UHFFFAOYSA-N",
      "BDBM50219921",
    ],
  },
  {
    primary_id: "D0E0TK",
    names: ["Malate Like Intermediate"],
  },
  {
    primary_id: "D0E0UC",
    names: ["DSR-17759"],
  },
  {
    primary_id: "D0E0UN",
    names: ["PC-22", "Anti-Cancer Agent (2), ProCell Therapeutics"],
  },
  {
    primary_id: "D0E0VN",
    names: [
      "6-Deoxy-Alpha-D-Glucose",
      "Alpha-D-Quinovopyranose",
      "6-Deoxy-Alpha-D-Glucopyranose",
      "UNII-68BDH91OBS",
      "68BDH91OBS",
      "Isorhodeose",
      "Isorhamnose",
      "D-Epifucose",
      "D-Glucomethylose",
      "D-Chinovose",
      "6-DEOXY-ALPHA-D-GLUCOSE",
      "551-63-3",
      "Alpha-D-Quinovose",
      "3h-6-Deoxyglucose",
      "Alpha-D-Quinovose Pyranose",
      "AC1L9B8Y",
      "SCHEMBL79180",
      "CHEBI:42606",
      "Alpha-D-Glucopyranose, 6-Deoxy-",
      "ZINC2042980",
      "DB03773",
      "C08352",
      "(2S,3R,4S,5S,6R)-6-Methyloxane-2,3,4,5-Tetrol",
    ],
  },
  {
    primary_id: "D0E0WL",
    names: [
      "CCR5 Receptor Inhibitors",
      "CCR5 Receptor Inhibitors (HIV Infection)",
      "CCR5 Antagonists (HIV Infection), Kemia",
      "CCR5 Receptor (HIV Infection), Kemia",
      "CCR5 Receptor Inhibitors (HIV Infection), Kemia",
    ],
  },
  {
    primary_id: "D0E0WQ",
    names: [
      "Barbital",
      "Barbitale",
      "Barbitalum",
      "Barbitone",
      "Barbitonum",
      "DEBA",
      "Diemal",
      "Diemalum",
      "Diethylbarbitone",
      "Diethylmalonylurea",
      "Dormileno",
      "Dormonal",
      "Ethylbarbital",
      "Hypnogene",
      "Malonal",
      "Medinal",
      "Sedeval",
      "Uronal",
      "Veroletten",
      "Verolettin",
      "Veronal",
      "Vesperal",
      "Barbital Faes Brand",
      "Barbitale [DCIT]",
      "Diethylbarbituric Acid",
      "Faes Brand Of Barbital",
      "Barbital (TN)",
      "Barbital (VAN)",
      "Barbital [INN:JAN]",
      "Barbitalum [INN-Latin]",
      "Veronal (TN)",
      "Barbital (JP15/INN)",
      "Kyselina 5,5-Diethylbarbiturova",
      "Kyselina 5,5-Diethylbarbiturova [Czech]",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5,5-Diethyl-(9CI)",
      "5,5-Diethyl-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5,5-Diethylbarbituric Acid",
      "5,5-Diethyl-1,3-Diazinane-2,4,6-Trione",
      "5,5-Diethylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "7979P",
    ],
  },
  {
    primary_id: "D0E0WU",
    names: ["TRIN-2755"],
  },
  {
    primary_id: "D0E0WX",
    names: ["ISIS 28472"],
  },
  {
    primary_id: "D0E0XL",
    names: ["QMF149"],
  },
  {
    primary_id: "D0E0XV",
    names: ["TPCA-1"],
  },
  {
    primary_id: "D0E0YW",
    names: [
      "DS-1062",
      "AUTFGCQEAOOQAR-UHFFFAOYSA-N",
      "Ethyl 5-(Chloromethyl)-1,2,4-Oxadiazole-3-Carboxylate",
      "1009620-97-6",
      "Ethyl5-(Chloromethyl)-1,2,4-Oxadiazole-3-Carboxylate",
      "SCHEMBL3214456",
      "KS-00000HYY",
      "CTK3J9399",
      "DTXSID80654863",
      "MolPort-009-199-811",
      "ZINC43220516",
      "CE-804",
      "ANW-49321",
      "SBB091191",
      "AKOS005146382",
      "RP25017",
      "CJ-20372",
      "SC-14657",
      "BR-40600",
      "AN-25976",
      "TC-061709",
      "KB-202058",
      "DB-026590",
      "AJ-108509",
      "KB-111885",
      "AB0001145",
      "FT-0083498",
      "4CH-008814",
      "X8538",
      "ST24030632",
      "FT-0651464",
      "AM20070658",
      "S-5410",
      "A15053",
      "J-521069",
      "Ethyl 5-Chloromethyl [1,2,4]oxadiazole-3-Carboxylate",
      "I14-10208",
      "Ethyl 5",
    ],
  },
  {
    primary_id: "D0E0ZR",
    names: ["Rimonabant"],
  },
  {
    primary_id: "D0E0ZS",
    names: ["N-(4'-Isonicotinoylbiphenyl-3-Yl)Acetamide", "CHEMBL1215730", "BDBM50324614"],
  },
  {
    primary_id: "D0E0ZV",
    names: ["3-(2-N-Piperidyl-Acetamino)-Rutaecarpine", "CHEMBL1097952"],
  },
  {
    primary_id: "D0E1AN",
    names: ["MINT-1526A"],
  },
  {
    primary_id: "D0E1AW",
    names: ["GC5101B"],
  },
  {
    primary_id: "D0E1AZ",
    names: [
      "ISU-201",
      "Anti-Inflammatory MAb (Inflammatory Disease), Isu Abxis",
      "Anti-Inflammatory Antibody (Autoimmune/Inflammatory Diseases), Isu Abxis",
    ],
  },
  {
    primary_id: "D0E1BR",
    names: ["PMID19191557C35"],
  },
  {
    primary_id: "D0E1DB",
    names: [
      "5-Aminomethyl-4,5-Dihydro-Isoxazol-3-Ol",
      "Dihydromuscimol",
      "72241-46-4",
      "CHEMBL40363",
      "(RS)-4,5-Dihydromuscimol",
      "5-(Aminomethyl)-1,2-Oxazolidin-3-One",
      "3-Isoxazolol, 5-(Aminomethyl)-4,5-Dihydro-",
      "AC1L4Y9G",
      "SCHEMBL2924224",
      "AC1Q6H18",
      "SCHEMBL12477033",
      "CTK5D5767",
      "5-Aminomethyl-Isoxazolidin-3-One",
      "ZHCZZTNIHDWRNS-UHFFFAOYSA-N",
      "5-(Aminomethyl)-3-Isoxazolidinone",
      "3-Isoxazolidinone,5-(Aminomethyl)-",
      "BDBM50225363",
      "3-Isoxazolidinone, 5-(Aminomethyl)-",
    ],
  },
  {
    primary_id: "D0E1DF",
    names: ["Afrezza", "Afrezza (TN)"],
  },
  {
    primary_id: "D0E1EC",
    names: ["Ranatensin"],
  },
  {
    primary_id: "D0E1FP",
    names: ["Interferon Alpha 5"],
  },
  {
    primary_id: "D0E1FQ",
    names: ["Dmt-Pro-Imp-Phe-NH2", "CHEMBL228516"],
  },
  {
    primary_id: "D0E1GC",
    names: ["PMID26651364-Compound-5g"],
  },
  {
    primary_id: "D0E1HC",
    names: ["Tezacaftor And Ivacaftor"],
  },
  {
    primary_id: "D0E1HF",
    names: ["FFC-14A"],
  },
  {
    primary_id: "D0E1HP",
    names: [
      "3,3,3-Tris(4-Chlorophenyl)Propanoic Acid",
      "3,3,3-Tris(4-Chlorophenyl)Propionic Acid",
      "2168/6/1",
      "3,3,3-Tris(P-Chlorophenyl)Propionic Acid",
      "CHEMBL201593",
      "AK-23664",
      "3,3,3-Tris-(4-Chlorophenyl)Propionic Acid",
      "3,3,3-TRIS(4-CHLOROPHENYL)PROPIONIS ACID",
      "W-107531",
      "Rarechem Al Bo 1263",
      "AC1Q3NC9",
      "ACMC-1CB39",
      "SCHEMBL503944",
      "C21H15Cl3O2",
      "AC1L2O20",
      "LHIVWYJOCNGZRI-UHFFFAOYSA-",
      "CTK4E7478",
      "DTXSID30176065",
      "LHIVWYJOCNGZRI-UHFFFAOYSA-N",
      "MolPort-003-928-967",
      "3,3,3-Tris-(4-Chlorophenyl)-Pr",
      "ZINC4529217",
      "KS-00000NC6",
      "EINECS 218-509-3",
      "CT-098",
    ],
  },
  {
    primary_id: "D0E1HY",
    names: ["GPCR39 Pepducins"],
  },
  {
    primary_id: "D0E1IO",
    names: ["N-Carbamoyl-L-Aspartate"],
  },
  {
    primary_id: "D0E1KS",
    names: ["Tricyclic Compound 5"],
  },
  {
    primary_id: "D0E1KX",
    names: ["Dimethylformamide"],
  },
  {
    primary_id: "D0E1LB",
    names: ["MK-2578"],
  },
  {
    primary_id: "D0E1LW",
    names: ["Immunoglobulin Intravenous"],
  },
  {
    primary_id: "D0E1NH",
    names: [
      "1-(HYDROXYMETHYLENEAMINO)-8-HYDROXY-OCTANE",
      "8-(Hydroxymethylamino)Octan-1-Ol",
      "AC1NDZBQ",
      "8-[(Hydroxymethyl)Amino]octan-1-Ol",
    ],
  },
  {
    primary_id: "D0E1NK",
    names: ["N-Hydroxy-N-Methyl-3-Phenanthren-3-Yl-Acrylamide", "CHEMBL174898", "BDBM50015157"],
  },
  {
    primary_id: "D0E1OC",
    names: ["ALX 1393"],
  },
  {
    primary_id: "D0E1OJ",
    names: ["Xenon Xe-133"],
  },
  {
    primary_id: "D0E1PL",
    names: ["[3H]NLX-112", "[3H]F13640"],
  },
  {
    primary_id: "D0E1QI",
    names: ["SU11274"],
  },
  {
    primary_id: "D0E1QP",
    names: [
      "2,3,4,9-Tetrahydro-1H-Carbazole-1-Carboxamide",
      "CHEMBL112265",
      "352549-39-4",
      "CBMicro_001045",
      "Cambridge Id 5870454",
      "AC1N6ME3",
      "Oprea1_743470",
      "SCHEMBL251128",
      "CTK1B0687",
      "DTXSID20401358",
      "MolPort-000-735-346",
      "HMS1632P07",
      "SMSF0008851",
      "STL525366",
      "BDBM50178767",
      "Carboxamido-1,2,3-Tetrahydrocarbazole",
      "AKOS004917884",
      "CB02357",
      "BIM-0000968.P001",
      "SR-01000154363",
      "SR-01000154363-1",
      "1H-Carbazole-1-Carboxamide, 2,3,4,9-Tetrahydro-",
    ],
  },
  {
    primary_id: "D0E1RT",
    names: ["ASP7373"],
  },
  {
    primary_id: "D0E1RX",
    names: ["1-[3-(2-Benzyl-Phenoxy)-Propyl]-Pyrrolidine", "CHEMBL187367", "ZINC13581632"],
  },
  {
    primary_id: "D0E1SQ",
    names: ["BIBW-22"],
  },
  {
    primary_id: "D0E1ST",
    names: ["CAR-T Cells Targeting GD2"],
  },
  {
    primary_id: "D0E1SV",
    names: ["MIQ-001"],
  },
  {
    primary_id: "D0E1SW",
    names: [
      "Acetazolamide",
      "Acetadiazol",
      "Acetamidothiadiazolesulfonamide",
      "Acetamox",
      "Acetazolam",
      "Acetazolamid",
      "Acetazolamida",
      "Acetazolamidum",
      "Acetazolamine",
      "Acetazoleamide",
      "Acetozalamide",
      "AkZol",
      "ApoAcetazolamide",
      "Atenezol",
      "Cidamex",
      "Dazamide",
      "Defiltran",
      "Dehydratin",
      "Diacarb",
      "Diakarb",
      "Diamox",
      "Didoc",
      "Diluran",
      "Diuramid",
      "Diuramide",
      "Diuriwas",
      "Diutazol",
      "Donmox",
      "Duiramid",
      "Edemox",
      "Eumicton",
      "Fonurit",
      "Glauconox",
      "Glaumox",
      "Glaupax",
      "Glupax",
      "HumaZolamide",
      "Natrionex",
      "Nephramid",
      "Nephramide",
      "Phonurit",
      "Storzolamide",
      "Vetamox",
      "Acetazolamide Apotex Brand",
      "Acetazolamide Chiesi Brand",
      "Acetazolamide Dioptic Brand",
      "Acetazolamide Grin Brand",
      "Acetazolamide ICN Brand",
      "Acetazolamide Jumer Brand",
      "Acetazolamide Llorens Brand",
      "Acetazolamide Medphano Brand",
      "Acetazolamide Novopharm Brand",
      "Acetazolamide Orion Brand",
      "Acetazolamide Wassermann Brand",
      "Ak Zol",
      "Apo Acetazolamide",
      "Apotex Brand Of Acetazolamide",
      "Chiesi Brand Of Acetazolamide",
      "Ciba Vision Brand Of Acetazolamide",
      "DiamoxSequels",
      "Dioptic Brand Of Acetazolamide",
      "Grin Brand Of Acetazolamide",
      "Huma Zolamide",
      "ICN Brand Of Acetazolamide",
      "Jumer Brand Of Acetazolamide",
      "Llorens Brand Of Acetazolamide",
      "Medphano Brand Of Acetazolamide",
      "Monosodium Salt Acetazolamide",
      "Novopharm Brand Of Acetazolamide",
      "Orion Brand Of Acetazolamide",
      "Storz Brand Of Acetazolamide Preparation",
      "Wassermann Brand Of Acetazolamide",
      "Wyeth Brand Of Acetazolamide Preparation",
      "A 6011",
      "Carbonic Anhydrase Inhibitor 6063",
      "Acetazolamida [INN-Spanish]",
      "Acetazolamide (AAZ)",
      "Acetazolamide, Monosodium Salt",
      "Acetazolamidum [INN-Latin]",
      "Ak-Zol",
      "Apo-Acetazolamide",
      "Carbonic Anhydrase Inhibitor No. 6063",
      "Diamox (TN)",
      "Diureticum-Holzinger",
      "Huma-Zolamide",
      "SK-Acetazolamide",
      "Acetazolamide Sodium, (Sterile)",
      "Acetazolamide [INN:BAN:JAN]",
      "Acetazolamide (JP15/USP/INN)",
      "4-Diamox",
    ],
  },
  {
    primary_id: "D0E1UA",
    names: ["2-Hydroxy Capric Acid", "2-Hydroxy-Decanoic Acid", "2-OH-C10"],
  },
  {
    primary_id: "D0E1UG",
    names: ["Methyl3beta-Hydroxyolean-12-En-27-Oate", "CHEMBL207494"],
  },
  {
    primary_id: "D0E1UY",
    names: ["AC-501"],
  },
  {
    primary_id: "D0E1UZ",
    names: ["CL-283796", "ACB-796", "N-(4-Chloro-2,6-Dimethylphenyl)-N'-Heptyl-N'-[4-(3-Methylbutyl)Benzyl]urea"],
  },
  {
    primary_id: "D0E1VD",
    names: ["OM-89"],
  },
  {
    primary_id: "D0E1VV",
    names: [
      "ELOMOTECAN HYDROCHLORIDE",
      "BN-80927",
      "R-1559",
      "Elomotecan Hydrochloride",
      "(+)-9-Chloro-5(R)-Ethyl-5-Hydroxy-10-Methyl-12-(4-Methylpiperidin-1-Ylmethyl)-4,5,13,15-Tetrahydro-1H,3H-Oxepino[3',4':6,7]indolizino[1,2-B]quinoline-3,15-Dione Hydrochloride",
    ],
  },
  {
    primary_id: "D0E1WH",
    names: ["LY-2608204"],
  },
  {
    primary_id: "D0E1WI",
    names: ["Fentanyl"],
  },
  {
    primary_id: "D0E1WY",
    names: [
      "EFIPLADIB",
      "Efipladib",
      "381683-94-9",
      "UNII-S97YUG2A91",
      "S97YUG2A91",
      "CHEMBL272342",
      "PLA-902",
      "Efipladib [USAN:INN]",
      "Efipladib (USAN)",
      "4-(3-(1-Benzhydryl-5-Chloro-2-(2-((3,4-Dichlorophenyl)Methylsulfonamido)Ethyl)-1H-Indol-3-Yl)Propyl)Benzoic Acid",
      "SCHEMBL1500831",
      "HIZOPJQOPKRKFM-UHFFFAOYSA-N",
      "ZINC85536943",
      "BDBM50226792",
      "D06674",
      "4-{3-[1-Benzhydryl-5-Chloro-2-(2-{[(3,4-Dichlorobenzyl)Sulfonyl]amino}ethyl)-1H-Indol-3-Yl]propyl}benzoic Acid",
    ],
  },
  {
    primary_id: "D0E1XD",
    names: [
      "M-Aminophenylboronic Acid",
      "3-Aminophenylboronic Acid",
      "30418-59-8",
      "3-Aminobenzeneboronic Acid",
      "(3-Aminophenyl)Boronic Acid",
      "M-AMINOPHENYLBORONIC ACID",
      "3-Aminophenyl Boronic Acid",
      "M-Aminophenyl Boronic Acid",
      "3-Amino Phenylboronic Acid",
      "CHEMBL20852",
      "Boronic Acid, (3-Aminophenyl)-",
      "MFCD00007755",
      "280563-63-5",
      "(M-Aminophenyl)Metaboric Acid",
      "3-Boronoaniline",
      "EINECS 250-189-0",
      "Zlchem 430",
      "3-Aminophenylboronicacid",
      "ACMC-1CTTO",
      "3amino Phenylboronic Acid",
      "3aminophenyl Boronic Acid",
      "AC1L3NRS",
      "(3-Aminophenyl)Boranediol",
      "3-Amino-Phenylboronic Acid",
      "AC1Q51DZ",
    ],
  },
  {
    primary_id: "D0E1XL",
    names: [
      "Levetiracetam",
      "102767-28-2",
      "Keppra",
      "(S)-2-(2-Oxopyrrolidin-1-Yl)Butanamide",
      "Keppra XR",
      "Levetiracetamum",
      "Ucb L059",
      "(2S)-2-(2-Oxopyrrolidin-1-Yl)Butanamide",
      "UCB-L 059",
      "UCB-L059",
      "Spritam",
      "(S)-Alpha-Ethyl-2-Oxo-1-Pyrrolidineacetamide",
      "(-)-(S)-Alpha-Ethyl-2-Oxo-1-Pyrrolidineacetamide",
      "SIB-S1",
      "UNII-44YRR34555",
      "1-Pyrrolidineacetamide, Alpha-Ethyl-2-Oxo-, (AlphaS)-",
      "UCB-22059",
      "Levetiracetamum [INN-Latin]",
      "Levetiractam",
      "CHEBI:6437",
      "Ucb L060",
      "Levetiracetam In Sodium Chloride",
      "44YRR34555",
      "Levroxa",
      "1-Pyrrolidineacetamide, Alpha-Ethyl-2-Oxo-,",
      "Leviteracetam",
      "Torleva",
      "Levetiracetam [INN]",
      "Etiracetam Levo-Isomer",
      "Keppra (TN)",
      "L-059",
      "Etiracetam, S-Isomer",
      "Keppra, Keppra XR),Levetiracetam",
      "Levetriacetam",
    ],
  },
  {
    primary_id: "D0E1YE",
    names: ["Lys[Z(NO2)]-Lys[Z(NO2)]"],
  },
  {
    primary_id: "D0E1YN",
    names: ["SB-2280"],
  },
  {
    primary_id: "D0E1YQ",
    names: [
      "2-(7-Phenylheptanoyl)Oxazole-4-Carboxamide",
      "CHEMBL460492",
      "SCHEMBL2162480",
      "ZOLDFPZCQMAAPD-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0E1YT",
    names: ["ISOMER A"],
  },
  {
    primary_id: "D0E1ZE",
    names: ["BBB-022"],
  },
  {
    primary_id: "D0E1ZQ",
    names: ["SWA11-ZZ-PE38"],
  },
  {
    primary_id: "D0E1ZR",
    names: ["VLD-517"],
  },
  {
    primary_id: "D0E1ZS",
    names: [
      "ALBAFURAN A",
      "Albafuran A",
      "CHEMBL564896",
      "CHEBI:2543",
      "84323-14-8",
      "4-[(2E)-3,7-Dimethylocta-2,6-Dien-1-Yl]-5-(6-Hydroxy-1-Benzofuran-2-Yl)Benzene-1,3-Diol",
      "Albafuran",
      "AC1NQYBF",
      "C08732",
      "BDBM50303002",
      "1,3-Benzenediol, 4-((2E)-3,7-Dimethyl-2,6-Octadienyl)-5-(6-Hydroxy-2-Benzofuranyl)-",
      "4-[(2E)-3,7-Dimethylocta-2,6-Dienyl]-5-(6-Hydroxy-1-Benzofuran-2-Yl)Benzene-1,3-Diol",
      "4-(3,7-Dimethyl-2,6-Octadienyl)-5-(6-Hydroxy-2-Benzofuranyl)-1,3-Benzenediol, 9CI",
      "4-(3,7-Dimethylocta-2,6-Dien-1-Yl)-5-(6-Hydroxy-1-Benzofuran-2-Yl)Benzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D0E1ZV",
    names: ["Labetuzumab-SN-38"],
  },
  {
    primary_id: "D0E2AC",
    names: [
      "3,6,9,12,15,18-HEXAOXAICOSANE",
      "3,6,9,12,15,18-Hexaoxaicosane",
      "3,6,9,12,15,18-Hexaoxaeicosane",
      "23601-39-0",
      "1-Ethoxy-2-[2-[2-[2-(2-Ethoxyethoxy)Ethoxy]ethoxy]ethoxy]ethane",
      "EINECS 245-774-2",
      "AC1L3JHX",
      "SCHEMBL987828",
      "DTXSID1041473",
      "CHEBI:39576",
      "CTK1A3907",
      "DB06867",
    ],
  },
  {
    primary_id: "D0E2AD",
    names: ["PMID27336223-Compound-10"],
  },
  {
    primary_id: "D0E2AF",
    names: ["PMID27998201-Compound-2"],
  },
  {
    primary_id: "D0E2AJ",
    names: ["P-27A"],
  },
  {
    primary_id: "D0E2BH",
    names: ["Nojirimycine Tetrazole"],
  },
  {
    primary_id: "D0E2BI",
    names: [
      "2-Fluorophenyl 4'-Ethylbiphenyl-4-Ylcarbamate",
      "CHEMBL599180",
      "BDBM50309731",
      "2-Fluorophenyl 4''-Ethylbiphenyl-4-Ylcarbamate",
    ],
  },
  {
    primary_id: "D0E2CO",
    names: [
      "BMY-22089",
      "(4R,6S)-6-[4,4-Bis(4-Fluorophenyl)-3-(1-Methyl-1H-Tetrazol-5-Yl)-1(E),3-Butadienyl]-4-Hydroxytetrahydropyran-2-One",
    ],
  },
  {
    primary_id: "D0E2CQ",
    names: ["RG7160"],
  },
  {
    primary_id: "D0E2CS",
    names: ["CD19-TCRz-41BB And CD22-TCRz-41BB CAR-T Cells"],
  },
  {
    primary_id: "D0E2CZ",
    names: [
      "FS-205-397",
      "6-Ethoxy-3,3-Dimethyl-2,3-Dihydro-1H-Indole Methanesulfonate",
      "6-Ethoxy-3,3-Dimethylindoline Methanesulfonate",
    ],
  },
  {
    primary_id: "D0E2DL",
    names: [
      "CDCA1-Derived Epitope Peptide Vaccine",
      "CDCA1-Derived Epitope Peptide Vaccine (HLA-A2402 Restricted, Prostate Cancer)",
      "CDCA1-Derived Epitope Peptide Vaccine (Prostate Cancer), Iwate Medical University/Tokyo University/Oita University",
      "Cell Division Cycle Associated Gene 1-Derived Epitope Peptide Vaccine (Prostate Cancer), Iwate Medical University/Tokyo University/Oita University",
      "CDCA1-Derived Epitope Peptide Vaccine (HLA-A2402 Restricted, Prostate Cancer), Iwate Medical University/Tokyo University/Oita University",
    ],
  },
  {
    primary_id: "D0E2DO",
    names: ["Intravenous Glibenclamide"],
  },
  {
    primary_id: "D0E2ED",
    names: ["IdioVax"],
  },
  {
    primary_id: "D0E2EN",
    names: ["TDI-0048"],
  },
  {
    primary_id: "D0E2EY",
    names: ["BLI-1005"],
  },
  {
    primary_id: "D0E2FV",
    names: [
      "9-Phenylethyl-9H-Adenine",
      "9-Phenethyladenine",
      "CHEMBL445696",
      "7051-86-7",
      "NSC211638",
      "AC1L7EWL",
      "Maybridge3_003004",
      "9-Phenethylpurin-6-Amine",
      "9-(2-Phenylethyl)Adenine",
      "MLS001181918",
      "SCHEMBL1230786",
      "6-Amino-9-Phenethyl-9H-Purine",
      "DTXSID10309298",
      "MolPort-002-902-800",
      "ZINC158322",
      "HMS1439I12",
      "HMS2852H03",
      "BDBM50257097",
      "CCG-51741",
      "AKOS012923591",
      "NSC-211638",
      "MCULE-7488166280",
      "IDI1_014391",
      "SMR000567667",
    ],
  },
  {
    primary_id: "D0E2FY",
    names: [
      "1-(2-Isopropoxy-Phenyl)-Piperazine",
      "1-(2-Isopropoxyphenyl)Piperazine",
      "CHEMBL9669",
      "54013-91-1",
      "2-Isopropoxyphenyl Piperazine",
      "SCHEMBL3867980",
      "CTK1F9762",
      "DTXSID40449277",
      "QJULELIONYLITF-UHFFFAOYSA-N",
      "ZINC26001539",
      "BDBM50017448",
      "1-[2-(Methylethoxy)Phenyl]piperazine",
      "AKOS010941535",
      "N-[2-(1-Methylethoxy)Phenyl]piperazine",
      "1-[2-(1-Methylethoxy)Phenyl]piperazine",
      "DA-05081",
      "BC4321135",
      "Piperazine, 1-[2-(1-Methylethoxy)Phenyl]-",
      "FT-0741526",
    ],
  },
  {
    primary_id: "D0E2FZ",
    names: ["ICA-69673"],
  },
  {
    primary_id: "D0E2GH",
    names: ["MVAH5"],
  },
  {
    primary_id: "D0E2GV",
    names: ["2-Tert-Butyldimethylsilyloxylycorine", "CHEMBL558873", "SCHEMBL12270994", "BDBM50293600"],
  },
  {
    primary_id: "D0E2HS",
    names: ["TAK-021"],
  },
  {
    primary_id: "D0E2IG",
    names: ["1,2-Bis(2,3,6-Trifluorophenyl)Ethane-1,2-Dione", "CHEMBL242722"],
  },
  {
    primary_id: "D0E2IH",
    names: [
      "1-[2-(Trityloxy)Ethoxymethyl]uracil",
      "CHEMBL424704",
      "SCHEMBL912787",
      "US8530490, Comparative 1",
      "QEKAGWDUKXATPZ-UHFFFAOYSA-N",
      "BDBM50190553",
    ],
  },
  {
    primary_id: "D0E2IN",
    names: ["LECOZOTAN HYDROCHLORIDE"],
  },
  {
    primary_id: "D0E2IR",
    names: [
      "NH-4",
      "Ammonium",
      "Ammonium Ion",
      "Ammonium(1+)",
      "Azanium",
      "NH4+",
      "Ammonium Cation",
      "14798-03-9",
      "Ammonia Ion",
      "Ammonium Ion(+)",
      "AMMONIUM CATION WITH D",
      "UNII-54S68520I4",
      "NH4(+)",
      "54S68520I4",
      "Ammonium Compounds",
      "ND4",
      "Quaternary Ammonium Compounds",
      "Nitrogen Cation",
      "Ammonium(+)",
      "Nitrogen(1+)",
      "Nitrogen Tetrahydride",
      "Ammonium Ion(1+)",
      "Ammonium (NH4+)",
      "AC1NUTFY",
      "AC1NUTFO",
      "Ammonia Ion (NH4+)",
      "Ammonium Ion (NH4+)",
      "Nitrogen(1+) Trihydride",
      "Ammonium (8CI,9CI)",
      "Ammonia, Ion (NH41+)",
      "AC1Q1W88",
      "AC1L18R7",
      "DTXSID5043974",
      "CHEBI:29352",
      "CHEBI:28938",
      "CTK0H5427",
    ],
  },
  {
    primary_id: "D0E2JN",
    names: ["KB-6806"],
  },
  {
    primary_id: "D0E2KC",
    names: [
      "4'-(Trifluoromethyl)-4-Biphenylyl Sulfamate",
      "CHEMBL396794",
      "SCHEMBL4665444",
      "ZINC28711937",
      "BDBM50220172",
      "4''-(Trifluoromethyl)-4-Biphenylyl Sulfamate",
    ],
  },
  {
    primary_id: "D0E2KE",
    names: ["PTR-302"],
  },
  {
    primary_id: "D0E2KT",
    names: ["S-234462"],
  },
  {
    primary_id: "D0E2LA",
    names: ["TAS-103"],
  },
  {
    primary_id: "D0E2LH",
    names: ["TT-173"],
  },
  {
    primary_id: "D0E2LR",
    names: ["3-[2-(1-Benzyl-Piperidin-4-Yl)-Ethyl]-1H-Indazole", "CHEMBL92958", "SCHEMBL6989630", "BDBM50039720"],
  },
  {
    primary_id: "D0E2LU",
    names: ["NSC-275420", "CHEMBL220048", "AC1L8512", "ZINC5579959", "NSC275425", "BDBM50195857", "NSC-275425"],
  },
  {
    primary_id: "D0E2MI",
    names: [
      "ARH-1029",
      "AR-HP",
      "Dual-Acting H. Pylori Therapeutic (Peptic Ulcer Disease), CJ Corp",
      "Dual-Acting H. Pylori Therapeutic, (Peptic Ulcer Disease), Taiho Pharmaceutical",
    ],
  },
  {
    primary_id: "D0E2MJ",
    names: ["Otamixaban"],
  },
  {
    primary_id: "D0E2ML",
    names: ["IG-RD-001"],
  },
  {
    primary_id: "D0E2NQ",
    names: ["Depression NCE"],
  },
  {
    primary_id: "D0E2OG",
    names: ["Tyr-D-Nle-Gly-D-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D0E2OK",
    names: ["MAL Formulation"],
  },
  {
    primary_id: "D0E2OU",
    names: ["Quinupristin"],
  },
  {
    primary_id: "D0E2PX",
    names: [
      "E-4031",
      "E 4031",
      "113558-89-7",
      "CHEMBL327980",
      "CHEBI:34732",
      "2-Methyl-6-(2-(4-(4-Methylsulfonylamino)Benzoylpiperidin-1-Yl)Ethyl)Pyridine",
      "Methanesulfonamide, N-(4-((1-(2-(6-Methyl-2-Pyridinyl)Ethyl)-4-Piperidinyl)Carbonyl)Phenyl)-",
      "N-(4-((1-(2-(6-Methyl-2-Pyridinyl)Ethyl)-4-Piperidinyl)Carbonyl)Phenyl)Methanesulfonamide",
      "N-[4-[1-[2-(6-Methylpyridin-2-Yl)Ethyl]piperidine-4-Carbonyl]phenyl]methanesulfonamide",
      "E4031",
      "N-(4-(1-(2-(6-Methylpyridin-2-Yl)Ethyl)Piperidine-4-Carbonyl)Phenyl)Methanesulfonamide",
      "Tocris-1808",
      "AC1Q6VTV",
    ],
  },
  {
    primary_id: "D0E2QC",
    names: ["RK-023"],
  },
  {
    primary_id: "D0E2QG",
    names: ["NC-6300"],
  },
  {
    primary_id: "D0E2QN",
    names: ["AZD-1678", "CCR4 Antagonist (Asthma), AstraZeneca"],
  },
  {
    primary_id: "D0E2RA",
    names: ["AV-7909"],
  },
  {
    primary_id: "D0E2RD",
    names: [
      "Abraxane",
      "33069-62-4",
      "Taxol A",
      "Paxene",
      "Plaxicel",
      "Paxceed",
      "Yewtaxan",
      "Onxol",
      "Ebetaxel",
      "Capxol",
      "Genaxol",
      "Nab-Paclitaxel",
      "LipoPac",
      "ABI-007",
      "Paclitaxel (Taxol)",
      "Genetaxyl",
      "Genexol",
      "Cyclopax",
      "Padexol",
      "Intaxel",
      "TaxAlbin",
      "Zisu",
      "OncoGel",
      "EmPAC",
      "Xorane",
      "ABI 007",
      "UNII-P88XT4IS4D",
      "HSDB 6839",
      "MBT 0206",
      "DRG-0190",
      "QW 8184",
      "Nabpaclitaxel",
      "P88XT4IS4D",
      "CHEMBL428647",
      "CHEBI:45863",
      "7-Epi-Paclitaxel",
      "5beta,20-Epoxy-1,2-Alpha,4,7beta,10beta,13alpha-Hexahydroxytax-11-En-9-On",
    ],
  },
  {
    primary_id: "D0E2RG",
    names: ["Bupivacaine Transdermal"],
  },
  {
    primary_id: "D0E2RS",
    names: ["PRAME Immunotherapeutic"],
  },
  {
    primary_id: "D0E2RV",
    names: [
      "3-(4-Amino-Phenyl)-3-Heptyl-Piperidine-2,6-Dione",
      "CHEMBL29338",
      "3-(4-Aminophenyl)-3-Heptylpiperidine-2,6-Dione",
      "NSC622730",
      "AC1L7GXY",
      "AC1Q6G81",
      "CTK6D7912",
      "2,3-(4-Aminophenyl)-3-Heptyl-",
      "BDBM50025057",
      "NSC-622730",
      "NCI60_006748",
      "2,6-Piperidinedione, 3-(4-Aminophenyl)-3-Heptyl-",
    ],
  },
  {
    primary_id: "D0E2SI",
    names: ["CID16197121"],
  },
  {
    primary_id: "D0E2TH",
    names: ["AVX101"],
  },
  {
    primary_id: "D0E2TY",
    names: ["Indomethacin Analog 3"],
  },
  {
    primary_id: "D0E2UQ",
    names: [
      "A3P5PS",
      "3'-Phosphoadenosine-5'-Phosphosulfate",
      "Adenosine-3'-Phosphate-5' -Phosphosulfate",
      "Phosphoadenosine Phosphosulfate (PAPS)",
    ],
  },
  {
    primary_id: "D0E2VA",
    names: ["T-Cell Receptor Mimic Mabs"],
  },
  {
    primary_id: "D0E2VC",
    names: [
      "PD-128763",
      "5-Methyl-3,4-Dihydroisoquinolin-1(2H)-One",
      "129075-56-5",
      "3,4-Dihydro-5-Methyl-1(2H)-Isoquinolinone",
      "3,4-DIHYDRO-5-METHYL-ISOQUINOLINONE",
      "1(2H)-Isoquinolinone, 3,4-Dihydro-5-Methyl-",
      "CHEBI:41928",
      "PD128763",
      "CHEMBL125200",
      "5-Methyl-3,4-Dihydro-2H-Isoquinolin-1-One",
      "3,4-Dihydro-5-Methylisoquinolinone",
      "1(2H)-Isoquinolinone,3,4-Dihydro-5-Methyl-",
      "5-Methyl-1,2,3,4-Tetrahydroisoquinolin-1-One",
      "DHQ",
      "PD 128763",
      "Dihydroisoquinolinone, 1",
      "AC1L3WIO",
      "ACMC-1C7L4",
      "SCHEMBL831538",
      "AMBZ0075",
      "KS-00000QFP",
      "CTK4B6146",
      "BDBM27682",
      "3,4-Dihydro-5-Methyl-Isoquinolinone",
    ],
  },
  {
    primary_id: "D0E2VF",
    names: ["CD19 Redirected Autologous T Cells"],
  },
  {
    primary_id: "D0E2VI",
    names: ["PMID29338548-Compound-36"],
  },
  {
    primary_id: "D0E2WE",
    names: ["Mapp-66"],
  },
  {
    primary_id: "D0E2WF",
    names: ["ORG 34517/34850"],
  },
  {
    primary_id: "D0E2WK",
    names: ["PMID27774822-Compound-Figure2Example1-1left"],
  },
  {
    primary_id: "D0E2WS",
    names: ["PMID26004420-Compound-US20140275020E"],
  },
  {
    primary_id: "D0E2WX",
    names: [
      "5-Fluoro Orotate",
      "5-Fluoroorotic Acid",
      "703-95-7",
      "5-Fluoroorotate",
      "5-Fluoro-2,6-Dioxo-1,2,3,6-Tetrahydropyrimidine-4-Carboxylic Acid",
      "Fluoroorotic Acid",
      "Orotic Acid, 5-Fluoro-",
      "5-FOA",
      "5-Fluoro Orotic Acid",
      "NSC 31712",
      "ENT-26398",
      "Ro 2-9945",
      "5-Fluorouracil-4-Carboxylic Acid",
      "UNII-7IA9OUC93E",
      "WR 152520",
      "5-Fluoroorotic Acid (VAN)",
      "MLS000737636",
      "EINECS 211-876-0",
      "4-Pyrimidinecarboxylic Acid, 5-Fluoro-1,2,3,6-Tetrahydro-2,6-Dioxo-",
      "7IA9OUC93E",
      "AI3-26398",
      "FOA",
      "5-Fluorouracil-6-Carboxylic Acid",
      "SEHFUALWMUWDKS-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0E2XM",
    names: ["12,13-DEHYDRO-8-O-ACETYLMANZAMINE A", "12,13-Dehydro-8-O-Acetylmanzamine A", "CHEMBL252520"],
  },
  {
    primary_id: "D0E2XN",
    names: ["PMID27607364-Compound-59"],
  },
  {
    primary_id: "D0E2XP",
    names: [
      "N-[2-(5-Hydroxy-1H-Indol-3-Yl)Ethyl]linoleamide",
      "CHEMBL236385",
      "BDBM22991",
      "N-Arachidonoylserotonin Analogue, 1d",
    ],
  },
  {
    primary_id: "D0E2XW",
    names: ["1-Fluoro-5-Phenyl-3-Aza-Bicyclo[3.1.0]hexane", "CHEMBL519717"],
  },
  {
    primary_id: "D0E2YL",
    names: ["UNI-RhIL-11"],
  },
  {
    primary_id: "D0E2YV",
    names: [
      "Glial Cell Line-Derived Neurotrophic Factor Protein",
      "BVF-014",
      "Glial Cell Line-Derived Neurotrophic Factor Protein (CNS Disease)",
      "Glial Cell Line-Derived Neurotrophic Factor Protein (CNS Disease), Amgen",
      "Glial Cell Line-Derived Neurotrophic Factor Protein (CNS Disease), MedGenesis",
      "Glial Cell Line-Derived Neurotrophic Factor Protein (CNS Disease), MedGenesis/ Amgen",
      "Glial Cell-Derived Neurotrophic Factor Protein (CNS Disease), Amgen/Biovail",
      "Glial Cell-Derived Neurotrophic Factor Protein (CNS Disease), Amgen/Valeant",
    ],
  },
  {
    primary_id: "D0E2YX",
    names: ["NRD-135S"],
  },
  {
    primary_id: "D0E2ZA",
    names: ["PPC-5650"],
  },
  {
    primary_id: "D0E2ZX",
    names: ["SGN-CD70A"],
  },
  {
    primary_id: "D0E3AL",
    names: [
      "UNC0006",
      "CHEMBL2165126",
      "SCHEMBL253164",
      "GTPL7651",
      "NGCKUAWDNUFNBB-UHFFFAOYSA-N",
      "BDBM50395587",
      "7-(4-(4-(2,3-Dichlorophenyl)-1,4-Diazepan-1-Yl)Butoxy)-3,4-Dihydroquinolin-2(1H)-One",
    ],
  },
  {
    primary_id: "D0E3AR",
    names: ["ISIS 113904"],
  },
  {
    primary_id: "D0E3BH",
    names: ["Ridogrel"],
  },
  {
    primary_id: "D0E3BJ",
    names: ["SLV-311"],
  },
  {
    primary_id: "D0E3CB",
    names: ["PMID24749861C34"],
  },
  {
    primary_id: "D0E3CO",
    names: ["N-(2-Ethylphenyl)-5-Methylbenzo[d]oxazol-2-Amine", "CHEMBL1269649"],
  },
  {
    primary_id: "D0E3CX",
    names: ["NDX-1017"],
  },
  {
    primary_id: "D0E3DF",
    names: ["(2R,3S)-2-[(2-Iodophenoxy)Phenylmethyl]morpholine", "CHEMBL568336"],
  },
  {
    primary_id: "D0E3DM",
    names: ["(S)-3-(Naphthalen-2-Ylmethoxy)Pyrrolidine", "CHEMBL445794"],
  },
  {
    primary_id: "D0E3DS",
    names: ["Alkyl Mannoside Derivative 11"],
  },
  {
    primary_id: "D0E3DT",
    names: ["NVS Antibody"],
  },
  {
    primary_id: "D0E3EA",
    names: ["AP-02-00", "AP-02-00 Series (Dyslipidemia)"],
  },
  {
    primary_id: "D0E3ED",
    names: ["Octahydro-Pyrrolo[3,4-C]-Pyrrole Derivative 1"],
  },
  {
    primary_id: "D0E3EK",
    names: ["NP-619"],
  },
  {
    primary_id: "D0E3EO",
    names: ["JTT-251"],
  },
  {
    primary_id: "D0E3ET",
    names: [
      "AM-1714",
      "UNII-E3OY6PCU04",
      "E3OY6PCU04",
      "CHEMBL429797",
      "BWKBVEVEQOCSCF-UHFFFAOYSA-N",
      "335371-37-4",
      "SCHEMBL3298491",
      "BDBM50228072",
      "6H-Dibenzo(B,d)Pyran-6-One, 3-(1,1-Dimethylheptyl)-1,9-Dihydroxy-",
      "3-(1,1-Dimethylheptyl)-1,9-Dihydroxy-6H-Dibenzo[b,d]pyran-6-One",
      "1,9-Dihydroxy-3-(2-Methyloctan-2-Yl)-6H-Benzo[c]chromen-6-One",
    ],
  },
  {
    primary_id: "D0E3EY",
    names: ["Elsamitrucin"],
  },
  {
    primary_id: "D0E3FD",
    names: [
      "Bromazepam",
      "Bromazepamum",
      "Calmepam",
      "Compedium",
      "Compendium",
      "Creosedin",
      "Durazanil",
      "Lectopam",
      "Lekotam",
      "Lexaurin",
      "Lexilium",
      "Lexomil",
      "Lexotan",
      "Lexotanil",
      "Normoc",
      "Somalium",
      "Ultramidol",
      "Bromazepamum [Latin]",
      "LA Xvii",
      "KL 001",
      "Ro 53350",
      "Apo-Bromazepam",
      "Brazepam (TN)",
      "Bromaze (TN)",
      "Bromazepam(USAN",
      "Bromazepamum [INN-Latin]",
      "Gen-Bromazepam",
      "KL-001",
      "Lectopam (TN)",
      "Lexotan (TN)",
      "Novo-Bromazepam",
      "Nu-Bromazepam",
      "Ro 4-9253",
      "Ro 5-3350",
      "Bromazepam (JP15/USAN/INN)",
      "Bromazepam [USAN:INN:BAN:JAN]",
      "1,3-Dihydro-7-Bromo-5-(2-Pyridyl)-2H-1,4-Benzodiazepin-2-One",
      "7-Bromo-1,3-Dihydro-5-(2-Pyridyl)-2H-1,4-Benzdiazepin-2-One",
      "7-Bromo-1,3-Dihydro-5-(2-Pyridyl)-2H-1,4-Benzodiazepin-2-One",
      "7-Bromo-5-(2-Pyridyl)-3H-1,4-Benzodiaxepin-2(1H)-One",
      "7-Bromo-5-(2-Pyridyl)-3H-1,4-Benzodiazepin-2(1H)-One",
      "7-Bromo-5-Pyridin-2-Yl-1,3-Dihydro-1,4-Benzodiazepin-2-One",
    ],
  },
  {
    primary_id: "D0E3FK",
    names: ["MERIOLIN 7"],
  },
  {
    primary_id: "D0E3FR",
    names: ["ML297"],
  },
  {
    primary_id: "D0E3GI",
    names: ["BPH-629", "BPH 629", "BPH629"],
  },
  {
    primary_id: "D0E3HC",
    names: ["L000021649"],
  },
  {
    primary_id: "D0E3HJ",
    names: ["Oligotide"],
  },
  {
    primary_id: "D0E3HY",
    names: ["IP10 C8"],
  },
  {
    primary_id: "D0E3ID",
    names: ["7-Iodo-1,5-Dihydro-Imidazo[2,1-B]quinazolin-2-One"],
  },
  {
    primary_id: "D0E3IK",
    names: [
      "N-(2-Propyloxy-4-Nitrophenyl)Methanesulfonamide",
      "CHEMBL204184",
      "N-(2-N-Propyloxy-4-Nitrophenyl)Methanesulfonamide",
      "880145-98-2",
      "SCHEMBL3301465",
      "CTK6E5797",
      "BDBM50182536",
      "AKOS015965877",
      "N-(4-Nitro-2-Propoxyphenyl)Methanesulfonamide",
    ],
  },
  {
    primary_id: "D0E3JF",
    names: ["Cx-911"],
  },
  {
    primary_id: "D0E3JL",
    names: ["SL-25.1188"],
  },
  {
    primary_id: "D0E3KD",
    names: ["9-(4-Hydroxyphenyl)-2,7-Phenanthroline"],
  },
  {
    primary_id: "D0E3LA",
    names: ["(R)-2-Amino-3-(4-Octylphenylamino)Propan-1-Ol", "CHEMBL572686", "SCHEMBL1312243"],
  },
  {
    primary_id: "D0E3LP",
    names: ["SEN-196"],
  },
  {
    primary_id: "D0E3LW",
    names: ["NV-27"],
  },
  {
    primary_id: "D0E3MA",
    names: ["Polyacrylic Acid", "BufferGel", "Carbomer", "PAA"],
  },
  {
    primary_id: "D0E3NT",
    names: ["AGK2", "AGK-2", "AGK 2"],
  },
  {
    primary_id: "D0E3NZ",
    names: ["L-696229", "3-[2-(Benzoxazol-2-Yl)Ethyl]-5-Ethyl-6-Methylpyridine-2(1H)-One"],
  },
  {
    primary_id: "D0E3OF",
    names: [
      "Warfarin",
      "Brumolin",
      "Choice",
      "Coumadin",
      "Coumafen",
      "Coumafene",
      "Coumaphen",
      "Coumaphene",
      "Coumarins",
      "Coumefene",
      "Dethmor",
      "Dethnel",
      "Kumader",
      "Kumadu",
      "Kumatox",
      "Kypfarin",
      "Maveran",
      "Panwarfin",
      "Prothromadin",
      "RAX",
      "Ratorex",
      "Ratoxin",
      "Ratron",
      "Rattentraenke",
      "Rattunal",
      "Rodafarin",
      "Rosex",
      "Sofarin",
      "Solfarin",
      "Warfarat",
      "Warfarina",
      "Warfarine",
      "Warfarinum",
      "Warficide",
      "Zoocoumarin",
      "Arab Rat Death",
      "Arab Rat Deth",
      "Coumafene [French]",
      "Dicusat E",
      "Eastern States Duocide",
      "Fasco Fascrat Powder",
      "Maag Rattentod Cum",
      "Mouse Pak",
      "Ratron G",
      "Rattenstreupulver Neu Schacht",
      "Rattenstreupulver New Schacht",
      "Rodafarin C",
      "Rodex Blox",
      "Sorexa Plus",
      "Temus W",
      "Twin Light Rat Away",
      "Vampirinip II",
      "Vampirinip Iii",
      "Warfarin Q",
      "Warfarin Plus",
      "Warfarin Plus [discontinued]",
      "Zoocoumarin [Netherlands And USSR]",
      "Zoocoumarin [Russian]",
      "CBKinase1_000192",
      "CBKinase1_012592",
      "Latka 42",
      "Latka 42 [Czech]",
      "PS104_SUPELCO",
      "WARF Compound 42",
      "Warf 10",
      "Warf 42",
      "Athrombine-K",
      "CO-Rax",
      "Choice (TN)",
      "Coumadin (TN)",
      "D-Con",
      "Frass-Ratron",
      "Jantoven (TN)",
      "Liqua-Tox",
      "Mar-Frin",
      "Marevan (TN)",
      "Place-Pax",
      "Rac-Warfarin",
      "Rat & Mice Bait",
      "Rat-Gard",
      "Rat-Kill",
      "Rat-Mix",
      "Rat-Ola",
      "Rat-Trol",
      "Ratten-Koederrohr",
      "Ro-Deth",
      "Rough & Ready Mouse Mix",
      "Tox-Hid",
      "Waran (TN)",
      "Warfarin (INN)",
      "Warfarin (And Salts Of)",
      "Warfarin [BSI:ISO]",
      "Warfarin [INN:BAN]",
      "Warfarin(R)",
      "Warfarina [INN-Spanish]",
      "Warfarine [INN-French]",
      "Warfarine [ISO-French]",
      "Warfarinum [INN-Latin]",
      "Cov-R-Tox",
      "Martin's Mar-Frin",
      "Rat-B-Gon",
      "Rat-A-Way",
      "Rats-No-More",
      "Spray-Trol Brand Roden-Trol",
      "Rat-O-Cide #2",
      "Warfarin Titrated To An INR Of 2.5-3.0",
      "W.A.R.F. 42",
      "(-)-Warfarin",
      "(S)-Warfarin",
      "200 Coumarin",
    ],
  },
  {
    primary_id: "D0E3OH",
    names: ["2-(3-Cyanobenzyl)-5-Mercaptopentanoic Acid"],
  },
  {
    primary_id: "D0E3OL",
    names: ["GNF-PF-2857"],
  },
  {
    primary_id: "D0E3OT",
    names: ["PMID30185082-Compound-14"],
  },
  {
    primary_id: "D0E3OY",
    names: ["JWH-372"],
  },
  {
    primary_id: "D0E3OZ",
    names: ["OBIDOXIME"],
  },
  {
    primary_id: "D0E3PF",
    names: ["PT-320"],
  },
  {
    primary_id: "D0E3QU",
    names: ["Cytovaxine"],
  },
  {
    primary_id: "D0E3RC",
    names: ["99mTc-Sulesomab"],
  },
  {
    primary_id: "D0E3RQ",
    names: ["Ac-RYYRIK-GGG-K-(NH2)-YAFGYPS-GG", "CHEMBL414736"],
  },
  {
    primary_id: "D0E3RW",
    names: [
      "Cefditoren",
      "Cefditoren [USAN:INN]",
      "Meiact (TN)",
      "Spectracef (TN)",
      "(+)-(6R,7R)-7-(2-(2-Amino-4-Thiazolyl)Glyoxylamido)-3-((Z)-2-(4-Methyl-5-Thiazolyl)Vinyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 7(Sup 2)-(Z)-(O-Methyloxime)",
      "(6R-(3(Z),6alpha,7beta(Z)))-7-((2-Amino-4-Thiazolyl)(Methoxyimino)Acetyl)Amino-3-(2-(4-Methyl-5-Thiazolyl)Ethenyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0E3SA",
    names: ["Betafectin"],
  },
  {
    primary_id: "D0E3SB",
    names: ["SR11254"],
  },
  {
    primary_id: "D0E3SD",
    names: [
      "AZD-9272",
      "AZD 9272",
      "327056-26-8",
      "UNII-54SQ9B412I",
      "AZD9272",
      "54SQ9B412I",
      "3-Fluoro-5-[3-(5-Fluoropyridin-2-Yl)-1,2,4-Oxadiazol-5-Yl]benzonitrile",
      "CHEMBL2164550",
      "3-Fluoro-5-(3-(5-Fluoropyridin-2-Yl)-1,2,4-Oxadiazol-5-Yl)Benzonitrile",
      "RBSPCALDSNXWEP-UHFFFAOYSA-N",
      "SCHEMBL2027395",
      "GTPL6439",
      "CHEMBL2164551",
      "MolPort-039-338-067",
      "ZINC33980255",
      "BDBM50395923",
      "AKOS027470228",
      "HY-110254",
      "AZD9272, &gt",
      "CS-0033119",
      "J3.560.338G",
      "3-Fluoro-5-[3-(5-Fluoro-2-Pyridinyl)-1,2,4-Oxadiazol-5-Yl]benzonitrile",
    ],
  },
  {
    primary_id: "D0E3SF",
    names: ["MCB-3837"],
  },
  {
    primary_id: "D0E3SH",
    names: ["Panobinostat"],
  },
  {
    primary_id: "D0E3ST",
    names: [
      "1-(4-Ethylpiperazin-1-Yl)-2-Phenylethanone",
      "CHEMBL439203",
      "1-Ethyl-4-(Phenylacetyl)Piperazine",
      "AC1MNIR9",
      "SCHEMBL20353684",
      "MolPort-019-032-483",
      "ZINC23379273",
      "BDBM50207797",
      "AKOS003796844",
      "MCULE-6061952446",
    ],
  },
  {
    primary_id: "D0E3SX",
    names: ["SCH-1359113"],
  },
  {
    primary_id: "D0E3TC",
    names: ["Staurosporine"],
  },
  {
    primary_id: "D0E3UM",
    names: ["L-657925"],
  },
  {
    primary_id: "D0E3UW",
    names: ["RG6046"],
  },
  {
    primary_id: "D0E3VF",
    names: ["E Coli Vaccine"],
  },
  {
    primary_id: "D0E3VJ",
    names: ["N-[5-(Ethylthio)-1,3,4-Thiadiazol-2-Yl]sulfamide", "CHEMBL451538"],
  },
  {
    primary_id: "D0E3VR",
    names: ["S-(N-Pentyl-N-Hydroxycarbamoyl)Glutathione", "CHEMBL129965", "SCHEMBL3281399", "BDBM50092830"],
  },
  {
    primary_id: "D0E3WH",
    names: [
      "2-Methyl-6-(3-(P-Tolyloxy)Prop-1-Ynyl)Pyridine",
      "CHEMBL212130",
      "2-Methyl-6-(3-P-Tolyloxy-Prop-1-Ynyl)Pyridine",
      "SCHEMBL5698271",
      "BDBM50191151",
    ],
  },
  {
    primary_id: "D0E3WQ",
    names: ["Eterobarb"],
  },
  {
    primary_id: "D0E3WT",
    names: [
      "2-(2,4-Diphenylthiazol-5-Yl)Acetic Acid",
      "2-(2,4-Diphenyl-1,3-Thiazol-5-Yl)Acetic Acid",
      "21256-15-5",
      "CHEMBL592001",
      "SMR000168586",
      "Diphenylthiazolylaceticacid",
      "Bionet2_000936",
      "AC1MC90U",
      "Oprea1_148343",
      "MLS000327963",
      "SCHEMBL4497493",
      "CTK4E6215",
      "DTXSID40377203",
      "MolPort-002-345-061",
      "ZINC169124",
      "HMS2377A22",
      "HMS1366K12",
      "KS-00001A6R",
      "7213AD",
      "SBB077302",
      "BDBM50309445",
      "AKOS005070458",
      "RP15938",
      "MCULE-6578968783",
      "4K-539S",
      "(Diphenyl-1,3-Thiazol-5-Yl)Acetic Acid",
      "AM803507",
      "AJ-17064",
      "KB-220568",
      "TR-009976",
      "CS-0039039",
      "FT-0680367",
      "I04-4316",
      "J-505484",
    ],
  },
  {
    primary_id: "D0E3XG",
    names: ["Zolasartan"],
  },
  {
    primary_id: "D0E3ZO",
    names: ["AIK33"],
  },
  {
    primary_id: "D0E3ZU",
    names: ["ISIS 113210"],
  },
  {
    primary_id: "D0E4AB",
    names: [
      "N'-(2-Fluorobenzoyl)-2-Naphthohydrazide",
      "CHEMBL108888",
      "N'-(2-Fluorobenzoyl)Naphthalene-2-Carbohydrazide",
      "NSC88634",
      "AC1L60EG",
      "ZINC1569432",
      "BDBM50066318",
      "NSC-88634",
      "AKOS008432264",
      "N''-(2-Fluorobenzoyl)-2-Naphthohydrazide",
      "(2-Fluorophenyl)-N-(2-Naphthylcarbonylamino)Carboxamide",
      "Naphthalene-2-Carboxylic Acid N''-(2-Fluoro-Benzoyl)-Hydrazide",
    ],
  },
  {
    primary_id: "D0E4AN",
    names: [
      "6-Phosphogluconic Acid",
      "6-Phosphogluconate",
      "921-62-0",
      "6-Phospho-D-Gluconic Acid",
      "6-Phospho-D-Gluconate",
      "6-O-Phosphono-D-Gluconic Acid",
      "Gluconic Acid-6-Phosphate",
      "(2R,3S,4R,5R)-2,3,4,5-Tetrahydroxy-6-(Phosphonooxy)Hexanoic Acid",
      "UNII-W31WK7B8U0",
      "D-Gluconic Acid 6-(Dihydrogen Phosphate)",
      "CHEBI:48928",
      "W31WK7B8U0",
      "CHEMBL1230513",
      "BIRSGZKFKXLSJQ-SQOUGZDYSA-N",
      "1pgp",
      "6PG",
      "6-Phosphogluconic Acid Barium Salt",
      "C6H13O10P",
      "6-P-Gluconate",
      "EINECS 213-069-9",
      "D-Gluconic Acid, 6-(Dihydrogen Phosphate)",
      "Bmse000198",
      "AC1Q6RV2",
      "AC1L3M6P",
      "SCHEMBL50297",
    ],
  },
  {
    primary_id: "D0E4BN",
    names: ["Monoaryl-1,2-Diamine Derivative 4"],
  },
  {
    primary_id: "D0E4BT",
    names: ["N-Benzyl-2-Thiomorpholinopyrimidin-4-Amine", "CHEMBL1086014"],
  },
  {
    primary_id: "D0E4DN",
    names: ["SC16LD6.5"],
  },
  {
    primary_id: "D0E4DO",
    names: ["PTX-111"],
  },
  {
    primary_id: "D0E4DR",
    names: ["[2-(Purin-9-Yl)Ethyl]-Bisphosphonic Acid", "CHEMBL457424", "SCHEMBL3976006"],
  },
  {
    primary_id: "D0E4DW",
    names: [
      "Phenytoin",
      "Aleviatin",
      "Antisacer",
      "Auranile",
      "Causoin",
      "Citrullamon",
      "Citrulliamon",
      "Comital",
      "Comitoina",
      "Convul",
      "Danten",
      "Dantinal",
      "Dantoinal",
      "Dantoine",
      "Denyl",
      "Difenilhidantoina",
      "Difenin",
      "Difetoin",
      "Difhydan",
      "Dihycon",
      "Dihydantoin",
      "Dilabid",
      "Dilantin",
      "Dilantine",
      "Dillantin",
      "Dintoin",
      "Dintoina",
      "Diphantoin",
      "Diphedal",
      "Diphedan",
      "Diphenat",
      "Diphenin",
      "Diphenine",
      "Diphentoin",
      "Diphentyn",
      "Diphenylan",
      "Diphenylhydantoin",
      "Diphenylhydantoine",
      "Diphenylhydatanoin",
      "Ditoinate",
      "Ekko",
      "Elepsindon",
      "Enkelfel",
      "Epamin",
      "Epanutin",
      "Epelin",
      "Epifenyl",
      "Epihydan",
      "Epilantin",
      "Epinat",
      "Epised",
      "Eptal",
      "Eptoin",
      "Fenitoin",
      "Fenitoina",
      "Fentoin",
      "Fenylepsin",
      "Fenytoine",
      "Hidan",
      "Hidantal",
      "Hidantilo",
      "Hidantina",
      "Hidantomin",
      "Hindatal",
      "Hydantal",
      "Hydantin",
      "Hydantoinal",
      "Hydantol",
      "Idantoil",
      "Idantoin",
      "Iphenylhydantoin",
      "Kessodanten",
      "Labopal",
      "Lehydan",
      "Lepitoin",
      "Lepsin",
      "Minetoin",
      "Neosidantoina",
      "Novantoina",
      "Novophenytoin",
      "Oxylan",
      "PHENYTEK",
      "Phanantin",
      "Phanatine",
      "Phenatine",
      "Phenatoine",
      "Phenhydan",
      "Phenhydanin",
      "Phenitoin",
      "Phentoin",
      "Phentytoin",
      "Phenytex",
      "Phenytoine",
      "Phenytoinum",
      "Ritmenal",
      "Saceril",
      "Sanepil",
      "Silantin",
      "Sinergina",
      "Sodanthon",
      "Sodantoin",
      "Sodanton",
      "Solantin",
      "Solantoin",
      "Solantyl",
      "Sylantoic;TOIN",
      "Tacosal",
      "Thilophenyl",
      "Zentronal",
      "Zentropil",
      "Component Of Mebroin",
      "Dantoinal Klinos",
      "Difenilhidantoina [Spanish]",
      "Dihydan Toin",
      "Dilantin Acid",
      "Diphenylhydantoine [French]",
      "Ekko Capsules",
      "Elepsi Ndon",
      "Epdantoin Simple",
      "Epdantoine Simple",
      "Epilan D",
      "Extended Phenytoin Sodium",
      "Fen Toin",
      "Fenantoin Mn Pharma",
      "Fenidantoin S",
      "Fenytoin Dak",
      "Hidantina Senosian",
      "Hidantina Vitoria",
      "Ictalis Simp Le",
      "Ictalis Simple",
      "Om Hidantoina Simple",
      "Phenat Ine",
      "Phenytoin AWD",
      "Sodium Diphenylhydantoinate",
      "Taco Sal",
      "Toin Unicelles",
      "CL12003",
      "D 4007",
      "D4007_SIGMA",
      "Didan TDC 250",
      "Epasmir 5",
      "IFLab1_000214",
      "DILANTIN-30",
      "DPH (VAN)",
      "Di-Hydan",
      "Di-Lan",
      "Di-Phetine",
      "Dilantin (TN)",
      "Dilantin Infatabs (TN)",
      "Dilantin Kapseals (TN)",
      "Dilantin-125",
      "Diphenylhydantoin (VAN)",
      "Epanutin (TN)",
      "Epasmir (5)",
      "Epilan-D",
      "Eptoin (TN)",
      "Fenidantoin (S)",
      "Fenitoina [INN-Spanish]",
      "Neos-Hidantoina",
      "Om-Hydantoine",
      "PHENYTOIN SODIUM, EXTENDED",
      "Phenytek (TN)",
      "Phenytoin (PHN)",
      "Phenytoin-Gerot",
      "Phenytoine [INN-French]",
      "Phenytoinum [INN-Latin]",
      "Di-Lan (VAN)",
      "Didan-Tdc-250",
      "Gerot-Epilan-D",
      "PHENYTOIN (5,5-DIPHENYLHYDANTOIN)",
      "Phenytoin (JP15/USP/INN)",
      "Phenytoin [USAN:INN:BAN:JAN]",
      "Hydantoin, 5,5-Diphenyl-(8CI)",
      "2,4-Imidazolidinedione, 5,5-Diphenyl-(9CI)",
      "2-Hydroxy-5,5-Diphenyl-3,5-Dihydro-4H-Imidazol-4-One",
      "5,5-DIPHENYLHYDANTOIN",
      "5,5-Diphenyl-2,4-Imidazolidinedione",
      "5,5-Diphenyl-Imidazolidine-2,4-Dione",
      "5,5-Diphenylhydantoin (IUPAC)",
      "5,5-Diphenylhydantoin (Phenytoin)",
      "5,5-Diphenylimidazolidin-2,4-Dione",
      "5,5-Dwufenylohydantoina",
      "5,5-Dwufenylohydantoina [Polish]",
      "5,5-Diphenylimidazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0E4EP",
    names: ["Oncolysin M", "Anti-CD33-BR", "Anti-My9-BR"],
  },
  {
    primary_id: "D0E4EZ",
    names: ["R-1438"],
  },
  {
    primary_id: "D0E4FG",
    names: [
      "MOZENAVIR MESILATE",
      "DMP-450",
      "Mozenavir Mesilate",
      "XM-412 (Free Base)",
      "(4R,5S,6S,7R)-1,3-Bis(3-Aminobenzyl)-4,7-Dibenzyl-5,6-Dihydroxyhexahydro-1,3-Diazepin-2-One Dimethanesulfonate",
    ],
  },
  {
    primary_id: "D0E4FJ",
    names: [
      "(E)-Octadec-9-Enal",
      "9-OCTADECENAL",
      "Octadecenyl Aldehyde",
      "UNII-979G27P46D",
      "CHEMBL190707",
      "979G27P46D",
      "9-Octadecenal, (9E)-",
      "5090-41-5",
      "E-9-Octadecenal",
      "9-Octadecenal, (E)-",
      "FEMA No. 4059, E-",
      "10009-79-7",
      "(9E)-9-Octadecenal",
      "(9E)-9-Octadecenal #",
      "AC1NR25P",
      "SCHEMBL260834",
      "DTXSID9074305",
      "ZENZJGDPWWLORF-MDZDMXLPSA-N",
      "MolPort-044-723-520",
      "LMFA06000099",
      "BDBM50170061",
      "UNII-971UC454BB Component ZENZJGDPWWLORF-MDZDMXLPSA-N",
    ],
  },
  {
    primary_id: "D0E4FM",
    names: ["Y-700"],
  },
  {
    primary_id: "D0E4GE",
    names: ["AZD-2551"],
  },
  {
    primary_id: "D0E4GG",
    names: ["Resatorvid"],
  },
  {
    primary_id: "D0E4GJ",
    names: ["LY-314657"],
  },
  {
    primary_id: "D0E4GM",
    names: [
      "3-Isopr-Sal-Cyclosal-D4TMP",
      "CHEMBL375636",
      "(Sp)-3-Isopr-Sal-Cyclosal-D4TMP",
      "(Rp)-3-Isopr-Sal-Cyclosal-D4TMP",
      "BDBM50206632",
      "2,4(1H,3H)-Pyrimidinedione, 1-[(2R,5S)-5-[[[8-(2,2-Dimethyl-4H-1,3-Benzodioxin-8-Yl)-2-Oxido-4H-1,3,2-Benzodioxaphosphorin-2-Yl]oxy]methyl]-2,5-Dihydro-2-Furanyl]-5-Methyl-",
    ],
  },
  {
    primary_id: "D0E4GZ",
    names: ["Small Molecule Program Targeting Th17 Cells, Autoimmune Diseases"],
  },
  {
    primary_id: "D0E4HB",
    names: [
      "2-(2-Cyclohexylidenehydrazinyl)-4-Phenylthiazole",
      "CHEMBL220495",
      "AC1P6Q43",
      "SCHEMBL19017688",
      "ZINC7350105",
      "BDBM50326652",
      "AKOS005139675",
      "ST51067929",
      "N-(Cyclohexylideneamino)-4-Phenyl-Thiazol-2-Amine",
      "1-Cyclohexylidene-2-(4-Phenylthiazol-2-Yl)Hydrazine",
      "N-(Cyclohexylideneamino)-4-Phenyl-1,3-Thiazol-2-Amine",
    ],
  },
  {
    primary_id: "D0E4HZ",
    names: ["F-12682", "Antidepressant, Pierre Fabre", "F-12318", "5-HT1B Antagonists, Pierre Fabre"],
  },
  {
    primary_id: "D0E4IB",
    names: [
      "PBF509",
      "5-Bromo-2,6-Di(1H-Pyrazol-1-Yl)Pyrimidin-4-Amine",
      "SCHEMBL2546228",
      "CHEMBL3694769",
      "ATFXVNUWQOXRRU-UHFFFAOYSA-N",
      "BDBM128295",
      "ZINC72317391",
      "AKOS030527812",
      "DS-19509",
      "US8796284, 1",
      "1337962-47-6",
    ],
  },
  {
    primary_id: "D0E4IZ",
    names: ["ISIS 113019"],
  },
  {
    primary_id: "D0E4JB",
    names: ["TP-834"],
  },
  {
    primary_id: "D0E4JL",
    names: ["Rotigitine"],
  },
  {
    primary_id: "D0E4JU",
    names: [
      "N-Hydroxy-N-Methyl-3-Naphthalen-2-Yl-Acrylamide",
      "CHEMBL63882",
      "BDBM50015178",
      "ZINC26247459",
      "N-Methyl-N-Hydroxy-2-Naphthaleneacrylamide",
      "(E)-N-Hydroxy-N-Methyl-3-(Naphthalen-2-Yl)Acrylamide",
      "(E)-3-(2-Naphthalenyl)-N-Methyl-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D0E4JX",
    names: ["PD-137337"],
  },
  {
    primary_id: "D0E4KI",
    names: ["Example 8 [WO1999033801A1]"],
  },
  {
    primary_id: "D0E4KZ",
    names: ["4-Phosphonomethyl-Piperidine-2-Carboxylic Acid", "CHEMBL30161", "CTK0D0245"],
  },
  {
    primary_id: "D0E4LB",
    names: ["2-(5-Cyano-1-Pent-1-Ynyl)-N6-Methoxyadenosine", "CHEMBL375619"],
  },
  {
    primary_id: "D0E4LC",
    names: ["GSK1511931"],
  },
  {
    primary_id: "D0E4LS",
    names: ["LAG525"],
  },
  {
    primary_id: "D0E4LU",
    names: ["SB-216995"],
  },
  {
    primary_id: "D0E4MB",
    names: ["GDC-9545"],
  },
  {
    primary_id: "D0E4MT",
    names: ["Influenza A Virus H3N2 Vaccine"],
  },
  {
    primary_id: "D0E4MX",
    names: ["Myomap"],
  },
  {
    primary_id: "D0E4ON",
    names: ["N-Butyl-4-Methyl-3,4-Dihydroquinazolin-2-Amine"],
  },
  {
    primary_id: "D0E4OS",
    names: [
      "ACULEACIN A",
      "Aculeacin A",
      "58814-86-1",
      "AC1Q6G0M",
      "AC1L3U84",
      "YKPHLXGEPNYRPY-UHFFFAOYSA-N",
      "LS-14909",
      "N-{23-[1,2-Dihydroxy-2-(4-Hydroxyphenyl)Ethyl]-2,11,12,15-Tetrahydroxy-6,20-Bis(1-Hydroxyethyl)-16-Methyl-5,8,14,19,22,25-Hexaoxotetracosahydro-1h-Dipyrrolo[2,1-C:2',1'-L][1,4,7,10,13,16]hexaazacyclohenicosin-9-Yl}hexadecanamide",
      "Echinocandin B, 1-((4R,5R)-4,5-Dihydroxy-N(Sup 2)-(1-Oxohexadecyl)-L-Ornithine)-",
    ],
  },
  {
    primary_id: "D0E4OT",
    names: ["JWH-325"],
  },
  {
    primary_id: "D0E4PN",
    names: ["API-003"],
  },
  {
    primary_id: "D0E4PQ",
    names: ["Inno-LC-01"],
  },
  {
    primary_id: "D0E4QW",
    names: [
      "Bone Synthesis-Stimulating Small Molecule Therapeutics, Oral, Osteoporosis",
      "Bone Synthesis-Stimulating Small Molecule Therapeutics (Oral, Osteoporosis)",
    ],
  },
  {
    primary_id: "D0E4RG",
    names: ["NCX-4215"],
  },
  {
    primary_id: "D0E4RH",
    names: ["DDB-S"],
  },
  {
    primary_id: "D0E4SI",
    names: ["Rv-11"],
  },
  {
    primary_id: "D0E4SM",
    names: [
      "4-{2-[(3-Nitrobenzoyl)Amino]Phenoxy}Phthalic Acid",
      "4-{2-[(3-NITROBENZOYL)AMINO]PHENOXY}PHTHALIC ACID",
      "CHEMBL326611",
      "4-[2-[(3-Nitrobenzoyl)Amino]phenoxy]phthalic Acid",
      "1z6p",
      "AC1LCW3D",
      "SCHEMBL4323472",
      "NAQUAVBNIYTIIS-UHFFFAOYSA-N",
      "BDBM50149303",
      "DB04044",
      "4-[2-(3-Nitrobenzoylamino)Phenoxy]phthalic Acid",
      "4-[2-(3-Nitrobenzoylamino)-Phenoxy]phthalic Acid",
      "4-[2-(3-Nitrobenzoylamino)-Phenoxyl]phthalic Acid",
      "4-[2-(3-Nitro-Benzoylamino)-Phenoxy]-Phthalic Acid",
      "4-[2-(3-Nitrobenzamido)Phenoxy]benzene-1,2-Dicarboxylic Acid",
    ],
  },
  {
    primary_id: "D0E4SX",
    names: ["VP20629"],
  },
  {
    primary_id: "D0E4TD",
    names: ["Sharon-3000"],
  },
  {
    primary_id: "D0E4TO",
    names: ["PMID25726713-Compound-13"],
  },
  {
    primary_id: "D0E4UB",
    names: ["AX-201"],
  },
  {
    primary_id: "D0E4UI",
    names: ["2,6-Diamino-5-Nitropyrimidin-4(3H)-One"],
  },
  {
    primary_id: "D0E4UM",
    names: ["PMID30247903-Compound-General Structure14"],
  },
  {
    primary_id: "D0E4UT",
    names: ["9-But-3-Enyl-9H-Adenine", "CHEMBL510907", "BDBM50256954"],
  },
  {
    primary_id: "D0E4UV",
    names: ["PMID26924192-Compound-51"],
  },
  {
    primary_id: "D0E4VQ",
    names: [
      "Beloranib",
      "251111-30-5",
      "CKD-732",
      "UNII-FI471K8BU6",
      "ZGN-433",
      "FI471K8BU6",
      "O-(4-(2-Dimethylaminoethoxy)-Trans-Cinnamoyl)Fumagillol",
      "Beloranib [INN]",
      "ZGN 433",
      "AC1OCFID",
      "ZGN-440(Beloranib)",
      "US9682965, Compound A",
      "GTPL8445",
      "SCHEMBL14648763",
      "DTXSID40179800",
      "BDBM148430",
      "ZINC3982162",
      "AKOS027326543",
      "DB12671",
      "(3R,4S,5S,6R)-5-Methoxy-4-((2R,3R)-2-Methyl-3-(3-Methylbut-2-En-1-Yl)Oxiran-2-Yl)-1-Oxaspiro(25)Octan-6-Yl (2E)-3-(4-(2-(Dimethylamino)Ethoxy)Phenyl)Prop-2-Enoate",
      "O-(4-Dimethylaminoethoxycinnamoyl)Fumagillol",
      "CKD-731",
      "Fumagillin Analogs, Chong Kun Dang",
      "HRK-429",
      "HRK-512",
    ],
  },
  {
    primary_id: "D0E4VT",
    names: ["MAHDL01"],
  },
  {
    primary_id: "D0E4WF",
    names: [
      "METHYLTHIOADENOSINE",
      "5'-Deoxy-5'-Methylthioadenosine",
      "2457-80-9",
      "Methylthioadenosine",
      "5'-S-Methyl-5'-Thioadenosine",
      "5'-Deoxy-5'-(Methylthio)Adenosine",
      "5'-DEOXY-5'-METHYLTHIOADENOSINE",
      "(2R,3R,4S,5S)-2-(6-Amino-9H-Purin-9-Yl)-5-((Methylthio)Methyl)Tetrahydrofuran-3,4-Diol",
      "Thiomethyladenosine",
      "Vitamin L2",
      "ADENOSINE, 5'-S-METHYL-5'-THIO-",
      "5'-S-Methylthioadenosine",
      "MTA",
      "S-Methyl-5'-Thioadenosine",
      "Vitamin L(Sub 2)",
      "UNII-634Z2VK3UQ",
      "5'-Deoxy(Methylthio)Adenosine",
    ],
  },
  {
    primary_id: "D0E4WR",
    names: [
      "Azelaic Acid",
      "AZA",
      "Azelaic",
      "Azelex",
      "Finacea",
      "Finevin",
      "Skinorem",
      "Skinoren",
      "Acide Azelaique",
      "Acide Azelaique [French]",
      "Acido Azelaico",
      "Acido Azelaico [Spanish]",
      "Acidum Azelaicum",
      "Acidum Azelaicum [Latin]",
      "Anchoic Acid",
      "Azalaic Acid",
      "Azelaic Acid Polyanhydride",
      "Azelaic Polyanhydride",
      "Azelainic Acid",
      "Azleaic Acid",
      "Heptanedicarboxylic Acid",
      "Lepargylic Acid",
      "NONANEDIOIC ACID",
      "Nonanedioic Acid",
      "Polyazelaic Anhydride",
      "AZ1",
      "Dicarboxylic Acid C9",
      "Emerox 1110",
      "Emerox 1144",
      "ZK 62498",
      "A-9800",
      "AGN-191861",
      "Azelaic Acid [USAN:INN]",
      "Azelaic Acid,technical Grade",
      "Azelex (TN)",
      "Finacea (TN)",
      "Finevin (TN)",
      "N-Nonanedioic Acid",
      "Nonanedioic Acid, Homopolymer",
      "Poly(Azelaic Anhydride)",
      "SH-441",
      "Skinoren (TN)",
      "ZK-62498",
      "AZELAIC ACID, 95%",
      "Azelaic Acid (USAN/INN)",
      "Emery's L-110",
      "1,7-Heptanedicarboxylic Acid",
      "1,9-Nonanedioic Acid",
    ],
  },
  {
    primary_id: "D0E4YP",
    names: [
      "SB 227122",
      "CHEMBL64322",
      "SCHEMBL3031616",
      "AYXBAIULRDEVAS-UHFFFAOYSA-N",
      "N,N-Dimethyl-N-(4-((2-(4-Methylphenyl)-6,7-Dihydro-5H-Benzocyclohepten-8-Yl)Carbonyl)Aminobenzyl)-N-(4-Tetrahydropyranyl)Ammonium Iodide",
      "N,N-Dimethyl-N-(4-((2-(4-Methylphenyl)-6,7-Dihydro-5 H-Benzocyclohepten-8-Yl)Carbonyl)Aminobenzyl)-N-(4-Tetrahydropyranyl)Ammonium Iodide",
    ],
  },
  {
    primary_id: "D0E4YR",
    names: ["IN0-3112"],
  },
  {
    primary_id: "D0E4YW",
    names: ["ARL66096", "2-Propylthio-Betagamma-Difluoromethylene ATP", "FPL66096", "ARL 66096"],
  },
  {
    primary_id: "D0E5AT",
    names: ["Pyrazolopyrimidine And Thienopyrimidine Amide Derivative 1"],
  },
  {
    primary_id: "D0E5BK",
    names: ["AGS-16M8FIAGS-16C3F"],
  },
  {
    primary_id: "D0E5BW",
    names: ["[131I]-Metuximab Injection"],
  },
  {
    primary_id: "D0E5CF",
    names: [
      "3-(Decylthio)-1,1,1-Trifluoropropan-2-One",
      "3-Decylsulfanyl-1,1,1-Trifluoropropan-2-One",
      "CHEMBL270374",
      "92682-26-3",
      "AC1L4GC5",
      "Decyl-Thio-Trifluoropropanone",
      "CTK5H1583",
      "DTXSID50239101",
      "BDBM50371969",
      "2-Propanone, 3-(Decylthio)-1,1,1-Trifluoro-",
    ],
  },
  {
    primary_id: "D0E5CI",
    names: [
      "8-Piperazin-1-Yl-Imidazo[1,2-A]pyrazine",
      "76537-53-6",
      "8-(1-Piperazinyl)Imidazo(1,2-A)Pyrazine",
      "8-(1-Piperazinyl)Imidazo[1,2-A]pyrazine",
      "CHEMBL331036",
      "8-Piperazin-1-Ylimidazo[1,2-A]pyrazine",
      "8-Pdpr",
      "Imidazo(1,2-A)Pyrazine, 8-(1-Piperazinyl)-",
      "Imidazo[1,2-A]pyrazine, 8-(1-Piperazinyl)-",
      "AC1L2YVL",
      "8-(Piperazin-1-Yl)Imidazo[1,2-A]pyrazine",
      "AC1Q4X6M",
      "SCHEMBL5496661",
      "AC1Q1I52",
      "CTK5E3085",
      "DTXSID50227346",
      "ZINC5117657",
      "BDBM50002151",
      "AKOS024075563",
      "FT-0756627",
      "Imidazo[1,2-A]pyrazine,8-(1-Piperazinyl)-",
      "A839966",
    ],
  },
  {
    primary_id: "D0E5CO",
    names: ["PMID28092474-Compound-33i"],
  },
  {
    primary_id: "D0E5CX",
    names: ["Abloid", "Amyloid Protein Deposition Inhibitor (Alzheimer's Disease), Virionics"],
  },
  {
    primary_id: "D0E5DB",
    names: [
      "Laromustine",
      "Cloretazine",
      "Onrigin",
      "Alkylating Agents, Vion",
      "SHP, Vion",
      "Sulfonyl Hydrazine Prodrugs, Vion",
      "VN-40101M",
      "VNP-40101M",
      "VNP-4090-CE",
      "90CE",
    ],
  },
  {
    primary_id: "D0E5DP",
    names: ["Cixutumumab"],
  },
  {
    primary_id: "D0E5EB",
    names: ["Impentamine"],
  },
  {
    primary_id: "D0E5ED",
    names: ["PMID29649907-Compound-5"],
  },
  {
    primary_id: "D0E5EG",
    names: ["Priorix-Tetra"],
  },
  {
    primary_id: "D0E5EW",
    names: ["8-Methyl-2-Phenyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0E5FA",
    names: ["62Cu-PTSM", "Copper62-PTSM", "Copper-62-Labelled Pyruvaldehyde Bis(N4-Methylthiosemicarbazone)"],
  },
  {
    primary_id: "D0E5FF",
    names: ["PT"],
  },
  {
    primary_id: "D0E5FG",
    names: ["ISIS 114537"],
  },
  {
    primary_id: "D0E5FV",
    names: ["ISOLICOFLAVONOL"],
  },
  {
    primary_id: "D0E5GI",
    names: ["CJ-12255"],
  },
  {
    primary_id: "D0E5GY",
    names: ["RO-319790"],
  },
  {
    primary_id: "D0E5HC",
    names: [
      "3-(5-Methoxy-1H-Indol-3-Yl)Propanoic Acid",
      "39547-16-5",
      "3-(5-Methoxy-1H-Indol-3-Yl)-Propionic Acid",
      "1H-Indole-3-Propanoic Acid, 5-Methoxy-",
      "3-(5-Methoxyindol-3-Yl)Propanoic Acid",
      "CBMicro_030931",
      "ChemDiv2_000435",
      "AC1L48GT",
      "Oprea1_046476",
      "SCHEMBL3024961",
      "CTK1C3093",
      "5-Methoxyindole-3-Propionic Acid",
      "ZLSZCJIWILJKMR-UHFFFAOYSA-N",
      "MolPort-002-085-835",
      "ZINC195148",
      "HMS3604K06",
      "HMS1370D17",
      "2429AE",
      "STK984200",
      "SBB028111",
      "AKOS000300418",
      "MCULE-1797370196",
      "DB07723",
      "CCG-108176",
      "DS-3311",
      "AK403360",
      "SC-43672",
      "BIM-0030775.P001",
      "TR-055403",
      "AX8188175",
    ],
  },
  {
    primary_id: "D0E5HO",
    names: ["Intrathecal Electrolyte Solution"],
  },
  {
    primary_id: "D0E5IJ",
    names: ["Indoline Derivative 13"],
  },
  {
    primary_id: "D0E5IM",
    names: ["PERSICARIN"],
  },
  {
    primary_id: "D0E5IP",
    names: ["WHI-P154"],
  },
  {
    primary_id: "D0E5IT",
    names: ["PMA-411R"],
  },
  {
    primary_id: "D0E5JC",
    names: ["MSX-122"],
  },
  {
    primary_id: "D0E5KK",
    names: ["MGluR2 PAMs"],
  },
  {
    primary_id: "D0E5MB",
    names: [
      "2-(4'-Chloro-Biphenyl-4-Sulfonyl)-Pentanoic Acid",
      "CHEMBL378740",
      "BDBM50185874",
      "2-(4''-Chloro-Biphenyl-4-Sulfonyl)-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0E5MJ",
    names: ["INCB19602"],
  },
  {
    primary_id: "D0E5MK",
    names: ["4,5-Bis(4-Chlorophenyl)-1,2-Selenazole", "CHEMBL501676"],
  },
  {
    primary_id: "D0E5MO",
    names: ["TrkA-XTEN"],
  },
  {
    primary_id: "D0E5NY",
    names: ["AZD-2423", "CCR2b Antagonist (Pain, COPD), AstraZeneca"],
  },
  {
    primary_id: "D0E5ON",
    names: ["Cis-4-Hydroxynipecotic Acid"],
  },
  {
    primary_id: "D0E5QB",
    names: ["Ro-65-7219"],
  },
  {
    primary_id: "D0E5QE",
    names: ["8-Hydroxy-7,9-Dimethyl-Delta-Carboline", "CHEMBL458465"],
  },
  {
    primary_id: "D0E5QN",
    names: ["CGP 71872", "CGP71872", "CGP-71872"],
  },
  {
    primary_id: "D0E5QO",
    names: ["Quinoline Derivative 7"],
  },
  {
    primary_id: "D0E5QY",
    names: ["MEN-B"],
  },
  {
    primary_id: "D0E5RA",
    names: ["HGT-1111"],
  },
  {
    primary_id: "D0E5TD",
    names: ["ISIS 110068"],
  },
  {
    primary_id: "D0E5TH",
    names: ["BTL-TML-HSV", "Antiviral (Herpes Simplex Infection), Beech Tree"],
  },
  {
    primary_id: "D0E5TI",
    names: ["Lyme Disease Vaccine"],
  },
  {
    primary_id: "D0E5UK",
    names: ["Fibrin Sealant"],
  },
  {
    primary_id: "D0E5UQ",
    names: [
      "[3H]CNQX",
      "6-Cyano-7-Nitroquinoxaline-2,3-Dione",
      "CNQX",
      "115066-14-3",
      "6-CYANO-7-NITROQUINOXALINE-2,3-DIONE",
      "7-Nitro-2,3-Dioxo-1,2,3,4-Tetrahydroquinoxaline-6-Carbonitrile",
      "UNII-6OTE87SCCW",
      "FG 9065",
      "6OTE87SCCW",
      "FG-9065",
      "6-Cyano-2,3-Dihydroxy-7-Nitroquinoxaline",
      "CHEBI:34468",
      "1,4-Dihydro-2,3-Dihydroxy-7-Nitro-6-Quinoxalinecarbonitrile",
      "7-Nitro-2,3-Dioxo-1,4-Dihydroquinoxaline-6-Carbonitrile",
      "6-Quinoxalinecarbonitrile, 1,2,3,4-Tetrahydro-7-Nitro-2,3-Dioxo-",
      "ST50405216",
      "2,3-Dihydroxy-7-Nitro-6-Quinoxalinecarbonitrile",
      "FG9065",
      "AC1MWAKF",
      "Tocris-0190",
      "Biomol-NT_000200",
    ],
  },
  {
    primary_id: "D0E5VO",
    names: ["Vinanterol Inhalation/ Fluticasone Furoate Powder"],
  },
  {
    primary_id: "D0E5VP",
    names: ["KF-66490"],
  },
  {
    primary_id: "D0E5VT",
    names: [
      "6,11-Dihydro-5H-Benzo[a]carbazole",
      "21064-49-3",
      "5H-Benzo[a]carbazole, 6,11-Dihydro-",
      "1,2,7-Trihydrobenzo[a]4aH-Carbazole",
      "ST076800",
      "5,11-Dihydro-6H-Benzo[a]carbazole",
      "5,11-DIHYDRO-6H-BENZO(A)CARBAZOLE",
      "NSC168810",
      "AC1L6RUQ",
      "Maybridge1_006237",
      "AC1Q1H7M",
      "CHEMBL239937",
      "Aza-Heterocyclic Derivative, 7",
      "SCHEMBL12126575",
      "HMS559D11",
      "CTK0J8060",
      "BDBM19186",
      "DTXSID40305026",
      "MolPort-000-737-601",
      "ZX-AN022290",
      "ALBB-023776",
      "ZINC1023865",
      "CCG-20433",
      "STK874208",
      "BBL028411",
      "5,6-Dihydro-11H-Benzo[a]carbazole",
      "AKOS000547063",
      "MCULE-4710620349",
      "NSC-168810",
      "RH 00",
    ],
  },
  {
    primary_id: "D0E5WC",
    names: ["PMID29865878-Compound-59"],
  },
  {
    primary_id: "D0E5WJ",
    names: ["SiRNA Therapeutics, Prostate Cancer"],
  },
  {
    primary_id: "D0E5WS",
    names: [
      "6-Methyl-2-P-Tolyl-Chromen-4-One",
      "88952-74-3",
      "CHEMBL133226",
      "6-Methyl-2-(4-Methylphenyl)Chromen-4-One",
      "6-Methyl-2-(4-Methylphenyl)-4H-Chromen-4-One",
      "4H-1-Benzopyran-4-One, 6-Methyl-2-(4-Methylphenyl)-",
      "ACMC-20lfbb",
      "AC1LFDMB",
      "4',6-Dimethylflavone",
      "BAS 01121723",
      "Oprea1_016511",
      "Oprea1_052018",
      "SCHEMBL10024074",
      "CTK3A4544",
      "DTXSID70354567",
      "MolPort-000-450-864",
      "ZINC265995",
      "STK888587",
      "AKOS000603226",
      "MCULE-9170697941",
      "NCGC00320777-01",
      "6-Methyl-2-(P-Tolyl)-4H-Chromen-4-One",
      "ST45177106",
      "AB00086662-03",
      "AB00086662-01",
    ],
  },
  {
    primary_id: "D0E5WX",
    names: ["PF-04518600"],
  },
  {
    primary_id: "D0E5XA",
    names: ["R763"],
  },
  {
    primary_id: "D0E5XM",
    names: ["Imbutamine"],
  },
  {
    primary_id: "D0E5YG",
    names: [
      "AZD1236",
      "(S)-5-(((4-((5-Chloropyridin-2-Yl)Oxy)Piperidin-1-Yl)Sulfonyl)Methyl)-5-Methylimidazolidine-2,4-Dione",
      "459814-90-5",
      "AZD-1236",
      "UNII-B4OQY51WZS",
      "B4OQY51WZS",
      "(S)-5-(((4-((5-Chloropyridin-2-Yl)Oxy)Piperidin-1-Yl)-Sulfonyl)Methyl)-5-Methylimidazolidine-2,4-Dione",
      "SCHEMBL942315",
      "GTPL7844",
      "DTXSID30647184",
      "SFJFBTPHDHUUPU-OAHLLOKOSA-N",
      "AZD 1236",
      "6326AB",
      "ZINC59688588",
      "AKOS016011525",
      "DB11961",
      "AX8246058",
      "KB-211575",
      "AJ-113592",
      "Piperidine, 4-((5-Chloro-2-Pyridinyl)Oxy)-1-((((4S)-4-Methyl-2,5-Dioxo-4-Imidazolidinyl)Methyl)Sulfonyl)",
    ],
  },
  {
    primary_id: "D0E5ZK",
    names: ["A1-Adenosine Receptor"],
  },
  {
    primary_id: "D0E5ZP",
    names: [
      "7-(Trifluoromethyl)-1H-Indole-2,3-Dione",
      "391-12-8",
      "7-Trifluoromethylisatin",
      "7-(Trifluoromethyl)Isatin",
      "7-(Trifluoromethyl)Indoline-2,3-Dione",
      "7-Trifluoromethyl-1h-Indole-2,3-Dione",
      "7-(Trifluoromethyl)-2,3-Dihydro-1h-Indole-2,3-Dione",
      "MXLDJTXXAYVWDF-UHFFFAOYSA-N",
      "1H-INDOLE-2,3-DIONE, 7-(TRIFLUOROMETHYL)-",
      "7-(Trifluoromethyl)-1~{H}-Indole-2,3-Dione",
      "7-Trifluoroisatin",
      "PubChem9557",
      "AC1LCPWB",
      "ACMC-1CLGD",
      "7-Trifluoromethyl-Isatin",
      "Isatin-Based Compound, 45",
      "AC1Q4IR6",
      "KSC493C7F",
      "SCHEMBL594546",
      "CHEMBL376435",
      "Jsp006801",
      "CTK3J3172",
    ],
  },
  {
    primary_id: "D0E6AF",
    names: ["Radium-223-Dichloride"],
  },
  {
    primary_id: "D0E6AG",
    names: ["CPI-1205"],
  },
  {
    primary_id: "D0E6AH",
    names: ["Bryostatin-1", "Bryostatin 1", "83314-01-6"],
  },
  {
    primary_id: "D0E6AP",
    names: ["STX"],
  },
  {
    primary_id: "D0E6BA",
    names: [
      "2,3,7-Trichloro-5-Nitroquinoxaline (TNQX)",
      "5-Nitro-2,3,7-Trichloroquinoxaline",
      "72558-70-4",
      "2,3,7-Trichloro-5-Nitroquinoxaline",
    ],
  },
  {
    primary_id: "D0E6BB",
    names: [
      "Plazomicin",
      "ACHN-490",
      "UNII-LYO9XZ250J",
      "1154757-24-0",
      "LYO9XZ250J",
      "Plazomicin [USAN:INN]",
      "Plazomicin (USAN)",
      "ZINC68150640",
      "DB12615",
      "D10151",
      "D-Streptamine,",
    ],
  },
  {
    primary_id: "D0E6BI",
    names: ["5'-Methylthio-ImmH"],
  },
  {
    primary_id: "D0E6BP",
    names: ["Ketoprofen Patch"],
  },
  {
    primary_id: "D0E6CH",
    names: ["Hydroxypropyl Cellulose"],
  },
  {
    primary_id: "D0E6DI",
    names: [
      "T487",
      "AC1MVK5U",
      "2-(2,4-Diethyloctoxy)-N-[2-(2,4-Diethyloctoxy)Ethyl]-N-Methyl-Ethanamine",
      "2-(2,4-Diethyloctoxy)-N-[2-(2,4-Diethyloctoxy)Ethyl]-N-Methylethanamine",
    ],
  },
  {
    primary_id: "D0E6ED",
    names: ["Peptide Analog 11"],
  },
  {
    primary_id: "D0E6EF",
    names: ["BMS-986192"],
  },
  {
    primary_id: "D0E6EP",
    names: ["PMID25656651-Compound-19b"],
  },
  {
    primary_id: "D0E6ES",
    names: ["S. Flexneri Type 2a-REPA Asucc"],
  },
  {
    primary_id: "D0E6FO",
    names: ["JTE-607"],
  },
  {
    primary_id: "D0E6GC",
    names: [
      "N-(6-(Pyridin-4-Yl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1086782",
      "SCHEMBL1460286",
      "BBNQSWZLWJBJIN-UHFFFAOYSA-N",
      "BDBM50313652",
    ],
  },
  {
    primary_id: "D0E6GG",
    names: ["Ro67-4853"],
  },
  {
    primary_id: "D0E6GH",
    names: ["14-O-Phenylpropylnaltrexone", "CHEMBL514774", "SCHEMBL3167650", "BDBM50249017"],
  },
  {
    primary_id: "D0E6GS",
    names: ["DasKloster 0249-01"],
  },
  {
    primary_id: "D0E6GX",
    names: ["Metformin DR"],
  },
  {
    primary_id: "D0E6HT",
    names: ["JNJ-63871860"],
  },
  {
    primary_id: "D0E6IT",
    names: ["H-MCpa-Gly-Gly-Phe-Leu-OH", "CHEMBL392275"],
  },
  {
    primary_id: "D0E6JF",
    names: ["CL-329753"],
  },
  {
    primary_id: "D0E6JG",
    names: ["Benzyldihydroxyoctenone"],
  },
  {
    primary_id: "D0E6JS",
    names: [
      "Anacardic Acid",
      "Anacardic Acid C15:3",
      "CHEMBL464925",
      "(8E,11E,14E)-Anacardic Acid",
      "NSC638512",
      "2-Hydroxy-6-(8,11,14-Pentadecatrienyl)Benzoic Acid",
      "18654-18-7",
      "2-Hydroxy-6-[(8E,11E)-Pentadeca-8,11,14-Trienyl]benzoic Acid",
      "2-Hydroxy-6-[(8E,11E)-Pentadeca-8,11,14-Trien-1-Yl]benzoic Acid",
      "6-(8(Z),11(Z),14-Pentadecatrienyl)Salicylic Acid",
      "8,11,14-Anacardic Acid",
      "Anacardic Acid IV",
      "AC1NTUKB",
      "6-(8,11,14-Pentadecatrienyl)Salicylic Acid",
      "Salicylic Acid, 6-(8,11,14-Pentadecatrienyl)-",
      "SCHEMBL154720",
      "GTPL6999",
      "Benzoic Acid, 2-Hydroxy-6-(8,11,14-Pentade",
    ],
  },
  {
    primary_id: "D0E6JX",
    names: ["KNI-10088"],
  },
  {
    primary_id: "D0E6JY",
    names: ["EDC/IL-4"],
  },
  {
    primary_id: "D0E6KA",
    names: ["ISIS 4730"],
  },
  {
    primary_id: "D0E6KF",
    names: ["Carbamide Derivative 2"],
  },
  {
    primary_id: "D0E6KN",
    names: ["GSK2647544"],
  },
  {
    primary_id: "D0E6LC",
    names: ["SPR-210"],
  },
  {
    primary_id: "D0E6LJ",
    names: ["Gp41 Inhibitors (HIV Infection), Kemia"],
  },
  {
    primary_id: "D0E6LU",
    names: ["Resorcinol Compound 10"],
  },
  {
    primary_id: "D0E6ME",
    names: ["GED-0507-34-Levo"],
  },
  {
    primary_id: "D0E6MW",
    names: ["BN-108"],
  },
  {
    primary_id: "D0E6NH",
    names: ["MEDI-570"],
  },
  {
    primary_id: "D0E6NR",
    names: ["BAY-Y-3118"],
  },
  {
    primary_id: "D0E6NW",
    names: ["VER-5384"],
  },
  {
    primary_id: "D0E6OA",
    names: ["TERIKALANT"],
  },
  {
    primary_id: "D0E6OC",
    names: ["Tranilast"],
  },
  {
    primary_id: "D0E6ON",
    names: [
      "3-(6-Amino-Purin-9-Yl)-5-M-Tolyl-Pentan-2-Ol",
      "CHEMBL418074",
      "SCHEMBL7455950",
      "ZINC13835543",
      "BDBM50070643",
      "(2S,3R)-3-(6-Amino-Purin-9-Yl)-5-M-Tolyl-Pentan-2-Ol",
    ],
  },
  {
    primary_id: "D0E6PF",
    names: ["Vacc-C5"],
  },
  {
    primary_id: "D0E6PQ",
    names: ["4-(2-Fluoro-6-Phenoxyphenyl)Piperidine", "CHEMBL574428"],
  },
  {
    primary_id: "D0E6QI",
    names: ["MEN-14295"],
  },
  {
    primary_id: "D0E6QN",
    names: ["Staphylococcus Aureus Vaccine Conjugate Pentavalent"],
  },
  {
    primary_id: "D0E6RE",
    names: ["LB-102"],
  },
  {
    primary_id: "D0E6RX",
    names: ["ARD-1600"],
  },
  {
    primary_id: "D0E6RZ",
    names: ["DAV-147"],
  },
  {
    primary_id: "D0E6SB",
    names: ["HQK-1001"],
  },
  {
    primary_id: "D0E6SO",
    names: ["Tellimagrandin II", "CHEMBL510512"],
  },
  {
    primary_id: "D0E6SQ",
    names: ["ATL1102"],
  },
  {
    primary_id: "D0E6SX",
    names: ["AZD-5069", "CXCR2 Inhibitor (COPD), Astrazeneca"],
  },
  {
    primary_id: "D0E6TO",
    names: ["2-(3-Butoxy-Phenyl)-6-Methyl-Chromen-4-One", "CHEMBL131928"],
  },
  {
    primary_id: "D0E6TQ",
    names: ["Tricyclic Indole Compound 14"],
  },
  {
    primary_id: "D0E6UA",
    names: ["Deramciclane", "EGIS-3886", "EGIS-7056", "EGYT-3886"],
  },
  {
    primary_id: "D0E6UD",
    names: [
      "FK-664",
      "1-Ethyl-6-(3,4-Dimethoxyphenyl)-3-Methyl-4-(2,4,6-Trimethylphenylimino)-1,2,3,4-Tetrahydropyrimidin-2-One",
    ],
  },
  {
    primary_id: "D0E6UU",
    names: ["AG-SCT101"],
  },
  {
    primary_id: "D0E6UZ",
    names: [
      "CUDC-907",
      "1339928-25-4",
      "Fimepinostat",
      "CUDC 907",
      "UNII-3S9RX35S5X",
      "CUDC907",
      "3S9RX35S5X",
      "CHEMBL3622533",
      "N-Hydroxy-2-[[2-(6-Methoxypyridin-3-Yl)-4-Morpholin-4-Ylthieno[3,2-D]pyrimidin-6-Yl]methyl-Methylamino]pyrimidine-5-Carboxamide",
      "Fimepinostat [USAN]",
      "PI3K/HDAC Inhibitor Centn",
      "MLS006010994",
      "SCHEMBL1284705",
      "GTPL8952",
      "KS-00000TDO",
      "EX-A742",
      "AOB6775",
      "DTXSID90712307",
      "MolPort-023-293-550",
      "JOWXJLIFIIOYMS-UHFFFAOYSA-N",
      "HMS3656H04",
      "BCP06870",
      "S2759",
      "BDBM50188961",
      "2341AH",
      "ZINC73488511",
      "ABP001045",
      "AKOS026750340",
      "SB16569",
      "CUDC-907 (PI3K/HDAC Inhibi",
    ],
  },
  {
    primary_id: "D0E6VF",
    names: ["VAX-128"],
  },
  {
    primary_id: "D0E6VL",
    names: ["RWJ-22108"],
  },
  {
    primary_id: "D0E6VN",
    names: ["NDV-4"],
  },
  {
    primary_id: "D0E6VR",
    names: ["DS-1093"],
  },
  {
    primary_id: "D0E6VS",
    names: ["Steroid Derivative 1"],
  },
  {
    primary_id: "D0E6XN",
    names: [
      "Cedar Pollen Allergen Extract",
      "Cedar Pollen Allergen Extract (Sublingual, Cedar Pollen Allergy)",
      "TO-194-SL",
      "Cedar Pollen Allergen Extract (Sublingual, Cedar Pollen Allergy), Torii",
      "Cedar Pollen Allergen Extract (Sublingual, Cedar Pollen Allergy), Torii/ALK-Abello",
    ],
  },
  {
    primary_id: "D0E6XR",
    names: [
      "Dasatinib",
      "Dasatinibum",
      "Sprycel",
      "Spyrcel",
      "Dasatinib [USAN]",
      "Dasatinib Anhydrous",
      "BMS 354825",
      "BMS354825",
      "BMS-354825",
      "Dasatinib (USAN)",
      "Dasatinib, BMS 354825",
      "SPRYCEL (TN)",
      "Sprycel (TN)",
      "BMS-354825, Sprycel, BMS354825, Dasatinib",
      "N-(2-Chloro-6-Methylphenyl)-2-[[6-[4-(2-Hydroxyethyl)-1-Piperazinyl]-2-Methyl-4-Pyrimidinyl]amino]-5-Thiazolecarboxamide, Monohydrate",
      "N-(2-Chloro-6-Methylphenyl)-2-(6-(4-(2-Hydroxyethyl)Piperazin-1-Yl)-2-Methylpyrimidin-4-Ylamino)Thiazole-5-Carboxamide",
      "N-(2-Chloro-6-Methylphenyl)-2-((6-(4-(2-Hydroxyethyl)Piperazin-1-Yl)-2-Methylpyrimidin-4-Yl)Amino)Thiazole-5-Carboxamide",
      "N-(2-Chloro-6-Methylphenyl)-2-[[6-[4-(2-Hydroxyethyl)Piperazin-1-Yl]-2-Methylpyrimidin-4-Yl]amino]-1,3-Thiazole-5-Carboxamide",
      "N-(2-CHLORO-6-METHYLPHENYL)-2-({6-[4-(2-HYDROXYETHYL)PIPERAZIN-1-YL]-2-METHYLPYRIMIDIN-4-YL}AMINO)-1,3-THIAZOLE-5-CARBOXAMIDE",
      "(18F)-N-(2-Chloro-6-Methylphenyl)-2-(6-(4-(2-Hydroxyethyl)Piperazin-1-Yl)-2-Methylpyrimidin-4-Ylamino)Thiazole-5-Carboxamide",
      "2-(6-(4-(2-Hydroxyethyl)Piperazin-1-Yl)-2-Methylpyrimidin-4-Ylamino)-N-(2-Chloro-6-Methylphenyl)Thiazole-5-Carboxamide",
    ],
  },
  {
    primary_id: "D0E6YH",
    names: ["Alkylene Linked Bis-Galanthamine Derivative"],
  },
  {
    primary_id: "D0E6YQ",
    names: [
      "Tacrine",
      "Cognex",
      "Romotal",
      "Tacrina",
      "Tacrinal",
      "Tacrinum",
      "Tenakrin",
      "Tetrahydroaminacrine",
      "Tetrahydroaminoacridine",
      "Tetrahydroaminocrin",
      "Tetrahydroaminocrine",
      "Tha",
      "Tacrine Hydrochloride",
      "BBL001044",
      "CS 12602",
      "Cognex (TN)",
      "Tacrina [INN-Spanish]",
      "Tacrinal (TN)",
      "Tacrine (INN)",
      "Tacrine [INN:BAN]",
      "Tacrinum [INN-Latin]",
      "1,2,3,4-TETRAHYDRO-9-ACRIDINAMINE",
      "1,2,3,4-TETRAHYDRO-9-ACRIDINAMINE (SEE ALSO 1684-40-8)",
      "1,2,3,4-Tetrahydro-9-Acridineamine",
      "1,2,3,4-Tetrahydro-Acridin-9-Ylamine",
      "1,2,3,4-Tetrahydroaminoacridine",
      "1,2,3,4-Tetrahydroacridin-9-Amine",
      "5-Amino-6,7,8,9-Tetrahydroacridine (European)",
      "9-AMINOTETRAHYDROACRIDINE",
      "9-Acridinamine, 1,2,3,4-Tetrahydro-(9CI)",
      "9-Amino-1,2,3,4-Tetrahydroacridine Hydrate Hydrochloride Hydrate",
      "9-Amino-1,2,3,4-Tetrahydroacridine",
    ],
  },
  {
    primary_id: "D0E6YT",
    names: ["PMID27215781-Compound-27"],
  },
  {
    primary_id: "D0E6ZI",
    names: ["BTM-0512"],
  },
  {
    primary_id: "D0E6ZN",
    names: ["TKA-731"],
  },
  {
    primary_id: "D0E6ZY",
    names: ["CDKN1A"],
  },
  {
    primary_id: "D0E7AD",
    names: ["Ibalizumab"],
  },
  {
    primary_id: "D0E7AK",
    names: ["VK-2809"],
  },
  {
    primary_id: "D0E7AS",
    names: ["SUN-N8075"],
  },
  {
    primary_id: "D0E7AZ",
    names: ["Tiotidine"],
  },
  {
    primary_id: "D0E7BC",
    names: [
      "GM-CSF-Expressing Oncolytic Adenovirus",
      "CG-4030",
      "OAS-403",
      "GM-CSF-Expressing Modified OAV001 Vector, Cell Genesys",
      "GM-CSF-Expressing Modified OAV001 Vector, Genetic Therapy",
      "GM-CSF-Expressing Oncolytic Adenovirus, Cell Genesys",
    ],
  },
  {
    primary_id: "D0E7BP",
    names: ["CP-663427"],
  },
  {
    primary_id: "D0E7BQ",
    names: [
      "2',5'-Dideoxyadenosine",
      "6698-26-6",
      "UNII-988H339Z1L",
      "Adenosine, 2',5'-Dideoxy-",
      "(2R,3S,5R)-5-(6-Amino-9H-Purin-9-Yl)-2-Methyltetrahydrofuran-3-Ol",
      "988H339Z1L",
      "(2R,3S,5R)-5-(6-Aminopurin-9-Yl)-2-Methyloxolan-3-Ol",
      "5-(6-AMINOPURIN-9-YL)-2-METHYLTETRAHYDROFURAN-3-OL",
      "NSC 95943",
      "2'5'-Dideoxyadenosine",
      "2',5-Dideoxyadenosine",
      "AC1L22QD",
      "Adenosine,2',5'-Dideoxy-",
      "SCHEMBL284737",
      "GTPL5108",
      "CHEMBL1253350",
      "ZINC40150",
      "CTK8F3849",
      "MolPort-003-941-144",
      "FFHPXOJTVQDVMO-DSYKOEDSSA-N",
      "0286AC",
      "MFCD00210904",
      "AKOS016003707",
      "VZ29944",
      "NCGC00485002-01",
    ],
  },
  {
    primary_id: "D0E7BS",
    names: ["PRX-12266"],
  },
  {
    primary_id: "D0E7BU",
    names: ["CYM2503", "CYM-2503"],
  },
  {
    primary_id: "D0E7BY",
    names: ["StMN1 Lp"],
  },
  {
    primary_id: "D0E7CA",
    names: ["Tyr-D-Ala-Phe-Thr[-D-Glc(OAc)4]-Tyr-Pro-Ser-NH2"],
  },
  {
    primary_id: "D0E7CV",
    names: ["FVATDVGPFAF", "CHEMBL385986"],
  },
  {
    primary_id: "D0E7DH",
    names: ["Factor VIII Gene Therapy"],
  },
  {
    primary_id: "D0E7ES",
    names: [
      "Cytidine",
      "65-46-3",
      "Cytosine Riboside",
      "1-Beta-D-Ribofuranosylcytosine",
      "4-Amino-1-Beta-D-Ribofuranosyl-2(1H)-Pyrimidinone",
      "1beta-Ribofuranosylcytosine",
      "Beta-D-Ribofuranoside, Cytosine-1",
      "1-Beta-Ribofuranosylcytosine",
      "Zytidin",
      "Cytidin",
      "4-Amino-1beta-D-Ribofuranosyl-2(1H)-Pyrimidinone",
      "Cytosine, 1-Beta-D-Ribofuranosyl-",
      "1beta-2'-Ribofuranosylcytosine, D-",
      "UNII-5CSZ8459RP",
      "NSC 20258",
      "Cyd",
      "CHEBI:17562",
      "2(1H)-Pyrimidinone, 4-Amino-1-Beta-D-Ribofuranosyl-",
      "EINECS 200-610-9",
      "2(1H)-Pyrimidinone, 4-Amino-1beta-D-Ribofuranosyl-",
    ],
  },
  {
    primary_id: "D0E7FC",
    names: ["STRO-001"],
  },
  {
    primary_id: "D0E7FG",
    names: ["SMND-309"],
  },
  {
    primary_id: "D0E7FM",
    names: ["GTx-758"],
  },
  {
    primary_id: "D0E7FS",
    names: [
      "Adinazolam",
      "Adinazolamum",
      "Deracyn",
      "Adinazolam [USAN]",
      "U 41123",
      "Adinazolamum [INN-Latin]",
      "Deracyn (TN)",
      "U 41,123;U-41123",
      "Adinazolam (USAN/INN)",
      "Adinazolam [USAN:BAN:INN]",
      "1-(8-Chloro-6-Phenyl-4H-[1,2,4]triazolo[4,3-A][1,4]benzodiazepin-1-Yl)-N,N-Dimethylmethanamine",
      "8-Chloro-1-((Dimethylamino)Methyl)-6-Phenyl-4H-S-Triazolo(4,3-A)(1,4)Benzodiazepine",
      "8-Chloro-1-[(Dimethylamino)Methyl]-6-Phenyl-4h-S-Triazolo[4,3-A][1,4]benzodiazepine",
    ],
  },
  {
    primary_id: "D0E7FT",
    names: ["PMID30124346-Compound-60TABLE5"],
  },
  {
    primary_id: "D0E7FU",
    names: [
      "2-Amino-N-(Quinolin-8-Yl)Benzenesulfonamide",
      "2-Amino-N-Quinolin-8-Yl-Benzenesulfonamide",
      "16082-64-7",
      "CHEMBL256062",
      "QBS",
      "2-Amino-N-Quinolin-8-Ylbenzenesulfonamide",
      "AC1LLN9A",
      "Cambridge Id 5175136",
      "Oprea1_584159",
      "CBDivE_007127",
      "SCHEMBL460773",
      "CTK4D0607",
      "DTXSID90360049",
      "NIOOKXAMJQVDGB-UHFFFAOYSA-N",
      "ZINC758699",
      "DNDI1417207",
      "CCG-15219",
      "BDBM50372507",
      "MFCD00168987",
      "AKOS000154013",
      "NCGC00161683-02",
      "NCGC00161683-01",
      "NCGC00161683-04",
      "NCGC00161683-03",
      "2-Amino-N-Quinoline-8-Yl-Benzenesulfonamide",
      "AB00074880-01",
      "2-AMINO-N-QUINOLINE-8-YL-BENZENESULFONAM",
    ],
  },
  {
    primary_id: "D0E7GN",
    names: ["GFC-012"],
  },
  {
    primary_id: "D0E7IM",
    names: ["OPB-31121"],
  },
  {
    primary_id: "D0E7IZ",
    names: ["MTI-SAM3"],
  },
  {
    primary_id: "D0E7LU",
    names: ["3,5-DHPG", "3,5-Dihydroxyphenylglycine"],
  },
  {
    primary_id: "D0E7MA",
    names: ["ATG002"],
  },
  {
    primary_id: "D0E7MV",
    names: [
      "Capreomycin",
      "Capreomycin Sulfate Standard",
      "Capreomycin Sulphate",
      "Caprocin (Disulfate)",
      "Ogostal (Disulfate)",
      "(3S)-3,6-Diamino-N-[[(2S,5S,8E,11S,15S)-15-Amino-11-[(6R)-2-Amino-1,4,5,6-Tetrahydropyrimidin-6-Yl]-8-[(Carbamoylamino)Methylidene]-2-(Hydroxymethyl)-3,6,9,12,16-Pentaoxo-1,4,7,10,13-Pentazacyclohexadec-5-Yl]methyl]hexanamide",
      "(3S)-3,6-Diamino-N-[[(2S,5S,8E,11S,15S)-15-Amino-11-[(6R)-2-Amino-1,4,5,6-Tetrahydropyrimidin-6-Yl]-8-[(Carbamoylamino)Methylidene]-2-Methyl-3,6,9,12,16-Pentaoxo-1,4,7,10,13-Pentazacyclohexadec-5-Yl]methyl]hexanamide",
    ],
  },
  {
    primary_id: "D0E7NL",
    names: ["H-Dmt-Tic-(2S,3S)-Beta-MeCha-Phe-OH", "CHEMBL219482"],
  },
  {
    primary_id: "D0E7NO",
    names: [
      "Decanoic Acid",
      "CAPRIC ACID",
      "334-48-5",
      "N-Decanoic Acid",
      "N-Capric Acid",
      "Decylic Acid",
      "Caprinic Acid",
      "Decoic Acid",
      "N-Decylic Acid",
      "N-Decoic Acid",
      "1-Nonanecarboxylic Acid",
      "Caprynic Acid",
      "Neo-Fat 10",
      "Hexacid 1095",
      "Decanoate",
      "Tert-DECANOIC ACID",
      "C10 Fatty Acid",
      "Versatic 10 Acid",
      "Versatic 10",
      "Fatty Acid(C10)",
      "NSC 5025",
      "Econosan Acid Sanitizer",
      "Decanoic Acid (Natural)",
      "UNII-4G9EDB6V73",
      "FEMA No. 2364",
      "CCRIS 4610",
      "HSDB 2751",
      "Emery 659",
      "C10:0",
      "EINECS 206-376-4",
      "EPA Pesticide Chemical Code 128955",
      "BRN 1754556",
      "AI3-04453",
      "4G9EDB6V73",
      "CHEBI:30813",
    ],
  },
  {
    primary_id: "D0E7NV",
    names: ["AVPIAQKSEK-FAM", "CHEMBL404788"],
  },
  {
    primary_id: "D0E7OA",
    names: ["PMID27376512-Compound-AsCEBP-2HPE"],
  },
  {
    primary_id: "D0E7OC",
    names: ["Serelsa"],
  },
  {
    primary_id: "D0E7OL",
    names: ["C-0084"],
  },
  {
    primary_id: "D0E7OQ",
    names: ["PMID25399762-Compound-Figure3-Lycojaponicumin B"],
  },
  {
    primary_id: "D0E7PQ",
    names: ["Vorinostat"],
  },
  {
    primary_id: "D0E7PY",
    names: ["Ro-4938581"],
  },
  {
    primary_id: "D0E7PZ",
    names: ["NOX-B11"],
  },
  {
    primary_id: "D0E7QB",
    names: ["Mirikizumab"],
  },
  {
    primary_id: "D0E7QN",
    names: ["Biapenem"],
  },
  {
    primary_id: "D0E7RC",
    names: ["HER-2/HER-1 Vaccine"],
  },
  {
    primary_id: "D0E7RI",
    names: ["PMID17556356C1a"],
  },
  {
    primary_id: "D0E7RQ",
    names: ["SNN-0029"],
  },
  {
    primary_id: "D0E7RV",
    names: ["Aposense Probe-Cytotoxic Agent Conjugates", "Aposense Probe-Cytotoxic Agent Conjugates (Cancer)"],
  },
  {
    primary_id: "D0E7SF",
    names: ["PMID25399762-Compound-Table 6-12"],
  },
  {
    primary_id: "D0E7SH",
    names: [
      "6-Chloro-5-Pentylpyrimidine-2,4(1H,3H)-Dione",
      "CHEMBL238183",
      "BDBM20057",
      "5-Substituted-6-Chlorouracil, 5d",
    ],
  },
  {
    primary_id: "D0E7TO",
    names: [
      "1-(1-(4-Bromophenyl)Ethylidene)Thiosemicarbazide",
      "CHEMBL258261",
      "NSC521067",
      "AC1NSLKG",
      "16546-06-8",
      "ZINC4709233",
      "4'-Bromoacetophenonethiosemicarbazone",
      "BDBM50376212",
      "AKOS002388584",
      "AKOS008939483",
      "NSC-521067",
    ],
  },
  {
    primary_id: "D0E7TR",
    names: ["TBI-302"],
  },
  {
    primary_id: "D0E7TU",
    names: ["PMID29671355-Compound-72b"],
  },
  {
    primary_id: "D0E7UH",
    names: [
      "Maleic Acid",
      "2-(2-Ethoxyethyl)-3-Ethylbut-2-Enedioic Acid",
      "6309-80-4",
      "AC1L5ZJW",
      "CTK6G3643",
      "CTK5B7536",
      "DTXSID10285386",
      "Maleic Acid,(2-Ethoxyethyl)Ethyl- (8CI)",
    ],
  },
  {
    primary_id: "D0E7US",
    names: ["Flu (Pre-) Pandemic"],
  },
  {
    primary_id: "D0E7UW",
    names: ["TI3"],
  },
  {
    primary_id: "D0E7VO",
    names: ["PMID24900538C2c"],
  },
  {
    primary_id: "D0E7VY",
    names: ["BAY 98-7196"],
  },
  {
    primary_id: "D0E7WH",
    names: ["KPAX002-2"],
  },
  {
    primary_id: "D0E7WU",
    names: ["ISIS 7848"],
  },
  {
    primary_id: "D0E7XM",
    names: ["BAY-2253651a"],
  },
  {
    primary_id: "D0E7XO",
    names: ["IMM-255"],
  },
  {
    primary_id: "D0E7XR",
    names: ["KNI-10283"],
  },
  {
    primary_id: "D0E7YE",
    names: ["2,6,8-Triphenyl-9H-Purine", "CHEMBL378959"],
  },
  {
    primary_id: "D0E7YK",
    names: ["(R)-5-Amino-3-(4-Chloro-Phenyl)-Pentanoic Acid", "CHEMBL62361", "(R)-Homobaclofen"],
  },
  {
    primary_id: "D0E7YQ",
    names: ["TABILAUTIDE"],
  },
  {
    primary_id: "D0E7ZN",
    names: ["PMID25553724-Compound-US2011000295210"],
  },
  {
    primary_id: "D0E7ZU",
    names: [
      "Deutetrabenazine",
      "Dutetrabenazine",
      "Austedo",
      "SD809",
      "SD-809",
      "SD 809",
      "1392826-25-3",
      "Deutetrabenazine [USAN:INN]",
    ],
  },
  {
    primary_id: "D0E8AE",
    names: ["Myolimus"],
  },
  {
    primary_id: "D0E8AS",
    names: ["Tricyclic Heterocycle Derivative 6"],
  },
  {
    primary_id: "D0E8BD",
    names: ["NM-006-MENK"],
  },
  {
    primary_id: "D0E8CC",
    names: [
      "BENZOLAMIDE",
      "3368-13-6",
      "UNII-FC5AAH89R5",
      "CL 11366",
      "FC5AAH89R5",
      "2-Benzenesulfonamido-1,3,4-Thiadiazole-5-Sulfonamide",
      "CHEMBL73962",
      "1,3,4-Thiadiazole-2-Sulfonamide, 5-[(Phenylsulfonyl)Amino]-",
      "W 1803",
      "5-(Phenylsulfonamido)-1,3,4-Thiadiazole-2-Sulfonamide",
      "2-(Phenylsulfonylamino)-1,3,4-Thiadiazole-5-Sulfonamide",
      "5-[(Phenylsulfonyl)Amino]-1,3,4-Thiadiazole-2-Sulfonamide",
      "1,3,4-Thiadiazole-2-Sulfonamide, 5-((Phenylsulfonyl)Amino)-",
      "5-Benzenesulfonamido-1,3,4-Thiadiazol-2-Sulfonamide",
      "3dbu",
      "D8W",
      "Benzolamide (BZA)",
      "3d8w",
      "AC1L2CTC",
    ],
  },
  {
    primary_id: "D0E8CI",
    names: [
      "Triprolidine",
      "Actidil",
      "Actidilat",
      "Entra",
      "Histafed",
      "Myidyl",
      "Tripolidina",
      "Triprolidin",
      "Triprolidinum",
      "Venen",
      "Triprolidine HCL",
      "Triprolidine Monohydrochloride",
      "Triprolidine Hydrochloride",
      "Triprolidine Hydrochloride Anhydrous",
      "T 6764",
      "Actidil (TN)",
      "Myidil (TN)",
      "Pro-Actidil",
      "Pro-Entra",
      "Tripolidina [INN-Spanish]",
      "Triprolidine (INN)",
      "Triprolidine Hydrochloride (Anhydrous)",
      "Triprolidine Monohydrochloride, Monohydrate",
      "Triprolidine [INN:BAN]",
      "Triprolidinum [INN-Latin]",
      "Venen (TN)",
      "Trans-2-(3-(1-Pyrrolidinyl)-1-P-Tolylpropenyl)Pyridine",
      "Trans-2-(3-(1-Pyrrolidinyl)-1-P-Tolylpropenyl)Pyridine Monohydrochloride",
      "Trans-1-(2-Pyridyl)-3-Pyrrolidino-1-P-Tolylprop-1-Ene",
      "Trans-1-(4-Methylphenyl)-1-(2-Pyridyl)-3-Pyrrolidinoprop-1-Ene",
      "Pyridine, 2-(3-(1-Pyrrolidinyl)-1-P-Tolylpropenyl)-, Monohydrochloride, Monohydrate, Stereoisomer",
      "Pyridine, 2-((1E)-1-(4-Methylphenyl)-3-(1-Pyrrolidinyl)-1-Propenyl)-, Monohydrochloride",
      "Pyridine, 2-((1E)-1-(4-Methylphenyl)-3-(1-Pyrrolidinyl)-1-Propen-1-Yl)-, Hydrochloride (1:1)",
      "(E)-2-(1-(4-Methylphenyl)-3-(1-Pyrrolidinyl)-1-Propenyl)Pyridine",
      "(E)-2-[3-(1-Pyrrolidinyl)-1-P-Tolylpropenyl]pyridine Hydrochloride",
      "2-[(E)-1-(4-Methylphenyl)-3-Pyrrolidin-1-Ium-1-Ylprop-1-Enyl]pyridine Chloride",
      "2-[(E)-1-(4-Methylphenyl)-3-Pyrrolidin-1-Ylprop-1-Enyl]pyridine",
      "2-[(E)-1-(4-Methylphenyl)-3-Pyrrolidin-1-Ylprop-1-Enyl]pyridine Hydrochloride",
    ],
  },
  {
    primary_id: "D0E8CZ",
    names: ["Amibegron"],
  },
  {
    primary_id: "D0E8DK",
    names: ["COL-1077"],
  },
  {
    primary_id: "D0E8DM",
    names: [
      "3-(4-Pyridin-2-Yl-Pyrazol-1-Yl)-Benzonitrile",
      "CHEMBL189389",
      "546141-91-7",
      "SCHEMBL3922456",
      "CTK1F8523",
      "DTXSID40432285",
      "ZINC13582324",
      "BDBM50151901",
      "Benzonitrile, 3-[4-(2-Pyridinyl)-1H-Pyrazol-1-Yl]-",
    ],
  },
  {
    primary_id: "D0E8DX",
    names: [
      "6-Pyridin-3-Yl-3,4-Dihydroquinoline-2(1H)-Thione",
      "CHEMBL511329",
      "SCHEMBL1119425",
      "BBIRVEDVKRWBOZ-UHFFFAOYSA-N",
      "BDBM50273815",
      "ZINC40380214",
      "6-Pyridin-3-Yl-3,4-Dihydro-1H-Quinoline-2-Thione",
    ],
  },
  {
    primary_id: "D0E8FG",
    names: ["1-(1-Benzo[b]thiophen-2-Yl-Cycloheptyl)-Azepane", "CHEMBL18190", "BDBM50040196"],
  },
  {
    primary_id: "D0E8GM",
    names: ["(+)-LSD"],
  },
  {
    primary_id: "D0E8GT",
    names: ["ISIS 109502"],
  },
  {
    primary_id: "D0E8HA",
    names: [
      "T-817MA",
      "Edonerpic Maleate",
      "UNII-0LB9F7I5P3",
      "0LB9F7I5P3",
      "519187-97-4",
      "T-817",
      "T-817 Maleate",
      "SCHEMBL48064",
      "RLUCYBFCLXANSO-BTJKTKAUSA-N",
      "HY-17631A",
      "DC10762",
      "CS-8069",
      "J2.179.155E",
      "1-(3-(2-(1-Benzothiophen-5-Yl)Ethoxy)Propyl)Azetidin-3-Ol Maleate",
      "1-{3-[2-(1-Benzothiophen-5-Yl)Ethoxy]propyl}-3-Azetidinol Maleate",
      "1-(3-(2-(Benzo(B)Thiophene-5-Yl)Ethoxy)Propyl)Azetidine-3-Ol Maleate",
      "1-(3-(2-(1-Benzothiophene-5-Yl)Ethoxy)Propyl)-3-Azetidinol Maleate",
      "3-Azetidinol, 1-(3-(2-Benzo(B)Thien-5-Ylethoxy)Propyl)-, (2Z)-2-Butenedioate (1:",
    ],
  },
  {
    primary_id: "D0E8HU",
    names: [
      "3-Fluoromethyl-1,2,3,4-Tetrahydro-Isoquinoline",
      "CHEMBL121531",
      "Isoquinoline, 3-(Fluoromethyl)-1,2,3,4-Tetrahydro-",
      "SCHEMBL6847135",
      "BDBM50080517",
      "AKOS024125792",
    ],
  },
  {
    primary_id: "D0E8IE",
    names: [
      "4-(2-Oxo-2H-Chromene-3-Carboxamido)Benzoic Acid",
      "4-[(2-Oxo-2H-Chromene-3-Carbonyl)-Amino]-Benzoic Acid",
      "4-[(2-Oxochromen-3-Yl)Carbonylamino]benzoic Acid",
      "4-{[(2-Oxo-2H-Chromen-3-Yl)Carbonyl]amino}benzoic Acid",
      "BAS 00518567",
      "AC1MJCO7",
      "3-Carboxamido Coumarin, 19",
      "Oprea1_826423",
      "Oprea1_127959",
      "CHEMBL513209",
      "SCHEMBL4389144",
      "BDBM29169",
      "MolPort-000-375-260",
      "ZINC3076649",
      "STL353172",
      "SBB041117",
      "BBL026015",
      "AKOS000269615",
      "MCULE-2534949799",
      "ST50102584",
      "4-[(2-Oxochromene-3-Carbonyl)Amino]benzoic Acid",
    ],
  },
  {
    primary_id: "D0E8IG",
    names: ["BA-016"],
  },
  {
    primary_id: "D0E8IH",
    names: [
      "2-(2-Methoxy-Phenyl)-6-Methyl-Chromen-4-One",
      "89112-85-6",
      "2-(2-Methoxyphenyl)-6-Methyl-4H-Chromen-4-One",
      "CHEMBL334355",
      "2-(2-Methoxyphenyl)-6-Methylchromen-4-One",
      "4H-1-Benzopyran-4-One, 2-(2-Methoxyphenyl)-6-Methyl-",
      "F3139-0622",
      "ACMC-20lhvv",
      "AC1LIFWU",
      "2'-Methoxy-6-Methylflavone",
      "CTK3A1211",
      "DTXSID40358996",
      "QEIHFNZSIRWEBX-UHFFFAOYSA-N",
      "MolPort-000-450-861",
      "ZINC521727",
      "STK788037",
      "AKOS002182005",
      "MCULE-9515838114",
      "NCGC00339312-01",
      "ST4146766",
    ],
  },
  {
    primary_id: "D0E8IJ",
    names: ["3-Hydroxy-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL233618"],
  },
  {
    primary_id: "D0E8IK",
    names: ["ISIS 109112"],
  },
  {
    primary_id: "D0E8IQ",
    names: [
      "DS-8201",
      "9-Aminofluorene",
      "9H-Fluoren-9-Amine",
      "525-03-1",
      "FLUOREN-9-AMINE",
      "Fluoren-9-Ylamine",
      "UNII-4NHO2K4K5B",
      "CCRIS 7000",
      "BRN 2209545",
      "4NHO2K4K5B",
      "OUGMRQJTULXVDC-UHFFFAOYSA-N",
      "Fluorene-9-Ylamine",
      "9-Amino-Fluoren",
      "9-Amino-Fluorene",
      "9H-9-Fluorenamine",
      "9H-Fluoren-9-Yl-Amine",
      "AC1L1VP5",
      "4-12-00-03390 (Beilstein Handbook Reference)",
      "SCHEMBL353865",
      "AC1Q53A2",
      "AC1Q53A1",
      "KS-00000JGC",
      "CTK1H0380",
      "DTXSID90200496",
      "MolPort-001-794-448",
      "HMS1780P20",
      "9H-Fluoren-9-Ylamine Hydrochloride",
      "ZINC1724407",
      "ALBB-023296",
      "CA-733",
      "SBB005783",
      "AKOS000264388",
      "MCULE-8757055914",
      "DS-",
    ],
  },
  {
    primary_id: "D0E8IT",
    names: ["HBVax"],
  },
  {
    primary_id: "D0E8IZ",
    names: ["Tonabersat"],
  },
  {
    primary_id: "D0E8JL",
    names: ["ZT-386"],
  },
  {
    primary_id: "D0E8JM",
    names: [
      "3-Sulfinoalanine",
      "2-Amino-3-Sulfinopropanoic Acid",
      "2381-08-0",
      "Alanine 3-Sulfinic Acid",
      "Cysteine Hydrogen Sulfite Ester",
      "L-Cysteinesulfinic Acid Monohydrate",
      "Alanine, 3-Sulfino-",
      "UNII-56X032NVQL",
      "56X032NVQL",
      "Cysteinesulfinic Acid, L-",
      "AC1Q5S9C",
      "MLS000859909",
      "SCHEMBL443654",
      "AC1L18I0",
      "GTPL4695",
      "CHEMBL1702607",
      "2-Amino-3-Sulfinopropionic Acid",
      "BDBM86194",
      "NSC_109",
      "CAS_109",
      "ADVPTQAUNPRNPO-UHFFFAOYSA-N",
      "HMS3369H02",
      "HMS3266C09",
      "HMS2235M21",
      "EINECS 214-228-5",
      "AKOS022145786",
      "SMR000326770",
      "ST45022126",
      "207121-48-0",
    ],
  },
  {
    primary_id: "D0E8JO",
    names: [
      "FO-152",
      "AC1OCEZ1",
      "[(2R,3S,4R,5R)-5-(5-Fluoro-2,4-Dioxopyrimidin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl (2S)-2-Amino-3-Methylbutanoate Hydrochloride",
    ],
  },
  {
    primary_id: "D0E8KF",
    names: ["N-(6-Ethoxypyridin-2-Yl)Acetamide"],
  },
  {
    primary_id: "D0E8KK",
    names: ["IkT-041"],
  },
  {
    primary_id: "D0E8KO",
    names: ["Anti-CD3 Activated Vaccine-Primed Lymphocytes"],
  },
  {
    primary_id: "D0E8KR",
    names: ["Octanedioic Acid Hydroxyamide Pyridin-2-Ylamide"],
  },
  {
    primary_id: "D0E8KS",
    names: ["SB 203186"],
  },
  {
    primary_id: "D0E8LA",
    names: ["Saroglitazar"],
  },
  {
    primary_id: "D0E8LT",
    names: ["Prifinium"],
  },
  {
    primary_id: "D0E8MW",
    names: [
      "D-1367",
      "167692-94-6",
      "2-(3,3-Dimethylbutyl)-4,4,5,5-Tetramethyl-1,3,2-Dioxaborolane",
      "SCHEMBL15923700",
      "DTXSID80570615",
      "ZINC196470483",
      "SC-92964",
      "2-(3,3-Dimethylbutyl)-4,4,5,5,-Tetramethyl-1,3,2-D",
      "1,3,2-Dioxaborolane, 2-(3,3-Dimethylbutyl)-4,4,5,5-Tetramethyl-",
    ],
  },
  {
    primary_id: "D0E8NJ",
    names: ["ABP 798"],
  },
  {
    primary_id: "D0E8NP",
    names: [
      "Birinapant",
      "1260251-31-7",
      "Birinapant (TL32711)",
      "TL-32711",
      "UNII-6O4Z07B57R",
      "TL 32711",
      "6O4Z07B57R",
      "(2S)-N-[(2S)-1-[(2R,4S)-2-[[6-Fluoro-2-[6-Fluoro-3-[[(2R,4S)-4-Hydroxy-1-[(2S)-2-[[(2S)-2-(Methylamino)Propanoyl]amino]butanoyl]pyrrolidin-2-Yl]methyl]-1H-Indol-2-Yl]-1H-Indol-3-Yl]methyl]-4-Hydroxypyrrolidin-1-Yl]-1-Oxobutan-2-Yl]-2-(Methylamino)Propanamide",
    ],
  },
  {
    primary_id: "D0E8NW",
    names: ["KUR-115"],
  },
  {
    primary_id: "D0E8OO",
    names: ["ISIS 4764"],
  },
  {
    primary_id: "D0E8PG",
    names: ["BGB-3112"],
  },
  {
    primary_id: "D0E8PR",
    names: ["BAY1163877"],
  },
  {
    primary_id: "D0E8QE",
    names: ["Vesigenurtacel-L"],
  },
  {
    primary_id: "D0E8QK",
    names: [
      "JNJ-26070109",
      "CCK2 Receptor Antagonists, (Oral, Cancer)",
      "CCK2 Receptor Antagonists, (Oral, Cancer),Johnson & Johnson Pharmaceutical Research & Development",
    ],
  },
  {
    primary_id: "D0E8RA",
    names: [
      "8-Bromo-9-(2,3-Dihydroxypropyl)-9H-Adenine",
      "CHEMBL505633",
      "3-(6-Amino-8-Bromopurin-9-Yl)Propane-1,2-Diol",
      "3-(6-Amino-8-Bromo-Purin-9-Yl)-Propane-1,2-Diol",
      "AC1MCMJY",
      "C8H10BrN5O2",
      "Oprea1_182885",
      "Oprea1_060925",
      "MolPort-000-712-951",
      "3-(6-AMINO-8-BROMO-9H-PURIN-9-YL)PROPANE-1,2-DIOL",
      "STL558390",
      "CCG-18677",
      "BDBM50257096",
      "AKOS001644314",
      "MCULE-9507586118",
      "BAS 00110783",
      "69369-04-6",
      "ST50975882",
    ],
  },
  {
    primary_id: "D0E8SE",
    names: ["443C81", "BW 443C", "BW-443C81"],
  },
  {
    primary_id: "D0E8SV",
    names: ["RG7906"],
  },
  {
    primary_id: "D0E8TK",
    names: ["SPN-802"],
  },
  {
    primary_id: "D0E8TW",
    names: ["MRS2690"],
  },
  {
    primary_id: "D0E8UH",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Ser(Bzl)-D-Pro-)", "CHEMBL241555"],
  },
  {
    primary_id: "D0E8UJ",
    names: ["BW-1370U87", "1-Ethylphenoxathiin-10,10-Dioxide"],
  },
  {
    primary_id: "D0E8UO",
    names: ["Urokinase"],
  },
  {
    primary_id: "D0E8UP",
    names: ["PMID25666693-Compound-69"],
  },
  {
    primary_id: "D0E8UV",
    names: ["Linetastine"],
  },
  {
    primary_id: "D0E8VD",
    names: ["Erythribyssin L"],
  },
  {
    primary_id: "D0E8VF",
    names: ["IMC-1C11"],
  },
  {
    primary_id: "D0E8WW",
    names: ["Peptide Analog 46"],
  },
  {
    primary_id: "D0E8XV",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 3"],
  },
  {
    primary_id: "D0E8YH",
    names: ["NCX 1022"],
  },
  {
    primary_id: "D0E8YS",
    names: ["MOR-206"],
  },
  {
    primary_id: "D0E8ZH",
    names: ["HOE-33258"],
  },
  {
    primary_id: "D0E8ZI",
    names: ["FOV-2304"],
  },
  {
    primary_id: "D0E8ZL",
    names: ["CD20-CART"],
  },
  {
    primary_id: "D0E8ZY",
    names: ["Vapitadine"],
  },
  {
    primary_id: "D0E9AF",
    names: ["ALN-TTRsc"],
  },
  {
    primary_id: "D0E9AL",
    names: ["BPH-628", "BPH 628", "B28", "BPH628"],
  },
  {
    primary_id: "D0E9AY",
    names: ["Grifolic Acid"],
  },
  {
    primary_id: "D0E9AZ",
    names: ["Vax-TET"],
  },
  {
    primary_id: "D0E9BF",
    names: [
      "5-Hydroxymethyl-2-Furfural (Sickle Cell Disease), AesRx LLC",
      "Aes-103",
      "5-HMF",
      "5-Hydroxymethylfurfural",
    ],
  },
  {
    primary_id: "D0E9BQ",
    names: ["Anti-Neu5Gc Mabs", "Anti-Neu5Gc MAbs (Cancer)"],
  },
  {
    primary_id: "D0E9CC",
    names: ["Ombrabulin", "AVE8062"],
  },
  {
    primary_id: "D0E9CD",
    names: ["Isovanillin"],
  },
  {
    primary_id: "D0E9CG",
    names: [
      "3-Phenyl-1-Propylquinazoline-2,4(1H,3H)-Dione",
      "CHEMBL479747",
      "MolPort-002-665-959",
      "ZINC6670445",
      "STK789842",
      "BDBM50251664",
      "AKOS003747776",
      "MCULE-9417936038",
    ],
  },
  {
    primary_id: "D0E9CK",
    names: ["Aryl Alkanolamine Derivative 1"],
  },
  {
    primary_id: "D0E9CT",
    names: ["Genz-10850"],
  },
  {
    primary_id: "D0E9CV",
    names: ["Benzamide Derivative 4"],
  },
  {
    primary_id: "D0E9DD",
    names: ["AZUMAMIDE E", "Azumamide E", "CHEMBL402363"],
  },
  {
    primary_id: "D0E9DE",
    names: ["BMS Compound 4c"],
  },
  {
    primary_id: "D0E9DI",
    names: ["2-(P-Toluidino)-4-Phenylpyrimidine-5-Carbonitrile", "CHEMBL233874"],
  },
  {
    primary_id: "D0E9DP",
    names: [
      "AAV-IGF",
      "AAV-IGF (Amyotrophic Lateral Sclerosis)",
      "AAV1-IGF-1",
      "AAV-IGF (Amyotrophic Lateral Sclerosis), Genzyme",
    ],
  },
  {
    primary_id: "D0E9EE",
    names: ["SR 144190"],
  },
  {
    primary_id: "D0E9EH",
    names: ["Acetyl-Ala-Ala-Pro-Ala-Trifluromethane", "CHEMBL130128", "BDBM50014742"],
  },
  {
    primary_id: "D0E9FD",
    names: ["SN-1"],
  },
  {
    primary_id: "D0E9GD",
    names: ["Biphenyl Mannoside Derivative 15"],
  },
  {
    primary_id: "D0E9GF",
    names: ["A-Substituted Phenylpropionic Acid Derivative 1"],
  },
  {
    primary_id: "D0E9HC",
    names: ["1-(Bis(4-Chlorophenyl)Methyl)-3-Phenylurea", "CHEMBL375286", "ZINC13676236"],
  },
  {
    primary_id: "D0E9HQ",
    names: ["R7334"],
  },
  {
    primary_id: "D0E9HW",
    names: ["Salvinorin B Propoxymethyl Ether", "CHEMBL258285"],
  },
  {
    primary_id: "D0E9HY",
    names: ["CCG-1423", "CCG1423", "CCG 1423"],
  },
  {
    primary_id: "D0E9IM",
    names: ["L-Iso-Aspartate"],
  },
  {
    primary_id: "D0E9IT",
    names: ["N,N`-Diacetyl-L-Cystine", "DiNAC", "DiNAC, AstraZeneca", "H-327/86"],
  },
  {
    primary_id: "D0E9IY",
    names: ["PMID28350212-Compound-13"],
  },
  {
    primary_id: "D0E9JD",
    names: ["Quinoline 2"],
  },
  {
    primary_id: "D0E9JI",
    names: ["Tricyclic Indole Compound 13"],
  },
  {
    primary_id: "D0E9JM",
    names: [
      "Potassium Chloride",
      "Acronitol",
      "Celeka",
      "Chloropotassuril",
      "Chlorvescent",
      "Durekal",
      "Durules",
      "Enpott",
      "Enseal",
      "Infalyte",
      "KCL",
      "KSR",
      "Kadalex",
      "Kalcorid",
      "Kaleorid",
      "Kaleorod",
      "Kaliduron",
      "Kaliglutol",
      "Kalilente",
      "Kalinorm",
      "Kaliolite",
      "Kalipor",
      "Kalipoz",
      "Kalitabs",
      "Kaliumchlorid",
      "Kaochlor",
      "Kaskay",
      "Kato",
      "Kayback",
      "Kayciel",
      "Kaysiel",
      "Keylyte",
      "Kloren",
      "Klorvess",
      "Klotrix",
      "Kolyum",
      "Miopotasio",
      "Neobakasal",
      "Pfiklor",
      "Potasion",
      "Potasol",
      "Potavescent",
      "Rekawan",
      "Selora",
      "Steropotassium",
      "Sylvite",
      "[KCl]",
      "Chlorid Draselny",
      "Chlorid Draselny [Czech]",
      "Chloride Of Potash",
      "Conductance Standard Solution",
      "Dipotassium Dichloride",
      "Emplets Potassium Chloride",
      "K Tab",
      "KCL Retard",
      "KM Potassium Chloride",
      "Kalinorm Depottab",
      "Kalitrans Retard",
      "Kalium Duriles",
      "Kalium Durules",
      "Kalium Retard",
      "Kalium SR",
      "Kaon Cl",
      "Kaon Ultra",
      "Kay Ciel",
      "Kelp Salt",
      "Leo K",
      "Monopotassium Chloride",
      "Muriate Of Potash",
      "Natural Sylvite",
      "Plus Kalium Retard",
      "Potassium Chloride BP",
      "Potassium Chloride In Plastic Container",
      "Potassium Cl",
      "Potassium Chloride [JAN]",
      "Potassium Chloride Solution",
      "Potassium Chloride Standard",
      "Potassium Monochloride",
      "Potassium Muriate",
      "Rekawan Retard",
      "Repone K",
      "Sal Digestnum Sylvii",
      "Super K",
      "Tripotassium Trichloride",
      "Trona Muriate Of Potash",
      "Trona Potassium Chloride",
      "Ultra K Chlor",
      "IN2900",
      "Potassium Chloride 10meq In Plastic Container",
      "Potassium Chloride 20meq Inplastic Container",
      "Potassium Chloride 30meq In Plastic Container",
      "Potassium Chloride 40meq In Plastic Container",
      "Addi-K",
      "Apo-K",
      "Cena-K",
      "Chloride, Potassium",
      "Chloropotassuril Diffu-K",
      "Diffu-K",
      "Duffi-K",
      "Durules-K",
      "K+10",
      "K+8",
      "K-10",
      "K-Care",
      "K-Contin",
      "K-Dur",
      "K-Grad",
      "K-Lease",
      "K-Lor",
      "K-Lyte Cl",
      "K-Norm",
      "K-SR",
      "K-Sol",
      "K-Tab",
      "K. Tab",
      "KCL (TN)",
      "KCl-Retard Zyma",
      "Kalinor-Retard P",
      "Kalium S.R",
      "Kalium-Durettes",
      "Kalium-R",
      "Kalium-Duriles",
      "Kaon Cl-10",
      "Kaon-Cl",
      "Kaon-Cl 10",
      "Kaon-Cl TABS",
      "Kaon-Ci",
      "Kay-Ciel",
      "Kay-EM",
      "Klor-Con",
      "Klor-Con M10",
      "Klor-Con M15",
      "Klor-Con M20",
      "Klor-Lyte",
      "Klotrix (TN)",
      "Lento-K",
      "Lento-Kalium",
      "Leo-K",
      "Micro-K",
      "Micro-K 10",
      "Micro-K Extentcaps",
      "Micro-K LS",
      "Micro-Kalium Retard",
      "Nu-K",
      "POTASSIUM CHLORIDE, ACS",
      "Peter-Kal",
      "Potassium Atomic Spectroscopy Standard Concentrate 1.00 G K",
      "Potassium Chloride (K3Cl3)",
      "Potassium Chloride (KCl)",
      "Potassium Standard Concentrate 10.00 G K",
      "Potassium Thallium Chloride (KTlCl)",
      "Repone-K",
      "Rum-K",
      "Sando-K",
      "Slow-K",
      "Slow-K Tablets",
      "Span-K",
      "Super K (Salt)",
      "Ten-K",
      "Clor-K-Zaf",
      "Hydrochloric Acid Potassium Salt (1:1)",
      "ISA (Ionic Strength Adjustment Solution: 1 M KCl)",
      "K-Lyte/Cl",
      "K-Predne-Dome",
      "K-Dur (TN)",
      "K-Lyte/C1",
      "Kalium S.R.",
      "Kaon-Cl (TN)",
      "Kay-Cee-L",
      "Klor-Con (TN)",
      "Potassium Chloride (JP15/USP)",
      "Ultra-K-Chlor",
      "Conductivity Standard Solution, 1413-S/Cm",
      "Conductivity Standard Solution, 500-S/Cm",
      "POTASSIUM CHLORIDE, U.S.P.",
      "11118_FLUKA",
    ],
  },
  {
    primary_id: "D0E9JR",
    names: [
      "Ethyl 2-Cyano-2-(Quinoxalin-2(1H)-Ylidene)Acetate",
      "AC1NVSSE",
      "MLS000104229",
      "CHEMBL3197417",
      "MolPort-002-136-693",
      "MolPort-019-735-612",
      "HMS2400I13",
      "CCG-2361",
      "ZINC18045921",
      "STL321690",
      "AKOS022107323",
      "SMR000051278",
      "SR-01000198931",
      "SR-01000198931-1",
      "Ethyl (2Z)-Cyano(Quinoxalin-2(1H)-Ylidene)Ethanoate",
      "Ethyl (2Z)-2-Cyano-2-(1H-Quinoxalin-2-Ylidene)Acetate",
    ],
  },
  {
    primary_id: "D0E9KA",
    names: ["Ingenol Mebutate"],
  },
  {
    primary_id: "D0E9KB",
    names: [
      "N,N'-Bis-(4-Butoxy-Phenyl)-Guanidine",
      "CHEMBL326502",
      "SCHEMBL4983215",
      "N,n'-Bis(4-Butoxyphenyl)Guanidine",
      "BDBM50066423",
    ],
  },
  {
    primary_id: "D0E9KC",
    names: [
      "AFM-15",
      "Anti-CD3 Humanized Antibody (Diabody/TandAb, Type I Diabetes/Autoimmune/Inflammatory Diseases), Affimed",
    ],
  },
  {
    primary_id: "D0E9LH",
    names: [
      "CDRI-99/411",
      "Artemisinin Derivative (Oral, Malaria) CDRI",
      "Artemisinin Derivative (Oral, Malaria) IPCA Laboratories",
      "99/411",
    ],
  },
  {
    primary_id: "D0E9LJ",
    names: [
      "ADP Ribose",
      "ADPR (Nucleotide)",
      "Adenosine Diphosphoribose",
      "Ribose Adenosinediphosphate",
      "Adenosine Pyrophosphate-Ribose",
      "[32P]-ADP-Ribose",
      "AC1L1J3O",
      "GTPL2444",
      "Adenosine 5'-Diphosphate, D-Ribose Ester",
      "Adenosine 5'-Diphosphate, D-Ribose Ester (6CI)",
      "Adenosine 5'-Pyrophosphate, 5'-5-Ester With D-Ribofuranose (7CI)",
      "Ribofuranose, 5-5'-Ester With Adenosine 5'-(Trihydrogen Pyrophosphate), D-",
      "Adenosine 5'-(Trihydrogen Pyrophosphate), 5'-5-Ester With D-Ribofuranose",
      "Adenosine 5'-(Trihydrogen Diphosphate), P'-5-Ester With D-Ribo",
    ],
  },
  {
    primary_id: "D0E9MC",
    names: [
      "CP-339818",
      "CHEMBL281622",
      "AC1LCVJB",
      "CP 339818",
      "Tocris-1399",
      "C13847",
      "(1-Benzyl-1H-Quinolin-4-Ylidene)-Pentyl-Amine",
      "SureCN12648579",
      "GTPL2558",
      "SCHEMBL12648579",
      "CHEBI:34602",
      "1-Benzyl-N-Pentylquinolin-4-Imine",
      "1-Benzyl-N-Pentyl-Quinolin-4-Imine",
      "ZINC17919916",
      "BDBM50083523",
      "ZINC100002080",
      "ZINC253533285",
      "NCGC00025144-01",
      "AJ-70179",
      "CP339818",
      "AJ-70180",
      "N-Pentyl-1-(Phenylmethyl)Quinolin-4-Imine",
    ],
  },
  {
    primary_id: "D0E9ME",
    names: ["MEDI7247"],
  },
  {
    primary_id: "D0E9MI",
    names: ["FDY-5301"],
  },
  {
    primary_id: "D0E9MO",
    names: ["PMID25482888-Compound-14"],
  },
  {
    primary_id: "D0E9NJ",
    names: ["PARCETASAL"],
  },
  {
    primary_id: "D0E9NT",
    names: [
      "2,3,4-Trihydroxybenzoic Acid",
      "2,3,4-TRIHYDROXYBENZOIC ACID",
      "610-02-6",
      "4-Pyrogallolcarboxylic Acid",
      "Pyrogallolcarboxylic Acid",
      "2,3,4-Trihydroxybenzoate",
      "Pyrogallol-4-Carboxylic Acid",
      "UNII-AD1ID2JF5O",
      "Benzoic Acid, Trihydroxy-",
      "Benzoic Acid, 2,3,4-Trihydroxy-",
      "2,3,4-Trihydroxybenzene Carboxylic Acid",
      "EINECS 210-205-9",
      "NSC 27436",
      "AD1ID2JF5O",
      "BRN 2210807",
      "CHEMBL220779",
      "CHEBI:88714",
      "2,3,4-Trihydroxybenzoic Acid, 97%",
      "AK-94172",
      "W-105210",
      "Pyrogallolcarboxylate",
      "Trihydroxybenzoic Acid",
      "2,4-Trihydroxybenzoate",
      "AC1L1YC0",
      "2,4-Trihydroxybenzoic Acid",
      "Oprea1_870375",
    ],
  },
  {
    primary_id: "D0E9NU",
    names: ["KRN-23"],
  },
  {
    primary_id: "D0E9OI",
    names: ["DWE-01"],
  },
  {
    primary_id: "D0E9ON",
    names: [
      "AC-(D)PHE-PRO-BOROHOMOORNITHINE-OH",
      "AC1NRAS2",
      "DB07660",
      "N-Acetyl-D-Phenylalanyl-N-[(1R)-4-Amino-1-(Dihydroxyboranyl)Butyl]-L-Prolinamide",
      "(1R)-4-Amino-1-{[(2S)-1-[(2R)-2-Acetamido-3-Phenylpropanoyl]pyrrolidin-2-Yl]formamido}butylboronic Acid",
      "[(1R)-1-[[(2S)-1-[(2R)-2-Acetamido-3-Phenylpropanoyl]pyrrolidine-2-Carbonyl]amino]-4-Aminobutyl]boronic Acid",
    ],
  },
  {
    primary_id: "D0E9PE",
    names: ["Tricyclic Compound 6"],
  },
  {
    primary_id: "D0E9PK",
    names: [
      "MOXONIDINE",
      "BDF-5895",
      "BE-5895",
      "Normoxin",
      "AC1OCENE",
      "SCHEMBL1230620",
      "Moxonidine Hydrochloride Hydrate",
      "Moxonidine Hydrochloride Monohydrate",
      "LY-326869",
      "4-Chloro-N-(4,5-Dihydro-1H-Imidazol-2-Yl)-6-Methoxy-2-Methylpyrimidin-5-Amine Hydrate Hydrochloride",
    ],
  },
  {
    primary_id: "D0E9PL",
    names: [
      "6-Fluoro-5-Phenylpyrimidine-2,4(1H,3H)-Dione",
      "CHEMBL395907",
      "BDBM20075",
      "6-Fluoro-5-Phenylpyrimidine-2,4(1H,3H)-Dione, 21",
    ],
  },
  {
    primary_id: "D0E9PT",
    names: [
      "CK-2289",
      "101183-99-7",
      "CK 2289",
      "CHEMBL17338",
      "2H-Imidazol-2-One,4-Ethyl-1,3-Dihydro-5-[4-(1H-Imidazol-1-Yl)Benzoyl]-",
      "FIYSLYBKJKWZMC-UHFFFAOYSA-N",
      "AC1L2SLK",
      "ACMC-20m483",
      "SCHEMBL7266072",
      "CTK3J9593",
      "DTXSID40143763",
      "4-Ethyl-5-(4-Imidazol-1-Ylbenzoyl)-1,3-Dihydroimidazol-2-One",
      "BDBM50226869",
      "4-Ethyl-1,3-Dihydro-5-[4-(1H-Imidazol-1-Yl)Benzoyl]-2H-Imidazol-2-One",
      "2H-Imidazol-2-One, 4-Ethyl-1,3-Dihydro-5-(4-(1H-Imidazol-1-Yl)Benzoyl)-",
    ],
  },
  {
    primary_id: "D0E9QX",
    names: [
      "(S)-PIA",
      "CHEMBL420705",
      "38594-97-7",
      "(2R,3S,4R,5R)-2-(Hydroxymethyl)-5-[6-[[(2S)-1-Phenylpropan-2-Yl]amino]purin-9-Yl]oxolane-3,4-Diol",
      "S-PIA",
      "N6-((S)-1-Methyl-2-Phenylethyl)Adenosine",
      "AC1NSM10",
      "GTPL415",
      "SCHEMBL6762494",
      "CTK1C3340",
      "2-Hydroxymethyl-5-[6-(1-Methyl-2-Phenyl-Ethylamino)-Purin-9-Yl]-Tetrahydro-Furan-3,4-Diol",
      "S(+)-N6-(2-PHENYLISOPROPYL)ADENOSINE (S( +)-PIA)",
      "N6-(D-2-Phenylisopropyl)Adenosine",
      "ZINC1320081",
      "PDSP2_001015",
      "PDSP1_000298",
      "PDSP2_000296",
      "PDSP1_001031",
      "BDBM50080398",
    ],
  },
  {
    primary_id: "D0E9QZ",
    names: ["Copper Catalyst-Based Agent", "Copper Catalyst-Based Agent (Overactive Bladder)"],
  },
  {
    primary_id: "D0E9RP",
    names: ["WCK-2349"],
  },
  {
    primary_id: "D0E9SR",
    names: [
      "[3H]JNJ 7777120",
      "1-[(5-Chloro-1H-Indol-2-Yl)Carbonyl]-4-Methylpiperazine",
      "JNJ-7777120",
      "459168-41-3",
      "JNJ7777120",
      "JNJ 7777120",
      "UNII-4H1AU2V37X",
      "HUQJRYMLJBBEDO-UHFFFAOYSA-N",
      "4H1AU2V37X",
      "CHEMBL129198",
      "1-[(5-CHLORO-1H-INDOL-2-YL)CARBONYL]-4-METHYL-PIPERAZINE",
      "1-((5-Chloro-1H-Indol-2-Yl)Carbonyl)-4-Methylpiperazine",
      "(5-Chloro-1H-Indol-2-Yl)-(4-Methylpiperazin-1-Yl)Methanone",
      "(5-Chloro-1H-Indol-2-Yl)(4-Methylpiperazin-1-Yl)Methanone",
      "METHANONE, (5-CHLORO-1H-INDOL-2-YL)(4-METHYL-1-PIPERAZINYL)-",
      "STK175446",
      "[3H]-JNJ7777120",
    ],
  },
  {
    primary_id: "D0E9SZ",
    names: [
      "SI-657",
      "AC1L9R2T",
      "6-[3-Acetamido-2-[6-[3-Acetamido-2,5-Dihydroxy-6-(Hydroxymethyl)Oxan-4-Yl]oxy-2-Carboxy-4,5-Dihydroxyoxan-3-Yl]oxy-5-Hydroxy-6-(Hydroxymethyl)Oxan-4-Yl]oxy-3,4,5-Trihydroxyoxane-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0E9TD",
    names: ["Protirelin"],
  },
  {
    primary_id: "D0E9TE",
    names: ["PMID22464687C15a"],
  },
  {
    primary_id: "D0E9TP",
    names: ["Sulodexide"],
  },
  {
    primary_id: "D0E9TU",
    names: ["ISODISPAR B"],
  },
  {
    primary_id: "D0E9UH",
    names: [
      "RP1",
      "Dimethyl Cyclohex-4-Ene-1,2-Dicarboxylate",
      "RP 1 (Ester)",
      "Dimethyl 4-Cyclohexene-1,2-Dicarboxylate",
      "RP 1",
      "7500-55-2",
      "4-CYCLOHEXENE-1,2-DICARBOXYLIC ACID, DIMETHYL ESTER",
      "NSC 401683",
      "BRN 1876401",
      "AI3-03408",
      "DVVAGRMJGUQHLI-UHFFFAOYSA-N",
      "4-Cyclohexene-1,2-Dicarboxylic Acid, 1,2-Dimethyl Ester",
      "1,2-Dimethyl Cyclohex-4-Ene-1,2-Dicarboxylate",
      "RP-1[ester]",
      "ACMC-209eby",
      "ACMC-209kd2",
      "Cis-4-Cyclohexene-1,2-Dicarboxylic Acid Dimethyl Ester",
      "AC1Q43VK",
      "NCIOpen2_000700",
      "SCHEMBL418070",
      "AC1L2N81",
      "RP-1",
      "CTK5E0893",
      "4-Cyclohexene-1, Dimethyl Ester",
    ],
  },
  {
    primary_id: "D0E9UX",
    names: ["PF3845"],
  },
  {
    primary_id: "D0E9VL",
    names: ["BDD-20617", "BDD-206XX", "BDD-20617-DA", "Levodopa Analogs (Parkinson's Disease), BiRDS"],
  },
  {
    primary_id: "D0E9VU",
    names: ["LEVONORDEFRIN"],
  },
  {
    primary_id: "D0E9WL",
    names: ["Loracarbef"],
  },
  {
    primary_id: "D0E9WO",
    names: ["Febarbamate"],
  },
  {
    primary_id: "D0E9WX",
    names: ["5-[5-Bromo-Indan-(1E)-Ylidenemethyl]-1H-Imidazole"],
  },
  {
    primary_id: "D0E9XG",
    names: ["YM-46A"],
  },
  {
    primary_id: "D0E9XP",
    names: ["Respiratory Syncytial Virus VLPvaccine"],
  },
  {
    primary_id: "D0E9XR",
    names: ["STP705"],
  },
  {
    primary_id: "D0E9YI",
    names: ["MGMTP140K Gene-Modified Hematopoietic Progenitor Cells"],
  },
  {
    primary_id: "D0E9ZP",
    names: ["ITK Inhibitors"],
  },
  {
    primary_id: "D0E9ZW",
    names: ["Tarizine Derivative 1"],
  },
  {
    primary_id: "D0EA3E",
    names: ["PMID26560530-Compound-26"],
  },
  {
    primary_id: "D0EA5P",
    names: ["PMID25991433-Compound-G4"],
  },
  {
    primary_id: "D0EA5U",
    names: ["Porcine Neutral Insulin"],
  },
  {
    primary_id: "D0EA6L",
    names: ["PMID25991433-Compound-K2"],
  },
  {
    primary_id: "D0EA7H",
    names: ["LF-7-0165c"],
  },
  {
    primary_id: "D0EA7Z",
    names: ["4-Propyl-3,4-Dihydroquinazolin-2-Amine", "CHEMBL270840"],
  },
  {
    primary_id: "D0EA9Y",
    names: ["Biaryl Mannoside Derivative 14"],
  },
  {
    primary_id: "D0EB0Y",
    names: ["Autologous T Cells Transfected With Chimeric Anti-Mesothelin Immunoreceptor SS1"],
  },
  {
    primary_id: "D0EB4X",
    names: ["2-Butyl-1(3)H-Anthra[1,2-D]imidazole-6,11-Dione", "CHEMBL593752"],
  },
  {
    primary_id: "D0EB5N",
    names: ["Lefamulin"],
  },
  {
    primary_id: "D0EB7G",
    names: ["PMID29649907-Compound-3"],
  },
  {
    primary_id: "D0EB9H",
    names: ["3-Ethoxy-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL233358"],
  },
  {
    primary_id: "D0EB9M",
    names: ["PMID28627961-Compound-22"],
  },
  {
    primary_id: "D0EB9U",
    names: [
      "1-Cyclohexylidenethiosemicarbazide",
      "(Cyclohexylideneamino)Thiourea",
      "Cyclohexanone Thiosemicarbazone",
      "5351-77-9",
      "Cyclohexanone, Thiosemicarbazone",
      "2-Cyclohexylidenehydrazinecarbothioamide",
      "Hydrazinecarbothioamide, 2-Cyclohexylidene-",
      "CHEMBL510328",
      "Amino[(Cyclohexylideneazamethyl)Amino]methane-1-Thione",
      "AC1MD1GF",
      "Thiosemicarbazonecyclohexanone",
      "SCHEMBL941085",
      "Thiosemicarbazone, Cyclohexanone",
      "NSC717",
      "CTK1H1977",
      "DTXSID20385240",
      "NSC-717",
      "MolPort-000-248-718",
      "GYRDZVHNPFBIST-UHFFFAOYSA-N",
      "HMS1577F04",
      "ZINC5413245",
      "SBB041170",
    ],
  },
  {
    primary_id: "D0EC0B",
    names: ["6-Fluoro-2-Methyl-9H-Carbazole", "CHEMBL1172721"],
  },
  {
    primary_id: "D0EC1Y",
    names: ["SIG-001"],
  },
  {
    primary_id: "D0EC2K",
    names: ["SIPATRIGINE"],
  },
  {
    primary_id: "D0EC3H",
    names: ["Quinazoline Derivative 12"],
  },
  {
    primary_id: "D0EC3Z",
    names: ["PMID25666693-Compound-82"],
  },
  {
    primary_id: "D0EC4Z",
    names: ["Six-Membered Heterocyclic Benzamide Derivative 4"],
  },
  {
    primary_id: "D0EC5T",
    names: ["Neurox"],
  },
  {
    primary_id: "D0EC5X",
    names: ["Gemeprost", "Cervagem (TN)"],
  },
  {
    primary_id: "D0EC6B",
    names: ["Dodeca-2E,4E-Dienoic Acid Isobutylamide"],
  },
  {
    primary_id: "D0EC6Q",
    names: [
      "Chlophedianol",
      "Abehol",
      "Antitussin",
      "Calmotusin",
      "Chlofedanol",
      "Clofedano",
      "Clofedanol",
      "Clofedanolum",
      "Clofedianolo",
      "Dencyl",
      "Eutus",
      "Tussistop",
      "Clofedianolo [Italian]",
      "Clophedianol Base",
      "Ulo Base",
      "SL 501 Base",
      "Antitussin (TN)",
      "Clofedanol (INN)",
      "Clofedanol [INN:BAN]",
      "Clofedanolum [INN-Latin]",
      "Ulone (TN)",
      "Alpha-(Dimethylaminoethyl)-O-Chlorobenzhydrol",
      "Benzenemethanol, 2-Chloro-Alpha-(2-(Dimethylamino)Ethyl)-Alpha-Phenyl-(9CI)",
      "1-(2-Chlorophenyl)-1-Phenyl-3-Dimethylaminopropanol",
      "1-(2-Chlorophenyl)-3-(Dimethylamino)-1-Phenyl-1-Propanol",
      "1-(2-Chlorophenyl)-3-(Dimethylamino)-1-Phenylpropan-1-Ol",
      "1-(2-Chlorophenyl)-3-Dimethylamino-1-Phenylpropan-1-Ol",
      "1-Phenyl-1-(O-Chlorophenyl)-3-Dimethylaminopropanol",
      "2-Chloro-.alpha.-(2-Dimethylaminoethyl)Benzhydrol",
      "2-Chloro-Alpha-(2-(Dimethylamino)Ethyl)Benzhydrol",
      "2-Cloro-Alpha-(2-Dimetilaminoetil)-Benzidrolo",
      "2-Cloro-Alpha-(2-Dimetilaminoetil)-Benzidrolo [Italian]",
    ],
  },
  {
    primary_id: "D0ED2I",
    names: [
      "H-Dmt-Tic-Gly-NH-Ph",
      "CHEMBL501451",
      "BDBM50272081",
      "(3S)-N-(Phenylcarbamoylmethyl)-2-[(S)-3-(2,6-Dimethyl-4-Hydroxyphenyl)-2-Aminopropionyl]-1,2,3,4-Tetrahydroisoquinoline-3-Carboxamide",
      "2-[2-Amino-3-(4-Hydroxy-2,6-Dimethyl-Phenyl)-Propionyl]-1,2,3,4-Tetrahydro-Isoquinoline-3-Carboxylic Acid Phenylcarbamoylmethyl-Amide",
    ],
  },
  {
    primary_id: "D0ED5S",
    names: ["PMID25553724-Compound-US20130116324 2"],
  },
  {
    primary_id: "D0ED6E",
    names: ["CAM-2036"],
  },
  {
    primary_id: "D0ED6O",
    names: [
      "CSTC1",
      "BAC",
      "D-Bacillosamine",
      "Bacillosamine",
      "D-Bac",
      "2,4-Diamino-2,4,6-Trideoxy-D-Glucopyranose",
      "2,4-Diamino-2,4,6-Trideoxy-Glucose",
      "2,4,6-Trideoxy-2,4-Diamino-Glucose",
      "2,4-Diamino-2,4,6-Trideoxy-D-Glucose",
      "2,4,6-Trideoxy-2,4-Diamino-D-Glucose",
      "2,4,6-Trideoxy-2,4-Diamino-Glucopyranose",
      "2,4-Diamino-2,4,6-Trideoxy-Glucopyranose",
      "2,4-Diamino-2,4,6-Trideoxy-Glucopyranoside",
      "2,4,6-Trideoxy-2,4-Diamino-Glucopyranoside",
      "2,4,6-Trideoxy-2,4-Diamino-D-Glucopyranose",
      "2,4,6-Trideoxy-2,4-Diamino-D-Glucopyranoside",
      "2,4-Diamino-2,4,6-Trideoxy-D-Gluc",
    ],
  },
  {
    primary_id: "D0ED7U",
    names: ["Tiagabine"],
  },
  {
    primary_id: "D0EE0Q",
    names: ["JWH-366"],
  },
  {
    primary_id: "D0EE3P",
    names: ["Pyrimidine Derivative 11"],
  },
  {
    primary_id: "D0EE3Y",
    names: ["RSV-F"],
  },
  {
    primary_id: "D0EE4C",
    names: ["1,4,-IP2", "1D-Myo-Inositol 1,4-Bisphosphate", "Inositol 1,4-Bis(Phosphate)"],
  },
  {
    primary_id: "D0EE4O",
    names: ["Stapuldencel-T", "DCVAC Pca"],
  },
  {
    primary_id: "D0EE5S",
    names: ["2-Pyrazinone Derivative 7"],
  },
  {
    primary_id: "D0EE6A",
    names: [
      "AAL-993",
      "269390-77-4",
      "CHEMBL153843",
      "2-[(4-Pyridyl)Methyl]amino-N-[3-(Trifluoromethyl)Phenyl] Benzamide",
      "2-((4-Pyridyl)Methyl)Amino-N-(3-(Trifluoromethyl)Phenyl)Benzamide",
      "2-(Pyridin-4-Ylmethylamino)-~{n}-[3-(Trifluoromethyl)Phenyl]benzamide",
      "2-[(4-Pyridyl)Methyl]amino-N-[3-(Trifluoromethyl)Phenyl]benzamide",
      "2-[(Pyridin-4-Ylmethyl)Amino]-N-[3-(Trifluoromethyl)Phenyl]benzamide",
      "2-((Pyridin-4-Ylmethyl)Amino)-N-(3-(Trifluoromethyl)Phenyl)Benzamide",
      "BLAFVGLBBOPRLP-UHFFFAOYSA-N",
      "AC1O4QQI",
      "Anthranyl Amide Derivative C",
    ],
  },
  {
    primary_id: "D0EE6L",
    names: [
      "BP-897",
      "BP 897",
      "BP897",
      "UNII-2FVL90IM8I",
      "2FVL90IM8I",
      "CHEMBL25236",
      "314776-92-6",
      "N-[4-(4-(2-Methoxyphenyl)Piperazinyl)Butyl]-2-Naphthamide",
      "N-[4-[4-(2-Methoxyphenyl)Piperazin-1-Yl]butyl]naphthalene-2-Carboxamide",
      "BP-897 Free Base",
      "SPBio_002558",
      "SR-01000075725",
      "DO-687",
      "Prestwick-07H10",
      "Prestwick3_000559",
      "Prestwick2_000559",
      "Prestwick1_000559",
      "Prestwick0_000559",
      "Lopac-B-9308",
      "2-Naphthalenecarboxamide, N-(4-(4-(2-Methoxyphenyl)-1-Piperazinyl)Butyl)-",
      "AC1MI4Y9",
      "Lopac0_000165",
      "BSPBio_000637",
      "SCHEMBL2125882",
      "GTPL7625",
      "BPBio1_000701",
    ],
  },
  {
    primary_id: "D0EE7R",
    names: ["ISIS 9003"],
  },
  {
    primary_id: "D0EE8V",
    names: ["JNJ-42756493"],
  },
  {
    primary_id: "D0EE9G",
    names: ["TAK-214"],
  },
  {
    primary_id: "D0EF0P",
    names: ["PMID29338548-Compound-23"],
  },
  {
    primary_id: "D0EF2H",
    names: ["FP-001"],
  },
  {
    primary_id: "D0EF5T",
    names: ["PMID25656651-Compound-10"],
  },
  {
    primary_id: "D0EF7V",
    names: ["Ro-31-4724"],
  },
  {
    primary_id: "D0EF9B",
    names: ["DFD-11"],
  },
  {
    primary_id: "D0EF9I",
    names: ["A-76895"],
  },
  {
    primary_id: "D0EF9J",
    names: ["PMID27537201-Compound-Figure13c"],
  },
  {
    primary_id: "D0EF9Y",
    names: ["VSV-IFN-Beta-NIS"],
  },
  {
    primary_id: "D0EG1F",
    names: ["GR-199114X"],
  },
  {
    primary_id: "D0EG1I",
    names: ["Tofacitinib"],
  },
  {
    primary_id: "D0EG3N",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 34"],
  },
  {
    primary_id: "D0EG4F",
    names: ["Imidazopyridine Acetamide Analog 6"],
  },
  {
    primary_id: "D0EG5A",
    names: ["Retaspimycin"],
  },
  {
    primary_id: "D0EG5G",
    names: [
      "1-(2,3-DICHLOROPHENYL)-2,2,2-TRIFLUORO-1-(1-(4-FLUOROPHENYL)-1H-INDAZOL-5-YL)ETHANOL (ENANTIOMERIC MIX)",
      "CHEMBL1094208",
      "BDBM50316566",
      "1-(2,3-Dichlorophenyl)-2,2,2-Trifluoro-1-(1-(4-Fluorophenyl)-1H-Indazol-5-Yl)Ethanol",
    ],
  },
  {
    primary_id: "D0EG5P",
    names: ["Matrix M"],
  },
  {
    primary_id: "D0EG7I",
    names: ["H-Dmt-Tic-Lys(Ac)-NH-Ph"],
  },
  {
    primary_id: "D0EG8X",
    names: ["(1-Amino-3-Methylsulfanyl-Propyl)-Phosphinic Acid"],
  },
  {
    primary_id: "D0EG8Y",
    names: ["PMID26077642-Compound-Figure3H"],
  },
  {
    primary_id: "D0EH0C",
    names: ["AV-965"],
  },
  {
    primary_id: "D0EH0S",
    names: [
      "7-Hydroxy-8-Propionyl-2H-Chromen-2-One",
      "7-Hydroxy-8-Propionyl-Coumarin",
      "CHEMBL1288557",
      "67752-18-5",
      "7-Hydroxy-8-Propionylcoumarin",
    ],
  },
  {
    primary_id: "D0EH1F",
    names: ["1-(3-Nitro-Benzyl)-1H-Indole-5-Carboxamidine", "CHEMBL329730", "BDBM50027293"],
  },
  {
    primary_id: "D0EH1J",
    names: ["CD19.CAR-T Cells"],
  },
  {
    primary_id: "D0EH2K",
    names: ["PMID29671355-Compound-11"],
  },
  {
    primary_id: "D0EH2O",
    names: ["BIMG80"],
  },
  {
    primary_id: "D0EH2P",
    names: ["Centanafadine"],
  },
  {
    primary_id: "D0EH2Q",
    names: ["PF-05212366"],
  },
  {
    primary_id: "D0EH2T",
    names: ["PMID25666693-Compound-118"],
  },
  {
    primary_id: "D0EH4P",
    names: ["PMID26815044-Compound-117"],
  },
  {
    primary_id: "D0EH5Z",
    names: [
      "2-(3,5-Dimethoxy-4-Phenethoxyphenyl)Ethanamine",
      "CHEMBL465866",
      "207740-42-9",
      "Phenescaline",
      "DTXSID00659517",
      "BDBM50251265",
    ],
  },
  {
    primary_id: "D0EH6G",
    names: ["PMID26156084-Compound-WO2014028589 13"],
  },
  {
    primary_id: "D0EH6L",
    names: ["5,6-Bis-P-Tolylamino-Isoindole-1,3-Dione", "CHEMBL7914", "SCHEMBL8834183", "BDBM50040920"],
  },
  {
    primary_id: "D0EH9A",
    names: ["Semprana"],
  },
  {
    primary_id: "D0EH9J",
    names: ["NanoPac Ointment"],
  },
  {
    primary_id: "D0EH9Z",
    names: ["Heterocyclic Derivative 14"],
  },
  {
    primary_id: "D0EI1U",
    names: ["DCDT-2980S"],
  },
  {
    primary_id: "D0EI2Q",
    names: ["Tricyclic Indole Compound 7"],
  },
  {
    primary_id: "D0EI2V",
    names: ["SAR439459"],
  },
  {
    primary_id: "D0EI4B",
    names: ["N-Phenyl-Pyridine-2-Carboxamide Derivative 2"],
  },
  {
    primary_id: "D0EI5V",
    names: [
      "Bis-Cyclosal-D4TMP",
      "CHEMBL376095",
      "5-Methyl-1-[(2R,5S)-5-[[8-[2-[[(2S,5R)-5-(5-Methyl-2,4-Dioxo-Pyrimidin-1-Yl)-2,5-Dihydrofuran-2-Yl]methoxy]-2-Oxo-4H-1,3,2$l^{5}-Benzodioxaphosphinin-8-Yl]-2-Oxo-4H-1,3,2$l^{5}-Benzodioxaphosphinin-2-Yl]oxymethyl]-2,5-Dihydrofuran-2-Yl]pyrimidine-2,4-Dione",
      "2,4(1H,3H)-Pyrimidinedione, 1,1'-[(2,2'-Dioxido[8,8'-Bi-4H-1,3,2-Benzodioxaphosphorin]-2,2'-Diyl)Bis[oxymethylene[(2R,5S)-2,5-Dihydro-5,2-Furandiyl]]]bis[5-Methyl-",
    ],
  },
  {
    primary_id: "D0EI6X",
    names: [
      "3-Aminobenzo[c][1,5]naphthyridin-6(5H)-One",
      "CHEMBL106154",
      "Benzo[c]-1,5-Naphthyridin-6(5H)-One, 3-Amino-",
      "SCHEMBL12750402",
      "BDBM50130580",
      "433726-73-9",
    ],
  },
  {
    primary_id: "D0EI7H",
    names: ["ThromboMer"],
  },
  {
    primary_id: "D0EI8V",
    names: ["GW-637185X", "CHEMBL539663", "GW637185X", "BDBM50297670"],
  },
  {
    primary_id: "D0EI9B",
    names: ["BAY 367620", "BAY 36-7620", "BAY36-7620", "BAY-367620"],
  },
  {
    primary_id: "D0EJ0B",
    names: ["Axicabtagene Ciloleucel"],
  },
  {
    primary_id: "D0EJ0O",
    names: ["SC401"],
  },
  {
    primary_id: "D0EJ3B",
    names: [
      "AMG 900",
      "AMG-900",
      "945595-80-2",
      "AMG900",
      "UNII-9R2G075611",
      "N-(4-(3-(2-Aminopyrimidin-4-Yl)Pyridin-2-Yloxy)Phenyl)-4-(4-Methylthiophen-2-Yl)Phthalazin-1-Amine",
      "9R2G075611",
      "N-(4-((3-(2-Aminopyrimidin-4-Yl)Pyridin-2-Yl)Oxy)Phenyl)-4-(4-Methylthiophen-2-Yl)Phthalazin-1-Amine",
      "N-[4-[3-(2-Aminopyrimidin-4-Yl)Pyridin-2-Yl]oxyphenyl]-4-(4-Methylthiophen-2-Yl)Phthalazin-1-Amine",
      "N-(4-(3-(2-Aminopyrimidin-4-Yl)Pyridin-2-Yloxy)Phenyl)-4-(4-Methylthiophen- 2-Yl)Phthalazin-1-Amine",
      "IVUGFMLRJOCGAS-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0EJ6O",
    names: [
      "Ciprofibrate",
      "Ciprofibrato",
      "Ciprofibratum",
      "Ciprol",
      "Hiperlipen",
      "Hyperlipen",
      "Lipanor",
      "Modalim",
      "Oroxadin",
      "Sanofi Synthelabo Brand Of Ciprofibrate",
      "Sanofi Winthrop Brand Of Ciprofibrate",
      "C 0330",
      "WIN 35833",
      "Ciprofibrato [INN-Spanish]",
      "Ciprofibratum [INN-Latin]",
      "Win 35,833",
      "Win-35833",
      "Ciprofibrate (USAN/INN)",
      "Ciprofibrate [USAN:BAN:INN]",
      "2-(4-(2,2-Dichlorocyclopropyl)Phenoxy)2-Methylpropanoic Acid",
      "2-(P-(2,2-Dichlorocyclopropyl)Phenoxy)-2-Methylpropionic Acid",
      "2-[4-(2,2-Dichlorocyclopropyl)Phenoxy]-2-Methylpropanoic Acid",
      "2-[p-(2,2-Dichlorocyclopropyl)Phenoxy]-2-Methylpropanoic Acid",
      "2-{4-[2,2-Dichlorocyclopropyl]phenoxy}-2-Methylpropanoic Acid",
      "2-{[4-(2,2-Dichlorocyclopropyl)Phenyl]oxy}-2-Methylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0EJ7Q",
    names: ["ISIS 3304"],
  },
  {
    primary_id: "D0EJ7U",
    names: ["CPP-115"],
  },
  {
    primary_id: "D0EJ8K",
    names: ["GSK-2392102A"],
  },
  {
    primary_id: "D0EJ9B",
    names: ["Phenyl Boronic Acid"],
  },
  {
    primary_id: "D0EK0G",
    names: ["3-(4-Methoxyphenyl)-4-Phenyl-1H-Pyrrole-2,5-Dione", "CHEMBL372076", "SCHEMBL3822337"],
  },
  {
    primary_id: "D0EK1C",
    names: ["PHE-377"],
  },
  {
    primary_id: "D0EK1D",
    names: ["Arteflene"],
  },
  {
    primary_id: "D0EK3T",
    names: ["ISIS 1831"],
  },
  {
    primary_id: "D0EK3Z",
    names: ["Isoindoline Derivative 4"],
  },
  {
    primary_id: "D0EK5H",
    names: ["JCV-Specific TCR A Chains-CD3zeta Fusion Protein"],
  },
  {
    primary_id: "D0EK5U",
    names: ["16beta-Cyano-Estradiol"],
  },
  {
    primary_id: "D0EK6D",
    names: ["FK-838"],
  },
  {
    primary_id: "D0EK6F",
    names: [
      "5-Methyl-3-Phenylethynyl[1,2,4]triazine",
      "CHEMBL227199",
      "1,2,4-Triazine, 5-Methyl-3-(2-Phenylethynyl)-",
      "BDBM50215455",
      "945915-49-1",
    ],
  },
  {
    primary_id: "D0EK6P",
    names: [
      "(2R,4R)-4-Aminopyrrolidine-2,4-Dicarboxylate",
      "(2R,4R)-4-Aminopyrrolidine-2,4-Dicarboxylic Acid",
      "(2R,4R)-APDC",
      "169209-63-6",
      "2R,4R-APDC",
      "CHEMBL40086",
      "CHEBI:40139",
      "EN300-88063",
      "2R, 4R-APDC Monohydrate",
      "52A",
      "4-Amino-2,4-Pyrrolidinedicarboxylic Acid",
      "AC1NSJRT",
      "AC1Q4UAD",
      "SCHEMBL158843",
      "2R, 4R-APDC",
      "(2R,3R)-APDC",
      "GTPL1392",
      "CTK0E5090",
      "DTXSID10415500",
      "MolPort-003-982-102",
      "BDBM50052398",
      "ZINC32628523",
      "SBB067830",
      "MFCD16875409",
      "AKOS015916056",
      "AKOS015854214",
      "RTC-065564",
      "FCH3884669",
      "NCGC00025040-02",
      "LY314593",
      "AJ-85443",
      "SC-48823",
      "TC-065564",
      "B6634",
    ],
  },
  {
    primary_id: "D0EK7H",
    names: [
      "4,7-Dichloro-1H-Indole-2,3-Dione",
      "4,7-Dichloroisatin",
      "18711-13-2",
      "4,7-Dichloroindoline-2,3-Dione",
      "4,7-Dichloro Isatin",
      "4,7-Dichloro-Isatin",
      "4,7-Dichloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "1H-Indole-2,3-Dione, 4,7-Dichloro-",
      "4,7-Dichloroisatin;",
      "4,7-Dichloroisatine;",
      "AC1MC1Y2",
      "Isatin-Based Compound, 51",
      "ACMC-1C3I3",
      "4,7-Dichloroisatin, 97%",
      "KSC494I6J",
      "SCHEMBL281619",
      "CHEMBL223039",
      "4,7-Dichloroindole-2,3-Dione",
      "CTK3J4464",
      "BDBM22831",
      "DTXSID90370507",
      "4,7-Dichloro-2,3-Indolinedione",
      "MolPort-001-766-630",
      "4,7-Dichloroindole-2,3-Dione;",
    ],
  },
  {
    primary_id: "D0EK8U",
    names: [
      "[[N-(Benzyloxycarbonyl)Amino]Methyl]Phosphate",
      "[[n-(Benzyloxycarbonyl)Amino]methyl]phosphate",
      "(BENZYLOXYCARBONYLAMINO)METHYLPHOSPHONIC ACID",
      "({[(Benzyloxy)Carbonyl]amino}methyl)Phosphonic Acid",
      "AC1L1CL8",
      "SCHEMBL5586441",
      "CHEMBL1232804",
      "CTK7G8496",
      "WUNKRZNFNIYEPN-UHFFFAOYSA-N",
      "DB02642",
      "(N-Carbobenzoxy-Amino)Methylphosphonic Acid",
      "Phenylmethoxycarbonylaminomethylphosphonic Acid",
    ],
  },
  {
    primary_id: "D0EK9K",
    names: ["Sch-900875", "CXCR3 Antagonists (Inflammatory Diseases), Schering-Plough/Ligand/Merck & Co"],
  },
  {
    primary_id: "D0EK9T",
    names: ["4-Phenyl-Benzo[b]thiophene-2-Carboxamidine", "CHEMBL591931", "SCHEMBL13709464"],
  },
  {
    primary_id: "D0EL0F",
    names: [
      "HBV-001-D1",
      "Dengue Fever Vaccine, Hawaii Biotech",
      "PreM80%E",
      "DEN-1-80E",
      "Monovalent Dengue Fever Vaccine (Subcutaneous, Dengue Virus Infection), Merck",
    ],
  },
  {
    primary_id: "D0EL1Y",
    names: ["3-[5-(4-Hydroxyphenyl)-1,3-Oxazol-2-Yl]phenol", "CHEMBL488752", "SCHEMBL1180391"],
  },
  {
    primary_id: "D0EL2L",
    names: ["VLCA- 04"],
  },
  {
    primary_id: "D0EL2O",
    names: ["Egualen Sodium", "Azuloxa (TN)"],
  },
  {
    primary_id: "D0EL3A",
    names: ["N-Hydroxy-4-(Oleamidomethyl)Benzamide", "CHEMBL271701"],
  },
  {
    primary_id: "D0EL5B",
    names: ["Pyrrolo[1,2-F]triazine Derivative 2"],
  },
  {
    primary_id: "D0EL5Q",
    names: ["JTX-2011"],
  },
  {
    primary_id: "D0EL6M",
    names: [
      "ImmuMax",
      "Chito-ZN",
      "Chitosan Based Vaccine Adjuvant, Repros Therapeutics",
      "Chitosan Based Vaccine Adjuvant, Zonagen",
      "Z-216",
    ],
  },
  {
    primary_id: "D0EL6Z",
    names: ["Fusicoccin Derivatives"],
  },
  {
    primary_id: "D0EL9Z",
    names: ["AV0328"],
  },
  {
    primary_id: "D0EM1C",
    names: [
      "Chk2 Inhibitor II",
      "BML-277",
      "516480-79-8",
      "Chk2 Inhibitor II Hydrate",
      "CHEMBL179583",
      "2-(4-(4-Chlorophenoxy)Phenyl)-1H-Benzimidazole-5-Carboxamide",
      "C20H14ClN3O2",
      "2-[4-(4-Chlorophenoxy)Phenyl]-1h-Benzimidazole-6-Carboxamide",
      "2-[4-(4-Chlorophenoxy)Phenyl]-1H-1,3-Benzodiazole-6-Carboxamide",
      "2-(4-(4-Chlorophenoxy)Phenyl)-1H-Benzimidazole-5-Carboxamide Hydrate",
      "2-Arylbenzimidazole",
      "4a9r",
      "MLS006010751",
      "GTPL5954",
      "BML277",
      "SCHEMBL1723139",
      "CTK8E8283",
      "BDBM34064",
      "DTXSID40433479",
      "MolPort-039-139-575",
      "HMS3229E12",
      "ZINC3815901",
      "EX-A1077",
      "BCP09077",
    ],
  },
  {
    primary_id: "D0EM1D",
    names: ["RO6867461"],
  },
  {
    primary_id: "D0EM4S",
    names: ["JNJ-39439335"],
  },
  {
    primary_id: "D0EM6S",
    names: [
      "Recombinant Viral Vector Vaccine",
      "Ebola Virus Vaccine, AlphaVax",
      "Filovirus Vaccine, AlphaVax",
      "Marburg Virus Vaccine, AlphaVax",
      "Recombinant Viral Vector Vaccine (ArV, VEE Virus Technology, Filovirus/Marburg/Ebola Virus Infection)",
      "Recombinant Viral Vector Vaccine (ArV, VEE Virus Technology, Filovirus/Marburg/Ebola Virus Infection), AlphaVax",
    ],
  },
  {
    primary_id: "D0EM8D",
    names: ["PMID26077642-Compound-Figure3G"],
  },
  {
    primary_id: "D0EM9A",
    names: ["DSLET", "DSTLE", "Dislet", "[D-Ser2, Leu5, Thr6]-Enkephalin"],
  },
  {
    primary_id: "D0EN0F",
    names: ["TB-09"],
  },
  {
    primary_id: "D0EN0G",
    names: ["Histamine Phosphate"],
  },
  {
    primary_id: "D0EN0W",
    names: ["RV001"],
  },
  {
    primary_id: "D0EN3B",
    names: ["ExpressGraft"],
  },
  {
    primary_id: "D0EN4B",
    names: ["Pyrazole And Thiophene Derivative 2"],
  },
  {
    primary_id: "D0EN4G",
    names: ["R-102444", "Dopamine D2 Antagonists, Sankyo", "5-HT2 Antagonists, Sankyo"],
  },
  {
    primary_id: "D0EN7L",
    names: [
      "1-(But-2-Enylidene)Thiosemicarbazide",
      "CHEMBL505588",
      "Crotonaldehyde Thiosemicarbazone",
      "2-Butenal Thiosemicarbazone",
      "AC1O21BI",
      "ZINC5179201",
      "22113-96-8",
      "BDBM50268196",
    ],
  },
  {
    primary_id: "D0EN7T",
    names: ["Pristanic Acid"],
  },
  {
    primary_id: "D0EO1L",
    names: ["PMID26882240-Compound-3"],
  },
  {
    primary_id: "D0EO2C",
    names: ["LY-2881835"],
  },
  {
    primary_id: "D0EO3K",
    names: ["ETI-210"],
  },
  {
    primary_id: "D0EO3S",
    names: ["Chalcone Derivative 2"],
  },
  {
    primary_id: "D0EO4F",
    names: [
      "A-123189",
      "CHEMBL13856",
      "GTPL491",
      "SCHEMBL7834335",
      "BDBM50099325",
      "A123189",
      "A 123189",
      "3-{2-[4-(2-Methoxy-Phenyl)-Piperazin-1-Yl]-Ethyl}-5-M-Tolyl-1H-Thieno[2,3-D]pyrimidine-2,4-Dione",
      "3-[2-[4-(2-Methoxyphenyl)Piperazin-1-Yl]ethyl]-5-(3-Methylphenyl)-1H-Thieno[3,2-E]pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0EO5F",
    names: ["(Sar)WTLNSAGYLLGPKK(Lys-Palmitoyl)K", "CHEMBL507353"],
  },
  {
    primary_id: "D0EO5G",
    names: ["ALKS 6428"],
  },
  {
    primary_id: "D0EO5P",
    names: ["1-(2-(2-Ethylphenyl)-1-Phenylethyl)Piperazine", "CHEMBL210126", "SCHEMBL5458859"],
  },
  {
    primary_id: "D0EO6G",
    names: ["Anti-CD19- CAR"],
  },
  {
    primary_id: "D0EO6S",
    names: ["Glaspimod"],
  },
  {
    primary_id: "D0EO8F",
    names: ["PMID30124346-Compound-LDT8"],
  },
  {
    primary_id: "D0EO9Z",
    names: ["PMID28051882-Compound-XIV"],
  },
  {
    primary_id: "D0EP0C",
    names: [
      "Spironolactone",
      "Abbolactone",
      "Acelat",
      "Aldace",
      "Aldactazide",
      "Aldactide",
      "Aldactone",
      "Alderon",
      "Aldopur",
      "Almatol",
      "Altex",
      "Aquareduct",
      "Deverol",
      "Diatensec",
      "Dira",
      "Duraspiron",
      "Espironolactona",
      "Euteberol",
      "Flumach",
      "Frumikal",
      "Jenaspiron",
      "Lacalmin",
      "Lacdene",
      "Laractone",
      "Melarcon",
      "Nefurofan",
      "NovoSpiroton",
      "Osyrol",
      "Practon",
      "SNL",
      "Sagisal",
      "Sincomen",
      "Spiractin",
      "Spiresis",
      "Spiretic",
      "Spiridon",
      "Spirobeta",
      "Spiroctan",
      "Spiroctanie",
      "Spiroderm",
      "Spirogamma",
      "Spirolactone",
      "Spirolakton",
      "Spirolang",
      "Spirolone",
      "Spirone",
      "Spironocompren",
      "Spironolactonum",
      "Spironolattone",
      "Spironone",
      "Spirospare",
      "Sprioderm",
      "Suracton",
      "Uractone",
      "Urusonin",
      "Veroshpiron",
      "Verospiron",
      "Verospirone",
      "Xenalon",
      "Aldactone A",
      "Alphapharm Brand Of Spironolactone",
      "Alpharma Brand Of Spironolactone",
      "Alter Brand Of Spironolactone",
      "Ashbourne Brand Of Spironolactone",
      "Azupharma Brand Of Spironolactone",
      "Betapharm Brand Of Spironolactone",
      "Cardel Brand Of Spironolactone",
      "Ct Arzneimittel Brand Of Spironolactone",
      "Dexo Brand Of Spironolactone",
      "Espironolactona Alter",
      "Espironolactona Mundogen",
      "Generosan Brand Of Spironolactone",
      "Hormosan Brand Of Spironolactone",
      "Jenapharm Brand Of Spironolactone",
      "Merck Dura Brand Of Spironolactone",
      "Mundogen Brand Of Spironolactone",
      "Novo Spiroton",
      "Novopharm Brand Of Spironolactone",
      "Pfizer Brand Of Spironolactone",
      "Pharmafrid Brand Of Spironolactone",
      "Roche Brand Of Spironolactone",
      "Searle Brand Of Spironolactone",
      "Spiro Von Ct",
      "Spirono Isis",
      "Spironolactone A",
      "Spironolattone [DCIT]",
      "Verospirone Opianin",
      "Worwag Brand Of Spironolactone",
      "LT00772287",
      "SC 9420",
      "SC9420",
      "Aldactazide (TN)",
      "Aldactone (TN)",
      "Berlactone (TN)",
      "Ct-Arzneimittel Brand Of Spironolactone",
      "Espironolactona [INN-Spanish]",
      "Mayoly-Spindler Brand Of Spironolactone",
      "Novo-Spiroton",
      "SC-9420",
      "Spiractin (TN)",
      "Spiro-Tablinen",
      "Spirono-Isis",
      "Spironolactonum [INN-Latin]",
      "Spirotone (TN)",
      "Supra-Puren",
      "Verospiron (TN)",
      "Von Ct, Spiro",
      "Novo-Spiroton (TN)",
      "Spironolactone [BAN:INN:JAN]",
      "Spironolactone [INN:BAN:JAN]",
      "Spiro L.U.T.",
      "Spironolactone (JP15/USP/INN)",
      "Spiro[17H-Cyclopenta[a]phenauthrene-17,2'-(3'H)-Furan]",
      "Spiro(17H-Cyclopenta(A)Phenauthrene-17,2'-(3'H)-Furan)",
      "4-Pregnen-21-Oic Acid-17alpha-Ol-3-One-7alpha-Thiol Gamma-Lactone 7-Acetate",
      "7-Alpha-Acetylthio-3-Oxo-17-Alpha-Pregn-4-Ene-21,17-Beta-Carbolactone",
      "7alpha-(Acetylsulfanyl)-3-Oxo-17alpha-Pregn-4-Ene-21,17-Carbolactone",
    ],
  },
  {
    primary_id: "D0EP0M",
    names: ["PMID29334795-Compound-24"],
  },
  {
    primary_id: "D0EP1G",
    names: ["HTS-05058"],
  },
  {
    primary_id: "D0EP3H",
    names: ["EvofosfamideORPHAN DRUG"],
  },
  {
    primary_id: "D0EP5O",
    names: ["NS-377"],
  },
  {
    primary_id: "D0EP8X",
    names: [
      "Gamma Hydroxybutyric Acid",
      "Anetamin",
      "GHB",
      "Hydroxyb",
      "Oxybate",
      "SHB",
      "Somsanit",
      "Xyrem",
      "Gamma OH",
      "Oxybate Sodium",
      "Utyric Acid Monosodium Salt",
      "EB 27",
      "WY 3478",
      "Gam-OH",
      "Gamma-Hydroxy Butyrate",
      "Gamma-Hydroxy Sodium Butyrate",
      "Gamma-Hydroxybutanoic Acid",
      "Gamma-Hydroxybutyrate Sodium",
      "Gamma-Hydroxybutyric Acid",
      "Gamma-Hydroxybutyric Acid Decomposition Product",
      "Gamma-Hydroxybutyric Acid Monosodium Salt",
      "Gamma-OH",
      "Xyrem (TN)",
      "Gamma-Hydroxybutyric Acid, Sodium Salt",
      "Oxy-N-Butyric Acid",
      "Sodium Oxybate (4-Hydroxybutanoic Acid)",
      "Sodium .gamma.-Hydroxybutyrate",
      "Sodium .gamma.-Oxybutyrate",
      "AA3E2AF0-AB7A-4A1E-A391-199C049D7162",
      "Butanoic Acid, 4-Hydroxy-, Monosodium Salt",
      "Butanoic Acid, 4-Hydroxy-, Sodium Salt",
      "Butyric Acid, 4-Hydroxy-, Monosodium Salt",
      "Butyric Acid, 4-Hydroxy-, Sodium Salt",
      "3-Carboxypropoxy Acid",
      "4 HB",
      "4-Hydroxy",
      "4-Hydroxyacid",
      "4-Hydroxyalkanoic Acid",
      "4-Hydroxybutanoic Acid",
      "4-Hydroxybuttersaeure",
      "4-Hydroxybutyrate Sodium",
      "4-Hydroxybutyric Acid",
      "4-Hydroxybutyric Acid Monosodium Salt",
      "4-Hydroxycarboxylic Acid",
      "4-OHB",
      "4-Hydroxy-Butyric Acid",
    ],
  },
  {
    primary_id: "D0EP9A",
    names: ["Dinutuximab"],
  },
  {
    primary_id: "D0EQ1J",
    names: ["S-111"],
  },
  {
    primary_id: "D0EQ1Z",
    names: ["Isosorbide-2-Benzylcarbamate-5-(O-Toluate)", "CHEMBL583687"],
  },
  {
    primary_id: "D0EQ3O",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 4"],
  },
  {
    primary_id: "D0EQ3V",
    names: ["Resorcinol Compound 4"],
  },
  {
    primary_id: "D0EQ4T",
    names: ["Guanosine-5'-Triphosphate"],
  },
  {
    primary_id: "D0EQ5W",
    names: ["Sh-28-GST", "Bilhvax", "Therapeutic Protein Vaccine (Schistosomaisis), INSERM"],
  },
  {
    primary_id: "D0EQ6D",
    names: ["JCARH125"],
  },
  {
    primary_id: "D0EQ6V",
    names: ["Alhydrogel"],
  },
  {
    primary_id: "D0EQ8X",
    names: ["2-Phenyl-6-Propyl-Chromen-4-One", "CHEMBL134539"],
  },
  {
    primary_id: "D0EQ9T",
    names: ["JNJ-39220675"],
  },
  {
    primary_id: "D0ER0L",
    names: [
      "4-Benzyl-5-Piperidin-4-Yl-Isoxazol-3-Ol",
      "CHEMBL144364",
      "CHEMBL542896",
      "439944-86-2",
      "CTK1C8001",
      "DTXSID40440825",
      "ZINC13473970",
      "BDBM50113808",
      "AKOS030538866",
      "4-Benzyl-5-(4-Piperidinyl)Isoxazole-3-Ol",
      "3(2H)-Isoxazolone, 4-(Phenylmethyl)-5-(4-Piperidinyl)-",
    ],
  },
  {
    primary_id: "D0ER1D",
    names: [
      "2-(3-Methoxy-Phenyl)-6-Methyl-Chromen-4-One",
      "3'-METHOXY-6-METHYLFLAVONE",
      "CHEMBL131632",
      "4H-1-Benzopyran-4-One, 2-(3-Methoxyphenyl)-6-Methyl-",
      "88952-72-1",
      "ACMC-20lfb9",
      "AC1LVOZ6",
      "CTK3A4546",
      "DTXSID50364725",
      "GPLWNAUILJELBH-UHFFFAOYSA-N",
      "ZINC1933195",
      "AKOS002387234",
      "MB03835",
      "3'-Methoxy-6-Methylflavone, AldrichCPR",
    ],
  },
  {
    primary_id: "D0ER1G",
    names: [
      "4-Hydroxy-Piperidine-3-Carboxylic Acid",
      "CHEMBL277372",
      "SCHEMBL5426302",
      "ZINC26898692",
      "BDBM50223874",
      "AKOS006353852",
      "(3S)-4alpha-Hydroxypiperidine-3alpha-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0ER2C",
    names: ["Triptoreline"],
  },
  {
    primary_id: "D0ER5C",
    names: ["6-Hydroxy-2,9-Dimethyl-9H-Beta-Carbolin-2-Ium", "CHEMBL373651"],
  },
  {
    primary_id: "D0ER5F",
    names: ["Ad5FGF-4"],
  },
  {
    primary_id: "D0ER8I",
    names: ["Ushercell", "Cellulose Sulfate Gel, Polydex"],
  },
  {
    primary_id: "D0ER9I",
    names: ["JNJ-40929837"],
  },
  {
    primary_id: "D0ES1Q",
    names: ["Temsirolimus"],
  },
  {
    primary_id: "D0ES2U",
    names: ["TP-680"],
  },
  {
    primary_id: "D0ES2Y",
    names: ["8-(2-Methyl-Cyclohexyloxy)-Quinolin-2-Ylamine", "CHEMBL362713", "SCHEMBL5884370"],
  },
  {
    primary_id: "D0ES4S",
    names: [
      "RP-68303",
      "136701-68-3",
      "CHEMBL274728",
      "1-(2-(4-((5-Fluoro-1H-Indol-3-Yl)Methyl)-1-Piperidinyl)Ethyl)-5,6-Dihydro-1H,4H-1,2,5-Thiadiazolo(4,3,2-Ij)Quinoline 2,2-Dioxide",
      "1-(Fim-PE)-Dtqd",
      "1H,4H-[1,2,5]Thiadiazolo[4,3,2-Ij]quinoline,1-[2-[4-[(5-Fluoro-1H-Indol-3-Yl)Methyl]-1-Piperidinyl]ethyl]-5,6-Dihydro-,2,2-Dioxide",
      "ACMC-20mw9l",
      "AC1L4DLH",
      "SCHEMBL9635292",
      "CTK4C0419",
      "DTXSID70159892",
      "BDBM50047098",
      "1-{2-[4-(5-Fluoro-1H-Indol-3-Ylmethyl)-Piperidin-1-Yl]-Ethyl}-4,5-Dihydro-1H,3H-2-Thia-1,2a-Diaza-Acenaphthylene 2,2-Dioxide",
    ],
  },
  {
    primary_id: "D0ES5A",
    names: ["Molecule 11", "38293-71-9", "AC1L1YVB", "CTK4H9638", "2-CHLOROACETOPHENONE 2,4-DINITROPHENYLHYDRAZONE"],
  },
  {
    primary_id: "D0ES5X",
    names: ["4-APPES", "GTPL6258"],
  },
  {
    primary_id: "D0ES6N",
    names: ["SB-030"],
  },
  {
    primary_id: "D0ES6Z",
    names: [
      "(2E)-3-(2-OCT-1-YN-1-YLPHENYL)ACRYLIC ACID",
      "(2e)-3-(2-Oct-1-Yn-1-Ylphenyl)Acrylic Acid",
      "1lox",
      "SCHEMBL4947304",
      "SCHEMBL4947296",
      "DB08492",
      "(2E)-3-(2-Oct-1-Yn-1-Ylphenyl)Prop-2-Enoic Acid",
      "(2E)-3-[2-(Oct-1-Yn-1-Yl)Phenyl]prop-2-Enoic Acid",
    ],
  },
  {
    primary_id: "D0ES7H",
    names: ["TV-Brain-1"],
  },
  {
    primary_id: "D0ES7P",
    names: ["H-Dmt-Tic-(2S,3R)-Beta-MeCha-Phe-NH2", "CHEMBL415426"],
  },
  {
    primary_id: "D0ES9J",
    names: ["BL-4040"],
  },
  {
    primary_id: "D0ES9U",
    names: ["COR-003"],
  },
  {
    primary_id: "D0ET0J",
    names: ["PT-322"],
  },
  {
    primary_id: "D0ET0T",
    names: ["2-EPI-2-THIOSALVINORIN A", "2-Epi-2-Thiosalvinorin A", "CHEMBL231537"],
  },
  {
    primary_id: "D0ET2A",
    names: ["Imidazo Triazine Derivative 3"],
  },
  {
    primary_id: "D0ET2F",
    names: [
      "5-Methyl Nicotinic Acid",
      "5-Methylnicotinic Acid",
      "3222-49-9",
      "5-Methylpyridine-3-Carboxylic Acid",
      "5-Methyl-Nicotinic Acid",
      "CHEMBL247132",
      "DJDHHXDFKSLEQY-UHFFFAOYSA-N",
      "MFCD00829036",
      "3-Pyridinecarboxylic Acid, 5-Methyl-",
      "5-METHYL-3-PYRIDINECARBOXYLIC ACID",
      "5-Methylnicotinic Acid, 97%",
      "5-Methyinicotinic Acid",
      "PubChem11961",
      "ACMC-1AFVP",
      "5-Methyl Nicotinic Aicd",
      "EC 608-720-2",
      "AC1L5U3L",
      "Nicotinic Acid, 5-Methyl-",
      "AC1Q5UC0",
      "NCIOpen2_001043",
      "5-METHYLNICOTIC ACID",
      "KSC222G7T",
      "SCHEMBL185789",
      "GTPL1592",
      "Jsp005966",
      "CTK1C2379",
      "DTXSID60292444",
    ],
  },
  {
    primary_id: "D0ET2S",
    names: ["Epetirimod"],
  },
  {
    primary_id: "D0ET2T",
    names: ["GSK2586881"],
  },
  {
    primary_id: "D0ET3L",
    names: ["Pyrazole Derivative 63"],
  },
  {
    primary_id: "D0ET4S",
    names: [
      "BOMA",
      "SCHEMBL660569",
      "GTPL1421",
      "LWSOZPNJUFEDJU-UHFFFAOYSA-N",
      "[4-(1,3-Benzoxazol-2-Yl)-2-Methoxyphenyl]acetonitrile",
    ],
  },
  {
    primary_id: "D0ET4U",
    names: ["PMID28092474-Compound-32r"],
  },
  {
    primary_id: "D0ET5E",
    names: ["Stem Cell Therapy, Dry AMD"],
  },
  {
    primary_id: "D0ET6F",
    names: ["AT-004"],
  },
  {
    primary_id: "D0ET9M",
    names: ["Biaryl Mannoside Derivative 30"],
  },
  {
    primary_id: "D0ET9N",
    names: ["WC-3049"],
  },
  {
    primary_id: "D0ET9U",
    names: ["Pyrazoline Derivative 1"],
  },
  {
    primary_id: "D0EU0L",
    names: ["HIE-124"],
  },
  {
    primary_id: "D0EU1R",
    names: ["GSK-2334470"],
  },
  {
    primary_id: "D0EU5A",
    names: ["PMID28092474-Compound-32h"],
  },
  {
    primary_id: "D0EU9W",
    names: ["1-(3-Methoxyphenyl)-2-(Phenylsulfonyl)Ethanone", "CHEMBL393079", "BDBM50212534"],
  },
  {
    primary_id: "D0EU9Z",
    names: ["InterveXin-PCP"],
  },
  {
    primary_id: "D0EV1I",
    names: ["C-014C", "CHEMBL509396", "BDBM50244050"],
  },
  {
    primary_id: "D0EV1L",
    names: ["Elsiglutide"],
  },
  {
    primary_id: "D0EV2U",
    names: ["IV Aprepitant"],
  },
  {
    primary_id: "D0EV3G",
    names: ["PMID25666693-Compound-26"],
  },
  {
    primary_id: "D0EV3O",
    names: ["N-Ethyl-ETAV"],
  },
  {
    primary_id: "D0EV4B",
    names: [
      "CreaVax-PC",
      "Autologous DC Vaccine (Injectable, Prostate Cancer), CreaGene",
      "Autologous Dendritic Cell Vaccine (Injectable, Prostate Cancer), CreaGene",
    ],
  },
  {
    primary_id: "D0EV5O",
    names: ["NGP 555"],
  },
  {
    primary_id: "D0EV5U",
    names: ["Flavonoid Derivative 3"],
  },
  {
    primary_id: "D0EV6B",
    names: ["N-(1-Adamantyl)-N'-(4-Guanidinobenzyl)Urea"],
  },
  {
    primary_id: "D0EV6T",
    names: [
      "Tipranavir",
      "Aptivus",
      "TPV",
      "PNU 140690",
      "U 140690",
      "Aptivus (Boehringer Ingelheim)",
      "Aptivus (TN)",
      "Aptivus(TM)",
      "PNU-140690",
      "PNU-140690E",
      "Tipranavir (INN)",
      "U-140690",
      "N-[3-[(1R)-1-[(2R)-6-Hydroxy-4-Oxo-2-Phenethyl-2-Propyl-3H-Pyran-5-Yl]propyl]phenyl]-5-(Trifluoromethyl)Pyridine-2-Sulfonamide",
      "N-(3-{(1R)-1-[(6R)-4-HYDROXY-2-OXO-6-PHENETHYL-6-PROPYL-5,6-DIHYDRO-2H-PYRAN-3-YL]PROPYL}PHENYL)-5-(TRIFLUOROMETHYL)-2-PYRIDINESULFONAMIDE",
    ],
  },
  {
    primary_id: "D0EV6U",
    names: ["MFQ", "Antibacterial Therapeutics, DesignMedix"],
  },
  {
    primary_id: "D0EV6V",
    names: ["PMID29338548-Compound-15"],
  },
  {
    primary_id: "D0EV7U",
    names: ["GSK-LSD1"],
  },
  {
    primary_id: "D0EV8E",
    names: [
      "Econazole",
      "Ecostatin",
      "Palavale",
      "Pevaryl",
      "Ecostatin Vaginal Ovules",
      "Ecostatin Cream",
      "Spectazole Cream",
      "SQ 13050",
      "Ecostatin (TN)",
      "Gyno-Pevaryl 150",
      "Gyno-Pevaryl",
      "Pevaryl (TN)",
      "Spectazole (TN)",
      "Econazole (USAN/INN)",
      "1-[2,4-Dichloro-.beta.-[(P-Chlorobenzyl)Oxy]phenethyl]imidazole",
      "1-[2-[(4-Chlorophenyl)Methoxy]-2-(2,4-Dichlorophenyl)Ethyl]-1H-Imidazole",
      "1-[2-[(4-Chlorophenyl)Methoxy]-2-(2,4-Dichlorophenyl)Ethyl]imidazole",
      "1-{2-(4-Chlorobenzyloxy)-2-(2,4-Dichlorophenyl)Ethyl}-1H-Imidazole",
      "1-{2-[(4-Chlorobenzyl)Oxy]-2-(2,4-Dichlorophenyl)Ethyl}-1H-Imidazole",
      "1-{2-[(4-Chlorophenyl)Methoxy]-2-(2,4-Dichlorophenyl)Ethyl}-1H-Imidazole",
    ],
  },
  {
    primary_id: "D0EV9B",
    names: ["BTD-001"],
  },
  {
    primary_id: "D0EW2D",
    names: ["IMD-4482"],
  },
  {
    primary_id: "D0EW3L",
    names: [
      "BUTEIN",
      "Butein",
      "487-52-5",
      "2',3,4,4'-Tetrahydroxychalcone",
      "3,4,2',4'-Tetrahydroxychalcone",
      "2',4',3,4-Tetrahydroxychalcone",
      "UNII-4WVS5M0LGF",
      "EINECS 207-659-5",
      "4WVS5M0LGF",
      "(E)-1-(2,4-Dihydroxyphenyl)-3-(3,4-Dihydroxyphenyl)Prop-2-En-1-One",
      "CHEMBL128000",
      "CHEBI:3237",
      "(2E)-1-(2,4-Dihydroxyphenyl)-3-(3,4-Dihydroxyphenyl)Prop-2-En-1-One",
      "1-(2,4-Dihydroxyphenyl)-3-(3,4-Dihydroxyphenyl)-2-Propen-1-One",
      ",3,4-Tetrahydroxychalcone",
      "Chalcone, 2',3,4,4'-Tetrahydroxy- (7CI,8CI)",
      "Acrylophenone, 2',4'-Dihydroxy-3-(3,4-Dihy",
    ],
  },
  {
    primary_id: "D0EW3O",
    names: ["Cyclic Peptidomimetic Derivative 3"],
  },
  {
    primary_id: "D0EW4L",
    names: ["Abatacept"],
  },
  {
    primary_id: "D0EW4M",
    names: ["PNB-04", "Alzheimer's Disease Therapy, PharmaNeuroBoost"],
  },
  {
    primary_id: "D0EW5Y",
    names: [
      "AlloDerm",
      "AlloCraft",
      "Cymetra",
      "GraftJacket",
      "Repliform",
      "Strattice",
      "Graft Tissue Product, LifeCell",
    ],
  },
  {
    primary_id: "D0EW6H",
    names: ["LTC4"],
  },
  {
    primary_id: "D0EW8Y",
    names: ["Thieno-Phenanthridin-6-One"],
  },
  {
    primary_id: "D0EW9I",
    names: ["Quadrivalent"],
  },
  {
    primary_id: "D0EW9J",
    names: [
      "PMID17480064C16",
      "724711-21-1",
      "3fhr",
      "2jbp",
      "2jbo",
      "P4O",
      "Pyrrolopyridine, 16",
      "Kinome_3179",
      "3r2y",
      "GTPL8177",
      "BDBM30185",
      "ZINC16052855",
      "AKOS027322581",
      "DB08358",
      "KB-19275",
      "DB-013997",
      "FT-0745988",
    ],
  },
  {
    primary_id: "D0EW9U",
    names: ["[18F]DCFPyL", "DCFPyL-18F", "Fluorine-18-DCFPyL"],
  },
  {
    primary_id: "D0EW9W",
    names: ["Peptide Analog 7"],
  },
  {
    primary_id: "D0EX1C",
    names: ["RASV-Sp"],
  },
  {
    primary_id: "D0EX1R",
    names: ["N,N'-(1',7'-Heptylene)-Bis-(-)-Nor-MEP", "CHEMBL258935"],
  },
  {
    primary_id: "D0EX1T",
    names: ["PMID27215781-Compound-11"],
  },
  {
    primary_id: "D0EX1W",
    names: ["Ad-RTS-IL-12"],
  },
  {
    primary_id: "D0EX2M",
    names: ["1-(2-(3-Chlorophenyl)-1-Phenylethyl)Piperazine", "CHEMBL214240", "SCHEMBL5448643"],
  },
  {
    primary_id: "D0EX3N",
    names: ["PMID28074661-Compound-WO2009018344C78"],
  },
  {
    primary_id: "D0EX4L",
    names: ["Methyl Piperate"],
  },
  {
    primary_id: "D0EX5A",
    names: ["Dihydrothieno [2,3-E]indazole Derivative 3"],
  },
  {
    primary_id: "D0EX5C",
    names: ["Pyrrolo-Pyrazine Derivative 1"],
  },
  {
    primary_id: "D0EX7J",
    names: ["JWH-442"],
  },
  {
    primary_id: "D0EX7S",
    names: ["3-Cyano-N-(1,4-Diphenyl-1H-Pyrazol-5-Yl)Benzamide", "CHEMBL210488"],
  },
  {
    primary_id: "D0EX9E",
    names: ["PMID26004420-Compound-US20140309213A"],
  },
  {
    primary_id: "D0EX9O",
    names: ["Salicylic Acid Derivative 1"],
  },
  {
    primary_id: "D0EX9Q",
    names: [
      "2-Propyl-Pentanoic Acid 4-Sulfamoyl-Benzyl Ester",
      "CHEMBL107722",
      "BDBM50108587",
      "ZINC13471991",
      "2-Propylvaleric Acid 4-Sulfamoylbenzyl Ester",
    ],
  },
  {
    primary_id: "D0EY0A",
    names: [
      "AZD7687",
      "1166827-44-6",
      "CHEMBL2178944",
      "CHEMBL2178953",
      "AZD-7687",
      "AZD 7687",
      "UNII-6QTJ9P2NYT",
      "6QTJ9P2NYT",
      "SCHEMBL2252415",
      "SCHEMBL5532381",
      "SCHEMBL2252417",
      "GTPL7827",
      "SCHEMBL2255196",
      "DTXSID60655297",
      "YXFNPRHZMOGREC-SHTZXODSSA-N",
      "3466AH",
      "ZINC43206395",
      "BDBM50399684",
      "BDBM50399710",
      "AZD 7687;AZD-7687",
      "ZINC101581684",
      "AKOS030526281",
      "CS-1026",
      "NCGC00378858-01",
      "HY-15497",
      "KB-144428",
      "W-5990",
      "{4-[4-(6-Carbamoyl-3,5-Dimethylpyrazin-2-Yl)Phenyl]cyclohexyl}acetic Acid",
      "{trans-4-[4-(6-Carbamoyl-3,5-Dimethylpyrazin-2-Yl)Phenyl]cyclohexyl}acetic Acid",
    ],
  },
  {
    primary_id: "D0EY1E",
    names: ["Influsome-Vac"],
  },
  {
    primary_id: "D0EY1K",
    names: [
      "CB1 Antagonists Merck & Co",
      "CB1 Antagonists (Endocrine/Neurological Diseases) Merck & Co",
      "CB1 Antagonists (Endocrine Disease) , Schering-Plough",
      "CB1 Antagonists (Neurological Disease), Schering-Plough",
      "CB1 Antagonists (Endocrine/Neurological Diseases), Merck & Co",
    ],
  },
  {
    primary_id: "D0EY1Y",
    names: ["CRS-207 + GVAX"],
  },
  {
    primary_id: "D0EY2F",
    names: ["7-Hydroxy-6-Propionyl-2H-Chromen-2-One", "7-Hydroxy-6-Propanoylcoumarin", "CHEMBL1288535", "144582-52-5"],
  },
  {
    primary_id: "D0EY2Q",
    names: ["Iodophenyl-N-Methyl-N-Fluoroalkyl-3-Isoquinoline Carboxamide Derivative 2"],
  },
  {
    primary_id: "D0EY3X",
    names: ["KLS-40"],
  },
  {
    primary_id: "D0EY5A",
    names: ["VLTS-587"],
  },
  {
    primary_id: "D0EY5F",
    names: ["Isoindole 1,3-Dione Analog 1"],
  },
  {
    primary_id: "D0EY9P",
    names: ["SCH-44342"],
  },
  {
    primary_id: "D0EY9S",
    names: ["1,2,4-Oxadiazole Derivative 4"],
  },
  {
    primary_id: "D0EY9Y",
    names: ["PMID25468267-Compound-53"],
  },
  {
    primary_id: "D0EZ0M",
    names: ["TTH 03-002"],
  },
  {
    primary_id: "D0EZ2W",
    names: ["CR-2345", "CHEMBL558614"],
  },
  {
    primary_id: "D0EZ5J",
    names: ["Daptacel"],
  },
  {
    primary_id: "D0EZ5O",
    names: ["PMID27841045-Compound-145"],
  },
  {
    primary_id: "D0EZ5Y",
    names: ["KiroVAX-001"],
  },
  {
    primary_id: "D0EZ6C",
    names: ["PMID26560530-Compound-27"],
  },
  {
    primary_id: "D0EZ6V",
    names: ["Oxazole Derivative 1"],
  },
  {
    primary_id: "D0EZ9C",
    names: [
      "CTLA-4-XTEN",
      "Cytotoxic T-Lymphocyte Protein 4 Modulator (XTEN, Rheumatoid Arthritis/Transplant Rejection), Amunix",
    ],
  },
  {
    primary_id: "D0EZ9G",
    names: ["PMID25980951-Compound-14"],
  },
  {
    primary_id: "D0F0AU",
    names: ["3-Methyl-1H-Thieno[2,3-C]pyrazole-5-Carboxamide", "CHEMBL198022", "SCHEMBL15514276"],
  },
  {
    primary_id: "D0F0AX",
    names: ["10Z-Hymenialdisine"],
  },
  {
    primary_id: "D0F0BB",
    names: [
      "6-Tert-Butyl-M-Cresol",
      "2-Tert-Butyl-5-Methylphenol",
      "88-60-8",
      "6-Tert-Butyl-3-Methylphenol",
      "2-(Tert-Butyl)-5-Methylphenol",
      "M-Cresol, 6-Tert-Butyl-",
      "Phenol, 2-(1,1-Dimethylethyl)-5-Methyl-",
      "5-Methyl-2-Tert-Butylphenol",
      "3-Methyl-6-Tert-Butylphenol",
      "UNII-UXV32MJ2CA",
      "2-Tert-Butyl-5-Methyl-Phenol",
      "6-T-Butyl-M-Cresol",
      "HSDB 5260",
      "EINECS 201-842-3",
      "NSC 48467",
      "UXV32MJ2CA",
      "2-T-Butyl-5-Methylphenol",
      "BRN 1908225",
      "Benzene, 1-Tert-Butyl-2-Hydroxy-4-Methyl-",
      "2-(1,1-DIMETHYLETHYL)-5-METHYLPHENOL",
      "Phenol, 2-Tert-Butyl-5-Methyl-",
    ],
  },
  {
    primary_id: "D0F0BS",
    names: [
      "3alpha-(Bis-Chloro-Phenylmethoxy)Tropane",
      "CHEMBL119664",
      "CHEMBL541825",
      "SCHEMBL1152029",
      "BDBM50039177",
      "3-[Bis(4-Chlorophenyl)Methoxy]tropane",
      "3-[Bis-(4-Chloro-Phenyl)-Methoxy]-8-Methyl-8-Aza-Bicyclo[3.2.1]octane",
    ],
  },
  {
    primary_id: "D0F0CP",
    names: ["MK-9"],
  },
  {
    primary_id: "D0F0CS",
    names: ["ZARDAVERINE"],
  },
  {
    primary_id: "D0F0EI",
    names: ["3-(6-Hydroxy-2-Naphthyl)Benzoic Acid"],
  },
  {
    primary_id: "D0F0EP",
    names: [
      "Bemetizide",
      "Dehydrosanol",
      "Bemeticide",
      "Pertensosanol",
      "Bemetizid",
      "Bemetizide [INN:BAN]",
      "Bemetizidum [INN-Latin]",
      "Bemetizida [INN-Spanish]",
      "DIU 60",
      "1824-52-8",
      "EINECS 217-357-5",
      "SU 7078",
      "BRN 0631888",
      "Bemetizidum",
      "Bemetizida",
      "6-Chloro-3,4-Dihydro-3-(Alpha-Methylbenzyl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine, 3,4-Dihydro-6-Chloro-3-(Alpha-Methylbenzyl)-7-Sulfamoyl-, 1,1-Dioxide",
      "6-Chlor-3,4-Dihydro-3-(Alpha-Methylbenzyl)-7-Sulfamoyl-2H-1,2,4-Benzothiadiazine-1,1-Dioxide",
      "Bemetecide",
      "AC1L2HCS",
    ],
  },
  {
    primary_id: "D0F0ET",
    names: ["T-270"],
  },
  {
    primary_id: "D0F0FZ",
    names: ["UNBS-5162"],
  },
  {
    primary_id: "D0F0IA",
    names: [
      "A-381393",
      "CHEMBL127257",
      "GTPL8441",
      "BDBM50150141",
      "A 381393",
      "A-381,393",
      "2-[[4-(3,4-Dimethylphenyl)Piperazin-1-Yl]methyl]-1H-Benzimidazole",
      "2-[4-(3,4-Dimethylphenyl)Piperazin-1-Ylmethyl]-1h-Benzimidazole",
    ],
  },
  {
    primary_id: "D0F0ID",
    names: ["Polyoxidonium"],
  },
  {
    primary_id: "D0F0JI",
    names: ["(E)-Octadec-9-Enoic Acid Phenylamide"],
  },
  {
    primary_id: "D0F0JJ",
    names: [
      "E2012",
      "870843-42-8",
      "E 2012",
      "(E)-1-[(1S)-1-(4-FLUOROPHENYL)ETHYL]-3-[3-METHOXY-4-(4-METHYL-1H-IMIDAZOL-1-YL)BENZYLIDENE]PIPERIDIN-2-ONE",
      "E-2012",
      "UNII-3LSD4Y5F0F",
      "3LSD4Y5F0F",
      "CHEMBL1224151",
      "J-501810",
      "(3E)-1-[(1S)-1-(4-Fluorophenyl)Ethyl]-3-[[3-Methoxy-4-(4-Methylimidazol-1-Yl)Phenyl]methylidene]piperidin-2-One",
      "(E)-1-((1s)-1-(4-Fluorophenyl)Ethyl)-3-(3-Methoxy-4-(4-Methyl-1h-Imidazol-1-Yl)Benzylidene)Piperidin-2-One",
      "PUOAETJYKQITMO-LANLRWRYSA-N",
    ],
  },
  {
    primary_id: "D0F0JU",
    names: ["4,5-Bis(4-Methoxyphenyl)Isothiazole", "CHEMBL473946"],
  },
  {
    primary_id: "D0F0JZ",
    names: ["CPG23CARD", "Adult Stem Cell Therapy (Cardiovascular Disease), Celprogen"],
  },
  {
    primary_id: "D0F0KS",
    names: ["N-(2-Mercapto-Ethyl)-N'-Phenyl-Oxalamide", "CHEMBL193979"],
  },
  {
    primary_id: "D0F0KV",
    names: [
      "CV-6504.HCL",
      "117585-83-8",
      "2,3,5-Trimethyl-6-(3-Pyridylmethyl)-2,5-Cyclohexane-1,4-Dione Hydrochloride",
      "3,5,6-Trimethyl-2-(3-Pyridylmethyl)-1,4-Benzoquinone Hydrochloride",
      "CV-6504.HCl",
    ],
  },
  {
    primary_id: "D0F0LO",
    names: [
      "HG-1144",
      "ABH Modulator (Cancer), Human Genome Sciences",
      "ALKBH Modulator (Cancer), Human Genome Sciences",
      "AlkB Modulator (Cancer), Human Genome Sciences",
      "HABH Modulator (Cancer), Human Genome Sciences",
      "AlkB, E.coli Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0F0MN",
    names: ["Boc-Glu-Tyr(SO3H)-Nle-D-Lys-Trp-Nle-Asp-Phe-NH2", "CHEMBL386304"],
  },
  {
    primary_id: "D0F0MP",
    names: ["L-165461"],
  },
  {
    primary_id: "D0F0MU",
    names: ["BLX-883", "A Form Of Alfa Interferon"],
  },
  {
    primary_id: "D0F0NB",
    names: ["Insulin Adjustable Basal"],
  },
  {
    primary_id: "D0F0NW",
    names: [
      "Dust Mite Peptide Allergy Desensitization",
      "Dust Mite Peptide Allergy Desensitization (ToleroMune)",
      "Dust Mite Peptide Allergy Desensitization (ToleroGenix), Circassia",
      "Dust Mite Peptide Allergy Desensitization (ToleroMune), Circassia",
      "Dust Mite-PAD, Circassia",
    ],
  },
  {
    primary_id: "D0F0OJ",
    names: ["JTK-656"],
  },
  {
    primary_id: "D0F0OK",
    names: [
      "Sulfametopyrazine",
      "Dalysep",
      "Kelfizin",
      "Kelfizina",
      "Kelfizine",
      "Longum",
      "Polycidal",
      "SMP",
      "SULFALENE",
      "Solfametopirazina",
      "Sulfalen",
      "Sulfaleno",
      "Sulfalenum",
      "Sulfamethopyrazine",
      "Sulfamethoxypyrazine",
      "Sulfamethoxysuccinate",
      "Sulfametoxypyridazin",
      "Sulfapyrazinemethoxine",
      "Sulfapyrazinemethoxyine",
      "Sulfapyrazinemethoxyne",
      "Sulfapyrazinmethoxine",
      "Sulphalene",
      "Sulphametopyrazine",
      "Kelfizine W",
      "Solfametopirazina [DCIT]",
      "AS 18908",
      "CBMicro_013257",
      "FI 5978",
      "WR 4629",
      "Farmitalia 204/122",
      "Kelfizina (TN)",
      "Sulfalene (USAN)",
      "Sulfalene [USAN:INN]",
      "Sulfaleno [INN-Spanish]",
      "Sulfalenum [INN-Latin]",
      "Sulfamethopyrazine (JAN)",
      "N1-(3-Methoxypyrazinyl)Sulfanilamide",
      "N(Sup 1)-(3-Methoxypyrazinyl)Sulfanilamide",
      "N(Sup1)-(3-Methoxypyrazinyl)Sulfanilamide",
      "N(Sup 1)-(3-Methoxy-2-Pyrazinyl)Sulfanilamide",
      "N(Sup1)-(3-Methoxy-2-Pyrazinyl)Sulfanilamide",
      "Sulfanilamide, N1-(3-Methoxypyrazinyl)-(8CI)",
      "2-(P-Aminobenzenesulfanamide)-3-Methoxypyrazine",
      "2-(P-Aminobenzenesulfonamido)-3-Methoxypyrazine",
      "2-Methoxy-3-Sulfanilamidopyrazine",
      "2-Sulfanilamide 3-Methoxy-Pyrazine",
      "2-Sulfanilamido-3-Methoxypyrazine",
      "3-Methoxy-2-Sulfanilamidopyrazine",
      "3-Methoxy-2-Sulfapyrazine",
      "3-Methoxypyrazine Sulfanilamide",
      "4-Amino-N-(3-Methoxy-Pyrazin-2-Yl)-Benzenesulfonamide",
      "4-Amino-N-(3-Methoxypyrazinyl)-Benzenesulfonamide",
      "4-Amino-N-(3-Methoxypyrazinyl)Benzenesulfonamide",
      "4-Amino-N-(3-Methoxypyrazin-2-Yl)Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0F0PG",
    names: ["ZD-6804"],
  },
  {
    primary_id: "D0F0PK",
    names: ["Indomethacin Analog 2"],
  },
  {
    primary_id: "D0F0PY",
    names: ["N,N'-(1',12'-Dodecydene)-Bis-(-)-Nor-MEP", "CHEMBL265214"],
  },
  {
    primary_id: "D0F0RO",
    names: ["[(40-OH) MeLeu]4-CsA Derivatives"],
  },
  {
    primary_id: "D0F0RR",
    names: ["GSK2592984A"],
  },
  {
    primary_id: "D0F0TW",
    names: ["SL-910102"],
  },
  {
    primary_id: "D0F0TY",
    names: ["PMID26004420-Compound-US20140031349C"],
  },
  {
    primary_id: "D0F0UA",
    names: [
      "MCI",
      "AC1L1B35",
      "CTK8A8774",
      "(2-Mercaptomethyl-4-Phenyl-Butyrylimino)-(5-Tetrazol-1-Ylmethyl-Thiophen-2-Yl)-Acetic Acid",
      "2-[4-Phenyl-2-(Sulfanylmethyl)Butanoyl]imino-2-[5-(Tetrazol-1-Ylmethyl)Thiophen-2-Yl]acetic Acid",
    ],
  },
  {
    primary_id: "D0F0UT",
    names: [
      "ACYLINE",
      "Acyline",
      "UNII-S3439D3B35",
      "CHEMBL262747",
      "S3439D3B35",
      "BDBM50102456",
      "DB11906",
      "D-Alaninamide, N-Acetyl-3-(2-Naphthalenyl)-D-Alanyl-4-Chloro-D-Phenylalanyl-3-(3-Pyridinyl)-D-Alanyl-L-Seryl-4-(Acetylamino)-L-Phenylalanyl-4-(Acetylamino)-D-Phenylalanyl-L-Leucyl-N6-(1-Methylethyl)-L-Lysyl-L-Prolyl-",
      "Z-3146",
      "Ac-D-Nal-[D-(PCl)Phe]-D-Pal-Ser-[Aph(Ac)]-D-[Aph(Ac)]-Leu-ILys-Pro-DAla-NH2",
    ],
  },
  {
    primary_id: "D0F0VD",
    names: ["ACHP"],
  },
  {
    primary_id: "D0F0VF",
    names: ["Benzoic Acid Linked Peptide Analog 2"],
  },
  {
    primary_id: "D0F0VQ",
    names: ["SapC-DOPS"],
  },
  {
    primary_id: "D0F0VW",
    names: ["Anti-BCMA CAR-T Cells"],
  },
  {
    primary_id: "D0F0VY",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 5"],
  },
  {
    primary_id: "D0F0XC",
    names: ["4-Oxadiazole Derivative 1"],
  },
  {
    primary_id: "D0F0XZ",
    names: [
      "MSC2032964A",
      "CHEMBL3330170",
      "MSC 2032964A",
      "1124381-43-6",
      "N-[5-(Cyclopropylamino)-7-(Trifluoromethyl)[1,2,4]triazolo[1,5-A]pyridin-2-Yl]-3-Pyridinecarboxamide",
      "XUKGFHHTSUKORV-UHFFFAOYSA-N",
      "SCHEMBL2788575",
      "GTPL8076",
      "MolPort-039-338-069",
      "ZINC59102467",
      "BDBM50023727",
      "AKOS027470238",
      "MRF-0000032",
      "NCGC00479218-01",
      "N-[5-(Cyclopropylamino)-7-(Trifluoromethyl)[1,2,4]triazolo[1,5-A]pyridin-2-Yl]nicotinamide",
    ],
  },
  {
    primary_id: "D0F0YL",
    names: ["SL-25.1039"],
  },
  {
    primary_id: "D0F0YM",
    names: ["MGN-4893", "Anti-MiR-451 Therapeutic (Polycythemia Vera), MiRagen Therapeutics"],
  },
  {
    primary_id: "D0F0YZ",
    names: [
      "Pentobarbital",
      "Aethaminalum",
      "Dorsital",
      "Etaminal",
      "Ethaminal",
      "Mebubarbital",
      "Mebumal",
      "Nebralin",
      "Nembutal",
      "Neodorm",
      "Pentabarbital",
      "Pentabarbitone",
      "Pentobarbitale",
      "Pentobarbitalum",
      "Pentobarbitone",
      "Pentobarbiturate",
      "Rivadorm",
      "Pentobarbital Calcium",
      "Pentobarbital Suppository Dosage Form",
      "Pentobarbitale [DCIT]",
      "Pentobarbituric Acid",
      "Ethyl-Propylmethylcarbinylbarbituric Acid",
      "Nembutal (TN)",
      "Nembutal (VAN)",
      "Neodorm (VAN)",
      "Neodorm (New)",
      "Pentobarbital (VAN)",
      "Pentobarbital [USP:INN]",
      "Pentobarbital, Monosodium Salt",
      "Pentobarbitalum [INN-Latin]",
      "Pentobarbitone (VAN)",
      "Pentobarbital (USP/INN)",
      "(+-)-5-Ethyl-5-(1-Methylbutyl)Barbituric Acid",
      "5-Ethyl-5-(1-Methylbutyl)-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Ethyl-5-(1-Methylbutyl)Barbituric Acid",
      "5-Ethyl-5-(1-Methylbutyl)Malonylurea",
      "5-Ethyl-5-(Sec-Pentyl)Barbituric Acid",
      "5-Ethyl-2-Hydroxy-5-(1-Methylbutyl)Pyrimidine-4,6(1H,5H)-Dione",
      "5-Ethyl-5-(Pentan-2-Yl)Pyrimidine-2,4,6(1H,3H,5H)-Trione;5-Ethyl-5-Pentan-2-Yl-1,3-Diazinane-2,4,6-Trione",
    ],
  },
  {
    primary_id: "D0F0ZE",
    names: ["Tyverb/Tykerb"],
  },
  {
    primary_id: "D0F0ZK",
    names: ["KB-003"],
  },
  {
    primary_id: "D0F0ZX",
    names: [
      "4-(DIMETHYLAMINO)BUTYL IMIDOTHIOCARBAMATE",
      "4-(Dimethylamino)Butyl Carbamimidothioate",
      "F 91488",
      "UNII-1I4LVX494H",
      "SKF 91488",
      "F-91488",
      "SKF-91488",
      "1I4LVX494H",
      "68643-23-2",
      "[4-(Carbamimidoylsulfanyl)Butyl]dimethylamine",
      "Carbamimidothioic Acid, 4-(Dimethylamino)Butyl Ester",
      "4DI",
      "Tocris-0512",
      "Lopac-S-145",
      "AC1L1JVO",
      "AC1Q1UAN",
      "Lopac0_001172",
      "Carbamimidothioic Acid 4-(Dimethylamino)Butyl Ester",
      "CHEMBL1230270",
      "SCHEMBL12648461",
      "CTK5C8308",
      "ZINC29747245",
      "AKOS006328360",
      "DB07106",
      "CCG-205246",
      "NCGC00024626-01",
      "NCGC00015911-02",
    ],
  },
  {
    primary_id: "D0F0ZY",
    names: ["Pazopanib HCl"],
  },
  {
    primary_id: "D0F1AS",
    names: [
      "1-METHYLXANTHINE",
      "1-Methylxanthine",
      "6136-37-4",
      "1-Methyl-3,7-Dihydro-Purine-2,6-Dione",
      "1-Methyl-1H-Purine-2,6(3H,7H)-Dione",
      "Methylxanthine",
      "Xanthine, 1-Methyl-",
      "UNII-7EE8WCA32U",
      "2,6-Dihydroxy-1-Methylpurine",
      "CCRIS 5816",
      "1-Methyl-7H-Xanthine",
      "EINECS 228-108-5",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1-Methyl-",
      "7EE8WCA32U",
      "CHEMBL1250",
      "2,6 Dihydroxy-1-Methylpurine",
      "CHEBI:68444",
      "3,7-Dihydro-1-Methyl-1H-Purine-2,6-Dione",
      "MVOYJPOZRLFTCP-UHFFFAOYSA-N",
      "1-Methyl-3,7-Dihydropurine-2,6-Dione",
      "1-Methyl-3,9-Dihydropurine-2,6-Dione",
    ],
  },
  {
    primary_id: "D0F1AX",
    names: [
      "CB-102930",
      "Antimicrobials, Cubist",
      "CB-193",
      "CB-286",
      "CB-432",
      "CB-447",
      "CB-454",
      "CB-484",
      "CB-545",
      "CB-628",
      "CB-629",
      "TRNA Synthetase Inhibitors (Antimicrobial)",
      "TRNA Synthetase Inhibitors (Antimicrobial), Cubist",
    ],
  },
  {
    primary_id: "D0F1BD",
    names: ["1-Biphenyl-4-Ylmaleimide"],
  },
  {
    primary_id: "D0F1CE",
    names: ["LCQ908"],
  },
  {
    primary_id: "D0F1CN",
    names: ["SST-0001"],
  },
  {
    primary_id: "D0F1CP",
    names: [
      "EPLIVANSERIN MESILATE",
      "1(Z)-[2-(Dimethylamino)Ethoxyimino]-1-(2-Fluorophenyl)-3-(4-Hydroxyphenyl)-2(E)-Propene Methanesulfonate",
      "Eplivanserin Mesilate",
      "SR-46349C",
    ],
  },
  {
    primary_id: "D0F1CT",
    names: ["Cyclopropylamine Derivative 4"],
  },
  {
    primary_id: "D0F1DK",
    names: ["Pyridotriazolopyrimidine Derivative 1"],
  },
  {
    primary_id: "D0F1DY",
    names: ["Albiglutide"],
  },
  {
    primary_id: "D0F1EB",
    names: [
      "MC-CAM",
      "CHEMBL386272",
      "BDBM50195674",
      "BDBM50091405",
      "14-[(4-Chlorocinnamoyl)Amino]-4,5alpha-Epoxy-17-(Cyclopropylmethyl)-3-Methoxymorphinan-6-One",
      "1N-[4-Cyclopropylmethyl-10-Methoxy-14-Oxo-(17S)-12-Oxa-4-Azapentacyclo[9.6.1.01,13.05,17.07,18]octadeca-7,9,11(18)-Trien-17-Yl]-3-(4-Chlorophenyl)-(E)-2-Propenamide(Methyoclocinnamox (MC-CAM))",
    ],
  },
  {
    primary_id: "D0F1EF",
    names: [
      "Auryntricarboxylic Acid (ATA)",
      "244218-51-7",
      "UNII-7I21WLZ2FP",
      "TCMDC-125882",
      "JTC-801 HCl",
      "7I21WLZ2FP",
      "N-(4-Amino-2-Methylquinolin-6-Yl)-2-((4-Ethylphenoxy)Methyl)Benzamide Hydrochloride",
      "N-(4-Amino-2-Methyl-6-Quinolinyl)-2-[(4-Ethylphenoxy)Methyl]benzamide Hydrochloride",
      "C26H26ClN3O2",
      "N-(4-Amino-2-Methylquinolin-6-Yl)-2-(4-Ethylphenoxymethyl)Benzamide Hydrochloride",
      "N-(4-Amino-2-Methylquinolin-6-Yl)-2-[(4-Ethylphenoxy)Methyl]benzamide Hydrochloride",
      "AC1NSKGE",
      "MLS006011235",
      "SCHEMBL2240337",
      "CHEMBL531742",
      "CTK8E7568",
      "DTXSID10415525",
    ],
  },
  {
    primary_id: "D0F1EI",
    names: ["AZD-0303"],
  },
  {
    primary_id: "D0F1EX",
    names: [
      "Alclometasone",
      "Aclometasone",
      "Aclovate (TN)",
      "Alclometasone (INN)",
      "Alclometasone [INN:BAN]",
      "(7R,8S,9S,10R,11S,13S,14S,16R,17R)-7-Chloro-11,17-Dihydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-7,8,9,11,12,14,15,16-Octahydro-6H-Cyclopenta[a]phenanthren-3-One",
      "(7alpha,11beta,16alpha)-7-Chloro-11,17,21-Trihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
      "7alpha-Chloro-16alpha-Methylprednisolone",
    ],
  },
  {
    primary_id: "D0F1FD",
    names: ["Complex Heterocyclic Compound 1"],
  },
  {
    primary_id: "D0F1FN",
    names: [
      "(2-Indol-1-Yl-Ethyl)-Dimethyl-Amine",
      "1H-Indole-1-Ethanamine, N,N-Dimethyl-",
      "CHEMBL38633",
      "87482-09-5",
      "SCHEMBL4060777",
      "CTK3C3615",
      "DTXSID80579188",
      "PSHKCPACSIZILK-UHFFFAOYSA-N",
      "(2-Indol-1-Ylethyl)Dimethylamine",
      "BDBM50108308",
      "N,N-Dimethyl-1H-Indole-1-Ethanamine",
      "AKOS013123773",
      "[2-(1H-Indol-1-Yl)Ethyl]dimethylamine",
      "2-(1H-Indol-1-Yl)-N,N-Dimethylethan-1-Amine",
    ],
  },
  {
    primary_id: "D0F1GD",
    names: ["AuroVax", "Antiherpes Vaccine, AuRx", "HSV-2 Vaccine, AuRx"],
  },
  {
    primary_id: "D0F1GS",
    names: ["Calcium Acetate"],
  },
  {
    primary_id: "D0F1HT",
    names: ["MB-CART19.1"],
  },
  {
    primary_id: "D0F1HV",
    names: ["TPP-1"],
  },
  {
    primary_id: "D0F1IS",
    names: ["BMS-189323"],
  },
  {
    primary_id: "D0F1JB",
    names: [
      "Globorix",
      "Cojugated Pediatric Vaccine (Diptheria/Tetanus/Pertussis/Hepatitis B/Haemophilus Influenzae Type B Disease/Neisseria Meningitis A And C), GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D0F1JD",
    names: ["Apabetalone"],
  },
  {
    primary_id: "D0F1JY",
    names: [
      "LAS-186323",
      "DHODH Inhibitor (Autoimmune Disease), Alimirall",
      "DHODH Inhibitor (Rheumatoid Arthritis/Multiple Sclerosis), Almirall",
    ],
  },
  {
    primary_id: "D0F1KM",
    names: ["WR-190706"],
  },
  {
    primary_id: "D0F1KP",
    names: ["PSN821"],
  },
  {
    primary_id: "D0F1KR",
    names: ["(S)-Sirtinol"],
  },
  {
    primary_id: "D0F1LA",
    names: ["N,O-Didansyl-L-Tyrosine"],
  },
  {
    primary_id: "D0F1LZ",
    names: ["Acetic Acid, Glacial"],
  },
  {
    primary_id: "D0F1MU",
    names: ["EML-336", "CRE-16336", "EML-16336", "ONO-5816"],
  },
  {
    primary_id: "D0F1NW",
    names: ["Cancer Vac"],
  },
  {
    primary_id: "D0F1OG",
    names: ["Soluble ErbB3 Theragnostics"],
  },
  {
    primary_id: "D0F1OH",
    names: ["CD19CAT-41BBZ CAR T-Cells"],
  },
  {
    primary_id: "D0F1PH",
    names: ["Rimeporide"],
  },
  {
    primary_id: "D0F1RL",
    names: ["Alpha-Sulfanyl(4-Bromobenzyl)Phosphonic Acid"],
  },
  {
    primary_id: "D0F1RU",
    names: ["4-(2,3-Dimethoxy-Phenoxy)-6,7-Dimethoxy-Quinoline", "CHEMBL98155"],
  },
  {
    primary_id: "D0F1SI",
    names: ["4-(2-(Thiophen-3-Yl)-9H-Purin-6-Yl)Morpholine", "CHEMBL608095", "SCHEMBL4438208"],
  },
  {
    primary_id: "D0F1SN",
    names: ["NU-8231"],
  },
  {
    primary_id: "D0F1SS",
    names: ["Lecanindole D"],
  },
  {
    primary_id: "D0F1TU",
    names: ["TPP+"],
  },
  {
    primary_id: "D0F1UD",
    names: [
      "4-Nonylphenylboronic Acid",
      "256383-45-6",
      "4-N-Nonylphenylboronic Acid",
      "(4-Nonylphenyl)Boronic Acid",
      "4-N-NONYLBENZENEBORONIC ACID",
      "4-N-Nonylphenylboronicacid",
      "VONVJOGSLHAKOX-UHFFFAOYSA-N",
      "AC1NEAO1",
      "(4-Nonylphenyl)Boranediol",
      "4-Nonylbenzene Boronic Acid",
      "B-(4-Nonylphenyl)Boronic Acid",
      "SCHEMBL3405525",
      "CHEMBL485946",
      "4-(N-Nonyl)Benzeneboronic Acid",
      "BDBM26134",
      "CTK8B1318",
      "1-Borono-4-(Non-1-Yl)Benzene",
      "DTXSID10404611",
      "MolPort-000-931-545",
      "Boronic Acid,B-(4-Nonylphenyl)-",
      "4-(Non-1-Yl)Benzeneboronic Acid",
      "ZX-AT001529",
      "OR7244",
      "CN-219",
    ],
  },
  {
    primary_id: "D0F1UL",
    names: [
      "Dydrogesterone",
      "Dehydrogesterone",
      "Didrogesterona",
      "Didrogesterone",
      "Diphaston",
      "Dufaston",
      "Duphaston",
      "Duvaron",
      "Dydrogesterona",
      "Dydrogesteronum",
      "Gestatron",
      "Gynorest",
      "Hydrogesterone",
      "Hydrogestrone",
      "Isopregnenone",
      "Prodel",
      "Retrone",
      "Terolut",
      "Didrogesterone [DCIT]",
      "Solvay Brand Of Dydrogesterone",
      "DELTA6-Retroprogesterone",
      "Duphaston (TN)",
      "Dydrogesterona [INN-Spanish]",
      "Dydrogesteronum [INN-Latin]",
      "Gynorest (TN)",
      "Delta(6)-Retroprogesterone",
      "Delta(Sup 6)-Retroprogesterone",
      "Retro-6-Dehydroprogesterone",
      "Dydrogesterone (JP15/USP/INN)",
      "Dydrogesterone [USAN:INN:BAN:JAN]",
      "Pregna-4,6-Diene-3,20-Dione, (9-Beta,10-Alpha)-(9CI)",
      "(8S,9R,10S,13S,14S,17S)-17-Acetyl-10,13-Dimethyl-1,2,8,9,11,12,14,15,16,17-Decahydrocyclopenta[a]phenanthren-3-One",
      "(9-Beta,10-Alpha)-Pregna-4,6-Diene-3,20-Dione",
      "(9beta,10alpha)-Pregna-4,6-Diene-3,20-Dione",
      "10alpha-Isopregnenone",
      "6 Dehydro 9 Beta 10 Alpha Progesterone",
      "6-Dehydro-9 Beta-10 Alpha-Progesterone",
      "6-Dehydro-9.beta.,10.alpha.-Progesterone",
      "6-Dehydro-9beta,10alpha-Progesterone",
      "6-Dehydro-Retro-Progesterone",
      "6-Dehydroretroprogesterone",
      "9-.beta.,10.alpha.-Pregna-4,6-Diene-3,20-Dione",
      "9-Beta,10-Alpha-Pregna-4,6-Diene-3,20-Dione",
      "9-Beta,10alpha-Pregna-4,6-Diene-3,20-Dione",
      "9.beta.,10.alpha.-Pregna-4,6-Diene-3,20-Dione",
      "9beta,10alpha-Pregna-4,6-Diene-3,20-Dione",
      "9beta,10alpha-Pregna-4,6-Diene-3,20-Dione (8CI)",
    ],
  },
  {
    primary_id: "D0F1UW",
    names: ["C-Vaxin"],
  },
  {
    primary_id: "D0F1UZ",
    names: ["Amitriptyline+ketamine", "AmiKet"],
  },
  {
    primary_id: "D0F1VP",
    names: [
      "VL-2799",
      "CHEMBL380355",
      "BDBM50185259",
      "AKOS030589696",
      "2(R,S)-{2-[(1H-Indole-2-Carbonyl)Amino]-Benzoylamino}propionic Acid",
      "2-(R,S)-{2-[(1H-Indole-2-Carbonyl)-Amino]-Benzoylamino}-Propionic Acid",
    ],
  },
  {
    primary_id: "D0F1VQ",
    names: ["ZIFROSILONE"],
  },
  {
    primary_id: "D0F1VR",
    names: [
      "3,4-Dibromo-2-(5-Bromo-2-Hydroxy-Phenoxy)-Phenol",
      "CHEMBL187472",
      "Phenol, 3,4-Dibromo-2-(5-Bromo-2-Hydroxyphenoxy)-",
      "BDBM50150781",
      "741285-19-8",
      "5,5',6-Tribromo-2,2'-Dihydroxydiphenyl Ether",
    ],
  },
  {
    primary_id: "D0F1WD",
    names: [
      "C-10068",
      "Deuterated Sigma-1 Agonists (Epilepsy/Brain Injury/Neuropathic Pain)",
      "Deuterated Sigma-1 Agonists (Epilepsy/Brain Injury/Neuropathic Pain), CoNCERT/Walter Reed Army Institute",
    ],
  },
  {
    primary_id: "D0F1WQ",
    names: ["Pbi-ShSTMN1 LP", "Bi-ShRNAstathmin"],
  },
  {
    primary_id: "D0F1WR",
    names: ["GSK649868"],
  },
  {
    primary_id: "D0F1WX",
    names: [
      "SSR240600",
      "Burapitant",
      "SSR-240600",
      "SCHEMBL1281822",
      "CHEMBL3544947",
      "BDBM86055",
      "SSR-240600C",
      "AN-27922",
      "L001672",
    ],
  },
  {
    primary_id: "D0F1XE",
    names: [
      "1-(3-Methylbutylidene)Thiosemicarbazide",
      "CHEMBL467890",
      "Isobutyraldehyde-Thiosemicarbazone",
      "AC1NYENJ",
      "ZINC5452593",
      "BDBM50268195",
      "AKOS032954865",
    ],
  },
  {
    primary_id: "D0F1XY",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-Phe-Asp-Nle-D-Trp-Boc", "CHEMBL413644"],
  },
  {
    primary_id: "D0F1ZB",
    names: ["LFA-102"],
  },
  {
    primary_id: "D0F1ZE",
    names: [
      "2-Naphthalen-1-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "CHEMBL3144719",
      "CHEMBL273743",
      "SCHEMBL9646265",
      "SXKBSXCUDSHBDF-UHFFFAOYSA-N",
      "BDBM50008985",
      "2-[(1-Naphthalenyl)Methyl]-1H-Indazol-3(2H)-One",
    ],
  },
  {
    primary_id: "D0F1ZI",
    names: ["F-16-IL-2 Fusion Protein"],
  },
  {
    primary_id: "D0F2AD",
    names: [
      "Dioscin",
      "19057-60-4",
      "Collettiside III",
      "Dioscine",
      "CHEBI:74023",
      "CCRIS 4123",
      "UNII-3B95U4OLWV",
      "3-O-(Rhaalpha1-4(Rhaalpha1-2)Glcbeta)-(25R)-Spirost-5-En-3beta-Ol",
      "3-O-[alpha-L-Rha-(1-&gt;4)-[alpha-L-Rha-(1-&gt;2)]-Beta-D-Glc]-Diosgenin",
      "3-O-[alpha-L-Rhap-(1-&gt;4)-[alpha-L-Rhap-(1-&gt;2)]-Beta-D-Glcp]-Diosgenin",
      "(25R)-Spirost-5-En-3beta-Yl Alpha-L-Rhamnopyranosyl-(1-&gt;2)-[alpha-L-Mannopyranosyl-(1-&gt;4)]-Beta-D-Glucopyranoside",
      "3B95U4OLWV",
      "AC1L3OGN",
      "Dioscin(Collettiside III)",
      "Dioscin (Collettiside III)",
      "GTPL840",
    ],
  },
  {
    primary_id: "D0F2AK",
    names: ["Promegestone"],
  },
  {
    primary_id: "D0F2BF",
    names: ["PMID28270021-Compound-WO2010077680 803"],
  },
  {
    primary_id: "D0F2BP",
    names: ["Erleadaapalutamide"],
  },
  {
    primary_id: "D0F2CH",
    names: ["Mutated Growth Hormone"],
  },
  {
    primary_id: "D0F2CQ",
    names: ["Fluoroglycoconjugates"],
  },
  {
    primary_id: "D0F2CS",
    names: ["Cipro DPI", "Ciprofloxacin Dry Powder Inhaler"],
  },
  {
    primary_id: "D0F2DC",
    names: ["Trans-2-Fluoro-2-P-Tolylcyclopropanamine", "CHEMBL468852"],
  },
  {
    primary_id: "D0F2DD",
    names: ["KRH-102140", "5-Lipoxygenase Inhibitor (Inflammation), KRICT"],
  },
  {
    primary_id: "D0F2DE",
    names: ["Non-Nucleoside Therapeutics"],
  },
  {
    primary_id: "D0F2DJ",
    names: ["Stroke Program, Dong Wha"],
  },
  {
    primary_id: "D0F2GG",
    names: ["Fenclonine", "4-Chlorophenylalanine", "Para-Chlorophenylalanine", "PCPA"],
  },
  {
    primary_id: "D0F2GK",
    names: ["RGI-3010"],
  },
  {
    primary_id: "D0F2GY",
    names: ["Glu20-Lys24][Gly8][GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0F2HA",
    names: ["ICI-D-8731", "D-8731", "ZD-8731"],
  },
  {
    primary_id: "D0F2HJ",
    names: ["2-Cinnamamido-N1-Hydroxy-N4-Pentylsuccinamide", "CHEMBL596560"],
  },
  {
    primary_id: "D0F2IC",
    names: ["NTx-488"],
  },
  {
    primary_id: "D0F2IM",
    names: ["MEDI-573"],
  },
  {
    primary_id: "D0F2JS",
    names: ["CHR-2797"],
  },
  {
    primary_id: "D0F2JT",
    names: ["MRX-1"],
  },
  {
    primary_id: "D0F2JY",
    names: ["LY-2334737"],
  },
  {
    primary_id: "D0F2KL",
    names: [
      "3-[(6-Methylpyridin-2-Yl)Ethynyl]benzonitrile",
      "CHEMBL181483",
      "SCHEMBL4711543",
      "FKODYULBKPINFM-UHFFFAOYSA-N",
      "BDBM50160632",
      "ZINC28362342",
      "3-(6-Methyl-Pyridin-2-Ylethynyl)-Benzonitrile",
    ],
  },
  {
    primary_id: "D0F2KN",
    names: ["Dihydropyrimidinone Derivative 3"],
  },
  {
    primary_id: "D0F2KX",
    names: ["Granotapide"],
  },
  {
    primary_id: "D0F2KY",
    names: ["TetraMen-T"],
  },
  {
    primary_id: "D0F2ME",
    names: [
      "Acetate Ion",
      "Acetate",
      "Acetic Acid, Ion(1-)",
      "Acetate Ions",
      "71-50-1",
      "Monoacetate",
      "MeCO2 Anion",
      "UNII-569DQM74SC",
      "Ethanoate",
      "Acetat",
      "569DQM74SC",
      "Shotgun",
      "Ethanoat",
      "CHEMBL1354",
      "Racemic Acetate",
      "Azetat",
      "Acetyl Hydroxide",
      "Acetic Acid Ion",
      "Acetic Cid Glacial",
      "TCLP Extraction Fluid 2",
      "AC1Q1J9D",
      "AC1Q1J2O",
      "CH3-COO(-)",
      "AC1L18N9",
      "DTXSID1037694",
      "CTK5D4394",
      "CHEBI:30089",
      "MolPort-006-169-563",
      "QTBSBXVTEAMEQO-UHFFFAOYSA-M",
      "STL282721",
      "CMC_13391",
      "BDBM50159793",
      "AKOS022101130",
      "AN-25008",
      "AN-23801",
      "LS-189936",
      "1395-EP2380874A2",
      "1395-EP2380661A2",
      "1395-EP2316837A1",
    ],
  },
  {
    primary_id: "D0F2ML",
    names: ["MEDETOMIDINE"],
  },
  {
    primary_id: "D0F2MM",
    names: ["Nanotherapeutics"],
  },
  {
    primary_id: "D0F2MP",
    names: ["PMID23639540C13d"],
  },
  {
    primary_id: "D0F2NF",
    names: ["GSK-958108"],
  },
  {
    primary_id: "D0F2NG",
    names: ["HIV Vaccine"],
  },
  {
    primary_id: "D0F2NW",
    names: ["Pyrazolopyrimidine And Thienopyrimidine Amide Derivative 2"],
  },
  {
    primary_id: "D0F2OG",
    names: ["AFM-19", "Anti-Chemokine Antibody (BiBLOCK-TandAb, COPD/Asthma/Allergy), Affimed"],
  },
  {
    primary_id: "D0F2OO",
    names: ["PMID29649907-Compound-18"],
  },
  {
    primary_id: "D0F2PD",
    names: ["Arimoclomol", "BRX-345"],
  },
  {
    primary_id: "D0F2PO",
    names: [
      "Pindolol",
      "Betapindol",
      "Blocklin",
      "Calvisken",
      "Carvisken",
      "Decreten",
      "Durapindol",
      "Pectobloc",
      "Pinbetol",
      "Pindololum",
      "Prindolol",
      "Prinodolol",
      "Pynastin",
      "Visken",
      "Blockin L",
      "Blocklin L",
      "LB 46",
      "LB46",
      "P 0778",
      "Betapindol (TN)",
      "Blockin L (TN)",
      "Blocklin L (TN)",
      "Blocklin-L",
      "Calvisken (TN)",
      "Cardilate (TN)",
      "Carvisken (TN)",
      "DL-LB 46",
      "DL-Pindolol",
      "Decreten (TN)",
      "Durapindol (TN)",
      "Glauco-Viskin",
      "Glauco-Visken",
      "LB-46",
      "P-6820",
      "Pectobloc (TN)",
      "Pinbetol (TN)",
      "Pindololum [INN-Latin]",
      "Prindolol (TN)",
      "Pynastin (TN)",
      "Visken (TN)",
      "Blocklin-L (TN)",
      "Glauco-Visken (TN)",
      "Pindolol (JP15/USP/INN)",
      "Pindolol [USAN:INN:BAN:JAN]",
      "DL-4-[2-Hydroxy-3-(Isopropylamino)Propoxy]indole",
      "(+-)-Pindolol",
      "(1)-1-(1H-Indol-4-Yloxy)-3-(Isopropylamino)Propan-2-Ol",
      "1-((1-Methylethyl)Amino)-3-(4-Indolyloxy)-2-Propanol",
      "1-(1H-Indol-4-Yloxy)-3-((1-Methylethyl)Amino)-2-Propanol",
      "1-(1H-Indol-4-Yloxy)-3-(Isopropylamino)-2-Propanol",
      "1-(1H-Indol-4-Yloxy)-3-[(1-Methylethyl)Amino]-2-Propanol",
      "1-(1H-Indol-4-Yloxy)-3-(Isopropylamino)Propan-2-Ol",
      "1-(1H-Indol-4-Yloxy)-3-(Propan-2-Ylamino)-Propan-2-Ol",
      "1-(1H-Indol-4-Yloxy)-3-(Propan-2-Ylamino)Propan-2-Ol",
      "1-(1H-Indol-4-Yloxy)-3-[(1-Methylethyl)Amino]propan-2-Ol",
      "1-(1H-Indol-4-Yloxy)-3-[(Propan-2-Yl)Amino]propan-2-Ol",
      "1-(Indol-4-Yloxy)-3-(Isopropylamino)-2-Propanol",
      "4-(2-Hydroxy-3-Isopropylaminopropoxy)-Indole",
      "4-(3-(Isopropylamino)-2-Hydroxypropoxy)Indole",
    ],
  },
  {
    primary_id: "D0F2PQ",
    names: ["BL-1050"],
  },
  {
    primary_id: "D0F2PZ",
    names: [
      "SUN-E3001",
      "CHS-13340",
      "PTH[1,34], Suntory",
      "RhPTH(1-34)",
      "RhPTH[1,34], Daiichi Asubio Pharma",
      "Recombinant Human Parathyroid Hormone[residues 1-34] (Osteoporosis), Asubio Pharma",
      "RhPTH[1,34] (Osteporosis), Asubio Pharma",
    ],
  },
  {
    primary_id: "D0F2QP",
    names: [
      "CRB-0022",
      "Anti-NGF Monoclonal Antibody (Osteoarthritis Pain/Chronic Pain), Rottapharm Madaus",
      "Anti-Nerve Growth Factor Monoclonal Antibody (Osteoarthritis Pain/Chronic Pain), Rottapharm Madaus",
    ],
  },
  {
    primary_id: "D0F2QS",
    names: ["Mebiphon"],
  },
  {
    primary_id: "D0F2RP",
    names: ["VEGF/RGel"],
  },
  {
    primary_id: "D0F2SA",
    names: ["BPKDi"],
  },
  {
    primary_id: "D0F2SM",
    names: ["(R)(+)-2-(4-Fluorophenyl)-7-Methoxychroman-4-One", "CHEMBL600385"],
  },
  {
    primary_id: "D0F2SQ",
    names: ["Thiophosphoric Acid Dec-9-Enyl Ester"],
  },
  {
    primary_id: "D0F2TY",
    names: ["EBV-Related Hodgkin's Disease Vaccine"],
  },
  {
    primary_id: "D0F2VL",
    names: ["3-Amino-5,6-Dihydro-2H-Benzo[f]quinazolin-1-One"],
  },
  {
    primary_id: "D0F2VZ",
    names: ["Diabetes Therapy, BioCure"],
  },
  {
    primary_id: "D0F2WA",
    names: ["10-Trifluoroacetyl-DDACTHF"],
  },
  {
    primary_id: "D0F2WN",
    names: ["NAHE-001"],
  },
  {
    primary_id: "D0F2WP",
    names: ["TAK-385"],
  },
  {
    primary_id: "D0F2WQ",
    names: ["1,3,5-BENZENETRICARBOXYLIC ACID"],
  },
  {
    primary_id: "D0F2XC",
    names: ["EBMS Therapy, Cardiovascular Regeneration"],
  },
  {
    primary_id: "D0F2XI",
    names: [
      "4BP-TQS",
      "360791-49-7",
      "4-(4-Bromophenyl)-3a,4,5,9b-Tetrahydro-3H-Cyclopenta[c]quinoline-8-Sulfonamide",
      "AC1MEMT7",
      "ChemDiv1_000271",
      "Cambridge Id 5647991",
      "Oprea1_528880",
      "ARONIS25331",
      "GTPL3962",
      "SCHEMBL17458811",
      "HMS587M07",
      "SYN5056",
      "MolPort-002-160-471",
      "STL289206",
      "AKOS003520670",
      "AKOS022133488",
      "MCULE-3388310837",
      "4BP-TQS, &gt",
      "KS-0000477C",
      "ST042158",
      "AS-16422",
      "BB0293824",
      "KB-268939",
      "AB00091637-01",
      "SR-01000388448",
      "SR-01000388448-1",
      "4-(4-Bromophenyl)-3H,3aH,4H,5H,9bH-Cyclopenta[c]quinoline-8-Sulfonamide",
    ],
  },
  {
    primary_id: "D0F2XQ",
    names: ["Fludarabine"],
  },
  {
    primary_id: "D0F2XV",
    names: [
      "Aztreonam",
      "Azactam",
      "Primbactam",
      "Azactam (TN)",
      "SQ-26776",
      "Monobactam, SQ 26776, Squibb 26776, Aztreonam",
      "[2S-[2alpha,3beta(Z)]]-2-[[[1-(2-Amino-4-Thiazolyl)-2-[(2-Methyl-4-Oxo-1-Sulfo-3-Azetidinyl)Amino]-2-Oxoethylidene]amino]oxy]-2-Methylpropanoic Acid",
      "2-({[(1Z)-1-(2-Amino-1,3-Thiazol-4-Yl)-2-{[(2S,3S)-2-Methyl-4-Oxo-1-Sulfoazetidin-3-Yl]amino}-2-Oxoethylidene]amino}oxy)-2-Methylpropanoic Acid",
      "2-[(Z)-[1-(2-Amino-1,3-Thiazol-4-Yl)-2-[[(2S,3S)-2-Methyl-4-Oxo-1-Sulfoazetidin-3-Yl]amino]-2-Oxoethylidene]amino]oxy-2-Methylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0F2XZ",
    names: ["(S)-2-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Pyridine", "CHEMBL429740"],
  },
  {
    primary_id: "D0F2YC",
    names: [
      "1,3-Dibenzyl-[1,3]diazetidine-2,4-Dione",
      "CHEMBL47394",
      "1,3-Dibenzyl-1,3-Diazetidine-2,4-Dione",
      "GTPL6542",
      "BDBM50101132",
      "Compound 1 [PMID: 11425539]",
    ],
  },
  {
    primary_id: "D0F3AN",
    names: ["LY2119620"],
  },
  {
    primary_id: "D0F3AR",
    names: ["4-((Z)-1-Naphthalen-1-Yl-Propenyl)-1H-Imidazole", "CHEMBL322844", "SCHEMBL7919847", "ZINC13757204"],
  },
  {
    primary_id: "D0F3BK",
    names: ["PMID27774824-Compound-Figure6Example6"],
  },
  {
    primary_id: "D0F3BO",
    names: ["Binuclear Gold(I) Compound 3"],
  },
  {
    primary_id: "D0F3BP",
    names: ["PAN-90716-DES"],
  },
  {
    primary_id: "D0F3BY",
    names: ["GW842470X"],
  },
  {
    primary_id: "D0F3CA",
    names: [
      "3,4-Dichloroisocoumarin",
      "51050-59-0",
      "3,4-Dcl",
      "3,4-Dichloro-1H-Isochromen-1-One",
      "1H-2-Benzopyran-1-One,3,4-Dichloro-",
      "3,4-DCI",
      "3,4 Dichloroisocoumarin",
      "3,4-Dichloro-1H-2-Benzopyran-1-One",
      "1H-2-Benzopyran-1-One, 3,4-Dichloro-",
      "CHEMBL24983",
      "3,4-Dichloroisochromen-1-One",
      "3,4-Dichloro-2-Benzopyran-1-One",
      "C9H4Cl2O2",
      "Lopac-D-7910",
      "Cid_1609",
      "AC1Q3H9V",
      "AC1L1BU9",
      "Lopac0_000442",
      "KBioGR_000269",
      "KBioSS_000269",
      "BSPBio_001549",
      "MLS002153325",
      "SCHEMBL106901",
      "3,4-Dichloro-Isochromen-1-One",
      "KBio3_000538",
      "KBio2_002837",
      "CTK4J3543",
      "KBio2_005405",
    ],
  },
  {
    primary_id: "D0F3CH",
    names: ["JWH-151"],
  },
  {
    primary_id: "D0F3CO",
    names: ["KOJIC ACID"],
  },
  {
    primary_id: "D0F3CS",
    names: ["Phenylalanylmethane"],
  },
  {
    primary_id: "D0F3EH",
    names: ["CS-712"],
  },
  {
    primary_id: "D0F3EO",
    names: ["HS-110"],
  },
  {
    primary_id: "D0F3FB",
    names: ["S-9977"],
  },
  {
    primary_id: "D0F3FC",
    names: ["GSK-650394"],
  },
  {
    primary_id: "D0F3FV",
    names: ["MRNA-1440"],
  },
  {
    primary_id: "D0F3GA",
    names: [
      "Acyclic Nucleoside Phosphonates",
      "Acyclic Nucleoside Phosphonates (Antiviral)",
      "Acyclic Nucleoside Phosphonates (Antiviral), RegaInstitute",
      "Benzothiadiazine Dioxide Derivatives (Antiviral), Rega Institute",
      "HPMP-5-AzaC",
    ],
  },
  {
    primary_id: "D0F3GC",
    names: ["Dihydropyrido Pyrazinone Compound 3"],
  },
  {
    primary_id: "D0F3GK",
    names: ["Peniel 1000"],
  },
  {
    primary_id: "D0F3GL",
    names: ["Vacc-4x"],
  },
  {
    primary_id: "D0F3HA",
    names: ["Cyclohexyl Carbamate Derivative 2"],
  },
  {
    primary_id: "D0F3HC",
    names: ["GS-9268", "Aztreonam Lysine"],
  },
  {
    primary_id: "D0F3HP",
    names: ["PMID25666693-Compound-101"],
  },
  {
    primary_id: "D0F3II",
    names: ["COR100140"],
  },
  {
    primary_id: "D0F3IM",
    names: ["IMX-MSP4"],
  },
  {
    primary_id: "D0F3IP",
    names: [
      "Asasantin",
      "Aggrenox",
      "Aspirin Mixture With Dipyridamole",
      "87653-67-6",
      "Asasantin Retard",
      "Aspirin / Dipyridamole",
      "Aspirin, Dipyridamole Drug Combination",
      "TX-3301",
      "TX 3301",
      "C24H40N8O4.C9H8O4",
      "Aspirin/Extended-Release Dipyridamole",
      "AC1L3AE0",
      "SCHEMBL456521",
      "DTXSID40236543",
      "LS-178622",
      "Benzoic Acid, 2-(Acetyloxy)-, Mixt. With 2,2',2'',2'''-((4,8-Di-1-Piperidinylpyrimido(5,4-D)Pyrimidine-2,6-Diyl)Dinitrilo)Tetrakis(Ethanol)",
    ],
  },
  {
    primary_id: "D0F3JA",
    names: ["HL-023"],
  },
  {
    primary_id: "D0F3JL",
    names: ["RSLV-132"],
  },
  {
    primary_id: "D0F3JT",
    names: ["Mecasermin"],
  },
  {
    primary_id: "D0F3KC",
    names: ["Indium-111", "ABT-806"],
  },
  {
    primary_id: "D0F3KP",
    names: ["Thiocarbamate"],
  },
  {
    primary_id: "D0F3LS",
    names: ["Insulin Determir"],
  },
  {
    primary_id: "D0F3MT",
    names: ["AVX901"],
  },
  {
    primary_id: "D0F3NB",
    names: ["DTRMWXHS 12"],
  },
  {
    primary_id: "D0F3NF",
    names: ["TAS-116"],
  },
  {
    primary_id: "D0F3NH",
    names: ["Balaglitazone", "DRF-2593", "NN-2344", "DRF-2593-307", "NNC-61-0645"],
  },
  {
    primary_id: "D0F3OM",
    names: ["2-((3,5-Diamino-1H-Pyrazol-4-Yl)Diazenyl)Phenol"],
  },
  {
    primary_id: "D0F3ON",
    names: ["Adv-005"],
  },
  {
    primary_id: "D0F3PC",
    names: ["ISIS 111121"],
  },
  {
    primary_id: "D0F3PF",
    names: [
      "1-Adamantan-1-Yl-3-(1-Benzyl-Piperidin-4-Yl)-Urea",
      "CHEMBL387034",
      "AC1MG4WB",
      "SCHEMBL4349264",
      "MolPort-001-589-466",
      "BDBM100357",
      "ZINC5041294",
      "STK455719",
      "BDBM50191877",
      "AKOS003332205",
      "MCULE-1433138775",
      "ST50939640",
      "SR-01000276706",
      "1-(1-Adamantyl)-3-(1-Benzylpiperidin-4-Yl)Urea",
      "US8501783, 1158",
      "SR-01000276706-1",
      "N-Adamantanyl{[1-Benzyl(4-Piperidyl)]amino}carboxamide",
    ],
  },
  {
    primary_id: "D0F3PU",
    names: ["Iboctadekin + Doxil"],
  },
  {
    primary_id: "D0F3QP",
    names: ["4-Hydroxylonchocarpin"],
  },
  {
    primary_id: "D0F3RN",
    names: [
      "1-(4-Chloro-Phenyl)-2-Phenyl-Ethane-1,2-Dione",
      "4-Chlorobenzil",
      "22711-23-5",
      "1-(4-Chlorophenyl)-2-Phenylethane-1,2-Dione",
      "4-Chlorodibenzoyl",
      "(P-Chlorophenyl)Phenylethanedione",
      "QDCKVAZDINMMHO-UHFFFAOYSA-N",
      "1-(4-Chlorophenyl)-2-Phenylethan-1,2-Dione",
      "1-(4-Chlorophenyl)-2-Phenyl-1,2-Ethanedione",
      "1,2-Ethanedione,1-(4-Chlorophenyl)-2-Phenyl-",
      "P-Chlorbenzil",
      "EINECS 245-169-3",
      "ACMC-20amkt",
      "1-(4-Chloro-Phenyl)-2-Phenyl-Ethane",
      "AC1L3IMU",
      "Benzil-Based Compound, 15",
      "SCHEMBL912515",
      "CHEMBL192180",
      "CTK4F0011",
      "BDBM22737",
      "DTXSID00177246",
      "4-Chlorobibenzyl-Alpha,beta-Dione",
      "MolPort-001-763-274",
    ],
  },
  {
    primary_id: "D0F3RQ",
    names: ["BAY 87-2243"],
  },
  {
    primary_id: "D0F3SQ",
    names: ["PMID29649907-Compound-2"],
  },
  {
    primary_id: "D0F3SY",
    names: ["Tegafur"],
  },
  {
    primary_id: "D0F3SZ",
    names: ["Hirudin"],
  },
  {
    primary_id: "D0F3TB",
    names: ["PMID29671355-Compound-65a"],
  },
  {
    primary_id: "D0F3TI",
    names: ["PT-630"],
  },
  {
    primary_id: "D0F3TM",
    names: ["PMID17942791C3"],
  },
  {
    primary_id: "D0F3UR",
    names: [
      "4-(4-Methyl-Benzylideneamino)-Benzenesulfonamide",
      "CHEMBL429915",
      "AC1LEQSK",
      "SCHEMBL5312741",
      "SOHFVFXITPDEDK-UHFFFAOYSA-N",
      "ZINC95919525",
      "ZINC18036434",
      "BDBM50371763",
      "ZINC253618240",
      "AKOS016038193",
      "AKOS003632590",
      "MCULE-3900521489",
      "ST009696",
      "N-(4-Methylbenzylidene)-4-Sulfamoylaniline",
      "J3.552.121F",
      "4-[[(E)-4-Methylbenzylidene]amino]benzenesulfonamide",
      "4-[(4-Methylphenyl)Methylideneamino]benzenesulfonamide",
      "4-[(1E)-2-(4-Methylphenyl)-1-Azavinyl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0F3VE",
    names: [
      "GNF-PF-2812",
      "3-(4-Methoxyphenyl)-5H-Indeno[1,2-C]pyridazin-5-One",
      "NSC663884",
      "CHEMBL125044",
      "147508-58-5",
      "3-(4-Methoxyphenyl)Indeno[1,2-C]pyridazin-5-One",
      "5H-Indeno[1,2-C]pyridazin-5-One, 3-(4-Methoxyphenyl)-",
      "AC1Q6KMT",
      "AC1L8E6Y",
      "Oprea1_523550",
      "CBDivE_007579",
      "MLS000691948",
      "Cid_379338",
      "CTK7A3357",
      "BDBM21618",
      "KS-00001QSJ",
      "MolPort-002-851-046",
      "HMS2631I03",
      "ZINC196663",
      "STK336082",
      "MMV666026",
      "Indeno[1,2-C]pyridazin-5-One, 6",
      "AKOS005075095",
      "MCULE-6151549558",
      "NSC-663884",
      "SMR000333991",
      "NCI60_021917",
    ],
  },
  {
    primary_id: "D0F3VI",
    names: [
      "CEL-1000",
      "CEL-1000C",
      "DerG, CEL-SCI",
      "Immunostimulant Peptide, CEL-SCI",
      "Peptide Vaccine (Vaxcine, Oral, Herpes Simplex Virus Infection)",
      "Peptide Vaccine (Vaxcine, Oral, Herpes Simplex Virus Infection), Infexion",
    ],
  },
  {
    primary_id: "D0F3VN",
    names: ["Sulfamoylamide Derivative 1"],
  },
  {
    primary_id: "D0F3VW",
    names: ["Allergy Immunotherapeutic Vaccine"],
  },
  {
    primary_id: "D0F3VX",
    names: ["8-(2-Methoxy-1-Methyl-Ethoxy)-Quinolin-2-Ylamine", "CHEMBL363011", "SCHEMBL5884451"],
  },
  {
    primary_id: "D0F3VZ",
    names: ["1-Cycloheptyl-3-(1-Propionylpiperidin-4-Yl)Urea", "CHEMBL1257632", "SCHEMBL2719520"],
  },
  {
    primary_id: "D0F3WF",
    names: [
      "Photo-Labeled Dapivirine Derivative",
      "DAPY",
      "Preventative HIV Vaccine, Photoimmune Biotechnology",
      "Photo-Labeled Dapivirine Derivative (HIV-1 Infection)",
      "Photo-Labeled Dapivirine Derivative (HIV-1 Infection), Photoimmune Biotechnology",
    ],
  },
  {
    primary_id: "D0F3WG",
    names: [
      "Disufenton Sodium",
      "Cerovive",
      "ARL-16556",
      "CPI-22",
      "CXY-059",
      "HPN-07",
      "NXY-059",
      "NXY-059G",
      "OKN-007",
      "S-PBN",
      "Nitrones (Cancer), Oklahoma Medical Research Foundation",
      "Nitrones, Renovis/AstraZeneca",
      "PBN Derivatives, Renovis/AstraZeneca",
      "Spin Trap Agents, Renovis/AstraZeneca",
    ],
  },
  {
    primary_id: "D0F3WR",
    names: ["2-(3-Methoxybenzylthio)-2-Phenylacetic Acid"],
  },
  {
    primary_id: "D0F3XG",
    names: ["JWH-346"],
  },
  {
    primary_id: "D0F3XQ",
    names: [
      "RS 57639",
      "AJVNZBQUOHOEQO-UHFFFAOYSA-N",
      "RS57639",
      "RS-57639",
      "[1-[3-(2,3-Dihydro-1,4-Benzodioxin-7-Yl)Propyl]piperidin-4-Yl]methyl 4-Amino-5-Chloro-2-Methoxybenzoate",
      "[3H]RS 57639",
      "AC1NSKL9",
      "GTPL236",
      "GTPL239",
      "PDSP1_001584",
      "PDSP2_001568",
      "[1-[3-(2,3-Dihydro-1,4-Benzodioxin-6-Yl)Propyl]piperidin-4-Yl]methyl",
    ],
  },
  {
    primary_id: "D0F3XS",
    names: ["AdoC(Aoc)Arg6", "CHEMBL610876"],
  },
  {
    primary_id: "D0F3XT",
    names: ["7,8-Dihydrobiopterin"],
  },
  {
    primary_id: "D0F3ZG",
    names: ["RO-5458640", "Anti-TWEAK Humanized Monoclonal Antibody (Iv, Cancer), Hoffmann-La Roche"],
  },
  {
    primary_id: "D0F470",
    names: ["FP-025"],
  },
  {
    primary_id: "D0F4AN",
    names: ["MCL-432"],
  },
  {
    primary_id: "D0F4AP",
    names: ["Glycanics"],
  },
  {
    primary_id: "D0F4AW",
    names: ["Indol-5-Ol Derivative 1"],
  },
  {
    primary_id: "D0F4BE",
    names: ["O-Arachidonoyl-N-(2-Hydroxyethyl)Hydroxylamine", "CHEMBL203380"],
  },
  {
    primary_id: "D0F4BX",
    names: ["HU210"],
  },
  {
    primary_id: "D0F4CG",
    names: ["18F-AV-1451"],
  },
  {
    primary_id: "D0F4CN",
    names: ["Beta-Lactam Antibiotics", "Beta-Lactam Antibiotics (Bacterial Infections)"],
  },
  {
    primary_id: "D0F4CT",
    names: ["MRLP-164"],
  },
  {
    primary_id: "D0F4DI",
    names: ["PMID25666693-Compound-30"],
  },
  {
    primary_id: "D0F4DW",
    names: [
      "HG-1300",
      "CRBP3 Modulator (Cancer), Human Genome Sciences",
      "CRBPIII Modulator (Cancer), Human Genome Sciences",
      "Cellular Retinol Binding Protein III Modulator (Cancer), Human Genome Sciences",
      "RBP5 Modulator (Cancer), Human Genome Sciences",
      "Splice Variants Of Cytostatin I And III Modulator (Cancer), Human Genome Sciences",
      "Retinol Binding Protein 5, Cellular Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0F4EL",
    names: ["LEUCETTAMINE A"],
  },
  {
    primary_id: "D0F4EN",
    names: ["2-Phenyl-2H-Pyrazolo[4,3-C]quinoline", "CHEMBL190111", "ZINC13648697"],
  },
  {
    primary_id: "D0F4FK",
    names: [
      "KB-R7943",
      "182004-65-5",
      "KB-R7943 Mesylate",
      "2-[2-[4-(4-NITROBENZYLOXY)PHENYL]ETHYL]ISOTHIOUREA MESYLATE",
      "KB-R7943 (Mesylate)",
      "4-((4-Nitrobenzyl)Oxy)Phenethyl Carbamimidothioate Methanesulfonate",
      "4-((4-Nitrobenzyl)Oxy)Phenethyl Carbamimidothioatemethanesulfonate",
      "2-(2-(4-(4-Nitrobenzyloxy)Phenyl)Ethyl)Isothiourea, Methane Sulfonate",
      "4-[(4-Nitrobenzyl)Oxy]phenethyl Carbamimidothioate Methanesulfonate",
      "KB-R7943  Mesylate",
      "SCHEMBL742683",
      "GTPL4232",
      "CTK0H1228",
      "DTXSID50431360",
      "MolPort-003-983-603",
      "C16H17N3O3S.CH4O3S",
      "HMS3267H09",
    ],
  },
  {
    primary_id: "D0F4FN",
    names: ["68Ga-DOTA-AE-105", "68Ga-NODAGA-AE-105"],
  },
  {
    primary_id: "D0F4FV",
    names: ["DX-2300"],
  },
  {
    primary_id: "D0F4GF",
    names: ["MK-8998"],
  },
  {
    primary_id: "D0F4GJ",
    names: ["Trifenagrel", "BW-325U"],
  },
  {
    primary_id: "D0F4GS",
    names: ["Anti-Inflammatory Polysaccharides"],
  },
  {
    primary_id: "D0F4GT",
    names: ["PMID25980951-Compound-17"],
  },
  {
    primary_id: "D0F4GU",
    names: ["ISIS 9069"],
  },
  {
    primary_id: "D0F4HB",
    names: ["Lucinactant"],
  },
  {
    primary_id: "D0F4HJ",
    names: ["5-HT 6 Antagonists"],
  },
  {
    primary_id: "D0F4HT",
    names: ["ICI-211965", "129424-08-4", "ZM-211965"],
  },
  {
    primary_id: "D0F4IC",
    names: [
      "AM-250 SCI",
      "AMAP-713",
      "Guanabenz (Spinal Cord Injury), AcurePharma",
      "SCI Program, AcurePharma/AnaMar Medical",
      "AM-250 Program (Spinal Cord Injury), AcurePharma",
      "Anti-Inflammatory/MC Receptor Modulator (Spinal Cord Injury), AnaMar",
    ],
  },
  {
    primary_id: "D0F4IF",
    names: ["N-Phenyl-Pyridine-2-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0F4IG",
    names: [
      "DS-4574",
      "DS 4574",
      "118314-35-5",
      "6-(2-Cyclohexylethyl)-(1,3,4)Thiadiazolo(3,2-A)-1,2,3-Triazolo(4,5-D)Pyrimidin-9(1H)-One",
      "(1,3,4)Thiadiazolo(3,2-A)-1,2,3-Triazolo(4,5-D)Pyrimidin-9(1H)-One, 6-(2-Cyclohexylethyl)-",
      "[1,3,4]Thiadiazolo[3,2-A]-1,2,3-Triazolo[4,5-D]pyrimidin-9(3H)-One,6-(2-Cyclohexylethyl)-",
      "AC1L4LXR",
      "ACMC-20c9t1",
      "AC1Q6E9S",
      "SCHEMBL156066",
      "CTK4B0638",
      "DTXSID80152096",
      "AKOS030534249",
      "6-(2-Cyclohexylethyl)[1,3,4]thiadiazolo[3,2-A][1,2,3]triazolo[4,5-D]pyrimidin-9(2h)-One",
      "LS-150402",
    ],
  },
  {
    primary_id: "D0F4IM",
    names: ["Adcetris"],
  },
  {
    primary_id: "D0F4JK",
    names: ["CE-224535", "CP-23575"],
  },
  {
    primary_id: "D0F4JL",
    names: ["NSC-661755"],
  },
  {
    primary_id: "D0F4KL",
    names: [
      "Thymine",
      "5-Methyluracil",
      "65-71-4",
      "2,4-Dihydroxy-5-Methylpyrimidine",
      "Thymin",
      "5-Methylpyrimidine-2,4(1H,3H)-Dione",
      "Thymine Anhydrate",
      "2,4(1H,3H)-Pyrimidinedione, 5-Methyl-",
      "5-Methyl-2,4(1H,3H)-Pyrimidinedione",
      "Thymin (Purine Base)",
      "5-Methyl-1,2,3,4-Tetrahydropyrimidine-2,4-Dione",
      "5-Methyl-1H-Pyrimidine-2,4-Dione",
      "CCRIS 5584",
      "UNII-QR26YLT7LT",
      "5-Methyl Uracil",
      "5-Methylpyrimidine-2,4-Dione",
      "4-Hydroxy-5-Methylpyrimidin-2(1H)-One",
      "CHEBI:17821",
      "AI3-25479",
      "NSC14705",
      "EINECS 200-616-1",
      "NSC 14705",
      "QR26YLT7LT",
      "CHEMBL993",
    ],
  },
  {
    primary_id: "D0F4KU",
    names: [
      "Adriamycin",
      "25316-40-9",
      "Doxorubicin HCl",
      "Adriacin",
      "ADRIAMYCIN HYDROCHLORIDE",
      "Hydroxydaunorubicin Hydrochloride",
      "Doxorubicin (Adriamycin) HCl",
      "UNII-82F2G7BL4E",
      "ADRIAMYCIN, HYDROCHLORIDE",
      "KW-125",
      "Lipodox",
      "MLS001401460",
      "82F2G7BL4E",
      "SMR000058570",
      "CPD000058570",
      "DSSTox_RID_78854",
      "DSSTox_RID_80678",
      "DSSTox_CID_10636",
      "DSSTox_GSID_30636",
      "DSSTox_GSID_45111",
      "CCRIS 740",
      "CAS-NOCAS_45111",
    ],
  },
  {
    primary_id: "D0F4LI",
    names: ["LASSBio-881"],
  },
  {
    primary_id: "D0F4LP",
    names: ["HESC-Derived CD15+ISL-1+cardiac Progenitors"],
  },
  {
    primary_id: "D0F4MF",
    names: ["2,3-Dimethoxy-4'-Amino-Trans-Stilbene", "CHEMBL1173700", "BDBM50322050"],
  },
  {
    primary_id: "D0F4MI",
    names: ["TAK-075", "Calcium-Sensing Receptor Antagonists (Osteoporosis), Takeda"],
  },
  {
    primary_id: "D0F4MK",
    names: [
      "CyMVectin",
      "Cytomegalovirus (CMV) DNA Vaccine (Glycoprotein B (GB) + Phosphoprotein 65 (PP65) + Vaxfectin), Vical",
    ],
  },
  {
    primary_id: "D0F4MN",
    names: ["PMID29473428-Compound-76"],
  },
  {
    primary_id: "D0F4NA",
    names: ["N-(4-Iodophenyl)-4-(Trifluoromethyl)Benzamide"],
  },
  {
    primary_id: "D0F4NB",
    names: ["PMID30124346-Compound-13TABLE4"],
  },
  {
    primary_id: "D0F4NH",
    names: ["VX-222"],
  },
  {
    primary_id: "D0F4NK",
    names: ["BPH-676", "BPH676", "BPH 676"],
  },
  {
    primary_id: "D0F4NN",
    names: [
      "CGTG-102",
      "Ad5/3-D24-GMCSF",
      "Adenovirus-Based Gene Therapy (Breast Cancer, Injectable Formulation), Oncos",
    ],
  },
  {
    primary_id: "D0F4NP",
    names: ["JNJ-17305600"],
  },
  {
    primary_id: "D0F4NS",
    names: [
      "Mozavaptan",
      "137975-06-5",
      "Opc-31260",
      "Opc 31260",
      "Mozavaptan [INN]",
      "CHEMBL420762",
      "OPC31260",
      "5-Dimethylamino-1-(4-(2-Methylbenzoylamino)Benzoyl)-2,3,4,5-Tetrahydro-1H-Benzazepine",
      "N-(4-(5-(Dimethylamino)-2,3,4,5-Tetrahydro-1H-Benzo[b]azepine-1-Carbonyl)Phenyl)-2-Methylbenzamide",
      "N-(4-((5-(Dimethylamino)-2,3,4,5-Tetrahydro-1H-1-Benzazepin-1-Yl)Carbonyl)Phenyl)-2-Methylbenzamide",
      "N-(4-(((5RS)-5-(Dimethylamino)-2,3,4,5-Tetrahydro-1H-1-Benzazepin-1-Yl)Carbonyl)Phenyl)-2-Methylbenzamide",
      "C27H29N3O2",
      "OPC-31260",
    ],
  },
  {
    primary_id: "D0F4NW",
    names: ["RO-5428029"],
  },
  {
    primary_id: "D0F4QJ",
    names: ["CR-5790"],
  },
  {
    primary_id: "D0F4QO",
    names: ["THE-320"],
  },
  {
    primary_id: "D0F4QV",
    names: ["GSK2696273"],
  },
  {
    primary_id: "D0F4QW",
    names: ["Bimatoprost Sustained Release"],
  },
  {
    primary_id: "D0F4QX",
    names: ["Thenoyltrifluoroacetone"],
  },
  {
    primary_id: "D0F4RM",
    names: ["MD-2009"],
  },
  {
    primary_id: "D0F4RX",
    names: ["OPC-14117"],
  },
  {
    primary_id: "D0F4SF",
    names: ["ISIS 150453"],
  },
  {
    primary_id: "D0F4SI",
    names: ["N-9xxx"],
  },
  {
    primary_id: "D0F4SN",
    names: ["KUR-111"],
  },
  {
    primary_id: "D0F4SY",
    names: ["Pridopidine"],
  },
  {
    primary_id: "D0F4TV",
    names: ["AWD-12-281"],
  },
  {
    primary_id: "D0F4UI",
    names: ["CPD-VO"],
  },
  {
    primary_id: "D0F4UM",
    names: ["MG-4101"],
  },
  {
    primary_id: "D0F4UT",
    names: ["2-(2-(Biphenyl-4-Yl)Ethylsulfinyl)Acetic Acid", "CHEMBL572947"],
  },
  {
    primary_id: "D0F4UV",
    names: ["ISIS 20586"],
  },
  {
    primary_id: "D0F4VD",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 7"],
  },
  {
    primary_id: "D0F4VK",
    names: ["TB-101"],
  },
  {
    primary_id: "D0F4VW",
    names: [
      "SRX-246",
      "AVN-518",
      "SRX-246-HCI",
      "Vasopressin V1a Antagonist (Capsule/Oral, Stress-Related Affective Illness/Anxiety), Azevan Pharmaceutoicals",
    ],
  },
  {
    primary_id: "D0F4WJ",
    names: ["6-(4-Decyloxy-Phenyl)-1,1,1-Trifluoro-Hexan-2-One", "CHEMBL513627"],
  },
  {
    primary_id: "D0F4WQ",
    names: ["1-(4-Nitro-2-Phenylsulfanylbenzyl)-1H-Imidazole", "CHEMBL1085936"],
  },
  {
    primary_id: "D0F4WS",
    names: ["BM-13.1180"],
  },
  {
    primary_id: "D0F4XB",
    names: [
      "2-Amino-4,6-Di-Furan-2-Yl-Nicotinonitrile",
      "CHEMBL506250",
      "AC1MDUVT",
      "SCHEMBL6252357",
      "MolPort-002-903-619",
      "ZINC20432232",
      "BDBM50262441",
      "MCULE-1490383880",
      "SR-01000003798",
      "SR-01000003798-1",
    ],
  },
  {
    primary_id: "D0F4YC",
    names: ["H-Dmt-Tic-OH"],
  },
  {
    primary_id: "D0F4YX",
    names: ["PMID25666693-Compound-15"],
  },
  {
    primary_id: "D0F4ZN",
    names: ["PAMAQUESIDE", "CP-148623", "Pamaqueside < Prop INN", "11-Ketotigogenyl Beta-O-Cellobioside"],
  },
  {
    primary_id: "D0F4ZW",
    names: ["SODIUM PERFLUOROHEXANESULFONAMIDE", "CHEMBL1076889", "Sodium Perfluorohexane Sulfonamide"],
  },
  {
    primary_id: "D0F4ZY",
    names: ["KW-6002"],
  },
  {
    primary_id: "D0F5AB",
    names: ["Rad51"],
  },
  {
    primary_id: "D0F5AC",
    names: ["4-Imidazol-1-Ylmethylxanthen-9-One", "CHEMBL1083971"],
  },
  {
    primary_id: "D0F5AJ",
    names: ["NORARISTEROMYCIN"],
  },
  {
    primary_id: "D0F5AQ",
    names: ["5-(2-Phenethylpiperazin-1-Yl)-1H-Indazole", "CHEMBL1170373", "SCHEMBL2944873"],
  },
  {
    primary_id: "D0F5AR",
    names: ["Cibinetide"],
  },
  {
    primary_id: "D0F5BD",
    names: ["2-Morpholinobenzo[h]quinolin-4(1H)-One", "CHEMBL198675"],
  },
  {
    primary_id: "D0F5BL",
    names: ["EXO-230"],
  },
  {
    primary_id: "D0F5BY",
    names: ["N-(4-Amino-6-Butoxy-5-Cyanopyridin-2-Yl)Acetamide"],
  },
  {
    primary_id: "D0F5CO",
    names: ["Enloplatin", "CL-287110"],
  },
  {
    primary_id: "D0F5DK",
    names: [
      "TRAIL-R Atrimer",
      "Atrimer (Cancer), Anaphore",
      "Death Receptor Agonist Atrimers (Cancer), Anaphore",
      "TRAIL-R Atrimer (Cancer)",
      "TRAIL-R Atrimer(Cancer), Anaphore",
    ],
  },
  {
    primary_id: "D0F5DO",
    names: [
      "Arginine",
      "DL-Arginine Hydrochloride",
      "32042-43-6",
      "2-Amino-5-Guanidinopentanoic Acid Hydrochloride",
      "Argininhydrochlorid",
      "DL-Arginine HCl",
      "220144-84-3",
      "H-DL-Arg-OH.xHCl",
      "DL-(+/-)-Arginine Hydrochloride",
      "1-Hydrochloride Arginine",
      "NSC7914",
      "Arginine Monochloride",
      "2-Amino-5-Guanidinovaleric Acid Monohydrochloride",
      "WLN: MUYZM3YZVQ &amp",
      "DL-Arginine Hydrochloride, 98%, Anhydrous",
      "Arginine Monohydrochloride, L-(+)-",
      "ARGININE, (L)",
      "L(+)-Arginine Hydrochloride",
      "D-Arginine, Hydrochloride (1:1)",
      "EINECS 250-903-0",
      "EINECS 239-674-8",
    ],
  },
  {
    primary_id: "D0F5EI",
    names: [
      "4-(5-Bromo-1H-Indol-3-Yl)Pyrimidin-2-Amine",
      "Meridianin C",
      "213473-00-8",
      "2-Pyrimidinamine, 4-(5-Bromo-1H-Indol-3-Yl)-",
      "CHEMBL44541",
      "SCHEMBL1612228",
      "CTK0J7680",
      "BDBM10840",
      "DTXSID50434275",
      "PKQJCYXKRNGUKQ-UHFFFAOYSA-N",
      "AKOS027469387",
      "AS-49872",
    ],
  },
  {
    primary_id: "D0F5EJ",
    names: [
      "LIPOCRINE",
      "CHEMBL194823",
      "Rac-Lipocrine",
      "Tacrine-Lipoic Acid Hybrid 7",
      "SCHEMBL14341601",
      "BDBM10512",
    ],
  },
  {
    primary_id: "D0F5GF",
    names: ["AE-08"],
  },
  {
    primary_id: "D0F5HB",
    names: ["Sotrastaurin Acetate"],
  },
  {
    primary_id: "D0F5HC",
    names: ["PMID25656651-Compound-40"],
  },
  {
    primary_id: "D0F5IS",
    names: ["NGN-9079", "Alzheimer's Disease Therapy, NeuroGeneration"],
  },
  {
    primary_id: "D0F5IY",
    names: ["LIRIMILAST"],
  },
  {
    primary_id: "D0F5JG",
    names: ["AK-1", "Actokine-1"],
  },
  {
    primary_id: "D0F5JH",
    names: ["CS-038", "Chiglitazar", "CS-00098", "PPAR Alpha/Gamma Agonist (Diabetes), Chipscreen Biosciences"],
  },
  {
    primary_id: "D0F5JR",
    names: ["ONO-1603"],
  },
  {
    primary_id: "D0F5JY",
    names: [
      "BMS-564929",
      "627530-84-1",
      "UNII-9BLW27W4X7",
      "BMS 564929",
      "9BLW27W4X7",
      "C14H12ClN3O3",
      "2-Chloro-4-[(7r,7as)-7-Hydroxy-1,3-Dioxotetrahydro-1h-Pyrrolo[1,2-C]imidazol-2(3h)-Yl]-3-Methylbenzonitrile",
      "2-Chloro-3-Methyl-4-[(7R,7AS)-Tetrahydro-7-Hydroxy-1,3-Dioxo-1H-Pyrrolo[1,2-C]imidazol-2(3H)-Yl]benzonitrile",
      "KEJORAMIZFOODM-PWSUYJOCSA-N",
      "2nw4",
      "SCHEMBL2967577",
      "DTXSID50432357",
      "ZINC3938678",
      "3500AH",
      "AKOS027288930",
      "CS-1381",
      "DB07286",
      "BMS-564,929",
      "NCGC00378909-01",
      "HY-12111",
      "8NH",
      "KB-75607",
      "BMS 564929;BMS564929",
      "W-5594",
      "S900006120",
    ],
  },
  {
    primary_id: "D0F5JZ",
    names: ["PLX-4720"],
  },
  {
    primary_id: "D0F5KN",
    names: ["99mTc-MIP-1405"],
  },
  {
    primary_id: "D0F5KY",
    names: [
      "CX4",
      "AC1MBGFP",
      "Maybridge4_002933",
      "CX-4",
      "GTPL7663",
      "CCG-196",
      "MolPort-002-912-245",
      "HMS1529F07",
      "MCULE-4373871510",
      "NCGC00176395-01",
      "BRD-A62325689-001-01-9",
      "1-(2-Chloro-6-Methylphenyl)-3-(1,2-Diphenylethyl)Thiourea",
    ],
  },
  {
    primary_id: "D0F5LB",
    names: [
      "Carbon Monoxide",
      "Covox",
      "IK-2001",
      "Carbon Monoxide (Inhaled, Organ Transplan/Cystic Fibrosis/Restenosis/Liver Failure)",
      "Carbon Monoxide (Inhaled, Organ Transplan/Cystic Fibrosis/Restenosis/Liver Failure), Ikaria",
    ],
  },
  {
    primary_id: "D0F5LH",
    names: [
      "Oxybutynine",
      "5633-20-5",
      "Ditropan",
      "Oxytrol",
      "Oxibutyninum",
      "Oxybutyninum",
      "Oxibutinina",
      "Oxybutyninum [INN-Latin]",
      "Oxybutynine [INN-French]",
      "Oxybutinin",
      "Cystrin",
      "Oxibutinina [INN-Spanish]",
      "Oxybutynin Base",
      "Transdermal Patch",
      "Lyrinel XL",
      "Oxybutynin [USAN:INN:BAN]",
      "4-Diethylamino-2-Butinyl Alpha-Cyclohexylmandelat",
      "CCRIS 1923",
      "Oxybutynin Topical Gel",
      "Ditropan Xl",
      "Oxybutynin (Ditropan)",
      "HSDB 3270",
      "4-Diethylamino-2-Butynyl Alpha-Phenylcyclohexaneglycolate",
      "CHEMBL1231",
      "Cyclohexaneglycolic Acid, Alpha-Phenyl-, 4-(Diethylamino)-2-B",
    ],
  },
  {
    primary_id: "D0F5MA",
    names: ["2,7-Bis(4-Chlorobutyramido)Anthraquinone", "SCHEMBL4293006"],
  },
  {
    primary_id: "D0F5MF",
    names: ["PMID29649907-Compound-33"],
  },
  {
    primary_id: "D0F5MT",
    names: ["Fosamprenavir"],
  },
  {
    primary_id: "D0F5MZ",
    names: ["4-(2'-(Trifluoromethyl)Biphenyl-3-Yl)Oxazole", "CHEMBL1224519", "BDBM50325801"],
  },
  {
    primary_id: "D0F5NB",
    names: [
      "CD-581",
      "1-(Eicosa-5,8,11-Triynoyl)-4-(2-Hydroxyethyl)Piperazine",
      "4-(1-Oxo-5,8,11-Eicosatriynyl)-1-Piprazineethanol",
    ],
  },
  {
    primary_id: "D0F5ND",
    names: ["PMID25435179-Compound-WO2013059594(IV)"],
  },
  {
    primary_id: "D0F5NG",
    names: ["KUR-114"],
  },
  {
    primary_id: "D0F5NJ",
    names: [
      "METHYLENEDIOXYMETHAMPHETAMINE",
      "3,4-Methylenedioxymethamphetamine",
      "MDMA",
      "Ecstasy",
      "Methylenedioxymethamphetamine",
      "42542-10-9",
      "N-METHYL-3,4-METHYLENEDIOXYAMPHETAMINE",
      "DL-(3,4-Methylenedioxy)Methamphetamine",
      "(RS)-3,4-(Methylenedioxy)Methamphetamine",
      "Midomafetamine",
      "MDMA (Unspecified)",
      "N,alpha-Dimethyl-1,3-Benzodioxole-5-Ethanamine",
      "HSDB 6929",
      "1-(1,3-Benzodioxol-5-Yl)-N-Methylpropan-2-Amine",
      "CHEBI:1391",
      "DEA No 7405",
      "(+-)-(3,4-Methylenedioxy)Methamphetamine",
      "(+-)-N-Methyl-3,4-(Methylenedioxy)Amphetamine",
      "1,3-Benzodioxole-5-Ethanamine, N,alpha-Dimethyl-",
    ],
  },
  {
    primary_id: "D0F5NQ",
    names: ["PMID25666693-Compound-65"],
  },
  {
    primary_id: "D0F5OR",
    names: ["Spiramycin"],
  },
  {
    primary_id: "D0F5QL",
    names: ["GM-109"],
  },
  {
    primary_id: "D0F5SD",
    names: ["ISIS 156475"],
  },
  {
    primary_id: "D0F5SO",
    names: [
      "NBI-59159",
      "CHEMBL1628570",
      "GTPL4626",
      "BDBM50410451",
      "(2S)-2-Amino-3-[(9H-Fluoren-2-Yl)Carbamoyl]propanoic Acid",
    ],
  },
  {
    primary_id: "D0F5TA",
    names: ["Ethyl-Indol-1-Yl-Pyridin-4-Yl-Amine", "CHEMBL154541", "SCHEMBL7613957", "BDBM50048600"],
  },
  {
    primary_id: "D0F5TD",
    names: ["PMID27841036-Compound-37"],
  },
  {
    primary_id: "D0F5TM",
    names: ["OPI-1100"],
  },
  {
    primary_id: "D0F5TR",
    names: ["PMID25553724-Compound-US2012832440110"],
  },
  {
    primary_id: "D0F5TS",
    names: ["KM-3900"],
  },
  {
    primary_id: "D0F5TV",
    names: ["TAD"],
  },
  {
    primary_id: "D0F5TZ",
    names: ["PMID26155854C57"],
  },
  {
    primary_id: "D0F5UN",
    names: ["SLV320"],
  },
  {
    primary_id: "D0F5UO",
    names: ["KMS-88009"],
  },
  {
    primary_id: "D0F5VB",
    names: ["PD-123319"],
  },
  {
    primary_id: "D0F5VD",
    names: ["Ezatiostat"],
  },
  {
    primary_id: "D0F5VX",
    names: ["MNX-100"],
  },
  {
    primary_id: "D0F5VY",
    names: [
      "NSC-23180",
      "2-Nitrophenanthrene-9,10-Dione",
      "2-Nitro-9,10-Phenanthrenedione",
      "604-95-5",
      "9,10-Phenanthrenedione, 2-Nitro-",
      "CHEMBL433282",
      "NSC23180",
      "9, 2-Nitro-",
      "2-Nitro-9,10-Dihydrophenanthrene-9,10-Dione",
      "NSC5425",
      "2-Nitro-9,10-Phenanthrenequinone",
      "2-Nitrophenanthraquinone",
      "AC1Q1ZPU",
      "AC1Q1Y4O",
      "SCHEMBL2599395",
      "AC1L3T23",
      "BDBM22858",
      "CTK8D5611",
      "DTXSID90209161",
      "MolPort-000-824-665",
      "1,2-Dione-Based Compound, 15",
      "ZINC1686937",
      "NSC-5425",
      "STK246885",
      "AKOS001483088",
      "MCULE-2114795800",
      "BP-22843",
      "SR-01000388930",
    ],
  },
  {
    primary_id: "D0F5WP",
    names: ["1-(2-Aminoethyl)-1H-Indazol-6-Ol"],
  },
  {
    primary_id: "D0F5WV",
    names: [
      "[3H]JB-93182",
      "CHEMBL14557",
      "GTPL908",
      "SCHEMBL7096084",
      "BDBM50213845",
      "JB-93182",
      "5-[[(2S)-2-[[6-(1-Adamantylmethylcarbamoyl)1H-Indole-5-Carbonyl]amino]-3-Phenylpropanoyl]amino]benzene-1,3-Dicarboxylic Acid",
    ],
  },
  {
    primary_id: "D0F5WX",
    names: ["GSK768974"],
  },
  {
    primary_id: "D0F5WY",
    names: ["SCH-725737"],
  },
  {
    primary_id: "D0F5XQ",
    names: ["1-Adamantan-1-Yl-3-(4-Hydroxybutyl)Urea", "CHEMBL397197"],
  },
  {
    primary_id: "D0F5YL",
    names: ["ISIS 103736"],
  },
  {
    primary_id: "D0F5YU",
    names: [
      "4-[5-Chloro-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 8b",
      "SCHEMBL4231649",
      "CHEMBL175799",
      "BDBM8619",
      "AC1O7061",
      "ZINC100056323",
      "4-[(Z)-(5-Chloro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0F5ZM",
    names: ["Imidazole Salicylate"],
  },
  {
    primary_id: "D0F5ZW",
    names: [
      "CGP61594",
      "4-[2-(4-Azidophenyl)Acetamido]-5,7-Dichloro-1,2,3,4-Tetrahydroquinoline-2-Carboxylic Acid",
      "CGP 61594",
      "[3H]CGP61594",
      "CGP-61594",
      "[3H]CGP 61594",
      "GTPL5474",
    ],
  },
  {
    primary_id: "D0F5ZZ",
    names: ["Sharon-2000"],
  },
  {
    primary_id: "D0F6AB",
    names: ["AR-C-89855", "AR-C68164AA", "AR-C68475AA", "AR-C69457AA", "AR-C89855AA"],
  },
  {
    primary_id: "D0F6AI",
    names: ["TINGENONE"],
  },
  {
    primary_id: "D0F6AJ",
    names: ["Hsp 6/11"],
  },
  {
    primary_id: "D0F6CD",
    names: ["SEROTONIN"],
  },
  {
    primary_id: "D0F6CI",
    names: ["R-1437"],
  },
  {
    primary_id: "D0F6CT",
    names: ["GI 181771"],
  },
  {
    primary_id: "D0F6DF",
    names: [
      "AB-3D3",
      "AB-0447a",
      "KAAG1-Targeting MAb (Ovarian Cancer), Alethia",
      "KAAG1-Targeting Monoclonal Antibody (Ovarian Cancer), Alethia",
      "Kidney Associated Antigen 1-Targeting MAb (Ovarian Cancer), Alethia",
      "Kidney Associated Antigen 1-Targeting Monoclonal Antibody (Ovarian Cancer), Alethia",
      "AB-0447-Targeting Monoclonal Antibodies (Cancer), Alethia",
    ],
  },
  {
    primary_id: "D0F6EJ",
    names: ["PMID21927650C18"],
  },
  {
    primary_id: "D0F6EO",
    names: [
      "Amoxicillin",
      "AMPC",
      "Actimoxi",
      "Amoclen",
      "Amolin",
      "Amopen",
      "Amopenixin",
      "Amoxi",
      "Amoxibiotic",
      "Amoxicaps",
      "Amoxicilina",
      "Amoxicillanyl",
      "Amoxicilline",
      "Amoxicillinum",
      "Amoxiden",
      "Amoxil",
      "Amoxivet",
      "Amoxycillin",
      "Anemolin",
      "Aspenil",
      "Biomox",
      "Bristamox",
      "Cemoxin",
      "Clamoxyl",
      "Delacillin",
      "DisperMox",
      "Efpenix",
      "Flemoxin",
      "Hiconcil",
      "Histocillin",
      "Hydroxyampicillin",
      "Ibiamox",
      "Imacillin",
      "Lamoxy",
      "Larotid",
      "Moxacin",
      "Moxal",
      "Moxatag",
      "Ospamox",
      "Pamoxicillin",
      "Piramox",
      "Polymox",
      "Robamox",
      "Sumox",
      "Tolodina",
      "Trimox",
      "Unicillin",
      "Utimox",
      "Vetramox",
      "Wymox",
      "AMOXICILLIN CRYSTALLINE",
      "AMOXICILLIN PEDIATRIC",
      "Amoxicillin Anhydrous",
      "Amoxicilline [INN]",
      "Amoxycillin Trihydrate",
      "Metafarma Capsules",
      "Metifarma Capsules",
      "Sawamox PM",
      "BLP 1410",
      "AMK (TN)",
      "Actimoxi (TN)",
      "Alphamox (TN)",
      "Amoksibos (TN)",
      "Amoksiklav (TN)",
      "Amoxi-Mast",
      "Amoxibiotic (TN)",
      "Amoxicilina (TN)",
      "Amoxicilina [INN-Spanish]",
      "Amoxicillin (INN)",
      "Amoxicillin (TN)",
      "Amoxicillin (Anhydrous)",
      "Amoxicilline [INN-French]",
      "Amoxicillinum [INN-Latin]",
      "Amoxiclav (TN)",
      "Amoxidal (TN)",
      "Amoxil (TN)",
      "Amoxin (TN)",
      "Apo-Amoxi",
      "Augmentin (TN)",
      "BL-P 1410",
      "BRL-2333",
      "Bactox (TN)",
      "Betalaktam (TN)",
      "Cilamox (TN)",
      "Clamoxyl (TN)",
      "Curam (TN)",
      "D-Amoxicillin",
      "Dedoxil (TN)",
      "Dispermox (TN)",
      "Duomox (TN)",
      "Enhancin (TN)",
      "Geramox (TN)",
      "Gimalxina (TN)",
      "Hiconcil (TN)",
      "Isimoxin (TN)",
      "Klavox (TN)",
      "Lamoxy (TN)",
      "Moxatag (TN)",
      "Moxilen (TN)",
      "Moxypen (TN)",
      "Moxyvit (TN)",
      "Nobactam (TN)",
      "Novamoxin (TN)",
      "Ospamox (TN)",
      "P-Hydroxyampicillin",
      "Pamoxicillin (TN)",
      "Panamox (TN)",
      "Panklav (TN)",
      "Polymox (TN)",
      "Ro 10-8756",
      "Samthongcillin (TN)",
      "Sandoz (TN)",
      "Senox (TN)",
      "Sinacilin (TN)",
      "Tolodina (TN)",
      "Trimox (TN)",
      "Wymox (TN)",
      "Yucla (TN)",
      "Zerrsox (TN)",
      "Zimox (TN)",
      "Apo-Amoxi (TN)",
      "Alpha-Amino-P-Hydroxybenzylpenicillin",
      "D-2-Amino-2-(4-Hydroxyphenyl)Acetamidopenicillanic Acid",
      "D-(-)-Alpha-Amino-P-Hydroxybenzylpenicillin",
      "(-)-6-(2-Amino-2-(P-Hydroxyphenyl)Acetamido)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo-(3.2.0)Heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-[[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetyl]amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-{[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetyl]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid, 6-(2-Amino-2-(P-Hydroxyphenyl)Acetamido)-3,3-Dimethyl-7-Oxo-, D-(8CI)",
      "6-(D-(-)-Alpha-Amino-P-Hydroxyphenylacetamido)Penicillanic Acid",
      "6-(D-(-)-P-Hydroxy-Alpha-Aminobenzyl)Penicillin",
      "6-(P-Hydroxy-Alpha-Aminophenylacetamido)Penicillanic Acid",
      "6beta-[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetamido]-2,2-Dimethylpenam-3alpha-Carbonyl",
      "6beta-[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetamido]-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0F6EZ",
    names: ["(4-Guanidino-Benzyl)-Carbamic Acid Benzyl Ester", "CHEMBL391969"],
  },
  {
    primary_id: "D0F6FZ",
    names: [
      "N-(4-M-Tolylamino-Quinazolin-7-Yl)-Acrylamide",
      "CHEMBL297968",
      "SCHEMBL2994207",
      "FAGJCAQBJKSOOS-UHFFFAOYSA-N",
      "BDBM50077245",
      "N-[4-(3-Methylanilino)Quinazoline-7-Yl]acrylamide",
    ],
  },
  {
    primary_id: "D0F6GB",
    names: ["2-(2-Chlorophenoxy)-3-(Piperidin-4-Yl)Pyridine", "CHEMBL609904", "SCHEMBL13769617"],
  },
  {
    primary_id: "D0F6GP",
    names: ["5-(4-Methylpiperazin-1-Yl)-3-Tosyl-1H-Indazole", "CHEMBL1276926"],
  },
  {
    primary_id: "D0F6GR",
    names: [
      "Clentiazem",
      "CLENTIAZEM",
      "Clentiazem [INN]",
      "Clentiazemum [INN-Latin]",
      "UNII-40DK034DRC",
      "40DK034DRC",
      "96125-53-0",
      "C22H25ClN2O4S",
      "(+)-Cis-2-(4-Methoxyphenyl)-3-Acetoxy-5-(2-(N,N-Dimethylamino)Ethyl)-8-Chloro-2,3-Dihydro-1,5-Benzothiazepin-4(5H)-One",
      "Clentiazemum",
      "1,5-Benzothiazepin-4(5H)-One, 3-(Acetyloxy)-8-Chloro-5-(2-(Dimethylamino)Ethyl)-2,3-Dihydro-2-(4-Methoxyphenyl)-, (25-Cis)-",
      "1,5-Benzothiazepin-4(5H)-One, 3-(Acetyloxy)-8-Chloro-5-(2-(Dimethylamino)Ethyl)-2,3-Dihydro-2-(4-Methoxyphenyl)-, (2S,3S)-",
      "AC1L1M5S",
      "SCHEMBL33909",
    ],
  },
  {
    primary_id: "D0F6GU",
    names: ["PF-2400013"],
  },
  {
    primary_id: "D0F6GV",
    names: ["Perospirone"],
  },
  {
    primary_id: "D0F6GX",
    names: [
      "Ac-Tyr-D-Phe-Arg-2-Nal-NHCH3",
      "CHEMBL202699",
      "BDBM50179459",
      "(S)-2-{(R)-2-[(S)-2-Acetylamino-3-(4-Hydroxy-Phenyl)-Propionylamino]-3-Phenyl-Propionylamino}-5-Guanidino-Pentanoic Acid ((S)-1-Methylcarbamoyl-2-Naphthalen-2-Yl-Ethyl)-Amide",
    ],
  },
  {
    primary_id: "D0F6HW",
    names: ["IPH-4201"],
  },
  {
    primary_id: "D0F6IL",
    names: ["KNW-001"],
  },
  {
    primary_id: "D0F6JC",
    names: [
      "GSK1278863",
      "Daprodustat",
      "960539-70-2",
      "UNII-JVR38ZM64B",
      "GSK-1278863",
      "JVR38ZM64B",
      "N-((1,3-Dicyclohexylhexahydro-2,4,6-Trioxopyrimidin-5-Yl)Carbonyl)Glycine",
      "Daprodustat [USAN:INN]",
      "GSK 1278863",
      "Daprodustat(GSK1278863)",
      "Daprodustat (JAN/USAN/INN)",
      "GTPL8455",
      "Daprodustat (GSK1278863)",
      "CHEMBL3544988",
      "KS-00000M8Z",
      "EX-A1121",
      "BCP16766",
      "S8171",
      "AKOS027439964",
      "Glycine, N-((1,3-Dicyclohexylhexahydro-2,4,6-Trioxo-5-Pyrimidinyl)Carbonyl)-",
      "ZINC231226004",
      "CS-5453",
      "SB19761",
      "DB11682",
      "HY-17608",
      "J3.560.573H",
      "D10874",
    ],
  },
  {
    primary_id: "D0F6JI",
    names: ["AR-D-111421"],
  },
  {
    primary_id: "D0F6KM",
    names: ["ISIS-APOCIII"],
  },
  {
    primary_id: "D0F6NZ",
    names: ["PMID22902653C30"],
  },
  {
    primary_id: "D0F6OF",
    names: ["NMS-P626"],
  },
  {
    primary_id: "D0F6OK",
    names: ["N-Methylleucine"],
  },
  {
    primary_id: "D0F6QN",
    names: ["SLP-0901"],
  },
  {
    primary_id: "D0F6RH",
    names: ["2-(Heptylthiomethyl)-5-Hydroxy-4H-Pyran-4-One", "CHEMBL1269401"],
  },
  {
    primary_id: "D0F6SS",
    names: ["Ganglioside Vaccine"],
  },
  {
    primary_id: "D0F6SY",
    names: ["StarGen"],
  },
  {
    primary_id: "D0F6TD",
    names: ["SUN N4057"],
  },
  {
    primary_id: "D0F6TM",
    names: ["(6-Hydroxy-2-Oxo-2H-Quinolin-1-Yl)-Acetic Acid", "CHEMBL291659", "BDBM50022252"],
  },
  {
    primary_id: "D0F6TN",
    names: ["Mannoside Derivative 6"],
  },
  {
    primary_id: "D0F6TS",
    names: ["Endothelin-2"],
  },
  {
    primary_id: "D0F6TV",
    names: ["Timcodar Dimesilate"],
  },
  {
    primary_id: "D0F6VD",
    names: ["Lactam Derivative 1"],
  },
  {
    primary_id: "D0F6VI",
    names: ["N-(3-(2-Hydroxy-5-Methoxyphenyl)Propyl)Acetamide", "CHEMBL1092062"],
  },
  {
    primary_id: "D0F6VM",
    names: ["3-(2-N-Pyrrolyl-Propionamino)-Rutaecarpine", "CHEMBL1094633"],
  },
  {
    primary_id: "D0F6WV",
    names: ["PD-157695"],
  },
  {
    primary_id: "D0F6WW",
    names: ["LU-AA33810"],
  },
  {
    primary_id: "D0F6XB",
    names: ["Fusion Proteins"],
  },
  {
    primary_id: "D0F6XR",
    names: [
      "DV-7028",
      "133364-63-3",
      "DV 7028",
      "AC1O5RHW",
      "SCHEMBL9378376",
      "3-(2-(4-(4-Fluorobenzoyl)Piperidin-1-Yl)Ethyl)-6,7,8,9-Tetrahydro-2H-Pyrido(1,2-A)-1,3,5-Triazine-2,4(3H)Dione Maleate",
      "2H-Pyrido(1,2-A)-1,3,5-Triazine-2,4(3H)-Dione, 3-(2-(4-(4-Fluorobenzoyl)-1-Piperidinyl)Ethyl)-6,7,8,9-Tetrahydro-,(Z)-2-Butenedioate (1:1)",
    ],
  },
  {
    primary_id: "D0F6YB",
    names: ["3-Cyano-5-Fluoro-N-Phenylbenzamide", "CHEMBL1209392"],
  },
  {
    primary_id: "D0F6YK",
    names: ["Implitapide"],
  },
  {
    primary_id: "D0F6YM",
    names: ["VBP15"],
  },
  {
    primary_id: "D0F6YW",
    names: ["Sagopilone"],
  },
  {
    primary_id: "D0F6ZC",
    names: ["8-Methyl-2-Morpholin-4-Yl-Chromen-4-One", "CHEMBL179635", "SCHEMBL3539026", "BDBM50159667"],
  },
  {
    primary_id: "D0F6ZH",
    names: [
      "3-(2-Naphthyl)Phenol",
      "2-(3-Hydroxyphenyl)Naphthalene",
      "3-(NAPHTHALEN-2-YL)PHENOL",
      "33104-32-4",
      "CHEMBL258687",
      "3-Naphthalene-2-Ylphenol",
      "3-Naphthalen-2-Yl-Phenol",
      "SCHEMBL3015094",
      "DTXSID90487649",
      "FQDQJXSIUCYJDC-UHFFFAOYSA-N",
      "BDBM50373033",
    ],
  },
  {
    primary_id: "D0F6ZS",
    names: ["Ro-25-6833"],
  },
  {
    primary_id: "D0F6ZT",
    names: [
      "GL-0810",
      "912762-45-9",
      "Tert-Butyl (2-(Hydroxyimino)-2-Phenylethyl)Carbamate",
      "Tert-Butyl N-[(2Z)-2-(Hydroxyimino)-2-Phenylethyl]carbamate",
      "(2-HYDROXYIMINO-2-PHENYL-ETHYL)-CARBAMIC ACID TERT-BUTYL ESTER",
      "MolPort-002-506-758",
    ],
  },
  {
    primary_id: "D0F7AJ",
    names: [
      "Ceftolozane/Tazobactam",
      "CXA-101/Tazobactam",
      "CXA-201",
      "Tazobactam/Ceftolozane",
      "Tazobactam/CXA-101",
      "Zerbaxa",
    ],
  },
  {
    primary_id: "D0F7AP",
    names: ["ISIS 122974"],
  },
  {
    primary_id: "D0F7AT",
    names: ["Cryptenamine Acetates"],
  },
  {
    primary_id: "D0F7BE",
    names: ["PNU-107859"],
  },
  {
    primary_id: "D0F7BO",
    names: [
      "Alpha Melanotropin",
      "DK-0210",
      "DasKloster 0210-01",
      "Alpha Melanotropin (Inhaled, Chronic Beryllium Disease)",
      "Alpha Melanotropin (Inhaled, Chronic Beryllium Disease), MondoBIOTECH",
      "Anti-Inflammatory Agent (Chronic Beryllium Disease), MondoBIOTECH",
    ],
  },
  {
    primary_id: "D0F7BV",
    names: ["1-(1H-Indol-4-Yloxy)-3-Phenethylamino-Propan-2-Ol", "CHEMBL37989"],
  },
  {
    primary_id: "D0F7CR",
    names: ["BMS-986178"],
  },
  {
    primary_id: "D0F7CS",
    names: ["Rotenone"],
  },
  {
    primary_id: "D0F7DM",
    names: ["9-DEAZAINOSINE-2',3'-O-ETHYLIDENEPHOSPHONATE"],
  },
  {
    primary_id: "D0F7DQ",
    names: ["Triphenylacetic Acid Methyl Ester"],
  },
  {
    primary_id: "D0F7DS",
    names: ["PMID26161698-Compound-18"],
  },
  {
    primary_id: "D0F7EK",
    names: ["MX-6162", "Differentiation-Involution Inducing Agents, Maxia"],
  },
  {
    primary_id: "D0F7FE",
    names: ["SY-300"],
  },
  {
    primary_id: "D0F7FM",
    names: ["ASP2409"],
  },
  {
    primary_id: "D0F7FX",
    names: ["AZD-9684"],
  },
  {
    primary_id: "D0F7FY",
    names: ["Antimicrobial Oral Vaccine"],
  },
  {
    primary_id: "D0F7GX",
    names: [
      "ACE-011",
      "116496-76-5",
      "4-Acetamido-3-Ethoxynitrobenzene",
      "N-(2-Ethoxy-4-Nitrophenyl)Acetamide",
      "Acetamide,N-(2-Ethoxy-4-Nitrophenyl)-",
      "2'-ETHOXY-4'-NITROACETANILIDE",
      "CDS1_004703",
      "AC1MEGDI",
      "ACMC-20c3rb",
      "CBMicro_030367",
      "2-Ethoxy-4-Nitroacetanilide",
      "Oprea1_342150",
      "DivK1c_005743",
      "ACE011",
      "SCHEMBL2204761",
      "KS-00002AMW",
      "CTK4A9819",
      "DTXSID60387001",
      "XZWVZJDHIDYKPB-UHFFFAOYSA-N",
      "MolPort-000-279-575",
      "ZX-AT005552",
      "ZINC5061120",
      "OR9529",
      "AKOS001044796",
      "PS-6223",
      "VZ36467",
      "MCULE-3006561606",
      "AB07920",
      "AS02565",
      "KB-71958",
      "CC-31292",
      "AJ-53053",
      "Acetamide, N-(2-Ethoxy-4-Nitrop",
    ],
  },
  {
    primary_id: "D0F7IG",
    names: ["PH10 Nasal Spray"],
  },
  {
    primary_id: "D0F7IW",
    names: ["1,1,1-Trifluoro-5-(4-Octylphenoxy)Pentan-2-One"],
  },
  {
    primary_id: "D0F7JK",
    names: [
      "N-Methyl,N-Phenyl-1H-Indole-2-Carboxamide",
      "CHEMBL464597",
      "N-Methyl-N-Phenyl-1H-Indole-2-Carboxamide",
      "AC1LGHJJ",
      "MolPort-003-811-419",
      "ZINC338381",
      "BDBM50273486",
      "AKOS008968640",
    ],
  },
  {
    primary_id: "D0F7KC",
    names: [
      "8-Acetyl-7-Methoxy-2H-Chromen-2-One",
      "8-Acetyl-7-Methoxycoumarin",
      "89019-07-8",
      "CHEMBL1288594",
      "8-Acetyl-7-Methoxy-Chromen-2-One",
      "2H-1-Benzopyran-2-One,8-Acetyl-7-Methoxy-",
      "Acetyl Methoxycoumarin",
      "ACMC-20lgfq",
      "Acetyl-7-Methoxycoumarin, 8-",
      "SCHEMBL16283617",
      "CTK5G2445",
      "DTXSID20556130",
      "8-Acetyl-7-Methoxy-2-Oxochromene",
      "8-Acetyl-7-Methoxycoumarin ,98%",
      "ZINC2562448",
      "MFCD00270164",
      "8 - Acetyl - 7 - Methoxycoumarin",
      "BDBM50332024",
      "7-Methoxy-8-Coumarinul Methyl Ketone",
      "AKOS016009510",
      "RP27113",
      "ST085908",
      "AJ-40748",
      "DB-078289",
      "KB-200257",
      "AX8111029",
    ],
  },
  {
    primary_id: "D0F7KG",
    names: [
      "L-136662",
      "CHEMBL1222280",
      "AC1M5I9D",
      "BDBM50324737",
      "AKOS008966462",
      "3-(2-Aminoethyl)-5-(4-(Dimethylamino)Benzylidene)Thiazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0F7LE",
    names: ["AZD-0902"],
  },
  {
    primary_id: "D0F7LI",
    names: ["PAN-301-1"],
  },
  {
    primary_id: "D0F7NL",
    names: ["PMID28447479-Compound-20"],
  },
  {
    primary_id: "D0F7NQ",
    names: ["Flumethasone Pivalate"],
  },
  {
    primary_id: "D0F7NT",
    names: ["349U85.HCL", "6-Piperidinoquinolin-2(1H)-One Hydrochloride"],
  },
  {
    primary_id: "D0F7NU",
    names: [
      "1,1,1-Trifluoro-3-(Hexylsulfinyl)Propan-2-One",
      "CHEMBL155396",
      "1,1,1-Trifluoro-3-(Hexane-1-Sulfinyl)Propane-2-One",
      "479669-33-5",
    ],
  },
  {
    primary_id: "D0F7NZ",
    names: ["Guanidine Hydrochloride"],
  },
  {
    primary_id: "D0F7OX",
    names: [
      "Benzaldehyde O-4-Methoxyphenylcarbamoyl Oxime",
      "CHEMBL599469",
      "AC1NSB21",
      "MLS001207590",
      "MolPort-001-926-439",
      "BDBM50309693",
      "AKOS000588577",
      "BAS 00346640",
      "SMR000513623",
      "ST50224746",
      "AB00076721-01",
      "[(E)-Benzylideneamino] N-(4-Methoxyphenyl)Carbamate",
    ],
  },
  {
    primary_id: "D0F7OY",
    names: ["Recombinant Plague F1V Vaccine"],
  },
  {
    primary_id: "D0F7PZ",
    names: ["PF-232798"],
  },
  {
    primary_id: "D0F7QP",
    names: ["StarGen Stargardt's Disease Gene Therapy"],
  },
  {
    primary_id: "D0F7QZ",
    names: ["Styrylboronic Acid"],
  },
  {
    primary_id: "D0F7RU",
    names: ["Anti-TSLP Mab", "Anti-TSLP MAb (Asthma)"],
  },
  {
    primary_id: "D0F7SE",
    names: ["C[RGDf-(3R)-Carboxymorpholine]", "CHEMBL443362"],
  },
  {
    primary_id: "D0F7SO",
    names: ["Quinoline-8-Sulfonamide"],
  },
  {
    primary_id: "D0F7SW",
    names: ["MOL-2591"],
  },
  {
    primary_id: "D0F7TR",
    names: [
      "Autologous Skeletal Myoblast Therapy",
      "Autologous Skeletal Myoblast Therapy (Heart Failure)",
      "Autologous Skeletal Myoblast Therapy (Heart Failure), ACTC",
      "Autologous Skeletal Myoblast Therapy (Heart Failure), Advanced Cell Technology",
      "Autologous Skeletal Myoblast Therapy (Myocardial Infarctoin), Mytogen",
      "Autologous Stem Cell Therapy (Heart Failure), ACTC",
      "Autologous Stem Cell Therapy (Myocardial Infarction), Mytogen",
    ],
  },
  {
    primary_id: "D0F7TV",
    names: ["Torbafylline"],
  },
  {
    primary_id: "D0F7UB",
    names: ["Triazolo-Benzodiazepine Derivative 1"],
  },
  {
    primary_id: "D0F7UW",
    names: ["4beta,15-Epoxy-Miller-9Z-Enolide", "CHEMBL205620"],
  },
  {
    primary_id: "D0F7WQ",
    names: ["HE-2000"],
  },
  {
    primary_id: "D0F7XJ",
    names: ["2-Methoxyethyl 18-Methoxycoronaridinate", "CHEMBL607152"],
  },
  {
    primary_id: "D0F7XM",
    names: ["12-Epi LTB4", "12(S)-Leukotriene B4"],
  },
  {
    primary_id: "D0F7XN",
    names: ["M-216765"],
  },
  {
    primary_id: "D0F7YH",
    names: ["P1 Argininal Derivative"],
  },
  {
    primary_id: "D0F7YW",
    names: ["MGN-1703"],
  },
  {
    primary_id: "D0F7ZH",
    names: ["FXI-AS1"],
  },
  {
    primary_id: "D0F7ZJ",
    names: ["Iseganan"],
  },
  {
    primary_id: "D0F7ZK",
    names: ["2-Morpholino-6-Styryl-4H-Pyran-4-One", "CHEMBL223559", "SCHEMBL13435577"],
  },
  {
    primary_id: "D0F7ZR",
    names: ["ARX-424"],
  },
  {
    primary_id: "D0F8AB",
    names: ["PMID27376512-Compound-Figure3CM"],
  },
  {
    primary_id: "D0F8AP",
    names: [
      "9H-Beta-Carbolin-3-Ol",
      "3-Hydroxy-Beta-Carboline",
      "91985-78-3",
      "3H-Pyrido[3,4-B]indol-3-One, 2,9-Dihydro-",
      "Beta-Carbolin-3-Ol",
      "ACMC-20lva1",
      "CHEMBL443140",
      "CHEMBL299662",
      "SCHEMBL10644440",
      "CTK3H2577",
      "9H-Pyrido[3,4-B]indol-3-Ol",
      "DTXSID90556566",
      "UDTDJSXOXQLKGM-UHFFFAOYSA-N",
      "AKOS006373463",
    ],
  },
  {
    primary_id: "D0F8AR",
    names: ["PMID19899765C22"],
  },
  {
    primary_id: "D0F8AX",
    names: ["1-Benzhydryl-4-Cyclopropylpiperidin-4-Ol", "CHEMBL398039"],
  },
  {
    primary_id: "D0F8BF",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 2"],
  },
  {
    primary_id: "D0F8BR",
    names: ["XGP-115"],
  },
  {
    primary_id: "D0F8BS",
    names: [
      "YM-796",
      "(S)-(-)-2,8-Dimethyl-3-Methylene-1-Oxa-8-Azaspiro[4.5]decane L-Tartrate Monohydrate",
      "132041-81-7",
    ],
  },
  {
    primary_id: "D0F8BZ",
    names: ["Ac-Bhg-F-N-Y-Y-W", "CHEMBL385405"],
  },
  {
    primary_id: "D0F8CC",
    names: ["CD666", "CD-666", "CD 666"],
  },
  {
    primary_id: "D0F8CD",
    names: ["Tyr-Pro-Phe-D-Ala-Bn", "CHEMBL577748"],
  },
  {
    primary_id: "D0F8CH",
    names: [
      "4-(Acetylamino)-3-Amino Benzoic Acid",
      "3-Amino-4-Acetamidobenzoic Acid",
      "CHEMBL109162",
      "4-Acetylamino-3-Aminobenzoic Acid",
      "4-(Acetylamino)-3-Aminobenzoic Acid",
      "53484-10-9",
      "ST3",
      "BANA108",
      "AC1L9JL6",
      "BANA-108",
      "SCHEMBL2344997",
      "BANA 108",
      "BDBM5274",
      "4-Acetamido-3-Aminobenzoic Acid",
      "4-Acetamido-3-Amino-Benzoic Acid",
      "4-Acetylamino-3-Amino-Benzoic Acid",
      "AKOS012418429",
    ],
  },
  {
    primary_id: "D0F8CM",
    names: ["Pentosan Polysulfate"],
  },
  {
    primary_id: "D0F8CS",
    names: ["ISF-120"],
  },
  {
    primary_id: "D0F8CV",
    names: [
      "DAMGO",
      "DAGO",
      "Glyol",
      "Dagol",
      "DAMGE",
      "78123-71-4",
      "2-Ala-4-Mephe-5-Gly-Enkephalin",
      "Tyr-Ala-Gly-(Nme)Phe-Gly-Ol",
      "Enkephalin, Ala(2)-Mephe(4)-Gly(5)-",
      "RX 783006",
      "(D-Ala(2)-Mephe(4)-Gly-Ol(5))Enkephalin",
      "Ala(2)-Mephe(4)-Gly-Ol(5) Enkephalin",
      "CHEBI:272",
      "CHEMBL38874",
      "Tyr-D-Ala-Gly-MePhe-Gly-Ol",
      "Enkephalin, Ala(2)-MePhe(4)-Gly-Ol(5)-",
      "H-Tyr-D-Ala-Gly-N-Me-Phe-Glycinol",
      "Enkephalin, Alanyl(2)-Methylphenylalanyl(4)-Glycine(5)-",
      "[3H]DAMGO",
      "L-Phenylalaninamide, L-Tyrosyl-D-Alanylglycyl-N-(2-Hydroxyethyl)-Nalpha-Methyl-",
    ],
  },
  {
    primary_id: "D0F8DL",
    names: [
      "SB 203580",
      "152121-47-6",
      "SB-203580",
      "SB203580",
      "4-(4-(4-Fluorophenyl)-2-(4-(Methylsulfinyl)Phenyl)-1H-Imidazol-5-Yl)Pyridine",
      "4-(4-Fluorophenyl)-2-[4-(Methylsulfinyl)Phenyl]-5-(4-Pyridyl)-1H-Imidazole",
      "CHEBI:90705",
      "4-(4-Fluorophenyl)-2-(4-Methylsulfinylphenyl)-5-(4-Pyridyl)-1H-Imidazole",
      "CHEMBL10",
      "RWJ 64809",
      "4-(4-Fluorophenyl)-2-(4-Methylsulfinylphenyl)-5-(4-Pyridyl)1H-Imidazole",
      "4-[4-(4-Fluorophenyl)-2-(4-Methylsulfinylphenyl)-1H-Imidazol-5-Yl]pyridine",
      "PB 203580",
      "UNII-WS78QR6DSV",
    ],
  },
  {
    primary_id: "D0F8EM",
    names: ["Op-1"],
  },
  {
    primary_id: "D0F8EU",
    names: ["Oralex"],
  },
  {
    primary_id: "D0F8GK",
    names: ["LY2886721"],
  },
  {
    primary_id: "D0F8GT",
    names: ["AVI-6006"],
  },
  {
    primary_id: "D0F8GZ",
    names: ["ISIS 103819"],
  },
  {
    primary_id: "D0F8HG",
    names: ["ISIS 111120"],
  },
  {
    primary_id: "D0F8HN",
    names: ["DA-8031"],
  },
  {
    primary_id: "D0F8HS",
    names: ["NLX-101"],
  },
  {
    primary_id: "D0F8HU",
    names: ["VaxiFlu-S"],
  },
  {
    primary_id: "D0F8HX",
    names: ["Lactoquinomycin"],
  },
  {
    primary_id: "D0F8IB",
    names: ["MONODICTYOCHROMONE B"],
  },
  {
    primary_id: "D0F8IO",
    names: [
      "Cephaloridine",
      "Cefaloridine",
      "Cefaloridin",
      "Cephaloridin",
      "Cephaloridinum",
      "Cepaloridin",
      "Cefalorizin",
      "Cephalomycine",
      "Cefaloridinum",
      "Cepalorin",
      "Cefaloridina",
      "Loridine",
      "Ceflorin",
      "50-59-9",
      "Kefloridin",
      "Glaxoridin",
      "Ceporin",
      "Vioviantine",
      "Intrasporin",
      "Sefacin",
      "Keflordin",
      "Deflorin",
      "Cilifor",
      "Ceporan",
      "Sasperin",
      "Faredina",
      "Ceporine",
      "Keflodin",
      "Verolgin",
      "Lloncefal",
      "Kefspor",
      "Ampligram",
      "Betaine Cephaloridine",
      "CHEBI:3537",
      "UNII-LVZ1VC61HB",
      "Cefaloridinum [INN-Latin]",
      "Cefaloridina [INN-Spanish]",
      "N-(7-(2'-Thienylacetamidoceph-3-Ylmethyl))-Pyridinium-2-Carboxylate",
      "SCH",
    ],
  },
  {
    primary_id: "D0F8JO",
    names: [
      "4-((+/-)-(1H-Imidazol-1-Yl)-(E)-Retinoic Acid",
      "CHEMBL57765",
      "SCHEMBL1234027",
      "4-(1-Imidazolyl)Retinoic Acid",
      "BDBM50091698",
      "(2E,4E,6E,8E)-9-(3-Imidazol-1-Yl-2,6,6-Trimethyl-Cyclohex-1-Enyl)-3,7-Dimethyl-Nona-2,4,6,8-Tetraenoic Acid",
    ],
  },
  {
    primary_id: "D0F8KE",
    names: ["Imidazole Benzamide Derivative 1"],
  },
  {
    primary_id: "D0F8KR",
    names: ["RTU-1096"],
  },
  {
    primary_id: "D0F8KX",
    names: ["ISIS 19218"],
  },
  {
    primary_id: "D0F8LE",
    names: ["Indole-Based Analog 1"],
  },
  {
    primary_id: "D0F8LL",
    names: ["ISIS 25115"],
  },
  {
    primary_id: "D0F8LS",
    names: ["4-(1-Cyclopentyl-7-Fluoro-1H-Indazol-3-Yl)Phenol"],
  },
  {
    primary_id: "D0F8NE",
    names: ["V-103"],
  },
  {
    primary_id: "D0F8OB",
    names: ["Antifungal Potentiators", "Antifungal Potentiators (Fungal Infection)"],
  },
  {
    primary_id: "D0F8OH",
    names: ["KNI-10260"],
  },
  {
    primary_id: "D0F8PG",
    names: ["PMID29334795-Compound-21"],
  },
  {
    primary_id: "D0F8QJ",
    names: ["WEDELOLACTONE"],
  },
  {
    primary_id: "D0F8QM",
    names: ["FR-158999", "4-[4-(4-Amidinophenoxy)Butyryl-L-Aspartyl-L-Valyl]thiomorpholine S,S-Dioxide"],
  },
  {
    primary_id: "D0F8RA",
    names: [
      "Theophylline",
      "Bronchoretard",
      "Cetraphylline",
      "Chronophyllin",
      "Dimethylxanthine",
      "Elixophylline",
      "Liquophylline",
      "Pseudotheophylline",
      "Afonilum Retard",
      "Bronchodid Duracap",
      "Elixophyllin SR",
      "Somophyllin CRT",
      "Spophyllin Retard",
      "Telbans DrySyrup",
      "Teofilina [Polish]",
      "Theal Tablets",
      "Theobid Duracap",
      "Theodur Dry Syrup",
      "Uniphyllin Continus",
      "Elixophyllin (TN)",
      "Elixophyllin(E)",
      "Pulmo-Timelets",
      "Somophyllin-CRT",
      "Somophyllin-DF",
      "Theo-Organidin",
      "Theoclear-200",
      "Theodur G (TN)",
      "Theolair (TN)",
      "Theophylline (JP15)",
      "Theophylline-SR",
      "Quibron-T (TN)",
      "Theo-Dur-Sprinkle",
      "Theophylline-[8-3H",
      "Theoclear L.A.-130",
      "1,3 Dimethylxanthine",
      "1,3-Dimethylxanthine",
      "1H-Purine-2,6-Dione",
    ],
  },
  {
    primary_id: "D0F8RK",
    names: ["GED-APC"],
  },
  {
    primary_id: "D0F8SA",
    names: ["8S-Hydroxylobelane", "CHEMBL377539", "SCHEMBL14178958"],
  },
  {
    primary_id: "D0F8SK",
    names: ["Perfluoropropane Gas-Filled Nanobubbles"],
  },
  {
    primary_id: "D0F8SW",
    names: ["SNS01-T"],
  },
  {
    primary_id: "D0F8TB",
    names: ["TSR-033"],
  },
  {
    primary_id: "D0F8TG",
    names: ["Fluperlapine", "C10967"],
  },
  {
    primary_id: "D0F8TM",
    names: ["Alpha-1 Proteinase Inhibitor (Inhaled), Alpha/Profile", "A1PI (Inhaled), Alpha/Profile"],
  },
  {
    primary_id: "D0F8TS",
    names: ["MPC-9055"],
  },
  {
    primary_id: "D0F8TV",
    names: ["Interleukin-8 Inhibitors, Millennium/Pfizer"],
  },
  {
    primary_id: "D0F8TZ",
    names: [
      "3-(2-Carboxy-5-Mercaptopentyl)Benzoic Acid",
      "Thiol-Based Inhibitor, 6c",
      "SCHEMBL2058464",
      "CHEMBL382398",
      "CTK8A8854",
      "BDBM17762",
      "JKPYNGWTXQIZFC-UHFFFAOYSA-N",
      "AKOS015966199",
      "3-[2-Carboxy-2-(3-Sulfanylpropyl)Ethyl]benzoic Acid",
      "3-(2-CARBOXY-5-MERCAPTO-PENTYL)-BENZOIC ACID",
    ],
  },
  {
    primary_id: "D0F8UU",
    names: ["Photosensitizers"],
  },
  {
    primary_id: "D0F8UV",
    names: ["Nelipepimut S", "E75"],
  },
  {
    primary_id: "D0F8VH",
    names: ["AA-166", "BMP Agonist (Cartilage Repair), Thrasos Therapeutics"],
  },
  {
    primary_id: "D0F8VM",
    names: ["Meningococcal Vaccine Groups A,C,Y,W-135 Conjugate"],
  },
  {
    primary_id: "D0F8VS",
    names: ["L-Homoarginine"],
  },
  {
    primary_id: "D0F8WV",
    names: ["ISIS 104257"],
  },
  {
    primary_id: "D0F8XI",
    names: ["DS-1442"],
  },
  {
    primary_id: "D0F8XK",
    names: ["ISIS-GCCR"],
  },
  {
    primary_id: "D0F8XU",
    names: ["Isoindoline Derivative 2"],
  },
  {
    primary_id: "D0F8XZ",
    names: ["PMID30074415-Compound-20"],
  },
  {
    primary_id: "D0F8YO",
    names: ["2-[3-(1-Hydroxy-Propyl)-Phenyl]-Propionic Acid"],
  },
  {
    primary_id: "D0F8ZD",
    names: ["OPN-302"],
  },
  {
    primary_id: "D0F8ZG",
    names: ["GX-G3"],
  },
  {
    primary_id: "D0F8ZI",
    names: ["3-Morpholin-4-Ylmethyl-5H-Phenanthridin-6-One", "CHEMBL194535"],
  },
  {
    primary_id: "D0F9AI",
    names: ["ISIS 113907"],
  },
  {
    primary_id: "D0F9AM",
    names: ["LUF6200", "CHEMBL3590446", "GTPL7847", "BDBM50095642"],
  },
  {
    primary_id: "D0F9AO",
    names: ["BHV-5000"],
  },
  {
    primary_id: "D0F9BT",
    names: ["ONX-0912"],
  },
  {
    primary_id: "D0F9BX",
    names: ["GW-660511"],
  },
  {
    primary_id: "D0F9BY",
    names: [
      "Anidulafungin",
      "Ecalta",
      "Eraxis",
      "Anidulafungin [USAN:INN]",
      "Ecalta (TN)",
      "Eraxis (TN)",
      "LY-303366",
      "V-Echinocandin",
      "VER-002",
      "Echinocandin B, 1-((4R,5R)-4,5-Dihydroxy-N2-((4\"-(Pentyloxy)(1,1':4',1\"-Terphenyl)-4-Yl)Carbonyl)-L-Ornithine)",
      "(4R,5R)-4,5-Dihydroxy-N(Sup 2)-((4'-(Pentyloxy)-P-Terphenyl-4-Yl)Carbonyl)-L-Ornithyl-L-Threonyl-Trans-4-Hydroxy-L-Prolyl-(S)-4-Hydroxy-4-(P-Hydroxyphenyl)-L-Threonyl-L-Threonyl-(3S,4S)-3-Hydroxy-4-Methyl-L-Proline Cyclic (6-1)-Peptide",
    ],
  },
  {
    primary_id: "D0F9DD",
    names: ["PMID24900824C2-13-1"],
  },
  {
    primary_id: "D0F9EM",
    names: ["ISIS 23707"],
  },
  {
    primary_id: "D0F9FA",
    names: [
      "L-709049",
      "Ac-Yvad-Cho",
      "143313-51-3",
      "Acetyl-Tyrosyl-Valyl-Alanyl-Aspartal",
      "Caspase-1 Inhibitor I",
      "CHEMBL37630",
      "Ac-Tyr-Val-Ala-Asp-H",
      "IL-1beta Converting Enzyme (ICE) Inhibitor I",
      "Acetyl-Tyr-Val-Ala-Asp-Aldehyde",
      "N-Acetyl-Tyr-Val-Ala-Asp-Aldehyde",
      "(3S)-3-[(2S)-2-[(2S)-2-[(2S)-2-Acetamido-3-(4-Hydroxyphenyl)Propanamido]-3-Methylbutanamido]propanamido]-4-Oxobutanoic Acid",
      "Ac-Tyr-Val-Ala-Asp-Aldehyde",
      "AC1NSK2K",
      "SCHEMBL4349143",
      "BDBM10355",
      "MolPort-016-580-695",
      "N-Acetyl-Tyr-Val-Ala-Asp Aldehyde",
      "ZINC3915255",
      "1600AH",
      "NCGC00167338-01",
    ],
  },
  {
    primary_id: "D0F9FG",
    names: ["Isojaspic Acid"],
  },
  {
    primary_id: "D0F9FR",
    names: [
      "Oxazolidinones",
      "2-Oxazolidone",
      "Oxazolidin-2-One",
      "2-Oxazolidinone",
      "497-25-6",
      "1,3-Oxazolidin-2-One",
      "Oxazolidinone",
      "Oxazolidone",
      "UNII-Z4D49W92PP",
      "EINECS 207-840-9",
      "NSC 35382",
      "BRN 0106251",
      "AI3-38980",
      "CHEBI:1237",
      "Z4D49W92PP",
      "IZXIZTKNFFYFOF-UHFFFAOYSA-N",
      "Carbamic Acid, (2-Hydroxyethyl)-, Gamma-Lactone",
      "MFCD00005268",
      "2-Oxazolidone, 98%",
      "WLN: T5MVOTJ",
      "51667-26-6",
      "Carbamic Acid, .gamma.-Lactone",
      "SMR000857362",
      "Oxazolodinone",
      "Hydroxyoxazoline",
      "2-Oxazolidinon",
      "Hydroxy-Oxazoline",
      "2-Oxazolidine",
      "Oxazolidine-2-One",
      "PubChem8624",
      "ACMC-209upq",
      "AC1Q6HSI",
    ],
  },
  {
    primary_id: "D0F9GE",
    names: [
      "Dipyridamole",
      "Agilease",
      "Anginal",
      "Antistenocardin",
      "Apricor",
      "Cardioflux",
      "Cardoxil",
      "Cardoxin",
      "Cerebrovase",
      "Chilcolan",
      "Cleridium",
      "Coribon",
      "Coridil",
      "Coronarine",
      "Corosan",
      "Coroxin",
      "Curantil",
      "Curantyl",
      "Dipiridamol",
      "Dipyramidole",
      "Dipyridamine",
      "Dipyridamol",
      "Dipyridamolum",
      "Dipyridan",
      "Dipyudamine",
      "Dypyridamol",
      "Gulliostin",
      "Justpertin",
      "Kurantil",
      "Miosen",
      "Natyl",
      "Peridamol",
      "Permiltin",
      "Persantin",
      "Persantine",
      "Piroan",
      "Prandiol",
      "Protangix",
      "Stenocardil",
      "Stenocardiol",
      "Stimolcardio",
      "Apotex Brand Of Dipyridamole",
      "Ashbourne Brand Of Dipyridamole",
      "Belmac Brand Of Dipyridamole",
      "Berlin Chemie Brand Of Dipyridamole",
      "Boehringer Ingelheim Brand Of Dipyridamole",
      "IPRAD Brand Of Dipyridamole",
      "Iv Persantine",
      "Novopharm Brand Of Dipyridamole",
      "Cleridium 150",
      "D 9766",
      "Prandiol 75",
      "Apo-Dipyridamole",
      "Apo-Dipyridamole Fc",
      "Apo-Dipyridamole Sc",
      "Berlin-Chemie Brand Of Dipyridamole",
      "Dipiridamol [INN-Spanish]",
      "Dipyridamolum [INN-Latin]",
      "Novo-Dipiradol",
      "Persantine (TN)",
      "RA-8",
      "Usaf Ge-12",
      "Dipyridamole (JP15/USP/INN)",
      "Dipyridamole [USAN:INN:BAN:JAN]",
      "Permole, Persantine, Dipyridan, Dipyridamole",
      "2,6-Bis(Diethanolamine)-4,8-Dipiperidinopyrimido[5,4-D]pyrimidine",
      "2,6-Bis(Diethanolamino)-4,8-Dipiperidinopyrimido(5,4-D)Pyrimidine",
      "2,6-Bis(Diethanolamino)-4,8-Dipiperidinopyrimido[5,4-D]pyrimidine",
    ],
  },
  {
    primary_id: "D0F9GL",
    names: ["PMID25553724-Compound-US2011806744710"],
  },
  {
    primary_id: "D0F9HK",
    names: ["Strontium-89"],
  },
  {
    primary_id: "D0F9IE",
    names: ["MK-5890"],
  },
  {
    primary_id: "D0F9IK",
    names: ["SMRX-11", "CSSK, Nostrum", "Clot-Specific Streptokinase (Myocardial Infarction), Nostrum Pharmaceuticals"],
  },
  {
    primary_id: "D0F9IS",
    names: ["FR-900482"],
  },
  {
    primary_id: "D0F9IX",
    names: ["Samatasvir"],
  },
  {
    primary_id: "D0F9JF",
    names: ["FR-166124"],
  },
  {
    primary_id: "D0F9KI",
    names: ["LAS-30538"],
  },
  {
    primary_id: "D0F9KV",
    names: [
      "1(Beta)-O-Galloylpedunculagin",
      "Casuarictin",
      "CHEMBL446979",
      "CHEBI:3461",
      "79786-00-8",
      "Sanguiin H 11",
      "Cuspinin",
      "1(.beta.)-O-Galloylpedunculagin",
      "(10ar,11s,12ar,25ar,25bs)-2,3,4,5,6,7,17,18,19,20,21,22-Dodecahydroxy-9,15,24,27-Tetraoxo-9,10a,11,12a,13,15,24,25a,25b,27-Decahydrodibenzo[g,i]dibenzo[6',7':8',9'][1,4]dioxecino[2',3':4,5]pyrano[3,2-B][1,5]dioxacycloundecin-11-Yl 3,4,5-Trihydroxybenzoate",
      "AC1Q6P15",
      "AC1L2K81",
      "BDBM50250990",
      "C10212",
      ".beta.-D-Glucopyranose, Cyclic 2,3:4,6-Bis[(1S)-4,4',5,5',6,6'-Hexahydroxy[1,1'-Biphen",
    ],
  },
  {
    primary_id: "D0F9KX",
    names: ["PT-101"],
  },
  {
    primary_id: "D0F9LO",
    names: ["1-(4-Chlorooxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL462432", "SCHEMBL2162562"],
  },
  {
    primary_id: "D0F9LX",
    names: ["18R-HEPE", "18R-Hydroxyeicosapentaenoic Acid", "18R HEPE"],
  },
  {
    primary_id: "D0F9MQ",
    names: ["N-(2-Hexyloxy-4-Nitrophenyl)Methanesulfonamide", "CHEMBL202471", "SCHEMBL3294121", "BDBM50182531"],
  },
  {
    primary_id: "D0F9NA",
    names: ["MRC-375"],
  },
  {
    primary_id: "D0F9ND",
    names: ["MGAWN-1"],
  },
  {
    primary_id: "D0F9NL",
    names: ["12S-HPETE", "12-(S)-Hydroperoxyeicosatetraenoic Acid", "12-(S)-HPETE"],
  },
  {
    primary_id: "D0F9NN",
    names: ["AP-501"],
  },
  {
    primary_id: "D0F9OP",
    names: ["Aerophobin-1", "CHEMBL479515"],
  },
  {
    primary_id: "D0F9PM",
    names: [
      "WAY-196025",
      "CHEMBL404529",
      "SCHEMBL1501417",
      "YOFBEFNLUYVWRR-UHFFFAOYSA-N",
      "BDBM50226793",
      "4-(3-{1-Benzhydryl-5-Chloro-2-[2-(2,6-Dimethyl-Phenylmethanesulfonylamino)-Ethyl]-1H-Indol-3 -Yl}-Propyl)-Benzoic Acid",
      "4-{3-[5-Chloro-2-(2-{[(2,6-Dimethylbenzyl)Sulfonyl]amino}ethyl)-1-(Diphenylmethyl)-1H-Indol-3-Yl]propyl}benzoic Acid",
      "4-(3-(1-Benzhydryl-5-Chloro-2-(2-((2,6-Dimethylphenyl)Methylsulfonamido)Ethyl)Indolin-3-Yl)Propyl)Benzoic Acid",
    ],
  },
  {
    primary_id: "D0F9QC",
    names: ["PMID27744724-Compound-27"],
  },
  {
    primary_id: "D0F9QH",
    names: ["Abituzumab"],
  },
  {
    primary_id: "D0F9QJ",
    names: ["[Gly(But)7]OT", "CHEMBL234363"],
  },
  {
    primary_id: "D0F9QP",
    names: ["GW-559090", "Alpha-4 Integrin Antagonist (Inhaled), GlaxoSmithKline"],
  },
  {
    primary_id: "D0F9QT",
    names: ["A-987306"],
  },
  {
    primary_id: "D0F9QY",
    names: [
      "3,3'-Difluorobenzaldazine",
      "DFB",
      "15332-10-2",
      "CHEMBL371811",
      "1,2-Bis(3-Fluorobenzylidene)Hydrazine",
      "AC1O7H0M",
      "SCHEMBL12017614",
      "SCHEMBL12497017",
      "Bis(3-Fluorobenzylidene)Hydrazine",
      "CHEBI:92414",
      "MolPort-003-940-930",
      "HMS3262J07",
      "Tox21_500843",
      "DFB, &gt",
      "MFCD03653615",
      "BDBM50156074",
      "ZINC13719901",
      "AKOS024456711",
      "VZ25360",
      "CCG-222147",
      "NCGC00261528-01",
      "NCGC00025238-05",
      "NCGC00025238-02",
      "NCGC00025238-03",
      "NCGC00025238-04",
      "KB-76455",
      "SR-01000597595",
      "C-14798",
      "J-009001",
      "SR-01000597595-1",
      "3,3 Inverted Exclamation Marka-Difluorobenzaldazine",
    ],
  },
  {
    primary_id: "D0F9RE",
    names: ["Allylamines"],
  },
  {
    primary_id: "D0F9RK",
    names: ["Spiroimidazolone Derivative 3"],
  },
  {
    primary_id: "D0F9RO",
    names: [
      "Corosolic Acid",
      "A,5xi,18",
      "A)-2,3-Dihydroxyurs-12-En-28-Oic Acid",
      "AC1Q5QHO",
      "AC1L4WCO",
      "SCHEMBL1157257",
      "KB-333790",
      "LS-193275",
      "(2a,3b)-2,3-Dihydroxy-Urs-12-En-28-Oic Acid",
      "(1S,2R,4aS,6aR,6aS,6bR,10R,11R,12aR,14bR)-10,11-Dihydroxy-1,2,6a,6b,9,9,12a-Heptamethyl-2,3,4,5,6,6a,7,8,8a,10,11,12,13,14b-Tetradecahydro-1H-Picene-4a-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0F9SG",
    names: [
      "Dexfenfluramine",
      "DEXFENFLURAN",
      "Dexfenfluramine (INN)",
      "Redux (TN)",
      "(2S)-N-Ethyl-1-[3-(Trifluoromethyl)Phenyl]propan-2-Amine",
      "(S)-N-Ethyl-1-[3-(Trifluoromethyl)Phenyl]propan-2-Amine",
    ],
  },
  {
    primary_id: "D0F9TL",
    names: ["BT-063"],
  },
  {
    primary_id: "D0F9TY",
    names: ["Aryl Piperazine Derivative 16"],
  },
  {
    primary_id: "D0F9VH",
    names: ["Tesetaxel"],
  },
  {
    primary_id: "D0F9WF",
    names: [
      "SINOMENINE",
      "Cucoline",
      "Kukoline",
      "(9alpha,13alpha,14alpha)-4-Hydroxy-3,7-Dimethoxy-17-Methyl-7,8-Didehydromorphinan-6-One",
    ],
  },
  {
    primary_id: "D0F9XG",
    names: ["MEDI2070"],
  },
  {
    primary_id: "D0F9XO",
    names: ["BH4"],
  },
  {
    primary_id: "D0F9YJ",
    names: ["Pfs48"],
  },
  {
    primary_id: "D0F9YV",
    names: ["PMID3514912C9"],
  },
  {
    primary_id: "D0F9YZ",
    names: ["KOTALANOL"],
  },
  {
    primary_id: "D0F9ZW",
    names: ["N-Acetylserotonin"],
  },
  {
    primary_id: "D0FA0A",
    names: ["Apristor"],
  },
  {
    primary_id: "D0FA2L",
    names: ["N,N-Methylenebis-2-Phenylacetamide And Benzenesulfonamide Derivative 3"],
  },
  {
    primary_id: "D0FA2N",
    names: ["HE-3177"],
  },
  {
    primary_id: "D0FA2O",
    names: ["Trioxsalen"],
  },
  {
    primary_id: "D0FA3G",
    names: ["PMID29334795-Compound-61"],
  },
  {
    primary_id: "D0FA4C",
    names: ["JDP-201"],
  },
  {
    primary_id: "D0FA4Q",
    names: ["IL1R1 NAM"],
  },
  {
    primary_id: "D0FA5D",
    names: ["Aromatic Ring Compound 3"],
  },
  {
    primary_id: "D0FA5Z",
    names: ["LX-1033"],
  },
  {
    primary_id: "D0FA6S",
    names: ["Altiratinib"],
  },
  {
    primary_id: "D0FB1C",
    names: ["BPX-04"],
  },
  {
    primary_id: "D0FB1Q",
    names: ["PMID25656651-Compound-31b"],
  },
  {
    primary_id: "D0FB1S",
    names: ["GTx-230", "ABI-274", "ABI-288", "Tubulin Antagonist (Oral, Cancer), GTx Inc"],
  },
  {
    primary_id: "D0FB3R",
    names: ["Alkaloid Derivative 4"],
  },
  {
    primary_id: "D0FB4W",
    names: ["CYAD-101"],
  },
  {
    primary_id: "D0FB6T",
    names: ["SYN-987"],
  },
  {
    primary_id: "D0FB7S",
    names: ["7beta-Hydroxycholesterol", "7-Beta-OHC"],
  },
  {
    primary_id: "D0FC0B",
    names: ["PMID29649907-Compound-21"],
  },
  {
    primary_id: "D0FC0Z",
    names: ["Isoxazole-Based Bicyclic Compound 13"],
  },
  {
    primary_id: "D0FC1J",
    names: ["Nifurtimox"],
  },
  {
    primary_id: "D0FC4R",
    names: ["CVX 096"],
  },
  {
    primary_id: "D0FC4Y",
    names: [
      "PMID22902653C31",
      "BMS 4",
      "GTPL8117",
      "MolPort-042-665-786",
      "ZINC84707544",
      "BDBM50390791",
      "NCGC00372509-03",
    ],
  },
  {
    primary_id: "D0FC5J",
    names: ["PMID26004420-Compound-US20140031349D"],
  },
  {
    primary_id: "D0FC5T",
    names: [
      "DIAVERIDINE",
      "Diaveridine < Rec INN",
      "EGIS-5645",
      "2,4-Diamino-5-(3',4'-Dimethoxybenzyl)Pyrimidine",
      "5-[(3,4-Dimethoxyphenyl)Methyl]-2,4-Pyrimidinediamine",
    ],
  },
  {
    primary_id: "D0FC7H",
    names: ["MUC-1 CD40L Dendritic Cell Vaccine"],
  },
  {
    primary_id: "D0FC7L",
    names: [
      "2-Cyclohexylamino-Pyridine-2-Carbonitrile",
      "2-Cyano-Pyridine, 8",
      "2-Pyridinecarbonitrile, 6-(Cyclohexylamino)-",
      "CHEMBL218522",
      "BDBM19747",
      "AKOS013783092",
      "927176-95-2",
    ],
  },
  {
    primary_id: "D0FC7P",
    names: ["Hepatitis B Vaccine 1018-ISS Conjugate"],
  },
  {
    primary_id: "D0FC8O",
    names: ["DWP-05195", "DWJ-204", "DWP-0519"],
  },
  {
    primary_id: "D0FC8P",
    names: ["Pyrazole Derivative 19"],
  },
  {
    primary_id: "D0FC9J",
    names: ["PMID28394193-Compound-51"],
  },
  {
    primary_id: "D0FD0B",
    names: ["PSN-9301"],
  },
  {
    primary_id: "D0FD0H",
    names: [
      "Aminocaproic Acid",
      "6-Aminohexanoic Acid",
      "6-Aminocaproic Acid",
      "60-32-2",
      "Amicar",
      "Epsikapron",
      "Caprocid",
      "Capramol",
      "Epsamon",
      "EACA",
      "Caprolisin",
      "Acepramin",
      "Hemocaprol",
      "Epsicapron",
      "Respramin",
      "Amikar",
      "EPSILON-AMINOCAPROIC ACID",
      "Hexanoic Acid, 6-Amino-",
      "Epsilcapramin",
      "Aminokapron",
      "Caplamin",
      "Acepramine",
      "Capracid",
      "Hemopar",
      "Ipsilon",
      "Afibrin",
      "Atsemin",
      "Hepin",
      "Epsilon S",
      "Aminocaproic",
      "Capralense",
      "Epsilon-Leucine",
      "EACS",
      "Epsilon-Norleucine",
      "Epsilcapramine",
      "6-Amino-N-Hexanoic Acid",
      "Omega-Aminocaproic Acid",
      "Epsilon-Aminohexanoic Acid",
      "6-Amino-Hexanoic Acid",
      "ACS",
      "Aminocaproate",
      "Capranol",
      "Caproamin",
      "Epsicaprom",
      "Hemopar;Hepin",
      "Acide Aminocaproique",
      "Acide Aminocaproique [French]",
      "Acide Aminocaproque",
      "Acido Aminocaproico",
      "Acidum Aminocaproicum",
      "Acidum Aminocaproicum [Latin]",
      "Aminocaproic Acid In Plastic Container",
      "Aminocaproic Acids",
      "Aminohexanoic Acid",
      "Eaca Kabi",
      "Epsilon Aminocaproic Acid",
      "A 7824",
      "CL 10304",
      "CY 116",
      "CY116",
      "JD 177",
      "Acide Aminocaproique [INN-French]",
      "Acido Aminocaproico [DCIT,Spanish]",
      "Acido Aminocaproico [INN-Spanish]",
      "Acidum Aminocaproicum [INN-Latin]",
      "Amicar (TN)",
      "Aminocaproic Acid (USP)",
      "CL-10304",
      "CY-116",
      "Epsilon-Ahx",
      "Epsilon-Aminocaproic Acid",
      "Epsilon-Aminocapronsaeure",
      "Epsilon-Aminocaproate",
      "Epsilon-Aminocapronzuur",
      "Fullevir (TN)",
      "Kyselina Omega-Aminokapronova",
      "Kyselina Omega-Aminokapronova [Czech]",
      "Omega-Aminohexanoic Acid",
      "S04-0132",
      "Acid, 6-Aminocaproic",
      "Acid, 6-Aminohexanoic",
      "Acid, Epsilon-Aminocaproic",
      "Aminocaproic Acid (USP/INN)",
      "Aminocaproic Acid [USAN:BAN:INN]",
      "Aminocaproic Acid [USAN:INN:BAN]",
      "Epsilon-Aminocaproic Acid (JAN)",
      "HEXANOIC ACID,6-AMINO",
      "Epsilon-Amino-N-Caproic Acid",
      "Epsilon-Amino-N-Hexanoic Acid",
      "Amicar, A-Amino Caproic Acid, A-Ahx, 6-Aminohexanoic Acid, Aminocaproic Acid",
      "6 Aminocaproic Acid",
      "6 Aminohexanoic Acid",
      "6-Amino-N-Caproate",
      "6-Amino-N-Caproic Acid",
      "6-Aminohexanoate",
      "6-Amino Hexanoic Acid",
    ],
  },
  {
    primary_id: "D0FD2I",
    names: ["Discodermolide", "Discodermolide, Novartis", "XAA-296"],
  },
  {
    primary_id: "D0FD3D",
    names: [
      "Cripto-1 Mabs",
      "Cripto-1 MAbs (Cancer)",
      "Anticancer Antibodies (Cancer), Oncomab/Medarex",
      "Cripto-1 MAb (Cancer), Prima BioMed",
      "Cripto-1 MAbs(Cancer), Bioceros/Oncomab",
      "Anti-Cripto-1 Antibodies (Cancer), Oncomab/Medarex",
      "Anti-Cripto-1 Antibodies (Cancer), Austin Research Institute/Prima BioMed/Medarex",
    ],
  },
  {
    primary_id: "D0FD6D",
    names: [
      "CEP-11981",
      "UNII-J8AY0Z4CBP",
      "856691-93-5",
      "J8AY0Z4CBP",
      "CHEMBL2010872",
      "BOL-303213X",
      "Compound 11b [PMID 22148921]",
      "GTPL8189",
      "SCHEMBL2439310",
      "DTXSID80234942",
      "CEP11981",
      "BDBM50379186",
      "CEP 11981",
      "4H-Indazolo(5,4-A)Pyrrolo(3,4-C)Carbazol-4-One, 2,5,6,11,12,13-Hexahydro-2-Methyl-11-(2-Methylpropyl)-8-(2-Pyrimidinylamino)-",
      "Z-3152",
    ],
  },
  {
    primary_id: "D0FD6E",
    names: ["Aspoxicillin", "Doyle (TN)"],
  },
  {
    primary_id: "D0FD6Z",
    names: [
      "8-Fluoro-6-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL192386",
      "SCHEMBL3924505",
      "KBQSRNQTSRTYGN-UHFFFAOYSA-N",
      "ZINC13645086",
      "BDBM50168353",
    ],
  },
  {
    primary_id: "D0FD7H",
    names: ["ALT-803"],
  },
  {
    primary_id: "D0FD8G",
    names: [
      "2-(Biphenyl-4-Yl)Vinylboronic Acid",
      "352530-23-5",
      "Trans-2-(4-Biphenyl)Vinylboronic Acid",
      "(2-([1,1'-Biphenyl]-4-Yl)Vinyl)Boronic Acid",
      "(E)-(2-([1,1'-Biphenyl]-4-Yl)Vinyl)Boronic Acid",
      "AC1O0CFW",
      "Alkenylboronic Acid, 22",
      "4-Phenylstyrylboronic Acid",
      "CHEMBL453758",
      "SCHEMBL2910475",
      "SCHEMBL12260418",
      "C14H13BO2",
      "BDBM26143",
      "DTXSID30421729",
      "ZINC169870786",
      "AKOS015893176",
      "FCH5518572",
      "AB21449",
      "RTR-014581",
      "KB-61854",
      "BBV-43957496",
      "ACM352530235",
      "OR360465",
      "AX8240126",
      "[(E)-2-(4-Phenylphenyl)Ethenyl]boranediol",
      "V5431",
      "[(E)-2-(4-Phenylphenyl)Ethenyl]boronic Acid",
      "B-4710",
      "I04-2357",
    ],
  },
  {
    primary_id: "D0FD8Q",
    names: ["PMID8246237C18t"],
  },
  {
    primary_id: "D0FD9M",
    names: ["PMID21536437C15c"],
  },
  {
    primary_id: "D0FE3P",
    names: ["MIV 150 + Zinc Acetate"],
  },
  {
    primary_id: "D0FE7A",
    names: ["RS 67333"],
  },
  {
    primary_id: "D0FE7D",
    names: ["Emibetuzumab"],
  },
  {
    primary_id: "D0FE7H",
    names: ["PMID10498202C1", "4oc6", "GTPL6541", "BDBM50080960"],
  },
  {
    primary_id: "D0FF0I",
    names: ["MOD-5014"],
  },
  {
    primary_id: "D0FF0N",
    names: ["TBI-1501"],
  },
  {
    primary_id: "D0FF0Z",
    names: [
      "4-(3-Phenylethynyl-Benzyl)-1H-Imidazole",
      "CHEMBL449840",
      "BDBM22538",
      "4-Benzyl-1H-Imidazole Derivative, 19",
    ],
  },
  {
    primary_id: "D0FF2G",
    names: ["Sym013"],
  },
  {
    primary_id: "D0FF2S",
    names: ["RP-G28"],
  },
  {
    primary_id: "D0FF3D",
    names: ["Metazamide"],
  },
  {
    primary_id: "D0FF4M",
    names: [
      "1,2,3,4,4a,5-Hexahydrophenanthridin-6(10bH)-One",
      "CHEMBL84044",
      "7403-93-2",
      "NSC403412",
      "AC1L83AN",
      "SCHEMBL6645911",
      "DTXSID70323238",
      "BDBM50131013",
      "NSC-403412",
      "2,3,4,4a,5,10b-Hexahydro-1H-Phenanthridin-6-One",
      "1,3,4,4a,5,10b-Hexahydro-2H-Phenanthridin-6-One",
    ],
  },
  {
    primary_id: "D0FF5C",
    names: ["Quinoline Carboxamide Derivative 4"],
  },
  {
    primary_id: "D0FF6H",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 6"],
  },
  {
    primary_id: "D0FF6S",
    names: [
      "A-803467",
      "944261-79-4",
      "A 803467",
      "UNII-339LBH1395",
      "A803467",
      "5-(4-Chlorophenyl)-N-(3,5-Dimethoxyphenyl)Furan-2-Carboxamide",
      "5-(4-CHLOROPHENYL)-N-(3,5-DIMETHOXYPHENYL)-2-FURANCARBOXAMIDE",
      "CHEMBL250699",
      "339LBH1395",
      "5-4(-Chlorophenyl)-N-(3,5-Dimethoxyphenyl)Furan-2-Carboxamide",
      "MLS006011118",
      "GTPL5734",
      "SCHEMBL9275137",
      "CTK5H6562",
      "EX-A083",
      "DTXSID90241480",
      "AOB2888",
      "MolPort-006-069-060",
      "HMS3656L08",
      "BCP04519",
      "2-Furancarboxamide, 5-(4-Chlorophenyl)-N-(3,5-Dimethoxyphenyl)-",
      "BN0738",
      "ABP001000",
      "ZINC14982453",
      "MFCD10574689",
      "BDBM50212239",
      "S2785",
    ],
  },
  {
    primary_id: "D0FF7A",
    names: ["Z-6568"],
  },
  {
    primary_id: "D0FF9F",
    names: [
      "5-Butyl-1H-Pyrazole-3-Carboxylic Acid",
      "92933-48-7",
      "5-Butyl-2H-Pyrazole-3-Carboxylic Acid",
      "3-Butyl-1H-Pyrazole-5-Carboxylic Acid",
      "CHEMBL428730",
      "890624-89-2",
      "3-Butylpyrazole-5-Carboxylic Acid",
      "5-N-Butyl-1H-Pyrazole-3-Carboxylic Acid",
      "BAS 13299640",
      "AC1O5JK4",
      "GTPL1591",
      "SCHEMBL1527555",
      "CTK8F6771",
      "CTK6D6234",
      "MolPort-027-640-734",
      "ZJTXSGLJNBAMJS-UHFFFAOYSA-N",
      "MolPort-002-026-457",
      "ZX-AV000908",
      "ZINC4386887",
      "STL257894",
      "BDBM50220842",
      "BBL030719",
      "2213AF",
      "SBB027985",
      "AKOS024458395",
      "5-Butyl-1H-Pyrazol-3-Carboxylic Acid",
      "AKOS000301963",
      "CHM0075795",
    ],
  },
  {
    primary_id: "D0FG2H",
    names: ["PMID29338548-Compound-26"],
  },
  {
    primary_id: "D0FG2Z",
    names: ["NC-6004"],
  },
  {
    primary_id: "D0FG4C",
    names: ["Epoxysuccinate Derivative 10"],
  },
  {
    primary_id: "D0FG6E",
    names: ["Osteoporosis Therapy, Entera Bio Ltd"],
  },
  {
    primary_id: "D0FG6M",
    names: ["GW642444"],
  },
  {
    primary_id: "D0FG6T",
    names: ["NRP-388"],
  },
  {
    primary_id: "D0FH1V",
    names: ["2-Methylsulfanylestradiol 3,17-O,O-Bis-Sulfamate", "CHEMBL219273"],
  },
  {
    primary_id: "D0FH3M",
    names: ["RPR-108514A", "CHEMBL543669"],
  },
  {
    primary_id: "D0FH3X",
    names: [
      "A-437203",
      "ABT-925 Anhydrous Free Base",
      "220519-06-2",
      "UNII-E6CKI5C54O",
      "E6CKI5C54O",
      "CHEMBL439338",
      "2-((3-(4-(2-(Tert-Butyl)-6-(Trifluoromethyl)Pyrimidin-4-Yl)Piperazin-1-Yl)Propyl)Thio)Pyrimidin-4(3H)-One",
      "ABT-925 Free Base",
      "SCHEMBL1043144",
      "DTXSID70176529",
      "BDBM50177354",
      "HY-U00185",
      "AKOS027334456",
      "CS-7258",
      "LU-201640",
      "LU 201640",
      "A 37203",
      "A-37203",
      "L018707",
      "4(3H)-Pyrimidinone, 2-((3-(4-(2-(1,1-Dimethylethyl)-6-(Trifluoromethyl)-4-Pyrimidinyl)-1-Piperazinyl)Propyl)Thio)-",
    ],
  },
  {
    primary_id: "D0FH4G",
    names: ["Gold-Complexed Thiosaccharide Derivative 1"],
  },
  {
    primary_id: "D0FH5A",
    names: [
      "2-Methyl-9H-Indeno[2,1-D]pyrimidin-9-One",
      "9H-Indeno[2,1-D]pyrimidin-9-One, 2-Methyl-",
      "CHEMBL392622",
      "Aza-Heterocyclic Derivative, 2a",
      "BDBM19174",
    ],
  },
  {
    primary_id: "D0FH5K",
    names: ["N-Substituted 9-Azabicyclo[3.3.1]nonan-3alpha-Yl-Phenylcarbamate Analog 2"],
  },
  {
    primary_id: "D0FH5N",
    names: ["IW-6118"],
  },
  {
    primary_id: "D0FH6P",
    names: ["PMID28454500-Compound-34"],
  },
  {
    primary_id: "D0FH7B",
    names: ["AH 11110", "AH11110", "AH-11110"],
  },
  {
    primary_id: "D0FH7C",
    names: ["Gold Sodium Thiomalate"],
  },
  {
    primary_id: "D0FH8R",
    names: ["DB-160", "Dipeptidyl Peptidase IV Inhibitor (Oral, Type 2 Diabetes), DARA BioSciences"],
  },
  {
    primary_id: "D0FH9J",
    names: ["PMID18334293C15b"],
  },
  {
    primary_id: "D0FH9O",
    names: ["SDX-7500"],
  },
  {
    primary_id: "D0FI1I",
    names: ["3-Methoxy-4-(O-Tolyloxy)Benzonitrile", "CHEMBL449867", "SCHEMBL4183144"],
  },
  {
    primary_id: "D0FI1K",
    names: ["ARX-212"],
  },
  {
    primary_id: "D0FI1L",
    names: ["CAD-1883"],
  },
  {
    primary_id: "D0FI1W",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 10"],
  },
  {
    primary_id: "D0FI2F",
    names: ["2-(Benzyloxy)-N7-Hydroxy-N1-Phenylheptanediamide", "CHEMBL402718", "SCHEMBL8152458"],
  },
  {
    primary_id: "D0FI2L",
    names: ["8-Aminoguanosine"],
  },
  {
    primary_id: "D0FI3A",
    names: ["PMID28394193-Compound-31"],
  },
  {
    primary_id: "D0FI3Q",
    names: ["Biaryl Mannoside Derivative 13"],
  },
  {
    primary_id: "D0FI4X",
    names: ["2-(3-Aminophenoxy)-5-Hexylphenol", "CHEMBL404984"],
  },
  {
    primary_id: "D0FI5C",
    names: ["MEDI-528"],
  },
  {
    primary_id: "D0FI7B",
    names: [
      "Trihydroxycholestane",
      "5beta-Cholestane-3alpha,7alpha,12alpha-Triol",
      "Trihydroxycoprostane",
      "3,7,12-Trihydroxycholestane",
      "547-96-6",
      "5-B-Cholestane-3a ,7a ,12a-Triol",
      "(3R,5S,7R,8R,9S,10S,12S,13R,14S,17R)-10,13-Dimethyl-17-[(2R)-6-Methylheptan-2-Yl]-2,3,4,5,6,7,8,9,11,12,14,15,16,17-Tetradecahydro-1H-Cyclopenta[a]phenanthrene-3,7,12-Triol",
      "3alpha,7alpha,12alpha-Trihydroxycoprostane",
      "3alpha,7alpha,12alpha-Trihydroxy-5beta-Cholestane",
      "3,7,12-Trihydroxycoprostane",
      "A,5",
      "A)-Cholestane-3,7,12-Triol",
      "AC1L4NRJ",
    ],
  },
  {
    primary_id: "D0FI8L",
    names: ["N1-Guanyl-1,7-Diaminoheptane"],
  },
  {
    primary_id: "D0FI9G",
    names: ["10-Hydroxy-Infractopicrin"],
  },
  {
    primary_id: "D0FI9M",
    names: ["ISIS 232828"],
  },
  {
    primary_id: "D0FJ0W",
    names: ["BAY 1026153"],
  },
  {
    primary_id: "D0FJ1I",
    names: [
      "N-(6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDIN-4-YL)-3-HYDROXYPROPANAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1083025",
      "BDBM50320176",
    ],
  },
  {
    primary_id: "D0FJ1N",
    names: ["PMID30107136-Compound-Example42"],
  },
  {
    primary_id: "D0FJ1W",
    names: ["DE-766"],
  },
  {
    primary_id: "D0FJ3N",
    names: ["Piperazine Carbamate/Urea Derivative 2"],
  },
  {
    primary_id: "D0FJ3P",
    names: [
      "GW-2592X",
      "Azepanone Inhibitors, GlaxoSmithKline",
      "Cathepsin K Inhibitors (Bone Disorder)",
      "Cathepsin K Inhibitors, SmithKline Beecham",
      "GW-2637X",
      "GW-2934X",
      "GW-9696X",
      "SB-237632",
      "SB-244875",
      "SB-267320",
      "SB-271194",
      "SB-327537",
      "SB-331750",
      "SB-357114",
      "SB-432238",
      "SB-553484",
      "Cathepsin K Inhibitors (Bone Disorder), GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D0FJ4A",
    names: ["Thiazole Carboxamide Derivative 18"],
  },
  {
    primary_id: "D0FJ4J",
    names: ["Oragens"],
  },
  {
    primary_id: "D0FJ7G",
    names: ["IDD594"],
  },
  {
    primary_id: "D0FJ8A",
    names: ["Deferoxamine Mesylate", "Desferal"],
  },
  {
    primary_id: "D0FJ8E",
    names: ["Peptide Analog 18"],
  },
  {
    primary_id: "D0FJ9I",
    names: [
      "Cotellic",
      "Cobimetinib Fumarate",
      "Cobimetinib Hemifumarate",
      "Xl-518 Hemifumarate",
      "GDC-0973 Hemifumarate",
      "UNII-6EXI96H8SV",
      "6EXI96H8SV",
      "Cobimetinib Fumarate [USAN]",
      "1369665-02-0",
      "Cobimetinib Fumarate (USAN)",
      "Cotellic (TN)",
      "CHEMBL2364607",
      "CHEBI:90853",
      "Methanone, (3,4-Difluoro-2-((2-Fluoro-4-Iodophenyl)Amino)Phenyl)(3-Hydroxy-3-(2S)-2-Piperidinyl-1-Azetidinyl)-, (2E)-2-Butenedioate (2:1)",
      "D10615",
      "Bis[(2S)-2-{1-[3,4-Difluoro-2-(2-Fluoro-4-Iodoanilino)Benzoyl]-3-Hydroxyazetidin-3-Yl}piperidin-1-Ium] (2E)-But-2-Enedioate",
    ],
  },
  {
    primary_id: "D0FJ9W",
    names: ["KB-004", "Anti-EphA3 Tyrosine Kinase Receptor Monoclonal Antibody (Humaneered, Iv, Cancer), KaloBios"],
  },
  {
    primary_id: "D0FK0A",
    names: [
      "2,4-Diamino-6-Phenyl-5,6,7,8,-Tetrahydropteridine",
      "(6R)-6-Phenyl-5,6,7,8-Tetrahydropteridine-2,4-Diamine",
      "AC1L9HFU",
    ],
  },
  {
    primary_id: "D0FK0Y",
    names: ["Heteroaryl-Azepine Derivative 8"],
  },
  {
    primary_id: "D0FK2Q",
    names: ["PMID28870136-Compound-47"],
  },
  {
    primary_id: "D0FK3W",
    names: ["2-(3-Bromophenyl)Pyrido[2,3-D]pyrimidine", "CHEMBL239197"],
  },
  {
    primary_id: "D0FK4P",
    names: ["PMID30107136-Compound-Example48"],
  },
  {
    primary_id: "D0FK5P",
    names: ["Pyrimidine Derivative 8"],
  },
  {
    primary_id: "D0FK5Z",
    names: [
      "N-(1-Phenethylpiperidin-4-Yl)-1-Naphthamide",
      "CHEMBL229021",
      "MolPort-027-939-937",
      "ZINC27938740",
      "BDBM50203472",
      "AKOS034266808",
      "MCULE-9720193295",
      "Z384293848",
      "N-[1-(2-Phenylethyl)Piperidin-4-Yl]naphthalene-1-Carboxamide",
    ],
  },
  {
    primary_id: "D0FK6A",
    names: ["TDI-0068"],
  },
  {
    primary_id: "D0FK6J",
    names: ["Lobatamide B"],
  },
  {
    primary_id: "D0FK6N",
    names: ["Peptide Analog 5"],
  },
  {
    primary_id: "D0FK6O",
    names: ["MIN-202"],
  },
  {
    primary_id: "D0FK6T",
    names: ["2-(4-Cyclopentyl-Piperazin-1-Yl)-Quinoline", "CHEMBL178608", "SCHEMBL3970736"],
  },
  {
    primary_id: "D0FK6Y",
    names: ["Quinoline And Quinazoline Derivative 10"],
  },
  {
    primary_id: "D0FK7E",
    names: ["Carbamate Derivative 14"],
  },
  {
    primary_id: "D0FK9H",
    names: ["Ontuxizumab"],
  },
  {
    primary_id: "D0FL0R",
    names: ["ALS-L1023"],
  },
  {
    primary_id: "D0FL0T",
    names: ["Imidazo Bicyclic Iminium Derivative 1"],
  },
  {
    primary_id: "D0FL1D",
    names: ["3-(1-Benzyl-Piperidin-3-Yl)-2-Phenyl-1H-Indole", "CHEMBL46523", "SCHEMBL8524973", "BDBM50099274"],
  },
  {
    primary_id: "D0FL1E",
    names: ["Resorcinol Compound 33"],
  },
  {
    primary_id: "D0FL2K",
    names: ["Cantrixil"],
  },
  {
    primary_id: "D0FL3H",
    names: ["PMID26651364-Compound-6a"],
  },
  {
    primary_id: "D0FL4G",
    names: [
      "DAF-3",
      "Dual-Specific Monoclonal Antibodies (Cancer)",
      "Dual-Specific Monoclonal Antibodies (Cancer), Genentech",
    ],
  },
  {
    primary_id: "D0FL5S",
    names: ["CART-20 Cells"],
  },
  {
    primary_id: "D0FL5V",
    names: [
      "Clobetasol",
      "Clobetasolum",
      "Clofenazon",
      "Clobecort Amex",
      "Butavate (TN)",
      "Clobecort Amex (TN)",
      "Clobetasol (INN)",
      "Clobetasol [INN:BAN]",
      "Clobetasolum [INN-Latin]",
      "Clobex (TN)",
      "Dermatovate (TN)",
      "Dermovate (TN)",
      "Movate (TN)",
      "Olux (TN)",
      "Temovate (TN)",
      "Tenovate (TN)",
      "(11beta,16beta)-21-Chloro-9-Fluoro-11,17-Dihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
      "(8S,9R,10S,11S,13S,14S,16S,17R)-17-(2-Chloroacetyl)-9-Fluoro-11,17-Dihydroxy-10,13,16-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "(9R,17R)-17-(2-Chloroacetyl)-9-Fluoro-11,17-Dihydroxy-10,13,16-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "17-(2-Chloroacetyl)-9-Fluoro-11,17-Dihydroxy-10,13,16-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "21-Chloro-9-Fluoro-11beta,17-Dihydroxy-16beta-Methylpregna-1,4-Diene-3,20-Dione",
      "21-Chloro-9-Fluoro-11,17-Dihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
    ],
  },
  {
    primary_id: "D0FL6C",
    names: ["HL-130"],
  },
  {
    primary_id: "D0FL6L",
    names: ["IMA202"],
  },
  {
    primary_id: "D0FL6P",
    names: ["Quinoline And Quinazoline Derivative 8"],
  },
  {
    primary_id: "D0FL6X",
    names: ["DS-8587"],
  },
  {
    primary_id: "D0FL7O",
    names: ["4-Biphenyl-4-Yl-2-(1-Pentyl-Hexyl)-1H-Imidazole", "CHEMBL332257", "SCHEMBL4817122"],
  },
  {
    primary_id: "D0FL8A",
    names: ["PMID28270021-Compound-WO2010077680 481"],
  },
  {
    primary_id: "D0FL9B",
    names: ["Magnesium/Zinc/Lithium Salts"],
  },
  {
    primary_id: "D0FM2P",
    names: [
      "Flunisolide",
      "Aerobid",
      "Aerospan",
      "Bronalide",
      "Flunisolida",
      "Flunisolidum",
      "Lunis",
      "Nasalide",
      "Nasarel",
      "Nisolid",
      "Rhinalar",
      "Rhinolar",
      "Soluzione",
      "Synaclyn",
      "Syntaris",
      "Aerobid M",
      "Aerospan HFA",
      "Flunisolide HFA",
      "Flunisolide [INN]",
      "Flunisolide [anhydrous]",
      "Flunisolide Anhydrous",
      "F0437",
      "RS 3999",
      "AeroBid (TN)",
      "Flunisolida [INN-Spanish]",
      "Flunisolide (Anhydrous)",
      "Flunisolidum [INN-Latin]",
      "Nasalide (TN)",
      "Nasarel (TN)",
      "RS-3999",
      "AeroBid, Nasalide, Nasarel,Flunisolide",
      "Pregna-1,4-Diene-3,20-Dione, 6-Alpha-Fluoro-11-Beta,16-Alpha,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone",
      "Pregna-1,4-Diene-3,20-Dione, 6-Alpha-Fluoro-11-Beta,16-Alpha,17,21-Tetrahydroxy-,cyclic 16,17-Acetal With Acetone",
      "6 Alpha-Fluoro-11 Beta,16 Alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione Cyclic 16, 17-Acetal With Acetone",
      "6 Alpha-Fluorodihydroxy-16 Alpha,17 Alpha-Isopropylidenedioxy-1,4-Pregnadiene-3,20-Dione",
      "6-Fluoro-11,21-Dihydroxy-16,17-(Isopropylidenedioxy)Pregna-1,4-Diene-3,20-Dione",
      "6alpha-Fluoro-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione Cyclic 16, 17-Acetal With Acetone",
      "6alpha-Fluoro-11beta,21-Dihydroxy-16alpha,17alpha-(Isopropylidenedioxy)Pregna-1,4-Diene-3,20-Dione",
    ],
  },
  {
    primary_id: "D0FM2W",
    names: [
      "Defibrotide",
      "Defitelio",
      "Defibrotide (Iv, Veno-Occlusive Disease)",
      "Defibrotide (Iv, Veno-Occlusive Disease), Gentium",
    ],
  },
  {
    primary_id: "D0FM3Q",
    names: ["PMID28454500-Compound-33"],
  },
  {
    primary_id: "D0FM4T",
    names: ["EC-0746"],
  },
  {
    primary_id: "D0FM5M",
    names: ["IMO-2125"],
  },
  {
    primary_id: "D0FM6W",
    names: ["PMID27109571-Compound-34"],
  },
  {
    primary_id: "D0FM6Y",
    names: ["PMID28447479-Compound-22"],
  },
  {
    primary_id: "D0FM7Z",
    names: ["BL-1021"],
  },
  {
    primary_id: "D0FM8Q",
    names: ["PMID27841045-Compound-134"],
  },
  {
    primary_id: "D0FM9V",
    names: ["PMID30107136-Compound-Example60"],
  },
  {
    primary_id: "D0FN1P",
    names: ["Saviprazole"],
  },
  {
    primary_id: "D0FN3U",
    names: ["PRL-3 Specific Mabs"],
  },
  {
    primary_id: "D0FN4K",
    names: ["XToll"],
  },
  {
    primary_id: "D0FN4T",
    names: ["RM-1929"],
  },
  {
    primary_id: "D0FN5D",
    names: ["2,2-Bis(4-Fluorophenyl)-N-Hydroxyacetamide", "CHEMBL573190", "SCHEMBL2841871"],
  },
  {
    primary_id: "D0FN6D",
    names: ["NGD-97-1", "CP-457,920"],
  },
  {
    primary_id: "D0FN6F",
    names: ["PMID26924192-Compound-25"],
  },
  {
    primary_id: "D0FN7C",
    names: ["NSC-54162"],
  },
  {
    primary_id: "D0FN7I",
    names: [
      "Des-Bromoaplysamine-1",
      "CHEMBL204377",
      "BDBM50177732",
      "SY246342",
      "MFCD30297079 (95%)",
      "3-[4-[2-(Dimethylamino)Ethyl]phenoxy-2,6-D2]-N,N-Dimethyl-1-Propanamine",
    ],
  },
  {
    primary_id: "D0FN7J",
    names: ["Methocarbamol"],
  },
  {
    primary_id: "D0FN8Y",
    names: ["Heteroaromatic Ring Derivative 4"],
  },
  {
    primary_id: "D0FN9X",
    names: ["CGEN-671", "Anti-CGEN-671 MAb (Cancer), Compugen"],
  },
  {
    primary_id: "D0FO1P",
    names: ["HL-021"],
  },
  {
    primary_id: "D0FO2B",
    names: [
      "OXYQUINOLINE",
      "8-HYDROXYQUINOLINE",
      "Quinolin-8-Ol",
      "8-Quinolinol",
      "148-24-3",
      "Oxyquinoline",
      "Oxine",
      "Quinophenol",
      "8-Quinol",
      "8-Oxyquinoline",
      "Phenopyridine",
      "Oxychinolin",
      "Bioquin",
      "Oxybenzopyridine",
      "Oxin",
      "Hydroxybenzopyridine",
      "1-Azanaphthalene-8-Ol",
      "Tumex",
      "8-Chinolinol",
      "8-Hydroxychinolin",
      "8-OQ",
      "8-Hydroxy-Chinolin",
      "Fennosan H 30",
      "Fennosan",
      "O-Oxychinolin",
      "8-Oxychinolin",
      "Usaf Ek-794",
      "Fennosan HF-15",
      "NCI-C55298",
      "NSC 2039",
      "Oxyquinoline [USAN]",
      "Caswell No. 719",
      "Oxoquinoline",
      "8-Chinolinol [Czech]",
      "O-Oxychinolin [German]",
      "Quinoline, 8-Hydroxy-",
      "NSC 615011",
      "UNII-5UTX5635HP",
    ],
  },
  {
    primary_id: "D0FO2K",
    names: [
      "N-(5,6-DIPHENYLFURO[2,3-D]PYRIMIDIN-4-YL)GLYCINE",
      "(5,6-Diphenyl-Furo[2,3-D]pyrimidin-4-Ylamino)-Acetic Acid",
      "701223-63-4",
      "DFW",
      "2-[(5,6-Diphenylfuro[2,3-D]pyrimidin-4-Yl)Amino]acetic Acid",
      "2-({5,6-Diphenylfuro[2,3-D]pyrimidin-4-Yl}amino)Acetic Acid",
      "2brh",
      "CBKinase1_018018",
      "Furanopyrimidine Compound 4",
      "CBKinase1_005618",
      "AC1LI48C",
      "SCHEMBL6748456",
      "CHEMBL363302",
      "BDBM14802",
      "MolPort-002-125-656",
      "ZINC383023",
      "AKOS015957210",
      "MCULE-9828982030",
      "DB07653",
      "L-3296",
      "SR-01000273355",
      "SR-01000273355-1",
      "BRD-K42410867-001-01-6",
      "F1684-0511",
    ],
  },
  {
    primary_id: "D0FO4C",
    names: ["Dihydropyrido Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0FO4J",
    names: [
      "3-(Butylthio)-1,1,1-Trifluoropropan-2-One",
      "3-Butylsulfanyl-1,1,1-Trifluoropropan-2-One",
      "34509-08-5",
      "CHEMBL402615",
      "AC1L4AHE",
      "3-Butylsulfanyl-1,1,1-Trifluoro-Propan-2-One",
      "CTK1C3366",
      "DTXSID90188068",
      "BDBM50371973",
    ],
  },
  {
    primary_id: "D0FO6Z",
    names: ["ASP-0892"],
  },
  {
    primary_id: "D0FO7G",
    names: ["Nintedanib"],
  },
  {
    primary_id: "D0FP0D",
    names: ["AZD-6088"],
  },
  {
    primary_id: "D0FP0R",
    names: ["Apaziquone"],
  },
  {
    primary_id: "D0FP1A",
    names: ["PTH 1-34"],
  },
  {
    primary_id: "D0FP4R",
    names: ["UR-13870"],
  },
  {
    primary_id: "D0FP5B",
    names: ["MK-8962"],
  },
  {
    primary_id: "D0FP5F",
    names: ["Isosorbide-Di-(Propylcarbamate)"],
  },
  {
    primary_id: "D0FP8S",
    names: [
      "Neugranin",
      "Albugranin",
      "CG-10639",
      "Granulocyte Colony Stimulating Factor (Long-Acting), CoGenesys",
      "Granulocyte Colony Stimulating Factor (Long-Acting), Teva Pharmaceutical",
      "Long-Acting G-CSF, HGS",
      "Long-Acting G-CSF, Teva Pharmaceutical",
      "G-SCF (Long-Acting), CoGenesys",
    ],
  },
  {
    primary_id: "D0FP9N",
    names: ["P11187"],
  },
  {
    primary_id: "D0FQ1Z",
    names: ["GNF-PF-4292"],
  },
  {
    primary_id: "D0FQ2N",
    names: [
      "3-Isothiocyanato-9H-Beta-Carboline",
      "CHEMBL53651",
      "9H-Pyrido[3,4-B]indole, 3-Isothiocyanato-",
      "CHEMBL2262044",
      "Beta-Carbolin-3-Yl Isothiocyanate",
      "BDBM50013788",
    ],
  },
  {
    primary_id: "D0FQ2U",
    names: ["Talabostat"],
  },
  {
    primary_id: "D0FQ3D",
    names: ["Nicotinyl Hydroxamic Acid Derivative 1"],
  },
  {
    primary_id: "D0FQ3G",
    names: ["Cyclo(-D-Tyr-D-Ala-L-Arg-L-Nal-Gly-)", "CHEMBL384429"],
  },
  {
    primary_id: "D0FQ3N",
    names: [
      "3,4-Dihydroxyxanthone",
      "3,4-Dihydroxy-Xanthone",
      "3,4-Dihydroxyxanthen-9-One",
      "CHEMBL446323",
      "39731-48-1",
      "AC1NX8OR",
      "SCHEMBL133422",
      "3,4-Dihydroxy-Xanthen-9-One",
      "CTK4I1796",
      "DTXSID20192815",
      "YFVCSEXMOBEPQB-UHFFFAOYSA-N",
      "3,4-Dihydroxy-9H-Xanthen-9-One",
      "9H-Xanthen-9-One,3,4-Dihydroxy-",
      "ZINC15119047",
      "BDBM50269645",
      "9H-Xanthen-9-One, 3,4-Dihydroxy-",
    ],
  },
  {
    primary_id: "D0FQ4M",
    names: ["Selenotifen", "BN-52256"],
  },
  {
    primary_id: "D0FQ4W",
    names: ["PMID25522065-Compound-13"],
  },
  {
    primary_id: "D0FQ4Z",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 6"],
  },
  {
    primary_id: "D0FQ6O",
    names: ["Etarotene"],
  },
  {
    primary_id: "D0FQ6V",
    names: ["PMID26161698-Compound-17"],
  },
  {
    primary_id: "D0FR1P",
    names: ["Benzoyl-Piperidine Derivative 1"],
  },
  {
    primary_id: "D0FR1Q",
    names: ["Sulfonylated Piperazine Derivative 3"],
  },
  {
    primary_id: "D0FR3B",
    names: ["PDE 4 Inhibitor (Inflammation)"],
  },
  {
    primary_id: "D0FR3J",
    names: ["CD19 Specific CAR T Cells"],
  },
  {
    primary_id: "D0FR3X",
    names: ["(S)-N2-{4-[(3-Chlorobenzyl)Oxy]benzyl}serinamide", "CHEMBL394347", "SCHEMBL2017824"],
  },
  {
    primary_id: "D0FR3Z",
    names: ["Aminoarylpyridine Derivative 1"],
  },
  {
    primary_id: "D0FR4S",
    names: [
      "4-(2-Aminoethyl)-1,2,5-Oxadiazol-3-Ol",
      "CHEMBL211176",
      "AKOS006351843",
      "4-(2-Aminoethyl)-1,2,5-Oxadiazole-3-Ol",
    ],
  },
  {
    primary_id: "D0FR5V",
    names: [
      "Alpha Crystallin Inhibiting IgA Mabs",
      "Alpha Crystallin Inhibiting IgA MAbs (Tuberculosis Infection)",
      "TBA-61;TBA-84",
      "Alpha Crystallin Inhibiting IgA MAbs (Tuberculosis Infection), Finlay Institute",
      "Anti-Tuberculosis Antibodies, Finlay Institute",
    ],
  },
  {
    primary_id: "D0FR6I",
    names: ["Cyanocobalamin", "68-19-9"],
  },
  {
    primary_id: "D0FR6J",
    names: [
      "NC00075159",
      "2-{(5Z)-5-[4-(Dimethylamino)Benzylidene]-4-Oxo-2-Thioxo-1,3-Thiazolidin-3-Yl}propanoic Acid",
      "AC1NSO7A",
      "GTPL7664",
      "MolPort-000-500-763",
      "SMSF0008623",
      "2-[5-[(4-Dimethylaminophenyl)Methylidene]-4-Oxo-2-Sulfanylidene-1,3-Thiazolidin-3-Yl]propanoic Acid",
      "AKOS001607119",
      "AKOS016042842",
      "CB00089",
      "ST50147973",
      "AB00099445-01",
      "2-{5-[4-(Dimethylamino)Benzylidene]-4-Oxo-2-Thioxo-1,3-Thiazolidin-3-Yl}propanoic Acid",
      "2-[(5Z)-5-[(4-Dimethylaminophenyl)Methylidene]-4-Oxo-2-Sulfanylidene-1,3-Thiazolidin-3-Yl]propanoic Acid",
    ],
  },
  {
    primary_id: "D0FR7E",
    names: ["CX1789"],
  },
  {
    primary_id: "D0FR8L",
    names: ["Chlorobenzamide Derivative 2"],
  },
  {
    primary_id: "D0FR8Z",
    names: [
      "AcAsp-Glu-Met-Glu-Nal-Cyse",
      "CHEMBL64618",
      "Ac-Asp-Glu-Met-Glu-Cha-Cys-OH",
      "AC1LAAIY",
      "BDBM50096405",
      "AcAsp-Glu-Met-GLu-Cha(Beta-Cyclohexylalanine)-Cys",
      "Ac-Asp-Glu-Met-Glu-Beta-Cyclohexylalanine-Cys-OH",
      "Ac-L-Asp-L-Glu-L-Met-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "(4S)-4-[[(2S)-2-[[(2S)-2-[[(2S)-2-Acetamido-4-Hydroxy-4-Oxobutanoyl]amino]-5-Hydroxy-5-Oxopentanoyl]amino]-4-Methylsulfanylbutanoyl]amino]-5-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D0FR9I",
    names: ["Aryl Oxyanilide Derivative 3"],
  },
  {
    primary_id: "D0FR9L",
    names: ["Haem Arginate"],
  },
  {
    primary_id: "D0FS0D",
    names: ["1,2,3-Triazole Derivative 2"],
  },
  {
    primary_id: "D0FS0F",
    names: ["CK-301"],
  },
  {
    primary_id: "D0FS1B",
    names: ["Micro-Dystrophin Gene Therapy"],
  },
  {
    primary_id: "D0FS1J",
    names: ["Imidazo Bicyclic Iminium Derivative 4"],
  },
  {
    primary_id: "D0FS2X",
    names: ["PMID28067079-Compound-98"],
  },
  {
    primary_id: "D0FS3H",
    names: ["IDP-126 Gel"],
  },
  {
    primary_id: "D0FS3J",
    names: ["Heteroaryl-Azepine Derivative 15"],
  },
  {
    primary_id: "D0FS3L",
    names: [
      "4-Formylphenyl-O-Beta-D-Ribopyranoside",
      "CHEMBL499285",
      "4-Formylphenyl Beta-D-Ribopyranoside",
      "BDBM50245887",
    ],
  },
  {
    primary_id: "D0FS4S",
    names: ["Imidazo[1,2-B]pyridazine Derivative 4"],
  },
  {
    primary_id: "D0FS4V",
    names: ["Bip-Tyr-Thr-Pro-Thr(Obzl)-Gly", "CHEMBL374492"],
  },
  {
    primary_id: "D0FS6S",
    names: [
      "LX3305",
      "AMXVYJYMZLDINS-BWZBUEFSSA-N",
      "GTPL9851",
      "(1R,2S,3R)-1-[(2E)-2-(1-Nitrosoethylidene)-1,3-Dihydroimidazol-4-Yl]butane-1,2,3,4-Tetrol",
    ],
  },
  {
    primary_id: "D0FS8K",
    names: ["Biphenyl Carboxamidopropanoic Acid Derivative 3"],
  },
  {
    primary_id: "D0FS9K",
    names: ["MINAMESTANE"],
  },
  {
    primary_id: "D0FS9V",
    names: ["PMID26651364-Compound-120"],
  },
  {
    primary_id: "D0FS9X",
    names: ["Fused Thiophene Derivative 1"],
  },
  {
    primary_id: "D0FT0J",
    names: ["D-AP5", "D-AP-5", "D-Aminophosphonovaleric Acid"],
  },
  {
    primary_id: "D0FT1X",
    names: ["PMID25726713-Compound-59"],
  },
  {
    primary_id: "D0FT2Z",
    names: ["P1025", "Ac-Gln-Leu-Lys-Thr-Ala-Asp-Leu-Pro-Ala-Gly-Arg-Asp-Glu-Thr-Thr-Ser-Phe-Val-Leu-Val-NH2 Acetate"],
  },
  {
    primary_id: "D0FT3R",
    names: ["Tildrakizumab"],
  },
  {
    primary_id: "D0FT4P",
    names: ["Aryl Mannoside Derivative 3"],
  },
  {
    primary_id: "D0FT4R",
    names: [
      "Sivelestat",
      "127373-66-4",
      "UNII-DWI62G0P59",
      "2-[[2-[[4-(2,2-Dimethylpropanoyloxy)Phenyl]sulfonylamino]benzoyl]amino]acetic Acid",
      "Elastase Inhibitor IV",
      "C20H22N2O7S",
      "N-(2-(4-(2,2-Dimethylpropionyloxy)Phenylsulfonylamino)Benzoyl)Aminoacetic Acid",
      "Sivelestat (ONO-5046)",
      "LY544349",
      "CHEMBL76688",
      "LY 544349",
      "DWI62G0P59",
      "Glycine, N-(2-(((4-(2,2-Dimethyl-1-Oxopropoxy)Phenyl)Sulfonyl)Amino)Benzoyl)-",
      "N-(O-(P-Pivaloyloxybenzene)Sulfonylaminobenzoyl)Glycine",
    ],
  },
  {
    primary_id: "D0FT5T",
    names: ["CYP2001"],
  },
  {
    primary_id: "D0FT5V",
    names: ["(-)-Isoelaeocarpiline", "CHEMBL521711"],
  },
  {
    primary_id: "D0FT7Y",
    names: ["Ammonia", "Ammonia N13"],
  },
  {
    primary_id: "D0FT9J",
    names: ["Tyr-Pro-Tmp-Phe-NH", "CHEMBL389023"],
  },
  {
    primary_id: "D0FT9P",
    names: ["Talisomycin"],
  },
  {
    primary_id: "D0FU0I",
    names: ["Pyrrolo-Pyrimidine Derivative 3"],
  },
  {
    primary_id: "D0FU0K",
    names: ["5,7-Dichloro-3-Hydroxy-1H-Quinazoline-2,4-Dione"],
  },
  {
    primary_id: "D0FU0O",
    names: ["XmAb18087"],
  },
  {
    primary_id: "D0FU1J",
    names: ["PTG-100"],
  },
  {
    primary_id: "D0FU2M",
    names: ["Bicyclo-Heptan-2-Amine Derivative 3"],
  },
  {
    primary_id: "D0FU2V",
    names: [
      "D-237",
      "CHEMBL457025",
      "BDBM50273939",
      "(R)-6-((2-(4-Phenylpiperazin-1-Yl)Ethyl)(Propyl)Amino)-5,6,7,8-Tetrahydronaphthalen-1-Ol",
    ],
  },
  {
    primary_id: "D0FU5E",
    names: [
      "S-34324",
      "CHEMBL90997",
      "SCHEMBL6949357",
      "BDBM50131357",
      "4-(5-Fluoro-2-Indanylmethyl)-2-Imidazoline",
      "L001645",
    ],
  },
  {
    primary_id: "D0FU5Y",
    names: ["SequestOx"],
  },
  {
    primary_id: "D0FU6L",
    names: ["Quinoline Derivative 20"],
  },
  {
    primary_id: "D0FU7E",
    names: ["SCD-1 Inhibitor"],
  },
  {
    primary_id: "D0FU7Y",
    names: [
      "2-Hydroxycarbamoylmethyl-Pentanedioic Acid",
      "CHEMBL65043",
      "Hydroxamate, 41",
      "SCHEMBL1373534",
      "BDBM17779",
      "2-[(Hydroxycarbamoyl)Methyl]pentanedioic Acid",
      "2-[2-Oxo-2-(Hydroxyamino)Ethyl]pentanedioic Acid",
    ],
  },
  {
    primary_id: "D0FU8R",
    names: ["Anifrolumab"],
  },
  {
    primary_id: "D0FV1R",
    names: [
      "Anti-Heparanase Mabs",
      "Anti-Heparanase MAbs (Cancer)",
      "Anti-Heparanase MAbs (Cancer), AstraZeneca/Amgen",
      "10E9.1",
    ],
  },
  {
    primary_id: "D0FV3G",
    names: ["1-Aryl-4-Methyl-[1,2,4]triazolo[4,3-A]quinoxaline Derivative 6"],
  },
  {
    primary_id: "D0FV3K",
    names: [
      "AC0010",
      "Avitinib",
      "1557267-42-1",
      "N-(3-((2-((3-Fluoro-4-(4-Methylpiperazin-1-Yl)Phenyl)Amino)-7H-Pyrrolo[2,3-D]pyrimidin-4-Yl)Oxy)Phenyl)Acrylamide",
      "Avitinib Free Base",
      "SCHEMBL15453394",
      "KS-00000TON",
      "EX-A783",
      "MolPort-042-663-560",
      "Avitinib(AC0010/AC0010MA)",
      "ZINC142081723",
      "AKOS030632728",
      "CS-W008904",
      "N-(3-{2-[3-Fluoro-4-(4-Methyl-Piperazin-1-Yl)-Phenylamino]-7H-Pyrrolo[2,3-D]pyrimidin-4-Yloxy}-Phenyl)-Acrylamide",
      "DS-19269",
      "AK543905",
      "2-Propenamide, N-[3-[[2-[[3-Fluoro-4-(4-Methyl-1-Piperazinyl)Phenyl]amino]-7H-Pyrrolo[2,3-D]pyrimidin-4-",
    ],
  },
  {
    primary_id: "D0FV3V",
    names: [
      "Mepenzolate",
      "Cantil",
      "Cantilaque",
      "Cantilon",
      "Cantril",
      "Colibantil",
      "Colopiril",
      "Colum",
      "Delevil",
      "Eftoron",
      "Gastropidil",
      "Glycophenylate",
      "Mepenzolon",
      "Tralanta",
      "Trancolon",
      "Trokonil",
      "Bromure De Mepenzolate",
      "Bromuro De Mepenzolato",
      "MEPENZOLATE BROMIDE",
      "Mepenzolate Methylbromide",
      "Mepenzolati Bromidum",
      "Mepenzolic Acid",
      "Mepenzolone Bromide",
      "JB 340",
      "Bromure De Mepenzolate [INN-French]",
      "Bromuro De Mepenzolato [INN-Spanish]",
      "Cantil (TN)",
      "Mepenzolati Bromidum [INN-Latin]",
      "Mepenzolic Acid, Bromine Salt",
      "Mepenzolate Bromide (JP15/INN)",
      "Mepenzolate Bromide [INN:BAN:JAN]",
      "N-Methyl-3-Piperidyl Benzilate Methobromide",
      "N-Methyl-3-Piperidyl Benzilate Methyl Bromide",
      "N-Methyl-3-Piperidyldiphenylglycolate Methobromide",
      "N-Methyl-3-Piperidylbenzilate Methyl Bromide",
      "Benzilic Acid, 1-Methyl-3-Piperidyl Ester Methobromide",
      "Benzilic Acid, Ester With 3-Hydroxy-1,1-Dimethylpiperidinium Bromide",
      "Piperidinium, 3-[(Hydroxydiphenylacetyl)Oxy]-1,1-Dimethyl-, Bromide",
      "Piperidinium, 3-Hydroxy-1,1-Dimethyl-, Benzilate (Ester)",
      "Piperidinium, 3-Hydroxy-1,1-Dimethyl-, Bromide, Benzilate",
      "Piperidinium, 3-((Hydroxydiphenylacetyl)Oxy)-1,1-Dimethyl-(VAN)",
      "Piperidinium, 3-((Hydroxydiphenylacetyl)Oxy)-1,1-Dimethyl-, Bromide",
      "(1,1-Dimethylpiperidin-1-Ium-3-Yl) 2-Hydroxy-2,2-Diphenylacetate",
      "(1,1-Dimethylpiperidin-1-Ium-3-Yl) 2-Hydroxy-2,2-Diphenylacetate Bromide",
      "1-Methyl-3-Piperidyl Benzilate Methyl Bromide",
      "1-Methyl-3-Piperidyl Benzilate Methylbromide",
      "2-(1,1-Dimethylpiperidin-1-Ium-3-Yl)Oxy-2-Oxo-1,1-Diphenylethanolate",
      "2-[(1,1-Dimethylpiperidinium-3-Yl)Oxy]-2-Oxo-1,1-Diphenylethanolate",
      "3-((Hydroxydiphenylacetyl)Oxy)-1,1-Dimethylpiperidinium Bromide",
      "3-Hydroxy-1,1-Dimethylpiperidinium Bromide Benzilate",
      "3-{[hydroxy(Diphenyl)Acetyl]oxy}-1,1-Dimethylpiperidinium Bromide",
    ],
  },
  {
    primary_id: "D0FV6M",
    names: ["Deuterium-Substituted L-DOPA", "DP-102"],
  },
  {
    primary_id: "D0FV7K",
    names: ["4-((1H-Indol-1-Yl)Methyl)Benzenamine", "CHEMBL247281", "SCHEMBL14338710", "BDBM50203132"],
  },
  {
    primary_id: "D0FV8P",
    names: [
      "POL-6326",
      "CXCR4 Antagonists, Polyphor",
      "POL-2438",
      "POL-3026",
      "Epitope Mimetics (HIV Fusion), Polyphor",
      "CXCR4 Antagonists (Cancer/HIV), Polyphor",
    ],
  },
  {
    primary_id: "D0FV9R",
    names: ["PMID28350212-Compound-1"],
  },
  {
    primary_id: "D0FV9Y",
    names: ["PhSO2-Gly-(Me-Gly)-Arg-(2-Thiazole)", "CHEMBL403629"],
  },
  {
    primary_id: "D0FV9Z",
    names: ["Pyrazolodihydropyridine Derivative 1"],
  },
  {
    primary_id: "D0FW0B",
    names: ["ZP2929"],
  },
  {
    primary_id: "D0FW2A",
    names: ["LEO 80185"],
  },
  {
    primary_id: "D0FW2B",
    names: ["BAX-930"],
  },
  {
    primary_id: "D0FW2D",
    names: ["NX-101"],
  },
  {
    primary_id: "D0FW2W",
    names: [
      "4-(2,4-Dichloro-Phenoxy)-4'-Fluoro-Biphenyl-3-Ol",
      "2-(2,4-Dichlorophenoxy)-5-(4-Fluorophenyl)Phenol",
      "Triclosan Derivative, 19",
      "CHEMBL260979",
    ],
  },
  {
    primary_id: "D0FW3M",
    names: ["Heterocyclic Derivative 18"],
  },
  {
    primary_id: "D0FW5Q",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 10"],
  },
  {
    primary_id: "D0FW5U",
    names: ["PMID25828189-Compound-21"],
  },
  {
    primary_id: "D0FW8K",
    names: [
      "(RS)-(Tetrazol-5-Yl)Glycine",
      "138199-51-6",
      "Tet-Glycine",
      "Tetrazol-5-Yl-Gly",
      "5-Tetrazolyl-Glycine",
      "(Tetrazol-5-Yl)Glycine",
      "Tetrazolylglycine",
      "2-Amino-2-(1H-1,2,3,4-Tetrazol-5-Yl)Acetic Acid",
      "2-Amino-2-(2H-Tetrazol-5-Yl)Acetic Acid",
      "CHEBI:35003",
      "Alpha-Tetrazolylglycine",
      "LY 285 265",
      "Biomol-NT_000191",
      "C13738",
      "AC1L2Q8Q",
      "GTPL4068",
      "BPBio1_001214",
      "CHEMBL140784",
      "SCHEMBL10231795",
      "MolPort-023-275-877",
      "HMS3266G07",
      "AKOS006272482",
      "AKOS024048687",
      "NCGC00024531-02",
      "RT-015857",
      "2-Amino-2-(1H-Tetrazol-5-Yl)Acetic Acid",
      "T-205",
    ],
  },
  {
    primary_id: "D0FW8Z",
    names: ["Kybiqo"],
  },
  {
    primary_id: "D0FX2Q",
    names: ["Rifamycin"],
  },
  {
    primary_id: "D0FX3J",
    names: ["S-(N-Methyl-N-Hydroxycarbamoyl)Glutathione"],
  },
  {
    primary_id: "D0FX3P",
    names: ["ISIS 104838"],
  },
  {
    primary_id: "D0FX4J",
    names: ["Telenzepine"],
  },
  {
    primary_id: "D0FX4W",
    names: ["Iperoxo"],
  },
  {
    primary_id: "D0FX5D",
    names: [
      "Cx-602",
      "Allogeneic Adipose-Derived Mesenchymal Stem Cells (Endoscopic Delivery, IBD), Cellerix/Fundacion La Paz",
      "Allogeneic Adipose-Derived Mesenchymal Stem Cells (Endoscopic Delivery, IBD), TiGenix /Fundacion La Paz",
    ],
  },
  {
    primary_id: "D0FX6I",
    names: ["Benzamide Derivative 15"],
  },
  {
    primary_id: "D0FX6P",
    names: ["Dextranomer"],
  },
  {
    primary_id: "D0FX7I",
    names: ["Isoquinoline Sulfonyl Derivative 2"],
  },
  {
    primary_id: "D0FX7U",
    names: ["IGF-Drug Conjugates"],
  },
  {
    primary_id: "D0FX8A",
    names: ["Tenecteplase"],
  },
  {
    primary_id: "D0FX8N",
    names: ["CT1812", "Elayta"],
  },
  {
    primary_id: "D0FX9D",
    names: ["Tetrazole Acetic Acid Derivative 1"],
  },
  {
    primary_id: "D0FY0D",
    names: ["HD-201"],
  },
  {
    primary_id: "D0FY1Q",
    names: ["PMID29473428-Compound-52"],
  },
  {
    primary_id: "D0FY1W",
    names: ["Thymidine Kinase-Expressing Adenovirus And Ganciclovir Suicide Gene Therapy"],
  },
  {
    primary_id: "D0FY2N",
    names: ["NPRx-30"],
  },
  {
    primary_id: "D0FY2Q",
    names: ["Pyridine Derivative 10"],
  },
  {
    primary_id: "D0FY3O",
    names: ["Rabies Immune Globulin"],
  },
  {
    primary_id: "D0FY4J",
    names: ["BBI-6000"],
  },
  {
    primary_id: "D0FY4L",
    names: ["SAR438335"],
  },
  {
    primary_id: "D0FY4M",
    names: ["Echinocandin"],
  },
  {
    primary_id: "D0FY5E",
    names: ["TAI-GPC3-CART Cells"],
  },
  {
    primary_id: "D0FY6N",
    names: ["[N40,Pro1,Tyr4]BB"],
  },
  {
    primary_id: "D0FY6Q",
    names: [
      "JTK-109",
      "CHEMBL379677",
      "2-[4-(Benzyloxy)Phenyl]-1-Cyclohexyl-1h-Benzimidazole-5-Carboxylic Acid",
      "AC1LAMB2",
      "SCHEMBL791364",
      "CTK7I8521",
      "GWCTUYUNVJTNJR-UHFFFAOYSA-N",
      "MolPort-039-347-685",
      "BDBM50181950",
      "ZINC28572342",
      "AKOS027470656",
      "2-(4-Benzyloxyphenyl)-1-Cyclohexyl-Benzimidazole-5-Carboxylic Acid",
      "1-Cyclohexyl-2-(4-Phenylmethoxyphenyl)Benzimidazole-5-Carboxylic Acid",
      "2-(4-(Benzyloxy)Phenyl)-1-Cyclohexyl-1H-Benzo[d]imidazole-5-Carboxylic Acid",
      "1H-Benzimidazole-5-Carboxylic Acid, 1-Cyclohexyl-2-[4-(Phenylmethoxy)Phenyl]-",
    ],
  },
  {
    primary_id: "D0FY8B",
    names: ["Carbamide Derivative 8"],
  },
  {
    primary_id: "D0FY9K",
    names: ["Quinazolinone Derivative 4"],
  },
  {
    primary_id: "D0FY9P",
    names: ["KPAX002"],
  },
  {
    primary_id: "D0FZ1H",
    names: ["Mycophenolic Acid/Nucleotide Derivative 4"],
  },
  {
    primary_id: "D0FZ1W",
    names: ["BTA-798", "Capsid-Binding Inhibitors, Biota", "Capsid-Binding (Anti-Rhinovirus) Agents, Biota"],
  },
  {
    primary_id: "D0FZ1Y",
    names: ["CART30"],
  },
  {
    primary_id: "D0FZ1Z",
    names: ["Doxorubicin-CEA Conjugate", "DOX-CEA", "Doxorubicin-CEA Conjugate, Immunomedics"],
  },
  {
    primary_id: "D0FZ4X",
    names: ["MitoPBN"],
  },
  {
    primary_id: "D0FZ5R",
    names: ["Imidazolidine-2,4-Dione Derivative 1"],
  },
  {
    primary_id: "D0FZ6A",
    names: ["Dodecylphosphate"],
  },
  {
    primary_id: "D0FZ7D",
    names: ["BsAb CG7-MICA"],
  },
  {
    primary_id: "D0FZ7Q",
    names: ["NanoPac"],
  },
  {
    primary_id: "D0FZ7W",
    names: [
      "2-Methylisoquinoline-1,3,4-Trione",
      "2-Methylisoquinoline-1,3,4(2H)-Trione",
      "21640-33-5",
      "2-Methyl-Isoquinoline-1,3,4-Trione",
      "2-Methyl-1,2,3,4-Tetrahydroisoquinoline-1,3,4-Trione",
      "N-Methylphthalonimide",
      "AC1LVZDH",
      "AC1Q3XNZ",
      "1,3,4(2H)-Isoquinolinetrione, 2-Methyl-",
      "CHEMBL381480",
      "SCHEMBL13442852",
      "Isoquinoline-1,3,4-Trione 2a",
      "CTK4E7387",
      "BDBM10248",
      "DTXSID90364926",
      "MolPort-001-798-932",
      "ZINC2030689",
      "CS-D0370",
      "FCH830488",
      "STK368698",
      "AKOS005444509",
      "MCULE-7985115369",
      "ACM21640335",
      "2-Methyl-1,3,4(2H)-Isoquinolinetrione",
      "AK325966",
    ],
  },
  {
    primary_id: "D0FZ8U",
    names: ["Glysopep"],
  },
  {
    primary_id: "D0FZ9N",
    names: ["TDI-0049"],
  },
  {
    primary_id: "D0G0AI",
    names: ["Obizur"],
  },
  {
    primary_id: "D0G0AQ",
    names: ["ADC-1012"],
  },
  {
    primary_id: "D0G0BJ",
    names: [
      "VE-821",
      "ATR Serine/Threonine Protein Kinase Inhibitors (Cancer), Oxford University",
      "ATR Serine/Threonine Protein Kinase Inhibitors (Cancer), Vertex Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0G0BL",
    names: ["AMG 337"],
  },
  {
    primary_id: "D0G0CJ",
    names: ["2-(N-Cyclopentylamino)-3'-Bromopropiophenone", "CHEMBL599846", "BDBM50308113"],
  },
  {
    primary_id: "D0G0CX",
    names: [
      "MCC-257",
      "5-Acetamido-N-(Cholestan-3alpha-Yl)-3,5-Dideoxy-2-O-Methyl-Alpha-D-Glycero-D-Galacto-2-Nonulopyranosonic Acid Amide",
    ],
  },
  {
    primary_id: "D0G0EA",
    names: ["AGN-190744", "130162-96-8"],
  },
  {
    primary_id: "D0G0ED",
    names: ["3-Sal-Cyclosal-D4TMP", "CHEMBL373537", "(Sp)-3-Sal-Cyclosal-D4TMP", "(Rp)-3-Sal-Cyclosal-D4TMP"],
  },
  {
    primary_id: "D0G0EE",
    names: [
      "3-(1H-Imidazol-4-Yl)Benzenethiol",
      "1048983-24-9",
      "CHEMBL446387",
      "SCHEMBL1501883",
      "BDBM24665",
      "3-(1H-Imidazol-4-Yl)Benzene-1-Thiol",
      "3-(1H-Imidazol-4-Yl)Benzenethiol, 17",
    ],
  },
  {
    primary_id: "D0G0EG",
    names: ["PMID26560530-Compound-15"],
  },
  {
    primary_id: "D0G0ER",
    names: ["DWJ-208"],
  },
  {
    primary_id: "D0G0FJ",
    names: ["2-Hydrazinylbenzenesulfonamide", "2-Hydrazino-Benzenesulfonamide"],
  },
  {
    primary_id: "D0G0FK",
    names: ["Tricyclic Compound 1"],
  },
  {
    primary_id: "D0G0FQ",
    names: ["IMA-950"],
  },
  {
    primary_id: "D0G0GI",
    names: [
      "DMP-696",
      "DMP 696",
      "DMP696",
      "CHEMBL44698",
      "SCHEMBL2931046",
      "GTPL3499",
      "ZINC1489783",
      "PDSP1_001296",
      "PDSP2_001280",
      "BDBM50084875",
      "CS-6810",
      "HY-12131",
      "8-(2,4-Dichlorophenyl)-N-(1,3-Dimethoxypropan-2-Yl)-2,7-Dimethylpyrazolo[1,5-A][1,3,5]triazin-4-Amine",
      "[8-(2,4-Dichloro-Phenyl)-2,7-Dimethyl-Pyrazolo[1,5-A][1,3,5]triazin-4-Yl]-(2-Methoxy-1-Methoxymethyl-Ethyl)-Amine",
      "N-[1-(Methoxymethyl)-2-Methoxyethyl]-2,7-Dimethyl-8-(2,4-Dichlorophenyl)Pyrazolo[1,5-A]-1,3,5-Triazine-4-Amine",
    ],
  },
  {
    primary_id: "D0G0GP",
    names: ["Carbon Dioxide Intranasal"],
  },
  {
    primary_id: "D0G0GZ",
    names: [
      "N-Methyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL606338",
      "52985-78-1",
      "CTK1E4142",
      "DTXSID00601293",
      "BDBM50308426",
      "ZINC13717066",
      "AKOS004108104",
      "N-Methyl-1''H-Phenothiazine-1''-Carboxamide",
      "10H-Phenothiazine-10-Carboxamide, N-Methyl-",
    ],
  },
  {
    primary_id: "D0G0HK",
    names: ["GNF-PF-4453"],
  },
  {
    primary_id: "D0G0HP",
    names: ["SALVINICIN B"],
  },
  {
    primary_id: "D0G0IA",
    names: ["Preflucel"],
  },
  {
    primary_id: "D0G0IQ",
    names: ["JL-18"],
  },
  {
    primary_id: "D0G0IZ",
    names: [
      "Labradimil",
      "Cereport",
      "Lobradimil",
      "Receptor Mediated Permeabilizer",
      "RMP 7",
      "DRG-0182",
      "RMP-7",
      "N2-((S)-2-(L-Arginyl-L-Prolyl-Trans-4-Hydroxy-L-Prolylglycyl-3-(2-Thienyl)-L-Alanyl-L-Seryl-L-Prolinamido)-3-(P-Methoxyphenyl)Propyl)-L-Arginine",
      "(S-(R*,R*))-L-Arginyl-L-Prolyl-Trans-4-Hydroxy-L-Prolyl-3-(2-Thienyl)-L-Alanylglycyl-L-Seryl-N-(2-((4-((Aminoiminomethyl)Amino)-1-Carboxybutyl)Amino)-1-((4-Methoxyphenyl)Methyl)Ethyl)-L-Prolinamide",
    ],
  },
  {
    primary_id: "D0G0JA",
    names: ["KNI-10255"],
  },
  {
    primary_id: "D0G0JC",
    names: ["N-(Pyridin-3-Ylmethyl)Aniline"],
  },
  {
    primary_id: "D0G0JO",
    names: ["ISIS 119282"],
  },
  {
    primary_id: "D0G0KC",
    names: [
      "Second-Generation Cannabinoids",
      "BF Cannabinoids, Pharmos",
      "BF-029",
      "BF-036",
      "BX Cannabinoids, Pharmos",
      "Dextrocannabinoid Platform, Pharmos",
      "PRS-211092",
      "PRS-211095",
      "PRS-211220",
      "PRS-211364",
      "PRS-211392",
      "PRS-211394",
      "Tricyclic Dextrocannabinoids, Pharmos",
      "Second-Generation Cannabinoids (Pain/Autoimmune)",
      "Second-Generation Cannabinoids (Pain/Autoimmune), Pharmos",
    ],
  },
  {
    primary_id: "D0G0KN",
    names: [
      "6,7-Dimethoxy-3-(4-Methoxy-Phenyl)-Quinoline",
      "CHEMBL70130",
      "SCHEMBL8501467",
      "ZINC8074",
      "BDBM50039071",
    ],
  },
  {
    primary_id: "D0G0KQ",
    names: ["(1H-Indol-2-Yl)(5-Phenoxy-1H-Indol-2-Yl)Methanone", "CHEMBL210425"],
  },
  {
    primary_id: "D0G0KZ",
    names: ["ZP-2571"],
  },
  {
    primary_id: "D0G0LH",
    names: ["S-312-D"],
  },
  {
    primary_id: "D0G0MB",
    names: ["MEDI7510"],
  },
  {
    primary_id: "D0G0MM",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0G0MZ",
    names: ["JZL184"],
  },
  {
    primary_id: "D0G0NB",
    names: [
      "2-(2,3-Dihydro-1H-Indol-5-Yl)-1-Methyl-Ethylamine",
      "CHEMBL32958",
      "1H-Indole-5-Ethanamine, 2,3-Dihydro-Alpha-Methyl-",
      "BDBM50025081",
    ],
  },
  {
    primary_id: "D0G0OF",
    names: ["7-(1H-Indol-5-Yloxy)-N-Hydroxyheptanamide", "CHEMBL426516"],
  },
  {
    primary_id: "D0G0OP",
    names: ["2-(4-Chlorobenzyloxyamino)-N-Hydroxypropanamide", "CHEMBL228229"],
  },
  {
    primary_id: "D0G0OV",
    names: ["2-Phenylethynyl-5,6,7,8-Tetrahydro-Quinoline", "CHEMBL253160"],
  },
  {
    primary_id: "D0G0PI",
    names: ["CD38 And BCMA CAR-T Cells"],
  },
  {
    primary_id: "D0G0PU",
    names: ["RO-5212054"],
  },
  {
    primary_id: "D0G0RT",
    names: [
      "Carmoterol",
      "147568-66-9",
      "8-Hydroxy-5-((R)-1-Hydroxy-2-(((R)-1-(4-Methoxyphenyl)Propan-2-Yl)Amino)Ethyl)Quinolin-2(1H)-One",
      "UNII-9810NUL4D1",
      "CHEMBL1094785",
      "9810NUL4D1",
      "2(1h)-Quinolinone, 8-Hydroxy-5-[(1r)-1-Hydroxy-2-[[(1r)-2-(4-Methoxyphenyl)-1-Methylethyl]amino]ethyl]-",
      "8-Hydroxy-5-[(1R)-1-Hydroxy-2-{[(2R)-1-(4-Methoxyphenyl)Propan-2-Yl]amino}ethyl]-1,2-Dihydroquinolin-2-One",
      "Carmoterol [INN]",
      "8-Hydroxy-5-[(1R)-1-Hydroxy-2-[[(2R)-1-(4-Methoxyphenyl)Propan-2-Yl]amino]ethyl]-1H-Quinolin-2-One",
      "AC1Q6MT1",
    ],
  },
  {
    primary_id: "D0G0TP",
    names: [
      "3-(6-Amino-Purin-9-Yl)-Non-5-Yn-2-Ol",
      "CHEMBL311182",
      "BDBM50288071",
      "(2S,3R)-3-(6-Amino-Purin-9-Yl)-Non-5-Yn-2-Ol",
    ],
  },
  {
    primary_id: "D0G0TS",
    names: ["Meclinertant"],
  },
  {
    primary_id: "D0G0TT",
    names: ["RG7410"],
  },
  {
    primary_id: "D0G0TW",
    names: ["Rociletinib;Hm61713"],
  },
  {
    primary_id: "D0G0UZ",
    names: [
      "SBP-002",
      "Coramsine",
      "Solasonine + Solamargine",
      "Solasodine Glycosides (Cancer, Injectable Formulation), Solbec",
      "Solasodine Glycosides (Psoriasis, Cream Formulation), Solbec",
      "Solasonine + Solamargine (Cancer), Solbec",
      "Solasonine + Solamargine (Psoriasis), Solbec",
      "SBP-002 (Intravenous, Cancer), Solbec",
      "SBP-002 (Psoriasis, Cream Formulation), Solbec",
    ],
  },
  {
    primary_id: "D0G0VV",
    names: [
      "Bafetinib",
      "859212-16-1",
      "INNO-406",
      "NS-187",
      "UNII-NVW4Z03I9B",
      "CNS-9",
      "NVW4Z03I9B",
      "INNO406",
      "CHEMBL206834",
      "(S)-N-(3-([4,5'-Bipyrimidin]-2-Ylamino)-4-Methylphenyl)-4-((3-(Dimethylamino)Pyrrolidin-1-Yl)Methyl)-3-(Trifluoromethyl)Benzamide",
      "4-[[(3S)-3-DIMETHYLAMINOPYRROLIDIN-1-YL]METHYL]-N-[4-METHYL-3-[(4-PYRIMIDIN-5-YLPYRIMIDIN-2-YL)AMINO]PHENYL]-3-(TRIFLUOROMETHYL)BENZAMIDE",
      "INNO 406",
      "NS 187",
      "N-[3-(4,5'-Bipyrimidin-2-Ylamino)-4-Methylphenyl]-4-{[(3s)-3-(Dimethylamino)Pyrrolidin-1-Yl]methyl}-3-(Trifluoromethyl)Benzamide",
    ],
  },
  {
    primary_id: "D0G0WC",
    names: ["2-(3-Bromophenyl)-7-Methyl-1,8-Naphthyridine", "CHEMBL237906"],
  },
  {
    primary_id: "D0G0WP",
    names: ["CGNLSTCBLGTYTQDFNKFHZYPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D0G0XB",
    names: [
      "3,2'-Bis-Trifluoromethyl-Biphenyl-4-Carbonitrile",
      "CHEMBL396656",
      "BDBM50220379",
      "582293-27-4",
      "3,2''-Bis-Trifluoromethyl-Biphenyl-4-Carbonitrile",
    ],
  },
  {
    primary_id: "D0G0XN",
    names: [
      "UK-156406",
      "1-[3-(3-Amidinophenyl)-2(S)-(2-Methyl-1,2,3,4-Tetrahydroisoquinolin-7-Ylsulfonamido)Propionyl]-4-Methyl-1,2,3,6-Tetrahydropyridine-2(R)-Carboxylic Acid",
      "1-[3-Amidino-N-(2-Methyl-1,2,3,4-Tetrahydroisoquinolin-7-Ylsulfonyl)-L-Phenylalanyl]-4-Methyl-1,2,3,6-Tetrahydropyridine-2(R)-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0G0YL",
    names: ["LT-NS001"],
  },
  {
    primary_id: "D0G0YT",
    names: ["HCGRPalpha"],
  },
  {
    primary_id: "D0G0YZ",
    names: ["UK-356202", "Compound 13j [PMID: 15149680]", "UK-356,202"],
  },
  {
    primary_id: "D0G0ZE",
    names: ["TTP-547"],
  },
  {
    primary_id: "D0G0ZI",
    names: [
      "Benzo[c][1,2]oxaborol-1(3H)-Ol",
      "5735-41-1",
      "1-Hydroxy-2,1-Benzoxaborolane",
      "2,1-Benzoxaborol-1(3h)-Ol",
      "Monoester",
      "1,3-Dihydro-1-Hydroxy-2,1-Benzoxaborole",
      "1,3-DIHYDRO-2,1-BENZOXABOROL-1-OL",
      "2,1-Benzoxaborole, 1,3-Dihydro-1-Hydroxy-",
      "CHEBI:78238",
      "1-Hydroxy-3H-2,1-Benzoxaborole",
      "2-(Hydroxymethyl)Phenylboronic Acid Cyclic Monoester",
      "2-(HYDROXYMETHYL)PHENYLBORONIC ACID, DEHYDRATE",
      "KSC-6-288",
      "NSC719278",
      "PubChem5079",
      "2,1-Benzoxaborol-1-Ol",
      "2-Oxa-1-Boraindan-1-Ol",
      "AMTB152",
      "C7H7BO2",
      "3H-2,1-Benzoxaborol-1-Ol",
      "1-Hydroxy-2,1-Benzoxaborole",
    ],
  },
  {
    primary_id: "D0G0ZQ",
    names: ["6,7-Dimethoxy-3-Thiophen-2-Yl-Quinoline", "CHEMBL304336", "SCHEMBL8501345", "ZINC3834034"],
  },
  {
    primary_id: "D0G1AA",
    names: ["AKP-001"],
  },
  {
    primary_id: "D0G1AF",
    names: ["KNI-10155"],
  },
  {
    primary_id: "D0G1AK",
    names: ["UR-12510"],
  },
  {
    primary_id: "D0G1AQ",
    names: ["KALA-617"],
  },
  {
    primary_id: "D0G1CB",
    names: ["PRO-105"],
  },
  {
    primary_id: "D0G1CF",
    names: ["PMID25482888-Compound-71"],
  },
  {
    primary_id: "D0G1CK",
    names: [
      "PD-140723",
      "CHEMBL317675",
      "CHEMBL141630",
      "PDSP1_000911",
      "AC1NSKGW",
      "BDBM50281277",
      "PDSP2_000897",
      "BDBM50061990",
      "(3R)-3-[[(2R)-2-(2-Adamantyloxycarbonylamino)-3-(1H-Indol-3-Yl)-2-Methylpropanoyl]amino]-4-Phenylbutanoic Acid",
      "(R)-3-[(R)-2-(Adamantan-2-Yloxycarbonylamino)-3-(1H-Indol-3-Yl)-2-Methyl-Propionylamino]-4-Phenyl-Butyric Acid",
    ],
  },
  {
    primary_id: "D0G1DN",
    names: ["3-(5-Phenyl-2-Thienyl)Phenol"],
  },
  {
    primary_id: "D0G1DS",
    names: ["5-Chloro-6-Hydrazinouracil Hydrochloride", "CHEMBL558777"],
  },
  {
    primary_id: "D0G1DW",
    names: [
      "N-[4'-(Trifluoromethyl)-4-Biphenylyl]sulfamide",
      "CHEMBL390629",
      "SCHEMBL5531754",
      "WELQTBDQZUFHMC-UHFFFAOYSA-N",
      "ZINC28711911",
      "BDBM50220182",
    ],
  },
  {
    primary_id: "D0G1EA",
    names: [
      "SLP Vaccine",
      "Cervical Cancer Vaccine, Leiden University Medical Center",
      "SLP Vaccine (Cervical Cancer)",
      "SLP Vaccine (Cervical Cancer), Leiden University Medical Center",
      "Synthetic Long Peptide Vaccine (Cervical Cancer), Leiden University Medical Center",
    ],
  },
  {
    primary_id: "D0G1EE",
    names: [
      "ATRINOSITOL",
      "Atrinositol",
      "Atrinositol [INN]",
      "UNII-VYF3049W3N",
      "VYF3049W3N",
      "D-Myo-Inositol 1,2,6-Tris(Dihydrogen Phosphate)",
      "28841-62-5",
      "PP56",
      "Alpha-Trinositol",
      "AC1L23WT",
      "SCHEMBL636077",
      "Inositol 1,2,6-Triphosphate",
      "CHEMBL2106016",
      "Myo-Inositol 1,2,6-Triphosphate",
      "GKDKOMAJZATYAY-UOTPTPDRSA-N",
      "GKDKOMAJZATYAY-GCVPSNMTSA-N",
      "PP-56",
      "C6H15O15P3",
      "D-Myo-Inositol-126-Triphosphate",
      "ZINC71789368",
      "LS-84057",
      "D-Chiro-Inositol 1,2,6-Trisphosphoric Acid",
      "D-Myo-Inositol-1,2,6-Tris(Hydrogen Phosphate)",
    ],
  },
  {
    primary_id: "D0G1EH",
    names: ["AEOL-10150", "AEOL-10112", "AEOL-10113", "AEOL-10113)", "AEOL-10123"],
  },
  {
    primary_id: "D0G1EX",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Phg-D-Pro-)", "CHEMBL428737"],
  },
  {
    primary_id: "D0G1GP",
    names: ["ISIS 2922"],
  },
  {
    primary_id: "D0G1HX",
    names: ["CLBS03"],
  },
  {
    primary_id: "D0G1IF",
    names: ["Naloxonazine"],
  },
  {
    primary_id: "D0G1IG",
    names: ["F-16-131I"],
  },
  {
    primary_id: "D0G1IV",
    names: ["Testogen TDS"],
  },
  {
    primary_id: "D0G1JJ",
    names: ["(S)-3-(1'-Adamantanecarbonyl)Amino-Caprolactam"],
  },
  {
    primary_id: "D0G1JO",
    names: ["Combidex"],
  },
  {
    primary_id: "D0G1JR",
    names: ["PMID28394193-Compound-57"],
  },
  {
    primary_id: "D0G1LN",
    names: ["SN-2"],
  },
  {
    primary_id: "D0G1LP",
    names: ["ALT-802"],
  },
  {
    primary_id: "D0G1LS",
    names: ["Chalcone Derivative 3"],
  },
  {
    primary_id: "D0G1ML",
    names: ["(35)S-Labeled Oligonucleotide"],
  },
  {
    primary_id: "D0G1MZ",
    names: ["CY-1787"],
  },
  {
    primary_id: "D0G1NW",
    names: ["PF-4308515"],
  },
  {
    primary_id: "D0G1OS",
    names: ["LT-0301", "Cancer Stem Cell Differentiation Inducer (Cancer), LTT Bio-Pharma"],
  },
  {
    primary_id: "D0G1OW",
    names: ["Quercetin 3-O-Neohesperidoside"],
  },
  {
    primary_id: "D0G1OZ",
    names: ["Mephentermine"],
  },
  {
    primary_id: "D0G1QL",
    names: ["ET-008"],
  },
  {
    primary_id: "D0G1RA",
    names: ["Rhodamine-123"],
  },
  {
    primary_id: "D0G1RH",
    names: ["NPS-2143"],
  },
  {
    primary_id: "D0G1RM",
    names: ["Low-Dose Oral Interferon"],
  },
  {
    primary_id: "D0G1RT",
    names: [
      "L-694247",
      "137403-12-4",
      "L-694,247",
      "N-(4-((5-(3-(2-Aminoethyl)-1H-Indol-5-Yl)-1,2,4-Oxadiazol-3-Yl)Methyl)Phenyl)Methanesulfonamide",
      "L 694247",
      "2-[5-[3-(4-Methylsulfonylamino)Benzyl-1,2,4-Oxadiazol-5-Yl]-1H-Indol-3-Yl]ethanamine",
      "N-[4-[[5-[3-(2-Aminoethyl)-1H-Indol-5-Yl]-1,2,4-Oxadiazol-3-Yl]methyl]phenyl]methanesulfonamide",
      "Methanesulfonamide,N-[4-[[5-[3-(2-Aminoethyl)-1H-Indol-5-Yl]-1,2,4-Oxadiazol-3-Yl]methyl]phenyl]-",
      "Tocris-0781",
      "Biomol-NT_000112",
      "GTPL15",
      "AC1L30BK",
    ],
  },
  {
    primary_id: "D0G1SM",
    names: ["AZD-9335", "Analgesic (Neuropathic Pain), AstraZeneca"],
  },
  {
    primary_id: "D0G1SN",
    names: ["ED1"],
  },
  {
    primary_id: "D0G1SY",
    names: ["INP-05", "CNS Drug (Pressurized Olfactory Delivery, Parkinson's Disease), Impel NeuroPharma"],
  },
  {
    primary_id: "D0G1TC",
    names: ["Vaccine, Salmonella Infection"],
  },
  {
    primary_id: "D0G1TX",
    names: [
      "BA-011",
      "BA-011FZ041",
      "FZ-41",
      "HIV Integrase Inhibitors, BioAlliance",
      "Styryquinoline Derivatives (HIV Infection), BioAlliance",
    ],
  },
  {
    primary_id: "D0G1TY",
    names: ["CPG 52364"],
  },
  {
    primary_id: "D0G1UE",
    names: ["[3H]AMPA"],
  },
  {
    primary_id: "D0G1VG",
    names: ["HYG-202"],
  },
  {
    primary_id: "D0G1VS",
    names: ["UK-78282"],
  },
  {
    primary_id: "D0G1VX",
    names: [
      "BENZYL BENZOATE",
      "120-51-4",
      "Benylate",
      "Ascabiol",
      "Novoscabin",
      "Benzoic Acid, Phenylmethyl Ester",
      "Benzoic Acid Benzyl Ester",
      "Scobenol",
      "Scabitox",
      "Ascabin",
      "Benzyl Phenylformate",
      "Phenylmethyl Benzoate",
      "Scabiozon",
      "Scabagen",
      "Peruscabin",
      "Colebenz",
      "Scabanca",
      "Benzylets",
      "Benzoic Acid, Benzyl Ester",
      "Vanzoate",
      "Scabide",
      "Benzyl Benzenecarboxylate",
      "Benzylbenzoate",
      "Antiscabiosum",
      "Benzylis Benzoas",
      "Benzyl Alcohol Benzoic Ester",
      "Peruscabina",
      "Venzonate",
      "Spasmodin",
      "Benzylum Benzoicum",
      "Benzylester Kyseliny Benzoove",
      "Benzoesaeurebenzylester",
      "Caswell No. 082",
    ],
  },
  {
    primary_id: "D0G1WC",
    names: ["OC-L Vaccine"],
  },
  {
    primary_id: "D0G1WL",
    names: ["PITAVASTATIN CALCIUM"],
  },
  {
    primary_id: "D0G1WM",
    names: ["Influenza A Virus H7N1 Vaccine"],
  },
  {
    primary_id: "D0G1WR",
    names: ["DasKloster 0014-01", "DasKloster-0014"],
  },
  {
    primary_id: "D0G1WV",
    names: ["Thiophanate-Methyl"],
  },
  {
    primary_id: "D0G1XC",
    names: ["MHL Cyclohexylthiosemicarbazone"],
  },
  {
    primary_id: "D0G1XL",
    names: [
      "DSTBULET",
      "Dstbulet",
      "CHEMBL298972",
      "Tyrosyl-Seryl(O-Tert-Butyl)-Glycyl-Phenylalanyl-Leucyl-Threonine",
      "111035-56-4",
      "(2S,3R)-2-[[(2S)-2-[[(2S)-2-[[2-[[(2R)-2-[[(2S)-2-Amino-3-(4-Hydroxyphenyl)Propanoyl]amino]-3-[(2-Methylpropan-2-Yl)Oxy]propanoyl]amino]acetyl]amino]-3-Phenylpropanoyl]amino]-4-Methylpentanoyl]amino]-3-Hydroxybutanoic Acid",
      "Tyr-Ser(O-Tert-Bu)-Gly-Phe-Leu-Thr",
      "AC1L2XS7",
      "(Ser(2)(O-T-Butyl)-Leu(5))Enkephalyl-Thr(6)",
      "Leu(5) Enkephalin, Ser(2)(O-Tert-Butyl)-Thr(6)",
      "GTPL3873",
      "DTXSID40149447",
    ],
  },
  {
    primary_id: "D0G1XP",
    names: [
      "2-(Tert-Butylamino)-3',4'-Dichloropentanophenone",
      "CHEMBL566050",
      "SCHEMBL769423",
      "BDBM50302924",
      "J3.628.916C",
      "3',4'-Dichloro-Alpha-(Tert-Butylamino)Valerophenone",
      "2-(Tert-Butylamino)-3'',4''-Dichloropentanophenone",
    ],
  },
  {
    primary_id: "D0G1YA",
    names: ["PF-05212377"],
  },
  {
    primary_id: "D0G1YH",
    names: ["ND-201"],
  },
  {
    primary_id: "D0G1YI",
    names: ["Pyrimidine Derivative 13"],
  },
  {
    primary_id: "D0G1YN",
    names: [
      "DPI-3290",
      "DPI-3290W93",
      "(+)-3-[1(R)-[4-Allyl-2(S),5(R)-Dimethylpiperazin-1-Yl]-1-(3-Hydroxyphenyl)Methyl]-N-(3-Fluorophenyl)-N-Methylbenzamide",
      "3290W93",
    ],
  },
  {
    primary_id: "D0G1YO",
    names: ["GSK1521498"],
  },
  {
    primary_id: "D0G1YQ",
    names: ["PDE4 Inhibitors, Scottich Biomedical"],
  },
  {
    primary_id: "D0G1YR",
    names: ["TRI-1144"],
  },
  {
    primary_id: "D0G1ZU",
    names: ["EGFR CART"],
  },
  {
    primary_id: "D0G2AK",
    names: [
      "3-Methoxy-N',2-Diphenylquinoline-4-Carbohydrazide",
      "CHEMBL218717",
      "SCHEMBL3963611",
      "WQHSKTAHWLQAGK-UHFFFAOYSA-N",
      "BDBM50194593",
      "3-Methyloxy-N',2-Diphenyl-4-Quinolinecarbohydrazide",
    ],
  },
  {
    primary_id: "D0G2AV",
    names: ["DRH-12"],
  },
  {
    primary_id: "D0G2BN",
    names: ["Tyr-Pro-Phg-Phe-NH2", "CHEMBL211119"],
  },
  {
    primary_id: "D0G2BS",
    names: ["2-(4-Methyl-Piperazin-1-Yl)-Quinoline"],
  },
  {
    primary_id: "D0G2CG",
    names: [
      "AL3818",
      "Anlotinib",
      "1058156-90-3",
      "UNII-GKF8S4C432",
      "GKF8S4C432",
      "AL-3818",
      "SCHEMBL2063386",
      "GTPL9601",
      "KSMZEXLVHXZPEF-UHFFFAOYSA-N",
      "MolPort-044-567-604",
      "ZINC117924202",
      "AKOS030526233",
      "DB11885",
      "CS-5396",
      "AL 3818",
      "HY-19716",
      "1-[[4-[(4-Fluoro-2-Methyl-1H-Indol-5-Yl)Oxy]-6-Methoxyquinolin-7-Yl]oxymethyl]cyclopropan-1-Amine",
      "1-((4-(4-Fluoro-2-Methyl-1h-Indol-5-Yloxy)-6-Methoxyquinolin-7-Yloxy)Methyl)Cyclopropan-Amine",
      "Cyclopropanamine, 1-(((4-((4-Fluoro-2-Methyl-1H-Indol-5-Yl)Oxy)-6-Methoxy-7-Quinolinyl)Oxy)Methyl)-",
    ],
  },
  {
    primary_id: "D0G2CL",
    names: ["AG-2024"],
  },
  {
    primary_id: "D0G2CP",
    names: ["FAUC 113"],
  },
  {
    primary_id: "D0G2CQ",
    names: ["WC-3027"],
  },
  {
    primary_id: "D0G2CT",
    names: ["Indirubin-5-Sulfonate"],
  },
  {
    primary_id: "D0G2CY",
    names: [
      "(+/-)-3-Allylazetidin-2-One",
      "3-(2'-Propenyl)-2-Azetidinone",
      "1058693-04-1",
      "Allyl-Beta-Lactam",
      "CHEMBL447982",
    ],
  },
  {
    primary_id: "D0G2DQ",
    names: [
      "5-Nitro-1H-Indole-2,3-Dione",
      "5-Nitroisatin",
      "611-09-6",
      "5-Nitroindoline-2,3-Dione",
      "5-Nitroindole-2,3-Dione",
      "1H-Indole-2,3-Dione, 5-Nitro-",
      "N-Nitroisatin",
      "Isatin, 5-Nitro-",
      "INDOLE-2,3-DIONE, 5-NITRO-",
      "CCRIS 4031",
      "UNII-J4J633881Z",
      "5-Nitroisatin, 97%",
      "EINECS 210-252-5",
      "NSC 525798",
      "2,3-Dihydro-5-Nitroindole-2,3-Dione",
      "BRN 0180223",
      "CHEMBL118305",
      "UNMYHYODJHKLOC-UHFFFAOYSA-N",
      "J4J633881Z",
      "5-Nitro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "5-Nitro Isatin",
      "Isatin, 5-Nitro- (6CI)",
      "5-Nitroisatine",
      "Isatin Analog 3",
      "NSC525798",
      "PubChem15514",
      "Indole-2, 5-Nitro",
    ],
  },
  {
    primary_id: "D0G2DR",
    names: ["ANT-G12", "ANT-C7", "Cytotoxic Peptimetics (CSTa, Prostate Cancer), Antyra"],
  },
  {
    primary_id: "D0G2EF",
    names: ["8-Bromo-4,9-Dihydro-3H-Beta-Carboline", "CHEMBL6746", "BDBM50136503"],
  },
  {
    primary_id: "D0G2EP",
    names: ["Amidine Compound 6"],
  },
  {
    primary_id: "D0G2EQ",
    names: ["(1S,2S)-N-Arachidonoylcyclopropanolamide", "Cyclopropanolamide, 11a", "CHEMBL485369"],
  },
  {
    primary_id: "D0G2FV",
    names: ["Ultra-Rapid Insulin"],
  },
  {
    primary_id: "D0G2FY",
    names: ["Anti-CCR5 Mabs", "Anti-CCR5 MAbs (HIV)"],
  },
  {
    primary_id: "D0G2GA",
    names: ["Aminopurvalanol A"],
  },
  {
    primary_id: "D0G2GR",
    names: ["OP-2507"],
  },
  {
    primary_id: "D0G2GT",
    names: ["SGS-111"],
  },
  {
    primary_id: "D0G2GZ",
    names: ["XGP-120"],
  },
  {
    primary_id: "D0G2HT",
    names: ["1-Benzyl-4-[3-(Methylsulfonyl)Phenyl]piperidine", "CHEMBL596800", "SCHEMBL4825273"],
  },
  {
    primary_id: "D0G2IC",
    names: [
      "(S)-FLURBIPROFEN",
      "51543-39-6",
      "Esflurbiprofen",
      "(S)-(+)-2-Fluoro-Alpha-Methyl-4-Biphenylacetic Acid",
      "UNII-J5ZZK9P7MX",
      "(S)-2-(2-Fluorobiphenyl-4-Yl)Propanoic Acid",
      "(S)-2-(2-Fluoro-[1,1'-Biphenyl]-4-Yl)Propanoic Acid",
      "(2S)-2-(2-Fluorobiphenyl-4-Yl)Propanoic Acid",
      "J5ZZK9P7MX",
      "CHEMBL435298",
      "CHEBI:42446",
      "SYTBZMRGLBWNTM-JTQLQIEISA-N",
      "(2S)-2-(3-Fluoro-4-Phenylphenyl)Propanoic Acid",
      "BTS-24332",
      "(S)-2-Fluoro-Alpha-Methyl-4-Biphenylacetic Acid",
      "(S)-2-Fluoro-Alpha-Methyl(1,1'-Biphenyl)-4-Acetic Ac",
    ],
  },
  {
    primary_id: "D0G2JG",
    names: [
      "CPFPX",
      "CHEMBL2311146",
      "8-Cyclopentyl-3-(3-Fluoranylpropyl)-1-Propyl-7H-Purine-2,6-Dione",
      "[18F]CPFPX",
      "AC1NUVWE",
      "GTPL5603",
    ],
  },
  {
    primary_id: "D0G2JV",
    names: ["2,3,5-Trimethoxy-4'-Amino-Trans-Stilbene", "CHEMBL1170663", "BDBM50322054"],
  },
  {
    primary_id: "D0G2JZ",
    names: ["Bisbenzimide (Hoechst 33258)"],
  },
  {
    primary_id: "D0G2KD",
    names: ["Iophendylate"],
  },
  {
    primary_id: "D0G2LF",
    names: [
      "[(1e)-4-Phenylbut-1-Enyl]Benzene",
      "[(1E)-4-PHENYLBUT-1-ENYL]BENZENE",
      "1,4-DIPHENYL-1-BUTENE",
      "14213-84-4",
      "AC1NR9PT",
      "(E)-1,4-Diphenyl-1-Butene",
      "[(E)-4-Phenylbut-1-Enyl]benzene",
      "ZINC14880527",
      "[(1E)-4-Phenyl-1-Butenyl]benzene",
      "[(E)-4-Phenyl-But-1-Enyl]-Benzene",
      "1,1'-(1E)-But-1-Ene-1,4-Diyldibenzene",
    ],
  },
  {
    primary_id: "D0G2LQ",
    names: [
      "5-(4-Chloro-Benzyl)-1H-Pyrazole-3-Carboxylic Acid",
      "CHEMBL341520",
      "1H-Pyrazole-3-Carboxylic Acid, 5-[(4-Chlorophenyl)Methyl]-",
      "BDBM50132144",
    ],
  },
  {
    primary_id: "D0G2LR",
    names: ["Preeclampsia Diagnostic/Treatment"],
  },
  {
    primary_id: "D0G2LT",
    names: ["RGH-1756"],
  },
  {
    primary_id: "D0G2MC",
    names: ["BPM 31510 Intravenous"],
  },
  {
    primary_id: "D0G2MH",
    names: ["Iodohippurate Sodium"],
  },
  {
    primary_id: "D0G2MM",
    names: [
      "Cyclohexylammonium Ion",
      "CYCLOHEXYLAMMONIUM ION",
      "Cyclohexylammonium",
      "Cyclohexylazanium",
      "Cyclohexanaminium",
      "Cyclohexaniminium",
      "Cyclohexyl-Ammonium",
      "Cyclohexyl Ammonium",
      "AC1LU7QN",
      "SAMPL4, C7",
      "CHEBI:42939",
      "PAFZNILMFXTMIY-UHFFFAOYSA-O",
      "BDBM197294",
      "DB02995",
      "NCGC00249017-01",
      "CJ-13833",
      "A801941",
    ],
  },
  {
    primary_id: "D0G2MR",
    names: ["ProstaVac"],
  },
  {
    primary_id: "D0G2MU",
    names: ["9-Phenanthrol"],
  },
  {
    primary_id: "D0G2MW",
    names: ["Icosapent"],
  },
  {
    primary_id: "D0G2NF",
    names: ["(2S)-Aminobutyryl-(R)-Pipecolinic Acid Amide", "CHEMBL225617"],
  },
  {
    primary_id: "D0G2OJ",
    names: ["Ginkgolide C"],
  },
  {
    primary_id: "D0G2OZ",
    names: ["MORAb-202"],
  },
  {
    primary_id: "D0G2QG",
    names: ["PMID16290936C1b", "736144-17-5", "GTPL8613", "CTK2H1087", "DTXSID70658350", "BDBM50148296"],
  },
  {
    primary_id: "D0G2QH",
    names: ["AEE-788", "AEE", "AEE 788", "AEE788", "GNF-Pf-5343", "AEE-788, NVP-AEE 788, AEE788"],
  },
  {
    primary_id: "D0G2QW",
    names: ["PMID1992138C8b"],
  },
  {
    primary_id: "D0G2RB",
    names: ["Di-Benzyloxycarbonyl-Guanidino Acetylryanodine", "CHEMBL416926"],
  },
  {
    primary_id: "D0G2RE",
    names: ["BN 50730"],
  },
  {
    primary_id: "D0G2RY",
    names: ["2-Amino-6-(Indan-5-Ylamino)-3H-Pyrimidin-4-One"],
  },
  {
    primary_id: "D0G2SI",
    names: ["Pyrazolo-Triazine Derivative 1"],
  },
  {
    primary_id: "D0G2SZ",
    names: ["Piliel"],
  },
  {
    primary_id: "D0G2TA",
    names: ["Rfsh/Rhfsh"],
  },
  {
    primary_id: "D0G2TX",
    names: ["PMID27539678-Compound-16"],
  },
  {
    primary_id: "D0G2UL",
    names: ["L-Nw-Nitroarginine"],
  },
  {
    primary_id: "D0G2UP",
    names: [
      "DF-152",
      "DF-152 Series (Mycobacterium Tuberculosis Infection)",
      "DF-152 Series (Mycobacterium Tuberculosis Infection), Dafra Pharma R&D",
      "VUF-8514 Derivatives (Mycobacterium Tuberculosis Infection), Dafra Pharma R&D",
    ],
  },
  {
    primary_id: "D0G2UY",
    names: ["HBF-0259"],
  },
  {
    primary_id: "D0G2VC",
    names: ["KT-5720"],
  },
  {
    primary_id: "D0G2VT",
    names: [
      "SUN-11031",
      "6,6,6-Trifluoromethionine",
      "AC1L5KZ2",
      "SCHEMBL245985",
      "CTK1D7491",
      "NSC73030",
      "NSC-73030",
      "2-Amino-4-(Trifluoromethylthio)Butyric Acid",
      "2-Amino-4-(Trifluoromethylsulfanyl)Butanoic Acid",
    ],
  },
  {
    primary_id: "D0G2WI",
    names: [
      "CP-126998",
      "3-[2-(1-Benzylpiperidin-4-Yl)Ethyl]-7-Methyl-6,7-Dihydro-5H-Pyrrolo[3,2-F][1,2-]benzisoxazol-6-One",
    ],
  },
  {
    primary_id: "D0G2XE",
    names: ["PMX-53"],
  },
  {
    primary_id: "D0G2XI",
    names: ["4-(4-Benzyl-1H-1,2,3-Triazol-1-Yl)Phenol", "CHEMBL1288977"],
  },
  {
    primary_id: "D0G2XM",
    names: [
      "AP-227",
      "CHEMBL268477",
      "AP227",
      "BDBM50095205",
      "[6-(2-Benzhydryloxyimino-Pentyl)-5,6,7,8-Tetrahydro-Naphthalen-1-Yloxy]-Acetic Acid",
      "(6-{2-[(E)-Benzhydryloxyimino]-Pentyl}-5,6,7,8-Tetrahydro-Naphthalen-1-Yloxy)-Acetic Acid",
    ],
  },
  {
    primary_id: "D0G2XN",
    names: ["S-12968"],
  },
  {
    primary_id: "D0G2XR",
    names: ["TCD-717", "Choline Kinase Alpha Inhibitor (Iv, Solid Tumors), TCD Pharma"],
  },
  {
    primary_id: "D0G2YV",
    names: ["KNT-5"],
  },
  {
    primary_id: "D0G2ZG",
    names: ["Duac Low Dose"],
  },
  {
    primary_id: "D0G2ZL",
    names: [
      "Managlinat Dialanetil",
      "CS-917",
      "GP-3034",
      "MB-05032",
      "MB-06322",
      "MB-06633",
      "MB-6322",
      "R-132917",
      "Type 2 Diabetes Therapeutic, Gensia/Sankyo",
      "Type 2 Diabetes Therapeutic, SICOR/Sankyo",
      "Diabetes Therapeutics, Metabasis/Sanyko/Daiichi Sankyo",
      "Purine Nucleotide Analogs, Gensia/Sankyo/Daiichi Sankyo",
      "Purine Nucleotide Analogs, Metabasis/Sanyko/Daiichi Sankyo",
      "Purine Nucleotide Analogs, SICOR/Sankyo/Daiichi Sankyo",
    ],
  },
  {
    primary_id: "D0G2ZV",
    names: ["TRC-6XXX"],
  },
  {
    primary_id: "D0G2ZX",
    names: ["Vernakalant IV"],
  },
  {
    primary_id: "D0G3AQ",
    names: [
      "Prazepam",
      "Centrax",
      "Demetrin",
      "Lysanxia",
      "Prazeene",
      "Prazene",
      "Prazepamum",
      "Sedapran",
      "Settima",
      "Trepidan",
      "Verstran",
      "W 4020",
      "Centrac (TN)",
      "Centrax (TN)",
      "Demetrin (TN)",
      "K-373",
      "Lysanxia (TN)",
      "Mono Demetrin (TN)",
      "Pozapam (TN)",
      "Prasepine (TN)",
      "Prazene (TN)",
      "Prazepamum [INN-Latin]",
      "Reapam (TN)",
      "Trepidan (TN)",
      "W-4020",
      "Prazepam (JP15/USAN/INN)",
      "Prazepam [USAN:INN:BAN:JAN]",
      "7-Chloro-1-(Cyclopropylmethyl)-1,3-Dihydro-5-Phenyl-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-1-(Cyclopropylmethyl)-5-Phenyl-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Chloro-1-Cyclopropylmethyl-5-Phenyl-1H-1,4-Benzodiazepin-2(3H)-One",
      "7-Chloro-1-(Cyclopropylmethyl)-5-Phenyl-3H-1,4-Benzodiazepin-2-One",
    ],
  },
  {
    primary_id: "D0G3AR",
    names: [
      "CX-717",
      "CX-1763",
      "CX-546",
      "CX-614",
      "CX-727",
      "CX-729",
      "CX-743",
      "CX-815",
      "S-40929",
      "First Generation AMPA Receptor Modulators, Cortex/University Of California",
      "First-Generation AMPAKINE Compounds, University Of California/Cortex",
    ],
  },
  {
    primary_id: "D0G3BI",
    names: [
      "6-Methyl-2-Oxo-2H-Chromene-3-Carboxylic Acid",
      "10242-13-4",
      "2H-1-BENZOPYRAN-3-CARBOXYLIC ACID, 6-METHYL-2-OXO-",
      "BRN 0172386",
      "6-Methyl-2-Oxo-2H-1-Benzopyran-3-Carboxylic Acid",
      "6-Methyl-2-Oxochromene-3-Carboxylic Acid",
      "CHEMBL577123",
      "AC1L18EL",
      "5-18-08-00343 (Beilstein Handbook Reference)",
      "SCHEMBL2808135",
      "CTK0H8591",
      "DTXSID00145066",
      "MolPort-007-984-853",
      "FJICLQQBBFWGMZ-UHFFFAOYSA-N",
      "HMS1622M11",
      "ZINC2023765",
      "SBB077427",
      "BDBM50303488",
      "AKOS002679479",
      "MCULE-8314622881",
      "LS-39185",
      "VU0511228-1",
      "2-Oxo-6-Methyl-2H-1-Benzopyran-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0G3BJ",
    names: ["Ingenol-3-Bezoate"],
  },
  {
    primary_id: "D0G3BK",
    names: ["ZP-2307"],
  },
  {
    primary_id: "D0G3BN",
    names: [
      "AE-37",
      "Breast Cancer Vaccine, Antigen Express",
      "AE-37, Antigen Express",
      "HER-2/Neu Vaccine, Antigen Express",
      "HER-2/Neu Vaccine (Breast Cancer/Prostate Cancer), Antigen Express",
      "Ii-Key/HER-2/Neu (776-790)",
    ],
  },
  {
    primary_id: "D0G3BY",
    names: ["3-(3-Cyano-1H-Indol-6-Yl)-1-Methyl-1-Phenylurea", "CHEMBL205328"],
  },
  {
    primary_id: "D0G3CO",
    names: ["NHP-229"],
  },
  {
    primary_id: "D0G3CQ",
    names: [
      "D-Eritadenine",
      "AC1O59J4",
      "ZINC3872190",
      "(2S,3R)-4-(6-Aminopurin-9-Yl)-2,3-Dihydroxybutanoic Acid",
      "(2S,3R)-4-(6-Aminopurin-9-Yl)-2,3-Dihydroxy-Butanoic Acid",
      "9H-Purine-9-Propanoic Acid, 6-Amino-Alpha-Hydroxy-, (AlphaR)-",
    ],
  },
  {
    primary_id: "D0G3DB",
    names: ["[3H]SR 121463A", "[3H]-SR121463A"],
  },
  {
    primary_id: "D0G3DC",
    names: ["ASP-1001"],
  },
  {
    primary_id: "D0G3DD",
    names: ["CL-H01"],
  },
  {
    primary_id: "D0G3DL",
    names: [
      "Rifampin",
      "Abrifam",
      "Archidyn",
      "Arficin",
      "Arzide",
      "Benemicin",
      "Benemycin",
      "Dipicin",
      "Doloresum",
      "Eremfat",
      "Famcin",
      "Fenampicin",
      "RFP",
      "RMP",
      "Ramp",
      "Rifa",
      "Rifadin",
      "Rifadine",
      "Rifagen",
      "Rifaldazin",
      "Rifaldazine",
      "Rifaldin",
      "Rifam",
      "Rifamor",
      "Rifampicin",
      "Rifampicina",
      "Rifampicine",
      "Rifampicinum",
      "Rifamsolin",
      "Rifaprodin",
      "Rifcin",
      "Rifinah",
      "Rifobac",
      "Rifoldin",
      "Rifoldine",
      "Riforal",
      "Rimactan",
      "Rimactane",
      "Rimactazid",
      "Rimactizid",
      "Rimazid",
      "Rimycin",
      "Sinerdol",
      "Tubocin",
      "Rifamicin AMP",
      "Rifampicin SV",
      "Rifampicine [French]",
      "Rifampin [USAN]",
      "Rifamycin AMP",
      "Ba 41166",
      "AZT + Rifampin",
      "BA-41166E",
      "Ba 41166/E",
      "DRG-0109",
      "Dione 21-Acetate",
      "L-5103",
      "L-5103 Lepetit",
      "Piperine & Rifampicin",
      "R-Cin",
      "R/AMP",
      "Reserpine & Rifampicin",
      "Rifadin (TN)",
      "Rifadin I.V",
      "Rifampicin & EEP",
      "Rifampicin & Propolis",
      "Rifampicina [INN-Spanish]",
      "Rifampicinum [INN-Latin]",
      "Rifampin (USP)",
      "Rimactan (TN)",
      "Rimactane (TN)",
      "Rimycin (TN)",
      "Sinerdol (TN)",
      "Tubocin (TN)",
      "Rifadin I.V.",
      "Rifampicin (JP15/INN)",
      "Rifampicin[INN:BAN:JAN]",
      "Rifadin, Rimactane, Rifampicin, Rifampin",
      "1-B]furan-21-Yl Acetate",
      "3-(((4-Methyl-1-Piperazinyl)Imino)-Methyl)Rifamycin",
      "3-(((4-Methyl-1-Piperazinyl)Imino)Methyl)Rifamycin SV",
      "3-(4-Methylpiperazinyliminomethyl)-Rifamycin SV",
      "3-(4-Methylpiperazinyliminomethyl)Rifamycin SV",
      "3-([(4-Methyl-1-Piperazinyl)Imino]methyl)Rifamycin SV",
      "3-[(4-Methyl-1-Piperazinyl)Iminomethyl]rifamycin SV",
      "3-[[(4-Methyl-1-Piperazinyl)Imino]-Methyl]rifamycin",
      "8-(((4-Methyl-1-Piperazinyl)Imino)Methyl)Rifamycin SV",
      "8-(4-Methylpiperazinyliminomethyl) Rifamycin SV",
      "8-[[(4-Methyl-1-Piperazinyl)Imino[methyl]rifamycin",
      "8-[[(4-Methyl-1-Piperazinyl)Imino]methyl]rifamycin Sv",
      "8-[[(4-Methylpiperazinyl)Imino]methyl]rifamycin Sv",
      "8CI)",
    ],
  },
  {
    primary_id: "D0G3DM",
    names: ["Indazolyl-Thiadiazolamine Derivative 1"],
  },
  {
    primary_id: "D0G3DP",
    names: ["PTI-303"],
  },
  {
    primary_id: "D0G3EC",
    names: ["Baquting"],
  },
  {
    primary_id: "D0G3EU",
    names: ["AMG-139", "Anti-Inflammatory Agent (Psoriasis, IBD), Amgen"],
  },
  {
    primary_id: "D0G3GE",
    names: ["Ac-His-DPhe(3,4-DiCl)-Arg-Trp-NH2", "CHEMBL501394"],
  },
  {
    primary_id: "D0G3GF",
    names: ["AdPEDR"],
  },
  {
    primary_id: "D0G3GR",
    names: [
      "E7/HSP70 DNA Vaccine",
      "E7/HSP70 DNA Vaccine, Johns Hopkins University",
      "E7/VP22 DNA Vaccine, Johns Hopkins University",
      "HPV-DNA Fusion Protein Vaccine (Anticancer), Johns Hopkins University",
      "PNGVL4a-Sig/E7(Detox)/HSP70 DNA Vaccine, Johns Hokins University",
    ],
  },
  {
    primary_id: "D0G3IL",
    names: ["PMID25482888-Compound-17"],
  },
  {
    primary_id: "D0G3IT",
    names: ["RU-56187"],
  },
  {
    primary_id: "D0G3IV",
    names: ["ISIS 105987"],
  },
  {
    primary_id: "D0G3KP",
    names: [
      "Carbon Nanospheres",
      "Carbon Nanospheres (Allergy)",
      "Carbon Nanospheres (Allergy), Luna/VCU",
      "Fullerenes (Allergy), Luna/VCU",
    ],
  },
  {
    primary_id: "D0G3KY",
    names: ["PMID25416646-Compound-Figure2-K"],
  },
  {
    primary_id: "D0G3LG",
    names: [
      "Vaccine, Antimicrobial",
      "Contrel",
      "Vaccine (Antimicrobial)",
      "Vaccine (Antimicrobial), Repros Therapeutics",
      "Vaccine (Antimicrobial), Zonagen",
    ],
  },
  {
    primary_id: "D0G3LM",
    names: [
      "Clomethiazole",
      "533-45-9",
      "Chlormethiazole",
      "5-(2-CHLOROETHYL)-4-METHYLTHIAZOLE",
      "Distraneurin",
      "Emineurina",
      "Clomethiazolum",
      "Chlormethiazol",
      "Chlorethiazol",
      "Chlorethiazole",
      "Somnevrin",
      "Clometiazole",
      "Clomethiazol",
      "5-(2-Chloroethyl)-4-Methyl-1,3-Thiazole",
      "Thiazole, 5-(2-Chloroethyl)-4-Methyl-",
      "Clometiazolo [DCIT]",
      "Chloro-S.C.T.Z.",
      "C6H8ClNS",
      "UNII-0C5DBZ19HV",
      "Clometiazol [INN-Spanish]",
      "Clomethiazolum [INN-Latin]",
      "WY 1485",
      "Clomethiazole [INN]",
      "SCTZ [as Edisylate]",
      "EINECS 208-565-7",
      "4-Methyl-5-(Beta-Chloroethyl)Thiazole",
      "BRN 0114244",
      "0C5DBZ19HV",
    ],
  },
  {
    primary_id: "D0G3MD",
    names: [
      "(4-Quinolin-2-Ylpiperazin-1-Yl)Acetic Acid",
      "CHEMBL468498",
      "AC1LEONH",
      "SCHEMBL13780710",
      "A3329/0141355",
      "BDBM50258497",
      "AKOS009544966",
      "2-(4-Quinolin-2-Ylpiperazin-1-Yl)Acetic Acid",
    ],
  },
  {
    primary_id: "D0G3MH",
    names: ["PNU-96391A", "(-)-OSU-6162"],
  },
  {
    primary_id: "D0G3MN",
    names: ["GSK2636771"],
  },
  {
    primary_id: "D0G3NC",
    names: ["Amidopyrazole Derivative 2"],
  },
  {
    primary_id: "D0G3OF",
    names: [
      "V-073",
      "Capsid Inhibitors (Polioviral Infections), ViroDefense",
      "Capsid Inhibitors (Polioviral Infections), Schering-Plough Research Institute",
    ],
  },
  {
    primary_id: "D0G3OI",
    names: ["MK-8237"],
  },
  {
    primary_id: "D0G3OY",
    names: ["Methylhistaprodifen"],
  },
  {
    primary_id: "D0G3PI",
    names: ["Alitretinoin"],
  },
  {
    primary_id: "D0G3PK",
    names: ["Fsn-0503", "Cathepsin S MAb (Cancer), Fusion"],
  },
  {
    primary_id: "D0G3QD",
    names: ["Chalcone Derivative 5"],
  },
  {
    primary_id: "D0G3QF",
    names: ["Epoxyeicosatrienoic Acid Mimetics"],
  },
  {
    primary_id: "D0G3RD",
    names: [
      "2-Methyl-1,2-Di-Pyridin-3-Yl-Propane",
      "CHEMBL54171",
      "87372-71-2",
      "CTK3C4533",
      "DTXSID60658228",
      "ZINC29551986",
      "BDBM50028165",
      "3,3'-(2-Methylpropane-1,2-Diyl)Dipyridine",
      "Pyridine, 3,3'-(1,1-Dimethyl-1,2-Ethanediyl)Bis-",
    ],
  },
  {
    primary_id: "D0G3RN",
    names: ["VUF-10214"],
  },
  {
    primary_id: "D0G3RO",
    names: ["3-(4H-Imidazol-4-Yl)Benzenethiol"],
  },
  {
    primary_id: "D0G3RW",
    names: ["Low Toxic Paclitaxel Derivative"],
  },
  {
    primary_id: "D0G3RZ",
    names: [
      "6-(4-Methylpiperazin-1-Yl)-9H-Purin-2-Amine",
      "CHEMBL594694",
      "1196993-60-8",
      "SCHEMBL20093538",
      "MolPort-039-193-873",
      "BBL036459",
      "STL490826",
      "ZINC45352375",
      "BDBM50304514",
    ],
  },
  {
    primary_id: "D0G3SF",
    names: ["1-(4-Methoxyphenyl)-3-(4-Methylthiazol-2-Yl)Urea", "CHEMBL375257"],
  },
  {
    primary_id: "D0G3SH",
    names: [
      "Ursodeoxycholic Acid",
      "Actigall",
      "Antigall",
      "Arsacol",
      "Delursan",
      "Destolit",
      "Deursil",
      "Litursol",
      "Lyeton",
      "Peptarom",
      "Solutrat",
      "UDCA",
      "UDCS",
      "UrSO",
      "Urosiol",
      "Ursacol",
      "Ursobilin",
      "Ursochol",
      "Ursodamor",
      "Ursodeoxycholate",
      "Ursodiol",
      "Ursofalk",
      "Ursolvan",
      "Ursosan",
      "Acide Ursodesoxycholique",
      "Acido Ursodeossicolico",
      "Acido Ursodeossicolico [Italian]",
      "Acido Ursodeoxicolico",
      "Acidum Ursodeoxycholicum",
      "Deoxyursocholic Acid",
      "Sodium Ursodeoxycholate",
      "Ursacholic Acid",
      "Urso DS",
      "Urso Forte",
      "Ursodeoxy Cholic Acid",
      "Ursodesoxycholicacid",
      "Ursodexycholic Acid",
      "Ursodiol [USAN]",
      "IU5",
      "U0030",
      "Urso 250",
      "Acide Ursodesoxycholique [INN-French]",
      "Acido Ursodeoxicolico [INN-Spanish]",
      "Acidum Ursodeoxycholicum [INN-Latin]",
      "Actigall (TN)",
      "Cholit-Ursan",
      "Deursil (TN)",
      "Dom-Ursodiol C",
      "ISO-URSODEOXYCHOLIC ACID",
      "PHL-Ursodiol C",
      "PMS-Ursodiol C",
      "U-9000",
      "Urso (TN)",
      "Urso Forte (TN)",
      "Ursodiol (USP)",
      "Ursosan (TN)",
      "Ursodeoxycholic Acid (JP15/INN)",
      "Ursodeoxycholic Acid, UDCA, Ursosan, Ursofalk, Urso Forte, Udiliv, Ursodiol",
      "Cholan-24-Oic Acid, 3,7-Dihydroxy-, (3-Alpha,5-Beta,7-Beta)-(9CI)",
      "(3alpha,5beta,7beta)-3,7-Dihydroxycholan-24-Oic Acid",
      "(3alpha,5beta,7beta,8xi)-3,7-Dihydroxycholan-24-Oic Acid",
      "(4R)-4-[(3R,5S,7S,8R,9S,10S,13R,14S,17R)-3,7-Dihydroxy-10,13-Dimethyl-2,3,4,5,6,7,8,9,11,12,14,15,16,17-Tetradecahydro-1H-Cyclopenta[a]phenanthren-17-Yl]pentanoic Acid",
      "17-Beta-(1-Methyl-3-Carboxypropyl)Etiocholane-3-Alpha,7-Beta-Diol",
      "3 Alpha,7 Beta-Dihydroxy-5 Beta-Cholan-24-Oic Acid",
      "3,7-Dihydroxycholan-24-Oic Acid",
      "3-Alpha,7-Beta-Dihydroxy-5-Beta-Cholanoic Acid",
      "3-Alpha,7-Beta-Dihydroxycholanic Acid",
      "3-Alpha,7-Beta-Dioxycholanic Acid",
      "3alpha,7beta-Dihydroxy-5beta-Cholan-24-Oic Acid",
      "3alpha,7beta-Dihydroxy-5beta-Cholanic Acid",
      "5beta-Cholan-24-Oic Acid-3alpha,7beta-Diol",
      "5beta-Cholanic Acid-3alpha,7beta-Diol",
      "7-Beta-Hydroxylithocholic Acid",
      "7beta-Hydroxylithocholic Acid",
    ],
  },
  {
    primary_id: "D0G3SL",
    names: ["FUROSIN"],
  },
  {
    primary_id: "D0G3SZ",
    names: [
      "ALTENUSIN",
      "AC1L4N6W",
      "AC1Q68NU",
      "SCHEMBL16971034",
      "4',5'-Dihydroxy-5-Methoxy-2'-Methylbiphenyl-2-Carboperoxoic Acid",
      "ACM31186126",
      "J-018273",
      "2-(4,5-Dihydroxy-2-Methylphenyl)-4-Methoxybenzenecarboperoxoic Acid",
    ],
  },
  {
    primary_id: "D0G3TH",
    names: ["PMID25399762-Compound-Figure4-Kadcoccitone A"],
  },
  {
    primary_id: "D0G3TJ",
    names: [
      "ASKP-1240",
      "Anti-CD40 MAb (Organ Transplant Rejection/Autoimmune Disease), Kirin/Astellas",
      "Anti-CD40 MAb (Organ Transplant Rejection/Autoimmune Disease), Kyowa Hakko Kirin/Astellas",
      "4D11",
    ],
  },
  {
    primary_id: "D0G3TK",
    names: ["KAEMPFEROL"],
  },
  {
    primary_id: "D0G3TV",
    names: ["CID2440433", "CID 2440433"],
  },
  {
    primary_id: "D0G3UG",
    names: ["HG-1429"],
  },
  {
    primary_id: "D0G3UK",
    names: ["M4 Agonist"],
  },
  {
    primary_id: "D0G3UV",
    names: ["PMID25666693-Compound-55"],
  },
  {
    primary_id: "D0G3VE",
    names: [
      "Acetic Acid 5-Butyl-2-Phenyl-Thiazol-4-Yl Ester",
      "CHEMBL303221",
      "SCHEMBL9630562",
      "BDBM50012410",
      "Acetic Acid 5-Butyl-2-Phenyl-4-Thiazolyl Ester",
    ],
  },
  {
    primary_id: "D0G3YC",
    names: ["Netupitant"],
  },
  {
    primary_id: "D0G3YZ",
    names: [
      "(+/-)5-(Biphenyl-4-Yl)-3-Hydroxypentanoic Acid",
      "CHEMBL572982",
      "SCHEMBL5613354",
      "ILGSIIFHQGOKKV-UHFFFAOYSA-N",
      "BDBM50300465",
      "5-Biphenyl-4-Yl-3-Hydroxy-Pentanoic Acid",
      "5-(Biphenyl-4-Yl)-3-Hydroxypentanoic Acid",
    ],
  },
  {
    primary_id: "D0G3ZH",
    names: ["N-(Quinolin-8-Yl)-6-(Sulfamoylamino)Hexanamide", "CHEMBL507114", "SCHEMBL5330834"],
  },
  {
    primary_id: "D0G3ZN",
    names: ["PF-00614435"],
  },
  {
    primary_id: "D0G3ZX",
    names: ["Omri-Hep-B"],
  },
  {
    primary_id: "D0G4AE",
    names: ["KNX-Monoclonal204"],
  },
  {
    primary_id: "D0G4AJ",
    names: ["AGN-208397"],
  },
  {
    primary_id: "D0G4AL",
    names: ["PA101"],
  },
  {
    primary_id: "D0G4BF",
    names: ["DTaP-HepB Prophylactic Vaccine", "DTaP-HepB Prophylactic Vaccine (Diphtheria/Tetanus/Pertussis/HBV)"],
  },
  {
    primary_id: "D0G4BG",
    names: ["N-(Propargyl),N-(Pyrrol-2-Ylmethyl)Amine"],
  },
  {
    primary_id: "D0G4BI",
    names: ["Efinaconazole"],
  },
  {
    primary_id: "D0G4BW",
    names: ["PMID17929793C23e"],
  },
  {
    primary_id: "D0G4CF",
    names: [
      "CVT-6883",
      "752222-83-6",
      "GS-6201",
      "UNII-67CKV7X08G",
      "CHEMBL260933",
      "67CKV7X08G",
      "CVT 6883",
      "GS 6201",
      "3-Ethyl-3,9-Dihydro-1-Propyl-8-[1-[[3-(Trifluoromethyl)Phenyl]methyl]-1H-Pyrazol-4-Yl]-1H-Purine-2,6-Dione",
      "3-Ethyl-1-Propyl-8-(1-(3-(Trifluoromethyl)Benzyl)-1H-Pyrazol-4-Yl)-1H-Purine-2,6(3H,7H)-Dione",
      "Kinome_3621",
      "SCHEMBL238694",
      "GTPL5615",
      "CVT6883",
      "AOB4675",
      "MolPort-035-765-814",
      "CTV-6883",
      "ZINC29055563",
      "BDBM50236738",
      "AKOS024458311",
      "API0004749",
      "3-Ethyl-1-Propyl-8-[1-[[3-(Trifluoromethyl)Phenyl]methyl]pyrazol-4-Yl]-7H-Purine-2,6-Dione",
    ],
  },
  {
    primary_id: "D0G4CG",
    names: ["[3H]oxotremorine-M"],
  },
  {
    primary_id: "D0G4CI",
    names: ["Methylglyoxal"],
  },
  {
    primary_id: "D0G4CJ",
    names: ["4-(3-Benzoyl-1H-Pyrrol-1-Yl)Butanoic Acid", "CHEMBL590016"],
  },
  {
    primary_id: "D0G4CS",
    names: ["PMID24900237C15", "1022957-12-5", "GTPL8220", "BDBM50385590", "KB-75520"],
  },
  {
    primary_id: "D0G4CY",
    names: ["PA101B"],
  },
  {
    primary_id: "D0G4DE",
    names: ["BIA 3-202"],
  },
  {
    primary_id: "D0G4DF",
    names: ["AP-1600", "AP-1600 Series (Ophthalmic Inflammation)"],
  },
  {
    primary_id: "D0G4EK",
    names: ["PMID28454500-Compound-50"],
  },
  {
    primary_id: "D0G4ES",
    names: ["Tetrandrine"],
  },
  {
    primary_id: "D0G4ET",
    names: ["MB-3", "Alpha-Methylene-Gamma-Butyrolactone 3"],
  },
  {
    primary_id: "D0G4FA",
    names: ["1-Benzhydryl-4-(4-Methoxyphenyl)Piperidin-4-Ol", "CHEMBL401234"],
  },
  {
    primary_id: "D0G4GA",
    names: ["TC-6987"],
  },
  {
    primary_id: "D0G4GB",
    names: ["Omega-Chloroacetophenone"],
  },
  {
    primary_id: "D0G4GT",
    names: ["Phenylpropylamine Derivative 1"],
  },
  {
    primary_id: "D0G4GY",
    names: ["Anti-Meso-CAR Vector Transduced T Cells"],
  },
  {
    primary_id: "D0G4HO",
    names: ["PLX-PAD"],
  },
  {
    primary_id: "D0G4HR",
    names: [
      "4-(4-(2-Nitrovinyl)Phenoxysulfonyl)Benzoic Acid",
      "CHEMBL76958",
      "BDBM50007075",
      "4-[4-(2-Nitro-Vinyl)-Phenoxysulfonyl]-Benzoic Acid",
      "4-[4-[(E)-2-Nitroethenyl]phenoxysulfonyl]benzoic Acid",
    ],
  },
  {
    primary_id: "D0G4HT",
    names: ["Sub[-Tyr-Arg-Leu-Arg-Tyr-NH2]2"],
  },
  {
    primary_id: "D0G4ID",
    names: ["KUR-024"],
  },
  {
    primary_id: "D0G4IS",
    names: ["PMID27109571-Compound-2"],
  },
  {
    primary_id: "D0G4IW",
    names: ["CART-19"],
  },
  {
    primary_id: "D0G4JB",
    names: ["NS-13001"],
  },
  {
    primary_id: "D0G4JC",
    names: ["ANB-019"],
  },
  {
    primary_id: "D0G4JI",
    names: [
      "Pyruvic Acid",
      "BTS",
      "Brenztraubensaeure",
      "Acetylformic Acid",
      "Acide Pyruvique",
      "Pyroracemic Acid",
      "CH3COCOOH",
      "A-Ketopropionic Acid",
      "Alpha-Ketopropionic Acid",
      "Alpha-Oxopropionsaeure",
      "Alpha-Keto Propionic Acid",
      "P-9250",
      "Pyruvic Acid (8CI)",
      "Pyruvic Acid (Natural)",
      "Propanoic Acid, 2-Oxo-(9CI)",
      "2-Ketopropionic Acid",
      "2-Oxopropanoate",
      "2-Oxopropanoic Acid",
      "2-Oxopropansaeure",
      "2-Oxopropionic Acid",
      "2-Oxopropionsaeure",
      "2-Oxo(1-14c)Propanoic Acid",
      "2-Oxo-Propionic Acid",
    ],
  },
  {
    primary_id: "D0G4JS",
    names: [
      "1-(1H-Indazol-6-Yl)-3-Pyridin-2-Yl-Urea",
      "Diarylurea Deriv. 14a",
      "AC1NS9HT",
      "BDBM6656",
      "CHEMBL143759",
      "ZINC13471116",
      "AKOS027814075",
      "3-1H-Indazol-6-Yl-1-Pyridin-2-Ylurea",
      "1-(1H-Indazol-6-Yl)-3-Pyridin-2-Ylurea",
      "N-(1H-Indazol-6-Yl)-N -Pyridin-2-Ylurea",
    ],
  },
  {
    primary_id: "D0G4KB",
    names: ["[3H]pentazocine", "(+)-[3H]-Pentazocine", "[3H]-(+)-Pentazocine", "CHEMBL2311194"],
  },
  {
    primary_id: "D0G4KD",
    names: ["HS-113"],
  },
  {
    primary_id: "D0G4KG",
    names: ["Khellin"],
  },
  {
    primary_id: "D0G4KT",
    names: ["16-Isobutylidene-Estradiol"],
  },
  {
    primary_id: "D0G4LX",
    names: ["PMID20121197C57"],
  },
  {
    primary_id: "D0G4MB",
    names: ["ABT-510"],
  },
  {
    primary_id: "D0G4NM",
    names: [
      "DPDPE",
      "2,5-Pen-Enkephalin",
      "(D-Pen2,D-Pen5)-Enkephalin",
      "Enkephalin, D-Penicillamine (2,5)-",
      "(4s,7s,13s)-7-Benzyl-3,3,14,14-Tetramethyl-6,9,12-Trioxo-13-(L-Tyrosylamino)-1,2-Dithia-5,8,11-Triazacyclotetradecane-4-Carboxylic Acid",
      "DPLPE",
      "C[D-Pen(2,5)]-Enkephalin",
      "CHEMBL31421",
      "CHEBI:73356",
      "Enkephalin, Penicillamine(2,5)-",
      "Cyclo-[D-Pen(2,5)]-Enkephalin",
      "88373-73-3",
      "D-Penicillamine-(2,5)-Enkephalin",
      "H-Tyr-C(D-Pen-Gly-Phe-D-Pen)-OH",
      "[D-Pen(2)-D-Pen(5)]-Enkephaline",
      "H-Tyr-Cyclo-(D-Pen-Gly-Phe-D-Pen)-OH",
      "[3H]DPDPE",
      "D-Valine, L-Tyros",
    ],
  },
  {
    primary_id: "D0G4OD",
    names: ["Tigecycline"],
  },
  {
    primary_id: "D0G4OS",
    names: ["SII Rmab"],
  },
  {
    primary_id: "D0G4OT",
    names: ["XL784"],
  },
  {
    primary_id: "D0G4PZ",
    names: ["N-Phenethyl-2-Phenylquinoline-4-Carboxamide"],
  },
  {
    primary_id: "D0G4QL",
    names: ["UNI-RE-4"],
  },
  {
    primary_id: "D0G4RG",
    names: ["4-Morpholin-4-Yl-Benzo[h]chromen-2-One", "CHEMBL179641", "BDBM50159617"],
  },
  {
    primary_id: "D0G4RI",
    names: ["PF-06664178"],
  },
  {
    primary_id: "D0G4SM",
    names: ["Artemisinin-Peptide Conjugates", "Artemisinin-Peptide Conjugates, Artemisia BioMedical"],
  },
  {
    primary_id: "D0G4SO",
    names: ["SUVN-F90101", "Alpha 4 Beta 2 Nicotinic Acetylcholine Receptor (NAChR) Agonist (Pain), Suven"],
  },
  {
    primary_id: "D0G4SU",
    names: ["Tedisamil"],
  },
  {
    primary_id: "D0G4UU",
    names: ["Respiratory Disease Therapeutics"],
  },
  {
    primary_id: "D0G4VH",
    names: ["Ac-I[CVSQDWGHHRC]T-NH2"],
  },
  {
    primary_id: "D0G4VV",
    names: ["SCH-356036"],
  },
  {
    primary_id: "D0G4WF",
    names: ["5,6,7-Trimethyl-2-P-Tolyl-Chromen-4-One", "CHEMBL168080", "4',5,6,7-Tetramethylflavone"],
  },
  {
    primary_id: "D0G4XE",
    names: ["Adult Stem Cell Therapy"],
  },
  {
    primary_id: "D0G4YI",
    names: ["PMID30273516-Compound-1"],
  },
  {
    primary_id: "D0G4YN",
    names: ["177 Lu PSMA-617 Targeted Radioligand Therapy"],
  },
  {
    primary_id: "D0G4YR",
    names: ["MT-3995"],
  },
  {
    primary_id: "D0G4YS",
    names: ["4-PAM Derivative 1"],
  },
  {
    primary_id: "D0G4YT",
    names: ["Liranaftate"],
  },
  {
    primary_id: "D0G4YZ",
    names: ["11C-PBR-28"],
  },
  {
    primary_id: "D0G4ZG",
    names: ["PMID2153213C2f"],
  },
  {
    primary_id: "D0G4ZL",
    names: [
      "3,4-Dimethyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL185161",
      "3,4-Dimethylpyrrolidin-2-On Imine",
      "BDBM50150928",
    ],
  },
  {
    primary_id: "D0G4ZT",
    names: ["CP-294838", "CP 294838", "CP294838"],
  },
  {
    primary_id: "D0G5AG",
    names: [
      "Mizoribine",
      "Bredinin",
      "Bredinine",
      "MZR",
      "Mizoribina",
      "Mizoribinum",
      "M 3047",
      "Bredinin (TN)",
      "HE-69",
      "HS-0046",
      "Mizoribina [INN-Spanish]",
      "Mizoribine [INN:JAN]",
      "Mizoribinum [INN-Latin]",
      "Mizoribine (JAN/INN)",
      "Bredinin, HE 69, NSC 289637, MZB, Mizoribine",
      "Anhydro-4-Carbamoyl-5-Hydroxy-1-Beta-D-Ribofuranosyl-Imidazolium Hydroxide",
      "N'-(Beta-D-Ribofuranosyl)-5-Hydroxyimidazole-4-Carboxamide",
      "N'-(Beta-D-Ribofuranosyl)-5-Hydroxy-Imida-Zole-4-Carboxamide",
      "1-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-5-Hydroxyimidazole-4-Carboxamide;4-Carbamoyl-1-Beta-D-Ribofuranosyl-Imidazolium-5-Olate",
      "4-Carbamoyl-1-B-D-Ribofuranosylimidazolium-5-Olate",
      "5-Hydroxy-1-Beta-D-Ribofuranosyl-1H-Imidazole-4-Carboxamide",
      "5-Hydroxy-1-Beta-D-Ribofuranosylimidazole-4-Carboxamide",
    ],
  },
  {
    primary_id: "D0G5BI",
    names: ["Lamivudine /Lopinavir/Ritonavir Fixed-Dose Combination"],
  },
  {
    primary_id: "D0G5BK",
    names: [
      "Octafluoropropane Perflutren Lipid Microsphere",
      "Definity",
      "Luminity",
      "Octafluoropropane",
      "Perflutren",
      "Perflutren Lipid Microsphere",
      "DM-115",
      "DMP-115",
      "FS-069",
      "FS-69",
      "MRX-115",
      "YM-454",
      "Perflutren (Aerosomes), Lantheus",
      "Perflutren (Aerosomes), Bristol-Myers Squibb Medical Imaging",
      "Perflutren (Aerosomes), ImaRx/DuPont",
    ],
  },
  {
    primary_id: "D0G5BL",
    names: ["RG7880"],
  },
  {
    primary_id: "D0G5CF",
    names: [
      "Doxercalciferol",
      "Doxcercalciferol",
      "Hectorol",
      "Doxercalciferol [INN]",
      "TSA 840",
      "BCI-101",
      "Doxercalciferol (INN)",
      "Hectorol (TN)",
      "(1R,3S,5Z)-5-[(2E)-2-[(1R,3aS,7aR)-1-[(E,2R,5R)-5,6-Dimethylhept-3-En-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylidenecyclohexane-1,3-Diol",
      "(5Z,7E,22E)-9,10-Secoergosta-5,7,10(19),22-Tetraene-1alpha,3beta-Diol",
      "1-Hydroxyergocalciferol",
      "1-Alpha-Hydroxyvitamin D2",
      "1alpha-Hydroxyergocalciferol",
      "1alpha-OH-D2",
      "9,10-Secoergosta-5,7,10(19),22-Tetraene-1,3-Diol,(1-Alpha,3-Beta,5Z,7E,22E)",
    ],
  },
  {
    primary_id: "D0G5CI",
    names: [
      "5'-Deoxy-5'-Phenyladenophostin A",
      "CHEMBL1161890",
      "3'-O-(3-O,4-O-Diphosphono-Alpha-D-Glucopyranosyl)-5'-Phenyl-5'-Deoxy-2'-Adenylic Acid",
    ],
  },
  {
    primary_id: "D0G5CP",
    names: ["4'-(6-Methoxypyridin-3-Yl)Biphenyl-3-Ol", "CHEMBL1097417", "BDBM50318417"],
  },
  {
    primary_id: "D0G5CS",
    names: ["PINOCEMBRIN"],
  },
  {
    primary_id: "D0G5CV",
    names: ["Resorcinol Compound 1"],
  },
  {
    primary_id: "D0G5DH",
    names: ["Diamide 7", "CHEMBL1770298", "GTPL5830", "BDBM50418334"],
  },
  {
    primary_id: "D0G5DK",
    names: ["ONO-1301"],
  },
  {
    primary_id: "D0G5DU",
    names: ["4-Propyl-1-[3-(Phenylpropoxy)Propyl]piperidine", "CHEMBL462598"],
  },
  {
    primary_id: "D0G5EI",
    names: ["Indoline Derivative 11"],
  },
  {
    primary_id: "D0G5ET",
    names: ["Velaglucerase Alfa"],
  },
  {
    primary_id: "D0G5FR",
    names: [
      "KST-213",
      "Anniahlase",
      "KST-213 (Liquid Crystal Pharmaceutical, Cancer/Microbiological Infections), IC-MedTech",
    ],
  },
  {
    primary_id: "D0G5FS",
    names: ["Bidentate Pyrazolopyrimidine Acetamide Analog 3"],
  },
  {
    primary_id: "D0G5FV",
    names: ["Thiostrepton"],
  },
  {
    primary_id: "D0G5GB",
    names: ["MGAH22"],
  },
  {
    primary_id: "D0G5GJ",
    names: [
      "R-68151",
      "1-Ethyl-3-{4-[4-(4-Hydroxy-Phenyl)-Piperazin-1-Yl]-Phenyl}-5,5-Dimethyl-2-Thioxo-Imidazolidin-4-One",
      "5-Lipoxygenase-In-1",
      "R 68151",
      "CHEMBL85599",
      "138331-04-1",
      "AC1L30HG",
      "SCHEMBL13696698",
      "DTXSID10160583",
      "BDBM50006793",
      "HY-U00308",
      "CS-7300",
      "(1-Ethyl-3(4-(4-(4-Hydroxyphenyl)-1-Piperazinyl)Phenyl)-5-Dimethyl)-2-Thioxo-4-Imidazolidinone",
      "1-Ethyl-3-[4-[4-(4-Hydroxyphenyl)Piperazin-1-Yl]phenyl]-5,5-Dimethyl-2-Sulfanylideneimidazolidin-4-One",
      "M55551",
    ],
  },
  {
    primary_id: "D0G5GV",
    names: ["[123I]iodoproxyfan"],
  },
  {
    primary_id: "D0G5HI",
    names: ["PF-06678552"],
  },
  {
    primary_id: "D0G5IT",
    names: ["Amylin Receptor Agonists (Obesity/Diabetes)"],
  },
  {
    primary_id: "D0G5JW",
    names: ["Erythropoietin"],
  },
  {
    primary_id: "D0G5LX",
    names: ["Liposomal Tretinoin"],
  },
  {
    primary_id: "D0G5LY",
    names: ["SCP-1"],
  },
  {
    primary_id: "D0G5LZ",
    names: ["GNF-PF-1591"],
  },
  {
    primary_id: "D0G5ME",
    names: ["Telcagepant"],
  },
  {
    primary_id: "D0G5ML",
    names: [
      "Donitriptan",
      "Donitriptan Mesylate",
      "F-11356",
      "F-12640",
      "Migraine Therapy, Pierre Fabre",
      "5-HT1D Agonist, Pierre Fabre",
    ],
  },
  {
    primary_id: "D0G5MS",
    names: ["MANGOSTANOL"],
  },
  {
    primary_id: "D0G5MT",
    names: ["11-Deoxy-PGE1", "11-Deoxyprostaglandin E1", "Doproston"],
  },
  {
    primary_id: "D0G5NE",
    names: ["(Z)-8-(3-Chlorostyryl)Caffeine", "CHEMBL483243"],
  },
  {
    primary_id: "D0G5OE",
    names: ["[131I]-BC8"],
  },
  {
    primary_id: "D0G5OV",
    names: ["WAY-255348"],
  },
  {
    primary_id: "D0G5PF",
    names: ["AFQ056"],
  },
  {
    primary_id: "D0G5PK",
    names: ["3-Cyclohexyl-1-Propyl-Piperidine Hydrochloride"],
  },
  {
    primary_id: "D0G5PT",
    names: [
      "DEMETHYLMEDICARPIN",
      "3,9-Dihydroxypterocarpan",
      "Demethylmedicarpin",
      "61135-91-9",
      "(6aR,11aR)-3,9-Dihydroxypterocarpan",
      "CHEMBL1098413",
      "CHEBI:15648",
      "(6aR-Cis)-6a,11a-Dihydro-6H-Benzofuro(3,2-C)(1)Benzopyran-3,9-Diol",
      "(6aR,11aR)-6a,11a-Dihydro-6H-Benzo[4,5]furo[3,2-C]chromene-3,9-Diol",
      "C04271",
      "AC1Q70TU",
      "AC1L4S7L",
      "(-)-3,9-Dihydroxypterocarpan",
      "DTXSID80210024",
      "ZINC902004",
      "BDBM50317431",
      "AKOS032948673",
      "6h-Benzofuro(3,2-C)(1)Benzopyran-3,9-Diol, 6a,11a-Dihydro-,(6ar-Cis)-",
      "W2072",
      "(6aR,11aR)-6a,11a-Dihydro-6H-[1]benzofuro[3,2-C]chromene-3,9-Diol",
    ],
  },
  {
    primary_id: "D0G5QB",
    names: [
      "Alfentanil",
      "Alfenta",
      "Alfentanilum",
      "Alfentanyl",
      "Alfenta (TN)",
      "Alfentanil (INN)",
      "Alfentanil [INN:BAN]",
      "Alfentanilum [INN-Latin]",
      "Rapifen (TN)",
      "N-[1-[2-(4-Ethyl-5-Oxo-2-Tetrazolin-1-Yl)Ethyl]-4-(Methoxymethyl)-4-Piperidyl]propionanilide",
      "N-[1-[2-(4-Ethyl-5-Oxotetrazol-1-Yl)Ethyl]-4-(Methoxymethyl)Piperidin-4-Yl]-N-Phenylpropanamide",
      "N-(1-(2-(4-Ethyl-4,5-Dihydro-5-Oxotetrazolyl)Ethyl)-4-(Methoxymethyl)-4-Piperidyl)Propionanilid",
      "N-(1-(2-(4-Ethyl-5-Oxo-2-Tetrazolin-1-Yl)Ethyl)-4-(Methoxymethyl)-4-Piperidyl)Propionanilide",
      "N-(1-(2-(4-Ethyl-4,5-Dihydro-5-Oxotetrazolyl)Ethyl)-4-(Methoxymethyl)-4-Piperidyl)-N-Phenylpropionamid",
      "N-{1-[2-(4-Ethyl-5-Oxo-4,5-Dihydro-1H-Tetrazol-1-Yl)Ethyl]-4-(Methoxymethyl)Piperidin-4-Yl}-N-Phenylpropanamide",
      "N-{1-[2-(4-Ethyl-5-Oxo-4,5-Dihydro-1H-Tetrazol-1-Yl)Ethyl]-4-[(Methyloxy)Methyl]piperidin-4-Yl}-N-Phenylpropanamide",
    ],
  },
  {
    primary_id: "D0G5QX",
    names: ["TRIMEDOXIME"],
  },
  {
    primary_id: "D0G5RH",
    names: ["WIN-35066-2"],
  },
  {
    primary_id: "D0G5RQ",
    names: [
      "AGX-51",
      "AGX-8",
      "Small Molecule Id Protein Inhibitors (Oral, Cancer)",
      "Differentiation Protein Inhibitor Inhibitors (Oral, Cancer), AngioGenex",
      "Small Molecule Id Protein Inhibitors (Oral, Cancer), AngioGenex",
      "DNA-Binding Protein Inhibitor Inhibitors (Oral, Cancer), AngioGenex",
    ],
  },
  {
    primary_id: "D0G5RT",
    names: ["MC-113"],
  },
  {
    primary_id: "D0G5SK",
    names: ["Anti-CD19 CAR T Cells"],
  },
  {
    primary_id: "D0G5SM",
    names: ["HALOXYSTEROL A"],
  },
  {
    primary_id: "D0G5SU",
    names: ["Paquinimod", "ABR-215757", "ABR-25757", "Project 57-57, Active Biotech"],
  },
  {
    primary_id: "D0G5TD",
    names: ["(S)-1-(3-Methoxyphenethyl)-2-Methylpyrrolidine", "CHEMBL272306"],
  },
  {
    primary_id: "D0G5TL",
    names: ["6-Hydroxy-1,2,9-Trimethyl-9H-Beta-Carbolin-2-Ium", "CHEMBL441868"],
  },
  {
    primary_id: "D0G5TU",
    names: ["LESOPITRON DIHYDROCHLORIDE"],
  },
  {
    primary_id: "D0G5UB",
    names: [
      "Amlexanox",
      "ANW",
      "Amlenanox",
      "Amlexanoxo",
      "Amlexanoxum",
      "Amoxanox",
      "Aphthasol",
      "Aptheal",
      "Apthera",
      "Elics",
      "OraDisc",
      "OraRinse",
      "Solfa",
      "Amlexanoxo [Spanish]",
      "Amlexanoxum [Latin]",
      "GlaxoSmithKline Brand Of Amlexanox",
      "OraDisc A",
      "AA 673",
      "CHX 3673",
      "AA-673",
      "Aphthasol (TN)",
      "CHX-3673",
      "Solfa (TN)",
      "Amlexanox [USAN:INN:JAN]",
      "Amlexanox (JAN/USAN/INN)",
      "2-Amino-7-Isopropyl-5-Oxo-5H-(1)Benzopyrano(2,3-B)Pyridine-3-Carboxylic Acid",
      "2-Amino-5-Oxo-7-(Propan-2-Yl)-5H-Chromeno[2,3-B]pyridine-3-Carboxylic Acid",
      "2-Amino-5-Oxo-7-Propan-2-Ylchromeno[2,3-B]pyridine-3-Carboxylic Acid",
      "2-Amino-7-(1-Methylethyl)-5-Oxo-5H-Chromeno[2,3-B]pyridine-3-Carboxylic Acid",
      "2-Amino-7-Isopropyl-5-Oxo-5H-(1)Benzopyrano(2,3b)Pyridine-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0G5UD",
    names: ["Ro 46-8443"],
  },
  {
    primary_id: "D0G5UH",
    names: ["ER-21041"],
  },
  {
    primary_id: "D0G5UR",
    names: ["PMID26924192-Compound-52"],
  },
  {
    primary_id: "D0G5UW",
    names: ["Saruplase"],
  },
  {
    primary_id: "D0G5UZ",
    names: ["BVD-CNV", "C. Novyi-NT"],
  },
  {
    primary_id: "D0G5VD",
    names: ["8-Hydroxy-2-(4-Nitro-Phenyl)-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0G5VO",
    names: [
      "9-(4-Hydroxybutyl)-N2-Phenylguanine",
      "9-(4-HYDROXYBUTYL)-N2-PHENYLGUANINE",
      "HBPG",
      "CHEMBL406254",
      "BPG",
      "6H-Purin-6-One, 1,9-Dihydro-9-(4-Hydroxybutyl)-2-(Phenylamino)-",
      "161363-19-5",
      "1QHI",
      "AC1L9LM8",
      "SCHEMBL1506775",
      "SCHEMBL17485742",
      "CTK0A9784",
      "BDBM21866",
      "DTXSID00332272",
      "AKOS030558959",
      "N2-Phenyl-9-(4-Hydroxybutyl) Guanine",
      "DB02495",
      "9-(4-Hydroxybuthyl)-N2-Phenylguanine",
      "2-Anilino-9-(4-Hydroxybutyl)-1H-Purin-6-One",
      "2-Anilino-9-(4-Hydroxybutyl)-3H-Purin-6-One",
      "9-(4-Hydroxybutyl)-2-(Phenylamino)-1,9-Dihydro-6H-Purin-6-One",
    ],
  },
  {
    primary_id: "D0G5VT",
    names: ["Nazvax-PX"],
  },
  {
    primary_id: "D0G5WV",
    names: ["KTE-C19 CAR"],
  },
  {
    primary_id: "D0G5YA",
    names: ["(S)-N2-{4-[(4-Chlorobenzyl)Oxy]benzyl}alaninamide", "CHEMBL397987"],
  },
  {
    primary_id: "D0G5YI",
    names: [
      "Di(1H-Indol-2-Yl)Methanone",
      "CHEMBL207483",
      "200706-56-5",
      "Indolyl Ketone",
      "Di(1H-Indole-2-Yl) Ketone",
      "SCHEMBL370573",
      "Methanone, Di-1H-Indol-2-Yl-",
      "ZINC24262",
      "CTK0J0872",
      "DTXSID70436953",
    ],
  },
  {
    primary_id: "D0G5YR",
    names: [
      "SAR-566658",
      "CA6-Targeted DM4 Immunotoxin (Cancer), Sanofi-Aventis",
      "DM4-Conjugated DS6 MAb (Cancer), Sanofi-Aventis",
      "DM4-Conjugated Anti-Muc1 Monoclonal Antibody (Cancer), Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D0G5ZA",
    names: ["MVA-BN RSV"],
  },
  {
    primary_id: "D0G5ZE",
    names: ["MDL-28170"],
  },
  {
    primary_id: "D0G5ZF",
    names: ["N6-Methoxy-2-[(2-Pyridinyl)Ethynyl]adenosine", "CHEMBL223327", "SCHEMBL4380052", "BDBM50205697"],
  },
  {
    primary_id: "D0G5ZL",
    names: ["U-93385", "(-)-Cis-3-Propyl-2,3,3a(R),4,5,9b-Hexahydro-1H-Benz[e]indole-9-Carboxamide"],
  },
  {
    primary_id: "D0G6AB",
    names: ["Monocrotaline"],
  },
  {
    primary_id: "D0G6AQ",
    names: [
      "2-(3-Bromophenyl)Histamine",
      "CHEMBL26116",
      "GTPL1194",
      "SCHEMBL15974857",
      "BDBM22863",
      "PDSP2_001292",
      "PDSP1_001308",
      "AKOS022562838",
      "AKOS023977997",
      "2-(3-Bromophenyl)-1H-Imidazole-4-(Ethanamine)",
      "2-[2-(3-Bromophenyl)-3H-Imidazol-4-Yl]ethanamine",
    ],
  },
  {
    primary_id: "D0G6BG",
    names: ["2-Benzylbenzo[f]chromen-3-One", "CHEMBL513763", "SCHEMBL15398169", "BDBM50278257"],
  },
  {
    primary_id: "D0G6BI",
    names: [
      "2-(3''-(6''-Bromo-Indolyl)Ethyloxy)Adenosine",
      "CHEMBL225459",
      "MRS-3997",
      "BDBM50208794",
      "2-(3''''-(6''''-Bromo-Indolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D0G6BJ",
    names: ["1-(3,4-DICHLOROPHENYL)-6-(METHOXYMETHYL)-3-AZABICYCLO[4.1.0]HEPTANE (ENANTIOMERIC MIX)"],
  },
  {
    primary_id: "D0G6BU",
    names: ["4pox"],
  },
  {
    primary_id: "D0G6CD",
    names: ["2,7-Bis[2-(Diethylamino)Acetamido]anthraquinone", "SCHEMBL4305307", "CHEMBL493878"],
  },
  {
    primary_id: "D0G6CF",
    names: ["B-701"],
  },
  {
    primary_id: "D0G6CH",
    names: ["MD-990"],
  },
  {
    primary_id: "D0G6CT",
    names: ["Levonorgestrel"],
  },
  {
    primary_id: "D0G6CU",
    names: [
      "1,3-Propanediphosphonic Acid",
      "1,3-Propylenediphosphonic Acid",
      "Propane-1,3-Diylbis(Phosphonic Acid)",
      "3-Phosphonopropylphosphonic Acid",
      "CHEMBL1230579",
      "7702-51-4",
      "AC1L92ZA",
      "SCHEMBL182326",
      "Propane-1,3-Diphosphonic Acid",
      "CTK2H9782",
      "1,3-Propanediylbisphosphonic Acid",
      "ZINC31962397",
      "BDBM50326711",
      "AKOS025402728",
      "P2304",
      "I04-10331",
    ],
  },
  {
    primary_id: "D0G6DF",
    names: ["HMPL-004"],
  },
  {
    primary_id: "D0G6DH",
    names: ["PMID19111466C7d"],
  },
  {
    primary_id: "D0G6DR",
    names: ["NS-065"],
  },
  {
    primary_id: "D0G6DS",
    names: ["8-Hexyloxy-Quinolin-2-Ylamine", "CHEMBL187295", "SCHEMBL5884086"],
  },
  {
    primary_id: "D0G6DW",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 3"],
  },
  {
    primary_id: "D0G6EB",
    names: [
      "MDX-1097",
      "CKap",
      "MKap",
      "K-1-21",
      "Mab Therapy, Immune System Therapeutics/Medarex",
      "Monoclonal Antibody (Multiple Myeloma, Humanized/Murine/Chimeric), PacMab/Medarex",
    ],
  },
  {
    primary_id: "D0G6EI",
    names: ["CP-533536"],
  },
  {
    primary_id: "D0G6EO",
    names: ["Hemoximer"],
  },
  {
    primary_id: "D0G6ES",
    names: ["TAK-218"],
  },
  {
    primary_id: "D0G6GB",
    names: ["Veglin"],
  },
  {
    primary_id: "D0G6GC",
    names: ["Tyr-D-Ala-Gly-Phe-Met-Pro-Leu-Trp-NH-Bzl", "CHEMBL448998"],
  },
  {
    primary_id: "D0G6HA",
    names: ["Taliglucerase Alfa", "Elelyso (TN)"],
  },
  {
    primary_id: "D0G6HC",
    names: ["JG-365", "(S)-JG-365", "127231-42-9", "132748-20-0"],
  },
  {
    primary_id: "D0G6HI",
    names: ["Friulimicin Topical"],
  },
  {
    primary_id: "D0G6HZ",
    names: ["PD-29361"],
  },
  {
    primary_id: "D0G6IG",
    names: ["3-Butoxycarbonyl-6-Ethyl-4-Quinolone", "CHEMBL206591"],
  },
  {
    primary_id: "D0G6IH",
    names: ["LIBS Project"],
  },
  {
    primary_id: "D0G6IO",
    names: [
      "1-(4-Bromophenyl)-3-Hydroxyurea",
      "CHEMBL259767",
      "89677-50-9",
      "AC1N5XPQ",
      "AC1Q7DJ1",
      "SCHEMBL5443582",
      "QFELVVGTIKGYJL-UHFFFAOYSA-N",
      "ZINC396739",
      "N-(4-Bromophenyl)-N'-Hydroxyurea",
      "BDBM50377111",
      "AKOS024335516",
    ],
  },
  {
    primary_id: "D0G6IQ",
    names: ["PMID25666693-Compound-143"],
  },
  {
    primary_id: "D0G6IX",
    names: ["[D-Gln3,Ncy(SO,isopropyl)7]acyline", "CHEMBL425486"],
  },
  {
    primary_id: "D0G6JE",
    names: [
      "SCH-C",
      "Ancriviroc",
      "SCH-C &amp",
      "AOP-RANTES",
      "SCH 351125 &amp",
      "SCH 351125 (Non-Stereospecific)",
      "SCH 351125",
      "CHEMBL78535",
      "305792-46-5",
      "Methanone, (4-Bromophenyl)(1'-((2,4-Dimethyl-3-Pyridinyl)Carbonyl)-4'-Methyl(1,4'-Bipiperidin)-4-Yl)-, O-Ethyloxime, (Z)-, N1-Oxide &amp",
      "Methanone, (4-Bromophenyl)(1'-((2,4-Dimethyl-3-Pyridinyl)Carbonyl)-4'-Methyl(1,4'-Pipiperidin)-4-Yl)-, O-Ethyloxime, N1-Oxide",
      "1'-[(2,4-Dimethyl-1-Oxidopyridin-3-Yl)Carbonyl]-4'-Methyl-1,4'-Bipiperidine",
      "Methanone,",
      "SCH C",
      "SCH351125",
      "SC-351125",
      "SCH 351125 AOP-RANTES",
      "SCH-351125",
      "SCH-C Cpd",
      "AK671/SCH-C",
      "SCH 351125 & AOP-RANTES",
      "SCH-C & PA14",
      "SCH-C AOP-RANTES",
      "SCH-C & AOP-RANTES",
      "Methanone, (4-Bromophenyl)[1'-[(2,4-Dimethyl-3-Pyridinyl)Carbonyl]-4'-Methyl[1,4'-Bipiperidin]-4-Yl]-, O-Ethyloxime, (Z)-, N1-Oxide",
      "Methanone, (4-Bromophenyl)[1'-[(2,4-Dimethyl-3-Pyridinyl)Carbonyl]-4'-Methyl[1,4'-Bipiperidin]-4-Yl]-, O-Ethyloxime, (Z)-, N1-Oxide & AOP-RANTES",
      "Methanone, (4-Bromophenyl)[1'-[(2,4-Dimethyl-3-Pyridinyl)Carbonyl]-4'-Methyl[1,4'-Bipiperidin]-4-Yl]-, O-Ethyloxime, (Z)-, N1-Oxide & PRO 140 (Anti-CCR5 Monoclonal Antibody)",
      "Methanone, (4-Bromophenyl)(1'-((2,4-Dimethyl-3-Pyridinyl)Carbonyl)-4'-Methyl(1,4'-Bipiperidin)-4-Yl)-, O-Ethyloxime, (Z)-, N1-Oxide AOP-RANTES",
      "Methanone, (4-Bromophenyl)(1'-((2,4-Dimethyl-3-Pyridinyl)Carbonyl)-4'-Methyl(1,4'-Bipiperidin)-4-Yl)-, O-Ethyloxime, (Z)-, N1-Oxide & AOP-RANTES",
      "(2,4-Dimethyl-1-Oxy-Pyridin-3-Yl)-(4'-Methyl-[1,4']bipiperidinyl-1'-Yl)-Methanone",
      "4-((4-Bromophenyl)-(Ethoxyimino)Methyl)-1'-((2,4-Dimethyl-3-Pyridinyl)Carbonyl)-4'-Methyl-1,4'-Bipiperidine N-Oxide",
      "[3H]ancriviroc",
    ],
  },
  {
    primary_id: "D0G6JM",
    names: [
      "TTNPB",
      "Arotinoid Acid",
      "71441-28-6",
      "Arotinoic Acid",
      "Ro 13-7410",
      "CCRIS 3297",
      "UNII-673M8C29UR",
      "4-[(E)-2-(5,6,7,8-Tetrahydro-5,5,8,8-Tetramethyl-2-Naphthalenyl)-1-Propenyl]benzoic Acid",
      "Tocris-0761",
      "Lopac-T-3757",
      "TTNPB (Arotinoid Acid)",
      "BRN 2008247",
      "CHEMBL275311",
      "CHEBI:75261",
      "673M8C29UR",
      "4-(2-(5,6,7,8-Tetrahydro-5,5,8,8-Tetramethyl-2-Naphthalenyl)-1-Propenyl)Benzoic Acid",
      "(E)-4-(2-(5,6,7,8-Tetrahydro-5,5,8,8-Tetramethyl-2-Naphthalenyl)-1-Propen-1-Yl)Benzoic Acid",
    ],
  },
  {
    primary_id: "D0G6KH",
    names: ["NOX-S91"],
  },
  {
    primary_id: "D0G6LR",
    names: ["PTI-721"],
  },
  {
    primary_id: "D0G6MA",
    names: [
      "2-(4-Fluorophenyl)-7-Methoxy-4H-Chromen-4-One",
      "CHEMBL344504",
      "4'-Fluoro-7-Methoxyflavone",
      "SCHEMBL9142214",
      "ZINC27557271",
      "BDBM50310198",
    ],
  },
  {
    primary_id: "D0G6NA",
    names: ["REN-850"],
  },
  {
    primary_id: "D0G6NV",
    names: ["4-(6-Methoxy-1H-Indol-1-Ylsulfonyl)Benzenamine", "CHEMBL209724"],
  },
  {
    primary_id: "D0G6NY",
    names: ["4-Tert-Butyl-N-(Naphthalen-1-Yl)Thiazol-2-Amine", "CHEMBL379460", "SCHEMBL14433284", "ZINC36159661"],
  },
  {
    primary_id: "D0G6OL",
    names: ["MVI-118"],
  },
  {
    primary_id: "D0G6ON",
    names: ["PMID27376512-Compound-AsCEBP-1"],
  },
  {
    primary_id: "D0G6OU",
    names: ["Ca(2+) Chelator"],
  },
  {
    primary_id: "D0G6OW",
    names: ["Sodium 2,3,5,6-Tetrafluorobenzoate"],
  },
  {
    primary_id: "D0G6PG",
    names: ["CPA-926", "6-(2-Acetamido-2-Deoxy-Beta-D-Glucopyranosyloxy)-7-Hydroxy-2H-1-Benzopyran-2-One"],
  },
  {
    primary_id: "D0G6PL",
    names: ["LG-2293"],
  },
  {
    primary_id: "D0G6PS",
    names: ["PMID17574411C42"],
  },
  {
    primary_id: "D0G6QF",
    names: ["Vandetanib"],
  },
  {
    primary_id: "D0G6QL",
    names: [
      "1-Naphthalen-2-Yl-1H-Imidazole",
      "1-(Naphthalen-2-Yl)-1H-Imidazole",
      "1-(2-Naphthyl)-1h-Imidazole",
      "25372-01-4",
      "1H-Imidazole, 1-(2-Naphthalenyl)-",
      "SCHEMBL2071832",
      "CHEMBL195996",
      "BDBM8916",
      "MolPort-009-323-053",
      "Imidazole-Substituted Naphthalene 23",
      "ZINC13674480",
      "AKOS034336039",
      "MCULE-9753314459",
    ],
  },
  {
    primary_id: "D0G6QV",
    names: ["Albaconazole"],
  },
  {
    primary_id: "D0G6QW",
    names: ["PMID26161698-Compound-44"],
  },
  {
    primary_id: "D0G6RB",
    names: ["Cintredekin Besudotox"],
  },
  {
    primary_id: "D0G6RI",
    names: [
      "4-Hydroxyphenylpyruvic Acid",
      "156-39-8",
      "3-(4-Hydroxyphenyl)-2-Oxopropanoic Acid",
      "Testacid",
      "P-Hydroxyphenylpyruvic Acid",
      "4-Hydroxyphenylpyruvate",
      "Testacide",
      "3-(4-Hydroxyphenyl)Pyruvic Acid",
      "(P-HYDROXYPHENYL)PYRUVIC ACID",
      "3-(P-Hydroxyphenyl)Pyruvic Acid",
      "3-(P-Hydroxyphenyl)-2-Oxopropanoic Acid",
      "3-(4-HYDROXY-PHENYL)PYRUVIC ACID",
      "(P-Hydroxyphenyl)-Pyruvic Acid",
      "Pyruvic Acid, P-Hydroxyphenyl-",
      "NSC 100738",
      "4-Hydroxy Alpha-Oxobenzenepropanoic Acid",
      "Pyruvic Acid, (P-Hydroxyphenyl)-",
      "P-Hydroxyphenylpyruvate",
    ],
  },
  {
    primary_id: "D0G6RK",
    names: ["ER-23006"],
  },
  {
    primary_id: "D0G6RN",
    names: [
      "S-OOPP",
      "CHEMBL1214256",
      "SCHEMBL3768627",
      "GTPL6493",
      "UUEMOTWYJKOUPP-JTQLQIEISA-N",
      "BDBM50416509",
      "(S)-N-(2-Oxooxetan-3-Yl)-3-Phenylpropionamide",
      "N-[(3s)-2-Oxo-3-Oxetanyl]-3-Phenylpropanamide",
      "N-[(3S)-2-Oxooxetan-3-Yl]-3-Phenylpropanamide",
    ],
  },
  {
    primary_id: "D0G6RU",
    names: ["NE-1530"],
  },
  {
    primary_id: "D0G6SA",
    names: ["6,7-Dimethoxy-3-Phenyl-Quinoline", "CHEMBL66063", "SCHEMBL8177777", "ZINC3834036"],
  },
  {
    primary_id: "D0G6SD",
    names: [
      "Delavirdine",
      "DLV",
      "Rescriptor",
      "SPP",
      "DELAVIRDINE MESYLATE",
      "Delavirdine [INN]",
      "U 90152",
      "BHAP-U 90152",
      "Delavirdine (INN)",
      "Rescriptor (TM);Rescriptor (TN)",
      "U-90152",
      "U-90152S",
      "PNU-90152-T",
      "Delavirdine (*Mesylate Salt*)",
      "U90152S (*Mesylate Salt*)",
      "Delavirdine(U-90152) & .a.IFN",
      "N-[2-[4-[3-(Propan-2-Ylamino)Pyridin-2-Yl]piperazine-1-Carbonyl]-1H-Indol-5-Yl]methanesulfonamide",
      "N-(2-(1-(3-(Isopropylamino)Pyridin-2-Yl)Piperazine-4-Carbonyl)-1H-Indol-5-Yl)Methanesulfonamide",
      "N-[2-({4-[3-(Propan-2-Ylamino)Pyridin-2-Yl]piperazin-1-Yl}carbonyl)-1H-Indol-5-Yl]methanesulfonamide",
      "N-{2-[(4-{3-[(1-Methylethyl)Amino]pyridin-2-Yl}piperazin-1-Yl)Carbonyl]-1H-Indol-5-Yl}methanesulfonamide",
      "N-{2-[4-(3-Isopropylamino-Pyridin-2-Yl)-Piperazine-1-Carbonyl]-1H-Indol-5-Yl}-Methanesulfonamide",
      "Piperazine, 1-[3-[(1-Methylethyl)Amino]-2-Pyridinyl]-4-[[5-[(Methylsulfonyl)Amino]-1H-Indol-2-Yl]carbonyl]-& Alpha-Interferon",
      "(1-(5-METHANSULPHONAMIDO-1H-INDOL-2-YL-CARBONYL)4-[METHYLAMINO)PYRIDINYL]PIPERAZINE",
      "(N-[2-[4-[3-(1-Methylethylamino)Pyridin-2-Yl]piperazin-1-Yl]carbonyl-1H-Indol-5-Yl] Methanesulfonamide)",
      "1-(3-((1-Methylethyl)Amino)-2-Pyridinyl)-4-((5-((Methylsulfonyl)Amino)-1H-Indol-2-Yl)Carbonyl)Piperazine",
      "1-(5-Methanesulphonamido)-1H-Indol-2-Yl-Carbonyl)-4-[3-(Isopropylamino)-2-Pyridinyl]piperaz",
      "2-(4-(5-Methanesulfonamido-1H-Indol-2-Ylcarbonyl)-1-Piperazinyl)-N-(1-Methylethyl)-3-Pyridinamine",
    ],
  },
  {
    primary_id: "D0G6SE",
    names: ["ANGIOTENSIN II"],
  },
  {
    primary_id: "D0G6SL",
    names: ["MRS1062"],
  },
  {
    primary_id: "D0G6SP",
    names: ["KNI-10529"],
  },
  {
    primary_id: "D0G6SZ",
    names: [
      "GW873140",
      "Aplaviroc",
      "Aplaviroc HCl",
      "Aplaviroc Hydrochloride",
      "AK 602",
      "AK602",
      "GSK873140",
      "GW 873140",
      "ONO 4128",
      "AK-602",
      "Aplaviroc Hydrochloride (USAN)",
      "GW-873140",
      "ONO-4128",
      "Benzoic Acid, 4-[4-[[1-Butyl-3-[(R)-Cyclohexylhydroxymethyl]-2,5-Dioxo-1,4,9-Triazaspiro[5.5]undec-9-Yl]methyl]phenoxy]-& Soluble CD4",
      "4-[4-[[(9R)-11-Butyl-9-[(R)-Cyclohexyl(Hydroxy)Methyl]-7,10-Dioxo-3,8,11-Triazaspiro[5.5]undecan-3-Yl]methyl]phenoxy]benzoic Acid",
      "4-[4-[[(9R)-11-Butyl-9-[(R)-Cyclohexyl(Hydroxy)Methyl]-7,10-Dioxo-3,8,11-Triazaspiro[5.5]undecan-3-Yl]methyl]phenoxy]benzoic Acid Hydrochloride",
      "873140 Compound",
    ],
  },
  {
    primary_id: "D0G6TL",
    names: ["DiaPep-277", "Diaccine", "AVE-0277", "AVE-277", "Antidiabetic, Peptor"],
  },
  {
    primary_id: "D0G6TR",
    names: ["RI-TPO"],
  },
  {
    primary_id: "D0G6TV",
    names: ["Recombinant Human Lubricin"],
  },
  {
    primary_id: "D0G6TX",
    names: ["PYC-38"],
  },
  {
    primary_id: "D0G6UA",
    names: ["PMID25772215-Compound-WO2012177618M5"],
  },
  {
    primary_id: "D0G6UP",
    names: ["YM-75466"],
  },
  {
    primary_id: "D0G6UQ",
    names: ["EP-42675"],
  },
  {
    primary_id: "D0G6US",
    names: ["SLV-322"],
  },
  {
    primary_id: "D0G6VC",
    names: [
      "Cx-501",
      "Cultured Skin Film (Epidermolysis Bullosa), Cellerix",
      "Autologous Keratinocytes + Allogeneic Fibroblast Stem Cells (Skin Film, Epidermolysis Bullosa), Cellerix",
    ],
  },
  {
    primary_id: "D0G6VL",
    names: ["Sarpogrelate"],
  },
  {
    primary_id: "D0G6VT",
    names: [
      "2-(3''-(5''-Iodo-Indolyl)Ethyloxy)Adenosine",
      "CHEMBL222748",
      "BDBM50208791",
      "2-(3''''-(5''''-Iodo-Indolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D0G6WO",
    names: ["Benzamidrazone Analogue"],
  },
  {
    primary_id: "D0G6WQ",
    names: [
      "(1R)-MENTHYL HEXYL PHOSPHONATE GROUP",
      "AC1L9KBM",
      "DB08200",
      "Hexyl[(1R,2S,5R)-2-Isopropyl-5-Methylcyclohexyl]oxyphosphinic Acid",
      "Hexyl-[(1R,2S,5R)-5-Methyl-2-Propan-2-Ylcyclohexyl]oxyphosphinic Acid",
      "(1R,2S,5R)-5-Methyl-2-(1-Methylethyl)Cyclohexyl Hydrogen (S)-Hexylphosphonate",
    ],
  },
  {
    primary_id: "D0G6WW",
    names: ["Morrhuate Sodium"],
  },
  {
    primary_id: "D0G6XC",
    names: ["SW-33377"],
  },
  {
    primary_id: "D0G6XS",
    names: ["Fucose"],
  },
  {
    primary_id: "D0G6XT",
    names: ["Dioctanoylglycerol Pyrophosphate", "DGPP 8:0"],
  },
  {
    primary_id: "D0G6ZG",
    names: [
      "Amediplase",
      "Amediplase [INN]",
      "Trans,trans-3,4:12,13-Tetrahydroxy-3,4,12,13-Tetrahydro-Dibenz(A,h)Anthracene",
      "(3S,4S,12S,13S)-3,4,12,13-Tetrahydronaphtho[1,2-B]phenanthrene-3,4,12,13-Tetrol",
    ],
  },
  {
    primary_id: "D0G6ZS",
    names: ["DTwP-HepB Prophylactic Vaccine"],
  },
  {
    primary_id: "D0G6ZW",
    names: ["Panimun Bioral"],
  },
  {
    primary_id: "D0G7BR",
    names: ["AMAP-102", "5-HT Antagonist (Rheumatism), AnaMar"],
  },
  {
    primary_id: "D0G7CG",
    names: [
      "Cmp-2-Keto-3-Deoxy-Octulosonic Acid",
      "CMP-KDO",
      "CMP-2-KETO-3-DEOXY-OCTULOSONIC ACID",
      "AC1L9IPX",
      "CMP-3-Deoxy-D-Manno-Octulosonate",
      "CMP-Beta-KDO",
      "Cytidine 5'-Monophosphate 3-Deoxy-Beta-D-Gulo-Oct-2-Ulo-Pyranosonic Acid",
      "C04121",
      "CHEBI:86284",
      "DB04482",
      "CMP-3-Deoxy-Beta-D-Manno-Octulosonic Acid",
      "Cytidine 5'-[(3-Deoxy-Beta-D-Manno-Oct-2-Ulopyranosonyl) Hydrogen Phosphate]",
    ],
  },
  {
    primary_id: "D0G7CO",
    names: ["Ac-WVTHRLAGLLS[Cit]SGGVVRKNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D0G7DF",
    names: ["4-Phenyl-1-(Phenyl(M-Tolyl)Methyl)Piperidin-4-Ol", "CHEMBL390279"],
  },
  {
    primary_id: "D0G7DJ",
    names: ["Etidocaine", "Duranest"],
  },
  {
    primary_id: "D0G7DP",
    names: ["RP-316"],
  },
  {
    primary_id: "D0G7FB",
    names: ["MCL-147"],
  },
  {
    primary_id: "D0G7FJ",
    names: ["Meloxicam"],
  },
  {
    primary_id: "D0G7FS",
    names: ["ASKH95"],
  },
  {
    primary_id: "D0G7FX",
    names: ["SiRNA Therapy, AMD"],
  },
  {
    primary_id: "D0G7GF",
    names: ["3'-Amino-4-(1,1-Dimethyl-Heptyl)-Biphenyl-2-Ol", "CHEMBL237897", "BDBM50213160"],
  },
  {
    primary_id: "D0G7GN",
    names: ["Phenylpropylamine Derivative 5"],
  },
  {
    primary_id: "D0G7HF",
    names: ["(4-Hexylphenyl)(Oxiran-2-Yl)Methanone"],
  },
  {
    primary_id: "D0G7HK",
    names: ["Benzothiazepine Analog 8"],
  },
  {
    primary_id: "D0G7HN",
    names: ["4-Iodyl-Benzoic Acid", "4-Iodylbenzoic Acid", "CHEMBL119805", "AC1MW5HG", "SCHEMBL3123027"],
  },
  {
    primary_id: "D0G7HO",
    names: ["NS-649"],
  },
  {
    primary_id: "D0G7HQ",
    names: [
      "2,2-Difluoromevalonate 5-Diphosphate",
      "GTPL3213",
      "CHEMBL1162018",
      "2,2-Difluoro-3-Hydroxy-5-(Hydroxy-Phosphonooxyphosphoryl)Oxy-3-Methylpentanoic Acid",
    ],
  },
  {
    primary_id: "D0G7IF",
    names: ["VLX1570"],
  },
  {
    primary_id: "D0G7IR",
    names: [
      "Cytosine",
      "71-30-7",
      "4-Amino-2-Hydroxypyrimidine",
      "Cytosinimine",
      "4-Amino-2(1H)-Pyrimidinone",
      "6-Aminopyrimidin-2(1h)-One",
      "2(1H)-Pyrimidinone, 4-Amino-",
      "4-Aminopyrimidin-2(1H)-One",
      "2(1H)-Pyrimidinone, 6-Amino-",
      "Cyt",
      "4-Aminopyrimidin-2-Ol",
      "4-Amino-2-Oxo-1,2-Dihydropyrimidine",
      "Cytosin",
      "Zytosin",
      "AI3-52281",
      "UNII-8J337D1HZY",
      "6-Amino-1H-Pyrimidin-2-One",
      "4-Amino-1H-Pyrimidin-2-One",
      "CHEBI:16040",
      "4-Amino-2-Pyrimidinol",
      "EINECS 200-749-5",
      "NSC 27787",
      "2-Pyrimidinol, 4-Amino-",
      "8J337D1HZY",
      "OPTASPLRGRRNAP-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0G7IV",
    names: ["Premarin/Pravachol"],
  },
  {
    primary_id: "D0G7IY",
    names: [
      "Cromolyn Sodium",
      "Altoderm",
      "Cromolyn Sodium (Topical, Atopic Dermatitis)",
      "Cromolyn Sodium (Topical, Atopic Dermatitis), Manhattan Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0G7KE",
    names: ["JX-2011"],
  },
  {
    primary_id: "D0G7KJ",
    names: [
      "Diflorasone",
      "Diflorasona",
      "Diflorasonum",
      "Maxiflor",
      "Murode",
      "U 34865",
      "Diflorasona [INN-Spanish]",
      "Diflorasone (INN)",
      "Diflorasone [INN:BAN]",
      "Diflorasonum [INN-Latin]",
      "Murode (TN)",
      "(6S,8S,9R,10S,11S,13S,14S,16S,17R)-6,9-Difluoro-11,17-Dihydroxy-17-(2-Hydroxyacetyl)-10,13,16-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "(6alpha,11beta,16beta)-6,9-Difluoro-11,17,21-Trihydroxy-16-Methylpregna-1,4-Diene-3,20-Dione",
      "6a,9a-Difluoro-16b-Methylprednisolone",
      "6alpha,9-Difluoro-11beta,17,21-Trihydroxy-16beta-Methylpregna-1,4-Diene-3,20-Dione",
    ],
  },
  {
    primary_id: "D0G7KY",
    names: ["Imidazopyridazine Derivative 5"],
  },
  {
    primary_id: "D0G7LB",
    names: ["PMID25991433-Compound-L3"],
  },
  {
    primary_id: "D0G7LC",
    names: ["PENNVAX-6"],
  },
  {
    primary_id: "D0G7LE",
    names: [
      "4-Benzenesulfonyl-1-Phenethyl-Piperidine",
      "CHEMBL148280",
      "ZINC13472072",
      "BDBM50108703",
      "4-(Phenylsulfonyl)-1-Phenethylpiperidine",
    ],
  },
  {
    primary_id: "D0G7LP",
    names: [
      "Pepticlere",
      "DP-68",
      "DP-74",
      "Small Peptide Beta Amyloid Protein Fibril Formation Inhibitors, Proteotech",
      "Alzheimers Disease Therapy (Nasal Spray), ProteTech",
      "Small Peptide A-Beta Protein Fibril Formation Inhibitors, Proteotech",
      "Laminin Alpha Chain Derivatives (Alzheimer's Disease), Proteotech",
      "6-9 Mer Peptide Analogs (Alzheimer's Disease), Proteotech",
    ],
  },
  {
    primary_id: "D0G7LT",
    names: ["PD-AAV9", "Adeno-Associated Virus Vector-9 Based Gene Therapy (Injectable, PD), RegenX Biosciences"],
  },
  {
    primary_id: "D0G7MC",
    names: ["5-Hexyl-2-(Pyridin-2-Yloxy)Phenol", "CHEMBL264679"],
  },
  {
    primary_id: "D0G7MJ",
    names: ["NSC-640556"],
  },
  {
    primary_id: "D0G7MM",
    names: ["TA-8995", "CETP Inhibitor (Dyslipidemia), Mitsubishi Tanabe"],
  },
  {
    primary_id: "D0G7MU",
    names: ["Beleodaqbelinostat"],
  },
  {
    primary_id: "D0G7MV",
    names: ["INCB24360"],
  },
  {
    primary_id: "D0G7NE",
    names: [
      "2,7-Diaminoanthraquinone",
      "2,7-Diaminoanthrachinon",
      "BRN 2809381",
      "605-44-7",
      "2,7-Diaminoanthrachinon [Czech]",
      "2,7-Diaminoanthracene-9,10-Dione",
      "9,10-Anthracenedione, 2,7-Diamino- (9CI)",
      "ANTHRAQUINONE, 2,7-DIAMINO-",
      "9,10-Anthracenedione, 2,7-Diamino-",
      "AC1L1Y5O",
      "SCHEMBL3135129",
      "CHEMBL454957",
      "CTK5B1667",
      "DTXSID20209235",
      "KJNHYKBXQOSFJR-UHFFFAOYSA-N",
      "ZINC31483469",
      "9,10-Anthracenedione,2,7-Diamino-",
      "AKOS030561506",
    ],
  },
  {
    primary_id: "D0G7NW",
    names: ["Cyamemazine", "Cyamepromazine", "RP-7204"],
  },
  {
    primary_id: "D0G7OC",
    names: ["Hypoxia Therapeutic"],
  },
  {
    primary_id: "D0G7OZ",
    names: ["Pobilukast"],
  },
  {
    primary_id: "D0G7PB",
    names: ["N-Methyl-N-Ethyl Estradiol-16-Carboxamide"],
  },
  {
    primary_id: "D0G7QH",
    names: ["NeisVac-C"],
  },
  {
    primary_id: "D0G7QJ",
    names: ["3-(3-(Benzamido)-5-Nitrobenzamido)Propanoic Acid", "CHEMBL218226"],
  },
  {
    primary_id: "D0G7QT",
    names: ["EMD-386088", "Compound 18 [PMID 16055331]", "EMD 386088", "EMD386088"],
  },
  {
    primary_id: "D0G7RA",
    names: ["4-(4-Chlorophenyl)-5-P-Tolyl-3H-1,2-Dithiol-3-One", "CHEMBL504541"],
  },
  {
    primary_id: "D0G7RE",
    names: [
      "PD-137342",
      "CHEMBL2062144",
      "BDBM50422043",
      "4-[(R)-2-[(S)-2-(Adamantan-2-Yloxycarbonylamino)-3-(1H-Indol-3-Yl)-2-Methylpropanoylamino]-1-Phenylethylamino]-4-Oxobutyric Acid",
    ],
  },
  {
    primary_id: "D0G7SM",
    names: ["AEA-35p"],
  },
  {
    primary_id: "D0G7SP",
    names: ["SB-209247"],
  },
  {
    primary_id: "D0G7SR",
    names: ["Zinforo", "Ceftaroline"],
  },
  {
    primary_id: "D0G7TI",
    names: ["P38 MAP Kinase Alpha Inhibitors"],
  },
  {
    primary_id: "D0G7TT",
    names: [
      "D-AP4",
      "78739-01-2",
      "(2R)-2-Amino-4-Phosphonobutanoic Acid",
      "(R)-2-Amino-4-Phosphonobutanoic Acid",
      "D(-)-2-Amino-4-Phosphonobutyric Acid",
      "Tocris-0101",
      "Tocris-0102",
      "D-APB",
      "AC1LT3KN",
      "Lopac-A-1910",
      "GTPL1443",
      "SCHEMBL12648443",
      "CHEMBL1319383",
      "CTK2I0929",
      "BDBM82006",
      "MolPort-004-956-473",
      "ZINC1656225",
      "PDSP1_000361",
      "PDSP2_000359",
      "BN0083",
      "(R)-2-Amino-4-Phosphonobutyric Acid",
      "AKOS024456336",
      "FCH3604711",
      "API0009835",
      "NCGC00024467-01",
      "D-(-)-2-Amino-4-Phosphonobutyric Acid",
      "NCGC00024466-01",
      "NCGC00015041-02",
      "NCGC00015041-01",
      "B6201",
      "FT-0772107",
      "Butanoic Acid, 2",
    ],
  },
  {
    primary_id: "D0G7UV",
    names: ["VI-0134"],
  },
  {
    primary_id: "D0G7WR",
    names: ["Vaccine, Colorectal Tumor"],
  },
  {
    primary_id: "D0G7WY",
    names: ["Epanova"],
  },
  {
    primary_id: "D0G7XA",
    names: ["NB-002"],
  },
  {
    primary_id: "D0G7XO",
    names: ["Sevelamer Carbonate Tablet"],
  },
  {
    primary_id: "D0G7XT",
    names: ["Modified PT1.2"],
  },
  {
    primary_id: "D0G7XW",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 2"],
  },
  {
    primary_id: "D0G7YF",
    names: ["VX-467"],
  },
  {
    primary_id: "D0G7YG",
    names: ["ABX-MA1", "Anti-MUC18 MAb, Abgenix"],
  },
  {
    primary_id: "D0G7YS",
    names: ["ONYX-015", "Dl1520", "E1B-Deleted Adenovirus (Cancer), ONYX"],
  },
  {
    primary_id: "D0G7YZ",
    names: ["BMS-189664"],
  },
  {
    primary_id: "D0G7ZT",
    names: ["Anti-PSCA Mab"],
  },
  {
    primary_id: "D0G8AG",
    names: ["BN-82685"],
  },
  {
    primary_id: "D0G8BE",
    names: ["AMPPL"],
  },
  {
    primary_id: "D0G8BM",
    names: ["TG101348"],
  },
  {
    primary_id: "D0G8BN",
    names: [
      "CAPSAZEPINE",
      "Capsazepine",
      "138977-28-3",
      "Capsazepin",
      "N-[2-(4-Chlorophenyl)Ethyl]-1,3,4,5-Tetrahydro-7,8-Dihydroxy-2H-2-Benzazepine-2-Carbothioamide",
      "UNII-LFW48MY844",
      "N-[2-(4-Chlorophenyl)Ethyl]-7,8-Dihydroxy-1,3,4,5-Tetrahydro-2-Benzazepine-2-Carbothioamide",
      "CHEMBL391997",
      "LFW48MY844",
      "ST50826300",
      "CHEBI:70773",
      "N-[2-(4-Chlorophenyl)Ethyl]-7,8-Dihydroxy-1,3,4,5-Tetrahydro-2H-2-Benzazepine-2-Carbothioamide",
      "N-(2-[4-Chlorophenyl]ethyl)-1,3,4,5-Tetrahydro-7,8-Dihydroxy-2H-2-Benzazepine-2-Carbothioamide",
    ],
  },
  {
    primary_id: "D0G8BT",
    names: [
      "4,9-Dihydro-3H-Beta-Carboline",
      "4894-26-2",
      "4,9-Dihydro-3H-Pyrido[3,4-B]indole",
      "3H-Pyrido(3,4-B)Indole, 4,9-Dihydro-",
      "3,4-Dihydro-Beta-Carboline",
      "CHEMBL266221",
      "4,9-Dihydro-3H-Pyrido(3,4-B)Indole",
      "3H-Pyrido[3,4-B]indole, 4,9-Dihydro-",
      "AC1L3RVU",
      "3,4-Dihydrobeta-Carboline",
      "SCHEMBL678673",
      "CTK7D1379",
      "DTXSID20197643",
      "ZINC332024",
      "BDBM50136506",
      "SBB062953",
      "FCH841675",
      "AKOS006272808",
      "ACM4894262",
      "3H,4H,9H-PYRIDO[3,4-B]INDOLE",
      "TX-010240",
      "FT-0698471",
    ],
  },
  {
    primary_id: "D0G8BV",
    names: ["Guggulsterone"],
  },
  {
    primary_id: "D0G8CF",
    names: ["Benzo[d]oxazole Derivative 3"],
  },
  {
    primary_id: "D0G8CN",
    names: ["ISIS 114170"],
  },
  {
    primary_id: "D0G8CU",
    names: [
      "COTI-2",
      "Coti-2",
      "UNII-2BTA1O65BR",
      "2BTA1O65BR",
      "1039455-84-9",
      "ZINC114475331",
      "CS-8156",
      "HY-19896",
      "1-Piperazinecarbothioic Acid, 4-(2-Pyridinyl)-, 2-(6,7-Dihydro-8(5H)-Quinolinylidene)Hydrazide",
    ],
  },
  {
    primary_id: "D0G8CV",
    names: ["SLV-314"],
  },
  {
    primary_id: "D0G8DV",
    names: ["Ad5CMV-NIS"],
  },
  {
    primary_id: "D0G8EI",
    names: ["HL-1020"],
  },
  {
    primary_id: "D0G8FF",
    names: ["GRI977143"],
  },
  {
    primary_id: "D0G8FT",
    names: [
      "ML204",
      "5465-86-1",
      "ML 204",
      "4-Methyl-2-(Piperidin-1-Yl)Quinoline",
      "4-Methyl-2-(1-Piperidinyl)-Quinoline",
      "4-Methyl-2-Piperidin-1-Ylquinoline",
      "4-Methyl-2-(1-Piperidinyl)Quinoline",
      "VU0024172-3",
      "NSC25850",
      "AC1Q4W9R",
      "Cambridge Id 5563912",
      "AC1L5K4A",
      "MLS001007138",
      "4-Methyl-2-Piperidylquinoline",
      "4-Methyl-2-Piperidinoquinoline",
      "2-Piperidino-4-Methylquinoline",
      "GTPL4255",
      "Cid_230710",
      "4-Methyl-2-Piperidino-Quinoline",
      "SCHEMBL14975993",
      "CHEMBL1459962",
      "CTK5A2131",
      "BDBM77617",
      "DTXSID30282438",
      "EX-A938",
      "AOB1526",
      "MolPort-000-220-757",
      "HMS2704E20",
      "BCP28617",
    ],
  },
  {
    primary_id: "D0G8FU",
    names: ["CPS125", "GTPL6369", "SCHEMBL15257793"],
  },
  {
    primary_id: "D0G8GA",
    names: [
      "9-Phenyl-9H-Purin-6-Ylamine",
      "9-Phenylpurin-6-Amine",
      "CHEMBL62756",
      "20145-09-9",
      "9-Phenyladenine",
      "9H-Purin-6-Amine, 9-Phenyl-",
      "NSC516599",
      "AC1L6X8K",
      "6-Amino-9-Phenyl-9H-Purine",
      "SCHEMBL5146335",
      "CTK1A5420",
      "DTXSID30325733",
      "ZINC1604448",
      "BDBM50009689",
      "AKOS030561953",
    ],
  },
  {
    primary_id: "D0G8GL",
    names: ["Miller-9Z-Enolide"],
  },
  {
    primary_id: "D0G8HD",
    names: [
      "Sulfamic Acid 3-Benzoyl-Phenyl Ester",
      "CHEMBL63838",
      "SCHEMBL9451288",
      "WRPUJPPBHRVDQK-UHFFFAOYSA-N",
      "Sulfamic Acid 3-Benzoylphenyl Ester",
    ],
  },
  {
    primary_id: "D0G8HM",
    names: ["N-Cyclohexyl-N'-(4-Iodophenyl)Urea"],
  },
  {
    primary_id: "D0G8HX",
    names: ["3-Phenyl Isoquinolin-1(2H) Derivative 2"],
  },
  {
    primary_id: "D0G8IA",
    names: ["Cy3B-Telenzepine"],
  },
  {
    primary_id: "D0G8IH",
    names: ["4-(Methylamino)Naphthalen-1-Ol", "CHEMBL571240", "SCHEMBL10158309", "BDBM50303921"],
  },
  {
    primary_id: "D0G8IM",
    names: ["Isoxazole-Based Bicyclic Compound 9"],
  },
  {
    primary_id: "D0G8JI",
    names: ["Cyclo(RGDfV) (Control)", "CHEMBL206344"],
  },
  {
    primary_id: "D0G8JX",
    names: ["Pitavastatin Derivative 1"],
  },
  {
    primary_id: "D0G8KA",
    names: ["PMID:18445527C11"],
  },
  {
    primary_id: "D0G8KT",
    names: ["GBR-12289"],
  },
  {
    primary_id: "D0G8LB",
    names: ["Mersalyl", "[3-[[2-(Carboxymethoxy)Benzoyl]amino]-2-Methoxypropyl]hydroxymercury(II)"],
  },
  {
    primary_id: "D0G8LH",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 8"],
  },
  {
    primary_id: "D0G8LN",
    names: ["ISIS-SGLT2"],
  },
  {
    primary_id: "D0G8MW",
    names: ["BIOLUP", "Antiviral (Systemic Lupus Erythematosus), Lascco"],
  },
  {
    primary_id: "D0G8MY",
    names: ["SIM-0710"],
  },
  {
    primary_id: "D0G8NI",
    names: ["RO3"],
  },
  {
    primary_id: "D0G8NJ",
    names: [
      "Rescinnamine",
      "Anapral",
      "Anaprel",
      "Apolon",
      "Apoterin",
      "Cartric",
      "Cinamine",
      "Cinatabs",
      "Cinnaloid",
      "Cinnasil",
      "Moderil",
      "Normorescina",
      "Paresinan",
      "Raupyrol",
      "Raurescin",
      "Raurescine",
      "Recinnamine",
      "Recitensina",
      "Rescaloid",
      "Rescamin",
      "Rescidan",
      "Rescin",
      "Rescinamina",
      "Rescinnamin",
      "Rescinnamina",
      "Rescinnaminum",
      "Rescinpal",
      "Rescisan",
      "Rescitens",
      "Resealoid",
      "Reserpinene",
      "Reserpinin",
      "Reserpinine",
      "Resipal",
      "Reskinnamin",
      "Rozex",
      "Scinnamina",
      "Tenamine",
      "Tuareg",
      "Apoterin S",
      "Methyl Trimethoxycinnamoylreserpate",
      "Rescinnamina [DCIT]",
      "Trimethoxy Cinnamoyl Reserpate De Methyl",
      "Trimethoxy Cinnamoyl Reserpate De Methyl [French]",
      "Trimethoxycinnamoyl Methyl Reserpate",
      "Tsuruselpi S",
      "Anaprel (TN)",
      "Cinnasil (TN)",
      "Moderil (TN)",
      "NP-011016",
      "Rescinamina [INN-Spanish]",
      "Rescinnamine (VAN)",
      "Rescinnaminum [INN-Latin]",
      "Resepinine (C35 Alkaloid)",
      "Reserpinine (C35 Alkaloid)",
      "Reserpinine (VAN)",
      "Tsuruselpi S (TN)",
      "Rescinnamine (JAN/INN)",
      "Rescinnamine [BAN:INN:JAN]",
      "Methyl Reserpate 3,4,5-Trimethoxycinnamic Acid Ester",
      "Reserpic Acid Methyl Ester 3,4,5-Trimethoxycinnamate",
      "Methyl 18-O-(3,4,5-Trimethoxycinnamoyl)Reserpate",
      "O-(3,4,5-Trimethoxy-Trans-Cinnamoyl) Methyl Reserpate",
      "3,4,5-Trimethoxycinnamic Acid, Methyl Reserpate",
      "3,4,5-Trimethoxycinnamoyl Methyl Reserpate",
      "3,4,5-Trimethylcinnamic Acid, Ester With Methyl Reserpate",
      "3,4,5-Trimethylcinnamoyl Methyl Reserpate",
    ],
  },
  {
    primary_id: "D0G8NK",
    names: ["Axanum"],
  },
  {
    primary_id: "D0G8NN",
    names: ["Pramipexole"],
  },
  {
    primary_id: "D0G8NP",
    names: ["CS-00028", "CS-028", "HDAC Antagonist (Cancer), Shenzhen Chipscreen Biosciences"],
  },
  {
    primary_id: "D0G8OB",
    names: [
      "Alpha-Arylamino Hydroxamic Acids",
      "Alpha-Arylamino Hydroxamic Acids (Anthrax Lethal Factor Inhibitors)",
      "Alpha-Arylamino Hydroxamic Acids (Anthrax Lethal Factor Inhibitors), PanThera Biopharma",
    ],
  },
  {
    primary_id: "D0G8OC",
    names: [
      "Dihydrotachysterol",
      "Antitanil",
      "Calcamine",
      "Dichystrolum",
      "Dihidrotaquisterol",
      "Dihydral",
      "Dihydrotachysterolum",
      "Diidrotachisterolo",
      "Dygratyl",
      "Hytakerol",
      "Parterol",
      "Tachyrol",
      "Tachystin",
      "Dht Intensol",
      "Diidrotachisterolo [DCIT]",
      "AT 10",
      "DHT2",
      "Anti-Tetany Substance 10",
      "Dht(Sub 2)",
      "Dihidrotaquisterol [INN-Spanish]",
      "Dihydrotachysterol(Sub 2)",
      "Dihydrotachysterolum [INN-Latin]",
      "Dihydrotachysterol [INN:BAN:JAN]",
      "(1S,3E,4S)-3-[(2E)-2-[(1R,3aS,7aR)-1-[(E,2R,5R)-5,6-Dimethylhept-3-En-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylcyclohexan-1-Ol",
      "(1S,3E,4S)-3-[(2E)-2-[(3aS,7aR)-1-[(E,2R,5R)-5,6-Dimethylhept-3-En-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylcyclohexan-1-Ol",
      "(3E)-3-[(2E)-2-[1-[(E)-5,6-Dimethylhept-3-En-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylcyclohexan-1-Ol",
      "(3S,5E,7E,10S,22E)-9,10-Secoergosta-5,7,22-Trien-3-Ol",
      "(3Z)-3-[(2E)-2-[1-[(E)-5,6-Dimethylhept-3-En-2-Yl]-7a-Methyl-2,3,3a,5,6,7-Hexahydro-1H-Inden-4-Ylidene]ethylidene]-4-Methylcyclohexan-1-Ol",
      "9,10-Secoergosta-5,7,22-Trien-3beta-Ol",
    ],
  },
  {
    primary_id: "D0G8OD",
    names: [
      "N-(Benzyloxycarbonyl)-Leucyl-Glycine-Nitrile",
      "Dipeptidyl Nitrile, 1",
      "Cbz-Leu-NH-CH2-CN",
      "JMC487688 Compound 8",
      "CHEMBL200161",
      "SCHEMBL6183068",
      "BDBM19768",
      "UFXQLUZNMRVTPU-AWEZNQCLSA-N",
      "2-[(Z-L-Leu-)Amino]ethanenitrile",
      "Benzyl (S)-1-Cyanomethylcarbamoyl-3-Methylbutylcarbamate",
      "Benzyl N-[(1S)-1-[(Cyanomethyl)Carbamoyl]-3-Methylbutyl]carbamate",
    ],
  },
  {
    primary_id: "D0G8PA",
    names: ["MK-8228"],
  },
  {
    primary_id: "D0G8QW",
    names: ["Delapril", "Adecut (TN)"],
  },
  {
    primary_id: "D0G8SB",
    names: ["2,2-Bipyridyl Derivative 3"],
  },
  {
    primary_id: "D0G8SQ",
    names: ["Levacecarnine Hci"],
  },
  {
    primary_id: "D0G8SW",
    names: [
      "N5-[4-(4-Methylphenoxy)Phenyl]-L-Glutamamide",
      "CHEMBL479157",
      "Modified Amino Acid Analog, 9e",
      "BDBM24282",
    ],
  },
  {
    primary_id: "D0G8TL",
    names: ["HwFwLL-NH2", "CHEMBL502208"],
  },
  {
    primary_id: "D0G8TM",
    names: [
      "(+)-5-(5-Bromothiophen-3-Yl)-5-Methyl-4-Oxo-4,5-Dihydro-Furan-2-Carboxylic Acid",
      "CHEMBL375493",
      "GTPL1589",
      "SCHEMBL3111157",
      "BDBM50208137",
      "5-(5-Bromothiophen-3-Yl)-5-Methyl-4-Oxofuran-2-Carboxylic Acid",
      "5-(5-Bromothiophen-3-Yl)-5-Methyl-4-Oxo-4,5-Dihydrofuran-2-Carboxylic Acid",
      "(+)-5-(5-Bromo-Thiophen-3-Yl)-5-Methyl-4-Oxo-4,5-Dihydro-Furan-2-Carboxylic Acid",
      "5-(5-Bromo-Thiophen-3-Yl)-5-Methyl-4-Oxo-4,5-Dihydro-Furan-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0G8UA",
    names: ["(R)-2-(2-Phenyl-2-(Piperazin-1-Yl)Ethyl)Phenol", "CHEMBL1084971"],
  },
  {
    primary_id: "D0G8UG",
    names: ["Sulukast"],
  },
  {
    primary_id: "D0G8UL",
    names: ["Alferminogene Tadenovec"],
  },
  {
    primary_id: "D0G8UY",
    names: ["PF-04958242"],
  },
  {
    primary_id: "D0G8VB",
    names: ["Beta-Naphthylacetic Acids Derivative 1"],
  },
  {
    primary_id: "D0G8VF",
    names: ["NSC-676468"],
  },
  {
    primary_id: "D0G8VU",
    names: ["PD-168077"],
  },
  {
    primary_id: "D0G8XJ",
    names: ["5-Cl-5-Deoxy-(+/-)-ENBA"],
  },
  {
    primary_id: "D0G8XL",
    names: [
      "1-(3-Cyano-Phenyl)-1H-Pyrazole-4-Carboxylic Acid",
      "CHEMBL169728",
      "1-(3-CYANOPHENYL)-1H-PYRAZOLE-4-CARBOXYLIC ACID",
      "SCHEMBL15579010",
      "BDBM50098064",
      "AKOS009563536",
    ],
  },
  {
    primary_id: "D0G8XR",
    names: [
      "4-Iodo-2,6-Diisopropyl-Phenol",
      "4-Iodopropofol",
      "169255-48-5",
      "4-IODO-2,6-BIS(ISOPROPYL)PHENOL",
      "CHEMBL53267",
      "Bobel 4",
      "Phenol, 4-Iodo-2,6-Bis(1-Methylethyl)-",
      "4-Iodo-2,6-Diisopropylphenol",
      "ZINC8678",
      "SCHEMBL1163939",
      "DTXSID10432353",
      "MolPort-009-677-266",
      "ZX-AT014004",
      "BDBM50064424",
      "2,6-Bis(Prop-2-Yl)-4-Iodophenol",
      "AKOS025310281",
      "OR54368",
      "FCH1363142",
      "2,6-Bis(Isopropyl)-4-Iodophenol 97%",
    ],
  },
  {
    primary_id: "D0G8YJ",
    names: ["CD19-TriCAR-T"],
  },
  {
    primary_id: "D0G8ZD",
    names: [
      "(1-Phenyl-Ethyl)-(2-Phenyl-Quinazolin-4-Yl)-Amine",
      "CHEMBL72703",
      "AC1LTN5B",
      "Cid_5051334",
      "ZINC1773076",
      "BDBM50116318",
      "2-Phenyl-N-[(1S)-1-Phenylethyl]quinazolin-4-Amine",
    ],
  },
  {
    primary_id: "D0G8ZQ",
    names: ["G17DT"],
  },
  {
    primary_id: "D0G8ZX",
    names: ["Homocamptothecins (HCPTs)"],
  },
  {
    primary_id: "D0G9AI",
    names: ["L-Tryptophan-L-Glutamine", "CHEMBL476173", "SCHEMBL5970454"],
  },
  {
    primary_id: "D0G9AP",
    names: ["E-2011"],
  },
  {
    primary_id: "D0G9AW",
    names: ["GS-9973"],
  },
  {
    primary_id: "D0G9BH",
    names: ["PC-8608"],
  },
  {
    primary_id: "D0G9BO",
    names: ["Mycophenolic Acid/Nucleotide Derivative 8"],
  },
  {
    primary_id: "D0G9BP",
    names: ["ISIS 175510"],
  },
  {
    primary_id: "D0G9BX",
    names: [
      "SC-47643",
      "CHEMBL129921",
      "BDBM50035970",
      "3-(8-Guanidino-Octanoylamino)-N-[2-(4-Methoxy-Phenyl)-Ethyl]-Succinamic Acid",
    ],
  },
  {
    primary_id: "D0G9CJ",
    names: ["X-082-NAB"],
  },
  {
    primary_id: "D0G9CR",
    names: ["PMID25980951-Compound-39"],
  },
  {
    primary_id: "D0G9DJ",
    names: ["Iva-His-Pro-Phe-His-AHPPA-Leu-Phe-NH2"],
  },
  {
    primary_id: "D0G9EI",
    names: ["Anti-IL-5 Receptor Mab"],
  },
  {
    primary_id: "D0G9EU",
    names: ["JWH-295"],
  },
  {
    primary_id: "D0G9EX",
    names: [
      "C5aR Pepducins",
      "C5aR Pepducins ((Lipopeptide, Inflammatory Diseases)",
      "Component 5a Receptor Modulators (Lipopeptide, Inflammatory Diseases), Anchor Therapeutics",
      "C5aR Pepducins ((Lipopeptide, Inflammatory Diseases), Anchor Therapeutics",
    ],
  },
  {
    primary_id: "D0G9FF",
    names: ["Nucleozin"],
  },
  {
    primary_id: "D0G9FL",
    names: ["CT-064"],
  },
  {
    primary_id: "D0G9FP",
    names: ["Rofleponide"],
  },
  {
    primary_id: "D0G9FQ",
    names: ["2-Hydroxyiminours-12-En-28-Oic Acid", "2-(Hydroxyimino)-19beta-Methyl-30-Noroleana-12-Ene-28-Oic Acid"],
  },
  {
    primary_id: "D0G9HY",
    names: ["Tacrine-Indole Hybrid Derivative 1"],
  },
  {
    primary_id: "D0G9ID",
    names: ["WIN-35065-2"],
  },
  {
    primary_id: "D0G9IP",
    names: ["Eicosatetranoic Acid"],
  },
  {
    primary_id: "D0G9IU",
    names: ["Rifapentine"],
  },
  {
    primary_id: "D0G9JB",
    names: ["ISIS 112699"],
  },
  {
    primary_id: "D0G9JL",
    names: ["MTC-220"],
  },
  {
    primary_id: "D0G9JQ",
    names: ["PB1046"],
  },
  {
    primary_id: "D0G9KD",
    names: [
      "Benzo[g]quinazolin-4-Yl-(3-Bromo-Phenyl)-Amine",
      "CHEMBL63786",
      "N-(3-Bromophenyl)Benzo[g]quinazolin-4-Amine",
      "Benzoquinazoline Deriv. 23",
      "AC1NS43R",
      "SCHEMBL4348523",
      "BDBM3585",
      "BUVKJPOCMOXSSQ-UHFFFAOYSA-",
      "ZINC3815025",
      "4-[(3-Bromophenyl)Amino]benzo[g]quinazoline",
      "N-(3-Bromophenyl)Benzo[g]quinazoline-4-Amine",
    ],
  },
  {
    primary_id: "D0G9KS",
    names: ["Aryl Mannoside Derivative 2"],
  },
  {
    primary_id: "D0G9LM",
    names: ["Glutamine Hydroxamate"],
  },
  {
    primary_id: "D0G9LP",
    names: ["Neutralase"],
  },
  {
    primary_id: "D0G9LW",
    names: ["PMID19191557C21"],
  },
  {
    primary_id: "D0G9MC",
    names: ["TLC178"],
  },
  {
    primary_id: "D0G9ME",
    names: ["NAX-5055"],
  },
  {
    primary_id: "D0G9MM",
    names: ["Isoxazole Derivative 1"],
  },
  {
    primary_id: "D0G9MY",
    names: ["CD33-Specific Gene-Engineered T Cells"],
  },
  {
    primary_id: "D0G9NE",
    names: ["1-Cyclohexylamido-5-Sulfonamidoindane", "CHEMBL206830", "Indanesulfonamide Derivative 11b", "BDBM11032"],
  },
  {
    primary_id: "D0G9NN",
    names: ["PEV-6A", "Breast Cancer Vaccine (Virosomal, PeviPRO), Pevion/BioLife Science"],
  },
  {
    primary_id: "D0G9NQ",
    names: ["PMID29053063-Compound-17"],
  },
  {
    primary_id: "D0G9NS",
    names: ["NSC-57893"],
  },
  {
    primary_id: "D0G9OC",
    names: ["BMS-PCSK9"],
  },
  {
    primary_id: "D0G9OH",
    names: ["VVZ-149"],
  },
  {
    primary_id: "D0G9OT",
    names: ["Fused Heterocyclic Compound 7"],
  },
  {
    primary_id: "D0G9PD",
    names: ["IChorcumab"],
  },
  {
    primary_id: "D0G9QO",
    names: ["PMID27977313-Compound-7"],
  },
  {
    primary_id: "D0G9RW",
    names: ["TAN-1496"],
  },
  {
    primary_id: "D0G9SD",
    names: ["EF-13"],
  },
  {
    primary_id: "D0G9SH",
    names: [
      "SA-5845",
      "Carbon-11 Labeled Sigma Opioid Receptor Ligands, Santen",
      "FE-SA-4503",
      "FE-SA-5845",
      "Carbon-11-SA-4503",
      "Carbon-11-SA-5845",
      "11C-SA-4503",
      "11C-SA-5845",
    ],
  },
  {
    primary_id: "D0G9UO",
    names: ["HTRA-1 Mabs"],
  },
  {
    primary_id: "D0G9UX",
    names: ["CGS 35066", "CGS-35066", "CHEMBL34668", "SCHEMBL4558321"],
  },
  {
    primary_id: "D0G9WE",
    names: [
      "Batrachotoxin",
      "BATRACHOTOXIN",
      "UNII-TSG6XHX09R",
      "TSG6XHX09R",
      "Batrachotoxinin A, 20-(2,4-Dimethyl-1H-Pyrrole-3-Carboxylate)",
      "Betrachotoxinin A, 20-Alpha-(2,4-Dimethyl-1H-Pyrrole-3-Carboxylate)",
      "23509-16-2",
      "GTPL2619",
      "LS-24727",
      "Betrachotoxinin A, 20-Alpha-(2,4-Dimethyl-1H-Pyrrole-3-Carboxylate) (9CI)",
    ],
  },
  {
    primary_id: "D0G9WP",
    names: [
      "5-Pyridin-3-Yl-2,3-Dihydro-1H-Inden-1-One",
      "CHEMBL448743",
      "5-(Pyridin-3-Yl)-2,3-Dihydroinden-1-One",
      "5-Pyridin-3-Yl-1-Indanone",
      "5-(3-Pyridyl)-1-Indanone",
      "SCHEMBL6205709",
      "CTK8E5276",
      "QNSOPUXDEXJMIY-UHFFFAOYSA-N",
      "BDBM50273704",
      "ZINC40829496",
      "255895-87-5",
      "TX-011073",
    ],
  },
  {
    primary_id: "D0G9WX",
    names: ["5-Benzhydryl-1,3,4-Thiadiazole-2(3H)-Thione", "CHEMBL593062", "BDBM50320720"],
  },
  {
    primary_id: "D0G9WZ",
    names: ["ISIS 119262"],
  },
  {
    primary_id: "D0G9XG",
    names: ["NBM-HD-1"],
  },
  {
    primary_id: "D0G9XP",
    names: ["LB80380"],
  },
  {
    primary_id: "D0G9XS",
    names: [
      "ADS-0101",
      "ADS-0102",
      "DOM-0101",
      "DOM-0102",
      "Domain Antibody Therapy (Inhalant Formulation, COPD/Respiratory Disease)",
      "Domain Antibody Therapy (Inhalant Formulation, COPD/Respiratory Disease), Domantis",
      "DAbs (Inhalant Formulation, COPD/Respiratory Disorders), Domantis/Argenta",
    ],
  },
  {
    primary_id: "D0G9XX",
    names: [
      "2'-Deoxyguanosine-5'-Monophosphate",
      "Deoxyguanylic Acid",
      "Deoxy-GMP",
      "2'-Deoxyguanylic Acid",
      "Deoxyguanylate",
      "Deoxyguanosine 5'-Monophosphate",
      "DGMP",
      "2'-Deoxy-GMP",
      "2'-DGMP",
      "Deoxyguanosine Monophosphate",
      "Deoxyguanosine 5'-Phosphate",
      "2'-Deoxyguanosine 5'-Phosphate",
      "2'-Deoxy-5'-Guanylic Acid",
      "2'-Deoxyguanosine 5'-Monophosphate",
      "5'-DGMP",
      "5'-Deoxyguanylic Acid",
      "Deoxy GMP",
      "2'-Deoxyguanosine 5'MP",
      "902-04-5",
      "Deoxyguanosine-Phosphate",
      "5'-Guanylic Acid, 2'-Deoxy-",
      "2'-DG-5'-MP",
      "2'-Deoxy-5'-GMP",
      "UNII-7EAM4TG712",
      "2'-Deoxyguanosine 5'-Phosphoric",
    ],
  },
  {
    primary_id: "D0G9YH",
    names: [
      "Amsacrine",
      "Acridinylanisidide",
      "Amecrin",
      "Amekrin",
      "Amsacrina",
      "Amsacrinum",
      "Amsidine",
      "Amsidyl",
      "Amsine",
      "Lamasine",
      "MAMSA",
      "NAMSA",
      "Acridinyl Anisidide",
      "SN 21429",
      "SN11841",
      "AMSA P-D",
      "Acridinyl Anisidide (TN)",
      "Amsacrina [INN-Spanish]",
      "Amsacrinum [INN-Latin]",
      "Amsidyl (TN)",
      "Cain's Acridine",
      "M-AMSA",
      "Meta-AMSA",
      "Meta-Amsacrine",
      "SN-11841",
      "Amsacrine (USAN/INN)",
      "Amsacrine [USAN:BAN:INN]",
      "M-AMSA (TN)",
      "N-[4-(9-Acridinylamino)-3-Methoxyphenyl]methanesulfonamide",
      "N-(4-(9-Acridinylamino)-3-Methoxyphenyl)Methanesulfonamide",
      "N-[4-(9-Acridinylamino)-3-Methoxy-Phenyl]methanesulfonamide",
      "N-[4-(Acridin-9-Ylamino)-3-Methoxyphenyl]methanesulfonamide",
      "N-[4-(Acridin-9-Ylmethyl)-3-Methoxyphenyl]methanesulfonamide",
      "N-(4-(Acridin-9-Ylamino)-3-Methoxyphenyl)Methanesulfonamide",
      "N-[4-(Acridin-9-Ylamino)-3-(Methyloxy)Phenyl]methanesulfonamide",
      "4'-(9-Acridinylamino)-3'-Methoxymethanesulfonanilide",
      "4'-(9-Acridinylamino)Methanesulfon-M-Anisidide",
      "4'-(9-Acridinylamino)Methanesulfon-Meta-Anisidide",
      "4'-(9-Acridinylamino)Methanesulphon-M-Anisidide",
    ],
  },
  {
    primary_id: "D0G9YL",
    names: ["GSK-8062"],
  },
  {
    primary_id: "D0G9ZJ",
    names: ["RG7458"],
  },
  {
    primary_id: "D0G9ZU",
    names: ["ESBA-105"],
  },
  {
    primary_id: "D0G9ZV",
    names: ["BMS-986177"],
  },
  {
    primary_id: "D0G9ZW",
    names: ["Roquinimex"],
  },
  {
    primary_id: "D0G9ZY",
    names: ["76Br-16alpha,17alpha-Dioxolane Progestin"],
  },
  {
    primary_id: "D0GA0A",
    names: [
      "Interferon Alfacon-1",
      "Advaferon",
      "Infergen",
      "Advaferon (TN)",
      "Infergen (TN)",
      "Interferon Alfacon-1 (Genetical Recombination)",
      "Interferon Alfacon-1 (USAN/INN)",
      "Interferon Alfacon-1 (Genetical Recombination) (JAN)",
    ],
  },
  {
    primary_id: "D0GA0Y",
    names: ["2-(4-Amino-Phenyl)-8-Methyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0GA1Q",
    names: ["H-345/52", "Adekalant"],
  },
  {
    primary_id: "D0GA3M",
    names: [
      "BE-16627B",
      "137530-61-1",
      "Be16627b",
      "AC1L30CH",
      "L-N-(N-Hydroxy-2-Isobutylsuccinamoyl)Seryl-L-Valine",
      "L-Valine, N-(N-(2-(2-(Hydroxyamino)-2-Oxoethyl)-4-Methyl-1-Oxopentyl)-L-Seryl)-",
      "(2S)-2-[[(2S)-3-Hydroxy-2-[[2-[2-(Hydroxyamino)-2-Oxoethyl]-4-Methylpentanoyl]amino]propanoyl]amino]-3-Methylbutanoic Acid",
    ],
  },
  {
    primary_id: "D0GA4M",
    names: [
      "3-(1-Pyridin-2-Yl-1H-Pyrazol-4-Yl)-Benzonitrile",
      "CHEMBL182973",
      "SCHEMBL3923401",
      "WCEMBGUJGXXYCF-UHFFFAOYSA-N",
      "BDBM50151912",
      "ZINC13582322",
    ],
  },
  {
    primary_id: "D0GA5T",
    names: ["Indiplon"],
  },
  {
    primary_id: "D0GA6X",
    names: ["PMID28447479-Compound-4"],
  },
  {
    primary_id: "D0GA7D",
    names: ["PMID27019002-Compound-42b"],
  },
  {
    primary_id: "D0GA7H",
    names: ["Grass Peptide Allergy Desensitization Agent"],
  },
  {
    primary_id: "D0GA8C",
    names: ["Macrolactam Derivative 1"],
  },
  {
    primary_id: "D0GA8I",
    names: ["ATLCAR.CD30 Cells"],
  },
  {
    primary_id: "D0GB2L",
    names: ["SNN-0031"],
  },
  {
    primary_id: "D0GB3A",
    names: ["ABT-080"],
  },
  {
    primary_id: "D0GB3P",
    names: ["Pyridazin-3(2H)-One Derivative 1"],
  },
  {
    primary_id: "D0GB3Z",
    names: ["Dicoppcopper Gluconate + Disulfiram"],
  },
  {
    primary_id: "D0GB4V",
    names: ["STAUROSPORINONE"],
  },
  {
    primary_id: "D0GB5X",
    names: ["Lulizumab Pegol"],
  },
  {
    primary_id: "D0GB6Y",
    names: ["PMID26651364-Compound-10a"],
  },
  {
    primary_id: "D0GB7F",
    names: ["ARX-AHD", "Dolastatin-Conjugated HER2 Antibody (Breast Cancer), Ambrx"],
  },
  {
    primary_id: "D0GB7G",
    names: ["PMID27774824-Compound-Figure6Example12"],
  },
  {
    primary_id: "D0GB8I",
    names: ["N1-Hydroxy-2-Isobutyl-N3-Phenethylmalonamide", "CHEMBL220010", "BDBM50197502"],
  },
  {
    primary_id: "D0GB8J",
    names: [
      "(S)-2-Amino-2-Cyclohexylacetic Acid",
      "14328-51-9",
      "H-Chg-Oh",
      "L-Alpha-Cyclohexylglycine",
      "L-Cyclohexylglycine",
      "H-Cyclohexyl-Gly-Oh",
      "L-(+)-2-Cyclohexylglycine",
      "(2s)-Amino(Cyclohexyl)Acetic Acid",
      "CYCLOHEXYLGLYCYL",
      "2-Cyclohexyl-L-Glycine",
      "(2S)-2-Amino-2-Cyclohexylacetic Acid",
      "CYCLOHEXYL-GLYCINE",
      "L-Cyclohexyl Glycine",
      "(S)-Cyclohexylglycine",
      "L-2-Cyclohexylglycine",
      "H-Cyclohexyl-D-Gly-Oh",
      "CHEMBL382372",
      "UNII-59Q5O5615E",
      "(S)-Amino-Cyclohexyl-Acetic Acid",
      "(2S)-Amino(Cyclohexyl)Ethanoic Acid",
      "MFCD01311679",
      "59Q5O5615E",
      "L-(-)-Cyclohexylglycine",
    ],
  },
  {
    primary_id: "D0GC1Y",
    names: [
      "Nitroarginine",
      "Arginine, N(G)-Nitro-",
      "L-Ornithine, N5-[imino(Nitroamino)Methyl]-",
      "C6H13N5O4",
      "Omega-Nitroarginine",
      ".omega.-Nitroarginine",
      "Arginine, Omega-Nitro-",
      "N(G)-Methyl-L-Arginine",
      "L-Ornithine, N5-(Imino(Nitroamino)Methyl)-",
      "Arginine, .omega.-Nitro-",
      "N(Sup G)-Nitro-L-Arginine",
      "Arginine, Omega-Nitro-, L-",
      "Nitroarginin",
      "EINECS 218-418-9",
      "N5-(N-Nitrocarbamimidoyl)Ornithine",
      "Dl-Nitro Arginine",
      "NO2Arg",
      "AI3-62168",
      "NOARG",
      "Norvaline, (+)-",
      "N(Omega)-Nitroarginine",
    ],
  },
  {
    primary_id: "D0GC2M",
    names: [
      "Acamprosate",
      "AOTA",
      "Acamprosato",
      "Acamprosatum",
      "Acamprostate",
      "Aotal",
      "Campral",
      "Calcium Acetyl Homotaurinate",
      "Calcium Acetylhomotaurinate",
      "Calcium Acetylhomotaurine",
      "Sodium Acetylhomotaurine",
      "Acamprosate 6473",
      "Acamprosate (Campral)",
      "Acamprosate (INN)",
      "Acamprosate [INN:BAN]",
      "Acamprosato [INN-Spanish]",
      "Acamprosatum [INN-Latin]",
      "Aotal (TN)",
      "Campral (TN)",
      "N-Acetylhomotaurine",
      "3-(Acetylamino)Propanesulphonic Acid",
      "3-Acetamido-1-Propanesulfonic Acid",
      "3-Acetamidopropane-1-Sulfonic Acid",
    ],
  },
  {
    primary_id: "D0GC3E",
    names: ["Cyclo(1,11)H-ESIYDPGDDIK-OH", "CHEMBL525597"],
  },
  {
    primary_id: "D0GC3U",
    names: ["CD19-Directed CAR-T Cells"],
  },
  {
    primary_id: "D0GC5H",
    names: [
      "Ramatroban",
      "116649-85-5",
      "Baynas",
      "BAY U3405",
      "Bay U-3405",
      "Bay U 3406",
      "Bay-U-3405",
      "Bay U 3405",
      "BAY-U 3405",
      "UNII-P1ALI72U6C",
      "C21H21FN2O4S",
      "3-[(3R)-3-[(4-Fluorophenyl)Sulfonylamino]-1,2,3,4-Tetrahydrocarbazol-9-Yl]propanoic Acid",
      "P1ALI72U6C",
      "3-(4-Fluorophenylsulfonamido)-1,2,3,4-Tetrahydro-9-Carbazole Propanoic Acid",
      "CHEMBL361812",
      "(+)-(3R)-3-(P-Fluorobenzenesulfonamido)-1,2,3,4-Tetrahydrocarbazole-9-Propionic Acid",
      "NCGC00167519-01",
      "EN-137774",
      "DSSTox_RID_81819",
      "Baynas (TN)",
      "Ramatroban (JAN/INN)",
      "[3H]ramatroban",
    ],
  },
  {
    primary_id: "D0GC5Y",
    names: ["PMID21571530C7e"],
  },
  {
    primary_id: "D0GC7S",
    names: [
      "1-Phenyl-3-(3-(Pyridin-2-Yl)Isoquinolin-1-Yl)Urea",
      "CHEMBL70553",
      "BDBM50088465",
      "1-Phenyl-3-[3-(2-Pyridyl)-1-Isoquinolyl]urea",
    ],
  },
  {
    primary_id: "D0GC8F",
    names: ["MED-27"],
  },
  {
    primary_id: "D0GC9B",
    names: ["JMV180", "Boc-Tyr(SO3H)Ahx-Gly-Trp-Ahx-Asp2phenylethyl Ester", "JMV-180", "JMV 180"],
  },
  {
    primary_id: "D0GD1A",
    names: ["Cyclo(1,10)H-EIYDPGDDIK-OH", "CHEMBL506798"],
  },
  {
    primary_id: "D0GD1R",
    names: ["ISIS 112003"],
  },
  {
    primary_id: "D0GD4Q",
    names: ["Patidegib"],
  },
  {
    primary_id: "D0GD5G",
    names: ["H-KPPR-OH", "CHEMBL1091089"],
  },
  {
    primary_id: "D0GD6J",
    names: ["TDI-0029"],
  },
  {
    primary_id: "D0GD6R",
    names: ["PMID29671355-Compound-68b"],
  },
  {
    primary_id: "D0GD7H",
    names: ["PMID25435179-Compound-WO2013059594(III)"],
  },
  {
    primary_id: "D0GD8Y",
    names: ["U0126"],
  },
  {
    primary_id: "D0GD9E",
    names: ["GAP T Cells"],
  },
  {
    primary_id: "D0GD9G",
    names: ["JWH-207"],
  },
  {
    primary_id: "D0GE2F",
    names: ["GSK-2485852"],
  },
  {
    primary_id: "D0GE3O",
    names: ["(E)-2-(1-Decyl-2-Oxoindolin-3-Ylidene)Acetic Acid", "CHEMBL496308"],
  },
  {
    primary_id: "D0GE4A",
    names: [
      "N-Butyl-2-(1H-Indol-3-Yl)-2-Oxoacetamide",
      "CHEMBL223476",
      "N-Butyl-2-(1H-Indol-3-Yl)-2-Oxo-Acetamide",
      "BAS 04280181",
      "AC1MK4QP",
      "MolPort-000-249-106",
      "ZINC3635496",
      "BDBM50206948",
      "N-Butyl-2-Indol-3-Yl-2-Oxoacetamide",
      "AKOS000530602",
      "MCULE-8866008030",
      "ST50101897",
    ],
  },
  {
    primary_id: "D0GE4F",
    names: ["PMID28766366-Compound-Scheme25-4"],
  },
  {
    primary_id: "D0GE5B",
    names: ["RG-2833"],
  },
  {
    primary_id: "D0GE6F",
    names: ["Thiazole Carboxamide Derivative 17"],
  },
  {
    primary_id: "D0GE8S",
    names: [
      "ES-936",
      "192820-78-3",
      "ES 936",
      "UNII-1XI90I177M",
      "5-METHOXY-1,2-DIMETHYL-3-(4-NITROPHENOXYMETHYL)INDOLE-4,7-DIONE",
      "5-Methoxy-1,2-Dimethyl-3-[(4-Nitrophenoxy)Methyl]indole-4,7-Dione",
      "1XI90I177M",
      "5-Methoxy-1,2-Dimethyl-3-((4-Nitrophenoxy)Methyl)Indole-4,7-Dione",
      "AC1NBNHW",
      "CHEMBL357217",
      "SCHEMBL3991028",
      "CTK8E6509",
      "DTXSID20172879",
      "MolPort-006-168-362",
      "ZINC587989",
      "AKOS024457934",
      "1H-Indole-4,7-Dione, 5-Methoxy-1,2-Dimethyl-3-((4-Nitrophenoxy)Methyl)-",
      "API0010300",
      "DB02400",
      "ES936, &gt",
      "LS-191843",
      "B5514",
      "KS-00000189",
      "J-012477",
    ],
  },
  {
    primary_id: "D0GE9U",
    names: [
      "3-(3'-Furyl)-Aniline",
      "936249-46-6",
      "3-(3-Furanyl)-Benzenamine",
      "3-(Furan-3-Yl)Aniline",
      "Benzenamine, 3-(3-Furanyl)-",
      "SCHEMBL1019148",
      "CHEMBL222787",
      "LQDARHFLRKPAMM-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0GF2L",
    names: ["TG-1031"],
  },
  {
    primary_id: "D0GF2R",
    names: ["KN035"],
  },
  {
    primary_id: "D0GF3P",
    names: ["PMID25980951-Compound-38"],
  },
  {
    primary_id: "D0GF4I",
    names: ["4-Thio-UTP", "4-S-UTP", "4-Thiouridine Triphosphate"],
  },
  {
    primary_id: "D0GF9J",
    names: ["(11H-Dibenzo[b,e][1,4]dioxepin-8-Yl)-Acetic Acid", "CHEMBL33814", "ZINC29223313"],
  },
  {
    primary_id: "D0GG2F",
    names: ["ISIS 9067"],
  },
  {
    primary_id: "D0GG2J",
    names: [
      "1-(1-(2-FLUOROPHENYL)-2-(2-(TRIFLUOROMETHOXY)PHENYL)ETHYL)PIPERAZINE (ENANTIOMERIC MIX)",
      "CHEMBL1083426",
      "SCHEMBL5443198",
      "BDBM50319828",
      "1-(1-(2-Fluorophenyl)-2-(2-(Trifluoromethoxy)Phenyl)Ethyl)Piperazine",
      "(-)-1-(1-(2-Fluorophenyl)-2-(2-(Trifluoromethoxy)Phenyl)Ethyl)Piperazine",
    ],
  },
  {
    primary_id: "D0GG4T",
    names: ["JWH-150"],
  },
  {
    primary_id: "D0GG5I",
    names: ["Recombinant RGD-Hirudin"],
  },
  {
    primary_id: "D0GG5K",
    names: ["PMID25416646-Compound-Figure2-N"],
  },
  {
    primary_id: "D0GG6N",
    names: ["PMID30247903-Compound-General Structure9"],
  },
  {
    primary_id: "D0GG6X",
    names: ["Kodaistatin A"],
  },
  {
    primary_id: "D0GG7L",
    names: ["BMS-986104"],
  },
  {
    primary_id: "D0GG7Z",
    names: [
      "AFLATREME",
      "Aflatrem",
      "UNII-ZEL123Y65D",
      "70553-75-2",
      "ZEL123Y65D",
      "CHEMBL327558",
      "C20555",
      "4H-3,15a-Epoxy-1-Benzoxepino(6',7':6,7)Indeno(1,2-B)Indol-4-One, 9-(1,1-Dimethyl-2-Propenyl)-2,3,5b,6,7,7a,8,13,13b,13c,14,15-Dodecahydro-5b-Hydroxy-2,2,13b,13c-Tetramethyl-, (3R-(3alpha,5balpha,7abeta,13balpha,13cbeta,15aalpha))-",
      "4H-3,15a-Epoxy-1-Benzoxepino(6',7':6,7)Indeno(1,2-B)Indol-4-One, 9-(1,1-Dimethyl-2-Propenyl)-2,3,5b,6,7,7a,8,13,13b,13c,14,15-Dodecahydro-5b-Hydroxy-2,2,13b,13c-Tetramethyl-, (3R,5bS,7aS,13bS,13cR,15aS)-",
    ],
  },
  {
    primary_id: "D0GG8N",
    names: ["LTX-200"],
  },
  {
    primary_id: "D0GG9P",
    names: [
      "Balixafortide",
      "1051366-32-5",
      "Balixafortide [INN]",
      "UNII-PRC974M49B",
      "PRC974M49B",
      "Ala-Cys-Ser-Ala-Pro-Arg-Tyr-Cys-Tyr-Gln-Lys-Pro-Pro-Tyr-His Cyclic (2-&gt;9)-Disulfide",
      "Cyclo(L-Alanyl-L-Cysteinyl-L-Seryl-L-Alanyl-D-Prolyl-(2S)-2,4-Diaminobutanoyl-L-Arginyl-L-Tyrosyl-L-Cysteinyl-L-Tyrosyl-L-Glutaminyl-L-Lysyl-D-Prolyl-L-Prolyl-L-Tyrosyl-L-Histidyl), Cyclic (2-&gt;9)-Disulfide",
    ],
  },
  {
    primary_id: "D0GG9W",
    names: ["ISA-2011B"],
  },
  {
    primary_id: "D0GH0O",
    names: ["BB-76163"],
  },
  {
    primary_id: "D0GH0Z",
    names: ["PMID25522065-Compound-41"],
  },
  {
    primary_id: "D0GH1C",
    names: ["CVT-1"],
  },
  {
    primary_id: "D0GH1I",
    names: ["3-(4-Hydroxyphenyl)-7-Isobutoxychromen-4-One"],
  },
  {
    primary_id: "D0GH3Q",
    names: ["ITF2357"],
  },
  {
    primary_id: "D0GH4B",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 13"],
  },
  {
    primary_id: "D0GH4V",
    names: ["4-Carboxamido-Isoindolinone Derivative 5"],
  },
  {
    primary_id: "D0GH5N",
    names: ["NDP-Alpha-MSH"],
  },
  {
    primary_id: "D0GH7L",
    names: ["PRN1008"],
  },
  {
    primary_id: "D0GH8D",
    names: ["PMID27774822-Compound-Figure10Example1"],
  },
  {
    primary_id: "D0GH9J",
    names: [
      "Octyl Thiophosphatidic Acid",
      "CHEMBL427017",
      "GTPL6995",
      "BDBM50176398",
      "(S)-O-2,3-Bis(Octyloxy)Propyl O,O-Dihydrogen Phosphorothioate",
    ],
  },
  {
    primary_id: "D0GH9W",
    names: ["Heteroaryl-Carboxamide Derivative 6"],
  },
  {
    primary_id: "D0GH9Z",
    names: ["GW-275175", "CMV Therapy, Glaxo Wellcome", "GW-275175X"],
  },
  {
    primary_id: "D0GI1D",
    names: ["Lu AF21934", "(1S,2R)-2-[(Aminooxy)Methyl]-N-(3,4-Dichlorophenyl)Cyclohexane-1-Carboxamide"],
  },
  {
    primary_id: "D0GI2R",
    names: [
      "N-[(5-Chloro-1-Benzothien-3-Yl)Methyl]sulfamide",
      "CHEMBL1084675",
      "SCHEMBL4274889",
      "PURBZUVIUSNPGZ-UHFFFAOYSA-N",
      "BDBM50310436",
    ],
  },
  {
    primary_id: "D0GI4S",
    names: ["PMID29865878-Compound-64"],
  },
  {
    primary_id: "D0GI5V",
    names: [
      "[3H]N-Methyl-SSR504734",
      "2-Chloro-N-[(S)-[(2S)-1-Methylpiperidin-2-Yl](Phenyl)Methyl]-3-(Trifluoromethyl)Benzamide",
      "CHEMBL1939495",
      "N-Methyl-SSR504734",
      "[3H]-N-Methyl-SSR504734",
      "SCHEMBL4245201",
      "GTPL4603",
      "GTPL4604",
    ],
  },
  {
    primary_id: "D0GI6O",
    names: ["Iodine-124-Labeled F-16 ScFv Antibody"],
  },
  {
    primary_id: "D0GI7I",
    names: ["Spirocyclic Diamine Urea Derivative 1"],
  },
  {
    primary_id: "D0GI7S",
    names: [
      "3-Phenyl-Pyrrolidine",
      "3-Phenylpyrrolidine",
      "936-44-7",
      "Pyrrolidine, 3-Phenyl-",
      "(Pyrrolidin-3-Yl)Benzene",
      "CHEMBL75207",
      "PRRFFTYUBPGHLE-UHFFFAOYSA-N",
      "F2189-0088",
      "3-Phenyl-Pyrolidine",
      "3-Phenyl-Pyrollidine",
      "4-Phenyl-Pyrrolidine",
      "PubChem22374",
      "BAS 03334576",
      "AC1MK0OA",
      "SCHEMBL4122",
      "AC1Q1H4Y",
      "(+/-)-3-Phenyl-Pyrrolidine",
      "3-Phenylpyrrolidine, AldrichCPR",
      "KS-00001OYR",
      "CTK3I6515",
      "DTXSID60389848",
      "MolPort-000-006-168",
      "HMS1704P05",
      "3AAX-0-0",
      "BBL008865",
      "ANW-54854",
      "SBB010168",
      "KM3208",
      "BDBM50144660",
      "STK006609",
      "AKOS000674061",
      "AKOS016051772",
      "MCULE-5600189216",
    ],
  },
  {
    primary_id: "D0GI9S",
    names: [
      "(S)-2-Amino-4-Methylsulfanyl-Butane-1-Thiol",
      "CHEMBL36661",
      "1-Butanethiol, 2-Amino-4-(Methylthio)-, (2S)-",
      "SCHEMBL7164846",
      "PC-18",
      "BDBM50078128",
      "139428-86-7",
    ],
  },
  {
    primary_id: "D0GJ1E",
    names: [
      "8-(4-Bromophenyl)-N-Hydroxy-8-Oxooctanamide",
      "CHEMBL319070",
      "SCHEMBL3382402",
      "RFYYZRFJBNAHCG-UHFFFAOYSA-N",
      "BDBM50114817",
      "N-Hydroxy-7-(4-Bromobenzoyl)Heptanamide",
      "8-(4-Bromo-Phenyl)-8-Oxo-Octanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0GJ1U",
    names: ["C326"],
  },
  {
    primary_id: "D0GJ2G",
    names: ["Anti-CD22 CAR-T Cells"],
  },
  {
    primary_id: "D0GJ4G",
    names: ["Pyrrolo-Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0GJ7B",
    names: ["NELOTANSERIN"],
  },
  {
    primary_id: "D0GJ7J",
    names: ["ISOCONAZOLE"],
  },
  {
    primary_id: "D0GJ7N",
    names: ["Demcizumab"],
  },
  {
    primary_id: "D0GJ8R",
    names: ["Colestipol", "Colestid", "Colestipol Hydrochloride", "Flavored Colestid"],
  },
  {
    primary_id: "D0GJ8U",
    names: ["4-(4-Morpholin-4-Yl-Butyl)-2H-Phthalazin-1-One", "CHEMBL373210"],
  },
  {
    primary_id: "D0GK1M",
    names: ["PNT-400"],
  },
  {
    primary_id: "D0GK2Q",
    names: ["Cylopentenyl Cytosine"],
  },
  {
    primary_id: "D0GK4E",
    names: ["EMIVIRINE"],
  },
  {
    primary_id: "D0GK6Q",
    names: ["[125I]CGP 71872", "[125I]CGP71872"],
  },
  {
    primary_id: "D0GK7A",
    names: ["UNC0638"],
  },
  {
    primary_id: "D0GK9J",
    names: [
      "ACVR2B",
      "ACVR2B Program (Muscle Atrophy)",
      "ACVR2B Program (Muscle Atrophy), Rigel Pharmaceuticals",
      "Activin Type IIB Receptorantagonist, (Small Molecule Therapeutic, Muscle Atrophy), Rigel Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0GL0J",
    names: ["TGFTX-2"],
  },
  {
    primary_id: "D0GL0K",
    names: ["MRZ-8456"],
  },
  {
    primary_id: "D0GL0L",
    names: [
      "1-(9-Phenyl-9H-Fluoren-9-Yl)-1H-1,2,4-Triazole",
      "CHEMBL376371",
      "1H-1,2,4-Triazole, 1-(9-Phenyl-9H-Fluoren-9-Yl)-",
      "828265-51-6",
      "Fluorenyl Deriv. 17",
      "BDBM9468",
      "CTK3D6011",
    ],
  },
  {
    primary_id: "D0GL0Q",
    names: ["ICX-RHY"],
  },
  {
    primary_id: "D0GL1X",
    names: ["PMID26560362-Compound-90"],
  },
  {
    primary_id: "D0GL2X",
    names: [
      "1,3-Dibenzyl Urea",
      "1,3-Dibenzylurea",
      "N,N'-Dibenzylurea",
      "1466-67-7",
      "Sym-Dibenzylurea",
      "Urea, N,N'-Bis(Phenylmethyl)-",
      "1,3-Dibenzyl-Urea",
      "CHEMBL504463",
      "KATOLVAXCGIBLO-UHFFFAOYSA-N",
      "N-Benzyl[benzylamino]carboxamide",
      "Dibenzylurea",
      "EINECS 215-984-9",
      "NSC408007",
      "NSC 408007",
      "N,N'-Dibenzylharnstoff",
      "AI3-19855",
      "AC1Q5OY5",
      "AC1L2IW5",
      "Oprea1_448978",
      "Urea,N'-Bis(Phenylmethyl)-",
      "SCHEMBL714625",
      "Urea,N,N'-Bis(Phenylmethyl)-",
      "DTXSID3061710",
      "ZINC84096",
      "KS-00001BSB",
      "CTK4C5047",
      "MolPort-000-421-383",
      "HMS1697A06",
      "STK008269",
      "BDBM50248768",
      "SBB023417",
      "CCG-48904",
    ],
  },
  {
    primary_id: "D0GL7H",
    names: ["Talotrexin"],
  },
  {
    primary_id: "D0GL7O",
    names: ["HL-034"],
  },
  {
    primary_id: "D0GL7U",
    names: [
      "Norethindrone",
      "Activella",
      "Anhydrohydroxynorprogesterone",
      "Anovulatorio",
      "Anovule",
      "Brevicon",
      "Brevinor",
      "Camila",
      "Ciclovulan",
      "Conceplan;Conludaf",
      "Conludag",
      "Demulen",
      "Errin",
      "Estrinor",
      "Ethinylnortestosterone",
      "Ethynylmortestosterone",
      "Ethynylnortestosterone",
      "Gencept",
      "Genora",
      "Gestest",
      "Jenest",
      "Loestrin",
      "Menzol",
      "Microneth",
      "Micronett",
      "Micronor",
      "Micronovum",
      "Milli",
      "Minovlar",
      "Nelova",
      "Nodiol",
      "Noraethisteronum",
      "Noralutin",
      "Norcept",
      "Norcolut",
      "Norcolute",
      "Noresthisterone",
      "Norethadrone",
      "Norethin",
      "Norethindirone",
      "Norethisteron",
      "Norethisterone",
      "Norethisteronum",
      "Norethyndron",
      "Norethynodron",
      "Norethynodrone",
      "Noretisterona",
      "Noretisterone",
      "Norfor",
      "Norgestin",
      "Noriday",
      "Norluten",
      "Norlutin",
      "Norluton",
      "Normapause",
      "Norpregneninlone",
      "Norpregneninolone",
      "Norpregneninotone",
      "Orlest",
      "Proluteasi",
      "Synphase",
      "Triella",
      "Utovlan",
      "Utovlar",
      "Component Of Noriday",
      "Norethindrone Norethisterone",
      "Norethindrone [USAN]",
      "Norethisterone [Progestins]",
      "Noretisterone [DCIT]",
      "Primolut N",
      "Brevinor 21",
      "Brevinor 28",
      "Noriday 28",
      "Ortho 1 35",
      "Ortho 7 7 7",
      "Ovysmen 1 35",
      "SC 4640",
      "Synphasic 28",
      "Trinovum 21",
      "Brevinor-1 21",
      "Brevinor-1 28",
      "Camila (TN)",
      "Jenest-28",
      "Micronor (TN)",
      "Mini-Pe",
      "Mini-Pill",
      "Nor-QD",
      "Nora-BE",
      "Norcept-E",
      "Norethin 1/35 E",
      "Norethin 1/50 M",
      "Norethindrone (USP)",
      "Norethisterone (JP15)",
      "Norethisteronum [INN-Latin]",
      "Noretisterona [INN-Spanish]",
      "Ortho-Novum 1 35",
      "Ortho-Novum 1 50",
      "Ortho-Novum 7 7 7",
      "Ovysmen 0.5 35",
      "Primolut-N",
      "Tri-Norinyl",
      "Ethinyl-19-Nortestosterone",
      "Nor-Q.D",
      "Primolut-N (TN)",
      "Nor-Q.D.",
      "17-Ethinyl-19-Nortestosterone",
      "17-Ethynyl-17-Hydroxyestr-4-En-3-One",
      "17-Alpha-Ethynyl-19-Nortestosterone",
      "17-Alpha-Ethynyl-4-Estren-17-Ol-3-One",
      "17-Ethynyl-17beta-Hydroxyestr-4-En-3-One",
      "17.alpha.-Ethinyl-19-Nortestosterone",
      "17.alpha.-Ethynyl-19-Nortestosterone",
      "17.alpha.-Ethynyl-4-Estren-17-Ol-3-One",
      "17alpha-Ethinyl-19-Nortestosterone",
      "17alpha-Ethynyl-19-Nortestosterone",
      "17alpha-Ethynyl-4-Estren-17-Ol-3-One",
      "19-Nor-17-Alpha-Ethynyltestosterone",
      "19-Nor-17-Ethinyltestosterone",
      "19-Nor-17.alpha.-Ethynyltestosterone",
      "19-Nor-17alpa-Ethynyltestosterone",
      "19-Nor-17alpha-Ethynyltestosterone",
      "19-Nor-Ethindrone",
      "19-Nor-Ethinyl-4,5-Testosterone",
      "19-Norethindrone",
      "19-Norethinyltestosterone",
      "19-Norethisterone",
      "4-Estren-17alpha-Ethynyl-17beta-Ol-3-One",
    ],
  },
  {
    primary_id: "D0GL9I",
    names: ["N-Omega-Hydroxy-L-Arginine"],
  },
  {
    primary_id: "D0GM0N",
    names: ["ENDG-6010"],
  },
  {
    primary_id: "D0GM1P",
    names: ["Phenyl 4-(Hexyloxy)Phenylcarbamate"],
  },
  {
    primary_id: "D0GM4S",
    names: ["AG-1284"],
  },
  {
    primary_id: "D0GM6E",
    names: ["Pegdinetanib", "BMS-844203"],
  },
  {
    primary_id: "D0GM6O",
    names: ["Biphenyl Mannoside Derivative 9"],
  },
  {
    primary_id: "D0GM8E",
    names: ["Haemophilus Recombinant Vaccine"],
  },
  {
    primary_id: "D0GM8L",
    names: ["NS3 Protease Inhibitors"],
  },
  {
    primary_id: "D0GM8V",
    names: ["(O10eq)-Beta-Guanidinopropionylryanodine", "CHEMBL27669"],
  },
  {
    primary_id: "D0GM9E",
    names: ["RQ-00310941"],
  },
  {
    primary_id: "D0GN1A",
    names: ["Ac-Cys-Ile-Tyr-Lys-Phe(4-NO2)-Tyr-Phe"],
  },
  {
    primary_id: "D0GN3E",
    names: [
      "3-(1H-Indol-3-Yl)-Quinoline",
      "3-(1H-Indol-3-Yl)Quinoline",
      "CHEMBL309116",
      "3-(3-Quinolyl)-1H-Indole",
      "SCHEMBL8171583",
      "ZINC13737696",
      "BDBM50039096",
    ],
  },
  {
    primary_id: "D0GN3M",
    names: ["Ala11-SRIF-14-Amide"],
  },
  {
    primary_id: "D0GN5K",
    names: ["PMID25522065-Compound-23"],
  },
  {
    primary_id: "D0GN5R",
    names: ["Benzothiazepine Analog 7"],
  },
  {
    primary_id: "D0GN5Z",
    names: ["SULFURETIN"],
  },
  {
    primary_id: "D0GN7U",
    names: ["PMID25553724-Compound-US2011803494010"],
  },
  {
    primary_id: "D0GN9J",
    names: ["Org-6632"],
  },
  {
    primary_id: "D0GN9K",
    names: ["C[CO-2,3-Pyrazine-CO-D-Phe-Arg-Trp-Lys]-NH2", "CHEMBL438744"],
  },
  {
    primary_id: "D0GO0R",
    names: ["Padeliporfin Di-Potassium"],
  },
  {
    primary_id: "D0GO0T",
    names: ["HYG-301"],
  },
  {
    primary_id: "D0GO4T",
    names: ["IPH4102"],
  },
  {
    primary_id: "D0GO7W",
    names: [
      "21-AMINOEPOTHILONE B",
      "BMS-310705",
      "(4S,7R,8S,9S,13R,14S,16S)-13,14-Epoxy-4,8-Dihydroxy-5,5,7,9,13-Pentamethyl-16-[1-Methyl-2(E)-[2-(Aminomethyl)Thiazol-4-Yl]vinyl]-1-Oxacyclohexadecane-2,6-Dione",
    ],
  },
  {
    primary_id: "D0GO9G",
    names: ["PC-Mab"],
  },
  {
    primary_id: "D0GP0H",
    names: ["VGX-100"],
  },
  {
    primary_id: "D0GP1I",
    names: ["[3H]MK-912", "[3H]L-657,743"],
  },
  {
    primary_id: "D0GP1O",
    names: ["Emixustat"],
  },
  {
    primary_id: "D0GP1W",
    names: [
      "Deuterated Oxazolidinone Antibiotics",
      "C-20080",
      "C-20081",
      "C-20173",
      "Deuterated Oxazolidinone Antibiotics, CoNCERT",
    ],
  },
  {
    primary_id: "D0GP3R",
    names: ["5-Nitroindirubin-3'-Oxime"],
  },
  {
    primary_id: "D0GP4R",
    names: ["Picumeterol"],
  },
  {
    primary_id: "D0GP7D",
    names: ["NSC-119911"],
  },
  {
    primary_id: "D0GP7L",
    names: ["PMID26651364-Compound-115"],
  },
  {
    primary_id: "D0GP8N",
    names: ["MK-8808"],
  },
  {
    primary_id: "D0GP8P",
    names: [
      "GSK961081",
      "CHEMBL1683934",
      "SCHEMBL524583",
      "NVEMUJANQDPDSC-DHUJRADRSA-N",
      "BDBM50337878",
      "Biphenyl-2-Ylcarbamic Acid 1-{9-[(R)-2-Hydroxy-2-(8-Hydroxy-2-Oxo-1,2-Dihydroquinolin-5-Yl)Ethylamino]nonyl}piperidin-4-Yl Ester",
      "(R)-1-(9-(2-Hydroxy-2-(8-Hydroxy-2-Oxo-1,2-Dihydroquinolin-5-Yl)Ethylamino)Nonyl)Piperidin-4-Yl Biphenyl-2-Ylcarbamate",
    ],
  },
  {
    primary_id: "D0GP8Q",
    names: ["Risankizumab"],
  },
  {
    primary_id: "D0GP9Q",
    names: [
      "TG100801",
      "867331-82-6",
      "TG 100801",
      "UNII-1VZO7A0J9S",
      "TG-100801",
      "1VZO7A0J9S",
      "4-Chloro-3-(5-Methyl-3-((4-(2-(Pyrrolidin-1-Yl)Ethoxy)Phenyl)Amino)Benzo[e][1,2,4]triazin-7-Yl)Phenyl Benzoate",
      "4-Chloro-3-(5-Methyl-3-((4-(2-(Pyrrolidin-1-Yl)Ethoxy)Phenyl)-Amino)Benzo[e][1,2,4]triazin-7-Yl)Phenyl Benzoate",
      "C33H30ClN5O3",
      "CHEMBL403989",
      "SCHEMBL12198196",
      "KS-00001CQP",
      "BDBM97971",
      "DTXSID20235791",
      "MolPort-027-835-464",
      "BCP02570",
      "3641AC",
      "ABP000364",
      "ZINC29136020",
      "AKOS016008711",
      "CS-0785",
      "DB05075",
      "NCGC00378840-01",
      "KB-81090",
      "HY-10186",
      "TG100-801",
      "W-5618",
    ],
  },
  {
    primary_id: "D0GQ0V",
    names: ["PMID27841045-Compound-131"],
  },
  {
    primary_id: "D0GQ1S",
    names: [
      "DMCM",
      "Methyl 6,7-Dimethoxy-4-Ethyl-Beta-Carboline-3-Carboxylate",
      "UNII-1309288N1J",
      "82499-00-1",
      "CHEMBL17468",
      "METHYL-6,7-DIMETHOXY-4-ETHYL-BETA-CARBOLINE-3-CARBOXYLATE",
      "Methyl 4-Ethyl-6,7-Dimethoxy-9H-Pyrido[3,4-B]indole-3-Carboxylate",
      "1309288N1J",
      "Methyl 4-Ethyl-6,7-Dimethoxy-9H-Pyrido(3,4-B)Indole-3-Carboxylate",
      "4-Ethyl-6,7-Dimethoxy-9H-Pyrido(3,4-B)Indole-3-Carboxylic Acid, Methyl Ester",
      "9H-Pyrido(3,4-B)Indole-3-Carboxylic Acid, 4-Ethyl-6,7-Dimethoxy-, Methyl Ester",
      "C17H18N2O4",
      "Lopac-E-007",
      "Biomol-NT_000249",
      "AC1Q5ZP9",
    ],
  },
  {
    primary_id: "D0GQ2G",
    names: [
      "[3H]RY80",
      "CHEMBL286594",
      "Ethyl 8-Ethynyl-5-Methyl-6-Oxo-4H-Imidazo[1,5-A][1,4]benzodiazepine-3-Carboxylate",
      "RY80",
      "AC1NSKLC",
      "SCHEMBL7454519",
      "GTPL4364",
      "BDBM25877",
      "RY-80",
      "PDSP2_001730",
      "PDSP1_001747",
      "RY-80, 1",
      "LS-193636",
      "Ethyl 8-Ethynyl-5,6-Dihydro-5-Methyl-6-Oxo-4h-Imidazo[1,5-A][1,4]benzodiazepine-3-Carboxylate",
      "Ethyl 12-Ethynyl-8-Methyl-9-Oxo-2,4,8-Triazatricyclo[8.4.0.0^{2,6}]tetradeca-1(14),3,5,10,12-Pentaene-5-Carboxylate",
    ],
  },
  {
    primary_id: "D0GQ2Z",
    names: ["Tetra-Hydro-Oxazolopyridine Derivative 1"],
  },
  {
    primary_id: "D0GQ4N",
    names: ["Citrate Salts"],
  },
  {
    primary_id: "D0GQ6D",
    names: ["Aminobenzoxazole Analog 1"],
  },
  {
    primary_id: "D0GQ7K",
    names: ["Mefloquine"],
  },
  {
    primary_id: "D0GQ7L",
    names: ["PMID26161698-Compound-25"],
  },
  {
    primary_id: "D0GQ8W",
    names: ["Carbazole-3-Carboxamide Analog 1"],
  },
  {
    primary_id: "D0GQ9A",
    names: ["Sodium Phenylarsonate"],
  },
  {
    primary_id: "D0GR0I",
    names: [
      "CD19-Targeted Allogeneic T-Cell Therapy",
      "CD19-Targeted Allogeneic T-Cell Therapy (Acute Lymphoblastic Leukemia)",
    ],
  },
  {
    primary_id: "D0GR0T",
    names: [
      "Methadyl Acetate",
      "ALPHACETYLMETHADOL",
      "Acemethadone",
      "Acetilmetadol",
      "Acetylmethadol",
      "Acetylmethadolum",
      "Amidolacetate",
      "Levamethadyl",
      "Acetilmetadol [INN-Spanish]",
      "Acetylmethadol (INN)",
      "Acetylmethadolum [INN-Latin]",
      "Methadyl Acetate (USAN)",
      "Methadyl Acetate [USAN:BAN]",
      "N-LAMM",
      "Race-Acetylmethadol",
      "[6-(Dimethylamino)-4,4-Diphenylheptan-3-Yl] Acetate",
      "Benzeneethanol, Beta-(2-(Dimethylamino)Propyl)-Alpha-Ethyl-Beta-Phenyl-, Acetate (Ester)",
      "Benzeneethanol, .beta.-[2-(Dimethylamino)Propyl]-.alpha.-Ethyl-.beta.-Phenyl-, Acetate",
      "(R,S)-4-Dimethylamino-1-Ethyl-2,2-Diphenylpentyl Acetat",
      "1-Ethyl-4-Dimethylamino-2,2-Diphenylpentylacetat",
      "3-Acetoxy-6-Dimethylamino-4,4-Diphenylheptane",
      "4-(Dimethylamino)-1-Ethyl-2,2-Diphenylpentyl Acetate",
      "6-(Dimethylamino)-4,4-Diphenyl-3-Heptanol Acetate",
    ],
  },
  {
    primary_id: "D0GR1X",
    names: ["Pyrazolodiazepine Derivative 1"],
  },
  {
    primary_id: "D0GR1Y",
    names: ["Minnelide"],
  },
  {
    primary_id: "D0GR2H",
    names: [
      "BMY-44621",
      "CHEMBL304794",
      "BDBM50290020",
      "(S)-5-Guanidino-2-[[1-(Phenylacetyl)-L-Prolyl]amino]pentanal",
      "(S)-1-Phenylacetyl-Pyrrolidine-2-Carboxylic Acid ((S)-1-Formyl-4-Guanidino-Butyl)-Amide",
    ],
  },
  {
    primary_id: "D0GR2J",
    names: ["Idoxifene", "CB-7432", "SB-223030"],
  },
  {
    primary_id: "D0GR3C",
    names: ["Biphenyl Mannoside Derivative 22"],
  },
  {
    primary_id: "D0GR5L",
    names: [
      "AL-43",
      "AL-438",
      "CHEMBL266282",
      "A-240610.0",
      "SCHEMBL4172433",
      "BDBM50107347",
      "A-224817.0",
      "5-Allyl-2,2,4-Trimethyl-10-Methoxy-2,5-Dihydro-1H-1-Aza-6-Oxachrysene",
      "5-Allyl-10-Methoxy-2,2,4-Trimethyl-2,5-Dihydro-1H-6-Oxa-1-Aza-Chrysene",
      "5-Allyl-10-Methoxy-2,2,4-Trimethyl-2,5-Dihydro-1H-Chromeno[3,4-F]quinoline",
      "(+/-)5-Allyl-10-Methoxy-2,2,4-Trimethyl-2,5-Dihydro-1H-6-Oxa-1-Aza-Chrysene",
      "(+)5-Allyl-10-Methoxy-2,2,4-Trimethyl-2,5-Dihydro-1H-6-Oxa-1-Aza-Chrysene",
    ],
  },
  {
    primary_id: "D0GR5Q",
    names: ["Risperidone Controlled-Release Implant"],
  },
  {
    primary_id: "D0GR5X",
    names: [
      "Tolamba",
      "AIC, Dynavax",
      "AIC, UCB Pharma",
      "AIC-0003",
      "Allergy Therapeutic, Dynavax",
      "Amb A 1 ISS Conjugate, Dynavax",
      "Ragweed Allergy Vaccine, Dynavax",
      "Ragweed Allergy Vaccine, UCB Pharma",
      "ISS-1018 (Allergy), Dynavax",
    ],
  },
  {
    primary_id: "D0GR6H",
    names: ["2-Benzoylaminoquinazoline-4-Carboxyanilide", "CHEMBL239194"],
  },
  {
    primary_id: "D0GR6I",
    names: ["Ganitumab"],
  },
  {
    primary_id: "D0GR7W",
    names: ["Mycophenolic Acid/Nucleotide Derivative 2"],
  },
  {
    primary_id: "D0GR9H",
    names: ["CD22-Specific CAR T-Cells Also Expressing An EGFRt"],
  },
  {
    primary_id: "D0GR9O",
    names: [
      "AGN-34",
      "CHEMBL190635",
      "SCHEMBL1984049",
      "7-[2-(Hexyloxy)-3,5-Diisopropylphenyl]-3-Methyl-2,4,6-Octatrienoic Acid",
    ],
  },
  {
    primary_id: "D0GS0O",
    names: [
      "1,4-Dideoxy-1,4-Imino-D-Arabinito",
      "1,4-Dideoxy-1,4-Imino-D-Arabinitol",
      "Imino-D-Arabinitol",
      "CHEMBL80254",
      "3,4-Pyrrolidinediol, 2-(Hydroxymethyl-14C)-, (2R,3R,4R)-",
      "(2R,3R,4R)-2-(Hydroxymethyl)Pyrrolidine-3,4-Diol",
      "259140-24-4",
      "(2R,3R,4R)-2-Hydroxymethyl-Pyrrolidine-3,4-Diol",
      "BDBM50031485",
      "AC1L9PZY",
      "100937-52-8",
      "DAB-1",
      "Lopac0_000366",
      "MLS002153179",
      "SCHEMBL143519",
      "CHEMBL305131",
      "DTXSID10180589",
      "OQEBIHBLFRADNM-UOWFLXDJSA-N",
      "HMS2233K17",
      "ZINC1492250",
      "1,4-Dideoxy-1,4-Imino-Arabinitol",
      "BDBM50016703",
    ],
  },
  {
    primary_id: "D0GS1B",
    names: ["1-(2-Morpholinoethyl)-1H-Indol-3-Yl Acetate", "CHEMBL495249"],
  },
  {
    primary_id: "D0GS2P",
    names: ["2-Piperidine Thiazole Derivative 1"],
  },
  {
    primary_id: "D0GS2W",
    names: ["W-198", "5-Bromotetrandrine"],
  },
  {
    primary_id: "D0GS6T",
    names: ["6-Aryl-Imidazopyridine And 6-Aryl-Triazolopyridine Carboxamide Analog 1"],
  },
  {
    primary_id: "D0GS7N",
    names: [
      "L-158809",
      "2-Ethyl-5,7-Dimethyl-3-[[4-[2-(2H-Tetrazol-5-Yl)Phenyl]phenyl]methyl]imidazo[4,5-B]pyridine",
      "CHEMBL7550",
      "133240-46-7",
      "2-Ethyl-5,7-Dimethyl-3-((4-(2-(2H-Tetrazol-5-Yl)Phenyl)Phenyl)Methyl)Imidazo(4,5-B)Pyridine",
      "YFWXFHNZGKNDBC-UHFFFAOYSA-N",
      "[3H]L158809",
      "[3H]-L158809",
      "AC1L4CB2",
      "[3H]L-158,809",
      "SCHEMBL683357",
      "GTPL3939",
      "BDBM50009718",
      "3H-Imidazo(4,5-B)Pyridine, 2-Ethyl-5,7-Dimethyl-3-((2'-(2H-Tetrazol-5-Yl)(1,1'-Biphenyl)-4-Yl)Methyl)-",
      "LS-193980",
      "L004397",
    ],
  },
  {
    primary_id: "D0GS9E",
    names: [
      "D-Mannose",
      "Mannose",
      "D-Mannopyranose",
      "Seminose",
      "Carubinose",
      "Mannopyranose",
      "Mannopyranoside",
      "D-(+)-Mannose",
      "530-26-7",
      "CHEBI:4208",
      "DL-Mannose",
      "D(+)-Mannose",
      "(+-)-Mannose",
      "(3S,4S,5S,6R)-6-(Hydroxymethyl)Oxane-2,3,4,5-Tetrol",
      "D-Mannopyranoside",
      "D-Man",
      "WQZGKKKJIJFFOK-QTVWNMPRSA-N",
      "Man",
      "Mannopyranose, D-",
      "SMR000857125",
      "EINECS 208-474-2",
      "D-Mannose,(S)",
      "Alpha,beta-D-Mannopyranose",
      "Bmse000018",
      "Bmse000882",
      "Epitope ID:152206",
      "Bmse000874",
      "AC1L2D5C",
      "SCHEMBL38300",
      "MLS001332528",
      "MLS001332527",
      "GTPL4650",
      "CHEMBL469448",
      "DTXSID5040463",
    ],
  },
  {
    primary_id: "D0GS9X",
    names: ["OMP-21M18"],
  },
  {
    primary_id: "D0GT0I",
    names: ["PMID29053063-Compound-11d"],
  },
  {
    primary_id: "D0GT0Y",
    names: ["MN-246"],
  },
  {
    primary_id: "D0GT3R",
    names: ["Anti-CD19 CAR-T Cells"],
  },
  {
    primary_id: "D0GT4N",
    names: [
      "BRL-55834",
      "Brl-55834",
      "Brl 55834",
      "131899-25-7",
      "Brl55834",
      "SCHEMBL6369898",
      "AC1L2Z70",
      "DTXSID80157243",
      "3,4-Dihydro-2,2-Dimethyl-4-(Oxopiperidin-1-Yl)-6-Pentafluoroethyl-2H-1-Benzopyran-3-Ol",
      "2-Piperidinone, 1-(3,4-Dihydro-3-Hydroxy-2,2-Dimethyl-6-(Pentafluoroethyl)-2H-1-Benzopyran-4-Yl)-, (3S-Trans)-",
    ],
  },
  {
    primary_id: "D0GT5M",
    names: ["Tetra-Azabenzo[e]azulene Derivative 2"],
  },
  {
    primary_id: "D0GT6I",
    names: ["(2-Mercapto-Ethyl)-Trimethyl-Ammonium Iodide"],
  },
  {
    primary_id: "D0GT7A",
    names: ["PMID28394193-Compound-14"],
  },
  {
    primary_id: "D0GT8N",
    names: [
      "NM-PP1",
      "1-NM-PP1",
      "221244-14-0",
      "PP1 Analog II, 1NM-PP1",
      "1-Tert-Butyl-3-(Naphthalen-1-Ylmethyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "Mutant Kinases Inhibitor II",
      "1NM-PP1(PP1 Analog II)",
      "AC1NP9I6",
      "CHEMBL573578",
      "1 NM-PP1",
      "CHEBI:52309",
      "4-Amino-1-Tert-Butyl-3-(1'-Naphthylmethyl)Pyrazolo[3,4-D]pyrimidine",
      "1-(Tert-Butyl)-3-(Naphthalen-1-Ylmethyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "MMV676599",
      "1-(1,1-Dimethylethyl)-3-(1-Naphthalenylmethyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "W-201917",
      "C20H21N5",
    ],
  },
  {
    primary_id: "D0GT8W",
    names: ["BMS-587101", "BMS-688521"],
  },
  {
    primary_id: "D0GT9I",
    names: ["Mineral Oil"],
  },
  {
    primary_id: "D0GU1S",
    names: ["JNJ-55920839"],
  },
  {
    primary_id: "D0GU4K",
    names: [
      "Aprepitant",
      "Aprepitant [USAN]",
      "L 754030",
      "MK 0869",
      "Emend (TN)",
      "L-754030",
      "MK-0869",
      "ONO-7436",
      "Aprepitant (JAN/USAN/INN)",
      "MK-869, L-754030, Emend, Aprepitant",
      "3-(((2R,3S)-3-(P-Fluorophenyl)-2-(((AlphaR)-Alpha-Methyl-3,5-Bis(Trifluoromethyl)Benzyl)Oxy)Morpholino)Methyl)-Delta(2)-1,2,4-Triazolin-5-One",
      "3-(((2R,3S)-3-(P-Fluorophenyl)-2-(((AlphaR)-Alpha-Methyl-3,5-Bis(Trifluoromethyl)Benzyl)Oxy)Morpholino)Methyl)-Delta(Sup 2)-1,2,4-Triazolin-5-One",
      "5-[[(2R,3S)-2-[(1R)-1-[3,5-Bis(Trifluoromethyl)Phenyl]ethoxy]-3-(4-Fluorophenyl)Morpholin-4-Yl]methyl]-1,2-Dihydro-1,2,4-Triazol-3-One",
      "5-[[(2S,3R)-2-[(1R)-1-[3,5-Bis(Trifluoromethyl)Phenyl]ethoxy]-3-(4-Fluorophenyl)Morpholin-4-Yl]methyl]-1,2-Dihydro-1,2,4-Triazol-3-One",
      "5-{[(2R,3S)-2-{(1R)-1-[3,5-Bis(Trifluoromethyl)Phenyl]ethoxy}-3-(4-Fluorophenyl)Morpholin-4-Yl]methyl}-2,4-Dihydro-3H-1,2,4-Triazol-3-One",
    ],
  },
  {
    primary_id: "D0GU4M",
    names: [
      "2-Tosylnaphthalene",
      "2-Naphthyl-P-Tolyl Sulfone",
      "CHEMBL462553",
      "AC1LAR2N",
      "SCHEMBL9587829",
      "UOUHDIZYOCFQKG-UHFFFAOYSA-N",
      "4-Methylphenyl 2-Naphthyl Sulfone",
      "ZINC32149231",
      "BDBM50245927",
      "2-(4-Methylphenyl)Sulfonylnaphthalene",
      "2-[(4-Methylphenyl)Sulfonyl]naphthalene",
      "2-[(4-Methylphenyl)Sulfonyl]naphthalene #",
    ],
  },
  {
    primary_id: "D0GU4Q",
    names: ["Mithramycin"],
  },
  {
    primary_id: "D0GU4Z",
    names: ["CLP-1004"],
  },
  {
    primary_id: "D0GU5E",
    names: ["4-(3-Cyanobenzylthio)-2-Aminobutanoic Acid", "CHEMBL380507", "BDBM50179730"],
  },
  {
    primary_id: "D0GU9J",
    names: ["Ad2/HIF-1alpha/VP16"],
  },
  {
    primary_id: "D0GV0H",
    names: ["DPS-201"],
  },
  {
    primary_id: "D0GV0O",
    names: ["Nephesh-1000"],
  },
  {
    primary_id: "D0GV0Q",
    names: [
      "BMY-43748",
      "7-[3(S)-Aminopyrrolidin-1-Yl]-1-(2,4-Difluorophenyl)-6-Fluoro-5-Methyl-4-Oxo-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylicacid",
    ],
  },
  {
    primary_id: "D0GV3X",
    names: ["PMID28766366-Compound-Scheme24-11H-Pyrido[4,3-A]carbazole"],
  },
  {
    primary_id: "D0GV5G",
    names: ["PMID25666693-Compound-66"],
  },
  {
    primary_id: "D0GV6B",
    names: ["Monoaryl-1,2-Diamine Derivative 2"],
  },
  {
    primary_id: "D0GV8J",
    names: ["Talazoparib"],
  },
  {
    primary_id: "D0GV9F",
    names: ["Biaryl Mannoside Derivative 2"],
  },
  {
    primary_id: "D0GV9Q",
    names: ["N-Formylmethionine"],
  },
  {
    primary_id: "D0GW1P",
    names: ["Actacel"],
  },
  {
    primary_id: "D0GW2L",
    names: ["AN-5973"],
  },
  {
    primary_id: "D0GW2Y",
    names: ["11-Deoxycortisol", "Cortexolone", "Cortodoxone", "11-Deoxycortisone"],
  },
  {
    primary_id: "D0GW3R",
    names: ["PAV-612"],
  },
  {
    primary_id: "D0GW4Q",
    names: ["PD-0173956"],
  },
  {
    primary_id: "D0GW5F",
    names: ["Benzazepine Derivative 5"],
  },
  {
    primary_id: "D0GW5H",
    names: ["Polyhydroxy Benzamide Derivative 5"],
  },
  {
    primary_id: "D0GW6K",
    names: ["ADX N05"],
  },
  {
    primary_id: "D0GW6Q",
    names: ["MK-3118"],
  },
  {
    primary_id: "D0GW6V",
    names: [
      "[Sar,D-Phe8,des-Arg9]bradykinin",
      "[N-Methylglycine,D-Phe8,des-Arg9]bradykinin",
      "[sarcosine,D-Phe8,des-Arg9]bradykinin",
      "Sar[DPhe8][des-Arg9]BK",
    ],
  },
  {
    primary_id: "D0GW8L",
    names: ["Pyrrole Derivative 2"],
  },
  {
    primary_id: "D0GW8X",
    names: ["GDC-0077"],
  },
  {
    primary_id: "D0GX0I",
    names: ["EDI200"],
  },
  {
    primary_id: "D0GX2A",
    names: ["NSC-87509"],
  },
  {
    primary_id: "D0GX3K",
    names: ["N,N-Diallyl[D-Pro-10]Dyn A-(1-11)", "CHEMBL266782"],
  },
  {
    primary_id: "D0GX3L",
    names: ["Arbaclofen Placarbil", "AP", "R-Baclofen - XenoPort", "XP-19986"],
  },
  {
    primary_id: "D0GX5C",
    names: ["PMID23084894C9n"],
  },
  {
    primary_id: "D0GX6V",
    names: ["Pyrazole Derivative 1"],
  },
  {
    primary_id: "D0GX7G",
    names: [
      "N5-(1-Iminobutyl)-L-Ornithine",
      "CHEMBL442896",
      "Ethyl-L-NIO",
      "AC1NRDHJ",
      "(2S)-2-Amino-5-(1-Aminobutylideneamino)Pentanoic Acid",
      "BDBM152700",
      "BDBM50255074",
      "ZINC12503853",
      "(S)-2-Amino-5-Butyrimidoylamino-Pentanoic Acid",
      "(S)-2-Amino-5-Butyrimidamidopentanoic Acid (9)",
    ],
  },
  {
    primary_id: "D0GX7W",
    names: ["PMID25991433-Compound-P3"],
  },
  {
    primary_id: "D0GX8F",
    names: ["BN-82002"],
  },
  {
    primary_id: "D0GX8J",
    names: ["PMID29338548-Compound-34"],
  },
  {
    primary_id: "D0GY0E",
    names: ["Benzothiazepine Analog 5"],
  },
  {
    primary_id: "D0GY0O",
    names: [
      "BAY-X-7195",
      "UGWPB2QIT9",
      "UNII-UGWPB2QIT9",
      "BAY X-7195",
      "BAY X 7195",
      "AC1O5X0Q",
      "143538-27-6",
      "SCHEMBL156423",
      "CHEMBL314115",
      "4-[(Z,4S)-7-Hydroxy-7-Oxo-1-[4-(4-Phenoxybutoxy)Phenyl]hept-2-En-4-Yl]sulfanylbenzoic Acid",
    ],
  },
  {
    primary_id: "D0GY0U",
    names: ["MultiGeneAngio Cell Therapy"],
  },
  {
    primary_id: "D0GY1D",
    names: ["FP0011"],
  },
  {
    primary_id: "D0GY1Y",
    names: ["CS-8080"],
  },
  {
    primary_id: "D0GY2Z",
    names: ["Indomethacin Analog 1"],
  },
  {
    primary_id: "D0GY3R",
    names: ["SERMs"],
  },
  {
    primary_id: "D0GY4F",
    names: ["PMID25399762-Compound-Table1-C8"],
  },
  {
    primary_id: "D0GY4H",
    names: ["Taberminogene Vadenovec"],
  },
  {
    primary_id: "D0GY4V",
    names: ["TY-11345"],
  },
  {
    primary_id: "D0GY5T",
    names: ["Tuberculin"],
  },
  {
    primary_id: "D0GY5Z",
    names: [
      "Aspirin",
      "ACETYLSALICYLIC ACID",
      "50-78-2",
      "2-Acetoxybenzoic Acid",
      "O-Acetoxybenzoic Acid",
      "O-Acetylsalicylic Acid",
      "Polopiryna",
      "Acylpyrin",
      "Ecotrin",
      "Easprin",
      "Acetylsalicylate",
      "Acetophen",
      "Acenterine",
      "Acetosal",
      "Colfarit",
      "Salicylic Acid Acetate",
      "O-Carboxyphenyl Acetate",
      "Enterosarein",
      "Aspergum",
      "Salcetogen",
      "Pharmacin",
      "Acetosalin",
      "Premaspin",
      "Micristin",
      "Benaspir",
      "Aspirdrops",
      "Acetonyl",
      "Aceticyl",
      "Temperal",
      "Acetylin",
      "Empirin",
      "Ecolen",
      "Rhodine",
      "Endydol",
      "Saletin",
      "Rheumintabletten",
      "Solprin Acid",
      "Acidum Acetylsalicylicum",
      "Globentyl",
      "Pravigard",
    ],
  },
  {
    primary_id: "D0GY8G",
    names: ["Thiazole Carboxamide Derivative 19"],
  },
  {
    primary_id: "D0GY8R",
    names: ["2-(N-Pyrrolidinyl)-3'-Methylpropiophenone", "CHEMBL589494", "BDBM50308118"],
  },
  {
    primary_id: "D0GZ3A",
    names: ["L19-TNF-Alpha"],
  },
  {
    primary_id: "D0GZ3J",
    names: ["NAMI-A"],
  },
  {
    primary_id: "D0GZ4G",
    names: ["PMID25666693-Compound-4"],
  },
  {
    primary_id: "D0GZ5F",
    names: ["CX-1001"],
  },
  {
    primary_id: "D0GZ5M",
    names: ["Avobenzone;Ecamsule;Octocrylene", "Anthelios"],
  },
  {
    primary_id: "D0GZ7B",
    names: [
      "AG490",
      "(E)-N-Benzyl-2-Cyano-3-(3,4-Dihydroxyphenyl)Acrylamide",
      "133550-30-8",
      "AG-490",
      "Tyrphostin B42",
      "Tyrphostin AG 490",
      "AG 490",
      "Tyrphostin AG490",
      "Tyrphostin AG-490",
      "AG-490 (Tyrphostin B42)",
      "Alpha-Cyano-(3,4-Dihydroxy)-N-Benzylcinnamide",
      "N-Benzyl-2-Cyano-3-(3,4-Dihydroxyphenyl)Acrylamide",
      "134036-52-5",
      "(2E)-N-Benzyl-2-Cyano-3-(3,4-Dihydroxyphenyl)Prop-2-Enamide",
      "CHEMBL56543",
      "(E)-N-Benzyl-2-Cyano-3-(3,4-Dihydroxyphenyl)Prop-2-Enamide",
      "(2E)-2-CYANO-3-(3,4-DIHYDROXYPHENYL)-N-(PHENYLMETHYL)-2-PROPENAMIDE",
      "SMR001230665",
    ],
  },
  {
    primary_id: "D0H0AO",
    names: ["Phenyl 7-Methoxy-4-Phenylbenzofuran-2-Ylcarbamate", "CHEMBL597237"],
  },
  {
    primary_id: "D0H0AT",
    names: ["2-(4-Nitrophenyl)-5-(4-Methoxyphenyl)Thiophene", "CHEMBL205403"],
  },
  {
    primary_id: "D0H0AU",
    names: ["VER-155008"],
  },
  {
    primary_id: "D0H0BD",
    names: ["[3H]strychnine"],
  },
  {
    primary_id: "D0H0BG",
    names: ["Sulbactam Sodium"],
  },
  {
    primary_id: "D0H0BQ",
    names: ["NM-IL-12"],
  },
  {
    primary_id: "D0H0CH",
    names: ["PMID30107136-Compound-Example54"],
  },
  {
    primary_id: "D0H0DC",
    names: ["Des-AA1,2,4,5,10,12,13-[D-Trp8]SRIF", "CHEMBL438285"],
  },
  {
    primary_id: "D0H0DP",
    names: ["3-Amino-3-Demethoxythiocolchicine"],
  },
  {
    primary_id: "D0H0EC",
    names: ["KL044"],
  },
  {
    primary_id: "D0H0EM",
    names: ["Dofequidar Fumarate"],
  },
  {
    primary_id: "D0H0EN",
    names: ["(2'Z,3'E)-5-Nitro-5'-Methyl-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0H0EP",
    names: ["EDP-18"],
  },
  {
    primary_id: "D0H0EQ",
    names: ["Ponatinib"],
  },
  {
    primary_id: "D0H0FG",
    names: [
      "Dehydropipernonaline",
      "107584-38-3",
      "Dehydropipernoline",
      "Piperidine, 1-[9-(1,3-Benzodioxol-5-Yl)-1-Oxo-2,4,8-Nonatrienyl]-, (E,E,E)-",
      "Piperidine, 1-(9-(1,3-Benzodioxol-5-Yl)-1-Oxo-2,4,8-Nonatrienyl)-, (E,E,E)-",
      "AC1O5SQM",
      "CHEMBL483708",
      "MolPort-039-101-180",
      "KAYVDASZRFLFRZ-PQECNABGSA-N",
      "N-(9-(3,4-Methylenedioxyphenyl)-2,4,8-Nonatrienoyl)Piperidine",
      "ZINC14658239",
      "9-(3,4-Methylenedioxy)-2,4,8-Nonatrienoic Acid Piperidide",
      "(2E,4E,8E)-9-(1,3-Benzodioxol-5-Yl)-1-Piperidin-1-Ylnona-2,4,8-Trien-1-One",
      "Piperidine, 1-[(2E,4E,8E)-9-(1",
    ],
  },
  {
    primary_id: "D0H0FX",
    names: ["NCX-6560"],
  },
  {
    primary_id: "D0H0GJ",
    names: ["CSNLSTCVLGKLSQELNKLHBYPRTNTGSGTP-Amide"],
  },
  {
    primary_id: "D0H0GY",
    names: ["HL-001"],
  },
  {
    primary_id: "D0H0GZ",
    names: [
      "2-Oxo-N-P-Tolyl-2H-Chromene-3-Carboxamide",
      "1847-00-3",
      "N-(4-Methylphenyl)-2-Oxo-2H-Chromene-3-Carboxamide",
      "AC1LCCPS",
      "BAS 00837919",
      "Oprea1_724223",
      "Oprea1_384928",
      "3-Carboxamido Coumarin, 12",
      "CBDivE_000120",
      "CHEMBL512843",
      "AC1Q2M91",
      "ZINC59647",
      "BDBM29162",
      "2h-1-Benzopyran-3-Carboxamide,n-(4-Methylphenyl)-2-Oxo-",
      "MolPort-000-375-219",
      "RYYPYTSGWXNKDU-UHFFFAOYSA-N",
      "N-(P-Tolyl)Coumarin-3-Carboxamide",
      "N-(P-Tolyl)-3-Coumarincarboxamide",
      "STK709419",
      "AKOS000533534",
      "MCULE-3147355051",
      "ST4011307",
      "KB-284700",
      "EU-0034745",
      "2-Oxo-N-(P-Tolyl)-2H-Chromene-3-Carboxamide",
    ],
  },
  {
    primary_id: "D0H0HB",
    names: ["FAV-201"],
  },
  {
    primary_id: "D0H0HJ",
    names: ["Tranylcypromine"],
  },
  {
    primary_id: "D0H0HM",
    names: ["(E)-3-(4-Chlorophenylimino)Indolin-2-One"],
  },
  {
    primary_id: "D0H0HR",
    names: ["Torin 1"],
  },
  {
    primary_id: "D0H0IA",
    names: ["WY-28342"],
  },
  {
    primary_id: "D0H0IZ",
    names: ["N-Phenyl-3-(2-Aminoethyl)Benzenesulfonamide", "CHEMBL415046", "BDBM50193460"],
  },
  {
    primary_id: "D0H0KA",
    names: ["Macbecin"],
  },
  {
    primary_id: "D0H0KB",
    names: ["Riluzole"],
  },
  {
    primary_id: "D0H0KH",
    names: ["GS-4059"],
  },
  {
    primary_id: "D0H0KK",
    names: ["PD-155080", "CHEMBL274383", "SCHEMBL8187314", "BDBM50034256"],
  },
  {
    primary_id: "D0H0KP",
    names: [
      "6-(4-(3-(Benzylamino)Propyl)Phenoxy)Nicotinamide",
      "CHEMBL236170",
      "SCHEMBL3697663",
      "FZBJOVYOONJNIG-UHFFFAOYSA-N",
      "BDBM50219917",
    ],
  },
  {
    primary_id: "D0H0LK",
    names: ["IC9.GD2.CAR.IL-15 T-Cells"],
  },
  {
    primary_id: "D0H0LO",
    names: ["Carboxamide Derivative 6"],
  },
  {
    primary_id: "D0H0LP",
    names: ["PMID20483621C5g"],
  },
  {
    primary_id: "D0H0MK",
    names: ["[Mpa1, D-Tyr(Et)2, Aib7]OT", "CHEMBL393021"],
  },
  {
    primary_id: "D0H0MO",
    names: ["1,3,4-Oxadiazole Derivative 5"],
  },
  {
    primary_id: "D0H0ND",
    names: [
      "Simvastatin",
      "Cholestat",
      "Coledis",
      "Colemin",
      "Corolin",
      "Denan",
      "Labistatin",
      "Lipex",
      "Lipovas",
      "Lodales",
      "Medipo",
      "Nivelipol",
      "Pantok",
      "Rendapid",
      "Simovil",
      "Simvastatina",
      "Simvastatine",
      "Simvastatinum",
      "Sinvacor",
      "Sivastin",
      "Synvinolin",
      "Vasotenal",
      "Zocor",
      "Zocord",
      "Simvast CR",
      "Simvastatina [Spanish]",
      "Simvastatine [French]",
      "Simvastatinum [Latin]",
      "MK 0733",
      "MK 733",
      "MK733",
      "TNP00259",
      "DRG-0320",
      "KS-1113",
      "L 644128-000U",
      "MK-0733",
      "MK-733",
      "Simcard (TN)",
      "Simlup (TN)",
      "Simvacor (TN)",
      "Simvastatin & Primycin",
      "Simvastatin, Compactin",
      "Zocor (TN)",
      "Simvastatin [USAN:INN:BAN]",
      "Simvastatin (JAN/USP/INN)",
      "Zocor, Simlup, Simcard, Simvacor, Simvoget, Zorced, Simvastatin",
      "[(1S,3R,7S,8S,8aR)-8-[2-[(2R,4R)-4-Hydroxy-6-Oxooxan-2-Yl]ethyl]-3,7-Dimethyl-1,2,3,7,8,8a-Hexahydronaphthalen-1-Yl] 2,2-Dimethylbutanoate",
      "Butanoic Acid, 2,2-Dimethyl-, (1S,3R,7S,8S,8aR)-1,2,3,7,8,8a-Hexahydro-3,7-Dimethyl-8-[2-[(2R,4R)-Tetrahydro-4-Hydroxy-6-Oxo-2H-Pyran-2-Yl]ethyl]-1-Naphthalenyl Ester",
      "Butanoic Acid, 2,2-Dimethyl-, (1S,3R,7S,8S,8aR)-1,2,3,7,8,8a-Hexahydro-3,7-Dimethyl-8-(2-((2R,4R)-Tetrahydro-4-Hydroxy-6-Oxo-2H-Pyran-2-Yl)Ethyl)-1-Naphthalenyl Ester",
      "Butanoic Acid, 2,2-Dimethyl-, (1S,3R,7S,8S,*aR)-1,2,3,7,8,8a-Hexahydro-3,7-Dimethyl-8-(2-((2R,4R)-Tetrahydro-4-Hydroxy-6-Oxo-2H-Pyran-2-Yl)Ethyl)-1-Naphthalenyl Ester",
      "(1S,3R,7S,8S,8aR)-8-{2-[(2R,4R)-4-Hydroxy-6-Oxotetrahydro-2H-Pyran-2-Yl]ethyl}-3,7-Dimethyl-1,2,3,7,8,8a-Hexahydronaphthalen-1-Yl 2,2-Dimethylbutanoate",
      "Butanoic Acid, 2,2-Dimethyl-,1,2,3,7,8,8a-Hexahydro-3,7-Dimethyl-8-[2-(Tetrahydro-4-Hydroxy-6-Oxo-2H-Pyran-2-Yl)-Ethyl]-1-Naphthalenyl Ester, [1S-[1 Alpha,3 Alpha,7 Beta,8 Beta(2S*,4S*),-8a Beta",
      "2,2-Dimethylbutanoic Acid (1S,3R,7S,8S,8aR)-1,2,3,7,8,8a-Hexahydro-3,7-Dimethyl-8-[2-[(2R,4R)-Tetrahydro-4-Hydroxy-6-Oxo-2H-Pyran-2-Yl]ethyl]-1-Naphthalenyl Ester",
      "2,2-Dimethylbutyric Acid, 8-Ester With (4R,6R)-6-(2-((1S,2S,6R,8S,8aR)-1,2,6,7,8,8a-Hexahydro-8-Hydroxy-2,6-Dimethyl-1-Naphthyl)Ethyl)Tetrahydro-4-Hydroxy-2H-Pyran-2-One",
    ],
  },
  {
    primary_id: "D0H0NE",
    names: ["6,9-Dimethyl-2-Oxa-Spiro[4.4]nonan-1-One"],
  },
  {
    primary_id: "D0H0OO",
    names: ["Dynorphin B"],
  },
  {
    primary_id: "D0H0PL",
    names: ["LY215840"],
  },
  {
    primary_id: "D0H0PQ",
    names: [
      "Aminocarbonyl Dihydrogen Phosphate",
      "Carbamoylphosphate",
      "CARBAMYL PHOSPHATE",
      "Carbamoyl Phosphate",
      "Phosphono Carbamate",
      "590-55-6",
      "Monocarbamoyl Phosphate",
      "PHOSPHORIC ACID MONO(FORMAMIDE)ESTER",
      "Carbamoyl Dihydrogen Phosphate",
      "CHEMBL369105",
      "Carbamic Phosphoric Monoanhydride",
      "CHEBI:17672",
      "Carbamoyl-P",
      "Carbamyl-Phosphate",
      "Carbamoyl-Phosphate",
      "Carbamoyl Phosphoric Acid",
      "AC1L18VP",
      "SCHEMBL50374",
      "Carbamic Acid, Monoanhydride With Phosphoric Acid",
      "(Carbamoyloxy)Phosphonic Acid",
      "AC1Q68Q7",
      "ZINC8383183",
      "BDBM50162819",
      "AKOS006341689",
      "Carbamic Aci",
    ],
  },
  {
    primary_id: "D0H0QR",
    names: ["PMID28906174-Compound-Figure1g"],
  },
  {
    primary_id: "D0H0SE",
    names: ["TRIPHLOROETHOL A"],
  },
  {
    primary_id: "D0H0SH",
    names: [
      "ATB-284",
      "ATB-282",
      "IBS Therapeutics, Antibe Therapeutics",
      "Irritable Bowel Syndrome Therapeutics, Antibe Therapeutics",
    ],
  },
  {
    primary_id: "D0H0SJ",
    names: ["Neocarzinostatin"],
  },
  {
    primary_id: "D0H0SL",
    names: ["Hemoglobin Glutamer"],
  },
  {
    primary_id: "D0H0UB",
    names: ["Darotropium + 642444"],
  },
  {
    primary_id: "D0H0UC",
    names: ["Resorcinol Compound 12"],
  },
  {
    primary_id: "D0H0VB",
    names: ["BC-822"],
  },
  {
    primary_id: "D0H0VI",
    names: ["Neo-GAA"],
  },
  {
    primary_id: "D0H0VS",
    names: ["SBC-104"],
  },
  {
    primary_id: "D0H0VW",
    names: ["Oncolysin S"],
  },
  {
    primary_id: "D0H0WK",
    names: ["Gadofosveset"],
  },
  {
    primary_id: "D0H0WL",
    names: ["PT-106"],
  },
  {
    primary_id: "D0H0XC",
    names: ["2-Cycloalkyl Resorcinol Cannabinoid Ligand Derivative 2"],
  },
  {
    primary_id: "D0H0XX",
    names: ["PMID26666989-Compound-Figure12bottommiddle01"],
  },
  {
    primary_id: "D0H0YD",
    names: [
      "Impoyz",
      "Clobetasol Propionate",
      "CLOBETASOL PROPIONATE",
      "25122-46-7",
      "Clobetasol 17-Propionate",
      "Clobex",
      "Temovate",
      "Dermovate",
      "Embeline",
      "Embeline E",
      "Clobesol",
      "Cormax",
      "Olux",
      "Temovate E",
      "Olux-E",
      "Clobetasol 17-Propanoate",
      "GR 2/925",
      "C25H32ClFO5",
      "MLS000028708",
      "Clobestasol Propionate",
      "Clobetasol Propionate [USAN:JAN]",
      "CGP 9555",
      "CCI 4725",
      "EINECS 246-634-3",
      "21-Chloro-9-Fluoro-11beta,17-Dihydroxy-16beta-Methylpregna-1,4-Diene-3,20-Dione 17-Propionate",
      "UNII-779619577M",
      "[(8S,9R,10S,11S,13S,14S,16S,17R)-17-(2-Chloroacetyl)-9-Fluoro-11-Hydroxy-10,13,16-Trimethyl-3-Oxo-6,7,8,11,12",
    ],
  },
  {
    primary_id: "D0H0YU",
    names: ["ISIS 111997"],
  },
  {
    primary_id: "D0H0YZ",
    names: ["GGF-2"],
  },
  {
    primary_id: "D0H0ZQ",
    names: ["Ad35.CS.01"],
  },
  {
    primary_id: "D0H0ZU",
    names: ["MP-435", "C5a Antagonist (Rheumatoid Arthritis), Mitsubishi Tanabe"],
  },
  {
    primary_id: "D0H0ZV",
    names: ["MEDI7814"],
  },
  {
    primary_id: "D0H1AR",
    names: [
      "Methacycline",
      "Bialatan",
      "Metaciclina",
      "Metacycline",
      "Metacyclinum",
      "Methacyclin",
      "Methacyclinum",
      "Methylenecycline",
      "Methyleneoxytetracycline",
      "Rondomycin",
      "Methacycline Amphoteric",
      "Methacycline Base",
      "GS 2876",
      "GS-2876",
      "Metaciclina [INN-Spanish]",
      "Metacycline (INN)",
      "Metacyclinum [INN-Latin]",
      "Methacycline (USAN)",
      "Methacycline [USAN:BAN]",
      "Tri-Methacycline",
      "(2Z,4S,4aR,5S,5aR,12aS)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,10,11,12a-Tetrahydroxy-6-Methylidene-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(4S,4aR,5S,5aR,12aS)-4-(Dimethylamino)-3,5,10,12,12a-Pentahydroxy-6-Methylidene-1,11-Dioxo-1,4,4a,5,5a,6,11,12a-Octahydrotetracene-2-Carboxamide",
      "4-(Dimethylamino)-1,4,4a,5,5a,6,11,12a-Octahydro-3,5,10,12,12a-Pentahydroxy-6-Methylene-1,11-Dioxo-2-Naphthacenecarboxamide",
      "6-Demethyl-6-Deoxy-5-Hydroxy-6-Methylenetetracycline",
      "6-Deoxy-6-Demethyl-6-Methylene-5-Oxytetracycline",
      "6-Methylene-5-Hydroxytetracycline",
      "6-Methylene-5-Oxytetracycline",
      "6-Methyleneoxytetracycline",
    ],
  },
  {
    primary_id: "D0H1AY",
    names: [
      "AQW-051",
      "669770-29-0",
      "(R)-3-((6-(P-Tolyl)Pyridin-3-Yl)Oxy)Quinuclidine",
      "AQW051",
      "NPDLTEZXGWRMLQ-IBGZPJMESA-N",
      "UNII-JQH481R778",
      "SCHEMBL1459285",
      "GTPL7371",
      "SCHEMBL19522679",
      "JQH481R778",
      "ZINC3942685",
      "AKOS030628482",
      "SB17130",
      "AS-35273",
      "(R)-3-(6-P-Tolyl-Pyridin-3-Yloxy)-1-Aza-Bicyclo[2.2.2]octane",
    ],
  },
  {
    primary_id: "D0H1BF",
    names: ["Sp-Adenosine-3',5'-Cyclic-Monophosphorothioate", "AC1NRD1K"],
  },
  {
    primary_id: "D0H1BQ",
    names: ["PMID27539678-Compound-14"],
  },
  {
    primary_id: "D0H1BU",
    names: ["H-Dmt-Tic-Gly-NH-Bzl"],
  },
  {
    primary_id: "D0H1BY",
    names: [
      "Bishydroxy[2h-1-Benzopyran-2-One,1,2-Benzopyrone]",
      "BISHYDROXY[2H-1-BENZOPYRAN-2-ONE,1,2-BENZOPYRONE]",
      "DB04392",
    ],
  },
  {
    primary_id: "D0H1BZ",
    names: ["Biaryl Mannoside Derivative 19"],
  },
  {
    primary_id: "D0H1CO",
    names: [
      "2-Naphthalen-1-Yl-Benzooxazol-6-Ol",
      "CHEMBL188527",
      "2-(Naphthalen-1-Yl)Benzo[d]oxazol-6-Ol",
      "SCHEMBL6311736",
      "LYJKLNQQSXCGDI-UHFFFAOYSA-N",
      "BDBM50154077",
      "ZINC12353755",
    ],
  },
  {
    primary_id: "D0H1DV",
    names: [
      "N-Phenyl-5,6-Dip-Tolylpyrazine-2-Carboxamide",
      "CHEMBL225242",
      "SCHEMBL4450848",
      "BDBM35881",
      "5,6-Diaryl-Pyrazine-2-Amide Derivative, 7b",
      "5,6-Di-P-Tolyl-Pyrazine-2-Carboxylic Acid Phenylamide",
    ],
  },
  {
    primary_id: "D0H1EA",
    names: ["TH-302"],
  },
  {
    primary_id: "D0H1EB",
    names: ["ATLa2", "ATL Analog [15-Epi-16-(Para-Fluoro)-Phenoxy-LXA4]"],
  },
  {
    primary_id: "D0H1EI",
    names: [
      "5-(4-Phenylbutoxy)Psoralen",
      "4-(4-Phenylbutoxy)Furo[3,2-G]chromen-7-One",
      "Psora-4",
      "Psora 4",
      "724709-68-6",
      "CHEMBL1256851",
      "4-(4-Phenylbutoxy)-7H-Furo[3,2-G]chromen-7-One",
      "4-(4-Phenylbutoxy)-7H-Furo[3,2-G][1]benzopyran-7-One",
      "NCGC00015863-01",
      "Lopac-P-9872",
      "SCHEMBL2801777",
      "GTPL9583",
      "AC1O7G66",
      "CTK8F0317",
      "DTXSID10424970",
      "MolPort-023-277-165",
      "HMS3260K16",
      "ZINC7997390",
      "Psora-4, &gt",
      "Tox21_500137",
      "BDBM50331458",
      "AKOS024458119",
      "LP00137",
      "CCG-204232",
      "NCGC00015863-02",
      "NCGC00015863-03",
      "NCGC00093627-02",
      "NCGC00260822-01",
      "NCGC00093627-01",
    ],
  },
  {
    primary_id: "D0H1FL",
    names: ["PF-514273"],
  },
  {
    primary_id: "D0H1FN",
    names: ["AL-02"],
  },
  {
    primary_id: "D0H1FV",
    names: ["Selfotel"],
  },
  {
    primary_id: "D0H1FZ",
    names: ["2-(Methylsulfonylthio)Ethyl 2-Propylpentanoate", "CHEMBL271677", "SCHEMBL4156413"],
  },
  {
    primary_id: "D0H1GF",
    names: ["Sch-34826"],
  },
  {
    primary_id: "D0H1GJ",
    names: [
      "Sulfamerazine",
      "Cremomerazine",
      "Kelamerazine",
      "Mebacid",
      "Mesulfa",
      "Methylpyrimal",
      "Methylsulfadiazine",
      "Methylsulfazin",
      "Methylsulfazine;Metilsulfadiazin",
      "Metilsulfazin",
      "Percoccide",
      "Pyralcid",
      "Romezin",
      "Septacil",
      "Septosyl",
      "Solfamerazina",
      "Solumedin",
      "Sulfameradine",
      "Sulfamerazin",
      "Sulfamerazina",
      "Sulfamerazinum",
      "Sulfamethyldiazine",
      "Sulphamerazine",
      "Sumedine",
      "Pyrimal M",
      "Solfamerazina [DCIT]",
      "RP 2632",
      "A-310",
      "Debenal-M",
      "Pirimal-M",
      "Sulfamerazina [INN-Spanish]",
      "Sulfamerazine (INN)",
      "Sulfamerazinum [INN-Latin]",
      "Veta-Merazine",
      "Sulfamerazine [USAN:INN:BAN]",
      "N-(4-Methyl-2-Pyrimidyl)Sulfanilamide",
      "N1-(4-Methyl-2-Pyrimidinyl)Sulfanilamide",
      "N1-(4-Methylpyrimidin-2-Yl)Sulfanilamide",
      "N(1)-(4-Methyl-2-Pyrimidinyl)Sulfanilamide",
      "N(Sup 1)-(4-Methyl-2-Pyrimidinyl)Sulfanilamide",
      "N(Sup1)-(4-Methyl-2-Pyrimidinyl)Sulfanilamide",
      "Sulfanilamide, N1-(4-Methyl-2-Pyrimidinyl)-(8CI)",
      "(P-Aminobenzolsulfonyl)-2-Amino-4-Methylpyrimidin",
      "(P-Aminobenzolsulfonyl)-2-Amino-4-Methylpyrimidin [German]",
      "2(P-Aminobenzolsulfonamido)-4-Methylpyrimidin",
      "2-(4-Aminobenzenesulfonamido)-4-Methylpyrimidine",
      "2-(Sulfanilamido)-4-Methylpyrimidine",
      "2-(P-Aminobenzolsulfonamido)-4-Methylpyrimidine",
      "2-Sulfa-4-Methylpyrimidine",
      "2-Sulfanilamido-4-Methylpyrimidine",
      "2643-RP",
      "4-Amino-N-(4-Methyl-2-Pyrimidinyl)-Benzenesulfonamide",
      "4-Amino-N-(4-Methyl-2-Pyrimidinyl)-Benzenesulfonamide (9CI)",
      "4-Amino-N-(4-Methyl-2-Pyrimidinyl)Benzenesulfonamide",
      "4-Amino-N-(4-Methyl-Pyrimidin-2-Yl)-Benzenesulfonamide",
      "4-Amino-N-(4-Methylpyrimidin-2-Yl)Benzene-1-Sulfonamide",
      "4-Amino-N-(4-Methylpyrimidin-2-Yl)Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0H1GQ",
    names: ["2-(3-Benzylquinoxalin-2-Ylamino)Ethanol", "CHEMBL203892"],
  },
  {
    primary_id: "D0H1GU",
    names: ["Double Oxidized Cysteine"],
  },
  {
    primary_id: "D0H1HP",
    names: ["RPR-109891"],
  },
  {
    primary_id: "D0H1IC",
    names: ["MiRNA Mimetics"],
  },
  {
    primary_id: "D0H1II",
    names: [
      "1-(3-Cyano-1-Methyl-1H-Indol-6-Yl)-3-Phenylurea",
      "CHEMBL333482",
      "AC1LABSI",
      "BDBM50133727",
      "1-(3-Cyano-1-Methylindol-6-Yl)-3-Phenylurea",
      "1-(3-Cyano-1-Methyl-1H-Indol-6-Yl)-3-Phenyl-Urea",
    ],
  },
  {
    primary_id: "D0H1IX",
    names: ["Pyrazole Derivative 5"],
  },
  {
    primary_id: "D0H1JN",
    names: ["NsG-0501", "ECB-EP", "Encapsulated Cell Technology (Epilepsy), NsGene"],
  },
  {
    primary_id: "D0H1JS",
    names: ["Piperazine Urea Derivative 3"],
  },
  {
    primary_id: "D0H1JX",
    names: ["3,9-Dihydroxy-2,10-Diprenylpterocap-6a-Ene"],
  },
  {
    primary_id: "D0H1LK",
    names: [
      "3-(4-Amino-Phenyl)-Pyrrolidine-2,5-Dione",
      "32856-49-8",
      "3-(4-Aminophenyl)Pyrrolidine-2,5-Dione",
      "3-(4-Aminophenyl)-2,5-Pyrrolidinedione",
      "CHEMBL148903",
      "2,5-Pyrrolidinedione, 3-(4-Aminophenyl)-",
      "WSP-3",
      "3-(4'-Aminophenyl)Pyrrolidine-2,5-Dione",
      "AC1Q6LVA",
      "2-(P-Aminophenyl) Succinimide",
      "SCHEMBL3274268",
      "AC1L4Q15",
      "CTK4G9448",
      "BDBM50025154",
      "FCH920777",
      "AKOS006343465",
      "AB07636",
      "CC-12440",
      "KB-26942",
      "AS-35730",
      "2,5-Pyrrolidinedione,3-(4-Aminophenyl)-",
      "AX8033232",
      "AB0073720",
      "FT-0691904",
      "X-3375",
      "C-33345",
      "3-(4-Amino-Phenyl)-Pyrrolidine-2,5-Dione, AldrichCP",
    ],
  },
  {
    primary_id: "D0H1LO",
    names: ["Lanthanum Carbonate"],
  },
  {
    primary_id: "D0H1LQ",
    names: ["PMID27788040-Compound-6c"],
  },
  {
    primary_id: "D0H1MK",
    names: ["INN-202"],
  },
  {
    primary_id: "D0H1MV",
    names: ["RLP-068"],
  },
  {
    primary_id: "D0H1NI",
    names: ["PINS"],
  },
  {
    primary_id: "D0H1NL",
    names: ["TESAGLITAZAR"],
  },
  {
    primary_id: "D0H1NU",
    names: ["4-[4-(Benzyloxy)Piperidino]butyl-4-Chlorobenzoate", "CHEMBL389048", "SCHEMBL4020282"],
  },
  {
    primary_id: "D0H1OB",
    names: ["ISIS 116670"],
  },
  {
    primary_id: "D0H1OK",
    names: ["TG-100435"],
  },
  {
    primary_id: "D0H1OL",
    names: ["Guanosine"],
  },
  {
    primary_id: "D0H1OO",
    names: [
      "AFT-EPO",
      "AFT-EPO (Sustained Release, ProFuse, Anemia)",
      "AFT-EPO (Sustained Release, ProFuse, Anemia), Asterion",
      "AFT-Erythropoietin (Sustained Release, ProFuse, Anemia), Asterion",
    ],
  },
  {
    primary_id: "D0H1PK",
    names: ["MVA-BN"],
  },
  {
    primary_id: "D0H1PN",
    names: [
      "ME-3277",
      "4-[2-(4,5,6,7-Tetrahydrothieno[3,2-C]pyridin-2-Ylcarboxamido)Acetyl]phenylene-1,2-Dioxybis(Acetic Acid)",
    ],
  },
  {
    primary_id: "D0H1QY",
    names: [
      "Camphor",
      "76-22-2",
      "2-Camphanone",
      "2-Bornanone",
      "DL-Camphor",
      "(+)-Camphor",
      "L-(-)-Camphor",
      "(+/-)-Camphor",
      "Root Bark Oil",
      "1,7,7-Trimethylbicyclo[2.2.1]heptan-2-One",
      "Bornan-2-One",
      "Alphanon",
      "Kampfer",
      "Gum Camphor",
      "Spirit Of Camphor",
      "Formosa Camphor",
      "Laurel Camphor",
      "2-Camphonone",
      "Matricaria Camphor",
      "Camphor, Synthetic",
      "Bornane, 2-Oxo-",
      "1,7,7-Trimethylnorcamphor",
      "Root Bark Spirit",
      "2-Keto-1,7,7-Trimethylnorcamphane",
      "DL-Bornan-2-One",
      "D-(+)-Camphor",
      "Norcamphor, 1,7,7-Trimethyl-",
      "21368-68-3",
      "Camphor, (1R,4R)-(+)-",
      "Bicyclo[2.2.1]heptan-2-One, 1,7,7-Tri",
    ],
  },
  {
    primary_id: "D0H1TA",
    names: [
      "EK-399",
      "Ek-399",
      "AC1L2RJX",
      "AC1Q5SZK",
      "Ekk-399",
      "(2r)-N-(2-{[(2s)-1-(2-Acetylhydrazinyl)-1-Oxo-3-Phenylpropan-2-Yl](Ethyl)Amino}-2-Oxoethyl)-2-{[(2s)-2-Amino-3-(4-Hydroxyphenyl)Propanoyl]amino}-4-(Methylsulfinyl)Butanamide Acetate(1:1)(Non-Preferred Name)",
      "Tyr-Met(O)-Gly-Etphe-Nhnhcoch3",
      "98849-86-6",
      "Tyrosyl-Methionyl(O)-Glycyl-Ethylphenylalanine-2-Acetylhydrazide",
      "L-Phenylalanine, L-Tyrosyl-4-(Methylsulfinyl)-D-2-Aminobutanoylglycyl-N-Ethyl-, 2-Acetylhydrazide, Monoacetate (Salt)",
    ],
  },
  {
    primary_id: "D0H1TG",
    names: ["ISIS-CRPRx"],
  },
  {
    primary_id: "D0H1TL",
    names: ["ABT-874"],
  },
  {
    primary_id: "D0H1UB",
    names: ["PanCyte"],
  },
  {
    primary_id: "D0H1UK",
    names: [
      "MK-8591",
      "4'-Ethynyl-2-Fluoro-2'-Deoxyadenosine",
      "UNII-QPQ082R25D",
      "QPQ082R25D",
      "865363-93-5",
      "2'-Deoxy-4'-C-Ethynyl-2-Fluoroadenosine",
      "(2R,3S,5R)-5-(6-Amino-2-Fluoro-Purin-9-Yl)-2-Ethynyl-2-(Hydroxymethyl)Tetrahydrofuran-3-Ol",
      "E2FdA",
      "2F-EdA",
      "E2-FdA",
      "AC1O5FTD",
      "4'Ed2FA",
      "SCHEMBL2528556",
      "CHEMBL517231",
      "4'-E-D2-FA",
      "IKKXOSBHLYMWAE-QRPMWFLTSA-N",
      "AKOS028113263",
      "4/'-Ethynyl-2-Fluoro-2/'-Deoxyadenosine",
      "FT-0701326",
      "Adenosine,2'-Deoxy-4'-C-Ethynyl-2-Fluoro-",
      "Adenosine, 2'-Deoxy-4'-C-Ethynyl-2-Fluoro-",
      "9H-Purin-6-Amine, 9-(2-Deoxy-4-C-Ethynyl-.",
    ],
  },
  {
    primary_id: "D0H1UW",
    names: ["LATIN T1D"],
  },
  {
    primary_id: "D0H1UY",
    names: [
      "RQ-00000010",
      "CJ-033466",
      "PF-01354082",
      "RQ-10",
      "5-HT 4 Receptor Partial Agonist (GERD), RaQualia",
      "5-HT4 Receptor Partial Agonist (Gastric Motility Disorder), Pfizer",
      "5-Hydroxytryptamine 4 Receptor Partial Agonist (Gastric Motility Disorder), Pfizer",
      "5-Hydroxytryptamine 4 Receptor Partial Agonist (Gastroesophageal Reflux), RaQualia",
    ],
  },
  {
    primary_id: "D0H1VB",
    names: [
      "5-Tert-Butyl-2-(4-Ethynyl-Phenyl)-[1,3]dithiane",
      "120076-03-1",
      "1,3-Dithiane, 5-(1,1-Dimethylethyl)-2-(4-Ethynylphenyl)-, Trans-",
      "ACMC-20mopv",
      "CHEMBL90399",
      "SCHEMBL8950548",
      "CTK0F9221",
      "DTXSID40625630",
      "HKLYPSSDHPAOJM-UHFFFAOYSA-N",
      "2-(4-Ethynylphenyl)-5-T-Butyl-1,3-Dithiane",
      "5-Tert-Butyl-2-(4-Ethynylphenyl)-1,3-Dithiane",
    ],
  },
  {
    primary_id: "D0H1VJ",
    names: [
      "DdCTP SODIUM",
      "CHEMBL486991",
      "132619-66-0",
      "2',3'-Dideoxycytidine 5'-Triphosphate Sodium Salt",
      "DTXSID60635554",
      "PUBCHEM_23679072",
      "FT-0643179",
      "J-006199",
      "2',3'-Dideoxycytidine 5'-Triphosphate Sodium Salt, &gt",
      "2',3'-Dideoxycytidine 5'-Triphosphate Sodium Salt Solution, For MALDI MS, &gt",
      "=98.0% (HPLC)",
      "2 Inverted Exclamation Marka,3 Inverted Exclamation Marka-Dideoxycytidine 5 Inverted Exclamation Marka-Triphosphate Sodium Salt Solution",
      "2 Inverted Exclamation Marka,3 Inverted Exclamation Marka-Dideoxycytidine 5",
    ],
  },
  {
    primary_id: "D0H1VT",
    names: ["Cis-(+/-)-2-Fluoro-1,2-Diphenylcyclopropylamine", "CHEMBL469024", "SCHEMBL16019175", "ZINC13559955"],
  },
  {
    primary_id: "D0H1VY",
    names: [
      "2,4,6-Trihydroxy-3-Nitro-N-Tridecyl-Benzamide",
      "CHEMBL175532",
      "Benzamide, 2,4,6-Trihydroxy-3-Nitro-N-Tridecyl-",
      "126395-25-3",
      "ACMC-20mryy",
      "RD34082",
      "AC1LA66U",
      "SCHEMBL12902000",
      "CTK0F6648",
      "DTXSID50333097",
      "BDBM50158817",
      "AKOS030576830",
      "RD-3-4082",
    ],
  },
  {
    primary_id: "D0H1WC",
    names: ["LY3023414"],
  },
  {
    primary_id: "D0H1WN",
    names: ["Indoline Derivative 8"],
  },
  {
    primary_id: "D0H1WR",
    names: ["SK&F-29661"],
  },
  {
    primary_id: "D0H1WV",
    names: ["Mulberrofuran W"],
  },
  {
    primary_id: "D0H1WY",
    names: ["Pegademase Bovine", "Adagen (TN)"],
  },
  {
    primary_id: "D0H1XL",
    names: ["Fc-IFN Alpha 2b"],
  },
  {
    primary_id: "D0H1YE",
    names: [
      "BE-22179",
      "BE 22179",
      "AC1L4UUG",
      "AC1Q6NP2",
      "SCHEMBL14221468",
      "FD-179",
      "N,n'-[(1r,7s,14r,20s)-2,12,15,25-Tetramethyl-11,24-Dimethylidene-3,6,10,13,16,19,23,26-Octaoxo-9,22,28,29-Tetrathia-2,5,12,15,18,25-Hexaazabicyclo[12.12.4]triacontane-7,20-Diyl]bis(3-Hydroxyquinoline-2-Carboxamide)",
      "3-Hydroxy-N-[(1R,7S,14R,20S)-7-[(3-Hydroxyquinoline-2-Carbonyl)Amino]-2,12,15,25-Tetramethyl-11,24-Dimethylidene-3,6,10,13,16,19,23,26-Octaoxo-9,22,28,29-Tetrathia-2,5,12,15,18,25-Hexazabicyclo[12.12.4]triacontan-20-Yl]quinoline-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0H1YK",
    names: ["NSC-56071"],
  },
  {
    primary_id: "D0H1YQ",
    names: [
      "Xatral",
      "Alfetim",
      "Alfoten",
      "Urion",
      "Uroxatral",
      "Alfuzosin HCl",
      "Alfuzosin Hydrochloride",
      "Alfuzosin Hydrochloride [USAN]",
      "Xatral OD",
      "Xatral Retard",
      "Xatral SR",
      "Xatral XL",
      "SL 77 499-10",
      "SL 77499-10",
      "SL-7749910",
      "Uroxatral, Alfuzosin Hydrochloride",
      "Alfuzosin Hydrochloride (JAN/USAN)",
      "SL-77499-10",
      "SL-77.0499-10",
      "N-[3-[4-Amino-6,7-Dimethoxy-2-Quinazolinyl)Methylamino]propyl]tetrahydro-2-Furancarboxamide Hydrochloride",
      "N-[3-[(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)-Methylamino]propyl]oxolane-2-Carboxamide Hydrochloride",
      "N-{3-[(4-Amino-6,7-Dimethoxychinazolin-2-Yl)(Methyl)Amino]propyl}tetrahydrofuran-2-Carboxamidhydrochlorid",
      "(+-)-N-(3-((4-Amino-6,7-Dimethoxy-2-Quinazolinyl)Methylamino)Propyl)Tetrahydro-2-Furamide Monohydrochloride",
    ],
  },
  {
    primary_id: "D0H1YZ",
    names: ["LymPro"],
  },
  {
    primary_id: "D0H1ZT",
    names: ["KNI-10216"],
  },
  {
    primary_id: "D0H2AM",
    names: ["XR-3054"],
  },
  {
    primary_id: "D0H2AP",
    names: ["EDG-004"],
  },
  {
    primary_id: "D0H2BL",
    names: [
      "7-Benzyloxy-2-(Morpholin-4-Yl)-Chromen-4-One",
      "CHEMBL95298",
      "SCHEMBL3536307",
      "BDBM50178028",
      "7-Benzyloxy-2-Morpholin-4-Yl-Chromen-4-One",
    ],
  },
  {
    primary_id: "D0H2BU",
    names: ["PMID25435285-Compound-53"],
  },
  {
    primary_id: "D0H2CF",
    names: ["Anti-MUC1 Mab"],
  },
  {
    primary_id: "D0H2CI",
    names: ["Salvinorin B Methoxymethyl Ether", "CHEMBL258098"],
  },
  {
    primary_id: "D0H2CM",
    names: ["PP-242"],
  },
  {
    primary_id: "D0H2CS",
    names: ["Glycinamid"],
  },
  {
    primary_id: "D0H2CV",
    names: ["SDZ-SCV-106", "Colorectal Cancer Vaccine, Novartis", "Vaccine (Colorectal Cancer), Novartis"],
  },
  {
    primary_id: "D0H2DD",
    names: [
      "4-(4-Bromophenyl)-5-Cyano-2H-1,2,3-Triazole",
      "4-(4-BROMOPHENYL)-1H-1,2,3-TRIAZOLE-5-CARBONITRILE",
      "CHEMBL238828",
      "1119392-12-9",
      "AC1O4QZZ",
      "5-(4-Bromophenyl)-2H-Triazole-4-Carbonitrile",
      "SCHEMBL4105736",
      "CTK7C5822",
      "MolPort-006-755-219",
      "ZINC6448351",
      "BDBM50200765",
      "AKOS022169659",
      "MS-8537",
      "KS-000029L0",
      "4-(4-Bromophenyl)-1H-1,2,3-Triazole-5-Carbonitrile, AldrichCPR",
    ],
  },
  {
    primary_id: "D0H2DL",
    names: ["PMID26560530-Compound-50"],
  },
  {
    primary_id: "D0H2DN",
    names: ["DE-310"],
  },
  {
    primary_id: "D0H2DQ",
    names: [
      "Probucol",
      "Biphenabid",
      "Bisbid",
      "Bisphenabid",
      "Lesterol",
      "Lorelco",
      "Lursell",
      "Lurselle",
      "Panavir",
      "Probucolum",
      "Sinlestal",
      "Superlipid",
      "Almirall Brand Of Probucol",
      "Aventis Brand Of Probucol",
      "Hoechst Brand Of Probucol",
      "DH 581",
      "DH581",
      "DE-3872",
      "DH-581",
      "LORELCO (TN)",
      "Probucolum [INN-Latin]",
      "ZERO/001429",
      "Probucol (JAN/USP/INN)",
      "Probucol [USAN:BAN:INN:JAN]",
      "Acetone Bis(3,5-Di-Tert-Butyl-4-Hydroxyphenyl) Mercaptole",
      "Acetone, Bis (3,5-Di-Tert-Butyl-4-Hydroxyphenyl) Mercaptole",
      "Acetone, Bis(3,5-Di-Tert-Butyl-4-Hydroxyphenyl) Mercaptole",
      "2,6-Ditert-Butyl-4-[2-(3,5-Ditert-Butyl-4-Hydroxyphenyl)Sulfanylpropan-2-Ylsulfanyl]phenol",
      "4,4'-(Isopropylidenedithio)Bis(2, 6-Di-Tert-Butylphenol)",
      "4,4'-(Isopropylidenedithio)Bis(2,6-Di-Tert-Butylphenol)",
      "4,4'-(Isopropylidenedithio)Bis[2, 6-Di-Tert-Butylphenol]",
      "4,4'-(Isopropylidenedithio)Bis[2,6-Di-Tert-Butylphenol]",
      "4,4'-(Propane-2,2-Diyldisulfanediyl)Bis(2,6-Di-Tert-Butylphenol)",
      "4,4'-[(1-Methylethylidene)Bis(Thio)]bis-[2,6-Bis(1,1-Dimethylethyl)Phenol]",
    ],
  },
  {
    primary_id: "D0H2DS",
    names: ["PTH(7-34) Liposomal Cream"],
  },
  {
    primary_id: "D0H2EM",
    names: [
      "HIV-1 Vaccine",
      "ChAdV63.HIVconsv",
      "MVA.HIVconsv",
      "PSG2.HIVconsv",
      "HIV-1 Vaccine, University Of Oxford",
      "Universal HIV-1 Vaccine, Universityof Oxford",
    ],
  },
  {
    primary_id: "D0H2FI",
    names: [
      "6-(4-Hydroxy-Phenyl)-1-Nitro-Naphthalen-2-Ol",
      "CHEMBL195788",
      "SCHEMBL3935673",
      "MYVOVZDBAWARDP-UHFFFAOYSA-N",
      "ZINC13645031",
      "BDBM50168340",
    ],
  },
  {
    primary_id: "D0H2GH",
    names: [
      "3-(3,4-Dimethyl-Phenyl)-1-Propyl-Piperidine",
      "CHEMBL72630",
      "CTK8F4622",
      "DTXSID60441778",
      "BDBM50068422",
      "1-Propyl-3-(3,4-Dimethylphenyl)Piperidine",
    ],
  },
  {
    primary_id: "D0H2GT",
    names: ["Pyrrole Derivative 3"],
  },
  {
    primary_id: "D0H2GU",
    names: ["Cis-10"],
  },
  {
    primary_id: "D0H2HC",
    names: ["(R/S) EF-1500", "GTPL4583"],
  },
  {
    primary_id: "D0H2HZ",
    names: [
      "GSK2646264",
      "CQLSAYKHANAIMX-UHFFFAOYSA-N",
      "GTPL10108",
      "Compound 44 [PMID: 30249354]",
      "7-{2-Methoxy-6-[(4-Methylpyridin-2-Yl)Methoxy]phenyl}-2,3,4,5,5a,9a-Hexahydro-1H-3-Benzazepine",
    ],
  },
  {
    primary_id: "D0H2IA",
    names: ["CGP 56999A", "CGP56999A", "CGP-56999A"],
  },
  {
    primary_id: "D0H2IO",
    names: ["P32/98"],
  },
  {
    primary_id: "D0H2IQ",
    names: ["BL-7010"],
  },
  {
    primary_id: "D0H2IT",
    names: ["PD-172939", "CHEMBL35093", "ZINC4632541", "BDBM50070518", "(R)-PD-172939"],
  },
  {
    primary_id: "D0H2JD",
    names: ["Gemcitabine Prodrug"],
  },
  {
    primary_id: "D0H2JP",
    names: [
      "Buprenorphine + Naloxone",
      "Buprenorphine/Naloxone",
      "[5?,7?(S)]-17-(Cyclopropylmethyl)-?-(1,1-Dimethylethyl)-4,5-Epoxy-18,19-Dihydro-3-Hydroxy-6-Methoxy-?-Methyl-6,14-Ethenomorphinan-7-Methanol",
      "SCHEMBL18085614",
      "Buprenorphine 10 Mg/Ml In Methanol",
      "Buprenorphine 01 Mg/Ml In Methanol",
      "ALKS5461",
    ],
  },
  {
    primary_id: "D0H2JQ",
    names: ["NB-506"],
  },
  {
    primary_id: "D0H2JS",
    names: ["Tin-117m-Labeled Annexin"],
  },
  {
    primary_id: "D0H2JX",
    names: ["Norbinaltorphimine Derivative"],
  },
  {
    primary_id: "D0H2KD",
    names: [
      "(1H-Indol-2-Yl)(Piperazin-1-Yl)Methanone",
      "2-(Piperazin-1-Ylcarbonyl)-1H-Indole",
      "136818-95-6",
      "CHEMBL129660",
      "1H-Indol-2-Yl-1-Piperazinyl-Methanone",
      "1H-Indol-2-Yl(Piperazin-1-Yl)Methanone",
      "2-(Piperazine-1-Carbonyl)-1H-Indole",
      "(1H-Indol-2-Yl)-Piperazin-1-Yl-Methanone",
      "Piperazoylindole",
      "AC1LJDZ7",
      "Oprea1_711133",
      "SCHEMBL2679610",
      "DTXSID30358889",
      "MolPort-002-566-226",
      "FIVCRRVYLAXYFC-UHFFFAOYSA-N",
      "ZINC509902",
      "ALBB-016237",
      "STL484224",
      "BDBM50133002",
      "4012AD",
      "AKOS000210389",
      "MCULE-2536499366",
      "NS-02944",
      "Piperazine, 1-(1H-Indol-2-Ylcarbonyl)-",
    ],
  },
  {
    primary_id: "D0H2KE",
    names: ["LN-040", "Autologous Cell Vaccine (Leukemia), LipoNova"],
  },
  {
    primary_id: "D0H2KL",
    names: ["PMID29338548-Compound-13"],
  },
  {
    primary_id: "D0H2KV",
    names: ["ALS 2-0426"],
  },
  {
    primary_id: "D0H2LC",
    names: ["Anti-CD30 CAR-T Cells"],
  },
  {
    primary_id: "D0H2LD",
    names: ["PMID25435285-Compound-43"],
  },
  {
    primary_id: "D0H2LE",
    names: ["Gene Therapy, Huntington's Disease, Medtronic"],
  },
  {
    primary_id: "D0H2LG",
    names: ["ISIS 25128"],
  },
  {
    primary_id: "D0H2LJ",
    names: ["TDI-0105"],
  },
  {
    primary_id: "D0H2LN",
    names: ["ROMe", "(R)-FTY720-OMe"],
  },
  {
    primary_id: "D0H2LT",
    names: [
      "4-Amino-3-(2-Chloro-Phenyl)-Butyric Acid",
      "CHEMBL83245",
      "28311-34-4",
      "SCHEMBL349398",
      "BDBM50007121",
      "AKOS015157811",
      "4-Amino-3-(2-Chlorophenyl)Butyric Acid",
      "4-Amino-3-(2-Chlorophenyl)Butanoic Acid",
      "4-Amino-3-(-Chlorophenyl)-Butanoic Acid",
      "Benzenepropanoic Acid, Beta-(Aminomethyl)-2-Chloro-",
    ],
  },
  {
    primary_id: "D0H2MA",
    names: ["Brilliant Blue G"],
  },
  {
    primary_id: "D0H2MF",
    names: ["PF-02367982", "CHEMBL1083754", "BDBM50318934"],
  },
  {
    primary_id: "D0H2MM",
    names: ["INfluenza H3N2 Vaccine"],
  },
  {
    primary_id: "D0H2MO",
    names: ["Colforsin Daropate Hci", "Adehl (TN)", "Adele (TN)"],
  },
  {
    primary_id: "D0H2NH",
    names: [
      "BRIFENTANIL",
      "Brifentanil < Rec INN",
      "A-3331 (Monohydrochloride)",
      "Rac-Cis-1-[2-(4-Ethyl-4,5-Dihydro-5-Oxo-1H-Tetrazol-1-Yl)Ethyl]-3-Methyl-4-[N-(2-Fluorophenyl)Methoxyacetamido]piperidine",
      "Rac-Cis-N-[1-[2-(4-Ethyl-5-Oxo-2-Tetrazolin-1-Yl)Ethyl]-3-Methyl-4-Piperidyl]-2'-Fluoro-2-Methoxyacetanilide",
    ],
  },
  {
    primary_id: "D0H2NI",
    names: ["YM-543", "ASP-543", "SGLT-2 Inhibitor (Oral, Diabetes), Astellas/Kotobuki"],
  },
  {
    primary_id: "D0H2NT",
    names: ["MK-056"],
  },
  {
    primary_id: "D0H2OA",
    names: ["Alpha-Monofluoromethyl-3,4-Dehydroornithine Ethyl Ester"],
  },
  {
    primary_id: "D0H2PA",
    names: ["PKF-242-484"],
  },
  {
    primary_id: "D0H2PE",
    names: [
      "Phenyl-(3-Phenyl-1H-Indazol-6-Yl)-Amine",
      "CHEMBL383177",
      "N,3-Diphenyl-1H-Indazol-6-Amine",
      "SCHEMBL6582340",
    ],
  },
  {
    primary_id: "D0H2QL",
    names: ["WRSs2"],
  },
  {
    primary_id: "D0H2QO",
    names: ["Tetrahydrofolic Acid"],
  },
  {
    primary_id: "D0H2RB",
    names: ["NAFAZATROM"],
  },
  {
    primary_id: "D0H2RD",
    names: ["(+/-)-Threo-N-(4-Phenylbutyl)Ritalinol", "CHEMBL1254281"],
  },
  {
    primary_id: "D0H2RF",
    names: ["Tyr-Pro-Imp-Phe-NH2", "CHEMBL434559"],
  },
  {
    primary_id: "D0H2RG",
    names: ["Phenylpurine Acetamide Analog 2"],
  },
  {
    primary_id: "D0H2RI",
    names: ["Fludeoxyglucose F 18"],
  },
  {
    primary_id: "D0H2RJ",
    names: [
      "Clazosentan",
      "180384-56-9",
      "Ro-61-1790",
      "VML-588",
      "UNII-3DRR0X4728",
      "AXV-343434",
      "CHEMBL109648",
      "3DRR0X4728",
      "Clazosentan [INN]",
      "VML 588",
      "AXV 034",
      "AXV-034343",
      "Ro 61-1790",
      "AXV-034",
      "AXV 034343",
      "AC1O5FK3",
      "SCHEMBL1652657",
      "CTK4D7597",
      "DTXSID60170955",
      "ZINC3939238",
      "BDBM50066370",
      "AKOS032946377",
      "SB18855",
      "N-[6-(2-HYDROXYETHOXY)-5-(2-METHOXYPHENOXY)-2-[2-(2H-TETRAZOL-5-YL)-4-PYRIDINYL]-4-PYRIMIDINYL]-5-METHYL-2-PYRIDINESULFONAMIDE",
      "DB-065318",
      "FT-0765561",
    ],
  },
  {
    primary_id: "D0H2RZ",
    names: [
      "Heptanoic Acid",
      "2(3H)-Furanone, Dihydro-5,5-Dimethyl-4-(3-Oxobutyl)-",
      "4436-81-1",
      "NSC45645",
      "4,5-Dihydro-5,5-Dimethyl-4-(3-Oxobutyl)Furan-2(3H)-One",
      "16091-70-6",
      "HEPTANOIC ACID,",
      "HEPTANOIC ACID LACTONE DERIV",
      "AC1L3CDV",
      "AC1Q1K5S",
      "SCHEMBL7128855",
      "Heptanoic Acid Lactone Derivative",
      "AWQSAIIDOMEEOD-UHFFFAOYSA-N",
      "NSC36847",
      "NSC140633",
      "NSC-36847",
      "NSC140621",
      "NSC127945",
      "NSC-45645",
      "AKOS006346009",
      "NSC-127945",
      "MCULE-6610625549",
      "NSC-140621",
      "NSC-140633",
      "5,5-Dimethyl-4-(3-Oxobutyl)Oxolan-2-One",
      "C06066",
      "2(3H)-Furanone,5-Dimethyl-4-(3-Oxobutyl)-",
    ],
  },
  {
    primary_id: "D0H2SE",
    names: ["Benzamide Derivative 8"],
  },
  {
    primary_id: "D0H2SL",
    names: [
      "2-(4-Bromo-2-Tert-Butylphenoxy)Acetic Acid",
      "2-[4-BROMO-2-(TERT-BUTYL)PHENOXY]ACETIC ACID",
      "425372-86-7",
      "(4-Bromo-2-Tert-Butylphenoxy)Acetic Acid",
      "CHEMBL386348",
      "2-(4-Bromo-2-(Tert-Butyl)Phenoxy)Acetic Acid",
      "2-[2-(Tert-Butyl)-4-Bromophenoxy]acetic Acid",
      "CBMicro_046462",
      "AC1LEXUE",
      "Oprea1_645612",
      "Oprea1_576852",
      "ARONIS011292",
      "SCHEMBL12478128",
      "CTK7J5471",
      "BBB/316",
      "MolPort-000-651-933",
      "GCBNGPDJYUPSBS-UHFFFAOYSA-N",
      "ZINC120478",
      "KS-000042GS",
      "ZX-AS003555",
      "ZX-AL002245",
      "SBB043460",
      "STK948100",
      "BDBM50200269",
      "3158AE",
      "AKOS000273807",
      "MCULE-9342897788",
    ],
  },
  {
    primary_id: "D0H2SY",
    names: [
      "Oxybuprocaine",
      "Benoxil",
      "Benoxinate",
      "Butoxyaminobenzoyldiethylaminoethanol",
      "Butoxyprocaine",
      "Conjucain",
      "Dorsacain",
      "Novescine",
      "Novesinol",
      "Oxibuprocaina",
      "Oxibuprocainum",
      "Oxibuprokain",
      "Oxybucaine",
      "Oxybuprocainum",
      "Oxyriprocaine",
      "Prescaina",
      "Benoxinate Hydrochloride",
      "Monofree Oxybuprocaine",
      "Oxybuprocaine HCL",
      "Oxybuprocaine Hydrochloride",
      "S 749;Monofree Oxybuprocaine (TN)",
      "Novesin (TN)",
      "Novesine (TN)",
      "Oxibuprocaina [INN-Spanish]",
      "Oxybuprocaine (INN)",
      "Oxybuprocaine [INN:BAN]",
      "Oxybuprocainum [INN-Latin]",
      "Diethylaminoethyl-4-Amino-3-Butoxybenzoate",
      "BENZOIC ACID, 4-AMINO-3-BUTOXY-, 2-(DIETHYLAMINO)ETHYL ESTER",
      "Benzoic Acid, 4-Amino-3-Butoxy-, 2-(Diethylamino)Ethyl Ester (7CI,8CI,9CI)",
      "2-(Diethylamino)Ethyl 4-Amino-3-Butoxybenzoate",
      "2-(Diethylamino)Ethyl 4-Amino-3-N-Butoxybenzoate",
      "2-Diethylaminoethyl 4-Amino-3-Butoxybenzoate",
      "3-Butoxy-4-Aminobenzoic Acid 2-(Diethylamino)Ethyl Ester",
      "3-Butoxy-4-Aminobenzoic Acid 2-Diethylaminoethyl Ester",
      "4-Amino-3-Butoxy-2-(Diethylamino)Ethyl Ester Benzoic Acid",
      "4-Amino-3-Butoxy-Benzoic Acid 2-Diethylamino-Ethyl Ester",
      "4-Amino-3-Butoxybenzoic Acid 2-(Diethylamino)Ethyl Ester",
      "4-Amino-3-Butoxybenzoicacid 2-Diethylaminoethyl Ester",
      "4-Amino-3-N-Butoxy-Benzoesaeure-Diaethylaminoaethylester",
      "4-Amino-3-N-Butoxy-Benzoesaeure-Diaethylaminoaethylester [German]",
    ],
  },
  {
    primary_id: "D0H2TE",
    names: ["Pseudoephedrine, Nor"],
  },
  {
    primary_id: "D0H2TS",
    names: ["4-(2-((3-Fluorophenoxy)Methyl)Phenyl)Piperidine", "CHEMBL573766"],
  },
  {
    primary_id: "D0H2TZ",
    names: ["Phosphomethylphosphonic Acid Guanylate Ester"],
  },
  {
    primary_id: "D0H2UB",
    names: [
      "Suramin",
      "Antrypol",
      "Belganyl",
      "Farma",
      "Fourneau",
      "Germanin",
      "Moranyl",
      "Naganil",
      "Naganin",
      "Naganine",
      "Naganol",
      "Naphuride",
      "SVR",
      "Suramine",
      "SURAMIN SODIUM",
      "Sodium Suramin",
      "Suramine Sodium",
      "Farma 939",
      "Naganol 6 Na",
      "Germanin (TN)",
      "SGCTO-001",
      "Harnstoff, Symmetischer Der 3-Aminobenzoyl-3-Amino-4-Methylbenzoyl-1-Naphthylamin-4,6,8-Trisulfonsaeure",
      "Sym-Bis(M-Aminobenzoyl-M-Amino-P-Methylbenzoyl-1-Napthylamino-4,6,8-Trisulfonate) Carbamide",
      "M,m'-Ureylen-Bis-(8-(M-(Benzamido)-P-Toluamido)Naphthalin-1,3,5-Trisulfonsaeure)",
      "1,3,5-Naphthalenetrisulfonic Acid, 8,8'-[carbonylbis[imino-3,1-Phenylenecarbonylimino(4-Methyl-3,1-Phenylene)Carbonylimino]]bis-(9CI)",
      "1,3,5-Naphthalenetrisulfonic Acid, 8,8'-[carbonylbis[imino-3,1-Phenylenecarbonylimino(4-Methyl-3,1-Phenylene)Carbonylimino]]bis-, Hexasodium Salt",
      "1,3,5-Naphthalenetrisulfonic Acid, 8,8'-[ureylenebis[m-Phenylenecarbonylimino(4-Methyl-M-Phenylene)Carbonylimino]]di-(8CI)",
      "1,3,5-Naphthalenetrisulfonic Acid, 8,8'-[ureylenebis[m-Phenylenecarbonylimino(4-Methyl-M-Phenylene)Carbonylimino]]di-, Hexasodium Salt",
      "8,8'-(Carbonylbis(Imino-3,1-Phenylenecarbonylimino(4-Methyl-3,1-Phenylene)Carbonylimino))Bisnaphthalene-1,3,5-Trisulphonic Acid",
      "8,8'-[CARBONYLBIS[IMINO-3,1-PHENYLENECARBONYLIMINO(4-METHYL-3,1-PHENYLENE)CARBONYLIMINO]]BIS-1,3,5-NAPHTHALENETRISULFONIC",
      "8,8'-[Carbonylbis[imino-3,1-Phenylenecarbonylimino(4-Methyl-3,1-Phenylene)Carbonyl-Imino]]bis-1,3,5-Naphthalenetrisulfonic Acid",
      "8,8'-[Carbonylbis[imino-3,1-Phenylenecarbonylimino(4-Methyl-3,1-Phenylene)Carbonyl-Imino]]bis-1,3,5-Napthalenetrisulfonic Acid",
      "8,8'-[Ureylenebis[m-Phenylenecarbonylimino(4-Methyl-M-Phenylene)Carbonylimino]]di-1,3,5-Naphthalenetrisulfonic Acid",
      "8,8'-[carbonylbis[imino-3,1-Phenylenecarbonylimino(4-Methyl-3,1-Phenylene)Carbonylimino]]bisnaphthalene-1,3,5-Trisulphonic Acid",
      "8-[[4-Methyl-3-[[3-[[3-[[2-Methyl-5-[(4,6,8-Trisulfonaphthalen-1-Yl)Carbamoyl]phenyl]carbamoyl]phenyl]carbamoylamino]benzoyl]amino]benzoyl]amino]naphthalene-1,3,5-Trisulfonic Acid",
    ],
  },
  {
    primary_id: "D0H2UG",
    names: ["N-N-Nonyl-7-Methoxytacrine Hydrochloride", "CHEMBL1257644"],
  },
  {
    primary_id: "D0H2UJ",
    names: ["Ampicidin Analog 1"],
  },
  {
    primary_id: "D0H2UM",
    names: ["2-(5-Bromo-Indole-1-Sulfonyl)-Benzoic Acid", "CHEMBL184005"],
  },
  {
    primary_id: "D0H2UN",
    names: ["SC-108"],
  },
  {
    primary_id: "D0H2UT",
    names: ["Brucine", "10,11-Dimethoxy Strychnine"],
  },
  {
    primary_id: "D0H2UU",
    names: ["Entecavir+lamivudine+adefovir"],
  },
  {
    primary_id: "D0H2VA",
    names: ["LUF-5853"],
  },
  {
    primary_id: "D0H2VN",
    names: ["GRL-11097"],
  },
  {
    primary_id: "D0H2VW",
    names: [
      "N,4-Dibenzhydrylpiperazine-1-Carboxamide",
      "CHEMBL599217",
      "381248-72-2",
      "SCHEMBL3396540",
      "CTK1A9093",
      "DTXSID50435823",
      "PPVBMJIAJXVQFX-UHFFFAOYSA-N",
      "AKOS029837741",
      "1-Piperazinecarboxamide, N,4-Bis(Diphenylmethyl)-",
      "4-Benzhydryl-Piperazine-1-Carboxylic Acid Benzhydryl Amide",
      "1-[(Diphenylmethyl)Aminocarbonyl]-4-(Diphenylmethyl) Piperazine",
    ],
  },
  {
    primary_id: "D0H2VY",
    names: [
      "8-Benzyl-9H-Purine-2,6-Diamine",
      "CHEMBL102303",
      "9H-Purine-2,6-Diamine, 8-(Phenylmethyl)-",
      "AC1L9X4P",
      "SCHEMBL3923325",
      "8-Benzyl-7H-Purine-2,6-Diamine",
      "BDBM50059941",
    ],
  },
  {
    primary_id: "D0H2WQ",
    names: ["QUINPIROLE"],
  },
  {
    primary_id: "D0H2WY",
    names: ["Pyrazine Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0H2XJ",
    names: [
      "Alpha-Emtbl",
      "3-Ethyl-3-Methylthiolan-2-One",
      "103620-92-4",
      "Alpha-Ethyl-Alpha-Methyl-Thiobutyrolactone",
      "AC1L2TRI",
      "CHEBI:35058",
      "C13720",
      "2(3H)-Thiophenone, 3-Ethyldihydro-3-Methyl-",
      "CHEMBL34197",
      "GTPL2363",
    ],
  },
  {
    primary_id: "D0H2YM",
    names: ["27-Hydroxycholesterol", "27-OHC", "26-Hydroxycholesterol"],
  },
  {
    primary_id: "D0H2YT",
    names: ["Inosinic Acid"],
  },
  {
    primary_id: "D0H2YX",
    names: ["Unoprostone Isopropyl Ester"],
  },
  {
    primary_id: "D0H2YY",
    names: ["JX-929"],
  },
  {
    primary_id: "D0H2ZN",
    names: ["ZP-005"],
  },
  {
    primary_id: "D0H2ZQ",
    names: ["S-18326", "Acetyl-D-Phenylalanyl-N-(Cyclopentyl)-Glycyl-L-1-Boroarginine"],
  },
  {
    primary_id: "D0H2ZW",
    names: ["Xipamide"],
  },
  {
    primary_id: "D0H3AB",
    names: ["Incobotulinum Toxin A"],
  },
  {
    primary_id: "D0H3BC",
    names: ["3-[5-(Benzylmethylamino)Pentyloxy]xanthen-9-One", "CHEMBL427249"],
  },
  {
    primary_id: "D0H3CQ",
    names: ["[3H]nisoxetine", "[3H]-Nisoxetine"],
  },
  {
    primary_id: "D0H3CV",
    names: ["Ro-48-8724"],
  },
  {
    primary_id: "D0H3CW",
    names: ["SK&F-105854"],
  },
  {
    primary_id: "D0H3DT",
    names: ["(3-(2-Methylquinolin-7-Yl)Phenyl)Methanol", "CHEMBL231986"],
  },
  {
    primary_id: "D0H3DY",
    names: [
      "VUF-5297",
      "CHEMBL13795",
      "SCHEMBL7508989",
      "BDBM50127605",
      "AKOS006351626",
      "(1S,2S)-2-(3H-Imidazol-4-Yl)-Cyclopropylamine",
    ],
  },
  {
    primary_id: "D0H3EJ",
    names: [
      "Carbocyclic Peptidomimetic",
      "CHEMBL434943",
      "OM99-2 Cyclopentano Analog 3",
      "BDBM16049",
      "N-{[(1R,2R)-2-{(1S,2S)-2-[(L-Alpha-Glutamyl-L-Valyl-L-Asparaginyl)Amino]-1-Hydroxy-4-Methylpentyl}cyclopentyl]carbonyl}-L-Alanyl-L-Alpha-Glutamyl-L-Phenylalanine",
      "(4S)-4-[(2S)-2-{[(1R,2R)-2-[(1S,2S)-2-[(2S)-2-[(2S)-2-[(4S)-4-Amino-4-Formamidobutanoic Acid]-3-Methylbutanamido]butanediamido]-1-Hydroxy-4-Methylpentyl]cyclopentyl]formamido}propanamido]-4-{[(1S)-1-Carboxy-2-Phenylethyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D0H3EV",
    names: [
      "9-Nitropaullone",
      "Alsterpaullone",
      "237430-03-4",
      "NSC 705701",
      "NSC-705701",
      "CHEMBL50894",
      "MLS002702475",
      "9-Nitro-7,12-Dihydroindolo[3,2-D][1]benzazepin-6(5H)-One",
      "9-Nitro-7,12-Dihydro-5H-Indolo[3,2-D][1]benzazepin-6-One",
      "9-Nitro-7,12-Dihydroindolo-[3,2-D][1]benzazepin-6(5)-One",
      "9-NITRO-5,12-DIHYDRO-7H-BENZO[2,3]AZEPINO[4,5-B]INDOL-6-ONE",
      "Paullone Analog 1",
      "AC1NMCUD",
      "Kinome_3754",
      "1q3w",
      "Alsterpaullone Derivative, 2",
      "Lopac0_000057",
      "CBiol_001723",
      "GTPL5925",
      "BDBM7262",
      "SCHEMBL2170104",
      "CTK8F0374",
      "ZINC23894",
      "BDBM84528",
      "DTXSID50407444",
    ],
  },
  {
    primary_id: "D0H3FA",
    names: ["BB-101", "Analgesic (Acute/Chronic Pain), Balboa"],
  },
  {
    primary_id: "D0H3FH",
    names: ["MNI-135"],
  },
  {
    primary_id: "D0H3FX",
    names: ["TGF Alpha"],
  },
  {
    primary_id: "D0H3GI",
    names: ["4-(P-Tolyl)Spiro[chromene-2,4'-Piperidine]", "CHEMBL492448", "SCHEMBL471328", "BDBM50252831"],
  },
  {
    primary_id: "D0H3GK",
    names: ["Autologous Cell Based Gene Therapy", "Autologous Cell Based Gene Therapy (Pulmonary Hypertension)"],
  },
  {
    primary_id: "D0H3HB",
    names: [
      "EG-011",
      "Ad-VEGF D",
      "Trinam-Variants (Refractory Angina), Ark Therpeutics Group",
      "Adenoviral Short-Form VEGF D Gene Therapy (Angina/Peripheral Vascular Disease), Ark Therapeutics",
      "Short Form VEGF-D Gene Therapy (Refractory Angina/Peripheral Vascular Disease), Ark Therapeutics Group",
    ],
  },
  {
    primary_id: "D0H3HM",
    names: [
      "Aripiprazole",
      "129722-12-9",
      "Abilify",
      "Abilitat",
      "Abilify Discmelt",
      "OPC-14597",
      "Discmelt",
      "Opc 14597",
      "OPC 31",
      "OPC-31",
      "7-[4-[4-(2,3-Dichlorophenyl)-1-Piperazinyl]butoxy]-3,4-Dihydrocarbostyril",
      "UNII-82VFR53I78",
      "C23H27Cl2N3O2",
      "HSDB 7320",
      "7-(4-(4-(2,3-DICHLOROPHENYL)PIPERAZIN-1-YL)BUTOXY)-3,4-DIHYDROQUINOLIN-2(1H)-ONE",
      "CHEMBL1112",
      "7-{4-[4-(2,3-Dichlorophenyl)Piperazin-1-Yl]butoxy}-1,2,3,4-Tetrahydroquinolin-2-One",
      "7-(4-(4-(2,3-Dichlorophenyl)-1-Piperazinyl)Butyloxy)-3,4-Dihydro-2(1H)-Quinolinone",
      "CHEBI:31236",
      "Aripiprazol",
      "Aripiprazolum",
      "Aripirazole",
      "Arpizol",
      "Asprito",
      "Pripiprazole",
      "Aripiprazole [USAN]",
      "Otsuka Brand Of Aripiprazole",
      "OPC 14597",
      "ALKS-9070",
      "Abilify (TN)",
      "BMS-337039",
      "Bristol-Myers Squibb Brand Of Aripiprazole",
      "Discmelt (TN)",
      "KS-1030",
      "S06-0010",
      "Aripiprazole (JAN/USAN/INN)",
      "7-(4-(4-(2,3-Dichlorophenyl)-1-Piperazinyl)Butoxy)-3,4-Dihydro-2(1H)-Quinolinone",
      "7-(4-(4-(2,3-Dichlorophenyl)-1-Piperazinyl)Butoxy)-3,4-Dihydrocarbostyril",
      "7-[4-[4-(2,3-Dichlorophenyl)Piperazin-1-Yl]butoxy]-3,4-Dihydro-1H-Quinolin-2-One",
      "7-{4-[4-(2,3-Dichlorophenyl)Piperazin-1-Yl]butoxy}-3,4-Dihydroquinolin-2(1H)-One",
      "ALKS9070/ALKS9072",
      "Aripiprazole/Escitalopram Fixed-Dose Combination",
    ],
  },
  {
    primary_id: "D0H3HV",
    names: [
      "AP-1030",
      "AP-1102",
      "AP-1130",
      "MS-05",
      "Combined MC1/MC4 Receptor Agonists, Action Pharma",
      "Melanocortin-1/-4 Receptor Agonists, ActionPharma",
      "Melanocortin-1/Melanocortin-4 Receptor Agonists, Action Pharma",
    ],
  },
  {
    primary_id: "D0H3ID",
    names: ["Pfizer 10"],
  },
  {
    primary_id: "D0H3IJ",
    names: ["Fructose-6-Phosphate"],
  },
  {
    primary_id: "D0H3IS",
    names: ["SNEWIQPRLPQH", "CHEMBL448652"],
  },
  {
    primary_id: "D0H3JC",
    names: ["Ragweed Allergy Immunotherapy Vaccine"],
  },
  {
    primary_id: "D0H3JF",
    names: ["VTX-2337"],
  },
  {
    primary_id: "D0H3JH",
    names: [
      "CGI-1316",
      "CGI-1746",
      "CGI-2233",
      "CGI-2815",
      "CGI-3130",
      "CGI-560",
      "CGI-676",
      "Btk Inhibitors (Autoimmune Disease/Inflammatory Disease)",
      "Btk Inhibitors (Autoimmune Disease/Inflammatory Disease), CGI Pharmaceuticals",
      "Btk Inhibitors (Autoimmune Disease/Inflammatory Disease), Cellular Genomics",
      "Btk Inhibitors (Autoimmune Disease/Inflammatory Disease), Gilead",
    ],
  },
  {
    primary_id: "D0H3JR",
    names: ["MKC-121"],
  },
  {
    primary_id: "D0H3KF",
    names: ["Bipyrazole Derivative 1"],
  },
  {
    primary_id: "D0H3KI",
    names: [
      "2-Deoxyglucose",
      "154-17-6",
      "Deoxyglucose",
      "2-Deoxy-D-Mannose",
      "2-Deoxy-D-Arabinohexose",
      "UNII-9G2MP84A8W",
      "D-Arabino-Hexose, 2-Deoxy-",
      "HSDB 5484",
      "Arabino-Hexose, 2-Deoxy-",
      "D-Glucose, 2-Deoxy-",
      "9G2MP84A8W",
      "(3R,4S,5R)-3,4,5,6-Tetrahydroxyhexanal",
      "AK-44445",
      "2 Deoxyglucose",
      "2 Deoxy D Glucose",
      "2 Desoxy D Glucose",
      "D-Arabino-2-Desoxyhexose",
      "D-2-Glucodesose",
      "D-2dGlc",
      "Deoxy-D-Glucose, 2-",
      "2-DEOXYLGLUCOSE",
      "2-INNo-D-AEIIC",
      "SCHEMBL7670",
      "AC1L33KH",
      "KSC175S5P",
      "4-01-00-04282 (Beilstein Handbook Reference)",
      "Jsp003004",
      "CHEMBL2074932",
      "CTK0H5957",
      "MolPort-002-317-302",
      "2-Deoxy-D-Glucose",
    ],
  },
  {
    primary_id: "D0H3KU",
    names: [
      "5-OH-DPAT",
      "5-OH-Dpat",
      "CHEMBL273273",
      "68593-96-4",
      "6-(Dipropylamino)-5,6,7,8-Tetrahydronaphthalen-1-Ol",
      "6-Dipropylaminotetralin-1-Ol",
      "5-Hydroxy-2-N,N-Dipropylaminotetralin",
      "5-Hydroxy-2-(Di-N-Propylamino)Tetralin",
      "(-)-5-Hydroxy-2-(Dipropylamino)Tetralin",
      "6-Dipropylamino-5,6,7,8-Tetrahydro-Naphthalen-1-Ol",
      "GTPL128",
      "SCHEMBL279021",
      "AC1L58V4",
      "2-Dipropylamino-5-Hydroxy-1,2,3,4-Tetrahydronaphthalene",
      "5-Hydroxy-2-(Dipropylamino)Tetralin",
      "BDBM50020221",
      "5-Hydroxy-N,N-Dipropyl-2-Aminotetralin",
      "L000957",
      "1-Naphthalenol,",
    ],
  },
  {
    primary_id: "D0H3LE",
    names: ["7-Methyl-2-Phenylpyrido[2,3-D]pyrimidine", "CHEMBL394304"],
  },
  {
    primary_id: "D0H3LF",
    names: ["2-(4-Isopropyl-Piperazin-1-Yl)-Quinoline", "CHEMBL360253", "SCHEMBL13648771"],
  },
  {
    primary_id: "D0H3LU",
    names: ["NSC-86715"],
  },
  {
    primary_id: "D0H3MG",
    names: ["Felypressin"],
  },
  {
    primary_id: "D0H3MO",
    names: ["(-)-Norfenfluramine"],
  },
  {
    primary_id: "D0H3MS",
    names: ["Antiparasitics"],
  },
  {
    primary_id: "D0H3NA",
    names: [
      "4-Benzo[b]thiophen-4-Yl-1H-Imidazole",
      "CHEMBL30534",
      "1H-Imidazole, 5-Benzo[b]thien-4-Yl-",
      "SCHEMBL1502418",
      "BDBM50085681",
      "4-(1H-Imidazole-4-Yl)Benzo[b]thiophene",
    ],
  },
  {
    primary_id: "D0H3ND",
    names: ["AGS62P1", "AGS-62P1"],
  },
  {
    primary_id: "D0H3NN",
    names: ["Chondrocyte Tissue Grafts"],
  },
  {
    primary_id: "D0H3NO",
    names: ["PMID27744724-Compound-4"],
  },
  {
    primary_id: "D0H3NS",
    names: [
      "2-Oxo-2H-Chromene-3-Carboxylic Acid",
      "Coumarin-3-Carboxylic Acid",
      "531-81-7",
      "3-Carboxycoumarin",
      "2-Oxochromene-3-Carboxylic Acid",
      "2-Oxobenzopyran-3-Carboxylic Acid",
      "G 1 (Rodenticide)",
      "2H-1-BENZOPYRAN-3-CARBOXYLIC ACID, 2-OXO-",
      "2-Oxo-2H-1-Benzopyran-3-Carboxylic Acid",
      "UNII-V85UOV8788",
      "G 1 (VAN)",
      "G 1 (The Rodenticide) (VAN)",
      "EINECS 208-518-0",
      "NSC 14797",
      "NSC 53239",
      "BRN 0154276",
      "CHEMBL83294",
      "Malonic Acid, Salicylidene-, Delta-Lactone",
      "AI3-36066",
      "ACMLKANOGIVEPB-UHFFFAOYSA-N",
      "V85UOV8788",
      "NSC53239",
      "MFCD00006852",
      "G 1 (The Rodenticide)",
      "WLN:",
    ],
  },
  {
    primary_id: "D0H3OP",
    names: [
      "2-Ethyl-9H-Carbazole",
      "9H-Carbazole, 2-Ethyl-",
      "CHEMBL1171435",
      "106551-62-6",
      "SCHEMBL278610",
      "AC1L3D11",
      "BDBM50322582",
    ],
  },
  {
    primary_id: "D0H3OZ",
    names: ["BIM-46187"],
  },
  {
    primary_id: "D0H3PC",
    names: ["Technetium Tc-99m Polyphosphate Kit"],
  },
  {
    primary_id: "D0H3PV",
    names: ["Insulin + Pramlintide"],
  },
  {
    primary_id: "D0H3QK",
    names: ["AMINOFENTANYL", "CHEMBL385168", "BDBM50191054"],
  },
  {
    primary_id: "D0H3QX",
    names: [
      "4-Nitro-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL60027",
      "4-Nitro-N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "AC1LEVE1",
      "Oprea1_616019",
      "Oprea1_578616",
      "Oprea1_274027",
      "MolPort-001-016-564",
      "ZINC1506439",
      "BDBM50101782",
      "STK401913",
      "AKOS000523323",
      "MCULE-9071819567",
      "BAS 00417263",
      "N-(4-Phenyl-2-Thiazolyl)-4-Nitrobenzamide",
      "ST50002539",
      "SR-01000394982",
      "SR-01000394982-1",
    ],
  },
  {
    primary_id: "D0H3RD",
    names: ["BAS-0338876", "CHEMBL206046", "BDBM50187566"],
  },
  {
    primary_id: "D0H3RO",
    names: [
      "Azithromycin-N-Benzyltriazolylnonahydroxamic Acid",
      "CHEMBL509089",
      "SCHEMBL14329756",
      "BDBM27183",
      "Triazole-Linked Azithromycin-Based Compound, 16g",
    ],
  },
  {
    primary_id: "D0H3RQ",
    names: ["8-Bromo-6-Methyl-3-(4'-Methoxyphenyl)Coumarin", "CHEMBL1221980", "SCHEMBL17857193", "BDBM50324964"],
  },
  {
    primary_id: "D0H3SL",
    names: ["NK3201"],
  },
  {
    primary_id: "D0H3ST",
    names: ["AKCEA-ANGPTL3-LRX"],
  },
  {
    primary_id: "D0H3TC",
    names: ["PMID21851057C4d"],
  },
  {
    primary_id: "D0H3TD",
    names: ["Imipenem"],
  },
  {
    primary_id: "D0H3UI",
    names: ["AG 9"],
  },
  {
    primary_id: "D0H3UO",
    names: ["RGD-039"],
  },
  {
    primary_id: "D0H3UR",
    names: ["M3814"],
  },
  {
    primary_id: "D0H3VI",
    names: ["PMID25656651-Compound-31a"],
  },
  {
    primary_id: "D0H3VK",
    names: ["BIIB 028"],
  },
  {
    primary_id: "D0H3VZ",
    names: ["Salvinorin B Methylthiomethyl Ether", "CHEMBL403658"],
  },
  {
    primary_id: "D0H3WI",
    names: ["Ribavirin"],
  },
  {
    primary_id: "D0H3WJ",
    names: ["1-(10H-Phenothiazin-10-Yl)-2-Phenylpropan-1-One", "CHEMBL240045"],
  },
  {
    primary_id: "D0H3WU",
    names: ["Omega-3-Acid Ethyl Esters 90"],
  },
  {
    primary_id: "D0H3XB",
    names: ["TULOPAFANT"],
  },
  {
    primary_id: "D0H3XF",
    names: ["5,7-Diphenyl-7H-Pyrrolo[2,3-D]pyrimidin-4-Ol"],
  },
  {
    primary_id: "D0H3YC",
    names: ["EP-128504"],
  },
  {
    primary_id: "D0H3YG",
    names: ["PMID29757691-Compound-2a"],
  },
  {
    primary_id: "D0H3YR",
    names: ["[3H]robalzotan", "[3H]NAD-299", "[3H]NAD 299"],
  },
  {
    primary_id: "D0H3ZG",
    names: ["Six-Membered Heterocyclic Benzamide Derivative 2"],
  },
  {
    primary_id: "D0H3ZR",
    names: ["CytoGam"],
  },
  {
    primary_id: "D0H3ZZ",
    names: ["PMID29473428-Compound-71"],
  },
  {
    primary_id: "D0H4AD",
    names: [
      "1-(4-Bromophenyl)-2-(Tert-Butylamino)Propan-1-One",
      "CHEMBL566001",
      "SCHEMBL770168",
      "BDBM50302910",
      "AKOS022330096",
    ],
  },
  {
    primary_id: "D0H4BF",
    names: ["NS-11757"],
  },
  {
    primary_id: "D0H4BJ",
    names: ["Muscarine"],
  },
  {
    primary_id: "D0H4BT",
    names: [
      "2-(4-(Methylsulfonyl)Phenyl)Pyridine",
      "CHEMBL1240924",
      "2-[4-(Methylsulfonyl)Phenyl]pyridine",
      "SCHEMBL8229590",
    ],
  },
  {
    primary_id: "D0H4CB",
    names: ["Hemoglobin Raffimer"],
  },
  {
    primary_id: "D0H4CF",
    names: ["3-((4-Aminophenyl)Diazenyl)Benzenesulfonamide", "3-(4'-Aminophenyl)Diazenylbenzenesulfonamide"],
  },
  {
    primary_id: "D0H4CL",
    names: ["C-Linked Disaccharide Biphenyl Mannoside Derivative 1"],
  },
  {
    primary_id: "D0H4DF",
    names: [
      "GALANTIDE",
      "52364-71-3",
      "4'-Pentyloxy-[1,1'-Biphenyl]-4-Carbonitrile",
      "4-Pentyloxy-4'-Cyanobiphenyl",
      "4-Cyano-4'-Pentyloxybiphenyl",
      "4-Amyloxycyanodiphenyl",
      "5OCB",
      "4'-(Pentyloxy)Biphenyl-4-Carbonitrile",
      "[1,1'-Biphenyl]-4-Carbonitrile, 4'-(Pentyloxy)-",
      "5COB",
      "M 15 (Liquid Crystal)",
      "NZh K 1",
      "4-Cyano-4'-Pentoxybiphenyl",
      "D 105 (Stationary Phase)",
      "Ro CB 5315",
      "M 15",
      "4-Pentyloxy-[1,1'-Biphenyl]-4'-Carbonitrile",
      "EINECS 257-875-9",
      "BRN 2215500",
      "4-(4-Pentoxyphenyl)Benzonitrile",
      "D 105",
      "4'-(Pentyloxy)-(1,1'-Biphenyl)-4-Carbonitrile",
    ],
  },
  {
    primary_id: "D0H4DR",
    names: ["BMS-852927"],
  },
  {
    primary_id: "D0H4DU",
    names: ["KD-7332"],
  },
  {
    primary_id: "D0H4DW",
    names: ["9-Ethyl-Beta-Carboline", "CHEMBL1097825", "SCHEMBL2220005", "BMGUCVPPDGYFRV-UHFFFAOYSA-N"],
  },
  {
    primary_id: "D0H4EK",
    names: ["C[RGDf-(R)-N-Me-Alpha-TfmF]", "CHEMBL381590"],
  },
  {
    primary_id: "D0H4EM",
    names: ["Tuberculosis Recombinant Subunit Vaccine"],
  },
  {
    primary_id: "D0H4GB",
    names: [
      "CB1 Antagonist, Bayer",
      "2-[(2,6-Dichloro-3-Methylphenyl)Amino]benzoate",
      "CHEMBL876",
      "CL-583.NA SALT",
      "Meclofenamic Acid(1-)",
      "UPCMLD-DP012",
      "AC1L1H9Q",
      "UPCMLD-DP012:001",
      "CTK5I4915",
      "CHEBI:76230",
      "BDBM50012896",
      "MCULE-3600081195",
      "CCG-204812",
      "NCGC00023217-05",
      "NCGC00023217-04",
      "NCGC00023217-03",
      "NCGC00023217-06",
      "ZB000384",
      "2-(2,6-Dichloro-3-Methylanilino)Benzoate",
      "AB00053494",
      "AB00053494_19",
      "2-(2,6-Dichloro-3-Methyl-Phenylamino)-Benzoate",
      "2-(2,6-Dichloro-3-Methyl-Phenylamino)-Benzoate(Fenamate Series)",
    ],
  },
  {
    primary_id: "D0H4GE",
    names: ["MSI-469"],
  },
  {
    primary_id: "D0H4GI",
    names: [
      "CEP-5214",
      "12-(3-Hydroxypropyl)-9-(Isopropoxymethyl)-6,7,12,13-Tetrahydro-5H-Indeno[2,1-A]pyrrolo[3,4-C]carbazol-5-One",
    ],
  },
  {
    primary_id: "D0H4GN",
    names: [
      "Desflurane",
      "Desflurano",
      "Desfluranum",
      "Suprane",
      "I 653",
      "I653",
      "PC6781E",
      "Desflurane [USAN:INN]",
      "Desflurano [INN-Spanish]",
      "Desfluranum [INN-Latin]",
      "I-653",
      "R-E 236ea1",
      "Suprane (TN)",
      "Desflurane (JAN/USP/INN)",
      "Difluoromethyl 1,2,2,2-Tetrafluoroethyl Ether",
      "(+-)-2-Difluoromethyl 1,2,2,2-Tetrafluoroethyl Ether",
      "1,1,1,2-Tetrafluoro-2-(Difluoromethoxy)Ethane",
      "2-(Difluoromethoxy)-1,1,1,2-Tetrafluoroethane",
    ],
  },
  {
    primary_id: "D0H4GQ",
    names: ["RY-066"],
  },
  {
    primary_id: "D0H4GT",
    names: ["131I-Radretumab"],
  },
  {
    primary_id: "D0H4GU",
    names: ["EPX-200"],
  },
  {
    primary_id: "D0H4HD",
    names: ["PMID25991433-Compound-A1"],
  },
  {
    primary_id: "D0H4HR",
    names: ["PMID28394193-Compound-42"],
  },
  {
    primary_id: "D0H4ID",
    names: [
      "(S)-3-(1H-Imidazol-4-Yl)Propyl Sec-Butylcarbamate",
      "CHEMBL1080090",
      "BDBM50311766",
      "BDBM50074077",
      "[(1S)-1-Methylpropyl]carbamic Acid 3-(1H-Imidazole-4-Yl)Propyl Ester",
      "((S)-Sec-Butyl)-Carbamic Acid 3-(1H-Imidazol-4-Yl)-Propyl Ester",
    ],
  },
  {
    primary_id: "D0H4IM",
    names: ["PH-284"],
  },
  {
    primary_id: "D0H4IP",
    names: ["SL-052"],
  },
  {
    primary_id: "D0H4IU",
    names: ["GPI-16072"],
  },
  {
    primary_id: "D0H4JB",
    names: [
      "4-(6,7-Dimethoxy-Quinolin-3-Yl)-Phenol",
      "CHEMBL303704",
      "4-(6,7-Dimethoxyquinolin-3-Yl)Phenol",
      "ZINC3834025",
    ],
  },
  {
    primary_id: "D0H4JM",
    names: [
      "Yohimbine",
      "APHRODINE",
      "Actibine",
      "Aphrodyne",
      "Aphrosol",
      "Corynine",
      "Johimbin",
      "Quebrachin",
      "Quebrachine",
      "Thybine",
      "Yocon",
      "Yohimar",
      "Yohimbin",
      "Yohimex",
      "Yoman",
      "Yovital",
      "Dayto Himbin",
      "Yohimbic Acid Methyl Ester",
      "Baron-X",
      "Trans-Quinolizidine Yohimbine",
      "Yohimbine (DCF)",
      "Methyl 17alpha-Hydroxyyohimban-16alpha-Carboxylate",
      "Yohimbol-16alpha-Carboxylic Acid, Methyl Ester (6CI)",
      "Benz[g]indolo[2,3-A]quinolizine, Yohimban-16-Carboxylic Acid Deriv.",
      "Methyl (16alpha,17alpha)-17-Hydroxyyohimban-16-Carboxylate",
      "Yohimban-16alpha-Carboxylic Acid, 17alpha-Hydroxy-, Methyl Ester (8CI)",
      "Yohimban-16-Alpha-Carboxylic Acid, 17-Alpha-Hydroxy-, Methyl Ester",
      "Yohimban-16-Carboxylic Acid, 17-Hydroxy-, Methyl Ester, (16alpha,17alpha)-(9CI)",
      "Yohimban-16-.alpha.-Carboxylic Acid, 17-.alpha.-Hydroxy-, Methyl Ester",
      "(+)-Yohimbin",
      "(+)-Yohimbine",
      "(16alpha,17alpha)-17-Hydroxy-Yohimban-16-Carboxylic Acid Methyl Ester",
      "(16alpha,17alpha)-17-Hydroxyyohimban-16-Carboxylic Acid Methyl Ester",
      "17-Hydroxy-Yohimbane-16-Carboxylic Acid Methyl Ester",
      "17-Hydroxyyohimban-16-Carboxylic Acid Methyl Ester",
      "17alpha-Hydroxyyohimban-16alpha-Carboxylic Acid Methyl Ester",
      "2-Hydroxy-1,2,3,4,4a,5,7,8,13,13b,14,14a-Dodecahydro-Indolo[2',3':3,4]pyrido[1,2-B]isoquinoline-1-Carboxylic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0H4JN",
    names: ["RTI-113", "CHEMBL608548"],
  },
  {
    primary_id: "D0H4KF",
    names: ["EC-0565"],
  },
  {
    primary_id: "D0H4LG",
    names: ["1,2,4-Triazole [1,5-A]pyrimidin-7-Yl Derivative 1"],
  },
  {
    primary_id: "D0H4LI",
    names: ["H6N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0H4LK",
    names: ["Thiazoline Derivative 1"],
  },
  {
    primary_id: "D0H4LP",
    names: ["PMID24412111C9g"],
  },
  {
    primary_id: "D0H4MB",
    names: ["ISONIPECOTAMIDE"],
  },
  {
    primary_id: "D0H4MO",
    names: ["NE58027"],
  },
  {
    primary_id: "D0H4MV",
    names: [
      "5-Methyl-N-Phenylbenzo[d]oxazol-2-Amine",
      "CHEMBL1269622",
      "SCHEMBL13436713",
      "BDBM50329396",
      "N-Phenyl-5-Methylbenzoxazole-2-Amine",
      "(5-Methyl-Benzooxazol-2-Yl)-Phenyl-Amine",
      "5-Methyl-N-Phenyl-1,3-Benzoxazol-2-Amine",
    ],
  },
  {
    primary_id: "D0H4ND",
    names: ["IC31"],
  },
  {
    primary_id: "D0H4NK",
    names: ["1-Phenylbiguanide"],
  },
  {
    primary_id: "D0H4NN",
    names: ["NB3322"],
  },
  {
    primary_id: "D0H4NQ",
    names: ["AWD-5239"],
  },
  {
    primary_id: "D0H4NR",
    names: ["N-(3,4-Dichlorophenyl)Propyl-ETAV"],
  },
  {
    primary_id: "D0H4PF",
    names: ["Dipeptide Analog 3"],
  },
  {
    primary_id: "D0H4PN",
    names: [
      "1-(1-Phenyl-Propyl)-3-Thiazol-2-Yl-Thiourea",
      "CHEMBL151773",
      "AC1MHEIH",
      "SCHEMBL6284442",
      "BDBM50097039",
      "1-(1-Phenylpropyl)-3-Thiazol-2-Yl-Thiourea",
      "N-(Alpha-Ethylbenzyl]-N'-[2-(Thiazolyl)]thiourea",
    ],
  },
  {
    primary_id: "D0H4PP",
    names: ["VXA-G1.1-NN"],
  },
  {
    primary_id: "D0H4QM",
    names: ["PB-80"],
  },
  {
    primary_id: "D0H4RE",
    names: [
      "1-(2-(Diethylamino)Quinazolin-4-Yl)-3-Phenylurea",
      "CHEMBL305516",
      "BDBM50088469",
      "1-[2-(Diethylamino)-4-Quinazolinyl]-3-Phenylurea",
    ],
  },
  {
    primary_id: "D0H4RV",
    names: ["NDC-1022"],
  },
  {
    primary_id: "D0H4SC",
    names: ["N-(4-Amino-4'-Bromobiphenyl-3-Yl)Benzamide", "CHEMBL1097748", "BDBM50317990"],
  },
  {
    primary_id: "D0H4SE",
    names: ["Benzothiazine-Carboxamide Compound 6"],
  },
  {
    primary_id: "D0H4TC",
    names: ["Davalintide", "Davalintide Acetate", "Second-Generation Amylinomimetics (Obesity), Amylin"],
  },
  {
    primary_id: "D0H4TP",
    names: [
      "GSK189254A",
      "720690-73-3",
      "GSK 189254A",
      "GSK-189254",
      "UNII-5T4TX6CO53",
      "GSK189254",
      "CHEMBL517140",
      "5T4TX6CO53",
      "6-[(3-Cyclobutyl-2,3,4,5-Tetrahydro-1H-3-Benzazepin-7-Yl)Oxy]-N-Methylpyridine-3-Carboxamide",
      "6-((3-Cyclobutyl-2,3,4,5-Tetrahydro-1H-3-Benzazepin-7-Yl)Oxy)-N-Methyl-3-Pyridinecarboxamide",
      "WROHEWWOCPRMIA-UHFFFAOYSA-N",
      "854485-15-7",
      "GSK-189254A",
      "SCHEMBL169579",
      "ZINC3961799",
      "AOB87474",
      "EX-A1905",
      "ABP000814",
      "BDBM50247054",
      "AKOS032944981",
      "CS-6206",
      "BCP9000737",
      "GSK-189,254",
      "KB-77605",
      "HY-14111",
    ],
  },
  {
    primary_id: "D0H4TQ",
    names: ["Thyrotropin Alfa"],
  },
  {
    primary_id: "D0H4TR",
    names: ["PMID25666693-Compound-80"],
  },
  {
    primary_id: "D0H4UJ",
    names: ["INOGATRAN"],
  },
  {
    primary_id: "D0H4UN",
    names: ["PMID24900608C1"],
  },
  {
    primary_id: "D0H4UP",
    names: ["CAR-GPC3 T Cells"],
  },
  {
    primary_id: "D0H4US",
    names: [
      "(2S)-2-(4-Ethylphenoxy)-3-Phenylpropanoic Acid",
      "CHEMBL191060",
      "ZINC13671697",
      "BDBM50171895",
      "DB07842",
      "(S)-2-(4-Ethyl-Phenoxy)-3-Phenyl-Propionic Acid",
    ],
  },
  {
    primary_id: "D0H4VA",
    names: ["Pioglitazone Companion Diagnostic"],
  },
  {
    primary_id: "D0H4VB",
    names: ["PMID24900428C14"],
  },
  {
    primary_id: "D0H4WH",
    names: ["Des-AA5-[D-Trp8]SRIF", "CHEMBL408338"],
  },
  {
    primary_id: "D0H4WT",
    names: ["Peptidomimetic Analog 7"],
  },
  {
    primary_id: "D0H4XV",
    names: ["IM0-8400"],
  },
  {
    primary_id: "D0H4YD",
    names: ["AMG520", "CNP520"],
  },
  {
    primary_id: "D0H4YH",
    names: ["4,3'',5''-Trimethoxy-[1,1':3',1'']-Terphenyl", "CHEMBL206919", "BDBM50186761"],
  },
  {
    primary_id: "D0H4YL",
    names: ["Afluria Quadrivalent"],
  },
  {
    primary_id: "D0H4YP",
    names: [
      "4-Hydroxy-3-(2-Naphthylmethyl)-2H-Chromen-2-One",
      "CHEMBL571283",
      "SCHEMBL7354617",
      "BDBM35550",
      "4-Hydroxy-2H-Chromen-2-One Core, 26",
    ],
  },
  {
    primary_id: "D0H4YW",
    names: ["HINOKITIOL"],
  },
  {
    primary_id: "D0H4ZD",
    names: ["GPI-2A"],
  },
  {
    primary_id: "D0H5BC",
    names: ["Tosagestin"],
  },
  {
    primary_id: "D0H5BG",
    names: ["PMID23428964CI3"],
  },
  {
    primary_id: "D0H5CD",
    names: ["GI-129471"],
  },
  {
    primary_id: "D0H5CE",
    names: ["4-Pyridin-3-Yl-5-Quinolin-4-Yl-Thiazol-2-Ylamine", "CHEMBL359724", "BDBM50151364"],
  },
  {
    primary_id: "D0H5CV",
    names: ["ZK-119010"],
  },
  {
    primary_id: "D0H5DM",
    names: ["Viaskin Milk"],
  },
  {
    primary_id: "D0H5DU",
    names: [
      "Oxyphencyclimine",
      "Antulcus",
      "Caridan",
      "Daricol",
      "Manir",
      "Naridan",
      "Oxifencicliminum",
      "Oxiphencycliminum",
      "Oxyphencycliminum",
      "Zamanil",
      "Oxifencicliminum [INN-Spanish]",
      "Oxyphencyclimine (INN)",
      "Oxyphencyclimine [INN:BAN]",
      "Oxyphencycliminum [INN-Latin]",
      "Benzeneacetic Acid, .alpha.-Cyclohexyl-.alpha.-Hydroxy-, (1,4,5,6-Tetrahydro-1-Methyl-2-Pyrimidinyl)Methyl Ester",
      "(1-Methyl-1,4,5,6-Tetrahydropyrimidin-2-Yl)Methyl Cyclohexyl(Hydroxy)Phenylacetate",
      "(1-Methyl-5,6-Dihydro-4H-Pyrimidin-2-Yl)Methyl 2-Cyclohexyl-2-Hydroxy-2-Phenylacetate",
      "1,4,5,6-Tetrahydro-1-Methyl-2-Pyrimidinemethanol-Alpha-Phenylcyclohexaneglycolate",
      "1,4,5,6-Tetrahydro-1-Methyl-2-Pyrimidinylmethyl Alpha-Cyclohexyl-Alpha-Phenylglycolat",
      "1-Methyl-1,4,5,6-Tetrahydro-2-Pyrimidinylmethyl-Alpha-Cyclohexylmandelat",
    ],
  },
  {
    primary_id: "D0H5EP",
    names: ["1-Benzenesulfonyl-3-Piperidin-4-Yl-1H-Indole", "CHEMBL180826"],
  },
  {
    primary_id: "D0H5ER",
    names: ["MLN-591RL"],
  },
  {
    primary_id: "D0H5ES",
    names: ["Rontalizumab"],
  },
  {
    primary_id: "D0H5FI",
    names: ["MRL24"],
  },
  {
    primary_id: "D0H5FW",
    names: [
      "PUR-118",
      "DP ICALM",
      "ICALM (Dry Powder, Respiratory Disease), Pulmatrix",
      "Inhaled Cationic Airway Lining Modulator (ICALM, Dry Powder,respiratory Disease), Pulmatrix",
    ],
  },
  {
    primary_id: "D0H5FY",
    names: ["RS 93520"],
  },
  {
    primary_id: "D0H5GP",
    names: ["PMID29671355-Compound-15"],
  },
  {
    primary_id: "D0H5GU",
    names: ["GlucoStem"],
  },
  {
    primary_id: "D0H5GW",
    names: ["Aurora A Kinase Inhibitors (Cancer), Genentech"],
  },
  {
    primary_id: "D0H5HI",
    names: ["4,6,6,7-Tetramethyl-6H-Benzo[c]chromene-3,8-Diol", "CHEMBL204025", "SCHEMBL6827956"],
  },
  {
    primary_id: "D0H5HJ",
    names: ["ALN-PCS"],
  },
  {
    primary_id: "D0H5HO",
    names: [
      "ANDROSTERONE",
      "Androsterone",
      "53-41-8",
      "Androkinine",
      "Androtine",
      "5alpha-Androsterone",
      "Atromide ICI",
      "3alpha-Hydroxy-5alpha-Androstan-17-One",
      "3-Epihydroxyetioallocholan-17-One",
      "Cis-Androsterone",
      "3alpha-Hydroxyetioallocholan-17-One",
      "5alpha-Androstane-3alpha-Ol-17-One",
      "3alpha-Hydroxy-17-Androstanone",
      "Androstanon-3-Alpha-Ol-17-One",
      "3-Alpha-Hydroxy-17-Androstanone",
      "3-Alpha-Hydroxyetioallocholan-17-One",
      "3-Alpha-Hydroxy-5-Alpha-Androstan-17-One",
      "5-Alpha-Androstan-3-Alpha-Ol-17-One",
      "Caswell No. 051G",
      "Androstan-17-One, 3-Hydroxy-, (3alpha,5alpha)-",
    ],
  },
  {
    primary_id: "D0H5HP",
    names: [
      "1-Amino-4-Chloroanthracene-9,10-Dione",
      "1-Amino-4-Chloroanthraquinone",
      "2872-47-1",
      "4-Chloro-1-Aminoanthraquinone",
      "4-Amino-4-Chloroanthraquinone",
      "ANTHRAQUINONE, 1-AMINO-4-CHLORO-",
      "C.I. 62805",
      "NSC 39927",
      "BRN 0916373",
      "1-Amino-4-Chloro-9,10-Anthracenedione",
      "9,10-Anthracenedione, 1-Amino-4-Chloro-",
      "CHEMBL603094",
      "NSC39927",
      "AC1L2AVQ",
      "9, 1-Amino-4-Chloro-",
      "4-14-00-00440 (Beilstein Handbook Reference)",
      "SCHEMBL5109323",
      "CTK4G1984",
      "DTXSID50182880",
      "MolPort-000-643-769",
      "AWACQBFBMROGQC-UHFFFAOYSA-N",
      "ZINC4792024",
      "NSC-39927",
      "BDBM50306769",
      "STK238272",
    ],
  },
  {
    primary_id: "D0H5HS",
    names: ["HS-1183", "Bile Acid Derivatives, Pusan", "HS-1199", "HS-1200"],
  },
  {
    primary_id: "D0H5HT",
    names: ["PCAP-1"],
  },
  {
    primary_id: "D0H5HZ",
    names: [
      "Etamicastat",
      "BIA-5453",
      "Dopamine Beta Hydroxylase Inhibitors (Cardiovascular Diseases), BIAL",
      "Dopamine Beta Hydroxylase Inhibitors (Cardiovascular Diseases), Portela",
    ],
  },
  {
    primary_id: "D0H5II",
    names: [
      "2-Methoxy-3,4-Methylenedioxybenzophenone",
      "872881-74-8",
      "(4-Methoxybenzo[d][1,3]dioxol-5-Yl)(Phenyl)Methanone",
      "CHEMBL497717",
      "CTK3C4926",
      "SUKAEERDMQPLMM-UHFFFAOYSA-",
      "DTXSID20475539",
      "AKOS027335527",
      "4-Methoxy-5-Benzoyl-1,3-Benzodioxole",
      "Methanone, (4-Methoxy-1,3-Benzodioxol-5-Yl)Phenyl-",
    ],
  },
  {
    primary_id: "D0H5IU",
    names: ["2849466"],
  },
  {
    primary_id: "D0H5IZ",
    names: ["Etirinotecan Pegol"],
  },
  {
    primary_id: "D0H5JV",
    names: ["4-Chloro-3-Hydroxy-1H-Quinolin-2-One"],
  },
  {
    primary_id: "D0H5JW",
    names: ["[3H](R)-(-)-Baclofen", "AC1OCG0F", "ZB000014"],
  },
  {
    primary_id: "D0H5KA",
    names: ["VRC-HIVDNA016-00-VP"],
  },
  {
    primary_id: "D0H5KG",
    names: ["AR-9273"],
  },
  {
    primary_id: "D0H5KK",
    names: ["Garnocestim"],
  },
  {
    primary_id: "D0H5KO",
    names: ["KW-3635"],
  },
  {
    primary_id: "D0H5LC",
    names: ["CHPMPC", "Cyclic HPMPC", "Cyclic Cidofovir", "Cidofovir Prodrug, Gilead", "GS-504 Analog", "GS-930"],
  },
  {
    primary_id: "D0H5LK",
    names: ["Dapoxetine"],
  },
  {
    primary_id: "D0H5LY",
    names: ["STNM-04"],
  },
  {
    primary_id: "D0H5MB",
    names: [
      "Bopindolol",
      "BOPINDOLOL",
      "62658-63-3",
      "Sandonorm",
      "(+-)-Bopindolol",
      "1-(Tert-Butylamino)-3-((2-Methyl-1H-Indol-4-Yl)Oxy)Propan-2-Yl Benzoate",
      "Bopindololum [INN-Latin]",
      "Bopindolol [INN]",
      "UUOJIACWOAYWEZ-UHFFFAOYSA-N",
      "Bopindolol (INN)",
      "Wandonorm",
      "69010-88-4",
      "NCGC00163155-01",
      "Sandonorm (TN)",
      "(+-)-4-(2-Benzoyloxy-3-Tert-Butylaminopropoxy)-2-Methylindole",
      "DSSTox_CID_2684",
      "DSSTox_RID_76690",
      "(+-)-1-(Tert-Butylamino)-3-((2-Methylindol-4-Yl)Oxy)-2-Propanol Benzoate (Ester)",
      "DSSTox_GSID_22684",
    ],
  },
  {
    primary_id: "D0H5MS",
    names: ["CT84.66"],
  },
  {
    primary_id: "D0H5MV",
    names: ["N-Propargyl-1H-Pyrrole-2-Carboxamide"],
  },
  {
    primary_id: "D0H5MW",
    names: ["PHN-021"],
  },
  {
    primary_id: "D0H5MX",
    names: ["TAFA-93"],
  },
  {
    primary_id: "D0H5MZ",
    names: ["ISIS 15999"],
  },
  {
    primary_id: "D0H5NF",
    names: ["IFN-Beta-CTP"],
  },
  {
    primary_id: "D0H5NT",
    names: ["Birch Pollen Allergy Desensitization Tablet"],
  },
  {
    primary_id: "D0H5NX",
    names: ["TOP-53"],
  },
  {
    primary_id: "D0H5OR",
    names: ["RM-493"],
  },
  {
    primary_id: "D0H5OX",
    names: ["HAL-MPE1"],
  },
  {
    primary_id: "D0H5PD",
    names: ["CP-0597"],
  },
  {
    primary_id: "D0H5PG",
    names: [
      "CGS-22652",
      "Cgs 22652",
      "CHEMBL65414",
      "(+-)-Gamma-(4-(((4-Chlorophenyl)Sulfonyl)Amino)Butyl)-3-Pyridineheptanoic Acid",
      "(R,S)-8-((4-Chlorophenylsulfonyl)Amino)-4-(3-(3-Pyridinyl)Propyl)Octanoic Acid",
      "3-Pyridineheptanoic Acid, Gamma-(4-(((4-Chlorophenyl)Sulfonyl)Amino)Butyl)-, (+-)-",
      "C22H29ClN2O4S",
      "AC1L2ZNZ",
      "8-CSAPOA",
      "SCHEMBL9710116",
      "8-(4-Chloro-Benzenesulfonylamino)-4-(3-Pyridin-3-Yl-Propyl)-Octanoic Acid",
      "ISMHAZMNDUAHLK-UHFFFAOYSA-N",
      "BDBM50003776",
      "134235-78-2",
      "LS-131625",
      "L004635",
    ],
  },
  {
    primary_id: "D0H5QU",
    names: ["ZNCCNCSSKWCRDHSRCC"],
  },
  {
    primary_id: "D0H5RS",
    names: ["1-(Oxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL220125", "SCHEMBL12403823", "BDBM50204493", "ZINC34803651"],
  },
  {
    primary_id: "D0H5TU",
    names: [
      "RQ-00000008",
      "CJ-042794",
      "Prostaglandin EP4 Antagonists (Chronic Inflammatory Pain), Pfizer",
      "Prostaglandin EP4 Antagonists (Chronic Inflammatory Pain), RaQualia",
    ],
  },
  {
    primary_id: "D0H5TW",
    names: ["HYG-104"],
  },
  {
    primary_id: "D0H5UL",
    names: ["PMID28074661-Compound-US20128202889C90"],
  },
  {
    primary_id: "D0H5VF",
    names: ["RPR-113829"],
  },
  {
    primary_id: "D0H5VO",
    names: ["PMID29338548-Compound-27"],
  },
  {
    primary_id: "D0H5WE",
    names: ["PMID29338548-Compound-30"],
  },
  {
    primary_id: "D0H5WL",
    names: ["NSC-651016", "CHEMBL262118"],
  },
  {
    primary_id: "D0H5WM",
    names: ["S-0509", "3-[3-[N-(2-Benzoylphenyl)-N-(Tert-Butoxycarbonylmethyl)Carbamoylmethyl]ureido]benzoic Acid"],
  },
  {
    primary_id: "D0H5WN",
    names: ["Isopropylcarbamic Acid Biphenyl-3-Yl Ester"],
  },
  {
    primary_id: "D0H5WV",
    names: ["OSCILLARIN"],
  },
  {
    primary_id: "D0H5WY",
    names: [
      "5,5'-Methylenebis(1,3,4-Oxadiazole-2(3H)-Thione)",
      "CHEMBL608132",
      "Bis-(5-Mercapto-1,3,4-Oxadiazol-2-Yl)-Methane",
      "BDBM50304603",
      "90748-72-4",
      "Bis(1,3,4-Oxadiazol-2-Methyl)-5-Thione",
    ],
  },
  {
    primary_id: "D0H5XB",
    names: ["Asparaginase Erwinia Chrysanthemi"],
  },
  {
    primary_id: "D0H5XC",
    names: ["Pyrazole Derivative 69"],
  },
  {
    primary_id: "D0H5XD",
    names: [
      "3-(3-Carboxy-Propionylamino)-Benzoic Acid",
      "CHEMBL296834",
      "3-(3-Carboxypropanamido)Benzoic Acid",
      "3-[(3-Carboxypropanoyl)Amino]benzoic Acid",
      "259222-20-3",
      "IFLab1_001254",
      "3-(3-Carboxypropanoylamino)Benzoic Acid",
      "AC1LFPO3",
      "Oprea1_683242",
      "Oprea1_208346",
      "CTK0J3689",
      "DTXSID30353899",
      "MolPort-000-385-127",
      "HMS1415I22",
      "ZINC216961",
      "STK032065",
      "BDBM50127981",
      "AKOS000112854",
      "MCULE-6483245282",
      "IDI1_009121",
      "ST50036495",
      "3-(4-Oxo-4-Hydroxybutyrylamino)Benzoic Acid",
      "3-[(4-Hydroxy-4-Oxobutanoyl)Amino]benzoic Acid",
      "SR-01000005669",
      "Benzoic Acid, 3-[(3-Carbo",
    ],
  },
  {
    primary_id: "D0H5XP",
    names: ["2-(Ethylthiomethyl)-5-Hydroxy-4H-Pyran-4-One", "CHEMBL1269349"],
  },
  {
    primary_id: "D0H5XR",
    names: ["YM-348"],
  },
  {
    primary_id: "D0H5YE",
    names: ["NNC 05-2045"],
  },
  {
    primary_id: "D0H5ZS",
    names: ["C31510"],
  },
  {
    primary_id: "D0H6AA",
    names: ["M3Pr6S", "CHEMBL385048", "3-Propionylmorphine 6-Sulfate"],
  },
  {
    primary_id: "D0H6AF",
    names: ["Thiazole Carboxamide Derivative 4"],
  },
  {
    primary_id: "D0H6AG",
    names: ["PMID23981898C11d"],
  },
  {
    primary_id: "D0H6AL",
    names: ["1,3,4-Oxadiazole Derivative 4"],
  },
  {
    primary_id: "D0H6AT",
    names: ["SYM-005"],
  },
  {
    primary_id: "D0H6BG",
    names: ["PHCCC", "(-) PHCCC"],
  },
  {
    primary_id: "D0H6BL",
    names: [
      "APL-001",
      "APL-002",
      "STATs-Mediated Cytokine Activity Enhancers (Leukopenia)",
      "STATs-Mediated Cytokine Activity Enhancers (Leukopenia), Shanghai Ambrosia Pharmaceutical",
    ],
  },
  {
    primary_id: "D0H6BS",
    names: ["Rydaptmidostaurin"],
  },
  {
    primary_id: "D0H6BV",
    names: ["1-(4-(Hexyloxy)Phenyl)-3-Morpholinopropan-1-One", "CHEMBL561245"],
  },
  {
    primary_id: "D0H6CA",
    names: ["Lenercept"],
  },
  {
    primary_id: "D0H6CK",
    names: ["PMID16516466C12c"],
  },
  {
    primary_id: "D0H6CQ",
    names: ["MERIOLIN 5"],
  },
  {
    primary_id: "D0H6CX",
    names: ["PMID25666693-Compound-72"],
  },
  {
    primary_id: "D0H6DA",
    names: ["QCP", "CHEMBL485831", "BDBM50242616"],
  },
  {
    primary_id: "D0H6DL",
    names: [
      "1,3,5-Triphenylimidazolidine-2,4-Dione",
      "CHEMBL381136",
      "61505-60-0",
      "SCHEMBL2791034",
      "CTK2D8602",
      "DTXSID10469422",
      "1,3,5-Triphenylimidazoline-2,4-Dione",
      "2,4-Imidazolidinedione, 1,3,5-Triphenyl-",
    ],
  },
  {
    primary_id: "D0H6DN",
    names: ["ISIS 29469"],
  },
  {
    primary_id: "D0H6DZ",
    names: ["OMPT", "(2S)-OMPT"],
  },
  {
    primary_id: "D0H6EF",
    names: ["MVA-BN HIV Multiantigen"],
  },
  {
    primary_id: "D0H6FG",
    names: ["Methoxy Arachidonyl Fluorophosphonate"],
  },
  {
    primary_id: "D0H6FY",
    names: ["Natural Killer Cell Therapy"],
  },
  {
    primary_id: "D0H6GI",
    names: ["VE-822"],
  },
  {
    primary_id: "D0H6GW",
    names: ["4-Heptyl-N-(Pyridin-3-Yl)Benzamide", "CHEMBL263921"],
  },
  {
    primary_id: "D0H6IG",
    names: ["EP-400"],
  },
  {
    primary_id: "D0H6JC",
    names: [
      "1,2-Dihydro-Indazol-3-One",
      "1H-Indazol-3-Ol",
      "3-Indazolinone",
      "7364-25-2",
      "1H-Indazol-3(2H)-One",
      "100922-96-1",
      "Indazolinone",
      "3-Hydroxy-1H-Indazole",
      "3-Hydroxyindazole",
      "1,2-Dihydroindazol-3-One",
      "1,2-Dihydro-3H-Indazol-3-One",
      "3-Oxo-1,2-Indazole",
      "3H-Indazol-3-One, 1,2-Dihydro-",
      "3-Indazolone",
      "2H-Indazol-3-Ol",
      "Hydroxyindazole",
      "2,3-Dihydro-1H-Indazol-3-One",
      "NSC 9352",
      "UNII-8E254S9S1R",
      "CCRIS 6800",
      "EINECS 230-904-2",
      "CHEMBL276725",
      "SWEICGMKXPNXNU-UHFFFAOYSA-N",
      "8E254S9S1R",
      "MFCD00005685",
      "1H-2-Hydroindazol-3-One",
      "Benzopyrazolone",
      "3-Oxoindazoline",
    ],
  },
  {
    primary_id: "D0H6JK",
    names: ["NMS-E628", "ALK Inhibitor (Cancer), Nerviano"],
  },
  {
    primary_id: "D0H6KD",
    names: ["[125I]GTI"],
  },
  {
    primary_id: "D0H6LE",
    names: ["LP-20104"],
  },
  {
    primary_id: "D0H6LQ",
    names: ["KNI-10266"],
  },
  {
    primary_id: "D0H6LR",
    names: ["PTR-3046", "CHEMBL439096"],
  },
  {
    primary_id: "D0H6LU",
    names: ["ISIS 23412"],
  },
  {
    primary_id: "D0H6MC",
    names: [
      "3h-Indole-5,6-Diol",
      "5,6-Dihydroxyindole",
      "3131-52-0",
      "1H-Indole-5,6-Diol",
      "Dopamine Lutine",
      "3H-INDOLE-5,6-DIOL",
      "Dhi",
      "UNII-Z3OC8499KG",
      "5,6-DihydroxyindoleI",
      "C8H7NO2",
      "CHEMBL92636",
      "Z3OC8499KG",
      "CHEBI:27404",
      "AK-25753",
      "D-3560",
      "3ID",
      "5,6-Dihydroxy-1H-Indole",
      "Indole-5,6-Diol",
      "5,6-Dihydroxy Indole",
      "AC1L3FSK",
      "EC 412-130-9",
      "SCHEMBL48994",
      "AC1Q7A19",
      "CTK1C3371",
      "DTXSID20185242",
      "SGNZYJXNUURYCH-UHFFFAOYSA-N",
      "MolPort-001-782-232",
      "ZINC895800",
      "BCP02562",
      "ACT06797",
      "EBD26988",
      "ZX-AT011826",
      "KS-00000J9K",
      "CD-595",
      "BDBM50028548",
      "BBL102959",
      "ANW-44822",
      "STL556768",
      "MFCD00798933",
      "FCH834612",
    ],
  },
  {
    primary_id: "D0H6MZ",
    names: ["6-Ethyl-2,4-Diisobutylaminopyrimidine", "CHEMBL484451"],
  },
  {
    primary_id: "D0H6OL",
    names: ["ISIS 112004"],
  },
  {
    primary_id: "D0H6OO",
    names: ["HTS-00798"],
  },
  {
    primary_id: "D0H6OS",
    names: ["Geldanamycin-Estradiol Hybrid"],
  },
  {
    primary_id: "D0H6PH",
    names: ["GSK-2231260A"],
  },
  {
    primary_id: "D0H6PK",
    names: ["FKF02SC"],
  },
  {
    primary_id: "D0H6PN",
    names: ["LORECLEZOLE"],
  },
  {
    primary_id: "D0H6PX",
    names: ["TAK-603"],
  },
  {
    primary_id: "D0H6QD",
    names: ["Eptinezumab"],
  },
  {
    primary_id: "D0H6QG",
    names: ["PMID25772215-Compound-US02014179750TG7-74"],
  },
  {
    primary_id: "D0H6QL",
    names: ["Salicylhydroxamic Acid"],
  },
  {
    primary_id: "D0H6QU",
    names: ["Apomorphine", "Apomorphine (Intranasal, Parkinson's Disease)"],
  },
  {
    primary_id: "D0H6SH",
    names: ["MT203"],
  },
  {
    primary_id: "D0H6TE",
    names: ["JMV 1802"],
  },
  {
    primary_id: "D0H6TP",
    names: [
      "Monobenzone",
      "Agerite",
      "Alba",
      "Benoquin",
      "Benzoquin",
      "Carmifal",
      "Depigman",
      "Dermochinona",
      "Leucodinine",
      "Monobenzon",
      "Monobenzona",
      "Monobenzonum",
      "PBP",
      "Pigmex",
      "Superlite",
      "Agerite Alba",
      "Benzyl Hydroquinone",
      "Hydrochinon Monobenzylether",
      "Hydrochinon Monobenzylether [Czech]",
      "Hydroquinone Benzyl Ether",
      "Hydroquinone Monobenzyl Ether",
      "ICN Brand Of Monobenzone",
      "Monobenzone [INN]",
      "Monobenzyl Ether Of Hydroquinone",
      "Monobenzyl Ether Hydroquinone",
      "Monobenzyl Hydroquinone",
      "Alba-Dome",
      "Benoquin (TN)",
      "Benoquin, Monobenzone",
      "Benzyl P-Hydroxyphenyl Ether",
      "Carmifal(TN)",
      "Depigman (TN)",
      "Dermochinona (TN)",
      "HYDROQUINONE MONOBENZYL ETHER, N F",
      "Leucodinine (TN)",
      "Monobenzon (TN)",
      "Monobenzona [INN-Spanish]",
      "Monobenzonum [INN-Latin]",
      "Novo-Depigman",
      "P-BENZYLOXYPHENOL",
      "P-Hydroxyphenyl Benzyl Ether",
      "Pigmex (TN)",
      "Superlite (TN)",
      "Superlite (Antioxidant)",
      "Monobenzone (USP/INN)",
      "P-(BENZYLOXY)PHENOL",
      "4-(Benzyl-Oxy)Phenol",
      "4-(Benzyloxy)Phenol",
      "4-(Benzyloxyl)Phenol",
      "4-(Phenylmethoxy)Phenol",
      "4-Benzyloxyphenol",
      "4-Benzyloxy Phenol",
      "4-Phenylmethoxy-Phenol",
      "4-Phenylmethoxyphenol",
    ],
  },
  {
    primary_id: "D0H6TX",
    names: ["(S)-1-(1H-Indazol-4-Yl)-3-(1-P-Tolylethyl)Urea"],
  },
  {
    primary_id: "D0H6UA",
    names: ["1-(4-Propoxyphenyl)Propan-2-Amine", "CHEMBL455991", "AC1MHJ8Q", "BDBM50276773"],
  },
  {
    primary_id: "D0H6UK",
    names: ["HOO-IFOS"],
  },
  {
    primary_id: "D0H6UL",
    names: ["ICON-1"],
  },
  {
    primary_id: "D0H6UX",
    names: ["KNI-10762"],
  },
  {
    primary_id: "D0H6VG",
    names: ["KF-15766"],
  },
  {
    primary_id: "D0H6VK",
    names: [
      "6-(3-Ethyl-Phenylamino)-1H-Pyrimidine-2,4-Dione",
      "CHEMBL51750",
      "72255-48-2",
      "2,4(1H,3H)-Pyrimidinedione, 6-[(3-Ethylphenyl)Amino]-",
      "CTK2H2677",
      "DTXSID90658211",
      "BDBM50028320",
    ],
  },
  {
    primary_id: "D0H6VL",
    names: [
      "NCL-00016149",
      "AW-393",
      "P53-MDM2 Inhibitors (Cancer)",
      "MDM2-P53 Inhibitors (Cancer), Cancer Research Technology",
      "P53-MDM2 Inhibitors (Cancer), Cancer Research Technology",
    ],
  },
  {
    primary_id: "D0H6VY",
    names: ["SM-101"],
  },
  {
    primary_id: "D0H6VZ",
    names: ["REV-576", "Complement C5a Inhibitor (Inflammatory Disorders/Reperfusion Injury), Evolutec"],
  },
  {
    primary_id: "D0H6WG",
    names: ["1-(2-Mercaptoethyl)-3-(M-Tolylsulfonyl)Urea", "CHEMBL459135"],
  },
  {
    primary_id: "D0H6WV",
    names: ["Tubercidin"],
  },
  {
    primary_id: "D0H6XN",
    names: ["Geminally-Substituted Cyanoethylpypazolo Pyridone Derivative 1"],
  },
  {
    primary_id: "D0H6YF",
    names: [
      "2-Iodo-Melatonin",
      "2-Iodomelatonin",
      "93515-00-5",
      "N-[2-(2-Iodo-5-Methoxy-1h-Indol-3-Yl)Ethyl]acetamide",
      "CHEMBL289233",
      "N-(2-(2-Iodo-5-Methoxy-1H-Indol-3-Yl)Ethyl)Acetamide",
      "Acetamide,n-[2-(2-Iodo-5-Methoxy-1h-Indol-3-Yl)Ethyl]-",
      "Acetamide, N-(2-(2-Iodo-5-Methoxy-1H-Indol-3-Yl)Ethyl)-, (3beta)-",
      "Acetamide, N-(2-(2-Iodo-5-Methoxy-1H-Indol-3-Yl)Ethyl)-",
      "SR-01000075935",
      "2-I-MLT",
      "Melatonin,2-Iodo",
      "ML2",
      "Tocris-0737",
      "Lopac-I-1899",
      "AC1L3GZ8",
      "AC1Q5P6Z",
      "Lopac0_000610",
      "SCHEMBL163451",
      "GTPL1343",
      "BDBM29611",
      "CTK8F4316",
      "DTXSID30239462",
      "IODOMELATONIN",
    ],
  },
  {
    primary_id: "D0H6YH",
    names: ["N-Cyclohexylquinolin-4-Amine", "CHEMBL449616", "BDBM50278115"],
  },
  {
    primary_id: "D0H6YK",
    names: ["MK-3614"],
  },
  {
    primary_id: "D0H6YL",
    names: ["(LTB4-(Csa)4)2-Glu-H Conjugate", "CHEMBL412510", "SCHEMBL5052860"],
  },
  {
    primary_id: "D0H6ZA",
    names: ["Pritumumab"],
  },
  {
    primary_id: "D0H6ZC",
    names: ["RWJ-387273"],
  },
  {
    primary_id: "D0H6ZP",
    names: ["IN-1166"],
  },
  {
    primary_id: "D0H7AC",
    names: ["PF-03814735"],
  },
  {
    primary_id: "D0H7AM",
    names: ["MEDI1873"],
  },
  {
    primary_id: "D0H7AR",
    names: ["MK-51"],
  },
  {
    primary_id: "D0H7AV",
    names: ["Pilsicainide"],
  },
  {
    primary_id: "D0H7AZ",
    names: ["Aminocandin"],
  },
  {
    primary_id: "D0H7BF",
    names: ["H3B-8800"],
  },
  {
    primary_id: "D0H7BH",
    names: ["GNF-PF-3955"],
  },
  {
    primary_id: "D0H7CG",
    names: ["ISIS 32020"],
  },
  {
    primary_id: "D0H7DM",
    names: ["IMX-110"],
  },
  {
    primary_id: "D0H7DQ",
    names: ["K00248"],
  },
  {
    primary_id: "D0H7EB",
    names: ["H-Arg-Arg-Pro-Tyr-N-Me-Ile-Leu-OH", "CHEMBL503059"],
  },
  {
    primary_id: "D0H7FP",
    names: ["N-Alkyl Urea Hydroxamic Acids"],
  },
  {
    primary_id: "D0H7FW",
    names: ["YF-476"],
  },
  {
    primary_id: "D0H7GH",
    names: ["ETH615"],
  },
  {
    primary_id: "D0H7GQ",
    names: ["NS 1209"],
  },
  {
    primary_id: "D0H7HH",
    names: ["PMID25399762-Compound-Figure3-Lycojaponicumin C"],
  },
  {
    primary_id: "D0H7JC",
    names: ["GL-2045"],
  },
  {
    primary_id: "D0H7JE",
    names: ["RS-100302"],
  },
  {
    primary_id: "D0H7JJ",
    names: ["Sodium Sulfamate"],
  },
  {
    primary_id: "D0H7KF",
    names: ["Quetiapine"],
  },
  {
    primary_id: "D0H7KP",
    names: ["(3-Amino-1-Hydroxy-Propyl)-Methyl-Phosphinic Acid"],
  },
  {
    primary_id: "D0H7KQ",
    names: ["ABBV-8E12", "C2N-8E12"],
  },
  {
    primary_id: "D0H7LN",
    names: ["Lx-TB-PstS1"],
  },
  {
    primary_id: "D0H7MB",
    names: ["Nextomabs"],
  },
  {
    primary_id: "D0H7MF",
    names: ["(5-Fluoro-1H-Indol-2-Yl)-(1H-Indol-2-Yl)Methanone", "CHEMBL209042"],
  },
  {
    primary_id: "D0H7MV",
    names: [
      "Dual Attack Synbiotics",
      "Dual Attack Synbiotics (Bacterial Infections)",
      "Dual Attack Synbiotics (Bacterial Infections), Contrafect",
      "Recombinant Lysin + Antibiotics (Bacterial Infections), ContraFect",
    ],
  },
  {
    primary_id: "D0H7NB",
    names: ["5-(4-Chloro-Phenyl)-1-Methyl-Piperidine-2-Thione", "CHEMBL56517", "BDBM50091711"],
  },
  {
    primary_id: "D0H7NV",
    names: ["7beta, 27-Dihydroxycholesterol", "7beta,26-Dihydroxycholesterol"],
  },
  {
    primary_id: "D0H7NY",
    names: ["FGGFTGARKSARKAANQ"],
  },
  {
    primary_id: "D0H7OC",
    names: ["Piperazine Carbamate/Urea Derivative 4"],
  },
  {
    primary_id: "D0H7OO",
    names: ["PDS-0102"],
  },
  {
    primary_id: "D0H7OP",
    names: ["N-Allylnorlitebamine"],
  },
  {
    primary_id: "D0H7PC",
    names: ["SB-357134"],
  },
  {
    primary_id: "D0H7PI",
    names: ["2-N-Cyclopropylamino-1-(4-Methylthiophenyl)Butane", "CHEMBL1078818"],
  },
  {
    primary_id: "D0H7PR",
    names: ["4,5,7-Trichloro-3-Hydroxy-1H-Quinolin-2-One", "CHEMBL147690"],
  },
  {
    primary_id: "D0H7PS",
    names: ["MCT-175"],
  },
  {
    primary_id: "D0H7PW",
    names: ["PIPERINE"],
  },
  {
    primary_id: "D0H7QA",
    names: ["N-Hydroxy-N'-(3-Methylphenyl)Octanediamide", "CHEMBL1089339", "BDBM50314136"],
  },
  {
    primary_id: "D0H7QU",
    names: ["BMS 791325", "BMS-791325"],
  },
  {
    primary_id: "D0H7RH",
    names: ["ANTIOQUINE", "CHEMBL452904", "Antioquine (S,R)", "SCHEMBL6044740"],
  },
  {
    primary_id: "D0H7RN",
    names: ["PD-135146"],
  },
  {
    primary_id: "D0H7RV",
    names: [
      "[3H]SB-733993",
      "[3H]-(2R)-3-[(2R,6S)-2,6-Dimethylpiperidin-1-Yl]-2-Hydroxy-S-(Naphthalen-1-Yl)Propane-1-Sulfonamido",
      "[3H]-SB-733993",
    ],
  },
  {
    primary_id: "D0H7SG",
    names: ["PV-327"],
  },
  {
    primary_id: "D0H7SO",
    names: ["Heteroaryl-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0H7TB",
    names: ["18F Labeled Ethanolamine Derivatives (Cancer Imaging), Bayer Schering"],
  },
  {
    primary_id: "D0H7TD",
    names: [
      "CK-2018509",
      "CK-165",
      "CK-2017473",
      "CK-2018448",
      "CK-2018571",
      "CK-2018885",
      "CK-2018887",
      "CK-2019165",
      "CK-448",
      "Smooth Muscle Myosin Inhibitors (Hypertension), Cytokinetics",
      "Smooth Muscle Myosin Inhibitors (Respiratory Disorders), Cytokinetics",
      "Smooth Muscle Myosin Inhibitors (Respiratory Disorders/Hypertension), Cytokinetics",
    ],
  },
  {
    primary_id: "D0H7TS",
    names: ["Mesenchymal Stem Cell Therapy"],
  },
  {
    primary_id: "D0H7UQ",
    names: ["N-Hydroxy-3-Naphthalen-2-Yl-Propionamide", "CHEMBL154998", "SCHEMBL10362477", "BDBM50015120"],
  },
  {
    primary_id: "D0H7UR",
    names: ["Mannoside Derivative 11"],
  },
  {
    primary_id: "D0H7VA",
    names: ["Wnt Stimulator , Osteoporosis"],
  },
  {
    primary_id: "D0H7WA",
    names: ["FCP-3P1", "CardioRex", "Antihyperlipidemic, Forbes Medi-Tech"],
  },
  {
    primary_id: "D0H7WK",
    names: [
      "SNAP-5114",
      "157604-55-2",
      "(S)-SNAP-5114",
      "(S)-SNAP 5114",
      "CHEMBL75035",
      "(S)-1-[2-[TRIS(4-METHOXYPHENYL)METHOXY]ETHYL]-3-PIPERIDINECARBOXYLIC ACID",
      "GTPL4677",
      "SCHEMBL18187570",
      "CTK0H0672",
      "DTXSID40440224",
      "MolPort-003-983-686",
      "HMS3268E15",
      "ZINC3995954",
      "MFCD06411702",
      "BN0494",
      "BDBM50039246",
      "AKOS024456666",
      "NCGC00025217-02",
      "B6792",
      "SR-01000597587",
      "(S)-SNAP-5114, &gt",
      "SR-01000597587-1",
      "(S)-1-(2-(Tris(4-Methoxyphenyl)Methoxy)Ethyl)Piperidine-3-Carboxylic Acid",
      "(3S)-1-{2-[tris(4-Methoxyphenyl)Methoxy]ethyl}piperidine-3-Carboxylic",
    ],
  },
  {
    primary_id: "D0H7XJ",
    names: ["ASP-9521"],
  },
  {
    primary_id: "D0H7XL",
    names: ["OSI-027"],
  },
  {
    primary_id: "D0H7YU",
    names: ["AviFlu"],
  },
  {
    primary_id: "D0H7ZB",
    names: ["SX-013"],
  },
  {
    primary_id: "D0H7ZN",
    names: ["VM-206"],
  },
  {
    primary_id: "D0H7ZS",
    names: ["N-[4'-(Ethylsulfonyl)-3-Fluoro-4-Biphenylyl]urea", "CHEMBL244512", "BDBM50220161"],
  },
  {
    primary_id: "D0H8AI",
    names: [
      "NEUROTENSIN",
      "CHEMBL407196",
      "55508-42-4",
      "58889-67-1",
      "Neurotensin (Ox)",
      "(D-Tyr11)Neurotensin",
      "Neurotensin 1-13",
      "11-Tyr-Neurotensin",
      "Neurotensin, Tyr(11)-",
      "Neurotensin, Tyrosine(11)-",
      "GTPL1579",
      "Neurotensin, &gt",
      "MolPort-016-581-032",
      "Neurotensin (Ox), 11-D-Tyrosine-",
      "MFCD00076678",
      "BDBM50130880",
      "NCGC00167274-01",
      "NT(1-13)",
      "889N671",
      "PGlu-Leu-Tyr-Glu-Asn-Lys-Pro-Arg-Arg-Pro-Tyr-Ile-Leu",
      "PGlu-Leu-Tyr-Glu-Asn-Lys-Pro-Arg-Arg-Pro-Tyr-Ile-Leu-OH",
    ],
  },
  {
    primary_id: "D0H8AW",
    names: ["GNF-PF-607"],
  },
  {
    primary_id: "D0H8BD",
    names: ["PMA-101R"],
  },
  {
    primary_id: "D0H8BJ",
    names: ["ISIS 122975"],
  },
  {
    primary_id: "D0H8CC",
    names: ["Tert-Butyl (2S)-1-Cyanopyrrolidine-2-Carboxylate", "CHEMBL363610"],
  },
  {
    primary_id: "D0H8DS",
    names: ["Tumor Infiltrating Lymphocyte"],
  },
  {
    primary_id: "D0H8EO",
    names: [
      "3,3-Diethyl-1-Phenylazetidine-2,4-Dione",
      "CHEMBL272413",
      "2,4-Azetidinedione, 3,3-Diethyl-1-Phenyl-",
      "15745-94-5",
      "3,3-Diethyl-1-Phenylazetidin-2,4-Dione",
      "AC1L3FYP",
      "DTXSID30166264",
      "KDXSTYUPRNLDGW-UHFFFAOYSA-N",
      "BDBM50235613",
      "1-Phenyl-3,3-Diethylazetidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0H8FG",
    names: [
      "(+)-(5S,8S,9R,10S)-20-Methoxypuupehenone",
      "CHEMBL463805",
      "Methoxypuupehenone",
      "AC1LCV1Q",
      "BDBM50242010",
      "(4aS,6aS,12aR,12bS)-10-Methoxy-4,4,6a,12b-Tetramethyl-2,3,4a,5,6,12a-Hexahydro-1H-Benzo[a]xanthen-9-One",
      "9H-Benzo[a]xanthen-9-One, 1,2,3,4,4a,5,6,6a,12a,12b-Decahydro-10-Methoxy-4,4,6a,12b-Tetramethyl-, (4aS,6aS,12aR,12bS)-",
      "InChI=1/C22H30O3/C1-20(2)8-6-9-21(3)18(20)7-10-22(4)19(21)12-14-11-17(24-5)15(23)13-16(14)25-22/H11-13,18-19H,6-10H2,1-5H3/T18-,19+,21-,22-/M0/S",
    ],
  },
  {
    primary_id: "D0H8FH",
    names: [
      "Indapamide",
      "Arifon",
      "Bajaten",
      "Cormil",
      "Damide",
      "Fludex",
      "Idapamide",
      "Indaflex",
      "Indamol",
      "Indapamida",
      "Indapamidum",
      "Ipamix",
      "Lozide",
      "Lozol",
      "Metindamide",
      "Natrilix",
      "Natrix",
      "Noranat",
      "Pressurai",
      "Tandix",
      "Tertensif",
      "Veroxil",
      "RHC 2555",
      "SE 1520",
      "USV 2555",
      "Apo-Indapamide",
      "Indapamida [INN-Spanish]",
      "Indapamide (USP)",
      "Indapamidum [INN-Latin]",
      "Lozol (TN)",
      "Natrilix (TN)",
      "Natrix (TN)",
      "Novo-Indapamide",
      "Nu-Indapamide",
      "S-1520",
      "SE-1520",
      "Indapamide (JAN/USP)",
      "Indapamide [USAN:INN:BAN:JAN]",
      "N-[4-Chloro-3-Sulfamoylbenzamido]-2-Methylindoline",
      "NLozol, Arifon, Fludex, Noranat, Veroxil, Tertensif, Indapamide",
      "N-(4-Chloro-3-Sulfamoylbenzamido)-2-Methylindoline",
      "Benzamide, 3-(Aminosulfonyl)-4-Chloro-N-(2,3-Dihydro-2-Methyl-1H-Indol-1-Yl)-(9CI)",
      "1-(4-Chloro-3-Sulfamoylbenzamido)-2-Methylindoline",
      "3-(Aminosulfonyl)-4-Chloro-N-(2,3-Dihydro-2-Methyl-1H-Indol-1-Yl)Benzamide",
      "4-Chloro-N-(2-Methyl-1-Indolinyl)-3-Sulfamoylbenzamide",
      "4-Chloro-N-(2-Methyl-2,3-Dihydroindol-1-Yl)-3-Sulfamoylbenzamide",
    ],
  },
  {
    primary_id: "D0H8FY",
    names: ["Firategrast"],
  },
  {
    primary_id: "D0H8GW",
    names: ["PS-938285"],
  },
  {
    primary_id: "D0H8ID",
    names: ["Tonazocine Mesylate"],
  },
  {
    primary_id: "D0H8IH",
    names: ["PMID26609882-Compound-36"],
  },
  {
    primary_id: "D0H8IT",
    names: ["Methylmalonyl-CoA"],
  },
  {
    primary_id: "D0H8JM",
    names: [
      "VT-214",
      "CBP1",
      "VT-200 Series",
      "VT-239",
      "Non-Selective Chemokine Binding Proteins (Inflammation), Viron",
    ],
  },
  {
    primary_id: "D0H8KG",
    names: ["PD166285"],
  },
  {
    primary_id: "D0H8KI",
    names: ["[125I]GR231118", "[125I]1229U91", "[125I]GW1229"],
  },
  {
    primary_id: "D0H8LZ",
    names: ["Boronic Acid Derivative 6"],
  },
  {
    primary_id: "D0H8MH",
    names: ["HGH-CTP"],
  },
  {
    primary_id: "D0H8MR",
    names: ["CAT-1902"],
  },
  {
    primary_id: "D0H8OA",
    names: ["MK-5442"],
  },
  {
    primary_id: "D0H8OB",
    names: ["Desirudine"],
  },
  {
    primary_id: "D0H8OH",
    names: ["Palovarotene"],
  },
  {
    primary_id: "D0H8PI",
    names: ["6-(4-Hydroxy-Phenyl)-Naphthalen-1-Ol", "6-(4-Hydroxyphenyl)-1-Naphthol"],
  },
  {
    primary_id: "D0H8PX",
    names: ["MORAb-066", "Anti-Tissue Factor Monoclonal Antibody (Pancreatic Tumor), Morphotek"],
  },
  {
    primary_id: "D0H8QL",
    names: ["Mazindol"],
  },
  {
    primary_id: "D0H8RU",
    names: ["N-(4-(Trifluoromethyl)Benzyl)-4-Phenoxybenzamide", "CHEMBL199074"],
  },
  {
    primary_id: "D0H8TE",
    names: ["Z-Arg-Leu-Val-Agly-Ala-Gly-NH2"],
  },
  {
    primary_id: "D0H8UT",
    names: ["Autologous Anti-CD 123 CAR TCR/4-1BB-Expressing T-Lymphocytes"],
  },
  {
    primary_id: "D0H8VM",
    names: ["UAI-201"],
  },
  {
    primary_id: "D0H8VN",
    names: ["PMID28394193-Compound-12"],
  },
  {
    primary_id: "D0H8VY",
    names: ["BMS-201038", "AEGR 733", "AEGR733", "BMS 201038", "BMS 201238", "BMS201038", "AEGR-733", "BMS 201038-01"],
  },
  {
    primary_id: "D0H8WF",
    names: ["Glucagon Recombinant"],
  },
  {
    primary_id: "D0H8WV",
    names: ["Tyr-D-Ala-Phe-Gly-Tyr-Pro-Thr(Beta-D-Glc)-Gly-NH2"],
  },
  {
    primary_id: "D0H8WX",
    names: ["RSV/PIV-3 Vaccine"],
  },
  {
    primary_id: "D0H8WY",
    names: ["PMID19097784C2"],
  },
  {
    primary_id: "D0H8WZ",
    names: ["SR-43845"],
  },
  {
    primary_id: "D0H8XB",
    names: [
      "Lancovutide Inhaled",
      "Duramycin",
      "Lancovutide",
      "Cystic Fibrosis Treatment, Molichem",
      "Moli-1901",
      "Moli-901",
      "Duramycin(Ophthalmic, Dry Eye), Lantibio",
      "Lancovutide (Ophthalmic, Dry Eye), Lantibio",
      "Duramycin (Inhaled, Cystic Fibrosis), Lantibio/AOP Orphan",
      "Duramycin (Inhaled, Cystic Fibrosis), Molichem/AOP Orphan",
      "Lancovutide (Inhaled, Cystic Fibrosis), Lantibio/AOP Orphan",
      "Moli-1901 (Ophthalmic, Dry Eye), Lantibio",
    ],
  },
  {
    primary_id: "D0H8XH",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 14"],
  },
  {
    primary_id: "D0H8XQ",
    names: ["AZ12216052", "AZ 12216052", "AZ-12216052"],
  },
  {
    primary_id: "D0H8XV",
    names: [
      "R-Dimethindene",
      "CHEMBL564226",
      "UNII-C9Z23T6963",
      "C9Z23T6963",
      "(R)-Dimethindene",
      "Dimetindene, (R)-",
      "(R)-(-)-Dimethindene",
      "ZINC1482162",
      "BDBM50297307",
      "UNII-661FH77Z3P Component MVMQESMQSYOVGV-HNNXBMFYSA-N",
      "1H-Indene-2-Ethanamine, N,N-Dimethyl-3-((1R)-1-(2-Pyridinyl)Ethyl)-",
      "1H-Indene-2-Ethanamine, N,N-Dimethyl-3-(1-(2-Pyridinyl)Ethyl)-, (R)-",
    ],
  },
  {
    primary_id: "D0H8ZB",
    names: ["M2 Proton Channel Inhibitors"],
  },
  {
    primary_id: "D0H8ZI",
    names: ["PDS-0101"],
  },
  {
    primary_id: "D0H8ZT",
    names: ["4-(7-Chloro-1-Cyclohexyl-1H-Indazol-3-Yl)Phenol"],
  },
  {
    primary_id: "D0H8ZY",
    names: ["HEPLISAV-B"],
  },
  {
    primary_id: "D0H9AU",
    names: ["PMID25776143-Compound-8"],
  },
  {
    primary_id: "D0H9AV",
    names: ["AcAsp-Glu-Dif-Ile-Cha-Cys-Pro-Cha-Asp-ValNH2"],
  },
  {
    primary_id: "D0H9AX",
    names: ["L-736380"],
  },
  {
    primary_id: "D0H9BL",
    names: [
      "Clinopodic Acid C",
      "CHEMBL1080779",
      "BDBM50310832",
      "(R)-3-(3,4-Dihydroxyphenyl)-2-[3-[2beta-(3,4-Dihydroxyphenyl)-3beta-Carboxy-2,3-Dihydro-1,4-Benzodioxin-6-Yl]propenoyloxy]propionic Acid",
    ],
  },
  {
    primary_id: "D0H9BP",
    names: ["Xanthine Amine Congener"],
  },
  {
    primary_id: "D0H9DA",
    names: ["AZD6738"],
  },
  {
    primary_id: "D0H9DB",
    names: ["8-Biphenyl-2-Yl-2-Morpholin-4-Ylchromen-4-One", "CHEMBL425391"],
  },
  {
    primary_id: "D0H9DE",
    names: ["CS-722"],
  },
  {
    primary_id: "D0H9DJ",
    names: [
      "Uridine-5'-Monophosphate",
      "5'-URIDYLIC ACID",
      "Uridine 5'-Monophosphate",
      "Uridylic Acid",
      "Uridine Monophosphate",
      "58-97-9",
      "Uridine Phosphate",
      "Uridine 5'-Phosphate",
      "Uridine 5'-Phosphoric Acid",
      "5'-UMP",
      "UMP",
      "UMP (Nucleic Acid)",
      "Uridine 5'-(Dihydrogen Phosphate)",
      "5'Uridylic Acid",
      "Uridylate",
      "UNII-E2OU15WN0N",
      "BRN 0047486",
      "CHEBI:16695",
      "Uridine 5-Monophosphate",
      "EINECS 200-408-0",
      "E2OU15WN0N",
      "CHEMBL214393",
      "U 5'-P",
      "AK113761",
      "27416-86-0",
      "2,4(1H,3H)-Pyrimidinedione, 1-(5-O-Phosphono-Beta-D-Ribofuranosyl)-",
      "Polyuridylic Acids",
    ],
  },
  {
    primary_id: "D0H9DS",
    names: ["8-Phenyl-Octanoic Acid Hydroxyamide", "CHEMBL123624", "N-Hydroxy-8-Phenyloctanamide", "SCHEMBL5807174"],
  },
  {
    primary_id: "D0H9EY",
    names: ["2'-Me-CCPA", "2'methyl-2-Chloro-N6-Cyclopentyladenosine"],
  },
  {
    primary_id: "D0H9FR",
    names: ["PRN1371"],
  },
  {
    primary_id: "D0H9FX",
    names: ["HCV-371"],
  },
  {
    primary_id: "D0H9GH",
    names: ["31.1Chi"],
  },
  {
    primary_id: "D0H9GP",
    names: ["PD-135111"],
  },
  {
    primary_id: "D0H9GQ",
    names: [
      "DOV-216303",
      "66504-40-3",
      "CHEMBL528995",
      "3-Azabicyclo[3.1.0]hexane, 1-(3,4-Dichlorophenyl)-",
      "SCHEMBL79279",
      "SCHEMBL9604846",
      "CTK1J4675",
      "MolPort-035-942-269",
      "BSMNRYCSBFHEMQ-UHFFFAOYSA-N",
      "DOV-2947",
      "BDBM50243895",
      "AKOS025396236",
      "FCH1601649",
      "SB10890",
      "KB-50426",
      "AS-51627",
      "AK173863",
      "CS-0052898",
      "KS-00000461",
    ],
  },
  {
    primary_id: "D0H9HT",
    names: ["TAK-701"],
  },
  {
    primary_id: "D0H9IH",
    names: ["(R)-1-(3-Methoxyphenethyl)-2-Methylpyrrolidine", "CHEMBL439429"],
  },
  {
    primary_id: "D0H9IX",
    names: ["PMID28870136-Compound-52"],
  },
  {
    primary_id: "D0H9KW",
    names: ["Filaminast"],
  },
  {
    primary_id: "D0H9MA",
    names: ["COR-1"],
  },
  {
    primary_id: "D0H9MR",
    names: ["MP-PN-2"],
  },
  {
    primary_id: "D0H9NO",
    names: ["NM-4654"],
  },
  {
    primary_id: "D0H9NZ",
    names: ["(R/S)-2-((4-Benzylphenoxy)Methyl)Piperazine", "CHEMBL1098820"],
  },
  {
    primary_id: "D0H9OT",
    names: ["PfAMA-1"],
  },
  {
    primary_id: "D0H9PT",
    names: ["RG3638"],
  },
  {
    primary_id: "D0H9QA",
    names: ["Gal-B5"],
  },
  {
    primary_id: "D0H9QC",
    names: ["S-474474"],
  },
  {
    primary_id: "D0H9QG",
    names: ["SB-258719"],
  },
  {
    primary_id: "D0H9QN",
    names: ["AZ-TAK1"],
  },
  {
    primary_id: "D0H9QX",
    names: ["FM-TP4000"],
  },
  {
    primary_id: "D0H9RD",
    names: [
      "DZ-2640",
      "(1R,5S,6S)-2-[6,7-Dihydro-5H-Pyrrolo[1,2-A]imidazol-6(S)-Ylsulfanyl]-6-[1(R)-Hydroxyethyl]-1-Methyl-1-Carba-2-Penem-3-Carboxylic Acid Pivaloyloxymethyl Ester",
    ],
  },
  {
    primary_id: "D0H9RN",
    names: ["NU-172"],
  },
  {
    primary_id: "D0H9RP",
    names: ["Lactam Derivative 3"],
  },
  {
    primary_id: "D0H9SD",
    names: ["PMID30280939-Compound-WO2013148426"],
  },
  {
    primary_id: "D0H9SF",
    names: ["A-76202M", "A 76202"],
  },
  {
    primary_id: "D0H9SX",
    names: ["Daporinad", "APO-866", "FK-866", "WK-175"],
  },
  {
    primary_id: "D0H9TO",
    names: ["3-Pyridin-4-Yl-Quinolin-7-Ol"],
  },
  {
    primary_id: "D0H9UT",
    names: [
      "Carbamic Acid",
      "Aminoformic Acid",
      "463-77-4",
      "UNII-O0UC6XOS4H",
      "O0UC6XOS4H",
      "CHEBI:28616",
      "OUT",
      "Carbamidsaeure",
      "Aminoformate",
      "Ammoniocarboxylate",
      "Aminoameisensaeure",
      "Imidocarbonic Acid",
      "Aminomethanoic Acid",
      "Aminocarboxylic Acid",
      "AC1L18VM",
      "CHEMBL125278",
      "DTXSID5048009",
      "CTK1D6698",
      "1111-78-0 (Ammonium Salt)",
      "KXDHJXZQYSOELW-UHFFFAOYSA-N",
      "4366-93-2 (Potassium Salt)",
      "ZINC8383199",
      "BDBM50369454",
      "ABP000336",
      "Carbamic Acid(6CI,7CI,8CI,9CI)",
      "ACM463774",
      "AKOS006223007",
      "DB04261",
      "NCGC00166327-01",
      "LS-69662",
      "KB-75881",
      "DB-011502",
      "FT-0689176",
    ],
  },
  {
    primary_id: "D0H9VP",
    names: ["MP-4-CO", "Carbon Monoxide Therapy (Sickle Cell Anemia), Sangart"],
  },
  {
    primary_id: "D0H9VQ",
    names: ["L-Norleucyl-L-Prolinamide", "CHEMBL388843"],
  },
  {
    primary_id: "D0H9VW",
    names: ["(Z)-5-Decylidene-2-Thioxothiazolidin-4-One"],
  },
  {
    primary_id: "D0H9WO",
    names: ["Gantofiban"],
  },
  {
    primary_id: "D0H9WR",
    names: ["LX-1031"],
  },
  {
    primary_id: "D0H9WY",
    names: ["PAZ-320"],
  },
  {
    primary_id: "D0H9XR",
    names: [
      "SC-435",
      "ASBT Inhibitors, Pfizer",
      "ASBT Inhibitors, Pharmacia",
      "ASBT Inhibitors, Satiogen",
      "Bile Acid Transporter Inhibitors, Pfizer",
      "Bile Acid Transporter Inhibitors, Pharmacia",
      "Hypercholesterolemia Therapeutics, Pfizer",
      "Hypercholesterolemia Therapeutics, Pharmacia",
      "SC-635",
    ],
  },
  {
    primary_id: "D0H9YO",
    names: ["FLUASTERONE"],
  },
  {
    primary_id: "D0H9ZQ",
    names: [
      "Dexpanthenol",
      "D-Pantothenol",
      "(2S)-2,4-Dihydroxy-N-(3-Hydroxypropyl)-3,3-Dimethylbutanamide",
      "L-Pantothenol",
      "CAS-81-13-0",
      "(?)-Panthenol",
      "AC1NX8S7",
      "KSC490G8B",
      "SCHEMBL807508",
      "CHEMBL1741351",
      "CTK3J0380",
      "SNPLKNRPJHDVJA-SSDOTTSWSA-N",
      "ZINC1567243",
      "ANW-37417",
      "AKOS024438122",
      "RTX-012330",
      "NCGC00016326-01",
      "AJ-27156",
      "CJ-05387",
      "UNII-WV9CM0O67Z Component SNPLKNRPJHDVJA-SSDOTTSWSA-N",
    ],
  },
  {
    primary_id: "D0HA0J",
    names: ["PMID27376512-Compound-MTC-427"],
  },
  {
    primary_id: "D0HA0U",
    names: [
      "12-Dehydroxyriccardin C",
      "Riccardin C Derivative, 20c",
      "CHEMBL260632",
      "BDBM23850",
      "2',4-[Ethylene-1,4-Phenylene-Oxy-(6-Hydroxy-1,3-Phenylene)-Ethylene]-1,1'-Biphenyl-4'-Ol",
    ],
  },
  {
    primary_id: "D0HA1K",
    names: [
      "N1-[4-(Phenylmethoxy)Phenyl]-L-Glutamine",
      "Amino Acid Analog, 4a",
      "CHEMBL478550",
      "BDBM24251",
      "(4S)-4-Amino-4-{[4-(Benzyloxy)Phenyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D0HA2R",
    names: [
      "BBT-012",
      "BBT-006",
      "Long-Acting Interferon Alpha (PEGylated, Viral Infections/Cancer), Bolder BioTechnology",
    ],
  },
  {
    primary_id: "D0HA6Y",
    names: ["[11C]SB207145", "[11C] SB207145 (PET Ligand)"],
  },
  {
    primary_id: "D0HA7E",
    names: ["TD-Pur"],
  },
  {
    primary_id: "D0HB0V",
    names: ["JX-594"],
  },
  {
    primary_id: "D0HB2F",
    names: [
      "ABD-035",
      "Albumin Binding Domain Molecules (Cancer, Albumod), Affibody",
      "Anti-HER-2 Albubody (Cancer), Affibody",
    ],
  },
  {
    primary_id: "D0HB3F",
    names: ["Pyrimido-Indole Derivative 1"],
  },
  {
    primary_id: "D0HB4E",
    names: ["INCB47986"],
  },
  {
    primary_id: "D0HB5V",
    names: ["CN-2097"],
  },
  {
    primary_id: "D0HB6O",
    names: ["H-Cdp-Gly-Gly-Phe-Leu-OH", "CHEMBL246739"],
  },
  {
    primary_id: "D0HB9G",
    names: [
      "2,3,5,6-Tetrafluoro-4-Methoxy-Benzamide",
      "2,3,5,6-TETRAFLUORO-4-METHOXY-BENZAMIDE",
      "2,3,5,6-Tetrafluoro-4-Methoxybenzamide",
      "C8H5F4NO2",
      "BEK",
      "AC1LCVZS",
      "SCHEMBL1002032",
      "ZINC12501151",
      "AKOS000299242",
    ],
  },
  {
    primary_id: "D0HC3H",
    names: [
      "Dimepheptanol",
      "Methadol",
      "Racemethadol",
      "Pangerin",
      "Bimethadol",
      "545-90-4",
      "NIH 2933",
      "Betamethadol",
      "Betametadol",
      "6-DIMETHYLAMINO-4,4-DIPHENYL-3-HEPTANOL",
      "3-HEPTANOL, 6-(DIMETHYLAMINO)-4,4-DIPHENYL-",
      "Dimepheptanolum",
      "Dimefeptanol",
      "Dimefeptanolo",
      "Bimethadolum",
      "(-)-Beta-Methadol",
      "Betametadolo [DCIT]",
      "(3S,6R)-Methadol",
      "Beta-Methadol, (-)-",
      "Betametadol [INN-Spanish]",
      "Dimefeptanolo [DCIT]",
      "Betamethadolum [INN-Latin]",
      "3-Heptanol,4-Diphenyl-, Hydrochloride",
      "Betamethadol [INN:BAN:DCF]",
      "DEA No. 9609",
      "Dimefeptanol [INN-Spanish]",
      "Dimepheptanolum [IN",
    ],
  },
  {
    primary_id: "D0HC5P",
    names: ["1,2,4-Triazole [1,5-A]pyrimidin-7-Yl Derivative 3"],
  },
  {
    primary_id: "D0HC8Q",
    names: ["PN-200", "Antiviral (Genital Herpes), PharmaNova", "PN-200, PharmaNova"],
  },
  {
    primary_id: "D0HC9K",
    names: ["PMID30107136-Compound-Example41"],
  },
  {
    primary_id: "D0HC9P",
    names: [
      "Kaempferol-3-O-(2''-O-Galloyl)-Glucoside",
      "CHEMBL444191",
      "76343-90-3",
      "AC1NUQQD",
      "Astragalin 2''-Gallate",
      "SCHEMBL12470746",
      "BDBM50004199",
      "AKOS030532822",
      "4H-1-Benzopyran-4-One, 5,7-Dihydroxy-2-(4-Hydroxyphenyl)-3-((2-O-(3,4,5-Trihydroxybenzoyl)-Beta-D-Glucopyranosyl)Oxy)-",
      "[(2S,3R,4S,5S,6R)-2-[5,7-Dihydroxy-2-(4-Hydroxyphenyl)-4-Oxochromen-3-Yl]oxy-4,5-Dihydroxy-6-(Hydroxymethyl)Oxan-3-Yl] 3,4,5-Trihydroxybenzoate",
    ],
  },
  {
    primary_id: "D0HC9U",
    names: ["4-Imidazol-1-Ylmethyl-2-Nitroxanthen-9-One", "CHEMBL1083663"],
  },
  {
    primary_id: "D0HD0G",
    names: [
      "SB3",
      "Antimony Cation (3+)",
      "Antimony(3+)",
      "UNII-069647RPT5",
      "ANTIMONY (III) ION",
      "069647RPT5",
      "23713-48-6",
      "Antimony(3+) Ion",
      "TRIVALENT ANTIMONY",
      "Antimony Element",
      "ANTIMONY CATION (3 )",
      "Antimony(III) Cation",
      "AC1L2XOH",
      "Sb(3+)",
      "CHEBI:49867",
      "CTK8D9770",
      "DTXSID10178380",
      "ANTIMONY (III) OXIDE, 99.9%,",
    ],
  },
  {
    primary_id: "D0HD2G",
    names: ["Phloretin"],
  },
  {
    primary_id: "D0HD6C",
    names: ["Tetra-Hydro-1H-1,2,6-Triazaazulene Derivative 1"],
  },
  {
    primary_id: "D0HD7C",
    names: ["PPA-904"],
  },
  {
    primary_id: "D0HD7S",
    names: ["Pyrrole Derivative 6"],
  },
  {
    primary_id: "D0HD8F",
    names: ["Pyrazole Derivative 76"],
  },
  {
    primary_id: "D0HD8X",
    names: ["ISF35"],
  },
  {
    primary_id: "D0HD9K",
    names: [
      "Acebutolol",
      "Acebrutololum",
      "Acebutololo",
      "Acebutololum",
      "Acetobutolol",
      "Neptal",
      "Prent",
      "Sectral",
      "Acebutolol HCL",
      "RP 21823",
      "Acebrutololum [INN-Latin]",
      "Acebutololum [INN-Latin]",
      "Acetobutolol [INN-Spanish]",
      "Dl-Acebutolol",
      "M & B 17803A",
      "Prent (TN)",
      "Sectral (TN)",
      "Acebutolol (USAN/INN)",
      "Acebutolol [USAN:INN:BAN]",
      "M&B-17803 A",
      "N-(3-Acetyl-4-[2-Hydroxy-3-(Isopropylamino)Propoxy]phenyl)Butanamide",
      "N-[3-Acetyl-4-[2-Hydroxy-3-[(1-Methylethyl)Amino]propoxy]phenyl]butanamide",
      "N-[3-Acetyl-4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl]butanamide",
      "N-{3-Acetyl-4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl}butanamide",
      "(+-)-3'-Acetyl-4'-(2-Hydroxy-3-(Isopropylamino)Propoxy)Butyranilide",
      "(+-)-Acebutolol",
      "(+-)-N-(3-Acetyl-4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)Phenyl)Butanamide",
      "1-(2-Acetyl-4-N-Butyramidophenoxy)-2-Hydroxy-3-Isopropylaminopropane",
      "3'-(Acetyl-4'-(2-Hydroxy-3-(Isopropylamino)Propoxy)Butyranilide",
      "3'-Acetyl-4'-(2-Hydroxy-3-(Isopropylamino)Propoxy)Butyranilide",
      "3'-Acetyl-4'-(2-Hydroxy-3-Isopropylaminopropoxy)Butyranilid",
      "5'-Butyramido-2'-(2-Hydroxy-3-Isopropylaminopropoxy)Acetophenone",
    ],
  },
  {
    primary_id: "D0HD9P",
    names: [
      "Arachidonic Acid",
      "506-32-1",
      "Arachidonate",
      "(5Z,8Z,11Z,14Z)-Icosa-5,8,11,14-Tetraenoic Acid",
      "Immunocytophyte",
      "(All-Z)-5,8,11,14-Eicosatetraenoic Acid",
      "Cis-5,8,11,14-Eicosatetraenoic Acid",
      "5,8,11,14-Eicosatetraenoic Acid, (All-Z)-",
      "5Z,8Z,11Z,14Z-Eicosatetraenoic Acid",
      "UNII-27YG812J1I",
      "All-Cis-5,8,11,14-Eicosatetraenoic Acid",
      "Arachidonic Acid, 99%",
      "CHEMBL15594",
      "CHEBI:15843",
      "YZXBAPSDXZZRGB-DOFZRALJSA-N",
      "27YG812J1I",
      "Cis,cis,cis,cis-5,8,11,14-Eicosatetraenoic Acid",
      "(5Z,8Z,11Z,14Z)-5,8,11,14-Eicosatetraenoic Acid",
    ],
  },
  {
    primary_id: "D0HE0E",
    names: ["SU4984"],
  },
  {
    primary_id: "D0HE0Y",
    names: [
      "Amifampridine",
      "3,4-DIAMINOPYRIDINE",
      "Pyridine-3,4-Diamine",
      "3,4-Pyridinediamine",
      "Firdapse",
      "Diamino-3,4 Pyridine",
      "4,5-Diaminopyridine",
      "3,4-DAP",
      "SC10",
      "Amifampridin",
      "Zenas",
      "UNII-RU4S6E2G0J",
      "EINECS 200-220-9",
      "NSC 521760",
      "BRN 0110232",
      "RU4S6E2G0J",
      "OYTKINVCDFNREN-UHFFFAOYSA-N",
      "WT559",
      "NCGC00167560-01",
      "SMR000752913",
    ],
  },
  {
    primary_id: "D0HE1P",
    names: ["BAY 60-6583", "BAY-60-6583", "BAY60-6583"],
  },
  {
    primary_id: "D0HE3B",
    names: ["Biphenyl Mannoside Derivative 18"],
  },
  {
    primary_id: "D0HE4S",
    names: [
      "Disulergine",
      "UNII-1Q3CYC1YR6",
      "1Q3CYC1YR6",
      "CHEMBL95067",
      "59032-40-5",
      "Disulergina",
      "Disulerginum",
      "Sulergin",
      "Disulergine[inn]",
      "Disulergine [INN]",
      "Disulerginum [INN-Latin]",
      "Disulergina [INN-Spanish]",
      "Compound 71",
      "SULERGINE",
      "CM 29717",
      "CH 29-717",
      "CH-29717",
      "AC1Q6V4Q",
      "AC1L2AG4",
      "SCHEMBL1841646",
      "BDBM50130260",
      "CH 29717",
      "N,N-Dimethyl-N'-(6-Methylergolin-8alpha-Yl)Sulfamide",
      "N,N-Dimethyl-N'-((8 Alpha)-6-Methylergolin-8-Yl)Sulfamide",
    ],
  },
  {
    primary_id: "D0HE7C",
    names: ["ORIC-101"],
  },
  {
    primary_id: "D0HE7I",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0HE7X",
    names: ["PMID25666693-Compound-11"],
  },
  {
    primary_id: "D0HE8M",
    names: ["1,2,4-Oxadiazole Derivative 5"],
  },
  {
    primary_id: "D0HE9J",
    names: ["APN-201"],
  },
  {
    primary_id: "D0HF0A",
    names: ["DQP-1105"],
  },
  {
    primary_id: "D0HF0I",
    names: ["Tyr-Pro-Phe-Phe-NHCH3", "CHEMBL453689"],
  },
  {
    primary_id: "D0HF0R",
    names: ["Aminopyrimidine Derivative 4"],
  },
  {
    primary_id: "D0HF0W",
    names: ["Levopropoxyphene Napsylate Anhydrous"],
  },
  {
    primary_id: "D0HF1A",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 1"],
  },
  {
    primary_id: "D0HF1N",
    names: ["Ethcathinone"],
  },
  {
    primary_id: "D0HF2D",
    names: ["PBF-1129"],
  },
  {
    primary_id: "D0HF4I",
    names: ["4-(2-(4-Methoxyphenylamino)Ethyl)-1H-Imidazole", "CHEMBL1098527"],
  },
  {
    primary_id: "D0HF7V",
    names: ["Peptide Analog 3"],
  },
  {
    primary_id: "D0HG0F",
    names: ["1,3,5-Triazine Derivative 1"],
  },
  {
    primary_id: "D0HG1W",
    names: ["2-(Substituted Ethynyl)Quinoline Derivative 4"],
  },
  {
    primary_id: "D0HG2S",
    names: ["Sulfonamide Derivative 10"],
  },
  {
    primary_id: "D0HG4U",
    names: ["(R)-(-)-2-Phenyl-Apomorphine Hydrochloride", "CHEMBL458397"],
  },
  {
    primary_id: "D0HG5M",
    names: ["Apratastat"],
  },
  {
    primary_id: "D0HG9P",
    names: ["VentaProst"],
  },
  {
    primary_id: "D0HH0G",
    names: ["ABT-767"],
  },
  {
    primary_id: "D0HH0U",
    names: ["S-488410"],
  },
  {
    primary_id: "D0HH1K",
    names: ["Palmarumycin Derivative 1"],
  },
  {
    primary_id: "D0HH1O",
    names: ["PMID25522065-Compound-14"],
  },
  {
    primary_id: "D0HH2I",
    names: ["CV-9103"],
  },
  {
    primary_id: "D0HH3R",
    names: ["SNAP 398299"],
  },
  {
    primary_id: "D0HH4L",
    names: ["Thiazolidine Derivative 1"],
  },
  {
    primary_id: "D0HH5B",
    names: ["Benzimidazole Derivative 15"],
  },
  {
    primary_id: "D0HH6A",
    names: ["Thromboxane A2"],
  },
  {
    primary_id: "D0HH9N",
    names: ["Cholesten", "Cholestane", "Cholestan", "Alpha-Cholestane"],
  },
  {
    primary_id: "D0HH9P",
    names: ["NCE-401"],
  },
  {
    primary_id: "D0HI0L",
    names: ["HSV-1716"],
  },
  {
    primary_id: "D0HI1K",
    names: ["GSK618334"],
  },
  {
    primary_id: "D0HI1R",
    names: ["PMID27967267-Compound-48"],
  },
  {
    primary_id: "D0HI1T",
    names: ["SAR440181"],
  },
  {
    primary_id: "D0HI2N",
    names: ["D[Cha4,Dab8]VP", "CHEMBL412742"],
  },
  {
    primary_id: "D0HI5B",
    names: ["PSN375963"],
  },
  {
    primary_id: "D0HI5D",
    names: ["Naxitamab"],
  },
  {
    primary_id: "D0HI6O",
    names: ["PAT5A"],
  },
  {
    primary_id: "D0HI8X",
    names: ["Heterocyclic Derivative 13"],
  },
  {
    primary_id: "D0HJ1O",
    names: ["PMID25772215-Compound-US02014179750M1"],
  },
  {
    primary_id: "D0HJ1R",
    names: ["INCB54707"],
  },
  {
    primary_id: "D0HJ1Y",
    names: [
      "BW B70C",
      "BWB70C",
      "BW-B 70C",
      "134470-38-5",
      "BW-B70C",
      "CHEMBL86676",
      "CHEBI:75307",
      "(E)-N-(3-(3-(4-Fluorophenoxy)Phenyl)-1-(R,S)-Methylprop-2-Enyl)-N-Hydroxyurea",
      "70C",
      "N-[3-[3-4(-Fluorophenoxy)Phenyl]-1-Methyl-2-Propenyl]-N-Hydroxyurea",
      "N-(3-(3-(4-Fluorophenoxy)Phenyl)-1-Methyl-2-Propenyl)-N-Hydroxyurea",
      "Urea, N-(3-(3-(4-Fluorophenoxy)Phenyl)-1-Methyl-2-Propenyl)-N-Hydroxy-",
      "C17H17FN2O3",
      "SR-01000075717",
      "(E)-N-{3-[3-(4-Fluorophenoxy)Phenyl]-1-(R,s)-Methylprop-2-Enyl}-N-Hydroxyurea",
      "Lopac0_000202",
      "BSPBio_001512",
      "SCHEMBL2681955",
    ],
  },
  {
    primary_id: "D0HJ2J",
    names: ["Cyclic Octapeptide Derivative 1"],
  },
  {
    primary_id: "D0HJ5H",
    names: [
      "BGC-20-1531",
      "AH-22921",
      "EP4 Antagonist, BTG",
      "EP4 Antagonist, Pharmagene",
      "Migraine Therapy, BTG",
      "Migraine Therapy, Pharmagene",
      "PGN-1531",
      "EP4 Receptor Antagonists (Migraine), Asterand",
      "R-4 (Migraine), Pharmagene",
    ],
  },
  {
    primary_id: "D0HJ5Y",
    names: ["ChronsVax", "Crohn's Disease Vaccine", "IR-777"],
  },
  {
    primary_id: "D0HJ7I",
    names: ["DA-727"],
  },
  {
    primary_id: "D0HJ9U",
    names: ["LAU-0901"],
  },
  {
    primary_id: "D0HK3A",
    names: ["PMID30280939-Compound-WO2013148427"],
  },
  {
    primary_id: "D0HK3N",
    names: ["Belnacasan"],
  },
  {
    primary_id: "D0HK3U",
    names: [
      "AM-156",
      "AM-206",
      "AM-432",
      "CRTH2 Receptor Antagonists (Oral, Allergic Rhinitis)",
      "CRTH2 Receptor Antagonists (Oral, Allergic Rhinitis), Amira Pharmaceuticals",
      "CRTH2 Antagonists (COPD/Asthma/Allergic Rhinitis), Amira",
      "DP2 Antagonists (COPD/Asthma/Allergic Rhinitis), Amira",
      "Prostaglandin D2 (PGD2) Antagonists (COPD/Asthma/Allergic Rhinitis), Amira",
    ],
  },
  {
    primary_id: "D0HK3X",
    names: ["ASF-1057"],
  },
  {
    primary_id: "D0HK5B",
    names: ["SCH-63390"],
  },
  {
    primary_id: "D0HK6O",
    names: ["OT-101"],
  },
  {
    primary_id: "D0HK7B",
    names: ["FGGFTGARKSARKYANQ"],
  },
  {
    primary_id: "D0HK7G",
    names: [
      "DBT-1339",
      "DBTA-1339",
      "DBTAI-1339",
      "DWJ-501",
      "DWK-1339",
      "Beta-Amyloid Aggregation Inhibitors (Alzheimer's), Digital Biotech",
      "Beta-Amyloid Deposition Inhibitors (Alzheimer's), Digital Biotech",
      "Beta-Amyloid Aggregation/Deposition Inhibitors (Alzheimer's), Digital Biotech",
    ],
  },
  {
    primary_id: "D0HK7R",
    names: ["Midafotel", "EAA-494"],
  },
  {
    primary_id: "D0HL1X",
    names: ["ISIS-PKK"],
  },
  {
    primary_id: "D0HL5C",
    names: ["Pyrrolo-Pyrazine Derivative 2"],
  },
  {
    primary_id: "D0HL5N",
    names: ["Trastuzumab Duocarmazine"],
  },
  {
    primary_id: "D0HL6X",
    names: ["PMID28350212-Compound-36"],
  },
  {
    primary_id: "D0HL7Q",
    names: [
      "N-Alpha-Amidino-Tyr(Me)-D-Pro-Gly-Trp-Phe-NH2",
      "CHEMBL243724",
      "BDBM50201411",
      "N Alpha-Amidino-Tyr(Me)-D-Pro-Gly-Trp-Phe-NH2",
    ],
  },
  {
    primary_id: "D0HL9S",
    names: [
      "1-(4-Aminobenzyl)-1H-Imidazole",
      "56643-85-7",
      "4-Imidazol-1-Ylmethylphenylamine",
      "4-(1H-Imidazol-1-Ylmethyl)Aniline",
      "4-((1H-Imidazol-1-Yl)Methyl)Aniline",
      "4-(Imidazol-1-Ylmethyl)Aniline",
      "4-[(1-Imidazolyl)Methyl]aniline",
      "4-Imidazol-1-Ylmethyl-Phenylamine",
      "4-[(1H-Imidazol-1-Yl)Methyl]aniline",
      "1-(4-Aminobenzyl)Imidazole",
      "CHEMBL598389",
      "4-(Imidazolylmethyl)Phenylamine",
      "Benzenamine, 4-(1H-Imidazol-1-Ylmethyl)-",
      "BAS 06504682",
      "AC1LOLP2",
      "SCHEMBL77025",
      "AC1Q520P",
      "CTK5A5496",
      "DTXSID40361425",
      "4-(Imidazol-1-Yl)Methyl-Aniline",
    ],
  },
  {
    primary_id: "D0HM1S",
    names: ["FP-0230"],
  },
  {
    primary_id: "D0HM1V",
    names: ["KYMRIAH"],
  },
  {
    primary_id: "D0HM2S",
    names: ["BTI-320"],
  },
  {
    primary_id: "D0HM4V",
    names: ["PMID29865878-Compound-50"],
  },
  {
    primary_id: "D0HM5L",
    names: ["Breast Cancer Vaccine"],
  },
  {
    primary_id: "D0HM5Q",
    names: ["ND-AMB"],
  },
  {
    primary_id: "D0HM5W",
    names: ["N*4*-(3-Bromo-Phenyl)-Quinazoline-4,6-Diamine"],
  },
  {
    primary_id: "D0HM6B",
    names: [
      "T-121",
      "DHLNQBGKTBJPSZ-SKPGKBGKSA-N",
      "T121",
      "H-Arg-Arg-Trp-Cys(X1)-Tyr-Arg-Lys-Lys-Gly-Tyr-Arg-Lys-Cys(X1)-Arg-OH",
    ],
  },
  {
    primary_id: "D0HM6Q",
    names: ["PMID27539678-Compound-13"],
  },
  {
    primary_id: "D0HM6Y",
    names: ["PMID28092474-Compound-32y"],
  },
  {
    primary_id: "D0HM7G",
    names: ["SUVN-G1031"],
  },
  {
    primary_id: "D0HM8Y",
    names: ["3,8-Dihydroxy-7-Methyl-6H-Benzo[c]chromen-6-One", "CHEMBL206226"],
  },
  {
    primary_id: "D0HN0R",
    names: ["2-Cyclohexylamido-5-Sulfonamidoindane", "CHEMBL204990", "Indanesulfonamide Derivative 12b", "BDBM11033"],
  },
  {
    primary_id: "D0HN2M",
    names: ["UFP-112", "[(PF)Phe4Aib7Arg14Lys15]N/OFQ-NH2", "UFP112", "UFP 112"],
  },
  {
    primary_id: "D0HN2U",
    names: ["JMV 1535"],
  },
  {
    primary_id: "D0HN6Q",
    names: ["RG-7348"],
  },
  {
    primary_id: "D0HN6X",
    names: ["SKYFlu"],
  },
  {
    primary_id: "D0HN7B",
    names: ["COR-001"],
  },
  {
    primary_id: "D0HN7I",
    names: ["Alacizumab Pegol", "CDP-791"],
  },
  {
    primary_id: "D0HO0I",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 12"],
  },
  {
    primary_id: "D0HO0W",
    names: [
      "L-5-Iodo-2'-Deoxyuridine",
      "CHEMBL408518",
      "URIDINE, 2'-DEOXY-5-IODO-",
      "AC1LAEAG",
      "SCHEMBL51859",
      "XQFRJNBWHJMXHO-XVMARJQXSA-N",
      "ZINC5223557",
      "BDBM50375780",
      "FT-0620507",
      "5-Iodo-1-(2-Deoxy-Alpha-D-Ribofuranosyl)Uracil",
    ],
  },
  {
    primary_id: "D0HO1A",
    names: ["CD19 CAR Gene Transduced T Lymphocytes"],
  },
  {
    primary_id: "D0HO1O",
    names: [
      "2ewy",
      "N-{(1s,2r)-1-Benzyl-2-Hydroxy-3-[(3-Methylbenzyl)Amino]propyl}dibenzo[b,F]oxepine-10-Carboxamide",
      "GTPL8688",
      "DBO",
      "Hydroxyethylamine Transition-State Inhibitor 1",
      "N-[(2S,3R)-3-Hydroxy-4-[(3-Methylphenyl)Methylamino]-1-Phenylbutan-2-Yl]benzo[b][1]benzoxepine-6-Carboxamide",
    ],
  },
  {
    primary_id: "D0HO1S",
    names: [
      "CGP-53353",
      "145915-60-2",
      "CGP 53353",
      "DAPH 2",
      "DAPH-7",
      "CGP53353",
      "PKCbetaII/EGFR Inhibitor",
      "5,6-BIS[(4-FLUOROPHENYL)AMINO]-1H-ISOINDOLE-1,3(2H)-DIONE",
      "CHEMBL7939",
      "CGP 53 353",
      "4,5-Bis(4-Fluoroanilino)-Phthalimide",
      "CG53353",
      "5,6-Bis((4-Fluorophenyl)Amino)Isoindoline-1,3-Dione",
      "5,6-Bis[(4-Fluorophenyl)Amino]-2H-Isoindole-1,3-Dione",
      "4,5-Bis(4-Fluoroanilino)Phthalimide",
      "4,5-Bis[4-Fluoroanilino]phthalimide",
      "PKCbII/EGFR Inhibitor",
      "AC1O8KXF",
      "5,6-Bis(4-Fluoroanilino)Isoindole-1,3-Dione",
      "MolMap_000016",
      "DAPH-2",
      "CGP-53353, Solid",
      "SCHEMBL230492",
    ],
  },
  {
    primary_id: "D0HO2T",
    names: [
      "AZD7325",
      "AZD-7325",
      "UNII-KNM216XOUH",
      "KNM216XOUH",
      "CHEMBL1783282",
      "942437-37-8",
      "KYDURMHFWXCKMW-UHFFFAOYSA-N",
      "SCHEMBL1962584",
      "GTPL7712",
      "AZD 7325",
      "BDBM50418481",
      "SB17497",
      "4-Amino-8-(2-Fluoro-6-Methoxyphenyl)-N-Propylcinnoline-3-Carboxamide",
      "4-Amino-8-(2-Fluoro-6-Methoxy-Phenyl)-N-Propylcinnoline-3-Carboxamide",
    ],
  },
  {
    primary_id: "D0HO5B",
    names: ["Indoline Derivative 14"],
  },
  {
    primary_id: "D0HO7I",
    names: [
      "ANONAINE",
      "Anonaine",
      "(-)-Anonaine",
      "Anonain",
      "(-)-Annonaine",
      "1862-41-5",
      "CHEBI:76",
      "(7ar)-6,7,7a,8-Tetrahydro-5h-[1,3]benzodioxolo[6,5,4-De]benzo[g]quinoline",
      "CHEMBL401798",
      "AC1L4NWO",
      "AC1Q6ZR1",
      "SCHEMBL15800856",
      "CTK4D9192",
      "DTXSID00171865",
      "BDBM50202322",
      "C09339",
      "A15375",
      "5H-Benzo[g]-1,3-Benzodioxolo[6,5,4-De]quinoline,6,7,7a,8-Tetrahydro-, (7aR)-",
      "(R)-6,7,7a,8-Tetrahydro-5H-Benzo[g][1,3]dioxolo[4'',5'':4,5]benzo[1,2,3-De]quinoline",
      "5H-Benzo(G)-1,3-Benzodioxolo(6,5,4-De)Quinoline, 6,7,7a,8-Tetrahydro-, (R)-",
    ],
  },
  {
    primary_id: "D0HO8C",
    names: ["Urea And Carbamate Bioisostere Derivative 2"],
  },
  {
    primary_id: "D0HO8V",
    names: ["Pyrrolo-Pyrrolone Derivative 4"],
  },
  {
    primary_id: "D0HO9M",
    names: ["AV-2"],
  },
  {
    primary_id: "D0HP1O",
    names: ["AF-DX-384"],
  },
  {
    primary_id: "D0HP2B",
    names: ["Coxsackievirus"],
  },
  {
    primary_id: "D0HP2H",
    names: ["2-Fluorophenyl 4-(Decyloxy)Phenylcarbamate", "CHEMBL591825", "SCHEMBL5218224"],
  },
  {
    primary_id: "D0HP3M",
    names: ["BILD-1263"],
  },
  {
    primary_id: "D0HP5B",
    names: ["GW583340"],
  },
  {
    primary_id: "D0HP6W",
    names: ["Autoantibody LKM-3"],
  },
  {
    primary_id: "D0HP8K",
    names: ["Thiazole Carboxamide Derivative 2"],
  },
  {
    primary_id: "D0HP8Q",
    names: ["SAR339375"],
  },
  {
    primary_id: "D0HQ0E",
    names: ["Spiradoline"],
  },
  {
    primary_id: "D0HQ1I",
    names: ["GNF-PF-85"],
  },
  {
    primary_id: "D0HQ1W",
    names: ["SKF-96365"],
  },
  {
    primary_id: "D0HQ3A",
    names: ["ELGODIPINE HYDROCHLORIDE"],
  },
  {
    primary_id: "D0HQ4M",
    names: ["Isopropyl Dodecylfluorophosphonate"],
  },
  {
    primary_id: "D0HQ6N",
    names: [
      "Benztropine",
      "Akitan",
      "Benzatropina",
      "Benzatropine",
      "Benzatropinum",
      "Benztropinum",
      "Cobrentin",
      "Cogentine",
      "Cogentinol",
      "Benzatropine [INN]",
      "Benzatropine Mesilate",
      "Pms Benztropine",
      "Tropine Benzohydryl Ether",
      "NK 02",
      "Apo-Benztropine",
      "Benzatropina [INN-Spanish]",
      "Benzatropine (INN)",
      "Benzatropine [INN:BAN]",
      "Benzatropinum [INN-Latin]",
      "Cogentin (TN)",
      "Benzhydryl 8-Methyl-8-Azabicyclo[3.2.1]oct-3-Yl Ether",
      "(1R,5R)-3-Benzhydryloxy-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "(1r,5r)-3-(Diphenylmethoxy)-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "(3-Endo)-3-(Diphenylmethoxy)-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "(3-Endo)-3-[(Diphenylmethyl)Oxy]-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "(5R)-3-Benzhydryloxy-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "3-(Diphenylmethoxy)-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "3-Benzhydryloxy-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "3alpha-(Diphenylmethoxy)-1alphaH,5alphaH-Tropane",
      "3alpha-(Diphenylmethoxy)Tropane",
      "3alpha-Benzhydryloxy-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "3endo-Benzhydryloxytropane",
      "8-Azabicyclo(3.2.1)Octane, 3-(Diphenylmethoxy)-8-Methyl-, Endo-(9CI)",
    ],
  },
  {
    primary_id: "D0HQ8X",
    names: ["Azepan-4-One Derivative 1"],
  },
  {
    primary_id: "D0HR0O",
    names: [
      "2,2,2-Tris-(4-Fluorophenyl)-Acetamide",
      "CHEMBL270704",
      "2,2,2-Tris(4-Fluorophenyl)Acetamide",
      "SCHEMBL2338402",
    ],
  },
  {
    primary_id: "D0HR1V",
    names: [
      "3-Iodothyronamine",
      "CHEMBL201002",
      "3'-Iodothyronamine",
      "3''-Iodothyronamine",
      "GTPL2145",
      "SCHEMBL13886595",
      "BDBM50181801",
      "4-[4-(2-Aminoethyl)Phenoxy]-2-Iodophenol",
    ],
  },
  {
    primary_id: "D0HR5Q",
    names: ["CIP-137401", "CIP-1374", "MEK Inhibitors, Cheminpharma", "MEK Inhibitors (Cancer), AlloStem"],
  },
  {
    primary_id: "D0HR6E",
    names: ["NTHI Vaccine"],
  },
  {
    primary_id: "D0HR6P",
    names: ["PMID25399762-Compound-Table1-C23"],
  },
  {
    primary_id: "D0HR7O",
    names: ["16-(4-Dimethylamino-Benzylidene)-Estradiol"],
  },
  {
    primary_id: "D0HR7P",
    names: ["Amonafide"],
  },
  {
    primary_id: "D0HR7X",
    names: ["Riboflavin Ophthalmic Solution; Ultraviolet-A (UVA) Irradiation"],
  },
  {
    primary_id: "D0HR7Z",
    names: ["PF-05280014"],
  },
  {
    primary_id: "D0HR8H",
    names: [
      "(5s)-5-Iododihydro-2,4(1h,3h)-Pyrimidinedione",
      "IDH",
      "AC1NRBRW",
      "SCHEMBL4316335",
      "DB02303",
      "(5S)-5-Iodo-1,3-Diazinane-2,4-Dione",
    ],
  },
  {
    primary_id: "D0HR8L",
    names: [
      "6-(3-Hydroxy-Phenyl)-Naphthalen-1-Ol",
      "CHEMBL193010",
      "6-(3-Hydroxyphenyl)-1-Naphthol",
      "SCHEMBL3010784",
      "HXKYTCGOCJUQSE-UHFFFAOYSA-N",
      "ZINC13644987",
    ],
  },
  {
    primary_id: "D0HR8Y",
    names: ["PMID29649907-Compound-10"],
  },
  {
    primary_id: "D0HR8Z",
    names: [
      "Miglustat",
      "BuDNJ",
      "Butyldeoxynojirimycin",
      "Miglustatum",
      "NBV",
      "Vevesca",
      "Zavesca",
      "Miglustat [USAN]",
      "OGT 918",
      "SC 48334",
      "SC48334",
      "Miglustat, Hydrochloride",
      "N-Butyl Deoxynojirimycin",
      "N-Butyl Dnj",
      "N-Butyldeoxynojirimycin",
      "N-Butylmoranoline",
      "NB-DNJ",
      "OGT-918",
      "SC-48334",
      "Zavesca (TN)",
      "Miglustat (USAN/INN)",
      "N-Bu-DNJ",
      "N-Butyl-DNJ",
      "N-Butyl-Deoxynojirimycin",
      "N-(N-Butyl)Deoxynojirimycin",
      "N-Butyl-1-Deoxynojirimycin",
      "N-(N-Butyl)-1,5-Dideoxy-1,5-Imino-D-Glucitol",
      "(2R,3R,4R,5S)-1-Butyl-2-(Hydroxymethyl)Piperidine-3,4,5-Triol",
      "1,5-Dideoxy-1,5-N-Butylimino-D-Glucitol",
    ],
  },
  {
    primary_id: "D0HR9S",
    names: ["NSC-683634"],
  },
  {
    primary_id: "D0HS1D",
    names: ["PMID25666693-Compound-20"],
  },
  {
    primary_id: "D0HS2E",
    names: ["Rx-04", "Antibacterials (Iv, Gram-Negative Bacterial Infections), Rib-X/Sanofi"],
  },
  {
    primary_id: "D0HS2J",
    names: ["PMID27774824-Compound-Figure8Example99"],
  },
  {
    primary_id: "D0HS2Z",
    names: ["GPR119 Agonists"],
  },
  {
    primary_id: "D0HS3M",
    names: [
      "1-(1-(Pyrazin-2-Yl)Ethylidene)Thiosemicarbazide",
      "CHEMBL401556",
      "AC1NT9J5",
      "142564-78-1",
      "BDBM50376205",
      "ZINC26974934",
      "AKOS014545779",
      "1-(2-Pyrazinyl)Ethanone Thiosemicarbazone",
    ],
  },
  {
    primary_id: "D0HS3X",
    names: ["BCH-2051"],
  },
  {
    primary_id: "D0HS5P",
    names: [
      "Calindol",
      "CHEMBL2092942",
      "Clindol",
      "GTPL719",
      "SCHEMBL4103949",
      "CHEMBL1801356",
      "ZINC1494434",
      "BDBM50404271",
      "NCGC00344505-01",
    ],
  },
  {
    primary_id: "D0HS5V",
    names: [
      "Brivanib",
      "649735-46-6",
      "BMS-540215",
      "Brivanib (BMS-540215)",
      "BMS 540215",
      "UNII-DDU33B674I",
      "Brivanib [USAN]",
      "BMS540215",
      "DDU33B674I",
      "CHEMBL377300",
      "(2R)-1-[4-(4-Fluoro-2-Methyl-1H-Indol-5-Yloxy)-5-Methylpyrrolo[2,1-F][1,2,4]triazin-6-Yloxy]propanol",
      "Brivanib (USAN)",
      "(2R)-1-[4-[(4-FLUORO-2-METHYL-1H-INDOL-5-YL)OXY]-5-METHYL-PYRROLO[2,1-F][1,2,4]TRIAZIN-6-YL]OXYPROPAN-2-OL",
      "(2R)-1-({4-[(4-Fluoro-2-Methyl-1H-Indol-5-Yl)Oxy]-5-Methylpyrrolo[2,1-F][1,2,4]triazin-6-Yl}oxy)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D0HS7N",
    names: ["AMG 420"],
  },
  {
    primary_id: "D0HS8T",
    names: ["Imidazopyridine Derivative 1"],
  },
  {
    primary_id: "D0HS9X",
    names: ["3-Substituted-2-Furancarboxylic Acid Hydrazide Derivative 1"],
  },
  {
    primary_id: "D0HS9Y",
    names: ["CTCE-0501"],
  },
  {
    primary_id: "D0HT2D",
    names: [
      "AcAsp-Glu-Met-Glu-Cha-Cys",
      "CHEMBL64867",
      "AC1LAAJ4",
      "BDBM50096409",
      "AcAsp-Glu-Met-Glu-Nal(2-Naphthylalanine)-Cys",
      "Ac-L-Asp-L-Glu-L-Met-L-Glu-3-(2-Naphthyl)-L-Ala-L-Cys-OH",
      "(R)-2-[(S)-2-((S)-2-{(S)-2-[(S)-2-((S)-2-Acetylamino-3-Carboxy-Propanoylamino)-4-Carboxy-Butanoylamino]-4-Methylsulfanyl-Butanoylamino}-4-Carboxy-Butanoylamino)-3-Naphthalen-2-Yl-Propanoylamino]-3-Mercapto-Propionic Acid",
    ],
  },
  {
    primary_id: "D0HT2X",
    names: ["CNT0-1959"],
  },
  {
    primary_id: "D0HT4H",
    names: ["SOM-0009"],
  },
  {
    primary_id: "D0HT4Y",
    names: ["CC-92480", "CELMoD"],
  },
  {
    primary_id: "D0HT9C",
    names: ["Sulfonamide Derivative 18"],
  },
  {
    primary_id: "D0HU0A",
    names: ["TK216"],
  },
  {
    primary_id: "D0HU0O",
    names: ["Tetra-Hydro-Pyrrolopyrimidinedione Derivative 1"],
  },
  {
    primary_id: "D0HU0W",
    names: ["Temocillin Disodium"],
  },
  {
    primary_id: "D0HU4J",
    names: [
      "(+/-)-2-Phenylthiomorpholine",
      "2-Phenylthiomorpholine",
      "77082-31-6",
      "2-PHENYL-THIOMORPHOLINE",
      "CHEMBL608438",
      "Thiomorpholine,2-Phenyl-",
      "AC1NMBWZ",
      "AC1Q1ICC",
      "Thiomorpholine, 2-Phenyl-",
      "SCHEMBL1636929",
      "CTK5E3816",
      "DTXSID20407436",
      "MolPort-001-791-006",
      "SRYHBJDVLOEJNP-UHFFFAOYSA-N",
      "2-Phenyl-Thiomorpholine, AldrichCPR",
      "BDBM50307369",
      "AKOS006230101",
      "MCULE-8074427790",
      "QC-5067",
      "NE56940",
      "KB-69531",
      "EN300-80273",
    ],
  },
  {
    primary_id: "D0HU4L",
    names: ["ISIS 6431"],
  },
  {
    primary_id: "D0HU9H",
    names: ["Panitumumab"],
  },
  {
    primary_id: "D0HV0D",
    names: ["Aryl Piperazine Derivative 10"],
  },
  {
    primary_id: "D0HV0Y",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 5"],
  },
  {
    primary_id: "D0HV3A",
    names: ["FLUMEZAPINE"],
  },
  {
    primary_id: "D0HV3N",
    names: ["PMID29865878-Compound-60"],
  },
  {
    primary_id: "D0HV4B",
    names: ["ADCT-402"],
  },
  {
    primary_id: "D0HV5D",
    names: ["2-Methyl-1,2-Di-Pyridin-3-Yl-Propan-1-One Oxime"],
  },
  {
    primary_id: "D0HV6H",
    names: ["H-Tyr-C[D-Allylgly-Gly-Phe-D-Allylgly]NH2", "CHEMBL220219"],
  },
  {
    primary_id: "D0HV6O",
    names: ["AP-23588", "AP-23485", "AP-23485 Analogs, ARIAD"],
  },
  {
    primary_id: "D0HV6X",
    names: ["TRK-130"],
  },
  {
    primary_id: "D0HV7A",
    names: ["Lintuzumab Ac-225", "Actimab-A (TN)"],
  },
  {
    primary_id: "D0HV7K",
    names: ["Melacine"],
  },
  {
    primary_id: "D0HV7T",
    names: ["Picolinamido Propanoic Acid Derivative 3"],
  },
  {
    primary_id: "D0HV7V",
    names: ["ISIS 12660"],
  },
  {
    primary_id: "D0HW1I",
    names: [
      "PAN-622-Toxin Conjugate",
      "Antibody-Toxin Conjugate (Cholangiocarcinoma), Panacea",
      "Aspartate Beta-Hydroxylase Inhibitor (Cholangiocarcinoma), Panacea",
      "PAN-622-Toxin Conjugate (Cholangiocarcinoma)",
      "PAN-622-Toxin Conjugate (Cholangiocarcinoma), Panacea",
    ],
  },
  {
    primary_id: "D0HW3Y",
    names: [
      "5,6-Dihydroxy-7-Nitro-2,3-Dihydroinden-1-One",
      "5,6-Dihydroxy-7-Nitro-1-Indanone",
      "383382-47-6",
      "CHEMBL9039",
      "SCHEMBL7432202",
      "ULOQNEJBWLIGHK-UHFFFAOYSA-N",
      "5,6-Dihydroxy-7-Nitro-Indan-1-One",
    ],
  },
  {
    primary_id: "D0HW6Y",
    names: ["Flavonoid Derivative 8"],
  },
  {
    primary_id: "D0HX0D",
    names: [
      "Tin Protoporphyrin",
      "AC1NUFJX",
      "3-[(1Z,4Z,10Z,14Z)-18-(2-Carboxyethyl)-8,13-Bis(Ethenyl)-3,7,12,17-Tetramethylporphyrin-21,22,23,24-Tetraid-2-Yl]propanoic Acid",
    ],
  },
  {
    primary_id: "D0HX0P",
    names: ["INP-06", "Biological CNS Drug (Pressurized Olfactory Delivery, Pain), Impel NeuroPharma"],
  },
  {
    primary_id: "D0HX1T",
    names: ["DA-697b", "DZ-697b", "Oral Anticoagulants, Daiichi"],
  },
  {
    primary_id: "D0HX9S",
    names: ["PMID28621580-Compound-WO2014079545C69"],
  },
  {
    primary_id: "D0HY0H",
    names: ["Hydrazide Derivative 4"],
  },
  {
    primary_id: "D0HY3D",
    names: ["SY-1425"],
  },
  {
    primary_id: "D0HY3K",
    names: ["3-Phenyl Pyrazole Derivative 1"],
  },
  {
    primary_id: "D0HY3P",
    names: ["SGN-10", "BMS-191352", "BR96 ScFv-PE40", "BR96-SCIT"],
  },
  {
    primary_id: "D0HY4Q",
    names: [
      "98mTC-CIM-ANT",
      "99mTC-Chelator SSTR2 Antagonist (Radiolabeled, Neuroendocrine Tumor Imaging), Molecular Insight",
    ],
  },
  {
    primary_id: "D0HY5Q",
    names: ["PMID25980951-Compound-43"],
  },
  {
    primary_id: "D0HY7G",
    names: ["PMID30247903-Compound-General Structure4"],
  },
  {
    primary_id: "D0HY9M",
    names: ["Fused Aryl Carbocycle Derivative 4"],
  },
  {
    primary_id: "D0HY9S",
    names: ["BOS172722"],
  },
  {
    primary_id: "D0HZ1V",
    names: ["KDM-1001"],
  },
  {
    primary_id: "D0HZ1Z",
    names: ["Epoxysuccinate Derivative 2"],
  },
  {
    primary_id: "D0HZ2A",
    names: ["Recombinant Factor VIIa PEGylated Liposomal"],
  },
  {
    primary_id: "D0HZ2E",
    names: ["M100907"],
  },
  {
    primary_id: "D0HZ3Q",
    names: ["AGN-190383", "120755-15-9"],
  },
  {
    primary_id: "D0HZ4C",
    names: ["AvidinOx", "177Lu-ST2210"],
  },
  {
    primary_id: "D0HZ7J",
    names: ["N-Alkyl-4-Oxazolecar Boxamide Derivative 2"],
  },
  {
    primary_id: "D0HZ8U",
    names: ["STO609"],
  },
  {
    primary_id: "D0HZ9X",
    names: [
      "2-Pyridin-3-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "2-(3-Pyridylmethyl)Indazolinone",
      "ICI-207968",
      "120273-58-7",
      "CHEMBL3144579",
      "AC1L2UZT",
      "CHEMBL8337",
      "SCHEMBL9596545",
      "DTXSID90152784",
      "BDBM50008992",
      "ZINC100091041",
      "AKOS023543919",
      "3H-Indazol-3-One,",
      "ICI 207968",
    ],
  },
  {
    primary_id: "D0I0AA",
    names: [
      "Acrolein",
      "ACROLEIN",
      "Acrylaldehyde",
      "2-Propenal",
      "Propenal",
      "107-02-8",
      "Acrylic Aldehyde",
      "Allyl Aldehyde",
      "Prop-2-Enal",
      "Ethylene Aldehyde",
      "Acraldehyde",
      "Aqualin",
      "Magnacide H",
      "2-Propen-1-One",
      "Aqualine",
      "Slimicide",
      "Crolean",
      "Acquinite",
      "Magnacide",
      "Prop-2-En-1-Al",
      "Acrylaldehyd",
      "Akroleina",
      "Akrolein",
      "Acroleine",
      "Acroleina",
      "Papite",
      "Biocide",
      "Aldeide Acrilica",
      "Aldehyde Acrylique",
      "Acraldehydeacroleina",
      "Polyacrolein",
      "Propenaldehyde",
      "Trans-Acrolein",
      "NSC 8819",
      "Akrolein [Czech]",
      "Propenal [Czech]",
      "Rcra Waste Number P003",
      "Magnacide H And B",
      "Akroleina [Polish]",
      "Acroleina [Italian]",
    ],
  },
  {
    primary_id: "D0I0AQ",
    names: ["MELOSMINE"],
  },
  {
    primary_id: "D0I0AW",
    names: [
      "Elbasvir + Grazoprevir",
      "Elbasvir And Grazoprevir",
      "Grazoprevir/Elbasvir",
      "MK-5172/MK-8742",
      "MK-5172A",
      "MK-8742/MK-5172",
      "ZEPATIER",
    ],
  },
  {
    primary_id: "D0I0BJ",
    names: ["Recombinant Interleukin-2"],
  },
  {
    primary_id: "D0I0BN",
    names: ["Oxazolo[3,4-A]pyrazine Derivative 3"],
  },
  {
    primary_id: "D0I0BV",
    names: [
      "1'-Acetoxychavicol Acetate",
      "52946-22-2",
      "1'-Acetoxychavicol",
      "UNII-SQV3080A20",
      "CHEBI:469",
      "1'S-1'-Acetoxychavicol Acetate",
      "SQV3080A20",
      "(AlphaS)-4-(Acetyloxy)-Alpha-Ethenylbenzenemethanol",
      "[4-[(1S)-1-Acetyloxyprop-2-Enyl]phenyl] Acetate",
      "Galangal Acetate",
      "(1S)-1-[4-(Acetyloxy)Phenyl]prop-2-En-1-Yl Acetate",
      "Benzenemethanol, 4-(Acetyloxy)-.alpha.-Ethenyl-, Acetate, (.alpha.S)-",
      "CCRIS 7708",
      "1'-Acethoxychavicol",
      "AC1Q60ZN",
      "AC1L3O7Q",
      "GTPL6298",
      "CHEMBL323727",
      "SCHEMBL17454871",
      "(S)-1'-Acetoxychavicol Acetate",
      "Benzenemethanol, 4-(Acetyloxy)-",
    ],
  },
  {
    primary_id: "D0I0CU",
    names: ["NSC-745888"],
  },
  {
    primary_id: "D0I0DE",
    names: ["ISIS 19658"],
  },
  {
    primary_id: "D0I0DL",
    names: ["Alpha 1-PI"],
  },
  {
    primary_id: "D0I0DQ",
    names: ["Glisolamide"],
  },
  {
    primary_id: "D0I0DS",
    names: ["Theobromine"],
  },
  {
    primary_id: "D0I0EG",
    names: [
      "Captopril",
      "Acediur",
      "Aceplus",
      "Acepress",
      "Acepril",
      "Alopresin",
      "Apopril",
      "Asisten",
      "Capoten",
      "Captolane",
      "Captoprilum",
      "Captopryl",
      "Captoril",
      "Captril",
      "Cesplon",
      "Dilabar",
      "Garranil",
      "Hipertil",
      "Hypertil",
      "Isopresol",
      "Lopirin",
      "Lopril",
      "MCO",
      "Tenosbon",
      "Tensiomin",
      "Tensobon",
      "Tensoprel",
      "Lopirin [Switzerland]",
      "C 4042",
      "SA 333",
      "SQ 14225",
      "X8Z",
      "Apopril (TN)",
      "Capoten (TN)",
      "Captoprilum [INN-Latin]",
      "Garranil (Discontinued)",
      "L-Captopril",
      "SQ 14,225",
      "SQ-14225",
      "SQ-14534",
      "SQ-14,225",
      "SQ-14,534",
      "Captopril (JP15/USP/INN)",
      "Captopril [USAN:INN:BAN:JAN]",
      "D-3-Mercapto-2-Methylpropionylproline",
      "D-2-Methyl-3-Mercaptopropanoyl-L-Proline",
      "D-3-Mercapto-2-Methylpropanoyl-L-Proline",
      "[2S]-1-[3-Mercapto-2-Methylpropionyl]-L-Proline",
      "N-[(S)-3-Mercapto-2-Methylpropionyl]-L-Proline;(2S)-1-(3-Mercapto-2-Methylpropionyl)-L-Proline",
      "(2S)-1-[(2S)-2-Methyl-3-Sulfanylpropanoyl]pyrrolidine-2-Carboxylic Acid",
      "(S)-1-(3-Mercapto-2-Methyl-1-Oxo-Propyl)-L-Proline",
      "(S)-1-(3-Mercapto-2-Methyl-1-Oxopropyl)-L-Proline",
      "1-((2S)-3-Mercapto-2-Methylpropionyl)-L-Proline",
      "1-(3-Mercapto-2-Methyl-1-Oxopropyl)-L-Proline",
      "1-(D-3-Mercapto-2-Methyl-1-Oxopropyl)-L-Proline (S,S)",
      "1-[(2S)-2-Methyl-3-Sulfanylpropanoyl]-L-Proline",
      "3-Mercapto-2-Methylpropionyl-Proline",
    ],
  },
  {
    primary_id: "D0I0EN",
    names: ["TURBINATINE"],
  },
  {
    primary_id: "D0I0EO",
    names: ["ISIS-GCGRrx"],
  },
  {
    primary_id: "D0I0FA",
    names: ["JCAR020"],
  },
  {
    primary_id: "D0I0FM",
    names: ["FR-901451"],
  },
  {
    primary_id: "D0I0GL",
    names: ["3-(4-Fluoro-Phenyl)-6,7-Dimethoxy-Quinoline", "CHEMBL68401", "ZINC3834028"],
  },
  {
    primary_id: "D0I0GM",
    names: ["Daxalipram"],
  },
  {
    primary_id: "D0I0HL",
    names: ["DEMETHYLZEYLASTERONE", "Demethylzeylasterone", "CHEMBL465251"],
  },
  {
    primary_id: "D0I0IM",
    names: ["5-Ketodihydromevinolin", "Compound 69 [PMID: 3656359]"],
  },
  {
    primary_id: "D0I0JE",
    names: ["1,3,4-Thiadiazole Derivative 1"],
  },
  {
    primary_id: "D0I0JG",
    names: [
      "[3H]fenobam",
      "57653-26-6",
      "3-(3-Chlorophenyl)-1-(1-Methyl-4-Oxo-5H-Imidazol-2-Yl)Urea",
      "SMR001456387",
      "MLS006011708",
      "ZINC1436",
      "GTPL1434",
      "GTPL1433",
      "1-(3-Chlorophenyl)-3-(1-Methyl-4-Oxo-4,5-Dihydro-1H-Imidazol-2-Yl)Urea",
      "CTK8F9740",
      "CTK5B9436",
      "DWPQODZAOSWNHB-UHFFFAOYSA-N",
      "LP00842",
      "NCGC00092384-04",
      "FT-0630701",
    ],
  },
  {
    primary_id: "D0I0KP",
    names: ["ISC-HpNSC"],
  },
  {
    primary_id: "D0I0KV",
    names: ["16-(2',2'-Dimethyl)-Propylidene-Estrone"],
  },
  {
    primary_id: "D0I0LP",
    names: ["Parvovirus B19 Vaccine"],
  },
  {
    primary_id: "D0I0LW",
    names: ["PMID24999562C6b"],
  },
  {
    primary_id: "D0I0MJ",
    names: [
      "CD-832.HCL",
      "129904-55-8",
      "2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylic Acid 5-(3-Nitrooxypropyl) 3-[2-(3-Pyridylcarbonylamino)Ethyl] Diester Monohydrochloride",
      "CD-832.HCl",
    ],
  },
  {
    primary_id: "D0I0ML",
    names: [
      "2-(2'-Methyl-Biphenyl-3-Yl)-Ethylamine",
      "CHEMBL234885",
      "ZINC28821767",
      "BDBM50210124",
      "AKOS022255130",
      "2-(2''-Methyl-Biphenyl-3-Yl)-Ethylamine",
    ],
  },
  {
    primary_id: "D0I0MR",
    names: [
      "(Z)-1,1,1-Trifluoro-Nonadec-10-En-2-One",
      "CHEMBL422064",
      "SCHEMBL8059738",
      "C19H33F3O",
      "MolPort-009-018-697",
      "HMS3649G07",
      "BDBM50069273",
      "1964AH",
      "ZINC34803437",
      "LS-96805",
      "(Z)-1,1,1-Trifluoro-10-Nonadecen-2-One",
      "(10z)-1,1,1-Trifluorononadec-10-En-2-One",
      "SR-01000946571",
      "SR-01000946571-1",
    ],
  },
  {
    primary_id: "D0I0MT",
    names: ["Aryl Carboxamide Derivative 4"],
  },
  {
    primary_id: "D0I0MU",
    names: ["Cbz-Ile-Leu-Ala-LeuVSMe", "CHEMBL207403"],
  },
  {
    primary_id: "D0I0NJ",
    names: ["CART-19 Cells"],
  },
  {
    primary_id: "D0I0NP",
    names: ["SL125"],
  },
  {
    primary_id: "D0I0NU",
    names: ["NKP-2235"],
  },
  {
    primary_id: "D0I0NY",
    names: [
      "L-370,518",
      "CHEMBL2062141",
      "L-370518",
      "CHEMBL442024",
      "BDBM50454822",
      "BDBM50056772",
      "(S)-1-((R)-2-Methylamino-3-Phenyl-Propionyl)-Pyrrolidine-2-Carboxylic Acid [(S)-1-(4-Amino-Cyclohexyl)-2-Methylcarbamoyl-2-Oxo-Ethyl]-Amide",
    ],
  },
  {
    primary_id: "D0I0OH",
    names: [
      "5-Fluoro-2'-Deoxyuridine-5'-Monophosphate",
      "5-Fluorodeoxyuridine Monophosphate",
      "FLUORODEOXYURIDYLATE",
      "F-DUMP",
      "134-46-3",
      "FdUMP",
      "CHEMBL886",
      "CHEBI:2129",
      "UNII-7CJ707H131",
      "7CJ707H131",
      "88410-68-8",
      "5-FLUORO-2-DEOXYURIDINE MONOPHOSPHATE (FDUMP)",
      "[(2R,3S,5R)-5-(5-Fluoro-2,4-Dioxo-Pyrimidin-1-Yl)-3-Hydroxy-Tetrahydrofuran-2-Yl]methyl Dihydrogen Phosphate",
      "UFP",
      "Poly(DF5U)",
      "Poly(5'-FU)",
      "Poly(5-Fluoro-2'-Deoxyuridylic Acid)",
      "5FdUMP",
      "DB03761",
      "5'-Uridylic Acid, 2'-Deoxy-5-Fluoro-",
      "AC1L1RES",
      "5'-Uridylic Acid,2'-Deoxy-5-Fluoro-, Homopolymer",
    ],
  },
  {
    primary_id: "D0I0QH",
    names: ["6-Fluoromevalonate Pyrophosphate", "6-FMevPP"],
  },
  {
    primary_id: "D0I0RB",
    names: ["Nadifloxacin", "Acuatim", "Nadixa", "OPC-7251"],
  },
  {
    primary_id: "D0I0RF",
    names: [
      "BVT-142",
      "BVT-13",
      "BVT-142 Analogs, Biovitrum",
      "Dual PPAR Alpha/Gamma Agonists (Type II Diabetes)",
      "Dual PPAR Alpha/Gamma Agonists (Type II Diabetes), Biovitrum",
    ],
  },
  {
    primary_id: "D0I0RJ",
    names: [
      "Amiloride",
      "Amiclaran",
      "Amilorida",
      "Amiloridum",
      "Amipramidin",
      "Amipramizid",
      "Amipramizide",
      "Amiprazidine",
      "Amyloride",
      "Guanamprazin",
      "Guanamprazine",
      "Midamor",
      "Amiloride HCL",
      "Amiloride Hydrochloride Hydrate",
      "Amiclaran (TN)",
      "Amikal (Hydrochloride Dihydrate)",
      "Amilorida [INN-Spanish]",
      "Amiloride (INN)",
      "Amiloride [INN:BAN]",
      "Amiloridum [INN-Latin]",
      "Biduret (TN)",
      "Midamor (Hydrochloride Dihydrate)",
      "MK-870 (Hydrochloride Dihydrate)",
      "AMILORIDE (SEE ALSO: AMILORIDE HCL (2016-88-8))",
      "N-Amidino-3,5-Diamino-6-Chloropyrazinecarboxamide",
      "N-Amidino-3,5-Diamino-6-Chlorpyrazincarboxamid",
      "Pyrazinecarboxamide, 3,5-Diamino-N-(Aminoiminomethyl)-6-Chloro-, Monohydrochloride",
      "3,5-Diamino-N-(Aminoiminomethyl)-6-Chloropyrazinecarboxamide",
      "3,5-Diamino-6-Chloro-N-(Diaminomethylidene)Pyrazine-2-Carboxamide",
      "3,5-Diamino-N-[amino(Imino)Methyl]-6-Chloropyrazine-2-Carboxamide",
      "3,5-Diamino-N-Carbamimidoyl-6-Chloropyrazine-2-Carboxamide",
      "Amiloride (Na-Ca Chanel Blocker)",
    ],
  },
  {
    primary_id: "D0I0RT",
    names: ["Thioureido Sulfonamide"],
  },
  {
    primary_id: "D0I0SG",
    names: [
      "CLVax 1.0",
      "Anti-Influenza-T-Cell Vaccines (Intramascular/Intranasal Route, Influenza Virus Infection), CureLab",
    ],
  },
  {
    primary_id: "D0I0SH",
    names: ["KY-021"],
  },
  {
    primary_id: "D0I0SK",
    names: ["4-(4-Chlorobenzylthio)-2-Aminobutanoic Acid", "CHEMBL377079", "BDBM50179703"],
  },
  {
    primary_id: "D0I0SY",
    names: ["MEDI-542"],
  },
  {
    primary_id: "D0I0TD",
    names: ["AZM-134", "AZM-133", "GLP-1 Mimetics, Alizyme", "Glucagon-Like Peptide-1 Mimetics, Alizyme"],
  },
  {
    primary_id: "D0I0TW",
    names: ["TAS-109", "DNA Repair Inhibitor (Solid Tumors), Taiho"],
  },
  {
    primary_id: "D0I0UE",
    names: ["Lexipafant"],
  },
  {
    primary_id: "D0I0UI",
    names: ["ONO-AE2-227"],
  },
  {
    primary_id: "D0I0UM",
    names: ["CTL019"],
  },
  {
    primary_id: "D0I0UW",
    names: ["1-(4-P-Tolyl-Butyl)-Piperidine", "CHEMBL571073"],
  },
  {
    primary_id: "D0I0VI",
    names: ["Albumin Human", "Albumin (Human)", "Kedbumin"],
  },
  {
    primary_id: "D0I0VJ",
    names: ["Pyrazole Derivative 3"],
  },
  {
    primary_id: "D0I0VY",
    names: ["DA-7101"],
  },
  {
    primary_id: "D0I0WF",
    names: ["RHODIOLININ"],
  },
  {
    primary_id: "D0I0WP",
    names: [
      "4-(Methyl(4-Phenylthiazol-2-Yl)Amino)Phenol",
      "CHEMBL460515",
      "4-[Methyl-(4-Phenyl-Thiazol-2-Yl)-Amino]-Phenol",
      "AC1LDK73",
      "MLS000075845",
      "Cid_649391",
      "ZINC813364",
      "HMS2534H13",
      "BDBM50247460",
      "AKOS000563578",
      "SMR000014954",
      "BAS 08769335",
      "SR-01000322128",
      "SR-01000322128-1",
    ],
  },
  {
    primary_id: "D0I0XA",
    names: ["KDOAM25"],
  },
  {
    primary_id: "D0I0XF",
    names: ["IMO-8400", "Bazlitoran", "Bazlitoran [INN]", "Bazlitoran [USAN]", "UNII-2U46M95B5M", "2U46M95B5M"],
  },
  {
    primary_id: "D0I0XX",
    names: ["P2B-003"],
  },
  {
    primary_id: "D0I0YE",
    names: ["N-[3,3-Bis-(4-Fluorophenyl)-Propyl]-Benzamide"],
  },
  {
    primary_id: "D0I0YS",
    names: ["TTP-4000"],
  },
  {
    primary_id: "D0I0ZO",
    names: [
      "Anti-CD45 Mabs",
      "AHCD45 Therapy, Baylor College",
      "YTH-24",
      "YTH-24/54",
      "YTH-25.4",
      "YTH-54.12",
      "Anti-CD45 MAbs (Stem Cell Transplantation/Cancer)",
      "Anti-CD45 MAbs (Stem Cell Transplantation/Cancer), Baylor College",
    ],
  },
  {
    primary_id: "D0I0ZP",
    names: ["Glyminox"],
  },
  {
    primary_id: "D0I0ZY",
    names: ["ETN-001"],
  },
  {
    primary_id: "D0I1AC",
    names: ["(4-Chloro-1H-Pyrazol-1-Yl)(O-Tolyl)Methanone"],
  },
  {
    primary_id: "D0I1AF",
    names: ["7-Vinyl-6H-Chromeno[4,3-B]quinoline-3,9-Diol"],
  },
  {
    primary_id: "D0I1AW",
    names: ["ISIS 116359"],
  },
  {
    primary_id: "D0I1BA",
    names: ["BCX-1898"],
  },
  {
    primary_id: "D0I1BJ",
    names: ["Dmt-Pro-Phe-D-1-Nal-NH2", "CHEMBL385583"],
  },
  {
    primary_id: "D0I1BQ",
    names: ["IL-18BP"],
  },
  {
    primary_id: "D0I1BX",
    names: ["CGS-32359", "C5aRAD"],
  },
  {
    primary_id: "D0I1CQ",
    names: ["BIIB067"],
  },
  {
    primary_id: "D0I1DH",
    names: ["Technetium Tc-99m Mertiatide Kit"],
  },
  {
    primary_id: "D0I1DW",
    names: [
      "Edotecarin",
      "ED-749",
      "Edotecarin < Prop INN",
      "J-107088",
      "PF-804950",
      "12-(Beta-D-Glucopyranosyl)-2,10-Dihydroxy-6-[2-Hydroxy-1-(Hydroxymethyl)Ethylamino]-6,7,12,13-Tetrahydro-5H-Indolo[2,3-A]pyrrolo[3,4-C]carbazole-5,7-Dione",
    ],
  },
  {
    primary_id: "D0I1DZ",
    names: ["DE-110"],
  },
  {
    primary_id: "D0I1EE",
    names: ["Alexa-488-Telenzepine"],
  },
  {
    primary_id: "D0I1EP",
    names: [
      "MRS928",
      "3,5,7-Trimethoxyflavone",
      "3,5,7-Trimethoxy-2-Phenyl-4H-Chromen-4-One",
      "Galangin Trimethyl Ether",
      "26964-29-4",
      "Galangin 3,5,7-Trimethyl Ether",
      "3,5,7-Trimethoxy-2-Phenylchromen-4-One",
      "Chromen-4-One, 3,5,7-Trimethoxy-2-Phenyl-",
      "CHEMBL75772",
      "CHEBI:5263",
      "CBTHKWVPSIGKMI-UHFFFAOYSA-N",
      "Flavone, 3,5,7-Trimethoxy-",
      "3,5,7-Trimethoxy-2-Phenyl-Chromen-4-One",
      "AC1L3LSQ",
      "C10045",
      "AC1Q6E1K",
      "GTPL402",
      "3,5,7-Tri-O-Methylgalangin",
      "MEGxp0_001894",
      "4H-1-Benzopyran-4-One, 3,5,7-Trimethoxy-2-Phenyl-",
      "SCHEMBL1664287",
      "CTK8D5933",
      "ACon1_000793",
    ],
  },
  {
    primary_id: "D0I1EX",
    names: ["DTI-0009 (Oral)"],
  },
  {
    primary_id: "D0I1FJ",
    names: ["SB-747651A"],
  },
  {
    primary_id: "D0I1FQ",
    names: ["Raltegravir"],
  },
  {
    primary_id: "D0I1FS",
    names: ["ISIS 134499"],
  },
  {
    primary_id: "D0I1FT",
    names: ["Lexgenleucel-T"],
  },
  {
    primary_id: "D0I1FU",
    names: ["GSK2830930A"],
  },
  {
    primary_id: "D0I1GO",
    names: ["PMID28627961-Compound-32"],
  },
  {
    primary_id: "D0I1GV",
    names: ["1-Arylmethylpyrrolidin-2-Yl Ethanol Amine", "Compound 26a [PMID: 16216508]"],
  },
  {
    primary_id: "D0I1HI",
    names: ["MER-4101"],
  },
  {
    primary_id: "D0I1HM",
    names: ["CM-182"],
  },
  {
    primary_id: "D0I1IK",
    names: ["7-Fluoroindirubin-3-Acetoxime"],
  },
  {
    primary_id: "D0I1IW",
    names: [
      "2-(3''-(5''-Fluoro-Indolyl)Ethyloxy)Adenosine",
      "CHEMBL373478",
      "BDBM50208809",
      "2-(3''''-(5''''-Fluoro-Indolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D0I1JG",
    names: ["ES-1"],
  },
  {
    primary_id: "D0I1KL",
    names: ["ACT-2"],
  },
  {
    primary_id: "D0I1KQ",
    names: ["NEUROPEPTIDE-Y"],
  },
  {
    primary_id: "D0I1LH",
    names: [
      "Aldosterone",
      "ALDOSTERONE",
      "52-39-1",
      "Electrocortin",
      "Aldocortin",
      "Aldocortene",
      "Aldocorten",
      "(+)-Aldosterone",
      "Elektrocortin",
      "D-Aldosterone",
      "Reichstein X",
      "18-Oxocorticosterone",
      "Aldosteronum",
      "Aldosterona",
      "Aldosteronum [INN-Latin]",
      "Aldosterona [INN-Spanish]",
      "[3H]aldosterone",
      "18-Aldocorticosterone",
      "Aldosterone [INN:BAN:DCF]",
      "11beta,21-Dihydroxypregn-4-Ene-3,18,20-Trione",
      "11beta,21-Dihydroxy-3,20-Diketo-4-Pregnen-18-Al",
      "11beta,21-Dihydroxy-3,20-Diketopregn-4-Ene-18-Al",
      "NSC 73856",
      "18-Formyl-11beta,21-Dihydroxy-4-Pregnene-3,20-Dione",
      "UNII-4964P6T9RB",
      "Aldosterone, (11 Beta,17 Alpha)-Isomer",
      "Aldosterone, (+-)-Isomer",
      "(11BETA)-11,21-DIHYDROXY-3,20-DIOXOPREGN-4-EN-18-AL",
      "(8S,9S,10R,11S,13R,14S,17S)-11-Hydroxy-17-(2-Hydroxyacetyl)-10-Methyl-3-Oxo-1,2,6,7,8,9,11,12,14,15,16,17-Dodecahydrocyclopenta[a]phenanthrene-13-Carbaldehyde",
      "11beta,21-Dihydroxy-3,20-Dioxo-4-Pregnen-18-Al",
      "11beta,21-Dihydroxy-3,20-Dioxo-4-Pregnen-18-Al, 18,11-Halbacetal",
      "11beta,21-Dihydroxy-3,20-Dioxo-Pregn-4-Ene-18-Al",
      "11beta,21-Dihydroxy-3,20-Dioxopregn-4-En-18-Al",
    ],
  },
  {
    primary_id: "D0I1LR",
    names: [
      "2-Morpholin-4-Yl-8-Thiophen-3-Ylchromen-4-One",
      "CHEMBL360183",
      "503468-85-7",
      "2-Morpholin-4-Yl-8-Thiophen-3-Yl-Chromen-4-One",
      "CTK1G6920",
      "DTXSID30469641",
      "BDBM50156502",
      "AKOS030552941",
      "4H-1-Benzopyran-4-One, 2-(4-Morpholinyl)-8-(3-Thienyl)-",
    ],
  },
  {
    primary_id: "D0I1ME",
    names: ["10R-Hydroxylobel-7-Ene", "CHEMBL217815"],
  },
  {
    primary_id: "D0I1NG",
    names: ["6-(4-((Phenethylamino)Methyl)Phenoxy)Nicotinamide", "CHEMBL238361", "SCHEMBL3702187"],
  },
  {
    primary_id: "D0I1NM",
    names: ["Avastinbevacizumab"],
  },
  {
    primary_id: "D0I1NU",
    names: ["Neamine"],
  },
  {
    primary_id: "D0I1NV",
    names: ["Adenovirus Gene Therapy", "Adenovirus Gene Therapy (Bladder Cancer)"],
  },
  {
    primary_id: "D0I1NX",
    names: ["4-[1,2-Bis(4-Hydroxyphenyl)But-1-Enyl]phenol", "CHEMBL37775"],
  },
  {
    primary_id: "D0I1OJ",
    names: ["N-(4-Morpholinophenyl)Docos-13-Enamide", "CHEMBL199906"],
  },
  {
    primary_id: "D0I1OS",
    names: ["E-2212"],
  },
  {
    primary_id: "D0I1OX",
    names: ["SPL-2101"],
  },
  {
    primary_id: "D0I1PF",
    names: ["LCAR-B4822M CAR-T Cell"],
  },
  {
    primary_id: "D0I1PY",
    names: ["Insulin Susp Protamine Zinc Beef/Pork"],
  },
  {
    primary_id: "D0I1QF",
    names: ["Vesamicol"],
  },
  {
    primary_id: "D0I1QI",
    names: ["Ganetespib"],
  },
  {
    primary_id: "D0I1QJ",
    names: ["KP-100"],
  },
  {
    primary_id: "D0I1RB",
    names: ["Exo-THPO"],
  },
  {
    primary_id: "D0I1RG",
    names: [
      "(4-Phenylpiperazin-1-Yl)(P-Tolyl)Methanone",
      "(4-Phenyl-Piperazin-1-Yl)-P-Tolyl-Methanone",
      "Piperazine, A2",
      "Cambridge Id 5345452",
      "AC1LF9G0",
      "Oprea1_767331",
      "Oprea1_285639",
      "CHEMBL239457",
      "SCHEMBL10721885",
      "BDBM25773",
      "MolPort-001-016-695",
      "ZINC196133",
      "HMS1676A12",
      "STK020131",
      "AKOS000671240",
      "MCULE-2746904510",
      "BAS 00500757",
      "4-Methylphenyl 4-Phenylpiperazinyl Ketone",
      "ST50682677",
      "AB00081719-01",
      "1-[(4-Methylphenyl)Carbonyl]-4-Phenylpiperazine",
      "SR-01000406866",
      "(4-Methylphenyl)(4-Phenylpiperazin-1-Yl)Methanone",
      "(4-Methylphenyl)-(4-Phenylpiperazin-1-Yl)Methanone",
    ],
  },
  {
    primary_id: "D0I1RP",
    names: ["2-(4-Fluoro-Indole-1-Sulfonyl)-Benzoic Acid", "CHEMBL362888"],
  },
  {
    primary_id: "D0I1RW",
    names: ["Labeled FSH Superagonist"],
  },
  {
    primary_id: "D0I1SI",
    names: ["2,4-Dimethoxy-4'-Amino-Trans-Stilbene", "CHEMBL1170662", "BDBM50322047"],
  },
  {
    primary_id: "D0I1SK",
    names: [
      "Isosorbide Dinitrate",
      "Angidil",
      "Astridine",
      "BiDil",
      "Cardis",
      "Carvanil",
      "Carvasin",
      "Cedocard",
      "Claodical",
      "Cornilat",
      "Coronex",
      "Corosorbide",
      "Corovliss",
      "Difutrat",
      "Dignionitrat",
      "Dilatrate",
      "Diniket",
      "Dinitroisosorbide",
      "Dinitrosorbide",
      "Disorlon",
      "Emoper",
      "EureCor",
      "Flindix",
      "Frandol",
      "Harrical",
      "ISD",
      "ISDN",
      "IsoBid",
      "Isochron",
      "Isodinit",
      "Isoket",
      "Isorbid",
      "Isordil",
      "Isostat",
      "Isotrate",
      "Korodil",
      "Langoran",
      "Laserdil",
      "Lomilan",
      "Maycor",
      "Myorexon",
      "Nitrosorbid",
      "Nitrosorbide",
      "Nitrosorbon",
      "Nosim",
      "Resoidan",
      "Rigedal",
      "Sorbangil",
      "Sorbide",
      "Sorbidilat",
      "Sorbidinitrate",
      "Sorbidnitrate",
      "Sorbislo",
      "Sorbitrate",
      "Sorbonit",
      "Sorquad",
      "Sorquat",
      "Tinidil",
      "Titradose",
      "Vascardin",
      "Vasodilat",
      "Vasorbate",
      "Xanyl",
      "Cedocard Retard",
      "Dilatrate SR",
      "Dinitrato De Isosorbida",
      "Iso Bid",
      "Isomak R",
      "Isordil Tembids",
      "Isosorbide Dinitrato",
      "Isosorbide Dinitrato [DCIT]",
      "Isosorbidi Dinitras",
      "Isosorbidi Nitras",
      "Rifloc Retard",
      "Sorbide Nitrate",
      "Sorbidi Nitras",
      "Cardio 10",
      "Cardonit 40",
      "IBD 20",
      "Isoket Retard 120",
      "Isoket Retard120",
      "Isoket Retard 40",
      "TYB 3215",
      "Cedocard (TN)",
      "D-Isosorbide Dinitrate",
      "Dilatrate-SR",
      "Dinitrate D'isosorbide",
      "Dinitrate, Isosorbide",
      "Dinitrato De Isosorbida [INN-Spanish]",
      "Iso-Bid",
      "Iso-Mack",
      "Iso-Puren",
      "Isoket Retard-120",
      "Isordil (TN)",
      "Isosorbidi Dinitras [INN-Latin]",
      "SDM No. 40",
      "SDM No. 50",
      "SST-101",
      "Sorate-10",
      "Sorate-5",
      "Sorbide, Dinitrate",
      "Sorbitrate (TN)",
      "D-Isosorbide Dinitrate-Lactose Mixture",
      "Dianhydrosorbitol 2,5-Dinitrate",
      "Dilatrate-Sr (TN)",
      "Dinitrate D'isosorbide [INN-French]",
      "Isosorbide 2,5-Dinitrate",
      "Sorbide T.D.",
      "Glucitol, 1,4:3",
      "Isosorbide Dinitrate (JP15/USP/INN)",
      "Isosorbide Dinitrate [USAN:INN:BAN:JAN]",
      "Isosorbide Dinitrate Mixture With Not <60% Lactose, Mannose, Starch Or Calcium Hydrogen Phosphate [UN2907] [Flammable Solid]",
      "D-Glucitol, 1,4:3,6-Dianhydro-, Dinitrate",
      "D-Glucitol, 1,4:3,6-Dianhydro-, 2,5-Dinitrate",
      "[(3S,3aS,6R,6aS)-3-Nitrooxy-2,3,3a,5,6,6a-Hexahydrofuro[3,2-B]furan-6-Yl] Nitrate",
      "1,4:3, 6-Dianhydro-D-Glucitol Dinitrate",
      "1,4:3,6-Dianhydro-D-Glucitol Dinitrate",
      "1,4:3,6-Dianhydrosorbitol 2, 5-Dinitrate",
      "1,4:3,6-Dianhydrosorbitol 2,5-Dinitrate",
      "1,4:3,6-Dianhydro-2,5-Di-O-Nitro-D-Glucitol",
    ],
  },
  {
    primary_id: "D0I1SZ",
    names: [
      "N-(3-METHYLBUT-2-EN-1-YL)-9H-PURIN-6-AMINE",
      "2365-40-4",
      "Isopentenyladenine",
      "N6-Isopentenyladenine",
      "N6-(2-Isopentenyl)Adenine",
      "Isopentenyl Adenine",
      "6-(Gamma,gamma-Dimethylallylamino)Purine",
      "IPADE",
      "Dimethylallyladenine",
      "N6-(2-Isopentenyl)-Adenine",
      "N6-Dimethylallyladenine",
      "N6-(Delta2-Isopentenyl)Adenine",
      "N-(3-Methyl-2-Butenyl)Adenine",
      "N6-(3-Methyl-2-Butenyl)Adenine",
      "N6-(Delta 2-Isopentenyl)-Adenine",
      "N(6)-(Delta(2)-Isopentenyl)Adenine",
      "1H-Purin-6-Amine, N-(3-Methyl-2-Butenyl)-",
      "N-(3-Methylbut-2-Enyl)-7H-Purin-6-Amine",
      "Adenine, N-",
    ],
  },
  {
    primary_id: "D0I1TB",
    names: ["5-(2-Methylquinolin-7-Yl)-2-Phenylnicotinonitrile", "CHEMBL1096197"],
  },
  {
    primary_id: "D0I1TM",
    names: ["FP-1102"],
  },
  {
    primary_id: "D0I1UF",
    names: ["CASUARIIN", "Casuariin", "CHEMBL509562", "SCHEMBL1883046"],
  },
  {
    primary_id: "D0I1VK",
    names: ["Heterocyclic-Substituted 3-Alkyl Azetidine Derivative 2"],
  },
  {
    primary_id: "D0I1WN",
    names: [
      "1,10-(Methylenedi-4,1-Phenylene)Bismaleimide",
      "Bismaleimide",
      "13676-54-5",
      "Bismaleimide S",
      "Bis(4-Maleimidophenyl)Methane",
      "P,p'-Dimaleimidodiphenylmethane",
      "Diphenylmethanebismaleimide",
      "4,4'-Methylenebis(N-Phenylmaleimide)",
      "Bis(P-Maleimidophenyl)Methane",
      "4,4'-Diphenylmethanedimaleimide",
      "4,4'-Dimaleimidodiphenylmethane",
      "4,4'-Diphenylmethanebismaleimide",
      "4,4'-Biphenylmethanebismaleimide",
      "4,4'-Bismaleimidodiphenylmethane",
      "1H-Pyrrole-2,5-Dione, 1,1'-(Methylenedi-4,1-Phenylene)Bis-",
      "4,4'-Methylenebis(Phenylmaleimide)",
      "P,p'-Methylenebis(N-Phenylmaleimide)",
    ],
  },
  {
    primary_id: "D0I1XE",
    names: ["Diphenyl Purine Derivative 3"],
  },
  {
    primary_id: "D0I1XG",
    names: [
      "Salmon Calcitonin",
      "Astronin",
      "Biocalcin",
      "Bionocalcin",
      "Cadens",
      "Calciben",
      "Calcihexal",
      "Calcimar",
      "Calcimonta",
      "Calcinil",
      "Calcioton",
      "Calcitonina",
      "Calcitoran",
      "Calco",
      "Calogen",
      "Calsynar",
      "Caltine",
      "Casalm",
      "Catonin",
      "Cibacalcine",
      "Citonina",
      "Eptacalcin",
      "Forcaltonin",
      "Fortical",
      "Ipocalcin",
      "Kalsimin",
      "Karil",
      "Miacalcic",
      "Miacalcin",
      "Miracalcic",
      "Oseototal",
      "Osseocalcina",
      "Osteobion",
      "Osteovis",
      "Ostosalm",
      "Ostostabil",
      "Porostenina",
      "Prontocalcin",
      "Quosten",
      "Riostin",
      "Rulicalcin",
      "Salcat",
      "Salcatonin",
      "Salcatyn",
      "Salmocalcin",
      "Salmofar",
      "Sical",
      "Stalcin",
      "Staporos",
      "Steocin",
      "Tonocalcin",
      "Ucecal",
      "Calcitonin Salmon",
      "Calcitonin Vom Lachs",
      "Calcitonine De Saumon",
      "Calsynar Lyo L",
      "Recombinant Salmon Calcitonin",
      "Salmon Calcitonin I",
      "Synthetic Salmon Calcitonin",
      "CALCITONIN, SALMON",
      "Calcimar (TN)",
      "Calcitonin (Salmon)",
      "Calcitonin Salmon (Synthesis)",
      "Calcitonin, Salmar",
      "Calcitonin,salmon",
      "Calcitonin-Salmon",
      "Fortical (TN)",
      "Isi-Calcin",
      "Miacalcin (TN)",
      "TZ-CT",
      "Thyrocalcitonin (Salmon)",
      "Calcitonin Salmon (USAN/INN)",
      "Calcitonin Salmon (Synthesis) (JAN)",
      "Calcitonin, Salmon, For Bioassay",
      "Salmon Calcitonin (1-32)",
      "Calcitonin [USAN:INN:BAN:JAN]",
      "Salmon Calcitonin-(I-32)",
    ],
  },
  {
    primary_id: "D0I1XT",
    names: [
      "4-((Benzylideneamino)Methyl)Benzenesulfonamide",
      "CHEMBL582858",
      "4-[(Benzylideneamino)Methyl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0I1YH",
    names: [
      "4-[(3'-Hydroxybiphenyl-4-Yl)Methyl]pyridine",
      "CHEMBL1172882",
      "SCHEMBL18770302",
      "BDBM50322789",
      "4''-(Pyridin-4-Ylmethyl)Biphenyl-3-Ol",
    ],
  },
  {
    primary_id: "D0I1YQ",
    names: ["Pyrazolopyridines And Imidazopyridine Derivative 1"],
  },
  {
    primary_id: "D0I1ZB",
    names: ["ENDG-4010"],
  },
  {
    primary_id: "D0I1ZN",
    names: ["Ac-L-Phe-D-Trp-L-Phe-D-Pro-NH2", "CHEMBL442612"],
  },
  {
    primary_id: "D0I1ZT",
    names: ["ETV-002"],
  },
  {
    primary_id: "D0I1ZY",
    names: [
      "Tralokinumab",
      "CAT-354",
      "Anti-IL 13 Monoclonal Antibody, CAT/AstraZeneca",
      "IL-13 Antagonist, CAT/AstraZeneca",
      "Interleukin-13 Antagonist, CAT/AstraZeneca",
    ],
  },
  {
    primary_id: "D0I2AD",
    names: ["SF-113"],
  },
  {
    primary_id: "D0I2AF",
    names: ["2-Phenyl-2H-Benzo[d][1,2,3]triazole-4-Carboxamide", "CHEMBL1096560", "SCHEMBL2265205"],
  },
  {
    primary_id: "D0I2AG",
    names: [
      "S-(N-4bromophenyl-N-Hydroxycarbamoyl)Glutathione",
      "CHEMBL128872",
      "AC1L9LM2",
      "SCHEMBL3281589",
      "BDBM50092826",
      "L-GammaGlu-S-[Hydroxy(4-Bromophenyl)Carbamoyl]-L-Cys-Gly-OH",
      "(2S)-2-Amino-5-{[(1R)-1-[({[(4-Bromophenyl)(Hydroxy)Amino]carbonyl}thio)Methyl]-2-(Carboxyamino)-2-Oxoethyl]amino}-5-Oxopentanoic Acid",
      "(S)-2-Amino-5-((R)-3-((4-Bromophenyl)Hydroxycarbamoylthio)-1-(Carboxymethylamino)-1-Oxopropan-2-Ylamino)-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D0I2AV",
    names: ["Tositumomab", "Bexxar (TN)"],
  },
  {
    primary_id: "D0I2CE",
    names: ["C[L-MTyr-D-Pro-L-Phe-D-Trp]", "CHEMBL510506"],
  },
  {
    primary_id: "D0I2DB",
    names: ["NSC-37031"],
  },
  {
    primary_id: "D0I2DF",
    names: ["GNF-PF-2893"],
  },
  {
    primary_id: "D0I2DV",
    names: ["1-Phenyl-1,3,4-Triazole Derivative 3"],
  },
  {
    primary_id: "D0I2EL",
    names: ["PMID21444206C3j"],
  },
  {
    primary_id: "D0I2EQ",
    names: ["2,6-Diphenyl-8-Methyl-1-Deazapurine", "CHEMBL220587"],
  },
  {
    primary_id: "D0I2EW",
    names: ["Site-Specific Gene Targeting Agents"],
  },
  {
    primary_id: "D0I2FC",
    names: ["Burnamine-17-O-3',4',5'-Trimethoxybenzoate", "CHEMBL592589", "BDBM50308526"],
  },
  {
    primary_id: "D0I2FI",
    names: [
      "Dequalinium",
      "Dequadin",
      "6707-58-0",
      "UNII-E7QC7V26B8",
      "Dequavagyn",
      "Decamine",
      "Labosept",
      "Sorot",
      "GNF-Pf-5483",
      "E7QC7V26B8",
      "CHEBI:41872",
      "Polycidine",
      "Dequavet",
      "Dekadin",
      "Rumilet",
      "Dekamin",
      "Sentril",
      "Sanoral",
      "Oralgol",
      "Ivazil",
      "Dequadin Chloride",
      "Dequalin Chloride",
      "C30H40N4",
      "DEQ",
      "1,1'-Decane-1,10-Diylbis(4-Amino-2-Methylquinolinium)",
      "Solvidont",
      "Quinolinium, 1,1'-(1,10-Decanediyl)Bis(4-Amino-2-Methyl-",
      "1-[10-(4-Amino-2-Methylquinolyl)Decyl]-2-Methyl-4-Quinolylamine",
      "CHEMBL121663",
    ],
  },
  {
    primary_id: "D0I2FM",
    names: ["Tacrine-Phenothiazine Hybrid Derivative 1"],
  },
  {
    primary_id: "D0I2FZ",
    names: ["IRAK-1/4 Inhibitor"],
  },
  {
    primary_id: "D0I2GK",
    names: [
      "Detrothyronine",
      "DT-3",
      "3,5,3'-Triiodo-D-Thyronine",
      "CHEMBL557",
      "UNII-46XII7C16X",
      "46XII7C16X",
      "Detrothyronin",
      "Dt 3",
      "5714/8/9",
      "Dextro-3:5:3'-Triiodothyronine",
      "Detrothyroninum",
      "Detrotironina",
      "D-Trijodthyronin",
      "DEXTROTHYRONINE",
      "Triiodothyronine, D-",
      "Detrothyronine [INN]",
      "AC1L2FR4",
      "SCHEMBL8301",
      "AC1Q5R0T",
      "DT 3 (Hormone) (VAN)",
      "Detrotironina [INN-Spanish]",
      "Detrothyroninum [INN-Latin]",
      "DT3 (VAN)",
      "D-3,5,3'-Triiodothyronine",
      "CTK4G9296",
      "(2R)-2-Amino-3-[4-(4-Hydroxy-3-Iodophenoxy)-3,5-Diiodophenyl]propanoic Acid",
      "SKF D 2623",
    ],
  },
  {
    primary_id: "D0I2GP",
    names: [
      "2-(2-Cyclohexylidenehydrazinyl)-4-P-Tolylthiazole",
      "CHEMBL1253700",
      "BDBM50326648",
      "ZINC54362881",
      "AKOS005139686",
      "ST51067932",
    ],
  },
  {
    primary_id: "D0I2GS",
    names: ["Contezolid"],
  },
  {
    primary_id: "D0I2GT",
    names: ["TMX-30X"],
  },
  {
    primary_id: "D0I2HH",
    names: ["Ac-WVTH[Cit]LAGLLS[Cit]SGGVVRKNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D0I2HK",
    names: ["Bis-Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D0I2HL",
    names: ["NLC-002"],
  },
  {
    primary_id: "D0I2HV",
    names: ["PF-05402536"],
  },
  {
    primary_id: "D0I2IS",
    names: [
      "Heptane-1,2,3-Triol",
      "(2R,3R)-Heptane-1,2,3-Triol",
      "HTO",
      "AC1L9G8Y",
      "CHEBI:43208",
      "ZINC2043148",
      "LMFA05000549",
      "DB04079",
    ],
  },
  {
    primary_id: "D0I2IY",
    names: ["ABT-700"],
  },
  {
    primary_id: "D0I2JX",
    names: ["3-Isobutoxy-5-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL413487"],
  },
  {
    primary_id: "D0I2JY",
    names: ["R-1206"],
  },
  {
    primary_id: "D0I2KC",
    names: ["N-(2-Amino-5-(Furan-2-Yl)Phenyl)Benzamide", "CHEMBL1097651"],
  },
  {
    primary_id: "D0I2KE",
    names: ["MK-7684"],
  },
  {
    primary_id: "D0I2KW",
    names: ["FV-Aib-TDVGPFAF", "[Aib29,Asp31,Pro34,Phe35]CGRP(27-37)"],
  },
  {
    primary_id: "D0I2KZ",
    names: ["PC-2000"],
  },
  {
    primary_id: "D0I2LX",
    names: ["RMG-40083"],
  },
  {
    primary_id: "D0I2MA",
    names: ["IO-21"],
  },
  {
    primary_id: "D0I2MD",
    names: ["RL-6A"],
  },
  {
    primary_id: "D0I2MK",
    names: [
      "Metoprolol",
      "Beatrolol",
      "BelocDuriles",
      "Betaloc",
      "BetalocAstra",
      "Betalok",
      "Dutoprol",
      "LOPRESSIDONE",
      "Lopresor",
      "Lopresoretic",
      "Lopressor",
      "Meijoprolol",
      "Metohexal",
      "Metoprololum",
      "Metroprolol",
      "Preblok",
      "Presolol",
      "Seloken",
      "Seroken",
      "Spesicor",
      "Spesikor",
      "Toprol",
      "AstraZeneca Brand Of Metaoprolol Tartrate",
      "AstraZeneca Brand Of Seloken",
      "Beloc Duriles",
      "Betaloc Astra",
      "Leiras Brand Of Metoprolol Succinate Or Metoprolol Tartrate",
      "Metoprolol Succinate",
      "Novartis Brand Of Metprolol Tartrate",
      "Seloken AstraZeneca Brand",
      "CGP 2175",
      "CGP2175",
      "H 93 26",
      "H 9326",
      "Beloc-Duriles",
      "Betaloc (TN)",
      "Betaloc-Astra",
      "CGP-2175",
      "Corvitol (TN)",
      "Dl-Metoprolol",
      "H 93-26",
      "Lopressor (TN)",
      "Metoprololum [INN-Latin]",
      "Metrol (TN)",
      "Minax (TN)",
      "Neobloc (TN)",
      "Selo-Zok",
      "Selokeen (TN)",
      "TOPROL-XL",
      "Tartrate, Metoprolol",
      "Metoprolol (USAN/INN)",
      "Metoprolol [USAN:INN:BAN]",
      "Toprol-XL (TN)",
      "(RS)-Metoprolol",
      "1-(Isopropylamino)-3-(4-(2-Methoxyethyl)Phenoxy)Propan-2-Ol",
      "1-(Isopropylamino)-3-(P-(2-Methoxyethyl)Phenoxy)-2-Propanol",
      "1-(Isopropylamino)-3-[4-(2-Methoxyethyl)Phenoxy]propan-2-Ol",
      "1-[(1-Methylethyl)Amino]-3-({4-[2-(Methyloxy)Ethyl]phenyl}oxy)Propan-2-Ol",
      "1-[4-(2-Methoxyethyl)Phenoxy]-3-(Propan-2-Ylamino)Propan-2-Ol",
      "1-[4-(2-Methoxyethyl)Phenoxy]-3-Propan-2-Ylamino-Propan-2-Ol",
      "3-[4-(2-Methoxyethyl)Phenoxy]-1-(Propan-2-Ylamino)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D0I2MR",
    names: ["PMID26651364-Compound-9f"],
  },
  {
    primary_id: "D0I2MU",
    names: ["3-Isobutyl-8-Pyrrolidinoxanthine", "IPDX", "GTPL447", "SCHEMBL2954176", "KHQOTPZSYMSVHB-UHFFFAOYSA-N"],
  },
  {
    primary_id: "D0I2NF",
    names: ["Intragam P", "6% Intravenous Immunoglobulin Therapy (Primary Immunodeficiency), CSL Ltd"],
  },
  {
    primary_id: "D0I2NX",
    names: [
      "N-Benzyl-2-(Toluene-4-Sulfonylamino)-Benzamide",
      "CHEMBL94764",
      "AC1Q2LMI",
      "AC1MT671",
      "ZINC5121035",
      "BDBM50146383",
      "AKOS003681368",
      "MCULE-1042732308",
      "N-Benzyl-2-[(4-Methylphenyl)Sulfonylamino]benzamide",
    ],
  },
  {
    primary_id: "D0I2OO",
    names: ["PMID28270021-Compound-WO2013161919Example85-117"],
  },
  {
    primary_id: "D0I2OP",
    names: [
      "Belaperidone",
      "Balaperidone",
      "Belaperidone [INN]",
      "LU 111995",
      "LU-111995",
      "3-[2-[(1S,5R,6S)-6-(4-Fluorophenyl)-3-Azabicyclo[3.2.0]heptan-3-Yl]ethyl]-1H-Quinazoline-2,4-Dione",
      "3-{2-[(1s,5r,6s)-6-(4-Fluorophenyl)-3-Azabicyclo[3.2.0]hept-3-Yl]ethyl}quinazoline-2,4(1h,3h)-Dione",
    ],
  },
  {
    primary_id: "D0I2PK",
    names: ["Ac-VTHRLAGLLSRSGGVVRKNFVPTDVGPFAF-NH2"],
  },
  {
    primary_id: "D0I2PS",
    names: [
      "CMET Avimer Polypeptides",
      "CMET Avimer Polypeptides (Cancer)",
      "MEDI-555",
      "CMET Avimer Polypeptides (Cancer), MedImmmue",
      "CMET-Targeting Anticancer Avimers, MedImmune",
      "CMET-Targeting Avimers (Cancer), Avidia/MedImmune",
    ],
  },
  {
    primary_id: "D0I2RB",
    names: ["ONO-4641"],
  },
  {
    primary_id: "D0I2RF",
    names: ["Indirubin Derivative 2"],
  },
  {
    primary_id: "D0I2RS",
    names: ["PMID26936077-Compound-34"],
  },
  {
    primary_id: "D0I2RW",
    names: [
      "2,3-Dichloro-1,4-Naphthoquinone",
      "117-80-6",
      "DICHLONE",
      "2,3-Dichloronaphthalene-1,4-Dione",
      "Diclone",
      "2,3-Dichloronaphthoquinone",
      "Phygon",
      "Uniroyal",
      "Sanquinon",
      "Algistat",
      "Phygon Paste",
      "Dichloronaphthoquinone",
      "Phygon XL",
      "Quintar",
      "Compound 604",
      "1,4-Naphthalenedione, 2,3-Dichloro-",
      "Phygon Seed Protectant",
      "U.s. Rubber 604",
      "Quintar 540F",
      "Dichlon",
      "USR 604",
      "2,3-Dichloro-1,4-Naphthaquinone",
      "2,3-Dichloro-1,4-Naphthalenedione",
      "Caswell No. 298",
      "ENT 3,776",
      "1,4-Naphthoquinone, 2,3-Dichloro-",
      "US Rubber 604",
      "Latka 604",
      "Dichlone [BSI:ISO]",
      "Latka 604 [Czech]",
    ],
  },
  {
    primary_id: "D0I2SD",
    names: [
      "Medroxyprogesterone",
      "Asconale",
      "Colirest",
      "Hematrol",
      "Hydroxymethylprogesterone",
      "Lunelle",
      "Medrossiprogesterone",
      "Medroxiprogesterona",
      "Medroxiprogesteronum",
      "Medroxyprogesteron",
      "Medroxyprogesteronum",
      "Methylhydroxyprogesterone",
      "Adgyn Medro",
      "Farlutal Inyectable",
      "Medroprogesterone Acetate",
      "Medrossiprogesterone [DCIT]",
      "Medroxyprogesteron Acetate",
      "Medroxyprogesterone Strakan Brand",
      "Sodelut G",
      "Strakan Brand Of Medroxyprogesterone",
      "MPA Gyn 5",
      "U 8840",
      "CBP-1011",
      "Farlutal Inyectable (TN)",
      "G-Farlutal",
      "Medroxiprogesterona [INN-Spanish]",
      "Medroxyprogesterone (INN)",
      "Medroxyprogesterone [INN:BAN]",
      "Medroxyprogesteronum [INN-Latin]",
      "Novo-Medrone",
      "Pregn-4-Ene-3,20-Dione, 17-Hydroxy-6-Methyl-, (6alpha)-(9CI)",
      "Pregn-4-Ene-3,20-Dione, 17-Hydroxy-6-Methyl-, (6-Alpha)-(9CI)",
      "(6S,8R,9S,10R,13S,14S,17R)-17-Acetyl-17-Hydroxy-6,10,13-Trimethyl-2,6,7,8,9,11,12,14,15,16-Decahydro-1H-Cyclopenta[a]phenanthren-3-One",
      "(6alpha)-17-Hydroxy-6-Methylpregn-4-Ene-3,20-Dione",
      "17 Alpha Hydroxy 6 Alpha Methylprogesterone",
      "17 Alpha-Hydroxy-6 Alpha-Methylprogesterone",
      "17-Hydroxy-6.alpha.-Methylprogesterone",
      "17-Hydroxy-6alpha-Methyl-Pregn-4-Ene-3,20-Dione",
      "17-Hydroxy-6alpha-Methylprogesterone",
      "17.alpha.-Hydroxy-6.alpha.-Methylprogesterone",
      "17alpha-Hydroxy-6alpha-Methyl-4-Pregnene-3,20-Dione",
      "17alpha-Hydroxy-6alpha-Methylprogesterone",
      "6-Dihydromegestrol",
      "6-Alpha-Methyl-17-Alpha-Hydroxyprogesterone",
      "6.alpha.-Methyl-17.alpha.-Hydroxyprogesterone",
      "6alpha-Methyl-17alpha-Hydroxyprogesterone",
      "6alpha-Methyl-4-Pregnen-17alpha-Ol-3,20-Dione",
      "6alpha-Methyl-5-Pregnen-17alpha-Ol-3,20-Dione",
    ],
  },
  {
    primary_id: "D0I2SY",
    names: ["ISIS 140148"],
  },
  {
    primary_id: "D0I2TT",
    names: [
      "RMH-41",
      "D-709119",
      "DNA Binders, Spirogen",
      "DRG-16",
      "DRH-417",
      "ELB-21",
      "GWL-78",
      "SG-2057",
      "ZC-14",
      "Pyrrolobenzodiazepine Derivatives (Cancer/Bacterial Infection), Spirogen",
    ],
  },
  {
    primary_id: "D0I2UC",
    names: ["5-(3-Benzylpyrrolidin-3-Yl)-1-Methyl-1H-Indole", "CHEMBL459176", "SCHEMBL981431"],
  },
  {
    primary_id: "D0I2UE",
    names: ["4-Imidazol-1-Ylmethylthioxanthen-9-One", "CHEMBL1083353"],
  },
  {
    primary_id: "D0I2UH",
    names: ["1-(4-Cyanobenzyl)-5-(2-Methylphenyl)-1H-Imidazole", "CHEMBL610513", "SCHEMBL3822526"],
  },
  {
    primary_id: "D0I2VE",
    names: ["RWJ-56110"],
  },
  {
    primary_id: "D0I2VG",
    names: ["ISIS 144396"],
  },
  {
    primary_id: "D0I2VK",
    names: [
      "Isocarboxazid",
      "BMIH",
      "Benazide",
      "Enerzer",
      "Isocarbonazid",
      "Isocarbossazide",
      "Isocarboxazida",
      "Isocarboxazide",
      "Isocarboxazidum",
      "Isocarboxyzid",
      "Maraplan",
      "Marplan",
      "Marplon",
      "Isocarbossazide [DCIT]",
      "Isocarboxazid (INN)",
      "Isocarboxazid [INN:BAN]",
      "Isocarboxazida [INN-Spanish]",
      "Isocarboxazide [INN-French]",
      "Isocarboxazidum [INN-Latin]",
      "Marplan (TN)",
      "Ro5-0831",
      "Ro 5-0831/1",
      "N'-Benzyl N-Methyl-5-Isoxazolecarboxylhydrazide-3",
      "N'-Benzyl-5-Methyl-1,2-Oxazole-3-Carbohydrazide",
      "1-Benzyl-2-(5-Methyl-3-Isoxazolyl-Carbonyl)Hydrazine",
      "1-Benzyl-2-(5-Methyl-3-Isoxazolylcarbonyl)Hydrazine",
      "3-Isoxazolecarboxylic Acid, 5-Methyl-, 2-(Phenylmethyl)Hydrazide",
      "3-Isoxazolecarboxylic Acid, 5-Methyl-, 2-Benzylhydrazide",
      "5-Methyl-3-Isoxazolecarboxylic Acid 2-Benzylhydrazide",
      "5-Methyl-N'-(Phenylmethyl)Isoxazole-3-Carbohydrazide",
    ],
  },
  {
    primary_id: "D0I2VR",
    names: ["Choline Magnesium Trisalicylate"],
  },
  {
    primary_id: "D0I2VT",
    names: ["ND7001"],
  },
  {
    primary_id: "D0I2VW",
    names: ["GSK2838232"],
  },
  {
    primary_id: "D0I2WQ",
    names: ["11-Heptanoyloxy-N-N-Propylnoraporphine", "CHEMBL516580"],
  },
  {
    primary_id: "D0I2WV",
    names: ["Levamlodipine"],
  },
  {
    primary_id: "D0I2XG",
    names: ["Nocardimicin D"],
  },
  {
    primary_id: "D0I2XM",
    names: ["L-796568"],
  },
  {
    primary_id: "D0I2YV",
    names: [
      "BIBB-515",
      "BIBB 515",
      "156635-05-1",
      "UNII-CG2Q6688S4",
      "CHEMBL417571",
      "CG2Q6688S4",
      "(4-Chloro-Phenyl)-{4-[4-(4,5-Dihydro-Oxazol-2-Yl)-Benzylidene]-Piperidin-1-Yl}-Methanone",
      "(4-Chloro-Phenyl)-(4-[4-(4,5-Dihydro-Oxazol-2-Yl)-Benzylidene]-Piperidin-1-Yl)-Methanone",
      "AC1L9UY8",
      "SCHEMBL3676833",
      "CTK8E8258",
      "DTXSID00333401",
      "MolPort-009-019-333",
      "ZINC598970",
      "BDBM50128071",
      "RT-011501",
      "J-009325",
      "1-(4-Chlorobenzoyl)-4-((4-(2-Oxazolin-2-Yl) Benzylidene))Piperidine",
      "Piperidine, 1-(4-Chlorobenzoyl)-4-((4-(4,5-Dihydro-2-Oxazolyl)Phenyl)Methylene)-",
    ],
  },
  {
    primary_id: "D0I3AH",
    names: ["N-Benzyl[D-Pro-10]Dyn A-(1-11)", "CHEMBL268818", "BDBM50059583"],
  },
  {
    primary_id: "D0I3AS",
    names: ["CD-2475/101"],
  },
  {
    primary_id: "D0I3BB",
    names: ["KW-7158"],
  },
  {
    primary_id: "D0I3BL",
    names: ["Naphthyridinomycin", "AC1MJ44U", "62046-87-1", "DADMe-ImmH"],
  },
  {
    primary_id: "D0I3BS",
    names: ["KRL-401"],
  },
  {
    primary_id: "D0I3BT",
    names: ["PD-135666"],
  },
  {
    primary_id: "D0I3CP",
    names: [
      "SDX-7400",
      "Caplostatin",
      "Methionine Aminopeptidase-2 Inhibitor (Cancer), SynDevRx",
      "Caplostatin Conjugated HPMA Copolymer-TNP-470 (Cancer), SynDevRx",
    ],
  },
  {
    primary_id: "D0I3CV",
    names: ["L-695256"],
  },
  {
    primary_id: "D0I3EE",
    names: ["UCPH-101"],
  },
  {
    primary_id: "D0I3EI",
    names: ["NK-1001"],
  },
  {
    primary_id: "D0I3EQ",
    names: [
      "4-CMTB",
      "2-(4-Chlorophenyl)-3-Methyl-N-(Thiazol-2-Yl)Butanamide",
      "300851-67-6",
      "CHEMBL610463",
      "2-(4-Chlorophenyl)-3-Methyl-N-(1,3-Thiazol-2-Yl)Butanamide",
      "Enamine_000061",
      "AC1Q1O0T",
      "AC1N7SQ9",
      "Oprea1_875848",
      "MLS001018069",
      "GTPL5500",
      "SCHEMBL16369644",
      "Cid_4307629",
      "AOB5572",
      "MolPort-000-514-794",
      "CHEBI:113043",
      "HMS1394C17",
      "HMS2644I21",
      "BDBM50305973",
      "AKOS016375538",
      "AKOS001033996",
      "MCULE-6415878091",
      "4-CMTB, &gt",
      "SMR000354300",
      "SR-01000025543",
      "SR-01000025543-1",
      "Z28173527",
      "4-Chloro-?-(1-Methylethyl)-N-2-Thiazolylbenzeneacetamide",
    ],
  },
  {
    primary_id: "D0I3EY",
    names: ["5-Phenyl-1H-Indazol-3-Amine", "CHEMBL500779", "SCHEMBL3359889", "3.00E+63", "BDBM50254831", "DB07161"],
  },
  {
    primary_id: "D0I3GT",
    names: ["Live Attenuated Recombinant Vaccine"],
  },
  {
    primary_id: "D0I3GZ",
    names: ["TAK-003"],
  },
  {
    primary_id: "D0I3HE",
    names: ["KF26777"],
  },
  {
    primary_id: "D0I3HI",
    names: ["KP-413"],
  },
  {
    primary_id: "D0I3HV",
    names: ["[Mpa1, D-Tyr(Et)2, L-Tic7]OT", "CHEMBL234377"],
  },
  {
    primary_id: "D0I3IG",
    names: ["Alseroxylon"],
  },
  {
    primary_id: "D0I3IO",
    names: ["6-(4-Fluorostyryl)-2-Morpholino-4H-Chromen-4-One", "CHEMBL426654"],
  },
  {
    primary_id: "D0I3IR",
    names: ["Kodaistatin C"],
  },
  {
    primary_id: "D0I3IZ",
    names: [
      "(2-(4-Chlorobenzyloxy)-5-Bromophenyl)Methanamine",
      "CHEMBL233845",
      "SCHEMBL5699914",
      "[5-Bromo-2-[(4-Chlorophenyl)Methoxy]phenyl]methanamine",
      "BDBM50205765",
      "AKOS010099713",
      "Benzenemethanamine, 5-Bromo-2-[(4-Chlorophenyl)Methoxy]-",
    ],
  },
  {
    primary_id: "D0I3JZ",
    names: ["Peginesatide Acetate"],
  },
  {
    primary_id: "D0I3KG",
    names: ["FGGFTGARKSARKKWNQ"],
  },
  {
    primary_id: "D0I3KR",
    names: ["CCG-203971", "Compound 8a [PMID: 23707258]", "CCG203971", "CCG 203971"],
  },
  {
    primary_id: "D0I3KT",
    names: ["PMID26815044-Compound-64"],
  },
  {
    primary_id: "D0I3LE",
    names: [
      "Insulin-Aspart",
      "Aspart",
      "NovoLog",
      "NovoRapid",
      "Aspart Insulin",
      "Insulin Aspart",
      "Insulin Aspart [USAN]",
      "Insulin X14",
      "NovoMix 30",
      "NovoRapid 30 Mix",
      "INA-X 14",
      "Novolog Mix 70/30",
      "AspB28-Insulin (Human)",
      "B28-Asp-Insulin",
      "(AspB28)-Human Insulin",
      "28(Sup B)-L-Aspartic Acid-Insulin (Human)",
    ],
  },
  {
    primary_id: "D0I3LL",
    names: ["Palodesangren D"],
  },
  {
    primary_id: "D0I3LO",
    names: ["IPI-549"],
  },
  {
    primary_id: "D0I3LQ",
    names: ["[3H]estradiol-17beta-Glucuronide", "[3H]-Estradiol-17beta-Glucuronide"],
  },
  {
    primary_id: "D0I3LY",
    names: ["PMID28766366-Compound-Scheme22Middle"],
  },
  {
    primary_id: "D0I3NA",
    names: ["Human-Cl RhFVIII"],
  },
  {
    primary_id: "D0I3NS",
    names: ["PMID25991433-Compound-G2"],
  },
  {
    primary_id: "D0I3OS",
    names: ["Rebmab-100"],
  },
  {
    primary_id: "D0I3OX",
    names: ["ESN-XX"],
  },
  {
    primary_id: "D0I3PD",
    names: ["NSC-649091"],
  },
  {
    primary_id: "D0I3PG",
    names: [
      "SDZ-CPI-975",
      "CPT Inhibitors, Sandoz",
      "Carnitine Palmitoyl Transferase I Inhbitors, Sandoz",
      "SDZ-267-894",
    ],
  },
  {
    primary_id: "D0I3QJ",
    names: ["HX-100"],
  },
  {
    primary_id: "D0I3QU",
    names: ["ISIS 18078"],
  },
  {
    primary_id: "D0I3QY",
    names: ["Benzazepine Derivative 6"],
  },
  {
    primary_id: "D0I3RD",
    names: ["TAK-733"],
  },
  {
    primary_id: "D0I3RN",
    names: ["PGL-2"],
  },
  {
    primary_id: "D0I3RO",
    names: [
      "Droxidopa",
      "23651-95-8",
      "L-DOPS",
      "Northera",
      "(2S,3R)-2-Amino-3-(3,4-Dihydroxyphenyl)-3-Hydroxypropanoic Acid",
      "Threo-Dopaserine",
      "3916-18-5",
      "DOPS",
      "DL-Threo-DOPS",
      "L-Threodops",
      "DL-Threo-3,4-Dihydroxyphenylserine",
      "DL-Threo-Droxidopa",
      "DL-Threo-Dihydroxyphenylserine",
      "UNII-J7A92W69L7",
      "SM 5688",
      "CHEBI:31524",
      "Droxidopa (L-DOPS)",
      "Threo-Beta,3-Dihydroxy-DL-Tyrosine",
      "EINECS 223-480-5",
      "Beta,3-Dihydroxy-DL-Tyrosine Threo-",
      "BRN 2852792",
      "L-Threo-Dihydroxyphenylserine",
      "DL-Threo-3-(3,4-Dihydroxyphenyl)Serine",
      "Serine, 3-(3,4-Dihydroxyphenyl)-, DL-Threo-",
    ],
  },
  {
    primary_id: "D0I3TD",
    names: [
      "CRL-37212",
      "Anaplastic Lymphoma Kinase Inhibitors (Cancer)",
      "CRL-43425",
      "CRL-82007",
      "ALK Inihibitors (Cancer), ChemBridge Research",
      "Anaplastic Lymphoma Kinase Inhibitors (Cancer), ChemBridge Research",
    ],
  },
  {
    primary_id: "D0I3TJ",
    names: ["Fluvax", "Afluria", "Enzira", "Vaccine (Influenza), CSL Ltd"],
  },
  {
    primary_id: "D0I3TN",
    names: ["(1S,2S)-2-(1H-Imidazol-4-Yl)-Cyclopentylamine", "CHEMBL289383"],
  },
  {
    primary_id: "D0I3TS",
    names: ["GW-594884A"],
  },
  {
    primary_id: "D0I3TU",
    names: ["MK-7246"],
  },
  {
    primary_id: "D0I3TX",
    names: [
      "J-104123",
      "CHEMBL143820",
      "GTPL3110",
      "SCHEMBL8623891",
      "BDBM50288819",
      "(R)-4-[(E)-(1S,2S)-2-(3,4-Dichloro-Benzyl)-1-Methyl-4-Naphthalen-2-Yl-But-3-Enylcarbamoyl]-3-Methyl-Butyric Acid",
      "(3R)-4-{[(2S,3S,4E)-3-[(3,4-Dichlorophenyl)Methyl]-5-(Naphthalen-2-Yl)Pent-4-En-2-Yl]carbamoyl}-3-Methylbutanoic Acid",
    ],
  },
  {
    primary_id: "D0I3UA",
    names: [
      "7-Oxo-7H-Dibenzo[de,g]quinoline",
      "CHEMBL559502",
      "38750-39-9",
      "NSC296570",
      "AC1L6XTV",
      "CTK1C6162",
      "DTXSID80315725",
      "7H-Dibenzo[de,g]quinolin-7-One",
      "7H-Dibenzo[de,g]quinoline-7-One",
      "BDBM50293443",
    ],
  },
  {
    primary_id: "D0I3US",
    names: ["9-N-METHYL-8-METHOXY-MANZAMINE A", "9-N-Methyl-8-Methoxy-Manzamine A"],
  },
  {
    primary_id: "D0I3VP",
    names: ["SULOFENUR"],
  },
  {
    primary_id: "D0I3VS",
    names: [
      "Autologous Anti-Gp100 T-Cell Receptor Gene-Engineered Peripheral Blood Lymphocytes",
      "Autologous Anti-Gp100 T-Cell Receptor Gene-Engineered Peripheral Blood Lymphocytes (Melanoma)",
      "Autologous Anti-Gp100 TCR Gene-Engineered PBLs (Melanoma), NCI",
      "Autologous Anti-Gp100 T-Cell Receptor Gene-Engineered Peripheral Blood Lymphocytes (Melanoma), National Cancer Institute",
    ],
  },
  {
    primary_id: "D0I3VT",
    names: [
      "CAD-106",
      "Amilomotide",
      "Alzheimers Disease Vaccine, Cytos/Novartis",
      "Immunodrug Vaccines (Alzheimers Disease), Cytos/Novartis",
      "Beta Amyloid 1-6 Peptide/Qbeta Virus-Like Particle Conjugate (Alzheimer's Disease), Cytos/Novartis",
    ],
  },
  {
    primary_id: "D0I3VV",
    names: ["Telomelysin"],
  },
  {
    primary_id: "D0I3WC",
    names: ["RO-4929097"],
  },
  {
    primary_id: "D0I3XA",
    names: [
      "Ciproxifan",
      "184025-18-1",
      "FUB-359",
      "FUB 359",
      "FUB359",
      "UNII-5EVQ7IRG99",
      "5EVQ7IRG99",
      "CHEMBL14638",
      "ACQBHJXEAYTHCY-UHFFFAOYSA-N",
      "Cyclopropyl-[4-[3-(1H-Imidazol-5-Yl)Propoxy]phenyl]methanone",
      "(4-(3-(1H-Imidazol-4-Yl)Propoxy)Phenyl)(Cyclopropyl)Methanone",
      "Methanone, Cyclopropyl(4-(3-(1H-Imidazol-5-Yl)Propoxy)Phenyl)-",
      "Ciproxifan (FUB-359)",
      "AC1O4Y0P",
      "GTPL1265",
      "SCHEMBL3335184",
      "BDBM27213",
      "EX-A077",
      "DTXSID50171532",
      "Cyclopropyl-(4-(3-1H-Imidazol-4-Yl)Propyloxyphenyl)Ketone",
      "CHEBI:125467",
      "MolPort-027-720-958",
      "ZINC1892860",
    ],
  },
  {
    primary_id: "D0I3XG",
    names: ["Verteporfin"],
  },
  {
    primary_id: "D0I3XN",
    names: [
      "4-Nitrophenylboronic Acid",
      "24067-17-2",
      "(4-Nitrophenyl)Boronic Acid",
      "4-Nitrobenzeneboronic Acid",
      "P-Nitrophenylboronic Acid",
      "4-Nitrophenyl Boronic Acid",
      "P-Nitrophenyl Boronic Acid",
      "4-Nitro Phenyl Boronic Acid",
      "Boronic Acid, (4-Nitrophenyl)-",
      "MFCD00161360",
      "4-Nitrophenylboronic Acid, 97%",
      "4-Nitrophenylboronicacid",
      "4-Borononitrobenzene",
      "PubChem24079",
      "4-Nitrobenzenboronic Acid",
      "(4-Nitrophenyl)Boranediol",
      "4-Nitro-Phenylboronic Acid",
      "ACMC-209g8k",
      "Para-Nitrophenylboronic Acid",
      "4-Nitrobenzene Boronic Acid",
      "AC1MCN49",
      "SCHEMBL5993",
    ],
  },
  {
    primary_id: "D0I3XR",
    names: [
      "Tefibazumab",
      "Aurexis",
      "Monoclonal Antibodies (S Aureus Infections), Inhibitex",
      "Monoclonal Antibodies (Staphylococcus Aureus Infections), Inhibitex",
      "MAb 12-9 (S Aureus Infections), Inhibitex",
      "MAb 12-9 (Staphylococcus Aureus Infections), Inhibitex",
      "SA-MAb (S Aureus Infections), Inhibitex",
      "SA-MAb (Staphylococcus Aureus Infections), Inhibitex",
    ],
  },
  {
    primary_id: "D0I3XV",
    names: ["RTI-5989-25"],
  },
  {
    primary_id: "D0I3XW",
    names: ["NB-598"],
  },
  {
    primary_id: "D0I3XZ",
    names: ["Bis-(5-Hydroxy-1H-Indol-2-Yl)-Methanone"],
  },
  {
    primary_id: "D0I3YK",
    names: ["1,6-Diazanaphthalenes And Pyrido[2,3-D] Pyrimidine Derivative 2"],
  },
  {
    primary_id: "D0I3YX",
    names: [
      "Capadenoson",
      "544417-40-5",
      "UNII-O519NVW73R",
      "O519NVW73R",
      "CHEMBL3235279",
      "BAY 68-4986",
      "2-Amino-6-[[[2-(4-Chlorophenyl)-4-Thiazolyl]methyl]thio]-4-[4-(2-Hydroxyethoxy)Phenyl]-3,5-Pyridinedicarbonitrile",
      "C25H18ClN5O2S2",
      "Capadenoson [INN]",
      "2-Amino-6-({(2-(4-Chlorophenyl)-1,3-Thiazol-4-Yl)Methyl}sulfanyl)-4-(4-(2-Hydroxyethoxy)Phenyl)Pyridine-3,5-Dicarbonitrile",
      "2-Amino-6-({[2-(4-Chlorophenyl)-1,3-Thiazol-4-Yl]methyl}sulfanyl)-4-[4-(2-Hydroxyethoxy)Phenyl]pyridine-3,5-Dicarbonitrile",
      "SCHEMBL174016",
      "Capadenoson;BAY 68-4986",
    ],
  },
  {
    primary_id: "D0I3ZP",
    names: ["[1,4]Oxazepan-(3E)-Ylideneamine", "CHEMBL362772", "SCHEMBL2593607", "BDBM50155790"],
  },
  {
    primary_id: "D0I3ZW",
    names: ["PMID25666693-Compound-159"],
  },
  {
    primary_id: "D0I4AF",
    names: [
      "N-(6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDIN-4-YL)-3-HYDROXY-2,2-DIMETHYLPROPANAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1083016",
      "SCHEMBL1404523",
      "BDBM50320175",
    ],
  },
  {
    primary_id: "D0I4BC",
    names: ["1-(2-(Naphthalen-2-Yl)Ethyl)Pyrrolidine", "CHEMBL270410"],
  },
  {
    primary_id: "D0I4BK",
    names: ["XmAb14045"],
  },
  {
    primary_id: "D0I4BZ",
    names: [
      "(S)-Zacopride",
      "CHEMBL28992",
      "Tocris-1795",
      "NCGC00025295-01",
      "AC1O7H1O",
      "SCHEMBL5373467",
      "ZINC3961",
      "GTPL2289",
      "SCHEMBL16233195",
      "PDSP2_001618",
      "PDSP1_001634",
      "BDBM50056419",
      "UNII-9GN3OT4156 Component FEROPKNOYKURCJ-CYBMUJFWSA-N",
      "4-Amino-N-(S)-1-Aza-Bicyclo[2.2.2]oct-3-Yl-5-Chloro-2-Methoxy-Benzamide",
      "4-Amino-N-[(3S)-1-Azabicyclo[2.2.2]octan-3-Yl]-5-Chloro-2-Methoxybenzamide",
      "4-Amino-N-[(8S)-1-Azabicyclo[2.2.2]octan-8-Yl]-5-Chloro-2-Methoxybenzamide",
    ],
  },
  {
    primary_id: "D0I4DG",
    names: ["Nahuoic Acid A"],
  },
  {
    primary_id: "D0I4DJ",
    names: ["AAE-M-PBP-Amine", "Acetylaminoethyl-Methyl-Phenylbutoxyphenyl-Amine"],
  },
  {
    primary_id: "D0I4DQ",
    names: ["Alprostadil"],
  },
  {
    primary_id: "D0I4EI",
    names: ["NSL-105"],
  },
  {
    primary_id: "D0I4EL",
    names: ["Odulimomab", "Antilfa", "Anti-LFA-1, Aventis"],
  },
  {
    primary_id: "D0I4FF",
    names: [
      "20-Hydroxy-LTB4",
      "20-Hydroxy-Leukotriene B4",
      "20-Hydroxy LTB4",
      "20-OH-LTB4",
      "20-OH-Leukotriene B4",
      "(5S,6Z,8E,10E,12R,14Z)-5,12,20-Trihydroxyicosa-6,8,10,14-Tetraenoic Acid",
      "CHEBI:15646",
      "79516-82-8",
      "5S,12R,20-Trihydroxy-6Z,8E,10E,14Z-Eicosatetraenoic Acid",
      "20-Hydroxyleukotriene B4",
      "(5S,12R)-5,12,20-Trihydroxy-(6Z,8E,10E,14Z)-Eicosatetraenoic Acid",
      "(6Z,8E,10E,14Z)-(5S,12R)-5,12,20-Trihydroxyicosa-6,8,10,14-Tetraenoate",
      "(6Z,8E,10E,14Z)-(5S,12R)-5,12,20-Trihydroxyeicosa-6,8,10,14-Tetraenoate",
      "5,12,20-Trihete",
      "W-Hydroxy-LTB4",
    ],
  },
  {
    primary_id: "D0I4FL",
    names: ["N-1177-Inh"],
  },
  {
    primary_id: "D0I4FP",
    names: ["BAZ2-ICR"],
  },
  {
    primary_id: "D0I4GI",
    names: ["RO-26-9228"],
  },
  {
    primary_id: "D0I4HG",
    names: ["4SCAR19 And 4SCAR38"],
  },
  {
    primary_id: "D0I4HS",
    names: ["PE0139"],
  },
  {
    primary_id: "D0I4HT",
    names: ["4-Naphthalen-1-Yl-5-Piperidin-4-Yl-Isoxazol-3-Ol", "CHEMBL370714", "BDBM50159229"],
  },
  {
    primary_id: "D0I4JB",
    names: ["ISIS 191761"],
  },
  {
    primary_id: "D0I4KB",
    names: ["1-(1-Propionylpiperidin-4-Yl)-3-P-Tolylurea", "CHEMBL1257756", "SCHEMBL2719286", "BDBM50327828"],
  },
  {
    primary_id: "D0I4LB",
    names: ["SM-16"],
  },
  {
    primary_id: "D0I4LL",
    names: [
      "2',4'-Dicyanobiphenyl-4-Yl Sulfamate",
      "CHEMBL592320",
      "SCHEMBL4667745",
      "BDBM50307888",
      "2'',4''-Dicyanobiphenyl-4-Yl Sulfamate",
    ],
  },
  {
    primary_id: "D0I4LM",
    names: ["ABIO-05/01"],
  },
  {
    primary_id: "D0I4LQ",
    names: ["RHIIP"],
  },
  {
    primary_id: "D0I4LX",
    names: [
      "AS-1397",
      "10-(2-(Diethylamino)Propionyl)Phenothiazine",
      "Methyldifazine",
      "10-(Alpha-Diethylaminopropionyl)-Phenothiazine Hydrochloride",
      "AC1L1BEE",
      "AC1Q5K0B",
      "Lopac0_000351",
      "SCHEMBL1320997",
      "CHEMBL376514",
      "NIOSH/SO4600000",
      "BDBM13551",
      "CTK5B9958",
      "2-(Diethylamino)-1-(10H-Phenothiazin-10-Yl)Propan-1-One",
      "CCG-204446",
      "LS-105449",
      "10-(A-Diethyl-Aminopropionyl)-Phenothiazine",
      "SO46000000",
      "Phenothiazine, 10-(2-(Diethylamino)Propionyl)-",
      "2-(Diethylamino)-1-Phenothiazin-10-Ylpropan-1-One",
    ],
  },
  {
    primary_id: "D0I4ME",
    names: ["MMDA"],
  },
  {
    primary_id: "D0I4MQ",
    names: ["AdcAhxArg6", "CHEMBL438544"],
  },
  {
    primary_id: "D0I4MW",
    names: ["DWJ-425", "DW-Ab2", "MAb (RA), Daewoong", "Monoclonal Antibody (Rheumatoid Arthritis), Daewoong"],
  },
  {
    primary_id: "D0I4NN",
    names: [
      "4,5-Dibromo-1H-Pyrrole-2-Carboxylic Acid Amide",
      "CHEMBL356164",
      "34649-20-2",
      "4,5-Dibromopyrrole-2-Carboxamide",
      "4,5-Dibromo-1h-Pyrrole-2-Carboxamide",
      "4,5-Dibromopyrrole-2-Carbonamide",
      "MLS001250248",
      "4,5-Dibromopyrrole-2-Carbamide",
      "CTK1B1045",
      "DTXSID30555154",
      "BDBM50108773",
      "SMR001216076",
      "1H-Pyrrole-2-Carboxamide, 4,5-Dibromo-",
      "4,5-Dibromo-1H-Pyrrole-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0I4OJ",
    names: ["Protectaid"],
  },
  {
    primary_id: "D0I4OV",
    names: [
      "(4-(Thiophen-2-Yl)Phenyl)Methanamine",
      "4-(2-Thienyl)Benzylamine",
      "4-(2-THIENYL)BENZYLAMINE",
      "203436-48-0",
      "1-(4-Thiophen-2-Ylphenyl)Methanamine",
      "[4-(Thiophen-2-Yl)Phenyl]Methanamine",
      "CHEMBL539640",
      "Benzenemethanamine,4-(2-Thienyl)-",
      "Benzenemethanamine, 4-(2-Thienyl)-",
      "SDCCGMLS-0065961P001",
      "AC1MCQW2",
      "AC1Q53XJ",
      "4-(2-Thienyl) Benzylamine",
      "4-(Thien-2-Yl)Benzylamine",
      "SCHEMBL112455",
      "CTK4E3973",
      "DTXSID90379994",
      "YKNLMMDEWQZCLJ-UHFFFAOYSA-N",
      "MolPort-000-142-431",
      "HMS3604G04",
      "ZINC158702",
      "(4-(2-Thienyl)Phenyl)Methylamine",
      "(4-Thiophen-2-Ylphenyl)Methanamine",
      "SBB091000",
    ],
  },
  {
    primary_id: "D0I4OX",
    names: ["SURADISTA"],
  },
  {
    primary_id: "D0I4PB",
    names: ["MCT-485"],
  },
  {
    primary_id: "D0I4PC",
    names: ["Alpha-Conotoxin GI"],
  },
  {
    primary_id: "D0I4PL",
    names: ["DPC-543"],
  },
  {
    primary_id: "D0I4PW",
    names: ["APD371"],
  },
  {
    primary_id: "D0I4QA",
    names: ["Drug 2849330", "2849330"],
  },
  {
    primary_id: "D0I4RK",
    names: ["N-Hydroxy-2,2-Diphenylpropanamide", "CHEMBL585365", "SCHEMBL2844474"],
  },
  {
    primary_id: "D0I4RX",
    names: [
      "TJ-127",
      "Allergy Therapeutics, Phytera/Tsumura",
      "Rheumatoid Arthritis And Allergy Therapeutics, Phytera/Tsumura",
      "Rheumatoid Arthritis Therapeutics, Phytera/Tsumura",
    ],
  },
  {
    primary_id: "D0I4TH",
    names: ["GDC-0068", "AKT Inhibitors"],
  },
  {
    primary_id: "D0I4TO",
    names: [
      "(S)-NORDULOXETINE",
      "CHEMBL595063",
      "SCHEMBL4573573",
      "ZINC34220632",
      "BDBM50304388",
      "(S)-Gamma-(1-Naphthyloxy)-2-Thiophenepropan-1-Amine",
    ],
  },
  {
    primary_id: "D0I4UU",
    names: [
      "FUB 349",
      "4-(4-Phenyl-Butyl)-1H-Imidazole",
      "FUB-349",
      "CHEMBL296450",
      "FUB349",
      "4-(4-Phenylbutyl)-3H-Imidazole",
      "GTPL1259",
      "BDBM50071197",
      "ZINC29405823",
    ],
  },
  {
    primary_id: "D0I4UY",
    names: ["GSK-1108167A"],
  },
  {
    primary_id: "D0I4VT",
    names: ["1-Propyl-3-(3-Trifluoromethyl-Phenyl)-Pyrrolidine", "CHEMBL310607"],
  },
  {
    primary_id: "D0I4VX",
    names: ["DRP-049"],
  },
  {
    primary_id: "D0I4WH",
    names: ["NCX-434"],
  },
  {
    primary_id: "D0I4XF",
    names: ["ALT 005"],
  },
  {
    primary_id: "D0I4XW",
    names: ["SEphB4-HSA"],
  },
  {
    primary_id: "D0I4YK",
    names: ["Methanandamide"],
  },
  {
    primary_id: "D0I4YP",
    names: ["BIOO-5"],
  },
  {
    primary_id: "D0I4ZN",
    names: ["GSK2879552"],
  },
  {
    primary_id: "D0I4ZQ",
    names: ["Collagenase Clostridium Histolyticum"],
  },
  {
    primary_id: "D0I5BN",
    names: ["NV-196"],
  },
  {
    primary_id: "D0I5BY",
    names: ["C[RGDf-(S)-Alpha-TfmF]", "CHEMBL204309"],
  },
  {
    primary_id: "D0I5CN",
    names: ["VRS-859"],
  },
  {
    primary_id: "D0I5CO",
    names: ["NNI-PD"],
  },
  {
    primary_id: "D0I5CS",
    names: ["PD 176252"],
  },
  {
    primary_id: "D0I5CT",
    names: [
      "Amthamine",
      "142437-67-0",
      "2-Amino-5-(2-Aminoethyl)-4-Methylthiazole",
      "5-(2-Aminoethyl)-4-Methyl-1,3-Thiazol-2-Amine",
      "Tocris-0668",
      "5-(2-Aminoethyl)-4-Methylthiazol-2-Amine",
      "AC1L2QR3",
      "SCHEMBL400272",
      "ZINC5099",
      "GTPL4025",
      "CHEMBL293762",
      "BDBM22881",
      "CTK8E9579",
      "CHEBI:92694",
      "DTXSID80162039",
      "AKOS006271424",
      "RL01758",
      "NCGC00024719-01",
      "NCGC00024719-02",
      "5-Thiazoleethanamine, 2-Amino-4-Methyl-",
      "KB-47389",
      "RT-011284",
      "FT-0694778",
      "2-AMINO-4-METHYLTHIAZOLE-5-ETHANAMINE",
      "L000107",
      "J-007652",
    ],
  },
  {
    primary_id: "D0I5CZ",
    names: ["(S)(+)-7-Fluoro-2-Phenylchroman-4-One", "CHEMBL592764"],
  },
  {
    primary_id: "D0I5DE",
    names: ["Benzimidazole Derivative 14"],
  },
  {
    primary_id: "D0I5DM",
    names: ["N-Arylmethyl-N-Phenyl Cyclic Urea Derivative 1"],
  },
  {
    primary_id: "D0I5DP",
    names: ["AMG 176"],
  },
  {
    primary_id: "D0I5DS",
    names: ["Meprednisone", "Betapar"],
  },
  {
    primary_id: "D0I5DV",
    names: ["PMID25399762-Compound-Figure2-Spirooliganone B"],
  },
  {
    primary_id: "D0I5ED",
    names: ["Placental Growth Factor"],
  },
  {
    primary_id: "D0I5EU",
    names: ["RG7421+RG7204", "Cobimetinib+vemurafenib", "Vemurafenib + Cobimetinib (BRAF Inhibitor + MEK Inhibitor)"],
  },
  {
    primary_id: "D0I5FP",
    names: ["RHAMM-R3 Peptide Vaccine"],
  },
  {
    primary_id: "D0I5GC",
    names: ["Dry-Powder Peptide YY(3-36)", "Dry-Powder Peptide YY(3-36) (Obesity)"],
  },
  {
    primary_id: "D0I5GS",
    names: ["CAR.CD30 Cells"],
  },
  {
    primary_id: "D0I5HE",
    names: ["NPY5RA-972"],
  },
  {
    primary_id: "D0I5HF",
    names: ["Apixaban"],
  },
  {
    primary_id: "D0I5HV",
    names: [
      "Articaine",
      "Carticaine",
      "23964-58-1",
      "Articaine [INN:BAN]",
      "Articainum [INN-Latin]",
      "Articaina [INN-Spanish]",
      "Hoe-045",
      "Ultracain",
      "HOE 045",
      "Methyl 4-Methyl-3-[(N-Propylalanyl)Amino]thiophene-2-Carboxylate",
      "Methyl 4-Methyl-3-[2-(Propylamino)Propanoylamino]thiophene-2-Carboxylate",
      "Methyl (4-Methyl-3-(2-(Propylamino)Propionamido)-2-Thiophencarboxylat)",
      "Q-200652",
      "Articainum",
      "Articaina",
      "4-Methyl-3-(2-(Propylamino)Propionamido)-2-Thiophenecarboxylic Acid, Methyl Ester",
      "2-Thiophenecarboxylicacid, 4-Methyl-3-[[1-Oxo-2-(Propylamino)Propy",
    ],
  },
  {
    primary_id: "D0I5HZ",
    names: ["4-(2-Phenyl-1H-Benzo[d]imidazol-1-Yl)Phenol", "CHEMBL200483", "SCHEMBL6440186"],
  },
  {
    primary_id: "D0I5IM",
    names: ["LY-518674"],
  },
  {
    primary_id: "D0I5IS",
    names: [
      "MBS-103",
      "Bismuth Thiol Based Antibacterial Agent (Inhaled, Pulmonary Tuberculosis), Microbion",
      "Bismuth Thiol Based Agent (Inhalant, Pulmonary Tuberculosis/Cystic Fibrosis/Lung Infection/COPD/Pneumonia), Microbion",
    ],
  },
  {
    primary_id: "D0I5JM",
    names: ["LC-350189"],
  },
  {
    primary_id: "D0I5JP",
    names: ["NSC-137546"],
  },
  {
    primary_id: "D0I5KI",
    names: ["Dibenzo [c,f]-[2,7]naphthyridine Derivative 1"],
  },
  {
    primary_id: "D0I5KX",
    names: ["Thymalfasin"],
  },
  {
    primary_id: "D0I5LM",
    names: ["R-1065"],
  },
  {
    primary_id: "D0I5LV",
    names: ["3-Methoxyphenyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL481871"],
  },
  {
    primary_id: "D0I5ND",
    names: ["CNX-010", "11-Beta Hydroxysteroid Dehydrogenase 1 Inhibitors (Type 2 Diabetes), Connexios Life Sciences"],
  },
  {
    primary_id: "D0I5NN",
    names: ["Valtorim"],
  },
  {
    primary_id: "D0I5NW",
    names: [
      "3-(3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "Dihydronaphthalene 2",
      "AC1O70H5",
      "BDBM8889",
      "SCHEMBL4508891",
      "CHEMBL205881",
    ],
  },
  {
    primary_id: "D0I5OM",
    names: ["Dextrin-2-Sulphate (D2S) Intravaginal Gel"],
  },
  {
    primary_id: "D0I5OR",
    names: [
      "Tetrahydrouridine",
      "3,4,5,6-Tetrahydrouridine",
      "NSC-112907",
      "NSC 112907",
      "CHEMBL2311128",
      "18771-50-1",
      "U 23284",
      "NSC-112907-D",
      "BRN 0752319",
      "2(1H)-Pyrimidinone, Tetrahydro-4-Hydroxy-1-Beta-D-Ribofuranosyl-",
      "NSC112907",
      "AC1L1H1G",
      "SCHEMBL587704",
      "3,4,5, 6-Tetrahydrouridine",
      "2(1H)-Pyrimidinone, Tetrahydro-4-Hydroxy-1-.beta.-D-Ribofuranosyl-",
      "UCKYOOZPSJFJIZ-XVKVHKPRSA-N",
      "MolPort-044-561-340",
      "BDBM50421666",
      "AKOS032953792",
      "DB12484",
      "LS-7719",
      "CS-7633",
      "HY-15345",
      "3,4,5,6-Tetrahydrouridine (&gt",
      "80% By HPLC)",
      "J-012078",
    ],
  },
  {
    primary_id: "D0I5PM",
    names: [
      "DW-908e",
      "Allergy Therapeutics, Daiichi",
      "Asthma Therapeutics, Daiichi",
      "PS-181895",
      "PS-460644",
      "PS-489655",
      "PS-969819",
      "Asthma/Allergy Therapeutics, Daiichi",
    ],
  },
  {
    primary_id: "D0I5PY",
    names: ["1-(Biphenyl-3-Ylmethyl)-1H-1,2,4-Triazole", "CHEMBL598847", "SCHEMBL10172554"],
  },
  {
    primary_id: "D0I5PZ",
    names: ["N'-Pyridoxyl-Lysine-5'-Monophosphate"],
  },
  {
    primary_id: "D0I5QK",
    names: [
      "Dual PI3K/MTOR Inhibitors",
      "Dual PI3K/MTOR Inhibitors (Cancer)",
      "Dual PI3K/MTOR Inhibitors (Cancer), CNIO",
    ],
  },
  {
    primary_id: "D0I5QL",
    names: ["CRD-5", "Charge-Regulating Drugs (Dyslipidemia/Heart Disease), Liponex"],
  },
  {
    primary_id: "D0I5QV",
    names: ["VIR-201"],
  },
  {
    primary_id: "D0I5QX",
    names: ["Etanercept"],
  },
  {
    primary_id: "D0I5QZ",
    names: ["SQ-26332"],
  },
  {
    primary_id: "D0I5RR",
    names: ["V-503"],
  },
  {
    primary_id: "D0I5SD",
    names: ["Azetidine Derivative 1"],
  },
  {
    primary_id: "D0I5TC",
    names: ["Cyanocobalamin Co-57"],
  },
  {
    primary_id: "D0I5TL",
    names: [
      "ADX-48621",
      "Dipraglurant",
      "872363-17-2",
      "UNII-CV8JZR21A1",
      "ADX48621",
      "CV8JZR21A1",
      "CHEMBL2346738",
      "6-Fluoro-2-[4-(2-Pyridinyl)-3-Butyn-1-Yl]-Imidazo[1,2-A]pyridine",
      "Dipraglurant [INN]",
      "SCHEMBL103033",
      "GTPL6452",
      "C16H12FN3",
      "DTXSID90236230",
      "BCP23706",
      "BDBM50431705",
      "ZINC72266314",
      "3590AH",
      "6-Fluoro-2-(4-(Pyridin-2-Yl)But-3-Yn-1-Yl)Imidazo(1,2-A)Pyridine",
      "AKOS030526956",
      "SB16993",
      "DB12733",
      "CS-0690",
      "NCGC00351745-02",
      "HY-14859",
      "DB-076892",
      "FT-0705373",
      "J3.560.334D",
      "W-5761",
      "ADX 48621;ADX48621;ADX-48621",
    ],
  },
  {
    primary_id: "D0I5TQ",
    names: [
      "2-Amino-6-(Toluene-4-Sulfonyl)-Benzonitrile",
      "AC1LA8E0",
      "CHEMBL53896",
      "BDBM1787",
      "CTK7C6568",
      "ZINC5930941",
      "2-Amino-6-(P-Tolylsulfonyl)Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3g",
      "2-Amino-6-(4-Methylphenyl)Sulfonylbenzonitrile",
      "2-(4-Methylphenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-[(4-Methylbenzene)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0I5TT",
    names: ["EPI-2221"],
  },
  {
    primary_id: "D0I5TV",
    names: [
      "DATPalphaS",
      "Datpalphas",
      "[[(2R,3S,5R)-5-(6-Aminopurin-9-Yl)-3-Hydroxyoxolan-2-Yl]methoxy-Hydroxyphosphinothioyl] Phosphono Hydrogen Phosphate",
      "2'-Deoxyadenosine 5'-O-(1-Thiotriphosphate)",
      "Alpha-Thio-Datp",
      "Sp-Datp Alpha S",
      "Sp-DATP-.alpha.-S",
      "AC1L51OG",
      "SCHEMBL222923",
      "GTPL1715",
      "CHEMBL2390988",
      "Alpha-Thiodeoxyadenosine Triphosphate",
      "Deoxyadenosine 5'-(Alpha-Thio)Triphosphate",
      "2'-Deoxyadenosine-5'-O-(1-Thiotriphosphate)",
      "(Sp-)2'- Deoxyadenosine- 5'- O- (.alpha.-Thiotriphosphate)",
      "Adenosine, 2'-Deoxy-, 5'-P''-Ester With Thiotriphosphoric Ac",
    ],
  },
  {
    primary_id: "D0I5US",
    names: ["UB-421"],
  },
  {
    primary_id: "D0I5UU",
    names: ["Norastemizole"],
  },
  {
    primary_id: "D0I5UY",
    names: ["Imidazo[5,1-C][1,2,4]benzotriazine Derivative 2"],
  },
  {
    primary_id: "D0I5VA",
    names: [
      "CF502",
      "CHEMBL175543",
      "MRS-3558",
      "GTPL5601",
      "MRS3558",
      "MRS-5358",
      "MRS 3558",
      "BDBM50163020",
      "(1S,2R,3S,4R,5S)-4-[2-Chloro-6-(3-Chloro-Benzylamino)-Purin-9-Yl]-2,3-Dihydroxy-Bicyclo[3.1.0]hexane-1-Carboxylic Acid Methylamide",
      "(1S,2R,3S,4R,5S)-4-(6-(3-Chlorobenzylamino)-2-Chloro-9H-Purin-9-Yl)-2,3-Dihydroxy-N-Methylbicyclo[3.1.0]hexane-1-Carboxamide",
      "1N-Methyl-4-[2-Chloro-6-(3-Chlorobenzylamino)-9H-9-Purinyl]-2,3-Dihydroxy-(1S,2R,3S,4R,5S)-Bicyclo[3.1.0]hexane-1-Carboxamide",
    ],
  },
  {
    primary_id: "D0I5VG",
    names: ["PMID25656651-Compound-29b"],
  },
  {
    primary_id: "D0I5VJ",
    names: ["THIOGENISTEIN"],
  },
  {
    primary_id: "D0I5WB",
    names: ["Clomiphene Citrate", "Clomid", "Milophene", "Serophene"],
  },
  {
    primary_id: "D0I5WJ",
    names: ["Blisibimod"],
  },
  {
    primary_id: "D0I5WO",
    names: [
      "Lymphocyte Immune Globulin, Anti-Thymocyte Globulin",
      "Atgam",
      "Lymphocyte Immune Globulin, Anti-Thymocyte Globulin (Equine)",
      "Lymphocyte Immune Globulin, Anti-Thymocyte Globulin (Equine), Pharmacia & Upjohn",
    ],
  },
  {
    primary_id: "D0I5XM",
    names: [
      "Azithromycin-N-Benzyltriazolylhexahydroxamic Acid",
      "CHEMBL446811",
      "SCHEMBL14328156",
      "CHEMBL3735805",
      "BDBM27177",
      "Triazole-Linked Azithromycin-Based Compound, 16a",
    ],
  },
  {
    primary_id: "D0I5XU",
    names: ["PF-3635659"],
  },
  {
    primary_id: "D0I5YK",
    names: ["Decynium 22"],
  },
  {
    primary_id: "D0I5YQ",
    names: ["4-Nitrophenyl-Difluoromethanesulfonamide", "CHEMBL370159", "BDBM50175001"],
  },
  {
    primary_id: "D0I5YS",
    names: [
      "2-(2-Chlorophenyl)-2,2-Diphenylethanamide",
      "CHEMBL406683",
      "AC1NNFGO",
      "Amino-2-Chlorotrityl Resin",
      "SCHEMBL1001586",
      "2-Chlorotriphenylmethyl Amine Resin",
      "(2-Chlorophenyl)-Diphenylmethanamine",
      "2-Chlorotrityl Amine, Polymer-Bound",
      "BDBM50371377",
      "2-Chlorotrityl Amine, Polymer-Bound, 100-200 Mesh, Extent Of Labeling: 1.3-1.8 Mmol/G Loading, 1 % Cross-Linked",
    ],
  },
  {
    primary_id: "D0I5YT",
    names: ["3,4'-(Thiophene-2,4-Diyl)Diphenol"],
  },
  {
    primary_id: "D0I5YU",
    names: ["4-(Propylthio)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL462664", "BDBM50247958"],
  },
  {
    primary_id: "D0I5ZM",
    names: ["Remogli Ozin-Etabonate"],
  },
  {
    primary_id: "D0I5ZO",
    names: ["AS-1522489-00"],
  },
  {
    primary_id: "D0I6AE",
    names: ["(2'Z,3'E)-5-Nitro-5'-Chloro-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0I6AN",
    names: ["T140", "[L-3-(2-Naphthyl)-Alanine3]-T134"],
  },
  {
    primary_id: "D0I6AU",
    names: ["BDD-10103"],
  },
  {
    primary_id: "D0I6CF",
    names: ["Iodophenyl-N-Methyl-N-Fluoroalkyl-3-Isoquinoline Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0I6DO",
    names: ["Bufrudin, Biopharm", "137879-02-8", "Bufrudin"],
  },
  {
    primary_id: "D0I6EH",
    names: [
      "Cudraxanthone D",
      "96552-41-9",
      "CHEMBL425926",
      "MolPort-035-706-470",
      "ZINC13411981",
      "BDBM50175019",
      "AKOS032948528",
      "4CN-1670",
      "W2633",
      "2,3,8-Trihydroxy-6-Methoxy-5-(2-Methyl-3-Buten-2-Yl)-1-(3-Methyl- 2-Buten-1-Yl)-9h-Xanthen-9-One",
    ],
  },
  {
    primary_id: "D0I6EJ",
    names: ["3-(4-Phenyl-2,4'-Bipyridin-6-Yl)Phenol", "CHEMBL1095650"],
  },
  {
    primary_id: "D0I6EK",
    names: [
      "P10s-Padre/Montanide ISA 51 Vaccine",
      "Carbohydrate Mimetic Peptide Vaccine (Breast Cancer), University Of Arkansas",
      "P10s-Padre/MontanideISA 51 Vaccine (Breast Cancer)",
      "Peptide Mimotope-Based Vaccine (Breast Cancer), University Of Arkansas",
      "P10s-Padre/Montanide ISA 51 Vaccine (Breast Cancer), University Of Arkansas",
    ],
  },
  {
    primary_id: "D0I6EU",
    names: ["PO3 2-Leu-Trp-O-3K", "CHEMBL281562"],
  },
  {
    primary_id: "D0I6FA",
    names: ["Hemoglobin Crosfumaril"],
  },
  {
    primary_id: "D0I6FS",
    names: ["Rh-Bmp-2/Acs"],
  },
  {
    primary_id: "D0I6GC",
    names: ["NOGGIN"],
  },
  {
    primary_id: "D0I6GH",
    names: ["3-(Phenoxymethyl)-5H-Indeno[1,2-C]pyridazin-5-One", "CHEMBL239282", "Aza-Heterocyclic Derivative, 1e"],
  },
  {
    primary_id: "D0I6HB",
    names: ["Quinazolinone Derivative 5"],
  },
  {
    primary_id: "D0I6HJ",
    names: [
      "INC-106",
      "Angiotensin II MAb (Restenosis), Inncardio/Queen Mary And Westfield College",
      "Angiotensin II Type I Receptor Monoclonal Antibody (Restenosis), Inncardio/Queen Mary Univeristy",
    ],
  },
  {
    primary_id: "D0I6HV",
    names: ["NNC 92-1687"],
  },
  {
    primary_id: "D0I6IB",
    names: [
      "Tenoxicam",
      "Artriunic",
      "Liman",
      "Mobiflex",
      "Reutenox",
      "Tenoxicamum",
      "Tilcotil",
      "Apotex Brand Of Tenoxicam",
      "Novag Brand Of Tenoxicam",
      "Novopharm Brand Of Tenoxicam",
      "Roche Brand Of Tenoxicam",
      "Solvay Brand Of Tenoxicam",
      "Apo-Tenoxicam",
      "Mobiflex (TN)",
      "Novo-Tenoxicam",
      "Ro 12-0068",
      "Ro-120068",
      "Tenoxicamum [INN-Latin]",
      "Tilcotil (TN)",
      "Ro 12-0068/000",
      "Ro-12-0068",
      "Tenoxicam (JAN/USAN/INN)",
      "Tenoxicam [USAN:BAN:INN:JAN]",
      "(3E)-3-[hydroxy-(Pyridin-2-Ylamino)Methylidene]-2-Methyl-1,1-Dioxothieno[2,3-E]thiazin-4-One",
      "(3Z)-3-[hydroxy-(Pyridin-2-Ylamino)Methylidene]-2-Methyl-1,1-Dioxothieno[2,3-E]thiazin-4-One",
      "2H-Thieno(2,3-E)-1,2-Thiazine-3-Carboxamide, 4-Hydroxy-2-Methyl-N-2-Pyridinyl-, 1,1-Dioxide",
      "3-[hydroxy-(Pyridin-2-Ylamino)Methylidene]-2-Methyl-1,1-Dioxothieno[2,3-E]thiazin-4-One",
      "4-HYDROXY-2-METHYL-N-2-PYRIDINYL-2H-THIENO[2,3-E]-1,2-THIAZINE-3-CARBOXAMIDE 1,1-DIOXIDE",
      "4-Hydroxy-2-Methyl-N-2-Pyridinyl-2H-Thieno(2,3-E)-1,2-Thiazine-3-Carboxamide1,1-Dioxide",
      "4-Hydroxy-2-Methyl-N-2-Pyridyl-2H-Thieno(2,3-E)-1,2-Thiazine-3-Carboxamide 1,1-Dioxide",
      "4-Hydroxy-2-Methyl-N-(Pyridin-2-Yl)-2H-Thieno[2,3-E][1,2]thiazine-3-Carboxamide 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D0I6II",
    names: [
      "3,4-Dihydroquinazolin-2-Amine Hydrobromide",
      "CHEMBL557381",
      "SCHEMBL2386507",
      "RXCXYMVUYRKQAO-UHFFFAOYSA-N",
      "1,4-Dihydro-Quinazolin-2-Ylamine Monohydrobromide",
    ],
  },
  {
    primary_id: "D0I6IP",
    names: [
      "Candicidin",
      "(23E,25E,27E,29E,31E,33E,35E)-22-(4-Amino-3,5-Dihydroxy-6-Methyloxan-2-Yl)Oxy-10,12,14,18,20-Pentahydroxy-37-Methyl-2,4,8,16-Tetraoxo-1-Oxacyclooctatriaconta-23,25,27,29,31,33,35-Heptaene-19-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0I6IQ",
    names: ["Neihulizumab"],
  },
  {
    primary_id: "D0I6IR",
    names: ["PMID29130358-Compound-Figure14(6)"],
  },
  {
    primary_id: "D0I6JF",
    names: ["Sodium Phosphate P 32"],
  },
  {
    primary_id: "D0I6JG",
    names: ["Organon-3"],
  },
  {
    primary_id: "D0I6JQ",
    names: [
      "S-(N-4chlorophenyl-N-Hydroxycarbamoyl)Glutathione",
      "CHEMBL131578",
      "SCHEMBL3281101",
      "BDBM50092824",
      "S-(N-P-Chlorophenyl-N-Hydroxycarbamoyl)Glutathione",
    ],
  },
  {
    primary_id: "D0I6KC",
    names: ["HydroVAX"],
  },
  {
    primary_id: "D0I6KF",
    names: [
      "(S)-(-)-(4,5,6-Trimethoxyindan-1-Yl)Methanamine",
      "CHEMBL211301",
      "890309-58-7",
      "CTK2I1397",
      "DTXSID10467882",
      "ZINC35569548",
      "BDBM50190614",
      "1H-Indene-1-Methanamine, 2,3-Dihydro-4,5,6-Trimethoxy-, (1S)-",
    ],
  },
  {
    primary_id: "D0I6KU",
    names: ["5-(2-M-Tolylethynyl)Pyrimidine", "5-(M-Tolylethynyl)Pyrimidine", "CHEMBL508497"],
  },
  {
    primary_id: "D0I6LD",
    names: ["Monovalent Oral Polio Vaccine 1"],
  },
  {
    primary_id: "D0I6LH",
    names: ["Triptolide"],
  },
  {
    primary_id: "D0I6LN",
    names: ["INP103"],
  },
  {
    primary_id: "D0I6LO",
    names: ["PMID25435285-Compound-47"],
  },
  {
    primary_id: "D0I6LR",
    names: ["BL-6030", "EDP-05", "Bacterial Quorum Sensing Inhibitor (Bacterial Infection), BioLineRx"],
  },
  {
    primary_id: "D0I6MA",
    names: ["DC-EBIO", "Dichloro-EBIO"],
  },
  {
    primary_id: "D0I6MR",
    names: ["NN1406"],
  },
  {
    primary_id: "D0I6MT",
    names: ["N6-[(4-Amino)-Phenyl]-9-Benzyl-2-Phenyladenine", "CHEMBL496985"],
  },
  {
    primary_id: "D0I6MZ",
    names: ["Colorectal Cancer Vaccine"],
  },
  {
    primary_id: "D0I6NH",
    names: ["Anti-PD-L1 MAb"],
  },
  {
    primary_id: "D0I6OI",
    names: ["RG7601"],
  },
  {
    primary_id: "D0I6OZ",
    names: ["Biosuperior Protein Therapeutic", "Biosuperior Protein Therapeutic (Hematological Disease)"],
  },
  {
    primary_id: "D0I6PP",
    names: ["VaxiGrip QIV IM"],
  },
  {
    primary_id: "D0I6QE",
    names: ["Alpha-Sulfanyl(4-Chlorobenzyl)Phosphonic Acid"],
  },
  {
    primary_id: "D0I6QN",
    names: ["K-454"],
  },
  {
    primary_id: "D0I6QZ",
    names: ["Org-37663"],
  },
  {
    primary_id: "D0I6RC",
    names: ["Benzyl Phenyl Ether Derivative 2"],
  },
  {
    primary_id: "D0I6RE",
    names: ["5-(2-Hydroxyethyl)Nonane-1,9-Diol", "DB07171"],
  },
  {
    primary_id: "D0I6RI",
    names: ["GRNVAC1"],
  },
  {
    primary_id: "D0I6RL",
    names: [
      "2-(Phenylsulfonamido)-1-Naphthoic Acid",
      "1-Naphthalenecarboxylic Acid, 2-[(Phenylsulfonyl)Amino]-",
      "677290-81-2",
      "Sulfonamide Compound, 9",
      "SCHEMBL3986500",
      "CHEMBL377198",
      "CTK1H6801",
      "BDBM17603",
      "DTXSID20471157",
      "CXPIFTCBANXJNX-UHFFFAOYSA-N",
      "AKOS030618475",
      "2-[(Phenylsulfonyl)Amino]-1-Naphthoic Acid",
      "2-Benzenesulfonamidonaphthalene-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0I6SI",
    names: [
      "3-Hydroxy-2-Propionylcyclohex-2-Enone",
      "CHEMBL308796",
      "62847-90-9",
      "AC1LBQYE",
      "2-(1-Hydroxypropylidene)Cyclohexane-1,3-Dione",
      "CTK2B1414",
      "DTXSID40343105",
      "ROVGIVWUAMEQQN-UHFFFAOYSA-N",
      "ZINC4786244",
      "2-Cyclohexen-3-Ol-1-One, 2-Propioyl-",
      "2-Propionyl-3-Hydroxy-2-Cyclohexene-1-One",
      "3-Hydroxy-2-Propionyl-2-Cyclohexen-1-One #",
      "2-Cyclohexen-1-One, 3-Hydroxy-2-(1-Oxopropyl)-",
    ],
  },
  {
    primary_id: "D0I6UA",
    names: ["PMID27967267-Compound-27"],
  },
  {
    primary_id: "D0I6UE",
    names: ["STI-002"],
  },
  {
    primary_id: "D0I6UK",
    names: [
      "BO-0742",
      "AHMA Derivatives (Anticancer)",
      "BO-0796",
      "BO-0941",
      "AHMA Derivatives (Anticancer), Memorial Sloan-Kettering",
    ],
  },
  {
    primary_id: "D0I6UM",
    names: ["NSC-745885"],
  },
  {
    primary_id: "D0I6UN",
    names: ["AbGn-150", "Antibody-150"],
  },
  {
    primary_id: "D0I6UU",
    names: ["UK-74505"],
  },
  {
    primary_id: "D0I6UV",
    names: [
      "1-[(Z)-4-(Triphenylmethoxy)-2-Butenyl]thymine",
      "CHEMBL101135",
      "5-Methyl-1-[(Z)-4-Trityloxybut-2-Enyl]pyrimidine-2,4-Dione",
      "AC1O54TL",
      "5-Methyl-1-(4-Trityloxy-But-2-Enyl)-1H-Pyrimidine-2,4-Dione",
      "SCHEMBL19196301",
      "BDBM50118490",
      "5-Methyl-1-[(2Z)-4-(Trityloxy)But-2-Enyl]pyrimidine-2,4(1H,3H)-Dione",
    ],
  },
  {
    primary_id: "D0I6VK",
    names: ["Carbamate Derivative 6"],
  },
  {
    primary_id: "D0I6VU",
    names: ["Enzastaurin"],
  },
  {
    primary_id: "D0I6WA",
    names: ["THIIC"],
  },
  {
    primary_id: "D0I6WJ",
    names: ["Carbamide Derivative 14"],
  },
  {
    primary_id: "D0I6XA",
    names: ["(2R,3S)-3-(6-Amino-Purin-9-Yl)-Nonan-2-Ol", "3-(6-Amino-Purin-9-Yl)-Nonan-2-Ol"],
  },
  {
    primary_id: "D0I6XJ",
    names: ["AMP-X-0001"],
  },
  {
    primary_id: "D0I6YD",
    names: ["BPH-252", "BPH 252", "BPH252"],
  },
  {
    primary_id: "D0I6YL",
    names: ["Chimeric Small Nucleolar RNA-TAR Decoy"],
  },
  {
    primary_id: "D0I6YM",
    names: ["Ferroquine"],
  },
  {
    primary_id: "D0I6YT",
    names: ["PMID25522065-Compound-42"],
  },
  {
    primary_id: "D0I6ZO",
    names: ["AZD-5213"],
  },
  {
    primary_id: "D0I6ZS",
    names: [
      "Dimethylnordihydroguarierate Acid",
      "CHEMBL103600",
      "AC1L9VQY",
      "CTK7A7114",
      "ZINC13780579",
      "BDBM50065997",
      "Meso-1,4-Bis-(3-Hydroxy-4-Methoxyphenyl)-2,3-Dimethylbutane",
      "5,5'-[(2R,3S)-2,3-Dimethyl-1,4-Butanediyl]bis(2-Methoxyphenol)",
      "5-[(2S,3R)-4-(3-Hydroxy-4-Methoxyphenyl)-2,3-Dimethylbutyl]-2-Methoxyphenol",
      "5-[(2r,3s)-4-(3-Hydroxy-4-Methoxyphenyl)-2,3-Dimethylbutyl]-2-Methoxyphenol",
      "5-[4-(3-Hydroxy-4-Methoxyphenyl)-2,3-Dimethyl-(2R,3S)-Butyl]-2-Methoxyphenol",
    ],
  },
  {
    primary_id: "D0I7AJ",
    names: ["PMID29338548-Compound-14"],
  },
  {
    primary_id: "D0I7AL",
    names: ["(R)-EF-1520", "GTPL6512"],
  },
  {
    primary_id: "D0I7BD",
    names: ["CYT-006-AngQb", "AngQb", "Immunodrug Vaccines (Hypertension), Cytos"],
  },
  {
    primary_id: "D0I7BL",
    names: [
      "ARISTEROMYCIN",
      "19186-33-5",
      "3-(6-Aminopurin-9-Yl)-5-(Hydroxymethyl)Cyclopentane-1,2-Diol",
      "NSC613806",
      "NSC615828",
      "NSC613807",
      "NSC103526",
      "Carbocyclic Lyxo-A",
      "Carbocyclic Xylo-A",
      "AC1Q4VH0",
      "Cyclaradine Carbocyclic Ara A",
      "SCHEMBL9820408",
      "AC1L1D83",
      "CTK4E0829",
      "3-(6-Amino-9h-Purin-9-Yl)-5-(Hydroxymethyl)Cyclopentane-1,2-Diol",
      "NSC-613807",
      "NSC-613806",
      "NSC-615828",
      "NSC-103526",
      "J-012404",
      "1,2-Cyclopentanediol,5-(6-Amino-9H-Purin-9-Yl)-3-(Hydroxymethyl)-, (1S,2R,3R,5R)-",
    ],
  },
  {
    primary_id: "D0I7BU",
    names: ["GSK1482160"],
  },
  {
    primary_id: "D0I7BX",
    names: [
      "Naphthalen-1-Yl 10H-Phenothiazine-10-Carboxylate",
      "CHEMBL451838",
      "Naphthyl Phenothiazine-10-Carboxylate",
      "BDBM50292622",
      "ZINC27529770",
      "AKOS002279100",
    ],
  },
  {
    primary_id: "D0I7CE",
    names: ["SK&F-89626"],
  },
  {
    primary_id: "D0I7DH",
    names: ["(E)-1-(4-Heptylphenyl)But-2-En-1-One"],
  },
  {
    primary_id: "D0I7DN",
    names: ["Tribenzyl 2-Aminopropane-1,2,3-Tricarboxylate", "CHEMBL561821"],
  },
  {
    primary_id: "D0I7EU",
    names: ["SB-208651"],
  },
  {
    primary_id: "D0I7EX",
    names: ["Org-34116"],
  },
  {
    primary_id: "D0I7FF",
    names: [
      "1,1,1-Trifluorododecan-2-One",
      "CHEMBL86668",
      "26902-72-7",
      "2-Dodecanone, 1,1,1-Trifluoro-",
      "AC1L424F",
      "1,1,1-Trifluoro-2-Dodecanone",
      "BDBM50371959",
    ],
  },
  {
    primary_id: "D0I7FH",
    names: ["PF-4859989"],
  },
  {
    primary_id: "D0I7FI",
    names: [
      "5-AMINO-NAPHTALENE-2-MONOSULFONATE",
      "119-79-9",
      "5-Amino-2-Naphthalenesulfonic Acid",
      "5-Aminonaphthalene-2-Sulfonic Acid",
      "1,6-Cleve's Acid",
      "1-Aminonaphthalene-6-Sulfonic Acid",
      "2-Naphthalenesulfonic Acid, 5-Amino-",
      "1-NAPHTHYLAMINE-6-SULFONIC ACID",
      "Kyselina Cleve",
      "1-Amino-6-Naphthalenesulfonic Acid",
      "5-Aminonaphthalene-2-Sulphonic Acid",
      "Cleve's Acid-1,6",
      "5-Naphthylamine-2-Sulfonic Acid",
      "1-Amino-6-Sulfonaphthalene",
      "Cleve's Beta-Acid",
      "Cleve's Acid, Mixed",
      "Kyselina Cleve [Czech]",
      "Kyselina 1-Naftylamin-6-Sulfonova",
      "51548-48-2",
      "NSC 31506",
      "UNII-59G56TB11B",
      "Cleve's .beta.-Aci",
    ],
  },
  {
    primary_id: "D0I7FK",
    names: ["8-(3-Methyl-Cyclohexyloxy)-Quinolin-2-Ylamine", "CHEMBL183932", "SCHEMBL5883847"],
  },
  {
    primary_id: "D0I7GE",
    names: [
      "ALPHA-NAPHTHOFLAVONE",
      "7,8-Benzoflavone",
      "Alpha-Naphthoflavone",
      "604-59-1",
      "2-Phenyl-4H-Benzo[h]chromen-4-One",
      "Alpha-Naphthylflavone",
      "2-Phenylbenzo[h]chromen-4-One",
      "Benzo(H)Flavone",
      "7,8-BF",
      "4H-Naphtho[1,2-B]pyran-4-One, 2-Phenyl-",
      ".alpha.-Naphthoflavone",
      "2-Phenyl-Benzo[h]chromen-4-One",
      "2-Phenyl-4H-Naphtho(1,2-B)Pyran-4-One",
      "CCRIS 3607",
      "2-Phenylbenzo(H)Chromen-4-One",
      "EINECS 210-071-1",
      "UNII-FML65D8PY5",
      "NSC 407011",
      "BRN 0210862",
      "FML65D8PY5",
      "Benzo[h]flavone",
      "MLS003171601",
      "CHEMBL283196",
      "CHEBI:76995",
      "VFMMPHCGEFXGIP-UHFFFAOYSA-N",
      "4H-NAPHTHO(1,2-B)PYRAN-4-ONE, 2-PH",
    ],
  },
  {
    primary_id: "D0I7HB",
    names: ["NuroPro"],
  },
  {
    primary_id: "D0I7HG",
    names: ["RBT-401"],
  },
  {
    primary_id: "D0I7HL",
    names: [
      "2-Phenylpropoxyadenosine",
      "CHEMBL374151",
      "Adenosine, 2-(3-Phenylpropoxy)-",
      "SCHEMBL8110831",
      "2-(3-Phenylpropyloxy)Adenosine",
      "CTK0C0845",
      "131865-80-0",
      "BDBM50208810",
    ],
  },
  {
    primary_id: "D0I7HP",
    names: [
      "PT-308",
      "149485-75-6",
      "Thiourea, N-(2-(2-Chlorophenyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(2-Chlorophenyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 12",
      "AC1MHDMI",
      "SCHEMBL6953553",
      "BDBM1905",
      "CHEMBL102957",
      "DTXSID80164290",
      "1-[2-(2-Chlorophenyl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "AKOS030030937",
      "1-(2-Thiazolyl)-3-[2-Chlorophenethyl]thiourea",
      "1-[2-(2-Chlorophenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "N-(2-(2-Chlorophenethyl))-N -(2-Thiazolyl)Thiourea",
    ],
  },
  {
    primary_id: "D0I7IA",
    names: ["5'-Deoxy-5'-Dimethylsulfonioadenosine Chloride"],
  },
  {
    primary_id: "D0I7IB",
    names: [
      "Sodium Butyrate",
      "Butyrate Sodium",
      "Sodium Butanoate",
      "Sodium Propanecarboxylate",
      "OR8783",
      "Butanoic Acid, Sodium Salt",
      "Butyric Acid, Na",
      "Sodium Butyrate (USP)",
      "Sodium N-Butyrate",
      "TPA/BA",
      "Tetradecanoyl Phorbol Acetate/ Sodium Butyrate",
      "Butanoic Acid, Sodium Salt (1:1)",
      "Tetradecanoyl Phorbol Acetate (TPA)/ Sodium Butyrate (BA)",
    ],
  },
  {
    primary_id: "D0I7IF",
    names: [
      "6-Bromo-5-Phenylpyrimidine-2,4(1H,3H)-Dione",
      "CHEMBL235666",
      "BDBM20076",
      "6-Bromo-5-Phenylpyrimidine-2,4(1H,3H)-Dione, 23",
    ],
  },
  {
    primary_id: "D0I7IH",
    names: ["2-(4-Propylphenylsulfonyl)Naphthalene-1,4-Diol", "CHEMBL445555", "BDBM50245880", "J3.494.437G"],
  },
  {
    primary_id: "D0I7KU",
    names: ["Tacrine-Indole Hybrid Derivative 2"],
  },
  {
    primary_id: "D0I7LB",
    names: ["ZD-4953"],
  },
  {
    primary_id: "D0I7MC",
    names: ["4-(4-(Pentyloxy)Phenoxy)Phenol", "CHEMBL500079"],
  },
  {
    primary_id: "D0I7MF",
    names: ["SIM-0702"],
  },
  {
    primary_id: "D0I7MG",
    names: ["AG-707"],
  },
  {
    primary_id: "D0I7MK",
    names: ["DasKloster 0080-01"],
  },
  {
    primary_id: "D0I7MT",
    names: ["Puerarin"],
  },
  {
    primary_id: "D0I7MW",
    names: ["JWH-244"],
  },
  {
    primary_id: "D0I7MX",
    names: ["LB-30870"],
  },
  {
    primary_id: "D0I7NG",
    names: ["LOR-2040"],
  },
  {
    primary_id: "D0I7NU",
    names: ["Fructose-1,6-Bisphosphatase Inhibitors"],
  },
  {
    primary_id: "D0I7OD",
    names: [
      "Asterric Acid",
      "577-64-0",
      "MLS000863583",
      "CHEMBL469424",
      "SMR000440714",
      "2-Hydroxy-6-(4-Hydroxy-2-Methoxy-6-Methoxycarbonylphenoxy)-4-Methylbenzoic Acid",
      "AC1MIV9I",
      "Asterric Acid_120092",
      "MEGxm0_000244",
      "ZINC7778",
      "SCHEMBL6046569",
      "XOKVHFNTYHPEHN-UHFFFAOYSA-",
      "Cid_3080568",
      "ACon1_001529",
      "ACon0_000930",
      "CTK8F7847",
      "BDBM50711",
      "DTXSID60206405",
      "MolPort-001-739-484",
      "HMS2270H17",
      "MFCD00216131",
      "MCULE-7550426133",
      "NCGC00180411-01",
      "Asterric Acid, &gt",
      "L007432",
      "BRD-K20637966-001-01-0",
    ],
  },
  {
    primary_id: "D0I7OE",
    names: ["CryptoGAM", "Cryptosporidiosis Antibody"],
  },
  {
    primary_id: "D0I7ON",
    names: ["9-Benzyl-6-(4-Nitro-Benzylsulfanyl)-9H-Purine", "CHEMBL362047", "SCHEMBL2965769"],
  },
  {
    primary_id: "D0I7PG",
    names: ["N-(5'-Phosphopyridoxyl)-D-Alanine"],
  },
  {
    primary_id: "D0I7PO",
    names: ["Micardis Telmisartan"],
  },
  {
    primary_id: "D0I7PU",
    names: ["Lirilumab"],
  },
  {
    primary_id: "D0I7QX",
    names: [
      "(Benzo[b]furan-2-Yl)-(1H-Indol-2-Yl)Methanone",
      "CHEMBL226183",
      "BDBM50202215",
      "(Benzofuran-2-Yl)(1H-Indole-2-Yl) Ketone",
    ],
  },
  {
    primary_id: "D0I7RA",
    names: ["4-(5-Bromoindole-3-Yl)-6,7-Dimethoxyquinazoline", "CHEMBL473320", "SCHEMBL5616932"],
  },
  {
    primary_id: "D0I7RF",
    names: ["10-OBn-Ginkgolide B", "10-OBn-GB"],
  },
  {
    primary_id: "D0I7RO",
    names: [
      "BPC-157",
      "BPC-15",
      "PL-10",
      "PL-14736",
      "PLD-116",
      "Glycyl-Glutamyl-Prolyl-Prolyl-Prolyl-Glycyl-Lysyl-Prolyl-Alanyl-Aspartyl-Aspartyl-Alanyl-Glycyl-Leucyl-Valine",
    ],
  },
  {
    primary_id: "D0I7SL",
    names: ["Diabetes Therapy, Reyon"],
  },
  {
    primary_id: "D0I7SU",
    names: ["Warfarin Breakable Tablets"],
  },
  {
    primary_id: "D0I7SZ",
    names: [
      "Quinapril",
      "Ectren",
      "Koretic",
      "Quinaprilum",
      "QUINAPRIL HCL",
      "Quinaprilum [Latin]",
      "Accupril (TN)",
      "Quinapril [INN:BAN]",
      "Quinapril (USP/INN)",
      "[3S-[2[R*(R*)],3R*]]-2-[2-[[1-(Ethoxycarbonyl)-3-Phenylpropyl]amino]-1-Oxopropyl]-1,2,3,4-Tetrahydro-3-Isoquinolinecarboxylic Acid",
      "(3S)-2-(N-{(1S)-1-[(Ethyloxy)Carbonyl]-3-Phenylpropyl}-L-Alanyl)-1,2,3,4-Tetrahydroisoquinoline-3-Carboxylic Acid",
      "(3S)-2-[(2S)-2-[[(2S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Yl]amino]propanoyl]-3,4-Dihydro-1H-Isoquinoline-3-Carboxylic Acid",
      "(3S)-2-{(2S)-2-[(1S)-1-Ethoxycarbonyl-3-Phenylpropylamino]propanoyl}-1,2,3,4-Tetrahydroisoquinoline-3-Carboxylic Acid",
      "(3S)-2-{N-((S)-1-Ethoxycarbonyl-3-Phenylpropyl)-L-Alanyl}-1,2,3,4-Tetrahydroisoquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0I7TN",
    names: [
      "3-DEAZAADENOSINE",
      "Cc3Ado",
      "BW-91Y78",
      "3DZA",
      "4-Amino-1-Beta-D-Ribofuranosyl-1H-Imidazo[4,5-C]pyridine",
    ],
  },
  {
    primary_id: "D0I7TW",
    names: ["PMID25399762-Compound-Table 7-2-Methoxyphenol"],
  },
  {
    primary_id: "D0I7TZ",
    names: ["L-Cysteate"],
  },
  {
    primary_id: "D0I7UQ",
    names: [
      "MER-5101",
      "Amyloid-Beta Peptide Conjugate + MAS-1 (Alzheimers Disease), Mercia",
      "Th2-Biased Active Amyloid-Beta Vaccine Conjugate (Alzheimer's Disease), Mercia",
    ],
  },
  {
    primary_id: "D0I7UZ",
    names: ["Peptide Analog 6"],
  },
  {
    primary_id: "D0I7VD",
    names: ["Ethylisothiourea"],
  },
  {
    primary_id: "D0I7VI",
    names: ["EG-10"],
  },
  {
    primary_id: "D0I7VY",
    names: ["RY796"],
  },
  {
    primary_id: "D0I7WD",
    names: ["C[hGlu22-Lys26][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0I7XE",
    names: ["Neuroprotective Thioderivatives"],
  },
  {
    primary_id: "D0I7YL",
    names: ["S1627"],
  },
  {
    primary_id: "D0I7YR",
    names: ["AG-NCA805"],
  },
  {
    primary_id: "D0I7ZH",
    names: ["EGFR806-Specific CAR T Cell"],
  },
  {
    primary_id: "D0I8AF",
    names: ["CCK-8", "Cholecystokinin 8", "Cholecystokinin Fragment 26-33 Amide (Sulphated)", "CCK-8 (Sulphated)"],
  },
  {
    primary_id: "D0I8AT",
    names: ["N-(4'-Pentanonyl)-4-(4''-Dimethylamino-Styryl)Pyridinium"],
  },
  {
    primary_id: "D0I8AY",
    names: ["Pyrrolidinyl Urea Derivative 10"],
  },
  {
    primary_id: "D0I8BA",
    names: ["1-Benzoyl-2-Benzyl-1,2-Dihydropyridin-3(6H)-One", "CHEMBL379434"],
  },
  {
    primary_id: "D0I8BC",
    names: ["S-100"],
  },
  {
    primary_id: "D0I8BH",
    names: ["Vx-001"],
  },
  {
    primary_id: "D0I8BN",
    names: ["ISIS 103612"],
  },
  {
    primary_id: "D0I8BO",
    names: [
      "1-(5-(Furan-2-Yl)Oxazol-2-Yl)Octadec-9-En-1-One",
      "CHEMBL174048",
      "SCHEMBL3196395",
      "BDBM50157127",
      "(Z)-1-(5-Furan-2-Yl-Oxazol-2-Yl)-Octadec-9-En-1-One",
    ],
  },
  {
    primary_id: "D0I8CA",
    names: ["Isoxyl"],
  },
  {
    primary_id: "D0I8CK",
    names: [
      "IPL-512602",
      "BLX-LSAID",
      "IPL-576 Series",
      "OX-LSAID",
      "Lead LSAID (Asthma), Biolipox",
      "Lead LSAID (Asthma), Orexo",
      "Lead LSAID (Asthma), Inflazyme/Aventis",
      "Lead LSAID (Asthma), Inflazyme/Sanofi-Aventis",
    ],
  },
  {
    primary_id: "D0I8CT",
    names: ["PMID28766366-Compound-Scheme15-2"],
  },
  {
    primary_id: "D0I8DD",
    names: [
      "Dantrolene",
      "Dantrium",
      "Dantroleno",
      "Dantrolenum",
      "DANTROLENE SODIUM",
      "Dantrium Intravenous",
      "Dantrium (TN)",
      "Dantrolen (TN)",
      "Dantroleno [INN-Spanish]",
      "Dantrolenum [INN-Latin]",
      "F-368",
      "Dantrolene (USAN/INN)",
      "Dantrolene [USAN:BAN:INN]",
      "1-(((5-(4-Nitrophenyl)-2-Furanyl)Methylene)Amino)-2,4-Imidazolidinedione",
      "1-((5-(P-Nitrophenyl)Furfurylidene)Amino)Hydantoin",
      "1-({(1E)-[5-(4-Nitrophenyl)Furan-2-Yl]methylidene}amino)Imidazolidine-2,4-Dione",
      "1-({[5-(4-Nitrophenyl)Furan-2-Yl]methylidene}amino)Imidazolidine-2,4-Dione",
      "1-[(E)-[5-(4-Nitrophenyl)Furan-2-Yl]methylideneamino]imidazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0I8DH",
    names: ["ONX-0801"],
  },
  {
    primary_id: "D0I8DW",
    names: [
      "4-Nitrophenylsulfamide",
      "N-(4-Nitrophenyl)Sulfamide",
      "CHEMBL168778",
      "70466-80-7",
      "AC1LD5GZ",
      "N-(4-Nitrophenyl)Sulfamide #",
      "Sulphamide, N-(4-Nitrophenyl)-",
      "RAXPQAODVUNKFW-UHFFFAOYSA-N",
      "1-Nitro-4-(Sulfamoylamino)Benzene",
      "BDBM50124157",
    ],
  },
  {
    primary_id: "D0I8DX",
    names: ["AMG 820"],
  },
  {
    primary_id: "D0I8FI",
    names: ["Isoproterenol"],
  },
  {
    primary_id: "D0I8FO",
    names: [
      "ABI-0043",
      "ABI-0036N",
      "ABI-0042N",
      "ABI-0047N",
      "ABI-0073N",
      "ABI-0094",
      "ABI-0185N",
      "ABI-0197N",
      "ABI-0299",
      "ABI-0369",
      "ABI-0597",
      "ABI-0699",
      "ABI-0710",
      "ABI-0710N",
      "ABI-0713",
      "ABI-0761",
      "ABI-0761N",
      "ABI-0933",
      "ABI-0966N",
      "ABI-0971",
      "ABI-1131",
      "ABI-1162",
      "ABI-1339",
      "ABI-1657",
      "ABI-1662",
      "ABI-1808",
      "Rifalazil Analogs, ActivBiotics",
      "Rifalazil Derivatives (Antibacterial)",
      "Rifalazil Derivatives (Antibacterial), ActivBiotics",
    ],
  },
  {
    primary_id: "D0I8FS",
    names: ["BIIB092"],
  },
  {
    primary_id: "D0I8FV",
    names: ["SUN13834"],
  },
  {
    primary_id: "D0I8FY",
    names: ["EnacyloxinIia"],
  },
  {
    primary_id: "D0I8GD",
    names: ["MRS1186"],
  },
  {
    primary_id: "D0I8GN",
    names: ["INK4a"],
  },
  {
    primary_id: "D0I8GU",
    names: ["LI 401"],
  },
  {
    primary_id: "D0I8HJ",
    names: ["Lormetazepam"],
  },
  {
    primary_id: "D0I8HK",
    names: [
      "1-(2'-Chlorophenyl)Penta-1,4-Dien-3-One",
      "CHEMBL224976",
      "BDBM50177103",
      "1,4-Pentadien-3-One, 1-(2-Chlorophenyl)-",
      "1-(2''-Chlorophenyl)Penta-1,4-Dien-3-One",
    ],
  },
  {
    primary_id: "D0I8HL",
    names: ["(D)Arg-Arg-Pro-Hyp-Gly-Thi-Ser-(D)Tic-Aoc-Arg"],
  },
  {
    primary_id: "D0I8HY",
    names: ["Arg-Pro-Lys-Pro-Gln-Ser-Phe-Phe-Gly-Leu-Met-NH2", "CHEMBL394671"],
  },
  {
    primary_id: "D0I8JJ",
    names: ["9-Deazainosine"],
  },
  {
    primary_id: "D0I8KA",
    names: ["BLZ-100"],
  },
  {
    primary_id: "D0I8KC",
    names: ["6-[2-(1H-INDOL-6-YL)ETHYL]PYRIDIN-2-AMINE", "6IP"],
  },
  {
    primary_id: "D0I8KZ",
    names: ["ISIS 29223"],
  },
  {
    primary_id: "D0I8LH",
    names: ["Formamide"],
  },
  {
    primary_id: "D0I8LL",
    names: ["XGP-310"],
  },
  {
    primary_id: "D0I8LS",
    names: ["Rubratin"],
  },
  {
    primary_id: "D0I8LU",
    names: [
      "Zuclopenthixol",
      "Acuphase",
      "CLOPENTHIXOL",
      "Cisordinol",
      "Clopenthixolum",
      "Clopentixol",
      "Clopixol",
      "Zuclopenthixolum",
      "Zuclopentixol",
      "Alpha Clopenthixol",
      "Zuclopenthixolum [Latin]",
      "Zuclopentixol [Spanish]",
      "Acuphase (TN)",
      "Alpha-Clopenthixol",
      "Cisordinol (TN)",
      "Clopenthixol (USAN)",
      "Clopixol (TN)",
      "Zuclopenthixol (INN)",
      "Zuclopenthixol [BAN:INN]",
      "(Z)-4-(3-(2-Chlorothioxanthen-9-Ylidene)Propyl)-1-Piperazineethanol",
      "2-[4-[(3Z)-3-(2-Chlorothioxanthen-9-Ylidene)Propyl]piperazin-1-Yl]ethanol",
      "2-{4-[(3Z)-3-(2-Chloro-10H-Dibenzo[b,e]thiopyran-10-Ylidene)Propyl]piperazin-1-Yl}ethanol",
      "2-{4-[3-(2-Chloro-10H-Dibenzo[b,e]thiopyran-10-Ylidene)Propyl]piperazin-1-Yl}ethanol",
      "4-(3-(2-Chlorothioxanthen-9-Ylidene)Propyl)-1-Piperazineethanol",
    ],
  },
  {
    primary_id: "D0I8MN",
    names: ["2-(4-Chloro-2-Cyclopentylphenoxy)Acetic Acid", "CHEMBL397842", "SCHEMBL4879219"],
  },
  {
    primary_id: "D0I8MP",
    names: ["N-(1-(3-Bromobenzyl)Piperidin-4-Yl)-2-Naphthamide", "CHEMBL395867"],
  },
  {
    primary_id: "D0I8MT",
    names: ["Girentuximab"],
  },
  {
    primary_id: "D0I8NK",
    names: ["Thrombopoietin Mimetic Antibodies"],
  },
  {
    primary_id: "D0I8NM",
    names: ["Gene Therapy, IFN-Alpha, PINC"],
  },
  {
    primary_id: "D0I8NS",
    names: [
      "Anti-EpCAM Mab",
      "Anti-EpCAM MAb (Undisclosed Indication)",
      "Anti-EpCAM MAb (Undisclosed Indication), PharmAbcine",
      "Epithelial Cell Adhesion Molecule Inhibitor (MAb, Undisclosed Indication), PharmAbcine",
    ],
  },
  {
    primary_id: "D0I8OE",
    names: [
      "PcTx1",
      "CIGUATOXIN",
      "Ciguatoxin 1",
      "Ciguatoxin CTX 1",
      "Pacific Ciguatoxin 1",
      "CTX 1",
      "P-CTX 1",
      "UNII-2UKQ3B7696",
      "HSDB 7241",
      "11050-21-8",
      "2UKQ3B7696",
      "Pacific Ciguatoxin-1",
      "P-CTX-1",
      "Ciguatoxins",
      "Ciguatoxin CTX1B",
      "Ciguatoxin I",
      "Ciguatoxin 1B",
      "SCHEMBL13303026",
      "DTXSID3042637",
      "NOCAS_42637",
      "CHEBI:36467",
      "LS-53705",
      "C16762",
    ],
  },
  {
    primary_id: "D0I8ON",
    names: ["KP772"],
  },
  {
    primary_id: "D0I8OY",
    names: ["XLCART001"],
  },
  {
    primary_id: "D0I8PA",
    names: ["MN-7105", "Anticancer Agent (Solid Tumor), Mivenion"],
  },
  {
    primary_id: "D0I8PQ",
    names: ["S-(N-Ethyl-N-Hydroxycarbamoyl)Glutathione", "CHEMBL128867", "SCHEMBL3287492", "BDBM50092832"],
  },
  {
    primary_id: "D0I8QC",
    names: [
      "Anti-GM-CSF Human Mabs",
      "Anti-GM-CSF Human MAbs (Inflammatory/Autoimmune Disease)",
      "Anti-GM-CSF Human MAbs (Inflammatory/Autoimmune Disease), Theraclone Sciences",
      "Anti-GM-CSF Human Monoclonal Antibodies (Inflammatory/Autoimmune Disease), Theraclone Sciences",
      "Human Antibodies (GM-CSF, Inflammatory Disease/Autoimmune Disease), Spaltudaq",
    ],
  },
  {
    primary_id: "D0I8QF",
    names: ["PU-2049"],
  },
  {
    primary_id: "D0I8QJ",
    names: ["Ranpirnase"],
  },
  {
    primary_id: "D0I8QS",
    names: ["BN82451"],
  },
  {
    primary_id: "D0I8QY",
    names: ["P31-Protein"],
  },
  {
    primary_id: "D0I8RK",
    names: ["Bicyclic Hexapeptide Derivative 2"],
  },
  {
    primary_id: "D0I8RR",
    names: ["Streptozocin"],
  },
  {
    primary_id: "D0I8RX",
    names: ["Sulfonamide Derivative 17"],
  },
  {
    primary_id: "D0I8RY",
    names: ["SDZ-ICM-567", "5-HT3 Antagonist, Novartis"],
  },
  {
    primary_id: "D0I8SA",
    names: ["BO-110"],
  },
  {
    primary_id: "D0I8SC",
    names: ["WSB-290"],
  },
  {
    primary_id: "D0I8SJ",
    names: ["NDP-SYSMEHFRWGKPVG"],
  },
  {
    primary_id: "D0I8SK",
    names: ["L-Isoleucine"],
  },
  {
    primary_id: "D0I8TF",
    names: ["EXO-226"],
  },
  {
    primary_id: "D0I8TJ",
    names: ["CY-1788"],
  },
  {
    primary_id: "D0I8TR",
    names: ["ML233"],
  },
  {
    primary_id: "D0I8TV",
    names: ["DLX-0201", "Cancer Therapy, Delex"],
  },
  {
    primary_id: "D0I8TW",
    names: ["IBC-VS01"],
  },
  {
    primary_id: "D0I8WD",
    names: ["Methyl L-Phenylalaninate"],
  },
  {
    primary_id: "D0I8WN",
    names: ["Alzheimer's Disease Therapy, Cornell University"],
  },
  {
    primary_id: "D0I8WU",
    names: [
      "AcDif-Glu-Cha-Cys",
      "CHEMBL62433",
      "AC1LAAI7",
      "Ac-Dif-Glu-Cha-Cys-OH",
      "BDBM50096401",
      "Ac-3,3-Diphenyl-L-Ala-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "AcDif(3,3-Diphenylalanine)-Glu-Cha(Beta-Cyclohexylalanine)-Cys",
      "(4S)-4-[[(2S)-2-Acetamido-3,3-Diphenylpropanoyl]amino]-5-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D0I8WY",
    names: ["M55113"],
  },
  {
    primary_id: "D0I8XD",
    names: [
      "SB-13",
      "CARBAMIC ACID, DIMETHYL-, 2-(DIMETHYLAMINO)-P-TOLYL ESTER, HYDROCHLORIDE",
      "Carbamic Acid, N,N-Dimethyl-, 2-Dimethylamino-4-Methylphenyl Ester, Hydrochloride",
      "AC1L2CX4",
      "LS-49497",
      "63884-75-3",
      "[2-(Dimethylcarbamoyloxy)-5-Methylphenyl]-Dimethylazanium Chloride",
    ],
  },
  {
    primary_id: "D0I8XI",
    names: ["Vaccine, Nanoparticle Formulation, Autoimmune Disease/Allergy/Type I Diabetes/Transplant Rejection"],
  },
  {
    primary_id: "D0I8YN",
    names: [
      "2-(2-(2-Fluorophenylamino)Phenyl)Acetic Acid",
      "CHEMBL427739",
      "SCHEMBL7165042",
      "DWKOQWOOVUATNI-UHFFFAOYSA-N",
      "BDBM50295276",
      "2-[(2-Fluorophenyl)Amino]phenylacetic Acid",
    ],
  },
  {
    primary_id: "D0I8YO",
    names: ["Hepatitis B Vaccine"],
  },
  {
    primary_id: "D0I8YS",
    names: ["RO5520985"],
  },
  {
    primary_id: "D0I8ZB",
    names: ["Ruthenium Complexes"],
  },
  {
    primary_id: "D0I8ZE",
    names: ["PMID27788040-Compound-5b"],
  },
  {
    primary_id: "D0I8ZM",
    names: ["CR-3294"],
  },
  {
    primary_id: "D0I8ZZ",
    names: ["Relcovaptan"],
  },
  {
    primary_id: "D0I9AA",
    names: ["7-Fluoro-2-P-Tolyl-4H-Chromene-4-Thione", "CHEMBL599547"],
  },
  {
    primary_id: "D0I9AC",
    names: ["SM04554"],
  },
  {
    primary_id: "D0I9AL",
    names: [
      "Guanylpirenzepine",
      "4-[2-Oxo-2-(6-Oxo-5H-Pyrido[2,3-B][1,4]benzodiazepin-11-Yl)Ethyl]piperazine-1-Carboximidamide Dihydrochloride",
    ],
  },
  {
    primary_id: "D0I9AR",
    names: ["BMS-185857"],
  },
  {
    primary_id: "D0I9AT",
    names: ["Tryptanthrin"],
  },
  {
    primary_id: "D0I9BT",
    names: ["PMID25522065-Compound-4"],
  },
  {
    primary_id: "D0I9CK",
    names: ["PMID25399762-Compound-Figure3-Myriberine A"],
  },
  {
    primary_id: "D0I9DN",
    names: ["IL-4R"],
  },
  {
    primary_id: "D0I9FI",
    names: [
      "2-(4-Bromo-Phenyl)-1-Methyl-Ethylamine",
      "1-(4-Bromophenyl)Propan-2-Amine",
      "13235-83-1",
      "4-Bromoamphetamine",
      "Para-Bromoamphetamine",
      "BRN 3240553",
      "Benzeneethanamine, 4-Bromo-Alpha-Methyl-, (+-)-",
      "CHEMBL61355",
      "(+-)-4-Bromo-Alpha-Methylbenzeneethanamine",
      "18455-37-3",
      "1-(4-Bromobenzyl)Ethylamine",
      "4-Ba",
      "AC1L4F4G",
      "3-12-00-02675 (Beilstein Handbook Reference)",
      "SCHEMBL164716",
      "CTK0I1684",
      "SMNXUMMCCOZPPN-UHFFFAOYSA-N",
      "1-(4-Bromophenyl)Propane-2-Amine",
      "BDBM50005252",
      "AKOS022180693",
      "AKOS000160383",
      "1-Methyl-2-(4-Bromophenyl)Ethanamine",
    ],
  },
  {
    primary_id: "D0I9FQ",
    names: ["XGP-125"],
  },
  {
    primary_id: "D0I9FU",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 4"],
  },
  {
    primary_id: "D0I9GA",
    names: ["AbGn-100", "Antibody-100", "Apoptosis Stimulator (Cancer), AbGenomics"],
  },
  {
    primary_id: "D0I9GE",
    names: ["ISIS 19659"],
  },
  {
    primary_id: "D0I9GR",
    names: ["Plevitrexed"],
  },
  {
    primary_id: "D0I9HF",
    names: ["Hesperidin"],
  },
  {
    primary_id: "D0I9HG",
    names: ["Amsilarotene", "Am-555S", "TAC-101", "Anticancer Retinoic Acid Receptor Antagonist (Oral), Taiho"],
  },
  {
    primary_id: "D0I9IB",
    names: ["5-Butyl-Methyl Immidazole Carboxylate 30", "Compound 30 [PMID: 22889560]"],
  },
  {
    primary_id: "D0I9IF",
    names: ["AVN-492"],
  },
  {
    primary_id: "D0I9IL",
    names: ["OTL38"],
  },
  {
    primary_id: "D0I9IX",
    names: ["Tyr-D-Ala-Phe-Thr(-D-Glc)-Tyr-Pro-Ser-NH2"],
  },
  {
    primary_id: "D0I9JE",
    names: ["PMID29130358-Compound-Tenuigenin"],
  },
  {
    primary_id: "D0I9JH",
    names: ["L-655240"],
  },
  {
    primary_id: "D0I9JL",
    names: ["[Ncy(Methyl)4]acyline", "CHEMBL388369"],
  },
  {
    primary_id: "D0I9KL",
    names: [
      "Anticancer Etherlipid Prodrugs",
      "Anticancer Etherlipid Prodrugs (PLA2-Degradable Liposome Prodrug Formulation)",
      "Anticancer Etherlipid Prodrugs (PLA2-Degradable Liposome Prodrug Formulation), LiPlasome",
      "Anticancer Etherlipids (Phospholipase-Susceptible Liposome Formulation), LiPlasome",
    ],
  },
  {
    primary_id: "D0I9KV",
    names: [
      "1-(4-Bromophenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One",
      "CHEMBL202420",
      "4-Bromo-Alpha-Pvp",
      "SCHEMBL4937387",
      "BDBM50182578",
      "4'-Bromo-Alpha-Pyrrolidinopentiophenone",
      "2-(Pyrrolidin-1-Yl)-1-(4-Bromophenyl)Pentan-1-One",
      "1-Pentanone, 1-(4-Bromophenyl)-2-(1-Pyrrolidinyl)-",
      "1-(4-Bromophenyl)-2-(Pyrrolidin-1-Yl)Pentan-1-One",
    ],
  },
  {
    primary_id: "D0I9LH",
    names: ["PMI-001"],
  },
  {
    primary_id: "D0I9LO",
    names: ["RWJ-416457"],
  },
  {
    primary_id: "D0I9LU",
    names: [
      "Arecaidine Propargyl Ester",
      "35516-99-5",
      "NCGC00015006-01",
      "Lopac-A-140",
      "AC1L1D7L",
      "AC1Q62JO",
      "Lopac0_000136",
      "GTPL295",
      "CHEMBL128365",
      "SCHEMBL13184690",
      "CTK1B6885",
      "CHEBI:92418",
      "DTXSID30274358",
      "SPHRJZBOFYIKMC-UHFFFAOYSA-N",
      "Prop-2-Ynyl 1-Methyl-3,6-Dihydro-2H-Pyridine-5-Carboxylate",
      "2-Propynyl 1-Methyl-1,2,5,6-Tetrahydro-3-Pyridinecarboxylate",
      "ZINC25720839",
      "AKOS030566155",
      "CCG-204231",
      "NCGC00015006-03",
      "NCGC00162072-01",
      "NCGC00015006-04",
      "NCGC00162072-02",
      "NCGC00015006-02",
      "L000114",
      "BRD-K23922020-075-01-1",
      "Prop-2-Ynyl 1-Methyl-5,6-Dihydro-2H-Pyridin",
    ],
  },
  {
    primary_id: "D0I9ME",
    names: ["Des-AA1,2,5-[D-Trp8,(NalphaMe)IAmp9,Tyr11]SRIF", "CHEMBL411860"],
  },
  {
    primary_id: "D0I9MF",
    names: ["Benzaldehyde O-4-Ethoxyphenylcarbamoyl Oxime", "CHEMBL599470"],
  },
  {
    primary_id: "D0I9MG",
    names: ["IMM-308", "Clostridium Difficile Infection Vaccine, (Oral), Immuron"],
  },
  {
    primary_id: "D0I9NC",
    names: ["MDL-101002"],
  },
  {
    primary_id: "D0I9NE",
    names: ["4-(2-(3-Fluorophenyl)Ethynyl)-2-Methylthiazole", "CHEMBL202323", "SCHEMBL4145662"],
  },
  {
    primary_id: "D0I9NH",
    names: [
      "5-Hydroxy-2-(4-Aminophenyl)Benzofuran",
      "CHEMBL380476",
      "CTK7D7241",
      "ZINC13686340",
      "BDBM50185949",
      "2-(4-Amino-Phenyl)-Benzofuran-5-Ol",
    ],
  },
  {
    primary_id: "D0I9ON",
    names: ["(+/-)-Threo-N-Allylmethylphenidate", "CHEMBL1253918"],
  },
  {
    primary_id: "D0I9PA",
    names: [
      "3-(2,3-Dihydro-1,4-Benzodioxin-6-Yl)Pyridine",
      "CHEMBL462092",
      "1092522-84-3",
      "3-(2,3-Dihydrobenzo[b][1,4]dioxin-6-Yl)Pyridine",
      "3-(2,3-Dihydro-Benzo[1,4]dioxin-6-Yl)-Pyridine",
      "ZINC40873967",
      "BDBM50273702",
    ],
  },
  {
    primary_id: "D0I9PC",
    names: ["PMID27744724-Compound-13"],
  },
  {
    primary_id: "D0I9PF",
    names: ["PMID22861813C8a"],
  },
  {
    primary_id: "D0I9PG",
    names: [
      "1-N-Acetyl-Beta-D-Glucosamine",
      "1-N-ACETYL-BETA-D-GLUCOSAMINE",
      "N-Acetyl-Beta-D-Glucopyranosylamine",
      "CHEMBL335315",
      "N-Beta-D-Glucopyranosylacetamide",
      "NBG",
      "6983-36-4",
      "Acetamide, N-Beta-D-Glucopyranosyl-",
      "2prj",
      "AC1L9HVU",
      "SCHEMBL4388634",
      "N-Acetyl-B-D-Glucopyranosylamine",
      "BDBM50240802",
      "DB02320",
      "N-(3,4,5-Trihydroxy-6-Hydroxymethyl-Tetrahydro-Pyran-2-Yl)-Acetamide",
      "N-[(2R,3R,4S,5S,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]acetamide",
    ],
  },
  {
    primary_id: "D0I9PH",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-Trp-D-Nle-D-Asp-D-Phe-H", "CHEMBL218651"],
  },
  {
    primary_id: "D0I9QC",
    names: ["PMID27019002-Compound-31a"],
  },
  {
    primary_id: "D0I9QH",
    names: ["SR-58878"],
  },
  {
    primary_id: "D0I9RB",
    names: ["SOM-0525"],
  },
  {
    primary_id: "D0I9RW",
    names: ["1-((S)-2-Aminopropyl)-7-Iodo-1H-Indazol-6-Ol"],
  },
  {
    primary_id: "D0I9RY",
    names: [
      "N-Hydroxy-2-Methyl-3-Naphthalen-2-Yl-Acrylamide",
      "CHEMBL367336",
      "ZINC28109710",
      "BDBM50015187",
      "(E)-2-Methyl-3-(2-Naphthalenyl)-2-Propenehydroximic Acid",
    ],
  },
  {
    primary_id: "D0I9SX",
    names: ["PHENAZOCINE"],
  },
  {
    primary_id: "D0I9TR",
    names: ["PMID2296036C4d"],
  },
  {
    primary_id: "D0I9TV",
    names: ["DW-816"],
  },
  {
    primary_id: "D0I9UH",
    names: ["MOXIFETIN HYDROGEN MALEATE"],
  },
  {
    primary_id: "D0I9UP",
    names: ["HBX-41108"],
  },
  {
    primary_id: "D0I9VN",
    names: [
      "1-Amino-6-Cyclohex-3-Enylmethyloxypurine",
      "1-AMINO-6-CYCLOHEX-3-ENYLMETHYLOXYPURINE",
      "872556-74-6",
      "4-(Cyclohexylmethoxy)-1H-Imidazo[4,5-C]pyridin-6-Amine",
      "AC1L9IVC",
      "CTK2I2667",
      "DTXSID90332206",
      "3h-Imidazo[4,5-C]pyridin-6-Amine,4-(Cyclohexylmethoxy)-",
      "ZINC20149007",
      "AKOS030619175",
      "DB02603",
      "KB-268453",
      "1H-Imidazo[4,5-C]pyridin-6-Amine, 4-(Cyclohexylmethoxy)-",
    ],
  },
  {
    primary_id: "D0I9VO",
    names: ["Epratuzumab"],
  },
  {
    primary_id: "D0I9VU",
    names: ["PMID28350212-Compound-20"],
  },
  {
    primary_id: "D0I9VY",
    names: ["CV-4093"],
  },
  {
    primary_id: "D0I9WR",
    names: ["CNTO-3157"],
  },
  {
    primary_id: "D0I9XH",
    names: ["Celastrol"],
  },
  {
    primary_id: "D0I9XR",
    names: ["JWH-302"],
  },
  {
    primary_id: "D0I9YI",
    names: ["AZD-6942", "CCR2b Antagonists, AstraZeneca", "CCR2b, AstraZeneca"],
  },
  {
    primary_id: "D0I9YJ",
    names: ["Phalloidin"],
  },
  {
    primary_id: "D0I9YM",
    names: ["IMCnyeso"],
  },
  {
    primary_id: "D0I9ZJ",
    names: ["5-Hydroxymethyluridine-2'-Deoxy-5'-Monophosphate"],
  },
  {
    primary_id: "D0I9ZQ",
    names: ["SB-104"],
  },
  {
    primary_id: "D0I9ZV",
    names: ["Recombinant Soluble PSMA Protein Vaccine"],
  },
  {
    primary_id: "D0IA0N",
    names: ["NRL-1"],
  },
  {
    primary_id: "D0IA1G",
    names: ["PAT-001"],
  },
  {
    primary_id: "D0IA3U",
    names: ["Remodelin"],
  },
  {
    primary_id: "D0IA4F",
    names: ["Imidazole Derivative 11"],
  },
  {
    primary_id: "D0IA4G",
    names: ["PMID25656651-Compound-37a"],
  },
  {
    primary_id: "D0IA5O",
    names: ["GSK-2241658A"],
  },
  {
    primary_id: "D0IA7K",
    names: ["MF-5137"],
  },
  {
    primary_id: "D0IA7R",
    names: [
      "2-(2-Aminophenoxy)-5-Hexylphenol",
      "CHEMBL264434",
      "2-(2-Azanylphenoxy)-5-Hexyl-Phenol",
      "PT13",
      "BDBM50373348",
    ],
  },
  {
    primary_id: "D0IA7Z",
    names: ["Carbamide Derivative 1"],
  },
  {
    primary_id: "D0IA8W",
    names: ["BMS-986156"],
  },
  {
    primary_id: "D0IA9E",
    names: ["BTRX-246040"],
  },
  {
    primary_id: "D0IB3K",
    names: ["TILIROSIDE"],
  },
  {
    primary_id: "D0IB3R",
    names: ["Imidazopyridine Derivative 3"],
  },
  {
    primary_id: "D0IB3Z",
    names: ["Thiazolidine Derivative 3"],
  },
  {
    primary_id: "D0IB4H",
    names: ["[Mpa1, D-Tic7]OT", "CHEMBL265630"],
  },
  {
    primary_id: "D0IB5L",
    names: ["O-Acetylserine"],
  },
  {
    primary_id: "D0IB6Y",
    names: ["2-Aminomethyl-5-(P-Methoxyphenyl)Tetrahydrofuran", "CHEMBL451652", "SCHEMBL2689310"],
  },
  {
    primary_id: "D0IB8C",
    names: [
      "6-(Toluene-4-Sulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL72368",
      "6-Tosylpyridazin-3(2H)-One",
      "BDBM16447",
      "ZINC11536215",
      "AKOS015830955",
      "AKOS001773373",
      "MCULE-5757912617",
      "CCG-126887",
      "6-Phenylsulfonylpyridazin-2H-3-One, 9",
      "99985-09-8",
      "Phenyl-Substituted Sulfonylpyridazinone, 8i",
      "3(2H)-Pyridazinone, 6-[(4-Methylphenyl)Sulfonyl]-",
      "F6548-0368",
    ],
  },
  {
    primary_id: "D0IB8D",
    names: ["PMID26651364-Compound-117a"],
  },
  {
    primary_id: "D0IB8N",
    names: [
      "Noradrenalone (Arterenone)",
      "Arterenone",
      "499-61-6",
      "Noradrenalone",
      "2-Amino-1-(3,4-Dihydroxyphenyl)Ethanone",
      "2-Amino-1-(3,4-Dihydroxyphenyl)Ethan-1-One",
      "Arterenon",
      "EINECS 207-883-3",
      "U 0603",
      "2-Amino-3',4'-Dihydroxyacetophenone",
      "BRN 2719260",
      "Ethanone, 2-Amino-1-(3,4-Dihydroxyphenyl)-",
      "ACETOPHENONE, 2-AMINO-3',4'-DIHYDROXY-",
      "ADROLOP",
      "AC1Q5DI7",
      "SCHEMBL1703207",
      "AC1L1V18",
      "CTK4J1837",
      "5090-29-9 (Hydrochloride)",
      "DTXSID10198144",
      "MolPort-001-785-861",
      "ZINC37629258",
      "AKOS006275024",
      "ACN-049173",
      "AS07040",
      "LS-13364",
      "KB-282798",
      "KB-227300",
      "Ethanone,2-Amino-1-(3,4-Dihydroxyphenyl)-",
    ],
  },
  {
    primary_id: "D0IC1N",
    names: ["(-)-Hydroxycitrate"],
  },
  {
    primary_id: "D0IC2O",
    names: ["Anti-MiR-191 Modified Oligonucleotide"],
  },
  {
    primary_id: "D0IC3K",
    names: ["N-Phenyl-5-Phenyl-Pyrazolin-3-Yl Amide Derivative 1"],
  },
  {
    primary_id: "D0IC4J",
    names: ["4-(2-(3-Fluorophenoxy)Phenyl)Piperidine", "CHEMBL583752"],
  },
  {
    primary_id: "D0IC5F",
    names: ["DM3B6S", "CHEMBL213737"],
  },
  {
    primary_id: "D0IC5I",
    names: ["ASP-0819"],
  },
  {
    primary_id: "D0IC5U",
    names: ["Isosorbide-2-(Benzylcarbamate)-5-Mononitrate", "CHEMBL445695"],
  },
  {
    primary_id: "D0IC6D",
    names: ["KM-133"],
  },
  {
    primary_id: "D0IC7A",
    names: ["TZTX-001"],
  },
  {
    primary_id: "D0IC7C",
    names: [
      "5-Isopropyl-1H-Pyrazole-3-Carboxylic Acid",
      "92933-47-6",
      "5-Isopropyl-2H-Pyrazole-3-Carboxylic Acid",
      "3-Isopropyl-1H-Pyrazole-5-Carboxylic Acid",
      "5-ISOPROPYLPYRAZOLE-3-CARBOXYLIC ACID",
      "3-(Propan-2-Yl)-1H-Pyrazole-5-Carboxylic Acid",
      "CHEMBL238002",
      "CHWXKAHFWLSLOQ-UHFFFAOYSA-N",
      "1H-Pyrazole-3-Carboxylic Acid, 5-(1-Methylethyl)-",
      "890590-91-7",
      "5-Isopropyl-1H-Pyrazol-3-Carboxylic Acid",
      "5-(Propan-2-Yl)-1H-Pyrazole-3-Carboxylic Acid",
      "3-(Methylethyl)Pyrazole-5-Carboxylic Acid",
      "PubChem22754",
      "BAS 15375081",
      "ACMC-20cbo3",
      "AC1Q5UGZ",
      "AC1Q1OGL",
    ],
  },
  {
    primary_id: "D0IC8O",
    names: ["Curcumin Analog 2"],
  },
  {
    primary_id: "D0IC8P",
    names: ["HL-026"],
  },
  {
    primary_id: "D0IC8U",
    names: [
      "1-Adamantan-1-Yl-3-(1-Propyl-Piperidin-4-Yl)-Urea",
      "CHEMBL215168",
      "SCHEMBL5144236",
      "MolPort-028-759-082",
      "BDBM100355",
      "BDBM50191872",
      "ZINC36330629",
      "AKOS034496458",
      "MCULE-1016100156",
      "US8501783, 1155",
      "1-(Adamantan-1-Yl)-3-(1-Propylpiperidin-4-Yl)Urea",
      "Z642063290",
    ],
  },
  {
    primary_id: "D0ID0R",
    names: ["LF-984"],
  },
  {
    primary_id: "D0ID2I",
    names: ["Typhella Typhoid Vaccine Oral"],
  },
  {
    primary_id: "D0ID3R",
    names: ["PMID25980951-Compound-5"],
  },
  {
    primary_id: "D0ID3W",
    names: ["GW-813893"],
  },
  {
    primary_id: "D0ID3Y",
    names: [
      "(+/-)-2-(4'-Methoxyphenyl)Thiomorpholin-5-One",
      "CHEMBL597768",
      "2-(4'-Methoxyphenyl)Thiomorpholin-5-One",
      "BDBM50307373",
      "42109-48-8",
    ],
  },
  {
    primary_id: "D0ID4H",
    names: ["PMID25991433-Compound-N1"],
  },
  {
    primary_id: "D0ID4Z",
    names: ["Fomivirsen Sodium"],
  },
  {
    primary_id: "D0ID5M",
    names: ["GPYRMEHFRWGSPPKD-NH2"],
  },
  {
    primary_id: "D0ID6U",
    names: [
      "CYCLORGDFV",
      "Cyclo(Arg-Gly-Asp-D-Phe-Val)",
      "CHEMBL411941",
      "Cyclo[Arg-Gly-Asp-D-Phe-Val]",
      "Cyclo(-Arg-Gly-Asp-D-Phe-Val)",
      "137813-35-5",
      "C-[-Arg-Gly-Asp-FV-]",
      "C[Arg-Gly-Asp-(R)-Phe-Val]",
      "Cyclo-(Arg-Gly-Asp-D-Phe-Val)",
      "Cyclo(Arg-Gly-Asp-D-Phe-Val-)",
      "Cyclo(-Arg-Gly-Asp-D-Phe-Val-)",
      "BDBM50237601",
      "ZINC17655303",
      "NCGC00167283-01",
      "[(2S,5R,8S,11S)-5-Benzyl-11-(3-Guanidino-Propyl)-8-Isopropyl-3,6,9,12,15-Pentaoxo-1,4,7,10,13pentaaza-Cyclopentadec-2-Yl]-Acetic Acid",
    ],
  },
  {
    primary_id: "D0ID9W",
    names: ["ATA188"],
  },
  {
    primary_id: "D0IE1E",
    names: ["Nitrofurazone"],
  },
  {
    primary_id: "D0IE3M",
    names: [
      "Anti-Inflammatory/Anti-Proliferative Agents",
      "Anti-Inflammatory/Anti-Proliferative Agents (Dermatological Diseases)",
      "Anti-Inflammatory/Anti-Proliferative Agents (Dermatological Diseases), Eli Lilly",
      "Anti-Inflammatory/Anti-Proliferative Agents (Dermatological Diseases), Magen BioSciences",
    ],
  },
  {
    primary_id: "D0IE4H",
    names: ["TAZOMELINE"],
  },
  {
    primary_id: "D0IE6Z",
    names: ["Platelet Factor 4 (PF-4)"],
  },
  {
    primary_id: "D0IE8M",
    names: ["Cyclic Hydroxamate Derivative 1"],
  },
  {
    primary_id: "D0IE9E",
    names: ["PMID26560530-Compound-6"],
  },
  {
    primary_id: "D0IE9L",
    names: ["M4344"],
  },
  {
    primary_id: "D0IF0T",
    names: ["Aminopyrimidine Derivative 7"],
  },
  {
    primary_id: "D0IF0U",
    names: ["Sacituzumab Govitecan"],
  },
  {
    primary_id: "D0IF2H",
    names: [
      "2-(4-Butoxy-Phenyl)-N-Hydroxy-N-Methyl-Acetamide",
      "CHEMBL368604",
      "KUC110092N",
      "ZINC28096255",
      "BDBM50015186",
      "KSC-229-6-1",
    ],
  },
  {
    primary_id: "D0IF3G",
    names: ["Pyrimidinyl Compound 3"],
  },
  {
    primary_id: "D0IF3H",
    names: ["Zebularine"],
  },
  {
    primary_id: "D0IF3N",
    names: ["N,N-Dimethylhistamine"],
  },
  {
    primary_id: "D0IF4J",
    names: ["PMID26651364-Compound-110"],
  },
  {
    primary_id: "D0IF5M",
    names: ["Isoquinoline Derivative 4"],
  },
  {
    primary_id: "D0IF8Z",
    names: [
      "(4-Methyl-2-Oxo-2H-Quinolin-1-Yl)-Acetic Acid",
      "103368-21-4",
      "1(2h)-Quinolineacetic Acid, 4-Methyl-2-Oxo-",
      "NSC108456",
      "(4-Methyl-2-Oxoquinolin-1(2H)-Yl)Acetic Acid",
      "CHEMBL64455",
      "2-(4-Methyl-2-Oxoquinolin-1(2H)-Yl)Acetic Acid",
      "BAS 02839786",
      "(4-Methyl-2-Oxo-1(2H)-Quinolinyl)Acetic Acid",
      "2-(4-Methyl-2-Oxohydroquinolyl)Acetic Acid",
      "AC1L6JZ1",
      "AC1Q6I1O",
      "MLS000714792",
      "SCHEMBL15709048",
      "CTK4A2028",
      "DTXSID20296246",
      "MolPort-001-984-878",
      "ZINC380285",
      "HMS2670L06",
      "HMS1681E20",
      "HMS3482A22",
      "ZX-AN005388",
      "ALBB-005471",
      "BB_SC-06267",
      "ZX-CM004260",
      "BBL010328",
    ],
  },
  {
    primary_id: "D0IG2Y",
    names: ["MVA P53 Vaccine"],
  },
  {
    primary_id: "D0IG4I",
    names: ["THIOCOLCHICOSIDE"],
  },
  {
    primary_id: "D0IG5E",
    names: [
      "2,5'-Dichloro-5'-Deoxy-N6-Cyclopentyladenosine",
      "CHEMBL477445",
      "BDBM50267394",
      "2,5''-Dichloro-5''-Deoxy-N6-Cyclopentyladenosine",
    ],
  },
  {
    primary_id: "D0IG6J",
    names: ["Piperazine Derivative 1"],
  },
  {
    primary_id: "D0IG7E",
    names: ["PMID25435179-Compound-WO2013036720H3K7TSu Analogues Targeting On Mitochondria"],
  },
  {
    primary_id: "D0IG7F",
    names: ["12R-HPETE", "12(R)-HPETE"],
  },
  {
    primary_id: "D0IG7W",
    names: ["BMS-986224"],
  },
  {
    primary_id: "D0IH2I",
    names: ["13C-Urea"],
  },
  {
    primary_id: "D0IH3I",
    names: ["PMID28460551-Compound-1"],
  },
  {
    primary_id: "D0IH3T",
    names: ["PMID27539678-Compound-8"],
  },
  {
    primary_id: "D0IH4M",
    names: ["CB-10-01 Cancer Vaccine"],
  },
  {
    primary_id: "D0IH6G",
    names: ["Resorcinol Compound 26"],
  },
  {
    primary_id: "D0IH6W",
    names: [
      "(E,E)-1,7-Diphenyl-4,6-Heptadien-3-One",
      "Alnustone",
      "33457-62-4",
      "Alnusone",
      "CHEMBL461082",
      "1,7-Diphenylhepta-4,6-Dien-3-One",
      "1,7-Diphenyl-4,6-Heptadien-3-One",
      "HEP-DIENE-ONE DER FR ALPINA KATSUMANDAI",
      "AC1NSVUW",
      "4,6-Heptadien-3-One, 1,7-Diphenyl-",
      "DAH-3-Keto-4,6-Dien",
      "(4E,6E)-1,7-Diphenylhepta-4,6-Dien-3-One",
      "DTXSID30415724",
      "OWMJDOUOHDOUFG-FNCQTZNRSA-N",
      "MolPort-028-600-133",
      "ZINC1538308",
      "NSC378841",
      "BDBM50300191",
      "AKOS030524791",
      "NSC-378841",
      "AK544086",
      "Y0026",
      "Trans,trans-1,7-Diphenyl-1,3-Heptadien-5-One",
    ],
  },
  {
    primary_id: "D0IH8R",
    names: ["PMID30280939-Compound-WO2008009652"],
  },
  {
    primary_id: "D0II5S",
    names: ["DMP-728", "CHEMBL114946", "BDBM50369582"],
  },
  {
    primary_id: "D0II7M",
    names: ["Modified Adenovirus Delta24-RGD"],
  },
  {
    primary_id: "D0II7X",
    names: ["Imidazo Bicyclic Iminium Derivative 2"],
  },
  {
    primary_id: "D0II9K",
    names: ["Girisopam", "EGIS-5810", "GYKI-51189"],
  },
  {
    primary_id: "D0IJ0F",
    names: [
      "AG-013",
      "ActoBiotic (Oral Mucositis), ActoGenix",
      "TFF-Producing Lactococci Lactis (Mucositis), ActoGenix/VIB/University Of Ghent",
      "Trefoil Factor Producing Micro-Organisms (TopAct, Oral, Mucositis), ActoGenix",
    ],
  },
  {
    primary_id: "D0IJ0Q",
    names: ["ML-436"],
  },
  {
    primary_id: "D0IJ0U",
    names: ["TS-071"],
  },
  {
    primary_id: "D0IJ1D",
    names: ["HuM-195-Bi-213"],
  },
  {
    primary_id: "D0IJ2H",
    names: [
      "FK-584",
      "4,4-Diphenylcyclopentenylamine Hydrochloride",
      "2-Cyclopenten-1-Amine, 4,4-Diphenyl-, Hydrochloride",
      "4,4-Diphenyl-2-Cyclopentenylamine",
      "FK 584",
      "56740-43-3",
      "AC1L57OZ",
      "LS-58284",
      "4,4-Diphenylcyclopent-2-En-1-Amine Hydrochloride",
    ],
  },
  {
    primary_id: "D0IJ2X",
    names: [
      "1-Prop-2-Ynyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL67415",
      "1H-Purine-2,6-Dione, 3,9-Dihydro-1-(2-Propyn-1-Yl)-",
      "146830-81-1",
      "1-Propargylxanthine",
      "SCHEMBL3423318",
      "BDBM50042217",
    ],
  },
  {
    primary_id: "D0IJ3R",
    names: [
      "TRK-710",
      "(SP-4-3)-[3-Acetyl-5(S)-Methyl-2,4(3H,5H)-Furandionato-O3,O4][1(R),2(R)-Cyclohexanediamine-N,N']platinum(1+) Salt With 3-Acetyl-5(S)-Methyl-2,4(3H,5H)-Furandione (1:1)",
      "173903-27-0",
    ],
  },
  {
    primary_id: "D0IJ4G",
    names: ["VPM-2-001", "Cytomegalovirus Vaccine (Dense Bodies), Vakzine Projekt Management"],
  },
  {
    primary_id: "D0IJ5I",
    names: ["Befiperide"],
  },
  {
    primary_id: "D0IJ7H",
    names: ["3-Hydroxy-3-Methyl-Glutaric Acid"],
  },
  {
    primary_id: "D0IJ7O",
    names: ["Metastat"],
  },
  {
    primary_id: "D0IK1I",
    names: ["PHN-031"],
  },
  {
    primary_id: "D0IK1S",
    names: ["PMID25666693-Compound-102"],
  },
  {
    primary_id: "D0IK1U",
    names: ["SEA-0400"],
  },
  {
    primary_id: "D0IK2S",
    names: [
      "(+/-)-7-Fluoro-2-Phenylchroman-4-One",
      "7-Fluoroflavanone",
      "4H-1-Benzopyran-4-One, 7-Fluoro-2,3-Dihydro-2-Phenyl-",
      "98769-92-7",
      "7-Fluoro-Flavanone",
      "AC1NRPFB",
      "ACMC-20m2iw",
      "CHEMBL596693",
      "SCHEMBL10360962",
      "CTK3G7728",
      "DTXSID20414862",
      "SZNYRENOXQIQMR-UHFFFAOYSA-N",
      "7-Fluoro-2-Phenyl-Chroman-4-One",
      "AKOS030553463",
    ],
  },
  {
    primary_id: "D0IK4V",
    names: ["GNF-PF-5134"],
  },
  {
    primary_id: "D0IK5F",
    names: ["BSI-201"],
  },
  {
    primary_id: "D0IK5R",
    names: ["ERY974"],
  },
  {
    primary_id: "D0IK9N",
    names: ["Ethynyl Compound 1"],
  },
  {
    primary_id: "D0IK9V",
    names: ["Ketoheterocycle Derivative 3"],
  },
  {
    primary_id: "D0IL0C",
    names: ["SK-1306X", "Cararthron", "Joins"],
  },
  {
    primary_id: "D0IL0G",
    names: [
      "N-(6-(Furan-3-Yl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1086781",
      "SCHEMBL1461525",
      "ZGQZPFJWHPTRAH-UHFFFAOYSA-N",
      "BDBM50313709",
    ],
  },
  {
    primary_id: "D0IL0H",
    names: ["MaC46/M87o"],
  },
  {
    primary_id: "D0IL0U",
    names: ["Heterocycle-Containing Compound 4"],
  },
  {
    primary_id: "D0IL0Y",
    names: ["Peptide Analog 55"],
  },
  {
    primary_id: "D0IL1B",
    names: [
      "MCD-386/Glycopyrrolate",
      "13283-82-4",
      "(1,1-Dimethylpyrrolidin-1-Ium-3-Yl) 2-Cyclopentyl-2-Hydroxy-2-Phenylacetate",
      "Glycopyrronium (USAN)",
      "NCGC00179456-02",
      "Tovanor Breezhaler",
      "Zlchem 10",
      "Beta-1-Methyl-3-Pyrrolidyl-Alpha-Cyclopentylmandelate Methobromide",
      "G00010-Watson-Int",
      "Prestwick2_000746",
      "Prestwick1_000746",
      "Prestwick3_000746",
      "Prestwick0_000746",
      "AC1Q60WT",
      "BSPBio_000732",
      "SCHEMBL133002",
      "SPBio_002671",
      "AC1L1G28",
      "BPBio1_000806",
      "GTPL7459",
      "Pyrrolidinium,",
    ],
  },
  {
    primary_id: "D0IL2B",
    names: ["VML-252"],
  },
  {
    primary_id: "D0IL3B",
    names: ["PMID27841036-Compound-XVII"],
  },
  {
    primary_id: "D0IL3C",
    names: ["Tetra/Penta-Cyclic Pyridophthalazinone Derivative 1"],
  },
  {
    primary_id: "D0IL3O",
    names: [
      "Strontium Malonate",
      "Bone Disease Therapy, Osteologix",
      "NBS-101",
      "Osteoporosis Therapy, Osteologix",
      "Strontium (Bone Disease), Osteologix",
      "Strontium (Osteonecrosis), Osteologix",
      "Strontium (Osteoporosis), Osteologix",
    ],
  },
  {
    primary_id: "D0IL4C",
    names: [
      "TgDCC-E1A",
      "E1A Tumor Supressor Gene Product, Targeted Genetics",
      "RGG-0853",
      "DC-Cholesterol (E1A)",
      "E1A/DC-Cholesterol Liposomes, Targeted Genetics",
      "Gene Therapy (Cancer), Targeted Genetics/MD Anderson",
    ],
  },
  {
    primary_id: "D0IL4Z",
    names: ["PMID25435285-Compound-38"],
  },
  {
    primary_id: "D0IL7F",
    names: ["N,N-DiCPM[D-Pro-10]Dyn A-(1-11)", "CHEMBL406893"],
  },
  {
    primary_id: "D0IL7L",
    names: [
      "Prednisone",
      "Adasone",
      "Ancortone",
      "Bicortone",
      "Cartancyl",
      "Colisone",
      "Cortan",
      "Cortidelt",
      "Cotone",
      "Dacorten",
      "Dacortin",
      "Decortancyl",
      "Decortin",
      "Decortisyl",
      "Dehydrocortisone",
      "Dekortin",
      "Dellacort",
      "Deltacortene",
      "Deltacortisone",
      "Deltacortone",
      "Deltasone",
      "Deltison",
      "Deltisona",
      "Deltisone",
      "Deltra",
      "Diadreson",
      "Econosone",
      "Encorton",
      "Encortone",
      "Enkortolon",
      "Enkorton",
      "Fiasone",
      "Hostacortin",
      "Incocortyl",
      "Juvason",
      "Kortancyl",
      "Lisacort",
      "Lodotra",
      "Metacortandracin",
      "Meticorten",
      "Nisona",
      "Nizon",
      "Novoprednisone",
      "Nurison",
      "Orasone",
      "Panafcort",
      "Panasol",
      "Paracort",
      "Parmenison",
      "Pehacort",
      "Precort",
      "Predeltin",
      "Prednicorm",
      "Prednicort",
      "Prednicot",
      "Prednidib",
      "Prednilonga",
      "Prednison",
      "Prednisona",
      "Prednisonum",
      "Prednitone",
      "Prednizon",
      "Prednovister",
      "Presone",
      "Pronison",
      "Pronisone",
      "Rectodelt",
      "Retrocortine",
      "Servisone",
      "Sone",
      "Sterapred",
      "Supercortil",
      "Ultracorten",
      "Ultracortene",
      "Winpred",
      "Wojtab",
      "Zenadrid",
      "Dellacort A",
      "Delta E",
      "Delta Cortelan",
      "Liquid Pred",
      "Origen Prednisone",
      "Prednisone Intensol",
      "Zenadrid [veterinary]",
      "P1276",
      "U 6020",
      "Apo-Prednisone",
      "Delta E.",
      "Delta-Cortelan",
      "Delta-Cortisone",
      "Delta-Cortone",
      "Delta-Dome",
      "Delta-E",
      "Delta1-Cortisone",
      "Delta1-Dehydrocortisone",
      "Di-Adreson",
      "In-Sone",
      "Me-Korti",
      "Meticortelone (TN)",
      "Meticorten (TN)",
      "Meticorten (Veterinary)",
      "Metrevet (Veterinary)",
      "Prednicen-M",
      "Prednisona [INN-Spanish]",
      "Prednisone [INN:BAN]",
      "Prednisonum [INN-Latin]",
      "SK-Prednisone",
      "Zenadrid (Veterinary)",
      "Delta(Sup 1)-Cortisone",
      "Delta(Sup 1)-Dehydrocortisone",
      "Delta(Sup1)-Cortisone",
      "Delta-1-Cortisone",
      "Delta-1-Dehydrocortisone",
      "Deltasone, Liquid Pred, Orasone, Adasone, Deltacortisone,Prednisone",
      "(1S,2R,10S,11S,14R,15S)-14-Hydroxy-14-(2-Hydroxyacetyl)-2,15-Dimethyltetracyclo[8.7.0.0^{2,7}.0^{11,15}]heptadeca-3,6-Diene-5,17-Dione",
      "(8S,9S,10R,13S,14S,17R)-17-Hydroxy-17-(2-Hydroxyacetyl)-10,13-Dimethyl-6,7,8,9,12,14,15,16-Octahydrocyclopenta[a]phenanthrene-3,11-Dione",
      "(8xi,9xi,14xi)-17,21-Dihydroxypregna-1,4-Diene-3,11,20-Trione",
      "1,2-Dehydrocortisone",
      "1,4-Pregnadiene-17-Alpha,21-Diol-3,11,20-Trione",
      "1,4-Pregnadiene-17.alpha.,21-Diol-3,11,20-Trione",
      "1,4-Pregnadiene-17alpha,21-Diol-3,11,20-Trione",
      "1-Cortisone",
      "1-Dehydrocortisone",
      "17,21-Dihydroxypregna-1,4-Diene-3,11,20-Trione",
      "17alpha,21-Dihydroxy-1,4-Pregnadiene-3,11,20-Trione",
    ],
  },
  {
    primary_id: "D0IL7V",
    names: ["KRP-107"],
  },
  {
    primary_id: "D0IL8R",
    names: ["A-93178", "A-93178.5"],
  },
  {
    primary_id: "D0IL9H",
    names: ["1-Benzhydryl-4-(4-Propylphenyl)Piperidin-4-Ol", "CHEMBL393675"],
  },
  {
    primary_id: "D0IM1B",
    names: ["MOv18IgE"],
  },
  {
    primary_id: "D0IM1Q",
    names: ["N-(Biphenyl-4-Yl)Benzo[d]isoxazol-3-Amine", "CHEMBL578782"],
  },
  {
    primary_id: "D0IM3P",
    names: ["ACC2 Project"],
  },
  {
    primary_id: "D0IM4E",
    names: [
      "2-(3-Amino-Phenyl)-6-Methyl-Chromen-4-One",
      "CHEMBL132423",
      "ZINC13491189",
      "AKOS022269929",
      "468060-72-2",
      "KB-191922",
    ],
  },
  {
    primary_id: "D0IM5A",
    names: ["KITE-439"],
  },
  {
    primary_id: "D0IM5E",
    names: ["Desethyl Isoquine", "CHEMBL146826", "ZINC1912679", "BDBM50134934"],
  },
  {
    primary_id: "D0IM5H",
    names: ["KNI-10541"],
  },
  {
    primary_id: "D0IM6O",
    names: ["Talactoferrin"],
  },
  {
    primary_id: "D0IM6T",
    names: ["Tisagenlecleucel-T"],
  },
  {
    primary_id: "D0IM7C",
    names: ["AE-9C90CB"],
  },
  {
    primary_id: "D0IN0X",
    names: ["(S)-N2-{4-[(3-Fluorobenzyl)Oxy]benzyl}serinamide", "CHEMBL243059", "SCHEMBL2014885"],
  },
  {
    primary_id: "D0IN2K",
    names: ["CD22 CAR-T"],
  },
  {
    primary_id: "D0IN5E",
    names: ["PMID25666693-Compound-48"],
  },
  {
    primary_id: "D0IN7A",
    names: ["Alpha-Sulfanyl(2,4-Dichlorobenzyl)Phosphonic Acid"],
  },
  {
    primary_id: "D0IN7I",
    names: ["Nemonapride"],
  },
  {
    primary_id: "D0IN7R",
    names: ["MUC-1 CART Cell Immunotherapy"],
  },
  {
    primary_id: "D0IN8Z",
    names: ["LY2928057"],
  },
  {
    primary_id: "D0IN9G",
    names: ["Liatermin"],
  },
  {
    primary_id: "D0IO0I",
    names: ["Go-Y022"],
  },
  {
    primary_id: "D0IO0M",
    names: ["Beta-Phe Compound 3"],
  },
  {
    primary_id: "D0IO1H",
    names: ["Naloxone Sublingual Spray"],
  },
  {
    primary_id: "D0IO2A",
    names: ["PMID25399762-Compound-Table 6-9"],
  },
  {
    primary_id: "D0IO2X",
    names: ["PMID25684022-Compound-WO2011137219 38(5-7)"],
  },
  {
    primary_id: "D0IO8G",
    names: ["GW4065"],
  },
  {
    primary_id: "D0IO8J",
    names: [
      "2-Methyl-6-(Phenylethynyl)Pyridine",
      "MPEP",
      "96206-92-7",
      "6-Methyl-2-(Phenylethynyl)Pyridine",
      "2-Methyl-6-(2-Phenylethynyl)Pyridine",
      "UNII-7VC0YVI27Y",
      "7VC0YVI27Y",
      "CHEMBL66654",
      "CHEBI:64159",
      "Pyridine, 2-Methyl-6-(Phenylethynyl)-",
      "Tocris-1212",
      "AC1MHGB1",
      "Lopac-M-5435",
      "Lopac0_000738",
      "MLS006011229",
      "SCHEMBL103061",
      "2-Picoline, 6-Phenylethynyl-",
      "GTPL1426",
      "DTXSID9043982",
      "MolPort-003-958-770",
      "HMS3651F13",
      "2-Methyl-6-Phenylethynyl-Pyridine",
      "ZINC2581406",
      "BCP05962",
      "ZX-AN014840",
      "2-Methyl-6-(Phenylethynyl)-Pyridine",
      "S2809",
      "HY-14609A",
      "2151AH",
    ],
  },
  {
    primary_id: "D0IO8Y",
    names: ["Epoxysuccinate Derivative 8"],
  },
  {
    primary_id: "D0IO9Z",
    names: ["Tafinlar + Mekinist"],
  },
  {
    primary_id: "D0IP2J",
    names: ["ProQuad"],
  },
  {
    primary_id: "D0IP5E",
    names: ["PMID25666693-Compound-9"],
  },
  {
    primary_id: "D0IP6X",
    names: ["Osanetant"],
  },
  {
    primary_id: "D0IP7L",
    names: ["(6-Propoxy-2-Naphthyl)-2-Aminopropane", "CHEMBL471840"],
  },
  {
    primary_id: "D0IP7M",
    names: ["PAT-SM4"],
  },
  {
    primary_id: "D0IP9E",
    names: ["Hyqvia"],
  },
  {
    primary_id: "D0IP9V",
    names: ["PH-709829"],
  },
  {
    primary_id: "D0IQ0S",
    names: ["Tyr-Pro-Phe-Ala-Bn", "CHEMBL577749"],
  },
  {
    primary_id: "D0IQ0Z",
    names: ["GSK1070916"],
  },
  {
    primary_id: "D0IQ1V",
    names: ["CIGB-300"],
  },
  {
    primary_id: "D0IQ2P",
    names: ["NexVax2"],
  },
  {
    primary_id: "D0IQ3E",
    names: ["Muc1-Specific Gene-Engineered T Cells"],
  },
  {
    primary_id: "D0IQ4S",
    names: ["L-Ala-L-BoroPro"],
  },
  {
    primary_id: "D0IQ4V",
    names: ["Anti-GPC3 CAR T"],
  },
  {
    primary_id: "D0IQ5V",
    names: ["PMID28074661-Compound-US20100022547C86"],
  },
  {
    primary_id: "D0IQ6G",
    names: ["PMID27774822-Compound-Figure2Example1-1right"],
  },
  {
    primary_id: "D0IQ6O",
    names: ["S-1286", "A-CNDAC"],
  },
  {
    primary_id: "D0IQ6P",
    names: ["Cabozantinib", "Cabometyx", "Cometriq"],
  },
  {
    primary_id: "D0IQ7Y",
    names: [
      "PBIT",
      "2514-30-9",
      "2-(4-Methylphenyl)-1,2-Benzisothiazol-3(2H)-One",
      "2-(4-Methylphenyl)-1,2-Benzothiazol-3-One",
      "MLS000583746",
      "2-(P-Tolyl)Benzo[d]isothiazol-3(2H)-One",
      "2-(P-Tolyl)-1,2-Benzothiazol-3-One",
      "SMR000200989",
      "2-(4-Methylphenyl)-1,2-Benzothiazol-3(2H)-One",
      "1,2-Benzisothiazol-3(2H)-One, 2-(4-Methylphenyl)-",
      "2-(4-Methylphenyl)-2,3-Dihydro-1,2-Benzothiazol-3-One",
      "ChemDiv3_007090",
      "AC1LIP69",
      "Cid_935415",
      "SCHEMBL2443755",
      "GTPL7026",
      "CHEMBL1336959",
      "CTK0J4356",
      "BDBM34737",
      "AOB6896",
      "DTXSID10359056",
      "MolPort-002-285-696",
      "HMS2576N21",
    ],
  },
  {
    primary_id: "D0IQ8I",
    names: ["PMID26560530-Compound-23"],
  },
  {
    primary_id: "D0IR0R",
    names: ["(RS/SR)-2-[1-(4-Chlorophenyl)Butyl]piperidine", "CHEMBL218847"],
  },
  {
    primary_id: "D0IR2V",
    names: ["Prodarsan", "Cockayne Disease Therapy, DNage", "Cockayne Disease Therapy, Pharming"],
  },
  {
    primary_id: "D0IR4Y",
    names: ["2-(2-Amino-Ethyl)-7-Imino-Azepane", "CHEMBL92475", "SCHEMBL6976741", "BDBM50104654"],
  },
  {
    primary_id: "D0IR5I",
    names: ["3-Pyridin-3-Yl-Quinoline-6,7-Diol"],
  },
  {
    primary_id: "D0IR6K",
    names: [
      "AF-219",
      "AF-742",
      "P2X3 Antagonist (Pain), Roche",
      "P2X3 Antagonist (Oral, Pain), Afferent Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0IR6U",
    names: ["CMP-6"],
  },
  {
    primary_id: "D0IR7E",
    names: ["3-Ketosphinganine"],
  },
  {
    primary_id: "D0IR7H",
    names: ["PMID25666693-Compound-27"],
  },
  {
    primary_id: "D0IR8B",
    names: ["Benzonaphthyridine Derivative 2"],
  },
  {
    primary_id: "D0IR8J",
    names: ["INDUS-815C"],
  },
  {
    primary_id: "D0IS1M",
    names: ["PMID29473428-Compound-34"],
  },
  {
    primary_id: "D0IS5V",
    names: ["TC-34"],
  },
  {
    primary_id: "D0IS5Z",
    names: [
      "Anti-CEA Designer T Cell Therapy",
      "Anti-CEA Designer T Cells, Beth Israel",
      "Engineered T Cells, Harvard/Immunomedics",
      "Anti-CEA Designer T Cell Therapy, Harvard/Immunomedics",
      "Gene Therapy (CEA+ Adenocarcinoma), Beth Israel",
      "Gene Therapy (Carcinoembryonic Antigen), Harvard/Immunomedics",
      "Gene Therapy (CEA+ Adenocarcinoma), Harvard/Immunomedics",
      "T Cell Gene Therapy (CEA, Colon Tumor), Harvard/Immunomedics",
    ],
  },
  {
    primary_id: "D0IS6B",
    names: ["Normetanephrine"],
  },
  {
    primary_id: "D0IS8B",
    names: ["PMID25726713-Compound-33"],
  },
  {
    primary_id: "D0IS8R",
    names: ["PMID27744724-Compound-10"],
  },
  {
    primary_id: "D0IT1M",
    names: [
      "4,6-Dideoxyglucose",
      "4,6-DIDEOXYGLUCOSE",
      "AC1NRBIQ",
      "ZINC12501177",
      "DB01841",
      "4,6-Dideoxy-Alpha-D-Xylo-Hexopyranose",
    ],
  },
  {
    primary_id: "D0IT1P",
    names: ["2,7-Bis[2-(Pyrrolidino)Acetamido]anthraquinone", "SCHEMBL4297878", "CHEMBL522685"],
  },
  {
    primary_id: "D0IT1V",
    names: ["Zika Virus Vaccine"],
  },
  {
    primary_id: "D0IT2G",
    names: [
      "Dexamethasone",
      "Adexone",
      "Anaflogistico",
      "Aphtasolon",
      "Aphthasolone",
      "Auxiron",
      "Azium",
      "Calonat",
      "Corson",
      "Corsone",
      "Cortisumman",
      "DXM",
      "Decacort",
      "Decacortin",
      "Decaderm",
      "Decadron",
      "Decagel",
      "Decaject",
      "Decalix",
      "Decameth",
      "Decasone",
      "Decaspray",
      "Dectancyl",
      "Dekacort",
      "Deltafluorene",
      "Dergramin",
      "Deronil",
      "Desadrene",
      "Desametasone",
      "Desamethasone",
      "Desameton",
      "Deseronil",
      "Dexacort",
      "Dexacortal",
      "Dexacortin",
      "Dexadeltone",
      "Dexafarma",
      "Dexair",
      "Dexalona",
      "Dexaltin",
      "Dexametasona",
      "Dexameth",
      "Dexamethansone",
      "Dexamethasonum",
      "Dexamethazone",
      "Dexamonozon",
      "Dexapolcort",
      "Dexaprol",
      "Dexason",
      "Dexasone",
      "Dexinolon",
      "Dexinoral",
      "Dexone",
      "Dexonium",
      "Dexpak",
      "Dextelan",
      "Dezone",
      "Dinormon",
      "Dxms",
      "Fluormethylprednisolone",
      "Fluormone",
      "Fluorocort",
      "Fortecortin",
      "Gammacorten",
      "Hexadecadrol",
      "Hexadrol",
      "IontoDex",
      "Loverine",
      "Luxazone",
      "Maxidex",
      "Mediamethasone",
      "Methylfluorprednisolone",
      "Mexidex",
      "Millicorten",
      "Mymethasone",
      "Oradexon",
      "Policort",
      "Posurdex",
      "Prodex",
      "Spoloven",
      "Superprednol",
      "Turbinaire",
      "Visumetazone",
      "Alcon Brand Of Dexamethasone",
      "Bisu DS",
      "Desametasone [DCIT]",
      "Dexa Mamallet",
      "Dexamethasone Base",
      "Dexamethasone Intensol",
      "Dexamethasone Alcohol",
      "ECR Brand Of Dexamethasone",
      "Foy Brand Of Dexamethasone",
      "Hexadrol Elixir",
      "Hexadrol Tablets",
      "ICN Brand Of Dexamethasone",
      "Lokalison F",
      "Merck Brand Of Dexamethasone",
      "Pet Derm III",
      "Prednisolon F",
      "Prednisolone F",
      "Sunia Sol D",
      "Dexone 4",
      "MK 125",
      "Merz Brand 1 Of Dexamethasone",
      "Merz Brand 2 Of Dexamethasone",
      "Aeroseb-D",
      "Aeroseb-Dex",
      "Azium (Veterinary)",
      "Decadron (TN)",
      "Decadron Tablets, Elixir",
      "Decadron, Dexamethasone",
      "Decadron-LA",
      "Dex-Ide",
      "Dexa-Cortidelt;Dexa-Cortisyl",
      "Dexa-Mamallet",
      "Dexa-Scheroson",
      "Dexa-Sine",
      "Dexacen-4",
      "Dexametasona [INN-Spanish]",
      "Dexamethasonum [INN-Latin]",
      "Dexone 0.5",
      "Dexone 0.75",
      "Dexone 1.5",
      "Hl-Dex",
      "Isopto-Dex",
      "OTO-104",
      "Ocu-Trol;Pet-Derm Iii",
      "SK-Dexamethasone",
      "Decaject L.A.",
      "Dexamethasone [INN:BAN:JAN]",
      "Decaject-L.A.",
      "Dexamethasone (JP15/USP/INN)",
      "Delta1-9alpha-Fluoro-16alpha-Methylcortisol",
      "Delta(Sup 1)-9-Alpha-Fluoro-16-Alpha-Methylcortisol",
      "(3H)-Dexamethasone",
      "1-Dehydro-16.alpha.-Methyl-9.alpha.-Fluorohydrocortisone",
      "1-Dehydro-16alpha-Methyl-9alpha-Fluorohydrocortisone",
      "16-Alpha-Methyl-9-Alpha-Fluoro-1-Dehydrocortisol",
      "16-Alpha-Methyl-9-Alpha-Fluoro-Delta(Sup 1)-Hydrocortisone",
      "16-Alpha-Methyl-9-Alpha-Fluoro-Delta1-Hydrocortisone",
      "16-Alpha-Methyl-9-Alpha-Fluoroprednisolone",
      "16.alpha.-Methyl-9.alpha.-Fluoro-1-Dehydrocortisol",
      "16.alpha.-Methyl-9.alpha.-Fluoroprednisolone",
      "16alpha-Methyl-9alpha-Fluoro-1-Dehydrocortisol",
      "16alpha-Methyl-9alpha-Fluoro-Delta(Sup 1)-Hydrocortisone",
      "16alpha-Methyl-9alpha-Fluoroprednisolone",
      "9-Alpha-Fluoro-16-Alpha-Methylprednisolone",
      "9.alpha.-Fluoro-16.alpha.-Methylprednisolone",
      "9A-FLUORO-16BETA-METHYLPREDNISOLONE",
      "9alpha-Fluoro-16alpha-Methylprednisolone",
    ],
  },
  {
    primary_id: "D0IT2X",
    names: [
      "Carprofen",
      "Carprofene",
      "Carprofeno",
      "Carprofenum",
      "Imadyl",
      "Ridamyl",
      "Rimadyl",
      "Carprofeno [Spanish]",
      "C 5720",
      "Ro 205720",
      "C-5720",
      "Carprofene [INN-French]",
      "Carprofeno [INN-Spanish]",
      "Carprofenum [INN-Latin]",
      "Rimadyl (TN)",
      "Ro 20-5720",
      "Carprofen (USAN/INN)",
      "Carprofen [USAN:INN:BAN]",
      "Ro 20-5720/000",
      "Ro-20-5720",
      "Ro-20-5720/000",
      "(+-)-6-Chloro-Alpha-Methylcarbazole-2-Acetic Acid",
      "(+-)-Isomer Of Carprofen",
      "(+/-)-2-(3-Chloro-9H-Carbazol-7-Yl)Propanoic Acid",
      "(+/-)-6-Chloro-.alpha.-Methylcarbazole-2-Acetic Acid",
      "(-)-6-Chlor-Alpha-Methyl-2-Carbazolessigsaeure",
      "2-(6-Chloro-9H-Carbazol-2-Yl)-Propionic Acid",
      "2-(6-Chloro-9H-Carbazol-2-Yl)Propanoic Acid",
      "6-Chlor-Alpha-Methyl-Carbazol-2-Essigsaeure",
      "6-Chlor-Alpha-Methyl-Carbazol-2-Essigsaeure [German]",
      "6-Chloro-Alpha-Methyl-9H-Carbazole-2-Acetic Acid",
      "6-Chloro-Alpha-Methylcarbazole-2-Acetic Acid",
      "9H-Carbazole-2-Acetic Acid, 6-Chloro-.al",
    ],
  },
  {
    primary_id: "D0IT3V",
    names: ["Thieno[2,3-C]pyridine Derivative 1"],
  },
  {
    primary_id: "D0IT4N",
    names: ["PMID25666693-Compound-94"],
  },
  {
    primary_id: "D0IT5D",
    names: [
      "Alpha-Propyldopacetamide",
      "2-(3,4-Dihydroxyphenyl)Pentanamide",
      "154-62-1",
      "A-Propyldopacetamide",
      "Dihydroxyphenylvaleramide",
      "EINECS 205-830-9",
      "Benzeneacetamide,3,4-Dihydroxy-A-Propyl-,(AR)-",
      "Benzeneacetamide,3,4-Dihydroxy-A-Propyl-,(AS)-",
      ".alpha.-Propyldopacetamide",
      "ACMC-20mn67",
      "ACMC-20mn66",
      "AC1Q5IT3",
      "AC1L2R8E",
      "GTPL5095",
      "CTK8G6411",
      "GDXQWRJYXZXWMT-UHFFFAOYSA-N",
      "MolPort-003-894-618",
      "KUC106729N",
      "2-(3,4-Dihydroxyphenyl)Valeramide",
      "AKOS024285098",
      "A-Propyl-3,4-Dihydroxyphenyl-Acetamide",
      "MCULE-1302132273",
    ],
  },
  {
    primary_id: "D0IT6Q",
    names: [
      "Tandutinib",
      "CT 53518",
      "CT53518",
      "MLN 518",
      "CT-53518",
      "MLN-0518",
      "MLN-518",
      "MLN518, CT53518",
      "Tandutinib (USAN/INN)",
      "(4-(6-Methoxy-7-(3-Piperidylpropoxy)Quinazolin-4-Yl)Piperazinyl)-N-(4-(Methylethoxy)Phenyl)Carboxamide",
      "4-(6-Methoxy-7-(3-Piperidin-1-Ylpropoxy)Quinazolin-4-Yl)Piperazine-1-Carboxylic Acid (4-Isopropoxyphenyl)Amide",
      "4-[6-Methoxy-7-(3-Piperidin-1-Ylpropoxy)Quinazolin-4-Yl]-N-(4-Propan-2-Yloxyphenyl)Piperazine-1-Carboxamide",
    ],
  },
  {
    primary_id: "D0IT7F",
    names: ["Sitagliptin + Metformin"],
  },
  {
    primary_id: "D0IT9C",
    names: ["Picolinamido Propanoic Acid Derivative 1"],
  },
  {
    primary_id: "D0IU0W",
    names: ["Q-201"],
  },
  {
    primary_id: "D0IU1H",
    names: ["PMID25666693-Compound-52"],
  },
  {
    primary_id: "D0IU2F",
    names: ["NMED-160"],
  },
  {
    primary_id: "D0IU2K",
    names: ["YPAA-NH2", "CHEMBL466147"],
  },
  {
    primary_id: "D0IU3X",
    names: ["(4-Bromophenyl)Difluoromethanesulfonamide", "CHEMBL370854", "BDBM50175009"],
  },
  {
    primary_id: "D0IU5E",
    names: [
      "1-Benzoyl-N-Phenyl-1H-Pyrazole-3-Carboxamide",
      "AC1LERN1",
      "TimTec1_001906",
      "Oprea1_174326",
      "N-Benzoylpyrazole Deriv., 6",
      "CHEMBL388239",
      "BDBM23704",
      "MolPort-001-664-680",
      "ZINC115253",
      "HMS1539G14",
      "STK398485",
      "AKOS003748858",
      "MCULE-1390939767",
      "1-Benzoyl-N-Phenylpyrazole-3-Carboxamide",
      "ST025783",
      "N-Phenyl[1-(Phenylcarbonyl)Pyrazol-3-Yl]carboxamide",
    ],
  },
  {
    primary_id: "D0IU6I",
    names: ["[3H]PD140376", "[3H]PD 140376", "[3H]-PD140,376"],
  },
  {
    primary_id: "D0IU8V",
    names: [
      "4-(2-Benzylamino-Ethoxy)-1,3-Dihydro-Indol-2-One",
      "CHEMBL59752",
      "SCHEMBL7478147",
      "CVWQKEFTSSJDLS-UHFFFAOYSA-N",
      "ZINC1903144",
      "BDBM50077578",
      "4-[2-(Benzylamino)Ethoxy]indoline-2-One",
    ],
  },
  {
    primary_id: "D0IV2E",
    names: ["Presqualene Diphosphate"],
  },
  {
    primary_id: "D0IV3M",
    names: ["Polyethylene Glycol"],
  },
  {
    primary_id: "D0IV4C",
    names: ["WP-1066"],
  },
  {
    primary_id: "D0IV5D",
    names: ["PMID3941405C3d"],
  },
  {
    primary_id: "D0IV6S",
    names: ["Intravenous Carbamazepine"],
  },
  {
    primary_id: "D0IV7F",
    names: ["IDP-115"],
  },
  {
    primary_id: "D0IV7J",
    names: ["131I-COU-1"],
  },
  {
    primary_id: "D0IV7Z",
    names: ["AZD-8418"],
  },
  {
    primary_id: "D0IV8T",
    names: ["PMID27744724-Compound-5"],
  },
  {
    primary_id: "D0IV9K",
    names: ["3-(Trifluoromethyl)Phenyl 4-Butoxybenzylcarbamate", "CHEMBL597272"],
  },
  {
    primary_id: "D0IW0Y",
    names: ["MEN-10207"],
  },
  {
    primary_id: "D0IW1Y",
    names: ["F-16915"],
  },
  {
    primary_id: "D0IW4E",
    names: ["GALE-301 + GALE-302"],
  },
  {
    primary_id: "D0IW4Q",
    names: ["JWH-305"],
  },
  {
    primary_id: "D0IW4R",
    names: [
      "TREFENTANIL HYDROCHLORIDE",
      "A-3665",
      "ANQ-3665",
      "Trefentanil Hydrochloride < Rec INNM",
      "N-[1-[2-(4-Ethyl-5-Oxo-4,5-Dihydro-1H-Tetrazol-1-Yl)Ethyl]-4-Phenylpiperidin-4-Yl]-N-(2-Fluorophenyl)Propionamide Hydrochloride",
      "1-[2-(4-Ethyl-5-Oxo-4,5-Dihydro-1H-Tetrazol-1-Yl)Ethyl]-4-[N-(2-Fluorophenyl)Propionamido]-4-Phenylpiperidine Hydrochloride",
    ],
  },
  {
    primary_id: "D0IW5C",
    names: [
      "3,4'-(1H-1,2,4-Triazole-3,5-Diyl)Dipyridine",
      "CHEMBL1077387",
      "36770-51-1",
      "1H-3-(3-Pyridyl)-5-(4-Pyridyl)-1,2,4-Triazole",
      "AC1LJ7ET",
      "SCHEMBL11592234",
      "MolPort-002-889-160",
      "BDBM50311281",
      "STL442012",
      "CCG-42398",
      "ZINC18164784",
      "AKOS002669464",
      "AKOS005228430",
      "AKOS002732698",
      "MCULE-6941572691",
      "FT-0749446",
      "3,4''-(1H-1,2,4-Triazole-3,5-Diyl)Dipyridine",
      "SR-01000632401-1",
      "3-(5-Pyridin-4-Yl-1H-1,2,4-Triazol-3-Yl)Pyridine",
      "3-(3-Pyridyl)-5-(4-Pyridyl)-1H-1,2,4-Triazole",
    ],
  },
  {
    primary_id: "D0IW6G",
    names: ["EC33"],
  },
  {
    primary_id: "D0IW6R",
    names: ["PD-L1 MAb + LY"],
  },
  {
    primary_id: "D0IW7A",
    names: ["Cyclic Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D0IW8I",
    names: ["LipoNeu"],
  },
  {
    primary_id: "D0IW8V",
    names: [
      "L-Tryptophan-L-Asparagine",
      "CHEMBL513911",
      "175027-11-9",
      "WN Dipeptide",
      "H-Trp-Asn-OH",
      "W-N Dipeptide",
      "L-Tryptophyl-L-Asparagine",
      "Tryptophan Asparagine Dipeptide",
      "CHEBI:141447",
      "WN",
      "ZINC2561118",
      "BDBM50266679",
      "(2S)-4-Amino-2-{[(2S)-2-Amino-3-(1H-Indol-3-Yl)Propanoyl]amino}-4-Oxobutanoic Acid",
    ],
  },
  {
    primary_id: "D0IW9Q",
    names: ["MDX-1147"],
  },
  {
    primary_id: "D0IX0N",
    names: [
      "N*1*-(5-Methyl-2-Nitro-Phenyl)-Butane-1,4-Diamine",
      "CHEMBL89454",
      "1,4-Butanediamine, N1-(5-Methyl-2-Nitrophenyl)-",
      "BDBM50065369",
      "AKOS018628293",
    ],
  },
  {
    primary_id: "D0IX1A",
    names: ["Pentolinium"],
  },
  {
    primary_id: "D0IX1I",
    names: ["NBI-98854"],
  },
  {
    primary_id: "D0IX1V",
    names: ["1-(10-Bromoanthracen-9-Yl)-2-Aminopropane", "CHEMBL465525"],
  },
  {
    primary_id: "D0IX2C",
    names: ["Quinazolinedione Derivative 2"],
  },
  {
    primary_id: "D0IX3I",
    names: ["2-Phenyl-2H-Indazole-7-Carboxamide", "CHEMBL594298", "SCHEMBL1422404", "BDBM50306166"],
  },
  {
    primary_id: "D0IX4B",
    names: [
      "AG-024322",
      "AG-24322",
      "N-[[5-[(3E)-3-(4,6-Difluorobenzimidazol-2-Ylidene)-1,2-Dihydroindazol-5-Yl]-4-Methylpyridin-3-Yl]methyl]ethanamine",
    ],
  },
  {
    primary_id: "D0IX6I",
    names: ["Cortisone", "11-Dehydro-17-Hydroxycorticosterone", "17-Hydroxy-11-Dehydrocorticosterone"],
  },
  {
    primary_id: "D0IX7G",
    names: [
      "FR-76830",
      "113243-75-7",
      "FR 76830",
      "3-Pyridinecarboxamide,2-Methyl-N-[2-(4-Morpholinyl)Ethyl]-4-(3-Nitrophenyl)-6-Phenyl-",
      "HNBRMVZTOLAZRW-UHFFFAOYSA-N",
      "ACMC-20mhoq",
      "AC1MIZ8R",
      "SCHEMBL9601411",
      "Fr76830",
      "CTK4A8167",
      "DTXSID70150329",
      "2-Methyl-N-(2-(4-Morpholinyl)Ethyl)-4-(3-Nitrophenyl)-6-Phenylnicotinamide",
      "AKOS030543198",
      "2-Methyl-N-(4-Morpholinylethyl)-4-(3-Nitrophenyl)-6-Phenyl-3-Pyridinecarboxamide",
      "3-(2-Morpholinoethylcarbamoyl)-2-Methyl-4-(3-Nitrophenyl)-6-Phenylpyridine",
    ],
  },
  {
    primary_id: "D0IX7O",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 27"],
  },
  {
    primary_id: "D0IX7S",
    names: [
      "MDL 100,453",
      "D-Norvaline, 4-Oxo-5-Phosphono-",
      "CHEMBL431029",
      "MDL-100483",
      "129938-34-7",
      "4-Opnv",
      "Mdl 100453",
      "4-Oxo-5-Phosphononorvaline",
      "AC1MHZND",
      "SCHEMBL195547",
      "(R)-4-Oxo-5-Phosphononorvaline",
      "BDBM50010893",
      "AKOS030593176",
      "MDL-100453",
      "2-Amino-4-Oxo-5-Phosphono-Pentanoic Acid",
      "(2R)-2-Amino-4-Oxo-5-Phosphonopentanoic Acid",
      "(R)-2-Amino-4-Oxo-5-Phosphono-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0IX7Y",
    names: ["BX-912", "BX 912", "BX912"],
  },
  {
    primary_id: "D0IX9E",
    names: ["NIZ985"],
  },
  {
    primary_id: "D0IX9O",
    names: ["Q-Derp1"],
  },
  {
    primary_id: "D0IY0R",
    names: ["Viquidacin", "AVE-4221", "AVE-6971", "NXL-101", "Topoisomerase IV Inhibitor Antibacterials, Novexel"],
  },
  {
    primary_id: "D0IY1Z",
    names: ["Azetidine Derivative 5"],
  },
  {
    primary_id: "D0IY2B",
    names: ["Hairpin RNA"],
  },
  {
    primary_id: "D0IY2M",
    names: [
      "AF-11",
      "AF-13",
      "AF-6",
      "Cytokine Production Inhibitors (Pain/Inflammatory Disease/Cancer)",
      "Cytokine Production Inhibitors (Pain/Inflammatory Disease/Cancer), Angelini Pharmaceuticals",
      "CCL-2 Inhibitors (Pain/Inflammatory Disease/Cancer), Angelini Pharmaceuticals",
      "MCP-1 Inhibitors (Pain/Inflammatory Disease/Cancer), Angelini Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0IY2V",
    names: ["MCL-445"],
  },
  {
    primary_id: "D0IY3E",
    names: ["PMID16242323C22d"],
  },
  {
    primary_id: "D0IY3Z",
    names: ["Bivigam"],
  },
  {
    primary_id: "D0IY5H",
    names: ["PMID27607364-Compound-62"],
  },
  {
    primary_id: "D0IY5Y",
    names: ["A-80040", "CHEMBL378186", "SCHEMBL16705052", "BDBM50189633"],
  },
  {
    primary_id: "D0IY8T",
    names: ["PMID25435285-Compound-46"],
  },
  {
    primary_id: "D0IY9H",
    names: ["PA451"],
  },
  {
    primary_id: "D0IZ1A",
    names: ["LY309887"],
  },
  {
    primary_id: "D0IZ1D",
    names: ["Brofaromine", "Brofaremine", "Consonar", "Consonev", "Brofaromine Hydrochloride", "CGP-11305A"],
  },
  {
    primary_id: "D0IZ1I",
    names: [
      "SD-726",
      "Analgesic (Serotonin Noradrenaline Reuptake Inhibitor), Sosei",
      "Pain Therapy (Serotonin Noradrenaline Reuptake Inhibitor), Sosei",
      "SNRI(Pain), Sosei",
    ],
  },
  {
    primary_id: "D0IZ1X",
    names: ["(+)-SK&F10047", "(+)-SKF 10.047"],
  },
  {
    primary_id: "D0IZ4Z",
    names: ["3-[4-(4-Hydroxyphenyl)-1,3-Oxazol-2-Yl]phenol", "CHEMBL529446", "SCHEMBL1180107", "BDBM50271078"],
  },
  {
    primary_id: "D0IZ7T",
    names: ["YM-17E"],
  },
  {
    primary_id: "D0IZ8B",
    names: ["Pyridine Derivative 11"],
  },
  {
    primary_id: "D0IZ8M",
    names: ["PMID21855335C19a"],
  },
  {
    primary_id: "D0IZ8X",
    names: ["BPN14770"],
  },
  {
    primary_id: "D0IZ9O",
    names: [
      "4-Dehydroxyriccardin C",
      "Riccardin C Derivative, 20d",
      "CHEMBL411246",
      "BDBM23851",
      "2',4-(Ethylene-1,4-Phenylene-Oxy-1,3-Phenylene-Ethylene)-1,1'-Biphenyl-2,4'-Diol",
    ],
  },
  {
    primary_id: "D0J0AE",
    names: ["CP-945598"],
  },
  {
    primary_id: "D0J0AG",
    names: ["5-Bromo-2-(4-Hydroxy-Phenyl)-Quinolin-6-Ol"],
  },
  {
    primary_id: "D0J0AH",
    names: ["GP-668"],
  },
  {
    primary_id: "D0J0AN",
    names: ["Polymer-Tubulysin A Prodrug Conjugate"],
  },
  {
    primary_id: "D0J0AT",
    names: ["PMID25726713-Compound-25"],
  },
  {
    primary_id: "D0J0BB",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 11"],
  },
  {
    primary_id: "D0J0BI",
    names: ["NG-049"],
  },
  {
    primary_id: "D0J0BN",
    names: ["TAK-079"],
  },
  {
    primary_id: "D0J0BU",
    names: [
      "BETRIXABAN",
      "Betrixaban",
      "330942-05-7",
      "Bevyxxa",
      "PRT054021",
      "UNII-74RWP7W0J9",
      "PRT 054021",
      "74RWP7W0J9",
      "Betrixaban Maleate",
      "Betrixaban [USAN:INN]",
      "Betrixaban/",
      "Betrixaban (USAN)",
      "D0J0BU",
      "SCHEMBL158591",
      "QCR-99",
      "GTPL9602",
      "EX-A358",
      "MolPort-009-682-948",
      "ZINC3",
    ],
  },
  {
    primary_id: "D0J0BV",
    names: ["TCS 5861528"],
  },
  {
    primary_id: "D0J0BW",
    names: [
      "Cyanotriphenylborate",
      "Cyanotriphenylboranuide",
      "Cyanotriphenylboronanion",
      "Cyano-Tri(Phenyl)Boron",
      "Cyano(Triphenyl)Boranuide",
      "Cyanotriphenylborate(1-)",
      "AC1L47BQ",
      "GTPL2368",
      "SCHEMBL2769206",
      "CHEBI:38895",
      "Sodium(Cyano-C)Triphenylborate(1-)",
      "(Cyano-KappaC)(Triphenyl)Borate(1-)",
    ],
  },
  {
    primary_id: "D0J0CA",
    names: [
      "BETA-CCM",
      "Beta-CCM",
      "Methyl Beta-Carboline-3-Carboxylate",
      "Beta-Carboline-3-Carboxylic Acid Methyl Ester",
      "69954-48-9",
      "3-Carbomethoxy-Beta-Carboline",
      "UNII-I2A008F6YL",
      "Methyl 9H-Pyrido[3,4-B]indole-3-Carboxylate",
      "CHEMBL453066",
      "CHEMBL268191",
      "I2A008F6YL",
      "Methyl 9H-Pyrido(3,4-B)Indole-3-Carboxylate",
      "Methyl 9H-Beta-Carboline-3-Carboxylate",
      "9H-Beta-Carboline-3-Carboxylic Acid Methyl Ester",
      "9H-Pyrido(3,4-B)Indole-3-Carboxylic Acid, Methyl Ester",
      "C13H10N2O2",
      "Beta CCM",
      "Lopac-E-002",
      "Biomol-NT_000273",
      "AC1L32LA",
      "Lopac0_000523",
      "SCHEMBL1066725",
    ],
  },
  {
    primary_id: "D0J0CB",
    names: [
      "Cilostamide",
      "68550-75-4",
      "Ciloalamide",
      "OPC 3689",
      "Cilostamide [INN]",
      "N-Cyclohexyl-N-Methyl-4-(1,2-Dihydro-2-Oxo-6-Quinolyloxy)Butyramide",
      "Cilostamidum [INN-Latin]",
      "Cilostamida [INN-Spanish]",
      "UNII-45S5605Q18",
      "OPC 3869",
      "CHEMBL34431",
      "NCGC00015269-03",
      "N-Cyclohexyl-N-Methyl-4-[(2-Oxo-1H-Quinolin-6-Yl)Oxy]butanamide",
      "45S5605Q18",
      "Butanamide, N-Cyclohexyl-4-((1,2-Dihydro-2-Oxo-6-Quinolinyl)Oxy)-N-Methyl-",
      "N-Cyclohexyl-N-Methyl-4-((2-Oxo-1,2-Dihydroquinolin-6-Yl)Oxy)Butanamide",
      "DSSTox_RID_80698",
      "DSSTox_CID_25140",
      "DSSTox_GSID_45140",
    ],
  },
  {
    primary_id: "D0J0CU",
    names: [
      "2-Amino-6-(2-Cyano-Benzenesulfonyl)-Benzonitrile",
      "6-Amino-2-[(2-Cyanophenyl)Sulfonyl]benzenecarbonitrile",
      "AC1LA8F0",
      "CHEMBL298823",
      "BDBM1796",
      "CTK7C8868",
      "ZINC5933865",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3p",
      "2-Amino-6-(2-Cyanophenyl)Sulfonylbenzonitrile",
      "2-(2-Cyanophenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(2-Cyanophenyl)Sulfonyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D0J0DK",
    names: ["Ac-His-DNal(2)-Arg-Trp-NH2", "CHEMBL454736"],
  },
  {
    primary_id: "D0J0EL",
    names: ["1,8-Dihydroxy-Anthraquinone", "Danthron"],
  },
  {
    primary_id: "D0J0EM",
    names: ["Virexxa"],
  },
  {
    primary_id: "D0J0EP",
    names: ["PMID28074661-Compound-US20120264760C86"],
  },
  {
    primary_id: "D0J0ES",
    names: ["1-(3,4-Dichloro-Phenyl)-3-Diethylamino-Indan-5-Ol", "CHEMBL315697"],
  },
  {
    primary_id: "D0J0FD",
    names: ["MACI"],
  },
  {
    primary_id: "D0J0GJ",
    names: ["P-1116"],
  },
  {
    primary_id: "D0J0GM",
    names: [
      "6-(2-Aminophenoxy)Benzo[d]isothiazol-3-Amine",
      "CHEMBL458612",
      "SCHEMBL14241034",
      "ZINC3818486",
      "BDBM50274720",
      "AKOS022180470",
      "AJ-45722",
    ],
  },
  {
    primary_id: "D0J0GR",
    names: ["Rocapuldencel-T"],
  },
  {
    primary_id: "D0J0HE",
    names: ["MK-8150"],
  },
  {
    primary_id: "D0J0HK",
    names: ["3-Deoxyguanosine"],
  },
  {
    primary_id: "D0J0HV",
    names: ["VBY- 285"],
  },
  {
    primary_id: "D0J0IJ",
    names: ["RG7636"],
  },
  {
    primary_id: "D0J0IY",
    names: ["ANTAQ"],
  },
  {
    primary_id: "D0J0JC",
    names: ["FRG-8701"],
  },
  {
    primary_id: "D0J0JH",
    names: ["PMID27841045-Compound-156"],
  },
  {
    primary_id: "D0J0JT",
    names: ["CC-11006"],
  },
  {
    primary_id: "D0J0JY",
    names: [
      "Tetradecane",
      "629-59-4",
      "N-TETRADECANE",
      "Alkanes, C14-16",
      "Tridecane, Methyl-",
      "CCRIS 715",
      "Tetradecane, 99%",
      "UNII-03LY784Y58",
      "HSDB 5728",
      "EINECS 211-096-0",
      "NSC 72440",
      "C14H30",
      "BRN 1733859",
      "AI3-04240",
      "CHEBI:41253",
      "BGHCVCJVXZWKCC-UHFFFAOYSA-N",
      "MFCD00008986",
      "03LY784Y58",
      "90622-46-1",
      "Tetradecane, Analytical Standard",
      "Alkanes, C14-30",
      "Tetradekan",
      "N-Teradecane",
      "Tetradecane Olefine",
      "EINECS 292-448-0",
      "Paraffinic Hydrocarbons (C14-C30)",
      "Tetradecane, &gt",
      "AC1L1ZHO",
      "DSSTox_CID_7267",
      "EC 211-096-0",
      "DSSTox_RID_78378",
      "ACMC-1B07Q",
      "DSSTox_GSID_27267",
    ],
  },
  {
    primary_id: "D0J0KC",
    names: ["Pervanadate"],
  },
  {
    primary_id: "D0J0KD",
    names: ["Fibrinolysin"],
  },
  {
    primary_id: "D0J0KG",
    names: ["DM-1451", "DM 1451"],
  },
  {
    primary_id: "D0J0KR",
    names: ["RP-66471", "133320-02-2"],
  },
  {
    primary_id: "D0J0KT",
    names: ["RhuDex"],
  },
  {
    primary_id: "D0J0KX",
    names: ["PF-275366"],
  },
  {
    primary_id: "D0J0LC",
    names: ["PMID26651364-Compound-4"],
  },
  {
    primary_id: "D0J0LI",
    names: ["Gonadotropin, Chorionic", "A.P.L.", "Chorionic Gonadotropin", "Follutein", "Pregnyl"],
  },
  {
    primary_id: "D0J0MD",
    names: ["(Z)-4-(4-Hexylphenylamino)-4-Oxobut-2-Enoic Acid"],
  },
  {
    primary_id: "D0J0MK",
    names: ["JTT-608"],
  },
  {
    primary_id: "D0J0MX",
    names: [
      "1,4-Diphenyl-(1E,3E)-1,3-Butadiene",
      "Bistyryl",
      "1,4-Diphenyl-1,3-Butadiene",
      "1,4-Diphenylbutadiene",
      "Trans,trans-1,4-Diphenyl-1,3-Butadiene",
      "538-81-8",
      "1,4-Diphenylerythrene",
      "Distyryl",
      "Trans,trans-1,4-Diphenylbuta-1,3-Diene",
      "(1E,3E)-1,4-Diphenylbuta-1,3-Diene",
      "886-65-7",
      "NSC 122702",
      "1,4-Diphenylbuta-1,3-Diene",
      "UNII-R7P15V6543",
      "1,1'-(1E,3E)-Buta-1,3-Diene-1,4-Diyldibenzene",
      "1,3-Butadiene, 1,4-Diphenyl-",
      "EINECS 212-952-6",
      "CHEMBL198810",
      "CHEBI:35100",
      "JFLKFZNIIQFQBS-FNCQTZNRSA-N",
      "(4-Phenyl-1,3-Butadienyl)Benzene",
      "R7P15V6543",
      "1,1'-Buta-1,3-Diene-1,4-Diyldibenzene",
    ],
  },
  {
    primary_id: "D0J0NM",
    names: ["PMID28460551-Compound-4"],
  },
  {
    primary_id: "D0J0NS",
    names: ["[3H]PBC-264", "[3H]propionyl-BC-264", "[3H]-Propionyl-BC264", "[3H]-Propionyl-BC-264"],
  },
  {
    primary_id: "D0J0OF",
    names: ["Choroid Plexus Cell-Derived Factors", "Choroid Plexus Cell-Derived Factors (Topical Gel, Wound Healing)"],
  },
  {
    primary_id: "D0J0OY",
    names: [
      "3-(Benzyloxy)-2-(4-Chlorophenyl)Thiazolidin-4-One",
      "CHEMBL217096",
      "921611-01-0",
      "CTK3H0755",
      "DTXSID50658813",
      "BDBM50198243",
      "AKOS030551987",
      "4-Thiazolidinone, 2-(4-Chlorophenyl)-3-(Phenylmethoxy)-",
    ],
  },
  {
    primary_id: "D0J0PR",
    names: ["PIBROZELESIN HYDROCHLORIDE"],
  },
  {
    primary_id: "D0J0PT",
    names: ["BPX-601"],
  },
  {
    primary_id: "D0J0QW",
    names: [
      "(+/-)-2-(4'-Ethoxyphenyl)Thiomorpholine",
      "CHEMBL597371",
      "2-(4'-Ethoxyphenyl)Thiomorpholine",
      "BDBM50307370",
      "AKOS022307820",
      "1218947-83-1",
    ],
  },
  {
    primary_id: "D0J0RG",
    names: ["UK-66914"],
  },
  {
    primary_id: "D0J0RV",
    names: ["Larotaxel", "Benzenepropanoic Acid", "PNU 100940", "XRP 9881", "XRP9881"],
  },
  {
    primary_id: "D0J0TP",
    names: [
      "DIRLOTAPIDE",
      "Dirlotapide",
      "Slentrol",
      "UNII-578H0RMP25",
      "481658-94-0",
      "CHEMBL410414",
      "578H0RMP25",
      "Dirlotapide [USAN:INN]",
      "CP 742033",
      "Dirlotapide (USAN/INN)",
      "Slentrol [veterinary] (TN)",
      "SCHEMBL37781",
      "TUOSYWCFRFNJBS-BHVANESWSA-N",
      "HY-U00070",
      "BDBM50204367",
      "CS-6779",
      "CP-742033",
      "CP-742,033",
      "D03867",
      "N-((1S)-2-(Benzylmethylamino)-2-Oxo-1-Phenylethyl)-1-Methyl-5-(((4'-(Trifluoromethyl)Biphenyl-2-Yl)Carbonyl)Amino)-1H-Indole-2-Carboxamide",
      "1H-Indole-2-Carboxamide, 1-Methyl-N-((1S)-2-(Methyl(Phenylmethyl)Amino)-2-Oxo-1-Phenylethyl)-5-(((4'-(Trifluoromethyl",
    ],
  },
  {
    primary_id: "D0J0UC",
    names: ["Azetidine-Benzoxazin-3(4H)-One Derivative 1"],
  },
  {
    primary_id: "D0J0UN",
    names: ["PMID25482888-Compound-69"],
  },
  {
    primary_id: "D0J0UT",
    names: ["9H-Purine Derivative"],
  },
  {
    primary_id: "D0J0WG",
    names: ["PMID27998201-Compound-1"],
  },
  {
    primary_id: "D0J0WJ",
    names: ["N4-Butyl-2-Cinnamamido-N1-Hydroxysuccinamide", "CHEMBL596553"],
  },
  {
    primary_id: "D0J0WO",
    names: [
      "(4-Fluorophenyl)(Pyridin-4-Yl)Methanone",
      "4-(4-Fluorobenzoyl)Pyridine",
      "41538-36-7",
      "Methanone, (4-Fluorophenyl)-4-Pyridinyl-",
      "CHEMBL563196",
      "4-Fluorophenyl 4-Pyridyl Ketone",
      "(4-Fluorophenyl)-Pyridin-4-Ylmethanone",
      "AC1ODYVS",
      "3fu0",
      "ACMC-20amw5",
      "AC1Q4LVT",
      "SCHEMBL4618020",
      "CTK1D3778",
      "DTXSID60427471",
      "MolPort-001-628-204",
      "WTRWBYGUMQEFFI-UHFFFAOYSA-N",
      "ZINC2583439",
      "(4-Pyridyl)(P-Fluorophenyl) Ketone",
      "BDBM50294173",
      "MFCD02683088",
      "SBB092751",
      "4-[(4-Fluorophenyl)Carbonyl]pyridine",
      "AKOS003603273",
      "MCULE-1391167832",
      "DB06917",
      "NE40002",
    ],
  },
  {
    primary_id: "D0J0WX",
    names: ["(-)-3PPP, Maryland"],
  },
  {
    primary_id: "D0J0XN",
    names: ["OpRegen"],
  },
  {
    primary_id: "D0J0YB",
    names: [
      "1-Adamantan-1-Yl-3-(2-Hydroxyethyl)Urea",
      "CHEMBL398166",
      "120615-92-1",
      "NSC98656",
      "1-(1-Adamantyl)-3-(2-Hydroxyethyl)Urea",
      "AC1L6ASW",
      "Oprea1_805585",
      "CTK8A5210",
      "MolPort-000-709-916",
      "HMS1608K05",
      "ZINC1643992",
      "STK862338",
      "BDBM50223393",
      "IMED57309943",
      "NSC-98656",
      "AKOS023552817",
      "AKOS001482962",
      "MCULE-6824900815",
      "N-1-Adamantyl-N'-(2-Hydroxyethyl)Urea",
      "EN300-234857",
      "SR-01000396891",
      "SR-01000396891-1",
      "F0266-0451",
    ],
  },
  {
    primary_id: "D0J0YH",
    names: ["BI-187004 CL"],
  },
  {
    primary_id: "D0J0ZF",
    names: ["15-Isobutyrylmiguanin"],
  },
  {
    primary_id: "D0J0ZG",
    names: ["HS014"],
  },
  {
    primary_id: "D0J0ZK",
    names: [
      "MEL57A",
      "CHEMBL1253475",
      "GTPL6260",
      "SCHEMBL16410080",
      "BDBM50326990",
      "3-[(Z)-4-[[(Z)-4-(7, 8-Dimethoxy-2-Oxo-1H-3-Benzazepin-3-Yl)But-2-Enyl]-[(2R)-2-(3, 4-Dimethoxyphenyl)Propyl]amino]but-2-Enyl]-7, 8-Dimethoxy-1H-3-Benzazepin-2-One",
    ],
  },
  {
    primary_id: "D0J0ZM",
    names: ["RP-400C"],
  },
  {
    primary_id: "D0J0ZQ",
    names: ["Nociceptin/Orphanin FQ Receptor PET Ligands"],
  },
  {
    primary_id: "D0J0ZS",
    names: [
      "Gabapentin",
      "Aclonium",
      "GBN",
      "Gabapen",
      "Gabapentina",
      "Gabapentine",
      "Gabapentinium",
      "Gabapentino",
      "Gabapentinum",
      "Gabapetin",
      "Neurontin",
      "Serada",
      "Vultin",
      "Apotex Brand Of Gabapentin",
      "Aventis Brand Of Gabapentin",
      "Gabapentin GR",
      "Gabapentin Hexal",
      "Gabapentin Stada",
      "Gabapentino [Spanish]",
      "Hexal Brand Of Gabapentin",
      "Novopharm Brand Of Gabapentin",
      "Parke Davis Brand Of Gabapentin",
      "Pfizer Brand Of Gabapentin",
      "Pharmascience Brand Of Gabapentin",
      "Ratiopharm Brand Of Gabapentin",
      "Stadapharm Brand Of Gabapentin",
      "GOE 2450",
      "Go 3450",
      "Apo-Gabapentin",
      "DDS-2003",
      "DM-1796",
      "DM-5689",
      "G-154",
      "Gabapentin (Neurontin)",
      "Gabapentin-Ratiopharm",
      "Gabapentine [INN-French]",
      "Gabapentino [INN-Spanish]",
      "Gabapentinum [INN-Latin]",
      "Goe-3450",
      "Neurontin (TN)",
      "Novo-Gabapentin",
      "PMS-Gabapentin",
      "Warner-Lambert Brand Of Gabapentin",
      "Gabapentin [USAN:INN:BAN]",
      "[1-(AMINOMETHYL)CYCLOHEXYL]ACETIC ACID",
      "Gabapentin (JAN/USAN/INN)",
      "1-(Aminomethyl)-Cyclohexaneacetic Acid",
      "1-(Aminomethyl)Cyclohexaneacetic Acid",
      "2-[1-(Aminomethyl)Cyclohexyl]acetic Acid",
    ],
  },
  {
    primary_id: "D0J0ZU",
    names: ["KIN-3031"],
  },
  {
    primary_id: "D0J1AE",
    names: [
      "Phenethyl-(4-Phenyl-Butyl)-Amine",
      "CHEMBL280111",
      "SCHEMBL8084143",
      "4-Phenyl-N-Phenethyl-1-Butanamine",
      "ZINC13736042",
    ],
  },
  {
    primary_id: "D0J1AU",
    names: ["CODA-001"],
  },
  {
    primary_id: "D0J1AV",
    names: ["LAS 100977"],
  },
  {
    primary_id: "D0J1AW",
    names: [
      "4-(3-Hydroxy-Benzylideneamino)-Benzenesulfonamide",
      "CHEMBL270243",
      "66667-57-0",
      "NSC722923",
      "AC1L8PAB",
      "CBDivE_016053",
      "4-[(3-Hydroxyphenyl)Methylideneamino]benzenesulfonamide",
      "CTK1H9643",
      "CTK7J9950",
      "DTXSID20328076",
      "BDBM50371761",
      "AKOS030585458",
      "MCULE-4187647437",
      "NSC-722923",
      "SR-01000199564",
      "SR-01000199564-1",
      "4-[(3-Hydroxy-Benzylidene)-Amino]-Benzenesulfonamide",
      "Benzenesulfonamide, 4-[[(3-Hydroxyphenyl)Methylene]amino]-",
    ],
  },
  {
    primary_id: "D0J1AY",
    names: ["Pyridoxyl-Alanine-5-Phosphate"],
  },
  {
    primary_id: "D0J1BA",
    names: ["MRS1191"],
  },
  {
    primary_id: "D0J1CC",
    names: ["PMID16460935C28"],
  },
  {
    primary_id: "D0J1CF",
    names: ["Her2- And PSA-Targeted TGX-D1"],
  },
  {
    primary_id: "D0J1CL",
    names: ["EcoNail", "Econazole/SEPA"],
  },
  {
    primary_id: "D0J1DE",
    names: ["Heterologous Liver-Derived Stem Cells"],
  },
  {
    primary_id: "D0J1DV",
    names: ["ISIS 104256"],
  },
  {
    primary_id: "D0J1DW",
    names: ["Trisnorsqualene Cyclopropylamine"],
  },
  {
    primary_id: "D0J1FB",
    names: ["GSK586529"],
  },
  {
    primary_id: "D0J1FL",
    names: ["AZD-2098"],
  },
  {
    primary_id: "D0J1FW",
    names: ["ISIS 3067"],
  },
  {
    primary_id: "D0J1FZ",
    names: ["Plague Injectable Vaccine"],
  },
  {
    primary_id: "D0J1GA",
    names: ["GSK-1399686", "1399686"],
  },
  {
    primary_id: "D0J1GM",
    names: ["3beta-Hydroxyurs-12-En-27-Oic Acid", "CHEMBL380467"],
  },
  {
    primary_id: "D0J1GR",
    names: [
      "CP-868596",
      "Crenolanib",
      "670220-88-9",
      "Crenolanib (CP-868596)",
      "ARO-002",
      "UNII-LQF7I567TQ",
      "LQF7I567TQ",
      "CP-868596 (Crenolanib)",
      "CP-868,596",
      "[1-[2-[5-(3-Methyloxetan-3-Ylmethoxy)Benzimidazol-1-Yl]quinolin-8-Yl]piperidin-4-Yl]amine",
      "1-(2-(5-((3-Methyloxetan-3-Yl)Methoxy)-1H-Benzo-[d]imidazol-1-Yl)Quinolin-8-Yl)Piperidin-4-Amine",
      "CP868569",
      "1-[2-[5-[(3-Methyl-3-Oxetanyl)Methoxy]-1-Benzimidazolyl]-8-Quinolyl]-4-Piperidinamine",
      "J-502712",
      "1-[2-[5-[(3-Methyloxetan-3-Yl)Methoxy]benzimidazol-1-Yl]quinolin-8-Yl]piperidin-4-Amine",
    ],
  },
  {
    primary_id: "D0J1HB",
    names: [
      "PF-06840003",
      "3-(5-Fluoro-1H-Indol-3-Yl)Pyrrolidine-2,5-Dione",
      "198474-05-4",
      "PF06840003",
      "2,5-Pyrrolidinedione, 3-(5-Fluoro-1H-Indol-3-Yl)-",
      "3-(5-Fluoro-Indol-3-Yl)-Pyrrolidine-2,5-Dione",
      "SCHEMBL5463464",
      "GTPL9565",
      "CTK0A0183",
      "DTXSID00630149",
      "MXKLDYKORJEOPR-UHFFFAOYSA-N",
      "KS-000008MY",
      "EX-A1186",
      "EOS200271",
      "AKOS027252536",
      "CS-6186",
      "AS-51973",
      "HY-101111",
      "3-(5-Fluoro-1H-Indol-3-Yl)-Pyrrolidine-2,5-Dione",
    ],
  },
  {
    primary_id: "D0J1HG",
    names: ["HG-1338"],
  },
  {
    primary_id: "D0J1HP",
    names: [
      "(R)-2-(Benzylamino-Methyl)-Chroman-7-Ol",
      "CHEMBL64553",
      "CHEMBL134807",
      "BDBM50061669",
      "ZINC13527706",
      "(2R)-2-(Benzylaminomethyl)Chroman-7-Ol",
      "(R)-2-[(Benzylamino)Methyl]chroman-7-Ol",
    ],
  },
  {
    primary_id: "D0J1HQ",
    names: ["PMID27019002-Compound-43c"],
  },
  {
    primary_id: "D0J1IE",
    names: [
      "3-(5-((Methylthio)Methyl)Furan-2-Yl)Pyridine",
      "859239-20-6",
      "Pyridine, 3-[5-[(Methylthio)Methyl]-2-Furanyl]-",
      "SCHEMBL3611062",
      "CHEMBL214990",
      "CTK2I3857",
      "BDBM12361",
      "DTXSID60475412",
      "QSLAHFFHHYZNQL-UHFFFAOYSA-N",
      "Nicotine 3-Heteroaromatic Analogue 15",
    ],
  },
  {
    primary_id: "D0J1IT",
    names: ["Norovirus Vaccine"],
  },
  {
    primary_id: "D0J1IX",
    names: [
      "1-(10H-Phenothiazin-10-Yl)-2-Phenylethanone",
      "CHEMBL394753",
      "AC1LODHB",
      "Oprea1_136899",
      "SCHEMBL7966578",
      "MolPort-019-780-057",
      "ZINC1022328",
      "BDBM50219227",
      "1-Phenothiazin-10-Yl-2-Phenylethanone",
      "1-(10H-Phenothiazine-10-Yl)-2-Phenylethanone",
      "1-(10H-Phenothiazin-10-Yl)-2-Phenylethan-1-One",
    ],
  },
  {
    primary_id: "D0J1JB",
    names: ["PMID15925511C13"],
  },
  {
    primary_id: "D0J1JE",
    names: ["SiRNA Therapy, Huntington's Disease"],
  },
  {
    primary_id: "D0J1KG",
    names: ["PD-4048"],
  },
  {
    primary_id: "D0J1KH",
    names: ["NSC-95397"],
  },
  {
    primary_id: "D0J1KS",
    names: ["BMS-945429"],
  },
  {
    primary_id: "D0J1KT",
    names: ["Azetidine Urea Derivative 2"],
  },
  {
    primary_id: "D0J1LO",
    names: ["S-Isopropyl-Isothiourea"],
  },
  {
    primary_id: "D0J1LX",
    names: ["Bornyl (3,4,5-Trihydroxy)-Cinnamate", "CHEMBL408621"],
  },
  {
    primary_id: "D0J1MI",
    names: [
      "Mebendazole",
      "Bantenol",
      "Banworm",
      "Besantin",
      "Lomper",
      "MBDZ",
      "Madicure",
      "Mebendan",
      "Mebendazol",
      "Mebendazolum",
      "Mebenoazole",
      "Mebenvet",
      "Mebex",
      "Mebutar",
      "Noverme",
      "Ovitelmin",
      "Pantelmin",
      "Sqworm",
      "Sufil",
      "Surfont",
      "Telmin",
      "Vermicidin",
      "Vermicol",
      "Vermidil",
      "Vermin",
      "Vermirax",
      "Vermox",
      "Verpanyl",
      "Versid",
      "Wormkuur",
      "Abello Farmacia Brand Of Mebendazole",
      "Anti Worm",
      "Ardeypharm Brand Of Mebendazole",
      "Boots Brand Of Mebendazole",
      "Boots Threadworm Treatment",
      "Diba Brand Of Mebendazole",
      "Elfar Brand Of Mebendazole",
      "Equivurm Plus",
      "Esteve Brand Of Mebendazole",
      "Healthypharm Brand Of Mebendazole",
      "Janssen Brand Of Mebendazole",
      "Leidapharm Brand Of Mebendazole",
      "Norgine Brand Of Mebendazole",
      "Pfizer Brand Of Mebendazole",
      "Pripsen Mebendazole",
      "SSL Brand Of Mebendazole",
      "Streger Brand Of Mebendazole",
      "Taxandria Brand Of Mebendazole",
      "Tedec Meiji Brand Of Mebendazole",
      "R 17635",
      "R17635",
      "Anti-Worm",
      "Antiox (TN)",
      "Degort's Brand Of Mebendazole",
      "Mebendazol [INN-Spanish]",
      "Mebendazole(USAN)",
      "Mebendazolum [INN-Latin]",
      "Ovex (TN)",
      "Pripsen (TN)",
      "R 17,635",
      "R-17635",
      "Vermox (TN)",
      "MEBENDAZOLE, 99%",
      "Mebendazole (JAN/USP)",
      "Mebendazole (JAN/USP/INN)",
      "Mebendazole [USAN:INN:BAN:JAN]",
      "Methyl5-Benzoyl Benzimidazole-2-Carbamate",
      "Methyl 5-Benzoyl-2-Benzimidazolecarbamate",
      "Methyl 5-Benzoyl-2-Benzimidazolylcarbamate",
      "N-2 (5-Benzoyl-Benzimidazole) Carbamate De Methyle",
      "N-2 (5-Benzoyl-Benzimidazole) Carbamate De Methyle [French]",
      "Methyl N-(5-Benzoyl-2-Benzimidazolyl)Carbamate",
      "Methyl (5-Benzoyl-1H-Benzimidazol-2-Yl)Carbamate",
      "Methyl [5-(Phenylcarbonyl)-1H-Benzimidazol-2-Yl]carbamate",
      "N-(Benzoyl-5, Benzimidazolyl)-2, Carbamate De Methyle",
      "N-(Benzoyl-5, Benzimidazolyl)-2, Carbamate De Methyle [French]",
      "Methyl N-(5-Benzoyl-1H-Benzimidazol-2-Yl)Carbamate",
      "Methyl N-(6-Benzoyl-1H-Benzimidazol-2-Yl)Carbamate",
      "Carbamic Acid, N-(5-Benzoylbenzimidazol-2-Yl)-, Methyl Ester",
      "Carbamic Acid, (5-Benzoyl-1H-Benzimidazol-2-Yl)-, Methyl Ester",
      "Methyl N-(6-Benzoyl-1H-1,3-Benzodiazol-2-Yl)Carbamate",
      "Carbamic Acid, (5-Benzoyl-1H-Benzimidazol-2-Yl)-, Methyl Ester (9CI)",
      "(5-Benzoyl-1H-Benzimidazol-2-Yl)-Carbamic Acid Methyl Ester",
      "(5-Benzoyl-1H-Benzimidazol-2-Yl)Carbamic Acid Methyl Ester",
      "(5-Benzoyl-1H-Benzoimidazol-2-Yl)-Carbamic Acid Methyl Ester",
      "2-Benzimidazolecarbamic Acid, 5-Benzoyl-, Methyl Ester",
      "2-Benzimidazolecarbamic Acid, 5-Benzoyl-, Methyl Ester (8CI)",
      "5-Benzoyl-2-Benzimidazolecarbamic Acid Methyl Ester",
      "5-Benzoyl-2-Benzimidazolecarbamic Acid, Methyl Ester",
    ],
  },
  {
    primary_id: "D0J1ML",
    names: [
      "Mestranol",
      "Devocin",
      "Menophase",
      "Mestranolo",
      "Mestranolum",
      "Norquen",
      "Ovastol",
      "Component Of Norinyl",
      "Component Of Norquen",
      "Component Of Ovulen",
      "Ethynylestradiol Methyl Ether",
      "Mestranol [Steroidal Oestrogens]",
      "Mestranolo [DCIT]",
      "EE3ME",
      "Ethinyl Estradiol 3 Methyl Ether",
      "SC 4725",
      "Component Of Ortho-Novum",
      "Delta-MVE",
      "EE(Sub3)ME",
      "EthinylEstradiol 3-Methyl Ether",
      "Ethinylestradiol 3-Methyl Ether",
      "Ethinyloestradiol 3-Methyl Ether",
      "Ethynylestradiol 3-Methyl Ether",
      "Ethynyloestradiol 3-Methyl Ether",
      "Inostral (Steroid)",
      "Mestranolum [INN-Latin]",
      "Mestranol (JP15/USP/INN)",
      "Mestranol [USAN:INN:BAN:JAN]",
      "Alpha.-19-Norpregna-1,3,5(10)-Trien-20-Yn-17-Ol, 3-Meth",
      "(17beta)-17-Ethynyl-3-(Methyloxy)Estra-1,3,5(10)-Trien-17-Ol",
      "17-Ethynyl-3-Methoxy-1,3,5(10)-Oestratien-17-Beta-Ol",
      "17-Ethynyl-3-Methoxyestra-1,3,5(10)-Trien-17-Ol",
      "17-Ethynylestradiol 3-Methyl Ether",
      "17-Ethynyloestradiol 3-Methyl Ether",
      "17-Alpha-Ethinyl Estradiol 3-Methyl Ether",
      "17-Alpha-Ethinyl Oestradiol 3-Methyl Ether",
      "17-Alpha-Ethynyl-3-Methoxy-1,3,5(10)-Estratrien-17-Beta-Ol",
      "17-Alpha-Ethynyloestradiol Methyl Ether",
      "17-Ethynyl-3-Methoxyestra-1(10),2,4-Trien-17beta-Ol",
      "17-Ethynyl-3-Methoxyoestra-1(10),2,4-Trien-17beta-Ol",
      "17alpha-Ethinyl Estradiol 3-Methyl Ether",
      "17alpha-Ethinyl Oestradiol 3-Methyl Ether",
      "17alpha-Ethinylestradiol 3-Methyl Ether",
      "17alpha-Ethynylestradiol 3-Methyl Ether",
      "17alpha-Ethynylestradiol Methyl Ether",
      "17alpha-Ethynyloestradiol 3-Methyl Ether",
      "17beta-Estradiol, 17-Ethynyl-, 3-(Methyl Ether)",
      "3-Methoxy-17-Alpha-19-Norpregna-1,3,5(10)-Trien-20-Yn-17-Ol",
      "3-Methoxy-17-Alpha-Ethinylestradiol",
      "3-Methoxy-17-Alpha-Ethinyloestradiol",
      "3-Methoxy-17-Alpha-Ethynyl-1,3,5(10)-Estratrien-17-Beta-Ol",
      "3-Methoxy-17-Alpha-Ethynyl-1,3,5(10)-Oestratrien-17-Beta-Ol",
      "3-Methoxy-17-Alpha-Ethynylestradiol",
      "3-Methoxy-17-Alpha-Ethynyloestradiol",
      "3-Methoxy-17-Ethynyloestradiol-17-Beta",
      "3-Methoxy-17alpha-Ethinylestradiol",
      "3-Methoxy-17alpha-Ethinyloestradiol",
      "3-Methoxy-17alpha-Ethynyl-1,3,5(10)-Estratrien-17beta-Ol",
      "3-Methoxy-17alpha-Ethynyl-1,3,5(10)-Oestratrien-17beta-Ol",
      "3-Methoxy-17alpha-Ethynylestradiol",
      "3-Methoxy-17alpha-Ethynyloestradiol",
      "3-Methoxy-19-Nor-17-Alpha-Pregna-1,3,5(10)-Trien-20-Yn-17-Ol",
      "3-Methoxy-19-Nor-17alpha-Pregna-1,3,5(10)-Trien-20-Yn-17-Ol",
      "3-Methoxy-19-Norpregna-1,3,5(10)-Trien-20-Yn-17beta-Ol",
      "3-Methoxyethynylestradiol",
      "3-Methoxyethynyloestradiol",
      "3-Methylethynylestradiol",
      "3-Methylethynyloestradiol",
      "3-O-Methylethynylestradiol",
    ],
  },
  {
    primary_id: "D0J1MO",
    names: ["TRX-518"],
  },
  {
    primary_id: "D0J1NQ",
    names: [
      "Pinaverium Bromide",
      "Dicetel",
      "Bromure De Pinaverium",
      "Bromuro De Pinaverio",
      "Pinaverii Bromidum",
      "Pinaverium Bromide [INN]",
      "LAT 1717",
      "Bromure De Pinaverium [INN-French]",
      "Bromuro De Pinaverio [INN-Spanish]",
      "Dicetel (TN)",
      "Pinaverii Bromidum [INN-Latin]",
      "Pinaverium Bromide (INN)",
      "Morpholinium, 4-(2-Bromo-4,5-Dimethoxy)Benzyl-4-(2-(2-(6,6-Dimethyl-2-Norpinyl)Ethoxy)Ethyl)-, Bromide",
      "4-(6-Bromoveratryl)-4-(2-(2-(6,6-Dimethyl-2-Norpinyl)Ethoxy)Ethyl)Morpholinium Bromide",
      "4-[(2-Bromo-4,5-Dimethoxyphenyl)Methyl]-4-[2-[2-(6,6-Dimethyl-4-Bicyclo[3.1.1]heptanyl)Ethoxy]ethyl]morpholin-4-Ium Bromide",
    ],
  },
  {
    primary_id: "D0J1OG",
    names: ["Prolanta"],
  },
  {
    primary_id: "D0J1OK",
    names: ["N-Oxalylglycine Derivative 1"],
  },
  {
    primary_id: "D0J1PG",
    names: [
      "2-AMINO-3-(4-HYDROXY-1,2,5-OXADIAZOL-3-YL)PROPIONIC ACID (STRUCTURAL MIX)",
      "CHEMBL1094129",
      "BDBM50318264",
    ],
  },
  {
    primary_id: "D0J1PJ",
    names: ["U50,488H"],
  },
  {
    primary_id: "D0J1PL",
    names: ["5-Hexyl-2-(4-Nitrophenoxy)Phenol", "CHEMBL264417", "JA1"],
  },
  {
    primary_id: "D0J1PW",
    names: ["Orthokine"],
  },
  {
    primary_id: "D0J1PZ",
    names: [
      "N-(5-Mercapto-[1,3,4]thiadiazol-2-Yl)-Acetamide",
      "32873-56-6",
      "2-Acetylamino-5-Mercapto-1,3,4-Thiadiazole",
      "N-(5-Sulfanyl-1,3,4-Thiadiazol-2-Yl)Acetamide",
      "2-Acetamido-5-Mercapto-1,3,4-Thiadiazole",
      "UNII-6B8475348K",
      "MLS002703417",
      "CHEMBL382111",
      "DWSMAMSVZRCQMP-UHFFFAOYSA-N",
      "N-(5-Mercapto-1,3,4-Thiadiazol-2-Yl)Acetamide",
      "N-(5-Sulfanylidene-4,5-Dihydro-1,3,4-Thiadiazol-2-Yl)Acetamide",
      "6B8475348K",
      "{N}-(5-Mercapto-1,3,4-Thiadiazol-2-Yl)Acetamide",
      "N-(2-Sulfanylidene-3H-1,3,4-Thiadiazol-5-Yl)Acetamide",
      "Acetamide,N-(4,5-Dihydro-5-Thioxo-1,3,4-Thiadiazol-2-Yl)-",
      "C4H5N3OS2",
      "NSC97893",
    ],
  },
  {
    primary_id: "D0J1QQ",
    names: ["Sanguiin H-6"],
  },
  {
    primary_id: "D0J1QT",
    names: [
      "BMS-344577",
      "UNII-U259PQB19A",
      "U259PQB19A",
      "CHEMBL570867",
      "288079-93-6",
      "BDBM35316",
      "BDBM50328724",
      "2,5-Pyridinedicarboxamide, N5-((((3S)-Hexahydro-2-Oxo-1-(2-Oxo-2-(1-Pyrrolidinyl)Ethyl)-1H-Azepin-3-Yl)Imino)((2-Methyl-5-Benzofuranyl)Amino)Methyl)-N2,N2-Dimethyl-",
      "2,5-Pyridinedicarboxamide, N5-((((3S)-Hexahydro-2-Oxo-1-(2-Oxo-2-(1-Pyrrolidinyl)Ethyl)-1H-Azepin-3-Yl)Amino)((2-Methyl-5-Benzofuranyl)Amino)Methylene)-N2,N2-Dimethyl-",
    ],
  },
  {
    primary_id: "D0J1RB",
    names: ["Isoflavone Derivative 6"],
  },
  {
    primary_id: "D0J1RH",
    names: ["CY-1748"],
  },
  {
    primary_id: "D0J1RS",
    names: ["H5N1 Pandemic Influenza Mab"],
  },
  {
    primary_id: "D0J1SA",
    names: ["13-Acetylphorbol"],
  },
  {
    primary_id: "D0J1SU",
    names: ["ISIS 17020"],
  },
  {
    primary_id: "D0J1SX",
    names: ["4alpha-PDH", "4alpha-Phorbol 12,13-Dihexanoate"],
  },
  {
    primary_id: "D0J1SZ",
    names: ["TGF-Beta Elevating Agent"],
  },
  {
    primary_id: "D0J1TN",
    names: ["ETEC Vaccine"],
  },
  {
    primary_id: "D0J1TS",
    names: ["AZD2207", "AC1O4QIO", "CHEMBL3545289"],
  },
  {
    primary_id: "D0J1TU",
    names: ["Technetium Tc-99m Sestamibi Kit"],
  },
  {
    primary_id: "D0J1UA",
    names: ["Elosulfase Alfa"],
  },
  {
    primary_id: "D0J1US",
    names: ["Gold-Complexed Thiosaccharide Derivative 3"],
  },
  {
    primary_id: "D0J1VB",
    names: [
      "4-(Piperazin-1-Yl)Thieno[3,2-C]pyridine",
      "106261-27-2",
      "1-{THIENO[3,2-C]PYRIDIN-4-YL}PIPERAZINE",
      "Thieno[3,2-C]pyridine,4-(1-Piperazinyl)-",
      "Thieno[3,2-C]pyridine, 4-(1-Piperazinyl)-",
      "4-Piperazin-1-Yl-Thieno[3,2-C]pyridine",
      "CHEMBL608135",
      "4-Piperazin-1-Ylthieno[3,2-C]pyridine",
      "CHEMBL552833",
      "4-(1-Piperazinyl)Thieno[3,2-C]pyridine",
      "4-Piperazine-1-Yl-Thieno[3,2-C]pyridine",
      "4-Piperazine-1-Yl-Thieno[2,3-C]pyridine Hydrochloride",
      "ACMC-1BO03",
      "SCHEMBL616087",
      "CTK4A4454",
      "DTXSID10450113",
      "MolPort-002-500-678",
      "RVGRTFBJOXMFAX-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0J1VS",
    names: [
      "4-Furan-2-Yl-6-Nitro-2-Piperazin-1-Yl-Quinoline",
      "CHEMBL166948",
      "SCHEMBL6364878",
      "APJKUNDSNZQZPA-UHFFFAOYSA-N",
      "BDBM50110576",
      "2-Piperazino-4-(2-Furyl)-6-Nitroquinoline",
    ],
  },
  {
    primary_id: "D0J1VY",
    names: [
      "Entacapone",
      "Comtan",
      "Comtess",
      "Entacapona",
      "Entacaponum",
      "Novartis Brand Of Entacapone",
      "Orion Brand Of Entacapone",
      "KB475572",
      "OR 611",
      "COM-998",
      "Comtan (TN)",
      "Entacapona [INN-Spanish]",
      "Entacapone [USAN:INN]",
      "Entacaponum [INN-Latin]",
      "OR-611",
      "Stalevo (TN)",
      "Entacapone (JAN/USAN/INN)",
      "N,N-Diethyl-2-Cyano-3-(3,4-Dihydroxy-5-Nitrophenyl) Acrylamide",
      "(2E)-2-Cyano-3-(3,4-Dihydroxy-5-Nitrophenyl)-N,N-Diethylprop-2-Enamide",
      "(E)-2-Cyano-3-(3,4-Dihydroxy-5-Nitrophenyl)-N,N-Diethyl-2-Propenamide",
      "(E)-2-Cyano-3-(3,4-Dihydroxy-5-Nitro-Phenyl)-N,N-Diethyl-Prop-2-Enamide",
      "(E)-2-Cyano-3-(3,4-Dihydroxy-5-Nitrophenyl)-N,N-Diethylprop-2-Enamide",
      "(E)-Alpha-Cyano-N,N-Diethyl-3,4-Dihydroxy-5-Nitrocinnamamide",
      "2-Cyano-N,N-Diethyl-3-(3,4-Dihydroxy-5-Nitrophenyl)Propenamide",
    ],
  },
  {
    primary_id: "D0J1WI",
    names: ["Sphingosine-1-Phosphate Receptor-1 Agonist"],
  },
  {
    primary_id: "D0J1WJ",
    names: [
      "ALS-769",
      "ALS-1026",
      "ALS-851",
      "Betulinic Acid Derivative Caspase Inducers (Cancer)",
      "Betulinic Acid Derivative Caspase Inducers (Cancer), Advanced Life Sciences",
      "Lupane Triterpenoids (Cancer), Advanced Life Sciences",
    ],
  },
  {
    primary_id: "D0J1WO",
    names: ["ISIS 113715"],
  },
  {
    primary_id: "D0J1XP",
    names: [
      "1-(2-Hydroxy-5-Nitro-Phenyl)-3-Phenyl-Urea",
      "CHEMBL24912",
      "SCHEMBL5595042",
      "MHNNUSRLYWTBJF-UHFFFAOYSA-N",
      "ZINC13559606",
      "BDBM50140790",
      "AKOS016618380",
      "N-[5-Nitro-2-Hydroxyphenyl]-N'-Phenylurea",
      "N-[5-Nitro-2-Hydroxyphenyl]-N'-Phenyl Urea",
    ],
  },
  {
    primary_id: "D0J1XZ",
    names: ["BPH-675", "BPH675", "BPH 675"],
  },
  {
    primary_id: "D0J1ZH",
    names: ["PH94B NS", "Aloradine IN", "Aloradine NS"],
  },
  {
    primary_id: "D0J2AE",
    names: [
      "3-Amino-Benzenesulfonamide",
      "3-AMINOBENZENESULFONAMIDE",
      "98-18-0",
      "Metanilamide",
      "M-Aminobenzenesulfonamide",
      "Benzenesulfonamide, 3-Amino-",
      "M-Sulfamoylaniline",
      "3-Aminobenzene-1-Sulfonamide",
      "Benzenesulfonamide, M-Amino-",
      "M-Aminobenzenesulphonamide",
      "Metaniilamide",
      "3-Aminosulfonylaniline",
      "NSC 7542",
      "EINECS 202-646-0",
      "BRN 0511851",
      "CHEMBL6852",
      "JPVKCHIPRSQDKL-UHFFFAOYSA-N",
      "MFCD00035781",
      "3-Sulfanilamide",
      "M-Sulfamylaniline",
      "NSC7542",
      "3-Sulfamoyl-Aniline",
      "3-Sulfamoyl-Phenylamine",
      "3-Aminobenzensulfonamide",
      "AC1Q6UKN",
      "AC1L1OMG",
    ],
  },
  {
    primary_id: "D0J2BH",
    names: ["TA-GW"],
  },
  {
    primary_id: "D0J2CP",
    names: ["PF 5208766"],
  },
  {
    primary_id: "D0J2CR",
    names: ["CGP 20712A"],
  },
  {
    primary_id: "D0J2DQ",
    names: ["Stroke Therapeutic"],
  },
  {
    primary_id: "D0J2EE",
    names: [
      "DIDODECANOYLPHLOROGLUCINOL",
      "CHEMBL470757",
      "SCHEMBL9188500",
      "MolPort-044-550-959",
      "BDBM50256011",
      "ZINC44417494",
      "1,1''-(2,4,6-Trihydroxy-1,3-Phenylene)Didodecan-1-One",
      "1,1'-(2,4,6-Trihydroxy-1,3-Phenylene)Bis-1-Dodecanone",
      "1,1'-(2,4,6-Trihydroxy-1,3-Phenylene)Bis(Dodecan-1-One)",
    ],
  },
  {
    primary_id: "D0J2ES",
    names: ["BIO-300"],
  },
  {
    primary_id: "D0J2FJ",
    names: ["5-Iodo Orotate"],
  },
  {
    primary_id: "D0J2FU",
    names: ["BI 891065"],
  },
  {
    primary_id: "D0J2FY",
    names: [
      "2'-Monophosphoadenosine 5'-Diphosphoribose",
      "53-59-8",
      "Cozymase II",
      "Codehydrogenase II",
      "TPN (Nucleotide)",
      "Codehydrase II",
      "Nadide Phosphate",
      "NAD Phosphate",
      "EINECS 200-178-1",
      "BRN 3885115",
      "Nicotinamide Adenine Dinucleotide Phosphate",
      "CHEBI:44409",
      "Beta-NADP",
      "Adenosine 5'-(Trihydrogen Diphosphate), 2'-(Dihydrogen Phosphate), P'-5'-Ester With 3-(Aminocarbonyl)-1-Beta-D-Ribofuranosylpyridinium, Inner Salt",
      "Beta-TPN",
      "TPN-Ox",
      "Nadp Nicotinamide-Adenine-Dinucleotide Phosphate",
      "Adenosine 5'-(Trihydrogen Diphosphate), 2'-(Dihydrogen P",
    ],
  },
  {
    primary_id: "D0J2GG",
    names: ["SR-3850"],
  },
  {
    primary_id: "D0J2HH",
    names: ["Cyclohexyl Carbamate Derivative 1"],
  },
  {
    primary_id: "D0J2HL",
    names: [
      "Cardiovascular Disease Medicine",
      "Cardiovascular Medicine",
      "Cardiovascular Therapy",
      "Drug-Coated Angiosculpt",
    ],
  },
  {
    primary_id: "D0J2IB",
    names: ["ANG-3070"],
  },
  {
    primary_id: "D0J2ID",
    names: [
      "6-Chloro-N-(Pyridin-3-Yl)Indoline-1-Carboxamide",
      "CHEMBL38911",
      "SCHEMBL8526818",
      "LOMPSBWQXLVHJX-UHFFFAOYSA-N",
      "BDBM50321876",
      "6-Chloro-1-(3-Pyridylcarbamoyl)Indoline",
      "N-(3-Pyridinyl)-6-Chloro-2,3-Dihydro-1H-Indole-1-Carboxamide",
      "6-Chloro-2,3-Dihydro-Indole-1-Carboxylic Acid Pyridin-3-Ylamide",
    ],
  },
  {
    primary_id: "D0J2IH",
    names: ["Avosentan"],
  },
  {
    primary_id: "D0J2IK",
    names: ["3,6-Bis(Dmt-Tic-NH-Ethyl)-2(1H)-Pyrazinone", "CHEMBL198800"],
  },
  {
    primary_id: "D0J2IN",
    names: ["CAR-T Cells Targeting GPC3"],
  },
  {
    primary_id: "D0J2JM",
    names: ["UNIFLORINE B"],
  },
  {
    primary_id: "D0J2KV",
    names: ["Propafenone"],
  },
  {
    primary_id: "D0J2LG",
    names: ["DCVax-001"],
  },
  {
    primary_id: "D0J2MJ",
    names: ["Selegiline Hydrochloride"],
  },
  {
    primary_id: "D0J2NB",
    names: ["KRH-594"],
  },
  {
    primary_id: "D0J2NG",
    names: ["8-N-Heptylnaringenin"],
  },
  {
    primary_id: "D0J2NI",
    names: ["TDI-0010"],
  },
  {
    primary_id: "D0J2NK",
    names: [
      "Oxytetracycline",
      "Adamycin",
      "Biostat",
      "Dabicycline",
      "Dalimycin",
      "Fanterrin",
      "Geomycin",
      "Geotilin",
      "Hydroxytetracycline",
      "Imperacin",
      "Lenocycline",
      "Macocyn",
      "OTC",
      "Oksisyklin",
      "Ossitetraciclina",
      "Oxacycline",
      "Oxitetraciclina",
      "Oxitetracyclin",
      "Oxitetracycline",
      "Oxitetracyclinum",
      "Oxymycin",
      "Oxymykoin",
      "Oxypam",
      "Oxyterracin",
      "Oxyterracine",
      "Oxyterracyne",
      "Oxytetracid",
      "Oxytetracyclin",
      "Oxytetracyclinum",
      "Proteroxyna",
      "Riomitsin",
      "Ryomycin",
      "Solkaciclina",
      "Tarocyn",
      "Tarosin",
      "Teravit",
      "Terrafungine",
      "Terramitsin",
      "Terramycin",
      "Tetran",
      "Unimycin",
      "Ursocyclin",
      "Ursocycline",
      "Vendarcin",
      "Biostat PA",
      "Ossitetraciclina [DCIT]",
      "Oxytetracycline HCl",
      "Oxytetracycline [INN]",
      "Oxytetracycline Amphoteric",
      "Oxytetracycline Anhydrous",
      "Oxytetracycline Calcium",
      "Terramycin Im",
      "Antibiotic TM 25",
      "LA 200",
      "Liquamycin LA 200",
      "Mycoshield TMQTHC 20",
      "Pennox 200",
      "TM 5",
      "Terramycin Q50",
      "Geomycin (Streptomyces Vimosus)",
      "OTC (Antibiotic)",
      "Oxitetraciclina [INN-Spanish]",
      "Oxytetracycline (Anhydrous)",
      "Oxytetracycline (Internal Use)",
      "Oxytetracyclinum [INN-Latin]",
      "Terramycin, Liquamycin, Oxytetracycline",
      "(2E,4S,4aR,5S,5aR,6S,12aS)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,6,10,11,12a-Pentahydroxy-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(2E,4S,6S,12aS)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,6,10,11,12a-Pentahydroxy-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(2Z)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,6,10,11,12a-Pentahydroxy-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(2Z,4S,4aR,5S,5aR,6S,12aS)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,6,10,11,12a-Pentahydroxy-6-Methyl-4,4a,5,5a-Tetrahydrotetracene-1,3,12-Trione",
      "(4S,4aR,5S,5aR,6S,12aS)-4-(Dimethylamino)-3,5,6,10,12,12a-Hexahydroxy-6-Methyl-1,11-Dioxo-1,4,4a,5,5a,6,11,12a-Octahydrotetracene-2-Carboxamide",
      "5-Hydroxytetracycline",
    ],
  },
  {
    primary_id: "D0J2NS",
    names: [
      "Anti-D",
      "(2-Amino-D-Glucose Hydrochloride)",
      "Glu-Cosamine Hydrochloride",
      "3h-Glucosamine Hydrochloride",
      "SCHEMBL21212",
      "SPECTRUM1500316",
      "MolPort-003-666-196",
      "Pharmakon1600-01500316",
      "HMS1920B13",
      "NSC757051",
      "CCG-39600",
    ],
  },
  {
    primary_id: "D0J2NX",
    names: ["LE-DT"],
  },
  {
    primary_id: "D0J2OC",
    names: ["MBX-700"],
  },
  {
    primary_id: "D0J2PO",
    names: ["ARTOBILOXANTHONE", "Artobiloxanthone", "CHEMBL1099162"],
  },
  {
    primary_id: "D0J2PW",
    names: [
      "2-(2-Formylphenoxy)Acetic Acid",
      "2-Formylphenoxyacetic Acid",
      "6280-80-4",
      "(2-Formylphenoxy)Acetic Acid",
      "O-Formylphenoxyacetic Acid",
      "Acetic Acid, (2-Formylphenoxy)-",
      "2-Carboxymethoxybenzaldehyde",
      "Ortho-Formylphenoxyacetic Acid",
      "Acetic Acid, (O-Formylphenoxy)-",
      "2-Formylphenoxyaceticacid",
      "Acetic Acid, 2-(2-Formylphenoxy)-",
      "CHEMBL384289",
      "ANWMNLAAFDCKMT-UHFFFAOYSA-N",
      "NSC 6140",
      "EINECS 228-480-9",
      "NSC 133590",
      "O-Formyphenoxyacetic Acid",
      "O-Formyl Phenoxyacetic Acid",
      "AC1L2G6R",
      "2-Formyl Phenoxyacetic Acid",
      "2-Monoformylphenoxyacetic Acid",
    ],
  },
  {
    primary_id: "D0J2RI",
    names: ["4-Ethyl-3-Methyl-Pyrrolidin-(2Z)-Ylideneamine", "CHEMBL184798", "BDBM50150930"],
  },
  {
    primary_id: "D0J2RV",
    names: ["XL-888"],
  },
  {
    primary_id: "D0J2RW",
    names: ["FGGFTGCRKSARKC"],
  },
  {
    primary_id: "D0J2SM",
    names: [
      "2-(2-Iodo-Phenyl)-Benzo[d][1,3]oxazin-4-One",
      "CHEMBL10378",
      "NSC165772",
      "AC1L6PF7",
      "SCHEMBL913450",
      "BDBM50063703",
      "NSC-165772",
      "2-(2-Iodophenyl)-3,1-Benzoxazin-4-One",
    ],
  },
  {
    primary_id: "D0J2SR",
    names: ["Quinazolinedione Derivative 1"],
  },
  {
    primary_id: "D0J2TN",
    names: [
      "Diphenoxylate",
      "Difenossilato",
      "Difenoxilato",
      "Diphenoxalate",
      "Diphenoxylatum",
      "Difenossilato [DCIT]",
      "Difenoxilato [Spanish]",
      "Diphenoxylate HCl",
      "Diphenoxylate Monohydrochloride",
      "Diphenoxylate Hydrochloride",
      "Diphenoxylatum [Latin]",
      "NIH 7562",
      "R 1132",
      "Difenoxilato [INN-Spanish]",
      "Diphenoxylate (INN)",
      "Diphenoxylate [INN:BAN]",
      "Diphenoxylate Hydrochloride (USP)",
      "Diphenoxylate Hydrochloride(Usp)",
      "Diphenoxylatum [INN-Latin]",
      "R 1132 (Antiperistaltic)",
      "R-1132",
      "Co-Phenotrope (TN)",
      "Ethyl 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenylisonipecotate",
      "Ethyl 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenylisonipecotate Monohydrochloride",
      "Ethyl 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-4-Piperidinecarboxylate",
      "Ethyl 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenylpiperidine-4-Carboxylate",
      "Ethyl 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenylpiperidine-4-Carboxylate Hydrochloride",
      "Isonipecotic Acid, 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-, Ethyl Ester",
      "Ethyl 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenylpiperidin-1-Ium-4-Carboxylate Chloride",
      "Isonipecotic Acid, 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-, Ethyl Ester, Hydrochloride",
      "1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-4-Piperidinecarboxylic Acid Ethyl Ester",
      "1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-Isonipecotic Acid Ethyl Ester",
      "1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenylisonipecotic Acid Ethyl Ester Hydrochloride",
      "1-(3-Cyano-3,3-Diphenylpropyl)-4-(Ethoxycarbonyl)-4-Phenylpiperidinium Chloride",
      "2,2-Diphenyl-4-(4-Carbethoxy-4-Phenylpiperidino)Butyronitrile",
      "4-Ethoxycarbonyl-Alpha,alpha,4-Triphenyl-1-Piperidinebutyronitrile, Hydrochloride",
      "4-Piperidinecarboxylic Acid, 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-, Ethyl Ester",
      "4-Piperidinecarboxylic Acid, 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-, Ethyl Ester (9CI)",
      "4-Piperidinecarboxylic Acid, 1-(3-Cyano-3,3-Diphenylpropyl)-4-Phenyl-, Ethyl Ester, Monohydrochloride",
      "4-Ethoxycarbonyl-Alpha,alpha,4-Triphenyl-1-Piperidinebutyronitrile Hydrochloride",
    ],
  },
  {
    primary_id: "D0J2UA",
    names: ["6-Bromoaplysinopsin"],
  },
  {
    primary_id: "D0J2UD",
    names: ["CGNLSTCMLGTYTQDFc[DKFHO]FPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D0J2UJ",
    names: ["Chloropicrin"],
  },
  {
    primary_id: "D0J2UW",
    names: [
      "CGS 21680",
      "2-(4-(2-Carboxyethyl)Phenethylamino)-5'-N-Ethylcarboxamidoadenosine",
      "Cgs 21680",
      "CGS-21680",
      "120225-54-9",
      "UNII-T5HB1E831H",
      "T5HB1E831H",
      "CHEMBL331372",
      "CGS21680",
      "4-(2-((6-Amino-9-(N-Ethyl-Beta-D-Ribofuranuronamidosyl)-9H-Purin-2-Yl)Amino)Ethyl)Benzenepropanoic Acid",
      "CHEBI:73283",
      "CGS 21680 Hydrochloride",
      "Benzenepropanoic Acid, 4-(2-((6-Amino-9-(N-Ethyl-Beta-D-Ribofuranuronamidosyl)-9H-Purin-2-Yl)Amino)Ethyl)-",
      "2-[p-(2-Carboxyethyl)Phenylethyl-Amino]-5'-N-Ethylcarboxamido Adenosine",
      "[3H]CGS 21680",
    ],
  },
  {
    primary_id: "D0J2VB",
    names: ["Iboctadekin + Rituximab"],
  },
  {
    primary_id: "D0J2VN",
    names: ["ISIS 100742"],
  },
  {
    primary_id: "D0J2VQ",
    names: ["EM-523"],
  },
  {
    primary_id: "D0J2VS",
    names: ["1-Hydroxyl-3,5-Bis(4-Hydroxylstyryl)Benzene Derivative 2"],
  },
  {
    primary_id: "D0J2WC",
    names: ["ONO-9780307"],
  },
  {
    primary_id: "D0J2WN",
    names: ["PCCG-4", "(2S,1'S,2'S,3?R)-2-(2'-Carboxy-3'-Phenylcyclopropyl)Glycine"],
  },
  {
    primary_id: "D0J2WW",
    names: [
      "4-[6-Methoxy-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 16b",
      "CHEMBL370281",
      "AC1O706H",
      "BDBM8627",
      "4-[(Z)-(6-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0J2XD",
    names: [
      "3-(5-Phenyl-Oxazol-2-Ylamino)-Benzonitrile",
      "3-[(5-Phenyl-1,3-Oxazol-2-Yl)Amino]benzonitrile",
      "GW627834A",
      "2-Anilino-5-Aryloxazole 10",
      "AC1NS7R5",
      "BDBM5849",
      "CHEMBL192413",
      "NCGC00241897-01",
      "AB01092081-01",
      "N-(3-Cyanophenyl)-5-Phenyl-1,3-Oxazol-2-Amine Hydrochloride",
    ],
  },
  {
    primary_id: "D0J2XP",
    names: ["BPI-713001"],
  },
  {
    primary_id: "D0J2YB",
    names: ["YIL781"],
  },
  {
    primary_id: "D0J2YR",
    names: ["PI-166"],
  },
  {
    primary_id: "D0J2ZI",
    names: ["ISIS 103747"],
  },
  {
    primary_id: "D0J2ZS",
    names: ["PMID24825313C7"],
  },
  {
    primary_id: "D0J3AN",
    names: ["HL-076"],
  },
  {
    primary_id: "D0J3AO",
    names: [
      "PD-160946",
      "CHEMBL47146",
      "BDBM50050652",
      "[(R)-2-(2-Fluoro-Phenyl)-1-Methyl-1-(7-Ureido-Heptylcarbamoyl)-Ethyl]-Carbamic Acid (S)-2-Methyl-1-Phenyl-Propyl Ester",
    ],
  },
  {
    primary_id: "D0J3AV",
    names: ["PEG-LPA-2"],
  },
  {
    primary_id: "D0J3BT",
    names: ["NIFEVIROC"],
  },
  {
    primary_id: "D0J3BU",
    names: ["Duteplase"],
  },
  {
    primary_id: "D0J3BV",
    names: ["Ac-I[CV(Bpa)QDWGAHRC]T-NH2"],
  },
  {
    primary_id: "D0J3BY",
    names: ["Pimagedine HCl"],
  },
  {
    primary_id: "D0J3CC",
    names: ["Latranal"],
  },
  {
    primary_id: "D0J3CG",
    names: ["Non-Beta2 Receptor Bronchodilators"],
  },
  {
    primary_id: "D0J3CJ",
    names: ["2-Fluorophenyl 4-(Octyloxy)Phenylcarbamate", "CHEMBL597029", "SCHEMBL5216564"],
  },
  {
    primary_id: "D0J3CT",
    names: ["FK-143"],
  },
  {
    primary_id: "D0J3CY",
    names: ["N-(3-Ethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093041", "BDBM50314134"],
  },
  {
    primary_id: "D0J3DB",
    names: ["2-Tosylanthracene-1,4-Diol", "CHEMBL451059"],
  },
  {
    primary_id: "D0J3DC",
    names: ["VLP Norovirus Bivalent Vaccine"],
  },
  {
    primary_id: "D0J3DN",
    names: ["Anti-CD19-CAR PBL"],
  },
  {
    primary_id: "D0J3DW",
    names: ["Piperidine-1-Carboxylic Acid Adamantan-2-Ylamide", "CHEMBL596815", "SCHEMBL4099349", "BDBM50120376"],
  },
  {
    primary_id: "D0J3EA",
    names: ["Theliatinib"],
  },
  {
    primary_id: "D0J3EJ",
    names: [
      "4-(4-(4-Nitrophenyl)Thiazol-2-Ylamino)Phenol",
      "4-{[4-(4-Nitrophenyl)-1,3-Thiazol-2-Yl]amino}phenol",
      "CHEMBL559270",
      "BDBM91658",
      "ZINC36627530",
      "STL255714",
      "AKOS002279368",
      "MCULE-8884798678",
      "N-Aryl-4-Aryl-1,3-Thiazole-2-Amine, 6",
    ],
  },
  {
    primary_id: "D0J3EN",
    names: ["AB122"],
  },
  {
    primary_id: "D0J3EU",
    names: ["(2-Chloro-Ethyl)-Trimethyl-Ammonium Chloride"],
  },
  {
    primary_id: "D0J3EZ",
    names: [
      "6-Nitro-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "6-Nitroquinoxaline-2,3-Diol",
      "1,4-Dihydro-6-Nitroquinoxaline-2,3-Dione",
      "6-Nitro-1,4-Dihydroquinoxaline-2,3-Dione",
      "6-Nitro-2,3-Dihydroxyquinoxaline",
      "6-Nitro-2,3-Quinoxalinediol",
      "2,3-Dihydroxy-6-Nitroquinoxaline",
      "6-Nitro-1,2,3,4-Tetrahydroquinoxaline-2,3-Dione",
      "RYMLSFWVYNAKAR-UHFFFAOYSA-N",
      "6-Nitroquinoxaline-2,3(1H,4H)-Dione",
      "AC1N1YFP",
      "AC1Q1Y5W",
      "Oprea1_565337",
      "CHEMBL23044",
      "ACMC-209g63",
      "6-Nitro Quinoxaline-2,3-Dion",
      "SCHEMBL1259529",
      "SCHEMBL14385403",
      "6-Nitro-2,3-Quinoxalinediol #",
    ],
  },
  {
    primary_id: "D0J3FH",
    names: ["NDT9520492"],
  },
  {
    primary_id: "D0J3FK",
    names: ["DNX-2000"],
  },
  {
    primary_id: "D0J3HE",
    names: ["ACAM529"],
  },
  {
    primary_id: "D0J3HR",
    names: [
      "1-(3,4-Dihydronaphthalen-1-Yl)Ethanone",
      "CHEMBL378621",
      "1-(3,4-Dihydro-Naphthalen-1-Yl)-Ethanone",
      "67106-36-9",
      "SCHEMBL6337844",
      "1-Acetyl-3,4-Dihydronaphthalene",
      "CTK1J3902",
      "DTXSID40447596",
      "BDBM50189272",
      "AKOS030597011",
      "1-(3,4-Dihydro-1-Naphthalenyl)Ethanone",
      "Ethanone, 1-(3,4-Dihydro-1-Naphthalenyl)-",
    ],
  },
  {
    primary_id: "D0J3IA",
    names: [
      "5,6-Dichloro-N-(2-Morpholinoethyl)Nicotinamide",
      "CHEMBL589770",
      "MolPort-005-210-928",
      "ZINC21809540",
      "BDBM50307191",
      "AKOS005859120",
      "1039842-02-8",
      "Z32410257",
    ],
  },
  {
    primary_id: "D0J3JI",
    names: [
      "I-CBP112",
      "1640282-31-0",
      "I-CBP 112",
      "CHEMBL3774655",
      "1-[7-(3,4-Dimethoxyphenyl)-9-[[(3S)-1-Methylpiperidin-3-Yl]methoxy]-2,3,4,5-Tetrahydro-1,4-Benzoxazepin-4-Yl]propan-1-One",
      "1-[7-(3,4-Dimethoxyphenyl)-9-{[(3S)-1-Methylpiperidin-3-Yl]methoxy}-2,3,4,5-Tetrahydro-1,4-Benzoxazepin-4-Yl]propan-1-One",
      "GTPL8236",
      "SCHEMBL17620385",
      "MolPort-035-765-871",
      "EX-A2474",
      "ZINC96024493",
      "BDBM50151663",
      "AKOS024458402",
      "CS-6146",
      "NCGC00350526-04",
      "HY-19541",
      "I-CBP112, &gt",
    ],
  },
  {
    primary_id: "D0J3JN",
    names: ["TV-45070"],
  },
  {
    primary_id: "D0J3JY",
    names: ["PMID25399762-Compound-Table1-C25"],
  },
  {
    primary_id: "D0J3KY",
    names: ["ReN-001", "CTX-0E03", "Stem Cell Therapy (Chronic Stroke), ReNeuron"],
  },
  {
    primary_id: "D0J3LR",
    names: ["VTX-11e"],
  },
  {
    primary_id: "D0J3MY",
    names: [
      "NPI-3114",
      "Actinomycete-Derived Chloronated Bisindole Pyrrole Derivatives (Gram Positive Bacterial Infection), Nereus",
    ],
  },
  {
    primary_id: "D0J3NJ",
    names: ["RP-69698", "CHEMBL95799", "SCHEMBL8987246"],
  },
  {
    primary_id: "D0J3NK",
    names: ["MCL-449"],
  },
  {
    primary_id: "D0J3NS",
    names: ["3-(Aminomethyl)-4-(Thiophen-3-Yl)Butanoic Acid", "CHEMBL382417", "SCHEMBL6376202"],
  },
  {
    primary_id: "D0J3NX",
    names: ["N-Cyclopentyl-6-(2-Phenylethynyl)Nicotinamide", "CHEMBL551958"],
  },
  {
    primary_id: "D0J3NY",
    names: ["Benzene Sulfonamide Derivative 8"],
  },
  {
    primary_id: "D0J3OD",
    names: ["Indoline Derivative 1"],
  },
  {
    primary_id: "D0J3OV",
    names: ["PI-083"],
  },
  {
    primary_id: "D0J3OZ",
    names: ["2-(Pyrimidin-4-Ylamino)Thiazole-5-Carbonitrile", "CHEMBL202748", "SCHEMBL5889065"],
  },
  {
    primary_id: "D0J3PJ",
    names: ["SB-408124"],
  },
  {
    primary_id: "D0J3PK",
    names: ["4-Imidazol-1-Yl-2-Phenyl-Chroman-7-Ol", "CHEMBL95919"],
  },
  {
    primary_id: "D0J3PM",
    names: ["4-Hydroxy-N-Isopropyl-N-Phenylbenzenesulfonamide", "CHEMBL204664"],
  },
  {
    primary_id: "D0J3PS",
    names: ["ASP-0265"],
  },
  {
    primary_id: "D0J3QC",
    names: ["DCPIB", "4-(2-Butyl-6,7-Dichlor-2-Cyclopentyl-Indan-1-On-5-Yl) Oxybutyric Acid"],
  },
  {
    primary_id: "D0J3QN",
    names: ["CG-1255", "CG-1552", "Histone Deacetylase Inhibitors, CircaGen"],
  },
  {
    primary_id: "D0J3QU",
    names: [
      "ZD-7944",
      "CHEMBL339767",
      "ZD7944",
      "SCHEMBL7501969",
      "BDBM50409575",
      "(R)-3-((S)-1-(3,4-Dichlorophenyl)-3-(4-(2-((S)-Methylsulfinyl)Phenyl)Piperidin-1-Yl)Propyl)-2-Ethylisoindolin-1-One",
    ],
  },
  {
    primary_id: "D0J3RB",
    names: ["NSC-106080"],
  },
  {
    primary_id: "D0J3RP",
    names: ["ISIS-GCCRrx"],
  },
  {
    primary_id: "D0J3RS",
    names: ["(2'Z,3'E)-5-Fluoro-5'-Hydroxy-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0J3RY",
    names: ["Denileukin Diftitox"],
  },
  {
    primary_id: "D0J3SN",
    names: [
      "6-Methoxy-2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "20315-68-8",
      "6-Methoxy-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indole",
      "6-Methoxytryptoline",
      "6-Methoxy-1,2,3,4-Tetrahydro-Beta-Carboline",
      "Pinoline",
      "6-Methoxy-1,2,3,4-Tetrahydro-9H-Pyrido[3,4-B]indole",
      "6-MeOthbc",
      "6-Methoxy-1,2,3,4-Tetrahydro-9H-Pyrido[3,4b] Indole",
      "6-Methoxytetrahydro-Beta-Carboline",
      "2,3,4,9-Tetrahydro-6-Methoxy-1H-Pyrido(3,4-B)Indole",
      "BRN 0172873",
      "CHEMBL266084",
      "1H-Pyrido[3,4-B]indole, 2,3,4,9-Tetrahydro-6-Methoxy-",
      "QYMDEOQLJUUNOF-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0J3SP",
    names: [
      "2-Pentafluorophenylamido-5-Sulfonamidoindane",
      "CHEMBL184361",
      "Indanesulfonamide Derivative 12c",
      "BDBM11035",
    ],
  },
  {
    primary_id: "D0J3SU",
    names: [
      "Isatin Sulfonamide 34",
      "CHEMBL439753",
      "JMC522188 Compound 2",
      "Pyrrolidine Isatin Analogue 2",
      "GTPL6515",
      "SCHEMBL2264739",
      "BDBM10323",
      "PKOUAUWPBMZQCH-FQEVSTJZSA-N",
      "Isatin Sulfonamide 34 [PMID: 11384246]",
      "(S)-1-Benzyl-5-{1-[2-(Phenoxymethyl)Pyrrolidinyl]sulfonyl}isatin",
      "(S)-1-Benzyl-5-[1-[2-(Phenoxymethyl)Pyrrolidinyl]sulfonyl]-Isatin",
      "5-[(2S)-2-(Phenoxymethyl)Pyrrolidin-1-Yl]sulfonyl-1-(Phenylmethyl)Indole-2,3-Dione",
      "(S)-1-Benzyl-5-(2-Phenoxymethyl-Pyrrolidine-1-Sulfonyl)-1H-Indole-2,3-Dione",
    ],
  },
  {
    primary_id: "D0J3SW",
    names: [
      "BCEAB",
      "CHEBI:6426",
      "Ac-Leu-Leu-Arg-H",
      "Ac-Leu-Leu-Argininal",
      "Acetyl-L-Leucyl-L-Leucylargininal",
      "N-Acetyl-L-Leucyl-L-Leucyl-L-Argininal",
      "55123-66-5",
      "UNII-J97339NR3V",
      "J97339NR3V",
      "103476-89-7",
      "Acetyl-Leu-Leu-Arg-Al",
      "Leupeptin Hemisulfate",
      "L-Leucinamide, N-Acetyl-L-Leucyl-N-(4-((Aminoiminomethyl)Amino)-1-Formylbutyl)-",
      "N-Acetyl-L-Leucyl-N-[(1S)-4-{[amino(Imino)Methyl]amino}-1-Formylbutyl]-L-Leucinamide",
    ],
  },
  {
    primary_id: "D0J3TI",
    names: ["Rosmantuzumab"],
  },
  {
    primary_id: "D0J3TN",
    names: ["Piperidinyl Pyrazole Derivative 2"],
  },
  {
    primary_id: "D0J3TP",
    names: ["ZD-0870", "D-0870", "M-16354"],
  },
  {
    primary_id: "D0J3TS",
    names: [
      "6-Thiophen-2-Yl-Imidazo[2,1-B]thiazole",
      "6-(Thiophen-2-Yl)Imidazo[2,1-B][1,3]thiazole",
      "AC1Q7FWK",
      "AC1N51XV",
      "CHEMBL12113",
      "IFLab2_000013",
      "IFLab1_003798",
      "MolPort-000-476-548",
      "HMS1422M14",
      "ZINC406855",
      "STL115074",
      "CCG-22586",
      "AKOS002232733",
      "MCULE-2716534216",
      "6-(2-Thienyl)Imidazo[2,1-B]thiazole",
      "IDI1_019039",
      "IDI1_009905",
      "Imidazo[2,1-B]thiazole,6-(2-Thienyl)-",
      "KB-305118",
      "EU-0080970",
      "ST50113084",
      "6-(2-Thienyl)Imidazo[2,1-B]1,3-Thiazoline",
      "6-Thiophen-2-Ylimidazo[2,1-B][1,3]thiazole",
      "SR-01000506671",
    ],
  },
  {
    primary_id: "D0J3TT",
    names: ["Ear Disorder Therapeutics"],
  },
  {
    primary_id: "D0J3TY",
    names: [
      "2-M-Tolyl-2H-Pyrazolo[3,4-C]quinolin-4(5H)-One",
      "CHEMBL387592",
      "BDBM50218914",
      "2-M-Tolyl-2,5-Dihydro-Pyrazolo[3,4-C]quinolin-4-One",
    ],
  },
  {
    primary_id: "D0J3UU",
    names: [
      "(2S)-2-(4-Chlorophenoxy)-3-Phenylpropanoic Acid",
      "CHEMBL364748",
      "SCHEMBL20553473",
      "ZINC13671687",
      "BDBM50171897",
      "DB08760",
      "(S)-2-(4-Chlorophenoxy)-3-Phenylpropanoic Acid",
      "(S)-2-(4-Chloro-Phenoxy)-3-Phenyl-Propionic Acid",
    ],
  },
  {
    primary_id: "D0J3VV",
    names: ["IPH-3201"],
  },
  {
    primary_id: "D0J3VX",
    names: ["1-(Isoquinolin-5-Yl)-3-(4-Morpholinobenzyl)Urea"],
  },
  {
    primary_id: "D0J3XJ",
    names: ["N-(3-Phenyl)Propyl-2-(4-Bromophenylacetamide)", "CHEMBL478643"],
  },
  {
    primary_id: "D0J3YD",
    names: ["2-Hydroxyiminoolean-12-En-28-Oic Acid"],
  },
  {
    primary_id: "D0J3YX",
    names: ["BAY 86-6150"],
  },
  {
    primary_id: "D0J3ZA",
    names: ["KBSA-302"],
  },
  {
    primary_id: "D0J3ZK",
    names: ["(R)-Flurocarazolol", "GTPL65", "CHEMBL418834", "PDSP2_000795", "PDSP1_000808"],
  },
  {
    primary_id: "D0J3ZM",
    names: ["Azole Benzene Derivative 3"],
  },
  {
    primary_id: "D0J3ZT",
    names: ["PRT-201"],
  },
  {
    primary_id: "D0J4AT",
    names: ["4-(2,5-Diphenyl-Furan-3-Yl)-Morpholine", "CHEMBL323451", "2,5-Diphenyl-3-Morpholinofuran"],
  },
  {
    primary_id: "D0J4AZ",
    names: ["L-Piperazino-3-Phenyl-Indane Derivative 1"],
  },
  {
    primary_id: "D0J4BF",
    names: ["Glucagon-(1-6)"],
  },
  {
    primary_id: "D0J4BO",
    names: ["Norovirus Monovalent Vaccine"],
  },
  {
    primary_id: "D0J4CA",
    names: ["PMID28270021-Compound-WO2010077680 103"],
  },
  {
    primary_id: "D0J4CB",
    names: ["1-(METHOXYMETHYL)-6-(NAPHTHALEN-2-YL)-3-AZABICYCLO[4.1.0]HEPTANE (ENANTIOMERIC MIX)"],
  },
  {
    primary_id: "D0J4CI",
    names: ["Mycophenolic Acid/Nucleotide Derivative 12"],
  },
  {
    primary_id: "D0J4CL",
    names: ["PMID29649907-Compound-19"],
  },
  {
    primary_id: "D0J4CV",
    names: ["AAV-SFLT Gene Therapy"],
  },
  {
    primary_id: "D0J4DJ",
    names: ["Nocardimicin F"],
  },
  {
    primary_id: "D0J4EF",
    names: [
      "1-Biphenyl-4-Yl-3-Aza-Bicyclo[3.1.0]hexane",
      "CHEMBL505313",
      "86225-52-7",
      "SCHEMBL4713453",
      "CTK2I3607",
      "DTXSID40534177",
      "BDBM50243943",
      "1-([1,1'-Biphenyl]-4-Yl)-3-Azabicyclo[3.1.0]hexane",
      "3-Azabicyclo[3.1.0]hexane, 1-[1,1'-Biphenyl]-4-Yl-",
    ],
  },
  {
    primary_id: "D0J4EG",
    names: [
      "L-648051",
      "4-Apsob",
      "L 648051",
      "UNII-0LGZ40MB45",
      "0LGZ40MB45",
      "91541-18-3",
      "4-((3-(4-Acetyl-3-Hydroxy-2-Propylphenoxy)Propyl)Sulfonyl)-Gamma-Oxobenzenebutanoic Acid",
      "4-(4-{[3-(4-Acetyl-3-Hydroxy-2-Propylphenoxy)Propyl]sulfonyl}phenyl)-4-Oxobutanoic Acid",
      "Benzenebutanoic Acid, 4-((3-(4-Acetyl-3-Hydroxy-2-Propylphenoxy)Propyl)Sulfonyl)-Gamma-Oxo-",
      "AC1Q5VP2",
      "AC1L3TT7",
      "SCHEMBL157166",
      "CHEMBL282253",
      "CTK8D5118",
      "DTXSID50238619",
      "ZAKKEARLDPTRLX-UHFFFAOYSA-N",
      "AKOS030530731",
      "LS-186850",
      "LS-187510",
    ],
  },
  {
    primary_id: "D0J4EZ",
    names: ["AS95", "AS 95", "AS-95"],
  },
  {
    primary_id: "D0J4GG",
    names: ["LF-160335"],
  },
  {
    primary_id: "D0J4GO",
    names: [
      "Dichlorphenamide",
      "Diclofenamide",
      "120-97-8",
      "4,5-Dichlorobenzene-1,3-Disulfonamide",
      "Dichlofenamide",
      "Dichlorophenamide",
      "Daranide",
      "Dichlorphenamid",
      "Glauconide",
      "Antidrasi",
      "Glaucol",
      "Oratrol",
      "Diclofenamidum",
      "Diclofenamida",
      "Diclofenamid",
      "Barastonin",
      "Glaumid",
      "Glafco",
      "Glajust",
      "4,5-Dichloro-M-Benzenedisulfonamide",
      "1,3-Benzenedisulfonamide, 4,5-Dichloro-",
      "Diclofenamidum [INN-Latin]",
      "4,5-Dichloro-1,3-Disulfamoylbenzene",
      "1,3-Disulfamoyl-4,5-Dichlorobenzene",
      "Diclofenamida [INN-Spanish]",
      "1,3-Disulfamyl-4,5-Dichlorobenzene",
      "Dasanide",
      "Keveyis",
      "Dichlorphenamide [BAN]",
      "Llorens Brand Of Dichlorphenamide",
      "Merck Brand Of Dichlorphenamide",
      "CB 8000",
      "I7A",
      "Daranide (TN)",
      "Dichlorophenamide (DCP)",
      "Dichlorphenamide (USP)",
      "Diclofenamide (JP15/INN)",
      "3,4-Dichloro-5-Sulfamylbenzenesulfonamide",
      "4,5-Dichloro-1,3-Benzenedisulfonamide",
      "4,5-Dichloro-Benzene-1,3-Disulfonic Acid Diamide",
      "4,5-DICHLOROBENZENE-1,3-DISULFONAMIDE",
    ],
  },
  {
    primary_id: "D0J4GP",
    names: ["Aminopyrimidine Derivative 9"],
  },
  {
    primary_id: "D0J4GQ",
    names: ["FR-152558"],
  },
  {
    primary_id: "D0J4GY",
    names: ["Dihydropyrido Phthalazinone Derivative 2"],
  },
  {
    primary_id: "D0J4HH",
    names: [
      "3-(5-Pyridin-2-Yl-Tetrazol-2-Yl)-Benzonitrile",
      "507268-13-5",
      "CHEMBL365499",
      "3-[5-(2-Pyridinyl)-2H-Tetrazol-2-Yl]Benzonitrile",
      "3-(5-(Pyridin-2-Yl)-2h-Tetrazol-2-Yl)Benzonitrile",
      "3-(5-Pyridin-2-Yl-2H-Tetrazol-2-Yl)Benzonitrile",
      "SCHEMBL660355",
      "CTK1G6179",
      "DTXSID70436690",
      "OGIBYDWBTRKAOL-UHFFFAOYSA-N",
      "ZINC3962980",
      "BDBM50151907",
      "AKOS015995227",
      "RP28801",
      "DA-05371",
      "DB-050669",
      "KB-233258",
      "FT-0760671",
      "J-510771",
      "3-[5-(2-Pyridinyl)-2H-Tetrazole-2-Yl]benzonitrile",
      "Benzonitrile, 3-[5-(2-Pyridinyl)-2H-Tetrazol-2-Yl]-",
    ],
  },
  {
    primary_id: "D0J4HT",
    names: ["Lipid Chaperones"],
  },
  {
    primary_id: "D0J4IP",
    names: ["PMID26560530-Compound-31"],
  },
  {
    primary_id: "D0J4IT",
    names: [
      "Cat Peptide Allergy Desensitization Agent",
      "Cat Allergy Vaccine, PowderJect",
      "Cat Peptide Allergy Desensitization Agent (ToleroMune)",
      "Cat-PAD",
      "Cat Peptide Allergy Desensitization Agent (ToleroGenix), Circassia",
      "Cat Peptide Allergy Desensitization Agent (ToleroMune), Circassia",
    ],
  },
  {
    primary_id: "D0J4IX",
    names: ["Glaziovine"],
  },
  {
    primary_id: "D0J4JM",
    names: [
      "Reserpine",
      "Abesta",
      "Abicol",
      "Adelfan",
      "Adelphin",
      "Alkarau",
      "Alkaserp",
      "Alserin",
      "Apoplon",
      "Apsical",
      "Ascoserp",
      "Ascoserpina",
      "Austrapine",
      "Banasil",
      "Banisil",
      "Benazyl",
      "Bioserpine",
      "Brinderdin",
      "Briserine",
      "Broserpine",
      "Cardioserpin",
      "Carditivo",
      "Carpacil",
      "Carrserp",
      "Darebon",
      "Deserpine",
      "Dralserp",
      "Eberpine",
      "Eberspine",
      "Ebserpine",
      "Elfanex",
      "Elserpine",
      "Enipresser",
      "Escaspere",
      "Eserpine",
      "Eskaserp",
      "Eskaserpine",
      "Gammaserpine",
      "Gilucard",
      "Helfoserpin",
      "Hexaplin",
      "Hiposerpil",
      "Hiserpia",
      "Hydropine",
      "Hydroserp",
      "Hypersil",
      "Hypersine",
      "Hypertensan",
      "Idoserp",
      "Idsoserp",
      "Interpina",
      "Kitine",
      "Klimanosid",
      "Lemiserp",
      "Loweserp",
      "Mallopress",
      "Maviserpin",
      "Mayserpine",
      "Mephaserpin",
      "Modenol",
      "Naquival",
      "Nembuserpin",
      "Neoserfin",
      "Neoserp",
      "Neoslowten",
      "Orthoserpina",
      "Perskleran",
      "Pressimedin",
      "Purserpine",
      "Quiescin",
      "Raucap",
      "Raudiford",
      "Raudixin",
      "Raudixoid",
      "Raugal",
      "Raulen",
      "Rauloydin",
      "Raumorine",
      "Raunervil",
      "Raunorine",
      "Raunova",
      "Raupasil",
      "Raupoid",
      "Raurine",
      "Rausan",
      "Rausedan",
      "Rausedil",
      "Rausedyl",
      "Rauserpin",
      "Rauserpine",
      "Rauserpol",
      "Rausingle",
      "Rautrin",
      "Rauvlid",
      "Rauwasedin",
      "Rauwilid",
      "Rauwiloid",
      "Rauwipur",
      "Rauwita",
      "Rauwoleaf",
      "Rawilid",
      "Recipin",
      "Regroton",
      "Resaltex",
      "Resedrex",
      "Resedril",
      "Reserbal",
      "Resercaps",
      "Resercen",
      "Reserfia",
      "Reserjen",
      "Reserlor",
      "Reserp",
      "Reserpal",
      "Reserpamed",
      "Reserpanca",
      "Reserpene",
      "Reserpex",
      "Reserpidefe",
      "Reserpil",
      "Reserpin",
      "Reserpina",
      "Reserpinum",
      "Reserpka",
      "Reserpoid",
      "Reserpur",
      "Reserutin",
      "Resiatric",
      "Residin",
      "Residine",
      "Resine",
      "Resocalm",
      "Resperin",
      "Resperine",
      "Respital",
      "Restran",
      "Rezerpin",
      "Riserpa",
      "Rivased",
      "Rivasin",
      "Rolserp",
      "Roxel",
      "Roxinoid",
      "Roxynoid",
      "Ryser",
      "Salupres",
      "Sandril",
      "Sandron",
      "Sedaraupin",
      "Sedaraupina",
      "Sederaupin",
      "Sedserp",
      "Seominal",
      "Serfin",
      "Serfolia",
      "Serolfia",
      "Serpalan",
      "Serpaloid",
      "Serpaneurona",
      "Serpanray",
      "Serpasil",
      "Serpasol",
      "Serpate",
      "Serpazil",
      "Serpazol",
      "Serpedin",
      "Serpen",
      "Serpena",
      "Serpentil",
      "Serpentin",
      "Serpentina",
      "Serpicon",
      "Serpil",
      "Serpiloid",
      "Serpilum",
      "Serpine",
      "Serpipur",
      "Serpivate",
      "Serpivite",
      "Serpogen",
      "Serpoid",
      "Serpone",
      "Serpyrit",
      "Sertabs",
      "Sertens",
      "Sertensin",
      "Sertina",
      "Supergan",
      "Tefaserpina",
      "Temposerpine",
      "Tensanyl",
      "Tenserpinie",
      "Tensional",
      "Tensionorme",
      "Tepserpine",
      "Terbolan",
      "Transerpin",
      "Triserpin",
      "Tylandril",
      "Unilord",
      "Unipres",
      "VIXO",
      "Esc Aspere",
      "Hydromox R",
      "Hypercal B",
      "Loweser P",
      "Neose Rfin",
      "Rau Sedyl",
      "Renese R",
      "Rese Rpamed",
      "Se Rpate",
      "V Serp",
      "VI XO",
      "Diupres 250",
      "Diupres 500",
      "ENT 50146",
      "H 520",
      "Hydropres 25",
      "Hydropres 50",
      "Hydrosine 25",
      "R 0875",
      "Apoplon (TN)",
      "Diupres-250",
      "Diupres-500",
      "Diurese-R",
      "Diutensen-R",
      "HYDRO-RESERP",
      "Hydrap-ES",
      "Hydromox-R",
      "Key-Serpine",
      "L-Carpserp",
      "Neo-Serp",
      "Rau-Sed",
      "Renese-R",
      "Rese-Lar",
      "Reser-Ar",
      "SK-Reserpine",
      "T-Serp",
      "Usaf Cb-27",
      "V-Serp",
      "Vio-Serpine",
      "Cam-Ap-Es",
      'L"-Carpserp',
      "R-E-S",
      "Ser-A-Gen",
      "3P Reserp",
    ],
  },
  {
    primary_id: "D0J4KC",
    names: [
      "CETi-1",
      "Cholesteryl Ester Transfer Protein Vaccine",
      "CETP Vaccine, T Cell Sciences",
      "Vaccine (CETP), AVANT",
      "Vaccine (CETP), T Cell Sciences",
      "Vaccine (Atherosclerosis), T Cell Sciences",
    ],
  },
  {
    primary_id: "D0J4KJ",
    names: [
      "5-P-Tolylsulfanyl-Quinazoline-2,4-Diamine",
      "5-(4-Tolylthio)-2,4-Diaminoquinazoline",
      "168910-32-5",
      "5-[(4-METHYLPHENYL)SULFANYL]-2,4-QUINAZOLINEDIAMINE",
      "CHEMBL83547",
      "5-[(4-Methylphenyl)Sulfanyl]quinazoline-2,4-Diamine",
      "TQ4",
      "Maybridge3_004364",
      "AC1L9JD9",
      "Oprea1_160965",
      "SCHEMBL2126685",
      "BDBM18046",
      "CTK0E5148",
      "DTXSID80332215",
      "MolPort-002-910-071",
      "HMS1443G08",
      "GW578",
      "ZINC172633",
      "HMS3604M19",
      "CCG-44692",
      "AKOS027447345",
      "MCULE-8600056307",
      "DB04306",
      "IDI1_015751",
      "5-(P-Tolylthio)Quinazoline-2,4-Diamine",
      "RT-010103",
      "5-(P-Tolylsulfanyl)Quinazoline-2,4-Diamine",
      "FT-0675290",
    ],
  },
  {
    primary_id: "D0J4KQ",
    names: ["Peptide Analog 51"],
  },
  {
    primary_id: "D0J4LI",
    names: ["Thymoctonan"],
  },
  {
    primary_id: "D0J4LR",
    names: ["24-Ethyl-Cholest-7-Ene-3,5,6-Triol", "CHEMBL201951"],
  },
  {
    primary_id: "D0J4LV",
    names: ["PMID30107136-Compound-Example53"],
  },
  {
    primary_id: "D0J4MU",
    names: ["MRS2957"],
  },
  {
    primary_id: "D0J4MY",
    names: ["Tasidotin Hydrochloride"],
  },
  {
    primary_id: "D0J4NI",
    names: ["Dihexan-3-Yl 5-(Hydroxymethyl)Isophthalate", "CHEMBL523053", "SCHEMBL13625642"],
  },
  {
    primary_id: "D0J4NN",
    names: ["KNI-10742"],
  },
  {
    primary_id: "D0J4NQ",
    names: ["Sphingosylphosphorylcholine"],
  },
  {
    primary_id: "D0J4NR",
    names: ["MC-4001"],
  },
  {
    primary_id: "D0J4ON",
    names: ["RGB-286147"],
  },
  {
    primary_id: "D0J4PB",
    names: ["GW 597599"],
  },
  {
    primary_id: "D0J4PC",
    names: ["PMID28350212-Compound-15"],
  },
  {
    primary_id: "D0J4PE",
    names: ["Acyl Oxymethyl Acrylamide Ester Derivative 2"],
  },
  {
    primary_id: "D0J4PM",
    names: ["NNC-0151-0000-0000"],
  },
  {
    primary_id: "D0J4QE",
    names: ["Diazepinone Derivative 5"],
  },
  {
    primary_id: "D0J4QG",
    names: ["Pyrazole And Thiophene Derivative 3"],
  },
  {
    primary_id: "D0J4QH",
    names: ["Methyl Phosphinic Acid"],
  },
  {
    primary_id: "D0J4RI",
    names: ["ASN06917370", "ASN 06917370", "ASN-06917370"],
  },
  {
    primary_id: "D0J4RY",
    names: [
      "BAS-450225",
      "CHEMBL210036",
      "2,4-Dichloro-N-(5-Phenethyl-1,3,4-Thiadiazol-2-Yl)Benzamide",
      "AC1LL38T",
      "Oprea1_759762",
      "Oprea1_195875",
    ],
  },
  {
    primary_id: "D0J4SW",
    names: [
      "2-P-Tolyl-2H-Pyrazolo[3,4-C]quinolin-4(5H)-One",
      "CHEMBL242202",
      "BDBM50218920",
      "2-P-Tolyl-2,5-Dihydro-Pyrazolo[3,4-C]quinolin-4-One",
    ],
  },
  {
    primary_id: "D0J4TX",
    names: ["AVX-002"],
  },
  {
    primary_id: "D0J4UO",
    names: ["Macrocycle Derivative 3"],
  },
  {
    primary_id: "D0J4VA",
    names: ["3-Fluoro-4'-(1-(Pyridin-4-Yl)Propyl)Biphenyl-4-Ol", "CHEMBL1173261", "SCHEMBL18770414", "BDBM50322797"],
  },
  {
    primary_id: "D0J4VR",
    names: [
      "3,5-Dihydroxyl-4'-Amino-Trans-Stilbene",
      "CHEMBL1172402",
      "Stilbene Derivative, 3",
      "SCHEMBL2516281",
      "CHEBI:125141",
      "(E)-4'-Aminostilbene-3,5-Diol",
      "BDBM50322066",
      "3,5-Dihydroxyl-4''-Amino-Trans-Stilbene",
    ],
  },
  {
    primary_id: "D0J4WA",
    names: ["BAY1213790"],
  },
  {
    primary_id: "D0J4WW",
    names: ["NIP-520"],
  },
  {
    primary_id: "D0J4XV",
    names: ["AZD4635"],
  },
  {
    primary_id: "D0J4YA",
    names: ["GW441756"],
  },
  {
    primary_id: "D0J4YH",
    names: ["P-Coumaric Acid Derivative 3"],
  },
  {
    primary_id: "D0J4YV",
    names: ["AP-214"],
  },
  {
    primary_id: "D0J4YW",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 24"],
  },
  {
    primary_id: "D0J4ZH",
    names: ["Tamoxifen Methyl Iodide"],
  },
  {
    primary_id: "D0J5AB",
    names: ["PMID27109571-Compound-44"],
  },
  {
    primary_id: "D0J5AD",
    names: ["FI-6"],
  },
  {
    primary_id: "D0J5AR",
    names: ["2-(2'-Aminoethyl)-5-Benzyltetrahydrofuran", "CHEMBL448912", "SCHEMBL2691374", "BDBM50276507"],
  },
  {
    primary_id: "D0J5AU",
    names: ["Isosorbide-2-(Ethylcarbamate)-5-Mononitrate", "CHEMBL494518"],
  },
  {
    primary_id: "D0J5CX",
    names: ["TAK-637"],
  },
  {
    primary_id: "D0J5DC",
    names: [
      "Clofibrate",
      "Amotril",
      "Angiokapsul",
      "Anparton",
      "Antilipid",
      "Antilipide",
      "Apolan",
      "Arterioflexin",
      "Arterosol",
      "Artevil",
      "Ateculon",
      "Ateriosan",
      "Athebrate",
      "Atheromide",
      "Atheropront",
      "Athromidin",
      "Atrolen",
      "Atromid",
      "Atromida",
      "Atromidin",
      "Atrovis",
      "Azionyl",
      "Bioscleran",
      "Bresit",
      "CPIB",
      "Cartagyl",
      "Chlorfenisate",
      "Chlorphenisate",
      "Cinnarizin",
      "Citiflus",
      "Claripex",
      "Cloberat",
      "Clobrat",
      "Clofar",
      "Clofibate",
      "Clofibram",
      "Clofibrat",
      "Clofibrato",
      "Clofibratum",
      "Clofinit",
      "Clofipront",
      "Delipid",
      "Deliva",
      "ELPI",
      "EPIB",
      "Fibralem",
      "Gerastop",
      "Hyclorate",
      "Klofibrat",
      "Klofiran",
      "Levatrom",
      "Lipamid",
      "Lipavil",
      "Lipavlon",
      "Lipidsenker",
      "Lipofacton",
      "Lipomid",
      "Liponorm",
      "Liporeduct",
      "Liporil",
      "Liposid",
      "Liprin",
      "Liprinal",
      "Lobetrin",
      "Miscleron",
      "Misclerone",
      "Miskleron",
      "Negalip",
      "Normalip",
      "Normat",
      "Normet",
      "Normolipol",
      "Novofibrate",
      "Persantinat",
      "Recolip",
      "Regardin",
      "Regelan",
      "Robigram",
      "Scrobin",
      "Serofinex",
      "Serotinex",
      "Skerolip",
      "Sklerepmexe",
      "Sklero",
      "Sklerolip",
      "Skleromex",
      "Skleromexe",
      "Ticlobran",
      "Xyduril",
      "Yoclo",
      "Amotril S",
      "Atromid S",
      "Claripex CPIB",
      "Clofibrato [Spanish]",
      "Dura Clofibrat",
      "Ethyl Chlorophenoxyisobutyrate",
      "Ethyl Clofibrate",
      "Regelan N",
      "Vincamin Compositum",
      "AY 61123",
      "C 6643",
      "Lipide 500",
      "Oxan 600",
      "AY-61123",
      "Athranid-Wirkstoff",
      "Atromid-S",
      "BML2-F02",
      "Chlorophenoxyisobutyrate, Ethyl",
      "Clobren-5F",
      "Clobren-SF",
      "Clofibrate (CLOF)",
      "Clofibrato [INN-Spanish]",
      "Clofibratum [INN-Latin]",
      "Clofibric Acid, Ethyl Ester",
      "Ethyl P-Chlorophenoxyisobutyrate",
      "Ethyl Para-Chlorophenoxyisobutyrate",
      "Neo-Atomid",
      "Neo-Atromid",
      "Sklero-Tablinen",
      "Sklero-Tablinene",
      "Sklero-Tabuls",
      "ATROMID-S (TN)",
      "Atromid-S (TN)",
      "Atromid-S, Clofibrate",
      "Ethyl Alpha-P-Chlorophenoxyisobutyrate",
      "Clofibrate (JP15/USP/INN)",
      "Clofibrate [USAN:INN:BAN:JAN]",
      "Ethyl 2-(4-Chlorophenoxy)Isobutyrate",
      "Ethyl 2-(P-Chlorophenoxy)Isobutyrate",
      "Ethyl Alpha-(4-Chlorophenoxy)Isobutyrate",
      "Ethyl Alpha-(P-Chlorophenoxy)Isobutyrate",
      "Ethyl-Alpha-P-Chlorophenoxy-Isobutyrate",
    ],
  },
  {
    primary_id: "D0J5DL",
    names: ["L-4-OXALYSINE"],
  },
  {
    primary_id: "D0J5DN",
    names: [
      "7,3',4'-Trihydroxyisoflavone",
      "3',4',7-Trihydroxyisoflavone",
      "3'-Hydroxydaidzein",
      "485-63-2",
      "3-(3,4-Dihydroxyphenyl)-7-Hydroxy-4H-Chromen-4-One",
      "UNII-T08Y239E7Y",
      "CHEMBL13486",
      "CHEBI:50399",
      "3',4',7-Trihydroxy Isoflavone",
      "DDKGKOOLFLYZDL-UHFFFAOYSA-N",
      "3'',4'',7-Trihydroxyisoflavone",
      "T08Y239E7Y",
      "3-(3,4-Dihydroxyphenyl)-7-Hydroxychromen-4-One",
      "3-(3,4-Dihydroxyphenyl)-7-Hydroxy-4H-1-Benzopyran-4-One",
      "4H-1-Benzopyran-4-One, 3-(3,4-Dihydroxyphenyl)-7-Hydroxy-",
      "47X",
      "Orita-13",
      "3l5r",
      "AC1NR4TZ",
      "SCHEMBL73012",
      "BIDD:ER0118",
      "GTPL9985",
    ],
  },
  {
    primary_id: "D0J5ED",
    names: ["LTX-109"],
  },
  {
    primary_id: "D0J5EF",
    names: [
      "2-(Tert-Butylamino)-3'-Fluoropropiophenone",
      "2-(Tert-Butylamino)-1-(3-Fluorophenyl)Propan-1-One",
      "CHEMBL576063",
      "AC1Q5DGH",
      "AC1L4Y0Z",
      "2-(Tert-Butylamino)-1-(3-Fluorphenyl)Propan-1-On",
      "34841-40-2",
      "SCHEMBL770228",
      "CTK8I3431",
      "BDBM50302925",
      "TL8002592",
    ],
  },
  {
    primary_id: "D0J5EQ",
    names: ["Maclizumab"],
  },
  {
    primary_id: "D0J5EW",
    names: ["Pyrimidinedione Derivative 1"],
  },
  {
    primary_id: "D0J5EZ",
    names: ["AM-2S", "CHEMBL470194", "SCHEMBL13208678", "BDBM50278743"],
  },
  {
    primary_id: "D0J5FK",
    names: ["GNE-7915"],
  },
  {
    primary_id: "D0J5FU",
    names: ["MIV-701"],
  },
  {
    primary_id: "D0J5GC",
    names: ["Heteroaromatic Ring Derivative 1"],
  },
  {
    primary_id: "D0J5GS",
    names: ["Azaindole Derivative 6"],
  },
  {
    primary_id: "D0J5GT",
    names: ["ZANKIREN"],
  },
  {
    primary_id: "D0J5HG",
    names: ["ETS-6103"],
  },
  {
    primary_id: "D0J5HR",
    names: [
      "LGK974",
      "1243244-14-5",
      "LGK-974",
      "2-(2',3-Dimethyl-[2,4'-Bipyridin]-5-Yl)-N-(5-(Pyrazin-2-Yl)Pyridin-2-Yl)Acetamide",
      "WNT-974",
      "LGK 974",
      "UNII-U27F40013Q",
      "CHEBI:78030",
      "CHEMBL3188386",
      "U27F40013Q",
      "AK165831",
      "2-(2',3-Dimethyl-2,4'-Bipyridin-5-Yl)-N-[5-(Pyrazin-2-Yl)Pyridin-2-Yl]acetamide",
      "MLS006011002",
      "SCHEMBL1723611",
      "KS-00000SUI",
      "MolPort-029-945-290",
      "HMS3653G11",
      "BCP08495",
      "EX-A1402",
      "ZINC95930187",
      "STL556617",
      "BDBM50133870",
      "BBL102809",
      "2140AH",
      "S7143",
      "AKOS025211913",
      "SB19314",
      "CS-2807",
      "DB12561",
      "NCGC00347950-07",
      "NCGC00347950-01",
      "OR302651",
      "QC-11701",
    ],
  },
  {
    primary_id: "D0J5HU",
    names: [
      "Ro-46-2005",
      "150725-87-4",
      "Ro 46-2005",
      "RO462005",
      "CHEMBL115951",
      "Ro46-2005",
      "4-Tert-Butyl-N-[6-(2-Hydroxyethoxy)-5-(3-Methoxyphenoxy)Pyrimidin-4-Yl]benzenesulfonamide",
      "Benzenesulfonamide,4-(1,1-Dimethylethyl)-N-[6-(2-Hydroxyethoxy)-5-(3-Methoxyphenoxy)-4-Pyrimidinyl]-",
      "4-Tert-Butyl-N-{6-(2-Hydroxyethoxy)-5-(3-Methoxyphenoxy)Pyrimidin-4-Yl}benzenesulfonamide",
      "ZNXOKLWCOWOECF-UHFFFAOYSA-N",
      "AC1L3TVG",
      "ACMC-20n61p",
      "SCHEMBL2678965",
      "KS-00001DFD",
      "CTK4C6747",
      "DTXSID80164598",
      "ZINC1535471",
      "BCP17756",
      "BDBM50105015",
      "AKOS030526571",
      "Ro 462005",
    ],
  },
  {
    primary_id: "D0J5IB",
    names: ["Pyrrolidine Derivative 11"],
  },
  {
    primary_id: "D0J5IR",
    names: ["Tyr-Pro-Phe-D-Val-Bn", "CHEMBL577746"],
  },
  {
    primary_id: "D0J5JO",
    names: ["1-Benzhydryl-4-M-Tolylpiperidin-4-Ol", "CHEMBL437380"],
  },
  {
    primary_id: "D0J5KA",
    names: ["N-(2,4-Dimethoxyphenethyl)Docos-13-Enamide", "CHEMBL200121"],
  },
  {
    primary_id: "D0J5KF",
    names: [
      "Granisetron",
      "BRL-43694",
      "Kytril (TN)",
      "Granisetron (USAN/INN)",
      "1-Methyl-N-[(1S,5R)-9-Methyl-9-Azabicyclo[3.3.1]nonan-3-Yl]indazole-3-Carboxamide",
      "1-Methyl-N-[(3-Endo)-9-Methyl-9-Azabicyclo[3.3.1]non-3-Yl]-1H-Indazole-3-Carboxamide",
    ],
  },
  {
    primary_id: "D0J5KG",
    names: ["Methyl 3beta-Hydroxyolean-12-En-28-Oate", "CHEMBL381707"],
  },
  {
    primary_id: "D0J5KK",
    names: [
      "1-Adamantan-1-Yl-3-Piperidin-4-Yl-Urea",
      "ASN 06482309",
      "CHEMBL215356",
      "AC1MLKMS",
      "SCHEMBL8234307",
      "BDBM50191891",
      "AKOS000794965",
    ],
  },
  {
    primary_id: "D0J5KZ",
    names: ["OBE-102"],
  },
  {
    primary_id: "D0J5NA",
    names: ["INO-3106"],
  },
  {
    primary_id: "D0J5NB",
    names: ["Pyrazole Derivative 25"],
  },
  {
    primary_id: "D0J5OO",
    names: ["Pyrrolidinyl Urea Derivative 6"],
  },
  {
    primary_id: "D0J5OS",
    names: ["Vasonex", "CGRP, Vasogenix"],
  },
  {
    primary_id: "D0J5OV",
    names: [
      "Chebulagic Acid",
      "23094-71-5",
      "NSC 342674",
      "AC1L2HS4",
      "Beta-D-Glucopyranose, Cyclic 3,6-(4,4',5,5',6,6'-Hexahydroxy(1,1'-Biphenyl)-2,2'-Dicarboxylate) 1-(3,4,5-Trihydroxybenzoate), Cyclic 2-5:4-1-Ester With (5-Carboxy-3,4-Dihydro-3,7,8-Trihydroxy-2-Oxo-2H-1-Benzopyran-4-Yl)Butanedioic Acid, Stereoisomer",
    ],
  },
  {
    primary_id: "D0J5OX",
    names: ["3-(4-Chloro-2-Cyclohexylphenoxy)Propanoic Acid", "CHEMBL247544", "CTK7J4510", "BDBM50213910"],
  },
  {
    primary_id: "D0J5OY",
    names: ["Des-AA1,2,4,5-[D-Trp8]SRIF", "CHEMBL436962"],
  },
  {
    primary_id: "D0J5PL",
    names: ["ISIS 134528"],
  },
  {
    primary_id: "D0J5PT",
    names: ["AGT-182"],
  },
  {
    primary_id: "D0J5QA",
    names: ["GRNCM-1"],
  },
  {
    primary_id: "D0J5QD",
    names: ["GL-4001"],
  },
  {
    primary_id: "D0J5QP",
    names: ["Triazole Derivative 6"],
  },
  {
    primary_id: "D0J5QW",
    names: [
      "ON-579",
      "141286-14-8",
      "ON 579",
      "2-[4-[2-[(4-Chlorophenyl)Sulfonylamino]ethylsulfanyl]-2,6-Difluorophenoxy]acetic Acid",
      "Acetic Acid,2-[4-[[2-[[(4-Chlorophenyl)Sulfonyl]amino]ethyl]thio]-2,6-Difluorophenoxy]-",
      "ACMC-20cvej",
      "AC1Q6UCK",
      "AC1L4U4O",
      "{4-[(2-{[(4-Chlorophenyl)Sulfonyl]amino}ethyl)Sulfanyl]-2,6-Difluorophenoxy}acetic Acid",
      "CHEMBL312763",
      "SCHEMBL2925987",
      "CTK4C2555",
      "DTXSID70161657",
      "AKOS030574939",
      "4-(2-(4-Chlorophenylsulfonylamino)-Ethylthio)-2,6-Difluorophenoxyacetic Acid",
      "KB-227077",
    ],
  },
  {
    primary_id: "D0J5RG",
    names: ["Alpha-Oxoadipic Acid", "Alpha-Ketoadipic Acid", "2-Ketoadipate", "2-Oxoadipate"],
  },
  {
    primary_id: "D0J5RN",
    names: ["Armodafinil"],
  },
  {
    primary_id: "D0J5RQ",
    names: [
      "3-Acetyl-11-Keto-Ursolic Acid",
      "11-OXOURSOLIC ACID ACETATE",
      "CHEMBL601891",
      "NSC33403",
      "AC1L5RI4",
      "3-O-Acetyl-11-Oxoursolic Acid",
      "MolPort-044-649-591",
      "NSC-33403",
      "BDBM50307109",
      "AKOS016036264",
      "35959-01-4",
      "(1S,2R,4aS,6aR,6aS,6bR,8aR,10S,12aS,14bS)-10-Acetyloxy-1,2,6a,6b,9,9,12a-Heptamethyl-13-Oxo-1,2,3,4,5,6,6a,7,8,8a,10,11,12,14b-Tetradecahydropicene-4a-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0J5SD",
    names: ["Gamma-Secretase Modulators"],
  },
  {
    primary_id: "D0J5SK",
    names: [
      "6-Chloro-N-(3-Chlorophenyl)Quinazolin-4-Amine",
      "4-Anilidoquinazoline Deriv. 12a",
      "AC1NS5EY",
      "SCHEMBL4355658",
      "BDBM4397",
    ],
  },
  {
    primary_id: "D0J5TL",
    names: [
      "4-Ethyl-5-Piperidin-4-Yl-Isoxazol-3-Ol",
      "CHEMBL143993",
      "439944-80-6",
      "3(2H)-Isoxazolone, 4-Ethyl-5-(4-Piperidinyl)-",
      "CHEMBL543365",
      "CTK1C8002",
      "DTXSID50445689",
      "BDBM50113809",
      "AKOS030538897",
      "AKOS015904779",
      "4-Ethyl-5-(4-Piperidinyl)-3-Isoxazolol",
      "4-Ethyl-5-(4-Piperidinyl)Isoxazole-3-Ol",
      "I14-23452",
    ],
  },
  {
    primary_id: "D0J5TN",
    names: ["CEP-751"],
  },
  {
    primary_id: "D0J5TS",
    names: [
      "Carzinophilin",
      "CARZINOPHILIN",
      "Cardinophyllin",
      "Cardinophillin",
      "Azinomycin",
      "CARZINOPHILIN A",
      "Carzinophyllin",
      "NSC 20088",
      "Antibiotic From Streptomyces Sahachiroi",
      "BRN 5721373",
      "C31H33N3O11",
      "1403-28-7",
      "Carzinophilin (8CI)",
      "AC1MHU1X",
      "Carzinophilin (8CI)(9CI)",
      "LS-52587",
      "LS-52588",
      "11002-20-3",
      "[(1S)-2-[[(1E)-1-[(3R,4R,5S)-3-Acetyloxy-4-Hydroxy-1-Azabicyclo[3.1.0]hexan-2-Ylidene]-2-[[(E)-1-Hydroxy-3-Oxobut-1-En-2-Yl]amino]-2-Oxoethyl]amino]-1-[(2S)-2-Methyloxiran-2-Yl]-2-Oxoethyl] 3-Methoxy-5-Methylnaphthalene-1-Carboxylate",
    ],
  },
  {
    primary_id: "D0J5UC",
    names: [
      "SDZ-ISQ-844",
      "143526-87-8",
      "Sdz Isq 844",
      "Sdz-Isq-844",
      "SDZ ISQ-844",
      "SCHEMBL7298437",
      "AC1L317P",
      "CTK4C3695",
      "3-Isoquinolinemethanol,1-(3,5-Dimethoxyphenyl)-3,4-Dihydro-6,7-Dimethoxy-, (3S)-",
      "AKOS030566039",
      "6,7-Dimethoxy-1-(3,4-Dimethoxyphenyl)-3-Hydroxymethyl-3,4-Dihydroisoquinoline",
      "(S)-1-(3,5-Dimethoxyphenyl)-3,4-Dihydro-6,7-Dimethoxy-3-Isoquinolinemethanol",
      "3-Isoquinolinemethanol, 1-(3,5-Dimethoxyphenyl)-3,4-Dihydro-6,7-Dimethoxy-, (S)-",
    ],
  },
  {
    primary_id: "D0J5UK",
    names: ["IRT-102"],
  },
  {
    primary_id: "D0J5VK",
    names: [
      "NSC-622445",
      "5,5'-Methylenedisalicylic Acid",
      "122-25-8",
      "5,5'-Methylenebis(2-Hydroxybenzoic Acid)",
      "Methylenebis(Salicylic Acid)",
      "5,5-Methylenebis(Salicylic Acid)",
      "UNII-2KF4FVV76N",
      "5,5-Methylenedisalicylic Acid",
      "5-(3-Carboxy-4-Hydroxybenzyl)Salicylic Acid",
      "4,4'-Dihydroxy-3,3'-Dicarboxydiphenylmethane",
      "3,3'-Dicarboxy-4,4'-Dihydroxydiphenylmethane",
      "NSC 14778",
      "2KF4FVV76N",
      "4,4'-Dihydroxydiphenylmethane-3,3'-Dicarboxylic Acid",
      "3,3'-Methylenebis(6-Hydroxybenzoic Acid)",
      "CHEMBL115145",
      "Benzoic Acid, 3,3'-Methylenebis[6-Hydroxy-",
    ],
  },
  {
    primary_id: "D0J5VR",
    names: ["Idelalisib"],
  },
  {
    primary_id: "D0J5WI",
    names: ["FK-518"],
  },
  {
    primary_id: "D0J5XM",
    names: ["Pyrido[2,3-D]pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0J5XS",
    names: ["TV-1380"],
  },
  {
    primary_id: "D0J5XZ",
    names: ["VP004"],
  },
  {
    primary_id: "D0J5YC",
    names: ["Fosphenytoin"],
  },
  {
    primary_id: "D0J5YU",
    names: ["TSR-022"],
  },
  {
    primary_id: "D0J6AB",
    names: [
      "MK-5108",
      "1010085-13-8",
      "VX-689",
      "MK 5108",
      "MK5108",
      "MK-5108 (VX-689)",
      "UNII-H8J407531S",
      "VX689",
      "VX 689",
      "CHEMBL3600873",
      "H8J407531S",
      "4-(3-Chloro-2-Fluorophenoxy)-1-[[6-(1,3-Thiazol-2-Ylamino)Pyridin-2-Yl]methyl]cyclohexane-1-Carboxylic Acid",
      "4-(3-Chloranyl-2-Fluoranyl-Phenoxy)-1-[[6-(1,3-Thiazol-2-Ylamino)Pyridin-2-Yl]methyl]cyclohexane-1-Carboxylic Acid",
      "(1r,4r)-4-(3-Chloro-2-Fluorophenoxy)-1-((6-(Thiazol-2-Ylamino)Pyridin-2-Yl)Methyl)Cyclohexane-1-Carboxylic Acid",
      "Cyclohexanecarboxylic Acid,",
      "MK-5108/VX-689",
    ],
  },
  {
    primary_id: "D0J6AE",
    names: ["CYAD-01"],
  },
  {
    primary_id: "D0J6AJ",
    names: ["JTCR016"],
  },
  {
    primary_id: "D0J6AU",
    names: ["Odanacatib"],
  },
  {
    primary_id: "D0J6BR",
    names: ["Pentastarch"],
  },
  {
    primary_id: "D0J6DQ",
    names: ["Tremelimumab"],
  },
  {
    primary_id: "D0J6DS",
    names: ["Thiazolidine Derivative 2"],
  },
  {
    primary_id: "D0J6DU",
    names: ["VLP-Based Influenza Vaccine"],
  },
  {
    primary_id: "D0J6EQ",
    names: ["M-512101"],
  },
  {
    primary_id: "D0J6FE",
    names: ["AVI-4557"],
  },
  {
    primary_id: "D0J6FF",
    names: ["PENNVAX-GP/MVA-CMDR HIV Vaccine Regime"],
  },
  {
    primary_id: "D0J6GO",
    names: ["NRP409"],
  },
  {
    primary_id: "D0J6HU",
    names: ["Sylvantsiltuximab"],
  },
  {
    primary_id: "D0J6IE",
    names: ["2-(2-Chlorophenylamino)-5-Methylthiazol-4(5H)-One"],
  },
  {
    primary_id: "D0J6II",
    names: [
      "Azimilide",
      "Azimilide [INN:BAN]",
      "1-({[5-(4-Chlorophenyl)Furan-2-Yl]methylidene}amino)-3-[4-(4-Methylpiperazin-1-Yl)Butyl]imidazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0J6JC",
    names: ["PTL-101"],
  },
  {
    primary_id: "D0J6JT",
    names: ["M-Chlorophenylpiperazine", "1-(M-Chlorophenyl)Piperazine", "Meta Chlorophenyl Piperazine"],
  },
  {
    primary_id: "D0J6JZ",
    names: ["MINALRESTAT"],
  },
  {
    primary_id: "D0J6KJ",
    names: [
      "1-Deoxy-1-Methoxycarbamido-Beta-D-Glucopyranose",
      "1-DEOXY-1-METHOXYCARBAMIDO-BETA-D-GLUCOPYRANOSE",
      "CHEMBL134529",
      "CR1",
      "AC1L9IGO",
      "Carbamic Acid, N-Beta-D-Glucopyranosyl-, Methyl Ester",
      "BDBM50363873",
      "DB03657",
      "188679-87-0",
      "N-(Methoxycarbonyl)-Beta-D-Glucopyranosylamine",
      "Methyl N-[(2R,3R,4S,5S,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]carbamate",
    ],
  },
  {
    primary_id: "D0J6KN",
    names: ["4-(7-Methyl-1-Propyl-1H-Indazol-3-Yl)Phenol"],
  },
  {
    primary_id: "D0J6KS",
    names: ["1,2,4-Triazole [4,3-A]quinoxaline Derivative 3"],
  },
  {
    primary_id: "D0J6KZ",
    names: ["S-777469"],
  },
  {
    primary_id: "D0J6LN",
    names: ["Etazolate"],
  },
  {
    primary_id: "D0J6LP",
    names: ["Colchiceine Amide"],
  },
  {
    primary_id: "D0J6LT",
    names: ["PMID29865878-Compound-57"],
  },
  {
    primary_id: "D0J6LU",
    names: ["Cryopreserved Human Liver Cells"],
  },
  {
    primary_id: "D0J6ME",
    names: ["2-Ethyl-4-(Pyridin-2-Yl)Thieno[3,2-D]pyrimidine", "CHEMBL259337", "SCHEMBL3684919"],
  },
  {
    primary_id: "D0J6MM",
    names: ["PMID25522065-Compound-37"],
  },
  {
    primary_id: "D0J6MS",
    names: ["BIBU-251"],
  },
  {
    primary_id: "D0J6NG",
    names: ["OPA-6566"],
  },
  {
    primary_id: "D0J6NI",
    names: ["4-((1H-Imidazol-4-Yl)Methyl)-1-Phenylpiperidine", "CHEMBL1171443"],
  },
  {
    primary_id: "D0J6NV",
    names: ["GI-5005"],
  },
  {
    primary_id: "D0J6NW",
    names: ["Shigella Vaccine"],
  },
  {
    primary_id: "D0J6OC",
    names: ["PMID25468267-Compound-52"],
  },
  {
    primary_id: "D0J6OE",
    names: ["Serine Protease Inhibitor Kazal Type 1 (SPINK1)"],
  },
  {
    primary_id: "D0J6OH",
    names: ["Oblinutuzumab"],
  },
  {
    primary_id: "D0J6OV",
    names: ["(R)-PFI-2"],
  },
  {
    primary_id: "D0J6PQ",
    names: ["6-Furan-2-Yl-9-Phenyl-9H-Purin-2-Ylamine", "CHEMBL439336"],
  },
  {
    primary_id: "D0J6PR",
    names: [
      "AC-100",
      "Bicyclo[4.2.0]octa-1,3,5-Trien-3-Ylboronic Acid",
      "BENZOCYCLOBUTANE-4-BORONIC ACID",
      "Boronic Acid, Bicyclo[4.2.0]octa-1,3,5-Trien-3-Yl-",
      "SCHEMBL12759201",
      "KS-00000SRU",
      "MolPort-005-942-889",
      "ZINC196051840",
      "AKOS025146995",
      "DS-8536",
      "BC225113",
      "AK164312",
      "1,2-Dihydrobenzocyclobutene-4-Ylboranic Acid",
      "CS-0043999",
      "J3.522.588I",
      "4-Bbcb                                             Benzocyclobutene-4-Boronic Acid",
    ],
  },
  {
    primary_id: "D0J6PV",
    names: [
      "CGP 47656",
      "(3-Amino-Propyl)-Difluoromethyl-Phosphinic Acid",
      "CGP-47656",
      "CHEMBL113553",
      "3-Aminopropyl-(Difluoromethyl)Phosphinic Acid",
      "CGP47656",
      "SCHEMBL342825",
      "GTPL1066",
      "TXAHGWWWANKBDA-UHFFFAOYSA-N",
      "BDBM50032968",
      "P-(3-Aminopropyl)-P-Difluoromethyl-Phosphinic",
    ],
  },
  {
    primary_id: "D0J6PY",
    names: ["Taxol/Paraplatin/Herceptin"],
  },
  {
    primary_id: "D0J6QC",
    names: ["PP1-Pro-Phe-N-MeHis-LVA-Ile-Amp-(O)"],
  },
  {
    primary_id: "D0J6RQ",
    names: [
      "1,5-Bis(4-Hydroxyphenyl)Penta-1,4-Dien-3-One",
      "3654-49-7",
      "(1E,4E)-1,5-Bis(4-Hydroxyphenyl)Penta-1,4-Dien-3-One",
      "Bis-1,5-(4-Hydroxyphenyl)-1,4-Pentadien-3-One",
      "1,5-Bis-(4-Hydroxyphenyl)-1,4-Pentadien-3-One",
      "CHEMBL129134",
      "EINECS 222-896-4",
      "AC1O5NFJ",
      "1,5-Di(P-Hydroxyphenyl)-1,4-Pentadien-3-One",
      "1,5-BIS(4-HYDROXYPHENYL)-1,4-PENTADIEN-3-ONE",
      "4,4'-Dihydroxydistyrylketon",
      "ZINC6092599",
      "BDBM50067044",
      "AKOS015962226",
      "CD-1056",
      "M064",
      "CC-03405",
      "AC-16155",
      "A823289",
      "C-35230",
      "654B497",
      "1,5-Bis-(4-Hydroxy-Phenyl)-Penta-1,4-Dien-3-One",
      "W-106603",
    ],
  },
  {
    primary_id: "D0J6RV",
    names: ["BAY1161909"],
  },
  {
    primary_id: "D0J6SP",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp28-]", "CHEMBL270683"],
  },
  {
    primary_id: "D0J6SX",
    names: ["(L)-Phe-(D)-Phe-NH2", "CHEMBL590275", "AC1OEWE6", "BDBM50308388"],
  },
  {
    primary_id: "D0J6TA",
    names: ["Piperazine Carbamic Compound 1"],
  },
  {
    primary_id: "D0J6TN",
    names: ["2085-P"],
  },
  {
    primary_id: "D0J6TU",
    names: ["5-(4-Chlorophenyl)-4-(4-Methoxyphenyl)Isothiazole", "CHEMBL491089"],
  },
  {
    primary_id: "D0J6UA",
    names: ["CTP-499"],
  },
  {
    primary_id: "D0J6UH",
    names: [
      "E339-3D6",
      "GTPL6187",
      "4-{[(1-{[2-(1-Benzyl-3-Methyl-1H-Imidazol-3-Ium-4-Yl)-1-[(1-Methylpiperidin-1-Ium-4-Yl)Carbamoyl]ethyl]carbamoyl}-5-({[12-({4-[6-(Diethylamino)-3-(Diethyliminiumyl)-3H-Xanthen-9-Yl]-3-Sulfobenzene}sulfonamido)Dodecyl]carbamoyl}amino)Pentyl)Carbamoyl]methyl}-2-Imino-3-Methyl-2,3-Dihydro-1,3-Thiazol-3-Ium Tritrifluoroacetate",
    ],
  },
  {
    primary_id: "D0J6UV",
    names: ["IBI-20089"],
  },
  {
    primary_id: "D0J6UW",
    names: ["Maleimides Derivative 3"],
  },
  {
    primary_id: "D0J6VE",
    names: ["Ad-IFNg", "Adeno Interferon Gamma"],
  },
  {
    primary_id: "D0J6VN",
    names: ["Indol-3-Carbinol"],
  },
  {
    primary_id: "D0J6VT",
    names: ["Vaccine, Influenza Virus Infection, VLP Vaccine Technology"],
  },
  {
    primary_id: "D0J6VU",
    names: ["CVS-995", "Corthrombin Injectable"],
  },
  {
    primary_id: "D0J6VW",
    names: ["CUV-9900"],
  },
  {
    primary_id: "D0J6WA",
    names: ["HS-10296"],
  },
  {
    primary_id: "D0J6WE",
    names: ["CD19-CAR-T Cells"],
  },
  {
    primary_id: "D0J6WG",
    names: ["(O10eq)-Guanidino Acetylryanodine", "CHEMBL435362"],
  },
  {
    primary_id: "D0J6WJ",
    names: ["Modified Vitamin A"],
  },
  {
    primary_id: "D0J6WM",
    names: ["10-OBn-Ginkgolide C", "10-OBn-GC"],
  },
  {
    primary_id: "D0J6WV",
    names: ["WRR605"],
  },
  {
    primary_id: "D0J6WW",
    names: ["Proquazone"],
  },
  {
    primary_id: "D0J6WX",
    names: ["Flutropium Bromide"],
  },
  {
    primary_id: "D0J6XC",
    names: ["Tiratricol"],
  },
  {
    primary_id: "D0J6XI",
    names: [
      "PMID25408830C2",
      "AC1LNW3E",
      "MLS000663364",
      "GTPL7810",
      "MolPort-002-097-893",
      "HMS2628P17",
      "HMS3438L21",
      "ZINC1063855",
      "BDBM50032921",
      "STL431755",
      "AKOS000445117",
      "MCULE-6603148725",
      "CCG-129099",
      "SMR000300929",
      "SR-01000295962",
      "SR-01000295962-1",
    ],
  },
  {
    primary_id: "D0J6XO",
    names: [
      "A-331440",
      "UNII-45WZM2FLYQ",
      "392338-13-5",
      "A 331440",
      "45WZM2FLYQ",
      "CHEMBL319000",
      "GTPL4023",
      "SCHEMBL5216650",
      "ZINC22452295",
      "BDBM50240709",
      "4-[4-[3-[(3R)-3-Dimethylaminopyrrolidin-1-Yl]propoxy]phenyl]benzonitrile",
      "4'-(3-((3R)-3-(Dimethylamino)Pyrrolidinyl)Propoxy)(1,1'-Biphenyl)-4-Carbonitrile",
      "(1,1'-Biphenyl)-4-Carbonitrile, 4'-(3-((3R)-3-(Dimethylamino)-1-Pyrrolidinyl)Propoxy)-",
      "KB-74351",
      "A331440",
      "4''-[3-((R)-3-Dimethylamino-Pyrrolidin-1-Yl)-Propoxy]-Biphenyl-4-Carbonitrile",
    ],
  },
  {
    primary_id: "D0J6YZ",
    names: ["Very Small Embryonic-Like Stem Cells"],
  },
  {
    primary_id: "D0J6ZA",
    names: [
      "SRD-441",
      "Aureostat",
      "ST-441",
      "STP-01a",
      "Protease Inhibitor (Atopic Dermatitis), Surface Therapeutics",
    ],
  },
  {
    primary_id: "D0J7AI",
    names: ["BN-80245", "DU-1441", "DU-1442"],
  },
  {
    primary_id: "D0J7AM",
    names: [
      "4-Bromo-N-Hydroxy-Benzamide",
      "4-Bromo-N-Hydroxybenzamide",
      "1836-27-7",
      "CHEMBL351337",
      "4-Bromobenzenecarbohydroxamic Acid",
      "P-Bromobenzohydroxamic Acid",
      "AC1LCOKZ",
      "N-Hydroxy-4-Bromobenzamide",
      "P-Bromophenylhydroxamic Acid",
      "4-Bromo-N-Hydroxybenzamide #",
      "SCHEMBL8575405",
      "Benzamide, 4-Bromo-N-Hydroxy-",
      "CTK0E2598",
      "DTXSID20345349",
      "VESPFSJNQMGUCQ-UHFFFAOYSA-N",
      "MolPort-002-985-506",
      "ZINC5249551",
      "STK249669",
      "BDBM50015106",
      "AKOS000175576",
      "MCULE-4510290140",
      "DA-16649",
      "SC-53612",
      "KB-290158",
      "ST51029575",
      "FT-0708429",
    ],
  },
  {
    primary_id: "D0J7BH",
    names: [
      "4-Morpholin-4-Yl-Chromen-2-One",
      "4-Morpholin-4-Ylchromen-2-One",
      "CHEMBL367262",
      "65625-83-4",
      "4-Morpholinyl Coumarin",
      "SMR000116476",
      "AC1LFJN6",
      "CBMicro_022351",
      "Oprea1_628619",
      "Oprea1_860966",
      "MLS000526002",
      "SCHEMBL11450752",
      "4-Morpholino-2H-Chromen-2-One",
      "CTK1I2254",
      "DTXSID30355510",
      "MolPort-001-959-554",
      "ZINC312514",
      "HMS2490I17",
      "CCG-9410",
      "BDBM50159613",
      "AKOS000639877",
      "4-(4-Morpholinyl)-2H-Chromen-2-One",
      "MCULE-8859511318",
      "BAS 01256112",
      "BIM-0022285.P001",
      "ST50008215",
      "2H-1-Benzopyran-2-One, 4-(4-Morpholinyl)-",
      "SR-01000312410",
      "AI-204/31682008",
    ],
  },
  {
    primary_id: "D0J7CD",
    names: ["Poly IC-Poly Arginine"],
  },
  {
    primary_id: "D0J7CE",
    names: ["CDE-6960"],
  },
  {
    primary_id: "D0J7CH",
    names: ["QGE-031"],
  },
  {
    primary_id: "D0J7CK",
    names: ["CSNLSTCVLGKLSQELc[DKLQK]YPRTNTGSGTP-Amide"],
  },
  {
    primary_id: "D0J7CP",
    names: ["OctreoScan"],
  },
  {
    primary_id: "D0J7CR",
    names: [
      "Rac-Cis-N-Arachidonoylcyclopropanolamide",
      "Cyclopropanolamide, 13a",
      "Cyclopropanolamide, Rac-10a",
      "CHEMBL474488",
      "BDBM29078",
    ],
  },
  {
    primary_id: "D0J7DA",
    names: ["PMID28594589-Compound-TABLE3C12"],
  },
  {
    primary_id: "D0J7DN",
    names: ["F-G-G-F-T-G-A-R-K-S-A-R-K-L-Aib-N-Q-CONH2"],
  },
  {
    primary_id: "D0J7EH",
    names: [
      "ACCLAIM",
      "Depon",
      "Excel",
      "Furore",
      "PUMA",
      "Whip",
      "Fenoxaprop Ethyl",
      "Fenoxaprop Ethyl Ester",
      "Fenoxaprop Ethyl Ester [ANSI]",
      "Tiller Herbicide",
      "HOE 33171",
      "FENOXAPROP-ETHYL",
      "HOE-A 25-01",
      "Ethyl 2-(4-((6-Chlorobenzoxazol-2-Yl)Oxy)Phenoxy)Propionate",
      "Ethyl 2-[4-[(6-Chloro-1,3-Benzoxazol-2-Yl)Oxy]phenoxy]propanoate",
      "Ethyl (D+)-2-(4-(6-Chlor-2-Benzoxazolyloxy)Phenoxy)Propanoate",
      "Ethyl (D+)-2-(4-(6-Chlor-2-Benzoxazolyloxy)Phenoxy)Propanoate [French]",
      "Ethyl 2-(4-[(6-Chloro-1,3-Benzoxazol-2-Yl)Oxy]phenoxy)Propanoate",
      "Ethyl 2-{4-[(6-Chloro-1,3-Benzoxazol-2-Yl)Oxy]phenoxy}propanoate",
      "Ethyl-2-((4-(6-Chloro-2-Benzoxazolyloxy))-Phenoxy)Propionate",
      "Ethyl (+-)-2-(4-(6-Chloro-2-Benzoxazolyloxy)Phenoxy)Propanoate",
      "Propanoic Acid, 2-(4-((6-Chloro-2-Benzoxazolyl)Oxy)Phenoxy)-, Ethyl Ester",
      "(+/-)-2-(4-((6-Chloro-2-Benzoxazolyl)Oxy)Phenoxy)Propanoic Acid Ethyl Ester",
      "2-(4-((6-Chloro-2-Benzoxazolylen)Oxy)Phenoxy)Propanoic Acid, Ethyl Ester",
    ],
  },
  {
    primary_id: "D0J7EL",
    names: [
      "3-Nitrophenylboronic Acid",
      "13331-27-6",
      "(3-Nitrophenyl)Boronic Acid",
      "3-Nitrobenzeneboronic Acid",
      "M-Nitrophenylboronic Acid",
      "Boronic Acid, (3-Nitrophenyl)-",
      "Benzeneboronic Acid, M-Nitro-",
      "M-Nitrobenzeneboronic Acid",
      "3-Nitrophenyl Boronic Acid",
      "Boronic Acid, B-(3-Nitrophenyl)-",
      "M-Nitophenyl Boronic Acid",
      "NSC 59739",
      "BRN 2938638",
      "3-Nitro-Phenyl Boronic Acid",
      "CHEMBL20945",
      "AI3-60393",
      "MFCD00007193",
      "WLN: WNR CBQQ",
      "3-Nitrophenylboronic Acid, 97%",
      "Zlchem 233",
      "PubChem6366",
      "AC1L1BZL",
      "(3-Nitrophenyl)Boranediol",
      "3-Nitropenyl Boronic Acid",
    ],
  },
  {
    primary_id: "D0J7EM",
    names: ["1-Phenylthio-N,N-Dimethyltryptamine", "CHEMBL253397"],
  },
  {
    primary_id: "D0J7EN",
    names: ["KR61639"],
  },
  {
    primary_id: "D0J7EP",
    names: ["AVN 397"],
  },
  {
    primary_id: "D0J7EZ",
    names: ["ISIS 134604"],
  },
  {
    primary_id: "D0J7FD",
    names: [
      "BB-78485",
      "(2r)-N-Hydroxy-3-Naphthalen-2-Yl-2-[(Naphthalen-2-Ylsulfonyl)Amino]propanamide",
      "2ves",
      "SCHEMBL5485583",
      "CHEMBL261713",
      "DB07861",
      "(2R)-N-Hydroxy-3-(Naphthalen-2-Yl)-2-(Naphthalene-2-Sulfonamido)Propanamide",
      "(2R)-N-Hydroxy-3-Naphthalen-2-Yl-2-[(Naphthalen-2-Ylsulfonyl)Amino]propanamide (Non-Preferred Name)",
    ],
  },
  {
    primary_id: "D0J7FJ",
    names: ["1-(5-Fluorooxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL373906", "SCHEMBL3145274", "BDBM50374375"],
  },
  {
    primary_id: "D0J7FK",
    names: ["Methyl 6-(3-Cyclohexylureido)Hexanoate"],
  },
  {
    primary_id: "D0J7GF",
    names: [
      "Benzyl-(2-Phenyl-Quinazolin-4-Yl)-Amine",
      "N-Benzyl-2-Phenyl-4-Quinazolinamine",
      "CHEMBL72650",
      "N-Benzyl-2-Phenylquinazolin-4-Amine",
      "AC1LFMYC",
      "BAS 01023367",
      "Oprea1_781281",
      "Oprea1_710967",
      "MLS001210388",
      "SCHEMBL1083763",
      "MolPort-001-952-692",
      "HMS2816J14",
      "KUC104420N",
      "ZINC274067",
      "STL227888",
      "BDBM50033296",
      "KSC-1-145",
      "Benzyl(2-Phenylquinazolin-4-Yl)Amine",
      "AKOS000533392",
      "MCULE-1161339740",
      "SMR000515839",
    ],
  },
  {
    primary_id: "D0J7GJ",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-Phe-Asp-Nle-D-Trp-H", "CHEMBL375412"],
  },
  {
    primary_id: "D0J7GU",
    names: ["4-Methylthiopyridine-3-Sulfonamide", "CHEMBL1165117", "1078160-10-7"],
  },
  {
    primary_id: "D0J7HR",
    names: ["Secondary And Tertiary (Hetero)Arylamide Derivative 1"],
  },
  {
    primary_id: "D0J7HS",
    names: ["AGTAD[CFWKYC]V", "CHEMBL1163463", "9047-55-6", "Urotensin II Mouse", "BDBM50320454"],
  },
  {
    primary_id: "D0J7HV",
    names: ["HER2.taNK"],
  },
  {
    primary_id: "D0J7IV",
    names: ["ISIS 5884"],
  },
  {
    primary_id: "D0J7KJ",
    names: ["6-Acetyl-7-Propoxy-2H-Chromen-2-One", "CHEMBL1288560"],
  },
  {
    primary_id: "D0J7LV",
    names: [
      "VLP Vaccine",
      "ANAVAX",
      "VLP Vaccine (ANAVAX, Influenza)",
      "VLP Vaccine (ANAVAX, H5 Influenza), Select",
      "VLP Vaccine (ANAVAX, M2einfluenza), Select",
      "VLP Vaccine (ANAVAX, Influenza), Select",
      "Virus-Like Particle Vaccine (ANAVAX, Influenza), Select",
    ],
  },
  {
    primary_id: "D0J7LY",
    names: [
      "5-Methyl-5-(5-Methylthiophen-3-Yl)-4-Oxo-4,5-Dihydrofuran-2-Carboxylic Acid",
      "Compound 5aj [PMID: 17358052]",
    ],
  },
  {
    primary_id: "D0J7MD",
    names: [
      "(6-Methoxy-2-Oxo-2H-Quinolin-1-Yl)-Acetic Acid",
      "CHEMBL305164",
      "6-Methoxy-2-Oxo-1,2-Dihydro-1-Quinolineacetic Acid",
      "103368-22-5",
      "SCHEMBL8368861",
      "BDBM50022249",
    ],
  },
  {
    primary_id: "D0J7ML",
    names: ["D-20133"],
  },
  {
    primary_id: "D0J7MM",
    names: ["ADS-103316", "CHEMBL216754", "SCHEMBL6507269", "BDBM50200790"],
  },
  {
    primary_id: "D0J7MQ",
    names: ["MKC-1106-MT"],
  },
  {
    primary_id: "D0J7NR",
    names: [
      "5-Methyl-1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One",
      "CHEMBL319551",
      "2H-Imidazo[4,5-B]quinolin-2-One, 1,3-Dihydro-5-Methyl-",
      "BDBM50229556",
    ],
  },
  {
    primary_id: "D0J7NS",
    names: [
      "4-Methyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL183012",
      "CHEMBL541090",
      "4-Methylpyrrolidine-2-Imine",
      "BDBM50066780",
      "AKOS006351281",
      "4-Methyl-Pyrrolidin-(2E)-Ylideneamine",
    ],
  },
  {
    primary_id: "D0J7OG",
    names: ["Midecamycin"],
  },
  {
    primary_id: "D0J7OM",
    names: ["6-(Benzylamino)-9-Butyl-9H-Purine-2-Carbonitrile", "Compound 3{8,12}", "CHEMBL255079"],
  },
  {
    primary_id: "D0J7OU",
    names: ["C-CAR088"],
  },
  {
    primary_id: "D0J7OZ",
    names: ["EMD-387008"],
  },
  {
    primary_id: "D0J7QC",
    names: ["124I-8H9"],
  },
  {
    primary_id: "D0J7QU",
    names: [
      "E-913",
      "E913",
      "E 913",
      "342394-93-8",
      "1,4,9-Triazaspiro(5.5)Undecane-2,5-Dione, 1-Butyl-3-(Cyclohexylmethyl)-9-((2,3-Dihydro-1,4-Benzodioxin-6-Yl)Methyl)-",
      "11-Butyl-9-(Cyclohexylmethyl)-3-(2,3-Dihydro-1,4-Benzodioxin-6-Ylmethyl)-3,8,11-Triazaspiro[5.5]undecane-7,10-Dione",
      "1,4,9-Triazaspiro[5.5]undecane-2,5-Dione, 1-Butyl-3-(Cyclohexylmethyl)-9-[(2,3-Dihydro-1,4-Benzodioxin-6-Yl)Methyl]-",
      "AC1LANMM",
      "SCHEMBL1649664",
      "GTPL3500",
      "CHEMBL1196395",
      "E9-13",
      "BDBM50190513",
      "LS-184716",
    ],
  },
  {
    primary_id: "D0J7QX",
    names: ["AT-012"],
  },
  {
    primary_id: "D0J7RK",
    names: [
      "Dobutamine",
      "Dobutamina",
      "Dobutaminum",
      "Dobutamine Hcl",
      "Dobutamine [USAN]",
      "Dobutamine Hydrobromide",
      "LY 81929",
      "Lilly 81929",
      "DL-Dobutamine",
      "Dobutamina [INN-Spanish]",
      "Dobutamine Hcl In Dextrose 5%",
      "Dobutamine(Usp)",
      "Dobutaminum [INN-Latin]",
      "Rac-Dobutamine",
      "Racemic-Dobutamine",
      "Dobutamine (USP/INN)",
      "Dobutamine [USAN:BAN:INN]",
      "Dobutamine Tartrate (1:1), (S-(R*,R*))-Isomer",
      "(+-)-4-(2-((3-(P-Hydroxyphenyl)-1-Methylpropyl)Amino)Ethyl)Pyrocatechol",
      "3,4-Dihydroxy-N-[3-(4-Hydroxyphenyl)-1-Methylpropyl]-Beta-Phenylethylamine",
      "4-(2-{[3-(4-Hydroxyphenyl)-1-Methylpropyl]amino}ethyl)Benzene-1,2-Diol",
      "4-(2-{[4-(4-Hydroxyphenyl)Butan-2-Yl]amino}ethyl)Benzene-1,2-Diol",
      "4-[2-[4-(4-Hydroxyphenyl)Butan-2-Ylamino]ethyl]benzene-1,2-Diol",
    ],
  },
  {
    primary_id: "D0J7RP",
    names: ["RP-AAV8"],
  },
  {
    primary_id: "D0J7RW",
    names: ["WIN-55212-2"],
  },
  {
    primary_id: "D0J7SD",
    names: ["Piperazine Derivative 4"],
  },
  {
    primary_id: "D0J7TM",
    names: [
      "Benzylpenicilloyl Polylysine",
      "Benzylpenicilloyl",
      "Benzylpenicilloyl G",
      "Benzylpenicilloyl G Polylysine",
      "Benzylpenicilloyl-Polylysine",
      "Penicilloyl-Polylysine",
      "2-(2-((5-Carboxypentyl)Amino)-2-Oxo-1-((Phenylacetyl)Amino)Ethyl)-5,5-Dimethyl-4-Thiazolidinecarboxylic Acid",
      "2-[2-[(6-Hydroxy-6-Oxohexyl)Amino]-2-Oxo-1-[(2-Phenylacetyl)Amino]ethyl]-5,5-Dimethyl-1,3-Thiazolidine-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0J7UI",
    names: [
      "ICI-198615",
      "Cyclopentyl N-[1-[[4-(Benzenesulfonylcarbamoyl)-2-Methoxyphenyl]methyl]indazol-6-Yl]carbamate",
      "Ici 198615",
      "104448-53-5",
      "CHEMBL22033",
      "Ici-198,615",
      "ZM 198615",
      "ZM-198,615",
      "Cyclopentyl N-[1-({4-[(Benzenesulfonyl)Carbamoyl]-2-Methoxyphenyl}methyl)-1H-Indazol-6-Yl]carbamate",
      "[3H]ICI198615",
      "[3H]-ICI198615",
      "[3H]ICI-198615",
      "[3H]ICI-198,615",
      "AC1L3GQZ",
      "(1-((2-Methoxy-4-(((Phenylsulfonyl)Amino)Carbonyl)Phenyl)Methyl)-1H-Indazol-6-Yl)Carbamic Acid Cyclopentyl Ester",
      "GTPL3414",
      "GTPL3358",
      "SCHEMBL9806720",
      "DTXSID80146490",
      "BDBM50009075",
      "PDSP1_000520",
      "PDSP2_000518",
      "ICI198615",
    ],
  },
  {
    primary_id: "D0J7UL",
    names: ["ALX148"],
  },
  {
    primary_id: "D0J7VA",
    names: ["Isorhamnetin 3-O-Rhamnoside"],
  },
  {
    primary_id: "D0J7VH",
    names: ["4SCAR19 And 4SCAR22"],
  },
  {
    primary_id: "D0J7VL",
    names: ["H-Cxp-Ala-Gly-Phe-Leu-OH", "CHEMBL247163"],
  },
  {
    primary_id: "D0J7WO",
    names: ["BSF-78438"],
  },
  {
    primary_id: "D0J7XB",
    names: ["ENHYDRIN"],
  },
  {
    primary_id: "D0J7XL",
    names: ["Gramicidin D"],
  },
  {
    primary_id: "D0J7XM",
    names: [
      "DELTORPHIN-II",
      "Deltorphin-II",
      "Delt II",
      "Delt-II",
      "(Ala(2))Deltorphin II",
      "Deltorphin II, Ala(2)-",
      "(Ala(2),glu(4))Deltorphin",
      "Deltorphin, Ala(2)-Glu(4)-",
      "Tyr-Ala-Phe-Glu-Val-Val-Gly-NH2",
      "Deltorphin, Alanyl(2)-Glutamyl(4)-",
      "Glycinamide, L-Tyrosyl-D-Alanyl-L-Phenylalanyl-L-Alpha-Glutamyl-L-Valyl-L-Valyl-",
      "AC1L3XB1",
      "LS-72217",
    ],
  },
  {
    primary_id: "D0J7XN",
    names: ["UR-5269"],
  },
  {
    primary_id: "D0J7XZ",
    names: [
      "CP-409069",
      "CHEMBL78625",
      "SCHEMBL3114566",
      "BDBM50113780",
      "(2R,4aS,10aR)-4a-Benzyl-2-(Prop-1-Ynyl)-1,2,3,4,4a,9,10,10a-Octahydrophenanthrene-2,7-Diol",
    ],
  },
  {
    primary_id: "D0J7YE",
    names: ["N-(1H-Indazol-5-Yl)Acetamide"],
  },
  {
    primary_id: "D0J7YU",
    names: [
      "2-Benzyl-1,2-Dihydro-Indazol-3-One",
      "1848-46-0",
      "CHEMBL1984519",
      "NSC34822",
      "2-Benzyl-1H-Indazol-3-One",
      "SCHEMBL9645952",
      "CHEMBL267523",
      "AC1L91I6",
      "CTK4D8835",
      "DTXSID40328538",
      "2-Benzyl-1H-Indazol-3(2H)-One",
      "ZINC1667231",
      "NSC-34822",
      "BDBM50009000",
      "AKOS015963502",
      "FCH2821694",
      "ACM1848460",
      "NCI60_003094",
    ],
  },
  {
    primary_id: "D0J7YV",
    names: ["MANGOSTENONE F", "CHEMBL1224411", "BDBM50325677"],
  },
  {
    primary_id: "D0J7ZK",
    names: ["N-Cyclohexylquinazolin-4-Amine"],
  },
  {
    primary_id: "D0J8AE",
    names: ["OMN-54"],
  },
  {
    primary_id: "D0J8BD",
    names: ["PMID2909732C7"],
  },
  {
    primary_id: "D0J8BO",
    names: ["Tu-514"],
  },
  {
    primary_id: "D0J8BZ",
    names: ["BIIB 2024"],
  },
  {
    primary_id: "D0J8DC",
    names: ["1-(2-(Naphthalen-1-Yl)-1-Phenylethyl)Piperazine", "CHEMBL212878"],
  },
  {
    primary_id: "D0J8DD",
    names: ["TAK-653"],
  },
  {
    primary_id: "D0J8DR",
    names: ["PMID25991433-Compound-G1"],
  },
  {
    primary_id: "D0J8EC",
    names: ["ACT-280778"],
  },
  {
    primary_id: "D0J8ED",
    names: ["JP-1201"],
  },
  {
    primary_id: "D0J8FE",
    names: ["CDK2 Inhibitors", "CDK2 Inhibitors (Cancer), Hoffmann-La Roche"],
  },
  {
    primary_id: "D0J8FS",
    names: [
      "3-Nitro-4'-Nitro-Trans-Stilbene",
      "(E)-3,4'-Dinitrostilbene",
      "3,4'-Dinitrostilbene",
      "CHEMBL1173131",
      "(E)-1-Nitro-3-(2-(4-Nitrophenyl)Ethenyl)Benzene",
      "51042-51-4",
      "Benzene, 1-Nitro-3-(2-(4-Nitrophenyl)Ethenyl)-, (E)-",
      "Stilbene, 3,4'-Dinitro-",
      "CCRIS 8545",
      "AC1NTBW4",
      "Benzene, 1-Nitro-3-(2-(4-Nitrophenyl)Ethenyl)-",
      "WMOGPQFWQAXCTI-SNAWJCMRSA-N",
      "3-Nitro-4''-Nitro-Trans-Stilbene",
      "ZINC6092536",
      "BDBM50322057",
      "27892-99-5",
      "LS-30911",
      "1-Nitro-3-[2-[4-Nitrophenyl]ethenyl]benzene",
      "1-Nitro-3-[(E)-2-(4-Nitrophenyl)Ethenyl]benzene",
      "1-Nitro-3-[(E)-2-(4-Nitrophenyl)Ethenyl]benzene #",
    ],
  },
  {
    primary_id: "D0J8GO",
    names: ["Aminoazetidine Derivative 4"],
  },
  {
    primary_id: "D0J8HJ",
    names: ["PMID26651364-Compound-127"],
  },
  {
    primary_id: "D0J8IC",
    names: ["SiRNA Nanoparticles, Non-Small-Cell Lung Cancer"],
  },
  {
    primary_id: "D0J8ID",
    names: ["5-HT4/D2 Antagonists"],
  },
  {
    primary_id: "D0J8II",
    names: [
      "Corticosteroid",
      "AU-015",
      "Corticosteroid (Controlled Release, Gastrointestinal Diseases)",
      "Corticosteroid (Eudracol, Gastrointestinal Diseases), Auriga Laboratories",
      "Corticosteroid (Controlled Release, Gastrointestinal Diseases), Auriga Laboratories",
    ],
  },
  {
    primary_id: "D0J8IJ",
    names: ["Eliglustat Tartrate"],
  },
  {
    primary_id: "D0J8JF",
    names: ["RG-4934"],
  },
  {
    primary_id: "D0J8JP",
    names: ["Pimavanserin"],
  },
  {
    primary_id: "D0J8KR",
    names: ["ISOPENTENYL PYROPHOSPHATE"],
  },
  {
    primary_id: "D0J8KW",
    names: ["SR144528"],
  },
  {
    primary_id: "D0J8LQ",
    names: [
      "(3-Bromophenyl)(10H-Phenothiazin-10-Yl)Methanone",
      "CHEMBL239616",
      "(3-Bromo-Phenyl)-Phenothiazin-10-Yl-Methanone",
      "AC1LCW7V",
      "ChemDiv1_023786",
      "C19H12BrNOS",
      "HMS654J04",
      "XWGLMCFWNRNHDU-UHFFFAOYSA-N",
      "MolPort-002-545-921",
      "10-(3-Bromobenzoyl)Phenothiazine",
      "ZINC4785817",
      "STL361327",
      "BDBM50219221",
      "AKOS001639315",
      "MCULE-3829813488",
      "BAS 00721881",
      "10-(3-Bromobenzoyl)-10H-Phenothiazine #",
      "(3-Bromophenyl)-Phenothiazin-10-Ylmethanone",
      "EU-0008504",
      "SR-01000465493",
      "SR-01000465493-1",
    ],
  },
  {
    primary_id: "D0J8LU",
    names: ["4-Methyl-1,1':4',1''-Terphenyl-3,4''-Diol", "CHEMBL568945", "BDBM50299638"],
  },
  {
    primary_id: "D0J8MA",
    names: ["KA2237"],
  },
  {
    primary_id: "D0J8NC",
    names: [
      "ASP-8370",
      "AS-1725195",
      "AS-1928370",
      "TRPV1 Antagonists (Neuropathic Pain)",
      "TRPV1 Antagonists (Neuropathic Pain), Astellas",
    ],
  },
  {
    primary_id: "D0J8OC",
    names: [
      "Scriptaid",
      "CGK1026",
      "IN1099",
      "SB-556629",
      "GNF-PF-2024",
      "N-Hydroxy-1,3-Dioxo-1H-Benz(De)Isoquinoline-2(3H)-Hexan Amide",
      "6-(1,3-Dioxo-1H,3H-Benzo[de]isoquinolin-2-Yl)-Hexanoic Acid Hydroxyamide",
      "6-(1,3-Dioxo-1H-Benzo[de]isoquinolin-2(3H)-Yl)-N-Hydroxyhexanamide",
      "6-(1,3-Dioxobenzo[de]isoquinolin-2-Yl)-N-Hydroxyhexanamide",
    ],
  },
  {
    primary_id: "D0J8OW",
    names: [
      "(+)-MCPG",
      "(S)-Mcpg",
      "(S)-MCPG",
      "(+)-Alpha-Methyl-4-Carboxyphenylglycine",
      "(S)-(ALPHA)-METHYL-4-CARBOXYPHENYLGLYCINE",
      "4-[(1S)-1-Amino-1-Carboxyethyl]benzoic Acid",
      "4-(1-AMINO-1-CARBOXY-ETHYL)-BENZOIC ACID",
      "UNII-61F6J48NHR",
      "Alpha-Mcpg",
      "61F6J48NHR",
      "CHEMBL257626",
      "CHEBI:43876",
      "Alpha-Methyl-Cpgly",
      "(S)-A-Methyl-4-Carboxyphenylglycine",
      "(S)-?-Methyl-4-Carboxyphenylglycine",
      "(S)-4-(1-Amino-1-Carboxyethyl)Benzoic Acid",
      "(S)-(+)-Alpha-Methyl-4-Carboxyphenylglycine",
      "MCG",
      "(S)-(+)-Alpha-Amino-4-Carboxy-2-Methylbenzeneacetic",
      "Alpha-Methyl-4-Carboxyphenylglycine",
      "4-(1-Amino-1-Carboxy-Ethyl)-Benzoic Acid",
    ],
  },
  {
    primary_id: "D0J8PI",
    names: [
      "Edotreotide",
      "SMT-487",
      "UNII-U194AS08HZ",
      "204318-14-9",
      "U194AS08HZ",
      "CHEMBL408350",
      "DOTATOC",
      "DOTA-D-PHE-CYS-TYR-D-TRP-LYS-THR-CYS-THR-OL (DISULFIDE BRIDGE: 2-7)",
      "SMT 487",
      "Edotreotide [USAN:INN]",
      "DOTA-TOC Acetate",
      "AC1L4KLV",
      "SCHEMBL1649285",
      "SCHEMBL19712197",
      "RZHKDBRREKOZEW-AAXZNHDCSA-N",
      "BDBM50165171",
      "N-((4,7,10-Tris(Carboxymethyl)-1,4,7,10-Tetraazacycoldodec-1-Yl)Acetyl-D-Phenylalanyl-L-Cysteinyl-L-Tyrosyl-D-Tryprophyl-L-Lysyl-L-Threonyl-N-((1R,2R)-2-Hydroxy-1-(Hydroxymethyl)Propyl)-L-Cysteinamide Cyclic (2-7)-Disulfide",
    ],
  },
  {
    primary_id: "D0J8PJ",
    names: ["PMID25991433-Compound-B3"],
  },
  {
    primary_id: "D0J8PR",
    names: ["LY03003"],
  },
  {
    primary_id: "D0J8PY",
    names: [
      "4-Benzyl-1-(2-Phenoxy-Ethyl)-Piperidine",
      "4-Benzyl-1-(2-Phenoxyethyl)Piperidine",
      "CHEMBL102659",
      "AC1LPA49",
      "SCHEMBL5888854",
      "HRBKFQYSVXXYDE-UHFFFAOYSA-N",
      "ZINC1159765",
    ],
  },
  {
    primary_id: "D0J8QS",
    names: ["WAY-855"],
  },
  {
    primary_id: "D0J8RI",
    names: [
      "(R,S)-1-(5-Fluoro-1H-Indol-1-Yl)Propan-2-Amine",
      "CHEMBL96883",
      "1H-Indole-1-Ethanamine, 5-Fluoro-Alpha-Methyl-",
      "SCHEMBL7994160",
      "BDBM50321881",
      "169674-98-0",
    ],
  },
  {
    primary_id: "D0J8RM",
    names: ["Posatirelin"],
  },
  {
    primary_id: "D0J8RP",
    names: ["3-(2,3-Dimethyl-Phenyl)-1-Propyl-Piperidine", "CHEMBL341825", "BDBM50068421"],
  },
  {
    primary_id: "D0J8RR",
    names: [
      "Dextromethorphan+quinidine",
      "Dextromethorphan Hydrobromide Monohydrate",
      "6700-34-1",
      "Dextromethorphan Hydrobromide",
      "Methorate",
      "Drixoral Cough",
      "PediaCare 1",
      "Dextromethorphan Hydrobromide Hydrate",
      "Dextromethorphan (Hydrobromide Hydrate)",
      "Hold",
      "St. Joseph Cough Syrup",
      "Chloraseptic DM",
      "UNII-9D2RTI9KYH",
      "Romilar",
      "Tylenol Cough Liquid",
      "Endotussin-NN Pediatric",
      "Robitussin Cough Calmers",
      "Robitussin Pediatric Cough",
      "9D2RTI9KYH",
      "Dextromethorphan Hydrobromide OROS Tablets",
      "Drixoral Cough &amp",
      "Sore Throat",
      "Robitussin Maximum Strength Cough",
    ],
  },
  {
    primary_id: "D0J8RS",
    names: ["PD-146176"],
  },
  {
    primary_id: "D0J8RV",
    names: ["SMT-A07"],
  },
  {
    primary_id: "D0J8SE",
    names: ["DFD-07"],
  },
  {
    primary_id: "D0J8SU",
    names: ["Substance P"],
  },
  {
    primary_id: "D0J8TH",
    names: ["CEB-925", "ACAT Inhibitor, AHP"],
  },
  {
    primary_id: "D0J8TY",
    names: [
      "ARACHIDONYL TRIFLUOROMETHYLKETONE",
      "Arachidonyl Trifluoromethyl Ketone",
      "Aacocf3",
      "Arachidonyltrifluoromethane",
      "149301-79-1",
      "Arachidonic Acid Trifluoromethyl Ketone",
      "Arachidonyltrifluoromethyl Ketone",
      "ATFMK",
      "UNII-00XIW1CR0F",
      "C21H31F3O",
      "(6Z,9Z,12Z,15Z)-1,1,1-Trifluorohenicosa-6,9,12,15-Tetraen-2-One",
      "00XIW1CR0F",
      "CHEBI:2341",
      "CHEMBL281211",
      "1,1,1-Trifluoro-6Z,9Z,12Z,15Z-Heneicosatetraen-2-One",
      "6,9,12,15-Heneicosatetraen-2-One, 1,1,1-Trifluoro-, (6Z,9Z,12Z,15Z)-",
      "6,9,12,15-Heneicosatetraen-2-One, 1,1,1-Trifluoro-, (All-Z)-",
      "Arachidonyl Trifluoromethylketone",
    ],
  },
  {
    primary_id: "D0J8UO",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 33"],
  },
  {
    primary_id: "D0J8UV",
    names: ["AC-260584"],
  },
  {
    primary_id: "D0J8VR",
    names: [
      "IRAK4 Inhibitor Rac-45",
      "CHEMBL401633",
      "GTPL8081",
      "SCHEMBL18427753",
      "BDBM50377180",
      "IRAK4 Inhibitor Rac-45 [PMID: 18501603]",
    ],
  },
  {
    primary_id: "D0J8VU",
    names: ["4-(1,2,3-Thiadiazol-4-Yl)Phenyl Hexylcarbamate", "CHEMBL550587"],
  },
  {
    primary_id: "D0J8VV",
    names: ["PRO-055"],
  },
  {
    primary_id: "D0J8WE",
    names: ["CRYPTOPHYCIN 52"],
  },
  {
    primary_id: "D0J8WJ",
    names: ["AKL-0707", "Akela GHRH", "LAB GHRH", "GHRH Analog, Akela", "Malnutrition Therapy, LAB International"],
  },
  {
    primary_id: "D0J8WO",
    names: ["TAK-428"],
  },
  {
    primary_id: "D0J8XO",
    names: ["BCH-150"],
  },
  {
    primary_id: "D0J8YA",
    names: ["PentaLyte"],
  },
  {
    primary_id: "D0J8YF",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 8"],
  },
  {
    primary_id: "D0J8ZA",
    names: [
      "Quinethazone",
      "Aquamox",
      "Chinetazone",
      "Chinethazone",
      "Chinethazonum",
      "Hydromox",
      "Idrokin",
      "Quinetazona",
      "Quinethazon",
      "Quinethazonum",
      "Chinetazone[DCIT]",
      "CL 36010",
      "Hydromox (TN)",
      "Quinetazona [INN-Spanish]",
      "Quinethazone (Aquamox)",
      "Quinethazonum [INN-Latin]",
      "Quinethazone (JAN/INN)",
      "Quinethazone [INN:BAN:JAN]",
      "2-Ethyl-7-Chloro-1,2,3,4-Tetrahydro-4-Oxochinazolin-6-Sulfonamid",
      "7-Chloro-2-Ethyl-1,2,3,4-Tetrahydro-4-Oxo-6-Quinazolinesulfonamide",
      "7-Chloro-2-Ethyl-1,2,3,4-Tetrahydro-4-Oxo-6-Sulfamoylquinazoline",
      "7-Chloro-2-Ethyl-6-Sulfamoyl-1,2,3,4-Tetrahydro-4-Quinazolinone",
      "7-Chloro-2-Ethyl-4-Oxo-1,2,3,4-Tetrahydroquinazoline-6-Sulfonamide",
      "7-Chloro-2-Ethyl-4-Oxo-2,3-Dihydro-1H-Quinazoline-6-Sulfonamide",
    ],
  },
  {
    primary_id: "D0J8ZH",
    names: [
      "4'-Demethyl-4beta-Amino-4-Desoxypodophyllotoxin",
      "CHEMBL32083",
      "SCHEMBL4854498",
      "WZTRSUUEQXXWCN-JHQYFNNDSA-N",
      "(5R,5aR,8aS,9S)-9-Amino-5-(4-Hydroxy-3,5-Dimethoxyphenyl)-5,5a,8a,9-Tetrahydrofuro[3',4':6,7]naphtho[2,3-D][1,3]dioxol-6(8H)-One",
      "(5R,5aR,8aS,9S)-9-Amino-5-(4-Hydroxy-3,5-Dimethoxyphenyl)-5,8,8a,9-Tetrahydrofuro[3',4':6,7]naphtho[2,3-D][1,3]dioxol-6(5aH)-One",
    ],
  },
  {
    primary_id: "D0J9AK",
    names: ["MK-944a"],
  },
  {
    primary_id: "D0J9CF",
    names: ["IDDBCP-150101"],
  },
  {
    primary_id: "D0J9CG",
    names: ["NmVac"],
  },
  {
    primary_id: "D0J9CR",
    names: [
      "AMTB",
      "CHEMBL1289953",
      "GTPL4132",
      "SCHEMBL10039870",
      "ZINC35076019",
      "BDBM50331290",
      "N-(3-Aminopropyl)-2-(3-Methylbenzyloxy)-N-(Thiophen-2-Ylmethyl)Benzamide",
      "N-(3-Aminopropyl)-2-{[(3-Methylphenyl)Methyl]oxy}-N-(2-Thienylmethyl)Benzamide",
    ],
  },
  {
    primary_id: "D0J9CS",
    names: ["PMID25666693-Compound-59"],
  },
  {
    primary_id: "D0J9DD",
    names: [
      "4-Phenylimidazole",
      "670-95-1",
      "4-PHENYL-1H-IMIDAZOLE",
      "5-Phenyl-1H-Imidazole",
      "4(5)-Phenylimidazole",
      "1H-Imidazole, 4-Phenyl-",
      "Imidazole, 4-Phenyl-",
      "5-Phenylimidazole",
      "4-PHENYL IMIDAZOLE",
      "CHEMBL14145",
      "XHLKOHSAWQPOFO-UHFFFAOYSA-N",
      "MFCD00005197",
      "NSC-195337",
      "F1918-0054",
      "PIM",
      "4-Phenyl-1h-Imidazol",
      "4-Phenyl-Imidazole",
      "EINECS 211-580-1",
      "PubChem7224",
      "NSC 195337",
      "ACMC-209nxp",
      "4-Phenylimidazole, 4-PI",
      "4-Phenylimidazole, 97%",
      "1H-Imidazole, 5-Phenyl-",
      "AC1L2C7W",
      "AC1Q4YD1",
      "SCHEMBL37563",
      "KSC696A2D",
      "Imidazole, 4-Phenyl- (8CI)",
      "AMBZ0133",
      "CTK5J6021",
    ],
  },
  {
    primary_id: "D0J9DI",
    names: ["Heterocyclic Derivative 5"],
  },
  {
    primary_id: "D0J9DO",
    names: ["[Tyr-Pro-Phe-NH-CH2-]2", "CHEMBL180286"],
  },
  {
    primary_id: "D0J9DY",
    names: ["PMID2296027C25"],
  },
  {
    primary_id: "D0J9FK",
    names: ["[3H]PSB-11"],
  },
  {
    primary_id: "D0J9FN",
    names: ["PMID27744724-Compound-21"],
  },
  {
    primary_id: "D0J9FQ",
    names: ["SiRNA Molecules, LNP Technology"],
  },
  {
    primary_id: "D0J9FT",
    names: [
      "3-[(1s)-1-(Dimethylamino)Ethyl]Phenol",
      "139306-10-8",
      "(S)-3-(1-(Dimethylamino)Ethyl)Phenol",
      "3-[(1S)-1-(DIMETHYLAMINO)ETHYL]PHENOL",
      "3-[(1S)-1-(Dimethylaminoethyl)]phenol",
      "(S)-3-[1-(Dimethylamino)Ethyl]phenol",
      "3-((S)-1-Dimethylamino-Ethyl)-Phenol",
      "UNII-1608PLR9ZO",
      "(S)-3-1(-Dimethylamino)Ethylphenol",
      "NAP-226-90",
      "(S)-3-1(- Dimethylamino)Ethylphenol",
      "NAP 226-90",
      "1608PLR9ZO",
      "(S)-3-(1-DIMETHYLAMINOETHYL)PHENOL",
      "Phenol, 3-[(1S)-1-(Dimethylamino)Ethyl]-",
      "AK-51758",
      "3-((1S)-1-(Dimethylamino)Ethyl)Phenol",
      "Phenol, 3-((1S)-1-(Dimethylamino)Ethyl)-",
      "SAF",
      "A1-00312",
      "W-205531",
    ],
  },
  {
    primary_id: "D0J9GD",
    names: ["Mevastatin"],
  },
  {
    primary_id: "D0J9GR",
    names: ["OX-27"],
  },
  {
    primary_id: "D0J9HO",
    names: ["Single Tablet Regimen Of Sofosbuvir And Ledipasvir"],
  },
  {
    primary_id: "D0J9HW",
    names: [
      "Olaparib",
      "AZD 2281",
      "AZD2281",
      "AZD-2281",
      "Acylpiperazine Analogue, 47",
      "KU-0059436",
      "KU-59436",
      "Olaparib, KU-0059436, AZD2281,KU0059436, AZD2281",
      "4-[(3-{[4-Cyclopropylcarbonyl)Piperazin-4-Yl]carbonyl}-4-Fluorophenyl)Methyl]phtalazin-1(2H)-One",
      "4-[3-(4-Cyclopropanecarbonyl-Piperazine-1-Carbonyl)-4-Fluoro-Benzyl]-2H-Phthalazin-1-One",
    ],
  },
  {
    primary_id: "D0J9IH",
    names: [
      "PELITREXOL",
      "AG-2037",
      "Pelitrexol < USAN",
      "AG-2032 (Racemate)",
      "AG-2038 ((6R)-Isomer)",
      "N-[5-[2-[2-Amino-4-Oxo-3,4,5,6,7,8-Hexahydropyrido[2,3-D]pyrimidin-6(S)-Yl]ethyl]-4-Methylthien-2-Ylcarbonyl]-L-Glutamic Acid",
    ],
  },
  {
    primary_id: "D0J9JB",
    names: ["PMID26004420-Compound-WO2012058116C"],
  },
  {
    primary_id: "D0J9JH",
    names: ["GARVEATIN A"],
  },
  {
    primary_id: "D0J9JQ",
    names: ["XR-5082"],
  },
  {
    primary_id: "D0J9JS",
    names: ["Pyrimidine Derivative 22"],
  },
  {
    primary_id: "D0J9KC",
    names: [
      "IGN-101",
      "Cancer Vaccine, Igeneon",
      "Anti-EpCam Murine Monoclonal Antibody 17-A Carcinoma Vaccine",
      "Aluminum-Adsorbed Murine Monoclonal Antibody 17-1A (Edrecolomab) Vaccine Antigen (Cancer), Igeneon",
    ],
  },
  {
    primary_id: "D0J9LU",
    names: ["N-Arylpiperazine Derivative"],
  },
  {
    primary_id: "D0J9MH",
    names: ["Ar5Y_4"],
  },
  {
    primary_id: "D0J9MO",
    names: ["Tetrabranched Peptides"],
  },
  {
    primary_id: "D0J9MP",
    names: ["N-(3-(3-Aminopropyl)Phenyl)Benzenesulfonamide", "CHEMBL378179", "SCHEMBL3125892", "ZINC36093367"],
  },
  {
    primary_id: "D0J9NJ",
    names: ["CLX-0901"],
  },
  {
    primary_id: "D0J9OG",
    names: ["N-(2,4-Dimethoxy-Phenyl)-3,5-Dimethoxy-Benzamide"],
  },
  {
    primary_id: "D0J9OS",
    names: ["3-(Furan-3-Yl)-1-Methyl-1H-Indole", "CHEMBL207375", "1H-Indole, 3-(3-Furanyl)-1-Methyl-", "BDBM50182882"],
  },
  {
    primary_id: "D0J9PD",
    names: ["STP-206"],
  },
  {
    primary_id: "D0J9PG",
    names: ["AH6809", "AH 6809", "AH-6809"],
  },
  {
    primary_id: "D0J9PK",
    names: ["OXA-01"],
  },
  {
    primary_id: "D0J9PS",
    names: ["MEDI1841"],
  },
  {
    primary_id: "D0J9PT",
    names: ["BMS-820836"],
  },
  {
    primary_id: "D0J9QK",
    names: ["(R)-3-(1'-Adamantanecarbonyl)Amino-Caprolactam", "CHEMBL512094"],
  },
  {
    primary_id: "D0J9QS",
    names: ["BTRC4017A"],
  },
  {
    primary_id: "D0J9QV",
    names: [
      "Calcium-Activated Small Conductance Potassium Channel Blockers",
      "Calcium-Activated Small Conductance Potassium Channel Blockers, Bristol Myers Squibb",
    ],
  },
  {
    primary_id: "D0J9SB",
    names: ["Derlotuximan Biotin"],
  },
  {
    primary_id: "D0J9SL",
    names: ["Piperazine Derivative 7"],
  },
  {
    primary_id: "D0J9SO",
    names: ["BS 7840", "CHEMBL380748"],
  },
  {
    primary_id: "D0J9SS",
    names: [
      "6-Nitro-2-(3-Nitro-Phenyl)-Chromen-4-One",
      "6,3'-Dinitroflavone",
      "3',6-Dinitroflavone",
      "100914-36-1",
      "4H-1-Benzopyran-4-One,6-Nitro-2-(3-Nitrophenyl)-",
      "6-3'-Dinitroflavone",
      "ACMC-20m3ym",
      "AC1L2SI8",
      "6-Nitro-2-(3-Nitrophenyl)-4H-1-Benzopyran-4-One",
      "SCHEMBL4607216",
      "CHEMBL292868",
      "CTK3J9355",
      "DTXSID90143576",
      "AKOS030553659",
    ],
  },
  {
    primary_id: "D0J9TA",
    names: ["Sensor Linked Micelle"],
  },
  {
    primary_id: "D0J9TD",
    names: ["N-Biphenyl-3-Ylmethyl-N'-Hydroxy-Malonamide", "CHEMBL376650"],
  },
  {
    primary_id: "D0J9TO",
    names: ["HG-1076"],
  },
  {
    primary_id: "D0J9UA",
    names: ["HESC-Derived RPE (PF-05206388)"],
  },
  {
    primary_id: "D0J9UB",
    names: ["RG7593"],
  },
  {
    primary_id: "D0J9UC",
    names: [
      "N-[4'-(Trifluoromethyl)-4-Biphenylyl]urea",
      "CHEMBL243883",
      "SCHEMBL5427711",
      "ZINC28711905",
      "BDBM50220162",
    ],
  },
  {
    primary_id: "D0J9UI",
    names: ["YM60828"],
  },
  {
    primary_id: "D0J9UN",
    names: [
      "Uracil Mustard",
      "Chlorethaminacil",
      "Demethyldopan",
      "Desmethyldopan",
      "Nordopan",
      "Uracillost",
      "Uracilmostaza",
      "Uramustin",
      "Uramustina",
      "Uramustine",
      "Uramustinum",
      "Aminouracil Mustard",
      "Uracil Lost",
      "Uracil Lost [German]",
      "Uracil Mustard [USAN]",
      "Uracil Nitrogen Mustard",
      "ENT 50439",
      "U 8344",
      "CB-4835",
      "SK-19849",
      "U-8344",
      "Uracil Mustard (TN)",
      "Uracil Mustard (USAN)",
      "Uramustina [INN-Spanish]",
      "Uramustine (INN)",
      "Uramustinum [INN-Latin]",
      "URACIL MUSTARD (500 MG) (FOR U.S. SALE ONLY)",
      "2,6-Dihydroxy-5-Bis(2-Chloroethyl)Aminopyrimidine",
      "2,6-Dihydroxy-5-Bis[2-Chloroethyl]aminopyrimidine",
      "5-(Bis(2-Chlorethyl)Amino)-2,4(1H,3H)Pyrimidinedione",
      "5-(Bis(2-Chloroethyl)Amino)-2,4(1H,3H)Pyrimidinedione",
      "5-(Bis(2-Chloroethyl)Amino)-2,4-(1H,3H)Pyrimidinedione",
      "5-(Bis(2-Chloroethyl)Amino)Uracil",
      "5-(Di-(Beta-Chloroethyl)Amino)Uracil",
      "5-(Di-2-Chloroethyl)Aminouracil",
      "5-Aminouracil Mustard",
      "5-N,N-Bis(2-Chloroethyl)Aminouracil",
      "5-[Bis(2-Chlorethyl)Amino]-2,4(1H,3H)Pyrimidinedione",
      "5-[Bis(2-Chloroethyl)Amino]uracil",
      "5-[Di(2-Chloroethyl)Amino]uracil",
      "5-[Di(Beta-Chloroethyl)Amino]uracil",
      "5-[Di(Beta.-Chloroethyl)Amino]uracil",
      "5-[bis(2-Chloroethyl)Amino]-1H-Pyrimidine-2,4-Dione",
      "5-[bis(2-Chloroethyl)Amino]-2,4(1H,3H)-Pyrimidinedione",
      "5-[bis(2-Chloroethyl)Amino]pyrimidine-2,4(1H,3H)-Dione",
      "5-[bis(2-Chloroethyl)Amino]pyrimidine-2,4-Diol",
    ],
  },
  {
    primary_id: "D0J9UY",
    names: ["V505"],
  },
  {
    primary_id: "D0J9VA",
    names: ["H-[Trp-Arg-Nva-Arg-Tyr]2-NH2", "CHEMBL408891"],
  },
  {
    primary_id: "D0J9VG",
    names: ["NSI-566"],
  },
  {
    primary_id: "D0J9VK",
    names: ["CM-156"],
  },
  {
    primary_id: "D0J9VL",
    names: ["MC2-01 PAD Cream"],
  },
  {
    primary_id: "D0J9VP",
    names: [
      "3-Tert-Butyl-3-Ethyl-Dihydro-Furan-2-One",
      "CHEMBL97749",
      "2(3H)-Furanone, 3-(1,1-Dimethylethyl)-3-Ethyldihydro-",
      "153579-88-5",
    ],
  },
  {
    primary_id: "D0J9WO",
    names: ["4-(3-Bromo-Phenylamino)-Quinazoline-6,7-Diol"],
  },
  {
    primary_id: "D0J9WP",
    names: ["PMID28074661-Compound-US20100022547C87"],
  },
  {
    primary_id: "D0J9XZ",
    names: [
      "Torasemide",
      "Demadex",
      "Dilutol",
      "Luprac",
      "Presoril",
      "Sutril",
      "TORSEMIDE",
      "Toradiur",
      "Torasemida",
      "Torasemidum",
      "Torem",
      "Torocard",
      "Torrem",
      "Unat",
      "Torasemide N",
      "Torsemide [USAN]",
      "AC 4464",
      "BM 02015",
      "JDL 464",
      "BM-02015",
      "Demadex (TN)",
      "Demadex, Torsemide",
      "GJ-1090",
      "JDL-464",
      "KS-1123",
      "Luprac (TN)",
      "PW-2132",
      "Torasemida [INN-Spanish]",
      "Torasemidum [INN-Latin]",
      "Torsemide (USP)",
      "Torasemide (JAN/INN)",
      "N-(Isopropylcarbamoyl)-4-(M-Tolylamino)Pyridine-3-Sulfonamide",
      "N-{[(1-Methylethyl)Amino]carbonyl}-4-[(3-Methylphenyl)Amino]pyridine-3-Sulfonamide",
      "N-(((1-Methylethyl)Amino)Carbonyl)-4-((3-Methylphenyl)Amino)-3-Pyridinesulfonamide",
      "1-Isopropyl-3-((4-M-Toluidino-3-Pyridyl)Sulfonyl)Urea",
      "1-[4-(3-Methylanilino)Pyridin-3-Yl]sulfonyl-3-Propan-2-Ylurea",
      "1-Isopropyl-3-((4-(3-Methylphenylamino)Pyridine)-3-Sulfonyl)Urea",
      "1-Isopropyl-3-[(4-M-Toluidino-3-Pyridyl) Sulfonyl]urea",
    ],
  },
  {
    primary_id: "D0J9YS",
    names: ["DFRF4539A"],
  },
  {
    primary_id: "D0J9YX",
    names: ["PMID27828716-Compound-17"],
  },
  {
    primary_id: "D0J9ZA",
    names: ["L-Tryptophan-L-2-Aminoadipic Acid", "CHEMBL515773"],
  },
  {
    primary_id: "D0J9ZF",
    names: [
      "4-Benzyl-2-Methylphthalazin-1(2H)-One",
      "CHEMBL304747",
      "AC1LDJCK",
      "4-Benzyl-2-Methyl-1(2H)-Phthalazinone",
      "SCHEMBL8556390",
      "KUTSOIMAOFABLX-UHFFFAOYSA-N",
      "4-Benzyl-2-Methylphthalazin-1-One",
      "BDBM50131423",
      "4-Benzyl-2-Methyl-2H-Phthalazin-1-One",
      "4-Benzyl-2-Methyl-1(2H)-Phthalazinone #",
      "4-Benzyl-1,2-Dihydro-2-Methyl-1-Oxophthalazine",
    ],
  },
  {
    primary_id: "D0J9ZG",
    names: ["PMID28270010-Compound-Figure5-1"],
  },
  {
    primary_id: "D0J9ZR",
    names: ["Niclosamide"],
  },
  {
    primary_id: "D0JA0A",
    names: ["PMID25828189-Compound-22"],
  },
  {
    primary_id: "D0JA1Z",
    names: ["TA-6666"],
  },
  {
    primary_id: "D0JA3Y",
    names: ["MEDI-578"],
  },
  {
    primary_id: "D0JA4K",
    names: ["PMID27841036-Compound-36"],
  },
  {
    primary_id: "D0JA5B",
    names: ["PMID27744724-Compound-3"],
  },
  {
    primary_id: "D0JA5D",
    names: ["RO330-0802/001"],
  },
  {
    primary_id: "D0JA7V",
    names: [
      "PRX-105",
      "Acetylcholinesterase (Poison Intoxication, ProCellEx), Protalix/Hebrew University Of Jerusalem",
      "Acetylcholinesterase (Poison Intoxication, Plant Cell-Expression), Protalix/Hebrew University Of Jerusalem",
    ],
  },
  {
    primary_id: "D0JA8J",
    names: ["PMID25553724-Compound-US20130237529 35"],
  },
  {
    primary_id: "D0JA8R",
    names: ["HLA-DQ2 Peptide Vaccine"],
  },
  {
    primary_id: "D0JB0D",
    names: ["Influenza Subunit Vaccines"],
  },
  {
    primary_id: "D0JB0N",
    names: ["BLV-0704", "BLV-200704", "Ibu-KTP-NH2", "Ibu-Kyotorphin-NH2", "ND-Targeting Compound (Pain), Bioalvo"],
  },
  {
    primary_id: "D0JB1D",
    names: ["Hemicholinium-3"],
  },
  {
    primary_id: "D0JB1V",
    names: ["Indazole Derivative 3"],
  },
  {
    primary_id: "D0JB2J",
    names: ["Isatuximab"],
  },
  {
    primary_id: "D0JB3H",
    names: ["Omaveloxolone"],
  },
  {
    primary_id: "D0JB4G",
    names: [
      "CHROMOTROPATE",
      "129-96-4",
      "Chromotropic Acid Disodium Salt",
      "Sodium Chromotropate",
      "2,7-Naphthalenedisulfonic Acid, 4,5-Dihydroxy-, Disodium Salt",
      "Disodium Chromotropate",
      "Sodium 4,5-Dihydroxynaphthalene-2,7-Disulfonate",
      "Disodium 4,5-Dihydroxynaphthalene-2,7-Disulfonate",
      "Disodium 4,5-Dihydroxynaphthalene-2,7-Disulphonate",
      "Mordant Brown 7",
      "Chromotropic Acid Sodium Salt",
      "3888-44-6",
      "2,7-Naphthalenedisulfonic Acid, 4,5-Dihydroxy-, Sodium Salt (1:2)",
      "CHEMBL425511",
      "148-25-4 (Parent)",
      "Mordantbrown",
      "Chromogen C",
      "NSC 4883",
      "EINECS 204-972-",
    ],
  },
  {
    primary_id: "D0JB4S",
    names: ["PMID29671355-Compound-61"],
  },
  {
    primary_id: "D0JB4V",
    names: ["XEN-403"],
  },
  {
    primary_id: "D0JB4W",
    names: ["CHIR-29498", "CHIR-29496", "CHIR-29499", "CHIR-33670"],
  },
  {
    primary_id: "D0JB6H",
    names: [
      "6-Methyl-4-Morpholin-4-Yl-Chromen-2-One",
      "CHEMBL361588",
      "6-Methyl-4-Morpholino-Coumarin",
      "MolPort-000-485-396",
      "BDBM50159606",
      "ZINC13608106",
      "STK908587",
      "AKOS002216573",
      "MCULE-4100962736",
      "NCGC00327351-01",
      "6-Methyl-4-Morpholin-4-Ylchromen-2-One",
      "ST096430",
      "AB01322204-02",
    ],
  },
  {
    primary_id: "D0JB7I",
    names: ["7-Azaindole Derivative 4"],
  },
  {
    primary_id: "D0JB7Z",
    names: ["AIT-034"],
  },
  {
    primary_id: "D0JB9C",
    names: ["PF-06688992"],
  },
  {
    primary_id: "D0JC3U",
    names: ["Resorcinol Compound 11"],
  },
  {
    primary_id: "D0JC4J",
    names: ["SARMs"],
  },
  {
    primary_id: "D0JC4Q",
    names: [
      "4-(4-Chlorobenzyl)-2-Allylphthalazin-1(2H)-One",
      "CHEMBL207277",
      "BDBM50183665",
      "2-Allyl-4-[(4-Chlorophenyl)Methyl]phthalazin-1-One",
      "1(2H)-Phthalazinone, 4-[(4-Chlorophenyl)Methyl]-2-Allyl-",
    ],
  },
  {
    primary_id: "D0JC7A",
    names: ["NP-50501"],
  },
  {
    primary_id: "D0JC8E",
    names: ["Tricyclic Isoxazoloquinazoline Derivative 4"],
  },
  {
    primary_id: "D0JC8G",
    names: ["PL2100", "Aspirin/ Phosphatidyl-Choline"],
  },
  {
    primary_id: "D0JC8L",
    names: ["PMID25666693-Compound-31"],
  },
  {
    primary_id: "D0JC8M",
    names: ["PMID27454349-Compound-95"],
  },
  {
    primary_id: "D0JC9K",
    names: ["VI7734"],
  },
  {
    primary_id: "D0JC9N",
    names: [
      "Adapalene",
      "Adaferin",
      "Adapaleno",
      "Adapalenum",
      "Differin",
      "Differine",
      "CD 271",
      "CD271",
      "Adaferin (TN)",
      "Adapaleno [INN-Spanish]",
      "Adapalenum [INN-Latin]",
      "CD-271",
      "Differin (TN)",
      "Differin, Adapalene",
      "KS-1196",
      "Adapalene [USAN:BAN:INN]",
      "Adapalene (JAN/USAN/INN)",
      "6-(3-(1-Adamantyl)-4-Methoxyphenyl)-2-Naphthoic Acid",
      "6-(3-Adamantan-1-Yl-4-Methoxyphenyl)Naphthalene-2-Carboxylic Acid",
      "6-[3-(1-Adamantyl)-4-Methoxyphenyl]naphthalene-2-Carboxylic Acid",
      "6-[3-(Adamantan-1-Yl)-4-Methoxyphenyl]-2-Naphthoic Acid",
    ],
  },
  {
    primary_id: "D0JD0G",
    names: ["PMID29649907-Compound-4"],
  },
  {
    primary_id: "D0JD1A",
    names: [
      "Antimicrobial Peptide",
      "Antimicrobial Peptide (Infection)",
      "Antimicrobial Peptide (Infection), UNMC/SDSU",
      "Antimicrobial Peptide (Infection), University Of Nebraska Medical Center/San Diego State University",
    ],
  },
  {
    primary_id: "D0JD1O",
    names: [
      "Carba-Nicotinamide-Adenine-Dinucleotide",
      "Carba-NAD",
      "Carbanicotinamide Adenine Dinucleotide",
      "112345-60-5",
      "AC1L4TS7",
      "AC1Q5J0L",
      "SCHEMBL16445201",
      "5'-O-{[({[(1r,2r,3s,4r)-4-(3-Carbamoylpyridinium-1-Yl)-2,3-Dihydroxycyclopentyl]methoxy}phosphinato)Oxy](Hydroxy)Phosphoryl}adenosine",
      "[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] [(1R,2R,3S,4R)-4-(3-Carbamoylpyridin-1-Ium-1-Yl)-2,3-Dihydroxycyclopentyl]methyl Phosphate",
      "Adenosine 5'-(Trihydrogen Diphosphate), 5'-((4-(3-(Aminocarbonyl)Pyridinio)-2,3-Dihydroxycyclopentyl)M",
    ],
  },
  {
    primary_id: "D0JD1R",
    names: ["Zn-DPA-G"],
  },
  {
    primary_id: "D0JD4C",
    names: ["L-454560"],
  },
  {
    primary_id: "D0JD4S",
    names: ["CU-906"],
  },
  {
    primary_id: "D0JD4T",
    names: ["Anti-CD19 CAR Transduced T Cells"],
  },
  {
    primary_id: "D0JD5W",
    names: ["PMID27998201-Compound-7"],
  },
  {
    primary_id: "D0JD6Y",
    names: ["CD19 CAR T"],
  },
  {
    primary_id: "D0JD8N",
    names: [
      "Dicer-Substrate Small Interfering RNAs",
      "Dicer-Substrate Small Interfering RNAs (Cancer)",
      "DsiRNAs (Cancer), Dicerna",
      "Dicer-Substrate Small Interfering RNAs (Cancer), Dicerna",
      "DsiRNAs (Cancer), Dicerna/Kyowa Hakko Kirin",
      "Dicer-Substrate Small Interfering RNAs (Cancer), Dicerna/Kyowa Hakko Kirin",
    ],
  },
  {
    primary_id: "D0JD9E",
    names: [
      "SQ-24798",
      "2-Mercaptomethyl-5-Guanidinopentanoic Acid",
      "CHEMBL236822",
      "5-Carbamimidamido-2-(Sulfanylmethyl)Pentanoic Acid",
      "69734-02-7",
      "5-(Diaminomethylideneamino)-2-(Sulfanylmethyl)Pentanoic Acid",
      "SQ 24798",
      "SQ 24,798",
      "AC1Q5RYU",
      "AC1L4Y1G",
      "GTPL8656",
      "SCHEMBL5415499",
      "CTK5D1141",
      "BDBM50201438",
      "AKOS030556047",
      "SQ-24,798",
      "5-Guanidino-2-(Mercaptomethyl)Pentanoic Acid",
      "(+/-)-5-Guanidino-2-(Mercaptomethyl)Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0JD9F",
    names: ["Onyvax-105 Anti-Idiotype Cancer Vaccine 105AD7"],
  },
  {
    primary_id: "D0JD9Z",
    names: ["PMID25656651-Compound-33b"],
  },
  {
    primary_id: "D0JE1I",
    names: ["R-Fadrozole"],
  },
  {
    primary_id: "D0JE1M",
    names: ["PMID29130358-Compound-LonimacranthoideVI"],
  },
  {
    primary_id: "D0JE2E",
    names: [
      "Rosuvastatin",
      "Astende",
      "Cirantan",
      "Cresadex",
      "Creston",
      "Crestor",
      "Provisacor",
      "Razel",
      "Rosedex",
      "Rosimol",
      "Rosumed",
      "Rosustatin",
      "Rosuvas",
      "Rosuvast",
      "Rosvel",
      "Rovartal",
      "Simestat",
      "Sinlip",
      "Vivacor",
      "Rosuvastatin [INN]",
      "Rosuvastatin Calcium",
      "Rosuvastatin Calcium [USAN]",
      "Rosuvastatin Hemicalcium",
      "S 4522",
      "ZD 4522",
      "ZD4522",
      "AZD-4522",
      "Creston (TN);Crestor (TN)",
      "Pyrimidine Compound, 26",
      "Rosuvastatin (INN)",
      "S-4522",
      "ZD 4522, Calcium Salt",
      "ZD-4522",
      "Rosuvastatin Calcium (JAN/USAN)",
      "Bis[(E)-7-[4-(4-Fluorophenyl)-6-Isopropyl-2-[methyl(Methylsulfonyl)Amino] Pyrimidin-5-Yl](3R,5S)-3,5-Dihydroxyhelpt-6-Enoic Acid] Calcium Salt",
      "Calcium (E,3R,5S)-7-[4-(4-Fluorophenyl)-2-[methyl(Methylsulfonyl)Amino]-6-Propan-2-Ylpyrimidin-5-Yl]-3,5-Dihydroxyhept-6-Enoate",
      "(3R,5S,6E)-7-(4-(4-Fluorophenyl)-6-(1-Methylethyl)-2-(Ethyl(Methylsulfonyl)Amino)-5-Pyrimidinyl)-3,5-Dihydroxy-6-Heptenoic Acid",
      "(3R,5S,6E)-7-{4-(4-Fluorophenyl)-2-[methyl(Methylsulfonyl)Amino]-6-(Propan-2-Yl)Pyrimidin-5-Yl}-3,5-Dihydroxyhept-6-Enoic Acid",
      "(3R,5S,6E)-7-{4-(4-Fluorophenyl)-6-Isopropyl-2-[methyl(Methylsulfonyl)Amino]pyrimidin-5-Yl}-3,5-Dihydroxyhept-6-Enoic Acid",
      "(E,3R,5R)-7-[4-(4-Fluorophenyl)-2-[methyl(Methylsulfonyl)Amino]-6-Propan-2-Ylpyrimidin-5-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(E,3R,5S)-7-[4-(4-Fluorophenyl)-2-[methyl(Methylsulfonyl)Amino]-6-Propan-2-Ylpyrimidin-5-Yl]-3,5-Dihydroxyhept-6-Enoic Acid",
      "(S-((R*,S*-(E)))-7-(4-(4-Fluorophenyl)-6-(1-Methylethyl)-2-(Methyl(Methylsulfonyl) Amino)-5-Pyrimidinyl)-3,5-Dihydroxy-6-Heptenoic Acid, Calcium Salt (2:1)",
      "(S-(R*,S*-(E)))-7-(4-(4-Fluorophenyl)-6-(1-Methylethyl)-2-(Methyl(Methylsulfonyl)Amino)-5-Pyrimidinyl)-3,5-Dihydroxy-6-Heptenoic Acid, Calcium Salt (2:1)",
      "6-Heptenoic Acid, 7-(4-(4-Fluorophenyl)-6-(1-Methylethyl)-2-(Ethyl(Methylsulfonyl)Amino)-5-Pyrimidinyl)-3,5-Dihydroxy-, (3R,5S,6E)",
      "6-Heptenoic Acid, 7-(4-(4-Fluorophenyl)-6-(1-Methylethyl)-2-(Methyl(Methylsulfonyl)Amino)-5-Pyrimidinyl)-3,5-Dihydroxy-, Calcium Salt (2:1), (3R,5S,6E)",
    ],
  },
  {
    primary_id: "D0JE3Z",
    names: [
      "4-Fluorobenzoyl-Phe-Gly-Leu-Arg-Trp-NH2",
      "CHEMBL388586",
      "Peptide Analogue, 1",
      "SCHEMBL4132720",
      "BDBM26338",
      "FTM-080",
      "N-[(4-Fluorophenyl)Carbonyl]-L-Phenylalanylglycyl-L-Leucyl-L-Arginyl-L-Tryptophanamide",
    ],
  },
  {
    primary_id: "D0JE4D",
    names: ["Dornase Alfa"],
  },
  {
    primary_id: "D0JE6I",
    names: ["Aryl Cyclopropylamine Derivative 3"],
  },
  {
    primary_id: "D0JF0B",
    names: ["PMID30247903-Compound-General Structure19"],
  },
  {
    primary_id: "D0JF0W",
    names: ["PGF1alpha"],
  },
  {
    primary_id: "D0JF3M",
    names: ["P-144"],
  },
  {
    primary_id: "D0JF5K",
    names: ["Peptide Analog 8"],
  },
  {
    primary_id: "D0JF6C",
    names: ["Eltanexor Oral"],
  },
  {
    primary_id: "D0JF6O",
    names: ["Eltenac", "BY-820", "B-788-20"],
  },
  {
    primary_id: "D0JF6W",
    names: ["PMID28870136-Compound-65"],
  },
  {
    primary_id: "D0JF8D",
    names: [
      "N-(Tert-Butoxycarbonyl)-Tyrosyl-Glycine-Nitrile",
      "Dipeptide-Derived Nitrile, 23",
      "CHEMBL371893",
      "BDBM20102",
      "Tert-Butyl N-[(1S)-1-[(Cyanomethyl)Carbamoyl]-2-(4-Hydroxyphenyl)Ethyl]carbamate",
    ],
  },
  {
    primary_id: "D0JF9Z",
    names: [
      "CSL-362",
      "CSL-360",
      "Monoclonal Antibody (Acute Myelogenous Leukemia), CSL",
      "Therapeutic Leukaemia Antibody (AML), CSL",
      "7G3",
    ],
  },
  {
    primary_id: "D0JG2W",
    names: [
      "4-(4-Chlorophenylthio)-2-(Pyridin-2-Yl)Pyrimidine",
      "CHEMBL208731",
      "4-[(4-Chlorophenyl)Thio]-2-Pyridin-2-Ylpyrimidine",
      "SCHEMBL8280517",
      "BDBM50182822",
    ],
  },
  {
    primary_id: "D0JG2X",
    names: ["JCAR018"],
  },
  {
    primary_id: "D0JG6T",
    names: ["XEN-401"],
  },
  {
    primary_id: "D0JG8O",
    names: ["Luliconazole 10%"],
  },
  {
    primary_id: "D0JH2D",
    names: [
      "2-Methyl-6-(4-Phenylbut-1-Ynyl)Pyridine",
      "CHEMBL214542",
      "2-Methyl-6-(4-Phenylbut-1-Yn-1-Yl)Pyridine",
      "SCHEMBL5703352",
      "BDBM50191117",
    ],
  },
  {
    primary_id: "D0JH4U",
    names: ["5-Oxo-15-HETE", "5-Oxo-15-Hydroxy-(6E,8Z,11Z,13E)-Eicosatetraenoic Acid"],
  },
  {
    primary_id: "D0JH5D",
    names: ["1-Benzhydryl-4-(2-Fluorophenyl)Piperidin-4-Ol", "CHEMBL232476"],
  },
  {
    primary_id: "D0JH5K",
    names: ["TVI-Kidney-1"],
  },
  {
    primary_id: "D0JH6W",
    names: [
      "DX-9065a",
      "Dx-9065a",
      "QXQVEPEVI2",
      "UNII-QXQVEPEVI2",
      "155204-81-2",
      "2-(4-((1-Acetimidoyl-3-Pyrrolidinyl)Oxy)Phenyl)-3-(7-Amidino-2-Naphthyl)Propanoic Acid Hydrocloride Pentahydrate",
      "DX 9065a",
      "(S-(R*,R*))-7-(Aminoiminomethyl)-Alpha-(4-((1-(1-Iminoethyl)-3-Pyrrolidinyl)Oxy)Phenyl)-2-Naphthalenepropanoic Acid Monohydrochloride, Pentahydrate",
      "2-Naphthalenepropanoic Acid, 7-(Aminoiminomethyl)-Alpha-(4-((1-(1-Iminoethyl)-3-Pyrrolidinyl)Oxy)Phenyl)-, Monohydrochloride, Pentahydrate, (S-(R*,R*))-",
      "AC1L3U17",
      "X-9065a",
      "C26H28N4O3.5H2O.HCl",
    ],
  },
  {
    primary_id: "D0JH7C",
    names: [
      "1,10-Phenanthroline-5,6-Dione",
      "27318-90-7",
      "[1,10]Phenanthroline-5,6-Dione",
      "UNII-8JD7KXA2W0",
      "8JD7KXA2W0",
      "MLS003171048",
      "KCALAFIVPCAXJI-UHFFFAOYSA-N",
      "1 10-Phenanthroline-5 6-Dione 97",
      "AK-27690",
      "J-400086",
      "Pyridino[3,2-H]quinoline-5,6-Dione",
      "1,10-Phenanthroline-5,6-Dione, 98%",
      "Phen-5,6-Dione",
      "1,10-Pad",
      "NSC346882",
      "NSC 346882",
      "PubChem16755",
      "ACMC-209gwd",
      "AC1L2IPW",
      "PHOX31",
      "KSC204S8L",
      "Cid_72810",
      "SCHEMBL460433",
      "CHEMBL235504",
      "AC1Q6N66",
      "KS-00000LQX",
      "CTK1A4985",
      "BDBM22860",
      "DTXSID60181763",
      "MolPort-002-720-727",
      "1,2-Dione-Based Compound, 17",
      "ZX-AT019110",
    ],
  },
  {
    primary_id: "D0JH7Q",
    names: ["PMID28627961-Compound-33"],
  },
  {
    primary_id: "D0JH8Z",
    names: ["PMID28454500-Compound-3"],
  },
  {
    primary_id: "D0JH9E",
    names: ["Anti-Botulinum Mabs"],
  },
  {
    primary_id: "D0JH9F",
    names: ["5-(4-Chloro-Phenyl)-1-Methyl-Piperidin-2-One", "CHEMBL294630", "BDBM50091709"],
  },
  {
    primary_id: "D0JH9J",
    names: ["6-Biphenyl-2-Yl-2-Morpholin-4-Yl-Chromen-4-One", "CHEMBL200678"],
  },
  {
    primary_id: "D0JH9T",
    names: ["ACE-393", "ACE-81", "ACE-961", "Campylobacter Jejuni Vaccine, ACE BioSciences"],
  },
  {
    primary_id: "D0JI0H",
    names: [
      "1-(2-Bromoethyl)-1H-Indole-2,3-Dione",
      "4290-78-2",
      "1-(2-Bromoethyl)Indoline-2,3-Dione",
      "1-(2-Bromoethyl)-2,3-Dihydro-1H-Indole-2,3-Dione",
      "AC1MSZWL",
      "1-(2-Bromoethyl)Isatin",
      "Isatin-Based Compound, 5",
      "1H-Indole-2,3-Dione, 1-(2-Bromoethyl)-",
      "CHEMBL223000",
      "SCHEMBL10685462",
      "TOS-BB-0914",
      "BDBM22785",
      "CTK4I6774",
      "DTXSID90393478",
      "MolPort-000-453-141",
      "SJALPCXFOSGNST-UHFFFAOYSA-N",
      "ZINC2668691",
      "1-(2-Bromoethyl)Indole-2,3-Dione",
      "STK866424",
      "BBL023389",
      "1-(2-Bromoethyl)-Indole-2,3-Dione",
      "AKOS000245669",
      "MCULE-3160954506",
      "KB-85508",
      "AJ-44119",
    ],
  },
  {
    primary_id: "D0JI0Q",
    names: ["N-Methyl Estradiol-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0JI1J",
    names: ["GP-683", "Analgesic-Sparing Agent, Univ Texas"],
  },
  {
    primary_id: "D0JI1S",
    names: ["RDEA-640"],
  },
  {
    primary_id: "D0JI2E",
    names: ["Aleplasinin"],
  },
  {
    primary_id: "D0JI2X",
    names: ["PS-154636-1"],
  },
  {
    primary_id: "D0JI3S",
    names: ["1-Benzhydryl-3-Butylpiperidin-4-Amine", "CHEMBL234414"],
  },
  {
    primary_id: "D0JI3T",
    names: ["PMID27109571-Compound-43"],
  },
  {
    primary_id: "D0JI4G",
    names: ["Palodesangren E"],
  },
  {
    primary_id: "D0JI4J",
    names: ["BMS-986179"],
  },
  {
    primary_id: "D0JI4M",
    names: ["CAR-T Cells Targeting HER2"],
  },
  {
    primary_id: "D0JI5G",
    names: ["PCNU"],
  },
  {
    primary_id: "D0JI5X",
    names: ["PMID29865878-Compound-42"],
  },
  {
    primary_id: "D0JI8B",
    names: ["Milataxel"],
  },
  {
    primary_id: "D0JI8S",
    names: ["3,4-Difluorobenzaldehyde O-Benzoyloxime", "CHEMBL178450", "SCHEMBL3226311", "SCHEMBL3226318"],
  },
  {
    primary_id: "D0JJ0T",
    names: ["Pyrrolidinyl Urea Derivative 2"],
  },
  {
    primary_id: "D0JJ1B",
    names: ["Artilide", "Artilide Fumarate", "U-88943E"],
  },
  {
    primary_id: "D0JJ1N",
    names: ["Matairesinol Derivative 1"],
  },
  {
    primary_id: "D0JJ6T",
    names: ["N6-((+/-)-Endo-Norborn-2-Yl)Adenosine", "CHEMBL489640", "N-(2-Norbornyl)Adenosine", "SCHEMBL10608249"],
  },
  {
    primary_id: "D0JJ7S",
    names: ["Valbenazine Tosylate"],
  },
  {
    primary_id: "D0JJ8A",
    names: ["CaV2.2 Blocker", "CaV2.2 Blocker (Pain)", "CaV2.2 Blocker (Pain), Merck & Co"],
  },
  {
    primary_id: "D0JJ8K",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 7"],
  },
  {
    primary_id: "D0JK0I",
    names: ["Prenalterol", "CGP 7760B"],
  },
  {
    primary_id: "D0JK0Y",
    names: ["Peptide Analog 35"],
  },
  {
    primary_id: "D0JK2N",
    names: ["PMID25666693-Compound-22"],
  },
  {
    primary_id: "D0JK2R",
    names: ["RMJH-111b"],
  },
  {
    primary_id: "D0JK2U",
    names: ["Pancreatic Cancer Cell Therapy"],
  },
  {
    primary_id: "D0JK3V",
    names: ["Imidazole Derivative 4"],
  },
  {
    primary_id: "D0JL2K",
    names: ["Grepafloxacin"],
  },
  {
    primary_id: "D0JL2T",
    names: [
      "3,7-Dihydroxy-Flavone",
      "3,7-Dihydroxyflavone",
      "7-Hydroxyflavonol",
      "492-00-2",
      "3,7-Dihydroxy-2-Phenyl-4H-Chromen-4-One",
      "5-Deoxygalangin",
      "4H-1-Benzopyran-4-One, 3,7-Dihydroxy-2-Phenyl-",
      "UNII-O948D0K9BQ",
      "O948D0K9BQ",
      "CHEMBL210276",
      "3,7-Dihydroxy-2-Phenylchromen-4-One",
      "7,3'-Dihydroxyflavonol",
      "SR-01000314616",
      "Resogalangin",
      "SPBio_001803",
      "Spectrum_001462",
      "SpecPlus_000778",
      "AC1NU2BD",
      "Spectrum4_001767",
      "Spectrum3_001623",
      "Spectrum2_001652",
      "Spectrum5_000353",
      "3,7-Dihydroxy-2-Phenyl-4H-1-Benzopyran-4-One",
      "Oprea1_793720",
      "KBioSS_001942",
      "KBioGR_002253",
    ],
  },
  {
    primary_id: "D0JL3O",
    names: ["NICARBAZIN"],
  },
  {
    primary_id: "D0JL3Y",
    names: ["HuGAL-F2"],
  },
  {
    primary_id: "D0JL4D",
    names: ["CBT-101 + CBT-501"],
  },
  {
    primary_id: "D0JL8I",
    names: ["ERYTHRIBYSSIN A"],
  },
  {
    primary_id: "D0JM0G",
    names: ["Non-Hodgkin's Lymphoma Vaccine"],
  },
  {
    primary_id: "D0JM0Z",
    names: [
      "3-Methylpyridine",
      "3-METHYLPYRIDINE",
      "3-Picoline",
      "108-99-6",
      "Beta-Picoline",
      "Pyridine, 3-Methyl-",
      "M-Picoline",
      "M-Methylpyridine",
      "B-Picoline",
      "Beta-Methylpyridine",
      "3-Methyl Pyridine",
      ".beta.-Methylpyridine",
      "NSC 18251",
      ".beta.-Picoline",
      "Meta-Methylpyridine",
      "UNII-B083J4KF7F",
      "3-Mepy",
      "CCRIS 1722",
      "HSDB 4254",
      "EINECS 203-636-9",
      "AI3-24110",
      "B083J4KF7F",
      "3-Methylpyridin",
      "CHEBI:39922",
      "ITQTTZVARXURQS-UHFFFAOYSA-N",
      "3-Picoline, 99%",
      "DSSTox_CID_1897",
      "DSSTox_RID_76391",
      "DSSTox_GSID_21897",
      "CAS-108-99-6",
      "Bpicolin",
      "3-Methypyridine",
      "5-Methylpyridine",
      "3-Methyl-Pyridin",
      "3-Methyl Pyridin",
    ],
  },
  {
    primary_id: "D0JM7W",
    names: ["Phenylthioacetate Derivative 1"],
  },
  {
    primary_id: "D0JM7Z",
    names: ["ASP8062"],
  },
  {
    primary_id: "D0JN0A",
    names: ["PMID28092474-Compound-33h"],
  },
  {
    primary_id: "D0JN0N",
    names: ["N-{4'-[(Trifluoromethyl)Thio]-4-Biphenyl}urea", "CHEMBL244511", "SCHEMBL5434921", "BDBM50220203"],
  },
  {
    primary_id: "D0JN3M",
    names: ["ITAMELINE"],
  },
  {
    primary_id: "D0JN5H",
    names: ["Trunkamide"],
  },
  {
    primary_id: "D0JN5N",
    names: ["Laquinimod"],
  },
  {
    primary_id: "D0JN5O",
    names: ["PMID25435179-Compound-EP20142759295NP-1"],
  },
  {
    primary_id: "D0JN6P",
    names: ["PEPIDH1M"],
  },
  {
    primary_id: "D0JN7L",
    names: ["PMID27599163-Compound-77"],
  },
  {
    primary_id: "D0JN8C",
    names: ["MRNA-1325"],
  },
  {
    primary_id: "D0JN8Q",
    names: ["N-Benzoyl-Phenylalanyl-Glycine-Nitrile"],
  },
  {
    primary_id: "D0JN9R",
    names: [
      "8-Benzyloxycaffeine",
      "CHEMBL602259",
      "8-(Benzyloxy)-1,3,7-Trimethyl-3,7-Dihydro-1h-Purine-2,6-Dione",
      "5422-51-5",
      "NSC11301",
      "AC1L5CNO",
      "ChemDiv2_004644",
      "AC1Q6NU8",
      "MLS000115142",
      "Cid_223566",
      "SCHEMBL15648367",
      "CTK5A0068",
      "DTXSID60279114",
      "HMS1382D02",
      "HMS2252G09",
      "NSC-11301",
      "BDBM50306696",
      "ZINC37868689",
      "AKOS001694622",
      "CCG-117368",
      "IDI1_003359",
      "SMR000092353",
    ],
  },
  {
    primary_id: "D0JN9Z",
    names: ["DB103"],
  },
  {
    primary_id: "D0JO0I",
    names: ["VBY- 825"],
  },
  {
    primary_id: "D0JO2A",
    names: ["PMID26666989-Compound-Figure9left01"],
  },
  {
    primary_id: "D0JO3U",
    names: ["Elliptinium Acetate", "Celiptium (TN)"],
  },
  {
    primary_id: "D0JO3X",
    names: ["AG-SDT102"],
  },
  {
    primary_id: "D0JO6Z",
    names: ["Benzyl Amine Derivative 2"],
  },
  {
    primary_id: "D0JO7Y",
    names: ["Fulvestrant"],
  },
  {
    primary_id: "D0JO8Q",
    names: ["KYS-05071"],
  },
  {
    primary_id: "D0JO8T",
    names: ["ACER-003"],
  },
  {
    primary_id: "D0JO8Z",
    names: ["Fosfestrol"],
  },
  {
    primary_id: "D0JO9M",
    names: ["Anti-HCD70 CAR Transduced PBL"],
  },
  {
    primary_id: "D0JP4A",
    names: ["OsteoCel"],
  },
  {
    primary_id: "D0JP4H",
    names: [
      "Bethanidine",
      "Betanidina",
      "Betanidine",
      "Betanidinum",
      "Betanidole",
      "Bethanid",
      "Esbatal",
      "Regulin",
      "Tenathan",
      "Bethanidine Hemisulfate",
      "Bethanidinesulfate",
      "BW 467C60",
      "BW467C60",
      "Betanidina [INN-Spanish]",
      "Betanidine [INN:BAN]",
      "Betanidinum [INN-Latin]",
      "Esbatal (TN)",
      "Regulin (TN)",
      "Tenathan (TN)",
      "Bethanidine Sulfate (2:1)",
      "Ulfate (2:1)",
      "BW-467-C-60;Bethanidine Sulfate (2:1) (USAN)",
      "Bethanidine, Sulfate (2:1)",
      "Guanidine, 1-Benzyl-2,3-Dimethyl-(8CI)",
      "Guanidine, 1-Benzyl-2,3-Dimethyl-, S",
      "N-Benzyl-N',N''-Dimethylguanidine Sulfate",
      "N,N'-Dimethyl-N''-(Phenylmethyl)-Guanidine",
      "Sulfuric Acid Compound With N''-Benzyl-N,N'-Dimethylguanidine (1:1)",
      "Guanidine, N, N'-Dimethyl-N''-(Phenylmethyl)-, Sulfate (2:1)",
      "1,2-Dimethyl-3-(Phenylmethyl)Guanidine",
      "1-BENZYL-2,3-DIMETHYLGUANIDINE",
      "1-Benzyl-2,3-Dimethylguanidine Sulfate",
      "1-Benzyl-2,3-Dimethylguanidine Sulfate (1:1/2)",
      "1-Benzyl-2,3-Dimethylguanidinium Sulfate",
      "2-Benzyl-1,3-Dimethylguanidine",
    ],
  },
  {
    primary_id: "D0JP6A",
    names: ["PMID25482888-Compound-6"],
  },
  {
    primary_id: "D0JP7S",
    names: ["PMID24900824C2-3-1"],
  },
  {
    primary_id: "D0JP8D",
    names: ["GSK249320"],
  },
  {
    primary_id: "D0JQ0Z",
    names: ["PMID28766366-Compound-Scheme8NCGC-00189310"],
  },
  {
    primary_id: "D0JQ1D",
    names: ["Ro 41-5253"],
  },
  {
    primary_id: "D0JQ1T",
    names: [
      "2-[4-(1H-Indol-5-Yl)-Phenyl]-Propionic Acid",
      "CHEMBL104592",
      "BDBM50074934",
      "2-[4-(1H-Indole-5-Yl)Phenyl]propanoic Acid",
    ],
  },
  {
    primary_id: "D0JQ2W",
    names: ["GBM-Lamp-Vax"],
  },
  {
    primary_id: "D0JQ3X",
    names: ["4-Fluoro-N-(4-(Methylsulfonyl)Phenyl)Aniline", "CHEMBL1240925"],
  },
  {
    primary_id: "D0JQ4R",
    names: ["AA-10022"],
  },
  {
    primary_id: "D0JQ4S",
    names: ["Xmab-7195"],
  },
  {
    primary_id: "D0JQ7W",
    names: [
      "2-Bromo-4-Methylphenyl 2-Nitrobenzoate",
      "CHEMBL549456",
      "AC1LDUV8",
      "Oprea1_572597",
      "Oprea1_573870",
      "ARONIS001613",
      "ZINC38499",
      "MolPort-001-525-391",
      "KS-00003V1E",
      "STK096466",
      "BDBM50297528",
      "AKOS000491705",
      "MCULE-5867447196",
      "ST054603",
      "(2-Bromo-4-Methylphenyl) 2-Nitrobenzoate",
    ],
  },
  {
    primary_id: "D0JQ7Y",
    names: ["Ximelagatran"],
  },
  {
    primary_id: "D0JQ9D",
    names: ["Immune Globulin Intravenous (Human)"],
  },
  {
    primary_id: "D0JQ9Z",
    names: ["Disulfiram Analog 2"],
  },
  {
    primary_id: "D0JR0F",
    names: ["Thiazole Carboxamide Derivative 25"],
  },
  {
    primary_id: "D0JR0G",
    names: ["(2'Z,3'E)-5-Chloro-5'-Hydroxy-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0JR0P",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 4"],
  },
  {
    primary_id: "D0JR0W",
    names: [
      "[18F]FITM",
      "GTPL6213",
      "CHEMBL1771389",
      "4-(18F)Fluoro-N-Methyl-N-(4-{6-[(Propan-2-Yl)Amino]pyrimidin-4-Yl}-1,3-Thiazol-2-Yl)Benzamide",
    ],
  },
  {
    primary_id: "D0JR2C",
    names: ["PMID29473428-Compound-29"],
  },
  {
    primary_id: "D0JR3W",
    names: ["PMID23441572C24"],
  },
  {
    primary_id: "D0JR3Y",
    names: ["2-Heptyl-1(3)H-Anthra[1,2-D]imidazole-6,11-Dione", "CHEMBL596063"],
  },
  {
    primary_id: "D0JR5G",
    names: ["PMID26924192-Compound-50"],
  },
  {
    primary_id: "D0JR7X",
    names: ["PMID25656651-Compound-29a"],
  },
  {
    primary_id: "D0JR9L",
    names: ["PMID29671355-Compound-38a"],
  },
  {
    primary_id: "D0JR9V",
    names: ["JBP-1"],
  },
  {
    primary_id: "D0JS0W",
    names: ["PF-05230907"],
  },
  {
    primary_id: "D0JS2A",
    names: ["Somatotropin Rdna Original", "Accretropin (TN)"],
  },
  {
    primary_id: "D0JS2L",
    names: ["Anti-RSV Mab", "Anti-RSV MAb, Trellis", "Anti-RSV MAb, MedImmune/Trellis"],
  },
  {
    primary_id: "D0JS4B",
    names: ["[14C]methylamine", "[14C]-Methylamine"],
  },
  {
    primary_id: "D0JS4E",
    names: ["ICM-3"],
  },
  {
    primary_id: "D0JS7E",
    names: ["LMS-611 Inhalant"],
  },
  {
    primary_id: "D0JS8H",
    names: ["ME1111"],
  },
  {
    primary_id: "D0JS9U",
    names: ["GSK-2231395A"],
  },
  {
    primary_id: "D0JT0B",
    names: ["Amidine Compound 5"],
  },
  {
    primary_id: "D0JT0U",
    names: ["CAR-T Cells Targeting CD33"],
  },
  {
    primary_id: "D0JT1P",
    names: [
      "Brain Tumor Vaccine",
      "Brain Cancer Vaccine, IRC",
      "Brain Tumor Vaccine, IRC",
      "IR-850",
      "Established Cancer Cell Line Therapy (Brain Cancer), IRC",
      "GM-CSF Vaccine (Brain Cancer), IRC",
    ],
  },
  {
    primary_id: "D0JT2N",
    names: [
      "DL-Threo-Beta-Hydroxyaspartate",
      "7298-99-9",
      "(2S,3S)-2-Amino-3-Hydroxybutanedioic Acid",
      "(3S)-3-Hydroxy-L-Aspartic Acid",
      "L-(-)-Threo-3-Hydroxyaspartic Acid",
      "CHEBI:10696",
      "L-Threo-3-Hydroxyaspartate",
      "Threo-3-Hydroxy-L-Aspartate",
      "SR-01000075907",
      "Threo-3-Hydroxy-DL-Aspartic Acid",
      "EINECS 224-299-4",
      "Beta-Hydroxyaspartic Acid, L-Threo-",
    ],
  },
  {
    primary_id: "D0JT3F",
    names: ["PMID26609882-Compound-35"],
  },
  {
    primary_id: "D0JT3M",
    names: ["[2-(3-Benzyl-Indol-1-Yl)-Ethyl]-Dimethyl-Amine", "CHEMBL186621", "SCHEMBL3328890"],
  },
  {
    primary_id: "D0JT5I",
    names: ["PF-04457845"],
  },
  {
    primary_id: "D0JT8B",
    names: ["PMID25666693-Compound-147"],
  },
  {
    primary_id: "D0JT8Y",
    names: ["4-(3-Nitro-Benzylideneamino)-Benzenesulfonamide", "CHEMBL404921", "BDBM50371766", "AKOS003620777"],
  },
  {
    primary_id: "D0JU1R",
    names: ["Pyrrolo-Pyrazine Derivative 3"],
  },
  {
    primary_id: "D0JU3A",
    names: ["U-Strip Diabetes"],
  },
  {
    primary_id: "D0JU4G",
    names: ["Ginkgolide M"],
  },
  {
    primary_id: "D0JU4R",
    names: ["Thiazole Derivative 4"],
  },
  {
    primary_id: "D0JU4Z",
    names: ["4-Amino-3,5-Di-Substituted-Thiazole Derivative 1"],
  },
  {
    primary_id: "D0JU5X",
    names: ["Bardoxolone Methyl", "BARD", "WPTTVJLTNAWYAO-OYWPANLISA-N", "218600-53-4"],
  },
  {
    primary_id: "D0JU7T",
    names: ["Pyrimidinyl Compound 2"],
  },
  {
    primary_id: "D0JU8E",
    names: ["Pyrimidine Derivative 12"],
  },
  {
    primary_id: "D0JU9N",
    names: [
      "BMS-740808",
      "280118-23-2",
      "BMS 740808",
      "UNII-LDD74E663F",
      "BMS740808",
      "LDD74E663F",
      "1-(3-AMINO-1,2-BENZISOXAZOL-5-YL)-6-(2'-{[(3R)-3-HYDROXYPYRROLIDIN-1-YL]METHYL}BIPHENYL-4-YL)-3-(TRIFLUOROMETHYL)-1,4,5,6-TETRAHYDRO-7H-PYRAZOLO[3,4-C]PYRIDIN-7-ONE",
      "2fzz",
      "1-(3-Amino-1,2-Benzoxazol-5-Yl)-6-[4-[2-[[(3R)-3-Hydroxypyrrolidin-1-Yl]methyl]phenyl]phenyl]-3-(Trifluoromethyl)-4,5-Dihydropyrazolo[3,4-C]pyridin-7-One",
      "5QC",
      "AC1OCAC6",
      "SCHEMBL8360280",
      "CHEMBL378093",
      "BDBM12693",
      "DTXSID40182293",
      "C31H27F3N6O3",
      "BCPP000326",
      "BCP23400",
      "ZINC14210470",
    ],
  },
  {
    primary_id: "D0JV0F",
    names: ["KNI-10106"],
  },
  {
    primary_id: "D0JV0I",
    names: ["ISIS 100743"],
  },
  {
    primary_id: "D0JV0O",
    names: ["GM608"],
  },
  {
    primary_id: "D0JV2E",
    names: [
      "ETEC Vaccine, Vaccine Technologies",
      "E Coli Vaccine, AVANT",
      "E Coli Vaccine, Celldex",
      "E Coli Vaccine, Vaccine Technologies",
      "ETEC Vaccine, AVANT",
      "ETEC Vaccine, Celldex",
    ],
  },
  {
    primary_id: "D0JV2P",
    names: ["BTP2"],
  },
  {
    primary_id: "D0JV2S",
    names: [
      "VU0119498",
      "1-(4-Bromobenzyl)Indole-2,3-Dione",
      "79183-37-2",
      "1-(4-Bromobenzyl)-1H-Indole-2,3-Dione",
      "CHEMBL523685",
      "1-[(4-Bromophenyl)Methyl]indole-2,3-Dione",
      "1-[(4-Bromophenyl)Methyl]-2,3-Dihydro-1H-Indole-2,3-Dione",
      "STK215777",
      "1-(4-Bromobenzyl)Indoline-2,3-Dione",
      "AC1Q24VD",
      "AC1MHO09",
      "GTPL7632",
      "SCHEMBL5430888",
      "Cid_3008304",
      "MolPort-002-341-984",
      "ZINC2454837",
      "BDBM50258439",
      "AKOS000245456",
      "CCG-198352",
      "NE20417",
      "MCULE-6184534031",
      "VU 0119498",
      "ST50892639",
      "VU-0119498",
      "EU-0067620",
      "VU0119498-2",
      "1-[(4-Bromophenyl)Methyl]indoline-2,3-Dione",
    ],
  },
  {
    primary_id: "D0JV4A",
    names: ["PA08140"],
  },
  {
    primary_id: "D0JV4Q",
    names: ["Lanoconazole", "Astat", "NND-318", "TJN-318"],
  },
  {
    primary_id: "D0JV4R",
    names: ["TriCAR-T-CD19"],
  },
  {
    primary_id: "D0JV5J",
    names: [
      "5-Boronothiophene-2-Carboxylic Acid",
      "465515-31-5",
      "2-Carboxythiophene-5-Boronic Acid",
      "5-Carboxythiophene-2-Boronic Acid",
      "5-(Dihydroxyboryl)Thiophene-2-Carboxylic Acid",
      "5-(Dihydroxyboryl)-2-Thiophenecarboxylic Acid",
      "C5H5BO4S",
      "CHEMBL573906",
      "5-(Dihydroxyboranyl)Thiophene-2-Carboxylic Acid",
      "AF-399/25053010",
      "AK-26004",
      "PubChem7851",
      "AC1MCWDJ",
      "ACMC-1AP1P",
      "SCHEMBL363172",
      "KS-00000JEI",
      "CTK1D5505",
      "2-Carboxythiophene-5-Boronicacid",
      "DTXSID90381557",
      "MolPort-000-144-982",
      "OQGIKNPOYTVNNF-UHFFFAOYSA-N",
      "2-Carboxy-5-Thiopheneboronic Acid",
    ],
  },
  {
    primary_id: "D0JV5L",
    names: ["4-Methyl-5-(4-Piperidyl)Isothiazol-3-Ol", "CHEMBL204110"],
  },
  {
    primary_id: "D0JV8Q",
    names: ["XB-05"],
  },
  {
    primary_id: "D0JV8Z",
    names: ["HIV Recombinant Proteins Vaccine"],
  },
  {
    primary_id: "D0JW0V",
    names: ["GCS-100"],
  },
  {
    primary_id: "D0JW1C",
    names: [
      "Incensole Acetate",
      "34701-53-6",
      "Incensol Acetate",
      "GTPL4220",
      "1,5,9-Trimethyl-12-(Propan-2-Yl)-15-Oxabicyclo[10.2.1]pentadeca-5,9-Dien-2-Yl Acetate",
    ],
  },
  {
    primary_id: "D0JW1R",
    names: ["Pyrroles"],
  },
  {
    primary_id: "D0JW1S",
    names: [
      "Meglumine Gadoterate",
      "Artirem",
      "Dotarem",
      "Magnescope",
      "Gadoteric Acid",
      "Magnescope Syringe",
      "EK-5504",
      "Gadolinium-DOTA Meglumine",
      "Gadolium-DOTA Meglumine",
      "Gadoteric Acid/Meglumine",
    ],
  },
  {
    primary_id: "D0JW2O",
    names: ["Anti-HER3/EGFR DAF"],
  },
  {
    primary_id: "D0JW2Y",
    names: [
      "Anti-Clostridium Difficile Mabs",
      "Anti-Clostridium Difficile MAbs, Mapp Biopharmaceutical/University Of Arizona",
      "Anti-Clostridium Difficile Monoclonal Antibodies, Mapp Biopharmaceutical/University Of Arizona",
    ],
  },
  {
    primary_id: "D0JW6R",
    names: ["LAM-002"],
  },
  {
    primary_id: "D0JW7D",
    names: [
      "Omiganan",
      "CLS-001",
      "MBI-594AN",
      "MX-594AN",
      "Omiganan (Topical, Acne/Rosacea)",
      "Omiganan (Topical, Acne/Rosacea), Biowest Therapeutics/Cutanea",
      "Omiganan (Topical, Acne/Rosacea), MIGENIX/Cutanea",
    ],
  },
  {
    primary_id: "D0JW7I",
    names: ["RON8 Mab"],
  },
  {
    primary_id: "D0JW7T",
    names: ["Amide Derivative 1"],
  },
  {
    primary_id: "D0JW9E",
    names: ["CAR-T Cells Targeting Mucl"],
  },
  {
    primary_id: "D0JX2S",
    names: ["3-(2-Carbamoyl-5-Mercaptopentyl)Benzoic Acid"],
  },
  {
    primary_id: "D0JX3P",
    names: ["YAWF-NH2", "CHEMBL445690"],
  },
  {
    primary_id: "D0JX4I",
    names: ["PMID25666693-Compound-62"],
  },
  {
    primary_id: "D0JX6K",
    names: ["SAGE-718"],
  },
  {
    primary_id: "D0JX7K",
    names: ["ActoBiotics", "ActoBiotics (Oral, Allergic Diseases)"],
  },
  {
    primary_id: "D0JX7M",
    names: ["CD123CAR-41BB-CD3zeta-EGFRt-Expressing T Cells"],
  },
  {
    primary_id: "D0JY0L",
    names: ["7-Azaindole Derivative 9"],
  },
  {
    primary_id: "D0JY0M",
    names: ["CD28 And CD137 CAR-T Cells"],
  },
  {
    primary_id: "D0JY1I",
    names: ["PMID25656651-Compound-19a"],
  },
  {
    primary_id: "D0JY2I",
    names: ["Pyrrolidine Derivative 7"],
  },
  {
    primary_id: "D0JY2K",
    names: ["NS-1643"],
  },
  {
    primary_id: "D0JY2X",
    names: ["Neu-2000"],
  },
  {
    primary_id: "D0JY3V",
    names: ["Racecadotril", "Acetorphan", "Hidrasec", "Tiorfan"],
  },
  {
    primary_id: "D0JY4C",
    names: ["PMID25522065-Compound-19"],
  },
  {
    primary_id: "D0JY4Y",
    names: [
      "E-3040",
      "E 3040",
      "CHEMBL105139",
      "145096-30-6",
      "E3040",
      "AC1L9EDQ",
      "CHEBI:4732",
      "6-Hydroxy-5,7-Dimethyl-2-Methylamino-4-(3-Pyridylmethyl)Benzothiazole",
      "SCHEMBL6009376",
      "DTXSID80162877",
      "CHEBI:271098",
      "5,7-Dimethyl-2-(Methylamino)-4-(3-Pyridinylmethyl)-6-Benzothiazolol",
      "BDBM50037037",
      "6-Benzothiazolol, 5,7-Dimethyl-2-(Methylamino)-4-(3-Pyridinylmethyl)-",
      "C11593",
      "L001447",
      "5,7-Dimethyl-2-Methylamino-4-Pyridin-3-Ylmethyl-Benzothiazol-6-Ol",
      "6-Hydroxy-5,7-Dimethyl-2-Methylamino-4-(3-Pyridylmethyl) Benzothiazole",
    ],
  },
  {
    primary_id: "D0JY5S",
    names: ["Methantheline"],
  },
  {
    primary_id: "D0JY6J",
    names: [
      "6-ETHYL-5-PHENYLPYRIMIDINE-2,4-DIAMINE",
      "2,4-Diamino-5-Phenyl-6-Ethylpyrimidine",
      "UNII-KMJ59LN22F",
      "2,4-Diamino-6-Ethyl-5-Phenylpyrimidine",
      "TCMDC-124296",
      "Pyrimidine, 2,4-Diamino-6-Ethyl-5-Phenyl-",
      "27653-49-2",
      "BRN 0190097",
      "KMJ59LN22F",
      "GNF-PF-26",
      "CHEMBL22148",
      "Diamino-5-Phenyl-6-Ethylpyrimidine, 2,4-",
      "CP7",
      "2,4-Pyrimidinediamine, 6-Ethyl-5-Phenyl-",
      "AC1L3PJ1",
      "Oprea1_813184",
      "SCHEMBL3498787",
      "CTK8H9609",
      "BDBM18788",
      "DTXSID70182029",
      "XREDUPOVEQDQQS-UHFFFAOYSA-N",
      "MolPort-002-484-045",
      "ZINC13282257",
      "AKOS003622581",
      "MCULE-8548953211",
      "DB07577",
    ],
  },
  {
    primary_id: "D0JY6R",
    names: ["MTL-101"],
  },
  {
    primary_id: "D0JY8T",
    names: [
      "Bazedoxifene",
      "Brilence",
      "Conbriza",
      "Viviant",
      "Bazedoxifene Acetate",
      "TSE-424",
      "WAY-140424",
      "WAY-TES 424",
    ],
  },
  {
    primary_id: "D0JY9B",
    names: ["(R)-N2-{4-[(3-Chlorobenzyl)Oxy]benzyl}alaninamide", "CHEMBL242835", "SCHEMBL2185997"],
  },
  {
    primary_id: "D0JY9S",
    names: ["Dihydropyridine Compound 2"],
  },
  {
    primary_id: "D0JZ2B",
    names: ["Alkynyl-Substituted Pyrimidinyl-Pyrrole Derivative 1"],
  },
  {
    primary_id: "D0JZ3S",
    names: [
      "4r-Fluoro-N6-Ethanimidoyl-L-Lysine",
      "4R-FLUORO-N6-ETHANIMIDOYL-L-LYSINE",
      "I58",
      "AC1L9LS8",
      "SCHEMBL4308483",
      "DB01835",
      "(4R)-N~6~-[(1Z)-Ethanimidoyl]-4-Fluoro-L-Lysine",
      "(2S,4R)-2-Amino-6-Ethanimidamido-4-Fluorohexanoic Acid",
      "(2S,4R)-2-Amino-6-(1-Aminoethylideneamino)-4-Fluorohexanoic Acid",
    ],
  },
  {
    primary_id: "D0JZ4C",
    names: ["Px563L"],
  },
  {
    primary_id: "D0JZ4R",
    names: ["ISIS 122970"],
  },
  {
    primary_id: "D0JZ4W",
    names: ["PMID27744724-Compound-29"],
  },
  {
    primary_id: "D0JZ5Y",
    names: ["Gp75 DNA Vaccine"],
  },
  {
    primary_id: "D0JZ8P",
    names: [
      "10-Propyl-10H-Dibenzo[b,f][1,4]thiazepin-11-One",
      "10Pr-DBTA-11one",
      "10-Propyl-Dibenz(B,f)(1,4)Thiazepin-11-(10H)-One",
      "135810-44-5",
      "5-Propylbenzo[b][1,4]benzothiazepin-6-One",
      "10-Propyl-Dibenz[b,f][1,4]thiazepin-11-(10H)-One",
      "Dibenzoxazepinone 5",
      "AC1L9R9G",
      "CHEMBL55258",
      "BDBM1994",
      "SCHEMBL9004259",
      "DTXSID30159535",
      "ARJSKRFEYLKXQL-UHFFFAOYSA-N",
      "10-Propyldibenzo[b,f][1,4]thiazepin-11(10H)-One",
      "10-N-Propyl-Dibenz[b,f][1,4]thiazepin-11(10H)-One",
    ],
  },
  {
    primary_id: "D0JZ8X",
    names: ["HGTV43"],
  },
  {
    primary_id: "D0JZ9L",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 4"],
  },
  {
    primary_id: "D0K0AT",
    names: ["SARALASIN"],
  },
  {
    primary_id: "D0K0CJ",
    names: ["F-G-G-F-T-G-Aib-R-K-S-A-R-K-L-A-N-Q-COOH"],
  },
  {
    primary_id: "D0K0CK",
    names: ["AZ-17"],
  },
  {
    primary_id: "D0K0DB",
    names: ["RO-25-6981"],
  },
  {
    primary_id: "D0K0DV",
    names: ["N-Phenyl-5-Phenyl-Pyrazolin-3-Yl Amide Derivative 2"],
  },
  {
    primary_id: "D0K0DY",
    names: ["Zinc Hyaluronate", "Curioscii (TN)"],
  },
  {
    primary_id: "D0K0EK",
    names: [
      "Prasterone",
      "Dehydroepiandrosterone",
      "DHEA",
      "53-43-0",
      "PRASTERONE",
      "Dehydroisoandrosterone",
      "Androstenolone",
      "Trans-Dehydroandrosterone",
      "Psicosterone",
      "Diandrone",
      "Diandron",
      "3beta-Hydroxyandrost-5-En-17-One",
      "17-Hormoforin",
      "Prestara",
      "17-Chetovis",
      "Andrestenol",
      "5-Dehydroepiandrosterone",
      "Intrarosa",
      "Siscelar Plus",
      "5,6-Didehydroisoandrosterone",
      "Dehydro-Epi-Androsterone",
      "Prasteronum",
      "5,6-Dehydroisoandrosterone",
      "Prasterona",
      "Epiandrosterone, 5-Dehydro-",
      "Caswell No 051F",
      "5,6-Dehydroisoandrostorone",
    ],
  },
  {
    primary_id: "D0K0EW",
    names: ["N-(1-Adamantyl)-N'-Propylsulfamide", "CHEMBL374746", "SCHEMBL3107083", "ZINC35091097", "BDBM50300769"],
  },
  {
    primary_id: "D0K0FI",
    names: [
      "7-Methoxy-1-Naphthylpiperazine",
      "GTPL6",
      "SCHEMBL9478699",
      "Oxiran-2-Ylmethyl N-[2-(Dimethyl-Trimethylsilylsilyl)Ethyl]carbamate",
    ],
  },
  {
    primary_id: "D0K0FV",
    names: ["QR-334"],
  },
  {
    primary_id: "D0K0FZ",
    names: ["[14C]acetylCoA", "[14C]-AcetylCoA"],
  },
  {
    primary_id: "D0K0GF",
    names: ["SSR-181507"],
  },
  {
    primary_id: "D0K0GJ",
    names: ["PMID29865878-Compound-48"],
  },
  {
    primary_id: "D0K0GN",
    names: ["LDI-200", "Chorionic Gonadotropin, Milkhaus", "HCG, Milkhaus", "Oncology Therapeutics, Milkhaus"],
  },
  {
    primary_id: "D0K0GU",
    names: ["8-Furan-3-Yl-6-Styryl-Naphthalene-2-Carboxamidine", "CHEMBL360195"],
  },
  {
    primary_id: "D0K0HD",
    names: ["Ethyl 2-(2-Oxohexadecanamido)Acetate"],
  },
  {
    primary_id: "D0K0HU",
    names: [
      "N,N-Dimethyl-2-(2'-Methylbiphenyl-3-Yl)Ethanamine",
      "CHEMBL395870",
      "BDBM50210123",
      "N,N-Dimethyl-2-(2''-Methylbiphenyl-3-Yl)Ethanamine",
    ],
  },
  {
    primary_id: "D0K0IC",
    names: [
      "4-(2-Phenyl-1H-Indol-3-Yl)Phenol",
      "CHEMBL371012",
      "SCHEMBL7041571",
      "OITCYCYIUVYFKQ-UHFFFAOYSA-N",
      "BDBM50175419",
      "4-(2-Phenyl-1H-Indol-3-Yl)-Phenol",
    ],
  },
  {
    primary_id: "D0K0IM",
    names: ["MK-8666"],
  },
  {
    primary_id: "D0K0IN",
    names: ["KBSA-301"],
  },
  {
    primary_id: "D0K0IO",
    names: ["Pyrimidine Derivative 21"],
  },
  {
    primary_id: "D0K0IS",
    names: [
      "Polyglutamate Camptothecin",
      "CT-2106",
      "PG-Interferon",
      "Polyglutamate Camptothecin, Cell Therapeutics",
      "Polyglutamate-Camptothecin",
      "CPT-Gly-PG",
      "Camptothecin-Glycine-Polyglutamate",
      "PG-CPT, Cell Therapeutics",
      "PG-Camptothecin, CT",
      "Polyglutamate-Linked Anticancer Agents, Cell Therapeutics",
      "PG-G-CSF, Cell Therapeutics",
    ],
  },
  {
    primary_id: "D0K0IW",
    names: ["CRA-028129"],
  },
  {
    primary_id: "D0K0JJ",
    names: ["[HO1][Lys8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D0K0JP",
    names: ["Phenyl 4-(Octyloxy)Phenylcarbamate"],
  },
  {
    primary_id: "D0K0JQ",
    names: [
      "Dilmapimod",
      "SB-681323",
      "444606-18-2",
      "UNII-Q3238VQW0N",
      "GW 681323",
      "SB 681323",
      "Q3238VQW0N",
      "GW681323",
      "SB681323",
      "Dilmapimod [USAN:INN]",
      "GSK 681323",
      "Dilmapimod (USAN/INN)",
      "GTPL7815",
      "SCHEMBL1065268",
      "CHEMBL2103838",
      "ORVNHOYNEHYKJG-UHFFFAOYSA-N",
      "BCP23819",
      "ZINC34997404",
      "CS-6731",
      "DB12140",
      "SB16737",
      "8-(2,6-Difluorophenyl)-4-(4-Fluoro-2-Methylphenyl)-2-[[2-Hydroxy-1-(Hydroxymethyl)Ethyl]amino]-Pyrido[2,3-D]pyrimidin-7(8H)-One",
      "HY-10404",
      "GW-681323",
      "DB-070558",
      "FT-0718750",
      "J3.498.915J",
      "D09602",
    ],
  },
  {
    primary_id: "D0K0KG",
    names: ["Heterodimeric IL-15"],
  },
  {
    primary_id: "D0K0KH",
    names: ["Tropisetron"],
  },
  {
    primary_id: "D0K0KJ",
    names: ["LF-521"],
  },
  {
    primary_id: "D0K0KP",
    names: ["GE-68"],
  },
  {
    primary_id: "D0K0KQ",
    names: ["BC-819"],
  },
  {
    primary_id: "D0K0KR",
    names: [
      "AR-129330",
      "CHEMBL193438",
      "CHEMBL537846",
      "SCHEMBL3938380",
      "SCHEMBL3941081",
      "SCHEMBL3938377",
      "CHEMBL1188966",
      "NWUCPBLQECHRFK-QAQDUYKDSA-N",
      "BDBM50170189",
      "BDBM50166555",
      "Trans-4-Bromo-N-{4-[(4-Dimethylamino-Quinazolin-2-Ylamino)-Methyl]-Cyclohexylmethyl}-2-Trifluoromethoxy-Benzenesulfonamide",
      "4-Bromo-N-{4-[(4-Dimethylamino-Quinazolin-2-Ylamino)-Methyl]-Cyclohexylmethyl}-2-Trifluoromethoxy-Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0K0KT",
    names: ["Cbz-Glu(OtBu)-Ala-LeuVSMe", "CHEMBL208015"],
  },
  {
    primary_id: "D0K0LA",
    names: ["LC34AD3"],
  },
  {
    primary_id: "D0K0LG",
    names: ["CPG23NEUR"],
  },
  {
    primary_id: "D0K0MB",
    names: ["LP-10218"],
  },
  {
    primary_id: "D0K0MI",
    names: ["Sulphur Hexafluoride Microbubble Ultrasound Agent"],
  },
  {
    primary_id: "D0K0MW",
    names: [
      "Lofexidine",
      "Britlofex",
      "Lofexidina",
      "Lofexidinum",
      "Britlofex (TN)",
      "Lofexidina [INN-Spanish]",
      "Lofexidine (INN)",
      "Lofexidine [INN:BAN]",
      "Lofexidinum [INN-Latin]",
      "1H-Imidazole, 2-(1-(2,6-Dichlorophenoxy)Ethyl)-4,5-Dihydro-(9CI)",
      "2-(1-(2,6-Dichlorophenoxy)Ethyl)-4,5-Dihydro-1H-Imidazole",
      "2-(A-(2,6-Dichlorophenoxy)Ethyl)2-Imidazoline",
      "2-(Alpha-(2,6-Dichlorophenoxy)Ethyl)2-Imidazoline",
      "2-(Alpha-(2,6-Dichlorophenoxy)Ethyl) Delta-2-Imidazoline",
      "2-[1-(2,6-Dichlorophenoxy)Ethyl]-4,5-Dihydro-1H-Imidazole",
      "2-{1-[(2,6-Dichlorophenyl)Oxy]ethyl}-4,5-Dihydro-1H-Imidazole",
    ],
  },
  {
    primary_id: "D0K0NP",
    names: [
      "DMP-802",
      "CHEMBL117989",
      "CHEMBL275611",
      "SCHEMBL7748060",
      "BDBM50075566",
      "(S)-3-{2-[(R)-3-(4-Carbamimidoyl-Phenyl)-4,5-Dihydro-Isoxazol-5-Yl]-Acetylamino}-2-(3,5-Dimethyl-Isoxazole-4-Sulfonylamino)-Propionic Acid",
    ],
  },
  {
    primary_id: "D0K0OB",
    names: ["AL-18", "AL-1842", "AL-18 Series, Advenchen", "Bcr-Abl Tyrosine Kinase Inhibitors (Leukemia), Advenchen"],
  },
  {
    primary_id: "D0K0OR",
    names: [
      "1-(5-(Pyridin-2-Yl)Oxazol-2-Yl)Octan-1-One",
      "CHEMBL175021",
      "SCHEMBL3197441",
      "VLJKBQYNIDBQEJ-UHFFFAOYSA-N",
      "BDBM50163176",
    ],
  },
  {
    primary_id: "D0K0OT",
    names: [
      "GNF-PF-2224",
      "8-Cyclopentyl-1,3-Dipropylxanthine",
      "DPCPX",
      "102146-07-6",
      "8-Cyclopentyl-1,3-Dipropyl-1H-Purine-2,6(3H,7H)-Dione",
      "1,3-Dpcpx",
      "1,3-Dipropyl-8-Cyclopentylxanthine",
      "PD-116,948",
      "PD-116948",
      "UNII-9PTP4FOI9E",
      "Dipropylcyclopentylxanthine",
      "9PTP4FOI9E",
      "CHEMBL183",
      "MLS000069347",
      "CHEBI:73282",
      "8-Cyclopentyl-3,7-Dihydro-1,3-Dipropyl-1H-Purin-2,6-Dione",
      "8-Cyclopentyl-1,3-Dipropyl-7H-Purine-2,6-Dione",
      "1,3-DIPROPYL-8-CYCLOPENTYLXANTHINE [DPCPX]",
      "SMR000058434",
      "DPCPX, 1,3-Dipropyl-8-Cyclopentylxanthine",
    ],
  },
  {
    primary_id: "D0K0OU",
    names: ["MBX-1684"],
  },
  {
    primary_id: "D0K0OY",
    names: ["Glu-Trp-Pro-Arg-Pro-Gln-Ile-Pro-Pro"],
  },
  {
    primary_id: "D0K0OZ",
    names: ["Famotidine"],
  },
  {
    primary_id: "D0K0PG",
    names: ["PMID26882240-Compound-6"],
  },
  {
    primary_id: "D0K0PR",
    names: [
      "19alpha,24-Dihydroxyurs-12-En-3-On-28-Oic Acid",
      "CHEMBL494111",
      "BDBM50253117",
      "3-Oxo-19,24-Dihydroxyurs-12-Ene-28-Oic Acid",
    ],
  },
  {
    primary_id: "D0K0QU",
    names: [
      "(+)-(5S,8S,10S)-20-Methoxypuupehenol",
      "CHEMBL459185",
      "BDBM50242017",
      "(+)-(5S,8S,9R,10S)-15,20-Dimethoxypuupehenol",
    ],
  },
  {
    primary_id: "D0K0SA",
    names: ["N-(2-AMINOETHYL)-P-CHLOROBENZAMIDE"],
  },
  {
    primary_id: "D0K0SB",
    names: ["NKM"],
  },
  {
    primary_id: "D0K0SO",
    names: ["Cyclo(-D-Tyr-D-Arg-L-Arg-L-Nal-D-Ala-)", "CHEMBL373636"],
  },
  {
    primary_id: "D0K0SW",
    names: [
      "VLST-002",
      "AntiKine Antibodies (Autoimmune Disease/Inflammatory Disease), VLST",
      "Virulence Factor Mimics (Rheumatoid Arthritis/Crohn's Disease), VLST",
    ],
  },
  {
    primary_id: "D0K0TC",
    names: ["Sertraline"],
  },
  {
    primary_id: "D0K0TJ",
    names: ["SNX-2112"],
  },
  {
    primary_id: "D0K0TL",
    names: ["PMID25553724-Compound-WO2013082253 34"],
  },
  {
    primary_id: "D0K0TO",
    names: [
      "NSC-78017",
      "CHEMBL1288342",
      "AC1MW695",
      "ZINC1713901",
      "BDBM50332193",
      "2,6-Dimethyl-3H-Pyrrolo[3,2-F]quinolin-6-Ium",
    ],
  },
  {
    primary_id: "D0K0TR",
    names: [
      "3-(4-Hydroxy-Phenyl)-4H-Chromen-7-Ol",
      "CHEMBL195560",
      "4h-1-Benzopyran-7-Ol,3-(4-Hydroxyphenyl)-",
      "232261-61-9",
      "Isophenoxodiol",
      "SCHEMBL3755117",
      "BDBM50166895",
      "ZINC13643803",
      "KB-290777",
      "US8552057, 4",
    ],
  },
  {
    primary_id: "D0K0TY",
    names: ["N,N`-Bis-Alkyl Butylimmidazole 12b", "Compound 12b [PMID: 23376252]"],
  },
  {
    primary_id: "D0K0TZ",
    names: ["Multimeric-001", "BVX-M001", "Second-Generation Universal Influenza Vaccine, BiondVax Pharmaceuticals"],
  },
  {
    primary_id: "D0K0UJ",
    names: ["PMID20936789C31"],
  },
  {
    primary_id: "D0K0UM",
    names: ["PMID23232060C5"],
  },
  {
    primary_id: "D0K0VH",
    names: ["S-5751"],
  },
  {
    primary_id: "D0K0VO",
    names: ["Urapidil"],
  },
  {
    primary_id: "D0K0WA",
    names: ["CNTO-5825"],
  },
  {
    primary_id: "D0K0WK",
    names: [
      "NSC-341622",
      "66358-49-4",
      "UNII-Q1G3FJ6TLS",
      "NSC314622",
      "Q1G3FJ6TLS",
      "MLS002701805",
      "NSC-314622",
      "2,3-Dimethoxy-6-Methyl-5H-(1,3)Dioxolo(4',5':5,6)Indeno(1,2-C)Isoquinoline-5,12(6H)-Dione",
      "NSC 314622",
      "2,3-Dimethoxy-6-Methyl-5H-[1,3]dioxolo[4',5':5,6]indeno[1,2-C]isoquinoline-5,12(6H)-Dione",
      "NCIMech_000446",
      "AC1L75KV",
      "CHEMBL79513",
      "SCHEMBL14147251",
      "CTK5C4398",
      "DTXSID40216582",
      "ZINC1570831",
      "CCG-35623",
      "SMR001565399",
      "NCI60_002710",
      "5H-[1,6]indeno[1,2-C]isoquinoline-5,12-Dione, 2,3-Dimethoxy-6-Methyl-",
    ],
  },
  {
    primary_id: "D0K0XG",
    names: ["MF-8615"],
  },
  {
    primary_id: "D0K0XT",
    names: ["MR-1029"],
  },
  {
    primary_id: "D0K0XW",
    names: ["C-9138"],
  },
  {
    primary_id: "D0K0YC",
    names: ["PD-0325901"],
  },
  {
    primary_id: "D0K0YF",
    names: [
      "4-Fluorophenylboronic Acid",
      "1765-93-1",
      "4-Fluorobenzeneboronic Acid",
      "(4-Fluorophenyl)Boronic Acid",
      "4-Fluorophenyl Boronic Acid",
      "(4-Fluorophenyl)Boranediol",
      "P-Fluorophenylboronic Acid",
      "4-Fluorophenylbornic Acid",
      "4-Fluorophenylboric Acid",
      "(4-FLUOROPHENYL)DIHYDROXYBORON",
      "P-Fluorobenzeneboronic Acid",
      "4-Fluoro Phenylboronic Acid",
      "C6H6BFO2",
      "4-Fluoro Phenyl Boronic Acid",
      "CHEMBL344890",
      "(4-Fluorophenyl)Dihydroxyborane",
      "CHEBI:48661",
      "Boronic Acid, (4-Fluorophenyl)-",
      "LBUNNMJLXWQQBY-UHFFFAOYSA-N",
      "(P-FLUOROPHENYL)BORIC ACID",
    ],
  },
  {
    primary_id: "D0K0YO",
    names: ["Melanoma Antigens"],
  },
  {
    primary_id: "D0K0YX",
    names: [
      "5-(4'-Cyanobiphenyl-4-Yl)-3-Hydroxypentanoic Acid",
      "CHEMBL583588",
      "SCHEMBL5613483",
      "BDBM50300472",
      "5-(4''-Cyanobiphenyl-4-Yl)-3-Hydroxypentanoic Acid",
    ],
  },
  {
    primary_id: "D0K0ZB",
    names: ["Tresperimus"],
  },
  {
    primary_id: "D0K0ZI",
    names: ["PMID25666693-Compound-141"],
  },
  {
    primary_id: "D0K0ZK",
    names: ["ADX-63365"],
  },
  {
    primary_id: "D0K0ZX",
    names: ["Heteroaryl-Pyrazole Derivative 3"],
  },
  {
    primary_id: "D0K1AF",
    names: ["A-74273", "A-74723"],
  },
  {
    primary_id: "D0K1AI",
    names: [
      "Sch-58500",
      "ACN-53",
      "ACN-P53 TSG, Canji/Schering-Plough",
      "Gene Therapy (P53), Canji/Schering-Plough",
      "RAd/P53, Canji/Schering-Plough",
    ],
  },
  {
    primary_id: "D0K1AK",
    names: ["LCAR-B38M CAR-T Cell"],
  },
  {
    primary_id: "D0K1AP",
    names: ["NSL-101", "Analgesic (Pain), Ampika/ NeuroSolutions"],
  },
  {
    primary_id: "D0K1AT",
    names: ["[D-Ncy(Methyl)4]acyline", "CHEMBL268297"],
  },
  {
    primary_id: "D0K1AW",
    names: [
      "Meta-Nitro-Tyrosine",
      "3-Nitro-L-Tyrosine",
      "621-44-3",
      "3-Nitrotyrosine",
      "H-Tyr(3-NO2)-OH",
      "L-3-Nitrotyrosine",
      "Tyrosine, 3-Nitro-",
      "L-Tyrosine, 3-Nitro-",
      "H-3-Nitro-Tyr-OH",
      "UNII-7COY1HA6HK",
      "Tyrosine, 3-Nitro-, L-",
      "3-Mononitrotyrosine",
      "CCRIS 3566",
      "(S)-2-Amino-3-(4-Hydroxy-3-Nitrophenyl)Propanoic Acid",
      "(2S)-2-Amino-3-(4-Hydroxy-3-Nitrophenyl)Propanoic Acid",
      "EINECS 210-688-6",
      "NSC 37413",
      "7COY1HA6HK",
      "AI3-63160",
      "H-4-Hydroxy-3-Nitro-Phe-OH",
      "CHEBI:44454",
      "FBTSQILOGYXGMD-LURJTMIESA-N",
      "MFCD00007123",
      "3-Nitro-L-Tyrosine Crystalline",
      "M-Nitrotyrosine",
    ],
  },
  {
    primary_id: "D0K1BR",
    names: ["NLP-61"],
  },
  {
    primary_id: "D0K1BV",
    names: ["N-(17-Methylmorphinan-3-Yl)-N'-Phenylurea", "CHEMBL568829", "BDBM50303599"],
  },
  {
    primary_id: "D0K1CL",
    names: ["SDZ-224-015"],
  },
  {
    primary_id: "D0K1CM",
    names: ["Azocan-(2Z)-Ylideneamine", "CHEMBL329431", "2-Iminooctahydroazocine", "SCHEMBL3928259", "BDBM50049249"],
  },
  {
    primary_id: "D0K1CU",
    names: ["2-(4-Chlorophenylsulfonyl)Naphthalene-1,4-Diol", "CHEMBL460104"],
  },
  {
    primary_id: "D0K1DC",
    names: ["Romergoline"],
  },
  {
    primary_id: "D0K1DR",
    names: ["LEUCETTAMINE B"],
  },
  {
    primary_id: "D0K1EG",
    names: ["QAV-680"],
  },
  {
    primary_id: "D0K1EO",
    names: ["Picroliv"],
  },
  {
    primary_id: "D0K1FE",
    names: [
      "8-Bromo-6-Methyl-3-Phenylcoumarin",
      "CHEMBL1221930",
      "SCHEMBL17857186",
      "8-Bromo-3-Phenyl-6-Methylcumarin",
    ],
  },
  {
    primary_id: "D0K1FG",
    names: [
      "8-Acetyl-7-Hydroxy-2H-Chromen-2-One",
      "8-Acetyl-7-Hydroxycoumarin",
      "6748-68-1",
      "7-Hydroxy-8-Acetylcoumarin",
      "CHEMBL446518",
      "AN-829/13872055",
      "AC1NTTTW",
      "SCHEMBL4639845",
      "CTK5C6237",
      "8-Acetyl-7-Hydroxychromen-2-One",
      "DTXSID40419935",
      "MolPort-003-821-703",
      "8-Acetyl-7-Hydroxy-2-Oxochromene",
      "ZINC366861",
      "8-Acetyl-7-Hydroxy-Chromen-2-One",
      "8-Acetyl-7-Hydroxycoumarin, 97%",
      "BDBM50332038",
      "MFCD00270162",
      "8 - Acetyl - 7 - Hydroxycoumarin",
      "AKOS022647720",
      "OR345001",
      "KB-200256",
      "ST50309088",
      "Z3067",
      "2H-1-Benzopyran-2-One, 8-Acetyl-7-Hydroxy-",
    ],
  },
  {
    primary_id: "D0K1GJ",
    names: ["Piperidinyl Triazole Derivative 1"],
  },
  {
    primary_id: "D0K1HD",
    names: ["UCM 793"],
  },
  {
    primary_id: "D0K1HO",
    names: ["Acifran"],
  },
  {
    primary_id: "D0K1IE",
    names: ["CGNLSTCMLGTYTQDFNPFHTFPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D0K1JI",
    names: ["ISIS 173813"],
  },
  {
    primary_id: "D0K1LJ",
    names: ["Ad5-TRAIL", "Ad5-TRAIL Adenoviral Gene Therapy (Prostate Cancer), University Of Iowa"],
  },
  {
    primary_id: "D0K1LO",
    names: ["R-873"],
  },
  {
    primary_id: "D0K1MA",
    names: [
      "CGS-314447",
      "CHEMBL415967",
      "BDBM50159369",
      "CGS-31,4447",
      "{1-[(S)-2-Biphenyl-4-Yl-1-(1H-Tetrazol-5-Yl)-Ethylamino]-2-Naphthalen-2-Yl-Ethyl}-Phosphonic Acid",
    ],
  },
  {
    primary_id: "D0K1MB",
    names: [
      "PMID7932551C9",
      "(3alpha,5beta,12alpha,17xi)-3-Hydroxy-21-[(2R,4R)-4-Hydroxy-6-Oxotetrahydro-2H-Pyran-2-Yl]pregnan-12-Yl (2S)-2-Methylbutanoate",
      "GTPL3030",
      "BDBM50037255",
    ],
  },
  {
    primary_id: "D0K1MS",
    names: ["2-Methoxy-5-(5,6,7-Trimethoxy-Indan-1-Yl)-Phenol", "CHEMBL131684"],
  },
  {
    primary_id: "D0K1NM",
    names: [
      "1-(Adamantan-1-Yl)-2-(1H-Imidazol-1-Yl)Ethanone",
      "CHEMBL493448",
      "SCHEMBL10247513",
      "CHEMBL1187207",
      "BDBM50252801",
      "DB07342",
      "1(Adamantan1yl) 2(1himidazol1yl) Ethanone",
      "1-(Adamantan-1-Yl)-2-(Imidazol-1-Yl)Ethanone",
      "1-(Adamantan-1-Yl)-2-(1H-Imidazol-1-Yl)Ethanone Hydrochloride",
    ],
  },
  {
    primary_id: "D0K1OA",
    names: ["D-4418"],
  },
  {
    primary_id: "D0K1OK",
    names: ["CCR5 Mab"],
  },
  {
    primary_id: "D0K1OR",
    names: ["IB-06F259"],
  },
  {
    primary_id: "D0K1PP",
    names: ["Hydroxamate Analog 2"],
  },
  {
    primary_id: "D0K1PR",
    names: ["B-428"],
  },
  {
    primary_id: "D0K1PS",
    names: ["(1R,2S)-2-(1H-Imidazol-4-Yl)-1-Methyl-Propylamine", "CHEMBL277341", "BDBM50034711"],
  },
  {
    primary_id: "D0K1QB",
    names: ["2-(2,4-Difluorophenyl)-2,2-Diphenylacetamide", "CHEMBL270913", "SCHEMBL14437488"],
  },
  {
    primary_id: "D0K1QD",
    names: ["Sulfamylon"],
  },
  {
    primary_id: "D0K1QO",
    names: [
      "CEFETECOL",
      "Cefetecol < Rec INN",
      "GR-69153",
      "GR-69153E (DiNa Salt)",
      "GR-69153X (As Tetrahydrate)",
      "(6R,7R)-7-[2-(2-Amino-4-Thiazolyl)Glyoxylamino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid 7(2)-(Z)-[O-[(S)-Alpha-Carboxy-3,4-Dihydroxybenzyl]oxime]",
      "(6R,7R,2'Z,S)-7beta-[2-(2-Aminothiazol-4-Yl)-2-[(Carboxy)(3,4-Dihydroxyphenyl)Methoxyimino]acetamido]-3-Cephem-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0K1QV",
    names: [
      "2-[4-(2-Thienyl)Phenyl]-4-Methylmorpholin-2-Ol",
      "CHEMBL449619",
      "GTPL3081",
      "BDBM50253901",
      "Compound 5 [PMID: 18754614]",
    ],
  },
  {
    primary_id: "D0K1RU",
    names: ["Quinoline Derivative 3"],
  },
  {
    primary_id: "D0K1SR",
    names: ["PH-794428"],
  },
  {
    primary_id: "D0K1ST",
    names: ["Revefenacin"],
  },
  {
    primary_id: "D0K1SZ",
    names: ["Norcisapride"],
  },
  {
    primary_id: "D0K1TL",
    names: ["Clobetasol Propionate Oral"],
  },
  {
    primary_id: "D0K1TM",
    names: ["AMP-423"],
  },
  {
    primary_id: "D0K1TQ",
    names: ["GW0742X"],
  },
  {
    primary_id: "D0K1TW",
    names: ["2-(3-Chloro-4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D0K1UA",
    names: [
      "1-Allyl-3,7-Dimethyl-3,7-Dihydro-Purine-2,6-Dione",
      "Allyltheobromine",
      "1-Allyltheobromine",
      "Theobromine, 1-Allyl-",
      "UNII-4JG7O04XYX",
      "2530-99-6",
      "4JG7O04XYX",
      "CHEMBL25427",
      "Allyltheobromin",
      "NSC40882",
      "AC1L5YRZ",
      "3,7-Dimethyl-1-Prop-2-Enylpurine-2,6-Dione",
      "AC1Q6LF9",
      "SCHEMBL8435386",
      "CTK4F5434",
      "MolPort-001-981-271",
      "HMS1586E14",
      "ZINC349954",
      "NSC-40882",
      "BDBM50025568",
      "STL378446",
      "AKOS002340304",
      "MCULE-9142254572",
      "BAS 02549760",
      "WLN: T56 BN DN FNVNVJ B1 F1 H1U2",
      "1H-Purine-2, 3,7-Dihydro-3,7-Dimethyl-1-(2-Propenyl)-",
    ],
  },
  {
    primary_id: "D0K1UD",
    names: ["MMV00/0053"],
  },
  {
    primary_id: "D0K1UF",
    names: ["Saliphenylhalamide"],
  },
  {
    primary_id: "D0K1UI",
    names: [
      "B-Nonylglucoside",
      "69984-73-2",
      "Nonyl Beta-D-Glucopyranoside",
      "(2R,3S,4S,5R,6R)-2-(Hydroxymethyl)-6-(Nonyloxy)Tetrahydro-2H-Pyran-3,4,5-Triol",
      "Nonyl B-D-Glucopyranoside",
      "N-Nonyl -D-Glucopyranoside",
      "NONYL-Beta-D-GLUCOPYRANOSIDE",
      "N-Nonyl -D-Glucoside-(1,5);NGP",
      "B-D-Glucopyranoside, Nonyl",
      "N-NONYL-BETA-D-GLUCOPYRANOSIDE",
      "BNG",
      "Beta-D-Glucopyranoside, Nonyl",
      "4gby",
      "4gbz",
      "Nonyl -D-Glucopyranoside",
      "Nonyl ?-D-Glucopyranoside",
      "AC1L4EB7",
      "SCHEMBL136394",
      "Nonyl-I(2)-D-Glucopyranoside",
      "CHEMBL490225",
      "MolPort-002-893-587",
      "C15H30O6",
      "GC1498",
    ],
  },
  {
    primary_id: "D0K1UO",
    names: ["Resorcinol Compound 30"],
  },
  {
    primary_id: "D0K1UR",
    names: ["4-Biphenyl-4-Yl-2-(1-Propyl-Butyl)-1H-Imidazole", "CHEMBL118682", "SCHEMBL4811439"],
  },
  {
    primary_id: "D0K1VK",
    names: [
      "AZD8055",
      "1009298-09-2",
      "AZD-8055",
      "AZD 8055",
      "[5-[2,4-Bis((3S)-3-Methylmorpholin-4-Yl)Pyrido[2,3-D]pyrimidin-7-Yl]-2-Methoxyphenyl]methanol",
      "(5-(2,4-Bis((S)-3-Methylmorpholino)Pyrido[2,3-D]pyrimidin-7-Yl)-2-Methoxyphenyl)Methanol",
      "UNII-970JJ37FPW",
      "970JJ37FPW",
      "CHEMBL1801204",
      "AK109550",
      "(5-(2,4-Bis((S)-3-Methylmorpholino)Pyrido-[2,3-D]pyrimidin-7-Yl)-2-Methoxyphenyl)Methanol",
      "(5-(2,4-Bis((3S)-3-Methylmorpholin-4-Yl)Pyrido(2,3-D)Pyrimidin-7-Yl)-2-Methoxyphenyl)Methanol",
    ],
  },
  {
    primary_id: "D0K1VV",
    names: ["SR8278"],
  },
  {
    primary_id: "D0K1WT",
    names: ["GSK-679586A", "Asthma Therapeutic (Iv), GlaxoSmithKline", "679586"],
  },
  {
    primary_id: "D0K1XK",
    names: [
      "Hydralazine",
      "Apresolin",
      "Apresoline",
      "Apressin",
      "Apressoline",
      "Aprezolin",
      "HLZ",
      "Hidral",
      "Hidralazin",
      "Hidralazina",
      "Hipoftalin",
      "Hydralazin",
      "Hydralazinum",
      "Hydrallazin",
      "Hydrallazine",
      "Hydrazinophthalazine",
      "Hypophthalin",
      "Idralazina",
      "Hidralazina [Spanish]",
      "Idralazina [DCIT]",
      "Idralazina [Italian]",
      "BA 5968",
      "C 5968",
      "Ciba 5968",
      "Praparat 5968",
      "Apresoline (TN)",
      "Apressin (Pharmaceutical)",
      "C-5068",
      "C-5968",
      "Hidral (TN)",
      "Hidralazina [INN-Spanish]",
      "Hydralazine (INN)",
      "Hydralazine [INN:BAN]",
      "Hydralazinum [INN-Latin]",
      "Hydrazone 1(2H)-Phthalazinone",
      "Phthalazin-1-Ylhydrazine",
      "[2H-Phthalazin-(1Z)-Ylidene]-Hydrazine Hydrochloride",
      "(1Z)-1(2H)-Phthalazinone Hydrazone",
      "(2H)-Phthalazinone Hydrazone",
      "1(2H)-Phthalazinone Hydrazone",
      "1(2H)-Phthalazinone, Hydrazone",
      "1-Hydrazinophthalazine",
      "1-Phthalazinylhydrazine",
      "6-Hydralazine",
    ],
  },
  {
    primary_id: "D0K1XV",
    names: ["RemUnity"],
  },
  {
    primary_id: "D0K1YQ",
    names: ["SP-10"],
  },
  {
    primary_id: "D0K1YY",
    names: [
      "6-Benzylthioinosine",
      "NSC26273",
      "6-Benzylthionebularine",
      "MLS002702829",
      "CHEMBL178704",
      "6-Benzylthiopurine Ribonucleoside",
      "6165/3/3",
      "NSC-26273",
      "6-(Benzylsulfanyl)-9-Pentofuranosyl-9h-Purine",
      "AC1L3TX3",
      "AC1Q4YP0",
      "Cid_95263",
      "ChEMBL_299712",
      "SCHEMBL15427008",
      "CTK5B3520",
      "NSC 26273",
      "BDBM50159129",
      "SMR001566656",
      "NCI60_002098",
      "AI3-50272",
      "Inosine,6-S-(Phenylmethyl)-6-Thio- (9CI)",
      "9H-Purine, 6-(Benzylthio)-9-.beta.-D-Ribofuranosyl-",
      "9H-Purine, 6-(Benzylthio)-9-Beta-D-Ribofuranosyl- (8CI)",
      "9H-Purine, 6-((Phenylmethyl)Thio)-9-Beta-D-Ribofuranosyl-",
    ],
  },
  {
    primary_id: "D0K1ZD",
    names: ["Edobacomab", "Antiendotoxin MAb, Pfizer", "Antiendotoxin MAb, XOMA", "XOMEN-E5"],
  },
  {
    primary_id: "D0K1ZY",
    names: ["4,5,6,7-Tetrahydrofuro[3,4-C]pyridine-1(3H)-One Derivative 2"],
  },
  {
    primary_id: "D0K2AA",
    names: ["Biaryl Mannoside Derivative 11"],
  },
  {
    primary_id: "D0K2AR",
    names: ["Pyridocarbazole"],
  },
  {
    primary_id: "D0K2BQ",
    names: [
      "BRL-61063",
      "Cipamfylline",
      "132210-43-6",
      "UNII-AFP56R140L",
      "AFP56R140L",
      "CHEMBL356323",
      "Cipamfylline [USAN:INN]",
      "Cipamfyllinum",
      "AC1Q6LCD",
      "8-Amino-1,3-Bis(Cyclopropylmethyl)Xanthine",
      "Cipamfylline (USAN/INN)",
      "AC1L2G0B",
      "SCHEMBL26990",
      "MLS006010682",
      "SCHEMBL8327157",
      "Brl 61063",
      "8-Amino-1,3-Bis(Cyclopropylmethyl)-7H-Purine-2,6-Dione",
      "DTXSID50157437",
      "Cipamfylline, &gt",
      "KSPYMJJKQMWWNB-UHFFFAOYSA-N",
      "ZINC3781835",
      "BDBM50040348",
      "1,3-Dicyclopropylmethyl-8-Amino Xanthine",
      "SMR004701664",
      "8-Amino-1,3-Di(Cyclopropylmethyl)Xanthine",
      "D03516",
    ],
  },
  {
    primary_id: "D0K2BT",
    names: ["PMID28766366-Compound-Scheme12-4"],
  },
  {
    primary_id: "D0K2BZ",
    names: [
      "D-Tartaric Acid",
      "147-71-7",
      "D-(-)-Tartaric Acid",
      "(2S,3S)-2,3-Dihydroxysuccinic Acid",
      "D(-)-TARTARIC ACID",
      "(2S,3S)-2,3-Dihydroxybutanedioic Acid",
      "D-Threaric Acid",
      "(-)-D-Tartaric Acid",
      "(-)-Tartaric Acid",
      "(2S,3S)-(-)-Tartaric Acid",
      "(S,S)-Tartaric Acid",
      "(-)-(S,S)-Tartaric Acid",
      "(S,S)-(-)-Tartaric Acid",
      "UNII-RRX6A4PL3C",
      "Butanedioic Acid, 2,3-Dihydroxy-, (2S,3S)-",
      "D-(-)-Tartaricacid",
      "(2S,3S)-Tartaric Acid",
      "RRX6A4PL3C",
      "CHEBI:15672",
      "MFCD00004238",
      "D-Tartrate",
      "(S,S)-Tartrate",
      "D(-)-Tartaric Acid, 99%",
      "Levo-Tartaric Acid",
    ],
  },
  {
    primary_id: "D0K2CR",
    names: ["Nemorubicin"],
  },
  {
    primary_id: "D0K2CV",
    names: [
      "HOE-694",
      "141923-47-9",
      "3-METHYLSULFONYL-4-PIPERIDIN-1-YLBENZOYL GUANIDINE",
      "HOE694",
      "AC1L3XEA",
      "CHEMBL62231",
      "SCHEMBL3906516",
      "CTK4C2847",
      "ZINC3915646",
      "AKOS030541973",
      "KB-236684",
      "TX-016775",
      "3-Methylsulphonyl-4-Piperidinobenzoyl-Guanidine",
    ],
  },
  {
    primary_id: "D0K2CZ",
    names: [
      "1,1-Dimethyl-5-Oxa-Spiro[2.4]heptan-4-One",
      "5-Oxaspiro[2.4]heptan-4-One, 1,1-Dimethyl-",
      "65652-09-7",
      "CHEMBL97270",
    ],
  },
  {
    primary_id: "D0K2DR",
    names: [
      "5-Pyridin-3-Yl-1,3-Dihydro-2H-Indol-2-One",
      "CHEMBL459071",
      "5-(Pyrid-3-Yl)-2h-Indolin-2-One",
      "SCHEMBL748904",
      "SCHEMBL6840746",
      "LRXSVYIRRDZVNQ-UHFFFAOYSA-N",
      "BDBM50273706",
      "220904-98-3",
    ],
  },
  {
    primary_id: "D0K2EC",
    names: ["MEDI-77"],
  },
  {
    primary_id: "D0K2ER",
    names: ["MK-886"],
  },
  {
    primary_id: "D0K2EX",
    names: [
      "PMID22802221C21",
      "C21",
      "UNII-RC2V4W0EYC",
      "RC2V4W0EYC",
      "477775-14-7",
      "C-21",
      "AT2R Agonist C21",
      "GTPL6918",
      "ZINC6467610",
      "BDBM50156173",
      "AKOS030231077",
      "SB18730",
      "NCGC00386668-01",
      "M024/C21",
      "M-24",
      "Butyl 3-(4-((1",
    ],
  },
  {
    primary_id: "D0K2FH",
    names: ["Extract Of Serenoa Repens"],
  },
  {
    primary_id: "D0K2FK",
    names: ["Cyclic Sulfonamide Derivative 3"],
  },
  {
    primary_id: "D0K2FQ",
    names: ["1,2-Di(Berberine-9-O-Yl)Ethane Dibromide", "CHEMBL1163560"],
  },
  {
    primary_id: "D0K2GA",
    names: ["Silteplase"],
  },
  {
    primary_id: "D0K2GB",
    names: ["(2S)-Aminobutyryl-L-Proline N-Propylamide", "CHEMBL225539", "SCHEMBL6243789"],
  },
  {
    primary_id: "D0K2GG",
    names: ["PMID27977313-Compound-44"],
  },
  {
    primary_id: "D0K2GH",
    names: ["Pyrrolidinyl Urea Derivative 12"],
  },
  {
    primary_id: "D0K2GT",
    names: ["ISIS 222043"],
  },
  {
    primary_id: "D0K2GV",
    names: ["HIV Vaccine"],
  },
  {
    primary_id: "D0K2HA",
    names: ["PF-2413873"],
  },
  {
    primary_id: "D0K2HU",
    names: ["PF-06444752"],
  },
  {
    primary_id: "D0K2ID",
    names: ["NKTR-181"],
  },
  {
    primary_id: "D0K2KD",
    names: [
      "PB-6",
      "Anaeban",
      "Inflammaban",
      "Bacillus Subtilis (High Dose, Inflammatory Bowel Disease/Diarrhea), Kemin",
      "Bacillus Subtilis (Low Dose,bacterial GI Infection/Stomach Ulcer), Kemin",
    ],
  },
  {
    primary_id: "D0K2KS",
    names: ["HuCNS-SC"],
  },
  {
    primary_id: "D0K2LB",
    names: ["PMID25399762-Compound-Table1-C2"],
  },
  {
    primary_id: "D0K2LR",
    names: ["LG-611"],
  },
  {
    primary_id: "D0K2MC",
    names: ["[D-Ncy(Methyl)10] Acyline", "CHEMBL387499"],
  },
  {
    primary_id: "D0K2MT",
    names: ["ADC-7828"],
  },
  {
    primary_id: "D0K2NA",
    names: ["YM-57158"],
  },
  {
    primary_id: "D0K2NJ",
    names: ["GSK312948"],
  },
  {
    primary_id: "D0K2NY",
    names: ["Va-RYYRIK-NH2", "CHEMBL272084"],
  },
  {
    primary_id: "D0K2OK",
    names: ["Tyr-Pro-Dmp-Phe-NH2", "CHEMBL266122"],
  },
  {
    primary_id: "D0K2OT",
    names: ["2,2-Bipyridyl Derivative 2"],
  },
  {
    primary_id: "D0K2OV",
    names: ["Oxytrex"],
  },
  {
    primary_id: "D0K2PD",
    names: [
      "3-Aminobenzamide",
      "3544-24-9",
      "M-Aminobenzamide",
      "Benzamide, 3-Amino-",
      "3-Amino-Benzamide",
      "Benzamide, M-Amino-",
      "Meta-Aminobenzamide",
      "3-ABA",
      "3-Aminobenzimide",
      "3-Amino Benzamide",
      "Aniline-3-Carboxamide",
      "3-Aminobenzoic Acid Amide",
      "UNII-8J365YF1YH",
      "CCRIS 3925",
      "EINECS 222-586-9",
      "NSC 36962",
      "BRN 2802373",
      "PARP Inhibitor I, 3-ABA",
      "3-H2NC6H4CONH2",
      "CHEMBL81977",
      "3-AB",
      "8J365YF1YH",
      "CHEBI:64042",
      "GSCPDZHWVNUUFI-UHFFFAOYSA-N",
      "MFCD00007989",
      "3-Aminobenzamide, 98%",
      "SR-01000075657",
      "HSDB 7581",
      "3-Azanylbenzamide",
      "M-Amino Benzamide",
      "4pml",
      "3-Aminobenzaminde",
    ],
  },
  {
    primary_id: "D0K2PH",
    names: ["Elvucitabine"],
  },
  {
    primary_id: "D0K2PI",
    names: ["(S)-Methyl 4-(2-Oxohexadecanamido)Octanoate", "CHEMBL462361"],
  },
  {
    primary_id: "D0K2PK",
    names: ["PMID25980951-Compound-33"],
  },
  {
    primary_id: "D0K2PS",
    names: ["Carmoxirole", "Carmoxirole Hydrochloride", "EMD-45609"],
  },
  {
    primary_id: "D0K2QF",
    names: [
      "3,4-Dichloro-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL293437",
      "3,4-Dichloro-N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "AC1LF45X",
      "Oprea1_772660",
      "Oprea1_724335",
      "ZINC73690",
      "CCG-993",
      "MolPort-001-949-151",
      "BDBM50101784",
      "STK017434",
      "AKOS000519164",
      "MCULE-6935310530",
      "BAS 00860111",
      "ST50005447",
      "N-(4-Phenyl-2-Thiazolyl)-3,4-Dichlorobenzamide",
      "Z27772110",
      "(3,4-Dichlorophenyl)-N-(4-Phenyl(1,3-Thiazol-2-Yl))Carboxamide",
    ],
  },
  {
    primary_id: "D0K2QG",
    names: [
      "DASANTAFIL",
      "Dasantafil < USAN",
      "SCH-446132",
      "7-(3-Bromo-4-Methoxybenzyl)-1-Ethyl-8-[2(R)-Hydroxycyclopent-1(R)-Ylamino]-3-(2-Hydroxyethyl)-2,3,6,7-Tetrahydro-1H-Purine-2,6-Dione",
      "7-(3-Bromo-4-Methoxybenzyl)-1-Ethyl-8-[2(R)-Hydroxycyclopent-1(R)-Ylamino]-3-(2-Hydroxyethyl)Xanthine",
    ],
  },
  {
    primary_id: "D0K2RV",
    names: ["Timothy Grass Extract"],
  },
  {
    primary_id: "D0K2RW",
    names: ["Cyanomethyl Pypazole Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0K2RX",
    names: ["Peptide Analog 60"],
  },
  {
    primary_id: "D0K2RY",
    names: ["Itriglumide", "CR-2945"],
  },
  {
    primary_id: "D0K2SE",
    names: ["5-Imidazol-1-Yl-5,6,7,8-Tetrahydro-Quinoline"],
  },
  {
    primary_id: "D0K2TB",
    names: ["Docarpamine"],
  },
  {
    primary_id: "D0K2UA",
    names: ["4-Tert-Butyl-N-(2-Isopropylphenyl)Thiazol-2-Amine", "CHEMBL206788"],
  },
  {
    primary_id: "D0K2UF",
    names: ["PMID28092474-Compound-33f"],
  },
  {
    primary_id: "D0K2UJ",
    names: [
      "MUT-11931",
      "Antivirulence WaaC Inhibitors (Bacterial Infection)",
      "Antivirulence WaaC Inhibitors (Bacterial Infection), Mutabilis",
    ],
  },
  {
    primary_id: "D0K2UP",
    names: [
      "S-Benzyl-Glutathione",
      "AC1Q5QKZ",
      "AC1L4ODA",
      "S-Benzyl-L-Cysteinyl-N-L-",
      "A-Glutamylglycine",
      "Glycine, N-(N-L-Gamma-Glutamyl-S-(Phenylmethyl)-L-Cysteinyl)-",
      "(2S)-2-Amino-5-[[(2R)-2-Amino-3-Benzylsulfanylpropanoyl]-(Carboxymethyl)Amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D0K2VO",
    names: ["6,7-Dimethoxy-4-(4-Phenylbut-1-Ynyl)Quinazoline", "CHEMBL250318", "BDBM50222435"],
  },
  {
    primary_id: "D0K2WK",
    names: [
      "Cicletanine",
      "Cycletanide",
      "Tenstaten",
      "89943-82-8",
      "Justar",
      "BN-1270",
      "Win-90000",
      "Cicletanide",
      "BN 1270",
      "Cicletaninum",
      "Cicletanina",
      "Cicletaninum [Latin]",
      "Cicletanina [Spanish]",
      "Cicletanine [USAN:INN:BAN]",
      "Win 90,000",
      "ACMC-20mckj",
      "ACMC-20mcin",
      "AC1Q3NNY",
      "Cicletanine (USAN/INN)",
      "(+-)-BN-1270",
      "AC1L1HZ3",
      "SCHEMBL49794",
      "CHEMBL191886",
      "(R)-(-)-CICLETANINE",
      "CHEBI:135078",
      "Furo[3,4-C]pyridin-7-Ol, 3-(4-Chlorophenyl)-1,3-Dihydro-6-Methyl-, (S)-",
      "Furo[3,4-C]pyridin-7-Ol, 3-(4-Chlorophenyl)-1,3-Dihydro-6-Methyl-, (R)-",
      "CVKNDPRBJVBDSS-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0K2WP",
    names: ["Aryl Urea Derivative 2"],
  },
  {
    primary_id: "D0K2XB",
    names: ["GX-51"],
  },
  {
    primary_id: "D0K2XD",
    names: ["ADX71743", "ADX 71743", "ADX-71743"],
  },
  {
    primary_id: "D0K2XJ",
    names: [
      "EUGENIIN",
      "Beta-D-Glucopyranose, Cyclic 4,6-((1S)-4,4',5,5',6,6'-Hexahydroxy(1,1'-Biphenyl)-2,2'-Dicarboxylate) 1,2,3-Tris(3,4,5-Trihydroxybenzoate)",
      "81571-72-4",
      "58970-75-5",
      "SCHEMBL618555",
      "AC1L46N3",
      "LS-71467",
      "1,2,3-Tri-O-Galloyl-4,6-(S)-Hexahydroxydiphenoyl-B-D-Glucopyranose",
      "Beta-D-Glucopyranose, Cyclic 4,6-(4,4',5,5',6,6'-Hexahydroxy(1,1'-Biphenyl)-2,2'-Dicarboxylate) 1,2,3-Tris(3,4,5-Trihydroxybenzoate)",
    ],
  },
  {
    primary_id: "D0K2XK",
    names: ["PMID26815044-Compound-I"],
  },
  {
    primary_id: "D0K2YG",
    names: [
      "4-(5-Methoxy-Benzoimidazol-1-Yl)-Phenylamine",
      "5-Substituted 1-Phenylbenzimidazole 22",
      "AC1NS4PO",
      "SCHEMBL7989819",
      "CHEMBL309035",
      "BDBM3933",
      "AKOS023129997",
      "4-(5-Methoxybenzimidazol-1-Yl)Aniline",
      "5-Methoxy-1-(4-Aminophenyl)-1H-Benzimidazole",
      "4-(5-Methoxy-1H-1,3-Benzodiazol-1-Yl)Aniline",
      "1-(4-Aminophenyl)-5-Methoxybenzimidazole Dihydrochloride",
    ],
  },
  {
    primary_id: "D0K2YO",
    names: ["Sulfonamide Derivative 15"],
  },
  {
    primary_id: "D0K2YY",
    names: [
      "4-(2-Nitroprop-1-Enyl)Benzene-1,2-Diol",
      "13662-96-9",
      "1,2-Benzenediol, 4-(2-Nitro-1-Propenyl)-",
      "AC1MMGH2",
      "Oprea1_551147",
      "CTK0F3796",
      "DTXSID20390758",
      "AKOS017269192",
    ],
  },
  {
    primary_id: "D0K2ZF",
    names: ["PH-797804"],
  },
  {
    primary_id: "D0K2ZG",
    names: [
      "5-LO (Pain/Neuro-Inflammation), St Charles Pharmaceuticals",
      "5-Lipoxygenase Inhibitors (Pain/Neuro-Inflammation), St Charles Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0K2ZQ",
    names: ["Xanthine/Amino Piperidine Compound 1"],
  },
  {
    primary_id: "D0K3AO",
    names: [
      "CYLINDOL A",
      "Cylindol A",
      "CHEBI:65709",
      "Dimethyl 3,3'-Oxybis(4-Hydroxybenzoate)",
      "Methyl 4-Hydroxy-3-(2-Hydroxy-5-Methoxycarbonylphenoxy)Benzoate",
    ],
  },
  {
    primary_id: "D0K3BO",
    names: [
      "2-Acetyl-Cyclohexane-1,3-Dione",
      "2-Acetyl-1,3-Cyclohexanedione",
      "4056-73-9",
      "2-Acetylcyclohexane-1,3-Dione",
      "1,3-Cyclohexanedione, 2-Acetyl-",
      "CHEMBL167675",
      "CHNXDYRMRBQOEF-UHFFFAOYSA-N",
      "ACMC-1CTSA",
      "AC1L3C2Y",
      "2-Acetyl1,3-Cyclohexanedione",
      "2-Acetyl-1,3-Cyclohexadione",
      "SCHEMBL170644",
      "1-Acetyl-2,6-Dioxocyclohexane",
      "CTK4I3348",
      "1,3-Cyclohexanedione,2-Acetyl-",
      "DTXSID50193577",
      "BDBM50088797",
      "AKOS015906528",
      "ZINC100068139",
      "2-Acetyl-1,3-Cyclohexanedione, 98%",
      "MCULE-5567869384",
      "TC-171004",
      "ST50997553",
      "X3265",
      "CS-0060856",
      "Z-8544",
    ],
  },
  {
    primary_id: "D0K3BR",
    names: ["PMID25656651-Compound-26b"],
  },
  {
    primary_id: "D0K3CE",
    names: [
      "4-[6-Methoxy-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "AC1O706F",
      "CHEMBL365314",
      "BDBM8626",
      "(4-Pyridylmethylene)Indane 16a",
      "4-[(E)-(6-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0K3CJ",
    names: [
      "3-(6,7-Dimethoxy-Quinolin-4-Yloxy)-Phenol",
      "CHEMBL101389",
      "SCHEMBL6431286",
      "CGIFTRWAYIWEGG-UHFFFAOYSA-N",
      "BDBM50290850",
    ],
  },
  {
    primary_id: "D0K3DA",
    names: ["PMID26651364-Compound-112"],
  },
  {
    primary_id: "D0K3DQ",
    names: [
      "Cefotiam",
      "Aspil",
      "CTM",
      "Cefotiamum",
      "Ceradolan",
      "Haloapor",
      "CEFOTIAM HYDROCHLORIDE",
      "CGP 14221E",
      "Abbott-48999",
      "Aspil (TN)",
      "Cefotiam (INN)",
      "Cefotiam [INN:BAN]",
      "Cefotiamum [INN-Latin]",
      "SCE-963",
      "CGP-14221-E",
      "(6R,7R)-7-[2-(2-Amino-Thiazol-4-Yl)-Acetylamino]-3-[1-(2-Dimethylamino-Ethyl)-1H-Tetrazol-5-Ylsulfanylmethyl]-8-Oxo-5-Thia-1-Aza-Bicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[2-(2-Amino-1,3-Thiazol-4-Yl)Acetyl]amino]-3-[[1-(2-Dimethylaminoethyl)Tetrazol-5-Yl]sulfanylmethyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2-Amino-1,3-Thiazol-4-Yl)Acetyl]amino}-3-[({1-[2-(Dimethylamino)Ethyl]-1H-Tetrazol-5-Yl}sulfanyl)Methyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2-Amino-1,3-Thiazol-4-Yl)Acetyl]amino}-3-[({1-[2-(Dimethylamino)Ethyl]-1H-Tetrazol-5-Yl}thio)Methyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7b-[2-(Aminothiazol-4-Yl)Acetamido]-3-[[[1-(2-Dimethylaminoethyl)-1h-Tetrazol-5-Yl]thio]methyl]ceph-3-Em-4-Carboxylic Acid",
      "7beta-(2-Amino-1,3-Thiazol-4-Yl)Acetamido-3-[({1-[2-(Dimethylamino)Ethyl]-1H-Tetrazol-5-Yl}sulfanyl)Methyl]-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0K3EB",
    names: ["Di-Substituted Piperidine Derivative 2"],
  },
  {
    primary_id: "D0K3ES",
    names: ["MEK Inhibitor I"],
  },
  {
    primary_id: "D0K3EW",
    names: [
      "6-(3,4-DICHLOROPHENYL)-1-[1-(METHYLOXY)-3-BUTEN-1-YL]-3-AZABICYCLO[4.1.0]HEPTANE (DIASTEREOMERIC MIX)",
      "CHEMBL1173087",
      "BDBM50322696",
      "Rac-6-(3,4-Dichlorophenyl)-5-Methoxy-1-1-Methoxybut-3-Enyl)-3-Azabicyclo[4.1.0]heptane",
    ],
  },
  {
    primary_id: "D0K3FG",
    names: ["EDP-13"],
  },
  {
    primary_id: "D0K3FJ",
    names: ["Imidazole Derivative 6"],
  },
  {
    primary_id: "D0K3FX",
    names: ["NT-503"],
  },
  {
    primary_id: "D0K3GB",
    names: ["99mTc-MIP-1340"],
  },
  {
    primary_id: "D0K3GQ",
    names: ["Oleoyl-Thiophosphate"],
  },
  {
    primary_id: "D0K3GU",
    names: ["PF-3185043"],
  },
  {
    primary_id: "D0K3HY",
    names: ["VU0152100"],
  },
  {
    primary_id: "D0K3JC",
    names: ["D-Phenylalanyl-N-(3-Methylbenzyl)-L-Prolinamide", "DB07133", "51U"],
  },
  {
    primary_id: "D0K3JQ",
    names: ["RPI-MN"],
  },
  {
    primary_id: "D0K3JZ",
    names: ["PT-00311"],
  },
  {
    primary_id: "D0K3KE",
    names: ["PMID25666693-Compound-155"],
  },
  {
    primary_id: "D0K3KM",
    names: ["PMID25666693-Compound-148"],
  },
  {
    primary_id: "D0K3LN",
    names: ["N-(4-Sulfamoyl-Phenyl)-Butyramide"],
  },
  {
    primary_id: "D0K3LO",
    names: ["Romosozumab", "AMG 785"],
  },
  {
    primary_id: "D0K3LQ",
    names: ["4-(N-Propyl)Amino-3-Nitrobenzoic Acid", "Compound 5c [PMID: 17931863]"],
  },
  {
    primary_id: "D0K3LW",
    names: [
      "Malathion",
      "Calmathion",
      "Camathion",
      "Carbafos",
      "Carbetovur",
      "Carbetox",
      "Carbofos",
      "Carbophos",
      "Celthion",
      "Chemathion",
      "Cimexan",
      "Cleensheen",
      "Cythion",
      "Dorthion",
      "Emmatos",
      "Ethiolacar",
      "Etiol",
      "Extermathion",
      "Flair",
      "Forthion",
      "Fosfothion",
      "Fosfotion",
      "Fyfanon",
      "Hilthion",
      "Karbofos",
      "Kypfos",
      "Malacide",
      "Malafor",
      "Malagran",
      "Malakill",
      "Malamar",
      "Malaphele",
      "Malaphos",
      "Malasol",
      "Malaspray",
      "Malataf",
      "Malathiazol",
      "Malathione",
      "Malathiozoo",
      "Malathon",
      "Malathyl",
      "Malation",
      "Malatol",
      "Malatox",
      "Maldison",
      "Malmed",
      "Malphos",
      "Maltox",
      "Mercaptothion",
      "Mercaptotion",
      "Moscarda",
      "Oleophosphothion",
      "Organoderm",
      "Ovide",
      "Paladin",
      "Phosphothion",
      "Prioderm",
      "Sadofos",
      "Sadophos",
      "Sumitox",
      "TAK",
      "Taskil",
      "Vetiol",
      "Zithiol",
      "Carbethoxy Malathion",
      "Detm Ol Ma",
      "Detmol MA",
      "Emmatos Extra",
      "Lice Rid;Malathion LV Concentrate",
      "Malathion [USP]",
      "Malation [Polish]",
      "Maltox MLT",
      "Mercaptosuccinic Acid Diethyl Ester",
      "Mercaptotion [Spanish]",
      "Ortho Malathion",
      "Siptox I",
      "Vegfru Malatox",
      "AC 26691",
      "EL 4049",
      "Experimental Insecticide 4049",
      "Fog 3",
      "Fosfotion 550",
      "Hilthion 25wdp",
      "IFO 13140",
      "Latka 4049",
      "Latka 4049 [Czech]",
      "MET86C_SUPELCO",
      "Malamar 50",
      "Malathion 60",
      "Malathion E50",
      "OMS 1",
      "PS86_SUPELCO",
      "SF 60",
      "Sadofos 30",
      "American Cyanamid 4,049",
      "Derbac-M",
      "ENT 17,034",
      "Four Thousand Forty-Nine",
      "Insecticide No. 4049",
      "Kop-Thionkypfosmalacide",
      "Kop-Thion",
      "Malathion (USP)",
      "Malathion (Old ID)",
      "Ovide (TN)",
      "Staeubol-Puder",
      "Suleo-M",
      "TM-4049",
      "Kill-A-Mite",
      "MALATHION, 96%",
      "Malathion-2,3-14C",
      "8059HC",
    ],
  },
  {
    primary_id: "D0K3LX",
    names: ["RNAPc2"],
  },
  {
    primary_id: "D0K3MC",
    names: ["Lofepramine"],
  },
  {
    primary_id: "D0K3MR",
    names: ["5-(Biphenyl-4-Yl)-Pentanoic Acid N-Hydroxyamide", "CHEMBL541239", "SCHEMBL7045815"],
  },
  {
    primary_id: "D0K3MU",
    names: ["NRL-0301"],
  },
  {
    primary_id: "D0K3MV",
    names: ["Imidazo[1,2-B]pyridazine Acetamide Derivative 7"],
  },
  {
    primary_id: "D0K3OK",
    names: ["Difelikefalin"],
  },
  {
    primary_id: "D0K3PS",
    names: ["NKH477", "6-(3-Dimethylaminopropionyl) Forskolin Hydrochloride"],
  },
  {
    primary_id: "D0K3QL",
    names: ["FR-230481"],
  },
  {
    primary_id: "D0K3QR",
    names: ["Purin-6-One Derivative 1"],
  },
  {
    primary_id: "D0K3QS",
    names: [
      "Everolimus",
      "Afinitor",
      "Certican",
      "Zortress",
      "Everolimus [USAN]",
      "RAD 001",
      "RAD001",
      "Afinitor (TN)",
      "CERTICAN(R)",
      "Certican (TN)",
      "RAD-001",
      "RAD-001C",
      "SDZ-RAD",
      "NVP-RAD-001",
      "Everolimus (JAN/USAN/INN)",
      "RAD001, SDZ-RAD, Certican, Zortress, Afinitor, Everolimus",
      "(3S,6R,7E,9R,10R,12R,14S,15E,17E,19E,21S,23S,26R,27R,34as)-9,10,12,13,14,21,22,23,24,25,26,27,32,33,34,34a-Hexadecahydro-9,27-Dihydroxy-3-((1R)-2-((1S,3R,4R)-4-(2-Hydroxyethoxy)-3-Methoxycyclohexyl)-1-Methylethyl)-10,21-Dimethoxy-6,8,12,14,20,26-Hexamethy",
      "40-O-(2-Hydroxyethyl)-Rapamycin",
      "42-O-(2-Hydroxyethyl)Rapamycin",
      "MTOR Kinase Inhibitors",
    ],
  },
  {
    primary_id: "D0K3QT",
    names: ["Pyrrolidinyl Urea Derivative 4"],
  },
  {
    primary_id: "D0K3QW",
    names: ["REC-0436"],
  },
  {
    primary_id: "D0K3QX",
    names: ["Tricyclic Indole Compound 6"],
  },
  {
    primary_id: "D0K3RC",
    names: ["3-(4-Phenoxy-Benzenesulfonyl)-Cyclohexanethiol", "CHEMBL48474"],
  },
  {
    primary_id: "D0K3RP",
    names: ["NRI-1013"],
  },
  {
    primary_id: "D0K3SI",
    names: [
      "Decyltrimethylammonium",
      "Trimethyldecylammonium",
      "15053-09-5",
      "N-Decyltrimethylammonium",
      "CHEBI:55325",
      "Ammonium, Decyltrimethyl-",
      "Decyltrimethylaminium",
      "Decyl(Trimethyl)Azanium",
      "Trimethyl-N-Decylammonium",
      "Decyltrimethylammonium Ion",
      "AC1L27UL",
      "SCHEMBL363068",
      "N,N,N-Trimethyldecan-1-Aminium",
      "N,N,N-Trimethyl-1-Decanaminium",
      "CHEMBL1180301",
      "2082-84-0 (Bromide)",
      "CTK4C6688",
      "10108-87-9 (Chloride)",
      "DTXSID10164566",
      "1-Decanaminium,N,N,N-Trimethyl-",
      "1-Decanaminium, N,N,N-Trimethyl-",
      "ZINC1700269",
      "MCULE-3384760582",
      "65059-98-5 (Methyl Sulfate Salt)",
    ],
  },
  {
    primary_id: "D0K3SM",
    names: ["MALP-2S"],
  },
  {
    primary_id: "D0K3SV",
    names: ["PF-05236812", "AAB-003"],
  },
  {
    primary_id: "D0K3SZ",
    names: [
      "4-Methylbenzenemethanethiol",
      "4498-99-1",
      "4-Methylbenzyl Mercaptan",
      "(4-Methylphenyl)Methanethiol",
      "P-Xylene-Alpha-Thiol",
      "Alpha-Mercapto-P-Xylene",
      "4-Methylbenzylmercaptan",
      "Benzenemethanethiol, 4-Methyl-",
      "CHEMBL1224559",
      "AGFYZLVFPSGUIX-UHFFFAOYSA-N",
      "P-Xylyl Mercaptan",
      "(4-Methylphenyl)Methane-1-Thiol",
      "P-Tolylmethanethiol",
      "NSC79870",
      "EINECS 224-796-6",
      "AC1L2UTO",
      "AC1Q7GOJ",
      "ACMC-1AQT2",
      "4-Methyl-Alpha-Toluenethiol",
      "SCHEMBL385561",
      "PARA-XYLENE-ALPHA-THIOL",
      "(4-Methylphenyl)Methanethiol #",
      "CTK3J1384",
      "DTXSID30196349",
      "MolPort-000-146-954",
    ],
  },
  {
    primary_id: "D0K3TQ",
    names: ["PMID9003518C3"],
  },
  {
    primary_id: "D0K3TS",
    names: ["SIGMOIDIN A"],
  },
  {
    primary_id: "D0K3UL",
    names: ["CP-226269", "CP-226,269", "CP 226269", "CP226269"],
  },
  {
    primary_id: "D0K3UO",
    names: ["PMID29649907-Compound-39"],
  },
  {
    primary_id: "D0K3UQ",
    names: ["Vercirnon", "1605786"],
  },
  {
    primary_id: "D0K3UV",
    names: ["PMID25399762-Compound-Figure1-Chukrasone A"],
  },
  {
    primary_id: "D0K3VA",
    names: [
      "Erdosteine",
      "Asdigan",
      "Biopulmin",
      "Dostin",
      "Dostol",
      "Drupsan",
      "Ectrin",
      "Edirel",
      "Erdomed",
      "Erdopect",
      "Erdos",
      "Erdotin",
      "Esteclin",
      "Fluidasa",
      "Flusten",
      "Mucoflux",
      "Mucofor",
      "Mucotec",
      "Mucothera",
      "Mukial",
      "Secresolv",
      "Theovix",
      "Tusselin",
      "Vectrine",
      "KW-9144",
      "RV-144",
    ],
  },
  {
    primary_id: "D0K3VK",
    names: ["4-(6-Morpholino-4-Oxo-4H-Pyran-2-Yl)Benzonitrile", "CHEMBL222765"],
  },
  {
    primary_id: "D0K3VP",
    names: ["Vaccine, Respiratory Syncytial Virus Infection"],
  },
  {
    primary_id: "D0K3VR",
    names: ["P-F-HHSiD", "4-Fluorohexahydrosiladifenidol", "P-Pluorohexahydrosiladyphenidol", "PFHHSiD"],
  },
  {
    primary_id: "D0K3WN",
    names: [
      "DNP-004089",
      "BACE Inhibitors, De Novo",
      "Beta-Amyloid Converting Enzyme Inhibitors,De Novo",
      "Beta-Secretase Inhibitors, De Novo",
    ],
  },
  {
    primary_id: "D0K3WO",
    names: ["MK-08887A", "DULERA"],
  },
  {
    primary_id: "D0K3XB",
    names: ["JCAR015"],
  },
  {
    primary_id: "D0K3XP",
    names: ["Methoxy Polyethylene Glycol-Epoetin Beta"],
  },
  {
    primary_id: "D0K3XS",
    names: ["Custirsen", "OGX-001/TV-1011", "OGX-011"],
  },
  {
    primary_id: "D0K3YG",
    names: ["ANX-201"],
  },
  {
    primary_id: "D0K3YM",
    names: [
      "N-Isopropyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL592185",
      "N-Isopropyl-10H-Phenothiazine-10-Carboxamide",
      "N-Propan-2-Ylphenothiazine-10-Carboxamide",
      "Oprea1_607910",
      "MLS001196405",
      "ARONIS009672",
      "AC1LE088",
      "SCHEMBL11315494",
      "ZINC88005",
      "MolPort-000-803-539",
      "MolPort-019-786-000",
      "HMS1587K22",
      "HMS2859K14",
      "STK126366",
      "BDBM50308429",
      "AKOS000498951",
      "MCULE-3571561994",
      "CCG-113325",
      "SMR000556300",
      "81225-58-3",
      "BB0286649",
      "N-(Methylethyl)Phenothiazin-10-Ylcarboxamide",
      "EU-0046799",
      "ST45046412",
      "KS-00004187",
      "N-Isopropyl-1''H-Phenothiazine-1''-Carboxamide",
      "AN-329/41195442",
    ],
  },
  {
    primary_id: "D0K3ZB",
    names: ["Quinazoline Derivative 6"],
  },
  {
    primary_id: "D0K3ZL",
    names: ["MA09-HRPE Cells"],
  },
  {
    primary_id: "D0K3ZR",
    names: [
      "Bisoprolol",
      "Bisocor",
      "Bisoprololum",
      "Cardicor",
      "Concor",
      "Detensiel",
      "Emconcor",
      "Emcor",
      "Euradal",
      "Isoten",
      "Monocor",
      "Soloc",
      "Soprol",
      "Zebeta",
      "Bisoprolol Fumerate",
      "Bisoprolol Hemifumarate",
      "Bisoprololum [Latin]",
      "CL-297939",
      "Concor (TN)",
      "Concore (TN)",
      "EMD-33512",
      "Monocor (TN)",
      "Zebeta (TN)",
      "Bisoprolol (USAN/INN)",
      "Bisoprolol [USAN:BAN:INN]",
      "EMD-33-512",
      "(+-)-1-((Alpha-(2-Isopropoxyethoxy)-P-Tolyl)Oxy)-3-(Isopropylamino)-2-Propanol",
      "(RS)-1-(4-(2-Isopropoxyethoxymethyl)Phenoxy)-3-(Isopropylamino)-2-Propanol",
      "1-(Propan-2-Ylamino)-3-[4-(2-Propan-2-Yloxyethoxymethyl)Phenoxy]propan-2-Ol",
      "1-[(1-Methylethyl)Amino]-3-({4-[({2-[(1-Methylethyl)Oxy]ethyl}oxy)Methyl]phenyl}oxy)Propan-2-Ol",
      "1-{4-[(2-Isopropoxyethoxy)Methyl]phenoxy}-3-(Isopropylamino)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D0K4AC",
    names: [
      "DELTORPHIN",
      "Deltorphin",
      "Dermenkephalin",
      "Deltorphin A",
      "119975-64-3",
      "CHEMBL20775",
      "CHEBI:81455",
      "Tyr-Met-Phe-His-Leu-Met-Asp-NH2",
      "AC1MHYOS",
      "DTXSID60152630",
      "BDBM50000518",
      "Tyrosyl-Methionyl-Phenylalanyl-Histidyl-Leucyl-Methionyl-Aspartamide",
      "ZINC169301619",
    ],
  },
  {
    primary_id: "D0K4AQ",
    names: ["Mycophenolic Acid/Nucleotide Derivative 6"],
  },
  {
    primary_id: "D0K4CD",
    names: ["ASP-8477"],
  },
  {
    primary_id: "D0K4CH",
    names: ["IMGN-009"],
  },
  {
    primary_id: "D0K4CQ",
    names: ["Indecainide"],
  },
  {
    primary_id: "D0K4CX",
    names: ["PMID30280939-Compound-WO2015035128"],
  },
  {
    primary_id: "D0K4DI",
    names: ["Anistreplase"],
  },
  {
    primary_id: "D0K4DP",
    names: ["1-[4-Hydroxy-3-(Tritylaminomethyl)Butyl]uracil", "CHEMBL378101", "SCHEMBL913211"],
  },
  {
    primary_id: "D0K4ED",
    names: ["Ro-23-9424"],
  },
  {
    primary_id: "D0K4EM",
    names: [
      "3-(Pyridin-4-Yl)-1H-Indol-6-Amine",
      "CHEMBL207612",
      "887615-80-7",
      "CTK3A6374",
      "DTXSID00658787",
      "BDBM50182874",
      "AKOS030619478",
      "1H-Indol-6-Amine, 3-(4-Pyridinyl)-",
    ],
  },
  {
    primary_id: "D0K4FP",
    names: ["AZD-8165", "AZ-12971554", "Thombin Inhibitor Prodrug (Oral), AstraZeneca"],
  },
  {
    primary_id: "D0K4GD",
    names: ["RS-1636"],
  },
  {
    primary_id: "D0K4GG",
    names: ["STP-902"],
  },
  {
    primary_id: "D0K4GI",
    names: ["Affinitak + Gemcitabine"],
  },
  {
    primary_id: "D0K4GK",
    names: ["22R-Hydroxycholesterol", "22beta-Hydroxycholesterol", "22R-OHC"],
  },
  {
    primary_id: "D0K4GW",
    names: ["INL-002"],
  },
  {
    primary_id: "D0K4HG",
    names: [
      "8-(3-Nitro-Phenyl)-6-Pyridin-4-Ylmethyl-Quinoline",
      "8-(3-Nitrophenyl)-6-(Pyridin-4-Ylmethyl)Quinoline",
      "159925-31-2",
      "RS-14203",
      "CHEMBL127944",
      "8-(3-Nitrophenyl)-6-(4-Pyridinylmethyl)Quinoline",
      "ZINC6165",
      "SCHEMBL3698956",
      "DTXSID20431317",
      "XRSKAWJXBDACRF-UHFFFAOYSA-N",
      "3g58",
      "3g45",
      "BDBM50092632",
      "AKOS022171718",
      "4CA-1115",
      "KB-74213",
      "DA-09714",
      "AJ-08271",
      "AX8257760",
      "FT-0733089",
    ],
  },
  {
    primary_id: "D0K4HI",
    names: ["Sulfonylthioureas"],
  },
  {
    primary_id: "D0K4HW",
    names: ["N-DESMETHYLCLOZAPINE"],
  },
  {
    primary_id: "D0K4IH",
    names: [
      "4-(Benzylideneamino)Benzoic Acid",
      "CHEMBL567820",
      "820985-82-8",
      "AC1LEKUN",
      "SCHEMBL11027747",
      "SCHEMBL10354517",
      "ZINC56233",
      "CTK3E2373",
      "DTXSID10350765",
      "MolPort-000-420-278",
      "4-(A-Benzylideneamino)Benzoic Acid",
      "Benzylideneaniline-P-Carboxylic Acid",
      "STK861975",
      "BDBM50299292",
      "AKOS002162184",
      "MCULE-4796916281",
      "4-((1E)-2-Phenyl-1-Azavinyl)Benzoic Acid",
      "ST45187403",
      "4-{[(E)-Phenylmethylidene]amino}benzoic Acid",
      "Benzoic Acid, 4-[(E)-(Phenylmethylene)Amino]-",
    ],
  },
  {
    primary_id: "D0K4IS",
    names: ["PF-06293620"],
  },
  {
    primary_id: "D0K4IV",
    names: ["TD-1439"],
  },
  {
    primary_id: "D0K4JI",
    names: ["JOT106"],
  },
  {
    primary_id: "D0K4JQ",
    names: ["GSK2336805"],
  },
  {
    primary_id: "D0K4JS",
    names: ["ATX-3105"],
  },
  {
    primary_id: "D0K4KM",
    names: ["PMID29473428-Compound-60"],
  },
  {
    primary_id: "D0K4LQ",
    names: ["Pyrazolopyrimidine Derivative 3"],
  },
  {
    primary_id: "D0K4LS",
    names: ["Go-Y026"],
  },
  {
    primary_id: "D0K4MG",
    names: ["2-HYDROXYGARVIN A", "2-Hydroxygarvin A", "CHEMBL465623", "SCHEMBL3076542"],
  },
  {
    primary_id: "D0K4MH",
    names: ["Tapentadol Hydrochloride"],
  },
  {
    primary_id: "D0K4MU",
    names: [
      "CROBENETINE HYDROCHLORIDE",
      "CROBENETINE",
      "CROBENETINE)",
      "BIII-890-CL",
      "Crobenetine",
      "Crobenetine Hydrochloride",
      "(2R,6S)-3-[2(S)-Benzyloxypropyl]-6,11,11-Trimethyl-1,2,3,4,5,6-Hexahydro-2,6-Methano-3-Benzazocin-10-Ol",
      "(2R,6S)-3-[2(S)-Benzyloxypropyl]-6,11,11-Trimethyl-1,2,3,4,5,6-Hexahydro-2,6-Methano-3-Benzazocin-10-Ol Hydrochloride",
    ],
  },
  {
    primary_id: "D0K4MW",
    names: ["LP-30171"],
  },
  {
    primary_id: "D0K4NZ",
    names: ["Velneperit"],
  },
  {
    primary_id: "D0K4OC",
    names: ["DI-VAL-L-DC"],
  },
  {
    primary_id: "D0K4OF",
    names: [
      "PlaMavax",
      "AdCh63-ME-TRAP",
      "MVA-ME-TRAP",
      "ME-TRAP Fusion Antigen Adenoviral Vector Vaccine (Malaria), Okairos",
    ],
  },
  {
    primary_id: "D0K4OL",
    names: ["LOR-2501"],
  },
  {
    primary_id: "D0K4OS",
    names: ["TS-121"],
  },
  {
    primary_id: "D0K4PK",
    names: ["1-Guanidino-7-Isoquinolinesulphonamide"],
  },
  {
    primary_id: "D0K4PN",
    names: [
      "AZD-1134",
      "CHEMBL602674",
      "SCHEMBL4251105",
      "CKBARMWSFIJZTL-UHFFFAOYSA-N",
      "BDBM50306588",
      "6-Fluoro-8-(4-Methyl-Piperazin-1-Yl)-4-Oxo-4H-Chromene-2-Carboxylic Acid [4-(4-Propionyl-Piperazin-1-Yl)-Phenyl]-Amide",
    ],
  },
  {
    primary_id: "D0K4PV",
    names: [
      "6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-N-((R)-1-HYDROXYETHYL)-1,2,2-TRIMETHYL-1,2,3,4-TETRAHYDRO-1,8-NAPHTHYRIDINE-4-CARBOXAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1082650",
      "BDBM50320181",
    ],
  },
  {
    primary_id: "D0K4PY",
    names: [
      "LIQUIRTIGENIN",
      "578-86-9",
      "4',7-Dihydroxyflavanone",
      "(S)-7-Hydroxy-2-(4-Hydroxyphenyl)Chroman-4-One",
      "(2S)-Liquiritigenin",
      "5-DEOXYFLAVANONE",
      "UNII-T194LKP9W6",
      "7-Hydroxy-2-(4-Hydroxy-Phenyl)-Chroman-4-One",
      "T194LKP9W6",
      "CHEMBL252642",
      "CHEBI:28777",
      "AK121923",
      "(2S)-7-Hydroxy-2-(4-Hydroxyphenyl)-2,3-Dihydro-4H-1-Benzopyran-4-One",
      "(2S)-7-Hydroxy-2-(4-Hydroxyphenyl)-2,3-Dihydro-4H-Chromen-4-One",
      "(2S)-2-(4-Hydroxyphenyl)-7-Oxidanyl-2,3-Dihydrochromen-4-One",
    ],
  },
  {
    primary_id: "D0K4QL",
    names: ["Doxorubicin-LL2 Conjugate, Immunomedics", "DOX-LL2, Immunomedics"],
  },
  {
    primary_id: "D0K4QT",
    names: ["BAY-Y-5959"],
  },
  {
    primary_id: "D0K4QW",
    names: [
      "1,3-Bis(Nitrooxy)Propan-2-Yl 2-Acetoxybenzoate",
      "CHEMBL467905",
      "1,3-Dinitrooxy-2-Propyl 2-Acetoxybenzoate",
      "SCHEMBL8318285",
      "BDBM50258493",
    ],
  },
  {
    primary_id: "D0K4RK",
    names: ["Blinatumomab", "AMG 103"],
  },
  {
    primary_id: "D0K4RN",
    names: [
      "2-(1H-Pyrrol-1-Ylcarbonyl)Benzene-1,3,5-Triol",
      "1-(2,4,6-Trihydroxybenzoyl)Pyrrole",
      "288839-58-7",
      "3eko",
      "CHEMBL513225",
      "DB08443",
      "2-(1-Pyrrolylcarbonyl)Benzene-1,3,5-Triol",
      "2-(Pyrrole-1-Carbonyl)Benzene-1,3,5-Triol",
    ],
  },
  {
    primary_id: "D0K4RU",
    names: ["AF-130"],
  },
  {
    primary_id: "D0K4SL",
    names: ["Phosphatidylserine", "Distearoylphosphatidylserine", "LifeExtension PS Caps"],
  },
  {
    primary_id: "D0K4SV",
    names: [
      "3,4-Dihydro-1H-Quinolin-(2E)-Ylideneamine",
      "CHEMBL6656",
      "2-Amino-3,4-Dihydroquinoline",
      "1,2,3,4-Tetrahydroquinolin-2-Imine",
      "AC1N2ULT",
      "3,4-Dihydroquinolin-2-Amine",
      "SCHEMBL2075410",
      "RREBANHDFAXBEZ-UHFFFAOYSA-N",
      "MolPort-005-905-447",
      "HMS1726J17",
      "3,4-Dihydroquinolin-2(1H)-Imine",
      "ZINC13014981",
      "BDBM50062129",
      "AKOS006221851",
      "AKOS008024789",
      "MCULE-1322063466",
      "3,4-Dihydro-1H-Quinolin-(2Z)-Ylideneamine",
    ],
  },
  {
    primary_id: "D0K4SZ",
    names: ["PMID25666693-Compound-46"],
  },
  {
    primary_id: "D0K4TG",
    names: ["PS-015146"],
  },
  {
    primary_id: "D0K4TI",
    names: [
      "5-Hexyl-5-Phenyl-Pyrimidine-2,4,6-Trione",
      "5-Hexyl-5-Phenylbarbituric Acid",
      "BRN 0297956",
      "BARBITURIC ACID, 5-HEXYL-5-PHENYL-",
      "67051-21-2",
      "CHEMBL173075",
      "AC1L2LKK",
      "4-24-00-02103 (Beilstein Handbook Reference)",
      "CTK8J9653",
      "DTXSID20217355",
      "5-Hexyl-5-Phenyl Barbituric Acid",
      "BDBM50099125",
      "LS-24512",
    ],
  },
  {
    primary_id: "D0K4TV",
    names: ["5-Methyl-2-M-Tolyl-1H-[1,8]naphthyridin-4-One"],
  },
  {
    primary_id: "D0K4UF",
    names: ["Imidazopyridine Acetamide Analog 4"],
  },
  {
    primary_id: "D0K4UT",
    names: ["DNA-2401"],
  },
  {
    primary_id: "D0K4UV",
    names: ["KU-5039"],
  },
  {
    primary_id: "D0K4UW",
    names: ["Imitrodast"],
  },
  {
    primary_id: "D0K4VN",
    names: ["Molecule 21"],
  },
  {
    primary_id: "D0K4VT",
    names: ["Benzoxazepine Analog 1"],
  },
  {
    primary_id: "D0K4VW",
    names: ["Cenersen"],
  },
  {
    primary_id: "D0K4WI",
    names: [
      "2-[4-(2,4-Dichlorophenoxy)Phenoxy]Propanoic Acid",
      "Diclofop",
      "40843-25-2",
      "Diclofop Acid",
      "DICHLORFOP ACID",
      "Caswell No. 328B",
      "2-(4-(2,4-Dichlorophenoxy)Phenoxy)Propanoic Acid",
      "Diclofop [ANSI:BSI:ISO]",
      "2-[4-(2,4-Dichlorophenoxy)Phenoxy]propanoic Acid",
      "HOE 21079",
      "BRN 2338390",
      "CHEBI:81909",
      "OOLBCHYXZDXLDS-UHFFFAOYSA-N",
      "Methyl 2-(4-(2,4-Dichlorophenyoxy)Phenoxy)Propanoate",
      "HOE-021079",
      "(RS)-2-(4-(2,4-Dichlorophenoxy)Phenoxy)Propionic Acid",
      "Methyl (RS)-2-(4-(2,4-Dichlorophenoxy)Phenoxy)Propionate",
      "Propanoic Acid, 2-(4-(2,4-Dichlorophenoxy)Phenoxy)-",
      "Propanoic Acid, 2-[4-(2,4-Dichloro",
    ],
  },
  {
    primary_id: "D0K4WS",
    names: ["KNI-10152"],
  },
  {
    primary_id: "D0K4WT",
    names: [
      "S-1033",
      "(+)-15-Deoxyprostaglandin F2alpha Sodium Salt Sesquihydrate",
      "(+)-7-[(1R,2R,3R,5S)-3,5-Dihydroxy-2-[1(E)-Octenyl]cyclopentyl]-5(Z)-Heptenoic Acid Sodium Salt Sesquihydrate",
      "138282-73-2",
    ],
  },
  {
    primary_id: "D0K4WW",
    names: ["11A1"],
  },
  {
    primary_id: "D0K4XA",
    names: ["F-G-G-F-T-G-A-R-K-S-A-R-K-L-MeA-N-Q-COOH"],
  },
  {
    primary_id: "D0K4XQ",
    names: ["MCL-431"],
  },
  {
    primary_id: "D0K4YF",
    names: ["PMID27376512-Compound-Table1Example4"],
  },
  {
    primary_id: "D0K4YP",
    names: ["THI", "2-Acetyl-5-Tetrahydroxybutyl Imidazole"],
  },
  {
    primary_id: "D0K4YY",
    names: ["(5S,6R)-[Octahydro-Quinolin-(2E)-Ylidene]amine", "CHEMBL193007"],
  },
  {
    primary_id: "D0K4ZP",
    names: ["N-(Dibenzo[b,d]thiophen-3-Ylsulfonyl)-L-Valine"],
  },
  {
    primary_id: "D0K4ZR",
    names: ["Oleocanthal", "(-)-Oleocanthal"],
  },
  {
    primary_id: "D0K4ZX",
    names: ["Conotoxin"],
  },
  {
    primary_id: "D0K5BP",
    names: ["HSP-990"],
  },
  {
    primary_id: "D0K5BY",
    names: ["MIM-3Ae"],
  },
  {
    primary_id: "D0K5CB",
    names: [
      "Salbutamol",
      "Aerolin",
      "Airomir",
      "Almotex",
      "Anebron",
      "Asmadil",
      "Asmanil",
      "Asmasal",
      "Asmatol",
      "Asmaven",
      "Asmidon",
      "Asmol",
      "Asthalin",
      "Asthavent",
      "Broncovaleas",
      "Bronter",
      "Bugonol",
      "Bumol",
      "Butamol",
      "Butohaler",
      "Butotal",
      "Butovent",
      "Buventol",
      "Cetsim",
      "Cobutolin",
      "Dilatamol",
      "Ecovent",
      "Farcolin",
      "Gerivent",
      "Grafalin",
      "Libretin",
      "Medolin",
      "Mozal",
      "Novosalmol",
      "Parasma",
      "Pneumolat",
      "Proventil",
      "Respax",
      "Respolin",
      "Rotahaler",
      "Sabutal",
      "Sabutamol",
      "Salamol",
      "Salbetol",
      "Salbron",
      "Salbuhexal",
      "Salbulin",
      "Salbupur",
      "Salbusian",
      "Salbutalan",
      "Salbutamolum",
      "Salbutan",
      "Salbutard",
      "Salbutine",
      "Salbutol",
      "Salbuven",
      "Salbuvent",
      "Sallbupp",
      "Salmaplon",
      "Salomol",
      "Salvent",
      "Saventol",
      "Servitamol",
      "Solbutamol",
      "Spreor",
      "Sultanol",
      "Suprasma",
      "Suxar",
      "Theosal",
      "Tobybron",
      "Vencronyl",
      "Ventamol",
      "Ventilan",
      "Ventiloboi",
      "Ventodisks",
      "Ventoline",
      "Volma",
      "Volmax",
      "Zaperin",
      "Albuterol Sulphate",
      "Albuterol [USAN]",
      "Albuterol Hemisulfate",
      "Proventil HFA",
      "Proventil Inhaler",
      "Salbutamol Sulphate",
      "Salbutamol Sulfate",
      "Sultanol N",
      "Ventalin Inhaler",
      "Ventolin Inhaler",
      "Ventolin Rotacaps",
      "Volare Albuterol HFA",
      "AH 3365",
      "S 8260",
      "Aerolin (TN)",
      "Albuterol (USP)",
      "Alti-Salbutamol",
      "Arubendol-Salbutamol",
      "Asmol Uni-Dose",
      "Asthalin (TN)",
      "Broncho-Spray",
      "Buto-Asma",
      "Dl-Albuterol",
      "Dl-Salbutamol",
      "ProAi (TN)",
      "Proventil (TN)",
      "Racemic-Salbutamol",
      "Salbu-BASF",
      "Salbu-Fatol",
      "Salbutamolum [INN-Latin]",
      "Ventolin (TN)",
      "Volare Easi-Breathe",
      "R,S-Albuterol",
      "Alpha-[(T-Butylamino)Methyl]-4-Hydroxy-M-Xylene-Alpha,alpha-Diol",
      "Alpha-[(Tert-Butylamino)Methyl]-4-Hydroxy-M-Xylene-Alpha,alpha'-Diol",
      "DL-N-Tert-Butyl-2-(4-Hydroxy-3-Hydroxymethylphenyl)-2-Hydroxyethylamine",
      "Alpha'-((Tert-Butylamino)Methyl)-4-Hydroxy-M-Xylene-Alpha,alpha'-Diol",
      "Alpha(Sup 1)-((Tert-Butylamino)Methyl)-4-Hydroxy-M-Xylene-Alpha,alpha'-Diol",
      "(Alpha1-((Tert-Butylamino)Methyl)-4-Hydroxy-M-Xylene-Alpha,alpha-Diol)",
      "1-(Tert-Butylaminomethyl)-4-Hydroxy-M-Xylene-Alpha1,alpha3-Diol",
      "2-(Tert-Butylamino)-1-(4-Hydroxy-3-Hydroxymethylphenyl)Ethanol",
      "2-T-Butylamino-1-(4-Hydroxy-3-Hydroxy-3-Hydroxymethyl)Phenylethanol",
      "4-Hydroxy-3-Hydroxymethyl-Alpha-((Tert-Butylamino)Methyl)Benzyl Alcohol",
      "4-[2-(Tert-Butylamino)-1-Hydroxyethyl]-2-(Hydroxymethyl)Phenol",
      "4-{2-[(1,1-Dimethylethyl)Amino]-1-Hydroxyethyl}-2-(Hydroxymethyl)Phenol",
    ],
  },
  {
    primary_id: "D0K5CJ",
    names: ["DAVLB-HYDRAZIDE", "Desacetylvinblastine-3-Carboxyhydrazide"],
  },
  {
    primary_id: "D0K5CK",
    names: ["5,7-Dimethyl-2-M-Tolyl-1H-[1,8]naphthyridin-4-One"],
  },
  {
    primary_id: "D0K5CP",
    names: ["Pancreatic Enzyme"],
  },
  {
    primary_id: "D0K5CX",
    names: ["Celacade", "Celacade (TN)"],
  },
  {
    primary_id: "D0K5DA",
    names: ["N-(4-Methoxybenzyl)Oleamide"],
  },
  {
    primary_id: "D0K5DM",
    names: ["E[c(RGDyK)]2-PTX Conjugate"],
  },
  {
    primary_id: "D0K5EE",
    names: ["S-33084", "Benzopyrano[3,4-C]pyrrole Derivs, Servier"],
  },
  {
    primary_id: "D0K5EI",
    names: ["Naphthalen-2-Yl Cyclohexylcarbamate"],
  },
  {
    primary_id: "D0K5EP",
    names: ["2'-O-Methyl G-Clamp Containing Oligonucleotides"],
  },
  {
    primary_id: "D0K5ER",
    names: [
      "Rosiglitazone XR",
      "Avandia",
      "Nyracta",
      "Venvia",
      "Rosiglitazone Maleate [USAN]",
      "Rosiglitazone Maleate",
      "BRL 49653C",
      "Avandia (TN)",
      "Avandiaadministration For 6-12 Weeks",
      "BRL 49653-C",
      "BRL-49653C",
      "SB-206846",
      "SB-210232",
      "Rosiglitazone Maleate (JAN/USAN)",
      "(+-)-5-(P-(2-(Methyl-2-Pyridylamino)Ethoxy)Benzyl)-2,4-Thiazolidinedione Maleate (1:1)",
      "(+-)-5-[[4-2-(Methyl]-2-Pyridinylamino)Ethoxy]phenyl]methyl]-2,4-Thiazolidinedione,(Z)-2-Butenedioate (1:1)",
      "2,4-Thiazolidinedione, 5-((4-(2-(Methyl-2-Pyridinylamino)Ethoxy)Phenyl)Methyl)-, (2Z)-2-Butenedioate (1:1)",
      "2,4-Thiazolidinedione, 5-[[4-[2-(Methyl-2-Pyridinylamino) Ethoxy]phenyl]methyl]-, (2Z)-2-Butenedioate (1:1)",
      "2,4-Thiazolidinedione,5-((4-(2-(Methyl-2-Pyridinylamino)Ethoxy)Phenyl)Methyl)-,(2Z)-2-Butenedioate",
      "5-[[4-[2-[methyl(Pyridin-2-Yl)Amino]ethoxy]phenyl]methyl]-1,3-Thiazolidine-2,4-Dione",
      "5-{[4-({2-[methyl(Pyridin-2-Yl)Amino]ethyl}oxy)Phenyl]methyl}-1,3-Thiazolidine-2,4-Dione (2Z)-But-2-Enedioate",
    ],
  },
  {
    primary_id: "D0K5ET",
    names: ["M207"],
  },
  {
    primary_id: "D0K5GG",
    names: ["2,3,4-Trimethoxy-4'-Amino-Trans-Stilbene", "CHEMBL1173067", "BDBM50322053"],
  },
  {
    primary_id: "D0K5HD",
    names: ["LY-315902"],
  },
  {
    primary_id: "D0K5HI",
    names: ["Talnetant"],
  },
  {
    primary_id: "D0K5IP",
    names: ["Palonosetron And Fosnetupitant"],
  },
  {
    primary_id: "D0K5IQ",
    names: ["DAA-1097", "DAA-1106"],
  },
  {
    primary_id: "D0K5JA",
    names: ["8-Cyclohexyloxy-Quinolin-2-Ylamine", "CHEMBL185235", "SCHEMBL5883787"],
  },
  {
    primary_id: "D0K5JE",
    names: ["HD-0701"],
  },
  {
    primary_id: "D0K5JG",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 5"],
  },
  {
    primary_id: "D0K5JU",
    names: ["4-Imidazol-1-Ylmethyl-3-Nitroxanthen-9-One", "CHEMBL1083664"],
  },
  {
    primary_id: "D0K5KG",
    names: [
      "3-(1-Methyl-3,4-Dihydronaphthalen-2-Yl)-Pyridine",
      "3614-50-4",
      "3-(1-Methyl-3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "Dihydronaphthalene 6",
      "2-Yl)-Pyridine",
      "3-(1-Methyl-3,4-Dihydro-Naphthalen-",
      "AC1O70HB",
      "SCHEMBL4514804",
      "CHEMBL206283",
      "BDBM8892",
      "CTK4H5985",
      "DTXSID90424897",
      "ZINC13684515",
      "AKOS015965701",
      "ACM3614504",
      "AC-20696",
      "KB-232450",
    ],
  },
  {
    primary_id: "D0K5KL",
    names: [
      "BW 373U86",
      "BW-373U86",
      "SNC-86",
      "CHEMBL25230",
      "155836-50-3",
      "BW373U86",
      "4-((R)-((2S,5R)-4-Allyl-2,5-Dimethylpiperazin-1-Yl)(3-Hydroxyphenyl)Methyl)-N,N-Diethylbenzamide",
      "4-[(R)-[(2S,5R)-2,5-Dimethyl-4-Prop-2-Enylpiperazin-1-Yl]-(3-Hydroxyphenyl)Methyl]-N,N-Diethylbenzamide",
      "LBLDMHBSVIVJPM-YZIHRLCOSA-N",
      "150428-54-9",
      "Tocris-1663",
      "AC1L3O2K",
      "(+-)-4-((Alpha-R*)-Alpha-((2S*,5R*)-4-Allyl-2,5-Dimethyl-1-Piperazinyl)-3-Hydroxybenzyl)-N,N-Diethylbenzamide",
      "SCHEMBL232127",
      "GTPL9002",
      "CHEBI:114187",
      "MolPort-023-276-182",
    ],
  },
  {
    primary_id: "D0K5KS",
    names: ["Adalimumab"],
  },
  {
    primary_id: "D0K5LJ",
    names: [
      "N-Propargyl-1(S)-Aminoindan",
      "185517-74-2",
      "(S)-N-(2-Propynyl)-2,3-Dihydroinden-1-Amine",
      "TVP1022",
      "N-PROPARGYL-1(S)-AMINOINDAN",
      "(S)-Rasagiline",
      "J-500397",
      "S-PAI",
      "AC1NRD1Z",
      "N-Propargyl-1-(S)Aminoindan",
      "SCHEMBL332967",
      "CHEMBL1235738",
      "BDBM11000",
      "(S)-N-2-Propynyl-1-Indanamine",
      "RUOKEQAAGRXIBM-LBPRGKRZSA-N",
      "MolPort-006-666-243",
      "KS-00000LL3",
      "ZINC19875505",
      "ANW-23242",
      "FCH907116",
      "AKOS024256741",
      "TV-1022",
      "DB03894",
      "AJ-75608",
      "AS-32581",
      "HY-14200",
      "CJ-16483",
      "AC-14138",
      "TVP1022, &gt",
      "TC-111987",
      "AB0052190",
      "FT-0694134",
      "FT-0674331",
      "V3968",
      "(1S)-N-Methyl-N-Prop-2-Ynyl-Indan-1-Amine",
    ],
  },
  {
    primary_id: "D0K5LQ",
    names: ["Pemirolast"],
  },
  {
    primary_id: "D0K5LT",
    names: ["Adult Stem Cell Therapy"],
  },
  {
    primary_id: "D0K5LX",
    names: ["4-(6-HYDROXY-1H-INDAZOL-3-YL)BENZENE-1,3-DIOL"],
  },
  {
    primary_id: "D0K5ME",
    names: ["Levetimide"],
  },
  {
    primary_id: "D0K5NG",
    names: ["HLD200"],
  },
  {
    primary_id: "D0K5NN",
    names: ["GFP-Bacterial Methioninase/Selenomethionine GDEPT"],
  },
  {
    primary_id: "D0K5NQ",
    names: ["N-[4-(Trifluoromethyl)Phenyl]sulfamide"],
  },
  {
    primary_id: "D0K5NV",
    names: ["[Lys8(Alexa 546) ]PVA", "CHEMBL1161979"],
  },
  {
    primary_id: "D0K5NZ",
    names: ["MESULERGINE"],
  },
  {
    primary_id: "D0K5OT",
    names: ["Penicillin G Sodium"],
  },
  {
    primary_id: "D0K5OZ",
    names: ["AZ3971", "AZ 3971", "AZ-3971"],
  },
  {
    primary_id: "D0K5PY",
    names: ["N,N-Dimethylsphingosine"],
  },
  {
    primary_id: "D0K5QH",
    names: ["7alpha-Hydroxycholesterol", "7-Alpha-OHC"],
  },
  {
    primary_id: "D0K5QJ",
    names: ["ADS-103294", "CHEMBL214678", "SCHEMBL6533940", "BDBM50200791"],
  },
  {
    primary_id: "D0K5QW",
    names: [
      "I-309",
      "2050-48-8",
      "Bis(4-Bromophenyl)Sulfone",
      "4,4'-Sulfonylbis(Bromobenzene)",
      "Bis(P-Bromophenyl) Sulfone",
      "Sulfone, Bis(P-Bromophenyl)",
      "4-Bromophenyl Sulfone",
      "4,4'-Dibromodiphenyl Sulfone",
      "1,1'-Sulfonylbis(4-Bromobenzene)",
      "4,4'-Dibromodiphenylsulfone",
      "Benzene, 1,1'-Sulfonylbis[4-Bromo-",
      "4,4'-DIBROMO DIPHENYL SULFONE",
      "Bis(P-Bromophenyl)Sulfone",
      "Benzene, 1,1'-Sulfonylbis(4-Bromo-",
      "NSC 43047",
      "BRN 2505841",
      "1-Bromo-4-(4-Bromophenyl)Sulfonylbenzene",
      "AI3-22602",
      "AK112394",
      "1-Bromo-4-(4-Bromophenyl)Sulfonyl-Benzene",
    ],
  },
  {
    primary_id: "D0K5QY",
    names: ["AP-101"],
  },
  {
    primary_id: "D0K5RX",
    names: ["KYS-05050"],
  },
  {
    primary_id: "D0K5SN",
    names: ["Dosmalfate", "Diotul (TN)"],
  },
  {
    primary_id: "D0K5TE",
    names: ["BMS-955176"],
  },
  {
    primary_id: "D0K5TW",
    names: ["NS5B HCV Polymerase Inhibitors"],
  },
  {
    primary_id: "D0K5UF",
    names: ["PMID24432909C8e", "4cd0", "GTPL8137", "ZINC98050687", "BDBM50448785", "NCGC00485046-01", "P-355", "AWJ"],
  },
  {
    primary_id: "D0K5UH",
    names: [
      "CR-1795",
      "CHEMBL169466",
      "BDBM50281699",
      "5-Dipentylcarbamoyl-5-[((R)-Naphthalene-2-Carbonyl)-Amino]-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0K5UI",
    names: [
      "UBP310",
      "(S)-1-(2-Amino-2-Carboxyethyl)-3(2-Carboxythiophene-3-YL-Methyl)-5-Methylpyrimidine-2,4-Dione",
      "UBP 310",
      "UBP-310",
      "CHEMBL373428",
      "(S)-1-(2-AMINO-2-CARBOXYETHYL)-3(2-CARBOXYTHIOPHENE-3-YL-METHYL)-5-METHYLPYRIMIDINE-2,4-DIONE",
      "UBA",
      "3-[[3-[(2S)-2-Amino-3-Hydroxy-3-Oxopropyl]-5-Methyl-2,6-Dioxopyrimidin-1-Yl]methyl]thiophene-2-Carboxylic Acid",
      "[3H]UBP310",
      "AC1O4WKX",
      "GTPL4334",
      "GTPL4092",
      "SCHEMBL15959446",
      "ZTAZUCRXCRXNSU-VIFPVBQESA-N",
      "MolPort-006-069-045",
      "2f34",
      "ZINC16052001",
      "BDBM50207593",
      "AKOS024457719",
      "NCGC00370889-01",
    ],
  },
  {
    primary_id: "D0K5UK",
    names: ["HuMax-Wnt"],
  },
  {
    primary_id: "D0K5UM",
    names: ["3,5-Dimethyl-2-(Phenylsulfonamido)Benzoic Acid"],
  },
  {
    primary_id: "D0K5VE",
    names: ["6-(4-Chlorophenyl)-4-Morpholino-2H-Pyran-2-One", "CHEMBL222821", "SCHEMBL3537608"],
  },
  {
    primary_id: "D0K5WC",
    names: ["6-Hydroxy-5,7-Dimethyl-Beta-Carboline", "CHEMBL458558"],
  },
  {
    primary_id: "D0K5WH",
    names: ["ISIS 6717"],
  },
  {
    primary_id: "D0K5WM",
    names: ["CS-3030"],
  },
  {
    primary_id: "D0K5WS",
    names: [
      "Cholecalciferol",
      "Arachitol",
      "Calciol",
      "Cholecalciferolum",
      "Colecalciferol",
      "Colecalciferolo",
      "Colecalciferolum",
      "Colecalcipherol",
      "Delsterol",
      "Deparal",
      "Ebivit",
      "FeraCol",
      "Provitamine",
      "Provitina",
      "Rampage",
      "Ricketon",
      "Trivitan",
      "Vigorsan",
      "Colecalciferolo [DCIT]",
      "VITAMIN D",
      "Duphafral D3 1000",
      "LT00244775",
      "NEO Dohyfral D3",
      "Oleovitamin D3",
      "TNP00266",
      "VITAMIN D3 POWDER",
      "VITAMIN_D3",
      "Vitamin D3",
      "Vitamin D3 Emulsifiable",
      "Activated 7-Dehydrocholesterol",
      "Cholecalciferol (D3)",
      "Cholecalciferol, D3",
      "Colecalciferol (INN)",
      "Colecalciferolum [INN-Latin]",
      "D3-Vicotrat",
      "D3-Vigantol",
      "Delta-D",
      "Irradiated 7-Dehydrocholesterol",
      "Micro-Dee",
      "Vitamin D-3",
      "Cholecalciferol (JP15/USP)",
      "Cholecalciferol [USAN:BAN:JAN]",
      "Delta-D (TN)",
      "Vitinc Dan-Dee-3",
      "Vi-De-3-Hydrosol",
      "(+)-Vitamin D3",
      "(3S,5Z,7E)-9,10-Secocholesta-5,7,10(19)-Trien-3-Ol",
      "(3beta,5Z,7E)-9,10-Secocholesta-5,7,10(19)-Trien-3-Ol",
      "(5Z,7E)-(3S)-9,10-Seco-5,7,10(19)-Cholestatrien-3-Ol",
      "(5Z,7E)-(3S)-9,10-Secocholesta-5,7,10(19)-Trien-3-Ol",
      "25(OH)D",
      "7-Dehydrocholesterol Activated",
      "7-Dehydrocholesterol, Activated",
      "7-Dehydrocholesterol, Irradiated",
      "7-Dehydrocholestrol, Activated",
      "9,10-Seco(5Z,7E)-5,7,10(19)-Cholestatrien-3-Ol",
      "9,10-Seco(5Z,7E)-5,7,10(19)-Cholestatrien-3beta-Ol",
      "9,10-Secocholesta-5(Z),7(E),10(19)-Trien-3(Beta.)-Ol",
      "9,10-Secocholesta-5,7,10(19)-Trien-3-Beta-Ol",
    ],
  },
  {
    primary_id: "D0K5XL",
    names: ["ADS-103293", "CHEMBL217626", "SCHEMBL6507646", "BDBM50200786"],
  },
  {
    primary_id: "D0K5YJ",
    names: ["PMID25482888-Compound-50"],
  },
  {
    primary_id: "D0K5ZY",
    names: [
      "INDOLACTUM",
      "(-)-Indolactam V",
      "Indolactam V",
      "90365-57-4",
      "Indolactum",
      "UNII-8CIY9O1323",
      "(-)-ILV",
      "BRN 4711877",
      "CHEMBL27266",
      "8CIY9O1323",
      "(2s,5s)-5-(Hydroxymethyl)-2-Isopropyl-1-Methyl-1,2,4,5,6,8-Hexahydro-3h-[1,4]diazonino[7,6,5-Cd]indol-3-One",
      "3H-Pyrrolo(4,3,2-Gh)-1,4-Benzodiazonin-3-One, 1,2,4,5,6,8-Hexahydro-5-(Hydroxymethyl)-1-Methyl-2-(1-Methylethyl)-, (2S-(2R*,5R*))-",
      "3H-Pyrrolo(4,3,2-Gh)-1,4-Benzodiazonin-3-One, 1,2,4,5,6,8-Hexahydro-5-(Hydroxymethyl)-1-Methyl-2-(1-Methylethyl)-, (2S,5S)-",
    ],
  },
  {
    primary_id: "D0K6AF",
    names: [
      "Dysiherbaine",
      "(2r,3ar,6s,7r,7ar)-2-[(2s)-2-Amino-2-Carboxyethyl]-6-Hydroxy-7-(Methylamino)Hexahydro-2h-Furo[3,2-B]pyran-2-Carboxylic Acid",
      "DYH",
      "CHEMBL221142",
      "GTPL4185",
      "SCHEMBL12409079",
      "BDBM85740",
      "(2R,3aR,6S,7R,7aR)-2-[(2S)-2-Amino-2-Carboxyethyl]-6-Hydroxy-7-(Methylamino)Hexahydro-2H-Furo[3,2-B]pyran-2-Carboxylic Acid (Non-Preferred Name)",
      "(2R,3aR,6S,7R,7aR)-2-[(2S)-2-Amino-2-Carboxyethyl]-6-Hydroxy-7-(Methylamino)-Hexahydro-2H-Furo[3,2-B]pyran-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0K6AK",
    names: [
      "VUF-5296",
      "CHEMBL13559",
      "SCHEMBL7784076",
      "ZINC13518991",
      "BDBM50127607",
      "AKOS006350891",
      "(1R,2R)-2-(1H-Imidazol-4-Yl)-Cyclopropylamine",
    ],
  },
  {
    primary_id: "D0K6AL",
    names: ["PM1183"],
  },
  {
    primary_id: "D0K6BI",
    names: ["PMID22115617C2c"],
  },
  {
    primary_id: "D0K6CG",
    names: [
      "DRA-161",
      "DRA-162",
      "DRA-162)",
      "DRACO Anti-CD3 Therapeutic",
      "DRA-187b Based SMIP, Emergent",
      "DRA-187b Based SMIP, Trubion Pharmaceuticals",
      "DRA-187a-Based SMIP (LADA, Solid Organ Transplantation), Emergent",
      "DRA-187a-Based SMIP (LADA, Solid Organ Transplantation), Trubion Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0K6CM",
    names: ["PMID29338548-Compound-16"],
  },
  {
    primary_id: "D0K6CW",
    names: ["CEN-109"],
  },
  {
    primary_id: "D0K6DG",
    names: ["ONO-8130"],
  },
  {
    primary_id: "D0K6EG",
    names: ["TUG-891"],
  },
  {
    primary_id: "D0K6ES",
    names: [
      "CRT-0004592",
      "CRT-0066854",
      "CRT-0099431",
      "Protein Kinase C Iota Inhibitors (Cancer)",
      "PKCi Inhibitors (Cancer), CRT",
      "Protein Kinase C Iota Inhibitors (Cancer), Cancer Research Technology",
    ],
  },
  {
    primary_id: "D0K6FM",
    names: ["SMEATHXANTHONE A"],
  },
  {
    primary_id: "D0K6GI",
    names: ["Benzimidazole Derivative 2"],
  },
  {
    primary_id: "D0K6GX",
    names: ["N-Cyclohexyl-4-Phenyl-Butyramide"],
  },
  {
    primary_id: "D0K6GY",
    names: ["Ethyl Octylfluorophosphonate", "CHEMBL1222256"],
  },
  {
    primary_id: "D0K6GZ",
    names: ["Sparfloxacin"],
  },
  {
    primary_id: "D0K6HD",
    names: ["Azetidine Derivative 2"],
  },
  {
    primary_id: "D0K6HE",
    names: ["PRO-001"],
  },
  {
    primary_id: "D0K6HG",
    names: ["V116517"],
  },
  {
    primary_id: "D0K6HL",
    names: [
      "Cyclopiazonic Acid",
      "2o9j",
      "2oa0",
      "BSPBio_001303",
      "GTPL5350",
      "SCHEMBL1471247",
      "MolPort-003-983-745",
      "BS0055",
      "ZINC100061481",
      "ZINC100067111",
      "ZINC110970687",
      "FT-0624278",
      "J-011619",
      "(2R,3S,9R)-5-Acetyl-4-Hydroxy-8,8-Dimethyl-7,16-Diazapentacyclo[9.6.1.0^{2,9}.0^{3,7}.0^{15,18}]octadeca-1(17),4,11(18),12,14-Pentaen-6-One",
    ],
  },
  {
    primary_id: "D0K6IM",
    names: ["PMID28270021-Compound-WO2010077680 495"],
  },
  {
    primary_id: "D0K6IW",
    names: [
      "N-[3,3-Bis-(4-Fluorophenyl)-Propyl]-Nicotinamide",
      "CHEMBL563216",
      "SCHEMBL5947860",
      "PGLSPMQRWMIFOQ-UHFFFAOYSA-N",
      "BDBM50297398",
    ],
  },
  {
    primary_id: "D0K6JQ",
    names: ["INO-3401", "DNA Vaccine (Intradermal Cellectra Electroporation, H5N1 Influenza), Inovio"],
  },
  {
    primary_id: "D0K6JZ",
    names: ["DCLL9718S"],
  },
  {
    primary_id: "D0K6KU",
    names: ["AFX-9901"],
  },
  {
    primary_id: "D0K6LA",
    names: ["OPC-22575"],
  },
  {
    primary_id: "D0K6LX",
    names: ["MK-5823"],
  },
  {
    primary_id: "D0K6MH",
    names: [
      "2-(7-Phenylheptanoyl)Oxazole-4-Carbonitrile",
      "CHEMBL462423",
      "SCHEMBL2162448",
      "IGDLMZPDBOKHRJ-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0K6MI",
    names: ["[125I]ICYP", "[125I]iodocyanopindolol"],
  },
  {
    primary_id: "D0K6MM",
    names: ["N-(Furan-2-Ylmethyl)Estradiol-16-Carboxamide"],
  },
  {
    primary_id: "D0K6MW",
    names: ["Teicoplanin"],
  },
  {
    primary_id: "D0K6NS",
    names: ["T-226293"],
  },
  {
    primary_id: "D0K6NW",
    names: ["REC-02"],
  },
  {
    primary_id: "D0K6OL",
    names: [
      "2-Cyclohexyl-N-(4-Methoxy-Phenyl)-Acetamide",
      "CHEMBL192523",
      "2-Cyclohexyl-N-(4-Methoxyphenyl)Acetamide",
      "CBMicro_031991",
      "AC1LH2YD",
      "Oprea1_584438",
      "SCHEMBL12931990",
      "MolPort-002-194-789",
      "ZINC451523",
      "STL384380",
      "BDBM50167041",
      "AKOS001067578",
      "MCULE-9916735458",
      "BIM-0031907.P001",
      "SR-01000232787",
    ],
  },
  {
    primary_id: "D0K6OM",
    names: ["1,2-Bis(3,4-Difluorophenyl)Ethane-1,2-Dione", "CHEMBL397739", "BDBM50209399"],
  },
  {
    primary_id: "D0K6PO",
    names: [
      "Acenanthrene-9,10-Dione",
      "1,2-Aceanthrylenedione",
      "Aceanthrenequinone",
      "6373/11/1",
      "1,2-Aceanthrenedione",
      "Aceanthrylene-1,2-Dione",
      "Aceanthrenchinon",
      "NSC245130",
      "AC1L7ULB",
      "Aceanthrenequinone, 96%",
      "ACMC-20ap73",
      "SCHEMBL579274",
      "CHEMBL235503",
      "CTK2F3554",
      "BDBM22859",
      "DTXSID90311753",
      "YAIBDWAANBTYIA-UHFFFAOYSA-N",
      "MolPort-002-800-851",
      "1,2-Dione-Based Compound, 16",
      "1,2-Dihydroaceanthrylene-1,2-Dione",
      "MFCD00035767",
      "AKOS015902885",
      "NSC-245130",
      "MCULE-7113625880",
      "VZ20381",
      "CJ-29850",
      "CC-02809",
      "KB-64253",
      "TC-172326",
      "DB-054531",
      "ST50825822",
      "AE-641/00376058",
      "373A111",
    ],
  },
  {
    primary_id: "D0K6PP",
    names: ["AG-NPP709"],
  },
  {
    primary_id: "D0K6QS",
    names: [
      "CHLORODEOXYURIDINE",
      "Chlorodeoxyuridine",
      "5-Chlorodeoxyuridine",
      "5-Chloro-2'-Deoxyuridine",
      "CldU",
      "50-90-8",
      "BRN 0546807",
      "CHEMBL505732",
      "Uridine, 5-Chloro-2'-Deoxy-",
      "Uridine,5-Chloro-2'-Deoxy-",
      "5-Chloro-1-[(2R,4S,5R)-4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl]pyrimidine-2,4-Dione",
      "5-Chloro-1-((2R,4S,5R)-4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl)Pyrimidine-2,4(1H,3H)-Dione",
      "2'-Deoxy-5-Chlorouridine",
      "5-Chloro-DUrd",
      "5-Chloro-2 -Deoxyuridine",
      "AC1L23DV",
      "AC1Q77TD",
      "SCHEMBL1469771",
      "CTK4J3337",
      "NJCXGFKPQSFZIB-RRKCRQDMSA-N",
      "MolPort-001-814-149",
    ],
  },
  {
    primary_id: "D0K6QU",
    names: ["IT-141"],
  },
  {
    primary_id: "D0K6QX",
    names: ["3-(2,6-Dimethyl-Phenyl)-1-Propyl-Piperidine", "CHEMBL144501", "BDBM50068424"],
  },
  {
    primary_id: "D0K6RA",
    names: [
      "Chloropyramine",
      "Halopyramine",
      "Chlorpyramine",
      "Anaphylline",
      "Suprastin",
      "Synpen",
      "Avapena",
      "Synopen",
      "Chlorneoantergan",
      "Chloropyribenzamine",
      "Chlorpyraminum",
      "Allergan S",
      "Synopen R",
      "Chloroneoantergan",
      "59-32-5",
      "UNII-2K3L8O9SOV",
      "Chloropyraminum [INN-Latin]",
      "Cloropiramina [INN-Spanish]",
      "NSC 241038",
      "Chloropyramine [INN:BAN:DCF]",
      "EINECS 200-421-1",
      "N-(P-Chlorobenzyl)-N',N'-Dimethyl-N-(2-Pyridyl)Ethylenediamine",
      "BRN 0257085",
      "2K3L8O9SOV",
      "C16H20ClN3",
      "2-((P-Chlorobenzyl)(2-(Dimethylamino)Ethyl)Amino)Pyridine",
      "Ethylenediamine, N-(P-Chlorobenzyl)-N',N'-",
    ],
  },
  {
    primary_id: "D0K6RG",
    names: [
      "Benzyl Bromide",
      "BENZYL BROMIDE",
      "100-39-0",
      "(Bromomethyl)Benzene",
      "Alpha-Bromotoluene",
      "Bromophenylmethane",
      "Benzene, (Bromomethyl)-",
      "Benzene, Bromomethyl-",
      "Phenylmethyl Bromide",
      "1-Bromotoluene",
      "Benzylbromide",
      "Cyclite",
      "Bromomethylbenzene",
      "A-BROMOTOLUENE",
      "Toluene, Alpha-Bromo-",
      "BnBr",
      "NSC 8041",
      "UNII-XR75BS721D",
      "HSDB 369",
      ".alpha.-Bromotoluene",
      ".omega.-Bromotoluene",
      "CCRIS 5980",
      "EINECS 249-248-3",
      "EINECS 202-847-3",
      "UN1737",
      "Toluene, .alpha.-Bromo-",
      "ALPHA-BROMOPHENYLMETHANE",
      "AI3-15300",
      "XR75BS721D",
      "CHEMBL1085946",
      "CHEBI:59858",
      "AGEZXYOZHKGVCM-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0K6SD",
    names: ["PMID16242323C15a"],
  },
  {
    primary_id: "D0K6SM",
    names: ["Ilepatril"],
  },
  {
    primary_id: "D0K6SV",
    names: ["QSPac", "CHEMBL521026"],
  },
  {
    primary_id: "D0K6SZ",
    names: ["Dipeptide Analog 4"],
  },
  {
    primary_id: "D0K6TE",
    names: [
      "6-(3-Chloro-4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL365999",
      "SCHEMBL4012132",
      "UVRXGDFVCKYIGU-UHFFFAOYSA-N",
      "BDBM50168370",
      "ZINC13645074",
    ],
  },
  {
    primary_id: "D0K6TI",
    names: ["8S-HETE", "8-Hydroxyeicosatetraenoic Acid"],
  },
  {
    primary_id: "D0K6TN",
    names: ["Resorcinol Compound 25"],
  },
  {
    primary_id: "D0K6UG",
    names: ["RhH1.3"],
  },
  {
    primary_id: "D0K6UJ",
    names: ["Yeast-Based Malaria Treatment"],
  },
  {
    primary_id: "D0K6UK",
    names: ["Saframycin A", "AC1MHXXG"],
  },
  {
    primary_id: "D0K6UO",
    names: ["NK-101", "Cyclin Dependent Kinase Inhibitor (Traumatic Brain Injury), Neurokin"],
  },
  {
    primary_id: "D0K6UQ",
    names: ["HLA-A*2402-Restricted KIF20A And VEGFR-1 Epitope Peptide Vaccine"],
  },
  {
    primary_id: "D0K6UV",
    names: ["DNL201"],
  },
  {
    primary_id: "D0K6VD",
    names: ["Juniper Tar"],
  },
  {
    primary_id: "D0K6VR",
    names: ["6-Chloro-1,2,3,4-Tetrahydro-Pyrazino[1,2-A]indole", "CHEMBL288445", "BDBM50108303", "AKOS022692451"],
  },
  {
    primary_id: "D0K6VW",
    names: ["7-Iodoindirubin-3-Oxime"],
  },
  {
    primary_id: "D0K6WD",
    names: [
      "(9H-Beta-Carbolin-3-Yl)-Ethyl-Amine",
      "9H-Pyrido[3,4-B]indol-3-Amine, N-Ethyl-",
      "95935-52-7",
      "ACMC-20m0fc",
      "CHEMBL353967",
      "N-Ethyl-Beta-Carboline-3-Amine",
      "CTK3F3147",
      "DTXSID30541219",
      "N-Ethyl-9H-Beta-Carbolin-3-Amine",
    ],
  },
  {
    primary_id: "D0K6WI",
    names: ["3-(Aminooxy)Propan-1-Amine Hydrochloride", "CHEMBL1209776"],
  },
  {
    primary_id: "D0K6WQ",
    names: ["Azaline B Derivative"],
  },
  {
    primary_id: "D0K6XD",
    names: ["4-(1H-Indol-4-Yloxy)-1-(Isopropylamino)Butan-2-Ol", "CHEMBL412022"],
  },
  {
    primary_id: "D0K6XL",
    names: ["PMID25726713-Compound-29"],
  },
  {
    primary_id: "D0K6YK",
    names: ["IN-1130"],
  },
  {
    primary_id: "D0K6YM",
    names: ["TG4040 (MVA-HCV)"],
  },
  {
    primary_id: "D0K6ZA",
    names: ["[3H]OSIP339391"],
  },
  {
    primary_id: "D0K6ZH",
    names: ["PSI-661"],
  },
  {
    primary_id: "D0K6ZI",
    names: ["H2N2 Live Attenuated Influenza Vaccine"],
  },
  {
    primary_id: "D0K6ZT",
    names: ["2-(2-Fluorophenoxy)-3-(Piperidin-4-Yl)Pyridine", "CHEMBL597530", "SCHEMBL13769759"],
  },
  {
    primary_id: "D0K7BF",
    names: ["NX-201"],
  },
  {
    primary_id: "D0K7CD",
    names: ["Fused Aryl Carbocycle Derivative 8"],
  },
  {
    primary_id: "D0K7CR",
    names: ["Sp-876"],
  },
  {
    primary_id: "D0K7CV",
    names: [
      "Agmatine",
      "1-(4-Aminobutyl)Guanidine",
      "(4-Aminobutyl)Guanidine",
      "Argmatine",
      "306-60-5",
      "1-Amino-4-Guanidobutane",
      "N-4-Aminobutylguanidine",
      "Guanidine, (4-Aminobutyl)-",
      "N-(4-Aminobutyl)Guanidine",
      "(4-Aminobutyl) Guanidine",
      "1,4-Butanediamine, N-(Aminoiminomethyl)-",
      "2-(4-Aminobutyl)Guanidine",
      "UNII-70J407ZL5Q",
      "EINECS 206-187-7",
      "NSC 56332",
      "4-Guanidino-1-Butanamine",
      "CHEMBL58343",
      "CHEBI:17431",
      "QYPPJABKJHAVHS-UHFFFAOYSA-N",
      "70J407ZL5Q",
      "Agmatinium",
      "Guanidine, (4-Aminobutyl)- (8CI)(9CI)",
      "AGMATINE SULFATE ENDOGENOUS AGONIST AT",
      "AG2",
    ],
  },
  {
    primary_id: "D0K7CX",
    names: [
      "HT1042",
      "5-(3-Nitrophenyl)-1,3,4-Oxathiazol-2-One",
      "CHEMBL3265177",
      "23589-77-7",
      "NSC294566",
      "AC1L6WL8",
      "GTPL8649",
      "SCHEMBL1781484",
      "DTXSID10315430",
      "BDBM50010993",
    ],
  },
  {
    primary_id: "D0K7DV",
    names: ["Kevetrin"],
  },
  {
    primary_id: "D0K7DW",
    names: ["Sar-Arg-Val-Tyr-Ile-His-Pro-Ala"],
  },
  {
    primary_id: "D0K7EC",
    names: [
      "N,N-Diethyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL589062",
      "Phenothiazine-10-Carboxylic Acid Diethylamide",
      "N,N-Diethyl-10H-Phenothiazine-10-Carboxamide",
      "N,N-Diethylphenothiazine-10-Carboxamide",
      "BAS 04087804",
      "AC1LE3I9",
      "MLS001212486",
      "ARONIS009570",
      "SCHEMBL7343904",
      "ZINC91243",
      "MolPort-001-593-657",
      "MolPort-019-785-999",
      "HMS1680D13",
      "HMS2821P21",
      "STK017216",
      "CCG-22244",
      "BDBM50308411",
      "AKOS000498788",
      "MCULE-7627155893",
      "KS-0000415D",
      "N,N-Diethylphenothiazin-10-Ylcarboxamide",
      "SMR000518964",
      "BB0286547",
      "ST45046311",
      "N,N-Diethyl-1''H-Phenothiazine-1''-Carboxamide",
      "SR-01000506178",
      "AN-329/41195338",
    ],
  },
  {
    primary_id: "D0K7EZ",
    names: ["N-(2-Methoxybenzyl)Cinnamamidine"],
  },
  {
    primary_id: "D0K7FA",
    names: ["C[YYAEGLEE]-NH2", "CHEMBL1082940"],
  },
  {
    primary_id: "D0K7FT",
    names: ["AG-221", "AG-221 (IDH2 Inhibitor)"],
  },
  {
    primary_id: "D0K7FY",
    names: ["EGF816"],
  },
  {
    primary_id: "D0K7GD",
    names: [
      "N*4*-(3-Bromo-Phenyl)-Quinazoline-4,7-Diamine",
      "CHEMBL420624",
      "7-Amino-4-[(3-Bromophenyl)Amino]quinazoline",
      "4-N-(3-Bromophenyl)Quinazoline-4,7-Diamine",
      "4-Anilinoquinazoline Deriv. 48",
      "AC1NS3NB",
      "BDBM3297",
      "SCHEMBL2986298",
      "GHVBIROTJQDRQB-UHFFFAOYSA-N",
      "ZINC3815072",
      "AKOS014671983",
      "7-Amino-4-(3-Bromoanilino)Quinazoline",
      "7-Amino-4(3-Bromoanilino)-Quinazoline",
      "7-Amino-4-(3-Bromoanilino)-Quinazoline",
      "169205-81-6",
      "N-(3-Bromophenyl)Quinazoline-4,7-Diamine",
      "7-Amino-4-[(3-Bromophenyl)Aminolquinazoline",
      "FT-0768311",
      "7-Amino-4-[(3-Bromophenyl)-Amino]quinazoline",
    ],
  },
  {
    primary_id: "D0K7GL",
    names: ["PMID7473541C19"],
  },
  {
    primary_id: "D0K7GN",
    names: ["Neurotrophic Factor Companion"],
  },
  {
    primary_id: "D0K7HI",
    names: ["ISIS 11665"],
  },
  {
    primary_id: "D0K7HR",
    names: ["2-(4-Hydroxy-Phenyl)-4-Vinyl-Quinolin-6-Ol", "2-(4-Hydroxyphenyl)-4-Vinylquinolin-6-Ol"],
  },
  {
    primary_id: "D0K7HT",
    names: [
      "2',5'-DIDEOXY-ADENOSINE 3'-MONOPHOSPHATE",
      "2',5'-Dideoxy-Adenosine 3'-Monophosphate",
      "2',5'-Dideoxyadenosine 3'-Monophosphate",
      "AC1L9H2O",
      "SCHEMBL1920523",
      "CTK8E3534",
      "2',5'-Dideoxy-3'-Adenylic Acid",
      "2,5-Dideoxyadenosine 3-Monophosphate",
      "AKOS025394264",
      "DB06843",
      "121878-11-3",
      "RT-018381",
      "2',5'-Dideoxyadenosine 3'-Phosphoric Acid",
      "[(2R,3S,5R)-5-(6-Aminopurin-9-Yl)-2-Methyloxolan-3-Yl]oxyphosphonic Acid",
      "[(2R,3S,5R)-5-(6-Aminopurin-9-Yl)-2-Methyloxolan-3-Yl] Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0K7HU",
    names: [
      "Ciclesonide",
      "Alvesco",
      "Omnair",
      "Omnaris",
      "Osonase",
      "Osonide",
      "Alvesco HFA",
      "Ciclesonide [INN]",
      "Omnaris HFA",
      "RPR 251526",
      "Alvesco (TN)",
      "BTR-15",
      "BTR-15K",
      "BY-9010",
      "BYK-20426",
      "KS-1165",
      "Omnaris (TN)",
      "Omniair (TN)",
      "TBN-15",
      "B-9207-015",
      "Ciclesonide (JAN/USAN/INN)",
      "(R)-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione Cyclic 16,17-Acetal With Cyclohexanecarboxaldehyde, 21-Isobutyrate",
    ],
  },
  {
    primary_id: "D0K7HV",
    names: [
      "N-(4'-T-Butyl-4-Biphenylyl)Urea",
      "CHEMBL244126",
      "SCHEMBL5425660",
      "N-(4''-T-Butyl-4-Biphenylyl)Urea",
      "BDBM50220188",
    ],
  },
  {
    primary_id: "D0K7JC",
    names: ["ZP-001"],
  },
  {
    primary_id: "D0K7JT",
    names: ["3-Ethenylquinoline-8-Carboxamide", "CHEMBL481793", "8-Quinolinecarboxamide, 3-Ethenyl-", "BDBM50255270"],
  },
  {
    primary_id: "D0K7KM",
    names: ["3-Phenyl-1-(2-Methylthiazol-4-Ylethynyl)Benzene", "CHEMBL365403", "BDBM50149798"],
  },
  {
    primary_id: "D0K7KV",
    names: ["ORP-100"],
  },
  {
    primary_id: "D0K7LA",
    names: ["Low Molecular Weight Dextran Sulphate"],
  },
  {
    primary_id: "D0K7LF",
    names: ["Tetrabutylammonium Ion"],
  },
  {
    primary_id: "D0K7LI",
    names: ["Resorcinol Compound 31"],
  },
  {
    primary_id: "D0K7LU",
    names: ["Santonin"],
  },
  {
    primary_id: "D0K7LZ",
    names: [
      "8-Dibenzofuran-4-Yl-2-Morpholin-4-Ylchromen-4-One",
      "CHEMBL186048",
      "503468-92-6",
      "8-(Dibenzo[b,d]furan-4-Yl)-2-Morpholino-4H-Chromen-4-One",
      "8-Dibenzofuran-4-Yl-2-Morpholin-4-Yl-Chromen-4-One",
      "SCHEMBL13346347",
      "CTK1G6916",
      "DTXSID50470335",
      "BDBM50156503",
      "AKOS030553517",
      "4H-1-Benzopyran-4-One, 8-(4-Dibenzofuranyl)-2-(4-Morpholinyl)-",
    ],
  },
  {
    primary_id: "D0K7MY",
    names: ["ESI-014"],
  },
  {
    primary_id: "D0K7NC",
    names: ["PMID30107136-Compound-Example16"],
  },
  {
    primary_id: "D0K7NI",
    names: ["ABT-RTA-408"],
  },
  {
    primary_id: "D0K7NJ",
    names: ["Recombinant Coagulation Factors"],
  },
  {
    primary_id: "D0K7NQ",
    names: [
      "Colistin",
      "Colistin Sulphate",
      "Polymyxin E",
      "Colistin Sulfate, Nonsterile",
      "Polymyxin E. Sulfate",
      "Coly-Mycin M Parenteral (TN)",
    ],
  },
  {
    primary_id: "D0K7OO",
    names: [
      "SA-14-14-2",
      "CD JEVAX",
      "JE Virus Vaccine, Chengdu Institute Of Biological Products",
      "JEV Vaccine, Chengdu Instituteof Biological Products",
      "Japanese Encephalitis Virus Vaccine, Chengdu Institute Of Biological Products",
    ],
  },
  {
    primary_id: "D0K7OV",
    names: ["Imidazo Pyridine Derivative 5"],
  },
  {
    primary_id: "D0K7OW",
    names: ["GSK-1360707"],
  },
  {
    primary_id: "D0K7PD",
    names: ["Cat Hair Allergen Extract", "Cat Hair Allergen Extract (Sublingual, Allergy)"],
  },
  {
    primary_id: "D0K7PJ",
    names: ["ISIS 102486"],
  },
  {
    primary_id: "D0K7PQ",
    names: ["ST-570"],
  },
  {
    primary_id: "D0K7PS",
    names: [
      "5-Bromo-2-(4-Chlorophenylsulfonamido)Benzoic Acid",
      "5-Bromo-2-{[(4-Chlorophenyl)Sulfonyl]amino}benzoic Acid",
      "AC1LLKZK",
      "BAS 00347593",
      "Sulfonamide Compound, 1",
      "CBDivE_004908",
      "SCHEMBL5925257",
      "CHEMBL212522",
      "BDBM17595",
      "MolPort-001-926-487",
      "ZINC754636",
      "HMS3604C20",
      "STL325205",
      "AKOS000541754",
      "DB07309",
      "MCULE-4831129771",
      "107143-69-1",
      "ST50224787",
      "EU-0033636",
      "SR-01000389963",
      "A193400",
      "5-Bromo-2-(4-Chlorobenzenesulfonamido)Benzoic Acid",
      "SR-01000389963-1",
      "5-Bromo-2-[(4-Chlorobenzene)Sulfonamido]benzoic Acid",
      "5-Bromo-2-[(4-Chlorophenyl)Sulfonylamino]benzoic Acid",
    ],
  },
  {
    primary_id: "D0K7QP",
    names: ["(1S,2S)-N-Oleoylcyclopropanolamide"],
  },
  {
    primary_id: "D0K7QQ",
    names: ["TS-134"],
  },
  {
    primary_id: "D0K7QR",
    names: ["ST-5732"],
  },
  {
    primary_id: "D0K7RO",
    names: ["2-Morpholino-1,1-Di(Pyridin-3-Yl)Octan-1-Ol", "CHEMBL1088804", "SCHEMBL1242942"],
  },
  {
    primary_id: "D0K7SU",
    names: ["N-Arachidonyl-D-Alanine"],
  },
  {
    primary_id: "D0K7SX",
    names: ["Avelumab"],
  },
  {
    primary_id: "D0K7TE",
    names: ["Biaryl Mannoside Derivative 27"],
  },
  {
    primary_id: "D0K7TX",
    names: [
      "HOE-065",
      "2-[N-[(S)-1-Ethoxycarbonyl-3-Phenylpropyl]-L-Alanyl]-(1S,3S,5S)-2-Azabicyclo[3.3.0]octane-3-Carboxylic Acid N-Octyl Ester Maleate",
    ],
  },
  {
    primary_id: "D0K7UL",
    names: ["S-2678"],
  },
  {
    primary_id: "D0K7VM",
    names: ["ARI-1778"],
  },
  {
    primary_id: "D0K7WK",
    names: [
      "Ondansetron",
      "DESMETHYLONDANSETRON",
      "Zofran",
      "Zophren",
      "Zudan",
      "Sandoz Ondansetron",
      "ZOFRAN IN PLASTIC CONTAINER",
      "Zofran ODT",
      "GR 38032",
      "GR 38032X",
      "GR38032F",
      "Apo-Ondansetron",
      "GR-38032F",
      "Novo-Ondansetron",
      "Ondansetron (Zofran)",
      "PHL-Ondansetron",
      "PMS-Ondansetron",
      "Ratio-Ondansetron",
      "SN-307",
      "Zofran (TN)",
      "Zofran ODT (TN)",
      "Ondansetron [USAN:INN:BAN]",
      "Ondansetron (JAN/USP/INN)",
      "Ondansetron, (+,-)-Isomer",
      "(RS)-1,2,3,9-Tetrahydro-9-Methyl-3-(2-Methylimidazol-1-Ylmethyl)Carbazol-4-One",
      "1,2,3,9-Tetrahydro-9-Methyl-3-((2-Methyl-1H-Imidazol-1-Yl)Methyl)-4H-Carbazol-4-One",
      "9-Methyl-3-(2-Methyl-Imidazol-1-Ylmethyl)-1,2,3,9-Tetrahydro-Carbazol-4-One",
      "9-Methyl-3-[(2-Methyl-1H-Imidazol-1-Yl)Methyl]-1,2,3,9-Tetrahydro-4H-Carbazol-4-One",
      "9-Methyl-3-[(2-Methylimidazol-1-Yl)Methyl]-2,3-Dihydro-1H-Carbazol-4-One",
    ],
  },
  {
    primary_id: "D0K7WW",
    names: ["Uridine Diphosphate Galactose"],
  },
  {
    primary_id: "D0K7XA",
    names: ["Pyrazole Derivative 21"],
  },
  {
    primary_id: "D0K7XM",
    names: [
      "APLYSAMINE",
      "Aplysamine-1",
      "NSC625525",
      "CHEMBL380697",
      "3-(2,6-Dibromo-4-(2-(Dimethylamino)Ethyl)Phenoxy)-N,N-Dimethyl-1-Propanamine",
      "AC1L7JVE",
      "AC1Q25RU",
      "BDBM50177729",
      "NSC-625525",
      "3-[2,6-Dibromo-4-(2-Dimethylaminoethyl)Phenoxy]-N,N-Dimethyl-Propan-1-Amine",
      "3-(2,6-Dibromo-4-(2-(Dimethylamino)Ethyl)Phenoxy)-N,N-Dimethylpropan-1-Amine",
      "N-(3-(2,6-Dibromo-4-(2-(Dimethylamino)Ethyl)Phenoxy)Propyl)-N,N-Dimethylamine",
      "3-[2,6-Dibromo-4-(2-Dimethylaminoethyl)Phenoxy]-N,N-Dimethylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D0K7XQ",
    names: ["N-Hydroxy-N-[1-(4-Isobutylphenyl)Ethyl]urea", "CHEMBL167577", "SCHEMBL9443901"],
  },
  {
    primary_id: "D0K7XR",
    names: ["KSB-304"],
  },
  {
    primary_id: "D0K7XU",
    names: [
      "N-Hydroxy-4-Naphthalen-2-Yl-Benzamide",
      "CHEMBL154008",
      "SCHEMBL10361606",
      "ZINC27653366",
      "BDBM50015182",
      "4-(2-Naphthalenyl)Benzohydroximic Acid",
    ],
  },
  {
    primary_id: "D0K7YL",
    names: ["CRA_8696", "AC1O4QGN"],
  },
  {
    primary_id: "D0K7ZH",
    names: ["PF-06649751"],
  },
  {
    primary_id: "D0K8AA",
    names: ["Zinc Salts"],
  },
  {
    primary_id: "D0K8AL",
    names: ["OG-232"],
  },
  {
    primary_id: "D0K8AP",
    names: ["[4Aph(CO-NH-(C2H4O)2-C2H5)5]degarelix", "CHEMBL219532"],
  },
  {
    primary_id: "D0K8AU",
    names: ["2-Morpholino-N-Phenethylpyrimidin-4-Amine", "CHEMBL1085505", "BDBM50319974"],
  },
  {
    primary_id: "D0K8BJ",
    names: ["MNK-010"],
  },
  {
    primary_id: "D0K8BO",
    names: ["TX-RAD"],
  },
  {
    primary_id: "D0K8BT",
    names: ["Isosorbide-2-(Methylcarbamate)-5-Benzoate", "CHEMBL523500"],
  },
  {
    primary_id: "D0K8BZ",
    names: ["N-(4-Phenylsulfamoyl-Naphthalen-1-Yl)-Benzamide", "CHEMBL222110", "SCHEMBL1337989"],
  },
  {
    primary_id: "D0K8CI",
    names: ["Otilonium Bromide"],
  },
  {
    primary_id: "D0K8CP",
    names: ["HO/02/10"],
  },
  {
    primary_id: "D0K8DH",
    names: ["4-Biphenyl-4-Yl-2-Hexyl-1H-Imidazole", "CHEMBL118603", "SCHEMBL4814536"],
  },
  {
    primary_id: "D0K8DI",
    names: ["SQ-109"],
  },
  {
    primary_id: "D0K8DW",
    names: ["VBI-1501A"],
  },
  {
    primary_id: "D0K8DY",
    names: [
      "9-(N-Benzylaminomethyl)-9,10-Dihydroanthracene",
      "CHEMBL349543",
      "BDBM50097644",
      "9-(Benzylaminomethyl)-9,10-Dihydroanthracene",
    ],
  },
  {
    primary_id: "D0K8DZ",
    names: ["VUF 5207"],
  },
  {
    primary_id: "D0K8ED",
    names: ["N-Carboxymethyl-N-Cyclopentyl-Phthalamic Acid", "CHEMBL115153"],
  },
  {
    primary_id: "D0K8EE",
    names: ["NSC-93405"],
  },
  {
    primary_id: "D0K8EV",
    names: ["PMID29473428-Compound-11"],
  },
  {
    primary_id: "D0K8EZ",
    names: [
      "2-Amino-6-(3-Fluoro-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(3-Fluorophenylthio)Benzenecarbonitrile",
      "AC1LAE0W",
      "SCHEMBL2639266",
      "BDBM1743",
      "CHEMBL449041",
      "CTK7C6437",
      "ZINC20475",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1m",
      "2-Amino-6-(3-Fluorophenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(3-Fluorophenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D0K8FI",
    names: [
      "Pegmusirudin",
      "BSF-87981",
      "LU-57291",
      "LU-87981",
      "Polyethylene Glycol Hirudin, Knoll",
      "SPP-200",
      "PEG-Hirudin, Knoll",
    ],
  },
  {
    primary_id: "D0K8GD",
    names: [
      "AR20",
      "CZCVYRBGBQAORP-UHFFFAOYSA-N",
      "AR-20",
      "Allylcarbamic Ester Of M-Oxyphenyltrimethylammonium Methylsulfate",
      "AMMONIUM, (M-HYDROXYPHENYL)TRIMETHYL-, METHYLSULFATE, ALLYLCARBAMATE",
      "64051-14-5",
      "Carbamic Acid, N-Allyl-, 3-Dimethylaminophenyl Ester, Methosulfate",
      "Carbamic Acid, N-Allyl-, 3-(Trimethylammonio)Phenyl Ester, Methylsulfate",
      "AC1L2HDE",
      "DTXSID40214189",
      "LS-18470",
      "Methyl Sulfate,trimethyl-[3-(Prop-2-Enylcarbamoyloxy)Phenyl]",
      "Trimethyl-[3-(Prop-2-Enylcarbamoyloxy)Phenyl]azanium",
    ],
  },
  {
    primary_id: "D0K8HA",
    names: ["LL-6531"],
  },
  {
    primary_id: "D0K8HF",
    names: ["PLD-147"],
  },
  {
    primary_id: "D0K8HU",
    names: ["PMID25726713-Compound-39"],
  },
  {
    primary_id: "D0K8IX",
    names: [
      "Thiazolidinedione",
      "2295-31-0",
      "1,3-Thiazolidine-2,4-Dione",
      "Thiazolidine-2,4-Dione",
      "2,4-Dioxothiazolidine",
      "2,4(3H,5H)-Thiazoledione",
      "USAF EK-5496",
      "Thiazolidindione",
      "UNII-AA68LXK93C",
      "Thiazolidinedione-2,4",
      "NSC 6745",
      "EINECS 218-941-2",
      "BRN 0110700",
      "AA68LXK93C",
      "AI3-61185",
      "CHEBI:50992",
      "NSC6745",
      "ZOBPZXTWZATXDG-UHFFFAOYSA-N",
      "MFCD00005478",
      "2,4-Thiazolidinedione, 99%",
      "C3H3NO2S",
      "Thiazolidine-Dione",
      "2,4-Thiazolidindione",
      "2,5H)-Thiazoledione",
      "PubChem17487",
    ],
  },
  {
    primary_id: "D0K8JE",
    names: ["TT-123", "C03/TT-123"],
  },
  {
    primary_id: "D0K8KA",
    names: ["DOX-PSASP-BBN-RGD Prodrugs", "DOX-PSASP-BBN-RGD Prodrugs (Prostate Cancer)"],
  },
  {
    primary_id: "D0K8KE",
    names: ["Laxatives Bulk Forming", "Bulk Forming Laxatives"],
  },
  {
    primary_id: "D0K8KJ",
    names: ["DuP-129", "ACAT Inhibitors, Du Pont"],
  },
  {
    primary_id: "D0K8KX",
    names: ["Quercetin"],
  },
  {
    primary_id: "D0K8LB",
    names: ["PI-88/Taxotere"],
  },
  {
    primary_id: "D0K8LM",
    names: [
      "BSO",
      "5072-26-4",
      "DL-Buthionine-[S,R]-Sulfoximine",
      "Buthionine Sulfoxamine",
      "Buthionine Sulphoximine",
      "Buthione Sulfoximine",
      "Buthionine-S,R-Sulfoximine",
      "2-Amino-4-(S-Butylsulfonimidoyl)Butanoic Acid",
      "Butionine Sulfoximine",
      "2-Amino-4-(Butylsulfonimidoyl)Butanoic Acid",
      "L-Buthionine Sulfoximine",
      "D,L-Buthionine-(S,R)-Sulfoximine",
      "DL-Buthionine-Sulfoximine",
      "NSC 381100",
      "Butanoic Acid, 2-Amino-4-(S-Butylsulfonimidoyl)-",
      "BRN 2367136",
      "DL-Buthionine-S,R-Sulfoximine",
      "CHEBI:28714",
      "DL-Buthionine-(S,R)-Sulfoximine",
    ],
  },
  {
    primary_id: "D0K8LN",
    names: ["1-Chloro-4-(1-Phenyl-Cyclohexanesulfonyl)-Benzene", "CHEMBL191195"],
  },
  {
    primary_id: "D0K8MF",
    names: [
      "N-Hydroxy-7-(4-Methoxyphenyl)-7-Oxoheptanamide",
      "CHEMBL95990",
      "SCHEMBL1521117",
      "NIGGAQZXTHMKPL-UHFFFAOYSA-N",
      "BDBM50114822",
      "N-Hydroxy-6-(4-Methoxybenzoyl)Hexanamide",
      "7-(4-Methoxy-Phenyl)-7-Oxo-Heptanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0K8MN",
    names: ["5-Biphenyl-4-Ylmethyl-2-Isobutyl-2H-Tetrazole", "CHEMBL255904"],
  },
  {
    primary_id: "D0K8MP",
    names: [
      "Permethrin",
      "Ambush",
      "PerFoam",
      "Transpermethrin",
      "Acticin Cream",
      "Elimite Cream",
      "Nix Cream Rinse",
      "Transpermethrin [ISO];FMC 35171",
      "NRDC 146",
      "NRDC 148",
      "PS758_SUPELCO",
      "Permethrin (Isomers)",
      "Permethrin (Isomers) Solution",
      "Permethrine,c&t",
      "Trans-(+-)-Permethrin",
      "[3-(Phenyloxy)Phenyl]methyl 3-(2,2-Dichloroethenyl)-2,2-Dimethylcyclopropanecarboxylate",
      "(+)-CIS-PERMETHRIN",
      "(+)-Trans-Permethrin",
      "(+-)-Cis-Fmc 33297",
      "(+-)-Cis-Permethrin",
      "(+-)-Trans-Permethrin",
      "(3-Phenoxyphenyl)Methyl (+-)-Cis,trans-3-(2,2-Dichloroethenyl)-2,2-Dimethylcyclopropanecarboxylate",
      "(3-Phenoxyphenyl)Methyl 3-(2,2-Dichloroethenyl)-2,2-Dimethylcyclopropane-1-Carboxylate",
      "1RS,cis-Permethrin",
      "1RS-Trans-Permethrin",
      "3-Phenoxybenzyle (1RS)-Cis, Trans-3-(2,2-Dichlorovinyl)-2,2-Dimethylcyclopropane Carboxylate",
      "3-Phenoxybenzyl 3-(2,2-Dichloroethenyl)-2,2-Dimethylcyclopropanecarboxylate",
    ],
  },
  {
    primary_id: "D0K8MQ",
    names: ["FP-1096"],
  },
  {
    primary_id: "D0K8NI",
    names: ["ISIS 29246"],
  },
  {
    primary_id: "D0K8NO",
    names: [
      "AK198",
      "{2-[(4-Amino-2-Fluorobenzyl)Carbamoyl]-5-Chlorophenoxy}acetic Acid",
      "GTPL8637",
      "2-[2-[(4-Amino-2-Fluorophenyl)Methylcarbamoyl]-5-Chlorophenoxy]acetic Acid",
    ],
  },
  {
    primary_id: "D0K8NP",
    names: ["Terpyridineplatinum(II) Complexe 1"],
  },
  {
    primary_id: "D0K8NR",
    names: [
      "AMG 386",
      "7440-23-5",
      "Natrium",
      "Sodium-23",
      "Sodio",
      "Sodium Metal",
      "Sodio [Spanish]",
      "Sodium (Liquid Alloy)",
      "UNII-9NEZ333N27",
      "HSDB 687",
      "EINECS 231-132-9",
      "UN1428",
      "UNII-23J3BHR95O",
      "Sodium, Dry Stick",
      "9NEZ333N27",
      "MFCD00085307",
      "Sodium, 998%, Oiled Sticks, Wrapped In Aluminium Foil",
      "Monosodium",
      "Sodium Atom",
      "Mono Sodium",
      "Mono-Sodium",
      "Sodium, CP",
      "SodiuR4733m Hydride, CP",
      "AC1NSENP",
      "ACMC-1BKTZ",
      "Sodium, ACS Reagent, Dry",
      "11Na",
      "EC 231-132-9",
      "AC1Q1W6R",
      "SODIUM, LARGE PIECES",
      "Sodium Hydride, Dry, 95%",
      "NAH 80",
      "HSDB 745",
      "CTK2H7876",
      "CHEBI:26708",
      "Oravescent Fentanyl",
      "R3487",
      "PT-15",
      "R4930",
    ],
  },
  {
    primary_id: "D0K8NW",
    names: ["Ritanserin"],
  },
  {
    primary_id: "D0K8PH",
    names: ["SUVN-504"],
  },
  {
    primary_id: "D0K8QD",
    names: [
      "4-[1-(4-Hydroxyphenyl)-2-Phenylbut-1-Enyl]phenol",
      "91221-46-4",
      "1,1-Bis(4-Hydroxyphenyl)-2-Phenylbut-1-Ene",
      "1,1-Bis(4-Hydroxyphenyl)-2-Phenyl-1-Butene",
      "CHEMBL149791",
      "4,4'-(2-Phenylbut-1-Ene-1,1-Diyl)Diphenol",
      "Tamoxifen Bis-Phenol",
      "1,1-Bhpe",
      "Tamoxifenbisphenol",
      "AC1L1YAG",
      "SCHEMBL639225",
      "CTK5G9076",
      "DTXSID50238452",
      "BPKSDMHGDYTXLI-UHFFFAOYSA-N",
      "ZINC1849484",
      "BDBM50121317",
      "AKOS030254689",
      "KB-09832",
      "4,4'-(2-Phenyl-1-Butenylidene)Bisphenol",
      "B5114",
      "FT-0663304",
      "Phenol, 4,4'-(2-Phenyl-1-Butenylidene)Bis-",
    ],
  },
  {
    primary_id: "D0K8SH",
    names: ["GX-137", "DNA Vaccine (HCV), Genexine"],
  },
  {
    primary_id: "D0K8SK",
    names: [
      "2-(4-Methoxyphenyl)-4,5-Dihydro-1H-Imidazole",
      "6302-84-7",
      "4,5-Dihydro-2-(4-Methoxyphenyl)-1H-Imidazole",
      "2-Imidazoline, 2-(P-Methoxyphenyl)-",
      "1H-Imidazole, 4,5-Dihydro-2-(4-Methoxyphenyl)-",
      "CHEMBL13633",
      "NSC41538",
      "AC1L5ZGN",
      "2-(P-Anisyl)-1-Imidazoline",
      "SCHEMBL9575256",
      "AC1Q580G",
      "CTK5B7166",
      "DTXSID40285359",
      "LPRQSQCAHSRGRZ-UHFFFAOYSA-N",
      "ZINC1672770",
      "BDBM50240364",
      "NSC-41538",
      "AKOS022491172",
      "MCULE-4518622050",
      "1-(2-Imidazolin-2-Yl)-4-Methoxybenzene",
      "2-(P-METHOXY-PHENYL)-IMIDAZOLINE",
      "DB-073247",
      "KB-222983",
      "ST50998435",
      "FT-0712566",
    ],
  },
  {
    primary_id: "D0K8TI",
    names: ["Ranirestat", "AS-3201"],
  },
  {
    primary_id: "D0K8TO",
    names: ["AP1903"],
  },
  {
    primary_id: "D0K8UN",
    names: ["SCIB-2"],
  },
  {
    primary_id: "D0K8VA",
    names: ["Gemini", "1R,25-Dihydroxy-21-(3-Hydroxy-3-Methylbutyl)Vitamin D3", "KH"],
  },
  {
    primary_id: "D0K8VL",
    names: ["N-(Furan-2-Ylmethyl)-Estrone-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0K8VR",
    names: [
      "[3H]HU-243",
      "140835-18-3",
      "(3H)HU 243",
      "(6aR,8S,9S,10aR)-9-(Hydroxymethyl)-6,6-Dimethyl-3-(2-Methyloctan-2-Yl)-8,9-Ditritio-7,8,10,10a-Tetrahydro-6aH-Benzo[c]chromen-1-Ol",
      "AC1MIXOL",
      "3-Dimethylheptyl-11-Hydroxyhexahydrocannabinol",
      "BCP9000763",
      "6H-Dibenzo(B,d)Pyran-8-T-9-Methanol, 3-(1,1-Dimethylheptyl)-6a,7,8,9,10,10a-Hexahydro-9-T-1-Hydroxy-6,6-Diemthyl-, (6aR-(6aalpha,8beta,9alpha,10abeta))-",
    ],
  },
  {
    primary_id: "D0K8WE",
    names: ["Anti-CD33 CAR-T Cells"],
  },
  {
    primary_id: "D0K8WJ",
    names: ["Sf-1019"],
  },
  {
    primary_id: "D0K8XC",
    names: [
      "Lactose",
      "D(+)Cellobiose",
      "Glcbeta1,4Glc",
      "Lactose, Beta-",
      "D-(+)-Cellobiose",
      "D-Glucose, 4-O-Beta-D-Glucopyranosyl-",
      "NSC2559",
      "Beta-D-Glucopyranose, 4-O-Beta-D-Galactopyranosyl-",
      "4-O-Beta-D-Glucopyranosyl-D-Glucose",
      "528-50-7",
      "Beta-Lactose",
      "Cellobiose, Beta-",
      "Lactopress",
      "4-O-Hexopyranosylhexopyranose",
      "Ss-Lactose",
      "Lactose NF",
      "Beta-Cellobiose",
      "Beta-D-Lactose",
      "D-Glucose, 4-O-Beta-D-Galactopyranosyl-",
      "Beta-Maltose Monohydrate",
      "SCHEMBL684118",
      "AC1Q28M2",
      "AC1L18X1",
      "SCHEMBL20276017",
    ],
  },
  {
    primary_id: "D0K8XK",
    names: ["ISIS 103621"],
  },
  {
    primary_id: "D0K8XT",
    names: ["Thiazole Carboxamide Derivative 10"],
  },
  {
    primary_id: "D0K8YD",
    names: ["PMID24900635C21"],
  },
  {
    primary_id: "D0K8YG",
    names: ["ISA-HPV-01"],
  },
  {
    primary_id: "D0K8YI",
    names: ["7-Chloroindirubin-3-Oxime"],
  },
  {
    primary_id: "D0K8YK",
    names: [
      "DVD-Ig",
      "DVD-Ig (Arthritis)",
      "DVD-Ig (Arthritis), Abbott",
      "Dual Anti-CD20/CD3 Immunoglobulin, Abbott",
      "Dual Anti-TNF/PGE2 Innunoglobulin, Abbott",
      "Dual Anti-IL-1 Alpha/Beta Immunoglobulin (Arthritis), Abbott",
      "Dual Anti-IL-12/IL-18 Immunoglobulin, Abbott",
      "1D4.1-2.5",
      "1D4.1-ABT325",
    ],
  },
  {
    primary_id: "D0K8YX",
    names: ["4SCAR19 And 4SCAR30"],
  },
  {
    primary_id: "D0K8ZD",
    names: ["FVPTDVG-Tic-FAF-Tic"],
  },
  {
    primary_id: "D0K8ZW",
    names: [
      "1-Cyclopentyl-3,7-Dihydro-Purine-2,6-Dione",
      "CHEMBL302344",
      "Cyclopentylxanthine",
      "SCHEMBL2520260",
      "BDBM50042198",
    ],
  },
  {
    primary_id: "D0K8ZZ",
    names: [
      "[3H]NECA",
      "5'-N-Ethylcarboxamidoadenosine",
      "NECA",
      "35920-39-9",
      "Adenosine-5'-(N-Ethylcarboxamide)",
      "N-Ethyl-5'-Carboxamidoadenosine",
      "1-(6-Amino-9H-Purin-9-Yl)-1-Deoxy-N-Ethyl-Beta-D-Ribofuranuronamide",
      "N-ETHYL-5'-CARBOXAMIDO ADENOSINE",
      "MLS000069759",
      "CHEMBL464859",
      "CHEBI:73284",
      "5'-N-ETHYLCARBOXAMIDO-ADENOSINE",
      "SMR000058759",
      "N-Ethylcarboxamidoadenosine",
      "5'-N(Sup 6)-Ethylcarboxamidoadenosine",
      "(2S,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-N-Ethyl-3,4-Dihydroxyoxolane-2-Carboxamide",
      "[3H]adenosine-5'-(N-Ethylcarboxamide)",
    ],
  },
  {
    primary_id: "D0K9BF",
    names: [
      "AcAsp-Glu-Dif-Glu-Cha-Cys",
      "CHEMBL417559",
      "Ac-Asp-Glu-Dif-Glu-Cha-Cys-OH",
      "(4S)-4-[[(2S)-2-Acetamido-4-Hydroxy-4-Oxo-Butanoyl]amino]-5-[[(1S)-1-Benzhydryl-2-[[(1S)-1-[[(1S)-1-(Cyclohexylmethyl)-2-[[(1R)-2-Hydroxy-2-Oxo-1-(Sulfanylmethyl)Ethyl]amino]-2-Oxo-Ethyl]carbamoyl]-4-Hydroxy-4-Oxo-Butyl]amino]-2-Oxo-Ethyl]amino]-5-Oxo-Pentanoic Acid",
      "AC1LA689",
      "BDBM50084685",
      "Ac-Asp-Glu-3,3-Diphenylalanine-GLu-Beta-Cyclohexylalanine-Cys-OH",
      "Ac-L-Asp-L-Glu-3,3-Diphenyl-L-Ala-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
    ],
  },
  {
    primary_id: "D0K9BQ",
    names: ["ISIS 134603"],
  },
  {
    primary_id: "D0K9BV",
    names: ["TVB-2640"],
  },
  {
    primary_id: "D0K9CL",
    names: ["2,4-Diaminoquinozalines And Quinoxaline-2,3-Diones"],
  },
  {
    primary_id: "D0K9CZ",
    names: ["VPM-1-002"],
  },
  {
    primary_id: "D0K9DI",
    names: ["GSK-356278"],
  },
  {
    primary_id: "D0K9ES",
    names: ["Suprofenac"],
  },
  {
    primary_id: "D0K9FA",
    names: ["Dolutegravir + Lamivudine"],
  },
  {
    primary_id: "D0K9GC",
    names: [
      "2-[3-(Pyridin-4-Yloxy)-Benzoylamino]-Benzoic Acid",
      "2-[3-(Pyridin-4-Yloxy)Benzoylamino]benzoic Acid",
      "CHEMBL438975",
      "BDBM23613",
      "2-Benzoylamino Benzoic Acid Deriv., 28g",
      "2-{[3-(Pyridin-4-Yloxy)Benzene]amido}benzoic Acid",
    ],
  },
  {
    primary_id: "D0K9GG",
    names: ["L-703,606"],
  },
  {
    primary_id: "D0K9HE",
    names: ["Nor-Binaltorphimine Dihydrochloride"],
  },
  {
    primary_id: "D0K9HF",
    names: ["BLX-301"],
  },
  {
    primary_id: "D0K9HO",
    names: ["N-Arachidonylmaleimide"],
  },
  {
    primary_id: "D0K9IB",
    names: ["Vonapanitase"],
  },
  {
    primary_id: "D0K9IF",
    names: ["PF-06305591"],
  },
  {
    primary_id: "D0K9JO",
    names: ["AskBio009"],
  },
  {
    primary_id: "D0K9JP",
    names: [
      "A-80426",
      "CHEMBL268258",
      "SCHEMBL647243",
      "ZINC3810797",
      "BDBM50057128",
      "AKOS030558130",
      "NCGC00378605-01",
    ],
  },
  {
    primary_id: "D0K9KJ",
    names: [
      "BU-E 47",
      "CHEMBL304239",
      "GTPL1219",
      "SCHEMBL9710041",
      "BU-E-47",
      "BDBM50406050",
      "PDSP1_001313",
      "PDSP2_001297",
    ],
  },
  {
    primary_id: "D0K9KN",
    names: ["JNJ-26113100", "Dermatitis Agent (Oral), Alza"],
  },
  {
    primary_id: "D0K9KQ",
    names: ["Cyclo(-D-Ala-D-Arg-L-Arg-L-Nal-Gly-)", "CHEMBL373440"],
  },
  {
    primary_id: "D0K9KS",
    names: ["Draculin"],
  },
  {
    primary_id: "D0K9KZ",
    names: ["DIOA", "Dihydroindenyl-Oxyalkanoic Acid"],
  },
  {
    primary_id: "D0K9LT",
    names: ["5-Hexyl-2-(Pyrazin-2-Yloxy)Phenol", "CHEMBL258932"],
  },
  {
    primary_id: "D0K9LV",
    names: ["5-Hexyl-2-(Pyrimidin-2-Yloxy)Phenol", "CHEMBL258931"],
  },
  {
    primary_id: "D0K9MB",
    names: ["CX-1030"],
  },
  {
    primary_id: "D0K9MC",
    names: ["CetuGEX"],
  },
  {
    primary_id: "D0K9MH",
    names: ["Acrylamide Compound 1"],
  },
  {
    primary_id: "D0K9MN",
    names: ["ZP-EPO"],
  },
  {
    primary_id: "D0K9MT",
    names: [
      "5-Isopropyl-3-(2-Pyrrolidin-1-Yl-Ethyl)-1H-Indole",
      "CHEMBL297660",
      "SCHEMBL8507362",
      "BDBM50090518",
      "3-(2-Pyrrolizinoethyl)-5-Isopropyl-1H-Indole",
    ],
  },
  {
    primary_id: "D0K9MY",
    names: ["Saxagliptin"],
  },
  {
    primary_id: "D0K9NJ",
    names: ["F18-ML-10"],
  },
  {
    primary_id: "D0K9NK",
    names: ["KKHT-20818"],
  },
  {
    primary_id: "D0K9NW",
    names: [
      "4-(3,4-Dihydro-1H-Isoquinolin-2-Yl)-Quinoline",
      "CHEMBL50166",
      "SCHEMBL6159581",
      "ZINC26870",
      "BDBM50127827",
    ],
  },
  {
    primary_id: "D0K9OK",
    names: ["PSEUDOCOCAINE"],
  },
  {
    primary_id: "D0K9OL",
    names: ["SCH 50911"],
  },
  {
    primary_id: "D0K9ON",
    names: [
      "3-(2-Cyclohexyl-Ethyl)-6,7-Dimethoxy-Quinoline",
      "CHEMBL66786",
      "SCHEMBL8166505",
      "HLKSYKUFDFBKPC-UHFFFAOYSA-N",
      "ZINC3834045",
      "BDBM50039049",
    ],
  },
  {
    primary_id: "D0K9OU",
    names: [
      "MBX400",
      "Cyclopropavir",
      "Synguanol",
      "Cyclopropavir Analogs",
      "Synadenol Enantiomers",
      "Antiviral Agents, Microbiotix",
      "MBX-400",
      "MBX-468",
      "MBX-769S",
      "Methylenecyclopropane Nucleoside Analogs (Viral Infection)",
      "QYL-1064",
      "QYL-284A",
      "QYL-418",
      "QYL-438",
      "QYL-546",
      "QYL-587A",
      "QYL-609",
      "QYL-678",
      "QYL-685",
      "QYL-769",
      "QYL-788",
      "QYL-941",
      "SRI-21950",
      "SRI-22376",
      "SRI-225716",
      "SRI-7419",
      "Z-A",
      "Z-G",
      "ZSM-377a",
      "ZSM-519",
      "ZSM-527",
      "Antiviral Agents, Wayne State University/University Of Michigan",
      "Methylenecyclopropane Nucleoside Analogs (Viral Infection), Microbiotix",
      "XCC-I-82",
      "ZSM-I-62",
      "ZSM-I-64",
      "Nucleoside Analogs (Antiviral), Alabama/Wayne State",
    ],
  },
  {
    primary_id: "D0K9PC",
    names: ["AG-881"],
  },
  {
    primary_id: "D0K9PG",
    names: ["NXD-30001"],
  },
  {
    primary_id: "D0K9PS",
    names: ["CHF-5480"],
  },
  {
    primary_id: "D0K9QH",
    names: ["Benzothiazine-Carboxamide Compound 3"],
  },
  {
    primary_id: "D0K9RR",
    names: ["LF-943"],
  },
  {
    primary_id: "D0K9SS",
    names: ["GP2015"],
  },
  {
    primary_id: "D0K9TC",
    names: ["Sameridine"],
  },
  {
    primary_id: "D0K9TS",
    names: ["Pyz11-D-Trp8-SRIF"],
  },
  {
    primary_id: "D0K9TT",
    names: ["Ac-Cys-Ile-Cyclo[Phe-Lys]-Tyr-Tyr"],
  },
  {
    primary_id: "D0K9UK",
    names: ["E-6700"],
  },
  {
    primary_id: "D0K9UN",
    names: ["N-Phenyl-1H-Indole-2-Carboxamide"],
  },
  {
    primary_id: "D0K9UT",
    names: ["Epi-13"],
  },
  {
    primary_id: "D0K9VE",
    names: ["3-Pyridin-4-Yl-Quinoline-5,7-Diol"],
  },
  {
    primary_id: "D0K9VK",
    names: ["Vaccine, Shigella Flexneri"],
  },
  {
    primary_id: "D0K9WJ",
    names: ["2-(4-Cyclopropyl-Piperazin-1-Yl)-Quinoline", "CHEMBL179578", "SCHEMBL13648831", "BDBM50159109"],
  },
  {
    primary_id: "D0K9WL",
    names: ["Glucagon Injection"],
  },
  {
    primary_id: "D0K9WP",
    names: ["131I-TM-601"],
  },
  {
    primary_id: "D0K9XI",
    names: ["DHF-18"],
  },
  {
    primary_id: "D0K9XV",
    names: ["N1-(4-Fluorobenzyl)-2-Benzyl-N3-Hydroxymalonamide", "CHEMBL219618", "BDBM50206556"],
  },
  {
    primary_id: "D0K9YJ",
    names: ["JNJ-3534"],
  },
  {
    primary_id: "D0K9YR",
    names: [
      "SCH-54470",
      "CHEMBL43370",
      "BDBM50287430",
      "(S)-2-[(1-{[(R)-1-((S)-6-Amino-2-Methanesulfonylamino-Hexanoylamino)-2-Phenyl-Ethyl]-Hydroxy-Phosphinoylmethyl}-Cyclopentanecarbonyl)-Amino]-3-(1H-Indol-3-Yl)-Propionic Acid",
    ],
  },
  {
    primary_id: "D0K9YS",
    names: ["EM1396"],
  },
  {
    primary_id: "D0K9ZU",
    names: [
      "CD45RB",
      "Abeta Amyloid Agonist (Peptide, Glioma)",
      "Abeta Amyloid Agonist (Peptide, Glioma), Lee Moffitt Cancer Center",
    ],
  },
  {
    primary_id: "D0KA1G",
    names: [
      "2'-Deoxyadenosine",
      "958-09-8",
      "(2R,3S,5R)-5-(6-Amino-9H-Purin-9-Yl)-2-(Hydroxymethyl)Tetrahydrofuran-3-Ol",
      "2'-Deoxyformycin A",
      "CCRIS 1782",
      "UNII-P582C98ULC",
      "2'-DA",
      "CHEBI:17256",
      "2'-Deoxy-D-Adenosine",
      "AI3-52383",
      "(2R,3S,5R)-5-(6-Amino-9H-Purin-9-Yl)-2-(Hydroxymethyl)Oxolan-3-Ol",
      "EINECS 213-488-7",
      "NSC 83258",
      "Adenine Deoxyriboside",
      "BRN 0091015",
    ],
  },
  {
    primary_id: "D0KA3L",
    names: ["Epoxysuccinate Derivative 6"],
  },
  {
    primary_id: "D0KA3X",
    names: ["Diaryl Amine Derivative 3"],
  },
  {
    primary_id: "D0KA4R",
    names: ["NNZ-2591", "Diketopiperazines (Neurodegenerative Disorders), Neuren"],
  },
  {
    primary_id: "D0KA7I",
    names: ["PMID30107136-Compound-Example7"],
  },
  {
    primary_id: "D0KA9L",
    names: ["AME-133v"],
  },
  {
    primary_id: "D0KA9Y",
    names: ["[Ncy(Methyl)10]acyline", "CHEMBL427438"],
  },
  {
    primary_id: "D0KB0Y",
    names: ["Pegargiminase"],
  },
  {
    primary_id: "D0KB2E",
    names: ["FR167356"],
  },
  {
    primary_id: "D0KB2R",
    names: ["N-(1-Methyl-1H-Indol-2-Ylmethyl)-N-Phenylamine", "CHEMBL449889"],
  },
  {
    primary_id: "D0KB2Z",
    names: ["PMID25666693-Compound-1"],
  },
  {
    primary_id: "D0KB4T",
    names: ["PMID29649907-Compound-44"],
  },
  {
    primary_id: "D0KB6Q",
    names: ["N-Hydroxy-2-Naphthalen-2-Yl-Acetamide"],
  },
  {
    primary_id: "D0KB6S",
    names: ["MVA-MBN85B"],
  },
  {
    primary_id: "D0KB6T",
    names: ["N-(4-Sulfamoyl-Phenyl)-Benzamide"],
  },
  {
    primary_id: "D0KB8D",
    names: ["2-(2-Sec-Butoxypyrimidin-4-Ylamino)Benzoic Acid", "CHEMBL242702"],
  },
  {
    primary_id: "D0KC2S",
    names: ["CAR-T Cells Targeting Mesothelin"],
  },
  {
    primary_id: "D0KC2Z",
    names: ["Uridine-Diphosphate-N-Acetylglucosamine"],
  },
  {
    primary_id: "D0KC5X",
    names: ["S-18616"],
  },
  {
    primary_id: "D0KC6Y",
    names: ["PMID25726713-Compound-21"],
  },
  {
    primary_id: "D0KC7H",
    names: ["NCX-1236"],
  },
  {
    primary_id: "D0KC7K",
    names: ["EC-154"],
  },
  {
    primary_id: "D0KC8X",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 5"],
  },
  {
    primary_id: "D0KC9E",
    names: [
      "3-(6-Amino-Purin-9-Yl)-6-O-Tolyl-Hexan-2-Ol",
      "CHEMBL42063",
      "SCHEMBL7458391",
      "BDBM50070641",
      "(2S,3R)-3-(6-Amino-Purin-9-Yl)-6-O-Tolyl-Hexan-2-Ol",
    ],
  },
  {
    primary_id: "D0KC9U",
    names: ["Stage IV Melanoma Vaccine"],
  },
  {
    primary_id: "D0KC9V",
    names: [
      "AD10-1025",
      "AD10-369",
      "AD10-371",
      "AD10-797",
      "AD10-798",
      "Partial PPAR Gamma Agonist (Oral, Type 2 Diabetes)",
      "Partial PPAR Gamma Agonist (Oral, Type 2 Diabetes), Adamed SP",
      "Phenyl Propionic Acid Derivative 3 (Oral, Type 2 Diabetes), Adamed SP",
      "PPAR Modulators (Oral, Hypercholesterolemia/NIDDM), Adamed",
    ],
  },
  {
    primary_id: "D0KD1A",
    names: ["HF-0699"],
  },
  {
    primary_id: "D0KD1U",
    names: [
      "Practolol",
      "Dalzic",
      "Eraldin",
      "Practololo",
      "Practololum",
      "Praktololu",
      "Teranol",
      "Eralzdin Practolol",
      "Practololo [DCIT]",
      "Praktololu [Polish]",
      "AY 21011",
      "Cardiol (TN)",
      "Cordialina (TN)",
      "Dalzic (TN)",
      "Eraldin (TN)",
      "Eraldina (TN)",
      "Practololum [INN-Latin]",
      "Praktol (TN)",
      "Pralon (TN)",
      "Teranol (TN)",
      "Practolol [USAN:BAN:INN]",
      "N-{4-[2-Hydroxy-3-(Isopropylamino)Propoxy]phenyl}acetamide",
      "N-[4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl]acetamide",
      "N-[4-({2-Hydroxy-3-[(1-Methylethyl)Amino]propyl}oxy)Phenyl]acetamide",
      "N-{4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]phenyl}acetamide",
      "N-(4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)Phenyl)Acetamide",
      "(+-)-Practolol",
      "1-(4-Acetamidophenoxy)-3-Isopropylamino-2-Propanol",
      "4'-(2-Hydroxy-3-(Isopropylamino)Propoxy)Acetanilide",
    ],
  },
  {
    primary_id: "D0KD4U",
    names: ["FK-355"],
  },
  {
    primary_id: "D0KD4X",
    names: ["Rapacuronium Bromide"],
  },
  {
    primary_id: "D0KD5P",
    names: ["Nefiracetam"],
  },
  {
    primary_id: "D0KD5X",
    names: ["Pyrazole Derivative 31"],
  },
  {
    primary_id: "D0KD6O",
    names: [
      "(S)-4-AHCP",
      "CHEMBL28472",
      "3-(3-HYDROXY-7,8-DIHYDRO-6H-CYCLOHEPTA[D]ISOXAZOL-4-YL)-L-ALANINE",
      "2-AMINO-3-(3-HYDROXY-7,8-DIHYDRO-6H-CYCLOHEPTA[D]-4-ISOXAZOLYL)PROPIONIC ACID",
      "(2S)-2-Amino-3-(3-Oxo-7,8-Dihydro-6H-Cyclohepta[d][1,2]oxazol-4-Yl)Propanoic Acid",
      "1wvj",
      "AC1NRBRE",
      "GTPL4069",
      "BDBM50126764",
      "DB04152",
      "(S)-2-Amino-3-[(3-Hydroxy-7,8-Dihydro-6H-Cyclohept[d]isoxazol)-4-Yl]propionic Acid",
      "(2S)-2-Amino-3-{3-Oxo-2H,6H,7H,8H-Cyclohepta[d][1,2]oxazol-4-Yl}propanoic Acid",
    ],
  },
  {
    primary_id: "D0KD6S",
    names: ["SCH-57726"],
  },
  {
    primary_id: "D0KD6U",
    names: ["Benzoic Acid Derivative 1"],
  },
  {
    primary_id: "D0KD7E",
    names: ["XEL-003FP", "Antifungal (Controlled Release/Solution Formulation, Onychomycosis), Xel Pharmaceuticals"],
  },
  {
    primary_id: "D0KD8D",
    names: ["[2-(3-Benzyl-3H-Indol-1-Yl)-Ethyl]-Dimethyl-Amine"],
  },
  {
    primary_id: "D0KD8P",
    names: ["Juvenile Rheumatoid Arthritis Therapeutic"],
  },
  {
    primary_id: "D0KD9M",
    names: ["(S)-6-(Pyrrolidin-3-Ylmethoxy)-2-Naphthonitrile", "CHEMBL469551"],
  },
  {
    primary_id: "D0KE0C",
    names: ["STP-805"],
  },
  {
    primary_id: "D0KE1F",
    names: ["DFP-10917"],
  },
  {
    primary_id: "D0KE1V",
    names: ["SDZ-249482"],
  },
  {
    primary_id: "D0KE3I",
    names: ["(E)-8-Biphenyl-4-Yl-1-Oxazol-2-Yl-Oct-7-En-1-One", "CHEMBL126465", "SCHEMBL7368197", "SCHEMBL7368201"],
  },
  {
    primary_id: "D0KE3S",
    names: [
      "Butirosin B",
      "Ambuyrosin A",
      "AC1Q5OCU",
      "AC1L1DSB",
      "SCHEMBL19944113",
      "C21H41N5O12",
      "D-Streptamine, O-2,6-Diamino-2,6-Dideoxy-Alpha-D-Glucopyranosyl-(1-4)-O-(Beta-D-Xylofuranosyl-(1-5))-N(Sup 1)-(4-Amino-2-Hydroxy-1-Oxobutyl)-2-Deoxy-",
      "Butirosin A From Bacillus Vitellinus",
      "LS-16557",
      "4-Amino-N-{5-Amino-4-[(2,6-Diamino-2,6-Dideoxyhexopyranosyl)Oxy]-2-Hydroxy-3-(Pentofuranosyloxy)Cyclohexyl}-2-Hydroxybutanamide",
      "LS-147025",
    ],
  },
  {
    primary_id: "D0KE4C",
    names: [
      "HG-1071",
      "BAPX2 Modulator, Human Genome Sciences",
      "NKX3A Modulator, Human Genome Sciences",
      "Mouse Homolog Of NKX3.1 Modulator, Human Genome Sciences",
      "NK3 Transcription Factor Related, Locus 1 Modulator, Human Genome Sciences",
      "NKX3-1 Modulator, Human Genome Sciences",
      "NKX3.1 Modulator, Human Genome Sciences",
      "Homeobox Protein Nkx-3.1 Modulator, Human Genome Sciences",
      "NK Homeobox, Family 3, Member A Modulator, Human Genome Sciences",
      "NK Homeobox, Family 3, Subunit A Modulator, Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0KE4E",
    names: ["PMID27376512-Compound-AsCEBP-2"],
  },
  {
    primary_id: "D0KE4Q",
    names: ["DFD-29"],
  },
  {
    primary_id: "D0KE4Z",
    names: ["ARL-67085", "AR-C 67085XX", "AR-C67085MX", "AR-C67775XX", "FPL-67085", "FPL-67085MX", "SPL-67085"],
  },
  {
    primary_id: "D0KE7K",
    names: ["URG-302"],
  },
  {
    primary_id: "D0KE8Q",
    names: ["PMID26651364-Compound-46"],
  },
  {
    primary_id: "D0KE8R",
    names: ["(-)-Uniflorine A", "CHEMBL1076191", "Uniflorine A"],
  },
  {
    primary_id: "D0KE8U",
    names: ["Carbamate Derivative 3"],
  },
  {
    primary_id: "D0KE9G",
    names: ["HESC-Derived Oligodendrocyte Progenitors (AST-OPC1)"],
  },
  {
    primary_id: "D0KF0N",
    names: [
      "1-Benzenesulfonyl-2-Nitro-Benzene",
      "31515-43-2",
      "2-Nitrophenyl Phenyl Sulfone",
      "1-Nitro-2-(Phenylsulfonyl)Benzene",
      "2-Nitrodiphenyl Sulfone",
      "2-Nitro Diphenyl Sulfone",
      "Benzene, 1-Nitro-2-(Phenylsulfonyl)-",
      "2'-Nitrophenylphenylsulfone",
      "1-(Benzenesulfonyl)-2-Nitrobenzene",
      "1-Nitro-2-(Phenylsulphonyl)Benzene",
      "2NO2Ph-SO2-Ph",
      "NSC-624231",
      "NSC624231",
      "NSC 624231",
      "144113-81-5",
      "W-106894",
      "SMR000554655",
      "O-Nitrophenyl Phenyl Sulfone",
      "Sulfone, O-Nitrophenyl Phenyl",
      "EINECS 250-672-6",
      "2-Nitrodiphenylsulfone",
      "PubChem10856",
      "2-Nitrophenylphenylsulfone",
      "ACMC-209hn5",
      "AC1L22CT",
      "MLS001074875",
    ],
  },
  {
    primary_id: "D0KF0X",
    names: ["PMID25522065-Compound-10"],
  },
  {
    primary_id: "D0KF2P",
    names: ["PMID22984835C4"],
  },
  {
    primary_id: "D0KF3A",
    names: ["PMID25666693-Compound-98"],
  },
  {
    primary_id: "D0KF3D",
    names: ["ISIS 16108"],
  },
  {
    primary_id: "D0KF3N",
    names: ["3-Cyclopent-1-Enyl-6,7-Dimethoxy-Quinoline", "CHEMBL66618", "ZINC8079", "SCHEMBL4364039", "BDBM50039090"],
  },
  {
    primary_id: "D0KF3O",
    names: ["CardioPET"],
  },
  {
    primary_id: "D0KF6X",
    names: ["MLN3897"],
  },
  {
    primary_id: "D0KF8C",
    names: ["Aminocyclopentenone Compound 3"],
  },
  {
    primary_id: "D0KF8H",
    names: ["PMID27774824-Compound-Figure8Example22"],
  },
  {
    primary_id: "D0KF9Q",
    names: ["Rigosertib"],
  },
  {
    primary_id: "D0KF9T",
    names: ["Anti-CD19 CAR-T"],
  },
  {
    primary_id: "D0KG0Z",
    names: [
      "2-(3-Fluoro-Phenyl)-Benzo[h]chromen-4-One",
      "CHEMBL436080",
      "652138-30-2",
      "CTK1J8219",
      "DTXSID70467020",
      "BDBM50159673",
      "4H-Naphtho[1,2-B]pyran-4-One, 2-(3-Fluorophenyl)-",
    ],
  },
  {
    primary_id: "D0KG1D",
    names: ["2-(3-Hydroxyphenyl)Quinolin-6-Ol", "CHEMBL260459", "SCHEMBL3007819"],
  },
  {
    primary_id: "D0KG1T",
    names: ["Pyrimido-Indole Derivative 2"],
  },
  {
    primary_id: "D0KG1U",
    names: ["BXCL702"],
  },
  {
    primary_id: "D0KG3M",
    names: ["1-(4-Chlorophenylsulfonyl)-4-Phenylazepan-4-Ol", "CHEMBL562534", "SCHEMBL12550078"],
  },
  {
    primary_id: "D0KG3R",
    names: ["Rivaroxaban"],
  },
  {
    primary_id: "D0KG4B",
    names: ["Cefbuperazone Sodium"],
  },
  {
    primary_id: "D0KG4Q",
    names: ["Cemiplimab"],
  },
  {
    primary_id: "D0KG5D",
    names: ["Mapatumumab"],
  },
  {
    primary_id: "D0KG5I",
    names: ["N-Alkylamide Derivative 1"],
  },
  {
    primary_id: "D0KG5U",
    names: ["PMID26651364-Compound-9b"],
  },
  {
    primary_id: "D0KG9M",
    names: ["PMID27998201-Compound-22"],
  },
  {
    primary_id: "D0KH1D",
    names: [
      "3-[4-(4-Fluoro-Phenoxy)-Phenyl]-1H-Pyrazole",
      "CHEMBL38031",
      "3-[4-(4-Fluorophenoxy)Phenyl]-1H-Pyrazole",
      "3-(4-(4-Fluorophenoxy)Phenyl)-1H-Pyrazole",
      "SCHEMBL1385301",
      "CTK7C0984",
      "CGMPMLHCCFCLQL-UHFFFAOYSA-N",
      "BDBM50141066",
      "ZINC13559818",
    ],
  },
  {
    primary_id: "D0KH3M",
    names: [
      "BIFENOX",
      "42576-02-3",
      "Methyl 5-(2,4-Dichlorophenoxy)-2-Nitrobenzoate",
      "Modown",
      "Caswell No. 561AA",
      "MC-4379",
      "5-(2,4-Dichlorophenoxy)-2-Nitrobenzoic Acid Methyl Ester",
      "UNII-KSB85XT26Y",
      "Bifenox [ANSI:BSI:ISO]",
      "CCRIS 7158",
      "Benzoic Acid, 5-(2,4-Dichlorophenoxy)-2-Nitro-, Methyl Ester",
      "HSDB 6567",
      "EINECS 255-894-7",
      "EPA Pesticide Chemical Code 104301",
      "BRN 2170169",
      "KSB85XT26Y",
      "CHEMBL449928",
      "CHEBI:6613",
      "SUSRORUBZHMPCO-UHFFFAOYSA-N",
      "W-110976",
      "Modown 4 Flowable",
      "MODOWN(R)",
      "AC1L21BG",
      "DSSTox_RID_79477",
      "DSSTox_CID_20320",
      "DSSTox_GSID_40320",
    ],
  },
  {
    primary_id: "D0KH5D",
    names: ["Indoline Derivative 2"],
  },
  {
    primary_id: "D0KH5R",
    names: ["SB 215505"],
  },
  {
    primary_id: "D0KH5T",
    names: [
      "4-[3-Hydroxyanilino]-6,7-Dimethoxyquinazoline",
      "Whi-P180",
      "211555-08-7",
      "3-[(6,7-Dimethoxyquinazolin-4-Yl)Amino]phenol",
      "WHI-P180, Hydrochloride",
      "JANEX 3",
      "4-[3-HYDROXYANILINO]-6,7-DIMETHOXYQUINAZOLINE",
      "CHEMBL127907",
      "3-((6,7-Dimethoxyquinazolin-4-Yl)Amino)Phenol",
      "AK341047",
      "DTQ",
      "4-(3&amp",
      "-Hydroxyphenyl)Amino-6,7-Dimethoxyquinazoline, HCl",
      "JANEX-3",
      "4btk",
      "1di8",
      "AC1L1KWT",
      "AC1Q4XVE",
      "3-(6,7-Dimethoxyquinazolin-4-Ylamino)Phenol",
      "Anilinoquinazoline Deriv 5",
      "WHI-P180(Janex 3)",
      "BDBM4622",
      "SCHEMBL1180103",
      "CTK4E5981",
      "AOB5495",
      "MolPort-000-672-017",
      "BNDYIYYKEIXHNK-UHFFFAOYSA-N",
      "HMS3229P15",
      "WHI-P180",
    ],
  },
  {
    primary_id: "D0KH7Q",
    names: ["PMID25726713-Compound-51"],
  },
  {
    primary_id: "D0KH8S",
    names: ["3-(6-Morpholino-4-Oxo-4H-Pyran-2-Yl)Benzoic Acid", "CHEMBL222180", "SCHEMBL13435565"],
  },
  {
    primary_id: "D0KH9W",
    names: ["15S-HPETE", "15(S)-HPETE", "15-(S)-Hydroperoxyeicosatetraenoic Acid"],
  },
  {
    primary_id: "D0KH9Z",
    names: ["Pro-His-Pro-Phe-His-Leu(CH2NH)Val-Ile-His-Lys"],
  },
  {
    primary_id: "D0KI1H",
    names: ["PMID27109571-Compound-33"],
  },
  {
    primary_id: "D0KI3F",
    names: ["1-(4-Bromooxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL448688", "SCHEMBL2162450"],
  },
  {
    primary_id: "D0KI3U",
    names: ["NXB-5886", "Antibacterial (Streptococcus), Nymox"],
  },
  {
    primary_id: "D0KI5F",
    names: ["Geminally-Substituted Cyanoethylpypazolo Pyridone Derivative 2"],
  },
  {
    primary_id: "D0KI5P",
    names: ["ASN02563583", "ASN 02563583", "ASN-02563583"],
  },
  {
    primary_id: "D0KI5R",
    names: [
      "Dabigatran Etexilate",
      "BIBR-1048",
      "211915-06-9",
      "Dabigatran Etexilate Mesilate",
      "Ethyl N-[(2-{[(4-{N'-[(Hexyloxy)Carbonyl]carbamimidoyl}phenyl)Amino]methyl}-1-Methyl-1H-Benzimidazol-5-Yl)Carbonyl]-N-Pyridin-2-Yl-Beta-Alaninate",
      "Dabigatran Etexilate Methanesulfonate",
      "BIBR-1048 (Dabigatran)",
      "UNII-2E18WX195X",
      "BIBR1048",
      "BIBR-1048-BS-RS1",
      "CHEBI:70746",
      "2E18WX195X",
      "Cc-72",
      "DSSTox_CID_31470",
      "DSSTox_RID_97355",
      "DSSTox_GSID_57681",
      "SCHEMBL505829",
      "DTXSID4057681",
      "Dabigatran Etexilate (USAN/INN)",
      "MolPort-016-633-287",
      "EBD35035",
      "Tox21_113924",
      "BDBM504",
    ],
  },
  {
    primary_id: "D0KI6J",
    names: ["SiRNA Therapeutics, Inflammation"],
  },
  {
    primary_id: "D0KI9N",
    names: ["Antimicrobial LL-37 Cathelicidin", "Antimicrobial LL-37 Cathelicidin (Peptide, Infection)"],
  },
  {
    primary_id: "D0KJ0U",
    names: ["AZD-7928"],
  },
  {
    primary_id: "D0KJ2F",
    names: ["Solid Tumor Immunotherapy"],
  },
  {
    primary_id: "D0KJ2J",
    names: ["BAY 1082439"],
  },
  {
    primary_id: "D0KJ3H",
    names: [
      "2-(2''-Indolylethyloxy)Adenosine",
      "CHEMBL374461",
      "SCHEMBL4234414",
      "2-(2'-Indolylethyloxy)Adenosine",
      "BDBM50208812",
    ],
  },
  {
    primary_id: "D0KJ4B",
    names: ["Lenvimalenvatinib"],
  },
  {
    primary_id: "D0KJ4Q",
    names: ["P-[18F]MPPF"],
  },
  {
    primary_id: "D0KJ4S",
    names: ["ADS-4101"],
  },
  {
    primary_id: "D0KJ4U",
    names: ["ACEA-1011"],
  },
  {
    primary_id: "D0KJ7C",
    names: ["PMID7629799C2e"],
  },
  {
    primary_id: "D0KJ9U",
    names: ["PMID25522065-Compound-28"],
  },
  {
    primary_id: "D0KK0E",
    names: ["PMID26666989-Compound-Figure9topright01"],
  },
  {
    primary_id: "D0KK1I",
    names: ["MVA E2 Vaccine"],
  },
  {
    primary_id: "D0KK2E",
    names: ["Eribulin"],
  },
  {
    primary_id: "D0KK4C",
    names: [
      "2-[2-(1H-Imidazol-4-Yl)-Cyclopropyl]-Ethylamine",
      "CHEMBL60716",
      "SCHEMBL1443461",
      "BDBM50127610",
      "AKOS027413644",
      "(1S,2S)-2-(2-Aminoethyl)-1-(1H-Imidazol-4-Yl)Cyclopropane",
    ],
  },
  {
    primary_id: "D0KK4F",
    names: [
      "4-Methylimidazole",
      "4-Methyl-1H-Imidazole",
      "822-36-6",
      "5-Methyl-1H-Imidazole",
      "1H-Imidazole, 4-Methyl-",
      "4(5)-Methylimidazole",
      "4-Me-I",
      "5-Methylimidazole",
      "IMIDAZOLE, 4-METHYL-",
      "4(Or 5)-Methylimidazole",
      "4-MEI",
      "4-Methylimidazol",
      "UNII-Q64GF9FV4I",
      "1H-Imidazole, 5-Methyl-",
      "4-Methyl Imidazole",
      "EINECS 212-497-3",
      "NSC 40744",
      "Q64GF9FV4I",
      "AI3-08274",
      "CHEBI:40035",
      "XLSZMDLNRCVEIJ-UHFFFAOYSA-N",
      "4-Methylimidazole, 98%",
      "4-Methyl-D3-Imidazole",
      "88054-16-4",
      "4MI",
      "4MZ",
      "InChI=1/C4H6N2/C1-4-2-5-3-6-4/H2-3H,1H3,(H,5,6",
      "CCRIS 8912",
      "HSDB 7757",
      "4-Methyl-Imidazole",
    ],
  },
  {
    primary_id: "D0KK4N",
    names: ["ISA-P53-01"],
  },
  {
    primary_id: "D0KK4U",
    names: ["CB-1267"],
  },
  {
    primary_id: "D0KK6A",
    names: ["PMID8410973C2"],
  },
  {
    primary_id: "D0KK6L",
    names: ["PMID26560530-Compound-13"],
  },
  {
    primary_id: "D0KK9D",
    names: [
      "CA-011",
      "2-Amino-4-(P-Nitrophenyl)Thiazole",
      "4-(4-Nitrophenyl)-1,3-Thiazol-2-Amine",
      "2104/9/8",
      "2-Amino-4-(4-Nitrophenyl)Thiazole",
      "4-(4-Nitrophenyl)-2-Thiazolamine",
      "2-Thiazolamine, 4-(4-Nitrophenyl)-",
      "4-(4-Nitrophenyl)Thiazol-2-Amine",
      "THIAZOLE, 2-AMINO-4-(P-NITROPHENYL)-",
      "4-(4-Nitrophenyl)Thiazol-2-Ylamine",
      "2-Amino-4-(4'-Nitrophenyl)-1,3-Thiazole",
      "RIKJWJIWXCUKQV-UHFFFAOYSA-N",
      "BRN 0200321",
      "4-(4-Nitro-Phenyl)-Thiazol-2-Ylamine",
      "AI3-62320",
      "4-(4-Nitrophenyl)-1,3-Thiazole-2-Ylamine",
      "DSSTox_CID_65",
      "AC1L27WX",
      "Cambridge Id 5156331",
      "DSSTox_RID_",
    ],
  },
  {
    primary_id: "D0KK9E",
    names: ["Exherin"],
  },
  {
    primary_id: "D0KK9V",
    names: ["GI-4000 In Combination With Gemcitabine"],
  },
  {
    primary_id: "D0KL1I",
    names: [
      "1,4-Dideoxy-O2-Sulfo-Glucuronic Acid",
      "AC1NRBS8",
      "(2R,4S,5S)-4-Hydroxy-5-Sulfooxyoxane-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0KL1P",
    names: ["PB-28"],
  },
  {
    primary_id: "D0KL3B",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 8"],
  },
  {
    primary_id: "D0KL3K",
    names: [
      "YM-440",
      "2,2'-[2(Z)-Butene-1,4-Diyl]dioxybis(1,4-Phenylene)Bis(Methylene)Bis[1,2,4-Oxadiazole-3,5(2H,4H)-Dione]",
    ],
  },
  {
    primary_id: "D0KL3U",
    names: [
      "AR9281",
      "1-(Adamantane-1-Yl)-3-(1-Acetylpiperidine-4-Yl)Urea",
      "UNII-4HA03Q8EZ9",
      "4HA03Q8EZ9",
      "CHEMBL436774",
      "1-[(1-Acetylpiperidin-4-Yl)-3-Adamantan-1-Yl]urea",
      "913548-29-5",
      "Ar9281",
      "AR-9281",
      "Apau (Enzyme Inhibitor)",
      "SCHEMBL654229",
      "1-(1-Acetyl-Piperidin-4-Yl)-3-Adamantan-1-Yl-Urea",
      "SCHEMBL18464997",
      "MolPort-023-222-910",
      "HUDQLWBKJOMXSZ-UHFFFAOYSA-N",
      "BDBM100423",
      "BDBM50191854",
      "ZINC36330562",
      "AKOS030231617",
      "J3137087F",
      "US8501783, 1153",
      "1-(Adamant-1-Yl)-3-(1-Acetylpiperidin-4-Yl) Urea",
      "N-(1-Acetylpiperidin-4-Yl)-N'-(Adamant-1-Yl) Urea",
      "N-(1-Acetylpiperidin-4-Yl)-N'-(Adamant-1-Yl)Urea",
    ],
  },
  {
    primary_id: "D0KL4E",
    names: ["SAR422459"],
  },
  {
    primary_id: "D0KL4J",
    names: [
      "Rabeprazole",
      "Dexrabeprazole",
      "Eraloc",
      "Rebeprazole Sodium",
      "CL23619",
      "LY307640",
      "Aciphex (TN)",
      "Eraloc (TN)",
      "Pariet (TN)",
      "Rabeprazole (INN)",
      "Rabeprazole [BAN:INN]",
      "Rabeprazole [INN:BAN]",
      "Rablet (TN)",
      "2-(((4-(3-Methoxypropoxy)-3-Methyl-2-Pyridinyl)Methyl)Sulfinyl)-1H-Benzimidazole",
      "2-((4-(3-Methoxypropoxy)-3-Methylpyridin-2-Yl)Methylsulfinyl)-1H-Benzimidazole",
      "2-({[4-(3-Methoxypropoxy)-3-Methylpyridin-2-Yl]methyl}sulfinyl)-1H-Benzimidazole",
      "2-[[4-(3-Methoxypropoxy)-3-Methylpyridin-2-Yl]methylsulfinyl]-1H-Benzimidazole",
    ],
  },
  {
    primary_id: "D0KL4K",
    names: ["LSP4-2022"],
  },
  {
    primary_id: "D0KL4T",
    names: ["Ensitiximab"],
  },
  {
    primary_id: "D0KL5K",
    names: ["ISIS 102668"],
  },
  {
    primary_id: "D0KL5M",
    names: ["PMID25656651-Compound-38"],
  },
  {
    primary_id: "D0KL5N",
    names: ["Dmt-Pro-Tmp-Phe-NH2", "CHEMBL228503"],
  },
  {
    primary_id: "D0KL5U",
    names: ["SYM-008"],
  },
  {
    primary_id: "D0KL7C",
    names: ["Aviptadil", "Aviptadil (Intravenous/Peptide, Acute Respiratory Distress Syndrome/Acute Lung Injury)"],
  },
  {
    primary_id: "D0KL7G",
    names: ["MCL-1-Specific Antisense Oligonucleotide + Midostaurin(PKC412)"],
  },
  {
    primary_id: "D0KL9X",
    names: ["Krypton, Kr-81m"],
  },
  {
    primary_id: "D0KM0T",
    names: ["Pyrazole Derivative 74"],
  },
  {
    primary_id: "D0KM0W",
    names: ["PMID25666693-Compound-137"],
  },
  {
    primary_id: "D0KM0Y",
    names: ["2-Amino-4-Phenyl-6-Thiophen-2-Yl-Nicotinonitrile", "CHEMBL476308", "SCHEMBL6251249"],
  },
  {
    primary_id: "D0KM1B",
    names: ["Follicle Stimulating Hormone"],
  },
  {
    primary_id: "D0KM1F",
    names: ["PT-00114"],
  },
  {
    primary_id: "D0KM1H",
    names: ["Iso-Caffeine"],
  },
  {
    primary_id: "D0KM3P",
    names: ["NOStentin"],
  },
  {
    primary_id: "D0KM4T",
    names: ["UAI-101"],
  },
  {
    primary_id: "D0KM7H",
    names: [
      "N-Hydroxy-8-(2-Methoxyphenyl)-8-Oxooctanamide",
      "CHEMBL95885",
      "SCHEMBL3382183",
      "BDBM50114815",
      "8-(2-Methoxy-Phenyl)-8-Oxo-Octanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0KM7V",
    names: ["PMID25656651-Compound-34b"],
  },
  {
    primary_id: "D0KM8V",
    names: ["PMID27977313-Compound-47"],
  },
  {
    primary_id: "D0KN0I",
    names: ["Valetizumab"],
  },
  {
    primary_id: "D0KN0K",
    names: [
      "6-Benzylamino-5-Iodo-1H-Pyrimidine-2,4-Dione",
      "5-Iodo-6-(Benzylamino)Uracil",
      "CHEMBL161515",
      "100763-62-0",
    ],
  },
  {
    primary_id: "D0KN1L",
    names: ["(S)-Benzyl 4-Chloro-3-Oxobutan-2-Ylcarbamate"],
  },
  {
    primary_id: "D0KN1U",
    names: ["Myo-Inositol Hexaphosphate"],
  },
  {
    primary_id: "D0KN2C",
    names: ["Insulin Long-Acting Iv"],
  },
  {
    primary_id: "D0KN2M",
    names: [
      "Cynarin",
      "1,3-O-Dicaffeoylquinic Acid",
      "19870-46-3",
      "1,3-Dicaffeoylquinic Acid",
      "1,5-Dicaffeoylquinic Acid",
      "Cynarine",
      "1,5-Di-O-Caffeoylquinic Acid",
      "1,3-Di-O-Caffeoylquinic Acid",
      "30964-13-7",
      "(1S,3R,4R,5R)-1,3-Bis[3-(3,4-Dihydroxyphenyl)Acryloyloxy]-4,5-Dihydroxycyclohexanecarboxylic Acid",
      "(1S,3R,4R,5R)-1,3-Bis[[(E)-3-(3,4-Dihydroxyphenyl)Prop-2-Enoyl]oxy]-4,5-Dihydroxy-Cyclohexanecarboxylic Acid",
      "AC1O6FEM",
      "1,5-Dicaffeoylquinic-Acid",
      "1,3-Dicaffeoyl Quinic Acid",
      "1,5-Dicaffeoylquinic Acid?",
      "CHEMBL478574",
      "1,5-DCQA",
    ],
  },
  {
    primary_id: "D0KN3S",
    names: ["Carbamide Derivative 5"],
  },
  {
    primary_id: "D0KN5B",
    names: ["CYT107"],
  },
  {
    primary_id: "D0KN6L",
    names: ["PMID30273516-Compound-15"],
  },
  {
    primary_id: "D0KN6Q",
    names: ["3R14S-OCHRATOXIN A"],
  },
  {
    primary_id: "D0KN7H",
    names: ["Mitoflaxone"],
  },
  {
    primary_id: "D0KN7T",
    names: ["Thiazole Carboxamide Derivative 22"],
  },
  {
    primary_id: "D0KN7Y",
    names: ["NPT088"],
  },
  {
    primary_id: "D0KN9S",
    names: ["PMID27321640-Compound-76"],
  },
  {
    primary_id: "D0KO1E",
    names: ["ISIS 110073"],
  },
  {
    primary_id: "D0KO2E",
    names: ["Heteroaryl-Azepine Derivative 11"],
  },
  {
    primary_id: "D0KO3I",
    names: ["Olenolic Acid Acetate Derivative 1"],
  },
  {
    primary_id: "D0KO6Y",
    names: [
      "[3H]CGS19755",
      "ACMC-20mhnp",
      "ACMC-20bxqj",
      "4-(Phosphonomethyl)-2-Piperidinecarboxylic Acid",
      "AC1N8SPX",
      "SCHEMBL120235",
      "2-Piperidinecarboxylicacid, 4-(Phosphonomethyl)-, (2R,4S)-Rel-",
      "GTPL4080",
      "113229-89-3",
      "AKOS005066562",
      "4-Phosphonomethyl-2-Piperidinecarboxylic Acid",
      "A802182",
    ],
  },
  {
    primary_id: "D0KO9R",
    names: [
      "Bromocresol Green",
      "76-60-8",
      "BROMCRESOL GREEN",
      "Bromo Cresol Green",
      "3,3-Bis(3,5-Dibromo-4-Hydroxy-2-Methylphenyl)-3H-Benzo[c][1,2]oxathiole 1,1-Dioxide",
      "Tetrabromo-M-Cresolphthalein Sulfone",
      "3',3'',5',5''-Tetrabromo-M-Cresolsulfonephthalein",
      "Bromocresol Blue",
      "NSC 7817",
      "UNII-8YGN0Y942M",
      "EINECS 200-972-8",
      "BRN 0372527",
      "CHEMBL145704",
      "8YGN0Y942M",
      "Bromocresol Green, ACS Reagent",
      "NSC7817",
      "FRPHFZCDPYBUAU-UHFFFAOYSA-N",
      "MFCD00005874",
      "O-Toluenesulfonic Acid, Alpha-Hydroxy-, Gamma-Sultone",
      "BROMOCRESOL GREEN, ACS",
      "Phenol, 4,4'-(3H-2,1-Benzox",
    ],
  },
  {
    primary_id: "D0KP0I",
    names: ["Piperazine Carbamate/Urea Derivative 1"],
  },
  {
    primary_id: "D0KP0L",
    names: ["SE-ET-TP020d"],
  },
  {
    primary_id: "D0KP0U",
    names: ["PMID26394986-Compound-22"],
  },
  {
    primary_id: "D0KP2R",
    names: ["ID-12"],
  },
  {
    primary_id: "D0KP3K",
    names: ["SB-410220"],
  },
  {
    primary_id: "D0KP4J",
    names: ["PMID30273516-Compound-41"],
  },
  {
    primary_id: "D0KP8N",
    names: ["Flavonoid Derivative 7"],
  },
  {
    primary_id: "D0KP9A",
    names: ["Anti-PD-L1", "BMS-936559"],
  },
  {
    primary_id: "D0KQ0C",
    names: ["Danegaptide"],
  },
  {
    primary_id: "D0KQ1K",
    names: ["Bbs-Arg-(D-Pip)-Gly-(SPH(PY)EKVS)-Gly-Hir"],
  },
  {
    primary_id: "D0KQ2D",
    names: ["RVX-297"],
  },
  {
    primary_id: "D0KQ6L",
    names: ["Phenyl 4-(Dodecyloxy)Phenylcarbamate"],
  },
  {
    primary_id: "D0KQ6U",
    names: ["2,7-Bis(Phenylpropionamido)Anthraquinone", "SCHEMBL4296330"],
  },
  {
    primary_id: "D0KQ7M",
    names: ["ARQ 531"],
  },
  {
    primary_id: "D0KQ8A",
    names: ["Tyr-Pro-Trp-D-Val-Bn", "CHEMBL577745"],
  },
  {
    primary_id: "D0KQ8W",
    names: ["SurVaxM"],
  },
  {
    primary_id: "D0KQ8X",
    names: ["Entresto"],
  },
  {
    primary_id: "D0KQ9H",
    names: [
      "L-747201",
      "CHEMBL116092",
      "SCHEMBL9075818",
      "BDBM50060419",
      "L010227",
      "3-(2-Pyrrolizinoethyl)-5-(4H-1,2,4-Triazol-4-Yl)-1H-Indole",
    ],
  },
  {
    primary_id: "D0KR0I",
    names: ["Benzenamine Derivative 4"],
  },
  {
    primary_id: "D0KR1N",
    names: ["PPI-2458"],
  },
  {
    primary_id: "D0KR2J",
    names: [
      "Entecavir",
      "Baraclude",
      "ETV",
      "Entecavir Hydrate",
      "Entecavir Monohydrate",
      "BMS-200475",
      "Baraclude (TN)",
      "Entecavir (INN)",
      "Entecavir (USAN)",
      "Entecavir Hydrate (JAN)",
      "SQ-34676",
      "2-Amino-1,9-Dihydro-9-[(1S,3R,4S)-4-Hydroxy-3-(Hydroxymethyl)-2-Methylenecyclopentyl]-6H-Purin-6-One",
      "2-Amino-9-[(1S,3R,4S)-4-Hydroxy-3-(Hydroxymethyl)-2-Methylidenecyclopentyl]-1,9-Dihydro-6H-Purin-6-One",
      "2-Amino-9-[(1S,3R,4S)-4-Hydroxy-3-(Hydroxymethyl)-2-Methylidenecyclopentyl]-1,9-Dihydro-6H-Purin-6-One-Water (1/1)",
      "2-Amino-9-[(1S,3R,4S)-4-Hydroxy-3-(Hydroxymethyl)-2-Methylidenecyclopentyl]-3H-Purin-6-One",
      "6-H-Purin-6-One-,2-Amino-1,9-Dihydro-9-[(1S,3R,4S)-4-Hydroxy-3-(Hydroxymethyl)-2-Methylenecyclopentyl]",
      "9-((1S,3R,4S)-4-Hydroxy-3-(Hydroxymethyl)-2-Methylenecyclopentyl)Guanine Monohydrate",
    ],
  },
  {
    primary_id: "D0KR2T",
    names: ["PMID28394193-Compound-15"],
  },
  {
    primary_id: "D0KR3X",
    names: ["CGS-30440"],
  },
  {
    primary_id: "D0KR5B",
    names: [
      "Hydrocortisone",
      "Acticort",
      "Alacort",
      "Algicirtis",
      "Alphaderm",
      "Amberin",
      "Anflam",
      "Anucort",
      "Aquacort",
      "Cetacort",
      "Chronocort",
      "Cleiton",
      "Cobadex",
      "Colocort",
      "Corhydron",
      "Cortanal",
      "Cortef",
      "Cortenema",
      "Cortesal",
      "Corticreme",
      "Cortifair",
      "Cortifan",
      "Cortiment",
      "Cortisol",
      "Cortisolonum",
      "Cortisporin",
      "Cortispray",
      "Cortolotion",
      "Cortonema",
      "Cortoxide",
      "Cortril",
      "Cremesone",
      "Cutisol",
      "Delacort",
      "Dermacort",
      "Dermaspray",
      "Dermil",
      "Dermocortal",
      "Dermolate",
      "Dihydrocostisone",
      "Dioderm",
      "Drotic",
      "DuoCort",
      "Efcorbin",
      "Efcortelan",
      "Efcortelin",
      "Eldecort",
      "Eldercort",
      "Epicort",
      "Epicortisol",
      "Evacort",
      "Ficortril",
      "Fiocortril",
      "Flexicort",
      "Genacort",
      "Glycort",
      "Hidalone",
      "Hycort",
      "Hycortol",
      "Hycortole",
      "Hydracort",
      "Hydrasson",
      "Hydrocortal",
      "Hydrocorticosterone",
      "Hydrocortisyl",
      "Hydrocortone",
      "Hydroskin",
      "Hydroxycortisone",
      "Hytisone",
      "Hytone",
      "Idrocortisone",
      "Kyypakkaus",
      "Lactisona",
      "Lubricort",
      "Maintasone",
      "Medicort",
      "Meusicort",
      "Mildison",
      "Milliderm",
      "Nutracort",
      "Optef",
      "Otocort",
      "Penecort",
      "Permicort",
      "Prepcort",
      "Proctocort",
      "Protocort",
      "Rectoid",
      "Sanatison",
      "Schericur",
      "Sigmacort",
      "Signef",
      "Stiefcorcil",
      "Synacort",
      "Tarcortin",
      "Texacort",
      "Timocort",
      "Traumaide",
      "Uniderm",
      "Vytone",
      "Zenoxone",
      "ACETASOL HC",
      "Aeroseb HC",
      "AnusolHC",
      "Aquanil HC",
      "Barseb HC",
      "CaldeCORT Spray",
      "Clear Aid",
      "Component Of Lubricort",
      "Component Of Otalgine",
      "Cortisol Alcohol",
      "Cortisporin Otico",
      "Ef Corlin",
      "Epiderm H",
      "Esiderm H",
      "Foille Insetti",
      "HYDROCORTISONE AND ACETIC ACID",
      "HYDROCORTISONE IN ABSORBASE",
      "Heb Cort",
      "Hydrocortisone Alcohol",
      "Hydrocortisone Base",
      "Hydrocortisone Free Alcohol",
      "Hydrocortisone Solution",
      "Hytone Lotion",
      "Idrocortisone [DCIT]",
      "Komed HC",
      "Lacticare HC",
      "Nogenic HC",
      "ORLEX HC",
      "Pediotic Suspension",
      "Polcort H",
      "Preparation H Hydrocortisone Cream",
      "Prevex HC",
      "Proctozone HC",
      "Remederm HC",
      "Scalpicin Capilar",
      "Scheroson F",
      "Systral Hydrocort",
      "Transderma H",
      "VoSol HC",
      "H 4001",
      "Texacort Lotion 25",
      "[3H]cortisol",
      "ACETIC ACID W/ HYDROCORTISONE",
      "Acticort (TN)",
      "Aeroseb-HC",
      "Ala-Cort",
      "Ala-Scalp",
      "Anti-Inflammatory Hormone",
      "Anucort-HC",
      "Anusol HC (TN)",
      "Balneol-Hc",
      "Basan-Corti",
      "Beta-Hc",
      "COR-OTICIN",
      "Colocort (TN)",
      "Cort-Dome",
      "Cortef (TN)",
      "Cortisol, Hydrocortisone",
      "Cremicort-H",
      "Cyclodextrin-Encapsulated Hydrocortisone",
      "Derm-Aid",
      "Dome-Cort",
      "Domolene-HC",
      "Genacort (Lotion)",
      "Gyno-Cortisone",
      "H-Cort",
      "HC #1",
      "HC #4",
      "HC (HYDROCORTISONE)",
      "Heb-Cort",
      "Hi-Cor",
      "Hidro-Colisona",
      "Hidrocortisona [INN-Spanish]",
      "Hydro-Adreson",
      "Hydro-Colisona",
      "Hydro-RX",
      "Hydrocortisone-Water Soluble",
      "Hydrocortisonum [INN-Latin]",
      "Hytone (TN)",
      "Incortin-H",
      "Incortin-Hydrogen",
      "Kendall's Compound F",
      "Lacticare-HC",
      "Neo-Cortef",
      "Neosporin-H Ear",
      "Nystaform-HC",
      "Otosone-F",
      "Rectasol-HC",
      "Reichstein's Substance M",
      "Scalp-Cort",
      "Stie-Cort",
      "Component Of Neo-Cort-Dome",
      "Hydrocortisone [INN:BAN:JAN]",
      "Hydrocortisone (JP15/USP/INN)",
      "11-Beta-Hydrocortisone",
      "11-Beta-Hydroxycortisone",
      "11beta,17,21-Trihydroxyprogesterone",
      "11beta-Hydrocortisone",
      "11beta-Hydroxycortisone",
      "17-Hydroxycorticosterone",
      "17alpha-Hydroxycorticosterone",
    ],
  },
  {
    primary_id: "D0KR5K",
    names: ["Capravirine", "AG-1549", "S-1153", "NNRTI (HIV Infection), Pfizer"],
  },
  {
    primary_id: "D0KR6E",
    names: ["SER-207"],
  },
  {
    primary_id: "D0KR7K",
    names: ["PMID25656651-Compound-16b"],
  },
  {
    primary_id: "D0KR7R",
    names: ["PMID25482888-Compound-18"],
  },
  {
    primary_id: "D0KR8L",
    names: ["AMG 899"],
  },
  {
    primary_id: "D0KR9E",
    names: ["PMID26815044-Compound-122"],
  },
  {
    primary_id: "D0KR9U",
    names: [
      "Picrotoxin",
      "Cocculin",
      "Cocculus",
      "Picrotox",
      "Picrotoxine",
      "Picrotoxinum",
      "Sesquiterpene",
      "Coques Du Levant",
      "Coques Du Levant [French];Fish Berry",
      "Indian Berry",
      "Oriental Berry",
      "P 1675",
      "Picrotin-Picrotoxinin",
      "Picrotoxin (Compound Of One Mole Picrotoxinin And One Mole Picrotin)",
      "Picrotoxinin-Picrotin",
      "Picrotin, Compound With Picrotoxinin (1:1)",
      "Picrotin, Compd. With Picrotoxinin (1:1)",
      "Picrotoxinin, Compd. With Picrotin (1:1)",
      "3,6-Methano-8H-1,5,7-Trioxacyclopenta(Ij)Cycloprop(A)Azulene-4,8(3H)-Dione, Hexahydro-2a-Hydroxy-9-(1-Hydroxy-1-Methylethyl)-8b-Methyl-, (1aR-(1aalpha,2abeta,3beta,6beta,6abeta,8aS*,8bbeta,9S*))-, Compd. With (1aR-(1aalpha,2abeta,3beta,6beta,6",
      "3,6-Methano-8H-1,5,7-Trioxacyclopenta[ij]cycloprop[a]azulene-4,8(3H)-Dione, Hexahydro-2a-Hydroxy-9-(1-Hydroxy-1-Methylethyl)-8b-Methyl-, (1aR,2aR,3S,6R,6aS,8aS,8bR,9S)-& (1aR,2aR,3S,6R,6aS,8aS,8bR,9R",
      "3,6-Methano-8H-1,5,7-Trioxacyclopenta[ij]cycloprop[a]azulene-4,8(3H)-Dione, Hexahydro-2a-Hydroxy-9-(1-Hydroxy-1-Methylethyl)-8b-Methyl-, [1aR-(1a.alpha.,2",
    ],
  },
  {
    primary_id: "D0KS0I",
    names: ["WAY-256805"],
  },
  {
    primary_id: "D0KS1Z",
    names: ["PMID16242323C26c"],
  },
  {
    primary_id: "D0KS2T",
    names: ["RU81843"],
  },
  {
    primary_id: "D0KS2X",
    names: [
      "CGS-9895",
      "Cgs-9895",
      "77779-50-1",
      "Cgs 9895",
      "MLS003171322",
      "NSC373970",
      "2-(4-Methoxy-Phenyl)-1,2-Dihydro-Pyrazolo[4,3-C]quinolin-3-One",
      "CHEMBL296335",
      "2-(4-Methoxyphenyl)-2,5-Dihydro-3H-Pyrazolo[4,3-C]quinolin-3-One",
      "FTHGIXILGYJOBQ-UHFFFAOYSA-N",
      "NSC 373970",
      "AC1L2PJC",
      "AC1Q4BZG",
      "SCHEMBL3646887",
      "SCHEMBL1466285",
      "CHEMBL3144739",
      "CTK5E4891",
      "BDBM84956",
      "AOB4750",
      "MolPort-010-675-457",
      "KSKRJZMRHSNRBX-UHFFFAOYSA-N",
      "HMS3436H11",
      "PDSP2_001747",
      "PDSP1_001764",
      "2,5-Dihydro-2-(4-Methoxyphenyl)-3H-Pyrazolo(4,3-C)Quinolin-3-One",
      "AKOS021660772",
      "ZINC101168346",
      "NSC-373970",
    ],
  },
  {
    primary_id: "D0KS3G",
    names: ["PMID29166791-Compound-AMPCP"],
  },
  {
    primary_id: "D0KS3J",
    names: ["PMID25726713-Compound-64"],
  },
  {
    primary_id: "D0KS3X",
    names: [
      "U-89267",
      "CHEMBL142750",
      "SCHEMBL8883956",
      "BDBM50040717",
      "(S)-1-(5-Cyclopropyl-1,2,4-Oxadiazol-3-Yl)-10,11,12,12a-Tetrahydroimidazo[1,5-A]pyrrolo[2,1-C]quinoxalin-10-One",
      "1-(5-Cyclopropyl-1,2,4-Oxadiazol-3-Yl)-10,11,12,12a-Tetrahydroimidazo[1,5-A]pyrrolo[2,1-C]quinoxalin-10-One",
    ],
  },
  {
    primary_id: "D0KS6A",
    names: ["2alpha-Hydroxyurs-12-En-28-Oic Acid", "CHEMBL1078844"],
  },
  {
    primary_id: "D0KS6F",
    names: ["IRX-2"],
  },
  {
    primary_id: "D0KS6W",
    names: [
      "Phenyltoloxamine",
      "Bristamin Hydrochloride",
      "Phenyltoloxamine Hydrochloride",
      "HL 2153",
      "(2-(Dimethylamino)Ethyl) (O-Benzylphenoxy)Ether Hydrochloride",
      "N,N-Dimethyl-2-(Alpha-Phenyl-O-Toloxy)-Ethylamine Hydrochloride",
      "ETHYLAMINE, 2-(O-BENZYLPHENOXY)-N,N-DIMETHYL-, HYDROCHLORIDE",
      "Ethylamine, N,N-Dimethyl-2-((Alpha-Phenyl-O-Tolyl)Oxy)-, Hydrochloride",
      "N-(2'-Dimethylaminoaethyl)-(O-Benzylphenol)-Aether Hydrochlorid [German]",
      "AC1L2KJQ",
      "92-12-6 (Parent)",
      "LS-67923",
      "2-(2-Benzylphenoxy)Ethyl-Dimethylazanium Chloride",
    ],
  },
  {
    primary_id: "D0KS8Y",
    names: ["Imidazo Cyclohexylamine Derivative 2"],
  },
  {
    primary_id: "D0KT1Q",
    names: ["Pyrazole Derivative 11"],
  },
  {
    primary_id: "D0KT2M",
    names: ["2-(2-Methoxyphenoxy)-3-(Piperidin-4-Yl)Pyridine", "CHEMBL597531"],
  },
  {
    primary_id: "D0KT3L",
    names: ["2-[(4-Fluorophenyl)Ethynyl]-N6-Methoxyadenosine", "CHEMBL223739", "SCHEMBL4381150"],
  },
  {
    primary_id: "D0KT3P",
    names: ["KRP-109"],
  },
  {
    primary_id: "D0KT3X",
    names: ["Thrombin/Fibrinogen Matrix Patch"],
  },
  {
    primary_id: "D0KT4B",
    names: ["CLP-1002", "Cross-Linked Polyelectrolyte Polymer (CHF, Kidney Disease), Sorbent Therapeutics"],
  },
  {
    primary_id: "D0KT4N",
    names: [
      "Erythose-4-Phosphate",
      "D-Erythrose 4-Phosphate",
      "Erythrose 4-Phosphate",
      "Threose 4-Phosphate",
      "ERYTHOSE-4-PHOSPHATE",
      "D-Erythrose-4-Phosphate",
      "585-18-2",
      "Erythrose-4-Phosphate",
      "Erythrose-4P",
      "Erythrose-4-P",
      "D-Erythrose-4-P",
      "UNII-2156QF7O8M",
      "CHEBI:48153",
      "2156QF7O8M",
      "(2R,3R)-2,3-Dihydroxy-4-Oxobutyl Dihydrogen Phosphate",
      "E4P",
      "Butanal, 2,3-Dihydroxy-4-(Phosphonooxy)-, (R-(R*,R*))-",
      "4-D-Erythrosephosphoric Acid",
      "Erythrose 4-PO4",
      "D-Erythrose 4-PO4",
      "AC1L3UFJ",
      "4-O-Phosphono-D-Erythrose",
      "SCHEMBL50110",
      "AC1Q6S62",
      "Butanal, 2,3-Dihydroxy-4-(Phosphonooxy)-,(2r,3r)-",
    ],
  },
  {
    primary_id: "D0KT5E",
    names: ["Lysophosphatidylcholine"],
  },
  {
    primary_id: "D0KT5W",
    names: ["PMID25656651-Compound-30b"],
  },
  {
    primary_id: "D0KT8D",
    names: ["AC0058TA"],
  },
  {
    primary_id: "D0KT8U",
    names: ["Semotiadil"],
  },
  {
    primary_id: "D0KU2D",
    names: ["N-Phenyl-1H-Pyrrole-2-Carboxamide"],
  },
  {
    primary_id: "D0KU2T",
    names: ["Pravastatin Derivative 1"],
  },
  {
    primary_id: "D0KU4M",
    names: ["MB07803"],
  },
  {
    primary_id: "D0KU5D",
    names: ["YM-35278"],
  },
  {
    primary_id: "D0KU5J",
    names: ["Resorcinol Compound 5"],
  },
  {
    primary_id: "D0KU6D",
    names: [
      "(R,S)-1-(6-Fluoro-1H-Indol-1-Yl)Propan-2-Amine",
      "1H-Indole-1-Ethanamine, 6-Fluoro-Alpha-Methyl-",
      "CHEMBL92656",
      "SCHEMBL8959596",
    ],
  },
  {
    primary_id: "D0KV1I",
    names: ["GC-1113"],
  },
  {
    primary_id: "D0KV1Q",
    names: ["Trafermin"],
  },
  {
    primary_id: "D0KV1Z",
    names: ["ImmunoVEX HSV2"],
  },
  {
    primary_id: "D0KV2E",
    names: ["Sonepcizumab"],
  },
  {
    primary_id: "D0KV2U",
    names: ["P-Toluenesulfonate Derivative 1"],
  },
  {
    primary_id: "D0KV3M",
    names: ["Varlilumab"],
  },
  {
    primary_id: "D0KV4G",
    names: [
      "1-(Piperazin-1-Yl)Isoquinoline",
      "126653-00-7",
      "1-Piperazine-1-Yl-Isoquinoline",
      "1-Piperazin-1-Yl-Isoquinoline",
      "1-(1-Isoquinolinyl)Piperazine",
      "1-(1-Piperazinyl)Isoquinoline",
      "CHEMBL595552",
      "Isoquinoline,1-(1-Piperazinyl)-",
      "Isoquinoline, 1-(1-Piperazinyl)-",
      "W-205253",
      "1-[1-Piperazinyl]isoquinoline",
      "PubChem8306",
      "ACMC-209ymu",
      "Piperazinohydroisoquinoline",
      "1-Piperazin-1-Ylisoquinoline",
      "1-Pyperazin-1-Yl-Isoquinoline",
      "SCHEMBL2076366",
      "TPC-A003",
      "1-(Isoquinolin-1-Yl)Piperazine",
      "CTK4B5291",
      "KS-00000JYY",
      "IDFYLSABDUMICK-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0KV4Z",
    names: [
      "CI-949",
      "CI 949",
      "UNII-EM1J51043X",
      "EM1J51043X",
      "L-Arginine, Compd. With 5-Methoxy-3-(1-Methylethoxy)-1-Phenyl-N-1H-Tetrazol-5-Yl-1H-Indole-2-Carboxamide (1:1)",
      "OHOCQQJBKBVAFF-VWMHFEHESA-N",
      "AC1L4M1L",
      "AC1Q5G9U",
      "121530-58-3",
      "SCHEMBL17041896",
      "N5-(Diaminomethylidene)-L-Ornithine- 5-Methoxy-1-Phenyl-3-(Propan-2-Yloxy)-N-(2h-Tetrazol-5-Yl)-1h-Indole-2-Carboxamide(1:1)",
      "LS-21607",
      "5-Methoxy-3-(1-Methylethoxy)-1-Phenyl-N-1H-Tetrazol-5-Yl-1H-Indole-2-Carboxamide L-Arginate Salt",
      "(2S)-2-Amino-5-(Diaminomethylideneamino)Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0KV5Z",
    names: ["Benzisoselenazolone Difluorocytidine Derivative 1"],
  },
  {
    primary_id: "D0KV6C",
    names: ["RG3039"],
  },
  {
    primary_id: "D0KV7D",
    names: ["PMID25435285-Compound-20"],
  },
  {
    primary_id: "D0KW0F",
    names: ["Pyridine Derivative 9"],
  },
  {
    primary_id: "D0KW2A",
    names: [
      "BN50739",
      "BN 50739",
      "BN-50739",
      "128672-07-1",
      "Tetrahydro-4,7,8,10 Methyl-(Chloro-2-Phenyl)-6-((3,4-Dimethoxyphenyl)Thio)Methylthiocarbonyl-9-Pyrido(4',3'-4,5)Thieno(3,2-F)Triazolo-1,2,4(4,3-A)Diazepine-1,4",
      "4H-Pyrido(4',3':4,5)Thieno(3,2-F)(1,2,4)Triazolo(4,3-A)(1,4)Diazepine, 6-(2-Chlorophenyl)-9-(2-((3,4-Dimethoxyphenyl)Thio)-1-Thioxoethyl)-7,8,9,10-Tetrahydro-1-Methyl-",
      "AC1L3GCW",
      "GTPL1851",
      "DTXSID60155969",
      "LS-187569",
      "LS-186919",
      "L000670",
    ],
  },
  {
    primary_id: "D0KW3Z",
    names: ["GORALATIDE", "AcSDKP", "Seraspenide", "BIM-32001", "Goralatide", "Acetyl-Seryl-Aspartyl-Lysyl-Proline"],
  },
  {
    primary_id: "D0KW4D",
    names: ["Niraparib"],
  },
  {
    primary_id: "D0KW4J",
    names: ["Melphalan Drug Delivery System"],
  },
  {
    primary_id: "D0KW4Y",
    names: [
      "H-Tyr-D-Ala-Tic-Asp-Val-Val-Gly-NH2",
      "CHEMBL330991",
      "BDBM50001460",
      "3-[(2-{2-[2-Amino-3-(4-Hydroxy-Phenyl)-Propionylamino]-Propionyl}-1,2,3,4-Tetrahydro-Isoquinoline-3-Carbonyl)-Amino]-N-{1-[1-(Carbamoylmethyl-Carbamoyl)-2-Methyl-Propylcarbamoyl]-2-Methyl-Propyl}-Succinamic Acid",
    ],
  },
  {
    primary_id: "D0KW6V",
    names: ["3-Benzyl-N-(2-Morpholinoethyl)Quinoxalin-2-Amine", "CHEMBL206465"],
  },
  {
    primary_id: "D0KW7O",
    names: ["PMID25399762-Compound-Figure3-Fluevirosine A"],
  },
  {
    primary_id: "D0KW8U",
    names: ["PTS-508a"],
  },
  {
    primary_id: "D0KW9T",
    names: ["RSIFN-Co"],
  },
  {
    primary_id: "D0KX0J",
    names: ["Pyridyl Compound 1"],
  },
  {
    primary_id: "D0KX1A",
    names: ["Aryl Mannoside Derivative 6"],
  },
  {
    primary_id: "D0KX1E",
    names: ["CEP-2563"],
  },
  {
    primary_id: "D0KX1Y",
    names: ["Erenumab", "Aimovig", "Erenumab [USAN]", "UNII-I5I8VB78VT", "I5I8VB78VT"],
  },
  {
    primary_id: "D0KX2W",
    names: ["1,2,4,5-Tetra-Substituted Imidazole Derivative 1"],
  },
  {
    primary_id: "D0KX3A",
    names: ["PF-4194471"],
  },
  {
    primary_id: "D0KX3C",
    names: ["ZM-274773"],
  },
  {
    primary_id: "D0KX3H",
    names: ["Pyrrolopyrimidine"],
  },
  {
    primary_id: "D0KX3X",
    names: ["MCL-516"],
  },
  {
    primary_id: "D0KX4U",
    names: ["ISIS 20579"],
  },
  {
    primary_id: "D0KX5G",
    names: ["Imidazo Bicyclic Iminium Derivative 8"],
  },
  {
    primary_id: "D0KX5H",
    names: ["MSP-2017", "Calcium Channel Antagonist (Systemic, Cardiovascular Disease), Milestone"],
  },
  {
    primary_id: "D0KX6D",
    names: ["ZK-112993", "105114-63-4"],
  },
  {
    primary_id: "D0KX6E",
    names: ["DI-Leu16-IL2"],
  },
  {
    primary_id: "D0KX7A",
    names: ["CR-4174"],
  },
  {
    primary_id: "D0KX7I",
    names: ["Ensartinib"],
  },
  {
    primary_id: "D0KX8B",
    names: ["GNE Lipoplex"],
  },
  {
    primary_id: "D0KX8N",
    names: ["Diaryl Morpholine Derivative 1"],
  },
  {
    primary_id: "D0KX9B",
    names: [
      "EP-101",
      "106-86-5",
      "1,2-Epoxy-4-Vinylcyclohexane",
      "3-Vinyl-7-Oxabicyclo[4.1.0]heptane",
      "Epoxide 101",
      "4-Vinylcyclohexene Oxide",
      "Unoxat Epoxide 101",
      "Vinylcyclohexane Monoxide",
      "4-Vinyl-1,2-Epoxycyclohexane",
      "1-Vinyl-3,4-Epoxycyclohexane",
      "4-Vinylcyclohexene Monoxide",
      "3,4-Epoxycyclohexylethylene",
      "7-Oxabicyclo[4.1.0]heptane, 3-Ethenyl-",
      "4-Vinylcyclohexane Monoepoxide",
      "Vinylcyclohexene Monoxide",
      "4-Vinylcyclohexene-1,2-Epoxide",
      "4-Vinylcyclohexane, 1,2-Epoxide",
      "EINECS 203-436-1",
      "4-Vinyl-1-Cyclohexene 1,2-Epoxide",
      "NSC 35409",
    ],
  },
  {
    primary_id: "D0KX9Z",
    names: ["S-12363"],
  },
  {
    primary_id: "D0KY1Y",
    names: ["Benzimidazole Derivative 16"],
  },
  {
    primary_id: "D0KY4O",
    names: ["ASF-1075"],
  },
  {
    primary_id: "D0KY6Y",
    names: ["NanoPacpaclitaxel Nanoformulation"],
  },
  {
    primary_id: "D0KY7S",
    names: ["ISIS-FXI"],
  },
  {
    primary_id: "D0KY9B",
    names: [
      "5-Chloryl-2,4,6-Quinazolinetriamine",
      "2,4,6-Triamino-5-Chloroquinazoline",
      "17511-20-5",
      "CHEMBL6708",
      "CLZ",
      "AC1L47X8",
      "SCHEMBL1611666",
      "CTK4D5160",
      "DTXSID70169931",
      "MolPort-003-808-568",
      "JZWXVYNQIJJTKF-UHFFFAOYSA-N",
      "BDBM50404662",
      "5-Chloroquinazoline-2,4,6-Triamine",
      "ZINC13282329",
      "AKOS022649928",
      "2,4,6-Quinazolinetriamine,5-Chloro-",
      "2,4,6-Quinazolinetriamine, 5-Chloro-",
    ],
  },
  {
    primary_id: "D0KZ0V",
    names: ["CD19 CAR T Cells"],
  },
  {
    primary_id: "D0KZ2D",
    names: ["1-Valproylamido-5-Sulfonamidoindane", "CHEMBL204941", "Indanesulfonamide Derivative 11a", "BDBM11030"],
  },
  {
    primary_id: "D0KZ2I",
    names: ["AdoC(BetaAsp)2AlaArg6", "CHEMBL611127"],
  },
  {
    primary_id: "D0KZ3Q",
    names: ["Omnitram"],
  },
  {
    primary_id: "D0KZ5O",
    names: ["Bidentate Pyrazolopyrimidine Acetamide Analog 2"],
  },
  {
    primary_id: "D0KZ5Q",
    names: ["Imidazo Triazine Derivative 2"],
  },
  {
    primary_id: "D0KZ5T",
    names: ["Cyclopropylamine Derivative 6"],
  },
  {
    primary_id: "D0KZ6Q",
    names: ["PMID25435285-Compound-40"],
  },
  {
    primary_id: "D0KZ6T",
    names: ["PMID26560530-Compound-47"],
  },
  {
    primary_id: "D0KZ7A",
    names: [
      "3-(Aminomethyl)-4-(Furan-2-Yl)Butanoic Acid",
      "CHEMBL382876",
      "3-Aminomethyl-4-Furan-2-Yl-Butyric Acid",
      "311803-95-9",
      "SCHEMBL6375245",
      "BDBM50171795",
    ],
  },
  {
    primary_id: "D0L0AI",
    names: ["677T"],
  },
  {
    primary_id: "D0L0AU",
    names: ["DMPDDF"],
  },
  {
    primary_id: "D0L0AY",
    names: ["2-(Aminomethyl)-5-Phenethyltetrahydrofuran", "CHEMBL456639", "SCHEMBL2691411"],
  },
  {
    primary_id: "D0L0AZ",
    names: ["2-(2,4-Dichlorophenoxy)-5-Isobutylphenol"],
  },
  {
    primary_id: "D0L0BX",
    names: ["ICARITIN"],
  },
  {
    primary_id: "D0L0CD",
    names: ["Phenylpropylamine Derivative 2"],
  },
  {
    primary_id: "D0L0CL",
    names: ["CP-471358"],
  },
  {
    primary_id: "D0L0CV",
    names: ["Diamine Derivative 2"],
  },
  {
    primary_id: "D0L0DA",
    names: ["PRX003"],
  },
  {
    primary_id: "D0L0DC",
    names: [
      "Bromo-Deaza-SAH",
      "CHEMBL2349526",
      "(2s)-2-Amino-4-({[(2s,3s,4r,5r)-5-(4-Amino-5-Bromo-7h-Pyrrolo[2,3-D]pyrimidin-7-Yl)-3,4-Dihydroxytetrahydrofuran-2-Yl]methyl}sulfanyl)Butanoic Acid (Non-Preferred Name)",
      "GTPL7018",
      "BDBM50431676",
      "(S)-2-Amino-4-[[(2S)-3beta,4beta-Dihydroxy-5alpha-(4-Amino-5-Bromo-7H-Pyrrolo[2,3-D]pyrimidine-7-Yl)Tetrahydrofuran-2alpha-Yl]methylthio]butanoic Acid",
      "(2S)-2-Amino-4-[[(2S,3S,4R,5R)-5-(4-Amino-5-Bromopyrrolo[2,3-D]pyrimidin-7-Yl)-3,4-Dihydroxyoxolan-2-Yl]methylsulfanyl]butanoic Acid",
    ],
  },
  {
    primary_id: "D0L0DO",
    names: ["ETR-002"],
  },
  {
    primary_id: "D0L0DQ",
    names: ["PMID30247903-Compound-General Structure24"],
  },
  {
    primary_id: "D0L0DT",
    names: ["Pyrimidine Derivative 16"],
  },
  {
    primary_id: "D0L0DZ",
    names: ["XL-041"],
  },
  {
    primary_id: "D0L0ER",
    names: ["ALT-020-04"],
  },
  {
    primary_id: "D0L0FC",
    names: [
      "[1-Benzyl-1H-Quinolin-(4E)-Ylidene]-Hexyl-Amine",
      "CHEMBL56173",
      "BDBM50030521",
      "1-Benzyl N-Hexylquinolin-4(1H)-Imine",
    ],
  },
  {
    primary_id: "D0L0GM",
    names: [
      "Vilanterol",
      "503068-34-6",
      "GW642444x",
      "UNII-028LZY775B",
      "GW-642444x",
      "CHEBI:75037",
      "028LZY775B",
      "Vilanterol (GW642444",
      "GW 642444X)",
      "4-((1R)-2-((6-(2-((2,6-Dichlorophenyl)Methoxy)Ethoxy)Hexyl)Amino)-1-Hydroxyethyl)-2-(Hydroxymethyl)Phenol",
      "4-[(1R)-2-[(6-{2-[(2,6-Dichlorophenyl)Methoxy]ethoxy}hexyl)Amino]-1-Hydroxyethyl]-2-(Hydroxymethyl)Phenol",
      "Vilanterol [USAN:INN]",
      "Vilanterolum",
      "Vilanterol Base",
      "Vilanterol (USAN)",
      "SCHEMBL142630",
      "GTPL7353",
      "CHEMBL1198857",
      "DTXSID80198318",
      "DAFYYTQWSAWIGS-DEOSSOPVSA-N",
      "MolPort-044-560-195",
      "Relovair",
    ],
  },
  {
    primary_id: "D0L0HG",
    names: ["AC1LG8KT", "BAS 01816608", "TCS JNK 5a"],
  },
  {
    primary_id: "D0L0HV",
    names: ["VX-916"],
  },
  {
    primary_id: "D0L0IR",
    names: ["ISIS 102454"],
  },
  {
    primary_id: "D0L0JM",
    names: ["Igiv-Hb"],
  },
  {
    primary_id: "D0L0JX",
    names: ["ISIS 188782"],
  },
  {
    primary_id: "D0L0KI",
    names: ["NB-401", "Cystic Fibrosis Therapy (NanoStat, Topical Formulation), NanoBio"],
  },
  {
    primary_id: "D0L0KO",
    names: [
      "6-Bromo-N-M-Tolylquinazolin-4-Amine",
      "CHEMBL1081537",
      "AC1LEZQQ",
      "Oprea1_147022",
      "Neuro1_000540",
      "MLS000684595",
      "SCHEMBL4349199",
      "MolPort-000-660-263",
      "HMS3380E09",
      "HMS2596L23",
      "ZINC122234",
      "BDBM50311883",
      "AKOS002348862",
      "MCULE-2560701920",
      "SMR000268469",
      "ST50477342",
      "EU-0043981",
      "6-Bromo-N-(3-Methylphenyl)Quinazolin-4-Amine",
      "6-Bromo-N-(3-Methylphenyl)-4-Quinazolinamine",
      "(6-Bromoquinazolin-4-Yl)(3-Methylphenyl)Amine",
      "SR-01000485095",
    ],
  },
  {
    primary_id: "D0L0LR",
    names: ["UCL-67022"],
  },
  {
    primary_id: "D0L0MB",
    names: [
      "Acetophenazine",
      "Acephenazinum",
      "Acetophenazina",
      "Acetophenazinum",
      "Tindal",
      "Acetophenazine [INN]",
      "Acetophenazina [INN-Spanish]",
      "Acetophenazinum [INN-Latin]",
      "Ketone, 10-(3-(4-(2-Hydroxyethyl-1-Piperazinyl)Propyl)Phenothiazin-2-Yl Methyl",
      "1-(10-{3-[4-(2-Hydroxyethyl)Piperazin-1-Yl]propyl}-10H-Phenothiazin-2-Yl)Ethanone",
      "1-[10-[3-[4-(2-Hydroxyethyl)Piperazin-1-Yl]propyl]phenothiazin-2-Yl]ethanone",
      "10-(3-(4-(2-Hydroxyethyl)-1-Piperazinyl)Propyl)Phenothiazin-2-Yl Methyl Ketone",
      "2-Acetyl-10-[3-[4-(B-Hydroxyethyl)Piperazinyl]propyl]phenothiazine",
    ],
  },
  {
    primary_id: "D0L0MI",
    names: ["Imidazotriazinone Derivative 1"],
  },
  {
    primary_id: "D0L0MJ",
    names: [
      "Rockout",
      "3-(Pyridin-4-Yl)-1H-Indole",
      "3-(4-Pyridyl)Indole",
      "7272-84-6",
      "3-Pyridin-4-Yl-1H-Indole",
      "3-(4-Pyridyl)-1H-Indole",
      "Rho Kinase Inhibitor III, Rockout",
      "3-(4-Pyridinyl)-1H-Indole",
      "CHEMBL380071",
      "1H-Indole, 3-(4-Pyridinyl)-",
      "3-(4-Pyridyl)",
      "Rho Kinase Inhibitor III",
      "ChemDiv2_000332",
      "AC1LD92F",
      "Oprea1_292323",
      "Oprea1_060403",
      "CBDivE_015772",
      "MLS000029246",
      "SCHEMBL238277",
      "GTPL6032",
      "Cid_644354",
      "ZINC80555",
      "CHEBI:93984",
      "DTXSID90349379",
      "3-(PYRIDIN-4-YL)INDOLE",
      "MolPort-001-899-519",
      "HMS1369P02",
      "HMS3229K13",
      "HMS2280K08",
      "STL331403",
      "IN1125",
      "BDBM50182878",
    ],
  },
  {
    primary_id: "D0L0MK",
    names: ["Pachycarpine", "(+)-Sparteine"],
  },
  {
    primary_id: "D0L0MO",
    names: ["Satigrel", "E-5510"],
  },
  {
    primary_id: "D0L0MU",
    names: [
      "C-(5H-Dibenzo[a,d]cyclohepten-5-Yl)-Methylamine",
      "CHEMBL46619",
      "AC1L4MLR",
      "SCHEMBL11335219",
      "CTK7E4003",
      "BKZVRYQHIBSITO-UHFFFAOYSA-N",
      "ZINC3622666",
      "BDBM50097217",
      "5-Aminomethyl-5H-Dibenzo[a,d]cycloheptene",
      "5H-Dibenzo[a,d]cycloheptene-5-Methanamine",
    ],
  },
  {
    primary_id: "D0L0MZ",
    names: ["Bis(5-Acetoxybenzo[b]furan-2-Yl)Methanone", "CHEMBL226182"],
  },
  {
    primary_id: "D0L0NE",
    names: ["DPC-A78277"],
  },
  {
    primary_id: "D0L0NI",
    names: ["Dhp-C[D-Cys-Gly-Phe(PNO2)-D-Cys]NH2", "CHEMBL386043"],
  },
  {
    primary_id: "D0L0NY",
    names: ["6-Imidazol-1-Ylmethyl-8-Phenyl-Quinoline", "CHEMBL419416", "BDBM50092624"],
  },
  {
    primary_id: "D0L0OU",
    names: ["CLT-005", "CTL-005-NP", "STAT-3 Inhibitor (Inflammation, Angiogenesis), Charlesson"],
  },
  {
    primary_id: "D0L0QR",
    names: [
      "CHYMOSTATIN",
      "Chymostatin",
      "CHEMBL247767",
      "AC1L9E22",
      "MRXDGVXSWIXTQL-HYHFHBMOSA-N",
      "C11308",
      "9076-44-2",
      "SCHEMBL8259564",
      "BDBM87059",
      "CHEBI:510447",
      "C31H41N7O6",
      "BDBM222139",
      "LS-187745",
      "X6871",
      "FT-0623815",
      "(2S)-2-[[(1S)-2-[[(1S)-1-[[(1S)-1-Benzyl-2-Oxo-Ethyl]carbamoyl]-3-Methyl-Butyl]amino]-1-(2-Iminohexahydropyrimidin-4-Yl)-2-Oxo-Ethyl]carbamoylamino]-3-Phenyl-Propanoic Acid",
    ],
  },
  {
    primary_id: "D0L0RI",
    names: ["DSP-7888"],
  },
  {
    primary_id: "D0L0RK",
    names: ["4-Hydroxy-N-Phenyl-N-Propylbenzenesulfonamide", "CHEMBL205070"],
  },
  {
    primary_id: "D0L0RO",
    names: ["AE37 Peptide Vaccine"],
  },
  {
    primary_id: "D0L0RP",
    names: ["[Lys8(Alexa 488) ]PVA", "CHEMBL1161978"],
  },
  {
    primary_id: "D0L0RX",
    names: ["ABX-101", "Anticancer Antibody (1), Abxign"],
  },
  {
    primary_id: "D0L0SD",
    names: ["N,N'-(1',3'-Propylene)-Bis-(-)-Nor-MEP", "CHEMBL258468"],
  },
  {
    primary_id: "D0L0SF",
    names: ["LY320954"],
  },
  {
    primary_id: "D0L0SI",
    names: ["Galectin 2"],
  },
  {
    primary_id: "D0L0SP",
    names: [
      "Acrylamide",
      "ACRYLAMIDE",
      "2-Propenamide",
      "1979/6/1",
      "Prop-2-Enamide",
      "Propenamide",
      "Acrylic Amide",
      "Ethylenecarboxamide",
      "Akrylamid",
      "Acrylic Acid Amide",
      "Vinyl Amide",
      "2-Propeneamide",
      "Propeneamide",
      "Optimum",
      "Acrylagel",
      "Amresco Acryl-40",
      "Propenoic Acid Amide",
      "Poly(Acrylamide)",
      "Akrylamid [Czech]",
      "Ethylene Carboxamide",
      "RCRA Waste Number U007",
      "POLYACRYLAMIDE",
      "Amid Kyseliny Akrylove",
      "Acrylamide Solution",
      "Amide Propenoic Acid",
      "Porisutoron",
      "Polystoron",
      "Polystolon",
      "Amid Kyseliny Akrylove [Czech]",
      "CCRIS 7",
      "Flokonit E",
      "Aminogen PA",
      "Flygtol GB",
      "Acrylamide Monome",
    ],
  },
  {
    primary_id: "D0L0SW",
    names: [
      "Bentiromide",
      "Bentiromide Sodium",
      "41748-47-4",
      "N-Benzoyl-L-Tyrosyl-4-Aminobenzoic Acid Sodium Salt",
      "NCGC00164607-01",
      "EINECS 255-530-7",
      "DSSTox_CID_26476",
      "DSSTox_RID_81647",
      "DSSTox_GSID_46476",
      "DTXSID6046476",
      "CHEMBL3188891",
      "Tox21_112229",
      "AKOS024373587",
      "ACM41748474",
      "Sodium (S)-4-((2-(Benzoylamino)-3-(4-Hydroxyphenyl)-1-Oxopropyl)Amino)Benzoate",
      "CAS-41748-47-4",
      "FT-0771579",
      "ST51012404",
      "N-Benzoyl-L-Tyrosine P-Amidobenzoic Acid Sodium Salt",
      "Sodium (S)-4-(2-Benzamido-3-(4-Hydroxyphenyl)Propanamido)Benzoate",
      "N-Benzoyl-L-Tyrosine P-Amidobenzoic Acid So",
    ],
  },
  {
    primary_id: "D0L0UF",
    names: ["MEN-11467"],
  },
  {
    primary_id: "D0L0UV",
    names: ["ISIS 25551"],
  },
  {
    primary_id: "D0L0UY",
    names: ["Plantainoside D"],
  },
  {
    primary_id: "D0L0VU",
    names: ["MRE 2029F20"],
  },
  {
    primary_id: "D0L0VV",
    names: ["GUT-70", "Cyclin D1 Inhibitor (Cancer), Nippon"],
  },
  {
    primary_id: "D0L0WC",
    names: ["ISIS 188781"],
  },
  {
    primary_id: "D0L0XV",
    names: ["NP-05"],
  },
  {
    primary_id: "D0L0YA",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 19"],
  },
  {
    primary_id: "D0L0YF",
    names: [
      "Homo-AMPA",
      "CHEMBL107768",
      "HomoAMPA",
      "AC1N6KQF",
      "2-Amino-4-(3-Hydroxy-5-Methyl-Isoxazol-4-Yl)-Butyric Acid",
      "GTPL3359",
      "SCHEMBL3731488",
      "CTK8G0203",
      "HMS3267O13",
      "BDBM50052551",
      "NCGC00024946-02",
      "SR-01000597464",
      "SR-01000597464-1",
      "2-Amino-4-(5-Methyl-3-Oxo-1,2-Oxazol-4-Yl)Butanoic Acid",
      "2-Amino-4-(3-Hydroxy-5-Methylisoxazole-4-Yl)Butyric Acid",
      "2-Amino-4-(3-Hydroxy-5-Methylisoxazol-4-Yl)Butyric Acid",
      "A-Amino-2,3-Dihydro-5-Methyl-3-Oxo-4-Isoxazolebutanoic Acid",
      "2-Amino-4-(5-Methyl-3-Oxo-2,3-Dihydro-1,2-Oxazol-4-Yl)Butanoic Acid",
    ],
  },
  {
    primary_id: "D0L0YG",
    names: ["Sphingosine"],
  },
  {
    primary_id: "D0L0ZC",
    names: ["GSK1059615"],
  },
  {
    primary_id: "D0L0ZF",
    names: ["VESNARINONE"],
  },
  {
    primary_id: "D0L0ZH",
    names: ["TASPINE"],
  },
  {
    primary_id: "D0L0ZP",
    names: [
      "3-Methoxy-4-(Oxazol-5-Yl)Aniline",
      "198821-79-3",
      "3-METHOXY-4-(1,3-OXAZOL-5-YL)ANILINE",
      "Benzenamine, 3-Methoxy-4-(5-Oxazolyl)-",
      "3-Methoxy-4-(5-Oxazolyl)Aniline",
      "CHEMBL45870",
      "5-(4-Amino-2-Methoxyphenyl)Oxazole",
      "AK116113",
      "A1-00139",
      "SCHEMBL1353846",
      "CTK4E2632",
      "KS-00000NNJ",
      "DTXSID80572997",
      "MolPort-006-666-066",
      "KYCMMXMEXWSPCV-UHFFFAOYSA-N",
      "ZINC2518269",
      "FCH848314",
      "3-Methoxy-4-(Oxazol-5-Yl) Aniline",
      "BDBM50127715",
      "3-Methoxy-4-Oxazol-5-Yl-Phenylamine",
      "AKOS006278384",
      "CS-W005501",
      "MP-1879",
      "DS-2798",
      "3-Methoxy-4-(Oxazol-5-Yl)Benzenamine",
      "AJ-37081",
    ],
  },
  {
    primary_id: "D0L1AV",
    names: ["Trans-(1S(R),2S(R))-2-Hydroxycyclooctyl Nitrate", "CHEMBL556256", "BDBM50293661"],
  },
  {
    primary_id: "D0L1BS",
    names: ["PF-454583"],
  },
  {
    primary_id: "D0L1BW",
    names: ["SR95531"],
  },
  {
    primary_id: "D0L1BZ",
    names: ["PMID30247903-Compound-General Structure21"],
  },
  {
    primary_id: "D0L1CO",
    names: ["MAGE-A10 TCR"],
  },
  {
    primary_id: "D0L1CR",
    names: ["Opium (Class)"],
  },
  {
    primary_id: "D0L1CX",
    names: ["N-{4-[2-(3-Hydroxyphenyl)Ethyl]phenyl}phthalimide", "CHEMBL574107"],
  },
  {
    primary_id: "D0L1DL",
    names: [
      "Bydureon",
      "141758-74-9",
      "AC002993",
      "Bydureon Pen",
      "UNII-9P1872D4OL",
      "HSDB 7789",
      "Exenatide [USAN:INN:BAN:JAN]",
      "DA 3091",
      "SCHEMBL14634818",
      "MolPort-009-194-177",
      "9P1872D4OL",
      "AKOS015994651",
      "CS-1174",
      "HS-2012",
      "HY-13443",
      "Y-100006",
      "Exendin 3 (Heloderma Horridum), 2-Glycine-3-L-Glutamic Acid-",
      "335149-21-8",
      "286014-72-0",
    ],
  },
  {
    primary_id: "D0L1DP",
    names: ["ABT-089"],
  },
  {
    primary_id: "D0L1DR",
    names: ["Recombinant Human Iduronate-2-Sulfatase"],
  },
  {
    primary_id: "D0L1DU",
    names: ["HIF-1 Alpha Gene Therapy"],
  },
  {
    primary_id: "D0L1EI",
    names: ["4-[4-(Benzyloxy)Piperidino]butyl Benzoate", "CHEMBL228950", "SCHEMBL4028855"],
  },
  {
    primary_id: "D0L1EX",
    names: ["M2del11HAavir H5N1"],
  },
  {
    primary_id: "D0L1FN",
    names: [
      "Emfilermin",
      "AM-424",
      "Leukemia Inhibitory Factor, AMRAD",
      "RhLIF, AMRAD",
      "RhLIF, Serono",
      "Recombinant LIF Protein, AMRAD/Serono",
      "R-LIF, AMRAD/Serono",
    ],
  },
  {
    primary_id: "D0L1GD",
    names: ["MK-8353"],
  },
  {
    primary_id: "D0L1GF",
    names: ["7-Bromoindirubin-3-Oxime"],
  },
  {
    primary_id: "D0L1GI",
    names: [
      "ReS8-T Compounds",
      "Alzheimer Program (Tau-Mediated Cytotoxicity)",
      "Alzheimer Program (Tau-Mediated Cytotoxicity), ReMYND",
      "Tau Detoxifying Compounds (Alzheimer's), ReMYND",
      "Alzheimer Program (Tau-Mediated Cytotoxicity), ReMYND/ Roche",
      "ReS10-T Compounds (Alzheimer's), ReMYND",
      "ReS19-T Compounds (Alzheimer's), ReMYND",
      "ReS3-T Compounds (Alzheimer's), ReMYND",
      "ReS8-T Compounds (Alzheimer's), ReMYND",
    ],
  },
  {
    primary_id: "D0L1HK",
    names: ["TCN-032"],
  },
  {
    primary_id: "D0L1IB",
    names: ["(S)-3-(Azetidin-2-Ylmethoxy)-2-Fluoropyridine"],
  },
  {
    primary_id: "D0L1IH",
    names: [
      "A-79175",
      "CHEMBL90552",
      "UNII-25B75ID70W",
      "25B75ID70W",
      "AC1L43J2",
      "ZINC5333",
      "SCHEMBL6438420",
      "BDBM50369068",
      "141579-87-5",
      "1-[(2R)-4-[5-(4-Fluorophenoxy)Furan-2-Yl]but-3-Yn-2-Yl]-1-Hydroxyurea",
    ],
  },
  {
    primary_id: "D0L1IS",
    names: [
      "NOR-ROEFRACTINE",
      "CHEMBL512542",
      "(R)-Norroefractine",
      "AC1OEFB1",
      "ZINC3847479",
      "BDBM50259928",
      "(1R)-7-Methoxy-1-[(4-Methoxyphenyl)Methyl]-1,2,3,4-Tetrahydroisoquinolin-6-Ol",
    ],
  },
  {
    primary_id: "D0L1JE",
    names: ["CJC-1131", "CJC-1131-Albumin Bioconjugate", "DAC:GLP-1", "Glucagon-Like Peptide 1, ConjuChem"],
  },
  {
    primary_id: "D0L1JF",
    names: [
      "3-[4-Fluoro-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "CHEMBL176076",
      "AC1O705X",
      "BDBM8617",
      "SCHEMBL4221244",
      "(3-Pyridylmethylene)Indane 24b",
      "3-[(Z)-(4-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0L1JQ",
    names: ["PMID28454500-Compound-32"],
  },
  {
    primary_id: "D0L1JW",
    names: ["Hydrastine"],
  },
  {
    primary_id: "D0L1KX",
    names: [
      "CR-665",
      "CR665",
      "UNII-3BX9UG06GG",
      "3BX9UG06GG",
      "228546-92-7",
      "CR 665",
      "FE 200665",
      "FE-200665",
      "SCHEMBL4451321",
      "DTXSID30177395",
      "Jnj-38488502",
      "DB05155",
      "D-Argininamide, D-Phenylalanyl-D-Phenylalanyl-D-Norleucyl-N-(4-Pyridinylmethyl)-",
    ],
  },
  {
    primary_id: "D0L1LJ",
    names: ["Bervastatin"],
  },
  {
    primary_id: "D0L1LV",
    names: ["TGFK07AD"],
  },
  {
    primary_id: "D0L1ME",
    names: [
      "H-Phe-NH2",
      "5241-58-7",
      "Phenylalanine Amide",
      "(S)-2-Amino-3-Phenylpropanamide",
      "L-Phenylalanine Amide",
      "Phenylalaninamide",
      "(2S)-2-Amino-3-Phenylpropanamide",
      "Phe-NH2",
      "L-Phe-NH2",
      "Phenh2",
      "UNII-PV9T9B2S11",
      "CHEMBL350320",
      "PV9T9B2S11",
      "CHEBI:21371",
      "AK-46083",
      "Phenylalanylamide",
      "L-Phenylalanineamide",
      "Benzenepropanamide, Alpha-Amino-, (AlphaS)-",
      "DL-Phenylalanine Amide",
      "2-Amino-3-Phenyl-Propionamide",
      "Phenylalanine Imine",
      "(S)-Phenylalaninamide",
      "Benzenepropanamide, Alpha-Amino-, (S)-",
      "H-L-Phe-NH2",
      "AC1L9IEO",
      "SCHEMBL244302",
    ],
  },
  {
    primary_id: "D0L1MJ",
    names: ["C[Nle-Nle-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL204864", "BDBM50184360"],
  },
  {
    primary_id: "D0L1ND",
    names: ["PMID8709131C2a (+)", "BPQ-OH", "GTPL3102", "BDBM50052351"],
  },
  {
    primary_id: "D0L1NF",
    names: ["D-420720", "Dipeptidyl Peptidase Inhibitor (Diabetes), Development Centre For Biotechnology"],
  },
  {
    primary_id: "D0L1NO",
    names: ["PMID29671355-Compound-47a"],
  },
  {
    primary_id: "D0L1NQ",
    names: ["3-Phenylquinoline-8-Carboxamide", "CHEMBL450259", "BDBM50255264"],
  },
  {
    primary_id: "D0L1NS",
    names: [
      "2-(4-Benzyl-Piperazin-1-Yl)-Benzothiazole",
      "2-(4-Benzylpiperazin-1-Yl)-1,3-Benzothiazole",
      "CHEMBL282234",
      "2-(4-Benzylpiperazino)-1,3-Benzothiazole",
      "35463-75-3",
      "AC1LSALZ",
      "Oprea1_030694",
      "MLS001166194",
      "SCHEMBL7760900",
      "KS-00003DHQ",
      "MolPort-002-878-239",
      "HMS2852D05",
      "ZINC20405012",
      "BDBM50041381",
      "AKOS005101332",
      "MCULE-9582396226",
      "7P-339S",
      "1-(2-Benzothiazolyl)-4-Benzylpiperazine",
      "SMR000550026",
      "2-(4-Benzylpiperazin-1-Yl)Benzo[d]thiazole",
      "Z86230191",
    ],
  },
  {
    primary_id: "D0L1NU",
    names: ["PHA-666859"],
  },
  {
    primary_id: "D0L1OC",
    names: ["Anti-LT Alpha"],
  },
  {
    primary_id: "D0L1OF",
    names: ["HE-33"],
  },
  {
    primary_id: "D0L1OT",
    names: ["TroVax"],
  },
  {
    primary_id: "D0L1OY",
    names: ["ALCURONIUM", "Alcuronium"],
  },
  {
    primary_id: "D0L1PH",
    names: ["CB-5300"],
  },
  {
    primary_id: "D0L1PO",
    names: ["Alkyl Mannoside Derivative 4"],
  },
  {
    primary_id: "D0L1PP",
    names: ["PMID27998201-Compound-13"],
  },
  {
    primary_id: "D0L1PQ",
    names: [
      "3-(3-Pyridin-2-Yl-Pyrrol-1-Yl)-Benzonitrile",
      "CHEMBL361325",
      "SCHEMBL5100840",
      "VWIHRRSEESXDSS-UHFFFAOYSA-N",
      "ZINC13582313",
      "BDBM50151897",
    ],
  },
  {
    primary_id: "D0L1PW",
    names: ["R112"],
  },
  {
    primary_id: "D0L1QF",
    names: ["Corifollitropin Alfa"],
  },
  {
    primary_id: "D0L1RM",
    names: [
      "1-[(Prop-1-Ylamino)Ethyl]-1,1-Bisphosphonic Acid",
      "CHEMBL408745",
      "[2-(Propylamino)Ethane-1,1-Diyl]bis(Phosphonic Acid)",
      "SCHEMBL7074253",
      "BDBM50374757",
      "[2-(Propylamino)Ethylidene]bisphosphonic Acid",
      "[1-Phosphono-2-(Propylamino)Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0L1RZ",
    names: [
      "3-(4-Methoxyphenyl)Quinoxaline-5-Carboxamide",
      "CHEMBL519443",
      "Quinoxaline Analogue, 3e",
      "SCHEMBL7045177",
    ],
  },
  {
    primary_id: "D0L1SK",
    names: ["Tecentriqatezolizumab"],
  },
  {
    primary_id: "D0L1ST",
    names: [
      "1-(4-Chloro-Phenyl)-2-P-Tolyl-Ethane-1,2-Dione",
      "86508-29-4",
      "1-(4-Chlorophenyl)-2-(4-Methylphenyl)Ethane-1,2-Dione",
      "1-(4-Chlorophenyl)-2-(P-Tolyl)Ethane-1,2-Dione",
      "1-(4-Chlorophenyl)-2-(4-Methylphenyl)-Ethane-1,2-Dione",
      "1,2-Ethanedione,1-(4-Chlorophenyl)-2-(4-Methylphenyl)-",
      "AC1MCRLY",
      "Benzil-Based Compound, 16",
      "SCHEMBL2156105",
      "CHEMBL192252",
      "BDBM22738",
      "CTK5F6872",
      "DTXSID10380461",
      "ZINC2510245",
      "ZX-AT024772",
      "SBB102088",
      "1010AF",
      "AKOS025117220",
      "KB-147233",
      "FT-0605685",
      "I14-93818",
      "1-(4-Chlorophenyl)-2-(4-Methylphenyl)Ethan-1,2-Dione",
    ],
  },
  {
    primary_id: "D0L1SV",
    names: ["Cyclohexyl Carbamate Derivative 4"],
  },
  {
    primary_id: "D0L1TA",
    names: ["PMID26882240-Compound-1"],
  },
  {
    primary_id: "D0L1TB",
    names: ["NPS-4"],
  },
  {
    primary_id: "D0L1TT",
    names: ["Peptide Analog 17"],
  },
  {
    primary_id: "D0L1UA",
    names: [
      "N-(4-Hydroxybenzyl)Icosa-5,8,11,14-Tetraenamide",
      "CHEMBL434069",
      "BDBM50054470",
      "(5Z,8Z,11Z,14Z)-Icosa-5,8,11,14-Tetraenoic Acid 4-Hydroxy-Benzylamide",
    ],
  },
  {
    primary_id: "D0L1UH",
    names: ["ASP-0777"],
  },
  {
    primary_id: "D0L1UK",
    names: ["Acid-Activated Omeprazole"],
  },
  {
    primary_id: "D0L1VF",
    names: ["PH-10"],
  },
  {
    primary_id: "D0L1VI",
    names: [
      "AQ-13",
      "AQ-13 Dihydrochloride",
      "169815-40-1",
      "UNII-PKT9732S5K",
      "AQ 13",
      "PKT9732S5K",
      "DTXSID10168761",
      "(N1-(7-Chloroquinolin-4-Yl)-3-(N3,N3-Diethylamino)Propylamine) Dihydrochloride",
      "HY-100358",
      "CS-0018673",
      "1,3-Propanediamine, N'-(7-Chloro-4-Quinolinyl)-N,N-Diethyl-,     Dihydrochloride",
    ],
  },
  {
    primary_id: "D0L1VP",
    names: ["NX-CP105"],
  },
  {
    primary_id: "D0L1VR",
    names: ["ALT-946", "AGE Inhibitors (2nd Generation)", "AGE Inhibitors (2nd Generation), Alteon"],
  },
  {
    primary_id: "D0L1VT",
    names: ["Cyprodine"],
  },
  {
    primary_id: "D0L1WV",
    names: ["Kawain"],
  },
  {
    primary_id: "D0L1XR",
    names: ["Green Tea"],
  },
  {
    primary_id: "D0L1YA",
    names: [
      "(-)-Cercosporamide",
      "Cercosporamide",
      "131436-22-1",
      "CHEBI:78696",
      "(?)-Cercosporamide",
      "AC1L2Z33",
      "SCHEMBL12658718",
      "CTK8E7014",
      "MolPort-006-822-609",
      "GEWLYFZWVLXQME-MRXNPFEDSA-N",
      "ZINC5114136",
      "ZINC100098020",
      "AKOS024458192",
      "CS-4664",
      "HY-16982",
      "RT-011975",
      "J-006002",
      "(9aS)-8-Acetyl-1,3,7-Trihydroxy-9a-Methyl-9-Oxodibenzofuran-4-Carboxamide",
      "4-Dibenzofurancarboxamide, 8-Acetyl-9,9a-Dihydro-1,3,7-Trihydroxy-9a-Methyl-9-Oxo-, (S)-",
      "(9aS)-8-Acetyl-9,9a-Dihydro-1,3,7-Trihydroxy-9a-Methyl-9-Oxo-4-Dibenzofurancaboxamide",
    ],
  },
  {
    primary_id: "D0L1YC",
    names: [
      "1-DODECANOL",
      "Dodecyl Alcohol",
      "Dodecan-1-Ol",
      "Lauryl Alcohol",
      "Dodecanol",
      "112-53-8",
      "N-Dodecyl Alcohol",
      "Lauric Alcohol",
      "Laurinic Alcohol",
      "Undecyl Carbinol",
      "Dodecylalcohol",
      "Pisol",
      "1-Dodecyl Alcohol",
      "N-Dodecan-1-Ol",
      "Duodecyl Alcohol",
      "1-Hydroxydodecane",
      "Siponol L5",
      "Karukoru 20",
      "Siponol 25",
      "Lorol 7",
      "Lorol 5",
      "N-Dodecanol",
      "Lauryl 24",
      "Alcohol C-12",
      "Sipol L12",
      "Lorol 11",
      "Alfol 12",
      "Siponol L2",
      "Dytol J-68",
      "Cachalot L-50",
      "Cachalot L-90",
      "Lauroyl Alcohol",
      "N-Lauryl Alcohol",
      "C12 Alcohol",
      "Hainol 12SS",
      "Hydroxydodecane",
      "Conol 20P",
      "Lorol",
      "Conol 20PP",
      "Dodecyl Alcoho",
    ],
  },
  {
    primary_id: "D0L1YM",
    names: ["McN3377"],
  },
  {
    primary_id: "D0L1YR",
    names: ["Ara-LAMP-Vax"],
  },
  {
    primary_id: "D0L1YS",
    names: ["NE-033"],
  },
  {
    primary_id: "D0L1ZC",
    names: ["ISIS 4759"],
  },
  {
    primary_id: "D0L1ZD",
    names: ["ISIS 107791"],
  },
  {
    primary_id: "D0L1ZK",
    names: ["MDCO-216"],
  },
  {
    primary_id: "D0L1ZM",
    names: ["HF-0299"],
  },
  {
    primary_id: "D0L2AO",
    names: [
      "DIHYDROXANTHOHUMOL",
      "Dihydroxanthohumol",
      "Alpha,beta-Dihydroxanthohumol",
      "MLS000863606",
      "4,2',4'-Trihydroxy-6'-Methoxy-3'-Prenyldihydrochalcone",
      "CHEBI:66332",
      "SMR000440746",
      "A,b-Dihydroxanthohumol",
      "MEGxp0_001797",
      "CHEMBL510279",
      "SCHEMBL15704299",
      "BDBM76566",
      "ACon1_000059",
      "Cid_10450920",
      "HMS2267A19",
      "LMPK12120536",
      "1-[2,4-Dihydroxy-6-Methoxy-3-(3-Methylbut-2-Enyl)Phenyl]-3-(4-Hydroxyphenyl)Propan-1-One",
      "NCGC00168833-01",
      "BRD-K15951006-001-01-4",
      "2',4,4'-Trihydroxy-6'-Methoxy-3'-Prenyldihydrochalcone",
    ],
  },
  {
    primary_id: "D0L2AW",
    names: [
      "1-Hydroxyamine-2-Isobutylmalonic Acid",
      "1-HYDROXYAMINE-2-ISOBUTYLMALONIC ACID",
      "(2R)-2-(Hydroxycarbamoyl)-4-Methylpentanoic Acid",
      "AC1O43QE",
      "DB02326",
    ],
  },
  {
    primary_id: "D0L2BC",
    names: ["MEDI-534"],
  },
  {
    primary_id: "D0L2BH",
    names: ["S-34109"],
  },
  {
    primary_id: "D0L2BT",
    names: [
      "DMARDs",
      "DMARDs (Oral, Rheumatoid Arthritis)",
      "DMARDs (Oral, Rheumatoid Arthritis), Abbott",
      "Disease-Modifying Antirheumatic Drugs (Oral, Rheumatoid Arthritis), Abbott",
    ],
  },
  {
    primary_id: "D0L2CG",
    names: [
      "1-Benzyl-3-Thiazol-2-Yl-Thiourea",
      "Thiourea, N-(Phenylmethyl)-N'-2-Thiazolyl-",
      "51623-90-6",
      "BAS 00581141",
      "1-Benzyl-3-(1,3-Thiazol-2-Yl)Thiourea",
      "PETT Bz Deriv.",
      "PETT Analog 30",
      "AC1LDT48",
      "MLS001208035",
      "BDBM1923",
      "SCHEMBL5455503",
      "CHEMBL145897",
      "REGID_For_CID_700723",
      "CTK1G4420",
      "ZINC80959",
      "DTXSID90351457",
      "PDHMEDMWKUZUAB-UHFFFAOYSA-N",
      "MolPort-000-656-277",
      "N-(Benzyl)-N'-[2-Thiazolyl]thiourea",
      "AKOS000533873",
      "1-Benzyl-3-1,3-Thiazol-2-Ylthiourea",
      "MCULE-4774117095",
      "N-(Benzyl)-N'-[2-Thiazolyl] Thiourea",
      "SMR000514687",
    ],
  },
  {
    primary_id: "D0L2CI",
    names: ["Insulin Degludec"],
  },
  {
    primary_id: "D0L2CN",
    names: ["Fibroblast Growth Factor-18"],
  },
  {
    primary_id: "D0L2CT",
    names: [
      "H-Tyr-D-Ala-Gly-Phe-Pro-Leu-Trp-NH-3,5-Bzl(CF3)2",
      "CHEMBL438389",
      "BDBM21012",
      "C-Terminal Modified Bifunctional Peptide, 5",
    ],
  },
  {
    primary_id: "D0L2EQ",
    names: ["PMID25522065-Compound-7"],
  },
  {
    primary_id: "D0L2FR",
    names: ["KU-135"],
  },
  {
    primary_id: "D0L2GD",
    names: ["[Cu(CN)2]-"],
  },
  {
    primary_id: "D0L2GX",
    names: ["RG7342"],
  },
  {
    primary_id: "D0L2HK",
    names: ["5-Oxo-1-2-4-Oxadiazol Biphenyl", "Compound 1c [PMID: 22410249]"],
  },
  {
    primary_id: "D0L2IQ",
    names: ["Fasidotril"],
  },
  {
    primary_id: "D0L2JE",
    names: ["XmAb13676"],
  },
  {
    primary_id: "D0L2JM",
    names: ["Levomequitazine"],
  },
  {
    primary_id: "D0L2JS",
    names: [
      "(+/-)-Threo-N-(2-Methylfuran)Methylphenidate",
      "CHEMBL1254929",
      "BDBM50327129",
      "(AlphaR)-Alpha-[(2R)-1-(2-Furanylmethyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0L2KB",
    names: ["RO-145974"],
  },
  {
    primary_id: "D0L2KL",
    names: ["DNA/MVA HIV Vaccine"],
  },
  {
    primary_id: "D0L2KM",
    names: ["Quinoline Derivative 11"],
  },
  {
    primary_id: "D0L2KN",
    names: [
      "2-Methyl-4-(3-Thienylethynyl)Thiazole",
      "CHEMBL202610",
      "Thiazole, 2-Methyl-4-[2-(3-Thienyl)Ethynyl]-",
      "SCHEMBL4144709",
      "BDBM50181788",
    ],
  },
  {
    primary_id: "D0L2KO",
    names: ["Azaphenylalanine Derivative"],
  },
  {
    primary_id: "D0L2LF",
    names: ["PMID27977313-Compound-42"],
  },
  {
    primary_id: "D0L2LG",
    names: ["WC-3018", "Antibiotic (Topical, Acne), Warner Chilcott"],
  },
  {
    primary_id: "D0L2LQ",
    names: ["Allylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL451174", "SCHEMBL4563341"],
  },
  {
    primary_id: "D0L2LS",
    names: [
      "Fluoxymesterone",
      "Androfluorene",
      "Androfluorone",
      "Androsterolo",
      "Fluossimesterone",
      "Fluosterone",
      "Fluotestin",
      "Fluoximesteron",
      "Fluoximesterona",
      "Fluoximesterone",
      "Fluoximesteronum",
      "Fluoxymesteronum",
      "Fluoxymestrone",
      "Flusteron",
      "Flutestos",
      "Halotestin",
      "Oralsterone",
      "Oratestin",
      "Testoral",
      "Ultandren",
      "Ultandrene",
      "Component Of Halodrin",
      "Fluossimesterone [DCIT]",
      "Ora Testryl",
      "U 6040",
      "Anadroid-F",
      "Android-F",
      "Fluoximesterona [INN-Spanish]",
      "Fluoxymesteronum [INN-Latin]",
      "Halotestin (TN)",
      "Neo-Ormonal",
      "Ora-Testryl",
      "Fluoxymesterone [INN:BAN:JAN]",
      "Fluoxymesterone (JP15/USP/INN)",
      "Fluoro-9-Alpha Dihydroxy-11-Beta,17-Beta Methyl-17-Alpha Androstene-4 One-3",
      "Fluoro-9-Alpha Dihydroxy-11-Beta,17-Beta Methyl-17-Alpha Androstene-4 One-3 [French]",
      "Androst-4-En-3-One, 9-Fluoro-11beta,17beta-Dihydroxy-17-Methyl-(VAN)",
      "Androst-4-En-3-One, 9-Fluoro-11beta,17beta-Dihydroxy-17-Methyl-(VAN) (8CI)",
      "(11beta,17beta)-9-Fluoro-11,17-Dihydroxy-17-Methylandrost-4-En-3-One",
      "(8S,9R,10S,11S,13S,14S,17S)-9-Fluoro-11,17-Dihydroxy-10,13,17-Trimethyl-1,2,6,7,8,11,12,14,15,16-Decahydrocyclopenta[a]phenanthren-3-One",
      "11-Beta,17-Beta-Dihydroxy-9-Alpha-Fluoro-17-Alpha-Methyl-4-Androster-3-One",
      "11beta,17beta-Dihydroxy-9alpha-Fluoro-17alpha-Methyl-4-Androsten-3-One",
      "11beta,17beta-Dihydroxy-9alpha-Fluoro-17alpha-Methyl-4-Androster-3-One",
      "17-Alpha-Methyl-9-Alpha-Fluoro-11-Beta-Hydroxytesterone",
      "17alpha-Methyl-9alpha-Fluoro-11beta-Hydroxytesterone",
      "9-Fluoro-11-Beta,17-Beta-Dihydroxy-17-Methylandrost-4-En-3-One",
      "9-Fluoro-11beta,17beta-Dihydroxy-17-Methylandrost-4-En-3-One",
      "9-Alpha-Fluoro-11-Beta,17-Beta-Dihydroxy-17-Alpha-Methyl-4-Androstene-3-One",
      "9-Alpha-Fluoro-11-Beta-Hydroxy-17-Methyltestosterone",
      "9-Alpha-Fluoro-17-Alpha-Methyl-11-Beta,17-Dihydroxy-4-Androsten-3-One;9-Fluoro-11beta,17beta-Dihydroxy-17alpha-Methylandrost-4-En-3-One",
      "9.alpha.-Fluoro-11.beta.,17.beta.-Dihydroxy-17.alpha.-Methyl-4-Androstene-3-One",
      "9.alpha.-Fluoro-11.beta.-Hydroxy-17-Methyltestosterone",
      "9alpha-Fluoro-11beta,17beta-Dihydroxy-17alpha-Methyl-4-Androstene-3-One",
      "9alpha-Fluoro-11beta,17beta-Dihydroxy-17alpha-Methylandrost-4-En-3-One",
      "9alpha-Fluoro-11beta-Hydroxy-17-Methyltestosterone",
      "9alpha-Fluoro-11beta-Hydroxy-17alpha-Methyltestosterone",
      "9alpha-Fluoro-17alpha-Methyl-11beta,17-Dihydroxy-4-Androsten-3-One",
    ],
  },
  {
    primary_id: "D0L2MA",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 12"],
  },
  {
    primary_id: "D0L2MN",
    names: ["2-(4-Chlorophenyl)-6-Phenyl-9H-Purine", "CHEMBL205586"],
  },
  {
    primary_id: "D0L2MS",
    names: ["PMID25991433-Compound-P1"],
  },
  {
    primary_id: "D0L2MU",
    names: [
      "7-Bromo-2-(4-Hydroxy-Phenyl)-Benzofuran-5-Ol",
      "CHEMBL183782",
      "SCHEMBL3927964",
      "UBYHIUUMRHTEFF-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0L2MX",
    names: [
      "Benzthiazide",
      "Aquapres",
      "Aquasec",
      "Aquatag",
      "Benzothiazide",
      "Benzotiazida",
      "Benzthazide",
      "Benzthiazidum",
      "Benztiazide",
      "Dihydrex",
      "Diucen;Diucene",
      "Edemex",
      "Exna",
      "Exosalt",
      "Fouane",
      "Fovane",
      "Freeuril",
      "HyDrine",
      "Lemazide",
      "Proaqua",
      "Regulon",
      "Urazide",
      "Urese",
      "Benztiazide [DCIT]",
      "P 1393",
      "Pfizer 1393",
      "Aqua-Scrip",
      "Aquatag (TN)",
      "Benzotiazida [INN-Spanish]",
      "Benzthiazidum [INN-Latin]",
      "Dihydrex (TN)",
      "Diucen (TN)",
      "Edemax (TN)",
      "Exna (TN)",
      "Foven (TN)",
      "Hy-Drine",
      "Pro-Aqua",
      "Rid-Ema",
      "S-Aqua",
      "Benzthiazide (JAN/INN)",
      "Benzthiazide [USAN:INN:BAN]",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3-((Benzylthio)Menthyl)-6-Chloro-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 3-((Benzylthio)Methyl)-6-Chloro-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3-(((Phenylmethyl)Thio)Methyl)-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3-[[(Phenylmethyl)Thio]methyl]-, 1,1-Dioxide",
      "3-((Benzylthio)Methyl)-6-Chloro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3-((Benzylthio)Methyl)-6-Chloro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide1,1-Dioxide",
      "3-((Benzylthio)Methyl)-6-Chloro-7-Sulfamoyl-2H-Benzo-1,2,4-Thiadiazine 1,1-Dioxide",
      "3-((Benzylthio)Methyl)-6-Chloro-7-Sulfamoyl-2H-Benzo-1,2,4-Thiadiazine-1,1-Dioxide",
      "3-(Benzylsulfanylmethyl)-6-Chloro-1,1-Dioxo-4H-1",
      "3-Benzylthiomethyl-6-Chloro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "3-Benzylthiomethyl-6-Chloro-7-Sulfamoyl-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "3-[(Benzylsulfanyl)Methyl]-6-Chloro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3-(((Phenylmethyl)Thio)Methyl)-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-7-Sulfamoyl-3-Benzylthiomethyl-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-Chloro-1,1-Dioxo-3-(Phenylmethylsulfanylmethyl)-4H-Benzo[e][1,2,4]thiadiazine-7-Sulfonamide",
      "6-Chloro-3-{[(Phenylmethyl)Thio]methyl}-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D0L2NG",
    names: ["4-Aryl Quinol Derivative 1"],
  },
  {
    primary_id: "D0L2NV",
    names: ["U-89854"],
  },
  {
    primary_id: "D0L2OS",
    names: ["REG-3"],
  },
  {
    primary_id: "D0L2OT",
    names: [
      "Lipovaxin-MM",
      "Dendritic Cell-Specific Antigen Targeting Vaccine (Liposome/Nanoparticle/Intravenous, Melanoma), Lipotek",
    ],
  },
  {
    primary_id: "D0L2PQ",
    names: ["Cypate-[(RGD)3-NH2]1"],
  },
  {
    primary_id: "D0L2PV",
    names: ["MOR-105"],
  },
  {
    primary_id: "D0L2QC",
    names: ["OPT-88"],
  },
  {
    primary_id: "D0L2QD",
    names: ["1-(4-Cyanobenzyl)-5-Bromo-1H-Imidazole", "CHEMBL599223", "SCHEMBL3829392", "BDBM50307238"],
  },
  {
    primary_id: "D0L2QJ",
    names: ["UC-84"],
  },
  {
    primary_id: "D0L2QZ",
    names: ["Biphenyl Mannoside Derivative 27"],
  },
  {
    primary_id: "D0L2RV",
    names: ["PF-4325667"],
  },
  {
    primary_id: "D0L2TT",
    names: ["DasKloster 0028-01", "DasKloster 0228-01"],
  },
  {
    primary_id: "D0L2UN",
    names: ["Auranofin"],
  },
  {
    primary_id: "D0L2UQ",
    names: ["Pracinostat"],
  },
  {
    primary_id: "D0L2VB",
    names: ["FE-203799"],
  },
  {
    primary_id: "D0L2VI",
    names: ["4-(3,5-Dichlorobenzylthio)-2-Aminobutanoic Acid", "CHEMBL204818"],
  },
  {
    primary_id: "D0L2VT",
    names: [
      "PV-10",
      "632-69-9",
      "Rose Bengal Sodium",
      "Rose Bengal Disodium Salt",
      "R105 Sodium",
      "Rose-Bengal (131 I) Natrium",
      "Food Red No 105, Sodium Salt",
      "EINECS 211-183-3",
      "Food Red Color No 105, Sodium Salt",
      "Sel Disodique De Rose Bengale Iodee (131 I)",
      "Rose Bengale (131 I) Sodique [INN-French]",
      "Rosa Bengala Sodica (131 I) [INN-Spanish]",
      "Roseum Bengalense (131 I) Natricum [INN-Latin]",
      "2,4,5,7-Tetraido(M,p,o',m')Tetrachlorofluorescein, Disodium Salt",
      "Fluorescein, 4,5,6,7-Tetrachloro-2',4',5',7'-Tetraiodo-, Disodium Salt",
      "Disodium",
    ],
  },
  {
    primary_id: "D0L2VV",
    names: ["Typhim Vi"],
  },
  {
    primary_id: "D0L2WJ",
    names: ["Ryzodeg"],
  },
  {
    primary_id: "D0L2WK",
    names: ["CAM2043"],
  },
  {
    primary_id: "D0L2WP",
    names: ["PMID20005102C1"],
  },
  {
    primary_id: "D0L2WW",
    names: ["RGel-Adnectin"],
  },
  {
    primary_id: "D0L2XO",
    names: [
      "BAY11-7082",
      "Bay 11-7082",
      "19542-67-7",
      "(E)-3-Tosylacrylonitrile",
      "Bay 11-7821",
      "(E)-3-(P-Toluenesulfonyl)Acrylonitrile",
      "BAY-11-7082",
      "UNII-4Y5G2A4F6O",
      "BAY-11-7821",
      "(E)-3-(4-Methylphenyl)Sulfonylprop-2-Enenitrile",
      "BAY-117082",
      "BAY-117821",
      "CHEMBL403183",
      "4Y5G2A4F6O",
      "3-(4-Methylphenylsulfonyl)-2-Propenenitrile",
      "CHEBI:85928",
      "3-[(4-Methylphenyl)Sulfonyl]prop-2-Enenitrile",
      "AK129348",
      "(E)-3-(4-Methylphenylsulfonyl)-2-Propenenitrile",
      "(E)3-[(4-Methylphenyl)Sulfonyl]-2-Propenenitrile",
      "J-501956",
      "(2E)-3-[(4-Methylphenyl)Sulfonyl]prop-2-Enenitrile",
    ],
  },
  {
    primary_id: "D0L2XU",
    names: ["Solulin"],
  },
  {
    primary_id: "D0L2XV",
    names: ["PMID28766366-Compound-Scheme18"],
  },
  {
    primary_id: "D0L2YA",
    names: ["TVX-009"],
  },
  {
    primary_id: "D0L2YF",
    names: ["Pc4 (Topical Formulation"],
  },
  {
    primary_id: "D0L2YR",
    names: [
      "DT-1687",
      "DT-1386",
      "MGluR4 PAM (Neurological Disease/Psychiatric Disorder), Domain Therapeutics",
      "MGluR4 PAM (Neurological Disease/Psychiatric Disorder), Merck Serono",
    ],
  },
  {
    primary_id: "D0L2ZY",
    names: ["X-82"],
  },
  {
    primary_id: "D0L3AJ",
    names: [
      "8-Methoxy-1,4-Dimethylquinolin-2(1H)-One",
      "8-Methoxy-1,4-Dimethyl-1h-Quinolin-2-One",
      "63791-96-8",
      "Casimiroin Analogue, 1f",
      "AC1NT5XU",
      "CHEMBL506858",
      "SCHEMBL17509827",
      "BDBM29217",
      "NCNFIHNRVBJRPR-UHFFFAOYSA-N",
      "MolPort-028-745-487",
      "ZINC33835729",
      "8-Methoxy-1,4-Dimethylquinolin-2-One",
      "1,4-Dimethyl-8-Methoxyquinoline-2(1H)-One",
    ],
  },
  {
    primary_id: "D0L3AP",
    names: ["DwFwLL-NH2", "CHEMBL449590"],
  },
  {
    primary_id: "D0L3AR",
    names: ["PD156707"],
  },
  {
    primary_id: "D0L3AX",
    names: [
      "4-Naphthalen-2-Yl-Phenol",
      "4-(Naphthalen-2-Yl)Phenol",
      "6336-82-9",
      "4-(2-Naphthyl)Phenol",
      "4-Naphthalen-2-Ylphenol",
      "CHEMBL194772",
      "NSC-39892",
      "2-(4-HYDROXYPHENYL)NAPHTHALENE",
      "NSC39892",
      "AC1L5XK9",
      "AC1Q7B9I",
      "SCHEMBL234529",
      "KS-00000ORV",
      "CTK5B8841",
      "DTXSID70284931",
      "NIRHUNSXEDESLN-UHFFFAOYSA-N",
      "MolPort-015-145-553",
      "ZINC1671629",
      "MFCD18312886",
      "KM0737",
      "BDBM50168385",
      "ANW-73541",
      "AKOS016007751",
      "IMED1575423883",
      "KB-40006",
      "AK-50357",
      "AJ-29221",
      "TC-162286",
      "AX8212912",
      "4CH-024303",
    ],
  },
  {
    primary_id: "D0L3BE",
    names: ["Hexahydro Naphthalene Derivative 1"],
  },
  {
    primary_id: "D0L3BI",
    names: ["MRS1084", "4-(Propyloxy)-7-Trans-Styrylvisnagin"],
  },
  {
    primary_id: "D0L3BQ",
    names: [
      "N-Hydroxy-4-Isobutyl-Benzamide",
      "CHEMBL439659",
      "SCHEMBL6919015",
      "BDBM50015175",
      "4-(2-Methylpropyl)Benzohydroximic Acid",
    ],
  },
  {
    primary_id: "D0L3CQ",
    names: ["Dexpirronium", "Anticholinergic Compound (COPD), Meda"],
  },
  {
    primary_id: "D0L3CU",
    names: ["PMID28092474-Compound-33c"],
  },
  {
    primary_id: "D0L3DC",
    names: ["HMR-1031"],
  },
  {
    primary_id: "D0L3DF",
    names: ["9-(4-Fluoro-Benzyl)-6-Phenylsulfanyl-9H-Purine", "CHEMBL176245"],
  },
  {
    primary_id: "D0L3DK",
    names: ["Vildagliptin"],
  },
  {
    primary_id: "D0L3DM",
    names: ["Tyr-Pro-D-Phe-D-Pro-NH2"],
  },
  {
    primary_id: "D0L3EE",
    names: [
      "(10H-Phenothiazin-10-Yl)(Phenyl)Methanone",
      "10h-Phenothiazin-10-Yl(Phenyl)Methanone",
      "CHEMBL239400",
      "Phenothiazin-10-Yl(Phenyl)Methanone",
      "10-BENZOYL-10H-PHENOTHIAZINE",
      "38076-73-2",
      "NSC95774",
      "10-Benzoylphenothiazine",
      "AC1Q5K0F",
      "Oprea1_507390",
      "Oprea1_637573",
      "SCHEMBL343497",
      "AC1L678I",
      "CTK4H9286",
      "Phenyl Phenothiazin-10-Yl Ketone",
      "DTXSID00294306",
      "MolPort-000-182-685",
      "ZINC402121",
      "NSC-95774",
      "BDBM50219217",
      "STL371969",
      "AKOS000279791",
      "MCULE-2324671928",
    ],
  },
  {
    primary_id: "D0L3EO",
    names: [
      "Cinitapride",
      "66564-14-5",
      "Cidine",
      "Cinitapride Tartrate",
      "Blaston",
      "Cinitapride [INN]",
      "C21H30N4O4",
      "Cinitapride Hydrogen Tartrate",
      "Cinitapride (INN)",
      "4-Amino-N-[1-(Cyclohex-3-En-1-Ylmethyl)Piperidin-4-Yl]-2-Ethoxy-5-Nitrobenzamide",
      "Cinitapridum",
      "Cinitaprida",
      "Paxapride",
      "Cinitapridum [INN-Latin]",
      "Cinitaprida [INN-Spanish]",
      "Cidin",
      "Cintapro",
      "Cinmove",
      "UNII-R8I97I2L24",
      "Paxapride (TN)",
      "AC1Q1YMD",
      "AC1L2AM4",
      "(Non-Labelled)Cinitapride-D5",
      "SCHEMBL476454",
      "SCHEMBL19235643",
      "CHEMBL2104523",
      "CHEBI:135642",
      "R8I97I2L24",
      "BCP04096",
      "AKOS015909742",
      "DB08810",
    ],
  },
  {
    primary_id: "D0L3EP",
    names: ["GSK1614343"],
  },
  {
    primary_id: "D0L3EW",
    names: ["ICI-D-6888"],
  },
  {
    primary_id: "D0L3FK",
    names: ["SU9516"],
  },
  {
    primary_id: "D0L3GG",
    names: ["GEM-132"],
  },
  {
    primary_id: "D0L3GH",
    names: ["CTCE-0324"],
  },
  {
    primary_id: "D0L3GT",
    names: [
      "Chroman 28",
      "CHEMBL415848",
      "GTPL664",
      "SCHEMBL4136892",
      "BDBM50203200",
      "(R)-3-(Naphthalene-7-Sulfonamido)-3-Phenyl-N-((R)-7-(Piperidin-1-Ylmethyl)-3,4-Dihydro-2H-Chromen-4-Yl)Propanamide",
      "(3R)-3-(Naphthalen-2-Ylsulfonylamino)-3-Phenyl-N-[(4R)-7-(Piperidin-1-Ylmethyl)Chroman-4-Yl]propanamide",
    ],
  },
  {
    primary_id: "D0L3HO",
    names: ["L-Trans-2,4-Pyrolidine Dicarboxylate"],
  },
  {
    primary_id: "D0L3HU",
    names: ["BAY 86-5044"],
  },
  {
    primary_id: "D0L3IL",
    names: ["ASM8"],
  },
  {
    primary_id: "D0L3IQ",
    names: [
      "ABYSSINONE V",
      "5,7-Dihydroxy-2-[4-Hydroxy-3,5-Bis(3-Methylbut-2-Enyl)Phenyl]-2,3-Dihydrochromen-4-One",
      "NSC618154",
      "NSC 618154",
      "AC1L2QBU",
      "CHEMBL454332",
      "CTK5E4111",
      "NSC-618154",
      "5,7,4'-Trihydroxy-3',5'-Diprenylflavanone",
      "4H-1-Benzopyran-4-One,3-Dihydro-5,7-Dihydroxy-2-[4-Hydroxy-3,5-Bis(3-Methyl-2-Butenyl)Phenyl]-, (S)-",
      "4H-1-Benzopyran-4-One,2,3-Dihydro-5,7-Dihydroxy-2-[4-Hydroxy-3,5-Bis(3-Methyl-2-Buten-1-Yl)Phenyl]-,(2S)-",
      "4H-Benzopyran-4-One, 2,3-Dihydro-5,7-Dihydroxy-2-(4-Hydroxy-3,5-Bis(3-Methyl-2-Butenyl)Phenyl)-, (S)-",
    ],
  },
  {
    primary_id: "D0L3IS",
    names: [
      "1-ETHOXY-2-(2-ETHOXYETHOXY)ETHANE",
      "2-Ethoxyethyl Ether",
      "DIETHYLENE GLYCOL DIETHYL ETHER",
      "112-36-7",
      "Diethyl Carbitol",
      "1-Ethoxy-2-(2-Ethoxyethoxy)Ethane",
      "Ethyl Diglyme",
      "Bis(2-Ethoxyethyl) Ether",
      "Diethyldiethylene Glycol",
      "3,6,9-Trioxaundecane",
      "DEGDEE",
      "Ether, Bis(2-Ethoxyethyl)",
      "Ethane, 1,1'-Oxybis[2-Ethoxy-",
      "2-(2-Ethoxyethoxy)-1-Ethoxyethane",
      "Diethylether Diethylenglykolu",
      "HSDB 68",
      "UNII-ZH086O935Z",
      "Ethanol, 2,2'-Oxybis-, Diethyl Ether",
      "Ethane, 1,1'-Oxybis(2-Ethoxy-",
      "Glycol, Diethylene-, Diethyl Ether",
      "EINECS 203-963-7",
      "Diethylether Diethylenglykolu [Czech]",
    ],
  },
  {
    primary_id: "D0L3JC",
    names: ["FGGFTGARKSARKRANQ"],
  },
  {
    primary_id: "D0L3JI",
    names: [
      "NGD 94-1",
      "2-[4-[(2-Phenyl-1H-Imidazol-5-Yl)Methyl]piperazin-1-Yl]pyrimidine",
      "Ngd 94-1",
      "178928-68-2",
      "UNII-DO9M06BFRJ",
      "Ngd-94-1",
      "DO9M06BFRJ",
      "CHEMBL103772",
      "179333-18-7",
      "2-[4-[(2-Phenyl-1H-Imidazol-5-Yl)Methyl]-1-Piperazinyl]-Pyrimidine",
      "2-[4-[(2-Phenyl-3H-Imidazol-4-Yl)Methyl]piperazin-1-Yl]pyrimidine",
      "[3H]NGD941",
      "[3H]-NGD941",
      "[3H]NGD 94-1",
      "AC1L4NQX",
      "Ngd94-1",
      "2-{4-[(2-Phenyl-1h-Imidazol-4-Yl)Methyl]piperazin-1-Yl}pyrimidine",
      "2-Phenyl-4(5)-((4-(2-Pyrimidinyl)-Piperazin-1-Yl)Methyl)Imidazole Dimaleate",
      "AC1Q4X5G",
      "GTPL979",
      "SCHEMBL6901339",
      "Diphenylbutylpiperidines Pimozide",
    ],
  },
  {
    primary_id: "D0L3JL",
    names: ["Aryl Mannoside Derivative 8"],
  },
  {
    primary_id: "D0L3JR",
    names: ["MG-516"],
  },
  {
    primary_id: "D0L3KA",
    names: ["BP-100-1-01"],
  },
  {
    primary_id: "D0L3KN",
    names: ["NeurArrest"],
  },
  {
    primary_id: "D0L3KR",
    names: ["2,2',4,4'-Tetrahydroxy-6'-Methoxychalcone", "CHEMBL244248", "BDBM50203987"],
  },
  {
    primary_id: "D0L3LO",
    names: ["ET-006"],
  },
  {
    primary_id: "D0L3MJ",
    names: ["SKF-83959"],
  },
  {
    primary_id: "D0L3MP",
    names: ["VRX496"],
  },
  {
    primary_id: "D0L3MZ",
    names: ["Heptazyme"],
  },
  {
    primary_id: "D0L3NU",
    names: ["ISIS 14548"],
  },
  {
    primary_id: "D0L3OE",
    names: ["SRSS-021", "Anti-Emetic, Sirus"],
  },
  {
    primary_id: "D0L3PO",
    names: [
      "2-(4-Fluoro-Benzyl)Isoquinoline-1,3,4-Trione",
      "CHEMBL203552",
      "SCHEMBL13442861",
      "Isoquinoline-1,3,4-Trione 2f",
      "CTK7C0409",
      "BDBM10253",
      "AKOS015966191",
      "2-(4-FLUORO-BENZYL)-ISOQUINOLINE-1,3,4-TRIONE",
    ],
  },
  {
    primary_id: "D0L3PY",
    names: ["Angiotensin IV"],
  },
  {
    primary_id: "D0L3RN",
    names: ["Epoxysuccinate Derivative 5"],
  },
  {
    primary_id: "D0L3RO",
    names: ["TAS-119"],
  },
  {
    primary_id: "D0L3RY",
    names: [
      "3-Methoxy-N-(4-Methylthiazol-2-Yl)Benzamide",
      "CHEMBL211740",
      "477516-34-0",
      "3-Methoxy-N-(4-Methyl-1,3-Thiazol-2-Yl)Benzamide",
      "SMR000155061",
      "AC1MAGB1",
      "Oprea1_281072",
      "Oprea1_273858",
      "MLS000569675",
      "CTK4J0236",
      "Cid_2679292",
      "DTXSID80369021",
      "MolPort-000-631-036",
      "CHEBI:114888",
      "HMS2341M09",
      "ZINC3562014",
      "BDBM50186332",
      "AKOS001313290",
      "MCULE-7375167923",
      "KB-288021",
      "BENZAMIDE, 3-METHOXY-N-(4-METHYL-2-THIAZOLYL)-",
    ],
  },
  {
    primary_id: "D0L3RZ",
    names: ["SBC-103"],
  },
  {
    primary_id: "D0L3SA",
    names: ["T2328"],
  },
  {
    primary_id: "D0L3SN",
    names: ["4-(2-Nitrovinyl)Benzene-1,2-Diol"],
  },
  {
    primary_id: "D0L3SP",
    names: ["MVA-BN Filo"],
  },
  {
    primary_id: "D0L3TR",
    names: ["Estetrol"],
  },
  {
    primary_id: "D0L3TX",
    names: [
      "4-Piperidin-1-Yl-Chromen-2-One",
      "CHEMBL176114",
      "4-Piperidinocoumarin",
      "AC1LEH6J",
      "4-Piperidylchromen-2-One",
      "Oprea1_207466",
      "Oprea1_800231",
      "MLS000711082",
      "4-Piperidin-1-Ylchromen-2-One",
      "ZINC53303",
      "HMS2627L16",
      "BDBM50159626",
      "AKOS003620982",
      "MCULE-1716733202",
      "4-(1-Piperidinyl)-2H-Chromen-2-One",
      "BAS 01052429",
      "SMR000280849",
      "ST50993947",
      "EU-0085678",
      "SR-01000527828",
    ],
  },
  {
    primary_id: "D0L3UI",
    names: ["Rabies VRVg"],
  },
  {
    primary_id: "D0L3VA",
    names: ["QAN-747"],
  },
  {
    primary_id: "D0L3VC",
    names: [
      "DG-8",
      "DH-9",
      "PPAR Gamma Agonists (Osteosarcoma/ADPKD)",
      "PPAR Gamma Agonists (Osteosarcoma/ADPKD), Chinese Academy Of Sciences",
    ],
  },
  {
    primary_id: "D0L3VT",
    names: ["Myoblast Cell Transplantation Therapy"],
  },
  {
    primary_id: "D0L3VU",
    names: ["Pumactant"],
  },
  {
    primary_id: "D0L3VV",
    names: ["NSC-339583"],
  },
  {
    primary_id: "D0L3VY",
    names: [
      "Karomab",
      "Anticancer MAb (Colorectal Cancer), NEMOD",
      "Anticancer MAb (Cancer), Glycotope",
      "GT-Mab 3.2 GEX",
    ],
  },
  {
    primary_id: "D0L3WJ",
    names: ["IRT-103"],
  },
  {
    primary_id: "D0L3XE",
    names: ["Short Ragweed Pollen Extracts"],
  },
  {
    primary_id: "D0L3XN",
    names: ["Cardiac PET Imaging Agent"],
  },
  {
    primary_id: "D0L3XU",
    names: [
      "(S)-UH-301",
      "5-Fhdpat",
      "UNII-1G916IMD7L",
      "CHEMBL22778",
      "1G916IMD7L",
      "5-Fluoro-8-Hydroxy-2-(Dipropylamino)Tetralin",
      "(S)-7-(Dipropylamino)-4-Fluoro-5,6,7,8-Tetrahydronaphthalen-1-Ol",
      "1-Naphthalenol, 7-(Dipropylamino)-4-Fluoro-5,6,7,8-Tetrahydro-, (S)-",
      "S(-)-5-Fluoro-8-Hydroxy-2-Dipropylamino-1,2,3,4-Tetrahydronaphthalene Hydrochloride",
      "Lopac-U-109",
      "Lopac-U-108",
      "GTPL61",
      "AC1L3U5D",
      "Lopac0_001236",
    ],
  },
  {
    primary_id: "D0L3XV",
    names: ["SMART Anti-E/P Selectin"],
  },
  {
    primary_id: "D0L3YP",
    names: ["Paclitaxel Liposomal"],
  },
  {
    primary_id: "D0L3YX",
    names: [
      "T-62",
      "40312-34-3",
      "T62",
      "UNII-9Q765ZIF8L",
      "CHEMBL57997",
      "9Q765ZIF8L",
      "3-(4-Chlorobenzoyl)-4,5,6,7-Tetrahydro-1-Benzothiophen-2-Amine",
      "(2-Amino-4,5,6,7-Tetrahydrobenzo[b]thiophen-3-Yl)(4-Chlorophenyl)Methanone",
      "2-Amino-3-(4-Chlorobenzoyl)-5,6,7,8-Tetrahydrobenzothiophene",
      "(2-Amino-4,5,6,7-Tetrahydro-1-Benzothien-3-Yl)(4-Chlorophenyl)Methanone",
      "(2-Amino-4,5,6,7-Tetrahydro-Benzo[b]thiophen-3-Yl)-(4-Chloro-Phenyl)-Methanone",
      "BAS 01842965",
      "AC1Q50OD",
      "AC1LH2S9",
      "Oprea1_104969",
      "Oprea1_077736",
      "SCHEMBL135569",
      "CTK6G9061",
      "DTXSID20193322",
    ],
  },
  {
    primary_id: "D0L3ZZ",
    names: ["PMID25522065-Compound-16"],
  },
  {
    primary_id: "D0L4AL",
    names: ["NTx-265"],
  },
  {
    primary_id: "D0L4AR",
    names: [
      "2-(4-Amino-Phenyl)-6-Methyl-Chromen-4-One",
      "CHEMBL132422",
      "468060-73-3",
      "CTK1D1882",
      "DTXSID00451376",
      "ZINC13491190",
      "AKOS022269882",
      "KB-191927",
      "4h-1-Benzopyran-4-One,2-(4-Aminophenyl)-6-Methyl-",
      "4H-1-Benzopyran-4-One, 2-(4-Aminophenyl)-6-Methyl-",
    ],
  },
  {
    primary_id: "D0L4BR",
    names: ["HuCD40L", "Avrend", "CD40 Ligand, Celldex", "CD40 Ligand, Immunex", "HuCD40L, Immunex"],
  },
  {
    primary_id: "D0L4BU",
    names: ["Sanaria PfSPZ Vaccine"],
  },
  {
    primary_id: "D0L4CB",
    names: ["CGP 64213", "CGP64213", "CGP-64213"],
  },
  {
    primary_id: "D0L4CI",
    names: [
      "Desmethylclomipramine",
      "Norclomipramine",
      "UNII-01DN47PPQG",
      "01DN47PPQG",
      "N-Demethylclomipramine",
      "Demethylchlorimipramine",
      "Desmethylchlorimipramine",
      "AC1LDNXR",
      "SCHEMBL11384662",
      "VPIXQGUBUKFLRF-UHFFFAOYSA-N",
      "CHEBI:124969",
      "ZINC2570877",
      "API0007900",
      "BRD-K52696183-003-01-0",
      "5H-Dibenz(B,f)Azepine-5-Propanamine, 3-Chloro-10,11-Dihydro-N-Methyl-",
      "3-Chloro-10,11-Dihydro-N-Methyl-5H-Dibenz(B,f)Azepine-5-Propanamine",
      "3-(2-Chloro-5,6-Dihydrobenzo[b][1]benzazepin-11-Yl)-N-Methylpropan-1-Amine",
    ],
  },
  {
    primary_id: "D0L4CN",
    names: ["MTIG7192A"],
  },
  {
    primary_id: "D0L4CW",
    names: ["Growth Hormone Releasing Factor Peptides"],
  },
  {
    primary_id: "D0L4DW",
    names: ["(2S)-Aminobutyryl-L-Proline N-Pentylamide", "CHEMBL376374", "SCHEMBL6242559"],
  },
  {
    primary_id: "D0L4EG",
    names: ["Mentat"],
  },
  {
    primary_id: "D0L4EO",
    names: ["3-(4-(2-Phenylethynyl)Benzoyl)Pentanoic Acid", "CHEMBL202875"],
  },
  {
    primary_id: "D0L4ET",
    names: ["Hepatect CP"],
  },
  {
    primary_id: "D0L4EU",
    names: ["PMID27109571-Compound-8"],
  },
  {
    primary_id: "D0L4FJ",
    names: ["PD-135385"],
  },
  {
    primary_id: "D0L4FM",
    names: ["PHENYLSULFAMATE"],
  },
  {
    primary_id: "D0L4FS",
    names: [
      "Genistein",
      "Bonistein",
      "GEN",
      "Genestein",
      "Genisteol",
      "Genisterin",
      "Prunetol",
      "Sophoricol",
      "Differenol A",
      "G 6649",
      "G10000",
      "IN1327",
      "NPI 031L",
      "STO514",
      "TNP00151",
      "GENISTEIN (ENDOCRINE DISRUPTER)",
      "Lactoferrin-Genistein",
      "PTI G4660 (Genistein)",
      "PTI-G4660",
      "SIPI 807-1",
      "C.I. 75610",
      "SIPI-9764-I",
      "ENDOCRINE DISRUPTOR (GENISTEIN) (SEE ALSO GENISTEIN (446-72-0))",
      "4 Inverted Exclamation Marka,5,7-Trihydroxyisoflavone",
      ",5,7-Trihydroxyisoflavone",
      "4',5, 7-Trihydroxyisoflavone",
      "4',5,7-Trihydroxy Isoflavone",
      "4',5,7-Trihydroxyisoflavone",
      "4,5,7-Trihydroxyiso-Flavone",
      "4,6,7-Trihydroxyisoflavone",
      "5,7,4'-Trihydroxyisoflavone",
      "5,7-Dihydroxy-3-(4-Hydroxyphenyl)-4-Benzopyrone",
      "5,7-Dihydroxy-3-(4-Hydroxyphenyl)-4H-1-Benzopyran-4-One",
      "5,7-Dihydroxy-3-(4-Hydroxyphenyl)-4H-Chromen-4-One",
      "5,7-Dihydroxy-3-(4-Hydroxyphenyl)Chromen-4-One",
    ],
  },
  {
    primary_id: "D0L4GF",
    names: ["AG-3296"],
  },
  {
    primary_id: "D0L4GH",
    names: ["HBN-3"],
  },
  {
    primary_id: "D0L4GL",
    names: ["Biphenyl Derivative 2"],
  },
  {
    primary_id: "D0L4GX",
    names: ["JTT-252"],
  },
  {
    primary_id: "D0L4HJ",
    names: ["RPR-108518A", "CHEMBL543904"],
  },
  {
    primary_id: "D0L4HK",
    names: ["TVX-002"],
  },
  {
    primary_id: "D0L4HL",
    names: ["RG7314"],
  },
  {
    primary_id: "D0L4HU",
    names: ["Cyclohexyl-(3,4-Dichloro-Phenyl)-Acetonitrile", "CHEMBL432482", "SCHEMBL5903164", "BDBM50126935"],
  },
  {
    primary_id: "D0L4HY",
    names: ["Guanabenz"],
  },
  {
    primary_id: "D0L4IV",
    names: [
      "AL12180",
      "5,6-Dihydro-4,5-Didehydro-11-Deoxy-11-Oxa-16-(3-Chlorophenoxy)-Omega-Tetranor-PGF2alpha",
      "AL-12180",
    ],
  },
  {
    primary_id: "D0L4JH",
    names: ["Sarecycline"],
  },
  {
    primary_id: "D0L4JT",
    names: [
      "Sulfanilamide",
      "AAS",
      "AVC",
      "Albexan",
      "Albosal",
      "Ambeside",
      "Antistrept",
      "Astreptine",
      "Astrocid",
      "Bactesid",
      "Collomide",
      "Colsulanyde",
      "Copticide",
      "Deseptyl",
      "Desseptyl",
      "Dipron",
      "Ergaseptine",
      "Gerison",
      "Gombardol",
      "Infepan",
      "Lusil",
      "Lysococcine",
      "Neococcyl",
      "Orgaseptine",
      "PABS",
      "Prontalbin",
      "Prontylin",
      "Proseptal",
      "Proseptine",
      "Pysococcine",
      "SAN",
      "Sanamid",
      "Septanilam",
      "Septolix",
      "Septoplex",
      "Septoplix",
      "Solfanilamide",
      "Stramid",
      "Strepamide",
      "Streptagol",
      "Streptamid",
      "Streptocid",
      "Streptocide",
      "Streptocidum",
      "Streptocom",
      "Streptol",
      "Strepton",
      "Streptopan",
      "Streptrocide",
      "Sulfanilamida",
      "Sulfanilamidum",
      "Sulfocidin",
      "Therapol",
      "Tolder",
      "Prontosil I",
      "Prontosil White",
      "Pronzin Album",
      "Rubiazol A",
      "Septamide Album",
      "Solfanilamide [DCIT]",
      "Stopton Album",
      "Streptocid Album",
      "Streptocide White",
      "Sulfanilamide Vaginal Cream",
      "Sulphanilamide Extra Pure",
      "Sulphanilamide Gr",
      "White Streptocide",
      "F 1162",
      "Fourneau 1162",
      "Ro13354",
      "A-349",
      "AVC (TN)",
      "Aromatic/Heteroaromatic Sulfonamide 2",
      "F-1162",
      "P-Aminobenzenesulfonylamide",
      "P-Aminobenzensulfonamide",
      "Streptocid (TN)",
      "Streptocide (VAN)",
      "Sulfanilamida [INN-Spanish]",
      "Sulfanilamide (INN)",
      "Sulfanilamide [INN:DCF]",
      "Sulfanilamidum [INN-Latin]",
      "Aniline-P-Sulfonic Amide",
      "I.C. 56",
      "BENZENESULFONIC ACID,4-AMINO,AMIDE SULFANILAMIDE",
      "N4,N4-Bis(2-Bromoethyl)Sulfanilamide",
      "N(Sup 4),N(Sup 4)-Bis(2-Bromoethyl)Sulfanilamide",
      "N(Sup4),N(Sup4)-Bis(2-Bromoethyl)Sulfanilamide",
      "Benzenesulfonamide, 4-(Bis(2-Bromoethyl)Amino)-(9CI)",
      "Sulfanilamide, N4,N4-Bis(2-Bromoethyl)-(8CI)",
      "1162 F",
      "4-(Bis(2-Bromoethyl)Amino)Benzenesulfonamide",
      "4-Aminobenzene-1-Sulfonamide",
      "4-Aminophenylsulfonamide",
      "4-Sulfamoylaniline",
      "4-[bis(2-Bromoethyl)Amino]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0L4KE",
    names: ["N-(2-Thienylmethyl)-2,5-Thiophenedisulfonamide"],
  },
  {
    primary_id: "D0L4LX",
    names: ["Atorvastatin/ Ezetimibe"],
  },
  {
    primary_id: "D0L4MF",
    names: ["1,2-Bis(3,5-Difluorophenyl)-2-Hydroxyethanone", "CHEMBL437373"],
  },
  {
    primary_id: "D0L4ML",
    names: [
      "N-Hydroxy-6-Oxo-6-Phenylhexanamide",
      "CHEMBL95152",
      "SCHEMBL1521154",
      "BDBM50114819",
      "6-Oxo-6-Phenyl-Hexanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0L4NE",
    names: ["ISIS 1820"],
  },
  {
    primary_id: "D0L4NF",
    names: ["Alpha-Methylalanyl-L-Proline Butylamide", "CHEMBL388062"],
  },
  {
    primary_id: "D0L4NG",
    names: [
      "3-(4-Ethyl-3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "Dihydronaphthalene 10",
      "AC1O70HJ",
      "BDBM8896",
      "CHEMBL205250",
    ],
  },
  {
    primary_id: "D0L4NP",
    names: ["RU84687"],
  },
  {
    primary_id: "D0L4OH",
    names: ["PMID27019002-Compound-28"],
  },
  {
    primary_id: "D0L4OT",
    names: ["HOMOPHENYLALANINYLMETHANE"],
  },
  {
    primary_id: "D0L4OW",
    names: ["GANSTIGMINE"],
  },
  {
    primary_id: "D0L4PM",
    names: ["AVE-3247"],
  },
  {
    primary_id: "D0L4PT",
    names: ["L-764406"],
  },
  {
    primary_id: "D0L4PY",
    names: ["KB-5492"],
  },
  {
    primary_id: "D0L4QC",
    names: [
      "3-Hydroxy-6-Methyl-1H-Benzo[b]azepine-2,5-Dione",
      "3-Hydroxy-6-Methyl-1H-1-Benzazepine-2,5-Dione",
      "1H-1-Benzazepine-2,5-Dione, 3-Hydroxy-6-Methyl-",
      "SCHEMBL8580170",
      "CHEMBL143225",
      "LPRUKTXSSXGABW-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0L4RU",
    names: ["N-Omega-Propargyl-L-Arginine"],
  },
  {
    primary_id: "D0L4RX",
    names: ["PB-81"],
  },
  {
    primary_id: "D0L4SB",
    names: ["Oncolysin CD6", "Anti-CD6-BR", "Anti-T12-BR"],
  },
  {
    primary_id: "D0L4SD",
    names: ["Alpha-Acetyldigoxin", "Dioxanin (TN)", "Lanatilin (TN)", "Sandolanid (TN)"],
  },
  {
    primary_id: "D0L4SL",
    names: ["TA-270"],
  },
  {
    primary_id: "D0L4TM",
    names: [
      "2-(3-Phenyl-Propyl)-1,2-Dihydro-Indazol-3-One",
      "CHEMBL3144578",
      "CHEMBL8053",
      "BDBM50009009",
      "ZINC138045856",
    ],
  },
  {
    primary_id: "D0L4TY",
    names: [
      "N-(4,6-Diphenylpyrimidin-2-Yl)-3-Methylbutyramide",
      "CHEMBL376672",
      "820961-70-4",
      "SCHEMBL6501467",
      "CTK3E2638",
      "DTXSID50461763",
      "MolPort-007-705-567",
      "HMS3459C01",
      "ZINC13586473",
      "BDBM50157677",
      "AKOS001873749",
      "MCULE-2375799118",
      "N-(4,6-Diphenylpyrimidin-2-Yl)-3-Methylbutanamide",
      "Butanamide, N-(4,6-Diphenyl-2-Pyrimidinyl)-3-Methyl-",
    ],
  },
  {
    primary_id: "D0L4UC",
    names: ["Benzazepine Derivative 3"],
  },
  {
    primary_id: "D0L4UN",
    names: ["HIV-IG"],
  },
  {
    primary_id: "D0L4US",
    names: ["BMS-770767"],
  },
  {
    primary_id: "D0L4UV",
    names: [
      "CATPB",
      "(S)-3-(2-(3-Chlorophenyl)Acetamido)-4-(4-(Trifluoromethyl)Phenyl)Butanoic Acid",
      "GTPL6487",
      "SCHEMBL2312533",
      "CATPB, &gt",
      "ZINC118616157",
      "AKOS030210965",
      "1322598-09-3",
      "(3S)-3-[2-(3-Chlorophenyl)Acetamido]-4-[4-(Trifluoromethyl)Phenyl]butanoic Acid",
    ],
  },
  {
    primary_id: "D0L4VD",
    names: ["RG6058"],
  },
  {
    primary_id: "D0L4VI",
    names: [
      "L-Cysteine",
      "52-90-4",
      "Cystein",
      "Half-Cystine",
      "(R)-Cysteine",
      "Thioserine",
      "(R)-2-Amino-3-Mercaptopropanoic Acid",
      "L-(+)-Cysteine",
      "L-Cystein",
      "(2R)-2-Amino-3-Sulfanylpropanoic Acid",
      "Beta-Mercaptoalanine",
      "Half Cystine",
      "FREE CYSTEINE",
      "Cysteine, L-",
      "L-Cys",
      "L-Alanine, 3-Mercapto-",
      "L-Zystein",
      "H-Cys-OH",
      "Cisteinum [Latin]",
      "L-2-Amino-3-Mercaptopropionic Acid",
      "Cisteina [Spanish]",
      "Cisteina",
      "Polycysteine",
      "Cysteinum",
      "Cisteinum",
      "(R)-2-Amino-3-Mercaptopropionic Acid",
      "L Cysteine",
      "Alpha-Amino-Beta-Thiolpropionic Acid",
      "L-2-Amino-3-Mercaptopropanoic A",
    ],
  },
  {
    primary_id: "D0L4VR",
    names: [
      "Carbenoxolone",
      "5697-56-3",
      "UNII-MM6384NG73",
      "OBZHEBDUNPOCJG-WBXJDKIVSA-N",
      "MM6384NG73",
      "Carbenoxolone [INN:BAN]",
      "Carbenoxolonum [INN-Latin]",
      "Carbenoxolona [INN-Spanish]",
      "Carbenoxolona",
      "Carbenoxolonum",
      "EINECS 227-174-2",
      "CBO",
      "(2S,4aS,6aR,6aS,6bR,8aR,10S,12aS,14bR)-10-(4-Hydroxy-4-Oxobutanoyl)Oxy-2,4a,6a,6b,9,9,12a-Heptamethyl-13-Oxo-3,4,5,6,6a,7,8,8a,10,11,12,14b-Dodecahydro-1H-Picene-2-Carboxylic Acid",
      "3beta-Hydroxy-11-Oxoolean-12-En-30-Saeure Hydogensuccinat",
      "3beta-(3-Carboxypropionyloxy)-11-Oxo-Olean-12-En-30-Saeure",
    ],
  },
  {
    primary_id: "D0L4VZ",
    names: ["SPP-1148"],
  },
  {
    primary_id: "D0L4WB",
    names: ["8-(4-Methyl-Cyclohexyloxy)-Quinolin-2-Ylamine", "CHEMBL184573", "SCHEMBL5884069"],
  },
  {
    primary_id: "D0L4WN",
    names: [
      "1,4-Diaminoanthracene-9,10-Dione",
      "1,4-Diaminoanthraquinone",
      "128-95-0",
      "Disperse Violet 1",
      "Krisolamine",
      "1,4-Diamino Anthraquinone",
      "Grasol Violet R",
      "Seacyl Violet R",
      "Acetate Red Violet R",
      "9,10-Anthracenedione, 1,4-Diamino-",
      "Setacyl Violet R",
      "Oil Violet R",
      "Disperse Violet K",
      "Resiren Violet TR",
      "Oracet Violet 2R",
      "Gracet Violet 2R",
      "Setile Violet 3R",
      "Duranol Violet 2R",
      "Nacelan Violet 4R",
      "Cibacet Violet 2R",
      "Amacel Heliotrope R",
      "Dispersive Violet K",
      "Solvent Violet 11",
      "Cibacete Violet 2R",
      "Perliton Violet 3R",
      "Nyloquinone Violet R",
      "Artisil Viole",
    ],
  },
  {
    primary_id: "D0L4WO",
    names: ["SGN-19A"],
  },
  {
    primary_id: "D0L4WV",
    names: ["Grass Pollen Extract"],
  },
  {
    primary_id: "D0L4XW",
    names: ["Nimenrix"],
  },
  {
    primary_id: "D0L4YD",
    names: ["Solifenacin"],
  },
  {
    primary_id: "D0L4YF",
    names: [
      "2-(Pyridin-2-Yl)-1H-Benzo[d]imidazole",
      "2-(2-Pyridyl)Benzimidazole",
      "1137-68-4",
      "2-Pyridin-2-Yl-1H-Benzimidazole",
      "2-Pyridin-2-Yl-1H-Benzoimidazole",
      "2-(Pyridin-2-Yl)-1H-Benzimidazole",
      "NSC 32814",
      "NSC 110942",
      "CHEMBL72683",
      "YNFBMDWHEHETJW-UHFFFAOYSA-N",
      "1H-Benzimidazole, 2-(Pyridinyl)-",
      "1H-Benzimidazole, 2-(2-Pyridinyl)-",
      "2-(Pyridin-2-Yl)-1H-1,3-Benzodiazole",
      "2-(2-Pyridyl)Benzimidazole, 99%",
      "EINECS 214-508-7",
      "ACMC-20aime",
      "AC1L2EXP",
      "2(2-Pyridyl)Benzimidazole",
      "2-(2'-Pyrido)Benzimidazole",
      "MLS000554415",
      "2-(2-Pyridyl) Benzimidazole",
    ],
  },
  {
    primary_id: "D0L4ZK",
    names: ["RG-7351"],
  },
  {
    primary_id: "D0L5AB",
    names: [
      "BX-517",
      "BX517",
      "850717-64-5",
      "UNII-SYV8VN8W5K",
      "SYV8VN8W5K",
      "Pdk-1 Inhibitors",
      "BX517(PDK1 Inhibitor2)",
      "Indolinone Based Inhibitor, 4i",
      "SCHEMBL5567818",
      "CHEMBL228654",
      "5-Ureido-3-(1-(Pyrrol-2-Yl)Ethylidene)Indolin-2-One",
      "BDBM17004",
      "MolPort-046-033-615",
      "BCP16225",
      "EX-A2243",
      "ZINC14962724",
      "AKOS032945106",
      "CS-6066",
      "Urea, N-(2,3-Dihydro-2-Oxo-3-((3Z)-1-(1H-Pyrrol-2-Yl)Ethylidene)-1H-Indol-5-Yl)-",
      "Urea, N-(2,3-Dihydro-2-Oxo-3-(1-(1H-Pyrrol-2-Yl)Ethylidene)-1H-Indol-5-Yl)-",
      "Urea, (2,3-Dihydro-2-Oxo-3-(1-(1H-Pyrrol-2-Yl)Ethylidene)-1H-Indol-5-",
    ],
  },
  {
    primary_id: "D0L5AG",
    names: ["VU0029767"],
  },
  {
    primary_id: "D0L5AH",
    names: ["CELIKALIM"],
  },
  {
    primary_id: "D0L5AK",
    names: ["TTI-1612 Intravesicular"],
  },
  {
    primary_id: "D0L5AP",
    names: ["Gitalin"],
  },
  {
    primary_id: "D0L5AS",
    names: ["RPR-114334"],
  },
  {
    primary_id: "D0L5BC",
    names: ["FGGFTGARKSARKFANQ"],
  },
  {
    primary_id: "D0L5BK",
    names: ["KW-2149"],
  },
  {
    primary_id: "D0L5BW",
    names: ["GlaxoSmithKline Compound 6i"],
  },
  {
    primary_id: "D0L5CZ",
    names: ["Rocuronium"],
  },
  {
    primary_id: "D0L5DK",
    names: [
      "99MTC-CYT-379",
      "CYT-379 (99mTc)",
      "PAC-8 (99mTc)",
      "Acetyl-Seryl-Tyrosyl-Glycyl-Arginyl-Glycyl-Aspartyl-Valyl-Arginyl-Glycyl-Aspartyl-Phenylalanyl-Lysyl-Cysteinyl-Threonyl-Cysteinyl-Cysteinyl-Alaninamide Technetium[99mTc]-Labeled",
      "99mTc-PAC-8",
    ],
  },
  {
    primary_id: "D0L5DN",
    names: ["MCL-149"],
  },
  {
    primary_id: "D0L5DV",
    names: ["PMID25470667-Compound-Figure4-1A"],
  },
  {
    primary_id: "D0L5DZ",
    names: ["4SC-202"],
  },
  {
    primary_id: "D0L5EA",
    names: ["REC-200"],
  },
  {
    primary_id: "D0L5EG",
    names: ["Tricyclic Indole Compound 2"],
  },
  {
    primary_id: "D0L5EW",
    names: ["(6-Ethoxy-2-Naphthyl)-2-Aminopropane", "CHEMBL450964"],
  },
  {
    primary_id: "D0L5FB",
    names: ["TAK-329"],
  },
  {
    primary_id: "D0L5FW",
    names: ["[3H]methoxy-PEPy", "CHEMBL1169452", "GTPL6425", "SCHEMBL14573621"],
  },
  {
    primary_id: "D0L5FY",
    names: [
      "Metipranolol",
      "Disorat",
      "Methypranol",
      "Metipranololum",
      "Trimepranol",
      "Turoptin",
      "BM-01004",
      "Betanol (TN)",
      "Disorat (TN)",
      "Metipranololum [INN-Latin]",
      "OptiPranolol (TN)",
      "Trimepranol (TN)",
      "VUAB-6453",
      "VUFB-6453",
      "Metipranolol (USAN/INN)",
      "Metipranolol [USAN:BAN:INN]",
      "[4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]-2,3,6-Trimethylphenyl] Acetate",
      "Phenol, 4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)-2,3,6-Trimethyl-, 1-Acetate",
      "Phenol, 4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)-2,3,6-Trimethyl-, 1-Acetate (9CI)",
      "Phenol,4-(2-Hydroxy-3-((1-Methylethyl)Amino)Propoxy)-2,3,6-Trimethyl-, (+-)-, 1-Acetate",
      "(+-)-1-(4-Hydroxy-2,3,5-Trimethylphenoxy)-3-(Isopropylamino)-2-Propanol 4-Acetate",
      "(RS)-4-(2-Hydroxy-3-Isopropylaminopropoxy)-2,3,6-Trimethylphenyl Acetat",
      "1-(4-Acetoxy-2,3,5-Trimethylphenoxy)-3-Isopropylamino-2-Propanol",
      "1-(4-Hydroxy-2,3,5-Trimethylphenoxy)-3-(Isopropylamino)-2-Propanol 4-Acetate",
      "2-Propanol, 1-(4-Hydroxy-2,3,5-Trimethylphenoxy)-3-(Isopropylamino)-, 4-Acetate",
      "4-(2-Hydroxy-3-((1-Methylethyl)-Amino)Propoxy)-2,3,6-Trimethylphenol 1-Acetate",
      "4-[2-Hydroxy-3-(Isopropylamino)Propoxy]-2,3,6-Trimethylphenyl Acetate",
      "62164P",
    ],
  },
  {
    primary_id: "D0L5GH",
    names: ["Olcegepant"],
  },
  {
    primary_id: "D0L5GS",
    names: ["4-(Quinolin-4-Yl)-N-P-Tolylpyrimidin-2-Amine", "CHEMBL1080023"],
  },
  {
    primary_id: "D0L5HM",
    names: ["CD19 CAR T Cells"],
  },
  {
    primary_id: "D0L5IA",
    names: ["PEGylated Hyaluronidase (Human Recombinant)"],
  },
  {
    primary_id: "D0L5IC",
    names: [
      "CP-394531",
      "UNII-227D9ED2SI",
      "CHEMBL77779",
      "227D9ED2SI",
      "305822-63-3",
      "SCHEMBL3111189",
      "BDBM50113783",
      "2,7-Phenanthrenediol, 2-(Chloroethynyl)-1,2,3,4,4a,9,10,10a-Octahydro-4a-(Phenylmethyl)-, (2R,4aS,10aR)-",
      "2,7-Phenanthrenediol, 2-(2-Chloroethynyl)-1,2,3,4,4a,9,10,10a-Octahydro-4a-(Phenylmethyl)-, (2R,4aS,10aR)-",
      "4a-Benzyl-2-Chloroethynyl-1,2,3,4,4a,9,10,10a-Octahydro-Phenanthrene-2,7-Diol(CP-394531)",
    ],
  },
  {
    primary_id: "D0L5IL",
    names: ["Betamarc", "Appetite Stimulant (Cachexia/Appetite Loss), Anaborex"],
  },
  {
    primary_id: "D0L5IM",
    names: ["JWH-306"],
  },
  {
    primary_id: "D0L5IN",
    names: [
      "4-Amino Hexanoic Acid",
      "4-Aminohexanoic Acid",
      "5415-99-6",
      "Hexanoic Acid, 4-Amino-",
      "G-Aminocaproic Acid",
      "4-Ethyl Aminobutyric Acid",
      "AC1L3CX4",
      "SCHEMBL900872",
      "CTK1H4537",
      "MolPort-006-169-599",
      "ALBB-018090",
      "NSC11326",
      "NSC-11326",
      "AKOS000277394",
      "AKOS017258812",
      "Hexanoic Acid, 4-Amino-, Hydrochloride",
    ],
  },
  {
    primary_id: "D0L5JA",
    names: ["ETS-2101"],
  },
  {
    primary_id: "D0L5JL",
    names: [
      "8-Cyclopentyltheophylline",
      "8-Cyclopentyl-1,3-Dimethylxanthine",
      "35873-49-5",
      "8-Cyclopentyl Theophylline",
      "UNII-U7PWT4CPL5",
      "U7PWT4CPL5",
      "8-Cyclopentyl-1,3-Dimethyl-7H-Purine-2,6-Dione",
      "NSC 101806",
      "BRN 1133199",
      "Xanthine, 8-Cyclopentyl-1,3-Dimethyl-",
      "Theophylline, 8-Cyclopentyl-",
      "CHEMBL106265",
      "8-Cyclopentyl-1,3-Dimethyl-1H-Purine-2,6(3H,7H)-Dione",
      "1,3-Dimethyl-8-Cyclopentylxanthine",
      "Spectrum_000351",
      "Lopac-C-102",
      "AC1Q6LAU",
      "AC1L1CIO",
      "Spectrum4_000760",
      "Spectrum2_001734",
      "Spectrum5_001769",
      "Spectrum3_000658",
      "NCIOpen2_006919",
      "Lopac0_000391",
    ],
  },
  {
    primary_id: "D0L5KF",
    names: [
      "RU-58841",
      "154992-24-2",
      "RU 58841",
      "RU58841",
      "4-(3-(4-Hydroxybutyl)-4,4-Dimethyl-2,5-Dioxoimidazolidin-1-Yl)-2-(Trifluoromethyl)Benzonitrile",
      "ARBYGDBJECGMGA-UHFFFAOYSA-N",
      "UNII-0D8FJQ0ADW",
      "0D8FJQ0ADW",
      "CHEMBL9337",
      "4-[3-(4-Hydroxybutyl)-4,4-Dimethyl-2,5-Dioxoimidazolidin-1-Yl]-2-(Trifluoromethyl)Benzonitrile",
      "AK-64424",
      "4-[3-(4-Hydroxybutyl)-4,4-Dimethyl-2,5-Dioxo-1-Imidazolidinyl]-2-(Trifluoromethyl)Benzonitrile",
      "Benzonitrile, 4-[3-(4-Hydroxybutyl)-4,4-Dimethyl-2,5-Dioxo-1-Imidazolidinyl]-2-(Trifluoromethyl)-",
    ],
  },
  {
    primary_id: "D0L5KK",
    names: ["ROR1R-CAR-T Cell"],
  },
  {
    primary_id: "D0L5KP",
    names: ["N-(3,4-Dimethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093359", "BDBM50314135"],
  },
  {
    primary_id: "D0L5KQ",
    names: ["2-(Benzylamino)-5,5-Diethyloxazol-4(5H)-One"],
  },
  {
    primary_id: "D0L5KU",
    names: [
      "Remimazolam",
      "CNS-7054",
      "CNS-7056",
      "CNS-7056B",
      "CNS-7056X",
      "GW-351564X",
      "GW-418259X",
      "GW-457837A",
      "GW-502056X",
      "ONO-2745",
      "Short-Acting Sedatives, Therasci",
      "GABA A Agonist (Anesthesia, Sedation) CeNeS/Ono",
      "Sedatives (Short Acting), CeNeS/Ono",
      "Sedatives (Short Acting), PAION/Ono",
      "Sedatives (Short-Acting), TheraSci",
      "Short-Acting Sedatives, Therasci/GSK",
      "Sedatives (Short-Acting), TheraSci/GSK",
    ],
  },
  {
    primary_id: "D0L5KY",
    names: ["N5-(1-Iminobut-3-Enyl)-L-Ornithine"],
  },
  {
    primary_id: "D0L5LN",
    names: [
      "ANTHRAQUINONE",
      "84-65-1",
      "9,10-Anthraquinone",
      "Anthracene-9,10-Dione",
      "9,10-Anthracenedione",
      "Anthradione",
      "Hoelite",
      "9,10-Dioxoanthracene",
      "Corbit",
      "Morkit",
      "9,10-Anthrachinon",
      "Anthra-9,10-Quinone",
      "Anthrapel",
      "9,10-Quinone",
      "Anthrachinon",
      "Caswell No. 052A",
      "Anthraquinone [BSI:ISO]",
      "UNII-030MS0JBDO",
      "NSC 7957",
      "Bis-Alkylamino Anthraquinone",
      "9,10-Anthrachinon [Czech]",
      "CCRIS 649",
      "9,10-Anthracendion",
      "Anthracene, 9,10-Dihydro-9,10-Dioxo-",
      "CHEBI:40448",
      "HSDB 2074",
      "EINECS 201-549-0",
      "Anthracene-9,10-Quinone",
      "EPA Pesticide Chemical Code 122701",
    ],
  },
  {
    primary_id: "D0L5LS",
    names: ["Extended-Release Opioid"],
  },
  {
    primary_id: "D0L5ME",
    names: ["ISIS 298699"],
  },
  {
    primary_id: "D0L5MF",
    names: ["PYRAZOLOACRIDINE"],
  },
  {
    primary_id: "D0L5MW",
    names: ["(+/-)-Nantenine"],
  },
  {
    primary_id: "D0L5NU",
    names: ["Human Factor XIII"],
  },
  {
    primary_id: "D0L5NZ",
    names: ["PMID25514969-Compound-Figure2-3"],
  },
  {
    primary_id: "D0L5OP",
    names: ["PMID26651364-Compound-6c"],
  },
  {
    primary_id: "D0L5OU",
    names: ["[11C]xanomeline", "[11C]xanomeline (PET Ligand)"],
  },
  {
    primary_id: "D0L5PI",
    names: ["(2'Z,3'E)-5-Nitro-5'-Fluoro-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0L5PO",
    names: ["Phenyl Aminosalicylate"],
  },
  {
    primary_id: "D0L5QJ",
    names: [
      "1,9-Bis(Pyridinium)-Nonane Dibromide",
      "CHEMBL105742",
      "SCHEMBL3591238",
      "UOOOOWOODBZUKX-UHFFFAOYSA-L",
      "N,N'-Nonane-1,9-Diyl-Bis-Pyridinium Dibromide",
      "1,9-Di(1-Pyridiniumyl)Nonane With Dibromide Ions",
    ],
  },
  {
    primary_id: "D0L5QT",
    names: ["Pentetate Zinc Trisodium"],
  },
  {
    primary_id: "D0L5RA",
    names: ["PMID25468267-Compound-47"],
  },
  {
    primary_id: "D0L5RI",
    names: ["(S)-2-Amino-1-Thiazolidin-3-Yl-Propane-1-Thione", "CHEMBL318401", "BDBM50118934"],
  },
  {
    primary_id: "D0L5RW",
    names: ["Plerixafor"],
  },
  {
    primary_id: "D0L5RY",
    names: [
      "Astressin",
      "170809-51-5",
      "GTPL925",
      "CHEBI:76649",
      "MFCD00798715",
      "AKOS024456701",
      "FHLLREVLE-Nle-ARAEQLAQ-Cyclo-(EAHK)NRKL-Nle-EII-NH2",
      "[D-Phe(12), Nle(21,38), Glu(30), Lys(33)]-CRF (12-41)",
      "[D-Phe12, Nle21,38, Glu30, Lys33]-Corticotropin Releasing Factor Fragment 12-41",
      "D-Phe-His-Leu-Leu-Arg-Glu-Val-Leu-Glu-Nle-Ala-Arg-Ala-Glu-Gln-Leu-Ala-Gln-Cyclo-(Glu-Ala-His-Lys)-Asn-Arg-Lys-Leu-Nle-Glu-Ile-Ile-NH2",
      "[D-Phe(12), Nle(21,38), Glu(30), Lys(33)]-Corticotropin Releasing Factor Fragment 12-41",
    ],
  },
  {
    primary_id: "D0L5TJ",
    names: [
      "A-131701",
      "CHEMBL40650",
      "SCHEMBL7637490",
      "BDBM50060965",
      "2-[2-(6-Methoxy-1,3,3a,4,5,9b-Hexahydro-Benzo[e]isoindol-2-Yl)-Ethyl]-4H-9-Thia-2,4,5-Triaza-Fluorene-1,3-Dione",
      "2-[2-((3aR,9bR)-6-Methoxy-1,3,3a,4,5,9b-Hexahydro-Benzo[e]isoindol-2-Yl)-Ethyl]-4H-9-Thia-2,4,5-Triaza-Fluorene-1,3-Dione",
    ],
  },
  {
    primary_id: "D0L5TZ",
    names: [
      "6-(Hydroxymethyl)-2H-Chromen-2-One",
      "CHEMBL571738",
      "2H-1-Benzopyran-2-One, 6-(Hydroxymethyl)-",
      "6,7-Hydroxy-4-Methyl Coumarin",
      "SCHEMBL10350668",
      "BDBM50303496",
      "AKOS006293254",
    ],
  },
  {
    primary_id: "D0L5UC",
    names: ["5-Ethyl-3-Methyl-5-Phenyl-Oxazolidine-2,4-Dione", "CHEMBL41666", "BDBM50227231", "68475-20-7"],
  },
  {
    primary_id: "D0L5UM",
    names: ["Urea And Carbamate Bioisostere Derivative 9"],
  },
  {
    primary_id: "D0L5UO",
    names: ["Darapladib"],
  },
  {
    primary_id: "D0L5VS",
    names: [
      "KF-17837",
      "CHEMBL27508",
      "(E)-8-(3,4-Dimethoxystyryl)-7-Methyl-1,3-Dipropylxanthine",
      "141807-96-7",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-8-(2-(3,4-Dimethoxyphenyl)Ethenyl)-1,3-Dipropyl-7-Methyl-, (E)-",
      "UQGGPCQNHJCOPS-PKNBQFBNSA-N",
      "8-[(E)-2-(3,4-Dimethoxyphenyl)Ethenyl]-7-Methyl-1,3-Dipropylpurine-2,6-Dione",
      "AC1O68AZ",
      "SCHEMBL537086",
      "SCHEMBL537085",
      "SCHEMBL9364001",
      "8-[2-(3,4-Dimethoxy-Phenyl)-Vinyl]-7-Methyl-1,3-Dipropyl-3,7-Dihydro-Purine-2,6-Dione",
      "BDBM50006710",
      "KF-17387",
      "LS-126824",
      "L006852",
    ],
  },
  {
    primary_id: "D0L5VX",
    names: ["MNK-1411", "Cosyntropin Hexaacetate", "UNII-H86HJ92K8Y", "EINECS 245-145-2", "H86HJ92K8Y", "22633-88-1"],
  },
  {
    primary_id: "D0L5WA",
    names: ["SR-46559A", "6-[2-(Diethylamino)-2-Methylpropylamino]-3-Phenyl-4-Propylpyridazine Sesquifumarate"],
  },
  {
    primary_id: "D0L5WD",
    names: ["Ularitide"],
  },
  {
    primary_id: "D0L5WF",
    names: ["Somavaratan"],
  },
  {
    primary_id: "D0L5WI",
    names: [
      "N-Hydroxy-8-(Naphthalen-2-Yl)Octanamide",
      "CHEMBL95747",
      "SCHEMBL3378763",
      "KWFDCRKEDDNSLQ-UHFFFAOYSA-N",
      "N-Hydroxy-8-(2-Naphthyl)Octanamide",
      "BDBM50114833",
      "ZINC13474419",
      "8-Naphthalen-2-Yl-Octanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0L5WM",
    names: [
      "Amiodarone",
      "Amidorone",
      "Aminodarone",
      "Amiobeta",
      "Amiodarex",
      "Amiodarona",
      "Amiodarons",
      "Amiodaronum",
      "Amiohexal",
      "Amjodaronum",
      "Ancar",
      "Aratac;Arycor",
      "Braxan",
      "Corbionax",
      "Cordarex",
      "Cordarone",
      "Kordaron",
      "Labaz",
      "Ortacrone",
      "Pacerone",
      "Rytmarone",
      "Sedacoron",
      "Sedacorone",
      "Tachydaron",
      "ASTA Medica Brand Of Amiodarone Hydrochloride",
      "Alphapharm Brand Of Amiodarone Hydrochloride",
      "Amiodarone Base",
      "Armstrong Brand Of Amiodarone Hydrochloride",
      "Berenguer Infale Brand Of Amiodarone Hydrochloride",
      "Betapharm Brand Of Amiodarone Hydrochloride",
      "Cordarone Intravenous",
      "G Gam Brand Of Amiodarone Hydrochloride",
      "Hexal Brand Of Amiodarone Hydrochloride",
      "Leurquin Brand Of Amiodarone Hydrochloride",
      "Pharma Investi Brand Of Amiodarone Hydrochloride",
      "Sanofi Winthrop Brand Of Amiodarone Hydrochloride",
      "Wyeth Brand Of Amiodarone Hydrochloride",
      "Ethylaminoethoxy)Benzoyl]benzofuran",
      "L 3428",
      "L3428",
      "SKF 33134A",
      "Amio-Aqueous IV",
      "Amiodarona [INN-Spanish]",
      "Amiodaronum [INN-Latin]",
      "Aratac (TN)",
      "Arycor (TN)",
      "Atlansil (TN)",
      "Cordarone (TN)",
      "L-3428",
      "Pacerone (TN)",
      "Pms-Amiodarone",
      "SKF 33134-A",
      "Amiodarone (USAN/INN)",
      "Amiodarone [USAN:BAN:INN]",
      "Ketone, 2-Butyl-3-Benzofuranyl 4-(2-(Diethylamino)Ethoxy)-3,5-Diiodophenyl",
      "Ketone, 2-Butyl-3-Benzofuranyl 4-[2-(Diethylamino)Ethoxy]-3,5-Diiodophenyl (7CI,8CI)",
      "(2-Butyl-3-Benzofuranyl)(4-(2-(Diethylamino)Ethoxy)-3,5-Diidophenyl)Methanone",
      "(2-Butyl-1-Benzofuran-3-Yl)(4-{[2-(Diethylamino)Ethyl]oxy}-3,5-Diiodophenyl)Methanone",
      "(2-Butyl-1-Benzofuran-3-Yl)-[4-(2-Diethylaminoethyloxy)-3,5-Diiodophenyl]methanone",
      "(2-Butyl-1-Benzofuran-3-Yl){4-[2-(Diethylamino)Ethoxy]-3,5-Diiodophenyl}methanone",
      "(2-Butylbenzofuran-3-Yl)-[4-(2-Diethylaminoethoxy)-3,5-Diiodo-Phenyl]-Methanone",
      "(2-{4-[(2-Butyl-1-Benzofuran-3-Yl)Carbonyl]-2,6-Diiodophenoxy}ethyl)Diethylamine",
      "2-Butyl-3-(3,5-Diiodo-4-(2-Diethylaminoethoxy)Benzoyl)Benzofuran",
      "2-Butyl-3-(3,5-Diiodo-4-(Beta-Diethylaminoethoxy)Benzoyl)Benzofuran",
      "2-Butyl-3-(4'-Beta-N-Diethylaminoethoxy-3',5'-Diiodobenzoyl)Benzofuran",
      "2-Butyl-3-[3,5-Diiodo-4-(2-Di",
      "2-Butyl-3-Benzofuranyl 4-(2-(Diethylamino)Ethoxy)-3,5-Diiodophenyl Ketone",
      "2-Butyl-3-Benzofuranyl P-((2-Diethylamino)Ethoxy)-M,m-Diiodophenyl Ketone",
      "2-Butyl-3-Benzofuranyl P-[(2-Diethylamino)Ethoxy]-M,m-Diiodophenyl Ketone",
      "2-N-Butyl-3',5'-Diiodo-4'-N-Diethylaminoethoxy-3-Benzoylbenzofuran",
    ],
  },
  {
    primary_id: "D0L5WS",
    names: [
      "Benzoyl-Arginine-Alanine-Methyl Ketone",
      "AC1NRDMD",
      "Benzyl N-[(2S)-5-(Diaminomethylamino)-1-Oxo-1-(3-Oxobutan-2-Ylamino)Pentan-2-Yl]carbamate",
      "Molecular Hydrogen",
    ],
  },
  {
    primary_id: "D0L5XG",
    names: ["PF-4191834"],
  },
  {
    primary_id: "D0L5XI",
    names: ["AMG-517", "AMG 517", "BD-0082"],
  },
  {
    primary_id: "D0L5XK",
    names: [
      "7-AMINO-4-METHYL-CHROMEN-2-ONE",
      "7-Amino-4-Methylcoumarin",
      "26093-31-2",
      "Coumarin 120",
      "7-Amino-4-Methyl-2H-Chromen-2-One",
      "4-Methyl-7-Aminocoumarin",
      "2H-1-Benzopyran-2-One, 7-Amino-4-Methyl-",
      "Coumarin, 7-Amino-4-Methyl-",
      "UNII-OCY3JCT44X",
      "CCRIS 4961",
      "7-Amino-4-Methylchromen-2-One",
      "EINECS 247-454-8",
      "NSC 45796",
      "OCY3JCT44X",
      "7-Amino-4-Methyl-Coumarin",
      "MLS000057660",
      "AMC",
      "CHEBI:51771",
      "GLNDAGDHSLMOKX-UHFFFAOYSA-N",
      "NSC45796",
      "MFCD00006868",
      "GEO-02697",
      "SMR000067752",
      "AK-34405",
      "7-Amino-4-Methyl-2H-1-Benzopyran-2-One",
      "A-6591",
      "A1-00199",
      "J-100007",
    ],
  },
  {
    primary_id: "D0L5XY",
    names: ["FR221647"],
  },
  {
    primary_id: "D0L5YC",
    names: [
      "8-Methoxy-1,2,3,4-Tetrahydro-Naphthalen-2-Ylamine",
      "8-Methoxy-2-Aminotetralin",
      "3880-77-1",
      "8-Methoxy-1,2,3,4-Tetrahydronaphthalen-2-Amine",
      "CHEMBL26688",
      "ACMC-20msdp",
      "ACMC-20mscg",
      "2-Amino-8-Methoxytetralin",
      "8-Methoxytetralin-2-Amine",
      "8-Methoxy-2-Amino-Tetralin",
      "2-Naphthalenamine,1,2,3,4-Tetrahydro-8-Methoxy-, (2R)-",
      "2-Naphthalenamine,1,2,3,4-Tetrahydro-8-Methoxy-, (2S)-",
      "SCHEMBL266921",
      "CTK4I0515",
      "2-NAPHTHALENAMINE, 1,2,3,4-TETRAHYDRO-8-METHOXY-",
      "RVKOHSCTEHZRRT-UHFFFAOYSA-N",
      "MFCD00906373",
      "BDBM50016733",
      "AKOS022393176",
      "FCH1122360",
    ],
  },
  {
    primary_id: "D0L5YF",
    names: [
      "ZD-4190",
      "AC1OCFDK",
      "CHEMBL3544937",
      "N-(4-Bromo-2-Fluorophenyl)-6-Methoxy-7-[2-(Triazol-1-Yl)Ethoxy]quinazolin-4-Amine Hydrochloride",
    ],
  },
  {
    primary_id: "D0L5YV",
    names: [
      "Salmeterol",
      "Aeromax",
      "Astmerole",
      "SALMATEROL",
      "Salmeterolum",
      "Serevent",
      "Glaxo Wellcome Brand Of Salmeterol Xinafoate",
      "Salmeterolum [Latin]",
      "GR 33343X",
      "S 2692",
      "GR-33343X",
      "Serevent (TN)",
      "Salmeterol (USAN/INN)",
      "Salmeterol [USAN:BAN:INN]",
      "(+-)-4-Hydroxy-Alpha'-(((6-(4-Phenylbutoxy)Hexyl)Amino)Methyl)-M-Xylene-Alpha,alpha'-Diol",
      "(+-)-4-Hydroxy-Alpha1-(((6-(4-Phenylbutoxy)Hexyl)Amino)Methyl)-1,3-Benzenedimethanol",
      "(Inverted Question Mark) 4-Hydroxy-A1-[[[6-(4-Phenylbutoxy)Hexyl]amino]m-Ethyl]-1,3-Benzenedimethanol",
      "2-(Hydroxymethyl)-4-(1-Hydroxy-2-{[6-(4-Phenylbutoxy)Hexyl]amino}ethyl)Phenol",
      "2-(Hydroxymethyl)-4-[1-Hydroxy-2-({6-[(4-Phenylbutyl)Oxy]hexyl}amino)Ethyl]phenol",
      "2-(Hydroxymethyl)-4-[1-Hydroxy-2-[6-(4-Phenylbutoxy)Hexylamino]ethyl]phenol",
    ],
  },
  {
    primary_id: "D0L5ZH",
    names: ["CC-90006"],
  },
  {
    primary_id: "D0L6AF",
    names: ["AEZS-112"],
  },
  {
    primary_id: "D0L6AM",
    names: ["Imidazo[5,1-C][1,2,4]benzotriazine Derivative 4"],
  },
  {
    primary_id: "D0L6BH",
    names: ["NN9068"],
  },
  {
    primary_id: "D0L6BI",
    names: ["Glucagon Rdna"],
  },
  {
    primary_id: "D0L6BU",
    names: ["CKD-10101"],
  },
  {
    primary_id: "D0L6CM",
    names: ["Imidazole Derivative 8"],
  },
  {
    primary_id: "D0L6CN",
    names: ["N-(Phosphonacetyl)-L-Aspartate"],
  },
  {
    primary_id: "D0L6DA",
    names: [
      "Valdecoxib",
      "Bextra",
      "COX",
      "Kudeq",
      "Valdyn",
      "Pfizer Brand Of Valdecoxib",
      "Valdecoxib [USAN]",
      "SC 65872",
      "ND-0214",
      "SC-65872",
      "YM-974",
      "Valdecoxib (USAN/INN)",
      "P-(5-Methyl-3-Phenyl-4-Isoxazolyl)Benzenesulfonamide",
      "Benzenesulfonamide, 4-(5-Methyl-3-Phenyl-4-Isoxazolyl)-(9CI)",
      "4-(5-Methyl-3-Phenyl-4-Isoxazolyl)Benzenesulfonamide",
      "4-(5-Methyl-3-Phenyl-1,2-Oxazol-4-Yl)Benzenesulfonamide",
      "4-(5-Methyl-3-Phenyl-4-Isoxazolyl) Benzenesulfonamide",
      "4-(5-Methyl-3-Phenyl-Isoxazol-4-Yl)Benzenesulfonamide",
      "4-(5-Methyl-3-Phenylisoxazol-4-Yl)Benzenesulfonamide",
      "4-(Methyl-3-Phenyl-Isoxazol-4-Yl)-Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0L6DO",
    names: ["Fosfluconazole"],
  },
  {
    primary_id: "D0L6FF",
    names: ["NanoDTPA"],
  },
  {
    primary_id: "D0L6GV",
    names: ["[4',4''']-Biflavone", "CHEMBL205219", "[4'',4'''''']-Biflavone", "BDBM50183248"],
  },
  {
    primary_id: "D0L6HE",
    names: ["(S)(+)-7-Fluoro-2-(4-Fluorophenyl)Chroman-4-One", "CHEMBL601843"],
  },
  {
    primary_id: "D0L6HF",
    names: ["MDX-1459"],
  },
  {
    primary_id: "D0L6HL",
    names: ["PMID29130358-Compound-Figure17(11)"],
  },
  {
    primary_id: "D0L6HN",
    names: [
      "PHENOL",
      "Carbolic Acid",
      "108-95-2",
      "Hydroxybenzene",
      "Phenic Acid",
      "Oxybenzene",
      "Phenylic Acid",
      "Benzenol",
      "Phenyl Hydrate",
      "Monophenol",
      "Phenyl Hydroxide",
      "Phenylic Alcohol",
      "PhOH",
      "Monohydroxybenzene",
      "Phenole",
      "Paoscle",
      "Phenyl Alcohol",
      "Phenol Alcohol",
      "Izal",
      "Phenol, Liquefied",
      "Acide Carbolique",
      "Fenolo",
      "Phenosmolin",
      "Fenosmoline",
      "Fenosmolin",
      "Carbolsaure",
      "Fenol",
      "Liquid Phenol",
      "Carbolic Oil",
      "Phenol, Pure",
      "Phenol Homopolymer",
      "Phenole [German]",
      "Fenolo [Italian]",
      "Benzene, Hydroxy-",
      "Rcra Waste Number U188",
      "Campho-Phenique Gel",
      "Phenic",
      "Carbolsaure [German]",
    ],
  },
  {
    primary_id: "D0L6HU",
    names: ["CAR-T Cells Targeting CEA"],
  },
  {
    primary_id: "D0L6HX",
    names: ["PMID18072721C50", "2r4f", "GTPL3008", "BDBM20708"],
  },
  {
    primary_id: "D0L6HY",
    names: ["1-(Bis-Biphenyl-4-Yl-Methyl)-1H-Imidazole", "CHEMBL498534", "SCHEMBL11349905"],
  },
  {
    primary_id: "D0L6IF",
    names: ["AGT-0031"],
  },
  {
    primary_id: "D0L6IJ",
    names: [
      "HG-1143",
      "CCN4 Modulator (Cancer), Human Genome Sciences",
      "WISP1 Modulator (Cancer), Human Genome Sciences",
      "Connective Tissue Growth Factor-4 Modulator (Cancer), Human Genome Sciences",
      "WNT1-Inducible-Signaling Pathway Protein 1 Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0L6IQ",
    names: ["2-(4-Hydroxy-Phenyl)-4-Methoxy-Quinolin-6-Ol"],
  },
  {
    primary_id: "D0L6JE",
    names: ["Insulin-Lispro"],
  },
  {
    primary_id: "D0L6JS",
    names: ["AGN-192172", "CHEMBL49284", "BDBM50052881"],
  },
  {
    primary_id: "D0L6JX",
    names: [
      "CGP-50068",
      "(-)-3-[2-[3,4-Dihydro-2H-1-Benzopyran-3(S)-Yl]ethylamino]propionic Acid Methyl Ester Hydrochloride",
    ],
  },
  {
    primary_id: "D0L6KC",
    names: [
      "BILR-355",
      "BILR-355-BS",
      "11-Ethyl-5-Methyl-8-[2-(1-Oxidoquinolin-4-Yloxy)Ethyl]-6,11-Dihydro-5H-Dipyrido[3,2-B:2',3'-E][1,4]diazepin-6-One",
    ],
  },
  {
    primary_id: "D0L6KK",
    names: ["TF2"],
  },
  {
    primary_id: "D0L6LC",
    names: ["ISIS 25567"],
  },
  {
    primary_id: "D0L6LD",
    names: ["AZD-3199"],
  },
  {
    primary_id: "D0L6LO",
    names: ["TI4"],
  },
  {
    primary_id: "D0L6ND",
    names: [
      "AGELADINE A",
      "CHEMBL230806",
      "CHEBI:65373",
      "4-(4,5-Dibromo-1H-Pyrrol-2-Yl)-1H-Imidazo[4,5-C]pyridin-2-Amine",
      "Ageladine A, TFA",
      "SCHEMBL12563004",
      "BDBM50215926",
    ],
  },
  {
    primary_id: "D0L6NW",
    names: ["LY2605541"],
  },
  {
    primary_id: "D0L6OA",
    names: [
      "BL-1020",
      "BL 1020",
      "Perphenazine 4-Aminobutyrate",
      "Butanoic Acid, 4-Amino-, 2-(4-(3-(2-Chloro-10H-Phenothiazin-10-Yl)Propyl)-1-Piperazinyl)Ethyl Ester",
    ],
  },
  {
    primary_id: "D0L6OY",
    names: [
      "GSK-2590066A",
      "Cell Culture Based Influenza Vaccines, Chemo-Sero-Therapeutic Research Institute/ GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D0L6PL",
    names: ["3-Biphenyl-4-Ylethynyl-5-Methyl-[1,2,4]triazine", "CHEMBL226831"],
  },
  {
    primary_id: "D0L6PZ",
    names: ["PT-401"],
  },
  {
    primary_id: "D0L6QI",
    names: ["Rokitamycin"],
  },
  {
    primary_id: "D0L6QN",
    names: ["LOFENTANIL"],
  },
  {
    primary_id: "D0L6RF",
    names: ["BAY1179470"],
  },
  {
    primary_id: "D0L6RR",
    names: ["Transplant Acceptance Inducing Cells"],
  },
  {
    primary_id: "D0L6RT",
    names: ["PRAVADOLINE"],
  },
  {
    primary_id: "D0L6RV",
    names: [
      "3,5-Dibromo-2-(2,4-Dibromophenoxy)Phenol",
      "CHEMBL258224",
      "79755-43-4",
      "BPE-5",
      "AC1MJ5LD",
      "AC1Q78K6",
      "6-OH-BDE47",
      "DTXSID60229856",
      "ZINC14676777",
      "SR-1-1",
      "BDBM50232491",
      "6-Hydroxy-2,2,4,4-Tetrabromodiphenyl Ether",
      "6-Hydroxy-2,2',4,4'-Tetrabromodiphenylether",
      "3,5-Dibromo-2-(2'',4''-Dibromophenoxy)Phenol",
      "Phenol, 3,5-Dibromo-2-(2,4-Dibromophenoxy)-",
    ],
  },
  {
    primary_id: "D0L6RY",
    names: [
      "1-(4-Fluorophenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One",
      "CHEMBL203640",
      "4-Fluoro-Alpha-Pvp",
      "FPVP",
      "SCHEMBL4938098",
      "BDBM50182583",
      "4'-Fluoro-Alpha-Pyrrolidinopentiophenone",
      "1-(4-Fluorophenyl)-2-Pyrrolizinopentane-1-One",
      "4-Fluoro-Alpha-2-(1-Pyrrolidinyl)-Valerophenone",
      "1-(4-Fluorophenyl)-2-(1-Pyrrolidinyl)-1-Pentanone",
      "2-(Pyrrolidin-1-Yl)-1-(4-Fluorophenyl)Pentan-1-One",
      "1-Pentanone, 1-(4-Fluorophenyl)-2-(1-Pyrrolidinyl)-",
    ],
  },
  {
    primary_id: "D0L6SQ",
    names: ["ISIS 119284"],
  },
  {
    primary_id: "D0L6SS",
    names: ["BPX-101"],
  },
  {
    primary_id: "D0L6TP",
    names: ["N-(3-Phenoxy-4-Pyridinyl)Propanesulfonamide", "CHEMBL389485"],
  },
  {
    primary_id: "D0L6TQ",
    names: ["NCL-1"],
  },
  {
    primary_id: "D0L6UZ",
    names: ["L-374,087"],
  },
  {
    primary_id: "D0L6VH",
    names: [
      "BF-389",
      "Biofor 389",
      "UNII-MGJ3XBS5KD",
      "MGJ3XBS5KD",
      "BF 389",
      "127245-22-1",
      "4-(3,5-Di-Tert-Butyl-4-Hydroxybenzylidene)-2-Methyl-5,6-Dihydro-2H-1,2-Oxazin-3(4H)-One",
      "Dihydro-4-(3,5-Di-Tert-Butyl-4-Hydroxybenzylidene)-2-Methyl-2H-1,2-Oxazin-3(4H)-One",
      "2H-1,2-Oxazin-3(4H)-One, Dihydro-4-((3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl)Methylene)-2-Methyl-",
      "2H-1,2-Oxazin-3(4H)-One, 4-((3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl)Methylene)Dihydro-2-Methyl-",
      "Biofor-389",
      "AC1O5RBB",
      "SCHEMBL8291651",
      "LS-100029",
    ],
  },
  {
    primary_id: "D0L6VM",
    names: ["2-Fluorophenyl 4-(Dodecyloxy)Phenylcarbamate", "CHEMBL599488"],
  },
  {
    primary_id: "D0L6WT",
    names: ["MD-921"],
  },
  {
    primary_id: "D0L6XG",
    names: ["Carbamate Derivative 9"],
  },
  {
    primary_id: "D0L6XH",
    names: ["Naloxone Topical"],
  },
  {
    primary_id: "D0L6XO",
    names: ["AZD1390"],
  },
  {
    primary_id: "D0L6XQ",
    names: ["RWJ-676070"],
  },
  {
    primary_id: "D0L6XU",
    names: ["Intranasal Influenza Vaccine"],
  },
  {
    primary_id: "D0L6YF",
    names: ["N-Hydroxy-N'-(4-Methoxyphenyl)Octanediamide", "CHEMBL1091487", "BDBM50314137"],
  },
  {
    primary_id: "D0L6YI",
    names: [
      "2-(3''-(5''-Bromo-Indolyl)Ethyloxy)Adenosine",
      "CHEMBL222194",
      "MRS-3854",
      "BDBM50208783",
      "2-(3''''-(5''''-Bromo-Indolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D0L6ZE",
    names: ["MVP-009"],
  },
  {
    primary_id: "D0L6ZY",
    names: [
      "2-(Pyridin-2-Yl)-1H-Benzo[d]imidazol-5-Amine",
      "55396-63-9",
      "CHEMBL199925",
      "2-(Pyridin-2-Yl)-1H-1,3-Benzodiazol-5-Amine",
      "MLS000528495",
      "2-Pyridin-2-Yl-1H-Benzoimidazol-5-Ylamine",
      "ITFXYBZUPPBWEC-UHFFFAOYSA-N",
      "SMR000121070",
      "2-Pyridin-2-Yl-3H-Benzimidazol-5-Amine",
      "1H-Benzimidazol-5-Amine, 2-(2-Pyridinyl)-",
      "2-(Pyridin-2-Yl)-1H-1,3-Benzodiazol-6-Amine",
      "AC1LBVAV",
      "1H-Benzimidazol-6-Amine, 2-(2-Pyridinyl)-",
      "AC1Q52EP",
      "SCHEMBL8556415",
      "SCHEMBL4371835",
      "Cid_542828",
      "BDBM62926",
      "CTK1F6864",
      "DTXSID50337459",
      "MolPort-006-709-925",
      "MolPort-001-815-306",
    ],
  },
  {
    primary_id: "D0L7AG",
    names: ["ISIS 134564"],
  },
  {
    primary_id: "D0L7AO",
    names: ["Gababutin"],
  },
  {
    primary_id: "D0L7AS",
    names: ["NABILONE"],
  },
  {
    primary_id: "D0L7AT",
    names: [
      "7-Phloroethol",
      "7-Phloroeckol",
      "UNII-97GCC12YF0",
      "CHEMBL456228",
      "97GCC12YF0",
      "CHEBI:65790",
      "4-(3,5-Dihydroxyphenoxy)-8-(2,4,6-Trihydroxyphenoxy)Oxanthrene-1,3,6-Triol",
      "1-(3',5'-Dihydroxyphenoxy)-7-(2'',4'',6''-Trihydroxyphenoxy)-2,4,9-Trihydroxydibenzo-1,4-Dioxin",
      "7-Phloroglucinoleckol",
      "SCHEMBL10271928",
      "BDBM50276826",
      "4-(3,5-Dihydroxyphenoxy)-8-(2,4,6-Trihydroxyphenoxy)Dibenzo(B,E)(1,4)Dioxin-1,3,6-Triol",
      "[(2,4,9-Trihydroxydibenzo-P-Dioxin-1,7-Diyl)Dioxydibenzene]-2',3,4',5,6'-Pentol",
      "Dibenzo(B,E)(1,4)Dioxin-1,3,6-Triol, 4-(3,",
    ],
  },
  {
    primary_id: "D0L7AW",
    names: ["Benzaldehyde O-4-(Decyloxy)Phenylcarbamoyl Oxime", "CHEMBL597431"],
  },
  {
    primary_id: "D0L7AY",
    names: ["N-(Ethylphosphoryl)-L-Isoleucyl-L-Trp-NHCH3", "CHEMBL1160624"],
  },
  {
    primary_id: "D0L7BK",
    names: ["1-(1,4-Diphenylbutan-2-Yl)Piperazine", "CHEMBL384687"],
  },
  {
    primary_id: "D0L7BV",
    names: [
      "Gevotroline",
      "Carvotroline",
      "Carvotroline Hydrochloride",
      "Gevotroline Hydrochloride",
      "WY-47384",
      "WY-47791",
      "Wy-46320",
    ],
  },
  {
    primary_id: "D0L7DB",
    names: ["SCH-68631"],
  },
  {
    primary_id: "D0L7DD",
    names: ["ISIS 111085"],
  },
  {
    primary_id: "D0L7DL",
    names: ["8-Cyclohexyl-6-(4-Tolyl)-2-Phenyl-9H-Purine", "CHEMBL381112"],
  },
  {
    primary_id: "D0L7FB",
    names: ["T1-IR"],
  },
  {
    primary_id: "D0L7FM",
    names: [
      "Probenecid",
      "Apurina",
      "Bencid",
      "Benecid",
      "Benemid",
      "Benemide",
      "Benuryl",
      "Panuric",
      "Parabenem",
      "Probalan",
      "Probampacin",
      "Probecid",
      "Proben",
      "Probenecida",
      "Probenecide",
      "Probenecidum",
      "Probenemid",
      "Probenicid",
      "Probenid",
      "Probexin",
      "Prolongine",
      "Robenecid",
      "Sulprin",
      "Tubophan",
      "Uricosid",
      "Urocid",
      "Biokanol Brand Of Probenecid",
      "ICN Brand Of Probenecid",
      "IDIS Brand Of Probenecid",
      "Major Brand Of Probenecid",
      "Martec Brand Of Probenecid",
      "Merck Brand Of Probenecid",
      "Ophthalmic Brand Of Probenecid",
      "Parmed Brand Of Probenecid",
      "Probenecid Major Brand",
      "Probenecid Martec Brand",
      "Probenecid Parmed Brand",
      "Probenecid Weimer",
      "Probenecid Zenith Brand",
      "Probenecid Acid",
      "Synergid R",
      "Valdecasas Brand Of Probenecid",
      "Zenith Brand Of Probenecid",
      "Benemid (TN)",
      "Benuryl (TN)",
      "Col-BENEMID",
      "ColBenemid (Co Mponent Of)",
      "ColBenemid (Component Of)",
      "P-[Dipropylsulfamoyl]benzoic Acid",
      "Polycillin-BRB",
      "Pro-Cid",
      "Probenecida [INN-Spanish]",
      "Probenecide [INN-French]",
      "Probenecidum [INN-Latin]",
      "P-(Dipropylsulfamoyl)Benzoic Acid",
      "P-(Dipropylsulfamyl)Benzoic Acid",
      "Polycillin-PRB (Component Of)",
      "Probenecid [INN:BAN:JAN]",
      "Probenecid (JP15/USP/INN)",
      "4-((Dipropylamino)Sulfonyl)Benzoic Acid;4-(Di-N-Propylsulfamoyl)Benzoesaeure",
      "4-(Dipropylsulfamoyl)Benzoic Acid",
      "4-(N,N-Dipropylsulfamoyl)Benzoesaeure",
      "4-[(Dipropylamino)Sulfonyl]benzoic Acid",
    ],
  },
  {
    primary_id: "D0L7FS",
    names: ["N-Hydroxycarbamate Derivative"],
  },
  {
    primary_id: "D0L7GI",
    names: [
      "3-Chloro-N-(4-(Phenyldiazenyl)Phenyl)Benzamide",
      "CHEMBL566920",
      "3-Chloro-N-[4-(Phenyldiazenyl)Phenyl]benzamide",
      "AC1MEGAF",
      "MLS000571688",
      "CHEMBL1585037",
      "MolPort-002-176-797",
      "HMS2431J18",
      "ZINC18006916",
      "BDBM50303375",
      "AKOS002249913",
      "MCULE-8045658796",
      "SMR000193697",
      "3-Chloro-N-(4-Phenyldiazenylphenyl)Benzamide",
      "ST50752429",
    ],
  },
  {
    primary_id: "D0L7GN",
    names: ["Acylureido Penicillin Mezlocillin"],
  },
  {
    primary_id: "D0L7HC",
    names: ["N-Oxalyl-D-Tyrosine Derivative 5"],
  },
  {
    primary_id: "D0L7HD",
    names: ["5-Benzyl-2-(2,4-Dichlorophenoxy)Phenol", "Triclosan Derivative, 20", "CHEMBL405973", "BDBM25419"],
  },
  {
    primary_id: "D0L7HG",
    names: ["PMID29671355-Compound-39"],
  },
  {
    primary_id: "D0L7HP",
    names: ["NILTUBACIN"],
  },
  {
    primary_id: "D0L7IZ",
    names: ["JP1302"],
  },
  {
    primary_id: "D0L7JE",
    names: ["OC104-26"],
  },
  {
    primary_id: "D0L7JR",
    names: ["Lenogastrim"],
  },
  {
    primary_id: "D0L7KU",
    names: [
      "Antiarrhythmics",
      "Antiarrhythmics, BMS Pharmaceutical Research Institute",
      "BMS-208782",
      "BMS-208783",
      "IKs Channel Inhibitors, BMS",
      "SQ-23791",
    ],
  },
  {
    primary_id: "D0L7LC",
    names: [
      "Romidepsin",
      "Chromadax",
      "Istodax",
      "Antibiotic FR 901228",
      "FK 228",
      "FK228",
      "FR 901228",
      "FR901228",
      "HDInhib_000006",
      "Chromadax (TN)",
      "FK-228",
      "FK-901228",
      "FR-901228",
      "Istodax (TN)",
      "Romidepsin (USAN)",
      "Cyclo((2Z)-2-Amino-2-Butenoyl-L-Valyl-(3S,4E)-3-Hydroxy-7-Mercapto-4-Heptenoyl-D-Valyl-D-Cysteinyl), Cyclic (35)-Disulfide",
      "L-Valine, N-((3S,4E)-3-Hydroxy-7-Mercapto-1-Oxo-4-Heptenyl)-D-Valyl-D-Cysteinyl-(2Z)-2-Amino-2-Butenoxyl-, (4-1)-Lactone, Cyclic (1-2)-Disulfide",
      "(1S,4S,7Z,10S,16E,21R)-7-Ethylidene-4,21-Bis(1-Methylethyl)-2-Oxa-12,13-Dithia-5,8,20,23-Tetraazabicyclo(8.7.6)Tricos-16-Ene-3,6,9,19,22-Pentone",
      "(1S,4S,7Z,10S,16E,21R)-7-Ethylidene-4,21-Di(Propan-2-Yl)-2-Oxa-12,13-Dithia-5,8,20,23-Tetraazabicyclo[8.7.6]tricos-16-Ene-3,6,9,19,22-Pentone",
      "(1S,4S,7Z,10S,16E,21R)-7-Ethylidene-4,21-Di(Propan-2-Yl)-2-Oxa-12,13-Dithia-5,8,20,23-Tetrazabicyclo[8.7.6]tricos-16-Ene-3,6,9,19,22-Pentone",
    ],
  },
  {
    primary_id: "D0L7LH",
    names: ["Anti-IP10"],
  },
  {
    primary_id: "D0L7LT",
    names: ["DCVax-Pancreas", "Dendritic Cell-Based Immunotherapy (Pancreatic Cancer), Northwest Biotherapeutics"],
  },
  {
    primary_id: "D0L7MA",
    names: ["NP-1998"],
  },
  {
    primary_id: "D0L7MD",
    names: [
      "6-Iodo-4'-Methoxyflavone",
      "CHEMBL224064",
      "6-Iodo-2-(4-Methoxyphenyl)Chromen-4-One",
      "1033-44-9",
      "4h-1-Benzopyran-4-One,6-Iodo-2-(4-Methoxyphenyl)-",
      "AC1LAD9Q",
      "6-Iodo-4''-Methoxyflavone",
      "4'-Methoxy-6-Iodoflavanone",
      "SCHEMBL4983190",
      "BYDBCAPACOXVEH-UHFFFAOYSA-N",
      "ZINC6484552",
      "BDBM50176894",
    ],
  },
  {
    primary_id: "D0L7MI",
    names: ["7-Hydroxy-6-Nitro-2-Phenyl-Chromen-4-One", "CHEMBL56730", "6-Nitro-7-Hydroxyflavone"],
  },
  {
    primary_id: "D0L7MK",
    names: ["TR-4"],
  },
  {
    primary_id: "D0L7MM",
    names: ["4-((Naphthalen-2-Ylamino)Methyl)Benzene-1,2-Diol", "CHEMBL1240677", "BDBM50326006"],
  },
  {
    primary_id: "D0L7MO",
    names: ["F-80002-RR", "Cancer Therapy, FAES Farma"],
  },
  {
    primary_id: "D0L7MP",
    names: ["3-Acylidene-2-Oxoindole Derivative 2"],
  },
  {
    primary_id: "D0L7OC",
    names: ["4-(Butylthio)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL462665", "BDBM50247959"],
  },
  {
    primary_id: "D0L7OJ",
    names: ["CAR-T Cells Targeting Mesothelin"],
  },
  {
    primary_id: "D0L7OM",
    names: ["STEPHOLIDINE"],
  },
  {
    primary_id: "D0L7OR",
    names: ["Diaminopyridine Analog 5"],
  },
  {
    primary_id: "D0L7OY",
    names: ["D-7193", "D-7042"],
  },
  {
    primary_id: "D0L7PA",
    names: ["SB 258585"],
  },
  {
    primary_id: "D0L7PG",
    names: ["CKD-406"],
  },
  {
    primary_id: "D0L7PI",
    names: [
      "7beta, 25-Dihydroxycholesterol",
      "CHEMBL3311219",
      "(3S,7R,8S,9S,10R,13R,14S,17R)-17-[(2R)-6-Hydroxy-6-Methylheptan-2-Yl]-10,13-Dimethyl-2,3,4,7,8,9,11,12,14,15,16,17-Dodecahydro-1H-Cyclopenta[a]phenanthrene-3,7-Diol",
      "7beta,25-Dihydroxycholesterol",
      "AC1LAQHU",
      "GTPL4355",
      "SCHEMBL2485860",
      "7.beta.,25-Dihydroxycholesterol",
      "BDBM50045543",
      "(3.beta.,7.beta.)-7,25-Dihydroxycholest-5-En-3-Ol",
    ],
  },
  {
    primary_id: "D0L7PK",
    names: ["V-0191"],
  },
  {
    primary_id: "D0L7PQ",
    names: ["AcDif-Ile-Cha-Cys", "CHEMBL293631"],
  },
  {
    primary_id: "D0L7QB",
    names: ["Alfaferone", "Alfaferon", "Cilferon-A", "Alpha-IFN, ASW", "Interferon, ASW (Alpha)"],
  },
  {
    primary_id: "D0L7QE",
    names: ["Mycophenolic Acid/Nucleotide Derivative 9"],
  },
  {
    primary_id: "D0L7QR",
    names: ["VL-01"],
  },
  {
    primary_id: "D0L7RB",
    names: [
      "1-(4-Hydroxyphenyl)Prop-2-En-1-One",
      "4-Acryloylphenol",
      "CHEMBL378577",
      "95605-38-2",
      "4-Hydroxyphenyl Vinyl Ketone",
      "SCHEMBL2394894",
      "GTPL8841",
      "MolPort-007-991-476",
      "NKPPNPJUBLEKAD-UHFFFAOYSA-N",
      "ZINC28567957",
      "BDBM50177407",
      "AKOS005200150",
      "FCH1599833",
      "1-(4-Hydroxyphenyl)-2-Propene-1-One",
      "EN300-246996",
    ],
  },
  {
    primary_id: "D0L7RJ",
    names: [
      "DP-109",
      "DP-460",
      "DP-460)",
      "BAPTA Analog, D-Pharm",
      "Membrane Active Chelator Derivative Of BAPTA (Alzheimer's Disease), D-Pharm",
    ],
  },
  {
    primary_id: "D0L7RU",
    names: ["5,7-Dichloro-4-Hydroxy-3-Phenyl-1H-Quinolin-2-One", "CHEMBL104298"],
  },
  {
    primary_id: "D0L7SK",
    names: ["PMID26924192-Compound-30"],
  },
  {
    primary_id: "D0L7SY",
    names: ["Delequamine Hydrochloride", "Delquamine", "RS-15385-197", "RS-15385-FP", "RS-15385-FPh"],
  },
  {
    primary_id: "D0L7TA",
    names: ["MIN-101"],
  },
  {
    primary_id: "D0L7TN",
    names: ["GSK3003891A"],
  },
  {
    primary_id: "D0L7TW",
    names: ["Phenyl 4-(Heptyloxy)Phenylcarbamate"],
  },
  {
    primary_id: "D0L7UB",
    names: ["HEPTANOATE"],
  },
  {
    primary_id: "D0L7UQ",
    names: [
      "Prothionamide",
      "Ektebin",
      "Peteha",
      "Prothionamidum",
      "Protionamid",
      "Protionamida",
      "Protionamide",
      "Protionamidum",
      "Protionizina",
      "Tebeform",
      "Trevintix",
      "Tuberex",
      "RP 9778",
      "TH 1321",
      "Prothionamide (JP15)",
      "Protionamida [INN-Spanish]",
      "Protionamide (INN)",
      "Protionamide [INN:DCF]",
      "Protionamidum [INN-Latin]",
      "TH-1321",
      "Trevintix (TN)",
      "2-Propyl-4-Pyridinecarbothioamide",
      "2-Propyl-4-Thiocarbamoylpyridine",
      "2-Propyl-Thioisonicotinamide",
      "2-Propylisonicotinylthioamide",
      "2-Propylthioisonicotinamide",
      "2-Propylpyridine-4-Carbothioamide",
      "4-Pyridinecarbothioamide, 2-Propyl-(9CI)",
      "9778 R.P",
      "9778 R.P.",
    ],
  },
  {
    primary_id: "D0L7UT",
    names: ["IMX-MSP3"],
  },
  {
    primary_id: "D0L7VC",
    names: ["GI-6207"],
  },
  {
    primary_id: "D0L7VL",
    names: ["LIDOFLAZINE"],
  },
  {
    primary_id: "D0L7WS",
    names: ["RX-3117", "Antimetabolite (Cancer), Rexahn", "Antimetabolite (Cancer), Rexahn/ Teva"],
  },
  {
    primary_id: "D0L7XM",
    names: ["PMID25656651-Compound-20b"],
  },
  {
    primary_id: "D0L7YL",
    names: ["Rhuph20"],
  },
  {
    primary_id: "D0L7YW",
    names: ["Pyridine Derivative 12"],
  },
  {
    primary_id: "D0L7ZD",
    names: [
      "UNC9975",
      "CHEMBL2165119",
      "7-(4-(4-(2,3-Dichlorophenyl)-1,4-Diazepan-1-Yl)Butoxy)-3,4-Dihydro-1,8-Naphthyridin-2(1h)-One",
      "SCHEMBL252358",
      "GTPL7650",
      "JQSRFMXTGAVHIR-UHFFFAOYSA-N",
      "BDBM50395569",
      "7-[4-[4-(2,3-Dichlorophenyl)Hexahydro-1H-1,4-Diazepine-1-Yl]butoxy]-3,4-Dihydro-1,8-Naphthyridine-2(1H)-One",
    ],
  },
  {
    primary_id: "D0L7ZX",
    names: ["BAY-17-1998", "BAY-35-8535"],
  },
  {
    primary_id: "D0L8AJ",
    names: ["ISIS 20994"],
  },
  {
    primary_id: "D0L8BN",
    names: ["TOPOSTATIN"],
  },
  {
    primary_id: "D0L8CF",
    names: ["Quinolinic Acid"],
  },
  {
    primary_id: "D0L8CJ",
    names: ["MV-9411"],
  },
  {
    primary_id: "D0L8CK",
    names: [
      "CALCEOLARIOSIDE A",
      "Calceolarioside A",
      "CHEMBL481635",
      "84744-28-5",
      "AC1NRV65",
      "MEGxp0_000507",
      "ACon1_000369",
      "MolPort-001-740-672",
      "ZINC31156700",
      "BDBM50259819",
      "MCULE-7153533238",
      "NCGC00169142-01",
      "Calceolarioside A, &gt",
      "[(2R,3S,4R,5R,6R)-6-[2-(3,4-Dihydroxyphenyl)Ethoxy]-4,5-Dihydroxy-2-(Hydroxymethyl)Tetrahydropyran-3-Yl] (E)-3-(3,4-Dihydroxyphenyl)Prop-2-Enoate",
      "Beta-D-Glucopyranoside, 2-(3,4-Dihydroxyphenyl)Ethyl, 4-(3-(3,4-Dihydroxyphenyl)-2-Propenoate), (E)-",
      "Beta-D-Glucopyranoside, 2-(3,4-Dihydroxyphenyl)Ethyl 4-O-[(2E)-3-(3",
    ],
  },
  {
    primary_id: "D0L8CS",
    names: ["Vaccine, Tetravalent, Small-Cell Lung Cancer, MabVax"],
  },
  {
    primary_id: "D0L8CX",
    names: [
      "4-Bromo-2,6-Diisopropyl-Phenol",
      "4-BROMO-2,6-DIISOPROPYLPHENOL",
      "CHEMBL54497",
      "2432/3/3",
      "SCHEMBL3640323",
      "2,6-Diisopropyl-4-Bromophenol",
      "CTK5I6265",
      "4-Bromo-2,6-Di-Isopropylphenol",
      "MolPort-002-462-054",
      "QNJVELOLCDKQBN-UHFFFAOYSA-N",
      "ZINC13779577",
      "BDBM50064417",
      "AKOS028112327",
      "4-Bromo-2,6-Bis(Propan-2-Yl)Phenol",
      "AS-3289",
      "Phenol, 4-Bromo-2,6-Bis(1-Methylethyl)-",
    ],
  },
  {
    primary_id: "D0L8DL",
    names: ["WIN-63395"],
  },
  {
    primary_id: "D0L8DV",
    names: ["SC46275"],
  },
  {
    primary_id: "D0L8DZ",
    names: [
      "8-Phenyl-3,7-Dihydro-Purine-2,6-Dione",
      "8-Phenylxanthine",
      "2879-14-3",
      "UNII-0S2BVG6C31",
      "CHEMBL285191",
      "0S2BVG6C31",
      "1H-Purine-2,6-Dione, 3,9-Dihydro-8-Phenyl-",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-8-Phenyl-",
      "Xanthine, 8-Phenyl-",
      "SCHEMBL378879",
      "CTK0J1822",
      "DTXSID50485464",
      "BDBM50042214",
      "ZINC13472986",
      "8-Phenyl-1H-Purine-2,6(3H,7H)-Dione",
    ],
  },
  {
    primary_id: "D0L8EN",
    names: [
      "3-(4-(4-Hydroxyphenyl)Thiazol-2-Ylamino)Phenol",
      "CHEMBL552961",
      "3-{[4-(4-Hydroxyphenyl)-1,3-Thiazol-2-Yl]amino}phenol",
      "3-[[4-(4-Hydroxyphenyl)-1,3-Thiazol-2-Yl]amino]phenol",
      "AC1MOQ7Z",
      "BDBM50293580",
      "STK163120",
      "AKOS005408679",
      "MCULE-5910300038",
      "NCGC00285022-01",
    ],
  },
  {
    primary_id: "D0L8EO",
    names: ["Anti-TLR3 Mabs", "Anti-TLR3 MAbs (Autoimmune And Inflammatory Disorders)"],
  },
  {
    primary_id: "D0L8EQ",
    names: ["CB-182804"],
  },
  {
    primary_id: "D0L8FE",
    names: ["PMID26004420-Compound-WO2012058116A"],
  },
  {
    primary_id: "D0L8FW",
    names: [
      "2-(2-Methyl-Benzoyl)-Cyclohexane-1,3-Dione",
      "CHEMBL355470",
      "2-(2-Methylbenzoyl)Cyclohexane-1,3-Dione",
      "SCHEMBL10191381",
    ],
  },
  {
    primary_id: "D0L8GO",
    names: ["RK-28", "1-(4-Hydroxy-2-Butenoxymethyl)-2-Nitroimidazole"],
  },
  {
    primary_id: "D0L8HB",
    names: ["MEDI9197"],
  },
  {
    primary_id: "D0L8HO",
    names: [
      "RO-316233",
      "119139-23-0",
      "Bisindolylmaleimide Iv",
      "3,4-Di(1H-Indol-3-Yl)-1H-Pyrrole-2,5-Dione",
      "Arcyriarubin A",
      "3,4-Bis(3-Indolyl)Maleimide",
      "3,4-Di-1H-Indol-3-Yl-1H-Pyrrole-2,5-Dione",
      "UNII-MBK3OO5K8T",
      "BIM IV",
      "3,4-Bis(1H-Indol-3-Yl)Pyrrole-2,5-Dione",
      "MBK3OO5K8T",
      "CHEMBL266487",
      "3,4-Bis(1H-Indol-3-Yl)-2,5-Dihydro-1H-Pyrrole-2,5-Dione",
      "DQYBRTASHMYDJG-UHFFFAOYSA-N",
      "2,3-Bis(1H-Indol-3-Yl)Maleimide",
      "1H-Pyrrole-2,5-Dione, 3,4-Di-1H-Indol-3-Yl-",
      "Ro-31-6233",
      "AK-15401",
      "3,4-Bis(3-Indolyl)-1H-Pyrrole-2,5-Dione",
      "Bisindoylmaleimide",
      "Bisindolyl Deriv. 3",
    ],
  },
  {
    primary_id: "D0L8IH",
    names: ["IMA-910"],
  },
  {
    primary_id: "D0L8IO",
    names: ["AVN 322"],
  },
  {
    primary_id: "D0L8KJ",
    names: ["Oglemilast"],
  },
  {
    primary_id: "D0L8KK",
    names: ["ALB-137391(A)", "5-HT3 Receptor Partial Agonists (IBS), Celentyx/AMRI"],
  },
  {
    primary_id: "D0L8KL",
    names: [
      "5-Naphthalen-2-Yl-Oxazole",
      "143659-20-5",
      "5-(Naphthalen-2-Yl)-1,3-Oxazole",
      "5-(2-Naphthalenyl)Oxazole",
      "5-(Naphthalen-2-Yl)Oxazole",
      "Oxazole,5-(2-Naphthalenyl)-",
      "5-(2-Naphthyl)-1,3-Oxazole",
      "5-(2-Naphthyl)Oxazole",
      "ACMC-1C0FI",
      "5-(2-Naph-Thyl-)Oxazol",
      "SCHEMBL4500585",
      "BDBM8928",
      "CHEMBL193653",
      "5-Naphth-2-Yl-1,3-Oxazole",
      "CTK4C3747",
      "DTXSID10460853",
      "MolPort-001-758-948",
      "UQGCSVOKHFBQIP-UHFFFAOYSA-N",
      "Oxazole-Substituted Naphthalene 29",
      "KS-00001PB5",
      "ZX-AT019030",
      "ZINC13674492",
      "AKOS005254941",
      "MCULE-4854701546",
      "OR12565",
      "GL-1006",
      "KB-269976",
      "AX8104945",
      "DB-057999",
    ],
  },
  {
    primary_id: "D0L8KM",
    names: ["PMID29473428-Compound-14"],
  },
  {
    primary_id: "D0L8LX",
    names: ["H-Tyr-Gly-Gly-Phe-Met-NH2"],
  },
  {
    primary_id: "D0L8MO",
    names: [
      "ME-3407",
      "EF-4040",
      "2-(Butoxycarbonylmethylsulfinyl)Thiazolo[5,4-B]pyridine",
      "2-(Thiazolo[5,4-B]pyridin-2-Ylsulfinyl)Acetic Acid Butyl Ester",
    ],
  },
  {
    primary_id: "D0L8MR",
    names: ["MESCALINE"],
  },
  {
    primary_id: "D0L8ND",
    names: ["5-Bromo-2,3,4,9-Tetrahydro-1H-Beta-Carboline", "CHEMBL6426", "BDBM50136501", "AKOS023651923"],
  },
  {
    primary_id: "D0L8NF",
    names: ["Phytosphingosine 1-Phosphate", "PhytoS1P"],
  },
  {
    primary_id: "D0L8NG",
    names: [
      "(5-Methoxy-Chroman-3-Yl)-Dipropyl-Amine",
      "5-Meo-Dpac",
      "5-Methoxy-3-(Di-N-Propylamino)Chroman",
      "CHEMBL283606",
      "3-Dipropylamino-5-Methyloxychroman",
      "5-Methoxy-3-(Dipropylamino)Chroman",
      "AC1MIZ4R",
      "110927-00-9",
      "SCHEMBL2217080",
      "CTK8G5655",
      "GOWYIQOIWRLZLO-UHFFFAOYSA-N",
      "BDBM50036862",
      "3-(Di-N-Propylamino)-5-Methoxychroman",
      "L023817",
      "5-Methoxy-N,N-Dipropyl-3,4-Dihydro-2H-Chromen-3-Amine",
      "5-Methoxy-3,4-Dihydro-N,N-Dipropyl-2H-[1]-Benzopyran-3-Amine",
      "2H-1-Benzopyran-3-Amine, 3,4-Dihydro-5-Methoxy-N,N-Dipropyl-",
    ],
  },
  {
    primary_id: "D0L8OE",
    names: ["AC3056"],
  },
  {
    primary_id: "D0L8OJ",
    names: ["Alstiphyllanine D", "CHEMBL540945"],
  },
  {
    primary_id: "D0L8ON",
    names: ["Rx-101"],
  },
  {
    primary_id: "D0L8OP",
    names: ["AVP-21D9"],
  },
  {
    primary_id: "D0L8OZ",
    names: ["EG-013"],
  },
  {
    primary_id: "D0L8PE",
    names: ["Bb21217"],
  },
  {
    primary_id: "D0L8PG",
    names: ["SK-126"],
  },
  {
    primary_id: "D0L8PH",
    names: [
      "Biferonex",
      "Beneferon",
      "Beneserin",
      "Interferon Beta 1a, BioPartners",
      "Interferon Beta 1a, Rentschler/Berlex/Schering",
      "Beta-Interferon (1a), Schering/Dr Rentschler",
    ],
  },
  {
    primary_id: "D0L8PI",
    names: ["SCH-23390"],
  },
  {
    primary_id: "D0L8PO",
    names: ["YC-12"],
  },
  {
    primary_id: "D0L8PZ",
    names: ["ARQ 751"],
  },
  {
    primary_id: "D0L8QD",
    names: ["Pyridazinone Derivative 1"],
  },
  {
    primary_id: "D0L8QQ",
    names: ["VELNACRINE"],
  },
  {
    primary_id: "D0L8QX",
    names: ["Homorisedronate"],
  },
  {
    primary_id: "D0L8RC",
    names: ["5-FU, Galectin", "Carbasome/5-FU, Davanat-1, Davanat/5-FU"],
  },
  {
    primary_id: "D0L8RD",
    names: ["INT-0003/2005"],
  },
  {
    primary_id: "D0L8RG",
    names: [
      "CI-986",
      "CI 986",
      "5-(3,5-Di-Tert-Butyl-4-Hydroxyphenyl)-1,3,4-Thiadiazole-2(3H)-Thione",
      "5-(3,5-Bis(1,1-Dimethylethyl)-4-(Hydroxyphenyl))-1,3,4-Thiadiazole-2(3H)-Thione Choline Salt",
      "Ethanaminium, 2-Hydroxy-N,N,N-Trimethyl-, Salt With 5-(3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl)-1,3,4-Thiadiazole-2(3H)-Thione (1:1)",
      "130116-16-4",
      "AC1MI25H",
      "C16H22N2OS2.C5H14NO",
      "LS-172752",
      "2,6-Ditert-Butyl-4-(2-Sulfanylidene-3H-1,3,4-Thiadiazol-5-Yl)Phenolate",
    ],
  },
  {
    primary_id: "D0L8SN",
    names: ["P-IODOAMPHETAMINE"],
  },
  {
    primary_id: "D0L8SP",
    names: ["MTL-004"],
  },
  {
    primary_id: "D0L8TL",
    names: ["YP-008"],
  },
  {
    primary_id: "D0L8TN",
    names: [
      "BU-4514N",
      "BU 4514N",
      "AC1Q6BPU",
      "AC1L4UFU",
      "SCHEMBL194669",
      "4-[(2-{2-[(5-Amino-6-Methyltetrahydro-2h-Pyran-2-Yl)Oxy]propyl}-1,3,6-Trimethyl-1,2,4a,5,6,7,8,8a-Octahydronaphthalen-1-Yl)Carbonyl]-5-Hydroxy-1,2-Dihydro-3h-Pyrrol-3-One",
    ],
  },
  {
    primary_id: "D0L8TP",
    names: ["Ektomun", "Antibody (Melanoma), Trion"],
  },
  {
    primary_id: "D0L8UL",
    names: ["Pseudopalmatine Trifluoroacetate"],
  },
  {
    primary_id: "D0L8UQ",
    names: ["FKB327"],
  },
  {
    primary_id: "D0L8US",
    names: ["PG-11144"],
  },
  {
    primary_id: "D0L8VA",
    names: ["CSL-112"],
  },
  {
    primary_id: "D0L8VH",
    names: ["Cyclopentane Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0L8VT",
    names: ["DTPA Conjugate", "CHEMBL415548"],
  },
  {
    primary_id: "D0L8WA",
    names: ["AVAC"],
  },
  {
    primary_id: "D0L8WH",
    names: [
      "ABT-737",
      "852808-04-9",
      "ABT 737",
      "ABT737",
      "UNII-Z5NFR173NV",
      "Z5NFR173NV",
      "4-[4-[(4'-Chloro[1,1'-Biphenyl]-2-Yl)Methyl]-1-Piperazinyl]-N-[[4-[[(1R)-3-(Dimethylamino)-1-[(Phenylthio)Methyl]propyl]amino]-3-Nitrophenyl]sulfonyl]benzamide",
      "CHEMBL376408",
      "C42H45ClN6O5S2",
      "4-{4-[(4'-Chlorobiphenyl-2-Yl)Methyl]piperazin-1-Yl}-N-{[4-({(1r)-3-(Dimethylamino)-1-[(Phenylthio)Methyl]propyl}amino)-3-Nitrophenyl]sulfonyl}benzamide",
    ],
  },
  {
    primary_id: "D0L8WJ",
    names: ["[D-4Aph(CO-NH-OH)6]degarelix", "CHEMBL427976"],
  },
  {
    primary_id: "D0L8WW",
    names: ["ISIS 134606"],
  },
  {
    primary_id: "D0L8YH",
    names: ["AntiHer2-XTEN-Docetaxel"],
  },
  {
    primary_id: "D0L8YY",
    names: ["SR-14136"],
  },
  {
    primary_id: "D0L8ZG",
    names: [
      "P-Tolylboronic Acid",
      "4-Tolylboronic Acid",
      "4-Methylphenylboronic Acid",
      "5720/5/8",
      "(4-Methylphenyl)Boronic Acid",
      "P-Tolueneboronic Acid",
      "4-Methylbenzeneboronic Acid",
      "P-Methylbenzeneboronic Acid",
      "Boronic Acid, (4-Methylphenyl)-",
      "Boronic Acid, P-Tolyl-",
      "Tolyl Boronic Acid",
      "4-Methylphenylboric Acid",
      "(4-Methylphenyl)Boranediol",
      "4-Methylphenyl Boronic Acid",
      "917814-66-5",
      "P-Methylborophenylic Acid",
      "P-Methylphenylboronic Acid",
      "UNII-YJM4GM7K66",
      "YJM4GM7K66",
      "4-Methyl Phenyl Boronic Acid",
      "CHEMBL140780",
      "BIWQNIMLAISTBV-UHFFFAOYSA-N",
      "NSC 62870",
    ],
  },
  {
    primary_id: "D0L8ZX",
    names: ["OX-NLA"],
  },
  {
    primary_id: "D0L9AJ",
    names: ["Seletracetam"],
  },
  {
    primary_id: "D0L9AS",
    names: ["Peptide Analog 66"],
  },
  {
    primary_id: "D0L9AX",
    names: [
      "Ac-DEVD-CHO",
      "169332-60-9",
      "Caspase-3 Inhibitor I",
      "N-Acetyl-Asp-Glu-Val-Asp-Al",
      "Acetyl-Aspartyl-Glutamyl-Valyl-Aspartal",
      "Ac-Asp-Glu-Val-Asp-Aldehyde",
      "AC-ASP-GLU-VAL-ASP-H",
      "CHEMBL417149",
      "CHEBI:59385",
      "184179-08-6",
      "(4S,7S,10S,13S)-7-(2-Carboxyethyl)-4-(Carboxymethyl)-13-Formyl-10-Isopropyl-2,5,8,11-Tetraoxo-3,6,9,12-Tetraazapentadecan-15-Oic Acid",
      "Acetyl-Asp-Glu-Val-Asp-Aldehyde",
      "Acetyl-Asp-Glu-Val-Asp Aldehyde",
      "N-Acetyl-Asp-Glu-Val-Asp Aldehyde",
      "N-Acetyl-Asp-Glu-Val-Asp-Aldehyde",
    ],
  },
  {
    primary_id: "D0L9BG",
    names: ["PMID29473428-Compound-10"],
  },
  {
    primary_id: "D0L9BK",
    names: ["Ferric Hexacyanoferrate(II)"],
  },
  {
    primary_id: "D0L9BT",
    names: [
      "7-[1,2,4]Triazol-4-Ylmethyl-Chromen-4-One",
      "CHEMBL157925",
      "4H-1-Benzopyran-4-One, 7-(4H-1,2,4-Triazol-4-Ylmethyl)-",
      "BDBM50097361",
      "331684-17-4",
    ],
  },
  {
    primary_id: "D0L9BV",
    names: ["TDM-621"],
  },
  {
    primary_id: "D0L9BW",
    names: ["JNJ-1136"],
  },
  {
    primary_id: "D0L9CD",
    names: [
      "AZD-2115",
      "Dual Action MABA (COPD), AstraZeneca",
      "Dual Action Muscarinic Acetylcholine Receptor Antagonist/Beta 2 Adrenoceptor Agonist (COPD), AstraZeneca",
    ],
  },
  {
    primary_id: "D0L9DR",
    names: ["SB-782443"],
  },
  {
    primary_id: "D0L9DW",
    names: ["ISIS 20993"],
  },
  {
    primary_id: "D0L9EF",
    names: [
      "3-O-METHYLQUERCETIN",
      "1486-70-0",
      "2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-3-Methoxy-4H-Chromen-4-One",
      "Quercetin 3-O-Methyl Ether",
      "3-Methylquercetol",
      "NSC 154016",
      "Quercetin-3-O-Methyl Ether",
      "2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-3-Methoxy-4H-1-Benzopyran-4-One",
      "UNII-7J92C373RH",
      "CHEMBL163316",
      "CHEBI:16860",
      "7J92C373RH",
      "NSC-154016",
      "3-Methoxy-5,7,3',4'-Tetrahydroxyflavone",
      "4H-1-Benzopyran-4-One, 2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-3-Methoxy-",
    ],
  },
  {
    primary_id: "D0L9FD",
    names: ["PMID21295468C47"],
  },
  {
    primary_id: "D0L9FL",
    names: ["Coumarin/Resveratrol Hybrid Derivative 1"],
  },
  {
    primary_id: "D0L9GG",
    names: [
      "Alprazolam",
      "Alcelam",
      "Algad",
      "Alpaz",
      "Alplax",
      "Alpram",
      "Alprax",
      "Alprazolamum",
      "Alprazolan",
      "Alpronax",
      "Alprox",
      "Alviz",
      "Alzam",
      "Alzolam",
      "Alzon;Anpress",
      "ApoAlpraz",
      "Azor",
      "Bestrol",
      "Cassadan",
      "Constan",
      "Esparon",
      "Frontal",
      "Helex",
      "Intensol",
      "Ksalol",
      "Mialin",
      "Neurol",
      "Niravam",
      "NovoAlprazol",
      "NuAlpraz",
      "Panistat",
      "Panix",
      "Pharnax",
      "Prazam",
      "Prazolan",
      "Prinox",
      "Ralozam",
      "Relaxol",
      "Restyl",
      "Solanax",
      "Tafil",
      "Tensivan",
      "Tranax",
      "Trankimazin",
      "Tranquinal",
      "Tricalma",
      "Unilan",
      "Valeans",
      "Xanagis",
      "Xanax",
      "Xanolam",
      "Xanor",
      "Zacetin",
      "Zanapam",
      "Zaxan",
      "Zenax",
      "Zolam",
      "Zolan",
      "Zolarem",
      "Zoldac",
      "Zoldax",
      "Zopax",
      "Zopic",
      "Zotran",
      "Alphapharm Brand Of Alprazolam",
      "Alprazolam Alphapharm Brand",
      "Alprazolam Apotex Brand",
      "Alprazolam Kenral Brand",
      "Alprazolam Novopharm Brand",
      "Alprazolam Orion Brand",
      "Alprazolam Pfizer Brand",
      "Alprazolam Temmler Brand",
      "Alprazolam Extended Release Tablets",
      "Alprazolam Intensol",
      "Alprazolam Solution",
      "Apo Alpraz",
      "Apotex Brand Of Alprazolam",
      "Arzneimittelwerk Dresden Brand Of Alprazolam",
      "Kenral Brand Of Alprazolam",
      "Novo Alprazol",
      "Novopharm Brand Of Alprazolam",
      "Nu Alpraz",
      "Nu Pharm Brand Of Alprazolam",
      "Orion Brand Of Alprazolam",
      "Pfizer Brand Of Alprazolam",
      "Tafil D",
      "Temmler Brand Of Alprazolam",
      "Xanax TS",
      "Xanax XR",
      "D 65MT",
      "D65MT",
      "TGAR01P",
      "Tus 1",
      "U 31889",
      "AP-1002",
      "AZ-002",
      "Alprazolam Nu-Pharm Brand",
      "Alprazolam-D5",
      "Alprazolamum [INN-Latin]",
      "Apo-Alpraz",
      "D-65MT",
      "Gen-Alprazolan",
      "Niravam (TN)",
      "Novo-Alprazol",
      "Nu-Alpraz",
      "Nu-Pharm Brand Of Alprazolam",
      "Staccato-Alprazolam",
      "TUS-1",
      "U-31889",
      "U31,889",
      "Xanax (TN)",
      "Xanor (TN)",
      "U-31,889",
      "Alprazolam (JP15/USP/INN)",
      "Alprazolam [USAN:INN:BAN:JAN]",
      "8-Chloro-1-Methyl-6-(Phenyl-D5)-4H-(1,2,4)Triazolo[4,3-A][1,4]benzodiazepine",
      "8-Chloro-1-Methyl-6-Phenyl-4H-[1,2,4]triazolo[4,3-A][1,4]benzodiazepine",
      "8-Chloro-1-Methyl-6-Phenyl-4H-S-Triazolo(4,3-A)(1,4)Benzodiazepine",
      "8-Chloro-1-Methyl-6-Phenyl-4H-S-Triazolo(4,3-Alpha)(1,4)Benzodiazepine",
      "8-Chloro-1-Methyl-6-Phenyl-4H-S-Triazolo[4,3-A][1,4]benzodiazepine",
    ],
  },
  {
    primary_id: "D0L9GR",
    names: ["Very Low Dose (VLD) Cyclobenzaprine"],
  },
  {
    primary_id: "D0L9HG",
    names: ["Rotavirus Vaccine I321"],
  },
  {
    primary_id: "D0L9HX",
    names: ["Voclosporin"],
  },
  {
    primary_id: "D0L9HZ",
    names: ["EP1090"],
  },
  {
    primary_id: "D0L9IA",
    names: ["Glufosfamide"],
  },
  {
    primary_id: "D0L9JE",
    names: ["S3969"],
  },
  {
    primary_id: "D0L9JX",
    names: ["DNX-2401"],
  },
  {
    primary_id: "D0L9KH",
    names: ["APD-334"],
  },
  {
    primary_id: "D0L9KK",
    names: ["K-Ras(G12C) Inhibitor 6"],
  },
  {
    primary_id: "D0L9KW",
    names: ["ISIS 298712"],
  },
  {
    primary_id: "D0L9LH",
    names: ["[32P]S1P", "[32P]-S1P"],
  },
  {
    primary_id: "D0L9MM",
    names: ["Split Virion H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0L9NB",
    names: ["H-Dmt-Tic-NH-CH2-ImidPh", "CHEMBL259917"],
  },
  {
    primary_id: "D0L9NX",
    names: ["Satavaptan"],
  },
  {
    primary_id: "D0L9OF",
    names: ["Adagloxad Simolenin"],
  },
  {
    primary_id: "D0L9OS",
    names: ["FR-73966"],
  },
  {
    primary_id: "D0L9OU",
    names: [
      "PD-138916",
      "CHEMBL337661",
      "CHEMBL355656",
      "BDBM50007442",
      "4-[(R)-2-[(R)-2-(Adamantan-2-Yloxycarbonylamino)-3-(1H-Indol-3-Yl)-2-Methylpropanoylamino]-3-Phenylpropylamino]-4-Oxobutyric Acid",
    ],
  },
  {
    primary_id: "D0L9PA",
    names: [
      "X-396",
      "ALK Inhibitors (Cancer)",
      "X-276",
      "X-353",
      "X-376",
      "ALK Inhibitors (Cancer), Xcovery",
      "Anaplastic Lymphoma Kinase Inhibitors (Cancer), Xcovery",
    ],
  },
  {
    primary_id: "D0L9PF",
    names: ["LY-73497"],
  },
  {
    primary_id: "D0L9PL",
    names: ["UC-38"],
  },
  {
    primary_id: "D0L9PX",
    names: ["F10"],
  },
  {
    primary_id: "D0L9PY",
    names: ["CP-66948"],
  },
  {
    primary_id: "D0L9QM",
    names: ["MK-7145"],
  },
  {
    primary_id: "D0L9RP",
    names: ["PBC-264"],
  },
  {
    primary_id: "D0L9SL",
    names: ["3-Butyl-[1,4]oxazepan-(5Z)-Ylideneamine", "CHEMBL89282", "SCHEMBL8550109", "BDBM50104656"],
  },
  {
    primary_id: "D0L9SN",
    names: ["NAN-190"],
  },
  {
    primary_id: "D0L9TD",
    names: ["HIV Vaccine"],
  },
  {
    primary_id: "D0L9UU",
    names: [
      "Gentamicin",
      "Alcomicin",
      "Apogen",
      "Bristagen",
      "Cidomycin",
      "GENTAMYCIN",
      "Garamycin",
      "Garasol",
      "Gentacidin",
      "Gentacycol",
      "Gentafair",
      "Gentak",
      "Gentamar",
      "Gentamicina",
      "Gentamicine",
      "Gentamicins",
      "Gentamicinum",
      "Gentamycinum",
      "Gentavet",
      "Gentocin",
      "Jenamicin",
      "Refobacin",
      "Uromycine",
      "Garamycin Otic Solution",
      "Genoptic Liquifilm",
      "Gentamcin Sulfate",
      "Gentamicin Sulphate Sterile",
      "Refobacin TM",
      "Gentamicin C1",
      "G-Mycin",
      "G-Myticin",
      "Garamycin (TN)",
      "Gentamicin (BAN)",
      "Gentamicin (TN)",
      "Gentamicina [INN-Spanish]",
      "Gentamicine [INN-French]",
      "Gentamicinum [INN-Latin];Gentamycin-Creme",
      "Gentamycin-Creme [German]",
      "Ocu-Mycin",
      "Spectro-Genta",
      "U-Gencin",
      "Genoptic S.O.P.",
      "O-2-Amino-2,3,4,6,7-Pentadeoxy-6-(Methylamino)-Alpha-D-Ribo-Heptopyranosyl-(1-4)-O-(3-Deoxy-4-C-Methyl-3-(Methylamino)-Beta-L-Arabinopyranosyl-(1-6))-2-Deoxy-D-Streptamine",
      "(1R,2S,3S,4R,6S)-4,6-Diamino-3-[3-Deoxy-4-C-Methyl-3-(Methylamino)-Beta-L-Arabinopyranosyloxy]-2-Hydroxycyclohexyl 2-Amino-2,3,4,6,7-Pentadeoxy-6-(Methylamino)-Beta-L-Lyxo-Heptopyranoside",
      "(1R,2S,3S,4R,6S)-4,6-Diamino-3-{[3-Deoxy-4-C-Methyl-3-(Methylamino)-Beta-L-Arabinopyranosyl]oxy}-2-Hydroxycyclohexyl (6x)-2-Amino-2,3,4,6,7-Pentadeoxy-6-(Methylamino)-Alpha-D-Erythro-Heptopyranoside",
      "(2R,3R,4R,5R)-2-[(1S,2S,3R,4S,6R)-4,6-Diamino-3-[(2R,3R,6S)-3-Amino-6-[1-(Methylamino)Ethyl]oxan-2-Yl]oxy-2-Hydroxycyclohexyl]oxy-5-Methyl-4-(Methylamino)Oxane-3,5-Diol",
      "2-[4,6-Diamino-3-[3-Amino-6-[1-(Methylamino)Ethyl]oxan-2-Yl]oxy-2-Hydroxycyclohexyl]oxy-5-Methyl-4-(Methylamino)Oxane-3,5-Diol",
      "4,6-Diamino-3-{[3-Deoxy-4-C-Methyl-3-(Methylamino)Pentopyranosyl]oxy}-2-Hydroxycyclohexyl 2-Amino-2,3,4,6,7-Pentadeoxy-6-(Methylamino)Heptopyranoside",
    ],
  },
  {
    primary_id: "D0L9VP",
    names: ["GNF-PF-5434"],
  },
  {
    primary_id: "D0L9WF",
    names: ["ARH-1", "Arteether (Injectable, Malaria), Lincoln"],
  },
  {
    primary_id: "D0L9WG",
    names: [
      "Decoyinine",
      "AC1L1EVQ",
      "UPCMLD-DP098",
      "CBiol_001975",
      "KBioSS_000329",
      "KBioGR_000329",
      "CHEMBL2360461",
      "UPCMLD-DP098:002",
      "UPCMLD-DP098:001",
      "KBio3_000657",
      "KBio2_002897",
      "KBio3_000658",
      "KBio2_005465",
      "KBio2_000329",
      "CTK8A2092",
      "Bio2_000805",
      "Bio2_000325",
      "Bio1_000261",
      "Bio1_001239",
      "HMS1362A11",
      "Bio1_000750",
      "SMP2_000077",
      "IDI1_002080",
      "NCGC00161649-01",
      "NCGC00161649-02",
    ],
  },
  {
    primary_id: "D0L9WL",
    names: ["IDO Inhibitor"],
  },
  {
    primary_id: "D0L9WN",
    names: ["4-(4-Methyl-Indan-1-Yl)-1H-Imidazole"],
  },
  {
    primary_id: "D0L9XF",
    names: ["BBI503"],
  },
  {
    primary_id: "D0L9XL",
    names: ["NPS-2"],
  },
  {
    primary_id: "D0L9XR",
    names: [
      "2-Amino-3-Methyl-1-Pyrrolidin-1-Yl-Butan-1-One",
      "CHEMBL16709",
      "54124-67-3",
      "AC1NBNWM",
      "2-Amino-3-Methyl-1-(1-Pyrrolidinyl)-1-Butanone",
      "SCHEMBL1951493",
      "CTK8J1465",
      "MolPort-004-338-106",
      "ALBB-022686",
      "BDBM50118952",
      "AKOS016347010",
      "AKOS000180650",
      "1-Pyrrolizino-2-Amino-3-Methyl-1-Butanone",
      "DB-071796",
      "FT-0723537",
      "2-Amino-3-Methyl-1-Pyrrolidin-1-Ylbutan-1-One",
      "F2147-1598",
      "[(1S)-2-Methyl-1-(Pyrrolidin-1-Ylcarbonyl)Propyl]amine",
      "3-Methyl-1-Oxo-1-Pyrrolidin-1-Ylbutan-2-Amine Hydrochloride",
      "[2-Methyl-1-(Pyrrolidin-1-Ylcarbonyl)Propyl]amine Hydrochloride",
    ],
  },
  {
    primary_id: "D0L9XS",
    names: ["Plasmin"],
  },
  {
    primary_id: "D0L9YA",
    names: ["N-(3-Benzooxazol-7-Yl-Propyl)-Acetamide", "CHEMBL124488", "SCHEMBL6787351"],
  },
  {
    primary_id: "D0L9YC",
    names: ["Nocathiacin I Analogs"],
  },
  {
    primary_id: "D0L9YJ",
    names: ["3-[3-(Benzylmethylamino)Propoxy]xanthen-9-One", "CHEMBL225610"],
  },
  {
    primary_id: "D0L9YP",
    names: ["4-(Spiro[chromene-2,4'-Piperidine]-4-Yl)Benzamide", "CHEMBL550063", "SCHEMBL10266987", "BDBM50297313"],
  },
  {
    primary_id: "D0L9YS",
    names: [
      "Octopamine",
      "Analet",
      "Norden",
      "Norfen",
      "Norphen",
      "Norsympathol",
      "Norsympatol",
      "Norsynephrine",
      "Octapamine",
      "Octopamin",
      "Octopamina",
      "Octopaminum",
      "Octopamina [Spanish]",
      "Octopamine [INN]",
      "Octopamine Hydrochloride",
      "Octopaminum [Latin]",
      "Paraoxyphenyl Aminoethanol",
      "ND 50",
      "TBB066518",
      "WIN 5512",
      "WV 569",
      "Beta-Hydroxytyramine",
      "Octopamina [INN-Spanish]",
      "Octopaminum [INN-Latin]",
      "P-Hydroxyphenylethanolamine",
      "P-Norsynephrin",
      "P-Octopamine",
      "Para-Octopamine",
      "Alpha-Aminoethyl-4-Hydroxybenzylalkohol",
      "Alpha-(Aminoethyl)-4-Hydroxybenzenemethanol",
      "Alpha-(Aminoethyl)-P-Hydroxybenzyl Alcohol",
      "Alpha-(Aminomethyl)-P-Hydroxybenzyl Alcohol",
      "Alpha-(Aminomethyl)-4-Hydroxybenzenemethanol",
      "1-(P-Hydroxyphenyl)-2-Aminoethanol",
      "4-(2-Amino-1-Hydroxyethyl)Phenol",
      "4-Octopamine",
    ],
  },
  {
    primary_id: "D0L9YX",
    names: [
      "Dihydroxyaluminium",
      "(2-Aminoacetoxy)Dihydroxyaluminum",
      "Dihydroxyaluminium Glycinate",
      "Dihydroxyaluminum Aminoacetate",
      "41354-48-7",
      "Robalate",
      "Prodexin",
      "Hyperacid",
      "Alilac",
      "(Glycinato)Dihydroxyaluminum",
      "Alminate",
      "(T-4)-(Glycinato-N,O)Dihydroxyaluminum",
      "Dihydroxyaluminum Aminoacetate [USAN:USP]",
      "C2H6AlNO4",
      "Dihydroxoaluminium Glycinat",
      "Aluminum Glycinate, AldrichCPR",
      "DTXSID3044546",
      "(Glycinato-N,O)Dihydroxyaluminum",
      "51484-68-5 (Proacid)",
      "MolPort-023-220-319",
      "13682-92-3 (Parent)",
      "(Glycinato)Dihydroxyaluminum Hydrate",
      "EINECS 237-193-8",
      "Aluminum, (Glycinato-N",
    ],
  },
  {
    primary_id: "D0L9ZF",
    names: ["OsteoStem"],
  },
  {
    primary_id: "D0L9ZK",
    names: ["R-1068"],
  },
  {
    primary_id: "D0L9ZQ",
    names: ["Lysergic Acid", "D-Lysergic Acid", "Isolysergic Acid", "(+)-Lysergic Acid"],
  },
  {
    primary_id: "D0L9ZR",
    names: [
      "Praziquantel",
      "Azinox",
      "Biliricide",
      "Biltricide",
      "Cesol",
      "Cisticid",
      "Cutter",
      "Cysticide",
      "Droncit",
      "Drontsit",
      "Prasiquantel",
      "Praziquantelum",
      "Pyquiton",
      "Traziquantel",
      "Bayer Brand Of Praziquantel",
      "Cutter Tape Tabs",
      "Merck Brand Of Praziquantel",
      "Embay 8440",
      "P 4668",
      "Bay-8440",
      "Biltricide (TN)",
      "EMBAY-8440",
      "NPFAPI-02",
      "Praziquantelum [INN-Latin]",
      "Biltricide, Droncit, Praziquantel",
      "Praziquantel (JAN/USP/INN)",
      "Praziquantel [USAN:INN:BAN:JAN]",
      "Praziquantel, (R)-Isomer",
      "Praziquantel, (S)-Isomer",
      "Praziquantel, (+-)-Isomer",
      "(+-)-2-(Cyclohexylcarbonyl)-1,2,3,6,7,11b-Hexahydro-4H-Pyrazino(2,1a)Isoquinolin-4-One",
      "(11bS)-2-(Cyclohexylcarbonyl)-1,2,3,6,7,11b-Hexahydro-4H-Pyrazino[2,1-A]isoquinolin-4-One",
      "2-(Cyclohexylcarbonyl)-1,2,3,6,7,11b-Hexahydro-4H-Pyrazino(2,1-A)Isoquinolin-4-One",
      "2-(Cyclohexylcarbonyl)-1,2,3,6,7,11b-Hexahydro-4H-Pyrazino[2,1-A]-Isoquinolin-4-One",
      "2-(Cyclohexylcarbonyl)-1,2,3,6,7-11b-Hexahydro-4H-Pyrazino(2,1a)Isoquinolin-4-One",
      "2-(Cyclohexylcarbonyl)-1,2,3,6,7-11b-Hexahydro-4H-Pyrazinoe(2,1a)Isoquinolin-4-One",
      "2-(Cyclohexanecarbonyl)-3,6,7,11b-Tetrahydro-1H-Pyrazino[2,1-A]isoquinolin-4-One",
      "2-(Cyclohexylcarbonyl)-1,2,3,6,7,11b-Hexahydro-4H-Pyrazino[2,1-A]isoquinolin-4-One",
      "2-Cyclohexanecarbonyl-1,2,3,6,7,11b-Hexahydro-Pyrazino[2,1-A]isoquinolin-4-One",
      "2-Cyclohexylcarbonyl-1,2,3,6,7,11b-Hexahydro-4H-Pyrazino(2,1-A) Isoquinolin-4-One",
      "8440, EMBAY",
    ],
  },
  {
    primary_id: "D0LA0P",
    names: ["BRD4770", "BRD 4770", "BRD-4770"],
  },
  {
    primary_id: "D0LA0Q",
    names: ["PMID25666693-Compound-162"],
  },
  {
    primary_id: "D0LA0X",
    names: ["Alpha-1 Proteinase Inhibitor, Hemosol", "A1PI, Hemosol", "Alpha-1 Antitrypsin, Hemosol"],
  },
  {
    primary_id: "D0LA1R",
    names: ["AEB07"],
  },
  {
    primary_id: "D0LA2R",
    names: ["Dihydroxyfumaric Acid Derivative 1"],
  },
  {
    primary_id: "D0LA4I",
    names: ["4-(Indane-1-Sulfonyl)-Phenylamine", "CHEMBL191972"],
  },
  {
    primary_id: "D0LA5G",
    names: ["Evernimicin"],
  },
  {
    primary_id: "D0LA6O",
    names: ["PMID25416646-Compound-Figure5-E"],
  },
  {
    primary_id: "D0LA6S",
    names: ["R116031"],
  },
  {
    primary_id: "D0LA6Z",
    names: ["Beraprost 314d"],
  },
  {
    primary_id: "D0LB0N",
    names: ["5-(Biphenyl-4-Yl)-3-Oxopentanoic Acid", "CHEMBL572979"],
  },
  {
    primary_id: "D0LB0X",
    names: ["KH064"],
  },
  {
    primary_id: "D0LB2L",
    names: ["MS201408-0005A"],
  },
  {
    primary_id: "D0LB3J",
    names: ["Pyrazolopyrimidine Acetamide Analog 2"],
  },
  {
    primary_id: "D0LB3Z",
    names: ["ABL 001"],
  },
  {
    primary_id: "D0LB4A",
    names: ["PATULIN"],
  },
  {
    primary_id: "D0LB4T",
    names: [
      "AD-5075",
      "2,4-Thiazolidinedione, 5-((4-(2-Hydroxy-2-(5-Methyl-2-Phenyl-4-Oxazolyl)Ethoxy)Phenyl)Methyl)-",
      "AD 5075",
      "103788-05-2",
      "5-[[4-[2-Hydroxy-2-(5-Methyl-2-Phenyl-1,3-Oxazol-4-Yl)Ethoxy]phenyl]methyl]-1,3-Thiazolidine-2,4-Dione",
      "2,4-Thiazolidinedione,5-[[4-[2-Hydroxy-2-(5-Methyl-2-Phenyl-4-Oxazolyl)Ethoxy]phenyl]methyl]-",
      "YVQKIDLSVHRBGZ-UHFFFAOYSA-N",
      "[3H]AD5075",
      "[3H]-AD5075",
      "ACMC-20m6lh",
      "AC1L2TSO",
      "CHEMBL88496",
      "SCHEMBL131888",
      "GTPL2702",
      "GTPL2701",
      "CTK4A2384",
      "AD5075",
      "5-(4-(2-(5-Methyl-2-Phenyl-4-Oxazolyl)-2-Hydroxyethoxy)Benzyl)-2,4-Thiazolidinedione",
      "[3H]AD-5075",
    ],
  },
  {
    primary_id: "D0LB6D",
    names: ["RG7203"],
  },
  {
    primary_id: "D0LB6L",
    names: [
      "2,3-Dimethoxy-2'-Hydroxychalcone",
      "2'-HYDROXY-2,3-DIMETHOXYCHALCONE",
      "AC1NQ7LG",
      "CTK4I5873",
      "CTK1B1409",
      "3-(2,3-Dimethoxyphenyl)-1-(2-Hydroxyphenyl)Prop-2-En-1-One",
      "MCULE-6103350400",
      "FT-0772376",
      "2-Propen-1-One, 3-(2,3-Dimethoxyphenyl)-1-(2-Hydroxyphenyl)-, (2E)-",
    ],
  },
  {
    primary_id: "D0LB6Q",
    names: ["PMID27977313-Compound-19"],
  },
  {
    primary_id: "D0LB7I",
    names: ["PMID28870136-Compound-64"],
  },
  {
    primary_id: "D0LB7Z",
    names: ["Pyrrolidinyl Urea Derivative 3"],
  },
  {
    primary_id: "D0LB8B",
    names: ["Sialyl Lea-KLH Conjugate Vaccine"],
  },
  {
    primary_id: "D0LB8Y",
    names: ["Heteroaryl-Substituted Urea Derivative 1"],
  },
  {
    primary_id: "D0LB8Z",
    names: ["TPIV200"],
  },
  {
    primary_id: "D0LB9U",
    names: ["3-(2-Nitro-Ethyl)-[1,4]oxazepan-(5Z)-Ylideneamine", "CHEMBL314727", "SCHEMBL8550044", "BDBM50104648"],
  },
  {
    primary_id: "D0LC0E",
    names: ["PharmaPEG-Interferon-Alpha"],
  },
  {
    primary_id: "D0LC0F",
    names: ["2,3-Dihydro-1H-Indene-5-Sulfonamide"],
  },
  {
    primary_id: "D0LC1J",
    names: ["Aglatimagene Besadenovec"],
  },
  {
    primary_id: "D0LC2B",
    names: ["NSP-805"],
  },
  {
    primary_id: "D0LC2G",
    names: ["Cyclohexyl Carbamate Derivative 7"],
  },
  {
    primary_id: "D0LC2O",
    names: ["Ac-His-Trp-Ala-Val-Ala-His-Leu-Met-NH2", "CHEMBL438290"],
  },
  {
    primary_id: "D0LC3C",
    names: ["ARD-3150"],
  },
  {
    primary_id: "D0LC4L",
    names: ["KI-0806"],
  },
  {
    primary_id: "D0LC5C",
    names: ["6-(4-Chlorophenyl)-2-Morpholin-4-Ylpyridin-4-Ol", "CHEMBL225150"],
  },
  {
    primary_id: "D0LC5L",
    names: ["PF-06747143"],
  },
  {
    primary_id: "D0LC5O",
    names: ["(S)-3,4-DCPG", "(S)-3,4-Dicarboxylphenylglycine"],
  },
  {
    primary_id: "D0LC5Y",
    names: ["Piperazine Carbamic Compound 2"],
  },
  {
    primary_id: "D0LC6K",
    names: ["Fluticasone"],
  },
  {
    primary_id: "D0LC7K",
    names: [
      "ASIATIC ACID",
      "Asiatic Acid",
      "464-92-6",
      "Dammarolic Acid",
      "UNII-9PA5A687X5",
      "NSC 166063",
      "CHEBI:2873",
      "CHEMBL404313",
      "JXSVIVRDWWRQRT-UYDOISQJSA-N",
      "9PA5A687X5",
      "Asiantic Acid",
      "(1S,2R,4aS,6aR,6aS,6bR,8aR,9R,10R,11R,12aR,14bS)-10,11-Dihydroxy-9-(Hydroxymethyl)-1,2,6a,6b,9,12a-Hexamethyl-2,3,4,5,6,6a,7,8,8a,10,11,12,13,14b-Tetradecahydro-1H-Picene-4a-Carboxylic Acid",
      "Asiatic-Acid",
      "MFCD00238541",
      "HSDB 7662",
      "NSC-166063",
      "2,3,23-Trihydroxyurs-12-En-28-Oic Acid",
      "A)-2,3,23-Trihydroxyurs-12-En-28-Oic Acid",
      "Asiatic Acid, 97%",
      "AC1Q5QHS",
      "AC1L3O2W",
    ],
  },
  {
    primary_id: "D0LC7Z",
    names: ["6-(3-(Pyridin-4-Yl)Phenyl)Naphthalen-2-Ol", "CHEMBL254326"],
  },
  {
    primary_id: "D0LC8F",
    names: ["EDP-15"],
  },
  {
    primary_id: "D0LC8Z",
    names: ["[125I]SAP"],
  },
  {
    primary_id: "D0LC9U",
    names: ["TAK-573"],
  },
  {
    primary_id: "D0LD0X",
    names: [
      "1-Propyl-3,7-Dihydro-Purine-2,6-Dione",
      "1-Propylxanthine",
      "104285-82-7",
      "CHEMBL39986",
      "1H-Purine-2,6-Dione,3,9-Dihydro-1-Propyl-",
      "ACMC-1BNN6",
      "AC1L2TY0",
      "SCHEMBL4423680",
      "SCHEMBL8930198",
      "CTK4A2909",
      "DTXSID10146367",
      "IWBONKMODGBEOX-UHFFFAOYSA-N",
      "BDBM50001490",
      "1-Propyl-3,7-Dihydropurine-2,6-Dione",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1-Propyl-",
    ],
  },
  {
    primary_id: "D0LD1X",
    names: ["D-211B", "CHEMBL260682"],
  },
  {
    primary_id: "D0LD8U",
    names: ["TG-3003", "Anti-Colony Stimulating Factor 1 Receptor (CSF1-R/CD115) Humanized MAb (Cancer), Transgene"],
  },
  {
    primary_id: "D0LD9P",
    names: ["CST-104"],
  },
  {
    primary_id: "D0LD9S",
    names: ["SPERMINE"],
  },
  {
    primary_id: "D0LE0E",
    names: [
      "8-Fluoro-3-Hydroxy-1H-Benzo[b]azepine-2,5-Dione",
      "1H-1-Benzazepine-2,5-Dione, 8-Fluoro-3-Hydroxy-",
      "SCHEMBL9007832",
      "CHEMBL142890",
      "144066-26-2",
    ],
  },
  {
    primary_id: "D0LE1F",
    names: ["[3H]L655708"],
  },
  {
    primary_id: "D0LE2E",
    names: ["H22xKi-4"],
  },
  {
    primary_id: "D0LE3O",
    names: ["CEP-37248", "ART-123, Arana", "IL-12/IL-23 Monoclonal Antibody (Inflammatory Disease), Arana"],
  },
  {
    primary_id: "D0LE4M",
    names: ["Mab-338", "Anti-Metapneumovirus Monoclonal Antibody, MedImmune"],
  },
  {
    primary_id: "D0LE5N",
    names: ["CTL119"],
  },
  {
    primary_id: "D0LE6B",
    names: ["PMID27599163-Compound-75"],
  },
  {
    primary_id: "D0LE6F",
    names: ["CC-11050"],
  },
  {
    primary_id: "D0LE6K",
    names: ["BL-Angiostatin", "BLAS (Cancer), TMS/Tokyo University Of Agriculture And Technology"],
  },
  {
    primary_id: "D0LE6M",
    names: ["VLTS-582"],
  },
  {
    primary_id: "D0LE6N",
    names: ["Hexahydro-Cyclopenta[c]pyrrol-(1Z)-Ylideneamine", "CHEMBL365849", "BDBM50150935"],
  },
  {
    primary_id: "D0LE7J",
    names: ["PMID23374866C31", "4idv", "GTPL8174", "BDBM50429614", "13V"],
  },
  {
    primary_id: "D0LE7P",
    names: ["SDZ-GLC-756"],
  },
  {
    primary_id: "D0LE8H",
    names: [
      "Chloromethylpyrrolindolines",
      "Chloromethylpyrrolindolines (Cancer)",
      "ICT-2700",
      "ICT-2740",
      "Chloromethylpyrrolindolines (Cancer), University Of Bradford",
    ],
  },
  {
    primary_id: "D0LF0B",
    names: ["Tricyclic Compound 8"],
  },
  {
    primary_id: "D0LF0O",
    names: ["BMS-695735"],
  },
  {
    primary_id: "D0LF1E",
    names: ["AQST-117"],
  },
  {
    primary_id: "D0LF1U",
    names: ["Flavopiridol"],
  },
  {
    primary_id: "D0LF3K",
    names: ["A/Anhui/05"],
  },
  {
    primary_id: "D0LF3W",
    names: ["ISIS 138678"],
  },
  {
    primary_id: "D0LF5B",
    names: [
      "KN-38-7271",
      "Cannabinoid 1/2 Receptor Agonists (Cerebrovascular Ischemia/Pain), Bayer",
      "Cannabinoid 1/2 Receptor Agonists (Traumatic Brain Injury/Stroke), KeyNeurotek",
    ],
  },
  {
    primary_id: "D0LF5Q",
    names: ["ABT-267"],
  },
  {
    primary_id: "D0LF6M",
    names: ["Chimeric Antigen Receptor Modified T Cells Targeting CD19"],
  },
  {
    primary_id: "D0LF7W",
    names: ["C[-Arg-Gly-Asp-Acpca19-]", "CHEMBL539338"],
  },
  {
    primary_id: "D0LF8Z",
    names: ["Pentasa Sachet"],
  },
  {
    primary_id: "D0LG0E",
    names: ["ISIS 6720"],
  },
  {
    primary_id: "D0LG1H",
    names: ["NPL-2003"],
  },
  {
    primary_id: "D0LG1I",
    names: ["PMID25482888-Compound-12"],
  },
  {
    primary_id: "D0LG1K",
    names: ["TAR-1"],
  },
  {
    primary_id: "D0LG3Q",
    names: ["PLX-PAD Cell Therapy"],
  },
  {
    primary_id: "D0LG4V",
    names: ["SR-47063"],
  },
  {
    primary_id: "D0LG7X",
    names: ["RP6530"],
  },
  {
    primary_id: "D0LG8A",
    names: ["1-(3-(Pentafluorosulfanyl)Phenyl)Propan-2-Amine", "CHEMBL239434"],
  },
  {
    primary_id: "D0LG8E",
    names: [
      "Ephedrine",
      "Biophedrin",
      "Eciphin",
      "Efedrin",
      "Ephedral",
      "Ephedremal",
      "Ephedrin",
      "Ephedrital",
      "Ephedrol",
      "Ephedrosan",
      "Ephedrotal",
      "Ephedsol",
      "Ephendronal",
      "Ephoxamin",
      "Fedrin",
      "Kratedyn",
      "Lexofedrin",
      "Manadrin",
      "Mandrin",
      "Nasol",
      "Racephedrine",
      "Sanedrine",
      "Vencipon",
      "Zephrol",
      "CPDD 0049",
      "D-Ephedrine",
      "Ephedrine (TN)",
      "Ephedrine (USP)",
      "Ephedrine [USAN:BAN]",
      "Ephedrine L-Form",
      "I-Sedrin",
      "L-Ephedrine",
      "L(-)-Ephedrine",
      "L-(+)-Ephedrine",
      "L-(-)-Ephedrine",
      "(+)-Ephedrin",
      "(1R,2R)-Ephedrine",
      "(1S,2R)-Ephedrine",
      "(L)-EPHEDRINE",
      "1-EPHEDRINE",
      "1-Sedrin",
    ],
  },
  {
    primary_id: "D0LG8T",
    names: ["DS-3201"],
  },
  {
    primary_id: "D0LG9U",
    names: ["P-144, AMD"],
  },
  {
    primary_id: "D0LH0O",
    names: ["Carbamide Derivative 11"],
  },
  {
    primary_id: "D0LH1A",
    names: ["CL 385319", "CHEMBL2088468", "Cl-385319"],
  },
  {
    primary_id: "D0LH3S",
    names: [
      "3,4,4',5-Tetramethoxy-(Z)-Stilbene",
      "CHEMBL47426",
      "134029-49-5",
      "1-Mtpe",
      "NSC638499",
      "Deoxycombretastatin A4",
      "AC1NTUK5",
      "ZINC5900",
      "SCHEMBL3834841",
      "(Z)-1,2,3-Trimethoxy-5-(2-(4-Methoxyphenyl)Ethenyl)Benzene",
      "Cis-3,4,5,4'-Tetramethoxystilbene",
      "BDBM50006677",
      "10Z-3,4,4',5-Tetramethoxystilbene",
      "AKOS027382909",
      "NSC-638499",
      "10Z-3,4,4'',5-Tetramethoxystilbene",
      "3,4,4'',5-Tetramethoxy-(Z)-Stilbene",
      "(Z) 3,4',5-TETRAMETHOXYSTILBENE",
      "5-(4-Methoxystyryl)-1,2,3-Trimethoxybenzene",
      "(Z)-1,2,3-Trimethoxy-5-(4-Methoxystyryl)Benzene",
    ],
  },
  {
    primary_id: "D0LH4J",
    names: ["Aliskiren/Valsartan"],
  },
  {
    primary_id: "D0LH4L",
    names: [
      "BOLDINE",
      "Boldine",
      "476-70-0",
      "Uniboldina",
      "Boldin",
      "(S)-Boldine",
      "Boldine Chloroform",
      "(+)-Boldine",
      "NSC 65689",
      "UNII-8I91GE2769",
      "(S)-(+)-Boldine",
      "EINECS 207-509-9",
      "BRN 0094036",
      "1,10-Dimethoxy-2,9-Dihydroxyaporphine",
      "CHEMBL388342",
      "CHEBI:3148",
      "1,10-Dimethoxy-6a-Alpha-Aporphine-2,9-Diol",
      "LZJRNLRASBVRRX-ZDUSSCGKSA-N",
      "8I91GE2769",
      "4H-Dibenzo(De,g)Quinoline-2,9-Diol, 5,6,6a,7-Tetrahydro-1,10-Dimethoxy-6-Methyl-, (S)-",
      "4H-Dibenzo(De,g)Quinoline-2,9-Diol, 5,6,6a,7-Tetrahydro-1,10-Dimethoxy-6-Methyl-, (6aS)-",
    ],
  },
  {
    primary_id: "D0LH4P",
    names: ["CX-1846", "AMPAKINE Compounds (Parkinson's Disease), Cortex"],
  },
  {
    primary_id: "D0LH5P",
    names: [
      "GNF-PF-173",
      "10-Deazaaminopterin",
      "10-Deazaminopterin",
      "10-Deaza-Aminopterin",
      "UNII-PXJ16PPE04",
      "NSC 311469",
      "PXJ16PPE04",
      "CHEMBL293263",
      "L-Glutamic Acid, N-(4-(2-(2,4-Diamino-6-Pteridinyl)Ethyl)Benzoyl)-",
      "L-Glutamic Acid, N-[4-[2-(2,4-Diamino-6-Pteridinyl)Ethyl]benzoyl]-",
      "52454-37-2",
      "AC1L2OWJ",
      "AC1Q5QQQ",
      "10-DAM",
      "SCHEMBL7081697",
      "BDBM50016461",
      "LS-187417",
      "LS-186724",
      "(2S)-2-[[4-[2-(2,4-Diaminopteridin-6-Yl)Ethyl]benzoyl]amino]pentanedioic Acid",
      "(S)-2-{4-[2-(2,4-Diamino-Pteridin-6-Yl)-Ethyl]-Benzoylamino}-Pentanedioic Acid",
    ],
  },
  {
    primary_id: "D0LH6R",
    names: ["Imidazo Bicyclic Iminium Derivative 6"],
  },
  {
    primary_id: "D0LH7O",
    names: ["ISIS 13812"],
  },
  {
    primary_id: "D0LH9A",
    names: ["ISOGOIAZENSOLIDE"],
  },
  {
    primary_id: "D0LH9E",
    names: ["Imidazolidine-2,4-Dione Derivative 2"],
  },
  {
    primary_id: "D0LI0M",
    names: ["8-N-Pentylnaringenin"],
  },
  {
    primary_id: "D0LI1C",
    names: [
      "NITD609",
      "Cipargamin",
      "1193314-23-6",
      "NITD-609",
      "NITD 609",
      "UNII-Z7Q4FWA04P",
      "(1'R,3'S)-5,7'-Dichloro-6'-Fluoro-3'-Methyl-2',3',4',9'-Tetrahydrospiro[indoline-3,1'-Pyrido[3,4-B]indol]-2-One",
      "Z7Q4FWA04P",
      "CHEMBL1082723",
      "Spiro[3H-Indole-3,1'-[1H]pyrido[3,4-B]indol]-2(1H)-One, 5,7'-Dichloro-6'-Fluoro-2',3',4',9'-Tetrahydro-3'-Methyl-, (1'R,3'S)-",
      "Cipargamin [INN]",
      "1252008-89-1",
      "GTPL9721",
      "SCHEMBL1306342",
      "DTXSID70152424",
      "MolPort-039-337-269",
      "ZINC49037032",
      "BDBM50318666",
      "AKOS027253851",
      "SB16518",
      "DB12306",
      "CS-7486",
      "NCGC00263785-01",
    ],
  },
  {
    primary_id: "D0LI2E",
    names: ["Tetra-Hydro-Imidazo[1,5-D][1,4]oxazepin-3-Yl Derivative 1"],
  },
  {
    primary_id: "D0LI2I",
    names: ["Imidazopyridine And Triazolopyridine Compound 4"],
  },
  {
    primary_id: "D0LI3A",
    names: ["GS-326"],
  },
  {
    primary_id: "D0LI3C",
    names: [
      "Cryptophycin",
      "Cryptophycin 1",
      "UNII-XO974V2M5T",
      "124689-65-2",
      "XO974V2M5T",
      "10-((3-Chloro-4-Methoxyphenyl)Methyl)-6-Methyl-3-(2-Methylpropyl)-16-(1-(3-Phenyloxiranyl)Ethyl)-1,4-Dioxa-8,11-Diazacyclohexadec-13-Ene-2,5,9,12-Tetrone",
      "CRYPTOPHYCIN A",
      "1,4-Dioxa-8,11-Diazacyclohexadec-13-Ene-2,5,9,12-Tetrone, 10-((3-Chloro-4-Methoxyphenyl)Methyl)-6-Methyl-3-(2-Methylpropyl)-16-(1-(3-Phenyloxiranyl)Ethyl)-",
      "C35H43ClN2O8",
      "AC1O5PMQ",
      "CHEMBL441018",
      "SCHEMBL1346966",
      "PSNOPSMXOBPNNV-VVCTWANISA-N",
      "LS-172597",
      "C16877",
    ],
  },
  {
    primary_id: "D0LI3H",
    names: [
      "BMS-633",
      "BMS-339",
      "HCV NS3 Protease Inhibitors, BMS",
      "HCV NS3 Protease Inhibitors, DuPont",
      "Hepatitis C Virus NS3 Protease Inhibitors, BMS",
      "Hepatitis C Virus NS3 Protease Inhibitors, DuPont",
    ],
  },
  {
    primary_id: "D0LI3J",
    names: ["2-BFi", "2-Benzofuran-2-Yl-4,5-Dihydro-1H-Imidazole"],
  },
  {
    primary_id: "D0LI5N",
    names: ["IONIS-PTP1BRX"],
  },
  {
    primary_id: "D0LI6Z",
    names: ["1-Aryl-4-Methyl-[1,2,4]triazolo[4,3-A]quinoxaline Derivative 3"],
  },
  {
    primary_id: "D0LI7L",
    names: ["CC-0101", "Apoptosis Stimulator (Cancer), Celecure"],
  },
  {
    primary_id: "D0LI9D",
    names: ["Antihistamine", "Antihistamine (Ophthalmic Sustained Release, Punctal Plug)"],
  },
  {
    primary_id: "D0LJ0L",
    names: ["Lu-AA47070", "Adenosine A2a Antagonists (Parkinson's Disease), Lundbeck"],
  },
  {
    primary_id: "D0LJ0M",
    names: ["2-Cyclopropyl-4-Substituted-Phenoxy-Quinoline Derivative 1"],
  },
  {
    primary_id: "D0LJ1B",
    names: ["Reparixin"],
  },
  {
    primary_id: "D0LJ1Z",
    names: ["CZ-MD001", "CZ-MD001 Series (Diabetes)", "CZ-MD001 Series (Diabetes), Chemizon"],
  },
  {
    primary_id: "D0LJ2F",
    names: ["1,2-Dicyclohexylethane-1,2-Dione"],
  },
  {
    primary_id: "D0LJ2S",
    names: ["Anti-NY-ESO-1 CAR-T Cells"],
  },
  {
    primary_id: "D0LJ4E",
    names: ["NIR178"],
  },
  {
    primary_id: "D0LJ4J",
    names: ["DB104"],
  },
  {
    primary_id: "D0LJ4Q",
    names: ["8-Propoxy-Quinolin-2-Ylamine", "CHEMBL427153", "SCHEMBL5884205", "BDBM50152441"],
  },
  {
    primary_id: "D0LJ5P",
    names: [
      "4-Ethynyl Benzene Sulfonamide",
      "4-Ethynylbenzenesulfonamide",
      "1788-08-5",
      "4-Ethynylbenzene-1-Sulfonamide",
      "4-Ethynybenzenesulfonamide",
      "Benzenesulfonamide, 4-Ethynyl-",
      "CHEMBL272881",
      "Benzenesulfonamide, 4-Ethynyl- (9ci)",
      "P-Ethynylbenzenesulfonamide",
      "4-Ethvnylbenzenesulfonamide",
      "4-Ethynylbenzene Sulfonamide",
      "4-Ethynyl-Benzenesulfonamide",
      "Benzenesulfonamide,4-Ethynyl-",
      "SCHEMBL1066580",
      "CTK4D6902",
      "DTXSID80659176",
      "OQPUCENNUFNCQO-UHFFFAOYSA-N",
      "MolPort-004-759-802",
      "KS-00000L2U",
      "ANW-45086",
      "BDBM50236140",
      "ZINC29134554",
      "FC0779",
      "AKOS006308323",
    ],
  },
  {
    primary_id: "D0LJ5Q",
    names: ["GSK2340274A"],
  },
  {
    primary_id: "D0LJ6P",
    names: ["Etanidazole"],
  },
  {
    primary_id: "D0LJ7R",
    names: ["M5"],
  },
  {
    primary_id: "D0LJ7T",
    names: ["BT-1718"],
  },
  {
    primary_id: "D0LJ8C",
    names: ["LCZ696", "DTXSID50239500"],
  },
  {
    primary_id: "D0LJ9D",
    names: ["4-(4-(Thiophen-2-Yl)Benzyl)Pyridine", "CHEMBL1214736", "SCHEMBL18770350"],
  },
  {
    primary_id: "D0LK4J",
    names: ["Ac-Cys-Ile-Tyr-Lys-Tyr-Phe(4-NO2)"],
  },
  {
    primary_id: "D0LK4L",
    names: ["Nitrogen Mustard Derivative 1"],
  },
  {
    primary_id: "D0LK4R",
    names: ["PMID27376512-Compound-AsCEBP-1HPE"],
  },
  {
    primary_id: "D0LK4X",
    names: [
      "Carebastine",
      "Carebastina",
      "Carebastinum",
      "Carebastina [Spanish]",
      "Carebastine [INN]",
      "Carebastinum [Latin]",
      "E00013",
      "P-(4-(4-(Diphenylmethoxy)Piperidino)Butyryl)-Alpha-Methylhydratropic Acid",
      "2-(4-(4-(4-Benzhydryloxypiperidino)Butyryl)Phenyl)-2-Methylpropionsaeure",
      "2-[4-[4-(4-Benzhydryloxypiperidin-1-Yl)Butanoyl]phenyl]-2-Methylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0LK5P",
    names: ["[3H]LTC4"],
  },
  {
    primary_id: "D0LK6V",
    names: ["2,2-Dimethylbenzopyran Derivative 1"],
  },
  {
    primary_id: "D0LK8A",
    names: ["Heteroaryl-Cyclopropylamine Derivative 2"],
  },
  {
    primary_id: "D0LK8E",
    names: ["PMID28270021-Compound-WO2016054807Example112"],
  },
  {
    primary_id: "D0LK8O",
    names: [
      "LIFARIZINE HYDROCHLORIDE",
      "BANM",
      "Lifarizine Hydrochloride < Rec INNM",
      "RS-87476",
      "1-(Diphenylmethyl)-4-[5-Methyl-2-(4-Methylphenyl)-1H-Imidazol-4-Ylmethyl]piperazine Trihydrochloride",
    ],
  },
  {
    primary_id: "D0LK8U",
    names: [
      "CG-1521",
      "UNII-028K52V859",
      "028K52V859",
      "2,4,6-Heptatrienamide, N-Hydroxy-7-Phenyl-, (2E,4E,6E)-",
      "674767-29-4",
      "CHEMBL159249",
      "SCHEMBL10323570",
      "DBBYYRWVNDQECM-CDWOPPGASA-N",
      "N-Hydroxy-7-Phenylhepta-2,4,6-Trienamide",
    ],
  },
  {
    primary_id: "D0LK9N",
    names: ["Fradafiban", "BIBL-12", "BIBU-52", "BIBV-52", "BIBW-103", "BIBW-98"],
  },
  {
    primary_id: "D0LL0X",
    names: ["Pyrazole Derivative 82"],
  },
  {
    primary_id: "D0LL1F",
    names: ["[2-Phenylacetophenone]thiosemicarbazone", "CHEMBL1269810"],
  },
  {
    primary_id: "D0LL1H",
    names: ["ISIS 6603"],
  },
  {
    primary_id: "D0LL1N",
    names: ["ADCT-301", "Camidanlumab Tesirine"],
  },
  {
    primary_id: "D0LL3D",
    names: [
      "7-Bromo-1H-Indazole",
      "53857-58-2",
      "7-Bromoindazole",
      "7-Bromo-2H-Indazole",
      "845751-59-9",
      "1H-Indazole, 7-Bromo-",
      "1H-INDAZOLE,7-BROMO-",
      "CHEMBL439566",
      "AK-26850",
      "PubChem14406",
      "2H-Indazole, 7-Bromo-",
      "ACMC-1B1ZL",
      "BROMOINDAZOLE(7-)",
      "T56 BMNJ IE",
      "KSC268A6L",
      "7-Bromo-1H-Indazole, 97%",
      "SCHEMBL1855857",
      "CTK1G8065",
      "MolPort-027-946-828",
      "MolPort-006-717-407",
      "KMHHWCPTROQUFM-UHFFFAOYSA-N",
      "ZX-AT005164",
      "BCP00108",
      "STL555654",
      "2999AC",
      "FCH835100",
      "ANW-31868",
      "ZINC14007945",
      "MFCD07371564",
      "BBL101857",
      "SBB092096",
      "BDBM50209239",
      "AKOS016013628",
      "AKOS005072291",
      "RTC-062730",
      "RP04094",
    ],
  },
  {
    primary_id: "D0LL4C",
    names: ["TIP"],
  },
  {
    primary_id: "D0LL4Y",
    names: ["TAK-931"],
  },
  {
    primary_id: "D0LL5V",
    names: ["L-Valine"],
  },
  {
    primary_id: "D0LL6K",
    names: ["TRX-585"],
  },
  {
    primary_id: "D0LL7C",
    names: [
      "3C23K",
      "Anti-Mullerian Hormone Type II Receptor MAb (Humanized, Cancer) LFB",
      "Anti-MISRII MAb (Humanized, Cancer), LFB",
    ],
  },
  {
    primary_id: "D0LL7N",
    names: ["FR190997"],
  },
  {
    primary_id: "D0LL7V",
    names: ["PMID28454500-Compound-60"],
  },
  {
    primary_id: "D0LL8H",
    names: [
      "Isosorbide-2-(Benzylcarbamate)-5-Benzoate",
      "CHEMBL523830",
      "BDBM50253231",
      "(3R,3aR,6S,6aR)-6-(Benzylcarbamoyloxy)Hexahydrofuro[3,2-B]furan-3-Yl Benzoate",
    ],
  },
  {
    primary_id: "D0LL8I",
    names: ["WAY170523"],
  },
  {
    primary_id: "D0LL9E",
    names: [
      "1-(4-(Methylsulfonyl)Phenyl)-3-Phenylurea",
      "CHEMBL252433",
      "1-[4-(Methylsulfonyl)Phenyl]-3-Phenylurea",
      "SCHEMBL9913187",
      "MolPort-019-692-584",
      "STL012316",
      "ZINC29038894",
      "BDBM50233182",
      "AKOS005673001",
    ],
  },
  {
    primary_id: "D0LL9K",
    names: ["L-771688"],
  },
  {
    primary_id: "D0LL9W",
    names: ["VBI-1901"],
  },
  {
    primary_id: "D0LM0X",
    names: ["Ceramide Nanoliposome"],
  },
  {
    primary_id: "D0LM1Q",
    names: [
      "1,3-Dibenzyl-3,7-Dihydro-Purine-2,6-Dione",
      "1,3-Dibenzyl-3,7-Dihydro-1h-Purine-2,6-Dione",
      "CHEMBL24774",
      "31542-68-4",
      "NSC70563",
      "AC1Q6LGR",
      "1,3-Dibenzyl-1H-Purine-2,6(3H,7H)-Dione",
      "AC1L5IF2",
      "SCHEMBL3107001",
      "CTK4G7326",
      "NSC-70563",
      "BDBM50025573",
      "1,3-Dibenzyl-7H-Purine-2,6-Dione",
    ],
  },
  {
    primary_id: "D0LM2K",
    names: ["2-(2-(Biphenyl-4-Yl)Ethylsulfonyl)Acetic Acid", "CHEMBL566511"],
  },
  {
    primary_id: "D0LM3T",
    names: ["G3139 + Fludarabine"],
  },
  {
    primary_id: "D0LM4A",
    names: [
      "Varenicline",
      "CP 526555",
      "Champix (TN)",
      "Chantix (TN)",
      "Varenicline (INN)",
      "7,8,9,10-Tetrahydro-6,10-Methano-6H-Pyrazino(2,3-H)(3)Benzazepine",
      "7,8,9,10-Tetrahydro-6h-6,10-Methanoazepino[4,5-G]quinoxaline",
    ],
  },
  {
    primary_id: "D0LM4U",
    names: ["ATR-01"],
  },
  {
    primary_id: "D0LM5H",
    names: ["RPR260243"],
  },
  {
    primary_id: "D0LM5V",
    names: ["Benzene Sulfonamide Derivative 5"],
  },
  {
    primary_id: "D0LM6A",
    names: [
      "5'-S-Ethyl-5'-Thioadenosine",
      "S-Adenosylethane",
      "5'-Ethylthioadenosine",
      "CHEBI:74046",
      "SCHEMBL998091",
      "CHEMBL195660",
      "5'-Deoxy-5'-(Ethylthio)Adenosine",
      "DB07052",
      "(2R,3R,4S,5S)-2-(6-Aminopurin-9-Yl)-5-[(Ethylsulfanyl)Methyl]oxolane-3,4-Diol",
    ],
  },
  {
    primary_id: "D0LM6D",
    names: ["1,3,4-Oxadiazole Derivative 1"],
  },
  {
    primary_id: "D0LM7P",
    names: ["(D)Arg-Arg-Pro-Hyp-Gly-Phe-Ser-(D)Phe-Phe-Arg"],
  },
  {
    primary_id: "D0LM8L",
    names: [
      "N-[(4-Bromo-1-Benzothien-3-Yl)Methyl]sulfamide",
      "CHEMBL1084919",
      "SCHEMBL4276579",
      "FBZAPOBZDNKPQH-UHFFFAOYSA-N",
      "BDBM50310430",
    ],
  },
  {
    primary_id: "D0LN1T",
    names: ["PMID29671355-Compound-8"],
  },
  {
    primary_id: "D0LN2D",
    names: ["ETHIONINE"],
  },
  {
    primary_id: "D0LN2J",
    names: ["Fused Aryl Carbocycle Derivative 2"],
  },
  {
    primary_id: "D0LN9D",
    names: ["Cyano(Dimethyl)Methyl Isoxazoles And [1,3,4]-Thiadiazoles Derivative 1"],
  },
  {
    primary_id: "D0LN9P",
    names: ["TELUDIPINE HYDROCHLORIDE"],
  },
  {
    primary_id: "D0LO0I",
    names: ["PMID26560530-Compound-18"],
  },
  {
    primary_id: "D0LO1Z",
    names: ["PMID28460551-Compound-8"],
  },
  {
    primary_id: "D0LO2G",
    names: ["HSRx-888"],
  },
  {
    primary_id: "D0LO3F",
    names: ["NG-348"],
  },
  {
    primary_id: "D0LO4T",
    names: [
      "1-(4-Nonylbenzyl)Pyrrolidin-3-Ylphosphonic Acid",
      "CHEMBL184349",
      "570423-38-0",
      "SCHEMBL13937997",
      "CTK1F3135",
      "DTXSID10437782",
      "PRUUCTGBHIGGOH-UHFFFAOYSA-N",
      "BDBM50152329",
      "[1-(4-Nonyl-Benzyl)-Pyrrolidin-3-Yl]-Phosphonic Acid",
      "(R/S)-1-(4-Nonylbenzyl)-Pyrrolidin-3-Ylphosphonic Acid",
      "Phosphonic Acid, [1-[(4-Nonylphenyl)Methyl]-3-Pyrrolidinyl]-",
    ],
  },
  {
    primary_id: "D0LO5B",
    names: ["THZ1"],
  },
  {
    primary_id: "D0LO5N",
    names: ["SAR-103168"],
  },
  {
    primary_id: "D0LO7X",
    names: ["PMID27744724-Compound-23"],
  },
  {
    primary_id: "D0LO8S",
    names: ["3-Methoxy-5-(1,6-Naphthyridin-7-Yl)Benzonitrile", "CHEMBL395122"],
  },
  {
    primary_id: "D0LP0D",
    names: ["PMID29130358-Compound-Figure17(13)"],
  },
  {
    primary_id: "D0LP1K",
    names: ["PMID29671355-Compound-21"],
  },
  {
    primary_id: "D0LP1L",
    names: ["CAR-T Cells Targeting BCMA"],
  },
  {
    primary_id: "D0LP2H",
    names: ["OXAMATE"],
  },
  {
    primary_id: "D0LP2K",
    names: ["3-Demethoxy-3-D-Mannopyranosylaminothiocolchicine", "CHEMBL214305"],
  },
  {
    primary_id: "D0LP2M",
    names: [
      "BAS-01047655",
      "CHEMBL380706",
      "AC1LKNKS",
      "MolPort-001-663-455",
      "MolPort-019-782-693",
      "ZINC12376560",
      "STK392633",
      "BDBM50187570",
      "AKOS000548089",
      "MCULE-2522435117",
      "ST033733",
      "BAS 01047655",
      "AK-968/37005047",
      "A2180/0091610",
      "4-[2-(Benzyloxy)Benzylidene]-1-Phenyl-3,5-Pyrazolidinedione",
      "4-(2-Benzyloxy-Benzylidene)-1-Phenyl-Pyrazolidine-3,5-Dione",
      "4-(2-(Benzyloxy)Benzylidene)-1-Phenylpyrazolidine-3,5-Dione",
      "(4Z)-4-[2-(Benzyloxy)Benzylidene]-1-Phenylpyrazolidine-3,5-Dione",
    ],
  },
  {
    primary_id: "D0LP2P",
    names: ["Nu-3 Topical"],
  },
  {
    primary_id: "D0LP3P",
    names: ["PMID30185082-Compound-55"],
  },
  {
    primary_id: "D0LP4Q",
    names: ["RBPI-21"],
  },
  {
    primary_id: "D0LP4X",
    names: ["Aganirsen", "Aganirsen (Nanomolecule, Urogenital Cancer)"],
  },
  {
    primary_id: "D0LP5G",
    names: ["2-Phenyl-1H-Imidazo[4,5-C]quinolin-4-Ylamine", "CHEMBL276138", "ZINC13821064", "BDBM50011597"],
  },
  {
    primary_id: "D0LP5V",
    names: ["LUF-5956"],
  },
  {
    primary_id: "D0LP7U",
    names: ["AMV564"],
  },
  {
    primary_id: "D0LP7W",
    names: ["GSK-2210875"],
  },
  {
    primary_id: "D0LQ2Y",
    names: ["2CVV", "Chronic Fatigue Syndrome Therapy, Milkhaus"],
  },
  {
    primary_id: "D0LQ3L",
    names: ["GSK2982772"],
  },
  {
    primary_id: "D0LQ7V",
    names: ["HIVAX"],
  },
  {
    primary_id: "D0LR0R",
    names: [
      "Methdilazine",
      "Disyncram",
      "Methdilazinum",
      "Methodilazine",
      "Metodilazina",
      "Tacaryl",
      "Tacazyl",
      "Tacryl",
      "Methilazine Hydrochloride",
      "Methdilazine (INN)",
      "Methdilazinum [INN-Latin]",
      "Metodilazina [INN-Spanish]",
      "Tacaryl (TN)",
      "Methdilazine [USAN:INN:BAN]",
      "10-((1-Methyl-3-Pyrrolidinyl)Methyl)Phenothiazine",
      "10-((1-Methyl-3-Pyrrolidinyl)Methyl)-10H-Phenothiazine",
      "10-[(1-Methylpyrrolidin-3-Yl)Methyl]phenothiazine",
      "10-[(1-Methylpyrrolidin-3-Yl)Methyl]-10H-Phenothiazine",
    ],
  },
  {
    primary_id: "D0LR1M",
    names: [
      "LY-444711",
      "2(R)-(2-Amino-2-Methylpropanamido)-N-[1-[1(R)-(4-Methoxyphenyl)-1-Methyl-2-Oxo-2-(1-Pyrrolidinyl)Ethyl]-1H-Imidazol-4-Yl]-5-Phenylpentanamide Dihydrochloride",
    ],
  },
  {
    primary_id: "D0LR1W",
    names: ["Influenza Virus Vaccine Quadrivalent"],
  },
  {
    primary_id: "D0LR1Z",
    names: ["MK-2640"],
  },
  {
    primary_id: "D0LR2Q",
    names: ["LXR-015-2", "Elirex", "LXR-1035"],
  },
  {
    primary_id: "D0LR2Y",
    names: ["Diamidothiazole Derivative 1"],
  },
  {
    primary_id: "D0LR4B",
    names: ["Loxoprofen Gel"],
  },
  {
    primary_id: "D0LR4M",
    names: ["ETN-002"],
  },
  {
    primary_id: "D0LR4Q",
    names: ["GZ402671"],
  },
  {
    primary_id: "D0LR5O",
    names: ["PMID28394193-Compound-52"],
  },
  {
    primary_id: "D0LR6D",
    names: ["Cyclopropylamine Derivative 5"],
  },
  {
    primary_id: "D0LR6J",
    names: ["Bis-Aminopyrimidine Derivative 2"],
  },
  {
    primary_id: "D0LR7Z",
    names: ["Carbamate Derivative 1"],
  },
  {
    primary_id: "D0LR8X",
    names: ["SKF-83556"],
  },
  {
    primary_id: "D0LS1O",
    names: ["Tricyclic Compound 11"],
  },
  {
    primary_id: "D0LS2U",
    names: [
      "NSC-623548",
      "2581-36-4",
      "NSC 408488",
      "O-Cresotic Acid, 5,5'-Methylenedi-",
      "2,3-CRESOTIC ACID, 5,5'-METHYLENEDI-",
      "UNII-S3D8KC88KC",
      "5,5'-Methylenedi-2,3-Cresotic Acid",
      "NSC 623548",
      "BRN 3433298",
      "S3D8KC88KC",
      "CHEMBL113835",
      "5,5'-Methylenedi-O-Cresotic Acid",
      "NSC623548",
      "NSC408488",
      "5,5'-Methylenebis(2-Hydroxy-3-Methylbenzoic Acid)",
      "2, 5,5'-Methylenedi-",
      "AC1L29YK",
      "Oprea1_231968",
      "2-10-00-00398 (Beilstein Handbook Reference)",
      "SCHEMBL9755153",
      "CTK4F6504",
      "DTXSID90180466",
      "O-Cresotic Acid,5'-Methylenedi-",
      "MolPort-000-698-522",
      "ZINC4028795",
      "STL511095",
    ],
  },
  {
    primary_id: "D0LS3U",
    names: [
      "KD-332",
      "DTaP-Polio Vaccine (Diphtheria/Tetanus/Pertussis/Polio)",
      "DTaP-Polio Vaccine (Diphtheria/Tetanus/Pertussis/Polio), Chemo-Sero-Therapeutic Research Institute",
    ],
  },
  {
    primary_id: "D0LS4U",
    names: ["Etrabamine", "14839-JL"],
  },
  {
    primary_id: "D0LS4V",
    names: ["ISIS 25081"],
  },
  {
    primary_id: "D0LS4Y",
    names: ["PMID29130358-Compound-Figure16(9a)"],
  },
  {
    primary_id: "D0LS6W",
    names: ["KI-0803"],
  },
  {
    primary_id: "D0LS8H",
    names: ["MTX110"],
  },
  {
    primary_id: "D0LS9E",
    names: ["Durvalumab"],
  },
  {
    primary_id: "D0LS9F",
    names: ["ZEN-3694"],
  },
  {
    primary_id: "D0LT0T",
    names: ["3-(2-N-Pyrrolyl-Acetamino)-Rutaecarpine", "CHEMBL1097951"],
  },
  {
    primary_id: "D0LT0U",
    names: [
      "ORG-25935",
      "Cis-N-(6-Methoxy-1-Phenyl-1,2,3,4-Tetrahydronaphthalen-2-Ylmethyl)-N-Methylglycine Hydrochloride",
    ],
  },
  {
    primary_id: "D0LT1S",
    names: ["SB-223245"],
  },
  {
    primary_id: "D0LT2Z",
    names: ["Vaccimel"],
  },
  {
    primary_id: "D0LT4D",
    names: ["NI-1401", "Anti-Interleukin-17 MAb, NovImmune"],
  },
  {
    primary_id: "D0LT5D",
    names: ["(R)-8-Phenyl-N,N-Dipropylchroman-3-Amine", "CHEMBL186112", "BDBM50150676"],
  },
  {
    primary_id: "D0LT5Y",
    names: ["Piperazine Derivative 5"],
  },
  {
    primary_id: "D0LT6Z",
    names: ["PMID25553724-Compound-US2012810604610"],
  },
  {
    primary_id: "D0LT7Y",
    names: ["PMID7473541C11"],
  },
  {
    primary_id: "D0LU1O",
    names: ["FAZ053"],
  },
  {
    primary_id: "D0LU2F",
    names: ["C1-BODIPY-C12", "C1-Bodipy-C12"],
  },
  {
    primary_id: "D0LU2N",
    names: ["2-(2,4-Dichlorophenoxy)-5-Ethylphenol"],
  },
  {
    primary_id: "D0LU4I",
    names: ["ETBX-011 Cancer Vaccine"],
  },
  {
    primary_id: "D0LU4N",
    names: ["EGFRvIII CAR"],
  },
  {
    primary_id: "D0LU4S",
    names: [
      "7-Butoxy-2H-Chromen-2-One",
      "7-BUTOXYCOUMARIN",
      "7-Butoxychromen-2-One",
      "2H-1-Benzopyran-2-One, 7-Butoxy-",
      "CHEMBL571514",
      "71783-00-1",
      "CBMicro_045472",
      "AC1L4FS0",
      "SCHEMBL6686295",
      "DTXSID40221994",
      "MolPort-002-085-798",
      "ZINC2950199",
      "STK371317",
      "BDBM50303502",
      "AKOS001064182",
      "MCULE-1530014848",
      "BIM-0045671.P001",
      "ST50462808",
      "SR-01000227430",
    ],
  },
  {
    primary_id: "D0LU5Q",
    names: ["TPM-1/Morphine"],
  },
  {
    primary_id: "D0LU6N",
    names: ["SPD-473", "BTS-74398"],
  },
  {
    primary_id: "D0LU7K",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 6"],
  },
  {
    primary_id: "D0LU7U",
    names: ["N-Oxalyl-D-Tyrosine Derivative 4"],
  },
  {
    primary_id: "D0LU9X",
    names: ["Tetra-Hydro-Oxazolopyridine Derivative 3"],
  },
  {
    primary_id: "D0LV0R",
    names: ["Barixibat", "BARI-1453", "Bile Acid Resorption Inhibitor, Aventis", "HMR-1453", "1453, Aventis"],
  },
  {
    primary_id: "D0LV1G",
    names: ["Piperazinyl Norbenzomorphane Compound 4"],
  },
  {
    primary_id: "D0LV4S",
    names: ["Third Generation Antibacterials"],
  },
  {
    primary_id: "D0LV6B",
    names: ["Gly-Arg-Gly-Asp-Ser-Pro-Lys"],
  },
  {
    primary_id: "D0LV6K",
    names: ["PMID29671355-Compound-19"],
  },
  {
    primary_id: "D0LV7F",
    names: ["NCX-1047"],
  },
  {
    primary_id: "D0LV7S",
    names: [
      "Ammonium Carbonate",
      "AMMONIUM CARBONATE",
      "506-87-6",
      "Carbonic Acid, Diammonium Salt",
      "Diammonium Carbonate",
      "Carbonic Acid, Ammonium Salt",
      "Caswell No. 042",
      "Ammonia Sesquicarbonate",
      "Ammoniumcarbonat [German]",
      "UNII-PDP691CN28",
      "Carbonic Acid, Ammonium Salt (1:2)",
      "Ammoniumcarbonate",
      "CCRIS 7328",
      "10361-29-2",
      "Carbonate D'ammoniaque [French]",
      "HSDB 6305",
      "EINECS 208-058-0",
      "EPA Pesticide Chemical Code 073501",
      "Ammonium Carbonate, Extra Pure",
      "AI3-25347",
      "Ammonium Carbonate, ACS Reagent",
      "Ammonium Carbonate, For Analysis",
      "Ammoniumcarbonat",
      "PDP691CN28",
      "Ammonium Carbonicu",
    ],
  },
  {
    primary_id: "D0LV7U",
    names: [
      "1-(4-(4-Phenyl-1-Piperazinyl)Butyl)Indolin-2-One",
      "CHEMBL394080",
      "BDBM50216042",
      "1,3-Dihydro-1-[4-[4-(2-Methoxyphenyl)-1-Piperazinyl]butyl]-2H-Indole-2-One",
    ],
  },
  {
    primary_id: "D0LW0T",
    names: [
      "Savolitinib",
      "1313725-88-0",
      "AZD-6094",
      "AZD6094",
      "UNII-2A2DA6857R",
      "CHEMBL3334567",
      "2A2DA6857R",
      "Savolitinib [INN]",
      "Volitinib(Savolitinib)",
      "Savolitinib [USAN:INN]",
      "GTPL9918",
      "SCHEMBL12489208",
      "EX-A845",
      "BDBM50023342",
      "ZINC149738712",
      "AKOS030526403",
      "DB12048",
      "Compound 28 [PMID: 25148209]",
      "HY-15959",
      "AS-35250",
      "1H-1,2,3-Triazolo(4,5-B)Pyrazine, 1-((1S)-1-Imidazo(1,2-A)Pyridin-6-Ylethyl)-6-(1-Methyl-1H-Pyrazol-4-Yl)-",
      "KB-333895",
      "FT-0700162",
      "J-690125",
      "4-{1-[(1S)-1-{imidazo[1,2-A]pyri",
    ],
  },
  {
    primary_id: "D0LW1Y",
    names: ["GFT-505"],
  },
  {
    primary_id: "D0LW4A",
    names: ["Anti-DR5 Cells"],
  },
  {
    primary_id: "D0LW4O",
    names: ["PLEGRIDY"],
  },
  {
    primary_id: "D0LW9K",
    names: ["PMID25514969-Compound-11"],
  },
  {
    primary_id: "D0LW9R",
    names: ["Pyrrolidine Derivative 9"],
  },
  {
    primary_id: "D0LW9U",
    names: ["Polidocanol - BioForm Medical/Chemische Fabrik Kreussler & Co", "Aethoxysklerol", "Asclera"],
  },
  {
    primary_id: "D0LX0D",
    names: ["6-Benzyloxybenzo[b]thiophene-2-Carboxamidine", "CHEMBL598170", "SCHEMBL13709671"],
  },
  {
    primary_id: "D0LX2Y",
    names: ["Aminoazetidine Derivative 8"],
  },
  {
    primary_id: "D0LX4K",
    names: ["TDI-0066"],
  },
  {
    primary_id: "D0LX4U",
    names: ["1-[bis(4-Bromophenyl)Methyl]-3-Phenylthiourea", "CHEMBL224904"],
  },
  {
    primary_id: "D0LX5D",
    names: [
      "BIPHALIN",
      "Biphalin",
      "(Tyr-Ala-Gly-Phe-NH2)2",
      "D-Enk-O",
      "Dala(2)",
      "D-ENK",
      "Bis(Tyr-Ala-Gly-Phenh2)Hydrazide",
      "CHEMBL200199",
      "83916-01-2",
      "(Tyrosyl-Alanyl-Glycyl-Phenylalaninamide)Dimer",
      "Bis(Tyrosyl-Alanyl-Glycyl-Phenylalaninamide)Hydrazide",
      "L-Phenylalanine, N-(N-(N-L-Tyrosyl-D-Alanyl)Glycyl)-, 2-(N-(N-(N-L-Tyrosyl-D-Alanyl)Glycyl)-L-Phenylalanyl)Hydrazide",
      "Enkephalin Dimer",
      "AC1NUPQ3",
      "BDBM21014",
      "DTXSID80232802",
      "ZINC150345473",
      "LS-187519",
      "LS-186863",
    ],
  },
  {
    primary_id: "D0LX6R",
    names: ["(S)-N-Oleoyltyrosinol"],
  },
  {
    primary_id: "D0LX6X",
    names: ["Indole-Based Analog 13"],
  },
  {
    primary_id: "D0LX8D",
    names: ["AZD6918"],
  },
  {
    primary_id: "D0LX8Z",
    names: ["OCV-101"],
  },
  {
    primary_id: "D0LX9F",
    names: ["PMID27841045-Compound-157"],
  },
  {
    primary_id: "D0LX9H",
    names: ["Tysuberprost"],
  },
  {
    primary_id: "D0LY0C",
    names: ["Radiolabelled-HuA33"],
  },
  {
    primary_id: "D0LY0E",
    names: ["ISIS 114532"],
  },
  {
    primary_id: "D0LY3D",
    names: [
      "Ascofuranone And Analogs",
      "Ascofuranone",
      "ARA-01",
      "Ascofuranone And Analogs (Trypanosomiasis)",
      "Trypanosomiasis Therapy, ARigen/DNDi",
      "Ascofuranone And Analogs (Trypanosomiasis), ARigen/DNDi",
    ],
  },
  {
    primary_id: "D0LY5S",
    names: [
      "N-(1H-Benzo[d]imidazol-2-Yl)-3-Cyanobenzamide",
      "CHEMBL426133",
      "AC1MAT99",
      "MolPort-004-136-030",
      "ZINC6082995",
      "BDBM50184703",
      "AKOS008916776",
      "MCULE-9954960051",
      "N-(1H-Benzimidazol-2-Yl)-3-Cyanobenzamide",
      "N-(1H-1,3-Benzodiazol-2-Yl)-3-Cyanobenzamide",
      "Z27664939",
    ],
  },
  {
    primary_id: "D0LY6R",
    names: ["Artemisinin-Based Iron Chelators", "Artemisinin-Based Iron Chelators, Artemisia BioMedical"],
  },
  {
    primary_id: "D0LY6S",
    names: ["PMID28627961-Compound-31"],
  },
  {
    primary_id: "D0LZ1W",
    names: ["PMID26651364-Compound-122"],
  },
  {
    primary_id: "D0LZ1Z",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 7"],
  },
  {
    primary_id: "D0LZ2M",
    names: ["PMID25399762-Compound-Figure4-Incarviatone A"],
  },
  {
    primary_id: "D0LZ3O",
    names: [
      "N1-[4-(Phenylmethoxy)Phenyl]-L-Aspartamine",
      "(3S)-3-Amino-4-Oxo-4-[(4-Phenylmethoxyphenyl)Amino]butanoic Acid",
      "Amino Acid Analog, 4b",
      "CHEMBL477307",
      "BDBM24252",
      "N-[4-(Benzyloxy)Phenyl]-L-Alpha-Asparagine",
      "(3S)-3-Amino-3-{[4-(Benzyloxy)Phenyl]carbamoyl}propanoic Acid",
    ],
  },
  {
    primary_id: "D0LZ4A",
    names: ["Benzodihydroisofuran Derivative 2"],
  },
  {
    primary_id: "D0LZ4V",
    names: ["PMID27551786-Compound-II"],
  },
  {
    primary_id: "D0LZ5Q",
    names: ["PMID25776143-Compound-4"],
  },
  {
    primary_id: "D0LZ5R",
    names: ["T0156"],
  },
  {
    primary_id: "D0LZ7E",
    names: ["TP-434"],
  },
  {
    primary_id: "D0LZ7G",
    names: ["ACTR707"],
  },
  {
    primary_id: "D0LZ7Z",
    names: ["RG6264"],
  },
  {
    primary_id: "D0LZ8I",
    names: ["Tetra-Hydro-Naphthalene Derivative 3"],
  },
  {
    primary_id: "D0M0AA",
    names: ["MenHibrix"],
  },
  {
    primary_id: "D0M0AK",
    names: ["PMID27410995-Compound-Figure3k"],
  },
  {
    primary_id: "D0M0AM",
    names: ["Glycopyrrolate"],
  },
  {
    primary_id: "D0M0BM",
    names: [
      "6-Chlorotacrine Hydrochloride",
      "6-Chlorotacrine",
      "5778-84-7",
      "9-AMINO-6-CHLORO-1,2,3,4-TETRAHYDRO-ACRIDINE",
      "Tacrine Analogue 4",
      "6-Chlorotacrine.HCl",
      "6-Chlorotacrine HCl",
      "6-Chloro-1,2,3,4-Tetrahydroacridin-9-Amine",
      "SCHEMBL4271238",
      "BDBM8987",
      "ZINC5689",
      "CHEMBL292314",
      "GQZMDBQRGRRPMS-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0M0BN",
    names: ["C[RGDf-(R)-Alpha-TfmF]", "CHEMBL381589"],
  },
  {
    primary_id: "D0M0CF",
    names: ["Farnesol"],
  },
  {
    primary_id: "D0M0CO",
    names: ["PCAR-019 (Anti-CD19 CAR-T Cells)"],
  },
  {
    primary_id: "D0M0CU",
    names: ["ADE-DERM"],
  },
  {
    primary_id: "D0M0EE",
    names: ["N-(2-(4-Hexyl-2-Hydroxyphenoxy)Phenyl)Acetamide", "CHEMBL264176"],
  },
  {
    primary_id: "D0M0EP",
    names: ["PMID25399762-Compound-Figure3-Lycojaponicumin A"],
  },
  {
    primary_id: "D0M0FE",
    names: [
      "N-[2-(4'-Nitrophenyl)Ethyl]-Imidazole",
      "1-(4-Nitrophenethyl)-1H-Imidazole",
      "56643-91-5",
      "1-[2-(4-Nitrophenyl)Ethyl]-1H-Imidazole",
      "CHEMBL1098425",
      "1H-Imidazole, 1-[2-(4-Nitrophenyl)Ethyl]-",
      "1-[2-(4-Nitrophenyl)Ethyl]imidazole",
      "AC1MOACC",
      "MLS001195428",
      "SCHEMBL9663626",
      "CTK1F4146",
      "DTXSID30391397",
      "MolPort-002-886-896",
      "HCPMKJOVCNZGSD-UHFFFAOYSA-N",
      "HMS2885O15",
      "ZINC4025449",
      "BDBM50318407",
      "AKOS010189447",
      "MCULE-3977633271",
      "MS-1973",
      "KS-0000281S",
      "SMR000550723",
      "N-[2-(4''-Nitrophenyl)Ethyl]-Imidazole",
      "SR-01000308458",
    ],
  },
  {
    primary_id: "D0M0FY",
    names: ["Recombinant Human Hepatocyte Growth Factor"],
  },
  {
    primary_id: "D0M0GJ",
    names: ["SPV-30", "Boxwood Extract (HIV), Arkopharma"],
  },
  {
    primary_id: "D0M0GV",
    names: ["[32P]MRS2500", "[32P]2-Iodo-N6-Methyl-(N)-Methanocarba-2'-Deoxyadenosine-3',5'-Bisphosphate"],
  },
  {
    primary_id: "D0M0HE",
    names: ["YM-75440"],
  },
  {
    primary_id: "D0M0HJ",
    names: ["S-18986"],
  },
  {
    primary_id: "D0M0HM",
    names: ["N-Benzyl-(6-Methoxy-2-Naphthyl)-2-Aminopropane", "CHEMBL510214"],
  },
  {
    primary_id: "D0M0IQ",
    names: ["Bis-(5-Pyridin-3-Yl-Thiophen-2-Ylmethyl)-Amine", "CHEMBL178711", "SCHEMBL3615338"],
  },
  {
    primary_id: "D0M0JF",
    names: ["ISIS 10311"],
  },
  {
    primary_id: "D0M0JR",
    names: ["NsG-33"],
  },
  {
    primary_id: "D0M0KA",
    names: ["1-Adamantan-1-Yl-3-(5-Butoxypentyl)Urea", "CHEMBL245142", "SCHEMBL2050549"],
  },
  {
    primary_id: "D0M0KB",
    names: ["MK-6592"],
  },
  {
    primary_id: "D0M0KJ",
    names: ["X-081-NAB"],
  },
  {
    primary_id: "D0M0KX",
    names: ["2-Fluoro-4-[5-(3-Hydroxyphenyl)-2-Thienyl]phenol", "CHEMBL577338", "SCHEMBL1180589"],
  },
  {
    primary_id: "D0M0LC",
    names: ["5-(2-(4-Fluoro-3-Methylphenyl)Ethynyl)Pyrimidine", "CHEMBL488232"],
  },
  {
    primary_id: "D0M0MX",
    names: ["CIGB-247"],
  },
  {
    primary_id: "D0M0NT",
    names: ["PF-04620110"],
  },
  {
    primary_id: "D0M0OT",
    names: ["Leukemia Cancer Vaccine"],
  },
  {
    primary_id: "D0M0PY",
    names: ["DX-890"],
  },
  {
    primary_id: "D0M0QJ",
    names: ["ADS-102550", "CHEMBL217716", "SCHEMBL5903759", "BDBM50198218"],
  },
  {
    primary_id: "D0M0QQ",
    names: ["XMD8-92"],
  },
  {
    primary_id: "D0M0QX",
    names: ["MK-591"],
  },
  {
    primary_id: "D0M0RE",
    names: ["PMID29671355-Compound-67"],
  },
  {
    primary_id: "D0M0RN",
    names: ["SCH-42495"],
  },
  {
    primary_id: "D0M0RS",
    names: ["LGD-2226"],
  },
  {
    primary_id: "D0M0SS",
    names: ["FARGLITAZAR"],
  },
  {
    primary_id: "D0M0SV",
    names: [
      "PRS-110",
      "C-Met Inhibitor (Cancer), Pieris",
      "C-Mesenchymal-Epithelial Transition Factor (Lipoprotein/Protein Recombinant, Cancer), Pieris AG",
    ],
  },
  {
    primary_id: "D0M0TM",
    names: [
      "CANTHARIDIC_ACID",
      "Cantharidic Acid",
      "2,3-Dimethyl-7-Oxabicyclo[2.2.1]heptane-2,3-Dicarboxylic Acid",
      "Exo-1,6-Dicarboxy-Endo-1,6-Dimethyl-7-Oxabicylco[2,2,1]heptane",
      "2,3-Dicarboxy-2,3-Dimethyl-1,4-Epoxycyclohexane",
      "NSC115503",
      "AC1L1DX2",
      "Lopac0_000318",
      "SCHEMBL943636",
      "BMK1-G11",
      "CHEMBL275516",
      "CTK7I5696",
      "HMS3260P18",
      "Tox21_500318",
      "HSCI1_000263",
      "CCG-204413",
      "NSC-115503",
      "LP00318",
      "NCGC00093763-02",
      "NCGC00015272-04",
      "NCGC00015272-03",
      "NCGC00015272-02",
      "NCGC00261003-01",
      "NCGC00093763-01",
      "EU-0100318",
      "C 8088",
      "SR-01000075783",
      "SR-01000075783-1",
    ],
  },
  {
    primary_id: "D0M0TS",
    names: ["(2S)-Aminobutyryl-L-Proline (R)-Sec-Butylamide", "CHEMBL376183"],
  },
  {
    primary_id: "D0M0TV",
    names: ["BIIB059"],
  },
  {
    primary_id: "D0M0UB",
    names: ["AZD2184"],
  },
  {
    primary_id: "D0M0UE",
    names: ["PMID26882240-Compound-2"],
  },
  {
    primary_id: "D0M0UJ",
    names: ["GS-9148"],
  },
  {
    primary_id: "D0M0UT",
    names: ["Procysteine"],
  },
  {
    primary_id: "D0M0UU",
    names: ["ZYGK-1"],
  },
  {
    primary_id: "D0M0UV",
    names: [
      "INXC-ICAM1",
      "Antisense ICAM-1-TCS, Inex",
      "ICAM-1 Antisense (Inflammation), INEX",
      "Transmembrane Carrier System (Antisense ICAM-1), Inex",
      "Antisense (ICAM-1, Inflammation), INEX",
    ],
  },
  {
    primary_id: "D0M0VI",
    names: ["BODIPY-Pirenzepine", "BoPz"],
  },
  {
    primary_id: "D0M0VN",
    names: [
      "BISNAFIDE MESILATE",
      "DMP-840",
      "Bisnafide Mesilate",
      "Bisnafide Mesylate",
      "(R,R)-2,2'-(Ethane-1,2-Diyl)Bis[imino(1-Methylethane-2,1-Diyl)]bis(5-Nitro-2,3-Dihydro-1H-Benzo[de]isoquinoline-1,3-Dione) Dimethanesulfonate",
      "(R,R)-2,2'-(Ethylenediimino)Bis(1-Methyl-2,1-Ethanediyl)Bis(5-Nitro-2,3-Dihydro-1H-Benzo[de]isoquinoline-1,3-Dione) Dimethanesulfonate",
    ],
  },
  {
    primary_id: "D0M0VP",
    names: ["Medicor", "Anti-TL1-A Mab (Vaccination, Adjuvant), Heat Biologics"],
  },
  {
    primary_id: "D0M0WJ",
    names: ["Liposomal Encapsulated Doxorubicin (LED)"],
  },
  {
    primary_id: "D0M0WM",
    names: [
      "ATI-355",
      "Atinumab",
      "Anti-Nogo-A MAbs, Novartis",
      "Anti-Nogo-A Monoclonal Antibodies, Novartis",
      "11C7",
      "7B12",
    ],
  },
  {
    primary_id: "D0M0WS",
    names: ["3-Oxo-2,3-Dihydro-1H-Indazole-4-Carboxamide Derivative 2"],
  },
  {
    primary_id: "D0M0WT",
    names: ["NSC-99528"],
  },
  {
    primary_id: "D0M0WV",
    names: ["Fasiglifam Hemihydrate"],
  },
  {
    primary_id: "D0M0WX",
    names: ["3-(6-Phenyl-2,4'-Bipyridin-4-Yl)Phenol", "CHEMBL1094095"],
  },
  {
    primary_id: "D0M0WY",
    names: ["KSB-307"],
  },
  {
    primary_id: "D0M0XB",
    names: ["AZD0530"],
  },
  {
    primary_id: "D0M0XC",
    names: ["A839977", "A-839977", "A 839977"],
  },
  {
    primary_id: "D0M0XL",
    names: [
      "6-(4-Hydroxy-Phenyl)-1-Methoxy-Naphthalen-2-Ol",
      "CHEMBL195786",
      "SCHEMBL5980579",
      "DSBWWPDVDPKMHN-UHFFFAOYSA-N",
      "ZINC13645027",
      "BDBM50168344",
    ],
  },
  {
    primary_id: "D0M0XR",
    names: ["(R/R)BEFLOXATONE", "CHEMBL18101", "SCHEMBL9463614", "BDBM50110715"],
  },
  {
    primary_id: "D0M0XS",
    names: ["3beta-Acetoxyolean-12-En-27-Oic Acid", "CHEMBL208639"],
  },
  {
    primary_id: "D0M0XU",
    names: ["Ebola/Marburg Vaccine", "Ebola/Marburg Vaccine (AdVac, PER.C6)"],
  },
  {
    primary_id: "D0M0YB",
    names: ["BAY 11-42524"],
  },
  {
    primary_id: "D0M0YE",
    names: ["HCV-Specific STAR Fusions"],
  },
  {
    primary_id: "D0M0YH",
    names: ["FMet-Leu-Phe"],
  },
  {
    primary_id: "D0M0YO",
    names: [
      "S-Methyl 4-Methyl-4-Morpholinopent-2-Ynethioate",
      "CHEMBL447917",
      "SCHEMBL18768283",
      "4-Methyl-4-Morpholin-4-Ylpent-2-Ynthioic Acid S-Methyl Ester",
    ],
  },
  {
    primary_id: "D0M0ZB",
    names: ["H-Ala-Pro-Phe-Phe-NH2", "CHEMBL589082"],
  },
  {
    primary_id: "D0M0ZX",
    names: ["6-Ethyl-3-(2-Ethylbutoxycarbonyl)-4-Quinolone", "CHEMBL383080"],
  },
  {
    primary_id: "D0M1AC",
    names: ["Cyclotheonamide E4", "CHEMBL448961"],
  },
  {
    primary_id: "D0M1AE",
    names: ["N-{4-[(Benzylamino)Sulfonyl]-1-Naphthyl}benzamide", "CHEMBL222111", "SCHEMBL1339724"],
  },
  {
    primary_id: "D0M1BF",
    names: ["S6716", "3-Amidinobenzylindole Carboxamide 23"],
  },
  {
    primary_id: "D0M1BJ",
    names: [
      "L-735,821",
      "CHEMBL332826",
      "AC1NQZS7",
      "C13853",
      "CHEBI:34807",
      "L 735821",
      "(E)-3-(2,4-Dichlorophenyl)-N-[(3R)-1-Methyl-2-Oxo-5-Phenyl-3H-1,4-Benzodiazepin-3-Yl]prop-2-Enamide",
      "SureCN8611879",
      "SCHEMBL8837547",
      "SCHEMBL8611879",
      "GTPL2592",
      "CHEBI:303776",
      "BDBM50061218",
      "L735821",
      "N-[[(3R)-1-Methyl-2-Oxo-5-Phenyl-2,3-Dihydro-1H-1,4-Benzodiazepin]-3-Yl]-2,4-Dichlorobenzeneacrylamide",
      "3-(2,4-Dichloro-Phenyl)-N-(1-Methyl-2-Oxo-5-Phenyl-2,3-Dihydro-1H-Benzo[e][1,4]diazepin-3-Yl)-Acrylamide",
    ],
  },
  {
    primary_id: "D0M1CG",
    names: ["Allyl-Trimethyl-Ammonium"],
  },
  {
    primary_id: "D0M1CZ",
    names: ["Rh-Alphaglucosidase"],
  },
  {
    primary_id: "D0M1DE",
    names: ["PMA-109R"],
  },
  {
    primary_id: "D0M1DG",
    names: ["RTL-1000"],
  },
  {
    primary_id: "D0M1EG",
    names: ["P2X4 Inhibitora"],
  },
  {
    primary_id: "D0M1EI",
    names: ["5-(2-Methylquinolin-7-Yl)-2-Phenylbenzonitrile", "CHEMBL1099113"],
  },
  {
    primary_id: "D0M1EP",
    names: ["WNV DNA Vaccine"],
  },
  {
    primary_id: "D0M1HH",
    names: ["House Dust Mite Allergy Sublingual Vaccine"],
  },
  {
    primary_id: "D0M1II",
    names: ["HS-25"],
  },
  {
    primary_id: "D0M1IM",
    names: ["8H9 Mab"],
  },
  {
    primary_id: "D0M1IN",
    names: ["Acelarin"],
  },
  {
    primary_id: "D0M1IO",
    names: [
      "Bacitracin",
      "Altracin",
      "Baciguent",
      "Baciquent",
      "Citracin",
      "Fortracin",
      "Bacitracin A",
      "Bacitracin A1",
      "Bacitracin A2a",
      "Neosporin (TN)",
      "Solu-Tracin 50",
      "Partyl-, (10.4)-Lactam",
      "L-Asparagine, N-[[(4R)-2-[(1S,2S)-1-Amino-2-Methylbutyl]-4,5-Dihydro-4-Thiazolyl]carbonyl]-L-Leucyl-D-.alpha.-Glutamyl-L-Isoleucyl-L-Lysyl-D-Ornithyl-L-Isoleucyl-D-Phenylalanyl-L-Histidyl-D-.alpha.-As",
      "11702_FLUKA",
      "11702_SIGMA",
    ],
  },
  {
    primary_id: "D0M1JC",
    names: ["ISIS-EIF4ERx"],
  },
  {
    primary_id: "D0M1JT",
    names: ["4-Methoxy-5-Phenyl-6-Thia-10b-Aza-Benzo[e]azulene", "CHEMBL142639", "ZINC13603872", "BDBM50029753"],
  },
  {
    primary_id: "D0M1KH",
    names: [
      "PM-181104",
      "Antimicrobial (Oral), Piramal Life Sciences",
      "Antimicrobial (Oral), NPIL Research & Development",
    ],
  },
  {
    primary_id: "D0M1KK",
    names: ["PNB-03"],
  },
  {
    primary_id: "D0M1KX",
    names: [
      "Cobalt Hexammine Ion",
      "Cobalt Hexammine(Iii)",
      "Hexaamminecobalt(3+)",
      "Cobalt III Hexamine",
      "Hexaamminecobalt(III)",
      "Co(NH3)6",
      "Hexakis(Azaniumyl)Cobalt(3+)",
      "CHEBI:30027",
      "[Co(NH3)6](3+)",
    ],
  },
  {
    primary_id: "D0M1LM",
    names: ["Diamyd + GABA"],
  },
  {
    primary_id: "D0M1LO",
    names: ["Piperidine-1-Carboxamidine"],
  },
  {
    primary_id: "D0M1MB",
    names: ["NESS-200P"],
  },
  {
    primary_id: "D0M1MN",
    names: ["AFP TCR"],
  },
  {
    primary_id: "D0M1MQ",
    names: ["JWH-203"],
  },
  {
    primary_id: "D0M1MZ",
    names: ["Reviroc"],
  },
  {
    primary_id: "D0M1ND",
    names: ["Pyrimidinyl Ethylenediamine Derivative 1"],
  },
  {
    primary_id: "D0M1NS",
    names: ["CTT-54"],
  },
  {
    primary_id: "D0M1OE",
    names: [
      "ANOLOBINE",
      "Anolobine",
      "Anolobin",
      "Annolobine",
      "AC1Q6ZQY",
      "641-17-8",
      "(R)-6,7,7a,8-Tetrahydro-5H-Benzo(G)-1,3-Benzodioxolo(6,5,4-De)Quinolin-10-Ol",
      "CHEBI:2742",
      "CHEMBL257746",
      "Analobine",
      "AC1L4VB7",
      "CTK5C0739",
      "DTXSID60214303",
      "BDBM50202287",
      "5H-Benzo(G)-1,3-Benzodioxolo(6,5,4-De)Quinolin-10-Ol, 6,7,7a,8-Tetrahydro-, (R)-",
      "C09338",
      "5H-Benzo[g]-1,3-Benzodioxolo[6,5,4-De]quinolin-10-Ol,6,7,7a,8-Tetrahydro-, (7aR)-",
    ],
  },
  {
    primary_id: "D0M1ON",
    names: ["SHP-141"],
  },
  {
    primary_id: "D0M1OU",
    names: ["XEN-907"],
  },
  {
    primary_id: "D0M1OX",
    names: ["COP1-LNP", "COP1-Targeting SiRNA (Hepatocellular Carcinoma), Tekmira/NCI"],
  },
  {
    primary_id: "D0M1PL",
    names: ["Gadoteridol"],
  },
  {
    primary_id: "D0M1PQ",
    names: ["Mobenakin"],
  },
  {
    primary_id: "D0M1PR",
    names: ["Prevnar"],
  },
  {
    primary_id: "D0M1QJ",
    names: ["PMID18754614C10"],
  },
  {
    primary_id: "D0M1RD",
    names: ["ICT-107"],
  },
  {
    primary_id: "D0M1RM",
    names: ["Heterocyclic-Tetracyclic Tetrahydrofuran Derivative 1"],
  },
  {
    primary_id: "D0M1SP",
    names: ["G-203-2c"],
  },
  {
    primary_id: "D0M1TF",
    names: ["NSC-40331"],
  },
  {
    primary_id: "D0M1TG",
    names: ["PMID28394193-Compound-32"],
  },
  {
    primary_id: "D0M1UA",
    names: ["Lysophosphatidylinositol"],
  },
  {
    primary_id: "D0M1UE",
    names: [
      "IDD-5",
      "Collidem",
      "Cancer Vaccine (Colorectal), IDM",
      "Cell Therapy (Colorectal Cancer), Immuno-Designed Molecules",
    ],
  },
  {
    primary_id: "D0M1UF",
    names: ["Aryl Piperazine Derivative 1"],
  },
  {
    primary_id: "D0M1VC",
    names: [
      "Amdinocillin",
      "Coactin",
      "Hexacillin",
      "Mecilinamo",
      "Mecillinam",
      "Mecillinamum",
      "Selexidin",
      "Amdinocillin Mecillinam",
      "Amdinocillin [USAN]",
      "Penicillin HX",
      "FL 1060",
      "Ro 109070",
      "Amdinocillin (USAN)",
      "Coactin (TN)",
      "FL-1060",
      "Mecilinamo [INN-Spanish]",
      "Mecillinam (INN)",
      "Mecillinamum [INN-Latin]",
      "Ro 10-9070",
      "Ro-10-9070",
      "Rel-(2R,6S)-6-{[(1E)-Azepan-1-Ylmethylene]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-(((Hexahydro-1H-Azepin-1-Yl)Methylene)Amino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-(Azepan-1-Ylmethylideneamino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-[(Azepan-1-Ylmethylidene)Amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-{[(1E)-Azepan-1-Ylmethylidene]amino}-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "6-((Hexahydro-1H-Azepin-1-Yl)Methyleneamino)Penicillanic Acid",
      "6beta-[(Azepan-1-Ylmethylidene)Amino]-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0M1VF",
    names: ["Tin Protoporphyrin IX"],
  },
  {
    primary_id: "D0M1WF",
    names: ["SR-13654"],
  },
  {
    primary_id: "D0M1WN",
    names: ["L-701324"],
  },
  {
    primary_id: "D0M1WU",
    names: ["AJM-300", "Alpha-4 Integrin Antagonist (Inflammatory Bowel Disease), Ajinomoto"],
  },
  {
    primary_id: "D0M1WW",
    names: ["TDENV"],
  },
  {
    primary_id: "D0M1XY",
    names: ["DYB-186"],
  },
  {
    primary_id: "D0M1YN",
    names: [
      "ATI-17000",
      "CompB",
      "J-113397",
      "UNII-00M5444DIY",
      "CHEMBL357076",
      "00M5444DIY",
      "J113397",
      "1-[(3R,4R)-1-(Cyclooctylmethyl)-3-(Hydroxymethyl)Piperidin-4-Yl]-3-Ethylbenzimidazol-2-One",
      "1-[(3R,4R)-1-Cyclooctylmethyl-3-Hydroxymethyl-4-Piperidyl]-3-Ethyl-1,3-Dihydro-2H-Benzimidazol-2-One",
      "AC1NSK6N",
      "J-113,397",
      "1-(1-Cyclooctylmethyl-3-Hydroxymethyl-4-Piperidyl)-3-Ethyl-1,3-Dihydro-2H-Benzimidazol-2-One",
      "256640-45-6",
      "SCHEMBL875219",
      "J 113397",
      "GTPL1691",
      "(+)-J-113397",
      "ZINC1483900",
      "BDBM50083230",
      "NCGC00344513-02",
      "2H-Benzimidazol-2-One,",
    ],
  },
  {
    primary_id: "D0M1YR",
    names: ["MDV3101"],
  },
  {
    primary_id: "D0M1YS",
    names: ["32-P BioSilicon", "BrachySil"],
  },
  {
    primary_id: "D0M1YU",
    names: ["VER-2692"],
  },
  {
    primary_id: "D0M1YV",
    names: ["ELAEOCARPENINE", "Elaeocarpenine"],
  },
  {
    primary_id: "D0M1YW",
    names: ["ANG-3407", "CYP17 Inhibitor (Prostate Tumor), Angion"],
  },
  {
    primary_id: "D0M1ZC",
    names: ["JWH-343"],
  },
  {
    primary_id: "D0M1ZH",
    names: [
      "M4112",
      "2-Chloro-2',6'-Acetoxylidide",
      "2-Chloro-N-(2,6-Dimethylphenyl)Acetamide",
      "1131-01-7",
      "2-Chloro-2',6'-Dimethylacetanilide",
      "N-Chloroacetyl-2,6-Dimethylaniline",
      "Chloroaceto-2,6-Xylidide",
      "Acetamide, 2-Chloro-N-(2,6-Dimethylphenyl)-",
      "Chloroacetamido-2,6-Xylidine",
      "1-Chloroacetylamino-2,6-Dimethylbenzene",
      "N-(2,6-Dimethylphenyl)-2-Chloroacetamide",
      "UNII-5D2B69OAPX",
      "NSC 37260",
      "5D2B69OAPX",
      "2-Chloroaceto-2,6-Xylidide",
      "2-Chloro-N-(2,6-Dimethyl-Phenyl)-Acetamide",
      "FPQQSNUTBWFFLB-UHFFFAOYSA-N",
      "N-Chloracetyl-2,6-Dimethylaniline",
      "[(2,6-Dimethyl",
    ],
  },
  {
    primary_id: "D0M1ZI",
    names: ["AZUMAMIDE B", "CHEMBL402727"],
  },
  {
    primary_id: "D0M1ZT",
    names: ["CPX-POM"],
  },
  {
    primary_id: "D0M2AN",
    names: ["AD-412"],
  },
  {
    primary_id: "D0M2AY",
    names: ["6-Ethyl-3-Propylaminocarbonyl-4-Quinolone", "CHEMBL204566"],
  },
  {
    primary_id: "D0M2BB",
    names: [
      "1-(4-(1H-Pyrazol-1-Yl)Benzyl)-4-Phenylpiperazine",
      "CHEMBL210318",
      "MolPort-009-381-113",
      "ZINC12523427",
      "BDBM50184798",
      "AKOS033005282",
      "MCULE-2217757517",
      "Z104379906",
      "1-Phenyl-4-{[4-(1H-Pyrazol-1-Yl)Phenyl]methyl}piperazine",
    ],
  },
  {
    primary_id: "D0M2BR",
    names: ["Factor 8"],
  },
  {
    primary_id: "D0M2CT",
    names: ["LY456236"],
  },
  {
    primary_id: "D0M2CY",
    names: [
      "4S-4,5-Diamino-N-(4-Phenoxyphenyl)Pentanamide",
      "CHEMBL477275",
      "BDBM24292",
      "Modified Amino Acid Analog, 12b",
    ],
  },
  {
    primary_id: "D0M2DJ",
    names: [
      "2-(2-Acetyl-4-Bromophenoxy)Acetic Acid",
      "34849-51-9",
      "CHEMBL384130",
      "SCHEMBL789387",
      "ZPKLKLLENLTZEX-UHFFFAOYSA-N",
      "MolPort-035-831-033",
      "2-Acetyl-4-Bromophenoxyacetic Acid",
      "BDBM50200252",
      "ZINC36127031",
      "AKOS005215962",
      "(2-Acetyl-4-Bromophenoxy)Acetic Acid",
      "AS-30410",
      "SY028375",
      "[(2-Acetyl-4-Bromophenyl)Oxy]acetic Acid",
      "Aceticacid,2-(2-Acetyl-4-Bromophenoxy)-",
      "Acetic Acid, (2-Acetyl-4-Bromophenoxy)-",
      "MFCD27949767 (95%)",
    ],
  },
  {
    primary_id: "D0M2DM",
    names: ["BBI-3000"],
  },
  {
    primary_id: "D0M2DP",
    names: ["REACTA"],
  },
  {
    primary_id: "D0M2EG",
    names: ["Nasal Glucagon"],
  },
  {
    primary_id: "D0M2EH",
    names: ["Non-PEGylated Butyrylcholinesterase"],
  },
  {
    primary_id: "D0M2EM",
    names: ["BENZOQUINONE"],
  },
  {
    primary_id: "D0M2EN",
    names: [
      "N-Butylresorcinol",
      "4-Butylresorcinol",
      "4-Butylbenzene-1,3-Diol",
      "18979-61-8",
      "4-N-Butylresorcinol",
      "Butylresorcinol",
      "4-Butylresorcin",
      "Resorcinol, 4-Butyl-",
      "Rucinol",
      "UNII-2IK4UQ3ZGA",
      "2,4-Dihydroxy-N-Butyl Benzen",
      "BRN 1942645",
      "2IK4UQ3ZGA",
      "CHEMBL450195",
      "2,4-Dihydroxy-N-Butyl Benzene",
      "CHEBI:81689",
      "AK-81459",
      "Q-200189",
      "Kopcinol",
      "4-(2,4-Dihydroxyphenyl)Butane",
      "N-Butyl Resorcinol",
      "Rucinol (TN)",
      "EC 606-191-2",
      "AC1L4FN4",
      "ACMC-1CA91",
      "1,3-Benzenediol,4-Butyl-",
      "SCHEMBL68792",
      "4-06-00-06003 (Beilstein Handbook Reference)",
      "1,3-Benzenediol, 4-Butyl-",
    ],
  },
  {
    primary_id: "D0M2EZ",
    names: ["KYS-05042"],
  },
  {
    primary_id: "D0M2FH",
    names: ["Influenza A Virus Vaccine H1N1"],
  },
  {
    primary_id: "D0M2GI",
    names: ["CI-1002"],
  },
  {
    primary_id: "D0M2GQ",
    names: ["ISS-1018"],
  },
  {
    primary_id: "D0M2HC",
    names: ["4-(2-(4-Methylphenylamino)Ethyl)-1H-Imidazole", "CHEMBL1096515", "BDBM50317870"],
  },
  {
    primary_id: "D0M2IB",
    names: ["MDL-18962"],
  },
  {
    primary_id: "D0M2IS",
    names: ["Hebergel"],
  },
  {
    primary_id: "D0M2JA",
    names: ["ACP-106", "5-HT2A Inverse Agonists (CNS Disorders/Sleep Disorders), ACADIA"],
  },
  {
    primary_id: "D0M2JK",
    names: ["Aminopyridine Derivative 1"],
  },
  {
    primary_id: "D0M2JM",
    names: ["ISIS 119285"],
  },
  {
    primary_id: "D0M2JX",
    names: ["ISIS 29137"],
  },
  {
    primary_id: "D0M2KQ",
    names: ["N-(3,4,5-Trihydroxyphenethyl)Oleamide"],
  },
  {
    primary_id: "D0M2LD",
    names: ["BQCA"],
  },
  {
    primary_id: "D0M2LI",
    names: ["NNC-0123-0000-0338"],
  },
  {
    primary_id: "D0M2LV",
    names: ["(2S,3R)-2-(Benzyloxy)-3-Methoxycyclohexanone", "CHEMBL212678", "BDBM50186510"],
  },
  {
    primary_id: "D0M2MC",
    names: [
      "Phenmetrazine",
      "Cafilon",
      "Defenmetrazin",
      "Dexphenmetrazine",
      "Fenmetrazin",
      "Fenmetrazina",
      "Oxazimedrine",
      "Phenmetraline",
      "Phenmetrazin",
      "Phenmetrazinum",
      "Preludin Hydrochloride",
      "A 66",
      "Psychamine A 66",
      "Defenmetrazin (TN)",
      "Fenmetrazin (TN)",
      "Fenmetrazina [INN-Spanish]",
      "Oxazimedrine (TN)",
      "Phenmetraline (TN)",
      "Phenmetrazine [INN:BAN]",
      "Phenmetrazinum [INN-Latin]",
      "Dl-2-Phenyl-3-Methyltetrahydro-1,4-Oxazine",
      "2-Fenyl-3-Methylmorfolin",
      "2-Fenyl-3-Methylmorfolin [Czech]",
      "2-Phenyl-3-Methylmorpholine",
      "3-Methyl-2-Phenylmorpholine",
      "3-Methyl-2-Phenyltetrahydro-2H-1,4-Oxazine",
    ],
  },
  {
    primary_id: "D0M2ME",
    names: ["INDUSCA-1"],
  },
  {
    primary_id: "D0M2MR",
    names: [
      "Gliclazide",
      "Diaikron",
      "Diamicron",
      "Gliclazida",
      "Gliclazidum",
      "Gliklazid",
      "Glimicron",
      "Glyclazide",
      "Nordialex",
      "S 1702",
      "S 852",
      "SE 1702",
      "Diamicron (TN)",
      "Dianorm (TN)",
      "Gliclazida [INN-Spanish]",
      "Gliclazidum [INN-Latin]",
      "Glimicron (TN)",
      "S-1702",
      "S-852",
      "Gliclazide (JAN/INN)",
      "Glubitor-OD (TN)",
      "Gliclazide [BAN:INN:DCF:JAN]",
      "N-(Hexahydrocyclopenta[c]pyrrol-2(1H)-Ylcarbamoyl)-4-Methylbenzenesulfonamide",
      "N-[(Hexahydrocyclopenta[c]pyrrol-2(1H)-Ylamino)Carbonyl]-4-Methylbenzenesulfonamide",
      "N-(4-Methylbenzenesulfonyl)-N'-(3-Azabicyclo(3.3.0)Oct-3-Yl)Urea",
      "1-(3,3a,4,5,6,6a-Hexahydro-1H-Cyclopenta[c]pyrrol-2-Yl)-3-(4-Methylphenyl)Sulfonylurea",
      "1-(3-Azabicyclo(3.3.0)Oct-3-Yl)-3-(P-Tolylsulfonyl)Urea",
      "1-(3-Azabicyclo[3.3.0]oct-3-Yl)-3-P-Tolylsulphonylurea",
      "1-(Hexahydrocyclopenta(C)Pyrrol-2(1H)-Yl)-3-(P-Tolylsulfonyl)Urea",
    ],
  },
  {
    primary_id: "D0M2OJ",
    names: ["Peptidomimetics"],
  },
  {
    primary_id: "D0M2OK",
    names: ["Bis-Aminopyrimidine Derivative 5"],
  },
  {
    primary_id: "D0M2OL",
    names: ["SB-227931"],
  },
  {
    primary_id: "D0M2OW",
    names: ["Plasmodium Falciparum LSA-NRC Vaccine"],
  },
  {
    primary_id: "D0M2PE",
    names: ["1-Benzyl-1H-Indole-5-Carboxamidine", "CHEMBL101343", "BDBM50027286"],
  },
  {
    primary_id: "D0M2PP",
    names: [
      "VU0240551",
      "893990-34-6",
      "VU 0240551",
      "N-(4-Methyl-2-Thiazolyl)-2-[(6-Phenyl-3-Pyridazinyl)Thio]-Acetamide",
      "VU0240551-2-D4",
      "N-(4-Methylthiazol-2-Yl)-2-(6-Phenylpyridazin-3-Ylthio)Acetamide",
      "VU-0240551",
      "N-(4-Methyl-2-Thiazolyl)-2-[(6-Phenyl-3-Pyridazinyl)Thio]acetamide",
      "VU0240551-1",
      "N-(4-Methyl-1,3-Thiazol-2-Yl)-2-[(6-Phenylpyridazin-3-Yl)Thio]acetamide",
      "C16H14N4OS2",
      "C26H30N2O5",
      "N-(4-Methyl-1,3-Thiazol-2-Yl)-2-(6-Phenylpyridazin-3-Yl)Sulfanylacetamide",
      "AC1OHJ6T",
      "MLS000621247",
      "GTPL4665",
      "CHEMBL1491962",
      "SCHEMBL20240442",
      "BDBM43891",
    ],
  },
  {
    primary_id: "D0M2PZ",
    names: ["ISIS 103613"],
  },
  {
    primary_id: "D0M2QA",
    names: ["N-(3-Bromo-Benzoyl)-4-Chloro-Benzenesulfonamide", "CHEMBL186746", "ZINC16740683"],
  },
  {
    primary_id: "D0M2QH",
    names: [
      "Convallatoxin",
      "Corglycone",
      "Convallotoxin",
      "508-75-8",
      "Convallaotoxin",
      "Corglycon",
      "UNII-JY264VIR1Y",
      "Convallaton",
      "Korglykon",
      "Convallatoxoside",
      "Strophanthidin 3-O-Alpha-L-Rhamnoside",
      "JY264VIR1Y",
      "Strophanthidin, 3-(6-Deoxy-Alpha-L-Mannopyranoside)",
      "Corglykon",
      "CHEBI:27663",
      "3beta-(6-Deoxy-Alpha-L-Mannopyranosyloxy)-5,14-Dihydroxy-19-Oxo-5beta-Card-20(22)-Enolide",
      "3beta-((6-Deoxy-Alpha-L-Mannopyranosyl)Oxy)-5,14-Dihydroxy-19-Oxo-5beta-Card-20(22)-Enolide",
      "HSDB 3475",
      "Strophanthidin Alpha-L-Rhamnoside",
      "EINECS 208-086-3",
      "NSC 407808",
    ],
  },
  {
    primary_id: "D0M2QK",
    names: ["C(His-D-Phe-Arg-Trp-Ahp)", "CHEMBL379959"],
  },
  {
    primary_id: "D0M2QR",
    names: ["TOL-3021"],
  },
  {
    primary_id: "D0M2QW",
    names: ["Indolecarboxamides"],
  },
  {
    primary_id: "D0M2RC",
    names: ["N-[(4-Methoxyphenyl)Sulfonyl]-D-Alanine"],
  },
  {
    primary_id: "D0M2RI",
    names: ["AcAsp-Gla-Leu-Ile-Cha-Cys"],
  },
  {
    primary_id: "D0M2RT",
    names: ["Pyrazole Derivative 2"],
  },
  {
    primary_id: "D0M2RZ",
    names: ["EDP-MRSA-1", "Bridged Bicyclic Ketolide/Bicyclolide (MRSA Infection), Enanta"],
  },
  {
    primary_id: "D0M2SC",
    names: ["ISIS 9025"],
  },
  {
    primary_id: "D0M2SR",
    names: ["R-803"],
  },
  {
    primary_id: "D0M2TB",
    names: ["Trp-Ile-Ile-Asp-Leu-Hisc(Cys-Ser-Val-Tyr-Phe-Cys)", "CHEMBL408141"],
  },
  {
    primary_id: "D0M2TC",
    names: ["SLC-022"],
  },
  {
    primary_id: "D0M2TG",
    names: ["PYM-50028", "Cogane", "Smilagenin", "P-58", "P-63"],
  },
  {
    primary_id: "D0M2TS",
    names: [
      "Mivebresib",
      "ABBV-075",
      "1445993-26-9",
      "UNII-VR86R11J7J",
      "VR86R11J7J",
      "N-[4-(2,4-Difluorophenoxy)-3-(6-Methyl-7-Oxo-6,7-Dihydro-1h-Pyrrolo[2,3-C]pyridin-4-Yl)Phenyl]ethanesulfonamide",
      "N-(4-(2,4-Difluorophenoxy)-3-(6-Methyl-7-Oxo-6,7-Dihydro-1H-Pyrrolo[2,3-C]pyridin-4-Yl)Phenyl)Ethanesulfonamide",
      "8NG",
      "Mivebresib [INN]",
      "ABBV-075 (Mivebresib)",
      "ABBV075",
      "GTPL9117",
      "SCHEMBL15068241",
      "CHEMBL3987016",
      "Mivebresib(ABBV-075 Pound(C)",
      "MolPort-044-561-801",
      "RDONXGFGWSSFMY-UHFFFAOYSA-N",
      "BDBM220447",
      "EX-A1082",
      "S8400",
      "ZINC146486516",
      "AKOS030628486",
      "CS-5815",
    ],
  },
  {
    primary_id: "D0M2TY",
    names: ["RHEIN"],
  },
  {
    primary_id: "D0M2UT",
    names: ["NANT Squamous Cell Carcinoma Vaccine"],
  },
  {
    primary_id: "D0M2WA",
    names: ["Tricyclic Heterocycle Derivative 5"],
  },
  {
    primary_id: "D0M2WF",
    names: ["Technetium Tc-99m Albumin Microspheres Kit"],
  },
  {
    primary_id: "D0M2WG",
    names: ["Sulfamic Acid 4-(2-Hydroxy-Benzoyl)-Phenyl Ester", "CHEMBL65399"],
  },
  {
    primary_id: "D0M2WQ",
    names: ["CAP-7.1"],
  },
  {
    primary_id: "D0M2WZ",
    names: ["NS-2710"],
  },
  {
    primary_id: "D0M2XE",
    names: ["Insulin-Glulisine"],
  },
  {
    primary_id: "D0M2XJ",
    names: ["Quinoline And Quinazoline Derivative 5"],
  },
  {
    primary_id: "D0M2XK",
    names: ["CIK-CAR.CD19"],
  },
  {
    primary_id: "D0M2YE",
    names: ["Lanreotide Acetate"],
  },
  {
    primary_id: "D0M2YL",
    names: ["[125I]PD151242", "[125I](N-[{hexahydro-1-Azepinyl}carbonyl])Leu(1-Me)-DTrp-DTyr", "[125I]-PD151242"],
  },
  {
    primary_id: "D0M2YQ",
    names: ["PMID15482906C10"],
  },
  {
    primary_id: "D0M2YX",
    names: ["CIGB-228"],
  },
  {
    primary_id: "D0M2ZD",
    names: ["1,2-Bis(2,3-Difluorophenyl)-2-Hydroxyethanone", "CHEMBL244427"],
  },
  {
    primary_id: "D0M2ZE",
    names: ["UH-232"],
  },
  {
    primary_id: "D0M2ZG",
    names: ["PHA-647A"],
  },
  {
    primary_id: "D0M2ZX",
    names: ["Benzo[d]oxazole Derivative 4"],
  },
  {
    primary_id: "D0M3AH",
    names: [
      "TachoComb",
      "Collagen Fleece, Nycomed",
      "Collagen Fleece, Torii",
      "TO-193",
      "Fibrin/Thrombin/Aprotinin Collagen Fleece, Nycomed/Torii",
    ],
  },
  {
    primary_id: "D0M3AP",
    names: ["Bis(3-Fluorophenyl)-Ketone]thiosemicarbazone", "CHEMBL602732"],
  },
  {
    primary_id: "D0M3AR",
    names: ["L-168049"],
  },
  {
    primary_id: "D0M3BA",
    names: ["ISIS 23718"],
  },
  {
    primary_id: "D0M3BB",
    names: ["Synthetic Hypericin"],
  },
  {
    primary_id: "D0M3BJ",
    names: ["D[Lys8(5/6-Flu)]VT"],
  },
  {
    primary_id: "D0M3BY",
    names: ["PMID25980951-Compound-41"],
  },
  {
    primary_id: "D0M3CB",
    names: ["PMID27774822-Compound-Figure1Example20"],
  },
  {
    primary_id: "D0M3CG",
    names: [
      "R-N6-(Phenylisopropyl)Adenosine",
      "(R)-N-(1-Methyl-2-Phenylethyl)Adenosine",
      "(R)-PIA",
      "38594-96-6",
      "L-PIA",
      "L-Phenylisopropyladenosine",
      "Phenylisopropyladenosine, L-",
      "N6-D-Phenylisopropyladenosine",
      "(R)-(Phenylisopropyl)Adenosine",
      "(-)-(Phenylisopropyl)Adenosine",
      "N6-(R)-Phenylisopropyladenosine",
      "(R)-N6-Phenylisopropyladenosine",
      "(-)-PIA",
      "L-2-N6-(Phenylisopropyl)Adenosine",
      "L-N(Sup 6)-Phenylisopropyladenosine",
      "EINECS 254-028-5",
      "TH 162",
      "(R)-N6-(2-Phenylisopropyl)Adenosine",
      "BRN 4912716",
      "(-)-N-(Alpha-Methylphenethyl)Adensoine",
    ],
  },
  {
    primary_id: "D0M3CT",
    names: [
      "4-Phenylsulfanyl-7H-Pyrrolo[2,3-D]pyrimidine",
      "4-(Phenylsulfanyl)-7H-Pyrrolo[2,3-D]pyrimidine",
      "736142-84-0",
      "7h-Pyrrolo[2,3-D]pyrimidine,4-(Phenylthio)-",
      "SCHEMBL191691",
      "CHEMBL360302",
      "TUBUPSQEQADRHY-UHFFFAOYSA-N",
      "ZINC13608572",
      "KB-270401",
      "7H-Pyrrolo[2,3-D]pyrimidine, 4-(Phenylthio)-",
    ],
  },
  {
    primary_id: "D0M3CV",
    names: [
      "JZP-110",
      "(R)-2-Amino-3-Phenylpropyl Carbamate",
      "UNII-939U7C91AI",
      "939U7C91AI",
      "(2R)-2-Amino-3-Phenylpropyl Carbamate",
      "Solriamfetol",
      "Solriamfetol [USAN]",
      "Adx-N05 Free Base",
      "SCHEMBL2739637",
      "O-Carbamoyl-(D)-Phenylalaninol",
      "UCTRAOBQFUDCSR-SECBINFHSA-N",
      "ZINC34278783",
      "Benzenepropanol, Beta-Amino-, 1-Carbamate, (BetaR)-",
      "Benzenepropanol, Beta-Amino-, Carbamate (Ester), (R)-",
      "178429-62-4",
    ],
  },
  {
    primary_id: "D0M3DM",
    names: ["Indandione Derivative 1"],
  },
  {
    primary_id: "D0M3EA",
    names: ["2-Pyrazinone Derivative 6"],
  },
  {
    primary_id: "D0M3FH",
    names: ["1-1-1-Trichloroethane"],
  },
  {
    primary_id: "D0M3FJ",
    names: ["Oxytocin"],
  },
  {
    primary_id: "D0M3FZ",
    names: ["NOD Factors"],
  },
  {
    primary_id: "D0M3GK",
    names: ["RG7813"],
  },
  {
    primary_id: "D0M3GQ",
    names: [
      "BL-22",
      "CAT-3888",
      "GCR-3888",
      "RFB4(DsFv)-PE38",
      "PE38-Conjugated Anti-CD22 Immunotoxin, NCI/Cambridge Antibody Technology/AstraZeneca",
    ],
  },
  {
    primary_id: "D0M3GW",
    names: ["E-1210"],
  },
  {
    primary_id: "D0M3HV",
    names: ["Nitric Oxide Synthase Gene Therapy"],
  },
  {
    primary_id: "D0M3HZ",
    names: ["AZ13483342", "AZ 13483342", "AZ-13483342"],
  },
  {
    primary_id: "D0M3IE",
    names: ["VVZ-140"],
  },
  {
    primary_id: "D0M3IN",
    names: ["Xanthine"],
  },
  {
    primary_id: "D0M3JI",
    names: ["SM-19712"],
  },
  {
    primary_id: "D0M3KK",
    names: ["Perfluorochemical Emulsion"],
  },
  {
    primary_id: "D0M3KZ",
    names: ["NAD"],
  },
  {
    primary_id: "D0M3LK",
    names: ["BMS270394", "BMS 961", "BMS-270394", "BMS 270394"],
  },
  {
    primary_id: "D0M3MN",
    names: ["Leucovorin/5-Fluorouracil"],
  },
  {
    primary_id: "D0M3NF",
    names: [
      "DZ-13",
      "DNAzyme 13",
      "C-Jun Targeting DNAzyme (Basal Cell Carcinoma), NewSouth Innovations",
      "C-Jun Targeting Deoxyribozyme (Basal Cell Carcinoma), NewSouth Innovations",
    ],
  },
  {
    primary_id: "D0M3NR",
    names: ["Peptide Analog 68"],
  },
  {
    primary_id: "D0M3ON",
    names: ["Euro-Celtique 4"],
  },
  {
    primary_id: "D0M3OR",
    names: ["KW-2450"],
  },
  {
    primary_id: "D0M3PG",
    names: [
      "6,7-Dihydroxy-8-Nitro-1-Tetralone",
      "383382-48-7",
      "1(2h)-Naphthalenone,3,4-Dihydro-6,7-Dihydroxy-8-Nitro-",
      "CHEMBL8752",
      "SCHEMBL7428171",
      "CTK8I5216",
      "1(2H)-Naphthalenone, 3,4-Dihydro-6,7-Dihydroxy-8-Nitro-",
      "ZINC25989269",
    ],
  },
  {
    primary_id: "D0M3PO",
    names: [
      "2,4-Disulfamyltrifluoromethylaniline",
      "654-62-6",
      "4-Amino-6-(Trifluoromethyl)Benzene-1,3-Disulfonamide",
      "2-Amino-4-Trifluoromethyl-1,5-Benzendisulfonamide",
      "2,4-Disulfamyl-5-Trifluoromethylaniline",
      "UNII-YBN90877RI",
      "CHEMBL418",
      "YBN90877RI",
      "1,3-Benzenedisulfonamide, 4-Amino-6-(Trifluoromethyl)-",
      "5-Amino-Alpha,alpha,alpha-Trifluorotoluene-2,4-Disulfonamide",
      "5-Amino-Alpha,alpha,alpha-Trifluorotoluene-2,4-Disulphonamide",
      "C7H8F3N3O4S2",
      "2pow",
      "EINECS 211-506-8",
      "NSC 44625",
      "2,4-DISULFAMYLTRIFLUOROMETHYLANILINE",
      "AC1Q6TRC",
      "AC1L2C5H",
      "SCHEMBL3863705",
      "BDBM10866",
      "CTK5C2741",
    ],
  },
  {
    primary_id: "D0M3PV",
    names: ["L-741626"],
  },
  {
    primary_id: "D0M3QP",
    names: [
      "Digitoxin",
      "Acedoxin",
      "Asthenthilo",
      "Cardidigin",
      "Cardigin",
      "Cardiolanata",
      "Carditalin",
      "Carditoxin",
      "Coramedan",
      "Cristapurat",
      "Crystodigin",
      "Digicor",
      "Digilanid",
      "Digilong",
      "Digimed",
      "Digimerck",
      "Digipural",
      "Digisidin",
      "Digitoksim",
      "Digitoksin",
      "Digitophyllin",
      "Digitossina",
      "Digitoxina",
      "Digitoxine",
      "Digitoxinum",
      "Digitoxoside",
      "Digitoxosidum",
      "Digitrin",
      "Ditaven",
      "Glucodigin",
      "Lanatoxin",
      "Lanostabil",
      "Monodigitoxoside",
      "Myodigin",
      "Natigal",
      "Pandigal",
      "Panlanat",
      "Purodigin",
      "Purpurid",
      "Tardigal",
      "Unidigin",
      "Crystalline Digitalin",
      "Digitaline Cristallisee",
      "Digitaline Nativelle",
      "Digitalinum Verum",
      "Digitossina [DCIT]",
      "Digitoxigenin Tridigitoxoside",
      "LT00244784",
      "Crystodigin (TN)",
      "De-Tone",
      "Digitalin, Crystalline",
      "Digitaline (TN)",
      "Digitoxigenin-Tridigitoxosid",
      "Digitoxigenin-Tridigitoxosid [German]",
      "Digitoxina [INN-Spanish]",
      "Digitoxine [INN-French]",
      "Digitoxinum [INN-Latin]",
      "Mono-Digitoxid",
      "Mono-Digitoxid [German]",
      "Mono-Glycocard",
      "Purodigin, Crystalline",
      "Tri-Digitoxoside",
      "Tri-Digitoxoside [German]",
      "Digitoxin [INN:BAN:JAN]",
      "Digitoxin (JP15/USP/INN)",
      "Inhibits Na+/K+ ATPase",
      "5.beta.-Card-20(22)-Enolide, 3.beta.,14-Dihydroxy-, 3-[tris-(Digitoxoside)]",
    ],
  },
  {
    primary_id: "D0M3QT",
    names: ["VAL-201"],
  },
  {
    primary_id: "D0M3RI",
    names: ["CART-138 Cells"],
  },
  {
    primary_id: "D0M3RQ",
    names: [
      "5-(6-Hydroxy-2-Naphthyl)Pyridin-3-Ol",
      "CHEMBL261427",
      "SCHEMBL3002838",
      "RTRRNSMMHNFJIV-UHFFFAOYSA-N",
      "BDBM50373034",
    ],
  },
  {
    primary_id: "D0M3RY",
    names: ["4-Morpholino-6-Phenyl-2H-Pyran-2-One", "CHEMBL221409", "SCHEMBL3542543"],
  },
  {
    primary_id: "D0M3SC",
    names: [
      "N-(6-Chloro-5-P-Tolyl-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1097694",
      "SCHEMBL1461361",
      "REESNWQQNIKZJK-UHFFFAOYSA-N",
      "BDBM50313666",
    ],
  },
  {
    primary_id: "D0M3SS",
    names: ["GTU-Based DNA Vaccine"],
  },
  {
    primary_id: "D0M3TD",
    names: ["Org-43902"],
  },
  {
    primary_id: "D0M3TK",
    names: ["1-(2-(4-Fluorophenoxy)Phenyl)Piperazine", "CHEMBL573972"],
  },
  {
    primary_id: "D0M3TM",
    names: ["Prussian Blue"],
  },
  {
    primary_id: "D0M3UJ",
    names: ["ISIS 25073"],
  },
  {
    primary_id: "D0M3UR",
    names: ["THYMELEATOXIN"],
  },
  {
    primary_id: "D0M3UT",
    names: ["MURAGLITAZAR"],
  },
  {
    primary_id: "D0M3VA",
    names: ["SB-731489"],
  },
  {
    primary_id: "D0M3VE",
    names: ["Pyrazolopyrimidine Derivative 5"],
  },
  {
    primary_id: "D0M3VU",
    names: ["EQ-318"],
  },
  {
    primary_id: "D0M3XE",
    names: ["PF-06826647"],
  },
  {
    primary_id: "D0M3XF",
    names: ["1-(4-Tert-Butylphenylsulfonyl)-4-Methoxyazepane", "CHEMBL560396", "SCHEMBL12549992"],
  },
  {
    primary_id: "D0M3XR",
    names: ["MK-3102"],
  },
  {
    primary_id: "D0M3YA",
    names: [
      "(+/-)-Threo-N-(2-Methylthiopene)Methylphenidate",
      "CHEMBL1254843",
      "BDBM50327131",
      "(AlphaR)-Alpha-[(2R)-1-(2-Thienylmethyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0M3YC",
    names: ["FT-201"],
  },
  {
    primary_id: "D0M3YX",
    names: ["NPC-09"],
  },
  {
    primary_id: "D0M3ZV",
    names: [
      "9H-Carbazole-3-Carbaldehyde",
      "9H-Carbazole-3-Carboxaldehyde",
      "3-Formylcarbazole",
      "51761-07-0",
      "CHEMBL1172392",
      "3-Formyl-9H-Carbazole",
      "AC1L9YEB",
      "2-Formylcarbazole (Obsol.)",
      "SCHEMBL2775335",
      "CTK1G4153",
      "DTXSID70333418",
      "WRBOHOGDAJPJOQ-UHFFFAOYSA-N",
      "ZINC3641069",
      "BDBM50322592",
      "STL367222",
      "BBL026891",
      "AKOS000113618",
      "AB51127",
    ],
  },
  {
    primary_id: "D0M4AQ",
    names: ["2-((2-Iodophenoxy)(Phenyl)Methyl)Morpholine", "CHEMBL396328"],
  },
  {
    primary_id: "D0M4AV",
    names: [
      "CO-1686",
      "1374640-70-6",
      "AVL-301",
      "CO1686",
      "UNII-72AH61702G",
      "CNX-419",
      "CO-1686 (AVL-301)",
      "Rociletinib(AVL-301,CNX-419,CO-1686)",
      "72AH61702G",
      "N-(3-((2-((4-(4-Acetylpiperazin-1-Yl)-2-Methoxyphenyl)Amino)-5-(Trifluoromethyl)Pyrimidin-4-Yl)Amino)Phenyl)Acrylamide",
      "CO 1686",
      "Rociletinib (CO-1686, AVL-301)",
      "Rociletinib [USAN:INN]",
      "Tube721",
      "Rociletinib (USAN/INN)",
      "Rociletinib (CO-1686)",
      "SCHEMBL4177736",
      "GTPL7966",
      "CHEMBL3545308",
      "EX-A228",
      "MolPort-035-395-816",
      "C27H28F3N7O3",
      "HMS3653G08",
      "BDBM149404",
      "BCP07085",
      "AOB87314",
      "ZINC98043800",
      "S7284",
    ],
  },
  {
    primary_id: "D0M4BB",
    names: ["Chi Lob 7/4", "Anti-CD40 MAb (Chimeric, Cancer), Cancer Research UK"],
  },
  {
    primary_id: "D0M4BI",
    names: ["Benzamide Derivative 1"],
  },
  {
    primary_id: "D0M4BM",
    names: [
      "[1,3]Oxazinan-(2E)-Ylideneamine",
      "5,6-Dihydro-4h-1,3-Oxazin-2-Amine",
      "CHEMBL161118",
      "1848-68-6",
      "SCHEMBL31223",
      "2-Iminotetrahydro-1,3-Oxazine",
      "MolPort-044-586-586",
      "BDBM50049258",
      "ZINC13746441",
      "AKOS025312622",
      "AKOS006338233",
      "2-Amino-5,6-Dihydro-4h-1,3-Oxazine",
    ],
  },
  {
    primary_id: "D0M4BZ",
    names: [
      "2-(3-(Naphthalen-2-Yl)Propanamido)Benzoic Acid",
      "CHEMBL235740",
      "SCHEMBL5623042",
      "BDBM23518",
      "Biaryl Anthranilide Analogue, 1b",
      "TUCHRHOVXUFQLY-UHFFFAOYSA-N",
      "2-[3-(Naphthalen-2-Yl)Propanamido]benzoic Acid",
      "2-{[3-(2-Naphthalenyl)Propanoyl]amino}benzoic Acid",
    ],
  },
  {
    primary_id: "D0M4CP",
    names: ["[3H]Ro154513"],
  },
  {
    primary_id: "D0M4CX",
    names: ["NSC-613604"],
  },
  {
    primary_id: "D0M4DE",
    names: ["NP-184"],
  },
  {
    primary_id: "D0M4DI",
    names: ["MR-2266"],
  },
  {
    primary_id: "D0M4EF",
    names: ["C[Nle-Val-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL427205"],
  },
  {
    primary_id: "D0M4EH",
    names: ["Gram Positive Antibacterials"],
  },
  {
    primary_id: "D0M4EQ",
    names: ["PMID26815044-Compound-48"],
  },
  {
    primary_id: "D0M4EV",
    names: ["N-(4-Cyanophenyl)Sulfamide"],
  },
  {
    primary_id: "D0M4FO",
    names: ["PA-1093"],
  },
  {
    primary_id: "D0M4FW",
    names: ["THPO"],
  },
  {
    primary_id: "D0M4GN",
    names: ["Small-Cell Lung Cancer Mab Vaccine"],
  },
  {
    primary_id: "D0M4GR",
    names: ["(+/-)-2-(4'-Butoxyphenyl)Thiomorpholin-5-One", "CHEMBL598393", "BDBM50307364"],
  },
  {
    primary_id: "D0M4GX",
    names: ["Digoxin Immune Fab"],
  },
  {
    primary_id: "D0M4HC",
    names: [
      "L-757464",
      "5-Chloro-2-[4-[4-(2-Oxo-2,3-Dihydrobenzoxazol-3-Yl)Piperidin-1-Yl]butyl]-1,2-Benzisothiazol-3(2H)-One 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D0M4HL",
    names: ["P-7435", "DGAT1 Inhibitors (Diabetes/Metabolic Disorder), Piramal Life Sciences"],
  },
  {
    primary_id: "D0M4HM",
    names: ["MEN-10880"],
  },
  {
    primary_id: "D0M4IE",
    names: ["Bis-7-Tacrine", "Bis(7)-Tacrine"],
  },
  {
    primary_id: "D0M4IF",
    names: ["N-Oleoyl-Dopamine"],
  },
  {
    primary_id: "D0M4IR",
    names: ["KMI-494"],
  },
  {
    primary_id: "D0M4JK",
    names: ["UCB-2892"],
  },
  {
    primary_id: "D0M4JP",
    names: ["KNI-10759"],
  },
  {
    primary_id: "D0M4KE",
    names: ["Parthenolide"],
  },
  {
    primary_id: "D0M4KF",
    names: ["PMID28067079-Compound-97"],
  },
  {
    primary_id: "D0M4KN",
    names: [
      "10S-Hydroxylobelane",
      "CHEMBL122317",
      "SCHEMBL14178961",
      "BDBM50080814",
      "(S)-2-((2S,6R)-1-Methyl-6-Phenethyl-Piperidin-2-Yl)-1-Phenyl-Ethanol",
    ],
  },
  {
    primary_id: "D0M4LC",
    names: ["EPTALOPROST"],
  },
  {
    primary_id: "D0M4LF",
    names: [
      "AZD-1283",
      "AZD1283",
      "919351-41-0",
      "CHEMBL2419490",
      "AZD 1283",
      "Ethyl 6-(4-((Benzylsulfonyl)Carbamoyl)Piperidin-1-Yl)-5-Cyano-2-Methylnicotinate",
      "Ethyl 6-{4-[(Benzylsulfonyl)Carbamoyl]piperidin-1-Yl}-5-Cyano-2-Methylpyridine-3-Carboxylate",
      "6-(4-{[(Benzylsulfonyl)Amino]carbonyl}piperidin-1-Yl)-5-Cyano-2-Methylnicotinic Acid Ethyl Ester",
      "4ntj",
      "NEMHKCNXXRQYRF-UHFFFAOYSA-N",
      "AGN-PC-046SGK",
      "SCHEMBL3216200",
      "GTPL7360",
      "MolPort-046-033-568",
      "BCP10803",
      "EX-A1805",
      "BDBM50439277",
      "ZINC96283078",
      "AKOS030211015",
      "CS-3256",
      "NCGC00390624-01",
      "HY-15799",
    ],
  },
  {
    primary_id: "D0M4LJ",
    names: ["Melphalan Intravenous", "Captisol-Enabled"],
  },
  {
    primary_id: "D0M4LK",
    names: ["PMID28394193-Compound-55"],
  },
  {
    primary_id: "D0M4LN",
    names: ["ISIS 103671"],
  },
  {
    primary_id: "D0M4LQ",
    names: ["N-Butyl-4-Hydroxy-N-Phenylbenzenesulfonamide", "CHEMBL203810", "BDBM50177754"],
  },
  {
    primary_id: "D0M4LW",
    names: [
      "BIM-23268",
      "BIM-23268D",
      "L-Cysteinyl-L-Phenylalanyl-L-Phenylalanyl-D-Tryptophyl-L-Lysyl-L-Threonyl-L-Phenylalanyl-L-Cysteinamide Cyclic-(1-8)-Disulfide",
    ],
  },
  {
    primary_id: "D0M4MA",
    names: [
      "BROMODEOXYURIDINE",
      "5-BROMO-2'-DEOXYURIDINE",
      "59-14-3",
      "Broxuridine",
      "Bromodeoxyuridine",
      "5-Bromodeoxyuridine",
      "BRDU",
      "5-BrdU",
      "BUDR",
      "5-Bromouracil Deoxyriboside",
      "Bromouracil Deoxyriboside",
      "5-Bromodesoxyuridine",
      "5-Bdu",
      "Broxuridinum",
      "Broxuridina",
      "5-Bromouracil-2-Deoxyriboside",
      "5-Bromo-2-Deoxyuridine",
      "Bromodeoxyuridine (Brdu)",
      "Broxuridinum [INN-Latin]",
      "Uridine, 5-Bromo-2'-Deoxy-",
      "Broxuridina [INN-Spanish]",
      "C9H11BrN2O5",
      "NSC-38297",
      "Bromoouridine",
      "Radibud",
      "CCRIS 818",
      "Brudr",
      "UNII-G34N38R2N1",
      "HSDB 7477",
      "CHEBI:472552",
      "EINECS 200-415-9",
      "5-Budr",
      "5-Bromo-2'-Deoxy Uridine",
    ],
  },
  {
    primary_id: "D0M4MG",
    names: ["Resten-NG"],
  },
  {
    primary_id: "D0M4MH",
    names: ["ATR-107"],
  },
  {
    primary_id: "D0M4MW",
    names: ["PPI-1301"],
  },
  {
    primary_id: "D0M4ND",
    names: ["4-(2-Aminoethyl)-2-Ethylphenol", "CHEMBL255750", "4-Methyl-A-Methyl-Tyramine", "BDBM50372983", "DB07346"],
  },
  {
    primary_id: "D0M4NN",
    names: ["ISIS 102466"],
  },
  {
    primary_id: "D0M4OC",
    names: ["Venofer"],
  },
  {
    primary_id: "D0M4PH",
    names: ["LOXO-101"],
  },
  {
    primary_id: "D0M4PP",
    names: [
      "1-(4-Methoxyphenyl)-2-(Phenylsulfonyl)Ethanone",
      "CHEMBL238620",
      "27918-37-2",
      "1-(4-Methoxyphenyl)-2-(Phenylsulfonyl)-1-Ethanone",
      "AC1N74YZ",
      "Oprea1_356612",
      "2-(Benzenesulfonyl)-1-(4-Methoxyphenyl)Ethanone",
      "SCHEMBL6203408",
      "CTK0J2325",
      "DTXSID00401432",
      "MolPort-002-876-892",
      "ZINC399878",
      "KS-00003CN3",
      "BDBM50212535",
      "AKOS005097711",
      "7H-365S",
      "MCULE-5138703884",
      "4'-Methoxy-Alpha-(Phenylsulfonyl)Acetophenone",
      "4'-METHOXY-2-(PHENYLSULFONYL)ACETOPHENONE",
      "Ethanone, 1-(4-Methoxyphenyl)-2-(Phenylsulfonyl)-",
    ],
  },
  {
    primary_id: "D0M4PV",
    names: ["AAV5-HFIXco"],
  },
  {
    primary_id: "D0M4PY",
    names: ["GTP-0125"],
  },
  {
    primary_id: "D0M4QA",
    names: ["SIM-010603"],
  },
  {
    primary_id: "D0M4QM",
    names: ["D(CH2)5[Tyr(Me)2]AVP"],
  },
  {
    primary_id: "D0M4RD",
    names: ["Crystalline Anhydrous Toluene Derivative 1"],
  },
  {
    primary_id: "D0M4RW",
    names: [
      "FLUPENTIXOLE",
      "(E)-Flupenthixol",
      "Beta-Flupenthixol",
      "Trans-Flupenthixol",
      "Trans-(E)-Flupentixol",
      "Trans-Flupentixol",
      "53772-85-3",
      "EINECS 258-759-0",
      "UNII-895OJP78MJ",
      "Flupentiol",
      "2709-56-0",
      "895OJP78MJ",
      "Flupenthixol, Beta",
      "FLUPENTHIXOL, Alpha",
      "1-Piperazineethanol, 4-(3-(2-(Trifluoromethyl)-9H-Thioxanthen-9-Ylidene)Propyl)-, (E)-",
      "(E)-4-(3-(2-(Trifluoromethyl)-9H-Thioxanthen-9-Ylidene)Propyl)-1-Piperazineethanol",
      "2-[4-[(3E)-3-[2-(Trifluoromethyl)Thioxanthen-9-Ylidene]propyl]piperazin-1-Yl]ethanol",
    ],
  },
  {
    primary_id: "D0M4RX",
    names: ["RG7685"],
  },
  {
    primary_id: "D0M4SL",
    names: ["N-Isopropyl Estrone-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0M4SV",
    names: ["BPI-3006"],
  },
  {
    primary_id: "D0M4SY",
    names: ["BMS-536924"],
  },
  {
    primary_id: "D0M4TF",
    names: [
      "Canvaxin",
      "Cancer Vaccine, CancerVax",
      "Melanoma Vaccine, John Wayne Cancer Institute",
      "Polyvalent Melanoma Cancer Vaccine (PMCV)",
    ],
  },
  {
    primary_id: "D0M4TJ",
    names: ["Ralinepag"],
  },
  {
    primary_id: "D0M4TN",
    names: ["BCP-1071"],
  },
  {
    primary_id: "D0M4UE",
    names: ["Herzyme"],
  },
  {
    primary_id: "D0M4UK",
    names: ["PMID26161824C70"],
  },
  {
    primary_id: "D0M4UX",
    names: ["NT-KO-009"],
  },
  {
    primary_id: "D0M4UY",
    names: ["MBI-853NL"],
  },
  {
    primary_id: "D0M4UZ",
    names: ["JNJ-39758979"],
  },
  {
    primary_id: "D0M4VM",
    names: [
      "Sulfacetamide",
      "Acetocid",
      "Acetosulfamin",
      "Acetosulfamine",
      "Albamine",
      "Albucid",
      "Alesten",
      "Cetamide",
      "Formosulfacetamide",
      "Klaron",
      "Oclucid",
      "Ophthacet",
      "Region",
      "SULSTER",
      "Sebizon",
      "Solfacetamide",
      "Steramide",
      "Sulamyd",
      "Sulfacet",
      "Sulfacetamida",
      "Sulfacetamidum",
      "Sulfacetimide",
      "Sulfacil",
      "Sulfacyl",
      "Sulfacylum",
      "Sulfair",
      "Sulfanilacetamide",
      "Sulfanilazetamid",
      "Sulphacetamide",
      "Sulphacetamidum",
      "Sulphasil",
      "Sultrin",
      "Urosulfon",
      "Urosulfone",
      "Isopto Cetamide",
      "Sodium Sulamyd",
      "Sodium Sulfacetamide",
      "Solfacetamide [DCIT]",
      "Sulfacetamide Monosodium Salt",
      "Sulfacetamide Sodium Usp",
      "Sulfacetamide Sodium",
      "Sulfacetamide Sodium Anhydrous",
      "Sulfanilazetamid [German]",
      "Sulphacetamide Sodium",
      "Sulfair 15",
      "A-500",
      "Ak-Sulf",
      "Bleph-10",
      "Bleph-10 Liquifilm",
      "FML-S",
      "I-Sulfacet",
      "Isopto-Cetamide",
      "Klaron (TN)",
      "N-Acetylsulfanilamide",
      "N-Acetylsulfanilamine",
      "N-Sulfanilylacetamide",
      "N-Sulphanilylacetamide",
      "N1-Acetylsulfanilamide",
      "OP-Sulfa 30",
      "Ocusulf-10",
      "Ophthel-S",
      "P-Aminobenzenesulfonacetamide",
      "P-Aminobenzenesulfonoacetamide",
      "Steri-Units Sulfacetamide",
      "Sulf-10",
      "Sulf-15",
      "Sulfacel-15",
      "Sulfacetamida [INN-Spanish]",
      "Sulfacetamide [USAN:INN]",
      "Sulfacetamidum [INN-Latin]",
      "Sulfacyl (VAN)",
      "Sulfair-15",
      "Sulten-10",
      "N'-Acetylsulfanilamide",
      "N(Sup 1)-Acetylsulfanilamide",
      "N(Sup1)-Acetylsulfanilamide",
      "Sulfacetamide (USP/INN)",
      "N-(4-Aminobenzenesulfonyl)Acetamide",
      "N-(4-Aminophenylsulfonyl)Acetamide",
      "N-(4-Aminophenyl)Sulfonylacetamide",
      "N-Acetyl-4-Aminobenzenesulfonamide",
      "N-[(4-Aminophenyl)Sulfonyl]acetamide",
      "N-[(P-Aminophenyl)Sulfonyl]acetamide",
      "N(Sup 1)-Acetyl-4-Aminophenylsulfonamide",
      "N-((4-Aminophenyl)Sulfonyl)Acetamide",
      "N-((P-Aminophenyl)Sulfonyl)Acetamide",
    ],
  },
  {
    primary_id: "D0M4VZ",
    names: ["D-Pro-Phe-Arg Chloromethyl Ketone"],
  },
  {
    primary_id: "D0M4WA",
    names: ["Obeticholic Acid"],
  },
  {
    primary_id: "D0M4WU",
    names: ["JCAR017"],
  },
  {
    primary_id: "D0M4WY",
    names: ["PMID25666693-Compound-29"],
  },
  {
    primary_id: "D0M4XD",
    names: ["(2'Z,3'E)-5-Nitro-5'-Methoxy-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0M4XH",
    names: [
      "1-Ethylidenethiosemicarbazide",
      "CHEMBL449882",
      "Ethanone Thiosemicarbazone",
      "AC1NUX6Z",
      "[(Z)-Ethylideneamino]thiourea",
      "SCHEMBL3066172",
      "ZINC38825875",
    ],
  },
  {
    primary_id: "D0M4XO",
    names: ["PMID25514969-Compound-Figure2-4"],
  },
  {
    primary_id: "D0M4XY",
    names: ["Omacetaxine Mepesuccinate"],
  },
  {
    primary_id: "D0M4YC",
    names: [
      "Disopyramide",
      "Dicorantil",
      "Disopiramida",
      "Disopyramidum",
      "Isorythm",
      "Lispine",
      "Ritmodan",
      "Rythmodan",
      "Disopyramide Free Base",
      "Rythmodan P",
      "D 7644",
      "H 3292",
      "SC 7031",
      "Searle 703",
      "Disopiramida [INN-Spanish]",
      "Disopyramidum [INN-Latin]",
      "Norpace (TN)",
      "Rythmodan (TN)",
      "Rythmodan P (TN)",
      "Rythmodan-La",
      "SC-7031",
      "Xi-Disopyramide",
      "Disopyramide (JP15/USAN/INN)",
      "Disopyramide [USAN:BAN:INN:JAN]",
      "Alpha-Diisopropylaminoethyl-Alpha-Phenylpyridine-2-Acetamide",
      "Gamma-Diisopropylamino-Alpha-Phenyl-Alpha-(2-Pyridyl)Butyramide",
      "Alpha-(2-(Diisopropylamino)Ethyl)-Alpha-Phenyl-2-Pyridineacetamide",
      "Alpha-[2-[Bis(1-Methylethyl)Amino]ethyl]-Alpha-Phenyl-2-Pyridine Acetamide",
      "4-(Diisopropylamino)-2-Phenyl-2-Pyridin-2-Ylbutanamide",
      "4-[di(Propan-2-Yl)Amino]-2-Phenyl-2-Pyridin-2-Ylbutanamide",
    ],
  },
  {
    primary_id: "D0M4YK",
    names: [
      "Decorin-Like Collagen-Binding Peptidoglycan",
      "Decorin-Like Collagen-Binding Peptidoglycan (Hypertrophic Scars)",
    ],
  },
  {
    primary_id: "D0M4YT",
    names: ["PMID1895299C6v"],
  },
  {
    primary_id: "D0M4ZK",
    names: ["10-Methoxy-Ginkgolide C", "CHEMBL221823"],
  },
  {
    primary_id: "D0M4ZQ",
    names: [
      "2-(3-Chloro-Phenyl)-1H-[1,8]naphthyridin-4-One",
      "CHEMBL45677",
      "NSC679034",
      "AC1L8S0S",
      "1, 2-(3-Chlorophenyl)-",
      "SCHEMBL7920166",
      "CTK8C9853",
      "ZINC5541496",
      "BDBM50059959",
      "NSC-679034",
      "NCI60_028233",
      "2-(3-Chlorophenyl)-1H-1,8-Naphthyridin-4-One",
      "2-(3-Chlorophenyl)-1,8-Naphthyridin-4(1H)-One",
      "1,8-Naphthyridin-4(1H)-One, 2-(3-Chlorophenyl)-",
    ],
  },
  {
    primary_id: "D0M4ZR",
    names: ["L-Fucose"],
  },
  {
    primary_id: "D0M5AG",
    names: ["Aromatic Bicyclic Compound 3"],
  },
  {
    primary_id: "D0M5AR",
    names: ["Pyrazole Derivative 65"],
  },
  {
    primary_id: "D0M5BE",
    names: ["ZX-008"],
  },
  {
    primary_id: "D0M5BI",
    names: ["Ampion", "Ampion (TN)"],
  },
  {
    primary_id: "D0M5BR",
    names: ["PMID28117607-Compound-21"],
  },
  {
    primary_id: "D0M5BS",
    names: [
      "ONO-6126",
      "21-Cyanosaframycin-B",
      "NSC 325663",
      "66082-27-7",
      "C29H30N4O8",
      "21-Cyanosaframycin B",
      "AC1L2P2O",
      "SCHEMBL637953",
      "CHEMBL452709",
    ],
  },
  {
    primary_id: "D0M5CD",
    names: ["Doxorubicin-Eluting Beads", "DC Bead (TN)"],
  },
  {
    primary_id: "D0M5CU",
    names: ["YM-337"],
  },
  {
    primary_id: "D0M5CW",
    names: ["CP-741952"],
  },
  {
    primary_id: "D0M5DB",
    names: [
      "BzT-7",
      "CHEMBL1958337",
      "8-Chloro-1,4-Dimethyl-6-Phenyl-4h-[1,2,4]triazolo[4,3-A][1,3,4]benzotriazepine",
      "3u5l",
      "GTPL7515",
      "SCHEMBL11468349",
      "UYIVCPRWMLOCSB-UHFFFAOYSA-N",
      "BDBM50365263",
      "8-Chloro-1,4-Dimethyl-6-Phenyl-[1,2,4]triazolo[4,3-A][1,3,4]benzotriazepine",
      "8-Chloro-1,4-Dimethyl-6-Phenyl-4H-S-Triazolo(4,3-A)(1,3,4)Benzotriazepine",
      "8-Chloro-1,4-Dimethyl-6-Phenyl-4H-S-Triazolo(4,3-A)(1,3,4 )Benzotriazepine",
      "8-Chloro-1,4-Dimethyl-6-Phenyl-4H-S-Triazolo(4,3-A) (1,3,4)Benzotriazepine",
    ],
  },
  {
    primary_id: "D0M5DH",
    names: [
      "CRA1000",
      "CRA-1000",
      "UNII-3899V9A17F",
      "3899V9A17F",
      "CRA 1000",
      "226948-11-4",
      "2-Pyrimidinamine, N-Ethyl-4-(4-(3-Fluorophenyl)-3,6-Dihydro-1(2H)-Pyridinyl)-6-Methyl-N-(4-(1-Methylethyl)-2-(Methylthio)Phenyl)-",
      "2-(N-(2-Methylthio-4-Isopropylphenyl)-N-Ethylamino)-4-(4-(3-Fluorophenyl)-1,2,3,6-Tetrahydropyridin-1-Yl)-6-Methylpyrimidine",
      "CHEMBL297986",
      "SCHEMBL6171730",
      "GTPL3498",
      "PDSP2_001710",
      "PDSP1_001727",
    ],
  },
  {
    primary_id: "D0M5DM",
    names: ["ISIS 29236"],
  },
  {
    primary_id: "D0M5DR",
    names: ["RGS4 Inhibitor 13", "CHEMBL2037369", "GTPL8034", "SCHEMBL13517588", "BDBM50384771"],
  },
  {
    primary_id: "D0M5DX",
    names: ["Oxazolyl Methylthiothiazole Derivative 1"],
  },
  {
    primary_id: "D0M5DY",
    names: ["SDZ-PDI-747"],
  },
  {
    primary_id: "D0M5EC",
    names: ["PMID27336223-Compound-8"],
  },
  {
    primary_id: "D0M5EN",
    names: ["Trigriluzole"],
  },
  {
    primary_id: "D0M5ES",
    names: [
      "CHLORPYRIFOS",
      "Chlorpyrifos",
      "2921-88-2",
      "Chlorpyriphos",
      "Dursban",
      "Trichlorpyrphos",
      "Lorsban",
      "Chlorpyrifos-Ethyl",
      "Pyrinex",
      "Coroban",
      "Brodan",
      "Killmaster",
      "Piridane",
      "Danusban",
      "Spannit",
      "Lentrek",
      "Geodinfos",
      "Tafaban",
      "Stipend",
      "Bonidel",
      "Zidil",
      "Terial",
      "Durmet",
      "Equity",
      "Suscon Green",
      "Suscon Blue",
      "SuSCon",
      "Dursban R",
      "Dursban F",
      "Lock-On",
      "Dursban 4E",
      "Dowco 179",
      "Chlorpyriphos-Ethyl",
      "Ethyl Chlorpyriphos",
      "Dursban 10CR",
      "Chlorpyrifos (Dursban)",
      "Detmol U.A.",
      "Phosphorothioic Acid, O,O-Diethyl O-(3,5,6-Trichloro-2-Pyridinyl) Ester",
      "Chloropyrifos",
      "Clorpyrifos",
      "Dhanusban",
      "Grofo",
      "Detmol Ua",
    ],
  },
  {
    primary_id: "D0M5EU",
    names: ["(4-Benzylpiperidin-1-Yl)(M-Tolyl)Methanone"],
  },
  {
    primary_id: "D0M5EW",
    names: [
      "4-((1H-Imidazol-1-Yl)Methyl)-2H-Chromen-2-One",
      "CHEMBL427247",
      "2H-1-Benzopyran-2-One, 4-(1H-Imidazol-1-Ylmethyl)-",
      "828265-54-9",
      "Coumarin Deriv. 19",
      "BDBM9470",
      "CTK3D6009",
      "DTXSID40460922",
      "ZINC13586713",
    ],
  },
  {
    primary_id: "D0M5FF",
    names: [
      "Ro31-8220",
      "Bisindolylmaleimide IX",
      "Ro 31-8220",
      "125314-64-9",
      "Ro 31 8220",
      "Ro 318220",
      "UNII-W9A0B5E78O",
      "Ro-318220",
      "Ro-31-8220",
      "CHEMBL6291",
      "W9A0B5E78O",
      "CHEBI:38912",
      "3-{3-[4-(1-Methyl-1H-Indol-3-Yl)-2,5-Dioxo-2,5-Dihydro-1H-Pyrrol-3-Yl]-1H-Indol-1-Yl}propyl Carbamimidothioate",
      "Ro?31-8220?",
      "3-{3-[4-(1-Methyl-1H-Indol-3-Yl)-2,5-Dioxo-2,5-Dihydro-1H-Pyrrol-3-Yl]-1H-Indol-1-Yl}propyl Imidothiocarbamate",
      "CHEMBL1591531",
      "Carbamimidothioic Acid, 3-(3-(2,5-Dihydro-4-(1-Methyl-1H-Indol-3-Yl)-2,5-Dioxo-1H-Pyrrol-3-Yl)-1H-Indol-1-Yl)Propyl",
      "Bisindolymaleimide IX",
      "BISINDOLYLMALEIMIDE IX",
    ],
  },
  {
    primary_id: "D0M5FO",
    names: ["ML323"],
  },
  {
    primary_id: "D0M5GP",
    names: ["ISIS 20990"],
  },
  {
    primary_id: "D0M5GX",
    names: ["Priorix"],
  },
  {
    primary_id: "D0M5HV",
    names: ["Montelukast/Mometasone"],
  },
  {
    primary_id: "D0M5II",
    names: ["CART-19 Autologous T-Cells"],
  },
  {
    primary_id: "D0M5JA",
    names: ["Lumiliximab"],
  },
  {
    primary_id: "D0M5JC",
    names: [
      "BrevaRex",
      "AIT (Breast Cancer), AltaRex",
      "AIT (Breast Cancer), ViRexx",
      "MAb AR-20.5",
      "Vaccine (Breast Cancer), AltaRex",
      "Vaccine (Breastcancer), ViRexx",
      "Vaccine (Multiple Myeloma), Altarex",
      "Vaccine (Multiple Myeloma), ViRexx",
      "Anti-Idiotype Induction Therapy (Breast Cancer), AltaRex",
      "Anti-Idiotype Induction Therapy (Breast Cancer), ViRexx",
      "Anti-Idiotype Induction Therapy (Cancer), Paladin",
      "Anti-Idiotype Induction Therapy (Multiple Myeloma), AltaRex",
      "Anti-Idiotype Induction Therapy (Multiple Myeloma), United",
      "Anti-Idiotype Induction Therapy (Multiple Myeloma), ViRexx",
    ],
  },
  {
    primary_id: "D0M5JI",
    names: ["Luvox"],
  },
  {
    primary_id: "D0M5KC",
    names: ["POLYSIN"],
  },
  {
    primary_id: "D0M5KQ",
    names: ["Edurant LA"],
  },
  {
    primary_id: "D0M5LA",
    names: ["Des-AA1,2,4,5,11,12,13-[D-Trp8]SRIF", "CHEMBL368304"],
  },
  {
    primary_id: "D0M5LL",
    names: ["PMID30185082-Compound-56"],
  },
  {
    primary_id: "D0M5LP",
    names: [
      "BHQ",
      "2,6-Di-Tert-Butylhydroquinone",
      "2,6-Di-Tert-Butylbenzene-1,4-Diol",
      "2444-28-2",
      "2,6-Di-Tert-Butyl-Hydroquinone",
      "UNII-RW7RBM89DC",
      "1,4-Benzenediol, 2,6-Bis(1,1-Dimethylethyl)-",
      "RW7RBM89DC",
      "2,6-Di-T-Butylhydroquinone",
      "JFGVTUJBHHZRAB-UHFFFAOYSA-N",
      "2,6-Di-Tert-Butyl-1,4-Benzenediol",
      "Hydroquinone, 2,6-Di-Tert-Butyl-",
      "EINECS 219-481-5",
      "2,5-Ditertbutylhydroquinone",
      "AC1Q79LY",
      "Oprea1_865590",
      "SCHEMBL39962",
      "2,6-Di(T-Butyl)Hydroquinone",
      "3,5-Di-Tert-Butylhydroquinone",
      "CHEMBL375695",
      "GTPL5486",
      "AC1L2P04",
      "2,6-Di-Tert.butyl-Hydroquinone",
    ],
  },
  {
    primary_id: "D0M5MI",
    names: ["SC-560"],
  },
  {
    primary_id: "D0M5MW",
    names: ["DSP-0509"],
  },
  {
    primary_id: "D0M5NK",
    names: ["SDZ-PGU-693"],
  },
  {
    primary_id: "D0M5NR",
    names: [
      "1-Butyryl-1H-Indole-2,3-Dione",
      "92675-59-7",
      "Butyrylisatin",
      "AC1MXQ1S",
      "Isatin-Based Compound, 10",
      "1-Butanoylindole-2,3-Dione",
      "CHEMBL374970",
      "BDBM22790",
      "MolPort-002-322-431",
      "1-Butanoyl-1H-Indole-2,3-Dione",
      "ZINC3160706",
      "STK370040",
      "AKOS001849110",
      "MCULE-5909492944",
      "CCG-139393",
      "1-Butanoylbenzo[d]azolidine-2,3-Dione",
      "ST50561436",
    ],
  },
  {
    primary_id: "D0M5NU",
    names: ["N6 Benzyl-CAMP"],
  },
  {
    primary_id: "D0M5OC",
    names: ["Trandolapril"],
  },
  {
    primary_id: "D0M5OG",
    names: ["Hydroxyethyl Starch"],
  },
  {
    primary_id: "D0M5OI",
    names: ["CD40L"],
  },
  {
    primary_id: "D0M5OL",
    names: ["ISIS 122984"],
  },
  {
    primary_id: "D0M5PB",
    names: ["FTI 276"],
  },
  {
    primary_id: "D0M5PH",
    names: ["PF-04937319"],
  },
  {
    primary_id: "D0M5PI",
    names: [
      "Benzyl-(9-Isopropyl-9H-Purin-6-Yl)-Amine",
      "CHEMBL85015",
      "111853-20-4",
      "9H-Purin-6-Amine, 9-(1-Methylethyl)-N-(Phenylmethyl)-",
      "Purine Deriv. 9",
      "ACMC-20mewm",
      "SCHEMBL754120",
      "BDBM10641",
      "CTK0D3360",
      "DTXSID10444118",
      "6-(Benzylamino)-9-Isopropylpurine",
      "ZINC13538226",
      "AKOS030562149",
      "6-(Benzylamino)-9-Isopropyl-9H-Purine",
      "N-Benzyl-9-Isopropyl-9H-Purin-6-Amine",
    ],
  },
  {
    primary_id: "D0M5QD",
    names: ["COU-1", "Anti-CaOU-1 MAb (Colon Cancer), Affitech/ Natimmune"],
  },
  {
    primary_id: "D0M5QU",
    names: ["IkT-014"],
  },
  {
    primary_id: "D0M5RA",
    names: ["RU78783"],
  },
  {
    primary_id: "D0M5RF",
    names: ["Gestrinone", "Dimetrose (TN)"],
  },
  {
    primary_id: "D0M5TM",
    names: ["8-(3-Fluoro-Phenyl)-9-Methyl-9H-Purin-6-Ylamine", "CHEMBL48682"],
  },
  {
    primary_id: "D0M5UL",
    names: ["1,5-Benzodiazepines Series"],
  },
  {
    primary_id: "D0M5VF",
    names: [
      "3-(4-Phenoxy-Phenyl)-1H-Pyrazole",
      "5-(4-Phenoxyphenyl)-1H-Pyrazole",
      "CHEMBL41864",
      "111273-31-5",
      "1H-Pyrazole, 3-(4-Phenoxyphenyl)-",
      "ACMC-20me6r",
      "AC1MD5RK",
      "Maybridge3_001296",
      "Oprea1_637414",
      "MLS000851363",
      "SCHEMBL3364770",
      "CTK0G1854",
      "DTXSID00384478",
      "MolPort-001-792-591",
      "SGQSMRQYEPPNIA-UHFFFAOYSA-N",
      "3-(4-Phenoxyphenyl)-1H-Pyrazole",
      "HMS2799J18",
      "HMS1434K20",
      "BDBM50141064",
      "ZINC13559817",
      "AKOS023552923",
      "MCULE-9803599814",
      "IDI1_012683",
    ],
  },
  {
    primary_id: "D0M5VS",
    names: ["Aerugen"],
  },
  {
    primary_id: "D0M5VV",
    names: ["177Lu-DOTATATE"],
  },
  {
    primary_id: "D0M5WG",
    names: [
      "Aminodeoxykanamycin",
      "Bekanamycin",
      "Kanamycin B",
      "Nebramycin Factor 5",
      "Bekanamycine",
      "Bekanamycinum",
      "Becanamicina",
      "Bekanamycinum [INN-Latin]",
      "Bekanamycine [INN-French]",
      "Becanamicina [INN-Spanish]",
      "EINECS 225-170-5",
      "BRN 0061646",
      "Aminodeoxykanamycin Sulfate",
      "4696-76-8",
      "Antibiotic Derived From Streptomyces Kanamyceticus",
      "AC1L2FQA",
      "AC1Q57UP",
      "Antibiotic Derived From Streptomyces Kanamyceticus. Kanamycin B",
      "(1r,2s,4r,6s)-4,6-Diamino-3-[(3-Amino-3-Deoxy-",
      "A-D-Glucopyranosyl)Oxy]-2-Hydroxycyclohexyl 2,6-Diamino-2,6-Dideoxy-",
    ],
  },
  {
    primary_id: "D0M5WP",
    names: [
      "(C8-S)-Hydantocidin 5'-Phosphate",
      "(C8-S)-HYDANTOCIDIN 5'-PHOSPHATE",
      "1qf5",
      "AC1NRD1T",
      "DB04460",
      "5-{(5S,7R,8S,9R)-8,9-Dihydroxy-2,4-Dioxo-7-[(Phosphonooxy)Methyl]-6-Oxa-1,3-Diazaspiro[4.4]non-3-Yl}-N-Hydroxy-N-(Hydroxymethyl)-L-Norvaline",
      "(2S)-5-[(1R,2S,3R,5S)-1,2-Dihydroxy-6,8-Dioxo-3-(Phosphonooxymethyl)-4-Oxa-7,9-Diazaspiro[4.4]nonan-7-Yl]-2-[hydroxy(Hydroxymethyl)Amino]pentanoic Acid",
    ],
  },
  {
    primary_id: "D0M5WS",
    names: ["JNJ-16269110"],
  },
  {
    primary_id: "D0M5XE",
    names: ["PP-9706642", "Anti-HSV2 Agent, Piramal"],
  },
  {
    primary_id: "D0M5XJ",
    names: ["DCC-3014"],
  },
  {
    primary_id: "D0M5XN",
    names: ["BGB-283"],
  },
  {
    primary_id: "D0M5XV",
    names: [
      "D-219",
      "CHEMBL468017",
      "BDBM50261279",
      "N6-(2-(4-(2,3-Dichlorophenyl)Piperazin-1-Yl)-Ethyl)-N6-Propyl-4,5,6,7-Tetrahydrobenzo[d]thiaxole-2,6-Diamine",
      "(-)-N6-(2-(4-(2,3-Dichlorophenyl)Piperazin-1-Yl)Ethyl)-N6-Propyl-4,5,6,7-Tetrahydrobenzo[d]thiazole-2,6-Diamine",
    ],
  },
  {
    primary_id: "D0M5YQ",
    names: ["A-Substituted Phenylpropionic Acid Derivative 2"],
  },
  {
    primary_id: "D0M5YS",
    names: ["Cupric Chloride", "Cupric Chloride In Plastic Container"],
  },
  {
    primary_id: "D0M5ZE",
    names: ["RS 100235"],
  },
  {
    primary_id: "D0M5ZF",
    names: ["TVX-006"],
  },
  {
    primary_id: "D0M6AR",
    names: ["Mesenchymal Stem Cell Therapy"],
  },
  {
    primary_id: "D0M6BA",
    names: [
      "AR-67",
      "Silatecan",
      "220913-32-6",
      "DB-67",
      "UNII-3YEA04NV6H",
      "3YEA04NV6H",
      "DB67",
      "AR67",
      "AR 67",
      "(20S)-7-T-Butyldimethylsilyl-10-Hydroxycamptothecin",
      "NCI60_038363",
      "C26H30N2O5Si",
      "SCHEMBL1266162",
      "CHEMBL412309",
      "GTPL8919",
      "DTXSID00176592",
      "DB 67",
      "BCP19768",
      "7555AD",
      "NSC708298",
      "ZINC170020689",
      "AKOS030627314",
      "DB12384",
      "NSC-708298",
      "Compound 14 [PMID:11052802]",
      "KB-74723",
      "Z3398",
      "Z-3160",
      "1H-Pyrano(3',4':6,7)Indolizino(1,2-B)Quinoline-3,14(4H,12H)-Dione, 11-((1,1-Dimethylethyl)Dimethylsilyl)-4-Ethyl-4,9-Dihydroxy-, (4S)-",
    ],
  },
  {
    primary_id: "D0M6BD",
    names: ["RO-5126766", "CH-5126766", "Dual Raf/MEK Protein Kinase Inhibitor (Cancer), Roche"],
  },
  {
    primary_id: "D0M6BL",
    names: ["H-Tyr-Pro-Phe-Phe-NH-(CH2)5-(C=O)-Dap(6DMN)-NH2", "CHEMBL411335"],
  },
  {
    primary_id: "D0M6BO",
    names: ["SiRNA Therapy, Hepatoma"],
  },
  {
    primary_id: "D0M6CB",
    names: ["Naphthalene-2,6-Disulfonic Acid"],
  },
  {
    primary_id: "D0M6CC",
    names: ["Sarafotoxin 6C"],
  },
  {
    primary_id: "D0M6CZ",
    names: [
      "H-2',6'-Dimethyltyrosine-Tic-Phe-Phe-OH",
      "CHEMBL555206",
      "BDBM50299557",
      "H-2'',6''-Dimethyltyrosine-Tic-Phe-Phe-OH",
      "(S)-2-((S)-2-((S)-2-((S)-2-Amino-3-(4-Hydroxy-2,6-Dimethylphenyl)Propanoyl)-1,2,3,4-Tetrahydroisoquinoline-3-Carboxamido)-3-Phenylpropanamido)-3-Phenylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0M6DO",
    names: [
      "Aminoglutethimide",
      "Aminoglutethimidum",
      "Aminoglutetimida",
      "Cytadren",
      "Elipten",
      "Orimeten",
      "Ciba Vision Brand Of Aminoglutethimide",
      "Novartis Brand Of Aminoglutethimide",
      "A 9657",
      "Ba 16038",
      "AG-1",
      "Aminoglutethimide (AG)",
      "Aminoglutethimide [INN:BAN]",
      "Aminoglutethimidum [INN-Latin]",
      "Aminoglutetimida [INN-Spanish]",
      "Ba-16038",
      "C 16038-BA",
      "Cytadren (TN)",
      "Dl-Aminoglutethimide",
      "P-Aminoglutethimide",
      "Aminoglutethimide (USP/INN)",
      "Glutethimide, Para-Amino",
      "Alpha-(P-Aminophenyl)-Alpha-Ethylglutarimide",
      "(+-)-3-(P-Aminophenyl)-3-Ethyl-2,6-Piperidinedione",
      "(+/-)-P-AMINOGLUTETHIMIDE",
      "(Inverted Question Mark)-P-Aminoglutethimide",
      "2-(P-Aminophenyl)-2-Ethylglutarimide",
      "3-(4-Aminophenyl)-3-Ethyl-2,6-Piperidindion",
      "3-(4-Aminophenyl)-3-Ethyl-2,6-Piperidinedione",
      "3-(4-Aminophenyl)-3-Ethylpiperidine-2,6-Dione",
      "3-(P-Aminophenyl)-3-Ethylpiperidine-2,6-Dione",
      "3-Ethyl-3-(P-Aminophenyl)-2,6-Dioxopiperidine",
    ],
  },
  {
    primary_id: "D0M6DV",
    names: ["MOLEPHANTININ"],
  },
  {
    primary_id: "D0M6EG",
    names: [
      "Bisplatinum Complexes",
      "BBR-3610",
      "BBR-3611",
      "Bisplatinum Complexes (Cancer)",
      "CT-3610",
      "CT-47463",
      "CT-47518",
      "CT-47609",
      "CT-47613",
      "Bisplatinum Complexes (Cancer), Cell Therapeutics",
      "Bisplatinum Complexes (Cancer), Novuspharma",
      "Bis-Platinates (Cancer), Cell Therapeutics",
      "(Bis-Butyrate) Platinum",
      "(Bis-Capronate) Platinum",
    ],
  },
  {
    primary_id: "D0M6ER",
    names: ["SRP-4053"],
  },
  {
    primary_id: "D0M6FP",
    names: [
      "Beta-Adrenoceptor Ligands",
      "Beta-Adrenoceptor Ligands (Urinary Incontinence)",
      "Beta-Adrenoceptor Ligands (Urinary Incontinence), 4SC",
    ],
  },
  {
    primary_id: "D0M6GC",
    names: ["TRU-015", "CytoxB20G", "XXX_CytoxB20G", "CD20 Antagonist, Trubion", "PF-5212374"],
  },
  {
    primary_id: "D0M6GP",
    names: ["IMC-HIV"],
  },
  {
    primary_id: "D0M6GY",
    names: ["NSC-745799"],
  },
  {
    primary_id: "D0M6HE",
    names: ["Simplirix"],
  },
  {
    primary_id: "D0M6IG",
    names: ["Prostaganin"],
  },
  {
    primary_id: "D0M6IJ",
    names: ["Naphthalen-2-Yl(10H-Phenothiazin-10-Yl)Methanone", "CHEMBL240900"],
  },
  {
    primary_id: "D0M6JD",
    names: ["PMID26004420-Compound-WO2014085210C"],
  },
  {
    primary_id: "D0M6JP",
    names: ["S-14080"],
  },
  {
    primary_id: "D0M6KC",
    names: ["Abyssinin I", "CHEMBL389736"],
  },
  {
    primary_id: "D0M6KL",
    names: ["(-)-7-N-Methyldibromophakellin", "CHEMBL505073"],
  },
  {
    primary_id: "D0M6LN",
    names: ["L-Ornithine L-Aspartate"],
  },
  {
    primary_id: "D0M6LX",
    names: ["N-(Naphthylamino)-Benzoate Derivative 1"],
  },
  {
    primary_id: "D0M6MM",
    names: ["PMID27109571-Compound-3"],
  },
  {
    primary_id: "D0M6MP",
    names: ["PMID27744724-Compound-28"],
  },
  {
    primary_id: "D0M6NQ",
    names: ["C[RGDf-(S)-N-Me-Alpha-TfmF]", "CHEMBL413574"],
  },
  {
    primary_id: "D0M6OL",
    names: [
      "1-Deoxynojirimycin-1-Sulfonic Acid",
      "Nojirimycin Bisulfite Microbial",
      "SCHEMBL8978678",
      "GTPL4744",
      "ZINC5830003",
      "Nojirimycin Bisulfite Microbial, Solid",
      "5-Amino-5-Deoxyglucopyranose Bisulfite",
      "81703-56-2",
      "1-Sulfo-1-Deoxy-5-O-Aza-Beta-D-Glucopyranose",
      "(2S,3R,4S,5R,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Piperidine-2-Sulfonic Acid",
    ],
  },
  {
    primary_id: "D0M6OV",
    names: ["KOS1326", "4'' Deoxy EM-A Enolether"],
  },
  {
    primary_id: "D0M6OW",
    names: ["ANAVEX 1007"],
  },
  {
    primary_id: "D0M6PA",
    names: ["BCT-197", "BCT-197-A2201"],
  },
  {
    primary_id: "D0M6PB",
    names: ["Pyrrole Derivative 1"],
  },
  {
    primary_id: "D0M6PI",
    names: ["PMID29338548-Compound-44"],
  },
  {
    primary_id: "D0M6RM",
    names: [
      "Phenylarsonic Acid",
      "BENZENEARSONIC ACID",
      "1998/5/5",
      "Monophenylarsonic Acid",
      "Arsonic Acid, Phenyl-",
      "UNII-57F9KU116M",
      "Kyselina Benzenarsonova [Czech]",
      "HSDB 6381",
      "EINECS 202-631-9",
      "NSC 15566",
      "Arsonic Acid, As-Phenyl-",
      "BRN 2935741",
      "AI3-16050",
      "CHEMBL364571",
      "CHEBI:29851",
      "57F9KU116M",
      "Kyselina Benzenarsonova",
      "C6H7AsO3",
      "Benzenarsonic Acid",
      "Benzenearsonie Acid",
      "ACMC-20ajwq",
      "PhAsO3H2",
      "PhAsO(OH)2",
      "AC1Q1HDM",
      "AC1L1OLG",
      "KSC489M9F",
      "4-16-00-01183 (Beilstein Handbook Reference)",
      "SCHEMBL707127",
      "WLN: Q-AS-QO &amp",
      "DTXSID6059158",
      "CTK3I9692",
      "BDBM26997",
    ],
  },
  {
    primary_id: "D0M6RQ",
    names: ["Cyclo(-D-Tyr-L-Ala-L-Arg-L-Nal-Gly-)", "CHEMBL374108"],
  },
  {
    primary_id: "D0M6SD",
    names: ["Parenteral Amino Acids", "Amino Acid Solutions"],
  },
  {
    primary_id: "D0M6TA",
    names: ["LK-157", "Beta-Lactamase Inhibitors, Lek Pharmaceutical"],
  },
  {
    primary_id: "D0M6TB",
    names: ["NF157"],
  },
  {
    primary_id: "D0M6TX",
    names: ["T-109"],
  },
  {
    primary_id: "D0M6UK",
    names: ["PMID25037917C58"],
  },
  {
    primary_id: "D0M6UM",
    names: ["BW-A137C", "106328-28-3"],
  },
  {
    primary_id: "D0M6UZ",
    names: ["NEOLIGNAN 9-NOR-7,8-DEHYDRO-ISOLICARIN B"],
  },
  {
    primary_id: "D0M6VK",
    names: ["Methylscopolamine"],
  },
  {
    primary_id: "D0M6VZ",
    names: ["(-)-Englerin A"],
  },
  {
    primary_id: "D0M6WD",
    names: ["Peptide Analog 70"],
  },
  {
    primary_id: "D0M6WJ",
    names: [
      "Benzo[c][1,5]naphthyridin-6(5H)-One",
      "CHEMBL320031",
      "Benzo[c]-1,5-Naphthyridin-6(5H)-One",
      "SCHEMBL12750384",
      "BDBM50130585",
      "5H-Benzo[c][1,5]naphthyridin-6-One",
      "Benzo[c][1,5]naphthyridine-6(5H)-One",
    ],
  },
  {
    primary_id: "D0M6WW",
    names: ["PMID30107136-Compound-Example11"],
  },
  {
    primary_id: "D0M6XE",
    names: ["ZP-003"],
  },
  {
    primary_id: "D0M6XK",
    names: ["ANG1005"],
  },
  {
    primary_id: "D0M6XY",
    names: ["SYM-006"],
  },
  {
    primary_id: "D0M6YE",
    names: [
      "SKF-106760",
      "126053-71-2",
      "F 106760",
      "Skf 106760",
      "F-106760",
      "Ac-Cyclo-S,S-(Cys-(N(Alpha)-Me)Arg-Gly-Asp-Pen)-NH2",
      "AC1Q5IR2",
      "AC1L4UF1",
      "[(4r,7s,13s,16r)-16-(Acetylamino)-4-Carbamoyl-13-{3-[(Diaminomethylidene)Amino]propyl}-3,3,14-Trimethyl-6,9,12,15-Tetraoxo-1,2-Dithia-5,8,11,14-Tetraazacycloheptadecan-7-Yl]acetic Acid",
      "CTK4B4925",
      "L-Valinamide,N-Acetyl-L-Cysteinyl-N2-Methyl-L-Arginylglycyl-L-A-Aspartyl-3-Mercapto-, Cyclic (1&amp",
      "5)-Disulfide (9CI)",
    ],
  },
  {
    primary_id: "D0M6YW",
    names: ["GYKI-12743"],
  },
  {
    primary_id: "D0M6YZ",
    names: ["LTK-14", "14-Methoxy IG (Isogarcinol)", "LTK14"],
  },
  {
    primary_id: "D0M6ZA",
    names: [
      "Fsn-0306",
      "Cysteine Protease Inhibitor (Myeloma), Fusion Therapeutics",
      "Protease Inhibitor (Cancer), Fusion Antibodies",
    ],
  },
  {
    primary_id: "D0M6ZF",
    names: ["Boc-Asp-Tyr(So3-)-Nle-Gly-Trp-Asp-Phe-NH2", "CHEMBL1159915"],
  },
  {
    primary_id: "D0M6ZQ",
    names: ["ISIS 116362"],
  },
  {
    primary_id: "D0M7AT",
    names: [
      "L-Tryptophan-L-Arginine",
      "CHEMBL477417",
      "CHEBI:74866",
      "88831-09-8",
      "Tryptophyl-Arginine",
      "L-Tryptophyl-L-Arginine",
      "L-Arginine, L-Tryptophyl-",
      "Tryptophanyl-Arginine",
      "L-Trp-L-Arg",
      "SCHEMBL4947728",
      "CTK3A5775",
      "WR",
      "ZINC2561117",
      "BDBM50266680",
      "AKOS030606594",
      "H-Trp-Arg-OH  Inverted Exclamation Mark Currency 2 HCl",
    ],
  },
  {
    primary_id: "D0M7AU",
    names: ["N-Methyl-Pip-F-HomoF-Vinyl Sulfonyl Phenyl (N-Methyl-Pip-F-HF-VS Phi)", "Donepezil Fumarate"],
  },
  {
    primary_id: "D0M7AX",
    names: [
      "3-Isopropyl-1-Methyl-3,7-Dihydro-Purine-2,6-Dione",
      "3-Isopropyl-1-Methylxanthine",
      "CHEMBL26119",
      "102284-72-0",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1-Methyl-3-(1-Methylethyl)-",
      "ACMC-20m5ap",
      "1-Methyl-3-Isopropylxanthine",
      "3-Isopropyl-1-Methyl Xanthine",
      "CTK0D9143",
      "DTXSID50436895",
      "BDBM50025581",
    ],
  },
  {
    primary_id: "D0M7BL",
    names: ["BAY 1067197"],
  },
  {
    primary_id: "D0M7BR",
    names: ["FORMONONETIN"],
  },
  {
    primary_id: "D0M7BT",
    names: [
      "Doxapram",
      "Docatone",
      "Dopram",
      "Doxapramum",
      "Doxapram HCL",
      "Doxapram Hydrochloride",
      "Docatone (TN)",
      "Dopram (TN)",
      "Doxapram (INN)",
      "Doxapram [USP:JAN]",
      "Doxapramum [INN-Latin]",
      "(+-)-Doxapram",
      "1-Ethyl-4-(2-Morpholinoethyl)-3,3-Diphenyl-2-Pyrrolidinone",
      "1-Ethyl-4-(2-Morpholin-4-Ylethyl)-3,3-Diphenylpyrrolidin-2-One",
      "1-Ethyl-4-[2-(Morpholin-4-Yl)Ethyl]-3,3-Diphenylpyrrolidin-2-One",
      "2-Pyrrolidinone, 1-Ethyl-4-(2-(4-Morpholinyl)Ethyl)-3,3-Diphenyl-(9CI)",
    ],
  },
  {
    primary_id: "D0M7BW",
    names: ["GR-89696"],
  },
  {
    primary_id: "D0M7CB",
    names: ["MK-0873"],
  },
  {
    primary_id: "D0M7DC",
    names: [
      "1H-Pyrazole-3-Carboxylic Acid",
      "1621-91-6",
      "Pyrazole-3-Carboxylic Acid",
      "1H-Pyrazole-5-Carboxylic Acid",
      "2H-PYRAZOLE-3-CARBOXYLIC ACID",
      "5-Pyrazolecarboxylic Acid",
      "3-Pyrazolecarboxylic Acid",
      "797027-83-9",
      "3-Carboxy-1h-Pyrazole",
      "Pyrazolic Acid",
      "CHEMBL128679",
      "KOPFEFZSAMLEHK-UHFFFAOYSA-N",
      "Pyrazolecarboxylic",
      "3-Carboxypyrazole",
      "2H-PYRAZOLE-3-CARBOXYLICACID",
      "Zlchem 656",
      "Pyrazolecarboxylic Acid",
      "Pyrazole-5-Carboxylic",
      "PubChem12935",
      "AC1Q5UBI",
      "AC1LCCJ7",
      "3-Pyrazole Carboxylic Acid",
      "Pyrazole 3-Carboxylic Acid",
      "ACMC-209z0p",
      "Pyrazole-5-Carboxylic A",
    ],
  },
  {
    primary_id: "D0M7DE",
    names: [
      "N-(1-Benzofuran-3-Ylmethyl)Sulfamide",
      "CHEMBL1077117",
      "Sulfamide, N-(3-Benzofuranylmethyl)-",
      "SCHEMBL4279478",
      "XOBOZYKTCAJRGO-UHFFFAOYSA-N",
      "N-(3-Benzofuranylmethyl)-Sulfamide",
      "BDBM50310434",
    ],
  },
  {
    primary_id: "D0M7DG",
    names: ["24-Thiacycloartanol", "CHEMBL238349"],
  },
  {
    primary_id: "D0M7DS",
    names: ["Valomaciclovir Stearate"],
  },
  {
    primary_id: "D0M7DU",
    names: ["Radiolabeled Onartuzumab"],
  },
  {
    primary_id: "D0M7DW",
    names: ["Carbapenem Derivative", "Carbapenem Derivative (Bacterial Infection)"],
  },
  {
    primary_id: "D0M7EI",
    names: ["Acetic Acid 2-Pentylsulfanyl-Phenyl Ester", "CHEMBL144806", "SCHEMBL13659627", "BDBM50068195"],
  },
  {
    primary_id: "D0M7EP",
    names: [
      "CD79-Targeted Immunotoxins",
      "Anti-CD79b-MC-MMAF",
      "Anti-CD79b-MCC-DM1",
      "Anti-CD79b-Vc-MMAE",
      "Anti-CD79b-Vc-Vedotin",
      "CD79-Targeted Immunotoxins (Non-Hodgkin's Lymphoma)",
      "CD79-Targeted Immunotoxins (Non-Hodgkin's Lymphoma), Genentech",
      "Anti-CD79 Antibody-Drug Conjugates (Non-Hodgkin's Lymphoma), Genentech",
    ],
  },
  {
    primary_id: "D0M7FS",
    names: [
      "3-[5-Fluoro-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 5b",
      "BDBM8587",
      "CHEMBL383806",
      "AC1O704F",
      "SCHEMBL4220962",
      "ZINC13610771",
      "3-[(Z)-(5-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0M7FX",
    names: ["KD-247"],
  },
  {
    primary_id: "D0M7GB",
    names: ["(-)-Discorhabdin Z"],
  },
  {
    primary_id: "D0M7GG",
    names: ["PMID29671355-Compound-50"],
  },
  {
    primary_id: "D0M7GM",
    names: ["CSP-1103"],
  },
  {
    primary_id: "D0M7HH",
    names: ["[3H]PSB-0413", "[3H]AR-C67085", "[3H]AR-C67085MX"],
  },
  {
    primary_id: "D0M7HO",
    names: ["SQ-33800"],
  },
  {
    primary_id: "D0M7HQ",
    names: ["4-Isopropyl-N-(Naphthalen-1-Yl)Thiazol-2-Amine", "CHEMBL207589", "SCHEMBL14433285"],
  },
  {
    primary_id: "D0M7HZ",
    names: ["ISIS-SMNRx"],
  },
  {
    primary_id: "D0M7IA",
    names: ["Sanggenon C"],
  },
  {
    primary_id: "D0M7IG",
    names: ["RO-4920506"],
  },
  {
    primary_id: "D0M7IH",
    names: [
      "Abobotulinum Toxin A",
      "AbobotulinumtoxinA",
      "Azzalure",
      "Dysport",
      "Reloxin",
      "Abobotulinum Toxin A For Injection",
      "CNT-52120",
    ],
  },
  {
    primary_id: "D0M7IJ",
    names: [
      "DM-PIT-1",
      "DM-PIT-1-M",
      "DM-PIT-1 (Micelle Formulation, Cancer)",
      "DM-PIT-1 (Micelle Formulation, Cancer), Northeastern University/Tufts University",
    ],
  },
  {
    primary_id: "D0M7JT",
    names: ["Dabigatran"],
  },
  {
    primary_id: "D0M7KD",
    names: ["Cyclobutylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL451441"],
  },
  {
    primary_id: "D0M7KF",
    names: ["1-(7-Methyl-9H-Carbazol-3-Yl)Ethanone", "CHEMBL1173069"],
  },
  {
    primary_id: "D0M7KS",
    names: ["SB-207043"],
  },
  {
    primary_id: "D0M7LA",
    names: ["PMID15603962Crac-16"],
  },
  {
    primary_id: "D0M7LC",
    names: ["4-(6-Hydroxynaphthalen-2-Yl)Benzene-1,3-Diol"],
  },
  {
    primary_id: "D0M7LQ",
    names: [
      "CBD Cannabis Derivative",
      "AC1L5AAH",
      "(6,6-Dimethyl-3-Nonan-2-Yl-7,8,9,10-Tetrahydrobenzo[c]chromen-1-Yl) 5-(Diethylamino)Pentanoate",
    ],
  },
  {
    primary_id: "D0M7LV",
    names: ["LYSERGIC ACID DIETHYLAMIDE"],
  },
  {
    primary_id: "D0M7LZ",
    names: ["C[Glu23-Lys27][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0M7MB",
    names: ["ERC-124"],
  },
  {
    primary_id: "D0M7MH",
    names: ["NAChR Alpha 7 APLs"],
  },
  {
    primary_id: "D0M7MR",
    names: ["Multivalent Carbohydrate-Based Vaccine"],
  },
  {
    primary_id: "D0M7MW",
    names: ["GYKI-53655"],
  },
  {
    primary_id: "D0M7NA",
    names: ["A-993610"],
  },
  {
    primary_id: "D0M7NS",
    names: ["ISIS-APOCIIIRx"],
  },
  {
    primary_id: "D0M7OE",
    names: ["ISIS 25127"],
  },
  {
    primary_id: "D0M7OP",
    names: [
      "LY-266111",
      "CHEMBL29166",
      "SCHEMBL7796296",
      "KTBSRSVHEOTHLD-UKRRQHHQSA-N",
      "BDBM50044879",
      "(4aR,10bR)-8-Chloro-4,10b-Dimethyl-1,4,4a,5,6,10b-Hexahydro-2H-Benzo[f]quinolin-3-One",
      "8-Chloro-4,10b-Dimethyl-1,4,4a,5,6,10b-Hexahydro-2H-Benzo[f]quinolin-3-One",
      "(R)(+)-Trans-4-Methyl-8-Chloro-10b-Methyl-1,2,3,4,4a,5,6,10b-Octahydrobenzo[f]quinolin-3-One",
    ],
  },
  {
    primary_id: "D0M7OU",
    names: [
      "CP-461",
      "OSI 461",
      "UNII-68OJX9I7DT",
      "CP 461",
      "68OJX9I7DT",
      "1H-Indene-3-Acetamide, 5-Fluoro-2-Methyl-N-(Phenylmethyl)-1-(4-Pyridinylmethylene)-, Monohydrochloride, (1Z)-",
      "227619-96-7",
      "N-Benzyl-2-[(3Z)-6-Fluoro-2-Methyl-3-(Pyridin-4-Ylmethylidene)Inden-1-Yl]acetamide Hydrochloride",
      "AC1O6379",
      "279238-68-5",
    ],
  },
  {
    primary_id: "D0M7PS",
    names: [
      "E-4177",
      "135070-05-2",
      "CHEMBL71162",
      "2-[4-[(8-Cyclopropyl-5-Methyl-2,7,9-Triazabicyclo[4.3.0]nona-1,3,5,7-Tetraen-9-Yl)Methyl]phenyl]benzoic Acid",
      "E4177",
      "VWWMGPCUZVOLLK-UHFFFAOYSA-N",
      "ACMC-20mvn4",
      "AC1L2ZXE",
      "E 4177",
      "SCHEMBL685515",
      "CTK0I0030",
      "DTXSID50159239",
      "BDBM50049212",
      "3-((2'-Carboxybiphenyl-4-Yl)Methyl)-2-Cyclopropyl-7-Methyl-3H-Imidazo(4,5-B)Pyridine",
      "L004970",
      "4''-(2-Cyclopropyl-7-Methyl-Imidazo[4,5-B]pyridin-3-Ylmethyl)-Biphenyl-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0M7PU",
    names: ["PM-00104"],
  },
  {
    primary_id: "D0M7QA",
    names: [
      "N-{2-[4-(AMINOSULFONYL)PHENYL]ETHYL}ACETAMIDE",
      "41472-49-5",
      "N-(4-Sulfamoylphenethyl)Acetamide",
      "N-[2-(4-Sulfamoylphenyl)Ethyl]acetamide",
      "N-{2-[4-(Aminosulfonyl)Phenyl]ethyl}acetamide",
      "N-(2-(4-(Aminosulphonyl)Phenyl)Ethyl)Acetamide",
      "N-Acetyl-4-(2-Aminoethyl)Benzenesulfonamide",
      "2nns",
      "2nmx",
      "EINECS 255-386-5",
      "4-(2-Acetylaminoethyl)Benzenesulfonamide",
      "AC1LF2Y7",
      "Oprea1_087799",
      "CBDivE_010614",
      "SCHEMBL9016571",
      "DTXSID10194368",
      "MolPort-000-563-758",
      "IIMGUEXQORZTID-UHFFFAOYSA-N",
      "ZINC188952",
      "HMS3604G22",
      "HMS1786I07",
      "DK-42",
      "P-(2-Acetamidoethyl)Benzenesulfonamide",
      "AKOS000987732",
    ],
  },
  {
    primary_id: "D0M7QR",
    names: ["5-Ethyl-2-(2-(Pyrrolidin-1-Yl)Ethyl)Pyridine", "CHEMBL271300", "SCHEMBL13990825", "ZINC29131629"],
  },
  {
    primary_id: "D0M7RM",
    names: ["NSC-640558"],
  },
  {
    primary_id: "D0M7SD",
    names: ["MF-8623"],
  },
  {
    primary_id: "D0M7SG",
    names: ["IDP-118"],
  },
  {
    primary_id: "D0M7SQ",
    names: ["1,1,1-Trifluoro-9-Phenyl-Nonan-2-One", "CHEMBL91417", "SCHEMBL5267943", "BDBM50073987"],
  },
  {
    primary_id: "D0M7SY",
    names: ["SB269652"],
  },
  {
    primary_id: "D0M7SZ",
    names: ["CAT-4000"],
  },
  {
    primary_id: "D0M7TC",
    names: ["Agatoxin 489", "AG 489"],
  },
  {
    primary_id: "D0M7TO",
    names: ["Tasosartan"],
  },
  {
    primary_id: "D0M7TP",
    names: ["Piv-RYYRIK-NH2", "CHEMBL270231"],
  },
  {
    primary_id: "D0M7TT",
    names: ["IP-1200"],
  },
  {
    primary_id: "D0M7TW",
    names: ["PF-3900422"],
  },
  {
    primary_id: "D0M7VB",
    names: ["AV-1013"],
  },
  {
    primary_id: "D0M7VE",
    names: ["RO-4905417"],
  },
  {
    primary_id: "D0M7VN",
    names: ["4-(N-Methyl-Hydrazino)-Benzenesulfonamide", "CHEMBL183913", "SCHEMBL13242579"],
  },
  {
    primary_id: "D0M7VW",
    names: ["GR-65630"],
  },
  {
    primary_id: "D0M7VY",
    names: ["NSC-745796"],
  },
  {
    primary_id: "D0M7WE",
    names: [
      "2,3,4,5-Tetrahydro-1H-Pyrido[4,3-B]indole",
      "6208-60-2",
      "2,3,4,5-Tetrahydro-1h-Pyrido[4,3,b]indole",
      "1H,2H,3H,4H,5H-Pyrido[4,3-B]indole",
      "1H-Pyrido[4,3-B]indole, 2,3,4,5-Tetrahydro-",
      "MLS001198355",
      "1,2,3,4-Tetrahydropyridino[4,3-B]indole",
      "SMR000559186",
      "SR-01000530765",
      "PubChem2433",
      "BAS 00107381",
      "AC1Q1GJA",
      "AC1LF5C1",
      "Oprea1_367545",
      "SCHEMBL169954",
      "CHEMBL269074",
      "AC1Q1H88",
      "CTK5B4311",
      "BDBM73071",
      "DTXSID50352329",
      "Cid_11572011",
      "MolPort-000-928-989",
      "RPROHCOBMVQVIV-UHFFFAOYSA-N",
      "ALBB-007677",
      "KS-00000E6L",
      "STK500458",
      "BBL010407",
      "ANW-57714",
      "ZINC19228212",
    ],
  },
  {
    primary_id: "D0M7WS",
    names: ["Tricyclic Compound 9"],
  },
  {
    primary_id: "D0M7XB",
    names: ["Beta-Cyclodextrin Conjugate Derivative 1"],
  },
  {
    primary_id: "D0M7XL",
    names: ["PMID28092474-Compound-32e"],
  },
  {
    primary_id: "D0M7XS",
    names: ["Antithymocyte Globulin", "Atgam (TN)", "Thymoglobulin (TN)"],
  },
  {
    primary_id: "D0M7YB",
    names: ["ISOLONGIFOLOL"],
  },
  {
    primary_id: "D0M7YU",
    names: ["Folate Binding Protein Vaccine"],
  },
  {
    primary_id: "D0M7ZC",
    names: ["ISIS 134605"],
  },
  {
    primary_id: "D0M7ZZ",
    names: ["Erysubin E"],
  },
  {
    primary_id: "D0M8AA",
    names: ["PMID28394193-Compound-45"],
  },
  {
    primary_id: "D0M8AB",
    names: [
      "Glycine",
      "Aciport",
      "Aminoazijnzuur",
      "Aminoessigsaeure",
      "Amitone",
      "Corilin",
      "Glicina",
      "Glicoamin",
      "Gly",
      "Glycin",
      "Glycinum",
      "Glycocoll",
      "Glycolixir",
      "Glycosthene",
      "Glykokoll",
      "Glyzin",
      "Hgly",
      "Leimzucker",
      "Padil",
      "Polyglycine",
      "Acide Aminoacetique",
      "Acido Aminoacetico",
      "Acidum Aminoaceticum",
      "Aminoacetic Acid",
      "Aminoethanoic Acid",
      "An Alpha Amino Acid Ester",
      "Glycine [INN]",
      "Hampshire Glycine",
      "Polyglycine II",
      "Sucre De Gelatine",
      "Acide Aminoacetique [INN-French]",
      "Acido Aminoacetico [INN-Spanish]",
      "Acidum Aminoaceticum [INN-Latin]",
      "GLY (IUPAC Abbrev)",
      "GLYCINE, ACS",
      "Glicina [INN-Spanish]",
      "Glycinum [INN-Latin]",
      "Gyn-Hydralin",
      "L-Glycine",
      "S04-0135",
      "AB-131/40217813",
      "AMINOACETIC ACID 1.5% IN PLASTIC CONTAINER",
      "GLYCINE 1.5% IN PLASTIC CONTAINER",
      "Glycine (JP15/USP)",
      "Glycine Iron Sulphate (1:1)",
      "Glycine, Homopolymer (VAN)",
      "Glycine, Labeled With Carbon-14",
      "Glycine, Non-Medical",
      "Glycine-UL-14C Hydrochloride",
      "H-Gly-OH",
      "H2N-CH2-COOH",
      "L-Alpha-Amino Acids",
      "(1-13c)Glycinato",
      "(15N)Glycine",
      "2,2-Dialkylglycines",
      "2-Aminoacetic Acid",
    ],
  },
  {
    primary_id: "D0M8AI",
    names: [
      "ARRY-380",
      "937265-83-3",
      "ARRY-380 Analog",
      "ARRY-380 (Analog )",
      "UNII-H32S1659ED",
      "HER2-Inhibitor-1",
      "H32S1659ED",
      "6-[5-[[[2-(Methylsulfonyl)Ethyl]amino]methyl]-2-Furanyl]-N-[3-Methyl-4-([1,2,4]triazolo[1,5-A]pyridin-7-Yloxy)Phenyl]-4-Quinazolinamine",
      "HER2 Inhibitor 1",
      "SCHEMBL10169911",
      "C29H27N7O4S",
      "DTXSID00239555",
      "MolPort-023-293-540",
      "HMS3656B04",
      "BDBM185146",
      "EX-A1261",
      "AOB87708",
      "BCP02833",
      "ZINC73096245",
      "2227AH",
      "S2752",
      "AKOS026750520",
      "CS-0484",
      "SB16599",
      "BCP9000319",
      "NCGC00346672-01",
      "NCGC00346672-06",
    ],
  },
  {
    primary_id: "D0M8AR",
    names: [
      "1-(5-Phenyloxazol-2-Yl)Octadec-9-En-1-One",
      "CHEMBL426410",
      "2-Oleoyl-5-Phenyloxazole",
      "SCHEMBL3205790",
      "BDBM50161521",
      "AKOS030561892",
      "1-(5-Phenyl-Oxazol-2-Yl)-Nonadec-10-En-2-One",
      "(Z)-1-(5-Phenyloxazol-2-Yl)Octadec-9-En-1-One",
    ],
  },
  {
    primary_id: "D0M8AS",
    names: ["Y-29794"],
  },
  {
    primary_id: "D0M8BV",
    names: ["ISIS 19651"],
  },
  {
    primary_id: "D0M8CJ",
    names: ["RS-102895"],
  },
  {
    primary_id: "D0M8CL",
    names: ["A-0001"],
  },
  {
    primary_id: "D0M8CO",
    names: [
      "1-(7-Hydroxy-Naphthalen-1-Yl)-3-Pyridin-2-Yl-Urea",
      "Diarylurea Deriv. 14",
      "AC1NS9HN",
      "CHEMBL140624",
      "BDBM6654",
      "ZINC13471112",
      "1-(7-Hydroxy-1-Naphthyl)-3-(2-Pyridyl)Urea",
      "N-(7-Hydroxy-1-Naphthyl)-N -Pyridin-2-Ylurea",
      "1-(7-Hydroxynaphthalen-1-Yl)-3-Pyridin-2-Ylurea",
    ],
  },
  {
    primary_id: "D0M8DU",
    names: [
      "(N)Methanocarba-UTP",
      "GTPL1740",
      "({[({[4-(2,4-Dioxo-1,2,3,4-Tetrahydropyrimidin-1-Yl)-2,3-Dihydroxybicyclo[3.1.0]hexan-1-Yl]methoxy}(Hydroxy)Phosphoryl)Oxy](Hydroxy)Phosphoryl}oxy)Phosphonic Acid",
    ],
  },
  {
    primary_id: "D0M8EF",
    names: ["CD38 CAR-T Cell"],
  },
  {
    primary_id: "D0M8EH",
    names: ["O-Spiroketal Glucoside", "CHEMBL565784", "SCHEMBL1317722", "BDBM50303240"],
  },
  {
    primary_id: "D0M8FD",
    names: ["SNDX-275"],
  },
  {
    primary_id: "D0M8FF",
    names: ["OSL-95II", "A-005", "CM-728", "Iminocyclitol (Antiviral), Drexel University"],
  },
  {
    primary_id: "D0M8FL",
    names: [
      "2,3,4,5-Tetrahydro-Benzo[f][1,4]oxazepine",
      "17775-01-8",
      "2,3,4,5-Tetrahydrobenzo[f][1,4]oxazepine",
      "2,3,4,5-Tetrahydro-1,4-Benzoxazepine",
      "CHEMBL118420",
      "1,4-Benzoxazepine, 2,3,4,5-Tetrahydro-",
      "2.3.4.5-Tetrahydro-Benzo[f][1,4]oxazepine",
      "1,4-Benzoxazepine, 2,3,4,5-Tetrahydro-, Hydrochloride",
      "AC1Q1IB5",
      "AC1Q1IB6",
      "SCHEMBL400964",
      "CTK4D6590",
      "DTXSID90619367",
      "HDVHFHONOKCUHQ-UHFFFAOYSA-N",
      "MolPort-001-792-633",
      "KS-00000I8M",
      "ALBB-011885",
      "CT-115",
      "BDBM50052890",
      "ZINC11802711",
      "ANW-65610",
      "STK897763",
      "AKOS005173482",
      "MCULE-4987146186",
      "CS-W007152",
      "BS-12101",
    ],
  },
  {
    primary_id: "D0M8HC",
    names: [
      "AL5927",
      "N-[(4-Methoxyphenyl)Methyl]2,5-Thiophenedesulfonamide",
      "2-N-[(4-Methoxyphenyl)Methyl]thiophene-2,5-Disulfonamide",
      "N-(4-Methoxybenzyl)Thiophene-2,5-Disulfonamide",
      "AL9",
      "1bn4",
      "AC1L1HW5",
      "SCHEMBL680127",
      "BDBM11930",
      "2,5-Thiophenedisulfonamide, N2-[(4-Methoxyphenyl)Methyl]-",
      "DB03526",
    ],
  },
  {
    primary_id: "D0M8HH",
    names: [
      "PUMAPRAZOLE",
      "BY-841",
      "Pumaprazole",
      "N-[2-(2,3-Dimethylimidazol[1,2-A]pyridin-8-Ylaminomethyl)-3-Methylphenyl]carbamic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0M8HU",
    names: ["LAVYPWT", "CHEMBL395723"],
  },
  {
    primary_id: "D0M8HV",
    names: ["AZD-4451", "AZ-13263195", "AZ-13263197"],
  },
  {
    primary_id: "D0M8IR",
    names: ["Heterocyclic Derivative 3"],
  },
  {
    primary_id: "D0M8IT",
    names: ["Lysophosphatidylserine"],
  },
  {
    primary_id: "D0M8JE",
    names: ["1-(1-Biphenyl-4-Yl-2-Phenyl-Ethyl)-1H-Imidazole", "CHEMBL526293"],
  },
  {
    primary_id: "D0M8JF",
    names: ["MOR-102"],
  },
  {
    primary_id: "D0M8JZ",
    names: ["TOXIFERINE"],
  },
  {
    primary_id: "D0M8KB",
    names: ["Amide Derivative 2"],
  },
  {
    primary_id: "D0M8KC",
    names: ["PMID25522065-Compound-48"],
  },
  {
    primary_id: "D0M8KV",
    names: ["PMID30247903-Compound-General Structure39"],
  },
  {
    primary_id: "D0M8KY",
    names: ["(S)-DRF-1042", "5(S)-(2'-Hydroxyethoxy)-20(S)-Camptothecin", "5(S)-(2'-Hydroxyethoxy)-20(S)-CPT"],
  },
  {
    primary_id: "D0M8LN",
    names: ["A-690344", "CHEMBL201115", "BDBM50177356"],
  },
  {
    primary_id: "D0M8NI",
    names: ["Aplidinplitidepsin"],
  },
  {
    primary_id: "D0M8NW",
    names: ["Kuanoniamine D"],
  },
  {
    primary_id: "D0M8OC",
    names: ["Bavisant"],
  },
  {
    primary_id: "D0M8OV",
    names: ["Pyrazole Derivative 64"],
  },
  {
    primary_id: "D0M8PD",
    names: ["Estrogen"],
  },
  {
    primary_id: "D0M8PL",
    names: ["Angiozyme"],
  },
  {
    primary_id: "D0M8PM",
    names: ["IRX-4"],
  },
  {
    primary_id: "D0M8PW",
    names: ["Levocetirizine Dihydrochloride"],
  },
  {
    primary_id: "D0M8QG",
    names: ["SAN-18"],
  },
  {
    primary_id: "D0M8RB",
    names: ["GNF-PF-254"],
  },
  {
    primary_id: "D0M8RC",
    names: [
      "Terbutaline",
      "Asthmasian",
      "Brethaire",
      "Brethine",
      "Brican",
      "Bricanyl",
      "Bricar",
      "Bricaril",
      "Bricyn",
      "Terbutalin",
      "Terbutalina",
      "Terbutalino",
      "Terbutalinum",
      "Terbutalina [DCIT]",
      "Terbutaline Sulfate",
      "KWD 2019",
      "Asthmasian (TN)",
      "Brethaire (TN)",
      "Brethine (TN)",
      "Bricanyl (TN)",
      "KWD-2019",
      "Terbutaline (INN)",
      "Terbutaline [INN:BAN]",
      "Terbutalino [INN-Spanish]",
      "Terbutalinum [INN-Latin]",
      "(+-)-5-(2-((1,1-Dimethylethyl)Amino)-1-Hydroxyethyl)-1,3-Benzenediol",
      "(+-)-Terbutaline",
      "1,3-Benzenediol, 5-(2-((1,1-Dimethylethyl)Amino)-1-Hydroxyethyl)-(9CI)",
      "5-(2-((1,1-DIMETHYLETHYL)AMINO)-1-HYDROXYETHYL)-1,3-BENZENEDIOL",
      "5-[2-(Tert-Butylamino)-1-Hydroxyethyl]benzene-1,3-Diol",
      "5-{2-[(1,1-Dimethylethyl)Amino]-1-Hydroxyethyl}benzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D0M8RO",
    names: [
      "4-ANDROSTENE-3-17-DIONE",
      "Androstenedione",
      "4-Androstene-3,17-Dione",
      "Androst-4-Ene-3,17-Dione",
      "1963/5/8",
      "4-Androstenedione",
      "Androtex",
      "3,17-Dioxoandrost-4-Ene",
      "Delta-4-Androstenedione",
      "SKF 2170",
      "Fecundin",
      "Delta-4-Androsten-3,17-Dione",
      "Delta-4-Androstene-3,17-Dione",
      "Androstendione",
      "4-Androsten-3,17-Dione",
      "NSC 9563",
      "Delta-(Sup4)-Androsten-3,17-Dione",
      "Delta(Sup 4)-Androstene-3,17-Dione",
      "UNII-409J2J96VR",
      "17-Ketotestosterone",
      "D4-Androstenedione",
      "CHEBI:16422",
      "Androstenedione [JAN]",
      "HSDB 7335",
      "EINECS 200-554-5",
      "MLS000028510",
      "NSC9563",
      "ANDROSTENEDIONE",
    ],
  },
  {
    primary_id: "D0M8RW",
    names: ["FluCell"],
  },
  {
    primary_id: "D0M8SQ",
    names: ["Trimethyl-(3-Nitro-Phenyl)-Ammonium Iodide"],
  },
  {
    primary_id: "D0M8SY",
    names: ["PMID16242323C18b"],
  },
  {
    primary_id: "D0M8TO",
    names: ["Stem Cell Therapeutics"],
  },
  {
    primary_id: "D0M8UO",
    names: ["Imidazo[1,2-B]pyridazine Derivative 2"],
  },
  {
    primary_id: "D0M8UY",
    names: ["6-Hydroxybenzo[d][1,3]oxathiol-2-One"],
  },
  {
    primary_id: "D0M8VE",
    names: ["Iloperidone"],
  },
  {
    primary_id: "D0M8VL",
    names: ["GW-870086-X"],
  },
  {
    primary_id: "D0M8VN",
    names: [
      "DRF 2519",
      "5-[[4-[2-(4-Oxo-2H-1,3-Benzoxazin3(4H)-Yl)Ethoxy]phenyl]methyl2,4-Thiazolidinedione",
      "SCHEMBL6953746",
      "GTPL2671",
      "CHEMBL1491825",
      "DTXSID5040754",
      "NOCAS_40754",
      "CTK8F9377",
      "API0008459",
      "NCGC00165785-01",
      "NCGC00164420-01",
      "SR-05000000444",
      "SR-05000000444-2",
    ],
  },
  {
    primary_id: "D0M8VS",
    names: ["PMID28270021-Compound-WO2015042088Example4"],
  },
  {
    primary_id: "D0M8VV",
    names: ["Acerogenin C", "SCHEMBL4112475"],
  },
  {
    primary_id: "D0M8WO",
    names: ["Aryl Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0M8WX",
    names: ["2-[(2',3',4'-TRIFLUOROBIPHENYL-2-YL)OXY]ETHANOL"],
  },
  {
    primary_id: "D0M8XX",
    names: ["ATI-1123"],
  },
  {
    primary_id: "D0M8YB",
    names: ["PERIPENTADENINE"],
  },
  {
    primary_id: "D0M8YM",
    names: ["Pyrotinib"],
  },
  {
    primary_id: "D0M8YQ",
    names: ["HIVAX-2"],
  },
  {
    primary_id: "D0M8YW",
    names: ["Aminopyrimidine Derivative 5"],
  },
  {
    primary_id: "D0M9BA",
    names: ["Carnosine", "Beta-Alanyl-L-Histidine", "L-Carnosine"],
  },
  {
    primary_id: "D0M9BW",
    names: [
      "Artemether",
      "Artemetero",
      "Artemether[inn]",
      "Artemetheri",
      "Artemetherum",
      "Artemos",
      "Artenam",
      "Artesaph",
      "Artesian",
      "Artimist",
      "Artmether",
      "Falcidol",
      "Gvither",
      "Malartem",
      "Paluther",
      "Rither",
      "Arteannuin Ether",
      "Artemether [INN]",
      "Artemisininelactol Methyl Ether",
      "Dihydroartemisinin Methyl Ether",
      "Dihydroquinghaosu Methyl Ether",
      "SM 224",
      "SM 229",
      "Alpha-Artemether",
      "Alpha-Dihydroartemisinin Methyl Ether",
      "Artemetero [INN-Spanish]",
      "Artemether (INN)",
      "Artemetherum [INN-Latin]",
      "Beta-Artemether",
      "Beta-Dihydroartemisinin Methyl Ether",
      "Methyl-Dihydroartemisinine",
      "O-Methyldihydroartemisinine",
      "SM-224",
      "Beta-Methylether Of 11-Epi-Dihydroartemisinin",
      "Artemether, (3R-(3alpha,5abeta,6beta,8abeta,9alpha,10alpha,12beta,12aR*))-Isomer",
      "(3R,5aS,6R,8aS,9R,10S,12R,12aR)-10-Methoxy-3,6,9-Trimethyldecahydro-3,12-Epoxypyrano[4,3-J][1,2]benzodioxepine",
      "(3R,5aS,6R,8aS,9R,10S,12R,12aR)-Decahydro-10-Methoxy-3,6,9-Trimethyl-3,12-Epoxy-12H-Pyrano(4,3-J)-1,2-Benzodioxepin",
      "(3alpha,5abeta,6beta,8abeta,9alpha,10beta,12beta,12aR*)-Isomer Of Artemether",
      "(3r,5as,6r,8as,9r,10r,12r,12ar)-10-Methoxy-3,6,9-Trimethyldecahydro-3,12-Epoxy[1,2]dioxepino[4,3-I]isochromene",
      "(5aS,6R,8aS,9R,10S,12R,12aR)-10-Methoxy-3,6,9-Trimethyldecahydro-3,12-Epoxy[1,2]dioxepino[4,3-I]isochromene",
      "10-Methoxy-1,5,9-Trimethyl-(1R,4S,5R,8S,9R,10S,12R,13R)-11,14,15,16-Tetraoxatetracyclo[10.3.1.04,13.08,13]hexadecane",
      "3,12-Epoxy-12H-Pyrano[4,3-J]-1,2-Benzodioxepin, Decahydro-10-Methoxy-3,6,9-Tri",
    ],
  },
  {
    primary_id: "D0M9BY",
    names: ["PMID28394193-Compound-23"],
  },
  {
    primary_id: "D0M9BZ",
    names: ["PMID1656041C11nn"],
  },
  {
    primary_id: "D0M9CV",
    names: ["TA-993"],
  },
  {
    primary_id: "D0M9DC",
    names: [
      "Oxaprozin",
      "Actirin",
      "Alvo",
      "Danoprox",
      "Daypro",
      "Dayrun",
      "Deflam",
      "Oxaprozina",
      "Oxaprozine",
      "Oxaprozinum",
      "Voir",
      "Xopane",
      "Apotex Brand Of Oxaprozin",
      "CSC Brand Of Oxaprozin",
      "Pfizer Brand Of Oxaprozin",
      "Rhoxalpharma Brand Of Oxaprozin",
      "TRB Brand Of Oxaprozin",
      "Lyl)Propenoic Acid",
      "NCI310839",
      "O 9637",
      "WY 21743",
      "Apo-Oxaprozin",
      "Daypro (TN)",
      "Duraprox (TN)",
      "Oxaprozina [INN-Spanish]",
      "Oxaprozine [INN-French]",
      "Oxaprozinum [INN-Latin]",
      "Rhoxal-Oxaprozin",
      "WY-21743",
      "WY-21,743",
      "Oxaprozin (JP15/USAN/INN)",
      "Oxaprozin [USAN:BAN:INN:JAN]",
      "Beta-(4,5-Diphenyloxazol-2-Yl)Propionic Acid",
      "3-(4, 5-Diphenyl-2-Oxazo",
      "3-(4,5-Diphenyl-1,3-Oxazol-2-Yl)Propanoic Acid",
      "3-(4,5-Diphenyl-2-Oxazolyl)Propenoic Acid",
      "4, 5-Diphenyl-2-Oxazolepropionic Acid",
      "4,5-Diphenyl-2-Oxazolepropanoic Acid",
      "4,5-Diphenyl-2-Oxazolepropionic Acid",
    ],
  },
  {
    primary_id: "D0M9DD",
    names: ["PMID24999562C8d"],
  },
  {
    primary_id: "D0M9DT",
    names: ["DIMETHYLSPINGOSINE"],
  },
  {
    primary_id: "D0M9ER",
    names: [
      "EHT-1864",
      "EHT-0101",
      "EHT-0206",
      "EHT-101",
      "EHT-206",
      "Rac1 Inhibitor (Alzheimer's Disease/Cancer),Exonhit",
    ],
  },
  {
    primary_id: "D0M9EV",
    names: ["NBTX-001"],
  },
  {
    primary_id: "D0M9FA",
    names: ["TCR-Transduced T-Cell Immunotherapy"],
  },
  {
    primary_id: "D0M9FC",
    names: ["(R)-3-(2-Naphthamido)-4-M-Tolylbutanoic Acid", "CHEMBL1091065"],
  },
  {
    primary_id: "D0M9FF",
    names: ["ReN-1820"],
  },
  {
    primary_id: "D0M9FQ",
    names: ["PF-489791"],
  },
  {
    primary_id: "D0M9FT",
    names: [
      "Omniferon",
      "Alpha Leukoferon",
      "Human Alpha Interferon, Viragen",
      "Leukocyte-Derived Interferon",
      "Alpha-IFN, Viragen",
      "Topically Enhanced Onmiferon (Genital Herpes), Viragen/Zonagen",
      "BST-GEL (Omniferon), Viragen/Biosyntech",
    ],
  },
  {
    primary_id: "D0M9FZ",
    names: ["Pyrazole Derivative 6"],
  },
  {
    primary_id: "D0M9GE",
    names: ["Rimcazole"],
  },
  {
    primary_id: "D0M9GO",
    names: ["Tifluadom"],
  },
  {
    primary_id: "D0M9HA",
    names: ["Oral Anti-Inflammatory Agents"],
  },
  {
    primary_id: "D0M9IC",
    names: [
      "CONESSINE",
      "Conessine",
      "Roquessine",
      "Neriine",
      "546-06-5",
      "Conessinum",
      "Wrightine",
      "Conessin",
      "UNII-EZ38J9BBDF",
      "NSC 119994",
      "EZ38J9BBDF",
      "Konessin",
      "CHEBI:27965",
      "GPLGAQQQNWMVMM-MYAJQUOBSA-N",
      "Conessine [INN]",
      "N,N-Dimethylcon-5-Enin-3beta-Amine",
      "ST066907",
      "Conesina [INN-Spanish]",
      "Conessinum [INN-Latin]",
      "DSSTox_CID_26000",
      "DSSTox_RID_81281",
      "DSSTox_GSID_46000",
      "3beta-(Dimethylamino)Con-5-Ene",
      "Conesina",
      "EINECS 208-897-2",
      "BRN 4702160",
      "Con-5-Enine, 3.beta.-(Dimethylamino)-",
      "((2S,5S,12S,16S,1R,9R,13R)-6,7,13-Trimethyl-7-Azapentacyclo[10.8.0.0&lt",
      "2,9&gt",
      "5, 9&gt",
    ],
  },
  {
    primary_id: "D0M9IQ",
    names: ["Sodium N-Methylphenylaminomethanesulfonate"],
  },
  {
    primary_id: "D0M9JE",
    names: ["Pyrazole And Benzimidazole Derivative 1"],
  },
  {
    primary_id: "D0M9JF",
    names: ["Petrosamine"],
  },
  {
    primary_id: "D0M9JJ",
    names: ["DOV-216419"],
  },
  {
    primary_id: "D0M9JK",
    names: ["ISIS 113903"],
  },
  {
    primary_id: "D0M9JM",
    names: ["PMID25522065-Compound-17"],
  },
  {
    primary_id: "D0M9JU",
    names: [
      "6-(Naphthalen-2-Ylamino)-1H-Pyrimidine-2,4-Dione",
      "CHEMBL124937",
      "21333-09-5",
      "NSC212338",
      "AC1L7G1P",
      "Oprea1_822744",
      "Oprea1_362386",
      "DTXSID70309577",
      "ZINC322361",
      "BDBM50028361",
      "NSC-212338",
    ],
  },
  {
    primary_id: "D0M9KZ",
    names: ["Pyridine Derivative 4"],
  },
  {
    primary_id: "D0M9LB",
    names: ["NS-2389"],
  },
  {
    primary_id: "D0M9LJ",
    names: ["N-(4-Aminobiphenyl-3-Yl)Nicotinamide", "CHEMBL255805", "BDBM50232035"],
  },
  {
    primary_id: "D0M9LL",
    names: ["(E)-12-Nitrooctadec-12-Enoic Acid", "CHEMBL549351", "BDBM50295043", "(E)-12-Nitro-12-Octadecenoic Acid"],
  },
  {
    primary_id: "D0M9LM",
    names: ["Aryl Piperazine Derivative 14"],
  },
  {
    primary_id: "D0M9NJ",
    names: ["SOM-0005"],
  },
  {
    primary_id: "D0M9OF",
    names: ["3-(1H-Indol-2-Yl)-1H-Quinolin-2-One", "3-(1H-Indol-2-Yl)Quinolin-2(1H)-One"],
  },
  {
    primary_id: "D0M9OO",
    names: ["Galinpepimut-S"],
  },
  {
    primary_id: "D0M9OW",
    names: ["Propionyl-CoA"],
  },
  {
    primary_id: "D0M9PB",
    names: ["Imidazo[1,2-B]pyridazine Acetamide Derivative 1"],
  },
  {
    primary_id: "D0M9PD",
    names: ["PP-200"],
  },
  {
    primary_id: "D0M9PI",
    names: ["SLP-1003"],
  },
  {
    primary_id: "D0M9QJ",
    names: ["PHT-427"],
  },
  {
    primary_id: "D0M9QK",
    names: ["Beta-Acetyldigoxin"],
  },
  {
    primary_id: "D0M9QM",
    names: [
      "CGP-191",
      "CHEMBL122243",
      "1,3-Diphenyl-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine",
      "Pyrazolopyrimidine Deriv. 1",
      "AC1NS35Q",
      "SCHEMBL2360645",
      "BDBM3003",
      "AKOS028111796",
      "129852-40-0",
      "1,3-Diphenylpyrazolo[3,4-D]pyrimidin-4-Amine",
      "1H-Pyrazolo[3,4-D]pyrimidin-4-Amine, 1,3-Diphenyl-",
      "1,3-Diphenyl-1H-Pyrazolo[3,4-D]pyrimidin-4-Amine (11)",
    ],
  },
  {
    primary_id: "D0M9QZ",
    names: ["ABT-869"],
  },
  {
    primary_id: "D0M9RE",
    names: [
      "ARI-809",
      "Aldose Reductase Inhibitors (Diabetes)",
      "CP-744809",
      "Aldose Reductase Inhibitors (Diabetes), Pfizer",
    ],
  },
  {
    primary_id: "D0M9RF",
    names: [
      "3570-0208",
      "[6-Ethyl-2-Methyl-3-(1-Methylbenzimidazol-2-Yl)-4-Oxochromen-7-Yl] Acetate",
      "UNM000003536701",
      "AC1MJIQD",
      "BAS 00135206",
      "Oprea1_681432",
      "Oprea1_151337",
      "MLS001242385",
      "GTPL5828",
      "CHEMBL1329101",
      "Cid_3092570",
      "CHEBI:92290",
      "BDBM40404",
      "ML048",
      "HMS2231A24",
      "HMS3372G14",
      "ZINC4247033",
      "STK922580",
      "AKOS000525202",
      "MCULE-3542011334",
      "SMR000686159",
      "ST50330694",
      "EU-0040874",
      "SR-01000456743",
      "SR-01000456743-1",
      "3570-0208 [PMID:19807662]",
      "2-Methyl-3-(1-Methyl-2-Benzimidazolyl)-6-Ethyl-7-Acetoxychromone",
    ],
  },
  {
    primary_id: "D0M9RI",
    names: [
      "SUVN-D4010",
      "DWTFBJGTRBMHPG-UHFFFAOYSA-N",
      "UNII-GNQ25KYD72",
      "GNQ25KYD72",
      "1428862-32-1",
      "SCHEMBL14810657",
      "1-Isopropyl-3-{5-[1-(3-Methoxy Propyl)Piperidin-4-Yl]-[1,3,4]oxadiazol-2-Yl}-1H-Indazole",
      "1-Isopropyl-3-{5-[1-(3-Methoxy Propyl) Piperidin-4-Yl]-[1,3,4]oxadiazol-2-Yl}-1H-Indazole",
    ],
  },
  {
    primary_id: "D0M9RL",
    names: [
      "2-(3'-Allyl-Biphenyl-4-Yl)-Propionic Acid",
      "CHEMBL325787",
      "BDBM50074936",
      "2-(3''-Allyl-Biphenyl-4-Yl)-Propionic Acid",
    ],
  },
  {
    primary_id: "D0M9RM",
    names: [
      "[3H]CGS8216",
      "2-Phenylpyrazolo(4,3-C)Quinolin-3(5H)-One",
      "Cgs 8216",
      "CGS-8216",
      "77779-60-3",
      "BRN 4257286",
      "2-Phenyl-1,2-Dihydro-3h-Pyrazolo[4,3-C]quinolin-3-One",
      "2,5-Dihydro-2-Phenyl-3H-Pyrazolo(4,3-C)Quinolin-3-One",
      "3H-Pyrazolo(4,3-C)Quinolin-3-One, 2,5-Dihydro-2-Phenyl-",
      "2-Phenyl-1H-Pyrazolo[4,5-C]quinolin-3-One",
      "2,5-DIHYDRO-2-PHENYL-3H-PYRAZOLO[4,3-C]QUINOLIN-3-ONE",
      "AC1Q6FQG",
      "AC1Q6NJI",
      "AC1L2XPQ",
      "2-Phenyl-1H-Pyrazolo[4,3-C]quinolin-3-One",
      "2-Phenyl-2H-Pyrazolo[4,3-C]quinolin-3(5H)-One",
      "GTPL4366",
      "GTPL4156",
      "SCHEMBL1466280",
    ],
  },
  {
    primary_id: "D0M9RQ",
    names: ["MK-0249"],
  },
  {
    primary_id: "D0M9SD",
    names: ["VA-119930"],
  },
  {
    primary_id: "D0M9SL",
    names: ["DSP-7238"],
  },
  {
    primary_id: "D0M9TD",
    names: ["MiR-34a Mimics"],
  },
  {
    primary_id: "D0M9TG",
    names: ["Phthalazine Derivative 3"],
  },
  {
    primary_id: "D0M9TM",
    names: ["3-(3,5-Dimethyl-Phenyl)-1-Propyl-Piperidine", "CHEMBL144114", "BDBM50068419"],
  },
  {
    primary_id: "D0M9TO",
    names: ["JCAR023"],
  },
  {
    primary_id: "D0M9TW",
    names: ["3-(4-Cyanophenyl)Quinoxaline-5-Carboxamide", "CHEMBL481603", "Quinoxaline Analogue, 3c", "SCHEMBL7108289"],
  },
  {
    primary_id: "D0M9UG",
    names: ["KNI-10368"],
  },
  {
    primary_id: "D0M9VE",
    names: [
      "EXISULIND",
      "Aposulind",
      "Aptosyn",
      "Sulindac Sulfone",
      "FGN-1",
      "Prevatac (Former Brand Name)",
      "Exisulind",
      "(Z)-5-Fluoro-2-Methyl-1-[4-(Methylsulfonyl)Benzylidene]-1H-Indene-3-Acetic Acid",
    ],
  },
  {
    primary_id: "D0M9VK",
    names: [
      "1-(6-Methoxy-Naphthalen-2-Yl)-1H-Imidazole",
      "BDBM8918",
      "CHEMBL195629",
      "SCHEMBL4499181",
      "Imidazole-Substituted Naphthalene 25",
      "ZINC13674484",
      "1-(6-Methoxy-2-Naphthyl)-1h-Imidazole",
    ],
  },
  {
    primary_id: "D0M9VP",
    names: [
      "1-Hydroxy-1-Thio-Glycerol",
      "1-HYDROXY-1-THIO-GLYCEROL",
      "SGL",
      "AC1NRD54",
      "SCHEMBL5003699",
      "DB01684",
      "(2R)-3-Hydroxysulfanylpropane-1,2-Diol",
      "(2R)-2,3-Dihydroxypropane-1-Sulfenic Acid",
    ],
  },
  {
    primary_id: "D0M9VY",
    names: ["Halisulfate 1", "CHEBI:69280", "CHEMBL387584", "Halisulfate 1, (Rel)-"],
  },
  {
    primary_id: "D0M9VZ",
    names: ["Navuridine", "AZDU", "Azidouridine", "AVS-2353", "CS-87"],
  },
  {
    primary_id: "D0M9WM",
    names: [
      "Chlorothiazide",
      "Aldoclor",
      "Alurene",
      "Chloriazid",
      "Chlorosal",
      "Chlorothiazid",
      "Chlorothiazidum",
      "Chlorotiazida",
      "Chlorthiazid",
      "Chlorthiazide",
      "Chlorthiazidum",
      "Chlortiazid",
      "Chlorurit",
      "Chlotride",
      "Chlrosal",
      "Clorotiazida",
      "Clorotiazide",
      "Clotride",
      "Diupres",
      "Diuresal",
      "Diuril",
      "Diurilix",
      "Diurite",
      "Diutrid",
      "Flumen",
      "Minzil",
      "Salisan",
      "Salunil",
      "Saluretil",
      "Saluric",
      "Thiazide",
      "Urinex",
      "Warduzide",
      "Yadalan",
      "Clorotiazide [DCIT]",
      "Component Of Aldoclor",
      "Diuril Boluses",
      "C 4911",
      "Chlorothiazidum [INN-Latin]",
      "Clorotiazida [INN-Spanish]",
      "Diuril (TN)",
      "Diuril Boluses, Veterinary",
      "Neo-Dema",
      "Sk-Chlorothiazide",
      "Chlorothiazide [USAN:INN:BAN]",
      "Diuril, Chlotride, Chlorothiazide",
      "Chlorothiazide (JAN/USP/INN)",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-, 1,1-Dioxide",
      "6-Chloro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-7-Sulfamoyl-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "6-Chloro-4H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D0M9WX",
    names: [
      "AMT-030",
      "AAV Gene Therapy (AGT, Hyperoxaluria), AMT",
      "Adeno-Associated Virus Gene Therapy (Alanine:glyoxylate Aminotransferase, Hyperoxaluria), Amsterdam Molecular Therapeutics",
    ],
  },
  {
    primary_id: "D0M9XH",
    names: ["XP-21510"],
  },
  {
    primary_id: "D0M9XT",
    names: ["NB-003"],
  },
  {
    primary_id: "D0M9YH",
    names: ["6-Hydroxydopa Quinone"],
  },
  {
    primary_id: "D0M9YZ",
    names: ["Aromatic Bicyclic Compound 4"],
  },
  {
    primary_id: "D0M9ZL",
    names: ["TPO Gene Plasmid"],
  },
  {
    primary_id: "D0M9ZT",
    names: ["TJ-960"],
  },
  {
    primary_id: "D0M9ZW",
    names: ["Hexahydro Naphthalene Derivative 2"],
  },
  {
    primary_id: "D0M9ZX",
    names: [
      "FL104",
      "885672-81-1",
      "CHEMBL583981",
      "[1,1'-Biphenyl]-4-Carboxamide,N-[1-(4-Chlorophenyl)-3-(Dimethylamino)Propyl]-",
      "CHEMBL204989",
      "GTPL3501",
      "SCHEMBL14204301",
      "BDBM50414782",
      "KB-63706",
    ],
  },
  {
    primary_id: "D0MA0F",
    names: [
      "WF-11605",
      "9-Acetoxy-2-(1,2-Dimethylpropyl)-8-(Hexopyranosyloxy)-2,4a,7,7,10a,12a-Hexamethyl-1,2,3,4,4a,5,6,6a,7,8,9,10,10a,11,12,12a-Hexadecahydrochrysene-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0MA2E",
    names: ["GLYCAR T Cells"],
  },
  {
    primary_id: "D0MA2H",
    names: ["PMID26560530-Compound-2"],
  },
  {
    primary_id: "D0MA2J",
    names: ["POL-443"],
  },
  {
    primary_id: "D0MA2R",
    names: ["Triazolo-Phthalazine Derivative 1"],
  },
  {
    primary_id: "D0MA3D",
    names: ["L-659989"],
  },
  {
    primary_id: "D0MA3M",
    names: ["MANGOSTENONE G", "CHEMBL1224412", "BDBM50325678"],
  },
  {
    primary_id: "D0MA4F",
    names: ["GD2-CART01"],
  },
  {
    primary_id: "D0MA4O",
    names: ["Anti-CD20 CAR-T Cells"],
  },
  {
    primary_id: "D0MA6I",
    names: ["Quinoline And Quinazoline Derivative 3"],
  },
  {
    primary_id: "D0MA7A",
    names: ["3-(Phenoxymethyl) Benzylamine Derivative 4"],
  },
  {
    primary_id: "D0MA7C",
    names: ["Sodium Nitrite"],
  },
  {
    primary_id: "D0MA8C",
    names: ["GR 125,743"],
  },
  {
    primary_id: "D0MA8Q",
    names: ["PMID8709131C17"],
  },
  {
    primary_id: "D0MA8T",
    names: ["PMID26394986-Compound-42"],
  },
  {
    primary_id: "D0MA9G",
    names: [
      "3-(1-Ethyl-3,4-Dihydronaphthalen-2-Yl)-Pyridine",
      "3-(1-Ethyl-3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "Dihydronaphthalene 7",
      "AC1O70HD",
      "CHEMBL205503",
      "BDBM8893",
      "SCHEMBL4505824",
      "CTK6D0393",
      "ZINC13684518",
      "AKOS015965702",
    ],
  },
  {
    primary_id: "D0MA9N",
    names: [
      "Dapsone",
      "Aczone",
      "Atrisone",
      "Avlosulfon",
      "Avlosulfone",
      "Avlosulphone",
      "Avsulfor",
      "Croysulfone",
      "Croysulphone",
      "DADPS",
      "DDS",
      "Dapson",
      "Dapsona",
      "Dapsonum",
      "Diaminodifenilsulfona",
      "Diaminodiphenylsulfone",
      "Diaphenylsulfon",
      "Diaphenylsulfone",
      "Diaphenylsulphon",
      "Diaphenylsulphone",
      "Diphenasone",
      "Diphone",
      "Disulone",
      "Dubronax",
      "Dubronaz",
      "Dumitone",
      "Eporal",
      "Novophone",
      "Protogen",
      "Sulfadione",
      "Sulfona",
      "Sulfonyldianiline",
      "Sulphadione",
      "Sulphonyldianiline",
      "Tarimyl",
      "Udolac",
      "Araldite HT",
      "D SS",
      "Diaminodifenilsulfona [Spanish]",
      "Diaminodiphenyl Sulfone;Fatol Brand Of Dapsone",
      "Metabolite C",
      "Orsade Brand Of Dapsone",
      "Sulfone Ucb",
      "Sumicure S",
      "Araldite HT 976",
      "F 1358",
      "HT 976",
      "HY 976",
      "Hardener HT 976",
      "W R 448",
      "WR 448",
      "ALBB-005917",
      "AZT + Dapsone Cominbation",
      "Aczone (TN)",
      "DDS (Pharmaceutical)",
      "DDS, Diaphenylsulfone",
      "DDS, Pharmaceutical",
      "DSS (VAN)",
      "Dapsoderm-X",
      "Dapson-Fatol",
      "Dapsona [INN-Spanish]",
      "Dapsone (USP)",
      "Dapsone [USAN:BAN]",
      "Dapsonum [INN-Latin];Diamino-Diphenyl Sulphone",
      "Diaphenylsulfone (JAN)",
      "IN-201",
      "Mex-America Brand Of Dapsone",
      "P-Aminophenyl Sulfone",
      "Sulfanona-Mae",
      "Sulfon-Mere",
      "Sulfona-MAE",
      "Sulphon-Mere",
      "Bis(4-Aminophenyl) Sulfone",
      "Bis(4-Aminophenyl)Sulfone",
      "Bis(4-Aminophenyl)Sulphone",
      "Bis(P-Aminophenyl) Sulfone",
      "Bis(P-Aminophenyl)Sulphone",
      "Di(4-Aminophenyl) Sulfone",
      "Di(4-Aminophenyl)Sulfone",
      "Di(4-Aminophenyl)Sulphone",
      "Di(P-Aminophenyl) Sulfone",
      "Di(P-Aminophenyl)Sulphone",
      "P,p-Diaminodiphenyl Sulphone",
      "P,p-Sulfonylbisbenzamine",
      "P,p-Sulfonylbisbenzenamine",
      "P,p-Sulphonylbisbenzamine",
      "P,p-Sulphonylbisbenzenamine",
      "P,p-Sulphonyldianiline",
      "N, N'-Diphenyl Sulfondiamide",
      "N,N'-Diphenyl Sulfondiamide",
      "P, P'-Sulfonyldianiline",
      "P,p'-Diaminodiphenyl Sulfone",
      "P,p'-Sulfonyldianiline",
      "Diamino-4,4'-Diphenyl Sulfone",
      "Diamino-4,4'-Diphenyl Sulphone",
      "Sulfone, 4,4'-Diaminophenyl",
      "(4-Sulfanilylphenyl)Amine",
      "1,1'-Sulfonylbis(4-Aminobenzene)",
      "1,1'-Sulfonylbis[4-Aminobenzene]",
      "1,1'-Sulphonylbis(4-Aminobenzene)",
      "4,4' Diaminophenyl Sulfone",
      "4,4'-Dapsone",
      "4,4'-Diaminodiphenyl Sulfone",
      "4,4'-Diaminodiphenyl Suphone",
      "4,4'-Diaminodiphenylsulfone",
      "4,4'-Sulfonylbisaniline",
      "4,4'-Sulfonylbisbenzamine",
      "4,4'-Sulfonylbisbenzenamine",
      "4,4'-Sulfonyldianiline;4,4'-Sulfonyldianiline (Dapsone)",
      "4,4'-Sulphonylbisbenzamine",
      "4,4'-Sulphonylbisbenzenamine",
      "4,4'-Sulphonyldianiline",
      "4,4'-Diaminophenyl Sulfone",
      "4,4-Diaminodifenylsulfon",
      "4,4-Diaminodifenylsulfon [Czech]",
      "4,4-Sulfonyldianiline",
      "4-(4-Aminophenyl)Sulfonylaniline",
      "4-Aminop Henyl Sulfone",
      "4-Aminophenyl Sulfone",
      "4-Aminophenylsulfone",
      "4-[(4-Aminobenzene)Sulfonyl]aniline",
      "4-[(4-Aminophenyl)Sulfonyl]aniline",
    ],
  },
  {
    primary_id: "D0MA9U",
    names: ["PMID28870136-Compound-60"],
  },
  {
    primary_id: "D0MB0V",
    names: ["2-(Substituted Ethynyl)Quinoline Derivative 2"],
  },
  {
    primary_id: "D0MB2Z",
    names: ["ATR-101"],
  },
  {
    primary_id: "D0MB3S",
    names: [
      "7-Chloro-1,2,3,4-Tetrahydro-Acridin-9-Ylamine",
      "7-Chloro-1,2,3,4-Tetrahydroacridin-9-Amine",
      "BRN 0394541",
      "ACRIDINE, 1,2,3,4-TETRAHYDRO-9-AMINO-7-CHLORO-",
      "6115-67-9",
      "9-Amino-7-Chloro-1,2,3,4-Tetrahydroacridine",
      "Tacrine Analogue 3",
      "AC1L2KHB",
      "CHEMBL62084",
      "BDBM8986",
      "DTXSID20210043",
      "ZINC2034186",
      "AKOS011482955",
      "LS-14463",
    ],
  },
  {
    primary_id: "D0MB8B",
    names: ["Prochymal"],
  },
  {
    primary_id: "D0MB8I",
    names: [
      "Oxacillin",
      "Bactocill",
      "Ossacillina",
      "Oxacilina",
      "Oxacilline",
      "Oxacillinum",
      "Oxazocillin",
      "Oxazocilline",
      "Prostaphlin",
      "Prostaphlyn",
      "OXACILLIN SODIUM",
      "Ossacillina [DCIT]",
      "Sodium Oxacillin",
      "Bactocill (TN)",
      "MPI-Penicillin",
      "MPi-PC",
      "Oxacilina (TN)",
      "Oxacilina [INN-Spanish]",
      "Oxacillin (INN)",
      "Oxacillin [INN:BAN]",
      "Oxacilline [INN-French]",
      "Oxacillinum [INN-Latin]",
      "Penicillin, Methylphenylisoxazolyl",
      "Oxacillin, Monosodium Salt, Anhydrous",
      "(2S,5R,6R)-3,3-Dimethyl-6-[(5-Methyl-3-Phenyl-1,2-Oxazole-4-Carbonyl)Amino]-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-6-{[(5-Methyl-3-Phenylisoxazol-4-Yl)Carbonyl]amino}-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(5-Methyl-3-Phenyl-4-Isoxazolyl)Penicillin",
      "2,2-Dimethyl-6beta-(5-Methyl-3-Phenyl-1,2-Oxazole-4-Carboxamido)Penam-3alpha-Carboxylic Acid",
      "5-Methyl-3-Phenyl-4-Isoxazolyl-Penicillin",
      "6beta-(5-Methyl-3-Phenylisoxazol-4-Yl)Penicillanic Acid",
    ],
  },
  {
    primary_id: "D0MC0O",
    names: ["AZD-1080"],
  },
  {
    primary_id: "D0MC2E",
    names: ["MOR-203"],
  },
  {
    primary_id: "D0MC2R",
    names: ["FLUTROLINE"],
  },
  {
    primary_id: "D0MC2S",
    names: ["NIC-002"],
  },
  {
    primary_id: "D0MC3J",
    names: ["PMID27336223-Compound-14"],
  },
  {
    primary_id: "D0MC3K",
    names: [
      "CID755673",
      "521937-07-5",
      "CID 755673",
      "Cid-755673",
      "MLS000043346",
      "7-Hydroxy-2,3,4,5-Tetrahydro-1H-Benzofuro[2,3-C]azepin-1-One",
      "7-Hydroxy-2,3,4,5-Tetrahydro-1H-[1]benzofuro[2,3-C]azepin-1-One",
      "SMR000019965",
      "7-Hydroxy-2,3,4,5-Tetrahydro-[1]benzofuro[2,3-C]azepin-1-One",
      "2,3,4,5-Tetrahydro-7-Hydroxy-1H-Benzofuro[2,3-C]azepin-1-One",
      "3-Hydroxy-5,6,7,8-Tetrahydro-10-Oxa-8-Aza-Benzo[a]azulen-9-One",
      "AC1LFLHI",
      "SCHEMBL9904268",
      "Cid_755673",
      "REGID_For_CID_755673",
      "CHEMBL1450770",
      "BDBM32334",
      "CTK4J5567",
      "CHEBI:92059",
      "KS-00001CWT",
      "EX-A393",
    ],
  },
  {
    primary_id: "D0MC3R",
    names: ["Goxalapladib"],
  },
  {
    primary_id: "D0MC3U",
    names: ["N,N'-(1',10'-Decylene)-Bis-(-)-Nor-MEP", "CHEMBL259523"],
  },
  {
    primary_id: "D0MC4U",
    names: ["Exiguamine A", "CHEMBL468547", "BDBM21975"],
  },
  {
    primary_id: "D0MC5H",
    names: [
      "2-(4-Chlorobenzylidene)Cyclopentanone",
      "CHEMBL562352",
      "E-2-(4-Chlorobenzylidene)Cyclopentanone",
      "BDBM50293435",
      "AKOS027461656",
      "134791-44-9",
      "(E)-2-(4-Chlorobenzylidene)Cyclopentanone",
    ],
  },
  {
    primary_id: "D0MC5J",
    names: ["PACAP-27"],
  },
  {
    primary_id: "D0MC5O",
    names: ["CAP-1002"],
  },
  {
    primary_id: "D0MC6Y",
    names: ["LTX-04"],
  },
  {
    primary_id: "D0MC9C",
    names: ["PMID20947351C16"],
  },
  {
    primary_id: "D0MD0T",
    names: ["Satralizumab"],
  },
  {
    primary_id: "D0MD2L",
    names: [
      "Rufinamide",
      "106308-44-5",
      "Inovelon",
      "Banzel",
      "1-(2,6-Difluorobenzyl)-1H-1,2,3-Triazole-4-Carboxamide",
      "CGP-33101",
      "Cgp 33101",
      "Xilep",
      "1-[(2,6-Difluorophenyl)Methyl]-1H-1,2,3-Triazole-4-Carboxamide",
      "RUF-331",
      "UNII-WFW942PR79",
      "RUF 331",
      "1-[(2,6-Difluorophenyl)Methyl]triazole-4-Carboxamide",
      "WFW942PR79",
      "C10H8F2N4O",
      "E 2080",
      "POGQSBRIGCQNEG-UHFFFAOYSA-N",
      "1H-1,2,3-Triazole-4-Carboxamide, 1-[(2,6-Difluorophenyl)Methyl]-",
      "NCGC00165883-02",
      "E2080",
      "DSSTox_CID_26506",
      "DSSTox_RID_81675",
      "DSSTox_GSID_46506",
      "Banzel, Rufinamide",
      "E-2080",
      "SYN-111",
      "Rufinamide (USAN/INN)",
      "Inovelon/Banzel",
    ],
  },
  {
    primary_id: "D0MD4U",
    names: [
      "3-(2-Aminoethyl)-4-(Aminomethyl)Heptanedioic Acid",
      "3-(2-AMINOETHYL)-4-(AMINOMETHYL)HEPTANEDIOIC ACID",
      "(3R,4S)-3-(2-Aminoethyl)-4-(Aminomethyl)Heptanedioic Acid",
      "AC1NRCOO",
    ],
  },
  {
    primary_id: "D0MD5M",
    names: [
      "G100",
      "Caffeine, 8-((3-Methoxypropyl)Amino)-",
      "8-((3-Methoxypropyl)Amino)Caffeine",
      "CAFFEINE, 8-((3-METHOXYPROPYL)AMINO)-",
      "BRN 1086127",
      "95982-26-6",
      "8-(Gamma-Methoxypropyl)-Aminocoffein [German]",
      "8-(3-Methoxypropylamino)-1,3,7-Trimethylpurine-2,6-Dione",
      "8-[(3-Methoxypropyl)Amino]-1,3,7-Trimethyl-3,7-Dihydro-1H-Purine-2,6-Dione",
      "8-(Gamma-Methoxypropyl)-Aminocoffein",
      "AC1L1M3J",
      "CTK5H8490",
      "DTXSID70242056",
      "MolPort-002-837-706",
      "CCG-22657",
      "ZINC17172964",
      "AKOS001669223",
      "MCULE-8333260193",
      "LS-48594",
      "EU-0014431",
      "G-100",
      "AO-343/41780992",
    ],
  },
  {
    primary_id: "D0MD6C",
    names: [
      "Sernivo",
      "Diprosone",
      "Diprolene",
      "Diprolene AF",
      "Maxivate",
      "Diproderm",
      "Diprosis",
      "Alphatrex",
      "Psorion",
      "5593-20-4",
      "Rinderon DP",
      "Betamethasone-17,21-Dipropionate",
      "Betamethasone 17,21-Dipropionate",
      "Sch-11460",
      "Sch 11460",
      "UNII-826Y60901U",
      "Beta-Methasone 17,21-Dipropionate",
      "EINECS 227-005-2",
      "S-3440",
      "BRN 3638108",
      "CHEBI:31276",
      "CIWBQSYVNNPZIQ-XYWKZLDCSA-N",
      "S 3440",
      "826Y60901U",
      "9-Fluoro-11beta,17,21-Trihydroxy-16beta-Methylpregna-1,4-Diene-3,20-Dione 17,21-Dipropionate",
      "9-Fluoro-11beta,17,21-Trihydroxy-16beta-Methylpregna-1,4-Dien",
    ],
  },
  {
    primary_id: "D0MD6D",
    names: ["9-Benzyl-6-(Benzylamino)-9H-Purine-2-Carbonitrile", "Compound 3{8,17}", "CHEMBL400454"],
  },
  {
    primary_id: "D0MD6P",
    names: ["1,2,4-Triazole Derivative 3"],
  },
  {
    primary_id: "D0MD7H",
    names: ["N-(4-Aminobiphenyl-3-Yl)Benzamide"],
  },
  {
    primary_id: "D0MD7Y",
    names: ["Ragweed Mix Vaccine"],
  },
  {
    primary_id: "D0MD8D",
    names: ["Tecovirimat"],
  },
  {
    primary_id: "D0MD8O",
    names: [
      "Adacel",
      "Covaxis",
      "Tdap Vaccine",
      "DTaP Booster Vaccine, Aventis Pasteur",
      "Diphtheria/Tetanus/Pertussis Booster Vaccine, Aventis Pasteur",
    ],
  },
  {
    primary_id: "D0MD8S",
    names: ["CARTmeso/19"],
  },
  {
    primary_id: "D0ME1L",
    names: [
      "Digibind",
      "Digoxin Immune Fab (Ovine)",
      "Ovine Antidigoxin Polyclonal Antibody Fragments (Pre-Eclampsia), BTG",
      "Ovine Antidigoxin Polyclonal Antibody Fragments (Pre-Eclampsia), GlaxoSmithKline/Glenveigh",
    ],
  },
  {
    primary_id: "D0ME2T",
    names: ["RTA-901"],
  },
  {
    primary_id: "D0ME4N",
    names: ["PMID25399762-Compound-Table 7-Vanillyl Alcohol"],
  },
  {
    primary_id: "D0ME5D",
    names: ["Pyrrolidinyl Urea Derivative 5"],
  },
  {
    primary_id: "D0ME6Y",
    names: [
      "N-(5-Hydroxycarbamoyl-Pentyl)-4-Methoxy-Benzamide",
      "CHEMBL143802",
      "SCHEMBL1233091",
      "BDBM50082663",
      "ZINC13801951",
      "6-[(4-Methoxybenzoyl)Amino]hexanehydroxamic Acid",
    ],
  },
  {
    primary_id: "D0ME7Q",
    names: ["MP0250"],
  },
  {
    primary_id: "D0ME7S",
    names: ["Shikimate Kinase Inhibitors"],
  },
  {
    primary_id: "D0ME8Z",
    names: ["5-(2-Fluorobenzyloxy)Quinazoline-2,4-Diamine", "CHEMBL251427", "SCHEMBL1154761"],
  },
  {
    primary_id: "D0ME9B",
    names: ["PMID20855207C25"],
  },
  {
    primary_id: "D0MF1G",
    names: ["2-(Naphthalen-2-Ylsulfonyl)Naphthalene-1,4-Diol", "CHEMBL462374"],
  },
  {
    primary_id: "D0MF2V",
    names: ["PMID26560530-Compound-33"],
  },
  {
    primary_id: "D0MF3C",
    names: ["WAY-123641"],
  },
  {
    primary_id: "D0MF3F",
    names: ["AAV8-773", "Adeno-Virus Gene Therapy (Blue Cone Monochromacy), Applied Genetic Technologies"],
  },
  {
    primary_id: "D0MF5Y",
    names: ["3,7,3',4'-TETRAHYDROXYFLAVONE"],
  },
  {
    primary_id: "D0MF7U",
    names: ["PF-3052334"],
  },
  {
    primary_id: "D0MF9G",
    names: ["Potassium Perchlorate"],
  },
  {
    primary_id: "D0MF9H",
    names: ["Delucemine", "Delucemine Hydrochloride", "NPS-1506"],
  },
  {
    primary_id: "D0MG0F",
    names: [
      "IW-001",
      "Auto-Antigen (Oral, IPF), ImmuneWorks",
      "Auto-Antigen (Oral, Idiopathic Pulmonary Fibrosis), ImmuneWorks",
      "Auto-Antigen (Oral, Idiopathic Pulmonary Fibrosis), ImmuneWorks/ Lung Rx",
    ],
  },
  {
    primary_id: "D0MG0H",
    names: ["NVC-612"],
  },
  {
    primary_id: "D0MG1Y",
    names: ["Belladonna"],
  },
  {
    primary_id: "D0MG2R",
    names: ["ALX-0141"],
  },
  {
    primary_id: "D0MG3U",
    names: ["(E,E)-8-[4-(3-Fluorophenyl)Butadien-1-Yl]caffeine", "CHEMBL489391"],
  },
  {
    primary_id: "D0MG3Z",
    names: ["CAR-T Cells Targeting PSCA"],
  },
  {
    primary_id: "D0MG4D",
    names: ["Heteroaryl-Carboxamide Derivative 5"],
  },
  {
    primary_id: "D0MG5N",
    names: ["Mahureone D"],
  },
  {
    primary_id: "D0MG6V",
    names: ["3-Substituted-1,2,4-Oxadiazole Derivative 2"],
  },
  {
    primary_id: "D0MG9O",
    names: ["PHELLIGRIDIN I"],
  },
  {
    primary_id: "D0MH0S",
    names: [
      "1-(3-Fluoro-Pyridin-2-Yl)-4-Methyl-Piperazine",
      "85386-90-9",
      "1-(3-Fluoropyridin-2-Yl)-4-Methylpiperazine",
      "CHEMBL280514",
      "1-Methyl-4-(3-Fluoro-2-Pyridinyl)Piperazine",
      "Piperazine, 1-(3-Fluoro-2-Pyridinyl)-4-Methyl- (9ci)",
      "SCHEMBL10964078",
      "KZNJAAWCEFRAPW-UHFFFAOYSA-N",
      "ZINC36237989",
      "BDBM50026633",
      "AKOS025286419",
      "AM87258",
    ],
  },
  {
    primary_id: "D0MH1F",
    names: ["MEDI3726"],
  },
  {
    primary_id: "D0MH1K",
    names: ["SC-55634", "CHEMBL9599", "BDBM50281823"],
  },
  {
    primary_id: "D0MH1R",
    names: ["RG7418", "BI204"],
  },
  {
    primary_id: "D0MH1X",
    names: ["AZD1332", "AZD 1332", "AZD-1332"],
  },
  {
    primary_id: "D0MH3P",
    names: ["Contraceptive Combination", "Contraceptives, Combination"],
  },
  {
    primary_id: "D0MH4B",
    names: ["PMID25980951-Compound-19"],
  },
  {
    primary_id: "D0MH4R",
    names: ["Visnagin", "Desmethoxykhellin"],
  },
  {
    primary_id: "D0MH5C",
    names: ["PMID28394193-Compound-11"],
  },
  {
    primary_id: "D0MH6P",
    names: ["[(N4-Bzdig)0,Nle14]BB(7-14)", "CHEMBL266079"],
  },
  {
    primary_id: "D0MH7K",
    names: ["Pyrimidine-4(3H)-One Derivative 1"],
  },
  {
    primary_id: "D0MH8G",
    names: ["PMID28394193-Compound-29"],
  },
  {
    primary_id: "D0MH8K",
    names: ["LPO-1010CSR"],
  },
  {
    primary_id: "D0MH8O",
    names: [
      "Angiotensin-(1-7)",
      "Ang (1-7)",
      "Angiotensin (1-7)",
      "Angiotensin-(1-7) (Cancer)",
      "Angiotensin-(1-7) (Cancer), Wake Forest University",
    ],
  },
  {
    primary_id: "D0MH9X",
    names: ["Inositol 1,3,4,5-Tetrakisphosphate"],
  },
  {
    primary_id: "D0MI0S",
    names: ["Antigen-Pulsed Dendritic Cell Vaccine", "Antigen-Pulsed Dendritic Cell Vaccine (Melanoma)"],
  },
  {
    primary_id: "D0MI0T",
    names: ["Macrocyclic Quinazolinone Analogue"],
  },
  {
    primary_id: "D0MI1N",
    names: [
      "CR-5259",
      "CR-4144 Series",
      "CR-4152 Series",
      "CR-4153 Series",
      "CR-4167 Series",
      "Aggrecanase (ADAMTS-4 And 5) Inhibitors (Osteoarthritis),Rottapharm Madaus",
    ],
  },
  {
    primary_id: "D0MI2W",
    names: ["Cyclo[Ac-Cys-Ile-Tyr-Lys-Tyr-Tyr]", "CHEMBL429525"],
  },
  {
    primary_id: "D0MI3E",
    names: ["PMID25684022-Compound-WO2008107444"],
  },
  {
    primary_id: "D0MI5C",
    names: ["3,3'-(4-Phenylpyridine-2,6-Diyl)Diphenol", "CHEMBL1095114"],
  },
  {
    primary_id: "D0MI5G",
    names: ["RG2077"],
  },
  {
    primary_id: "D0MI7K",
    names: ["Bicarboxylic And Tricarboxylic Ethynyl Derivative 1"],
  },
  {
    primary_id: "D0MI8F",
    names: ["IdeS"],
  },
  {
    primary_id: "D0MI8Z",
    names: ["ONS-3010"],
  },
  {
    primary_id: "D0MI9F",
    names: ["PTX-001", "Anginex", "Anginex Analogs, University Of Minnesota", "DB-16", "DB-21", "PTX-004", "6DBF7"],
  },
  {
    primary_id: "D0MJ1L",
    names: ["2-Morpholino-7-Phenyl-4H-Chromen-4-One", "CHEMBL95309"],
  },
  {
    primary_id: "D0MJ2H",
    names: ["KD-7040"],
  },
  {
    primary_id: "D0MJ4L",
    names: ["Hydroxamate Analog 1"],
  },
  {
    primary_id: "D0MJ5E",
    names: [
      "DMB Mabs",
      "DMB MAbs",
      "Anti-MUC1 Junction Monoclonal Antibody (Cancer)",
      "Anti-MUC1 Junction MAbs (Cancer), Tel Aviv",
      "Anti-MUC1 Junction Monoclonal Antibody (Cancer), Tel Aviv",
    ],
  },
  {
    primary_id: "D0MJ7W",
    names: [
      "BRX-1002",
      "BRX-100248",
      "BRX-10030",
      "BRX-100982",
      "BRX-100982)",
      "RUNX3 Inhibitor (Cancer), Biorunx",
      "Runt-Related Transcription Factor 3 Inhibitor(Cancer), Biorunx",
    ],
  },
  {
    primary_id: "D0MJ9L",
    names: ["PB-102", "Anti-CD55 Chimeric Monoclonal Antibody (Cancer), Percipio Biotherapeutics"],
  },
  {
    primary_id: "D0MK2C",
    names: ["SR-31742A"],
  },
  {
    primary_id: "D0MK2F",
    names: ["Macrocyclic Peptide Analog 3"],
  },
  {
    primary_id: "D0MK2Y",
    names: [
      "Rotavax",
      "BRV-TV",
      "Rotavirus Vaccine (Oral, Live Attenuated)",
      "Live Attenuated Multivalent Human-Bovine Rotavirus Vaccine (Oral), Aridis",
      "Live Attenuated Multivalent Human-Bovine Rotavirus Vaccine (Oral), NIH",
      "Live Attenuated Multivalent Human-Bovine Vaccine (Oral, Rotavirus Infection), Aridis",
      "Live Attenuated Multivalent Human-Bovine Vaccine (Oral, Rotavirus Infection), NIH",
      "Live Attenuated Tetravalent (G1-G4) Bovine-Human Reassortant Rotavirus Vaccine (Oral), Shantha Biotechnics",
      "Rotavirus Vaccine (Oral, Live Attenuated), PATH/Aridis/Shantha Biotechnics",
    ],
  },
  {
    primary_id: "D0MK3G",
    names: ["ADXS-NEO"],
  },
  {
    primary_id: "D0MK3I",
    names: ["PMID30247903-Compound-General Structure2"],
  },
  {
    primary_id: "D0MK5C",
    names: ["PharmaPEG-Interferon-Beta"],
  },
  {
    primary_id: "D0MK6D",
    names: ["N-(5-Cyclopropyl-1h-Pyrazol-3-Yl)Benzamide"],
  },
  {
    primary_id: "D0MK7H",
    names: ["Anti-BCMA CAR-T Cells"],
  },
  {
    primary_id: "D0MK7X",
    names: ["Imidazo[5,1-C][1,2,4]benzotriazine Derivative 3"],
  },
  {
    primary_id: "D0MK9W",
    names: ["M1 Agonist"],
  },
  {
    primary_id: "D0ML0L",
    names: ["ARRY-614"],
  },
  {
    primary_id: "D0ML0R",
    names: ["SKF-105685"],
  },
  {
    primary_id: "D0ML0X",
    names: ["PMID30247903-Compound-General Structure38"],
  },
  {
    primary_id: "D0ML1F",
    names: [
      "Adefovir Dipivoxil",
      "Adefovirdipivoxl",
      "Hepsera",
      "Preveon",
      "YouHeDing",
      "Adefovir Depivoxil",
      "Adefovir Pivoxil",
      "GS 0840",
      "GS 840;Piv2PMEA",
      "Adefovir Dipivoxil (USAN)",
      "Adefovir Pivoxil (JAN)",
      "Bis(Pom)PMEA",
      "Bis-POM PMEA",
      "GS-0840",
      "GS-840",
      "Hepsera (TM)",
      "Hepsera (TN)",
      "Preveon (TN)",
      "Bis(POM)-PMEA",
      "Bis-POM PMEA, Adefovir Dipivoxil",
      "Bis-POM PMEA, Preveon, Hepsera, Adefovir Dipivoxil",
      "Bis(Pivaloyloxymethyl)-9-(2-Phosphonylmethoxyethyl)Adenine",
      "[2-(6-Aminopurin-9-Yl)Ethoxymethyl-(2,2-Dimethylpropanoyloxymethoxy)Phosphoryl]oxymethyl 2,2-Dimethylpropanoate",
      "Propanoic Acid,2,2-Dimethyl-(((2-(6-Amino-9H-Purin-9-Yl)Ehtoxy)Mehtyl)Phosphinyldiene)Bis(Oxymehtylene)Ester",
      "Propanoic Acid, 2,2-Dimethyl-, (((2-(6-Amino-9H-Purin-9-Yl)Ethoxy)Methyl)Phosphinylidene)Bis(Oxymethylene) Ester",
      "(((2-(6-Amino-9H-Purin-9-Yl)Ethoxy)Methyl)Phosphinylidene)Bis(Oxymethylene) 2,2-Dimethylpropanoate",
      "((2-(6-Amino-9H-Purin-9-Yl)Ethoxy)Methyl)Phosphonic Acid, Diester With Hydroxymethyl Pivalate",
      "9-(2-((-Bis((Pivaloyloxy)Methoxy)Phosphinyl)Methoxy)Ethyl)Adenine",
      "9-(2-((Bis((Pivaloyloxy)Methoxy)Phosphinyl)Methoxy)Ethyl)Adenine",
      "ADV",
    ],
  },
  {
    primary_id: "D0ML2V",
    names: ["RALURIDINE"],
  },
  {
    primary_id: "D0ML3Q",
    names: ["ISIS 29243"],
  },
  {
    primary_id: "D0ML3S",
    names: ["2,4-Bis-Docosanoylamino-Benzenesulfonate", "CHEMBL96329"],
  },
  {
    primary_id: "D0ML5T",
    names: ["EUR-1066"],
  },
  {
    primary_id: "D0ML8G",
    names: ["Turoctocog Alfa"],
  },
  {
    primary_id: "D0ML9K",
    names: ["T-506", "2'-Deoxy-5-Fluoro-3'-Oleoyluridine 5'-[2-(Trimethylammonio)Ethyl]phosphate Hydrate"],
  },
  {
    primary_id: "D0MM0N",
    names: ["MPC-300-IV"],
  },
  {
    primary_id: "D0MM1Y",
    names: ["Polyhydroxy Benzamide Derivative 2"],
  },
  {
    primary_id: "D0MM2K",
    names: ["CP-195543"],
  },
  {
    primary_id: "D0MM2L",
    names: ["Elvitegravir/Cobicistat/Emtricitabine/Tenofovir Disoproxil Fumarate"],
  },
  {
    primary_id: "D0MM3A",
    names: ["BIIB104"],
  },
  {
    primary_id: "D0MM4H",
    names: ["GSK1795091"],
  },
  {
    primary_id: "D0MM4X",
    names: ["MIV-710"],
  },
  {
    primary_id: "D0MM5G",
    names: ["PMID7629799C2d"],
  },
  {
    primary_id: "D0MM5P",
    names: ["Subcutaneous Furosemide"],
  },
  {
    primary_id: "D0MM6A",
    names: [
      "Coenzyme Q10 Analog",
      "Coenzyme Q10 Analog (Mitochondrial Disease)",
      "Coenzyme Q10 Analog (Mitochondrial Disease), Edison Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0MM6L",
    names: ["2-HYDROXYCARBAMOYL-4-METHYL-PENTANOIC ACID", "AC1L1BQO", "SCHEMBL1458463", "CINIOMOBGSHXRK-UHFFFAOYSA-N"],
  },
  {
    primary_id: "D0MM6Q",
    names: ["MM-POD1"],
  },
  {
    primary_id: "D0MM7G",
    names: ["1-((9H-Fluoren-2-Yl)Methyl)-1H-Imidazole", "CHEMBL502542", "SCHEMBL8607720"],
  },
  {
    primary_id: "D0MM7U",
    names: ["PMID27109571-Compound-36"],
  },
  {
    primary_id: "D0MM8M",
    names: ["Diazepinone Derivative 2"],
  },
  {
    primary_id: "D0MM8N",
    names: ["Idebenone"],
  },
  {
    primary_id: "D0MM9P",
    names: ["OXENDLONE"],
  },
  {
    primary_id: "D0MN3X",
    names: [
      "Glycerol-2-Phosphate",
      "1,3-Dihydroxypropan-2-Yl Phosphate",
      "Glycerol 2-Phosphate",
      "2-Glycerophosphate",
      "NCGC00166037-02",
      "B-Glycerol-Phosphate",
      "Beta-Glycerol-Phosphate",
      "AC1MU1UF",
      "Glycerol 2-Phosphate(2-)",
      "Glycerol 2-Phosphate Dianion",
      "CHEBI:58083",
      "STL280277",
      "2-(Phosphonatooxy)Propane-1,3-Diol",
      "AKOS022142160",
    ],
  },
  {
    primary_id: "D0MN9K",
    names: ["Meclofenamate Sodium"],
  },
  {
    primary_id: "D0MO0J",
    names: [
      "MEDI0382",
      "Cotadutide",
      "UNII-QL6A9B13HW",
      "QL6A9B13HW",
      "Cotadutide [INN]",
      "Cotadutide [USAN]",
      "MEDI-0382",
      "MEDI 0382 [WHO-DD]",
      "1686108-82-6",
      "His-Ser-Gln-Gly-Thr-Phe-Thr-Ser-Asp-Lys-(Gamma-Glupalmitoyl)-Ser-Glu-Tyr-Leu-Asp-Ser-Glu-Arg-Ala-Arg-Asp- Phe-Val-Ala-Trp-Leu-Glu-Ala-Gly-Gly",
      "Glycine, L-Histidyl-L-Seryl-L-Glutaminylglycyl-L-Threonyl-L-Phenylalanyl-L-Threonyl-L-Seryl-L-Alpha-Aspartyl-N6-(N-(1-Oxotetradecyl)-L-Gamma-Glutamyl)-L-Lysyl-L-Seryl-L-Alpha-Glutamyl-L-Tyrosyl-L-Leucyl-L-Alpha-Aspartyl-L-Seryl-L-Alpha-Glutamyl",
    ],
  },
  {
    primary_id: "D0MO0Z",
    names: ["Alkyl Mannoside Derivative 5"],
  },
  {
    primary_id: "D0MO1O",
    names: [
      "N-Methyl,N-(Benzyl),N-(Pyrrol-2-Ylmethyl)Amine",
      "CHEMBL374335",
      "Pyrrole Inhibitor 7",
      "SCHEMBL17045839",
      "BDBM15592",
      "ZINC13493580",
      "Benzyl(Methyl)(1H-Pyrrol-2-Ylmethyl)Amine",
    ],
  },
  {
    primary_id: "D0MO1P",
    names: ["PMID27109571-Compound-10"],
  },
  {
    primary_id: "D0MO2X",
    names: ["PMID28394193-Compound-30"],
  },
  {
    primary_id: "D0MO3W",
    names: ["Pyridino Tricyclic Compound 1"],
  },
  {
    primary_id: "D0MO5Y",
    names: ["PMID27019002-Compound-43b"],
  },
  {
    primary_id: "D0MO7R",
    names: ["Antiinfective Therapeutic", "Antiinfective Therapeutic (Gram-Positive Bacterial Infection)"],
  },
  {
    primary_id: "D0MP0X",
    names: ["AZD1480"],
  },
  {
    primary_id: "D0MP1C",
    names: ["Flosequinan"],
  },
  {
    primary_id: "D0MP1M",
    names: ["CERE-110"],
  },
  {
    primary_id: "D0MP2S",
    names: ["Centanamycin", "CC-1065 Analog", "ML-970", "AS-1-145", "Antimalarials (Oral), Spirogen"],
  },
  {
    primary_id: "D0MP2U",
    names: ["AN-9"],
  },
  {
    primary_id: "D0MP4W",
    names: ["Tetra-Hydro-Quinoline Derivative 1"],
  },
  {
    primary_id: "D0MP5H",
    names: [
      "Debrisoquin",
      "Debrisochinum",
      "Debrisoquina",
      "Debrisoquine",
      "Debrisoquinum",
      "Declinax",
      "Equitonil",
      "Isocaramidine",
      "Tendor",
      "DEBRISOQUIN SULFATE",
      "Debrisoquin Hemisulfate",
      "Debrisoquine Sulfate",
      "Isocaramidine Sulfate",
      "Debrisoquina [INN-Spanish]",
      "Debrisoquine [INN:BAN]",
      "Debrisoquinum [INN-Latin]",
      "Ro 5-3307/1",
      "Sulfuric Acid Compound With 3,4-Dihydro-2(1H)-Isoquinolinecarboximidamide (1:1)",
      "1,2,3, 4-Tetrahydro-Isoquinoline-2-Carboxamidine Sulfate",
      "2(1H)-Isoquinolinecarboxamidine, 3,4-Dihydro-(7CI,8CI)",
      "2(1H)-Isoquinolinecarboxamidine, 3,4-Dihydro-, Sulfate (2:1)",
      "2(1H)-Isoquinolinecarboximidamide, 3,4-Dihydro-, Sulfate (2:1)",
      "2-Amidino-1,2,3,4-Tetrahydroisoquinoline",
      "3,4-Dihydro-2(1H)-Isoquinolinecarboxamidine",
      "3,4-Dihydro-2(1H)-Isoquinolinecarboxamidine Sulfate (2:1)",
      "3,4-Dihydro-2(1H)-Isoquinolinecarboximidamide",
      "3,4-Dihydro-2(1H;)-Isoquinoline Carboxamidine Sulfate",
      "3,4-Dihydro-1H-Isoquinoline-2-Carboximidamide",
      "3,4-Dihydroisoquinoline-2(1H)-Carboximidamide",
    ],
  },
  {
    primary_id: "D0MP7N",
    names: ["NSC-345763"],
  },
  {
    primary_id: "D0MP7Z",
    names: ["Dmt-Pro-Phe-D-2-Nal-NH2"],
  },
  {
    primary_id: "D0MP8E",
    names: ["VUF 8430"],
  },
  {
    primary_id: "D0MP8N",
    names: ["Antibodie Derivative 5"],
  },
  {
    primary_id: "D0MQ2D",
    names: ["PMID28092474-Compound-32m"],
  },
  {
    primary_id: "D0MQ3X",
    names: ["PMID28454500-Compound-40"],
  },
  {
    primary_id: "D0MQ6K",
    names: ["RPR-118071"],
  },
  {
    primary_id: "D0MQ6T",
    names: ["PMID25776143-Compound-3"],
  },
  {
    primary_id: "D0MQ7F",
    names: ["Heteroaryl-Azepine Derivative 13"],
  },
  {
    primary_id: "D0MQ8A",
    names: ["3-Butyl-3-Ethyl-1-Phenylazetidine-2,4-Dione", "CHEMBL566476", "SCHEMBL10952562", "BDBM50303373"],
  },
  {
    primary_id: "D0MR0B",
    names: ["TEW-7197"],
  },
  {
    primary_id: "D0MR0Z",
    names: ["(R)-1-(4-Methoxyphenethyl)-2-Methylpyrrolidine", "CHEMBL271898"],
  },
  {
    primary_id: "D0MR1B",
    names: ["AS604872", "AS 604872", "AS-604872"],
  },
  {
    primary_id: "D0MR1K",
    names: [
      "D-Myo-Inositol 1,4,5-Trisphosphate",
      "D-Myo-Inositol-1,4,5-Triphosphate",
      "Inositol 1,4,5-Trisphosphate",
      "1,4,5-Insp3",
      "InsP3",
      "1D-Myo-Inositol 1,4,5-Trisphosphate",
      "IP3",
      "Ins(1,4,5)P3",
      "85166-31-0",
      "CHEMBL279107",
      "CHEBI:16595",
      "Triphosphoinositol",
      "I3P",
      "1D-Myo-Inositol 1,4,5-Tris(Dihydrogen Phosphate)",
      "D-Myo-Inositol, 1,4,5-Tris(Dihydrogen Phosphate)",
      "{[(1R,2S,3R,4R,5S,6R)-2,3,5-Trihydroxy-4,6-Bis(Phosphonooxy)Cyclohexyl]oxy}phosphonic Acid",
      "D-Myo-Inositol 1,4,5-Triphosphate",
      "1btn",
      "1mai",
      "Inositol Trisphosphate",
      "1n4k",
      "1h0a",
    ],
  },
  {
    primary_id: "D0MR1Z",
    names: ["Benzaldehyde O-4-Butoxyphenylcarbamoyl Oxime", "CHEMBL598243"],
  },
  {
    primary_id: "D0MR4N",
    names: ["1-(4-Butyl-Thiazol-2-Yl)-3-Phenethyl-Thiourea"],
  },
  {
    primary_id: "D0MR4Q",
    names: ["CCX-168"],
  },
  {
    primary_id: "D0MR7U",
    names: ["PMID28092474-Compound-32p"],
  },
  {
    primary_id: "D0MR8D",
    names: ["Imidazopyridine Acetamide Analog 3"],
  },
  {
    primary_id: "D0MR9V",
    names: ["PMID27998201-Compound-15"],
  },
  {
    primary_id: "D0MS1D",
    names: ["FCE-25876"],
  },
  {
    primary_id: "D0MS1G",
    names: ["Fish Oil Triglycerides"],
  },
  {
    primary_id: "D0MS2T",
    names: ["BCMA-Specific CAR-Expressing T Lymphocytes"],
  },
  {
    primary_id: "D0MS3D",
    names: ["Delta12-PGJ2", "Dddd-PGD2", "Delta(12)-PGJ2", "Delta-12-Prostaglandin D2"],
  },
  {
    primary_id: "D0MS3Q",
    names: ["CodaVax"],
  },
  {
    primary_id: "D0MS3V",
    names: ["2-(Biphenyl-3-Ylthio)-N,N-Dimethylethanamine", "CHEMBL597981"],
  },
  {
    primary_id: "D0MS3Z",
    names: [
      "GSK690693",
      "937174-76-0",
      "GSK-690693",
      "GSK 690693",
      "UNII-GWH480321B",
      "(S)-4-(2-(4-Amino-1,2,5-Oxadiazol-3-Yl)-1-Ethyl-7-(Piperidin-3-Ylmethoxy)-1H-Imidazo[4,5-C]pyridin-4-Yl)-2-Methylbut-3-Yn-2-Ol",
      "CHEBI:90677",
      "GWH480321B",
      "4-[2-(4-Amino-1,2,5-Oxadiazol-3-Yl)-1-Ethyl-7-[(3S)-3-Piperidinylmethoxy]-1H-Imidazo[4,5-C]pyridin-4-Yl]-2-Methyl-3-Butyn-2-Ol",
      "4-{2-(4-Amino-1,2,5-Oxadiazol-3-Yl)-1-Ethyl-7-[(3s)-Piperidin-3-Ylmethoxy]-1h-Imidazo[4,5-C]pyridin-4-Yl}-2-Methylbut-3-Yn-2-Ol",
      "J-502225",
      "C21H27N7O3",
    ],
  },
  {
    primary_id: "D0MS4R",
    names: ["Oxindole 95"],
  },
  {
    primary_id: "D0MS4Z",
    names: ["N-(3,3-Dimethyl-Butyl)-4-Hexyloxy-Benzamide", "CHEMBL122341", "ZINC13864671", "BDBM50106166"],
  },
  {
    primary_id: "D0MS6B",
    names: ["LOR 253"],
  },
  {
    primary_id: "D0MS7G",
    names: ["3-Phenyl Propanoic Derivative 2"],
  },
  {
    primary_id: "D0MS8T",
    names: [
      "IRL-1722",
      "CHEMBL418898",
      "BDBM50287880",
      "(S)-3-(1H-Indol-3-Yl)-2-[(2-Phenyl-Cyclopropanecarbonyl)-Amino]-Propionic Acid",
    ],
  },
  {
    primary_id: "D0MT0A",
    names: ["CCX915"],
  },
  {
    primary_id: "D0MT0M",
    names: [
      "Apraclonidine",
      "Aplonidine",
      "Apraclonidina",
      "Apraclonidinum",
      "Iopidine",
      "Iopidine Eye",
      "ALO 2145",
      "Apraclonidina [INN-Spanish]",
      "Apraclonidine (INN)",
      "Apraclonidine [INN:BAN]",
      "Apraclonidinum [INN-Latin]",
      "Iopidine (TN)",
      "P-Aminoclonidine",
      "Para-Aminoclonidine",
      "2,6-Dichloro-1-N-(4,5-Dihydro-1H-Imidazol-2-Yl)Benzene-1,4-Diamine",
      "2,6-Dichloro-N(1)-(4,5-Dihydro-1H-Imidazol-2-Yl)Benzene-1,4-Diamine",
      "2-(4-Amino-2,6-Dichloroanilino)-2-Imidazoline Hydrochloride",
      "2-(4-Amino-2,6-Dichloro)Phenyliminoimidazolidine",
      "4-Aminoclonidine",
    ],
  },
  {
    primary_id: "D0MT1N",
    names: ["N-Ethyl-2-Methylnorapomorphine Hydrochloride", "CHEMBL562460"],
  },
  {
    primary_id: "D0MT1Q",
    names: [
      "K-201",
      "DKKLXCRMAXNIJF-UHFFFAOYSA-N",
      "UNII-0I621Y6R4Q",
      "K201",
      "0I621Y6R4Q",
      "1038410-88-6",
      "K 201",
      "SCHEMBL194018",
      "CHEMBL2440857",
      "DTXSID90146108",
      "K-201, Moberg",
      "Dermatological Drug Combination (Kaprolac, Atopic Dermatitis), Moberg",
      "Urea + PEG/Propylene Glycol Formulation (Atopic Dermatitis), Moberg",
      "JTV 519",
    ],
  },
  {
    primary_id: "D0MT3A",
    names: ["Human Retinal Progenitor Cells"],
  },
  {
    primary_id: "D0MT3J",
    names: ["Benzimidazole Derivative 4"],
  },
  {
    primary_id: "D0MT4C",
    names: ["PMID29338548-Compound-48"],
  },
  {
    primary_id: "D0MT5C",
    names: [
      "L-742791",
      "CHEMBL276257",
      "SCHEMBL289708",
      "GTPL3467",
      "Compound 3 [PMID:9873496]",
      "BDBM50070152",
      "L742791",
      "L 742791",
      "L-742,791",
      "N-[4-[2-[[(2S)-2-Hydroxy-3-(4-Hydroxyphenoxy)Propyl]amino]ethyl]phenyl]-4-Iodobenzenesulfonamide",
    ],
  },
  {
    primary_id: "D0MT5K",
    names: ["XMT-1191"],
  },
  {
    primary_id: "D0MT5U",
    names: ["DopaFuse"],
  },
  {
    primary_id: "D0MT6D",
    names: ["PMID26666989-Compound-Figure9middle13"],
  },
  {
    primary_id: "D0MT7T",
    names: ["PMID25522065-Compound-49"],
  },
  {
    primary_id: "D0MT7W",
    names: ["NN8210"],
  },
  {
    primary_id: "D0MT8G",
    names: ["FKB01MD"],
  },
  {
    primary_id: "D0MT9G",
    names: [
      "RO-48-8684",
      "3-[5-(Dipropylaminomethyl)Oxazol-2-Yl]-8-Fluoro-5-Methyl-5,6-Dihydro-4H-Imidazo[1,5-A][1,4]benzodiazepin-6-One",
    ],
  },
  {
    primary_id: "D0MT9X",
    names: [
      "Calcium Dobesilate",
      "Calciumdobesilat",
      "Calcii Dobesilas",
      "Calcium Doxybenzylate",
      "Dobesilate Calcium",
      "CLS2210",
    ],
  },
  {
    primary_id: "D0MU0S",
    names: ["EM-800"],
  },
  {
    primary_id: "D0MU1F",
    names: ["XTL-6865"],
  },
  {
    primary_id: "D0MU1V",
    names: ["AG-26", "AG26", "AG 26"],
  },
  {
    primary_id: "D0MU3S",
    names: ["PMID25666693-Compound-47"],
  },
  {
    primary_id: "D0MU4A",
    names: ["Spiroxatrine"],
  },
  {
    primary_id: "D0MU7C",
    names: ["PMID2231594C3j"],
  },
  {
    primary_id: "D0MU7N",
    names: ["123I-MNI-330"],
  },
  {
    primary_id: "D0MU7U",
    names: ["Carbamates"],
  },
  {
    primary_id: "D0MU9L",
    names: [
      "Migalastat",
      "DDIG",
      "D-Galactitol, 1,5-Dideoxy-1,5-Imino",
      "(2R,3S,4R,5S)-2-(Hydroxymethyl)Piperidine-3,4,5-Triol",
      "1,5-Dideoxy-1,5-Iminogalactitol",
      "2-(Hydroxymethyl)-3,4,5-Piperidinetriol Hydrochloride",
    ],
  },
  {
    primary_id: "D0MV0T",
    names: [
      "2,3-Dihydropyrrolo[2,1-B]quinazolin-9(1H)-Imine",
      "CHEMBL214856",
      "SCHEMBL3385589",
      "Pyrrolo[2,1-B]quinazolin-9(1H)-Imine, 2,3-Dihydro-",
      "BDBM50194096",
      "745837-16-5",
    ],
  },
  {
    primary_id: "D0MV0W",
    names: ["GNF-PF-600"],
  },
  {
    primary_id: "D0MV3E",
    names: ["Aryl Pyrimidine Derivative 4"],
  },
  {
    primary_id: "D0MV3Q",
    names: ["Axovan-3"],
  },
  {
    primary_id: "D0MV4T",
    names: ["CD19.CAR T Cells"],
  },
  {
    primary_id: "D0MV4W",
    names: ["RUBOXISTAURIN HYDROCHLORIDE"],
  },
  {
    primary_id: "D0MW0N",
    names: ["Masitinib"],
  },
  {
    primary_id: "D0MW1M",
    names: ["MPGES-1 Inhibitors"],
  },
  {
    primary_id: "D0MW3H",
    names: [
      "CIPEMASTAT",
      "Cipemastat",
      "Trocade",
      "Ro 32-3555",
      "190648-49-8",
      "UNII-02HQ4TYQ60",
      "02HQ4TYQ60",
      "GFUITADOEPNRML-SJORKVTESA-N",
      "Cipemastat [USAN:INN]",
      "Ro 32-3555/000",
      "Trocade (TN)",
      "Cipemastat (USAN/INN)",
      "GTPL6466",
      "CHEMBL115653",
      "SCHEMBL7088217",
      "CTK8F1312",
      "BDBM30344",
      "MolPort-023-276-601",
      "ZINC600699",
      "(AlphaR,betaR)-Beta-(Cyclopentylmethyl)-Gamma-Oxo-Alpha-((3,4,4-Piperidinebutyrohydroxamic Acid",
      "AKOS024457333",
      "HY-19677",
      "Ro-32-03555",
      "Ro-323555000",
      "RT-015421",
      "RO32-3555",
      "CS-0016192",
      "D03517",
      "J-012306",
    ],
  },
  {
    primary_id: "D0MW5Z",
    names: ["2-Methyl-6-(Trifluoromethyl)-9H-Carbazole", "CHEMBL1170659", "SCHEMBL13772410"],
  },
  {
    primary_id: "D0MW7B",
    names: ["PF-4494700"],
  },
  {
    primary_id: "D0MW7D",
    names: ["PMID25991433-Compound-Eb"],
  },
  {
    primary_id: "D0MW7H",
    names: ["CMI-1145", "AKOS014861624"],
  },
  {
    primary_id: "D0MW7L",
    names: ["Heteroaryl-Carboxamide Derivative 7"],
  },
  {
    primary_id: "D0MW7S",
    names: ["Lexatumumab"],
  },
  {
    primary_id: "D0MX0Q",
    names: ["PMID28454500-Compound-35"],
  },
  {
    primary_id: "D0MX0Z",
    names: ["(R)-2-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Phenol", "CHEMBL404834"],
  },
  {
    primary_id: "D0MX1T",
    names: ["Methyl-(1,2,3,4-Tetrahydro-Naphthalen-1-Yl)-Amine"],
  },
  {
    primary_id: "D0MX3Y",
    names: ["PMID25470667-Compound-AZD3988"],
  },
  {
    primary_id: "D0MX5B",
    names: ["Indazole Derivative 5"],
  },
  {
    primary_id: "D0MX5N",
    names: ["ASCT01"],
  },
  {
    primary_id: "D0MX5S",
    names: ["Enoxaparin"],
  },
  {
    primary_id: "D0MX6K",
    names: ["Crysvita"],
  },
  {
    primary_id: "D0MX7B",
    names: ["Carboxamide Derivative 4"],
  },
  {
    primary_id: "D0MX7C",
    names: ["GBT-101 Topical Gel"],
  },
  {
    primary_id: "D0MY0E",
    names: ["Orexin-B"],
  },
  {
    primary_id: "D0MY0F",
    names: ["MGD013"],
  },
  {
    primary_id: "D0MY0M",
    names: ["15-DEOXYBUDLEIN A", "15-Deoxybudlein A", "Atripliciolide Angelate"],
  },
  {
    primary_id: "D0MY0R",
    names: ["[2-(6-Chloro-Purin-9-Yl)Ethyl]-Bisphosphonic Acid", "CHEMBL452354", "SCHEMBL3983260"],
  },
  {
    primary_id: "D0MY1A",
    names: ["Phenylmercury"],
  },
  {
    primary_id: "D0MY1U",
    names: ["Intanza"],
  },
  {
    primary_id: "D0MY2Y",
    names: ["Xestospongin C"],
  },
  {
    primary_id: "D0MY3C",
    names: ["AR08"],
  },
  {
    primary_id: "D0MY3K",
    names: ["PMID25666693-Compound-109"],
  },
  {
    primary_id: "D0MY6Q",
    names: ["Vibrilase"],
  },
  {
    primary_id: "D0MY7F",
    names: [
      "BB3",
      "3-(3,5-Dibromo-4-Hydroxybenzoyl)-2-Ethyl-N,N-Dimethylbenzofuran-6-Sulfonamide",
      "765317-71-3",
      "3-(3,5-DIBROMO-4-HYDROXY-BENZOYL)-2-ETHYL-BENZOFURAN-6-SULFONIC ACID DIMETHYLAMIDE",
      "3-(3,5-Dibromo-4-Hydroxybenzoyl)-2-Ethyl-N,N-Dimethyl-1-Benzofuran-6-Sulfonamide",
      "SCHEMBL4320310",
      "AC1L9M79",
      "1t48",
      "ZINC14881085",
      "AKOS025404898",
      "DB04142",
      "AK174884",
      "N,N-Dimethyl-2-Ethyl-3-(3,5-Dibromo-4-Hydroxybenzoyl)Benzofuran-6-Sulfonamide",
      "3-(3,5-Dibromo-4-Hydroxybenzoyl)-2-Ethyl-N,N-Dimethylbenzofuran -6-Sulfonamide",
    ],
  },
  {
    primary_id: "D0MY7X",
    names: ["Esonarimod"],
  },
  {
    primary_id: "D0MY8N",
    names: [
      "Beta-Carotene",
      "7235-40-7",
      "Beta Carotene",
      "Beta,beta-Carotene",
      "Betacarotene",
      "Provitamin A",
      "Solatene",
      "Carotaben",
      "Provatene",
      "All-Trans-Beta-Carotene",
      "Serlabo",
      "Natural Yellow 26",
      "Karotin",
      "KPMK",
      "C.I. Food Orange 5",
      "Solatene (Caps)",
      "Karotin [Czech]",
      "Food Orange 5",
      "Zlut Prirodni 26",
      "Betacarotenum [Latin]",
      "Betacaroteno [Spanish]",
      "Lucaratin",
      "BetaVit",
      "Beta-Karotin",
      "Beta-Carotin",
      "Beta;-Carotene",
      "Beta-Carotene, All-Trans-",
      ".beta. Carotene",
      ".beta.-Carotene",
      "Betacarotenum [INN-Latin]",
      "C.I. 75130",
      "Betacaroteno [INN-Spanish]",
      "Zlut Prirodni 26 [Czech]",
    ],
  },
  {
    primary_id: "D0MY8V",
    names: [
      "6-(3,5-Difluoro-4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL191716",
      "SCHEMBL3934682",
      "DZGRIHNLLUZLTL-UHFFFAOYSA-N",
      "BDBM50168366",
      "ZINC13645080",
    ],
  },
  {
    primary_id: "D0MZ2A",
    names: [
      "CTOP",
      "Ctop-NH2",
      "CTOPA",
      "CHEMBL507214",
      "103429-31-8",
      "AC1NSJX5",
      "H-D-Phe-C[Cys-Tyr-DTrp-Orn-Thr-Pen]-Thr-NH2",
      "GTPL1674",
      "CTOP, &gt",
      "BDBM50252861",
      "(4R,7S,10S,13R,16S,19R)-N-[(2S,3R)-1-Amino-3-Hydroxy-1-Oxobutan-2-Yl]-19-[[(2R)-2-Amino-3-Phenylpropanoyl]amino]-10-(3-Aminopropyl)-7-[(1R)-1-Hydroxyethyl]-16-[(4-Hydroxyphenyl)Methyl]-13-(1H-Indol-3-Ylmethyl)-3,3-Dimethyl-6,9,12,15,18-Pentaoxo-1,2-Dithia-5,8,11,14,17-Pentazacycloicosane-4-Carboxamide",
    ],
  },
  {
    primary_id: "D0MZ2B",
    names: ["IODOSTEARIC ACID", "9-(Or 10)-Iodooctadecanoic Acid", "9-(Or 10)-Iodostearic Acid"],
  },
  {
    primary_id: "D0MZ4V",
    names: ["1,2,4-Oxadiazole Derivative 1"],
  },
  {
    primary_id: "D0MZ7C",
    names: ["Isovelleral"],
  },
  {
    primary_id: "D0MZ7I",
    names: ["Indole-Based Analog 3"],
  },
  {
    primary_id: "D0MZ7K",
    names: ["Triazolo[4,3-B]pyridazine Derivative 2"],
  },
  {
    primary_id: "D0MZ8D",
    names: ["PMID28394193-Compound-39"],
  },
  {
    primary_id: "D0MZ8E",
    names: ["ISIS 183901"],
  },
  {
    primary_id: "D0MZ8J",
    names: ["PMID26651364-Compound-10d"],
  },
  {
    primary_id: "D0MZ9A",
    names: ["Suronacrine Maleate"],
  },
  {
    primary_id: "D0MZ9D",
    names: [
      "2-Propionyl-Cyclohexane-1,3-Dione",
      "CHEMBL166694",
      "104775-30-6",
      "1,3-Cyclohexanedione, 2-(1-Oxopropyl)-",
      "SCHEMBL8639644",
      "DTXSID50448337",
      "DVLHCJHTXAHANV-UHFFFAOYSA-N",
      "2-Propanoyl-1,3-Cyclohexanedione",
      "2-Propionylcyclohexane-1,3-Dione",
      "BDBM50088802",
      "AKOS006243102",
    ],
  },
  {
    primary_id: "D0MZ9P",
    names: ["PMID28394193-Compound-10"],
  },
  {
    primary_id: "D0MZ9Q",
    names: ["Bile Acid"],
  },
  {
    primary_id: "D0N0AJ",
    names: [
      "Double Mutant Heat-Labile Toxin",
      "DmLT",
      "LT(R192G/L211A)",
      "Double Mutant Heat-Labile Toxin (Recombinant Vaccine, ETEC Infection)",
      "Double Mutant Heat-Labile Toxin (Recombinant Vaccine, ETEC Infection), NIAID/PATH/Tulane University",
    ],
  },
  {
    primary_id: "D0N0AR",
    names: [
      "GPI-1485",
      "186268-78-0",
      "UNII-0709BVY57W",
      "(2S)-1-(3,3-Dimethyl-2-Oxopentanoyl)Pyrrolidine-2-Carboxylic Acid",
      "(2S)-1-(1,2-DIOXO-3,3-DIMETHYLPENTYL)-2-PYRROLIDINECARBOXYLIC ACID",
      "0709BVY57W",
      "(S)-1-(3,3-Dimethyl-2-Oxopentanoyl)Pyrrolidine-2-Carboxylic Acid",
      "L-Proline, 1-(3,3-Dimethyl-1,2-Dioxopentyl)-",
      "GPI 1485",
      "SCHEMBL2006950",
      "CTK6C7007",
      "FOPALECPEUVCTL-QMMMGPOBSA-N",
      "MolPort-023-219-569",
      "ZX-RL003437",
      "1-(1,2-Dioxo-3,3-Dimethylpentyl)-2-Pyrrolidine Carboxylic Acid, 2S",
      "ZINC33979157",
      "6256AD",
      "AKOS015991368",
      "DB05814",
      "EG-0058",
    ],
  },
  {
    primary_id: "D0N0BI",
    names: ["SIBRAFIBAN"],
  },
  {
    primary_id: "D0N0BN",
    names: ["(3-Tetradecylamino-Cyclopentyl)-Phosphonic Acid", "CHEMBL184591"],
  },
  {
    primary_id: "D0N0BW",
    names: ["ALD-518"],
  },
  {
    primary_id: "D0N0DP",
    names: ["FB-1811"],
  },
  {
    primary_id: "D0N0DU",
    names: ["DS-6051"],
  },
  {
    primary_id: "D0N0EB",
    names: ["P-17 Ophthalmic"],
  },
  {
    primary_id: "D0N0ED",
    names: ["NTE-2"],
  },
  {
    primary_id: "D0N0EQ",
    names: [
      "Streptomycin",
      "Agrept",
      "Agrimycin",
      "Gerox",
      "Neodiestreptopab",
      "SRY",
      "Strepcen",
      "Streptomicina",
      "Streptomycine",
      "Streptomycinum",
      "Streptomyzin",
      "Liposomal Streptomycin",
      "Streptomicina [Italian]",
      "Streptomycin A",
      "Streptomycin A Sulfate",
      "Streptomycin Sesquisulfate Hydrate",
      "Streptomycin Sulfate",
      "Streptomycin Sulphate",
      "Streptomyzin [German]",
      "Agrept (TN)",
      "Estreptomicina [INN-Spanish]",
      "Hokko-Mycin",
      "Plantomycin (TN)",
      "Rimosidin (TN)",
      "Streptomycin & EEP",
      "Streptomycin & Propolis",
      "Streptomycin (INN)",
      "Streptomycin (TN)",
      "Streptomycin [INN:BAN]",
      "Streptomycin, Sulfate Salt",
      "AS-50 (TN)",
      "STREPTOMYCIN SULFATE (2:3) SALT",
      "Agri-Mycin-17 (TN)",
      "O-2-Deoxy-2-(Methylamino)-.alpha.-L-Glucopyranosyl-(1->2)-O-5-Deoxy-3-C-Formyl-.alpha.-L-Lyxofuranosyl-(1->4)-N,N'-Bis(Aminoiminomethyl)-D-Streptamine And Liposome",
      "N,N'''-[(1R,2R,3S,4R,5R,6S)-4-{5-Deoxy-2-O-[2-Deoxy-2-(Methylamino)-Alpha-L-Glucopyranosyl]-3-C-Formyl-Alpha-L-Lyxofuranosyloxy}-2,5,6-Trihydroxycyclohexane-1,3-Diyl]diguanidine",
      "N,N'''-[(1R,2R,3S,4R,5R,6S)-4-({5-Deoxy-2-O-[2-Deoxy-2-(Methylamino)-Alpha-L-Glucopyranosyl]-3-C-Formyl-Alpha-L-Lyxofuranosyl}oxy)-2,5,6-Trihydroxycyclohexane-1,3-Diyl]diguanidine",
      "2,4-Diguanidino-3,5,6-Trihydroxycyclohexyl 5-Deoxy-2-O-(2-Deoxy-2-Methylamino-Alpha-L-Glucopyranosyl)-3-C-Formyl-Beta-L-Lyxopentanofuranoside",
      "2-[(1R,2R,3S,4R,5R,6S)-3-(Diaminomethylideneamino)-4-[(2R,3R,4R,5S)-3-[(2S,3S,4S,5R,6S)-4,5-Dihydroxy-6-(Hydroxymethyl)-3-(Methylamino)Oxan-2-Yl]oxy-4-Formyl-4-Hydroxy-5-Methyloxolan-2-Yl]oxy-2,5,6-Trihydroxycyclohexyl]guanidine",
      "2-[(1R,2R,3S,4R,5R,6S)-3-(Diaminomethylideneamino)-4-[(2S,3S,4S,5R)-3-[(2R,3R,4R,5S,6R)-4,5-Dihydroxy-6-(Hydroxymethyl)-3-(Methylamino)Oxan-2-Yl]oxy-4-Formyl-4-Hydroxy-5-Methyloxolan-2-Yl]oxy-2,5,6-Trihydroxycyclohexyl]guanidine",
      "2-[(1S,2S,3R,4S,5S,6R)-3-(Diaminomethylideneamino)-4-[(2R,3R,4R,5S)-3-[(2S,3S,4S,5R,6S)-4,5-Dihydroxy-6-(Hydroxymethyl)-3-(Methylamino)Oxan-2-Yl]oxy-4-Formyl-4-Hydroxy-5-Methyloxolan-2-Yl]oxy-2,5,6-Trihydroxycyclohexyl]guanidine",
      "2-[(1S,4S)-5-(Diaminomethylideneamino)-2-[(2R,5S)-3-[(2S,5R)-4,5-Dihydroxy-6-(Hydroxymethyl)-3-(Methylamino)Oxan-2-Yl]oxy-4-Formyl-4-Hydroxy-5-Methyloxolan-2-Yl]oxy-3,4,6-Trihydroxycyclohexyl]guanidine",
      "[2-Deoxy-2-(Dimethylamino)-Alpha-L-Glucopyranosyl]-(1->2)-[5-Deoxy-3-C-Formyl-Alpha-L-Lyxofuranosyl]-(1->4)-{N',N'''-[(1,3,5/2,4,6)-2,4,5,6-Tetrahydroxycyclohexane-1,3-Diyl]diguanidine}",
    ],
  },
  {
    primary_id: "D0N0ES",
    names: ["Vitamin B3"],
  },
  {
    primary_id: "D0N0FA",
    names: ["PMID25435285-Compound-51"],
  },
  {
    primary_id: "D0N0FX",
    names: ["3-(4-Methoxy-Benzenesulfonyl)-Cyclohexanethiol", "CHEMBL295066", "SCHEMBL7208466"],
  },
  {
    primary_id: "D0N0GE",
    names: ["L-Threonine"],
  },
  {
    primary_id: "D0N0GJ",
    names: [
      "Diphenyleneiodonium Chloride",
      "4673-26-1",
      "Dibenziodolium Chloride",
      "UNII-7M9D81YZ2N",
      "DPI",
      "7M9D81YZ2N",
      "CHEBI:77967",
      "CHEMBL397686",
      "SR-01000075494",
      "Dibenziodolium, Chloride",
      "AC1MBZ20",
      "C12H8I.Cl",
      "Dibenzo[b,d]iodolium Chloride",
      "SCHEMBL159318",
      "GTPL7802",
      "CTK8F9249",
      "DTXSID00196932",
      "MolPort-003-983-758",
      "MolPort-023-275-892",
      "HMS3266G10",
      "HMS3261I15",
      "2,2'-Biphenylyleneiodonium Chloride",
      "Diphenyleneiodonium Chloride, 97+%",
      "BCP08692",
      "Tox21_500367",
      "Diphenyleneiodonium Chloride, &gt",
      "MFCD00214165",
      "1726AH",
      "AKOS015903219",
      "LP00367",
      "CCG-221671",
    ],
  },
  {
    primary_id: "D0N0GT",
    names: ["3-Benzyloxy-2-Phenyl-Piperidine", "CHEMBL100178", "SCHEMBL9023808"],
  },
  {
    primary_id: "D0N0GX",
    names: ["(E)-4-(2-(Thiophen-3-Yl)Vinyl)Benzenesulfonamide", "CHEMBL1288802"],
  },
  {
    primary_id: "D0N0HK",
    names: ["3,4,5,6-Tetrachloro-[1,2]benzoquinone"],
  },
  {
    primary_id: "D0N0HO",
    names: ["SX-RDS1"],
  },
  {
    primary_id: "D0N0IC",
    names: ["N-(3,5-Dichlorophenyl)-4-Methoxy-3-Nitrobenzamide"],
  },
  {
    primary_id: "D0N0IQ",
    names: ["Ro-27-3225"],
  },
  {
    primary_id: "D0N0IV",
    names: ["VERAGUENSIN"],
  },
  {
    primary_id: "D0N0JC",
    names: ["RTI-5989-23"],
  },
  {
    primary_id: "D0N0JE",
    names: ["UP-275-22"],
  },
  {
    primary_id: "D0N0JL",
    names: ["Diphosphoric Acid"],
  },
  {
    primary_id: "D0N0KG",
    names: ["CDRI-85/92"],
  },
  {
    primary_id: "D0N0KO",
    names: ["CCNCSSKWCRDHSRCC"],
  },
  {
    primary_id: "D0N0KR",
    names: ["Emakalim"],
  },
  {
    primary_id: "D0N0KW",
    names: ["D-Methionine", "D-Methionine-(Methyl-13C)", "D-Methionine-(Methyl-13C), 99 Atom % 13C"],
  },
  {
    primary_id: "D0N0LB",
    names: ["Zoxazolamine"],
  },
  {
    primary_id: "D0N0LN",
    names: ["RN486"],
  },
  {
    primary_id: "D0N0LZ",
    names: [
      "3-Naphthalen-1-Yl-Pyrrolidine",
      "3-(1-NAPHTHYL)PYRROLIDINE",
      "178483-14-2",
      "3-(Naphthalen-1-Yl)Pyrrolidine",
      "CHEMBL311972",
      "SCHEMBL8878356",
      "CTK5J7733",
      "BDBM50290933",
      "5967AD",
      "AKOS011343953",
    ],
  },
  {
    primary_id: "D0N0ME",
    names: [
      "4-Benzhydryl-N-Butylpiperazine-1-Carboxamide",
      "CHEMBL1087032",
      "681801-59-2",
      "N-Butyl-4-(Diphenylmethyl)Piperazine-1-Carboxamide",
      "(4-(DIPHENYLMETHYL)PIPERAZINYL)-N-BUTYLFORMAMIDE",
      "AC1MDDX7",
      "AC1Q2X1X",
      "MLS001181725",
      "MolPort-001-841-442",
      "HMS2858M18",
      "HMS1662P18",
      "KS-000029IQ",
      "BDBM50312580",
      "ZINC19924797",
      "AKOS022169771",
      "MS-8361",
      "MCULE-5902192289",
    ],
  },
  {
    primary_id: "D0N0MV",
    names: ["Fused Heterocyclic Compound 8"],
  },
  {
    primary_id: "D0N0MY",
    names: ["ISIS 3308"],
  },
  {
    primary_id: "D0N0MZ",
    names: ["ITL-1636"],
  },
  {
    primary_id: "D0N0NJ",
    names: [
      "1-Benzyl-APDC",
      "CHEMBL60238",
      "171336-76-8",
      "(2R,4R)-1-BENZYL-4-AMINO-PYRROLIDINE-2,4-DIcarboxylic Acid",
      "GTPL1436",
      "SCHEMBL8061968",
      "CTK0A8043",
      "DTXSID60573467",
      "ZINC39952128",
      "BDBM50071161",
      "DB-064818",
      "AJ-101168",
      "FT-0772028",
      "4-Amino-1-(Phenylmethyl)Pyrrolidine-2,4-Dicarboxylic Acid",
      "(2R,4R)-4-Amino-1-Benzylpyrrolidine-2,4-Dicarboxylic Acid",
      "(2R,4R)-4-Amino-1-Benzyl-Pyrrolidine-2,4-Dicarboxylic Acid",
      "(2R)-1-Benzyl-4-Aminopyrrolidine-2beta,4alpha-Dicarboxylic Acid",
      "4-Amino-1-Benzyl-Pyrrolidine-2,4-Dicarboxylic Acid(1-Benzyl-APDC)",
    ],
  },
  {
    primary_id: "D0N0OE",
    names: ["7-(3,6,9,12-Tetraoxatricos-22-Enyl)Theophylline", "CHEMBL591242"],
  },
  {
    primary_id: "D0N0OG",
    names: ["TAK-361S"],
  },
  {
    primary_id: "D0N0OS",
    names: ["Vaccine, Yersinia Pestis Infection"],
  },
  {
    primary_id: "D0N0OU",
    names: ["Deferiprone"],
  },
  {
    primary_id: "D0N0OV",
    names: ["Retroviral Vector-Transduced Autologous T Cells To Express Anti-GPC3 CARs"],
  },
  {
    primary_id: "D0N0OY",
    names: ["2-(4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D0N0PI",
    names: [
      "2-NAPHTHALENESULFONIC ACID",
      "120-18-3",
      "Beta-Naphthylsulfonic Acid",
      "Beta-Naphthalenesulfonic Acid",
      "Naphthalene-2-Sulphonic Acid",
      "2-Naphthalene Sulfonic Acid",
      "Kyselina 2-Naftalensulfonova",
      "UNII-O9S4K2S25E",
      "2-Naphthylsulfonic Acid",
      "EINECS 204-375-3",
      "Kyselina 2-Naftalensulfonova [Czech]",
      "BRN 1955756",
      "AI3-18435",
      "O9S4K2S25E",
      "CHEBI:44229",
      "KVBGVZZKJNLNJU-UHFFFAOYSA-N",
      "MFCD00004089",
      "2-Naphthalenesulfonic Acid, Monohydrate",
      "W-109067",
      "2-Sulfonaphthalene",
      "2-Naphthalenesulfonic",
      "PubChem19963",
      "AC1L1QYN",
      "AC1Q6WYB",
    ],
  },
  {
    primary_id: "D0N0PU",
    names: ["LTB 019"],
  },
  {
    primary_id: "D0N0PW",
    names: ["IDP-113"],
  },
  {
    primary_id: "D0N0QC",
    names: ["GV1001"],
  },
  {
    primary_id: "D0N0QN",
    names: ["PMID25666693-Compound-146"],
  },
  {
    primary_id: "D0N0RN",
    names: ["Marizomib"],
  },
  {
    primary_id: "D0N0RU",
    names: [
      "Bexarotene",
      "Bexaroteno",
      "Bexarotenum",
      "Targret",
      "Targretin",
      "Targretyn",
      "Targrexin",
      "Bexarotene [USAN]",
      "Elan Brand Of Bexarotene;Ligand Brand Of Bexarotene",
      "LG 1069",
      "LG100069",
      "LG1069",
      "LG69 Compound",
      "LGD 1069",
      "LGD1069",
      "LG-100069",
      "LGD-1069",
      "Targretin (TN)",
      "Targretin-Gel",
      "Bexarotene (USAN/INN)",
      "P-(1-(5,6,7,8-Tetrahydro-3,5,5,8,8-Pentamethyl-2-Naphthyl)Vinyl)Benzoic Acid",
      "3-Methyl-TTNEB",
      "4-(1-(3,5,5,8,8-Pentamethyl-5,6,7,8-Tetrahydro-2-Naphthyl)Ethenyl)Benzoic Acid",
      "4-(1-(5,6,7,8-Tetrahydro-3,5,5,8,8-Pentamethyl-2-Naphthalenyl)Ethenyl)Benzoic Acid",
      "4-[1-(3,5,5,8,8-Pentamethyl-5,6,7,8-Tetrahydronaphthalen-2-Yl)Ethenyl]benzoic Acid",
      "4-[1-(3,5,5,8,8-Pentamethyl-5,6,7,8-Tetrahydronaphthalen-2-Yl)Vinyl]benzoic Acid",
      "4-[1-(3,5,5,8,8-Pentamethyl-6,7-Dihydronaphthalen-2-Yl)Ethenyl]benzoic Acid",
      "4-[1-3,5,5,8,8-Pentamethyl-5,6,7,8-Tetrahydro-2-Naphthaleneyl)Vinyl]benzene Carboxylic Acid",
      "9RA",
    ],
  },
  {
    primary_id: "D0N0SB",
    names: ["BOF-12013"],
  },
  {
    primary_id: "D0N0SC",
    names: ["AIKb2"],
  },
  {
    primary_id: "D0N0SN",
    names: ["RWJ-68141"],
  },
  {
    primary_id: "D0N0SO",
    names: [
      "Autosomal Dominant Retinitis Pigmentosa",
      "Autosomal Dominant Retinitis Pigmentosa, Applied Genetic Technologies",
      "RAAV2/5-MOP-RHO310-SiRNA301",
    ],
  },
  {
    primary_id: "D0N0TF",
    names: ["PMID25666693-Compound-156"],
  },
  {
    primary_id: "D0N0TQ",
    names: [
      "2-Aminomethyl-5-(Phenyl)Tetrahydrofuran",
      "(5-Phenyloxolan-2-Yl)Methanamine",
      "CHEMBL458639",
      "878661-56-4",
      "SCHEMBL2690113",
      "MolPort-035-929-309",
    ],
  },
  {
    primary_id: "D0N0TS",
    names: ["PSAMMAPLIN A"],
  },
  {
    primary_id: "D0N0TZ",
    names: [
      "Clopidogrel",
      "113665-84-2",
      "(S)-Clopidogrel",
      "Plavix",
      "Clopidogrel [INN:BAN]",
      "(+)-(S)-Clopidogrel",
      "(+)-Clopidogrel",
      "UNII-A74586SNO7",
      "HSDB 7430",
      "CLOPIDOGREL SULFATE",
      "CHEBI:37941",
      "GKTWGGQPFAXNFI-HNNXBMFYSA-N",
      "A74586SNO7",
      "CPD000550475",
      "Zyllt",
      "Clopidogrel (TN)",
      "SR-25990C",
      "Plavix (TN)",
      "Methyl (2S)-(2-Chlorophenyl)(6,7-Dihydrothieno[3,2-C]pyridin-5(4H)-Yl)Ethanoate",
      "Clopidogrelum",
      "Thrombo",
      "Clopidogrel BMS",
      "Clopidogrel Hexal",
      "Clopidogrel Acino",
      "Methyl (2S)-2-(2-Chlorophenyl)-2-{4H,5H,6H,7H-Thieno[3,2-C]pyridin-5-Yl}acetate",
      "PM-103",
      "Clopidogrel (Captisol-Enabled)",
      "Clopidogrel (Captisol-Enabled), The Medicines Co",
      "Clopidogrel (Captisol-Enabled, Cardiovascular Disease), Cydex",
      "Clopidogrel (Captisol-Enabled, Cardiovascular Disease), Prism Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0N0UC",
    names: ["Lauric Acid"],
  },
  {
    primary_id: "D0N0UY",
    names: ["AZD1704"],
  },
  {
    primary_id: "D0N0VC",
    names: ["Spliceosome Inhibitors"],
  },
  {
    primary_id: "D0N0VW",
    names: ["IDN-5174", "Camptothecin Analogs (Anticancer)", "Camptothecin Analogs (Anticancer), Indena"],
  },
  {
    primary_id: "D0N0WE",
    names: ["PMID25470667-Compound-Amidepsines"],
  },
  {
    primary_id: "D0N0WX",
    names: [
      "PhageBioDerm",
      "Bacteriophage Infused Polymer Matrix (Bacterial Infection), Intralytix",
      "Bacteriophage Infused Polymer Matrix (Bacterial Infection), Research Center For Medical Polymers And Biomaterials",
    ],
  },
  {
    primary_id: "D0N0XA",
    names: ["Reversed Chloroquines"],
  },
  {
    primary_id: "D0N0XU",
    names: ["Pyrazole Derivative 29"],
  },
  {
    primary_id: "D0N0YE",
    names: ["Isoquinoline Sulfonamide Derivative 2"],
  },
  {
    primary_id: "D0N0YS",
    names: ["(11H-Dibenzo[b,e][1,4]dioxepin-2-Yl)-Acetic Acid", "CHEMBL33378", "ZINC29221898"],
  },
  {
    primary_id: "D0N0YT",
    names: ["AP-10295"],
  },
  {
    primary_id: "D0N0YX",
    names: [
      "Beta-(2-Naphthyl)-Alanine",
      "58438-03-2",
      "3-(2-Naphthyl)-L-Alanine",
      "H-2-Nal-OH",
      "(S)-2-Amino-3-(Naphthalen-2-Yl)Propanoic Acid",
      "L-2-Naphthylalanine",
      "BETA-(2-NAPHTHYL)-ALANINE",
      "UNII-W425Q6KV9R",
      "L-3-(2-Naphthyl)-Alanine",
      "(2S)-2-Amino-3-(2-Naphthyl)Propanoic Acid",
      "(S)-2-Amino-3-Naphthalen-2-Yl-Propionic Acid",
      "W425Q6KV9R",
      "NAL",
      "(2S)-2-Amino-3-Naphthalen-2-Ylpropanoic Acid",
      "(2S)-2-Amino-3-(Naphthalen-2-Yl)Propanoic Acid",
      "3-(2-Naphthyl)-L-Alanine, 98%",
      "3-(2-Naphthyl)Alanine",
      "Beta-2-Naphthyl-L-Alanine",
      "L-Beta-(2-Naphthyl)Alanine",
      "L-3-(2-NAPHTHYL)ALANINE",
    ],
  },
  {
    primary_id: "D0N0ZK",
    names: [
      "(4S)-4-Ammoniopentanoate",
      "(S)-4-Aminovaleric Acid",
      "(S)-4-Aminopentanoic Acid",
      "(4S)-4-Aminopentanoic Acid",
      "4S-Aminopentanoic Acid",
      "UNII-G98ZJ0KD1D",
      "G98ZJ0KD1D",
      "1558-59-4",
      "Pentanoic Acid, 4-Amino-, (S)-",
      "4-Aminovaleric Acid, (S)-",
      "AC1NSNZ5",
      "SCHEMBL288484",
      "CHEMBL284327",
      "ZINC3581353",
      "LMFA01100022",
      "Pentanoic Acid, 4-Amino-, (4S)-",
      "(2E,4S)-4-Aminopent-2-Enoic Acid",
      "AKOS027321168",
      "UNII-CDM626IVRT Component ABSTXSZPGHDTAF-BYPYZUCNSA-N",
    ],
  },
  {
    primary_id: "D0N1AH",
    names: ["AS-03", "DL-Alpha-Tocopherol + Squalene + Tween 80"],
  },
  {
    primary_id: "D0N1AO",
    names: ["H-Dmt-Tic-NH-CH2-Indn", "CHEMBL260127"],
  },
  {
    primary_id: "D0N1AS",
    names: ["NGD-4715"],
  },
  {
    primary_id: "D0N1AT",
    names: ["BIOO-1"],
  },
  {
    primary_id: "D0N1AW",
    names: [
      "6-(Aminomethyl)-2H-Chromen-2-One",
      "CHEMBL567139",
      "6-Aminomethylcoumarin",
      "927173-90-8",
      "6-(Aminomethyl)Coumarin",
      "BDBM50303497",
    ],
  },
  {
    primary_id: "D0N1AY",
    names: ["Metallothionein"],
  },
  {
    primary_id: "D0N1AZ",
    names: [
      "1-Methyl-4-P-Tolyl-Piperidine-4-Carbonitrile",
      "CHEMBL111498",
      "1-Methyl-4-(4-Methylphenyl)Piperidine-4-Carbonitrile",
      "ZINC13610036",
      "BDBM50083527",
    ],
  },
  {
    primary_id: "D0N1BC",
    names: ["Reveromycin A"],
  },
  {
    primary_id: "D0N1BF",
    names: ["LY-2811376"],
  },
  {
    primary_id: "D0N1CC",
    names: ["PMID25435285-Compound-42"],
  },
  {
    primary_id: "D0N1CF",
    names: ["Imidazo[1,2-B]pyridazine Derivative 3"],
  },
  {
    primary_id: "D0N1CG",
    names: ["PMID25399762-Compound-Figure1-Eryngiolide A"],
  },
  {
    primary_id: "D0N1CH",
    names: [
      "Arverapamil",
      "(R)-Norverapamil",
      "Agi-003",
      "UNII-3J8P56R04P",
      "(R)-(+)-Nor Verapamil Hydrochloride",
      "123932-43-4",
      "3J8P56R04P",
      "Rezular",
      "(+)-Norverapamil",
      "(R)-Desmethylverapamil",
      "(+)-Desmethylverapamil",
      "CHEBI:134082",
      "ZINC13492624",
      "AKOS030532539",
      "Benzeneacetonitrile, Alpha-(3-((2-(3,4-Dimethoxyphenyl)Ethyl)Amino)Propyl)-3,4-Dimethoxy-Alpha-(1-Methylethyl)-, (AlphaR)-",
      "UNII-957Z3K3R56 Component UPKQNCPKPOLASS-AREMUKBSSA-N",
    ],
  },
  {
    primary_id: "D0N1CK",
    names: ["1,1,1-Trifluoro-8-Phenyl-Octan-2-One", "1,1,1-Trifluoro-8-Phenyloctan-2-One"],
  },
  {
    primary_id: "D0N1CL",
    names: [
      "2-(3-(3-Bromophenyl)Ureido)-4-Chlorobenzoic Acid",
      "CHEMBL154700",
      "SCHEMBL6431740",
      "ZINC1491954",
      "BDBM50137125",
      "AKOS012857302",
      "2-[3-(3-Bromo-Phenyl)-Ureido]-4-Chloro-Benzoic Acid",
    ],
  },
  {
    primary_id: "D0N1DG",
    names: ["F61F12", "Anti-Fas MAb (Cancer), IMED"],
  },
  {
    primary_id: "D0N1DQ",
    names: [
      "ANQ-9040",
      "3alpha-Acetoxy-3beta-(Hexahydroazepinomethyl)-17a,17a-Dimethyl-17-Homo-17a-Azonia-5alpha-Androstane Benzenesulfonate",
    ],
  },
  {
    primary_id: "D0N1EC",
    names: ["WY-47766"],
  },
  {
    primary_id: "D0N1EF",
    names: [
      "DARGLITAZONE",
      "CP-86325",
      "Darglitazone < Rec INN",
      "Rac-5-[4-[3-(5-Methyl-2-Phenyloxazol-4-Yl)Propionyl]benzyl]thiazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0N1EW",
    names: [
      "DuP-532",
      "Dup-532",
      "Dup 532",
      "124750-95-4",
      "CHEMBL443269",
      "1H-Imidazole-5-Carboxylicacid,4-(1,1,2,2,2-Pentafluoroethyl)-2-Propyl-1-[[2'-(2H-Tetrazol-5-Yl)[1,1'-Biphenyl]-4-Yl]methyl]-",
      "Dup532",
      "ACMC-20mr6i",
      "SCHEMBL62",
      "AC1L1TVZ",
      "AC1Q4ICA",
      "CTK4B4065",
      "BDBM82428",
      "DTXSID00154476",
      "PDSP2_000123",
      "PDSP1_000124",
      "PDSP1_000123",
      "BDBM50230908",
      "2-Propyl-4-Pentafluoroethyl-1-((2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Yl)Methyl)Imidazole-5-Carboxylic Acid",
      "CAS_124750-95-4",
      "CB91356279",
      "L002873",
      "1H-Imidazole-5-Carboxylic Acid, 4-(Pentafluoroethyl)-2-Propyl-1-((2'-(1H-Te",
    ],
  },
  {
    primary_id: "D0N1EZ",
    names: ["SPC-3"],
  },
  {
    primary_id: "D0N1FR",
    names: [
      "BECATECARIN",
      "BMS-181176",
      "Becatecarin < USAN",
      "DEAE-Rebeccamycin",
      "XL-119",
      "BMY-27557-01 (HCl)",
      "BMY-27557-14 (Tartrate)",
      "1,11-Dichloro-6-[2-(Diethylamino)Ethyl]-12-(4-O-Methyl-Beta-D-Glucopyranosyl)-6,7,12,13-Tetrahydro-5H-Indolo[2,3-A]pyrrolo[3,4-C]carbazole-5,7-Dione",
    ],
  },
  {
    primary_id: "D0N1FS",
    names: [
      "Diacerein",
      "13739-02-1",
      "Diacetylrhein",
      "Diacerhein",
      "Fisiodar",
      "Artrodar",
      "1,8-DIACETOXY-3-CARBOXYANTHRAQUINONE",
      "DIACERIN",
      "4,5-Diacetylrhein",
      "Diacereinum [Latin]",
      "Diacereine [French]",
      "Diacereina [Spanish]",
      "UNII-4HU6J11EL5",
      "Diacerein [INN]",
      "SF-277",
      "C19H12O8",
      "EINECS 237-310-2",
      "BRN 2184909",
      "4,5-Diacetyloxy-9,10-Dioxoanthracene-2-Carboxylic Acid",
      "MLS000028577",
      "4HU6J11EL5",
      "1,8-Diacetoxyanthraquinone-3-Carboxylic Acid",
      "Diacerein (INN)",
      "4,5-Diacetyloxy-9,10-Dioxo-2-Anthracenecarboxylic Acid",
      "NCGC00018274-04",
      "SMR000058958",
      "AK468692",
    ],
  },
  {
    primary_id: "D0N1FU",
    names: ["N-Isoleucylthiazolidine"],
  },
  {
    primary_id: "D0N1GB",
    names: ["APD-916"],
  },
  {
    primary_id: "D0N1GH",
    names: [
      "6-(Pyridin-3-Yl)-2-Naphthonitrile",
      "CHEMBL196796",
      "SCHEMBL4511285",
      "BDBM8909",
      "6-Pyridin-3-Yl-2-Naphthonitrile",
      "Pyridine-Substituted Naphthalene 8",
      "ZINC13674459",
    ],
  },
  {
    primary_id: "D0N1HE",
    names: ["UCL1848"],
  },
  {
    primary_id: "D0N1HL",
    names: ["Frakefamide", "BCH-3963", "LEF-553", "LEF-576", "SPD-759", "LEF, AstraZeneca/BioChem Pharma"],
  },
  {
    primary_id: "D0N1HV",
    names: ["4-(2,5-DIAMINO-5-HYDROXY-PENTYL)-PHENOL", "AC1NBQIC"],
  },
  {
    primary_id: "D0N1HW",
    names: [
      "L-745,870",
      "158985-00-3",
      "L-745870",
      "UNII-J3OLN3XSX7",
      "J3OLN3XSX7",
      "CHEMBL267014",
      "L 745,870",
      "3-((4-(4-Chlorophenyl)Piperazin-1-Yl)Methyl)-1H-Pyrrolo[2,3-B]pyridine",
      "3-(4-[4-Chlorophenyl]piperazin-1-Yl)-Methyl-1H-Pyrrolo[2,3-B]pyridine Trihydrochloride",
      "3-((4-(4-Chlorophenyl)Piperazin-1-Yl)Methyl)-1H-Pyrrolo(2,3-B)Pyridine",
      "3-[[4-(4-Chlorophenyl)Piperazin-1-Yl]methyl]-1H-Pyrrolo[2,3-B]pyridine",
      "CPMPP-3",
      "Tocris-1002",
      "Lopac-L-131",
      "Biomol-NT_000049",
      "AC1NSK75",
      "L745870",
      "Lopac0_000791",
      "GTPL3303",
      "BPBio1_001299",
      "SCHEMBL1059886",
      "CHEBI:92099",
    ],
  },
  {
    primary_id: "D0N1IA",
    names: ["PMID26666989-Compound-Figure9middle02"],
  },
  {
    primary_id: "D0N1IL",
    names: ["HG-1246"],
  },
  {
    primary_id: "D0N1KC",
    names: ["B-Type Natriuretic Peptide", "Cardeva (TN)"],
  },
  {
    primary_id: "D0N1LT",
    names: ["16-(Pyridin-3-Yl)Methyl-Estradiol"],
  },
  {
    primary_id: "D0N1MC",
    names: [
      "Cefatrizine",
      "Cephatriazine",
      "S 640P",
      "Orosporina",
      "Cefatrizino",
      "Cepticol",
      "Trizina",
      "Latocef",
      "Cetrazil",
      "Cefatrix",
      "Bricef",
      "Cefatrizinum [INN-Latin]",
      "Cefatrizino [INN-Spanish]",
      "Cefatrizine [USAN:INN:BAN]",
      "EINECS 257-324-2",
      "(6R,7R)-7-((R)-2-Amino-2-(P-Hydroxyphenyl)Acetamido)-8-Oxo-3-((V-Triazol-4-Ylthio)Methyl)-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "SCHEMBL1650588",
      "AC1L2300",
      "5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 7-((Amino(4-Hydroxyphenyl)Acetyl)Amino)-8-Oxo-3-((1H-1,2,3-Triazol-4-Ylthio)Methyl)-, (6R-(6alpha,7b",
    ],
  },
  {
    primary_id: "D0N1MY",
    names: [
      "Alpha-Eudesmol",
      ".alpha.-Eudesmol",
      "2-Naphthalenemethanol, 1,2,3,4,4a,5,6,8a-Octahydro-.alpha.,.alpha.,4a,8-Tetramethyl-, [2R-(2.alpha.,4a.alpha.,8a.beta.)]-",
      "AC1NSVC3",
      "SCHEMBL17962962",
      "FCSRUSQUAVXUKK-ILWWEHDPSA-N",
      "2-(4a,8-Dimethyl-1,2,3,4,4a,5,6,8a-Octahydro-2-Naphthalenyl)-2-Propanol #",
    ],
  },
  {
    primary_id: "D0N1NE",
    names: ["Xp-828l", "Dermylex (TN)"],
  },
  {
    primary_id: "D0N1NL",
    names: ["Recombinant Wolinella (RWS) Asparaginase"],
  },
  {
    primary_id: "D0N1PA",
    names: [
      "CGP-49823",
      "CHEMBL290364",
      "Cgp49823",
      "SCHEMBL6904655",
      "BDBM50287397",
      "{(R)-2-Benzyl-4-[(Quinolin-4-Ylmethyl)-Amino]-Piperidin-1-Yl}-(3,5-Dimethyl-Phenyl)-Methanone",
    ],
  },
  {
    primary_id: "D0N1PH",
    names: ["YM49598"],
  },
  {
    primary_id: "D0N1PQ",
    names: [
      "3-(6-Methoxynaphthalen-2-Yl)Pyridine",
      "CHEMBL362968",
      "BDBM8905",
      "SCHEMBL4500403",
      "Pyridine-Substituted Naphthalene 2",
      "3-(6-Methoxy-2-Naphthyl)Pyridine",
    ],
  },
  {
    primary_id: "D0N1PS",
    names: ["Cyclopentyl-(9-Cyclopentyl-9H-Purin-6-Yl)-Amine", "CHEMBL95617", "BDBM50009696"],
  },
  {
    primary_id: "D0N1PT",
    names: ["2-(4-Chloro-1H-Benzo[d]imidazol-2-Yl)Quinoxaline", "CHEMBL426297"],
  },
  {
    primary_id: "D0N1PW",
    names: [
      "4-Indol-1-Ylmethyl-Benzamidine",
      "1-(4-Amidinobenzyl)Indole",
      "83783-25-9",
      "CHEMBL100569",
      "4-ABI",
      "4-(1h-Indol-1-Ylmethyl)Benzenecarboximidamide",
      "AC1L34FF",
      "AC1Q1U95",
      "CTK5F1124",
      "DTXSID50232637",
      "ZINC5162918",
      "BDBM50027305",
      "AKOS030584119",
      "4-((1h-Indol-1-Yl)Methyl)Benzimidamide",
      "4-(Indol-1-Ylmethyl)Benzenecarboximidamide",
      "KB-214170",
      "Benzenecarboximidamide,4-(1H-Indol-1-Ylmethyl)-",
      "Benzenecarboximidamide, 4-(1H-Indol-1-Ylmethyl)-",
    ],
  },
  {
    primary_id: "D0N1PZ",
    names: [
      "S-Tenatoprazole",
      "Benatoprazole",
      "Protop",
      "Tenatoprazole",
      "Ulsacare",
      "STU-199",
      "STU-Na",
      "TU-199",
      "S-Tenatoprazole-Na",
    ],
  },
  {
    primary_id: "D0N1QH",
    names: ["HuMax-CD38b"],
  },
  {
    primary_id: "D0N1QO",
    names: ["N6-Methoxy-2-[(4-Methoxyphenyl)Ethynyl]adenosine", "CHEMBL220158", "SCHEMBL4381643"],
  },
  {
    primary_id: "D0N1QP",
    names: ["2,6-Diphenyl-1-Deazapurine", "CHEMBL220535", "ZINC34800849"],
  },
  {
    primary_id: "D0N1QQ",
    names: [
      "DIPROTIN A",
      "Diprotin A",
      "MLS002207185",
      "NSC602337",
      "HIV Inhibitor",
      "AC1Q5KXE",
      "AC1L1F6Q",
      "DivK1c_000899",
      "CHEMBL158110",
      "SCHEMBL17867000",
      "KBio1_000899",
      "HMS502M21",
      "NINDS_000899",
      "Ile-Pro-Ile, &gt",
      "BDBM50085083",
      "NSC-602337",
      "IDI1_000899",
      "AN-25068",
      "SMR001306756",
      "Bacillus Cereus BMF673-RF1 Culture Filtrate",
      "2-[[1-(2-Amino-3-Methyl-Pentanoyl)Pyrrolidine-2-Carbonyl]amino]-3-Methyl-Pentanoic Acid",
      "2-{[1-(2-Amino-3-Methyl-Pentanoyl)-Pyrrolidine-2-Carbonyl]-Amino}-3-Methyl-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0N1QW",
    names: ["2-(4-Benzoylpiperazin-1-Yl)Thiazole-5-Carboxamide", "CHEMBL568131"],
  },
  {
    primary_id: "D0N1QX",
    names: ["ImmStat"],
  },
  {
    primary_id: "D0N1RE",
    names: ["BIBF100"],
  },
  {
    primary_id: "D0N1RG",
    names: ["SoRI-9409"],
  },
  {
    primary_id: "D0N1RH",
    names: [
      "AMZ-0026",
      "Abavca",
      "Perthon",
      "AMZ-0026A",
      "ACA, Advanced Plant Pharmaceuticals/Amazon Biotech",
      "HIV Therapeutics, Advanced Plant Pharmaceuticals/Amazon Biotech",
    ],
  },
  {
    primary_id: "D0N1RM",
    names: ["JR-101"],
  },
  {
    primary_id: "D0N1RP",
    names: ["ISIS 188759"],
  },
  {
    primary_id: "D0N1RQ",
    names: [
      "2-(4-Amino-3'-Chloro-Biphenyl-3-Yl)-Propan-2-Ol",
      "CHEMBL350283",
      "SCHEMBL6770087",
      "ITEDDYPYHIHWHC-UHFFFAOYSA-N",
      "BDBM50110507",
      "2-[2-Amino-5-(3-Chlorophenyl)Phenyl]-2-Propanol",
      "(4-Amino-3'-Chloro-Biphenyl-3-Yl)-Dimethyl-Methanol",
      "2-(4-Amino-3''-Chloro-Biphenyl-3-Yl)-Propan-2-Ol",
    ],
  },
  {
    primary_id: "D0N1RX",
    names: ["PMID28092474-Compound-32d"],
  },
  {
    primary_id: "D0N1SE",
    names: ["1-(1-Propionylpiperidin-4-Yl)-3-M-Tolylurea", "CHEMBL1257755", "SCHEMBL2719775", "BDBM50327819"],
  },
  {
    primary_id: "D0N1SH",
    names: ["VX-15"],
  },
  {
    primary_id: "D0N1SL",
    names: ["KI-0801"],
  },
  {
    primary_id: "D0N1ST",
    names: [
      "AFC-5128",
      "AFC-5261",
      "P2X7 Purinoreceptor Antagonists (Neuropathic Pain/ Multiple Sclerosis)",
      "P2X7 Purinoreceptor Antagonist (Inflammatory Disease/Neurodegenerative Disorder), Affectis",
      "P2X7 Purinoreceptor Antagonists (Neuropathic Pain/ Multiple Sclerosis), Affectis",
    ],
  },
  {
    primary_id: "D0N1SU",
    names: ["Licofelone"],
  },
  {
    primary_id: "D0N1TH",
    names: ["ATI-2042", "Budiodarone"],
  },
  {
    primary_id: "D0N1TP",
    names: ["Paricalcitol"],
  },
  {
    primary_id: "D0N1TQ",
    names: ["ER819762"],
  },
  {
    primary_id: "D0N1UB",
    names: [
      "2-Amino-Quinoline-3-Carboxylic Acid Amide",
      "2-Aminoquinoline-3-Carboxamide",
      "31407-28-0",
      "3-Quinolinecarboxamide, 2-Amino-",
      "2-Amino-3-Quinolinecarboxamide",
      "CHEMBL206956",
      "GXZOXGRLLFZVGF-UHFFFAOYSA-N",
      "ZERO/004541",
      "AC1NP94V",
      "SCHEMBL30837",
      "CTK7D2638",
      "MolPort-002-742-881",
      "ZX-AN021879",
      "ALBB-023365",
      "STK735522",
      "BDBM50186621",
      "SBB012747",
      "ZINC19890815",
      "FCH854771",
      "AKOS003368785",
      "MCULE-6676169786",
      "NCGC00340196-01",
      "2-Aminoquinoline-3-Carboxylic Acid Amide",
      "OR346178",
      "KB-118564",
      "2-Amino-3-Quinolinecarboxamide, AldrichCPR",
      "ST4128359",
      "R9463",
    ],
  },
  {
    primary_id: "D0N1UH",
    names: ["VTT-301", "Anti-Inflammatory (Benign Prostatic Hyperplasia/Prostatitis), Vitala"],
  },
  {
    primary_id: "D0N1UU",
    names: ["PMID20471253C32"],
  },
  {
    primary_id: "D0N1VC",
    names: ["PMID28454500-Compound-49"],
  },
  {
    primary_id: "D0N1VG",
    names: ["DeNVax"],
  },
  {
    primary_id: "D0N1WG",
    names: ["GSK-1137585"],
  },
  {
    primary_id: "D0N1WM",
    names: [
      "A-68930",
      "5,6-Dihydroxy-3-Phenyl-1-Aminomethylisochroman",
      "A-70108",
      "A 68930",
      "A70360",
      "A70108",
      "A68930",
      "A 70360",
      "A-70360",
      "A 70108",
      "UNII-B4NI77O5D4",
      "CHEMBL86931",
      "B4NI77O5D4",
      "1-(Aminomethyl)-3,4-Dihydro-5,6-Dihydroxy-3-Phenyl-1H-2-Benzopyran",
      "130465-45-1",
      "1H-2-Benzopyran-5,6-Diol, 1-(Aminomethyl)-3,4-Dihydro-3-Phenyl-, (1R-Cis)-",
      "Tocris-1534",
      "AC1L3UDV",
      "GTPL6077",
      "SCHEMBL10380334",
      "CHEBI:103966",
      "ZINC3787668",
      "BDBM50007129",
      "NCGC00025206-01",
      "NCGC00025206-02",
      "LS-172768",
      "BRD-K33400588-003-01-2",
    ],
  },
  {
    primary_id: "D0N1WU",
    names: ["Etodolac"],
  },
  {
    primary_id: "D0N1XD",
    names: ["Gliotoxin"],
  },
  {
    primary_id: "D0N1XJ",
    names: ["SCID-X Stem Cell Therapy"],
  },
  {
    primary_id: "D0N1XQ",
    names: ["JMV 1719"],
  },
  {
    primary_id: "D0N1YF",
    names: ["Beperminogene Perplasmid"],
  },
  {
    primary_id: "D0N1YG",
    names: ["CureXcell"],
  },
  {
    primary_id: "D0N1YW",
    names: ["GNF-PF-2322"],
  },
  {
    primary_id: "D0N1ZJ",
    names: ["4-(Tert-Butylamino)Naphthalen-1-Ol", "CHEMBL568767", "SCHEMBL12599109"],
  },
  {
    primary_id: "D0N2AA",
    names: ["8-Aza-DADMe-ImmH"],
  },
  {
    primary_id: "D0N2AI",
    names: ["Biphenyl Mannoside Derivative 19"],
  },
  {
    primary_id: "D0N2AM",
    names: ["CAR CD30 T Cells"],
  },
  {
    primary_id: "D0N2AY",
    names: ["Stapled Peptides"],
  },
  {
    primary_id: "D0N2BP",
    names: ["2,2-Bis-(3-Fluorophenyl)-2-Phenylacetamide", "CHEMBL261828", "SCHEMBL13950934"],
  },
  {
    primary_id: "D0N2BX",
    names: ["Nadroparin Calcium"],
  },
  {
    primary_id: "D0N2CG",
    names: ["Carlecortemcel-L"],
  },
  {
    primary_id: "D0N2CN",
    names: ["GR-175737"],
  },
  {
    primary_id: "D0N2CS",
    names: ["RP-4000"],
  },
  {
    primary_id: "D0N2DD",
    names: ["PDL-241"],
  },
  {
    primary_id: "D0N2DJ",
    names: ["Benzimidazole Derivative 11"],
  },
  {
    primary_id: "D0N2DK",
    names: ["PMID29671355-Compound-44"],
  },
  {
    primary_id: "D0N2DN",
    names: ["4-Phenylspiro[chromene-2,4'-Piperidine]", "CHEMBL492447", "SCHEMBL467151", "BDBM50252830"],
  },
  {
    primary_id: "D0N2DP",
    names: ["N*6*-Cyclooctyl-N*2*-Phenyl-9H-Purine-2,6-Diamine", "CHEMBL365026"],
  },
  {
    primary_id: "D0N2FI",
    names: [
      "GW-766994",
      "CCR3 Antagonists, GSK",
      "CCR3 Antagonists, GlaxoSmithKline",
      "Chemokine Receptor 3 Antagonist, GlaxoSmithKline",
      "GW-633647",
      "GW-633647X",
      "GW-701877",
      "GW-701897",
      "GW-701897X",
      "GW-782415X",
      "SB-297006",
      "SB-328437",
    ],
  },
  {
    primary_id: "D0N2FN",
    names: ["PMID29671355-Compound-74"],
  },
  {
    primary_id: "D0N2GC",
    names: ["BMS-753493"],
  },
  {
    primary_id: "D0N2GM",
    names: ["JMV1431"],
  },
  {
    primary_id: "D0N2GO",
    names: [
      "7-Hydroxycostunolide",
      "NSC711176",
      "AC1NV7KU",
      "CHEMBL206443",
      "CHEMBL1976955",
      "NSC-711176",
      "Cyclodeca[b]furan-2(3H)-One,4,5,8,9,11a-Hexahydro-3a-Hydroxy- 6,10-Dimethyl-3-Methylene-, Stereoisomer",
    ],
  },
  {
    primary_id: "D0N2GQ",
    names: ["ATI-0917"],
  },
  {
    primary_id: "D0N2GS",
    names: ["NDC-KM"],
  },
  {
    primary_id: "D0N2HF",
    names: ["Vanoxerine"],
  },
  {
    primary_id: "D0N2HU",
    names: [
      "VRC-EBODNA023-00-VP",
      "DNA/RAd5 Prime-Boost Ebola Vaccine, NIAID",
      "Ebola DNA Vaccine (Plasmids Encoding Zaire + Sudan/Gulu Glycoproteins), NIAID/ Makerere University/Walter Reed/ US Military",
    ],
  },
  {
    primary_id: "D0N2IE",
    names: ["VB-201"],
  },
  {
    primary_id: "D0N2JI",
    names: ["VAS-203"],
  },
  {
    primary_id: "D0N2JY",
    names: ["VESTIPITANT MESYLATE"],
  },
  {
    primary_id: "D0N2KP",
    names: ["2,5-Bis-(3-Cyclopentyloxy-4-Methoxy-Phenyl)-Furan", "CHEMBL111506"],
  },
  {
    primary_id: "D0N2KU",
    names: ["PF-3409409", "CHEMBL552338", "SCHEMBL5888234", "BDBM50297077"],
  },
  {
    primary_id: "D0N2LE",
    names: ["GUANOSINE-2',3'-O-METHYLIDENEPHOSPHONATE"],
  },
  {
    primary_id: "D0N2LW",
    names: ["8-Phenyl-2-Thiomorpholin-4-Yl-Chromen-4-One", "CHEMBL175944"],
  },
  {
    primary_id: "D0N2LY",
    names: ["NN-2501"],
  },
  {
    primary_id: "D0N2MA",
    names: ["Des-AA1,5-[Tyr2,D-Trp8,IAmp9]Cbm-SRIF", "CHEMBL385811"],
  },
  {
    primary_id: "D0N2NI",
    names: ["Entrectinib"],
  },
  {
    primary_id: "D0N2NY",
    names: ["Ruxolitinib Cream"],
  },
  {
    primary_id: "D0N2QH",
    names: ["5'-Iodoresiniferatoxin"],
  },
  {
    primary_id: "D0N2QI",
    names: ["TA-CD Cocaine Abuse Vaccine"],
  },
  {
    primary_id: "D0N2QW",
    names: ["Patritumab"],
  },
  {
    primary_id: "D0N2QX",
    names: [
      "3-(Trifluoromethyl)Benzaldehyde Thiosemicarbazone",
      "CHEMBL90688",
      "(2E)-2-[3-(Trifluoromethyl)Benzylidene]hydrazinecarbothioamide",
      "264148-89-2",
      "MolPort-002-925-405",
      "HMS1669J15",
      "STK179777",
      "BDBM50114639",
      "AKOS005411111",
      "CCG-254652",
      "MS-7103",
    ],
  },
  {
    primary_id: "D0N2QZ",
    names: ["Pyrimidine Derivative 18"],
  },
  {
    primary_id: "D0N2RF",
    names: ["SMART ABL-364", "ABL-364", "SDZ-ABL-364"],
  },
  {
    primary_id: "D0N2RJ",
    names: ["1-(2-(3-Fluorophenoxy)Phenyl)Piperazine", "CHEMBL573973"],
  },
  {
    primary_id: "D0N2SM",
    names: [
      "2beta,3alpha-Dihydroxyolean-12-En-28-Oic Acid",
      "Bredemolic Acid",
      "CHEMBL577380",
      "5957-40-4",
      "ZINC8952016",
      "BDBM50346696",
    ],
  },
  {
    primary_id: "D0N2SR",
    names: ["Selexipag"],
  },
  {
    primary_id: "D0N2TF",
    names: ["R214127"],
  },
  {
    primary_id: "D0N2TP",
    names: ["2-(M-Tolylethynyl)Pyrimidine", "CHEMBL496882"],
  },
  {
    primary_id: "D0N2TV",
    names: ["Benzamide Derivative 12"],
  },
  {
    primary_id: "D0N2UF",
    names: ["ARI-3531", "Compound 22 [PMID: 23594271]"],
  },
  {
    primary_id: "D0N2UQ",
    names: ["PMID25666693-Compound-24"],
  },
  {
    primary_id: "D0N2VA",
    names: ["Pyrimidine Derivative 25"],
  },
  {
    primary_id: "D0N2VN",
    names: [
      "VU10010",
      "633283-39-3",
      "VU 10010",
      "3-Amino-N-[(4-Chlorophenyl)Methyl]-4,6-Dimethylthieno[2,3-B]pyridine-2-Carboxamide",
      "VU-10010",
      "CHEMBL1212994",
      "3-Amino-N-(4-Chlorobenzyl)-4,6-Dimethylthieno[2,3-B]pyridine-2-Carboxamide",
      "FPRULFHDSFKYBV-UHFFFAOYSA-N",
      "2fLI",
      "2-Fly",
      "F-LIGRLO-NH2",
      "AC1LEM0C",
      "2-FAP",
      "2-F-LIGRLO-NH2",
      "MLS002474501",
      "Cid_714286",
      "CTK5B8654",
      "DTXSID30351931",
      "MolPort-002-722-568",
      "HMS3431D09",
      "ZINC109066",
      "STL227650",
      "BDBM50323691",
      "AKOS000282930",
      "MCULE-8947006791",
      "NCGC00378784-01",
      "KB-81468",
      "SMR001398666",
      "RT-016241",
      "B7285",
    ],
  },
  {
    primary_id: "D0N2VO",
    names: [
      "2-Amino-4,6-Diphenyl-Nicotinonitrile",
      "2-Amino-4,6-Diphenylpyridine-3-Carbonitrile",
      "CHEMBL467387",
      "4604/6/2",
      "CBDivE_000439",
      "AC1LDJG4",
      "TimTec1_000178",
      "SCHEMBL4911494",
      "CTK1D2047",
      "DTXSID70349897",
      "YFQVSTNNJZZYLY-UHFFFAOYSA-N",
      "MolPort-000-994-643",
      "HMS1534I02",
      "C18H13N3",
      "2-Amino-4,6-Diphenylnicotinonitrile",
      "ZINC18995588",
      "STK831928",
      "BDBM50262323",
      "AKOS000286699",
      "MCULE-4409959472",
      "NCGC00175390-01",
      "ST001238",
      "EU-0034185",
      "2-Amino-4,6-Diphenyl-3-Pyridinecarbonitrile",
      "3-Pyridinecarbonitrile, 2-Amino-4,6-Diphenyl-",
      "SR-01000395192",
      "SR-01000395192-1",
    ],
  },
  {
    primary_id: "D0N2WA",
    names: ["[Bpa1]AngII", "CHEMBL1076612"],
  },
  {
    primary_id: "D0N2WK",
    names: ["CV-3988", "CV 3988", "CV3988"],
  },
  {
    primary_id: "D0N2XO",
    names: [
      "2-(3-Methoxyphenyl)-6-Morpholino-4H-Pyran-4-One",
      "CHEMBL106543",
      "SCHEMBL3538409",
      "BDBM50132356",
      "2-Morpholino-6-(3-Methoxyphenyl)-4H-Pyran-4-One",
    ],
  },
  {
    primary_id: "D0N2YN",
    names: ["Spirogermanium Hydrochloride"],
  },
  {
    primary_id: "D0N2YY",
    names: ["OSPC-RDT Vaccine"],
  },
  {
    primary_id: "D0N3AF",
    names: ["Imidazopyridazine Derivative 7"],
  },
  {
    primary_id: "D0N3BB",
    names: ["CBX-129801"],
  },
  {
    primary_id: "D0N3BU",
    names: ["ALT-020-02"],
  },
  {
    primary_id: "D0N3CC",
    names: [
      "(S-(2-Oxo)Pentadecyl-CoA)",
      "CHEMBL448516",
      "Coenzyme A, S-(2-Oxopentadecyl)-",
      "121124-66-1",
      "NSC618486",
      "AC1L9QGK",
      "S-(2-Oxopentadecyl) CoA",
      "S-2-Ketopentadecyl Coenzyme A, Sodium Salt",
      "BDBM50270015",
      "[[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-4-Hydroxy-3-Phosphonooxyoxolan-2-Yl]methoxy-Hydroxyphosphoryl] [3-Hydroxy-2,2-Dimethyl-4-Oxo-4-[[3-Oxo-3-[2-(2-Oxopentadecylsulfanyl)Ethylamino]propyl]amino]butyl] Hydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0N3CZ",
    names: ["Super Cinnamaldehyde"],
  },
  {
    primary_id: "D0N3DD",
    names: [
      "[3H]WIN35428",
      "Beta-Cft",
      "Win 35428",
      "2-Cmft",
      "50370-56-4",
      "2-CFT",
      "WIN 35,428",
      "2-Carbomethoxy-3-(4-Fluorophenyl)Tropane",
      "(3H)2-Carbomethoxy-3-(4-Fluorophenyl)Tropane",
      "(1R,2S,3S,5S)-Methyl 3-(4-Fluorophenyl)-8-Methyl-8-Azabicyclo[321]octane-2-Carboxylate",
      "2beta-Carbomethoxy-3beta-(4-Fluorophenyl)Tropane",
      "WIN35428",
      "Methyl (1R,2S,3S,5S)-3-(4-Fluorophenyl)-8-Methyl-8-Azabicyclo[321]octane-2-Carboxylate",
      "[3H]WIN 35,428",
      "(1R-(Exo,exo))-3-(4-Fluorophenyl)-8-Methyl-8-Azabicyclo(321)Octane-2-Carboxylic Acid, Methyl Ester",
      "[3H]-2beta-Carboxymethy-3beta-(4-Fluorophenyl)Tropane",
      "[3H]-WIN35428",
      "[3H]-Beta-CFT",
      "WIN_35428",
    ],
  },
  {
    primary_id: "D0N3DW",
    names: [
      "5-Phenyl-1,3,4-Thiadiazole-2(3H)-Thione",
      "5-Phenyl-1,3,4-Thiadiazole-2-Thiol",
      "5585-19-3",
      "1,3,4-Thiadiazole-2-Thiol, 5-Phenyl-",
      "L 1542",
      "2-Mercapto-5-Fenil-1,3,4-Tiadiazolo [Italian]",
      "CHEMBL596215",
      "2-Mercapto-5-Phenyl-1,3,4-Thiadiazole",
      "2-Mercapto-5-Fenil-1,3,4-Tiadiazolo",
      "AC1Q7GLK",
      "AC1MHZ9V",
      "SCHEMBL969040",
      "DTXSID40204444",
      "ZTLMHGOWADYAHM-UHFFFAOYSA-N",
      "MolPort-000-932-663",
      "ZINC13532595",
      "MFCD01681863",
      "BDBM50320718",
      "5-Phenyl-1,3,4-Thiadiazol-2-Thiol",
      "AKOS003056166",
      "MCULE-7311616477",
      "NE54511",
      "LS-150315",
    ],
  },
  {
    primary_id: "D0N3EF",
    names: ["Oral Interferons"],
  },
  {
    primary_id: "D0N3EJ",
    names: [
      "RGH-896",
      "AC1L338E",
      "1-[2-[[[[[5beta-[(Octadecylaminocarbonyloxy)Methyl]tetrahydrofuran]-2beta-Yl]methoxy]oxylatophosphinyl]oxy]ethyl]quinolinium",
      "[[[(2R)-5beta-[[(Octadecylcarbamoyl)Oxy]methyl]tetrahydrofuran]-2beta-Yl]methyl][2-(Quinolinium-1-Yl)Ethyl] Phosphate",
      "[(2S,5R)-5-[[hydroxy(2-Quinolin-1-Ium-1-Ylethoxy)Phosphoryl]oxymethyl]oxolan-2-Yl]methyl N-Octadecylcarbamate",
    ],
  },
  {
    primary_id: "D0N3FJ",
    names: ["BL-1889"],
  },
  {
    primary_id: "D0N3FL",
    names: ["PSB-0702", "CHEMBL502519"],
  },
  {
    primary_id: "D0N3GE",
    names: ["CD19 CART"],
  },
  {
    primary_id: "D0N3GU",
    names: ["YPWA-NH2", "CHEMBL512955"],
  },
  {
    primary_id: "D0N3HU",
    names: [
      "[1,4]Thiazepan-(5E)-Ylideneamine",
      "CHEMBL88308",
      "SCHEMBL10239183",
      "5-Iminohexahydro-1,4-Thiazepine",
      "[1,4]Thiazepan-(5Z)-Ylideneamine",
      "BDBM50104653",
    ],
  },
  {
    primary_id: "D0N3IA",
    names: [
      "HE3235",
      "Apoptone",
      "UNII-891O182ZP5",
      "183387-50-0",
      "HE 3235",
      "891O182ZP5",
      "SCHEMBL3402872",
      "Pregn-20-Yne-3,17-Diol, (3alpha,5alpha,17alpha)-",
      "DTXSID70171418",
      "MolPort-044-561-946",
      "ZINC5921098",
    ],
  },
  {
    primary_id: "D0N3IP",
    names: [
      "CAMPESTANOL ASCORBYL PHOSPHATE",
      "2-O-[[(3beta,5alpha,24R)-Ergostan-3-O-Yl](Hydroxy)Phosphoryl]-L-Ascorbic Acid Disodium Salt",
    ],
  },
  {
    primary_id: "D0N3IT",
    names: ["SNX-1012"],
  },
  {
    primary_id: "D0N3JT",
    names: [
      "Cantuzumab Mertansine",
      "Cantuzumab Ravtansine",
      "Cantuzumab Ravtansine)",
      "HuC242-DM1",
      "HuC242-DM4",
      "IMGN-242",
      "L-DM4",
      "Modified Cantuzumab Mertansine, ImmunoGen",
      "SB-408075",
      "SB-40875",
      "Ansamitocin-Anticolon Ab Conjugate, ImmunoGen/GlaxoSmithKline",
      "Ansamitocin-Anticolon Ab Conjugate, ImmunoGen/SmithKline Beecham",
      "DM1-Anticolon Ab Conjugate, ImmunoGen/GSK",
      "DM1-Anticolon Ab Conjugate, ImmunoGen/GlaxoSmithKline",
      "DM1-Anticolon Ab Conjugate, ImmunoGen/SmithKline Beecham",
    ],
  },
  {
    primary_id: "D0N3JX",
    names: ["SB-633825"],
  },
  {
    primary_id: "D0N3LC",
    names: ["PMID24793884C77"],
  },
  {
    primary_id: "D0N3LY",
    names: ["Conantokins T"],
  },
  {
    primary_id: "D0N3NI",
    names: [
      "Deuteroporphyrin",
      "Deuteroporphyrin (Injectable, Cancer)",
      "Deuteroporphyrin (Injectable, Cancer), Shanghai Fudan-Zhangjiang Bio-Pharmaceutical/ Shanghai Pharmaceuticals",
      "Duteroporphyrin (Injectable, Cancer), Shanghai Fudan-Zhangjiang Bio-Pharmaceutical/ Shanghai Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0N3NL",
    names: [
      "Acriflavine",
      "Xanthacridinum",
      "Euflavine",
      "Pantonsiletten",
      "Flavinetten",
      "Assiflavine",
      "Choliflavin",
      "Bialflavina",
      "Vetaflavin",
      "Buroflavin",
      "Tolivalin",
      "Isravin",
      "Diacrid",
      "Zoriflavin",
      "Trachosept",
      "Gonacin",
      "Flavisept",
      "Flavioform",
      "Bovoflavin",
      "Mediflavin",
      "Bioacridin",
      "Angiflan",
      "Tripla-Etilo",
      "Glyco-Flavine",
      "Acriflavine Neutral",
      "Acriflavine [NF]",
      "Caswell No. 008",
      "Euflavin",
      "Flavacridinum Hydrochloricum",
      "Trypaflavine Neutral",
      "CCRIS 5617",
      "UNII-1T3A50395T",
      "EPA Pesticide Chemical Code 000501",
      "Acridinium, 3,6-Diamino-10-Methyl-, Chloride, Mixt. With 3,6-Acridined",
    ],
  },
  {
    primary_id: "D0N3NO",
    names: ["Limaprost"],
  },
  {
    primary_id: "D0N3NZ",
    names: ["Somatomedin-1"],
  },
  {
    primary_id: "D0N3OH",
    names: ["A-317920"],
  },
  {
    primary_id: "D0N3PC",
    names: ["MR1-1"],
  },
  {
    primary_id: "D0N3PE",
    names: [
      "Guanethidine",
      "Abapresin",
      "Dopam",
      "Dopom",
      "Eutensol",
      "Guanethidinum",
      "Guanetidina",
      "Ismelin",
      "Isobarin",
      "Octadin",
      "Octadine",
      "Octatensin",
      "Octatensine",
      "Oktadin",
      "Oktatensin",
      "Oktatenzin",
      "Sanotensin",
      "Guanethidine Sulphae",
      "Guanethidine Sulfate",
      "Ismelin Sulfate",
      "Apo-Guanethidine",
      "Guanethidine (INN)",
      "Guanethidine [INN:BAN]",
      "Guanethidinum [INN-Latin]",
      "Guanetidina [INN-Spanish]",
      "Apo-Guanethidine (TN)",
      "Beta-1-Azacyclooctylethylguanidine",
      "Guanethidine Sulfate (1:1)",
      "Guanethidine Sulfate (2:1)",
      "Su 5864 Sulfate (1:1)",
      "N-(2-Perhydroazocin-1-Ylethyl)Guanidine",
      "((2-Hexahydro-1(2H)-Azocinyl)Ethyl)Guanidine",
      "(2-(Octahydro-1-Azocinyl)Ethyl)Guanidine",
      "1-(2-Azocan-1-Ylethyl)Guanidine",
      "1-[2-(Azocan-1-Yl)Ethyl]guanidine",
      "2-(1'-Azacyclooctyl)Ethylguanidine",
      "2-(1-N,N-Heptamethyleneimino)Ethylguanidine",
      "2-[2-(Azocan-1-Yl)Ethyl]guanidine",
    ],
  },
  {
    primary_id: "D0N3QF",
    names: ["AZD-5122"],
  },
  {
    primary_id: "D0N3RC",
    names: ["TZP-301"],
  },
  {
    primary_id: "D0N3RE",
    names: ["NS5a"],
  },
  {
    primary_id: "D0N3RK",
    names: ["CYSTEMUSTINE", "CMSO2EN2", "N-(2-Chloroethyl)-N'-[2-(Methylsulfonyl)Ethyl]-N-Nitrosourea"],
  },
  {
    primary_id: "D0N3RM",
    names: ["Befetupitant"],
  },
  {
    primary_id: "D0N3RU",
    names: ["Stem Cell Therapy, Heart Disease"],
  },
  {
    primary_id: "D0N3SA",
    names: ["IR-201"],
  },
  {
    primary_id: "D0N3SH",
    names: ["Pyroxamide"],
  },
  {
    primary_id: "D0N3SP",
    names: [
      "Butoconazole",
      "Butaconazole",
      "Butoconazol",
      "Butoconazolum",
      "Femstat",
      "Gynofort",
      "Duplicate RN For Parent Cpd",
      "RS 35887",
      "Butoconazol [INN-Spanish]",
      "Butoconazole (INN)",
      "Butoconazole [INN:BAN]",
      "Butoconazolum [INN-Latin]",
      "Gynofort (TN)",
      "RS 35887-10-3",
      "(+-)-1-[4-(4-Chlorophenyl)-2-[(2,6-Dichlorophenyl)Thio]butyl]-1H-Imidazole",
      "(+/-)-1-[4-(P-Chlorophenyl)-2-[(2,6-Dichlorophenyl)Thio]butyl]imidazole",
      "(-)-1-((4-(P-Chlorophenyl)-2-((2,6-Dichlorophenyl)Thio)Imidazole",
      "1-(4-(4-Chlorophenyl)-2-(2,6-Dichlorophenylthio)-N-Butyl)-1H-Imidazole",
      "1-[4-(4-Chlorophenyl)-2-(2,6-Dichlorophenyl)Sulfanylbutyl]imidazole",
      "1-{4-(4-Chlorophenyl)-2-[(2,6-Dichlorophenyl)Sulfanyl]butyl}-1H-Imidazole",
      "1H-Imidazole",
      "1H-Imidazole, 1-(4-(4-Chlorophenyl)-2-((2,6-Dichlorophenyl)Thio)Butyl)-, Nitrate",
    ],
  },
  {
    primary_id: "D0N3SR",
    names: [
      "Cilnidipine",
      "Atelec",
      "Cinaldipine",
      "Cinalong",
      "Siscard",
      "Cilnidipine [INN]",
      "FRC 8653",
      "Atelec (TN)",
      "FRC-8653",
      "Cilnidipine (JAN/INN)",
      "(+-)-(E)-Cinnamyl 2-Methoxyethyl 1,4-Dihydro-2,6-Dimethyl-4-(M-Nitrophenyl)-3,5-Pyridinedicarboxylate",
      "1,4-Dihydro-2,6-Dimethyl-4-(3-Nitrophenyl)-3,5-Pyridinedicarboxylic Acid 2-Methoxyethyl (2E)-3-Phenyl-2-Propenyl Ester",
      "2-Methoxyethyl (2E)-3-Phenylprop-2-En-1-Yl 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "3-O-(2-Methoxyethyl) 5-O-[(E)-3-Phenylprop-2-Enyl] 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
    ],
  },
  {
    primary_id: "D0N3SU",
    names: ["PMID25399762-Compound-Table 7-Piperonal"],
  },
  {
    primary_id: "D0N3TO",
    names: ["TRM-115"],
  },
  {
    primary_id: "D0N3UG",
    names: ["Hylenex"],
  },
  {
    primary_id: "D0N3UL",
    names: ["Fosfosal", "Disdolen (TN)"],
  },
  {
    primary_id: "D0N3UO",
    names: [
      "(1-Amino-3-Methyl-Butyl)-Phosphinic Acid",
      "AC1O4HVH",
      "Phosphinic Acid, P-(1-Amino-3-Methylbutyl)-",
      "SCHEMBL5037193",
      "80744-65-6",
    ],
  },
  {
    primary_id: "D0N3UQ",
    names: ["123I-MNI-168", "Alzheimer's Diagnostic, IND", "123I-SPECT Diagnostic (Alzheimer's Disease), IND"],
  },
  {
    primary_id: "D0N3VR",
    names: ["Voriconazole"],
  },
  {
    primary_id: "D0N3WN",
    names: ["ABT-308"],
  },
  {
    primary_id: "D0N3XA",
    names: ["ANA-1"],
  },
  {
    primary_id: "D0N3XP",
    names: ["G749"],
  },
  {
    primary_id: "D0N3XT",
    names: [
      "BREQUINAR",
      "Brequinar",
      "96187-53-0",
      "6-Fluoro-2-(2'-Fluoro-[1,1'-Biphenyl]-4-Yl)-3-Methylquinoline-4-Carboxylic Acid",
      "Brequinarum [Latin]",
      "Brequinar [INN]",
      "Biphenquinate",
      "6-Fluoro-2-(2'-Fluorobiphenyl-4-Yl)-3-Methylquinoline-4-Carboxylic Acid",
      "Brequinarum [INN-Latin]",
      "UNII-5XL19F49H6",
      "C23H15F2NO2",
      "NSC-368390",
      "NSC 368390",
      "6-Fluoro-2-[4-(2-Fluorophenyl)Phenyl]-3-Methylquinoline-4-Carboxylic Acid",
      "PHEZJEYUWHETKO-UHFFFAOYSA-N",
      "Dup 785",
      "5XL19F49H6",
      "6-FLUORO-2-(2'-FLUORO-1,1'-BIPHENYL-4-YL)-3-METHYLQUINOLINE-4-CARBOXYLIC ACID",
      "Brequinarum",
    ],
  },
  {
    primary_id: "D0N3XY",
    names: ["N-(Benzimidazolylcarbonyl)-Piperidine Derivative 1"],
  },
  {
    primary_id: "D0N3YL",
    names: ["ASC-201", "Dipeptidyl Peptidase-4 Inhibitor (Bone Marrow Transplantation), America Stem Cell"],
  },
  {
    primary_id: "D0N3YN",
    names: ["ONO-3080573"],
  },
  {
    primary_id: "D0N3YT",
    names: [
      "GLPG-0555",
      "Chondrocyte Differentiation-Modulating Therapeutics (Osteoarthritis), Galapagos/GlaxoSmithKline",
      "GT-622 Modulating Compounds (Osteoarthritis), Galapagos/GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D0N3ZJ",
    names: ["LOMERIZINE"],
  },
  {
    primary_id: "D0N3ZN",
    names: ["MEDI-8662"],
  },
  {
    primary_id: "D0N3ZY",
    names: ["DPC-681"],
  },
  {
    primary_id: "D0N4AB",
    names: ["Rx-01"],
  },
  {
    primary_id: "D0N4AG",
    names: ["Thiophosphoric Acid (E)-Tetradec-9-Enyl Ester", "CHEMBL187402"],
  },
  {
    primary_id: "D0N4AH",
    names: ["BPH-608", "BPH608", "BPH 608"],
  },
  {
    primary_id: "D0N4BQ",
    names: ["PMID8709131C4"],
  },
  {
    primary_id: "D0N4CJ",
    names: ["ISIS 9139"],
  },
  {
    primary_id: "D0N4DF",
    names: ["ALKS-37"],
  },
  {
    primary_id: "D0N4DO",
    names: [
      "Troponin-I",
      "CDI, Alseres",
      "CDI, Boston Life Sciences",
      "Cartilage Derived Inhibitor, Alseres",
      "Cartilage Derived Inhibitor, Boston Life Sciences",
      "Troponin, Alseres",
      "Troponin, Boston Life Sciences",
      "Troponin-I, Alseres",
      "Troponin-I, Boston Life Sciences",
    ],
  },
  {
    primary_id: "D0N4DR",
    names: ["(S)(+)-7-Methyl-2-Phenylchroman-4-One", "CHEMBL601205"],
  },
  {
    primary_id: "D0N4DY",
    names: ["PMID26004420-Compound-WO2015017305D"],
  },
  {
    primary_id: "D0N4EC",
    names: ["Taltirelin", "Ceredist (TN)"],
  },
  {
    primary_id: "D0N4EO",
    names: ["GMI-1070"],
  },
  {
    primary_id: "D0N4EZ",
    names: ["3-Ammoniopropane-1-Sulfinate"],
  },
  {
    primary_id: "D0N4FJ",
    names: [
      "CFTR Inhibitors",
      "CFTR Inhibitors (Diarrhea), IOWH/Novartis",
      "CFTR Inhibitors (Diarrhea), The Institute For OneWorld Health/Novartis",
    ],
  },
  {
    primary_id: "D0N4FS",
    names: [
      "DPI59",
      "HYDROXY(1-NAPHTHYL)METHYLPHOSPHONIC ACID",
      "[(S)-Hydroxy(Naphthalen-1-Yl)Methyl]phosphonic Acid",
      "I59",
      "1o4g",
      "AC1L9KXU",
    ],
  },
  {
    primary_id: "D0N4HB",
    names: ["Belatacept"],
  },
  {
    primary_id: "D0N4HH",
    names: ["HRI Kinase Inhibitors"],
  },
  {
    primary_id: "D0N4HQ",
    names: ["OXi4503"],
  },
  {
    primary_id: "D0N4IT",
    names: ["N-(2,4-Dichlorophenyl)-2-Nitrobenzamide"],
  },
  {
    primary_id: "D0N4IU",
    names: [
      "CDNF/MANF",
      "CDNF/MANF (Parkinsons Disease)",
      "Conserved Dopamine Neurotrophic Factor (Parkinsons Disease), Hermo Pharma",
      "Mesencephalic Astrocyte Derived Neurotrophic Factor (Parkinsons Disease), Hermo Pharma",
      "Neurotrophic Factors (Parkinsons Disease), Hermo Pharma",
      "CDNF/MANF (Parkinsons Disease), Hermo Pharma",
    ],
  },
  {
    primary_id: "D0N4IV",
    names: [
      "S-3304",
      "BPHA",
      "BPHA, Shionogi",
      "MMP Inhibitors, Shionogi",
      "Matrix Metalloproteinase Inhibitor, Shionogi",
    ],
  },
  {
    primary_id: "D0N4JA",
    names: ["CLT-008"],
  },
  {
    primary_id: "D0N4JG",
    names: ["Eladem"],
  },
  {
    primary_id: "D0N4JI",
    names: [
      "APG-101",
      "CD95 Receptor Extracellular Domain-IgG Fused Protein (GvHD), Apogenix",
      "CD95 Receptor Extracellular Domain-IgG Fused Protein (Myocardial Infarction), Apogenix",
      "CD95 Receptor Extracellular Domain-IgG Fused Protein (Stroke), Apogenix",
      "CD95 Receptor Extracellular Domain-Immunoglobulin G Fused Protein (Graft Versus Hostdisease), Apogenix",
    ],
  },
  {
    primary_id: "D0N4KK",
    names: ["NB-1008"],
  },
  {
    primary_id: "D0N4LH",
    names: ["Des-AA1,2,5-[D-Trp8,IAmp9,(NalphaMe)Cys14]SRIF", "CHEMBL414716"],
  },
  {
    primary_id: "D0N4LR",
    names: ["GSK2606414"],
  },
  {
    primary_id: "D0N4LW",
    names: ["Human Alpha Antitrypsin"],
  },
  {
    primary_id: "D0N4MK",
    names: ["IB-07A037", "Antibiotic Resistant Inhibitor (Bacterial Infection), Tiger Pharmatech"],
  },
  {
    primary_id: "D0N4MR",
    names: [
      "CDE-5498",
      "Dev-0304",
      "Dev-0305",
      "Kv4.3 Voltage-Dependent Potassium Channel Antagonists (Arrhythmia)",
      "Kv4.3 Voltage-Dependent Potassium Channel Antagonists (Arrhythmia), Devgen",
    ],
  },
  {
    primary_id: "D0N4ND",
    names: ["Fontolizumab"],
  },
  {
    primary_id: "D0N4NH",
    names: ["Anti-CD45 MAb 131I-BC8"],
  },
  {
    primary_id: "D0N4NJ",
    names: ["YKP-1358"],
  },
  {
    primary_id: "D0N4ON",
    names: ["Aryl Methoxyacrylate Derivative 1"],
  },
  {
    primary_id: "D0N4OW",
    names: ["Vasopressin"],
  },
  {
    primary_id: "D0N4OZ",
    names: ["Epothilone D"],
  },
  {
    primary_id: "D0N4PC",
    names: ["RU-46556"],
  },
  {
    primary_id: "D0N4PL",
    names: ["PMID29649907-Compound-36"],
  },
  {
    primary_id: "D0N4PW",
    names: ["IOWH032"],
  },
  {
    primary_id: "D0N4PZ",
    names: ["Incadronate"],
  },
  {
    primary_id: "D0N4QQ",
    names: [
      "VAGANINE D",
      "CHEMBL460447",
      "(?)-Vaganine D",
      "SCHEMBL15861466",
      "BDBM50242345",
      "[(20S)-20-(N,N-Dimethylamino)-3 Beta-(Senecioylamino)-5 Alpha-Pregn-16-En-4 Beta-Yl Acetate]",
    ],
  },
  {
    primary_id: "D0N4QT",
    names: ["L-708474", "CHEMBL58699", "SCHEMBL9301528", "BDBM50040666"],
  },
  {
    primary_id: "D0N4RF",
    names: ["Demotensin 3", "CHEMBL219862"],
  },
  {
    primary_id: "D0N4RI",
    names: ["Pyrrolidine Derivative 12"],
  },
  {
    primary_id: "D0N4RJ",
    names: ["Triazolo-Pyrazinone Derivative 1"],
  },
  {
    primary_id: "D0N4RO",
    names: ["PMID18078750C1b"],
  },
  {
    primary_id: "D0N4SF",
    names: ["GI-4000"],
  },
  {
    primary_id: "D0N4SU",
    names: [
      "Pegorgotein",
      "Dismutec",
      "Conjugated BSOD, Oxis",
      "SOD, Sanofi",
      "Superoxide Dismutase, Sanofi",
      "PEG-SOD, Oxis",
      "PEG-SOD, Sanofi",
      "PEG-BSOD, Oxis",
      "PEG-BSOD, Sanofi",
      "PEG-Superoxide Dismutase, Enzon Inc",
    ],
  },
  {
    primary_id: "D0N4TF",
    names: ["ISIS 156472"],
  },
  {
    primary_id: "D0N4TO",
    names: ["Biphenyl Mannoside Derivative 11"],
  },
  {
    primary_id: "D0N4TZ",
    names: ["Macrocycle Derivative 8"],
  },
  {
    primary_id: "D0N4VB",
    names: ["NLG802"],
  },
  {
    primary_id: "D0N4VG",
    names: ["CAR-T Cells Targeting CD123"],
  },
  {
    primary_id: "D0N4VI",
    names: [
      "1-(3,4-Dihydronaphthalen-2-Yl)-1H-Imidazole",
      "BRN 4745179",
      "89781-63-5",
      "1H-Imidazole, 1-(3,4-Dihydro-2-Naphthalenyl)-",
      "1-(3,4-Dihydro-2-Naphthalenyl)-1H-Imidazole",
      "1H-Imidazole,1-(3,4-Dihydro-2-Naphthalenyl)-",
      "ACMC-20lqcl",
      "Dihydronaphthalene 13",
      "AC1MIBH1",
      "SCHEMBL4513496",
      "CHEMBL208471",
      "BDBM8898",
      "1-(3,4-DIHYDRO-NAPHTHALEN-2-YL)-1H-IMIDAZOLE",
      "CTK5G3471",
      "DTXSID50237889",
      "ZINC13684552",
      "AKOS006279027",
      "1-(3,4-Dihydronaphthalen-2-Yl)Imidazole",
      "LS-78473",
    ],
  },
  {
    primary_id: "D0N4VN",
    names: ["INGAP Peptide"],
  },
  {
    primary_id: "D0N4VR",
    names: ["5-Fluoro-3-Thiophen-3-Yl-Quinoline", "CHEMBL305056", "SCHEMBL8501434", "ZINC3834023"],
  },
  {
    primary_id: "D0N4WB",
    names: ["SB-415286"],
  },
  {
    primary_id: "D0N4WW",
    names: ["PMID26651364-Compound-106"],
  },
  {
    primary_id: "D0N4WZ",
    names: [
      "CGP-36216",
      "CHEMBL325921",
      "123691-29-2",
      "SCHEMBL2348729",
      "RWCIPYZWXPUGRM-UHFFFAOYSA-N",
      "Ethyl(3-Aminopropyl)Phosphinic Acid",
      "3-Aminopropyl(Ethyl)Phosphinic Acid",
      "(3-Aminopropyl)Ethylphosphinic Acid",
      "ZINC13742894",
      "PDSP1_000409",
      "PDSP2_000407",
      "Ethyl-(3-Aminopropyl)Phosphinic Acid",
      "BDBM50032972",
      "(3-Amino-Propyl)-Ethyl-Phosphinic Acid",
      "Phosphinic Acid, P-(3-Aminopropyl)-P-Ethyl-",
    ],
  },
  {
    primary_id: "D0N4XG",
    names: ["Orthovanadate"],
  },
  {
    primary_id: "D0N4YP",
    names: ["6-(3-Aza-Bicyclo[3.1.0]hexan-1-Yl)Quinoline", "CHEMBL503444", "SCHEMBL3471202"],
  },
  {
    primary_id: "D0N4YR",
    names: ["3-Methoxy-4-Hydroxylonchocarpin"],
  },
  {
    primary_id: "D0N4YU",
    names: ["Enterococcus Faecalis Conjugate Vaccine"],
  },
  {
    primary_id: "D0N4YX",
    names: ["EAA-090"],
  },
  {
    primary_id: "D0N4ZM",
    names: ["Amidopyrazole Derivative 6"],
  },
  {
    primary_id: "D0N4ZS",
    names: ["ST-4206"],
  },
  {
    primary_id: "D0N4ZX",
    names: ["MK-1029"],
  },
  {
    primary_id: "D0N5AF",
    names: ["ISIS 11929"],
  },
  {
    primary_id: "D0N5AS",
    names: ["ITI-014"],
  },
  {
    primary_id: "D0N5AY",
    names: ["Ranagengliotucel-T"],
  },
  {
    primary_id: "D0N5BA",
    names: ["9-Deazahypoxanthine"],
  },
  {
    primary_id: "D0N5BC",
    names: [
      "Autolymphocyte Therapy",
      "Autologous Lymphocyte Therapy, Cytogen",
      "Autolymphocyte Therapy, Cytogen",
      "ALT (RCC),CYTOGEN",
      "ALT (RCC), Cellcor",
      "Autolymphocyte Therapy (RCC), CYTOGEN",
      "Autolymphocyte Therapy (RCC), Cellcor",
    ],
  },
  {
    primary_id: "D0N5BI",
    names: ["I-BRD9"],
  },
  {
    primary_id: "D0N5BK",
    names: ["PDE4"],
  },
  {
    primary_id: "D0N5BL",
    names: ["AJA-777"],
  },
  {
    primary_id: "D0N5CF",
    names: ["ISIS 114530"],
  },
  {
    primary_id: "D0N5CH",
    names: ["ICA-27243"],
  },
  {
    primary_id: "D0N5CI",
    names: ["(5-Phenyl-3H-Benzo[d]imidazol-4-Yl)Methanamine", "CHEMBL255554", "SCHEMBL3149686", "ZINC11687646"],
  },
  {
    primary_id: "D0N5CO",
    names: ["CDX-1140"],
  },
  {
    primary_id: "D0N5CT",
    names: [
      "BIBS-222",
      "Benzoic Acid,2-[[[4-[[2-Butyl-6-[[(Methylamino)Carbonyl]pentylamino]-1H-Benzimidazol-1-Yl]methyl]phenyl]amino]carbonyl]-3,6-Dichloro-",
      "ACMC-20n14b",
    ],
  },
  {
    primary_id: "D0N5CW",
    names: ["Oleoyl-Estrone", "Anti-Obesity Hormone-Based Therapy, Manhattan"],
  },
  {
    primary_id: "D0N5DW",
    names: ["CG-023132"],
  },
  {
    primary_id: "D0N5DZ",
    names: ["CBI-009"],
  },
  {
    primary_id: "D0N5GV",
    names: ["Nivolumab + Ipilimumab"],
  },
  {
    primary_id: "D0N5GZ",
    names: [
      "1-(4-Methyl-Benzyl)-1H-Imidazole",
      "1-(4-Methylbenzyl)-1H-Imidazole",
      "CHEMBL212741",
      "18994-86-0",
      "1H-Imidazole, 1-[(4-Methylphenyl)Methyl]-",
      "1-(4-Methylbenzyl)Imidazole",
      "SCHEMBL171734",
      "BDBM50188087",
      "ZINC35143440",
    ],
  },
  {
    primary_id: "D0N5HJ",
    names: [
      "Enalaprilat",
      "EAL",
      "Enalaprilate",
      "Enalaprilatum",
      "ENALAPRILAT INHIBITOR",
      "Enalapril Acid",
      "Enalapril Diacid",
      "Enalaprilat Anhydrous",
      "Enalaprilic Acid",
      "Enalprilat Hydrate",
      "Enalprilate Hydrate",
      "Enalaprilat (USP)",
      "MK-422",
      "Vasotec I.V.",
      "N-[(1S)-1-Carboxy-3-Phenylpropyl]-L-Alanyl-L-Proline",
      "N-[(1S)-1-Carboxy-3-Phenylpropyl]-L-Alanyl-L-Proline-Water (1/2)",
      "(2S)-1-[(2S)-2-[[(2S)-1-Hydroxy-1-Oxo-4-Phenylbutan-2-Yl]amino]propanoyl]pyrrolidine-2-Carboxylic Acid",
      "(2S)-1-[(2S)-2-[[(2S)-1-Hydroxy-1-Oxo-4-Phenylbutan-2-Yl]amino]propanoyl]pyrrolidine-2-Carboxylic Acid Dihydrate",
      "1-((2S)-2-{[(1S)-1-CARBOXY-3-PHENYLPROPYL]AMINO}PROPANOYL)-L-PROLINE",
      "1-(N-((S)-1-Carboxy-3-Phenylpropyl)-L-Alanyl)-L-Proline Dihydrate",
    ],
  },
  {
    primary_id: "D0N5HN",
    names: ["M-100240"],
  },
  {
    primary_id: "D0N5HO",
    names: ["SQ-32709"],
  },
  {
    primary_id: "D0N5IE",
    names: [
      "1-Phenyl-3-(4-Pyridin-2-Yl-Thiazol-2-Yl)-Urea",
      "CHEMBL164422",
      "TCMDC-143133",
      "BDBM50097425",
      "AKOS028168115",
      "1-Phenyl-3-[4-(2-Pyridyl)-2-Thiazolyl]urea",
    ],
  },
  {
    primary_id: "D0N5JJ",
    names: [
      "ADROGOLIDE HYDROCHLORIDE",
      "ABT-431",
      "Adrogolide Hydrochloride < USAN",
      "DAS-431",
      "A-93431.1",
      "9,10-Diacetoxy-2-Propyl-4,5,5a(R),6,7,11b(S)-Hexahydrobenzo[f]thieno[2,3-C]quinoline Hydrochloride",
    ],
  },
  {
    primary_id: "D0N5JQ",
    names: ["PMID30185082-Compound-63"],
  },
  {
    primary_id: "D0N5JZ",
    names: ["PF-04691502"],
  },
  {
    primary_id: "D0N5KF",
    names: ["Meropenem + Vaborbactam"],
  },
  {
    primary_id: "D0N5KS",
    names: [
      "Pumosetrag",
      "DDP-733",
      "MKC-733",
      "N-[(3R)-1-Azabicyclo[2.2.2]octan-3-Yl]-7-Oxo-4H-Thieno[3,2-B]pyridine-6-Carboxamide",
      "N-((3R)-1-Azabicyclo(2.2.2)Oct-3-Yl)-7-Oxo-4,7-Dihydrothieno(3,2-B)Pyridine-6-Carboxamide",
      "N-(1-Azabicyclo[2.2.2]oct-8-Yl)-2-Oxo-9-Thia-5-Azabicyclo[4.3.0]nona-3,7,10-Triene-3-Carboxamide",
    ],
  },
  {
    primary_id: "D0N5KX",
    names: ["Sulfamic Acid 4-Sulfamoyloxymethyl-Benzyl Ester", "CHEMBL360714"],
  },
  {
    primary_id: "D0N5LD",
    names: [
      "7-Nitroindazole-2-Carboxamidine",
      "7-Nitro-2h-Indazole-2-Carboximidamide",
      "7I2",
      "AC1L1CGX",
      "SCHEMBL4315825",
      "CTK5I3867",
      "7-Nitroindazole-2-Carboximidamide",
      "7-Nitro-2H-Indazole-2-Carboxamidine",
    ],
  },
  {
    primary_id: "D0N5LF",
    names: ["3-(1,5-Naphthyridin-3-Yl)Benzonitrile", "CHEMBL394718"],
  },
  {
    primary_id: "D0N5LJ",
    names: ["Igmesine"],
  },
  {
    primary_id: "D0N5LW",
    names: ["Phenylpurine Acetamide Analog 1"],
  },
  {
    primary_id: "D0N5MH",
    names: ["RG7916"],
  },
  {
    primary_id: "D0N5MI",
    names: ["Merestinib, LY2801653"],
  },
  {
    primary_id: "D0N5MU",
    names: [
      "5-(Pyrrolidin-1-Ylsulfonyl)Indoline-2,3-Dione",
      "220510-17-8",
      "5-(Pyrrolidine-1-Sulfonyl)-1H-Indole-2,3-Dione",
      "Isatin Sulfonamide 20",
      "5-Pyrrolidinylsulfonylisatin",
      "AC1N3S5K",
      "5-Pyrrolidin-1-Ylsulfonyl-1H-Indole-2,3-Dione",
      "SCHEMBL2486952",
      "CHEMBL305545",
      "BDBM10309",
      "DTXSID80399500",
      "QKCWUYHXHMXOLG-UHFFFAOYSA-N",
      "ZINC2944777",
      "5-[1-(Pyrrolidinyl)Sulfonyl]isatin",
      "2991AJ",
      "AKOS024262065",
      "AJ-44312",
      "5-(Pyrrolizinosulfonyl)Indoline-2,3-Dione",
      "AX8291337",
      "FT-0734877",
      "5-(Pyrrolidin-1-Ylsulfonyl)-1H-Indole-2,3-Dione",
    ],
  },
  {
    primary_id: "D0N5NC",
    names: ["TAS3681"],
  },
  {
    primary_id: "D0N5NI",
    names: ["Benlysta + Rituxan"],
  },
  {
    primary_id: "D0N5NM",
    names: ["Org-23366"],
  },
  {
    primary_id: "D0N5NV",
    names: ["Biaryl Compound 2"],
  },
  {
    primary_id: "D0N5OD",
    names: [
      "4-(Trifluoromethyl)Biphenyl",
      "398-36-7",
      "4-Trifluoromethylbiphenyl",
      "4-(Trifluoromethyl)-1,1'-Biphenyl",
      "1-Phenyl-4-(Trifluoromethyl)Benzene",
      "4-(Trifluoromethyl)-Biphenyl",
      "CHEMBL242993",
      "4-Phenylbenzotrifluoride",
      "P-Trifluoromethylbiphenyl",
      "SCHEMBL163728",
      "CTK4I1963",
      "DTXSID20432208",
      "MolPort-005-937-883",
      "4-(A,a,a-Trifluoromethyl)Biphenyl",
      "ZINC2529931",
      "STL555310",
      "2494AE",
      "BDBM50220191",
      "BBL101514",
      "1,1'-Biphenyl,4-(Trifluoromethyl)-",
      "AKOS005259545",
      "MCULE-3710616152",
      "AN-44717",
      "DS-11883",
      "AC-18838",
      "AK326586",
      "AB0077698",
      "TR-015900",
      "KB-195437",
    ],
  },
  {
    primary_id: "D0N5OL",
    names: ["STD-052"],
  },
  {
    primary_id: "D0N5OV",
    names: ["Cetuximab"],
  },
  {
    primary_id: "D0N5QC",
    names: ["Ethylmorphine"],
  },
  {
    primary_id: "D0N5QX",
    names: ["Alternaria Peptide Allergy Desensitization Agent"],
  },
  {
    primary_id: "D0N5RF",
    names: ["GPIba Targeting Mabs"],
  },
  {
    primary_id: "D0N5RQ",
    names: ["KYS-05041"],
  },
  {
    primary_id: "D0N5RV",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Ala-D-Tic-)", "CHEMBL238587"],
  },
  {
    primary_id: "D0N5SC",
    names: ["UK-369003"],
  },
  {
    primary_id: "D0N5SS",
    names: ["AZD1175"],
  },
  {
    primary_id: "D0N5SW",
    names: [
      "Sulochrin",
      "519-57-3",
      "UNII-8NA53C271Z",
      "CHEBI:16159",
      "8NA53C271Z",
      "Methyl 2-(2,6-Dihydroxy-4-Methylbenzoyl)-5-Hydroxy-3-Methoxybenzoate",
      "Benzoic Acid, 2-(2,6-Dihydroxy-4-Methylbenzoyl)-5-Hydroxy-3-Methoxy-, Methyl Ester",
      "Sulochrin_120096",
      "AC1Q5ZJI",
      "AC1L4NQS",
      "MLS000863634",
      "SCHEMBL233392",
      "CHEMBL61133",
      "MEGxm0_000192",
      "CTK1H2445",
      "ACon0_000926",
      "ACon1_000208",
      "DTXSID80199860",
      "MolPort-001-739-420",
      "YJRLSCDUYLRBIZ-UHFFFAOYSA-N",
      "HMS2268H19",
      "ZINC895263",
      "MFCD07370145",
      "ACM519573",
      "Sulochrin, &gt",
      "AKOS030615357",
      "MCULE-7066877679",
    ],
  },
  {
    primary_id: "D0N5TB",
    names: ["ITRI-305"],
  },
  {
    primary_id: "D0N5TL",
    names: ["Antanal 2", "CHEMBL454035"],
  },
  {
    primary_id: "D0N5UA",
    names: [
      "Dianicline+rimonabant",
      "[3H]nemonapride",
      "(2R,3R)-Nemonapride",
      "75272-39-8",
      "CHEBI:64219",
      "C21H26ClN3O2",
      "93664-94-9",
    ],
  },
  {
    primary_id: "D0N5UU",
    names: ["NRT-100"],
  },
  {
    primary_id: "D0N5VK",
    names: ["TSR-042"],
  },
  {
    primary_id: "D0N5VL",
    names: ["Saccharomyces Boulardii"],
  },
  {
    primary_id: "D0N5VO",
    names: ["JWH-403"],
  },
  {
    primary_id: "D0N5WE",
    names: ["Macrolactam Derivative 2"],
  },
  {
    primary_id: "D0N5WV",
    names: [
      "4-[(METHYLSULFONYL)AMINO]BENZOIC ACID",
      "4-(Methylsulfonamido)Benzoic Acid",
      "7151-76-0",
      "4-[(Methylsulfonyl)Amino]benzoic Acid",
      "4-Methanesulfonamidobenzoic Acid",
      "4-Methanesulfonylamino-Benzoic Acid",
      "4-(METHANESULFONYLAMINO)BENZOIC ACID",
      "Benzoic Acid, 4-[(Methylsulfonyl)Amino]-",
      "4-(Methanesulfonamido)Benzoic Acid",
      "CHEMBL339996",
      "SROHFTOYGFCJAF-UHFFFAOYSA-N",
      "P-(Methylsulfonamido)Benzoic Acid",
      "2hds",
      "Methanesulfonamidobenzoicacid",
      "AC1Q6VU0",
      "AC1Q4GQ4",
      "NCIOpen2_003463",
      "Oprea1_337582",
      "Oprea1_072870",
      "SCHEMBL118340",
      "AC1L5I58",
      "4-Methylsulfonylaminobenzoic Acid",
    ],
  },
  {
    primary_id: "D0N5XG",
    names: [
      "1-Benzyl-1H-Pyrrolo[3,2-B]pyridine",
      "CHEMBL561256",
      "1-Benzyl-4-Azaindole",
      "1h-Pyrrolo[3,2-B]pyridine,1-(Phenylmethyl)-",
      "SCHEMBL4716978",
      "LOGFFHFLSCMKJF-UHFFFAOYSA-N",
      "BDBM50295764",
      "ZINC43079815",
      "50426-35-2",
    ],
  },
  {
    primary_id: "D0N5XI",
    names: ["DP-001"],
  },
  {
    primary_id: "D0N5YA",
    names: [
      "Repaglinide",
      "Actulin",
      "GlucoNorm",
      "NovoNorm",
      "Prandin",
      "Repaglinida",
      "Repaglinidum",
      "Glaxo Wellcome Brand Of Replaginide",
      "Novo Nordisk Brand Of Repaglinide",
      "Repaglinide [USAN]",
      "Novo Nordisk Brand 2 Of Repaglinide",
      "AG-EE 388",
      "AG-EE 388 ZW",
      "AG-EE 623 ZW",
      "AGEE-623ZW",
      "GlucoNorm (TN)",
      "NN-623",
      "NovoNorm (TN)",
      "Prandin (TN)",
      "Repa-Glinide",
      "Repaglinida [INN-Spanish]",
      "Repaglinidum [INN-Latin]",
      "SMP-508",
      "AG-EE-388",
      "AG-EE-623 ZW",
      "Prandin, GlucoNorm, NovoNorm, Repaglinide",
      "Repaglinide (JAN/USP/INN)",
      "Repaglinide, (+-)-Isomer",
      "(+)-2-Ethoxy-Alpha-(((S)-Alpha-Isobutyl-O-Piperidinobenzyl)Carbamoyl)-P-Toluic Acid",
      "(S)-(+)-2-Ethoxy-4-[N-[1-(2-Piperidinophenyl)-3-Methyl-1-Butyl]aminocarbonylmethyl]benzoic Acid",
      "(S)-2-Ethoxy-4-(2-((Methyl-1-(2-(1-Piperidinyl)Phenyl)Butyl)Amino)-2-Oxoethyl)-Benzoic Acid",
      "(S)-2-Ethoxy-4-(2-((3-Methyl-1-(2-(1-Piperidinyl)-Phenyl)Butyl)Amino)-2-Oxoethyl)-Benzoic Acid",
      "111GE012",
      "2-Ethoxy-4-(2-((3-Methyl-1-(2-(1-Piperidinyl)Phenyl)Butyl)Amino)-2-Oxoethyl)Benzoic Acid",
      "2-Ethoxy-4-(2-{[(1S)-3-Methyl-1-(2-Piperidin-1-Ylphenyl)Butyl]amino}-2-Oxoethyl)Benzoic Acid",
      "2-Ethoxy-4-[2-({(1S)-3-Methyl-1-[2-(Piperidin-1-Yl)Phenyl]butyl}amino)-2-Oxoethyl]benzoic Acid",
      "2-Ethoxy-4-[2-[[(1S)-3-Methyl-1-(2-Piperidin-1-Ylphenyl)Butyl]amino]-2-Oxoethyl]benzoic Acid",
      "2-Ethoxy-N-(Alpha-(2-Methyl-1-Propyl)-2-Piperidinobenzyl)-4-Carbamoylmethylbenzoic Acid",
    ],
  },
  {
    primary_id: "D0N5YD",
    names: ["Nepicastat Oral"],
  },
  {
    primary_id: "D0N5YX",
    names: ["PD-158473"],
  },
  {
    primary_id: "D0N5ZS",
    names: ["Vedolizmab", "4168-17-6"],
  },
  {
    primary_id: "D0N6AC",
    names: ["SHAPE"],
  },
  {
    primary_id: "D0N6AM",
    names: ["PMID24157366C35"],
  },
  {
    primary_id: "D0N6AS",
    names: ["AC-Nle-C[Asp-His-DPhe-Pro-Trp-Lys]-NH2", "CHEMBL501642"],
  },
  {
    primary_id: "D0N6AY",
    names: ["Norfenfluramine", "(+/-)-Norfenfluramine"],
  },
  {
    primary_id: "D0N6BC",
    names: ["LDN-91946", "Compound 7"],
  },
  {
    primary_id: "D0N6BY",
    names: ["Amanitin", "Alpha-Amanitin"],
  },
  {
    primary_id: "D0N6CB",
    names: ["ST-1093"],
  },
  {
    primary_id: "D0N6CC",
    names: [
      "AFX-300",
      "AFX-301",
      "AFX-358",
      "AFX-300 Series, Aphoenix",
      "Macrolide Derivatives (Inflammation), Aphoenix",
    ],
  },
  {
    primary_id: "D0N6CD",
    names: ["3-Cyclohexanesulfonyl-Heptanoic Acid Hydroxyamide", "CHEMBL77258"],
  },
  {
    primary_id: "D0N6CN",
    names: ["2-(4-Butoxy-Phenoxy)-N-Hydroxy-Propionamide", "CHEMBL177422"],
  },
  {
    primary_id: "D0N6CR",
    names: ["Propoxycaine"],
  },
  {
    primary_id: "D0N6CS",
    names: ["FG-2216"],
  },
  {
    primary_id: "D0N6DD",
    names: ["SF-109"],
  },
  {
    primary_id: "D0N6DN",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-Phe-Asp-Nle-Trp-Boc", "CHEMBL405192"],
  },
  {
    primary_id: "D0N6EA",
    names: ["Odiparcil"],
  },
  {
    primary_id: "D0N6EB",
    names: ["CG-0070", "CG-5757", "Oncolytic Virus Therapy, Cell Genesys", "Oncolytic Virus Therapy, Cold Genesys"],
  },
  {
    primary_id: "D0N6EL",
    names: ["SX-AZD1"],
  },
  {
    primary_id: "D0N6ES",
    names: ["KN-62"],
  },
  {
    primary_id: "D0N6FH",
    names: [
      "Dihydroartemisinin",
      "Artenimol",
      "Cotecxin",
      "Dihydroartemisinine",
      "Dihydroqinghaosu",
      "Dihydroquinghaosu",
      "Dihydroquinghoasu",
      "VM3352",
      "Artenimol (INN)",
      "JAV-110",
      "GNF-PF-5634",
      "(3R,5aS,6R,8aS,9R,12R,12aR)-3,6,9-Trimethyldecahydro-3,12-Epoxypyrano[4,3-J][1,2]benzodioxepin-10-Ol",
      "[3R-(3.alpha.,5a.beta.,6.beta.,8a.beta.,9.alpha.,10.alpha.,12.beta.,12aR*)]-Decahydro-10-Hydroxy-3,6,9-Trimethyl-3,12-Epoxy-12H-Pyrano[4.3-J]-1,2-Benzodioxepin",
      "1,5,9-Trimethyl-(1R,4S,5R,8S,9R,10S,12R,13R)-11,14,15,16-Tetraoxatetracyclo[10.3.1.04,13.08,13]hexadecan-10-Ol(Dihydroartemisinin)",
    ],
  },
  {
    primary_id: "D0N6FI",
    names: ["ChimeriVax-JE"],
  },
  {
    primary_id: "D0N6FJ",
    names: ["5-(2-(3,5-Difluorophenyl)Ethynyl)Pyrimidine", "CHEMBL488233"],
  },
  {
    primary_id: "D0N6FL",
    names: ["Lipid Fragment", "2,10,23-TRIMETHYL-TETRACOSANE"],
  },
  {
    primary_id: "D0N6FZ",
    names: [
      "WS-50030",
      "Dual Dopamine D2 Partial Agonist/Serotonin Reuptake Inhibitor (Schizophrenia)",
      "Dual Dopamine D2 Partial Agonist/Serotonin Reuptake Inhibitor (Schizophrenia), Pfizer/Solvay",
    ],
  },
  {
    primary_id: "D0N6GL",
    names: ["Pyrazoline Derivative 10"],
  },
  {
    primary_id: "D0N6GW",
    names: ["NASPANVAC"],
  },
  {
    primary_id: "D0N6HA",
    names: ["COL-2401", "Antibacterial Peroxide (Vaginal Tablet, BDS, Bacterial Vaginosis), Columbia"],
  },
  {
    primary_id: "D0N6HL",
    names: ["FR-233118"],
  },
  {
    primary_id: "D0N6HP",
    names: ["Bicyclic Heteroaryl Carboxamide Analog 3"],
  },
  {
    primary_id: "D0N6HV",
    names: ["RO4988546"],
  },
  {
    primary_id: "D0N6IG",
    names: ["H3 Receptor Inverse Agonists"],
  },
  {
    primary_id: "D0N6IL",
    names: ["N-Hexyl-2-(2-Phenyl-1H-Indol-3-Yl)-Acetamide", "CHEMBL329499", "BDBM50045876"],
  },
  {
    primary_id: "D0N6IY",
    names: ["TZ101"],
  },
  {
    primary_id: "D0N6JC",
    names: ["MT-3724"],
  },
  {
    primary_id: "D0N6JE",
    names: ["Methyl-Pentyl-Prop-2-Ynyl-Amine Oxalic Acid"],
  },
  {
    primary_id: "D0N6JF",
    names: ["H-PGDS Inhibitors"],
  },
  {
    primary_id: "D0N6JH",
    names: ["N-Substituted 9-Azabicyclo[3.3.1]nonan-3alpha-Yl-Phenylcarbamate Analog 3"],
  },
  {
    primary_id: "D0N6JZ",
    names: ["Aminocyclopentenone Compound 4"],
  },
  {
    primary_id: "D0N6KO",
    names: ["THIQ"],
  },
  {
    primary_id: "D0N6LH",
    names: ["Ac-I[CV(1Nal)QDWGAHRC]T"],
  },
  {
    primary_id: "D0N6LY",
    names: ["Non-Nucleoside HCV NS5B Polymerase Inhibitors"],
  },
  {
    primary_id: "D0N6MD",
    names: ["PMID30247903-Compound-General Structure23"],
  },
  {
    primary_id: "D0N6MR",
    names: [
      "1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One",
      "CHEMBL98604",
      "SCHEMBL2014813",
      "BDBM50007220",
      "1H-Imidazo[4,5-B]quinolin-2(3H)-One",
    ],
  },
  {
    primary_id: "D0N6MX",
    names: ["Gamunex-C"],
  },
  {
    primary_id: "D0N6NA",
    names: ["1,3,4-Thiadiazole Derivative 2"],
  },
  {
    primary_id: "D0N6NG",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 7"],
  },
  {
    primary_id: "D0N6NM",
    names: [
      "CEP-37440",
      "Dual ALK/FAK Inhibitor (Cancer), Cephalon",
      "Dual Anaplastic Lymphoma Kinase/Focal Adhesion Kinase Inhibitor (Cancer),Cephalon",
    ],
  },
  {
    primary_id: "D0N6OE",
    names: [
      "Benafentrine Dimaleate",
      "AH-21-132",
      "Cis-6-(4-Acetamidophenyl)-8,9-Dimethoxy-2-Methyl-1,2,3,4,4a,10b-Hexahydrobenzo[c][1,6]naphthyridine Bis(Hydrogenmaleate)",
      "Cis-4'-(1,2,3,4,4a,10b-Hexahydro-8,9-Dimethoxy-2-Methylbenzo[c][1,6]naphthyridin-6-Yl)Acetanilide Bis(Hydrogenmaleate)",
      "Cis-N-[4-(1,2,3,4,4a,10b-Hexahydro-8,9-Dimethoxy-2-Methylbenzo[c][1,6]naphthyridin-6-Yl)Phenyl]acetamide (Z)-2-Butenedioate (1:2)",
    ],
  },
  {
    primary_id: "D0N6OH",
    names: ["(+/-)-Threo-N-Propargylmethylphenidate", "CHEMBL1254282"],
  },
  {
    primary_id: "D0N6OL",
    names: ["ADS-103253", "CHEMBL216228", "SCHEMBL6507329", "BDBM50200788"],
  },
  {
    primary_id: "D0N6PB",
    names: ["VM-106"],
  },
  {
    primary_id: "D0N6PE",
    names: ["CART-123 Cells"],
  },
  {
    primary_id: "D0N6PV",
    names: ["MK-852"],
  },
  {
    primary_id: "D0N6QB",
    names: ["MT-112", "BiTE Antibody (Solid Tumor), Micromet/Bayer"],
  },
  {
    primary_id: "D0N6QM",
    names: [
      "(1-Benzyl-2-Oxo-Ethyl)-Carbamic Acid Benzyl Ester",
      "Cbz-L-Phenylalaninal",
      "59830-60-3",
      "(S)-Benzyl (1-Oxo-3-Phenylpropan-2-Yl)Carbamate",
      "CHEMBL412423",
      "AK-43912",
      "N-Cbz-Phenylalaninal",
      "PubChem16008",
      "N-Cbz-L-Phenylalaninal",
      "AC1ODUQ7",
      "SCHEMBL630290",
      "KS-00000FVM",
      "DTXSID40427338",
      "ZX-AFC000446",
      "MolPort-009-682-951",
      "HZDPJHOWPIVWMR-INIZCTEOSA-N",
      "N-Benzyloxycarbonyl-L-Phenylalaninal",
      "ZINC2569810",
      "BDBM50014568",
      "8910AB",
      "ANW-72305",
      "AKOS005146049",
      "CC-1855",
      "KB-75975",
      "N-((Benzyloxy)Carbonyl)-L-Phenylalaninal",
      "AC-23793",
      "DS-14328",
      "AJ-41774",
      "AX8120205",
    ],
  },
  {
    primary_id: "D0N6QO",
    names: ["RP-1040"],
  },
  {
    primary_id: "D0N6QU",
    names: ["2-Hydroxygarveatin E"],
  },
  {
    primary_id: "D0N6QW",
    names: ["4-(3-Bromobenzylthio)-2-Aminobutanoic Acid", "CHEMBL380929", "BDBM50179727"],
  },
  {
    primary_id: "D0N6RF",
    names: ["Telmisartan"],
  },
  {
    primary_id: "D0N6SO",
    names: ["SYR-472"],
  },
  {
    primary_id: "D0N6TC",
    names: [
      "N5-[4-(2-Phenylethoxy)Phenyl]-L-Glutamine",
      "CHEMBL478993",
      "Modified Amino Acid Analog, 8i",
      "BDBM24274",
      "(2S)-2-Amino-4-{[4-(2-Phenylethoxy)Phenyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D0N6TD",
    names: ["BIW-8962"],
  },
  {
    primary_id: "D0N6UA",
    names: ["Leukemia DNA Vaccine"],
  },
  {
    primary_id: "D0N6UJ",
    names: ["Diacol"],
  },
  {
    primary_id: "D0N6UO",
    names: ["PMID29473428-Compound-47"],
  },
  {
    primary_id: "D0N6UP",
    names: ["ETHIMIZOL", "1-Ethyl-4,5-Bis(Methylcarbamoyl)Imidazole"],
  },
  {
    primary_id: "D0N6VK",
    names: [
      "Hepapoietin",
      "APS-1010",
      "Hepapoietin, SnowBrand",
      "Hepapoietin (Liver/Kidney Disease)",
      "Hepapoietin (Liver/Kidney Disease), Atlas Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0N6VS",
    names: ["ADU-S100"],
  },
  {
    primary_id: "D0N6WW",
    names: ["PMID29671355-Compound-14"],
  },
  {
    primary_id: "D0N6WX",
    names: ["AI-128"],
  },
  {
    primary_id: "D0N6XS",
    names: ["1-(10H-Phenothiazin-10-Yl)-2-Phenylbutan-1-One", "CHEMBL240261"],
  },
  {
    primary_id: "D0N6YN",
    names: ["BN-DF-037"],
  },
  {
    primary_id: "D0N6YV",
    names: ["Cenestin"],
  },
  {
    primary_id: "D0N6ZK",
    names: ["CGP-20376"],
  },
  {
    primary_id: "D0N6ZO",
    names: ["6-Cinnamoyl-N-Methylstephasunoline", "CHEMBL1098354"],
  },
  {
    primary_id: "D0N6ZP",
    names: ["SKF-105494"],
  },
  {
    primary_id: "D0N7AB",
    names: ["ISIS 114404"],
  },
  {
    primary_id: "D0N7AD",
    names: [
      "Azatadine",
      "Azatadina",
      "Azatadinum",
      "Azatidine",
      "Azatadine Maleate",
      "Azatadina [INN-Spanish]",
      "Azatadine (INN)",
      "Azatadine [INN:BAN]",
      "Azatadinum [INN-Latin]",
      "11-(1-Methyl-4-Piperidinylidene)-6,11-Dihydro-5H-Benzo[5,6]cyclohepta[1,2-B]pyridine",
      "11-(1-Methylpiperidin-4-Ylidene)-6,11-Dihydro-5H-Benzo[5,6]cyclohepta[1,2-B]pyridine",
      "6,11-Dihydro-11-(1-Methyl-4-Piperidylidene)-5H-Benzo(5,6)Cyclohepta(1,2-B)Pyridine",
    ],
  },
  {
    primary_id: "D0N7AF",
    names: ["ISIS 29155"],
  },
  {
    primary_id: "D0N7AJ",
    names: ["AST-487", "AST 487", "NVP-AST 487", "NVP-AST487"],
  },
  {
    primary_id: "D0N7AU",
    names: ["Nifurzide"],
  },
  {
    primary_id: "D0N7BL",
    names: ["UK-240455"],
  },
  {
    primary_id: "D0N7BP",
    names: ["Z-Arg-Leu-Val-Agly-Val-Ala-NH2"],
  },
  {
    primary_id: "D0N7BW",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 1"],
  },
  {
    primary_id: "D0N7CQ",
    names: [
      "Rac-4'-(1-Imidazol-1-Yl-Propyl)-Biphenyl-3,4-Diol",
      "CHEMBL551253",
      "BDBM50295625",
      "4'-[1-(1H-Imidazole-1-Yl)Propyl]biphenyl-3,4-Diol",
    ],
  },
  {
    primary_id: "D0N7CU",
    names: ["HG-1182"],
  },
  {
    primary_id: "D0N7CY",
    names: ["VLI-27", "AKT Inhibitor (Pancreatic Cancer), NovaLead Pharma"],
  },
  {
    primary_id: "D0N7DQ",
    names: ["APC-100"],
  },
  {
    primary_id: "D0N7DW",
    names: ["Needle-Free Influenza Vaccine Patch"],
  },
  {
    primary_id: "D0N7FD",
    names: ["Multi-Epitope Tyrosinase/Gp100 Vaccine"],
  },
  {
    primary_id: "D0N7FU",
    names: ["D-Phe-Cys-Tyr-D-Trp-Orn-Thr-Pen-Thr-NH2(CTOP)", "CHEMBL406014"],
  },
  {
    primary_id: "D0N7GN",
    names: ["Aerucin"],
  },
  {
    primary_id: "D0N7HN",
    names: ["(E)-N-(3-Iodoprop-2-Enyl)-2beta-Carbomethoxy-3beta-(4'-Methylphenyl)N"],
  },
  {
    primary_id: "D0N7HS",
    names: ["ASP-3258"],
  },
  {
    primary_id: "D0N7HT",
    names: ["PRO-135", "Antisense Oligonucleotide Dystrophin Modulator (Myotonic Dystrophy), Prosensa"],
  },
  {
    primary_id: "D0N7HY",
    names: ["Org 12962"],
  },
  {
    primary_id: "D0N7IH",
    names: ["N*1*-(6-Nitro-Quinolin-2-Yl)-Ethane-1,2-Diamine", "CHEMBL38754", "BDBM50090212", "AKOS009510501"],
  },
  {
    primary_id: "D0N7JP",
    names: ["TEM"],
  },
  {
    primary_id: "D0N7JS",
    names: ["PG11047"],
  },
  {
    primary_id: "D0N7JZ",
    names: ["SAND-26", "Begedina", "CD26-Targeting Antibody (Autoimmunity), ADIENNE"],
  },
  {
    primary_id: "D0N7KK",
    names: ["5-Methoxy-2-(2-(Pyrrolidin-1-Yl)Ethyl)-1H-Indole", "CHEMBL403264"],
  },
  {
    primary_id: "D0N7KV",
    names: ["Reniale", "Autologous Renal Tumor Cell Vaccine (Renal Cell Carcinoma), LipoNova"],
  },
  {
    primary_id: "D0N7LA",
    names: ["BIBH 1"],
  },
  {
    primary_id: "D0N7LZ",
    names: ["SK&F-104856"],
  },
  {
    primary_id: "D0N7ML",
    names: ["PV-707"],
  },
  {
    primary_id: "D0N7MQ",
    names: ["Small Molecule 33"],
  },
  {
    primary_id: "D0N7MS",
    names: ["ACAA", "ACA"],
  },
  {
    primary_id: "D0N7NB",
    names: ["5-N-Undecyl-6-Hydroxy-4,7-Dioxobenzothiazole"],
  },
  {
    primary_id: "D0N7NK",
    names: ["E6801", "E 6801", "E-6801"],
  },
  {
    primary_id: "D0N7OK",
    names: ["ISIS 112001"],
  },
  {
    primary_id: "D0N7OP",
    names: ["2-(2-Methoxyphenyl)-1H-Indene", "CHEMBL376583", "2-(O-Methoxyphenyl)-1H-Indene", "SCHEMBL16621596"],
  },
  {
    primary_id: "D0N7OV",
    names: ["Vacquinol-1"],
  },
  {
    primary_id: "D0N7PF",
    names: ["Technetium Tc-99m Tetrofosmin Kit"],
  },
  {
    primary_id: "D0N7QP",
    names: ["NO-Antihypertensives"],
  },
  {
    primary_id: "D0N7QV",
    names: [
      "4-(1-Imidazol-1-Yl-Vinyl)-Benzonitrile",
      "CHEMBL31760",
      "Benzonitrile, 4-[1-(1H-Imidazol-1-Yl)Ethenyl]-",
      "112809-36-6",
      "SCHEMBL1507802",
      "BDBM50047259",
      "4-(1-(1H-Imidazol-1-Yl)Vinyl)Benzonitrile",
    ],
  },
  {
    primary_id: "D0N7RK",
    names: ["PMID19502059C25d"],
  },
  {
    primary_id: "D0N7RW",
    names: ["5-Fluoro-2-Hydroxy-N-Isopropyl-Benzamide", "CHEMBL84730", "BDBM50084016", "AKOS018088189"],
  },
  {
    primary_id: "D0N7SG",
    names: ["R-84760"],
  },
  {
    primary_id: "D0N7UE",
    names: [
      "Imidazolidin-2-Ylidene-Quinoxalin-6-Yl-Amine",
      "91147-43-2",
      "CHEMBL49395",
      "Brimonidine Impurity II",
      "6-Quinoxalinamine, N-(4,5-Dihydro-1H-Imidazol-2-Yl)-",
      "SCHEMBL679239",
      "N-(4,5-Dihydro-1H-Imidazol-2-Yl)Quinoxalin-6-Amine",
      "SCHEMBL12860178",
      "ZINC14042",
      "CTK3I1267",
      "DTXSID00436663",
      "MolPort-039-240-453",
      "N-(4,5-DIHYDRO-1H-IMIDAZOL-2-YL)-6-QUINOXALINAMINE",
      "BDBM50213029",
      "N-Quinoxalin-6-Ylimidazolidin-2-Imine",
      "AKOS030240596",
    ],
  },
  {
    primary_id: "D0N7VL",
    names: ["Asp-BrPmp-Leu", "CHEMBL1289231", "BDBM50331945"],
  },
  {
    primary_id: "D0N7WB",
    names: ["CLR-151", "CLR-100 Series", "CLR-136", "CLR-155", "CLR-161"],
  },
  {
    primary_id: "D0N7WG",
    names: ["AM-111"],
  },
  {
    primary_id: "D0N7WP",
    names: ["4-(2-Pyridin-2-Ylacetamido)Benzenesulfonamide"],
  },
  {
    primary_id: "D0N7WR",
    names: ["Sepimostat Mesylate"],
  },
  {
    primary_id: "D0N7WW",
    names: ["RVX-208"],
  },
  {
    primary_id: "D0N7XI",
    names: ["Tremetinib"],
  },
  {
    primary_id: "D0N7XM",
    names: ["(-)-3,3'-Bisdemethylpinoresinol", "CHEMBL227187", "BDBM50208822", "J3.605.566I"],
  },
  {
    primary_id: "D0N7XW",
    names: ["Human Enterovirus 71 Vaccine"],
  },
  {
    primary_id: "D0N7XY",
    names: [
      "111In-Labeled Lactam Bridge-Cyclized Alpha-Melanocyte-Stimulating Hormone Peptide (Melanoma), NuView/University Of New Mexico",
    ],
  },
  {
    primary_id: "D0N7YK",
    names: ["YPEG-EPO"],
  },
  {
    primary_id: "D0N7YT",
    names: ["4-(P-Toluidino)-3-Isopropylnaphthalene-1,2-Dione", "CHEMBL204456"],
  },
  {
    primary_id: "D0N7ZF",
    names: ["FGGFTGARKSARKWANQ"],
  },
  {
    primary_id: "D0N7ZT",
    names: ["13-Acetate"],
  },
  {
    primary_id: "D0N8AB",
    names: ["PF-4629991"],
  },
  {
    primary_id: "D0N8AO",
    names: ["4-(4-Benzenesulfonamidophenyl)Piperazine", "CHEMBL426332", "SCHEMBL18553766"],
  },
  {
    primary_id: "D0N8BA",
    names: ["Bicyclic Pyrimidine Derivative 2"],
  },
  {
    primary_id: "D0N8BJ",
    names: ["[3H]5-HT", "[3H]-5-HT"],
  },
  {
    primary_id: "D0N8CI",
    names: ["PMID25522065-Compound-15"],
  },
  {
    primary_id: "D0N8CM",
    names: ["PAV-616"],
  },
  {
    primary_id: "D0N8CN",
    names: ["BVB-808"],
  },
  {
    primary_id: "D0N8CU",
    names: ["Benzo[d]oxazol-2(3H)-One Derivative 3"],
  },
  {
    primary_id: "D0N8DP",
    names: ["Bentazepam"],
  },
  {
    primary_id: "D0N8EC",
    names: [
      "BIS(8)-HUPERZINE B",
      "Bis(8)-Huperzine B",
      "Bis-Huperzine B 5a",
      "Bis(8)-Hup B",
      "CHEMBL360092",
      "BDBM10941",
    ],
  },
  {
    primary_id: "D0N8EI",
    names: ["4-(Butyl(Methyl)Amino)-N-(Quinolin-3-Yl)Benzamide", "CHEMBL258915", "SCHEMBL845760"],
  },
  {
    primary_id: "D0N8EL",
    names: ["SB 204070"],
  },
  {
    primary_id: "D0N8EU",
    names: [
      "(S)-2-Amino-5-(N-Methyl-Guanidino)-Pentanoic Acid",
      "Delta-N-Methylarginine",
      "N(5)-Methyl-L-Arginine",
      "CHEMBL99636",
      "N(5)-Carbamimidoyl-N(5)-Methyl-L-Ornithine",
      "CHEBI:21848",
      "77044-73-6",
      "L-NMA",
      "6-Monomethyl-L-Arginine",
      "AC1Q3XG3",
      "AC1L5A8F",
      "SCHEMBL2307076",
      "DTXSID70227848",
      "BDBM50049254",
      "L-Ornithine, N(5)-(Aminoiminomethyl)-N(5)-Methyl-",
      "(2S)-2-Amino-5-[carbamimidoyl(Methyl)Amino]pentanoic Acid",
      "(2S)-2-Amino-5-(Carbamimidoyl-Methylamino)Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0N8FQ",
    names: [
      "Reboxetine",
      "71620-89-8",
      "(2r)-2-[(R)-(2-Ethoxyphenoxy)(Phenyl)Methyl]morpholine",
      "Morpholine, 2-[(2-Ethoxyphenoxy)Phenylmethyl]-, (R*,R*)-",
      "Morpholine, 2-[(R)-(2-Ethoxyphenoxy)Phenylmethyl]-, (2R)-Rel-",
      "Reboxetine [INN:BAN]",
      "Morpholine, 2-((R)-(2-Ethoxyphenoxy)Phenylmethyl)-, (2R)-Rel-",
      "HSDB 7701",
      "Edronax (TN)",
      "(R,R)-Reboxetine",
      "AC1L2RJ0",
      "SCHEMBL34533",
      "CHEMBL383921",
      "DTXSID1048257",
      "MolPort-005-943-618",
      "CHEBI:135342",
      "ZINC3996032",
      "BCP12060",
      "AKOS015966368",
      "DB00234",
      "AN-34535",
      "AK431803",
      "AJ-47614",
    ],
  },
  {
    primary_id: "D0N8FU",
    names: ["2-Tert-Butoxy-9H-Carbazole", "CHEMBL1170845", "SCHEMBL13064179"],
  },
  {
    primary_id: "D0N8GC",
    names: ["MRX-4TZT"],
  },
  {
    primary_id: "D0N8GR",
    names: ["CZ-775"],
  },
  {
    primary_id: "D0N8IG",
    names: ["PdpSC18"],
  },
  {
    primary_id: "D0N8IL",
    names: ["PF-06425090"],
  },
  {
    primary_id: "D0N8JA",
    names: ["Theradigm-HBV", "CY-1899", "Theradigm-Hepatitis B Virus"],
  },
  {
    primary_id: "D0N8JB",
    names: ["JTT-552"],
  },
  {
    primary_id: "D0N8JG",
    names: ["FolateImmune"],
  },
  {
    primary_id: "D0N8JJ",
    names: ["MDDR 217769", "(E)-2-(4,6-Difluoroindan-1-Ylidene)Acetamide", "217769"],
  },
  {
    primary_id: "D0N8JP",
    names: ["JTV-803"],
  },
  {
    primary_id: "D0N8KZ",
    names: [
      "1-Nitro-2-(2-Nitrophenylsulfonyl)Benzene",
      "Bis(2-Nitrophenyl)Sulfone",
      "NSC633001",
      "14665-52-2",
      "Benzene, 1,1'-Sulfonylbis[2-Nitro-",
      "NSC-633001",
      "1-Nitro-2-[(2-Nitrophenyl)Sulfonyl]benzene",
      "Bis(2NO2Ph)SO2",
      "AC1Q6URW",
      "AC1L7QPG",
      "NCIMech_000590",
      "MLS001196654",
      "SCHEMBL2039879",
      "BDBM1780",
      "CHEMBL415959",
      "CTK0E9275",
      "DTXSID40327122",
      "MolPort-006-834-474",
      "Benzene,1'-Sulfonylbis(2-Nitro-",
      "HMS2872L23",
      "ZINC1622822",
      "CCG-35737",
      "AKOS001662347",
      "MCULE-4947941526",
      "1-Nitro-2-(2-Nitrophenyl)Sulfonylbenzene",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 4b",
      "SMR000556031",
      "NCI60_010909",
    ],
  },
  {
    primary_id: "D0N8LP",
    names: ["Beta-Endorphin"],
  },
  {
    primary_id: "D0N8LR",
    names: ["N-(4-Sulfamoyl-Phenyl)-Propionamide"],
  },
  {
    primary_id: "D0N8MC",
    names: ["CLL Immunotherapeutic"],
  },
  {
    primary_id: "D0N8MN",
    names: ["TNK-651", "175739-42-1"],
  },
  {
    primary_id: "D0N8MQ",
    names: ["GSK-159797"],
  },
  {
    primary_id: "D0N8NC",
    names: [
      "SAVOXEPIN MESYLATE",
      "CIPA",
      "Cipazoxapine",
      "CGP-19486A",
      "Savoxepin Mesylate",
      "3-(Cyclopentylmethyl)-2,3,4,5-Tetrahydro-1H-Dibenz[2,3:6,7]oxepino[4,5-D]azepine-7-Carbonitrile Methanesulfonate",
    ],
  },
  {
    primary_id: "D0N8NK",
    names: ["AZD-6605"],
  },
  {
    primary_id: "D0N8NN",
    names: [
      "CT-12441",
      "DT-22669",
      "DT-23552",
      "IL-12 Modulators (Oral, Diabetes/LADA/Diabetic Nephropathy/Diabetic Retinopathy)",
      "IL-12 Modulators (Oral, Diabetes/LADA/Diabetic Nephropathy/Diabetic Retinopathy), DiaKine/University Of Virginia",
    ],
  },
  {
    primary_id: "D0N8NT",
    names: ["BCMA-UCART"],
  },
  {
    primary_id: "D0N8OO",
    names: ["Sigmoidin F"],
  },
  {
    primary_id: "D0N8PF",
    names: ["Pyrazole Derivative 70"],
  },
  {
    primary_id: "D0N8PG",
    names: ["CYC-700 Series"],
  },
  {
    primary_id: "D0N8PI",
    names: [
      "2-Amino-6-(3-Chloro-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(3-Chlorophenylthio)Benzenecarbonitrile",
      "AC1LAE0K",
      "CHEMBL55599",
      "SCHEMBL2637932",
      "BDBM1739",
      "ZINC20476",
      "CTK7C6436",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1i",
      "2-Amino-6-(3-Chlorophenyl)Sulfanylbenzonitrile",
      "2-Amino-6-(3-Chlorophenyl)Sulfanyl-Benzonitrile",
    ],
  },
  {
    primary_id: "D0N8PV",
    names: [
      "Bovine Alkaline Phosphatase Iv",
      "Bovine Alkaline Phosphatase (Iv, Renal Failure), AM-Pharma",
      "Bovine Alkaline Phosphatase (Oral, Ulcerativecolitis), AM-Pharma",
    ],
  },
  {
    primary_id: "D0N8QD",
    names: [
      "(4-((1H-Imidazol-1-Yl)Methyl)Phenyl)Methanol",
      "[4-(1H-Imidazol-1-Ylmethyl)Phenyl]methanol",
      "103573-92-8",
      "CHEMBL590987",
      "{4-[(1H-Imidazol-1-Yl)Methyl]phenyl}methanol",
      "[4-(Imidazol-1-Ylmethyl)Phenyl]methanol",
      "SCHEMBL10661787",
      "CTK8G4735",
      "DTXSID80575387",
      "FSLVBXCLXDGFRE-UHFFFAOYSA-N",
      "MolPort-000-143-260",
      "SBB090788",
      "ZINC12370277",
      "BDBM50307211",
      "AKOS006344218",
      "RP03593",
      "[4-(Imidazolylmethyl)Phenyl]methan-1-Ol",
      "KB-63739",
      "DB-059022",
      "4-(1H-Imidazole-1-Ylmethyl)Benzenemethanol",
      "Y6875",
      "FT-0708208",
      "4-((1H-Imidazol-1-Yl)Methyl)Phenylmethanol",
    ],
  },
  {
    primary_id: "D0N8QK",
    names: [
      "ABT-288",
      "UNII-5MEI1M3NHH",
      "5MEI1M3NHH",
      "ABT 288",
      "GNIRITULTPTAQWKNQAVFIVSAN",
      "SCHEMBL2406947",
      "ABT288",
      "GTPL6927",
      "GNIRITULTPTAQW-KNQAVFIVSA-N",
      "948845-91-8",
      "J3.497.401B",
      "2-[4'-(3aR,6aR)-(5-Methylhexahydropyrrolo[3,4-B]pyrrol-1(2H)-Yl)-1,1'-Biphenyl-4-Yl]pyridazin-3(2H)-One",
      "2-{4'-[(3aR,6aR)-5-Methylhexahydropyrrolo[3,4-B]pyrrol-1(2H)-Yl]-1,1-Biphenyl-4-Yl}pyridazin-3(2H)-One",
      "2-[4'-(3aR,6aR)-(5-Methylhexahydropyrrolo[3,4-B]pyrrol-1(2H)-Yl)-1,1'-Biphenyl-4-Yl ]pyridazin-3(2H)-One",
      "(3aR, 6aR)-2-[4'-(5-Methyl-Hexahydro-Pyrrolo[3,4",
    ],
  },
  {
    primary_id: "D0N8QQ",
    names: [
      "CARIPORIDE",
      "Cariporide Mesylate",
      "UNII-0543W2JFRZ",
      "159138-81-5",
      "Cariporide Mesylate [USAN]",
      "CHEMBL462831",
      "0543W2JFRZ",
      "Cariporide Mesylate (USAN)",
      "Cariporide Mesilate",
      "N-(4-Isopropyl-3-Methanesulfonyl-Benzoyl)-Guanidine",
      "DSSTox_RID_82284",
      "DSSTox_CID_27344",
      "DSSTox_GSID_47344",
      "SCHEMBL221094",
      "DTXSID3047344",
      "AC1L4343",
      "Tox21_300231",
      "NCGC00254230-01",
      "CAS-159138-81-5",
      "D03406",
      "(4-Isopropyl-3-Methanesulfonyl-Benzoyl) Guanidine Methanesulfonate",
      "Benzamide, N-(Aminoiminomethyl)-4-(1-Methylethyl)-3-(Methylsulfonyl)-, Monomethanesulfonate",
    ],
  },
  {
    primary_id: "D0N8QR",
    names: ["GRF6019"],
  },
  {
    primary_id: "D0N8RA",
    names: ["GS-5829"],
  },
  {
    primary_id: "D0N8RD",
    names: ["N-[2-(1h-Indol-5-Yl)-Butyl]-4-Sulfamoyl-Benzamide"],
  },
  {
    primary_id: "D0N8RM",
    names: [
      "9-(2-Hydroxyethyl)-9H-Adenine",
      "2-(6-Aminopurin-9-Yl)Ethanol",
      "707-99-3",
      "9-(2-Hydroxyethyl)Adenine",
      "9h-Purine-9-Ethanol, 6-Amino-",
      "9-(2-Hydroxyethyl) Adenine",
      "CHEMBL95897",
      "6-Amino-9H-Purin-9-Ethanol",
      "VAQOTZQDXZDBJK-UHFFFAOYSA-N",
      "6-Amino-9-(2-Hydroxyethyl)Purine",
      "2-(6-Amino-9H-Purin-9-Yl)Ethanol",
      "NSC51467",
      "N9 -Hydroxyethyladenine",
      "AC1Q4VHV",
      "9-(2hydroxyethyl)Adenine",
      "ACMC-209og5",
      "TimTec1_003634",
      "9-(2'-Hydroxyethyl)Adenine",
      "9-(2-Hydroxyethyl)Adenine.",
      "SCHEMBL984944",
      "6-Amino-9h-Purine-9-Ethanol",
      "AC1L69W8",
      "ZINC36416",
      "KS-00000VZR",
    ],
  },
  {
    primary_id: "D0N8RT",
    names: [
      "Cyclopentyl(5-(Pyridin-2-Yl)Oxazol-2-Yl)Methanone",
      "Alpha-Ketooxazole, 14e",
      "SCHEMBL711639",
      "CHEMBL376397",
      "BDBM23134",
      "CZXHLRXAHDZQJF-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0N8RV",
    names: ["OSI-930"],
  },
  {
    primary_id: "D0N8TA",
    names: [
      "Acetyl-Ile-Glu-Thr-Asp-Aldehyde",
      "Ac-IETD-CHO",
      "191338-86-0",
      "CHEMBL478695",
      "Ac-Ile-Glu-Thr-Asp-CHO",
      "SCHEMBL20257131",
      "N-Acetyl-Ile-Glu-Thr-Asp-Aldehyde",
      "ZINC14947181",
      "BDBM50340547",
      "Acetyl-Isoleucyl-Glutamyl-Threonyl-Aspartal",
      "338A860",
      "N-Acetyl-Ile-Glu-Thr-Asp-Al, ~99% (TLC), Powder",
      "(4S)-4-{[(1S,2R)-1-{[(2S)-1-Carboxy-3-Oxopropan-2-Yl]carbamoyl}-2-Hydroxypropyl]carbamoyl}-4-[(2S,3S)-2-Acetamido-3-Methylpentanamido]butanoic Acid",
    ],
  },
  {
    primary_id: "D0N8TM",
    names: ["XGP-110"],
  },
  {
    primary_id: "D0N8TQ",
    names: [
      "N-(4-(Phenylamino)Quinazolin-6-Yl)Acrylamide",
      "CHEMBL1221699",
      "N-[4-(Phenylamino)Quinazolin-6-Yl]prop-2-Enamide",
      "Nchembio866-Comp4",
      "SCHEMBL614989",
      "BDBM50324687",
      "DB08462",
      "N-(4-Anilinoquinazoline-6-Yl)Acrylamide",
    ],
  },
  {
    primary_id: "D0N8UD",
    names: ["2,2,2-TRIFLUORO-1-(4-FLUOROPHENYL)-1-(1-(4-FLUOROPHENYL)-1H-INDAZOL-5-YL)ETHANOL (DIASTEREOMERIC MIX)"],
  },
  {
    primary_id: "D0N8UE",
    names: ["N-(2-Phenylethyl),N-(Pyrrol-2-Ylmethyl)Amine"],
  },
  {
    primary_id: "D0N8UQ",
    names: ["IMD-026259", "CNS Disorders Therapy, InterMed Discovery"],
  },
  {
    primary_id: "D0N8UU",
    names: ["ADX415"],
  },
  {
    primary_id: "D0N8UY",
    names: ["LY-686017"],
  },
  {
    primary_id: "D0N8VZ",
    names: [
      "2-Ethylestradiol 3,17-O,O-Bis-Sulfamate",
      "2-Ethylestradiol-3,17-O,O-Bis-Sulfamate",
      "CHEMBL425287",
      "(9beta,13alpha,14beta,17alpha)-2-Ethylestra-1(10),2,4-Triene-3,17-Diyl Disulfamate",
      "2-Ethyloestradiol-Bis-Sulfamate",
      "3oik",
      "WZB",
      "2x7t",
      "2-EbM",
      "SCHEMBL3690812",
      "BDBM50200941",
      "LS-193106",
      "2-Ethyl-3,17beta-Bis(Sulfamoyloxy)Estra-1,3,5(10)-Triene",
      "Estra-1,3,5(10)-Triene-3,17-Diol, 2-Ethyl-, Disulfamate, (17beta)-",
    ],
  },
  {
    primary_id: "D0N8WR",
    names: ["Aminobenzovesamicol", "Compound 11 [PMID: :7702637]", "M-Iodobenzyltrozamicol"],
  },
  {
    primary_id: "D0N8XL",
    names: ["BMS-605339"],
  },
  {
    primary_id: "D0N8YA",
    names: [
      "Bb-3497",
      "BB-3497",
      "CHEMBL431210",
      "BB 3497",
      "235784-88-0",
      "2-[(FORMYL-HYDROXY-AMINO)-METHYL]-HEXANOIC ACID (1-DIMETHYLCARBAMOYL-2,2-DIMETHYL-PROPYL)-AMIDE",
      "(2r)-N-[(2s)-1-(Dimethylamino)-3,3-Dimethyl-1-Oxobutan-2-Yl]-2-{[formyl(Hydroxy)Amino]methyl}hexanamide",
      "BB1",
      "1g27",
      "(2R)-N-[(2S)-1-(Dimethylamino)-3,3-Dimethyl-1-Oxobutan-2-Yl]-2-[[formyl(Hydroxy)Amino]methyl]hexanamide",
      "AC1L4M3L",
      "AC1Q5HZ8",
      "SCHEMBL13754309",
      "DTXSID80178216",
      "BDBM50104501",
      "BB3497",
      "DB04368",
      "(R)-2-[(Formyl-Hydroxy-Amino)-Methyl]-Hexanoic Acid ((S)-1-Dimethylcarbamoyl-2,2-",
    ],
  },
  {
    primary_id: "D0N8YG",
    names: ["1-((S)-2-Aminopropyl)-7-Methyl-1H-Indazol-6-Ol"],
  },
  {
    primary_id: "D0N8YJ",
    names: ["PMID28270010-Compound-Figure21-B"],
  },
  {
    primary_id: "D0N8YZ",
    names: [
      "1-(3,4-Dimethyl-Phenyl)-2-Phenyl-Ethane-1,2-Dione",
      "1-(3,4-Dimethylphenyl)-2-Phenylethane-1,2-Dione",
      "59411-15-3",
      "PubChem22503",
      "AC1M6ONN",
      "Benzil-Based Compound, 19",
      "AC1Q2DS8",
      "SCHEMBL2156354",
      "CHEMBL193140",
      "CTK5A9919",
      "BDBM22741",
      "DTXSID20368619",
      "MolPort-000-513-246",
      "ZINC3311689",
      "AKOS000118620",
      "MCULE-1712710570",
      "NE45262",
      "AJ-44881",
      "ST010102",
      "KB-213539",
      "J3.541.808C",
      "EN300-11497",
    ],
  },
  {
    primary_id: "D0N8ZJ",
    names: [
      "Dipeptide Therapeutic",
      "Anxiolytic, Kansai Technology Licensing Organization Co Ltd",
      "Dipeptide Therapeutic (Anxiety)",
      "Dipeptide Therapeutic (Anxiety), Kansai Technology Licensing Organization Co Ltd",
    ],
  },
  {
    primary_id: "D0N8ZS",
    names: [
      "Camonagrel",
      "105920-77-2",
      "Camonagrelum",
      "5-(2-Imidazol-1-Ylethoxy)-2,3-Dihydro-1H-Indene-1-Carboxylic Acid",
      "1H-Indene-1-Carboxylicacid, 2,3-Dihydro-5-[2-(1H-Imidazol-1-Yl)Ethoxy]-",
      "Camonagrel [INN]",
      "Camonagrelum [INN-Latin]",
      "ACMC-20m98b",
      "SCHEMBL634930",
      "GTPL5157",
      "AC1Q5V03",
      "AC1L246T",
      "CHEMBL2104622",
      "CTK0H6165",
      "(+-)-5-(2-Imidazol-1-Ylethoxy)-1-Indancarboxylic Acid",
      "5-[2-(1H-Imidazol-1-Yl)Ethoxy]-2,3-Dihydro-1H-Indene-1-Carboxylic Acid",
      "( Inverted Exclamation MarkA)-5-(2-Imidazol-1-Ylethoxy)-1-Indancarboxylic Acid",
    ],
  },
  {
    primary_id: "D0N9AD",
    names: ["RNS-60"],
  },
  {
    primary_id: "D0N9AK",
    names: ["ST-2986"],
  },
  {
    primary_id: "D0N9AS",
    names: ["GW-549771"],
  },
  {
    primary_id: "D0N9AT",
    names: ["ISIS 29178"],
  },
  {
    primary_id: "D0N9BU",
    names: ["SSR-97225"],
  },
  {
    primary_id: "D0N9CV",
    names: ["PMID25482888-Compound-11"],
  },
  {
    primary_id: "D0N9DB",
    names: [
      "9-Amino-7H-Dibenzo[de,h]quinolin-7-One",
      "CHEMBL246681",
      "9-Amino-1-Azabenzanthrone",
      "SCHEMBL18573118",
      "BDBM50211247",
      "9-Amino-1-Aza-Benzo[de]anthracen-7-One",
    ],
  },
  {
    primary_id: "D0N9DG",
    names: [
      "3-Isopropoxy-9H-Beta-Carboline",
      "CHEMBL67421",
      "AC1NFSY9",
      "3-Isopropyloxy-Beta-Carboline",
      "CHEMBL458006",
      "SCHEMBL10642443",
      "ZINC6858948",
      "BDBM50001471",
    ],
  },
  {
    primary_id: "D0N9DP",
    names: ["4-Hydroxy-3-Nonanoyl-6-Octyl-Pyran-2-One", "CHEMBL7816", "SCHEMBL10337787"],
  },
  {
    primary_id: "D0N9DS",
    names: ["PMID27607364-Compound-4"],
  },
  {
    primary_id: "D0N9DU",
    names: ["Indirubin Derivative E804"],
  },
  {
    primary_id: "D0N9DY",
    names: ["3-Iodoboldine", "CHEMBL256075", "BDBM50202337"],
  },
  {
    primary_id: "D0N9DZ",
    names: ["Elmustine"],
  },
  {
    primary_id: "D0N9ET",
    names: [
      "MRSAid",
      "Antibacterial Photosensitizer Conjugates (Gold Nanoparticle), Ondine BioPharma/UCL",
      "Antibacterial Photosensitizer Conjugates (Gold Nanoparticle), Ondine Biomedical/UCL",
      "Antibacterial Photosensitizer Conjugates (Nasal, Bacterial Infection), Ondine Biomedical",
      "Antibacterial Photosensitizer Conjugates (Nasal, Bacterialinfection), Ondine Biopharma",
    ],
  },
  {
    primary_id: "D0N9FH",
    names: [
      "2-Phenoxymethyl-4,5-Dihydro-1H-Imidazole",
      "2-(Phenoxymethyl)-4,5-Dihydro-1H-Imidazole",
      "CHEMBL14640",
      "43111-36-0",
      "SCHEMBL546875",
      "2-(Phenoxymethyl)-2-Imidazoline",
      "BDBM50179395",
      "AKOS006278066",
      "1H-Imidazole, 4,5-Dihydro-2-(Phenoxymethyl)-",
    ],
  },
  {
    primary_id: "D0N9FJ",
    names: ["PMID25666693-Compound-7"],
  },
  {
    primary_id: "D0N9GC",
    names: ["Neorphine"],
  },
  {
    primary_id: "D0N9GH",
    names: ["PMID28454500-Compound-9"],
  },
  {
    primary_id: "D0N9HG",
    names: [
      "18-HETE",
      "18-Hete",
      "(+/-)18-HETE",
      "(5Z,8Z,11Z,14Z)-18-Hydroxyicosa-5,8,11,14-Tetraenoic Acid",
      "18-Hydroxy-5Z,8Z,11Z,14Z-Eicosatetraenoic Acid",
      "133268-58-3",
      "18-Hydroxyarachidonic Acid",
      "18-Hydroxyeicosatetraenoic Acid",
      "(Z,Z,Z,Z)-18-Hydroxy-5,8,11,14-Icosatetraenoic Acid",
      "(Z,Z,Z,Z)-18-Hydroxy-5,8,11,14-Eicosatetraenoic Acid",
      "(5Z,8Z,11Z,14Z)-18-Hydroxyeicosa-5,8,11,14-Tetraenoic Acid",
      "(5Z,8Z,11Z,14Z)-18-Hydroxy-5,8,11,14-Eicosatetraenoic Acid",
      "128656-74-6",
      "(Z,Z,Z,Z)-18-Hydroxy-5,8,11,14-Icosatetraenoate",
    ],
  },
  {
    primary_id: "D0N9HX",
    names: ["N-(3-(Trifluoromethoxy)Benzyl)-4-Phenoxybenzamide", "CHEMBL198832"],
  },
  {
    primary_id: "D0N9IA",
    names: ["Tetra-Hydro-Imidazo[1,5-D][1,4]oxazepin-3-Yl Derivative 4"],
  },
  {
    primary_id: "D0N9IN",
    names: ["MTX-25251"],
  },
  {
    primary_id: "D0N9IX",
    names: [
      "N-Adamantanemethyloxypentyl-1-Deoxynojirimycin",
      "AMP-Deoxynojirimycin",
      "AMP-DNM",
      "216758-20-2",
      "CHEMBL574645",
      "SCHEMBL17132950",
      "SCHEMBL13777990",
      "CTK4E7471",
      "MolPort-044-562-021",
      "ZINC33989734",
      "BDBM50299749",
      "1613AH",
      "AKOS030538164",
      "RT-011277",
      "N-(Adamantanemethyloxypentyl)-Deoxynojirimycin",
      "J-014228",
      "(2R,3R,4R,5S)-1-[5-(1-Adamantylmethoxy)Pentyl]-2-(Hydroxymethyl)Piperidine-3,4,5-Triol",
      "3,4,5-PIPERIDINETRIOL, 2-(HYDROXYMETHYL)-1-[5-(TRICYCLO[3.3.1.13,7]DEC-1-YLMETHOXY)PENTYL]-, (2R,3R,4R,5S)-",
    ],
  },
  {
    primary_id: "D0N9JB",
    names: ["SAR228810"],
  },
  {
    primary_id: "D0N9JJ",
    names: ["PMID25399762-Compound-Table 7-Ethyl Vanillin"],
  },
  {
    primary_id: "D0N9KZ",
    names: [
      "TN-871",
      "1-[6-Butyl-5H-Indeno[5,6-D]-1,3-Dioxol-5-Yl]-4-Methylpiperazine Dihydrochloride",
      "2-N-Butyl-5,6-Methylenedioxy-1-(4-Methylpiperazinyl)Indene Dihydrochloride",
    ],
  },
  {
    primary_id: "D0N9MH",
    names: ["JMV 1799"],
  },
  {
    primary_id: "D0N9NA",
    names: ["Cypate-[(RGD)4-NH2]2"],
  },
  {
    primary_id: "D0N9NS",
    names: ["(R)-2-(2-Naphthamido)-3-M-Tolylpropanoic Acid", "CHEMBL1091064"],
  },
  {
    primary_id: "D0N9ON",
    names: ["KNI-10092"],
  },
  {
    primary_id: "D0N9PL",
    names: ["ACE-041"],
  },
  {
    primary_id: "D0N9PQ",
    names: ["Aimspro"],
  },
  {
    primary_id: "D0N9PY",
    names: [
      "3-[5-Ethoxy-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 19a",
      "SCHEMBL4226312",
      "CHEMBL425323",
      "SCHEMBL4226322",
      "BDBM8597",
      "AC1O704X",
      "3-[(E)-(5-Ethoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0N9RD",
    names: ["PMID25435179-Compound-US2012165330(I)"],
  },
  {
    primary_id: "D0N9SJ",
    names: ["ALTU-237"],
  },
  {
    primary_id: "D0N9SV",
    names: ["NCG21", "Compound 12 [PMID: 19007110]", "NGC21"],
  },
  {
    primary_id: "D0N9TK",
    names: ["AR-MTOR-26", "AR-MTOR-1", "MTORC1/2 Inhibitors (Cancer)", "MTORC1/2 Inhibitors (Cancer), Array BioPharma"],
  },
  {
    primary_id: "D0N9TY",
    names: ["AZD-7295", "A-689", "HCV NS5a Polymerase Inhibitor, Arrow", "HCV NS5a Polymerase Inhibitor, AstraZeneca"],
  },
  {
    primary_id: "D0N9UP",
    names: ["PR-21"],
  },
  {
    primary_id: "D0N9UZ",
    names: ["[3H]LY341495"],
  },
  {
    primary_id: "D0N9VK",
    names: ["ISIS 122986"],
  },
  {
    primary_id: "D0N9VZ",
    names: ["ADL-5747"],
  },
  {
    primary_id: "D0N9WB",
    names: [
      "APC-366",
      "UNII-TGP1HP0WBC",
      "TGP1HP0WBC",
      "APC 366",
      "178925-65-0",
      "Apc366",
      "N-(1-Hydroxy-2-Naphthoyl)-L-Arginyl-L-Prolinamide Hydrochloride",
      "APC-366 Hydrochloride",
      "AC1L42OQ",
      "SCHEMBL15659721",
      "APC-366-C",
      "APC-366-A",
      "APC-366-R",
      "L-Prolinamide, N2-((1-Hydroxy-2-Naphthalenyl)Carbonyl)-L-Arginyl-, Monohydrochloride",
      "(2S)-1-[(2S)-5-(Diaminomethylideneamino)-2-[(1-Hydroxynaphthalene-2-Carbonyl)Amino]pentanoyl]pyrrolidine-2-Carboxamide Hydrochloride",
    ],
  },
  {
    primary_id: "D0N9WG",
    names: [
      "N-(4,6-Diphenylpyrimidin-2-Yl)-3-Chlorobenzamide",
      "CHEMBL426390",
      "820961-63-5",
      "3-Chloro-N-(4,6-Diphenylpyrimidin-2-Yl)Benzamide",
      "CTK3E2645",
      "DTXSID50459660",
      "MolPort-007-705-564",
      "BDBM50157667",
      "ZINC13586467",
      "AKOS001552650",
      "MCULE-8841696019",
      "Benzamide, 3-Chloro-N-(4,6-Diphenyl-2-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D0N9WY",
    names: [
      "NPT-CLN",
      "Calcineurin Inhibitors (Asthma), Biotica",
      "NPT-CLN Series (Inhaled, Asthma)",
      "NPT-CLN Series (Inhaled, Asthma), Biotica",
    ],
  },
  {
    primary_id: "D0N9WZ",
    names: ["Benzene Sulfonamide Derivative 6"],
  },
  {
    primary_id: "D0N9XD",
    names: ["PC-25"],
  },
  {
    primary_id: "D0N9YP",
    names: ["NTE-1"],
  },
  {
    primary_id: "D0N9ZR",
    names: [
      "6-Bromo-5-Methyl-1H-Indole-2,3-Dione",
      "6-BROMO-5-METHYLISATIN",
      "306325-13-3",
      "6-Bromo-5-Methylindoline-2,3-Dione",
      "6-Bromo-5-Methyl-2,3-Dihydro-1H-Indole-2,3-Dione",
      "6-Bromo-5-Methyl-1H-Benzo[d]azolidine-2,3-Dione",
      "AC1LW5IO",
      "CBMicro_014209",
      "Isatin-Based Compound, 58",
      "SCHEMBL5526009",
      "AC1Q2O90",
      "CHEMBL221830",
      "ARONIS016716",
      "CTK7H5981",
      "BDBM22838",
      "BBC/891",
      "MolPort-001-022-207",
      "DQZFCBOXZKQQPM-UHFFFAOYSA-N",
      "ALBB-012856",
      "ZX-AN011645",
      "ZINC2052963",
      "SMSF0004612",
      "STK042825",
      "SBB071631",
      "AKOS000484014",
      "MCULE-6005372434",
      "FCH1317267",
      "CB09212",
      "KS-000049B7",
    ],
  },
  {
    primary_id: "D0N9ZT",
    names: ["Prednisone Microencapsulated"],
  },
  {
    primary_id: "D0NA1Y",
    names: ["Flecainide Inhalation"],
  },
  {
    primary_id: "D0NA2Q",
    names: ["Indoline Derivative 9"],
  },
  {
    primary_id: "D0NA2T",
    names: [
      "(R)-(-)-N-Propyl-2-Methoxy-11-Hydroxynoraporphine",
      "CHEMBL405345",
      "2-Methoxy-11-Hydroxy-N-Propylnoraporphine",
      "SCHEMBL1204062",
      "BDBM50336856",
    ],
  },
  {
    primary_id: "D0NA4J",
    names: ["CAR-T Cells Targeting MAGE-A1"],
  },
  {
    primary_id: "D0NA4O",
    names: ["PMID26161698-Compound-34"],
  },
  {
    primary_id: "D0NA5L",
    names: ["AAV2-REP1"],
  },
  {
    primary_id: "D0NA5S",
    names: ["[3H]S0139", "[3H]-S0139", "[3H]S-0139"],
  },
  {
    primary_id: "D0NA7G",
    names: ["MCL-451"],
  },
  {
    primary_id: "D0NA8B",
    names: ["Rubraca Rucaparib"],
  },
  {
    primary_id: "D0NA8U",
    names: ["Dexamethasone Sodium Phosphate"],
  },
  {
    primary_id: "D0NA8W",
    names: ["LTA"],
  },
  {
    primary_id: "D0NB0I",
    names: ["2,4'-Dimethoxy-5,3'-Dipropyl-Biphenyl", "CHEMBL560262", "BDBM50295928"],
  },
  {
    primary_id: "D0NB0V",
    names: ["PMID25980951-Compound-28"],
  },
  {
    primary_id: "D0NB1V",
    names: ["Pyridine Derivative 20"],
  },
  {
    primary_id: "D0NB2G",
    names: ["PMID16439120C26"],
  },
  {
    primary_id: "D0NB2K",
    names: ["Pyrrole Derivative 4"],
  },
  {
    primary_id: "D0NB4O",
    names: ["PMID26560530-Compound-12"],
  },
  {
    primary_id: "D0NB4Q",
    names: ["10-OBn-7alpha-F-Gingkolide B", "Compound 17 [PMID: 12570381]"],
  },
  {
    primary_id: "D0NB4V",
    names: ["Fibronectin Extra Domain A"],
  },
  {
    primary_id: "D0NB5K",
    names: ["18F-VEGF Binding Peptides (PET Imaging), Genentech", "18F-A3B"],
  },
  {
    primary_id: "D0NB5M",
    names: ["PMID25666693-Compound-35"],
  },
  {
    primary_id: "D0NB5P",
    names: ["Biphenyl Mannoside Derivative 8"],
  },
  {
    primary_id: "D0NB7S",
    names: ["Tyr-Pro-Phe-Phe-OC(CH3)3", "CHEMBL500195"],
  },
  {
    primary_id: "D0NB7Y",
    names: ["Pyrazolo[1,5-A]-1,3,5-Triazine Derivative 1"],
  },
  {
    primary_id: "D0NB7Z",
    names: ["Tetra-Hydro-Isoquinoline Derivative 2"],
  },
  {
    primary_id: "D0NB8V",
    names: ["3,4,5-Trimethoxy-3'-Amino-Trans-Stilbene", "CHEMBL1173065", "SCHEMBL17236642", "BDBM50322052"],
  },
  {
    primary_id: "D0NB9T",
    names: ["KD018"],
  },
  {
    primary_id: "D0NC0G",
    names: ["RQ-00203066"],
  },
  {
    primary_id: "D0NC1D",
    names: ["MX-6120"],
  },
  {
    primary_id: "D0NC1Q",
    names: ["AMG 172"],
  },
  {
    primary_id: "D0NC3P",
    names: [
      "(-)-Cubebin",
      "Cubebin",
      "18423-69-3",
      "Beta-Cubebin",
      "UNII-J237078S8A",
      "(2s,3r,4r)-3,4-Bis(1,3-Benzodioxol-5-Ylmethyl)Tetrahydrofuran-2-Ol",
      "EINECS 242-300-6",
      "Tetrahydro-3,4-Dipiperonylfuran-2-Ol",
      "(8R,8'R,9S)-Cubebin",
      "AI3-62265",
      "CHEMBL399831",
      "CHEBI:65684",
      "J237078S8A",
      "2-Furanol, 3,4-Bis(1,3-Benzodioxol-5-Ylmethyl)Tetrahydro-",
      "Cubebine",
      "9-Hydroxy-3,4:3',4'-Bis(Methylenedioxy)-9,9'-Epoxylignan",
      "3,4-Bis(2H-1,3-Benzodioxol-5-Ylmethyl)Oxolan-2-Ol",
      ".beta.-Cubebin",
      "2-Furanol, Tetrahydro-3,4-Dipiperonyl-",
      "AC1Q59VI",
      "AC1L3KW2",
      "SCHEMBL4884683",
    ],
  },
  {
    primary_id: "D0NC3Q",
    names: ["PMID27551786-Compound-III"],
  },
  {
    primary_id: "D0NC8H",
    names: [
      "GMI-1051",
      "Antibacterial (Pseudomonas Aeruginosa), GlycoMimetics",
      "Lectin Antagonists (Anti-Infectives), GlycoMimetics",
      "Lectin-Binding Glycomimetics (P Aeruginosa Infections), GlycoMimetics",
    ],
  },
  {
    primary_id: "D0NC8V",
    names: ["PMID16250654C37", "AC1OCFYZ", "GTPL2239", "BDBM50372611"],
  },
  {
    primary_id: "D0NC8Z",
    names: ["PMID30247903-Compound-General Structure45"],
  },
  {
    primary_id: "D0NC9E",
    names: ["SGX301"],
  },
  {
    primary_id: "D0NC9M",
    names: ["CM-CS1 T-Cell"],
  },
  {
    primary_id: "D0NC9Y",
    names: ["ISIS 2105"],
  },
  {
    primary_id: "D0ND0M",
    names: ["HNHA"],
  },
  {
    primary_id: "D0ND1C",
    names: [
      "3-(1-Carboxy-4-Mercaptobutoxy)Benzoic Acid",
      "Benzoic Acid, 3-(1-Carboxy-4-Mercaptobutoxy)-",
      "Thiol-Based Inhibitor, 26a",
      "CHEMBL207350",
      "SCHEMBL5053092",
      "BDBM17775",
      "KUHQPVJMGPHXPD-UHFFFAOYSA-N",
      "378242-30-9",
      "3-(1-Carboxy-4-Sulfanylbutoxy)Benzoic Acid",
      "3-(L-Carboxy-4-Mercaptobutoxy)-Benzoic Acid",
      "3-(1-Carboxy-4-Mercaptobutoxy)-Benzoic Acid",
    ],
  },
  {
    primary_id: "D0ND2J",
    names: [
      "Flavoxate",
      "Bladuril",
      "Flavossato",
      "Flavoxato",
      "Flavoxatum",
      "Urispas",
      "Flavossato [DCIT]",
      "Flavoxate HCI",
      "Flavoxate HCL",
      "Spasuret Hydrochloride",
      "AK 123",
      "Bladderon (TN)",
      "Bladuril (TN)",
      "DW-61",
      "Flavoxate (INN)",
      "Flavoxate [INN:BAN]",
      "Flavoxato [INN-Spanish]",
      "Flavoxatum [INN-Latin]",
      "Urispas (TN)",
      "Uritac (TN)",
      "Zopyran-8-Carboxylate",
      "Beta-Piperidinoethyl 3-Methylflavone-8-Carboxylate",
      "Piperidinoethyl-3-Methylflavone-8-Carboxylate",
      "1-Piperidinoethanol, 3-Methyl-4-Oxo-2-Phenyl-4H-1-Benzopyran-8-Carboxylate",
      "2-(1-Piperidinyl)Ethyl 3-Methyl-4-Oxo-2-Phenyl-4H-Chromene-8-Carboxylate",
      "2-(Piperidin-1-Yl)Ethyl 3-Methyl-4-Oxo-2-Phenyl-4H-Chromene-8-Carboxylate",
      "2-Piperidinoethyl 3-Methyl-4-Oxo-2-Phenyl-4H-1-Benzopyran-8-Carboxylat",
      "2-Piperidinoethyl 3-Methyl-4-Oxo-2-Phenyl-4H-1-Benzopyran-8-Carboxylate",
      "2-Piperidinoethyl-3-Methyl-4-Oxo-2-Phenyl-4H-1-Ben",
      "2-Piperidin-1-Ylethyl 3-Methyl-4-Oxo-2-Phenyl-4H-Chromene-8-Carboxylate",
      "2-Piperidin-1-Ylethyl 3-Methyl-4-Oxo-2-Phenylchromene-8-Carboxylate",
      "2-Piperidinoethyl 3-Methylflavone-8-Carboxylate",
      "4H-1-Benzopyran-8-Carboxylic Acid, 3-Methyl-4-Oxo-2-Phenyl-, 2-(1-Piperidinyl)Ethyl Ester",
    ],
  },
  {
    primary_id: "D0ND2Y",
    names: [
      "1-(3-Nitro-Phenyl)-Piperazine",
      "1-(3-Nitrophenyl)Piperazine",
      "54054-85-2",
      "1-(3-Nitrophenyl)-Piperazine",
      "CHEMBL267153",
      "Piperazine, 1-(3-Nitrophenyl)-",
      "(3-Nitrophenyl)Piperazine",
      "3-Nitrophenylpiperazine",
      "AC1Q1X8X",
      "AC1MC26R",
      "SCHEMBL519878",
      "Piperazine,1-(3-Nitrophenyl)-",
      "CTK4J9414",
      "DTXSID20375060",
      "MolPort-000-005-114",
      "LHHZRIYUOZPKSG-UHFFFAOYSA-N",
      "ZINC4244898",
      "SBB055462",
      "CN-052",
      "BDBM50017449",
      "ANW-49084",
      "AKOS003587736",
      "CS-W019388",
      "MCULE-2930089958",
      "BR-49879",
      "AN-40610",
      "KB-63925",
      "AJ-49640",
      "RT-006025",
      "DB-024583",
      "AB0025386",
      "W6842",
      "FT-0660276",
    ],
  },
  {
    primary_id: "D0ND3F",
    names: ["LM-030"],
  },
  {
    primary_id: "D0ND3H",
    names: ["PMID27109571-Compound-13"],
  },
  {
    primary_id: "D0ND4N",
    names: ["Allyl 4-(2-Oxohexadecanamido)Butanoate", "CHEMBL520227"],
  },
  {
    primary_id: "D0ND5D",
    names: ["3-(1-Methyl-Piperidin-2-Yl)-2-Phenyl-1H-Indole", "CHEMBL43697", "BDBM50099263"],
  },
  {
    primary_id: "D0ND5K",
    names: ["Xanthine Derivative 1"],
  },
  {
    primary_id: "D0ND6T",
    names: ["4-(2-Cyclohexylethoxy) Aniline Derivative 2"],
  },
  {
    primary_id: "D0ND6Y",
    names: ["ISIS 133211"],
  },
  {
    primary_id: "D0ND8J",
    names: ["(-)-(1S,1'R)-O,O-Dimethylgrisbine Hydrochloride", "CHEMBL507970"],
  },
  {
    primary_id: "D0ND8N",
    names: ["Textilinin"],
  },
  {
    primary_id: "D0ND8U",
    names: ["Fused Heterocyclic Compound 12"],
  },
  {
    primary_id: "D0ND9T",
    names: ["CRLX101"],
  },
  {
    primary_id: "D0NE0W",
    names: ["Tetra-Hydro-Imidazo[1,5-D][1,4]oxazepin-3-Yl Derivative 6"],
  },
  {
    primary_id: "D0NE3C",
    names: ["AZD3409"],
  },
  {
    primary_id: "D0NE3U",
    names: [
      "DERMORPHIN",
      "Dermorphin",
      "77614-16-5",
      "H-Tyr-D-Ala-Phe-Gly-Tyr-Pro-Ser-NH2",
      "UNII-2SEC01B703",
      "DERMORPHIN ACETATE",
      "Tyrosyl-Alanyl-Phenylalanyl-Glycyl-Tyrosyl-Prolyl-Serinamide",
      "CHEMBL278789",
      "2SEC01B703",
      "Tyr-D-Ala-Phe-Gly-Tyr-Pro-Ser-NH2",
      "AC1NUNYZ",
      "YAFGYPS-NH2",
      "SCHEMBL237169",
      "Tyr-Ala-Phe-Gly-Tyr-Pro-Ser",
      "DTXSID40228281",
      "HY-P0244",
    ],
  },
  {
    primary_id: "D0NE3W",
    names: [
      "GTx-024",
      "(R)-Ostarine",
      "1132656-73-5",
      "(2R)-3-(4-Cyanophenoxy)-N-[4-Cyano-3-(Trifluoromethyl)Phenyl]-2-Hydroxy-2-Methylpropanamide",
      "C19H14F3N3O3",
      "841205-47-8 Pound Not1202044-20-9",
      "SCHEMBL2197534",
      "CHEBI:94527",
      "DTXSID70649384",
      "BCPP000135",
      "AOB87717",
      "ZINC35793633",
      "BCP9001038",
      "NCGC00408913-01",
      "AS-17044",
      "FT-0673314",
      "Y0247",
      "370C134",
      "I06-2154",
      "BRD-K16621777-001-01-9",
      "(R)-N-(4-Cyano-3-(Trifluoromethyl)Phenyl)-3-(4-Cyanophenoxy)-2-Hydroxy-2-Methylpropanamide",
    ],
  },
  {
    primary_id: "D0NE5B",
    names: [
      "Rintatolimid",
      "Ampligen",
      "Atvogen",
      "Poly I:Poly C12U",
      "POLYI-POLYC12U",
      "Poly(I).poly(C12,U)",
      "Mismatched Double-Stranded RNA",
      "Poly I-Poly C (C12,U)",
      "(RI(N).r(C(12)U)N)",
      "DRG-0001",
      "Poly(Inosinic Acid) Poly(Cytidylic(12), Uridylic)Acid",
      "38640-92-5",
      "5'-Inosinic Acid, Homopolymer, Complex With 5'-Cytidylic Acid Polymer With 5'-Uridylic Acid (1:1)",
      "Poly(I)-Poly(C12U)",
      "Rintatolimod [USAN:INN]",
      "UNII-94325AJ25N",
      "SCHEMBL27306",
      "AC1L1Z15",
      "DTXSID50191952",
      "94325AJ25N",
      "LS-174734",
      "C10H13N4O8P.C9H13N2O9P.C9H14N3O8P",
      "1308845-87-5",
      "1221495-86-8",
    ],
  },
  {
    primary_id: "D0NE8F",
    names: ["EDS-EP"],
  },
  {
    primary_id: "D0NE8M",
    names: ["PMID29865878-Compound-51"],
  },
  {
    primary_id: "D0NE9T",
    names: [
      "Fondaparinux Sodium",
      "Arixtra",
      "Quixidar",
      "Fondaparin Sodium",
      "Arixtra (TN)",
      "GSK-576428",
      "SR-90107",
      "SR-90107A",
      "Fondaparinux Sodium (JAN/USAN/INN)",
    ],
  },
  {
    primary_id: "D0NE9W",
    names: ["CX-1020"],
  },
  {
    primary_id: "D0NF0P",
    names: ["ELS-130"],
  },
  {
    primary_id: "D0NF0Q",
    names: ["ZD-0947", "AZD-0947"],
  },
  {
    primary_id: "D0NF0W",
    names: [
      "Metixene",
      "Atosil",
      "Contalyl",
      "Dalpan",
      "METHIXENE",
      "Methixart",
      "Methixen",
      "Methyloxan",
      "Metisene",
      "Metixeno",
      "Metixenum",
      "Raunana",
      "Tremaril;Tremarit",
      "Tremonil",
      "Tremoquil",
      "Trest",
      "Methixen [German]",
      "Methixene Hydrochloride",
      "Metisene [DCIT]",
      "Metixene Hydrochloride",
      "Tremaril Hydrochloride",
      "Metixene (INN)",
      "Metixene (TN)",
      "Metixene [INN:BAN]",
      "Metixeno [INN-Spanish]",
      "Metixenum [INN-Latin]",
      "Methixene (*hydrochloride*)",
      "Metixene (*hydrochloride*)",
      "Tremonil (*hydrochloride*)",
      "1-Methyl-3-(9H-Thioxanthen-9-Yl-Methyl)Piperidine",
      "1-Methyl-3-(9H-Thioxanthen-9-Ylmethyl)Piperidine",
      "1-Methyl-3-(Thioxanthen-9-Ylmethyl) Piperidine",
      "1-Methyl-3-(Thioxanthen-9-Ylmethyl)-1-Piperidine",
      "1-Methyl-3-(Thioxanthen-9-Ylmethyl)-Piperidine Hydrochloride",
      "60 SJ 1977",
      "60 SJ 1977 (*hydrochloride*)",
      "9-(1'-Methylpiperidine-3'-Methyl)Thioxanthene",
    ],
  },
  {
    primary_id: "D0NF1D",
    names: ["BAY-1817080a"],
  },
  {
    primary_id: "D0NF1U",
    names: ["FENOFIBRIC ACID"],
  },
  {
    primary_id: "D0NF1Z",
    names: ["UBX0101"],
  },
  {
    primary_id: "D0NF2E",
    names: ["PMID29130358-Compound-Figure9(1)"],
  },
  {
    primary_id: "D0NF2P",
    names: ["Fully-Deleted Adenoviral Vector-Based Immunosuppressants"],
  },
  {
    primary_id: "D0NF3E",
    names: ["2-(4-Nitro-1H-Benzo[d]imidazol-2-Yl)Quinoxaline", "CHEMBL198060"],
  },
  {
    primary_id: "D0NF3Y",
    names: ["Tricyclic Indole Compound 5"],
  },
  {
    primary_id: "D0NF5J",
    names: ["EZN-2968"],
  },
  {
    primary_id: "D0NF6F",
    names: ["Trichostatin A"],
  },
  {
    primary_id: "D0NF7S",
    names: ["R-59494"],
  },
  {
    primary_id: "D0NF8V",
    names: ["Phenylpyridine Derivative 3"],
  },
  {
    primary_id: "D0NG1C",
    names: ["S. Aureus Type 336 Vaccine"],
  },
  {
    primary_id: "D0NG2F",
    names: ["Maleimides Derivative 1"],
  },
  {
    primary_id: "D0NG3T",
    names: ["BCMA CAR-T Cells"],
  },
  {
    primary_id: "D0NG6O",
    names: ["Piperidinone Derivative 1"],
  },
  {
    primary_id: "D0NG7O",
    names: [
      "Zolmitriptan",
      "AscoTop",
      "Flezol",
      "Zolmitriptane",
      "Zolmitriptanum",
      "Zomig",
      "Zomigon",
      "Zolmitriptan RapidFilm",
      "Zolmitriptan [USAN]",
      "Zomig Nasal Spray",
      "Zomig ZMT",
      "AscoTop (TN)",
      "BW-311C90",
      "KS-5072",
      "Zomig (TN)",
      "Zomig-ZMT",
      "Zomigon (TN)",
      "Zomigoro (TN)",
      "Zomig, Zomigon, AscoTopand, Zomigoro, Zolmitriptan",
      "(4S)-4-({3-[2-(Dimethylamino)Ethyl]-1H-Indol-5-Yl}methyl)-1,3-Oxazolidin-2-One",
      "(4S)-4-[[3-(2-Dimethylaminoethyl)-1H-Indol-5-Yl]methyl]-1,3-Oxazolidin-2-One",
      "(S)-4-((3-(2-(Dimethylamino)Ethyl)-1H-Indol-5-Yl)Methyl)-2-Oxazolidinone",
      "(S)-4-((3-(2-(Dimethylamino)Ethyl)Indol-5-Yl)Methyl)-2-Oxazolidinone",
      "(S)-4-[3-(2-Dimethylamino-Ethyl)-1H-Indol-5-Ylmethyl]-Oxazolidin-2-One",
      "(S)-4-[[3-[2-(Dimethylamino)Ethyl]-1H-Indol-5-Yl]methyl]-2-Oxazolidinone",
      "4-((3-(2-(Dimethylamino)Ethyl)-1H-Indol-5-Yl)Methyl)-2-Oxazolidinone",
      "4-[[3-(2-Dimethylaminoethyl)-1H-Indol-5-Yl]methyl]oxazolidin-2-One",
    ],
  },
  {
    primary_id: "D0NG8Q",
    names: ["Heteroaromatic Ring Derivative 2"],
  },
  {
    primary_id: "D0NG9V",
    names: ["PMID29671355-Compound-47b"],
  },
  {
    primary_id: "D0NH0U",
    names: ["HYG-440"],
  },
  {
    primary_id: "D0NH1E",
    names: [
      "Org-231295",
      "3'-Carbamoyl-[1,1'-Biphenyl]-3-Yl Cyclohexylcarbamate",
      "546141-08-6",
      "URB597",
      "URB-597",
      "URB 597",
      "FAAH Inhibitor II",
      "KDS-4103",
      "UNII-PX47LB88FO",
      "3-(3-Carbamoylphenyl)Phenyl N-Cyclohexylcarbamate",
      "PX47LB88FO",
      "CHEMBL184238",
      "3'-Carbamoylbiphenyl-3-Yl Cyclohexylcarbamate",
      "[3-(3-Carbamoylphenyl)Phenyl] N-Cyclohexylcarbamate",
      "Carbamic Acid, N-Cyclohexyl-, 3'-(Aminocarbonyl)[1,1'-Biphenyl]-3-Yl Ester",
      "Cyclohexyl-Carbamic Acid 3'-Carbamoyl-Biphenyl-3-Yl Ester",
      "KDS-1243",
      "FAAH Inhibitor, Merck & Co",
      "FAAH Inhibitor, Schering-Plough/Kadmus/University Of California",
    ],
  },
  {
    primary_id: "D0NH1P",
    names: ["NPT-200-11"],
  },
  {
    primary_id: "D0NH1V",
    names: ["EDP-08"],
  },
  {
    primary_id: "D0NH1Y",
    names: ["Formycin"],
  },
  {
    primary_id: "D0NH2H",
    names: ["Pyrrolo-Pyrimidine Derivative 5"],
  },
  {
    primary_id: "D0NH2M",
    names: ["PMID25666693-Compound-142"],
  },
  {
    primary_id: "D0NH2O",
    names: ["MB66 Microbicide"],
  },
  {
    primary_id: "D0NH3R",
    names: ["ASTX727"],
  },
  {
    primary_id: "D0NH4G",
    names: ["Vaccine, Ebola Virus Infection"],
  },
  {
    primary_id: "D0NH4L",
    names: ["43778"],
  },
  {
    primary_id: "D0NH5A",
    names: ["ISIS 25547"],
  },
  {
    primary_id: "D0NH5E",
    names: [
      "Autologous Dendritic Cell Therapy",
      "Autologous Dendritic Cell Therapy (Cancer)",
      "CTL-901",
      "Autologous Dendritic Cell Therapy (Cancer), ML Laboratories",
      "Dendritic Cell Vaccine (Cancer), ML Laboratories",
    ],
  },
  {
    primary_id: "D0NH5X",
    names: ["2-(6-Cyclopentylamino-Purin-9-Yl)-Ethanol", "CHEMBL320370", "BDBM50009691"],
  },
  {
    primary_id: "D0NH6F",
    names: [
      "VUF-8929",
      "140890-71-7",
      "Vuf 8929",
      "AC1O5RNQ",
      "N-(2-Bis(4-Fluorophenyl)Methoxy)Ethyl-(2-Phenyl)Ethylamine Maleate",
      "Benzeneethanamine, N-(2-(Bis(4-Fluorophenyl)Methoxy)Ethyl)-, (2Z)-2-Butenedioate (1:1)",
    ],
  },
  {
    primary_id: "D0NH6Q",
    names: ["MRS2365", "(N)-Methanocarba-2-MeSADP", "MRS 2365"],
  },
  {
    primary_id: "D0NH6T",
    names: ["(2S)-Abyssinone II"],
  },
  {
    primary_id: "D0NH7M",
    names: ["AG-538"],
  },
  {
    primary_id: "D0NH9P",
    names: ["PMID25399762-Compound-Table 7-4-Hydroxybenzoic Acid"],
  },
  {
    primary_id: "D0NI0C",
    names: [
      "Vidarabine",
      "Araadenosine",
      "Arabinosyladenine",
      "Armes",
      "RAB",
      "Spongoadenosine",
      "VIRDARABINE",
      "Vidarabin",
      "Vidarabina",
      "Vidarabinum",
      "Xylosyladenine",
      "Adenine Arabinoside",
      "Adenine Xyloside",
      "Adenosine Arabinose",
      "Ara A",
      "Arabinoside Adenine",
      "Arabinosyl Adenine",
      "Vidarabina [DCIT]",
      "Vidarabine Anhydrous",
      "Vira ATM",
      "Xylosyl A",
      "A 9251",
      "ARA-A NSC 247519",
      "Alpha-Ara A",
      "Ara-A",
      "Arabinosyl-Adenine",
      "Arasena-A",
      "Armes (TN)",
      "Beta-Ara A",
      "Vidarabine (JAN)",
      "Vira-A",
      "Adenosine-8-14C",
      "Vira-A, Vidarabine",
      "ADENOSINE, U.S.P.",
      "(+)-Cyclaradine",
      "9-Arabinosyladenine",
    ],
  },
  {
    primary_id: "D0NI0X",
    names: ["SCH-721015"],
  },
  {
    primary_id: "D0NI1W",
    names: ["Trp-Ile-Ile-Asp-Leu-Hisc(Cys-Val-Tyr-Phe-Cys)", "CHEMBL436884"],
  },
  {
    primary_id: "D0NI2B",
    names: ["Macrocycle Derivative 5"],
  },
  {
    primary_id: "D0NI4Z",
    names: [
      "7-Chloro-1,3-Dihydro-Imidazo[4,5-B]quinolin-2-One",
      "CHEMBL98851",
      "SCHEMBL10758208",
      "2H-Imidazo[4,5-B]quinolin-2-One, 7-Chloro-1,3-Dihydro-",
      "BDBM50007221",
    ],
  },
  {
    primary_id: "D0NI5C",
    names: [
      "2-Pyridinethiol",
      "2-Mercaptopyridine",
      "Pyridine-2-Thiol",
      "2637-34-5",
      "2-Thiopyridine",
      "Pyrid-2-Thione",
      "2(1H)-PYRIDINETHIONE",
      "Pyridinethione",
      "1H-Pyridine-2-Thione",
      "73018-10-7",
      "2-Pyridylthiol",
      "2-Thiopyridone",
      "2-Pyridinethione",
      "2-Mercapto Pyridine",
      "2-Pyridyl Mercaptan",
      "1,2-Dihydropyridine-2-Thione",
      "Thiopyridone-2 [French]",
      "2-Pyridinylthiol",
      "Alrithiol-2",
      "NSC 41337",
      "UNII-EE982KT952",
      "CHEBI:45223",
      "EINECS 220-131-9",
      "WHMDPDGBKYUEMW-UHFFFAOYSA-N",
      "EE982KT952",
      "29468-20-0",
      "2-Mercaptopyridine, 98%",
      "Thiopyridone-2",
      "PYS",
      "Pyridinethiol",
      "Mercaptopyridine",
    ],
  },
  {
    primary_id: "D0NI6M",
    names: ["Ar5Y_1"],
  },
  {
    primary_id: "D0NI7H",
    names: ["Protein Tyrosine Phosphatase 1B Inhibitors"],
  },
  {
    primary_id: "D0NI8W",
    names: [
      "2-(1H-Imidazol-4-Yl)Phenol",
      "19170-74-2",
      "2-(1H-Imidazol-5-Yl)-Phenol",
      "2-(1H-Imidazol-5-Yl)Phenol",
      "CHEMBL457063",
      "SCHEMBL1502109",
      "BDBM24663",
      "2-(1H-Imidazol-4-Yl)Phenol, 1",
    ],
  },
  {
    primary_id: "D0NI9X",
    names: ["Autologous Stem Cell Therapy"],
  },
  {
    primary_id: "D0NJ0S",
    names: ["Pyrazole Derivative 34"],
  },
  {
    primary_id: "D0NJ1A",
    names: ["Aloxistatin"],
  },
  {
    primary_id: "D0NJ2R",
    names: ["DFD-05"],
  },
  {
    primary_id: "D0NJ3Q",
    names: ["WZ-3146"],
  },
  {
    primary_id: "D0NJ3T",
    names: ["CART-19 Cells"],
  },
  {
    primary_id: "D0NJ3V",
    names: ["Papaverine"],
  },
  {
    primary_id: "D0NJ4G",
    names: [
      "5,7-Dichloro-1H-Indole-2,3-Dione",
      "5,7-Dichloroisatin",
      "6374-92-1",
      "5,7-Dichloroindoline-2,3-Dione",
      "1H-Indole-2,3-Dione, 5,7-Dichloro-",
      "NSC26045",
      "5,7-Dichloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "MLS002639083",
      "AYGGQJHJRFZDFH-UHFFFAOYSA-N",
      "5,7-Dichloro-1H-Benzo[d]azolidine-2,3-Dione",
      "5,7-Dichlor-1h-Indol-2,3-Dion",
      "EINECS 228-928-3",
      "Indole-2,3-Dione, 5,7-Dichloro-",
      "Isatin,7-Dichloro-",
      "PubChem22561",
      "AC1Q3MQY",
      "Isatin, 5,7-Dichloro-",
      "ACMC-1B7TC",
      "NCIMech_000038",
      "Isatin-Based Compound, 53",
      "Cambridge Id 5228213",
      "AC1Q3I9X",
      "CBDivE_012174",
    ],
  },
  {
    primary_id: "D0NJ4H",
    names: ["MORIN"],
  },
  {
    primary_id: "D0NJ4R",
    names: ["GALANIN"],
  },
  {
    primary_id: "D0NJ5H",
    names: [
      "Alogliptin",
      "850649-61-5",
      "UNII-JHC049LO86",
      "Alogliptina",
      "2-({6-[(3r)-3-Aminopiperidin-1-Yl]-3-Methyl-2,4-Dioxo-3,4-Dihydropyrimidin-1(2h)-Yl}methyl)Benzonitrile",
      "SYR-322",
      "JHC049LO86",
      "(R)-2-((6-(3-Aminopiperidin-1-Yl)-3-Methyl-2,4-Dioxo-3,4-Dihydropyrimidin-1(2H)-Yl)Methyl)Benzonitrile",
      "CHEBI:72323",
      "AK322010",
      "2-({6-[(3R)-3-Aminopiperidin-1-Yl]-3-Methyl-2,4-Dioxo-1,2,3,4-Tetrahydropyrimidin-1-Yl}methyl)Benzonitrile",
      "Vipidia",
      "Alogliptin [INN]",
      "Alogliptin (SYR-322)",
      "Alogliptinum",
      "Alogliptine",
      "HSDB 8203",
    ],
  },
  {
    primary_id: "D0NJ5U",
    names: ["PMID8246233C35"],
  },
  {
    primary_id: "D0NJ5W",
    names: [
      "CAPROCTAMINE",
      "CHEMBL11805",
      "SCHEMBL18398791",
      "BDBM50067482",
      "N,N''''-(Octane-1,8-Diyl)Bis(6-((2-Methoxybenzyl)(Methyl)Amino)-N-Methylhexanamide)",
      "6-[(2-Methoxy-Benzyl)-Methyl-Amino]-Hexanoic Acid [8-({6-[(2-Methoxy-Benzyl)-Methyl-Amino]-Hexanoyl}-Methyl-Amino)-Octyl]-Methyl-Amide",
    ],
  },
  {
    primary_id: "D0NJ6B",
    names: ["Cyclopropylamine Derivative 3"],
  },
  {
    primary_id: "D0NJ7Y",
    names: ["GlaxoSmithKline Compound 5h"],
  },
  {
    primary_id: "D0NJ8L",
    names: ["N-(4'-Bromo-3,3'-Difluoro-4-Biphenylyl)Urea", "CHEMBL244510", "SCHEMBL5436772", "BDBM50220178"],
  },
  {
    primary_id: "D0NJ9E",
    names: ["Imidazopyridine And Triazolopyridine Compound 2"],
  },
  {
    primary_id: "D0NJ9W",
    names: ["Nfu-PA-D4-RNP", "Anthrax Vaccine, Molecular Targeting Technology"],
  },
  {
    primary_id: "D0NK1R",
    names: ["PMID27774824-Compound-Figure12Example1"],
  },
  {
    primary_id: "D0NK2U",
    names: ["FK-973"],
  },
  {
    primary_id: "D0NK4T",
    names: ["Carbamate Derivative 12"],
  },
  {
    primary_id: "D0NK6K",
    names: ["P-BCMA-101 CAR-T Cells"],
  },
  {
    primary_id: "D0NK6Z",
    names: ["Beta LT", "Beta Alethine"],
  },
  {
    primary_id: "D0NK7X",
    names: ["Nordimmun"],
  },
  {
    primary_id: "D0NK8D",
    names: ["SC-53228"],
  },
  {
    primary_id: "D0NK8M",
    names: ["Human Heterologous Liver Cells"],
  },
  {
    primary_id: "D0NK9Y",
    names: ["Quinazolinone Derivative 1"],
  },
  {
    primary_id: "D0NL1F",
    names: ["12,13-DEHYDROMANZAMINE A", "12,13-Dehydromanzamine A", "CHEMBL268202"],
  },
  {
    primary_id: "D0NL2L",
    names: [
      "3-[7'-(Methoxy)-Napht-2'-Yl]-Quinuclidine-2-Ene",
      "CHEMBL478981",
      "SCHEMBL2422138",
      "KZWBNNXTWZPPPP-UHFFFAOYSA-N",
      "BDBM50270302",
      "3-[7''-(Methoxy)-Napht-2''-Yl]-Quinuclidine-2-Ene",
      "3-(6-Methoxy-2-Naphthyl)-1-Azabicyclo[2.2.2]oct-2-Ene",
    ],
  },
  {
    primary_id: "D0NL4R",
    names: ["PMID23788657C1754-31"],
  },
  {
    primary_id: "D0NL4Z",
    names: [
      "Monoisopropyl Ester Phosphonic Acid Group",
      "AC1NAQP0",
      "MONOISOPROPYL ESTER PHOSPHONIC ACID GROUP",
      "Propan-2-Yloxyphosphinic Acid",
      "SCHEMBL333601",
      "Phosphonic Acid Isopropyl Ester",
      "AC1O4B47",
      "ZINC5381386",
    ],
  },
  {
    primary_id: "D0NL5K",
    names: ["SEA"],
  },
  {
    primary_id: "D0NL5M",
    names: ["Mycobacterial Cell Wall-DNA Complex"],
  },
  {
    primary_id: "D0NL6C",
    names: ["8-(Biphenyl-3-Yloxy)-1,1,1-Trifluoro-Octan-2-One", "CHEMBL116023", "SCHEMBL7368359", "BDBM50218558"],
  },
  {
    primary_id: "D0NL7J",
    names: ["N1-(4-Chlorobenzyl)-2-Amino-N3-Hydroxymalonamide", "CHEMBL385996"],
  },
  {
    primary_id: "D0NL9N",
    names: ["Anti-CD19/20-CAR Vector-Transduced T Cells"],
  },
  {
    primary_id: "D0NM0B",
    names: ["PMID27977313-Compound-Figure4b"],
  },
  {
    primary_id: "D0NM0G",
    names: ["GSK-3787"],
  },
  {
    primary_id: "D0NM0P",
    names: [
      "3-[5-(4-Hydroxyphenyl)-3-Thienyl]phenol",
      "CHEMBL462513",
      "SCHEMBL1180467",
      "BDBM25853",
      "ZINC40393334",
      "4,3'-(2,4-Thiophenediyl)Bisphenol",
      "Hydroxyphenyl Substituted Thiophene, 25",
    ],
  },
  {
    primary_id: "D0NM1F",
    names: ["(R)-N-Isopropyl-N-(Pyrrolidin-3-Yl)-2-Naphthamide", "CHEMBL565639"],
  },
  {
    primary_id: "D0NM3D",
    names: ["PMID25666693-Compound-19"],
  },
  {
    primary_id: "D0NM6R",
    names: ["N-3'-Ethylaplysinopsin"],
  },
  {
    primary_id: "D0NM7S",
    names: ["A-935142", "A935142", "A 935142"],
  },
  {
    primary_id: "D0NN0I",
    names: ["APC-8020"],
  },
  {
    primary_id: "D0NN0V",
    names: [
      "4',5,7-Trihydroxy-6,8-Dimethylisoflavone",
      "CHEMBL512579",
      "AC1LCTEG",
      "SCHEMBL571136",
      "BDBM50250906",
      "4'',5,7-Trihydroxy-6,8-Dimethylisoflavone",
      "5,7-Dihydroxy-3-(4-Hydroxyphenyl)-6,8-Dimethylchromen-4-One",
      "5,7-Dihydroxy-3-(4-Hydroxyphenyl)-6,8-Dimethyl-4H-Chromen-4-One",
      "5,7-Dihydroxy-3-(4-Hydroxy-Phenyl)-6,8-Dimethyl-Chromen-4-One",
      "4H-1-Benzopyran-4-One, 5,7-Dihydroxy-3-(4-Hydroxyphenyl)-6,8-Dimethyl-",
    ],
  },
  {
    primary_id: "D0NN3G",
    names: ["4-HYDROXYBENZALDEHYDE O-(CYCLOHEXYLCARBONYL)OXIME"],
  },
  {
    primary_id: "D0NN3Q",
    names: ["4-Hydrazino-Benzenesulfonamide", "4-Hydrazinylbenzenesulfonamide"],
  },
  {
    primary_id: "D0NN4P",
    names: ["Urea And Carbamate Bioisostere Derivative 5"],
  },
  {
    primary_id: "D0NN4U",
    names: ["PMID26924192-Compound-105"],
  },
  {
    primary_id: "D0NN5A",
    names: ["MK-6096"],
  },
  {
    primary_id: "D0NN5B",
    names: ["FR-172357"],
  },
  {
    primary_id: "D0NN5F",
    names: [
      "BChE Inhibitors",
      "BChE Inhibitors (Imaging, Alzheimers Disease)",
      "BChE Inhibitors (Imaging, Alzheimers Disease), University OfNebraska Medical Center",
      "Imaging/Therapeutic Butyrylcholinesterase Inhibitors (Alzheimers Disease), UNMC",
    ],
  },
  {
    primary_id: "D0NN5H",
    names: ["PF-4523655"],
  },
  {
    primary_id: "D0NN7L",
    names: ["AC 165198"],
  },
  {
    primary_id: "D0NN7P",
    names: ["CART-HER-2"],
  },
  {
    primary_id: "D0NN7Q",
    names: [
      "HP-802-247",
      "Allogeneic Epidermal Keratinocytes And Dermal Fibroblasts (Topical Spray, Wound Healing), Healthpoint",
    ],
  },
  {
    primary_id: "D0NN7W",
    names: ["Cu64-CNND1-L"],
  },
  {
    primary_id: "D0NN8N",
    names: ["TNX-102", "Cyclobenzaprine Very Low Dose"],
  },
  {
    primary_id: "D0NN9I",
    names: ["IMU-200"],
  },
  {
    primary_id: "D0NO0K",
    names: ["RBT-101"],
  },
  {
    primary_id: "D0NO1A",
    names: ["AT001/R84"],
  },
  {
    primary_id: "D0NO1L",
    names: ["Pascolizumab"],
  },
  {
    primary_id: "D0NO3H",
    names: ["Peptide Analog 42"],
  },
  {
    primary_id: "D0NO4O",
    names: ["Azaindazole Derivative 2"],
  },
  {
    primary_id: "D0NO5E",
    names: [
      "Ethyl 5-Benzoyl-4-Phenylthiazol-2-Ylcarbamate",
      "CHEMBL603129",
      "MLS000537589",
      "AC1OJ6S4",
      "Cid_7315331",
      "REGID_For_CID_7315331",
      "HMS2189O04",
      "BDBM50308501",
      "SMR000161712",
      "MLS-0103498.0001",
      "Ethyl 5-Benzoyl-4-Phenyl-1,3-Thiazol-2-Ylcarbamate",
      "Ethyl N-(5-Benzoyl-4-Phenyl-1,3-Thiazol-2-Yl)Carbamate",
    ],
  },
  {
    primary_id: "D0NO6E",
    names: ["Mteron-F1"],
  },
  {
    primary_id: "D0NO7L",
    names: ["PRO-289"],
  },
  {
    primary_id: "D0NO7V",
    names: ["PMID27828716-Compound-20"],
  },
  {
    primary_id: "D0NO8S",
    names: ["MVA3000"],
  },
  {
    primary_id: "D0NP1J",
    names: ["Bucladesine Sodium", "Actosin (TN)"],
  },
  {
    primary_id: "D0NP2W",
    names: ["1,2,4-Triazolo[1,5a]pyridine Derivative 2"],
  },
  {
    primary_id: "D0NP8A",
    names: ["UK-356618"],
  },
  {
    primary_id: "D0NP8B",
    names: ["ERIC-1"],
  },
  {
    primary_id: "D0NP8M",
    names: ["209-342", "A34-Targeting Monoclonal Antibody (Cancer), Recepta"],
  },
  {
    primary_id: "D0NP8P",
    names: ["APG-1387", "AKLBERUGKZNEJY-RTEPGWBGSA-N", "SCHEMBL15490706"],
  },
  {
    primary_id: "D0NQ0B",
    names: [
      "XNT",
      "86456-22-6",
      "NSC354677",
      "9H-Xanthen-9-One, 1-((2-(Dimethylamino)Ethyl)Amino)-4-(Hydroxymethyl)-7-(((4-Methylphenyl)Sulfonyl)Oxy)-",
      "[8-(2-Dimethylaminoethylamino)-5-(Hydroxymethyl)-9-Oxoxanthen-2-Yl] 4-Methylbenzenesulfonate",
      "8-((2-(Dimethylamino)Ethyl)Amino)-5-(Hydroxymethyl)-9-Oxo-9H-Xanthen-2-Yl 4-Methylbenzenesulfonate",
      "9H-Xanthen-9-One, 1-[[2-(Dimethylamino)Ethyl]amino]-4-(Hydroxymethyl)-7-[[(4-Methylphenyl)Sulfonyl]oxy]-",
    ],
  },
  {
    primary_id: "D0NQ0V",
    names: ["BT-061"],
  },
  {
    primary_id: "D0NQ0W",
    names: ["SB-101", "Angiogenesis Inhibitor (Cancer), Celecure/Angitia"],
  },
  {
    primary_id: "D0NQ5I",
    names: ["METRIFUDIL"],
  },
  {
    primary_id: "D0NQ5R",
    names: ["AZD-4750"],
  },
  {
    primary_id: "D0NQ6N",
    names: ["PMID25772215-Compound-US02014179750TG6-10-1"],
  },
  {
    primary_id: "D0NQ7I",
    names: ["SPONGIADIOXIN A"],
  },
  {
    primary_id: "D0NR2A",
    names: ["Resorcinol Compound 6"],
  },
  {
    primary_id: "D0NR3V",
    names: ["Zatebradine"],
  },
  {
    primary_id: "D0NR6S",
    names: [
      "Maraviroc",
      "376348-65-1",
      "Selzentry",
      "Celsentri",
      "UK-427857",
      "UK-427,857",
      "UK 427857",
      "UNII-MD6P741W8A",
      "MD6P741W8A",
      "CHEMBL256907",
      "MVC",
      "CHEMBL1201187",
      "CHEBI:63608",
      "4,4-Difluoro-N-[(1S)-3-[(1R,5S)-3-(3-Methyl-5-Propan-2-Yl-1,2,4-Triazol-4-Yl)-8-Azabicyclo[321]octan-8-Yl]-1-Phenylpropyl]cyclohexane-1-Carboxamide",
      "Isopropyl, 4,4-Difluoro-N-((1S)-3-{(1R,3s,5S)-3-(3-Methyl-5-(Propan-2-Yl)-4H-1,2,4-Triazol-4-Yl)-8-Azabicyclo(321)Octan-8-Yl}-1-Phenylpropyl)Cyclohexanecarboxamide",
      "Maraviroc [USAN]",
      "Celsentri (TN)",
      "Celsentri(TM)",
      "PRO 140 & Maraviroc",
      "Selzentry (TN)",
      "Selzentry(TM)",
      "UK-427,857 Maraviroc (MVC)",
      "Exo-4,4-Difluoro-N-[3-[3-(3-Isopropyl-5-Methyl-4H-1,2,4-Triazol-4-Yl)-8-Azabicyclo[321]oct-8-Yl]-1(S)-Phenylpropyl]cyclohexanecarboxamide",
      "PRO 140 (Anti-CCR5 Monoclonal Antibody) & Exo-4,4-Difluoro-N-[3-[3-(3-Isopropyl-5-Methyl-4H-1,2,4-Triazol-4-Yl)-8-Azabicyclo[321]oct-8-Yl]-1(S)-Phenylpropyl]cyclohexanecarboxamide",
      "4,4-Difluoro-N-((1S)-3-(Exo-3-(3-Isopropyl-5-Methyl-4H-1,2,4-Triazol-4-Yl)-8-Azabicyclo(321)Oct-8-Yl)-1-Phenylpropyl)Cyclohexanecarboxamide",
      "[3H]maraviroc",
    ],
  },
  {
    primary_id: "D0NR6T",
    names: ["UB-311"],
  },
  {
    primary_id: "D0NR7H",
    names: ["A-837093", "3,4-Dihydro-Naphthalen-1-Olate"],
  },
  {
    primary_id: "D0NR9E",
    names: ["Omeprazole-Lansoprazole With Buffer"],
  },
  {
    primary_id: "D0NS0E",
    names: [
      "3-Hydroxyquinazoline-2,4(1H,3H)-Dione",
      "3-Hydroxy-Quinazoline-2,4-Dione",
      "CHEMBL183852",
      "5329-43-1",
      "NSC2552",
      "AC1Q6JF3",
      "AC1L58DP",
      "N-Hydroxy Quinazolinedione, 4",
      "SCHEMBL1520708",
      "3-Hydroxyquinazoline-2,4-Dione",
      "CTK4J7468",
      "BDBM33412",
      "DTXSID00277492",
      "ZINC1640999",
      "NSC-2552",
      "3-Hydroxy-1H-Quinazoline-2,4-Dione",
      "3-HYDROXY-2,3H)-QUINAZOLINEDIONE",
    ],
  },
  {
    primary_id: "D0NS0S",
    names: ["Vinconate", "Brenal", "Vincatenate", "Vintenate", "Vinconate Hydrochloride", "OC-340", "OM-853"],
  },
  {
    primary_id: "D0NS1S",
    names: [
      "ACACETIN",
      "Acacetin",
      "480-44-4",
      "Linarigenin",
      "5,7-Dihydroxy-4'-Methoxyflavone",
      "Acacetine",
      "4'-Methoxyapigenin",
      "Buddleoflavonol",
      "Linarisenin",
      "Akatsetin",
      "5,7-Dihydroxy-2-(4-Methoxyphenyl)-4H-Chromen-4-One",
      "Apigenin 4'-Methyl Ether",
      "5,7-Dioxy-4'-Methoxyflavone",
      "Apisenin 4'-Methyl Ether",
      "Apigenin 4'-Dimethyl Ether",
      "UNII-KWI7J0A2CC",
      "NSC 76061",
      "Flavone, 5,7-Dihydroxy-4'-Methoxy-",
      "5,7-Dihydroxy-2-(4-Methoxyphenyl)Chromen-4-One",
      "ACAETIN",
      "4'-Methoxy-5,7-Dihydroxyflavone",
      "NSC76061",
      "EINECS 207-552-3",
      "KWI7J0A2CC",
    ],
  },
  {
    primary_id: "D0NS1X",
    names: ["SAR-650984", "Anti-CD38 Monoclonal Antibody (Leukemia), ImmunoGen/Sanofi-Aventis"],
  },
  {
    primary_id: "D0NS2U",
    names: ["LLL-12"],
  },
  {
    primary_id: "D0NS3O",
    names: ["TT10", "EBVST", "GVYPHJQPOHDZEI-UHFFFAOYSA-N", "TT-10", "2230640-94-3"],
  },
  {
    primary_id: "D0NS6H",
    names: ["Donepezil", "Donepezil (Transdermal Patch, Alzheimer's Disease)"],
  },
  {
    primary_id: "D0NS6I",
    names: ["PMID28766366-Compound-Scheme27LeucettamineB"],
  },
  {
    primary_id: "D0NS6S",
    names: ["Cyclopenta[d]pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0NS6Z",
    names: [
      "COH29",
      "Coh-29",
      "COH-29",
      "UNII-07802BU06S",
      "07802BU06S",
      "1190932-38-7",
      "Rnr Inhibitor COH29",
      "SCHEMBL10036527",
      "MolPort-044-567-552",
      "AKOS032944935",
      "CS-6153",
      "HY-19931",
      "N-(4-(3,4-Dihydroxyphenyl)-5-Phenylthiazol-2-Yl)-3,4-Dihydroxybenzamide",
      "Benzamide, N-(4-(3,4-Dihydroxyphenyl)-5-Phenyl-2-Thiazolyl)-3,4-Dihydroxy-",
    ],
  },
  {
    primary_id: "D0NS7A",
    names: ["APN-301"],
  },
  {
    primary_id: "D0NS7W",
    names: ["Fused Heterocyclic Compound 6"],
  },
  {
    primary_id: "D0NS8T",
    names: ["13-DEOXYADRIAMYCIN HYDROCHLORIDE"],
  },
  {
    primary_id: "D0NS8V",
    names: ["Dihydropyrimidinone Derivative 2"],
  },
  {
    primary_id: "D0NS9C",
    names: ["BIIB-513"],
  },
  {
    primary_id: "D0NS9H",
    names: [
      "2-Methyl-6-(4-Phenylpent-1-Ynyl)Pyridine",
      "CHEMBL212834",
      "2-Methyl-6-(4-Phenylpent-1-Yn-1-Yl)Pyridine",
      "SCHEMBL5703254",
      "BDBM50191124",
    ],
  },
  {
    primary_id: "D0NS9X",
    names: [
      "2-Chloro-N-(3-Morpholinopropyl)Nicotinamide",
      "CHEMBL608436",
      "AC1N46O7",
      "2-Chloro-N-(3-Morpholin-4-Ylpropyl)Pyridine-3-Carboxamide",
      "ZINC23379340",
      "BDBM50307194",
      "AKOS003849609",
      "KB-120077",
    ],
  },
  {
    primary_id: "D0NT1R",
    names: ["ABBV-621"],
  },
  {
    primary_id: "D0NT1S",
    names: ["CB-10-01"],
  },
  {
    primary_id: "D0NT2S",
    names: ["PMID27744724-Compound-20"],
  },
  {
    primary_id: "D0NT2W",
    names: ["Niflumic"],
  },
  {
    primary_id: "D0NT4H",
    names: ["BEL-0218"],
  },
  {
    primary_id: "D0NT6P",
    names: ["MK8242"],
  },
  {
    primary_id: "D0NT8N",
    names: ["Piperidinyl Triazole Derivative 3"],
  },
  {
    primary_id: "D0NT8Q",
    names: ["DW-514"],
  },
  {
    primary_id: "D0NT8U",
    names: ["Indazoletriazolephenyl Derivative 1"],
  },
  {
    primary_id: "D0NU0U",
    names: [
      "AZD4547",
      "1035270-39-3",
      "AZD-4547",
      "AZD 4547",
      "UNII-2167OG1EKJ",
      "2167OG1EKJ",
      "CHEBI:63453",
      "KB-74810",
      "N-(5-(3,5-Dimethoxyphenethyl)-1H-Pyrazol-3-Yl)-4-((3R,5S)-Rel-3,5-Dimethylpiperazin-1-Yl)Benzamide",
      "N-{5-[2-(3,5-Dimethoxyphenyl)Ethyl]-1H-Pyrazol-3-Yl}-4-(Cis-3,5-Dimethylpiperazin-1-Yl)Benzamide",
      "Rel-N-[5-[2-(3,5-Dimethoxyphenyl)Ethyl]-1H-Pyrazol-3-Yl]-4-[(3R,5S)-3,5-Dimethyl-1-Piperazinyl]benzamide",
      "N-[5-[2-(3,5-Dimethoxyphenyl)Ethyl]-1H-Pyrazol-3-Yl]-4-[(3R,5S)-3,5-Dimethylpiperazin-1-Yl]benzamide",
    ],
  },
  {
    primary_id: "D0NU1W",
    names: ["3,6,8-Tribromo-Dibenzo[1,4]dioxin-1-Ol"],
  },
  {
    primary_id: "D0NU2H",
    names: ["Propyliodone"],
  },
  {
    primary_id: "D0NU3J",
    names: ["NOSTOCARBOLINE"],
  },
  {
    primary_id: "D0NU5Z",
    names: ["1,7,8,9-Tetrahydro-1,5-Diaza-Trindene-4,6-Dione", "CHEMBL201907", "SCHEMBL2086780", "ZINC28569089"],
  },
  {
    primary_id: "D0NU6K",
    names: ["(Z)-5-Octadecen-7,9-Diynoic Acid", "CHEMBL504781", "5Z-Octadecene-7,9-Diynoic Acid", "BDBM50292397"],
  },
  {
    primary_id: "D0NU6Y",
    names: ["192C86", "BW-192C86"],
  },
  {
    primary_id: "D0NU8J",
    names: ["PT2977"],
  },
  {
    primary_id: "D0NU8U",
    names: ["PMID27977313-Compound-Figure6C"],
  },
  {
    primary_id: "D0NU9L",
    names: ["CAR-20/19-T Cells"],
  },
  {
    primary_id: "D0NU9N",
    names: ["CAR-T Cells Targeting MUC1"],
  },
  {
    primary_id: "D0NV2M",
    names: ["PMID25666693-Compound-157"],
  },
  {
    primary_id: "D0NV2V",
    names: [
      "5-Fluorolevulinic Acid",
      "5-FLUOROLEVULINIC ACID",
      "5-Fluoro-4-Oxopentanoic Acid",
      "76385-49-4",
      "1gzg",
      "LAF",
      "5-Fluoro-4-Oxopentanoate",
      "5-Fluoro-4-Ketopentanoic Acid",
      "Substrate Analogue, 9",
      "AC1L2YNX",
      "AC1Q4OPQ",
      "SCHEMBL1053778",
      "Pentanoic Acid,5-Fluoro-4-Oxo-",
      "BDBM82192",
      "CTK5E2869",
      "DTXSID40227219",
      "Pentanoic Acid, 5-Fluoro-4-Oxo-",
    ],
  },
  {
    primary_id: "D0NV5N",
    names: ["Normorphine"],
  },
  {
    primary_id: "D0NV5O",
    names: ["Finerenone"],
  },
  {
    primary_id: "D0NV6I",
    names: ["Anti-CD19 CAR-T Cells"],
  },
  {
    primary_id: "D0NV7W",
    names: ["1-(4-Cyanobenzyl)-5-(2-Fluorophenyl)-1H-Imidazole", "CHEMBL599603", "SCHEMBL3826070"],
  },
  {
    primary_id: "D0NV8X",
    names: ["ISIS 25552"],
  },
  {
    primary_id: "D0NV9C",
    names: ["2-(4-Bromophenylsulfonamido)-N-Hydroxyacetamide", "CHEMBL1090283", "1218922-39-4", "SCHEMBL7413923"],
  },
  {
    primary_id: "D0NV9O",
    names: ["BAY 10-00394"],
  },
  {
    primary_id: "D0NW0T",
    names: [
      "Erdafitinib",
      "1346242-81-6",
      "UNII-890E37NHMV",
      "890E37NHMV",
      "Erdafitinib [USAN:INN]",
      "Erdafitinib (USAN/INN)",
      "GTPL9039",
      "SCHEMBL2583760",
      "CHEMBL3545376",
      "MolPort-044-560-398",
      "JNJ-42756493 (Erdafitinib)",
      "S8401",
      "Compound 4 [WO2011135376]",
      "ZINC168520308",
      "AKOS030526429",
      "CS-4988",
      "DB12147",
      "AC-30222",
      "1,2-Ethanediamine, N1-(3,5-Dimethoxyphenyl)-N2-(1-Methylethyl)-N1-(3-(1-Methyl-1H-Pyrazol-4-Yl)-6-Quinoxalinyl)-",
      "HY-18708",
      "AS-35040",
      "KB-333716",
      "D10927",
      "N'-(3,5-Dimethoxyphenyl)-N'-[3-(1-Methylpyrazol-4-Yl)Quino",
    ],
  },
  {
    primary_id: "D0NW2B",
    names: ["NK3 Antagonist PET Ligand"],
  },
  {
    primary_id: "D0NW2H",
    names: ["BG-777"],
  },
  {
    primary_id: "D0NW3X",
    names: [
      "Dofetilide",
      "Dofetilida",
      "Dofetilidum",
      "Tikosyn",
      "Xelide",
      "UK 68798",
      "Dofetilida [INN-Spanish]",
      "Dofetilidum [INN-Latin];Tikosyn (TN)",
      "UK 68,798",
      "UK-68798",
      "Dofetilide [USAN:BAN:INN]",
      "Tikosyn, UK68798, Dofetilide",
      "UK-68,798",
      "Dofetilide (JAN/USAN/INN)",
      "Beta-((P-Methanesulfonamidophenethyl)Methylamino)Methanesulfono-P-Phenetidide",
      "N-[4-[2-[2-[4-(Methanesulfonamido)Phenoxy]ethyl-Methylamino]ethyl]phenyl]methanesulfonamide",
      "N-(4-{2-[methyl(2-{4-[(Methylsulfonyl)Amino]phenoxy}ethyl)Amino]ethyl}phenyl)Methanesulfonamide",
      "Methanesulfonamide, N-(4-(2-(Methyl(2-(4-((Methylsulfonyl)Amino)Phenoxy)Ethyl)Amino)Ethyl)Phenyl)",
      "1-(4-Methanesulfonamidophenoxy)-2-(N-(4-Methanesulfonamidophenethyl)-N-Methylamine)Ethane",
      "1-MSPMPE",
    ],
  },
  {
    primary_id: "D0NW3Z",
    names: [
      "COCAINE.HCL",
      "Cocaine Muriate",
      "Cocaine Chloride",
      "Sal De Merck",
      "L-Cocaine Hydrochloride",
      "Cocain-Chlorhydrat [German]",
      "EINECS 200-167-1",
      "Cocaine Hydrochloride [JAN]",
      "1alpha-H,5alpha-H-Tropane-2beta-Carboxylic Acid, 3beta-Hydroxy-,",
      "Methyl 3beta-Hydroxy-1alphaH,5alphaH-Tropan-2beta-Carboxylate, Benzoate (Ester) Hydrochloride",
      "Methyl 3-Beta-Hydroxy-1-Alpha-H,5-Alpha-H-Tropan-2-Beta-Carboxylate, Benzoate (Ester) HCl",
      "8-Azabicyclo(3.2.1)Octane-2-Carboxylic Acid, 3-(Benzoyloxy)-8-Methyl-, Methyl Ester, Hydrochloride, (1R-(Exo,exo))-",
    ],
  },
  {
    primary_id: "D0NW6E",
    names: [
      "Heptyl 1-Thiohexopyranoside",
      "85618-20-8",
      "Heptyl B-D-Thioglucopyranoside",
      "Heptyl Beta-D-Thioglucopyranoside",
      "Heptyl-Beta-D-1-Thioglucopyranoside",
      "N-HEPTYL-BETA-D-THIOGLUCOPYRANOSIDE",
      "Heptyl 1-Thioglucoside",
      "HTG",
      "N-Heptylthioglucoside",
      "Heptyl Thioglucoside",
      "AC1LCVPH",
      "SCHEMBL137948",
      "Heptyl ?-D-Thioglucopyranoside",
      "C13H26O5S",
      "ZINC16052385",
      "N-Heptyl-I(2)-D-Thioglucopyranoside",
      "Heptyl-1-Thio-Beta-D-Glucopyranoside",
      "Heptyl 1-Thio-Beta-D-Glucopyranoside",
      "GC8635",
      "MFCD00043236",
      "AKOS016846196",
      "Beta-D-Glucopyranoside,heptyl 1-Thio-",
    ],
  },
  {
    primary_id: "D0NW7F",
    names: [
      "2-(3-Bromo-Phenyl)-6-Methyl-Chromen-4-One",
      "CHEMBL334427",
      "AC1LFCVT",
      "BAS 01121887",
      "3'-Bromo-6-Methylflavone",
      "6-Methyl-3''-Bromoflavone",
      "Oprea1_370713",
      "Oprea1_120869",
      "MolPort-001-955-842",
      "ZINC265292",
      "BDBM50118406",
      "AKOS000603553",
    ],
  },
  {
    primary_id: "D0NW7Y",
    names: ["Ravulizumab"],
  },
  {
    primary_id: "D0NW8C",
    names: [
      "M3541",
      "Aminomalonic Acid",
      "1068-84-4",
      "Aminomalonate",
      "2-Aminomalonic Acid",
      "2-Aminopropanedioic Acid",
      "Alpha-Aminomalonic Acid",
      "Propanedioic Acid, Amino-",
      "Aminopropanedioic Acid",
      "Propanedioic Acid,2-Amino-",
      "CHEBI:17475",
      "C00872",
      "Q-102736",
      "Malonic Acid, Amino-",
      "Aminomalonicacid",
      "Aminopropanedioate",
      "Amino-Propanedioate",
      "Amino-Malonic Acid",
      "A-Aminomalonic Acid",
      "NSC 352096",
      "Amino-Propanedioic Acid",
      "AC1L2PBO",
      ".alpha.-Aminomalonic Acid",
      "AC1Q5S2H",
      "Propanedioic Acid, 2-Amino-",
      "SCHEMBL209221",
      "Malonic Acid, Amino- (8CI)",
      "Jsp000609",
      "CHEMBL1232731",
      "KS-00000K",
    ],
  },
  {
    primary_id: "D0NW8L",
    names: ['Anti-CD19 "armored" CAR-Tcell Therapy'],
  },
  {
    primary_id: "D0NW8N",
    names: ["ISIS 105989"],
  },
  {
    primary_id: "D0NW9G",
    names: [
      "3-(9-Acridinylamino)-5-(Hydroxymethyl)Aniline (AHMA)",
      "CHEMBL321473",
      "NSC666097",
      "3-(9-Acridinylamino)-5-(Hydroxymethyl)Aniline",
      "154310-42-6",
      "[3-(Acridin-9-Ylamino)-5-Aminophenyl]methanol",
      "AC1Q7C6S",
      "AC1L8FG4",
      "SCHEMBL1865418",
      "CTK0E7866",
      "DTXSID30327555",
      "GZHHMAFXYHPHBO-UHFFFAOYSA-N",
      "ZINC3799478",
      "AKOS030584742",
      "NCI60_022917",
      "3-(9-Acridinylamino)-5-Hydroxymethylaniline",
      "3-(Acridin-9-Yl)Amino-5-Hydroxymethylaniline",
      "Benzenemethanol, 3-(9-Acridinylamino)-5-Amino-",
      "[3-(Acridin-9-Ylamino)-5-Amino-Phenyl]methanol",
    ],
  },
  {
    primary_id: "D0NX0M",
    names: [
      "3-(6-Methoxy-3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "CHEMBL206351",
      "Dihydronaphthalene 4",
      "AC1O70H9",
      "SCHEMBL4504105",
      "BDBM8891",
    ],
  },
  {
    primary_id: "D0NX0S",
    names: ["PTX-100"],
  },
  {
    primary_id: "D0NX1O",
    names: ["PMID26560530-Compound-17"],
  },
  {
    primary_id: "D0NX2G",
    names: ["(1-Benzyl-1H-Indazol-5-Yl)-Quinazolin-4-Yl-Amine", "CHEMBL284485", "SCHEMBL8561085", "BDBM50099965"],
  },
  {
    primary_id: "D0NX4W",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 14"],
  },
  {
    primary_id: "D0NX5K",
    names: ["Clonidine Topical Gel", "ARC-4558"],
  },
  {
    primary_id: "D0NX6F",
    names: ["BAY-85-3474"],
  },
  {
    primary_id: "D0NX8E",
    names: ["Triazole Gold Complexe 3"],
  },
  {
    primary_id: "D0NX8G",
    names: ["Pre-Pandemic Avian Influenza Vaccine"],
  },
  {
    primary_id: "D0NY1J",
    names: ["TG7-171"],
  },
  {
    primary_id: "D0NY1R",
    names: ["AC-3174"],
  },
  {
    primary_id: "D0NY1V",
    names: ["WIN 64338"],
  },
  {
    primary_id: "D0NY1Y",
    names: ["CART-34 Cells"],
  },
  {
    primary_id: "D0NY3W",
    names: ["N-Containing Heterocyclic Derivative 1"],
  },
  {
    primary_id: "D0NY4H",
    names: [
      "ReN-005",
      "CINES, ReNeuron",
      "Conditionally Immortalized Neuroepithelial Stem Cells, ReNeuron",
      "Neural Stem Cell Therapy, ReNeuron",
    ],
  },
  {
    primary_id: "D0NY4W",
    names: ["1-(4-Iodophenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One", "CHEMBL202479", "SCHEMBL4933112"],
  },
  {
    primary_id: "D0NY5G",
    names: ["Fullerene Derivative"],
  },
  {
    primary_id: "D0NY5Y",
    names: ["IMA-026"],
  },
  {
    primary_id: "D0NY6M",
    names: ["PMID27774822-Compound-Figure3CompoundI-165"],
  },
  {
    primary_id: "D0NY6P",
    names: ["TEPA (Possesses Cytotoxic Activity)"],
  },
  {
    primary_id: "D0NY6U",
    names: ["PMID24673130C26"],
  },
  {
    primary_id: "D0NY7A",
    names: ["S-19528"],
  },
  {
    primary_id: "D0NY7M",
    names: ["Tricyclic Benzimidazole Derivative 1"],
  },
  {
    primary_id: "D0NY9Q",
    names: ["MK2 Inhibitors"],
  },
  {
    primary_id: "D0NZ2D",
    names: ["SERCA 2a Gene Therapy"],
  },
  {
    primary_id: "D0NZ3C",
    names: ["TTC-352"],
  },
  {
    primary_id: "D0NZ3Q",
    names: ["Actoferrin"],
  },
  {
    primary_id: "D0O0AF",
    names: ["Sodium Sulfide Sulfur"],
  },
  {
    primary_id: "D0O0AH",
    names: [
      "MIRFENTANIL HYDROCHLORIDE",
      "A-3508 Hydrochloride",
      "ANQ-3508 HCl",
      "Mirfentanil Hydrochloride < Rec INNM",
      "OHM-10579 (Free Base)",
      "N-(1-Phenethyl-4-Piperidinyl)-N-(2-Pyrazinyl)Furan-2-Carboxamide Hydrochloride",
    ],
  },
  {
    primary_id: "D0O0AI",
    names: [
      "6-Carboxymethyluracil",
      "4628-39-1",
      "URACIL-6-ACETIC ACID",
      "Uracil-4-Acetic Acid",
      "(2,6-Dioxo-1,2,3,6-Tetrahydropyrimidin-4-Yl)Acetic Acid",
      "1,2,3,6-Tetrahydro-2,6-Dioxo-4-Pyrimidineacetic Acid",
      "2-(2,6-Dioxo-1,2,3,6-Tetrahydropyrimidin-4-Yl)Acetic Acid",
      "Uracil-6-Ylacetic Acid",
      "NSC13201",
      "4-Pyrimidineacetic Acid, 1,2,3,6-Tetrahydro-2,6-Dioxo-",
      "(2,6-Dioxo-1,2,3,6-Tetrahydro-Pyrimidin-4-Yl)-Acetic Acid",
      "CHEBI:46371",
      "2-(2,6-Dihydroxypyrimidin-4-Yl)Acetic Acid",
      "2-(2,4-Dioxo-1H-Pyrimidin-6-Yl)Acetic Acid",
      "4-Pyrimidineaceticacid, 1,2,",
    ],
  },
  {
    primary_id: "D0O0AR",
    names: ["RP-71483", "138279-55-7"],
  },
  {
    primary_id: "D0O0AW",
    names: ["Pyridine Derivative 21"],
  },
  {
    primary_id: "D0O0BL",
    names: ["2-Phenyl-4-[1,2,4]triazol-1-Yl-Chroman-7-Ol", "CHEMBL187185"],
  },
  {
    primary_id: "D0O0BM",
    names: ["PF-3463275"],
  },
  {
    primary_id: "D0O0BR",
    names: ["Poly-ICR"],
  },
  {
    primary_id: "D0O0BZ",
    names: ["ISIS 10373"],
  },
  {
    primary_id: "D0O0DF",
    names: ["Lirequinil"],
  },
  {
    primary_id: "D0O0DJ",
    names: ["Spiroimidazolone Derivative 1"],
  },
  {
    primary_id: "D0O0EA",
    names: ["KNI-10087"],
  },
  {
    primary_id: "D0O0EX",
    names: ["SAR-110894"],
  },
  {
    primary_id: "D0O0FA",
    names: ["KUSHENOL A"],
  },
  {
    primary_id: "D0O0FF",
    names: ["GW 468816"],
  },
  {
    primary_id: "D0O0FP",
    names: ["ST-1535"],
  },
  {
    primary_id: "D0O0GA",
    names: [
      "8-Fluoro-6H,11H-Indeno[1,2-C]isoquinolin-5-One",
      "CHEMBL370045",
      "BDBM27512",
      "Indeno[1,2-C]isoquinolinone, 1d",
      "ZINC13652896",
      "8-Fluoro-5,6-Dihydro-11H-Indeno[1,2-C]isoquinoline-5-One",
    ],
  },
  {
    primary_id: "D0O0GT",
    names: ["CVS-2139", "CHEMBL19599", "BDBM50111717"],
  },
  {
    primary_id: "D0O0GW",
    names: ["Example 2 (WO2013004676)"],
  },
  {
    primary_id: "D0O0GX",
    names: ["MedChew-1401"],
  },
  {
    primary_id: "D0O0GZ",
    names: ["Bicyclic Heteroaryl Benzamide Derivative 3"],
  },
  {
    primary_id: "D0O0HK",
    names: ["CRL-1018"],
  },
  {
    primary_id: "D0O0HR",
    names: [
      "KF 17837S",
      "152881-18-0",
      "8-(3,4-Dimethoxyphenyl)-7-Methyl-1,3-Dipropyl-1H-Purine-2,6(3H,7H)-Dione",
      "8-(3,4-Dimethoxyphenyl)-7-Methyl-1,3-Dipropylpurine-2,6-Dione",
      "1,3-Dipropyl-7-Methyl-8-(3,4-Dimethoxystyryl)Xanthine",
      "AC1L3X7D",
      "GTPL428",
      "CTK8C1262",
      "DTXSID30165151",
      "ZINC5114737",
      "7673AA",
      "ANW-66125",
      "AKOS016004661",
      "8-(3,4-Dimethoxyphenyl)-3,7-Dihydro-7-Methyl-1,3-Dipropyl-1H-Purine-2,6-Dione",
      "AJ-53127",
      "AX8236469",
      "L001123",
      "1H-Purine-2,6-Dione, 8-(3,4-Dimethoxyphenyl)-3,7-Dihydro-7-Methyl-1,3-Dipropyl-",
    ],
  },
  {
    primary_id: "D0O0HT",
    names: [
      "4-Sulfonamide-[1-(4-Aminobutane)]Benzamide",
      "4-Sulfonamide-[1-(4-Aminobutane)]benzamide",
      "N-(4-Aminobutyl)-4-Sulfamoylbenzamide",
      "AC1L1C69",
      "CHEMBL331282",
      "DB03697",
    ],
  },
  {
    primary_id: "D0O0HU",
    names: ["LAS-41007", "Dermatological Agent (Topical, Actinic Keratosis), Almirall"],
  },
  {
    primary_id: "D0O0II",
    names: ["ASC-JMZ1"],
  },
  {
    primary_id: "D0O0IZ",
    names: ["PD-135118", "CHEMBL335914", "CHEMBL353157", "SCHEMBL6894174", "BDBM50230684"],
  },
  {
    primary_id: "D0O0JB",
    names: [
      "PSMA-Targeted Tubulysin B Conjugates",
      "DUPA-TubH III",
      "DUPA-Tubulysin III",
      "EC-1069",
      "PSMA-Targeted Tubulysin B Conjugates (Prostate Cancer)",
      "PSMA-Targeted Tubulysin B Conjugates (Prostate Cancer), Endocyte/Purdue University",
    ],
  },
  {
    primary_id: "D0O0JH",
    names: ["F-12509A"],
  },
  {
    primary_id: "D0O0JO",
    names: ["N-(2-Chlorobenzyl)-5-(4-Chlorophenyl)Nicotinamide", "CHEMBL1269883"],
  },
  {
    primary_id: "D0O0JP",
    names: ["EMA-401"],
  },
  {
    primary_id: "D0O0LC",
    names: ["MER-101"],
  },
  {
    primary_id: "D0O0LF",
    names: [
      "F-18 Exendin-4 Derivative PET Tracers",
      "[18F]FB12-Ex4",
      "[18F]FB40-Ex4",
      "F-18 Exendin-4 Derivative PET Tracers (Diabetes)",
      "F-18 Exendin-4 Derivative PET Tracers (Diabetes), Kyoto University/Arkray",
    ],
  },
  {
    primary_id: "D0O0LQ",
    names: ["BI 2536", "BI2536, BI 2536"],
  },
  {
    primary_id: "D0O0LS",
    names: [
      "RXDX 101",
      "1108743-60-7",
      "RXDX-101",
      "UNII-L5ORF0AN1I",
      "Entrectinib (RXDX-101)",
      "L5ORF0AN1I",
      "Benzamide, N-[5-[(3,5-Difluorophenyl)Methyl]-1H-Indazol-3-Yl]-4-(4-Methyl-1-Piperazinyl)-2-[(Tetrahydro-2H-Pyran-4-Yl)Amino]-",
      "Benzamide, N-(5-((3,5-Difluorophenyl)Methyl)-1H-Indazol-3-Yl)-4-(4-Methyl-1-Piperazinyl)-2-((Tetrahydro-2H-Pyran-4-Yl)Amino)-",
      "Entrectinib [USAN:INN]",
      "YMX",
      "Kinome_2659",
      "Entrectinib(Rxdx-101)",
      "Entrectinib (USAN/INN)",
      "SCHEMBL3512601",
      "GTPL8290",
      "CHEMBL1983268",
      "KS-00000TSK",
    ],
  },
  {
    primary_id: "D0O0LU",
    names: [
      "1-Benzyl-1H-Indole-2,3-Dione",
      "1217-89-6",
      "1-Benzylisatin",
      "1-Benzylindole-2,3-Dione",
      "1-Benzyl-2,3-Dihydro-1H-Indole-2,3-Dione",
      "1-Benzylindoline-2,3-Dione",
      "1-Benzyl-2,3-Indolinedione",
      "1H-Indole-2,3-Dione, 1-(Phenylmethyl)-",
      "1H-Indole-2,3-Dione,1-(Phenylmethyl)-",
      "Benzylisatin",
      "N-Benzylisatin",
      "N-Benzyl Isatin",
      "4kwf",
      "NSC100000",
      "AC1Q6FLI",
      "AC1Q6FLG",
      "AC1L6CGC",
      "Isatin-Based Compound, 14",
      "AC1Q6K6Z",
      "N1-Benzyl-Indole-2,3-Dione",
      "CHEMBL115581",
      "SCHEMBL2087729",
      "BDBM22794",
      "CTK4B2748",
      "DTXSID30295164",
      "SIISFRLGYDVIRG-UHFFFAOYSA-N",
      "MolPort-000-322-417",
    ],
  },
  {
    primary_id: "D0O0MY",
    names: ["(+/-)-2-(4-Methoxyphenyl)-7-Methylchroman-4-One", "CHEMBL598913", "7-Methyl-4'-Methoxyflavanone"],
  },
  {
    primary_id: "D0O0NY",
    names: ["Sutilains"],
  },
  {
    primary_id: "D0O0OD",
    names: ["Sterol Derivative 3"],
  },
  {
    primary_id: "D0O0OP",
    names: [
      "[18F](R,R)-Quinuclidinyl-4-Fluoromethyl-Benzilate",
      "(R,R)-Quinuclidinyl-4-[18F]-Fluoromethyl-Benzilate",
      "(R,R)-Quinuclidinyl-4-[18F]-Fluoromethylbenzilate(PET Ligand)",
      "[18F]-FMeQNB",
      "(R,R)-[18F]-FMeQNB",
    ],
  },
  {
    primary_id: "D0O0PY",
    names: ["Pagoclone"],
  },
  {
    primary_id: "D0O0QB",
    names: ["ATG-Fresenius S"],
  },
  {
    primary_id: "D0O0QG",
    names: ["SC-51316"],
  },
  {
    primary_id: "D0O0QM",
    names: ["Modified Hepatocyte Growth Factor Gene Therapy"],
  },
  {
    primary_id: "D0O0QN",
    names: ["3-Aminoacetamido-4'-Methylfuro[3,2-G]coumarin", "CHEMBL202017", "BDBM50181852"],
  },
  {
    primary_id: "D0O0RB",
    names: ["6,6-Fused Nitrogenous Heterocyclic Compound 1"],
  },
  {
    primary_id: "D0O0RG",
    names: ["MUC-1 Dendritic Cancer Vaccine"],
  },
  {
    primary_id: "D0O0RK",
    names: [
      "A-81282",
      "141872-46-0",
      "Abbott 81282",
      "CHEMBL333534",
      "4-[butyl([2'-(1h-Tetrazol-5-Yl)[1,1'-Biphenyl]-4-Yl]methyl)Amino]-5-Pyrimidinecarboxylic Acid",
      "5-Pyrimidinecarboxylicacid, 4-[butyl[[2'-(2H-Tetrazol-5-Yl)[1,1'-Biphenyl]-4-Yl]methyl]amino]-",
      "Abbott-81282",
      "ACMC-20cd64",
      "AC1Q5UC5",
      "AC1L4U4U",
      "A 81282",
      "Oprea1_016094",
      "SCHEMBL7939345",
      "CTK8G9360",
      "CTK0I0417",
      "DTXSID70161839",
      "MolPort-039-032-861",
      "ZINC1533713",
      "BDBM50003155",
      "AKOS027380884",
      "4-(Butyl{[2'-(2h-Tetrazol-5-Yl)Biphenyl-4-Yl]methyl}amino)Pyrimidine-5-Carboxylic Acid",
      "CC-17571",
    ],
  },
  {
    primary_id: "D0O0RY",
    names: ["JB-1"],
  },
  {
    primary_id: "D0O0SH",
    names: [
      "SC-52892",
      "CHEMBL9642",
      "SCHEMBL7679693",
      "MIKRUZGZQMVMAG-UHFFFAOYSA-N",
      "BDBM50281814",
      "L007546",
      "1,4-Dibutyl-3-{6-[2-(1H-Tetrazol-5-Yl)-Phenyl]-Pyridin-3-Ylmethyl}-1,3-Dihydro-Imidazol-2-One",
    ],
  },
  {
    primary_id: "D0O0UE",
    names: [
      "4-(2-Phenylacetamido)Benzenesulfonamide",
      "2-Phenyl-N-(4-Sulfamoylphenyl)Acetamide",
      "N-[4-(Aminosulfonyl)Phenyl]-2-Phenylacetamide",
      "331274-56-7",
      "3oys",
      "Sulfonamide Deriv., 7a",
      "AC1LE45L",
      "Oprea1_842052",
      "CHEMBL574782",
      "ARONIS000876",
      "SCHEMBL13276539",
      "KS-00003UGZ",
      "BDBM35733",
      "MolPort-001-503-086",
      "ZINC142256",
      "STK097687",
      "AKOS000490206",
      "MCULE-4432635286",
      "ST039768",
      "AN-329/10027012",
      "F3145-0673",
    ],
  },
  {
    primary_id: "D0O0UG",
    names: ["PMID25666693-Compound-86"],
  },
  {
    primary_id: "D0O0UM",
    names: ["GM2-N"],
  },
  {
    primary_id: "D0O0UU",
    names: ["Flourouracil"],
  },
  {
    primary_id: "D0O0VE",
    names: ["Oncoquest-L"],
  },
  {
    primary_id: "D0O0VP",
    names: ["PYRAZOLOPYRIDAZINE 2"],
  },
  {
    primary_id: "D0O0VW",
    names: ["HGP-30W"],
  },
  {
    primary_id: "D0O0VZ",
    names: ["Piceatannol"],
  },
  {
    primary_id: "D0O0XX",
    names: ["(S)-Hexylhomoibotenic Acid", "(S)-HexylHIBO"],
  },
  {
    primary_id: "D0O0YA",
    names: ["SPIO-Stasix Nanoparticles"],
  },
  {
    primary_id: "D0O0YI",
    names: ["PMID25656651-Compound-7"],
  },
  {
    primary_id: "D0O0ZC",
    names: [
      "6-Hydroxyflavanone",
      "4250-77-5",
      "6-Hydroxy-2-Phenylchroman-4-One",
      "6-Hydroxy-2-Phenyl-2,3-Dihydro-4H-Chromen-4-One",
      "6-Hydroxy-Flavanone",
      "CHEMBL195033",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-6-Hydroxy-2-Phenyl-",
      "CHEBI:34471",
      "XYHWPQUEOOBIOW-UHFFFAOYSA-N",
      "6-Hydroxy-2-Phenyl-Chroman-4-One",
      "MFCD00017485",
      "6-Hydroxy-2-Phenyl-2,3-Dihydrochromen-4-One",
      "Flavanone, 6-Hydroxy-",
      "(+/-)-6-Hydroxyflavanone",
      "6 - Hydroxyflavanone",
      "ACMC-209jpu",
      "AC1MC1GQ",
      "Maybridge3_000570",
      "6-Hydroxyflavanone, 99%",
      "Bmse000985",
      "C14221",
      "Oprea1_761592",
      "SCHEMBL126598",
    ],
  },
  {
    primary_id: "D0O0ZG",
    names: ["Micrurus Fulvius Antivenin"],
  },
  {
    primary_id: "D0O0ZH",
    names: ["Infanrix-DTaP-IPV-Hep B-Hib"],
  },
  {
    primary_id: "D0O0ZU",
    names: [
      "Hexane-1,6-Diol",
      "1,6-HEXANEDIOL",
      "629-11-8",
      "Hexamethylene Glycol",
      "1,6-Dihydroxyhexane",
      "Hexamethylenediol",
      "Alpha,omega-Hexanediol",
      ".alpha.,.omega.-Hexanediol",
      "UNII-ZIA319275I",
      "CCRIS 8982",
      "6-Hydroxy-1-Hexanol",
      "1,6-Hexylene Glycol",
      "HSDB 6488",
      "NSC 508",
      "EINECS 211-074-0",
      "1,6-Hexanediol, 97%",
      "BRN 1633461",
      "AI3-03307",
      "CHEBI:43078",
      "XXMIOPMDWAUFGU-UHFFFAOYSA-N",
      "ZIA319275I",
      "HEZ",
      "1,6hexanediol",
      "1.6-Hexanediol",
      "1,6 Hexanediol",
      "1,6-Hexandiol",
      "1.6-Hexandiol",
      ".omega.-Hexanediol",
      "1,6-Hexane Diol",
      "Hexan-1,6-Diol",
      "1,6-Hexan-Diol",
    ],
  },
  {
    primary_id: "D0O1AK",
    names: ["D-Glutamic Acid", "D-Glutamate", "D-Glu"],
  },
  {
    primary_id: "D0O1AN",
    names: ["Hydroxypropyl Methylcellulose"],
  },
  {
    primary_id: "D0O1AV",
    names: ["Squalestatin 1"],
  },
  {
    primary_id: "D0O1BJ",
    names: [
      "MICROCYSTIN-LR",
      "Akerstox",
      "Microcystin-A",
      "5-L-Argininecyanoginosin LA",
      "Toxin I (Microcystis Aeruginosa)",
      "Cyanoginosin LA, 5-L-Arginine-",
      "Toxin T 17 (Microcystis Aeruginosa)",
      "BRN 4779759",
      "Toxin, Blue Green Alga, Microcystis Aeruginosa",
      "AC1O5MXK",
      "DTXSID00424146",
      "128657-50-1",
      "LS-55782",
    ],
  },
  {
    primary_id: "D0O1BL",
    names: ["BMN053"],
  },
  {
    primary_id: "D0O1BN",
    names: ["SULFOARECOLINE"],
  },
  {
    primary_id: "D0O1BT",
    names: ["2-(4-Hexyl-2-Methoxyphenoxy)Pyrimidine", "CHEMBL429332"],
  },
  {
    primary_id: "D0O1CJ",
    names: ["AdVac Tuberculosis Vaccine"],
  },
  {
    primary_id: "D0O1CN",
    names: ["ISIS 140161"],
  },
  {
    primary_id: "D0O1CW",
    names: [
      "GNF-PF-2700",
      "5361-37-5",
      "GNF-Pf-2700",
      "N-(8-Ethoxy-4-Methylquinazolin-2-Yl)Guanidine",
      "1-(8-Ethoxy-4-Methylquinazolin-2-Yl)Guanidine",
      "CHEMBL602578",
      "N-(8-Ethoxy-4-Methyl-Quinazolin-2-Yl)-Guanidine",
      "Guanidine, N-(8-Ethoxy-4-Methyl-2-Quinazolinyl)-",
      "Amino(8-Ethoxy-4-Methylquinazolin-2-Yl)Carboxamidine",
      "BAS 00364218",
      "2-(8-Ethoxy-4-Methylquinazolin-2-Yl)Guanidine",
      "AC1MDO65",
      "SCHEMBL4104976",
      "MolPort-000-870-551",
      "ZX-AN037437",
      "ALBB-021848",
      "BDBM50322849",
      "STK091313",
      "SBB041931",
      "ZINC19314134",
      "AKOS000270455",
      "MCULE-2552194685",
      "ST4052426",
      "R3533",
    ],
  },
  {
    primary_id: "D0O1DS",
    names: ["[123I] Iodobenzoyl 12-Ex4"],
  },
  {
    primary_id: "D0O1DU",
    names: [
      "D-Phenylalanyl-N-(3-Chlorobenzyl)-L-Prolinamide",
      "CHEMBL321130",
      "CHEMBL599035",
      "628262-82-8",
      "22U",
      "CTK1I8953",
      "DTXSID70648418",
      "BDBM50133531",
      "BDBM50307858",
      "DB06919",
      "D-Phenylalanyl-N-[(3-Chlorophenyl)Methyl]-L-Prolinamide",
      "L-Prolinamide, D-Phenylalanyl-N-[(3-Chlorophenyl)Methyl]-",
      "N-(3-Chlorobenzyl)-1-[(2R)-2-Amino-3-Phenylpropionyl]-L-Prolinamide",
      "(S)-1-((R)-2-Amino-3-Phenyl-Propionyl)-Pyrrolidine-2-Carboxylic Acid 3-Chloro-Benzylamide",
      "(S)-1-((R)-2-Amino-3-Phenylpropanoyl)-N-(3-Chlorobenzyl)Pyrrolidine-2-Carboxamide Hydrochlo",
    ],
  },
  {
    primary_id: "D0O1EH",
    names: ["KP415"],
  },
  {
    primary_id: "D0O1EI",
    names: ["PMX-500"],
  },
  {
    primary_id: "D0O1EY",
    names: [
      "9-Aminomethyl-9H-Fluorene-2,5,6-Triol",
      "9-(Aminomethyl)-9H-Fluorene-2,5,6-Triol",
      "CHEMBL57672",
      "103692-55-3",
      "9H-Fluorene-2,5,6-Triol,9-(Aminomethyl)-",
      "2,5,6-Tfma",
      "AC1L3YRM",
      "ACMC-1C8EO",
      "2,5,6-Trihydroxy-9H-Fluorene-9-Methanamine",
      "CTK4A2322",
      "BDBM50025202",
      "9H-Fluorene-2,5,6-Triol, 9-(Aminomethyl)-",
    ],
  },
  {
    primary_id: "D0O1FU",
    names: [
      "FAMOXADONE",
      "(S)-Famoxadone",
      "(-)-Famoxadone",
      "(S)-(-)-Famoxadone",
      "CHEBI:106738",
      "(5S)-5-Methyl-5-(4-Phenoxyphenyl)-3-(Phenylamino)-1,3-Oxazolidine-2,4-Dione",
      "(5S)-3-Anilino-5-Methyl-5-(4-Phenoxyphenyl)-1,3-Oxazolidine-2,4-Dione",
      "(S)-5-Methyl-5-(4-Phenoxy-Phenyl)-3-Phenylamino-Oxazolidine-2,4-Dione",
      "5-METHYL-5-(4-PHENOXYPHENYL)-3-(PHENYLAMINO)-2,4-OXAZOLIDINEDIONE",
      "FMX",
      "AC1L9K5S",
      "CHEMBL10118",
      "SCHEMBL673443",
      "PCCSBWNGDMYFCW-QFIPXVFZSA-N",
      "ZINC6491064",
      "DB07778",
      "AJ-56253",
      "UNII-V1C07OR6II Component PCCSBWNGDMYFCW-QFIPXVFZSA-N",
    ],
  },
  {
    primary_id: "D0O1GE",
    names: [
      "3-(3-Methoxyphenyl)-6-Methyl-2H-Chromen-2-One",
      "CHEMBL570731",
      "2H-1-Benzopyran-2-One,3-(3-Methoxyphenyl)-6-Methyl-",
      "SCHEMBL17857194",
      "BDBM50300895",
    ],
  },
  {
    primary_id: "D0O1GS",
    names: ["Fulranumab"],
  },
  {
    primary_id: "D0O1HH",
    names: [
      "3-(Pyridin-3-Yl)Prop-2-Yn-1-Amine",
      "777856-62-9",
      "2-PROPYN-1-AMINE, 3-(3-PYRIDINYL)-",
      "CHEMBL360541",
      "SCHEMBL3603698",
      "CTK2G6074",
      "BDBM12348",
      "DTXSID90460412",
      "MolPort-014-472-743",
      "BGKUWZFWNZFRMO-UHFFFAOYSA-N",
      "ZINC13607158",
      "Nicotine 3-Heteroaromatic Analogue 3a",
      "AKOS010148504",
      "MCULE-3042424640",
      "NE58716",
      "US8609708, 3",
      "Z1333761438",
    ],
  },
  {
    primary_id: "D0O1HL",
    names: ["SC-49483"],
  },
  {
    primary_id: "D0O1IM",
    names: [
      "CC-115",
      "CC-115 (Hydrochloride)",
      "CC-115 Hydrochloride",
      "1300118-55-1",
      "SCHEMBL1767621",
      "BCP20709",
      "HY-16962A",
      "AKOS030526389",
    ],
  },
  {
    primary_id: "D0O1JC",
    names: [
      "2-Hex-5-Enyl-5-Nonyl-Pyrrolidine",
      "2-Hex-5-Enyl-5-Nonylpyrrolidine",
      "CHEMBL417618",
      "100594-88-5",
      "Pyrrolidine, 2-(5-Hexenyl)-5-Nonyl-",
      "AC1L481F",
      "2-(5-Hexenyl)-5-Nonylpyrrolidine",
      "BDBM50284429",
    ],
  },
  {
    primary_id: "D0O1JD",
    names: [
      "6-(4-(2-(Benzylamino)Ethyl)Phenoxy)Nicotinamide",
      "CHEMBL237066",
      "6-[4-(2-Benzylamino-Ethyl)-Phenoxy]-Nicotinamide",
      "SCHEMBL3698270",
      "FCDWJNPFPYAKET-UHFFFAOYSA-N",
      "BDBM50219925",
    ],
  },
  {
    primary_id: "D0O1JH",
    names: ["KNI-727"],
  },
  {
    primary_id: "D0O1KA",
    names: ["[Aib7]OT", "CHEMBL395430"],
  },
  {
    primary_id: "D0O1KD",
    names: [
      "1-Anilino-8-Naphthalene Sulfonate",
      "82-76-8",
      "8-Anilino-1-Naphthalenesulfonic Acid",
      "Phenylperi Acid",
      "8-(Phenylamino)Naphthalene-1-Sulfonic Acid",
      "N-Phenyl Peri Acid",
      "1-Anilino-8-Naphthalenesulfonate",
      "1-Anilino-8-Naphthalenesulfonic Acid",
      "Peri Acid, Phenyl-",
      "8-Anilinonaphthalene-1-Sulfonic Acid",
      "ANS",
      "1-Naphthalenesulfonic Acid, 8-(Phenylamino)-",
      "8-Anilino-1-Naphthalene Sulfonic Acid",
      "ANSA",
      "1-(Phenylamino)-8-Naphthalenesulfonic Acid",
      "1-Anilino-8-Napthalenesulfonate",
    ],
  },
  {
    primary_id: "D0O1KN",
    names: ["Vaccines, CLIPS, Infection"],
  },
  {
    primary_id: "D0O1KX",
    names: [
      "Avasimibe",
      "166518-60-1",
      "CI-1011",
      "Avasimibe(CI-1011)",
      "UNII-28LQ20T5RC",
      "CI 1011",
      "Avasimibe (CI-1011)",
      "PD 148515",
      "CHEMBL101309",
      "28LQ20T5RC",
      "2,6-Diisopropylphenyl (2-(2,4,6-Triisopropylphenyl)Acetyl)Sulfamate",
      "2,6-Diisopropylphenyl 2-(2,4,6-Triisopropylphenyl)Acetylsulfamate",
      "2,6-Diisopropylphenyl ((2,4,6-Triisopropylphenyl)Acetyl)Sulfamate",
      "2,6-Diisopropylphenyl (2-(2,4,6-Triisopropylphenyl)-Acetyl)Sulfamate",
      "((2,4,6-Tris(1-Methylethyl)Phenyl)Acetyl)Sulfamic Acid 2,6-Bis(1-Methylethyl)Phenyl Ester",
    ],
  },
  {
    primary_id: "D0O1LD",
    names: ["ZSTK474"],
  },
  {
    primary_id: "D0O1LU",
    names: ["EP-51389"],
  },
  {
    primary_id: "D0O1LW",
    names: [
      "2-Deoxy-2-Amino Glucitol-6-Phosphate",
      "AGP",
      "2-Amino-2-Deoxy-D-Glucitol 6-Phosphate",
      "2-Amino-2-Deoxy-6-O-Phosphono-D-Glucitol",
      "AC1L9J4R",
      "SCHEMBL2847976",
      "CHEMBL396380",
      "DB02445",
      "2-Amino-2-Deoxy-D-Glucitol-6-Phosphate",
      "D-Glucitol, 2-Amino-2-Deoxy-, 6-(Dihydrogen Phosphate)",
      "[(2R,3S,4R,5S)-5-Amino-2,3,4,6-Tetrahydroxyhexyl] Dihydrogen Phosphate",
      "[(2R,3S,4R,5S)-5-Amino-2,3,4,6-Tetrahydroxy-Hexyl] Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0O1LX",
    names: ["PMID29671355-Compound-71a"],
  },
  {
    primary_id: "D0O1MP",
    names: [
      "CARZELESIN",
      "Carzelesin < Rec INN",
      "U-80244",
      "(S)-N-[2-[1-(Chloromethyl)-8-Methyl-5-(N-Phenylcarbamoyloxy)-1,2,3,6-Tetrahydrobenzo[1,2-B:4,3-B']dipyrrol-3-Ylcarbonyl]-1H-Indol-5-Yl]-6-(Diethylamino)Benzofuran-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0O1NF",
    names: ["Single Mab Therapy"],
  },
  {
    primary_id: "D0O1OC",
    names: ["PMID2296036C2g"],
  },
  {
    primary_id: "D0O1OL",
    names: ["Polymyxins"],
  },
  {
    primary_id: "D0O1OX",
    names: ["GA-FSH"],
  },
  {
    primary_id: "D0O1OZ",
    names: ["BRL-54504AX", "CHEMBL87114"],
  },
  {
    primary_id: "D0O1PH",
    names: ["Ethanolamine Oleate"],
  },
  {
    primary_id: "D0O1PM",
    names: ["(4-Ethynyl-Cyclohex-3-Enyl)-Dipropyl-Amine", "4-Ethynyl-N,N-Dipropylcyclohex-3-Enamine"],
  },
  {
    primary_id: "D0O1PU",
    names: ["BAY-39-9624"],
  },
  {
    primary_id: "D0O1QB",
    names: [
      "BW284C51",
      "4-(5-{4-[Dimethyl(Prop-2-Enyl)Ammonio]phenyl}-3-Oxopentyl)-N,N-Dimethyl-N-Prop-2-Enylbenzenaminium",
      "BW284c51",
      "CHEMBL140020",
      "BW-28C51",
      "EBW",
      "4-(5-{4-[DIMETHYL(PROP-2-ENYL)AMMONIO]PHENYL}-3-OXOPENTYL)-N,N-DIMETHYL-N-PROP-2-ENYLBENZENAMINIUM",
      "CHEMBL464538",
      "SCHEMBL7449688",
      "GTPL6600",
      "AC1L1B98",
      "BDBM10624",
      "ZAEXMNKDGJNLTA-UHFFFAOYSA-N",
      "ZINC3814201",
      "NCGC00247016-01",
      "1,5-Bis[4-(Allyldimethylaminio)Phenyl]pentane-3-One",
      "1,5-Bis(4-Allyldimethylammoniumphenyl)-Pentan-3-One Dibromide",
      "4,4'-(3-Oxopentane-1,5-Diyl)Bis(N,N-Dimethyl-N-Prop-2-En-1-Ylanilinium)",
    ],
  },
  {
    primary_id: "D0O1QK",
    names: ["MeAIB", "Alpha-(Methylamino)Isobutyric Acid", "2-(Methylamino)Isobutyric Acid"],
  },
  {
    primary_id: "D0O1QT",
    names: [
      "6-Amino-Benzothiazole-2-Sulfonic Acid Amide",
      "6-Aminobenzo[d]thiazole-2-Sulfonamide",
      "6-Amino-2-Benzothiazolesulfonamide",
      "94641-11-9",
      "MR69RTSOVN",
      "UNII-MR69RTSOVN",
      "CHEMBL35118",
      "Aminozolamide",
      "AC1L3QBY",
      "SCHEMBL9459715",
      "OFSGGVHHSMAUEI-UHFFFAOYSA-N",
      "6-Aminobenzothiazole-2-Sulfonamide",
      "ZINC5162075",
      "BDBM50079035",
      "2-Benzothiazolesulfonamide, 6-Amino-",
      "AKOS022656973",
      "FCH1394039",
      "6-Amino-1,3-Benzothiazole-2-Sulfonamide",
      "AX8292464",
    ],
  },
  {
    primary_id: "D0O1RH",
    names: ["KYN-54"],
  },
  {
    primary_id: "D0O1RL",
    names: ["PMID26004420-Compound-WO2014015495D"],
  },
  {
    primary_id: "D0O1TC",
    names: [
      "Gamma-Homolinolenic Acid",
      "DGLA",
      "BML3-B11",
      "Ro 12-1989",
      "Star GLA (GNC)",
      "Bishomo-Gamma-Linolenic Acid",
      "C 20:3 N-6",
      "Dihomo-Gamma-Linolenic Acid",
      "Homo-Gamma-Linolenic Acid",
      "Homo-Gamma-Linolensaeure",
      "Tona-Lean 1000 CLA (Action Labs)",
      "Cis-8,11,14-Eicosatrienoic Acid",
      "Eicosa-8Z,11Z,14Z-Trienoic Acid",
      "All-Cis-8,11,14-Eicosatrienoic Acid",
      "All-Cis-8,11,14-Icosatrienoic Acid",
      "All-Cis-Eicosa-8,11,14-Triensaeure",
      "Cis,cis,cis-8,11,14-Eicosatrienoic Acid",
      "Cis-8,cis-11,cis-14-Eicosatrienoic Acid",
      "(8E,11E,14E)-8,11,14-Icosatrienoic Acid",
      "(8Z,11Z,14Z)-Icosatrienoic Acid",
      "(8Z,11Z,14Z)-Icosa-8,11,14-Trienoic Acid",
      "(Z,Z,Z)-8,11,14-Eicosatrienoate",
      "(Z,Z,Z)-8,11,14-Eicosatrienoic Acid",
      "(Z,Z,Z)-8,11,14-Icosatrienoate",
      "(Z,Z,Z)-8,11,14-Icosatrienoicacid",
      "20:3, N-6,9,12 All-Cis",
      "8, 11, 14-Eicosatrienoic Acid",
      "8,11,14-Eicosatrienoate",
      "8,11,14-Eicosatrienoic Acid",
      "8,11,14-Eicosatrienoic Acid, (8Z,11Z,14Z)-(9CI)",
      "8,11,14-Eicosatrienoic Acid, (Z,Z,Z)-(8CI)",
      "8,11,14-Icosatrienoate",
      "8,11,14-All-Cis-Eicosatrienoic Acid",
      "8c,11c,14c-Eicosatriensaeure",
      "8c,11c,14c-Eicosatrienoic Acid",
    ],
  },
  {
    primary_id: "D0O1TJ",
    names: ["SAR439859"],
  },
  {
    primary_id: "D0O1UC",
    names: ["NTC-801"],
  },
  {
    primary_id: "D0O1UP",
    names: ["PRB-01022"],
  },
  {
    primary_id: "D0O1UQ",
    names: [
      "4-(Trifluoromethyl)Phenylboronic Acid",
      "128796-39-4",
      "4-Trifluoromethylphenylboronic Acid",
      "4-(Trifluoromethyl)Benzeneboronic Acid",
      "(4-(Trifluoromethyl)Phenyl)Boronic Acid",
      "[4-(Trifluoromethyl)Phenyl]boronic Acid",
      "(4-Trifluoromethylphenyl)Boronic Acid",
      "[4-(Trifluoromethyl)Phenyl]boranediol",
      "4-Boronobenzotrifluoride",
      "P-(Trifluoromethyl)Phenylboronic Acid",
      "CHEMBL143654",
      "Alpha,alpha,alpha-Trifluoro-P-Tolylboronic Acid",
      "4-Trifluoromethylphenyl Boronic Acid",
      "4-Trifluoromethylphenyl-Boronic Acid",
      "MFCD00151855",
      "Boronic Acid, [4-(Tr",
    ],
  },
  {
    primary_id: "D0O1UT",
    names: [
      "ICX-PRO",
      "Cyzact",
      "ProtoDerm",
      "ICXP-007",
      "Allogeneic Human Dermal Fibroblasts (Wound Healing), Healthpoint",
      "Allogeneic Human Dermal Fibroblasts (Wound Healing), Intercytex",
    ],
  },
  {
    primary_id: "D0O1UX",
    names: ["Peanut Allergy Desensitization Vaccine"],
  },
  {
    primary_id: "D0O1UZ",
    names: [
      "Cannabidiol",
      "13956-29-1",
      "(-)-Cannabidiol",
      "(-)-Trans-Cannabidiol",
      "Epidiolex",
      "UNII-19GBJ60SN5",
      "(-)-Trans-2-P-Mentha-1,8-Dien-3-Yl-5-Pentylresorcinol",
      "Delta1(2)-Trans-Cannabidiol",
      "(-)-CBD",
      "GWP42003-P",
      "CBD",
      "19GBJ60SN5",
      "QHMBSVQNZZTUGM-ZWKOTPCHSA-N",
    ],
  },
  {
    primary_id: "D0O1VU",
    names: ["EB-001"],
  },
  {
    primary_id: "D0O1WJ",
    names: ["SR-1001"],
  },
  {
    primary_id: "D0O1WR",
    names: [
      "GMZ-2",
      "Adjuvanted PfGLURP + PfMSP3 Vaccine (Malaria), AMANET",
      "GLURP-MSP3 Bivalent Vaccine (Malaria), EMVI",
      "Malaria Vaccine (PfGLURP + PfMSP3 + Aluminium Hydroxide Adjuvant), EMVI",
    ],
  },
  {
    primary_id: "D0O1WV",
    names: ["Threo-Ritalinol Hydrochloride"],
  },
  {
    primary_id: "D0O1WX",
    names: [
      "Ciprofloxacin XR",
      "Ciprofloxacin",
      "85721-33-1",
      "Ciprofloxacine",
      "Ciprobay",
      "Ciproxan",
      "Ciprofloxacina",
      "Ciprofloxacinum",
      "Ciprofloxacino",
      "Cipro IV",
      "Ciproxina",
      "Ciprinol",
      "Bernoflox",
      "Ciprodar",
      "Cifloxin",
      "Septicide",
      "Bacquinor",
      "Ciproquinol",
      "Cipromycin",
      "Ciprocinol",
      "Cipro XR",
      "Superocin",
      "Ciprowin",
      "Ciprolon",
      "Ciproflox",
      "Ciprecu",
      "BAY Q 3939",
      "Spitacin",
      "Quintor",
      "Quinolid",
      "Proflaxin",
      "Probiox",
      "Ipiflox",
      "Zumaflox",
      "Ciproxine",
      "Ciprolin",
      "Roxytal",
      "Italnik",
      "Fimoflox",
      "Corsacin",
      "Citopcin",
      "Ciprogis",
      "Rancif",
      "Ciriax",
      "Ciplus",
      "Baflox",
      "Loxan",
      "Cilab",
      "Cycin",
      "Cixan",
      "Unex",
      "GW1843",
      "Ciprofloxacin Hydrochloride",
      "Ciprofloxacin Intratympanic - Otonomy",
    ],
  },
  {
    primary_id: "D0O1XO",
    names: ["SYM-007"],
  },
  {
    primary_id: "D0O1XU",
    names: ["XR-3005"],
  },
  {
    primary_id: "D0O1XV",
    names: ["GSK2374697"],
  },
  {
    primary_id: "D0O1YF",
    names: ["RhLT28-171"],
  },
  {
    primary_id: "D0O1YH",
    names: ["4,5,6,7-Tetrabromo-1H-Benzo[d][1,2,3]triazole", "4,5,6,7-Tetrabromobenzotriazole"],
  },
  {
    primary_id: "D0O1YO",
    names: ["[Sar1,Tdf2]AngII", "CHEMBL1076602"],
  },
  {
    primary_id: "D0O1ZI",
    names: [
      "AZD8329",
      "AZD-8329",
      "AZD 8329",
      "UNII-5B38CV1212",
      "1048668-70-7",
      "CHEMBL2177609",
      "5B38CV1212",
      "4-[4-(2-Adamantylcarbamoyl)-5-Tert-Butyl-Pyrazol-1-Yl]benzoic Acid",
      "XWBXJBSVYVJAMZ-UHFFFAOYSA-N",
      "SCHEMBL617351",
      "GTPL7715",
      "BDBM50399335",
      "4-(4-(Adamant-2-Ylcarbamoyl)-5-Tert-Butylpyrazol-1-Yl)Benzoic Acid",
      "Benzoic Acid, 4-(5-(1,1-Dimethylethyl)-4-((Tricyclo(3.3.1.13,7)Dec-2-Ylamino)Carbonyl)-1H-Pyrazol-1-Yl)-",
      "4-[4-(2-Adamantylcarbamoyl)-5-Tertbutyl-Pyrazol-1-Yl]benzoic Acid",
      "4-[4-(2-Adamantylcarbamoyl)-5-Tert-Butylpyrazol-1-Yl]benzoic Ac",
    ],
  },
  {
    primary_id: "D0O1ZM",
    names: ["3-(4-Amino-Phenyl)-3-Hexyl-Piperidine-2,6-Dione", "CHEMBL29339"],
  },
  {
    primary_id: "D0O2AC",
    names: ["FR-145237"],
  },
  {
    primary_id: "D0O2AG",
    names: ["Pirsidomine", "C87-3754", "CAS-936"],
  },
  {
    primary_id: "D0O2AV",
    names: ["MRS1486"],
  },
  {
    primary_id: "D0O2AW",
    names: [
      "Pexidartinib",
      "1029044-16-3",
      "Pexidartinib (PLX3397)",
      "UNII-6783M2LV5X",
      "CHEMBL3813873",
      "CML-261",
      "6783M2LV5X",
      "5-((5-Chloro-1H-Pyrrolo[2,3-B]pyridin-3-Yl)Methyl)-N-((6-(Trifluoromethyl)Pyridin-3-Yl)Methyl)Pyridin-2-Amine",
      "Pexidartinib [INN]",
      "5-[(5-Chloro-1h-Pyrrolo[2,3-B]pyridin-3-Yl)Methyl]-N-{[6-(Trifluoromethyl)Pyridin-3-Yl]methyl}pyridin-2-Amine",
      "5-[(5-Chloro-1H-Pyrrolo[2,3-B]pyridin-3-Yl)Methyl]-N-[[6-(Trifluoromethyl)Pyridin-3-Yl]methyl]pyridin-2-Amine",
      "Pexidartinib [USAN]",
      "Pexidartinib(PLX3397)",
      "Pexida",
    ],
  },
  {
    primary_id: "D0O2BI",
    names: ["Ac-I[CVWQDWGHHRC]T-NH2"],
  },
  {
    primary_id: "D0O2CB",
    names: ["6-Hydroxymanzamine A", "CHEMBL252672"],
  },
  {
    primary_id: "D0O2CH",
    names: ["Makaluvamine N", "CHEMBL1162277"],
  },
  {
    primary_id: "D0O2CM",
    names: [
      "FLUORO-NEPLANOCIN A",
      "CHEMBL64718",
      "(1s,2r,5s)-5-(6-Amino-9h-Purin-9-Yl)-4-Fluoro-3-(Hydroxymethyl)Cyclopent-3-Ene-1,2-Diol",
      "AC1LAYBA",
      "SCHEMBL9215628",
      "ZINC3801483",
      "BDBM50144206",
      "KB-77273",
      "5-(6-Amino-Purin-9-Yl)-4-Fluoro-3-Hydroxymethyl-Cyclopent-3-Ene-1,2-Diol",
      "(1S,2R,5S)-5-(6-Aminopurin-9-Yl)-4-Fluoro-3-(Hydroxymethyl)Cyclopent-3-Ene-1,2-Diol",
    ],
  },
  {
    primary_id: "D0O2DN",
    names: ["L-797,591"],
  },
  {
    primary_id: "D0O2DQ",
    names: ["Mepacrine"],
  },
  {
    primary_id: "D0O2DY",
    names: ["PMID28627961-Compound-41"],
  },
  {
    primary_id: "D0O2EB",
    names: ["F-11105"],
  },
  {
    primary_id: "D0O2EF",
    names: ["PMID25991433-Compound-Q2"],
  },
  {
    primary_id: "D0O2EM",
    names: ["Nevirapine"],
  },
  {
    primary_id: "D0O2EN",
    names: ["PMID25772215-Compound-US02014179750TG6-129"],
  },
  {
    primary_id: "D0O2ET",
    names: ["AD-5467"],
  },
  {
    primary_id: "D0O2FD",
    names: ["PMID1656041C11ff"],
  },
  {
    primary_id: "D0O2FF",
    names: ["Epstein-Barr Vaccine"],
  },
  {
    primary_id: "D0O2FN",
    names: [
      "RG7420",
      "Cobimetinib",
      "934660-93-2",
      "GDC-0973",
      "XL518",
      "Xl-518",
      "GDC 0973",
      "UNII-ER29L26N1X",
      "XL 518",
      "ER29L26N1X",
      "CHEMBL2146883",
      "Cobimetinib (GDC-0973, RG7420)",
      "AK173907",
      "(S)-(3,4-Difluoro-2-(2-Fluoro-4-Iodophenylamino)Phenyl)(3-Hydroxy-3-(Piperidin-2-Yl)Azetidin-1-Yl)Methanone",
      "[3,4-Difluoro-2-(2-Fluoro-4-Iodo-Phenylamino)-Phenyl]-((S)-3-Hydroxy-3-Piperidin-2-Yl-Azetidin-1-Yl)-Methanone",
      "[3,4-Difluoro-2-[(2-Fluoro-4-Iodophenyl)Amino]phenyl][3-Hydroxy-3-[(2S)-2-Piperidinyl]-1-Azetidinyl]methanone",
    ],
  },
  {
    primary_id: "D0O2GK",
    names: [
      "N-(2-(5-Methoxybenzofuran-3-Yl)Ethyl)Acetamide",
      "CHEMBL323332",
      "SCHEMBL7018941",
      "ACETAMIDE,N-[2-(5-METHOXY-3-BENZOFURANYL)- ETHYL]-",
      "ZINC13474338",
      "BDBM50037242",
      "ACM27404359",
      "27404-35-9",
      "3-[2-(Acetylamino)Ethyl]-5-Methoxybenzofuran",
    ],
  },
  {
    primary_id: "D0O2HK",
    names: ["PMID27454349-Compound-102"],
  },
  {
    primary_id: "D0O2HL",
    names: ["IC980033"],
  },
  {
    primary_id: "D0O2HQ",
    names: ["Iopamidol"],
  },
  {
    primary_id: "D0O2HW",
    names: ["PMID25656651-Compound-27"],
  },
  {
    primary_id: "D0O2IE",
    names: ["Phenacetin"],
  },
  {
    primary_id: "D0O2JI",
    names: ["Ertumaxomab"],
  },
  {
    primary_id: "D0O2JT",
    names: ["MyVax"],
  },
  {
    primary_id: "D0O2KO",
    names: ["N-Omega-Propyl-L-Arginine"],
  },
  {
    primary_id: "D0O2KP",
    names: [
      "2-Phenyl-1,2'-Spirobi[1H-Indene]-5'-Ol",
      "CHEMBL439806",
      "SCHEMBL6283895",
      "BDBM50123212",
      "2-Phenyl-1,2''-Spirobi[1H-Indene]-5''-Ol",
    ],
  },
  {
    primary_id: "D0O2LC",
    names: [
      "2-(N-Cyclopentylamino)-3'-Chloropropiophenone",
      "CHEMBL566618",
      "SCHEMBL633829",
      "BDBM50302916",
      "2-(N-Cyclopentylamino)-3''-Chloropropiophenone",
    ],
  },
  {
    primary_id: "D0O2LD",
    names: ["THEBAINE"],
  },
  {
    primary_id: "D0O2LY",
    names: ["ISIS 6957"],
  },
  {
    primary_id: "D0O2MO",
    names: ["CRX-675"],
  },
  {
    primary_id: "D0O2MW",
    names: ["APN-501"],
  },
  {
    primary_id: "D0O2NQ",
    names: ["Eraxis/Vfend"],
  },
  {
    primary_id: "D0O2OH",
    names: [
      "4-ISOPROPYLPHENSERINE",
      "Cymserine",
      "Cymserinetartaric Acid",
      "145209-39-8",
      "CHEMBL129837",
      "BDBM10960",
      "DTXSID10432696",
      "4-Isopropylphenylcarbamic Acid (3aS)-1,3aalpha,8-Trimethyl-1,2,3,3a,8,8aalpha-Hexahydropyrrolo[2,3-B]indole-5-Yl Ester",
      "(3aS,8aR)-1,3a,8-Trimethyl-1H,2H,3H,3aH,8H,8aH-Pyrrolo[2,3-B]indol-5-Yl",
    ],
  },
  {
    primary_id: "D0O2OU",
    names: ["HIV Vaccines"],
  },
  {
    primary_id: "D0O2PK",
    names: ["PV-802"],
  },
  {
    primary_id: "D0O2PV",
    names: ["Carumonam", "Amasulin (TN)"],
  },
  {
    primary_id: "D0O2QB",
    names: ["Zolimomab Aritox"],
  },
  {
    primary_id: "D0O2RN",
    names: ["N-(4-Methyl-Benzoyl)-N'-Phenethyl-Guanidine", "CHEMBL107354", "SCHEMBL4629436", "BDBM50107044"],
  },
  {
    primary_id: "D0O2RX",
    names: ["PMID23517011C9"],
  },
  {
    primary_id: "D0O2SR",
    names: [
      "Nicorandil",
      "Adancor",
      "Dancor",
      "Ikorel",
      "Nicorandilum",
      "Sigmart",
      "Aventis Brand Of Nicorandil",
      "Aventis Pharma Brand Of Nicorandil",
      "Merck Brand Of Nicorandil",
      "Merck Lipha Sante Brand Of Nicorandil",
      "Nicorandil Aventis Brand",
      "Nicorandil Merck Brand",
      "Rhone Poulenc Rorer Brand Of Nicorandil",
      "SG 75",
      "SG75",
      "Ikorel (TN)",
      "Nicorandilum [INN-Latin]",
      "RP-46417",
      "Rhone-Poulenc Rorer Brand Of Nicorandil",
      "SG-75",
      "Sigmart (TN)",
      "Nitrate, 2-Nicotinamidethyl",
      "Nitrate, 2-Nicotinamidoethyl",
      "N-(2-Hydroxyethyl)Nicotinamide Nitrate",
      "Nicorandil (JP15/USAN/INN)",
      "Nicorandil [USAN:BAN:INN:JAN]",
      "N-(2-Hydroxyethyl)Nicotinamide Nitrate (Ester)",
      "N-[2-(Nitrooxy)Ethyl]pyridine-3-Carboxamide",
      "2 Nicotinamidethyl Nitrate",
      "2 Nicotinamidoethyl Nitrate",
      "2-(Nicotinamido)Ethyl Nitrat",
      "2-(Pyridine-3-Carboxamido)Ethyl Nitrate",
      "2-(Pyridine-3-Carbonylamino)Ethyl Nitrate",
      "2-Nicotinamidethyl Nitrate",
      "2-Nicotinamidoethyl Nitrate",
      "2-[(Pyridin-3-Ylcarbonyl)Amino]ethylnitrat",
    ],
  },
  {
    primary_id: "D0O2TC",
    names: ["Iberiotoxin"],
  },
  {
    primary_id: "D0O2TG",
    names: ["LCA-AAV7"],
  },
  {
    primary_id: "D0O2TY",
    names: ["PMID27215781-Compound-13"],
  },
  {
    primary_id: "D0O2UF",
    names: ["Griffithsin Vaginal Gel"],
  },
  {
    primary_id: "D0O2UK",
    names: ["N-(Benzyl),N-(Pyrrol-2-Ylmethyl)Amine"],
  },
  {
    primary_id: "D0O2US",
    names: ["GW7845"],
  },
  {
    primary_id: "D0O2VM",
    names: ["Small Molecule 32"],
  },
  {
    primary_id: "D0O2VZ",
    names: ["Otelixizumab"],
  },
  {
    primary_id: "D0O2WB",
    names: ["Pyridostigmine"],
  },
  {
    primary_id: "D0O2WW",
    names: ["PMID27019002-Compound-20b"],
  },
  {
    primary_id: "D0O2WX",
    names: ["AFP-07", "AFP07", "AFP 07"],
  },
  {
    primary_id: "D0O2XI",
    names: ["Enterotoxigenic E Coli Vaccine"],
  },
  {
    primary_id: "D0O2XJ",
    names: [
      "NXN-188",
      "6634-56-6",
      "4,4-Bis[4-Hydroxy-3-(Morpholin-4-Ylmethyl)Phenyl]pentanoic Acid",
      "NSC51920",
      "AC1Q5VTF",
      "AC1L6AFP",
      "CTK5C4317",
      "ZINC4721635",
      "NSC-51920",
      "AKOS030583716",
      "KB-274877",
    ],
  },
  {
    primary_id: "D0O2XK",
    names: ["Mesothelin-Targeted T Cells"],
  },
  {
    primary_id: "D0O2XQ",
    names: [
      "AMD-070",
      "AMD 070",
      "AMD070",
      "AMD11070",
      "S14-0353",
      "N-(1H-Benzoimidazol-2-Ylmethyl)-N-(5,6,7,8-Tetrahydroquinolin-8-Yl)Butane-1,4-Diamine",
      "N'-(1H-Benzo[d]imidazol-2-Ylmethyl)-N'-(5,6,7,8-Tetrahydroquinolin-8-Yl)Butane-1,4-Diamine Trihydrobromide Dihydrate",
    ],
  },
  {
    primary_id: "D0O2XW",
    names: ["VT-111a"],
  },
  {
    primary_id: "D0O2YE",
    names: ["HTF 919"],
  },
  {
    primary_id: "D0O2YF",
    names: ["KNI-10315"],
  },
  {
    primary_id: "D0O2ZI",
    names: [
      "90Y-Labeled Humanized Anti-Tac Mab (Anticancer), NCI",
      "90Y-HAT",
      "90Y-Labeled Humanized Anti-Tac Monoclonal Antobody, National Cancer Institute",
    ],
  },
  {
    primary_id: "D0O2ZR",
    names: ["ISIS 138612"],
  },
  {
    primary_id: "D0O3AB",
    names: [
      "Butethal",
      "Aethylbutylbarbitursaeure",
      "Budorm",
      "Butabarbitol",
      "Butobarbital",
      "Butobarbitalum",
      "Butobarbitone",
      "Butobarbitural",
      "Etoval",
      "Hyperbutal",
      "Longanoct",
      "Meonal",
      "Monodorm",
      "Neonal",
      "Sonerile",
      "Soneryl",
      "Butobarbital [BAN]",
      "Butobarbital (BAN)",
      "Neonal (TN)",
      "Butyl,5-Ethylbarbituric Acid",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5-Butyl-5-Ethyl-(9CI)",
      "5-BUTYL-5-ETHYLBARBITURIC ACID",
      "5-Butyl-5-Ethyl-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Ethyl-5-Butylbarbituric Acid",
      "5-Ethyl-5-N-Butylbarbituric Acid",
      "5-Butyl-5-Ethyl-1,3-Diazinane-2,4,6-Trione",
    ],
  },
  {
    primary_id: "D0O3AJ",
    names: ["SAGE-324"],
  },
  {
    primary_id: "D0O3BL",
    names: ["RS-14491"],
  },
  {
    primary_id: "D0O3BR",
    names: ["Diarylamine And Arylheteroarylamine Pyrazole Derivative 3"],
  },
  {
    primary_id: "D0O3CV",
    names: [
      "Ridaforolimus",
      "Deforolimus",
      "AP 23573",
      "MK 8669",
      "AP-23573",
      "MK-8669",
      "AP23573, MK-8669, Ridaforolimus, Deforolimus",
    ],
  },
  {
    primary_id: "D0O3CX",
    names: [
      "BMS-906024",
      "1401066-79-2",
      "UNII-DRL23N424R",
      "BMS 906024",
      "DRL23N424R",
      "(2R,3S)-N1-((S)-1-Methyl-2-Oxo-5-Phenyl-2,3-Dihydro-1H-Benzo[e][1,4]diazepin-3-Yl)-2,3-Bis(3,3,3-Trifluoropropyl)Succinamide",
      "SCHEMBL12543868",
      "DTXSID30161234",
      "CHEBI:131164",
      "ZINC100285156",
      "SB16893",
      "DB12006",
      "KB-145938",
      "Z-3229",
      "BMS-906024, &gt",
      "Butanediamide, N1-((3S)-2,3-Dihydro-1-Methyl-2-Oxo-5-Phenyl-1H-1,4-Benzodiazepin-3-Yl)-2,3-Bis(3,3,3-Trifluorophenyl)-, (2R,3S)-",
    ],
  },
  {
    primary_id: "D0O3DI",
    names: ["PELARGONIDIN CHLORIDE"],
  },
  {
    primary_id: "D0O3DK",
    names: ["GTP-010"],
  },
  {
    primary_id: "D0O3DW",
    names: ["Meningococcal Meningitis Polysaccharide Serogroups A,C,Y And W-135 Vaccine"],
  },
  {
    primary_id: "D0O3DZ",
    names: ["ISIS 17148"],
  },
  {
    primary_id: "D0O3EP",
    names: ["SRT2379"],
  },
  {
    primary_id: "D0O3EX",
    names: ["LIQ861"],
  },
  {
    primary_id: "D0O3FC",
    names: [
      "AMG-5445",
      "CHEMBL1086586",
      "4-Methoxy-N-[2,2,2-Trichloro-1-(4-Chloro-Phenylsulfanyl)-Ethyl]-Benzamide",
      "BAS 00092441",
      "AC1MD3F3",
      "CBDivE_009656",
      "SCHEMBL15159500",
      "MolPort-001-914-251",
      "BDBM50318456",
      "AKOS016361849",
      "AKOS000521797",
      "MCULE-5981280288",
      "ST50216415",
      "4-Methoxy-N-(2,2,2-Trichloro-1-(4-Chlorophenylthio)Ethyl)Benzamide",
      "4-Methoxy-N-[2,2,2-Trichloro-1-(4-Chlorophenyl)Sulfanylethyl]benzamide",
      "4-Methoxy-N-(2,2,2-Trichloro-1-((4-Chlorophenyl)Sulfanyl)Ethyl)Benzamide",
    ],
  },
  {
    primary_id: "D0O3FG",
    names: ["Desvenlafaxine"],
  },
  {
    primary_id: "D0O3FS",
    names: ["OBR-5-340"],
  },
  {
    primary_id: "D0O3FX",
    names: ["NU1025"],
  },
  {
    primary_id: "D0O3GF",
    names: [
      "[3H]8-OH-DPAT",
      "8-OH-Dpat",
      "78950-78-4",
      "8-HYDROXY-2-(DI-N-PROPYLAMINO)TETRALIN",
      "7-(Dipropylamino)-5,6,7,8-Tetrahydronaphthalen-1-Ol",
      "8-Hydroxy-DPAT",
      "CHEMBL56",
      "Ro-31-8220 In Solution",
      "CHEBI:73364",
      "1-Naphthalenol, 7-(Dipropylamino)-5,6,7,8-Tetrahydro-",
      "S(-)-8-HYDROXY-DPAT HYDROBROMIDE PARTIAL  5-HT1A SECROT",
      "(+/-)-8-HYDROXY-2-DIPROPYL-AMINOTETRALIN",
      "[3H]-8-OH-DPAT",
      "(+/-)-8-Hydroxy-DPAT Hydrobromide",
      "(+-)-8-OH-DPAT",
      "S(-)-8-OH-DPAT HBr",
      "S(-)-8-Hydroxy-DPAT Hydrobromide",
      "Dl-8-Hydroxy-2-(Dipropylamino)Tetralin",
      "GTPL7",
      "8OH-DPAT",
    ],
  },
  {
    primary_id: "D0O3GQ",
    names: [
      "2-(3-Phenylthio)Phenyl)-1-Aminoethane",
      "CHEMBL211688",
      "2-(3-(Phenylthio)Phenyl)Ethanamine",
      "BDBM50193470",
    ],
  },
  {
    primary_id: "D0O3GR",
    names: [
      "4-[3-(4-Methoxy-Phenoxy)-Propyl]-1H-Imidazole",
      "CHEMBL369714",
      "SCHEMBL7987297",
      "DEZXFTGWFMDRDA-UHFFFAOYSA-N",
      "BDBM50092844",
      "4-[3-(4-Methoxyphenoxy)Propyl]-1H-Imidazole",
      "3-(1H-Imidazol-4-Yl)Propyl 4-Methoxyphenyl Ether",
      "Compound With But-2-Enedioic Acid(0.25M H2O)",
    ],
  },
  {
    primary_id: "D0O3IE",
    names: [
      "5-Phenyl-1H-1,2,3-Triazole",
      "1680-44-0",
      "4-Phenyl-1H-1,2,3-Triazole",
      "4-Phenyl-1H-[1,2,3]triazole",
      "4-Phenyl-1,2,3-Triazole",
      "1H-1,2,3-Triazole, 4-Phenyl-",
      "4-Phenyl-2H-Triazole",
      "V-Triazole, 4-Phenyl-",
      "4-Phenyl-2H-[1,2,3]triazole",
      "LUEYUHCBBXWTQT-UHFFFAOYSA-N",
      "4-Phenyl-1h-1,2,3,-Triazole",
      "5-Phenyl-1H-[1,2,3]triazole",
      "112614-96-7",
      "Phenyltriazole",
      "4-Phenyltriazole",
      "NSC83125",
      "SCHEMBL9868",
      "AC1L3A0F",
      "5-Phenyl-1,2,3-Triazole",
      "SCHEMBL8221447",
      "CHEMBL193664",
      "1,2,3-Triazole Analogue, 4",
      "1,2,3-Triazole, 4-Phenyl-",
      "ZINC27320",
      "BDBM17448",
      "CTK4D2851",
    ],
  },
  {
    primary_id: "D0O3IT",
    names: ["L-NMMA"],
  },
  {
    primary_id: "D0O3JR",
    names: ["P-Coumaric Acid Derivative 1"],
  },
  {
    primary_id: "D0O3KA",
    names: ["RG7602"],
  },
  {
    primary_id: "D0O3KN",
    names: ["PMID28454500-Compound-59"],
  },
  {
    primary_id: "D0O3LC",
    names: ["AD-519", "AIDS-519", "G3.519"],
  },
  {
    primary_id: "D0O3NG",
    names: [
      "Dimaprit",
      "65119-89-3",
      "UNII-ZZQ699148P",
      "BRN 2350552",
      "CHEMBL12344",
      "3-(Dimethylamino)Propyl Imidothiocarbamate",
      "3-(Dimethylamino)Propyl Carbamimidothioate",
      "CARBAMIMIDOTHIOIC ACID, 3-(DIMETHYLAMINO)PROPYL ESTER",
      "CHEBI:81389",
      "ZZQ699148P",
      "Pseudourea, 2-(3-(Dimethylamino)Propyl)-2-Thio-",
      "[3-(Carbamimidoylsulfanyl)Propyl]dimethylamine",
      "Carbamimidothioicacid, 3-(Dimethylamino)Propyl Ester",
      "C6H15N3S",
      "AE-641/25069014",
      "Tocris-0506",
      "AC1Q1UAQ",
      "Prestwick0_000983",
      "Prestwick3_000983",
      "Prestwick2_000983",
      "Prestwick1_000983",
      "AC1L1F4E",
      "BSPBio_001045",
    ],
  },
  {
    primary_id: "D0O3NY",
    names: [
      "AM-1241",
      "444912-48-5",
      "AM1241",
      "(2-Iodo-5-Nitrophenyl)(1-((1-Methylpiperidin-2-Yl)Methyl)-1H-Indol-3-Yl)Methanone",
      "ZUHIXXCLLBMBDW-UHFFFAOYSA-N",
      "AM 1241",
      "GTPL3316",
      "SCHEMBL2030690",
      "CHEMBL408430",
      "CTK8B9184",
      "BDBM21283",
      "MolPort-009-019-655",
      "HMS3651A13",
      "HMS3650F09",
      "MFCD11045986",
      "(R,S)-AM1241",
      "(1-(Methylpiperidin-2-Ylmethyl)-3-(2-Iodo-5-Nitrobenzoyl)Indole)",
      "ANW-62168",
      "S1544",
      "AKOS016004972",
      "SB19545",
      "CCG-208738",
      "NCGC00165726-04",
      "NCGC00165726-01",
      "HY-18640",
      "TC-150913",
      "LS-192021",
      "KB-206133",
      "AX8233934",
      "SW219858-1",
      "CS-0011638",
      "ST24033932",
      "Z-3231",
    ],
  },
  {
    primary_id: "D0O3OI",
    names: ["AVL-3288"],
  },
  {
    primary_id: "D0O3OK",
    names: ["Motavizumab"],
  },
  {
    primary_id: "D0O3OS",
    names: [
      "SR141716A",
      "168273-06-1",
      "Zimulti",
      "UNII-RML78EN3XE",
      "RML78EN3XE",
      "CHEMBL111",
      "Rimonabant (SR141716)",
      "CHEBI:34967",
      "JZCPYUJPEARBJL-UHFFFAOYSA-N",
      "1H-Pyrazole-3-Carboxamide, 5-(4-Chlorophenyl)-1-(2,4-Dichlorophenyl)-4-Methyl-N-1-Piperidinyl-",
      "NCGC00164572-01",
      "Rimonbant",
    ],
  },
  {
    primary_id: "D0O3PE",
    names: ["RO 116 1148"],
  },
  {
    primary_id: "D0O3PP",
    names: ["ARGENT", "ARGENT (Prostate Cancer Therapy)"],
  },
  {
    primary_id: "D0O3PW",
    names: ["CX-2009"],
  },
  {
    primary_id: "D0O3QI",
    names: [
      "Benzo[1,3]dioxol-5-Ylmethyl-Quinazolin-4-Yl-Amine",
      "CHEMBL66732",
      "SCHEMBL5137740",
      "MolPort-007-049-029",
      "ZINC12360740",
      "BDBM50038983",
      "AKOS002366252",
      "MCULE-7168990543",
    ],
  },
  {
    primary_id: "D0O3QJ",
    names: ["PMID19954973C4"],
  },
  {
    primary_id: "D0O3QM",
    names: ["ISIS 188757"],
  },
  {
    primary_id: "D0O3QW",
    names: ["Trodusquemine"],
  },
  {
    primary_id: "D0O3SA",
    names: [
      "ATL-801",
      "BWA-1433",
      "Adenosine A2b Receptor Antagonists (Coronary Artery Occlusion), Pennsylvania State University;Adenosine A2b Receptor Antagonists (Diabetes), Pennsylvania State University",
    ],
  },
  {
    primary_id: "D0O3SJ",
    names: ["FGGFTGARKSARKLYNQ"],
  },
  {
    primary_id: "D0O3TB",
    names: ["Steroid Mimics"],
  },
  {
    primary_id: "D0O3TC",
    names: ["FK-962"],
  },
  {
    primary_id: "D0O3TE",
    names: ["NRG-101"],
  },
  {
    primary_id: "D0O3TG",
    names: ["PMID25666693-Compound-136"],
  },
  {
    primary_id: "D0O3TT",
    names: [
      "MF-152",
      "916888-66-9",
      "2-(4-(2-(Trifluoromethyl)Benzoyl)Piperazin-1-Yl)Thiazole-5-Carboxamide",
      "CHEMBL576820",
      "SCHEMBL3467167",
      "KS-00000RND",
      "DTXSID60474965",
      "MolPort-035-675-701",
      "ZINC45288748",
      "BDBM50301057",
      "AKOS022171752",
      "2-[4-[2-(Trifluoromethyl)Benzoyl]piperazin-1-Yl]-1,3-Thiazole-5-Carboxamide",
      "DS-5323",
      "ACN-025269",
      "BC261397",
      "AK136241",
      "SC-94064",
      "KB-222046",
      "AJ-110396",
    ],
  },
  {
    primary_id: "D0O3TW",
    names: ["PT4.0", "Antibacterial (SASPject, Gram Negative Bacteria), Phico"],
  },
  {
    primary_id: "D0O3VF",
    names: [
      "2-Morpholin-4-Yl-8-Thiophen-2-Ylchromen-4-One",
      "CHEMBL362729",
      "503468-86-8",
      "2-Morpholin-4-Yl-8-Thiophen-2-Yl-Chromen-4-One",
      "CTK1G6919",
      "DTXSID30469303",
      "BDBM50156496",
      "AKOS030552940",
      "4H-1-Benzopyran-4-One, 2-(4-Morpholinyl)-8-(2-Thienyl)-",
    ],
  },
  {
    primary_id: "D0O3VH",
    names: ["CDKN2C"],
  },
  {
    primary_id: "D0O3VK",
    names: [
      "CEP-37249",
      "ART-150",
      "DMF-10",
      "EGX-150",
      "DMF-10 62.3",
      "DMF-62.3",
      "Ganglioside-Targeted Humanized Hamster Antibody (Cancer), Arana",
      "Ganglioside-Targeted Humanized Hamster Antibody (Cancer), Cephalon",
      "Ganglioside-Targeted Humanized Hamster Antibody (Cancer), Peptech",
      "Ganglioside-Targeted Humanized Hamster Antibody (Cancer), Evogenix/UMMS",
      "Ganglioside-Targeted Humanized Hamster Antibody (Cancer), Evogenix/University Of Massachusetts",
    ],
  },
  {
    primary_id: "D0O3VP",
    names: ["BMS-914392"],
  },
  {
    primary_id: "D0O3VV",
    names: ["L-Arginine", "Argamine", "Argivene", "Detoxargin", "Levargin", "Minophagen A", "R-Gene 10"],
  },
  {
    primary_id: "D0O3WF",
    names: ["ISIS 103386"],
  },
  {
    primary_id: "D0O3XS",
    names: ["GSK311739"],
  },
  {
    primary_id: "D0O3XU",
    names: ["HO/03/03"],
  },
  {
    primary_id: "D0O3XZ",
    names: [
      "2-Amino-4-Methylpyridine",
      "4-METHYLPYRIDIN-2-AMINE",
      "695-34-1",
      "2-Amino-4-Picoline",
      "Ascensil",
      "2-Pyridinamine, 4-Methyl-",
      "4-Methyl-2-Pyridinamine",
      "4-Methyl-2-Aminopyridine",
      "4-Methyl-2-Pyridylamine",
      "W 45 Raschig",
      "4-PICOLINE, 2-AMINO-",
      "Methyl-4 Amino-2-Pyridine",
      "4M2AP",
      "Pyridine, 2-Amino-4-Methyl-",
      "4-Methylpyridin-2-Ylamine",
      "2-Amino-4-Methyl Pyridine",
      "RA 1226",
      "Alpha-Amino-Gamma-Picoline",
      "NSC 6972",
      "NSC 1490",
      "VMI 20-4",
      "UNII-394N1Z644H",
      "EINECS 211-780-9",
      "4-Methyl-Pyridin-2-Ylamine",
      "BRN 0107066",
      "W 45",
      "CHEMBL40833",
      "AI3-23984",
      "C6H8N2",
      "4-Methylpyridin-2-Amine",
    ],
  },
  {
    primary_id: "D0O3YA",
    names: ["OPRX"],
  },
  {
    primary_id: "D0O3YF",
    names: [
      "Cyclosporine",
      "CSA",
      "Ciclosporin",
      "Consupren",
      "Cyclokat",
      "Cyclophorine",
      "Cyclosporin",
      "Equoral",
      "Gengraf",
      "Mitogard",
      "Neoplanta",
      "Neoral",
      "NeuroSTAT",
      "Papilock",
      "Pulminiq",
      "Restasis",
      "Sandimmun",
      "Sandimmune",
      "SangCyA",
      "Sigmasporin",
      "Vekacia",
      "Zyclorin",
      "Cipol N",
      "Consupren S",
      "Cyclosporin A",
      "Cyclosporin A Implant",
      "Cyclosporine A",
      "Cyclosporine [USAN]",
      "RamihyphinA",
      "Sandimmun Neoral",
      "Sigmasporin Microoral",
      "Antibiotic S 7481F1",
      "C 3662",
      "Cicloral (TN)",
      "Ciclosporin (JP15)",
      "Cipol-N",
      "Cyclosporin A, Tolypocladium Inflatum",
      "Cyclosporine (USP)",
      "DE-076",
      "From Tolypocladium Inflatum (Trichoderma Polysporin)",
      "Gengraf (TN)",
      "Modusik-A",
      "Neoral (TN)",
      "Nova-22007",
      "OL 27-400",
      "OL-27400",
      "OLO-400",
      "Restasis (TN)",
      "S-Neoral",
      "SDZ-OXL 400",
      "ST-603",
      "Sandimmune (TN)",
      "Sang-2000",
      "Sang-35",
      "TRANSGENIC MODEL EVALUATION (CYCLOSPORIN A)",
      "CB-01-09 MMX",
      "CYCLOSPORIN A (SEE ALSO TRANSGENIC MODEL EVALUATION (CYCLOSPORIN A))",
      "CsA & IFN-Alpha",
      "Cyclosporin A & IFN-Alpha",
      "GNF-Pf-2808",
      "Helv Chim Acta 60: 1568 (1977)",
      "Sandimmune, Gengraf, Restasis, Atopica, Sangcya, Cyclosporine",
      "BMT-ABA-SAR-MLE-VAL-MLE-ALA-ALA-MLE-MLE-MVA",
      "BMT-ABA-SAR-MLE-VAL-MLE-ALA-DAL-MLE-MLE-MVA",
      "CsA",
    ],
  },
  {
    primary_id: "D0O3ZI",
    names: ["OpsoVac", "Adjuvant Vaccine (Infectious Disease/Cancer), Opsona/CSL"],
  },
  {
    primary_id: "D0O4BC",
    names: ["3-(2-Benzylamino-Ethoxy)-Phenol", "CHEMBL64508", "3-[2-(Benzylamino)Ethoxy]phenol"],
  },
  {
    primary_id: "D0O4BU",
    names: ["S-Benzo[d]oxazol-2-Yl O-Butyl Carbonothioate"],
  },
  {
    primary_id: "D0O4CH",
    names: ["CLR1404-I-131"],
  },
  {
    primary_id: "D0O4CV",
    names: ["Dihydroergotamine Nasal"],
  },
  {
    primary_id: "D0O4DE",
    names: ["Undecaprenyl Pyrophosphate Synthase Inhibitors"],
  },
  {
    primary_id: "D0O4EU",
    names: [
      "Acrivastine",
      "Acrivastin",
      "Acrivastina",
      "Acrivastinum",
      "Semprex",
      "Acrivastina [Spanish]",
      "Acrivastinum [Latin]",
      "Benadryl Allergy Relief",
      "BW 0270C",
      "BW 825C",
      "BW A825C",
      "BW-825C",
      "Acrivastine (USAN/INN)",
      "Acrivastine [USAN:INN:BAN]",
      "E-(9CI)",
      "(2E)-3-{6-[(1E)-1-(4-Methylphenyl)-3-Pyrrolidin-1-Ylprop-1-En-1-Yl]pyridin-2-Yl}prop-2-Enoic Acid",
      "(E)-3-[6-[(E)-1-(4-Methylphenyl)-3-Pyrrolidin-1-Ylprop-1-Enyl]pyridin-2-Yl]prop-2-Enoic Acid",
      "(E)-6-((E)-3-(1-Pyrrolidinyl)-1-P-Tolylpropenyl)-2-Pyridineacrylic Acid",
      "(E)-6-((E)-3-(1-Pyrrolidinyl-1-P-Tolylpropenyl)-2-Pyridinacrylsaeure",
      "(E,E)-3-[6-[1-(4-Methylphenyl)-3-(1-Pyrrolidinyl)-1-Propenyl]-2-Pyridinyl]-2-Propenoic Acid",
      "3-{6-[1-(4-Methylphenyl)-3-(Pyrrolidin-1-Yl)Prop-1-En-1-Yl]pyridin-2-Yl}prop-2-Enoic Acid",
    ],
  },
  {
    primary_id: "D0O4EV",
    names: ["ADI", "Arginine Deiminase"],
  },
  {
    primary_id: "D0O4FF",
    names: ["Debrase", "Bromelain Topical"],
  },
  {
    primary_id: "D0O4GU",
    names: ["H-D-Phe-Cys-Tyr-D-Trp-Lys-Val-Cys-Thr-NH2", "CHEMBL262017"],
  },
  {
    primary_id: "D0O4GY",
    names: ["Aminolevulinic Acid Hydrochloride"],
  },
  {
    primary_id: "D0O4HL",
    names: ["ISIS 20577"],
  },
  {
    primary_id: "D0O4IM",
    names: [
      "(R)-4-Amino-2-Methylbutanoic Acid",
      "CHEMBL257443",
      "96192-37-9",
      "(2R)-4-Amino-2-Methylbutanoic Acid",
      "Butanoic Acid, 4-Amino-2-Methyl-, (2R)-",
      "AC1LIBFM",
      "SCHEMBL14518787",
      "CTK3G8616",
      "ZINC518267",
      "(R)-2-Methyl-4-Aminobutyric Acid",
      "BDBM50374650",
      "AKOS006341386",
    ],
  },
  {
    primary_id: "D0O4IN",
    names: [
      "MOR-202",
      "Anti-CD38 Antibodies, MorphoSys",
      "HuCAL-MAb1, MorphoSys",
      "HuCAL-MAb2, MorphoSys",
      "HuCAL-MAb3, MorphoSys",
    ],
  },
  {
    primary_id: "D0O4IO",
    names: ["Ztz240"],
  },
  {
    primary_id: "D0O4IV",
    names: [
      "2-Fluoro-6-(3-Phenoxy-Benzoylamino)-Benzoic Acid",
      "CHEMBL426645",
      "BDBM23611",
      "3-Phenoxybenzoylamino Deriv., 28e",
      "2-Fluoro-6-(3-Phenoxybenzoylamino)Benzoic Acid",
      "2-Fluoro-6-[(3-Phenoxybenzene)Amido]benzoic Acid",
    ],
  },
  {
    primary_id: "D0O4JH",
    names: ["ACEA-1328"],
  },
  {
    primary_id: "D0O4JU",
    names: ["(S)-FTY720P", "CHEMBL1213480"],
  },
  {
    primary_id: "D0O4KC",
    names: ["7-Methoxy-4-Morpholin-4-Yl-Chromen-2-One", "CHEMBL177241", "BDBM50159670"],
  },
  {
    primary_id: "D0O4KI",
    names: [
      "2-Ethyl-4-(Thiazol-2-Yl)Thieno[3,2-D]pyrimidine",
      "CHEMBL407650",
      "2-Ethyl-4-(2-Thiazolyl)Thieno[3,2-D]pyrimidine",
      "443148-33-2",
      "SCHEMBL3674131",
    ],
  },
  {
    primary_id: "D0O4KL",
    names: [
      "1-Pentafluorophenylamido-5-Sulfonamidoindane",
      "CHEMBL205867",
      "Indanesulfonamide Derivative 11c",
      "BDBM11034",
    ],
  },
  {
    primary_id: "D0O4KM",
    names: ["Ethynyl Compound 4"],
  },
  {
    primary_id: "D0O4KO",
    names: ["Covalent Drug Therapeutics", "Covalent Drug Therapeutics (Avilomics)"],
  },
  {
    primary_id: "D0O4KT",
    names: ["Bioadhesive Berry Gel Therapy", "Bioadhesive Berry Gel Therapy (Dysplasia)"],
  },
  {
    primary_id: "D0O4KY",
    names: ["Tyr-Pro-Phe-Phe-OCH3", "CHEMBL361922", "Tyr-Pro-Phe-Phe-OMe"],
  },
  {
    primary_id: "D0O4LG",
    names: ["HIV Vaccine"],
  },
  {
    primary_id: "D0O4LO",
    names: [
      "1-(4-CHLOROPHENYL)-2-(2,4-DICHLOROPHENYL)-5-(METHYLSULFINYL)-N-(PIPERIDIN-1-YL)-1H-IMIDAZOLE-4-CARBOXAMIDE (ENANTIOMERIC MIX)",
      "SCHEMBL675964",
      "CHEMBL1088223",
      "BDBM50378776",
    ],
  },
  {
    primary_id: "D0O4LU",
    names: ["SK-896"],
  },
  {
    primary_id: "D0O4MI",
    names: ["ISIS 138648"],
  },
  {
    primary_id: "D0O4MK",
    names: [
      "3-Chloro-9H-Beta-Carboline",
      "3-Chloro-9H-Pyrido[3,4-B]indole",
      "CHEMBL298561",
      "91985-80-7",
      "3-Chloro-B-Carboline",
      "3-Chloro-Beta-Carboline",
      "AC1O2G3D",
      "CHEMBL496742",
      "SCHEMBL10642293",
      "BDBM50013805",
      "9H-Pyrido[3,4-B]indole, 3-Chloro-",
    ],
  },
  {
    primary_id: "D0O4ML",
    names: ["Indandione Derivative 3"],
  },
  {
    primary_id: "D0O4MW",
    names: ["NV.BC.04"],
  },
  {
    primary_id: "D0O4NJ",
    names: ["Z-100", "Ancer", "SSM", "Specific Substance Of Maruyama", "Ancer 20"],
  },
  {
    primary_id: "D0O4NR",
    names: ["LTB4"],
  },
  {
    primary_id: "D0O4QB",
    names: ["EUGENOL"],
  },
  {
    primary_id: "D0O4QR",
    names: ["Cefodizime Sodium"],
  },
  {
    primary_id: "D0O4QS",
    names: ["WX-554"],
  },
  {
    primary_id: "D0O4QX",
    names: [
      "2-Ethoxy-5-(M-Tolylethynyl)Pyrimidine",
      "CHEMBL496874",
      "Pyrimidine,2-Ethoxy-5-[2-(3-Methylphenyl)Ethynyl]-",
      "BDBM50278923",
    ],
  },
  {
    primary_id: "D0O4RB",
    names: [
      "DdRNAi Therapy RHIV7-Shl-TAR-CCR5RZ, Stem Cells",
      "BLT-HIV",
      "Lentiviral Vector Delivered DNA-Directed RNAi Therapeutic (HIV Infection), Benitec",
      "RHIV7-Shl-TAR-CCR5RZ",
      "T-Cell Therapy (HIV Infection), Benitec",
      "Lentiviral Vector Delivered DNA-Directed RNAi Therapeutic (HIV-Associated Lymphoma), Benitec",
      "DdRNAi Therapy (RHIV7-Shl-TAR-CCR5RZ, T-Cells, HIV), Benitec",
      "DdRNAi Therapy (RHIV7-Shl-TAR-CCR5RZ, Stem Cells, HIV/Lymphoma), Benitec",
    ],
  },
  {
    primary_id: "D0O4RG",
    names: ["ADE-HYPT", "Antihypertensive, Adetherapeutics"],
  },
  {
    primary_id: "D0O4RN",
    names: ["Sulfonylated Piperazine Derivative 5"],
  },
  {
    primary_id: "D0O4RQ",
    names: ["Focetria A(H1N1)"],
  },
  {
    primary_id: "D0O4SE",
    names: ["Tindamax"],
  },
  {
    primary_id: "D0O4SH",
    names: ["SAR-156597"],
  },
  {
    primary_id: "D0O4SZ",
    names: ["EDP-09"],
  },
  {
    primary_id: "D0O4TA",
    names: ["Pyridine Derivative 22"],
  },
  {
    primary_id: "D0O4TC",
    names: ["PMID26651364-Compound-10b"],
  },
  {
    primary_id: "D0O4TF",
    names: ["MB-11262"],
  },
  {
    primary_id: "D0O4TL",
    names: [
      "Cephalosporin C",
      "7-(5-Amino-5-Carboxyvaleramido)Cephalosporanic Acid",
      "61-24-5",
      "UNII-3XIY7HJT5L",
      "CHEBI:15776",
      "EINECS 200-501-6",
      "3XIY7HJT5L",
      "BRN 0065348",
      "5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 3-((Acetyloxy)Methyl)-7-((5-Amino-5-Carboxy-1-Oxopentyl)Amino)-8-Oxo-, (6R-(6alpha,7beta(R*)))-",
      "(6R,7R)-3-[(Acetyloxy)Methyl]-7-{[(5R)-5-Amino-5-Carboxypentanoyl]amino}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 7-(5-Amino-5-Carboxyvaleramid",
    ],
  },
  {
    primary_id: "D0O4UQ",
    names: ["BV-6", "BV6"],
  },
  {
    primary_id: "D0O4UZ",
    names: ["Gp41 HIV-1 Vaccine"],
  },
  {
    primary_id: "D0O4VW",
    names: ["TAL-0500018"],
  },
  {
    primary_id: "D0O4WB",
    names: ["OBP-601"],
  },
  {
    primary_id: "D0O4XF",
    names: [
      "1-(3-Benzylquinoxalin-2-Yl)Hydrazine",
      "2-Benzyl-3-Hydrazinoquinoxaline",
      "223929-23-5",
      "2-Benzyl-3-Hydrazinylquinoxaline",
      "CHEMBL379678",
      "AC1LAIMJ",
      "Benzylhydrazinoquinoxaline",
      "SCHEMBL7628856",
      "3-Benzyl-2-Hydrazinoquinoxaline",
      "KS-00001USU",
      "CTK7F1811",
      "3-Benzylquinoxaline-2-Ylhydrazine",
      "DTXSID80333186",
      "MolPort-009-195-340",
      "(3-Benzylquinoxalin-2-Yl)Hydrazine",
      "ZINC6580943",
      "SBB100772",
      "BDBM50179015",
      "7662AD",
      "AKOS005070001",
      "RTR-063098",
      "RP14269",
      "AJ-56391",
      "TR-063098",
      "2R-0102",
    ],
  },
  {
    primary_id: "D0O4XW",
    names: ["ONO-4819"],
  },
  {
    primary_id: "D0O4YE",
    names: ["Anti-CD19"],
  },
  {
    primary_id: "D0O4YS",
    names: [
      "6-Methyl-[1,3]dioxolo[4,5-H]quinolin-8(9H)-One",
      "6-Methyl[1,3]dioxolo[4,5-H]quinolin-8(9h)-One",
      "1137557-67-5",
      "Casimiroin Analogue, 1a",
      "CHEMBL482693",
      "BDBM29212",
    ],
  },
  {
    primary_id: "D0O4YY",
    names: ["Paliperidone Palmitate IM Long Acting Injectable"],
  },
  {
    primary_id: "D0O4ZP",
    names: ["KITE-585"],
  },
  {
    primary_id: "D0O4ZX",
    names: [
      "Encapsulated Cells",
      "CapCell",
      "Encapsulated Cells (CYP2B1 Secreting)",
      "Encapsulated Cells (CYP2B1 Secreting), Bavarian Nordic",
      "Encapsulated Cells/Ifosfamide, Bavarian Nordic",
      "Gene Therapy (Encapsulated Cells/Breast Cancer/Pancreatic Cancer), Bavarian Nordic",
    ],
  },
  {
    primary_id: "D0O5AG",
    names: ["Baloxavir Marboxil"],
  },
  {
    primary_id: "D0O5BC",
    names: ["2-(Benzofuran-2-Yl)-6-Morpholino-4H-Pyran-4-One", "CHEMBL222545"],
  },
  {
    primary_id: "D0O5BW",
    names: ["LY-2624587", "CXCR4 MAb (Cancer), Eli Lilly"],
  },
  {
    primary_id: "D0O5CL",
    names: ["ARO 087"],
  },
  {
    primary_id: "D0O5CR",
    names: [
      "TA1-RTA",
      "CHEMBL8197",
      "8-Methyl-8-Azabicyclo[3.2.1]oct-3-Yl 1H-Indole-3-Carboxylate",
      "89565-68-4",
      "124583-47-7",
      "(8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl) 1H-Indole-3-Carboxylate",
      "1H-Indole-3-Carboxylic Acid, 8-Methyl-8-Azabicyclo[3.2.1]oct-3-Yl Ester",
      "1H-Indole-3-Carboxylic Acid, 8-Methyl-8-Azabicyclo(3.2.1)Oct-3-Yl Ester, Endo-",
      "NCGC00015984-03",
      "ACMC-20mr3z",
      "ACMC-20bn7f",
      "Enamine_001310",
      "Biomol-NT_000158",
      "AC1L1KP5",
      "REGID_For_CID_5595",
      "Oprea1_655173",
      "Lopac0_001253",
      "Oprea1_462281",
      "MLS000055682",
      "SCHEMBL463128",
      "AC1Q625H",
      "SCHEMBL8946882",
    ],
  },
  {
    primary_id: "D0O5CV",
    names: ["MEDI4212"],
  },
  {
    primary_id: "D0O5ER",
    names: ["Catumaxomab"],
  },
  {
    primary_id: "D0O5EZ",
    names: [
      "Epibatidine",
      "( Inverted Exclamation MarkA)-Epibatidine",
      "CHEMBL298517",
      "(1S,2S,4R)-Epibatidine",
      "CMI 477",
      "152378-30-8",
      "148152-66-3",
      "(1S-Exo)-2-(6-Chloro-3-Pyridinyl)-7-Azabicyclo(2.2.1)Heptane",
      "7-Azabicyclo(2.2.1)Heptane, 2-(6-Chloro-3-Pyridinyl)-, (1S-Exo)-",
      "(-)-1-Epidatidine",
      "Tocris-0684",
      "SCHEMBL316977",
      "(1S,2S,4R)-2-(6-Chloropyridin-3-Yl)-7-Azabicyclo[2.2.1]heptane",
      "GTPL5348",
      "CMI 488",
      "ZINC967913",
      "BDBM50143320",
    ],
  },
  {
    primary_id: "D0O5FK",
    names: [
      "2-(4-(Methylamino)Phenyl)Benzo[d]thiazol-6-Ol",
      "566169-93-5",
      "UNII-K6396E6G8G",
      "CHEMBL93124",
      "K6396E6G8G",
      "AK-87409",
      "2-[4'-(Methylamino)Phenyl]-6-Hydroxybenzothiazole",
      "Pittsburgh Compound B Unlabelled",
      "2-(4'-Methylaminophenyl)-6-Hydroxybenzothiazole",
      "SCHEMBL912744",
      "ZQAQXZBSGZUUNL-UHFFFAOYSA-N",
      "MolPort-028-616-384",
      "ZINC3816332",
      "BDBM50129793",
      "AKOS022184240",
      "FC-3012",
      "4-Methylamino-Phenyl)-Benzothiazol-6-Ol",
      "AJ-45711",
      "PIB Authentic (6-OH-BTA-1) Free Base",
      "2-(4-Methylamino-Phenyl)-Benzothiazol-6-Ol",
    ],
  },
  {
    primary_id: "D0O5FY",
    names: ["Meropenem"],
  },
  {
    primary_id: "D0O5GK",
    names: ["R-Phenserine"],
  },
  {
    primary_id: "D0O5HB",
    names: ["ATX-MS3"],
  },
  {
    primary_id: "D0O5HF",
    names: ["Helenalin-2-Methylbutyrate"],
  },
  {
    primary_id: "D0O5IA",
    names: ["Quinoline And Quinazoline Derivative 2"],
  },
  {
    primary_id: "D0O5IN",
    names: ["PMID25416646-Compound-Figure5-A"],
  },
  {
    primary_id: "D0O5JC",
    names: ["GABAZINE"],
  },
  {
    primary_id: "D0O5KG",
    names: [
      "2-(3-Phenoxy-Benzoylamino)-Benzoic Acid",
      "CHEMBL178465",
      "BDBM23579",
      "2-(3-Phenoxybenzamido)Benzoic Acid",
      "2-(3-Phenoxybenzoylamino)Benzoic Acid",
      "2-[(3-Phenoxybenzene)Amido]benzoic Acid",
      "(3-Phenoxybenzoylamino)Benzoic Acid Deriv., 15",
    ],
  },
  {
    primary_id: "D0O5KH",
    names: ["Altrakincept"],
  },
  {
    primary_id: "D0O5KK",
    names: ["7,8,9,10-Tetrahydro-6H-Furo-[2,3-G][3]benzazepine", "CHEMBL261490", "BDBM50375901"],
  },
  {
    primary_id: "D0O5KN",
    names: ["Dog Peptide Allergy Desensitization Agent"],
  },
  {
    primary_id: "D0O5KR",
    names: ["4,6,7-Trimethyl-6H-Benzo[c]chromene-3,8-Diol", "CHEMBL381337", "SCHEMBL6828067"],
  },
  {
    primary_id: "D0O5KY",
    names: ["Recombinant Human Neuregulin-1 Beta"],
  },
  {
    primary_id: "D0O5LA",
    names: ["Progabide"],
  },
  {
    primary_id: "D0O5LL",
    names: ["GT-2342"],
  },
  {
    primary_id: "D0O5LU",
    names: ["Latamoxef"],
  },
  {
    primary_id: "D0O5NK",
    names: [
      "Oseltamivir",
      "Agucort",
      "Oseltamivirum",
      "Tamvir",
      "GS 4104",
      "GS4104",
      "Agucort (TN)",
      "GS-4104",
      "Oseltamivir (INN)",
      "Oseltamivir [INN:BAN]",
      "Ro-640796",
      "Tamiflu (TN)",
      "Tamiflu-Free",
      "Ro-64-0796",
      "Tamiflu (*Phosphate Salt 1:1*)",
      "Ethyl (3R,4R,5S)-4-Acetamido-5-Amino-3-Pentan-3-Yloxycyclohexene-1-Carboxylate",
      "Ethyl (5S,3R,4R)-4-(Acetylamino)-5-Amino-3-(Ethylpropoxy)Cyclohex-1-Enecarboxylate",
      "Ethyl (3R,4R,5S)-4-Acetamido-5-Amino-3-(1-Ethylpropoxy)-1-Cyclohexene-1-Carboxylate",
      "Ethyl (3R,4R,5S)-4-Acetamido-5-Amino-3-(Pentan-3-Yloxy)Cyclohex-1-Ene-1-Carboxylate",
      "(-)-Oseltamivir",
      "(3R,4R,5S)-4-Acetylamino-5-Amino-3-(1-Ethyl-Propoxy)-Cyclohex-1-Enecarboxylic Acid",
      "(3R,4R,5S)-4-Acetamido-5-Amino-3-(Pentan-3-Yloxy)Cyclohex-1-Enecarboxylic Acid",
      "4-Acetylamino-5-Amino-3-(1-Ethyl-Propoxy)-Cyclohex-1-Enecarboxylic Acid",
      "OTV",
    ],
  },
  {
    primary_id: "D0O5OF",
    names: [
      "SDZ-281-240",
      "[1R,9S,12S(1'R,3'S),13R,14S,17R,21S,23S,24R,25S,27R]-17-Ethyl-1,14-Dihydroxy-12-[2-[3-(Hydroxymethyl)Cyclopentyl]-1(E)-Methylvinyl]-23,25-Dimethoxy-13,19,21,27-Tetramethyl-11,28-Dioxa-4-Azatricyclo[22",
    ],
  },
  {
    primary_id: "D0O5OM",
    names: [
      "VL-0395",
      "CHEMBL137814",
      "GTPL886",
      "BDBM50061836",
      "3-{3-[(5-Carbamimidoyl-1H-Indole-2-Carbonyl)-Amino]-Benzoylamino}-3-Phenyl-Propionic Acid",
      "Beta-[[3-[(5-Amidino-1H-Indol-2-Yl)Carbonylamino]benzoyl]amino]benzenepropanoic Acid",
      "3-[[3-[(5-Carbamimidoyl1H-Indole-2-Carbonyl)Amino]benzoyl]amino]-3-Phenylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0O5PQ",
    names: ["[3H]ramosetron"],
  },
  {
    primary_id: "D0O5QL",
    names: ["HT-1067"],
  },
  {
    primary_id: "D0O5QT",
    names: ["PD-0805", "Alpha Synuclein Modulator (Parkinsons Disease), Bioarctic"],
  },
  {
    primary_id: "D0O5RK",
    names: ["Paz-PC", "Azelaoyl PC", "1-Palmitoyl-2-Azelaoyl PC"],
  },
  {
    primary_id: "D0O5SA",
    names: ["Adociasulfate-2", "CHEMBL426141", "Adociasulfate 2"],
  },
  {
    primary_id: "D0O5SC",
    names: ["Eleutherobin", "174545-76-7", "CHEMBL504387", "SCHEMBL12015046"],
  },
  {
    primary_id: "D0O5SZ",
    names: [
      "Homatropine Methylbromide",
      "Arkitropin",
      "Camatropine",
      "Equipin",
      "Esopin",
      "Homapin",
      "Homatromide",
      "Malcotran",
      "Mesopin",
      "Methylhomatropine",
      "Novatrin",
      "Novatrine",
      "Novatropine",
      "Npvatropine",
      "Sethyl",
      "Homatropine Methobromide",
      "Homatropine Methyl Bromide",
      "Homatropini Methylbromidum",
      "Humulon Lead Salt",
      "Methyl Bromide Homatropine",
      "Methylhomatropine Bromide",
      "Methylhomatropinum Bromatum",
      "Metilbromuro De Homatropina",
      "Omatropina Metilbromuro",
      "Omatropina Metilbromuro [DCIT]",
      "Tropinium Methobromide Mandelate",
      "DL-Homatropine Methyl Bromide",
      "DL-Methylbromide",
      "Equipin (TN)",
      "Homapin-10",
      "Homapin-5",
      "Homatropine DL-Methylbromide",
      "Homatropine Methylbromide (USP)",
      "Homatropine Methylbromide [INN:BAN]",
      "Homatropini Methylbromidum [INN-Latin]",
      "Hycodan, Probilagol",
      "Isopto Homatropine (TN)",
      "Methylbromure D'homatropine",
      "Metilbromuro De Homatropina [INN-Spanish]",
      "N-Methylhomatropinium Bromide",
      "Sed-Tems",
      "Methylbromure D'homatropine [INN-French]",
      "HOMATROPINIUM, 8-METHYL-, BROMIDE",
      "[(5S)-8,8-Dimethyl-8-Azoniabicyclo[3.2.1]octan-3-Yl] 2-Hydroxy-2-Phenylacetate Bromide",
      "(8,8-Dimethyl-8-Azoniabicyclo[3.2.1]octan-3-Yl) 2-Hydroxy-2-Phenylacetate Bromide",
      "3-[2-Hydroxy(Phenyl)Acetoxy]-8,8-Dimethyl-8-Azoniabicyclo[3.2.1]octane Bromide",
      "3-Alpha-Hydroxy-8-Methyl-1-Alpha-H,5-Alpha-H-Tropanium Bromide Mandelate",
      "3-{[hydroxy(Phenyl)Acetyl]oxy}-8,8-Dimethyl-8-Azoniabicyclo[3.2.1]octane Bromide",
      "3alpha-Hydroxy-8-Methyl-1alphaH,5alphaH-Tropanium Bromide Mandelate",
      "8-Methylhomatropinium Bromide",
    ],
  },
  {
    primary_id: "D0O5TA",
    names: [
      "5-Chloro-1-(4-Chloro-Benzyl)-1H-Indole-2,3-Dione",
      "5-Chloro-1-(4-Chlorobenzyl)-1H-Indole-2,3-Dione",
      "CHEMBL333067",
      "87423-59-4",
      "AC1M2SUT",
      "Cambridge Id 7142603",
      "SCHEMBL11186018",
      "KS-00001SNW",
      "MolPort-000-407-830",
      "ZINC2813807",
      "BDBM50133622",
      "STK394570",
      "BBL005671",
      "AKOS001662460",
      "MCULE-8294112694",
      "12F-374S",
      "H3079",
      "ST50867327",
      "5-Chloro-1-[(4-Chlorophenyl)Methyl]indole-2,3-Dione",
      "5-Chloro-1-[(4-Chlorophenyl)Methyl]benzo[d]azoline-2,3-Dione",
      "1-(4-Chlorobenzyl)-5-Chloro-2,3-Dihydro-1H-Indole-2,3-Dione",
    ],
  },
  {
    primary_id: "D0O5TC",
    names: ["Quinoxaline Derivative 2"],
  },
  {
    primary_id: "D0O5TK",
    names: ["D[Leu4,Dap8]VP", "CHEMBL373968"],
  },
  {
    primary_id: "D0O5TQ",
    names: ["Pentagastrin"],
  },
  {
    primary_id: "D0O5TR",
    names: ["RBP-7000"],
  },
  {
    primary_id: "D0O5UF",
    names: ["CNX-011"],
  },
  {
    primary_id: "D0O5VA",
    names: ["PGlu-His-Trp-Ser-His-D-Tyr-Leu-Arg-Pro-Gly-NH2", "CHEMBL436874", "BDBM50180786"],
  },
  {
    primary_id: "D0O5VI",
    names: [
      "EGTA",
      "67-42-5",
      "Egtazic Acid",
      "Ethylenebis(Oxyethylenenitrilo)Tetraacetic Acid",
      "Gedta",
      "Ebonta",
      "6,9-Dioxa-3,12-Diazatetradecanedioic Acid, 3,12-Bis(Carboxymethyl)-",
      "1,2-Bis[2-[bis(Carboxymethyl)Amino]ethoxy]ethane",
      "Ethylene Glycol Tetraacetic Acid",
      "H4egta",
      "Egtazic Acid [USAN:INN]",
      "Ethylene Glycol Bis(2-Aminoethyl Ether)-N,N,N',N'-Tetraacetic Acid",
      "NSC 615010",
      "Acide Egtazique [INN-French]",
      "Acido Egtazico [INN-Spanish]",
      "Acidum Egtazicum [INN-Latin]",
      "Ethylene Glycol-Bis(2-Aminoethylether)-N,N,N',N'-Tetraacetic Acid",
      "Ethylene Glyc",
    ],
  },
  {
    primary_id: "D0O5VR",
    names: ["TP-2758"],
  },
  {
    primary_id: "D0O5WD",
    names: ["Lactoferrin"],
  },
  {
    primary_id: "D0O5WP",
    names: ["Docetaxel"],
  },
  {
    primary_id: "D0O5WV",
    names: ["Ac-HSDAVFTDQYTRLRKQVAAKKYLQSIKQKRYC"],
  },
  {
    primary_id: "D0O5XF",
    names: ["CeaVac"],
  },
  {
    primary_id: "D0O5XJ",
    names: ["XL652/XL014"],
  },
  {
    primary_id: "D0O5XQ",
    names: ["AL-60371"],
  },
  {
    primary_id: "D0O5YE",
    names: [
      "2-(3-Isobutyl-Phenyl)-Propionic Acid",
      "66622-47-7",
      "2-(3-Isobutylphenyl)Propanoic Acid",
      "M-Isobutyl Ibuprofen",
      "2-[3-(2-Methylpropyl)Phenyl]propanoic Acid",
      "2-(3-Isobutylphenyl)Propionic Acid",
      "(2RS)-2-(3-(2-Methylpropyl)Phenyl)Propanoic Acid",
      "(2RS)-2-[3-(2-Methylpropyl)Phenyl]propanoic Acid",
      "Ibuprofen Specified Impurity A [EP]",
      "Ibuprofen Impurity A",
      "Alpha-(3-Isobutylphenyl)Propionic Acid",
      "SCHEMBL66534",
      "Alpha-Methyl-3-(2-Methylpropyl)Phenylacetic Acid",
      "CHEMBL187556",
      "Benzeneacetic Acid, Alpha-Methyl-3-(2-Methylpropyl)-",
      "CTK5C4963",
      "MolPort-029-294-062",
      "AKOS023890981",
    ],
  },
  {
    primary_id: "D0O5YZ",
    names: ["Mepindolol"],
  },
  {
    primary_id: "D0O5ZB",
    names: ["Ilodecakin"],
  },
  {
    primary_id: "D0O5ZG",
    names: ["1-Methoxy-6-Phenyl-6H-Benzo[c]chromene", "CHEMBL63422"],
  },
  {
    primary_id: "D0O5ZK",
    names: ["PMID28454500-Compound-96"],
  },
  {
    primary_id: "D0O5ZT",
    names: ["Organon-2"],
  },
  {
    primary_id: "D0O5ZY",
    names: ["MRS-2179"],
  },
  {
    primary_id: "D0O6AH",
    names: ["Trimethyl-(4-Oxo-Pentyl)-Ammonium Iodide"],
  },
  {
    primary_id: "D0O6AP",
    names: ["Topical Anticholinergics"],
  },
  {
    primary_id: "D0O6AQ",
    names: ["Gavestinel"],
  },
  {
    primary_id: "D0O6BD",
    names: [
      "VU0090157",
      "Cyclopentyl 1,6-Dimethyl-4-(6-Nitrobenzo[d][1,3]-Dioxol-5-Yl)-2-Oxo-1,2,3,4-Tetrahydropyrimidine-5-Carboxylate",
    ],
  },
  {
    primary_id: "D0O6BJ",
    names: ["MP40X"],
  },
  {
    primary_id: "D0O6BW",
    names: ["Encepur"],
  },
  {
    primary_id: "D0O6DC",
    names: ["LY-2463665"],
  },
  {
    primary_id: "D0O6DE",
    names: ["RG-101"],
  },
  {
    primary_id: "D0O6DN",
    names: ["C[-Arg-Gly-Asp-Acpca34-]", "CHEMBL534933"],
  },
  {
    primary_id: "D0O6EQ",
    names: ["MRNA-1388"],
  },
  {
    primary_id: "D0O6FX",
    names: ["4,6,7,10-Tetramethyl-6H-Benzo[c]chromene-3,8-Diol", "CHEMBL206584", "SCHEMBL6830345"],
  },
  {
    primary_id: "D0O6GC",
    names: [
      "Methysergide",
      "Deseril",
      "Desernil",
      "Desernyl",
      "Deseryl",
      "Desril",
      "Dimethylergometrin",
      "Methylmethylergonovine",
      "Methysergid",
      "Methysergidum",
      "Metisergide",
      "Metisergido",
      "Sansert",
      "Methyllysergic Acid Butanolamide",
      "Metisergide [DCIT]",
      "UML 491",
      "Deseril (TN)",
      "Methysergidum [INN-Latin]",
      "Metisergido [INN-Spanish]",
      "Sansert (TN)",
      "UML-491",
      "Methysergide (USAN/INN)",
      "Methysergide [USAN:INN:BAN]",
      "N-(Alpha-(Hydroxymethyl)Propyl)-1-Methyl-Dextro-Lysergamide",
      "N-(1-(Hydroxymethyl)Propyl)-1-Methyl-Dextro-(+)-Lysergamide",
      "(+)-9,10-Didehydro-N-(1-(Hydroxymethyl)Propyl)-1,6-Dimethylergoline-8beta-Carboxamide",
      "(+)-N-(1-(Hydroxymethyl)Propyl)-1-Methyl-D-Lysergamide",
      "(8beta)-N-[(1S)-1-(Hydroxymethyl)Propyl]-1,6-Dimethyl-9,10-Didehydroergoline-8-Carboxamide",
      "1-Methyl-D-Lysergic Acid Butanolamide",
      "1-Methyl-Dextro-Lysergic Acid (+)-1-Hydroxy-2-Butylamide",
      "1-Methylmethylergonovine",
      "9,10-Didehydro-N-(1-(Hydroxymethyl)Propyl)-1,6-Dimethylergoline-8-Carboxamide",
    ],
  },
  {
    primary_id: "D0O6GL",
    names: ["Spiro[cyclopropane-1,2-Adamantan]-2-Amine"],
  },
  {
    primary_id: "D0O6GN",
    names: ["Mdl 101,146"],
  },
  {
    primary_id: "D0O6GQ",
    names: ["Indazoletriazolephenyl Derivative 2"],
  },
  {
    primary_id: "D0O6HP",
    names: [
      "KAI-9803",
      "DELCASERTIB",
      "UNII-5G7N7E908H",
      "5G7N7E908H",
      "BMS-875944",
      "Delcasertib [USAN:INN]",
      "949100-39-4",
      "HY-106262",
      "CS-0025460",
      "L-Arginine, L-Cysteinyl-L-Tyrosylglycyl-L-Arginyl-L-Lysyl-L-Lysyl-L-Arginyl-L-Arginyl-L- Glutaminyl-L-Arginyl-L-Arginyl-, (1-&gt;1')-Disulfide With L-Cysteinyl-L-Seryl-L-Phenylalanyl-L- Asparaginyl-L-Seryl-L-Tyrosyl-L-Alpha-Glutamyl-L-Leucylglycyl-L-Seryl-L-Leucine",
      "L-Cysteinyl-L-Tyrosylglycyl-L-Arginyl-L-Lysyl-L-Lysyl-L-Arginyl-L-Arginyl-L-Glutaminyl-L- Arginyl-L-Arginyl-L-Arginine (1-&gt;1')-D",
    ],
  },
  {
    primary_id: "D0O6HW",
    names: ["4-(4-Bromobenzylthio)-2-Aminobutanoic Acid", "CHEMBL203746", "BDBM50179700"],
  },
  {
    primary_id: "D0O6IU",
    names: [
      "Phenylephrine",
      "Cyclomydril",
      "Dilatair",
      "Dionephrine",
      "Doktors",
      "Duration",
      "Fenilefrina",
      "Isophrim",
      "Isophrin",
      "Mesaton",
      "Mesatone",
      "Mesatonum",
      "Metaoxedrin",
      "Metaoxedrine",
      "Metaoxedrinum",
      "Metasympatol",
      "Metasynephrine",
      "Metsatonum",
      "Mezaton",
      "Mydfrin",
      "Neofrin",
      "Neosynephrine",
      "Nostril",
      "Ocugestrin",
      "Phenoptic",
      "Phenylephrinum",
      "Spersaphrine",
      "Visadron",
      "Alcon Efrin",
      "Isopto Frin",
      "Minims Phenylephrine",
      "Nostril Spray Pump",
      "Nostril Spray Pump Mild",
      "Phenylephrine Minims",
      "Prefrin Liquifilm",
      "Relief Eye Drops For Red Eyes",
      "Alconefrin Nasal Drops 12",
      "Alconefrin Nasal Drops 25",
      "Alconefrin Nasal Drops 50",
      "Alconefrin Nasal Spray 25",
      "Ah-Chew",
      "Ak-Dilate",
      "Ak-Nefrin",
      "Fenilefrina [INN-Spanish]",
      "I-Phrine",
      "L-Phenylephedrine",
      "L-Phenylephrine",
      "M-Methylaminoethanolphenol",
      "M-Oxedrine",
      "M-Sympathol",
      "M-Sympatol",
      "M-Synephrine",
      "Mydfrin (TN)",
      "Neo-Synephrine",
      "Neo-Synephrine Nasal Drops",
      "Neo-Synephrine Nasal Jelly",
      "Neo-Synephrine Nasal Spray",
      "Ocu-Phrin Sterile Eye Drops",
      "Phenylephrine (INN)",
      "Phenylephrine Minims (TN)",
      "Phenylephrine [INN:BAN]",
      "Phenylephrinum [INN-Latin]",
      "R(-)-Phenylephrine",
      "L-(3-Hydroxyphenyl)-N-Methylethanolamine",
      "L-1-(M-Hydroxyphenyl)-2-Methylaminoethanol",
      "L-M-Hydroxy-Alpha-((Methylamino)Methyl)Benzyl Alcohol",
      "L-Alpha-Hydroxy-Beta-Methylamino-3-Hydroxy-L-Ethylbenzene",
      "Tannins, Compds. With (R)-3-Hydroxy-Alpha-((Methylamino)Methyl)Benzenemethanol",
      "Benzenemethanol, 3-Hydroxy-Alpha-((Methylamino)Methyl)-, (R)-(9CI)",
      "(-)-M-Hydroxy-Alpha-(Methylaminomethyl)Benzyl Alcohol",
      "(R)-2-Hydroxy-2-(3-Hydroxyphenyl)-N-Methylethylamine",
      "(R)-3-Hydroxy-Alpha-((Methylamino)Methyl)Benzenemethanol",
      "3-[(1R)-1-Hydroxy-2-(Methylamino)Ethyl]phenol",
    ],
  },
  {
    primary_id: "D0O6IZ",
    names: [
      "Naphazoline",
      "Antan",
      "Clearine",
      "Nafazolin",
      "Nafazolina",
      "Nafazoline",
      "Naphazolinum",
      "Naphthizine",
      "Opcon",
      "Nafazolina [DCIT]",
      "Nafazoline [Spanish]",
      "Naphazoline Monohydrochloride",
      "Naphazolinum [Latin]",
      "Enamine_000333",
      "Alpha-Naphthylmethyl Imidazoline",
      "Ciba 2020/R",
      "Nafazolin (TN)",
      "Naphazoline (INN)",
      "Naphazoline [INN:BAN]",
      "Naphazolinum [INN-Latin]",
      "AK-968/41090774",
      "2-(1-Naphthylmethyl)-2-Imidazoline",
      "2-(1-Naphthylmethyl)-4,5-Dihydro-1H-Imidazole",
      "2-(Naphthyl-(1')-Methyl)Imidazolin",
      "2-(Naphthyl-(1')-Methyl)Imidazolin [German]",
      "2-(Alpha-Naphthylmethyl)-Imidazoline",
      "2-(Naphthalen-1-Ylmethyl)-4,5-Dihydro-1H-Imidazole",
      "4,5-Dihydro-2-(1-Naphthylmethyl)Imidazole",
    ],
  },
  {
    primary_id: "D0O6JB",
    names: ["HI-443"],
  },
  {
    primary_id: "D0O6JE",
    names: ["Multiform PI3K Inhibitors"],
  },
  {
    primary_id: "D0O6JQ",
    names: ["(R)PK-11195"],
  },
  {
    primary_id: "D0O6KE",
    names: ["Repirinast"],
  },
  {
    primary_id: "D0O6KR",
    names: ["9-Anthroic Acid", "Anthracene-10-Carboxylic Acid", "9-AC"],
  },
  {
    primary_id: "D0O6LL",
    names: ["PMID26560530-Compound-8"],
  },
  {
    primary_id: "D0O6LR",
    names: ["Autologous CD19-Targeting CAR T Cells"],
  },
  {
    primary_id: "D0O6LW",
    names: ["3-Phenyl Propanoic Derivative 1"],
  },
  {
    primary_id: "D0O6LY",
    names: ["MAT-304", "Anti-CD5 Antibody (Cancer/Autoimmune Disease), MAT Biopharma"],
  },
  {
    primary_id: "D0O6MG",
    names: ["RS-100329"],
  },
  {
    primary_id: "D0O6NA",
    names: ["JNJ-10229570"],
  },
  {
    primary_id: "D0O6NS",
    names: [
      "8-Br-Adenine",
      "8-Bromoadenine",
      "8-Bromo-7H-Purin-6-Amine",
      "6974-78-3",
      "8-Bromo-9H-Purin-6-Amine",
      "8-Bromo-1H-Adenine",
      "6-Amino-8-Bromopurine",
      "8-Bromopurine-6-Ylamine",
      "EINECS 230-225-1",
      "NSC 21697",
      "CHEMBL65747",
      "8-Bromo-9H-Purin-6-Ylamine",
      "FVXHPCVBOXMRJP-UHFFFAOYSA-N",
      "W-203555",
      "1H-Purin-6-Amine, 8-Bromo-",
      "8-Bromo-1h-Purin-6-Amine",
      "8-Bromadenin",
      "8-Bromo Adenine",
      "SCHEMBL8565",
      "AC1L31DI",
      "Oprea1_043695",
      "8-Bromo-9H-Purine-6-Amine",
      "9H-Purin-6-Amine,2-Bromo-",
      "9H-Purin-6-Amine,8-Bromo-",
      "8-Bromo-9H-Purin-6-Amine #",
      "CTK4G0895",
      "DTXSID70220036",
    ],
  },
  {
    primary_id: "D0O6NU",
    names: [
      "NSC-637992",
      "CCRIS 7539",
      "UNII-3S0283Z6W9",
      "C 1310",
      "CHEMBL125082",
      "3S0283Z6W9",
      "138154-40-2",
      "5-((2-(Diethylamino)Ethyl)Amino)-8-Hydroxy-1-Methyl-6H-Imidazo(4,5,1-De)Acidin-6-One",
      "6H-Imidazo(4,5,1-De)Acridin-6-One, 5-((2-(Diethylamino)Ethyl)Amino)-8-Hydroxy-1-Methyl-",
      "AC1L30G5",
      "SCHEMBL13526631",
      "DTXSID70160509",
      "ZINC3825293",
      "BDBM50008344",
      "NCI60_012641",
      "LS-183332",
      "5-(2-(Diethylamino)Ethylamino)-8-Hydroxy-1-Methyl-6H-Imidazo[4,5,1-De]acridin-6-One",
    ],
  },
  {
    primary_id: "D0O6NZ",
    names: ["F-G-G-F-T-G-A-R-K-S-Aib-R-K-L-A-N-Q-CONH2"],
  },
  {
    primary_id: "D0O6OH",
    names: ["PMID23312943C21"],
  },
  {
    primary_id: "D0O6OI",
    names: [
      "4'-(P-Toluenesulfonamide)-3,4-Dihydroxy Chalcone",
      "CHEMBL199829",
      "Sulfonamide Chalcone, 5",
      "SCHEMBL3484418",
      "BDBM50174837",
      "4''-(P-Toluenesulfonamide)-3,4-Dihydroxy Chalcone",
    ],
  },
  {
    primary_id: "D0O6OJ",
    names: [
      "N-Hydroxy-N-Methyl-3-Phenanthren-9-Yl-Acrylamide",
      "CHEMBL367247",
      "ZINC28023141",
      "BDBM50015150",
      "(E)-3-(Phenanthren-9-Yl)-N-Methyl-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D0O6OK",
    names: ["LY3074828"],
  },
  {
    primary_id: "D0O6OM",
    names: ["CGEN-15001", "CGEN-15001T (Recombinant Fusion Protein/LEADS, Autoimmune Diseases), Compugen"],
  },
  {
    primary_id: "D0O6OZ",
    names: ["Sodium Iodide I 131"],
  },
  {
    primary_id: "D0O6QC",
    names: ["Heteroaryl-Pyrazole Derivative 1"],
  },
  {
    primary_id: "D0O6QU",
    names: [
      "CD564",
      "Cd564",
      "CHEMBL309282",
      "110952-26-6",
      "6-(5,5,8,8-Tetramethyl-6,7-Dihydronaphthalene-2-Carbonyl)Naphthalene-2-Carboxylic Acid",
      "2-Naphthalenecarboxylicacid, 6-[(5,6,7,8-Tetrahydro-5,5,8,8-Tetramethyl-2-Naphthalenyl)Carbonyl]-",
      "1fcy",
      "ACMC-20mdu5",
      "AC1L1E1W",
      "SCHEMBL2391376",
      "CTK0I1187",
      "BDBM31886",
      "DTXSID60274381",
      "DB02741",
      "6-(5,6,7,8-Tetrahydro-5,5,8,8-Tetramethyl-2-Naphthoyl)-2-Naphthoic Acid",
      "6-[(5,5,8,8-Tetramethyl-5,6,7,8-Tetrahydronaphthalen-2-Yl)Carbonyl]naphthalene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0O6QW",
    names: ["Manifaxine", "BW-1555U88", "GW-320659", "GW-320659A"],
  },
  {
    primary_id: "D0O6RK",
    names: ["MD-0727"],
  },
  {
    primary_id: "D0O6RU",
    names: ["Cepacidine A", "Cepacidine A1"],
  },
  {
    primary_id: "D0O6SA",
    names: ["KA2507"],
  },
  {
    primary_id: "D0O6SB",
    names: [
      "Diprenorphine",
      "14357-78-9",
      "UNII-1F0L5N25ZZ",
      "CHEMBL281786",
      "CHEBI:4650",
      "1F0L5N25ZZ",
      "DEA No. 9058",
      "M50-50 Injection",
      "Diprenorphine [INN:BAN]",
      "Diprenorphinum [INN-Latin]",
      "Diprenorfina [INN-Spanish]",
      "Diprenorphinum",
      "Diprenorfina",
      "EINECS 238-325-7",
      "21-Cyclopropyl-6,7,8,14-Tetrahydro-7alpha-(1-Hydroxy-1-Methylethyl)-6,14-Endo-Ethanooripavine",
      "Diprenorfin",
      "(5?,7?)-17-(Cyclopropylmethyl)-4,5-Epoxy-18,19-Dihydro-3-Hydroxy-6-Methoxy-?,?-Dimethyl-6,14-Ethenomorphinan-7-Methanol",
      "6,14-Ethenomorphinan-7-Methanol, 17-(Cyclopropylmethyl)-4",
    ],
  },
  {
    primary_id: "D0O6SI",
    names: [
      "1,4-Butanediol",
      "1,4-BUTANEDIOL",
      "Butane-1,4-Diol",
      "110-63-4",
      "1,4-Butylene Glycol",
      "Tetramethylene Glycol",
      "1,4-Dihydroxybutane",
      "1,4-Tetramethylene Glycol",
      "Tetramethylene 1,4-Diol",
      "Sucol B",
      "1,4-BD",
      "DIOL 14B",
      "Agrisynth B1D",
      "UNII-7XOO2LE6G3",
      "HO(CH2)4OH",
      "NSC 406696",
      "CCRIS 5984",
      "1,4-Dihdyroxybutane",
      "HSDB 1112",
      "EINECS 203-786-5",
      "HOCH2CH2CH2CH2OH",
      "BRN 1633445",
      "7XOO2LE6G3",
      "AI3-07553",
      "CHEBI:41189",
      "WERYXYBDKMZEQL-UHFFFAOYSA-N",
      "MFCD00002968",
      "DSSTox_CID_4666",
      "DSSTox_RID_77492",
      "DSSTox_GSID_24666",
      "BDO",
      "CAS-110-63-4",
      "BU1",
      "1,4-Butanediol, Homopolymer",
      "4-Hydroxybutanol",
    ],
  },
  {
    primary_id: "D0O6SJ",
    names: ["PMID28092474-Compound-32n"],
  },
  {
    primary_id: "D0O6SK",
    names: ["Triazolo-Pyrimidinedione Derivative 2"],
  },
  {
    primary_id: "D0O6SQ",
    names: ["Ro-25-4835"],
  },
  {
    primary_id: "D0O6SX",
    names: [
      "Hydroxyzine",
      "Atara",
      "Atarax",
      "Ataraxoid",
      "Atarazoid",
      "Atarox",
      "Atazina",
      "Aterax",
      "Deinait",
      "Durrax",
      "Hidroxizina",
      "Hychotine",
      "Hydroksyzyny",
      "Hydroxine",
      "Hydroxizine",
      "Hydroxizinum",
      "Hydroxycine",
      "Hydroxyzin",
      "Hydroxyzinum",
      "Hydroxyzyne",
      "Idrossizina",
      "Neurozina",
      "Nevrolaks",
      "Orgatrax",
      "Pamazone",
      "Parenteral",
      "Paxistil",
      "Placidol",
      "Plaxidol",
      "Tranquizine",
      "Traquizine",
      "Vistaril",
      "Atarax Base",
      "Hydroksyzyny [Polish]",
      "Hydroxyzine Hcl",
      "Hydroxyzine Base",
      "Hydroxyzine Hydrochloride",
      "Idrossizina [DCIT]",
      "NP 212",
      "UCB 4492",
      "UCB 492",
      "Atarax (TN)",
      "Hidroxizina [INN-Spanish]",
      "Hy-Pam 25",
      "Hydroxyzine (INN)",
      "Hydroxyzine [INN:BAN]",
      "Hydroxyzinum [INN-Latin]",
      "Marex (TN)",
      "Neo-Calma",
      "Tran-Q",
      "U.CB 4492",
      "Vesparaz-Wirkstoff",
      "Vistaril (TN)",
      "U.C.B 4492",
      "N-(4-Chlorobenzhydryl)-N'-(Hydroxyethoxyethyl)Piperazine",
      "N-(4-Chlorobenzhydryl)-N'-(Hydroxyethyloxyethyl)Piperazine",
      "Ethanol, 2-(2-(4-((4-Chlorophenyl)Phenylmethyl)-1-Piperazinyl)Ethoxy)-(9CI)",
      "1-(P-Chloro-.alpha.-Phenylbenzyl)-4-[2-[(2-Hydroxyethoxy)Ethyl]piperazine",
      "1-(P-Chloro-Alpha-Phenylbenzyl)-4-(2-(2-Hydroxyethoxy)Ethyl)Piperazine",
      "1-(P-Chloro-Alpha-Phenylbenzyl)-4-(2-Hydroxyethoxyethyl)Piperazine",
      "1-(P-Chlorobenzhydryl)-4-(2-(2-Hydroxyethoxy)Ethyl)Diethylenediamine",
      "1-(P-Chlorobenzhydryl)-4-(2-(2-Hydroxyethoxy)Ethyl)Piperazine",
      "1-(P-Chlorobenzhydryl)-4-[2-(2-Hydroxyethoxy)Ethyl]diethylenediamine",
      "1-(P-Chlorobenzhydryl)-4-[2-(2-Hydroxyethoxy)Ethyl]piperazine",
      "1-(P-Chlorodiphenylmethyl)-4-(2-(2-Hydroxyethoxy)Ethyl)Piperazine",
      "1-(P-Chlorodiphenylmethyl)-4-[2-(2-Hydroxyethoxy)Ethyl]piperazine",
      "2-(2-(4-((4-Chlorophenyl)Phenylmethyl)-1-Piperazinyl)Ethoxy)Ethanol",
      "2-(2-(4-(P-Chloro-Alpha-Phenylbenzyl)-1-Piperazinyl)Ethoxy)Ethanol",
      "2-(2-{4-[(4-Chlorophenyl)(Phenyl)Methyl]piperazin-1-Yl}ethoxy)Ethanol",
      "2-[(2-{4-[(4-Chlorophenyl)(Phenyl)Methyl]piperazin-1-Yl}ethyl)Oxy]ethanol",
      "2-[2-[4-(P-Chloro-.alpha.-Phenylbenzyl)-1-Piperazinyl]ethoxy]ethanol",
      "2-[2-[4-[(4-Chlorophenyl)-Phenylmethyl]piperazin-1-Yl]ethoxy]ethanol",
    ],
  },
  {
    primary_id: "D0O6TD",
    names: ["BBT-021", "BBT-003", "Erythropoietin IgG-Fc Fusion Proteins (Anemia), Bolder BioTechnology Inc"],
  },
  {
    primary_id: "D0O6TG",
    names: ["PMID27410995-Compound-Figure3j"],
  },
  {
    primary_id: "D0O6UF",
    names: ["403U76"],
  },
  {
    primary_id: "D0O6UH",
    names: ["Cell-Based Gene Therapy"],
  },
  {
    primary_id: "D0O6UY",
    names: ["INCB54828"],
  },
  {
    primary_id: "D0O6UZ",
    names: ["Sertaconazole"],
  },
  {
    primary_id: "D0O6VK",
    names: ["Enzalutamide"],
  },
  {
    primary_id: "D0O6VU",
    names: ["PMID25666693-Compound-92"],
  },
  {
    primary_id: "D0O6VZ",
    names: [
      "GDC-0980/RG7422",
      "Apitolisib",
      "1032754-93-0",
      "GDC-0980",
      "GDC0980",
      "RG7422",
      "(S)-1-(4-((2-(2-Aminopyrimidin-5-Yl)-7-Methyl-4-Morpholinothieno[3,2-D]pyrimidin-6-Yl)Methyl)Piperazin-1-Yl)-2-Hydroxypropan-1-One",
      "UNII-1C854K1MIJ",
      "GDC-0980 (RG7422)",
      "Apitolisib (GDC-0980, RG7422)",
      "1C854K1MIJ",
      "CHEMBL1922094",
      "RG-7422",
      "(2s)-1-(4-{[2-(2-Aminopyrimidin-5-Yl)-7-Methyl-4-(Morpholin-4-Yl)Thieno[3,2-D]pyrimidin-6-Yl]methyl}piperazin-1-Yl)-2-Hydroxypropan-1-One",
      "J-502360",
      "C23H30N8O3S",
    ],
  },
  {
    primary_id: "D0O6WF",
    names: ["Ac-His-DPhe(PCF3)-Arg-Trp-NH2", "CHEMBL501679"],
  },
  {
    primary_id: "D0O6WJ",
    names: ["Ecalcidene"],
  },
  {
    primary_id: "D0O6WN",
    names: ["[HO1][Thr4,Orn8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D0O6WS",
    names: [
      "APC-004",
      "APC-005",
      "Mitosis Inhibitors/M Phase Cell Apoptosis Inducers (Cancer)",
      "Mitosis Inhibitors/M Phase Cell Apoptosis Inducers(Cancer), Shanghai Ambrosia Pharmaceutical",
    ],
  },
  {
    primary_id: "D0O6WV",
    names: [
      "BMS-777607",
      "1025720-94-8",
      "BMS 777607",
      "1196681-44-3",
      "BMS777607",
      "ASLAN-002",
      "UNII-A3MMS6HDO1",
      "N-(4-(2-Amino-3-Chloropyridin-4-Yloxy)-3-Fluorophenyl)-4-Ethoxy-1-(4-Fluorophenyl)-2-Oxo-1,2-Dihydropyridine-3-Carboxamide",
      "A3MMS6HDO1",
      "N-[4-[(2-Amino-3-Chloropyridin-4-Yl)Oxy]-3-Fluorophenyl]-4-Ethoxy-1-(4-Fluorophenyl)-2-Oxo-1,2-Dihydropyridine-3-Carboxamide",
      "N-(4-((2-Amino-3-Chloropyridin-4-Yl)Oxy)-3-Fluorophenyl)-4-Ethoxy-1-(4-Fluorophenyl)-2-Oxo-1,2-Dihydropyridine-3-Carboxamide",
    ],
  },
  {
    primary_id: "D0O6WW",
    names: ["8-Hydroxy-2-Phenyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0O6WZ",
    names: ["OcuNex"],
  },
  {
    primary_id: "D0O6XT",
    names: [
      "NI-57",
      "CHEMBL3752151",
      "1883548-89-7",
      "4-Cyano-~{n}-(1,3-Dimethyl-2-Oxidanylidene-Quinolin-6-Yl)-2-Methoxy-Benzenesulfonamide",
      "GTPL8573",
      "SCHEMBL17560720",
      "MolPort-039-101-322",
      "NI 57",
      "EX-A1876",
      "BDBM50157570",
      "ZINC254286814",
      "AKOS027470204",
      "NCGC00483115-01",
      "NI-57, &gt",
      "HY-19537",
      "CS-0015636",
      "4-Cyano-N-(1,3-Dimethyl-2-Oxoquinolin-6-Yl)-2-Methoxybenzenesulfonamide",
      "4-Cyano-N-(1,3-Dimethyl-2-Oxo-1,2-Dihydroquinolin-6-Yl)-2-Methoxybenzenesulfonamide",
    ],
  },
  {
    primary_id: "D0O6YE",
    names: ["RG7450", "Anti-STEAP1, DSTP3086S"],
  },
  {
    primary_id: "D0O6ZD",
    names: [
      "8-Acetyl-7-(Benzyloxy)-2H-Chromen-2-One",
      "CHEMBL1290111",
      "65535-49-1",
      "SCHEMBL7518570",
      "CTK1J6469",
      "DTXSID60555920",
      "BDBM50332028",
      "8-Acetyl-7-(Benzyloxy)-2H-1-Benzopyran-2-One",
      "2H-1-Benzopyran-2-One, 8-Acetyl-7-(Phenylmethoxy)-",
    ],
  },
  {
    primary_id: "D0O6ZH",
    names: ["Endo-CD"],
  },
  {
    primary_id: "D0O6ZM",
    names: [
      "4-(3,4-Dimethoxyphenethyl)Aniline",
      "CHEMBL1171680",
      "SCHEMBL6481605",
      "MEJNYOQOZUXCOG-UHFFFAOYSA-N",
      "ZINC34258590",
      "BDBM50322060",
      "4-[2-[3,4-Dimethoxyphenyl)Ethyl]aniline",
      "4[2-(3,4-Dimethoxyphenyl)Ethyl]benzenamine",
      "4-[2-(3,4-Dimethoxyphenyl)Ethyl]benzenamine",
      "4-[2-(3,4-Dimethoxyphenyl)Ethyl]phenylamine",
      "4-[2-(3,4-Dimethoxy-Phenyl)Ethyl]-Phenylamine",
      "4-[2-(3,4-Dimethoxy-Phenyl)-Ethyl]phenylamine",
    ],
  },
  {
    primary_id: "D0O6ZN",
    names: ["PMID24793972C2", "(1R,2R)-2-PCCA", "GTPL7798", "BDBM50065915", "HY-100013A", "CS-0022096", "1287205-42-8"],
  },
  {
    primary_id: "D0O6ZO",
    names: ["TAK-715"],
  },
  {
    primary_id: "D0O7AK",
    names: ["SF-107"],
  },
  {
    primary_id: "D0O7AS",
    names: ["PMID18155906C16f"],
  },
  {
    primary_id: "D0O7BH",
    names: ["PMID25726713-Compound-46"],
  },
  {
    primary_id: "D0O7BW",
    names: [
      "SC-102",
      "4-[3-(4-Fluorophenyl)-1h-Pyrazol-4-Yl]pyridine",
      "3hvc",
      "GG5",
      "SCHEMBL1554633",
      "CHEMBL1233024",
      "BDBM19429",
      "BILJSHVAAVZERY-UHFFFAOYSA-N",
      "ZINC16052350",
      "DB07829",
      "5-(4-Fluorophenyl)-4-(Pyridin-4-Yl)Pyrazole",
      "4-[3-(4-Fluorophenyl)-1H-Pyrazole-4-Yl]pyridine",
      "4-(3-(4-Fluorophenyl)-1h-Pyrazol-4-Yl)",
      "SPC-0038B",
      "SPC-0172",
      "Temoporfin Derivatives (Cancer)",
      "Temoporfin Derivatives (Cancer), Scotia Holdings",
      "4-[3-(4-FLUOROPHENYL)-1H-PYRAZOL-4-YL]PYRIDINE",
    ],
  },
  {
    primary_id: "D0O7CO",
    names: ["MyoCell"],
  },
  {
    primary_id: "D0O7CX",
    names: [
      "4'-Amino-4-Hydroxychalcone",
      "CHEMBL383563",
      "1-(4-Aminophenyl)-3-(4-Hydroxyphenyl)Prop-2-En-1-One",
      "4''-Amino-4-Hydroxychalcone",
      "AC1O21K2",
      "SCHEMBL3762443",
      "MolPort-019-723-769",
      "ZINC4099897",
      "SBB099103",
      "BDBM50174839",
      "AN-308/25123008",
      "(2E)-1-(4-Aminophenyl)-3-(4-Hydroxyphenyl)Prop-2-En-1-One",
    ],
  },
  {
    primary_id: "D0O7DA",
    names: [
      "(+)-BUTACLAMOL",
      "D-Butaclamol",
      "(+)-Butaclamol",
      "BUTACLAMOL",
      "CHEMBL8514",
      "56245-67-1",
      "1H-Benzo(6,7)Cyclohepta(1,2,3-De)Pyrido(2,1-A)Isoquinolin-3-Ol, 2,3,4,4a,8,9,13b,14-Octahydro-3-(1,1-Dimethylethyl)-, (3S-(3-Alpha,4a-Alpha,13b-Beta))-",
      "(+/-)-Butaclamol Hydrochloride",
      "BUTACLAMOL,d-",
      "AC1L1XRQ",
      "BUTACLAMOL,(+)",
      "GTPL62",
      "BUTACLAMOL, (-)",
      "Biomol-NT_000018",
      "Lopac0_000475",
      "Lopac0_000187",
      "SCHEMBL122821",
      "BPBio1_001177",
      "ZZJYIKPMDIWRSN-HWBMXIPRSA-N",
      "CHEBI:109533",
      "HMS2089C08",
      "ZINC2008410",
      "PDSP2_000547",
      "PDSP2_001512",
      "BDBM50008735",
      "CCG-204282",
      "NCGC00162085-04",
    ],
  },
  {
    primary_id: "D0O7DB",
    names: ["A-204176"],
  },
  {
    primary_id: "D0O7DG",
    names: ["Histrelin"],
  },
  {
    primary_id: "D0O7DN",
    names: ["CEP-427"],
  },
  {
    primary_id: "D0O7DP",
    names: [
      "CVT-12012",
      "UNII-OH3J29Q653",
      "1018675-35-8",
      "CHEMBL573829",
      "OH3J29Q653",
      "SCHEMBL906403",
      "EX-A2767",
      "BDBM50298898",
      "HY-11034",
      "2-Hydroxy-N-(2-(3-Methyl-2-Oxo-7-(((3-(Trifluoromethyl)Phenyl)Methyl)Amino)-1,2-Dihydroquinoxalin-1-Yl)Ethyl)Acetamide",
      "Acetamide, 2-Hydroxy-N-(2-(3-Methyl-2-Oxo-7-(((3-(Trifluoromethyl)Phenyl)Methyl)Amino)-1(2H)-Quinoxalinyl)Ethyl)-",
      "CS-0003005",
      "2-Hydroxy-N-(2-(3-Methyl-2-Oxo-7-(3-(Trifluoromethyl)Benzylamino)Quinoxalin-1(2H)-Yl)Ethyl)Acetamide",
    ],
  },
  {
    primary_id: "D0O7DU",
    names: ["CT-1746"],
  },
  {
    primary_id: "D0O7DZ",
    names: ["R-61837", "3-Methoxy-6-[4-(3-Methylphenyl)-1-Piperazinyl]pyridazine"],
  },
  {
    primary_id: "D0O7EC",
    names: ["ADCON-I"],
  },
  {
    primary_id: "D0O7ES",
    names: ["Heberpenta L", "Diphtheria/Tetanus/Pertussis/HBV/Hib Vaccine, CIGB"],
  },
  {
    primary_id: "D0O7FJ",
    names: [
      "Amino Acids",
      "Aminess 5.2% Essential Amino Acids W/ Histadine",
      "Aminosyn 10%",
      "Aminosyn 10% (Ph6)",
      "Aminosyn 3.5%",
      "Aminosyn 3.5% In Plastic Container",
      "Aminosyn 5%",
      "Aminosyn 7%",
      "Aminosyn 7% (Ph6)",
      "Aminosyn 8.5%",
      "Aminosyn 8.5% (Ph6)",
      "Aminosyn Ii 10%",
      "Aminosyn Ii 10% In Plastic Container",
      "Aminosyn Ii 15% In Plastic Container",
      "Aminosyn Ii 3.5%",
      "Aminosyn Ii 3.5% In Plastic Container",
      "Aminosyn Ii 5%",
      "Aminosyn Ii 7%",
      "Aminosyn Ii 8.5%",
      "Aminosyn-Hbc 7%",
      "Aminosyn-Hbc 7% In Plastic Container",
      "Aminosyn-Hf 8%",
      "Aminosyn-Pf 10%",
      "Aminosyn-Pf 7%",
      "Aminosyn-Rf 5.2%",
      "Branchamin 4%",
      "Branchamin 4% In Plastic Container",
      "Clinisol 15% Sulfite Free In Plastic Container",
      "Freamine 8.5%",
      "Freamine Hbc 6.9%",
      "Freamine Ii 8.5%",
      "Freamine Iii 10%",
      "Freamine Iii 8.5%",
      "Hepatamine 8%",
      "Hepatasol 8%",
      "Neopham 6.4%",
      "Nephramine 5.4%",
      "Novamine 11.4%",
      "Novamine 15%",
      "Novamine 15% Sulfite Free In Plastic Container",
      "Novamine 8.5%",
      "Premasol 10% In Plastic Container",
      "Premasol 6% In Plastic Container",
      "Prosol 20% Sulfite Free In Plastic Container",
      "Renamin W/O Electrolytes",
      "Travasol 10% In Plastic Container",
      "Travasol 10% W/O Electrolytes",
      "Travasol 5.5% In Plastic Container",
      "Travasol 5.5% W/O Electrolytes",
      "Travasol 8.5% In Plastic Container",
      "Travasol 8.5% W/O Electrolytes",
      "Trophamine",
      "Trophamine 10%",
    ],
  },
  {
    primary_id: "D0O7GB",
    names: ["SPK-RPE65"],
  },
  {
    primary_id: "D0O7GD",
    names: [
      "3'-(1,2,3-Triazol-1-Yl)-3'-Deoxy-Beta-D-Thymidine",
      "3'-(1,2,3-Triazol)DT",
      "3'-Deoxy-3'-(1,2,3-Triazol-1-Yl)Thymidine",
      "122370-58-5",
      "CHEMBL1092729",
      "Thymidine,3'-Deoxy-3'-(1H-1,2,3-Triazol-1-Yl)- (9CI)",
      "AC1L9Q42",
      "SCHEMBL9965127",
      "CTK4B3087",
      "DTXSID60153586",
      "BDBM50314847",
      "3'-(1H-1,2,3-Triazol-1-Yl)-3'-Deoxythymidine",
      "3''-(1,2,3-Triazol-1-Yl)-3''-Deoxy-Beta-D-Thymidine",
      "1-[(2R,4S,5S)-5-(Hydroxymethyl)-4-(Triazol-1-Yl)Tetrahydrofuran-2-Yl]-5-Methyl-Pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0O7GU",
    names: ["Palifermin"],
  },
  {
    primary_id: "D0O7HD",
    names: ["CEP1349"],
  },
  {
    primary_id: "D0O7HF",
    names: ["IDN-1965"],
  },
  {
    primary_id: "D0O7HP",
    names: ["Example 98 (WO2011020806)"],
  },
  {
    primary_id: "D0O7IH",
    names: ["Tarnylcypromine Derivative 3"],
  },
  {
    primary_id: "D0O7IQ",
    names: ["PMID25666693-Compound-6"],
  },
  {
    primary_id: "D0O7IS",
    names: ["[3H]AZ11637326", "GTPL3971"],
  },
  {
    primary_id: "D0O7IZ",
    names: [
      "Anti-MIF Antibodies",
      "Antimacrophage Inhibitory Factor, Baxter",
      "Anti-MIF Antibodies (Inflammation)",
      "Anti-MIF Antibodies (Inflammation), Baxter",
      "Anti-MIF Technology, Cytokine/IDEC",
      "Anti-Inflammatories, Cytokine/IDEC",
      "Anti-Macrophage Inhibitory Factor, Cytokine/IDEC",
    ],
  },
  {
    primary_id: "D0O7JK",
    names: ["ISG15-LNP01"],
  },
  {
    primary_id: "D0O7JV",
    names: ["4-Phenyl-1-(1-Phenylhexyl)Piperidin-4-Ol", "CHEMBL230253"],
  },
  {
    primary_id: "D0O7JW",
    names: ["Alcaftadine"],
  },
  {
    primary_id: "D0O7KV",
    names: ["P1201-07"],
  },
  {
    primary_id: "D0O7LU",
    names: ["AZD3463", "AZD-3463", "CS-1382", "HY-15609", "KB-154896"],
  },
  {
    primary_id: "D0O7MW",
    names: [
      "(5-Methoxy-1H-Indol-3-Yl)Methanamine",
      "60523-82-2",
      "5-Methoxy-1h-Indol-3-Methylamine",
      "3-(AMINOMETHYL)-5-METHOXYINDOLE",
      "CHEMBL1276050",
      "5-METHOXYINDOLE-3-METHYLAMINE",
      "SCHEMBL3976318",
      "CTK5B1636",
      "DTXSID20563182",
      "MolPort-006-831-850",
      "ZX-AN081076",
      "ALBB-030269",
      "FCH853008",
      "BDBM50330861",
      "9026AB",
      "ZINC20269183",
      "AKOS005214650",
      "MB03907",
      "CM-1862",
      "SC-45599",
      "KB-43524",
      "AJ-76727",
      "AK112525",
      "ST2402466",
      "DB-012273",
      "AX8104451",
      "FT-0691098",
      "4CH-017193",
      "1-(5-METHOXY-1H-INDOL-3-YL)METHANAMINE",
    ],
  },
  {
    primary_id: "D0O7MX",
    names: ["ISIS 116654"],
  },
  {
    primary_id: "D0O7NC",
    names: ["H-Dmt-Tic-NH-CH[(CH2)4-NH2]-Bid"],
  },
  {
    primary_id: "D0O7NF",
    names: ["MCP-205", "Delta Opioid Receptor Agonist (Cardiac Ischemia), Mt Cook Pharma"],
  },
  {
    primary_id: "D0O7NV",
    names: ["DOV-51892", "DOV-22047", "GABA-A Modulators, DOV"],
  },
  {
    primary_id: "D0O7OE",
    names: [
      "Argiotoxin-636",
      "Argiotoxin 636",
      "Argiopine",
      "Argiopin",
      "CHEMBL1098240",
      "CHEBI:34541",
      "105029-41-2",
      "108687-79-2",
      "Butanediamide, N1-(16,21-Diamino-21-Imino-15-Oxo-6,10,14,20-Tetraazaheneicos-1-Yl)-2-(((2,4-Dihydroxyphenyl)Acetyl)Amino)-, (S-(R*,R*))-",
      "AR 636",
      "AC1L3UBP",
      "GTPL4138",
      "SCHEMBL17014644",
      "BDBM50316373",
      "(S-(R*,R*))-N1-(16,21-Diamino-21-Imino-15-Oxo-6,10,14,20-Tetraazaheneicos-1-Yl)-2-(((2,4-Dihydroxyphenyl)Acetyl)Amino)Butanediamide",
      "C13927",
    ],
  },
  {
    primary_id: "D0O7OI",
    names: ["ISIS 29138"],
  },
  {
    primary_id: "D0O7PS",
    names: [
      "AERAS-402",
      "Crucell Ad35",
      "Tuberculosis Vaccine (AdVac, PER.C6), Crucell/Aeras",
      "AERAS-402/Crucell Ad35 Prime-Boost Vaccine (Tuberculosis), Crucell/Aeras",
    ],
  },
  {
    primary_id: "D0O7PT",
    names: ["Bis(31/31')[[Cys(31), Nva(34)]NPY(27-36)-NH(2)]"],
  },
  {
    primary_id: "D0O7QA",
    names: ["PF-05089771"],
  },
  {
    primary_id: "D0O7QQ",
    names: ["BIIB 022"],
  },
  {
    primary_id: "D0O7QV",
    names: ["Durhamycin A", "AC1LCSNY"],
  },
  {
    primary_id: "D0O7RK",
    names: ["Salvinorin B 2-Methoxyethoxymethyl Ether", "CHEMBL272483"],
  },
  {
    primary_id: "D0O7RY",
    names: ["IDR-1018"],
  },
  {
    primary_id: "D0O7SC",
    names: [
      "D-11B",
      "Bis-Intercalators (Cancer), Dartmouth/PhytoMedical",
      "Bis-Intercalation/Bis-Acridines Compounds (Cancer), Dartmouth/PhytoMedical",
      "Bis-Intercalators/Bis-Acridines (Cancer), Dartmouth/PhytoMedical",
      "Bis-Acridines/Bis-Ellipticines/Bis-Carbazoles (Cancer), Dartmouth/PhytoMedical",
    ],
  },
  {
    primary_id: "D0O7SM",
    names: [
      "BUDDLEDIN A",
      "CHEBI:3206",
      "CHEMBL485981",
      "AC1NQYK4",
      "62346-20-7",
      "CHEBI:542717",
      "BDBM50241571",
      "C09623",
      "[(1S,5E,8R,9R)-6,10,10-Trimethyl-2-Methylidene-7-Oxo-8-Bicyclo[7.2.0]undec-5-Enyl] Acetate",
      "[(1S,5E,8R,9R)-6,10,10-Trimethyl-2-Methylene-7-Oxo-8-Bicyclo[7.2.0]undec-5-Enyl] Acetate",
      "(1R,2R,4E,9S)-4,11,11-Trimethyl-8-Methylidene-3-Oxobicyclo[7.2.0]undec-4-En-2-Yl Acetate",
    ],
  },
  {
    primary_id: "D0O7SP",
    names: [
      "Bamifylline",
      "Bamifylline Hydrochloride",
      "Trentadil",
      "Pulmac",
      "Bamifylline HCl",
      "Bamiphylline Hydrochloride",
      "20684-06-4",
      "Mono-HCl",
      "Benzetamophylline Hydrochloride",
      "UNII-66466QLM3S",
      "8102 CB",
      "BAX 2793Z",
      "Bamifylline Hydrochloride [USAN]",
      "EINECS 243-967-6",
      "AC 3810",
      "CB 8102",
      "66466QLM3S",
      "8-Benzyl-7-(2-(Ethyl(2-Hydroxyethyl)Amino)Ethyl)Theophylline Monohydrochloride",
      "8'-Benzyl-7-(2-(Ethyl(2-Hydroxyethyl)Amino)Ethyl)Theophylline Hydrochloride",
      "8-Benzyl-7-(N-Ethyl-N-(Beta-Hydroxyethyl)Aminoethyl)Theophylline Hydrochloride",
    ],
  },
  {
    primary_id: "D0O7TC",
    names: ["7-Pyridin-3-Yl-2H-1,4-Benzothiazin-3(4H)-One", "CHEMBL509971", "SCHEMBL1119417"],
  },
  {
    primary_id: "D0O7TO",
    names: ["CRTh2 Receptor Antagonists, Athersys", "CRTh2 Receptor Antagonists (Asthma/Allergy), Athersys"],
  },
  {
    primary_id: "D0O7UF",
    names: [
      "N-Hydroxy-8-(4-Methoxyphenyl)-8-Oxooctanamide",
      "CHEMBL95835",
      "SCHEMBL3383312",
      "LIOTZVIDBXLBAC-UHFFFAOYSA-N",
      "N-Hydroxy-7-(P-Anisoyl)Heptanamide",
      "BDBM50114826",
      "8-(4-Methoxy-Phenyl)-8-Oxo-Octanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0O7UT",
    names: ["CMPD1"],
  },
  {
    primary_id: "D0O7UU",
    names: ["GEM-SP"],
  },
  {
    primary_id: "D0O7VZ",
    names: ["AntiCD19 CART"],
  },
  {
    primary_id: "D0O7WT",
    names: ["9-[2-(1-Phosphonobutan-2-Yloxy)Ethyl]hypoxanthine"],
  },
  {
    primary_id: "D0O7XA",
    names: ["Anti-BCMA CAR-T Cell Therapy"],
  },
  {
    primary_id: "D0O7XG",
    names: ["SEM-606"],
  },
  {
    primary_id: "D0O7XO",
    names: ["AOD-9604", "AOD 9604", "AOD9604", "Human GH (HGH) Lipolytic Fragment", "Tyr-Somatostatin (177-191)"],
  },
  {
    primary_id: "D0O7XU",
    names: ["[Tyr-Pro-Phe-Phe-NH-CH2-]2", "CHEMBL409336"],
  },
  {
    primary_id: "D0O7ZE",
    names: [
      "Calyculin-A",
      "Calyculin A",
      "C05370",
      "101932-71-2",
      "[(3R,5R,7R,8S,9S)-2-[(1S,3S,4S,5R,6R,7E,9E,11E,13Z)-14-Cyano-3,5-Dihydroxy-1-Methoxy-4,6,8,9,13-Pentamethyl-Tetradeca-7,9,11,13-Tetraenyl]-9-[(E)-3-[2-[(1S)-3-[[(2S,3S,4S)-4-(Dimethylamino)-2,3-Dihydroxy-5-Methoxy-Pentanoyl]amino]-1-Methyl-Propyl]oxazol-4-Yl]allyl]-7-Hydroxy-4,4,8-Trimethyl-1,10-Dioxaspiro[4.5]decan-3-Yl] Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0O7ZJ",
    names: ["JWH-120"],
  },
  {
    primary_id: "D0O7ZL",
    names: [
      "Augustic Acid",
      "CHEMBL482673",
      "2-Hydroxyoleanolic Acid",
      "SCHEMBL829270",
      "BDBM50299747",
      "ZINC14980269",
      "26707-60-8",
      "2beta,3beta-Dihydroxyolean-12-En-28-Oic Acid",
    ],
  },
  {
    primary_id: "D0O7ZR",
    names: ["NZ-4"],
  },
  {
    primary_id: "D0O7ZX",
    names: ["Annamycin Liposomal"],
  },
  {
    primary_id: "D0O8AK",
    names: ["Alpha-Methylserine-O-Phosphate", "Alpha-MSOP"],
  },
  {
    primary_id: "D0O8AN",
    names: ["UDP-Glucuronic Acid"],
  },
  {
    primary_id: "D0O8AP",
    names: ["PMID28117607-Compound-4"],
  },
  {
    primary_id: "D0O8AQ",
    names: ["Isosorbide-Di-(Ethylcarbamate)"],
  },
  {
    primary_id: "D0O8AR",
    names: ["AZ-23", "AZ-64", "Trk Inhibitors (Cancer)", "Trk Inhibitors (Cancer), AstraZeneca"],
  },
  {
    primary_id: "D0O8AY",
    names: [
      "Combined PR1/WT1 Vaccine",
      "Combined PR1/WT1 Vaccine (Leukemia)",
      "Combined PR1/WT1 Vaccine (Leukemia), NIH",
      "Proteinase 1/Wilms Tumor 1 Vaccine (Leukemia), NIH",
    ],
  },
  {
    primary_id: "D0O8BP",
    names: ["ISIS 13920"],
  },
  {
    primary_id: "D0O8BQ",
    names: ["NT-KO-012"],
  },
  {
    primary_id: "D0O8BS",
    names: [
      "AZD1656",
      "AZD-1656",
      "FJEJHJINOKKDCW-INIZCTEOSA-N",
      "3-{[5-(Azetidin-1-Ylcarbonyl)Pyrazin-2-Yl]oxy}-5-{[(1S)-1-Methyl-2-(Methyloxy)Ethyl]oxy}-N-(5-Methylpyrazin-2-Yl)Benzamide",
      "SCHEMBL321593",
      "GTPL7701",
      "UNII-660M185X4D",
      "CHEMBL3219124",
      "AZD 1656",
      "660M185X4D",
      "89961-EP2305674A1",
      "89961-EP2301929A1",
      "89961-EP2301935A1",
      "3-{[5-(Azetidin-1-Ylcarbonyl)Pyrazin-2-Yl]oxy}-5-[(1S)-2-Methoxy-1-Methylethoxy]-N-(5-Methylpyrazin-2-Yl)Benzamide",
    ],
  },
  {
    primary_id: "D0O8BX",
    names: ["Ac-Cys-Ile-Tyr-Lys-Phe(4-CN)-Tyr"],
  },
  {
    primary_id: "D0O8DG",
    names: ["Benzothiazepine Analog 9"],
  },
  {
    primary_id: "D0O8DI",
    names: [
      "Nitrous Oxide",
      "Distickstoffmonoxid",
      "Lachgas",
      "Stickdioxyd",
      "Dinitrogen Monoxide",
      "Dinitrogen Oxide",
      "Factitious Air",
      "Gaz Hilarant",
      "Hyponitrous Acid Anhydride",
      "Laughing Gas",
      "Nitrogen Hypoxide",
      "Nitrogenium Oxydulatum",
      "Nitrous Oxide [JAN]",
      "Oxido Nitroso",
      "Oxido Nitroso [Spanish]",
      "Oxyde Nitreux",
      "Stickdioxyd [German]",
      "N2O",
      "Nitrous Oxide [UN1070] [Nonflammable Gas]",
      "Diazyne 1-Oxide",
      "Gas, Laughing",
      "NITROUS-OXIDE",
      "Nitrogen Oxide (N2O)",
      "Nitrous Oxide (TN)",
      "Nitrous Oxide [Anaesthetics, Volatile]",
      "Nitrous Oxide, Compressed",
      "Nitrous Oxide, Refrigerated Liquid",
      "Nitrous Oxide, Refrigerated Liquid [UN2201] [Nonflammable Gas]",
      "Oxide, Nitrous",
      "Protoxyde D'azote",
      "Protoxyde D'azote [French]",
      "Nitrous Oxide (JP15/USP)",
      "Oxidodinitrogen(N-N)",
      "Stickstoff(I)-Oxid",
    ],
  },
  {
    primary_id: "D0O8DL",
    names: ["Dihydroisoquinoline Carboxamide Analog 1"],
  },
  {
    primary_id: "D0O8DZ",
    names: ["3-[6-(Benzylmethylamino)Hexyloxy]xanthen-9-One", "CHEMBL390737"],
  },
  {
    primary_id: "D0O8EB",
    names: ["GARTANIN"],
  },
  {
    primary_id: "D0O8EH",
    names: ["NKTT-320"],
  },
  {
    primary_id: "D0O8EK",
    names: [
      "A-54145",
      "A-54145 A12",
      "CB-182462",
      "Daptomycin Analogs, Cubist",
      "Daptomycin Derivatives, Cubist",
      "Lipopeptide Program, Cubist",
    ],
  },
  {
    primary_id: "D0O8EX",
    names: ["PMID26651364-Compound-8c"],
  },
  {
    primary_id: "D0O8FI",
    names: ["1,3-Dihydroxy Phenyl Derivative 1"],
  },
  {
    primary_id: "D0O8FN",
    names: ["INDUS-815B"],
  },
  {
    primary_id: "D0O8FS",
    names: ["ISIS 3309"],
  },
  {
    primary_id: "D0O8FW",
    names: ["EPIANDROSTERONE"],
  },
  {
    primary_id: "D0O8GK",
    names: ["Osimertinib"],
  },
  {
    primary_id: "D0O8GZ",
    names: ["KI-0904"],
  },
  {
    primary_id: "D0O8HK",
    names: ["BCL-003"],
  },
  {
    primary_id: "D0O8HL",
    names: ["EML-16257", "CRE-16257", "EML-016257", "EML-257"],
  },
  {
    primary_id: "D0O8HW",
    names: ["FR-175985", "CHEMBL49085", "BDBM50290398", "L014760"],
  },
  {
    primary_id: "D0O8IC",
    names: ["JWH-309"],
  },
  {
    primary_id: "D0O8IE",
    names: [
      "D-Fucose",
      "3615-37-0",
      "(2R,3S,4S,5R)-2,3,4,5-Tetrahydroxyhexanal",
      "Aldehydo-D-Fucose",
      "D(+)-Fucose",
      "Galactose, 6-Deoxy-",
      "UNII-603I57Y449",
      "D-Galactose, 6-Deoxy-",
      "CHEBI:48203",
      "603I57Y449",
      "D(+)-Fucose, 98+%",
      "EINECS 222-792-9",
      "AC1L3RVE",
      "AC1Q6A6X",
      "SCHEMBL21210",
      "GTPL4722",
      "CHEMBL3343362",
      "CTK1C3165",
      "MolPort-006-115-507",
      "PNNNRSAQSRJVSB-DPYQTVNSSA-N",
      "D-(+)-Fucose, &gt",
      "ZINC2508196",
      "KS-000015WE",
      "ANW-43420",
      "AKOS015924852",
      "FCH3608570",
      "RTR-014858",
      "SC-11329",
      "AK163637",
      "AS-35297",
      "KB-251309",
      "AX8020434",
      "TR-014858",
      "FT-0624543",
      "ST24034026",
      "K-8105",
      "615D370",
    ],
  },
  {
    primary_id: "D0O8IS",
    names: [
      "Cloperastine",
      "Cloperastine Hydrochloride",
      "14984-68-0",
      "Seki",
      "Cloperastine HCl",
      "Cloperastina Cloridrato [Italian]",
      "EINECS 239-067-8",
      "1-(2-((4-Chlorophenyl)(Phenyl)Methoxy)Ethyl)Piperidine Hydrochloride",
      "2-Piperidinoethyl P-Chlorobenzhydryl Ether Hydrochloride",
      "1-(2-((P-Chloro-Alpha-Phenylbenzyl)Oxy)Ethyl)Piperidine Hydrochloride",
      "1-(2-(P-Cloro-Alpha-Fenilbenzilossi)Etil)Piperidina Cloridrato [Italian]",
      "DSSTox_RID_80863",
      "DSSTox_CID_25413",
      "DSSTox_GSID_45413",
      "Q-200881",
      "4-Chlorobenzhydryl 2-(1-Piperidyl)Ethyl Ether Hydrochloride",
    ],
  },
  {
    primary_id: "D0O8JB",
    names: ["Secretin"],
  },
  {
    primary_id: "D0O8JM",
    names: ["NX-210"],
  },
  {
    primary_id: "D0O8JQ",
    names: ["KNI-10256"],
  },
  {
    primary_id: "D0O8JW",
    names: ["Ronomilast", "ELB-353", "PDE4 Inhibitor, BioTie", "PDE4 Inhibitor, Elbion", "AWD-12-353"],
  },
  {
    primary_id: "D0O8KI",
    names: ["AL-43546"],
  },
  {
    primary_id: "D0O8KX",
    names: ["Reslizumab"],
  },
  {
    primary_id: "D0O8LK",
    names: ["3-[12-(Benzylmethylamino)Dodecyloxy]xanthen-9-One", "CHEMBL224555"],
  },
  {
    primary_id: "D0O8LR",
    names: ["[3H]GSK931145", "[3H]-GSK931145"],
  },
  {
    primary_id: "D0O8LW",
    names: [
      "CYANIDIN CHLORIDE MONOHYDRATE",
      "AC1OCETS",
      "Cyanidin Chloride Monohydrate",
      "SCHEMBL1230940",
      "2-(3,4-Dihydroxyphenyl)Chromenylium-3,5,7-Triol Chloride",
    ],
  },
  {
    primary_id: "D0O8MA",
    names: [
      "(+/-)-Threo-N-(3-Methylpyridine)Methylphenidate",
      "CHEMBL1255014",
      "BDBM50327127",
      "(AlphaR)-Alpha-[(2R)-1-(3-Pyridinylmethyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0O8MI",
    names: ["Peptide Analog 56"],
  },
  {
    primary_id: "D0O8MJ",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 7"],
  },
  {
    primary_id: "D0O8MU",
    names: [
      "3-Bicyclo[2.2.1]hept-2-Yl-Benzene-1,2-Diol",
      "1,2-Benzenediol,3-Bicyclo[2.2.1]hept-2-Yl-",
      "462606-73-1",
      "AC1MXF8P",
      "Oprea1_604715",
      "GTPL2569",
      "CTK8I7953",
      "AKOS027407656",
      "KB-148826",
      "3-(3-Bicyclo[2.2.1]heptanyl)Benzene-1,2-Diol",
    ],
  },
  {
    primary_id: "D0O8NR",
    names: ["Protein Hydrolysate", "Aminosol 5%", "Hyprotigen 5%"],
  },
  {
    primary_id: "D0O8OB",
    names: ["Isoxsuprine"],
  },
  {
    primary_id: "D0O8OF",
    names: ["D[Orn4,Lys8]VP", "CHEMBL375323"],
  },
  {
    primary_id: "D0O8PM",
    names: ["N,N-Bis(Cyanomethyl)-3,4-Dimethoxybenzamide"],
  },
  {
    primary_id: "D0O8PQ",
    names: [
      "[[4-(Aminomethyl)Phenyl]Amino]Oxo-Acetic Acid,",
      "[[4-(AMINOMETHYL)PHENYL]AMINO]OXO-ACETIC ACID,",
      "840454-25-3",
      "LO1",
      "Acetic Acid, 2-[[4-(Aminomethyl)Phenyl]amino]-2-Oxo-",
      "1wax",
      "2-[4-(Aminomethyl)Anilino]-2-Oxoacetic Acid",
      "AC1L9MR9",
      "SCHEMBL4317924",
      "CHEMBL426815",
      "CTK3D0907",
      "DTXSID60332298",
      "DB02420",
      "{[4-(Aminomethyl)Phenyl]carbamoyl}formic Acid",
      "{[4-(Aminomethyl)Phenyl]amino}(Oxo)Acetic Acid",
      "Acetic Acid, [[4-(Aminomethyl)Phenyl]amino]oxo-",
    ],
  },
  {
    primary_id: "D0O8PR",
    names: ["Biaromatic Compound 1"],
  },
  {
    primary_id: "D0O8QJ",
    names: ["(S)-Flurocarazolol", "GTPL66", "CHEMBL326418", "SCHEMBL14155316", "PDSP2_000794", "PDSP1_000807"],
  },
  {
    primary_id: "D0O8QM",
    names: ["2-N-Allylamino-1-(4-Methylthiophenyl)Propan", "CHEMBL1079254"],
  },
  {
    primary_id: "D0O8QS",
    names: ["4-Methoxy-N-(3-Phenyl-Isoquinolin-1-Yl)-Benzamide", "CHEMBL62075", "BDBM50097446"],
  },
  {
    primary_id: "D0O8QU",
    names: ["B7-2/GM-CSF Cancer Gene Therapy Vaccine"],
  },
  {
    primary_id: "D0O8RG",
    names: [
      "2-Phenyl-N-(1-Phenylethyl)Quinoline-4-Carboxamide",
      "CHEMBL274763",
      "AC1ME6SQ",
      "Oprea1_279909",
      "SCHEMBL4477684",
      "MolPort-001-501-804",
      "IEDWYQQEMKCMSQ-UHFFFAOYSA-N",
      "BDBM50074822",
      "STK047893",
      "AKOS022078440",
      "AKOS003267625",
      "MCULE-5320435270",
      "ST45002516",
      "2-Phenyl-N-(1-Phenylethyl)-4-Quinolinecarboxamide",
      "(2-Phenyl(4-Quinolyl))-N-(Phenylethyl)Carboxamide",
      "2-Phenyl-Quinoline-4-Carboxylic Acid (1-Phenyl-Ethyl)Amide",
      "2-Phenyl-Quinoline-4-Carboxylic Acid (1-Phenyl-Ethyl)-Amide",
    ],
  },
  {
    primary_id: "D0O8RL",
    names: ["EQ-1280", "11-Beta Hydroxysteroid Dehydrogenase 1 Inhibitor (Diabetes), Equispharm"],
  },
  {
    primary_id: "D0O8RQ",
    names: ["PMID29130358-Compound-Figure18(14)"],
  },
  {
    primary_id: "D0O8RZ",
    names: ["Indoline Derivative 17"],
  },
  {
    primary_id: "D0O8SB",
    names: ["OPK-0018"],
  },
  {
    primary_id: "D0O8SJ",
    names: ["TGR-1202"],
  },
  {
    primary_id: "D0O8SS",
    names: ["SX8"],
  },
  {
    primary_id: "D0O8SX",
    names: ["Pyrazolo[4,3-H]quinazoline-3-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0O8SY",
    names: ["GI-197111"],
  },
  {
    primary_id: "D0O8TE",
    names: ["LASSBio-1524"],
  },
  {
    primary_id: "D0O8TJ",
    names: [
      "AR101",
      "5-[[(2R)-2-Cyclopropyl-7,8-Dimethoxy-2H-Chromen-5-Yl]methyl]pyrimidine-2,4-Diamine",
      "(R)-Iclaprim",
      "CHEMBL1236788",
      "5-{[(2R)-2-Cyclopropyl-7,8-Dimethoxy-2H-Chromen-5-Yl]methyl}pyrimidine-2,4-Diamine",
      "SCHEMBL20138534",
      "CHEBI:131752",
      "XCF",
      "ZINC3612862",
      "BDBM50336513",
      "DB08741",
      "UNII-42445HUU0O Component HWJPWWYTGBZDEG-AWEZNQCLSA-N",
    ],
  },
  {
    primary_id: "D0O8TL",
    names: ["CBS-9106", "Apoptosis Inducer (Cancer), CanBas"],
  },
  {
    primary_id: "D0O8UB",
    names: ["RG7129"],
  },
  {
    primary_id: "D0O8UF",
    names: ["SRSS-023"],
  },
  {
    primary_id: "D0O8UM",
    names: ["XEL-002BG", "Analgesic (Gel, Pain), Xel Pharmaceuticals"],
  },
  {
    primary_id: "D0O8WH",
    names: ["Tecentriq"],
  },
  {
    primary_id: "D0O8WM",
    names: ["SP-003"],
  },
  {
    primary_id: "D0O8WT",
    names: ["Ro0711401", "Compound 14a [PMID: 19233648]"],
  },
  {
    primary_id: "D0O8WV",
    names: [
      "2-Propylamido-5-Sulfonamidoindane",
      "CHEMBL205743",
      "N-(5-Sulfamoyl-2,3-Dihydro-1H-Inden-2-Yl)Butanamide",
      "Indanesulfonamide Derivative 12e",
    ],
  },
  {
    primary_id: "D0O8XG",
    names: [
      "Acetic Acid 2-Hept-3-Ynylsulfanyl-Phenyl Ester",
      "CHEMBL145412",
      "ZINC13782091",
      "BDBM50068194",
      "Acetic Acid 2-(3-Heptynylthio)Phenyl Ester",
    ],
  },
  {
    primary_id: "D0O8XW",
    names: ["PMID17560788C29f"],
  },
  {
    primary_id: "D0O8YC",
    names: ["Low Dose Fentanyl"],
  },
  {
    primary_id: "D0O8YF",
    names: ["Oncoprev"],
  },
  {
    primary_id: "D0O8YO",
    names: ["Technetium Tc-99m Exametazime Kit", "Ceretec"],
  },
  {
    primary_id: "D0O8YZ",
    names: ["NSC-65069"],
  },
  {
    primary_id: "D0O8ZD",
    names: ["PMID25980951-Compound-7"],
  },
  {
    primary_id: "D0O8ZE",
    names: ["REV-5901"],
  },
  {
    primary_id: "D0O8ZV",
    names: [
      "Bilirubin",
      "635-65-4",
      "Hematoidin",
      "Hemetoidin",
      "Principal Bile Pigment",
      "UNII-RFM9X3LJ49",
      "Bilirubin IXalpha",
      "Bilirubin IX-Alpha",
      "EINECS 211-239-7",
      "NSC 26685",
      "BRN 0074376",
      "RFM9X3LJ49",
      "AI3-23078",
      "21H-Biline-8,12-Dipropanoic Acid, 2,17-Diethenyl-1,10,19,22,23,24-Hexahydro-3,7,13,18-Tetramethyl-1,19-Dioxo-",
      "CHEBI:16990",
      "BPYKTIZUTYGOLE-IFADSCNNSA-N",
      "PHEOPHYTIN",
      "MFCD00005499",
      "Bilirubin, 99%",
      "Biline-8,12-Dipropionic Acid, 1,10,19,22,23,24-Hexahydro-2,7,13,17-Tetramethyl-1,19-Dioxo-3,18-Divinyl-",
    ],
  },
  {
    primary_id: "D0O9AF",
    names: ["TKM-Ebola"],
  },
  {
    primary_id: "D0O9AZ",
    names: ["CCR5Qb"],
  },
  {
    primary_id: "D0O9BK",
    names: ["PMID28766366-Compound-Scheme9EHT6840"],
  },
  {
    primary_id: "D0O9BL",
    names: ["1-(3-Iodophenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One", "CHEMBL426304", "SCHEMBL4938442"],
  },
  {
    primary_id: "D0O9BS",
    names: [
      "S-2-(O-Toluidino)-2-Oxoethyl Carbamothioate",
      "5429/4/9",
      "Carbamoylsulfanyl-Acetic Acid O-Toluidide",
      "NSC13347",
      "AC1L5DGT",
      "AC1Q5NMD",
      "Oprea1_302154",
      "MLS000085728",
      "CHEMBL236685",
      "ZINC69645",
      "MolPort-002-702-780",
      "HMS2293B23",
      "STK795741",
      "Carbamothioic Acid, S-[2-[(2-Methylphenyl)Amino]-2-Oxoethyl] Ester",
      "NSC-13347",
      "AKOS001735942",
      "MCULE-9952660100",
      "SMR000020857",
      "EU-0051903",
      "SR-01000520769",
      "SR-01000520769-1",
      "S-[2-(2-Methylanilino)-2-Oxoethyl] Carbamothioate",
      "S-{2-[(2-Methylphenyl)Amino]-2-Oxoethyl} Thiocarbamate",
      "S-{2-[(2-Methylphenyl)Amino]-2-Oxoethyl} Carbamothioate",
      "A1844/0077558",
    ],
  },
  {
    primary_id: "D0O9BZ",
    names: [
      "4-(4-Benzyl-Piperazin-1-Yl)-1H-Indole",
      "CHEMBL328051",
      "Maybridge2_000682",
      "AC1MBNW0",
      "Oprea1_014756",
      "SCHEMBL2161609",
      "JLZIBIQTDGMAFZ-UHFFFAOYSA-N",
      "BDBM50071857",
      "ZINC53006914",
    ],
  },
  {
    primary_id: "D0O9CF",
    names: ["Fibrinogen, I-125"],
  },
  {
    primary_id: "D0O9CZ",
    names: ["BAY412272", "BAY 41-2272", "BAY-412272"],
  },
  {
    primary_id: "D0O9DH",
    names: ["Oximidine I"],
  },
  {
    primary_id: "D0O9DK",
    names: ["PF-05280586"],
  },
  {
    primary_id: "D0O9DS",
    names: ["Pyrazole Derivative 81"],
  },
  {
    primary_id: "D0O9EB",
    names: ["3-(Butylsulfinyl)-1,1,1-Trifluoropropan-2-One", "CHEMBL403288"],
  },
  {
    primary_id: "D0O9EM",
    names: ["Eptapirone"],
  },
  {
    primary_id: "D0O9EQ",
    names: ["VX-210", "BA-210", "VX210"],
  },
  {
    primary_id: "D0O9ES",
    names: ["Cloprostenol"],
  },
  {
    primary_id: "D0O9EX",
    names: ["NANT Merkel Cell Cancer Vaccine"],
  },
  {
    primary_id: "D0O9FA",
    names: ["[Mpa1, D-Tyr(Et)2, Aib7, D-Tic9]OT", "CHEMBL233080"],
  },
  {
    primary_id: "D0O9FC",
    names: ["4-Carboxamido-Isoindolinone Derivative 1"],
  },
  {
    primary_id: "D0O9FE",
    names: ["Combo With MEKi"],
  },
  {
    primary_id: "D0O9FH",
    names: ["PUMVC3-HIGFBP-2"],
  },
  {
    primary_id: "D0O9FL",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp24-]", "CHEMBL437072"],
  },
  {
    primary_id: "D0O9FW",
    names: ["Protein A Mimetics"],
  },
  {
    primary_id: "D0O9GI",
    names: ["PMID25666693-Compound-63"],
  },
  {
    primary_id: "D0O9GJ",
    names: ["Alphabeta-MethyleneADP"],
  },
  {
    primary_id: "D0O9HF",
    names: ["TrasGEX"],
  },
  {
    primary_id: "D0O9HG",
    names: ["BAY-1006578", "Diagnostic PET Imaging Agent (Alzheimer's Disease), Bayer"],
  },
  {
    primary_id: "D0O9HS",
    names: [
      "1-(2-Chloro-Phenyl)-Piperazine",
      "1-(2-Chlorophenyl)Piperazine",
      "39512-50-0",
      "Piperazine, 1-(2-Chlorophenyl)-",
      "CHEMBL9841",
      "1-(2-Chlorophenyl) Piperazine",
      "PWZDJIUQHUGFRJ-UHFFFAOYSA-N",
      "1-(O-CHLOROPHENYL)PIPERAZINE",
      "(2-Chlorophenyl)Piperazine",
      "Chlorphenylpiperazin",
      "EINECS 254-480-3",
      "PubChem14571",
      "AC1Q3KEP",
      "ACMC-1AIF1",
      "Ortho-Chlorophenylpiperazine",
      "AC1L9A0W",
      "DSSTox_RID_97408",
      "DSSTox_CID_31523",
      "N-(O-Chlorophenyl)Piperazine",
      "DSSTox_GSID_57734",
      "N-(2-Chlorophenyl)Piperazine",
      "KSC224M6P",
      "SCHEMBL230424",
      "N-(O-Chlorophenyl)-Piperazine",
    ],
  },
  {
    primary_id: "D0O9IB",
    names: [
      "CI-933",
      "91829-95-7",
      "2-Pyrrolidinepropanoic Acid, 1-(4-Methoxybenzoyl)-5-Oxo-",
      "AC1L3YHP",
      "AC1Q5EHW",
      "SCHEMBL194483",
      "CTK3I7861",
      "1-(4-Methoxybenzoyl)-5-Oxo-2-Pyrrolidinepropanoic Acid",
      "2-Pyrrolidinepropanoicacid, 1-(4-Methoxybenzoyl)-5-Oxo-",
      "3-[1-(4-Methoxybenzoyl)-5-Oxopyrrolidin-2-Yl]propanoic Acid",
    ],
  },
  {
    primary_id: "D0O9II",
    names: [
      "[1,3]Thiazinan-(2E)-Ylideneamine",
      "2-Amino-5,6-Dihydro-4H-1,3-Thiazine",
      "5,6-Dihydro-4H-1,3-Thiazin-2-Amine",
      "30480-64-9",
      "CHEMBL306541",
      "2-Iminotetrahydro-1,3-Thiazine",
      "AC1Q4UKS",
      "AC1L4O0Q",
      "SCHEMBL30950",
      "2799-75-9 (Hydrobromide)",
      "CTK4G5273",
      "DTXSID60184552",
      "[1,3]Thiazinan-(2Z)-Ylideneamine",
      "BDBM50049250",
      "ZINC17744492",
      "FCH991782",
      "AKOS006347653",
      "AKOS003362396",
      "4H-1,3-Thiazin-2-Amine,5,6-Dihydro-",
      "EN300-203949",
      "3,4,5,6-Tetrahydro-2H-1,3-Thiazin-2-Imine",
    ],
  },
  {
    primary_id: "D0O9IS",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 8"],
  },
  {
    primary_id: "D0O9JD",
    names: ["Benzo(B)Carbazol-11-One Compound 2"],
  },
  {
    primary_id: "D0O9JI",
    names: ["Transferrin CRM-107"],
  },
  {
    primary_id: "D0O9JX",
    names: ["SC-58451"],
  },
  {
    primary_id: "D0O9JZ",
    names: ["PMID26560530-Compound-49"],
  },
  {
    primary_id: "D0O9KA",
    names: ["W-84"],
  },
  {
    primary_id: "D0O9KV",
    names: [
      "6-(2-Bromo-Benzenesulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL74579",
      "SCHEMBL6206670",
      "BDBM16445",
      "6-Phenylsulfonylpyridazin-2H-3-One, 7",
      "Phenyl-Substituted Sulfonylpyridazinone, 8a",
    ],
  },
  {
    primary_id: "D0O9KW",
    names: ["Diphtheria/Tetanus Vaccine"],
  },
  {
    primary_id: "D0O9KY",
    names: ["KI-0401"],
  },
  {
    primary_id: "D0O9LN",
    names: ["ME-143"],
  },
  {
    primary_id: "D0O9LR",
    names: [
      "4-[3-(4-Butyl-Phenoxy)-Propyl]-1H-Imidazole",
      "CHEMBL419654",
      "SCHEMBL7991257",
      "NKIFZJYIQSBUEI-UHFFFAOYSA-N",
      "BDBM50093221",
      "[3-(4-Imidazolyl)Propyl](4-Butylphenyl) Ether",
      "4-Butylphenyl 3-(1H-Imidazol-4-Yl)Propyl Ether",
    ],
  },
  {
    primary_id: "D0O9MG",
    names: [
      "L-655708",
      "130477-52-0",
      "CHEMBL52030",
      "NCGC00025115-02",
      "Ethyl (S)-11,12,13,13a-Tetrahydro-7-Methoxy-9-Oxo-9H-Imidazo[1,5-A]pyrrolo[2,1-C][1,4]benzodiazepine-1-Carboxylate",
      "Lopac0_000700",
      "SCHEMBL5533646",
      "YKYOQIXTECBVBB-AWEZNQCLSA-N",
      "MolPort-003-983-605",
      "HMS3262K21",
      "ZINC2584032",
      "Tox21_500700",
      "MFCD02684528",
      "BN0287",
      "BDBM50067424",
      "AKOS024456530",
      "FG-8094",
      "API0007742",
      "LP00700",
      "CCG-204785",
      "CS-4943",
      "NCGC00261385-01",
      "NCGC00025115-04",
      "NCGC00025115-03",
      "HY-14426",
      "KB-78047",
      "EU-0100700",
      "L-655,708, &gt",
    ],
  },
  {
    primary_id: "D0O9MX",
    names: [
      "Influenza Vaccine",
      "Anflu",
      "Influenza Vaccine (Split Virion)",
      "Influenza Vaccine (Split Virion), Sinovac",
    ],
  },
  {
    primary_id: "D0O9NE",
    names: [
      "TRISMETHOXYRESVERATROL",
      "22255-22-7",
      "Trans-Trimethoxyresveratrol",
      "(E)-1,3-Dimethoxy-5-(4-Methoxystyryl)Benzene",
      "(E)-3,5,4'-Trimethoxystilbene",
      "3,4',5-Trimethoxy-Trans-Stilbene",
      "3,4',5-Trimethoxystilbene",
      "3,5,4'-Trimethoxystilbene",
      "TRIMETHOXYSTILBENE",
      "E-Resveratrol Trimethyl Ether",
      "CHEMBL296411",
      "1,3-Dimethoxy-5-[(E)-2-(4-Methoxyphenyl)Ethenyl]benzene",
      "Trans-3,4',5-Trimethoxystilbene",
      "GDHNBPHYVRHYCC-SNAWJCMRSA-N",
      "(E)-3,4',5-Trimethoxystilbene",
      "5-[2-(4-Methoxyphenyl)Ethenyl]-1,3-Dimethoxy Benzene",
    ],
  },
  {
    primary_id: "D0O9OB",
    names: ["7-Propoxy-2-(Morpholin-4-Yl)-Chromen-4-One", "CHEMBL426099", "SCHEMBL3537201"],
  },
  {
    primary_id: "D0O9OC",
    names: ["AZD5658"],
  },
  {
    primary_id: "D0O9OS",
    names: ["MK-3222/SCH 900222"],
  },
  {
    primary_id: "D0O9OW",
    names: ["N-Isobutylnorlitebamine"],
  },
  {
    primary_id: "D0O9PQ",
    names: [
      "AP26113",
      "1197958-12-5",
      "ALK-IN-1",
      "UNII-3DGD69C6PV",
      "3DGD69C6PV",
      "CHEMBL3397300",
      "(2-((5-Chloro-2-((4-(4-(Dimethylamino)Piperidin-1-Yl)-2-Methoxyphenyl)Amino)Pyrimidin-4-Yl)Amino)Phenyl)Dimethylphosphine Oxide",
      "5-Chloro-N~2~-{4-[4-(Dimethylamino)Piperidin-1-Yl]-2-Methoxyphenyl}-N~4~-[2-(Dimethylphosphoryl)Phenyl]pyrimidine-2,4-Diamine",
      "5-Chloro-2-N-[4-[4-(Dimethylamino)Piperidin-1-Yl]-2-Methoxyphenyl]-4-N-(2-Dimethylphosphorylphenyl)Pyrimidine-2,4-Diamine",
      "AP26113-Analog",
      "Tube723",
      "Compound 11q [PMID: 27144831]",
    ],
  },
  {
    primary_id: "D0O9QG",
    names: [
      "S-(4-Chlorobenzyl)Isothiourea Hydrochloride",
      "544-47-8",
      "UNII-UG8Z59JOWY",
      "2-(4-Chlorobenzyl)-2-Thiopseudourea Hydrochloride",
      "S-(4-Chlorobenzyl)Isothiouronium Chloride",
      "4-CHLOROBENZYL CARBAMIMIDOTHIOATE HYDROCHLORIDE",
      "2-(P-Chlorobenzyl)Isothiouronium Chloride",
      "P-Chlorobenzylpseudothiuronium Chloride",
      "EINECS 208-871-0",
      "UG8Z59JOWY",
      "Carbamimidothioic Acid, (4-Chlorophenyl)Methyl Ester, Monohydrochloride",
      "4-Chlorobenzyl Imidothiocarbamate Hydrochloride",
      "S-(P-Chlorobenzyl)-Thiuronium Chloride",
      "4-CHLOROBENZYL CARBAMIMIDOTHIOATE HCL",
      "Pseudourea, 2-(P-Chlorobenzyl)-2-Thio-,",
    ],
  },
  {
    primary_id: "D0O9RD",
    names: ["(S)-2-Amino-1-Azetidin-1-Yl-2-Cyclohexyl-Ethanone", "CHEMBL194258"],
  },
  {
    primary_id: "D0O9RJ",
    names: ["CDX-011"],
  },
  {
    primary_id: "D0O9RV",
    names: ["MOD-5023"],
  },
  {
    primary_id: "D0O9SG",
    names: ["2-(4-Chlorophenyl)-5-Phenyl-4-Isoxazolin-3-One", "CHEMBL203853"],
  },
  {
    primary_id: "D0O9TL",
    names: ["PMID25435285-Compound-52"],
  },
  {
    primary_id: "D0O9TT",
    names: ["LY344864"],
  },
  {
    primary_id: "D0O9UC",
    names: [
      "Tak-220",
      "AIDS228341",
      "TAK220",
      "TBR220",
      "TBR-220",
      "1-Acetyl-N-[3-[4-[(4-Carbamoylphenyl)Methyl]piperidin-1-Yl]propyl]-N-(3-Chloro-4-Methylphenyl)Piperidine-4-Carboxamide",
    ],
  },
  {
    primary_id: "D0O9UG",
    names: ["ZD-0490"],
  },
  {
    primary_id: "D0O9UH",
    names: ["CACA", "Cis-Aminocrotonic Acid", "4-Aminocrotonic Acid"],
  },
  {
    primary_id: "D0O9UI",
    names: [
      "2-(2,4-Dichlorophenoxy)-5-Methylphenol",
      "Triclosan Derivative, 2",
      "CHEMBL258569",
      "SCHEMBL12286177",
      "BDBM25401",
    ],
  },
  {
    primary_id: "D0O9UN",
    names: ["SAR231893"],
  },
  {
    primary_id: "D0O9UR",
    names: [
      "2-Methoxy-4-(2-Nitrovinyl)Phenol",
      "6178-42-3",
      "2-Methoxy-4-[(E)-2-Nitroethenyl]phenol",
      "1-(4-HYDROXY-3-METHOXYPHENYL)-2-NITROETHENE",
      "(E)-2-Methoxy-4-(2-Nitrovinyl)Phenol",
      "Trans-4-Hydroxy-3-Methoxy-Beta-Nitrostyrene",
      "2-Methoxy-4-[(E)-2-Nitrovinyl]phenol",
      "CHEMBL309016",
      "22568-51-0",
      "AF-467/00017042",
      "2-Methoxy-4-(2-Nitroethenyl)Phenol",
      "2-Methoxy-4-(2-Nitro-Vinyl)-Phenol",
      "4-((1E)-2-Nitrovinyl)-2-Methoxyphenol",
      "4-{2-Nitrovinyl}-2-Methoxyphenol",
      "4-Hydroxy-3-Methoxy-Beta-Nitrostyrene",
      "AC1LCWDN",
      "PubChem19447",
      "AC1Q1WR9",
      "MLS000574924",
    ],
  },
  {
    primary_id: "D0O9UY",
    names: [
      "3-(4-Hydroxyphenyl)-7-Isopropoxychromen-4-One",
      "CHEMBL115109",
      "97846-18-9",
      "4H-1-Benzopyran-4-One, 3-(4-Hydroxyphenyl)-7-(1-Methylethoxy)-",
      "ACMC-20m1ro",
      "SCHEMBL1701110",
      "CTK3G8070",
      "DTXSID70440494",
      "7-Isopropoxy-4'-Hydroxyisoflavone",
      "BDBM50104874",
      "ZINC13864225",
      "AKOS030553087",
      "3-(4-Hydroxy-Phenyl)-7-Isopropoxy-Chromen-4-One",
    ],
  },
  {
    primary_id: "D0O9UZ",
    names: ["Alfimeprase"],
  },
  {
    primary_id: "D0O9VU",
    names: ["4-(2-Phenylacetamido)-3-Fluorobenzenesulfonamide"],
  },
  {
    primary_id: "D0O9VW",
    names: ["Lanperisone"],
  },
  {
    primary_id: "D0O9WA",
    names: ["PMID28766366-Compound-Scheme23MPPDerivatives"],
  },
  {
    primary_id: "D0O9WG",
    names: ["CSNLSTCVLGKLSQELHKLQTYPRTNTGSGTP-Amide"],
  },
  {
    primary_id: "D0O9WQ",
    names: ["Pyrrolo-Pyrrolone Derivative 6"],
  },
  {
    primary_id: "D0O9WS",
    names: ["Pyridine-2,4-Dicarboxylic Acid Analog 1"],
  },
  {
    primary_id: "D0O9XE",
    names: ["ISIS 13743"],
  },
  {
    primary_id: "D0O9XN",
    names: ["APD-597"],
  },
  {
    primary_id: "D0O9XO",
    names: ["KH-902"],
  },
  {
    primary_id: "D0O9YG",
    names: [
      "Cycloguanil",
      "Cycloguanyl",
      "Cycloguanilum",
      "516-21-2",
      "Chlorcycloguanil",
      "Chlorguanide Triazine",
      "UNII-26RM326WVN",
      "BN2410",
      "WR 5473",
      "BN-24-10",
      "CHEMBL747",
      "BRN 0229760",
      "1-(4-Chlorophenyl)-6,6-Dimethyl-1,6-Dihydro-1,3,5-Triazine-2,4-Diamine",
      "26RM326WVN",
      "QMNFFXRFOJIOKZ-UHFFFAOYSA-N",
      "1-(P-Chlorophenyl)-4,6-Diamino-2,2-Dimethyl-1,2-Dihydro-S-Triazine",
      "1-(4-Chlorophenyl)-6,6-Dimethyl-1,3,5-Triazine-2,4-Diamine",
      "1-P-Chlorophenyl-1,2-Dihydro-2,2-Dimethyl-4,6-Diamino-S-Triazine",
    ],
  },
  {
    primary_id: "D0O9YJ",
    names: ["PMID29865878-Compound-47"],
  },
  {
    primary_id: "D0O9YL",
    names: ["(R)-1-(2-Methoxyphenethyl)-2-Methylpyrrolidine", "CHEMBL404375"],
  },
  {
    primary_id: "D0O9YQ",
    names: ["N-N-Hexyl-7-Methoxytacrine Hydrochloride", "CHEMBL1257885"],
  },
  {
    primary_id: "D0O9ZT",
    names: ["Ebola Vaccine"],
  },
  {
    primary_id: "D0O9ZY",
    names: ["RO5166017"],
  },
  {
    primary_id: "D0OA0H",
    names: ["INO-3112"],
  },
  {
    primary_id: "D0OA1P",
    names: ["PMID25666693-Compound-53"],
  },
  {
    primary_id: "D0OA3L",
    names: [
      "VU0469650",
      "3-[(3R)-3-Methyl-4-[(4-Methyladamantan-1-Yl)Carbonyl]piperazin-1-Yl]pyridine-2-Carbonitrile",
      "GTPL6337",
    ],
  },
  {
    primary_id: "D0OA5G",
    names: ["SNS-314"],
  },
  {
    primary_id: "D0OA6W",
    names: ["Aryl Piperazine Derivative 8"],
  },
  {
    primary_id: "D0OA7W",
    names: [
      "HG-1141",
      "CASH Modulator (Cancer), Human Genome Sciences",
      "CASPER Modulator (Cancer), Human Genome Sciences",
      "CD 95 Induced Apoptosis Modulator (Cancer), Human Genome Sciences",
      "CLARP Modulator (Cancer), Human Genome Sciences",
      "Caspase Homolog Modulator (Cancer), Human Genome Sciences",
      "FLAME1 Modulator (Cancer), Human Genome Sciences",
      "FLICE Inhibitory Protein Modulator (Cancer), Human Genome Sciences",
      "FLIP Modulator (Cancer), Human Genome Sciences",
      "Inhibitor Of FLICE (Cancer), Human Genome Sciences",
      "MRIT Modulator (Cancer), Human Genome Sciences",
      "Caspase-Like Apoptosis Regulatory Protein Modulator (Cancer), Human Genome Sciences",
      "Fadd-Like Antiapoptotic Molecule 1 Modulator (Cancer), Human Genome Sciences",
      "I-FLICE Modulator (Cancer),Human Genome Sciences",
      "Mach-Relayted Inducer Of Toxicity Modulator (Cancer), Human Genome Sciences",
      "Caspase-Eight-Related Protein Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0OA8M",
    names: ["SC-19220"],
  },
  {
    primary_id: "D0OB0F",
    names: ["Bosutinib"],
  },
  {
    primary_id: "D0OB0M",
    names: ["Cyclo[Ac-Cys-Ile-Tyr-Lys-Tyr-Phe]", "CHEMBL380288"],
  },
  {
    primary_id: "D0OB0Q",
    names: ["Quinoline Carboxamide Derivative 6"],
  },
  {
    primary_id: "D0OB1F",
    names: [
      "Inositol 3-Phosphate",
      "1D-Myo-Inositol 3-Phosphate",
      "D-Myo-Inositol 3-Phosphate",
      "1L-Myo-Inositol 1-Phosphate",
      "D-Myo-Inositol 3-Monophosphate",
      "1D-Myo-Inositol 3-Monophosphate",
      "Inositol 3-Monophosphate",
      "Myo-Inositol 1-Phosphate",
      "Myo-Inositol 3-Phosphate",
      "Myo-Inositol 3-Monophosphate",
      "Myoinositol 3-Phosphate",
      "2831-74-5",
      "Myo-Inositol-1-Phosphate",
      "SCHEMBL7538",
      "Inositol-1-Phosphate, Myo-",
      "Myo-Inositol 1-Monophosphate",
      "DL-Myo-Inositol 1-Phosphate",
      "Myo-Inositol 3-Phosphoric Acid",
    ],
  },
  {
    primary_id: "D0OB1J",
    names: [
      "Diltiazem",
      "Acalix",
      "Adizem",
      "Aldizem",
      "Anoheal",
      "Cardil",
      "Cardizem",
      "Citizem",
      "Dilacor",
      "Dilcontin",
      "Dilren",
      "Diltia",
      "Diltiazemum",
      "Dilticard",
      "Diltzac",
      "Dilzen",
      "Endrydil",
      "Viazem",
      "Cardizen LA",
      "Incoril AP",
      "Tiazac Tildiem",
      "Tiazac XC",
      "Adizem (TN)",
      "Altiazem (TN)",
      "Angiozem (TN)",
      "Angizem (TN)",
      "Angizem CD (TN)",
      "Apo-Diltiaz",
      "Cardizem (Hydrochloride)",
      "Cardizem (TN)",
      "Cartia XT (TN)",
      "Dilacor-XR",
      "Dilatam (TN)",
      "Dilatem (TN)",
      "Dilcardia (TN)",
      "Dilcontin SR (TN)",
      "Dilt-Cd",
      "Dilta-Hexal",
      "Diltelan (TN)",
      "Diltiazem (INN)",
      "Diltiazem [INN:BAN]",
      "Diltiazemum [INN-Latin]",
      "Diltime (TN)",
      "Dilzem (TN)",
      "Dyalec (TN)",
      "Filazem (TN)",
      "Herben (TN)",
      "Nu-Diltiaz",
      "Progor (TN)",
      "RG 83606 (Hydrochloride)",
      "Surazem (TN)",
      "Tiamate (TN)",
      "Tiazac (TN)",
      "Tiazac XC (TN)",
      "Tildiem (TN)",
      "Vasmulax (TN)",
      "Viazem (TN)",
      "Zandil (TN)",
      "Zemtrial (TN)",
      "D-Cis-Diltiazem",
      "MK-793 (Malate)",
      "[(2S,3S)-5-(2-Dimethylaminoethyl)-2-(4-Methoxyphenyl)-4-Oxo-2,3-Dihydro-1,5-Benzothiazepin-3-Yl] Acetate",
      "Acetic Acid (2S,3S)-5-(2-Dimethylamino-Ethyl)-2-(4-Methoxy-Phenyl)-4-Oxo-2,3,4,5-Tetrahydro-Benzo[b][1,4]thiazepin-3-Yl Ester",
      "(+)-Cis-5-[2-(Dimethylamino)Ethyl]-2,3-Dihydro-3-Hydroxy-2-(P-Methoxyphenyl)-1,5-Benzothiazepin-4(5H)-One Acetate Ester",
      "(2S,3S)-5-(2-(Dimethylamino)Ethyl)-2-(4-Methoxyphenyl)-4-Oxo-2,3,4,5-Tetrahydrobenzo[b][1,4]thiazepin-3-Yl Acetate",
      "(2S,3S)-5-[2-(Dimethylamino)Ethyl]-2-(4-Methoxyphenyl)-4-Oxo-2,3,4,5-Tetrahydro-1,5-Benzothiazepin-3-Yl Acetate",
      "(2S,3S)-5-[2-(Dimethylamino)Ethyl]-2-[4-(Methyloxy)Phenyl]-4-Oxo-2,3,4,5-Tetrahydro-1,5-Benzothiazepin-3-Yl Acetate",
      "(2S-Cis)-3-(Acetyloxy)-5-[2-(Dimethylamino)Ethyl]-2,3-Dihydro-2-(4-Methoxyphenyl)-1,5-Benzothiazepin-4(5H)-One",
    ],
  },
  {
    primary_id: "D0OB2M",
    names: ["Mivacurium"],
  },
  {
    primary_id: "D0OB4R",
    names: ["EVP-0962"],
  },
  {
    primary_id: "D0OB7B",
    names: ["2-(1,4'-Bipiperidin-1'-Yl)Thiazolo[4,5-B]pyridine", "CHEMBL583693", "SCHEMBL12041243", "BDBM50299006"],
  },
  {
    primary_id: "D0OB7J",
    names: ["NPC-15669"],
  },
  {
    primary_id: "D0OB9V",
    names: ["Ginkgo Biloba Extract"],
  },
  {
    primary_id: "D0OC0W",
    names: ["AVID100"],
  },
  {
    primary_id: "D0OC1S",
    names: ["Pyrido[2,3-D]pyrimidine-2,4-Diamine Derivative 1"],
  },
  {
    primary_id: "D0OC1W",
    names: ["Sarafotoxin S6b"],
  },
  {
    primary_id: "D0OC3J",
    names: [
      "Flupenthixol",
      "Depixol",
      "Emergil",
      "Fluanxol",
      "Fluxanxol",
      "Siplaril",
      "Siplarol",
      "LC 44",
      "N 7009",
      "ALPHA-FLUPENTHIXOL",
      "Cis(Z)Flupenthixol",
      "Cis-Flupenthixol",
      "Cis-Flupentixol",
      "Depixol (TN)",
      "Fluanxol (TN)",
      "Flupentixol (INN)",
      "Flupentixol [INN:DCF]",
      "Flupentixolum [INN-Latin]",
      "Cis-(Z)-Flupenthixol",
      "(Z)-4-(3-(2-(Trifluoromethyl)-9H-Thioxanthen-9-Ylidene)Propyl)-1-Piperazineethanol",
      "(Z)-4-(3-(2-(Trifluoromethyl)-9H-Thioxanthen-9-Ylidene)Propyl)Piperazine-1-Ethanol",
      "1-Piperazineethanol, 4-(3-(2-(Trifluoromethyl)-9H-Thioxanthen-9-Ylidene)Propyl)-(9CI)",
      "2-Trifluoromethyl-9-(3-(4-(2-Hydroxyethyl)Piperazin-1-Yl)Propylidene)Thioxanthene",
      "2-Trifluoromethyl-9-(3-(4-(Beta-Hydroxyethyl)-1-Piperazinyl)Propylidene)Thioxanthene",
      "2-[4-[(3Z)-3-[2-(Trifluoromethyl)Thioxanthen-9-Ylidene]propyl]piperazin-1-Yl]ethanol",
      "4-(3-(2-(Trifluoromethyl)-9H-Thioxanthen-9-Ylidene)Propyl)-1-Piperazineethanol",
      "4-(3-(2-(Trifluoromethyl)Thioxanthen-9-Ylidene)Propyl)-1-Piperazineethanol",
    ],
  },
  {
    primary_id: "D0OC3P",
    names: ["Prenyl Pyrophosphate Analogue"],
  },
  {
    primary_id: "D0OC4B",
    names: [
      "7-Hydroxy-4-Phenylcoumarin",
      "2555-30-8",
      "7-Hydroxy-4-Phenyl-2H-Chromen-2-One",
      "7-Hydroxy-4-Phenylchromen-2-One",
      "NSC78474",
      "4-Phenyl-7-Hydroxycoumarin",
      "UNII-L0487H6Q0H",
      "CHEMBL325841",
      "L0487H6Q0H",
      "7-Hydroxy-4-Phenyl-Chromen-2-One",
      "7-Oxidanyl-4-Phenyl-Chromen-2-One",
      "NSC-78474",
      "2H-1-Benzopyran-2-One,7-Hydroxy-4-Phenyl-",
      "2H-1-Benzopyran-2-One, 7-Hydroxy-4-Phenyl-",
      "4-Phenylumbelliferone",
      "PubChem8691",
      "Coumarin Derivative, 3c",
      "AC1NSAV1",
      "7-Hydroxy4-Phenylcoumarin",
      "Oprea1_774373",
      "7-Hydroxy-4-Phenyl Coumarin",
      "MLS001143868",
      "SCHEMBL2987658",
    ],
  },
  {
    primary_id: "D0OC4O",
    names: ["RG7388"],
  },
  {
    primary_id: "D0OC5C",
    names: ["ICAR19 CAR-T Cells"],
  },
  {
    primary_id: "D0OC5T",
    names: ["ANX005"],
  },
  {
    primary_id: "D0OC6A",
    names: ["PMID25666693-Compound-75"],
  },
  {
    primary_id: "D0OC6H",
    names: ["S-024", "Chk1 Inhibitors (Cancer)", "S-144", "Chk1 Inhibitors (Cancer), Sentinel"],
  },
  {
    primary_id: "D0OD2I",
    names: ["BLU-667"],
  },
  {
    primary_id: "D0OD3T",
    names: ["Meningococcal ACYW-135 Vaccine"],
  },
  {
    primary_id: "D0OD3U",
    names: [
      "3-(Trifluoromethyl)Phenylboronic Acid",
      "1423-26-3",
      "3-Trifluoromethylphenylboronic Acid",
      "(3-(Trifluoromethyl)Phenyl)Boronic Acid",
      "[3-(Trifluoromethyl)Phenyl]boronic Acid",
      "3-(Trifluoromethyl)Benzeneboronic Acid",
      "3-(Trifluoromethyl)Phenylbornic Acid",
      "M-(Trifluoromethyl)Phenylboronic Acid",
      "CHEMBL336239",
      "3-Trifluoromethylphenyl Boronic Acid",
      "MFCD00151854",
      "[3-(Trifluoromethyl)Phenyl]Boranediol",
      "3-(Trifluoromethyl)Benzene Boronic Acid",
      "Boronic Acid, [3-(Trifluoromethyl)Phenyl]-",
      "Boronic Acid, B-[3-(Trifluoromethyl)Phenyl]-",
    ],
  },
  {
    primary_id: "D0OD8N",
    names: ["1-Methoxy-2-[2-(2-Methoxy-Ethoxy]-Ethane"],
  },
  {
    primary_id: "D0OD8T",
    names: ["CD19 CAR T Cells"],
  },
  {
    primary_id: "D0OE0D",
    names: ["PMID27215781-Compound-33"],
  },
  {
    primary_id: "D0OE0K",
    names: [
      "3-Tetrazol-2-Yl-1-Aza-Bicyclo[2.2.2]octane",
      "CHEMBL23563",
      "SCHEMBL8430767",
      "AMJXIOSRIOJUEM-UHFFFAOYSA-N",
      "BDBM50229667",
    ],
  },
  {
    primary_id: "D0OE1R",
    names: [
      "(3,4-DIHYDROXY-2-NITROPHENYL)(PHENYL)METHANONE",
      "BIE",
      "AC1OCAA0",
      "SCHEMBL5898857",
      "ICLKAUQIPVFHOI-UHFFFAOYSA-N",
      "3,4-Dihydroxy-2-Nitrobenzophenone",
      "4-Benzoyl-3-Nitrobenzene-1,2-Diol",
      "BIA-8-176",
      "ZINC13677656",
      "DB07462",
      "(3,4-Dihydroxy-2-Nitrophenyl)Phenylmethanone",
    ],
  },
  {
    primary_id: "D0OE2G",
    names: ["Fsn-1006"],
  },
  {
    primary_id: "D0OE2Z",
    names: [
      "ONO-8809",
      "[1S-(1alpha,2beta(Z),3beta,4alpha)]-6-[3-[[[(4-Bromophenyl)Sulfonyl]amino]methyl]bicyclo[2.2.1]hept-2-Yl]-5-Hexenoic Acid Decyl Ester",
      "(Z)-6-[(1S,2S,3S,4R)-3-(4-Bromobenzenesulfonylaminomethyl)Bicyclo[2.2.1]hept-2-Yl]-5-Hexenoic Acid N-Decyl Ester",
    ],
  },
  {
    primary_id: "D0OE4F",
    names: ["Imatinib And Nilotinib Derivative 1"],
  },
  {
    primary_id: "D0OE5N",
    names: [
      "5-{8(Z),-Pentadecenyl}resorcinol",
      "Bilobol",
      "5-[(8Z)-Pentadec-8-En-1-Yl]benzene-1,3-Diol",
      "5-[(8Z)-Pentadec-8-Enyl]resorcinol",
      "CHEBI:3104",
      "CHEMBL461628",
      "5-(8Z-Pentadecenyl)Resorcinol",
      "22910-86-7",
      "5-[(Z)-Pentadec-8-Enyl]benzene-1,3-Diol",
      "(Z)-5-(Pentadec-8-En-1-Yl)Benzene-1,3-Diol",
      "Trifurcatol A2",
      "Cardol Monoene",
      "Bilobol C15:1",
      "AC1NQZ6L",
      "21:1-.omega.7-Cardol",
      "SCHEMBL164545",
      "5-(8'Z-Heptadecenyl)Resorcinol",
      "DTXSID90872874",
      "TUGAUFMQYWZJAB-FPLPWBNLSA-N",
      "MolPort-005-945-917",
      "ZINC8234356",
      "5-[8'(Z)-Pentadecenyl]Resorcinol",
    ],
  },
  {
    primary_id: "D0OE5S",
    names: ["UE-2343"],
  },
  {
    primary_id: "D0OE6G",
    names: ["Isochroman-4-Ketone Derivative 1"],
  },
  {
    primary_id: "D0OE9W",
    names: ["Alpha-Substituted Pirinixic Acid And Pirinixic Acid Ester Derivative 1"],
  },
  {
    primary_id: "D0OF1I",
    names: ["8-Methoxy-2-(4-Nitro-Phenyl)-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0OF1R",
    names: ["Oportuzumab Monatox"],
  },
  {
    primary_id: "D0OF1X",
    names: ["PMID8709131C15"],
  },
  {
    primary_id: "D0OF2J",
    names: [
      "Anti-BrP-LPA",
      "CHEMBL3621356",
      "GTPL6987",
      "BDBM50187696",
      "[(1S,3S)-1-Bromo-4-Hexadecanoyloxy-3-Hydroxybutyl]phosphonic Acid",
      "Hexadecanoic Acid (2S,4S)-2-Hydroxy-4-Bromo-4-(Dihydroxyphosphinyl)Butyl Ester",
    ],
  },
  {
    primary_id: "D0OF2K",
    names: ["Rh-Thrombomodulin"],
  },
  {
    primary_id: "D0OF3B",
    names: ["Xenin"],
  },
  {
    primary_id: "D0OF3M",
    names: [
      "Sar-Arg-Val-Tyr-Ile-His-Pro-Phe-OH",
      "CHEMBL216061",
      "51833-69-3",
      "(Sar1)-Angiotensin II",
      "Sar-Arg-Val-Tyr-Ile-His-Pro-Phe",
      "(Sar1)Angiotensin II",
    ],
  },
  {
    primary_id: "D0OF4A",
    names: [
      "SART3 Peptide Cancer Vaccine",
      "Colorectal Cancer Vaccine, Kurume University",
      "SART3 Peptide Cancer Vaccine, Kurume University",
      "Vaccine (Colorectal Cancer), Kurume University",
    ],
  },
  {
    primary_id: "D0OF6C",
    names: [
      "2-(Trifluoromethyl)-9H-Carbazole",
      "2285-35-0",
      "CHEMBL1173704",
      "2-TRIFLUOROMETHYL-9H-CARBAZOLE",
      "SCHEMBL4126997",
      "C13H8F3N",
      "CTK4F0330",
      "DTXSID40479099",
      "HWUDQSLQFQHKSV-UHFFFAOYSA-N",
      "9H-Carbazole,2-(Trifluoromethyl)-",
      "BDBM50322579",
      "9H-Carbazole, 2-(Trifluoromethyl)-",
      "ZINC22003772",
      "AKOS015967072",
      "ACM2285350",
    ],
  },
  {
    primary_id: "D0OF9D",
    names: ["Hepatitis Delta Antigen"],
  },
  {
    primary_id: "D0OG0R",
    names: ["WAY-208466"],
  },
  {
    primary_id: "D0OG0X",
    names: ["GLS-5300"],
  },
  {
    primary_id: "D0OG1Q",
    names: [
      "4-Chloro-5,7-Diphenyl-7H-Pyrrolo[2,3-D]pyrimidine",
      "4-Chloro-5,7-Diphenylpyrrolo[2,3-D]pyrimidine",
      "287177-10-0",
      "CHEMBL426229",
      "AC1M1GSW",
      "MolPort-000-510-379",
      "ZINC2455203",
      "BDBM50088916",
      "SBB039474",
      "BBL001718",
      "STL115578",
      "AKOS000267629",
      "MCULE-6403159172",
      "ST45174715",
      "H6707",
      "L-3752",
    ],
  },
  {
    primary_id: "D0OG1V",
    names: ["MDX-066"],
  },
  {
    primary_id: "D0OG3K",
    names: ["Lodoxamide"],
  },
  {
    primary_id: "D0OG3Z",
    names: ["Bicyclic Heteroaryl Amide Derivative 3"],
  },
  {
    primary_id: "D0OG4A",
    names: ["Kirromycin"],
  },
  {
    primary_id: "D0OG4X",
    names: [
      "1-(1-(4-Fluorophenyl)Ethylidene)Thiosemicarbazide",
      "CHEMBL429500",
      "NSC118183",
      "AC1NUA7B",
      "BDBM50376208",
      "ZINC12350299",
      "AKOS008939384",
      "NSC-118183",
      "[(Z)-1-(4-Fluorophenyl)Ethylideneamino]thiourea",
      "1-[1-(4-Fluorophenyl)Ethylidene]thiosemicarbazide",
    ],
  },
  {
    primary_id: "D0OG5K",
    names: [
      "Bismuth",
      "Bismut",
      "Bismuto",
      "Wismut",
      "Bismuth Standard For AAS",
      "Bismuth Standard For ICP",
      "LTBB002773",
      "Bismuth(III) Nitrate Solution",
      "Bismuth, Elemental",
      "Bismuth-209",
      "BISMUTH, 99.95%",
      "BISMUTH, 99.999%",
      "83Bi",
    ],
  },
  {
    primary_id: "D0OG6N",
    names: ["LM-1507.NA"],
  },
  {
    primary_id: "D0OG8A",
    names: ["Sultamicillin Tosilate", "Ampictam (TN)", "Unacid (TN)", "Unasyn (TN)"],
  },
  {
    primary_id: "D0OG8Z",
    names: ["PMID27967267-Compound-49"],
  },
  {
    primary_id: "D0OH1O",
    names: [
      "VULM-1457",
      "Acyl CoA Cholesterol Acyltransferase Inhibitor (Dyslipidemia/Atherosclerosis), Univerzity Komenskeho V Bratislave",
    ],
  },
  {
    primary_id: "D0OH2H",
    names: ["BR-4628"],
  },
  {
    primary_id: "D0OH2P",
    names: [
      "(S)-(+)-CBPG",
      "CBPG",
      "3-[(S)-Amino(Carboxy)Methyl]bicyclo[1.1.1]pentane-1-Carboxylic Acid",
      "180465-02-5",
      "(S)-3-(Amino(Carboxy)Methyl)Bicyclo[1.1.1]pentane-1-Carboxylic Acid",
      "CHEMBL43412",
      "S-(+)-CBPG",
      "EN300-88066",
      "AC1NSJVN",
      "AC1Q4UAL",
      "SCHEMBL1308145",
      "GTPL3421",
      "(R)-3-(Amino(Carboxy)Methyl)Bicyclo[1.1.1]pentane-1-Carboxylic Acid",
      "CTK7I3432",
      "MolPort-044-812-180",
      "MolPort-005-313-665",
      "ZINC1485767",
      "BDBM50052256",
      "AKOS026740795",
      "FCH4013584",
      "AS-44366",
      "OR312475",
      "CS-0078773",
      "KS-00000111",
      "(S)-(+)-2-(3'-Carboxybicyclo[1.1.1]pentyl)-Glycine",
    ],
  },
  {
    primary_id: "D0OH2W",
    names: [
      "PF-02545920",
      "1037309-45-7",
      "2-((4-(1-Methyl-4-(Pyridin-4-Yl)-1H-Pyrazol-3-Yl)Phenoxy)Methyl)Quinoline Succinate",
      "UNII-TJ5KAZ8T5G",
      "TJ5KAZ8T5G",
      "PF-2545920 Succinate",
      "SCHEMBL439127",
      "CTK8C0559",
      "DTXSID90647726",
      "ANW-64894",
      "AKOS016005150",
      "KB-80291",
      "Quinoline, 2-((4-(1-Methyl-4-(4-Pyridinyl)-1H-Pyrazol-3-Yl)Phenoxy)Methyl)-, Butanedioate (1:1)",
      "AX8234870",
      "TC-153639",
      "Quinoline,2-[[4-[1-Methyl-4-(4-Pyridinyl)-1H-Pyrazol-3-Yl]phenoxy]methyl]-,succinate Salt",
      "2-[[4-[1-Methyl-4-(4-Pyridyl)Pyrazol-3-Yl]phenoxy]methyl]quinoline",
    ],
  },
  {
    primary_id: "D0OH3J",
    names: ["PD-0179483"],
  },
  {
    primary_id: "D0OH4N",
    names: ["Oraqix"],
  },
  {
    primary_id: "D0OH5E",
    names: ["Cartistem"],
  },
  {
    primary_id: "D0OH5L",
    names: ["N-Alkylamide Derivative 2"],
  },
  {
    primary_id: "D0OH5V",
    names: ["PIROXANTRONE"],
  },
  {
    primary_id: "D0OH7D",
    names: [
      "7-Benzylidenenaltrexone",
      "Bntx-7",
      "CHEMBL101519",
      "Morphinan-6-One, 17-(Cyclopropylmethyl)-4,5-Epoxy-3,14-Dihydroxy-7-(Phenylmethylene)-, (5alpha)-",
      "C27H27NO4",
      "7-BNTX",
      "129468-28-6",
      "(Z)-7-Benzylidenenaltrexone",
      "GTPL1634",
      "SCHEMBL15354616",
      "CHEBI:125500",
      "BDBM50450986",
      "LS-172729",
    ],
  },
  {
    primary_id: "D0OH8F",
    names: ["Inflammation Therapeutics"],
  },
  {
    primary_id: "D0OH8O",
    names: ["E Coli EPVaccine", "E Coli EPVaccine, Axis Genetics", "E Coli Edible Plant Vaccine, Axis Genetics"],
  },
  {
    primary_id: "D0OH8W",
    names: ["Visilizumab"],
  },
  {
    primary_id: "D0OH9B",
    names: ["WS-070117"],
  },
  {
    primary_id: "D0OI1H",
    names: ["2-Amino-Benzenesulfonamide", "2-Aminobenzenesulfonamide"],
  },
  {
    primary_id: "D0OI2F",
    names: ["Epoxysuccinate Derivative 1"],
  },
  {
    primary_id: "D0OI4E",
    names: ["ZARAGOZIC ACID B"],
  },
  {
    primary_id: "D0OI4X",
    names: ["Mycophenolic Acid/Nucleotide Derivative 1"],
  },
  {
    primary_id: "D0OI5Y",
    names: ["Human Serum Albumin"],
  },
  {
    primary_id: "D0OI6B",
    names: ["4-(2-Methoxybenzylthio)-2-Aminobutanoic Acid", "CHEMBL204961", "BDBM50179708"],
  },
  {
    primary_id: "D0OI7B",
    names: ["AZD-5745"],
  },
  {
    primary_id: "D0OI8M",
    names: ["UISH-001", "BTL-Ui", "Urinary Incontinence, Beech Tree Labs"],
  },
  {
    primary_id: "D0OI8P",
    names: ["Isoxazoline Derivative 4"],
  },
  {
    primary_id: "D0OJ1R",
    names: ["PMID26924192-Compound-57"],
  },
  {
    primary_id: "D0OJ1V",
    names: ["16-AlphaH,17-Isovaleryloxy-Ent-Kauran-19-Oic Acid", "CHEMBL379581", "CHEMBL2373418"],
  },
  {
    primary_id: "D0OJ4L",
    names: ["Hydroxychloroquine"],
  },
  {
    primary_id: "D0OJ5M",
    names: [
      "OLPADRONIC ACID SODIUM SALT",
      "Dimethyl-APD",
      "IG-8801",
      "Olpadronic Acid Sodium Salt",
      "3-(Dimethylamino)-1-Hydroxypropylidene-1,1-Diphosphonic Acid Monosodium Salt",
    ],
  },
  {
    primary_id: "D0OJ7E",
    names: [
      "3'-Carbamoylbiphenyl-3-Yl 6-Phenylhexylcarbamate",
      "CHEMBL451909",
      "887264-44-0",
      "C26H28N2O3",
      "JP83",
      "MolPort-009-019-196",
      "HMS3650I04",
      "BDBM50275301",
      "1384AH",
      "ZINC27646193",
      "AKOS027282688",
      "3'-Carbamoyl-Biphenyl-3-Yl-Hexylphenylcarbamate",
      "SR-01000946289",
      "3''-Carbamoylbiphenyl-3-Yl 6-Phenylhexylcarbamate",
    ],
  },
  {
    primary_id: "D0OJ7S",
    names: ["SR142948A"],
  },
  {
    primary_id: "D0OJ9P",
    names: ["1-Methyl-3-(Phenylcarbamoyloxy)Pyridinium Bromide", "CHEMBL1173603"],
  },
  {
    primary_id: "D0OK1N",
    names: ["Domagrozumab"],
  },
  {
    primary_id: "D0OK1S",
    names: ["PMID30107136-Compound-Example2"],
  },
  {
    primary_id: "D0OK2N",
    names: [
      "AMG 208",
      "AMG-208",
      "1002304-34-8",
      "AMG208",
      "UNII-Y2SR66P7VM",
      "7-Methoxy-4-((6-Phenyl-[1,2,4]triazolo[4,3-B]pyridazin-3-Yl)Methoxy)Quinoline",
      "Y2SR66P7VM",
      "CHEBI:90626",
      "7-Methoxy-4-[(6-Phenyl[1,2,4]triazolo[4,3-B]pyridazin-3-Yl)Methoxy]quinoline",
      "7-Methoxy-4-[(6-Phenyl-1,2,4-Triazolo[4,3-B]pyridazin-3-Yl)Methoxy]quinoline",
      "C22H17N5O2",
      "7-Methoxy-4-[(6-Phenyl-[1,2,4]triazolo[4,3-B]pyridazin-3-Yl)Methoxy]quinoline",
      "7-Methoxy-4-({6-Phenyl-[1,2,4]triazolo[4,3-B]pyridazin-3-Yl}methoxy)Quinoline",
      "Triazolopyridazine, 4",
      "3cd8",
    ],
  },
  {
    primary_id: "D0OK3K",
    names: ["Azetidinyl-Piperazine Derivative 2"],
  },
  {
    primary_id: "D0OK3O",
    names: ["Cenderitide"],
  },
  {
    primary_id: "D0OK4O",
    names: [
      "Dalazatide",
      "Dalazatide [INN]",
      "UNII-6U0259J807",
      "1081110-69-1",
      "O-PHOSPHONO-L-Tyrosyl-2-(2-(2-Aminoethoxy)Ethoxy)Acetyl(Potassium Channel Toxin Kappa-Stichotoxin-Shela Stoichactis Helianthus (Caribbean Sea Anemone)) Peptidamide",
      "6U0259J807",
    ],
  },
  {
    primary_id: "D0OK5I",
    names: ["Tridihexethyl"],
  },
  {
    primary_id: "D0OK5Q",
    names: [
      "Diphemanil Methylsulfate",
      "Demotil",
      "Diphemanil",
      "Diphemanilum",
      "Diphenatil",
      "Diphenmethanil",
      "Nivelon",
      "Nivelona",
      "Prantal",
      "Prantil",
      "Prentol",
      "Talpran",
      "Vagophemanil",
      "Variton",
      "Diphemanil Methosulfate",
      "Diphemanil Methyl Sulfate",
      "Diphemanil Methylsulphate",
      "Diphemanil Metilsulfate",
      "Diphemanili Metilsulfas",
      "Diphenmanil Methyl Sulfate",
      "Diphenmanil Methylsulfate",
      "Diphenmethanil Methyl Sulfate",
      "Diphenmethanil Methylsulfate",
      "Metilsulfate De Diphemanil",
      "Metilsulfato De Difemanilo",
      "Prantal Methylsulfate",
      "Vagophemanil Methyl Sulfate",
      "Ban-Guard",
      "Diphemanil Methylsulfate [INN:BAN]",
      "Diphemanil Metilsulfate (INN)",
      "Diphemanili Metilsulfas [INN-Latin]",
      "Metilsulfate De Diphemanil [INN-French]",
      "Metilsulfato De Difemanilo [INN-Spanish]",
      "Prantal (TN)",
      "Ban-Guard (Veterinary)",
      "P-(Alpha-Phenylbenzylidene)-1,1-Dimethylpiperidinium Methyl Sulfate",
      "N,N-Dimethyl-4-Piperidylidene-1,1-Diphenylmethane Methylsulfate",
      "P-(Alpha.-Phenylbenzylidene)-1,1-Dimethylpiperidinium Methyl Sulfate",
      "Piperidinium, 4-(Diphenylmethylene)-1,1-Dimethyl-, Methyl Sulfate",
      "4-(Diphenylmethylene)-1,1-Dimethylpiperidinium Methyl Sulfate",
      "4-(Diphenylmethylene)-1,1-Dimethylpiperidinium Methylsulfate",
      "4-(Diphenylmethylidene)-1,1-Dimethylpiperidinium Methyl Sulfate",
      "4-Benzhydrylidene-1,1-Dimethylpiperidin-1-Ium",
    ],
  },
  {
    primary_id: "D0OK5R",
    names: ["OP-R003"],
  },
  {
    primary_id: "D0OK6F",
    names: ["TRV734"],
  },
  {
    primary_id: "D0OK8M",
    names: ["TEI-6522"],
  },
  {
    primary_id: "D0OK8N",
    names: [
      "1-METHYL-3-PHENYL-1H-PYRAZOL-5-YLSULFAMIC ACID",
      "(2-METHYL-5-PHENYL-2H-PYRAZOL-3-YL)-SULFAMIC ACID",
      "UN3",
      "AC1NS1QY",
      "SULFAMIC ACID Analog 13",
      "BDBM13529",
      "DB04800",
      "(2-Methyl-5-Phenylpyrazol-3-Yl)Sulfamic Acid",
      "N-(2-Methyl-5-Phenylpyrazol-3-Yl)Sulfamic Acid",
      "(1-Methyl-3-Phenyl-1H-Pyrazol-5-Yl)Sulfamic Acid",
      "N-(1-Methyl-3-Phenyl-1H-Pyrazol-5-Yl)Sulfamic Acid",
    ],
  },
  {
    primary_id: "D0OK9X",
    names: ["AZD6765"],
  },
  {
    primary_id: "D0OL2D",
    names: [
      "2-(Phenylsulfonyl)Naphthalene-1,4-Diol",
      "CHEMBL462205",
      "SCHEMBL9717678",
      "YEDZIHZPRGSAMI-UHFFFAOYSA-N",
      "BDBM50245877",
    ],
  },
  {
    primary_id: "D0OL3X",
    names: [
      "(S,R)-(+)-Fenoterol",
      "CHEMBL229477",
      "(S,R)-Fenoterol",
      "AC1LDIMM",
      "SCHEMBL9971023",
      "ZINC20252",
      "BDBM50213103",
      "5-[(1S)-1-Hydroxy-2-[[(2R)-1-(4-Hydroxyphenyl)Propan-2-Yl]amino]ethyl]benzene-1,3-Diol",
    ],
  },
  {
    primary_id: "D0OL4R",
    names: [
      "CARM1 Inhibitors",
      "CARM1 Inhibitors (Cancer)",
      "CARM1 Inhibitors (Cancer), MethylGene",
      "PRMT4 Inhibitors (Cancer), MethylGene",
      "Protein Arginine Methyltransferase 4 Inhibitors (Cancer), MethylGene",
      "Coactivator-Associated Arginine Methyltransferase 1 (Cancer), MethylGene",
    ],
  },
  {
    primary_id: "D0OL4S",
    names: ["GLY-515n"],
  },
  {
    primary_id: "D0OL6O",
    names: ["Methyl Aminolevulinate"],
  },
  {
    primary_id: "D0OL7F",
    names: ["Uridine Triacetate"],
  },
  {
    primary_id: "D0OL7Q",
    names: ["PMID28350212-Compound-21"],
  },
  {
    primary_id: "D0OL9V",
    names: [
      "CGS-24565",
      "Cgs-24565",
      "Cgs 24565",
      "136583-72-7",
      "N,15-Didehydro-11,15-Dideoxo-1-Deoxy-1,15-Epoxy-11-Hydroxy-4-O-Methyl-3-(4-((2,4,6-Trimethylphenyl)Methyl)-1-Piperazinyl)Rifamycin 8-(2,2-Dimethylpropanoate)",
      "Rifamycin, N,15-Didehydro-11,15-Dideoxo-1-Deoxy-1,15-Epoxy-11-Hydroxy-4-O-Methyl-3-(4-((2,4,6-Trimethylphenyl)Methyl)-1-Piperazinyl)-, 8-(2,2-Dimethylpropanoate)",
    ],
  },
  {
    primary_id: "D0OM0M",
    names: [
      "AVE1625",
      "Drinabant",
      "358970-97-5",
      "AVE-1625",
      "UNII-61S98RLL5I",
      "61S98RLL5I",
      "Drinabant [INN]",
      "SCHEMBL684143",
      "C23H20Cl2F2N2O2S",
      "CHEMBL3545166",
      "CTK8E8263",
      "DTXSID50189455",
      "MolPort-009-019-211",
      "ZINC59299699",
      "1745AH",
      "RT-011348",
      "970D975",
      "Methanesulfonamide, N-(1-(Bis(4-Chlorophenyl)Methyl)-3-Azetidinyl)-N-(3,5-Difluorophenyl)-",
    ],
  },
  {
    primary_id: "D0OM0X",
    names: ["IB-201"],
  },
  {
    primary_id: "D0OM2M",
    names: ["PMID29649907-Compound-28"],
  },
  {
    primary_id: "D0OM2S",
    names: [
      "BMS184394",
      "CHEMBL82089",
      "AC1NBJP6",
      "SCHEMBL6372648",
      "BDBM31885",
      "6-[hydroxy-(5,5,8,8-Tetramethyl-6,7-Dihydronaphthalen-2-Yl)Methyl]naphthalene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0OM3A",
    names: ["Follitropin Beta"],
  },
  {
    primary_id: "D0OM5L",
    names: ["VC-01"],
  },
  {
    primary_id: "D0OM5M",
    names: ["Ro-48-1220"],
  },
  {
    primary_id: "D0OM6D",
    names: ["Pyrimidine Benzenesulfonamide Derivative 3"],
  },
  {
    primary_id: "D0OM9G",
    names: ["Immepip"],
  },
  {
    primary_id: "D0ON2A",
    names: ["5-(2-Methylquinolin-7-Yl)Nicotinonitrile", "CHEMBL233418"],
  },
  {
    primary_id: "D0ON2X",
    names: ["FR-173657"],
  },
  {
    primary_id: "D0ON2Z",
    names: ["RAZAXABAN"],
  },
  {
    primary_id: "D0ON3Q",
    names: [
      "Biotin Carboxylase Inhibitors",
      "Biotin Carboxylase Inhibitors (Bacterial Infection)",
      "AccC Inhibitors (Bacterial Infection), Schering-Plough",
      "Biotin Carboxylase Inhibitors (Bacterial Infection), Schering-Plough",
    ],
  },
  {
    primary_id: "D0ON4J",
    names: ["Muscle Wasting Protein Therapeutics"],
  },
  {
    primary_id: "D0ON4M",
    names: ["PMID25666693-Compound-8"],
  },
  {
    primary_id: "D0ON7G",
    names: ["Isoxazole-Based Bicyclic Compound 3"],
  },
  {
    primary_id: "D0OO1S",
    names: ["Anti-CD38 CAR-T Cells"],
  },
  {
    primary_id: "D0OO3J",
    names: ["FluGEM"],
  },
  {
    primary_id: "D0OO4P",
    names: [
      "CV-11194",
      "CV 11194",
      "136284-47-4",
      "CHEMBL48242",
      "1H-Benzimidazole-7-Carboxylicacid, 2-Butyl-1-[[2'-(2H-Tetrazol-5-Yl)[1,1'-Biphenyl]-4-Yl]methyl]-",
      "FLOKGHWIQFCIJW-UHFFFAOYSA-N",
      "AC1MHFWM",
      "ACMC-1CI8P",
      "SCHEMBL905714",
      "SCHEMBL12787593",
      "CTK0H7043",
      "DTXSID70159756",
      "BDBM50044402",
      "2-Butyl-1-((2'-(1H-Tetrazol-5-Yl)Biphenyl-4-Yl)Methyl)-1H-Benzimidazole-7-Carboxylic Acid",
      "2-Butyl-1-((2'-(1H-Tetrazol-5-Yl)(1,1'-Biphenyl)-4-Yl)Methyl)-1H-Benzimidazole-7-Carboxylic Acid",
      "L005054",
    ],
  },
  {
    primary_id: "D0OO6A",
    names: ["Isbogrel", "Antexan", "A-66900", "CV-4151"],
  },
  {
    primary_id: "D0OO8M",
    names: ["MAA868"],
  },
  {
    primary_id: "D0OP1L",
    names: ["Anti-CEA CAR-T Therapy"],
  },
  {
    primary_id: "D0OP2E",
    names: ["D-81050"],
  },
  {
    primary_id: "D0OP3D",
    names: ["AZ960", "AZ 960", "AZ-960"],
  },
  {
    primary_id: "D0OP3Y",
    names: ["Namn"],
  },
  {
    primary_id: "D0OP5F",
    names: ["Aryl Carboxamide Derivative 2"],
  },
  {
    primary_id: "D0OP7B",
    names: ["LY-210073"],
  },
  {
    primary_id: "D0OP7I",
    names: ["CARCIK-CD19"],
  },
  {
    primary_id: "D0OP8W",
    names: ["Zalospirone"],
  },
  {
    primary_id: "D0OQ1A",
    names: ["JNJ-55308942"],
  },
  {
    primary_id: "D0OQ1I",
    names: ["Narciclasine"],
  },
  {
    primary_id: "D0OQ2Q",
    names: ["ISIS 2678"],
  },
  {
    primary_id: "D0OQ2R",
    names: ["Mannoside Derivative 1"],
  },
  {
    primary_id: "D0OQ2S",
    names: ["PMID26882240-Compound-14"],
  },
  {
    primary_id: "D0OQ3L",
    names: ["SL65.0472"],
  },
  {
    primary_id: "D0OQ4H",
    names: [
      "A-69024",
      "58939-37-0",
      "7-Isoquinolinol, 1-((2-Bromo-4,5-Dimethoxyphenyl)Methyl)-1,2,3,4-Tetrahydro-6-Methoxy-2-Methyl-",
      "A 69024",
      "A-66359",
      "A 66359",
      "1-[(2-Bromo-4,5-Dimethoxyphenyl)Methyl]-6-Methoxy-2-Methyl-3,4-Dihydro-1H-Isoquinolin-7-Ol",
      "AC1L5ACF",
      "1-(2-Bromo-4,5-Dimethoxybenzyl)-7-Hydroxy-6-Methoxy-2-Methyl-1,2,3,4-Tetrahydroisoquinoline",
      "Oprea1_293762",
      "SCHEMBL462517",
      "CTK1H3031",
    ],
  },
  {
    primary_id: "D0OQ5H",
    names: [
      "6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-N-(HYDROXYMETHYL)-1,2,2-TRIMETHYL-1,2,3,4-TETRAHYDRO-1,8-NAPHTHYRIDINE-4-CARBOXAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1082330",
      "BDBM50320180",
    ],
  },
  {
    primary_id: "D0OQ5R",
    names: ["IGN523"],
  },
  {
    primary_id: "D0OQ5W",
    names: ["Meninge ACYW"],
  },
  {
    primary_id: "D0OQ7S",
    names: ["PMID25435179-Compound-WO2013059587(VI, 2nd Set)"],
  },
  {
    primary_id: "D0OQ8Q",
    names: [
      "Vatiquinone",
      "Alpha-Tocotrienol Quinone",
      "UNII-6O85FK9I0X",
      "1213269-98-7",
      "6O85FK9I0X",
      "Vatiquinone [USAN:INN]",
      "Vatiquinone (JAN/USAN)",
      "SCHEMBL503125",
      "(R)-Alpha-Tocotrienol Quinone",
      "CHEMBL1812161",
      "SCHEMBL14639355",
      "DTXSID90153231",
      "LNOVHERIIMJMDG-XZXLULOTSA-N",
      "DB11917",
      "2,5-Cyclohexadiene-1,4-Dione, 2-((3R,6e,10E)-3-Hydroxy-3,7,11,15-Tetramethyl-6,10,14-Hexadecatrien-1-Yl)-3,5,6-Trimethyl-",
      "D10407",
    ],
  },
  {
    primary_id: "D0OQ9V",
    names: ["BMN-110"],
  },
  {
    primary_id: "D0OR0K",
    names: ["Pyridine-2,4-Dicarboxylic Acid Analog 3"],
  },
  {
    primary_id: "D0OR2L",
    names: [
      "Cholic Acid",
      "81-25-4",
      "Cholalic Acid",
      "Cholate",
      "Cholalin",
      "Colalin",
      "Cholsaeure",
      "NSC-6135",
      "Cholic Acid, 5beta-",
      "3alpha,7alpha,12alpha-Trihydroxy-5beta-Cholanic Acid",
      "3alpha,7alpha,12alpha-Trihydroxy-5beta-Cholan-24-Oic Acid",
      "UNII-G1JO7801AE",
      "HSDB 982",
      "(R)-4-((3R,5S,7R,8R,9S,10S,12S,13R,14S,17R)-3,7,12-Trihydroxy-10,13-Dimethylhexadecahydro-1H-Cyclopenta[a]phenanthren-17-Yl)Pentanoic Acid",
      "Cholic Acid [USAN]",
      "3,7,12-Trihydroxycholanic Acid",
      "G1JO7801AE",
      "CHEMBL205596",
      "CHEBI:16359",
      "NSC6135",
      "BHQCQFFYRZLCQQ-OELDTZBJSA-N",
    ],
  },
  {
    primary_id: "D0OR2S",
    names: ["Broxaterol"],
  },
  {
    primary_id: "D0OR2V",
    names: ["N-(Arylamino)Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D0OR3G",
    names: ["Bexagliflozin"],
  },
  {
    primary_id: "D0OR4G",
    names: ["Diarylamine And Arylheteroarylamine Pyrazole Derivative 2"],
  },
  {
    primary_id: "D0OR5P",
    names: ["TAS-106"],
  },
  {
    primary_id: "D0OR5S",
    names: ["2-(Benzylamino)-6-(3-Acetamidophenyl)Pyrazine", "CHEMBL199800"],
  },
  {
    primary_id: "D0OR5V",
    names: ["9-Cyclopropyl-9H-Adenine", "CHEMBL469409", "9-Cyclopropylpurin-6-Amine", "AC1L987W"],
  },
  {
    primary_id: "D0OR6A",
    names: ["Melinamide", "Artes Oil (TN)"],
  },
  {
    primary_id: "D0OR6C",
    names: ["NF340"],
  },
  {
    primary_id: "D0OR6L",
    names: ["CAR-T Cells Targeting CD20"],
  },
  {
    primary_id: "D0OR6O",
    names: ["CM-352", "Compound 2 [PMID 25686022]"],
  },
  {
    primary_id: "D0OR7P",
    names: [
      "DARPins",
      "DARPins (Inflammatory/Autoimmune Diseases)",
      "DARPins (Inflammatory Diseases), Molecular Partners/Centocor Research And Development",
      "Designed Ankyrin Repeat Proteins (Inflammatory Diseases), Molecular Partners/Centocor Research And Development",
      "DARPins (Inflammatory/Autoimmune Diseases), Molecular Partners/Centocor Research And Development",
    ],
  },
  {
    primary_id: "D0OR8W",
    names: [
      "ETIPREDNOL DICLOACETATE",
      "BNP-166",
      "Cronaze (Ivax)",
      "Ethinase (Ivax)",
      "Etiprednol Dicloacetate < USAN",
      "Respicort (Ivax)",
      "(11beta,17alpha)-17-(2,2-Dichloroacetoxy)-11-Hydroxy-3-Oxoandrosta-1,4-Diene-17-Carboxylic Acid Ethyl Ester",
    ],
  },
  {
    primary_id: "D0OS1N",
    names: ["Tricyclic Compound 3"],
  },
  {
    primary_id: "D0OS2M",
    names: ["Mycophenolic Acid/Nucleotide Derivative 5"],
  },
  {
    primary_id: "D0OS3C",
    names: ["PF-05230901"],
  },
  {
    primary_id: "D0OS3L",
    names: ["1-(Trifluoromethyl)-9H-Carbazole", "CHEMBL1169609", "SCHEMBL6961593"],
  },
  {
    primary_id: "D0OS6O",
    names: ["Sertindole"],
  },
  {
    primary_id: "D0OS6P",
    names: ["XBIO-101"],
  },
  {
    primary_id: "D0OS7K",
    names: ["EGEN-001"],
  },
  {
    primary_id: "D0OS7U",
    names: ["NN-Dimethyl-D-Erythrosphingosine"],
  },
  {
    primary_id: "D0OS8C",
    names: ["NCX-1015"],
  },
  {
    primary_id: "D0OS8Y",
    names: [
      "MK-5435",
      "DFMTI",
      "CHEMBL579062",
      "864864-86-8",
      "SCHEMBL1500017",
      "GTPL6335",
      "KKZVYGIANGOHBS-UHFFFAOYSA-N",
      "MolPort-044-724-496",
      "KS-00001CM3",
      "BDBM50301804",
      "ZINC13983201",
      "AKOS032944969",
      "CS-5849",
      "HY-100404",
      "M-265",
      "L023906",
      "2-Isopropyl-5-[1-(2,4-Difluorophenyl)-5-Methyl-1H-1,2,3-Triazole-4-Yl]isoindoline-1-One",
      "5-[1-(2,4-Difluorophenyl)-5-Methyltriazol-4-Yl]-2-Propan-2-Yl-3H-Isoindol-1-One.",
      "5-[1-(2,4-Difluorophenyl)-5-Methyltriazol-4-Yl]-2-Propan-2-Yl-3H-Isoindol-1-One",
    ],
  },
  {
    primary_id: "D0OS9O",
    names: ["Isoquinoline Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D0OT0O",
    names: ["Foscavir"],
  },
  {
    primary_id: "D0OT1A",
    names: ["API-31510", "API-31510 (Iv)"],
  },
  {
    primary_id: "D0OT1Q",
    names: ["Axalimogene Filolisbac"],
  },
  {
    primary_id: "D0OT1S",
    names: ["CP-4200"],
  },
  {
    primary_id: "D0OT1Z",
    names: ["NSC-356820"],
  },
  {
    primary_id: "D0OT2H",
    names: [
      "1,2,4-Triazole",
      "1H-1,2,4-Triazole",
      "1,2,4-TRIAZOLE",
      "288-88-0",
      "4H-1,2,4-Triazole",
      "Pyrrodiazole",
      "S-Triazole",
      "1,2,4-1H-Triazole",
      "1H-1,2,4-Triazol",
      "UNII-10MS0Y1RDI",
      "NSC 83128",
      "1,2,4 Triazole",
      "1,2,4-Triazol",
      "4H-1,2,4-Triazole (VAN)",
      "EINECS 206-022-9",
      "63598-71-0",
      "1H-[1,2,4]triazole",
      "10MS0Y1RDI",
      "AI3-51031",
      "CHEBI:46077",
      "NSPMIYGKQJPBQR-UHFFFAOYSA-N",
      "MFCD00005228",
      "[1,2,4]-Triazole",
      "(1,2,4)-Triazole",
      "1,2,4-1H-Triazole, 99.5%",
      "InChI=1/C2H3N3/C1-3-2-5-4-1/H1-2H,(H,3,4,5",
      "HSDB 7860",
      "1,4-Triazole",
      "1,2,4triazole",
      "1,3,4-Triazole",
      "4H-1,4-Triazole",
      "1H-1,4-Triazole",
      "1,2, 4-",
    ],
  },
  {
    primary_id: "D0OT3G",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 10"],
  },
  {
    primary_id: "D0OT3H",
    names: ["UR-11353", "138060-13-6"],
  },
  {
    primary_id: "D0OT3L",
    names: ["Folate Binding Protein-E39"],
  },
  {
    primary_id: "D0OT3Y",
    names: ["Sebriplatin"],
  },
  {
    primary_id: "D0OT4O",
    names: ["Quinoline Derivative 6"],
  },
  {
    primary_id: "D0OT5L",
    names: [
      "7-Propoxy-2H-Chromen-2-One",
      "7-Propoxy-Chromen-2-One",
      "6093-73-8",
      "7-PROPOXYCOUMARIN",
      "2H-1-Benzopyran-2-One, 7-Propoxy-",
      "CHEMBL568516",
      "7-Propoxy-2H-1-Benzopyran-2-One",
      "AC1LUIIJ",
      "7-Propoxychromen-2-One",
      "CBMicro_001394",
      "SCHEMBL6690398",
      "CTK6E6140",
      "DTXSID90209762",
      "MolPort-000-158-388",
      "ZINC1893420",
      "SMSF0015515",
      "BDBM50303501",
      "STK829267",
      "AKOS001042495",
      "MCULE-7191883680",
      "CB03116",
      "RP26084",
      "KB-46577",
      "CJ-30724",
      "BIM-0001595.P001",
      "DB-072872",
      "FT-0687986",
    ],
  },
  {
    primary_id: "D0OT8O",
    names: ["STP-601"],
  },
  {
    primary_id: "D0OT8R",
    names: ["Pyridine-Carboximide Derivative 1"],
  },
  {
    primary_id: "D0OT9B",
    names: ["L-Aspartic Acid"],
  },
  {
    primary_id: "D0OT9G",
    names: ["H-Arg-Arg-Pro-Tyr-Ile-N-Me-Leu-OH", "CHEMBL508130"],
  },
  {
    primary_id: "D0OT9S",
    names: ["Vinflunine"],
  },
  {
    primary_id: "D0OT9U",
    names: ["Abeta Retroparticles", "Abeta Retroparticles (Alzheimer's Disease)"],
  },
  {
    primary_id: "D0OU0J",
    names: ["PMID29671355-Compound-55"],
  },
  {
    primary_id: "D0OU0S",
    names: ["FP-0110"],
  },
  {
    primary_id: "D0OU1V",
    names: ["PD-155392"],
  },
  {
    primary_id: "D0OU3Q",
    names: ["TAK-058"],
  },
  {
    primary_id: "D0OU6W",
    names: ["GSK2696275"],
  },
  {
    primary_id: "D0OU7P",
    names: ["SKF 38393"],
  },
  {
    primary_id: "D0OU9J",
    names: ["BioBypass"],
  },
  {
    primary_id: "D0OV0A",
    names: ["ISIS 25114"],
  },
  {
    primary_id: "D0OV0E",
    names: ["ISIS 138613"],
  },
  {
    primary_id: "D0OV0I",
    names: [
      "2-Amino-6-M-Tolylsulfanyl-Benzonitrile",
      "6-Amino-2-(3-Methylphenylthio)Benzenecarbonitrile",
      "AC1LAE0B",
      "CHEMBL296376",
      "BDBM1736",
      "SCHEMBL8641894",
      "CTK7C6434",
      "ZINC20466",
      "2-Amino-6-(M-Tolylsulfanyl)Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1f",
      "2-Amino-6-(3-Methylphenyl)Sulfanylbenzonitrile",
    ],
  },
  {
    primary_id: "D0OV2V",
    names: ["LY307452"],
  },
  {
    primary_id: "D0OV3S",
    names: ["4-[1-(4-Hydroxyphenyl)-2-Phenylpent-1-Enyl]phenol", "CHEMBL357824", "SCHEMBL19158863", "BDBM50121321"],
  },
  {
    primary_id: "D0OV4A",
    names: ["Cystic Fibrosis Therapeutics", "Cystic Fibrosis Therapeutics (Seglin)"],
  },
  {
    primary_id: "D0OV7O",
    names: ["ISIS 6729"],
  },
  {
    primary_id: "D0OW1R",
    names: ["YM-43611"],
  },
  {
    primary_id: "D0OW1V",
    names: ["Biaryl Mannoside Derivative 25"],
  },
  {
    primary_id: "D0OW2D",
    names: ["Tri-Substituted Benzene Derivative 1"],
  },
  {
    primary_id: "D0OW2O",
    names: ["U-92032"],
  },
  {
    primary_id: "D0OW2S",
    names: ["AMG 145"],
  },
  {
    primary_id: "D0OW4A",
    names: ["PD-145065"],
  },
  {
    primary_id: "D0OW4W",
    names: ["4-(4-Chlorophenyl)-5-P-Tolylisothiazole", "CHEMBL490889"],
  },
  {
    primary_id: "D0OW5Y",
    names: ["NW-1772"],
  },
  {
    primary_id: "D0OW6O",
    names: ["PMID26651364-Compound-11c"],
  },
  {
    primary_id: "D0OW6Q",
    names: ["2,3'-Diamino-3,4,4',5-Tetramethoxy-(Z)-Stillbene"],
  },
  {
    primary_id: "D0OW6V",
    names: ["AZM-1"],
  },
  {
    primary_id: "D0OW6Z",
    names: ["Sipoglitazar"],
  },
  {
    primary_id: "D0OW8M",
    names: [
      "AR-C133057XX",
      "CHEMBL114500",
      "4-({4-[(4-Methoxypyridin-2-Yl)Amino]piperidin-1-Yl}carbonyl)Benzonitrile",
      "4-[4-[(4-Methoxypyridin-2-Yl)Amino]piperidine-1-Carbonyl]benzonitrile",
      "4-{4-[(4-Methoxypyridin-2-Yl)Amino]piperidine-1-Carbonyl}benzonitrile",
      "SCHEMBL7824352",
      "CHEBI:95013",
      "BDBM50148167",
      "DB07002",
      "BRD-K40892394-001-01-9",
      "4-(4-(4-Methoxypyridin-2-Ylamino)Piperidine-1-Carbonyl)Benzonitrile",
      "4-[4-(4-Methoxy-Pyridin-2-Ylamino)-Piperidine-1-Carbonyl]-Benzonitrile",
    ],
  },
  {
    primary_id: "D0OW9L",
    names: ["MK-3134"],
  },
  {
    primary_id: "D0OX0B",
    names: ["1,2-Dihydro-3-(2,3,4-Trimethoxyphenyl)Naphthalene", "CHEMBL229245"],
  },
  {
    primary_id: "D0OX0V",
    names: [
      "2-(4-Ethylthiobenzimidazol-2-Yl)Quinoxaline",
      "CHEMBL198008",
      "Benzimidazole-Quinoxaline, C2",
      "BDBM21216",
      "2-[4-(Ethylsulfanyl)-1H-Benzimidazol-2-Yl]quinoxaline",
    ],
  },
  {
    primary_id: "D0OX1L",
    names: [
      "VALERGEN-DP (Sublingual)",
      "Dermatophagoides Pteronyssinus Allergen Immunotherapy (Sublingual, Asthma), National Biopreparations Center",
    ],
  },
  {
    primary_id: "D0OX1T",
    names: ["A-443654", "A-4436554"],
  },
  {
    primary_id: "D0OX1Y",
    names: [
      "Ad5-ENVA-48",
      "Ad26-ENVA-01",
      "Ad5-HVR48-ENVA-01",
      "HIV Vaccine (Ad35 Vector), IAVI/Crucell",
      "Recombinant Envelope Adenovirus 5 Vaccine (HIV Infection), Crucell/Harvard Medical School",
      "Adenovirus Vector Vaccine (HIV Infection), IAVI/Crucell/BIDMC/Harvard",
      "HIV Vaccine (RAd26 Vector), IAVI/Crucell/Beth Israel Deaconess Medical Center/Harvard",
      "HIV Vaccine (AdVac, PER.C6), Crucell/IAVI/BIDMC/Harvard",
    ],
  },
  {
    primary_id: "D0OX5H",
    names: ["ABT-122"],
  },
  {
    primary_id: "D0OX5S",
    names: ["Cyclopropyl-Spiro Piperidine Derivative 2"],
  },
  {
    primary_id: "D0OX6I",
    names: ["KB-9520"],
  },
  {
    primary_id: "D0OX9F",
    names: ["BIIB074", "CNV1014082", "BC600719", "HY-12796", "B5927", "(5R)-5-(4-[2-"],
  },
  {
    primary_id: "D0OX9Q",
    names: [
      "NOX-200",
      "2-Amino-3-(1H-Indol-3-Yl)-2-Methylpropanoic Acid",
      "Alpha-Methyl-DL-Tryptophan",
      "153-91-3",
      "NSC9948",
      "13510-08-2",
      "Alpha-Methyl-L-Tryptophan",
      "M-5098",
      "Tryptophan, Alpha-Methyl-",
      "16709-25-4",
      "DL-Alpha-Methyltryptophan",
      "Alpha-Methyl-D,L-Tryptophan",
      "NSC-9948",
      "NSC 9948",
      "Tryptophan,a-Methyl-",
      "ACMC-1BNRO",
      "Alpha-Methyltryptophan",
      "AC1L3UBI",
      "AC1Q5S1L",
      "(S)-Alpha-Methyl Pryptophan",
      "SCHEMBL343309",
      "CHEMBL559578",
      "GTPL4693",
      "CTK4C8033",
      "ZTTWHZHBPDYSQB-UHFFFAOYSA-N",
      "MolPort-003-958-850",
      "KS-000001AA",
      "ANW-21478",
    ],
  },
  {
    primary_id: "D0OX9W",
    names: ["Inolimomab"],
  },
  {
    primary_id: "D0OY0Q",
    names: ["Tihotungstate Derivative 1"],
  },
  {
    primary_id: "D0OY0U",
    names: ["PMID25656651-Compound-26c"],
  },
  {
    primary_id: "D0OY0X",
    names: ["Diphthamide", "2-(3-Carboxyamido-3-(Trimethylammonio)Propyl)Histidine"],
  },
  {
    primary_id: "D0OY1J",
    names: [
      "7-((1H-Imidazol-1-Yl)Methyl)-2H-Chromen-2-One",
      "CHEMBL224649",
      "Coumarin Deriv. 27",
      "BDBM9478",
      "ZINC13586720",
    ],
  },
  {
    primary_id: "D0OY1R",
    names: [
      "Aza-THIP",
      "654666-65-6",
      "1,2,4,5,6,7-Hexahydro-3H-Pyrazolo[3,4-C]pyridin-3-One",
      "4,5,6,7-Tetrahydro-1H-Pyrazolo[3,4-C]pyridin-3-Ol",
      "3H-Pyrazolo[3,4-C]pyridin-3-One, 1,2,4,5,6,7-Hexahydro-",
      "1H,4H,5H,6H,7H-Pyrazolo[3,4-C]pyridin-3-Ol",
      "4,5,6,7-Tetrahydro-2H-Pyrazolo[3,4-C]pyridin-3-Ol",
      "THIP, Aza-",
      "4,5,6,7-Tetrahydropyrazolo[5,4-C]pyridin-3-Ol",
      "SCHEMBL2946381",
      "GTPL4143",
      "SCHEMBL16057201",
      "CTK1J6712",
      "BDBM86256",
      "DTXSID20717441",
      "UPMMUUFLHUEUKP-UHFFFAOYSA-N",
      "MolPort-022-468-866",
      "ZINC13859300",
      "AKOS022182337",
      "AKOS022718659",
      "AKOS006351664",
    ],
  },
  {
    primary_id: "D0OY1V",
    names: ["NS-1608"],
  },
  {
    primary_id: "D0OY2V",
    names: ["3,9-Dihydroxy-4-Prenyl-[6aR,11aR]pterocarpan", "CHEMBL1087027", "BDBM50311578"],
  },
  {
    primary_id: "D0OY4K",
    names: ["Vestronidase Alfa"],
  },
  {
    primary_id: "D0OY5G",
    names: [
      "Brostallicin",
      "UNII-RPC6R41K4I",
      "203258-60-0",
      "RPC6R41K4I",
      "PNU-166196A",
      "Brostallicin [INN]",
      "AC1OCFEA",
      "SCHEMBL3678233",
      "CHEMBL1189025",
      "DTXSID70174222",
      "ZINC3979512",
      "Z-3228",
      "4-[[4-[[4-[[4-(2-Bromoprop-2-Enoylamino)-1-Methylpyrrole-2-Carbonyl]amino]-1-Methylpyrrole-2-Carbonyl]amino]-1-Methylpyrrole-2-Carbonyl]amino]-N-[2-(Diaminomethylideneamino)Ethyl]-1-Methylpyrrole-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0OY5X",
    names: ["DermaGraft"],
  },
  {
    primary_id: "D0OY7A",
    names: ["RBx-343E48F0"],
  },
  {
    primary_id: "D0OY8Y",
    names: ["UCM 549"],
  },
  {
    primary_id: "D0OY9L",
    names: ["ALXN6000"],
  },
  {
    primary_id: "D0OY9S",
    names: ["Rose Bengal Sodium I-131"],
  },
  {
    primary_id: "D0OZ0Z",
    names: ["Elenagen"],
  },
  {
    primary_id: "D0OZ1M",
    names: ["Fluorine-18-Labelled Peptides"],
  },
  {
    primary_id: "D0OZ1P",
    names: ["Fused Aryl Carbocycle Derivative 9"],
  },
  {
    primary_id: "D0OZ2F",
    names: [
      "AP-7",
      "2-Amino-7-Phosphonoheptanoic Acid",
      "DL-AP7",
      "78966-69-5",
      "DL-2-Amino-7-Phosphonoheptanoic Acid",
      "NSC672105",
      "85797-13-3",
      "BRN 3544152",
      "CHEMBL274440",
      "Heptanoic Acid, 2-Amino-7-Phosphono-, (+-)-",
      "2-Amino-7-Phosphono-Heptanoic Acid",
      "Heptanoic Acid, 2-Amino-7-Phosphono-",
      "(+/-)-2-Amino-7-Phosphonoheptanoic Acid",
      "C7H16NO5P",
      "AP-7 Compound",
      "L(+)-2-Amino-7-Phosphonoheptanoic Acid",
      "AP-7 (Drug)",
      "AC1L1F7W",
      "Lopac0_000008",
      "SCHEMBL154303",
      "CTK7D4156",
      "( Inverted Question Mark)-AP-7",
      "MYDMWESTDPJANS-UHFFFAOYSA-N",
      "MolPort-002-514-441",
    ],
  },
  {
    primary_id: "D0OZ2S",
    names: [
      "Lck Inhibitor",
      "213743-31-8",
      "7-Cyclopentyl-5-(4-Phenoxy)Phenyl-7H-Pyrrolo[2,3-D]pyrimidin-4-Ylamine",
      "CHEMBL47940",
      "KIN001-051",
      "RK-24466",
      "7-Cyclopentyl-5-(4-Phenoxyphenyl)-7H-Pyrrolo[2,3-D]pyrimidin-4-Ylamine",
      "4-Amino-5-(4-Phenoxyphenyl)-7h-Pyrrolo[2,3-D]pyrimidin-7-Yl-Cyclopentane",
      "7-Cyclopentyl-5-(4-Phenoxyphenyl)-7H-Pyrrolo[2,3-D]pyrimidin-4-Amine",
      "4-Amino-5-(4-Phenoxyphenyl)-7H-Pyrrolo[3,2-D]pyrimidin-7-Yl-Cyclopentane",
      "3vry",
      "3gen",
      "B43",
      "NCGC00015280-01",
      "Lopac-C-8863",
      "AC1O7G1S",
      "Lopac0_000450",
      "MLS002153184",
    ],
  },
  {
    primary_id: "D0OZ3S",
    names: ["RJM-0035-K002", "ACE Inhibitor (Hypertension), CDRI"],
  },
  {
    primary_id: "D0OZ4C",
    names: [
      "6-Benzenesulfonyl-2H-Pyridazin-3-One",
      "6-(Benzenesulfonyl)-2,3-Dihydropyridazin-3-One",
      "141060-78-8",
      "3(2H)-Pyridazinone, 6-(Phenylsulfonyl)-",
      "AC1Q6GZS",
      "CHEMBL74814",
      "SCHEMBL7295107",
      "BDBM16442",
      "ZICCKDUVBPQRQT-UHFFFAOYSA-N",
      "MolPort-007-599-834",
      "ZINC11536236",
      "AKOS001782098",
      "MCULE-8830355611",
      "NE56517",
      "6-Phenylsulfonylpyridazin-2H-3-One, 1",
      "6-Phenylsulfonyl-3-Oxo-2,3-Dihydropyridazine",
      "EN300-72670",
    ],
  },
  {
    primary_id: "D0OZ4F",
    names: ["Ordopidine", "ACR-321", "ACR-325"],
  },
  {
    primary_id: "D0OZ4J",
    names: ["RG-7446"],
  },
  {
    primary_id: "D0OZ5H",
    names: ["CD19-CAR T Cell"],
  },
  {
    primary_id: "D0OZ6W",
    names: ["STAGR320"],
  },
  {
    primary_id: "D0OZ7P",
    names: [
      "BILN-2061",
      "Ciluprevir",
      "300832-84-2",
      "BILN 2061",
      "UNII-75C8DU40T0",
      "CHEMBL297884",
      "75C8DU40T0",
      "Ciluprevir [USAN:INN]",
      "Ciluprevir (BILN-2061)",
      "BILN 2061 ZW",
      "BILN2061",
      "SCHEMBL2524642",
      "BILN-2061-ZW",
      "C40H50N6O8S",
      "(Cyclopentoxycarbonylamino)-[[2-[2-(Isopropylamino)Thiazol-4-Yl]-7-Methoxy-4-Quinolyl]oxy]-Dioxo-[?]carboxylic Acid",
      "BDBM50142916",
      "3548AH",
      "ZINC150339466",
      "AKOS024627485",
      "CS-0339",
      "DB05868",
      "BILN 2061ZW",
      "HY-10242",
      "W-5515",
      "F2145-0237",
    ],
  },
  {
    primary_id: "D0OZ8W",
    names: ["Biphenyl Derivative 1"],
  },
  {
    primary_id: "D0P0BV",
    names: [
      "2-(3''-(5''-Hydroxyindolyl)Ethyloxy)Adenosine",
      "CHEMBL222500",
      "BDBM50208785",
      "2-(3''''-(5''''-Hydroxyindolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D0P0BY",
    names: ["Codrituzumab"],
  },
  {
    primary_id: "D0P0CB",
    names: [
      "6-Iodo-4'-Dimethyaminoflavone",
      "871101-31-4",
      "2-(4-DIMETHYLAMINOPHENYL)-6-IODO-4-CHROMENONE",
      "CHEMBL375934",
      "2-(4-Dimethylaminophenyl)-6-Iodo-Chromen-4-One",
      "2-[4-(Dimethylamino)Phenyl]-6-Iodo-4H-1-Benzopyran-4-One",
      "SCHEMBL4985743",
      "6-Iodo-4'-Dimethylaminoflavone",
      "6-Iodo-4''-Dimethyaminoflavone",
      "CTK8E3396",
      "DTXSID30468831",
      "MVKVWWKDDYBNOJ-UHFFFAOYSA-N",
      "BDBM50176892",
      "ZINC13675691",
      "RT-007109",
    ],
  },
  {
    primary_id: "D0P0CM",
    names: [
      "GNF-PF-2272",
      "3-Methyltoxoflavin",
      "GNF-Pf-2272",
      "MLS000528498",
      "SMR000121073",
      "1,3,6-Trimethyl-1H-Pyrimido[5,4-E][1,2,4]triazine-5,7-Dione",
      "BAS 00444306",
      "1,3,6-Trimethylpyrimido[5,4-E][1,2,4]triazine-5,7-Dione",
      "1,3,6-Trimethylpyrimido[5,4-E][1,2,4]triazine-5,7(1H,6H)-Dione",
      "SR-01000322764",
      "Methyltoxoflavin",
      "CPXHNWKHOFNPDO-UHFFFAOYSA-N",
      "3-Methyl Toxoflavin",
      "AC1LA2XZ",
      "32502-62-8",
      "Cid_460747",
      "SCHEMBL2180986",
      "CHEMBL601757",
      "BDBM34671",
      "CHEBI:95315",
      "KUC104460N",
      "HMS2304H14",
      "STK397047",
      "KSC-1-228",
      "MCULE-2510138695",
      "NCGC00093522-02",
      "NCGC00093522-01",
      "HY-111117",
    ],
  },
  {
    primary_id: "D0P0CX",
    names: [
      "2-Amino-6-(Toluene-3-Sulfonyl)-Benzonitrile",
      "AC1LA8DX",
      "CHEMBL54600",
      "BDBM1786",
      "CTK7C6573",
      "ZINC5931254",
      "2-Amino-6-(M-Tolylsulfonyl)Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3f",
      "2-Amino-6-(3-Methylphenyl)Sulfonylbenzonitrile",
      "2-(3-Methylphenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-[(3-Methylphenyl)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0P0DA",
    names: [
      "BM-162115",
      "115077-66-2",
      "1,6-Dioxadispiro[4.1.5.1]trideca-8,11-Diene-2,10-Dione",
      "1,6-Dioxadispiro(4.1.5.1)Trideca-8,11-Diene-2,10-Dione",
      "BM 162115",
      "ACMC-20ml0u",
      "BM 16.2115",
      "SCHEMBL8797307",
      "AC1L526V",
      "CTK0I0331",
    ],
  },
  {
    primary_id: "D0P0DT",
    names: ["TAK-044"],
  },
  {
    primary_id: "D0P0EI",
    names: ["PRLX93936"],
  },
  {
    primary_id: "D0P0EP",
    names: [
      "6-Hydroxyuridine-5'-Phosphate",
      "6-Hydroxy-UMP",
      "6-HYDROXYURIDINE-5'-PHOSPHATE",
      "6-Hydroxyuridine 5'-(Dihydrogen Phosphate)",
      "CHEMBL383923",
      "29741-00-2",
      "Prfb Acid",
      "1lor",
      "1dqx",
      "1eix",
      "6-Hydroxyuridine 5'-Phosphate",
      "AC1Q69HY",
      "AC1L4PT5",
      "5'-Uridylic Acid,6-Hydroxy-",
      "SCHEMBL2130675",
      "5'-Uridylic Acid, 6-Hydroxy-",
      "CTK4G3746",
      "DTXSID50183892",
      "Phosphoribofuranosylbarbituric Acid",
      "BDBM50199178",
      "6-Hydroxyuridine 5'-Phosphoric Acid",
      "DB02890",
      "6-HYDROXYURIDINE-5''-PHOSPHATE",
      "Barbituric Acid Ribonucleoside 5'-Phosphate",
      "1-Beta-D-Ribofuranosyl(3H)Pyrimidine-2,4,6-Trione 5''-Monophosp",
    ],
  },
  {
    primary_id: "D0P0ES",
    names: ["PMID30273516-Compound-40"],
  },
  {
    primary_id: "D0P0FN",
    names: ["ZK-164015"],
  },
  {
    primary_id: "D0P0FQ",
    names: ["Relenza IV"],
  },
  {
    primary_id: "D0P0GT",
    names: [
      "Cycloleucine",
      "1-Aminocyclopentanecarboxylic Acid",
      "52-52-8",
      "1-Aminocyclopentane-1-Carboxylic Acid",
      "Cycloleucin",
      "1-Amino-1-Cyclopentanecarboxylic Acid",
      "1-Amino-1-Carboxycyclopentane",
      "1-Amino-Cyclopentanecarboxylic Acid",
      "CYCLO-LEUCINE",
      "Cyclopentanecarboxylic Acid, 1-Amino-",
      "NSC 1026",
      "CB 1639",
      "X 201",
      "UNII-0TQU7668EI",
      "1-Aminocyclopentanecarboxylate",
      "HSDB 5195",
      "WR 14,997",
      "NSC1026",
      "1-Amino Cyclopentane Carboxylic Acid",
      "EINECS 200-144-6",
      "BRN 0636626",
      "Aminocyclopentanecarboxylic Acid",
      "Cyclopentanecarboxylic Acid, 1-Amino-, L-",
      "AI3-26442",
    ],
  },
  {
    primary_id: "D0P0HB",
    names: [
      "Ethionamide",
      "Aethionamidum",
      "Aetina",
      "Aetiva",
      "Amidazin",
      "Amidazine",
      "Atina",
      "Ethatyl",
      "Ethimide",
      "Ethina",
      "Ethinamide",
      "Ethionamidum",
      "Ethioniamide",
      "Ethylisothiamide",
      "Ethyonomide",
      "Etimid",
      "Etiocidan",
      "Etionamid",
      "Etionamida",
      "Etionamide",
      "Etioniamid",
      "Etionid",
      "Etionizin",
      "Etionizina",
      "Etionizine",
      "Fatoliamid",
      "Iridocin",
      "Iridozin",
      "Isothin",
      "Isotiamida",
      "Itiocide",
      "Nicotion",
      "Nisotin",
      "Nizotin",
      "Rigenicid",
      "Sertinon",
      "Teberus",
      "Thianid",
      "Thianide",
      "Thioamide",
      "Thiomid",
      "Thioniden",
      "Tianid",
      "Tiomid",
      "Trecator",
      "Trekator",
      "Trescatyl",
      "Trescazide",
      "Tubenamide",
      "Tubermin",
      "Tuberoid",
      "Tuberoson",
      "Ethionamid Prothionamid",
      "Etionamide [DCIT]",
      "Iridocin Bayer",
      "Trecator SC",
      "Wyeth Brand Of Ethionamide",
      "Bayer 5312",
      "Bayer5312",
      "E0695",
      "TH 1314",
      "Alpha-Ethylisonicotinic Acid Thioamide",
      "Alpha-Ethylisonicotinoylthioamide",
      "Alpha-Ethylisothionicotinamide",
      "Alpha-Ethylthioisonicotinamide",
      "Alpha-Ethylt Hioisonicotinamide",
      "Ethina (VAN)",
      "Ethionamidum [INN-Latin]",
      "Etionamida [INN-Spanish]",
      "Tio-Mid",
      "Trecator-SC",
      "Alpha-Ethyl-Thioisonicotinamide",
      "Trecator-SC (TN)",
      "Ethionamide (JP15/USP/INN)",
      "Ethionamide [USAN:INN:BAN:JAN]",
      "2-ETHYL-4-PYRIDINECARBOTHIOAMIDE",
      "2-Ethyl-4-Thioamidylpyridine",
      "2-Ethyl-4-Thiocarbamoylpyridine",
      "2-Ethyl-Thioisonicotinamide",
      "2-Ethylisonicotinic Acid Thioamide",
      "2-Ethylisonicotinic Thioamide",
      "2-Ethylisonicotinothioamide",
      "2-Ethylisonicotinoylthioamide",
      "2-Ethylisothionicotinamide",
      "2-Ethylthioisonicotinamide",
      "2-E Thylisothionicotinamide",
      "2-Ethyl-4-Aminothiocarbonylpyridine",
      "2-Ethylisonicotinic Acid Thiomide",
      "2-Ethylpyridine-4-Carbothioamide",
      "2-Ethylthioisonicotinamide, Trecator SC, Amidazine, Ethionamide",
      "3-Ethylisothionicotinamide",
    ],
  },
  {
    primary_id: "D0P0HI",
    names: [
      "AZD8330",
      "869357-68-6",
      "AZD-8330",
      "ARRY-424704",
      "ARRY-704",
      "UNII-G4990BOZ66",
      "AZD 8330",
      "2-((2-Fluoro-4-Iodophenyl)Amino)-N-(2-Hydroxyethoxy)-1,5-Dimethyl-6-Oxo-1,6-Dihydropyridine-3-Carboxamide",
      "2-(2-Fluoro-4-Iodophenylamino)-N-(2-Hydroxyethoxy)-1,5-Dimethyl-6-Oxo-1,6-Dihydropyridine-3-Carboxamide",
      "G4990BOZ66",
      "C16H17FIN3O4",
      "2-[(2-Fluoro-4-Iodophenyl)Amino]-N-(2-Hydroxyethoxy)-1,5-Dimethyl-6-Oxopyridine-3-Carboxamide",
      "RWEVIPRMPFNTLO-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0P0HT",
    names: [
      "Fluorometholone",
      "Cortilet",
      "Cortisdin",
      "Delmeson",
      "Efflumidex",
      "FML",
      "Fluaton",
      "Flucon",
      "Flumetholon",
      "Fluormetholon",
      "Fluormetholone",
      "Fluormetholonum",
      "Fluoromethalone",
      "Fluorometholonum",
      "Fluorometolona",
      "Fluorometolone",
      "Fluoropos",
      "Loticort",
      "Oxylone",
      "Trilcin",
      "Ursnon",
      "Alcon Brand Of Fluorometholone",
      "FML Forte",
      "FML Liquifilm",
      "Fluor Op",
      "Fluoro Ophtal",
      "Fluorometolone [DCIT]",
      "Isdin Brand Of Fluorometholone",
      "Isopto Flucon",
      "Novartis Brand Of Fluorometholone",
      "PMS Fluorometholone",
      "Pharm Allergan Brand Of Fluorometholone",
      "Pharmascience Brand Of Fluorometholone",
      "Ursapharm Brand Of Fluorometholone",
      "Winzer Brand Of Fluorometholone",
      "Allergan Brand 1 Of Fluorometholone",
      "Allergan Brand 2 Of Fluorometholone",
      "Allergan Brand 3 Of Fluorometholone",
      "F0414",
      "U 8614",
      "Component Of Neo-Oxylone",
      "FML (TN)",
      "FML-S Liquifilm",
      "Flarex (TN)",
      "Flucon, Isopto",
      "Fluor-Op",
      "Fluoro-Ophtal",
      "Fluorometholonum [INN-Latin]",
      "Fluorometolona [INN-Spanish]",
      "Fml (TN)",
      "Neo-Oxylone",
      "Oxylone (TN)",
      "PMS-Fluorometholone",
      "Pharm-Allergan Brand Of Fluorometholone",
      "FML S.O.P",
      "Fluor-Op (TN)",
      "Fluorometholone [INN:BAN:JAN]",
      "Fluorometholone (JP15/USP/INN)",
      "Pregna-1,4-Diene-3,20-Dione, 9-Fluoro-11.beta.,17",
      "Pregna-1,4-Diene-3,20-Dione, 9-Fluoro-11beta,17-Dihydroxy-6alpha-Methyl-(8CI)",
      "(6S,8S,9R,10S,11S,13S,14S,17R)-17-Acetyl-9-Fluoro-11,17-Dihydroxy-6,10,13-Trimethyl-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthren-3-One",
      "11beta,17alpha-Dihydroxy-9-Fluoro-6-Methyl-1,4-Pregnadiene-3,20-Dione",
      "9-Fluoro-11,17-Dihydroxy-6-Methylpregna-1,4-Diene-3,20-Dione",
      "9-Fluoro-11-Beta,17-Dihydroxy-6-Alpha-Methylpregna-1,4-Diene-3,20-Dione",
      "9-Fluoro-11beta,17-Dihydroxy-6alpha-Methylpregna-1,4-Diene-3,20-Dione",
    ],
  },
  {
    primary_id: "D0P0HU",
    names: ["GSK1070916A"],
  },
  {
    primary_id: "D0P0IE",
    names: ["Plumbagin"],
  },
  {
    primary_id: "D0P0IX",
    names: ["ISOCLOZAPINE"],
  },
  {
    primary_id: "D0P0JE",
    names: ["ISIS 3300"],
  },
  {
    primary_id: "D0P0JW",
    names: ["MG-111", "134865-04-6", "EGF, Hitachi"],
  },
  {
    primary_id: "D0P0KG",
    names: ["[3H]resolvin D1"],
  },
  {
    primary_id: "D0P0KN",
    names: ["TG02"],
  },
  {
    primary_id: "D0P0KY",
    names: ["PMID25553724-Compound-WO2011127048 33"],
  },
  {
    primary_id: "D0P0LD",
    names: ["Efgartigimod"],
  },
  {
    primary_id: "D0P0LG",
    names: ["Bbs-Arg-(D-Pip)-Gly-(SPHYEKVS)-Gly-Hir"],
  },
  {
    primary_id: "D0P0LT",
    names: ["GSK3052230"],
  },
  {
    primary_id: "D0P0MN",
    names: ["NE-28345"],
  },
  {
    primary_id: "D0P0MP",
    names: ["PMID18324760C28"],
  },
  {
    primary_id: "D0P0MR",
    names: ["PMID25666693-Compound-57"],
  },
  {
    primary_id: "D0P0NH",
    names: ["HZ-08"],
  },
  {
    primary_id: "D0P0NS",
    names: ["CD22-Targeted CAR-T Cells"],
  },
  {
    primary_id: "D0P0OE",
    names: ["Cefuzonam Sodium", "Cosmosin (TN)"],
  },
  {
    primary_id: "D0P0OU",
    names: ["OPC-34712", "Brexpiprazole"],
  },
  {
    primary_id: "D0P0PC",
    names: ["S-265744 LAP"],
  },
  {
    primary_id: "D0P0PP",
    names: [
      "GKA1",
      "CHEMBL381434",
      "SCHEMBL1794696",
      "RTMFZNZPHCZAFG-UHFFFAOYSA-N",
      "ZINC28523790",
      "BDBM50165037",
      "6-(3-Isobutoxy-5-Isopropoxy-Benzoylamino)-Nicotinic Acid",
      "6-[(3-Isobutoxy-5-Isopropoxybenzoyl)Amino]-3-Pyridinecarboxylic Acid",
      "6-[(3-Isobutoxy-5-Isopropoxvbenzoyl)Amino]-3-Pyridinecarboxylic Acid",
    ],
  },
  {
    primary_id: "D0P0PT",
    names: ["Aromatic Hydrazine Carboxyimidoamide Derivative 3"],
  },
  {
    primary_id: "D0P0QB",
    names: ["ChimeriVax-West Nile Vaccine"],
  },
  {
    primary_id: "D0P0QG",
    names: [
      "HuMV833",
      "Anti-VEGF Antibody, Toagosei",
      "MAb (Cancer), Toagosei",
      "SMART Anti-VEGF MAb, Protein Design Labs",
      "SMART Anti-VEGF, Toagosei/PDL",
    ],
  },
  {
    primary_id: "D0P0QI",
    names: [
      "VU0285683",
      "327056-22-4",
      "VU 0285683",
      "CHEMBL2164544",
      "3-Fluoro-5-(3-(Pyridin-2-Yl)-1,2,4-Oxadiazol-5-Yl)Benzonitrile",
      "GELAFISMURFRCA-UHFFFAOYSA-N",
      "SCHEMBL4197981",
      "GTPL6398",
      "MolPort-035-765-727",
      "3-Fluoro-5-(3-Pyridin-2-Yl-1,2,4-Oxadiazol-5-Yl)Benzonitrile",
      "ZINC95554357",
      "BDBM50395943",
      "AKOS024458130",
      "NCGC00379115-01",
      "KB-276106",
      "B5569",
      "3-(2-Pyridyl)-5-(3-Cyano-5-Fluorophenyl)-1,2,4-Oxadiazole",
      "3-Fluoro-5-[3-(2-Pyridinyl)-1,2,4-Oxadiazol-5-Yl]-Benzonitrile",
    ],
  },
  {
    primary_id: "D0P0QK",
    names: [
      "Cysteine Hydrochloride",
      "DL-Cysteine Hydrochloride",
      "10318-18-0",
      "2-Amino-3-Mercaptopropanoic Acid Hydrochloride",
      "CYSTEINE HYDROCHLORIDE",
      "H-DL-Cys-OH.HCl",
      "(+-)-Cysteine Hydrochloride",
      "DL-CYSTEINE HCL",
      "CYSTEINE, HYDROCHLORIDE, DL-",
      "EINECS 233-698-2",
      "DL-Cysteine Hydrochloride Hydrate",
      "NSC8746",
      "DL-Cysteine Hydrochloride Anhydrous",
      "MFCD00064552",
      "2-Amino-3-Sulfanylpropanoic Acid Hydrochloride",
      "C-9675",
      "DL-Cysteine Hydrochloride, 95%, Anhydrous",
      "AK-73021",
      "L-Cysteine, Hydrochloride (1:1)",
      "Cysteine Chlorohydrate",
      "H-DL-Cys-OH?Cl",
      "L-Cystein-Hydrochloride",
    ],
  },
  {
    primary_id: "D0P0RB",
    names: ["H-Tyr-D-Ala-Gly-Phe-Pro-Leu-Trp-NMe-Bzl"],
  },
  {
    primary_id: "D0P0RD",
    names: ["3-(3,4-Dichloro-Phenyl)-6,7-Dimethoxy-Quinoline", "CHEMBL67702", "SCHEMBL8502992", "BDBM50039074"],
  },
  {
    primary_id: "D0P0RP",
    names: ["P113D"],
  },
  {
    primary_id: "D0P0RX",
    names: ["Nemonoxacin"],
  },
  {
    primary_id: "D0P0RY",
    names: [
      "3-[(2-Methyl-4-Thiazolyl)Ethynyl]benzonitrile",
      "CHEMBL201943",
      "MTEB",
      "SCHEMBL4153877",
      "Benzonitrile, 3-[2-(2-Methyl-4-Thiazolyl)Ethynyl]-",
      "GTPL1428",
      "3-[2-(2-Methyl-1,3-Thiazol-4-Yl)Ethynyl]benzonitrile",
      "BDBM50181745",
    ],
  },
  {
    primary_id: "D0P0SD",
    names: ["Eryaspase"],
  },
  {
    primary_id: "D0P0SM",
    names: [
      "Almogran",
      "Almotriptan",
      "Axert",
      "Almotriptan (USAN)",
      "Axert (TN)",
      "LAS-31416",
      "PNU-180638",
      "Almotriptan [USAN:INN:BAN]",
      "N,N-Dimethyl-2-[5-(Pyrrolidin-1-Ylsulfonylmethyl)-1H-Indol-3-Yl]ethanamine",
      "N,N-Dimethyl-2-{5-[(Pyrrolidin-1-Ylsulfonyl)Methyl]-1H-Indol-3-Yl}ethanamine",
      "1-(((3-(2-(Dimethylamino)Ethyl)Indol-5-Yl)Methyl)Sulfonyl)Pyrrolidine",
    ],
  },
  {
    primary_id: "D0P0SR",
    names: ["Kyproliscarfilzomib"],
  },
  {
    primary_id: "D0P0SY",
    names: ["ISIS 102609"],
  },
  {
    primary_id: "D0P0SZ",
    names: ["Piperaquine"],
  },
  {
    primary_id: "D0P0TA",
    names: ["GC-1008"],
  },
  {
    primary_id: "D0P0TI",
    names: ["TBPS"],
  },
  {
    primary_id: "D0P0TR",
    names: ["Meluadrine"],
  },
  {
    primary_id: "D0P0UM",
    names: ["7-Chloro-1,3-Dihydroxyacridone"],
  },
  {
    primary_id: "D0P0US",
    names: [
      "4-Chloro-6-Nitro-2-Piperazin-1-Yl-Quinoline",
      "4-CHLORO-6-NITRO-2-(PIPERAZIN-1-YL)QUINOLINE",
      "437708-76-4",
      "CHEMBL423269",
      "SCHEMBL6365676",
      "CTK4I7722",
      "DTXSID00438329",
      "ZINC13439731",
      "BDBM50110578",
      "AKOS027381012",
      "2-Piperazino-4-Chloro-6-Nitroquinoline",
      "ACM437708764",
      "KB-334255",
    ],
  },
  {
    primary_id: "D0P0UV",
    names: [
      "2-Chloro-N-(6-Cyanopyridin-3-Yl)Propanamide",
      "1112994-35-0",
      "SCHEMBL1483919",
      "CHEMBL446834",
      "VFOLQYOVUCHHET-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0P0VD",
    names: ["Co-Trimoxazole"],
  },
  {
    primary_id: "D0P0WF",
    names: ["PMID25684022-Compound-WO2011137219 35(1-11)"],
  },
  {
    primary_id: "D0P0WG",
    names: ["Ethidium"],
  },
  {
    primary_id: "D0P0WT",
    names: [
      "5,7-Dimethoxy-3-Pyridin-4-Yl-Quinoline",
      "5,7-Dimethoxy-3-(4-Pyridinyl)Quinoline",
      "137206-97-4",
      "5,7-Dimethoxy-3-(Pyridin-4-Yl)Quinoline",
      "CHEMBL68423",
      "Quinoline,5,7-Dimethoxy-3-(4-Pyridinyl)-",
      "5,7-Dmpq",
      "Bio1_000429",
      "Tocris-1222",
      "ACMC-20c86u",
      "AC1L1F9B",
      "AC1Q4YD3",
      "SCHEMBL3652915",
      "CTK4C0691",
      "DTXSID90160107",
      "Bio1_001407",
      "Bio1_000918",
      "ZINC2581408",
      "BDBM50039655",
      "AKOS030239521",
      "5,7-Dimethoxy-3-(4-Pyridyl)Quinoline",
      "5,7-Dimethoxy-3-Pyridin-4-Ylquinoline",
      "NCGC00025053-01",
      "AJ-43025",
      "KB-291722",
      "DB-063190",
    ],
  },
  {
    primary_id: "D0P0WV",
    names: ["Interferon Alfa-2b"],
  },
  {
    primary_id: "D0P0XA",
    names: ["Ibogaine"],
  },
  {
    primary_id: "D0P0XF",
    names: ["Ac-Asp-Glu-Val-Asp-CHO"],
  },
  {
    primary_id: "D0P0XO",
    names: [
      "3-Methyl-2(1H)-Thioxo-4(3H)-Quinazolinone",
      "1705-09-5",
      "2-Mercapto-3-Methylquinazolin-4(3H)-One",
      "2-Mercapto-3-Methyl-3H-Quinazolin-4-One",
      "CHEMBL460734",
      "3-Methyl-2-Sulfanylidene-1H-Quinazolin-4-One",
      "2-Mercapto-3-Methyl-3,4-Dihydroquinazolin-4-One",
      "3-Methyl-2-Thioxo-2,3-Dihydroquinazolin-4(1H)-One",
      "3-Methyl-2-Thioxo-2,3-Dihydro-1H-Quinazolin-4-One",
      "4(1H)-Quinazolinone, 2,3-Dihydro-3-Methyl-2-Thioxo-",
      "3-Methyl-2-Thioxo-1H-Quinazolin-4-One",
      "3-Methyl-2-Thioxo-2,3-Dihydro-4(1H)-Quinazolinone",
      "3-Methyl-2-Sulfanylquinazolin-4(3H)-One",
    ],
  },
  {
    primary_id: "D0P0XX",
    names: ["Avermectins"],
  },
  {
    primary_id: "D0P0YD",
    names: ["TNF-Targeting Small Molecule Therapeutic, Oral, Inflammation/Autoimmune Disease"],
  },
  {
    primary_id: "D0P0YT",
    names: ["Diphtheria Antitoxin"],
  },
  {
    primary_id: "D0P0YU",
    names: ["HaNK For Infusion"],
  },
  {
    primary_id: "D0P0ZB",
    names: [
      "F3",
      "1-Chloro-1,2,2-Trifluorocyclobutane",
      "661-71-2",
      "Cyclobutane, 1-Chloro-1,2,2-Trifluoro-",
      "1-Ctfcb",
      "AC1Q4KXO",
      "AC1L2WN1",
      "GTPL2400",
      "SCHEMBL3123282",
      "CTK5C3598",
      "MolPort-001-773-111",
      "ZX-AP007208",
      "7606AE",
      "PC2028",
      "7607AE",
      "FCH917920",
      "SBB086362",
      "MFCD00042139",
      "AKOS006230600",
      "Cyclobutane,1-Chloro-1,2,2-Trifluoro-",
      "TR-022419",
      "FT-0607576",
      "C-47497",
      "I14-28177",
    ],
  },
  {
    primary_id: "D0P0ZQ",
    names: ["4-(Isopropylthio)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL453740", "BDBM50247976"],
  },
  {
    primary_id: "D0P0ZY",
    names: ["Pomaglumetad"],
  },
  {
    primary_id: "D0P1AH",
    names: ["Pyrrolo-Pyrimidine Derivative 4"],
  },
  {
    primary_id: "D0P1AS",
    names: ["Rexin-G"],
  },
  {
    primary_id: "D0P1BE",
    names: ["Benzene Sulfonamide Derivative 3"],
  },
  {
    primary_id: "D0P1BK",
    names: ["NC1153"],
  },
  {
    primary_id: "D0P1BM",
    names: ["CAR-GPC3 T Cells"],
  },
  {
    primary_id: "D0P1BR",
    names: ["VII-87-B", "Antihypertensive Agent, Synvax Pharmaceuticals"],
  },
  {
    primary_id: "D0P1BZ",
    names: [
      "Daclizumab",
      "DAC HYP",
      "Daclizumab HYP",
      "Daclizumab High Yield Process",
      "Daclizumab (Subcutaneous, Multiple Sclerosis)",
      "Daclizumab (Subcutaneous, Multiple Sclerosis), Abbott/Biogen Idec",
    ],
  },
  {
    primary_id: "D0P1CE",
    names: ["CYT 99007"],
  },
  {
    primary_id: "D0P1CO",
    names: [
      "S-(3,4-Dichlorobenzyl)Isothiourea Hydrochloride",
      "22816-60-0",
      "MreB Perturbing Compound A22",
      "2-(3,4-Dichloro-Benzyl)-Isothiourea Hydrochloride",
      "S-(3,4-Dichlorobenzyl)Isothiourea, HCl",
      "3,4-Dichlorobenzyl Carbamimidothioate Hydrochloride",
      "2-[(3,4-Dichlorophenyl)Methyl]isothiourea Hydrochloride",
      "2-(3,4-DICHLORO-BENZYL)-ISOTHIOUREA",
      "{[(3,4-Dichlorophenyl)Methyl]sulfanyl}methanimidamide Hydrochloride",
      "CHEMBL1224484",
      "Amino[(3,4-Dichlorobenzyl)Sulfanyl]methaniminium Chloride",
      "A 22 Hydrochloride",
      "AC1MD4YH",
    ],
  },
  {
    primary_id: "D0P1CP",
    names: ["H-Dmt-Tic-(2S,3S)-Beta-MeCha-Phe-NH2", "CHEMBL269314"],
  },
  {
    primary_id: "D0P1CV",
    names: ["PHA-793887", "Cyclin-Dependent Kinase Inhibitor (Cancer), Nerviano"],
  },
  {
    primary_id: "D0P1DI",
    names: ["MCLA-128"],
  },
  {
    primary_id: "D0P1DP",
    names: ["NK-2210", "CDK Inhibitor (Neurological Disease), Neurokin"],
  },
  {
    primary_id: "D0P1EB",
    names: ["NIS215"],
  },
  {
    primary_id: "D0P1FJ",
    names: [
      "Aprophen",
      "Aprofene",
      "Aprofen",
      "Aprophenum",
      "Aprofene [INN]",
      "3563/1/7",
      "2-Diethylaminoethyl 2,2-Diphenylpropionate",
      "Aprofenum [INN-Latin]",
      "Aprofeno [INN-Spanish]",
      "UNII-PL791XXJ7B",
      "PL791XXJ7B",
      "CHEMBL26505",
      "2-(Diethylamino)Ethyl 2,2-Diphenylpropanoate",
      "2,2-Diphenylpropionic Acid 2-Diethylaminoethyl Ester",
      "BRN 2509326",
      "NCGC00160676-01",
      "Propionic Acid, 2,2-Diphenyl-, 2-(Diethylamino)Ethyl Ester",
      "DSSTox_CID_26294",
      "DSSTox_RID_81519",
      "DSSTox_GSID_46294",
      "Alpha,alpha-Diphenylpropionic Acid Beta-Diethylaminoethyl Ester",
    ],
  },
  {
    primary_id: "D0P1FO",
    names: [
      "Marinol",
      "Dronabinol",
      "TETRAHYDROCANNABINOL",
      "Delta9-Tetrahydrocannabinol",
      "Delta9-THC",
      "Deltanyne",
      "Dronabinolum",
      "Delta-9-Tetrahydrocannabinol",
      "Abbott 40566",
      "Delta-9-THC",
      "Delta(9)-THC",
      "1972/8/3",
      "Delta1-THC",
      "Delta(Sup 1)-Thc",
      "Delta(Sup 9)-Thc",
      "1-Trans-Delta-9-Tetrahydrocannabinol",
      "THC",
      "Namisol",
      "Dronabinolum [Latin]",
      "9-Tetrahydrocannabinol",
      "Delta(9)-Tetrahydrocannabinol",
      "Delta(1)-Tetrahydrocannabinol",
      "(-)-Delta9-Trans-Tetrahydrocannabinol",
      "Delta1-Tetrahydrocannabinol",
      "1-Trans-Delta9-Tetrahydrocannabinol",
      "Delta(9)-Tetrahydrocannibinol",
      "SP",
      "Compassia",
      "Ganja",
      "Hashish",
      "Marincap",
      "MaxEPA",
      "Omegaven",
      "Promega",
      "Relivar",
      "Sonergx",
      "Tetranabinex",
      "Cannabis Resin",
      "Drona Binol",
      "QCD 84924",
      "SP 104",
      "Tetrahydrocannabinol Delta9",
      "CAT-310",
      "DELTA1-THC",
      "DELTA1-Tetrahydrocannabinol;DELTA9-THC",
      "DRG-0138",
      "Delta-THC",
      "Delta9-Tetrahydrocannabinol Solution",
      "Dronabinol [USAN:INN]",
      "Marinol (TN)",
      "Pro-Mega;QCD-84924",
      "Trans-Tetrahydrocannabinol",
      "DELTA9-Trans-Tetrahydrocannabinol",
      "Delta(1)-THC",
      "Delta(Sup 1)-Tetrahydrocannabinol",
      "Delta(Sup 9)-Tetrahydrocannabinol",
      "Delta(Sup9)-THC",
      "Delta1-Tetrahydrocannabinol (VAN)",
      "Delta9-Tetrahydrocannabinol (VAN)",
      "Delta9-Trans-Tetrahydrocannabinol",
      "Dronabinol (USP/INN)",
      "Omega-3-Fatty Acid",
      "THC-Delta-9",
      "Trans-Delta9-Tetrahydrocannabinol",
      "Cannabinol, Tetrahydro-(6CI)",
      "Fish Oils, N-3 Fatty Acid-High",
      "Fish Oils, Omega-3 Fatty Acid-High",
      "L-Delta(Sup 1)-Tetrahydrocannabinol",
      "L-Delta1-Trans-Tetrahydrocannabinol",
      "L-Trans-Delta9-Tetrahydrocannabinol",
      "Trans-Delta-9-Tetrahydrocannabinol",
      "Cannabinol, Delta1-Tetrahydro-(7CI)",
      "Fats And Glyceridic Oils, Fish, N-3 Fatty Acid-High",
      "L-Delta1-Tetrahydrocannabinol",
      "Trans-DELTA9-Tetrahydrocannabinol",
      "L-Delta1-Trans-Tetrahydrocannabinol;L-Trans-Delta9-Tetrahydrocannabinol",
      "Tetrahydrocannabinols (-)-Delta1-3,4-Trans-Form",
      "(-)-DELTA1-Tetrahydrocannabinol",
      "(-)-DELTA9-THC",
      "(-)-DELTA9-Tetrahydrocannabinol",
      "(-)-DELTA9-Trans-Tetrahydrocannabinol",
      "(-)-Delta(Sup9)-Trans-Tetrahydrocannabinol",
      "(-)-3,4-Trans-Delta1-Tetrahydrocannabinol",
      "(-)-Delta(Sup 1)-3,4-Trans-Tetrahydrocannabinol",
      "(-)-Delta1-Tetrahydrocannabinol",
      "(-)-Delta9-(Trans)-Tetrahydrocannabinol",
      "(-)-Delta9-Tetrahydrocannabinol",
      "(-)-Trans-DELTA9-Tetrahydrocannabinol",
      "(-)-Trans-Delta1-Tetrahydrocannabinol",
      "(-)-Trans-Delta9-THC",
      "(-)-Trans-Delta9-Tetrahydrocannabinol",
      "(L)-Delta1-Tetrahydrocannabinol",
      "(L)-Delta(Sup 1)-Tetrahydrocannabinol",
      "1-Trans-Delta(Sup9)-Tetrahydrocannabinol",
      "1-Trans-D9-Tetrahydrocannabinol",
      "1-Trans-Delta(Sup 9)-Tetrahydrocannabinol",
      "14C-DELTA1-Tetrahydrocannabinol",
      "6H-Dibenzo",
      "9-Delta-Tetrahydrocannabinol",
      "9-Ene-Tetrahydrocannabinol",
    ],
  },
  {
    primary_id: "D0P1FZ",
    names: ["Osteoblast Cell Therapy"],
  },
  {
    primary_id: "D0P1HA",
    names: ["OBABERINE"],
  },
  {
    primary_id: "D0P1HC",
    names: ["Ac-WKY-NH2", "CHEMBL1164996"],
  },
  {
    primary_id: "D0P1HI",
    names: ["ROCK-1 Inhibitors"],
  },
  {
    primary_id: "D0P1HR",
    names: ["PMID29473428-Compound-21"],
  },
  {
    primary_id: "D0P1HZ",
    names: ["CHAP1", "CHEMBL99392", "Chap-1", "AC1OCEVW", "BDBM50082325"],
  },
  {
    primary_id: "D0P1ID",
    names: [
      "MTRX-1011A",
      "Anti-CD4 MAb, Roche Anti-CD4 MAb, Tolerx-2 MTRX-1011A (IV) MTRX-1011A (SC) MTRX-1011A MTRX-1011A (IV), MTRX-1011A (SC) RG-7424, RG-7424 (IV) RG-7424 (SC), RG-7424 RG-7424 (IV), RG-7424 (SC)",
    ],
  },
  {
    primary_id: "D0P1IJ",
    names: [
      "CytoCor",
      "Corneal Epithelial Cell Therapy (Corneal Wound Healing), International Stem Cell Corp",
      "Corneal Cell Therapy (Parthenogenetic Stem-Cell Derived), ISCO",
    ],
  },
  {
    primary_id: "D0P1IK",
    names: ["1,2-Bis(2,3,4-Trifluorophenyl)Ethane-1,2-Dione", "CHEMBL242931"],
  },
  {
    primary_id: "D0P1IX",
    names: ["OT-551"],
  },
  {
    primary_id: "D0P1IZ",
    names: ["Gadobutrol"],
  },
  {
    primary_id: "D0P1JA",
    names: ["LETRAZURIL"],
  },
  {
    primary_id: "D0P1JI",
    names: ["Wortmannin"],
  },
  {
    primary_id: "D0P1JJ",
    names: ["6-Ethylamino-2-(3''-Indolyl)Ethyloxy)Adenosine"],
  },
  {
    primary_id: "D0P1JL",
    names: ["Avaxim"],
  },
  {
    primary_id: "D0P1JQ",
    names: [
      "D-Leucyl-N-(3-Chlorobenzyl)-L-Prolinamide",
      "CHEMBL1229801",
      "DB06911",
      "N-(3-Chlorobenzyl)-1-[(2R)-2-Amino-4-Methylpentanoyl]-L-Prolinamide",
      "(2S)-1-[(2R)-2-Amino-4-Methylpentanoyl]-N-[(3-Chlorophenyl)Methyl]pyrrolidine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0P1JV",
    names: ["Bio-Flow"],
  },
  {
    primary_id: "D0P1KJ",
    names: ["SOM1201"],
  },
  {
    primary_id: "D0P1KV",
    names: ["PMID27454349-Compound-100"],
  },
  {
    primary_id: "D0P1LP",
    names: ["PHENIDONE"],
  },
  {
    primary_id: "D0P1LR",
    names: ["Pemedolac", "Dexpemedolac", "AY-30715", "PEM-420", "WAY-PEM-420"],
  },
  {
    primary_id: "D0P1ME",
    names: ["MK-3222"],
  },
  {
    primary_id: "D0P1MI",
    names: [
      "MDDR 347017",
      "4(S)-[2(S)-[3-(5-Methylisoxazol-3-Ylcarboxamido)-2-Oxo-1,2-Dihydropyridin-1-Yl]-4-Pentynamido]-5-[2-Oxopyrrolidin-3(S)-Yl]-2(E)-Pentenoic Acid Ethyl Ester",
    ],
  },
  {
    primary_id: "D0P1MT",
    names: ["KNI-10743"],
  },
  {
    primary_id: "D0P1PC",
    names: ["PMID25399762-Compound-Figure2-Houttuynoid A"],
  },
  {
    primary_id: "D0P1QN",
    names: ["ND-701", "Azole Derivatives (Tuberculosis), NeED Pharma"],
  },
  {
    primary_id: "D0P1QW",
    names: [
      "Becampanel",
      "AMP397",
      "AMP-397",
      "AMP-397A",
      "[(7-Nitro-2,3-Dioxo-1,4-Dihydroquinoxalin-5-Yl)Methylamino]methylphosphonic Acid",
    ],
  },
  {
    primary_id: "D0P1RC",
    names: ["BMP-655"],
  },
  {
    primary_id: "D0P1RL",
    names: ["Iodofiltic Acid I-123"],
  },
  {
    primary_id: "D0P1RQ",
    names: ["N-(2-Aminophenyl)-4-(Chroman-3-Ylmethyl)Benzamide", "CHEMBL238569", "SCHEMBL1064835"],
  },
  {
    primary_id: "D0P1RV",
    names: ["ATX-GD"],
  },
  {
    primary_id: "D0P1SA",
    names: [
      "Autologous Endothelial Progenitor Cell Therapy",
      "Autologous Endothelial Progenitor Cell Therapy (Ischemia)",
      "Autologous Endothelial Progenitor Cell Therapy (Ischemia), IBRI",
      "Autologous Stem Cell Therapy (Critical Limb Ischemia), IBRI",
      "Autologous CD34+ Cells (Peripheral Vascular Disease), IBRI",
    ],
  },
  {
    primary_id: "D0P1TH",
    names: ["Imidazo[1,2-B]pyridazine Acetamide Derivative 2"],
  },
  {
    primary_id: "D0P1TY",
    names: [
      "3-(Tetradecylamino)Propylphosphonic Acid",
      "CHEMBL334213",
      "725724-60-7",
      "CTK2H2323",
      "DTXSID50658353",
      "BDBM50148403",
      "(3-Tetradecylamino-Propyl)-Phosphonic Acid",
      "[3-(Tetradecylamino)Propyl]phosphonic Acid",
      "Phosphonic Acid, [3-(Tetradecylamino)Propyl]-",
    ],
  },
  {
    primary_id: "D0P1TZ",
    names: ["MLN-977"],
  },
  {
    primary_id: "D0P1UC",
    names: ["2-(5-Phenyl-Furan-2-Yl)-4,5-Dihydro-1H-Imidazole"],
  },
  {
    primary_id: "D0P1UO",
    names: ["1-(4-Cyanobenzyl)-5-(4-Fluorophenyl)-1H-Imidazole", "CHEMBL598790"],
  },
  {
    primary_id: "D0P1US",
    names: [
      "[125I]SB-236636",
      "GTPL2710",
      "(2S)-3-(4-{2-[(1,3-Benzoxazol-2-Yl)(Methyl)Amino]ethoxy}-3-(125I)Iodophenyl)-2-Ethoxypropanoic Acid",
    ],
  },
  {
    primary_id: "D0P1UW",
    names: ["PO3 2-Ile-Trp-O-3K", "CHEMBL281785"],
  },
  {
    primary_id: "D0P1UX",
    names: [
      "Venlafaxine",
      "Efectin",
      "Elafax",
      "Venlafaxina",
      "VenlafaxineXR",
      "Venlafaxinum",
      "Venlafaxine ER",
      "Wy 45030",
      "Efectin (TN)",
      "Venlafaxina [INN-Spanish];Venlafaxine (Effexor)",
      "Venlafaxine (INN)",
      "Venlafaxine [BAN:INN]",
      "Venlafaxine [INN:BAN]",
      "Venlafaxinum [INN-Latin]",
      "N,N-Dimethyl-2-(1-Hydroxycyclohexyl)-2-(4-Methoxyphenyl)Ethylamine",
      "1-[(1R)-2-(Dimethylamino)-1-(4-Methoxyphenyl)Ethyl]cyclohexanol",
      "1-[2-(Dimethylamino)-1-(4-Methoxyphenyl)Ethyl]cyclohexanol",
      "1-[2-(Dimethylamino)-1-(4-Methoxyphenyl)Ethyl]cyclohexan-1-Ol",
      "1-[2-Dimethylamino-1-(4-Methoxyphenyl)Ethyl]cyclohexan-1-Ol",
      "1-{2-(Dimethylamino)-1-[4-(Methyloxy)Phenyl]ethyl}cyclohexanol",
    ],
  },
  {
    primary_id: "D0P1VT",
    names: ["Pyrimidinone Derivative 1"],
  },
  {
    primary_id: "D0P1WA",
    names: [
      "Tiotropium",
      "186691-13-4",
      "UNII-0EB439235F",
      "0EB439235F",
      "[3H]tiotropium",
      "(1r,2r,4s,5s,7s)-7-{[hydroxy(Dithiophen-2-Yl)Acetyl]oxy}-9,9-Dimethyl-3-Oxa-9-Azoniatricyclo[3310~2,4~]nonane",
      "NCGC00167971-01",
      "0HK",
      "(1R,2R,4S,5S)-7-{[2-Hydroxy-2,2-Bis(Thiophen-2-Yl)Acetyl]oxy}-9,9-Dimethyl-3-Oxa-9-Azatricyclo[3310^{2,4}]nonan-9-Ium",
      "GTPL367",
      "SCHEMBL4662461",
      "GTPL8592",
      "DTXSID5044281",
      "CHEMBL3305968",
      "Spiriva (TN)",
      "Tiotropium Bromide [USAN:INN]",
      "(1A,2A,4A,5A,7A)-7-[(Hydroxydi-2-Thienylacetyl)Oxy]-9,9-Dimethyl-3-Oxa-9-Azoniatri-Cyclo[331024]nonane Bromide",
      "(1a,2,4,5a,7)-7-[(2-Hydroxy-2,2-Di-2-Thienylacetyl)Oxy]-9,9-Dimethyl-3-Oxa-9-Azoniatricyclo[33102,4]nonane Bromide",
      "7-{[hydroxy(Dithiophen-2-Yl)Acetyl]oxy}-9,9-Dimethyl-3-Oxa-9-Azoniatricyclo[33102,4]nonane Bromide",
    ],
  },
  {
    primary_id: "D0P1WB",
    names: ["EVT-301"],
  },
  {
    primary_id: "D0P1WR",
    names: ["ZP-004"],
  },
  {
    primary_id: "D0P1XH",
    names: [
      "1-D-Glucopyranosyl Uracil",
      "CHEMBL1096135",
      "1-Beta-D-Glucopyranosylpyrimidine-2,4(1h,3h)-Dione",
      "3bcs",
      "CJB",
      "3t3d",
      "1-D-Glucopyranosyl-Uracil",
      "SCHEMBL8712423",
      "BDBM50316587",
    ],
  },
  {
    primary_id: "D0P1XM",
    names: ["L-736281"],
  },
  {
    primary_id: "D0P1XQ",
    names: ["SEL-73", "Antipsychotic, Selvita"],
  },
  {
    primary_id: "D0P1YC",
    names: ["(R)(+)-7-Methyl-2-P-Tolylchroman-4-One", "CHEMBL598321"],
  },
  {
    primary_id: "D0P1YE",
    names: ["Orvepitant"],
  },
  {
    primary_id: "D0P1YH",
    names: ["S-1746"],
  },
  {
    primary_id: "D0P1YK",
    names: ["6-Nitro-2-(4-Phenoxy-Benzyl)-1H-Benzoimidazole", "CHEMBL63146"],
  },
  {
    primary_id: "D0P1YO",
    names: ["MOL-218"],
  },
  {
    primary_id: "D0P1ZM",
    names: ["6-(Naphthalene-1-Sulfonyl)-2H-Pyridazin-3-One", "CHEMBL194582", "SCHEMBL6206673"],
  },
  {
    primary_id: "D0P1ZR",
    names: [
      "Molecule 25",
      "Capsid Assembly Inhibitor (HIV-1 Infection)",
      "Capsid Assembly Inhibitor (HIV-1 Infection), Boehringer Ingelheim",
    ],
  },
  {
    primary_id: "D0P2AB",
    names: ["Azole Derivative 3"],
  },
  {
    primary_id: "D0P2AC",
    names: ["CH-4938056"],
  },
  {
    primary_id: "D0P2AG",
    names: ["Formoterol/Budesonide"],
  },
  {
    primary_id: "D0P2AK",
    names: ["Immune Globulin Subcutaneous, 20% Liquid"],
  },
  {
    primary_id: "D0P2AL",
    names: ["YJP-40101"],
  },
  {
    primary_id: "D0P2AR",
    names: ["ISIS 103629"],
  },
  {
    primary_id: "D0P2AZ",
    names: [
      "1-(4-Nonylbenzyl)Azetidine-3-Carboxylic Acid",
      "CHEMBL224623",
      "570423-68-6",
      "SCHEMBL13271128",
      "CTK1F3133",
      "DTXSID30436176",
      "BDBM50158342",
      "AKOS030540874",
      "3-Azetidinecarboxylic Acid, 1-[(4-Nonylphenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D0P2BD",
    names: ["SAFIRONIL"],
  },
  {
    primary_id: "D0P2BJ",
    names: ["Quinazoline Derivative 13"],
  },
  {
    primary_id: "D0P2BV",
    names: ["Human Thrombin"],
  },
  {
    primary_id: "D0P2BY",
    names: [
      "[2-(Benzoimidazol-1-Yl)Ethyl]-Bisphosphonic Acid",
      "CHEMBL457423",
      "AC1NQQY7",
      "[2-(Benzoimidazol-1-Yl)Ethylidene]bisphosphonic Acid",
      "SCHEMBL20385726",
      "BDBM50273716",
      "847738-31-2",
      "[2-(Benzimidazol-1-Yl)-1-Phosphonoethyl]phosphonic Acid",
      "Phosphonic Acid, [2-(1H-Benzimidazol-1-Yl)Ethylidene]bis-",
      "[2-(Benzimidazol-1-Yl)-1-Phosphono-Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0P2CK",
    names: [
      "Estazolam",
      "Cannoc",
      "Esilgan",
      "Estazolamum",
      "Eurodin",
      "Julodin",
      "Nemurel",
      "Nuctalon",
      "ProSom",
      "Somnatrol",
      "Tasedan",
      "Abbott Brand Of Estazolam",
      "HormonaBrand Of Estazolam",
      "Takeda Brand Of Estazolam",
      "Abbott 47631",
      "D 40TA",
      "D40TA",
      "U 33737",
      "D-40TA",
      "Estazolamum [INN-Latin]",
      "Eurodin (TN)",
      "ProSom (TN)",
      "Prosom (TN)",
      "Estazolam [USAN:INN:JAN]",
      "Estazolam (JP15/USAN/INN)",
      "4H-(1,2,4)Triazolo(4,3-A)(1,4)Benzodiazepine, 8-Chloro-6-Phenyl",
      "8-Chloro-6-Phenyl-4H-(1,2,4)Triazolo-(4,3-A)(1,4)Benzodiazepine",
      "8-Chloro-6-Phenyl-4H-[1,2,4]triazolo[4,3-A][1,4]benzodiazepine",
      "8-Chloro-6-Phenyl-4H-S-Triazolo(4,3-A)(1,4)Benzodiazepine",
      "8-Chloro-6-Phenyl-4H-S-Triazolo(4,3-A)(1,4)Benzodiazepine (IUPAC)",
      "8-Chloro-6-Phenyl-4H-S-Triazolo[4,3-A][1,4]benzodiazepine",
      "8-Chloro-6-Phenyl-4H-S-Triazolo[4,3-(Alpha)] [1,4]benzodiazepine",
    ],
  },
  {
    primary_id: "D0P2CS",
    names: ["EVANS BLUE"],
  },
  {
    primary_id: "D0P2CZ",
    names: ["SPH-260-4", "Delmopinol (Bacterial Infection), Sinclair IS Pharma"],
  },
  {
    primary_id: "D0P2DB",
    names: [
      "7,12-Dihydro-5,7,12-Triaza-Indeno[1,2-A]fluorene",
      "CHEMBL301605",
      "7,12-Dihydropyrido[3,2-B:5,4-B']diindole",
      "NCI60_006891",
      "AC1L7HBP",
      "AC1Q4YR3",
      "CTK5J6980",
      "ZINC12941871",
    ],
  },
  {
    primary_id: "D0P2DF",
    names: ["Ac-YGGFL-NH2", "CHEMBL500212"],
  },
  {
    primary_id: "D0P2DU",
    names: ["Prodipine"],
  },
  {
    primary_id: "D0P2DV",
    names: ["4-(2-(4-Fluorophenyl)Ethynyl)-2-Methylthiazole", "CHEMBL202125", "SCHEMBL4146642"],
  },
  {
    primary_id: "D0P2ED",
    names: ["GRC-17536"],
  },
  {
    primary_id: "D0P2ET",
    names: ["Garveatin E"],
  },
  {
    primary_id: "D0P2EX",
    names: ["M-Enoxaparin"],
  },
  {
    primary_id: "D0P2EY",
    names: ["ISIS 101983"],
  },
  {
    primary_id: "D0P2FC",
    names: ["ADMDD-15", "ADMDD-15 (Recombinant Protein, Cancer), Applied Integrin Sciences"],
  },
  {
    primary_id: "D0P2GA",
    names: ["CART-Meso Cells"],
  },
  {
    primary_id: "D0P2GB",
    names: ["MR-1526"],
  },
  {
    primary_id: "D0P2GE",
    names: ["6,7-Dimethoxy-4-(2-Phenylethynyl)Quinazoline", "CHEMBL250315", "SCHEMBL6180450", "BDBM50222433"],
  },
  {
    primary_id: "D0P2GK",
    names: [
      "Sodium Phenylbutyrate",
      "Ammonaps",
      "Buphenyl",
      "EL-532",
      "Sodium 4-Phenylbutyrate",
      "Sodium Phenylbutyrate, Elan",
      "Sodium Phenylbutyrate, Medicis",
      "Sodium Phenylbutyrate, Ucyclyd",
      "VP-101",
      "Sodium 4-Phenylbutyrate, Elan",
      "Sodium 4-Phenylbutyrate, Ucyclyd",
      "Sodium Phenylbutyrate (Cancer), MacroChem/Access",
      "Sodium Phenylbutyrate (Cancer), Virium/ Somanta",
      "Sodium Phenylbutyrate (Cancer), Virium/Access Pharmaceuticals",
      "Sodium 4-Phenyibutyrate (Cancer), MacroChem/Access",
      "Sodium 4-Phenylbutyrate (Cancer), Virium/ Somanta",
      "Sodium 4-Phenylbutyrate (Cancer), Virium/Access Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0P2GU",
    names: ["CSL-362-AML"],
  },
  {
    primary_id: "D0P2HR",
    names: ["[3H]granisetron", "[3H]-Granisetron", "[3H]-BRL-43694"],
  },
  {
    primary_id: "D0P2HX",
    names: ["RS-25344"],
  },
  {
    primary_id: "D0P2IJ",
    names: [
      "CPPZ",
      "CHEMBL1289113",
      "1-(4-(2-Chloro-4-Fluorophenyl)Piperazin-1-Yl)-2-(Pyridin-4-Ylmethoxy)Ethanone",
      "1-[4-(2-Chloro-4-Fluorophenyl)Piperazin-1-Yl]-2-(Pyridin-4-Ylmethoxy)Ethanone",
      "SCHEMBL1904664",
      "GTPL6416",
      "BDBM50331975",
    ],
  },
  {
    primary_id: "D0P2IN",
    names: ["5'-Amino-5'-Deoxy-Alpha-D-Thymidine", "CHEMBL428720", "BDBM50223794", "ZINC17000414"],
  },
  {
    primary_id: "D0P2IT",
    names: ["Asiaticoside", "Asiaticoside,(S)", "CHEMBL1789965", "WYQVAPGDARQUBT-JVGKFWCXSA-N", "16830-15-2"],
  },
  {
    primary_id: "D0P2IW",
    names: ["Peramivir"],
  },
  {
    primary_id: "D0P2JH",
    names: ["Sulfur Hexafluoride Lipid-Type A Microspheres"],
  },
  {
    primary_id: "D0P2KG",
    names: ["GSK1144814"],
  },
  {
    primary_id: "D0P2KV",
    names: ["H-Dmt-Tic-Asp-N(Me)-Ph", "CHEMBL448360"],
  },
  {
    primary_id: "D0P2LE",
    names: ["2-(2-Methylquinolin-4-Ylamino)-N-Phenylacetamide", "CHEMBL594511"],
  },
  {
    primary_id: "D0P2LR",
    names: ["Leprovac"],
  },
  {
    primary_id: "D0P2MD",
    names: [
      "AMB-8LK",
      "Cancer Therapy (Monoclonal Antibody), MAT Biopharma",
      "Y90 Anti-Ferritin Monoclonal Antibody (Cancer), MAT Biopharma",
      "Yttrium-90 Anti-Ferritin MAb (Cancer), MAT Biopharma",
      "Yttrium-90 Anti-Ferritin MAb (Hodgkin's Disease/Pancreatic/Liver Cancer), MAT Biopharma",
      "90Y-AMB8LK MAb (Cancer), MAT Biopharma",
      "90Y-AMB8LK Monoclonal Antibody (Cancer), MAT Biopharma",
      "90Y-Labelled Anti-Ferritin Monoclonal Antibody (Cancer), MAT Biopharma",
    ],
  },
  {
    primary_id: "D0P2ME",
    names: ["INO-5401"],
  },
  {
    primary_id: "D0P2MF",
    names: [
      "N91115",
      "Cavosonstat",
      "UNII-O2Z8Q22ZE4",
      "O2Z8Q22ZE4",
      "Cavosonstat [USAN]",
      "Cavosonstat (USAN)",
      "1371587-51-7",
      "3-Chloro-4-(6-Hydroxyquinolin-2-Yl)Benzoic Acid",
      "SCHEMBL1289569",
      "CHEMBL3989910",
      "BXSZILNGNMDGSL-UHFFFAOYSA-N",
      "AKOS030527843",
      "D10916",
      "N-91115",
      "3-Chloro-4-(6-Hydroxy-2-Quinolinyl)Benzoic Acid",
      "Benzoic Acid, 3-Chloro-4-(6-Hydroxy-2-Quinolinyl)-",
    ],
  },
  {
    primary_id: "D0P2MH",
    names: ["BTS-67582", "1,1-Dimethyl-2-(2-Morpholinophenyl)Guanidine Fumarate"],
  },
  {
    primary_id: "D0P2NN",
    names: ["Rimantadine Isomer 1"],
  },
  {
    primary_id: "D0P2OA",
    names: [
      "N-Hydroxycarbamoylmethyl-4-Methoxy-Benzamide",
      "BRN 2736542",
      "2-(P-Methoxybenzamido)Acetohydroxamic Acid",
      "ACETOHYDROXAMIC ACID, 2-(P-METHOXYBENZAMIDO)-",
      "CHEMBL198048",
      "65654-08-2",
      "AC1L2IK9",
      "SCHEMBL11668774",
      "CTK5C2963",
      "DTXSID60215890",
      "BDBM50175547",
      "LS-13134",
      "N-[2-(Hydroxyamino)-2-Oxoethyl]-4-Methoxybenzamide",
      "N-(HYDROXYCARBAMOYLMETHYL)-4-METHOXY-BENZAMIDE",
    ],
  },
  {
    primary_id: "D0P2OK",
    names: ["HPP404"],
  },
  {
    primary_id: "D0P2OW",
    names: ["IR208"],
  },
  {
    primary_id: "D0P2OZ",
    names: ["Chemosensitizers"],
  },
  {
    primary_id: "D0P2QG",
    names: [
      "BAS-01047341",
      "CHEMBL208828",
      "AC1LLNNX",
      "MolPort-001-953-454",
      "BDBM50187577",
      "CCG-12153",
      "ZINC13633981",
      "AKOS000446912",
      "BAS 01047341",
      "BIM-0025124.P001",
      "4-(4-(Diethylamino)-2-Hydroxybenzylidene)-1-(4-Iodophenyl)Pyrazolidine-3,5-Dione",
    ],
  },
  {
    primary_id: "D0P2QO",
    names: [
      "S-15535",
      "4-(Benzodioxan-5-Yl)-1-(Indan-2-Yl)Piperazine",
      "S15535",
      "146998-34-7",
      "S 15535",
      "1-(2,3-Dihydro-1,4-Benzodioxin-5-Yl)-4-(2,3-Dihydro-1h-Inden-2-Yl)-Piperazine",
      "1-(2,3-Dihydro-1H-Inden-2-Yl)-4-(2,3-Dihydrobenzo[b][1,4]dioxin-5-Yl)Piperazine",
      "Piperazine,1-(2,3-Dihydro-1,4-Benzodioxin-5-Yl)-4-(2,3-Dihydro-1H-Inden-2-Yl)-",
      "1-(2,3-Dihydro-1,4-Benzodioxin-8-Yl)-4-(2,3-Dihydro-1H-Inden-2-Yl)Piperazine",
      "PDSP1_000559",
      "ACMC-20ehn1",
      "[3H]S-15535",
      "GTPL26",
      "GTPL32",
      "AC1L31KI",
      "CHEMBL49247",
      "SCHEMBL1650175",
      "CTK4C5212",
      "BDBM82564",
      "ZINC16050",
      "1-(1,4-Benzodioxan-5-Yl)-4-(Indan-2-Yl)Piperazine",
    ],
  },
  {
    primary_id: "D0P2QV",
    names: ["Tyr-(R)-Spiro-Aba-Gly-Phe-NH2", "CHEMBL401142"],
  },
  {
    primary_id: "D0P2RO",
    names: ["SSR-126517E"],
  },
  {
    primary_id: "D0P2SI",
    names: ["Galiximab"],
  },
  {
    primary_id: "D0P2TB",
    names: ["ISIS 107773"],
  },
  {
    primary_id: "D0P2TD",
    names: [
      "ElCams",
      "ElCams (Oral, Inflammation/Infectious Diseases)",
      "ElCams (Oral, Inflammation/Infectious Diseases), Falco BioTherapeutics",
      "Protein-Based Therapeutics (Oral, Inflammation/Infectious Diseases), Falco BioTherapeutics",
    ],
  },
  {
    primary_id: "D0P2TS",
    names: ["UK-383367"],
  },
  {
    primary_id: "D0P2TT",
    names: ["H-Cpa-C[pen-Gly-Phe-Pen]OH"],
  },
  {
    primary_id: "D0P2UM",
    names: [
      "2-(Ethoxycarbonyl)-1H-Indole-5-Carboxylic Acid",
      "138731-14-3",
      "2-(Ethoxycarbonyl)Indole-5-Carboxylic Acid",
      "1H-INDOLE-2,5-DICARBOXYLIC ACID 2-ETHYL ESTER",
      "CHEMBL503643",
      "CAVYPAYXEMVXMS-UHFFFAOYSA-N",
      "2-ETHOXYCABONYL-5-INDOLE CARBOXYLIC ACID",
      "2-Ethoxycarbonyl-1H-Indole-5-Carboxylic Acid",
      "SCHEMBL1306748",
      "AC1Q34A5",
      "CTK6F6093",
      "DTXSID70592508",
      "MolPort-004-323-750",
      "KS-00000BY1",
      "STK695496",
      "SBB019411",
      "1264AJ",
      "ZINC14985904",
      "BDBM50268451",
      "AKOS000161198",
      "MCULE-3298585220",
      "AB49136",
      "NCGC00341253-01",
      "BS-14093",
      "SY043081",
      "AJ-66467",
    ],
  },
  {
    primary_id: "D0P2VL",
    names: ["9-O-[3-(4-Nitro-Phenoxyl)Butyl]-Berberine Bromide", "CHEMBL1165676"],
  },
  {
    primary_id: "D0P2VP",
    names: ["(2S,1'R,2'S)-2-(2'-Phosphonocyclopropyl)Glycine", "CHEMBL442076", "BDBM50207814"],
  },
  {
    primary_id: "D0P2WA",
    names: ["CVT-313", "AC1OCFD4", "NG 26"],
  },
  {
    primary_id: "D0P2YD",
    names: ["Isosulfan Blue"],
  },
  {
    primary_id: "D0P2YP",
    names: ["AGT-181"],
  },
  {
    primary_id: "D0P2YS",
    names: [
      "A 77636",
      "A77636",
      "1H-2-Benzopyran-5,6-Diol, 1-(Aminomethyl)-3,4-Dihydro-3-Tricyclo(3.3.1.1(3,7))Dec-1-Yl-, Hydrochloride, (1R-Cis)-",
      "UNII-WA6RGP1J4W",
      "WA6RGP1J4W",
      "3-(1'-Adamantyl)-1-Aminomethyl-3,4-Dihydro-5,6-Dihydroxy-1H-2-Benzopyran Hydrochloride",
      "C20H27NO3.HCl",
      "145307-34-2",
      "AC1Q3F7L",
      "AC1L21MK",
      "LS-173243",
      "(1R)-3-(1-Adamantyl)-1-(Aminomethyl)-3,4-Dihydro-1H-Isochromene-5,6-Diol Hydrochloride",
      "(1r)-1-(Aminomethyl)-3-(Tricyclo[3.3.1.13,7]dec-1-Yl)-3,4-Dihydro-1h-Isochromene-5,6-Diol Hydrochloride(1:1)",
    ],
  },
  {
    primary_id: "D0P2YU",
    names: ["Mitiglinide"],
  },
  {
    primary_id: "D0P2YV",
    names: ["SCH-12679"],
  },
  {
    primary_id: "D0P2ZM",
    names: ["SLX-1016"],
  },
  {
    primary_id: "D0P2ZY",
    names: ["INNO-305"],
  },
  {
    primary_id: "D0P3AU",
    names: [
      "6-(4-Methoxy-Benzenesulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL74785",
      "SCHEMBL6207869",
      "BDBM16446",
      "ZINC11536216",
      "AKOS001782172",
      "AKOS015830989",
      "MCULE-9943571795",
      "CCG-127351",
      "6-Phenylsulfonylpyridazin-2H-3-One, 8",
      "454181-51-2",
      "Phenyl-Substituted Sulfonylpyridazinone, 8h",
      "6-[(4-Methoxyphenyl)Sulfonyl]pyridazin-3(2H)-One",
      "3(2H)-Pyridazinone, 6-[(4-Methoxyphenyl)Sulfonyl]-",
    ],
  },
  {
    primary_id: "D0P3BL",
    names: [
      "A-192558",
      "UNII-SQ95TK2P11",
      "CHEMBL19309",
      "SQ95TK2P11",
      "4-Amino-1-(Ethyl-Isopropyl-Carbamoyl)-5-[(2,2,2-Trifluoro-Acetylamino)-Methyl]-Pyrrolidine-3-Carboxylic Acid",
      "BDBM50098745",
      "341969-96-8",
      "3-Pyrrolidinecarboxylic Acid, 4-Amino-1-((Ethyl(1-Methylethyl)Amino)Carbonyl)-5-(((2,2,2-Trifluoroacetyl)Amino)Methyl)-, (3R,4R,5S)-",
      "1-[(Ethylisopropylamino)Carbonyl]-2beta-[(Trifluoroacetylamino)Methyl]-3alpha-Aminopyrrolidine-4alpha-Carboxylic Acid",
      "3-Pyrrolidinecarboxylic Acid, 4-Amino-1-((Ethyl(1-Methylethyl)Amino)Carbonyl)-5",
    ],
  },
  {
    primary_id: "D0P3BS",
    names: ["TUTI-16"],
  },
  {
    primary_id: "D0P3BU",
    names: ["3-(Hexa-1,3-Dienyloxy)-9H-Pyrido[3,4-B]indole", "CHEMBL1269094"],
  },
  {
    primary_id: "D0P3CF",
    names: ["AZD-4407"],
  },
  {
    primary_id: "D0P3CX",
    names: [
      "6-Methyl-2-Phenyl-Chromen-4-One",
      "6-Methylflavone",
      "29976-75-8",
      "6-Methyl-2-Phenyl-4H-Chromen-4-One",
      "6-Methyl-2-Phenylchromen-4-One",
      "ST069348",
      "4H-1-Benzopyran-4-One,6-Methyl-2-Phenyl-",
      "AC1Q6AKA",
      "AC1LEN9R",
      "Maybridge3_003437",
      "Cambridge Id 5479507",
      "Oprea1_512723",
      "BIDD:ER0442",
      "SCHEMBL4648879",
      "CHEMBL134291",
      "ZINC58123",
      "CTK4G4151",
      "DTXSID40351008",
      "MolPort-000-648-469",
      "NOQJBXPAMJLUSS-UHFFFAOYSA-N",
      "HMS1440M05",
      "HY-N6630",
      "KS-000017TV",
      "SBB012439",
      "6019AH",
      "AKOS002387497",
      "MCULE-3565329436",
      "CCG-250475",
      "IDI1_014824",
      "NCGC00142612-01",
      "ZB002324",
      "6-Methylflavone Solution, 20 MM In DMSO",
    ],
  },
  {
    primary_id: "D0P3DC",
    names: [
      "LP-10",
      "Anti-NGF SiRNA, Lipella",
      "Liposomal SiRNA Therapy (Intravesical, Cystitis/Hyperactive Bladder), Lipella",
    ],
  },
  {
    primary_id: "D0P3DM",
    names: ["Fencamfamine"],
  },
  {
    primary_id: "D0P3DS",
    names: ["Pyrrolo[1,2-F]triazine Derivative 1"],
  },
  {
    primary_id: "D0P3DV",
    names: ["N-(4-Phenylbutyl)-1H-Pyrrole-2-Carboxamide"],
  },
  {
    primary_id: "D0P3EG",
    names: ["1,3,4-Oxadiazole Derivative 2"],
  },
  {
    primary_id: "D0P3FP",
    names: ["4-Phenyl-1-(1-Phenylpentyl)Piperidin-4-Ol", "CHEMBL390882"],
  },
  {
    primary_id: "D0P3FU",
    names: ["ISIS 3805"],
  },
  {
    primary_id: "D0P3FY",
    names: ["Acne Therapy, Transdermal/ TPM", "Acne Therapy (Transdermal/ TPM)"],
  },
  {
    primary_id: "D0P3FZ",
    names: [
      "5-(Piperidin-4-Yl)Isothiazol-3-Ol",
      "CHEMBL109209",
      "Thio-4-PIOL",
      "SCHEMBL2046840",
      "5-(4-Piperidyl)Isothiazole-3-Ol",
      "5-Piperidin-4-Yl-Isothiazol-3-Ol",
      "BDBM50032951",
      "PDSP2_001589",
    ],
  },
  {
    primary_id: "D0P3HG",
    names: ["ONO-3708", "BRN 4205392", "ONO3708", "ONO 3708"],
  },
  {
    primary_id: "D0P3HK",
    names: ["Infanrix-DTaP-Hib", "Combination Vaccine, SB", "DTPa Hib Vaccine, SB Biologicals"],
  },
  {
    primary_id: "D0P3HP",
    names: [
      "1,2,3,4,4a,5,10,10a-Octahydro-Benzo[g]quinoline",
      "CHEMBL133947",
      "67728-01-2",
      "SCHEMBL4107088",
      "CTK1J3020",
      "DTXSID80603290",
      "BDBM50219331",
      "AKOS023412127",
      "1,2,3,4,4a,5,10,10a-Octahydrobenzo[g]quinoline",
      "Benzo[g]quinoline, 1,2,3,4,4a,5,10,10a-Octahydro-",
    ],
  },
  {
    primary_id: "D0P3IE",
    names: ["ZK-93426"],
  },
  {
    primary_id: "D0P3IZ",
    names: ["E-2040"],
  },
  {
    primary_id: "D0P3JF",
    names: ["SCH-900978"],
  },
  {
    primary_id: "D0P3JU",
    names: [
      "Clorazepate",
      "Chlorazepate",
      "Tranxene",
      "Cchlorazepic Acid",
      "Chlorazepic Acid",
      "Clorazepate Dipotassium",
      "Clorazepic Acid",
      "Clorazepic Acid [BAN]",
      "Gen-Xene",
      "Novo-Clopate",
      "Tranxene (Cherazepate Dipotasium)",
      "Tranxene (TN)",
      "Novo-Clopate (TN)",
      "(Chloromethyl)(Dimethyl)(Tridecyloxy)Silane",
      "7-Chloro-2,3-Dihydro-2,2-Dihydroxy-5-Phenyl-1H-1,4-Benzodiazepine-3-Carboxylic Acid",
      "7-Chloro-2-Oxo-5-Phenyl-1,3-Dihydro-1,4-Benzodiazepine-3-Carboxylic Acid",
      "7-Chloro-2-Oxo-5-Phenyl-2,3-Dihydro-1H-1,4-Benzodiazepine-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0P3JW",
    names: ["PD-0166326"],
  },
  {
    primary_id: "D0P3KA",
    names: ["TEBUFELONE"],
  },
  {
    primary_id: "D0P3KH",
    names: ["PMUC5AC"],
  },
  {
    primary_id: "D0P3KI",
    names: ["NNC052090"],
  },
  {
    primary_id: "D0P3LQ",
    names: ["SIL-187"],
  },
  {
    primary_id: "D0P3MF",
    names: ["Gly-Hyp-Glu"],
  },
  {
    primary_id: "D0P3MU",
    names: ["Boronic Acid Derivative 2"],
  },
  {
    primary_id: "D0P3ND",
    names: ["1-[(7-Fluoronaphth-2-Yl)Methyl]-1H-Imidazole", "CHEMBL1098424"],
  },
  {
    primary_id: "D0P3NZ",
    names: [
      "CMP-1",
      "Chitin Microparticle Nasal Spray (Allergic Rhinitis), CMP Therapeutics",
      "Chitin Microparticle Nasal Spray (Allergy), CMP Therapeutics",
      "Chitin Microparticle Nasal Spray (Influenza), CMP Therapeutics",
    ],
  },
  {
    primary_id: "D0P3OH",
    names: [
      "3-Chlorophenyl 2-Oxo-2H-Chromene-3-Carboxylate",
      "CHEMBL199203",
      "SCHEMBL7682509",
      "2-Oxo-2H-1-Benzopyran-3-Carboxylic Acid 3-Chlorophenyl Ester",
    ],
  },
  {
    primary_id: "D0P3PB",
    names: ["RX-10045"],
  },
  {
    primary_id: "D0P3PF",
    names: ["AMX-256"],
  },
  {
    primary_id: "D0P3PG",
    names: ["Quinoline Methanols"],
  },
  {
    primary_id: "D0P3QR",
    names: ["Carbapenem Antibacterials"],
  },
  {
    primary_id: "D0P3SH",
    names: ["ReN-1826"],
  },
  {
    primary_id: "D0P3SJ",
    names: ["XP-21279"],
  },
  {
    primary_id: "D0P3SL",
    names: ["PMID26651364-Compound-8d"],
  },
  {
    primary_id: "D0P3SU",
    names: ["N-Hydroxy-3-Phenoxybenzamide", "CHEMBL245741", "SCHEMBL6671653"],
  },
  {
    primary_id: "D0P3SY",
    names: ["Danaparoid"],
  },
  {
    primary_id: "D0P3TF",
    names: ["TU-2100"],
  },
  {
    primary_id: "D0P3TT",
    names: ["IDR-90104"],
  },
  {
    primary_id: "D0P3TX",
    names: ["Abciximab"],
  },
  {
    primary_id: "D0P3UH",
    names: ["Imidazopyridine Derivative 4"],
  },
  {
    primary_id: "D0P3UZ",
    names: ["CNJ-H01", "Anti-HCV Hyperimmune"],
  },
  {
    primary_id: "D0P3VH",
    names: [
      "1-(10H-Phenothiazin-10-Yl)-3-Phenylpropan-1-One",
      "CHEMBL240047",
      "AC1LHUPA",
      "1-Phenothiazin-10-Yl-3-Phenylpropan-1-One",
      "MolPort-027-839-061",
      "MolPort-002-255-240",
      "ZINC469770",
      "STL138828",
      "BDBM50219214",
      "AKOS005715472",
      "MCULE-9350992214",
      "10-(3-Phenylpropanoyl)-10H-Phenothiazine",
      "AO-365/41906550",
    ],
  },
  {
    primary_id: "D0P3VK",
    names: ["PMID28270010-Compound-Figure17-1"],
  },
  {
    primary_id: "D0P3VP",
    names: ["JKB-119"],
  },
  {
    primary_id: "D0P3WB",
    names: ["6-Amino-N-(3,3-Diphenylpropyl)Nicotinamide", "CHEMBL568773", "SCHEMBL5947733"],
  },
  {
    primary_id: "D0P3WF",
    names: ["CGP 23996", "CGP23996", "CGP-23996"],
  },
  {
    primary_id: "D0P3WH",
    names: ["Anti-CD27 Agonist"],
  },
  {
    primary_id: "D0P3XJ",
    names: ["Z-YVAD-CHO", "CHEMBL159822"],
  },
  {
    primary_id: "D0P3XO",
    names: ["(S)-4CPG", "(S)-4-Carboxyphenylglycine"],
  },
  {
    primary_id: "D0P3XW",
    names: [
      "2-Chlorobenzenemethanethiol",
      "39718-00-8",
      "(2-Chlorophenyl)Methanethiol",
      "2-Chlorobenzyl Mercaptan",
      "2-Chlorobenzylmercaptan",
      "2-Chloro Benzyl Mercaptan",
      "Benzenemethanethiol, 2-Chloro-",
      "CHEMBL1224556",
      "WWFIIZLHSNBNTC-UHFFFAOYSA-N",
      "2-Chlorobenzyl Mercaptan, 98%",
      "O-Chlorobenzylmercaptan",
      "PubChem6836",
      "AC1Q3PHW",
      "AC1LBDF6",
      "2-Chlorophenylmethanethiol",
      "2-Chlorophenyl-Methanethiol",
      "2-Chloro-Alpha-Toluenethiol",
      "ACMC-1CT33",
      "SCHEMBL646102",
      "(2-Chlorophenyl)Methanethiol #",
      "O-Chlorotoluene-&amp;#x3B1;-Thiol",
      "CTK3J1402",
    ],
  },
  {
    primary_id: "D0P3YA",
    names: ["Dihydrodiazepinocarbazolone Derivative 1"],
  },
  {
    primary_id: "D0P3ZA",
    names: ["AB-3001"],
  },
  {
    primary_id: "D0P3ZF",
    names: ["IGF-MTX"],
  },
  {
    primary_id: "D0P3ZK",
    names: [
      "CFTRinh-172",
      "307510-92-5",
      "4-({4-Oxo-2-Sulfanylidene-3-[3-(Trifluoromethyl)Phenyl]-1,3-Thiazolidin-5-Ylidene}methyl)Benzoic Acid",
      "4-[4-Oxo-2-Thioxo-3-(3-Trifluoromethyl-Phenyl)-Thiazolidin-(5z)-Ylidenemethyl]-Benzoic Acid",
      "4-[[4-Oxo-2-Sulfanylidene-3-[3-(Trifluoromethyl)Phenyl]-1,3-Thiazolidin-5-Ylidene]methyl]benzoic Acid",
      "AC1L9ZJU",
      "CBMicro_044684",
      "(Inh)-172",
      "SCHEMBL919042",
      "GTPL4153",
      "KS-00000NFK",
      "CTK8F1370",
      "CHEBI:131686",
      "5-[(4-Carboxyphenyl)Methylene]-2-Thioxo-3-[(3-Trifluoromethyl)Phenyl]-4-Thiazolidinone",
      "HMS3653A11",
    ],
  },
  {
    primary_id: "D0P3ZN",
    names: ["RDEA3170"],
  },
  {
    primary_id: "D0P4BQ",
    names: ["LY-544344"],
  },
  {
    primary_id: "D0P4BY",
    names: [
      "SCH-24518",
      "CHEMBL292418",
      "CHEMBL540857",
      "Sch-24518",
      "8-Chloro-2,3,4,5-Tetrahydro-5-Phenyl-1H-3-Benzazepin-7-Ol",
      "ACMC-20mac5",
      "AC1L3SVB",
      "SCHEMBL13007204",
      "BDBM50004796",
      "8-Chloro-5-Phenyl-2,3,4,5-Tetrahydro-1H-3-Benzazepin-7-Ol",
      "8-Chloro-5-Phenyl-2,3,4,5-Tetrahydro-1H-Benzo[d]azepin-7-Ol",
    ],
  },
  {
    primary_id: "D0P4CQ",
    names: ["8-(2-Ethyl-1-Methyl-Butoxy)-Quinolin-2-Ylamine", "CHEMBL365459", "SCHEMBL5884532"],
  },
  {
    primary_id: "D0P4DE",
    names: ["Cabotegravir LA"],
  },
  {
    primary_id: "D0P4DG",
    names: ["S-070"],
  },
  {
    primary_id: "D0P4DJ",
    names: ["Somatropin Recombinant"],
  },
  {
    primary_id: "D0P4EC",
    names: ["Surfactant Ta"],
  },
  {
    primary_id: "D0P4FK",
    names: ["TB-010"],
  },
  {
    primary_id: "D0P4FT",
    names: ["ART-123"],
  },
  {
    primary_id: "D0P4GF",
    names: ["3,4-Benzo-7-(Beta-Bromoallyloxy)-8-Methylcoumarin", "CHEMBL487235"],
  },
  {
    primary_id: "D0P4GI",
    names: ["C[Nle-Pro-D-Nal(2')-Arg-Trp-Glu]-NH2", "CHEMBL203760", "BDBM50184366"],
  },
  {
    primary_id: "D0P4GP",
    names: ["PSB1114"],
  },
  {
    primary_id: "D0P4HR",
    names: ["Human Recombinant Protein"],
  },
  {
    primary_id: "D0P4IK",
    names: ["FK-706"],
  },
  {
    primary_id: "D0P4IL",
    names: ["REGN-728"],
  },
  {
    primary_id: "D0P4IP",
    names: ["Trypanothione"],
  },
  {
    primary_id: "D0P4JJ",
    names: ["Adoptive T-Cell Therapy", "Adoptive T-Cell Therapy (Cancer)"],
  },
  {
    primary_id: "D0P4JO",
    names: ["FORSYTHIASIDE"],
  },
  {
    primary_id: "D0P4KF",
    names: [
      "2-(4-Tert-Butyl-Phenyl)-4,5-Dihydro-1H-Imidazole",
      "CHEMBL276868",
      "67277-66-1",
      "SCHEMBL8229695",
      "CTK1J3678",
      "DTXSID50658095",
      "ZINC13806045",
      "BDBM50151704",
      "AKOS022538449",
      "2-(4-Tert-Butylphenyl)-4,5-Dihydro-1H-Imidazole",
      "1H-Imidazole, 2-[4-(1,1-Dimethylethyl)Phenyl]-4,5-Dihydro-",
    ],
  },
  {
    primary_id: "D0P4KL",
    names: [
      "S136492",
      "(E)-2-(2-(Quinolin-2-Yl)Vinyl)Phenol",
      "CHEMBL127545",
      "2-[(E)-2-(Quinolin-2-Yl)Ethenyl]phenol",
      "143816-42-6",
      "Quininib",
      "2-(2-(Quinolin-2-Yl)Vinyl)Phenol",
      "(6E)-6-[(2E)-2-(1H-Quinolin-2-Ylidene)Ethylidene]cyclohexa-2,4-Dien-1-One",
      "AC1OATYA",
      "AC1NW9KR",
      "Cambridge Id 5719593",
      "AC1NZV89",
      "AC1Q791J",
      "Quininib, &gt",
      "SCHEMBL10325190",
      "CHEBI:133170",
      "MolPort-001-824-964",
      "MolPort-000-712-889",
      "ZINC3882963",
      "STL044348",
      "BDBM50001270",
      "2-(2-Quinolin-2-Yl-Vinyl)-Phenol",
      "AKOS015955311",
      "AKOS005697868",
      "2-[(E)-2-(Quinolin-2-Yl)Vinyl]phenol",
    ],
  },
  {
    primary_id: "D0P4KP",
    names: ["CART19 Cells"],
  },
  {
    primary_id: "D0P4KU",
    names: ["ABI-011"],
  },
  {
    primary_id: "D0P4KV",
    names: ["MesoStem"],
  },
  {
    primary_id: "D0P4MA",
    names: ["2-MOE Phosphorothioate Gapmers (Myotonic Dystrophy), Genzyme/Isis Pharmaceuticals"],
  },
  {
    primary_id: "D0P4MN",
    names: ["ALO 212"],
  },
  {
    primary_id: "D0P4MT",
    names: ["Oxamniquine"],
  },
  {
    primary_id: "D0P4MU",
    names: ["7-Ethyloxyethoxy-2-Morpholin-4-Yl-Chromen-4-One", "CHEMBL196920"],
  },
  {
    primary_id: "D0P4NF",
    names: ["PMID27019002-Compound-50"],
  },
  {
    primary_id: "D0P4NP",
    names: ["Diaryl Amine Derivative 1"],
  },
  {
    primary_id: "D0P4PM",
    names: ["H-Dmt-Tic-(2R,3R)-Beta-MeCha-Phe-NH2", "CHEMBL218781"],
  },
  {
    primary_id: "D0P4PQ",
    names: ["Lanosterol"],
  },
  {
    primary_id: "D0P4PS",
    names: ["Antimalarial Peroxides"],
  },
  {
    primary_id: "D0P4PZ",
    names: ["Glucarpidase"],
  },
  {
    primary_id: "D0P4QS",
    names: ["5-Methoxy-2-(4-(Methylsulfonyl)Phenyl)-1H-Indole", "CHEMBL565567"],
  },
  {
    primary_id: "D0P4QX",
    names: ["LPG-1030S"],
  },
  {
    primary_id: "D0P4RA",
    names: ["Repifermin"],
  },
  {
    primary_id: "D0P4RB",
    names: ["Cypate-[(RGD)2-NH2]1", "CHEMBL438186"],
  },
  {
    primary_id: "D0P4RC",
    names: [
      "SITOSTANOL ASCORBYL PHOSPHATE",
      "2-O-[Hydroxy[(3beta,5alpha,24R)-Stigmastan-3-O-Yl]phosphoryl]-L-Ascorbic Acid Disodium Salt",
    ],
  },
  {
    primary_id: "D0P4RL",
    names: [
      "5,7-Dinitro-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "CHEMBL323335",
      "136529-54-9",
      "Biomol-NT_000208",
      "SCHEMBL8123057",
      "SCHEMBL8114607",
      "SCHEMBL4706926",
      "BPBio1_001268",
      "BDBM50038162",
      "NCGC00163270-01",
      "5,7-Dinitroquinoxaline-2,3(1H,4H)-Dione",
    ],
  },
  {
    primary_id: "D0P4SR",
    names: ["2-(4-Hydroxy-Phenyl)-1-P-Tolyl-3H-Inden-5-Ol", "CHEMBL197188"],
  },
  {
    primary_id: "D0P4TH",
    names: ["Trastuzumab-DM1"],
  },
  {
    primary_id: "D0P4TX",
    names: ["[14C]MTEP"],
  },
  {
    primary_id: "D0P4VX",
    names: ["Terlipressin"],
  },
  {
    primary_id: "D0P4WI",
    names: ["Recombinant Human TNF Receptor"],
  },
  {
    primary_id: "D0P4WO",
    names: ["ADX10059"],
  },
  {
    primary_id: "D0P4WQ",
    names: ["ISIS 134565"],
  },
  {
    primary_id: "D0P4WT",
    names: [
      "NVP-BBT594",
      "BBT594",
      "882405-89-2",
      "GTPL7584",
      "SCHEMBL12972358",
      "BBT-594",
      "EX-A2511",
      "BCP24855",
      "ZINC98207757",
      "AKOS032945083",
      "CS-6100",
      "HY-18840",
      "5-((6-Acetamidopyrimidin-4-Yl)Oxy)-N-(4-((4-Methylpiperazin-1-Yl)Methyl)-3-(Trifluoromethyl)Phenyl)Indoline-1-Carboxamide",
    ],
  },
  {
    primary_id: "D0P4XW",
    names: ["Tremfya"],
  },
  {
    primary_id: "D0P4YY",
    names: ["PMID24900824C2-3-2"],
  },
  {
    primary_id: "D0P4ZA",
    names: ["EM-2198"],
  },
  {
    primary_id: "D0P4ZR",
    names: ["Istiratumab"],
  },
  {
    primary_id: "D0P5AA",
    names: ["Oratecan"],
  },
  {
    primary_id: "D0P5AE",
    names: ["Ecallantide"],
  },
  {
    primary_id: "D0P5AL",
    names: ["Profezyme"],
  },
  {
    primary_id: "D0P5AP",
    names: ["Recombinant Factor Viii"],
  },
  {
    primary_id: "D0P5BQ",
    names: ["IkT-051"],
  },
  {
    primary_id: "D0P5BT",
    names: ["1,3-Di(Berberine-9-O-Yl)Ethane Dibromide", "CHEMBL1163253"],
  },
  {
    primary_id: "D0P5BY",
    names: [
      "N-(Cyanomethyl)Cyclohex-1-Ene-1-Carboxamide",
      "1-Cyclohexene-1-Carboxamide, N-(Cyanomethyl)-",
      "875142-19-1",
    ],
  },
  {
    primary_id: "D0P5CD",
    names: ["Iocetamic Acid"],
  },
  {
    primary_id: "D0P5CM",
    names: ["AZD-3161"],
  },
  {
    primary_id: "D0P5CT",
    names: ["4'-(Pyridin-4-Ylmethyl)Biphenyl-4-Carboxamide", "CHEMBL1215731", "BDBM50324615"],
  },
  {
    primary_id: "D0P5CV",
    names: [
      "BIS(16)-HUPERZINE B",
      "Bis(16)-Huperzine B",
      "Bis-Huperzine B 5f",
      "Bis(16)-HupB",
      "CHEMBL434898",
      "BDBM10946",
    ],
  },
  {
    primary_id: "D0P5DG",
    names: ["Temoporfin"],
  },
  {
    primary_id: "D0P5DW",
    names: ["Ach-02", "Acheflan (TN)"],
  },
  {
    primary_id: "D0P5DZ",
    names: ["V920"],
  },
  {
    primary_id: "D0P5EI",
    names: ["H-Dmt-Tic-Glu-NH-(CH2)5-NH2", "CHEMBL225479"],
  },
  {
    primary_id: "D0P5FA",
    names: ["Galnobax"],
  },
  {
    primary_id: "D0P5GE",
    names: [
      "Dibucaine",
      "Cincainum",
      "Cinchocaine",
      "Cinchocainum",
      "Cincocaina",
      "Cincocainio",
      "Dermacaine",
      "Dibucain",
      "Dibucainum",
      "Nupercainal",
      "Nupercaine",
      "Percamine",
      "Sovcaine",
      "Cinchocaine HCL",
      "Cinchocaine Hydrochloride",
      "Cincocaina [DCIT]",
      "Dibucaine Base",
      "Dibucaine [USP]",
      "Alpha-Butyloxycinchoninic Acid Diethylethylenediamide",
      "Cincain (TN)",
      "Cinchocaine (INN);Cinchocainum [INN-Latin]",
      "Cincocainio [INN-Spanish]",
      "Dibucaine (USP)",
      "Nupercainal (TN)",
      "Nupercainal (VAN)",
      "Nupercaine (TN)",
      "Sovcaine (TN)",
      "Alpha-Butyloxycinchonic Acid-Gamma-Diethylethylenediamine",
      "N-[2-(Diethylamino)Ethyl]-2-Butoxycinchoninamide",
      "N-(2-(Diethylamino)Ethyl)-2-Butoxycinchoninamide",
      "QUINOLINE,2-BUTOXY,4-CARBOXY,(N-TRIETHYLAMINO) AMIDE CINCHOCAIN",
      "2-Butoxy-N-(2-(Diethylamino)Ethyl)Cinchoninamide",
      "2-Butoxy-N-(Beta-Diethylaminoethyl)Cinchoninamide",
      "2-Butoxy-N-(Beta.-Diethylaminoethyl)Cinchoninamide",
      "2-Butoxy-N-[2-(Diethylamino)Ethyl]-4-Quinolinecarboxamide",
      "2-Butoxy-N-[2-(Diethylamino)Ethyl]cinchoninamide",
      "2-Butoxy-Quinoline-4-Carboxylic Acid (2-Diethylamino-Ethyl)-Amide",
      "2-Butoxyquinoline-4-Carboxylic Acid Diethylaminoethylamide",
      "2-N-Butoxy-N-(2-Diethylaminoethyl)Cinchoninamide",
      "2-Butoxy-N-(2-Diethylaminoethyl)Quinoline-4-Carboxamide",
      "2-Butoxy-N-(Alpha-Diethylaminoethyl)Cinchoninamide",
      "2-Butoxy-N-[2-(Diethylamino)Ethyl]quinoline-4-Carboxamide",
    ],
  },
  {
    primary_id: "D0P5HD",
    names: ["Anticancer/Antidiabetic Protein"],
  },
  {
    primary_id: "D0P5IH",
    names: ["C[L-Tyr(OMe)-D-Pro-L-Phe-D-Trp]", "CHEMBL507127"],
  },
  {
    primary_id: "D0P5IS",
    names: ["Opioid Prodrugs"],
  },
  {
    primary_id: "D0P5IZ",
    names: ["BI-811283"],
  },
  {
    primary_id: "D0P5JH",
    names: ["ABBV-368"],
  },
  {
    primary_id: "D0P5LH",
    names: ["APL-2"],
  },
  {
    primary_id: "D0P5LX",
    names: ["N-6060"],
  },
  {
    primary_id: "D0P5LY",
    names: [
      "Antiparasitic Diamidines",
      "DB-1255",
      "DB-829",
      "DB-844",
      "DB-868",
      "Antiparasitic Diamidines, UNC/Georgia State University/CPDD",
    ],
  },
  {
    primary_id: "D0P5MG",
    names: ["BAY 94-9343"],
  },
  {
    primary_id: "D0P5MJ",
    names: ["ERYSTAGALLIN A"],
  },
  {
    primary_id: "D0P5MY",
    names: ["N-Oxalyl-D-Tyrosine Derivative 3"],
  },
  {
    primary_id: "D0P5NU",
    names: ["BN-AA-003-NY"],
  },
  {
    primary_id: "D0P5NW",
    names: ["SB-236049"],
  },
  {
    primary_id: "D0P5NY",
    names: ["SPD 535"],
  },
  {
    primary_id: "D0P5OE",
    names: [
      "(1R,2R)-1,2-Diphenylethane-1,2-Diamine",
      "35132-20-8",
      "(1R,2R)-(+)-1,2-Diphenylethylenediamine",
      "(1R,2R)-Rel-1,2-Diphenylethane-1,2-Diamine",
      "(1R,2R)-1,2-Diphenylethylenediamine",
      "16635-95-3",
      "(+)-1,2-Diphenylethylenediamine",
      "MFCD00082769",
      "(+)-STILBENEDIAMINE",
      "(R,R)-DPEN",
      "(R,r)-(+)-1,2-Diphenylethylenediamine",
      "CHEMBL381340",
      "(1r,2r)-1,2-Diphenyl-Ethane-1,2-Diamine",
      "1,2-Ethanediamine, 1,2-Diphenyl-, (1R,2R)-",
      "(1R,2R)-(+)-1,2-Diphenyl-1,2-Ethane Diam",
    ],
  },
  {
    primary_id: "D0P5OF",
    names: [
      "7-Methoxy-1H-Indazole",
      "133841-05-1",
      "1H-Indazole, 7-Methoxy-",
      "CHEMBL15921",
      "ACMC-20aa2r",
      "7-METHOXYINDAZOLE",
      "SCHEMBL5483794",
      "SCHEMBL18003573",
      "CTK0H0012",
      "KS-00000DHI",
      "DTXSID90439880",
      "QCBANHQTMCETBI-UHFFFAOYSA-N",
      "MolPort-009-199-308",
      "BDBM50099399",
      "ANW-63985",
      "ZINC26894532",
      "AKOS024124789",
      "AKOS006290288",
      "TRA0059918",
      "AS-8550",
      "RP01575",
      "PB32675",
      "RTC-062053",
      "AN-6943",
      "KB-46446",
      "AC-29510",
      "SY032798",
      "SC-54780",
      "AK-58155",
      "AJ-82859",
      "1429222-35-4",
      "TC-062053",
      "DB-062996",
      "Y7436",
      "ST24045679",
      "FT-0767863",
      "4CH-023995",
      "CS-0043993",
      "AM20020408",
      "MFCD08752584 (97%)",
      "Q-2252",
    ],
  },
  {
    primary_id: "D0P5PD",
    names: ["IMMU-114"],
  },
  {
    primary_id: "D0P5PJ",
    names: ["MK-1972"],
  },
  {
    primary_id: "D0P5PW",
    names: [
      "4-(1-Naphthalen-1-Yl-Ethyl)-1H-Imidazole",
      "CHEMBL306751",
      "1H-Imidazole, 4-[1-(1-Naphthalenyl)Ethyl]-",
      "137967-87-4",
      "ACMC-20mx1j",
      "AC1L30EQ",
      "SCHEMBL1551477",
      "CTK0F3329",
      "DTXSID60276325",
      "BDBM50036910",
      "4-[1-(1-Naphthyl)Ethyl]-1H-Imidazole",
      "5-(1-Naphthalen-1-Ylethyl)-1H-Imidazole",
      "4-(1-(Naphthalene-1-Yl) Ethyl)-1h-Imidazole",
    ],
  },
  {
    primary_id: "D0P5QB",
    names: ["Cadherin-11"],
  },
  {
    primary_id: "D0P5QI",
    names: ["2-Hydroxymethyl-3-Hydroxymorphinan", "CHEMBL570449"],
  },
  {
    primary_id: "D0P5QM",
    names: ["(E)-13-Nitrooctadec-12-Enoic Acid", "CHEMBL540732"],
  },
  {
    primary_id: "D0P5QR",
    names: ["KAC-776"],
  },
  {
    primary_id: "D0P5QU",
    names: ["IS1"],
  },
  {
    primary_id: "D0P5QW",
    names: ["MQ1"],
  },
  {
    primary_id: "D0P5RD",
    names: ["PentaStaph"],
  },
  {
    primary_id: "D0P5RJ",
    names: ["BF/PC-18"],
  },
  {
    primary_id: "D0P5SA",
    names: ["Fluphenazine"],
  },
  {
    primary_id: "D0P5SB",
    names: ["PMID25666693-Compound-49"],
  },
  {
    primary_id: "D0P5SC",
    names: ["GTU-TB Vaccine", "DNA Vaccine (Mycobacterium Tuberculosis Infection), FIT Biotech"],
  },
  {
    primary_id: "D0P5SF",
    names: ["Pyrimido-Indole Derivative 3"],
  },
  {
    primary_id: "D0P5ST",
    names: [
      "3-(3-Aminobenzo[e][1,2,4]triazin-7-Yl)Phenol",
      "CHEMBL218003",
      "Phenol,3-(3-Amino-1,2,4-Benzotriazin-7-Yl)-",
      "SCHEMBL3737100",
      "BDBM50193876",
    ],
  },
  {
    primary_id: "D0P5SU",
    names: ["CHS-131"],
  },
  {
    primary_id: "D0P5TC",
    names: ["BURKE-103", "BLU-103"],
  },
  {
    primary_id: "D0P5TG",
    names: ["Multi-Epitope Peptide Melanoma Vaccine"],
  },
  {
    primary_id: "D0P5TI",
    names: ["CNX-1351"],
  },
  {
    primary_id: "D0P5TR",
    names: [
      "CGP-28014",
      "Cgp 28014",
      "111757-17-6",
      "Methanimidamide, N'-(1,6-Dihydro-6-Oxo-2-Pyridinyl)-N,N-Dipropyl-",
      "N-(2-Oxopyridin-6-Yl)-N',N'-Di-N-Propylformamidine",
      "N'-(1,6-Dihydro-6-Oxo-2-Pyridinyl)-N,N-Dipropylmethanimidamide",
      "N-(2-Pyridone-6-Yl)-N',N'-Di-N-Propylformamidine",
      "Cgp28014",
      "CHEBI:79969",
      "LS-90457",
    ],
  },
  {
    primary_id: "D0P5UE",
    names: ["50-235"],
  },
  {
    primary_id: "D0P5UQ",
    names: ["Tissue Repair Stem Cell Therapy"],
  },
  {
    primary_id: "D0P5UW",
    names: [
      "ECOPLADIB",
      "Ecopladib",
      "UNII-48TI67E57Q",
      "PLA-725",
      "CHEMBL269787",
      "48TI67E57Q",
      "381683-92-7",
      "Ecopladib [USAN:INN]",
      "Ecopladib (USAN/INN)",
      "AC1L4BXD",
      "SCHEMBL1500755",
      "DTXSID70191555",
      "BDBM50205515",
      "HY-U00037",
      "CS-6742",
      "D03938",
      "Benzoic Acid, 4-(2-(5-Chloro-2-(2-((((3,4-Dichlorophenyl)Methyl)Sulfonyl)Amino)Ethyl)-1-(Diphenylmethyl)-1H-Indol-3-Yl)Ethoxy)-",
      "4-(2-(5-Chloro-2-(2-(((3,4-Dichlorobenzyl)Sulfonyl)Amino)Ethyl)-1-(Diphenylmethyl)-1H-Indol-3-Yl)Ethoxy)Benzoic Acid",
    ],
  },
  {
    primary_id: "D0P5VC",
    names: ["Mirimostim"],
  },
  {
    primary_id: "D0P5VF",
    names: ["Pyrazolo[1,5-A]pyrimidine-3-Carboxylate"],
  },
  {
    primary_id: "D0P5VH",
    names: ["VennVax"],
  },
  {
    primary_id: "D0P5VJ",
    names: ["KM-233", "628263-22-9", "DTXSID50745439"],
  },
  {
    primary_id: "D0P5VL",
    names: ["Demotensin 1"],
  },
  {
    primary_id: "D0P5VX",
    names: ["Hormone-Independent Vaccine"],
  },
  {
    primary_id: "D0P5VZ",
    names: ["Tenecrin"],
  },
  {
    primary_id: "D0P5XM",
    names: ["SM04755"],
  },
  {
    primary_id: "D0P5YR",
    names: ["[(2-Aminooxyethyl)Methylamino]-5'-Deoxyadenosine"],
  },
  {
    primary_id: "D0P5YS",
    names: ["TERRITREM B"],
  },
  {
    primary_id: "D0P5ZE",
    names: [
      "Cyclohexyl-(2-Phenoxy-9H-Purin-6-Yl)-Amine",
      "CHEMBL363419",
      "SCHEMBL935805",
      "ZINC13648479",
      "BDBM50170816",
    ],
  },
  {
    primary_id: "D0P5ZG",
    names: ["EradicAide"],
  },
  {
    primary_id: "D0P5ZJ",
    names: ["GP-1100"],
  },
  {
    primary_id: "D0P5ZV",
    names: ["NSC-94891"],
  },
  {
    primary_id: "D0P6AL",
    names: ["VPX-595"],
  },
  {
    primary_id: "D0P6AM",
    names: ["4-Amino-3-Bromo-Benzenesulfonamide", "4-Amino-3-Bromobenzenesulfonamide"],
  },
  {
    primary_id: "D0P6AR",
    names: ["Tyr-Pro-L-(NMe)Phe-D-Pro-NH2"],
  },
  {
    primary_id: "D0P6AS",
    names: ["ISIS 101985"],
  },
  {
    primary_id: "D0P6BZ",
    names: ["IGFR1 Kinase Inhibitors"],
  },
  {
    primary_id: "D0P6CF",
    names: ["Ozanezumab", "1223249"],
  },
  {
    primary_id: "D0P6CN",
    names: ["XR-368"],
  },
  {
    primary_id: "D0P6CQ",
    names: ["ADS-NPTMZ-1"],
  },
  {
    primary_id: "D0P6DF",
    names: ["PD-153103"],
  },
  {
    primary_id: "D0P6DJ",
    names: ["VATALANIB"],
  },
  {
    primary_id: "D0P6EL",
    names: ["Cordil"],
  },
  {
    primary_id: "D0P6FF",
    names: ["N-Benzyl-17-(Cyclobutylmethyl)Morphinan-3-Amine", "CHEMBL571560", "MCL-630"],
  },
  {
    primary_id: "D0P6FN",
    names: [
      "6-(4-Hydroxy-2-Methoxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL427180",
      "SCHEMBL3932725",
      "TWSSGPRRAGPVKD-UHFFFAOYSA-N",
      "BDBM50168373",
      "ZINC13645059",
    ],
  },
  {
    primary_id: "D0P6GH",
    names: ["P'-Geranyl 2-Fluoromevalonate 5-Diphosphate"],
  },
  {
    primary_id: "D0P6GJ",
    names: ["Isoquinoline Sulfonyl Derivative 3"],
  },
  {
    primary_id: "D0P6GT",
    names: [
      "Cipro Inhale",
      "Ciloxan",
      "86393-32-0",
      "Ciprofloxacin Hydrochloride Monohydrate",
      "Ciprofloxacin Hydrochloride Hydrate",
      "Ceprimax",
      "Oftacilox",
      "Proquin XR",
      "Floxacipron",
      "Siprogut",
      "Roflazin",
      "Proxacin",
      "Cipropol",
      "Ciprofur",
      "Belmacina",
      "Supraflox",
      "Sophixin",
      "Plenolyt",
      "Phaproxin",
      "Globuce",
      "Felixene",
      "Disfabac",
      "Cunesin",
      "Citeral",
      "Ophaflox",
      "Novoquin",
      "Novidat",
      "Microgan",
      "Loxacid",
      "Keefloxin",
      "Cipronex",
      "Ciflosin",
      "Suiflox",
      "Renator",
      "Quinoflox",
      "Megaflox",
      "Inkamil",
      "Ciproktan",
      "Ciprocinal",
      "Flunas",
      "Piprol",
      "Sepcen",
      "Quipro",
      "Ofitin",
      "Ciflan",
      "Catex",
      "Strox",
      "Lypro",
      "Cilox",
      "Cipad",
      "Cenin",
      "Nixin",
      "Flociprin",
      "Bay",
    ],
  },
  {
    primary_id: "D0P6GX",
    names: ["CZ-2645"],
  },
  {
    primary_id: "D0P6HX",
    names: ["FPTQ"],
  },
  {
    primary_id: "D0P6IA",
    names: ["Tendamistat"],
  },
  {
    primary_id: "D0P6IK",
    names: ["Lanatoside B"],
  },
  {
    primary_id: "D0P6IL",
    names: ["5,6-Cyclic-Tetrahydropteridine"],
  },
  {
    primary_id: "D0P6IO",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Butyl Ester",
      "CHEMBL76374",
      "BDBM50116810",
      "4-(Sulfamoyloxy)Benzoic Acid Butyl Ester",
    ],
  },
  {
    primary_id: "D0P6IV",
    names: ["[11C]AZ10419369", "[11C]AZ10419369 (PET Ligand)"],
  },
  {
    primary_id: "D0P6IZ",
    names: ["L-Xylose"],
  },
  {
    primary_id: "D0P6JD",
    names: ["RGN-259 Eye Drops"],
  },
  {
    primary_id: "D0P6JE",
    names: ["3,3'-(3-Methylthiene-2,5-Diyl]diphenol", "CHEMBL572171", "BDBM50299646"],
  },
  {
    primary_id: "D0P6JS",
    names: ["PMID18986805C9b"],
  },
  {
    primary_id: "D0P6JZ",
    names: ["Antiinflammatory/Antimicrobial", "Antiinflammatory/Antimicrobial Series (Topical, Skin Infection)"],
  },
  {
    primary_id: "D0P6KN",
    names: ["INS48823"],
  },
  {
    primary_id: "D0P6LE",
    names: ["GSK2696274"],
  },
  {
    primary_id: "D0P6LN",
    names: ["3-Butyl-2,3,4,5-Tetrahydro-1H-Benzo[d]azepin-6-Ol", "CHEMBL132112", "ZINC13728506", "BDBM50003343"],
  },
  {
    primary_id: "D0P6LP",
    names: ["CB-01-16"],
  },
  {
    primary_id: "D0P6MB",
    names: ["Pexelizumab"],
  },
  {
    primary_id: "D0P6MW",
    names: ["Imprime PGG Immunotherapeutic"],
  },
  {
    primary_id: "D0P6NA",
    names: ["JWH-202"],
  },
  {
    primary_id: "D0P6NL",
    names: ["Technetium Tc-99m Pyrophosphate Kit", "Cis-Pyro", "Phosphotec", "Technescan Pyp Kit"],
  },
  {
    primary_id: "D0P6NP",
    names: ["EDP-239"],
  },
  {
    primary_id: "D0P6OS",
    names: [
      "TT-235",
      "[beta,beta-(3-Thiapentamethylene)-Beta-Sulfanylpropionic Acid,D-Trp2,Pen6,Arg8]-Oxytocin Acetate",
    ],
  },
  {
    primary_id: "D0P6PQ",
    names: [
      "THIOCTIC ACID",
      "Dl-Thioctic Acid",
      "Thioctic Acid",
      "1077-28-7",
      "5-(1,2-Dithiolan-3-Yl)Pentanoic Acid",
      "DL-Alpha-Lipoic Acid",
      "1,2-Dithiolane-3-Pentanoic Acid",
      "Thioctacid",
      "6,8-Thioctic Acid",
      "6-Thioctic Acid",
      "Lipothion",
      "Biletan",
      "Dl-Lipoic Acid",
      "Tioctacid",
      "Thioctsan",
      "Liposan",
      "Rac-Lipoate",
      "1,2-Dithiolane-3-Valeric Acid",
      "DL-6,8-Thioctic Acid",
      "Alpha-Liponsaeure",
      "DL-6-Thioctic Acid",
      "Alpha-Liponic Acid",
      "Thioktsaeure",
      "Tioctidasi",
      "(RS)-Lipoic Acid",
      "6,8-Dithiooctanoic Acid",
      "Espa-Lipon",
      "Thioctic Acid Dl-Form",
      "62-46-4",
    ],
  },
  {
    primary_id: "D0P6PT",
    names: ["Thiazole Carboxamide Derivative 21"],
  },
  {
    primary_id: "D0P6RT",
    names: ["PMID8410973C3"],
  },
  {
    primary_id: "D0P6SY",
    names: ["MDL-28618"],
  },
  {
    primary_id: "D0P6TX",
    names: [
      "1-(3-Benzyloxy-Pyridin-2-Yl)-3-Phenyl-Urea",
      "CHEMBL202880",
      "877458-90-7",
      "1-PHENYL-3-(3-(PHENYLMETHOXY)(2-PYRIDYL))UREA",
      "AC1MUB56",
      "MolPort-006-755-114",
      "KS-000029KK",
      "ZINC13680188",
      "BDBM50181486",
      "AKOS016664347",
      "MS-8514",
      "1-(3-Benzyloxy-2-Pyridyl)-3-Phenyl-Urea",
      "3-[3-(Benzyloxy)Pyridin-2-Yl]-1-Phenylurea",
      "1-Phenyl-3-(3-Phenylmethoxypyridin-2-Yl)Urea",
      "Urea, N-(3-Phenyl)-N'-[3-(Phenylmethoxy)-2-Pyridinyl]-",
    ],
  },
  {
    primary_id: "D0P6UB",
    names: ["Methamphetamine"],
  },
  {
    primary_id: "D0P6UK",
    names: ["Citrofen", "Citrofen (TN)"],
  },
  {
    primary_id: "D0P6VV",
    names: ["Dezocine"],
  },
  {
    primary_id: "D0P6WN",
    names: ["Carbamide Derivative 10"],
  },
  {
    primary_id: "D0P6YD",
    names: ["MK-7252"],
  },
  {
    primary_id: "D0P6YL",
    names: ["MX-35"],
  },
  {
    primary_id: "D0P6YN",
    names: ["CART-33 Cells"],
  },
  {
    primary_id: "D0P6YO",
    names: ["NSD-721"],
  },
  {
    primary_id: "D0P6ZB",
    names: ["Resorcinol Compound 14"],
  },
  {
    primary_id: "D0P6ZH",
    names: ["Lercanidipine"],
  },
  {
    primary_id: "D0P6ZZ",
    names: ["H-C[Cys-Phe-DTrp-Lys-Thr-Cys]-OH", "CHEMBL438247"],
  },
  {
    primary_id: "D0P7AS",
    names: ["5-Fluoro-6-Ethyluridine-5'-O-Monophosphate"],
  },
  {
    primary_id: "D0P7BA",
    names: ["INP102"],
  },
  {
    primary_id: "D0P7BQ",
    names: [
      "Atpenin B",
      "Atpenin A4",
      "Atpenin A5",
      "NBRI-23477A",
      "NBRI-23477B",
      "Atpenins Program (Cancer), Meiji Seika Kaisha",
      "Mitochondrial Complex II Inhibitors (Cancer), Meiji Seika Kaisha",
      "Succinate-Ubiquinone Oxidoreductase Inhibitors (Cancer)",
      "Succinate-Ubiquinone Oxidoreductase Inhibitors (Cancer), Meiji Seika Kaisha",
    ],
  },
  {
    primary_id: "D0P7CD",
    names: ["1-(4-Cyano-Thiazol-2-Yl)-3-Phenethyl-Thiourea"],
  },
  {
    primary_id: "D0P7CS",
    names: ["LUF-5417"],
  },
  {
    primary_id: "D0P7DA",
    names: ["PMID25435285-Compound-10"],
  },
  {
    primary_id: "D0P7DF",
    names: ["1-[4-(4-Iodophenoxy)Phenyl]piperazine", "CHEMBL567267", "SCHEMBL13234979"],
  },
  {
    primary_id: "D0P7EK",
    names: [
      "Mannitol",
      "Diosmol",
      "Hexahydroxyhexane",
      "Invenex",
      "Isotol",
      "MTL",
      "Manita",
      "Mannazucker",
      "Mannidex",
      "Mannigen",
      "Mannistol",
      "Mannit",
      "Mannite",
      "Osmitrol",
      "Osmofundin",
      "Osmosal",
      "Resectisol",
      "Cordycepic Acid",
      "Cpd Without Stereochemical Designation",
      "Manna Sugar",
      "Mannitol [USAN]",
      "Marine Crystal",
      "Resectisol In Plastic Container",
      "M0044",
      "Mannidex 16700",
      "Mannogem 2080",
      "D-Mannite",
      "D-Mannitol",
      "DL-Mannitol",
      "MANNITOL 25%",
      "Maniton-S",
      "Mannitol (USP)",
      "Mannitol (VAN)",
      "Mannitol 10%",
      "Mannitol 10% In Plastic Container",
      "Mannitol 15%",
      "Mannitol 15% In Plastic Container",
      "Mannitol 20%",
      "Mannitol 20% In Plastic Container",
      "Mannitol 5%",
      "Mannitol 5% In Plastic Container",
      "OSMITROL 10% IN WATER IN PLASTIC CONTAINER",
      "OSMITROL 15% IN WATER IN PLASTIC CONTAINER",
      "OSMITROL 20% IN WATER IN PLASTIC CONTAINER",
      "OSMITROL 5% IN WATER IN PLASTIC CONTAINER",
      "Osmitrol (TN)",
      "Osmitrol 10% In Water",
      "Osmitrol 15% In Water",
      "Osmitrol 20% In Water",
      "Osmitrol 5% In Water",
      "SDM No. 35",
      "SORBITOL-MANNITOL IN PLASTIC CONTAINER",
      "Sorbitol-Mannitol",
      "D-Mannitol (JP15)",
      "MANNITOL 10% W/ DEXTROSE 5% IN DISTILLED WATER",
      "D-(-)-Mannitol",
      "ED1D1E61-FEFB-430A-AFDC-D1F4A957FC3D",
      "MANNITOL 15% DEXTROSE 5% IN SODIUM CHLORIDE 0.45%",
      "MANNITOL 5% DEXTROSE 5% IN SODIUM CHLORIDE 0.12%",
      "(2R,3R,4R,5R)-Hexane-1,2,3,4,5,6-Hexol",
      "(L)-Mannitol",
    ],
  },
  {
    primary_id: "D0P7FK",
    names: [
      "2-Amino-4-Furan-2-Yl-6-Phenyl-Nicotinonitrile",
      "CHEMBL447192",
      "2-Amino-4-(Furan-2-Yl)-6-Phenylpyridine-3-Carbonitrile",
      "BAS 02565128",
      "AC1LEWZ0",
      "Oprea1_555020",
      "SCHEMBL6250225",
      "MolPort-001-022-735",
      "HMS1617K16",
      "STL308426",
      "ZINC19608452",
      "BDBM50262388",
      "AKOS000670186",
      "MCULE-4659467081",
      "ST50302441",
      "2-Amino-4-(2-Furyl)-6-Phenylnicotinonitrile",
      "2-Amino-4-(2-Furyl)-6-Phenylpyridine-3-Carbonitrile",
    ],
  },
  {
    primary_id: "D0P7FM",
    names: ["INCB57643"],
  },
  {
    primary_id: "D0P7GR",
    names: ["Tetradecyl-Phosphonic Acid"],
  },
  {
    primary_id: "D0P7GV",
    names: ["Annexin A5"],
  },
  {
    primary_id: "D0P7HI",
    names: [
      "AC-8632",
      "5-(Tetradecyloxy)-2-Furoic Acid",
      "TOFA",
      "54857-86-2",
      "5-(Tetradecyloxy)-2-Furancarboxylic Acid",
      "5-Tetradecyloxy-2-Furonic Acid",
      "Rmi 14514",
      "UNII-BI12FRY055",
      "2-Furancarboxylic Acid, 5-(Tetradecyloxy)-",
      "MDL 14514",
      "RMI-14514",
      "BRN 0539681",
      "5-(Tetradecyloxy)Furan-2-Carboxylic Acid",
      "BI12FRY055",
      "CHEBI:77936",
      "C19H32O4",
      "2-Furancarboxylic Acid,5-(Tetradecyloxy)-",
      "AC1Q5UHU",
      "AC1L3GNQ",
      "5-(Tetradecyloxy)Furoic Acid",
      "SCHEMBL543676",
      "5-Tetradecyloxy-2-Furoic Acid",
      "GTPL5280",
      "CHEMBL1562779",
      "TOFA, RMI 14514",
      "CTK5A2675",
      "DTXSID60203338",
      "Acetyl-Coenzyme A Carboxylase Inhibitor (Parkinson's Disease), Accera",
      "Acetyl-Coenzyme A Carboxylase Inhibitor (Parkinson's Disease), Neuera",
    ],
  },
  {
    primary_id: "D0P7HM",
    names: ["Aom-0821"],
  },
  {
    primary_id: "D0P7HQ",
    names: ["Pyrazole Derivative 13"],
  },
  {
    primary_id: "D0P7IL",
    names: ["GLG-801"],
  },
  {
    primary_id: "D0P7JE",
    names: ["S,S-(2-Hydroxyethyl)Thiocysteine", "AC1OAH3S"],
  },
  {
    primary_id: "D0P7JZ",
    names: [
      "Carbidopa",
      "Atamet",
      "Carbidopum",
      "Lodosin",
      "Lodosyn",
      "Methyldopahydrazine",
      "Stalevo",
      "Carbidopa Monohydrate",
      "Carbidopa Anhydrous",
      "Carbidopa Hydrate",
      "Carbidopum Monohydricum",
      "MK 486",
      "Alpha-Methyldopahydrazine",
      "C-126",
      "C-DOPA",
      "Carbidopa (Anhydrous)",
      "Carbidopum [INN-Latin]",
      "Lodosyn (TN)",
      "Lodosyn, Carbidopa",
      "MK-485",
      "MK-486",
      "N-Aminomethyldopa",
      "Carbidopa [USAN:INN:BAN]",
      "Carbidopa-1-Wasser",
      "Hadrazino-Alpha-Methyldopa",
      "L-Alpha-Methyldopahydrazine",
      "Carbidopa, (S)-Isomer",
      "Carbidopa, Entacapone, & Levodopa",
      "S(-)-CARBIDOPA",
      "S-(-)-Carbidopa",
      "L-Alpha-(3,4-Dihydroxybenzyl)-Alpha-Hydrazinopropionic Acid Monohydrate",
      "Alpha-Hydrazino-Alpha-Methyl-Beta-(3,4-Dihydroxyphenyl)Propionic Acid",
      "L-3-(3,4-Dihydroxyphenyl)-2-Methyl-2-Hydrazinopropionic Acid",
      "L-Alpha-Methyl-Alpha-Hydrazino-Beta-(3,4-Dihydroxyphenylpropionic Acid",
      "L-Alpha-Methyl-Beta-(3,4-Dihydroxyphenyl)-Alpha-Hydrazinopropionic Acid",
      "S(-)-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methylhydrocinnamic Acid Monohydrate",
      "S-(-)-Alpha-Hydrazino-3,4-Dihydroxy-2-Methylbenzenepropanoic Acid",
      "Benzenepropanoic Acid, Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methyl-, Monohydrate, (S)",
      "KINSON, 3-(3,4-DIHYDROXY-PHENYL)-2-HYDRAZINO-2-METHYL-PROPIONIC ACID",
      "Hydrocinnamic Acid, (-)-L-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methyl-, Monohydrate",
      "(-)-L-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methylhydrocinnamic Acid",
      "(-)-L-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methylhydrocinnamic Acid Monohydrate",
      "(2S)-3-(3,4-Dihydroxyphenyl)-2-Hydrazino-2-Methylpropanoic Acid",
      "(2S)-3-(3,4-Dihydroxyphenyl)-2-Hydrazinyl-2-Methylpropanoic Acid",
      "(2S)-3-(3,4-Dihydroxyphenyl)-2-Hydrazinyl-2-Methylpropanoic Acid Hydrate",
      "(2S)-3-(3,4-Dihydroxyphenyl)-2-Hydrazinyl-2-Methylpropanoic Acid Monohydrate",
      "(2S)-3-(3,4-Dihydroxyphenyl)-2-Hydrazinyl-2-Methylpropanoic Acid-Water (1/1)",
      "(S)-(-)-Carbidopa",
      "(S)-(-)-Carbidopa Hydrate",
      "(S)-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methyl-Benzenepropanoic Acid Monohydrate",
      "(S)-Carbidopa",
      "(S)-Carbidopahydrate",
      "(AlphaS)-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methylbenzenepropanoic Acid",
      "(AlphaS)-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methylbenzenepropanoic Acid Monohydrate",
    ],
  },
  {
    primary_id: "D0P7KI",
    names: ["Anti-CD3 And Anti-Her2/Neu Bispecific Antibody-Armed Activated T Cells"],
  },
  {
    primary_id: "D0P7KL",
    names: ["NSC-745795"],
  },
  {
    primary_id: "D0P7KP",
    names: [
      "Dodecane-Trimethylamine",
      "DODECANE-TRIMETHYLAMINE",
      "N-Dodecyltrimethylammonium",
      "Dotac Compound",
      "Dodecyltrimethylammonium",
      "N,N,N-Trimethyldodecan-1-Aminium",
      "UNII-GT3793XV5S",
      "10182-91-9",
      "DODECYLTRIMETHYLAMMONIUM ION",
      "N,N,N-Trimethyl-1-Dodecanaminium",
      "Dodecyl(Trimethyl)Azanium",
      "GT3793XV5S",
      "CHEBI:41378",
      "1-Dodecanaminium, N,N,N-Trimethyl-",
      "CHEMBL109873",
      "Lauryltrimethylaminium",
      "Dodecyl-Trimethyl-Azanium",
      "AC1L1QDV",
      "Dodecyl(Trimethyl)Ammonium",
      "AC1Q28RJ",
      "SCHEMBL93498",
      "112-00-5 (Chloride)",
      "DTXSID4045008",
      "CHEMBL1180004",
      "1119-94-4 (Bromide)",
      "CTK4A0283",
    ],
  },
  {
    primary_id: "D0P7KS",
    names: ["N-3601"],
  },
  {
    primary_id: "D0P7KU",
    names: ["TGF-BR2 Mab"],
  },
  {
    primary_id: "D0P7LP",
    names: ["Azetidine Derivative 3"],
  },
  {
    primary_id: "D0P7MF",
    names: ["CMLVAX100"],
  },
  {
    primary_id: "D0P7MO",
    names: ["SFV IL-12 Gene Therapy"],
  },
  {
    primary_id: "D0P7MU",
    names: [
      "N-(4-Pyridin-2-Yl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL164087",
      "AC1N3WC4",
      "N-(4-Pyridin-2-Yl-1,3-Thiazol-2-Yl)Benzamide",
      "Oprea1_401409",
      "MolPort-004-132-922",
      "BDBM50097430",
      "AKOS017070888",
      "N-[4-(2-Pyridyl)Thiazol-2-Yl]benzamide",
    ],
  },
  {
    primary_id: "D0P7NP",
    names: ["CP-305202", "CHEMBL1276497", "BDBM50330342"],
  },
  {
    primary_id: "D0P7NW",
    names: ["(+/-)-Threo-N-Benzyl-3'-Chloromethylphenidate", "CHEMBL1254517", "BDBM50327168"],
  },
  {
    primary_id: "D0P7NY",
    names: [
      "PF-429242",
      "947303-87-9",
      "(R)-4-((Diethylamino)Methyl)-N-(2-Methoxyphenethyl)-N-(Pyrrolidin-3-Yl)Benzamide",
      "UNII-49WB3OA7VN",
      "49WB3OA7VN",
      "PF 429242",
      "PF429242",
      "CHEMBL233611",
      "J-502224",
      "C25H35N3O2",
      "4-[(Diethylamino)Methyl]-N-[2-(2-Methoxyphenyl)Ethyl]-N-(3R)-3-Pyrrolidinylbenzamide",
      "(R)-4-((Diethylamino)Methyl)-N-(2-Methoxyphenethyl)-N-(Pyrrolidin-3-Yl)Benz Amide",
      "(-)-PF-429242",
      "SCHEMBL2742702",
      "CTK8B5018",
      "DTXSID00635376",
      "EX-A1409",
      "ANW-47048",
      "ZINC28823615",
      "BDBM50216181",
      "AKOS015998619",
      "RL05965",
      "Benzamide, 4-((Diethylamino)Methyl)-N-(2-(",
    ],
  },
  {
    primary_id: "D0P7OB",
    names: ["PD-172938"],
  },
  {
    primary_id: "D0P7OY",
    names: [
      "N-Methylpiperidine",
      "1-Methylpiperidine",
      "N-METHYLPIPERIDINE",
      "626-67-5",
      "Piperidine, 1-Methyl-",
      "Methylpiperidine",
      "N-Methyl Piperidine",
      "1-Methyl-Piperidine",
      "LK 20",
      "EINECS 210-959-9",
      "UN2399",
      "N-Methylpiperidine, 99%",
      "AI3-25263",
      "UNII-617374QZN4",
      "N-Methylpiperidin",
      "PAMIQIKDUOTOBW-UHFFFAOYSA-N",
      "617374QZN4",
      "Methylpiperdine",
      "1-Methylpiperadine",
      "N-Methyl-Piperidine",
      "1-Methyl Piperidine",
      "PubChem7703",
      "Piperidine, 1-Methyl",
      "ACMC-209n6u",
      "EC 210-959-9",
      "1-Methylpiperidine Solution",
      "AC1L1Z9U",
      "KSC353M0L",
      "1-Methylpiperidine, &gt",
      "SCHEMBL1220995",
      "GTPL5522",
      "CHEMBL281417",
    ],
  },
  {
    primary_id: "D0P7PD",
    names: ["Empagliflozin/Linagliptin"],
  },
  {
    primary_id: "D0P7PK",
    names: [
      "2,3-Diphenyl-1H-Indole",
      "2,3-Diphenylindole",
      "3469-20-3",
      "1H-Indole, 2,3-Diphenyl-",
      "CHEMBL200719",
      "GYGKJNGSQQORRG-UHFFFAOYSA-N",
      "Indole, 2,3-Diphenyl-",
      "NSC17363",
      "EINECS 222-432-0",
      "Indole,3-Diphenyl-",
      "Cyto5A2",
      "AC1Q1HUE",
      "1H-Indole,3-Diphenyl-",
      "AC1Q4W1P",
      "AC1L2S6L",
      "Oprea1_738570",
      "2,3-Diphenyl-1H-Indole #",
      "SCHEMBL3864662",
      "CTK1C3291",
      "KS-00000FNK",
      "DTXSID50188241",
      "MolPort-001-804-408",
      "ZINC1036850",
      "STK080838",
      "BDBM50175411",
      "RW3400",
      "NSC-17363",
      "CCG-46312",
      "AKOS001022174",
      "ACM3469203",
      "MCULE-8938765915",
      "VI30149",
      "ACN-036136",
      "7J-655S",
      "QC-2111",
      "CC-07034",
      "AK-81890",
    ],
  },
  {
    primary_id: "D0P7PW",
    names: ["Seebri"],
  },
  {
    primary_id: "D0P7PY",
    names: ["Cirmtuzumab"],
  },
  {
    primary_id: "D0P7QA",
    names: ["Pyrazole Derivative 14"],
  },
  {
    primary_id: "D0P7QG",
    names: ["CNM-Au8"],
  },
  {
    primary_id: "D0P7QO",
    names: ["PMID28766366-Compound-Scheme5"],
  },
  {
    primary_id: "D0P7RI",
    names: ["5BrUTP", "5-Bromouridine Triphosphate", "Bromouridine 5'-Triphosphate", "5-Bromo-UTP"],
  },
  {
    primary_id: "D0P7RN",
    names: [
      "1-(4-Fluorophenyl)-2-(Phenylsulfonyl)Ethanone",
      "CHEMBL238832",
      "41024-58-2",
      "Ethanone, 1-(4-Fluorophenyl)-2-(Phenylsulfonyl)-",
      "1-(4-Fluorophenyl)-2-(Phenylsulfonyl)-1-Ethanone",
      "AC1LS74Y",
      "CTK1D4107",
      "DTXSID90363277",
      "MolPort-002-876-867",
      "ZINC1396918",
      "BDBM50212525",
      "AKOS005098035",
      "MCULE-9386710722",
      "7H-334S",
      "KS-00001Z92",
      "4'-Fluoro-Alpha-(Phenylsulfonyl)Acetophenone",
      "2-(Benzenesulfonyl)-1-(4-Fluorophenyl)Ethanone",
    ],
  },
  {
    primary_id: "D0P7RO",
    names: [
      "TRIAZOLOPYRIMIDINE",
      "8-Azapurine",
      "273-40-5",
      "2H-Triazolo[4,5-D]pyrimidine",
      "179268-22-5",
      "6H-1,2,3-Triazolo[4,5-D]pyrimidine",
      "2H-1,2,3-Triazolo[4,5-D]pyrimidine",
      "3H-1,2,3-Triazolo[4,5-D]pyrimidine",
      "3H-[1,2,3]triazolo[4,5-D]pyrimidine",
      "Triazolo[4,5-D]pyrimidine",
      "Triazolo(4,5-D)Pyrimidine",
      "273-39-2",
      "99331-25-6",
      "3H-1,2,3-TRIAZOLO(4,5-D)PYRIMIDINE",
      "ACMC-1CNOM",
      "AC1L22AZ",
      "SCHEMBL26606",
      "179268-21-4",
      "SCHEMBL11026556",
      "CTK1A0899",
      "CTK0H1173",
      "CTK0H1170",
      "CTK3G7531",
      "CTK0H1174",
      "DTXSID80181745",
      "GIIGHSIIKVOWKZ-UHFFFAOYSA-N",
      "ZINC2020258",
      "ACM273405",
      "FCH907879",
      "AKOS006372727",
    ],
  },
  {
    primary_id: "D0P7RV",
    names: ["AVL-181", "AVL-192", "CNX-277", "CVX-512", "HCV Protease Inhibitors, Avila"],
  },
  {
    primary_id: "D0P7SO",
    names: ["ADMVA"],
  },
  {
    primary_id: "D0P7SY",
    names: [
      "2-(4-Methoxyphenyl)-4H-Chromene-4-Thione",
      "CHEMBL607571",
      "2-(4-Methoxyphenyl)Chromene-4-Thione",
      "AC1LO0PS",
      "MolPort-019-767-607",
      "ZINC1011200",
      "BDBM50310182",
    ],
  },
  {
    primary_id: "D0P7TK",
    names: ["Ebola Virus Vaccine + Matrix-M"],
  },
  {
    primary_id: "D0P7TN",
    names: [
      "5-Methyl-Cyclosal-D4TMP",
      "CHEMBL223807",
      "BDBM50206639",
      "5-Methyl-1-[(2R,5S)-5-(6-Methyl-2-Oxo-4H-2lambda*5*-Benzo[1,3,2]dioxaphosphinin-2-Yloxymethyl)-2,5-Dihydro-Furan-2-Yl]-1H-Pyrimidine-2,4-Dione",
      "5-Me-CycloSal-D4TMP",
      "AC1LABZ0",
      "CHEMBL8458",
      "BDBM50064051",
      "5-Methyl-1-[(2R,5S)-5-[(6-Methyl-2-Oxo-4H-1,3,2",
      "5-Methyl-1-[5-(6-Methyl-2-Oxo-4H-2lambda*5*-Benzo[1,3,2]dioxaphosphinin-2-Yloxymethyl)-2,5-Dihydro-Furan-2-Yl]-1H-Pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0P7UB",
    names: ["Benzothiazine-Carboxamide Compound 1"],
  },
  {
    primary_id: "D0P7UG",
    names: ["Personalized Cancer Vaccine"],
  },
  {
    primary_id: "D0P7UV",
    names: ["Cyclo(-D-Tyr-L-Arg-L-Arg-L-Nal-Beta-Ala-)", "CHEMBL375991"],
  },
  {
    primary_id: "D0P7VJ",
    names: [
      "Pramiracetam",
      "Amacetam",
      "Ectapram",
      "Neupramir",
      "Remen",
      "Amacetam Hydrochloride",
      "Amacetam Sulfate",
      "Pramiracetam Hydrochloride",
      "Pramiracetam Sulfate",
      "CNS-1879",
    ],
  },
  {
    primary_id: "D0P7VQ",
    names: [
      "BCTC",
      "393514-24-4",
      "N-(4-Tert-Butylphenyl)-4-(3-Chloropyridin-2-Yl)Piperazine-1-Carboxamide",
      "CHEMBL441472",
      "4-(3-Chloro-2-Pyridinyl)-N-[4-(1,1-Dimethylethyl)Phenyl]-1-Piperazinecarboxamide, BCTC",
      "SCHEMBL1269620",
      "GTPL2460",
      "KS-00001DIH",
      "CTK8F0011",
      "DTXSID90432997",
      "MolPort-003-983-482",
      "BCP19629",
      "BCTC, &gt",
      "BDBM50133817",
      "ZINC27109670",
      "MFCD08690556",
      "BN0674",
      "AKOS024457842",
      "TRA0012037",
      "MCULE-6176078265",
      "CS-5476",
      "NCGC00370959-01",
      "HY-19960",
      "DA-42544",
      "RT-011429",
      "B-110",
      "FT-0696667",
      "B7520",
      "AZ0001-0587",
    ],
  },
  {
    primary_id: "D0P7WJ",
    names: ["PSN357"],
  },
  {
    primary_id: "D0P7WW",
    names: ["ET-001"],
  },
  {
    primary_id: "D0P7YV",
    names: ["D-Phe-Cys-Tyr", "CHEMBL274400", "D-Phe-Cys-Tyr--Trp-Lys-Thr-Pen-Thr-NH2"],
  },
  {
    primary_id: "D0P7ZY",
    names: ["N-Oxalyl-D-Tyrosine Derivative 1"],
  },
  {
    primary_id: "D0P8AW",
    names: ["Ro-21-7767"],
  },
  {
    primary_id: "D0P8AX",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 23"],
  },
  {
    primary_id: "D0P8AY",
    names: [
      "Dibenzepin",
      "Dibenzepin Hydrochloride",
      "Dibenzepine Hydrochloride",
      "Dibenzepine HCl",
      "315-80-0",
      "Dibenzepin HCl",
      "Noveryl",
      "Noveril",
      "Neodalit",
      "Ansiopax",
      "Dibenzepine Monohydrochloride",
      "UNII-JLN0273S4Z",
      "Sedatiplex",
      "Ecatril",
      "JLN0273S4Z",
      "Hydrofluoride-1927 Wander",
      "HF 1927",
      "Dibenzepin Hydrochloride [USAN]",
      "EINECS 206-255-6",
      "Ecatril Hydrochloride",
      "NSC 169412",
      "DSSTox_RID_82755",
      "DSSTox_CID_28394",
      "DSSTox_GSID_48421",
      "CAS-315-80-0",
      "NSC169412",
      "NCGC00160395-01",
      "10-(2-(Dimethylamino)Ethyl)-5,10-Dihydro-5-Methyl-11-H-Dibenzo(B,e)(1,4)Diazepin-11-One Monohydrochlorid",
    ],
  },
  {
    primary_id: "D0P8BE",
    names: ["(3-Methyl-Indol-1-Yl)-Propyl-Pyridin-4-Yl-Amine", "CHEMBL350692", "SCHEMBL7612026", "BDBM50048587"],
  },
  {
    primary_id: "D0P8CM",
    names: ["PMID27454349-Compound-92"],
  },
  {
    primary_id: "D0P8DW",
    names: ["A-943931"],
  },
  {
    primary_id: "D0P8ED",
    names: ["Oncoquest-CLL"],
  },
  {
    primary_id: "D0P8EU",
    names: ["Thymocartin"],
  },
  {
    primary_id: "D0P8EY",
    names: ["Folate Gamma-Hydroxamic Acid"],
  },
  {
    primary_id: "D0P8GO",
    names: ["IKKbeta Inhibitors"],
  },
  {
    primary_id: "D0P8HU",
    names: ["CCX-721", "CCR1 Antagonist (Myeloma), ChemoCentryx"],
  },
  {
    primary_id: "D0P8IK",
    names: [
      "Ethyl Oxo(Piperidin-1-Yl)Acetate",
      "53074-96-7",
      "Ethyl 1-Piperidineglyoxylate",
      "Ethyl 1-Piperidineoxoacetate",
      "ETHYL OXO(PIPERIDIN-1-YL)ACETATE",
      "Ethyl 2-Oxo-2-(Piperidin-1-Yl)Acetate",
      "ASBDXHCMVYVJQQ-UHFFFAOYSA-N",
      "1-Piperidineaceticacid, A-Oxo-, Ethyl Ester",
      "E1P",
      "Ethyl1-Piperidineglyoxylate",
      "EINECS 258-343-9",
      "N-Ethoxalylpiperidine",
      "1w8m",
      "AI3-26189",
      "AC1L2WQF",
      "Ethyl 2-Oxo-2-Piperidylacetate",
      "SCHEMBL1819086",
      "CTK4J7020",
      "DTXSID60201157",
      "MolPort-001-510-784",
      "Ethyl Oxo(1-Piperidinyl)Acetate #",
      "ZINC2149356",
      "ALBB-030316",
      "Ethyl 2-Oxo-2-Piperidin-1-Ylacetate",
      "AKOS000444140",
    ],
  },
  {
    primary_id: "D0P8IR",
    names: ["Bicyclic Hexapeptide Derivative 1"],
  },
  {
    primary_id: "D0P8IU",
    names: ["AE37/GP2 Peptide Vaccine"],
  },
  {
    primary_id: "D0P8IV",
    names: [
      "Dactinomycin",
      "Cosmegen",
      "Dactinomicina",
      "Dactinomycine",
      "Dactinomycinum",
      "Meractinomycin",
      "ACT D",
      "ACTINOMYCIN D AMP",
      "Actactinomycin A IV",
      "Actinomycin A IV",
      "Actinomycin Aiv",
      "Actinomycin I",
      "Actinomycin IV",
      "Actinomycin Cl",
      "Actinomycin X I",
      "Antibiotic From Streptomyces Parvullus",
      "Chounghwamycin B",
      "D Actinomycin",
      "Dactinomycin D",
      "Dactinomyein D",
      "Dilactone Actin Omycindioic D Acid",
      "Dilactone Actinomycin D Acid",
      "Dilactone Actinomycindioic D Acid",
      "Lyovac Cosmegen",
      "Oncostatin K",
      "Actinomycin 11 Cosmegen",
      "Actinomycin 7",
      "Actinomycin C1",
      "Actinomycin I1",
      "Actinomycin X 1",
      "Actinomycin X1",
      "HBF 386",
      "HBF 386 Meractinomycin",
      "X 97",
      "AD (VAN)",
      "Actinomycin C (Sub1)",
      "Actinomycin C(Sub1)",
      "Actinomycin D (JP15)",
      "Actinomycin D, Sodium Deoxyribonucleic Acid Complex",
      "Actinomycin I (Sub1)",
      "Actinomycin I(Sub 1)",
      "Actinomycin I(Sub1)",
      "Actinomycin-IV",
      "Actinomycindioic D Acid, Dilactone",
      "Acto-D",
      "COSMEGEN (TN)",
      "Dactinomicina [INN-Spanish]",
      "Dactinomycin (USP)",
      "Dactinomycin [USAN:BAN]",
      "Dactinomycine [INN-French]",
      "Dactinomycinum [INN-Latin]",
      "NP-005932",
      "Actinomycin D Deriv. Of 3H-Phenoxaocardazine",
      "GNF-PF-1977",
      "Actinomycin-[threo-Val-Pro-Sar-Meval]",
      "Actinomyein-Theo-Val-Pro-Sar-Meval",
      "Actinomycin-(Threo-Val-Pro-Sar-Meval)",
      "O)-(1-Oxo-1,2-Ethanediyl)]bis(N-Methyl)L-Valine",
      "PXZ-THR-DVA-PRO-SAR-MVA-THR-DVA-PRO-SAR-MVA",
      "DVA-DPR-SAR-MVA-(C1)DTH-PXZ-(C11)DTH-DVA-DPR-SAR-MVA",
      "1H-Pyrrolo(2,1-1)-(1,4,7,10,13)Oxatetraazacyclohexadecine",
      "4,6-Dimethyl-3-Oxo-3H-Phenoxazine-1,9-Dicarboxamide",
    ],
  },
  {
    primary_id: "D0P8IX",
    names: [
      "1-Hydroxy-10H-Anthracen-9-One",
      "1-Hydroxyanthrone",
      "1-Hydroxy-9-Anthrone",
      "1715-81-7",
      "9-Anthrone, 1-Hydroxy-",
      "9-Anthronol",
      "BRN 3530761",
      "CHEMBL123161",
      "1-HYDROXY-9,10-DIHYDROANTHRACEN-9-ONE",
      "Hydroxyanthrone",
      "AC1L45BO",
      "SCHEMBL1204733",
      "CTK4D3980",
      "DTXSID10169112",
      "1-Hydroxyanthracen-9(10H)-One",
      "MolPort-022-384-226",
      "9(10H)-Anthracenone,1-Hydroxy-",
      "ZINC6117388",
      "BDBM50060879",
      "AKOS022650101",
      "LS-20754",
    ],
  },
  {
    primary_id: "D0P8JA",
    names: ["Ro-04-6790"],
  },
  {
    primary_id: "D0P8JC",
    names: [
      "ANG-1007",
      "DNA Intercalator (Cancer), AngioChem",
      "Doxorubicin/Angiopep-2 Conjugate",
      "Doxorubicin-Conjugated DNA Intercalator (Cancer), Angiochem",
      "EPiC-Peptide Conjugated DNA Intercalator (Brain Cancer), AngioChem",
    ],
  },
  {
    primary_id: "D0P8JX",
    names: ["L-734217"],
  },
  {
    primary_id: "D0P8KQ",
    names: ["(4-Iodo-Butyl)-Trimethyl-Ammonium Iodide"],
  },
  {
    primary_id: "D0P8LR",
    names: ["HC3-1496"],
  },
  {
    primary_id: "D0P8MB",
    names: [
      "AIGIV",
      "Anthrax Immune Globulin Therapy (Intravenous)",
      "Anthrax Immune Globulin Therapy (Intravenous), Emergent",
    ],
  },
  {
    primary_id: "D0P8ME",
    names: ["LG-889", "Adult Multipotent Stem Cell (Hemophilia A), Lentigen/Expression/Emory"],
  },
  {
    primary_id: "D0P8MG",
    names: ["KDS-2000"],
  },
  {
    primary_id: "D0P8MI",
    names: ["Suplatast Tosilate"],
  },
  {
    primary_id: "D0P8MK",
    names: [
      "2-(6-Amino-8-Bromo-9H-Purin-9-Yl)Ethanol",
      "CHEMBL474058",
      "43047-77-4",
      "8-Bromo-9-(2-Hydroxyethyl)-9H-Adenine",
      "SCHEMBL987368",
      "CTK1C8207",
      "DTXSID50659527",
      "HPCQLQBYCLLETI-UHFFFAOYSA-N",
      "BDBM50257051",
      "AKOS030562477",
      "9H-Purine-9-Ethanol, 6-Amino-8-Bromo-",
      "2-(6-Amino-8-Bromo-Purin-9-Yl)Ethanol",
      "6-Amino-8-Bromo-9-(2-Hydroxyethyl)-9H-Purine",
    ],
  },
  {
    primary_id: "D0P8MM",
    names: ["1-(1-Propionylpiperidin-4-Yl)-3-O-Tolylurea", "CHEMBL1257633", "SCHEMBL2720137", "BDBM50327838"],
  },
  {
    primary_id: "D0P8NR",
    names: ["PRB-6014"],
  },
  {
    primary_id: "D0P8NV",
    names: ["BCI-952"],
  },
  {
    primary_id: "D0P8NZ",
    names: ["H-Dmt-Tic-NH-CH2-Indl", "CHEMBL408975"],
  },
  {
    primary_id: "D0P8PE",
    names: ["Isoxazole-Based Bicyclic Compound 14"],
  },
  {
    primary_id: "D0P8PG",
    names: ["1-(4-Bromophenethyl)-1H-Imidazole", "CHEMBL215320", "SCHEMBL6986190"],
  },
  {
    primary_id: "D0P8PH",
    names: ["XE991"],
  },
  {
    primary_id: "D0P8PK",
    names: [
      "6-(3-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "6-(3-Hydroxyphenyl)-2-Naphthol",
      "6-(3-Hydroxyphenyl)Naphthalen-2-Ol",
    ],
  },
  {
    primary_id: "D0P8QC",
    names: ["CEN-209"],
  },
  {
    primary_id: "D0P8QN",
    names: ["Recombinant Factor IX"],
  },
  {
    primary_id: "D0P8RH",
    names: ["Borneol"],
  },
  {
    primary_id: "D0P8RK",
    names: ["PMID27828716-Compound-16"],
  },
  {
    primary_id: "D0P8RS",
    names: [
      "Proguanil",
      "Bigumal",
      "Chlorguanid",
      "Chlorguanide",
      "Chloriguane",
      "Chloroguanide",
      "Paludrin",
      "Proguanile",
      "Proguanilum",
      "Proguanile [DCIT]",
      "Diguanyl (Hydrochloride)",
      "Drinupal (Hydrochloride)",
      "Paludrine (TN)",
      "Paludrine (Hydrochloride)",
      "Palusil (Hydrochloride)",
      "Proguanil [INN:BAN]",
      "Proguanilum [INN-Latin]",
      "RP-3359",
      "Tirian (Hydrochloride)",
      "M-4888 (Hydrochloride)",
      "SN-12837 (Hydrochloride)",
      "N1-P-Chlorophenyl-N5-Isopropylbiguanide",
      "N-(4-Chlorophenyl)-N'-(1-Methylethyl)Imidodicarbonimidic Diamide",
      "N-(4-Chlorophenyl)-N'-(Isopropyl)-Imidodicarbonimidic Diamide",
      "N-(4-Chlorophenyl)-N'-(Propan-2-Yl)Imidodicarbonimidic Diamide",
      "(1E)-1-[amino-(4-Chloroanilino)Methylidene]-2-Propan-2-Ylguanidine",
      "1-(P-Chlorophenyl)-5-Isopropylbiguanide",
      "1-Isopropyl-5-(4-Chlorophenyl)Biguanide",
    ],
  },
  {
    primary_id: "D0P8SR",
    names: ["Pyrrolo[2,3-B]pyridine Derivative 1"],
  },
  {
    primary_id: "D0P8SU",
    names: [
      "(R)-1-(4-Nitrophenethyl)-2-Methylpyrrolidine",
      "CHEMBL440996",
      "SCHEMBL1574678",
      "PFZYNGPCNCRLFU-LLVKDONJSA-N",
      "BDBM50372333",
      "ZINC29131354",
    ],
  },
  {
    primary_id: "D0P8SV",
    names: [
      "Antiviral Hyperactivation Limiting Therapeutics",
      "Antiviral Hyperactivation Limiting Therapeutics (HIV Infection)",
      "VS1-002",
      "VS1-004",
      "Antiviral Hyperactivation Limiting Therapeutics (HIV Infection), Virostatics",
    ],
  },
  {
    primary_id: "D0P8TI",
    names: ["Salvinicin A"],
  },
  {
    primary_id: "D0P8TJ",
    names: [
      "Biotin",
      "Biodermatin",
      "Bioepiderm",
      "Bios II",
      "Biotin Forte",
      "Factor S",
      "Lutavit H2",
      "Meribin",
      "Rovimix H2",
    ],
  },
  {
    primary_id: "D0P8TL",
    names: ["Saxitoxin"],
  },
  {
    primary_id: "D0P8TS",
    names: ["Eperisone"],
  },
  {
    primary_id: "D0P8UI",
    names: ["(S)-2-Methyl-1-(2-P-Tolyl-Ethyl)-Pyrrolidine", "CHEMBL257911"],
  },
  {
    primary_id: "D0P8UN",
    names: ["INV103"],
  },
  {
    primary_id: "D0P8VL",
    names: ["PMID29865878-Compound-8"],
  },
  {
    primary_id: "D0P8VT",
    names: ["PMID30280939-Compound-US20170128558"],
  },
  {
    primary_id: "D0P8VZ",
    names: ["ACS-14"],
  },
  {
    primary_id: "D0P8WC",
    names: ["PMID28074661-Compound-US20169447134C85"],
  },
  {
    primary_id: "D0P8XE",
    names: ["GNF-PF-3037"],
  },
  {
    primary_id: "D0P8YC",
    names: [
      "Batimastat",
      "130370-60-4",
      "BB-94",
      "Batimastat (BB-94)",
      "BB 94",
      "UNII-BK349F52C9",
      "BB94",
      "Batimastat(BB-94)",
      "C23H31N3O4S2",
      "CHEMBL279786",
      "BK349F52C9",
      "Butanediamide, N4-Hydroxy-N1-(2-(Methylamino)-2-Oxo-1-(Phenylmethyl)Ethyl)-2-(2-Methylpropyl)-3-((2-Thienylthio)Methyl)-, (2R-(1(S*),2R*,3S*))-",
      "4-(N-HYDROXYAMINO)-2R-ISOBUTYL-2S-(2-THIENYLTHIOMETHYL)SUCCINYL-L-PHENYLALANINE-N-METHYLAMIDE",
      "(2S,3R)-5-Methyl-3-(((AlphaS)-Alpha-(Methylcarbamoyl)Phenethyl)Carbamoyl)-2-((2-Thienylthio)Methyl)Hexanohydroxamic Acid",
    ],
  },
  {
    primary_id: "D0P8YJ",
    names: ["PTI-80"],
  },
  {
    primary_id: "D0P8YL",
    names: ["Hydroxyqunoline Analog 3"],
  },
  {
    primary_id: "D0P8YV",
    names: ["5-Hydroxymethyl-Chonduritol"],
  },
  {
    primary_id: "D0P9AC",
    names: ["Phenelzine"],
  },
  {
    primary_id: "D0P9AK",
    names: ["CPI-003", "Cardiosphere-Derived Stem Cell Therapy (Myocardial Infarction), Capricor"],
  },
  {
    primary_id: "D0P9AP",
    names: ["5-Hexyl-2-(Pyridin-4-Yloxy)Phenol", "CHEMBL259138"],
  },
  {
    primary_id: "D0P9BG",
    names: ["3-Benzyloxy-4-Methoxy-2'-Hydroxychalcone", "CHEMBL437006"],
  },
  {
    primary_id: "D0P9BM",
    names: [
      "3-Hydroxybenzylhydrazine",
      "3-(Hydrazinylmethyl)Phenol",
      "637-33-2",
      "3-(Hydrazinomethyl)Phenol",
      "Nsd 1015",
      "M-Hydroxybenzyl Hydrazine",
      "UNII-A27K5Q85R2",
      "3-Hydrazinomethyl-Phenol",
      "BRN 1841459",
      "HYDRAZINE, 1-(3-HYDROXYBENZYL)-",
      "CHEMBL352205",
      "NSD-1015",
      "A27K5Q85R2",
      "3-Hydroxy-Benzylhydrazine",
      "C7H10N2O",
      "M-Hydroxybenzylhydrazine",
      "Phenol,3-(Hydrazinylmethyl)-",
      "Spectrum_000313",
      "SpecPlus_000785",
      "AC1L1BYI",
      "Spectrum4_001209",
      "Spectrum5_001883",
      "Spectrum3_000726",
      "Lopac-H-9382",
      "Lopac0_000597",
      "BSPBio_002252",
      "KBioGR_001618",
      "3-(Hydrazinomethyl)Phenol #",
    ],
  },
  {
    primary_id: "D0P9BT",
    names: [
      "Alicaforsen",
      "Alicaforsen [INN]",
      "ISIS 2302",
      "ISIS-2302",
      "Intercellular Adhesion Molecule-1 Antisense Oligodeoxynucleotide",
      "DNA, D((R)-P-Thio)(G-C-C-A-A-G-C-T-G-G-C-A-T-C-C-G-T-C-A)",
      "Deoxyribonucleic Acid, D((R)-P-Thio)(G-C-C-C-A-A-G-C-T-G-G-C-A-T-C-C-G-T-C-A)",
    ],
  },
  {
    primary_id: "D0P9CB",
    names: ["LG631"],
  },
  {
    primary_id: "D0P9CN",
    names: ["1,1,1,2,2,3,3,5-Octafluoro-8-Phenyloctan-4-One"],
  },
  {
    primary_id: "D0P9CQ",
    names: [
      "Azithromycinarylalkylhydroxamic Acid",
      "CHEMBL510806",
      "SCHEMBL14329660",
      "BDBM27175",
      "Azithromycinarylalkylhydroxamic Acid, 8",
    ],
  },
  {
    primary_id: "D0P9DD",
    names: ["(2R)-2-Methyl-4,5-Dideoxy"],
  },
  {
    primary_id: "D0P9DL",
    names: ["HBV Vaccine"],
  },
  {
    primary_id: "D0P9EA",
    names: ["Paraldehyde"],
  },
  {
    primary_id: "D0P9ED",
    names: [
      "CMI-489",
      "CHEMBL291498",
      "HLNPMOXPJPCMLY-UHFFFAOYSA-N",
      "N-Methylepibatidine",
      "N-Methyl Epibatidine",
      "SCHEMBL10105083",
      "222/224 N-Methyl-Epibatidine",
      "BDBM50079453",
      "7-Methyl-2-(6-Chloro-3-Pyridyl)-7-Azabicyclo[2.2.1]heptane",
      "2-(6-Chloro-Pyridin-3-Yl)-7-Methyl-7-Aza-Bicyclo[2.2.1]heptane",
    ],
  },
  {
    primary_id: "D0P9EL",
    names: ["AS-601811", "4,8-Dimethyl-2,3,5,6-Tetrahydro-1H-Benzo[c]quinolizin-3-One"],
  },
  {
    primary_id: "D0P9EO",
    names: ["2-(N-Cyclopentylamino)-3'-Methylpropiophenone", "CHEMBL592854", "BDBM50308114"],
  },
  {
    primary_id: "D0P9FT",
    names: ["REC-15-2739"],
  },
  {
    primary_id: "D0P9HX",
    names: [
      "3-Beta-Hydroxy-5-Androsten-17-One",
      "BSPBio_001732",
      "SCHEMBL8242191",
      "MEGxm0_000469",
      "CHEMBL309821",
      "ACon1_002040",
      "CHEBI:93823",
      "A-Hydroxyandrost-5-En-17-O",
      "MolPort-001-740-060",
      "NCGC00179051-01",
      "NCGC00179051-02",
    ],
  },
  {
    primary_id: "D0P9IA",
    names: [
      "Dolichol Phosphate",
      "Dolichol-P",
      "Dolichol-Phosphate",
      "Dolichyl-Phosphate",
      "Dolychol Phosphate",
      "Dolichol-PO4",
      "AC1NQWWQ",
      "GTPL3214",
      "[(6E)-3,7,11-Trimethyldodeca-6,10-Dienyl] Dihydrogen Phosphate",
      "{[(6E)-3,7,11-Trimethyldodeca-6,10-Dien-1-Yl]oxy}phosphonic Acid",
    ],
  },
  {
    primary_id: "D0P9II",
    names: ["(2S)-Aminobutyryl-L-Proline Isobutylamide", "CHEMBL223556", "SCHEMBL6241779"],
  },
  {
    primary_id: "D0P9JE",
    names: ["Yuganning"],
  },
  {
    primary_id: "D0P9JF",
    names: [
      "2-Methyl-1,2-Di-Pyridin-3-Yl-1-Methoxypropane",
      "CHEMBL55488",
      "87372-73-4",
      "CTK3C4531",
      "DTXSID00658227",
      "BDBM50028161",
      "3,3'-(1-Methoxy-2-Methylpropane-1,2-Diyl)Dipyridine",
      "Pyridine, 3,3'-(2-Methoxy-1,1-Dimethyl-1,2-Ethanediyl)Bis-",
    ],
  },
  {
    primary_id: "D0P9JW",
    names: ["Emeriamine", "AD-4808"],
  },
  {
    primary_id: "D0P9KA",
    names: ["YM-16151-4"],
  },
  {
    primary_id: "D0P9KD",
    names: ["PF-3732010"],
  },
  {
    primary_id: "D0P9KI",
    names: ["5-Propenyl-2'-Deoxyuridine Triphosphate"],
  },
  {
    primary_id: "D0P9KJ",
    names: ["PMID25666693-Compound-43"],
  },
  {
    primary_id: "D0P9KL",
    names: [
      "TAK-683",
      "(5S)-6-[6-[(1E,3R,5Z)-3-Hydroxyundeca-1,5-Dienyl]pyridin-2-Yl]hexane-1,5-Diol",
      "U 75302",
      "U-75302",
      "119477-85-9",
      "6-(6-(3-Hydroxy-1,5-Undecadien-1-Yl)-2-Pyridinyl)-1,5-Hexanediol",
      "U75302",
      "AC1O6A9C",
      "SCHEMBL1894359",
      "GTPL3325",
      "ZINC4489632",
      "SR-01000946976",
      "SR-01000946976-1",
      "1,5-Hexanediol, 6-(6-(3-Hydroxy-1,5-Undecadienyl)-2-Pyridinyl)-,",
    ],
  },
  {
    primary_id: "D0P9KP",
    names: ["PYY(22-36)"],
  },
  {
    primary_id: "D0P9KU",
    names: ["JWH-252"],
  },
  {
    primary_id: "D0P9LO",
    names: ["BP-2.94"],
  },
  {
    primary_id: "D0P9MQ",
    names: ["(+/-)-Threo-N-(4-Nitrobenzyl)Methylphenidate", "CHEMBL1253839"],
  },
  {
    primary_id: "D0P9MW",
    names: ["IMP-321"],
  },
  {
    primary_id: "D0P9NT",
    names: ["Sodium Thiosulfate"],
  },
  {
    primary_id: "D0P9OL",
    names: ["Boc-Cyclo-(Glu-Tyr-Nle-D-Lys)-Trp-Nle-Asp-Phe-NH2", "CHEMBL273880"],
  },
  {
    primary_id: "D0P9OU",
    names: ["Cat Allergy Vaccine"],
  },
  {
    primary_id: "D0P9OW",
    names: ["PL-225B"],
  },
  {
    primary_id: "D0P9PZ",
    names: ["PMID25980951-Compound-10"],
  },
  {
    primary_id: "D0P9QR",
    names: ["JNJ-61187165"],
  },
  {
    primary_id: "D0P9QW",
    names: ["Tipapkinogene Sovacivec"],
  },
  {
    primary_id: "D0P9RF",
    names: [
      "Terazosin",
      "Blavin",
      "Flumarc",
      "Fosfomic",
      "Hytracin",
      "Hytrin",
      "Terazosabb",
      "Terazosina",
      "Terazosine",
      "Terazosinum",
      "Vasomet",
      "Trazosin HCl",
      "A 45975",
      "Abbott 45975",
      "A-45975",
      "Hytrin (TN)",
      "Terazosabb (TN)",
      "Terazosin (INN)",
      "Terazosin [INN:BAN]",
      "Terazosina [INN-Spanish]",
      "Terazosine [INN-French]",
      "Terazosinum [INN-Latin]",
      "[4-(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)Piperazin-1-Yl](Tetrahydrofuran-2-Yl)Methanone",
      "[4-(4-Amino-6,7-Dimethoxyquinazolin-2-Yl)Piperazin-1-Yl]-(Oxolan-2-Yl)Methanone",
      "1-(4-Amino-6,7-Dimethoxy-2-Quinazolinyl)-4-((Tetrahydro-2-Furanyl)Carbonyl)Piperazine",
      "1-(4-Amino-6,7-Dimethoxy-2-Quinazolinyl)-4-(Tetrahydro-2-Furoyl)Piperazine",
      "6,7-Bis(Methyloxy)-2-[4-(Tetrahydrofuran-2-Ylcarbonyl)Piperazin-1-Yl]quinazolin-4-Amine",
      "6,7-Dimethoxy-2-[4-(Tetrahydrofuran-2-Ylcarbonyl)Piperazin-1-Yl]quinazolin-4-Amine",
    ],
  },
  {
    primary_id: "D0P9RG",
    names: ["ABX-EGF"],
  },
  {
    primary_id: "D0P9RP",
    names: ["Sucrose Octasulfate"],
  },
  {
    primary_id: "D0P9RV",
    names: ["PMID30247903-Compound-General Structure33"],
  },
  {
    primary_id: "D0P9SW",
    names: ["Erybreadin D"],
  },
  {
    primary_id: "D0P9TS",
    names: ["MNX-160"],
  },
  {
    primary_id: "D0P9UP",
    names: ["Civacir"],
  },
  {
    primary_id: "D0P9UW",
    names: ["Periodontitis Therapy, Sanofi"],
  },
  {
    primary_id: "D0P9UX",
    names: ["AMG 211"],
  },
  {
    primary_id: "D0P9VX",
    names: ["Pyrimidine Derivative 27"],
  },
  {
    primary_id: "D0P9WA",
    names: [
      "Analgesics",
      "Analgesics (Migraine, Spiegelmer)",
      "Analgesics (Migraine, Spiegelmer), Eli Lilly",
      "Analgesics (Migraine, Spiegelmer), Noxxon/Eli Lilly",
    ],
  },
  {
    primary_id: "D0P9WF",
    names: ["MTL-003"],
  },
  {
    primary_id: "D0P9WR",
    names: ["Flavopiridol Analog 1"],
  },
  {
    primary_id: "D0P9XE",
    names: [
      "Rx-101005",
      "Antibacterials, Trius Therapeutic",
      "Rx-101079",
      "DHFR Inhibitors (Bacterial Infection), Trius",
      "Dihydrofolate Reductase Inhibitor (Bacterial Infection), Trius",
    ],
  },
  {
    primary_id: "D0P9XM",
    names: ["Cobomarsen"],
  },
  {
    primary_id: "D0P9XW",
    names: ["VR465"],
  },
  {
    primary_id: "D0P9YP",
    names: ["AVP-13358"],
  },
  {
    primary_id: "D0P9YQ",
    names: ["Avotermin"],
  },
  {
    primary_id: "D0P9YR",
    names: ["Peptide Analog 29"],
  },
  {
    primary_id: "D0P9YU",
    names: [
      "(1-Amino-2-Phenyl-Ethyl)-Phosphinic Acid",
      "CHEMBL37846",
      "80744-66-7",
      "(1-AMINO-2-PHENYLETHYL)PHOSPHINIC ACID",
      "Phosphinic Acid, (1-Amino-2-Phenylethyl)-",
      "AC1O49RT",
      "MLS004256549",
      "SCHEMBL3862224",
      "CTK5E8078",
      "DTXSID90422994",
      "APQRSTHZZSRXIS-UHFFFAOYSA-N",
      "AKOS006273173",
      "SMR003081569",
      "(1-Amino-2-Phenylethyl)-Hydroxy-Oxophosphanium",
      "Phosphinic Acid,(1-Amino-2-Phenylethyl)- (9CI)",
    ],
  },
  {
    primary_id: "D0P9YV",
    names: ["N-(5-Chloro-2-(Phenylthio)Phenyl)Butyramide", "CHEMBL371088"],
  },
  {
    primary_id: "D0P9ZD",
    names: ["PMID28454500-Compound-94"],
  },
  {
    primary_id: "D0P9ZN",
    names: ["3-Methyl-6-(Trifluoromethyl)-9H-Carbazole", "CHEMBL1172905", "SCHEMBL10253400"],
  },
  {
    primary_id: "D0P9ZV",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Cyclohexyl Ester",
      "CHEMBL443552",
      "BDBM50139116",
      "4-(Sulfamoyloxy)Benzoic Acid Cyclohexyl Ester",
    ],
  },
  {
    primary_id: "D0P9ZZ",
    names: ["Phenyl 4-(Undecyloxy)Phenylcarbamate"],
  },
  {
    primary_id: "D0PA0S",
    names: ["LJP-920"],
  },
  {
    primary_id: "D0PA1B",
    names: ["DCDS-4501A"],
  },
  {
    primary_id: "D0PA1G",
    names: ["Benzylsulfinic Acid"],
  },
  {
    primary_id: "D0PA2N",
    names: [
      "Azlocillin",
      "Azlocilina",
      "Azlocilline",
      "Azlocillinum",
      "Securopen",
      "Bayer Brand Of Azlocillin",
      "Azlocilina [INN-Spanish]",
      "Azlocillin Sodium (Sterile)",
      "Azlocilline [INN-French]",
      "Azlocillinum [INN-Latin]",
      "Bay-E 6905",
      "Azlocillin (USAN/INN)",
      "Azlocillin [USAN:BAN:INN]",
      "(2R,5R,6S)-3,3-Dimethyl-7-Oxo-6-[[(2R)-2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2R,5S,6S)-3,3-Dimethyl-7-Oxo-6-[[(2R)-2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-((R)-2-(2-Oxo-1-Imidazolidinecarboxamido)-2-Phenylacetamido)-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-[[(2R)-2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-[[(2S)-2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-[[2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-{[(2R)-2-{[(2-Oxoimidazolidin-1-Yl)Carbonyl]amino}-2-Phenylacetyl]amino}-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-3,3-Dimethyl-7-Oxo-6-{[(2S)-2-{[(2-Oxoimidazolidin-1-Yl)Carbonyl]amino}-2-Phenylacetyl]amino}-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5S,6R)-3,3-Dimethyl-7-Oxo-6-[[(2R)-2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2r,5s,6s)-3,3-Dimethyl-7-Oxo-6-{[(2r)-2-{[(2-Oxoimidazolidin-1-Yl)Carbonyl]amino}-2-Phenylacetyl]amino}-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(5R,6R)-3,3-Dimethyl-7-Oxo-6-[[(2R)-2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(6R)-3,3-Dimethyl-7-Oxo-6-[[2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "2,2-Dimethyl-6beta-[(2R)-2-{[(2-Oxoimidazolidin-1-Yl)Carbonyl]amino}-2-Phenylacetamido]penam-3alpha-Carboxylic Acid",
      "3,3-Dimethyl-7-Oxo-6-[[2-[(2-Oxoimidazolidine-1-Carbonyl)Amino]-2-Phenylacetyl]amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "3,3-Dimethyl-7-Oxo-6-{[{[(2-Oxoimidazolidin-1-Yl)Carbonyl]amino}(Phenyl)Acetyl]amino}-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "6-((R)-2-(2-Oxoimidazolidin-1-Carboxamido)-2-Phenylacetamido)Penicillansaeure",
    ],
  },
  {
    primary_id: "D0PA2R",
    names: ["JD-4000"],
  },
  {
    primary_id: "D0PA5L",
    names: ["N-(Pentafluorophenyl)Sulfamide"],
  },
  {
    primary_id: "D0PA5S",
    names: ["Risedronate"],
  },
  {
    primary_id: "D0PA6K",
    names: ["LY53857"],
  },
  {
    primary_id: "D0PB0D",
    names: [
      "SRX-251",
      "AVN-246",
      "SRX-108",
      "SRX-31",
      "SRX-74",
      "Vasopressin V1a Antagonist (Raynauds Disease/Dysmenorrhea), Azevan Pharmaceuticals",
      "Vasopressin V1a Antagonist (Psychiatric Disorders/Dysmenorrhea), Azevan",
    ],
  },
  {
    primary_id: "D0PB0U",
    names: ["RG7893"],
  },
  {
    primary_id: "D0PB1C",
    names: ["TPM-02/Insulin"],
  },
  {
    primary_id: "D0PB2J",
    names: ["PMID25553724-Compound-WO2013082253 32"],
  },
  {
    primary_id: "D0PB2M",
    names: ["4-Iodophenyl-Alaninyl-(S)-2-Cyano-Pyrrolidine"],
  },
  {
    primary_id: "D0PB2W",
    names: ["BETA-HYDROXYETHYL THEOPHYLLINE"],
  },
  {
    primary_id: "D0PB3B",
    names: ["CBT-501"],
  },
  {
    primary_id: "D0PB3X",
    names: ["Vinylsulphones"],
  },
  {
    primary_id: "D0PB4P",
    names: ["XM-323", "DM-323", "DMP-323", "JCR-424"],
  },
  {
    primary_id: "D0PB5Q",
    names: [
      "Thiorphan",
      "(S)-THIORPHAN",
      "(2-MERCAPTOMETHYL-3-PHENYL-PROPIONYL)-GLYCINE",
      "CHEMBL298827",
      "95909-00-5",
      "1zdp",
      "AC1N9ZZO",
      "Lopac-T-6031",
      "SCHEMBL49662",
      "GTPL5278",
      "Glycine, N-[(2S)-2-(Mercaptomethyl)-1-Oxo-3-Phenylpropyl]-",
      "ZINC3872336",
      "BDBM50024102",
      "DB08626",
      "NCGC00016033-01",
      "NCGC00016933-01",
      "CAS-76721-89-6",
      "N-[(2S)-2-Benzyl-3-Sulfanylpropanoyl]glycine",
      "2-[(2S)-2-Benzyl-3-Sulfanylpropanamido]acetic Acid",
      "(S) (2-Mercaptomethyl-3-Phenyl-Propionylamino)-Acetic Acid",
      "2-[[(2S)-2-Benzyl-3-Sulfanylpropanoyl]amino]acetic Acid",
      "UNII-B79L7B5X3Z Component LJJ",
    ],
  },
  {
    primary_id: "D0PB5X",
    names: ["Cycloalkyl Nitrile Pyrazolo Pyridone Derivative 1"],
  },
  {
    primary_id: "D0PB6B",
    names: [
      "Dimethylhistaprodifen",
      "CHEMBL11553",
      "GTPL1203",
      "BDBM86170",
      "PDSP1_001311",
      "PDSP2_001295",
      "N,N-Dimethyl-2-(3,3-Diphenylpropyl)-1H-Imidazole-4-(Ethanamine)",
    ],
  },
  {
    primary_id: "D0PB6P",
    names: ["Org-9935"],
  },
  {
    primary_id: "D0PB6T",
    names: ["PMID28394193-Compound-49"],
  },
  {
    primary_id: "D0PC1A",
    names: ["PAV-617"],
  },
  {
    primary_id: "D0PC1V",
    names: [
      "T-82",
      "2-[2-(1-Benzylpiperidin-4-Yl)Ethyl]-9-Methoxy-2,3-Dihydro-1H-Pyrrolo[3,4-B]quinolin-1-One Hemifumarate",
    ],
  },
  {
    primary_id: "D0PC1Z",
    names: ["JWH-323"],
  },
  {
    primary_id: "D0PC2C",
    names: [
      "3-Ethyl-2,3,4,5-Tetrahydro-1H-Benzo[d]azepin-6-Ol",
      "CHEMBL129249",
      "1H-3-Benzazepin-6-Ol, 3-Ethyl-2,3,4,5-Tetrahydro-",
      "BDBM50003341",
      "143620-42-2",
    ],
  },
  {
    primary_id: "D0PC2Y",
    names: ["BVDU-MP"],
  },
  {
    primary_id: "D0PC5A",
    names: ["VER-156084"],
  },
  {
    primary_id: "D0PC5N",
    names: ["Premafloxacin"],
  },
  {
    primary_id: "D0PC7A",
    names: [
      "(+)-Trans-H2-PAT",
      "(2S,4R)-N,N-Dimethyl-4-Phenyl-1,2,3,4-Tetrahydronaphthalen-2-Amine",
      "Trans-PAT",
      "(Plusmn)-Trans-H2-PAT",
      "SCHEMBL3755418",
      "CHEMBL127307",
      "GTPL1209",
      "GTPL1207",
      "BDBM86032",
      "ZINC5965166",
      "PDSP2_000942",
      "PDSP1_000957",
    ],
  },
  {
    primary_id: "D0PC7I",
    names: ["L-742728"],
  },
  {
    primary_id: "D0PC8Q",
    names: ["MCF"],
  },
  {
    primary_id: "D0PC9R",
    names: ["GSK2941266"],
  },
  {
    primary_id: "D0PD0F",
    names: ["Paeoniflorin"],
  },
  {
    primary_id: "D0PD1W",
    names: ["Prostaglandin D2 Antagonists"],
  },
  {
    primary_id: "D0PD2I",
    names: ["Thiazole Carboxamide Derivative 8"],
  },
  {
    primary_id: "D0PD4C",
    names: ["CAR-T Cells Targeting CD22"],
  },
  {
    primary_id: "D0PD7X",
    names: [
      "2'-Nitro-Biphenyl-4-Carboxylic Acid Hydroxyamide",
      "CHEMBL177084",
      "BDBM50015162",
      "ZINC28086552",
      "2''-Nitro-Biphenyl-4-Carboxylic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0PD8G",
    names: ["Ac-I[CV(Dht)QDWGAHRC]T"],
  },
  {
    primary_id: "D0PE0D",
    names: ["Nuleusin"],
  },
  {
    primary_id: "D0PE0V",
    names: [
      "D-Cysteine",
      "921-01-7",
      "D-Cystein",
      "D-Zystein",
      "Cysteine, D-",
      "(2S)-2-Amino-3-Sulfanylpropanoic Acid",
      "(S)-2-Amino-3-Mercaptopropanoic Acid",
      "D-Amino-3-Mercaptopropionic Acid",
      "D-Cys",
      "(2S)-2-Amino-3-Mercaptopropanoic Acid",
      "CCRIS 5542",
      "CHEBI:16375",
      "UNII-IU8WN3PPI2",
      "EINECS 213-062-0",
      "IU8WN3PPI2",
      "(S)-2-Amino-3-Mercaptopropionic Acid",
      "CHEMBL171281",
      "DCY",
      "C3H7NO2S",
      "H-D-CYS-OH",
      "(S)-Cysteine",
      "D-Cysteine, 99%",
      "AC1L3OYY",
      "Epitope ID:140790",
      "D-Amino-3-Mercaptopropionate",
      "SCHEMBL95110",
      "GTPL4537",
      "AC1Q28E8",
      "D-Cysteine, &gt",
      "=99% (RT)",
      "KS-00000ACF",
    ],
  },
  {
    primary_id: "D0PE1A",
    names: ["DBO-11", "166374-49-8", "Naxifylline (USAN/INN)"],
  },
  {
    primary_id: "D0PE1K",
    names: ["Quinoline Carboxamide Derivative 5"],
  },
  {
    primary_id: "D0PE1R",
    names: ["Pyrrolo-Pyrrolone Derivative 2"],
  },
  {
    primary_id: "D0PE2L",
    names: ["GSK2402968"],
  },
  {
    primary_id: "D0PE2Y",
    names: ["ISIS 104255"],
  },
  {
    primary_id: "D0PE3J",
    names: ["CAR-T Cells Targeting CD19"],
  },
  {
    primary_id: "D0PE3Z",
    names: ["JNJ-61610588"],
  },
  {
    primary_id: "D0PE5T",
    names: [
      "BCX-1827",
      "(-)-(1R,3R,4R)-3-[(1S)-1-(Acetylamino)-2-Ethylbutyl]-4-[[amino(Imino)Methyl]amino]cyclopentanecarboxylic Acid",
      "(-)-(1R,3R,4R)-3-[(1S)-1-(Acetylamino)-2-Ethylbutyl]-4-{[amino(Imino)Methyl]amino}cyclopentanecarboxylic Acid",
      "AC1LAL6X",
      "Cyclopentane Derivative 50",
      "BDBM5023",
      "CHEMBL136111",
      "SCHEMBL14834652",
      "CTK6C6205",
      "(1R,3R,4R)-3-[(1S)-1-Acetamido-2-Ethyl-Butyl]-4-Guanidino-Cyclopentanecarboxylic Acid",
      "(1R,3S,4R)-3-[(1S)-1-Acetamido-2-Ethylbutyl]-4-(Diaminomethylideneamino)Cyclopentane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0PE8E",
    names: ["PMID25435179-Compound-WO2012106509Tenovin-6"],
  },
  {
    primary_id: "D0PE8P",
    names: ["PD173212"],
  },
  {
    primary_id: "D0PE8U",
    names: ["N-(1H-Indazol-5-Yl)Icosa-5,8,11,14-Tetraenamide", "CHEMBL472897"],
  },
  {
    primary_id: "D0PE9T",
    names: ["GS-438"],
  },
  {
    primary_id: "D0PF1A",
    names: ["CAR-BCMA T Cell"],
  },
  {
    primary_id: "D0PF2F",
    names: ["PMID25656651-Compound-15b"],
  },
  {
    primary_id: "D0PF2W",
    names: ["PMID25482888-Compound-72"],
  },
  {
    primary_id: "D0PF3J",
    names: ["DOLASTATIN-10"],
  },
  {
    primary_id: "D0PF3Z",
    names: ["N-IsopropylnorlitebamineN-Methoiodide"],
  },
  {
    primary_id: "D0PF4H",
    names: ["PMID28454500-Compound-91"],
  },
  {
    primary_id: "D0PF7F",
    names: ["TOLSERINE"],
  },
  {
    primary_id: "D0PF7S",
    names: ["PMID25980951-Compound-11"],
  },
  {
    primary_id: "D0PF8B",
    names: [
      "PEDF Gene Therapy",
      "AdPEDF",
      "AdGVPEDF.11D",
      "PEDF Gene Therapy (Ophthalmic)",
      "PEDF Gene Therapy (Ophthalmic), GenVec",
      "Pigment Epithelium-Derived Factor Gene Therapy, GenVec",
    ],
  },
  {
    primary_id: "D0PF9G",
    names: ["NN-4201"],
  },
  {
    primary_id: "D0PF9Q",
    names: [
      "2-(4-Piperidin-1-Ylmethylphenoxy)Benzothiazole",
      "Benzthiazole Compound, 33b",
      "SCHEMBL4689016",
      "CHEMBL454760",
      "BDBM24231",
    ],
  },
  {
    primary_id: "D0PF9X",
    names: ["Bicyclo-Heptan-2-Amine Derivative 2"],
  },
  {
    primary_id: "D0PG0F",
    names: ["Mitumprotimut-T"],
  },
  {
    primary_id: "D0PG0G",
    names: ["Drotrecogin Alfa"],
  },
  {
    primary_id: "D0PG0S",
    names: ["CI-999"],
  },
  {
    primary_id: "D0PG1G",
    names: ["PMID25726713-Compound-57"],
  },
  {
    primary_id: "D0PG2V",
    names: ["PMID28350212-Compound-12"],
  },
  {
    primary_id: "D0PG2W",
    names: ["Biomin Peptide-II"],
  },
  {
    primary_id: "D0PG5D",
    names: ["10-Propargyl-5,8-Dideazafolic Acid"],
  },
  {
    primary_id: "D0PG5G",
    names: ["Infliximab"],
  },
  {
    primary_id: "D0PG5J",
    names: ["ZD-4927"],
  },
  {
    primary_id: "D0PG5N",
    names: ["PMID25991433-Compound-J1"],
  },
  {
    primary_id: "D0PG5O",
    names: ["MGA012"],
  },
  {
    primary_id: "D0PG8L",
    names: [
      "D-Histidine",
      "351-50-8",
      "H-D-His-OH",
      "(R)-2-Amino-3-(1H-Imidazol-4-Yl)Propanoic Acid",
      "D-Histidin",
      "D-His",
      "(R)-2-Amino-3-(4-Imidazolyl)Propionic Acid",
      "(2R)-2-Amino-3-(1H-Imidazol-4-Yl)Propanoic Acid",
      "CHEBI:27947",
      "(2R)-2-Amino-3-(1H-Imidazol-5-Yl)Propanoic Acid",
      "MFCD00065963",
      "D-Alpha-Amino-Beta-(4-Imidazolyl)Propionic Acid",
      "(R)-Alpha-Amino-1H-Imidazole-4-Propionic Acid",
      "Q-101530",
      "D-Histamine",
      "EINECS 206-513-8",
      "PubChem12316",
      "2ez7",
      "AC1L2FID",
      "AC1Q4UAU",
      "AC1Q5R0N",
      "SCHEMBL59365",
      "KSC222G6N",
      "CHEMBL104875",
      "GTPL4679",
      "Jsp006338",
      "KS-00000ACN",
    ],
  },
  {
    primary_id: "D0PG8M",
    names: [
      "4',4-Dihydroxychalcone",
      "4,4'-Dihydroxychalcone",
      "UNII-CKY3J88Z94",
      "CKY3J88Z94",
      "CHEMBL145927",
      "1,3-Bis(4-Hydroxyphenyl)Prop-2-En-1-One",
      "3600-61-1",
      "4,4'-Dihydroxybenzylidene Acetophenone",
      "RVC 588 (Chalcone)",
      "4,4''-Dihydroxychalcone",
      "4'',4-Dihydroxychalcone",
      "AC1NV3S6",
      "(E)-1,3-Bis(4-Hydroxyphenyl)Prop-2-En-1-One",
      "Trans-4,4'-Dihydroxychalcone",
      "SCHEMBL773588",
      "(E)-4,4'-Dihydroxychalcone",
      "4,4'-Dihydroxychalcone, (E)-",
      "1,3-Bis(4-Hydroxyphenyl)Propenone",
      "ZINC5843955",
      "NSC652891",
      "LMPK12120193",
      "BDBM50068224",
      "AKOS022660924",
      "NSC-652891",
    ],
  },
  {
    primary_id: "D0PG8O",
    names: [
      "Naltrexone",
      "Depotrex",
      "Naltrel",
      "Vivitrex",
      "Vivitrol",
      "Naltrexone Depot",
      "IBS Therapy, Pain Therapeutics",
      "Irritable Bowel Syndrome Therapy, Pain Therapeutics",
      "PTI-901",
      "XR-NTX",
      "Naltrexone (Sustained Release), DAS",
      "Naltrexone (Sustained Release), Elbion",
      "Naltrexone Depot (Injectable Suspension), DrugAbuse Sciences",
      "Naltrexone Depot (Injectable Suspension), Elbion",
      "Opioid Antagonists (Irritable Bowel Syndrome), Pain Therapeutics",
      "Naltrexone (Low-Dose), Pain Therapeutics",
      "Naltrexone (Polymer Microcapsule Depot, Opioid Addiction/Alcohol Dependency), Biotek",
      "Naltrexone (Transdermal, Alcohol/Opioid Dependence), Syntropharma",
      "Naltrexone (Once-Monthly Controlled-Release, Medisorb), Alkermes",
    ],
  },
  {
    primary_id: "D0PH0G",
    names: ["NKTR-171", "Analgesic (Neuropathic Pain), Nektar"],
  },
  {
    primary_id: "D0PH0M",
    names: ["IDD-3"],
  },
  {
    primary_id: "D0PH0U",
    names: ["TDI-0042"],
  },
  {
    primary_id: "D0PH1G",
    names: [
      "3-[1-(4-Cyanobenzyl)-1H-Imidazol-5-Yl]-1-Propanol",
      "CHEMBL598399",
      "SCHEMBL3822518",
      "ACOYOVQVLSTOMH-UHFFFAOYSA-N",
      "ZINC34123980",
      "BDBM50307226",
      "AKOS027324260",
      "1-P-Cyanophenylmethyl-5-(3-Hydroxypropyl)-1H-Imidazole",
      "1-(P-Cyanophenylmethyl)-5-(3-Hydroxypropyl)-1H-Imidazole",
      "1-(P-Cyanophenylmethyl)-5-(3-Hydroxypropyl) -1H-Imidazole",
    ],
  },
  {
    primary_id: "D0PH4R",
    names: ["RG7842"],
  },
  {
    primary_id: "D0PH5J",
    names: ["IN0-8000"],
  },
  {
    primary_id: "D0PH5Z",
    names: [
      "Ceftazidime",
      "Ceftazidima",
      "Ceftazidimum",
      "Ceptaz",
      "Fortaz",
      "Ceftazidime Sodium In Plastic Container",
      "Ceftazidime Anhydrous",
      "Ceftazidime Pentahydrate",
      "Fortaz In Plastic Container",
      "SN 401",
      "CEFTAZIDIME (ARGININE FORMULATION)",
      "Ceftazidima [INN-Spanish]",
      "Ceftazidime (INN)",
      "Ceftazidime (TN)",
      "Ceftazidimum [INN-Latin]",
      "Cefzim (TN)",
      "Ceptaz (TN)",
      "Fortaz (TN)",
      "Fortum (TN)",
      "(6R,7R)-7-({(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(1-Carboxy-1-Methylethoxy)Imino]acetyl}amino)-8-Oxo-3-(Pyridinium-1-Ylmethyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "(6R,7R)-7-[[(2E)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(1-Hydroxy-2-Methyl-1-Oxopropan-2-Yl)Oxyiminoacetyl]amino]-8-Oxo-3-(Pyridin-1-Ium-1-Ylmethyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "(6R,7R)-7-[[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(1-Hydroxy-2-Methyl-1-Oxopropan-2-Yl)Oxyiminoacetyl]amino]-8-Oxo-3-(Pyridin-1-Ium-1-Ylmethyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "(6R,7R)-7-[[2-(2-Amino-1,3-Thiazol-4-Yl)-2-(1-Hydroxy-2-Methyl-1-Oxopropan-2-Yl)Oxyiminoacetyl]amino]-8-Oxo-3-(Pyridin-1-Ium-1-Ylmethyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-{[(2-Carboxypropan-2-Yl)Oxy]imino}acetyl]amino}-8-Oxo-3-(Pyridinium-1-Ylmethyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "7-[[2-(2-Amino-1,3-Thiazol-4-Yl)-2-(1-Hydroxy-2-Methyl-1-Oxopropan-2-Yl)Oxyiminoacetyl]amino]-8-Oxo-3-(Pyridin-1-Ium-1-Ylmethyl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "7beta-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-{[(2-Carboxypropan-2-Yl)Oxy]imino}acetyl]amino}-3-(Pyridinium-1-Ylmethyl)-3,4-Didehydrocepham-4-Carboxylate",
    ],
  },
  {
    primary_id: "D0PH6Z",
    names: ["CD19+ CAR T Cells"],
  },
  {
    primary_id: "D0PH9I",
    names: ["AZD5153"],
  },
  {
    primary_id: "D0PI0R",
    names: ["3'',5''-Dimethoxy-[1,1':2',1'']-Terphenyl-4-Ol", "CHEMBL207240", "ZINC35929342", "BDBM50186755"],
  },
  {
    primary_id: "D0PI1C",
    names: ["HQL-79"],
  },
  {
    primary_id: "D0PI1W",
    names: ["Nitrogen/Sulfur-Substituted Estrene Derivative 1"],
  },
  {
    primary_id: "D0PI1X",
    names: ["Bicyclic Heteroaryl Amide Derivative 1"],
  },
  {
    primary_id: "D0PI1Z",
    names: ["Resorcinol Compound 13"],
  },
  {
    primary_id: "D0PI2A",
    names: ["ISIS 173831"],
  },
  {
    primary_id: "D0PI2E",
    names: [
      "Sulfoxone",
      "Aldapsone",
      "Aldesulfon",
      "Diamidin",
      "Diason",
      "Diazon",
      "Novotrone",
      "Adesulfone Sodium",
      "Diasone Sodium Enterab",
      "Sodium Aldesulphone",
      "Sodium Sulfoxone",
      "[4-[4-(Sulfinomethylamino)Phenyl]sulfonylanilino]methanesulfinic Acid",
      "[sulfonylbis(4,1-Phenyleneimino)]dimethanesulfinic Acid",
    ],
  },
  {
    primary_id: "D0PI2I",
    names: ["PMID28447479-Compound-26"],
  },
  {
    primary_id: "D0PI3Z",
    names: ["Isepamicin"],
  },
  {
    primary_id: "D0PI4H",
    names: ["VPC12249"],
  },
  {
    primary_id: "D0PI4T",
    names: ["BW-42"],
  },
  {
    primary_id: "D0PI5D",
    names: ["7-Methyl-2-M-Tolylpyrido[2,3-D]pyrimidine", "CHEMBL238552"],
  },
  {
    primary_id: "D0PI5O",
    names: ["PMID28394193-Compound-43"],
  },
  {
    primary_id: "D0PI7V",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 1"],
  },
  {
    primary_id: "D0PI8W",
    names: [
      "Amylin",
      "Amlintide",
      "Pancreatic Amylin",
      "IAPP",
      "Islet Amyloid Polypeptide",
      "Insulinoma Amyloid Peptide",
      "Diabetes-Associated Peptide",
      "Insulinoma Amyloid Polypeptide",
      "Amylin (Human)",
      "Amlintide [USAN:INN]",
      "122384-88-7",
      "Human Islet Amyloid Peptide",
      "AC001",
      "AC 001",
      "SCHEMBL374892",
      "C8H16N2O4.C4H10",
      "LS-172011",
      "FT-0688968",
    ],
  },
  {
    primary_id: "D0PJ1W",
    names: ["PMID28766366-Compound-Scheme9EHT9851"],
  },
  {
    primary_id: "D0PJ2C",
    names: ["PMID27454349-Compound-99"],
  },
  {
    primary_id: "D0PJ3M",
    names: ["Cinnamaldehyde", "Cinnamic Aldehyde"],
  },
  {
    primary_id: "D0PJ3R",
    names: ["(Z)-2-Methyl-1-Oxazol-2-Yl-Octadec-9-En-1-One", "CHEMBL37044", "BDBM50100866"],
  },
  {
    primary_id: "D0PJ4K",
    names: [
      "Calcium",
      "7440-70-2",
      "UNII-SY7Q814VUP",
      "HSDB 273",
      "EINECS 231-179-5",
      "UN1401",
      "SY7Q814VUP",
      "Calcium, 99%, Granular",
      "Calcium, 99%, Turnings",
      "MFCD00085314",
      "Calcium Compounds",
      "Elemental Calcium",
      "Calcium, Elemental",
      "Kalzium",
      "Calcium Atom",
      "Calcium Ingot",
      "Calcium Chelate",
      "Calcium Standard Solution, For AAS, 1 Mg-Ml Ca In 2 To 5% HNO3",
      "Bio Lacta Cal",
      "Calcium(0)",
      "Calcium, Natural Milk",
      "Calcium, Granular, 99%",
      "20Ca",
      "EC 231-179-5",
      "Calcium Milk Calcium 28%",
      "AC1NUT41",
      "Calcium Krebs 21% 40M",
      "Calcium Sweet Chelate 20%",
      "Calcium Oratate 11% 40M",
      "KSC377C8L",
    ],
  },
  {
    primary_id: "D0PJ7M",
    names: ["1,2-Dihydroindazolo[4,3-Bc][1,5]benzoxazepine Derivative 1"],
  },
  {
    primary_id: "D0PJ8O",
    names: ["PMID25666693-Compound-129"],
  },
  {
    primary_id: "D0PJ8X",
    names: ["2-(Substituted Ethynyl)Quinoline Derivative 1"],
  },
  {
    primary_id: "D0PJ9J",
    names: [
      "1-(2-Phenoxy-Ethyl)-3-Thiazol-2-Yl-Thiourea",
      "CHEMBL345996",
      "AC1MHEHQ",
      "BDBM50097040",
      "1-(2-Phenoxyethyl)-3-Thiazol-2-Yl-Thiourea",
      "1-(2-Phenoxyethyl)-3-(2-Thiazolyl)Thiourea",
      "1-(2-Phenoxyethyl)-3-(1,3-Thiazol-2-Yl)Thiourea",
    ],
  },
  {
    primary_id: "D0PJ9Z",
    names: ["MiRNA Targeting Agent"],
  },
  {
    primary_id: "D0PK0R",
    names: ["Piperazine Derivative 2"],
  },
  {
    primary_id: "D0PK2K",
    names: ["ISIS 25125"],
  },
  {
    primary_id: "D0PK3W",
    names: ["Lotilibcin Dermatological Cream"],
  },
  {
    primary_id: "D0PK4M",
    names: ["Isoxazole-Based Bicyclic Compound 18"],
  },
  {
    primary_id: "D0PK5M",
    names: ["MLN0002"],
  },
  {
    primary_id: "D0PK6X",
    names: ["Pyridopyrimidine Derivative 1"],
  },
  {
    primary_id: "D0PK7J",
    names: ["Bis-Triazole Derivative 10", "CHEMBL2016873", "GTPL6537", "SCHEMBL15308834"],
  },
  {
    primary_id: "D0PK8Y",
    names: [
      "BMS-316810",
      "CHEMBL360330",
      "SCHEMBL12961958",
      "BDBM50164025",
      "Pyridine-2-Sulfonic Acid [6-Cyano-1-(3-Methyl-3H-Imidazol-4-Ylmethyl)-1,2,3,4-Tetrahydro-Quinolin-3-Yl]-Thiophen-3-Ylmethyl-Amide",
    ],
  },
  {
    primary_id: "D0PK9V",
    names: ["2,2-Bis(4-Chlorophenyl)-N-Hydroxyacetamide", "CHEMBL572805", "SCHEMBL2848402"],
  },
  {
    primary_id: "D0PL0Y",
    names: ["Furtrethonium"],
  },
  {
    primary_id: "D0PL2Q",
    names: ["Cytoros", "Ara-CDP-DL-PTBA"],
  },
  {
    primary_id: "D0PL3N",
    names: ["Gefapixant"],
  },
  {
    primary_id: "D0PL4F",
    names: ["GW-3965"],
  },
  {
    primary_id: "D0PL5F",
    names: ["OT-4003"],
  },
  {
    primary_id: "D0PL6T",
    names: ["RON Monoclonal Antibody"],
  },
  {
    primary_id: "D0PL7P",
    names: [
      "CYC065",
      "DLPIYBKBHMZCJI-WBVHZDCISA-N",
      "1070790-89-4",
      "CYC-065",
      "UNII-YET2XNU791",
      "YET2XNU791",
      "SCHEMBL13300946",
      "CHEMBL3655762",
      "BDBM106950",
      "CS-7615",
      "HY-101212",
      "US8592581, 1",
      "2-Pentanol, 3-((6-(((4,6-Dimethyl-3-Pyridinyl)Methyl)Amino)-9-(1-Methylethyl)-9H-Purin-2-Yl)Amino)-, (2R,3S)-",
    ],
  },
  {
    primary_id: "D0PL8D",
    names: ["PLX73086"],
  },
  {
    primary_id: "D0PM0Y",
    names: ["Lonsurf"],
  },
  {
    primary_id: "D0PM1B",
    names: ["PMID28766366-Compound-Scheme12-2"],
  },
  {
    primary_id: "D0PM1R",
    names: ["TZ-93"],
  },
  {
    primary_id: "D0PM2S",
    names: ["PMID25399762-Compound-Table 6-14"],
  },
  {
    primary_id: "D0PM3G",
    names: ["BC-RD-03"],
  },
  {
    primary_id: "D0PM3J",
    names: ["Dihydroisoxazole Derivative 1"],
  },
  {
    primary_id: "D0PM4N",
    names: ["Triazolo-Pyrimidinedione Derivative 1"],
  },
  {
    primary_id: "D0PM5R",
    names: [
      "AB-MECA",
      "152918-26-8",
      "(2S,3S,4R,5R)-5-[6-[(4-Aminophenyl)Methylamino]purin-9-Yl]-3,4-Dihydroxy-N-Methyloxolane-2-Carboxamide",
      "AB-MECA, Solid",
      "AC1NSJSB",
      "[3H]AB-MECA",
      "GTPL416",
      "SCHEMBL16585151",
      "CTK8E8476",
      "BDBM21242",
      "DTXSID70415501",
      "LDYMCRRFCMRFKB-MOROJQBDSA-N",
      "MolPort-003-940-100",
      "ZINC4475069",
      "AKOS030526575",
      "CS-5220",
      "NCGC00162075-03",
      "NCGC00162075-02",
      "NCGC00162075-01",
      "HY-19365",
      "RT-011146",
      "N6-(4-Aminobenzyl)-N-Methylcarboxamidoadenosine",
      "J-008959",
      "5'-Methylamino-N-(4-Aminobenzyl)-5'-Oxo-5'-Deoxyadenosine",
    ],
  },
  {
    primary_id: "D0PM5U",
    names: [
      "Epacadostat",
      "1204669-58-8",
      "IDO Inhibitor 1",
      "INCB 024360",
      "Epacadostat(INCB 024360)",
      "1204669-37-3",
      "INCB-024360",
      "UNII-71596A9R13",
      "Epacadostat (INCB024360)",
      "AK174791",
      "71596A9R13",
      "N-(3-Bromo-4-Fluorophenyl)-N'-Hydroxy-4-{[2-(Sulfamoylamino)Ethyl]amino}-1,2,5-Oxadiazole-3-Carboximidamide",
      "BBJ",
      "Epacadostat [USAN:INN]",
      "INCB024360",
      "SCHEMBL145945",
      "Epacadostat (JAN/USAN/INN)",
      "GTPL8221",
      "SCHEMBL18823200",
      "SCHEMBL19808101",
      "CHEMBL3545369",
      "EX-A793",
      "MolPort-044-723-575",
      "MolPort-039-137-699",
      "MolPort-039-193-841",
      "FBKMWOJEPMPVTQ-UHFFFAOYSA",
    ],
  },
  {
    primary_id: "D0PM7Q",
    names: [
      "AZD7762",
      "860352-01-8",
      "AZD-7762",
      "(S)-5-(3-Fluorophenyl)-N-(Piperidin-3-Yl)-3-Ureidothiophene-2-Carboxamide",
      "AZD 7762",
      "UNII-5D822Y3L1H",
      "3-(Carbamoylamino)-5-(3-Fluorophenyl)-N-[(3s)-Piperidin-3-Yl]thiophene-2-Carboxamide",
      "CHEMBL2041933",
      "5D822Y3L1H",
      "J-502468",
      "5-(3-Fluorophenyl)-N-[(3s)-3-Piperidyl]-3-Ureido-Thiophene-2-Carboxamide",
      "IAYGCINLNONXHY-LBPRGKRZSA-N",
      "YDJ",
      "AZD7762 Hydrochloride",
      "5-(3-Fluorophenyl)-3-Ureidothiophene-N-[(S)-Piperidin-3-Yl]-2-Carboxamide",
      "SCHEMBL1127614",
      "GTPL7713",
      "AOB1915",
      "QCR-261",
      "CHEBI:131156",
    ],
  },
  {
    primary_id: "D0PM8B",
    names: ["Intranasal Insulin"],
  },
  {
    primary_id: "D0PM8K",
    names: ["PMID27774824-Compound-Figure7Example10"],
  },
  {
    primary_id: "D0PM9D",
    names: ["Porfiromycin"],
  },
  {
    primary_id: "D0PM9P",
    names: [
      "KB-5246",
      "9,1-Epoxymethano-7-Fluoro-8-(4-Methyl-1-Piperazinyl)-5-Oxo-5H-Thiazolo[3,2-A]quinoline-4-Carboxylic Acid Hydrochloride",
    ],
  },
  {
    primary_id: "D0PN1A",
    names: ["H-Dmt-Tic-Asp-NH-Ph", "CHEMBL501202"],
  },
  {
    primary_id: "D0PN1F",
    names: ["Carbenicillin Indanyl Sodium"],
  },
  {
    primary_id: "D0PN2C",
    names: ["IsoBu-RYYRIK-NH2", "CHEMBL436852"],
  },
  {
    primary_id: "D0PN3M",
    names: ["CD19-Directed CAR-T Cells"],
  },
  {
    primary_id: "D0PN3P",
    names: ["H-Aba-Ala-Gly-Phe-Leu-OH", "CHEMBL247064"],
  },
  {
    primary_id: "D0PN4D",
    names: ["CPC-201"],
  },
  {
    primary_id: "D0PN6C",
    names: ["5-HT 6 Receptor Antagonists"],
  },
  {
    primary_id: "D0PN6U",
    names: ["PMID26815044-Compound-52"],
  },
  {
    primary_id: "D0PN6Z",
    names: [
      "L-AP4",
      "(2S)-2-Amino-4-Phosphonobutanoic Acid",
      "23052-81-5",
      "L-(+)-2-Amino-4-Phosphonobutyric Acid",
      "L-APB",
      "(S)-2-Amino-4-Phosphonobutanoic Acid",
      "CHEMBL33567",
      "L-AP-4",
      "S-2-Amino-4-Phosphonobutyrate",
      "[3H]AP4",
      "L-2-Amino-4-Phosphonobutiric Acid",
      "L-1-Amino-4-Phosphonobutanoic Acid",
      "Tocris-0103",
      "NCIStruc2_000128",
      "NCIStruc1_000162",
      "SCHEMBL1969235",
      "GTPL1410",
      "GTPL1412",
      "AC1L456H",
      "MolPort-002-514-399",
      "NCI30079",
      "ZINC2033983",
      "BN0084",
      "CCG-37553",
      "NCGC00013365",
    ],
  },
  {
    primary_id: "D0PN7H",
    names: ["PMID28074661-Compound-US20120264760C83"],
  },
  {
    primary_id: "D0PN8D",
    names: [
      "1-(7-Methoxy-Naphthalen-2-Yl)-Piperazine",
      "1-(7-Methoxynaphthalen-2-Yl)Piperazine",
      "CHEMBL129661",
      "1174207-86-3",
      "ZINC27313663",
      "BDBM50061300",
      "DA-15050",
    ],
  },
  {
    primary_id: "D0PN8H",
    names: ["Pacritinib"],
  },
  {
    primary_id: "D0PN8L",
    names: ["LIposome Entrapped Paclitaxel"],
  },
  {
    primary_id: "D0PN9E",
    names: ["SK-951"],
  },
  {
    primary_id: "D0PN9R",
    names: ["R-226161"],
  },
  {
    primary_id: "D0PN9S",
    names: ["7-Phenyl-1-(4-Phenyloxazol-2-Yl)Heptan-1-One", "CHEMBL460316", "SCHEMBL2162579"],
  },
  {
    primary_id: "D0PO0B",
    names: [
      "Cilobradine",
      "UNII-5O1LL04GJ3",
      "5O1LL04GJ3",
      "147541-45-5",
      "Cilobradine [INN]",
      "DK-AH269",
      "SCHEMBL628133",
      "GTPL2355",
      "CHEMBL1436376",
      "ZINC13829377",
      "NCGC00165786-01",
    ],
  },
  {
    primary_id: "D0PO0L",
    names: ["Retroviral Vector-Transduced Autologous T Cells To Express CD22-Specific CARs"],
  },
  {
    primary_id: "D0PO0T",
    names: ["BMS-986036"],
  },
  {
    primary_id: "D0PO3M",
    names: ["Imidazopyridine Acetamide Analog 1"],
  },
  {
    primary_id: "D0PO5W",
    names: ["Tissuegene-Cs"],
  },
  {
    primary_id: "D0PO7F",
    names: ["MRL20"],
  },
  {
    primary_id: "D0PO7P",
    names: ["Laxatives Stimulant", "Anthraquinone Laxatives"],
  },
  {
    primary_id: "D0PO8E",
    names: ["L-Methionine", "Acimethin", "Cymethion"],
  },
  {
    primary_id: "D0PO9X",
    names: ["2-Morpholino-6-(Naphthalen-1-Yl)-4H-Pyran-4-One", "CHEMBL225608"],
  },
  {
    primary_id: "D0PP0Z",
    names: ["CAL-202"],
  },
  {
    primary_id: "D0PP1I",
    names: ["ISIS 1822"],
  },
  {
    primary_id: "D0PP1T",
    names: ["AMG 757"],
  },
  {
    primary_id: "D0PP3Q",
    names: ["N-Hydroxy-2,2'-Bithiophene-5-Carboxamide", "CHEMBL217750", "SCHEMBL5903723", "BDBM50198479"],
  },
  {
    primary_id: "D0PP5M",
    names: ["BPH-652"],
  },
  {
    primary_id: "D0PP6A",
    names: ["ABT-165"],
  },
  {
    primary_id: "D0PP6Y",
    names: [
      "PMID23639540C13a",
      "1435615-18-1",
      "GTPL8155",
      "KS-00000UBW",
      "MolPort-044-728-919",
      "ZINC96270778",
      "BDBM50434751",
      "AKOS032946391",
      "AK687595",
    ],
  },
  {
    primary_id: "D0PP7U",
    names: ["Phenylacetyl-Ala,DTrp-Phenthylamide"],
  },
  {
    primary_id: "D0PP8G",
    names: ["ONO-5129", "Dual PPAR Alpha/Gamma Agonist (Metabolic Disorder), Ono"],
  },
  {
    primary_id: "D0PP8O",
    names: ["Imidazo[5,1-C]pyrido[2,3-E][1,2,4]triazine Derivative 6"],
  },
  {
    primary_id: "D0PP8T",
    names: ["Methyl 2-(2-Oxo-8-Phenyloctanamido)Acetate", "CHEMBL488440"],
  },
  {
    primary_id: "D0PQ1U",
    names: ["2-(1-Benzyl-1H-Inden-3-Yl)-N,N-Dimethylethanamine", "CHEMBL457351", "SCHEMBL3320417"],
  },
  {
    primary_id: "D0PQ3G",
    names: [
      "Furosemide",
      "Aisemide",
      "Aldalix",
      "Aldic",
      "Aluzine",
      "Anfuramaide",
      "Aquarid",
      "Aquasin",
      "Arasemide",
      "Beronald",
      "Bioretic",
      "Cetasix",
      "Depix",
      "Desal",
      "Desdemin",
      "Dirine",
      "Disal",
      "Discoid",
      "Disemide",
      "Diural",
      "Diurapid",
      "Diurin",
      "Diurolasa",
      "Diusemide",
      "Diusil",
      "Diuzol",
      "Dranex",
      "Dryptal",
      "Durafurid",
      "Edemid",
      "Edenol",
      "Eliur",
      "Endural",
      "Errolon",
      "Eutensin",
      "FUN",
      "Farsix",
      "Fluidrol",
      "Fluss",
      "Franyl",
      "Frumex",
      "Frumide",
      "Frusedan",
      "Frusema",
      "Frusemid",
      "Frusemide",
      "Frusemin",
      "Frusenex",
      "Frusetic",
      "Frusid",
      "Fulsix",
      "Fuluvamide",
      "Fuluvamine",
      "Fulvamide",
      "Furanthril",
      "Furanthryl",
      "Furantral",
      "Furantril",
      "Furanturil",
      "Furesis",
      "Furetic",
      "Furex",
      "Furfan",
      "Furix",
      "Furmid",
      "Furobeta",
      "Furocot",
      "Furodiurol",
      "Furodrix",
      "Furomen",
      "Furomex",
      "Furorese",
      "Furosan",
      "Furose",
      "Furosedon",
      "Furosemid",
      "Furosemida",
      "Furosemidu",
      "Furosemidum",
      "Furosemix",
      "Furoside",
      "Furosifar",
      "Furosix",
      "Furoter",
      "Furovite",
      "Fursemid",
      "Fursemida",
      "Fursemide",
      "Fursol",
      "Fusid",
      "Golan",
      "Hissuflux",
      "Hydrex",
      "Hydro",
      "Hydroled",
      "Impugan",
      "Jenafusid",
      "Katlex",
      "Kofuzon",
      "Kolkin",
      "Kutrix",
      "Lasemid",
      "Lasex",
      "Lasiletten",
      "Lasilix",
      "Lasix",
      "Laxur",
      "Lazix",
      "Liside",
      "Logirene",
      "Lowpston",
      "Lowpstron",
      "Luscek",
      "Macasirool",
      "Marsemide",
      "Mirfat",
      "Mita",
      "Moilarorin",
      "Myrosemide",
      "Nadis",
      "Nelsix",
      "Novosemide",
      "Odemase",
      "Odemex",
      "Oedemex",
      "Prefemin",
      "Profemin",
      "Promedes",
      "Promide",
      "Protargen",
      "Puresis",
      "Radisemide",
      "Radonna",
      "Radouna",
      "Retep",
      "Rosemide",
      "Rosis",
      "Rusyde",
      "Salinex",
      "Salix",
      "Salurex",
      "Salurid",
      "Seguril",
      "Selectofur",
      "Sigasalur",
      "Spirofur",
      "Synephron",
      "Transit",
      "Trofurit",
      "Uremide",
      "Uresix",
      "Urian",
      "Uridon",
      "Uritol",
      "Urosemide",
      "Vesix",
      "Yidoli",
      "Zafimida",
      "FUROSEMIDE USP",
      "Fu Sid",
      "Furosemide Monohydrochloride",
      "Furosemide Monosodium Salt",
      "Furosemidu [Polish]",
      "Lasix Retard",
      "Lasix Special",
      "Less Diur",
      "Polysquall A",
      "Sal Diureticum",
      "F0182",
      "F4381_SIGMA",
      "LB 502",
      "Aisemide (TN)",
      "Apo-Frusemide",
      "Apo-Furosemide",
      "Beronald (TN)",
      "Desdemin (TN)",
      "Discoid (TN)",
      "Diumide-K",
      "Diural (TN)",
      "Diurapid (TN)",
      "Dryptal (TN)",
      "Durafurid (TN)",
      "Errolon (TN)",
      "Eutensin (TN)",
      "Frudix (TN)",
      "Frusetic (TN)",
      "Frusid (TN)",
      "Fulsix (TN)",
      "Fuluvamide (TN)",
      "Furesis (TN)",
      "Furix (TN)",
      "Furo-Basan",
      "Furo-Puren",
      "Furomide M.D",
      "Furosedon (TN)",
      "Furosemida [INN-Spanish]",
      "Furosemide (Mita)",
      "Furosemidum [INN-Latin]",
      "Hoe-058A",
      "Hydro-Rapid",
      "Impugan (TN)",
      "Katlex (TN)",
      "LB-502",
      "Lasilix (TN)",
      "Lasix (TN)",
      "Lodix (TN)",
      "Lowpston (TN)",
      "Macasirool (TN)",
      "Mirfat (TN)",
      "Neo-Renal",
      "Nicorol (TN)",
      "Odemase (TN)",
      "Oedemex (TN)",
      "Profemin (TN)",
      "Rosemide (TN)",
      "Rusyde (TN)",
      "Salix (TN)",
      "Salix (Brand Of Furosemide)",
      "Trofurit (TN)",
      "Urex (TN)",
      "Urex-M",
      "Apo-Furosemide (TN)",
      "Furo-Puren (TN)",
      "Furomide M.D.",
      "Furosemide [USAN:INN:JAN]",
      "Hydro-Rapid(TN)",
      "Lasix, Frusemide, Furosemide",
      "Furosemide (JP15/USP/INN)",
      "Chlor-N-(2-Furylmethyl)-5-Sulfamylanthranilsaeure",
      "Chlor-N-(2-Furylmethyl)-5-Sulfamylanthranilsaeure [German]",
      "2-Furfurylamino-4-Chloro-5-Sulfamoylbenzoic Acid",
      "4-Chloro-5-Sulfamoyl-N-Furfuryl-Anthranilic Acid",
      "4-Chloro-N-(2-Furylmethyl)-5-Sulfamoylanthranilic Acid",
      "4-Chloro-N-Furfuryl-5-Sulfamoylanthranilic Acid",
      "4-Chloro-2-(Furan-2-Ylmethylamino)-5-Sulfamoylbenzoic Acid",
      "4-Chloro-2-[(Furan-2-Ylmethyl)Amino]-5-Sulfamoylbenzoic Acid",
    ],
  },
  {
    primary_id: "D0PQ3Q",
    names: ["4-(4-Chlorophenyl)-5-(4-Methoxyphenyl)Isothiazole", "CHEMBL522624"],
  },
  {
    primary_id: "D0PQ3V",
    names: ["SYN-1134", "Elastase Inhibitors, NAEJA", "SYN-1360", "SYN-1386", "SYN-1389", "SYN-1390", "SYN-1396"],
  },
  {
    primary_id: "D0PQ5R",
    names: ["P-Tolyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL481163", "SCHEMBL4449333"],
  },
  {
    primary_id: "D0PQ5T",
    names: [
      "Azelaic Bishydroxamic Acid",
      "18992-11-5",
      "Azelaic Bis(Hydroxamic Acid)",
      "Nonanedihydroximic Acid",
      "N,N'-Dihydroxynonanediamide",
      "Azelaic Bis Hydroxamic Acid",
      "AC1L22XV",
      "SCHEMBL4741072",
      "CHEMBL495390",
      "Nonanediamide, N,N'-Dihydroxy-",
      "DTXSID30172421",
      "ZINC5178518",
      "NSC611775",
    ],
  },
  {
    primary_id: "D0PQ7A",
    names: ["DprwFwLL-NH2", "CHEMBL507636"],
  },
  {
    primary_id: "D0PQ8B",
    names: ["3-Decanoyl-4-Hydroxy-6-Nonyl-Pyran-2-One", "CHEMBL7958", "SCHEMBL10337595"],
  },
  {
    primary_id: "D0PQ8T",
    names: ["SB-436811"],
  },
  {
    primary_id: "D0PR2Q",
    names: ["Pr-RYYRIK-NH2", "CHEMBL403807"],
  },
  {
    primary_id: "D0PR2T",
    names: ["DI-O-METHYLENDIANDRIN A", "Di-O-Methylendiandrin A", "CHEMBL388363"],
  },
  {
    primary_id: "D0PR4O",
    names: ["Ilixadencel"],
  },
  {
    primary_id: "D0PR5H",
    names: ["BIS-001"],
  },
  {
    primary_id: "D0PR6H",
    names: ["PENCLOMEDINE"],
  },
  {
    primary_id: "D0PR7L",
    names: [
      "4-Fluorobenzenemethanethiol",
      "4-Fluorobenzyl Mercaptan",
      "15894-04-9",
      "(4-Fluorophenyl)Methanethiol",
      "4-Fluoro Benzyl Mercaptan",
      "4-Fluorobenzylmercaptan",
      "P-Fluorotoluene-Alpha-Thiol",
      "4-Fluorophenylmethanethiol",
      "4-Fluoro-Alpha-Toluenethiol",
      "Benzenemethanethiol, 4-Fluoro-",
      "(4-Fluoro-Phenyl)-Methanethiol",
      "CHEMBL1224558",
      "4-Fluorobenzyl Mercaptan, 96%",
      "RKTRHMNWVZRZJQ-UHFFFAOYSA-N",
      "4-Fluorobenzylthiol",
      "EINECS 240-031-9",
      "4-Fluorobenzyl Thiol",
      "PubChem6855",
      "4-Fluorobenzylmercaptane",
      "AC1Q4NST",
      "P-Fluorobenzenemethanethiol",
      "ACMC-209dj5",
    ],
  },
  {
    primary_id: "D0PR9E",
    names: ["KMI-596"],
  },
  {
    primary_id: "D0PR9I",
    names: ["Lerdelimumab"],
  },
  {
    primary_id: "D0PS0J",
    names: ["PMID28350212-Compound-16"],
  },
  {
    primary_id: "D0PS2W",
    names: ["TILNOPROFEN ARBAMEL"],
  },
  {
    primary_id: "D0PS2X",
    names: ["GEM-ALS", "Amyotrophic Lateral Sclerosis Therapy (Conjugated Protein), Gemac"],
  },
  {
    primary_id: "D0PS4C",
    names: ["ANGX-1039"],
  },
  {
    primary_id: "D0PS6X",
    names: ["Mega-3-Acid Ethyl Esters"],
  },
  {
    primary_id: "D0PS7U",
    names: ["GE-137"],
  },
  {
    primary_id: "D0PT0U",
    names: ["PMID25666693-Compound-112"],
  },
  {
    primary_id: "D0PT1K",
    names: ["2-(4-Chlorophenyl)-2H-Indazole-7-Carboxamide", "CHEMBL1099295", "SCHEMBL2264064"],
  },
  {
    primary_id: "D0PT1W",
    names: ["PMID26077642-Compound-Vif1"],
  },
  {
    primary_id: "D0PT1Y",
    names: ["SAR-161271"],
  },
  {
    primary_id: "D0PT2D",
    names: ["MR-22388"],
  },
  {
    primary_id: "D0PT2M",
    names: ["EGFRvIII-CARs"],
  },
  {
    primary_id: "D0PT2W",
    names: ["JNJ-63709178"],
  },
  {
    primary_id: "D0PT5D",
    names: ["Anti-Mesothelin CAR T Cells"],
  },
  {
    primary_id: "D0PT6W",
    names: ["PIN-2"],
  },
  {
    primary_id: "D0PT8O",
    names: [
      "AcAsp-Glu-Dif-Lys-Cha-Cys",
      "CHEMBL294440",
      "AC1LAAJ1",
      "BDBM50096413",
      "AcAsp-Glu-Dif(3,3-Diphenylalanine)-Lys-Cha-(Beta-Cyclohexylalanine)-Cys",
      "Ac-L-Asp-L-Glu-3,3-Diphenyl-L-Ala-L-Lys-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "(4S)-4-[[(2S)-2-Acetamido-4-Hydroxy-4-Oxobutanoyl]amino]-5-[[(2S)-1-[[(2S)-6-Amino-1-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-1-Oxohexan-2-Yl]amino]-1-Oxo-3,3-Diphenylpropan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D0PT8T",
    names: ["PMID27977313-Compound-29"],
  },
  {
    primary_id: "D0PT9G",
    names: ["INT230-6"],
  },
  {
    primary_id: "D0PU1J",
    names: [
      "BW B218C",
      "BW-B218C",
      "ZLIVOFMPDUIULQ-VAWYXSNFSA-N",
      "134470-36-3",
      "AC1O5RJN",
      "SCHEMBL6008832",
      "N-(1-Methyl-3-(3-Phenoxyphenyl)Prop-2-Enyl)Acetohydroxamic Acid",
      "N-Hydroxy-N-[(E)-4-(3-Phenoxyphenyl)But-3-En-2-Yl]acetamide",
      "Acetamide, N-Hydroxy-N-(1-Methyl-3-(3-Phenoxyphenyl)-2-Propenyl)-",
      "(+)-(E)-N-[1-Methyl-3-(3-Phenoxyphenyl)Prop-2-Enyl]acetohydroxamic Acid",
      "N-[(E)-3-(3-Phenoxyphenyl)-1-Methyl-2-Propenyl]acetohydroxamic Acid",
    ],
  },
  {
    primary_id: "D0PU1L",
    names: ["PMID27336223-Compound-7"],
  },
  {
    primary_id: "D0PU1O",
    names: ["GRN-510"],
  },
  {
    primary_id: "D0PU1R",
    names: [
      "2-Methyl-7-Phenyl-1,8-Naphthyridine",
      "CHEMBL97344",
      "BDBM50090686",
      "7-Methyl-2-Phenyl-1,8-Naphthyridine",
      "2-Methyl-7-Phenyl-[1,8]naphthyridine",
    ],
  },
  {
    primary_id: "D0PU1W",
    names: ["MKC-180"],
  },
  {
    primary_id: "D0PU2A",
    names: ["Boronic Acid Derivative 3"],
  },
  {
    primary_id: "D0PU5L",
    names: [
      "Adaprolol Maleate-SME",
      "Adaprolol",
      "Adaprolol Maleate",
      "CDDD-2803",
      "HGP-2",
      "Submicron Emulsion, Pharmos",
      "Adaprolol Maleate-SME, Pharmos",
    ],
  },
  {
    primary_id: "D0PU5V",
    names: [
      "7-((1H-Imidazol-1-Yl)Methyl)Isoquinoline",
      "CHEMBL298720",
      "Isoquinoline 56",
      "BDBM10041",
      "ZINC13808228",
      "7-[(Imidazol-1-Yl)Methyl]isoquinoline",
      "7-(1H-Imidazol-1-Ylmethyl)Isoquinoline",
    ],
  },
  {
    primary_id: "D0PU6F",
    names: [
      "2-(1H-Benzo[d]imidazol-2-Yl)Quinoxaline",
      "CHEMBL200636",
      "Benzimidazolylquinoxaline",
      "Benzimidazole-Quinoxaline, C1",
      "BDBM21215",
      "2-(1H-Benzimidazol-2-Yl)Quinoxaline",
      "2-(1H-Benzoimidazole-2-Yl)Quinoxaline",
    ],
  },
  {
    primary_id: "D0PU8T",
    names: ["E5555", "Atopaxar"],
  },
  {
    primary_id: "D0PU8Z",
    names: ["Dacetuzumab"],
  },
  {
    primary_id: "D0PU9C",
    names: ["TTP-448"],
  },
  {
    primary_id: "D0PV0I",
    names: ["AKCEA-APO(A)-LRX"],
  },
  {
    primary_id: "D0PV1U",
    names: ["FP-0210"],
  },
  {
    primary_id: "D0PV3Y",
    names: [
      "ME-1036",
      "CP-5609",
      "Carbapenem Antibacterials, Cerexa",
      "Carbapenem Antibacterials, Meiji",
      "Carbapenem Antibacterials, Forest/ Meiji",
    ],
  },
  {
    primary_id: "D0PV4H",
    names: ["Legumain-Cleavable Doxorubicin Prodrugs"],
  },
  {
    primary_id: "D0PV6G",
    names: ["(+)-S-14297", "(+)S14297"],
  },
  {
    primary_id: "D0PV9N",
    names: [
      "GC4419",
      "Avasopasem Manganese",
      "UNII-EY1WA413UL",
      "EY1WA413UL",
      "Avasopasem Manganese [USAN]",
      "SC-72325A",
      "M-40419",
      "435327-40-5",
      "Manganese, Dichloro((4aS,13aS,17aS,21aS)-1,2,3,4,4a,5,6,12,13,13a,14,15,16,17,17a,18,19,20,21,21a-Eicosahydro-7,11-Nitrilo-7H-Dibenzo(B,H)-5,13,18,21-Tetraazacycloheptadecine-KappaN5,kappaN13,kappaN18,kappaN21,kappaN22)-, (Pb-7-11-2344'3')-",
    ],
  },
  {
    primary_id: "D0PW0G",
    names: [
      "Valategrast",
      "220847-86-9",
      "Valategrast (R411)",
      "UNII-06DM4KX7JG",
      "06DM4KX7JG",
      "Valategrast [INN]",
      "SCHEMBL480224",
      "CHEMBL2107792",
      "ZINC72190226",
      "KB-81423",
      "2-(Diethylamino)Ethyl N-(2-Chloro-6-Methylbenzoyl)-4-(2,6-Dichlorobenzamido)-L-Phenylalaninate",
    ],
  },
  {
    primary_id: "D0PW4C",
    names: [
      "Niravoline",
      "AC1L24CB",
      "AC1Q1ZY1",
      "N-Methyl-2-(3-Nitrophenyl)-N-[(2S)-2-Pyrrolidin-1-Yl-2,3-Dihydro-1H-Inden-1-Yl]acetamide",
    ],
  },
  {
    primary_id: "D0PW4M",
    names: ["KNI-10741"],
  },
  {
    primary_id: "D0PW4N",
    names: ["Ispinesib"],
  },
  {
    primary_id: "D0PW7B",
    names: ["DIDS", "4,4'-Diisothiocyanostilbene-2,2'-Disulphonic Acid"],
  },
  {
    primary_id: "D0PW7C",
    names: [
      "Cefaclor",
      "Alenfral",
      "Alfacet",
      "Alfatil",
      "CCL",
      "Ceclor",
      "Cefaclorum",
      "Distaclor",
      "Kefolor",
      "Panacef",
      "Panoral",
      "Alfatil Kapseln",
      "Cefaclor Anhydrous",
      "Cefaclor Hydrate",
      "Cefaclor Monohydrate",
      "Dystaclor MR",
      "Kefolor Suspension",
      "Muco Panoral",
      "Lilly 99638 Hydrate",
      "Alenfral (TN)",
      "Ceclor (TN)",
      "Cefaclor (JP15)",
      "Cefaclor (USP)",
      "Distaclor (TN)",
      "Keflor (TN)",
      "L-Kefral",
      "Raniclor (TN)",
      "S-6472",
      "Cefaclor-1-Wasser",
      "Cefaclor [USAN:INN:BAN:JAN]",
      "Ceclor, Distaclor, Keflor, Raniclor, Cefaclor",
      "(6R,7R)-7-((R)-2-Amino-2-Phenylacetamido)-3-Chloro-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid Monohydrate",
      "(6R,7R)-7-[[(2R)-2-Amino-2-Phenylacetyl]amino]-3-Chloro-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2R)-2-Amino-2-Phenylacetyl]amino]-3-Chloro-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid Hydrate",
      "(6R,7R)-7-{[(2R)-2-Amino-2-Phenylacetyl]amino}-3-Chloro-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "3-Chloro-7-D-(2-Phenylglycinamido)-3-Cephem-4-Carboxylic Acid",
      "3-Chloro-7-D-(2-Phenylglycinamido)-3-Cephem-4-Carboxylic Acid Monohydrate",
      "7-(2-Amino-2-Phenyl-Acetylamino)-3-Chloro-8-Oxo-5-Thia-1-Aza-Bicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-{[(2R)-2-Amino-2-Phenylacetyl]amino}-3-Chloro-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0PX0N",
    names: ["TF0023"],
  },
  {
    primary_id: "D0PX3G",
    names: [
      "[3H]SNAP-7941",
      "387825-78-7",
      "GTPL1318",
      "ZINC65731246",
      "KB-80596",
      "Methyl (6S)-6-(3,4-Difluorophenyl)-4-(Methoxymethyl)-2-Oxo-1-[3-[4-[3-(Propanoylamino)Phenyl]piperidin-1-Yl]propylcarbamoyl]-3,6-Dihydropyrimidine-5-Carboxylate",
    ],
  },
  {
    primary_id: "D0PX5F",
    names: ["NU-1001-41", "Anti-Phospho-AKT Monoclonal Antibodies (Cancer), Nuclea Biotechnologies"],
  },
  {
    primary_id: "D0PX7G",
    names: ["PMID30247903-Compound-General Structure42"],
  },
  {
    primary_id: "D0PX7X",
    names: ["Re-188-P-2045"],
  },
  {
    primary_id: "D0PX8I",
    names: ["TTP435"],
  },
  {
    primary_id: "D0PX8L",
    names: [
      "Clothiapine",
      "Clotiapine",
      "2058-52-8",
      "Entumine",
      "Entumin",
      "Etumine",
      "HF 2159",
      "Clotiapinum [INN-Latin]",
      "Clotiapina [INN-Spanish]",
      "UNII-Z05HCY0X1T",
      "S-805C",
      "Clothiapine [USAN]",
      "EINECS 218-162-8",
      "LW 2159",
      "C18H18ClN3S",
      "BRN 0568276",
      "Z05HCY0X1T",
      "CHEMBL304902",
      "2-Chloro-11-(4-Methylpiperazino)Dibenzo(B,f)(1,4)Thiazepine",
      "KAAZGXDPUNNEFN-UHFFFAOYSA-N",
      "2-Chloro-11-(4-Methyl-1-Piperazinyl)Dibenzo(B,f)(1,4)Thiazepine",
      "DIBENZO(B,f)(1,4)THIAZEPINE, 2-CHLORO-11-(4-METHYL-1-PIPERAZINYL)-",
      "Clothiapine (USAN)",
      "HF-2159",
      "NCGC00160387-01",
    ],
  },
  {
    primary_id: "D0PY0G",
    names: ["PMID28270021-Compound-WO2010077680 201"],
  },
  {
    primary_id: "D0PY0H",
    names: ["Azepan-4-One Derivative 2"],
  },
  {
    primary_id: "D0PY0W",
    names: ["PRAME Antigen-Specific Cancer"],
  },
  {
    primary_id: "D0PY1G",
    names: ["Hydrazide Derivative 3"],
  },
  {
    primary_id: "D0PY3L",
    names: ["MEDI-565"],
  },
  {
    primary_id: "D0PY4Z",
    names: ["ISIS 29219"],
  },
  {
    primary_id: "D0PY5D",
    names: ["Elacytarabine", "Elacyt (TN)"],
  },
  {
    primary_id: "D0PY5G",
    names: [
      "Glycopeptide",
      "AC1L96ZE",
      "4468-EP2305808A1",
      "(2R)-2-[[(2S)-2-[[(4R)-4-[[(2S)-2-[2-[(3R,4R,5S,6R)-5-[(2S,3R,4R,5S,6R)-3-Acetamido-4,5-Dihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxy-3-(Ethylamino)-2-Hydroxy-6-(Hydroxymethyl)Oxan-4-Yl]oxypropanoylamino]propanoyl]amino]-5-Amino-5-Oxopentanoyl]amino]-6-Aminohexanoyl]amino]propanoic Acid",
    ],
  },
  {
    primary_id: "D0PY5I",
    names: ["IC9-CAR19 T Cells"],
  },
  {
    primary_id: "D0PY5R",
    names: ["MDA-106"],
  },
  {
    primary_id: "D0PY7O",
    names: [
      "WTLNSAGYLL-CONH2",
      "AR-M 1896",
      "367518-31-8",
      "CHEMBL578710",
      "Galanin-2-11-NH..2..",
      "Gal(2-11)Amide",
      "Galanin(2-11)",
      "AC1O44IJ",
      "Galanin(2-11)Porcine,rat",
      "BDBM50307252",
      "AKOS024457247",
      "GALANIN (2-11) (PORCINE, RAT)",
      "Galanin (2-11) Porcine, Rat, &gt;=97% (HPLC), Lyophilized Powder",
    ],
  },
  {
    primary_id: "D0PY7Z",
    names: ["Aflunov", "Aflunov (TN)"],
  },
  {
    primary_id: "D0PZ4Y",
    names: ["PF-3557156"],
  },
  {
    primary_id: "D0PZ5R",
    names: ["BVS-212"],
  },
  {
    primary_id: "D0PZ9R",
    names: ["WAY-132983"],
  },
  {
    primary_id: "D0Q0AJ",
    names: ["SM-8849"],
  },
  {
    primary_id: "D0Q0AP",
    names: [
      "BMS-582949",
      "623152-17-0",
      "BMS 582949",
      "UNII-CR743OME9E",
      "BMS582949",
      "CR743OME9E",
      "PS540446",
      "CHEMBL1230065",
      "PS-540446",
      "4-{[5-(Cyclopropylcarbamoyl)-2-Methylphenyl]amino}-5-Methyl-N-Propylpyrrolo[2,1-F][1,2,4]triazine-6-Carboxamide",
      "3mvl",
      "SCHEMBL254996",
      "GTPL7838",
      "DTXSID90211380",
      "MolPort-044-560-326",
      "EX-A1265",
      "BCP14356",
      "ZINC36475284",
      "S8124",
      "BDBM50327009",
      "AKOS030573299",
      "DB12696",
      "Pyrrolo(2,1-F)(1,2,4)Triazine-6-Carboxamide, 4-((5-((Cyclopropylamino)Carbonyl)-2-Methylphenyl)Amino)-5-Methyl-N-Propyl-",
      "BMS582949 Free Base",
      "PS 540446",
    ],
  },
  {
    primary_id: "D0Q0BK",
    names: ["3-Fluoro-4'-(Pyridin-4-Ylmethyl)Biphenyl-4-Ol", "CHEMBL1173260", "SCHEMBL18770351", "BDBM50322791"],
  },
  {
    primary_id: "D0Q0BL",
    names: ["MiRNA-Based Therapeutics"],
  },
  {
    primary_id: "D0Q0BV",
    names: ["Naphthalen-2-Yl 10H-Phenothiazine-10-Carboxylate", "CHEMBL518302"],
  },
  {
    primary_id: "D0Q0CR",
    names: ["Mitoxantrone Liposomal"],
  },
  {
    primary_id: "D0Q0EB",
    names: ["ICI-198583", "112887-62-4", "CB-3819"],
  },
  {
    primary_id: "D0Q0EF",
    names: ["ITI-007"],
  },
  {
    primary_id: "D0Q0EK",
    names: [
      "4-Hydrazinocarbonyl-Benzenesulfonamide",
      "4-(Hydrazinecarbonyl)Benzenesulfonamide",
      "35264-29-0",
      "4-(Hydrazinecarbonyl)Benzene-1-Sulfonamide",
      "JIDAHYHCQJXNTD-UHFFFAOYSA-N",
      "4-(Hydrazinylcarbonyl)Benzenesulfonamide",
      "4-???benzenesulfonamide",
      "4-HYDRAZINOCARBONYL-BENZENE-SULFONAMIDE",
      "Hydrazide 2",
      "P-Sulfamylbenzoic Acid Hydrazide",
      "AC1L7XIP",
      "CHEMBL180323",
      "SCHEMBL4616920",
      "BDBM12136",
      "CTK4H4135",
      "DTXSID60312358",
      "4-Sulfamoylbenzoic Acid Hydrazide",
      "MolPort-000-887-192",
      "ZINC1556993",
      "STK349231",
      "SBB021016",
      "4376AB",
      "NSC252930",
      "AKOS000305291",
      "MCULE-5832659214",
    ],
  },
  {
    primary_id: "D0Q0EX",
    names: [
      "Lincomycin",
      "Cillimycin",
      "Epilincomycin",
      "Jiemycin",
      "LCM",
      "Lincocin",
      "Lincocine",
      "Lincolcina",
      "Lincolnensin",
      "Lincomicina",
      "Lincomix",
      "Lincomycine",
      "Lincomycinum",
      "Lincomyocin",
      "Lincorex",
      "Mycivin",
      "Lincomycin A",
      "Lincomycine [French]",
      "CBMicro_021584",
      "Lincomix 20",
      "Pura Ject 100",
      "Lincocin (TN)",
      "Lincomicina [INN-Spanish]",
      "Lincomycine [INN-French]",
      "Lincomycinum [INN-Latin]",
      "U 10,149A",
      "Lincomycin (USAN/INN)",
      "Lincomycin [USAN:INN:BAN]",
      "Lincomycin, (2S-Cis)-Isomer",
      "Methyl 6,8-Dideoxy-6-[(1-Methyl-4-Propylprolyl)Amino]-1-Thiooctopyranoside",
    ],
  },
  {
    primary_id: "D0Q0EY",
    names: [
      "(S)-2-Amino-4-Methyl-Pentane-1-Thiol",
      "CHEMBL448735",
      "88264-65-7",
      "Leucinthiol",
      "2-Amino-4-Methyl-Pentane-1-Thiol",
      "L-LEUCINETHIOL",
      "AC1L3ELJ",
      "1-Pentanethiol, 2-Amino-4-Methyl-, (2S)-",
      "SCHEMBL2002339",
      "GXEDNWSUKCJLLB-LURJTMIESA-N",
      "ZINC403014",
      "BDBM50078133",
      "AKOS006342069",
      "(S)-2-Amino-4-Methyl-1-Pentanethiol",
      "(S)-2-Amino-4-Methylpentane-1-Thiol",
      "(2S)-2-Amino-4-Methylpentane-1-Thiol",
      "1-Pentanethiol, 2-Amino-4-Methyl-, (S)-",
      "L-Leucinethiol, Oxidized Dihydrochloride, Leucine Aminopeptidase Inhibitor",
    ],
  },
  {
    primary_id: "D0Q0FW",
    names: ["N1-Methylnicotinamide"],
  },
  {
    primary_id: "D0Q0FZ",
    names: ["ISIS 29139"],
  },
  {
    primary_id: "D0Q0GY",
    names: ["IW-1221", "CRTH2 Inhibitor (Asthma), Ironwood"],
  },
  {
    primary_id: "D0Q0HE",
    names: ["Ac-I[CVTQDWGHHRC]T-NH2"],
  },
  {
    primary_id: "D0Q0HR",
    names: ["Y-931"],
  },
  {
    primary_id: "D0Q0HZ",
    names: ["Elpamotide"],
  },
  {
    primary_id: "D0Q0IE",
    names: [
      "PT-110",
      "181305-20-4",
      "Thiourea, N-(5-Chloro-2-Pyridinyl)-N'-(2-(3-Cyano-2,6-Difluorophenyl)Ethyl)-",
      "N-(2-(3-Cyano-2,6-Difluorophenethyl))-N'-(2-(5-Chloropyridyl))Thiourea",
      "1-(5-Chloropyridin-2-Yl)-3-[2-(3-Cyano-2,6-Difluorophenyl)Ethyl]thiourea",
      "N-[2-(3-Cyano-2,6-Difluorophenethyl)]-N'-[2-(5-Chloropyridyl)]thiourea",
      "Thiourea, N-(5-Chloro-2-Pyridinyl)-N'-[2-(3-Cyano-2,6-Difluorophenyl)Ethyl]-",
      "AC1MHDLU",
      "LY300046HCl Analog 4",
      "CHEMBL319224",
      "BDBM2120",
      "DTXSID20171100",
    ],
  },
  {
    primary_id: "D0Q0IM",
    names: ["SNA-125"],
  },
  {
    primary_id: "D0Q0IN",
    names: ["ISIS 104253"],
  },
  {
    primary_id: "D0Q0IR",
    names: ["AGN193109", "AGN 193109", "AGN-93109"],
  },
  {
    primary_id: "D0Q0IX",
    names: ["BIOD-531"],
  },
  {
    primary_id: "D0Q0JG",
    names: ["Ispaghula"],
  },
  {
    primary_id: "D0Q0JI",
    names: ["ASP-4058"],
  },
  {
    primary_id: "D0Q0JJ",
    names: ["Streptococcal B Vaccine"],
  },
  {
    primary_id: "D0Q0KD",
    names: ["1-Propyl-3-O-Tolyl-Piperidine Hydrochloride"],
  },
  {
    primary_id: "D0Q0KV",
    names: ["RNAi Therapeutics, GeneSegues Inc"],
  },
  {
    primary_id: "D0Q0KX",
    names: ["Fluorapacin"],
  },
  {
    primary_id: "D0Q0LD",
    names: ["NEODYSIDENIN"],
  },
  {
    primary_id: "D0Q0LF",
    names: ["Vitatropin"],
  },
  {
    primary_id: "D0Q0LH",
    names: ["S24014"],
  },
  {
    primary_id: "D0Q0LI",
    names: ["SER-109"],
  },
  {
    primary_id: "D0Q0MH",
    names: ["CM-101"],
  },
  {
    primary_id: "D0Q0MI",
    names: ["Zn-DPA-I", "Bacterial Infection Imaging Agent, Molecular Targeting Technology", "DPA-Tag"],
  },
  {
    primary_id: "D0Q0MM",
    names: ["L-NG-Nitroarginine Methyl Ester"],
  },
  {
    primary_id: "D0Q0MN",
    names: ["Threo-3,4-Dichlororitalinol Hydrochloride"],
  },
  {
    primary_id: "D0Q0OD",
    names: ["Secukinumab"],
  },
  {
    primary_id: "D0Q0OH",
    names: [
      "Alpha-D-Mannose-6-Phosphate",
      "6-H2PO3Manalpha",
      "Alpha-D-Mannose 6-Phosphate",
      "(6P)Manalpha",
      "Man6P",
      "(6P)Mana",
      "6-O-Phosphono-Alpha-D-Mannopyranose",
      "UNII-C77UQ006XD",
      "C77UQ006XD",
      "CHEBI:43896",
      "Mannose 6-Phosphate",
      "M6P",
      "1syo",
      "1m6p",
      "1sz0",
      "AC1L9KGE",
      "SCHEMBL742653",
      "CHEMBL402001",
      "NBSCHQHZLSJFNQ-PQMKYFCFSA-N",
      "ZINC4096188",
      "DB02900",
      "Alpha-D-Mannopyranose, 6-(Dihydrogen Phosphate)",
      "WURCS=2.0/1,1,0/[a1122h-1a_1-5_6*OPO/3O/3=O]/1/",
      "[(2R,3S,4S,5S,6S)-3,4,5,6-Tetrahydroxyoxan-2-Yl]methyl Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0Q0OP",
    names: ["CAB-2"],
  },
  {
    primary_id: "D0Q0OR",
    names: ["SSR126768A"],
  },
  {
    primary_id: "D0Q0OY",
    names: ["213-Bi DOTA-PESIN"],
  },
  {
    primary_id: "D0Q0PN",
    names: ["PMID19309152C2g"],
  },
  {
    primary_id: "D0Q0PO",
    names: ["LY2780301"],
  },
  {
    primary_id: "D0Q0PR",
    names: [
      "Novobiocin",
      "Albamix",
      "Albamycin",
      "Cardelmycin",
      "Cathocin",
      "Cathomycin",
      "Inamycin",
      "NOV",
      "Novobiocina",
      "Novobiocine",
      "Novobiocinum",
      "Robiocina",
      "Sirbiocina",
      "Spheromycin",
      "Stilbiocina",
      "Streptonivicin",
      "Crystallinic Acid",
      "Novobiocin Sodium Salt",
      "PA 93",
      "U 6391",
      "Albamycin (TN)",
      "Antibiotic PA-93",
      "Novo-R",
      "Novobiocin [INN:BAN]",
      "Novobiocina [INN-Spanish]",
      "Novobiocine [INN-French]",
      "Novobiocinum [INN-Latin]",
      "Streptonivicin (*Sodium Salt*)",
      "[(3R,4S,5R,6R)-5-Hydroxy-6-[2-Hydroxy-3-[[4-Hydroxy-3-(3-Methylbut-2-Enyl)Benzoyl]amino]-8-Methyl-4-Oxochromen-7-Yl]oxy-3-Methoxy-2,2-Dimethyloxan-4-Yl] Carbamate",
      "N-[7-[[3-O-(Aminocarbonyl)-6-Deoxy-5-C-Methyl-4-O-Methyl-.beta.-L-Lyxo-Hexopyranosyl]oxy]-4-Hydroxy-8-Methyl-2-Oxo-2H-1-Benzopyran-3-Yl]-4-Hydroxy-3-(3-Methyl-2-Butenyl)",
      "(3R,4S,5R,6R)-5-Hydroxy-6-{[4-Hydroxy-3-({[4-Hydroxy-3-(3-Methylbut-2-En-1-Yl)Phenyl]carbonyl}amino)-8-Methyl-2-Oxo-2H-Chromen-7-Yl]oxy}-3-Methoxy-2,2-Dimethyltetrahydro-2H-Pyran-4-Yl Carbamate",
      "(3r,4s,5r,6r)-5-Hydroxy-6-[(2-Hydroxy-3-{[4-Hydroxy-3-(3-Methylbut-2-En-1-Yl)Benzoyl]amino}-8-Methyl-4-Oxo-4h-Chromen-7-Yl)Oxy]-3-Methoxy-2,2-Dimethyltetrahydro-2h-Pyran-4-Yl Carbamate(Non-Preferred Name)",
      "7-(3-(O-Carbamoyl)-4-(O-Methyl)-5,5-Dimethyl-Alpha-L-Lyxopyranosyloxy)-4-Hydroxy-3-(4-Hydroxy-3-(3-Methyl-2-Butenyl)Benzamidol)-8-Methylcumarin",
      "7-(Carbamoyltetrahydro-3-Hydroxy-5-Methoxy-6,6-Dimethylpyran-2-Yloxy)-4-Hydroxy-3-(4-Hydroxy-3-(3-Methylbut-2-Enyl)Benzamide)-8-Methyl-2H-Chromen-2-One",
    ],
  },
  {
    primary_id: "D0Q0RC",
    names: [
      "Ethopropazine",
      "Aethopropropazin",
      "Athapropazine",
      "Athopropazin",
      "Ethapropazine",
      "Ethopromazine",
      "Etopropezina",
      "Fempropazine",
      "Fenpropazina",
      "Isopthazine",
      "Isotazin",
      "Isothazine",
      "Isothiazine",
      "Lysivane",
      "Parcidol",
      "Pardidol",
      "Parfezine",
      "Parkin",
      "Parkisol",
      "Parsidan",
      "Parsidol",
      "Parsitan",
      "Parsotil",
      "Phenopropazine",
      "Phenoprozine",
      "Prodictazin",
      "Prodierazine",
      "Profenamina",
      "Profenamine",
      "Profenaminum",
      "Prophenamine",
      "Prophenaminum",
      "Rochipel",
      "Rocipel",
      "Rodipal",
      "Profenamina [Italian]",
      "RP 3356",
      "SC 2538",
      "SKF 2538",
      "W 483",
      "Parkin (TN)",
      "Parsidan (TN)",
      "Parsidol(TN)",
      "Profenamina [INN-Spanish]",
      "Profenamine (INN)",
      "Profenamine [INN:BAN]",
      "Profenaminum [INN-Latin]",
      "N,N-Diethyl-Alpha-Methyl-10H-Phenothiazine-10-Ethanamine",
      "N,N-Diethyl-1-Phenothiazin-10-Ylpropan-2-Amine",
      "N,N-Diethyl-1-(10H-Phenothiazin-10-Yl)-2-Propanamine",
      "N,N-Diethyl-1-(10H-Phenothiazin-10-Yl)Propan-2-Amine",
      "10-(2-Diethylaminopropyl)Phenothiazine",
      "10-[2-(Diethylamino)-1-Propyl]phenothiazine;10-[2-(Diethylamino)-2-Methylethyl]phenothiazine",
      "10-[2-(Diethylamino)Propyl]phenothiazine",
      "2-Diethylamino-1-Propyl-N-Dibenzoparathiazine",
    ],
  },
  {
    primary_id: "D0Q0RK",
    names: ["AZD4721"],
  },
  {
    primary_id: "D0Q0RX",
    names: ["CD20-Specific CAR T Cells"],
  },
  {
    primary_id: "D0Q0SK",
    names: ["MAGE-3-Transduced Autologous T-Cell Vaccine"],
  },
  {
    primary_id: "D0Q0SQ",
    names: ["Talimogene"],
  },
  {
    primary_id: "D0Q0SZ",
    names: ["OLAMUFLOXACIN MESILATE"],
  },
  {
    primary_id: "D0Q0TI",
    names: ["NT-KO-003", "ATH-012", "NT-K0-003"],
  },
  {
    primary_id: "D0Q0TK",
    names: ["RST100"],
  },
  {
    primary_id: "D0Q0UA",
    names: ["UFT/Leucovorin Calcium"],
  },
  {
    primary_id: "D0Q0UQ",
    names: ["H-D-Tca-C[Cys-Tyr-D-Trp-Arg-Thr-Pen]-Thr-NH2"],
  },
  {
    primary_id: "D0Q0XC",
    names: ["PMID26651364-Compound-9c"],
  },
  {
    primary_id: "D0Q0XE",
    names: ["Dehydroascorbic Acid", "Dehydroascorbate"],
  },
  {
    primary_id: "D0Q0XH",
    names: ["EP-342"],
  },
  {
    primary_id: "D0Q0XJ",
    names: ["ISIS 107774"],
  },
  {
    primary_id: "D0Q0XN",
    names: ["Peptide Analog 19"],
  },
  {
    primary_id: "D0Q0XO",
    names: ["Pentanoic Acid (4-Sulfamoyl-Phenyl)-Amide"],
  },
  {
    primary_id: "D0Q0XV",
    names: ["(1R,2S)-N-Oleoylcyclopropanolamide"],
  },
  {
    primary_id: "D0Q0YC",
    names: [
      "2-(4-Propyl-Piperazin-1-Yl)-Quinoline",
      "CHEMBL180478",
      "Oprea1_188953",
      "SCHEMBL3970431",
      "2-(4-Ethylpiperazino)Quinoline",
      "HEWVIFVVKWCRML-UHFFFAOYSA-N",
      "BDBM50159120",
    ],
  },
  {
    primary_id: "D0Q0YN",
    names: [
      "SORAPRAZAN",
      "BY-359",
      "Soraprazan",
      "2,3-Dimethyl-7(R)-(2-Methoxyethoxy)-9(R)-Phenyl-7,8,9,10-Tetrahydroimidazo[1,2-H][1,7]naphthyridin-8(R)-Ol",
    ],
  },
  {
    primary_id: "D0Q0YS",
    names: ["RT3"],
  },
  {
    primary_id: "D0Q0YV",
    names: ["PMID23147077C10"],
  },
  {
    primary_id: "D0Q0ZY",
    names: ["PRI-724"],
  },
  {
    primary_id: "D0Q1AC",
    names: ["(4-Fluoro-Phenyl)-(9-Methyl-9H-Purin-6-Yl)-Amine"],
  },
  {
    primary_id: "D0Q1BD",
    names: ["Ip5I", "Diinosine-5',5\"-Pentaphosphate"],
  },
  {
    primary_id: "D0Q1BE",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 20"],
  },
  {
    primary_id: "D0Q1BT",
    names: [
      "Clidinium",
      "N-Methyl Quinuclidinyl Benzilate",
      "7020-55-5",
      "3-Hydroxy-1-Methylquinuclidinium Benzilate Ester",
      "3-{[hydroxy(Diphenyl)Acetyl]oxy}-1-Methyl-1-Azoniabicyclo[222]octane",
      "Ro-23773",
      "3-(2-Hydroxy-2,2-Diphenyl-Acetoxy)-1-Methyl-1-Azonia-Bicyclo[222]octane",
      "1-Azoniabicyclo(222)Octane, 3-((Hydroxydiphenylacetyl)Oxy)-1-Methyl-",
      "[3H]clidinium",
      "(-)MQNB",
      "Clidinium Bromid",
      "(1-Methyl-1-Azoniabicyclo[222]octan-8-Yl) 2-Hydroxy-2,2-Di(Phenyl)Acetate",
      "3-Hydroxy-1-Methylquinuclidinium Benzilate (Ester)",
      "Quinuclidinium,",
      "Librax (TN)",
      "Benzilic Acid, Ester With 3-Hydroxy-1-Methylquinuclidinium",
      "Quinuclidinium, 3-Hydroxy-1-Methyl-, Benzilate (Ester)",
      "(1-Methyl-1-Azoniabicyclo[222]octan-3-Yl) 2-Hydroxy-2,2-Diphenylacetate",
    ],
  },
  {
    primary_id: "D0Q1CD",
    names: ["Tanomastat"],
  },
  {
    primary_id: "D0Q1EC",
    names: ["STAT-3 DECOY (NSC-741763)"],
  },
  {
    primary_id: "D0Q1EG",
    names: ["PMID25399762-Compound-Table 6-15"],
  },
  {
    primary_id: "D0Q1EI",
    names: [
      "4-Amino-3,5-Dibromobenzenesulfonamide",
      "39150-45-3",
      "3,5-Dibromosulfanilamide",
      "2,6-Dibromosulfanilamide",
      "3,5-Dibromosulphanilamide",
      "CHEMBL306195",
      "DLXJPWSYRLLYSV-UHFFFAOYSA-N",
      "AK109126",
      "4-Amino-3,5-Dibromobenzene-1-Sulfonamide",
      "Benzenesulfonamide, 4-Amino-3,5-Dibromo-",
      "W-202619",
      "EINECS 254-321-8",
      "4-Amino-3,5-Dibromobenzenesulphonamide",
      "ACMC-209j3o",
      "AC1Q50HX",
      "AC1MJ34Y",
      "Halogenosulfanilamide Deriv. 12",
      "SCHEMBL11136228",
      "BDBM11611",
      "CTK4I0998",
      "DTXSID20192395",
      "MolPort-001-780-206",
      "ZINC2146706",
      "ALBB-026584",
      "ZX-AN025095",
      "KS-00000NE9",
      "5104AB",
    ],
  },
  {
    primary_id: "D0Q1ER",
    names: [
      "CGS-12066B",
      "CGS 12066B",
      "UNII-5TB4SXQ7HG",
      "CGS 12066B-Parent",
      "Cgs 12066",
      "CGS12066",
      "5TB4SXQ7HG",
      "109028-09-3",
      "4-(4-Methylpiperazin-1-Yl)-7-(Trifluoromethyl)Pyrrolo[1,2-A]quinoxaline",
      "CHEMBL27403",
      "CHEBI:64055",
      "CGS-12066A",
      "CGS-12066",
      "CGS-12066A Maleate Salt",
      "Pyrrolo[1,2-A]quinoxaline, 4-(4-Methyl-1-Piperazinyl)-7-(Trifluoromethyl)-",
      "Pyrrolo(1,2-A)Quinoxaline, 4-(4-Methyl-1-Piperazinyl)-7-(Trifluoromethyl)-",
      "NCGC00024701-01",
      "Tocris-0638",
      "7-Trifluoromethyl-4-(4-Methyl-1-Piperazinyl)Pyrrolo-[1,2-A]quinoxaline Maleate Salt",
    ],
  },
  {
    primary_id: "D0Q1EZ",
    names: ["Antifungal Multivalent Oral Vaccine"],
  },
  {
    primary_id: "D0Q1FX",
    names: ["Rac-4'-(1-Imidazol-1-Yl-Propyl)-Biphenyl-3-Ol", "CHEMBL556833", "BDBM50295624"],
  },
  {
    primary_id: "D0Q1GL",
    names: ["CT-200"],
  },
  {
    primary_id: "D0Q1HD",
    names: ["TRV250"],
  },
  {
    primary_id: "D0Q1HE",
    names: ["MRS2698"],
  },
  {
    primary_id: "D0Q1HF",
    names: ["NCGC00167772-01", "10-DEBC"],
  },
  {
    primary_id: "D0Q1HG",
    names: ["PA-1806", "BMS-180680", "SQ-32633", "SQ-33110", "BMS-180680-01"],
  },
  {
    primary_id: "D0Q1HK",
    names: ["2-Dipropylamino-1,2,3,4-Tetrahydro-Anthracen-9-Ol", "CHEMBL62297"],
  },
  {
    primary_id: "D0Q1HN",
    names: ["GEA 3162"],
  },
  {
    primary_id: "D0Q1HU",
    names: ["Resorcinol Compound 28"],
  },
  {
    primary_id: "D0Q1IH",
    names: ["ATX-201"],
  },
  {
    primary_id: "D0Q1IP",
    names: [
      "2-(2-Cycloheptylidenehydrazinyl)-4-Phenylthiazole",
      "CHEMBL248050",
      "AC1NRV52",
      "BDBM50326635",
      "Cycloheptanone, (4-Phenyl-2-Thiazolyl)Hydrazone",
      "N-(Cycloheptylideneamino)-4-Phenyl-Thiazol-2-Amine",
      "1-Cycloheptylidene-2-(4-Phenylthiazol-2-Yl)Hydrazine",
    ],
  },
  {
    primary_id: "D0Q1IT",
    names: [
      "Aclidinium/Formoterol",
      "(S,S)-Formoterol",
      "CHEBI:63081",
      "(S,S)-N-[2-Hydroxy-5-[1-Hydroxy-2-[[2-(4-Methoxyphenyl)-1-Methylethyl]amino]ethyl]phenyl]formaldehyde",
      "NCGC00025167-01",
      "67346-48-9",
      "Tocris-1448",
      "AC1MHY5S",
      "ZINC856",
    ],
  },
  {
    primary_id: "D0Q1JW",
    names: ["Hirufaxin"],
  },
  {
    primary_id: "D0Q1JZ",
    names: [
      "Anti-OX40 Monoclonal Antibody",
      "Anti-OX40 Antibody, Providence Health And Services",
      "Anti-OX40 Monoclonal Antibody (Iv, Cancer)",
      "Anti-OX40 (Iv, Cancer), Providence Health And Services",
      "Anti-OX40 Monoclonal Antibody (Iv, Cancer), Providence Health And Services",
    ],
  },
  {
    primary_id: "D0Q1KL",
    names: ["(R)-2,6-Bis-Hydroxymethyl-Piperidine-3,4,5-Triol", "CHEMBL87169"],
  },
  {
    primary_id: "D0Q1KN",
    names: ["SRP 299"],
  },
  {
    primary_id: "D0Q1KP",
    names: ["R-18893"],
  },
  {
    primary_id: "D0Q1LD",
    names: ["Ethyl 4-(2-Oxohexadecanamido)Benzoate", "CHEMBL460083"],
  },
  {
    primary_id: "D0Q1LG",
    names: ["1-(1-Biphenyl-4-Yl-Pentyl)-1H-Imidazole", "CHEMBL498523"],
  },
  {
    primary_id: "D0Q1LH",
    names: ["Micacides"],
  },
  {
    primary_id: "D0Q1LO",
    names: ["Sitamaquine"],
  },
  {
    primary_id: "D0Q1MG",
    names: ["DAC:antiviral", "Antiviral A, ConjuChem", "Antiviral B, ConjuChem", "DAC:HIV"],
  },
  {
    primary_id: "D0Q1MN",
    names: ["PMID25399762-Compound-Table1-C4"],
  },
  {
    primary_id: "D0Q1MS",
    names: ["Ertapenem"],
  },
  {
    primary_id: "D0Q1MX",
    names: ["ImmunoPulse IL-12"],
  },
  {
    primary_id: "D0Q1NA",
    names: ["PMID25522065-Compound-51"],
  },
  {
    primary_id: "D0Q1NF",
    names: ["3-(Phenoxymethyl) Benzylamine Derivative 6"],
  },
  {
    primary_id: "D0Q1NI",
    names: ["TBC-Thai"],
  },
  {
    primary_id: "D0Q1PN",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-(NMe)Phe-Asp-Nle-Trp-Ac", "CHEMBL408889"],
  },
  {
    primary_id: "D0Q1PO",
    names: ["A804598", "A-804598", "A 804598"],
  },
  {
    primary_id: "D0Q1PR",
    names: [
      "Atracurium",
      "Atracurium Dibesylate",
      "Tracrium (TN)",
      "2,2'-{pentane-1,5-Diylbis[oxy(3-Oxopropane-3,1-Diyl)]}bis[1-(3,4-Dimethoxybenzyl)-6,7-Dimethoxy-2-Methyl-1,2,3,4-Tetrahydroisoquinolinium]",
      "5-[3-[1-[(3,4-Dimethoxyphenyl)Methyl]-6,7-Dimethoxy-2-Methyl-3,4-Dihydro-1H-Isoquinolin-2-Ium-2-Yl]propanoyloxy]pentyl 3-[1-[(3,4-Dimethoxyphenyl)Methyl]-6,7-Dimethoxy-2-Methyl-3,4-Dihydro-1H-Isoquinolin-2-Ium-2-Yl]propanoate",
    ],
  },
  {
    primary_id: "D0Q1PS",
    names: ["VR096"],
  },
  {
    primary_id: "D0Q1QY",
    names: ["PMID29671355-Compound-9"],
  },
  {
    primary_id: "D0Q1RC",
    names: ["A-62176"],
  },
  {
    primary_id: "D0Q1RD",
    names: [
      "2-(3''(5''-Chloro-Indolyl)Ethyloxy)Adenosine",
      "CHEMBL222442",
      "BDBM50208782",
      "2-(3''''(5''''-Chloro-Indolyl)Ethyloxy)Adenosine",
    ],
  },
  {
    primary_id: "D0Q1RN",
    names: ["IL22-Fc"],
  },
  {
    primary_id: "D0Q1RZ",
    names: ["ATA190"],
  },
  {
    primary_id: "D0Q1SA",
    names: ["Rosaramicin"],
  },
  {
    primary_id: "D0Q1SQ",
    names: ["EDO-S101"],
  },
  {
    primary_id: "D0Q1SX",
    names: ["PMID26651364-Compound-7e"],
  },
  {
    primary_id: "D0Q1TX",
    names: ["Fusopin"],
  },
  {
    primary_id: "D0Q1UB",
    names: ["GZ402666"],
  },
  {
    primary_id: "D0Q1UM",
    names: ["ISIS 7849"],
  },
  {
    primary_id: "D0Q1UR",
    names: ["TIAPAFANT"],
  },
  {
    primary_id: "D0Q1VD",
    names: ["SB206"],
  },
  {
    primary_id: "D0Q1WI",
    names: ["RG7745"],
  },
  {
    primary_id: "D0Q1WV",
    names: ["Pyrrolidine Derivative 5"],
  },
  {
    primary_id: "D0Q1XF",
    names: ["PMID25980951-Compound-32"],
  },
  {
    primary_id: "D0Q1XK",
    names: ["PMID18754614C9"],
  },
  {
    primary_id: "D0Q1XV",
    names: ["HBV Vaccine"],
  },
  {
    primary_id: "D0Q1YJ",
    names: ["RON Mab"],
  },
  {
    primary_id: "D0Q1YX",
    names: ["SMNT-1"],
  },
  {
    primary_id: "D0Q1YZ",
    names: ["Teceleukin"],
  },
  {
    primary_id: "D0Q1ZC",
    names: [
      "3-(1-Methyl-Piperidin-4-Yl)-2-Phenyl-1H-Indole",
      "CHEMBL91667",
      "SCHEMBL7959758",
      "UBCVYEFSXHCFPK-UHFFFAOYSA-N",
      "BDBM50095055",
      "3-(1-Methylpiperidin-4-Yl)-2-Phenyl-1H-Indole",
      "L018753",
    ],
  },
  {
    primary_id: "D0Q1ZD",
    names: ["HO-4038"],
  },
  {
    primary_id: "D0Q1ZT",
    names: ["TP003"],
  },
  {
    primary_id: "D0Q2AS",
    names: ["Etozolin"],
  },
  {
    primary_id: "D0Q2AT",
    names: ["Flucloxacillin"],
  },
  {
    primary_id: "D0Q2BB",
    names: ["AVE1701"],
  },
  {
    primary_id: "D0Q2BG",
    names: ["CU-2010"],
  },
  {
    primary_id: "D0Q2BM",
    names: ["CB-001"],
  },
  {
    primary_id: "D0Q2CC",
    names: ["N-Hydroxy-N'-(2-Methylphenyl)Octanediamide", "CHEMBL1076794", "BDBM50314140"],
  },
  {
    primary_id: "D0Q2CM",
    names: ["CRB-0016"],
  },
  {
    primary_id: "D0Q2CR",
    names: ["1-(1,3,4-Oxadiazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL467796", "SCHEMBL2161990"],
  },
  {
    primary_id: "D0Q2DE",
    names: [
      "CHOLINE IODIDE",
      "Choline Iodide",
      "17773-10-3",
      "2-Hydroxy-N,N,N-Trimethylethanaminium Iodide",
      "Jodoetano",
      "Dilatol Zambon",
      "UNII-39528O55VK",
      "EINECS 241-754-2",
      "(2-Hydroxyethyl)Trimethylammonium Iodide",
      "AI3-61505",
      "CHEMBL293410",
      "Ethanaminium, 2-Hydroxy-N,N,N-Trimethyl-, Iodide",
      "39528O55VK",
      "CholineIodide",
      "AC1L3DCR",
      "AC1Q1TNR",
      "ACMC-209ed9",
      "SCHEMBL315876",
      "CTK4D6569",
      "ANW-22891",
      "MFCD00011900",
      "AKOS027320917",
      "2-Hydroxyethyl(Trimethyl)Azanium Iodide",
      "ACM17773103",
      "KS-0000140P",
      "LS-53247",
      "KB-76072",
      "TC-111636",
      "FT-0623771",
      "C-45721",
      "Ethanaminium,2-Hydroxy-N,N,N-Trimethyl-, Io",
    ],
  },
  {
    primary_id: "D0Q2EH",
    names: ["Brutieridin"],
  },
  {
    primary_id: "D0Q2EJ",
    names: ["CPH-106"],
  },
  {
    primary_id: "D0Q2ES",
    names: [
      "Benzonatate",
      "Benzonatato",
      "Benzonatatum",
      "Benzononantin",
      "Benzononatine",
      "Exangit",
      "Tesalon",
      "Tessalin",
      "Tessalon",
      "Ventussin",
      "Tessalon Perles",
      "KM65",
      "Benzonatato [INN-Spanish]",
      "Benzonatatum [INN-Latin]",
      "Tessalon Capsules (TN)",
      "Tessalon Perles (TN)",
      "Tessalon-Ciba",
      "Ventussin-Loz",
      "Benzonatate (USP/INN)",
      "Benzonatate [USAN:INN:BAN];Nonaethyleneglycol Monomethyl Ether P-N-Butylaminobenzoate",
      "P-Butylaminobenzoic Acid Omega-O-Methylnonaethyleneglycol Ester",
      "Polyethyleneglycol-P-N-Butylaminobenzoate Methyl Ester",
      "P-(N)-Butylaminobenzoesaeure-(Nonaaethylenglykol-Monomethylaether)-Ester",
      "P-(N)-Butylaminobenzoesaeure-(Nonaaethylenglykol-Monomethylaether)-Ester [German]",
      "Benzoic Acid, 4-(Butylamino)-, 2,5,8,11,14,17,20,23,26-Nonaoxaoctacos-28-Yl Ester",
      "Benzoic Acid, 4-(Butylamino)-, 3,6,9,12,15,18,21,24,27-Nonaoxaoctacos-1-Yl Ester",
      "Benzoic Acid, 4-(Butylamino)-, 3,6,9,12,15,18,21,24,27-Nonaoxaoctacos-1-Yl Ester (9CI)",
      "Benzoic Acid, P-(Butylamino)-, 2-(2-(2-(2-(2-(2-(2-(2-(2-Methoxyethoxy)Ethoxy)Ethoxy)Ethoxy)Ethoxy)Ethoxy)Ethoxy)Ethoxy)Ethyl Ester",
      "2,5,8,11,14,17,20,23,26-Nonaoxaoctacosan-28-Yl P-(Butylamino)Benzoate",
      "2,5,8,11,14,17,20,23,26-Nonaoxaoctacosan-28-Yl 4-(Butylamino)Benzoate",
      "2-[2-[2-[2-[2-[2-[2-[2-(2-Methoxyethoxy)Ethoxy]ethoxy]ethoxy]ethoxy]ethoxy]ethoxy]ethoxy]ethyl 4-(Butylamino)Benzoate",
      "2-[2-[2-[2-[2-[2-[2-[2-(2-Methoxyethoxy)Ethoxy]ethoxy]ethoxy]ethoxy]ethoxy]ethoxy]ethoxy]ethyl 4-Butylaminobenzoate",
      "3,6,9,12,15,18,21,24,27-Nonaoxaoctacos-1-Yl 4-(Butylamino)Benzoate",
      "3,6,9,12,15,18,21,24,27-Nonaoxaoctacosyl 4-Butylaminobenzoate",
      "4-(Butylamino)Benzoic Acid 3,6,9,12,15,18,21,24,27-Nonaoxaoctacos-1-Yl Ester",
    ],
  },
  {
    primary_id: "D0Q2FC",
    names: ["Tetra-Hydro-Imidazo[1,5-D][1,4]oxazepin-3-Yl Derivative 5"],
  },
  {
    primary_id: "D0Q2GK",
    names: ["PMID24000170C38"],
  },
  {
    primary_id: "D0Q2GM",
    names: ["N-(2-Amino-5-(Thiophen-2-Yl)Phenyl)Nicotinamide", "CHEMBL256440", "SCHEMBL1066609"],
  },
  {
    primary_id: "D0Q2GP",
    names: ["ISOLOXAPINE"],
  },
  {
    primary_id: "D0Q2GX",
    names: ["Strada"],
  },
  {
    primary_id: "D0Q2HM",
    names: [
      "EDD7H9",
      "Anti S1P3 Antibody (Breast Cancer), Expression Drug Designs",
      "Anti-GPCR Abs (Cancer), Expression Drug Designs",
      "G-Protein-Coupled Receptor Inhibitor Antibodies (Breast Cancer), Expression Drug Designs",
    ],
  },
  {
    primary_id: "D0Q2HO",
    names: ["Naldemedine Tosylate"],
  },
  {
    primary_id: "D0Q2HW",
    names: ["Cytochrome P450-Targeting Prodrugs", "Cytochrome P450-Targeting Prodrugs (OncoCYP, Cancer)"],
  },
  {
    primary_id: "D0Q2HZ",
    names: ["H-Dmt-Tic-NH-(R)CH(CH2-COOH)-Bid(N1-Me)", "CHEMBL499099", "BDBM50272228"],
  },
  {
    primary_id: "D0Q2IU",
    names: ["AcPYY(26-36)", "CHEMBL397527"],
  },
  {
    primary_id: "D0Q2IV",
    names: ["ALVAC-CEA/HB7.1"],
  },
  {
    primary_id: "D0Q2JB",
    names: ["CP-640922"],
  },
  {
    primary_id: "D0Q2JS",
    names: ["SB-282241", "CCR2B Antagonists, GlaxoSmithKline", "SB-380732", "MCP-1 Antagonists, GlaxoSmithKline"],
  },
  {
    primary_id: "D0Q2KK",
    names: ["5H-6-Thia-4,5-Diaza-Chrysene 6,6-Dioxide"],
  },
  {
    primary_id: "D0Q2KM",
    names: ["MM-111"],
  },
  {
    primary_id: "D0Q2LG",
    names: ["5S-HPETE", "5-(S)-Hydroperoxyeicosatetraenoic Acid", "5S-HpETE"],
  },
  {
    primary_id: "D0Q2LL",
    names: ["6-Hydroxy-Benzothiazole-2-Sulfonic Acid Amide", "6-Hydroxybenzo[d]thiazole-2-Sulfonamide"],
  },
  {
    primary_id: "D0Q2MH",
    names: ["Hexafluronium Bromide"],
  },
  {
    primary_id: "D0Q2MI",
    names: ["1-(3,5-Dimethylphenyl)-2-(Phenylsulfonyl)Ethanone", "CHEMBL239261", "BDBM50212547"],
  },
  {
    primary_id: "D0Q2MN",
    names: [
      "Dothiepin",
      "Cis-Dothiepin",
      "Dosulepin",
      "Prothiaden",
      "UNII-CZ377VWX9P",
      "Dosulepin [INN]",
      "113-53-1",
      "CZ377VWX9P",
      "CHEBI:36802",
      "Dosulepin (INN)",
      "Dosulepin Chloride",
      "3-Dibenzo[b,e]thiepin-11(6H)-Ylidene-N,N-Dimethyl-1-Propanamine",
      "Dosulepine [INN-French]",
      "Dosulepinum [INN-Latin]",
      "Dosulepina [INN-Spanish]",
      "Dothep",
      "HSDB 7181",
      "N,N-Dimethyldibenzo[b,e]thiepin-Delta(11(6H),gamma)-Propylamine",
      "3-Dibenzo[b,e]thiepin-11(6H)-Ylidene-N,N-Dimethylpropan-1-Amine",
      "Dothiepin;",
      "EINECS 204-031-2",
      "Prothiadiene",
      "Depresym",
    ],
  },
  {
    primary_id: "D0Q2MX",
    names: [
      "UNC9994",
      "CHEMBL2165138",
      "SCHEMBL248630",
      "GTPL7652",
      "URYZTMIOHYGTNJ-UHFFFAOYSA-N",
      "BDBM50395577",
      "5-{3-[4-(2,3-Dichlorophenyl)Piperidin-1-Yl]propoxy}-1,3-Benzothiazole",
    ],
  },
  {
    primary_id: "D0Q2OH",
    names: ["2-(3-Methylsulfanyl-Propyl)-Pentanedioic Acid", "CHEMBL61882"],
  },
  {
    primary_id: "D0Q2OK",
    names: ["MRS2693"],
  },
  {
    primary_id: "D0Q2PE",
    names: [
      "Norfloxacin",
      "Baccidal",
      "Barazan",
      "Chibroxin",
      "Fulgram",
      "Lexinor",
      "NFLX",
      "Norflo",
      "Norfloxacine",
      "Norfloxacino",
      "Norfloxacinum",
      "Noroxin",
      "Sebercim",
      "Merck Brand Of Norfloxacin",
      "Norfloxacin Merck Brand",
      "AM 0715",
      "AM 715",
      "AM0715",
      "MK 0366",
      "MK 366",
      "MK0366",
      "MK366",
      "AM-0715",
      "AM-715",
      "Chibroxin (TN)",
      "Insensye (TN)",
      "MK-0366",
      "MK-366",
      "Norflohexal (TN)",
      "Norfloxacine [INN-French]",
      "Norfloxacino [INN-Spanish]",
      "Norfloxacinum [INN-Latin]",
      "Norfocin (TN)",
      "Noroxin (TN)",
      "Nufloxib (TN)",
      "Roxin (TN)",
      "Utin (TN)",
      "Utinor (TN)",
      "Apo-Norflox (TN)",
      "Norfloxacin (JP15/USP/INN)",
      "Norfloxacin [USAN:BAN:INN:JAN]",
      "Chibroxin, MK-366, Baccidal, Sebercim, Zoroxin, Norfloxacin",
      "1,4-Dihydro-1-Ethyl-6-Fluoro-4-Oxo-7-(1-Piperazinyl)-3-Quinolinecarboxylic Acid",
      "1-Ethyl-6-Fluor-1,4-Dihydro-4-Oxo-7-(1-Piperazinyl)-3-Chinolincarbonsaeure",
      "1-Ethyl-6-Fluoro-1,4-Dihydro-4-Oxo-7-(1-Piperazinyl)-3-Quinolinecarboxylic Acid",
      "1-Ethyl-6-Fluoro-1,4-Dihydro-4-Oxo-7-[1-Piperazinyl]-3-Quinoline-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-4-Oxo-7-(Piperazin-1-Yl)-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-4-Oxo-7-Piperazin-1-Yl-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-4-Oxo-7-Piperazin-1-Ylquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0Q2PF",
    names: ["LY-293284"],
  },
  {
    primary_id: "D0Q2PO",
    names: ["[Asp6,Lys10]N/OFQ(1-13)NH2", "CHEMBL507269"],
  },
  {
    primary_id: "D0Q2QA",
    names: [
      "(S)-2-Amino-1-(4-Methylthiophenyl)-Propane",
      "CHEMBL439193",
      "ZINC6021007",
      "BDBM50213675",
      "(S)-Alpha-Methyl-4-Methylthiobenzeneethanamine",
      "UNII-6JP2T8KXTR Component OLEWMKVPSUCNLG-QMMMGPOBSA-N",
    ],
  },
  {
    primary_id: "D0Q2QU",
    names: ["Inosine Pranobex"],
  },
  {
    primary_id: "D0Q2RF",
    names: ["AP-1101"],
  },
  {
    primary_id: "D0Q2RJ",
    names: ["CR-5542"],
  },
  {
    primary_id: "D0Q2RP",
    names: ["24(S), 25-Epoxycholesterol", "24,25-Epoxy-Cholesterol"],
  },
  {
    primary_id: "D0Q2SE",
    names: ["Pyrrolidine Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0Q2SI",
    names: [
      "4-(4-Chlorobenzyl)Phthalazin-1(2H)-One",
      "53242-88-9",
      "4-(4-Chloro-Benzyl)-2H-Phthalazin-1-One",
      "4-[(4-Chlorophenyl)Methyl]-2H-Phthalazin-1-One",
      "UNII-56DW142LLX",
      "MLS000547504",
      "4-(4-Chlorobenzyl)Phthalazin-1-Ol",
      "CHEMBL63976",
      "4-(4-Chlorobenzyl)-1(2H)-Phthalazinone",
      "56DW142LLX",
      "4-[(4-CHLOROPHENYL)METHYL]PHTHALAZIN-1(2H)-ONE",
      "SMR000113649",
      "4-(4-Chlorobenzyl)-2H-Phthalazin-1-One",
      "4-[(4-Chlorophenyl)Methyl]-1(2H)-Phthalazinone",
      "4-((4-Chlorophenyl)Methyl)Phthalazin-1(2H)-One",
      "W-109042",
      "4-{[4-Chlorophenyl]methyl}-1(2H)-Phthalazinone",
    ],
  },
  {
    primary_id: "D0Q2TF",
    names: ["1-(4-Tert-Butylphenylsulfonyl)Azepan-4-Ol", "CHEMBL539200", "SCHEMBL12550392"],
  },
  {
    primary_id: "D0Q2UI",
    names: ["Armed Probodies", "Armed Probodies (Colon Cancer/Head And Neck Cancer/Glioblastoma Multiforme)"],
  },
  {
    primary_id: "D0Q2UW",
    names: ["DIMS-9054", "DNA-Based Immunomodulatory Sequence (Multiple Sclerosis/Infectious Disease), InDex"],
  },
  {
    primary_id: "D0Q2UZ",
    names: ["Y-C[D-Pen-(2S,3R)-2-Me-(2')Nal-GSFC]KR-NH2", "CHEMBL413670", "BDBM50064847"],
  },
  {
    primary_id: "D0Q2VA",
    names: ["EP-37"],
  },
  {
    primary_id: "D0Q2VL",
    names: ["PD-157533"],
  },
  {
    primary_id: "D0Q2VN",
    names: [
      "[3H]4NMPB",
      "[3H](1-Methyl-4-Piperidyl) 2-Hydroxy-2,2-Diphenyl-Acetate",
      "[3H]1-Methyl-4-Piperidyl Diphenylglycolate",
      "[3H]enpiperate",
    ],
  },
  {
    primary_id: "D0Q2VU",
    names: ["MRNA MRK-1777"],
  },
  {
    primary_id: "D0Q2WE",
    names: ["DFD-04"],
  },
  {
    primary_id: "D0Q2WS",
    names: ["Terbutyline"],
  },
  {
    primary_id: "D0Q2XB",
    names: [
      "3,5-Difluorophenol",
      "2713-34-0",
      "Phenol, 3,5-Difluoro-",
      "3,5-Difluoro Phenol",
      "3,5-Difluoro-Phenol",
      "Phenol,3,5-Difluoro-",
      "HJSSBIMVTMYKPD-UHFFFAOYSA-N",
      "MFCD00002255",
      "3,5difluorophenol",
      "3,5-Difluorphenol",
      "3.5-Difluorophenol",
      "PubChem1496",
      "Phenol Derivative, 6",
      "ACMC-209gvc",
      "AC1Q4NDO",
      "AC1L2PTJ",
      "3,5-Difluorophenol, 99%",
      "KSC203O5T",
      "SCHEMBL156265",
      "AC1Q78L9",
      "CHEMBL259068",
      "CTK1A3759",
      "BDBM26192",
      "HJSSBIMVTMYKPD-UHFFFAOYSA-",
      "DTXSID10181596",
      "ABLOCK AB-12-1639",
      "MolPort-000-154-449",
      "ZINC406989",
      "ACT11666",
      "JRD-0083",
      "CS-D1731",
      "SBB085756",
      "ANW-26134",
    ],
  },
  {
    primary_id: "D0Q2XF",
    names: ["Bimatoprost", "Bimatoprost (Topical, Alopecia)"],
  },
  {
    primary_id: "D0Q2XN",
    names: ["Turmeric Extracts"],
  },
  {
    primary_id: "D0Q2YA",
    names: ["Stroke Polyfunctional Therapeutic"],
  },
  {
    primary_id: "D0Q2YH",
    names: ["QCPac", "CHEMBL486028"],
  },
  {
    primary_id: "D0Q2YJ",
    names: ["Phenanthrene-9,10-Dione"],
  },
  {
    primary_id: "D0Q2ZO",
    names: ["4-Octyl-N-(Pyridin-3-Yl)Benzamide", "CHEMBL405990"],
  },
  {
    primary_id: "D0Q2ZQ",
    names: ["PAR-401"],
  },
  {
    primary_id: "D0Q2ZS",
    names: ["ISIS 196103"],
  },
  {
    primary_id: "D0Q2ZT",
    names: ["Kaolin/Pectin"],
  },
  {
    primary_id: "D0Q2ZV",
    names: ["OLANEXIDINE HYDROCHLORIDE"],
  },
  {
    primary_id: "D0Q3AN",
    names: ["2-Fluoro-4-[5-(3-Hydroxyphenyl)-3-Thienyl]phenol", "CHEMBL570110"],
  },
  {
    primary_id: "D0Q3BG",
    names: ["Gene Therapy, IFN-B"],
  },
  {
    primary_id: "D0Q3BV",
    names: [
      "Colistimethate",
      "Colimicina",
      "Colimyscine",
      "Colimycin M",
      "Colistimethale Sodium",
      "Colistimethate Sodium",
      "Colistin Sulfomethate",
      "Colistin Methanesulfonate Sodium Salt",
      "Colistin Sodium Methanesulfonate",
      "Colistin Sodium Methanesulfonate From Bacillus Colistinus",
      "Colistin Sulfomethate Sodium",
      "Colistinemethanesulfonate Sodique",
      "Colistrimethate Sodium",
      "Colymycin M",
      "Pentasodium Colistinmethanesulfonate",
      "Sodium Colistimethate",
      "Sodium Colistin Methanesulfonate",
      "Sodium Colistinemethanesulfonate",
      "W 1929",
      "Colistimethate Sodium [USAN:INN]",
      "Colistinmethanesulfonic Acid, Sodium Salt",
      "Coly-Mycin M",
      "Coly-Mycin M Parenteral",
      "Coly-Mycin Injectable",
    ],
  },
  {
    primary_id: "D0Q3CB",
    names: [
      "GWTLNSAGYLLGPPPALALA-CONH2",
      "143896-17-7",
      "CHEMBL604990",
      "M40",
      "GALANIN RECEPTOR LIGAND M40",
      "BDBM50307254",
      "AKOS024457623",
      "Galanin (1-13)-Pro-Pro-(Ala-Leu-)2Ala Amide",
    ],
  },
  {
    primary_id: "D0Q3CD",
    names: ["Salvinorin B 2-Methoxy-2-Propyl Ether", "CHEMBL429026"],
  },
  {
    primary_id: "D0Q3CX",
    names: ["6-(4-Methylpyridin-3-Yl)-2-Naphthonitrile", "CHEMBL500665", "BDBM50272247"],
  },
  {
    primary_id: "D0Q3DI",
    names: ["3-Methoxy-5-(1,5-Naphthyridin-3-Yl)Benzonitrile", "CHEMBL397617"],
  },
  {
    primary_id: "D0Q3DS",
    names: [
      "3-Pyridin-4-Yl-Quinoline",
      "3-(4-Pyridinyl)Quinoline",
      "CHEMBL89550",
      "3-(Pyridin-4-Yl)Quinoline",
      "SCHEMBL2346887",
      "QYCONSXONLLGHQ-UHFFFAOYSA-N",
      "BDBM50039658",
    ],
  },
  {
    primary_id: "D0Q3DV",
    names: ["(+/-)-Threo-Ritalinol Methyl Ether", "CHEMBL1188814", "BDBM50327173", "BDBM50212378"],
  },
  {
    primary_id: "D0Q3DY",
    names: ["PMID12238936C3f"],
  },
  {
    primary_id: "D0Q3EJ",
    names: ["2-N-Hydroxyamino-1-(4-Ethylthiophenyl)Butane", "CHEMBL1077983", "1204749-43-8"],
  },
  {
    primary_id: "D0Q3ER",
    names: ["CVBT-141B"],
  },
  {
    primary_id: "D0Q3ES",
    names: [
      "APD668",
      "832714-46-2",
      "APD-668",
      "CHEMBL1775179",
      "Isopropyl 4-((1-(2-Fluoro-4-(Methylsulfonyl)Phenyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Yl)Oxy)Piperidine-1-Carboxylate",
      "Isopropyl 4-(1-(2-Fluoro-4-(Methylsulfonyl)Phenyl)-1H-Pyrazolo[3,4-D]pyrimidin-4-Yloxy)Piperidine-1-Carboxylate",
      "1-Piperidinecarboxylic Acid,4-[[1-[2-Fluoro-4-(Methylsulfonyl)Phenyl]-1H-Pyrazolo[3,4-D]pyrimidin-4-Yl]oxy]-, 1-Methylethyl Ester",
      "SCHEMBL389323",
      "AOB6271",
      "XTRUQJBVQBUKSQ-UHFFFAOYSA-N",
      "MolPort-039-331-631",
      "EX-A1795",
      "BCP10207",
      "BDBM50343442",
      "ZINC68266967",
    ],
  },
  {
    primary_id: "D0Q3EV",
    names: ["N-N-Octyl-7-Methoxytacrine Hydrochloride", "CHEMBL1257766"],
  },
  {
    primary_id: "D0Q3EW",
    names: [
      "2np8",
      "Aurora Kinase Inhibitor III",
      "879127-16-9",
      "N-{3-[(4-{[3-(Trifluoromethyl)Phenyl]amino}pyrimidin-2-Yl)Amino]phenyl}cyclopropanecarboxamide",
      "CHEMBL383899",
      "Cyclopropanecarboxylic Acid-(3-(4-(3-Trifluoromethyl-Phenylamino)-Pyrimidin-2-Ylamino)-Phenyl)-Amide",
      "N-[3-[[4-[3-(Trifluoromethyl)Anilino]pyrimidin-2-Yl]amino]phenyl]cyclopropanecarboxamide",
      "Anilinopyrimidine1",
      "SCHEMBL676556",
      "GTPL5931",
      "CTK8E9807",
      "DTXSID20429557",
      "MolPort-029-945-855",
      "HMS3229O19",
      "HMS3229A22",
      "K00603a",
      "ZINC13831338",
      "MFCD09265250",
      "IN1527",
      "BDBM50200392",
    ],
  },
  {
    primary_id: "D0Q3FY",
    names: ["PMID19632837C17f", "Benzo[d]imidazole Inhibitors Of PRMT4 From BMS", "GTPL7031", "BDBM50301077"],
  },
  {
    primary_id: "D0Q3GG",
    names: ["AG-213"],
  },
  {
    primary_id: "D0Q3GH",
    names: [
      "1,1,1-Trifluoro-3-(Octylthio)Propan-2-One",
      "89820-00-8",
      "3-Octylthio-1,1,1-Trifluoro-2-Propanone",
      "CHEMBL89506",
      "2-Propanone,1,1,1-Trifluoro-3-(Octylthio)-",
      "1,1,1-TRIFLUORO-3-(OCTYLTHIO)ACETONE",
      "3-Octylthio-1,1,1-Trifluoropropan-2-One",
      "2-Propanone, 1,1,1-Trifluoro-3-(Octylthio)-",
      "1,1,1-Trifluoro-3-(Octylsulfanyl)Propan-2-One",
      "3-Otfp",
      "1,1,1-Trifluoro-3-Octylsulfanyl-Propan-2-One",
      "OTFP",
      "ACMC-20lqwm",
      "AC1L3SIW",
      "SCHEMBL4224604",
      "CTK5G3533",
      "DTXSID40237914",
      "WMQHRXUKAYSPPK-UHFFFAOYSA-N",
      "ZINC34803731",
      "BDBM50371970",
      "AKOS028111069",
      "DB08612",
      "1,1,1-Trifluoro-3-(Octylsulfanyl)Acetone",
    ],
  },
  {
    primary_id: "D0Q3GQ",
    names: [
      "4-Cycloheptyliden(4-Hydroxyphenyl)Methylphenol",
      "4,4'-(Cycloheptylidenemethylene)Diphenol",
      "4,4'-(Cycloheptylidenemethanediyl)Diphenol",
      "14303-48-1",
      "CHEMBL154947",
      "SCHEMBL3468370",
      "CTK0F0060",
      "DTXSID10469043",
      "XVTAYJKFHUKWSR-UHFFFAOYSA-N",
      "4,4'-(Cycloheptylidenemethylene)Bis(Phenol)",
      "Phenol, 4-[cycloheptylidene(4-Hydroxyphenyl)Methyl]-",
    ],
  },
  {
    primary_id: "D0Q3GV",
    names: [
      "S32504",
      "CHEMBL484599",
      "SCHEMBL3831115",
      "BDBM86473",
      "LS-192133",
      "S32504 (+)",
      "(+/-)-Trans-3,4,4a,5,6,10b-Hexahydro-9-Carbamoyl-4-Propyl-2h-Naphth[1,2-B]-1,4-Oxazine",
    ],
  },
  {
    primary_id: "D0Q3HO",
    names: ["Oligonucleotide Decoys (NFkappaB)"],
  },
  {
    primary_id: "D0Q3IE",
    names: ["Pro-His-Pro-His-Phe-Phe-Val-Tyr-Lys"],
  },
  {
    primary_id: "D0Q3IS",
    names: ["VB4-845"],
  },
  {
    primary_id: "D0Q3JZ",
    names: [
      "3-[5-Ethoxy-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "CHEMBL176886",
      "SCHEMBL4226316",
      "BDBM8598",
      "AC1O704Z",
      "(3-Pyridylmethylene)Indane 19b",
      "3-[(Z)-(5-Ethoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0Q3KD",
    names: [
      "2-(5-Mercapto-Pentyl)-Pentanedioic Acid",
      "CHEMBL61067",
      "Pentanedioic Acid, 2-(5-Mercaptopentyl)-",
      "BDBM50127621",
    ],
  },
  {
    primary_id: "D0Q3KF",
    names: [
      "4'-((2-Butylbenzofuran-3-Yl)Methyl)Biphenyl-4-Ol",
      "CHEMBL24141",
      "BDBM50086945",
      "4''-((2-Butylbenzofuran-3-Yl)Methyl)Biphenyl-4-Ol",
      "4''-(2-Butyl-Benzofuran-3-Ylmethyl)-Biphenyl-4-Ol",
    ],
  },
  {
    primary_id: "D0Q3KN",
    names: ["ISIS 103489"],
  },
  {
    primary_id: "D0Q3KS",
    names: [
      "CT-98024",
      "556813-39-9",
      "N2-(2-((4-(2,4-Dichlorophenyl)-5-(1H-Imidazol-2-Yl)Pyrimidin-2-Yl)Amino)Ethyl)-5-Nitropyridine-2,6-Diamine",
      "CHEMBL1080901",
      "CHIR-98024",
      "N6-[2-[[4-(2,4-Dichlorophenyl)-5-(1H-Imidazol-2-Yl)-2-Pyrimidinyl]amino]ethyl]-3-Nitro-2,6-Pyridinediamine",
      "NDFXSHIIGXVOKT-UHFFFAOYSA-N",
      "SCHEMBL4394521",
      "CTK8E8250",
      "DTXSID90433308",
      "MolPort-006-393-166",
      "BCP13710",
      "BDBM50313013",
      "ABP000489",
      "ZINC44136098",
      "AKOS016011284",
      "KB-76042",
      "CHIR98014(CT98014)/",
      "AK120785",
      "RT-011992",
      "AX8246201",
      "AJ-109287",
      "FT-0664506",
      "Z-3284",
    ],
  },
  {
    primary_id: "D0Q3KV",
    names: [
      "2-Amino-2-(2,3-Difluorophenyl)Acetic Acid",
      "237424-16-7",
      "2,3-Difluoro-DL-Phenylglycine",
      "2,3-DIFLUOR-DL-PHENYLGLYCINE",
      "CHEMBL204804",
      "AC1MC6PF",
      "MolPort-000-165-847",
      "JRD-0494",
      "SBB090537",
      "BDBM50179717",
      "Amino(2,3-Difluorophenyl)Acetic Acid",
      "AKOS012977999",
      "AB26703",
      "PS-11465",
      "Alpha-Amino-2,3-Difluoro-Benzeneacetic Acid",
    ],
  },
  {
    primary_id: "D0Q3LM",
    names: ["N6-Methoxy-2-[2-(Trimethylsilyl)Ethynyl]adenosine", "CHEMBL267421", "SCHEMBL4378430"],
  },
  {
    primary_id: "D0Q3MM",
    names: ["Cyclo(-D-Tyr-L-Arg-L-Arg-L-Nal-Gly-)", "CHEMBL219474"],
  },
  {
    primary_id: "D0Q3NG",
    names: [
      "N-(4-Amino-5-Cyano-6-Ethoxypyridin-2-Yl)Acetamide",
      "894803-94-2",
      "Acetamide,N-(4-Amino-5-Cyano-6-Ethoxy-2-Pyridinyl)-",
      "Kinome_3025",
      "Aminopyridine Deriv. 1",
      "CHEMBL208637",
      "SCHEMBL1145395",
      "Acetamide, N-(4-Amino-5-Cyano-6-Ethoxy-2-Pyridinyl)-",
      "BDBM15907",
      "Aminopyridine-Based Inhibitor 6a",
      "DXAJWOOLPAIVJG-UHFFFAOYSA-N",
      "ZINC14959957",
      "KB-74421",
    ],
  },
  {
    primary_id: "D0Q3OK",
    names: [
      "(R,S)-1-(5-Chloro-1H-Indol-1-Yl)Propan-2-Amine",
      "CHEMBL328844",
      "1H-Indole-1-Ethanamine, 5-Chloro-Alpha-Methyl-",
      "SCHEMBL7092716",
      "BDBM50321880",
      "169674-96-8",
    ],
  },
  {
    primary_id: "D0Q3OM",
    names: ["SKL-19014"],
  },
  {
    primary_id: "D0Q3PB",
    names: ["CCX872"],
  },
  {
    primary_id: "D0Q3PD",
    names: ["S-297995"],
  },
  {
    primary_id: "D0Q3QI",
    names: [
      "2-Hydroxyoctanoic Acid",
      "617-73-2",
      "2-Hydroxycaprylic Acid",
      "2-Hydroxy-N-Octanoic Acid",
      "Hydroxycaprylic Acid",
      "2-Hydroxy Caprylic Acid",
      "Hydroxyoctanoate",
      "2-Hydroxy-Octanoic Acid",
      "Octanoic Acid, Hydroxy-",
      "Alpha-Hydroxycaprylic Acid",
      "2-Hydroxyoctanoate",
      "Alpha-Hydroxyoctanoic Acid",
      "(+/-)-2-Hydroxyoctanoic Acid",
      "JKRDADVRIYVCCY-UHFFFAOYSA-N",
      "MFCD00014410",
      "2-Hydroxycaprylate",
      "Alpha-Hydroxyoctanoate",
      "Alpha-Hydroxycaprylate",
      "2-Hydroxyoctans",
      "Octanoic Acid, 2-Hydroxy-",
      "A-Hydroxycaprylate",
      "A-Hydroxyoctanoate",
      "EINECS 210-524-3",
      "NSC 189703",
    ],
  },
  {
    primary_id: "D0Q3QS",
    names: [
      "4-Aminosalicylate Sodium (Oral Controlled Release, Ulcerative Colitis), AGI Therapeutics",
      "AGI-022",
      "Salicylate Prodrug (Oralcontrolled Release, Ulcerative Colitis), AGI Therapeutics",
      "4-ASA-Na",
      "4-ASA-Na (Oral, Controlled Release), AGI Therapeutics",
    ],
  },
  {
    primary_id: "D0Q3RC",
    names: ["Taxotere-Mab"],
  },
  {
    primary_id: "D0Q3RF",
    names: ["(E)-1-(4-Nitro-Phenyl)-3-Pyridin-2-Yl-Propenone", "CHEMBL187434", "SCHEMBL4298443", "ZINC13584328"],
  },
  {
    primary_id: "D0Q3SP",
    names: ["NPS-1392"],
  },
  {
    primary_id: "D0Q3SU",
    names: ["Benzimidazole And Imadazopyridine Carboximidamide Compound 1"],
  },
  {
    primary_id: "D0Q3TF",
    names: ["Staphylocide"],
  },
  {
    primary_id: "D0Q3UL",
    names: ["PD-160170"],
  },
  {
    primary_id: "D0Q3UQ",
    names: ["LC16m8"],
  },
  {
    primary_id: "D0Q3VE",
    names: ["PF-04971729"],
  },
  {
    primary_id: "D0Q3VI",
    names: [
      "8-Methoxy-2,3,4,9-Tetrahydro-1H-Beta-Carboline",
      "CHEMBL269571",
      "8-Methoxy-1,2,3,4-Tetrahydro-Beta-Carboline",
      "BDBM50136502",
      "AKOS022647299",
      "122835-07-8",
    ],
  },
  {
    primary_id: "D0Q3VN",
    names: ["HO-LVA", "CHEMBL394602"],
  },
  {
    primary_id: "D0Q3VS",
    names: [
      "2-(2,4-Dichlorophenyl)-4,5-Dihydro-1H-Imidazole",
      "CHEMBL489080",
      "61033-72-5",
      "2-(2,4-Dichlorophenyl)-2-Imidazoline",
      "CTK2E8089",
      "DTXSID20492004",
      "BDBM50266030",
      "ZINC40976675",
      "AKOS027869670",
      "DB-072897",
      "FT-0720309",
      "1H-Imidazole, 2-(2,4-Dichlorophenyl)-4,5-Dihydro-",
    ],
  },
  {
    primary_id: "D0Q3VU",
    names: ["[3H]GlySar", "[3H]-GlySar", "Tritiated Glycylsarcosine"],
  },
  {
    primary_id: "D0Q3VZ",
    names: ["PMID28766366-Compound-Scheme27LeucettineL41"],
  },
  {
    primary_id: "D0Q3WB",
    names: [
      "BK-PIFA",
      "Avian Influenza Vaccine, Research Foundation For Microbial Diseases OfOsaka University",
      "H5N1 Influenza Vaccine, BIKEN",
    ],
  },
  {
    primary_id: "D0Q3WC",
    names: ["1-Methoxy-3-(3-(Pyridin-4-Yl)-1H-Indol-6-Yl)Urea", "CHEMBL205218"],
  },
  {
    primary_id: "D0Q3WN",
    names: ["Napsagatran"],
  },
  {
    primary_id: "D0Q3WP",
    names: [
      "MEDI-553",
      "B-Cell Depleting Anti-CD22 Monoclonal Antibody, Cellective",
      "B-Cell Depleting Anti-CD22 Monoclonal Antibody, MedImmune",
    ],
  },
  {
    primary_id: "D0Q3WV",
    names: ["[125I]DIV880"],
  },
  {
    primary_id: "D0Q3WW",
    names: ["BB-15600"],
  },
  {
    primary_id: "D0Q3XB",
    names: ["PUNICAFOLIN"],
  },
  {
    primary_id: "D0Q3XK",
    names: ["LONGANINE"],
  },
  {
    primary_id: "D0Q3XN",
    names: ["KR-31360"],
  },
  {
    primary_id: "D0Q3YO",
    names: [
      "Cinnarizine",
      "Aplactan",
      "Aplexal",
      "Apotomin",
      "Artate",
      "Carecin",
      "Cerebolan",
      "Cinaperazine",
      "Cinarizina",
      "Cinarizine",
      "Cinazyn",
      "Cinnacet",
      "Cinnageron",
      "Cinnarizinum",
      "Corathiem",
      "Denapol",
      "Dimitron",
      "Dimitronal",
      "Eglen",
      "Folcodal",
      "Giganten",
      "Glanil",
      "Hilactan",
      "Ixertol",
      "Katoseran",
      "Labyrin",
      "Lazeta",
      "Libotasin",
      "Marisan",
      "Midronal",
      "Mitronal",
      "Olamin",
      "Processine",
      "Sedatromin",
      "Senoger",
      "Sepan",
      "Siptazin",
      "Spaderizine",
      "Stugeron",
      "Stutgeron",
      "Stutgin",
      "Toliman",
      "Votegol",
      "Zepamol",
      "Cinnarizine Stugeron",
      "C 5270",
      "MD 516",
      "R 1575",
      "R 516",
      "Cero-Aterin",
      "Cinarizina [INN-Spanish]",
      "Cinnarizinum [INN-Latin]",
      "R-516",
      "Stugeron (TN)",
      "Stunarone (TN)",
      "Cinnarizine (JAN/USAN/INN)",
      "Cinnarizine [USAN:INN:BAN:JAN]",
      "N-Benzhydryl-N'-Cinnamylpiperazine",
      "1-(Diphenylmethyl)-4-(3-Phenyl-2-Propenyl)Piperazine",
      "1-(Diphenylmethyl)-4-(3-Phenyl-2-Propenyl)-Piperazine",
      "1-(Diphenylmethyl)-4-[(2E)-3-Phenylprop-2-En-1-Yl]piperazine",
      "1-Benzhydryl-4-Cinnamylpiperazin",
      "1-Cinnamyl-4-(Diphenylmethyl)Piperazine",
      "1-Benzhydryl-4-[(E)-3-Phenylprop-2-Enyl]piperazine",
      "1-Trans-Cinnamyl-4-Diphenylmethylpiperazine",
    ],
  },
  {
    primary_id: "D0Q3ZN",
    names: ["GS-3435"],
  },
  {
    primary_id: "D0Q4AQ",
    names: ["6-Bromo-8-(Piperazin-1-Yl)Imidazo[1,2-A]pyrazine", "CHEMBL609595", "BDBM50304813"],
  },
  {
    primary_id: "D0Q4AR",
    names: ["Glucagon-Like Peptide-1 Analog"],
  },
  {
    primary_id: "D0Q4AV",
    names: [
      "N-(Quinolin-6-Yl)-6-(Sulfamoylamino)Hexanamide",
      "CHEMBL454438",
      "SCHEMBL5359458",
      "CGIYOYXFUHZSMD-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0Q4BI",
    names: ["T-2"],
  },
  {
    primary_id: "D0Q4BK",
    names: ["PD-135222"],
  },
  {
    primary_id: "D0Q4BQ",
    names: ["SA-20896"],
  },
  {
    primary_id: "D0Q4BX",
    names: ["TA-111"],
  },
  {
    primary_id: "D0Q4CQ",
    names: ["Viagenpumatucel-L"],
  },
  {
    primary_id: "D0Q4CS",
    names: ["Cevimeline"],
  },
  {
    primary_id: "D0Q4CT",
    names: [
      "2'-Hydroxy-3,4,5-Trimethoxychalcone",
      "Crotaoprostrin",
      "CHEMBL521653",
      "2'-Hydroxy-3,4,5-Methoxychalcone",
      "(E)-1-(2-Hydroxyphenyl)-3-(3,4,5-Trimethoxyphenyl)Prop-2-En-1-One",
      "(2E)-1-(2-Hydroxyphenyl)-3-(3,4,5-Trimethoxyphenyl)Prop-2-En-1-One",
      "59817-22-0",
      "142955-68-8",
      "NSC331924",
      "AC1NWOUH",
      "MLS001049027",
      "SCHEMBL5423339",
      "MolPort-000-450-785",
      "SRSBUHVXNLHWHU-CMDGGOBGSA-N",
      "HMS2268N22",
      "ZINC4042476",
      "ZX-AT026929",
      "LMPK12120198",
      "STK888527",
      "BDBM50257726",
      "AKOS002181958",
      "NSC-331924",
      "NSC 331924",
      "2''-Hydroxy-3,4,5-Trimethoxychalcone",
      "SMR000387055",
    ],
  },
  {
    primary_id: "D0Q4CX",
    names: ["MLN-3126", "CCR9 Antagonist (Oral, Crohn's Disease), Millennium"],
  },
  {
    primary_id: "D0Q4DB",
    names: ["N-Docosatetra-7,10,13,16-Enoylethanolamine"],
  },
  {
    primary_id: "D0Q4DO",
    names: [
      "Dipeptidyl Peptidase IV Inhibitors",
      "Dipeptidyl Peptidase IV Inhibitors (Type 2 Diabetes)",
      "Dipeptidyl Peptidase IVinhibitors (Type 2 Diabetes), Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D0Q4DP",
    names: ["L-750,667"],
  },
  {
    primary_id: "D0Q4DS",
    names: ["AC Vaccine"],
  },
  {
    primary_id: "D0Q4DT",
    names: ["HuMax-TAC", "Anti-IL-2R Antibody, Genmab/Merck Serono"],
  },
  {
    primary_id: "D0Q4EG",
    names: ["NQN-1"],
  },
  {
    primary_id: "D0Q4EJ",
    names: ["Anti-EGFR V III CAR-T Cells"],
  },
  {
    primary_id: "D0Q4EQ",
    names: ["JNJ-38158471"],
  },
  {
    primary_id: "D0Q4EU",
    names: ["BADGE"],
  },
  {
    primary_id: "D0Q4EW",
    names: ["Lithium Citrate"],
  },
  {
    primary_id: "D0Q4FA",
    names: ["PR-P"],
  },
  {
    primary_id: "D0Q4FH",
    names: ["L-748780"],
  },
  {
    primary_id: "D0Q4GB",
    names: ["Geraniol"],
  },
  {
    primary_id: "D0Q4GH",
    names: ["(E)-3-(4-(4-Phenoxybenzoyl)Phenyl)Acrylic Acid", "CHEMBL201736"],
  },
  {
    primary_id: "D0Q4GJ",
    names: ["2-Pyridin-3-Yl-Benzo[h]chromen-4-One"],
  },
  {
    primary_id: "D0Q4GL",
    names: ["RAVAX"],
  },
  {
    primary_id: "D0Q4GT",
    names: ["PM-181108"],
  },
  {
    primary_id: "D0Q4HA",
    names: ["EDG-007"],
  },
  {
    primary_id: "D0Q4HQ",
    names: ["MRCT10.v362"],
  },
  {
    primary_id: "D0Q4HW",
    names: ["ISIS 329956"],
  },
  {
    primary_id: "D0Q4IE",
    names: ["ARX-618"],
  },
  {
    primary_id: "D0Q4IM",
    names: ["JWH-237"],
  },
  {
    primary_id: "D0Q4IR",
    names: [
      "Atherosclerosis/Rheumatoid Arthritis Agents",
      "Atherosclerosis/Rheumatoid Arthritis Agents (Sustained Release/CTP)",
    ],
  },
  {
    primary_id: "D0Q4IX",
    names: ["GDC-0994"],
  },
  {
    primary_id: "D0Q4JF",
    names: ["Phosphocholine"],
  },
  {
    primary_id: "D0Q4JM",
    names: ["TAK-441"],
  },
  {
    primary_id: "D0Q4LG",
    names: ["IT-139"],
  },
  {
    primary_id: "D0Q4LI",
    names: [
      "Furan-3-Yl(4-(Methylsulfonyl)Phenyl)Methanone",
      "CHEMBL1240537",
      "3-(P-Methylsulfonylbenzoyl)Furan",
      "1244038-62-7",
    ],
  },
  {
    primary_id: "D0Q4LX",
    names: ["N-(2-Amino-5-(Benzofuran-2-Yl)Phenyl)Benzamide", "CHEMBL1097063"],
  },
  {
    primary_id: "D0Q4MO",
    names: ["ONX-0914"],
  },
  {
    primary_id: "D0Q4NJ",
    names: ["MSC2156119"],
  },
  {
    primary_id: "D0Q4NX",
    names: ["FANG"],
  },
  {
    primary_id: "D0Q4OB",
    names: ["Carbamate Derivative 11"],
  },
  {
    primary_id: "D0Q4OV",
    names: ["PF530"],
  },
  {
    primary_id: "D0Q4OW",
    names: ["Pixantrone"],
  },
  {
    primary_id: "D0Q4PB",
    names: ["MOL 6131"],
  },
  {
    primary_id: "D0Q4PQ",
    names: [
      "WR-089120",
      "1-(3-Nitrophenyl)-3-Phenylprop-2-En-1-One",
      "16619-21-9",
      "3'-Nitrochalcone",
      "CHEMBL187682",
      "NSC186962",
      "AC1NWTRO",
      "(2E)-1-(3-Nitrophenyl)-3-Phenylprop-2-En-1-One",
      "2-Propen-1-One, 1-(3-Nitrophenyl)-3-Phenyl-",
      "SCHEMBL9779522",
      "DTXSID60420806",
      "MolPort-000-653-504",
      "ZINC4159317",
      "BDBM50154604",
      "SBB055406",
      "5324AD",
      "AKOS002346621",
      "NSC-186962",
      "ACM16619219",
      "KB-147050",
      "(E)-1-(3-Nitro-Phenyl)-3-Phenyl-Propenone",
    ],
  },
  {
    primary_id: "D0Q4PY",
    names: ["RWJ-68157"],
  },
  {
    primary_id: "D0Q4QB",
    names: ["PMID29130358-Compound-Figure13(4)"],
  },
  {
    primary_id: "D0Q4QF",
    names: ["MANZAMINE A"],
  },
  {
    primary_id: "D0Q4QV",
    names: ["TPIV100"],
  },
  {
    primary_id: "D0Q4SD",
    names: ["Eplerenone"],
  },
  {
    primary_id: "D0Q4SJ",
    names: ["ISIS 134607"],
  },
  {
    primary_id: "D0Q4ST",
    names: ["Diazepinone Derivative 3"],
  },
  {
    primary_id: "D0Q4TA",
    names: ["ARP100", "ARP 100", "ARP-100"],
  },
  {
    primary_id: "D0Q4TK",
    names: ["Morantel Tartrate"],
  },
  {
    primary_id: "D0Q4TW",
    names: [
      "SCY-644",
      "Cyclophilin Inhibitors (HIV, Hepatitis C Virus Infection)",
      "Cyclophilin Inhibitors (HIV, Hepatitis C Virus Infection), SCYNEXIS",
    ],
  },
  {
    primary_id: "D0Q4UA",
    names: ["Z-Arg-Leu-Val-Agly-Ile-Val-OMe"],
  },
  {
    primary_id: "D0Q4UD",
    names: ["(E)-5-Nitrooctadec-5-Enoic Acid", "CHEMBL561776", "(E)-5-Nitro-5-Octadecenoic Acid", "BDBM50295050"],
  },
  {
    primary_id: "D0Q4US",
    names: ["FR-144420"],
  },
  {
    primary_id: "D0Q4UY",
    names: ["CFC-222", "Ecenofloxacin"],
  },
  {
    primary_id: "D0Q4VE",
    names: [
      "15R-TIC",
      "GTPL5864",
      "5-[(3aS,5R,6R,6aS)-5-Hydroxy-6-[(E,3R)-3-Hydroxy-4-(3-Methylphenyl)But-1-Enyl]-1,3a,4,5,6,6a-Hexahydropentalen-2-Yl]pentanoic Acid",
      "4beta-[(R,E)-3-Hydroxy-4-(3-Methylphenyl)-1-Butenyl]-5alpha-Hydroxy-3,3abeta,4,5,6,6abeta-Hexahydropentalene-2-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0Q4VG",
    names: ["4-(1-Butyl-7-Chloro-1H-Indazol-3-Yl)Phenol"],
  },
  {
    primary_id: "D0Q4VO",
    names: [
      "SODIUM PHOSPHATE, DIBASIC, ANHYDROUS",
      "7558-79-4",
      "DISODIUM PHOSPHATE",
      "Disodium Hydrogen Phosphate",
      "Disodium Hydrogenorthophosphate",
      "Sodium Phosphate Dibasic",
      "Acetest",
      "Soda Phosphate",
      "Disodium Orthophosphate",
      "Sodium Hydrogenphosphate",
      "Sodium Hydrogen Phosphate",
      "Phosphoric Acid, Disodium Salt",
      "Disodium Acid Phosphate",
      "Phosphate Of Soda",
      "Sec-Sodium Phosphate",
      "Disodium Acid Orthophosphate",
      "Sodium Monohydrogen Phosphate",
      "Caswell No. 778",
      "Disodium Hydrophosphate",
      "Disodium Monohydrogen Phosphate",
      "Disodium Phosphoric Acid",
      "FEMA Number 2398",
    ],
  },
  {
    primary_id: "D0Q4WA",
    names: ["PMID25991433-Compound-O3"],
  },
  {
    primary_id: "D0Q4WG",
    names: ["ACT-1 Topical Gel"],
  },
  {
    primary_id: "D0Q4WR",
    names: [
      "Angiotensin Therapeutic Vaccine",
      "Angiotensin Therapeutic Vaccine, BTG",
      "Angiotensin Therapeutic Vaccine, Protherics",
      "PMD-2849",
      "PMD-2850",
      "PMD-2851",
      "PMD-2852",
      "PMD-2853",
      "PMD-3117",
    ],
  },
  {
    primary_id: "D0Q4XG",
    names: ["MOD-3012"],
  },
  {
    primary_id: "D0Q4XQ",
    names: [
      "Ethosuximide",
      "Aethosuccimidum",
      "Aethosuximide",
      "Asamid",
      "Atysmal",
      "Capitus",
      "Emeside",
      "Ethosuccimid",
      "Ethosuccimide",
      "Ethosuccinimide",
      "Ethosuxide",
      "Ethosuximidum",
      "Ethylmethylsuccimide",
      "Ethymal",
      "Etomal",
      "Etosuccimide",
      "Etosuximid",
      "Etosuximida",
      "Etosuximide",
      "Mesentol",
      "Pemal",
      "Pemalin",
      "Pentinimid",
      "Peptinimid",
      "Petinimid",
      "Petnidan",
      "Piknolepsin",
      "Pyknolepsinum",
      "Ronton",
      "Simatin",
      "Succimal",
      "Succimitin",
      "Suksilep",
      "Suxilep",
      "Suximal",
      "Suxin",
      "Suxinutin",
      "Thetamid",
      "Thilopemal",
      "Zaraondan",
      "Zarodan",
      "Zarondan",
      "Zarontin",
      "Zartalin",
      "Aethosuximide [German]",
      "Desitin Brand Of Ethosuximide",
      "Epileo Petit MAL",
      "Etosuccimide [DCIT]",
      "Etosuximida Faes",
      "Faes Brand Of Ethosuximide",
      "Fortbenton Brand Of Ethosuximide",
      "Jenapharm Brand Of Ethosuximide",
      "Katwijk Brand Of Ethosuximide",
      "LAB Brand Of Ethosuximide",
      "Parke Davis Brand Of Ethosuximide",
      "Pfizer Brand Of Ethosuximide",
      "United Drug Brand Of Ethosuximide",
      "Warner Lambert Brand Of Ethosuximide",
      "Wernigerode Brand Of Ethosuximide",
      "Cl 366",
      "E 7138",
      "E0746",
      "H 940",
      "PM 671",
      "CN-10395",
      "Ethosuximidum [INN-Latin]",
      "Etosuximida [INN-Spanish]",
      "Faes, Etosuximida",
      "H-490",
      "N-Ethyl Methylsuccinimide",
      "PM-671",
      "Simatin(E)",
      "Warner-Lambert Brand Of Ethosuximide",
      "Zarondan-Saft",
      "Zarontin (TN)",
      "C.I. 366",
      "CN-10,395",
      "Piknole.psi.n",
      "Pyknole.psi.num",
      "Alpha-Ethyl-Alpha-Methylsuccinimide",
      "Alpha-Methyl-Alpha-Ethylsuccinimide",
      "Ethosuximide (JP15/USP/INN)",
      "Ethosuximide [USAN:INN:BAN:JAN]",
      "Gamma-Methyl-Gamma-Ethylsuccinimide",
      "Gamma-Methyl-Gamma-Ethyl-Succinimide",
      "Gamma-Ethyl-Gamma-Methyl-Succinimide",
      "(+-)-2-Ethyl-2-Methylsuccinimide",
      "2-Ethyl-2-Methylsuccinimide",
      "2-Methyl-2-Ethylsuccinimide",
      "3-Ethyl-3-Methyl-2, 5-Pyrrolidinedion",
      "3-Ethyl-3-Methyl-2,5-Pyrrolidinedione",
      "3-Ethyl-3-Methylpyrrolidine-2,5-Dione",
      "3-Ethyl-3-Methylpyrroline-2,5-Dione",
      "3-Ethyl-3-Methylsuccinimide",
      "3-Methyl-3-Ethylpyrrolidine-2,5-Dione",
      "3-Methyl-3-Ethylsuccinimide",
    ],
  },
  {
    primary_id: "D0Q4XV",
    names: ["Indoline Derivative 19"],
  },
  {
    primary_id: "D0Q4YI",
    names: ["Trimetazidine"],
  },
  {
    primary_id: "D0Q4YK",
    names: ["Piracetam"],
  },
  {
    primary_id: "D0Q4YP",
    names: ["TAK-100", "CHEMBL1672127"],
  },
  {
    primary_id: "D0Q4YR",
    names: ["JWH-296"],
  },
  {
    primary_id: "D0Q4YY",
    names: ["Z-Arg-Leu-Val-Agly-Ileu-Val-OMe"],
  },
  {
    primary_id: "D0Q5AD",
    names: ["PROTOSTEMODIOL"],
  },
  {
    primary_id: "D0Q5AJ",
    names: [
      "MCL-154",
      "CHEMBL151872",
      "BDBM50135811",
      "(1R,9R,10R)-17-(Cyclobutylmethyl)-4-[(10-{[(1R,9R,10R)-17-(Cyclobutylmethyl)-17-Azatetracyclo[7.5.3.0^{1,10}.0^{2,7}]heptadeca-2(7),3,5-Trien-4-Yl]oxy}decyl)Oxy]-17-Azatetracyclo[7.5.3.0^{1,10}.0^{2,7}]heptadeca-2(7),3,5-Triene",
    ],
  },
  {
    primary_id: "D0Q5AS",
    names: [
      "DUP-860",
      "Alpha-(4-Chlorophenyl)-Alpha-[1-(2-Chlorophenyl)Ethenyl]-1H-1,2,4-Triazole-1-Ethanol Methanesulfonate",
      "3-(2-Chlorophenyl)-2-(4-Chlorophenyl)-1-(1H-1,2,4-Triazol-1-Yl)-3-Buten-2-Ol Methanesulfonate",
    ],
  },
  {
    primary_id: "D0Q5AT",
    names: ["QVA-149"],
  },
  {
    primary_id: "D0Q5BK",
    names: ["HGT-1410"],
  },
  {
    primary_id: "D0Q5BU",
    names: ["MSH-1001", "DNB-001", "KR-31378", "KR-31378)", "K+ Channel Activating Compounds, Dongbu/KRICT"],
  },
  {
    primary_id: "D0Q5CH",
    names: [
      "AP-301-IH",
      "AP-301",
      "AP-301-IRI",
      "AP-301-PN",
      "AP-301-PN)",
      "Peptide TNF Alpha Analog (Pneumonia), Apeptico",
      "Peptide TNF Alpha Modulator (Edema In Acute Lung Injury), Apeptico/Medical College Of Georgia",
      "Peptide TNF Alpha Modulator (Ischemia/Reperfusion Injury), Apeptico",
    ],
  },
  {
    primary_id: "D0Q5CS",
    names: ["6-Guanidino-2-(3''-Indolylethyloxy)Adenosine"],
  },
  {
    primary_id: "D0Q5DI",
    names: ["Nexturastat A"],
  },
  {
    primary_id: "D0Q5DT",
    names: ["IM156"],
  },
  {
    primary_id: "D0Q5EH",
    names: ["Anti-CD19 CAR-T Cells"],
  },
  {
    primary_id: "D0Q5FJ",
    names: ["8-Phenyl-2-Piperidin-1-Yl-Chromen-4-One", "CHEMBL176064", "SCHEMBL259239"],
  },
  {
    primary_id: "D0Q5FS",
    names: ["Pyrazole Derivative 18"],
  },
  {
    primary_id: "D0Q5FT",
    names: ["Fibroblast Growth Factor"],
  },
  {
    primary_id: "D0Q5FV",
    names: [
      "2-Sulfhydryl-Ethanol",
      "Beta-Mercaptoethanol",
      "2-Mercaptoethanol",
      "60-24-2",
      "Thioglycol",
      "Mercaptoethanol",
      "Ethanol, 2-Mercapto-",
      "2-Sulfanylethanol",
      "2-Thioethanol",
      "Thioethylene Glycol",
      "2-Hydroxyethanethiol",
      "2-Hydroxyethyl Mercaptan",
      "2-Hydroxy-1-Ethanethiol",
      "Thiomonoglycol",
      "Monothioglycol",
      "2-Mercapto-1-Ethanol",
      "1-Ethanol-2-Thiol",
      "Hydroxyethyl Mercaptan",
      "Monothioethylene Glycol",
      "2-Mercaptoethyl Alcohol",
      "2-ME",
      "Mercaptoetanol",
      "Ethylene Glycol, Monothio-",
      "1-Hydroxy-2-Mercaptoethane",
      "1-Mercapto-2-Hydroxyethane",
      "2-Hydroxyethylmercaptan",
      "Emery 5791",
      "USAF EK-4196",
    ],
  },
  {
    primary_id: "D0Q5GK",
    names: ["MG7-CART"],
  },
  {
    primary_id: "D0Q5GS",
    names: ["CD154"],
  },
  {
    primary_id: "D0Q5HW",
    names: ["N6-Methoxy-2-[(4-Methylphenyl)Ethynyl]adenosine", "CHEMBL426537", "SCHEMBL4386994"],
  },
  {
    primary_id: "D0Q5II",
    names: ["G009", "Beta-(1-3)-Glucan With Beta-(1-6) Side Chain Isolated From The Basidiomycetes Ganoderma Lucidum"],
  },
  {
    primary_id: "D0Q5IN",
    names: [
      "Lindane",
      "Aalindan",
      "Aficide",
      "Agrocide",
      "Agronexit",
      "Ameisenatod",
      "Ameisentod",
      "Aparasin",
      "Aphtiria",
      "Aphtitria",
      "Aplidal",
      "Arbitex",
      "BBH",
      "Benhexachlor",
      "Benhexol",
      "Benzanex",
      "Benzex",
      "Bexol",
      "Celanex",
      "Chloresene",
      "Codechine",
      "Devoran",
      "Dolmix",
      "Entomoxan",
      "Esoderm",
      "Exagama",
      "Forlin",
      "Gallogama",
      "Gamacarbatox",
      "Gamacid",
      "Gamacide",
      "Gamaphex",
      "Gamene",
      "Gamiso",
      "Gammahexa",
      "Gammahexane",
      "Gammalin",
      "Gammasan",
      "Gammaterr",
      "Gammex",
      "Gammexane",
      "Gammopaz",
      "Gamtox",
      "Geobilan",
      "Gexane",
      "Gyben",
      "HCH",
      "Hcch",
      "Heclotox",
      "Hecoltox",
      "Hexa",
      "Hexablanc",
      "Hexachlor",
      "Hexachloran",
      "Hexachlorane",
      "Hexamul",
      "Hexapoudre",
      "Hexatox",
      "Hexaverm",
      "Hexcidum",
      "Hexicide",
      "Hexit",
      "Hexyclan",
      "Hilbeech",
      "Hortex",
      "Inexit",
      "Isatox",
      "Isotox",
      "Jacutin",
      "Kokotine",
      "Kwell",
      "Lasochron",
      "Lendine",
      "Lentox",
      "Lidenal",
      "Lindafor",
      "Lindagam",
      "Lindagrain",
      "Lindagranox",
      "Lindalo",
      "Lindamul",
      "Lindan",
      "Lindano",
      "Lindanum",
      "Lindapoudre",
      "Lindaterra",
      "Lindatox",
      "Lindex",
      "Lindosep",
      "Lintox",
      "Linvur",
      "Lorexane",
      "Mszycol",
      "Nexit",
      "Nicochloran",
      "Novigam",
      "Novigan",
      "Omnitox",
      "Ovadziak",
      "Owadziak",
      "PLK",
      "Pedraczak",
      "Pflanzol",
      "Quellada",
      "Scabene",
      "Silvanol",
      "Streunex",
      "Submar",
      "Viton",
      "Agrocide III",
      "Agrocide WP",
      "Am Eisenatod",
      "Arcotal S",
      "BHCor HCH",
      "Borer Spray",
      "DOL GRANULE",
      "Eso Derm",
      "Gamma BHC",
      "HCH [BSI]",
      "HCH [ISO]",
      "Hilbeec H",
      "Isot Ox",
      "Lacco HI Lin",
      "Lindam Ul",
      "Mglawik L",
      "New Kotol",
      "Nexen Fb",
      "PMS Lindane",
      "Sang Gamma",
      "Agrocide 2",
      "Agrocide6G",
      "Agrocide 7",
      "Bentox 10",
      "Detox 25",
      "Gamacide 20",
      "Gammalin 20",
      "Geolin G 3",
      "Hungaria L7",
      "Latka 666",
      "Milbol 49",
      "PS71_SUPELCO",
      "TAP 85",
      "Agrisol G-20",
      "Alpha-BHC",
      "Alpha-HCH",
      "Ben-Hex",
      "Beta-BHC",
      "Beta-HCH",
      "Beta-Lindane",
      "Beta-Isomer",
      "D-BHC",
      "Delta-BHC",
      "Delta-HCH",
      "ENT 7,796",
      "ENT 8,601",
      "ENT 9,232",
      "ENT 9,233",
      "ENT 9,234",
      "Epsilon-HCH",
      "Forst-Nexen",
      "Gamma-BHC",
      "Gamma-HCH",
      "Gamma-Col",
      "HCH (Alpha)",
      "HCH (Beta)",
      "KWELL (TN)",
      "Kwell-R",
      "Nexen-Fb",
      "Nexi T-Stark",
      "Nexit-Stark",
      "Nexol-E",
      "Sang-Gamma",
      "T-HCH",
      "TRI-6",
      "Trives-T",
    ],
  },
  {
    primary_id: "D0Q5IP",
    names: ["3-(4-Phenyl-Piperidin-1-Ylmethyl)-1H-Indole", "CHEMBL59103", "BDBM50050468", "ZINC13755886"],
  },
  {
    primary_id: "D0Q5IR",
    names: ["Pyrido[1,2-A]indole-1.-Carboxylic Acid Analog 7"],
  },
  {
    primary_id: "D0Q5JO",
    names: ["Tricyclic Indole Compound 9"],
  },
  {
    primary_id: "D0Q5JV",
    names: ["Aromatic Bicyclic Compound 5"],
  },
  {
    primary_id: "D0Q5KL",
    names: ["Coagulation Factor IX"],
  },
  {
    primary_id: "D0Q5KZ",
    names: ["SGI110"],
  },
  {
    primary_id: "D0Q5LF",
    names: [
      "BIO-1211",
      "BIO 1211",
      "187735-94-0",
      "UNII-61G4E2353I",
      "CHEMBL88478",
      "61G4E2353I",
      "BIO1211",
      "GTPL6589",
      "SCHEMBL1223367",
      "MolPort-023-276-985",
      "ZINC3930111",
      "BDBM50074661",
      "AKOS024457866",
      "L-Proline, N-(2-(4-((((2-Methylphenyl)Amino)Carbonyl)Amino)Phenyl)Acetyl)-L-Leucyl-L-Alpha-Aspartyl-L-Valyl-",
      "L-Proline, N-((4-((((2-Methylphenyl)Amino)Carbonyl)Amino)Phenyl)Acetyl)-L-Leucyl-L-Alpha-Aspartyl-L-Valyl-",
    ],
  },
  {
    primary_id: "D0Q5MC",
    names: ["PCI-34051"],
  },
  {
    primary_id: "D0Q5MQ",
    names: ["Tasimelteon"],
  },
  {
    primary_id: "D0Q5MU",
    names: ["R-7025"],
  },
  {
    primary_id: "D0Q5NX",
    names: ["Lenalidomide"],
  },
  {
    primary_id: "D0Q5NZ",
    names: ["BP-100-1.02"],
  },
  {
    primary_id: "D0Q5OC",
    names: ["AGS-003"],
  },
  {
    primary_id: "D0Q5PC",
    names: [
      "PHENCYCLIDINE",
      "1-(1-Phenylcyclohexyl)Piperidine",
      "Angel Dust",
      "Dust",
      "PCP (Anesthetic)",
      "Fenciclidina",
      "Phencyclidinum",
      "Supergrass",
      "Cadillac",
      "Surfer",
      "Crystal",
      "Tranks",
      "Whack",
      "Zombie Dust",
      "Magic Mist",
      "Busy Bee",
      "Piperidine, 1-(1-Phenylcyclohexyl)-",
      "Elephant Tranquilizer",
      "1977/10/1",
      "Superweed",
      "Superjoint",
      "Cycline",
      "Sherman",
      "Cyclones",
      "Scuffle",
      "Snorts",
      "Cl-395",
      "Sheets",
      "Selma",
      "Peace",
      "Aurora Borealis",
      "Embalming Fluid",
      "Pits",
      "Goon",
      "Mist",
      "Crystal Joints",
      "Wobble Weed",
      "Rocket Fuel",
      "Horse Tracks",
      "Whacky Weed",
      "Monkey Dust",
      "Killer Weed",
      "Peace Weed",
      "Peace Pill",
    ],
  },
  {
    primary_id: "D0Q5PL",
    names: [
      "INDOPROFEN",
      "Dexindoprofen",
      "UNII-004T8726AU",
      "53086-13-8",
      "004T8726AU",
      "(+)-Indoprofen",
      "Dexindoprofenum",
      "Dexindoprofene",
      "Dexindoprofeno",
      "Dexindoprofen [INN]",
      "Dexindoprofenum [INN-Latin]",
      "Dexindoprofene [INN-French]",
      "Dexindoprofeno [INN-Spanish]",
      "Indoprofen (+)-",
      "EINECS 258-351-2",
      "AC1Q5QZO",
      "AC1L2A9M",
      "ZINC391",
      "SCHEMBL286896",
      "(+)-(S)-4-(1-Oxo-2-Isoindolinyl)Hydratropasaeure",
      "CHEMBL2106252",
      "AJ-07888",
      "(+)-(S)-P-(1-Oxo-2-Isoindolinyl)Hydratropic Acid",
      "UNII-CPE46ZU14N Component RJMIEHBSYVWVIN-NSHDSACASA-N",
    ],
  },
  {
    primary_id: "D0Q5PQ",
    names: ["FOLITENOL"],
  },
  {
    primary_id: "D0Q5QC",
    names: ["EMD-534085"],
  },
  {
    primary_id: "D0Q5QE",
    names: ["AN-10"],
  },
  {
    primary_id: "D0Q5QF",
    names: ["Desferrithiocin", "DFT", "(S)-Desferrithiocin"],
  },
  {
    primary_id: "D0Q5QV",
    names: ["MVT-5873"],
  },
  {
    primary_id: "D0Q5RA",
    names: [
      "CVP S Aureus Vaccine",
      "CVP S Aureus Vaccine, Axis Genetics",
      "Vaccine (S Aureus)",
      "Vaccine (S Aureus), Axis",
      "Vaccine (Staphylococcus Aureus), Axis",
    ],
  },
  {
    primary_id: "D0Q5RG",
    names: [
      "1-(1-(Thiophen-2-Yl)Ethylidene)Thiosemicarbazide",
      "CHEMBL401925",
      "1-(2-Thienyl)Ethanone Thiosemicarbazone",
      "AC1NT9MW",
      "ZINC4603414",
      "BDBM50376204",
      "AKOS006242416",
      "AKOS002388583",
      "1-(2-Thienyl)Ethanonethiosemicarbazone",
      "[(Z)-1-Thiophen-2-Ylethylideneamino]thiourea",
    ],
  },
  {
    primary_id: "D0Q5SI",
    names: ["SHP611"],
  },
  {
    primary_id: "D0Q5SL",
    names: [
      "BRL54443",
      "57477-39-1",
      "BRL 54443",
      "BRL-54443",
      "3-(1-Methylpiperidin-4-Yl)-1H-Indol-5-Ol",
      "UNII-Q2DH1CHI0Y",
      "Q2DH1CHI0Y",
      "1H-Indol-5-Ol, 3-(1-Methyl-4-Piperidinyl)-",
      "5-Hydroxy-3-(1-Methylpiperidin-4-Yl)-1H-Indole",
      "3-(1-METHYL-4-PIPERIDINYL)-1H-INDOL-5-OL",
      "AK163730",
      "NCGC00015134-01",
      "Tocris-1129",
      "Lopac-B-173",
      "AC1L1DOE",
      "Biomol-NT_000107",
      "Lopac0_000207",
      "ZINC7339",
      "BPBio1_001401",
      "SCHEMBL3546728",
      "GTPL3927",
      "CHEMBL1371156",
      "CTK6I2277",
      "CHEBI:92321",
      "KS-00000SOT",
      "QCR-209",
      "DTXSID40206089",
      "WKNFADCGOAHBPG-UHFFFAOYSA-N",
      "MolPort-021-804-935",
      "BCPP000316",
    ],
  },
  {
    primary_id: "D0Q5SP",
    names: ["Sodium Phenylacetate/Sodium Benzoate", "Ammonolect", "Ammonul"],
  },
  {
    primary_id: "D0Q5SV",
    names: ["Alpha.beta-Methylene-2-Thio-UDP", "Alpha,beta-Methylene-UDP"],
  },
  {
    primary_id: "D0Q5SX",
    names: ["HMaxi-K"],
  },
  {
    primary_id: "D0Q5TH",
    names: ["XR-5944"],
  },
  {
    primary_id: "D0Q5TP",
    names: ["Anti-Sortase Mab", "Anti-Sortase MAb (Staphylococcus Infections)"],
  },
  {
    primary_id: "D0Q5TW",
    names: ["TT-112"],
  },
  {
    primary_id: "D0Q5UH",
    names: ["SB-505124"],
  },
  {
    primary_id: "D0Q5UM",
    names: [
      "Subalin",
      "172451-91-1",
      "IFN (Alpha-2), NPO Vector",
      "Alfa-2 Interferon, NPO Vector",
      "Alfa-2 Interferon, SRC VB Vector",
      "Alpha-2 Interferon, NPO Vector",
      "Alpha-2 Interferon, SRC VB Vector",
    ],
  },
  {
    primary_id: "D0Q5UQ",
    names: ["Exjade", "Deferasirox"],
  },
  {
    primary_id: "D0Q5VB",
    names: ["4-(Methylhydrazino)-3-Pyridinesulfonamide", "CHEMBL1164878", "BDBM50320511"],
  },
  {
    primary_id: "D0Q5VC",
    names: ["BAN-2203", "Beta Amyloid Modulator (Alzheimer's Disease), BioArctic"],
  },
  {
    primary_id: "D0Q5VW",
    names: [
      "L-778123",
      "183499-57-2",
      "UNII-31ZXM8ZKQ3",
      "4-[(5-{[4-(3-CHLOROPHENYL)-3-OXOPIPERAZIN-1-YL]METHYL}-1H-IMIDAZOL-1-YL)METHYL]BENZONITRILE",
      "31ZXM8ZKQ3",
      "CHEMBL279433",
      "4-((5-((4-(3-Chlorophenyl)-3-Oxopiperazin-1-Yl)Methyl)-1H-Imidazol-1-Yl)Methyl)Benzonitrile",
      "L-778123 Free Base",
      "4-[[5-[[4-(3-Chlorophenyl)-3-Oxopiperazin-1-Yl]methyl]imidazol-1-Yl]methyl]benzonitrile",
      "4-[(5-{[4-(3-Chlorophenyl)-3-Oxopiperazin-1-Yl]methyl}imidazol-1-Yl)Methyl]benzonitrile",
      "SCHEMBL1683541",
      "AC1L50Q0",
      "CTK7C7787",
      "1s63",
      "1s64",
      "BDBM50097071",
      "ZINC53070632",
    ],
  },
  {
    primary_id: "D0Q5WB",
    names: ["L-269,289"],
  },
  {
    primary_id: "D0Q5XD",
    names: ["5'-Guanosine-Diphosphate-Monothiophosphate"],
  },
  {
    primary_id: "D0Q5XM",
    names: ["GSK-188909"],
  },
  {
    primary_id: "D0Q5XX",
    names: [
      "Eicosapentaenoic Acid/Docosa-Hexaenoic Acid",
      "Docosahexaenoic Acid",
      "Doconexent",
      "Cervonic Acid",
      "6217-54-5",
      "All-Cis-DHA",
      "Doconexentum",
      "Doconexento",
      "Doxonexent",
      "(4Z,7Z,10Z,13Z,16Z,19Z)-Docosa-4,7,10,13,16,19-Hexaenoic Acid",
      "AquaGrow Advantage",
      "All-Z-Docosahexaenoic Acid",
      "Martek DHA HM",
      "Ropufa 60",
      "Cis-4,7,10,13,16,19-Docosahexaenoic Acid",
      "Docosahexaenoate",
      "UNII-ZAD9OKH9JC",
      "(4Z,7Z,10Z,13Z,16Z,19Z)-Docosahexaenoic Acid",
      "Docosahexaenoic Acid (All-Z)",
      "CCRIS 7670",
      "All-Cis-4,7,10,13,16,19-Docosahexaenoic Acid",
      "ZAD9OKH9JC",
      "All-Cis-Docosa-4,7,10,13,16,19-Hexaenoic Acid",
      "CHEMBL367149",
      "Espanova (TN)",
      "DOCOSAHEXAENOIC ACID",
    ],
  },
  {
    primary_id: "D0Q5YF",
    names: ["Cipralisant", "Cipralisant [INN]", "5-[(1R,2R)-2-(5,5-Dimethylhex-1-Ynyl)Cyclopropyl]-1H-Imidazole"],
  },
  {
    primary_id: "D0Q5YH",
    names: ["NGD-94-4"],
  },
  {
    primary_id: "D0Q5YJ",
    names: ["PMID25553724-Compound-US2012819831110"],
  },
  {
    primary_id: "D0Q5YP",
    names: ["Rapamycin Immunosuppressant Drug"],
  },
  {
    primary_id: "D0Q5ZN",
    names: ["Seprilose"],
  },
  {
    primary_id: "D0Q5ZZ",
    names: ["3-(3-Benzyl-6-Methoxynaphthalen-2-Yl)Pyridine", "CHEMBL523196"],
  },
  {
    primary_id: "D0Q6AD",
    names: ["4'-(5-Chloro-2-Thienyl)Biphenyl-3-Ol", "CHEMBL1099312", "BDBM50318418"],
  },
  {
    primary_id: "D0Q6AE",
    names: ["EPI-288"],
  },
  {
    primary_id: "D0Q6AU",
    names: ["NEO-PV-01"],
  },
  {
    primary_id: "D0Q6BG",
    names: ["DAU-6202", "131780-83-1"],
  },
  {
    primary_id: "D0Q6BH",
    names: ["Agriflu", "Agrippal"],
  },
  {
    primary_id: "D0Q6BJ",
    names: ["3-(6-Methoxynaphthalen-2-Yl)-5-Phenylpyridine", "CHEMBL500393"],
  },
  {
    primary_id: "D0Q6CF",
    names: ["C[L-Tyr-D-Pro-L-Phe-D-Trp]", "CHEMBL506217"],
  },
  {
    primary_id: "D0Q6CJ",
    names: ["2-(3-Bromo-Phenyl)-6-Nitro-Chromen-4-One", "CHEMBL64792", "3'-Bromo-6-Nitroflavone"],
  },
  {
    primary_id: "D0Q6DV",
    names: ["Metallo-SAHA"],
  },
  {
    primary_id: "D0Q6DX",
    names: ["Triacetin"],
  },
  {
    primary_id: "D0Q6EA",
    names: ["SCH-1450206"],
  },
  {
    primary_id: "D0Q6ED",
    names: ["ASP8497"],
  },
  {
    primary_id: "D0Q6EE",
    names: ["3-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL244796", "SCHEMBL4296051"],
  },
  {
    primary_id: "D0Q6EH",
    names: ["SBT-20"],
  },
  {
    primary_id: "D0Q6EK",
    names: ["7alpha-NHEt-Ginkgolide B", "7alpha-NHEt-GB"],
  },
  {
    primary_id: "D0Q6EN",
    names: [
      "AHR-16303B",
      "Ahr-16303B",
      "Ahr 16303B",
      "117023-62-8",
      "1-(4-(3-(4-(Bis-(4-Fluorophenyl)Hydroxymethyl)-1-Piperidinyl)Propoxy)Phenyl)-2-Methyl-1-Propanone Ethanedioate",
      "ACMC-20mmz9",
      "AC1L4DL2",
      "CTK0H5536",
      "DTXSID80151685",
      "1-[4-[3-[4-[bis(4-Fluorophenyl)-Hydroxymethyl]piperidin-1-Yl]propoxy]phenyl]-2-Methylpropan-1-One",
      "1-Propanone, 1-(4-(3-(4-(Bis(4-Fluorophenyl)Hydroxymethyl)-1-Piperidinyl)Propoxy)Phenyl)-2-Methyl-, Ethanedioate (1:1) (Salt)",
    ],
  },
  {
    primary_id: "D0Q6FF",
    names: ["JWH-249"],
  },
  {
    primary_id: "D0Q6FV",
    names: ["TJN-135", "Calcitonin Analog, Tsumura", "TJ-135", "Inchin-Ko-To (ICKT)"],
  },
  {
    primary_id: "D0Q6GD",
    names: [
      "WCK-1152",
      "Antibacterial Fluoroquinolones, Wockhardt",
      "WCK-1153",
      "WCK-1457",
      "WCK-783",
      "WCK-919",
      "WCK-993A",
    ],
  },
  {
    primary_id: "D0Q6GK",
    names: ["EphB4-131"],
  },
  {
    primary_id: "D0Q6GP",
    names: ["Isoxazoline Derivative 2"],
  },
  {
    primary_id: "D0Q6GR",
    names: [
      "Acetyl CoA Carboxylase Inhibitors, Bayer",
      "Andrimid",
      "Moiramide B",
      "Acetyl CoA Carboxylase Inhibitors (Antibacterial)",
      "Antibacterial Agents, Bayer",
      "Acetyl CoA Carboxylase Inhibitors (Antibacterial), Bayer",
      "Pyrrolidinedione Derivatives (Antibacterial), Bayer",
    ],
  },
  {
    primary_id: "D0Q6HT",
    names: ["Beta,beta-Dimethylmelatonin", "CHEMBL206472", "BDBM50188423"],
  },
  {
    primary_id: "D0Q6IF",
    names: ["Vmab + Y-90 Emab"],
  },
  {
    primary_id: "D0Q6IW",
    names: ["Rosiglitazone + Simvastatin"],
  },
  {
    primary_id: "D0Q6JK",
    names: ["Quinoxaline Derivative 3"],
  },
  {
    primary_id: "D0Q6JY",
    names: ["A-003"],
  },
  {
    primary_id: "D0Q6KO",
    names: [
      "9-(2-Hydroxypropyl)-9H-Adenine",
      "9-(2-Hydroxypropyl)Adenine",
      "1-(6-Aminopurin-9-Yl)Propan-2-Ol",
      "CHEMBL504495",
      "MJZYTEBKXLVLMY-UHFFFAOYSA-N",
      "AC1L1CKL",
      "9H-Purine-9-Ethanol, 6-Amino-.alpha.-Methyl-",
      "SCHEMBL904363",
      "CTK6A8458",
      "9-(2-Hydroxy-1-Propyl)Adenine",
      "BCP14341",
      "BDBM50257055",
      "3-(6-Amino-9-Purinyl)-2-Propanol",
      "AKOS010941288",
      "TRA0093894",
      "AN-7936",
      "1-(6-Amino-Purin-9-Yl)-Propan-2-Ol",
      "SY022843",
      "A3032",
      "4CH-024207",
      "4CH-018216",
      "1-(6-Amino-9H-Purin-9-Yl)-2-Propanol #",
      "MFCD07369451 (97+%)",
    ],
  },
  {
    primary_id: "D0Q6KV",
    names: ["SG-2274", "Anticancer Compound, Spirogen"],
  },
  {
    primary_id: "D0Q6LA",
    names: ["Levoleucovorin"],
  },
  {
    primary_id: "D0Q6MA",
    names: ["Gamma-Sarcoglycan Gene-Containing Recombinant AAV1 Vector Based Therapy"],
  },
  {
    primary_id: "D0Q6MK",
    names: ["ISIS 100749"],
  },
  {
    primary_id: "D0Q6MN",
    names: [
      "4-Iodobenzo[B]Thiophene-2-Carboxamidine",
      "CHEMBL60088",
      "4-Iodo-1-Benzothiophene-2-Carboximidamide",
      "154628-42-9",
      "Benzo[b]thiophene-2-Carboximidamide, 4-Iodo-",
      "1o5b",
      "1c5x",
      "1c5q",
      "1c5n",
      "AC1L1C5F",
      "SCHEMBL4312886",
      "ZINC17086",
      "CTK0E7797",
      "DTXSID40274329",
      "BDBM50098137",
      "AKOS030587190",
      "4-Iodobenzo[b]thiophene-2-Carboximidamide",
    ],
  },
  {
    primary_id: "D0Q6MQ",
    names: ["CGEN-913", "Anti-CGEN-913 Antibody (Non-Hodgkin Lymphoma/Multiple Myeloma/Inflammation), Compugen"],
  },
  {
    primary_id: "D0Q6MS",
    names: ["4-[1-(4-Hydroxyphenyl)-2-Phenylprop-1-Enyl]phenol", "CHEMBL150461", "SCHEMBL18882768"],
  },
  {
    primary_id: "D0Q6NN",
    names: ["TAK-070"],
  },
  {
    primary_id: "D0Q6NZ",
    names: ["Oxymetholone"],
  },
  {
    primary_id: "D0Q6OL",
    names: [
      "KM-011",
      "Apo-A1 Mimetic Peptide (Atherosclerosis), KineMed",
      "Apolipoprotein-A1 Mimetic Peptide (Atherosclerosis), KineMed",
    ],
  },
  {
    primary_id: "D0Q6OS",
    names: ["Macrolides"],
  },
  {
    primary_id: "D0Q6PI",
    names: ["PHENETHYLAMINE"],
  },
  {
    primary_id: "D0Q6PR",
    names: ["PMID27977313-Compound-39"],
  },
  {
    primary_id: "D0Q6QU",
    names: ["MEDI6469"],
  },
  {
    primary_id: "D0Q6QW",
    names: ["3-Benzoyl-7-Chloro-4-Hydroxy-1H-Quinolin-2-One"],
  },
  {
    primary_id: "D0Q6RC",
    names: ["Thiazole Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0Q6RG",
    names: ["TD-1792"],
  },
  {
    primary_id: "D0Q6RH",
    names: [
      "ORM-10103",
      "488847-28-5",
      "5-Nitro-2-(2-Phenylchroman-6-Yl)Oxypyridine",
      "SCHEMBL4361661",
      "GTPL6481",
      "CTK1D1112",
      "DTXSID10591873",
      "GZONLGPIHCCJOI-UHFFFAOYSA-N",
      "NCGC00485395-01",
      "5-Nitro-2-(2-Phenylchroman-6-Yloxy)Pyridine",
      "5-Nitro-2-(2-Phenylchroman-6-Yloxy)-Pyridine",
      "Pyridine, 2-[(3,4-Dihydro-2-Phenyl-2H-1-Benzopyran-6-Yl)Oxy]-5-Nitro-",
    ],
  },
  {
    primary_id: "D0Q6RX",
    names: ["Oravax-PX"],
  },
  {
    primary_id: "D0Q6SD",
    names: ["Lamectacin"],
  },
  {
    primary_id: "D0Q6SF",
    names: ["APO-010"],
  },
  {
    primary_id: "D0Q6SH",
    names: ["4-(2-(3-Fluorophenoxy)-4-Methylphenyl)Piperidine", "CHEMBL573898"],
  },
  {
    primary_id: "D0Q6TU",
    names: ["GT2394"],
  },
  {
    primary_id: "D0Q6TW",
    names: [
      "ALN-RSV01",
      "ALN-RSV01 (Inhaled Formulation)",
      "ALN-RSV01 (Inhaled Formulation), Alnylam",
      "SiRNA (Inhaled Formulation, RSV Infection), Alnylam",
      "SiRNA (Inhaled Formulation, Respiratory Syncytial Virus Infection), Alnylam",
      "ALN-RSV01 (Inhaled Formulation), Alnylam/Kyowa Hakko Kirin",
      "ALN-RSV01 (Inhaled Formulation), Alnylam/Kyowa HakkoKirin/Cubist",
    ],
  },
  {
    primary_id: "D0Q6UT",
    names: [
      "5-Cyclohexyl-1,3,4-Oxadiazole-2(3H)-Thione",
      "5-Cyclohexyl-1,3,4-Oxadiazole-2-Thiol",
      "173589-87-2",
      "5-Cyclohexyl-[1,3,4]oxadiazole-2-Thiol",
      "CHEMBL456586",
      "1,3,4-Oxadiazole-2(3H)-Thione, 5-Cyclohexyl-",
      "BAS 09627004",
      "AC1Q7GLG",
      "AC1MWQ13",
      "MLS000719170",
      "SCHEMBL13401036",
      "CTK0A7651",
      "DTXSID20396654",
      "MolPort-000-932-255",
      "HMS1700P22",
      "HMS2732D23",
      "ZINC6675630",
      "BDBM50320725",
      "AKOS000669727",
      "MCULE-5633296933",
      "SMR000291438",
      "KB-124591",
      "5-Cyclohexyl-3H-1,3,4-Oxadiazole-2-Thione",
      "EN300-10672",
      "5-Cyclohexyl-3H-[1,3,4]oxadiazole-2-Thione",
      "K-5320",
    ],
  },
  {
    primary_id: "D0Q6VH",
    names: ["SIB-1893"],
  },
  {
    primary_id: "D0Q6VK",
    names: ["SB 272844"],
  },
  {
    primary_id: "D0Q6VL",
    names: ["Pyrrolo-Pyridazine Derivative 1"],
  },
  {
    primary_id: "D0Q6WP",
    names: ["PMID15686906C29"],
  },
  {
    primary_id: "D0Q6WX",
    names: ["CGS-15943", "104615-18-1"],
  },
  {
    primary_id: "D0Q6XB",
    names: [
      "3-Amino-4'-Amino-Trans-Stilbene",
      "3,4'-Diaminostilbene",
      "CCRIS 6786",
      "CHEMBL1171628",
      "BRN 3129972",
      "3-(2-(4-Aminophenyl)Ethenyl)Benzenamine",
      "Benzenamine, 3-(2-(4-Aminophenyl)Ethenyl)-",
      "79305-82-1",
      "3-[2-(4-Aminophenyl)Ethenyl]aniline",
      "AC1NYYMC",
      "Stilbene-3,4'-Diamine",
      "AC1Q51YO",
      "(E)-Stilbene-3,4'-Diamine",
      "SCHEMBL2774636",
      "SCHEMBL2774639",
      "3-Amino-4''-Amino-Trans-Stilbene",
      "ZINC5161777",
      "BDBM50322063",
      "AKOS030582755",
      "LS-28069",
      "3-[(E)-2-(4-Aminophenyl)Ethenyl]aniline",
      "3-13-00-00512 (Beilstein Handbook Reference)",
    ],
  },
  {
    primary_id: "D0Q6XR",
    names: ["ISIS 111084"],
  },
  {
    primary_id: "D0Q6YG",
    names: ["Alkaloidal Immunosuppresants", "CT-20202", "CT-20204", "Alkaloidal Immunosuppresants, C-TRI"],
  },
  {
    primary_id: "D0Q6YP",
    names: ["HELENALIN"],
  },
  {
    primary_id: "D0Q6YT",
    names: ["Poloxafuse-I"],
  },
  {
    primary_id: "D0Q6YW",
    names: ["Stem Cell Therapy"],
  },
  {
    primary_id: "D0Q6ZD",
    names: ["[3H]A-804598", "CHEMBL574539", "GTPL5408", "[3H]A804598", "[3H]A 804598"],
  },
  {
    primary_id: "D0Q6ZQ",
    names: ["ELND-007"],
  },
  {
    primary_id: "D0Q7AF",
    names: [
      "E-6837",
      "E 6837",
      "CHEMBL175835",
      "528859-61-2",
      "AC1OCFY6",
      "SCHEMBL1423485",
      "BDBM34143",
      "ZINC13611722",
      "AKOS032954248",
      "KB-76740",
    ],
  },
  {
    primary_id: "D0Q7AG",
    names: ["CGM097"],
  },
  {
    primary_id: "D0Q7AH",
    names: ["EP-1043"],
  },
  {
    primary_id: "D0Q7AW",
    names: ["IMIRESTAT"],
  },
  {
    primary_id: "D0Q7AZ",
    names: [
      "Plant-Derived Antibacterials",
      "ATWL-25",
      "Plant-Derived Antibacterials (Bacterial Infection)",
      "Plant-Derived Antibacterials (Bacterial Infection), Sequoia Sciences",
    ],
  },
  {
    primary_id: "D0Q7BG",
    names: ["AMG-837", "AMG 837", "AMG837"],
  },
  {
    primary_id: "D0Q7CR",
    names: ["MX-76629"],
  },
  {
    primary_id: "D0Q7DJ",
    names: ["Syringic Acid"],
  },
  {
    primary_id: "D0Q7EL",
    names: ["Kymriah"],
  },
  {
    primary_id: "D0Q7FC",
    names: ["Lurtotecan"],
  },
  {
    primary_id: "D0Q7FM",
    names: [
      "Azaperone",
      "1649-18-9",
      "Azaperon",
      "Stresnil",
      "Fluoperidol",
      "Suicalm",
      "Eucalmyl",
      "Azeperone",
      "Sedaperone Vet",
      "Azaperona",
      "Azaperonum",
      "R-1929",
      "NSC 170976",
      "Azaperonum [INN-Latin]",
      "Azaperona [INN-Spanish]",
      "1-Butanone, 1-(4-Fluorophenyl)-4-[4-(2-Pyridinyl)-1-Piperazinyl]-",
      "UNII-19BV78AK7W",
      "1-(3-(4-Fluorobenzoyl)Propyl)-4-(2-Pyridyl)Piperazine",
      "CCRIS 1586",
      "C19H22FN3O",
      "1-(4-Fluorophenyl)-4-[4-(2-Pyridinyl)-1-Piperazinyl]-1-Butanone",
      "4'-Fluoro-4-(4-(2-Pyridyl)-1-Piperazinyl)Butyrophenone",
      "EINECS 216-715-8",
      "R 1929",
      "BRN 0565491",
      "MLS003106751",
    ],
  },
  {
    primary_id: "D0Q7GG",
    names: [
      "Anthramycin",
      "Antramycin",
      "ANTHRAMYCIN",
      "Anthramycin [USAN]",
      "C16H17N3O4",
      "Anthramycin (USAN)",
      "Antramycinum [INN-Latin]",
      "Antramycine [INN-French]",
      "Antramicina [INN-Spanish]",
      "Antramycin (INN)",
      "Ro 5-9000",
      "4803-27-4",
      "Antibiotic PBA",
      "(E)-5,10,11,11a-Tetrahydro-9,11-Dihydroxy-8-Methyl-5-Oxo-1H-Pyrrolo(2,1-C)(1,4)-Benzodiazepine-2-Acrylamide",
      "AC1NSJTB",
      "AC1Q5IYM",
      "SCHEMBL4513",
      "CHEMBL39373",
      "1H-Pyrrolo(2,1-C)(1,4)Benzodiazepine-2-Acrylamide, 5,10,11,11a-Tetrahydro-9,11-Dihydroxy-8-Methyl-5-Oxo-, (E)-",
      "2-Propenamide, 3-(5,10,11,11a-Tetrahydro-9,11-Dihyd",
    ],
  },
  {
    primary_id: "D0Q7GZ",
    names: ["5-Nonyl-5-Phenyl-Imidazolidine-2,4-Dione", "CHEMBL39043"],
  },
  {
    primary_id: "D0Q7HG",
    names: ["H-Tyr-D-Ala-Gly-Phe-Pro-Leu-Trp-NH-Bzl"],
  },
  {
    primary_id: "D0Q7HO",
    names: [
      "PD-153035",
      "183322-45-4",
      "PD153035 Hydrochloride",
      "N-(3-Bromophenyl)-6,7-Dimethoxyquinazolin-4-Amine Hydrochloride",
      "PD153035 HCl",
      "PD 153035 HYDROCHLORIDE",
      "PD-153035 Hydrochloride",
      "PD153035 (Hydrochloride)",
      "Tyrphostin AG 1517",
      "AG 1517 Hydrochloride",
      "SU-5271 Hydrochloride",
      "UNII-AHJ252P69N",
      "ZM 252868",
      "SU 5271",
      "AG 1517",
      "4-[(3-BROMOPHENYL)AMINO]-6,7-DIMETHOXYQUINAZOLINE HYDROCHLORIDE",
      "AHJ252P69N",
      "Pd 153035",
      "PD153035.HCl",
      "CHEMBL1204168",
      "6,7-Dimethoxy-4-[N-(3-Bromophenyl)Amino]quinazoline Hydrochloride",
      "C16H15BrClN3O2",
      "4-Quinazolinamine, N-(3-Bromo",
    ],
  },
  {
    primary_id: "D0Q7IU",
    names: [
      "PFI-4",
      "900305-37-5",
      "PFI 4",
      "CHEMBL3356143",
      "N-[2,3-Dihydro-1,3-Dimethyl-2-Oxo-6-(1-Pyrrolidinyl)-1H-Benzimidazol-5-Yl]-2-Methoxybenzamide",
      "N-(1,3-Dimethyl-2-Oxo-6-(Pyrrolidin-1-Yl)-2,3-Dihydro-1H-Benzo[d]imidazol-5-Yl)-2-Methoxybenzamide",
      "PFI4",
      "GTPL8575",
      "EX-A823",
      "MolPort-004-921-423",
      "BCP23818",
      "ZINC6794685",
      "BDBM50032927",
      "S7906",
      "STL436496",
      "AKOS000482438",
      "CS-4866",
      "MCULE-9591292886",
      "PFI-4, &gt",
      "NCGC00476204-01",
      "HY-18664",
      "FT-0700502",
      "N-(1,3-Dimethyl-2-Oxo-6-Pyrrolidin-1-Ylbenzimidazol-5-Yl)-2-Methoxybenzamide",
    ],
  },
  {
    primary_id: "D0Q7IX",
    names: ["PF-06342674"],
  },
  {
    primary_id: "D0Q7JC",
    names: [
      "P-552-02",
      "CF-552",
      "KM-003",
      "P-552",
      "Sodium Channel Blocker (Oral, Dry Mouth/ Sjogren's Syndrome), Parion Sciences",
    ],
  },
  {
    primary_id: "D0Q7JJ",
    names: ["NA-808"],
  },
  {
    primary_id: "D0Q7LD",
    names: ["2-Methyl-2-Propanol"],
  },
  {
    primary_id: "D0Q7MA",
    names: ["S-649266", "Cephalosporin, GSK2696266"],
  },
  {
    primary_id: "D0Q7MG",
    names: ["Stearoyl CoA Desaturase-1 Inhibitors"],
  },
  {
    primary_id: "D0Q7MI",
    names: ["KPE-00001113"],
  },
  {
    primary_id: "D0Q7MQ",
    names: [
      "CP-100263",
      "3-Piperidinamine,N-[(2-Methoxyphenyl)Methyl]-2-Phenyl-, (2S,3S)-",
      "ACMC-1CCIL",
      "SCHEMBL3951618",
      "CTK4C0571",
      "L007874",
    ],
  },
  {
    primary_id: "D0Q7NB",
    names: ["842166X"],
  },
  {
    primary_id: "D0Q7NM",
    names: ["Isoxazoles And Isoxazoline Derivative 5"],
  },
  {
    primary_id: "D0Q7NZ",
    names: ["1-(Benzhydrylamino)Ethaniminium Bromide"],
  },
  {
    primary_id: "D0Q7OA",
    names: ["HydrocoDex", "Acetaminophen/Dextromethorphan"],
  },
  {
    primary_id: "D0Q7OB",
    names: ["RP-656"],
  },
  {
    primary_id: "D0Q7OE",
    names: ["BI-505"],
  },
  {
    primary_id: "D0Q7OR",
    names: ["AdHu5-ZGP", "Ebola Virus Vaccine, Defyrus"],
  },
  {
    primary_id: "D0Q7OY",
    names: [
      "2-Cyclopropanecarbonyl-Cyclohexane-1,3-Dione",
      "CHEMBL355709",
      "288258-62-8",
      "1,3-Cyclohexanedione, 2-(Cyclopropylcarbonyl)-",
      "SCHEMBL10192859",
      "BDBM50088792",
      "2-Cyclopropanecarbonylcyclohexane-1,3-Dione",
    ],
  },
  {
    primary_id: "D0Q7QA",
    names: ["PT001 GP"],
  },
  {
    primary_id: "D0Q7QK",
    names: ["Mannoside Derivative 13"],
  },
  {
    primary_id: "D0Q7QU",
    names: [
      "Alpha-Hydroxy-Midazolam",
      "1'-Hydroxymidazolam",
      "1-Hydroxymidazolam",
      "Alpha-Hydroxymidazolam",
      "1-Hydroxymethylmidazolam",
      "59468-90-5",
      "UNII-E5142BN92Z",
      "ALPHA-HYDROXY-MIDAZOLAM",
      "CHEMBL1188",
      "QHSMEGADRFZVNE-UHFFFAOYSA-N",
      "E5142BN92Z",
      "C18H13ClFN3O",
      "1-OH-Mdz",
      "8-Chloro-6-(2-Fluorophenyl)-4H-Imidazo[1,5-A][1,4]benzodiazepine-1-Methanol",
      "[8-Chloro-6-(2-Fluorophenyl)-4H-Imidazo[1,5-A][1,4]benzodiazepin-1-Yl]methanol",
      "8-Chloro-6-(2-Fluorophenyl)-4H-Imidazo(1,5-A)(1,4)Benzodiazepine-1-Methanol",
      "1'-Hydroxy Midazolam",
      "AC1L32ZB",
      "SCHEMBL7310758",
      "DTXSID50208175",
    ],
  },
  {
    primary_id: "D0Q7QY",
    names: ["SSR-125047"],
  },
  {
    primary_id: "D0Q7RA",
    names: ["Epoietin Delta", "Dynepo (TN)"],
  },
  {
    primary_id: "D0Q7RE",
    names: ["AZD-1152-HQPA", "Barasertib"],
  },
  {
    primary_id: "D0Q7RJ",
    names: ["SLP-1004"],
  },
  {
    primary_id: "D0Q7RV",
    names: ["AS04"],
  },
  {
    primary_id: "D0Q7SF",
    names: ["N-(Propylamide-Benzophenone)-1-Deoxynojirimycin", "CHEMBL590070", "BDBM50308672"],
  },
  {
    primary_id: "D0Q7SO",
    names: ["RS-66252"],
  },
  {
    primary_id: "D0Q7SP",
    names: ["Acurea"],
  },
  {
    primary_id: "D0Q7SR",
    names: ["Cervarix"],
  },
  {
    primary_id: "D0Q7SS",
    names: ["Tyr-(S)-Aba-Gly-Phe-NH2", "CHEMBL399796"],
  },
  {
    primary_id: "D0Q7ST",
    names: ["PMID12372533C20"],
  },
  {
    primary_id: "D0Q7SY",
    names: [
      "3-Hydroxypyruvic Acid",
      "Hydroxypyruvic Acid",
      "3-Hydroxy-2-Oxopropanoic Acid",
      "Hydroxypyruvate",
      "1113-60-6",
      "Beta-Hydroxypyruvate",
      "UNII-934B2KHY0S",
      "3-Hydroxypyruvate",
      "934B2KHY0S",
      "OH-Pyruvate",
      "OH-Pyr",
      "3PY",
      "4mfe",
      "Beta-Hydoxypyruvic Acid",
      "Beta-Hydroxypyruvic Acid",
      "AC1L1AFB",
      "SCHEMBL60708",
      "CTK8A1122",
      "CHEBI:30841",
      "DTXSID40149588",
      "MolPort-006-168-729",
      "ZINC1532558",
      "AKOS006378194",
      "DB02951",
      "HY-113013",
      "CS-0059352",
      "C00168",
      "J-002568",
      "Beta-Hydroxypyruvic Acid, &gt;=95.0% (Dry Substance, T)",
    ],
  },
  {
    primary_id: "D0Q7TH",
    names: [
      "BMS-181885",
      "3-[3-[3-[4-(5-Methoxypyrimidin-4-Yl)Piperazin-1-Yl]propyl]-1H-Indol-5-Ylamino]-4-Methyl-3-Cyclobutene-1,2-Dione",
    ],
  },
  {
    primary_id: "D0Q7TI",
    names: [
      "HG-1078",
      "DNA Directed RNA Polymerase II Polypeptide J Modulator (Cancer), Human Genome Sciences",
      "HRPB14 Modulator (Cancer), Human Genome Sciences",
      "POLR2J Modulator (Cancer), Human Genome Sciences",
      "POLR2J1 Modulator (Cancer), Human Genome Sciences",
      "RNA Polymerase II Subunit J Modulator (Cancer), Human Genome Sciences",
      "RPB11 Modulator (Cancer), Human Genome Sciences",
      "RPB11A Modulator (Cancer), Human Genome Sciences",
      "RPB11m Modulator (Cancer), Human Genome Sciences",
      "S Cerevisiae Homolog Of RPB11 Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0Q7TL",
    names: ["SM-122"],
  },
  {
    primary_id: "D0Q7TO",
    names: ["K00244"],
  },
  {
    primary_id: "D0Q7UD",
    names: ["ABT-888"],
  },
  {
    primary_id: "D0Q7UG",
    names: ["Tipelukast", "Asthma Therapy, MediciNova", "KCA-757", "MN-001"],
  },
  {
    primary_id: "D0Q7VI",
    names: ["Siguazodan"],
  },
  {
    primary_id: "D0Q7VU",
    names: ["N-Butyl-N'-Hydroxyguanidine"],
  },
  {
    primary_id: "D0Q7WP",
    names: ["9-[2-(1-Phosphonobutan-2-Yloxy)Ethyl]guanine"],
  },
  {
    primary_id: "D0Q7WW",
    names: ["UR-8328"],
  },
  {
    primary_id: "D0Q7XA",
    names: [
      "LG-888",
      "Coagulation Stimulator (Factor VIII Deficiency, Protein Recombinant), Lentigen",
      "Factor VIII Agonist (Factor VIII Deficiency, Protein Chimeric), Lentigen",
    ],
  },
  {
    primary_id: "D0Q7XS",
    names: ["LP-0067", "CXCR4 Antagonists (Autoimmune Disease)", "CXCR4 Antagonists (Autoimmune Disease), Leo Pharma"],
  },
  {
    primary_id: "D0Q7YI",
    names: ["Alogliptin/Pioglitazone"],
  },
  {
    primary_id: "D0Q7YU",
    names: ["17beta-HSD1 Inhibitor"],
  },
  {
    primary_id: "D0Q7YZ",
    names: ["Gantenerumab"],
  },
  {
    primary_id: "D0Q7ZF",
    names: ["Macrolactam Derivative 4"],
  },
  {
    primary_id: "D0Q7ZG",
    names: [
      "Proparacaine",
      "Alcaine",
      "Diocaine",
      "Kainair",
      "Ophthaine",
      "Ophthetic",
      "Paracaine",
      "Prossimetacaina",
      "Proximetacaina",
      "Proximetacainum",
      "Proxymetacaine",
      "Proxymetacainum",
      "Prossimetacaina [DCIT]",
      "Proxymetacaine Hcl",
      "Ak-Taine",
      "Alcaine (TN)",
      "Ocu-Caine",
      "Proximetacaina [INN-Spanish]",
      "Proxymetacaine (INN)",
      "Proxymetacaine [INN:BAN]",
      "Proxymetacainum [INN-Latin]",
      "Spectro-Caine",
      "Ak-Taine (TN)",
      "Beta-(Diethylamino)Ethyl 4-N-Propoxybenzoate",
      "BENZOIC ACID, 3-AMINO-4-PROPOXY-, 2-(DIETHYLAMINO)ETHYL ESTER",
      "2-(Diethylamino)Ethyl 3-Amino-4-Propoxybenzoate",
      "2-Diethylaminoethyl 3-Amino-4-Propoxybenzoate",
      "3-Amino-4-Propoxybenzoic Acid 2-(Diethylamino)Ethyl Ester",
    ],
  },
  {
    primary_id: "D0Q7ZQ",
    names: [
      "Succinylcholine",
      "Anectine",
      "Diacetylcholine",
      "Ditilin",
      "Ditiline",
      "Listenon",
      "Lysthenon",
      "Quelicin",
      "SCK",
      "Succicuran",
      "Succinocholine",
      "Succinoylcholine",
      "Succinylbischoline",
      "Succinyldicholine",
      "Sucostrin",
      "Suxamethonium",
      "Dicholine Succinate",
      "Succinyl Choline",
      "Anectine (TN)",
      "Scoline (TN)",
      "Succinic Acid, Diester With Choline",
      "Succinic Acid, Diester With Choline Chloride",
      "Choline, Succinate (Ester)",
      "Choline, Succinate (2:1) (Ester)",
      "Trimethyl-[2-[4-Oxo-4-(2-Trimethylazaniumylethoxy)Butanoyl]oxyethyl]azanium Diio",
      "Trimethyl-[2-[4-Oxo-4-[2-(Trimethylazaniumyl)Ethoxy]butanoyl]oxyethyl]azanium",
      "2,2'-[(1,4-DIOXOBUTANE-1,4-DIYL)BIS(OXY)]BIS(N,N,N-TRIMETHYLETHANAMINIUM)",
    ],
  },
  {
    primary_id: "D0Q7ZR",
    names: [
      "A-119637",
      "CHEMBL268758",
      "A119637",
      "GTPL490",
      "SCHEMBL7837611",
      "BDBM50099322",
      "A 119637",
      "3-{2-[4-(2-Methoxy-Phenyl)-Piperazin-1-Yl]-Ethyl}-5-Phenyl-1H-Thieno[2,3-D]pyrimidine-2,4-Dione",
      "3-[2-[4-(2-Methoxyphenyl)Piperazino]ethyl]-5-Phenyl-1,2,3,4-Tetrahydrothieno[2,3-D]pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0Q8AT",
    names: [
      "3-Epi-Masilinic Acid",
      "3-Epimaslinic Acid",
      "Epi-Maslinic Acid",
      "3-Epi-Maslinic Acid",
      "CHEMBL383749",
      "CHEBI:66683",
      "26563-68-8",
      "(2alpha,3alpha)-2,3-Dihydroxyolean-12-En-28-Oic Acid",
      "2alpha,3alpha-Dihydroxyolean-12-En-28-Oic Acid",
      "Epimaslinic Acid",
      "SCHEMBL5797455",
      "MolPort-039-338-353",
      "BDBM50175873",
      "ZINC12868514",
      "LMPR0106150030",
    ],
  },
  {
    primary_id: "D0Q8BK",
    names: ["GNF-5"],
  },
  {
    primary_id: "D0Q8BS",
    names: ["(S)-Alpha-Methylhistamine", "(S)-Alpha-MeHA"],
  },
  {
    primary_id: "D0Q8CA",
    names: ["GD2-Lm"],
  },
  {
    primary_id: "D0Q8CE",
    names: ["4-(2-(3-Tert-Butylphenylamino)Ethyl)-1H-Imidazole", "CHEMBL1096517"],
  },
  {
    primary_id: "D0Q8CR",
    names: ["PMID25991433-Compound-L1"],
  },
  {
    primary_id: "D0Q8CS",
    names: ["Tiludronic Acid"],
  },
  {
    primary_id: "D0Q8CV",
    names: ["CDX-301"],
  },
  {
    primary_id: "D0Q8CW",
    names: ["PF-738502"],
  },
  {
    primary_id: "D0Q8DM",
    names: [
      "BIM-26226",
      "D-Pentafluorophenylalanyl-L-Glutaminyl-L-Tryptophyl-L-Alanyl-L-Valyl-D-Alanyl-L-Histidyl-L-Leucine Methyl Ester",
      "(D-Pentafluorophenylalanyl6,D-Alanyl11)Bombesin (6-13) Methyl Ester",
    ],
  },
  {
    primary_id: "D0Q8DZ",
    names: ["EX-404"],
  },
  {
    primary_id: "D0Q8EX",
    names: ["N-Isopropyl-N'-Hydroxyguanidine"],
  },
  {
    primary_id: "D0Q8FE",
    names: ["UR-9660"],
  },
  {
    primary_id: "D0Q8GV",
    names: ["Prostate Cancer Vaccine, FK Biotecnologia"],
  },
  {
    primary_id: "D0Q8GX",
    names: [
      "SYM-011",
      "Dimercept",
      "Hermodulins",
      "Herstatin",
      "RB-200",
      "RBLX-200",
      "RBLX-242",
      "RBLX-242h",
      "RBLX-642h",
    ],
  },
  {
    primary_id: "D0Q8HM",
    names: [
      "6-(4-Fluoro-Phenylsulfanyl)-9H-Purine",
      "6-(4-Fluorophenylsulfanyl)-9H-Purine",
      "736142-69-1",
      "CHEMBL175603",
    ],
  },
  {
    primary_id: "D0Q8HV",
    names: [
      "4-(ACETYLAMINO)-3-HYDROXY-5-NITROBENZOIC ACID",
      "4-Acetamido-3-Hydroxy-5-Nitrobenzoic Acid",
      "162252-45-1",
      "4-(Acetylamino)-3-Hydroxy-5-Nitrobenzoic Acid",
      "ST1",
      "BANA105",
      "AC1MO7GF",
      "SCHEMBL5571782",
      "CHEMBL324455",
      "BANA-105",
      "CTK8C2119",
      "DTXSID30391837",
      "JIDRTCHFBHJIDG-UHFFFAOYSA-N",
      "ZINC3833968",
      "8224AA",
      "ANW-67849",
      "AKOS016007095",
      "DB08570",
      "AJ-45807",
      "TC-156594",
      "AX8236797",
      "KB-107355",
      "4-Acetamido-3-Hydroxy-5-Nitro-Benzoic Acid",
      "Benzoic Acid,4-(Acetylamino)-3-Hydroxy-5-Nitro-",
    ],
  },
  {
    primary_id: "D0Q8IQ",
    names: [
      "1-(3-Chlorophenyl)-2-(Dimethylamino)Propan-1-One",
      "CHEMBL566830",
      "2-(N,N-Dimethylamino)-3'-Chloropropiophenone",
      "740079-95-2",
      "SCHEMBL769582",
      "BDBM50302908",
      "AKOS009983839",
      "2-(N,N-Dimethylamino)-3''-Chloropropiophenone",
    ],
  },
  {
    primary_id: "D0Q8IX",
    names: ["Histone Deacetylase-1 Inhibitors (Cancer)"],
  },
  {
    primary_id: "D0Q8JE",
    names: ["NSC-637993"],
  },
  {
    primary_id: "D0Q8JK",
    names: ["Thiazole Carboxamide Derivative 15"],
  },
  {
    primary_id: "D0Q8JZ",
    names: ["3-Phenyl Propanoic Derivative 3"],
  },
  {
    primary_id: "D0Q8KM",
    names: ["1-(4-Cyanobenzyl)-5-Phenyl-1H-Imidazole", "CHEMBL597578", "SCHEMBL1949482"],
  },
  {
    primary_id: "D0Q8LH",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 32"],
  },
  {
    primary_id: "D0Q8LL",
    names: ["SYL-040012"],
  },
  {
    primary_id: "D0Q8LS",
    names: [
      "2-[4-(2-Piperidin-1-Ylethyl)Phenoxy]benzothiazole",
      "Benzthiazole Compound, 27b",
      "SCHEMBL2168381",
      "CHEMBL470753",
      "BDBM24223",
    ],
  },
  {
    primary_id: "D0Q8LU",
    names: ["2-(1,4'-Bipiperidin-1'-Yl)Thiazolo[4,5-C]pyridine", "CHEMBL574615", "SCHEMBL12041449", "BDBM50299011"],
  },
  {
    primary_id: "D0Q8MI",
    names: ["Lintopride"],
  },
  {
    primary_id: "D0Q8ML",
    names: ["ROR1 CAR-Specific Autologous T-Lymphocytes"],
  },
  {
    primary_id: "D0Q8MQ",
    names: ["Benzothiazine-Carboxamide Compound 5"],
  },
  {
    primary_id: "D0Q8NE",
    names: [
      "1-Adamantan-1-Yl-3-(4-Hydroxy-Phenyl)-Urea",
      "CHEMBL477872",
      "SCHEMBL12932185",
      "BDBM50267094",
      "AKOS009210324",
    ],
  },
  {
    primary_id: "D0Q8NJ",
    names: ["Viomycin Sulfate"],
  },
  {
    primary_id: "D0Q8NO",
    names: ["VUF 8328"],
  },
  {
    primary_id: "D0Q8NW",
    names: [
      "4-Hydroxy-5-Phenylthieno[2,3-B]pyridin-6(7H)-One",
      "CHEMBL414672",
      "4-Hydroxy-5-Phenyl-Thieno[2,3-B]pyridin-6(7H)-One",
      "179337-87-2",
      "SCHEMBL1077245",
      "SCHEMBL16776799",
      "CTK7H0273",
      "ZINC13679983",
      "AKOS015965514",
      "DA-09113",
      "4-Hydroxy-5-Phenyl-7H-Thieno[2,3-B]",
      "FT-0762488",
    ],
  },
  {
    primary_id: "D0Q8OW",
    names: [
      "AnergiX.MS",
      "AG-284",
      "AG2/83",
      "MS-AnergiX",
      "Antigen Specific Therapy (Multiple Sclerosis, AnergiX), Corixa",
    ],
  },
  {
    primary_id: "D0Q8PE",
    names: [
      "4-[2-(4-Phenyl-Piperidin-1-Yl)-Ethoxy]-Phenol",
      "CHEMBL100870",
      "763907-80-8",
      "CTK2G7823",
      "DTXSID50445220",
      "BDBM50079384",
      "4-[2-(4-Phenylpiperidino)Ethoxy]phenol",
      "Phenol, 4-[2-(4-Phenyl-1-Piperidinyl)Ethoxy]-",
    ],
  },
  {
    primary_id: "D0Q8PF",
    names: [
      "Ad5f35-LMPd1-2-Transduced Autologous Dendritic Cells",
      "AddLMP1-I-LMP2",
      "LMP1/LMP2 Cytotoxic T-Lymphocytes Vaccine (Nasopharyngeal Cancer), Baylor College Of Medicine",
      "Ad5f35-LMPd1-2-Transduced Autologous Dendritic Cells (EBV-Associated Cancer)",
      "Ad5f35-LMPd1-2-Transduced Autologous Dendritic Cells (EBV-Associated Cancer),NCI",
    ],
  },
  {
    primary_id: "D0Q8PJ",
    names: ["NLP-91"],
  },
  {
    primary_id: "D0Q8QA",
    names: ["ADS-100380", "CHEMBL216885", "SCHEMBL5904027", "BDBM50198221"],
  },
  {
    primary_id: "D0Q8QH",
    names: ["N-Oleoyl-N-(2-Hydroxyethyl)Hydroxylamine", "CHEMBL203189", "ZINC36294910"],
  },
  {
    primary_id: "D0Q8QR",
    names: ["I-387"],
  },
  {
    primary_id: "D0Q8RC",
    names: ["N-(Indolylcarbonyl)-Piperidine Derivative 1"],
  },
  {
    primary_id: "D0Q8RD",
    names: ["B-10", "Adjuvant (H5N1 Vaccines), FluGen"],
  },
  {
    primary_id: "D0Q8RH",
    names: ["NM-2026"],
  },
  {
    primary_id: "D0Q8RX",
    names: ["L-368899"],
  },
  {
    primary_id: "D0Q8SI",
    names: ["SOM-0777"],
  },
  {
    primary_id: "D0Q8SN",
    names: ["ET-003"],
  },
  {
    primary_id: "D0Q8SO",
    names: ["JWH-208"],
  },
  {
    primary_id: "D0Q8SP",
    names: ["NSC-745797"],
  },
  {
    primary_id: "D0Q8TD",
    names: ["1-(2-Bromo-Phenyl)-3-(2,4-Dihydroxy-Phenyl)-Urea", "CHEMBL184637"],
  },
  {
    primary_id: "D0Q8TI",
    names: ["FX-141L", "Chemokine Ligand Inhibitor (Inflammatory Disease), Funxional"],
  },
  {
    primary_id: "D0Q8TJ",
    names: ["AG-14", "AG14", "AG 14"],
  },
  {
    primary_id: "D0Q8TK",
    names: ["1-(4-Aminosulfonylphenyl)-2-(4-Pyridyl)Acetylene", "CHEMBL406989"],
  },
  {
    primary_id: "D0Q8TX",
    names: ["Ajinomoto 1"],
  },
  {
    primary_id: "D0Q8UU",
    names: ["BM-17.0744"],
  },
  {
    primary_id: "D0Q8UV",
    names: ["RO-145975"],
  },
  {
    primary_id: "D0Q8UZ",
    names: [
      "3,5-Dimethoxy-4'-Amino-Trans-Stilbene",
      "CHEMBL324290",
      "SCHEMBL3359253",
      "BDBM50131692",
      "ZINC27107058",
      "AKOS030581623",
      "Trans-3,5-Dimethoxyl-4''-Aminostilbene",
      "3,5-Dimethoxy-4''-Amino-Trans-Stilbene",
      "(E)-1-(4'-Aminostyryl)-3,5-Dimethoxybenzene",
      "4-[2-(3,5-Dimethoxyphenyl)Ethenyl]benzeneamine",
    ],
  },
  {
    primary_id: "D0Q8VI",
    names: ["PMID25435179-Compound-WO2013059587(V, 1st Set)"],
  },
  {
    primary_id: "D0Q8VM",
    names: ["TT-401"],
  },
  {
    primary_id: "D0Q8VT",
    names: ["JT-2724"],
  },
  {
    primary_id: "D0Q8WG",
    names: ["Tedalinab"],
  },
  {
    primary_id: "D0Q8WL",
    names: ["Evans Blue Dye"],
  },
  {
    primary_id: "D0Q8WN",
    names: [
      "N-(2-Acetamido)Iminodiacetic Acid",
      "26239-55-4",
      "2,2'-((2-Amino-2-Oxoethyl)Azanediyl)Diacetic Acid",
      "Carbamoylmethylaminodiacetic Acid",
      "ADA",
      "2,2'-[(2-Amino-2-Oxoethyl)Imino]diacetic Acid",
      "Glycine, N-(2-Amino-2-Oxoethyl)-N-(Carboxymethyl)-",
      "N-(Carbamoylmethyl)Iminodiacetic Acid",
      "N-Carbamoylmethyliminodi(Acetic Acid)",
      "UNII-5C4R3O704E",
      "(CARBAMOYLMETHYL-CARBOXYMETHYL-AMINO)-ACETIC ACID",
      "5C4R3O704E",
      "N-[2-Acetamido]iminodiacetic Acid",
      "MFCD00008031",
      "AK-41593",
      "ADA, 99%, For Biochemistry",
    ],
  },
  {
    primary_id: "D0Q8WX",
    names: ["AM-101"],
  },
  {
    primary_id: "D0Q8XG",
    names: ["ISIS 100929"],
  },
  {
    primary_id: "D0Q8XH",
    names: ["Cefsoludin Sodium"],
  },
  {
    primary_id: "D0Q8XO",
    names: ["CART-19"],
  },
  {
    primary_id: "D0Q8YA",
    names: ["UR-12670"],
  },
  {
    primary_id: "D0Q8YI",
    names: ["VEGF Kinoid", "Anti-VEGF Vaccine (Cancer), Neovacs"],
  },
  {
    primary_id: "D0Q8YN",
    names: [
      "Sch-40338",
      "CHEMBL326821",
      "AIESXMXVDNOKQF-UHFFFAOYSA-N",
      "GTPL1854",
      "SCHEMBL8877591",
      "BDBM50073173",
      "SCH 40338",
      "1-[4-(8-Chloro-6,11-Dihydro-5H-Benzo[5,6]cyclohepta[1,2-B]pyridin-11-Yl)-Piperazin-1-Yl]-Ethanone",
      "8-CHLORO-11-(1-ACETYL-4-PIPERAZINYL)-6,11-DIHYDRO-5H-BENZO[5,6]CYCLOHEPTA[1,2-B]PYRIDINE",
    ],
  },
  {
    primary_id: "D0Q8YO",
    names: ["PD-117558", "99734-97-1"],
  },
  {
    primary_id: "D0Q8YU",
    names: ["OX-ESI", "15-Lipoxygenase Inhibitors (Inflammation), Orexo/Ortho-McNeil-Janssen"],
  },
  {
    primary_id: "D0Q8YZ",
    names: ["DW-286"],
  },
  {
    primary_id: "D0Q8ZP",
    names: ["TDI-0033"],
  },
  {
    primary_id: "D0Q8ZV",
    names: [
      "2-(2-(2-Chlorophenoxy)Phenyl)Acetic Acid",
      "CHEMBL30038",
      "BDBM50295282",
      "ZINC28766380",
      "[2-(2-Chloro-Phenoxy)-Phenyl]-Acetic Acid",
    ],
  },
  {
    primary_id: "D0Q8ZX",
    names: [
      "Benzocaine",
      "Aethoform",
      "Americaine",
      "Anaesthesin",
      "Anaesthesinum",
      "Anaesthin",
      "Anestezin",
      "Anesthesin",
      "Anesthesine",
      "Anesthone",
      "Bensokain",
      "Benzocaina",
      "Benzocainum",
      "Chloraseptic",
      "Dermoplast",
      "Ethoform",
      "Ethoforme",
      "Hurricaine",
      "Identhesin",
      "Keloform",
      "Norcain",
      "Norcaine",
      "Norcainum",
      "Orthesin",
      "Otocain",
      "Outgro",
      "Parathesin",
      "Parathesine",
      "Solarcaine",
      "Topcaine",
      "Aethylium Paraminobenzoicum",
      "Amben Ethyl Ester",
      "Anestezin [Russian]",
      "Baby Anbesol",
      "Benzocaine Acetate",
      "Benzocaine Formate",
      "Benzocaine Hydrobromide",
      "Benzocaine Hydrochloride",
      "Benzocaine Methanesulfonate",
      "Ethyl PABA",
      "Ethyl Aminobenzoate",
      "Ethylis Aminobenzoas",
      "Solu H",
      "Acetate, Benzocaine",
      "Anaesthan-Syngala",
      "Auralgan (TN)",
      "Benzocaina [INN-Spanish]",
      "Benzocaine [INN:BAN]",
      "Benzocainum [INN-Latin]",
      "Ethyl 4-Aminobenzoate",
      "Ethyl 4-Aminobenzoate Hydrochloride",
      "Ethyl Aminobenzoate (JP15)",
      "Ethyl Aminobenzoate (VAN)",
      "Ethyl P-Aminobenzoate",
      "Ethyl P-Aminophenylcarboxylate",
      "Ethylaminobenzoate-4",
      "Ethylesterkyseliny P-Aminobenzoove",
      "Ethylester Kyseliny P-Aminobenzoove [Czech]",
      "Formate, Benzocaine",
      "Hydrobromide, Benzocaine",
      "Hydrochloride, Benzocaine",
      "Methanesulfonate, Benzocaine",
      "Ora-Jel",
      "P-Aminobenzoic Acid Ethyl Ester",
      "P-Aminobenzoic Ethyl Ester",
      "P-Carbethoxyaniline",
      "P-Ethoxycarboxylic Aniline",
      "Parathesin (TN)",
      "AE-562/40377256",
      "Benzocaine (USP/INN)",
      "ETHYL-P-AMINOBENZOATE",
      "P-(Ethoxycarbonyl)Aniline;P-Aminobenzoic Acid, Ethyl Ester",
      "Benzoic Acid, Amino-, Ethyl Ester",
      "H-4-Abz-Oet",
      "Benzoic Acid, 4-Amino-, Ethyl Ester",
      "Benzoic Acid, P-Amino-, Ethyl Ester",
      "Benzoic Acid, 4-Amino-, Ethyl Ester, Hydrochloride",
      "112909_ALDRICH",
      "112909_SIAL",
      "4 Aminobenzoic Acid Ethyl Ester",
      "4-(Ethoxycarbonyl)Aniline",
      "4-Aminobenzoic Acid Ethyl Ester",
      "4-Aminobenzoic Acid, Ethyl Ester",
      "4-Carbethoxyaniline",
      "4-Amino-Benzoic Acid Ethyl Ester",
    ],
  },
  {
    primary_id: "D0Q9AD",
    names: ["Pyrazole Derivative 59"],
  },
  {
    primary_id: "D0Q9BV",
    names: ["JIN-2019"],
  },
  {
    primary_id: "D0Q9CI",
    names: ["N-Methoxyethyl Estrone-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0Q9CM",
    names: ["4-(3-Bromophenoxy)-6-Chloroquinazoline", "CHEMBL1082103"],
  },
  {
    primary_id: "D0Q9CY",
    names: ["HKI-272"],
  },
  {
    primary_id: "D0Q9DK",
    names: [
      "Nizatidine",
      "Acinon",
      "Antizid",
      "Axid",
      "Calmaxid",
      "Cronizat",
      "Distaxid",
      "Galitidin",
      "Gastrax",
      "Naxidine",
      "Niatidine",
      "Nizatidina",
      "Nizatidinum",
      "Nizax",
      "Nizaxid",
      "Panaxid",
      "Tazac",
      "Ulcosol",
      "Ulxid",
      "Zanizal",
      "Zinga",
      "Axid Ar",
      "Nizatidina [Spanish]",
      "Nizatidinum [Latin]",
      "Splendil ER",
      "LY 139037",
      "Acinon (TN)",
      "Axid (TN)",
      "LY-139037",
      "Tazac (TN)",
      "ZE-101",
      "ZL-101",
      "Nizatidine (JAN/USP/INN)",
      "Nizatidine [USAN:BAN:INN:JAN]",
      "N-(2-(((2-((Dimethylamino)Methyl)-4-Thiazolyl)Methyl)Thio)Ethyl)-N'-Methyl-2-Nitro-1,1-Ethenediamine",
      "N-(4-(6-Methylamino-7-Nitro-2-Thia-5-Aza-6-Hepten-1-Yl)-2-Thiazolylmethyl)-N,N-Dimethylamin",
      "(E)-1-N'-[2-[[2-(Dimethylaminomethyl)-1,3-Thiazol-4-Yl]methylsulfanyl]ethyl]-1-N-Methyl-2-Nitroethene-1,1-Diamine",
      "(E)-N-[2-[[2-(Dimethylaminomethyl)-1,3-Thiazol-4-Yl]methylsulfanyl]ethyl]-N'-Methyl-2-Nitroethene-1,1-Diamine",
      "(E)-N-{2-[({2-[(Dimethylamino)Methyl]-1,3-Thiazol-4-Yl}methyl)Thio]ethyl}-N'-Methyl-2-Nitroethene-1,1-Diamine",
    ],
  },
  {
    primary_id: "D0Q9DL",
    names: ["PB-108"],
  },
  {
    primary_id: "D0Q9EB",
    names: ["KNI-227"],
  },
  {
    primary_id: "D0Q9EH",
    names: ["NV-18"],
  },
  {
    primary_id: "D0Q9EV",
    names: ["Lifitegrast"],
  },
  {
    primary_id: "D0Q9FB",
    names: [
      "JWH-308",
      "(5-(4-Fluorophenyl)-1-Pentyl-1H-Pyrrol-3-Yl)(Naphthalen-1-Yl)Methanone",
      "914458-39-2",
      "CHEMBL374292",
      "[5-(4-Fluorophenyl)-1-Pentyl-1H-Pyrrol-3-Yl](Naphthalen-1-Yl)Methanone",
      "CTK8B4184",
      "DTXSID20658825",
      "ANW-44201",
      "5345AC",
      "ZINC36294625",
      "BDBM50192602",
      "AKOS015999107",
      "AJ-92571",
      "KB-208490",
      "AX8228513",
      "TC-132946",
    ],
  },
  {
    primary_id: "D0Q9FU",
    names: [
      "(S)-1-(5,6-Difluoro-1H-Indol-1-Yl)Propan-2-Amine",
      "CHEMBL40726",
      "SCHEMBL8959516",
      "BDBM50108306",
      "(S)-1-(5,6-Difluoro-1H-Indol-1-Yl)-2-Propanamine",
    ],
  },
  {
    primary_id: "D0Q9FZ",
    names: ["LG100754"],
  },
  {
    primary_id: "D0Q9GJ",
    names: ["BP-C2", "BP + Molybdenum Salts (Radiation Exposure), Meabco"],
  },
  {
    primary_id: "D0Q9GL",
    names: ["AS70", "AS-70", "AS 70"],
  },
  {
    primary_id: "D0Q9GU",
    names: ["CID1792197", "CID 1792197"],
  },
  {
    primary_id: "D0Q9HF",
    names: [
      "Acetylcholine",
      "Choline Acetate",
      "O-Acetylcholine",
      "Acetyl Choline Ion",
      "Acetylcholinum",
      "51-84-3",
      "(2-Acetoxyethyl)Trimethylammonium",
      "Acetyl Choline Cation",
      "Choline Acetate (Ester)",
      "2-(Acetyloxy)-N,N,N-Trimethylethanaminium",
      "Ach",
      "Azetylcholin",
      "Ethanaminium, 2-(Acetyloxy)-N,N,N-Trimethyl-",
      "UNII-N9YNS0M02X",
      "BRN 1764436",
      "CHEBI:15355",
      "Bromoacetylcholine",
      "EINECS 200-128-9",
      "CHEMBL667",
      "N9YNS0M02X",
      "[2-(Acetyloxy)Ethyl]trimethylazanium",
      "Ethanaminium, 2-(Acetyloxy)-N,N,N-Trimethyl- (9CI)",
      "2-Acetyloxyethyl-Trimethylazanium",
      "[3H]acetylcholine",
      "Miochol",
      "Miochol-E",
    ],
  },
  {
    primary_id: "D0Q9HM",
    names: ["CM-AT"],
  },
  {
    primary_id: "D0Q9HT",
    names: ["LY-307161 SR"],
  },
  {
    primary_id: "D0Q9IE",
    names: ["BMS-707035"],
  },
  {
    primary_id: "D0Q9IK",
    names: ["CEP-9722"],
  },
  {
    primary_id: "D0Q9IP",
    names: ["PTI-DX304"],
  },
  {
    primary_id: "D0Q9IU",
    names: [
      "[Leu13]motilin (Human)",
      "[Leu13]-Motilin Porcine",
      "GTPL1453",
      "116283-54-6",
      "[Leu13]-Motilin Porcine, &gt",
    ],
  },
  {
    primary_id: "D0Q9IY",
    names: [
      "PNU-142731A",
      "9-[2-Oxo-2-(Pyrrolidin-1-Yl)Ethyl]-2,4-Bis(Pyrrolidin-1-Yl)-9H-Pyrimido[4,5-B]indole Hydrochloride",
    ],
  },
  {
    primary_id: "D0Q9JG",
    names: [
      "S-(4-Methoxybenzyl)Isothiourea Hydrochloride",
      "25985-08-4",
      "4-Methoxybenzyl Imidothiocarbamate Hydrochloride",
      "CHEMBL1224394",
      "46234-22-4",
      "2-(4-Methoxybenzyl)Isothiourea Hydrochloride",
      "SCHEMBL489767",
      "DTXSID10551871",
      "QGNMUFKTLKVUHM-UHFFFAOYSA-N",
      "NSC67155",
      "S-(4-Methoxybenzyl)Isothiourea HCl",
      "NSC-67155",
      "GEO-03857",
      "AKOS030231098",
      "S-(4-Methoxybenzyl)Thiopseudourea, Hydrochloride",
      "2-(4-Methoxybenzyl)-2-Thiopseudo-Urea Hydrochloride",
      "2-(4-Methoxybenzyl)-2-Thiopseudourea, Hydrochloride",
      "2-((4-Methoxyphenyl)Methyl)-2-Thiopseudourea Hydr",
    ],
  },
  {
    primary_id: "D0Q9JN",
    names: [
      "1-(5-Methyloxazol-2-Yl)-7-Phenylheptan-1-One",
      "CHEMBL220938",
      "SCHEMBL3144951",
      "BDBM50374374",
      "1-(5-Methyl-2-Oxazolyl)-7-Phenyl-1-Heptanone",
    ],
  },
  {
    primary_id: "D0Q9JT",
    names: ["Metyrapone"],
  },
  {
    primary_id: "D0Q9KP",
    names: [
      "(2S)-1-(5-Fluoro-1H-Indazol-1-Yl)Propan-2-Amine",
      "CHEMBL257503",
      "(S)-1-(5-Fluoro-1H-Indazol-1-Yl)Propan-2-Amine",
      "210580-57-7",
      "BDBM50375463",
      "AKOS024258782",
    ],
  },
  {
    primary_id: "D0Q9KR",
    names: ["Seviteronel"],
  },
  {
    primary_id: "D0Q9LC",
    names: ["5-Biphenyl-4-Yl-5-Hexyl-Pyrimidine-2,4,6-Trione", "CHEMBL175282", "BDBM50099116"],
  },
  {
    primary_id: "D0Q9LM",
    names: ["PMID27109571-Compound-7"],
  },
  {
    primary_id: "D0Q9MA",
    names: ["Cordycepin", "3'-Deoxyadenosine"],
  },
  {
    primary_id: "D0Q9MN",
    names: ["COL-204", "Conjugated Stigmine (Wake Promotion), CoLucid"],
  },
  {
    primary_id: "D0Q9NC",
    names: ["2-Phenyl-9H-Indeno[2,1-D]pyrimidine", "CHEMBL241205"],
  },
  {
    primary_id: "D0Q9NJ",
    names: ["AC-625"],
  },
  {
    primary_id: "D0Q9NW",
    names: ["Threo-Ritalinol Methyl Ether Hydrochloride"],
  },
  {
    primary_id: "D0Q9ON",
    names: ["Denopamine"],
  },
  {
    primary_id: "D0Q9OR",
    names: ["HI-445"],
  },
  {
    primary_id: "D0Q9PO",
    names: ["AZUMAMIDE C", "CHEMBL257972"],
  },
  {
    primary_id: "D0Q9PP",
    names: ["Recombinant Slit-2-D1-D2-Fc"],
  },
  {
    primary_id: "D0Q9PT",
    names: ["Synflorix"],
  },
  {
    primary_id: "D0Q9QC",
    names: [
      "DMD-02",
      "Duchenne Muscular Dystrophy Therapeutic, Avicena",
      "Creatine Kinase Modulator (Duchenne Muscular Dystrophy), Avicena",
    ],
  },
  {
    primary_id: "D0Q9QQ",
    names: ["Diterpenoid Derivative 2"],
  },
  {
    primary_id: "D0Q9RA",
    names: ["2-(4-Chlorobenzylidene)Cyclopentylmethyl Ether", "CHEMBL540746"],
  },
  {
    primary_id: "D0Q9RP",
    names: ["CBLC137"],
  },
  {
    primary_id: "D0Q9RR",
    names: [
      "AZD0328",
      "AR-R23465XX",
      "AZD-0328",
      "Spiro[1-Azabicyclo[2.2.2]octane-8,8'-7-Oxa-5-Azabicyclo[4.3.0]nona-2,4,10-Triene]",
    ],
  },
  {
    primary_id: "D0Q9RW",
    names: ["HL-135"],
  },
  {
    primary_id: "D0Q9SC",
    names: [
      "10,10-Difluoro TXA2",
      "GTPL6069",
      "(Z)-7-[(1S,3R,4R,5R)-7,7-Difluoro-3-[(E,3S)-3-Hydroxyoct-1-Enyl]-6-Oxabicyclo[3.1.1]heptan-4-Yl]hept-5-Enoic Acid",
    ],
  },
  {
    primary_id: "D0Q9TB",
    names: ["White Blood Cell Therapy"],
  },
  {
    primary_id: "D0Q9TC",
    names: ["Hexaxim"],
  },
  {
    primary_id: "D0Q9TL",
    names: ["L-702-539", "CHEMBL97380", "SCHEMBL9300407"],
  },
  {
    primary_id: "D0Q9TO",
    names: ["PMID27215781-Compound-12"],
  },
  {
    primary_id: "D0Q9TQ",
    names: ["1-Benzhydryl-4-Methoxy-4-Phenylpiperidine", "CHEMBL228965"],
  },
  {
    primary_id: "D0Q9UL",
    names: [
      "IATX-FVIII",
      "ATX-F8 Program (Hemophilia A)",
      "Factor VIII Inhibitor Apitope (Hemophilia A), Apitope",
      "ATX-F8 Program (Hemophilia A), Apitope Technology",
    ],
  },
  {
    primary_id: "D0Q9UO",
    names: ["GW-311616"],
  },
  {
    primary_id: "D0Q9UX",
    names: ["PHA848125"],
  },
  {
    primary_id: "D0Q9UZ",
    names: ["2-((3-Iodophenyl)(O-Tolyloxy)Methyl)Morpholine", "CHEMBL439030"],
  },
  {
    primary_id: "D0Q9VA",
    names: ["Tritanrix HB"],
  },
  {
    primary_id: "D0Q9VD",
    names: ["Example 131 [WO2009133348]"],
  },
  {
    primary_id: "D0Q9VS",
    names: ["Phosphatonin"],
  },
  {
    primary_id: "D0Q9VW",
    names: ["Beta-Gly"],
  },
  {
    primary_id: "D0Q9WN",
    names: ["FC EPO"],
  },
  {
    primary_id: "D0Q9WO",
    names: [
      "Cinolazepam",
      "Cinolazepamum",
      "Gerodorm",
      "Cinolazepam [INN]",
      "OX 373",
      "Cinolazepam (INN)",
      "Cinolazepamum [INN-Latin]",
      "Gerodorm (TN)",
      "OX-373;1-(2-Cyanoethyl)-7-Chloro-3-Hydroxy-5-(2'-Fluorophenyl)-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "3-[7-Chloro-5-(2-Fluorophenyl)-3-Hydroxy-2-Oxo-2,3-Dihydro-1H-1,4-Benzodiazepin-1-Yl]propanenitrile",
      "3-[7-Chloro-5-(2-Fluorophenyl)-3-Hydroxy-2-Oxo-3H-1,4-Benzodiazepin-1-Yl]propanenitrile",
      "7-Chloro-5-(O-Fluorophenyl)-2,3-Dihydro-3-Hydroxy-2-Oxo-1H-1,4-Benzodiazepine-1-Propionitrile",
      "7-Chloro-5-(2-Fluorophenyl)-2,3-Dihydro-3-Hydroxy-2-Oxo-1H-1,4-Benzodiazepine-1-Propanenitrile",
    ],
  },
  {
    primary_id: "D0Q9XE",
    names: ["MEDI4893"],
  },
  {
    primary_id: "D0Q9XI",
    names: [
      "BQ610",
      "BQ-610",
      "141595-53-1",
      "BQ 610",
      "AZEPANE-1-CARBONYL-LEU-D-TRP(FOR)-D-TRP-OH",
      "Hexahydroazepinocarbonyl-Leu-D-Trp(Cho)-D-Trp",
      "Hexahydroazepinocarbonyl-Leucyl-Tryptophyl(Cho)-Tryptophan",
      "AC1L3XIP",
      "SCHEMBL2678706",
      "CTK8F0466",
      "DTXSID20161768",
      "ZINC72190207",
      "D-Tryptophan, N-(1-Formyl-N-(N-((Hexahydro-1H-Azepin-1-Yl)Carbonyl)-L-Leucyl)-D-Tryptophyl)-",
      "RT-011809",
      "J-007520",
      "(R)-2-((R)-2-((S)-2-(Azepane-1-Carboxamido)-4-Methylpentanamido)-3-(1-Formyl-1H-Indol-3-Yl)Propanamido)-3-(1H-Indol-3-Yl)Propanoic Acid",
    ],
  },
  {
    primary_id: "D0Q9XK",
    names: ["PMID28092474-Compound-33k"],
  },
  {
    primary_id: "D0Q9XX",
    names: ["Casp 4 Inhib", "5-Nitro-Isatin 1 [PMID: 11384246]"],
  },
  {
    primary_id: "D0Q9YG",
    names: ["(R)-2-(2-Naphthamido)-5-Phenylpent-4-Ynoic Acid", "CHEMBL1090378", "SCHEMBL16550496"],
  },
  {
    primary_id: "D0Q9YT",
    names: ["Tetrahydrobiopterin"],
  },
  {
    primary_id: "D0Q9ZD",
    names: ["Camobucol", "AGIX-4207", "AGIX-4207 IV", "V-Protectant (Rheumatoid Arthritis), AtheroGenics"],
  },
  {
    primary_id: "D0Q9ZN",
    names: [
      "3-(4-(Benzyloxy)-3,5-Dibromophenyl)Propanoic Acid",
      "Benzyl Derivative, 8",
      "CHEMBL402713",
      "BDBM18888",
      "3-[4-(Benzyloxy)-3,5-Dibromophenyl]propanoic Acid",
    ],
  },
  {
    primary_id: "D0Q9ZR",
    names: ["Nitroimidazoles"],
  },
  {
    primary_id: "D0Q9ZU",
    names: ["HepTcell"],
  },
  {
    primary_id: "D0QA0B",
    names: ["RDX-008"],
  },
  {
    primary_id: "D0QA1M",
    names: ["MCP-203", "Delta Opioid Receptor Agonist (Depression/Parkinson's Disease), Mt Cook Pharma"],
  },
  {
    primary_id: "D0QA3C",
    names: ["OMB157"],
  },
  {
    primary_id: "D0QA3I",
    names: ["Gamma-Butyrolactone", "4-Butanolide", "2-Oxolanone"],
  },
  {
    primary_id: "D0QA4O",
    names: ["Isoxazole-Based Bicyclic Compound 10"],
  },
  {
    primary_id: "D0QA5U",
    names: ["2-(2-Carboxy-5-Mercaptopentyl)Benzoic Acid"],
  },
  {
    primary_id: "D0QA6Q",
    names: ["CGP-55847"],
  },
  {
    primary_id: "D0QA7X",
    names: ["APG-103", "CD95 Receptor Inhibitor (Inflammatory Disease), Apogenix"],
  },
  {
    primary_id: "D0QA8T",
    names: ["Urea And Carbamate Bioisostere Derivative 4"],
  },
  {
    primary_id: "D0QA9N",
    names: ["PMID27977313-Compound-28"],
  },
  {
    primary_id: "D0QA9Q",
    names: ["PMID25726713-Compound-14"],
  },
  {
    primary_id: "D0QB1O",
    names: ["CD19 And CD20 CAR-T Cells"],
  },
  {
    primary_id: "D0QB6M",
    names: ["ND-901"],
  },
  {
    primary_id: "D0QB7K",
    names: ["Yunaoning"],
  },
  {
    primary_id: "D0QB7N",
    names: ["GD2 T Cells"],
  },
  {
    primary_id: "D0QB8Q",
    names: ["EP-2167"],
  },
  {
    primary_id: "D0QB9L",
    names: ["2-Morpholino-6-(Naphthalen-2-Yl)-4H-Pyran-4-One", "CHEMBL225609"],
  },
  {
    primary_id: "D0QC0W",
    names: [
      "ZM-447439",
      "331771-20-1",
      "ZM447439",
      "ZM 447439",
      "N-[4-[[6-METHOXY-7-[3-(4-MORPHOLINYL)PROPOXY]-4-QUINAZOLINYL]AMINO]PHENYL]BENZAMIDE",
      "TCMDC-125873",
      "UNII-RSN3P9776R",
      "CHEMBL202721",
      "RSN3P9776R",
      "N-(4-((6-Methoxy-7-(3-Morpholinopropoxy)Quinazolin-4-Yl)Amino)Phenyl)Benzamide",
      "C29H31N5O4",
      "N-[4-({6-Methoxy-7-[3-(Morpholin-4-Yl)Propoxy]quinazolin-4-Yl}amino)Phenyl]benzamide",
      "N-(4-{[6-Methoxy-7-(3-Morpholin-4-Ylpropoxy)Quinazolin-4-Yl]amino}phenyl)Benzamide",
    ],
  },
  {
    primary_id: "D0QC2V",
    names: ["AEVI-1"],
  },
  {
    primary_id: "D0QC3M",
    names: ["Amorolfine"],
  },
  {
    primary_id: "D0QC5D",
    names: ["Gallium Citrate Ga-67"],
  },
  {
    primary_id: "D0QC5O",
    names: ["Piperazine Derivative 6"],
  },
  {
    primary_id: "D0QC6H",
    names: ["PMID28048944-Compound-7"],
  },
  {
    primary_id: "D0QC6Z",
    names: ["GALL-200"],
  },
  {
    primary_id: "D0QC9F",
    names: ["PMID22266036C12e"],
  },
  {
    primary_id: "D0QC9I",
    names: ["Benzoic Acid Linked Peptide Analog 1"],
  },
  {
    primary_id: "D0QD1G",
    names: ["Elvitegravir"],
  },
  {
    primary_id: "D0QD3O",
    names: [
      "2-Amino-4,6-Diphenyl-Pyrimidine",
      "4,6-Diphenylpyrimidin-2-Amine",
      "40230-24-8",
      "2-Amino-4,6-Diphenylpyrimidine",
      "4,6-Diphenyl-2- Pyrimidinamine",
      "4,6-Diphenyl-2-Pyrimidinamine",
      "CHEMBL261185",
      "KZUCBEYDRUCBCS-UHFFFAOYSA-N",
      "2-Pyrimidinamine, 4,6-Diphenyl-",
      "AK136264",
      "AC1LDIBD",
      "Cambridge Id 5109412",
      "AC1Q52JN",
      "CBDivE_006411",
      "MLS001162904",
      "SCHEMBL4904514",
      "4,6-Diphenylpyrimidine-2-Amine",
      "CTK1D4711",
      "DTXSID20347207",
      "MolPort-001-847-654",
      "HMS2833A20",
      "HMS1577A06",
      "4,6-Diphenyl-2-Pyrimidinamine #",
      "ZINC295268",
      "KS-00001N3D",
      "BDBM50375490",
      "AKOS010263933",
      "(4,6-Diphenyl-Pyrimidin-2-Yl)-Amine",
    ],
  },
  {
    primary_id: "D0QD4A",
    names: ["ABBV-428"],
  },
  {
    primary_id: "D0QD5Y",
    names: ["Aromatic Bicyclic Compound 1"],
  },
  {
    primary_id: "D0QD6R",
    names: ["Lactobacillus"],
  },
  {
    primary_id: "D0QD6T",
    names: ["6,6-Fused Nitrogenous Heterocyclic Compound 2"],
  },
  {
    primary_id: "D0QD8P",
    names: ["Myristoyl-Coa"],
  },
  {
    primary_id: "D0QD9R",
    names: ["Thyroglobulin"],
  },
  {
    primary_id: "D0QE0D",
    names: ["5-((6-Methylpyridin-2-Yl)Ethynyl)Nicotinonitrile", "CHEMBL1098656"],
  },
  {
    primary_id: "D0QE1Y",
    names: [
      "ViperaTAb",
      "Common Adder Antivenin, Protherics",
      "Vipera Berus Antivenom (Sheep Polyclonal FAbs), Protherics",
      "Antivenom (Sheep Polyclonal, Adder), Protherics",
    ],
  },
  {
    primary_id: "D0QE2H",
    names: ["Thiadiazolidindione Derivative 1"],
  },
  {
    primary_id: "D0QE3C",
    names: [
      "2-Phenylethynyl-7,8-Dihydro-6H-Quinolin-5-One",
      "864224-08-8",
      "2-(PHENYLETHYNYL)-7,8-DIHYDROQUINOLIN-5(6H)-ONE",
      "CHEMBL252956",
      "5(6H)-Quinolinone, 7,8-Dihydro-2-(Phenylethynyl)-",
      "SCHEMBL4325876",
      "2-(2-Phenylethynyl)-7,8-Dihydro-6H-Quinolin-5-One",
      "CTK3C7284",
      "DTXSID10464098",
      "XOUDFZJCSDKXLG-UHFFFAOYSA-N",
      "ZINC28967975",
      "BDBM50231741",
      "AKOS025146642",
      "SC-68865",
      "AK164588",
      "AS-41243",
      "2-(Phenylethynyl)-7,8-Dihydroquinolin-5(6H)-One, 98%",
    ],
  },
  {
    primary_id: "D0QE3V",
    names: ["L-888607"],
  },
  {
    primary_id: "D0QE4F",
    names: ["CD19-CAR And CD28-CAR T Cells"],
  },
  {
    primary_id: "D0QE5B",
    names: ["Tagorizine", "AD-3264", "AL-3264"],
  },
  {
    primary_id: "D0QE5U",
    names: ["Insulin-Detemir"],
  },
  {
    primary_id: "D0QE7H",
    names: ["ISIS 347577"],
  },
  {
    primary_id: "D0QE7J",
    names: [
      "1-(Piperidin-4-Ylmethyl)Pyridin-2(1H)-One",
      "2(1H)-Pyridinone, 1-(4-Piperidinylmethyl)-",
      "CHEMBL206887",
      "SCHEMBL3217361",
      "MolPort-008-735-437",
      "ZINC13686304",
      "AKOS010953288",
      "888729-52-0",
      "1-(Piperidin-4-Ylmethyl)-1,2-Dihydropyridin-2-One",
    ],
  },
  {
    primary_id: "D0QE7X",
    names: ["1-Aryl-4-Methyl-[1,2,4]triazolo[4,3-A]quinoxaline Derivative 4"],
  },
  {
    primary_id: "D0QE8K",
    names: ["PM-72G"],
  },
  {
    primary_id: "D0QE8S",
    names: ["Bicyclic Heteroaryl Carboxamide Analog 2"],
  },
  {
    primary_id: "D0QE8Z",
    names: ["Peptide Analog 44"],
  },
  {
    primary_id: "D0QE9E",
    names: ["PMID27744724-Compound-22"],
  },
  {
    primary_id: "D0QE9F",
    names: ["P-6915"],
  },
  {
    primary_id: "D0QF0L",
    names: ["CD19 CAR-T Cells"],
  },
  {
    primary_id: "D0QF0Y",
    names: ["PMID28092474-Compound-32k"],
  },
  {
    primary_id: "D0QF2W",
    names: [
      "AGTC-0106",
      "Alpha-1 Antitrypsin Deficiency Gene Therapy, AGTC",
      "RAAV1-CB-HAAT",
      "Gene Therapy (Alpha-1 Antitrypsin Deficiency), Applied Genetic Technologies",
    ],
  },
  {
    primary_id: "D0QF2X",
    names: ["TVI-Brain-1"],
  },
  {
    primary_id: "D0QF4V",
    names: ["Isoquinoline Derivative 5"],
  },
  {
    primary_id: "D0QF4W",
    names: ["Macrocycle Derivative 7"],
  },
  {
    primary_id: "D0QF4Z",
    names: [
      "MT-500",
      "199864-87-4",
      "RS-127445",
      "4-(4-Fluoronaphthalen-1-Yl)-6-Isopropylpyrimidin-2-Amine",
      "RS 127445",
      "UNII-0JAU3P8OBM",
      "MT 500",
      "0JAU3P8OBM",
      "CHEMBL473186",
      "C17H16FN3",
      "RS-127445 HCl",
      "2-Amino-4-(4-Fluoronaphth-1-Yl)-6-Isopropylpyrimidine",
      "RS127445",
      "2-Pyrimidinamine, 4-(4-Fluoro-1-Naphthalenyl)-6-(1-Methylethyl)-",
      "RS-127,445",
      "4-(4-Fluoronaphthalen-1-Yl)-6-Propan-2-Ylpyrimidin-2-Amine",
      "AC1L52MZ",
      "GTPL188",
      "RS 127445 Hydrochloride/",
      "SCHEMBL375979",
      "MolPort-021-804-999",
      "ZZZQXCUPAJFVBN-UHFFFAOYSA-N",
      "HMS3651H11",
      "BCPP000085",
      "BCP02714",
      "ZINC3961115",
    ],
  },
  {
    primary_id: "D0QF6E",
    names: ["Tridegin"],
  },
  {
    primary_id: "D0QF7Z",
    names: ["HER2/Neu Peptide Vaccine"],
  },
  {
    primary_id: "D0QF8B",
    names: [
      "Radioprotectants",
      "BIO-100",
      "BIO-200",
      "BIO-400",
      "Radioprotectants (Radiation Sickness)",
      "Radioprotectants (Radiation Sickness),Humanetics",
    ],
  },
  {
    primary_id: "D0QF9P",
    names: ["GSK-208108"],
  },
  {
    primary_id: "D0QG1B",
    names: [
      "Aprocitentan",
      "ACT-132577",
      "UNII-MZI81HV01P",
      "1103522-45-7",
      "Despropyl Macitentan",
      "MZI81HV01P",
      "CHEMBL2165326",
      "CHEBI:76609",
      "Macitentan Metabolite",
      "ACT 132577",
      "SCHEMBL3646065",
      "ZINC95553608",
      "BDBM50395672",
      "AKOS028114466",
      "CS-2687",
      "HY-15895",
      "BC600808",
      "5-(4-Bromophenyl)-4-[2-(5-Bromopyrimidin-2-Yl)Oxyethoxy]-6-(Sulfamoylamino)Pyrimidine",
      "N-[5-(4-Bromophenyl)-6-{2-[(5-Bromopyrimidin-2-Yl)Oxy]ethoxy}pyrimidin-4-Yl]sulfuric Diamide",
    ],
  },
  {
    primary_id: "D0QG2D",
    names: [
      "Amelubant",
      "Amelubant [INN]",
      "BIIL 283 BS",
      "BIIL 284",
      "Ethyl ((4-((3-((4-(1-(4-Hydroxyphenyl)-1-Methylethyl)Phenoxy)Methyl)Benzyl)Oxy)Phenyl)(Imino)Methyl)Carbamate",
      "Carbamic Acid, ((4-((3-((4-(1-(4-Hydroxyphenyl)-1-Methylethyl)Phenoxy)Methyl)Phenyl)Methoxy)Phenyl)Iminomethyl)-, Ethyl Ester",
    ],
  },
  {
    primary_id: "D0QG3E",
    names: ["CIGB-598a", "Anti-VEGF Antibody (Cancer), CIGB"],
  },
  {
    primary_id: "D0QG6A",
    names: ["NIP-142"],
  },
  {
    primary_id: "D0QG6G",
    names: ["3'',5''-Dimethoxy-[1,1':3',1'']-Terphenyl-4-Ol", "CHEMBL381164", "BDBM50186750", "ZINC40763401"],
  },
  {
    primary_id: "D0QG8F",
    names: ["DNCB", "1-Chloro-2,4-Dinitrobenzene"],
  },
  {
    primary_id: "D0QG9A",
    names: ["Apadenoson"],
  },
  {
    primary_id: "D0QH0B",
    names: ["EPI-2010"],
  },
  {
    primary_id: "D0QH0R",
    names: ["SB-206343"],
  },
  {
    primary_id: "D0QH0X",
    names: [
      "2,3,4,5-Tetrahydro-1H-Benzo[c]azepine",
      "7216-22-0",
      "2,3,4,5-Tetrahydro-1H-2-Benzazepine",
      "1H-2-Benzazepine, 2,3,4,5-Tetrahydro-",
      "CHEMBL118310",
      "SCHEMBL233066",
      "ARK042",
      "CTK2H2810",
      "DTXSID00440260",
      "SIQBPWRTJNBBER-UHFFFAOYSA-N",
      "MolPort-020-016-004",
      "BDBM50052883",
      "ANW-49031",
      "ZINC13516868",
      "AKOS005264678",
      "MCULE-2809792553",
      "AB49623",
      "2,3,4,5-Tetrahydro 1H-2-Benzazepine",
      "BS-13541",
      "BR-76361",
      "AJ-64016",
      "TC-137776",
      "DB-074526",
      "W8089",
      "ST24021451",
      "FT-0707305",
      "4CH-000440",
      "S-5095",
      "2,3,4,5-Tetrahydro-1H-Benzo[c]azepine, AldrichCPR",
    ],
  },
  {
    primary_id: "D0QH1J",
    names: ["PMID28394193-Compound-40"],
  },
  {
    primary_id: "D0QH3H",
    names: [
      "DCVax-Prostate",
      "DCVax + PSMA",
      "PSMA + DCVax",
      "Dendritic Cell Vaccine (Prostate Cancer), Northwest",
      "PSMA-Treated Dentritic Cells, Northwest",
      "Vaccine (Prostate Cancer), Pacific Northwest",
    ],
  },
  {
    primary_id: "D0QH4A",
    names: ["1-Benzhydryl-4-(4-Fluorophenyl)Piperidin-4-Ol", "CHEMBL232066"],
  },
  {
    primary_id: "D0QH5C",
    names: [
      "2-(4-Chloro-2-Cyclohexylphenoxy)Acetic Acid",
      "(4-Chloro-2-Cyclohexylphenoxy)Acetic Acid",
      "19774-97-1",
      "MLS002639096",
      "CHEMBL245908",
      "NSC26127",
      "AC1L5KDG",
      "AC1Q3LV0",
      "SCHEMBL4888054",
      "CTK7J5481",
      "DTXSID10282496",
      "MolPort-004-296-406",
      "HMS3089F12",
      "ZINC1628222",
      "AC1Q7647",
      "BDBM50213909",
      "NSC-26127",
      "AKOS000132678",
      "NE44164",
      "MCULE-7136939287",
      "AS-9826",
      "4-Chloro-2-Cyclohexylphenoxy-Acetic Acid",
      "SMR001548547",
    ],
  },
  {
    primary_id: "D0QH5I",
    names: ["N-Phenyl-5-Phenyl-Pyrazolin-3-Yl Amide Derivative 3"],
  },
  {
    primary_id: "D0QH5K",
    names: ["AK-106-001616"],
  },
  {
    primary_id: "D0QH5P",
    names: [
      "6-(3-Iodo-Phenylamino)-1H-Pyrimidine-2,4-Dione",
      "CHEMBL300465",
      "72255-50-6",
      "CTK2H2675",
      "DTXSID50658212",
      "BDBM50028331",
      "6-(3-Iodoanilino)Pyrimidine-2,4(1H,3H)-Dione",
      "2,4(1H,3H)-Pyrimidinedione, 6-[(3-Iodophenyl)Amino]-",
    ],
  },
  {
    primary_id: "D0QH6F",
    names: [
      "4-Methylaminomethyl-7-Methoxycoumarin",
      "AC1PGYCB",
      "7-Methoxy-4-(Methylaminomethyl)Chromen-2-One",
      "SCHEMBL5022816",
      "CHEMBL1080339",
      "BDBM26482",
      "GWMGUFTYUOYYQV-UHFFFAOYSA-N",
      "4-(Methylaminomethyl)-7-Methoxycoumarin",
      "7-Methoxy-4-(Methylaminomethyl)Coumarin",
    ],
  },
  {
    primary_id: "D0QH7G",
    names: ["RG-7432"],
  },
  {
    primary_id: "D0QH7H",
    names: ["AMP-579"],
  },
  {
    primary_id: "D0QH9W",
    names: ["Heteroaryl-Carboxamide Derivative 11"],
  },
  {
    primary_id: "D0QH9X",
    names: ["ISIS 32004"],
  },
  {
    primary_id: "D0QI0Z",
    names: ["IG-MD-009"],
  },
  {
    primary_id: "D0QI1K",
    names: ["HuMax-AXL-ADC"],
  },
  {
    primary_id: "D0QI1O",
    names: ["SL-601"],
  },
  {
    primary_id: "D0QI4Z",
    names: ["KP484"],
  },
  {
    primary_id: "D0QI6W",
    names: ["Non-Peptide TPO Mimics"],
  },
  {
    primary_id: "D0QI7H",
    names: [
      "2-ANILINO-6-CYCLOHEXYLMETHOXYPURINE",
      "2-Anilino-6-Cyclohexylmethoxypurine",
      "6-(Cyclohexylmethoxy)-N-Phenyl-7H-Purin-2-Amine",
      "CHEMBL122264",
      "444722-80-9",
      "2A6",
      "NU-6094",
      "1h1q",
      "AC1L1BOU",
      "SCHEMBL6791643",
      "BDBM5530",
      "CTK1D2421",
      "DTXSID30274313",
      "XWWRLKIBRPJQJX-UHFFFAOYSA-N",
      "O6-Cyclohexylmethylguanine Deriv 2",
      "AKOS030619892",
      "DB06948",
      "N-Phenyl-6-(Cyclohexylmethoxy)-9H-Purine-2-Amine",
      "6-(Cyclohexylmethoxy)-N-Phenyl-9H-Purin-2-Amine",
      "1H-Purin-2-Amine,",
    ],
  },
  {
    primary_id: "D0QI8G",
    names: ["Imidazo[5,1-C][1,2,4]benzotriazine Derivative 1"],
  },
  {
    primary_id: "D0QI8I",
    names: [
      "CGS 8515",
      "114832-13-2",
      "CGS-8515",
      "Methyl 2-((3,4-Dihydro-3,4-Dioxo-1-Naphthalenyl)Amino)Benzoate",
      "Benzoic Acid,2-[(3,4-Dihydro-3,4-Dioxo-1-Naphthalenyl)Amino]-, Methyl Ester",
      "Methyl 2-[(3,4-Dioxonaphthalen-1-Yl)Amino]benzoate",
      "AC1L4TWI",
      "AC1Q5ZBK",
      "Cgs8515",
      "ACMC-1C4N1",
      "SCHEMBL3273068",
      "CTK4A8934",
      "DTXSID80150875",
      "Methyl 2-[(3,4-Dioxo-3,4-Dihydronaphthalen-1-Yl)Amino]benzoate",
      "Benzoic Acid, 2-((3,4-Dihydro-3,4-Dioxo-1-Naphthalenyl)Amino)-, Methyl Ester",
    ],
  },
  {
    primary_id: "D0QI9N",
    names: ["BN-CB-045"],
  },
  {
    primary_id: "D0QJ2K",
    names: ["LEO-27989"],
  },
  {
    primary_id: "D0QJ4M",
    names: ["DPPA-3"],
  },
  {
    primary_id: "D0QJ4Y",
    names: ["TGF-Beta Shield"],
  },
  {
    primary_id: "D0QJ5H",
    names: ["PMID27967267-Compound-12"],
  },
  {
    primary_id: "D0QJ6L",
    names: ["Demogastrin"],
  },
  {
    primary_id: "D0QJ9Z",
    names: [
      "MGN-6114",
      "Anti MiR-92 Therapeutic (Peripheral Artery Disease), MiRagen Therapeutics",
      "MiR-92 Targeted Therapeutic (Peripheral Artery Disease), MiRagen Therapeutics",
      "MiR-92/MiR-126 Targeted Therapeutic (Peripheral Artery Disease), MiRagen Therapeutics",
    ],
  },
  {
    primary_id: "D0QK2E",
    names: ["SCH-205831"],
  },
  {
    primary_id: "D0QK4D",
    names: ["MM36 Topical Ointment"],
  },
  {
    primary_id: "D0QK5C",
    names: ["ALAMIFOVIR"],
  },
  {
    primary_id: "D0QK5X",
    names: ["Enzalutamide"],
  },
  {
    primary_id: "D0QK6D",
    names: ["PMID25991433-Compound-C2"],
  },
  {
    primary_id: "D0QK7K",
    names: ["Oxindole Derivative 4"],
  },
  {
    primary_id: "D0QL0D",
    names: ["SPIF"],
  },
  {
    primary_id: "D0QL0X",
    names: ["Biaryl Mannoside Derivative 23"],
  },
  {
    primary_id: "D0QL1U",
    names: ["DeOvo"],
  },
  {
    primary_id: "D0QL1V",
    names: [
      "Benzyl-Biphenyl-4-Ylmethyl-Imidazol-1-Yl-Amine",
      "CHEMBL490794",
      "N-Benzyl-N-[(4-Phenylphenyl)Methyl]imidazol-1-Amine",
      "AC1LA79Y",
      "BDBM50263717",
      "1H-Imidazol-1-Amine, N-([1,1'-Biphenyl]-4-Ylmethyl)-N-(Phenylmethyl)-",
    ],
  },
  {
    primary_id: "D0QL1X",
    names: ["7-Dimethylamino-3-Methyl-Hept-5-Yn-2-One", "CHEMBL165985"],
  },
  {
    primary_id: "D0QL3P",
    names: ["Oxcarbazepine"],
  },
  {
    primary_id: "D0QL3T",
    names: ["K-42"],
  },
  {
    primary_id: "D0QL4X",
    names: ["SOT-107"],
  },
  {
    primary_id: "D0QL5M",
    names: [
      "MPA",
      "5-N-Mpa",
      "5-(N-Methyl-N-Propyl)Amiloride",
      "1151-75-3",
      "AC1Q3POQ",
      "AC1L4W1E",
      "5-N-(Methyl-Propyl)Amiloride",
      "CHEMBL467106",
      "CHEBI:138016",
      "Pyrazinecarboxamide, 3-Amino-N-(Aminoiminomethyl)-6-Chloro-5-(Methylpropylamino)-",
      "3-Amino-N-Carbamimidoyl-6-Chloro-5-[methyl(Propyl)Amino]pyrazine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0QL7B",
    names: ["PMID28870136-Compound-41"],
  },
  {
    primary_id: "D0QL7J",
    names: ["Tricyclic Compound 2"],
  },
  {
    primary_id: "D0QL7X",
    names: ["Urea And Carbamate Bioisostere Derivative 10"],
  },
  {
    primary_id: "D0QL8W",
    names: ["Radezolid Topical"],
  },
  {
    primary_id: "D0QL9W",
    names: [
      "Quadrivalent Pediatric Combination Vaccine",
      "DD-687",
      "Quadrivalent Pediatric Combination Vaccine (Pertussis, Tetanus, Diphtheria And Polio)",
      "DTaP-IPV, Sanofi Pasteur/Daiichi Sankyo/Sanofi-Aventis/Kitasato Institute",
      "Quadrivalent Pediatric Combination Vaccine (Pertussis, Tetanus, Diphtheria And Polio), Sanofi Pasteur/Daiichi Sankyo/Sanofi/Kitasato Institute",
      "Quadrivalent Pediatric Combination Vaccine (Pertussis, Tetanus, Diphtheria And Polio), Sanofi Pasteur/Daiichi Sankyo/Sanofi-Aventis/Kitasato Institute",
    ],
  },
  {
    primary_id: "D0QM0D",
    names: ["Oxotetrahydro-2-H-Furo[3.2-B]pyrrol-4(5-H)-Yl Derivative 1"],
  },
  {
    primary_id: "D0QM0N",
    names: ["BILD-733"],
  },
  {
    primary_id: "D0QM1D",
    names: ["Central Azetidine Derivative 2"],
  },
  {
    primary_id: "D0QM1R",
    names: ["Pyrazole Derivative 77"],
  },
  {
    primary_id: "D0QM1T",
    names: ["Finazosin"],
  },
  {
    primary_id: "D0QM2O",
    names: ["PMID26651364-Compound-5f"],
  },
  {
    primary_id: "D0QM3E",
    names: ["SB 272183"],
  },
  {
    primary_id: "D0QM3Q",
    names: ["EP-102"],
  },
  {
    primary_id: "D0QM6U",
    names: ["ISIS-TTRRx"],
  },
  {
    primary_id: "D0QM6Y",
    names: ["IkT-004"],
  },
  {
    primary_id: "D0QM9P",
    names: ["INfluenza A Virus H5N8 Vaccine"],
  },
  {
    primary_id: "D0QM9Q",
    names: ["Cyclohexane Carboxamide Derivative 3"],
  },
  {
    primary_id: "D0QM9Y",
    names: ["KKHT-20918"],
  },
  {
    primary_id: "D0QN3K",
    names: ["Antibody Vaccine, Araclon Biotech Sl", "Antibody Vaccine (Alzheimers Disease)"],
  },
  {
    primary_id: "D0QN3Q",
    names: ["LNA Anti-MiR-155"],
  },
  {
    primary_id: "D0QN4U",
    names: ["2-(3''(7''-Bromo-Indolyl)Ethyloxy)Adenosine", "CHEMBL223729", "BDBM50208814"],
  },
  {
    primary_id: "D0QN5E",
    names: [
      "Quinoline-2-Carboxylic Acid Adamantan-1-Ylamide",
      "CHEMBL399161",
      "AC1ME9EW",
      "Oprea1_069959",
      "SCHEMBL6084070",
      "MolPort-002-116-284",
      "ZINC4776255",
      "STK208477",
      "N-1-Adamantylquinoline-2-Carboxamide",
      "BDBM50231752",
      "AKOS001569250",
      "MCULE-4553069817",
      "N-(1-Adamantyl)Quinoline-2-Carboxamide",
      "SR-01000399357",
      "SR-01000399357-1",
    ],
  },
  {
    primary_id: "D0QN5I",
    names: [
      "4-(Furan-2-Yl)Thieno[3,2-D]pyrimidin-2-Amine",
      "CHEMBL406298",
      "Thieno[3,2-D]pyrimidin-2-Amine, 4-(2-Furanyl)-",
      "SCHEMBL3678073",
      "ARPSUXKIHNZLQL-UHFFFAOYSA-N",
      "BDBM50377489",
      "443148-30-9",
    ],
  },
  {
    primary_id: "D0QN5M",
    names: ["LAM-001"],
  },
  {
    primary_id: "D0QN6H",
    names: ["Mallotusinic Acid"],
  },
  {
    primary_id: "D0QN6U",
    names: ["L-790070"],
  },
  {
    primary_id: "D0QN7I",
    names: ["Oxazolo[3,4-A]pyrazine Derivative 6"],
  },
  {
    primary_id: "D0QN8W",
    names: ["PMID28092474-Compound-34a"],
  },
  {
    primary_id: "D0QN9U",
    names: ["GS-156 Mab"],
  },
  {
    primary_id: "D0QO0D",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0QO0L",
    names: ["N-Hydroxy-4-((S)-2-Phenyl-Butyrylamino)-Benzamide"],
  },
  {
    primary_id: "D0QO1Y",
    names: ["PMID25726713-Compound-22"],
  },
  {
    primary_id: "D0QO6N",
    names: ["MGD010"],
  },
  {
    primary_id: "D0QO7H",
    names: [
      "Dithranol Cream",
      "Anthralin",
      "Dithranol",
      "Micanol",
      "Dithranol Cream (Crystalip)",
      "Dithranol Cream (Crystalip), Bioglan",
    ],
  },
  {
    primary_id: "D0QO8X",
    names: [
      "1,2,3,4-Tetrahydro-Isoquinolin-7-Ol",
      "1,2,3,4-Tetrahydroisoquinolin-7-Ol",
      "30798-64-2",
      "1,2,3,4-Tetrahydro-7-Isoquinolinol",
      "7-Hydroxy-1,2,3,4-Tetrahydroisoquinoline",
      "CHEMBL27730",
      "RADQTHXRZJGDQI-UHFFFAOYSA-N",
      "7-Isoquinolinol, 1,2,3,4-Tetrahydro-",
      "1,2,3,4-Tetrahydro-Iosquinoline-7-Ol",
      "AC1L9HE2",
      "7-Hydroxytetrahydroisochinolin",
      "SCHEMBL470826",
      "AC1Q798F",
      "DTXSID40329303",
      "MolPort-005-312-666",
      "ZINC3847389",
      "BDBM50024826",
      "ANW-75301",
      "AKOS006222928",
      "MB05747",
      "RP21285",
      "AC-9597",
      "MCULE-2692939705",
      "KB-74165",
      "KB-64192",
      "BS-13490",
      "AJ-45863",
      "TC-062003",
    ],
  },
  {
    primary_id: "D0QP0H",
    names: ["ISIS 113858"],
  },
  {
    primary_id: "D0QP1X",
    names: ["HIV Vaccine Ad4-MGag"],
  },
  {
    primary_id: "D0QP2S",
    names: ["Tyr-D-Ala-Gly-Phe-NH-NH-Phe-Asp-NMeNle-D-Trp-Boc", "CHEMBL374325"],
  },
  {
    primary_id: "D0QP3A",
    names: ["1-(4-(Benzyloxy)Phenethyl)Pyrrolidine", "CHEMBL271661"],
  },
  {
    primary_id: "D0QP4N",
    names: ["PMID25666693-Compound-81"],
  },
  {
    primary_id: "D0QP5D",
    names: ["NSC-664171"],
  },
  {
    primary_id: "D0QP7C",
    names: [
      "4-Hydroxy-6-Nitro-3-Phenylquinolin-2(1H)-One",
      "CHEMBL413773",
      "SCHEMBL4469573",
      "ZINC36185238",
      "BDBM50189455",
    ],
  },
  {
    primary_id: "D0QP8X",
    names: ["ONO-7579"],
  },
  {
    primary_id: "D0QP9A",
    names: ["KI-1001"],
  },
  {
    primary_id: "D0QP9D",
    names: ["2-Benzyl-7-Hydroxy-Chromen-4-One", "CHEMBL56346", "2-Benzyl-7-Hydroxychromone", "ZINC12343959"],
  },
  {
    primary_id: "D0QQ0W",
    names: ["Selicrelumab"],
  },
  {
    primary_id: "D0QQ1J",
    names: [
      "BITHIONOL",
      "Bithionol",
      "97-18-7",
      "Actamer",
      "Bithin",
      "2,2'-Thiobis(4,6-Dichlorophenol)",
      "Lorothidol",
      "Bitionol",
      "Bithionol Sulfide",
      "Bisoxyphen",
      "Bidiphen",
      "Lorothiodol",
      "Bitin",
      "Nobacter",
      "Bithionolate",
      "Neopellis",
      "Vancide BL",
      "Usaf B-22",
      "Bithional",
      "Bithionolum",
      "2-Hydroxy-3,5-Dichlorophenyl Sulfide",
      "TKhsd",
      "Bis(2-Hydroxy-3,5-Dichlorophenyl) Sulfide",
      "Bis(3,5-Dichloro-2-Hydroxyphenyl) Sulfide",
      "2,2'-Sulfanediylbis(4,6-Dichlorophenol)",
      "Caswell No. 852",
      "Bitionol [INN-Spanish]",
      "XL 7",
      "Bithionolum [INN-Latin]",
      "2,2'-Dihydroxy-3,3',5,5'-Tetrachlorodiphenyl Sulfide",
    ],
  },
  {
    primary_id: "D0QQ4Z",
    names: ["LB30812"],
  },
  {
    primary_id: "D0QQ5W",
    names: ["Beta-Cyclodextrin Conjugate Derivative 2"],
  },
  {
    primary_id: "D0QQ6Q",
    names: ["Enprostil"],
  },
  {
    primary_id: "D0QQ7A",
    names: ["Abyssinone-VI-4-O-Methyl Ether", "CHEMBL470865", "Abyssinone VI 4-O-Methyl Ether"],
  },
  {
    primary_id: "D0QQ7D",
    names: ["Tazobactam"],
  },
  {
    primary_id: "D0QQ7I",
    names: ["BAY1834845"],
  },
  {
    primary_id: "D0QQ7N",
    names: ["TH-9229"],
  },
  {
    primary_id: "D0QQ9L",
    names: ["APG-1252"],
  },
  {
    primary_id: "D0QQ9V",
    names: ["JNJ-64041809", "ADU-741"],
  },
  {
    primary_id: "D0QR0E",
    names: ["Resorcinol Compound 27"],
  },
  {
    primary_id: "D0QR0U",
    names: ["PMID26560530-Compound-35"],
  },
  {
    primary_id: "D0QR1I",
    names: ["Peptidomimetic Analog 2"],
  },
  {
    primary_id: "D0QR1L",
    names: ["ISIS 4756"],
  },
  {
    primary_id: "D0QR1Z",
    names: ["Isosteric Imidazolyl Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0QR2K",
    names: ["NKR-2 Cells"],
  },
  {
    primary_id: "D0QR3J",
    names: ["GC-2107"],
  },
  {
    primary_id: "D0QR3X",
    names: ["Edasalonexent"],
  },
  {
    primary_id: "D0QR4F",
    names: ["Sirolimus Ophthalmic"],
  },
  {
    primary_id: "D0QR4H",
    names: [
      "D-21151",
      "1-Phenoxyphthalazine",
      "Phthalazine, 1-Phenoxy-",
      "100537-30-2",
      "BRN 0169614",
      "ACMC-20dtxu",
      "1-Phenoxy-Phthalazine",
      "AC1LD7PM",
      "4-23-00-02603 (Beilstein Handbook Reference)",
      "ZINC5795",
      "SCHEMBL8809522",
      "CTK0H6325",
      "DTXSID30143373",
      "CSHSXUZQMJVDJY-UHFFFAOYSA-N",
      "AKOS025394053",
      "RT-006411",
      "LS-109178",
    ],
  },
  {
    primary_id: "D0QR5G",
    names: ["5-Methoxypsoralen", "5-MOP"],
  },
  {
    primary_id: "D0QR7C",
    names: ["SYN-1327"],
  },
  {
    primary_id: "D0QR8B",
    names: ["STP-432"],
  },
  {
    primary_id: "D0QS1U",
    names: [
      "Dihydralazine",
      "Dihydralazine Sulfate",
      "7327-87-9",
      "Dihydralazine Sulphate",
      "1,4-Dihydrazinophthalazine Sulfate",
      "Depressan",
      "Hydralazine Sulfate",
      "Ophthazin Sulfate",
      "UNII-1C2B1W91NK",
      "CCRIS 5386",
      "Sulfate De Dihydralazine [French]",
      "1,4-Dihydrazinylphthalazine Sulfate",
      "EINECS 230-808-0",
      "1C2B1W91NK",
      "Phthalazine, 1,4-Dihydrazino-, Sulfate (1:1)",
      "1,4-Dihydrazinophthalazine Hydrogen Sulfate",
      "W-104459",
      "Sulfate De Dihydralazine",
      "1,4-Dihydrazinophthalazine Sulfate(1:1)",
      "Dihydralazinsulfat",
      "C8H10N6.H2O4S",
      "Nepresol (TN)",
      "Dihydralazine Sulfate,(S)",
      "AC1Q6XE2",
    ],
  },
  {
    primary_id: "D0QS2B",
    names: ["N-(Pyridin-2-Yl)Pyrimidin-4-Amine Derivative 2"],
  },
  {
    primary_id: "D0QS2N",
    names: [
      "3hydroxy-1-Methyl-1-Phenylurea",
      "CHEMBL262340",
      "N'-Hydroxy-N-Methyl-N-Phenylurea",
      "13262-41-4",
      "1-Methyl-1-Phenyl-3-Hydroxyurea",
      "BDBM50377110",
    ],
  },
  {
    primary_id: "D0QS6G",
    names: ["Prevecon"],
  },
  {
    primary_id: "D0QS6O",
    names: ["AdoC(Ahx)Arg6", "CHEMBL610874"],
  },
  {
    primary_id: "D0QS9T",
    names: [
      "4-PHENOXY-N-(PYRIDIN-2-YLMETHYL)BENZAMIDE",
      "CHEMBL199237",
      "CHEBI:41135",
      "4-Phenoxy-N-[(Pyridin-2-Yl)Methyl]benzamide",
      "BI5",
      "1zyj",
      "AC1M5EGY",
      "Oprea1_773674",
      "MolPort-004-000-036",
      "HMS3604L15",
      "BDBM50174097",
      "ZINC13284470",
      "AKOS027682522",
      "MCULE-5005773275",
      "DB07459",
      "Z30166389",
    ],
  },
  {
    primary_id: "D0QT2F",
    names: [
      "6-(4-Methylpiperazin-1-Yl)-9Hpurine",
      "6-(4-Methylpiperazin-1-Yl)-9H-Purine",
      "6-(4-Methylpiperazin-1-Yl)-7H-Purine",
      "CHEMBL594152",
      "99172-01-7",
      "SDCCGMLS-0065440.P001",
      "AC1LFNRL",
      "Oprea1_666011",
      "MLS000419169",
      "6-(4-Methylpiperazinyl)Purine",
      "SCHEMBL13718480",
      "MolPort-000-847-583",
      "MolPort-000-384-358",
      "HMS2684L19",
      "ZINC213869",
      "HMS1650E09",
      "CCG-26902",
      "BBL036787",
      "STK630143",
      "BDBM50304512",
      "NSC510935",
      "AKOS001427557",
      "AKOS005562847",
      "NSC-510935",
      "MCULE-5386395090",
      "SMR000319851",
      "ST45178781",
      "AB00588487-02",
      "SR-01000104630",
      "SR-01000104630-1",
      "Z57745256",
    ],
  },
  {
    primary_id: "D0QT3C",
    names: ["Azole Derivative 6"],
  },
  {
    primary_id: "D0QT4X",
    names: ["6,7-Difluoro-3-Thiophen-3-Yl-Quinoline", "CHEMBL68184", "SCHEMBL8501272", "ZINC13737708"],
  },
  {
    primary_id: "D0QT5B",
    names: ["PMID27539678-Compound-11"],
  },
  {
    primary_id: "D0QT8L",
    names: ["PMID28270021-Compound-WO2014129431Example8-1"],
  },
  {
    primary_id: "D0QT9B",
    names: ["LPCN-1002"],
  },
  {
    primary_id: "D0QU0H",
    names: ["OMS721"],
  },
  {
    primary_id: "D0QU1E",
    names: [
      "PT-311",
      "149485-76-7",
      "Thiourea, N-(2-(2-Methoxyphenyl)Ethyl)-N'-2-Thiazolyl-",
      "PETT Deriv. 5",
      "Thiourea, N-[2-(2-Methoxyphenyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 5",
      "AC1MHDK2",
      "BDBM2855",
      "CHEMBL254201",
      "SCHEMBL6953979",
      "DTXSID40164291",
      "ZINC13744845",
      "AKOS030030935",
      "1-(2-Methoxyphenethyl)-3-(2-Thiazolyl)Thiourea",
      "1-[2-(2-Methoxyphenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "1-[2-(2-Methoxyphenyl)Ethyl]-3-1,3-Thiazol-2-Ylthiourea",
    ],
  },
  {
    primary_id: "D0QU3G",
    names: ["Diamine Derivative 3"],
  },
  {
    primary_id: "D0QU6N",
    names: ["PMID26651364-Compound-47"],
  },
  {
    primary_id: "D0QU6S",
    names: ["ISIS 112024"],
  },
  {
    primary_id: "D0QU8H",
    names: ["(S)-Amisulpride"],
  },
  {
    primary_id: "D0QU8I",
    names: ["PF-06482077"],
  },
  {
    primary_id: "D0QV0K",
    names: ["4-Benzyl-1-Phenethyl-Piperidine Hydrochloride"],
  },
  {
    primary_id: "D0QV0P",
    names: ["Phenoxypiperidine Derivative 1"],
  },
  {
    primary_id: "D0QV1E",
    names: ["Thiazole Carboxamide Derivative 28"],
  },
  {
    primary_id: "D0QV3O",
    names: ["OCID-4681"],
  },
  {
    primary_id: "D0QV4A",
    names: [
      "NI-0501",
      "Anti-IFN Gamma Antibody, NovImmune/Merck Serono",
      "Anti-IFN Gamma Antibody, NovImmune/Serono",
      "Anti-Interferon Gamma Antibody, NovImmune/Merck Serono",
      "Anti-Interferon Gamma Antibody, NovImmune/Serono",
    ],
  },
  {
    primary_id: "D0QV5T",
    names: ["Phenprocoumon"],
  },
  {
    primary_id: "D0QV6M",
    names: ["LM-4108"],
  },
  {
    primary_id: "D0QV7L",
    names: ["Phytobacx", "BCL-6", "PYN-6"],
  },
  {
    primary_id: "D0QV8M",
    names: ["ISIS 107776"],
  },
  {
    primary_id: "D0QV8V",
    names: [
      "2-(2,4-Dichlorophenoxy)-5-(3-Phenylpropyl)Phenol",
      "JPN",
      "Triclosan Derivative, 22",
      "CHEMBL259880",
      "BDBM25421",
      "6760-EP2316452A1",
      "6760-EP2311801A1",
      "6760-EP2311797A1",
      "6760-EP2311455A1",
      "6760-EP2301536A1",
      "6760-EP2298767A1",
      "6760-EP2281818A1",
      "6760-EP2311798A1",
      "6760-EP2308509A1",
      "6760-EP2305250A1",
      "6760-EP2301627A1",
      "6760-EP2301538A1",
      "6760-EP2298734A2",
      "6760-EP2295053A1",
      "6760-EP2292614A1",
      "6760-EP2287155A1",
      "6760-EP2277876A1",
      "6760-EP1441224A2",
      "6760-EP2314587A1",
      "6760-EP2311831A1",
      "6760-EP2311799A1",
      "6760-EP2308874A1",
      "6760-EP2308873A1",
      "6760-EP2305653A1",
    ],
  },
  {
    primary_id: "D0QV9Q",
    names: ["CART22 Cells"],
  },
  {
    primary_id: "D0QW0C",
    names: ["PMID30280939-Compound-WO2013119856"],
  },
  {
    primary_id: "D0QW0E",
    names: ["HBV Infection Vaccine"],
  },
  {
    primary_id: "D0QW1V",
    names: ["MAD-001"],
  },
  {
    primary_id: "D0QW4A",
    names: ["C[CO-O-C6H4-CO-Pro-D-Phe-Arg-Trp-Lys]-NH2", "CHEMBL253574"],
  },
  {
    primary_id: "D0QW4H",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 12"],
  },
  {
    primary_id: "D0QW5N",
    names: ["(+)-Cis-H2-PAT"],
  },
  {
    primary_id: "D0QW6B",
    names: ["Maleimides Derivative 2"],
  },
  {
    primary_id: "D0QW6X",
    names: ["3'thiacytidine"],
  },
  {
    primary_id: "D0QW7D",
    names: ["DK-507k"],
  },
  {
    primary_id: "D0QW7F",
    names: ["Quinoline Derivative 16"],
  },
  {
    primary_id: "D0QW7W",
    names: [
      "4-((1H-Imidazol-1-Yl)Methyl)Phenol",
      "4-(1h-Imidazol-1-Ylmethyl)Phenol",
      "1-(4-Hydroxybenzyl)Imidazole",
      "41833-17-4",
      "Phenol, 4-(1H-Imidazol-1-Ylmethyl)-",
      "CHEMBL166845",
      "ZEFROCQEWXHIEP-UHFFFAOYSA-N",
      "4-(Imidazolylmethyl)Phenol",
      "4-Hobi",
      "N-P-Hydroxybenzylimidazole",
      "AC1L4X2E",
      "AC1Q7A8C",
      "4-Imidazol-1-Ylmethyl-Phenol",
      "4-(1h-Imidazolylmethyl)Phenol",
      "4-(Imidazol-1-Ylmethyl)Phenol",
      "SCHEMBL6323414",
      "4-[(1-Imidazolyl)Methyl]phenol",
      "CTK4I5281",
      "DTXSID30194643",
      "MolPort-006-827-429",
      "1-(4-Hydroxybenzyl)-1H-Imidazole",
      "ZINC13549136",
      "SBB051635",
    ],
  },
  {
    primary_id: "D0QW9H",
    names: ["Imiglucerase", "Cerezyme (TN)"],
  },
  {
    primary_id: "D0QX0H",
    names: ["GTx-134"],
  },
  {
    primary_id: "D0QX0K",
    names: ["FT-105"],
  },
  {
    primary_id: "D0QX0O",
    names: ["PMID28048944-Compound-4"],
  },
  {
    primary_id: "D0QX3G",
    names: ["PMID29671355-Compound-22"],
  },
  {
    primary_id: "D0QX4B",
    names: ["CDC", "Cinnamyl-3,4-Dihydroxy-Alpha-Cyanocinnamate"],
  },
  {
    primary_id: "D0QX5D",
    names: ["3-[2-(5-Chloro-2-Thienyl)Pyridin-4-Yl]phenol", "CHEMBL1096026"],
  },
  {
    primary_id: "D0QX6B",
    names: ["GERANYLGERANYL DIPHOSPHATE"],
  },
  {
    primary_id: "D0QX6R",
    names: [
      "N-Hydroxy-7-Oxo-7-Phenylheptanamide",
      "CHEMBL95916",
      "SCHEMBL1521257",
      "BDBM50114832",
      "7-Oxo-7-Phenyl-Heptanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0QX6W",
    names: ["Benzotriazole Derivative 1"],
  },
  {
    primary_id: "D0QX8X",
    names: ["M2951"],
  },
  {
    primary_id: "D0QX9K",
    names: [
      "AMG 986",
      "DOMQFIFVDIAOOT-ROUUACIJSA-N",
      "UNII-4B8REJ8ZGY",
      "4B8REJ8ZGY",
      "Azelaprag",
      "Azelaprag [INN]",
      "Azelaprag (Proposed INN)",
      "AMG986",
      "SCHEMBL18247065",
      "GTPL10061",
      "AMG-986",
      "2049980-18-7",
      "Example 263 [WO2016187308A1]",
      "(2S,3R)-N-[4-(2,6-Dimethoxyphenyl)-5-(5-Methylpyridin-3-Yl)-1,2,4-Triazol-3-Yl]-3-(5-Methylpyrimidin-2-Yl)Butane-2-Sulfonamide",
      "2-Pyrimidineethanesulfonamide, N-(4-(2,6-Dimethoxyphenyl)-5-(5-Methyl-3-Pyridinyl)-4H-1,2,4-Triazol-3-Yl)-Alpha,beta,5-Trimethyl-, (AlphaS,betaR)-",
    ],
  },
  {
    primary_id: "D0QX9U",
    names: ["BN-GU-005-DHP"],
  },
  {
    primary_id: "D0QY0N",
    names: ["PMID27376512-Compound-MTC-422"],
  },
  {
    primary_id: "D0QY1M",
    names: ["GS-5806"],
  },
  {
    primary_id: "D0QY3L",
    names: ["Oxazolo[3,4-A]pyrazine Derivative 5"],
  },
  {
    primary_id: "D0QY3R",
    names: ["Cbz-Ile-T-ButylhomoGlu-Ala-LeuVSMe", "CHEMBL207196"],
  },
  {
    primary_id: "D0QY4L",
    names: ["Nitroimidazole"],
  },
  {
    primary_id: "D0QY4O",
    names: ["PMID27841045-Compound-136"],
  },
  {
    primary_id: "D0QY6M",
    names: ["Pyrrolo-Pyridinone Derivative 2"],
  },
  {
    primary_id: "D0QZ1Z",
    names: ["PVS-10200"],
  },
  {
    primary_id: "D0QZ3P",
    names: ["QAL964"],
  },
  {
    primary_id: "D0QZ4S",
    names: ["LEMINOPRAZOLE"],
  },
  {
    primary_id: "D0QZ7V",
    names: ["RXI-SCP"],
  },
  {
    primary_id: "D0QZ8K",
    names: ["Acyl Piperidine Derivative 1"],
  },
  {
    primary_id: "D0QZ9D",
    names: ["GRNIC-1"],
  },
  {
    primary_id: "D0R0AO",
    names: ["MOR-205"],
  },
  {
    primary_id: "D0R0AX",
    names: ["NCI-159878"],
  },
  {
    primary_id: "D0R0BI",
    names: [
      "CT-1",
      "CT-1, CDRI/ Nicholas Piramal",
      "Hypoglycemic Agent (Oral Formulation, Diabetes) CDRI/ Nicholas Piramal",
    ],
  },
  {
    primary_id: "D0R0BJ",
    names: ["Cirrhosis"],
  },
  {
    primary_id: "D0R0BX",
    names: [
      "Nitrofurantoin",
      "Alfuran",
      "Benkfuran",
      "Berkfuran",
      "Berkfurin",
      "Ceduran",
      "Chemiofuran",
      "Cistofuran",
      "Cyantin",
      "Cystit",
      "Dantafur",
      "Furabid",
      "Furachel",
      "Furadantin",
      "Furadantine",
      "Furadantoin",
      "Furadoine",
      "Furadonin",
      "Furadonine",
      "Furadoninum",
      "Furadontin",
      "Furalan",
      "Furaloid",
      "Furantoin",
      "Furatoin",
      "Furedan",
      "Furina",
      "Furobactina",
      "Furodantin",
      "Furophen",
      "Gerofuran",
      "Ituran",
      "Macpac",
      "Macrobid",
      "Macrodantin",
      "Macrodantina",
      "Macrofuran",
      "Macrofurin",
      "NITROFURANTION",
      "Nierofu",
      "Nifurantin",
      "Nifuretten",
      "Nitoin",
      "Nitrex",
      "Nitrofuradantin",
      "Nitrofurantoina",
      "Nitrofurantoine",
      "Nitrofurantoinum",
      "Novofuran",
      "Orafuran",
      "Parfuran",
      "Phenurin",
      "PiyEloseptyl",
      "Siraliden",
      "Trantoin",
      "Uerineks",
      "Urantoin",
      "Urizept",
      "Urodil",
      "Urodin",
      "Urofuran",
      "Urofurin",
      "Urolisa",
      "Urolong",
      "Welfurin",
      "Zoofurin",
      "Fua Med",
      "Furadantin Retard",
      "Furadantina MC",
      "Furadantine Mc",
      "Furophen T",
      "NITROFURANTOIN MACROCRYSTALLINE",
      "Nitrofurantoina [DCIT]",
      "Fua-Med",
      "Fur-Ren",
      "Furadantin (TN)",
      "Furadantine-MC",
      "Macrobid (TN)",
      "Macrodantin (TN)",
      "N-Toin",
      "ND-3320",
      "ND-7248",
      "NITROFURANTOIN, MACROCRYSTALLINE",
      "Nitro Macro (TN)",
      "Nitrofur-C",
      "Nitrofurantoine [INN-French]",
      "Nitrofurantoinum [INN-Latin]",
      "Ro-Antoin",
      "Urantoin (TN)",
      "Uro-Selz",
      "Uro-Tablinen",
      "Uro-Tablineu",
      "Usaf Ea-2",
      "Nitrofurantoin (JAN/USP/INN)",
      "Nitrofurantoin [USAN:INN:BAN:JAN]",
      "N-(5-Nitrofurfurylidene)-1-Aminohydantoin",
      "N-(5-Nitro-2-Furfurylidene)-1-Aminohydantoin",
      "N-(5-Nitro-2-Furfurylideno)-1-Aminohydantoina",
      "N-(5-Nitro-2-Furfurylideno)-1-Aminohydantoina [Polish]",
      "N-(5-Nitro-2-Furfurylidene)-1-Aminohyda Ntoin",
      "1-(((5-Nitro-2-Furanyl)Methylene)Amino)-2,4-Imidazolidinedione",
      "1-((5-Nitrofurfurylidene)Amino)Hydantoin",
      "1-(5-Nitro-2-Furfurylidenamino)Hydantoin",
      "1-(5-Nitro-2-Furfurylideneamino)Hydantoin",
      "1-[(5-:nitrofurfurylidene)Amino]hydantoin",
      "1-[(5-Nitrofurfurylidene)Amino]hydantoin",
      "1-[(E)-(5-Nitrofuran-2-Yl)Methylideneamino]imidazolidine-2,4-Dione",
      "1-[[(5-Nitro-2-Furanyl)Methylene]amino]-2,4-Imidazolidinedione",
      "1-{[(1E)-(5-Nitro-2-Furyl)Methylene]amino}imidazolidine-2,4-Dione",
      "1-{[(1E)-(5-Nitrofuran-2-Yl)Methylidene]amino}imidazolidine-2,4-Dione",
      "5-Nitrofurantoin",
    ],
  },
  {
    primary_id: "D0R0BY",
    names: ["Ac-YR[CEH(PCl-DF)RWC]-NH2", "CHEMBL415661"],
  },
  {
    primary_id: "D0R0CF",
    names: ["Fentanyl Inhalation"],
  },
  {
    primary_id: "D0R0DW",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0R0EF",
    names: ["AZD-7268"],
  },
  {
    primary_id: "D0R0EI",
    names: [
      "9-Hydroxypropyladenine, S-Isomer",
      "(S)-1-(6-Amino-9H-Purin-9-Yl)Propan-2-Ol",
      "14047-27-9",
      "(S)-9-(2-Hydroxypropyl)Adenine",
      "(2S)-1-(6-Amino-9H-Purin-9-Yl)Propan-2-Ol",
      "AC1L9HLO",
      "Tenofovir Related Compound 9",
      "S-9-(2-Hydroxypropyl)Adenine",
      "SCHEMBL5810639",
      "9H-Purine-9-Ethanol, 6-Amino-Alpha-Methyl-, (AlphaS)-",
      "CTK8C2128",
      "DTXSID60332177",
      "MolPort-003-848-032",
      "ZINC2046907",
      "ANW-67863",
      "6827AA",
      "DB03000",
      "SC-43410",
      "AK-82057",
      "AJ-33425",
      "KB-106920",
      "TX-015702",
      "(2S)-1-(6-Aminopurin-9-Yl)Propan-2-Ol",
      "AX8236781",
      "FT-0696938",
      "ST24035731",
    ],
  },
  {
    primary_id: "D0R0EV",
    names: [
      "E-6123",
      "E 6123",
      "131614-02-3",
      "BRN 4280434",
      "E-?6123",
      "4H-Pyrido(4',3':4,5)Thieno(3,2-F)(1,2,4)Triazolol(4,3-A)(1,4)Diazepine, 6-(2-Chlorophenyl)-9-(Cyclopropylcarbonyl)-7,8,9,10-Tetrahydro-1,4-Dimethyl-, (S)-",
      "4H-Pyrido(4',3':4,5)Thieno(3,2-F)(1,2,4)Triazolo(4,3-A)(1,4)Diazepine, 7,8,9,10-Tetrahydro-6-(2-Chlorophenyl)-9-(Cyclopropylcarbonyl)-1,4-Dimethyl-, (S)-",
      "AC1MHYY4",
      "CHEMBL114014",
      "DTXSID20157146",
      "API0009208",
      "HY-10164",
      "LS-134356",
    ],
  },
  {
    primary_id: "D0R0FE",
    names: [
      "Verapamil",
      "Arpamyl",
      "Calan",
      "Calcan",
      "Cordilox",
      "Dexverapamil",
      "Dilacoran",
      "Drosteakard",
      "Iproveratril",
      "Isoptimo",
      "Isoptin",
      "Isotopin",
      "Vasolan",
      "Veracim",
      "Verapamilo",
      "Verapamilum",
      "Veraptin",
      "Verexamil",
      "Bosoptin (TN)",
      "Calan (TN)",
      "D-365",
      "Isoptin (TN)",
      "Verapamilo [INN-Spanish]",
      "Verapamilum [INN-Latin]",
      "Verelan (TN)",
      "CP-16533-1",
      "Covera-HS (TN)",
      "Verapamil (USAN/INN)",
      "Verapamil [USAN:BAN:INN]",
      "Verapamil [USAN:INN:BAN]",
      "Alpha-((N-Methyl-N-Homoveratryl)-Gamma-Aminopropyl)-3,4-Dimethoxyphenylacetonitrile",
      "Alpha-Isopropyl-Alpha-((N-Methyl-N-Homoveratryl)-Gamma-Aminopropyl)-3,4-Dimethoxyphenylacetonitrile",
      "Alpha-(3-((2-(3,4-Dimethoxyphenyl)Ethyl)-Methylamino)Propyl)-3,4-Dimethoxy-Alpha-(1-Methylethyl)Benzeneacetonitrile",
      "(+-)-Verapamil",
      "(+/-)-VERAPAMIL",
      "(1)-3-(3,4-Dimethoxyphenyl)-6-((5,6-Dimethoxyphenethyl)Methylamino)Hexane-3-Carbonitrile",
      "2-(3,4-Dimethoxyphenyl)-5-[2-(3,4-Dimethoxyphenyl)Ethyl-Methyl-Amino]-2-(1-Methylethyl) Pentanenitrile",
      "2-(3,4-Dimethoxyphenyl)-5-[2-(3,4-Dimethoxyphenyl)Ethyl-Methylamino]-2-Propan-2-Ylpentanenitrile",
      "2-(3,4-Dimethoxyphenyl)-5-{[2-(3,4-Dimethoxyphenyl)Ethyl](Methyl)Amino}-2-(Propan-2-Yl)Pentanenitrile",
      "5-((3,4-Dimethoxyphenethyl)Methylamino)-2-(3,4-Dimethoxyphenyl)-2-Isopropylvaleronitrile",
      "5-[(3,4-Dimethoxyphenethyl)Methylamino]-2-(3,4-Dimethoxyphenyl)-2-Isopropylvaleronitrile",
      "Verapamil (Na-Ca Chanel Blocker)",
    ],
  },
  {
    primary_id: "D0R0FO",
    names: [
      "Lenvatinib",
      "E 7080",
      "E-7080, E7080",
      "4-[3-Chloro-4-(Cyclopropylcarbamoylamino)Phenoxy]-7-Methoxy-Quinoline-6-Carboxamide",
    ],
  },
  {
    primary_id: "D0R0FU",
    names: ["PXS-4159"],
  },
  {
    primary_id: "D0R0GK",
    names: ["NU300"],
  },
  {
    primary_id: "D0R0GY",
    names: ["Piperazine Carbamate/Urea Derivative 7"],
  },
  {
    primary_id: "D0R0HG",
    names: ["Osteoporosis Therapy, FAES Farma SA"],
  },
  {
    primary_id: "D0R0HI",
    names: ["N-(4-PHENYLAMINO-QUINAZOLIN-6-YL)-ACRYLAMIDE"],
  },
  {
    primary_id: "D0R0HY",
    names: ["IONIS-DMPK-2.5RX"],
  },
  {
    primary_id: "D0R0IB",
    names: ["VU0463597"],
  },
  {
    primary_id: "D0R0JE",
    names: [
      "3-(3-Bromophenylethynyl)-5-Methyl[1,2,4]triazine",
      "CHEMBL390803",
      "SCHEMBL4289011",
      "1,2,4-Triazine, 3-[2-(3-Bromophenyl)Ethynyl]-5-Methyl-",
      "BDBM50215451",
    ],
  },
  {
    primary_id: "D0R0JN",
    names: ["PRAX-330"],
  },
  {
    primary_id: "D0R0LB",
    names: ["PF-06747775"],
  },
  {
    primary_id: "D0R0LQ",
    names: [
      "5-Methoxy-2-(4-Dimethylaminophenyl)Benzofuran",
      "CHEMBL383116",
      "ZINC13686352",
      "BDBM50185945",
      "AKOS015965999",
      "KB-289042",
    ],
  },
  {
    primary_id: "D0R0MB",
    names: ["Ropeginterferon Alfa-2b"],
  },
  {
    primary_id: "D0R0ME",
    names: ["IDP-204040"],
  },
  {
    primary_id: "D0R0MM",
    names: ["Anti-GP88 Mabs"],
  },
  {
    primary_id: "D0R0MW",
    names: ["Sunitinib"],
  },
  {
    primary_id: "D0R0NX",
    names: ["AU-011"],
  },
  {
    primary_id: "D0R0OA",
    names: ["Tricyclic Indole Compound 3"],
  },
  {
    primary_id: "D0R0OC",
    names: ["U-104067F"],
  },
  {
    primary_id: "D0R0OX",
    names: ["Des-AA1,5-[Tyr2,D-Trp8,(NalphaMe)IAmp9]SRIF", "CHEMBL414692"],
  },
  {
    primary_id: "D0R0OZ",
    names: ["MORPHICEPTIN"],
  },
  {
    primary_id: "D0R0PC",
    names: ["HM 401"],
  },
  {
    primary_id: "D0R0PO",
    names: ["4-Carboxamido-Isoindolinone Derivative 3"],
  },
  {
    primary_id: "D0R0PU",
    names: ["7-(3-Fluoro-5-Methylphenyl)-1,6-Naphthyridine", "CHEMBL238389"],
  },
  {
    primary_id: "D0R0QY",
    names: ["Pyrrolo[1,2-C]pyrazole Derivative 1"],
  },
  {
    primary_id: "D0R0RW",
    names: ["1D05"],
  },
  {
    primary_id: "D0R0SJ",
    names: ["PMID25684022-Compound-WO2012135799 43(1.5)"],
  },
  {
    primary_id: "D0R0SP",
    names: ["LIPO-5"],
  },
  {
    primary_id: "D0R0TE",
    names: [
      "3-(6-Ethoxy-Naphthalen-2-Yl)-Pyridine",
      "3-(6-Ethoxynaphthalen-2-Yl)Pyridine",
      "SCHEMBL4511117",
      "BDBM8908",
      "CHEMBL193343",
      "3-(6-Ethoxy-2-Naphthyl)Pyridine",
      "Pyridine-Substituted Naphthalene 5",
    ],
  },
  {
    primary_id: "D0R0TF",
    names: ["BN50727"],
  },
  {
    primary_id: "D0R0TS",
    names: ["MK-1439", "Doravirine", "1338225-97-0", "UNII-913P6LK81M", "MK1439", "913P6LK81M", "MK 1439"],
  },
  {
    primary_id: "D0R0TW",
    names: ["KU-0060648"],
  },
  {
    primary_id: "D0R0UJ",
    names: [
      "Pargyline",
      "Benzylmethylpropargylamine",
      "Benzylmethylpropynylamine",
      "Eudatin",
      "Eutron",
      "Methylbenzylpropynylamine",
      "Paragyline",
      "Pargilina",
      "Pargylamine",
      "Pargylin",
      "Pargylinum",
      "Supirdyl",
      "A 19120",
      "MO 911",
      "Pargilina [INN-Spanish]",
      "Pargyline (INN)",
      "Pargyline [INN:BAN]",
      "Pargylinum [INN-Latin]",
      "Benzyl-Methyl-2-Propinylamin",
      "Benzyl-Methyl-2-Propinylamin [Czech]",
      "N-Methyl-N-Benzylpropynylamine",
      "N-Methyl-N-Propargylbenzylamine",
      "N-Methyl-N-2-Propynylbenzylamine",
      "N-Benzyl-N-Methyl-2-Propynylamine",
      "N-Benzyl-N-Methyl-2-Propyn-1",
      "N-Benzyl-N-Methyl-2-Propyn-1-Amine",
      "N-Benzyl-N-Methylprop-2-Yn-1-Amine",
      "N-Methyl-N-(Phenylmethyl)Prop-2-Yn-1-Amine",
    ],
  },
  {
    primary_id: "D0R0UV",
    names: ["PMID17963374C31"],
  },
  {
    primary_id: "D0R0UW",
    names: ["INKP-102"],
  },
  {
    primary_id: "D0R0UZ",
    names: ["3-Fluoro-5-(1,6-Naphthyridin-7-Yl)Benzonitrile", "CHEMBL238390"],
  },
  {
    primary_id: "D0R0VG",
    names: ["SK&F-96321"],
  },
  {
    primary_id: "D0R0WZ",
    names: ["KX01 And Paclitaxel"],
  },
  {
    primary_id: "D0R0XE",
    names: [
      "2-Acetyl-3-Hydroxycyclohex-2-Enone",
      "CHEMBL73025",
      "37514-00-4",
      "SCHEMBL5628771",
      "CTK1B5605",
      "DTXSID50472617",
      "YKOOMGHHVDVJAY-UHFFFAOYSA-N",
      "2-Acetyl-3-Hydroxy-Cyclohex-2-Enone",
      "2-Acetyl-3-Hydroxy-2-Cyclohexen-1-One",
      "2-Cyclohexen-1-One, 2-Acetyl-3-Hydroxy-",
    ],
  },
  {
    primary_id: "D0R0XY",
    names: ["C[YYDEGLEE]-NH2", "CHEMBL389147", "BDBM50223446"],
  },
  {
    primary_id: "D0R0YB",
    names: ["PMID25522065-Compound-6"],
  },
  {
    primary_id: "D0R0YW",
    names: ["HM-5016504", "C-Met Inhibitor (Cancer), Hutchison"],
  },
  {
    primary_id: "D0R0ZC",
    names: ["KNI-10539"],
  },
  {
    primary_id: "D0R0ZL",
    names: [
      "Clindamycin",
      "Antirobe",
      "CLDM",
      "CLY",
      "Chlolincocin",
      "Chlorlincocin",
      "Chlorodeoxylincomycin",
      "Chlorolincomycin",
      "Cleocin",
      "ClindaDerm",
      "Clindamicina",
      "Clindamycine",
      "Clindamycinum",
      "Clinimycin",
      "Dalacine",
      "Klimicin",
      "Sobelin",
      "Zindaclin",
      "Cleocin HCl",
      "Cleocin T Gel",
      "Cleocin T Lotion",
      "Cleocin T Topical Solution",
      "Clindamycine [French]",
      "Dalacin C",
      "Dalacin C Flavored Granules",
      "Dalacin C Phosphate",
      "Dalacin T Topical Solution",
      "ResiDerm A",
      "Klindan 300",
      "U 21251",
      "Cleocin (TN)",
      "Clindacin (TN)",
      "Clindamicina [INN-Spanish]",
      "Clindamycin & Interleukin 12",
      "Clindamycin & VRC3375",
      "Clindamycine [INN-French]",
      "Clindamycinum [INN-Latin]",
      "Dalacin (TN)",
      "Evoclin (TN)",
      "U-21251",
      "CLINDA & IL-12",
      "Clindamycin (USAN/INN)",
      "Clindamycin [USAN:BAN:INN]",
      "U-21,251",
      "7(S)-Chloro-7-Deoxylincomycin",
      "7-CDL",
      "7-Chloro-7-Deoxylincomycin",
      "7-Chlorolincomycin",
      "7-Deoxy-7(S)-Chlorolincomycin",
    ],
  },
  {
    primary_id: "D0R0ZP",
    names: ["VXA-RSV-F"],
  },
  {
    primary_id: "D0R1AB",
    names: ["H-Tyr-C[D-Allylgly-Gly-Phe-D-Allylgly]-OH"],
  },
  {
    primary_id: "D0R1AE",
    names: [
      "Methyl 2-(Naphthalen-2-Yl)Benzoate",
      "CHEMBL103936",
      "SCHEMBL16683936",
      "BDBM50090973",
      "ZINC13809589",
      "2-(2-Naphthyl)Benzoic Acid Methyl Ester",
      "2-Naphthalen-2-Yl-Benzoic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0R1BA",
    names: ["CAR-T Cells Targeting Muc1"],
  },
  {
    primary_id: "D0R1BD",
    names: [
      "Penicillin",
      "Cillin",
      "Pentids",
      "3,3-Dimethyl-7-Oxo-6-[(2-Phenylacetyl)Amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "3,3-Dimethyl-7-Oxo-6-[(Phenylacetyl)Amino]-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(Phenylmethyl)Penicillin",
      "7005-30-3",
      "NSC131815",
      "(Phenylmethyl)Penicillinic Acid",
      "3,3-Dimethyl-7-Oxo-6-((Phenylacetyl)Amino)-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "AC1L1DHC",
      "AC1Q5UVJ",
      "Penicilline G Sodium Salt",
      "Oprea1_713794",
      "Oprea1_861345",
      "CHEMBL300052",
      "SCHEMBL2109546",
      "CTK2H5530",
    ],
  },
  {
    primary_id: "D0R1BG",
    names: ["8-Methoxy-2-Morpholin-4-Yl-Chromen-4-One", "CHEMBL97024", "SCHEMBL3542889", "BDBM50159658"],
  },
  {
    primary_id: "D0R1CG",
    names: ["ASG-22ME"],
  },
  {
    primary_id: "D0R1CR",
    names: ["L-Phenylalanine"],
  },
  {
    primary_id: "D0R1DH",
    names: ["Oxybutynin Hydrochloride"],
  },
  {
    primary_id: "D0R1DP",
    names: ["A-74283"],
  },
  {
    primary_id: "D0R1DX",
    names: [
      "Adiponectin Mimetics",
      "Adiponectin Mimetics (Oral, Type 2 Diabetes/Atherosclerosis/Muscle Metabolic Diseases)",
    ],
  },
  {
    primary_id: "D0R1DY",
    names: ["H-Dmt-Tic-Lys-NH-CH2-Ph"],
  },
  {
    primary_id: "D0R1EO",
    names: [
      "[3-(Biphenyl-4-Yloxy)-Propyl]-Dimethyl-Amine",
      "CHEMBL132091",
      "N,N-Dimethyl-3-(4-Phenylphenoxy)Propan-1-Amine",
      "AC1L9UWB",
      "SCHEMBL10679594",
      "ZINC4842179",
      "BDBM50133286",
    ],
  },
  {
    primary_id: "D0R1ER",
    names: ["2-(1H-Indol-3-Ylmethyl)-1,2-Dihydro-Indazol-3-One", "CHEMBL3144574", "CHEMBL8374", "BDBM50009014"],
  },
  {
    primary_id: "D0R1FP",
    names: ["PMID2153213C1a"],
  },
  {
    primary_id: "D0R1FR",
    names: ["(3R)-9-Amino-3-Methyl-1,2,3,4-Tetrahydroacridine", "CHEMBL566788"],
  },
  {
    primary_id: "D0R1FT",
    names: [
      "6-O-Cyclohexylmethyl Guanine",
      "6-(Cyclohexylmethoxy)-9H-Purin-2-Amine",
      "Nu2058",
      "161058-83-9",
      "6-O-CYCLOHEXYLMETHYL GUANINE",
      "2-Amino-6-[(Cyclohexylmethyl)Oxy]purine",
      "NU 2058",
      "2-Amino-6-Cyclohexylmethoxypurine",
      "NU-2058",
      "O6-Cyclohexylmethylguanine",
      "CHEMBL269881",
      "1e1v",
      "1h1p",
      "O-Cyclohexylmethylguanine",
      "AC1Q4XUD",
      "9H-Purin-2-Amine, 6-(Cyclohexylmethoxy)-",
      "O6-Cyclohexylmethyl Guanine",
      "AC1L1IG4",
      "MLS001074898",
      "SCHEMBL3462331",
      "BDBM5485",
      "CTK8D6642",
      "CTK0E6580",
      "EX-A790",
      "AOB6207",
      "MolPort-033-437-778",
      "MolPort-003-958-993",
      "MolPort-044-561-861",
      "HMS3372K06",
      "HMS3262L18",
      "HMS2233F06",
    ],
  },
  {
    primary_id: "D0R1FZ",
    names: ["LXA4"],
  },
  {
    primary_id: "D0R1GD",
    names: ["Aryl Mannoside Derivative 16"],
  },
  {
    primary_id: "D0R1GF",
    names: [
      "2-[(Biphenyl-4-Carbonyl)-Amino]-Benzoic Acid",
      "2-[(Biphenyl-4-Ylcarbonyl)Amino]benzoic Acid",
      "94578-69-5",
      "AC1LIZYF",
      "2-[(4-Phenylbenzoyl)Amino]benzoic Acid",
      "Oprea1_107922",
      "CHEMBL370865",
      "SCHEMBL14075233",
      "BDBM16094",
      "MolPort-000-468-692",
      "ZINC499675",
      "STK068761",
      "BBL015607",
      "AKOS002229873",
      "MCULE-9626131737",
      "2-[(4-Phenylbenzene)Amido]benzoic Acid",
      "ST50103720",
      "H3372",
      "2-[(4-Phenylphenyl)Carbonylamino]benzoic Acid",
    ],
  },
  {
    primary_id: "D0R1GJ",
    names: ["SRP-4045", "Casimersen"],
  },
  {
    primary_id: "D0R1GV",
    names: ["DPX-Survivac"],
  },
  {
    primary_id: "D0R1HK",
    names: ["T-9001"],
  },
  {
    primary_id: "D0R1IL",
    names: [
      "4-Naphthalen-2-Yl-5-Piperidin-4-Yl-Isoxazol-3-Ol",
      "CHEMBL382320",
      "BDBM50159237",
      "J3.626.536A",
      "4-(Naphthalen-2-Yl)-5-(Piperidin-4-Yl)Isoxazol-3-Ol",
    ],
  },
  {
    primary_id: "D0R1JB",
    names: [
      "Acellular Pertussis Vaccine",
      "Acellular Pertussis Vaccine (Weed)",
      "Pollinex Quattro (Weed Allergy Vaccine)",
      "Quattro MPL (Weed Allergy Vaccine)",
      "Mugwort/Plantain SC Vaccines, Allergy Therapeutics",
      "MPL-Containing Pollinex Allergy Desensitization Vaccine (Weed), Allergy Therapeutics",
    ],
  },
  {
    primary_id: "D0R1JI",
    names: ["Rottlerin"],
  },
  {
    primary_id: "D0R1JM",
    names: ["NRT Inhibitor"],
  },
  {
    primary_id: "D0R1JO",
    names: ["SHP607"],
  },
  {
    primary_id: "D0R1JV",
    names: [
      "Ziprasidone",
      "146939-27-7",
      "Geodon",
      "Zeldox",
      "Ziprasidone Hydrochloride",
      "Ziprasidone [INN:BAN]",
      "UNII-6UKA5VEJ6X",
      "CP 88059",
      "Ziprasidone Mesylate Trihydrate",
      "6UKA5VEJ6X",
      "CHEMBL708",
      "C21H21ClN4OS",
      "CHEBI:10119",
      "CP-88,059-1",
      "Ziprazidone",
      "DSSTox_CID_3753",
      "DSSTox_RID_77186",
      "5-[2-[4-(1,2-Benzothiazol-3-Yl)Piperazin-1-Yl]ethyl]-6-Chloro-1,3-Dihydroindol-2-One",
      "DSSTox_GSID_23753",
      "Ziprasidonum",
      "Ziprasidona",
      "5-(2-(4-(Benzo[d]isothiazol-3-Yl)Piperazin-1-Yl)Ethyl)-6-Chloroindolin-2-One",
      "Zipradon",
      "CP 88059-01",
      "Geodon (TN)",
      "Zeldox (TN)",
      "Zipradon (TN)",
      "Ziprasidone (INN)",
      "CP-88,059",
      "CP-88059-1",
      "CP-88,059-01",
      "5-{2-[4-(1,2-Benzisothiazol-3-Yl)Piperazin-1-Yl]ethyl}-6-Chloro-1,3-Dihydro-2H-Indol-2-One",
      "5-{2-[4-(1,2-Benzothiazol-3-Yl)Piperazin-1-Yl]ethyl}-6-Chloro-1,3-Dihydro-2H-Indol-2-One",
      "6-Chloro-5-[2-[4-(7-Thia-8-Azabicyclo[430]nona-1,3,5,8-Tetraen-9-Yl)Piperazin-1-Yl]ethyl]-1,3-Dihydroindol-2-One",
      "TC-5280",
    ],
  },
  {
    primary_id: "D0R1KE",
    names: [
      "2-(4-Bromo-2-(Hydroxymethyl)Phenoxy)Acetic Acid",
      "CHEMBL214655",
      "2-[4-Bromo-2-(Hydroxymethyl)Phenoxy]acetic Acid",
      "AC1LHWL0",
      "AH-487/41804565",
      "SCHEMBL3201339",
      "MolPort-002-091-532",
      "ZINC470785",
      "HMS1590C02",
      "BDBM50200250",
      "MCULE-6729250214",
      "SR-01000266540",
    ],
  },
  {
    primary_id: "D0R1KR",
    names: ["Anti-Emetics", "Anti-Emetics (Iontophoretic Patch, Nausea)"],
  },
  {
    primary_id: "D0R1LT",
    names: ["E047/1", "1-(2-Butyl-1-Benzofuran-3-Yl)-1-[5-[2-(Diethylamino)Ethoxy]-2-Thienyl]methanone Hydrochloride"],
  },
  {
    primary_id: "D0R1MB",
    names: ["DIHYDROAKUAMMINE", "Dihydroakuammine"],
  },
  {
    primary_id: "D0R1MM",
    names: ["6-N-Octylaminouracil", "6-N-Octyl Aminouracil"],
  },
  {
    primary_id: "D0R1MR",
    names: ["Ac-I[CVFQDWGHHRC]T-NH2"],
  },
  {
    primary_id: "D0R1NH",
    names: ["C[Glu24-Lys28][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0R1NR",
    names: ["JWH-371"],
  },
  {
    primary_id: "D0R1OG",
    names: ["2,2-Bipyridyl Derivative 1"],
  },
  {
    primary_id: "D0R1PD",
    names: ["RU83876"],
  },
  {
    primary_id: "D0R1PX",
    names: ["Pyrano[2,3-B]pyridine Derivative 1"],
  },
  {
    primary_id: "D0R1PZ",
    names: ["F-15063", "D2/D3 Antagonists (Psychosis/Schizophrenia), Pierre Fabre"],
  },
  {
    primary_id: "D0R1QE",
    names: ["Ibuprofen"],
  },
  {
    primary_id: "D0R1QH",
    names: ["99m-Tc-Tropantiol"],
  },
  {
    primary_id: "D0R1QI",
    names: ["Aryl Azepine Derivative 2"],
  },
  {
    primary_id: "D0R1QJ",
    names: ["D-22888", "AWD-12-232"],
  },
  {
    primary_id: "D0R1QW",
    names: ["(R)-3-(Aminomethyl)-4-(Furan-2-Yl)Butanoic Acid", "CHEMBL377707", "SCHEMBL6382413"],
  },
  {
    primary_id: "D0R1QX",
    names: [
      "EC-708",
      "Colorectal Cancer Vaccine, Biovation",
      "Prostate Tumor Therapy, Biovation",
      "Deimmunized Antibodies (Prostatic Tumor), Biovation",
    ],
  },
  {
    primary_id: "D0R1RK",
    names: ["EpiCept"],
  },
  {
    primary_id: "D0R1RQ",
    names: ["BJ-B11"],
  },
  {
    primary_id: "D0R1RS",
    names: [
      "Indomethacin",
      "Aconip",
      "Amuno",
      "Arthrexin",
      "Artracin",
      "Artrinovo",
      "Artrivia",
      "Bonidin",
      "Bonidon",
      "Catlep",
      "Confortid",
      "Dolcidium",
      "Dolovin",
      "Durametacin",
      "Elmetacin",
      "Hicin",
      "IMN",
      "Idomethine",
      "Imbrilon",
      "Inacid",
      "Indacin",
      "Indameth",
      "Indmethacine",
      "Indocid",
      "Indocin",
      "Indomecol",
      "Indomed",
      "Indomee",
      "Indometacin",
      "Indometacina",
      "Indometacine",
      "Indometacinum",
      "Indometacyna",
      "Indomethacine",
      "Indomethacinum",
      "Indomethancin",
      "Indomethazine",
      "Indomethegan",
      "Indomethine",
      "Indometicina",
      "Indomo",
      "Indomod",
      "Indoptic",
      "Indoptol",
      "Indorektal",
      "Indoxen",
      "Inflazon",
      "Infrocin",
      "Lausit",
      "Liometacen",
      "Metacen",
      "Metartril",
      "Methazine",
      "Metindol",
      "Mezolin",
      "Miametan",
      "Mikametan",
      "Mobilan",
      "Novomethacin",
      "Reumacide",
      "Sadoreum",
      "Tannex",
      "Vonum",
      "Bonidon Gel",
      "DESMETHYL INDOMETHACIN",
      "Dolcidium PL;Flexin Continus",
      "Indocid Pda",
      "Indocid Sr",
      "Indocin Sr",
      "Indolar SR",
      "Indometacyna [Polish]",
      "Indometicina [Spanish]",
      "Inteban Sp",
      "Rhemacin LA",
      "Rheumacin LA",
      "I 7378",
      "IN1454",
      "Indomet 140",
      "Aconip (TN)",
      "Apo-Indomethacin",
      "Chibro-Amuno",
      "Chrono-Indicid",
      "Chrono-Indocid",
      "Indo-Lemmon",
      "Indo-Spray",
      "Indo-Phlogont",
      "Indo-Rectolmin",
      "Indo-Tablinen",
      "Indocid (TN)",
      "Indocid (Pharmaceutical)",
      "Indocin (TN)",
      "Indocin I.V",
      "Indometacina [INN-Spanish]",
      "Indometacine [INN-French]",
      "Indometacinum [INN-Latin]",
      "Indomethacin (USP)",
      "Indomethacin [USAN:BAN]",
      "Novo-Methacin",
      "Indochron E-R (TN)",
      "Indocin I.V.",
      "Indocin-SR (TN)",
      "Indometacin (JP15/INN)",
      "Indomethacin & MAP-30",
      "Indomethacin, Indochron E-R, Indocin-SR, Indocid, Indocin, Indomethacin",
    ],
  },
  {
    primary_id: "D0R1RV",
    names: ["ZP-008"],
  },
  {
    primary_id: "D0R1SQ",
    names: ["Biaryl Mannoside Derivative 1"],
  },
  {
    primary_id: "D0R1SV",
    names: ["GSK1014802"],
  },
  {
    primary_id: "D0R1TF",
    names: ["CNTF"],
  },
  {
    primary_id: "D0R1UA",
    names: ["GNF-PF-5188"],
  },
  {
    primary_id: "D0R1UE",
    names: ["CLR325"],
  },
  {
    primary_id: "D0R1UO",
    names: ["2-(2-Methylpropanoyl)-1,3,5-Benzenetriol"],
  },
  {
    primary_id: "D0R1VL",
    names: [
      "BIBN-99",
      "Bibn-99",
      "Bibn 99",
      "145301-48-0",
      "AC1L31GK",
      "SCHEMBL194898",
      "DTXSID00162975",
      "N-(3-(1-(2-(8-Chloro-5,6-Dihydro-6-Oxo-11H-Pyrido(2,3-B)(1,4)Benzodiazepin-11-Yl)-2-Oxoethyl)-4-Piperidinyl)Propyl)-N-Ethyl-2,2-Dimethylpentanamide",
      "L008252",
      "N-[3-[1-[2-(8-Chloro-6-Oxo-5H-Pyrido[2,3-B][1,4]benzodiazepin-11-Yl)-2-Oxoethyl]piperidin-4-Yl]propyl]-N-Ethyl-2,2-Dimethylpentanamide",
      "Pentanamide, N-(3-(1-(2-(8-Chloro-5,6-Dihydro-6-Oxo-11H-Pyrido(2,3-B)(1,4)Benzodiazepin-11-Yl)-2-Oxoethyl)-4-Piperidinyl)Propyl)-N-Ethyl-2,2-Dimethyl-",
    ],
  },
  {
    primary_id: "D0R1VU",
    names: [
      "Perifosine",
      "D 21266",
      "NKA17",
      "D-21266",
      "KRX-0401",
      "KRX-0401, NSC 639966",
      "Octadecyl-(1,1-Dimethyl-4-Piperidylio)Phosphate",
      "Piperidinium, 4-[[hydroxy(Octadecyloxy)Phosphinyl]oxy]-1,1-Dimethyl-, Inner Salt",
      "Piperidinium, 4-((Hydroxy(Octadecyloxy)Phosphinyl)Oxy)-1,1-Dimethyl-, Inner Salt",
      "(1,1-Dimethylpiperidin-1-Ium-4-Yl) Octadecyl Phosphate",
      "4-((Hydroxy(Octadecyloxy)Phosphinyl)Oxy)-1,1-Dimethylpiperidinium Inner Salt",
    ],
  },
  {
    primary_id: "D0R1WH",
    names: ["[L-Tic7]OT", "CHEMBL397407"],
  },
  {
    primary_id: "D0R1WQ",
    names: ["Thallous Chloride Tl-201"],
  },
  {
    primary_id: "D0R1WR",
    names: [
      "Procyclidine",
      "Arpicolin",
      "Elorine",
      "Kemadrin",
      "Kemadrine",
      "Lergine",
      "Metanin",
      "Osnervan",
      "Prociclidina",
      "Procidlidina",
      "Procyclid",
      "Procyclidinum",
      "Procyklidin",
      "Prosyklidin",
      "Spamol",
      "Triciclidina",
      "Triciloid",
      "Tricoloid",
      "Tricyclamol",
      "Vagosin",
      "Prociclidina [INN-Spanish]",
      "Procyclidine (INN)",
      "Procyclidine [INN:BAN]",
      "Procyclidinum [INN-Latin]",
      "1-Cyclohexyl-1-Phenyl-3-(1-Pyrrolidinyl)-1-Propanol",
      "1-Cyclohexyl-1-Phenyl-3-Pyrrolidino-1-Propanol",
      "1-Cyclohexyl-1-Phenyl-3-Pyrrolidin-1-Ylpropan-1-Ol",
    ],
  },
  {
    primary_id: "D0R1XF",
    names: ["212-Pb-TCMC-Trastuzumab"],
  },
  {
    primary_id: "D0R1XI",
    names: [
      "TRK-851",
      "(4bS,8R,8aS,16bR)-7-(Cyclopropylmethyl)-11-Fluoro-5,6,7,8,9,14,15,16b-Octahydro-4,8-Methano-8aH,13H-[1]benzofuro[2,3-A]dipyrido[4,3-B:3',2',1'-Jk]carbazole-1,8a-Diol",
      "189015-25-6",
    ],
  },
  {
    primary_id: "D0R1XO",
    names: ["Next Generation Recombinant Soluble Fc Receptors"],
  },
  {
    primary_id: "D0R1XT",
    names: ["Indol-1-Yl-Methyl-Pyridin-4-Yl-Amine", "CHEMBL152842", "ZINC3997", "SCHEMBL7615812", "BDBM50048589"],
  },
  {
    primary_id: "D0R1XY",
    names: [
      "[3H]CCPA",
      "2-Chloro-N6-Cyclopentyladenosine",
      "CCPA",
      "37739-05-2",
      "2-CHLORO-N-CYCLOPENTYLADENOSINE",
      "2-Chloro-N(6)Cyclopentyladenosine",
      "Adenosine, 2-Chloro-N-Cyclopentyl-",
      "BRN 4888162",
      "CHEMBL284969",
      "(2R,3R,4S,5R)-2-[2-Chloro-6-(Cyclopentylamino)Purin-9-Yl]-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "(2R,3R,4S,5R)-2-(2-Chloro-6-(Cyclopentylamino)-9H-Purin-9-Yl)-5-(Hydroxymethyl)Tetrahydrofuran-3,4-Diol",
      "2-Chloro-N6-Cyclopentyladenosine Hemihydrate",
      "C15H20ClN5O4",
      "[3H]-CCPA",
      "Ccpa (Biochemistry)",
      "AC1L3XBY",
      "2-Cl-CPA",
    ],
  },
  {
    primary_id: "D0R1YJ",
    names: ["HCGRP8-37", "Anti-CGRP Patch (Transdermal/Microneedle Formulation, Neurogenic Inflammatory Pain), AFASCI"],
  },
  {
    primary_id: "D0R1YL",
    names: [
      "NGX-267",
      "503431-81-0",
      "AF267B",
      "(2S)-2-ETHYL-8-METHYL-1-THIA-4,8-DIAZASPIRO[4,5]DECAN-3-ONE",
      "UNII-8D3PZX7G73",
      "AF 267B",
      "8D3PZX7G73",
      "(S)-2-Ethyl-8-Methyl-1-Thia-4,8-Diazaspiro[4.5]decan-3-One",
      "NGX267",
      "NGX 267",
      "SCHEMBL3651146",
      "CTK1G8674",
      "DTXSID40198399",
      "AF-267B",
      "PHOZOHFUXHPOCK-QMMMGPOBSA-N",
      "ZINC13816318",
      "AKOS025295388",
      "NCGC00162382-01",
      "RT-005412",
      "FT-0668336",
      "(S)-2-Ethyl-8-Methyl-1-Thia-4,8-Diaza-Spiro[4.5]decan-3-One",
      "1-Thia-4,8-Diazaspiro(4.5)Decan-3-One, 2-Ethyl-8-Methyl-, (2S)-",
    ],
  },
  {
    primary_id: "D0R1YP",
    names: [
      "Trans,trans-1,3-Bis-(4-Hydroxycyclohexyl)Urea",
      "CHEMBL231584",
      "1,3-Bis(4-Hydroxycyclohexyl)Urea",
      "SCHEMBL3195592",
      "SCHEMBL3195603",
      "MolPort-005-690-989",
      "BDBM50217480",
      "ZINC24975985",
      "AKOS034042111",
      "ZINC253654706",
    ],
  },
  {
    primary_id: "D0R1YT",
    names: ["[18F]MK-1312", "CHEMBL1645349", "GTPL6334", "MK-1312"],
  },
  {
    primary_id: "D0R1ZD",
    names: ["GSK575594A"],
  },
  {
    primary_id: "D0R2AI",
    names: ["4-(2-Cyclohexylethoxy) Aniline Derivative 1"],
  },
  {
    primary_id: "D0R2AT",
    names: [
      "2-(4-CARCOXY-5-ISOPROPYLTHIAZOLYL)BENZOPIPERIDINE",
      "DB08192",
      "5-Isopropyl-2-[(3S)-1,2,3,4-Tetrahydroisoquinolin-3-Yl]-1,3-Thiazole-4-Carboxylic Acid",
      "5-(1-Methylethyl)-2-[(3S)-1,2,3,4-Tetrahydroisoquinolin-3-Yl]-1,3-Thiazole-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0R2BA",
    names: [
      "D-Gluconhydroximo-1,5-Lactam",
      "D-GLUCONHYDROXIMO-1,5-LACTAM",
      "CHEMBL1213470",
      "(Z)-D-Gluconhydroximo-1,5-Lactam",
      "GOX",
      "(2S,3S,4R,5R)-6-(HYDROXYAMINO)-2-(HYDROXYMETHYL)-2,3,4,5-TETRAHYDROPYRIDINE-3,4,5-TRIOL",
      "CID445248",
      "GHIL",
      "1e6s",
      "AC1L9HO3",
      "BDBM36390",
      "3d51",
      "2j78",
      "BDBM50120840",
      "DB02376",
      "(Z)-D-Gluconhydroximo-1,5-Lactam, 15",
      "(2Z,3S,4S,5R,6R)-3,4,5-Trihydroxy-6-(Hydroxymethyl)Piperidin-2-One Oxime",
    ],
  },
  {
    primary_id: "D0R2BM",
    names: ["1-(4-Aminophenyl)-2-Pyrrolidin-1-Yl-Pentan-1-One", "CHEMBL555491"],
  },
  {
    primary_id: "D0R2BO",
    names: ["METHYLAMINO-PHENYLALANYL-LEUCYL-HYDROXAMIC ACID"],
  },
  {
    primary_id: "D0R2CF",
    names: [
      "7-(3,5-Dimethoxyphenyl)-1,6-Naphthyridine",
      "CHEMBL236262",
      "MLS002402797",
      "HMS3344J20",
      "HMS2201J14",
      "BDBM50224927",
    ],
  },
  {
    primary_id: "D0R2CI",
    names: ["GI-264879A"],
  },
  {
    primary_id: "D0R2CM",
    names: [
      "TA-383",
      "Cis-2-(4-Chlorophenyl)-4,5-Diphenyl-2-Imidazoline Hydrochloride",
      "Cis-2-(4-Chlorophenyl)-4,5-Diphenyl-4,5-Dihydro-1H-Imidazole Hydrochloride",
    ],
  },
  {
    primary_id: "D0R2CZ",
    names: ["Rsv Immune Globulin"],
  },
  {
    primary_id: "D0R2DP",
    names: ["XAP044", "7-Hydroxy-3-(4-Iodophenoxy)-4H-Chromen-4-One"],
  },
  {
    primary_id: "D0R2ES",
    names: ["2-Phenyl-5,6-Dihydro-Pyrazolo[1,5-C]quinazoline"],
  },
  {
    primary_id: "D0R2EY",
    names: [
      "SKF-97574",
      "3-Butyryl-4-(2-Methylphenylamino)-8-(2-Hydroxyethoxy)Quinoline",
      "SCHEMBL9264291",
      "FOYCAZSJGNVMMS-UHFFFAOYSA-N",
      "SKF 97574",
      "F-97574",
      "3-Butyryl-4-(2-Methylphenylamino)-8-(2-Hydroxyethoxy)Quinoline Hydrochloride",
      "1-(8-(2-Hydroxyethoxy)-4-((2-Methylphenyl)Amino)-3-Quinolinyl)-1-Butanone Monohydrochloride",
      "1-Butanone, 1-(8-(2-Hydroxyethoxy)-4-((2-Methylphenyl)Amino)-3-Quinolinyl)-, Monohydrochloride",
    ],
  },
  {
    primary_id: "D0R2FS",
    names: ["Ac-I[CVWQDWGAHRC]dT"],
  },
  {
    primary_id: "D0R2GA",
    names: ["Biaryl Mannoside Derivative 29"],
  },
  {
    primary_id: "D0R2GR",
    names: ["N-Neopentyl-1'H-Phenothiazine-1'-Carboxamide", "CHEMBL592432", "BDBM50308432"],
  },
  {
    primary_id: "D0R2GU",
    names: ["XRX-001"],
  },
  {
    primary_id: "D0R2HF",
    names: [
      "6-(2-Mercaptoacetamido)-N-Phenylhexanamide",
      "CHEMBL178727",
      "6-(2-Mercaptoacetylamino)-N-Phenylhexanamide",
      "824970-14-1",
      "4cqf",
      "SCHEMBL5998899",
      "CTK3D8890",
      "DTXSID50465903",
      "ZINC13609328",
      "BDBM50157884",
      "AKOS030603047",
      "N-Phenyl-6-[(Sulfanylacetyl)Amino]hexanamide",
      "N-Phenyl-6-[(Mercaptoacetyl)Amino]hexanamide",
      "Hexanamide, 6-[(Mercaptoacetyl)Amino]-N-Phenyl-",
      "6-(2-Mercapto-Acetylamino)-Hexanoic Acid Phenylamide",
    ],
  },
  {
    primary_id: "D0R2HJ",
    names: ["Isoquinoline-3-Carboxylic Acid Methyl Ester"],
  },
  {
    primary_id: "D0R2HZ",
    names: ["ETHOXY-IDAZOXAN"],
  },
  {
    primary_id: "D0R2JA",
    names: ["[35S]-Non-Peptide OT Antagonist", "GTPL3484", "[35S]non-Peptide OT Antagonist"],
  },
  {
    primary_id: "D0R2JE",
    names: ["N-Methoxyquinuclidine-3-Carboximidoyl Chloride", "CHEMBL135604"],
  },
  {
    primary_id: "D0R2JS",
    names: ["Droloxifene", "E-Droloxifene", "FK-435", "K-060", "K-060E", "RP-60850", "K-21.060E", "3-Hydroxytamoxifen"],
  },
  {
    primary_id: "D0R2JT",
    names: [
      "Iodocyanopindolol",
      "4-[2-Hydroxy-3-(Propan-2-Ylamino)Propoxy]-3-Iodo-1H-Indole-2-Carbonitrile",
      "GTPL562",
      "GTPL540",
    ],
  },
  {
    primary_id: "D0R2KF",
    names: ["Epervudine"],
  },
  {
    primary_id: "D0R2KJ",
    names: [
      "Dicloxacillin",
      "Dichloroxacillin",
      "Diclossacillina",
      "Dicloxaciclin",
      "Dicloxacilin",
      "Dicloxacilina",
      "Dicloxacilline",
      "Dicloxacillinum",
      "Dicloxacycline",
      "Dycill",
      "Dynapen",
      "Maclicine",
      "Methyldichlorophenylisoxazolylpenicillin",
      "Pathocil",
      "Diclossacillina [DCIT]",
      "Dicloxacillin Sodium",
      "BRL 1702",
      "Diclocil (TN)",
      "Dicloxacilina [INN-Spanish]",
      "Dicloxacilline [INN-French]",
      "Dicloxacillinum [INN-Latin]",
      "R-13423",
      "Dicloxacillin (USAN/INN)",
      "Dicloxacillin [USAN:INN:BAN]",
      "Dicloxacillin, Monosodium Salt, Mono-Hydrate",
      "(2S,5R,6R)-6-({[3-(2,6-Dichlorophenyl)-5-Methyl-1,2-Oxazol-4-Yl]carbonyl}amino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-({[3-(2,6-Dichlorophenyl)-5-Methylisoxazol-4-Yl]carbonyl}amino)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(2S,5R,6R)-6-[[3-(2,6-Dichlorophenyl)-5-Methyl-1,2-Oxazole-4-Carbonyl]amino]-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "3-(2,6-Dichlorophenyl)-5-Methyl-4-Isoxazolylpenicillin",
      "6-(3-(2,6-Dichlorophenyl)-5-Methyl-4-Isoxazolecarboxamido)-3,3-Dimethyl-7-Oxo-4-Thia-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "6-(3-(2,6-Dichlorophenyl)-5-Methyl-4-Isoxazolecarboxamido)Penicillanic Acid",
      "6beta-{[3-(2,6-Dichlorophenyl)-5-Methyl-1,2-Oxazol-4-Yl]carboxamido}-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0R2KL",
    names: ["L-644,698"],
  },
  {
    primary_id: "D0R2KO",
    names: ["CAF-01"],
  },
  {
    primary_id: "D0R2KR",
    names: ["Human Cytomegalovirus Vaccine"],
  },
  {
    primary_id: "D0R2KV",
    names: ["CP-316,311", "CP-316311"],
  },
  {
    primary_id: "D0R2KY",
    names: [
      "Ethynodiol Diacetate",
      "Cervicundin",
      "Continuin",
      "Femulen",
      "Luteonorm",
      "Metrodiol",
      "Ethinodiol Diacetate",
      "Ethyndiol Diacetate",
      "Ethynodiol Diacetate [Progestins]",
      "Ethynodiol Diacetate [USAN]",
      "Etynodiol Acetate",
      "Metrodiol Diacetate",
      "Ovulen 50",
      "SC 11800",
      "Etynodiol Di(Acetate)",
      "Luto-Metrodiol",
      "SC-11800",
      "Ethynodiol Diacetate (JAN/USP);[(3S,8R,9S,10R,13S,14S,17R)-17-Acetyloxy-17-Ethynyl-13-Methyl-2,3,6,7,8,9,10,11,12,14,15,16-Dodecahydro-1H-Cyclopenta[a]phenanthren-3-Yl] Acetate",
      "(3 Beta, 17 Alpha)-19-Norpregn-4-En-20-Yne-3,17 DiolDiacetate",
      "(3-Beta,17-Alpha)-19-Norpregn-4-En-20-Yne-3,17-Diol Diacetate",
      "(3beta,17beta)-17-Ethynylestr-4-Ene-3,17-Diyl Diacetate",
      "17-Alpha-Ethynyl-19-Norandrost-4-Ene-3-Beta,17-Beta-Diol Diacetate",
      "17-Alpha-Ethynyl-3,17-Dihydroxy-4-Estrene Diacetate",
      "17-Alpha-Ethynyl-4-Estrene-3-Beta,17-Beta-Diol Diacetate",
      "17-Alpha-Ethynylestr-4-Ene-3-Beta,17-Beta-Diol Acetate",
      "17alpha-Ethynyl-19-Norandrost-4-Ene-3beta,17-Beta-Diol Diacetate",
      "17alpha-Ethynyl-3,17-Dihydroxy-4-Estrene Diacetate",
      "17alpha-Ethynyl-4-Estrene-3beta,17beta-Diol Diacetate",
      "17alpha-Ethynylestr-4-Ene-3beta,17beta-Diol Acetate",
      "17alpha-Ethynylestr-4-Ene-3beta,17beta-Diyl Diacetate",
      "19-Nor-17-Alpha-Pregn-4-En-20-Yne-3-Beta,17-Diol Diacetate",
      "19-Nor-17-Alpha-Pregn-4-En-20-Yne-3-Beta,17-Diol, Diacetate",
      "19-Nor-17alpha-Pregn-4-En-20-Yne-3beta,17-Diol Diacetate",
      "3-Beta, 17-Beta-Diacetoxy-17-Alpha-Ethynyl-4-Oestrene",
      "3-Beta,17-Beta-Diacetoxy-19-Nor-17-Alpha-Pregn-4-En-20-Yne",
      "3beta, 17beta-Diacetoxy-17alpha-Ethynyl-4-Oestrene",
      "3beta,17beta-Diacetoxy-17alpha-Ethynyl-4-Estrene",
      "3beta,17beta-Diacetoxy-19-Nor-17alpha-Pregn-4-En-20-Yne",
    ],
  },
  {
    primary_id: "D0R2LR",
    names: ["GR-108359"],
  },
  {
    primary_id: "D0R2LS",
    names: ["SR-125543A"],
  },
  {
    primary_id: "D0R2LY",
    names: ["Multipeptide Vaccine Combination"],
  },
  {
    primary_id: "D0R2MG",
    names: ["HB-1275"],
  },
  {
    primary_id: "D0R2MO",
    names: ["2-Oxoguaia-1,4,11(13)-Trien-12,8alpha-Olide", "CHEMBL191179"],
  },
  {
    primary_id: "D0R2MQ",
    names: ["BW-858C"],
  },
  {
    primary_id: "D0R2NE",
    names: ["Entacapone+levodopa+carbidopa"],
  },
  {
    primary_id: "D0R2OA",
    names: ["Ipriflavone"],
  },
  {
    primary_id: "D0R2OU",
    names: ["Irdabisant", "CEP-26401"],
  },
  {
    primary_id: "D0R2OX",
    names: [
      "5-MPEP",
      "5-Methyl-2-(Phenylethynyl)Pyridine",
      "124300-38-5",
      "5MPEP",
      "GTPL1419",
      "SCHEMBL3955825",
      "CTK8E1822",
      "2-(Phenylethynyl)-5-Methylpyridine",
      "ZINC65731259",
      "6379AJ",
      "5-Methyl-2-(2-Phenylethynyl)Pyridine",
      "AKOS022184010",
      "SY015649",
      "KB-73636",
      "AX8280780",
      "AJ-115560",
      "TC-307922",
      "MFCD18205120 (95%)",
    ],
  },
  {
    primary_id: "D0R2PR",
    names: ["Zn-DPA-P"],
  },
  {
    primary_id: "D0R2PW",
    names: [
      "2-(3-Aminophenyl)-3-Mercaptopropanoic Acid",
      "CHEMBL246595",
      "607832-91-7",
      "SCHEMBL2352530",
      "BDBM50201431",
    ],
  },
  {
    primary_id: "D0R2QB",
    names: ["MT-7716"],
  },
  {
    primary_id: "D0R2QC",
    names: ["4-[4-(Benzhydryloxy)Piperidino]butyl Benzoate", "CHEMBL228999", "SCHEMBL4020166"],
  },
  {
    primary_id: "D0R2QG",
    names: ["TMI-358"],
  },
  {
    primary_id: "D0R2QS",
    names: [
      "Neplanocin A",
      "72877-50-0",
      "NPC-A",
      "A-11079-B1B",
      "NEOPLANOCIN A",
      "(1s,2r,5r)-5-(6-Amino-9h-Purin-9-Yl)-3-(Hydroxymethyl)Cyclopent-3-Ene-1,2-Diol",
      "NSC 316458",
      "CHEMBL8771",
      "(-)-9-(Trans-2,trans-3-Dihydroxy-4-(Hydroxymethyl)-Cyclopent-4-Enyl)-Adenine",
      "4-Cyclopentene-1,2-Diol, 3-(6-Amino-9H-Purin-9-Yl)-5-(Hydroxymethyl)-, (1S,2R,3R)-",
      "(1S,2R,5R)-5-(6-Aminopurin-9-Yl)-3-(Hydroxymethyl)Cyclopent-3-Ene-1,2-Diol",
      "Nephanocin A",
      "AC1Q4VG9",
      "AC1L2J1E",
      "SCHEMBL10469277",
      "CTK5D6979",
      "MolPort-044-561-069",
      "ZINC3832328",
      "BDBM50006222",
      "CCG-208688",
      "DIBRT",
    ],
  },
  {
    primary_id: "D0R2QV",
    names: ["PMID25666693-Compound-122"],
  },
  {
    primary_id: "D0R2RR",
    names: ["Typhoid Vaccine"],
  },
  {
    primary_id: "D0R2RV",
    names: [
      "Anti-EGFR Humanized Mabs",
      "Anti-EGFR Humanized MAbs (Cancer)",
      "Anti-EGFR Humanized MAbs (Cancer), Xencor",
      "Anti-EGFR Humanized Monoclonal Antibodies (Cancer), Xencor",
    ],
  },
  {
    primary_id: "D0R2SK",
    names: ["Erteberel"],
  },
  {
    primary_id: "D0R2SW",
    names: ["FSA2"],
  },
  {
    primary_id: "D0R2TD",
    names: ["[3H]muscimol"],
  },
  {
    primary_id: "D0R2TH",
    names: ["2-Pyrazinone Derivative 3"],
  },
  {
    primary_id: "D0R2TM",
    names: [
      "BALANOL",
      "Balanol",
      "63590-19-2",
      "Azepinostatin",
      "CHEMBL60254",
      "Balanol, 1",
      "2-[2,6-Dihydroxy-4-[(3R,4R)-3-[(4-Hydroxybenzoyl)Amino]azepan-4-Yl]oxycarbonylbenzoyl]-3-Hydroxybenzoic Acid",
      "BA1",
      "(-)-Balanol",
      "(3R-Trans)-Balanol",
      "AC1NRA6W",
      "SCHEMBL62128",
      "MEGxm0_000504",
      "GTPL8142",
      "BDBM3149",
      "Acyclic Balanol Analog (-)-1",
      "BDBM36607",
      "ACon0_001438",
      "ZINC3871640",
      "MCULE-9310625171",
      "167937-47-5",
      "FT-0662471",
      "33879-EP2275420A1",
      "33879-EP2295416A2",
      "33879-EP2295055A2",
      "33879-EP2311453A1",
      "33879-EP2305642A2",
      "33879-EP2298764A1",
      "33879-EP2298765A1",
      "33879-EP2298748A2",
    ],
  },
  {
    primary_id: "D0R2TQ",
    names: ["PMID28394193-Compound-Figure3bIII"],
  },
  {
    primary_id: "D0R2TU",
    names: [
      "4-(3-Phenoxy-Propyl)-1H-Imidazole",
      "CHEMBL112951",
      "CHEMBL128923",
      "4-(3-Phenoxypropyl)-1H-Imidazole",
      "BDBM50092833",
      "4-(3-Phenoxy-Propyl)-1H-Imidazole With (0.95M)Oxalic Acid",
    ],
  },
  {
    primary_id: "D0R2TW",
    names: ["GW848687X"],
  },
  {
    primary_id: "D0R2UG",
    names: ["HS-173"],
  },
  {
    primary_id: "D0R2UZ",
    names: ["PSB-6426"],
  },
  {
    primary_id: "D0R2VB",
    names: ["PMID25666693-Compound-106"],
  },
  {
    primary_id: "D0R2VH",
    names: ["2-Oleoyl-LPA", "2-Acyl-1LPA", "2Acyl-LPA"],
  },
  {
    primary_id: "D0R2VL",
    names: [
      "6-(2-Benzylisoindolin-5-Yloxy)Nicotinamide",
      "CHEMBL238362",
      "SCHEMBL3690327",
      "GGVSFVBTUMOASO-UHFFFAOYSA-N",
      "BDBM50226248",
    ],
  },
  {
    primary_id: "D0R2WK",
    names: [
      "NVP-TAE684",
      "761439-42-3",
      "NVP-TAE 684",
      "TAE684",
      "TAE-684",
      "5-Chloro-N4-(2-(Isopropylsulfonyl)Phenyl)-N2-(2-Methoxy-4-(4-(4-Methylpiperazin-1-Yl)Piperidin-1-Yl)Phenyl)Pyrimidine-2,4-Diamine",
      "TAE684 (NVP-TAE684)",
      "TAE 684",
      "UNII-EH1713MN4K",
      "CHEMBL509032",
      "EH1713MN4K",
      "5-Chloro-N2-[2-Methoxy-4-[4-(4-Methyl-1-Piperazinyl)-1-Piperidinyl]phenyl]-N4-[2-[(1-Methylethyl)Sulfonyl]phenyl]-2,4-Pyrimidinediamine",
      "C30H40ClN7O3S",
    ],
  },
  {
    primary_id: "D0R2XE",
    names: ["4-Pentadeca-1,3,6-Trienylsulfanyl-Butyric Acid", "CHEMBL16979", "ZINC36222955"],
  },
  {
    primary_id: "D0R2XX",
    names: ["Benzodiazepine Derivative 1"],
  },
  {
    primary_id: "D0R2YA",
    names: ["Cyclopropylamine Derivative 12"],
  },
  {
    primary_id: "D0R2YC",
    names: ["PMID25399762-Compound-Figure4-Gypensapogenin B"],
  },
  {
    primary_id: "D0R2YD",
    names: ["Lm Melanoma"],
  },
  {
    primary_id: "D0R2YM",
    names: [
      "5-Bromo-8-Piperazin-1-Yl-Imidazo[1,2-A]pyrazine",
      "CHEMBL123566",
      "BDBM50002150",
      "143591-65-5",
      "5-Bromo-8-(1-Piperazinyl)Imidazo[1,2-A]pyrazine",
      "Imidazo[1,2-A]pyrazine, 5-Bromo-8-(1-Piperazinyl)-",
    ],
  },
  {
    primary_id: "D0R2YN",
    names: ["NSC-122427"],
  },
  {
    primary_id: "D0R2ZG",
    names: ["[Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0R2ZN",
    names: ["GNF-PF-826"],
  },
  {
    primary_id: "D0R3AA",
    names: ["1,1,1-Trifluoroheptadecan-2-One"],
  },
  {
    primary_id: "D0R3BC",
    names: ["153Sm-EDTMP"],
  },
  {
    primary_id: "D0R3BV",
    names: ["Inotersen"],
  },
  {
    primary_id: "D0R3CV",
    names: ["ISIS 25564"],
  },
  {
    primary_id: "D0R3CY",
    names: ["SB-265123"],
  },
  {
    primary_id: "D0R3DP",
    names: ["L-Valyl-L-Proline Hexylamide", "CHEMBL375490", "SCHEMBL6245007"],
  },
  {
    primary_id: "D0R3DQ",
    names: ["SYL-040003"],
  },
  {
    primary_id: "D0R3DT",
    names: ["AT-006"],
  },
  {
    primary_id: "D0R3EL",
    names: ["GSK184072"],
  },
  {
    primary_id: "D0R3FP",
    names: [
      "Tenofovir Disoproxil Fumarate",
      "Bis-POC-PMPA",
      "GSK548470",
      "PMPA-Oral",
      "Tenofovir DF",
      "Tenozet",
      "Viread",
    ],
  },
  {
    primary_id: "D0R3FS",
    names: [
      "ODQ",
      "1h-[1,2,4]oxadiazolo[4,3-A]quinoxalin-1-One",
      "41443-28-1",
      "[1,2,4]oxadiazolo[4,3-A]quinoxalin-1-One",
      "UNII-S57V2NMV38",
      "S57V2NMV38",
      "1H-(1,2,4)Oxadiazolo(4,3-A)Quinoxalin-1-One",
      "ODQ, 98%",
      "SR-01000075519",
      "1-H-[1,2,4]Oxadiazolo-[4,3,2]quinoxalin-1-Ine",
      "1H-ODQ",
      "Tocris-0880",
      "AC1L1BIQ",
      "Lopac-O-3636",
      "AC1Q6H5T",
      "Lopac0_000953",
      "SCHEMBL210817",
      "CHEMBL598952",
      "GTPL5234",
      "DTXSID2036796",
      "CTK1D6485",
      "CHEBI:91215",
      "MolPort-003-959-081",
      "HMS3649K18",
      "HMS3262P08",
      "HMS3266N10",
      "KS-000013GE",
      "ZINC2569341",
      "BCP06748",
      "Tox21_500953",
      "GL1507",
      "1963AH",
      "MFCD00792620",
      "BS0137",
    ],
  },
  {
    primary_id: "D0R3GC",
    names: ["D-Phe-Arg-2-Nal-NHCH3"],
  },
  {
    primary_id: "D0R3GD",
    names: ["H-Dmt-Tic-NH-(D)-CH[(CH2)4-NH-Z]-Bid"],
  },
  {
    primary_id: "D0R3GE",
    names: ["ANG-2010"],
  },
  {
    primary_id: "D0R3GN",
    names: ["JWH-373"],
  },
  {
    primary_id: "D0R3GW",
    names: ["SB 649915"],
  },
  {
    primary_id: "D0R3HP",
    names: [
      "3,3'-(1,2,4,5-Tetrazine-3,6-Diyl)Diphenol",
      "MLS002706401",
      "71123-38-1",
      "3-[6-(3-Hydroxyphenyl)-1,2,4,5-Tetrazin-3-Yl]phenol",
      "NSC111295",
      "AC1Q4XDI",
      "AC1L6N8F",
      "SCHEMBL1180643",
      "Cid_269792",
      "CHEMBL457636",
      "CTK5D3544",
      "BDBM25867",
      "DTXSID10296755",
      "YDADTNARCJIMQM-UHFFFAOYSA-N",
      "ZINC1703222",
      "AKOS030537993",
      "NSC-111295",
      "Hydroxyphenyl Substituted Tetrazine, 39",
    ],
  },
  {
    primary_id: "D0R3HX",
    names: ["RB-6145"],
  },
  {
    primary_id: "D0R3IG",
    names: ["SJCAR19"],
  },
  {
    primary_id: "D0R3IK",
    names: [
      "EPI-0030",
      "APX-003",
      "SIM-BD-0801",
      "Humanized Anti-VEGF MAbs (RabMab/MLG, Cancer), Apexigen/Simcere",
      "Humanized Anti-VEGF MAbs (RabMab/MLG, Cancer), Epitomics/Simcere",
    ],
  },
  {
    primary_id: "D0R3IN",
    names: ["S-P-Bromobenzyl Glutatione"],
  },
  {
    primary_id: "D0R3JB",
    names: [
      "Mitoxantrone",
      "DHAD",
      "DHAQ",
      "Dihydroxyanthraquinone",
      "MIX",
      "Misostol",
      "Mitoxanthrone",
      "Mitoxantron",
      "Mitoxantrona",
      "Mitoxantronum",
      "Mitozantrone",
      "DHAQ HCl",
      "Mitoxantrone [INN]",
      "Mitozantrone Hydrochloride",
      "Mitoxantrone 2HCl",
      "Liposome Encapsulated Mitoxantrone (LEM)",
      "Misostol (TN)",
      "Mitoxantrona [INN-Spanish]",
      "Mitoxantrone (INN)",
      "Mitoxantrone (Free Base)",
      "Mitoxantronum [INN-Latin]",
      "Novantrone (TN)",
      "AN-584/42007670",
      "Novantrone(R) (Mitoxantrone For Injection Concentrate)",
      "DHAQ (*Diacetate Salt*)",
      "MITOXANTRONE, Mitoxantrone Hydrochloride, Mitoxantrone Dihydrochloride, MITOXANTHRONE HYDROCHLORIDE",
      "MITOXANTRONE, 1,4-DIHYDROXY-5,8-BIS({2-[(2-HYDROXYETHYL)AMINO]ETHYL}AMINO)ANTHRA-9,10-QUINONE",
      "1,4-Bis(2-(2-Hydroxyethylamino)Ethyl)Amino)-5,8-Dihydroxyanthraquinone",
      "1,4-DIHYDROXY-5,8-BIS({2-[(2-HYDROXYETHYL)AMINO]ETHYL}AMINO)-9,10-ANTHRACENEDIONE",
      "1,4-Dihydroxy-5,8-Bis(2-((2-Hydroxyethyl)Amino)Ethylamino)-9,10-Anthracenedione",
      "1,4-Dihydroxy-5,8-Bis(5-Hydroxy-3-Azapentylamino)Anthrachinon",
      "1,4-Dihydroxy-5,8-Bis[2-(2-Hydroxyethylamino)Ethylamino]anthracene-9,10-Dione",
      "1,4-Dihydroxy-5,8-Bis[[2-[(2-Hydroxyethyl)Amino]ethyl]amino]-9,10-Anthracenedione",
      "1,4-Dihydroxy-5,8-Bis({2-[(2-Hydroxyethyl)Amino]ethyl}amino)Anthra-9,10-Quinone",
      "1,4-Dihydroxy-5,8-Bis({2-[(2-Hydroxyethyl)Amino]ethyl}amino)Anthracene-9,10-Dione",
      "5,8-Bis((2-((2-Hydroxyethyl)Amino)Ethyl)Amino)-1,4-Dihydroxyanthraquinone",
      "9,10-Anthracenedione, 1,4-Dihydroxy-5,8-Bis((2-((2-Hydroxyethyl)Amino)Ethyl)Amino)-(9CI)",
    ],
  },
  {
    primary_id: "D0R3JE",
    names: ["GS-2992"],
  },
  {
    primary_id: "D0R3JK",
    names: ["Isoxazoline Derivative 1"],
  },
  {
    primary_id: "D0R3JM",
    names: ["Benzene Sulfonamide Derivative 7"],
  },
  {
    primary_id: "D0R3JO",
    names: ["PEG-EPO"],
  },
  {
    primary_id: "D0R3KL",
    names: ["RRV-TV Rotavirus Vaccine"],
  },
  {
    primary_id: "D0R3KN",
    names: [
      "MN-462",
      "EF-6265",
      "CPB/TAFIa Inhibitor, MediciNova",
      "CPB/TAFIa Inhibitor, Meiji Seika Kaisha/MediciNova",
      "Carboxypeptidase B/Thrombin-Activated Fibrinolysis Inhibitor, Meiji Seika Kaisha/MediciNova",
    ],
  },
  {
    primary_id: "D0R3KS",
    names: ["DX-2500", "Anti-FcRn Antibody (Autoimmune Disorder), Dyax Corp"],
  },
  {
    primary_id: "D0R3KY",
    names: ["Nanobody Therapeutics"],
  },
  {
    primary_id: "D0R3LH",
    names: ["UMN-01"],
  },
  {
    primary_id: "D0R3LP",
    names: [
      "(+/-)-2-P-Tolylchroman-4-One",
      "4'-Methylflavanone",
      "CHEMBL596690",
      "AC1MCXMJ",
      "Oprea1_810740",
      "Oprea1_128105",
      "SCHEMBL1890733",
      "MolPort-003-699-848",
      "HITISLKGAFZTFZ-UHFFFAOYSA-N",
      "2-(4-Methylphenyl)Chroman-4-One",
      "BDBM50310196",
      "AKOS003614932",
    ],
  },
  {
    primary_id: "D0R3LQ",
    names: ["EXC 001"],
  },
  {
    primary_id: "D0R3MA",
    names: ["Recombinant Mab-Biocide Fusion Proteins"],
  },
  {
    primary_id: "D0R3MB",
    names: [
      "6-(Benzofuran-2-Sulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL241777",
      "Benzofuran, 22",
      "SCHEMBL4448826",
      "BDBM16456",
      "Heterocyclic Sulfonylpyridazinone, 19h",
    ],
  },
  {
    primary_id: "D0R3ML",
    names: [
      "PC-213",
      "Carraguard",
      "PC-503",
      "PC-515",
      "PC-515)",
      "Carrageenan-Based Microbicide Gel, Population Council",
    ],
  },
  {
    primary_id: "D0R3MO",
    names: ["PMID20684608C35"],
  },
  {
    primary_id: "D0R3MT",
    names: [
      "Acadesine",
      "AICAR",
      "Acadra",
      "ADV-P1",
      "ATH-001",
      "Acadesine (Intravenous Formulation, Leukemia)",
      "Acadesine (Intravenous Formulation, Leukemia), Advanced In Vitro Cell Technologies",
    ],
  },
  {
    primary_id: "D0R3NR",
    names: ["GATA-3-Specific DNAzyme"],
  },
  {
    primary_id: "D0R3OP",
    names: ["AG-120"],
  },
  {
    primary_id: "D0R3OQ",
    names: ["BNV-222"],
  },
  {
    primary_id: "D0R3OR",
    names: ["SR-27950"],
  },
  {
    primary_id: "D0R3PD",
    names: ["CLAVARINONE"],
  },
  {
    primary_id: "D0R3PY",
    names: ["Xmab 5574/MOR208"],
  },
  {
    primary_id: "D0R3QC",
    names: ["PMID28074661-Compound-WO2009018344C79"],
  },
  {
    primary_id: "D0R3QY",
    names: ["Vigabatrin"],
  },
  {
    primary_id: "D0R3RG",
    names: ["GZ402663"],
  },
  {
    primary_id: "D0R3RJ",
    names: ["PMID29671355-Compound-26"],
  },
  {
    primary_id: "D0R3RO",
    names: ["AZD-5455"],
  },
  {
    primary_id: "D0R3SI",
    names: ["NNC-09-0026"],
  },
  {
    primary_id: "D0R3TA",
    names: ["4-(2-(4-Fluorobenzyloxy)Phenyl)Piperidine", "CHEMBL573531"],
  },
  {
    primary_id: "D0R3TU",
    names: ["N1,N2-Bis(Dihydrocaffeoyl)Spermine"],
  },
  {
    primary_id: "D0R3UI",
    names: ["PMID25726713-Compound-61"],
  },
  {
    primary_id: "D0R3VG",
    names: ["Thiazole Carboxamide Derivative 3"],
  },
  {
    primary_id: "D0R3VK",
    names: [
      "GDC-0623",
      "1168091-68-6",
      "GDC 0623",
      "UNII-HW67545I4Q",
      "RG 7421",
      "GDC0623",
      "HW67545I4Q",
      "G-868",
      "Imidazo[1,5-A]pyridine-6-Carboxamide, 5-[(2-Fluoro-4-Iodophenyl)Amino]-N-(2-Hydroxyethoxy)-",
      "5-((2-FLUORO-4-IODOPHENYL)AMINO)-N-(2-HYDROXYETHOXY)IMIDAZO[1,5-A]PYRIDINE-6-CARBOXAMIDE",
      "RFWVETIZUQEJEF-UHFFFAOYSA-N",
      "GTPL9909",
      "SCHEMBL1615104",
      "CHEMBL3330650",
      "MolPort-039-193-834",
      "BCP28689",
      "AOB87143",
      "EX-A2060",
      "S7553",
      "ZINC43206499",
      "BDBM50025226",
      "AKOS027253679",
      "DB11982",
      "SB16957",
      "CS-2281",
      "BC600628",
      "HY-15610",
      "KB-144448",
      "FT-0769207",
      "Z-3311",
    ],
  },
  {
    primary_id: "D0R3VL",
    names: [
      "CCR4 Antagonists",
      "CCR4 Antagonists (Atopic Dermatitis)",
      "RS-1154",
      "RS-1269",
      "CCR4 Antagonists (Atopic Dermatitis), Daiichi Sankyo",
    ],
  },
  {
    primary_id: "D0R3VQ",
    names: ["BAL-27862"],
  },
  {
    primary_id: "D0R3WG",
    names: ["SILPERISONE HYDROCHLORIDE"],
  },
  {
    primary_id: "D0R3WH",
    names: ["Piperidinyl Pyrazole Derivative 3"],
  },
  {
    primary_id: "D0R3XC",
    names: ["1,6-Bis(4-(Pyridin-2-Yl)Piperazin-1-Yl)Hexane", "CHEMBL1171644", "SCHEMBL4519313"],
  },
  {
    primary_id: "D0R3XI",
    names: ["CDRI-97/78", "Antimalarial Compound, CDRI/IPCA Labs"],
  },
  {
    primary_id: "D0R3XO",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Ala-D-Pro-)", "CHEMBL238596", "BDBM50222727"],
  },
  {
    primary_id: "D0R3YM",
    names: ["PMID25980951-Compound-1"],
  },
  {
    primary_id: "D0R3YO",
    names: ["HKT288"],
  },
  {
    primary_id: "D0R3YT",
    names: ["LPCN-1090"],
  },
  {
    primary_id: "D0R3ZE",
    names: ["MEDI-551"],
  },
  {
    primary_id: "D0R4AE",
    names: ["MT-7117"],
  },
  {
    primary_id: "D0R4AV",
    names: [
      "AC-(D)PHE-PRO-BOROLYS-OH",
      "CHEMBL100109",
      "AC1NRART",
      "BDBM50288414",
      "DB07658",
      "N-Acetyl-D-Phenylalanyl-N-[(1R)-5-Amino-1-(Dihydroxyboranyl)Pentyl]-L-Prolinamide",
      "1-(2-Acetylamino-3-Phenyl-Propionyl)-Pyrrolidine-2-Carboxylic Acid (5-Amino-1-Dihydroxyboranyl-Pentyl)-Amide",
      "[(1R)-1-[[(2S)-1-[(2R)-2-Acetamido-3-Phenylpropanoyl]pyrrolidine-2-Carbonyl]amino]-5-Aminopentyl]boronic Acid",
      "(1R)-5-Amino-1-{[(2S)-1-[(2R)-2-Acetamido-3-Phenylpropanoyl]pyrrolidin-2-Yl]formamido}pentylboronic Acid",
    ],
  },
  {
    primary_id: "D0R4BK",
    names: ["ISIS 4729"],
  },
  {
    primary_id: "D0R4BS",
    names: ["N-Hydroxy-4-(3-Phenyl-Propionylamino)-Benzamide"],
  },
  {
    primary_id: "D0R4BT",
    names: ["PMID15261275C1"],
  },
  {
    primary_id: "D0R4DK",
    names: ["1-(1-Phenyl-1H-Tetrazol-5-Ylthio)Propan-2-One", "CHEMBL1082938"],
  },
  {
    primary_id: "D0R4DN",
    names: ["NTC-121"],
  },
  {
    primary_id: "D0R4DP",
    names: [
      "Clotiazepam",
      "Clotiazepamum",
      "Clozan",
      "Distensan",
      "Rise",
      "Rize",
      "Rizen",
      "Tienor",
      "Trecalmo",
      "Veratran",
      "Y 6047",
      "Clotiazepam [INN:JAN]",
      "Clotiazepamum [INN-Latin]",
      "Clozan (TN)",
      "Distensan (TN)",
      "Rize (TN)",
      "Rizen (TN)",
      "Trecalmo (TN)",
      "Veratran (TN)",
      "Y-6047",
      "Clotiazepam (JP15/INN)",
      "2H-Thieno(2,3e)(1,4)-Diazepin-2-One, 5-(O-Chlorophenyl)-7-Ethyl-1,3-Dihydro-1-Methyl-(8CI)",
      "5-(2-Chlorophenyl)-7-Ethyl-1-Methyl-1,3-Dihydro-2H-Thieno(2,3-E)(1,4)Diazepin-2-One",
      "5-(2-Chlorophenyl)-7-Ethyl-1-Methyl-1,3-Dihydro-2H-Thieno[2,3-E][1,4]diazepin-2-One",
      "5-(2-Chlorophenyl)-7-Ethyl-1-Methyl-3H-Thieno[2,3-E][1,4]diazepin-2-One",
      "5-(O-Chlorophenyl)-7-Ethyl-1,3-Dihydro-1-Methyl-2H-Thieno(2,3-E)-1,4-Diazepin-2-One",
    ],
  },
  {
    primary_id: "D0R4EN",
    names: ["LXR 623"],
  },
  {
    primary_id: "D0R4ER",
    names: [
      "D-Lysine",
      "923-27-3",
      "(R)-2,6-Diaminohexanoic Acid",
      "(2R)-2,6-Diaminohexanoic Acid",
      "D-2,6-Diaminohexanoic Acid",
      "H-D-Lys-OH",
      "D-Lysin",
      "D-Lys",
      "UNII-3HQ6U6424Q",
      "(R)-2,6-Diaminocaproic Acid",
      "CHEBI:16855",
      "KDXKERNSBIXSRK-RXMQYKEDSA-N",
      "3HQ6U6424Q",
      "L-8990",
      "Lysine, D-",
      "DLY",
      "26853-89-4",
      "Lysine #",
      "Poly-D-Lysine",
      "(+)-S-Lysine",
      "D-Lysine, Homopolymer",
      "Poly-D-Lysine Hydrobromide",
      "EINECS 213-091-9",
      "PubChem6286",
      "D-Lysine, Free Base",
      "Aminutrin, 6-Amino-",
      "L-Lysine (9CI)",
      "PubChem12448",
      "Lysine, L- (8CI)",
      "27964-99-4",
      "SCHEMBL7343",
      "AC1Q54DQ",
      "AC1Q54DR",
      "GTPL4681",
    ],
  },
  {
    primary_id: "D0R4EY",
    names: [
      "Boscalid",
      "188425-85-6",
      "Nicobifen",
      "Endura",
      "2-Chloro-N-(4'-Chloro-[1,1'-Biphenyl]-2-Yl)Nicotinamide",
      "Emerald",
      "Anilide",
      "Cantus",
      "Boscalid [ISO]",
      "UNII-32MS8ZRD1V",
      "2-Chloro-N-(4'-Chlorobiphenyl-2-Yl)Nicotinamide",
      "BAS 510F",
      "3-Pyridinecarboxamide, 2-Chloro-N-(4'-Chloro[1,1'-Biphenyl]-2-Yl)-",
      "32MS8ZRD1V",
      "CHEBI:81822",
      "2-Chloro-N-[2-(4-Chlorophenyl)Phenyl]pyridine-3-Carboxamide",
      "WYEMLYFITZORAB-UHFFFAOYSA-N",
      "NCGC00163735-04",
      "3-Pyridinecarboxamide, 2-Chloro-N-(4'-Chloro(1,1'-Biphenyl)-2-Yl)-",
      "HSDB 7499",
      "AC1Q1HCS",
      "AC1L4U3A",
      "DSSTox_RID_79152",
    ],
  },
  {
    primary_id: "D0R4FM",
    names: [
      "DIHYDROKAEMPFEROL",
      "Aromadendrin",
      "480-20-6",
      "(+)-Dihydrokaempferol",
      "Dihydrokaempferol",
      "Katuranin",
      "(+)-Aromadendrin",
      "Aromadendrol",
      "(2R,3R)-3,5,7-Trihydroxy-2-(4-Hydroxyphenyl)Chroman-4-One",
      "(+)-Aromadendrol",
      "UNII-7YA4640575",
      "(2R,3R)-3,5,7-Trihydroxy-2-(4-Hydroxyphenyl)-2,3-Dihydro-4H-Chromen-4-One",
      "CHEMBL9323",
      "CHEBI:15401",
      "PADQINQHPQKXNL-LSDHHAIUSA-N",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-3,5,7-Trihydroxy-2-(4-Hydroxyphenyl)-, (2R,3R)-",
      "7YA4640575",
      "Arbo 14",
      "AC1L3VDW",
      "AC1Q6KIF",
      "(2R,3R)-Dihydrokaempferol",
      "MLS000697722",
    ],
  },
  {
    primary_id: "D0R4GD",
    names: ["13-Cis-Retinol"],
  },
  {
    primary_id: "D0R4GI",
    names: ["Tyr-D-Ala-Gly-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D0R4GM",
    names: ["MDX-33"],
  },
  {
    primary_id: "D0R4GX",
    names: ["Beta-D-Hydroxybutyric Acid", "3-Hydroxybutyrate", "(R)-3-Hydroxybutanoate"],
  },
  {
    primary_id: "D0R4HQ",
    names: [
      "Corticotropin",
      "ACTH",
      "Cortrophin",
      "Corticotrophin",
      "Adrenocorticotropic Hormone",
      "Corticotrophine",
      "Corticotrofina",
      "Acthargel",
      "Corticotrophinum",
      "Beta-Corticotropin",
      "Adrenocorticotrophin",
      "Purified Cortrophin Gel",
      "Corticotropin [USP:INN]",
      "9002-60-2",
      "CHEBI:3892",
      "BDBM82408",
      "ACTH-(1-39)",
      "25-Asp-30-Gln-Corticotropin Porcine",
      "NCGC00167127-01",
      "CAS_12279-41-3",
      "Adrenocorticotropic Hormone (1-39), Human",
      "LS-187380",
      "SYSMEHFRWGKPVGKKRRPVKVYPDGAEDQLAEAFPLEF",
      "J-004856",
      "Alpha1-39-Corticotropin (Swine), 25-L-Aspartic Acid-30-L-Glutamine",
    ],
  },
  {
    primary_id: "D0R4IS",
    names: ["Des-AA1,2,5-[(NalphaMe)Cys3,D-Nal8,IAmp9]SRIF", "CHEMBL408611"],
  },
  {
    primary_id: "D0R4IT",
    names: ["Heterocyclic-Fused Piperazine Derivative 1"],
  },
  {
    primary_id: "D0R4IZ",
    names: ["Timothy Grass Pollen Extracts"],
  },
  {
    primary_id: "D0R4JZ",
    names: ["(2R,3S,4S,5R)-2-Hexylpiperidine-3,4,5-Triol", "CHEMBL1090540", "SCHEMBL19829307", "BDBM50315255"],
  },
  {
    primary_id: "D0R4KE",
    names: ["2-(4-Fluorophenylsulfonamido)-1-Naphthoic Acid"],
  },
  {
    primary_id: "D0R4KJ",
    names: ["H-Dmt-Tic-(2S,3R)-Beta-MeCha-Phe-OH", "CHEMBL375095"],
  },
  {
    primary_id: "D0R4KK",
    names: ["Verubecestat"],
  },
  {
    primary_id: "D0R4KQ",
    names: ["4-(5-Methyl-2-Pirazolino)-3-Pyridinesulfonamide", "CHEMBL1163382"],
  },
  {
    primary_id: "D0R4LX",
    names: ["ISIS 112590"],
  },
  {
    primary_id: "D0R4LZ",
    names: ["2-(4-Methoxyphenyl)-6-Phenyl-9H-Purine", "CHEMBL207760"],
  },
  {
    primary_id: "D0R4MH",
    names: ["RS-513815"],
  },
  {
    primary_id: "D0R4ML",
    names: ["P1A"],
  },
  {
    primary_id: "D0R4MT",
    names: [
      "Gemcabene",
      "183293-82-5",
      "UNII-B96UX1DDKS",
      "B96UX1DDKS",
      "6-(5-Carboxy-5-Methyl-Hexyloxy)-2,2-Dimethyl-Hexanoic Acid",
      "Gemcabene [INN]",
      "PD-72953",
      "PD 72953",
      "AC1L4IHZ",
      "SCHEMBL761631",
      "CHEMBL2110686",
      "CTK4D8457",
      "DTXSID60171407",
      "SDMBRCRVFFHJKR-UHFFFAOYSA-N",
      "ZINC1893031",
      "PD72953",
      "DB05123",
      "ACM183293825",
      "6,6'-Oxybis(2,2-Dimethylhexanoic Acid)",
      "Hexanoic Acid,6,6'-Oxybis[2,2-Dimethyl-",
      "FT-0743032",
      "2,2,2',2'-Tetramethyl-6,6'-Oxydihexanoic Acid",
      "6-(6-Hydroxy-5,5-Dimethyl-6-Oxohexoxy)-2,2-Dimethylhexanoic Acid",
      "6-(5-CARBOXY-5-METHYL-HEXOXY)-2,2-DIME",
    ],
  },
  {
    primary_id: "D0R4NJ",
    names: ["DW-908", "DW-0908"],
  },
  {
    primary_id: "D0R4NR",
    names: ["Prednisone/ Dipyridamole"],
  },
  {
    primary_id: "D0R4NY",
    names: [
      "DTaP-IPV",
      "Certiva-IPV",
      "DTaP-IPV, North American Vaccine",
      "Vaccine (Diphtheria/Tetanus/Pertussis/Polio), North American Vaccine",
    ],
  },
  {
    primary_id: "D0R4NZ",
    names: ["Alunbrigbrigatinib"],
  },
  {
    primary_id: "D0R4OD",
    names: [
      "Anti-IFN Gamma",
      "AGT-1",
      "Anti-IFN Gamma (Autoimmune Diseases)",
      "Anti-Cytokine Antibodies, Advanced Biotherapy",
      "Anti-Cytokine Therapy, Advanced Biotherapy",
      "Anti-IFN Gamma (Autoimmune Diseases), Advanced Biotherapy",
    ],
  },
  {
    primary_id: "D0R4OI",
    names: ["K-103-IP"],
  },
  {
    primary_id: "D0R4OM",
    names: [
      "Trifluoperazine",
      "Calmazine",
      "Eskazine",
      "Fluoperazine",
      "Flurazine",
      "Modalina",
      "Stelazine",
      "Stellazine",
      "TFP",
      "Trifluoperazin",
      "Trifluoperazina",
      "Trifluoperazinum",
      "Trifluoromethylperazine",
      "Trifluoroperazine",
      "Trifluperazine",
      "Triflurin",
      "Trifluroperizine",
      "Triftazin",
      "Triftazine",
      "Triperazine",
      "Triphtazin",
      "Triphtazine",
      "Triphthasine",
      "PMS Trifluoperazine",
      "Stelazine Concentrate",
      "Trifluoperazina [Italian]",
      "Trifluoperazine HCl",
      "Trifluoperazine Dihydrochloride",
      "Trifluoroperazine Dihydrochloride",
      "Tripfluoperazine Hydrochloride",
      "NCI17474",
      "RP 7623",
      "SKF 5019",
      "Apo-Trifluoperazine",
      "Eskazine (TN)",
      "Eskazinyl (TN)",
      "Jatroneural (TN)",
      "Modalina (TN)",
      "Novo-Trifluzine",
      "Stelazine (TN)",
      "Terfluzine (TN)",
      "Trifluoperaz (TN)",
      "Trifluoperazina [INN-Spanish]",
      "Trifluoperazine (INN)",
      "Trifluoperazine [INN:BAN]",
      "Trifluoperazinum [INN-Latin]",
      "Apo-Trifluoperazine (TN)",
      "Stelazine (*Dihydrochloride*)",
      "Trifluoromethyl-10-(3'-(1-Methyl-4-Piperazinyl)Propyl)Phenothiazine",
      "10-(3-(4-Methyl-1-Piperazinyl)Propyl)-2-(Trifluoromethyl)Phenothiazine",
      "10-(3-(4-Methyl-1-Piperazinyl)Propyl)-2-(Trifluoromethyl)-10H-Phenothiazine",
      "10-(3-(4-Methyl-1-Piperazinyl)Propyl)-2-(Trifluoromethyl)-Phenothiazine",
      "10-(Gamma-(N'-Methylpiperazino)Propyl)-2-Trifluoromethylphenothiazine",
      "10-[3-(4-METHYL-PIPERAZIN-1-YL)-PROPYL]-2-TRIFLUOROMETHYL-10H-PHENOTHIAZINE",
      "10-[3-(4-Methyl-1-Piperazinyl)Propyl]-2-(Trifluoromethyl)-10H-Phenothiazine",
      "10-[3-(4-Methylpiperazin-1-Yl)Propyl]-2-(Trifluoromethyl)-10H-Phenothiazine",
      "10-[3-(4-Methylpiperazin-1-Yl)Propyl]-2-(Trifluoromethyl)Phenothiazine",
      "10-[3-(4-Methylpiperazin-1-Yl)Propyl]-2-(Trifluoromethyl)Phenothiazine Dihydrochloride",
      "2-Trifluoromethyl-10-(3'-(1-Methyl-4-Piperazinyl)Propyl)Phenothiazine",
    ],
  },
  {
    primary_id: "D0R4PI",
    names: ["Diphosphate"],
  },
  {
    primary_id: "D0R4PR",
    names: [
      "7-Acetonyloxy-3-Acetylamino-8-Methoxycoumarin",
      "CHEMBL201250",
      "CTK6J4706",
      "BDBM50181853",
      "AKOS015965518",
      "N-[8-Methoxy-2-Oxo-7-(2-Oxo-Propoxy",
      "N-(8-Methoxy-2-Oxo-7-(2-Oxopropoxy)-2H-Chromen-3-Yl)Acetamide",
    ],
  },
  {
    primary_id: "D0R4PS",
    names: ["[3H]folinic Acid"],
  },
  {
    primary_id: "D0R4PW",
    names: ["Zalutumumab"],
  },
  {
    primary_id: "D0R4QE",
    names: ["ISIS 9062"],
  },
  {
    primary_id: "D0R4QF",
    names: ["HERKINORIN"],
  },
  {
    primary_id: "D0R4RL",
    names: ["MYO-102"],
  },
  {
    primary_id: "D0R4RN",
    names: [
      "[125I]epibatidine",
      "CHEMBL6623",
      "NLPRAJRHRHZCQQ-UHFFFAOYSA-N",
      "140111-52-0",
      "[3H]EPIBATIDINE",
      "SR-01000597620",
      "[125I]EPIBATIDINE",
      "AC1Q3PYA",
      "AC1L1AYB",
      "208/210 Epibatidine",
      "KBioSS_000603",
      "KBioGR_000603",
      "BSPBio_001263",
      "(R)-2-(6-Chloro-Pyridin-3-Yl)-7-Aza-Bicyclo[221]heptane",
      "[3H]epibatidine",
    ],
  },
  {
    primary_id: "D0R4RV",
    names: ["PMID25553724-Compound-US20138552206 5"],
  },
  {
    primary_id: "D0R4SE",
    names: ["FX-87L", "Chemokine Ligand Inhibitor (Topical, Inflammatory Disease), Funxional"],
  },
  {
    primary_id: "D0R4TA",
    names: ["Tetronothiodin"],
  },
  {
    primary_id: "D0R4TI",
    names: ["(6-Methylthio-2-Naphthyl)Isopropylamine", "CHEMBL455558"],
  },
  {
    primary_id: "D0R4UA",
    names: ["Hu3F8 MAb"],
  },
  {
    primary_id: "D0R4UW",
    names: [
      "Roflumilast",
      "162401-32-3",
      "DAXAS",
      "Daliresp",
      "3-(CYCLOPROPYLMETHOXY)-N-(3,5-DICHLOROPYRIDIN-4-YL)-4-(DIFLUOROMETHOXY)BENZAMIDE",
      "BY217",
      "BYK20869",
      "UNII-0P6C6ZOP5U",
      "BY-217",
      "Roflumilast (Daxas)",
      "B9302-107",
      "0P6C6ZOP5U",
      "3-(Cyclopropylmethoxy)-N-(3,5-Dichloro-4-Pyridinyl)-4-(Difluoromethoxy)Benzamide",
      "Benzamide, 3-(Cyclopropylmethoxy)-N-(3,5-Dichloro-4-Pyridinyl)-4-(Difluoromethoxy)-",
      "CHEMBL193240",
      "CHEBI:47657",
      "BYK-20869",
      "ROF",
      "Libertek",
      "AK110425",
      "3-Cyclopropylmethoxy-N-(3,5-Dichloropyridin-4-Yl)-4-(Difluoromethoxy)Benzamide",
      "Roflumilast [USAN]",
      "APTA-2217",
      "Roflumilast (JAN/USAN/INN)",
      "3-Cyclopropylmethoxy-4-Difluoromethoxy-N-(3,5-Di-Chloropyrid-4-Yl)Benzamide",
      "Alogliptin/Roflumilast",
    ],
  },
  {
    primary_id: "D0R4WJ",
    names: ["Tannic Acid"],
  },
  {
    primary_id: "D0R4WT",
    names: ["NB-325"],
  },
  {
    primary_id: "D0R4WW",
    names: ["MR-20496", "CHEMBL9179", "BDBM50070095"],
  },
  {
    primary_id: "D0R4YD",
    names: ["H-Dmt-Tic-(2R,3S)-Beta-MeCha-Phe-OH", "CHEMBL376453"],
  },
  {
    primary_id: "D0R4YQ",
    names: [
      "CB-813",
      "CB-813d",
      "Recombinant Human Factor VIIa (Alterase, Hemophilia), Catalyst Biosciences",
      "RhFVIIa (Alterase, Hemophilia), Catalyst Biosciences",
      "Recombinant Human Factor VIIa (Alterase, Hemophilia), Catalyst Biosciences/Wyeth Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0R4ZT",
    names: ["Gallium Nitrate"],
  },
  {
    primary_id: "D0R5AD",
    names: [
      "2-(N,N-Diethylamino)-3'-Chloropropiophenone",
      "CHEMBL578815",
      "SCHEMBL634022",
      "BDBM50302915",
      "AKOS009048034",
    ],
  },
  {
    primary_id: "D0R5AJ",
    names: [
      "Cyclobutyl",
      "Cyclobutyl Radical",
      "Cyclobutanyl",
      "Cyclobutan-1-Yl",
      "4548/6/5",
      "Cyclobutylradical",
      "AC1L3CHP",
      "DTXSID90196522",
      "16188-EP2281563A1",
      "16097-EP2316459A1",
      "16097-EP2275401A1",
      "16188-EP2316459A1",
      "16188-EP2308873A1",
      "16097-EP2308848A1",
    ],
  },
  {
    primary_id: "D0R5AU",
    names: [
      "LASINAVIR",
      "BMS-234475",
      "CGP-61755",
      "Lasinavir",
      "N-[5(S)-(Tert-Butoxycarbonylamino)-4(S)-Hydroxy-6-Phenyl-2(R)-(2,3,4-Trimethoxybenzyl)Hexanoyl]-L-Valine 2-Methoxyethylamide",
    ],
  },
  {
    primary_id: "D0R5DH",
    names: ["Triazole Propanedioic Acid Derivative 1"],
  },
  {
    primary_id: "D0R5EF",
    names: [
      "N-(6-(Pyridin-3-Yl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1085918",
      "SCHEMBL6489919",
      "CZTAWAQITKVBNQ-UHFFFAOYSA-N",
      "BDBM50313686",
    ],
  },
  {
    primary_id: "D0R5ER",
    names: ["BPX-01"],
  },
  {
    primary_id: "D0R5EW",
    names: [
      "2-Chloro-N-(2-Morpholinoethyl)Nicotinamide",
      "CHEMBL598814",
      "551901-48-5",
      "2-CHLORO-N-[2-(MORPHOLIN-4-YL)ETHYL]PYRIDINE-3-CARBOXAMIDE",
      "AC1MRDK5",
      "CTK7F9629",
      "2-Chloro-N-(2-Morpholin-4-Ylethyl)Pyridine-3-Carboxamide",
      "MolPort-003-989-877",
      "ZINC19387520",
      "BDBM50307207",
      "AKOS000160265",
    ],
  },
  {
    primary_id: "D0R5FE",
    names: [
      "(P-Iodophenylacetylamino)Methylphosphinic Acid",
      "AC1O3GBT",
      "AC1L1B5E",
      "C9H11INO3P",
      "SCHEMBL4311614",
      "[[2-(4-Iodophenyl)Acetyl]amino]methylphosphinic Acid",
      "(R)-({[(4-Iodophenyl)Acetyl]amino}methyl)Phosphinic Acid",
    ],
  },
  {
    primary_id: "D0R5FH",
    names: ["Quinoxaline1"],
  },
  {
    primary_id: "D0R5FO",
    names: ["KYS-05057"],
  },
  {
    primary_id: "D0R5FU",
    names: ["Peptide Analog 9"],
  },
  {
    primary_id: "D0R5GF",
    names: ["MRKAd5/ALVAC"],
  },
  {
    primary_id: "D0R5GS",
    names: ["Gepirone ER"],
  },
  {
    primary_id: "D0R5HA",
    names: ["Tobacco Plant-Derived Anti-Idiotype Vaccine"],
  },
  {
    primary_id: "D0R5HG",
    names: ["Pyrimidine Benzenesulfonamide Derivative 2"],
  },
  {
    primary_id: "D0R5HH",
    names: [
      "ACE-083",
      "AVPLCNNLKMYJHF-UHFFFAOYSA-N",
      "81080-10-6",
      "Acetamide, N-[4-Hydroxy-3,5-Bis(1-Piperidinylmethyl)Phenyl]-",
      "ACE083",
      "DTXSID00558571",
      "ZINC71974091",
      "N-{4-Hydroxy-3,5-Bis[(Piperidin-1-Yl)Methyl]phenyl}acetamide",
    ],
  },
  {
    primary_id: "D0R5HK",
    names: ["Sodium Valproate"],
  },
  {
    primary_id: "D0R5HV",
    names: [
      "2,5-Dideoxy-2,5-Imino-D-Mannitol",
      "59920-31-9",
      "(2R,3R,4R,5R)-2,5-Bis(Hydroxymethyl)Pyrrolidine-3,4-Diol",
      "2,5-Dihydroxymethyl-3,4-Dihydroxypyrrolidine",
      "CHEBI:4289",
      "CHEMBL312653",
      "NSC624987",
      "NSC613239",
      "2(R),5(R)-Bis(Hydroxymethyl)-3(R),4(R)-Dihydroxypyrrolidine",
      "2,5-Anhydro-2,5-Imino-D-Mannitol",
      "DQQ",
      "2,5-Dideoxy-2,5-Imino-L-Mannitol",
      "NSC 624987",
      "AC1L3YWM",
      "AC1Q59GS",
      "SCHEMBL466574",
      "ZINC1492230",
      "2,5-Pyrrolidinedimethanol, 3,4-Dihydroxy-, (2R,3R,4R,5R)-",
    ],
  },
  {
    primary_id: "D0R5IS",
    names: ["Flufenoxina"],
  },
  {
    primary_id: "D0R5JD",
    names: ["S26131"],
  },
  {
    primary_id: "D0R5JL",
    names: ["Polio IPV"],
  },
  {
    primary_id: "D0R5JP",
    names: [
      "Pleuromutilins",
      "BC-3004",
      "BC-3080",
      "BC-3291",
      "Pleuromutilins (Oral, Bacterial Infection)",
      "Pleuromutilins (Oral, Bacterial Infection), Nabriva",
    ],
  },
  {
    primary_id: "D0R5KO",
    names: ["C(His-D-Phe-Arg-Trp-Ahx)", "CHEMBL379627"],
  },
  {
    primary_id: "D0R5KV",
    names: ["CI-1031"],
  },
  {
    primary_id: "D0R5LL",
    names: ["Cancer Vaccine"],
  },
  {
    primary_id: "D0R5MR",
    names: ["N4,N6-Dibenzylpyrimidine-4,6-Dicarboxamide"],
  },
  {
    primary_id: "D0R5NA",
    names: ["IS20"],
  },
  {
    primary_id: "D0R5NB",
    names: ["4SC-302", "Dihydroorotate Dehydrogenase (DHODH) Inhibitor (Flavivirus Infections), 4SC"],
  },
  {
    primary_id: "D0R5NU",
    names: ["CART123 Cells"],
  },
  {
    primary_id: "D0R5NX",
    names: ["ISIS 5874"],
  },
  {
    primary_id: "D0R5OE",
    names: ["(S)-Pyrrolidin-2-Yl(Thiazolidin-3-Yl)Methanone", "CHEMBL224435", "SCHEMBL2711156"],
  },
  {
    primary_id: "D0R5OF",
    names: ["3-Methyl-2-Morpholino-1,1-Diphenylbutan-1-Ol", "CHEMBL1090793"],
  },
  {
    primary_id: "D0R5OS",
    names: ["Apalcillin Sodium"],
  },
  {
    primary_id: "D0R5OU",
    names: [
      "3-(Benzyloxy)-9H-Pyrido[3,4-B]indole",
      "CHEMBL87331",
      "3-Benzyloxy-9H-Beta-Carboline",
      "SCHEMBL10641576",
      "BDBM50001481",
      "ZINC13728535",
    ],
  },
  {
    primary_id: "D0R5PP",
    names: ["PRS-190", "Bispecific Anticalin (Autoimmune Diseases), Pieris", "Duocalin (Autoimmunity), Pieris"],
  },
  {
    primary_id: "D0R5QF",
    names: ["AR231453", "AR-231,453", "AR 231453", "AR-231453"],
  },
  {
    primary_id: "D0R5QM",
    names: [
      "1-Butyl-4-(2-Methoxy-Phenyl)-Piperazine",
      "CHEMBL26789",
      "Oprea1_525926",
      "SCHEMBL10414460",
      "ZINC13738286",
      "BDBM50039791",
      "AKOS008298691",
    ],
  },
  {
    primary_id: "D0R5QQ",
    names: ["AVE-8134"],
  },
  {
    primary_id: "D0R5QX",
    names: ["AcAsp-Glu-Dif-Ile-Cha-Cys-Iqc-Nle-Thr-TyrNH2"],
  },
  {
    primary_id: "D0R5QY",
    names: ["Protein Subunit Vaccine (Mycobacterium Tuberculosis, Intranasal)"],
  },
  {
    primary_id: "D0R5RE",
    names: [
      "2,5-Dideoxy-2,5-Imino-Dl-Glycero-D-Manno-Heptitol",
      "CHEMBL456077",
      "2,5-Dideoxy-2,5-Imino-D-Glycero-D-Manno-Heptitol",
      "SCHEMBL5851667",
      "BDBM50242071",
    ],
  },
  {
    primary_id: "D0R5RF",
    names: ["5'-Phenylthio-ImmH"],
  },
  {
    primary_id: "D0R5RR",
    names: [
      "Clofarabine",
      "CAFdA",
      "CFB",
      "Clofarabina",
      "Clofarabinum",
      "Clofarex",
      "Clolar",
      "Evoltra",
      "Clofarabine [USAN]",
      "Clolar (TN)",
      "Evoltra (TN)",
      "Clofarabine (USAN/INN)",
      "Clolar, Evoltra, Clofarabine",
      "Cl-F-Ara-A",
      "(2R,3R,4S,5R)-5-(6-Amino-2-Chloropurin-9-Yl)-4-Fluoro-2-(Hydroxymethyl)Oxolan-3-Ol",
      "2-Chloro-9-(2-Deoxy-2-Fluoro-B-D-Arabinofuranosyl)-9H-Purin-6-Amine",
      "2-Chloro-9-(2-Deoxy-2-Fluoro-Beta-D-Arabinofuranosyl)-9H-Purin-6-Amine",
      "2-Chloro-9-(2-Deoxy-2-Fluoro-Beta-D-Arabinofuranosyl)Adenine",
      "2-Cl-2'-F-AraA",
      "2-Chloro-9-(2'-Deoxy-2'-Fluoro-Beta-D-Arabinofuranosyl)Adenine",
      "3S211048",
    ],
  },
  {
    primary_id: "D0R5RW",
    names: ["4-Nitrophenyl 4-Benzylpiperazine-1-Carboxylate", "CHEMBL597700", "SCHEMBL12794615"],
  },
  {
    primary_id: "D0R5TA",
    names: ["ENDOMORPHIN-1"],
  },
  {
    primary_id: "D0R5TT",
    names: ["FR-123826", "COX-2 Inhibitor, Fujisawa"],
  },
  {
    primary_id: "D0R5TV",
    names: ["HD-0801"],
  },
  {
    primary_id: "D0R5TZ",
    names: ["SIM-071201"],
  },
  {
    primary_id: "D0R5UR",
    names: ["ALL-4"],
  },
  {
    primary_id: "D0R5UZ",
    names: ["Caracemide"],
  },
  {
    primary_id: "D0R5VG",
    names: ["N-Hydroxy-4-(5-Phenyl-Pentanoylamino)-Benzamide"],
  },
  {
    primary_id: "D0R5VO",
    names: ["Avicin D", "Avicin D (Cancer)"],
  },
  {
    primary_id: "D0R5WB",
    names: [
      "2-Phenyl-3-(2-Piperidin-1-Yl-Ethyl)-1H-Indole",
      "CHEMBL91205",
      "SCHEMBL7645809",
      "BDBM50095028",
      "2-Phenyl-3-(2-Piperidinoethyl)-1H-Indole",
    ],
  },
  {
    primary_id: "D0R5WV",
    names: [
      "3,3'-(1,2,4-Thiadiazol-2,5-Diyl)Diphenol",
      "3,3'-(1,3,4-Thiadiazole-2,5-Diyl)Diphenol",
      "155877-54-6",
      "CHEMBL450189",
      "SCHEMBL1180710",
      "BDBM25837",
      "CTK0E7552",
      "DTXSID00618942",
      "KZZBPNSRBQBVII-UHFFFAOYSA-N",
      "Hydroxyphenyl Substituted Thiadiazole, 9",
      "2,5-Bis(3-Hydroxyphenyl)-1,3,4-Thiadiazole",
      "Phenol, 3,3'-(1,3,4-Thiadiazole-2,5-Diyl)Bis-",
    ],
  },
  {
    primary_id: "D0R5WX",
    names: ["4-Benzyl-1-Chroman-2-Ylmethyl-Piperidine", "CHEMBL178650"],
  },
  {
    primary_id: "D0R5XK",
    names: ["ONO-1714", "CHEMBL1800346", "CHEMBL105792", "SCHEMBL2717045", "BDBM50386178"],
  },
  {
    primary_id: "D0R5XL",
    names: ["CAPTAT-414"],
  },
  {
    primary_id: "D0R5ZH",
    names: [
      "8-Bromo-Adenosine-5'-Monophosphate",
      "8-Bromoadenosine 5'-Monophosphate",
      "8-BROMO-ADENOSINE-5'-MONOPHOSPHATE",
      "8-Bromoadenosine 5'-(Dihydrogen Phosphate)",
      "23567-96-6",
      "8-Br-AMP",
      "CHEMBL1230617",
      "8-Bromo-Adenosine Mono Phosphate",
      "50828-34-7",
      "Poly 8-Bromoadenylic Acid",
      "8BR",
      "Poly(8-Bra)",
      "Poly(8-Bromoriboadenylic Acid)",
      "8-Bromo-AMP",
      "Adenosine, 8-Bromo-, 5'-(Dihydrogen Phosphate)",
      "AC1L518I",
      "SCHEMBL4315602",
      "AC1Q270V",
      "CTK4J3218",
      "23567-96-6 (Monomer)",
      "DNPIJKNXFSPNNY-UUOKFMHZSA-N",
      "8-Bromoadenosine-5'-Monophosphate",
      "50828-34-7 (Parent)",
      "ZINC13527361",
      "MFCD00056945",
      "BDBM50222467",
    ],
  },
  {
    primary_id: "D0R5ZJ",
    names: ["SU-740"],
  },
  {
    primary_id: "D0R5ZL",
    names: ["S-(-)-Sulpiride"],
  },
  {
    primary_id: "D0R5ZR",
    names: ["RO-320432"],
  },
  {
    primary_id: "D0R6AB",
    names: ["Droxicam", "Dobenam", "Drogelon", "Droxar", "Ferpan", "Ombolan", "Precam", "E-3128", "E-318"],
  },
  {
    primary_id: "D0R6AI",
    names: ["TPX-100"],
  },
  {
    primary_id: "D0R6BI",
    names: [
      "Fenoldopam",
      "Carlacor",
      "Fenoldopamum",
      "Fenodopam Mesylate",
      "Fenoldopamum [Latin]",
      "SKF 82526",
      "Carlacor (TN)",
      "Corlopam (TN)",
      "Fenoldopam (INN);Fenoldopam [INN:BAN]",
      "SKF 82526-J",
      "SKF-82526J",
      "SK&F-82526",
      "6-Chloro-1-(4-Hydroxyphenyl)-2,3,4,5-Tetrahydro-1H-3-Benzazepine-7,8-Diol",
      "9-Chloro-5-(4-Hydroxyphenyl)-2,3,4,5-Tetrahydro-1H-3-Benzazepine-7,8-Diol",
    ],
  },
  {
    primary_id: "D0R6BR",
    names: [
      "Amobarbital",
      "AMAL",
      "Amasust",
      "Amital",
      "Amobarbitale",
      "Amobarbitalum",
      "Amobarbitone",
      "Amospan",
      "Amybal",
      "Amylbarbitone",
      "Amylobarbital",
      "Amylobarbitone",
      "Amytal",
      "Barbamil",
      "Barbamyl",
      "Binoctal",
      "Dorlotyn",
      "Dormytal",
      "Eunoctal",
      "Isomyl",
      "Isomytal",
      "Mylodorm",
      "Pentymal",
      "Pentymalum",
      "Robarb",
      "Schiwanox",
      "Sednotic",
      "Somnal",
      "Stadadorm",
      "Sumital",
      "Talamo",
      "Amobarbital Suppository Dosage Form",
      "Amobarbitale [DCIT]",
      "Barbamyl Acid",
      "Component Of Dexamyl",
      "Ethylisopentylbarbituric Acid",
      "Isoamylethylbarbituric Acid",
      "Amobarbital [INN:JAN]",
      "Amobarbitalum [INN-Latin]",
      "Component Of 15-90",
      "Component Of Amo-Dextrosule",
      "Component Of Q-Caps",
      "Isomytal (TN)",
      "Amobarbital (JP15/INN)",
      "5-Ethyl-5-(3-Methylbutyl)-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Ethyl-5-(3-Methylbutyl)Barbituric Acid;5-Ethyl-5-Isoamylbarbituric Acid",
      "5-Ethyl-5-Isoamylmalonyl Urea",
      "5-Ethyl-5-Isopentylbarbituric Acid",
      "5-Ethyl-5-Isopentylbarbitursaeure",
      "5-Isoamyl-5-Ethylbarbituric Acid",
      "5-Ethyl-5-(3-Methylbutyl)-1,3-Diazinane-2,4,6-Trione",
      "5-Ethyl-5-(3-Methylbutyl)Pyrimidine-2,4,6(1H,3H,5H)-Trione",
    ],
  },
  {
    primary_id: "D0R6CD",
    names: [
      "Insulin",
      "BrioDurance",
      "Insulin (Agglomerated Vesicle Technology, Diabetes), Cense Biosciences",
      "Insulin (Subcutaneous/Nanoparticle/Suspension/Sustained Release Formulation, Diabetes)",
      "Insulin (Subcutaneous/Nanoparticle/Suspension/Sustained Release Formulation, Diabetes), Cense Biosciences",
    ],
  },
  {
    primary_id: "D0R6CW",
    names: ["1-Phenyl-3-(1-Propionylpiperidin-4-Yl)Urea", "CHEMBL1256163", "SCHEMBL2729525", "BDBM50327829"],
  },
  {
    primary_id: "D0R6DA",
    names: ["3-Ethoxycarbonylpyroglutamate"],
  },
  {
    primary_id: "D0R6DT",
    names: ["Equilin"],
  },
  {
    primary_id: "D0R6EC",
    names: ["BC-7013"],
  },
  {
    primary_id: "D0R6EF",
    names: ["Heteroaryl-Azepine Derivative 9"],
  },
  {
    primary_id: "D0R6EL",
    names: ["ETC-1001"],
  },
  {
    primary_id: "D0R6EQ",
    names: ["Bivatuzumab Mertansine", "BIWI-1", "Bivatuzumab-DM1"],
  },
  {
    primary_id: "D0R6FJ",
    names: [
      "NORHARMANE",
      "9H-Pyrido[3,4-B]indole",
      "Norharman",
      "Norharmane",
      "244-63-3",
      "Beta-Carboline",
      "2,9-Diazafluorene",
      "Carbazoline",
      "9H-Beta-Carboline",
      "2-Azacarbazole",
      "9H-Pyrido(3,4-B)Indole",
      ".beta.-Carboline",
      "Carbazoline (VAN)",
      "B-Carboline",
      "Beta Carboline",
      "2H-Pyrido[3,4-B]indole",
      "UNII-94HMA1I78O",
      "CCRIS 6915",
      "CHEBI:109895",
      "EINECS 205-959-0",
      "NSC 84417",
      "BRN 0128414",
      "MLS000069651",
      "94HMA1I78O",
      "CHEMBL275224",
      "AIFRHYZBTHREPW-UHFFFAOYSA-N",
      "Norharman, 98%",
      "SMR000058207",
      "AK-25655",
      "N-8720",
      "N-8700",
      "244-66-6",
      "SR-01000000213",
      "9h-",
      "Prestwick_363",
      "Norharmane, 98%",
      "Zlchem 7",
    ],
  },
  {
    primary_id: "D0R6FO",
    names: ["Aryl Mannoside Derivative 10"],
  },
  {
    primary_id: "D0R6GB",
    names: ["AG-SFT103", "Antihypertensives, Ahn-Gook"],
  },
  {
    primary_id: "D0R6GK",
    names: ["F-G-G-F-T-G-A-R-K-S-A-R-K-L-MeA-N-Q-CONH2"],
  },
  {
    primary_id: "D0R6GM",
    names: ["Phosphate Binding Agent"],
  },
  {
    primary_id: "D0R6GO",
    names: ["PMID30247903-Compound-General Structure43"],
  },
  {
    primary_id: "D0R6GQ",
    names: [
      "Isocitric Acid",
      "Citric Acid, Tetrakis(Trimethylsilyl) Deriv.",
      "1,2,3-Propanetricarboxylic Acid, 2-[(Trimethylsilyl)Oxy]-, Tris(Trimethylsilyl) Ester",
      "Citric Acid, (4TMS)",
      "Citric Acid, Tetra-TMS",
      "Citric Acid, Tetrakis-TMS",
      "CITRIC ACID-TETRA-TMS",
      "Citric Acid, 4TMS Derivative",
      "AC1LB862",
      "CTK7F4886",
      "VFGAVMGYDWDESE-UHFFFAOYSA-N",
      "14330-97-3",
      "3-O-Trimethylsilylcitric Acid Tri(Trimethylsilyl) Ester",
      "Citric Acid, Trimethylsilyl Ether, Tris(Trimethylsilyl) Ester",
      "Tris(Trimethylsilyl) 2-Trimethylsilyloxypropane-1,2,3-Tricarboxylate",
    ],
  },
  {
    primary_id: "D0R6HF",
    names: ["Pyrazole Derivative 71"],
  },
  {
    primary_id: "D0R6HN",
    names: ["Strontium Chloride Sr-89"],
  },
  {
    primary_id: "D0R6IF",
    names: ["EF-4", "Efabetic", "Tarabetic"],
  },
  {
    primary_id: "D0R6IM",
    names: ["Empagliflozin/Metformin"],
  },
  {
    primary_id: "D0R6JE",
    names: ["Nicotinamide-Adenine-Dinucleotide"],
  },
  {
    primary_id: "D0R6JG",
    names: ["BIX 02188", "BIX02188", "BIX-02188"],
  },
  {
    primary_id: "D0R6JK",
    names: ["4-(4-Hydroxybenzylideneamino)Benzoic Acid"],
  },
  {
    primary_id: "D0R6JV",
    names: [
      "CX-1739",
      "CX-1739 (Injectable/AMPAKINE, Respiratory Depression)",
      "CX-717 Follow-On Compound (AMPAKINE), Cortex Pharmaceuticals",
      "CX-1739 (Injectable/AMPAKINE, Respiratory Depression), Biovail Laboratories",
      "CX-1739 (Injectable/AMPAKINE, Respiratory Depression), Cortex Pharmaceuticals",
      "CX-1739 (Injectable/AMPAKINE, Respiratory Depression), Valeant",
    ],
  },
  {
    primary_id: "D0R6KH",
    names: ["PMID26924192-Compound-56"],
  },
  {
    primary_id: "D0R6KV",
    names: ["Moexiprilat", "103775-14-0"],
  },
  {
    primary_id: "D0R6LF",
    names: ["Citalopram Derivative 1"],
  },
  {
    primary_id: "D0R6LU",
    names: ["1-Propyl-3-P-Tolyl-Piperidine Hydrochloride"],
  },
  {
    primary_id: "D0R6MB",
    names: [
      "6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDINE-4-CARBOXAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1082305",
      "BDBM50320178",
    ],
  },
  {
    primary_id: "D0R6MH",
    names: ["Cadrofloxacin", "Caderofloxacin", "CS-940"],
  },
  {
    primary_id: "D0R6MJ",
    names: ["MORAb-009", "Amatuximab"],
  },
  {
    primary_id: "D0R6MT",
    names: [
      "Isobutylmethylxanthine",
      "3-Isobutyl-1-Methylxanthine",
      "IBMX",
      "28822-58-4",
      "1-METHYL-3-ISOBUTYLXANTHINE",
      "Methylisobutylxanthine",
      "3-Isobutyl-1-Methyl-1H-Purine-2,6(3H,7H)-Dione",
      "Xanthine, 3-Isobutyl-1-Methyl-",
      "3-Isobutyl-1-Methyxanthine",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1-Methyl-3-(2-Methylpropyl)-",
      "Methyl-Isobutylxanthine",
      "3-Isobutyl-1-Methylxanthine (Ibmx)",
      "UNII-TBT296U68M",
      "3-Isobutyl 1-Methylxanthine",
      "NSC 165960",
      "CCRIS 4290",
      "CHEBI:34795",
      "1-Methyl-3-(2-Methylpropyl)-7H-Purine-2,6-Dione",
      "3-Isobutyl-1-Methyl-7H-Xanthine",
      "3-Isobutyl-1-Methyl-3,9-Dihydro-Purine-2,6-Dione",
      "3-ISOBUTYL-1-METHYLXANTHINE",
    ],
  },
  {
    primary_id: "D0R6NP",
    names: ["AZ12419304", "AZ 12419304", "AZ-12419304"],
  },
  {
    primary_id: "D0R6OD",
    names: ["AnergiX-IDDM", "Antigen Specific Therapy (Diabetes, AnergiX), Corixa"],
  },
  {
    primary_id: "D0R6OM",
    names: ["ACTB-1003"],
  },
  {
    primary_id: "D0R6PF",
    names: ["PMID22545772C42"],
  },
  {
    primary_id: "D0R6PX",
    names: ["(-)-(R)-Efonidipine"],
  },
  {
    primary_id: "D0R6RB",
    names: ["PSD-597"],
  },
  {
    primary_id: "D0R6RC",
    names: ["Chlortetracycline", "Aureomycin"],
  },
  {
    primary_id: "D0R6RE",
    names: ["Esterified Estrogens"],
  },
  {
    primary_id: "D0R6RM",
    names: ["CP-118", "Antihistamine Combination"],
  },
  {
    primary_id: "D0R6RO",
    names: ["Mianserin"],
  },
  {
    primary_id: "D0R6RT",
    names: ["Tetra-Hydro-Isoquinoline Derivative 4"],
  },
  {
    primary_id: "D0R6RU",
    names: ["STI571", "1092942-82-9", "Imatinib-D8", "Imatinib D8", "[d8]-Imatinib", "DTXSID70649425", "J-002260"],
  },
  {
    primary_id: "D0R6TI",
    names: [
      "Inamrinone Lactate",
      "Amrinone",
      "Inamrinone",
      "60719-84-8",
      "Wincoram",
      "Inocor",
      "Cordemcura",
      "Cartonic",
      "5-Amino-[3,4'-Bipyridin]-6(1H)-One",
      "Amrinonum [INN-Latin]",
      "Amrinonum",
      "Amrinona",
      "Amrinona [INN-Spanish]",
      "3-Amino-5-(4-Pyridinyl)-2(1H)-Pyridinone",
      "Win-40680",
      "Amcoral",
      "5-Amino-(3,4'-Bipyridin)-6(1H)-One",
      "WIN 40680",
      "AWD 08-250",
      "3-Amino-5-Pyridin-4-Yl-1H-Pyridin-2-One",
      "UNII-JUT23379TN",
      "CCRIS 3794",
      "Amrinone Lactate",
      "MLS000069829",
      "EINECS 262-390-0",
      "5-Amino-3,4'-Bipyridin-6(1H)-One",
      "BRN 0744819",
      "5-Amino(3,4'-Bipyridin)-6(1H)-One",
      "SMR000058850",
      "CHEMBL12856",
      "JUT23379TN",
    ],
  },
  {
    primary_id: "D0R6TL",
    names: [
      "N-(6-Chloro-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1082235",
      "N-(6-Chloro-1H-Indazol-3-Yl)Butanamide",
      "SCHEMBL4497072",
      "VHQZZDZWVQELLJ-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0R6TY",
    names: [
      "Noribogaine",
      "CHEMBL343956",
      "RAUCDOKTMDOIPF-NYRFGNLRSA-N",
      "BDBM50067814",
      "17-Ethyl-(1R,17S)-3,13-Diazapentacyclo[13.3.1.02,10.04,9.013,18]nonadeca-2(10),4(9),5,7-Tetraen-7-Ol (Noribogaine)",
    ],
  },
  {
    primary_id: "D0R6UA",
    names: ["8-AMINOGUANINE"],
  },
  {
    primary_id: "D0R6UI",
    names: [
      "[123I]SB 207710",
      "GTPL3243",
      "[123I]SB207710",
      "(1-Butyl-4-Piperidinylmethyl)-8-Amino-7-[123I]iodo-1,4-Benzodioxan-5-Carboxylate",
      "(1-Butylpiperidin-4-Yl)Methyl 8-Amino-7-(123I)Iodo-2,3-Dihydro-1,4-Benzodioxine-5-Carboxylate",
    ],
  },
  {
    primary_id: "D0R6UO",
    names: ["ISIS 24454"],
  },
  {
    primary_id: "D0R6UR",
    names: ["ACI-24", "Beta Amyloid Vaccine (Liposomal/SupraAntigen, Alzheimer's Disease/Down Syndrome), AC Immune"],
  },
  {
    primary_id: "D0R6UV",
    names: ["BMS-690514", "BMS 690514", "BMS690514"],
  },
  {
    primary_id: "D0R6VF",
    names: [
      "Clocinnamox",
      "Methoclocinnamox",
      "C-CAM",
      "14-Beta-(4-Chlorocinnamoylamino)-7,8-Dihydro-N-Cyclopropylmethylnorpmorphine Mesylate",
      "Clocinnamox Mesylate",
      "117332-69-1",
      "2-Propenamide, 3-(4-Chlorophenyl)-N-((5alpha)-17-(Cyclopropylmethyl)-4,5-Epoxy-3-Hydroxy-6-Oxomorphinan-14-Yl)-, Monomethanesulfonate",
      "NIH 10443 Mesylate",
      "NIH 10989",
      "SCHEMBL4404383",
      "AKOS024259103",
    ],
  },
  {
    primary_id: "D0R6VH",
    names: ["HI-1640V"],
  },
  {
    primary_id: "D0R6VV",
    names: ["INO-5150"],
  },
  {
    primary_id: "D0R6VX",
    names: ["Tyr-Pro-Trp-Gly-Bn", "CHEMBL570634"],
  },
  {
    primary_id: "D0R6WL",
    names: ["TAu MAb"],
  },
  {
    primary_id: "D0R6XI",
    names: ["Labeled TSH Superagonists"],
  },
  {
    primary_id: "D0R6YB",
    names: [
      "2-Pyridin-3-Yl-7-Aza-Bicyclo[2.2.1]heptane",
      "CHEMBL56817",
      "Deschloroepibatidine",
      "SCHEMBL8047370",
      "BDBM50100717",
      "AKOS023808321",
      "2-(3-Pyridyl)-7-Azabicyclo[2.2.1]heptane",
    ],
  },
  {
    primary_id: "D0R6YP",
    names: ["BCX-1923"],
  },
  {
    primary_id: "D0R6YZ",
    names: ["Xultophy"],
  },
  {
    primary_id: "D0R6ZB",
    names: ["EDP-10"],
  },
  {
    primary_id: "D0R6ZC",
    names: [
      "5,3'-Dipropyl-Biphenyl-2,4'-Diol",
      "CHEMBL330227",
      "35406-31-6",
      "Magreth-2",
      "SCHEMBL12319404",
      "CTK1B6970",
      "DTXSID90432573",
      "3',5-Dipropylbiphenyl-2,4'-Diol",
      "BDBM50157312",
      "AKOS030533140",
      "5,3''-Dipropyl-Biphenyl-2,4''-Diol",
      "5,3''''-Dipropyl-Biphenyl-2,4''''-Diol",
      "[1,1'-Biphenyl]-2,4'-Diol, 3',5-Dipropyl-",
    ],
  },
  {
    primary_id: "D0R6ZS",
    names: ["N-(3-Bromophenyl)-6-Chloroquinazolin-4-Amine", "CHEMBL1075626", "SCHEMBL4586988", "BDBM50311876"],
  },
  {
    primary_id: "D0R7AG",
    names: ["4-(5-Hydroxy-Benzooxazol-2-Yl)-Benzene-1,3-Diol"],
  },
  {
    primary_id: "D0R7AK",
    names: ["CP-601927", "CP-601932", "Alpha4beta2 NAchR Partial Agonists (Depression), Pfizer"],
  },
  {
    primary_id: "D0R7BB",
    names: ["GSK3359609"],
  },
  {
    primary_id: "D0R7BT",
    names: [
      "1-(2,2'-Bithiophen-5-Yl)Methanamine",
      "4380-96-5",
      "[2,2'-Bithiophen]-5-Ylmethanamine",
      "2,2'-Bithiophene-5-Methylamine",
      "2,2'-Bithiophen-5-Ylmethanamine",
      "[2,2'-Bithiophene]-5-Methanamine",
      "2,2-Bithiophene-5-Methylamine",
      "CHEMBL538940",
      "(5-(2-Thienyl)-2-Thienyl)Methylamine",
      "[5-(Thiophen-2-Yl)Thiophen-2-Yl]Methanamine",
      "SDCCGMLS-0065983.P001",
      "AC1MCQYM",
      "SCHEMBL2690127",
      "CTK1D7717",
      "DTXSID50380066",
      "MolPort-000-142-515",
      "FHYTVXBZSXZMGD-UHFFFAOYSA-N",
      "ZINC158800",
      "2,2''-Bithiophen-5-Ylmethanamine",
      "AC1Q5409",
      "QC-966",
      "SBB091920",
      "BDBM50294164",
    ],
  },
  {
    primary_id: "D0R7BX",
    names: ["RU78191"],
  },
  {
    primary_id: "D0R7CD",
    names: ["INCB59872"],
  },
  {
    primary_id: "D0R7CP",
    names: ["Bromo Benzyl Ether Derivative 2"],
  },
  {
    primary_id: "D0R7DW",
    names: ["Thiomarinols"],
  },
  {
    primary_id: "D0R7EA",
    names: ["Methyl 5-[(4-Tert-Butylbenzoyl)Amino]-2H-1,2,4-Triazole-3-Carboxylate"],
  },
  {
    primary_id: "D0R7EQ",
    names: ["Crenezumab"],
  },
  {
    primary_id: "D0R7FI",
    names: ["4-Phenyl-1-(1-Phenylpropan-2-Yl)Piperidin-4-Ol", "CHEMBL230140"],
  },
  {
    primary_id: "D0R7FS",
    names: [
      "BPI-704001",
      "BPI-705001",
      "BPI-715001",
      "PARP-1 Inhibitors (Cancer)",
      "PARP-1 Inhibitors (Cancer), Beta Pharma",
    ],
  },
  {
    primary_id: "D0R7GH",
    names: ["CS-526", "AKU-517", "R-105266"],
  },
  {
    primary_id: "D0R7GL",
    names: ["BW-A502U", "BW-502U83", "2-[10-(2-Hydroxyethoxy)-9-Anthracenylmethylamino]-2-Methyl-1,3-Propanediol"],
  },
  {
    primary_id: "D0R7HA",
    names: [
      "DPP-IV Inhibitors, Santhera",
      "DPP-IV Inhibitors (Type 2 Diabetes), Biovitrum",
      "DPP-IV Inhibitors (Type 2 Diabetes), Santhera",
      "Dipeptidyl-Peptidase IV Inhibitors (Type 2 Diabetes), Santhera",
    ],
  },
  {
    primary_id: "D0R7HL",
    names: ["LXS196"],
  },
  {
    primary_id: "D0R7HM",
    names: [
      "2-Morpholino-6-Phenyl-4H-Pyran-4-One",
      "CHEMBL105613",
      "2-Morpholin-4-Yl-6-Phenyl-Pyran-4-One",
      "SCHEMBL3540014",
    ],
  },
  {
    primary_id: "D0R7HO",
    names: [
      "Bumetanide",
      "Aquazone",
      "Bumedyl",
      "Bumetanida",
      "Bumetanidum",
      "Bumethanide",
      "Bumex",
      "Burine",
      "Burinex",
      "Butinat",
      "Cambiex",
      "Diurama",
      "Drenural",
      "Fontego",
      "Fordiuran",
      "Lixil",
      "Lunetoron",
      "Miccil",
      "Segurex",
      "Yurinex",
      "AstraZeneca Brand Of Bumetanide",
      "Atlantis Brand Of Bumetanide",
      "Bumetanide AstraZeneca Brand",
      "Bumetanide Atlantis Brand",
      "Bumetanide Farmacusi Brand",
      "Bumetanide Grossmann Brand",
      "Bumetanide Leo Brand",
      "Bumetanide Roche Brand",
      "Bumetanide Senosiain Brand",
      "Farmacusi Brand Of Bumetanide",
      "Grossmann Brand Of Bumetanide",
      "Leo Brand Of Bumetanide",
      "Roche Brand Of Bumetanide",
      "Senosiain Brand Of Bumetanide",
      "B 3023",
      "PF 1593",
      "PF1593",
      "Bumetanida [INN-Spanish]",
      "Bumetanidum [INN-Latin]",
      "Bumex (TN)",
      "Bumex, Bumetanide",
      "Lixil-Leo",
      "PF-1593",
      "Ro 10-6338",
      "Bumetanide (JP15/USP)",
      "Ro-10-6338",
      "Bumetanide (JP15/USP/INN)",
      "Bumetanide [USAN:BAN:INN:JAN]",
      "3-(Aminosulfonyl)-5-(Butylamino)-4-Phenoxybenzoic Acid",
      "3-(Butylamino)-4-Phenoxy-5-Sulfamoylbenzoic Acid",
      "3-(Aminosulfonyl)-5-(Butylamino)-4-(Phenyloxy)Benzoic Acid",
      "3-Butylamino-4-(Phenoxy)-5-Sulfamoylbenzoic Acid",
    ],
  },
  {
    primary_id: "D0R7HU",
    names: ["Pyrrolo[3,4-C]carbazole-1,3(2H,6H)-Dione", "CHEMBL370869", "SCHEMBL5381581"],
  },
  {
    primary_id: "D0R7ID",
    names: ["HMN-214"],
  },
  {
    primary_id: "D0R7II",
    names: ["Peptide Therapy, Asthma"],
  },
  {
    primary_id: "D0R7IJ",
    names: [
      "6-(3-Chloro-Benzenesulfonyl)-2H-Pyridazin-3-One",
      "SCHEMBL6206394",
      "CHEMBL197924",
      "BDBM16598",
      "JORLLGPOJPMFRS-UHFFFAOYSA-N",
      "Phenyl-Substituted Sulfonylpyridazinone, 8d",
    ],
  },
  {
    primary_id: "D0R7IM",
    names: ["UFP-101", "[Nphe1,Arg14,Lys15]N/OFQ-NH2", "UFP101", "[Nphe1,Arg14,Lys15]nociceptin-NH2", "UFP 101"],
  },
  {
    primary_id: "D0R7IU",
    names: ["NPSP-795"],
  },
  {
    primary_id: "D0R7IW",
    names: [
      "APC-0010",
      "APC-0011",
      "APC-0012",
      "APC-006",
      "APC-007",
      "APC-008",
      "APC-009",
      "Cell Mitosis Inhibitors (Cancer)",
      "Cell Mitosis Inhibitors (Cancer), Shanghai Ambrosia Pharmaceutical",
    ],
  },
  {
    primary_id: "D0R7JA",
    names: ["TMP269"],
  },
  {
    primary_id: "D0R7JE",
    names: ["Psychiatric Therapeutics"],
  },
  {
    primary_id: "D0R7JT",
    names: ["Fludrocortisone"],
  },
  {
    primary_id: "D0R7JW",
    names: ["Macrocycle Derivative 4"],
  },
  {
    primary_id: "D0R7KX",
    names: ["Rotarix"],
  },
  {
    primary_id: "D0R7LQ",
    names: ["Dideazaacyclotetrahydrofolic Acid"],
  },
  {
    primary_id: "D0R7LS",
    names: ["Bortezomib Intravenous"],
  },
  {
    primary_id: "D0R7LX",
    names: ["EU-C-002"],
  },
  {
    primary_id: "D0R7LY",
    names: [
      "Cis-H-Tyr-C[D-AllylGly-Gly-Phe-D-Allylgly]-OH",
      "CDADAE(8)",
      "CHEMBL375025",
      "BDBM21125",
      "(5S,8R,10Z,13R)-13-[(2S)-2-Amino-3-(4-Hydroxyphenyl)Propanamido]-5-Benzyl-3,6,14-Trioxo-1,4,7-Triazacyclotetradec-10-Ene-8-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0R7MJ",
    names: ["PURPUROGALLIN", "3tiy", "AC1NQYND", "BIDD:ER0545"],
  },
  {
    primary_id: "D0R7MN",
    names: ["SRI 63-441"],
  },
  {
    primary_id: "D0R7NI",
    names: ["Tagraxofusp"],
  },
  {
    primary_id: "D0R7NP",
    names: ["INP-04"],
  },
  {
    primary_id: "D0R7OD",
    names: ["SRIF-14"],
  },
  {
    primary_id: "D0R7OE",
    names: ["Salvinorin B Isopropoxymethyl Ether", "CHEMBL271078"],
  },
  {
    primary_id: "D0R7OZ",
    names: ["BGC-20-0166"],
  },
  {
    primary_id: "D0R7PE",
    names: [
      "(+/-)-5-Amino-2-(Mercaptomethyl)Pentanoic Acid",
      "CHEMBL237008",
      "SCHEMBL11507369",
      "IKYCTNVIQZAWIB-UHFFFAOYSA-N",
      "BDBM50226603",
      "5-Amino-2-Mercaptomethylpentanoic Acid",
    ],
  },
  {
    primary_id: "D0R7QC",
    names: ["D[Arg4]AVP", "CHEMBL375324"],
  },
  {
    primary_id: "D0R7QF",
    names: ["(1R,2S)-N-Arachidonoylcyclopropanolamide", "Cyclopropanolamide, 14a", "CHEMBL485368"],
  },
  {
    primary_id: "D0R7QL",
    names: ["9-N-ETHYL-8-ETHOXY-MANZAMINE A", "9-N-Ethyl-8-Ethoxy-Manzamine A", "CHEMBL403562"],
  },
  {
    primary_id: "D0R7QW",
    names: ["SR-2566"],
  },
  {
    primary_id: "D0R7QY",
    names: ["EMATE"],
  },
  {
    primary_id: "D0R7QZ",
    names: ["2-(2-Hydroxy-Phenyl)-6-Methyl-Chromen-4-One", "CHEMBL134408"],
  },
  {
    primary_id: "D0R7RB",
    names: ["Purine Riboside"],
  },
  {
    primary_id: "D0R7RY",
    names: ["Cyclopropylamine Derivative 8"],
  },
  {
    primary_id: "D0R7SM",
    names: ["PMID15686947C(S)-3h", "2-Benzylpyrridine-Substituted Aryloxypropanol Analogue", "GTPL712", "ZINC14192237"],
  },
  {
    primary_id: "D0R7UI",
    names: ["Peptide Analog 57"],
  },
  {
    primary_id: "D0R7UK",
    names: ["UR-8308"],
  },
  {
    primary_id: "D0R7UL",
    names: ["MD1003"],
  },
  {
    primary_id: "D0R7UO",
    names: [
      "5-Aminomethyl-3-Pyrrol-1-Yl-Oxazolidin-2-One",
      "CHEMBL17643",
      "2-Oxazolidinone, 5-(Aminomethyl)-3-(1H-Pyrrol-1-Yl)-, (5R)-",
      "BDBM50110724",
      "406934-17-6",
    ],
  },
  {
    primary_id: "D0R7US",
    names: ["Interferon Gamma-1a", "Biogamma (TN)"],
  },
  {
    primary_id: "D0R7VT",
    names: [
      "4-(1H-Indol-1-Ylsulfonyl)Benzenamine",
      "CHEMBL372109",
      "BDBM34151",
      "N-Arenesulfonylindole Antagonist, 10",
    ],
  },
  {
    primary_id: "D0R7VW",
    names: ["4-(3-Chlorophenylthio)-2-(Pyridin-2-Yl)Pyrimidine", "CHEMBL208781", "SCHEMBL6507354", "BDBM50182809"],
  },
  {
    primary_id: "D0R7WK",
    names: ["Neuropeptide S Antagonist"],
  },
  {
    primary_id: "D0R7WU",
    names: [
      "ACECLIDINE",
      "(-)-Aceclidine",
      "R-(-)-Aceclidine",
      "GTPL288",
      "ZB016005",
      "[(8R)-1-Azoniabicyclo[2.2.2]octan-8-Yl] Acetate",
    ],
  },
  {
    primary_id: "D0R7WY",
    names: [
      "AMPA Receptor Positive Modulators",
      "AMPA Receptor Positive Modulators (Neurological Disorders)",
      "AMPA Receptor Positive Modulators (Neurological Disorders), Merck & Co",
    ],
  },
  {
    primary_id: "D0R7XD",
    names: [
      "GSK1325756",
      "Danirixin",
      "954126-98-8",
      "UNII-R318PGH5VP",
      "GSK-1325756B",
      "R318PGH5VP",
      "GSK1325756B",
      "Danirixin [USAN:INN]",
      "GSK-1325756",
      "GSK 1325756",
      "Danirixin (USAN/INN)",
      "Danirixin(GSK1325756)",
      "GTPL8500",
      "SCHEMBL1198688",
      "CHEMBL3039531",
      "MolPort-044-561-791",
      "EX-A1178",
      "ZINC95627831",
      "AKOS030527036",
      "DB11922",
      "CS-5465",
      "1-(4-Chloro-2-Hydroxy-3-(((3S)-Piperidine-3-Sulfonyl)Phenyl)-3-(3-Fluoro-2-Methylphenyl)Urea",
      "HY-19768",
      "D10387",
      "(S)-1-(4-Chloro-2-Hydroxy-3-(Piperidin-3-Ylsulfonyl)Phenyl)-3-(3-Fluoro-2-Methylphenyl)Urea",
    ],
  },
  {
    primary_id: "D0R7YI",
    names: ["PMID30107136-Compound-Example13"],
  },
  {
    primary_id: "D0R7YN",
    names: ["Mannoside Derivative 3"],
  },
  {
    primary_id: "D0R7YY",
    names: ["Darusentan"],
  },
  {
    primary_id: "D0R7ZC",
    names: [
      "Beta-Methoxyamphetamine",
      "4-Methoxyamphetamine",
      "P-Methoxyamphetamine",
      "Para-Methoxyamphetamine",
      "1-(4-Methoxyphenyl)Propan-2-Amine",
      "64-13-1",
      "1-P-Methoxyphenyl-2-Propylamine",
      "1-P-Methoxyphenyl-2-Aminopropane",
      "Paramethoxyamphetamine",
      "1-(4-Methoxybenzyl)Ethylamine",
      "2-Amino-1-(4'-Methoxyphenyl)Propane",
      "P-Methoxy-Alpha-Methylphenethylamine",
      "(+-)-P-Methoxyamphetamine",
      "DEA No 7411",
      "NSC 32757",
      "50505-80-1",
      "23239-32-9",
      "DL-P-Methoxy-Alpha-Methylphenethylamine",
      "(+-)-P-Methoxy-Alpha-Methylphenethylamine",
    ],
  },
  {
    primary_id: "D0R8AG",
    names: ["Prophage Cancer Vaccine"],
  },
  {
    primary_id: "D0R8AZ",
    names: ["DAU-5884", "131780-47-7"],
  },
  {
    primary_id: "D0R8BC",
    names: [
      "6,7-Dimethoxy-4-(4-Methoxy-Phenoxy)-Quinoline",
      "CHEMBL98584",
      "6,7-Dimethoxy-4-(4-Methoxyphenoxy)Quinoline",
      "SCHEMBL7486400",
      "GBYWCVLXEHHOFE-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0R8CA",
    names: ["NM-9505", "Bujaciomab, Novelmed Therapeutics Inc"],
  },
  {
    primary_id: "D0R8CE",
    names: ["M-114"],
  },
  {
    primary_id: "D0R8CN",
    names: ["Glycolipids"],
  },
  {
    primary_id: "D0R8CR",
    names: ["PMID26924192-Compound-54"],
  },
  {
    primary_id: "D0R8DO",
    names: [
      "1-Benzyl-4-(4-Phenylpiperazin-1-Yl)Phthalazine",
      "CHEMBL496013",
      "1-Benzyl-4-(4-Phenylpiperazino)Phthalazine",
      "320418-04-0",
      "AC1O4EAW",
      "Oprea1_653246",
      "SCHEMBL13444952",
      "KS-000031YH",
      "ZINC1393434",
      "BDBM50269040",
      "AKOS005082019",
    ],
  },
  {
    primary_id: "D0R8DW",
    names: ["Benzoimidazole Derivative 2"],
  },
  {
    primary_id: "D0R8ER",
    names: [
      "Enoxacin",
      "Almitil",
      "Bactidan",
      "Comprecin",
      "Enoram",
      "Enoxacine",
      "Enoxacino",
      "Enoxacinum",
      "Enoxin",
      "Enoxor",
      "Flumark",
      "Penetrex",
      "Enoxacin Sesquihydrate",
      "Enoxacine [French]",
      "Enoxacino [Spanish]",
      "Enoxacinum [Latin]",
      "Faulding Brand Of Enoxacin",
      "Pierre Fabre Brand Of Enoxacin Sesquihydrate",
      "Rhone Poulenc Rorer Brand Of Enoxacin Sesquihydrate",
      "AT 2266",
      "AT2266",
      "CI919",
      "CL23362",
      "E0762",
      "PD 107779",
      "PD107779",
      "AT-2266",
      "Almitil (TN)",
      "Bactidan (TN)",
      "Bactidron (TN)",
      "Comprecin (TN)",
      "Enoksetin (TN)",
      "Enoxen (TN)",
      "Enoxin (TN)",
      "Enoxor (TN)",
      "Enroxil (TN)",
      "Flumark (TN)",
      "Gyramid (TN)",
      "PD-107779",
      "Penetrex (TN)",
      "Rhone-Poulenc Rorer Brand Of Enoxacin Sesquihydrate",
      "Sesquihydrate, Enoxacin",
      "Vinone (TN)",
      "Enoxacin (USAN/INN)",
      "Enoxacin [USAN:BAN:INN:JAN]",
      "1,8-Naphthyridine-3-Carboxylic Acid, 6-Fluoro-1,4-Dihydro-4-Oxo-7-Piperazinyl",
      "1-Ethyl-6-Fluoro-1,4-Dihydro-4-Oxo-7-(1-Piperazinyl)-1,8-Naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-1,4-Dihydro-4-Oxo-7-[1-Piperazinyl]-1,8-Naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-4-Oxo-7-Piperazin-1-Yl-1,4-Dihydro-[1,8]naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-4-Oxo-7-(Piperazin-1-Yl)-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-4-Oxo-7-Piperazin-1-Yl-1,4-Dihydro-1,8-Naphthyridine-3-Carboxylic Acid",
      "1-Ethyl-6-Fluoro-4-Oxo-7-Piperazin-1-Yl-1,8-Naphthyridine-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0R8EU",
    names: ["FTIDC"],
  },
  {
    primary_id: "D0R8EV",
    names: [
      "2-(Tert-Butylamino)-1-(3-Chlorophenyl)Butan-1-One",
      "CHEMBL566886",
      "AC1MHKA5",
      "SCHEMBL770048",
      "BDBM50302929",
    ],
  },
  {
    primary_id: "D0R8EW",
    names: [
      "SL-017",
      "ACPSL-017",
      "HB Topical, Altachem",
      "HB Topical, Quest",
      "SonoLight Topical HB, Altachem",
      "SonoLight Topical HB, Quest",
      "ACP-SL017 (Topical), Altachem",
      "ACP-SL017 (Topical), Quest",
      "Hypocrellin-Based Products (Non-Cancer), Altachem",
      "Hypocrellin-Based Products (Non-Cancer), Quest",
    ],
  },
  {
    primary_id: "D0R8FL",
    names: ["Egalet-002"],
  },
  {
    primary_id: "D0R8FM",
    names: ["Ebrotidine", "Ebrocit", "FI-3542"],
  },
  {
    primary_id: "D0R8FS",
    names: [
      "7-Dimethylamino-Hept-5-Yn-2-One",
      "CHEMBL166736",
      "7-(Dimethylamino)Hept-5-Yn-2-One",
      "AC1L2EXE",
      "AC1Q5H3K",
    ],
  },
  {
    primary_id: "D0R8GA",
    names: ["PMID25666693-Compound-14"],
  },
  {
    primary_id: "D0R8GK",
    names: ["PMID30259754-Compound-Px-102"],
  },
  {
    primary_id: "D0R8GR",
    names: ["OPC-14523"],
  },
  {
    primary_id: "D0R8GW",
    names: ["AC-7954", "AC7954", "AC 7954"],
  },
  {
    primary_id: "D0R8HY",
    names: [
      "DCCCyB",
      "CHEMBL2146721",
      "916159-99-4",
      "Benzamide,2,4-Dichloro-N-[[1-(Cyclopropylmethyl)-4-[(Cyclopropylmethyl)Sulfonyl]cyclohexyl]methyl]-",
      "SCHEMBL678963",
      "ZINC34807040",
      "BDBM50391757",
      "KB-74916",
      "J3.560.315H",
    ],
  },
  {
    primary_id: "D0R8ID",
    names: ["P-7170"],
  },
  {
    primary_id: "D0R8IE",
    names: ["Inecalcitol Oral"],
  },
  {
    primary_id: "D0R8IQ",
    names: [
      "WY-46016",
      "104325-55-5",
      "3-(2-Quinolinylmethoxy)Benzeneacetic Acid",
      "Wy-46016",
      "WY-46,016",
      "AC1L2TZC",
      "2-[3-(Quinolin-2-Ylmethoxy)Phenyl]acetic Acid",
      "[3-(Quinolin-2-Ylmethoxy)-Phenyl]-Acetic Acid",
      "CHEMBL310611",
      "SCHEMBL9053241",
      "CTK8G4858",
      "DTXSID00146391",
      "VTTJDSVAGYOMKY-UHFFFAOYSA-N",
      "ZINC5162878",
      "3-(2-Quinolinylmethoxy)Phenylacetic Acid",
      "3-[(2-Quinolinyl)Methoxy]benzeneacetic Acid",
      "Benzeneacetic Acid, 3-(2-Quinolinylmethoxy)-",
    ],
  },
  {
    primary_id: "D0R8IU",
    names: ["AZD-4017", "AZD-4017 (Oral Tablet, Ocular Hypertension)"],
  },
  {
    primary_id: "D0R8JK",
    names: ["N-Phenyl-6-(Sulfamoylamino)Hexanamide", "CHEMBL474097", "SCHEMBL5326902"],
  },
  {
    primary_id: "D0R8JN",
    names: [
      "AF-3485",
      "AF-3485 Program (Inflammatory Pain/Neoplasm)",
      "AF-3485 Program (Inflammatory Pain/Neoplasm), Angelini Pharmaceuticals",
      "MPGES-1 Inhibitors (Inflammatory Pain/Neoplasm), Angelini",
    ],
  },
  {
    primary_id: "D0R8JR",
    names: ["Propan-2-One O-4-Butoxyphenylcarbamoyl Oxime", "CHEMBL597086"],
  },
  {
    primary_id: "D0R8KA",
    names: ["Pyrrolopyrimidine 48"],
  },
  {
    primary_id: "D0R8KF",
    names: ["STP-322"],
  },
  {
    primary_id: "D0R8KR",
    names: [
      "DNA Fusion Vaccine",
      "ACVA",
      "DNA Fusion Vaccine (CEA-Expressing Tumors)",
      "PDOM-CAP-1",
      "DNA Fusion Vaccine (CEA-Expressing Tumors), Cancer Research UK",
    ],
  },
  {
    primary_id: "D0R8LB",
    names: ["NAEPA"],
  },
  {
    primary_id: "D0R8LN",
    names: ["N-{4-[2-(3-Methoxyphenyl)Ethyl]phenyl}phthalimide", "CHEMBL572484"],
  },
  {
    primary_id: "D0R8LQ",
    names: ["AQST-203"],
  },
  {
    primary_id: "D0R8LV",
    names: ["NI-0401", "Anti-CD3 Antibody, NovImmune/Merck Serono"],
  },
  {
    primary_id: "D0R8LW",
    names: [
      "ORG-34167",
      "(-)-(S)-1-[2-(3-Benzisoxazolyl)Phenyl]-3-Butenylamine",
      "2-(1,2-Benzisoxazol-3-Yl)-Alpha(S)-(2-Propenyl)Benzenemethanamine",
    ],
  },
  {
    primary_id: "D0R8NC",
    names: ["PMID7473541C20"],
  },
  {
    primary_id: "D0R8NE",
    names: [
      "1-(2-Phenoxy-Ethyl)-1H-Imidazole",
      "CHEMBL284827",
      "1H-Imidazole, 1-(2-Phenoxyethyl)-",
      "1-(2-Phenoxyethyl)Imidazole",
      "SCHEMBL10370478",
      "INUSBZKZIYQSRM-UHFFFAOYSA-N",
      "ZINC26473891",
      "BDBM50025988",
      "AKOS002677376",
    ],
  },
  {
    primary_id: "D0R8OK",
    names: [
      "8-Bromo-9-Methyl-9H-Adenine",
      "8-Bromo-9-Methyladenine",
      "CHEMBL510288",
      "56489-40-8",
      "AC1LGJ6D",
      "Oprea1_555380",
      "SCHEMBL8832637",
      "8-Bromo-9-Methylpurin-6-Amine",
      "CTK1E1875",
      "DTXSID90356366",
      "ICJDGKILGBQWGF-UHFFFAOYSA-N",
      "BDBM50256842",
      "AKOS022687396",
      "6-Amino-8-Bromo-9-Methyl-9H-Purine",
      "9H-Purin-6-Amine, 8-Bromo-9-Methyl-",
    ],
  },
  {
    primary_id: "D0R8OW",
    names: ["Sulfonamide Derivative 7"],
  },
  {
    primary_id: "D0R8PE",
    names: [
      "Pivagabine",
      "4-Pivalamidobutanoic Acid",
      "69542-93-4",
      "4-[(2,2-Dimethylpropanoyl)Amino]butanoic Acid",
      "UNII-C53SV0WO4V",
      "Pivagabine [INN]",
      "C53SV0WO4V",
      "Pivagabine (INN)",
      "NCGC00160457-01",
      "4-(2,2-Dimethylpropanamido)Butanoic Acid",
      "4-[(2,2-Dimethylpropionyl)Amino]butyric Acid",
      "DSSTox_CID_26162",
      "DSSTox_RID_81393",
      "DSSTox_GSID_46162",
      "4-(2,2-DIMETHYLPROPIONYLAMINO)BUTYRIC ACID",
      "Tonerg",
      "CAS-69542-93-4",
      "4-((2,2-Dimethylpropionyl)Amino)Butyric Acid",
      "SR-01000945179",
      "CXB-722",
      "EINECS 274-038-3",
      "N-Trimethylacetyl-4-Aminobutyric Acid",
      "CXB722",
    ],
  },
  {
    primary_id: "D0R8PL",
    names: ["11-Hexanoyloxy-N-N-Propylnoraporphine", "CHEMBL515342"],
  },
  {
    primary_id: "D0R8PX",
    names: ["Vasicine"],
  },
  {
    primary_id: "D0R8QG",
    names: ["EMD-1214063"],
  },
  {
    primary_id: "D0R8QX",
    names: [
      "HMGB-1 Protein And Its Fragments",
      "CTR-1",
      "CTR-2",
      "CTR-3",
      "CTR-4",
      "HMGB-1 Protein And Its Fragments (Wound Healing/Cartilage Repair/Bone Matrixregeneration/Ligaments And Tendon Repair)",
      "HMGB-1 Protein And Its Fragments (Wound Healing/Cartilage Repair/Bone Matrix Regeneration/Ligaments And Tendon Repair), Bio3",
    ],
  },
  {
    primary_id: "D0R8RK",
    names: ["Ro-25-0534"],
  },
  {
    primary_id: "D0R8RO",
    names: ["SAR-21609"],
  },
  {
    primary_id: "D0R8SY",
    names: ["Limtop"],
  },
  {
    primary_id: "D0R8TB",
    names: ["ERA-923"],
  },
  {
    primary_id: "D0R8TG",
    names: ["[(N4-Bzdig)0]BB(7-14)", "CHEMBL414307"],
  },
  {
    primary_id: "D0R8TH",
    names: ["(RS/SR)-2-[1-(4-Chlorophenyl)Propyl]piperidine", "CHEMBL376852", "BDBM50202382"],
  },
  {
    primary_id: "D0R8TL",
    names: [
      "DS2",
      "374084-31-8",
      "4-Chloro-N-[2-(Thiophen-2-Yl)Imidazo[1,2-A]pyridin-3-Yl]benzamide",
      "4-Chloro-N-[2-(2-Thienyl)Imidazo[1,2-A]pyridin-3-Yl]benzamide",
      "4-Chloro-N-(2-Thiophen-2-Ylimidazo[1,2-A]pyridin-3-Yl)Benzamide",
      "AC1LJTJ1",
      "AC1Q3INX",
      "CBMicro_003068",
      "Oprea1_620139",
      "GTPL4184",
      "SCHEMBL19695349",
      "CTK8E8260",
      "DTXSID00359666",
      "MolPort-000-854-183",
      "ZINC624098",
      "SMSF0018750",
      "MFCD01949910",
      "CCG-20403",
      "STK820984",
      "DS2, &gt",
      "AKOS001666828",
      "CB05084",
      "MCULE-5461685392",
      "NCGC00370916-01",
      "NS-05662",
      "BIM-0003182.P001",
      "RT-012420",
      "B7467",
    ],
  },
  {
    primary_id: "D0R8TR",
    names: ["1,5-Dideoxy-1,5-Imino-D-Xylitol"],
  },
  {
    primary_id: "D0R8UO",
    names: ["PMID25726713-Compound-26"],
  },
  {
    primary_id: "D0R8UU",
    names: ["1-[2-(2-Benzyl-Phenoxy)-Ethyl]-Piperidine", "CHEMBL364565", "SCHEMBL8773745", "BDBM50151048"],
  },
  {
    primary_id: "D0R8VT",
    names: [
      "3-(2-Dimethylamino-Ethyl)-1-Methyl-1H-Indol-4-Ol",
      "1-METHYLPSILOCIN",
      "1465-16-3",
      "3-[2-(DIMETHYLAMINO)ETHYL]-1-METHYLINDOL-4-OL",
      "CHEMBL197664",
      "SCHEMBL12558828",
      "CTK4C4996",
      "DTXSID40658761",
      "MolPort-023-276-636",
      "ZINC13985099",
      "BDBM50171261",
      "AKOS024457385",
      "API0007835",
      "B7244",
      "J-008232",
    ],
  },
  {
    primary_id: "D0R8VU",
    names: ["MyeloXen"],
  },
  {
    primary_id: "D0R8XD",
    names: ["Peptide Analog 14"],
  },
  {
    primary_id: "D0R8XV",
    names: ["2-N-Methylamino-1-(4-Ethylthiophenyl)Propane", "CHEMBL1077872", "1204749-25-6"],
  },
  {
    primary_id: "D0R8XX",
    names: [
      "N-[2-(5-Hydroxy-1H-Indol-3-Yl)Ethyl]lauramide",
      "CHEMBL394636",
      "BDBM22995",
      "N-Arachidonoylserotonin Analogue, 1h",
    ],
  },
  {
    primary_id: "D0R8YJ",
    names: [
      "N-Ethyl-N-Methyl-4-(Pyridin-3-Yl)But-3-Yn-1-Amine",
      "CHEMBL150948",
      "BDBM50138487",
      "1-(3-Pyridinyl)-4-(Methylethylamino)-1-Butyne",
    ],
  },
  {
    primary_id: "D0R8YO",
    names: ["CEDO-8956"],
  },
  {
    primary_id: "D0R8YV",
    names: ["PMID1656041C74"],
  },
  {
    primary_id: "D0R9AA",
    names: [
      "(S)-2-Amino-Propane-1-Thiol",
      "CHEMBL37279",
      "2(R,S)-Aminopropanethiol",
      "(S)-2-Aminopropane-1-Thiol",
      "ZINC5138737",
      "BDBM50078126",
    ],
  },
  {
    primary_id: "D0R9AB",
    names: ["Hsp90 Inhibitors"],
  },
  {
    primary_id: "D0R9AD",
    names: ["N-(3,5-Dichlorophenyl)Imidodicarbonimidic Diamide"],
  },
  {
    primary_id: "D0R9AP",
    names: ["H-Apa-Ala-Gly-Phe-Leu-OH", "CHEMBL247363"],
  },
  {
    primary_id: "D0R9BG",
    names: [
      "Acetohydroxamic Acid",
      "AHA",
      "Acethydroxamsaeure",
      "Acethydroxamsaure",
      "Acetohydroxamate",
      "HAE",
      "Lithostat",
      "Acethydroxamic Acid",
      "Acethydroxamsaeure [German]",
      "Acetohydroximic Acid",
      "Acetyl Hydroxyamino",
      "Acetylhydroxamic Acid",
      "Acide Acetohydroxamique",
      "Acide Acetohydroxamique [French]",
      "Acido Acetohidroxamico",
      "Acido Acetohidroxamico [Spanish]",
      "Acidum Acetohydroxamicum",
      "Acidum Acetohydroxamicum [Latin]",
      "Cetohyroxamic Acid",
      "Methylhydroxamic Acid",
      "SJX HLdmMAH",
      "AHA (TN)",
      "Acetic Acid, Oxime",
      "Acetohydroxamic Acid [USAN:INN]",
      "Lithostat (TN)",
      "N-Acetyl Hydroxyacetamide",
      "N-Acetylhydroxylamine",
      "N-Hydroxyacetamide",
      "N-Hydroxyacetimidic Acid",
      "N-Hydroxyethanimidic Acid",
      "S14-0751",
      "Acetohydroxamic Acid (USP/INN)",
      "Acetamide, N-Hydroxy-(9CI)",
    ],
  },
  {
    primary_id: "D0R9BP",
    names: ["ISIS 7847"],
  },
  {
    primary_id: "D0R9CC",
    names: ["BW 245C", "BW245C", "BW-245C"],
  },
  {
    primary_id: "D0R9CF",
    names: [
      "4-(4-Phenoxybutoxy)-7H-Furo[3,2-G]chromen-7-One",
      "5-(4-Phenoxybutoxy)Psoralen",
      "870653-45-5",
      "UNII-A62C114Q0Q",
      "CHEMBL444449",
      "A62C114Q0Q",
      "7H-Furo[3,2-G][1]benzopyran-7-One, 4-(4-Phenoxybutoxy)-",
      "4-(4-Phenoxybutoxy)Furo[3,2-G]chromen-7-One",
      "7H-Furo(3,2-G)(1)Benzopyran-7-One, 4-(4-Phenoxybutoxy)-",
      "C21H18O5",
      "7h-Furo[3",
      "4-(4-Phenoxybutoxy)-",
      "2-G][1]benzopyran-7-One",
      "5-(4-Phenoxybutoxy) Psoralen",
      "GTPL2554",
      "SCHEMBL2585454",
      "CTK8F0318",
      "DTXSID80461776",
    ],
  },
  {
    primary_id: "D0R9CH",
    names: [
      "ZK-810388",
      "3-Chloro-N-{4-Chloro-2-[(5-Chloropyridin-2-Yl)Carbamoyl]-6-Methoxyphenyl}-4-{[4,5-Dihydro-1,3-Oxazol-2-Yl(Methyl)Amino]methyl}thiophene-2-Carboxamide",
      "3-Chloro-N-[4-Chloro-2-[(5-Chloropyridin-2-Yl)Carbamoyl]-6-Methoxyphenyl]-4-[[4,5-Dihydro-1,3-Oxazol-2-Yl(Methyl)Amino]methyl]thiophene-2-Carboxamide",
      "AC1MOUJH",
      "1mq6",
      "Thiophene-Anthranilamide, 13a",
      "CHEMBL173994",
      "BDBM17111",
      "ZK810388",
      "ZK 810388",
      "N-[2-(5-Chloro-2-Pyridinylcarbamoyl)-4-Chloro-6-Methoxyphenyl]-3-Chloro-4-(Methyl 2-Oxazoline-2-Ylaminomethyl)Thioph",
    ],
  },
  {
    primary_id: "D0R9DP",
    names: ["CEM-102"],
  },
  {
    primary_id: "D0R9DX",
    names: ["GsMTx4"],
  },
  {
    primary_id: "D0R9ED",
    names: ["SC-50676"],
  },
  {
    primary_id: "D0R9EQ",
    names: ["Ropinirole"],
  },
  {
    primary_id: "D0R9FM",
    names: [
      "SentoClone",
      "Autologous T Lymphocyte Therapy (Malignant Melanoma), SentoClone",
      "Autologous Th1 Cell Therapy (Malignant Melanoma), SentoClone",
    ],
  },
  {
    primary_id: "D0R9GF",
    names: ["GALGT2 Gene Therapy"],
  },
  {
    primary_id: "D0R9GW",
    names: ["C[-Arg-Gly-Asp-Acpca22-]", "CHEMBL539850"],
  },
  {
    primary_id: "D0R9GY",
    names: ["Marine Natural Products"],
  },
  {
    primary_id: "D0R9HE",
    names: ["BI 113823"],
  },
  {
    primary_id: "D0R9HF",
    names: ["Brentuximab Vedotin Companion Diagnostic"],
  },
  {
    primary_id: "D0R9HI",
    names: ["SB-742457"],
  },
  {
    primary_id: "D0R9HL",
    names: [
      "Vildagliptin/Metformin",
      "274901-16-5",
      "(S)-1-[2-((5S,7S)-3-Hydroxy-Adamantan-1-Ylamino)-Acetyl]-Pyrrolidine-2-Carbonitrile",
      "1044676-63-2",
      "Vildaglipitin",
      "(-)-(2S)-1-[[(3-Hydroxytricyclo[3.3.1.1[3,7]]dec-1-Yl)Amino]acetyl]pyrrolidine-2-Carbonitrile",
      "(S)-1-[N-(3-Hydroxy-1-Adamantyl)Glycyl]pyrrolidine-2-Carbonitrile",
      "CHEMBL319819",
      "SCHEMBL16155482",
      "SCHEMBL16153676",
      "SCHEMBL10031674",
      "CHEMBL3084811",
      "MolPort-020-006-001",
      "(S)-1-(2-(((1r,3R,5R,7S)-3-Hydroxyadamantan-1-Yl)Amino)Acetyl)Pyrrolidine-2-Carbonitrile",
      "BDBM123776",
    ],
  },
  {
    primary_id: "D0R9HP",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 26"],
  },
  {
    primary_id: "D0R9IH",
    names: [
      "SAR-339658",
      "CHR-1103",
      "GBR-500",
      "TMC-2003",
      "Integrin Antibody (Inflammation), Chromos",
      "Integrin Antibody (Inflammation), Targeted Molecules Corp",
      "Integrin Antibody (Multiple Sclerosis), Chromos",
      "Integrin Antibody (Multiple Sclerosis), Glenmark",
      "CD49b Antagonist (Inflammation/Multiple Sclerosis), Glenmark",
    ],
  },
  {
    primary_id: "D0R9IL",
    names: ["CK-2066260"],
  },
  {
    primary_id: "D0R9IU",
    names: ["PRT-062607"],
  },
  {
    primary_id: "D0R9JC",
    names: ["H5N1 Influenza Mab Therapy"],
  },
  {
    primary_id: "D0R9JG",
    names: ["[D-Dab(CO-NH-OH)3]degarelix", "CHEMBL425813"],
  },
  {
    primary_id: "D0R9KF",
    names: ["(E)-3-(2,3-Dichlorophenylimino)Indolin-2-One"],
  },
  {
    primary_id: "D0R9KG",
    names: ["Temanogrel"],
  },
  {
    primary_id: "D0R9KV",
    names: ["3-(2,5-Dimethyl-Phenyl)-1-Propyl-Piperidine", "CHEMBL280491", "BDBM50068420"],
  },
  {
    primary_id: "D0R9KX",
    names: ["(5S,6S)-[Octahydro-Quinolin-(2E)-Ylidene]amine", "CHEMBL364230"],
  },
  {
    primary_id: "D0R9LG",
    names: ["Small Molecule Program Targeting Tregs, Autoimmune Diseases"],
  },
  {
    primary_id: "D0R9LV",
    names: ["1,6-Bis-(N,N-Dimethyl-Dmt-Tic-NH)Hexane", "CHEMBL370399"],
  },
  {
    primary_id: "D0R9MN",
    names: ["Danoprevir", "850876-88-9", "Danoprevir (USAN/INN)"],
  },
  {
    primary_id: "D0R9NB",
    names: ["Lprivask", "Desirudin SC"],
  },
  {
    primary_id: "D0R9NQ",
    names: ["Bi-51013", "BMA-031"],
  },
  {
    primary_id: "D0R9OH",
    names: ["Sulfamethoxazole"],
  },
  {
    primary_id: "D0R9ON",
    names: ["MR-889"],
  },
  {
    primary_id: "D0R9PD",
    names: ["Hydrazide Derivative 1"],
  },
  {
    primary_id: "D0R9PL",
    names: ["RWJ-25730"],
  },
  {
    primary_id: "D0R9PS",
    names: ["Aviscumine"],
  },
  {
    primary_id: "D0R9QW",
    names: [
      "Activin-Like Kinase 5 Inhibitor",
      "Activin-Like Kinase 5 Inhibitor (Scar Formation)",
      "ALK-5 Inhibitor (Scar Formation), Graceway Pharmaceuticals",
      "ALK-5 Inhibitor (Scar Formation), Pfizer",
      "Activin-Like Kinase-5 Inhibitor (Scar Formation), Graceway Pharmaceuticals",
      "Activin-Like Kinase-5 Inhibitor (Scar Formation), Pfizer",
    ],
  },
  {
    primary_id: "D0R9RK",
    names: ["PMID27109571-Compound-23"],
  },
  {
    primary_id: "D0R9RP",
    names: ["PMID25666693-Compound-32"],
  },
  {
    primary_id: "D0R9SJ",
    names: ["ST-1646", "CHEMBL392303"],
  },
  {
    primary_id: "D0R9TX",
    names: [
      "3-(4-Methoxyphenyl)-6-Methyl-2H-Chromen-2-One",
      "CHEMBL572233",
      "SCHEMBL17857190",
      "BDBM50300894",
      "3-(4-Methoxyphenyl)-6-Methylcoumarin",
      "113769-77-0",
      "3-(4-Methoxyphenyl)-6-Methyl-3-Chromene-2-One",
    ],
  },
  {
    primary_id: "D0R9UO",
    names: ["PAN-622"],
  },
  {
    primary_id: "D0R9UQ",
    names: ["Long-Acting Factor VII Conjugate"],
  },
  {
    primary_id: "D0R9US",
    names: ["GSK2140944"],
  },
  {
    primary_id: "D0R9VC",
    names: ["GnRH"],
  },
  {
    primary_id: "D0R9VR",
    names: ["Galantamine"],
  },
  {
    primary_id: "D0R9VV",
    names: [
      "CHLOROCYCLINONE C",
      "CHEMBL254815",
      "BDBM50228359",
      "Methyl 2-Chloro-6,8-Dihydroxy-9-{1-[(Hydroxyacetyl)Oxy]ethyl}-1-Methoxy-3-Methyl-7,12-Dioxo-7,12-Dihydrotetraphene-10-Carboxylate",
    ],
  },
  {
    primary_id: "D0R9VX",
    names: ["DS-3078"],
  },
  {
    primary_id: "D0R9VY",
    names: [
      "6-(4-CHLOROPHENYL)-7-(2,4-DICHLOROPHENYL)-N-(1-HYDROXY-2-METHYLPROPAN-2-YL)-2,2-DIMETHYL-3,4-DIHYDRO-2H-PYRANO[2,3-B]PYRIDINE-4-CARBOXAMIDE (ENANTIOMERIC MIX)",
      "CHEMBL1082321",
      "SCHEMBL1404688",
      "BDBM50320179",
    ],
  },
  {
    primary_id: "D0R9WD",
    names: ["Egalet-004"],
  },
  {
    primary_id: "D0R9WI",
    names: ["F-16242", "Antidepressant (F-16242), Pierre Fabre"],
  },
  {
    primary_id: "D0R9WP",
    names: [
      "Demeclocycline",
      "Bioterciclin",
      "Clortetrin",
      "DMCT",
      "DMCTC",
      "Declomycin",
      "Deganol",
      "Demeclociclina",
      "Demeclocyclinum",
      "Demeclor",
      "Demethylchlorotetracycline",
      "Demethylchlortetracyclin",
      "Demethylchlortetracycline",
      "Demethylchlortetracyclinum",
      "Demetraclin",
      "Diuciclin",
      "Ledermycin",
      "Methylchlorotetracycline",
      "Mexocine",
      "Novotriclina",
      "Perciclina",
      "Sumaclina",
      "Demeclocycline Monohydrochloride",
      "Demethylchlortetracycline Base",
      "Ledermycin Hydrochloride",
      "RP 10192",
      "DMCT (Antibiotic)",
      "Declomycin (TN)",
      "Declostatin (TN)",
      "Demeclociclina [INN-Spanish]",
      "Demeclocycline (USP)",
      "Demeclocycline [USAN:BAN]",
      "Demeclocyclinum [INN-Latin]",
      "Demethylchlortetracycline (JAN)",
      "Ledermycin (TN)",
      "Tri-Demethylchlortetracycline",
      "[4S-(4alpha,4aalpha,5aalpha,6beta,12aalpha)]-7-Chloro-4-(Dimethylamino)1,4,4a,5,5a,6,11,12a-Octahydro-3,6,10,12,12a-Pentahydroxy-1,11-Dioxo-2-Naphthacenecarboxamide",
      "(2E)-2-[amino(Hydroxy)Methylidene]-7-Chloro-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(2E,4S,4aS,5aS,6R,12aR)-2-[amino(Hydroxy)Methylidene]-7-Chloro-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(2E,4S,4aS,5aS,6S,12aS)-2-[amino(Hydroxy)Methylidene]-7-Chloro-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(2Z,4S,4aS,5aS,6S,12aS)-2-[amino(Hydroxy)Methylidene]-7-Chloro-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(4S,4aS,5aS,6S,12aS)-7-Chloro-4-(Dimethylamino)-3,6,10,12,12a-Pentahydroxy-1,11-Dioxo-1,4,4a,5,5a,6,11,12a-Octahydrotetracene-2-Carboxamide",
      "2-[amino(Hydroxy)Methylidene]-7-Chloro-4-(Dimethylamino)-6,10,11,12a-Tetrahydroxy-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "6-Demethyl-7-Chlorotetracycline",
      "6-Demethyl-7-Chlortetracycline",
      "6-Demethylchlorotetracycline",
      "6-Demethylchlortetracycline",
      "6-Demetil-7-Clorotetraciclina",
      "6-Demetil-7-Clorotetraciclina [Italian]",
      "7-Chloro-4-(Dimethylamino)-1,4,4a,5,5a,6,11,12a-Octahydro-3,6,10,12,12a-Pentahydroxy-1,11-Dioxo-2-Naphthacenecarboxamide",
      "7-Chloro-4-Dimethylamino-3,6,10,12,12a-Pentahydroxy-1,11-Dioxo-1,4,4a,5,5a,6,11,12a-Octahydro-Naphthacene-2-Carboxylic Acid Amide",
      "7-Chloro-6-Demethyltetracycline",
    ],
  },
  {
    primary_id: "D0R9XD",
    names: ["H-Dmt-Tic-Lys(Z)-NH-CH2-Ph"],
  },
  {
    primary_id: "D0R9XX",
    names: ["TG-N"],
  },
  {
    primary_id: "D0R9YR",
    names: [
      "BETULINIC ACID",
      "Betulinic Acid",
      "472-15-1",
      "Mairin",
      "Betulic Acid",
      "NSC 113090",
      "CCRIS 6748",
      "3-Hydroxylup-20(29)-En-28-Oic Acid",
      "UNII-4G6A18707N",
      "3beta-Hydroxy-20(29)-Lupaene-28-Oic Acid",
      "EINECS 207-448-8",
      "NSC677578",
      "Als-357",
      "NSC 677578",
      "CHEMBL269277",
      "CHEBI:3087",
      "3beta-Hydroxy-Lup-20(29)-En-28-Oic Acid",
      "4G6A18707N",
      "Lup-20(29)-En-28-Oic Acid, 3-Hydroxy-, (3beta)-",
      "AK-72848",
      "Lupatic Acid",
      "SMR000445624",
    ],
  },
  {
    primary_id: "D0R9ZB",
    names: ["Propiomazine"],
  },
  {
    primary_id: "D0R9ZS",
    names: ["(+/-)-Threo-N-(2-Chlorobenzyl)Methylphenidate", "CHEMBL1253919"],
  },
  {
    primary_id: "D0R9ZY",
    names: [
      "3-Azageranylgeranyl Diphosphate",
      "CHEMBL71360",
      "2-[METHYL-(5-GERANYL-4-METHYL-PENT-3-ENYL)-AMINO]-ETHYL-DIPHOSPHATE",
      "MGM",
      "150942-60-2",
      "AC1L9KO8",
      "GTPL3199",
      "DTXSID70332249",
      "BDBM50113289",
      "3-Aza-Geranylgeranyl Diphosphoric Acid",
      "DB08180",
      "3-Aza-Geranylgeranyl Pyrophosphoric Acid",
      "Diphosphoric Acid, Mono[2-[methyl[(3E,7E)-4,8,12-Trimethyl-3,7,11-Tridecatrienyl]amino]ethyl] Ester (9CI)",
      "2-[methyl-(4,8,12-Trimethyl-Trideca-3,7,11-Trienyl)-Amino]-Ethyl Trihydrogendiphosphate(3-Aza-GGPP)",
    ],
  },
  {
    primary_id: "D0RA0D",
    names: ["PMID29671355-Compound-25"],
  },
  {
    primary_id: "D0RA0P",
    names: [
      "2-Amino-2-(Thiophen-2-Yl)Acetic Acid",
      "21124-40-3",
      "AMINO-THIOPHEN-2-YL-ACETIC ACID",
      "Amino(2-Thienyl)Acetic Acid",
      "DL-Alpha-Amino-2-Thiopheneacetic Acid",
      "2-(2-Thienyl)Glycine",
      "4052-59-9",
      "2-Amino-2-(2-Thienyl)Acetic Acid",
      "CHEMBL382570",
      "Amino(Thiophen-2-Yl)Acetic Acid",
      "2-Thiopheneacetic Acid, Alpha-Amino-, (-)-",
      "XLMSKXASROPJNG-UHFFFAOYSA-N",
      "(-)-2-Thienylglycine",
      "EINECS 223-758-6",
      "ACMC-20altb",
      "(-)-Alpha-Amino-2-Thiopheneacetic Acid",
      "Alpha-(2-Thienyl)Glycine",
      "DL-2-(2-Thienyl)Glycine",
      "AC1Q5S3H",
      "2-(Thiophen-2-Yl)Glycine",
      "AC1L3HV3",
    ],
  },
  {
    primary_id: "D0RA1R",
    names: ["CD19-Targeting CAR T Cells"],
  },
  {
    primary_id: "D0RA5E",
    names: ["MK-8835B"],
  },
  {
    primary_id: "D0RA5Q",
    names: [
      "Bestatin",
      "Bestatin (TN)",
      "CHEBI:3070",
      "UPCMLD-DP116",
      "CHEMBL476869",
      "SureCN25971",
      "Ubenimex (JP16/INN)",
      "Ubenimex (JP17/INN)",
      "SCHEMBL25971",
      "GTPL5151",
      "AC1L972Z",
      "UPCMLD-DP116:001",
      "DTXSID4048430",
      "CHEBI:140702",
      "ZINC1532730",
      "ZINC01532730",
      "BDBM50010142",
      "AKOS026750073",
      "NCGC00161660-01",
      "C00732",
      "D00087",
      "((2S,3R)-3-Amino-2-Hydroxy-4-Phenylbutanoyl)-D-Leucine",
      "(2R)-2-[[(2S,3R)-3-Amino-2-Hydroxy-4-Phenylbutanoyl]amino]-4-Methylpentanoic Acid",
      "(2R)-2-[[(2S,3R)-3-Amino-2-Hydroxy-4-Phenyl-Butanoyl]amino]-4-Methyl-Pentanoic Acid",
    ],
  },
  {
    primary_id: "D0RA5T",
    names: ["Pseudocolumbamine Trifluoroacetate"],
  },
  {
    primary_id: "D0RA6H",
    names: ["LUPEOL"],
  },
  {
    primary_id: "D0RA7I",
    names: ["2,3-Diamino-Benzo[b]thiophene Derivative 1"],
  },
  {
    primary_id: "D0RA7L",
    names: ["Anti-BCMA CART Cells"],
  },
  {
    primary_id: "D0RA8F",
    names: ["PMID2153213C1f"],
  },
  {
    primary_id: "D0RA9C",
    names: ["CCT301-38 (Targeting ROR2)"],
  },
  {
    primary_id: "D0RA9E",
    names: [
      "Ramosetron",
      "132036-88-5",
      "UNII-7ZRO0SC54Y",
      "Ramosetron [INN]",
      "7ZRO0SC54Y",
      "CHEMBL1643895",
      "Ramosetron (INN)",
      "(1-Methylindol-3-Yl)-[(5R)-4,5,6,7-Tetrahydro-3H-Benzimidazol-5-Yl]methanone",
      "Nor-YM 060",
      "SCHEMBL16701",
      "GTPL2301",
      "DTXSID0043842",
      "NTHPAPBPFQJABD-LLVKDONJSA-N",
      "MolPort-019-991-383",
      "CHEBI:135156",
      "ZINC5116719",
      "AC1L3355",
      "BDBM50334454",
      "8235AH",
      "AKOS015896003",
      "SB19072",
      "DB09290",
      "SC-92398",
      "AJ-53160",
      "LS-187182",
      "TL8000762",
      "R-146",
      "FT-0651831",
      "D08466",
      "A806353",
      "(-)-(R)-1-Methylindol-3-Yl-4,5,6,7-Tetrahydro-5-Benzimidazolyl Ketone",
      "Nasea (TN)",
      "YM060",
    ],
  },
  {
    primary_id: "D0RB0D",
    names: [
      "7-Methoxy-2-Oxo-2H-Chromene-4-Carboxylic Acid",
      "CHEMBL12567",
      "2H-1-Benzopyran-4-Carboxylic Acid, 7-Methoxy-2-Oxo-",
      "BDBM50022188",
      "74802-00-9",
      "2-Oxo-7-Methoxy-2H-1-Benzopyran-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0RB1V",
    names: [
      "(-)-CATECHINGALLATE",
      "(-)-Catechin Gallate",
      "130405-40-2",
      "(2S,3R)-2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxychroman-3-Yl 3,4,5-Trihydroxybenzoate",
      "Catechin Gallate",
      "UNII-0KT1FO6VO6",
      "Ent-Catechin 3-O-Gallate",
      "0KT1FO6VO6",
      "CHEMBL129451",
      "CHEBI:76131",
      "(2S,3R)-2-(3,4-Dihydroxyphenyl)-5,7-Dihydroxy-3,4-Dihydro-2H-Chromen-3-Yl 3,4,5-Trihydroxybenzoate",
      "(2S,3R)-2-(3,4-Dihydroxyphenyl)-3,4-Dihydro-1(2H)-Benzopyran-3,5,7-Triol 3-(3,4,5-Trihydroxybenzoate)",
      "Catechin Gallate, (-)-",
      "CCRIS 9303",
      "Catechin GallateCG",
    ],
  },
  {
    primary_id: "D0RB2H",
    names: ["PBI-1308"],
  },
  {
    primary_id: "D0RB5Z",
    names: ["[Ncy(2-Naphthyl)1]acyline", "CHEMBL414344"],
  },
  {
    primary_id: "D0RB6K",
    names: ["Tetra-Hydro-Carboline Derivative 1"],
  },
  {
    primary_id: "D0RC0G",
    names: ["PMID27019002-Compound-43a"],
  },
  {
    primary_id: "D0RC0H",
    names: ["NPY Y5 Antagonists"],
  },
  {
    primary_id: "D0RC0J",
    names: ["ABT-436"],
  },
  {
    primary_id: "D0RC0P",
    names: ["EBV-Targeted Autologous T-Cell Therapy", "EBV-Targeted Autologous T-Cell Therapy (Lymphoma)"],
  },
  {
    primary_id: "D0RC1R",
    names: ["Dendritic Cell-Based Exosome Vaccine"],
  },
  {
    primary_id: "D0RC2V",
    names: ["Albuvirtide"],
  },
  {
    primary_id: "D0RC3L",
    names: ["HL-036"],
  },
  {
    primary_id: "D0RC4F",
    names: [
      "BUFROLIN",
      "Bufrolin",
      "54867-56-0",
      "UNII-46C1PX266N",
      "ICI-74917",
      "CHEMBL150764",
      "46C1PX266N",
      "Bufrolinum",
      "Bufroline",
      "Bufrolino",
      "Bufrolin [INN:BAN]",
      "Bufrolinum [INN-Latin]",
      "Bufroline [INN-French]",
      "Bufrolino [INN-Spanish]",
      "AC1Q5RDE",
      "AC1L2HFD",
      "SCHEMBL2109492",
      "CTK1H2392",
      "DTXSID60203353",
      "ZINC4215124",
      "6-Butyl-4,10-Dihydroxy-1,7-Phenanthroline-2,8-Dicarboxylic Acid",
      "BDBM50020876",
      "6-Butyl-1,4,7,10-Tetrahydro-4,10-Dioxo-1,7-Phenanthroline-2,8-Dicarbonsaeure",
      "6-Butyl-4,10-Dioxo-1,7-Dihydro-1,7-Phenanthroline-2,8-Dicarboxylic Acid",
    ],
  },
  {
    primary_id: "D0RC4W",
    names: ["Bicyclic Pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0RC5I",
    names: ["Azetidine Derivative 4"],
  },
  {
    primary_id: "D0RC5J",
    names: ["Disulfide Compound 2"],
  },
  {
    primary_id: "D0RC5X",
    names: [
      "(S)-2-Amino-2-P-Tolylacetic Acid",
      "119615-71-3",
      "CHEMBL378582",
      "(S)-2-Amino-2-(P-Tolyl)Acetic Acid",
      "(2S)-2-Amino-2-(4-Methylphenyl)Acetic Acid",
      "AC1ODTRS",
      "4-Methyl-L-Phenylglycine",
      "(S)-Amino-P-Tolyl-Acetic Acid",
      "SCHEMBL11728987",
      "DTXSID20427275",
      "ZINC2567159",
      "BENZENEACETIC ACID, ALPHA-AMINO-4-METHYL-, (ALPHAS)- (9CI)",
      "BDBM50179719",
      "AKOS006275863",
      "(2S)-Amino(4-Methylphenyl)Acetic Acid",
      "AJ-41354",
      "KB-276738",
      "AB1006787",
      "Benzeneacetic Acid, A-Amino-4-Methyl-, (AS)-",
    ],
  },
  {
    primary_id: "D0RC7L",
    names: ["PMID25666693-Compound-139"],
  },
  {
    primary_id: "D0RC7R",
    names: ["Resorcinol Compound 9"],
  },
  {
    primary_id: "D0RC8H",
    names: ["AdoC(Beta-Ala)2AlaArg6", "CHEMBL610875"],
  },
  {
    primary_id: "D0RC9T",
    names: [
      "2-Phenyl-3-Piperidin-3-Yl-1H-Indole",
      "CHEMBL42557",
      "2-Phenyl-3-(3-Piperidinyl)-1H-Indole",
      "SCHEMBL8525019",
      "BDBM50099262",
      "AKOS022450446",
      "3-(3-Piperidinyl)-2-Phenyl-1H-Indole",
      "244086-74-6",
      "3-(Piperidin-3-Yl)-2-Phenyl-1H-Indole",
      "DA-07759",
    ],
  },
  {
    primary_id: "D0RC9X",
    names: ["HDAC Inhibitors"],
  },
  {
    primary_id: "D0RD0D",
    names: ["BCMA CART"],
  },
  {
    primary_id: "D0RD0R",
    names: ["Ro 40-6055", "AM580", "CD-336", "Ro406055"],
  },
  {
    primary_id: "D0RD0Y",
    names: [
      "RetinoStat",
      "AngiStat",
      "XiaGen",
      "Cardiovascular Disease Therapy, Oxford Biomedica",
      "Gene Therapy (HRE), Oxford Biomedica",
      "Angiostatin/Endostatin Gene Therapy (LentiVector, AMD), Oxford Biomedica",
    ],
  },
  {
    primary_id: "D0RD1I",
    names: [
      "XOMA-3AB",
      "Botulism Therapy, XOMA",
      "Botulinum Type A Neurotoxin MAbs (Botulism), XOMA",
      "Anti-Type A Botulinum Neurotoxin Monoclonal Antibodies (Botulism), XOMA/NIAID",
    ],
  },
  {
    primary_id: "D0RD1Z",
    names: ["Exebryl-1"],
  },
  {
    primary_id: "D0RD3C",
    names: ["1-(1-Biphenyl-4-Yl-3-Methyl-Butyl)-1H-Imidazole", "CHEMBL497851"],
  },
  {
    primary_id: "D0RD5O",
    names: ["PMID25772215-Compound-US02014179750TG7-76"],
  },
  {
    primary_id: "D0RD5P",
    names: ["PMID28394193-Compound-36"],
  },
  {
    primary_id: "D0RD5S",
    names: ["5,7-Dimethoxy-3-Thiophen-3-Yl-Quinoline", "CHEMBL66071", "ZINC3834026"],
  },
  {
    primary_id: "D0RD5U",
    names: [
      "PT-114",
      "181305-49-7",
      "Thiourea, N-(5-Chloro-2-Pyridinyl)-N'-(2-(4-(Dimethylamino)-2,6-Difluorophenyl)Ethyl)-",
      "N-(2-(-2,6-Difluoro-4-(Dimethylamino)Phenethyl))-N'-(2-(5-Chloropyridyl))Thiourea",
      "1-(5-Chloropyridin-2-Yl)-3-[2-[4-(Dimethylamino)-2,6-Difluorophenyl]ethyl]thiourea",
      "N-[2-(-2,6-Difluoro-4-(Dimethylamino)Phenethyl)]-N'-[2-(5-Chloropyridyl)]thiourea",
      "1-(5-Chloropyridin-2-Yl)-3-{2-[4-(Dimethylamino)-2,6-Difluorophenyl]ethyl}thiourea",
      "Thiourea, N-(5-Chloro-2-Pyridinyl)-N'-[2-[4-(Dimethylamino)-2,6-Difluorophenyl]ethy",
    ],
  },
  {
    primary_id: "D0RD5W",
    names: ["Hyoscyamine"],
  },
  {
    primary_id: "D0RD6G",
    names: ["7-Ethynyl-6H-Chromeno[4,3-B]quinoline-3,9-Diol"],
  },
  {
    primary_id: "D0RD6X",
    names: [
      "CLDC",
      "CLDC (HCC)",
      "CLDC (HCC), Juvaris",
      "Cationic Lipid-DNA Complexes (Hepatocellular Carcinoma), Juvaris",
    ],
  },
  {
    primary_id: "D0RD7T",
    names: ["PMID28092474-Compound-33o"],
  },
  {
    primary_id: "D0RD7X",
    names: ["ISIS 119281"],
  },
  {
    primary_id: "D0RD9V",
    names: ["Quinolinyl Pyrazinyl Urea Derivative 2"],
  },
  {
    primary_id: "D0RE0F",
    names: ["Biphenyl Mannoside Derivative 13"],
  },
  {
    primary_id: "D0RE0K",
    names: ["PF-05161704"],
  },
  {
    primary_id: "D0RE1D",
    names: ["Terpyridineplatinum(II) Complexe 2"],
  },
  {
    primary_id: "D0RE3R",
    names: ["PMID25684022-Compound-US20120277229 44(1.4)"],
  },
  {
    primary_id: "D0RE4L",
    names: ["KX2-361"],
  },
  {
    primary_id: "D0RE6T",
    names: ["Telotristat Ethyl"],
  },
  {
    primary_id: "D0RE7T",
    names: [
      "4-[(Diphenylmethyl)Amino]-2-Phenylquinazoline",
      "CHEMBL305876",
      "Benzhydryl-(2-Phenyl-Quinazolin-4-Yl)-Amine",
      "SCHEMBL18388787",
      "BDBM50116317",
    ],
  },
  {
    primary_id: "D0RE8R",
    names: [
      "SF-101",
      "Ditalimfos",
      "Millie",
      "Plondrel",
      "Laptran",
      "Frutogard",
      "DITALIMPHOS",
      "Leucon",
      "O,O-Diethyl Phthalimidophosphonothioate",
      "Ortho 199",
      "5131-24-8",
      "Ditalimfos [ANSI]",
      "Dowco-199",
      "UNII-80DYL6I8WW",
      "O,O-Diethyl Phthalimidothiophosphate",
      "RE 199",
      "SF 101",
      "O,O-Diethyl Phthalimidophosphonothionate",
      "O 199",
      "EINECS 225-875-8",
      "O,O-Diaethyl-N-Phtalimido-Thiophosphat",
      "EPA Pesticide Chemical Code 106301",
      "O,O-Diethyl Phthalimido-Phosphonothioate",
      "BRN 1542822",
      "80DYL6I8WW",
      "Phosphonothioic Acid, Phthalimido-, O,O-Diethyl Ester",
      "Phosphonothioic Acid, (1,3-Dihydro-1,",
    ],
  },
  {
    primary_id: "D0RE9C",
    names: [
      "Lucerastat",
      "141206-42-0",
      "UNII-GVS3YDM418",
      "N-Butyldeoxygalactonojirimycin",
      "GVS3YDM418",
      "CHEMBL1086997",
      "N-(N-Butyl)Deoxygalactonojirimycin",
      "(2R,3S,4R,5S)-1-Butyl-2-(Hydroxymethyl)Piperidine-3,4,5-Triol",
      "Lucerastat [INN]",
      "N-Butyl-DGJ",
      "NB-DGJ",
      "N-Bu-DGJ",
      "AC1L9UXN",
      "N- Butyldeoxygalactonojirimycin",
      "SCHEMBL6821044",
      "N-Butyl-Deoxy-Galactonojirimycin",
      "CDP-923",
      "N-N-Butyl Deoxygalactonojirimycin",
      "OGT-923",
      "DTXSID60161601",
      "MolPort-039-015-418",
      "ZX-AT009021",
      "ZINC13719785",
      "BDBM50312528",
      "N-Butyl-D-Galacto-1-Deoxynojirimycin",
      "AKOS027384398",
      "NCGC00181326",
    ],
  },
  {
    primary_id: "D0RE9H",
    names: ["NR-LU-13"],
  },
  {
    primary_id: "D0RE9W",
    names: ["PMID27539678-Compound-7"],
  },
  {
    primary_id: "D0RE9X",
    names: [
      "5-Butyl-6-Chloropyrimidine-2,4(1H,3H)-Dione",
      "18493-80-6",
      "CHEMBL237996",
      "BDBM20056",
      "5-Substituted-6-Chlorouracil, 5c",
    ],
  },
  {
    primary_id: "D0RF1V",
    names: ["Triazole Derivative 4"],
  },
  {
    primary_id: "D0RF1Z",
    names: ["PMID29671355-Compound-69"],
  },
  {
    primary_id: "D0RF2M",
    names: ["NIPECOTIC ACID"],
  },
  {
    primary_id: "D0RF3F",
    names: ["MGCD-290"],
  },
  {
    primary_id: "D0RF4J",
    names: ["CLL1 CAR-T Cell"],
  },
  {
    primary_id: "D0RF4L",
    names: ["5-Methoxy-Luzindole", "5-Methoxyluzindole"],
  },
  {
    primary_id: "D0RF5E",
    names: ["PMID29865878-Compound-39"],
  },
  {
    primary_id: "D0RF6M",
    names: ["ML-18"],
  },
  {
    primary_id: "D0RF6P",
    names: ["I Kappa B Kinase Inhibitors"],
  },
  {
    primary_id: "D0RF7O",
    names: ["ML347"],
  },
  {
    primary_id: "D0RF9B",
    names: ["PYY(25-36)"],
  },
  {
    primary_id: "D0RF9T",
    names: ["Lexacalcitol"],
  },
  {
    primary_id: "D0RG0Z",
    names: ["BX-795", "BX795", "BX 795"],
  },
  {
    primary_id: "D0RG1F",
    names: ["PMID16242323C26b"],
  },
  {
    primary_id: "D0RG1T",
    names: ["PMID28766366-Compound-Scheme13INDY"],
  },
  {
    primary_id: "D0RG1Z",
    names: ["Biaryl Mannoside Derivative 22"],
  },
  {
    primary_id: "D0RG2P",
    names: ["SC-50560"],
  },
  {
    primary_id: "D0RG3O",
    names: [
      "Atropine",
      "Dl-Hyoscyamine",
      "Atropin",
      "Atropen",
      "Dl-Tropyltropate",
      "Atropinol",
      "Atropina",
      "Eyesules",
      "Troyl Tropate",
      "Isopto-Atropine",
      "Atropin [German]",
      "Atropina [Italian]",
      "Atropin-Flexiolen",
      "(+,-)-Tropyl Tropate",
      "51-55-8",
      "DL-Tropyl Tropate",
      "Tropine, Tropate (Ester)",
      "Tropic Acid, Ester With Tropine",
      "Atropine Sulfate",
      "CCRIS 3080",
      "Atropine (USP)",
      "Isopto Atropine",
      "Tropic Acid, 3-Alpha-Tropanyl Ester",
      "Atropt",
      "HSDB 2199",
      "DL-Tropanyl",
      "Anaspaz",
      "Atropair",
      "Atrosulf",
      "Belladenal",
      "Cytospaz",
      "Minims",
      "Tropanol",
      "Urised",
      "Atropine Care",
      "Atropine [BAN]",
      "Atropinium Cation",
      "Hyoscyamine Sulfate",
      "Minims Atropine",
      "OR22908",
      "Atropen (TN)",
      "Bellergal-S",
      "D1-Hyoscyamine",
      "I-Tropine",
      "NP-010662",
      "Neo-Diophen",
      "Ocu-Tropine",
      "Protamine & Atropine",
      "Atropinium(1+)",
      "Tropan-3alpha-Ol",
      "Tropan-3beta-Ol",
      "Atropine Sulfate SOP",
      "DL-Tropanyl 2-Hydroxy-1-Phenylpropionate",
      "Beta-Phenyl-Gamma-Oxypropionsaeure-Tropyl-Ester [German]",
      "Beta-Phenyl-Gamma-Oxypropionsaure-Tropyl-Ester [German]",
      "Tropan-3alpha-Yl 3-Hydroxy-2-Phenylpropanoate",
      "Endo-8-Methyl-8-Azabicyclo[321]octan-3-Ol",
      "B Eta-(Hydroxymethyl)Benzeneacetic Acid 8-Methyl-8-Azabicyclo[321]oct-3-Yl Ester",
      "[(1R,5S)-8-Methyl-8-Azabicyclo[321]octan-3-Yl] 3-Hydroxy-2-Phenylpropanoate",
      "[(1S,5R)-8-Methyl-8-Azabicyclo[321]oct-3-Yl] 3-Hydroxy-2-Phenyl-Propanoate",
      "Endo-(+/-)-Alpha-(Hydroxymethyl)Benzeneacetic Acid 8-Methyl-8-Azabicyclo[321]oct-3-Yl Ester",
      "Benzeneacetic Acid, Alpha-(Hydroxymethyl)-, (3-Endo)-8-Methyl-8-Azabicyclo(321)Oct-3-Yl Ester",
      "(3-Endo)-8-Methyl-8-Azabicyclo[321]oct-3-Yl (2S)-3-Hydroxy-2-Phenylpropanoate",
      "(3-Endo)-8-Methyl-8-Azabicyclo[321]oct-3-Yl 3-Hydroxy-2-Phenylpropanoate",
      "(3-Endo)-8-Methyl-8-Azabicyclo[321]oct-3-Yl Tropate",
      "(3-Endo)-8-Methyl-8-Azabicyclo[321]octan-3-Ol",
      "(3-Endo,8-Syn)-3-[(3-Hydroxy-2-Phenylpropanoyl)Oxy]-8-Methyl-8-Azoniabicyclo[321]octane",
      "(3-Exo)-8-Methyl-8-Azabicyclo[321]octan-3-Ol",
      "1-Alpha-H,5-Alpha-H-Tropan-3-Alpha-Ol (+-)-Tropate (Ester)",
      "1-Alpha-H,5-Alpha-H-Tropan-3-Alpha-Ol (+-)-Tropate (Ester) (8CI)",
      "1alphaH,5alphaH-Tropan-3alpha-Ol",
      "1alphaH,5alphaH-Tropan-3alpha-Ol (+-)-Tropate (Ester)",
      "1alphaH,5alphaH-Tropan-3beta-Ol",
      "2-Phenylhydracrylic Acid 3-Alpha-Tropanyl Ester",
      "8-Methyl-8-Azabicyclo[321]oct-3-Yl 3-Hydroxy-2-Phenylpropanoate",
      "8-Methyl-8-Azabicyclo[321]oct-3-Yl Tropate",
      "8-Methyl-8-Azabicyclo[321]octan-3-Yl 3-Hydroxy-2-Phenylpropionate",
    ],
  },
  {
    primary_id: "D0RG4J",
    names: ["Chlorobenzamide Derivative 1"],
  },
  {
    primary_id: "D0RG6P",
    names: ["AZD4901"],
  },
  {
    primary_id: "D0RG8O",
    names: ["Histaprodifen"],
  },
  {
    primary_id: "D0RG8Z",
    names: ["FPI-01"],
  },
  {
    primary_id: "D0RG9J",
    names: ["Pyrrolidine Derivative 2"],
  },
  {
    primary_id: "D0RH0A",
    names: ["[11C]pyrilamine", "[11C]-Pyrilamine", "[11C]-Mepyramine"],
  },
  {
    primary_id: "D0RH0J",
    names: ["LAM-111"],
  },
  {
    primary_id: "D0RH3O",
    names: ["PMID25726713-Compound-27"],
  },
  {
    primary_id: "D0RH3S",
    names: ["DC/I540/KLH Vaccine"],
  },
  {
    primary_id: "D0RH4S",
    names: ["1-Aminopentylphosphonic Acid Monophenyl Ester", "CHEMBL1090367"],
  },
  {
    primary_id: "D0RH6G",
    names: ["Heterocyclic Derivative 16"],
  },
  {
    primary_id: "D0RH7L",
    names: ["PMID27607364-Compound-162"],
  },
  {
    primary_id: "D0RH9K",
    names: ["Imidazo[1,5-A]pyridine Derivative 2"],
  },
  {
    primary_id: "D0RI3H",
    names: [
      "I-SAP",
      "(Z)-7-[(1R,2S,3S,5S)-3-[(4-Iodophenyl)Sulfonylamino]-7,7-Dimethyl-2-Bicyclo[3.1.1]heptanyl]hept-5-Enoic Acid",
    ],
  },
  {
    primary_id: "D0RI3W",
    names: ["1,2,4-Triazole [4,3-A]quinoxaline Derivative 2"],
  },
  {
    primary_id: "D0RI4I",
    names: ["BioE-743"],
  },
  {
    primary_id: "D0RI4S",
    names: ["2-Benzyl-2H-Pyrazolo[3,4-C]quinolin-4(5H)-One", "CHEMBL242205"],
  },
  {
    primary_id: "D0RI6L",
    names: [
      "M-3000",
      "2,4-Dichloro-6-Methylpyridine",
      "42779-56-6",
      "2,4-Dichloro-6-Picoline",
      "2,4-Dichloro-6-Methyl-Pyridine",
      "MFCD08669834",
      "2,4-Dichloro-6-Methylpyridine, 97%",
      "PubChem5465",
      "AN-584/42118738",
      "SCHEMBL380662",
      "2-Methyl-4,6-Dichloropyridine",
      "CTK6G6378",
      "DTXSID50474777",
      "WUGTXQVNSRFDNV-UHFFFAOYSA-N",
      "MolPort-003-984-236",
      "Pyridine,2,4-Dichloro-6-Methyl-",
      "STL558539",
      "BBL104289",
      "ANW-51977",
      "SBB087555",
      "ZINC16697709",
      "AKOS002682472",
      "AB46092",
      "RTC-068681",
      "RP02202",
      "QC-9072",
      "MCULE-9620518075",
      "FD-0748",
      "CS-W003538",
      "AN-2711",
      "TRA0085830",
      "SY015448",
      "SC-04449",
      "KB-17413",
    ],
  },
  {
    primary_id: "D0RI6O",
    names: ["Doxorubicin-Formaldehyde Conjugate"],
  },
  {
    primary_id: "D0RI6Q",
    names: ["Monalizumab"],
  },
  {
    primary_id: "D0RI6U",
    names: ["3-Nitro-N-(1,3-Diphenyl-1H-Pyrazol-5-Yl)Benzamide", "CHEMBL381264"],
  },
  {
    primary_id: "D0RI7N",
    names: ["Mesenchymal Bone Marrow-Derived Stem Cell Therapy"],
  },
  {
    primary_id: "D0RI7T",
    names: ["1-(1-(Biphenyl-4-Yl)Allyl)-1H-Imidazole", "CHEMBL443865"],
  },
  {
    primary_id: "D0RI8E",
    names: ["GD2-Targeted CAR-T Cells"],
  },
  {
    primary_id: "D0RI8G",
    names: ["JWH-312"],
  },
  {
    primary_id: "D0RI9A",
    names: ["LFX453"],
  },
  {
    primary_id: "D0RJ0J",
    names: ["M-5010"],
  },
  {
    primary_id: "D0RJ0V",
    names: ["GFT14"],
  },
  {
    primary_id: "D0RJ1U",
    names: ["PMID27744724-Compound-6"],
  },
  {
    primary_id: "D0RJ1W",
    names: ["OX-914"],
  },
  {
    primary_id: "D0RJ2P",
    names: ["BB-2983"],
  },
  {
    primary_id: "D0RJ2V",
    names: ["LMP1-CAR-T Cells"],
  },
  {
    primary_id: "D0RJ3W",
    names: ["PMID27774822-Compound-Figure11Example5"],
  },
  {
    primary_id: "D0RJ5B",
    names: [
      "4-(3-Bromophenylthio)-2-(Pyridin-2-Yl)Pyrimidine",
      "CHEMBL208163",
      "SCHEMBL6504580",
      "4-(3-Bromo-Phenylsulfanyl)-2-Pyridin-2-Yl-Pyrimidine",
      "BDBM50182817",
    ],
  },
  {
    primary_id: "D0RJ6R",
    names: ["Du-123015", "Benzopyran Piperazine Derivative (5HT Modulator, Neurological Disorders), Solvay"],
  },
  {
    primary_id: "D0RJ6S",
    names: [
      "3-(6-Bromo-Naphthalen-2-Yl)-Pyridine",
      "3-(6-Bromonaphthalen-2-Yl)Pyridine",
      "BDBM8907",
      "SCHEMBL4499954",
      "CHEMBL193534",
      "3-(6-Bromo-2-Naphthyl)Pyridine",
      "Pyridine-Substituted Naphthalene 4",
    ],
  },
  {
    primary_id: "D0RJ8J",
    names: ["PMID24793884C74"],
  },
  {
    primary_id: "D0RK1R",
    names: ["Azaindole Derivative 4"],
  },
  {
    primary_id: "D0RK2I",
    names: [
      "2-(Phosphonooxy)Butanoic Acid",
      "2-(PHOSPHONOOXY)BUTANOIC ACID",
      "AC1NRCQI",
      "2-Phosphonooxybutanoic Acid",
      "SCHEMBL4318695",
      "(2R)-2-(Phosphonooxy)Butanoic Acid",
    ],
  },
  {
    primary_id: "D0RK3P",
    names: ["Bis-Sulfonamide Derivative 2"],
  },
  {
    primary_id: "D0RK3S",
    names: ["Fused Heterocyclic Compound 10"],
  },
  {
    primary_id: "D0RK3U",
    names: ["PMID29865878-Compound-63"],
  },
  {
    primary_id: "D0RK4B",
    names: ["IONIS-MAPTRx"],
  },
  {
    primary_id: "D0RK4I",
    names: ["Empagliflozin + Linagliptin + Metformin"],
  },
  {
    primary_id: "D0RK4K",
    names: [
      "3-(3-Adamantan-1-Yl-Ureido)-Benzoic Acid",
      "CHEMBL478320",
      "SCHEMBL12932180",
      "BDBM50267173",
      "AKOS029868879",
      "3-(1-Adamantylcarbamoylamino)Benzoic Acid",
    ],
  },
  {
    primary_id: "D0RK4V",
    names: ["Aromatic Acetylene Derivative 1"],
  },
  {
    primary_id: "D0RK6W",
    names: ["Pyrazolo[4,3-C]pyridine Derivative 1"],
  },
  {
    primary_id: "D0RK7J",
    names: [
      "2,2,2-Trifluoro-N-(4-Sulfamoyl-Phenyl)-Acetamide",
      "2,2,2-Trifluoro-N-(4-Sulfamoylphenyl)Acetamide",
      "CHEMBL417648",
      "NSC36432",
      "AC1L5TZB",
      "AC1Q6VD0",
      "N-[4-(Aminosulfonyl)Phenyl]-2,2,2-Trifluoroacetamide",
      "Aromatic Sulfonamide Compound 9",
      "SCHEMBL16057183",
      "BDBM16644",
      "Acetamide, N-[4-(Aminosulfonyl)Phenyl]-2,2,2-Trifluoro-",
      "MolPort-001-023-586",
      "KYCGMLPXHVMDAN-UHFFFAOYSA-N",
      "ZINC993502",
      "HMS1580F07",
      "STK413007",
      "NSC-36432",
      "4-Trifluoroacetamido-Benzenesulfonamide",
      "AKOS001720753",
      "MCULE-7425586358",
      "SDCCGMLS-0064722.P001",
      "ST45139606",
      "EU-0050996",
      "AB00079123-01",
      "SR-01000408197",
      "AK-968/10112031",
    ],
  },
  {
    primary_id: "D0RK8E",
    names: [
      "NI-202",
      "Alpha-Synuclein Modulator (Parkinson!?s Disease)",
      "Monoclonal Antibody (PD), Neurimmune Therapeutics",
      "Alpha-Synuclein Modulator (Parkinson!?s Disease), Biogen Idec",
      "Alpha-Synuclein Modulator (Parkinson!?s Disease), Neurimmune Therapeutics",
    ],
  },
  {
    primary_id: "D0RK8U",
    names: ["JKB-122"],
  },
  {
    primary_id: "D0RL0F",
    names: [
      "3-(4-Bromophenyl)-1,1-Dimethylthiourea",
      "CHEMBL249923",
      "75105-07-6",
      "3-(P-Bromophenyl)-1,1-Dimethylthiourea",
      "AC1NT9ZG",
      "CTK2G1172",
      "DTXSID30418775",
      "YZPBPTSRPUXFGV-UHFFFAOYSA-N",
      "STL265123",
      "BDBM50223160",
      "AKOS023092916",
      "AKOS009470673",
      "MCULE-1938000461",
      "1-(4-Bromophenyl)-3,3-Dimethylthiourea",
      "N'-(4-Bromophenyl)-N,N-Dimethylthiourea #",
      "Thiourea, N'-(4-Bromophenyl)-N,N-Dimethyl-",
    ],
  },
  {
    primary_id: "D0RL3M",
    names: ["PMID29649907-Compound-35"],
  },
  {
    primary_id: "D0RL4M",
    names: ["Pranazepide"],
  },
  {
    primary_id: "D0RL4S",
    names: ["Cbz-Ile-HPhe-Ala-LeuVSMe", "CHEMBL446443"],
  },
  {
    primary_id: "D0RL4T",
    names: ["Gadolinium Based C60 Fullerene-Paclitaxel-ZME-018 Conjugates"],
  },
  {
    primary_id: "D0RL4X",
    names: ["AP-761"],
  },
  {
    primary_id: "D0RL5B",
    names: ["VLB-01"],
  },
  {
    primary_id: "D0RL8P",
    names: [
      "CHLOROCYCLINONE B",
      "CHEMBL254814",
      "BDBM50228360",
      "Methyl 9-[1-(Acetyloxyethyl)]-2-Chloro-6,8-Dihydroxy-1-Methoxy-3-Methyl-7,12-Dioxo-7,12-Dihydrotetraphene-10-Carboxylate",
    ],
  },
  {
    primary_id: "D0RL8X",
    names: ["CHO793076", "CHEMBL493342", "SCHEMBL1455615", "NPAAFPODGAKMTC-SANMLTNESA-N", "BDBM50257522"],
  },
  {
    primary_id: "D0RM0U",
    names: ["Boswellia Acid Derivative 1"],
  },
  {
    primary_id: "D0RM2O",
    names: ["S. Sonnei-REPA"],
  },
  {
    primary_id: "D0RM4V",
    names: ["PMID28454500-Compound-11"],
  },
  {
    primary_id: "D0RM5W",
    names: ["MX2/120"],
  },
  {
    primary_id: "D0RM5Z",
    names: ["Sofosbuvir + Velpatasvir + Voxilaprevir"],
  },
  {
    primary_id: "D0RM6A",
    names: [
      "RST-001",
      "ChR2 Gene Therapy (Retinopathy), RetroSense",
      "AAV-Based ChR2 Gene Therapy (Retinopathy), RetroSense",
      "Channelrhodopsin-2 Gene Therapy (Retinopathy), RetroSense",
    ],
  },
  {
    primary_id: "D0RM6C",
    names: ["PMID26004420-Compound-WO2012058134A"],
  },
  {
    primary_id: "D0RM7D",
    names: [
      "4-Iodopyrazole",
      "4-Iodo-1H-Pyrazole",
      "3469-69-0",
      "1H-Pyrazole, 4-Iodo-",
      "4-Iodo-Pyrazole",
      "Pyrazole, 4-Iodo",
      "LLNQWPTUJJYTTE-UHFFFAOYSA-N",
      "4-Iodopyrazole, 98%",
      "4-Iod-1h-Pyrazol",
      "4-Iodo Pyrazole",
      "SFtHCQVILimKP@",
      "Zlchem 55",
      "EINECS 222-434-1",
      "4-Iodo-1H-Pyrazol",
      "PubChem13295",
      "ACMC-1CSZW",
      "4-Iodopyrazole, 99%",
      "AC1Q4PAD",
      "AC1L2S6R",
      "AC1Q1GT3",
      "SCHEMBL71959",
      "AMTH001",
      "KSC225I2F",
      "Jsp006281",
      "LLNQWPTUJJYTTE-UHFFFAOYSA-",
      "CTK1C5422",
      "CHEBI:45260",
      "ZLB0042",
      "DTXSID30188244",
      "MolPort-000-156-497",
      "BM019",
      "KS-000004PJ",
      "ALBB-013400",
      "ACT01776",
      "ZINC2046962",
      "ACN-P000781",
    ],
  },
  {
    primary_id: "D0RM7J",
    names: [
      "Teijin-Lead_Cmp_5",
      "CHEMBL337246",
      "GTPL785",
      "SCHEMBL9912177",
      "BDBM50133112",
      "(R)-N-(2-(1-(2,4-Dimethylbenzyl)Pyrrolidin-3-Ylamino)-2-Oxoethyl)-3-(Trifluoromethyl)Benzamide",
      "N-{[(R)-1-(2,4-Dimethyl-Benzyl)-Pyrrolidin-3-Ylcarbamoyl]-Methyl}-3-Trifluoromethyl-Benzamide",
    ],
  },
  {
    primary_id: "D0RM9Q",
    names: ["Budipine"],
  },
  {
    primary_id: "D0RN2W",
    names: ["Potassium"],
  },
  {
    primary_id: "D0RN2X",
    names: ["PMID27841045-Compound-129"],
  },
  {
    primary_id: "D0RN3S",
    names: ["GS-4997"],
  },
  {
    primary_id: "D0RN5A",
    names: ["[3H]naloxone", "[N-Allyl-2,3-3H]naloxone"],
  },
  {
    primary_id: "D0RN6V",
    names: [
      "BRL-52656",
      "CHEMBL433508",
      "SCHEMBL7641140",
      "BRL-52656A",
      "BDBM50007164",
      "1-(2-Pyrrolidin-1-Ylmethyl-Piperidin-1-Yl)-2-(4-Trifluoromethyl-Phenyl)-Ethanone",
      "1-((S)-2-Pyrrolidin-1-Ylmethyl-Piperidin-1-Yl)-2-(4-Trifluoromethyl-Phenyl)-Ethanone",
      "(S)-1-(2-Pyrrolidin-1-Ylmethyl-Piperidin-1-Yl)-2-(4-Trifluoromethyl-Phenyl)-Ethanone(BRL 52656)",
    ],
  },
  {
    primary_id: "D0RN8T",
    names: ["PMID20299227C20"],
  },
  {
    primary_id: "D0RO0T",
    names: ["MKC-1313"],
  },
  {
    primary_id: "D0RO2K",
    names: ["CCNCSSKWCRDHARCC", "CHEMBL525208"],
  },
  {
    primary_id: "D0RO2L",
    names: ["CAR-T Cells Targeting PSMA"],
  },
  {
    primary_id: "D0RO5K",
    names: ["Parkinsons Disease Cell Therapy"],
  },
  {
    primary_id: "D0RO6K",
    names: ["PHOSPHOGLYCOLOHYDROXAMATE"],
  },
  {
    primary_id: "D0RO7A",
    names: ["PMID28454500-Compound-95"],
  },
  {
    primary_id: "D0RO7K",
    names: [
      "2-Methyl-9H-Carbazole",
      "2-METHYLCARBAZOLE",
      "9H-Carbazole, 2-Methyl-",
      "3652-91-3",
      "2-Methyldibenzopyrrole",
      "Carbazole, 2-Methyl-",
      "UNII-7MWG08F2MG",
      "7MWG08F2MG",
      "CHEMBL1173640",
      "PWJYOTPKLOICJK-UHFFFAOYSA-N",
      "2-Methyl Carbazole",
      "NSC10153",
      "EINECS 222-894-3",
      "NSC 10153",
      "2-Methyl-9H-Carbazol",
      "ACMC-1AHNQ",
      "AC1L2DUH",
      "2-Methyl-9H-Carbazole #",
      "SCHEMBL607346",
      "Carbazole, 2-Methyl- (8CI)",
      "DTXSID9073963",
      "CTK1C3104",
      "MolPort-006-115-551",
      "ZINC1706058",
      "9H-Carbazole, 2-Methyl- (9CI)",
      "FCH838910",
      "NSC-10153",
      "BDBM50322581",
      "AKOS006274371",
      "ACM3652913",
      "KB-25102",
      "OR340083",
    ],
  },
  {
    primary_id: "D0RO7R",
    names: ["BhCG Vaccine", "BhCG Vaccine (Cancer)"],
  },
  {
    primary_id: "D0RO8H",
    names: ["AC-262271"],
  },
  {
    primary_id: "D0RO8V",
    names: [
      "9-(3-IODOBENZYLAMINO)-1,2,3,4-TETRAHYDROACRIDINE",
      "I40",
      "N-[(3-Iodophenyl)Methyl]-1,2,3,4-Tetrahydroacridin-9-Amine",
      "1qon",
      "AC1L9LOA",
      "SCHEMBL3361969",
      "DB07940",
    ],
  },
  {
    primary_id: "D0RO9R",
    names: ["PMID21123062C27"],
  },
  {
    primary_id: "D0RO9T",
    names: ["BRD9539", "BRD-9539", "BRD 9539"],
  },
  {
    primary_id: "D0RP1J",
    names: ["PMID29334795-Compound-66"],
  },
  {
    primary_id: "D0RP2W",
    names: ["Mirvetuximab Soravtansine"],
  },
  {
    primary_id: "D0RP4U",
    names: ["PMID25684022-Compound-WO2006015124"],
  },
  {
    primary_id: "D0RP4W",
    names: ["Pyrido[1,2-A]indole-1.-Carboxylic Acid Analog 3"],
  },
  {
    primary_id: "D0RP5T",
    names: ["RG6206"],
  },
  {
    primary_id: "D0RP8F",
    names: ["PPACK", "AC1MI1QZ", "SCHEMBL871144", "LS-186049"],
  },
  {
    primary_id: "D0RP8X",
    names: [
      "UCL1972",
      "CHEMBL289855",
      "UCL-1972",
      "1-[5-(4-Nitrophenoxy)Pentyl]pyrrolidine",
      "1-(5-(4-Nitrophenoxy)Pentyl)Pyrrolidine",
      "SCHEMBL490968",
      "BDBM50089295",
      "1-[5-(4-Nitro-Phenoxy)-Pentyl]-Pyrrolidine",
      "L019446",
    ],
  },
  {
    primary_id: "D0RQ2T",
    names: ["Macrocyclic Tripeptide Motif"],
  },
  {
    primary_id: "D0RQ2W",
    names: ["Gadopentetate Dimeglumine"],
  },
  {
    primary_id: "D0RQ5Z",
    names: ["Shan 6"],
  },
  {
    primary_id: "D0RQ6F",
    names: ["Cergutuzumab Amunaleukin", "CEA-IL2v"],
  },
  {
    primary_id: "D0RQ8Q",
    names: ["PMID25991433-Compound-J2"],
  },
  {
    primary_id: "D0RQ8R",
    names: ["2-{[2-(2-Phenylethyl)Phenoxy]methyl}pyridine", "CHEMBL216198", "2-((2-Phenethylphenoxy)Methyl)Pyridine"],
  },
  {
    primary_id: "D0RR2D",
    names: ["Golden Phosphorous Acetyletic Compound 3"],
  },
  {
    primary_id: "D0RR2E",
    names: ["IDP-122"],
  },
  {
    primary_id: "D0RR4T",
    names: ["GI-270384X", "270384"],
  },
  {
    primary_id: "D0RR4X",
    names: ["H-D-Tic-C[Cys-Tyr-D-Trp-Arg-Thr-Pen]-Thr-NH2", "CHEMBL268162"],
  },
  {
    primary_id: "D0RR7X",
    names: ["GR94800"],
  },
  {
    primary_id: "D0RR8O",
    names: ["Pyrido[3,2-D]pyrimidine Derivative 2"],
  },
  {
    primary_id: "D0RR9O",
    names: ["EDP-24"],
  },
  {
    primary_id: "D0RS0Q",
    names: ["Ad-VirRx 007"],
  },
  {
    primary_id: "D0RS1Q",
    names: ["E-1413", "Desipramine Prodrug (Oral, ECLYPS), ProCor Pharmaceuticals"],
  },
  {
    primary_id: "D0RS2J",
    names: ["Benzamide Derivative 7"],
  },
  {
    primary_id: "D0RS3R",
    names: ["2-(1-(Aminomethyl)-3-Ethylcyclopentyl)Acetic Acid", "CHEMBL594478", "SCHEMBL5433016", "BDBM50304693"],
  },
  {
    primary_id: "D0RS4B",
    names: ["FGGFTGARKSARKVANQ"],
  },
  {
    primary_id: "D0RS4X",
    names: ["PEN-866"],
  },
  {
    primary_id: "D0RS5N",
    names: [
      "Peptide Antibiotics",
      "AQP-001",
      "Peptide Antibiotics (Gram Positive And Negative Infections)",
      "Peptide Antibiotics (Gram Positiveand Negative Infections), Aquapharm Biodiscovery",
    ],
  },
  {
    primary_id: "D0RS6O",
    names: ["PMID29757691-Compound-8a"],
  },
  {
    primary_id: "D0RT1R",
    names: ["Pyrimidine Derivative 30"],
  },
  {
    primary_id: "D0RT2C",
    names: ["AZD9291"],
  },
  {
    primary_id: "D0RT2E",
    names: ["RG-83852"],
  },
  {
    primary_id: "D0RT4U",
    names: ["Aryl Carbamate Analog 1"],
  },
  {
    primary_id: "D0RT5D",
    names: ["Tricyclic Indole Compound 10"],
  },
  {
    primary_id: "D0RT5T",
    names: ["ARANOSE", "Aranoza", "CRC-0510375", "3-(Alpha-L-Arabinopyranosyl)-1-Methyl-1-Nitrosourea"],
  },
  {
    primary_id: "D0RT6C",
    names: ["PMID27376512-Compound-Table1Example8"],
  },
  {
    primary_id: "D0RT8M",
    names: ["PMID25656651-Compound-28b"],
  },
  {
    primary_id: "D0RT9B",
    names: [
      "2-Aminomethyl-5-(P-Chlorophenyl)Tetrahydrofuran",
      "CHEMBL447825",
      "[5-(4-Chlorophenyl)Oxolan-2-Yl]methanamine",
      "SCHEMBL2689896",
    ],
  },
  {
    primary_id: "D0RU0O",
    names: ["IPI-145", "Duvelisib", "1201438-56-3", "INK-1197", "UNII-610V23S0JI;"],
  },
  {
    primary_id: "D0RU0W",
    names: ["Tetra-Hydro-Oxazolopyridine Derivative 4"],
  },
  {
    primary_id: "D0RU1X",
    names: [
      "8-Oxo-8-Phenyl-Octanoic Acid",
      "8-Oxo-8-Phenyloctanoic Acid",
      "7-Benzoylheptanoic Acid",
      "24314-23-6",
      "Benzeneoctanoic Acid, H-Oxo-",
      "7-BENZOYL HEPTANOIC ACID",
      "AC1L6TSB",
      "SCHEMBL3381106",
      "8-Keto-8-Phenyl-Caprylic Acid",
      "CHEMBL162423",
      "8-Oxo-8-Phenyloctanoic Acid #",
      "CTK4F3363",
      "DTXSID40305602",
      "UMCSRRHQLAVYRS-UHFFFAOYSA-N",
      "ZINC2168376",
      "7009f",
      "NSC171230",
      "AKOS016022495",
      "NSC-171230",
      "MCULE-7202530747",
      "ACM24314236",
      "ST50825837",
    ],
  },
  {
    primary_id: "D0RU3J",
    names: ["Fexapotide"],
  },
  {
    primary_id: "D0RU3M",
    names: ["SQ-34919"],
  },
  {
    primary_id: "D0RU5N",
    names: ["Beta-1-C-Butenyl-1-Deoxygalactonojirimycin", "CHEMBL1163562"],
  },
  {
    primary_id: "D0RU5R",
    names: ["NEV-801"],
  },
  {
    primary_id: "D0RU6U",
    names: [
      "Dixyrazine",
      "Esucos",
      "2470-73-7",
      "UCB 3412",
      "UNII-7H368W3AYC",
      "EINECS 219-591-3",
      "BRN 0768050",
      "7H368W3AYC",
      "10-(3-(4-Hydroxyethoxyethyl-1-Piperazinyl)-2-Methylpropyl)Phenothiazine",
      "(2-Methyl-3-(1-Hydroxyethoxyethyl-4-Piperazinyl)Propyl)-10-Phenothiazine",
      "2-(2-(4-(2-Methyl-3-Phenothiazin-10-Ylpropyl)-1-Piperazinyl)Ethoxy)Ethanol",
      "Ethanol,2-[2-[4-[2-Methyl-3-(10H-Phenothiazin-10-Yl)Propyl]-1-Piperazinyl]ethoxy]-",
      "Esocalm",
      "10-(2-Methyl-3-(1-Hydroxyethoxyethyl-4-Piperazinyl)Propyl)Phenothiazine",
      "Ethanol, 2-[2-[4-(2-Methyl-3-Phenoth",
    ],
  },
  {
    primary_id: "D0RU7T",
    names: [
      "G-619",
      "4-Methoxy-N3-(3-Picolyl)-1,3-Benzenedicarboxamide",
      "4-Methoxy-N3-(3-Pyridylmethyl)-1,3-Benzenedicarboxamide",
    ],
  },
  {
    primary_id: "D0RV0O",
    names: ["PMID29334795-Compound-23"],
  },
  {
    primary_id: "D0RV2E",
    names: ["Pyrazole Derivative 17"],
  },
  {
    primary_id: "D0RV2Z",
    names: ["PMID25991433-Compound-A2"],
  },
  {
    primary_id: "D0RV4P",
    names: ["Daratumumab"],
  },
  {
    primary_id: "D0RV6F",
    names: ["MK-0773", "C-8500", "MK-773"],
  },
  {
    primary_id: "D0RV6U",
    names: ["ADU-623"],
  },
  {
    primary_id: "D0RV7J",
    names: ["RG-7256", "B-Raf Inhibitor (Melanoma), Roche/Plexxikon"],
  },
  {
    primary_id: "D0RV7M",
    names: [
      "Deoxyuridine-5'-Diphosphate",
      "DUDP",
      "DEOXYURIDINE-5'-DIPHOSPHATE",
      "2'-Deoxyuridine 5'-Diphosphate",
      "4208-67-7",
      "Deoxyuridine-Diphosphate",
      "Deoxyuridine Diphosphate",
      "Uridine 5'-(Trihydrogen Diphosphate), 2'-Deoxy-",
      "CHEMBL9519",
      "CHEBI:28850",
      "2'-Deoxyuridine-5'-Diphosphate",
      "2'-Deoxyuridine 5'-(Trihydrogen Diphosphate)",
      "DUD",
      "1dud",
      "Deoxyuridine 5'-Diphosphate",
      "AC1L3RS9",
      "SCHEMBL156694",
      "Uridine, 2'-Deoxy-, 5'-(Trihydrogen Pyrophosphate)",
      "DTXSID90194922",
      "2''-Deoxyuridine-5''-Diphosphate",
      "ZINC8217150",
      "DEOXYURIDINE-5''-DIPHOSPHATE",
      "BDBM50179185",
      "DB03413",
    ],
  },
  {
    primary_id: "D0RV8L",
    names: ["PAV-667"],
  },
  {
    primary_id: "D0RV9D",
    names: ["PMID25726713-Compound-20"],
  },
  {
    primary_id: "D0RW1Y",
    names: [
      "9-N-Phenylmethylamino-Tacrine",
      "9-N-PHENYLMETHYLAMINO-TACRINE",
      "N-Benzyl-1,2,3,4-Tetrahydroacridin-9-Amine",
      "9-(3-Phenylmethylamino)-1,2,3,4-Tetrahydroacridine",
      "AC1L1CJU",
      "SCHEMBL3366446",
      "CHEMBL215344",
      "BDBM199182",
      "DB03672",
      "9-Benzylamino-1,2,3,4-Tetrahydroacridine",
      "N-Benzyl-1,2,3,4-Tetrahydroacridine-9-Amine",
      "N-Benzyl-1,2,3,4-Tetrahydroacridin-9-Amine (8a)",
      "9-Acridinamine, 1,2,3,4-Tetrahydro-N-(Phenylmethyl)-",
    ],
  },
  {
    primary_id: "D0RW4F",
    names: [
      "CereCRIB",
      "ACTID",
      "Drug Implant (Analgesic), CytoTherapeutics",
      "Bovine Cell Impant (Pain, ECT), CytoTherapeutics",
      "Bovine Cell Implant (Pain, CRIB), CytoTherapeutics",
      "Bovine Cell Impant (Pain, ECT), CytoTherapeutics, AstraZeneca",
      "Bovine Cell Implant (Pain, CRIB), CytoTherapeutics, AstraZeneca",
    ],
  },
  {
    primary_id: "D0RW5O",
    names: ["DPX-0907"],
  },
  {
    primary_id: "D0RW8J",
    names: ["Poly-Substituted Azoles Statin Lactone Derivative 1"],
  },
  {
    primary_id: "D0RW9P",
    names: ["HiviCides I And II"],
  },
  {
    primary_id: "D0RX0M",
    names: ["PMID25435285-Compound-39"],
  },
  {
    primary_id: "D0RX1E",
    names: ["ISIS 25116"],
  },
  {
    primary_id: "D0RX2U",
    names: ["PMID25522065-Compound-25"],
  },
  {
    primary_id: "D0RX3C",
    names: ["Piritramide"],
  },
  {
    primary_id: "D0RX6D",
    names: ["3-Acylidene-2-Oxoindole Derivative 1"],
  },
  {
    primary_id: "D0RX6F",
    names: ["CLR-131"],
  },
  {
    primary_id: "D0RX6I",
    names: ["4-((E)-1-Naphthalen-1-Yl-Propenyl)-1H-Imidazole", "CHEMBL102669", "ZINC13757203"],
  },
  {
    primary_id: "D0RX7Z",
    names: [
      "DEOXYCYTIDINE",
      "Cytosine Deoxyribonucleoside",
      "2'-DC",
      "Bmse000323",
      "ACMC-209rv6",
      "CYTIDINE, 2'-DEOXY-",
      "Cytosine Deoxy Nucleoside Hydrochloride",
      "4-Amino-1-[4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl]pyrimidin-2(1H)-One",
      "Desoxycytidine",
      "4-Amino-1-(2-Deoxypentofuranosyl)Pyrimidin-2(1H)-One",
      "4-Amino-1-[4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl]pyrimidin-2-One",
      "3h-Deoxycytidine",
      "4-Amino-1-(4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl)Pyrimidin-2(1H)-One",
      "40093-94-5",
      "AC1L19OG",
      "TimTec1_003892",
      "NCIOpen2_004589",
      "Oprea1_817993",
    ],
  },
  {
    primary_id: "D0RX8U",
    names: ["Indoline Derivative 10"],
  },
  {
    primary_id: "D0RX9W",
    names: [
      "N,N'-Dicyclohexyl-Urea",
      "1,3-Dicyclohexylurea",
      "N,n'-Dicyclohexylurea",
      "2387-23-7",
      "Dicyclohexylurea",
      "Urea, N,N'-Dicyclohexyl-",
      "UNII-ZV7823VVIM",
      "ZV7823VVIM",
      "CHEMBL1458",
      "N,N'-Dicyclohexylurea, 98%",
      "ADFXKUOMJKEIND-UHFFFAOYSA-N",
      "MFCD00003829",
      "Urea,3-Dicyclohexyl-",
      "Urea,N'-Dicyclohexyl-",
      "N,N Inverted Exclamation Marka-Dicyclohexylurea",
      "Urea, 1,3-Dicyclohexyl-",
      "Dicylohexylurea",
      "Dicylcohexylurea",
      "Dicylohexyl Urea",
      "Dicyclohexyl-Urea",
      "Dicyclohexyl Urea",
      "DCW",
      "EINECS 219-213-7",
      "NSC 30023",
      "NSC 17013",
      "N.n'-Dicyclohexylurea",
      "Rupatadine Impurity 1",
      "1,3-Dicyclohexyl-Urea",
    ],
  },
  {
    primary_id: "D0RY3N",
    names: ["MK-4280"],
  },
  {
    primary_id: "D0RY4M",
    names: ["Hyoscine"],
  },
  {
    primary_id: "D0RY4N",
    names: ["Human And Mouse PSMA DNA Vaccines"],
  },
  {
    primary_id: "D0RY6M",
    names: ["Heterocyclic-Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0RY9I",
    names: ["PMID29865878-Compound-62"],
  },
  {
    primary_id: "D0RY9R",
    names: ["PMID25514969-Compound-13"],
  },
  {
    primary_id: "D0RZ0N",
    names: ["FR-62765"],
  },
  {
    primary_id: "D0RZ1H",
    names: [
      "4,5,6-Trihydroxy-3-Methylphthalide",
      "CHEMBL486813",
      "AGUVVAYMPQDJDX-UHFFFAOYSA-",
      "BDBM50242174",
      "3-Methyl-4,5,6-Trihydroxy-Phthalide",
      "4,5,6-Trihydroxy-3-Methylisobenzofuran-1(3H)-One",
    ],
  },
  {
    primary_id: "D0RZ2M",
    names: [
      "2,6-Diphenyl-9H-Purine",
      "1H-Purine, 2,6-Diphenyl-",
      "CHEMBL381729",
      "889673-58-9",
      "CTK3A4137",
      "DTXSID90468791",
    ],
  },
  {
    primary_id: "D0RZ4M",
    names: ["Phytocannabinoid/Aminoalkylindole Derivative 1"],
  },
  {
    primary_id: "D0RZ5E",
    names: ["Biphenyl Mannoside Derivative 17"],
  },
  {
    primary_id: "D0RZ5O",
    names: ["Tetrazolyl Urea Derivative 1"],
  },
  {
    primary_id: "D0RZ5U",
    names: [
      "7-Allyl-1,3-Dimethyl-3,7-Dihydro-Purine-2,6-Dione",
      "7-Allyltheophylline",
      "Theophylline, 7-Allyl-",
      "61444-26-6",
      "UNII-NC789Z71XR",
      "BRN 0226015",
      "CHEMBL24289",
      "NC789Z71XR",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1,3-Dimethyl-7-(2-Propenyl)-",
      "7-Allyl-1,3-Dimethylxanthine",
      "7-Allyl-1,3-Dimethyl-3,7-Dihydro-1H-Purine-2,6-Dione",
      "1,3-Dimethyl-7-(Prop-2-En-1-Yl)-2,3,6,7-Tetrahydro-1H-Purine-2,6-Dione",
      "NSC521015",
      "AC1L2QUV",
      "ChemDiv2_003783",
      "1,3-Dimethyl-7-Prop-2-Enylpurine-2,6-Dione",
      "Cambridge Id 6249500",
      "AC1Q3ZA3",
      "SCHEMBL316279",
    ],
  },
  {
    primary_id: "D0RZ9C",
    names: ["PMID27109571-Compound-19"],
  },
  {
    primary_id: "D0RZ9V",
    names: [
      "IDH305",
      "DCGDPJCUIKLTDU-SUNYJGFJSA-N",
      "IDH-305",
      "1628805-46-8",
      "UNII-A791KH7YZW",
      "A791KH7YZW",
      "CHEMBL3947537",
      "SCHEMBL16045032",
      "BDBM247859",
      "CS-8084",
      "HY-104036",
      "US9434719, 466",
      "(R)-4-((S)-1-Fluoroethyl)-3-(2-(((S)-1-(4-Methyl-2'-(Trifluoromethyl)-[3,4'-Bipyridin]-6-Yl)Ethyl)Amino)Pyrimidin-4-Yl)Oxazolidin-2-One",
      "(4R)-4-[(1S)-1-Fluoroethyl]-3-[2-({(1S)-1-[4-Methyl-2'-(Trifluoromethyl)[3,4'-Bipyridin]-6-Yl]ethyl}amino)Pyrimidin-4-Yl]-1,3-Oxazolidin-2-One",
      "C81",
    ],
  },
  {
    primary_id: "D0S0AF",
    names: ["Bunazosin", "Andante", "E 015", "DE-070", "Detantol-R", "E-1015", "E-643"],
  },
  {
    primary_id: "D0S0AK",
    names: ["D-Trp8-SRIF-14"],
  },
  {
    primary_id: "D0S0AN",
    names: [
      "CP-320626",
      "UNII-FKX709RK3Q",
      "FKX709RK3Q",
      "CHEMBL99889",
      "5-CHLORO-1H-INDOLE-2-CARBOXYLIC ACID [1-(4-FLUOROBENZYL)-2-(4-HYDROXYPIPERIDIN-1YL)-2-OXOETHYL]AMIDE",
      "5-Chloro-N-[(2S)-3-(4-Fluorophenyl)-1-(4-Hydroxypiperidin-1-Yl)-1-Oxopropan-2-Yl]-1H-Indole-2-Carboxamide",
      "YDCGVASFVACWKF-NRFANRHFSA-N",
      "AC1L9GTI",
      "1H-Indole-2-Carboxamide, 5-Chloro-N-((1S)-1-((4-Fluorophenyl)Methyl)-2-(4-Hydroxy-1-Piperidinyl)-2-Oxoethyl)-",
      "186430-23-9",
      "SCHEMBL7234251",
      "BDBM35346",
      "DB03383",
      "1H-Indole-2-Carboxamide, 5-Chloro-N-(1-((4-Fluorophenyl)Methyl)-",
    ],
  },
  {
    primary_id: "D0S0AS",
    names: [
      "Ipratropium",
      "Aerodose",
      "Atrovent",
      "Atrovent Hfa",
      "Ipatropium Bromide",
      "Ipratropium Bromide",
      "X8M",
      "Apo-Ipravent",
      "Apovent (TN)",
      "Atrovent (TN)",
      "Kendral-Ipratropium",
      "[(5R)-8-Methyl-8-Propan-2-Yl-8-Azoniabicyclo[3.2.1]octan-3-Yl] 3-Hydroxy-2-Phenylpropanoate",
      "[(1R,5R)-8-Methyl-8-Propan-2-Yl-8-Azoniabicyclo[3.2.1]octan-3-Yl] 3-Hydroxy-2-Phenylpropanoate",
      "[(1R,5S)-8-Methyl-8-Propan-2-Yl-8-Azoniabicyclo[3.2.1]octan-3-Yl] 3-Hydroxy-2-Phenylpropanoate",
      "[(1R,5S)-8-Methyl-8-Propan-2-Yl-8-Azoniabicyclo[3.2.1]octan-3-Yl] (2R)-3-Hydroxy-2-Phenylpropanoate",
      "(3-Endo,8-Syn)-3-(3-Hydroxy-1-Oxo-2-Phenylpropoxy)-8-Methyl-8-(1-Methylethyl)-8-Azoniabicyclo(3.2.1)Octane",
      "(3-Endo,8-Syn)-3-[(3-Hydroxy-2-Phenylpropanoyl)Oxy]-8-Isopropyl-8-Methyl-8-Azoniabicyclo[3.2.1]octane",
      "(8-Methyl-8-Propan-2-Yl-8-Azoniabicyclo[3.2.1]octan-3-Yl) 3-Hydroxy-2-Phenylpropanoate",
      "3-[(3-Hydroxy-2-Phenylpropanoyl)Oxy]-8-Methyl-8-(Propan-2-Yl)-8-Azoniabicyclo[3.2.1]octane",
    ],
  },
  {
    primary_id: "D0S0BJ",
    names: ["IMCgp100"],
  },
  {
    primary_id: "D0S0BK",
    names: ["2-(2-Cyclohexyl-4-Methylphenoxy)Acetic Acid", "CHEMBL397651", "SCHEMBL4883828"],
  },
  {
    primary_id: "D0S0BN",
    names: ["PHLOROFUCOFUROECKOL A"],
  },
  {
    primary_id: "D0S0CW",
    names: ["AcALY-18"],
  },
  {
    primary_id: "D0S0DL",
    names: ["Benzene Sulfonamide Derivative 2"],
  },
  {
    primary_id: "D0S0DT",
    names: ["CAY10583", "Compound A [PMID:15866883]", "CAY 10583", "CAY-10583"],
  },
  {
    primary_id: "D0S0DU",
    names: ["KSB-303"],
  },
  {
    primary_id: "D0S0DY",
    names: ["OP-04"],
  },
  {
    primary_id: "D0S0EE",
    names: ["3-Alpha-Phenylmethoxy-3-Beta-Phenyl-Nortropane", "CHEMBL566837"],
  },
  {
    primary_id: "D0S0EK",
    names: ["CCDC 710249, HCl Salt", "CHEMBL471243", "CCDC 710249"],
  },
  {
    primary_id: "D0S0EM",
    names: ["PMID27841045-Compound-155"],
  },
  {
    primary_id: "D0S0EP",
    names: [
      "1-(4-Nitrobenzyl)-1H-Imidazole",
      "18994-90-6",
      "1-(4-Nitrobenzyl)Imidazole",
      "1-[(4-Nitrophenyl)Methyl]imidazole",
      "Imidazole, 1-(4-Nitrobenzyl)-",
      "CHEMBL13784",
      "1H-Imidazole, 1-[(4-Nitrophenyl)Methyl]-",
      "FLYGQJXMRPZYHQ-UHFFFAOYSA-N",
      "1-(4-Nitro-Benzyl)-1H-Imidazole",
      "[(4-Nitrophenyl)Methyl]imidazole",
      "AC1LBNLW",
      "AC1Q1ZC3",
      "ACMC-1C81T",
      "SCHEMBL78081",
      "CTK4E0266",
      "DTXSID10342908",
      "MolPort-001-494-965",
      "KS-000024IG",
      "ZINC1027711",
      "ALBB-024046",
      "CN-460",
      "ANW-23472",
      "STK149522",
      "SBB093104",
      "BDBM50188093",
      "1-(4-Nitrobenzyl)-1H-Imidazole #",
      "AKOS003266265",
      "VI10027",
    ],
  },
  {
    primary_id: "D0S0EQ",
    names: ["AZD-0865", "AR-H044277"],
  },
  {
    primary_id: "D0S0ES",
    names: ["Imidapril Hci"],
  },
  {
    primary_id: "D0S0FD",
    names: ["E2027"],
  },
  {
    primary_id: "D0S0FZ",
    names: [
      "Carisbamate",
      "Comfyde",
      "JNJ 10234094",
      "RWJ 333369",
      "YKP 509",
      "Carisbamate (USAN)",
      "JNJ-10234094",
      "RWJ-333369",
      "YKP-509",
      "S-2-O-Carbamoyl-1-O-Chlorophenyl-Ethanol",
      "[(2S)-2-(2-Chlorophenyl)-2-Hydroxyethyl] Carbamate",
    ],
  },
  {
    primary_id: "D0S0GM",
    names: ["ADX-10061", "CEE-310", "NNC-687", "NO-687", "CEE-03-310", "NNC-01-0687", "NNC-01-687"],
  },
  {
    primary_id: "D0S0GO",
    names: ["DLYE-5953A"],
  },
  {
    primary_id: "D0S0GT",
    names: [
      "3-(Phenylsulfonyl)-1H-Pyrrolo[2,3-B]pyridine",
      "CHEMBL538932",
      "633303-90-9",
      "SCHEMBL3889418",
      "CTK2A9458",
      "DTXSID00621754",
      "XIVJYIWQYBHEBJ-UHFFFAOYSA-N",
      "BDBM50295740",
      "3-(Benzenesulfonyl)-1H-Pyrrolo[2,3-B]pyridine",
      "1H-Pyrrolo[2,3-B]pyridine, 3-(Phenylsulfonyl)-",
    ],
  },
  {
    primary_id: "D0S0GV",
    names: ["Cyclo(-D-MeTyr-L-Arg-L-Arg-L-Nal-Gly-)", "CHEMBL219096"],
  },
  {
    primary_id: "D0S0HA",
    names: ["O6-Benzylguanine Alkylade"],
  },
  {
    primary_id: "D0S0HQ",
    names: ["Dihydropyrido Pyrazinone Compound 2"],
  },
  {
    primary_id: "D0S0HT",
    names: ["3-((1H-Imidazol-4-Yl)Methyl)Pyridine", "CHEMBL78838", "BDBM50326297"],
  },
  {
    primary_id: "D0S0II",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 3"],
  },
  {
    primary_id: "D0S0IW",
    names: ["PJ-3505"],
  },
  {
    primary_id: "D0S0JH",
    names: ["6-Amino-9-Methoxy-7H-Furo[3,2-G]chromen-7-One", "CHEMBL504356"],
  },
  {
    primary_id: "D0S0JL",
    names: ["Benzylcysteine"],
  },
  {
    primary_id: "D0S0KP",
    names: [
      "CVX-045",
      "CVX-22",
      "CovX-045",
      "PF-4856882",
      "TSP-1 Mimetic (Cancer), CovX",
      "Thrombospondin-1 Mimetic (Cancer), CovX",
      "Thrombospondin-1 Mimetic (Cancer), Pfizer",
    ],
  },
  {
    primary_id: "D0S0LD",
    names: ["DTwP-HBV Vaccine"],
  },
  {
    primary_id: "D0S0LZ",
    names: [
      "Doxycycline",
      "Atridox",
      "Azudoxat",
      "DOXY",
      "Deoxymykoin",
      "Dossiciclina",
      "Doxiciclina",
      "Doxitard",
      "Doxivetin",
      "Doxycen",
      "Doxychel",
      "Doxycin",
      "Doxycyclin",
      "Doxycyclinum",
      "Doxysol",
      "Doxytec",
      "Doxytetracycline",
      "Hydramycin",
      "Investin",
      "Jenacyclin",
      "Liviatin",
      "Monodox",
      "Oracea",
      "Ronaxan",
      "Spanor",
      "Supracyclin",
      "Vibramycin",
      "Vibramycine",
      "Vibravenos",
      "DOXCYCLINE ANHYDROUS",
      "DOXYCYCLINE CALCIUM",
      "DOXYCYCLINE MONOHYDRATE",
      "Dossiciclina [DCIT]",
      "Doxiciclina [Italian]",
      "Doxycycline Anhydrous",
      "Doxycycline Hyclate",
      "Vibramycin Novum",
      "Alpha-Doxycycline",
      "Alti-Doxycycline",
      "Apo-Doxy",
      "BMY-28689",
      "BU-3839T",
      "Doxiciclina [INN-Spanish]",
      "Doxy-Caps",
      "Doxy-Puren",
      "Doxy-Tabs",
      "Doxychel (TN)",
      "Doxycycline (INN)",
      "Doxycycline (TN)",
      "Doxycycline (Anhydrous)",
      "Doxycycline (Internal Use)",
      "Doxycycline-Chinoin",
      "Doxycyclinum [INN-Latin]",
      "Novo-Doxylin",
      "Nu-Doxycycline",
      "Periostat (TN)",
      "Vibra-Tabs",
      "Alpha-6-Deoxyoxytetracycline",
      "DMSC (*Fosfatex)",
      "Doxycycline (200mg/Day) Or Placebo",
      "Monodox (*monohydrate)",
      "Vibramycin (*monohydrate)",
      "Vivox (*Hyclate)",
      "GS-3065 (*monohydrate)",
      "Alpha-6-Deoxy-5-Hydroxytetracycline",
      "(2E,4S,4aR,5S,5aR,6R,12aS)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,10,11,12a-Tetrahydroxy-6-Methyl-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(2Z)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,10,11,12a-Tetrahydroxy-6-Methyl-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(2Z,4S,4aR,5S,5aR,6R)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,10,11,12a-Tetrahydroxy-6-Methyl-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(2Z,4S,4aR,5S,5aR,6R,12aS)-2-[amino(Hydroxy)Methylidene]-4-(Dimethylamino)-5,10,11,12a-Tetrahydroxy-6-Methyl-4a,5,5a,6-Tetrahydro-4H-Tetracene-1,3,12-Trione",
      "(4S,4aR,5S,5aR,6R,12aS)-4-(Dimethylamino)-3,5,10,12,12a-Pentahydroxy-6-Methyl-1,11-Dioxo-1,4,4a,5,5a,6,11,12a-Octahydrotetracene-2-Carboxamide",
      "2-Naphthacenecarboxamide, 4-(Dimethylamino)-1,4,4a,5,5a,6,11,12a-Octahydro-3,5,10,12,12a-Pentahydroxy-6-Methyl-1,11-Dioxo-, (4S,4aR,5S,5aR,6R,12aS)",
      "5-Hydroxy-Alpha-6-Deoxytetracycline",
      "6-Deoxyoxytetracycline",
      "6-Deoxytetracycline",
      "6-Alpha-Deoxy-5-Oxytetracycline",
      "6alpha-Deoxy-5-Oxytetracycline",
    ],
  },
  {
    primary_id: "D0S0MP",
    names: ["SGN-30"],
  },
  {
    primary_id: "D0S0MR",
    names: ["NVB-333"],
  },
  {
    primary_id: "D0S0NB",
    names: ["UCCF-853"],
  },
  {
    primary_id: "D0S0NK",
    names: ["Neoandrographolide"],
  },
  {
    primary_id: "D0S0NX",
    names: [
      "Peginterferon Beta",
      "AZ-01",
      "AZ-60",
      "Jak2 Inhibitors (Myeloproliferative Disorders)",
      "Jak2 Inhibitors (Myeloproliferative Disorders), AstraZeneca",
    ],
  },
  {
    primary_id: "D0S0OE",
    names: ["LTA4"],
  },
  {
    primary_id: "D0S0OF",
    names: ["Macrocyclic Peptide Analog 1"],
  },
  {
    primary_id: "D0S0OI",
    names: [
      "ATEVIRDINE",
      "Atevirdine",
      "136816-75-6",
      "Atevirdine Mesylate",
      "UNII-N24015WC6D",
      "CHEMBL280527",
      "N24015WC6D",
      "U-87201E",
      "1-[(5-Methoxyindol-2-Yl)Carbonyl]-4-[3-(Ethylamino)-2-Pyridyl]piperazine",
      "[4-[3-(Ethylamino)-2-Pyridyl]piperazin-1-Yl]-(5-Methoxy-1H-Indol-2-Yl)Methanone",
      "Atevirdine [INN]",
      "Piperazine, 1-(3-(Ethylamino)-2-Pyridinyl)-4-((5-Methoxy-1H-Indol-2-Yl)Carbonyl)-",
      "Piperazine, 1-[3-(Ethylamino)-2-Pyridinyl]-4-[(5-Methoxy-1H-Indol-2-Yl)Carbonyl]-",
      "AC1Q5KK9",
      "AC1L1U1S",
      "SCHEMBL356038",
      "Bis(Heteroaryl)Piperazine Analog",
      "BDBM1437",
    ],
  },
  {
    primary_id: "D0S0OV",
    names: ["1-(7-Methoxy-2-Phenyl-Chroman-4-Yl)-1H-Imidazole", "CHEMBL95109"],
  },
  {
    primary_id: "D0S0PA",
    names: [
      "C-(9H-Xanthen-9-Yl)-Methylamine",
      "100866-28-2",
      "XANTHENE-9-METHYLAMINE",
      "9H-Xanthene-9-Methanamine",
      "CHEMBL155276",
      "1-(9H-Xanthen-9-Yl)Methanamine",
      "9-Aminomethylxanthene",
      "ACMC-20m3ww",
      "9-AMINOMETHYL XANTHENE",
      "SCHEMBL4667689",
      "CTK0H2362",
      "DTXSID20576237",
      "VYDDHEPARBFMGU-UHFFFAOYSA-N",
      "ZINC21984468",
      "BDBM50097214",
      "AKOS015152152",
      "(9H-XANTHEN-9-YL)METHANAMINE",
      "AB24946",
    ],
  },
  {
    primary_id: "D0S0PB",
    names: [
      "2-Phenyl-1,2-Dihydro-Indazol-3-One",
      "3-Indazolinone, 2-Phenyl-",
      "2-Phenyl-1H-Indazol-3-One",
      "17049-65-9",
      "MLS-0315919.0001",
      "CHEMBL412902",
      "2-Phenylindazolone",
      "NSC34813",
      "2-Phenylindazolin-3-One",
      "3H-Indazol-3-One, 1,2-Dihydro-2-Phenyl-",
      "AC1L91HU",
      "Cid_411519",
      "SCHEMBL2438599",
      "CHEMBL1719803",
      "BDBM46671",
      "DTXSID20328536",
      "CFNJFZDGHGYAMU-UHFFFAOYSA-N",
      "NSC-34813",
      "ZINC84460115",
      "AKOS023543908",
      "3H-Indazol-3-One,2-Dihydro-2-Phenyl-",
      "1,2-Dihydro-2-Phenyl-3H-Indazol-3-One",
      "2-Phenyl-1,2-Dihydro-3H-Indazol-3-One #",
    ],
  },
  {
    primary_id: "D0S0PI",
    names: ["Nitrobenzylthioinosine"],
  },
  {
    primary_id: "D0S0PU",
    names: ["Cyclo(-D-Tyr-L-Arg-L-Arg-L-Nal-D-Ala-)", "CHEMBL375990"],
  },
  {
    primary_id: "D0S0QN",
    names: [
      "2,5-Bis(4-Hydroxybenzylidene)Cyclopentanone",
      "NSC666766",
      "CHEMBL452101",
      "AC1LVVMV",
      "SCHEMBL14473242",
      "MolPort-000-640-539",
      "ZINC5955757",
      "BDBM50264922",
      "AKOS001331259",
      "NSC-666766",
      "2,5-Bis[(E)-4-Hydroxybenzylidene]cyclopentanone",
      "(2E,5E)-2,5-Bis[(4-Hydroxyphenyl)Methylene]cyclopentanone",
    ],
  },
  {
    primary_id: "D0S0QY",
    names: ["TUG-770"],
  },
  {
    primary_id: "D0S0RE",
    names: ["HuMax-CD74"],
  },
  {
    primary_id: "D0S0RK",
    names: ["Flavone"],
  },
  {
    primary_id: "D0S0RS",
    names: ["MK-8245"],
  },
  {
    primary_id: "D0S0RX",
    names: ["ATGC-OP0359"],
  },
  {
    primary_id: "D0S0SE",
    names: ["Rupintrivir", "AG-7088", "AG-7185"],
  },
  {
    primary_id: "D0S0SH",
    names: [
      "12-(3-Adamantan-1-Yl-Ureido)-Dodeca Noic Acid",
      "AUDA",
      "479413-70-2",
      "CHEMBL215387",
      "Urea-Based Compound, 18",
      "12-[(Adamantan-1-Ylcarbamoyl)Amino]dodecanoic Acid",
      "MLS002415562",
      "SCHEMBL120588",
      "CTK8F0781",
      "BDBM25737",
      "DTXSID20435153",
      "HMS3650M09",
      "HMS2204E15",
      "HMS3332K06",
      "AUDA, &gt",
      "ZINC27645646",
      "MFCD12912267",
      "1631AH",
      "SMR001339077",
      "ACM479413702",
      "RT-011342",
      "12-(3-Adamantan-1-Yl-Ureido)-Dodecanoic Acid",
      "SR-01000860315",
      "SR-01000860315-2",
      "12-[[(Tricyclo[3.3.1.13,7]dec-1-Ylamino)Carbonyl]amino]-Dodecanoic Acid",
    ],
  },
  {
    primary_id: "D0S0ST",
    names: ["NPS-31807"],
  },
  {
    primary_id: "D0S0SZ",
    names: ["7-Ethynyl-2-(4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D0S0TR",
    names: ["NeurTriptin"],
  },
  {
    primary_id: "D0S0TY",
    names: ["Inositol 4-Phosphate", "1D-Myo-Inositol 4-Phosphate"],
  },
  {
    primary_id: "D0S0UL",
    names: ["Pyrazole Derivative 66"],
  },
  {
    primary_id: "D0S0VF",
    names: ["PMID28048944-Compound-20"],
  },
  {
    primary_id: "D0S0VR",
    names: ["PF-5230894"],
  },
  {
    primary_id: "D0S0VZ",
    names: ["IDRA-21"],
  },
  {
    primary_id: "D0S0WK",
    names: [
      "8-Oxo-8-Phenyl-Octanoic Acid Hydroxyamide",
      "CHEMBL95959",
      "SCHEMBL3383197",
      "N-Hydroxy-8-Oxo-8-Phenyloctanamide",
    ],
  },
  {
    primary_id: "D0S0WN",
    names: [
      "Biomed 101",
      "BioMed 101",
      "SC-41930",
      "SC 41930",
      "120072-59-5",
      "CHEMBL14823",
      "CGS 24115",
      "7-(3-(4-Acetyl-3-Methoxy-2-Propylphenoxy)Propoxy)-3,4-Dihydro-8-Propyl-2H-1-Benzopyran-2-Carboxylic Acid",
      "2H-1-Benzopyran-2-Carboxylic Acid, 7-(3-(4-Acetyl-3-Methoxy-2-Propylphenoxy)Propoxy)-3,4-Dihydro-8-Propyl-",
      "2H-1-Benzopyran-2-Carboxylicacid, 7-[3-(4-Acetyl-3-Methoxy-2-Propylphenoxy)Propoxy]-3,4-Dihydro-8-Propyl-",
      "7-(3-(4-Acetyl-3-Methoxy-2-Propylphenoxy)Propoxy)-8-Propylchroman-2-Carboxylic Acid",
      "SC-41390",
    ],
  },
  {
    primary_id: "D0S0WR",
    names: ["Luspatercept"],
  },
  {
    primary_id: "D0S0WU",
    names: ["4-(2,3-Dichlorobenzylthio)-2-Aminobutanoic Acid", "CHEMBL413572"],
  },
  {
    primary_id: "D0S0WX",
    names: [
      "9-(2-Aminopropyl)-9,10-Dihydroanthracene",
      "CHEMBL158780",
      "BDBM35926",
      "9,10-Dihydroanthracene(DHA), 3a",
      "9-(3-Aminopropyl)-9,10-Dihydroanthracene",
    ],
  },
  {
    primary_id: "D0S0XD",
    names: ["PMID22902653C35"],
  },
  {
    primary_id: "D0S0XK",
    names: ["S-21007", "153628-86-5"],
  },
  {
    primary_id: "D0S0XS",
    names: [
      "SR-271425",
      "BCN-326862",
      "SW-71425",
      "WIN-71425",
      "1-[2-(Diethylamino)Ethylamino]-4-(Formamidomethyl)-7-Methoxy-9H-Thioxanthen-9-One",
    ],
  },
  {
    primary_id: "D0S0XT",
    names: ["PMID25980951-Compound-26"],
  },
  {
    primary_id: "D0S0XV",
    names: ["ISIS 107775"],
  },
  {
    primary_id: "D0S0YQ",
    names: ["1-(2-Chlorophenyl)-3-(Pyridin-2-Yl)Thiourea"],
  },
  {
    primary_id: "D0S0YV",
    names: [
      "Modified HA14-1 Compounds (Cancer), GL Pharmaceuticals",
      "Bcl-2 Inhibitor (Cancer), GL Pharmaceuticals",
      "HA14-1, GL Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0S0ZJ",
    names: ["Selective BACE 1 Inhibitor"],
  },
  {
    primary_id: "D0S0ZQ",
    names: ["Vesimune"],
  },
  {
    primary_id: "D0S1AD",
    names: ["Tridolgosir"],
  },
  {
    primary_id: "D0S1AT",
    names: ["Oxazolo[3,4-A]pyrazine Derivative 1"],
  },
  {
    primary_id: "D0S1AY",
    names: [
      "5,8-Dimethoxy-4-Methylquinolin-2(1H)-One",
      "23947-41-3",
      "2(1h)-Quinolinone, 5,8-Dimethoxy-4-Methyl-",
      "5,8-Dimethoxy-4-Methyl-2-Quinolinol",
      "5,8-Dimethoxy-4-Methylquinolin-2-Ol",
      "ST056710",
      "5,8-Dimethoxy-4-Methylhydroquinolin-2-One",
      "2(1H)-Quinolinone,5,8-Dimethoxy-4-Methyl-",
      "MZX",
      "NSC109761",
      "Casimiroin Analogue, 1k",
      "AC1L6LUU",
      "AC1Q6I1R",
      "TimTec1_003888",
      "Oprea1_236890",
      "Oprea1_728115",
      "CHEMBL493174",
      "BDBM29222",
      "CTK4F2623",
      "DTXSID30296530",
      "MolPort-000-699-993",
      "MolPort-002-514-269",
      "BUHDAIGNGIXQJO-UHFFFAOYSA-N",
      "HMS1545A16",
      "ZINC8817671",
      "ALBB-032301",
    ],
  },
  {
    primary_id: "D0S1BF",
    names: ["AD02 Vaccine"],
  },
  {
    primary_id: "D0S1BN",
    names: ["Iptakalim Hydrochloride", "2,3-Dimethyl-N-Isopropyl-2-Butanamine Hydrochloride"],
  },
  {
    primary_id: "D0S1CQ",
    names: ["RUPATADINE"],
  },
  {
    primary_id: "D0S1CR",
    names: ["Anti-CD123-CAR-Transduced T Cells"],
  },
  {
    primary_id: "D0S1CS",
    names: [
      "3-Fluoro-5-(2-Methylquinolin-7-Yl)Benzonitrile",
      "CHEMBL232188",
      "GTPL6444",
      "SCHEMBL4303163",
      "BDBM50216746",
      "Compound 23 [PMID:",
      "Compound 23",
    ],
  },
  {
    primary_id: "D0S1DD",
    names: [
      "3-Chloro-N-(6-Methylpyridin-2-Yl)Benzamide",
      "CHEMBL377829",
      "AC1LHBCW",
      "Oprea1_422679",
      "ZINC361715",
      "BDBM50186323",
      "STK028098",
      "AKOS003227907",
      "MCULE-3037986633",
      "ST51016900",
    ],
  },
  {
    primary_id: "D0S1DI",
    names: ["Diaminopyridine Analog 4"],
  },
  {
    primary_id: "D0S1ER",
    names: ["PMID8246233C28"],
  },
  {
    primary_id: "D0S1FF",
    names: ["4-(Isopropylamino)-1-Naphthol", "CHEMBL578212", "SCHEMBL12599062"],
  },
  {
    primary_id: "D0S1FM",
    names: ["G3139 + Paclitaxel"],
  },
  {
    primary_id: "D0S1FV",
    names: ["GlyH-101", "CTK8E9894", "AGN-PC-005HH0"],
  },
  {
    primary_id: "D0S1GB",
    names: [
      "3-(3,4-Dimethyl-Phenyl)-Piperidine",
      "3-(3,4-Dimethylphenyl)Piperidine",
      "CHEMBL356746",
      "219703-95-4",
      "BDBM50068423",
      "AKOS013783555",
    ],
  },
  {
    primary_id: "D0S1GH",
    names: [
      "4-Morpholin-2-Yl-Benzene-1,2-Diol",
      "4-(2-Morpholinyl)Pyrocatechol",
      "1,2-Benzenediol, 4-(2-Morpholinyl)-",
      "BRN 0170116",
      "Pyrocatechol, 4-(2-Morpholinyl)-",
      "CHEMBL7681",
      "54826-84-5",
      "2-(3,4-Dioxyphenyl)Tetrahydro-1,4-Oxazin [German]",
      "AC1MIECG",
      "2-(3,4-Dioxyphenyl)Tetrahydro-1,4-Oxazin",
      "3,4-Dioxyphenyl-Morpholin",
      "SCHEMBL17632965",
      "CTK5A2632",
      "4-Morpholin-2-Ylbenzene-1,2-Diol",
      "BDBM50000502",
      "AKOS022644999",
      "UK-42620",
      "LS-136459",
    ],
  },
  {
    primary_id: "D0S1HE",
    names: ["Rhenium-186 Etidronate"],
  },
  {
    primary_id: "D0S1HI",
    names: ["Azoles"],
  },
  {
    primary_id: "D0S1HT",
    names: ["SUN-C8257"],
  },
  {
    primary_id: "D0S1HX",
    names: ["TAS-203"],
  },
  {
    primary_id: "D0S1II",
    names: ["1-(2-Nitrophenethyl)-1H-Pyrrolo[3,2-B]pyridine", "CHEMBL540744"],
  },
  {
    primary_id: "D0S1JV",
    names: ["AM-260", "AM-260 TASS"],
  },
  {
    primary_id: "D0S1KJ",
    names: ["PD-0173952"],
  },
  {
    primary_id: "D0S1KP",
    names: ["PT003"],
  },
  {
    primary_id: "D0S1KR",
    names: ["RVT-103+RVT-104"],
  },
  {
    primary_id: "D0S1LA",
    names: ["GFPTGG", "CHEMBL258999"],
  },
  {
    primary_id: "D0S1LE",
    names: ["Lintuzumab Bi-213", "Bismab-A (TN)"],
  },
  {
    primary_id: "D0S1MA",
    names: [
      "ANISOCOUMARIN H",
      "123237-86-5",
      "5'-Hydroxyauraptene",
      "MLS000863598",
      "MEGxp0_000088",
      "CHEMBL408980",
      "ACon1_000064",
      "MolPort-001-740-267",
      "HMS2270P13",
      "BDBM50375220",
      "MCULE-1296401261",
      "NCGC00168829-01",
      "SMR000440765",
      "BRD-A19484421-001-01-1",
    ],
  },
  {
    primary_id: "D0S1MC",
    names: [
      "AP-2243",
      "CHEMBL244416",
      "BDBM50218528",
      "3-{4-[(Benzylethylamino)Methyl]phenyl}-6,7-Dimethoxychromen-2-One",
    ],
  },
  {
    primary_id: "D0S1ME",
    names: ["AR-301"],
  },
  {
    primary_id: "D0S1MI",
    names: ["ACEROGENIN B", "Acerogenin B"],
  },
  {
    primary_id: "D0S1MO",
    names: ["ISIS-SGLT2rx"],
  },
  {
    primary_id: "D0S1NZ",
    names: ["Acipimox"],
  },
  {
    primary_id: "D0S1OE",
    names: [
      "Chlormezanone",
      "Alinam",
      "Banabin",
      "Bisina",
      "Chlomedinon",
      "Chlormethazanone",
      "Chlormethazone",
      "Chlormezanon",
      "Chlormezanona",
      "Chlormezanonum",
      "Clorilax",
      "Clormetazanone",
      "Clormetazon",
      "Clormezanona",
      "Clormezanone",
      "Dichloromethazanone",
      "Dichloromezanone",
      "Lobak",
      "Miorilax",
      "Muskel",
      "Myolespen",
      "Phenarol",
      "Rexan",
      "Rilansyl",
      "Rilaquil",
      "Rilasol",
      "Rilassol",
      "Rilax",
      "Rillasol",
      "Supotran",
      "Suprotan",
      "Tanafol",
      "Trancopal",
      "Trancote",
      "Tranrilax",
      "Transanate",
      "Clormezanone [DCIT]",
      "WIN 4692",
      "Banabil-Sintyal",
      "Banabin-Sintyal",
      "Banabin-Syntyal",
      "C-192",
      "Chlormezanonum [INN-Latin]",
      "Clormezanona [INN-Spanish]",
      "Dl-Chlormezanone",
      "Mio-Sed",
      "Muskel-Trancopal",
      "TRANCOPAL (TN)",
      "Trancopal (TN)",
      "Chlormezanone (JAN/INN)",
      "Chlormezanone [BAN:INN:JAN]",
      "Chlormezanone [INN:BAN:JAN]",
      "(+-)-Chlormezanone",
      "2-(4-Chlorophenyl)-3-Methyl-1,3-Thiazinan-4-One 1,1-Dioxide",
      "2-(4-Chlorophenyl)-3-Methyl-4-Metathiazanone-1,1-Dioxide",
      "2-(4-Chlorophenyl)Tetrahydro-3-Methyl-4H,1,3-Thiazin-4-One 1,1-Dioxide",
      "2-(4-Chlorophenyl)Tetrahydro-3-Methyl-4H-1,3-Thiazin-4-One 1,1-Dioxide",
      "2-(4-Chlorphenyl)-3-Methyl-4-Metathiazanon-1,1-Dioxid",
      "2-(4-Chlorophenyl)-3-Methyl-1,1-Dioxo-1,3-Thiazinan-4-One",
      "2-(P-Chlorophenyl)Tetrahydro-3-Methyl-4H-1,3-Thiazin-4-One 1,1-Dioxide",
      "2-(P-Chlorphenyl)-3-Methyl-1,3-Perhydrothiazin-4-On-1,1-Dioxide",
      "2-(Para-Chlorophenyl)Tetrahydro-3-Methyl-4H-1,3-Thiazin-4-One, 1,1-Dioxide",
      "4H-1,3-Thiazin-4-One, 2-(4-Chlorophenyl)Tetrahydro-3-Methyl-, 1,1-Dioxide",
      "4H-1,3-Thiazin-4-One, 2-(4-Chlorophenyl)Tetrahydro-3-Methyl-1,1-Dioxide",
      "4H-1,3-Thiazin-4-One, 2-(P-Chlorophenyl)Tetrahydro-3-Methyl-, 1,1,-Dioxide",
      "4H-1,3-Thiazin-4-One, 2-(P-Chlorophenyl)Tetrahydro-3-Methyl-, 1,1-Dioxide",
      "4H-1,3-Thiazin-4-One, 2-(P-Chlorophenyl)Tetrahydro-3-Methyl-1,1-Dioxide",
      "4H-1,3-Thiazin-4-One, Tetrahydro-2-(P-Chlorophenyl)-3-Methyl-, 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D0S1OV",
    names: ["PMID25991433-Compound-A7"],
  },
  {
    primary_id: "D0S1OY",
    names: [
      "2-Propyl-Pentanoic Acid 4-Sulfamoyl-Benzylamide",
      "CHEMBL432626",
      "4-(Valproylamide)-Benzyl Sulfonamide",
      "ZINC13471978",
      "BDBM50108562",
    ],
  },
  {
    primary_id: "D0S1PM",
    names: ["RBx-2258"],
  },
  {
    primary_id: "D0S1QN",
    names: ["Anti-CEA-CAR T"],
  },
  {
    primary_id: "D0S1QY",
    names: ["SHP620"],
  },
  {
    primary_id: "D0S1RT",
    names: ["UK-290795"],
  },
  {
    primary_id: "D0S1TI",
    names: ["RX-5902"],
  },
  {
    primary_id: "D0S1UW",
    names: ["Premarin/Trimegestone", "Conjugated Estrogen Tablets"],
  },
  {
    primary_id: "D0S1UZ",
    names: ["PD-360324"],
  },
  {
    primary_id: "D0S1VJ",
    names: [
      "8-(3,5-Dimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL105323",
      "AC1LAR96",
      "CTK6J9242",
      "BDBM50059940",
      "2,6-Diamino-8-(3',5'-Dimethoxyphenyl)Purine",
      "8-(3,5-Dimethoxyphenyl)-7H-Purine-2,6-Diamine",
      "8-(3,5-Dimethoxyphenyl)-3h-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D0S1VT",
    names: ["4(Z)-(5'-Deoxyadenosin-5'-Ylidene)Butanoic Acid", "CHEMBL525000"],
  },
  {
    primary_id: "D0S1VV",
    names: [
      "7-Methoxytacrine",
      "7-Meota",
      "7-Methoxy-1,2,3,4-Tetrahydroacridin-9-Amine",
      "TA-03",
      "5778-80-3",
      "BRN 0477401",
      "CHEMBL1256415",
      "1,2,3,4-Tetrahydro-7-Methoxy-9-Acridinamine",
      "9-Amino-7-Methoxy-1,2,3,4-Tetrahydroacridine",
      "9-Amino-7-Methoxy-1,2,3,4-Tetrahydroakridin [Czech]",
      "Acridine, 1,2,3,4-Tetrahydro-9-Amino-7-Methoxy-",
      "9-Amino-7-Methoxy-1,2,3,4-Tetrahydroakridin",
      "AC1L3O4H",
      "9-Acridinamine, 1,2,3,4-Tetrahydro-7-Methoxy-",
      "5-22-12-00199 (Beilstein Handbook Reference)",
      "SCHEMBL195752",
      "AC1Q582Q",
      "CTK8D9577",
      "MolPort-013-241-798",
      "ZINC6018808",
    ],
  },
  {
    primary_id: "D0S1WV",
    names: ["Enoblituzumab"],
  },
  {
    primary_id: "D0S1XC",
    names: ["5-(3-Butylpyrrolidin-3-Yl)-1H-Indole", "CHEMBL463585", "SCHEMBL983150"],
  },
  {
    primary_id: "D0S1XN",
    names: ["4-(4-Benzyl-Piperazin-1-Yl)-7-Bromo-1H-Indole", "CHEMBL90476"],
  },
  {
    primary_id: "D0S1XO",
    names: [
      "BIBX-79",
      "175033-26-8",
      "AC1L9UY2",
      "BIBX 79",
      "SCHEMBL8046646",
      "SCHEMBL3363189",
      "CHEMBL2377448",
      "CHEMBL3765649",
      "MolPort-028-951-512",
      "ZINC59696570",
      "ZINC238856506",
      "ZINC100502012",
      "AKOS034800865",
      "MCULE-9414485243",
      "EN300-155171",
      "Z1768160684",
      "4-Chloro-N-[4-[4-(Dimethylaminomethyl)Phenyl]cyclohexyl]-N-Methyl-Benzamide",
      "4-Chloro-N-[4-[4-(Dimethylaminomethyl)Phenyl]cyclohexyl]-N-Methylbenzamide",
      "4-Chloro-N-[4-(4-Dimethylaminomethyl-Phenyl)-Cyclohexyl]-N-Methyl-Benzamide",
    ],
  },
  {
    primary_id: "D0S1XX",
    names: ["BMS-986165"],
  },
  {
    primary_id: "D0S1YN",
    names: ["MYK-491"],
  },
  {
    primary_id: "D0S1YQ",
    names: ["Peptides"],
  },
  {
    primary_id: "D0S1YX",
    names: ["S-PRAnt"],
  },
  {
    primary_id: "D0S1ZB",
    names: ["Fotemustine"],
  },
  {
    primary_id: "D0S1ZY",
    names: ["RSLV-125"],
  },
  {
    primary_id: "D0S2AY",
    names: ["PMID27977313-Compound-6"],
  },
  {
    primary_id: "D0S2BB",
    names: [
      "B173",
      "BRL 54443 Maleate",
      "BRL 54443 Maleate Salt",
      "1197333-54-2",
      "EU-0100207",
      "3-(1-Methylpiperidin-4-Yl)-1H-Indol-5-Ol Maleate",
      "SCHEMBL4799792",
      "CHEMBL1256797",
      "MolPort-003-940-420",
      "BRL 54443 Maleate Salt, Solid",
      "HMS3260J15",
      "Tox21_500207",
      "BN0117",
      "LP00207",
      "CCG-221511",
      "NCGC00093680-01",
      "NCGC00260892-01",
      "B-173",
      "SR-01000075573-1",
      "J-004192",
      "3-(1-Methylpiperidin-4-Yl)-1H-Indol-5-Ol Maleate Salt",
    ],
  },
  {
    primary_id: "D0S2BL",
    names: ["CRTX-074"],
  },
  {
    primary_id: "D0S2BT",
    names: ["Potassium Aminosalicylate"],
  },
  {
    primary_id: "D0S2BU",
    names: [
      "BA-058",
      "BA-058 Microneedle Patch",
      "BA-058 (Transdermal Microneedle Patch, Osteoporosis)",
      "BA-058 (Transdermal Microneedle Patch, Osteoporosis), Radius Health",
    ],
  },
  {
    primary_id: "D0S2BV",
    names: [
      "Glitazone",
      "74772-78-4",
      "5-(4-Hydroxybenzyl)Thiazolidine-2,4-Dione",
      "5-(4-Hydroxybenzyl)-2,4-Thiazolidinedione",
      "5-(4-HYDROXYBENZYL)-1,3-THIAZOLIDINE-2,4-DIONE",
      "U-90441",
      "2,4-Dioxo-5-[(P-Hydroxyphenyl)-Methyl]thiazolidine",
      "5-[(4-Hydroxyphenyl)Methyl]-2,4-Thiazolidinedione",
      "2,4-Thiazolidinedione, 5-[(4-Hydroxyphenyl)Methyl]-",
      "5-(4-Hydroxybenzyl)Thiazolidin-2,4-Dione",
      "ACMC-1BF0J",
      "Pioglitazone Metabolite M1",
      "SCHEMBL623021",
      "SCHEMBL18174924",
      "CTK5E0483",
      "MolPort-006-394-329",
      "NKOHRVBBQISBSB-UHFFFAOYSA-N",
      "ZX-AT015682",
      "KM1640",
      "CH-087",
    ],
  },
  {
    primary_id: "D0S2BW",
    names: ["Methyl Estradiol-16-Beta-Carboxylate"],
  },
  {
    primary_id: "D0S2CQ",
    names: ["Anti-VLA-1 Mab"],
  },
  {
    primary_id: "D0S2DU",
    names: ["Eligen Insulin (Oral)"],
  },
  {
    primary_id: "D0S2DW",
    names: [
      "AP-2238",
      "UNII-545225E0BC",
      "CHEMBL75121",
      "545225E0BC",
      "3-(4-{[Benzyl(Methyl)Amino]methyl}-Phenyl)-6,7-Dimethoxy-2H-2-Chromenone",
      "AP2238",
      "553681-56-4",
      "BDBM10949",
      "2H-1-Benzopyran-2-One, 6,7-Dimethoxy-3-(4-((Methyl(Phenylmethyl)Amino)Methyl)Phenyl)-",
    ],
  },
  {
    primary_id: "D0S2ED",
    names: ["PMID25522065-Compound-35"],
  },
  {
    primary_id: "D0S2FW",
    names: ["Iodotubercidin"],
  },
  {
    primary_id: "D0S2GF",
    names: [
      "Chlorobenzylidene Malononitrile",
      "2-Chlorobenzylidene Malononitrile",
      "Alonitrile",
      "CS Gas (Lacrimator)",
    ],
  },
  {
    primary_id: "D0S2GT",
    names: ["CDP571"],
  },
  {
    primary_id: "D0S2GY",
    names: ["C[Glu19-Lys23][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0S2HO",
    names: ["Perillyl Alcohol"],
  },
  {
    primary_id: "D0S2HQ",
    names: [
      "3-Phenyl-CPP",
      "GTPL4104",
      "SCHEMBL20553475",
      "2-(4-Chlorophenoxy)-3-Phenylpropanoic Acid",
      "2-(P-Chlorophenoxy)-3-Phenylpropionic Acid",
    ],
  },
  {
    primary_id: "D0S2II",
    names: ["4-(Benzylideneamino)Benzenesulfonamide", "4-Benzylideneamino-Benzenesulfonamide"],
  },
  {
    primary_id: "D0S2IQ",
    names: ["Sapropterin Hydrochloride", "Biopten (TN)"],
  },
  {
    primary_id: "D0S2IS",
    names: [
      "3'-Bromopropiophenonethiosemicarbazone",
      "CHEMBL313679",
      "(1E)-1-(3-Bromophenyl)Propan-1-One Thiosemicarbazone",
      "SCHEMBL6338417",
      "BDBM50114613",
      "3'-Bromopropiophenone Thiosemicarbazone",
    ],
  },
  {
    primary_id: "D0S2JF",
    names: ["Pyrazole Derivative 83"],
  },
  {
    primary_id: "D0S2JG",
    names: ["LB30057"],
  },
  {
    primary_id: "D0S2JI",
    names: [
      "Clopamide",
      "Brinaldix",
      "636-54-4",
      "Clopamidum",
      "Aquex",
      "Chlosudimeprimyl",
      "Adurix",
      "Clopamidum [INN-Latin]",
      "Clopamida [INN-Spanish]",
      "UNII-17S83WON0I",
      "DT 327",
      "C14H20ClN3O3S",
      "Clopamide [USAN:INN:BAN]",
      "EINECS 211-261-7",
      "MLS000028574",
      "17S83WON0I",
      "Benzamide, 3-(Aminosulfonyl)-4-Chloro-N-(2,6-Dimethyl-1-Piperidinyl)-, Cis-",
      "4-Chloro-N-(2,6-Dimethylpiperidino)-3-Sulfamoylbenzamide",
      "SMR000058988",
      "Brinedine",
      "Clopamida",
      "4-Chloro-N-[(2R,6S)-2,6-Dimethylpiperidin-1-Yl]-3-Sulfamoylbenzamide",
      "DT-327",
      "NCGC00016527-01",
      "CAS-636-54-4",
      "Prestwick_756",
      "Aquex (",
    ],
  },
  {
    primary_id: "D0S2KM",
    names: ["Broad-Spectrum Antiviral", "Broad-Spectrum Antiviral (Rhinovirus Infection)"],
  },
  {
    primary_id: "D0S2KX",
    names: ["PLR-15"],
  },
  {
    primary_id: "D0S2LG",
    names: ["Smoothened Receptor Antagonist"],
  },
  {
    primary_id: "D0S2LO",
    names: ["TCS 2510"],
  },
  {
    primary_id: "D0S2LQ",
    names: ["PYY3-36"],
  },
  {
    primary_id: "D0S2LV",
    names: ["PMID25399762-Compound-Table1-C21"],
  },
  {
    primary_id: "D0S2ME",
    names: ["Pyrazoline Derivative 5"],
  },
  {
    primary_id: "D0S2MG",
    names: ["Ambrisentan + Tadalafil"],
  },
  {
    primary_id: "D0S2MO",
    names: ["FR194921"],
  },
  {
    primary_id: "D0S2MQ",
    names: ["SD-102"],
  },
  {
    primary_id: "D0S2NI",
    names: ["Rhpth"],
  },
  {
    primary_id: "D0S2NU",
    names: [
      "3,5-Dichlorobenzaldehyde Thiosemicarbazone",
      "CHEMBL421363",
      "NSC109597",
      "AC1NSHS0",
      "ZINC33848878",
      "BDBM50114607",
      "AKOS014545904",
      "NSC-109597",
    ],
  },
  {
    primary_id: "D0S2OA",
    names: ["1-Benzhydryl-4-(Benzyloxy)-4-Phenylpiperidine", "CHEMBL229018"],
  },
  {
    primary_id: "D0S2PD",
    names: ["BMS-823778"],
  },
  {
    primary_id: "D0S2PY",
    names: ["MK-866"],
  },
  {
    primary_id: "D0S2QP",
    names: [
      "COG-248",
      "Apolipoprotein E Agonist Series (Alzheimer's/Parkinsons Disease)",
      "Neuroprotectant (Alzheimers/Parkinsons Disease), Cognosci",
      "Apolipoprotein E Agonist Series (Alzheimer's/Parkinsons Disease), Cognosci",
    ],
  },
  {
    primary_id: "D0S2QZ",
    names: [
      "Cytidine-5'-Monophosphate",
      "Cytidine-Monophosphate",
      "CMP Dianion",
      "AC1OEJZP",
      "CMP",
      "5'-O-Phosphonatocytidine",
      "Cytidine 5'-Monophosphate(2-)",
      "Cytidine-5-Monophosphate Dianion",
      "CHEBI:60377",
      "CMP(2-)",
      "CJ-10966",
      "LS-190174",
      "A834351",
      "[(2R,3S,4R,5R)-5-(4-Amino-2-Oxo-1-Pyrimidinyl)-3,4-Dihydroxy-2-Oxolanyl]methyl Phosphate",
      "[(2R,3S,4R,5R)-5-(4-Amino-2-Oxopyrimidin-1-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Phosphate",
      "[(2R,3S,4R,5R)-5-(4-Azanyl-2-Oxidanylidene-Pyrimidin-1-Yl)-3,4-Bis(Oxidanyl)Oxolan-2-Yl]methyl Phosphate",
    ],
  },
  {
    primary_id: "D0S2RZ",
    names: ["XEL-007DO", "Cardiovascular Agent (Oral Controlled Release Tablet Formulation), Xel Pharmaceuticals"],
  },
  {
    primary_id: "D0S2SS",
    names: ["Quinazoline Derivative 4"],
  },
  {
    primary_id: "D0S2TI",
    names: ["YM90709"],
  },
  {
    primary_id: "D0S2TZ",
    names: [
      "CYNAROPICRIN",
      "Cynaropicrin",
      "Aguerin",
      "35730-78-0",
      "CHEMBL374146",
      "CHEBI:4038",
      "UNII-M9233789I9",
      "M9233789I9",
      "[(3aR,4S,6aR,8S,9aR,9bR)-8-Hydroxy-3,6,9-Trimethylidene-2-Oxo-3a,4,5,6a,7,8,9a,9b-Octahydroazuleno[4,5-B]furan-4-Yl] 2-(Hydroxymethyl)Prop-2-Enoate",
      "AC1Q69JD",
      "AC1L3O6W",
      "SCHEMBL1711811",
      "MEGxp0_001095",
      "ACon1_000045",
      "MolPort-001-741-258",
      "ZINC4098049",
      "HY-N2350",
      "BDBM50194430",
      "AKOS032971358",
      "MCULE-5051144608",
      "CS-8041",
      "NCGC00168845-01",
      "NCGC00168845-02",
      "2-Propenoic Acid, 2-(Hydroxymethyl)-, (3aR,4S,6aR,8S,9aR,9bR)-Dodecahydro-8-Hydroxy-3,6,9-Tris(M",
    ],
  },
  {
    primary_id: "D0S2UD",
    names: ["PMID28627961-Compound-30"],
  },
  {
    primary_id: "D0S2UG",
    names: ["Selegiline"],
  },
  {
    primary_id: "D0S2UR",
    names: ["PIRODOMAST"],
  },
  {
    primary_id: "D0S2UZ",
    names: [
      "PIPERNONALINE",
      "CHEMBL450332",
      "PKLGRWSJBLGIBF-JMQWPVDRSA-N",
      "MolPort-006-668-470",
      "ZINC14658236",
      "Pipernonaline, &gt",
      "9-(3,4-Methylenedioxyphenyl)-2,8-Nonadienoic Acid Piperidide",
      "1-[9-(1,3-Benzodioxol-5-Yl)-1-Oxo-2,8-Nonadienyl]piperidine, 9CI",
      "(2E,8E)-9-(Benzo[d][1,3]dioxol-5-Yl)-1-(Piperidin-1-Yl)Nona-2,8-Dien-1-One",
      "(2E,8E)-9-(2H-1,3-Benzodioxol-5-Yl)-1-(Piperidin-1-Yl)Nona-2,8-Dien-1-One",
    ],
  },
  {
    primary_id: "D0S2VD",
    names: ["Ibrutinib Companion Diagnostic"],
  },
  {
    primary_id: "D0S2WC",
    names: ["Nerisopam", "EGIS-6775"],
  },
  {
    primary_id: "D0S2XF",
    names: ["11-Dehydro-Thromboxane B2", "11-Dehydro-TXB2"],
  },
  {
    primary_id: "D0S2YX",
    names: ["Mofarotene"],
  },
  {
    primary_id: "D0S2ZJ",
    names: ["3-(6-Hydroxy-Benzooxazol-2-Yl)-Benzene-1,2-Diol"],
  },
  {
    primary_id: "D0S2ZR",
    names: ["JWH-147"],
  },
  {
    primary_id: "D0S3AE",
    names: ["Anti-MUC1 AR20.5 Mab"],
  },
  {
    primary_id: "D0S3AL",
    names: ["Piperidinyl Triazole Derivative 2"],
  },
  {
    primary_id: "D0S3AP",
    names: ["PMID25991433-Compound-A9"],
  },
  {
    primary_id: "D0S3AQ",
    names: ["CAT-2003"],
  },
  {
    primary_id: "D0S3AR",
    names: ["ISIS 12882"],
  },
  {
    primary_id: "D0S3BE",
    names: ["PMID27537201-Compound-Figure17"],
  },
  {
    primary_id: "D0S3BF",
    names: ["Pegadricase"],
  },
  {
    primary_id: "D0S3BK",
    names: ["GS010"],
  },
  {
    primary_id: "D0S3BO",
    names: ["KNI-10006"],
  },
  {
    primary_id: "D0S3BR",
    names: ["RG7598"],
  },
  {
    primary_id: "D0S3CX",
    names: ["Necuparanib"],
  },
  {
    primary_id: "D0S3DD",
    names: ["GSK-J1"],
  },
  {
    primary_id: "D0S3EF",
    names: ["CT-400P"],
  },
  {
    primary_id: "D0S3EK",
    names: ["EVT100"],
  },
  {
    primary_id: "D0S3EM",
    names: ["CAZ AVI", "CAZ104"],
  },
  {
    primary_id: "D0S3FW",
    names: [
      "N-Phenyl-5-Phenylimidazo[1,5-A]pyrazin-8-Amine",
      "CHEMBL426903",
      "849199-96-8",
      "Imidazo[1,5-A]pyrazin-8-Amine,n,5-Diphenyl-",
      "CTK3C9676",
      "DTXSID80658947",
      "BDBM50198371",
      "ZINC28642119",
      "KB-273858",
      "Imidazo[1,5-A]pyrazin-8-Amine, N,5-Diphenyl-",
    ],
  },
  {
    primary_id: "D0S3GE",
    names: ["[D-Tic7]OT", "CHEMBL435159"],
  },
  {
    primary_id: "D0S3HR",
    names: ["ARA-04"],
  },
  {
    primary_id: "D0S3IG",
    names: ["SCH-10363"],
  },
  {
    primary_id: "D0S3IX",
    names: [
      "Glycerol 3-Phosphate",
      "3-Glycerophosphate",
      "1957/3/4",
      "1-Glycerophosphate",
      "Glycerol 1-Phosphate",
      "1-Glycerophosphoric Acid",
      "Alpha-Phosphoglycerol",
      "Alpha-Glycerophosphoric Acid",
      "Glycerol Alpha-Phosphate",
      "DL-Glycerol 1-Phosphate",
      "Glycerol-3-Phosphate",
      "1-Phosphoglycerol",
      "DL-Glycerol 3-Phosphate",
      "GLYCEROPHOSPHATE",
      "2,3-Dihydroxypropyl Dihydrogen Phosphate",
      "2,3-Hydroxy-1-Propyl Dihydrogen Phosphate",
      "CHEBI:14336",
      "DL-Glyceryl 1-Phosphate",
      "Rac-Glycerol 1-Phosphate",
    ],
  },
  {
    primary_id: "D0S3JF",
    names: ["ISIS 147483"],
  },
  {
    primary_id: "D0S3KS",
    names: ["N-Cyclohexyl-2-(4-Methoxy-Phenyl)-Acetamide"],
  },
  {
    primary_id: "D0S3LF",
    names: ["MFH-244"],
  },
  {
    primary_id: "D0S3LK",
    names: ["1,6-Bis-(Dmt-Tic-Amino)Hexane", "CHEMBL197185"],
  },
  {
    primary_id: "D0S3MY",
    names: ["ET-642"],
  },
  {
    primary_id: "D0S3NS",
    names: [
      "2-(Indolin-1yl)-Melatonin",
      "CHEMBL514415",
      "GTPL7782",
      "BDBM50266430",
      "N-(2-{5-Methoxy-2-[(2,3-Dihydro-1H-Indol-1yl)Methyl)]-1H-Indol-3-Yl}ethyl)Acetamide",
    ],
  },
  {
    primary_id: "D0S3NU",
    names: ["Delamanid"],
  },
  {
    primary_id: "D0S3ON",
    names: ["Isoindoline Derivative 1"],
  },
  {
    primary_id: "D0S3PN",
    names: ["PMID29130358-Compound-Figure16(9c)"],
  },
  {
    primary_id: "D0S3PO",
    names: ["Pelitinib", "EKB-569", "EKI-569", "WAY-EKB-569"],
  },
  {
    primary_id: "D0S3PX",
    names: ["Intra-Oral Lozenge"],
  },
  {
    primary_id: "D0S3QH",
    names: ["AP-CDLD"],
  },
  {
    primary_id: "D0S3QK",
    names: ["CW-343"],
  },
  {
    primary_id: "D0S3QN",
    names: ["2-[7-(Benzyloxy)-2-Oxo-2H-Chromen-4-Yl]acetamide", "CHEMBL574419"],
  },
  {
    primary_id: "D0S3QX",
    names: ["PMID27977313-Compound-30"],
  },
  {
    primary_id: "D0S3RB",
    names: [
      "ELAROFIBAN",
      "Elarofiban",
      "RWJ-53308",
      "UNII-T9YH5NSL8U",
      "T9YH5NSL8U",
      "198958-88-2",
      "CHEMBL87728",
      "(3s)-3-[({(3r)-1-[3-(Piperidin-4-Yl)Propanoyl]piperidin-3-Yl}carbonyl)Amino]-3-(Pyridin-3-Yl)Propanoic Acid",
      "Elarofiban [INN]",
      "AC1L50BO",
      "AC1Q5T2G",
      "SCHEMBL1650115",
      "CTK4E2665",
      "BDBM50104600",
      "AKOS027326865",
      "(S)-Beta-((R)-1-(3-(4-Piperidyl)Propionyl)Nipecotamido)-3-Pyridinepropionic Acid",
      "(S)-3-{[(R)-1-(3-Piperidin-4-Yl-Propionyl)-Piperidine-3-Carbonyl]-Amino}-3-Pyridin-3-Yl-Propionic Acid",
    ],
  },
  {
    primary_id: "D0S3RP",
    names: ["N-(6-(4-Hydroxyphenyl)-1H-Indazol-3-Yl)Butyramide"],
  },
  {
    primary_id: "D0S3RX",
    names: ["1,2,3-Triazole Derivative 1"],
  },
  {
    primary_id: "D0S3RZ",
    names: [
      "Estradiol 3,17-O,O-Bis-Sulfamate",
      "CHEMBL221802",
      "SCHEMBL5167329",
      "ZINC3825541",
      "BDBM50159804",
      "Sulfamic Acid (8R,9S,13S,14S,17S)-13-Methyl-3-Sulfamoyloxy-7,8,9,11,12,13,14,15,16,17-Decahydro-6H-Cyclopenta[a]phenanthren-17-Yl Ester",
    ],
  },
  {
    primary_id: "D0S3TJ",
    names: [
      "6-MORPHOLIN-4-YL-9H-PURINE",
      "2846-96-0",
      "6-Morpholinopurine",
      "6-(Morpholin-4-Yl)-9H-Purine",
      "6-Morpholin-4-Yl-9h-Purine",
      "4-(1H-Purin-6-Yl)Morpholine",
      "4-(9h-Purin-6-Yl)Morpholine",
      "4-(7H-Purin-6-Yl)Morpholine",
      "SMR000017638",
      "MLS000080575",
      "2wmu",
      "EINECS 220-645-3",
      "4-Purin-6-Ylmorpholine",
      "6-Morpholino-9H-Purine",
      "6-Morpholino-1H-Purine",
      "AC1Q4X9J",
      "AC1Q1I5S",
      "Oprea1_261650",
      "Oprea1_519977",
      "MLS000104086",
      "SCHEMBL336612",
      "CHEMBL271138",
      "AC1L2Q64",
      "BDBM33210",
      "CTK4G1553",
      "9H-Purine,6-(4-Morpholinyl)-",
      "DTXSID80182656",
      "MEOMXKNIFWDDGZ-UHFFFAOYSA-N",
      "MolPort-002-610-718",
      "HMS2298A04",
    ],
  },
  {
    primary_id: "D0S3TL",
    names: ["Oxazolo[3,4-A]pyrazine Derivative 7"],
  },
  {
    primary_id: "D0S3TY",
    names: [
      "N,N'-Bis-(4-Butyl-Phenyl)-Guanidine",
      "CHEMBL109210",
      "SCHEMBL4980235",
      "N,N'-Bis(4-Butylphenyl)Guanidine",
      "BDBM50066427",
    ],
  },
  {
    primary_id: "D0S3TZ",
    names: [
      "CYPRODIME",
      "Cyprodime",
      "CHEMBL322796",
      "N-(Cyclopropylmethyl)-4,14-Dimethoxymorphinan-6-One",
      "SCHEMBL3619261",
      "BDBM50148071",
      "ZINC13580323",
      "NCGC00344512-01",
      "Morphinan-6-One, 17-(Cyclopropylmethyl)-4,14-Dimethoxy-",
      "(-)-N-(Cycloproylmethyl)-4,14-Dimethoxymorphinan-6-One(Cyprodime)",
      "17-Cyclopropylmethyl-3,10-Dimethoxy-(1R,9R,10S)-17-Azatetracyclo[7.5.3.01,10.02,7]heptadeca-2(7),3,5-Trien-13-One",
      "17-Cyclopropylmethyl-10-Hydroxy-4,14-Dimethoxy-(1S,9R,10S)-17-Azatetracyclo[7.5.3.01,10.02,7]heptadeca-2(7),3-Dien-6-One(Cyprodime)",
    ],
  },
  {
    primary_id: "D0S3UA",
    names: ["JWH-145"],
  },
  {
    primary_id: "D0S3UH",
    names: ["SUVN-507", "5-HT 6 Antagonist (Cognitive Disorder), Suven"],
  },
  {
    primary_id: "D0S3UM",
    names: ["Abnormal Cannabidiol", "Abn-CBD", "CAY10429"],
  },
  {
    primary_id: "D0S3UR",
    names: ["PIP3"],
  },
  {
    primary_id: "D0S3VV",
    names: [
      "4-AMBA",
      "4-(Aminomethyl)Benzoic Acid",
      "56-91-7",
      "4-Carboxybenzylamine",
      "Pamba",
      "4-Aminomethylbenzoic Acid",
      "Styptopur",
      "Gumbix",
      "P-Aminomethylbenzoic Acid",
      "Aminomethylbenzoic Acid",
      "Benzoic Acid, 4-(Aminomethyl)-",
      "Benzylamine-4-Carboxylic Acid",
      "Alpha-Amino-P-Toluic Acid",
      "P-(Aminomethyl)Benzoic Acid",
      "Aminomethyl Benzoic Acid",
      "UNII-68WG9JKC7L",
      "4-Aminomethyl-Benzoic Acid",
      "4-(Aminomethyl)Benzoesaeure",
      "NSC41629",
      "EINECS 200-297-9",
      "NSC 41629",
      "68WG9JKC7L",
      ".alpha.-Amino-P-Toluic Acid",
      "CHEMBL328875",
      "P-Toluic Acid, .alpha.-Amino-",
    ],
  },
  {
    primary_id: "D0S3WF",
    names: ["4-[4-(Benzyloxy)Piperidino]butyl-3-Chlorobenzoate", "CHEMBL389049", "SCHEMBL4024650"],
  },
  {
    primary_id: "D0S3WH",
    names: [
      "Artemisinin",
      "Arteannuin",
      "Artemisine",
      "Artemisinina",
      "Artemisinine",
      "Artemisininum",
      "Artesin",
      "Huanghuahaosu",
      "Qinghaosu",
      "Qinghosu",
      "Quinghaosu",
      "Artemisinin [INN]",
      "Artemisinina [Spanish]",
      "Artemisinine [French]",
      "Artemisininum [Latin]",
      "Qing Hau SU",
      "Qing Hau Sau",
      "Qing Hau Sau [Chinese]",
      "Qinghaosu [Chinese]",
      "Quing Hau Sau",
      "Artemisinin (INN)",
      "Coartem, Artemisinin",
      "Artemisia Annua L., Extract",
      "GNF-PF-5671",
      "GNF-Pf-5341",
      "Octahydro-3,6,9-Trimethyl-3,12-Epoxy-12H-Pyrano(4,3-J)-1,2-Benzodioxepin-10(3H)-One",
      "(+)-Arteannuin",
      "(+)-Artemisinin",
      "(3R,5aS,6R,8aS,9R,12S,12aR)-3,6,9-Trimethyloctahydro-3,12-Epoxypyrano[4,3-J][1,2]benzodioxepin-10(3H)-One",
      "(3R,5aS,6R,8aS,9R,12S,12aR)-Octahydro-3,6,9-Trimethyl-3,12-Epoxy-12H-Pyrano(4,3-J)-1,2-Benzodioxepin-10(3H)-One",
      "(5aS,6R,8aS,9R,12S,12aR)-3,6,9-Trimethyloctahydro-3,12-Epoxy[1,2]dioxepino[4,3-I]isochromen-10(3H)-One",
      "1,5,9-Trimethyl-(1R,4S,5R,9R,12S,13R)-11,14,15,16-Tetraoxatetracyclo[10.3.1.04,13.08,13]hexadecan-10-One",
      "3,6,9-Trimethyloctahydro-3,12-Epoxy[1,2]dioxepino[4,3-I]isochromen-10(3H)-One",
    ],
  },
  {
    primary_id: "D0S3WK",
    names: ["HRC-302"],
  },
  {
    primary_id: "D0S3WN",
    names: ["ATPgammaS", "Adenosine 5'-(3-Thio)Triphosphate"],
  },
  {
    primary_id: "D0S3WR",
    names: ["N,N-Dimethyl-4,4-Diphenylbutan-1-Amine"],
  },
  {
    primary_id: "D0S3XA",
    names: ["HRG-214"],
  },
  {
    primary_id: "D0S3XE",
    names: ["Turosteride"],
  },
  {
    primary_id: "D0S3XI",
    names: ["Monomethylfumarate"],
  },
  {
    primary_id: "D0S3XL",
    names: ["Neuromedin B"],
  },
  {
    primary_id: "D0S3YG",
    names: [
      "SNX-9",
      "Cyclin Dependent Kinase Inhibitors (Cancer), Senex Biotechnology",
      "SNX-9 Class Compounds (Cancer)",
      "SNX-9 Class Compounds (Cancer), Senex Biotechnology",
    ],
  },
  {
    primary_id: "D0S3YN",
    names: ["FE-202158"],
  },
  {
    primary_id: "D0S3YP",
    names: [
      "[3H](+)-Isradipine",
      "(+)-[3H]isradipine",
      "(+)-[3H]O5-Methyl O3-Propan-2-Yl 4-(2,1,3-Benzoxadiazol-7-Yl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "3H-(+)-PN 200-110",
    ],
  },
  {
    primary_id: "D0S3YV",
    names: ["CD30 CAR T Cells"],
  },
  {
    primary_id: "D0S3ZB",
    names: ["PMID28092474-Compound-32f"],
  },
  {
    primary_id: "D0S3ZP",
    names: ["Dorolac Nasal Solution"],
  },
  {
    primary_id: "D0S4AR",
    names: ["MX-68"],
  },
  {
    primary_id: "D0S4BJ",
    names: ["ISIS 103486"],
  },
  {
    primary_id: "D0S4BK",
    names: ["Tiazofurin Adenine Dinucleotide"],
  },
  {
    primary_id: "D0S4BP",
    names: ["DP-802"],
  },
  {
    primary_id: "D0S4BR",
    names: ["Methimazole"],
  },
  {
    primary_id: "D0S4BV",
    names: ["N-(R-Carboxy-Ethyl)-Alpha-(S)-(2-Phenylethyl)"],
  },
  {
    primary_id: "D0S4CE",
    names: ["VL-0494"],
  },
  {
    primary_id: "D0S4DT",
    names: ["Vinburnine", "Eburnamonine"],
  },
  {
    primary_id: "D0S4DV",
    names: [
      "CALYSTEGINE B2",
      "Calystegine B2",
      "(1S,2R,3S,4R,5S)-8-AZABICYCLO[3.2.1]OCTANE-1,2,3,4-TETROL",
      "8-Aza-Bicyclo[3.2.1]octane-1,2,3,4-Tetraol",
      "(1R,2S,3R,4S,5R)-8-Azabicyclo[3.2.1]octane-1,2,3,4-Tetrol",
      "127414-85-1",
      "CGB",
      "Calystegine B(2)",
      "2cbv",
      "1,2,3,4-Tetrahydroxy-Nor-Tropane",
      "CID124434",
      "SCHEMBL2435745",
      "CHEMBL526330",
      "BDBM36389",
      "MolPort-006-169-861",
      "ZINC12504453",
      "(2-Endo,3-Exo,4-Endo)-8-Azabicyclo(3.2.1)Octane-1,2,3,4-Tetrol",
      "AKOS030531595",
      "DB04658",
      "LS-190985",
      "W-200999",
      "8-Aza-Bicyclo[3.2.1]octane-1,2,3,4-Tetraol, 14",
    ],
  },
  {
    primary_id: "D0S4EI",
    names: ["S-Benzyl Phenylmethanesulfinothioate"],
  },
  {
    primary_id: "D0S4FQ",
    names: ["Insulin Glargine Biosimilar"],
  },
  {
    primary_id: "D0S4FV",
    names: ["(R)-N-Oleoyltyrosinol", "GTPL5720", "616884-63-0", "ZINC95862771"],
  },
  {
    primary_id: "D0S4GK",
    names: [
      "Deoxy-Bigchap",
      "Big CHAP, Deoxy",
      "N,N-Bis-(3-D-Gluconamidopropyl)Deoxycholamide",
      "AC1MTX5H",
      "DTXSID70394140",
      "AN-35629",
      "FT-0629429",
      "N,N -Bis(3-D -Gluconamidopropyl)- Deoxy-Cholamide",
      "N-[3-[4-(3,12-Dihydroxy-10,13-Dimethyl-2,3,4,5,6,7,8,9,11,12,14,15,16,17-Tetradecahydro-1H-Cyclopenta[a]phenanthren-17-Yl)Pentanoyl-[3-(2,3,4,5,6-Pentahydroxyhexanoylamino)Propyl]amino]propyl]-2,3,4,5,6-Pentahydroxyhexanamide",
    ],
  },
  {
    primary_id: "D0S4GR",
    names: ["ETV-004"],
  },
  {
    primary_id: "D0S4GT",
    names: ["CAR-T Cells Targeting GD2"],
  },
  {
    primary_id: "D0S4IB",
    names: ["Pyridopyrimidinone Derivative 3"],
  },
  {
    primary_id: "D0S4II",
    names: ["GSK1292263"],
  },
  {
    primary_id: "D0S4IK",
    names: ["PMI-016"],
  },
  {
    primary_id: "D0S4IR",
    names: ["PBCAR0191"],
  },
  {
    primary_id: "D0S4JI",
    names: [
      "9-BENZYL-9H-ADENINE",
      "9-Benzyladenine",
      "4261-14-7",
      "9-Benzyl-9H-Purin-6-Amine",
      "N9-Benzyladenine",
      "9-Benzylaminopurine",
      "ADENINE, 9-BENZYL-",
      "9-Bap",
      "9-Benzyl-6-Aminopurine",
      "N(Sup 9)-Benzyladenine",
      "9-Benzyl-9H-Purin-6-Ylamine",
      "9H-Purin-6-Amine, 9-(Phenylmethyl)-",
      "CCRIS 6908",
      "SQ 21611",
      "9-Benzylpurin-6-Amine",
      "NSC 35649",
      "9-(Phenylmethyl)-9H-Purin-6-Amine",
      "CHEMBL266094",
      "9H-Purin-6-Amine,9-(Phenylmethyl)-",
      "AC1L2FWA",
      "MLS000660735",
      "SCHEMBL104932",
      "9-Benzyl-9H-Purine-6-Amine",
      "CTK4I6467",
      "DTXSID70195371",
      "MRHCSNNEUHXNIC-UHFFFAOYSA-N",
      "HMS2676M21",
    ],
  },
  {
    primary_id: "D0S4JK",
    names: ["MLN8237", "Alisertib"],
  },
  {
    primary_id: "D0S4JL",
    names: ["Simtuzumab"],
  },
  {
    primary_id: "D0S4JT",
    names: ["1-(2-(Phenoxymethyl)Phenyl)Piperazine", "CHEMBL574127", "SCHEMBL2189855"],
  },
  {
    primary_id: "D0S4JY",
    names: ["Ro-24-4736"],
  },
  {
    primary_id: "D0S4KI",
    names: ["Menitorix"],
  },
  {
    primary_id: "D0S4KJ",
    names: ["KHK-2866"],
  },
  {
    primary_id: "D0S4KP",
    names: ["4-(6-Methoxy-3-Methylnaphthalen-2-Yl)Isoquinoline", "CHEMBL525785"],
  },
  {
    primary_id: "D0S4LL",
    names: ["Sanfetrinem"],
  },
  {
    primary_id: "D0S4LP",
    names: ["VLI-03A"],
  },
  {
    primary_id: "D0S4LV",
    names: ["VB-111"],
  },
  {
    primary_id: "D0S4LW",
    names: ["PMID9216829C5m"],
  },
  {
    primary_id: "D0S4MN",
    names: ["Veratridine"],
  },
  {
    primary_id: "D0S4MQ",
    names: ["8-Bromo-6-Styryl-Naphthalene-2-Carboxamidine", "CHEMBL360703", "BDBM50157086"],
  },
  {
    primary_id: "D0S4MX",
    names: ["MGN-1374", "Anti-MiR-15/MiR-195 Therapeutic (Post Myocardial Infarction), MiRagen Therapeutics"],
  },
  {
    primary_id: "D0S4NO",
    names: [
      "N-(5-Ethyl-1,3,4-Thiadiazol-2-Yl)Sulfamide",
      "CHEMBL500977",
      "30457-35-3",
      "CTK8I1084",
      "ZINC39121736",
      "BDBM50256510",
      "AKOS027393952",
      "KB-299693",
      "N-(5-Ethyl-1,3,4-Thiadiazol-2-Yl)Aminosulfonamide",
      "N-(5-Ethyl-1,3,4-Thiadiazol-2-Yl)Sulfuric Diamide",
    ],
  },
  {
    primary_id: "D0S4NR",
    names: ["L-Cysteine Sulphinic Acid"],
  },
  {
    primary_id: "D0S4NZ",
    names: ["AVN-458"],
  },
  {
    primary_id: "D0S4OH",
    names: [
      "Anti-CD20 Engineered Toxin Bodies",
      "Anti-CD20 Engineered Toxin Bodies (Lymphoma)",
      "Anti-CD20 ETBs (Lymphoma), Molecular Templates",
      "Anti-CD20 Engineered Toxin Bodies (Lymphoma), Molecular Templates",
    ],
  },
  {
    primary_id: "D0S4OP",
    names: ["PMID28394193-Compound-28"],
  },
  {
    primary_id: "D0S4PN",
    names: ["C-1205"],
  },
  {
    primary_id: "D0S4PZ",
    names: ["SWT-01113"],
  },
  {
    primary_id: "D0S4QZ",
    names: ["Lobaplatin", "D-19466"],
  },
  {
    primary_id: "D0S4RB",
    names: ["ITX-7650"],
  },
  {
    primary_id: "D0S4RK",
    names: ["Recombinant Factor VIII-Fc"],
  },
  {
    primary_id: "D0S4RQ",
    names: ["3-Sec-Butoxy-9H-Beta-Carboline", "CHEMBL86231", "BDBM50001480"],
  },
  {
    primary_id: "D0S4RR",
    names: ["ProTarg"],
  },
  {
    primary_id: "D0S4RZ",
    names: ["ISIS 29234"],
  },
  {
    primary_id: "D0S4SQ",
    names: ["2-Phenylamino-9-(4-Hydroxy-Butyl)-6-Oxopurine"],
  },
  {
    primary_id: "D0S4SX",
    names: ["K-134"],
  },
  {
    primary_id: "D0S4TH",
    names: ["Cycloalkyl Nitrile Pyrazolo Pyridone Derivative 2"],
  },
  {
    primary_id: "D0S4UA",
    names: ["1-Allyl-2-[3-(Isopropylamino)Propoxy]-9H-Xanthen-9-One"],
  },
  {
    primary_id: "D0S4UG",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 32"],
  },
  {
    primary_id: "D0S4VO",
    names: ["[N-Me,des-Sar]Gal-B2", "CHEMBL604991"],
  },
  {
    primary_id: "D0S4VR",
    names: [
      "(1H-Benzoimidazol-5-Yl)-(1H-Imidazol-2-Yl)-Amine",
      "CHEMBL74544",
      "1H-Benzimidazol-6-Amine, N-1H-Imidazol-2-Yl-",
      "185312-13-4",
      "BDBM50055831",
      "AKOS027401219",
    ],
  },
  {
    primary_id: "D0S4VW",
    names: ["Lead Acetate"],
  },
  {
    primary_id: "D0S4WA",
    names: ["ASP0113"],
  },
  {
    primary_id: "D0S4WQ",
    names: [
      "1-(3-Chlorobenzyl)-1H-Imidazole",
      "CHEMBL608437",
      "56643-68-6",
      "1-(3-Chlorobenzyl)Imidazole",
      "N-(3-Chlorobenzyl)-Imidazole",
      "1-(3-Chlorobenzyl)-Imidazole",
      "SCHEMBL9661609",
      "DARGVYVMURXBSO-UHFFFAOYSA-N",
      "MolPort-008-647-602",
      "BDBM50307215",
      "ZINC35143438",
      "AKOS003644458",
      "MCULE-2532110476",
      "1-[(3-Chlorophenyl)Methyl]-1H-Imidazole",
    ],
  },
  {
    primary_id: "D0S4XH",
    names: ["Pyridine Derivative 6"],
  },
  {
    primary_id: "D0S4XR",
    names: ["1-(3-(Benzyloxy)-2-Methylphenyl)Piperazine"],
  },
  {
    primary_id: "D0S4XV",
    names: ["Piperidinyl Triazole Derivative 6"],
  },
  {
    primary_id: "D0S4XX",
    names: ["INOC-003"],
  },
  {
    primary_id: "D0S4XZ",
    names: ["GE-1170A"],
  },
  {
    primary_id: "D0S4YD",
    names: ["Peptide Analog 52"],
  },
  {
    primary_id: "D0S4YT",
    names: ["Plasminogen Concentrate"],
  },
  {
    primary_id: "D0S4YV",
    names: [
      "2-Fluoromevalonate 5-Diphosphate",
      "GTPL3204",
      "2-Fluoro-3-Hydroxy-5-{[hydroxy(Phosphonooxy)Phosphoryl]oxy}-3-Methylpentanoic Acid",
    ],
  },
  {
    primary_id: "D0S4ZT",
    names: ["2-Allylthio-6-Amino-3-Benzyl-Quinazolin-4(3H)-One", "CHEMBL1093491"],
  },
  {
    primary_id: "D0S4ZZ",
    names: ["Anti-HER2 Bi-Specific Mabs", "Anti-HER2 Bi-Specific MAbs (Solid/Hematological Tumor)"],
  },
  {
    primary_id: "D0S5AL",
    names: ["XTL-2125"],
  },
  {
    primary_id: "D0S5BA",
    names: ["Sulfamic Acid 3-(4-Hydroxy-Benzoyl)-Phenyl Ester", "CHEMBL63504"],
  },
  {
    primary_id: "D0S5BC",
    names: ["CART-19 Cells"],
  },
  {
    primary_id: "D0S5BD",
    names: ["PMID28350212-Compound-30"],
  },
  {
    primary_id: "D0S5BG",
    names: ["Propanoic Acid"],
  },
  {
    primary_id: "D0S5BJ",
    names: ["MORPHINONE"],
  },
  {
    primary_id: "D0S5BV",
    names: ["ABT-652"],
  },
  {
    primary_id: "D0S5CH",
    names: ["Metaxalone"],
  },
  {
    primary_id: "D0S5CP",
    names: ["PMID26666989-Compound-Figure12left"],
  },
  {
    primary_id: "D0S5CU",
    names: [
      "Nisoldipine",
      "Baymycard",
      "Nisocor",
      "Nisoldipin",
      "Nisoldipino",
      "Nisoldipinum",
      "Sular",
      "Syscor",
      "Zadipina",
      "Bay K 5552",
      "Nisoldipino [INN-Spanish]",
      "Nisoldipinum [INN-Latin]",
      "Sular (TN)",
      "Bay-K-5552",
      "Nisoldipine (JAN/USAN/INN)",
      "Nisoldipine [USAN:BAN:INN:JAN]",
      "Isobutyl 1,4-Dihydro-5-Methoxycarbonyl-2,6-Dimethyl-4-(2-Nitrophenyl)-3-Pyridincarboxylat;Methyl 2-Methylpropyl 2,6-Dimethyl-4-(2-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "(+-)-Isobutyl Methyl 1,4-Dihydro-2,6-Dimethyl-4-(O-Nitrophenyl)-3,5-Pyridinedicarboxylate",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(2-Nitrophenyl)-, Methyl 2-Methylpropyl Ester",
      "3-O-Methyl 5-O-(2-Methylpropyl) 2,6-Dimethyl-4-(2-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
    ],
  },
  {
    primary_id: "D0S5CY",
    names: ["SGN-CD48A"],
  },
  {
    primary_id: "D0S5DB",
    names: [
      "MS7972",
      "9-ACETYL-2,3,4,9-TETRAHYDRO-1H-CARBAZOL-1-ONE",
      "CHEMBL1236441",
      "TTR",
      "9-Acetyl-3,4-Dihydro-2H-Carbazol-1-One",
      "AC1LGXTL",
      "Cambridge Id 5477972",
      "Oprea1_302910",
      "GTPL7508",
      "SCHEMBL5322539",
      "ZINC393073",
      "BDBM50158692",
      "AKOS024322726",
      "MCULE-6933890211",
      "DB08655",
    ],
  },
  {
    primary_id: "D0S5DR",
    names: ["Dimethylethylamine"],
  },
  {
    primary_id: "D0S5DY",
    names: ["R-85355"],
  },
  {
    primary_id: "D0S5EB",
    names: ["Adoniside"],
  },
  {
    primary_id: "D0S5EF",
    names: ["PF-4418948"],
  },
  {
    primary_id: "D0S5FF",
    names: ["BYM338"],
  },
  {
    primary_id: "D0S5FN",
    names: ["H-Cdp-Ala-Gly-Phe-Leu-OH", "CHEMBL247758"],
  },
  {
    primary_id: "D0S5FQ",
    names: ["MCPP", "1-(3-Chlorophenyl)Piperazine"],
  },
  {
    primary_id: "D0S5GD",
    names: ["Buprenorphine Prodrugs"],
  },
  {
    primary_id: "D0S5GM",
    names: [
      "5-Ethyl-2-(Phenylsulfonamido)Benzoic Acid",
      "Benzoic Acid, 5-Ethyl-2-[(Phenylsulfonyl)Amino]-",
      "677290-35-6",
      "Sulfonamide Compound, 6",
      "SCHEMBL3979169",
      "CHEMBL377012",
      "CTK1H6802",
      "BDBM17600",
      "DTXSID80439379",
      "BUWBVEJQFXVJCT-UHFFFAOYSA-N",
      "2-Benzenesulfonamido-5-Ethylbenzoic Acid",
      "5-Ethyl-2-[(Phenylsulfonyl)Amino]benzoic Acid",
    ],
  },
  {
    primary_id: "D0S5GU",
    names: ["Ap3a", "Diadenosine Triphosphate"],
  },
  {
    primary_id: "D0S5HO",
    names: ["N5-Methylfolate", "5-Methyl-THF", "5-Methyl-Tetrahydrofolate", "5-Methyltetrahydrofolate"],
  },
  {
    primary_id: "D0S5ID",
    names: ["Benzothiazepine Analog 11"],
  },
  {
    primary_id: "D0S5IR",
    names: ["SAGE-217"],
  },
  {
    primary_id: "D0S5IV",
    names: [
      "Anti-Marburg Virus Mabs",
      "Anti-Marburg Virus MAbs, Mapp Biopharmaceutical/USAMRIID",
      "Anti-Marburg Virus Monoclonal Antibodies (Marburg Virus Infection) Mapp Biopharmaceutical/US Army Medical Research Institute Of Infectious Diseases",
    ],
  },
  {
    primary_id: "D0S5KZ",
    names: [
      "Dengue Fever Therapeutics",
      "Dengue Fever Therapeutics, Daiichi Sankyo/ICGEB",
      "Dengue Fever Therapeutics, Ranbaxy/ICGEB",
    ],
  },
  {
    primary_id: "D0S5LD",
    names: [
      "BAY 80-6946",
      "Copanlisib",
      "BAY-80-6946",
      "Aliqopa",
      "UNII-WI6V529FZ9",
      "BAY80-6946",
      "WI6V529FZ9",
      "AK172384",
      "BAY 80-6946 (Copanlisib)",
      "Copanlisib [USAN:INN]",
      "Copanlisib (USAN/INN)",
      "GTPL7875",
      "SCHEMBL1655478",
      "SCHEMBL13084037",
      "Copanlisib (BAY 80-6946)",
      "DTXS",
    ],
  },
  {
    primary_id: "D0S5LH",
    names: [
      "Edrophonium",
      "EDR",
      "Edrophonum",
      "Edroponium",
      "Reversol",
      "Tensilon",
      "EDROPHONIUM ION",
      "Edrophone Chloride",
      "Enlon Plus",
      "ENLON-PLUS",
      "Enlon (TN)",
      "Reversol (TN)",
      "Tensilon (TN)",
      "Ethyl-(3-Hydroxyphenyl)-Dimethylazanium",
      "Ethyl-(3-Hydroxy-Phenyl)-Dimethyl-Ammonium",
      "N-Ethyl-3-Hydroxy-N,N-Dimethylanilinium",
      "N-Ethyl-3-Hydroxy-N,N-Dimethylbenzenaminium",
      "(3-Hydroxyphenyl)Dimethylethylammonium",
      "3-Hydroxy-N,N-Dimethyl-N-Ethylanilinium",
    ],
  },
  {
    primary_id: "D0S5LO",
    names: ["PF-04449913"],
  },
  {
    primary_id: "D0S5LS",
    names: ["N-(2,6-Diflouro-Benzyl)-4-Sulfamoyl-Benzamide"],
  },
  {
    primary_id: "D0S5LT",
    names: [
      "Deoxynojirimycin",
      "1-DEOXYNOJIRIMYCIN",
      "19130-96-2",
      "DUVOGLUSTAT",
      "Moranoline",
      "(2R,3R,4R,5S)-2-(Hydroxymethyl)Piperidine-3,4,5-Triol",
      "1,5-Deoxy-1,5-Imino-D-Mannitol",
      "Moranolin",
      "UNII-FZ56898FLE",
      "D-1-Deoxynojirimycin",
      "1,5-Dideoxy-1,5-Imino-D-Glucitol",
      "CHEMBL307429",
      "C6H13NO4",
      "FZ56898FLE",
      "CHEBI:44369",
      "1-Deoxy-Nojirimycin",
      "5-Amino-1,5-Dideoxy-D-Glucopyranose",
      "DNJ",
      "AK151410",
      "3,4,5-Piperidinetriol, 2-(Hydroxymethyl)-, (2R,3R,4R,5S)-",
      "3,4,5-Piperidinetriol, 2-(Hydroxymethyl)-, (2R-(2alpha,3beta,4alpha,5beta))-",
    ],
  },
  {
    primary_id: "D0S5MC",
    names: ["ISIS 134601"],
  },
  {
    primary_id: "D0S5MI",
    names: ["Bacillus Anthracis Therapeutics"],
  },
  {
    primary_id: "D0S5MJ",
    names: ["HDL-Based Therapeutics"],
  },
  {
    primary_id: "D0S5NG",
    names: [
      "Cetiedil",
      "Fusten",
      "Cetiedilum [INN-Latin]",
      "Cetiedil [INN]",
      "EINECS 238-028-2",
      "14176-10-4",
      "BRN 1591408",
      "CHEBI:34619",
      "2-(1-Azepanyl)Ethyl 2-Cyclohexyl-2-(3-Thienyl)Acetat",
      "2-(Hexahydro-1H-Azepin-1-Yl)Ethyl-Alpha-Cyclohexyl-3-Thiopheneacetic Acid",
      "Alpha-Cyclohexyl-3-Thiopheneacetic Acid, 2-(Hexahydro-1H-Azepin-1-Yl)Ethyl Ester",
      "Cetiedilum",
      "3-Thiopheneacetic Acid, Alpha-Cyclohexyl-, 2-(Hexahydro-1H-Azepin-1-Yl)Ethyl Ester",
      "C20H31NO2S",
      "C13786",
      "SCHEMBL458084",
      "CHEMBL419380",
      "2-(Azepan-1-Yl)Ethyl 2-Cyclohexyl-2-Thiophen-3-Ylacetate",
    ],
  },
  {
    primary_id: "D0S5NN",
    names: [
      "ABI-010",
      "ABI-287",
      "ABI-328",
      "Anticancer Geldanamycin Analogs, Abraxis",
      "Anticancer Geldanamycin Analogs, American Bioscience",
      "CY-214",
      "HH-23",
      "HH-24",
      "Hsp90 Inhibitors (Cancer), Abraxis",
      "Hsp90 Inhibitors (Cancer), American BioScience",
      "Nab-ABI-328",
      "Nab-17-AAG (Cancer), Abraxis",
      "Nab-17-AAG (Cancer), American Bioscience",
      "17-AAG (Albumin-Bound Nanoparticle, Intravenous), Abraxis",
      "17-AAG (Albumin-Bound Nanoparticle, Intravenous), American BioScience",
    ],
  },
  {
    primary_id: "D0S5NS",
    names: ["Pyrazole Derivative 24"],
  },
  {
    primary_id: "D0S5ON",
    names: [
      "1-(4-Nitrophenethyl)Pyrrolidine",
      "CHEMBL273004",
      "168897-19-6",
      "Pyrrolidine, 1-[2-(4-Nitrophenyl)Ethyl]-",
      "SCHEMBL3156895",
      "CTK0E5156",
      "DTXSID90441428",
      "YFOCDNYNVHXLJE-UHFFFAOYSA-N",
      "BDBM50372307",
      "ZINC29131362",
      "1-[2-(4-Nitrophenyl)-Ethyl]-Pyrrolidine",
    ],
  },
  {
    primary_id: "D0S5OQ",
    names: ["(D)-Ala-Pro-Glu", "CHEMBL205662"],
  },
  {
    primary_id: "D0S5OR",
    names: ["(+/-)-Daedalin A", "CHEMBL590726"],
  },
  {
    primary_id: "D0S5OU",
    names: ["PMID24809814C31", "4q6r", "GTPL8465", "BDBM50018282", "30J"],
  },
  {
    primary_id: "D0S5PJ",
    names: ["ISIS-FXIRx"],
  },
  {
    primary_id: "D0S5PS",
    names: [
      "5-[(1S)-1-Ammonioethyl]isoxazol-3-Olate",
      "CHEMBL285783",
      "5-[(S)-1-Aminoethyl]isoxazol-3-Ol",
      "AKOS022719281",
    ],
  },
  {
    primary_id: "D0S5QB",
    names: ["KYS-05040"],
  },
  {
    primary_id: "D0S5RB",
    names: ["Naturally Derived Antibacterial Agents"],
  },
  {
    primary_id: "D0S5RC",
    names: [
      "SNS-032",
      "BMS-387032",
      "BMS-387072",
      "BMS-387032, SNS-032",
      "N-[5-[[[5-(1,1-Dimethylethyl)-2-Oxazolyl]methyl]thio]-2-Thiazolyl]-4-Piperidinecarboxamide L-Tartaric Acid Salt (2:1)",
      "N-(5-(((5-(1,1-Dimethylethyl)-2-Oxazolyl)Methyl)Thio)-2-Thiazolyl)-4-Piperidinecarboxamide",
      "N-[5-[(5-Tert-Butyl-1,3-Oxazol-2-Yl)Methylsulfanyl]-1,3-Thiazol-2-Yl]piperidine-4-Carboxamide",
    ],
  },
  {
    primary_id: "D0S5RE",
    names: ["CPI-006"],
  },
  {
    primary_id: "D0S5RI",
    names: ["Tamoxifen Butyl Bromide"],
  },
  {
    primary_id: "D0S5RV",
    names: [
      "3-Hydroxy-1H-Benzo[b]azepine-2,5-Dione",
      "3-Hydroxy-1H-1-Benzazepine-2,5-Dione",
      "CHEMBL91742",
      "1H-1-Benzazepine-2,5-Dione, 3-Hydroxy-",
      "52280-64-5",
      "SCHEMBL2639080",
      "CTK1E4553",
      "DTXSID10618296",
      "BDBM50054638",
      "5-Hydroxy-1H-1-Benzazepine-2,3-Dione",
    ],
  },
  {
    primary_id: "D0S5RY",
    names: ["S 3025"],
  },
  {
    primary_id: "D0S5RZ",
    names: ["Amtolmetin Guacil", "Artromed", "Atromed", "Eufans", "Amtolmetin Guacyl", "MED-15", "ST-679"],
  },
  {
    primary_id: "D0S5SG",
    names: ["2-Morpholino-6-(Thiophen-3-Yl)-4H-Pyran-4-One", "CHEMBL223381"],
  },
  {
    primary_id: "D0S5SK",
    names: ["Pyrazole Derivative 79"],
  },
  {
    primary_id: "D0S5SM",
    names: ["JCARH125"],
  },
  {
    primary_id: "D0S5TT",
    names: ["ACE-435"],
  },
  {
    primary_id: "D0S5UB",
    names: ["PS522501"],
  },
  {
    primary_id: "D0S5UH",
    names: [
      "Clofazimine",
      "2030-63-9",
      "Lamprene",
      "Lampren",
      "Chlofazimine",
      "Clofazimina",
      "Clofaziminum",
      "Clofaziminum [INN-Latin]",
      "B 663 (Pharmaceutical)",
      "Clofazimina [INN-Spanish]",
      "B-663",
      "NSC-141046",
      "G 30320",
      "UNII-D959AE5USF",
      "B 663 (VAN)",
      "C27H22Cl2N4",
      "3-(P-Chloranilino)-10-(P-Chlorphenyl)-2,10-Dihydro-2-(Isopropylimino)-Phenazin",
      "B 663",
      "3-(P-Chloroanilino)-10-(P-Chlorophenyl)-2,10-Dihydro-2-(Isopropylimino)Phenazine",
      "3-(P-Chloranilino)-10-(P-Chlorophenyl)-2,10-Dihydro-2-(Isopropylimino)-Phenazine",
      "EINECS 217-980-2",
      "CFZ",
      "SMP2_000339",
      "B 663, Pharmaceutical",
      "G-30320",
      "Lamprene (TN)",
      "Liposome-Encapsulated Clofazimine",
      "Clofazimine [USAN:INN:BAN]",
      "G-30,320",
      "Clofazimine (JAN/USP/INN)",
      "N,5-Bis(4-Chlorophenyl)-3-Propan-2-Yliminophenazin-2-Amine",
      "N,5-Bis(4-Chlorophenyl)-3,5-Dihydro-3-(Isopropylimino)Phenazin-2-Amine",
      "N,5-Bis(4-Chlorophenyl)-3,5-Dihydro-3-(1-Methylethyl)Imino)-2-Phenazinamine",
      "N,5-Bis(4-Chlorophenyl)-3,5-Dihydro-3-[(1-Methylethyl)Imino]-2-Phenazinamine",
      "N,5-Bis(4-Chlorophenyl)-3-(Propan-2-Ylimino)-3,5-Dihydrophenazin-2-Amine",
      "(3Z)-N,5-Bis(4-Chlorophenyl)-3-[(1-Methylethyl)Imino]-3,5-Dihydrophenazin-2-Amine",
      "3-(P-Chloranilino)-10-(P-Chlorophenyl)-2,10-Dihydro-2-(Isopropylimino)Phenazine",
      "3-(P-Chloranilino)-10-(P-Chlorphenyl)-2,10-Dihydro-2-(Isopropylimino)-Phenazin [German]",
      "Riminophenazine",
    ],
  },
  {
    primary_id: "D0S5VI",
    names: [
      "N-Benzyl-4-Cyclopentylpiperazine-1-Carboxamide",
      "CHEMBL219757",
      "MolPort-009-268-235",
      "ZINC32779618",
      "BDBM50193214",
      "AKOS027663191",
      "MCULE-5408375837",
      "Z332433676",
    ],
  },
  {
    primary_id: "D0S5VL",
    names: ["SiRNA, Liposomal Nanoparticles, Cervical Cancer"],
  },
  {
    primary_id: "D0S5VU",
    names: ["Herbicide Amitrole"],
  },
  {
    primary_id: "D0S5WE",
    names: [
      "Alpha-D-Glucose-1-Phosphate",
      "Beta-D-Glucose 1-Phosphate",
      "1-O-Phosphono-Beta-D-Glucopyranose",
      "1-Phospho-Beta-D-Glucopyranose",
      "CHEBI:16218",
      "Beta-Glucose-1-Phosphate",
      "UNII-X9QC3WD5G5",
      "X9QC3WD5G5",
      "Beta-D-Glucopyranose 1-(Dihydrogen Phosphate)",
      "1-O-Phosphono-",
      "A-D-Glucopyranose",
      "Dol-P-Glc",
      "XGP",
      "Glucosylphosphodolichol",
      "Dolichylglucose Phosphate",
      "Epitope ID:145000",
      "AC1L3U9A",
      "AC1Q6S5M",
      "NCIStruc2_000464",
      "NCIStruc1_000461",
      "SCHEMBL361623",
      "CHEMBL67622",
      "Beta-D-Glucopyranosyl Phosphate",
      "Dolichol-D-Glucosylmonophosphate",
      ".alpha.-D-Glucose, 1-Phosphate",
      "ZINC3953999",
    ],
  },
  {
    primary_id: "D0S5WG",
    names: [
      "Flucytosine",
      "Alcobon",
      "Ancobon",
      "Ancotil",
      "Ancotyl",
      "Flucitosina",
      "Flucystine",
      "Flucytosin",
      "Flucytosinum",
      "Flucytosone",
      "Fluocytosine",
      "Fluorcytosine",
      "Fluorocytosine",
      "Flucitosina [DCIT]",
      "F0321",
      "LT00771985",
      "Ancobon (TN)",
      "Flucytosinum [INN-Latin]",
      "GL663142 & 5FC",
      "Ro 2-9915",
      "Ro 29915 E/265601",
      "Ro-2-9915",
      "Flucytosine (JP15/USP/INN)",
      "Flucytosine [USAN:INN:BAN:JAN]",
      "Cytosine, 5-Fluoro-(6CI,7CI,8CI)",
      "GL663142 & 4-Amino-5-Fluoropyrimidin-2(1H)-One",
      "2(1H)-Pyrimidinone, 4-Amino-5-Fluoro-)",
      "2-Hydroxy-4-Amino-5-Fluoropyrimidine",
      "4-Amino-5-Fluoro-2(1H)-Pyrimidinone",
      "4-Amino-5-Fluoro-2-Hydroxypyrimidine",
      "4-Amino-5-Fluoro-2-Hyroxypyrimidine",
      "4-Amino-5-Fluoropyrimidin-2(1H)-One",
      "5-FC",
      "5-Flucytosine",
      "5-Fluorocystosine",
      "5-Fluorocytosin",
      "5-Fluorocytosine",
      "5-Fluorocytosine-6-3H",
      "5-Flurocytosine",
      "5-Fluoro Cytosine",
      "5987P",
      "6-Amino-2-Oxo-5-Fluoropyrimidine",
      "6-Amino-5-Fluoro-1H-Pyrimidin-2-One",
      "9074P",
    ],
  },
  {
    primary_id: "D0S5WP",
    names: ["Trametinib + 2141795"],
  },
  {
    primary_id: "D0S5XO",
    names: ["NasoVAX", "Anthrax Vaccine (Intranasal), Vaxin"],
  },
  {
    primary_id: "D0S5XP",
    names: ["9-Allyl-8-Bromo-9H-Adenine", "CHEMBL506888", "8-Brom-9-Allyladenin"],
  },
  {
    primary_id: "D0S5YC",
    names: ["MEPTAZINOL"],
  },
  {
    primary_id: "D0S5YH",
    names: ["Saclofen"],
  },
  {
    primary_id: "D0S5YV",
    names: ["PIK-93"],
  },
  {
    primary_id: "D0S5ZE",
    names: ["D-192", "CHEMBL469182", "SCHEMBL7885003", "BDBM50261306"],
  },
  {
    primary_id: "D0S5ZJ",
    names: ["SERGOLEXOLE MALEATE"],
  },
  {
    primary_id: "D0S5ZY",
    names: ["3-Naphthalen-1-Yl-1-Propyl-Pyrrolidine", "CHEMBL82485"],
  },
  {
    primary_id: "D0S6AE",
    names: ["Diquafosol"],
  },
  {
    primary_id: "D0S6AH",
    names: ["Adiptin", "Adipose Tissue Protein (Obesity), HMGene"],
  },
  {
    primary_id: "D0S6AQ",
    names: ["Ramorelix"],
  },
  {
    primary_id: "D0S6BG",
    names: ["PMID29338548-Compound-20"],
  },
  {
    primary_id: "D0S6BX",
    names: ["PL017", "3-N-Me-Phe-Morphiceptin", "PL-017", "PL 017"],
  },
  {
    primary_id: "D0S6CD",
    names: ["NPS-P156"],
  },
  {
    primary_id: "D0S6CO",
    names: [
      "2,4-Dichloro-5-Sulfamoylbenzoic Acid",
      "2736-23-4",
      "Lasamide",
      "5-(Aminosulfonyl)-2,4-Dichlorobenzoic Acid",
      "2,4-Dichloro-5-Sulfamoyl-Benzoic Acid",
      "2,4-Dichloro-5-Sulphamoylbenzoic Acid",
      "UNII-LEG53TF0SN",
      "2,4-Dichloro-5-Sulfamylbenzoic Acid",
      "Benzoic Acid, 5-(Aminosulfonyl)-2,4-Dichloro-",
      "EINECS 220-358-3",
      "LEG53TF0SN",
      "3-Sulfamoyl-4,6-Dichlorobenzoic Acid",
      "BENZOIC ACID, 2,4-DICHLORO-5-SULFAMOYL-",
      "BRN 2219046",
      "5-Carboxy-2,4-Dichlorobenzenesulfonamide",
      "5-Aminosulfonyl-2,4-Dichlorobenzoic Acid",
      "M 12325",
    ],
  },
  {
    primary_id: "D0S6CT",
    names: [
      "(+/-)-Threo-N-(5-Phenylpentyl)Methylphenidate",
      "CHEMBL1254009",
      "BDBM50327118",
      "(AlphaR)-Alpha-[(2R)-1-(5-Phenylpentyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0S6CW",
    names: ["VPC32183", "Compound 10t [PMID: 15125924]"],
  },
  {
    primary_id: "D0S6DD",
    names: ["PMID25522065-Compound-38"],
  },
  {
    primary_id: "D0S6DK",
    names: [
      "(S)-4C3HPG",
      "(S)-4-CARBOXY-3-HYDROXYPHENYLGLYCINE",
      "85148-82-9",
      "DFS3SIU1ME",
      "UNII-DFS3SIU1ME",
      "(S)-4-(Amino(Carboxy)Methyl)-2-Hydroxybenzoic Acid",
      "CHEMBL39372",
      "(S)-4C3H-PG",
      "Benzeneacetic Acid, Alpha-Amino-4-Carboxy-3-Hydroxy-, (AlphaS)-",
      "4-[(1S)-1-Amino-2-Hydroxy-2-Oxoethyl]-2-Hydroxybenzoic Acid",
      "4C3HPG",
      "Tocris-0320",
      "4-Carboxy-3-Hydroxyphenylglycine, (S)-",
      "AC1NSKO9",
      "GTPL1374",
      "SCHEMBL12648356",
      "CTK8F2145",
      "MolPort-003-983-491",
      "ZINC2559031",
      "PDSP1_001316",
      "PDSP2_001300",
      "BDBM50053585",
      "4-Carboxy-3-Hydroxy-L-Phenylglycine",
      "BN0022",
    ],
  },
  {
    primary_id: "D0S6FE",
    names: ["PTX-008"],
  },
  {
    primary_id: "D0S6FJ",
    names: ["Benzaldehyde O-4-(Hexyloxy)Phenylcarbamoyl Oxime", "CHEMBL598245"],
  },
  {
    primary_id: "D0S6FK",
    names: ["USTILOXIN A"],
  },
  {
    primary_id: "D0S6GE",
    names: [
      "Ro-3201195",
      "[5-AMINO-1-(4-FLUOROPHENYL)-1H-PYRAZOL-4-YL](3-{[(2R)-2,3-DIHYDROXYPROPYL]OXY}PHENYL)METHANONE",
      "CHEMBL203567",
      "CHEBI:45116",
      "Ro 320-1195",
      "2gfs",
      "PQB",
      "[5-Amino-1-(4-Fluorophenyl)-1H-Pyrazol-4-Yl](3-{[(2S)-2,3-Dihydroxypropyl]oxy}phenyl)Methanone",
      "Aminopyrazole 63",
      "RO3201195",
      "AC1NS1L4",
      "SCHEMBL4108597",
      "BDBM15754",
      "IJDQETGUEUJVTB-HNNXBMFYSA-N",
      "DB08424",
      "Ro-320-1195",
      "J3.504.577E",
      "UNII-TE3ESF890C Component IJDQETGUEUJVTB-HNNXBMFYSA-N",
      "5-Amino-1-(4-Fluorophenyl)-4-[3-{2(S),3-Dihydroxypropoxy}benzoyl]pyrazole",
    ],
  },
  {
    primary_id: "D0S6GJ",
    names: [
      "KC-764",
      "(2-Methylpyrazolo[1,5-A]pyridin-3-Yl)(1,4,5,6-Tetrahydro-3-Pyridyl)Methanone",
      "2-Methyl-3-(1,4,5,6-Tetrahydronicotinoyl)Pyrazolo[1,5-A]pyridine",
      "2-Methyl-3-(1,4,5,6-Tetrahydropyridin-3-Ylcarbonyl)Pyrazolo[1,5-A]pyridine",
      "94457-09-7",
    ],
  },
  {
    primary_id: "D0S6GK",
    names: [
      "3,5-Difluorobenzenesulfonamide",
      "3,5-DIFLUOROBENZENESULFONAMIDE",
      "140480-89-3",
      "3,5-Difluorobenzenesulphonamide",
      "Benzenesulfonamide, 3,5-Difluoro-",
      "3,5-Difluorobenzene-1-Sulfonamide",
      "Buttpark 27\\07-04",
      "FBU",
      "PubChem11783",
      "ACMC-20ai3d",
      "AC1L9JFC",
      "KSC493S5P",
      "SCHEMBL602631",
      "CHEBI:42566",
      "CTK3J3957",
      "DTXSID20332219",
      "MKQPOVUFDWKPNO-UHFFFAOYSA-N",
      "MolPort-000-150-739",
      "ZINC2576766",
      "JRD-1790",
      "MFCD02091379",
      "BBL100443",
      "6828AA",
      "STL554237",
      "ANW-74375",
      "SBB091558",
      "3,5-Difluorobenzenesulfonamide, 97%",
      "AKOS000148222",
      "DB02087",
      "VZ25762",
      "TRA0083487",
      "PS-8315",
      "MCULE-4003409613",
      "RTR-005258",
      "CJ-10108",
    ],
  },
  {
    primary_id: "D0S6GS",
    names: [
      "6-Bromo-2-Phenyl-Chromen-4-One",
      "6-Bromoflavone",
      "1218-80-0",
      "6-Bromo-2-Phenyl-4H-Chromen-4-One",
      "6-Bromo-2-Phenyl-4H-1-Benzopyran-4-One",
      "6-Bromo-2-Phenyl-(4H)-4-Benzopyranone",
      "6-Bromo-2-Phenylchromen-4-One",
      "ACMC-20alzx",
      "AC1LAD8N",
      "MLS002473413",
      "CHEMBL52825",
      "SCHEMBL4449962",
      "ZINC58081",
      "DTXSID00332792",
      "HMS3343M11",
      "HMS2224K05",
      "5956AH",
      "MFCD00276169",
      "AKOS015889624",
      "MCULE-7797339727",
      "ZB002311",
      "ST059081",
      "SMR001397504",
      "KB-247810",
      "TX-017285",
      "4H-1-Benzopyran-4-One, 6-Bromo-2-Phenyl-",
      "K-1651",
      "6-Bromo-2-Phenyl-(4H)-4-Benzopyranone, 97%",
    ],
  },
  {
    primary_id: "D0S6GY",
    names: ["SER-101"],
  },
  {
    primary_id: "D0S6HI",
    names: [
      "Arcaine",
      "Tetramethylenediguanide",
      "1,4-Diguanidinobutane",
      "N,N'''-1,4-Butanediylbisguanidine",
      "Arcain",
      "544-05-8",
      "1-(4-Guanidinobutyl)Guanidine",
      "CHEMBL102740",
      "CHEBI:16652",
      "1,1'-(Butane-1,4-Diyl)Diguanidine",
      "Guanidine, N,N'''-1,4-Butanediylbis-",
      "A-8340",
      "1-(4-Carbamimidamidobutyl)Guanidine",
      "58585-47-0",
      "2,2'-Butane-1,4-Diyldiguanidine",
      "Tocris-0389",
      "Spectrum_001819",
      "Prestwick2_000876",
      "Spectrum5_001638",
      "Prestwick1_000876",
      "Prestwick0_000876",
      "Spectrum3_001100",
      "Spectrum2_000601",
      "Prestwick3_000876",
      "Spectrum4_001929",
      "Lopac-A-0384",
    ],
  },
  {
    primary_id: "D0S6HR",
    names: ["KI-0802"],
  },
  {
    primary_id: "D0S6IA",
    names: ["PMID25623274-Compound-WO2014132220C2"],
  },
  {
    primary_id: "D0S6IW",
    names: ["QEDIIRNIARHLAQVGDSMDR"],
  },
  {
    primary_id: "D0S6JG",
    names: ["Liothyronine"],
  },
  {
    primary_id: "D0S6JQ",
    names: ["2,7-Bis(Benzoamido)Anthraquinone", "SCHEMBL4289564"],
  },
  {
    primary_id: "D0S6JV",
    names: ["Nepadutant"],
  },
  {
    primary_id: "D0S6KA",
    names: ["CALANOLIDE A"],
  },
  {
    primary_id: "D0S6KD",
    names: ["(O10eq)-Beta-Alanylryanodine", "CHEMBL282089"],
  },
  {
    primary_id: "D0S6KI",
    names: ["AT-101", "AT-101, Atumida", "Hyperpermeability Inhibitor (Hemorrhagic Shock), Atumida"],
  },
  {
    primary_id: "D0S6KX",
    names: ["GSK-677954"],
  },
  {
    primary_id: "D0S6KY",
    names: ["Acetamide"],
  },
  {
    primary_id: "D0S6MA",
    names: ["OCID-2987"],
  },
  {
    primary_id: "D0S6ME",
    names: ["6-Benzyl-3-Propoxycarbonyl-4-Quinolone", "CHEMBL425590"],
  },
  {
    primary_id: "D0S6MQ",
    names: ["ISIS 16005"],
  },
  {
    primary_id: "D0S6MV",
    names: ["INK-1117"],
  },
  {
    primary_id: "D0S6NE",
    names: [
      "MF266-1",
      "CHEMBL185346",
      "GTPL6068",
      "BDBM50160909",
      "1-[5-[3-[5-Chloro-2-(Phenylmethoxy)Phenyl]thiophen-2-Yl]pyridin-3-Yl]-2,2,2-Trifluoroethane-1,1-Diol",
    ],
  },
  {
    primary_id: "D0S6NH",
    names: ["ZAMI-633"],
  },
  {
    primary_id: "D0S6NJ",
    names: ["AC-03-636"],
  },
  {
    primary_id: "D0S6NP",
    names: ["Kaempferol-3-O-Methyl Ether"],
  },
  {
    primary_id: "D0S6NV",
    names: [
      "1-Bromo-6-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL194936",
      "SCHEMBL3936480",
      "IPBYYNRPJBGKET-UHFFFAOYSA-N",
      "ZINC13645011",
      "BDBM50168334",
    ],
  },
  {
    primary_id: "D0S6OH",
    names: ["Relaxin"],
  },
  {
    primary_id: "D0S6OP",
    names: ["ISIS 110066"],
  },
  {
    primary_id: "D0S6OY",
    names: [
      "FabOvar",
      "Alpha-Folate Receptor-Targeting I131-Labeled MAb Fragment (Ip, Ovarian Carcinoma), Advanced Accelerator Applications",
      "Alpha-Folate Receptor-Targeting I131-Labeled MAb Fragment (Ip, Ovarian Carcinoma), Dompe",
    ],
  },
  {
    primary_id: "D0S6PJ",
    names: [
      "6-Nitroquipazine",
      "77372-73-7",
      "6-Nitro-2-(Piperazin-1-Yl)Quinoline",
      "6-Nitro-2-Piperazin-1-Yl-Quinoline",
      "6-Nitro,2-(1-Piperazinyl)Quinoline",
      "DU-24565",
      "DU 24565",
      "UNII-28M0X094BH",
      "Quinoline,6-Nitro-2-(1-Piperazinyl)-",
      "Quinoline, 6-Nitro-2-(1-Piperazinyl)-",
      "CHEMBL41140",
      "28M0X094BH",
      "6-Nitro-2-Piperazin-1-Ylquinoline",
      "6-Nitro-2-(1-Piperazinyl)Quinoline Maleate Salt",
      "Lopac-Q-109",
      "Biomol-NT_000101",
      "AC1L1JF0",
      "AC1Q1XW4",
      "Lopac0_001010",
      "SCHEMBL401156",
      "AC1Q21C2",
      "BPBio1_001389",
      "DTXSID7044001",
      "CTK5E4324",
      "GGDBEAVVGFNWIA-UHFFFAOYSA-N",
      "CHEBI:104164",
    ],
  },
  {
    primary_id: "D0S6QA",
    names: [
      "8-Bromo-9-(2-Butyl)-9H-Adenine",
      "CHEMBL505089",
      "202136-43-4",
      "(8-Bromo-9-Butyl-9H-Purin-6-Yl)Amine",
      "8-Bromo-9-Butyladenine",
      "SCHEMBL1965296",
      "CTK4E3576",
      "DTXSID80469393",
      "FKNNQOCJLMQVAV-UHFFFAOYSA-N",
      "BDBM50256953",
      "AKOS015965383",
      "9H-Purin-6-Amine,8-Bromo-9-Butyl-",
    ],
  },
  {
    primary_id: "D0S6QJ",
    names: ["C10-1040", "C10-1040 (C10-1001 Scaffold)"],
  },
  {
    primary_id: "D0S6QR",
    names: ["BMS-204352"],
  },
  {
    primary_id: "D0S6RD",
    names: ["Hygromycin B", "AC1NUSSH"],
  },
  {
    primary_id: "D0S6RL",
    names: ["NSC-99495"],
  },
  {
    primary_id: "D0S6RY",
    names: ["OPC 108459"],
  },
  {
    primary_id: "D0S6SJ",
    names: ["Piperidine Derivative 3"],
  },
  {
    primary_id: "D0S6SN",
    names: ["6-Hydroxymelatonin", "6-OH-MLT", "6-OH-Melatonin"],
  },
  {
    primary_id: "D0S6SP",
    names: [
      "BPL-002",
      "BPL-002 Series (Bacterial Infection)",
      "BPL-002 Series (Bacterial Infection), Bradford Pharma",
      "BPL-002 Series(Bacterial Infection), Redx Pharma",
    ],
  },
  {
    primary_id: "D0S6SQ",
    names: ["Odelepran"],
  },
  {
    primary_id: "D0S6SU",
    names: ["KRN-2391"],
  },
  {
    primary_id: "D0S6TK",
    names: ["Tamatinib"],
  },
  {
    primary_id: "D0S6TL",
    names: ["N-(Benzimidazole/Indole) Benzoic Acid Derivative 1"],
  },
  {
    primary_id: "D0S6TW",
    names: [
      "6,7-Dichloro-1,4-Dihydro-Quinoxaline-2,3-Dione",
      "25983-13-5",
      "6,7-Dichloroquinoxaline-2,3(1H,4H)-Dione",
      "DCQX",
      "6,7-Dichloroquinoxaline-2,3-Diol",
      "6,7-DICHLORO-1,4-DIHYDRO-2,3-QUINOXALINEDIONE",
      "6,7-Dichloro-1,4-Dihydroquinoxaline-2,3-Dione",
      "2,3-Dihydroxy-6,7-Dichloroquinoxaline",
      "CHEMBL284028",
      "Spectrum_001661",
      "ACMC-209gnv",
      "SpecPlus_000650",
      "Lopac-D-133",
      "AC1L1CDC",
      "Spectrum5_001426",
      "Spectrum4_000630",
      "Spectrum3_001668",
      "Spectrum2_000537",
      "Biomol-NT_000181",
      "AC1Q3Q7M",
      "Lopac0_000418",
      "KBioSS_002141",
      "KBioGR_001220",
      "BSPBio_003455",
      "Benzil-Related Co",
    ],
  },
  {
    primary_id: "D0S6UH",
    names: ["BC-6213"],
  },
  {
    primary_id: "D0S6VB",
    names: ["DG031"],
  },
  {
    primary_id: "D0S6VX",
    names: [
      "CGP-40215A",
      "Cgp-40215A",
      "Cgp 40215A",
      "Bis((3-(Aminoiminomethyl)Phenyl)Methylene)Carbonimidic Dihydrazide Trihydrochloride",
      "Carbonimidic Dihydrazide, Bis((3-(Aminoiminomethyl)Phenyl)Methylene)-, Trihydrochloride",
    ],
  },
  {
    primary_id: "D0S6WW",
    names: ["E12/DP3-117"],
  },
  {
    primary_id: "D0S6XD",
    names: [
      "Calphostin C",
      "CHEMBL460433",
      "UNII-I271P23G24",
      "I271P23G24",
      "CHEMBL1256495",
      "Ucn 1028 C",
      "UCN 1028C",
      "BSPBio_001520",
      "GTPL5156",
      "SCHEMBL15185703",
      "HSDB 7592",
      "SRJYZPCBWDVSGO-NHCUHLMSSA-N",
      "HMS1989L22",
      "HMS3402L22",
      "HMS1791L22",
      "BDBM213748",
      "Carbonic Acid, 2-(12-(2-(Benzoyloxy)Propyl)-3,10-Dihydro-4,9-Dihydroxy-2,6,7,11-Tetramethoxy-3,10-Dioxo-1-Perylenyl)-1-Methylethyl 4-Hydroxyphenyl Ester",
      "Carbonic Acid, (1R)-2-(12-((2R)-2-(Benzoyloxy)Propyl)-3,10-Dihydro-4,9-Dihydroxy-2,6,7,11-Tetramethoxy-3,10-Dioxo-1-Perylenyl)-1-Methylethyl 4-Hydroxy",
    ],
  },
  {
    primary_id: "D0S6XJ",
    names: ["GS-522"],
  },
  {
    primary_id: "D0S6XT",
    names: ["Bis-Aminopyrimidine Derivative 3"],
  },
  {
    primary_id: "D0S6XY",
    names: [
      "4,5-Dihydroxy-10H-Anthracen-9-One",
      "CHEMBL124951",
      "Isoanthralin",
      "1,8-Dihydroxy-10-Anthrone",
      "ZINC13606497",
      "BDBM50060858",
    ],
  },
  {
    primary_id: "D0S6YO",
    names: ["Ajinomoto 3"],
  },
  {
    primary_id: "D0S7AN",
    names: ["C-Met Mabs", "C-Met MAbs (Cancer/Diabetic Retinopathy/Macular Degeneration)"],
  },
  {
    primary_id: "D0S7AR",
    names: ["Papillomavirus Vaccine"],
  },
  {
    primary_id: "D0S7AW",
    names: ["KD020"],
  },
  {
    primary_id: "D0S7AY",
    names: ["NSC-125034"],
  },
  {
    primary_id: "D0S7BE",
    names: [
      "5-Methyl Orotate",
      "AC1LAAV1",
      "SCHEMBL15531476",
      "4-[4-[[11-Butyl-9-[(R)-Cyclohexyl(Hydroxy)Methyl]-7,10-Dioxo-3,8,11-Triazaspiro[5.5]undecan-3-Yl]methyl]phenoxy]benzoic Acid",
    ],
  },
  {
    primary_id: "D0S7BK",
    names: [
      "Orotic Acid",
      "65-86-1",
      "6-Carboxyuracil",
      "Oropur",
      "Orodin",
      "Orotonin",
      "Orotonsan",
      "Oroturic",
      "Orotyl",
      "Whey Factor",
      "Vitamin B13",
      "2,6-Dioxo-1,2,3,6-Tetrahydropyrimidine-4-Carboxylic Acid",
      "Uracil-6-Carboxylic Acid",
      "6-Uracilcarboxylic Acid",
      "Animal Galactose Factor",
      "Orotsaeure",
      "Molkensaeure",
      "Uracil-6-Carbosaeure",
      "4-Pyrimidinecarboxylic Acid, 1,2,3,6-Tetrahydro-2,6-Dioxo-",
      "2,6-Dihydroxypyrimidine-4-Carboxylic Acid",
      "2,6-Dihydroxy-4-Pyrimidinecarboxylic Acid",
      "Orotsaeure [German]",
      "Acidum Oroticum",
      "Acide Orotique",
      "Acido Orotico",
      "Acidum Oroti",
    ],
  },
  {
    primary_id: "D0S7BQ",
    names: ["GSK1322322"],
  },
  {
    primary_id: "D0S7BW",
    names: ["PMID29473428-Compound-33"],
  },
  {
    primary_id: "D0S7CM",
    names: ["HDL-100"],
  },
  {
    primary_id: "D0S7CP",
    names: ["VK-12", "Anticancer/Neuroprotectant (Cancer/Parkinsons/Alzheimer's Disease), Prana"],
  },
  {
    primary_id: "D0S7DS",
    names: ["SPD-756", "BCH-13520"],
  },
  {
    primary_id: "D0S7DV",
    names: ["Tiazofurin"],
  },
  {
    primary_id: "D0S7EQ",
    names: ["PEN-203"],
  },
  {
    primary_id: "D0S7FP",
    names: ["(R)-Indan-1-Yl-Methyl-Prop-2-Ynyl-Amine"],
  },
  {
    primary_id: "D0S7FR",
    names: ["Ostabolin-C"],
  },
  {
    primary_id: "D0S7HC",
    names: [
      "3-(4-Phenylpiperazin-1-Carbonyl)Coumarin",
      "CHEMBL486460",
      "3-(4-Phenylpiperazine-1-Carbonyl)-2H-Chromen-2-One",
      "420099-59-8",
      "AC1LEC4F",
      "CBMicro_029395",
      "Oprea1_062891",
      "ZINC99797",
      "PEIQALYIIRRQAZ-UHFFFAOYSA-N",
      "MolPort-000-467-605",
      "STK414837",
      "BDBM50244417",
      "AKOS001407600",
      "MCULE-7805538534",
      "NCGC00306822-01",
      "BIM-0029313.P001",
      "ST4080681",
      "AB00094693-01",
      "3-[(4-Phenylpiperazinyl)Carbonyl]chromen-2-One",
      "AB00094693-02",
      "3-(4-Phenylpiperazine-1-Carbonyl)Chromen-2-One",
      "SR-01000218055",
      "Chromen-2-One, 3-(4-Phenylpiperazine-1-Carbonyl)-",
    ],
  },
  {
    primary_id: "D0S7HM",
    names: ["ENMD-2076"],
  },
  {
    primary_id: "D0S7HW",
    names: ["Anti-MiR-199a Oligonucleotides", "Anti-MiR-199a Oligonucleotides (Ischemic Heart Disease)"],
  },
  {
    primary_id: "D0S7IE",
    names: ["N-(4-Amino-5-Cyano-6-Phenylpyridin-2-Yl)Acetamide"],
  },
  {
    primary_id: "D0S7IJ",
    names: ["8-(3-Methyl-Cyclopentyloxy)-Quinolin-2-Ylamine", "CHEMBL183739", "SCHEMBL5883724"],
  },
  {
    primary_id: "D0S7IQ",
    names: [
      "Anti-VEGF Human Mabs",
      "Anti-VEGF Human MAbs (Solid Tumor)",
      "Anti-VEGF Human MAbs (Solid Tumor), Xencor",
      "Anti-VEGF Human Monoclonal Antibodies (Solid Tumor), Xencor",
    ],
  },
  {
    primary_id: "D0S7IZ",
    names: ["Apricoxib"],
  },
  {
    primary_id: "D0S7JH",
    names: ["Macitentan"],
  },
  {
    primary_id: "D0S7JN",
    names: ["GP-2017"],
  },
  {
    primary_id: "D0S7KA",
    names: ["PMID25399762-Compound-Table 8-(-)-Deprenyl"],
  },
  {
    primary_id: "D0S7KQ",
    names: ["Hematide"],
  },
  {
    primary_id: "D0S7LD",
    names: [
      "2,6-Dihydroxy-1,7-Dimethoxyxanthone",
      "Calophymembranol A",
      "CHEMBL463769",
      "AC1NRZXJ",
      "BDBM50269644",
      "2,6-Dihydroxy-1,7-Dimethoxyxanthen-9-One",
    ],
  },
  {
    primary_id: "D0S7LG",
    names: ["ARN-509", "Arn-509 (AR Inhibitor)"],
  },
  {
    primary_id: "D0S7LN",
    names: [
      "GS-9132",
      "ACH-806",
      "HCV Replication Inhibitors, Achillion/Gilead Sciences",
      "Hepatitis C Virus Replication Inhibitors, Achillion/Gilead",
    ],
  },
  {
    primary_id: "D0S7MJ",
    names: [
      "(S)-N-(1-Phenylethyl)Acetimidamide Hydrobromide",
      "CHEMBL448703",
      "1-[(1-Phenylethyl)Amino]ethaniminium Bromide",
    ],
  },
  {
    primary_id: "D0S7NE",
    names: ["Diaryl Amine Derivative 2"],
  },
  {
    primary_id: "D0S7NH",
    names: [
      "Dansylamide",
      "1431-39-6",
      "5-(Dimethylamino)Naphthalene-1-Sulfonamide",
      "5-DIMETHYLAMINO-1-NAPHTHALENESULFONAMIDE",
      "Dansyl Amide",
      "5-Dimethylaminonaphthalene-1-Sulfonamide",
      "5-(DIMETHYLAMINO)-1-NAPHTHALENESULFONAMIDE",
      "TYNBFJJKZPTRKS-UHFFFAOYSA-N",
      "5-[Dimethylamino]-1-Naphthalenesulfonamide",
      "1-Naphthalenesulfonamide, 5-(Dimethylamino)-",
      "1okl",
      "EINECS 215-854-1",
      "AC1Q3WHH",
      "5-(Dimethylamino)Naphthalene-1-Sulphonamide",
      "AC1L22JG",
      "Oprea1_628153",
      "SCHEMBL108421",
      "CHEMBL119489",
      "ZINC56543",
      "BDBM11402",
      "TYNBFJJKZPTRKS-UHFFFAOYSA-",
      "DTXSID70162306",
    ],
  },
  {
    primary_id: "D0S7NM",
    names: ["8-(4-Hydroxyphenyl)-2-Morpholin-4-Ylchromen-4-One", "CHEMBL200517"],
  },
  {
    primary_id: "D0S7NS",
    names: ["MMI-0100"],
  },
  {
    primary_id: "D0S7PJ",
    names: ["Metronidazole 10% Ointment"],
  },
  {
    primary_id: "D0S7QB",
    names: ["JNJ-28165722"],
  },
  {
    primary_id: "D0S7SE",
    names: [
      "[3H]methoxymethyl-MTEP",
      "3-(Methoxymethyl)-5-[2-(2-Methyl-1,3-Thiazol-4-Yl)Ethynyl]pyridine",
      "Pyridine, 3-(Methoxymethyl)-5-[(2-Methyl-4-Thiazolyl)Ethynyl]-",
      "CHEMBL1169453",
      "Methoxymethyl-MTEP",
      "524924-75-2",
      "[3H]-Methoxymethyl-MTEP",
      "CHEMBL420533",
      "GTPL5394",
      "GTPL3345",
      "SCHEMBL5652003",
      "CTK1E4453",
      "DTXSID90438611",
      "AWDRAHDWMICWJM-UHFFFAOYSA-N",
      "BDBM50366853",
      "3-Methoxymethyl-5-(2-Methyl-Thiazol-4-Ylethynyl)-Pyridine",
      "3-(2-Methyl-4-Thiazolylethynyl)-5-(Methoxymethyl)Pyridine",
    ],
  },
  {
    primary_id: "D0S7SN",
    names: ["ISIS 102478"],
  },
  {
    primary_id: "D0S7SO",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-D-Trp-Nle-Asp-Phe-Bo", "CHEMBL217957"],
  },
  {
    primary_id: "D0S7TM",
    names: ["2,2'-Bi(1,3,4-Thiadiazole)-5,5'(4H,4'H)-Dithione", "CHEMBL1163927", "BDBM50320719"],
  },
  {
    primary_id: "D0S7UD",
    names: ["4-(1H-Indol-5-Yloxy)-5-Styrylnicotinonitrile", "CHEMBL568973", "SCHEMBL2935007"],
  },
  {
    primary_id: "D0S7UH",
    names: ["ALX-0171", "Anti-RSV Nanobody (Inhaled), Ablynx"],
  },
  {
    primary_id: "D0S7UQ",
    names: ["RG-7103"],
  },
  {
    primary_id: "D0S7UU",
    names: ["PF-06260414"],
  },
  {
    primary_id: "D0S7VI",
    names: ["AXT-914"],
  },
  {
    primary_id: "D0S7VL",
    names: ["Urea And Carbamate Bioisostere Derivative 15"],
  },
  {
    primary_id: "D0S7VN",
    names: ["MRL-953"],
  },
  {
    primary_id: "D0S7VO",
    names: ["Tiaprofenic Acid"],
  },
  {
    primary_id: "D0S7VY",
    names: ["3,5-Diacetoxy-4'-Amino-Trans-Stilbene", "CHEMBL1172400", "BDBM50322065"],
  },
  {
    primary_id: "D0S7VZ",
    names: ["WAY-100289"],
  },
  {
    primary_id: "D0S7WQ",
    names: [
      "N-Hydroxy-4-(4-Phenyl-Butyrylamino)-Benzamide",
      "CHEMBL143336",
      "656261-24-4",
      "SCHEMBL674421",
      "CTK1J6157",
      "DTXSID30433908",
      "ZINC13533300",
      "AKOS030583673",
      "N-Hydroxy-4-(4-Phenylbutyryl-Amino)Benzamide",
      "Benzenebutanamide, N-[4-[(Hydroxyamino)Carbonyl]phenyl]-",
    ],
  },
  {
    primary_id: "D0S7WX",
    names: ["Vitamin A"],
  },
  {
    primary_id: "D0S7WZ",
    names: [
      "(-)-3-(1-Propyl-Piperidin-3-Yl)-Benzonitrile",
      "3-(1-Propyl-3-Piperidinyl)Benzonitrile",
      "150336-90-6",
      "3-(1-Propylpiperidin-3-Yl)Benzonitrile",
      "CHEMBL107492",
      "Benzonitrile,3-(1-Propyl-3-Piperidinyl)-",
      "DS121 Cpd",
      "3-(3-Cyanophenyl)-N-N-Propylpiperidine",
      "AC1L1JNG",
      "AC1Q4RGX",
      "ACMC-1C3WE",
      "SCHEMBL872942",
      "CTK4C6605",
      "BDBM50041960",
      "3-(1-Propylpiperidine-3-Yl)Benzonitrile",
      "3-(1-Propyl-Piperidin-3-Yl)-Benzonitrile",
      "(+)-3-(1-Propyl-Piperidin-3-Yl)-Benzonitrile",
      "L007862",
      "Benzonitrile, 3-(1-Propyl-3-Piperidinyl)-, (+-)-",
    ],
  },
  {
    primary_id: "D0S7XR",
    names: [
      "TKP-1002",
      "Antipsychotics (Schizophrenia), EUSA Pharma",
      "Antipsychotics (Schizophrenia), Talisker Pharma",
      "Antipsychotics (Schizophrenia), University Of Liege",
    ],
  },
  {
    primary_id: "D0S7XV",
    names: ["Tetanus Immune Globulin"],
  },
  {
    primary_id: "D0S7YD",
    names: ["NE58062"],
  },
  {
    primary_id: "D0S7YR",
    names: ["Des-AA1,5-[Tyr2,D-Trp8,IAmp9]SRIF CH-288", "CHEMBL411002"],
  },
  {
    primary_id: "D0S7YU",
    names: ["ALD-601"],
  },
  {
    primary_id: "D0S7ZQ",
    names: ["4'-Methoxy-5,3'-Dipropyl-Biphenyl-2ol", "CHEMBL555297", "Magreth-1", "ZINC42966087", "BDBM50295920"],
  },
  {
    primary_id: "D0S7ZU",
    names: ["4-Biphenyl-4-Yl-2-Methyl-1H-Imidazole", "CHEMBL330800", "SCHEMBL3669956"],
  },
  {
    primary_id: "D0S7ZZ",
    names: ["Benzofuran-3-Yl-(Indol-3-Yl)Maleimides"],
  },
  {
    primary_id: "D0S8AC",
    names: [
      "1-Hydroxy-2-Dodecyl-4(1H)Quinolone",
      "CHEMBL224182",
      "AC1LCHH0",
      "SCHEMBL8535434",
      "2-Dodecyl-1-Hydroxyquinolin-4-One",
      "2-Dodecyl-1-Hydroxy-Quinolin-4-One",
      "BDBM50203194",
      "1-Hydroxy-2-Dodecyl-4(1H)Quinoline",
      "1-Hydroxy-2-Dodecyl-4-(1H)Quinoline",
      "(1-Hydroxy-2-Dodecyl-4(1H)Quinolone)",
      "1-Hydroxy-2-Dodecylquinoline-4(1H)-One",
      "2-Dodecyl-1-Oxidanidyl-Quinolin-1-Ium-4-Ol",
      "2-Dodecyl -4-HYDROXY QUINOLINE N-OXIDE",
    ],
  },
  {
    primary_id: "D0S8AF",
    names: ["Visceral Leishmaniasis Vaccine"],
  },
  {
    primary_id: "D0S8AN",
    names: ["SYM2081"],
  },
  {
    primary_id: "D0S8AS",
    names: ["Tert-Butyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL444507"],
  },
  {
    primary_id: "D0S8AV",
    names: [
      "SOPHORAFLAVANONE B",
      "8-Prenylnaringenin",
      "53846-50-7",
      "Sophoraflavanone B",
      "Flavaprenin",
      "(-)-(2S)-8-Dimethylallylnaringenin",
      "(S)-8-Dimethylallylnaringenin",
      "YS04",
      "CHEMBL376915",
      "CHEBI:50207",
      "(-)-(2S)-5,7-Dihydroxy-2-(4-Hydroxyphenyl)-8-(3-Methylbut-2-Enyl)Chroman-4-One",
      "(2S)-5,7-Dihydroxy-2-(4-Hydroxyphenyl)-8-(3-Methylbut-2-En-1-Yl)-2,3-Dihydro-4H-Chromen-4-One",
      "(S)-8-Prenylnaringenin",
      "8-Dimethylallylnaringenin",
      "AC1LA3DM",
      "UNII-5L872SZR8X",
      "8-Prenylnaringenin (8-PN)",
      "BIDD:ER0149",
      "Cid_509245",
      "SCHEMBL1171435",
      "5L872SZR8X",
      "ZINC39452",
      "CTK5J8800",
      "BDBM19460",
    ],
  },
  {
    primary_id: "D0S8AZ",
    names: ["Flavonoid Derivative 6"],
  },
  {
    primary_id: "D0S8BG",
    names: [
      "EMD 55900",
      "27686-84-6",
      "4,4'-((2R,3S)-2,3-Dimethylbutane-1,4-Diyl)Bis(Benzene-1,2-Diol)",
      "UNII-7BO8G1BYQU",
      "1,2-Benzenediol, 4,4'-(2,3-Dimethyl-1,4-Butanediyl)Bis-, (R*,S*)-",
      "EINECS 248-606-6",
      "7BO8G1BYQU",
      "CHEMBL313972",
      "Erythro-Nordihydroguaiaretic Acid",
      "CHEBI:73468",
    ],
  },
  {
    primary_id: "D0S8BT",
    names: ["TOpiramate Injection"],
  },
  {
    primary_id: "D0S8BU",
    names: ["SE RNA"],
  },
  {
    primary_id: "D0S8CE",
    names: ["XR-9577"],
  },
  {
    primary_id: "D0S8CI",
    names: [
      "ELIPRODIL",
      "Eliprodil",
      "119431-25-3",
      "Eliprodil [INN]",
      "C20H23ClFNO",
      "SL 820715",
      "SL 82-0715",
      "CHEMBL28564",
      "Alpha-(4-Chlorophenyl)-4-[(4-Fluorophenyl)Methyl]-1-Piperidineethanol",
      "NCGC00092329-02",
      "1-Piperidineethanol, Alpha-(4-Chlorophenyl)-4-(4-Fluorophenyl)-, (+-)-",
      "DSSTox_RID_81089",
      "DSSTox_CID_25744",
      "DSSTox_GSID_45744",
      "1-(4-Chlorophenyl)-2-(4-(4-Fluorobenzyl)Piperidin-1-Yl)Ethanol",
      "(+-)-Alpha-(P-Chlorophenyl)-4-(P-Fluorobenzyl)-1-Piperidineethanol",
      "1-(4-Chlorophenyl)-2-[4-[(4-Fluorophenyl)Methyl]piperidin-1-Yl]ethanol",
    ],
  },
  {
    primary_id: "D0S8CL",
    names: ["1-Benzyl-7-Chloro-4-Hexyloxy-Quinolinium"],
  },
  {
    primary_id: "D0S8DB",
    names: ["Org-9731"],
  },
  {
    primary_id: "D0S8DT",
    names: [
      "L-746233",
      "CHEMBL79294",
      "BDBM50078442",
      "3-{[3-Oxo-2-(2-Piperidin-4-Yl-Ethyl)-2,3-Dihydro-1H-Isoindole-5-Carbonyl]-Amino}-2-(Pyridine-3-Sulfonylamino)-Propionic Acid(L-746233)",
      "(2S)-2-[(3-Pyridinyl)Sulfonylamino]-3-[[2-[2-(4-Piperidinyl)Ethyl]-3-Oxoisoindolin-5-Yl]carbonylamino]propanoic Acid",
      "(S)-3-{[3-Oxo-2-(2-Piperidin-4-Yl-Ethyl)-2,3-Dihydro-1H-Isoindole-5-Carbonyl]-Amino}-2-(Pyridine-3-Sulfonylamino)-Propionic Acid",
    ],
  },
  {
    primary_id: "D0S8DU",
    names: ["TGWOOAA"],
  },
  {
    primary_id: "D0S8DV",
    names: ["Cotelliccobimetinib"],
  },
  {
    primary_id: "D0S8EB",
    names: ["4,6,10-Trimethyl-6H-Benzo[c]chromene-3,8-Diol", "CHEMBL380362"],
  },
  {
    primary_id: "D0S8EH",
    names: ["PMID28447479-Compound-11"],
  },
  {
    primary_id: "D0S8FT",
    names: [
      "Fluorescein Derivative Contrast Agent",
      "Albufluor",
      "Fluorescein Derivative Contrast Agent (Imaging, Ocular Disease)",
      "Fluorescein Derivative Contrast Agent (Imaging, Ocular Disease), Philogen",
    ],
  },
  {
    primary_id: "D0S8FU",
    names: ["Resultz"],
  },
  {
    primary_id: "D0S8FV",
    names: ["3-(6-Methoxy-3-Methylnaphthalen-2-Yl)Pyridine", "CHEMBL499871", "BDBM50272194"],
  },
  {
    primary_id: "D0S8HD",
    names: ["PEP-223/CoVaccine HT"],
  },
  {
    primary_id: "D0S8HE",
    names: ["MSOPPE", "Alpha-Methylserine-O-Phosphate Monophenyl Ester"],
  },
  {
    primary_id: "D0S8HZ",
    names: [
      "2-Methylquinoline-8-Carboxamide",
      "CHEMBL471966",
      "8-Quinolinecarboxamide, 2-Methyl-",
      "SCHEMBL422282",
      "BDBM50255329",
      "AKOS022882220",
    ],
  },
  {
    primary_id: "D0S8KA",
    names: ["3-(2-Amino-Propyl)-1H-Indol-5-Ol", "5-Hydroxy-Alpha-Methyltryptamine"],
  },
  {
    primary_id: "D0S8KE",
    names: ["NS-2979"],
  },
  {
    primary_id: "D0S8LJ",
    names: [
      "S-1",
      "Ciprofibrate-Coa",
      "Ciprofibrate-Coenzyme A",
      "Coenzyme A, Ciprofibrate-",
      "AC1L4TRG",
      "AC1Q3T4H",
      "111900-25-5",
      "S-{1-[(2r,3s,4r,5r)-5-(6-Amino-9h-Purin-9-Yl)-4-Hydroxy-3-(Phosphonooxy)Tetrahydrofuran-2-Yl]-3,5,9-Trihydroxy-8,8-Dimethyl-3,5-Dioxido-10,14-Dioxo-2,4,6-Trioxa-11,15-Diaza-3",
      "E5,5",
      "E5-Diphosphaheptadecan-17-Yl} 2-[4-(2,2-Dichlorocyclopropyl)Phenoxy]-2-Methylpropanethioate(Non-Preferred Name)",
      "Coenzyme A, S-(2-(4-(2,2-Dichlorocyclopropyl)Phenoxy)-2-Methylpropanoate)",
    ],
  },
  {
    primary_id: "D0S8LV",
    names: ["Panipenem", "Betamipron", "Carbenin (TN)"],
  },
  {
    primary_id: "D0S8MG",
    names: ["Genz-668764", "Aminoindoles (Malaria), Genzyme/Medicines For Malaria Venture"],
  },
  {
    primary_id: "D0S8MJ",
    names: ["NSC-356781"],
  },
  {
    primary_id: "D0S8MN",
    names: [
      "AAV-MiApoB",
      "ApoB MiRNA Gene Therapy (Hypercholesterolemia), AMT",
      "Anti-Apolipoprotein B AAV Gene Therapy (Hypercholesterolemia), Amsterdam Molecular Therapeutics",
    ],
  },
  {
    primary_id: "D0S8MS",
    names: ["ONO-9910539"],
  },
  {
    primary_id: "D0S8NM",
    names: ["SB290157"],
  },
  {
    primary_id: "D0S8QZ",
    names: ["PMID12614873C2b-1"],
  },
  {
    primary_id: "D0S8RD",
    names: [
      "Trecadrine",
      "90845-56-0",
      "AC1L241B",
      "SCHEMBL10553786",
      "HY-U00303",
      "CS-7288",
      "KB-124998",
      "L001625",
      "Sulfenamido-Sulfonamides",
    ],
  },
  {
    primary_id: "D0S8RG",
    names: ["Nasalflu Berna"],
  },
  {
    primary_id: "D0S8RN",
    names: ["IPN-100"],
  },
  {
    primary_id: "D0S8RY",
    names: ["GP-531"],
  },
  {
    primary_id: "D0S8TB",
    names: ["Carbamate Derivative 2"],
  },
  {
    primary_id: "D0S8TD",
    names: [
      "Methohexital",
      "Brevital",
      "Brietal",
      "Enallynymall",
      "Enallynymalum",
      "Methodrexitone",
      "Methohexitalum",
      "Methohexitone",
      "Metoesital",
      "Metohexital",
      "Metoesital [DCIT]",
      "Brevital (TN)",
      "Methohexital, Monosodium Salt",
      "Methohexitalum [INN-Latin]",
      "Metohexital [INN-Spanish]",
      "Methohexital (USP/INN)",
      "Alpha-DL-1-Methyl-5-Allyl-5-(1'-Methylpentyn-2-Yl)Barbituricacid",
      "(+-)-5-Allyl-1-Methyl-5-(1-Methyl-2-Pentynyl)Barbituric Acid",
      "1-Methyl-5-(1-Methylpent-2-Yn-1-Yl)-5-(Prop-2-En-1-Yl)Pyrimidine-2,4,6(1H,3H,5H)-Trione",
      "1-Methyl-5-(1-Methylpent-2-Yn-1-Yl)-5-Prop-2-En-1-Ylpyrimidine-2,4,6(1H,3H,5H)-Trione",
      "5-Allyl-1-Methyl-5-(1-Methyl-2-Pentynyl)-2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "5-Allyl-1-Methyl-5-(1-Methyl-Pent-2-Ynyl)-Pyrimidine-2,4,6-Trione",
      "5-Allyl-5-(3-Hexyn-2-Yl)-1-Methylbarbituric Acid",
      "5-Hex-3-Yn-2-Yl-1-Methyl-5-Prop-2-Enyl-1,3-Diazinane-2,4,6-Trione",
    ],
  },
  {
    primary_id: "D0S8UN",
    names: ["12-Phenylheme"],
  },
  {
    primary_id: "D0S8UP",
    names: [
      "3,4-Dihydroxy-10H-Anthracen-9-One",
      "CHEMBL121693",
      "1,2-Dihydroxy-10-Anthrone",
      "SCHEMBL5034789",
      "KBOKOYAHHLPGSJ-UHFFFAOYSA-N",
      "BDBM50060872",
      "ZINC13606495",
      "3,4-Dihydroxyanthracen-9(10H)-One",
    ],
  },
  {
    primary_id: "D0S8VE",
    names: ["MERIOLIN 3"],
  },
  {
    primary_id: "D0S8VG",
    names: ["PGL-3001"],
  },
  {
    primary_id: "D0S8WA",
    names: ["Obinutuzumab"],
  },
  {
    primary_id: "D0S8XP",
    names: ["Canagliflozin/Metformin"],
  },
  {
    primary_id: "D0S8YD",
    names: [
      "Decylamine-N,N-Dimethyl-N-Oxide",
      "2605-79-0",
      "N,N-Dimethyldecylamine N-Oxide",
      "N,N-Dimethyldecylamine Oxide",
      "1-Decanamine, N,N-Dimethyl-, N-Oxide",
      "DECYLAMINE-N,N-DIMETHYL-N-OXIDE",
      "Decyl(Dimethyl)Amine Oxide",
      "UNII-G387VUT5EZ",
      "Capric Dimethyl Amine Oxide",
      "N,N-Dimethyldecylamine-N-Oxide",
      "N,N-Dimethyldecan-1-Amine Oxide",
      "Decyl Dimethyl Amine Oxide",
      "N,N-Dimethyldecanamine Oxide",
      "G387VUT5EZ",
      "CHEBI:41899",
      "N,N-Dimethyl-1-Decanamine-N-Oxide",
      "1-Decanamine,N,N-Dimethyl-, N-Oxide",
      "Alkyldimethylamine Oxides, Commercial Grade",
      "3nok",
      "EINECS 220-020-5",
      "Decyldimethylamine Oxide",
    ],
  },
  {
    primary_id: "D0S8YL",
    names: ["Tomelukast"],
  },
  {
    primary_id: "D0S8YV",
    names: ["HGP-30"],
  },
  {
    primary_id: "D0S8ZJ",
    names: ["3alpha-(1'-Methyl-2'-Indolecarbonyloxy)-Tropane", "CHEMBL593963", "BDBM50304334"],
  },
  {
    primary_id: "D0S9AT",
    names: ["KML110"],
  },
  {
    primary_id: "D0S9AW",
    names: ["Rifamycin B"],
  },
  {
    primary_id: "D0S9AY",
    names: ["1-(2-(6-Fluoronaphthalen-2-Yl)Ethyl)Piperazine", "CHEMBL465515"],
  },
  {
    primary_id: "D0S9BH",
    names: ["Ozarelix"],
  },
  {
    primary_id: "D0S9CB",
    names: ["Flibanserin"],
  },
  {
    primary_id: "D0S9CI",
    names: ["N',2-Diphenylquinoline-4-Carbohydrazide 8m", "Compound 8m [PMID:16950617]"],
  },
  {
    primary_id: "D0S9EK",
    names: ["LIlotomab Satetraxetan"],
  },
  {
    primary_id: "D0S9ET",
    names: ["Pirprofen"],
  },
  {
    primary_id: "D0S9FI",
    names: ["ICasp9M28z T Cell"],
  },
  {
    primary_id: "D0S9FU",
    names: ["Simotinib"],
  },
  {
    primary_id: "D0S9GJ",
    names: [
      "AE-443p",
      "AE-AI",
      "Avian Influenza Vaccine (Ii-Key/H5 Hybrid), Antigen Express",
      "H5N1 Influenza Vaccine (Ii-Key/H5 Hybrid), Antigen Express",
    ],
  },
  {
    primary_id: "D0S9GU",
    names: ["Abyssinoflavanone VII", "CHEMBL229220"],
  },
  {
    primary_id: "D0S9HE",
    names: ["PMID27724045-Compound-29"],
  },
  {
    primary_id: "D0S9HJ",
    names: [
      "Quorum Sensing Blockers",
      "Bacterial Biofilm Inhibitors, 4SC",
      "Quorum Sensing Blockers (Antibacterials, Cystic Fibrosis/Opthalmology/Wounds)",
      "Quorum Sensing Blockers (Antibacterials, Cystic Fibrosis/Opthalmology/Wounds), 4SC",
      "Quorum Sensing Blockers (Antibacterials, Cystic Fibrosis/Opthalmology/Wounds), Quiescence",
      "Quorum Sensing Blockers (Antibacterials, Cystic Fibrosis/Opthalmology/Wounds), QuoNova",
    ],
  },
  {
    primary_id: "D0S9HV",
    names: ["MitoAscorbate"],
  },
  {
    primary_id: "D0S9IE",
    names: ["BAY1125976"],
  },
  {
    primary_id: "D0S9IO",
    names: ["N-(2-Oxazolemethyl)Milnacipran", "CHEMBL256408"],
  },
  {
    primary_id: "D0S9JC",
    names: ["4-Chloro-N-(2-Morpholinoethyl)Nicotinamide", "CHEMBL598187", "BDBM50307210", "AKOS019796958"],
  },
  {
    primary_id: "D0S9JF",
    names: ["6-Phenylsulfanylhexanoic Acid Hydroxamide"],
  },
  {
    primary_id: "D0S9JG",
    names: ["NVP 231"],
  },
  {
    primary_id: "D0S9JH",
    names: ["PD142893"],
  },
  {
    primary_id: "D0S9KH",
    names: [
      "OXDEX",
      "Dexamethasone-17-Carboxamide",
      "CHEMBL483096",
      "82137-90-4",
      "DX-17-Carboxamide",
      "17-Dxb",
      "17-Carboxamide Dexamethasone",
      "Dexamethasone-17beta-Carboxamide",
      "AC1L1YA1",
      "DTXSID30231582",
      "BDBM50271344",
      "Androsta-1,4-Diene-17-Carboxamide, 9-Fluoro-11,17-Dihydroxy-16-Methyl-3-Oxo-, (11beta,16alpha,17alpha)-",
      "(8S,9R,10S,11S,13S,14S,16R,17R)-9-Fluoro-11,17-Dihydroxy-10,13,16-Trimethyl-3-Oxo-6,7,8,11,12,14,15,16-Octahydrocyclopenta[a]phenanthrene-17-Carboxamide",
    ],
  },
  {
    primary_id: "D0S9KM",
    names: ["NGD 91-3"],
  },
  {
    primary_id: "D0S9LK",
    names: ["GSK-2584786A"],
  },
  {
    primary_id: "D0S9LR",
    names: ["Beclomethasone Dispropionate"],
  },
  {
    primary_id: "D0S9LT",
    names: ["Phenylphosphate"],
  },
  {
    primary_id: "D0S9MC",
    names: [
      "Clobazam - Lundbeck",
      "Clobazam",
      "Chlorepin",
      "Urbanyl",
      "Frisium",
      "Clorepin",
      "Urbadan",
      "22316-47-8",
      "Clobazamum",
      "Onfi",
      "LM-2717",
      "HR 376",
      "RU-4723",
      "Clobazamum [INN-Latin]",
      "LM 2717",
      "H-4723",
      "H 4723",
      "7-Chloro-1-Methyl-5-Phenyl-1H-1,5-Benzodiazepine-2,4(3H,5H)-Dione",
      "UNII-2MRO291B4U",
      "1H-1,5-Benzodiazepine-2,4(3H,5H)-Dione, 7-Chloro-1-Methyl-5-Phenyl-",
      "NSC 336279",
      "1-Phenyl-5-Methyl-8-Chloro-1,2,4,5-Tetrahydro-2,4-Dioxo-3H-1,5-Benzodiazepine",
      "CCRIS 7506",
      "7-Chloro-1-Methyl-5-Phenyl-1,5-Benzodiazepine-2,4-Dione",
      "EINECS 244-908-7",
      "BRN 0758410",
    ],
  },
  {
    primary_id: "D0S9MD",
    names: ["DFP-11207"],
  },
  {
    primary_id: "D0S9MO",
    names: ["Modified BCG Vaccine"],
  },
  {
    primary_id: "D0S9MR",
    names: ["Azetidine-Benzoxazin-3(4H)-One Derivative 2"],
  },
  {
    primary_id: "D0S9MU",
    names: ["Rizatriptan"],
  },
  {
    primary_id: "D0S9NB",
    names: ["2-Pyrazinone Derivative 4"],
  },
  {
    primary_id: "D0S9OH",
    names: ["L-MDAM"],
  },
  {
    primary_id: "D0S9OL",
    names: ["N-Hydroxy-2-(4-Phenoxy-Benzenesulfonyl)Benzamide", "CHEMBL575896"],
  },
  {
    primary_id: "D0S9PC",
    names: ["H-Arg-N-Me-Arg-Pro-Tyr-Ile-Leu-OH", "CHEMBL443967"],
  },
  {
    primary_id: "D0S9PV",
    names: ["Type VII Collagen Therapeutic"],
  },
  {
    primary_id: "D0S9QA",
    names: ["Ivabradine"],
  },
  {
    primary_id: "D0S9QJ",
    names: ["4-IPP"],
  },
  {
    primary_id: "D0S9QP",
    names: ["4-Biphenyl-2-Ylethynyl-6,7-Dimethoxy-Quinazoline", "CHEMBL250924", "BDBM50222419"],
  },
  {
    primary_id: "D0S9SD",
    names: [
      "Emtricitabine",
      "Coviracil",
      "DOTFC",
      "Emtriva",
      "RCV",
      "Racivir",
      "BW 1592",
      "BW 524W91",
      "BW524W91",
      "BW-524W91",
      "Coviracil (TN)",
      "Coviracil(TM)",
      "DRG-0208",
      "Emtriva(TM)",
      "Emtricitabine (JAN/USAN/INN)",
      "Beta-L-2',3'-Dideoxy-5-Fluoro-3'-Thiacytidine",
      "Beta-L-(-)-(2R,5S)-5-Fluoro-1-[2-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl]cytosine",
      "(-)-(2R,5S)-5-Fluoro-1-[2-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl]cytosine",
      "(-)-.beta.-L-FTC",
      "(-)-2',3'-Dideoxy-5-Fluoro-3'-Thiacytidine",
      "(-)-2'-Deoxy-5-Fluoro-3'-Thiacytidine",
      "(-)-FTC",
      "(-)-Beta-2',3'-Dideoxy-5-Fluoro-3'-Thiacytidine",
      "(-)-Cis-4-Amino-5-Fluoro-1-(2-Hydroxymethyl-1,3-Oxathiolan-5-Yl)-(1H)-Pyrimidin-2-One",
      "(2R-Cis)-4-Amino-5-Fluoro-1-(2-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl)-2(1H)-Pyrimidinone",
      "1-(2-(Hydroxymethyl)Oxathiolan-5-Yl)-5-Fluorocytosine",
      "2',3',5-FTC",
      "2',3'-Dideoxy-5-Fluoro-3'-Thiacytidine",
      "2'-Deoxy-5-Fluoro-3'-Oxa-4'-Thiocytidine",
      "2'-Deoxy-5-Fluoro-3'-Thiacytidine",
      "2-FTC",
      "3'-Thia-2'.3'-Dideoxy-5-Fluorocytidine",
      "4-Amino-5-Fluoro-1-[(2R,5S)-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl]-2(1H)-Pyrimidinone",
      "4-Amino-5-Fluoro-1-[(2R,5S)-2-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl]pyrimidin-2(1H)-One",
      "4-Amino-5-Fluoro-1-[(2R,5S)-2-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl]pyrimidin-2-One",
      "5-Fluoro-1-((2R,5S)-2-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl)Cytosine",
      "5-Fluoro-1-(2-(Hydroxymethyl)-1,3-Oxathiolan-5-Yl)Cytosine",
      "5-Fluoro-1-[(2R,5S)-2-(Hydroxymethyl)[1,3]oxathiolan-5-Yl]cytosine",
      "524W91",
      "FTC",
    ],
  },
  {
    primary_id: "D0S9SJ",
    names: ["SGC0946"],
  },
  {
    primary_id: "D0S9SQ",
    names: ["JCAR015"],
  },
  {
    primary_id: "D0S9SX",
    names: ["4-(2-Ethyl-4-M-Tolyl-Thiazol-5-Yl)-Pyridine", "CHEMBL198334", "BDBM50173628"],
  },
  {
    primary_id: "D0S9TZ",
    names: [
      "YM298198",
      "7-Amino-N-Cyclohexyl-N,1-Dimethyl-[1,3]thiazolo[3,2-A]benzimidazole-2-Carboxamide",
      "YM-298198",
      "748758-45-4",
      "[3H]YM298198",
      "[3H]-YM298198",
      "SCHEMBL3371668",
      "GTPL1389",
      "GTPL5392",
      "CHEMBL1517556",
      "KS-00002WSU",
      "CHEBI:93046",
      "MolPort-016-581-183",
      "ZINC11687445",
      "AKOS015994566",
      "ES-0033",
      "NCGC00161412-01",
      "L023814",
      "BRD-K59650319-003-01-7",
    ],
  },
  {
    primary_id: "D0S9UH",
    names: ["ZYKLOPHIN"],
  },
  {
    primary_id: "D0S9UK",
    names: ["2,3,3-Triphenyl-Acrylonitrile"],
  },
  {
    primary_id: "D0S9UO",
    names: ["SPI-3608"],
  },
  {
    primary_id: "D0S9UQ",
    names: ["GMQ", "2-Guanidine-4-Methylquinazoline", "GNF-Pf-3515", "2-GMQ"],
  },
  {
    primary_id: "D0S9VG",
    names: ["C4X-101"],
  },
  {
    primary_id: "D0S9WB",
    names: ["Lumacaftor + Ivacaftor"],
  },
  {
    primary_id: "D0S9WV",
    names: [
      "Pyrazine-2-Carboxylic Acid Adamantan-1-Ylamide",
      "CHEMBL253348",
      "BAS 03365795",
      "AC1MG7YQ",
      "Cambridge Id 7248781",
      "Oprea1_265306",
      "Oprea1_647087",
      "MLS000088554",
      "Cid_2931812",
      "MolPort-001-540-487",
      "HMS2379I12",
      "ZINC4197514",
      "N-Adamantanylpyrazin-2-Ylcarboxamide",
      "N-1-Adamantyl-2-Pyrazinecarboxamide",
      "BDBM50231727",
      "STK431969",
      "AKOS000665660",
      "N-(1-Adamantyl)Pyrazine-2-Carboxamide",
      "SDCCGMLS-0024327.P002",
      "MCULE-4724742312",
      "SMR000071836",
      "ST50923663",
    ],
  },
  {
    primary_id: "D0S9WW",
    names: ["GI-6301"],
  },
  {
    primary_id: "D0S9XM",
    names: ["Sclerostin-Targeted Small-Molecule Therapeutics"],
  },
  {
    primary_id: "D0S9XO",
    names: ["Mercaptoacrylate Inhibitor Of Calpain 1"],
  },
  {
    primary_id: "D0S9XR",
    names: ["TRx-0037"],
  },
  {
    primary_id: "D0S9XV",
    names: [
      "A-134974",
      "GTPL5130",
      "CHEMBL1591918",
      "ZINC3827162",
      "SMP2_000021",
      "NCGC00186045-01",
      "A134974",
      "A 134974",
    ],
  },
  {
    primary_id: "D0S9YA",
    names: [
      "2'-Hydroxychalcone",
      "6'-Hydroxychalcone",
      "ACMC-1BLFY",
      "2-HYDROXY CHALCONE",
      "2-Propen-1-One, 1-(Hydroxyphenyl)-3-Phenyl-",
      "SCHEMBL126857",
      "CHEMBL1867065",
      "CTK1E5803",
      "CTK4B2371",
      "MolPort-006-109-326",
      "AETKQQBRKSELEL-UHFFFAOYSA-N",
      "AC1L2476",
      "ACT07806",
      "CMLD2_000189",
      "ANW-17633",
      "MCULE-2929659613",
      "TRA0032483",
      "AN-6742",
      "NCGC00017803-05",
      "NCGC00017803-04",
      "DB-021203",
      "FT-0669974",
      "FT-0612588",
      "2-Propen-1-One,1-(2-Hydroxyphenyl)-3-Phenyl-",
      "1-(2-Hydroxyphenyl)-3-Phenyl-Prop-2-En-1-One",
    ],
  },
  {
    primary_id: "D0S9YE",
    names: [
      "(Beta-CCE)9H-Beta-Carboline-3-Carboxylic Acid",
      "Carboline-3-Carboxylic Acid",
      "9H-Pyrido[3,4-B]indole-3-Carboxylic Acid",
      "74214-63-4",
      "9H-Beta-Carboline-3-Carboxylic Acid",
      "Beta-Carboline-3-Carboxylic Acid",
      "NSC623957",
      "9H-Pyrido(3,4-B)Indole-3-Carboxylic Acid",
      "Beta-Carboline Carboxylic Acid",
      "3-Carboxy-Beta-Carboline",
      "AC1L59WQ",
      "MLS001198085",
      "CHEMBL11908",
      "B-Carboline-3-Carboxylic Acid",
      "SCHEMBL1180278",
      "Beta-Carbolin-3-Carboxylic Acid",
      "DTXSID30225209",
      "MolPort-000-872-217",
      "ARLVFKCLBYUINL-UHFFFAOYSA-N",
      "HMS3433I21",
      "HMS1620B11",
      "ZINC20104435",
      "AKOS021730281",
      "CCG-125106",
      "NSC-623957",
    ],
  },
  {
    primary_id: "D0S9YP",
    names: [
      "ZP-103",
      "AcGnG-NM",
      "SLeX Biosynthesis Inhibitor (Tumor Metastasis), Zacharon",
      "Sialyl Lewis X Biosynthesis Inhibitor (Tumor Metastasis),Zacharon",
      "Acylated GlcNAcb3Galb-O-NM (Metastasis), Zacharon",
      "Per-O-Acetylated GlcNAc|A1,3Gal|A-O-Naphthalenemethanol (Metastasis), Zacharon",
    ],
  },
  {
    primary_id: "D0S9YX",
    names: [
      "Biochanin A",
      "491-80-5",
      "Biochanin",
      "4'-Methylgenistein",
      "5,7-Dihydroxy-4'-Methoxyisoflavone",
      "5,7-Dihydroxy-3-(4-Methoxyphenyl)-4H-Chromen-4-One",
      "Genistein 4-Methyl Ether",
      "5,7-Dihydrox -4'-Methoxyisoflavone",
      "Biochanine A",
      "4H-1-Benzopyran-4-One, 5,7-Dihydroxy-3-(4-Methoxyphenyl)-",
      "Olmelin",
      "Pratensol",
      "NSC 123538",
      "Biochanin-A",
      "5,7-Dihydroxy-3-(4-Methoxyphenyl)Chromen-4-One",
      "4-Methylgenistein",
      "C16H12O5",
      "UNII-U13J6U390T",
      "CCRIS 5449",
      "5,7-Dihydroxy-3-P-Methoxyphenyl-4H-Chromen-4-One",
      "EINECS 207-744-7",
      "NSC123538",
      "Genistein 4'-Methyl Ether",
      "BIOCHANIN",
    ],
  },
  {
    primary_id: "D0SA1G",
    names: [
      "2-N-Ethylamino-1-(4-Methylthiophenyl)Propane",
      "CHEMBL1078039",
      "634607-27-5",
      "LAWDOCPCCFOXQO-UHFFFAOYSA-N",
      "BDBM50310856",
    ],
  },
  {
    primary_id: "D0SA1N",
    names: ["PMID28454500-Compound-37"],
  },
  {
    primary_id: "D0SA3J",
    names: ["Ibrolipim"],
  },
  {
    primary_id: "D0SA4A",
    names: ["Meta-Chlorphenylbiguanide"],
  },
  {
    primary_id: "D0SA6K",
    names: ["Biphenyl Carboxamidopropanoic Acid Derivative 1"],
  },
  {
    primary_id: "D0SA6L",
    names: ["Aminopyrimidine Derivative 8"],
  },
  {
    primary_id: "D0SB0W",
    names: [
      "8-(2,4-Dimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL421594",
      "AC1L9X4S",
      "SCHEMBL2947301",
      "BDBM50059921",
      "8-(2,4-Dimethoxyphenyl)-7H-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D0SB0X",
    names: ["CX-1040"],
  },
  {
    primary_id: "D0SB1B",
    names: ["CPX-351"],
  },
  {
    primary_id: "D0SB1W",
    names: ["PMID28766366-Compound-Scheme4Bottom"],
  },
  {
    primary_id: "D0SB2G",
    names: ["EP-1300"],
  },
  {
    primary_id: "D0SB2U",
    names: ["MVA-BN Brachyury Vaccine"],
  },
  {
    primary_id: "D0SB3H",
    names: ["PMID26924192-Compound-32"],
  },
  {
    primary_id: "D0SB4A",
    names: ["PMID25684022-Compound-US20130053382 35(1-11)"],
  },
  {
    primary_id: "D0SB5H",
    names: ["2-Methyl-[1,4]thiazepan-(5E)-Ylideneamine", "CHEMBL187008", "BDBM50155783"],
  },
  {
    primary_id: "D0SB5K",
    names: ["SAR164877"],
  },
  {
    primary_id: "D0SB6K",
    names: ["DAT-582", "AS-5820"],
  },
  {
    primary_id: "D0SB6Y",
    names: ["D-211A", "CHEMBL263636", "SCHEMBL10291414"],
  },
  {
    primary_id: "D0SB7U",
    names: ["PMID23084894C10i"],
  },
  {
    primary_id: "D0SB8E",
    names: ["UTL-5g"],
  },
  {
    primary_id: "D0SB8X",
    names: ["FluNhance"],
  },
  {
    primary_id: "D0SB9H",
    names: ["Joro Toxin"],
  },
  {
    primary_id: "D0SC1I",
    names: ["S. Pneumoniae Paediatric Next Generation"],
  },
  {
    primary_id: "D0SC2J",
    names: [
      "Tanespimycin",
      "CNF-101",
      "[(3S,5S,6R,7S,8E,10R,11S,12E,14E)-6-Hydroxy-5,11-Dimethoxy-3,7,9,15-Tetramethyl-16,20,22-Trioxo-21-(Prop-2-Enylamino)-17-Azabicyclo[16.3.1]docosa-8,12,14,18,21-Pentaen-10-Yl] Carbamate",
      "[(3R,5S,6R,7S,8E,10S,11S,12Z,14E)-6-Hydroxy-5,11-Dimethoxy-3,7,9,15-Tetramethyl-16,20,22-Trioxo-21-(Prop-2-Enylamino)-17-Azabicyclo[16.3.1]docosa-1(21),8,12,14,18-Pentaen-10-Yl] Carbamate",
      "17AAG, Tanespimycin, Geldanamycin, NSC330507, 17-(Allylamino)-17-Demethoxygeldanamycin, CP 127374",
    ],
  },
  {
    primary_id: "D0SC3B",
    names: ["Palmarumycin Derivative 2"],
  },
  {
    primary_id: "D0SC3W",
    names: ["Bis-((-)-N-Propargylmorphinan-3-Yl) Sebacoylate", "CHEMBL372823"],
  },
  {
    primary_id: "D0SC4X",
    names: ["Zaragozic Acid D2"],
  },
  {
    primary_id: "D0SC5V",
    names: [
      "(+/-)-2-Aminoindane-2-Carboxylic Acid",
      "27473-62-7",
      "2-Amino-2-Indancarboxylic Acid",
      "2-Amino-2,3-Dihydro-1H-Indene-2-Carboxylic Acid",
      "2-Aminoindane-2-Carboxylic Acid",
      "2-AMINOINDAN-2-CARBOXYLIC ACID",
      "2-Aminoindan-2-Carboxylicacid",
      "2-Amino-Indan-2-Carboxylic Acid",
      "2-Amino-1,3-Dihydroindene-2-Carboxylic Acid",
      "CHEMBL1082953",
      "2-Azanyl-1,3-Dihydroindene-2-Carboxylic Acid",
      "1H-Indene-2-Carboxylicacid, 2-Amino-2,3-Dihydro-",
      "2-Amino-2-Indancarboxylicacid",
      "NSC70943",
      "AC1L5IQE",
      "AC1Q50FV",
      "AC1Q5UK7",
      "SCHEMBL122942",
      "1H-Indene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0SC6I",
    names: ["Sodium Benzoate"],
  },
  {
    primary_id: "D0SC7C",
    names: [
      "2',3'-Dideoxythymidine-5'-Monophosphate",
      "DdTMP",
      "5'-Thymidylic Acid, 3'-Deoxy-",
      "2',3'-DIDEOXYTHYMIDINE-5'-MONOPHOSPHATE",
      "3'-DEOXYTHYMIDINE-5'-MONOPHOSPHATE",
      "[(2s,5r)-5-(5-Methyl-2,4-Dioxo-3,4-Dihydropyrimidin-1(2h)-Yl)Tetrahydrofuran-2-Yl]methyl Dihydrogen Phosphate",
      "3715-64-8",
      "2DT",
      "3'-Deoxy-5'-Thymidylic Acid",
      "AC1Q69EO",
      "AC1L4W6C",
      "3-Deoxy-5-Thymidylic Acid",
      "SCHEMBL8883",
      "Deoxy-Thymidine Monophosphate",
      "CHEMBL1229914",
      "CTK4H7825",
      "DTXSID20190634",
      "ZINC1610269",
      "DB03150",
      "2',3'-Dideoxythymidine-5'-Monophosphate Disodium Salt",
    ],
  },
  {
    primary_id: "D0SC8F",
    names: ["Ethylestrenol"],
  },
  {
    primary_id: "D0SD2J",
    names: ["INP104"],
  },
  {
    primary_id: "D0SD3E",
    names: ["PF-05186462"],
  },
  {
    primary_id: "D0SD3Q",
    names: ["GIDAZEPAM", "2-(7-Bromo-2-Oxo-5-Phenyl-2,3-Dihydro-1H-1,4-Benzodiazepin-1-Yl)Acetic Acid Hydrazide"],
  },
  {
    primary_id: "D0SD4G",
    names: ["PMID25666693-Compound-16"],
  },
  {
    primary_id: "D0SD4O",
    names: [
      "2-N-Methylamino-1-(4-Methylthiophenyl)Propane",
      "CHEMBL1078038",
      "WWHIYWVWPXXBTC-UHFFFAOYSA-N",
      "BDBM50310855",
    ],
  },
  {
    primary_id: "D0SD4Q",
    names: ["Aryl Mannoside Derivative 11"],
  },
  {
    primary_id: "D0SD6V",
    names: ["CFZ533"],
  },
  {
    primary_id: "D0SD7G",
    names: ["LY3002813"],
  },
  {
    primary_id: "D0SD8Q",
    names: [
      "BMS-193885",
      "UNII-819SRG2Y6N",
      "819SRG2Y6N",
      "186185-03-5",
      "1,4-Dihydro-4-[3-[[[[3-[4-(3-Methoxyphenyl)-1-Piperidinyl]propyl]amino]carbonyl]amino]phenyl]-2,6-Dimethyl-3,5-Dimethyl Ester-3,5-Pyridinedicarboxylic Acid",
      "HMS3263K13",
      "Tox21_501016",
      "ZINC27088632",
      "CCG-222320",
      "NCGC00261701-01",
      "NCGC00485144-01",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-4-(3-((((3-(4-(3-Methoxyphenyl)-1-Piperidinyl)Propyl)Amino)Carbonyl)Amino)Phenyl)-2,6-Dimethyl-, Dimethyl Ester",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-4-(3-((((3-(4-(3-Methoxyphen",
    ],
  },
  {
    primary_id: "D0SD8V",
    names: ["TDI-0047"],
  },
  {
    primary_id: "D0SD9H",
    names: [
      "Beta-D-Mannose",
      "Beta-D-Mannopyranose",
      "Beta-Mannose",
      "7322-31-8",
      "UNII-CUO87O37MT",
      "(2R,3S,4S,5S,6R)-6-(Hydroxymethyl)Oxane-2,3,4,5-Tetrol",
      "CUO87O37MT",
      "CHEBI:28563",
      "Beta-1,4-Mannan",
      "Epitope ID:137485",
      "(1-&gt",
      "2)-Beta-D-Mannan",
      "4)-Beta-D-Mannan",
      "AC1L97TQ",
      "SCHEMBL396651",
      "2)-Beta-D-Mannopyranan",
      "CHEBI:80962",
      "CHEBI:59573",
      "CHEBI:27857",
      "[2)-Beta-D-Manp-(1-&gt",
      "WQZGKKKJIJFFOK-RWOPYEJCSA-N",
      "ZINC3830679",
      "GUP",
      "NE16895",
      "C02209",
      "WURCS=2.0/1,1,0/[a1122h-1b_1-5]/1/",
      "(2RS,3S,4S,5S,6R)-6-(Hydroxymethyl)Tetrahydropyran-2,3,4,5-Tetrol",
    ],
  },
  {
    primary_id: "D0SD9L",
    names: ["Monoamine Derivative 5"],
  },
  {
    primary_id: "D0SD9R",
    names: ["ARI-0001 Cells"],
  },
  {
    primary_id: "D0SD9V",
    names: ["Flupirtine"],
  },
  {
    primary_id: "D0SD9Z",
    names: ["TRC-253"],
  },
  {
    primary_id: "D0SE1R",
    names: [
      "4'-(P-Toluenesulfonamide)-4-Hydroxychalcone",
      "CHEMBL371723",
      "TSAHC",
      "Sulfonamide Chalcone, 4",
      "SCHEMBL3483827",
      "BDBM50174835",
      "4''-(P-Toluenesulfonamide)-4-Hydroxychalcone",
      "4''-(4-Toluenesulfonamido)-4-Hydroxychalcone",
    ],
  },
  {
    primary_id: "D0SE2D",
    names: ["6-(4-Chlorophenylamino)-N,N-Diethylnicotinamide", "CHEMBL596589", "BDBM50305021"],
  },
  {
    primary_id: "D0SE3C",
    names: ["BS 7581", "CHEMBL444541", "CHEMBL1790046"],
  },
  {
    primary_id: "D0SE4D",
    names: ["Org-20599"],
  },
  {
    primary_id: "D0SE7V",
    names: ["Alkyl Mannoside Derivative 3"],
  },
  {
    primary_id: "D0SE8T",
    names: ["(2-Butyryloxy-Ethyl)-Trimethyl-Ammonium Iodide"],
  },
  {
    primary_id: "D0SE9K",
    names: ["Pyrimidine Derivative 28"],
  },
  {
    primary_id: "D0SE9M",
    names: ["PMID23434029C53"],
  },
  {
    primary_id: "D0SE9S",
    names: ["SCH-221510"],
  },
  {
    primary_id: "D0SF0U",
    names: ["HS-7"],
  },
  {
    primary_id: "D0SF1E",
    names: ["PMID25435285-Compound-22"],
  },
  {
    primary_id: "D0SF2R",
    names: ["GVX-3322", "Anticancer (DNA Vaccine), Genvax", "DNA Vaccine (Tetanus Toxin-Antigen Fusion),Genvax"],
  },
  {
    primary_id: "D0SF3B",
    names: [
      "2-Butylamido-5-Sulfonamidoindane",
      "CHEMBL208531",
      "Indanesulfonamide Derivative 12f",
      "N-(5-Sulfamoyl-2,3-Dihydro-1H-Inden-2-Yl)Pentanamide",
    ],
  },
  {
    primary_id: "D0SF5F",
    names: ["Org-34092"],
  },
  {
    primary_id: "D0SF5Q",
    names: ["Pennvax-B"],
  },
  {
    primary_id: "D0SF6C",
    names: ["P-Coumaric Acid Derivative 2"],
  },
  {
    primary_id: "D0SF8N",
    names: ["PMID28092474-Compound-33l"],
  },
  {
    primary_id: "D0SF8V",
    names: ["RO7082859"],
  },
  {
    primary_id: "D0SF9Z",
    names: ["PMID22266036C9a"],
  },
  {
    primary_id: "D0SG0G",
    names: ["5,6,7-Trimethoxy-3-Pyridin-4-Yl-Quinoline", "CHEMBL86717", "BDBM50039649"],
  },
  {
    primary_id: "D0SG0N",
    names: [
      "DB-200",
      "DB-200 Series (Topical, Psoriasis)",
      "CPT-1 Inhibitor (Topical, Psoriasis), DARA",
      "DB-200 Series (Topical, Psoriasis), DARA BioSciences",
    ],
  },
  {
    primary_id: "D0SG2L",
    names: ["PMID28074661-Compound-US20120264760C81"],
  },
  {
    primary_id: "D0SG4W",
    names: ["Oral Insulin"],
  },
  {
    primary_id: "D0SG5A",
    names: ["FGGFTGARKSARKRWNQ"],
  },
  {
    primary_id: "D0SG6A",
    names: [
      "Amidephrine",
      "Amidefrine",
      "Amidefrine Mesilate",
      "UNII-U56LDN9FFT",
      "37571-84-9",
      "UNII-NI24N176MY",
      "U56LDN9FFT",
      "NI24N176MY",
      "3354-67-4",
      "Amidephrine, (+)-",
      "N-(3-(1-Hydroxy-2-(Methylamino)-Ethyl)Phenyl)Methanesulfonamide",
      "Amidephrine, (-)-",
      "Amidefrine [INN:BAN]",
      "Amidefrin",
      "AC1L24YQ",
      "GTPL514",
      "SCHEMBL122479",
      "CHEMBL146408",
      "ZHOWHMXTJFZXRB-UHFFFAOYSA-N",
      "CHEBI:134994",
      "N-[3-[1-Hydroxy-2-(Methylamino)Ethyl]phenyl]methanesulfonamide",
      "BDBM50225291",
      "Methanesulfonamide, N-(3-(1-Hydroxy-2-(Methylamino)Ethyl)Phenyl)-, (-)-",
      "Methanesulfonamide, N-(3-(1-Hy",
    ],
  },
  {
    primary_id: "D0SG6Q",
    names: ["PMID19433355C11q"],
  },
  {
    primary_id: "D0SG7B",
    names: ["GLPG-0634"],
  },
  {
    primary_id: "D0SG8X",
    names: ["PMID30124346-Compound-LDT66"],
  },
  {
    primary_id: "D0SG9F",
    names: ["Isoxazole-Based Bicyclic Compound 1"],
  },
  {
    primary_id: "D0SG9W",
    names: ["PMID30185082-Compound-64"],
  },
  {
    primary_id: "D0SH0L",
    names: ["PMID27537201-Compound-Figure15b"],
  },
  {
    primary_id: "D0SH0M",
    names: ["PMID19800804C12"],
  },
  {
    primary_id: "D0SH1L",
    names: ["1-Benzhydryl-3-(Isoquinolin-5-Yl)Urea"],
  },
  {
    primary_id: "D0SH2X",
    names: ["Amocarzine"],
  },
  {
    primary_id: "D0SH2Y",
    names: ["Quinoline And Quinazoline Derivative 9"],
  },
  {
    primary_id: "D0SH3I",
    names: [
      "Bortezomib",
      "179324-69-7",
      "Velcade",
      "Bortezomib (PS-341)",
      "UNII-69G8BD63PP",
      "N-[(1R)-1-(DIHYDROXYBORYL)-3-METHYLBUTYL]-N-(PYRAZIN-2-YLCARBONYL)-L-PHENYLALANINAMIDE",
      "MLN-341",
      "[(1R)-3-Methyl-1-[[(2S)-3-Phenyl-2-(Pyrazine-2-Carbonylamino)Propanoyl]amino]butyl]boronic Acid",
      "[(1R)-3-Methyl-1-[[(2S)-1-Oxo-3-Phenyl-2-[(Pyrazinylcarbonyl)Amino]propyl]amino]butyl]boronic Acid",
      "CHEMBL325041",
      "69G8BD63PP",
      "Boronic Acid,",
      "DPBA",
      "PROSCRIPT BORONIC ACID",
      "LPD 341",
      "LPD-341",
      "VELCADE (TN)",
      "Velcade (TN)",
      "Pyz-Phe-BoroLeu",
      "Bortezomib(JAN/USAN/INN)",
      "Velcade, MG-341, PS-341, Bortezomib",
      "N-[(1R)-1-(Dihydroxyboranyl)-3-Methylbutyl]-Nalpha-(Pyrazin-2-Ylcarbonyl)-L-Phenylalaninamide",
      "Bortezomib (Proteasome Inhibitor)",
      "Peptide Boronate",
    ],
  },
  {
    primary_id: "D0SH5O",
    names: ["FK-317"],
  },
  {
    primary_id: "D0SH7J",
    names: ["INO-4212"],
  },
  {
    primary_id: "D0SI1Z",
    names: ["SCLERON"],
  },
  {
    primary_id: "D0SI4C",
    names: ["KF-17625"],
  },
  {
    primary_id: "D0SI7S",
    names: ["5,6,7,8-Tetrahydroanthracene-1,4-Dione", "CHEMBL508233", "SCHEMBL11038167"],
  },
  {
    primary_id: "D0SI9L",
    names: ["Nicotinamide Riboside"],
  },
  {
    primary_id: "D0SJ1Q",
    names: ["Bis-Aminopyrimidine Derivative 4"],
  },
  {
    primary_id: "D0SJ1U",
    names: ["Beta-Phenyl-Alpha,beta-Unsaturated Carbonyl Derivative 8"],
  },
  {
    primary_id: "D0SJ2Q",
    names: ["OLEANOLIC_ACID"],
  },
  {
    primary_id: "D0SJ3W",
    names: [
      "2-Furan-2-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "CHEMBL3144716",
      "1,2-Dihydro-2-(2-Furylmethyl)-3H-Indazol-3-One",
      "120273-74-7",
      "CHEMBL268738",
      "BDBM50009008",
      "AKOS023543910",
    ],
  },
  {
    primary_id: "D0SJ4S",
    names: ["P-Coumaric Acid Derivative 5"],
  },
  {
    primary_id: "D0SJ6E",
    names: ["N,N-Dimethyl-2-(1-Tosyl-1H-Indol-3-Yl)Ethanamine", "CHEMBL248071"],
  },
  {
    primary_id: "D0SJ8Q",
    names: ["ERITORAN"],
  },
  {
    primary_id: "D0SK0L",
    names: ["PMID27109571-Compound-24"],
  },
  {
    primary_id: "D0SK1W",
    names: [
      "AS-602305",
      "AS-603831",
      "Oxytocin Receptor Antagonists (Premature Labor)",
      "Oxytocin Receptor Antagonists (Premature Labor), Merck Serono",
      "Oxytocin Receptor Antagonists (Premature Labor), Serono",
    ],
  },
  {
    primary_id: "D0SK2J",
    names: ["Palux"],
  },
  {
    primary_id: "D0SK3A",
    names: ["BIIL 260", "BIIL-260", "BIIL260"],
  },
  {
    primary_id: "D0SK3R",
    names: ["PMID28870136-Compound-39"],
  },
  {
    primary_id: "D0SK4Y",
    names: ["VLA-84"],
  },
  {
    primary_id: "D0SK5C",
    names: ["RO 244383"],
  },
  {
    primary_id: "D0SK8U",
    names: [
      "Dephospho Coenzyme A",
      "3'-Dephospho-CoA",
      "3-Dephospho-CoA",
      "3633-59-8",
      "3'-Dephospho-CoA(2-)",
      "CHEBI:57328",
      "Adenosine 5'-{3-[(3R)-3-Hydroxy-2,2-Dimethyl-4-Oxo-4-({3-Oxo-3-[(2-Sulfanylethyl)Amino]propyl}amino)Butyl] Diphosphate}",
    ],
  },
  {
    primary_id: "D0SK9J",
    names: ["AN-3661"],
  },
  {
    primary_id: "D0SK9K",
    names: ["PMID28092474-Compound-32i"],
  },
  {
    primary_id: "D0SL0K",
    names: ["Fused Ring Compound 1"],
  },
  {
    primary_id: "D0SL2V",
    names: ["Lanatoside C"],
  },
  {
    primary_id: "D0SL4P",
    names: ["Sharon-4000"],
  },
  {
    primary_id: "D0SL4Q",
    names: ["GSK1331268"],
  },
  {
    primary_id: "D0SL8J",
    names: ["Ascrolimus", "A-86281", "ABT-281", "ZK-248258"],
  },
  {
    primary_id: "D0SL8T",
    names: ["ISIS-FGFR4"],
  },
  {
    primary_id: "D0SL8X",
    names: [
      "Edoxaban Tosylate",
      "480449-71-6",
      "Edoxaban (Tosylate)",
      "Edoxaban TsOH",
      "Edoxaban Tosilate",
      "UNII-32W99UE810",
      "CHEBI:85975",
      "32W99UE810",
      "SAVAYSA",
      "Edoxaban Tosylate [USAN]",
      "Edoxaban TsOH Salt",
      "Edoxaban Monotosylate",
      "Edoxaban P-Toluenesulfonate",
      "Edoxaban 4-Toluenesulfonate",
      "SCHEMBL330918",
      "CHEMBL2105682",
      "DTXSID10197399",
      "ZLFZITWZOYXXAW-QXXZOGQOSA-N",
      "BCP14472",
      "C24H30ClN7O4S.C7H8O3S",
      "3601AH",
      "HY-10264A",
      "AKOS022186217",
      "CS-1332",
      "W-5624",
      "Ethanediamide, N1-(5-Chloro-2-Pyridinyl)-N2-((1S,2R,4S)-4-((Dimethylamino)Carbonyl)- 2-(((4,5,6,7",
    ],
  },
  {
    primary_id: "D0SM0J",
    names: ["LY-117018"],
  },
  {
    primary_id: "D0SM0W",
    names: [
      "AN2728",
      "906673-24-3",
      "4-((1-Hydroxy-1,3-Dihydrobenzo[c][1,2]oxaborol-5-Yl)Oxy)Benzonitrile",
      "UNII-Q2R47HGR7P",
      "5-(4-Cyanophenoxy)-2,3-Dihydro-1-Hydroxy-2,1-Benzoxaborole",
      "Crisaborole Topical Ointment 2%",
      "Q2R47HGR7P",
      "CHEMBL484785",
      "AK102641",
      "4-((1-HYDROXY-1,3-DIHYDROBENZO-[C][1,2]OXABOROL-5-YL)OXY)BENZONITRILE",
      "4-[(1,3-Dihydro-1-Hydroxy-2,1-Benzoxaborol-5-Yl)Oxy]benzonitrile",
      "Benzonitrile, 4-[(1,3-Dihydro-1-Hydroxy-2,1-Benzoxaborol-5-Yl)Oxy]-",
      "Benzonitrile, 4-((1,3-Dihydro-1-Hydroxy-2,1-B",
    ],
  },
  {
    primary_id: "D0SM2O",
    names: ["N-(Pyridinepyrroylylcarbonyl)-Piperidine Derivative 1"],
  },
  {
    primary_id: "D0SM3V",
    names: ["12-Hydroxyheptadecatrienoic Acid", "12S-HHTrE"],
  },
  {
    primary_id: "D0SM4E",
    names: ["CD101 IV"],
  },
  {
    primary_id: "D0SM4F",
    names: [
      "Rac-4'-(1-Imidazol-1-Yl-Propyl)-Biphenyl-4-Ol",
      "CHEMBL500680",
      "BDBM50272067",
      "Rac-4''-(1-Imidazol-1-Yl-Propyl)-Biphenyl-4-Ol",
      "4''-(1H -Imidazol-1-Yl-Propyl)-Biphenyl-4-Ol",
    ],
  },
  {
    primary_id: "D0SM6N",
    names: ["ISIS-PTP1Brx"],
  },
  {
    primary_id: "D0SM6Y",
    names: ["MNT-02-07", "Botulinum Toxin Type B Therapy, Microbial Novoteqs"],
  },
  {
    primary_id: "D0SM8S",
    names: ["Lebrikizumab"],
  },
  {
    primary_id: "D0SM9Y",
    names: ["Pyrimidinyl Compound 6"],
  },
  {
    primary_id: "D0SN0U",
    names: [
      "Alpha-C-Galactosyl Ceramide",
      "Alpha-C-Galactosyl Ceramide (Cancer/Viral Infection)",
      "Alpha-C-Galactosylceramide (Immunostimulant), Kotobuki;Alpha-C-Galactosyl Ceramide (Cancer/Viral Infection), Kotobuki",
    ],
  },
  {
    primary_id: "D0SN1T",
    names: ["Nikkomycin Z"],
  },
  {
    primary_id: "D0SN2I",
    names: ["ABT-555"],
  },
  {
    primary_id: "D0SN2Q",
    names: ["AZD-6714"],
  },
  {
    primary_id: "D0SN3L",
    names: ["N-2-Phenylethyl-1H-Pyrrole-2-Carboxamide"],
  },
  {
    primary_id: "D0SN4Y",
    names: ["TRospium Intravesicular"],
  },
  {
    primary_id: "D0SN5O",
    names: ["ISIS 298711"],
  },
  {
    primary_id: "D0SN6R",
    names: ["(+/-)-3-((Naphthalen-2-Yloxy)Methyl)Pyrrolidine", "CHEMBL451460", "SCHEMBL2227832", "BDBM50278523"],
  },
  {
    primary_id: "D0SN6V",
    names: ["T-BuAc-RYYRIK-NH2", "CHEMBL270216"],
  },
  {
    primary_id: "D0SN7I",
    names: ["SB-656933"],
  },
  {
    primary_id: "D0SN8K",
    names: [
      "Anti-CD-20 Mab",
      "Anti-CD-20 MAb (Undisclosed Indication)",
      "Anti-CD-20 MAb (Undisclosed Indication), PharmAbcine",
      "CD-20 Inhibitor (MAb, Undisclosed Indication), PharmAbcine",
    ],
  },
  {
    primary_id: "D0SN9T",
    names: [
      "Nilutamide",
      "Anandron",
      "Nilandron",
      "Nilandrone",
      "Nilutamida",
      "Nilutamidum",
      "Aventis Behring Brand Of Nilutamide",
      "Aventis Brand Of Nilutamide",
      "Hoechst Brand Of Nilutamide",
      "Nilutamida [Spanish]",
      "Nilutamidum [Latin]",
      "N 8534",
      "RU 23908",
      "Anandron (TN)",
      "RU 23908-10",
      "RU-23908",
      "Nilutamide (USAN/INN)",
      "Nilutamide [USAN:BAN:INN]",
      "Nilutamide [USAN:INN:BAN]",
      "1-(3'-Trifluoromethyl-4'-Nitrophenyl)-4,4-Dimethylimidazolidine-2,5-Dione",
      "5,5-Dimethyl-3-(4-Nitro-3-(Trifluoromethyl)Phenyl)-2,4-Imidazolidinedione",
      "5,5-Dimethyl-3-(Alpha,alpha,alpha-Trifluoro-4-Nitro-M-Tolyl)Hydantoin",
      "5,5-Dimethyl-3-[4-Nitro-3-(Trifluoromethyl)Phenyl]-2,4-Imidazolidinedione",
      "5,5-Dimethyl-3-[4-Nitro-3-(Trifluoromethyl)Phenyl]imidazolidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0SO0F",
    names: ["Tricyclic Pyrrolidine Derivative 1"],
  },
  {
    primary_id: "D0SO0U",
    names: ["AG-122005"],
  },
  {
    primary_id: "D0SO1Z",
    names: ["PN-203", "Anticancer Agent (Injectable Nanoparticle Formulation), PharmaNova"],
  },
  {
    primary_id: "D0SO2D",
    names: ["(RS)-Mevalonate", "DL-Mevalonate", "DL-Mevalonic Acid"],
  },
  {
    primary_id: "D0SO4M",
    names: ["Human CD40 Agonist Ligand"],
  },
  {
    primary_id: "D0SO4O",
    names: ["Benzo[d]oxazol-2(3H)-One Derivative 1"],
  },
  {
    primary_id: "D0SO4Z",
    names: ["ASP1585", "Bixalomer", "AMG223"],
  },
  {
    primary_id: "D0SO5A",
    names: [
      "ATC-120",
      "CHEMBL412885",
      "Thiourea Analogue, 14",
      "SCHEMBL5947715",
      "BDBM20317",
      "ZINC14973328",
      "BDBM50142759",
      "N-(4-Tert-Butylbenzyl)-N''-{(1R)-1-[4-(Methylsulfonylamino)Phenyl]ethyl}thiourea",
      "(R)-N-(4-(1-(3-(4-Tert-Butylbenzyl)Thioureido)Ethyl)Phenyl)Methanesulfonamide",
      "N-(4-{(R)-1-[3-(4-Tert-Butyl-Benzyl)-Thioureido]-Ethyl}-Phenyl)-Methanesulfonamide",
    ],
  },
  {
    primary_id: "D0SO6T",
    names: ["PMID25726713-Compound-44"],
  },
  {
    primary_id: "D0SO8W",
    names: ["PMID28092474-Compound-32"],
  },
  {
    primary_id: "D0SO9G",
    names: ["5'-Methylthio-Immucillin-H"],
  },
  {
    primary_id: "D0SO9Q",
    names: ["GW0072"],
  },
  {
    primary_id: "D0SP0S",
    names: ["MC-4 Agonist"],
  },
  {
    primary_id: "D0SP2I",
    names: [
      "2-Nas-Phe(3-Am)-4-(2-Guanidinoethyl)Piperidine",
      "(S)-3-(3-(4-(2-GUANIDINOETHYL)PIPERIDIN-1-YL)-2-(NAPHTHALENE-2-SULFONAMIDO)-3-OXOPROPYL)BENZIMIDAMIDE",
      "2gv6",
      "AC1OA9XW",
      "CHEMBL210771",
      "SCHEMBL12960819",
      "BDBM23902",
      "3-Amidinophenylalanine Deriv., 8",
      "3-[(2S)-3-[4-(2-Carbamimidamidoethyl)Piperidin-1-Yl]-2-(Naphthalene-2-Sulfonamido)-3-Oxopropyl]benzene-1-Carboximidamide",
      "3-{(2S)-3-[4-(2-Carbamimidamidoethyl)Piperidin-1-Yl]-2-[(Naphthalen-2-Ylsulfonyl)Amino]-3-Oxopropyl}benzenecarboximidamide",
    ],
  },
  {
    primary_id: "D0SP2J",
    names: ["Bufotenine", "DM5-HT", "Dimethylserotonin", "N,N-Dimethyl-5-HT", "N,N-Dimethylserotonin", "5-OH-DMT"],
  },
  {
    primary_id: "D0SP2O",
    names: ["1H-Indole-2,3-Dione"],
  },
  {
    primary_id: "D0SP3D",
    names: ["Dihydroergotoxine"],
  },
  {
    primary_id: "D0SP5Q",
    names: [
      "PRT-064445",
      "Anticoagulant Antidote (Bleeding), Portola",
      "Factor Xa Inhibitor Antidote (Bleeding), Portola",
    ],
  },
  {
    primary_id: "D0SP5V",
    names: ["Setipiprant", "ACT-129968"],
  },
  {
    primary_id: "D0SP6A",
    names: [
      "SL-801",
      "CMASLSTVVOYJQY-UHFFFAOYSA-N",
      "1076235-04-5",
      "SCHEMBL1592515",
      "S-03747",
      "1H-Pyrrole-2,5-Dione, 1-[[6-Chloro-5-(Trifluoromethyl)-2-Pyridinyl]amino]-3-[(3,3-Dimethylbutoxy)Methyl]-4-Methyl-",
    ],
  },
  {
    primary_id: "D0SP7S",
    names: ["Androgen"],
  },
  {
    primary_id: "D0SP7T",
    names: ["PMID29671355-Compound-47d"],
  },
  {
    primary_id: "D0SQ0F",
    names: ["Pyrazole And Thiophene Derivative 1"],
  },
  {
    primary_id: "D0SQ0T",
    names: ["C-[2-(1H-Imidazol-4-Yl)-Cyclopropyl]-Methylamine", "CHEMBL292558", "BDBM50127603"],
  },
  {
    primary_id: "D0SQ1F",
    names: ["N-(2,6-Dimethylphenyl)-N'-Hydroxyoctanediamide", "CHEMBL1093358", "BDBM50314145"],
  },
  {
    primary_id: "D0SQ1O",
    names: ["XmAb5981"],
  },
  {
    primary_id: "D0SQ1R",
    names: ["PMID28394193-Compound-22"],
  },
  {
    primary_id: "D0SQ1T",
    names: [
      "2,5-Dideoxy-2,5-Imino-D-Altritol",
      "CHEMBL469844",
      "172823-15-3",
      "CHEMBL10437",
      "SCHEMBL2435520",
      "CTK0A7803",
      "DTXSID40437432",
      "ZINC5766623",
      "2,5-Imino-2,5-Dideoxy-D-Altritol",
      "BDBM50241865",
      "BDBM50099008",
      "(2R,3S,4S)-2,5-Bis-Hydroxymethyl-Pyrrolidine-3,4-Diol",
      "2,5-Pyrrolidinedimethanol, 3,4-Dihydroxy-, (2R,3R,4S,5R)-",
    ],
  },
  {
    primary_id: "D0SQ1W",
    names: [
      "Sterile Water",
      "Bacteriostatic Water For Injection In Plastic Container",
      "Sterile Water For Injection In Plastic Container",
      "Sterile Water In Plastic Container",
      "Sterile Water For Irrigation",
    ],
  },
  {
    primary_id: "D0SQ2K",
    names: ["Miravirsen"],
  },
  {
    primary_id: "D0SQ2Z",
    names: ["Ridaforolimus Eluting Coronary Stent"],
  },
  {
    primary_id: "D0SQ4B",
    names: ["PMID25514969-Compound-12"],
  },
  {
    primary_id: "D0SQ6A",
    names: ["FKW00GA"],
  },
  {
    primary_id: "D0SQ8R",
    names: ["V-710"],
  },
  {
    primary_id: "D0SQ9O",
    names: ["Herpes Simplex Vaccine"],
  },
  {
    primary_id: "D0SR0U",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 22"],
  },
  {
    primary_id: "D0SR3T",
    names: [
      "JN-711",
      "Alpha-7 Nicotinic Acetylcholine Receptor Agonists (CNS Disorders)",
      "Alpha-7 Nicotinic Acetylcholine Receptor Agonists (CNS Disorders), Novartis",
    ],
  },
  {
    primary_id: "D0SR4G",
    names: ["ONT-093"],
  },
  {
    primary_id: "D0SR4T",
    names: ["PHENSERINE TARTRATE"],
  },
  {
    primary_id: "D0SR5C",
    names: ["Fluvirin A(H1N1)"],
  },
  {
    primary_id: "D0SR5L",
    names: ["PMID27215781-Compound-19"],
  },
  {
    primary_id: "D0SR6S",
    names: ["PandyFlu"],
  },
  {
    primary_id: "D0SR8I",
    names: ["PMID26651364-Compound-124"],
  },
  {
    primary_id: "D0SR8Q",
    names: [
      "7-Phenyl-Naphthalen-2-Ol",
      "CHEMBL194718",
      "7-Phenyl-2-Naphthol",
      "7-Phenylnaphthalene-2-Ol",
      "SCHEMBL5981104",
      "MLFRZWHPLMDWES-UHFFFAOYSA-N",
      "BDBM50168338",
    ],
  },
  {
    primary_id: "D0SS1J",
    names: ["SAR399063", "DHA-GLP + Vit D"],
  },
  {
    primary_id: "D0SS1Q",
    names: ["Influenza Vaccine Patch"],
  },
  {
    primary_id: "D0SS2E",
    names: ["AZD-8450"],
  },
  {
    primary_id: "D0SS2O",
    names: ["Dihydrodiazepinocarbazolone Derivative 2"],
  },
  {
    primary_id: "D0SS4I",
    names: ["ASP-2905"],
  },
  {
    primary_id: "D0SS4P",
    names: ["Pirbuterol"],
  },
  {
    primary_id: "D0SS4S",
    names: [
      "Methyl 2-(Diallylamino)-1H-Indole-3-Carboxylate",
      "CHEMBL483269",
      "BDBM31111",
      "2-Substituted Indole-3-Carboxylate, 27h",
    ],
  },
  {
    primary_id: "D0SS5D",
    names: ["Lpc-Ether"],
  },
  {
    primary_id: "D0SS6J",
    names: ["GR-328713"],
  },
  {
    primary_id: "D0SS8M",
    names: [
      "3-AMINO-AZACYCLOTRIDECAN-2-ONE",
      "RIN",
      "AC1L9JYK",
      "(3S)-3-Aminoazacyclotridecan-2-One",
      "(3S)-3-Amino-Azacyclotridecan-2-One",
      "DB08476",
    ],
  },
  {
    primary_id: "D0ST0H",
    names: [
      "Xanthosine-5'-Monophosphate Disodium Salt",
      "EINECS 247-324-0",
      "Sodium 5'-O-(Hydroxyphosphinato)Xanthosine",
      "AC1Q1VBC",
      "CHEMBL1627079",
      "A818576",
      "Sodium [(2R,3S,4R,5R)-5-[2,6-Bis(Oxidanylidene)-3H-Purin-9-Yl]-3,4-Bis(Oxidanyl)Oxolan-2-Yl]methyl Hydrogen Phosphate",
      "Sodium [(2R,3S,4R,5R)-5-(2,6-Dioxo-3H-Purin-9-Yl)-3,4-Dihydroxy-2-Oxolanyl]methyl Hydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0ST1M",
    names: [
      "PMID20080612C1",
      "BAS 00486818",
      "Tg3-95-1",
      "301322-12-3",
      "AC1LDROW",
      "Oprea1_785181",
      "Oprea1_405733",
      "MLS000107338",
      "GTPL5819",
      "ZINC35938",
      "MolPort-000-222-632",
      "CHEBI:112974",
      "HMS2485P23",
      "CCG-17500",
      "BDBM50016951",
      "STK386484",
      "AKOS000513935",
      "MCULE-3137850852",
      "SMR000111709",
      "ST005800",
      "KB-275480",
      "SR-01000448413",
      "SR-01000448413-1",
      "F0817-0357",
      "Ethyl 2-(Furan",
    ],
  },
  {
    primary_id: "D0ST1U",
    names: ["Cyclo(-D-Tyr-D-Arg-L-Arg-L-Nal-L-Pic-)", "CHEMBL373637"],
  },
  {
    primary_id: "D0ST2K",
    names: ["PMID27109571-Compound-6"],
  },
  {
    primary_id: "D0ST3I",
    names: [
      "Fostemsavir",
      "864953-29-7",
      "Fostemsavir(BMS-663068)",
      "BMS-663068 Free Acid",
      "UNII-97IQ273H4L",
      "Fostemsavir [USAN]",
      "97IQ273H4L",
      "Fostemsavir (USAN)",
      "SCHEMBL754395",
      "CHEMBL3301594",
      "C25H26N7O8P",
      "DTXSID40235596",
      "MolPort-038-387-826",
      "ZINC14210883",
      "3502AH",
      "HY-15440A",
      "AKOS025396430",
      "DB11796",
      "CS-1059",
      "BMS-663038",
      "[3-[2-(4-Benzoylpiperazin-1-Yl)-2-Oxoacetyl]-4-Methoxy-7-(3-Methyl-1,2,4-Triazol-1-Yl)Pyrrolo[2,3-C]pyridin-1-Yl]methyl Dihydrogen Phosphate",
      "Piperazine, 1-Benzoyl-4-((4-Methoxy-7-(3-Methyl-1H-1,2,4-Tr",
    ],
  },
  {
    primary_id: "D0ST3S",
    names: ["4-(3-Fluorophenylethynyl)-2-Thiazolylamine", "CHEMBL370559", "878018-65-6", "SCHEMBL4140841"],
  },
  {
    primary_id: "D0ST4P",
    names: ["IM-023911"],
  },
  {
    primary_id: "D0ST5A",
    names: [
      "5-Bromothienyldeoxyuridine",
      "5-BROMOTHIENYLDEOXYURIDINE",
      "CHEMBL1231486",
      "5-(5-Bromo-2-Thienyl)-2'-Deoxyuridine",
      "5-(5-Bromothien-2-Yl)-2'-Deoxyuridine",
      "BTD",
      "134333-70-3",
      "BTDU",
      "Uridine,5-(5-Bromo-2-Thienyl)-2'-Deoxy-",
      "AC1L9K0Y",
      "SCHEMBL1636648",
      "CHEMBL358374",
      "CTK4B9107",
      "BDBM50054768",
      "DB03804",
      "5-(5-Bromothien-2-Yl)-2'-Deoxyuridine-",
      "5-(5-Bromothiophen-2-Yl)-2'-Deoxyuridine",
      "5-(5-Bromothien-2-Yl)-1- (.beta.-D-2-Deoxyribofuranos-1-Yl)Uracil",
      "5-(5-Bromo-Thiophen-2-Yl)-1-(4-Hydroxy-5-Hydroxymethyl-Tetrahydro-Furan-2-Yl)-1H-Pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0ST5P",
    names: ["TA-5538"],
  },
  {
    primary_id: "D0ST5U",
    names: ["Galanthamine Derivative"],
  },
  {
    primary_id: "D0SU0E",
    names: ["Biphenyl Mannoside Derivative 1"],
  },
  {
    primary_id: "D0SU0Y",
    names: ["1-(1-Biphenyl-4-Yl-Butyl)-1H-Imidazole", "CHEMBL449343"],
  },
  {
    primary_id: "D0SU1F",
    names: ["CP-122288"],
  },
  {
    primary_id: "D0SU1G",
    names: ["Zomepirac"],
  },
  {
    primary_id: "D0SU4D",
    names: ["Aflibercept + Nesvacumab"],
  },
  {
    primary_id: "D0SU5M",
    names: ["N-Cyclohexylethyl-ETAV"],
  },
  {
    primary_id: "D0SU7B",
    names: ["N-Hydroxy-4-(2-Oleamidoethyl)Benzamide", "CHEMBL251734"],
  },
  {
    primary_id: "D0SV0D",
    names: ["Bidentate Ligands Of Markush Derivative 1"],
  },
  {
    primary_id: "D0SV1F",
    names: [
      "2-(2-(Pentyloxy)Pyrimidin-4-Ylamino)Benzoic Acid",
      "4-Anilinopyrimidine 6c",
      "CHEMBL242701",
      "BDBM15981",
      "2-{[2-(Pentyloxy)Pyrimidin-4-Yl]amino}benzoic Acid",
    ],
  },
  {
    primary_id: "D0SV1I",
    names: ["Pyrido[1,2,4]triazolo[4,3-A]pyrazine Derivative 3"],
  },
  {
    primary_id: "D0SV2E",
    names: ["PMID26161698-Compound-32"],
  },
  {
    primary_id: "D0SV2S",
    names: ["Tetra-Hydro-Quinoline Derivative 3"],
  },
  {
    primary_id: "D0SV4A",
    names: ["CD19 Targeted Chimeric Antigen Receptor T Cells"],
  },
  {
    primary_id: "D0SV5D",
    names: ["GWTLNSAGYLLGPPPGFSPFR-CONH2"],
  },
  {
    primary_id: "D0SV5T",
    names: ["Veltuzumab Subcutaneous"],
  },
  {
    primary_id: "D0SV6C",
    names: ["PRONTOCIL"],
  },
  {
    primary_id: "D0SV6D",
    names: ["Heterocyclic-Substituted 3-Alkyl Azetidine Derivative 1"],
  },
  {
    primary_id: "D0SV6U",
    names: ["3-Phenyl Acrylic Acid Derivative 1"],
  },
  {
    primary_id: "D0SV6V",
    names: ["PP-303"],
  },
  {
    primary_id: "D0SV7D",
    names: ["LOTRAFIBAN"],
  },
  {
    primary_id: "D0SV8E",
    names: ["Methotrexate"],
  },
  {
    primary_id: "D0SW0V",
    names: ["Tacrine-Caffeic Acid Hybrid Derivative 2"],
  },
  {
    primary_id: "D0SW1D",
    names: ["Cellspan Esophageal Implant"],
  },
  {
    primary_id: "D0SW2H",
    names: ["TAK-778", "Benzothiepines, Takeda", "TAK-778-SR"],
  },
  {
    primary_id: "D0SW2Q",
    names: ["Tetra-Hydro-1H-1,2,6-Triazaazulene Derivative 2"],
  },
  {
    primary_id: "D0SW8L",
    names: ["LC-280126", "Cytopro", "Necrosis Inhibitors (Oral)", "Necrosis Inhibitors (Oral), LG Life Sciences"],
  },
  {
    primary_id: "D0SX1B",
    names: ["Benzimidazole Derivative 5"],
  },
  {
    primary_id: "D0SX1X",
    names: ["IGF-Methotrexate Conjugate"],
  },
  {
    primary_id: "D0SX3X",
    names: ["TEI-6122"],
  },
  {
    primary_id: "D0SX4D",
    names: ["KD-295"],
  },
  {
    primary_id: "D0SX4K",
    names: ["HR325"],
  },
  {
    primary_id: "D0SX6O",
    names: ["Meninvact"],
  },
  {
    primary_id: "D0SX7F",
    names: [
      "AZALANSTAT",
      "Azalanstat",
      "RS-21607",
      "UNII-2NL79NI1WS",
      "143393-27-5",
      "2NL79NI1WS",
      "CHEMBL70611",
      "1-(((2S,4S)-4-(((P-Aminophenyl)Thio)Methyl)-2-(P-Chlorophenethyl)-1,3-Dioxolan-2-Yl)Methyl)Imidazole",
      "4-[[(2S,4S)-2-[2-(4-Chlorophenyl)Ethyl]-2-(Imidazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl]methylsulfanyl]aniline",
      "Azalanstat [INN]",
      "RS-21607197",
      "RS 21607",
      "AC1Q3NQV",
      "AC1L1U3F",
      "SCHEMBL1230519",
      "GTPL8799",
      "4-{2-[2-(4-Chloro-Phenyl)-Ethyl]-2-Imidazol-1-Ylmethyl-[1,3]dioxolan-4-Ylmethylsulfanyl}-Phenylamine",
      "BDBM50044424",
      "Compound 1 [PMID: 8340925]",
      "Benzenamine, 4",
    ],
  },
  {
    primary_id: "D0SX9C",
    names: ["Alfa-Interferon"],
  },
  {
    primary_id: "D0SY2M",
    names: [
      "Daidzein",
      "486-66-8",
      "4',7-Dihydroxyisoflavone",
      "Daidzeol",
      "7,4'-Dihydroxyisoflavone",
      "7-Hydroxy-3-(4-Hydroxyphenyl)-4H-Chromen-4-One",
      "4H-1-Benzopyran-4-One, 7-Hydroxy-3-(4-Hydroxyphenyl)-",
      "7-Hydroxy-3-(4-Hydroxyphenyl)-4H-1-Benzopyran-4-One",
      "7-Hydroxy-3-(4-Hydroxyphenyl)Chromen-4-One",
      "7-Hydroxy-3-(4-Hydroxyphenyl)-4-Benzopyrone",
      "UNII-6287WC5J2L",
      "CCRIS 7600",
      "K 251b",
      "4,7-Dihydroxyisoflavone",
      "EINECS 207-635-4",
      "BRN 0231523",
      "CHEMBL8145",
      "4',7-Dihydroxy-Iso-Flavone",
      "D-(+)-Alpha-Methylbenzylamine",
      ",7-Dihydroxyisoflavone",
      "DIADZEIN",
    ],
  },
  {
    primary_id: "D0SY2O",
    names: ["ANG-3067"],
  },
  {
    primary_id: "D0SY6C",
    names: [
      "7,4'-Dihydroxyflavone",
      "4',7-Dihydroxyflavone",
      "2196-14-7",
      "7-Hydroxy-2-(4-Hydroxyphenyl)-4H-Chromen-4-One",
      "4H-1-Benzopyran-4-One, 7-Hydroxy-2-(4-Hydroxyphenyl)-",
      "FLAVONE, 4',7-DIHYDROXY-",
      "UNII-53ZZF57X0U",
      "CHEMBL294878",
      "53ZZF57X0U",
      "7-Hydroxy-2-(4-Hydroxyphenyl)Chromen-4-One",
      "CHEBI:29503",
      "LCAWNFIFMLXZPQ-UHFFFAOYSA-N",
      "BRN 0224356",
      "PubChem9844",
      "AC1NQZG5",
      "7, 4'-Dihydroxyflavone",
      "Spectrum4_001280",
      "7,4''-Dihydroxyflavone",
      "7,4' - Dihydroxyflavone",
      "C12123",
      "7,4&amp;#39-Dihydroxyflavone",
      "KBioGR_001900",
      "SPECTRUM1500721",
      "SCHEMBL157723",
    ],
  },
  {
    primary_id: "D0SY6J",
    names: ["Fused Heterocyclic Compound 1"],
  },
  {
    primary_id: "D0SY6P",
    names: ["PMID28394193-Compound-18"],
  },
  {
    primary_id: "D0SY6T",
    names: ["1-(3,3-Diphenyl-Allyl)-3-M-Tolyl-Urea", "CHEMBL9312"],
  },
  {
    primary_id: "D0SY7H",
    names: [
      "IDX-184",
      "B-102",
      "B-184",
      "B-299",
      "IDX-102",
      "Next-Generation Nucleotide Polymerase Inhibitors (HCV Infection), Idenix",
      "Next-Generation Nucleotide Polymerase Inhibitors (Hepatitis C Virus Infection), Idenix",
      "2'-Methyl Guanosine Prodrug (Hepatitis C Virus Infection), Idenix",
    ],
  },
  {
    primary_id: "D0SY9B",
    names: ["SSR149415"],
  },
  {
    primary_id: "D0SZ1G",
    names: ["AZD9567"],
  },
  {
    primary_id: "D0SZ1L",
    names: ["Triazolo-Benzodiazepine Derivative 2"],
  },
  {
    primary_id: "D0SZ2E",
    names: ["Acetohydroxamic"],
  },
  {
    primary_id: "D0SZ2G",
    names: ["SC-003"],
  },
  {
    primary_id: "D0SZ3B",
    names: ["ER-34122"],
  },
  {
    primary_id: "D0SZ4T",
    names: [
      "9-Hydroxyoctadecadienoic Acid",
      "(9S,10E,12Z)-9-Hydroxyoctadeca-10,12-Dienoic Acid",
      "9(S)-HODE",
      "Alpha-Dimorphecolic Acid",
      "9S-HODE",
      "(9S)-Hydroxyoctadecadienoic Acid",
      "UNII-42KE04U9BM",
      "9S-Hydroxy-10E,12Z-Octadecadienoic Acid",
      "42KE04U9BM",
      "CHEBI:34496",
      "(9S)-Hydroxyoctadecadinoiec Acid",
      "73543-67-6",
      "(10E,12Z)-(9S)-9-Hydroxyoctadeca-10,12-Dienoic Acid",
      "(9s,10e,12z)-9-Hydroxy-10,12-Octadecadienoic Acid",
      "(9S,10E,12Z)-9-Hydroxyoctadeca-10,12-Dienoate",
      "9(S)-Hydroxyoctadecadienoic Acid",
      "Alpha-Dimorphecolic",
      "AC1NSNNN",
      "(9S)-Hydroxyoctadecadienoate",
      "(+)-Alpha-Dimorphecolic",
    ],
  },
  {
    primary_id: "D0SZ5A",
    names: ["Pyrazolopyrimidine Derivative 1"],
  },
  {
    primary_id: "D0SZ5G",
    names: [
      "ANX-042",
      "BNP Sliced Variant (Heart Failure), Anexon",
      "B-Type Natriuretic Peptide Spliced Variant (Heart Failure), Anexon",
    ],
  },
  {
    primary_id: "D0SZ6E",
    names: [
      "YN-968D1",
      "Apatinib",
      "Apatinib Mesylate",
      "VEGF Receptor Inhibitor (Cancer), Advenchen",
      "VEGFR2 Receptor Inhibitor (Cancer), LSK/ Jiangsu Henrui/ Bukwang",
    ],
  },
  {
    primary_id: "D0SZ8Q",
    names: [
      "CSC-500297",
      "CSC-500204",
      "CSC-500 Series, Cascade",
      "5-HT2c Receptor Agonists (Obesity/Obsessive Compulsive Disorder), Cascade",
    ],
  },
  {
    primary_id: "D0SZ9E",
    names: [
      "8(S)-Amino-2(R)-Methyl-7-Oxononanoic Acid",
      "CHEMBL465576",
      "Nonanoic Acid, 8-Amino-2-Methyl-7-Oxo-, (S-(R*,S*))-",
      "181886-81-7",
      "SCHEMBL4098289",
      "AC1L42P6",
      "DTXSID90171213",
      "BDBM50292361",
      "(2R,8S)-8-Amino-2-Methyl-7-Oxononanoic Acid",
      "(2R,8S)-2-Methyl-7-Oxo-8-Aminononanoic Acid",
    ],
  },
  {
    primary_id: "D0SZ9F",
    names: [
      "2,4-Dichlorobenzenemethanethiol",
      "2,4-Dichlorobenzyl Mercaptan",
      "59293-67-3",
      "(2,4-Dichlorophenyl)Methanethiol",
      "2,4-Dichlorobenzylmercaptan",
      "2,4-Dichlorophenylmethanethiol",
      "2,4-Dichloro-Alpha-Toluenethiol",
      "2,4-Dichlorotoluene-Alpha-Thiol",
      "Benzenemethanethiol, 2,4-Dichloro-",
      "CHEMBL1224561",
      "ZSPXTTVUJDSRNJ-UHFFFAOYSA-N",
      "NSC33218",
      "EINECS 261-687-2",
      "AI3-31458",
      "AC1Q3MDQ",
      "ACMC-1B9KZ",
      "AC1L3V0Z",
      "SCHEMBL1802752",
      "2,4-Dichlorothiobenzyl Alcohol",
      "ZSPXTTVUJDSRNJ-UHFFFAOYSA-",
      "CTK5A9733",
      "DTXSID70208016",
      "MolPort-004-354-551",
    ],
  },
  {
    primary_id: "D0SZ9R",
    names: ["2X-121"],
  },
  {
    primary_id: "D0T0AG",
    names: [
      "EBIO",
      "10045-45-1",
      "1-Ethyl-2-Benzimidazolinone",
      "1-EBIO",
      "1-Ethyl-1H-Benzo[d]imidazol-2(3H)-One",
      "1-Ethylbenzimidazolinone",
      "1-Ethylbenzimidazolin-2-One",
      "1-Ethyl-1,3-Dihydro-2h-Benzimidazol-2-One",
      "2H-Benzimidazol-2-One, 1-Ethyl-1,3-Dihydro-",
      "1-Ethyl-Benzimidazolinone",
      "UNII-M82W79SS4W",
      "3-Ethyl-1H-Benzimidazol-2-One",
      "EINECS 233-148-1",
      "CHEMBL452887",
      "M82W79SS4W",
      "CHEBI:34076",
      "CXUCKELNYMZTRT-UHFFFAOYSA-N",
      "1-Ethyl-2,3-Dihydro-1H-1,3-Benzodiazol-2-One",
      "1-EB10",
      "1-Ethyl-1,3-Dihydrobenzimidazol-2-One",
      "Tocris-1041",
      "AC1Q2ZX3",
      "AC1Q2ZX4",
    ],
  },
  {
    primary_id: "D0T0AM",
    names: ["Proapoptotic Surrobodies"],
  },
  {
    primary_id: "D0T0BA",
    names: ["CX-1010"],
  },
  {
    primary_id: "D0T0BE",
    names: ["7-(3-Benzoyl-Ureido)-Heptanoic Acid Hydroxyamide", "CHEMBL1083440", "SCHEMBL4922807"],
  },
  {
    primary_id: "D0T0BF",
    names: [
      "EBV CTLs",
      "EBV CTLs (EBV-Associated Lymphoma, Nasopharyngeal Carcinoma)",
      "Epstein-Barr Virus Cytotoxic T Lymphocytes, Baylor College/ Cell Medica",
      "Epstein-Barr Virus Specifc T Cells, St Jude/Baylor",
      "Immunotherapy (EBV-Associated Lymphoma), St Jude/Baylor",
      "EBV CTLs (EBV-Associated Lymphoma, Nasopharyngeal Carcinoma), Baylor College/ Cell Medica",
    ],
  },
  {
    primary_id: "D0T0BP",
    names: ["MenBioVax"],
  },
  {
    primary_id: "D0T0BS",
    names: ["CGS-18102A", "CGP-50281", "CGS-18102"],
  },
  {
    primary_id: "D0T0BY",
    names: [
      "2-Morpholin-4-Yl-Pyrimido[2,1-A]isoquinolin-4-One",
      "Compound 401",
      "168425-64-7",
      "2-Morpholino-4H-Pyrimido[2,1-A]isoquinolin-4-One",
      "2-(Morpholin-4-Yl)Pyrimido[2,1-A]isoquinolin-4-One",
      "CHEMBL179242",
      "2-(4-MORPHOLINYL)-4H-PYRIMIDO[2,1-A]ISOQUINOLIN-4-ONE",
      "Compound401",
      "SCHEMBL10092321",
      "KS-00001DEG",
      "CTK4D2994",
      "DTXSID20434626",
      "MolPort-023-276-726",
      "HMS3229D15",
      "EX-A1016",
      "BCP04303",
      "BDBM50159620",
      "ZINC13608047",
      "AKOS016369524",
      "CS-5624",
      "NCGC00378805-02",
      "HY-19341",
      "KB-224235",
      "M2537",
      "B7337",
      "S-7713",
      "J-010456",
    ],
  },
  {
    primary_id: "D0T0CB",
    names: ["PMID27967267-Compound-7"],
  },
  {
    primary_id: "D0T0CG",
    names: ["Rp-8-CPT-CGMPS"],
  },
  {
    primary_id: "D0T0CK",
    names: ["3,4-Dimethoxy-4'-Amino-Trans-Stilbene", "CHEMBL1172937", "BDBM50322051"],
  },
  {
    primary_id: "D0T0CY",
    names: ["2-(4-Butoxy-Phenoxy)-N-Hydroxy-N-Methyl-Acetamide", "CHEMBL178065"],
  },
  {
    primary_id: "D0T0EJ",
    names: ["2-Cyclopentylaminomethyl-Pyrrolidine-3,4-Diol", "CHEMBL84940"],
  },
  {
    primary_id: "D0T0FT",
    names: ["AutoSynVax"],
  },
  {
    primary_id: "D0T0GA",
    names: ["2-(4-Phenoxy-Benzyl)-3H-Benzoimidazol-4-Ol", "CHEMBL63924"],
  },
  {
    primary_id: "D0T0GL",
    names: ["For-RYYRIK-NH2", "CHEMBL271478"],
  },
  {
    primary_id: "D0T0HH",
    names: ["Toluene"],
  },
  {
    primary_id: "D0T0HM",
    names: ["PMID28270021-Compound-WO2010077680 109"],
  },
  {
    primary_id: "D0T0HS",
    names: ["Gue1654"],
  },
  {
    primary_id: "D0T0IE",
    names: ["ISV-215"],
  },
  {
    primary_id: "D0T0IL",
    names: ["WIN-68577", "151928-33-5"],
  },
  {
    primary_id: "D0T0IR",
    names: ["S-Isopentyl 3-Methylbutane-1-Sulfinothioate"],
  },
  {
    primary_id: "D0T0IX",
    names: ["WRR-112"],
  },
  {
    primary_id: "D0T0JF",
    names: ["PseudIgY"],
  },
  {
    primary_id: "D0T0JU",
    names: ["Waixenicin A"],
  },
  {
    primary_id: "D0T0KA",
    names: ["Nicardipine"],
  },
  {
    primary_id: "D0T0KQ",
    names: ["SEL-100130", "Anti-Inflammatory (Topical), Selcia"],
  },
  {
    primary_id: "D0T0KT",
    names: [
      "Briciclib",
      "865783-99-9",
      "ON-014185",
      "UNII-WG93X96336",
      "WG93X96336",
      "ON 014185",
      "Briciclib [USAN:INN]",
      "Briciclib (USAN/INN)",
      "SCHEMBL1634579",
      "SCHEMBL1634581",
      "CHEMBL1206245",
      "MolPort-046-033-539",
      "LXENKEWVEVKKGV-BQYQJAHWSA-N",
      "EX-A2492",
      "BCP17292",
      "ZINC28965775",
      "AKOS027439966",
      "DB12004",
      "CS-5589",
      "HY-16366",
      "KB-79924",
      "Briciclib(ON 013105 ON 014185)",
      "ON-013105",
      "D10614",
      "(2-Methoxy-5-{[(E)-2-(2,4,6-Trimethoxyphenyl)Ethenesulfonyl]methyl}phenoxy)Phosphonic Acid",
      "(E)-5-((2,4,6-Trimethoxystyrylsulfonyl)Methyl)-2-Methoxyphenyl Dihydro",
    ],
  },
  {
    primary_id: "D0T0LE",
    names: [
      "IRX-4310",
      "AGN-194310",
      "AGN-4310",
      "ALRT-4310",
      "LGD-4310",
      "NRX-4310",
      "RARa Antagonist (Oral, Chemotherapy-Induced Neutropenia), Io Therapeutics",
      "Retinoic Acid Receptor Alpha Antagonist (Oral, Chemotherapy-Induced Neutropenia), Io Therapeutics",
    ],
  },
  {
    primary_id: "D0T0LM",
    names: ["Pirenzepine"],
  },
  {
    primary_id: "D0T0LO",
    names: ["RP-54745"],
  },
  {
    primary_id: "D0T0LR",
    names: ["Debio-0929"],
  },
  {
    primary_id: "D0T0LU",
    names: ["Conjugated Estrogens"],
  },
  {
    primary_id: "D0T0MY",
    names: ["DT-330"],
  },
  {
    primary_id: "D0T0NG",
    names: [
      "Arofylline",
      "Arofyllin",
      "KM09088",
      "Arofylline (USAN/INN)",
      "3-(4-Chlorophenyl)-1-Propyl-3,7-Dihydro-1H-Purine-2,6-Dione",
      "3-(4-Chlorophenyl)-1-Propyl-7H-Purine-2,6-Dione",
    ],
  },
  {
    primary_id: "D0T0NL",
    names: ["ARC031"],
  },
  {
    primary_id: "D0T0OQ",
    names: ["Furopyridine Derivative 2"],
  },
  {
    primary_id: "D0T0OW",
    names: [
      "Coformycin",
      "11033-22-0",
      "UNII-E49510ZL0H",
      "NSC 277817",
      "(R)-3,4,7,8-Tetrahydro-3-Beta-D-Ribofuranosylimidazo(4,5-D)(1,3)Diazepin-8-Ol",
      "CHEBI:16213",
      "E49510ZL0H",
      "(8R)-3-Beta-D-Ribofuranosyl-3,6,7,8-Tetrahydroimidazo[4,5-D][1,3]diazepin-8-Ol",
      "Imidazo(4,5-D)(1,3)Diazepin-8-Ol, 3,4,7,8-Tetrahydro-3-Beta-D-Ribofuranosyl-, (R)-",
      "CHEMBL284483",
      "NSC277817",
      "SCHEMBL442534",
      "YOOVTUPUBVHMPG-LODYRLCVSA-N",
      "AC1L1956",
      "BDBM50367032",
      "LS-77849",
      "C01677",
      "Imidazo[4,5-D][1,3]diazepin-8-Ol, 3,4,7,8-Tetrahydro-3-.beta.-D-Ribofuranosyl-, (8R)",
    ],
  },
  {
    primary_id: "D0T0PN",
    names: [
      "CP-122721",
      "UNII-R7OYP6N58F",
      "R7OYP6N58F",
      "CHEMBL319118",
      "CHEMBL1917847",
      "DSSTox_CID_27251",
      "DSSTox_RID_82210",
      "DSSTox_GSID_47251",
      "SCHEMBL156646",
      "DTXSID9047251",
      "Tox21_300205",
      "BDBM50067935",
      "ZINC22441997",
      "AKOS030542331",
      "DB05421",
      "NCGC00254228-01",
      "NCGC00247927-01",
      "CAS-145742-28-5",
      "(2S,3S)-N-(2-Methoxy-5-(Trifluoromethoxy)Benzyl)-2-Phenylpiperidin-3-Amine",
    ],
  },
  {
    primary_id: "D0T0PR",
    names: ["Ac-I[CV(Yphs)QDWGAHRC]I-NH2"],
  },
  {
    primary_id: "D0T0PU",
    names: ["CME-548"],
  },
  {
    primary_id: "D0T0QU",
    names: ["MEDI-559"],
  },
  {
    primary_id: "D0T0RI",
    names: ["JNJ-28318706"],
  },
  {
    primary_id: "D0T0RU",
    names: ["AC-8"],
  },
  {
    primary_id: "D0T0RV",
    names: ["PMID28048944-Compound-11"],
  },
  {
    primary_id: "D0T0SE",
    names: ["Anti-EGFR CAR T"],
  },
  {
    primary_id: "D0T0SK",
    names: ["ST-2987"],
  },
  {
    primary_id: "D0T0SO",
    names: ["BI-K0376"],
  },
  {
    primary_id: "D0T0SR",
    names: ["ISIS 19676"],
  },
  {
    primary_id: "D0T0TH",
    names: ["Replicase"],
  },
  {
    primary_id: "D0T0TZ",
    names: ["Ticrynafen"],
  },
  {
    primary_id: "D0T0UC",
    names: ["ANAVEX 1-41"],
  },
  {
    primary_id: "D0T0UH",
    names: [
      "3,3-Bis-(4-Hydroxy-Phenyl)-2-Phenyl-Acrylonitrile",
      "3,3-Bis(4-Hydroxyphenyl)-2-Phenylprop-2-Enenitrile",
      "CHEMBL65053",
    ],
  },
  {
    primary_id: "D0T0UM",
    names: ["EM-6110a"],
  },
  {
    primary_id: "D0T0VA",
    names: ["B-4474"],
  },
  {
    primary_id: "D0T0VC",
    names: [
      "5-Phenylamino-4-Cyano-3-Hydroxy-Isothiazole",
      "CHEMBL209793",
      "1137-65-1",
      "4-Isothiazolecarbonitrile, 2,3-Dihydro-3-Oxo-5-(Phenylamino)-",
      "AC1MBJGN",
      "Maybridge4_002946",
      "5-Anilino-3-Oxo-1,2-Thiazole-4-Carbonitrile",
      "Oprea1_090337",
      "MLS000850692",
      "SCHEMBL3527060",
      "CTK0G1139",
      "DTXSID30369410",
      "CHEBI:107126",
      "MolPort-002-913-499",
      "GHXPCAXQBVCFNU-UHFFFAOYSA-N",
      "HMS2799B08",
      "HMS1529F20",
      "CCG-1976",
      "ZINC15924361",
      "BDBM50187849",
      "AKOS022652528",
      "AKOS030549246",
      "MCULE-9694822584",
      "NCGC00176381-01",
      "SMR000456709",
      "5-Anilino-3-Oxo-Isothiazole-4-Carbonitrile",
    ],
  },
  {
    primary_id: "D0T0VZ",
    names: ["XEN-D0104"],
  },
  {
    primary_id: "D0T0XY",
    names: ["THEASINENSIN A"],
  },
  {
    primary_id: "D0T0XZ",
    names: ["ISIS 112020"],
  },
  {
    primary_id: "D0T0YA",
    names: ["PMID27387065-Compound-6"],
  },
  {
    primary_id: "D0T0YB",
    names: ["Cotrimazine"],
  },
  {
    primary_id: "D0T0YG",
    names: ["Pixuvripixantrone"],
  },
  {
    primary_id: "D0T0YI",
    names: [
      "4-Nitrophenyl Phosphate",
      "4-Nitrophenyl Dihydrogen Phosphate",
      "330-13-2",
      "Nitrophenylphosphate",
      "Phosphoric Acid, Mono(4-Nitrophenyl) Ester",
      "P-Nitrophenyl Phosphate",
      "P-Nitrophenyl Dihydrogen Phosphate",
      "P-Nitrophenol Phosphate",
      "P-Nitrophenylphosphate",
      "(4-Nitrophenyl) Dihydrogen Phosphate",
      "PNPP",
      "NSC 404086",
      "Phenol, P-Nitro-, Dihydrogen Phosphate",
      "CHEMBL24231",
      "(4-Nitrophenoxy)Phosphonic Acid",
      "CHEBI:17440",
      "XZKIHKMTEMTJQX-UHFFFAOYSA-N",
      "PNPP Alkaline Phosphatase Substrate",
      "Phosphoric Acid, Mono(P-Nitrophenyl) Ester (8CI)",
      "Phosphoric Ac",
    ],
  },
  {
    primary_id: "D0T0ZW",
    names: ["ALKS33"],
  },
  {
    primary_id: "D0T1AO",
    names: ["MystiTrip"],
  },
  {
    primary_id: "D0T1BE",
    names: ["PMID29338548-Compound-33"],
  },
  {
    primary_id: "D0T1BU",
    names: ["[125I]IRL1620", "[125I]IRL 1620", "[125I]-IRL1620"],
  },
  {
    primary_id: "D0T1CE",
    names: [
      "N-(6-(Thiophen-3-Yl)-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1083208",
      "SCHEMBL1462380",
      "KEXXQGBZRBKVRC-UHFFFAOYSA-N",
      "BDBM50313653",
    ],
  },
  {
    primary_id: "D0T1CH",
    names: ["4-[1-(4'-Methoxybiphenyl-4-Yl)Propyl]pyridine", "CHEMBL1215596", "BDBM50324608"],
  },
  {
    primary_id: "D0T1DZ",
    names: [
      "EPI-001",
      "227947-06-0",
      "BADGE-HCl-H2O",
      "Bisphenol A (3-Chloro-2-Hydroxypropyl) (2,3-Dihydroxypropyl) Ether",
      "3-[4-[2-[4-(3-Chloro-2-Hydroxypropoxy)Phenyl]propan-2-Yl]phenoxy]propane-1,2-Diol",
      "3-(4-{2-[4-(3-Chloro-2-Hydroxypropoxy)Phenyl]propan-2-Yl}phenoxy)Propane-1,2-Diol",
      "EPI 001",
      "AC1N56RN",
      "SCHEMBL12267194",
      "CTK8F8201",
      "CHEBI:59642",
      "DTXSID30400170",
      "MolPort-003-939-733",
      "BCP18361",
      "EX-A2389",
      "S7955",
      "AKOS030573303",
      "ACM227947060",
      "AC-29035",
      "RT-011557",
      "EPI-001, &gt",
      "J-014857",
    ],
  },
  {
    primary_id: "D0T1EO",
    names: ["Sulfonamides"],
  },
  {
    primary_id: "D0T1EU",
    names: ["Ndx-Peptide"],
  },
  {
    primary_id: "D0T1FC",
    names: ["PF-4878691"],
  },
  {
    primary_id: "D0T1FJ",
    names: ["4-(Aminomethyl)-7-(Benzyloxy)-2H-Chromen-2-One", "CHEMBL572533"],
  },
  {
    primary_id: "D0T1FR",
    names: [
      "Cis-2-Aminocyclohexylcarbamoylphosphonic Acid",
      "Cis-ACCP",
      "777075-44-2",
      "CHEMBL270083",
      "SCHEMBL569075",
      "C7H15N2O4P",
      "CTK8E9417",
      "MolPort-039-139-066",
      "BDBM50234465",
      "1683AH",
      "ZINC29134634",
    ],
  },
  {
    primary_id: "D0T1GB",
    names: ["PMID26394986-Compound-52"],
  },
  {
    primary_id: "D0T1GF",
    names: [
      "MP470",
      "Amuvatinib",
      "850879-09-3",
      "MP-470",
      "MP 470",
      "Amuvatinib (MP-470)",
      "HPK56",
      "UNII-SO9S6QZB4R",
      "Amuvatinib(MP470)",
      "N-(Benzo[d][1,3]dioxol-5-Ylmethyl)-4-(Benzofuro[3,2-D]pyrimidin-4-Yl)Piperazine-1-Carbothioamide",
      "SO9S6QZB4R",
      "HPK-56",
      "HPK 56",
      "N-(Benzo[d][1,3]dioxol-5-Ylmethyl)-4-(Benzofuro-[3,2-D]pyrimidin-4-Yl)Piperazine-1-Carbothioamide",
      "N-(1,3-Benzodioxol-5-Ylmethyl)-4-([1]benzofuro[3,2-D]pyrimidin-4-Yl)Piperazine-1-Carbothioamide",
      "Amuvatinib [USAN:INN]",
      "Amuvatinib (MP-470, HPK 56)",
      "PubChem22479",
      "Amuvatinib (USAN/INN)",
    ],
  },
  {
    primary_id: "D0T1GG",
    names: ["3-Phenyl-5-Ureidoisothiazole-4-Carboximide And 3-Amino-5-Phenylisothiazole Derivative 1"],
  },
  {
    primary_id: "D0T1GH",
    names: [
      "Artemisinin Derivatives",
      "Artemisinin Derivatives (GelSite, Cancer), ElSohly Laboratories",
      "Artemisinin Derivatives (GelSite, Cancer/Malaria)",
      "Artemisinin Derivatives (GelSite, Cancer/Malaria), ElSohly Laboratories",
    ],
  },
  {
    primary_id: "D0T1GP",
    names: [
      "(+/-)-7-Methyl-2-Phenylchroman-4-One",
      "64919-60-4",
      "7-Methylflavanone",
      "SCHEMBL1895393",
      "CHEMBL600392",
      "CTK1I3915",
      "DTXSID70502203",
      "AKOS030552841",
      "7-Methyl-2-Phenyl-2,3-Dihydro-4H-1-Benzopyran-4-One",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-7-Methyl-2-Phenyl-",
    ],
  },
  {
    primary_id: "D0T1GT",
    names: ["Sulfacytine"],
  },
  {
    primary_id: "D0T1GV",
    names: [
      "ADD-17014",
      "1-(4-Chlorophenyl)-5-(4-Pyridyl)-DELTA2-1,2,3-Triazoline",
      "4-[1-(4-Chlorophenyl)-4,5-Dihydro-1H-1,2,3-Triazol-5-Yl]pyridine",
    ],
  },
  {
    primary_id: "D0T1GZ",
    names: [
      "Phenethyl-(3-Phenyl-Propyl)-Amine",
      "CHEMBL283775",
      "AC1L8VET",
      "SCHEMBL625373",
      "N-Phenethylbenzenepropane-1-Amine",
      "ZINC1707035",
      "N-Phenethyl-3-Phenylpropan-1-Amine",
      "BDBM50041250",
    ],
  },
  {
    primary_id: "D0T1HE",
    names: ["APD-403"],
  },
  {
    primary_id: "D0T1HY",
    names: [
      "N-Hydroxy-3-Naphthalen-2-Yl-N-Phenyl-Acrylamide",
      "CHEMBL154575",
      "BDBM50015102",
      "(E)-3-(2-Naphthalenyl)-N-Phenyl-2-Propenehydroxamic Acid",
    ],
  },
  {
    primary_id: "D0T1II",
    names: ["Cyclo[RGDfK(Cypate)]", "CHEMBL407126"],
  },
  {
    primary_id: "D0T1IJ",
    names: ["Oprelvekin"],
  },
  {
    primary_id: "D0T1IK",
    names: ["HYNIC Analogue"],
  },
  {
    primary_id: "D0T1JA",
    names: [
      "D-Ornithine",
      "(2R)-2,5-Diaminopentanoic Acid",
      "348-66-3",
      "(R)-2,5-Diaminopentanoic Acid",
      "(R)-Ornithine",
      "UNII-2H368YCK0U",
      "2H368YCK0U",
      "CHEBI:16176",
      "AHLPHDHHMVZTML-SCSAIBSYSA-N",
      "EN300-67290",
      "D-Ornithin",
      "Ornithine, D-",
      "D-Ornitrine",
      "EINECS 206-482-0",
      "D-Orn",
      "NSC-118360",
      "AC1L2FIA",
      "Bmse000897",
      "Bmse000019",
      "NCIStruc1_000044",
      "NCIStruc2_000122",
      "SCHEMBL43724",
      "D-2,5-Diaminopentanoic Acid",
      "(2R)-2,5-Diaminopentanoate",
      "CHEMBL103686",
      "GTPL4682",
      "AC1Q5R10",
      "CTK4H3181",
      "ZINC1532678",
      "NCI118360",
      "CCG-38067",
      "NCGC00014201",
      "FCH950069",
      "AKOS027323605",
      "MCULE-2602820298",
    ],
  },
  {
    primary_id: "D0T1JN",
    names: ["Thiazole Derivative 5"],
  },
  {
    primary_id: "D0T1KL",
    names: ["Pankomab-GEX"],
  },
  {
    primary_id: "D0T1LK",
    names: ["Primaquine"],
  },
  {
    primary_id: "D0T1LX",
    names: ["BC-ONY-01"],
  },
  {
    primary_id: "D0T1MA",
    names: ["Dihydrothieno [2,3-E]indazole Derivative 2"],
  },
  {
    primary_id: "D0T1MI",
    names: ["INP-01"],
  },
  {
    primary_id: "D0T1MK",
    names: ["LY382884"],
  },
  {
    primary_id: "D0T1MP",
    names: ["Lurosetron"],
  },
  {
    primary_id: "D0T1MT",
    names: ["OTK18 TF Mabs"],
  },
  {
    primary_id: "D0T1OG",
    names: ["N-(5-Methanesulfonyl-2-Methyl-Benzoyl)-Guanidine", "CHEMBL539413", "SCHEMBL7125185"],
  },
  {
    primary_id: "D0T1OR",
    names: ["BRX-3"],
  },
  {
    primary_id: "D0T1PA",
    names: ["Hypoglaucin A", "(-)-Hypoglaucine A"],
  },
  {
    primary_id: "D0T1QV",
    names: ["ARA 290"],
  },
  {
    primary_id: "D0T1QY",
    names: ["Technetium Tc-99m Gluceptate Kit"],
  },
  {
    primary_id: "D0T1RG",
    names: ["Sitaxsentan"],
  },
  {
    primary_id: "D0T1RJ",
    names: ["MDT-012"],
  },
  {
    primary_id: "D0T1RM",
    names: [
      "Antivaginolysin Mabs",
      "Antivaginolysin MAbs (Bacterial Vaginosis)",
      "Antivaginolysin MAbs (Bacterial Vaginosis), Uab Profarma/Institute Of Biotechnology IBT",
      "Antivaginolysin Monoclonal Antibodies (Bacterial Vaginosis), Uab Profarma/Institute Of Biotechnology IBT",
    ],
  },
  {
    primary_id: "D0T1RT",
    names: ["Gamma Secretase Inhibitors"],
  },
  {
    primary_id: "D0T1SF",
    names: ["PNU-109291"],
  },
  {
    primary_id: "D0T1SP",
    names: ["PMID22465637C1"],
  },
  {
    primary_id: "D0T1TC",
    names: [
      "C-5304",
      "315228-19-4",
      "2-(2-Fluoro-4-Nitrophenyl)Acetic Acid",
      "2-FLUORO-4-NITROPHENYLACETIC ACID",
      "(2-Fluoro-4-Nitrophenyl)Acetic Acid",
      "Benzeneacetic Acid, 2-Fluoro-4-Nitro-",
      "(2-FLUORO-4-NITRO-PHENYL)-ACETIC ACID",
      "ACMC-209hnb",
      "SCHEMBL2033346",
      "KS-00000CHB",
      "CTK4G7282",
      "DTXSID70622955",
      "2-Fluoro-4-NitrophenylaceticAcid",
      "MolPort-006-826-327",
      "HKGNZXXYXCKJHO-UHFFFAOYSA-N",
      "2-Fluoro-4-NitroBenzeneacetic Acid",
      "ZINC40566661",
      "ANW-27141",
      "CX1226",
      "AKOS005257813",
      "RP25676",
      "CS-W003750",
      "MCULE-1785572911",
      "TRA-0184392",
      "SC-53768",
      "DS-16637",
      "SY005555",
      "KB-62664",
    ],
  },
  {
    primary_id: "D0T1TT",
    names: ["MK-1454"],
  },
  {
    primary_id: "D0T1TU",
    names: ["Etoloxamine"],
  },
  {
    primary_id: "D0T1UH",
    names: ["REC-0467"],
  },
  {
    primary_id: "D0T1UJ",
    names: ["Reteplase"],
  },
  {
    primary_id: "D0T1UU",
    names: ["Benzimidazole Derivative 17"],
  },
  {
    primary_id: "D0T1VK",
    names: ["CJ-30002"],
  },
  {
    primary_id: "D0T1WB",
    names: ["PMID25482888-Compound-70"],
  },
  {
    primary_id: "D0T1WN",
    names: ["Zolpidem"],
  },
  {
    primary_id: "D0T1WY",
    names: [
      "Clevudine",
      "163252-36-6",
      "Levovir",
      "L-FMAU",
      "UNII-IN51MVP5F1",
      "Clevudine (Levovir)",
      "IN51MVP5F1",
      "1-(2-Deoxy-2-Fluoro-Beta-L-Arabinofuranosyl)Thymine",
      "1-((2S,3R,4S,5S)-3-Fluoro-4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl)-5-Methylpyrimidine-2,4(1H,3H)-Dione",
      "AK106264",
      "1-[(2S,3R,4S,5S)-3-Fluoro-4-Hydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl]-5-Methyl-Pyrimidine-2,4-Dione",
      "1-[(2S,3R,4S,5S)-3-Fluoro-4-Hydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-5-Methylpyrimidine-2,4-Dione",
      "Clevudine [USAN:INN]",
      "Revovir",
      "Clevudine (USAN)",
    ],
  },
  {
    primary_id: "D0T1XE",
    names: ["Pergularinine"],
  },
  {
    primary_id: "D0T1XW",
    names: [
      "Meclizine",
      "Ancolan",
      "Ancolon",
      "Antivert",
      "Bonadettes",
      "Bonine",
      "Calmonal",
      "Chiclida",
      "Diadril",
      "Histamethine",
      "Histamethizine",
      "Histametizine",
      "Histametizyn",
      "Histametizyne",
      "Itinerol",
      "Marex",
      "Meclicot",
      "Meclozina",
      "Meclozine",
      "Meclozinum",
      "Medivert",
      "Monamine",
      "Navicalm",
      "Nevidoxine",
      "Parachloramine",
      "Peremesin",
      "Postafene",
      "Ravelon",
      "Sabari",
      "Siguran",
      "Subari",
      "Suprimal",
      "Travelon",
      "Veritab",
      "Vomisseis",
      "Vomissels",
      "Dramamine II",
      "Meclizine Hcl",
      "UCB 170",
      "UCB 5052",
      "UCB 5062",
      "Antivert (TN)",
      "Antivert/25",
      "Antivert/50",
      "Bonamine (TN)",
      "Bonine (TN)",
      "Meclizine [INN:BAN]",
      "Meclozina [INN-Spanish]",
      "Meclozine (BAN)",
      "Meclozinum [INN-Latin]",
      "Neo-Istafene",
      "Neo-Suprimal",
      "Neo-Suprimel",
      "Nevidoxine (TN)",
      "Postafen (TN)",
      "Sea-Legs",
      "Ru-Vert-M",
      "U. C. B. 5062",
      "U.C.B. 5062",
      "(+-)-Meclizine",
      "1-((4-Chlorophenyl)Phenylmethyl)-4-((3-Methylphenyl)Methyl)Piperazine",
      "1-(P-Chloro-.alpha.-Phenylbenzyl)-4-(M-Methylbenzyl)Piperazine",
      "1-(P-Chloro-Alpha-Phenylbenzyl)-4-(M-Methylbenzyl)Piperazine",
      "1-(P-Chlorobenzhydryl)-4-(M-Methylbenzyl)Diethylenediamine",
      "1-(P-Chlorobenzhydryl)-4-(M-Methylbenzyl)Piperazine",
      "1-[(4-Chlorophenyl)(Phenyl)Methyl]-4-[(3-Methylphenyl)Methyl]piperazine",
      "1-[(4-Chlorophenyl)-Phenylmethyl]-4-[(3-Methylphenyl)Methyl]piperazine",
      "1-P-Chlorobenzhydryl-4-M-Methylbenzylpiperazine",
    ],
  },
  {
    primary_id: "D0T1YB",
    names: ["Peptide Analog 23"],
  },
  {
    primary_id: "D0T1YH",
    names: ["ALD-806", "Anti-HGF MAb (Cancer), Alder Biopharmaceuticals"],
  },
  {
    primary_id: "D0T1YN",
    names: ["Tiqueside"],
  },
  {
    primary_id: "D0T1YO",
    names: [
      "GNF-PF-2307",
      "3-Phenyl-5H-Indeno[1,2-C]pyridazin-5-One",
      "NSC663883",
      "77152-22-8",
      "CHEMBL421088",
      "3-Phenylindeno[1,2-C]pyridazin-5-One",
      "5H-Indeno[1,2-C]pyridazin-5-One, 3-Phenyl-",
      "AC1Q6KMU",
      "AC1L8E6X",
      "Oprea1_571099",
      "MLS000539984",
      "Cid_379337",
      "Aza-Heterocyclic Derivative, 1b",
      "KS-00001QSP",
      "CTK2G0333",
      "BDBM19164",
      "DTXSID40327521",
      "MolPort-002-851-051",
      "HMS2177H12",
      "ZINC1383534",
      "STK336084",
      "AKOS005075114",
      "NSC-663883",
      "MCULE-8078785196",
      "SMR000125442",
      "NCI60_021916",
      "3-(Phenyl)-5H-Indeno[1,2-C]pyridazin-5-One",
      "SR-01000694335",
      "10K-057",
    ],
  },
  {
    primary_id: "D0T1YY",
    names: [
      "3-Methyl-4-Phenylbut-3-En-2-One Oxime",
      "5460-65-1",
      "AC1L5FRS",
      "Maybridge4_003045",
      "CTK1H3786",
      "MCULE-8988176310",
      "4-Phenyl-3-Methyl-3-Buten-2-On-Oxim",
      "N-(3-Methyl-4-Phenylbut-3-En-2-Ylidene)Hydroxylamine",
    ],
  },
  {
    primary_id: "D0T1ZJ",
    names: ["Naked DNA (B-Cell Lymphoma) Vical/Stanford"],
  },
  {
    primary_id: "D0T1ZM",
    names: [
      "NSC-745883",
      "2-(4-Cyanophenyl)-1H-Anthra[1,2-D]imidazole-6,11-Dione",
      "1190059-23-4",
      "SCHEMBL2399352",
      "CHEMBL603647",
      "ZINC45390864",
      "2-(4-Cyanophenyl)-1H-Anthra-[1,2-D]imidazole-6,11-Dione, 97%, AldrichCPR",
    ],
  },
  {
    primary_id: "D0T2AE",
    names: ["V-0251"],
  },
  {
    primary_id: "D0T2CA",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 2"],
  },
  {
    primary_id: "D0T2CG",
    names: ["3-Hydroxylauric Acid", "3-Hydroxy Lauric Acid"],
  },
  {
    primary_id: "D0T2DC",
    names: ["3-Cyano-N-(3-Ethylphenyl)-5-Fluorobenzamide", "CHEMBL1209550"],
  },
  {
    primary_id: "D0T2DL",
    names: ["GM-7050"],
  },
  {
    primary_id: "D0T2DP",
    names: ["PMID8496919C7"],
  },
  {
    primary_id: "D0T2EO",
    names: ["MAXY-4", "CTLA4-Ig Proteins (Rheumatoid Arthritis), Maxygen"],
  },
  {
    primary_id: "D0T2EP",
    names: ["CLOTURIN"],
  },
  {
    primary_id: "D0T2ER",
    names: ["TAK-593"],
  },
  {
    primary_id: "D0T2EW",
    names: [
      "Cyclohexylglycine-(2S)-Cyanopyrrolidine",
      "Chg-Pro-CN",
      "CHEMBL307636",
      "BMCL15687 Compound 3",
      "SCHEMBL14392566",
      "BDBM11694",
      "Cyclohexylglycine-(2S)-Cyanopyrolidine 3",
      "Cyclohexylglycine-(2S)-Cyanopyrrolidine 2",
    ],
  },
  {
    primary_id: "D0T2FC",
    names: ["NPC-1C"],
  },
  {
    primary_id: "D0T2FP",
    names: ["JNJ-54861911"],
  },
  {
    primary_id: "D0T2GA",
    names: ["ONO-8713"],
  },
  {
    primary_id: "D0T2GP",
    names: [
      "DP-5599",
      "CFMS Kinase Inhibitors (Autoimmune Diseases)",
      "CFMS Kinase Inhibitors (Autoimmune Diseases), Deciphera",
    ],
  },
  {
    primary_id: "D0T2HN",
    names: ["N-(Naphthalene-2-Yl)Ethyl-ETAV"],
  },
  {
    primary_id: "D0T2IA",
    names: [
      "Dapiprazole",
      "Dapiprazol",
      "Dapiprazolum",
      "Dapirazol",
      "Dapirazolum",
      "Glamidolo",
      "Remydrial",
      "Reversil",
      "Dapiprazol [German]",
      "Dapiprazole [INN]",
      "Dapiprazole (INN)",
      "Dapirazol [INN-Spanish]",
      "Dapirazolum [INN-Latin]",
      "Rev-Eyes",
      "Rev-Eyes (TN)",
      "S-Triazolo(4,3-A)Pyridine, 5,6,7,8-Tetrahydro-3-(2-(4-(O-Tolyl)-1-Piperazinyl)Ethyl)-,hydrochloride",
      "3-(2-(4-(2-Methylphenyl)-1-Piperazinyl)Ethyl)-5,6,7,8,-Tetrahydro-1,2,4-Triazolo(4,3-A)Pyridine Hydrochloride",
      "3-[2-[4-(2-Methylphenyl)Piperazin-1-Yl]ethyl]-5,6,7,8-Tetrahydro-[1,2,4]triazolo[4,3-A]pyridine",
      "3-{2-[4-(2-Methylphenyl)Piperazin-1-Yl]ethyl}-5,6,7,8-Tetrahydro[1,2,4]triazolo[4,3-A]pyridine",
      "5,6,7,8-Tetrahydro-3-(2-(4-(O-Tolyl)-1-Piperazinyl)Ethyl)-S-Triazolo(4,3-A)Pyridine",
    ],
  },
  {
    primary_id: "D0T2IF",
    names: ["DPX-Survivac Cancer Vaccine"],
  },
  {
    primary_id: "D0T2IH",
    names: [
      "GSK610677",
      "82571-53-7",
      "Ozagrel [INN]",
      "Ozagrelum [Latin]",
      "OKY 046",
      "UNII-L256JB984D",
      "(E)-3-[4-(Imidazol-1-Ylmethyl)Phenyl]prop-2-Enoic Acid",
      "CHEMBL11662",
      "(E)-3-(4-((1H-IMIDAZOL-1-YL)METHYL)PHENYL)ACRYLIC ACID",
      "C13H12N2O2",
      "L256JB984D",
      "Ozagrel (INN)",
      "(E)-P-(Imidazol-1-Ylmethyl)Cinnamic Acid",
      "2-Propenoic Acid, 3-(4-(1H-Imidazol-1-Ylmethyl)Phenyl)-, (E)-",
      "Pulmoza",
      "Ozagrelum",
      "3-[4-(1H-IMIDAZOL-1-YLMETHYL)PHENYL]-2E-PROPENOIC ACID",
      "Cataclot (TN)",
      "4-(1-Imidazoylmethyl)Cinnamic Acid",
      "Ozagrel,(S)",
      "Prestwick3_000979",
    ],
  },
  {
    primary_id: "D0T2IQ",
    names: [
      "Actinonin",
      "Actinonine",
      "UNII-P18SPA8N0K",
      "P18SPA8N0K",
      "13434-13-4",
      "CHEMBL308333",
      "Octanohydroxamic Acid, 3-((1-((2-(Hydroxymethyl)-1-Pyrrolidinyl)Carbonyl)-2-Methylpropyl)Carbamoyl)-",
      "2-[(FORMYL-HYDROXY-AMINO)-METHYL]-HEPTANOIC ACID [1-(2-HYDROXYMETHYL-PYRROLIDINE-1-CARBONYL)-2-METHYL-PROPYL]-AMIDE",
      "BB2",
      "Butanediamide, N4-Hydroxy-N1-(1-((2-(Hydroxymethyl)-1-Pyrrolidinyl)Carbonyl)-2-Methylpropyl)-2-Pentyl-, Stereoisomer",
      "SR-01000075681",
      "(?)-Actinonin",
      "4je7",
      "3pn4",
      "3m6p",
      "4dr9",
      "3m6q",
      "3m6r",
      "3g5k",
      "Spectrum2_000628",
      "Spectrum5_000728",
    ],
  },
  {
    primary_id: "D0T2IX",
    names: ["6-((1H-Imidazol-1-Yl)Methyl)-2H-Chromene-2-Thione"],
  },
  {
    primary_id: "D0T2JN",
    names: ["2,7-Bis(Cyclopentanecarbonamido)Anthraquinone", "CHEMBL480939", "SCHEMBL4293737"],
  },
  {
    primary_id: "D0T2KY",
    names: ["PMID19524438C6o"],
  },
  {
    primary_id: "D0T2LN",
    names: ["Thiazole Derivative 2"],
  },
  {
    primary_id: "D0T2LP",
    names: [
      "ARM036",
      "ALADORIAN",
      "UNII-PJ5KSA836G",
      "PJ5KSA836G",
      "865433-00-7",
      "Aladorian [USAN:INN]",
      "Aladorian (USAN/INN)",
      "S44121-1",
      "SCHEMBL3423110",
      "CHEMBL2104951",
      "DTXSID40235657",
      "FPWBHFYYVSNIFZ-UHFFFAOYSA-N",
      "S44121",
      "D10304",
      "(7-Methoxy-2,3-Dihydro-1,4-Benzothiazepin-4(5H)-Yl)Oxoacetic Acid",
      "1,4-Benzothiazepine-4(5H)-Acetic Acid, 2,3-Dihydro-7-Methoxy-Alpha-Oxo-",
      "2-(7-Methoxy-2,3-Dihydrobenzo[f][1,4]thiazepin-4(5H)-Yl)-2-Oxoacetic Acid",
    ],
  },
  {
    primary_id: "D0T2LR",
    names: ["PMX-30063 Topical Oral Rinse"],
  },
  {
    primary_id: "D0T2MN",
    names: [
      "Z-300",
      "2-(2-Hydroxyethylsulfanyl)-N-[3-[3-(Piperidin-1-Ylmethyl)Phenoxy]propyl]acetamide 2-(4-Hydroxybenzoyl)Benzoate",
    ],
  },
  {
    primary_id: "D0T2MS",
    names: ["ZX002"],
  },
  {
    primary_id: "D0T2MV",
    names: ["AS-9705"],
  },
  {
    primary_id: "D0T2NE",
    names: ["WO2012007375C7"],
  },
  {
    primary_id: "D0T2NG",
    names: ["SOM-1025"],
  },
  {
    primary_id: "D0T2NV",
    names: ["LAMP-Vax + Pp65 DC"],
  },
  {
    primary_id: "D0T2OT",
    names: ["GW-278884", "BW-668W95", "668W95"],
  },
  {
    primary_id: "D0T2OX",
    names: ["PMID28270010-Compound-Figure5-2"],
  },
  {
    primary_id: "D0T2PL",
    names: [
      "Calcitriol",
      "Rocaltrol",
      "32222-06-3",
      "Calcijex",
      "Topitriol",
      "1alpha,25-Dihydroxyvitamin D3",
      "Silkis",
      "Soltriol",
      "1alpha,25-Dihydroxycholecalciferol",
      "Calcitriolum",
      "Dihydroxyvitamin D3",
      "1,25-DHCC",
      "1,25-Dihydroxyvitamin D",
      "Vectical",
      "1,25-DIHYDROXYCHOLECALCIFEROL",
      "1,25-Dihydroxyvitamin D3",
      "1alpha,25-Dihydroxyvitamin D",
      "1-Alpha,25-Dihydroxyvitamin D3",
      "Calcitriolum [INN-Latin]",
      "UNII-FXC9231JVH",
      "1alpha,25(OH)2D3",
      "DN-101",
      "1,25-Dihydroxycholecaliferol",
      "CCRIS 5522",
      "Ro 21-5535",
      "HSDB 3482",
      "1-Alpha,25-Dihydroxycholecalciferol",
      "EINECS 250-963-8",
      "DN 101",
      "Calcitriol (Chemotherapy-Induced Alopecia)",
      "Calcitriol (Chemotherapy-Induced Alopecia), Cytotech Labs",
      "Calcitriol (API-31543, Drug-Induced Alopecia), Cytotech Labs",
      "1alpha,25 Dihydroxyvitamin D(3)",
    ],
  },
  {
    primary_id: "D0T2PT",
    names: ["MTbuVax"],
  },
  {
    primary_id: "D0T2QI",
    names: ["MPV-1743"],
  },
  {
    primary_id: "D0T2QL",
    names: [
      "Cinnamic Acid",
      "CINNAMIC ACID",
      "TRANS-CINNAMIC ACID",
      "140-10-3",
      "621-82-9",
      "(E)-Cinnamic Acid",
      "Trans-3-Phenylacrylic Acid",
      "3-Phenylacrylic Acid",
      "Phenylacrylic Acid",
      "Zimtsaeure",
      "(2E)-3-Phenylprop-2-Enoic Acid",
      "3-Phenylprop-2-Enoic Acid",
      "E-Cinnamic Acid",
      "3-Phenylpropenoic Acid",
      "(E)-3-Phenylprop-2-Enoic Acid",
      "Trans-Beta-Carboxystyrene",
      "Benzenepropenoic Acid",
      "Trans-Cinnamate",
      "(E)-3-Phenyl-2-Propenoic Acid",
      "(E)-Cinnamate",
      "Benzeneacrylic Acid",
      "Trans-3-Phenyl-2-Propenoic Acid",
      "Cinnamylic Acid",
      "Cinnamic Acid, (E)-",
      "T-Cinnamic Acid",
      "(2E)-3-Phenyl-2-Propenoic",
    ],
  },
  {
    primary_id: "D0T2QN",
    names: ["PF-2393296"],
  },
  {
    primary_id: "D0T2QP",
    names: ["PMID29338548-Compound-22"],
  },
  {
    primary_id: "D0T2RU",
    names: ["PF-277343"],
  },
  {
    primary_id: "D0T2RY",
    names: [
      "4-(4-Butylpiperidin-1-Yl)-1-O-Tolylbutan-1-One",
      "AC-42",
      "CHEMBL1242950",
      "AC42",
      "GTPL289",
      "SCHEMBL4504348",
      "ANTKBACNWQHQJE-UHFFFAOYSA-N",
      "ZINC2022527",
      "BDBM50326219",
      "AKOS030284249",
      "NCGC00485453-01",
      "Gamma-(4-Butylpiperidino)-2'-Methylbutyrophenone",
      "L019209",
      "4-(4-Butylpiperdin-1-Yl)-1-(2-Methylphenyl)Butan-1-One",
      "4-(4-Butylpiperidin-1-Yl)-1-(2-Methylphenyl)Butan-1-One",
    ],
  },
  {
    primary_id: "D0T2RZ",
    names: ["Moroctocog Alfa"],
  },
  {
    primary_id: "D0T2SD",
    names: [
      "4-(4-Methyl-Phenyliminomethyl)-Benzenesulfonamide",
      "CHEMBL271962",
      "SCHEMBL5309671",
      "GFCNORPCMFOXDQ-UHFFFAOYSA-N",
      "BDBM50371756",
    ],
  },
  {
    primary_id: "D0T2SJ",
    names: [
      "A-313326",
      "CHEMBL41748",
      "SCHEMBL7083765",
      "BDBM13389",
      "A313326",
      "4-[alpha-(1-Methyl-1H-Imidazole-5-Yl)-3-(1-Naphthyl)-4-Cyanobenzyloxymethyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0T2SK",
    names: ["AmZaar", "Antihypertensive Combination (Once-Daily Tablet), Renuvia"],
  },
  {
    primary_id: "D0T2ST",
    names: ["Azotropin", "Azotopin", "Infitropin CR"],
  },
  {
    primary_id: "D0T2TK",
    names: ["ZD6416"],
  },
  {
    primary_id: "D0T2TW",
    names: [
      "3-[5-(3-Nitrophenyl)Thiophen-2-Yl]propanoic Acid",
      "53N",
      "DB07139",
      "3-[5-(3-Nitrophenyl)Thiophen-2-Yl] Propanoic Acid",
    ],
  },
  {
    primary_id: "D0T2UB",
    names: ["APCK-110", "APcK-110MD", "C-Kit Inhibitors (Cancer)", "C-Kit Inhibitors (Cancer), MD Anderson"],
  },
  {
    primary_id: "D0T2VG",
    names: [
      "1-Pyridin-2-Yl-3-Quinolin-5-Yl-Urea",
      "Diarylurea Deriv. 14b",
      "AC1NS9HW",
      "BDBM6657",
      "CHEMBL143704",
      "ZINC13471117",
      "1-Pyridin-2-Yl-3-Quinolin-5-Ylurea",
      "3-Pyridin-2-Yl-1-Quinolin-5-Ylurea",
      "1-(5-Quinolyl)-3-(2-Pyridyl)Urea",
      "N-Pyridin-2-Yl-N -Quinolin-5-Ylurea",
    ],
  },
  {
    primary_id: "D0T2VH",
    names: ["N-(5-Tert-Butyl-1,3,4-Thiadiazol-2-Yl)Sulfamide", "CHEMBL475775"],
  },
  {
    primary_id: "D0T2VK",
    names: [
      "3-(Aminomethyl)-4-(Furan-3-Yl)Butanoic Acid",
      "CHEMBL381742",
      "3-Aminomethyl-4-(Furan-3-Yl)Butyric Acid",
      "311804-13-4",
      "SCHEMBL6376423",
    ],
  },
  {
    primary_id: "D0T2WM",
    names: ["N-(2,4-Dihydroxybenzyl)-3,4-Dihydroxybenzamide", "CHEMBL206902"],
  },
  {
    primary_id: "D0T2XU",
    names: [
      "Promethazine",
      "Antiallersin",
      "Aprobit",
      "Avomine",
      "Camergan",
      "Dimapp",
      "Diphergan",
      "Diprazin",
      "Diprazine",
      "Diprozin",
      "Fargan",
      "Fenazil",
      "Fenetazina",
      "Fenetazine",
      "Genphen",
      "Hiberna",
      "Histargan",
      "Iergigan",
      "Isophenergan",
      "Isopromethazine",
      "Lercigan",
      "Lergigan",
      "Metaryl",
      "Pelpica",
      "Phargan",
      "Phenargan",
      "Phenerzine",
      "Phensedyl",
      "Pilothia",
      "Pilpophen",
      "Pipolphene",
      "Proazaimine",
      "Proazamine",
      "Procit",
      "Promacot",
      "Promazinamide",
      "Promergan",
      "Promesan",
      "Prometasin",
      "Prometazin",
      "Prometazina",
      "Prometazine",
      "Prometh",
      "Promethacon",
      "Promethaine",
      "Promethazin",
      "PromethazineHcl",
      "Promethazinum",
      "Promethegan",
      "Promethiazine",
      "Promezathine",
      "Prorex",
      "Protazine",
      "Prothazin",
      "Prothazine",
      "Provigan",
      "Pyrethia",
      "Pyrethiazine",
      "Remsed",
      "Romergan",
      "Rumergan",
      "Tanidil",
      "Thiergan",
      "Valergine",
      "Vallergine",
      "Lilly 01516",
      "Lilly 1516",
      "RP 3277",
      "SKF 1498",
      "WY 509",
      "A-91033",
      "Atosil (TN)",
      "Avomine (TN)",
      "Fargan (TN)",
      "Farganesse (TN)",
      "Lergigan (TN)",
      "Phenergan (TN)",
      "Phenoject-50",
      "Pro-50",
      "Prometazina [INN-Spanish]",
      "Promethazine [INN:BAN]",
      "Promethazinum [INN-Latin]",
      "Promethegan (TN)",
      "Prothiazine (TN)",
      "Receptozine (TN)",
      "Romergan (TN)",
      "Zipan-25",
      "Dimethylamino-Isopropyl-Phenthiazin",
      "Dimethylamino-Isopropyl-Phenthiazin [German]",
      "Promethazine (JAN/INN)",
      "N-Dimethylamino-2-Methylethyl Thiodiphenylamine",
      "PROMETHAZINE (SEE ALSO PROMETHAZINE HYDROCHLORIDE 58-33-3)",
      "N,N,alpha-Trimethyl-10H-Phenothiazine-10-Ethanamine",
      "N-(2'-Dimethylamino-2'-Methyl)Ethylphenothiazine",
      "N,N-Dimethyl-1-Phenothiazin-10-Ylpropan-2-Amine",
      "N,N-Dimethyl-1-Phenothiazin-10-Ylpropan-2-Amine Hydrochloride",
      "N,N-Dimethyl-1-(10H-Phenothiazin-10-Yl)Propan-2-Amine",
      "(2-Dimethylamino-2-Methyl)Ethyl-N-Dibenzoparathiazine",
      "(Dimethylamino-2-Propyl-10-Phenothiazine Hydrochloride",
      "10-(2-(Dimethylamino)-2-Methylethyl)Phenothiazine",
      "10-(2-(Dimethylamino)Propyl)Phenothiazine",
      "10-(2-Dimethylaminopropyl)Phenothiazine",
      "10-[2-(Dimethylamino)Propyl]phenothiazine",
      "10H-Phenothiazine-10-Ethanamine, N,N,alpha-Trimethyl-(9CI)",
      "10H-Phenothiazine-10-Ethanamine, N,N,alpha-Trimethyl-, Radical Ion(1+)",
      "3389 R.p.",
    ],
  },
  {
    primary_id: "D0T2YP",
    names: ["2-Hydroxyethyl 18-Methoxycoronaridinate"],
  },
  {
    primary_id: "D0T2YQ",
    names: [
      "N-Chlorotaurine",
      "ATV-02",
      "ACU-NCT-001",
      "Broad-Spectrum Antimicrobial, Atlantic Technology",
      "N-Chlorotaurine-Sodium",
      "N-Chlorotaurine (Ophthalmic Formulation), Acuity",
      "N-Chlorotaurine (Ophthalmic Formulation), OPKO",
      "N-Chlorotaurine (Antimicrobial/ Infections/ Sinusitis)",
      "N-Chlorotaurine (Antimicrobial/ Infections/ Sinusitis), Pathogenics",
    ],
  },
  {
    primary_id: "D0T2YZ",
    names: [
      "N-(4-Sulfamoyl-Phenyl)-Isobutyramide",
      "2-Methyl-N-(4-Sulfamoylphenyl)Propanamide",
      "Propanamide, N-[4-(Aminosulfonyl)Phenyl]-2-Methyl-",
      "114841-20-2",
      "CHEMBL23417",
      "ACMC-20mkv1",
      "AC1MM1G7",
      "SCHEMBL13391029",
      "CTK0G0891",
      "BDBM16647",
      "Aromatic Sulfonamide Compound 12",
      "DTXSID70390682",
      "4-Isobutanamido-Benzenesulfonamide",
      "MolPort-006-401-341",
      "ZINC5384022",
      "AKOS003848097",
      "MCULE-4244298519",
      "N-[4-(Aminosulfonyl)Phenyl]-2-Methylpropanamide, AldrichCPR",
    ],
  },
  {
    primary_id: "D0T2ZQ",
    names: [
      "Felbinac Prodrug",
      "Daitac",
      "Napageln",
      "Seltouch",
      "BPAA Acyl Ester",
      "Felbinac Prodrug (Liposomal)",
      "LM-001",
      "Lipo-BPAA",
      "Felbinac Prodrug (Liposomal), Lederle",
    ],
  },
  {
    primary_id: "D0T2ZX",
    names: ["Radioimmunotherapy"],
  },
  {
    primary_id: "D0T3AD",
    names: [
      "Pentostatin",
      "Coforin",
      "Covidarabine",
      "Deoxycoformycin",
      "Nipent",
      "Oncopent",
      "Vidarbine",
      "Vira A Deaminase Inhibitor",
      "CL-67310465",
      "CO-Vidarabine",
      "Co-V",
      "Nipent (TN)",
      "PD-81565",
      "PD-ADI",
      "Pentostatin (JAN/USAN/INN)",
      "(8R)-3-(2-Deoxy-Beta-D-Erythro-Pentofuranosyl)-3,6,7,8-Tetrahydroimidazo[4,5-D][1,3]diazepin-8-Ol",
      "(8R)-3-[(2R,4S,5R)-4-Hydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-7,8-Dihydro-4H-Imidazo[4,5-D][1,3]diazepin-8-Ol",
      "(R)-3-(2-Deoxy-.beta.-D-Erythro-Pentofuranosyl)-3,6,7,8-Tetrahydroimidazo(4,5-D)(1,3)Diazepin-8-Ol",
      "2'-DCF",
      "2'-Deoxycoformycin",
      "2'-Dexoycoformycin",
    ],
  },
  {
    primary_id: "D0T3AE",
    names: ["Phosphoenolpyruvate"],
  },
  {
    primary_id: "D0T3AP",
    names: ["Amfetamine Transdermal"],
  },
  {
    primary_id: "D0T3AT",
    names: [
      "BIC1",
      "CHEMBL1738777",
      "1-[2-(1H-Benzimidazol-2-Ylsulfanyl)Ethyl]-3-Methyl-1,3-Dihydro-2H-Benzimidazole-2-Thione",
      "1-[2-(1H-Benzimidazol-2-Ylsulfanyl)Ethyl]-3-Methylbenzimidazole-2-Thione",
      "AC1LDQIR",
      "TimTec1_003670",
      "Oprea1_027553",
      "433249-32-2",
      "GTPL7510",
      "SCHEMBL8248939",
      "ZINC34355",
      "MolPort-000-901-027",
      "HMS1544G18",
      "BDBM50365462",
      "STK767484",
      "AKOS003573654",
      "MCULE-4889087096",
      "1-[2-(1h-Benzimidazol-2-Ylthio)Ethyl]-1,3-Dihydro-3-Methyl-2h-Benzimidazole-2-Thione",
      "ST049583",
      "KB-258991",
      "SR-01000537551",
      "SR-01000537551-1",
      "BRD-K75297647-001-01-4",
    ],
  },
  {
    primary_id: "D0T3AW",
    names: ["Antroquinonol"],
  },
  {
    primary_id: "D0T3BL",
    names: [
      "1-(4-Chlorophenethyl)-1H-Imidazole",
      "CHEMBL379290",
      "1H-Imidazole, 1-[2-(4-Chlorophenyl)Ethyl]-",
      "BDBM50191793",
      "56643-90-4",
    ],
  },
  {
    primary_id: "D0T3BX",
    names: ["IFN-Beta"],
  },
  {
    primary_id: "D0T3BZ",
    names: ["ISIS 9068"],
  },
  {
    primary_id: "D0T3CB",
    names: ["Ono 1"],
  },
  {
    primary_id: "D0T3CE",
    names: [
      "SUPER-LEU-DOX",
      "CPI-0004",
      "CPI-0004Na",
      "CPI-004Na",
      "DTS-201",
      "TAP-Doxorubicin",
      "TSP-Doxorubicin",
      "Phthacene-5,12-Dione Sodium Salt",
      "N-[N-(3-Carboxypropionyl)-Beta-Alanyl-L-Leucyl-L-Alanyl-L-Leucyl]daunorubicin Sodium Salt",
      "(8S,10S)-10-[3-[N-(3-Carboxy-1-Oxopropyl)-Beta-Alanyl-L-Leucyl-L-Alanyl-L-Leucylamido]-2,3,6-Trideoxy-Alpha-L-Lyxo-Hexopyranosyloxy]-8-(Hydroxyacetyl)-6,8,11-Trihydroxy-1-Methoxy-7,8,9,10-Tetrahydrona",
    ],
  },
  {
    primary_id: "D0T3CH",
    names: ["RGI-2001"],
  },
  {
    primary_id: "D0T3CM",
    names: ["BOLINAQUINONE", "Bolinaquinone", "SCHEMBL1231059"],
  },
  {
    primary_id: "D0T3CP",
    names: ["N-Oleoyl-O-(2-Hydroxyethyl)Hydroxylamine", "CHEMBL208270", "BDBM50185033"],
  },
  {
    primary_id: "D0T3DG",
    names: ["BCI-632"],
  },
  {
    primary_id: "D0T3DO",
    names: [
      "IRX-5183",
      "AGN-195183",
      "NRX-195183",
      "NRX-5183",
      "VTP-195183",
      "VTP-5183",
      "IRX-5183 (Oral, Autoimmune Disease), Io Therapeutics",
      "RARa (Oral, Cancer/Autoimmune Disease), Io Therapeutics",
      "RARa Agonist (Topical, Skin/Eye Diseases), Io Therapeutics",
      "Retinoic Acid Receptor Alpha Agonist (Oral, Cancer/Autoimmune Disease), Io Therapeutics",
      "Retinoic Acid Receptor Alpha Agonist (Topical, Skin/Eye Diseases), Io Therapeutics",
      "IRX-5183 (Oral, Cancer/Autoimmune Disease), Io Therapeutics",
      "IRX-5183 (Topical, Skin/Eye Diseases), Io Therapeutics",
    ],
  },
  {
    primary_id: "D0T3ED",
    names: ["NSC-666292", "CHEMBL495565", "BDBM50265440"],
  },
  {
    primary_id: "D0T3EV",
    names: ["SR-146131"],
  },
  {
    primary_id: "D0T3FE",
    names: ["ISIS 112711"],
  },
  {
    primary_id: "D0T3FN",
    names: ["RLX030"],
  },
  {
    primary_id: "D0T3FW",
    names: ["1-(2-Adamantyl)-3-Benzylpyrrolidin-2-One", "CHEMBL218286", "CHEMBL3085039", "SCHEMBL13435191"],
  },
  {
    primary_id: "D0T3GH",
    names: ["ISIS 102624"],
  },
  {
    primary_id: "D0T3HK",
    names: [
      "1-(Pyridin-4-Yl)Piperazine",
      "1-(4-Pyridyl)Piperazine",
      "1008-91-9",
      "1-Pyridin-4-Yl-Piperazine",
      "4-Piperazinopyridine",
      "1-(4-Pyridyl)-Piperazine",
      "4-Pyridylpiperazine",
      "1-Pyridin-4-Ylpiperazine",
      "1-(4-Pyridyl) Piperazine",
      "PIPERAZINE, 1-(4-PYRIDINYL)-",
      "MFCD00040745",
      "4-(1-Piperazino)Pyridine",
      "1-(4-Pyridinyl)Piperazine",
      "CHEMBL339069",
      "4-(1-PIPERAZINYL)PYRIDINE",
      "1-[4-Pyridyl]piperazine",
      "EINECS 213-764-7",
      "4-Pyridyl Piperazine",
      "(4-Pyridyl)Piperazine",
      "PubChem22899",
      "L-(4-Pyridyl)Piperazine",
      "AC1Q4WUQ",
      "N-(4-Pyridyl)Piperazine",
    ],
  },
  {
    primary_id: "D0T3HL",
    names: [
      "Cx-611",
      "Allogeneic Adipose-Derived Stem Cell Therapy (Intravenous, Rheumatoid Arthritis), Cellerix",
      "Allogeneic Adipose-Derived Stem Cell Therapy (Intravenous, Rheumatoid Arthritis), TiGenix",
    ],
  },
  {
    primary_id: "D0T3HU",
    names: ["Sarcoma Cancer Vaccine"],
  },
  {
    primary_id: "D0T3HY",
    names: ["Levorphanol"],
  },
  {
    primary_id: "D0T3IH",
    names: [
      "[3H]N-Methylspiperone",
      "3-N-Methylspiperone",
      "N-Methylspiperone",
      "N-Methylspiroperidol",
      "87539-19-3",
      "UNII-5U54JM2T8G",
      "5U54JM2T8G",
      "CHEMBL267932",
      "8-[4-(4-Fluorophenyl)-4-Oxobutyl]-3-Methyl-1-Phenyl-1,3,8-Triazaspiro[45]decan-4-One",
      "8-(4-(4-Fluorophenyl)-4-Oxobutyl)-3-Methyl-1-Phenyl-1,3,8-Triazaspiro(45)Decan-4-One",
      "1,3,8-Triazaspiro(45)Decan-4-One, 8-(4-(4-Fluorophenyl)-4-Oxobutyl)-3-Methyl-1-Phenyl-",
      "C24H28FN3O2",
      "[3H]methylspiperone",
      "Mespiperone",
      "N-METHYLSPIPERONE",
    ],
  },
  {
    primary_id: "D0T3JF",
    names: ["GliAtak, TN"],
  },
  {
    primary_id: "D0T3JW",
    names: ["U-99194A"],
  },
  {
    primary_id: "D0T3JZ",
    names: ["ABBV-085"],
  },
  {
    primary_id: "D0T3KI",
    names: [
      "Naratriptan",
      "Colatan",
      "Naramig",
      "Naratriptanum",
      "Amerge (TN)",
      "Naramig (TN)",
      "Naratriptan (INN)",
      "Amerge, Naramig,Naratriptan",
      "N-Methyl-2-(3-(1-Methylpiperiden-4-Yl)Indole-5-Yl)Ethanesulfonamide",
      "N-Methyl-2-[3-(1-Methylpiperidin-4-Yl)-1H-Indol-5-Yl]ethanesulfonamide",
      "N-Methyl-2-[3-(1-Methyl-4-Piperidyl)-1H-Indol-5-Yl]-Ethanesulfonamide",
    ],
  },
  {
    primary_id: "D0T3KK",
    names: ["KNI-10093"],
  },
  {
    primary_id: "D0T3KW",
    names: [
      "AS-337",
      "AS-343",
      "Adamantane Analogs (HIV-1 Infection)",
      "Adamantane Analogs (HIV-1 Infection), UNMC/RAMS",
      "Adamantane Analogs (HIV-1 Infection), University Of Nebraska Medical Center/Russian Academy Of Medical Sciences",
    ],
  },
  {
    primary_id: "D0T3KZ",
    names: ["TENOSAL"],
  },
  {
    primary_id: "D0T3LF",
    names: [
      "Dextroamphetamine",
      "D-Amphetamine",
      "Dexamphetamine",
      "Dexamfetamine",
      "(S)-Amphetamine",
      "Dexadrine",
      "(+)-(S)-Amphetamine",
      "Dexedrine",
      "(2S)-1-Phenylpropan-2-Amine",
      "(S)-(+)-Amphetamine",
      "Dextrostat",
      "Desamfetamina",
      "(S)-1-Phenyl-2-Propylamine",
      "Dexidrine",
      "Sympamin",
      "Dephadren",
      "Amsustain",
      "(+)-Phenaminum",
      "(+)-Alpha-Methylphenethylamine",
      "(S)-1-Phenyl-2-Aminopropane",
      "(+)-Alpha-Methylphenylethylamine",
      "D-(S)-Amphetamine",
      "(S)-(+)-Beta-Phenylisopropylamine",
      "51-64-9",
      "(S)-Alpha-Phenylethylamine",
      "(2S)-(+)-Amphetamine",
      "Benzedrine",
      "Dexacaps",
      "Dexamfetamina",
      "Dexamfetaminum",
      "Dexamphetaminum",
      "Dexanfetamina",
      "Isoamycin",
      "Propisamine",
      "Psychedrine",
      "Raphetamine",
      "Rhinalator",
      "Simpatedrin",
      "Sympamine",
      "Sympatedrine",
      "Weckamine",
      "Desamfetamina [DCIT]",
      "Dexedrine Spansule",
      "Dextroamphetamine [USAN]",
      "Dextroamphetamine Resin Complex",
      "D-AM",
      "Dexamfetamina [INN-Spanish]",
      "Dexamfetamine (INN)",
      "Dexamfetaminum [INN-Latin]",
      "Dexamphetaminum [INN-Latin]",
      "Dexanfetamina [INN-Spanish]",
      "Dexedrine (TN)",
      "Dextro-Amphetamine",
      "Dextro-Amphetamine Sulfate",
      "Dextroamphetamine (USAN)",
      "Dextrostat (TN)",
      "Dl-Amphetamine",
      "Dl-Benzedrine",
      "Fenylo-Izopropylaminyl",
      "Beta-Phenyl-Isopropylamine",
      "D-Alpha-Methylphenethylamine",
      "Alpha-Methylphenethylamine, D-Form",
      "S(+)-Amphetamine",
      "D-(+)-Amphetamine",
      "D-1-Phenyl-2-Aminopropan",
      "D-1-Phenyl-2-Aminopropan [German]",
      "D-1-Phenyl-2-Aminopropane",
      "D-2-Amino-1-Phenylpropane",
      "Dl-1-Phenyl-2-Aminopropane",
      "Benzeneethanamine, Alpha-Methyl-, (AS)-(9CI)",
      "Phenethylamine, Alpha-Methyl-, (+)-(8CI)",
      "(+/-)-Benzedrine",
      "(+/-)-Desoxynorephedrine",
      "(+/-)-Beta-Phenylisopropylamine",
      "(S)-1-Phenyl-2-Propanamine",
      "(S)-Alpha-Methylphenethylamine",
      "(S)-Alpha-Methylbenzeneethanamine",
      "(AlphaS)-Alpha-Methylbenzeneethanamine",
      "Dextroamphetamine Sulfate (Oral Liquid, ADHD), Auriga",
    ],
  },
  {
    primary_id: "D0T3ME",
    names: [
      "BIRB 796",
      "Doramapimod",
      "BIRB796",
      "BIRB-796",
      "Doramapimod, BIRB 796",
      "Doramapimod (USAN/INN)",
      "1-(3-(1,1-Dimethylethyl)-1-(4-Methylphenyl)-1H-Pyrazol-5-Yl)-3-(4-(2-(Morpholin-4-Yl)Ethoxy)Naphthalen-1-Yl)Urea",
      "1-(3-Tert-Butyl-1-P-Tolyl-1H-Pyrazol-5-Yl)-3-(4-(2-Morpholinoethoxy)Naphthalen-1-Yl)Urea",
      "1-(5-TERT-BUTYL-2-P-TOLYL-2H-PYRAZOL-3-YL)-3-[4-(2-MORPHOLIN-4-YL-ETHOXY)-NAPHTHALEN-1-YL]-UREA",
      "1-(5-Tert-Butyl-2-P-Tolyl-2H-Pyrazol-3-Yl)-3(4-(2-Morpholin-4-Yl-Ethoxy)Naph-Thalen-1-Yl)Urea",
      "1-(5-Tert-Butyl-2-P-Tolyl-2H-Pyrazol-3-Yl)-3(4-(2-Morpholin-4-Yl-Ethoxy)Naphthalen-1-Yl)Urea",
      "1-[5-Tert-Butyl-2-(4-Methylphenyl)Pyrazol-3-Yl]-3-[4-(2-Morpholin-4-Ylethoxy)Naphthalen-1-Yl]urea",
    ],
  },
  {
    primary_id: "D0T3NB",
    names: ["Sanguinarine"],
  },
  {
    primary_id: "D0T3NH",
    names: ["MBS-105", "Bismuth Thiol Based Anti-Bacterial Agent (Systemic Formulation), Microbion"],
  },
  {
    primary_id: "D0T3NP",
    names: ["Methyl P-Hydroxybenzoate"],
  },
  {
    primary_id: "D0T3NY",
    names: [
      "Crotamiton",
      "483-63-6",
      "N-Ethyl-O-Crotonotoluidide",
      "Crotamitone",
      "Crotalgin",
      "Eurax",
      "Veteusan",
      "Euraxil",
      "Eurasil",
      "Trans-Crotamiton",
      "Crotamitonum",
      "Crotamitex",
      "Crotan",
      "Crotonyl-N-Ethyl-O-Toluidine",
      "124236-29-9",
      "Caswell No. 431B",
      "Crotamitonum [INN-Latin]",
      "UNII-2EEH27851Y",
      "O-CROTONOTOLUIDIDE, N-ETHYL-",
      "EINECS 207-596-3",
      "EPA Pesticide Chemical Code 055701",
      "BRN 3275497",
      "(E)-N-Ethyl-N-(O-Tolyl)But-2-Enamide",
      "N-Ethyl-N-(2-Methylphenyl)-2-Butenamide",
      "AI3-17732",
      "DNTGGZPQPQTDQF-XBXARRHUSA-N",
      "2-Butenamide, N-Ethyl-N-(2-Methylphenyl)-",
      "2EEH27851Y",
    ],
  },
  {
    primary_id: "D0T3PR",
    names: ["Trifluoromethylketone"],
  },
  {
    primary_id: "D0T3PV",
    names: [
      "4-Hydroxy-1,2,5-Thiadiazole-3-Carboxylic Acid",
      "4-HYDROXY-1,2,5-THIADIAZOLE-3-CARBOXYLIC ACID",
      "LDHA Inhibitor, 1",
      "45654-48-6",
      "GBD",
      "AC1L9M6Z",
      "C3H2N2O3S",
      "1,2,5-Thiadiazole, TDA1",
      "SCHEMBL2573610",
      "CHEMBL1232973",
      "BDBM23251",
      "MolPort-006-168-875",
      "ZINC6523896",
      "AKOS006380523",
      "DB03162",
      "4-Oxo-1,2,5-Thiadiazole-3-Carboxylic Acid",
      "Z2467454497",
      "4-Oxo-4,5-Dihydro-1,2,5-Thiadiazole-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0T3PW",
    names: ["Myricetin"],
  },
  {
    primary_id: "D0T3QN",
    names: ["(S)-6-Amino-2-(2-Imino-Ethylamino)-Hexanoic Acid"],
  },
  {
    primary_id: "D0T3QR",
    names: ["GSK679586"],
  },
  {
    primary_id: "D0T3QU",
    names: ["MBL-HCV1", "Anti-HCV Antibody, MassBiologics"],
  },
  {
    primary_id: "D0T3QZ",
    names: ["[3H]GR65630", "[3H]-GR65630"],
  },
  {
    primary_id: "D0T3RR",
    names: ["ISIS-ANGPTL3"],
  },
  {
    primary_id: "D0T3SY",
    names: ["8-Methoxy-2-Methyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0T3TZ",
    names: ["Benzimidazole Derivative 6"],
  },
  {
    primary_id: "D0T3UP",
    names: [
      "1,3-Dihydro-Naphtho[2,3-D]imidazol-2-One",
      "1H-Naphtho[2,3-D]imidazol-2(3H)-One",
      "5649-76-3",
      "CHEMBL316599",
      "SCHEMBL17206226",
      "SCHEMBL11357960",
      "ZINC13824637",
      "BDBM50007236",
      "FCH995480",
      "AKOS024262366",
      "1H-Naphth[2,3-D]imidazol-2(3H)-One",
      "AJ-64431",
      "AX8292028",
      "1,3-Dihydro-2H-Naphth[2,3-D]imidazol-2-One",
    ],
  },
  {
    primary_id: "D0T3VF",
    names: ["GSK3050002"],
  },
  {
    primary_id: "D0T3VO",
    names: ["DX-619"],
  },
  {
    primary_id: "D0T3VP",
    names: [
      "4'-FLUORO-1,1'-BIPHENYL-4-CARBOXYLIC ACID",
      "5731/10/2",
      "4-(4-Fluorophenyl)Benzoic Acid",
      "4'-Fluoro-Biphenyl-4-Carboxylic Acid",
      "4'-Fluorobiphenyl-4-Carboxylic Acid",
      "4'-Fluoro-Biphenyl-4-Carboxylic Aci",
      "4'-Fluoro-[1,1'-Biphenyl]-4-Carboxylic Acid",
      "CHEMBL106708",
      "MFCD01631909",
      "4-(4-Fluorophenyl)Benzoic Acid, 97%",
      "4'-Fluoro[1,1'-Biphenyl]-4-Carboxylic Acid",
      "4FC",
      "4'-Fluoro(1,1'-Biphenyl)-4-Carboxylic Acid",
      "1ysg",
      "4-Biphenyl-4'-Fluoro-Carboxylicacid",
      "4-Biphenyl-4'-Fluoro-Carboxylic Acid",
      "PubChem10277",
      "1,5-Dibenzoyloxybenzene",
    ],
  },
  {
    primary_id: "D0T3VY",
    names: ["RBT-201"],
  },
  {
    primary_id: "D0T3WL",
    names: ["GSK2801"],
  },
  {
    primary_id: "D0T3XC",
    names: ["(+/-)-2-(4'-Butoxyphenyl)Thiomorpholine", "CHEMBL597372", "BDBM50307367"],
  },
  {
    primary_id: "D0T3XK",
    names: ["Natriuretic Peptides"],
  },
  {
    primary_id: "D0T3YS",
    names: [
      "CL-385004",
      "UNII-YS11IQJ45S",
      "YS11IQJ45S",
      "CHEMBL300946",
      "SCHEMBL5738957",
      "BDBM50078663",
      "Benzamide, 5-Fluoro-2-Methyl-N-(5-(5H-Pyrrolo(2,1-C)(1,4)Benzodiazepin-10(11H)-Ylcarbonyl)-2-Pyridinyl)-",
      "180416-31-3",
      "L015160",
    ],
  },
  {
    primary_id: "D0T3YY",
    names: ["MS417"],
  },
  {
    primary_id: "D0T3ZL",
    names: ["RC-552"],
  },
  {
    primary_id: "D0T3ZS",
    names: ["(+/-)-2-(4'-Benzyloxyphenyl)Thiomorpholin-5-One", "CHEMBL598394", "BDBM50307363"],
  },
  {
    primary_id: "D0T4AC",
    names: ["(8R,8'R,9'S)-5-Methoxyclusin", "CHEMBL482233", "BDBM50259849"],
  },
  {
    primary_id: "D0T4AN",
    names: ["4-(4-Butoxy-Phenyl)-N-Hydroxy-4-Oxo-Butyramide", "CHEMBL197632"],
  },
  {
    primary_id: "D0T4BG",
    names: ["Cancer Therapy, SP1", "Cancer Therapy (SP1)"],
  },
  {
    primary_id: "D0T4BL",
    names: ["Neovastat"],
  },
  {
    primary_id: "D0T4BP",
    names: ["2-Hydroxylauric Acid", "2-OH-C12", "2-Hydroxy Lauric Acid"],
  },
  {
    primary_id: "D0T4CD",
    names: ["NGN-9077"],
  },
  {
    primary_id: "D0T4CF",
    names: ["Five Membered Heterocyclic Benzamide Derivative 1"],
  },
  {
    primary_id: "D0T4DJ",
    names: [
      "(-)-HIP-A",
      "GTPL5328",
      "CHEMBL334583",
      "ZINC13801167",
      "( Inverted Exclamation MarkA)-HIP-A",
      "B6982",
      "(3aR,4S,6aR)-3-Hydroxy-3aH,4H,5H,6H,6aH-Pyrrolo[3,4-D][1,2]oxazole-4-Carboxylic Acid",
      "(3aR)-3-Hydroxy-3abeta,4,6,6abeta-Tetrahydro-5H-Pyrrolo[3,4-D]isoxazole-4beta-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0T4EA",
    names: ["GD2 Ganglioside Peptide Mimics"],
  },
  {
    primary_id: "D0T4EL",
    names: ["A-80263"],
  },
  {
    primary_id: "D0T4FT",
    names: ["PMID20690647C5a", "Cbz-Leu-D,L-Phe-CONH-(CH(2))(3)-Adenin-9-Yl", "GTPL8615", "BDBM50326304"],
  },
  {
    primary_id: "D0T4FY",
    names: ["CAR-T Cells Targeting CD30"],
  },
  {
    primary_id: "D0T4GA",
    names: ["INTEGRA Artificial Skin"],
  },
  {
    primary_id: "D0T4GG",
    names: ["NANT"],
  },
  {
    primary_id: "D0T4GX",
    names: ["Clade C HIV Vaccine"],
  },
  {
    primary_id: "D0T4GZ",
    names: ["MPL-S"],
  },
  {
    primary_id: "D0T4HB",
    names: ["Methyl Icosylphosphonofluoridate"],
  },
  {
    primary_id: "D0T4HR",
    names: ["2,8-Diamino-9-Benzyl-1,9-Dihydro-Purin-6-One"],
  },
  {
    primary_id: "D0T4HS",
    names: ["GR-95168"],
  },
  {
    primary_id: "D0T4IP",
    names: ["Immune Globulin Intravenous"],
  },
  {
    primary_id: "D0T4IQ",
    names: ["MEN-11149"],
  },
  {
    primary_id: "D0T4IW",
    names: ["8H9 I-131 Mab"],
  },
  {
    primary_id: "D0T4IY",
    names: ["7-Mercapto-Heptanoic Acid Quinolin-3-Ylamide", "CHEMBL112234"],
  },
  {
    primary_id: "D0T4JD",
    names: ["NSC-324572"],
  },
  {
    primary_id: "D0T4JO",
    names: [
      "7-METHYL-8-OXO-5-P-TOLYL-7,8-DIHYDRO-[1,7]NAPHTHYRIDINE-6-CARBOXYLIC ACID (3,5-BIS-TRIFLUOROMETHYL-BENZYL)-METHYL-AMIDE (STRUCTURAL MIX)",
      "CHEMBL107172",
      "SCHEMBL7850311",
      "TAK-622",
      "BDBM50030589",
      "N-Methyl-N-[3,5-Bis(Trifluoromethyl)Benzyl]-7-Methyl-8-Oxo-5-(4-Methylphenyl)-1,7-Naphthyridine-6-Carboxamide",
    ],
  },
  {
    primary_id: "D0T4JQ",
    names: [
      "Butanoic Acid",
      "Butyric Acid",
      "N-Butyric Acid",
      "107-92-6",
      "N-Butanoic Acid",
      "Propylformic Acid",
      "Ethylacetic Acid",
      "1-Propanecarboxylic Acid",
      "Butanic Acid",
      "Butyrate",
      "1-Butyric Acid",
      "Buttersaeure",
      "Butanoate",
      "Kyselina Maselna",
      "Butyric Acid (Natural)",
      "Propanecarboxylic Acid",
      "Buttersaeure [German]",
      "1-Butanoic Acid",
      "FEMA Number 2221",
      "Kyselina Maselna [Czech]",
      "FEMA No. 2221",
      "CCRIS 6552",
      "HSDB 940",
      "Butoic Acid",
      "Ethyacetic Acid",
      "2-Butanoate",
      "NSC 8415",
      "UNII-40UIR9Q29H",
      "UN2820",
      "AI3-15306",
      "EINECS 203-532-3",
      "BRN 0906770",
      "CH3-[CH2]2-COOH",
      "40UIR9Q29H",
    ],
  },
  {
    primary_id: "D0T4KC",
    names: ["1-(3-Cyano-2-Hydroxyphenyl)-3-Phenylurea", "CHEMBL239558", "SCHEMBL7488503"],
  },
  {
    primary_id: "D0T4KG",
    names: [
      "F-8-IL-10 Fusion Protein",
      "Dekavil",
      "F-8-IL-10",
      "F-8-IL-10 Fusion Protein (Rheumatoid Arthritis/Endometriosis)",
      "F-8-IL-10 Fusion Protein (Rheumatoid Arthritis/Endometriosis), Philogen",
    ],
  },
  {
    primary_id: "D0T4LT",
    names: ["GSK-1842799C"],
  },
  {
    primary_id: "D0T4LZ",
    names: ["Lynparzaolaparib"],
  },
  {
    primary_id: "D0T4ME",
    names: ["V930"],
  },
  {
    primary_id: "D0T4MJ",
    names: ["K00592a"],
  },
  {
    primary_id: "D0T4MK",
    names: ["Andexanet Alfa"],
  },
  {
    primary_id: "D0T4ML",
    names: ["L-372662"],
  },
  {
    primary_id: "D0T4MQ",
    names: ["Cytotropic Heterogeneous Molecular Lipids"],
  },
  {
    primary_id: "D0T4MS",
    names: ["VER-49009"],
  },
  {
    primary_id: "D0T4NV",
    names: ["CDX-1135"],
  },
  {
    primary_id: "D0T4OE",
    names: ["Palinavir", "BILA-2011", "BILA-2011 BS"],
  },
  {
    primary_id: "D0T4OT",
    names: ["Zilascorb (2H)"],
  },
  {
    primary_id: "D0T4PA",
    names: ["TAK-243"],
  },
  {
    primary_id: "D0T4PL",
    names: [
      "PMID1656041C11jj",
      "Compound 10jj [PMID: 1656041]",
      "Compound 4jj [PMID: 1656041]",
      "GTPL3015",
      "BDBM50007272",
    ],
  },
  {
    primary_id: "D0T4PS",
    names: ["LY 165,163"],
  },
  {
    primary_id: "D0T4QA",
    names: ["LXH254"],
  },
  {
    primary_id: "D0T4QX",
    names: [
      "CTEP",
      "2-Chloro-4-((2,5-Dimethyl-1-(4-(Trifluoromethoxy)Phenyl)-1H-Imidazol-4-Yl)Ethynyl)Pyridine",
      "871362-31-1",
      "CTEP (RO4956371)",
      "CHEMBL3410223",
      "2-Chloro-4-[2-[2,5-Dimethyl-1-[4-(Trifluoromethoxy)Phenyl]imidazol-4-Yl]ethynyl]pyridine",
      "[3H]CTEP",
      "MGluR5 Inhibitor",
      "C19H13ClF3N3O",
      "SCHEMBL608379",
      "GTPL6409",
      "GTPL6408",
      "DTXSID50469986",
      "EX-A095",
      "MolPort-035-395-697",
      "HMS3651N19",
      "BCP06140",
      "AOB87758",
      "ZINC35996961",
      "BDBM50071375",
      "S2861",
      "2340AH",
      "AKOS027301517",
      "Compound 3 [PMID: 25565255]",
      "CS-0974",
      "KS-000006G1",
      "NCGC00386156-06",
      "KB-76209",
      "HY-15445",
      "MGluR5 Antagonist (Oral), Roche",
      "Metabotropic Glutamate Receptor 5 Antagonist (Oral), Roche",
    ],
  },
  {
    primary_id: "D0T4RW",
    names: ["NSC-645827"],
  },
  {
    primary_id: "D0T4SE",
    names: ["(E)-N-(4-Methoxyphenethyl)Undec-2-Enamide", "CHEMBL198182"],
  },
  {
    primary_id: "D0T4SG",
    names: [
      "AZD1981",
      "802904-66-1",
      "AZD-1981",
      "UNII-2AD53WQ2CX",
      "AZD 1981",
      "2AD53WQ2CX",
      "CHEMBL1914489",
      "2-[4-Acetamido-3-(4-Chlorophenyl)Sulfanyl-2-Methylindol-1-Yl]acetic Acid",
      "1H-Indole-1-Acetic Acid, 4-(Acetylamino)-3-((4-Chlorophenyl)Thio)-2-Methyl-",
      "1H-Indole-1-Acetic Acid, 4-(Acetylamino)-3-[(4-Chlorophenyl)Thio]-2-Methyl-",
      "JWYIGNODXSRKGP-UHFFFAOYSA-N",
      "GTPL7680",
      "SCHEMBL1053662",
      "EX-A662",
      "MolPort-035-395-811",
      "HMS3653A06",
      "BCP20957",
      "ZINC73196066",
      "S7263",
      "BDBM50357102",
      "AKOS027263775",
      "SB16902",
      "DB11946",
      "CS-4189",
      "NCGC00386290-04",
      "HY-15950",
      "SC-94603",
    ],
  },
  {
    primary_id: "D0T4SJ",
    names: [
      "SCH-546909",
      "CDK2 Inhibitors (Cancer), Merck & Co",
      "CDK2 Inhibitors (Cancer), Schering-Plough",
      "Cyclindependent Kinase 2 Inhibitors (Cancer), Schering-Plough",
    ],
  },
  {
    primary_id: "D0T4SL",
    names: [
      "9-Methoxy-5,11-Dimethyl-6H-Pyrido[4,3-B]carbazole",
      "9-Methoxyellipticine",
      "Methoxyelliptione",
      "Methoxyellipticine",
      "9-Methoxyellipticin",
      "10371-86-5",
      "ICI 180 Base",
      "Ellipticine, 9-Methoxy-",
      "AT 181",
      "ICIG 772",
      "NSC69187",
      "NSC 69187",
      "B 656501 K003",
      "9-Methoxy-Ellipticine",
      "UNII-U0924292N9",
      "EINECS 233-812-0",
      "BRN 0279742",
      "9-Methoxy-5,11-Dimethyl-6H-Pyrido(4,3-B)Carbazole",
      "CHEMBL19954",
      "MLS000736760",
      "C18H16N2O",
      "BKRMCDAOAQWNTG-UHFFFAOYSA-N",
      "NSC-69187",
      "6H-Pyrido[4,3-B]carbazole, 9-Methoxy-5,11-Dimethyl-",
      "6H-Pyrido(4,3-B)Carbazole, 5,11-Dimethyl-9-Me",
    ],
  },
  {
    primary_id: "D0T4SO",
    names: ["PRO-367"],
  },
  {
    primary_id: "D0T4TN",
    names: [
      "Vasoactive Intestinal Peptide",
      "CCRIS 7231",
      "Vasoactive Intestinal Polypeptide",
      "Vasointestinal Peptide",
      "Vasoactive Intestinal Peptide, Synthetic Porcine",
      "SCHEMBL3041046",
      "GTPL1152",
      "VIP (Vasoactive Intestinal Peptide)",
      "LS-174653",
      "VIP",
    ],
  },
  {
    primary_id: "D0T4UE",
    names: ["Mycophenolic Bis(Sulfonamide)"],
  },
  {
    primary_id: "D0T4UL",
    names: ["PD-348292"],
  },
  {
    primary_id: "D0T4UO",
    names: [
      "(S)-Tacrine(10)-Hupyridone",
      "(S)-TACRINE(10)-HUPYRIDONE",
      "(5S)-5-{[10-(1,2,3,4-TETRAHYDROACRIDIN-9-YLAMINO)DECYL]AMINO}-5,6,7,8-TETRAHYDROQUINOLIN-2(1H)-ONE",
      "AC1NS1EG",
      "DB04615",
      "(5S)-5-[10-(1,2,3,4-Tetrahydroacridine-9-Ylamino)Decylamino]-1,2,5,6,7,8-Hexahydroquinoline-2-One",
      "(S)-N-9 -(1 ,2 ,3 ,4 -TETRAHYDROACRIDINYL)-N'-5 -[5 ,6 ,7 ,8 -TETRAHYDRO-2'(1'H)-QUINOLINONYL]-1,10-DIAMINODECANE",
    ],
  },
  {
    primary_id: "D0T4UQ",
    names: ["AS-01 Adjuvant", "AS-01", "AS-01B", "Combination Adjuvant (QS-21 + MPL), SmithKline Beecham"],
  },
  {
    primary_id: "D0T4VL",
    names: ["Primary Alcohol Metabolite Of Celecoxib"],
  },
  {
    primary_id: "D0T4WA",
    names: ["Trimazosin"],
  },
  {
    primary_id: "D0T4WF",
    names: ["3-Phenacyl-UDP"],
  },
  {
    primary_id: "D0T4WK",
    names: ["AAV2 GDNF"],
  },
  {
    primary_id: "D0T4WL",
    names: [
      "1-Hydroxy-3-Phenylurea",
      "Urea, N-Hydroxy-N'-Phenyl-",
      "Urea, 1-Hydroxy-3-Phenyl-",
      "7335-35-5",
      "CHEMBL125955",
      "NSC52953",
      "NSC 52953",
      "WLN: QMVMR",
      "AI3-63229",
      "AC1Q5NHR",
      "SCHEMBL676383",
      "AC1L322W",
      "DTXSID20223597",
      "MolPort-003-699-942",
      "ZINC161805",
      "BDBM50377106",
      "NSC-52953",
      "AKOS024336836",
    ],
  },
  {
    primary_id: "D0T4WX",
    names: ["Pertuzumab Companion Diagnostic"],
  },
  {
    primary_id: "D0T4XJ",
    names: ["5-HT2c Agonists (Metabolic Disorders), Boehringer"],
  },
  {
    primary_id: "D0T4XQ",
    names: ["SEP-227162"],
  },
  {
    primary_id: "D0T4XV",
    names: ["SL-18.1616"],
  },
  {
    primary_id: "D0T4YH",
    names: ["PF-03716539"],
  },
  {
    primary_id: "D0T4YQ",
    names: ["VU0092273"],
  },
  {
    primary_id: "D0T4YZ",
    names: ["CNSB-004", "Drug Combination (Intractable Severe Pain), CNSbio"],
  },
  {
    primary_id: "D0T4ZI",
    names: [
      "6-Chloro-2-(3-Nitro-Phenyl)-Chromen-4-One",
      "CHEMBL59263",
      "127767-40-2",
      "4H-1-Benzopyran-4-One, 6-Chloro-2-(3-Nitrophenyl)-",
      "6-Chloro-2-(3-Nitrophenyl)Chromen-4-One",
      "ACMC-20mskm",
      "3'-Nitro-6-Chloroflavone",
      "AC1NMK14",
      "6-Chloro-3' -Nitroflavone",
      "CTK0C1861",
      "DTXSID20407496",
      "AKOS001600357",
      "EU-0068162",
      "SR-01000400460",
    ],
  },
  {
    primary_id: "D0T4ZW",
    names: ["MP-RM-1"],
  },
  {
    primary_id: "D0T4ZY",
    names: ["1-(2-Ethoxy-1-Phenylethyl)-4-Phenylpiperidin-4-Ol", "CHEMBL228687"],
  },
  {
    primary_id: "D0T5AF",
    names: ["PMID27774824-Compound-Figure5Example13"],
  },
  {
    primary_id: "D0T5AH",
    names: ["PHA-690509"],
  },
  {
    primary_id: "D0T5AV",
    names: ["NSC-87877"],
  },
  {
    primary_id: "D0T5AW",
    names: [
      "Desoxypeganine",
      "Alcoholism Therapy, HF Arzneimittelforschung",
      "Desoxypeganine, HF Arzneimittelforschung",
    ],
  },
  {
    primary_id: "D0T5BC",
    names: ["Lactulose", "Cephulac;Cholac;Heptalac;Enulose;Enulose"],
  },
  {
    primary_id: "D0T5CH",
    names: ["HSC835"],
  },
  {
    primary_id: "D0T5DC",
    names: ["CAR-T Cells Targeting CEA"],
  },
  {
    primary_id: "D0T5DE",
    names: [
      "Barium Sulfate",
      "BARIUM SULFATE",
      "Barite",
      "7727-43-7",
      "Baritop",
      "Radiobaryt",
      "Macropaque",
      "Radiopaque",
      "Micropaque",
      "Esophotrast",
      "Microtrast",
      "Liquipake",
      "Barotrast",
      "Barosperse",
      "EntroBar",
      "Barium Sulphate",
      "EneMark",
      "EneCat",
      "Enamel White",
      "Blanc Fixe",
      "Readi-CAT",
      "Pigment White 22",
      "E-Z-Paque",
      "Colonatrast",
      "Liquibarine",
      "Microfanox",
      "Microbar",
      "Baryxine",
      "Barytes",
      "Barocat",
      "Topcontral",
      "Tonopaque",
      "Supramike",
      "Novopaque",
      "Danobaryt",
      "Barytgen",
      "Barobag",
      "Baraflave",
      "Unibaryt",
      "Suspobar",
      "Oratrast",
      "Oesobar",
      "Neobalgin",
      "Mixobar",
      "Finemeal",
      "Citobaryum",
      "Bayrites",
      "Baroloid",
      "Barodense",
      "Baridol",
      "Bakontal",
    ],
  },
  {
    primary_id: "D0T5DO",
    names: ["IODOSUPROFEN"],
  },
  {
    primary_id: "D0T5DP",
    names: ["AZD6244"],
  },
  {
    primary_id: "D0T5EI",
    names: ["Ethylketocyclazocine", "CHEMBL71301", "AC1L28LJ", "GTPL1602", "BDBM50017232", "L024009"],
  },
  {
    primary_id: "D0T5ER",
    names: ["Benzazepine Derivative 2"],
  },
  {
    primary_id: "D0T5ES",
    names: ["MUC-1 Cancer Vaccine", "CVac (TN)"],
  },
  {
    primary_id: "D0T5FO",
    names: ["CVA-21 DAF", "21 DAFv"],
  },
  {
    primary_id: "D0T5FU",
    names: ["DS-3801"],
  },
  {
    primary_id: "D0T5FY",
    names: [
      "2,4-Dichloro-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL61381",
      "2,4-Dichloro-N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "260790-88-3",
      "(Z)-2,4-Dichloro-N-(4-Phenylthiazol-2(3H)-Ylidene)Benzamide",
      "5307-12-0",
      "AC1LCD3F",
      "CBMicro_016815",
      "Oprea1_161519",
      "ARONIS019647",
      "KS-00004BJV",
      "DTXSID00344799",
      "MolPort-001-620-431",
      "MWSDOEQEOIKHRL-UHFFFAOYSA-N",
      "MolPort-003-009-221",
      "ZINC4696587",
      "CCG-5657",
      "STK048438",
      "BDBM50101786",
      "AKOS000486711",
      "AKOS024573981",
      "MCULE-1720813827",
      "ST036566",
      "BIM-0016665.P001",
      "N-(4-Phenyl-2-Thiazolyl)-2,4-Dichlorobenzamide",
    ],
  },
  {
    primary_id: "D0T5GL",
    names: ["Narrow Spectrum Antibiotics"],
  },
  {
    primary_id: "D0T5GO",
    names: ["Erythropoietin Gene Therapy"],
  },
  {
    primary_id: "D0T5GT",
    names: ["CE-1037"],
  },
  {
    primary_id: "D0T5GV",
    names: ["PETCM", "1-(Trichloromethyl)-2-(4-Pyridine)Ethanol"],
  },
  {
    primary_id: "D0T5HO",
    names: ["Vaxfectin Plasmid DNA Pandemic Influenza Vaccine"],
  },
  {
    primary_id: "D0T5IN",
    names: ["PX-867"],
  },
  {
    primary_id: "D0T5IP",
    names: ["2-Trityl-4,5-Dihydrooxazole", "CHEMBL271167"],
  },
  {
    primary_id: "D0T5JG",
    names: ["TDI-0114"],
  },
  {
    primary_id: "D0T5JP",
    names: ["ISIS 144368"],
  },
  {
    primary_id: "D0T5JS",
    names: ["TT-100"],
  },
  {
    primary_id: "D0T5KC",
    names: ["(S)-2-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Phenol", "CHEMBL408617"],
  },
  {
    primary_id: "D0T5KJ",
    names: [
      "4-(3-Hydroxy-Piperidin-3-Yl)-Benzene-1,2-Diol",
      "CHEMBL7278",
      "100112-61-6",
      "1,2-Benzenediol,4-(3-Hydroxy-3-Piperidinyl)-",
      "1,2-Benzenediol, 4-(3-Hydroxy-3-Piperidinyl)-",
      "CTK8G4032",
      "BDBM50000496",
      "AKOS022649735",
      "3-(3,4-Dihydroxyphenyl)Piperidin-3-Ol",
    ],
  },
  {
    primary_id: "D0T5LQ",
    names: [
      "123-Iodine-Labeled MFE-23",
      "CEA Antibody Program (Cancer/Imaging)",
      "MFE-23-Albumin Fusion Protein",
      "CEA Antibody Program (Cancer/Imaging), Cancer Research Technology/UCL/Novozymes Delta",
      "Albumin-Linked Anti-CEA Single Chain Antibody Fragment (Cancer), UCL/CRT/Novozymes",
      "Anti-CEA ScFv-Albumin Fusion (Cancer), UCL/CRT/Novozymes",
      "123-Iodine-LabeledMFE-23",
      "125-I-Labeled MFE-23-His",
      "125-Iodine-Labeled MFE-23",
      "99mTc-Labeled MFE-23",
    ],
  },
  {
    primary_id: "D0T5MB",
    names: ["Lu-35138"],
  },
  {
    primary_id: "D0T5MD",
    names: ["Isosorbide-2-Benzylcarbamate-5-Cyclopropanoate", "CHEMBL492432"],
  },
  {
    primary_id: "D0T5MO",
    names: [
      "5alpha-Androstan-3,17-Dione",
      "Androstanedione",
      "5alpha-Androstane-3,17-Dione",
      "846-46-8",
      "5a-Androstanedione",
      "5-Alpha-Androstane-3,17-Dione",
      "Dihydroandrostendione",
      "5alpha-Androstanedione",
      "UNII-2KR72RNR8Z",
      "Androstane-3,17-Dione",
      "5.alpha.-Androstanedione",
      "Dihydroandrostenedione",
      "5a-Androstane-3,17-Dione",
      "2KR72RNR8Z",
      "A-Androstane-3,17-Dione",
      "CHEMBL1230438",
      "CHEBI:15994",
      "5.alpha.-Androsta-3,17-Dione",
      "RAJWOBJTTGJROA-WZNAKSSCSA-N",
      "Androstane-3,17-Dione, (5alpha)-",
      "5.alpha.-Androstane-3,17-Dione",
      "Androstane-3, (5.alpha.)-",
    ],
  },
  {
    primary_id: "D0T5NB",
    names: ["GS-4774"],
  },
  {
    primary_id: "D0T5NI",
    names: ["Saperconazole"],
  },
  {
    primary_id: "D0T5NO",
    names: [
      "5,6,8-Trimethoxy-1,4-Dimethylquinolin-2(1H)-One",
      "Casimiroin Analogue, 1p",
      "CHEMBL449065",
      "BDBM29227",
      "MolPort-028-745-486",
      "ZINC40829644",
      "1,4-Dimethyl-5,6,8-Trimethoxyquinolin-2(1H)-One",
    ],
  },
  {
    primary_id: "D0T5NX",
    names: ["ISIS 148715"],
  },
  {
    primary_id: "D0T5OD",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Cyclooctyl Ester",
      "CHEMBL155465",
      "BDBM50139114",
      "4-(Sulfamoyloxy)Benzoic Acid Cyclooctyl Ester",
    ],
  },
  {
    primary_id: "D0T5OF",
    names: ["PMID28270010-Compound-Figure17-2"],
  },
  {
    primary_id: "D0T5OS",
    names: ["Phosphonotyrosine"],
  },
  {
    primary_id: "D0T5OW",
    names: ["2-(4-(4-Hydroxyphenyl)Thiazol-2-Ylamino)Phenol", "CHEMBL551839"],
  },
  {
    primary_id: "D0T5OX",
    names: ["Remifentanil"],
  },
  {
    primary_id: "D0T5PH",
    names: ["Relacatib"],
  },
  {
    primary_id: "D0T5PO",
    names: [
      "ROLIPRAM",
      "61413-54-5",
      "ZK 62711",
      "Rolipramum",
      "Rolipramum [Latin]",
      "4-[3-(Cyclopentyloxy)-4-Methoxyphenyl]-2-Pyrrolidinone",
      "Adeo",
      "Rolipram [USAN:INN]",
      "4-[3-(Cyclopentyloxy)-4-Methoxyphenyl]pyrrolidin-2-One",
      "4-(3-(Cyclopentyloxy)-4-Methoxyphenyl)Pyrrolidin-2-One",
      "4-(3-(Cyclopentyloxy)-4-Methoxyphenyl)-2-Pyrrolidinone",
      "(R,S)-Rolipram",
      "ZK-62711",
      "CHEMBL63",
      "EINECS 262-771-1",
      "BRN 1588548",
      "MLS000069691",
      "CHEBI:104872",
      "HJORMJIFDVBMOB-UHFFFAOYSA-N",
      "4-(3-Cyclopentyloxy-4-Methoxyphenyl)-2-Pyrrolidone",
      "[3H]rolipram",
    ],
  },
  {
    primary_id: "D0T5QC",
    names: ["AAV1-FS344"],
  },
  {
    primary_id: "D0T5QT",
    names: ["Becaplermin"],
  },
  {
    primary_id: "D0T5RJ",
    names: ["ISIS 103620"],
  },
  {
    primary_id: "D0T5RO",
    names: [
      "SOMATOSTATIN",
      "51110-01-1",
      "Somiaton",
      "Panhibin",
      "Aminopan",
      "Somatostatin Acetate",
      "SRIF",
      "Somatotropin Release-Inhibiting Factor",
      "Somatotropin Release-Inhibiting Hormone",
      "Growth Hormone Release-Inhibiting Factor",
      "CCRIS 7071",
      "EINECS 256-969-7",
      "Growth Hormone Release-Inhibiting Hormone",
      "AY 24910",
      "Growth Hormone Release Inhibiting Factor",
      "Somatostatin 1-14",
      "Ala-Gly-Cys-Lys-Asn-Phe-Phe-Trp-Lys-Thr-Phe-Thr-Ser-Cys",
      "NCGC00181005-01",
      "61950-59-2",
      "[D-Trp8]-SOMATOSTATIN",
      "SCHEMBL3046327",
    ],
  },
  {
    primary_id: "D0T5SG",
    names: ["ME-3221", "EF-2831"],
  },
  {
    primary_id: "D0T5SO",
    names: ["Piperidine Derivative 6"],
  },
  {
    primary_id: "D0T5SU",
    names: [
      "(S)-AMPA",
      "83643-88-3",
      "(S)-Alpha-Amino-3-Hydroxy-5-Methyl-4-Isoxazolepropionic Acid",
      "UNII-9280SC28GD",
      "CHEMBL276815",
      "9280SC28GD",
      "(S)-2-Amino-3-(3-Hydroxy-5-Methyl-Isoxazol-4-Yl)-Propionic Acid",
      "AMPA, L-",
      "AMQ",
      "Glutamate-AMPA",
      "1ftm",
      "Tocris-0254",
      "Tocris-0169",
      "Tocris-1074",
      "S-AMPA",
      "1p1q",
      "L-AMPA",
      "Lopac-G-017",
      "AC1L4JUQ",
      "Biomol-NT_000216",
      "(S)-A-Amino-3-Hydroxy-5-Methyl-4-Isoxazolepropionic Acid",
      "SCHEMBL667915",
      "BPBio1_001304",
      "CTK7I3476",
      "BDBM17662",
      "BDBM85214",
      "UUDAMDVQRQNNHZ-YFKPBYRVSA-N",
      "MolPort-003-940-001",
      "MolPort-003-983-512",
      "HMS3266E09",
    ],
  },
  {
    primary_id: "D0T5SZ",
    names: ["PNU-142721"],
  },
  {
    primary_id: "D0T5TN",
    names: ["CJM112"],
  },
  {
    primary_id: "D0T5TT",
    names: ["3-Prop-1-Ynylquinoline-8-Carboxamide", "CHEMBL504903", "ZINC40829471"],
  },
  {
    primary_id: "D0T5TU",
    names: ["TPI-1020"],
  },
  {
    primary_id: "D0T5UL",
    names: [
      "Benzphetamine",
      "Benzaphetamine",
      "Benzfetamina",
      "Benzfetamine",
      "Benzfetaminum",
      "Benzofetamina",
      "Benzphetaminum",
      "Benzylamphetamine",
      "Benzfetamina [DCIT]",
      "Benzfetamina [Spanish]",
      "Benzfetaminum [Latin]",
      "U 0441",
      "Benzfetamina [INN-Spanish]",
      "Benzfetamine (INN)",
      "Benzfetamine [INN:BAN]",
      "Benzfetaminum [INN-Latin]",
      "Benzofetamina [INN-Spanish]",
      "Benzphetaminum [INN-Latin]",
      "D-Benzphetamine",
      "Didrex (TN)",
      "N-Benzylmethamphetamin",
      "N-Benzyl-N,alpha-Dimethylphenethylamine",
      "N,alpha-Dimethyl-N-(Phenylmethyl)Benzeneethanamine",
      "D-N-Methyl-N-Benzyl-Beta-Phenylisopropylamine",
      "N,alpha-Dimethyl-N-(Phenylmethyl)-Benzeneethanamine",
      "N-Benzyl-N-Methyl-1-Phenyl-2-Propanamine",
      "N-Benzyl-N-Methyl-1-Phenylpropan-2-Amine",
      "N-Methyl-1-Phenyl-N-(Phenylmethyl)Propan-2-Amine",
      "Benzeneethanamine, N,alpha-Dimethyl-N-(Phenylmethyl)-, (+)-(9CI)",
      "(+)-N,alpha-Dimethyl-N-(Phenylmethyl)-Benzeneethanamine",
      "(+)-N-Benzyl-N,alpha-Dimethylphenethylamine",
      "(+)-Benzphetamine",
      "(2S)-N-Benzyl-N-Methyl-1-Phenylpropan-2-Amine",
      "(R,S)-N-Benzyl-Alpha,N-Dimethylphenethylamin",
      "(S)-(+)-N-Benzyl-N,alpha-Dimethylphenethylamine",
      "(S)-(+)-Benzphetamine",
      "(S)-Benzphetamine",
      "(AlphaS)-N,alpha-Dimethylphenethylamine",
    ],
  },
  {
    primary_id: "D0T5VB",
    names: ["WO2013110309CA127", "A-127", "GTPL8169", "BDBM50011235"],
  },
  {
    primary_id: "D0T5VD",
    names: ["EVK-001"],
  },
  {
    primary_id: "D0T5VG",
    names: ["2-(Aminomethyl)-5-(1'-Naphthethyl)Tetrahydrofuran", "CHEMBL511163", "SCHEMBL4615998", "BDBM50276460"],
  },
  {
    primary_id: "D0T5VQ",
    names: ["Mearubik"],
  },
  {
    primary_id: "D0T5WA",
    names: ["Healive"],
  },
  {
    primary_id: "D0T5WK",
    names: [
      "Nitrazepam",
      "Alodorm",
      "Apodorm",
      "Benzalin",
      "Calsamin",
      "Calsmin",
      "Cerson",
      "Dormalon",
      "Dumolid",
      "Eatan",
      "Epibenzalin",
      "Epinelbon",
      "Eunoctin",
      "Eunoktin",
      "Gerson",
      "Hipnax",
      "Hipsal",
      "Ibrovek",
      "Imadorm",
      "Imeson",
      "Imesont",
      "Ipersed",
      "Magadon",
      "Megadon",
      "Mitidin",
      "Mogadan",
      "Mogadon",
      "Mogadone",
      "Nelbon",
      "Nelmat",
      "Neozepam",
      "Neuchlonic",
      "Nitrados",
      "Nitravet",
      "Nitrazadon",
      "Nitrazep",
      "Nitrazepamum",
      "Nitrempax",
      "Nitrenpax",
      "Nitrodiazepam",
      "Noctesed",
      "Novanox",
      "Pacisyn",
      "Paxisyn",
      "Pelson",
      "Persopit",
      "Radedorm",
      "Relact",
      "Remnos",
      "Serenade",
      "Somitran",
      "Somnased",
      "Somnibel",
      "Somnite",
      "Sonebon",
      "Sonnolin",
      "Surem",
      "Trazenin",
      "Unisomnia",
      "Aliud Brand Of Nitrazepam",
      "Allphar Brand Of Nitrazepam",
      "Alphapharma Brand Of Nitrazepam",
      "Alpharma Brand Of Nitrazepam",
      "Alter Brand Of Nitrazepam",
      "CSP Brand Of Nitrazepam",
      "DDSA Brand Of Nitrazepam",
      "Dermatech Brand Of Nitrazepam",
      "Desitin Brand Of Nitrazepam",
      "Dormicum Brand Of Nitrazepam",
      "Eatan N",
      "ICN Brand Of Nitrazepam",
      "Neuraxpharm Brand Of Nitrazepam",
      "Nitrazepam AL",
      "Norgine Brand Of Nitrazepam",
      "Pfleger Brand Of Nitrazepam",
      "Protea Brand Of Nitrazepam",
      "Rhoxalpharma Brand Of Nitrazepam",
      "Sandoz Nitrazepam",
      "Scheurich Brand Of Nitrazepam",
      "Sonebon Tofraniln A",
      "Taurus Brandof Nitrazepam",
      "United Drug Brand Of Nitrazepam",
      "Wernigerode Brand Of Nitrazepam",
      "LA 1",
      "S 2000",
      "Alodorm (TN)",
      "Apo-Nitrazepam Tablets BP",
      "Apodorm (TN)",
      "Arem (TN)",
      "Benzalin (TN)",
      "Cavodan (TN)",
      "Ct-Arzneimittel Brand Of Nitrazepam",
      "Dima (TN)",
      "Dormalon (TN)",
      "Dormicum (Anticonvulsant)",
      "Dormigen (TN)",
      "Dormin-5",
      "Dormo-Puren",
      "Dumolid (TN)",
      "Eatan N (TN)",
      "Eunoctin (TN)",
      "Hypnotex (TN)",
      "ISOPROPYLACETATE/NITRAZEPAM",
      "Imeson (TN)",
      "Insoma (TN)",
      "Insomin (TN)",
      "Ipersed (TN)",
      "LA 1 (VAN)",
      "Mitidin (TN)",
      "Mogadan (TN)",
      "Mogadon (TN)",
      "N-Desmethylnimetazepam",
      "Nitavan (TN)",
      "Nitepam (TN)",
      "Nitrados (TN)",
      "Nitrapan (TN)",
      "Nitravet (TN)",
      "Nitrazadon (TN)",
      "Nitrazep (TN)",
      "Nitrazepam (TN)",
      "Nitrazepam Capsules BP 1993 (TN)",
      "Nitrazepam Oral Suspension BP 1993 (TN)",
      "Nitrazepam Tablets BP 1993 (TN)",
      "Nitrazepam-10",
      "Nitrazepam-5",
      "Nitrazepam-Neuraxpharm",
      "Nitrazepamum [INN-Latin]",
      "Nitrazepan (TN)",
      "Nitrazepol (TN)",
      "Nitredon (TN)",
      "Nitrosun (TN)",
      "Novanox (TN)",
      "Numbon (TN)",
      "Onirema (TN)",
      "Ormodon (TN)",
      "Pacisyn (TN)",
      "Paxadorm (TN)",
      "Pelson (TN)",
      "Pelsonfilina (TN)",
      "Protraz (TN)",
      "Radedorm (TN)",
      "Remnos (TN)",
      "Rhoxal-Nitrazepam",
      "Ro 4-5360",
      "Ro 5-3059",
      "Ro 53-60",
      "Serenade (TN)",
      "Somnibel N (TN)",
      "Somnipar (TN)",
      "Somnite (TN)",
      "Sonebon (TN)",
      "Sonotrat (TN)",
      "Surem (TN)",
      "Tri (TN)",
      "Unisomnia (TN)",
      "Dormo-Puren (TN)",
      "Nitrazepam (JP15/USAN/INN)",
      "Nitrazepam [USAN:INN:BAN:JAN]",
      "1,3-Dihydro-7-Nitro-5-Phenyl-2H-1,4-Benzodiazepin-2-One",
      "2,3-Dihydro-7-Nitro-5-Phenyl-1H-1,4-Benzodiazepin-2-On",
      "7-Nitro-1,3-Dihydro-5-Phenyl-2H-1,4-Benzodiazepin-2-One",
      "7-Nitro-5-Phenyl-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "7-Nitro-5-Phenyl-2,3-Dihydro-1H-1,4-Benzodiazepin-2-One",
      "7-Nitro-5-Phenyl-1,3-Dihydro-1,4-Benzodiazepin-2-One",
    ],
  },
  {
    primary_id: "D0T5WR",
    names: ["Bbs-Arg-(D-Pip)-Gly-(EQKLISEEDL)-Gly-Hir", "CHEMBL414125"],
  },
  {
    primary_id: "D0T5WX",
    names: ["Biphenyl 1,2-Diamine Derivative 1"],
  },
  {
    primary_id: "D0T5WY",
    names: ["JWH-293"],
  },
  {
    primary_id: "D0T5XC",
    names: [
      "1-(4-Methoxy-Phenyl)-2-Phenyl-Ethane-1,2-Dione",
      "4-Methoxybenzil",
      "P-Methoxybenzil",
      "BENZIL, 4-METHOXY-",
      "22711-21-3",
      "Ethanedione, (4-Methoxyphenyl)Phenyl-",
      "NSC 39465",
      "1-(4-Methoxyphenyl)-2-Phenylethane-1,2-Dione",
      "BRN 2052507",
      "NTINAJCDYRYMML-UHFFFAOYSA-N",
      "NSC39465",
      "Dibenzoyl, 4-Methoxy",
      "AC1L1LPW",
      "Benzil-Based Compound, 20",
      "4-08-00-02532 (Beilstein Handbook Reference)",
      "SCHEMBL2156563",
      "CHEMBL192474",
      "BDBM22742",
      "DTXSID40177245",
      "MolPort-001-788-309",
      "4-Methoxybibenzyl-Alpha,beta-Dione",
      "ZINC1671392",
      "STK863335",
      "NSC-39465",
      "BBL023172",
      "NSC602911",
      "AKOS000298817",
      "NSC-602911",
      "MCULE-6173981634",
    ],
  },
  {
    primary_id: "D0T5XN",
    names: [
      "LEURUBICIN",
      "Doxogenix L",
      "CT-012002",
      "L-ADR",
      "LEU-DOX",
      "RP-39937",
      "Leurubicin",
      "N-L-Leucyladriamycin",
      "N-L-Leucyldoxorubicin",
      "E-88/032 (Hydrochloride)",
      "(8S,10S)-10-[[3-[(S)-2-Amino-4-Methylvaleramido]-2,3,6-Trideoxy-Alphal-L-Lyxo-Hexopyranosyl]oxy]-8-Glycoloyl-7,8,9,10-Tetrahydro-6,8,11-Trihydroxy-1-Methoxy-5,12-Naphthacenedione",
      "(8S-Cis)-6,8,11-Trihydroxy-8-(Hydroxyacetyl)-10-[2,3,6-Trideoxy-3-(L-Leucylamino)-Alpha-L-Lyxohexopyranosyloxy]-1-Methoxy-5,8,9,10,12-Hexahydronaphthacene-5,12-Dione",
    ],
  },
  {
    primary_id: "D0T5YB",
    names: ["MEDI6012"],
  },
  {
    primary_id: "D0T5YX",
    names: ["DM-71"],
  },
  {
    primary_id: "D0T5ZC",
    names: ["BAY-50-9062"],
  },
  {
    primary_id: "D0T5ZM",
    names: ["NAChR APLs"],
  },
  {
    primary_id: "D0T6AY",
    names: ["WX-04554"],
  },
  {
    primary_id: "D0T6BA",
    names: ["S-16924"],
  },
  {
    primary_id: "D0T6CM",
    names: ["Pyrazolo-Triazine Derivative 2"],
  },
  {
    primary_id: "D0T6CN",
    names: ["BL-8040"],
  },
  {
    primary_id: "D0T6CU",
    names: ["WTLNSAGYLLGPHAVGNHPSFSDKNGLTS-CONH2"],
  },
  {
    primary_id: "D0T6CY",
    names: ["Pyrrolo-Pyridone Derivative 3"],
  },
  {
    primary_id: "D0T6DK",
    names: ["Rapamycin"],
  },
  {
    primary_id: "D0T6DR",
    names: [
      "3-Aminomethyl-5-Methyl-Hexanoic Acid",
      "3-(Aminomethyl)-5-Methylhexanoic Acid",
      "128013-69-4",
      "130912-52-6",
      "Rac-Pregabalin",
      "3-(Aminomethyl)-5-Methylhexanoicacid",
      "3-(Aminomethyl)-5-Methyl-Hexanoic Acid",
      "Hexanoic Acid,3-(Aminomethyl)-5-Methyl-",
      "Hexanoic Acid, 3-(Aminomethyl)-5-Methyl-",
      "AYXYPKUFHZROOJ-UHFFFAOYSA-N",
      "NCGC00095186-01",
      "AC1NFWUF",
      "PubChem15652",
      "ACMC-20p1cu",
      "ACMC-1CF05",
      "SCHEMBL15497",
      "CHEMBL88034",
      "PHARMA-0589",
      "KS-00000AKV",
      "CTK4B5836",
      "BCP17115",
      "BCP05685",
      "BBL100350",
      "CA-452",
      "FC0023",
      "ANW-51583",
      "STK894330",
    ],
  },
  {
    primary_id: "D0T6DU",
    names: ["OAG", "1-Oleoyl-2-Acetyl-Sn-Glycerol"],
  },
  {
    primary_id: "D0T6ED",
    names: ["Myxopyronin B"],
  },
  {
    primary_id: "D0T6EE",
    names: ["ACE-031"],
  },
  {
    primary_id: "D0T6EI",
    names: ["Sarecycline"],
  },
  {
    primary_id: "D0T6ES",
    names: ["SSR-125329A"],
  },
  {
    primary_id: "D0T6FY",
    names: ["KD-901"],
  },
  {
    primary_id: "D0T6GM",
    names: ["L-Serine"],
  },
  {
    primary_id: "D0T6ID",
    names: [
      "Pentylthio-TZTP",
      "CHEMBL130715",
      "GTPL304",
      "SCHEMBL6912246",
      "BDBM50003363",
      "ZINC13456708",
      "1-Methyl-3-(4-Pentylsulfanyl-1,2,5-Thiadiazol-3-Yl)-5,6-Dihydro-2H-Pyridine",
      "1-Methyl-5-(4-Pentylsulfanyl-[1,2,5]thiadiazol-3-Yl)-1,2,3,6-Tetrahydro-Pyridine",
      "1,2,5,6-Tetrahydro-1-Methyl-3-[4-Pentylthio-1,2,5-Thiadiazol-3-Yl]pyridine",
    ],
  },
  {
    primary_id: "D0T6IL",
    names: ["Atacicept EMD Serono"],
  },
  {
    primary_id: "D0T6IM",
    names: ["Tetomilast"],
  },
  {
    primary_id: "D0T6IN",
    names: [
      "Peginterferon Lambda-1a",
      "BMS-914143",
      "IFN Lambda, ZymoGenetics/Novo Nordisk",
      "Interferon Lambda 1, ZymoGenetics/Novo Nordisk",
      "PEG-IL-29",
      "PEG-RIL-29",
      "Interleukin-29, ZymoGenetics/Novo Nordisk",
      "PEG-IFN Lambda, ZymoGenetics/Novo Nordisk",
      "PEG-Interferon Lambda, ZymoGenetics/Novo Nordisk",
      "PEGylated IL-29 (HCV Infection), ZymoGenetics/Novo Nordisk",
      "Peginterferon Lambda-1a, ZymoGenetics/Bristol-Myers Squibb",
      "PEG-IFN Lambda, ZymoGenetics/Novo Nordisk/Bristol-Myers Squibb",
      "PEG-Interferon Lambda, ZymoGenetics/Novo Nordisk/Bristol-Myers Squibb",
      "Recombinant PEG-Interferon Lambda-1, ZymoGenetics/Bristol-Myers Squibb",
      "PEGylated IL-29 (HCV Infection), ZymoGenetics/Novo Nordisk/Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D0T6IP",
    names: ["Cefetamet Pivoxil Hcl"],
  },
  {
    primary_id: "D0T6IX",
    names: [
      "TGF-Beta Receptor 1 Kinase Inhibitors",
      "ALK5 Inhibitors (Cancer), AstraZeneca",
      "TGF-Beta Receptor 1 Kinase Inhibitors (Cancer)",
      "TGF-Beta Receptor 1 Kinase Inhibitors (Cancer), AstraZeneca",
      "Activin Receptor-Like Kinase Subtype-5 Inhibitors (Cancer), AstraZeneca",
    ],
  },
  {
    primary_id: "D0T6IZ",
    names: ["7-Azaindole Derivative 3"],
  },
  {
    primary_id: "D0T6JO",
    names: ["Indol-1-Yl-Propyl-Pyridin-4-Yl-Amine"],
  },
  {
    primary_id: "D0T6KI",
    names: ["CAR-T Cells Targeting CD80/86"],
  },
  {
    primary_id: "D0T6KV",
    names: ["GSK933776A"],
  },
  {
    primary_id: "D0T6LF",
    names: ["PF-3823863"],
  },
  {
    primary_id: "D0T6LU",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0T6MF",
    names: ["7-Tert-Butyl-9H-Carbazole-3-Carboxylic Acid", "CHEMBL1171078"],
  },
  {
    primary_id: "D0T6MM",
    names: ["AM-94"],
  },
  {
    primary_id: "D0T6MU",
    names: ["FR-113680"],
  },
  {
    primary_id: "D0T6NE",
    names: ["Z 944"],
  },
  {
    primary_id: "D0T6NG",
    names: ["CAR-T Cells Targeting CD138"],
  },
  {
    primary_id: "D0T6NJ",
    names: ["N,N-Diethyl-2-(2-Phenyl-1H-Indol-3-Yl)-Acetamide", "CHEMBL99509", "BDBM50045858"],
  },
  {
    primary_id: "D0T6NS",
    names: ["ACPC", "1-Aminocyclopropanecarboxylic Acid"],
  },
  {
    primary_id: "D0T6NX",
    names: ["Biphenyl Mannoside Derivative 29"],
  },
  {
    primary_id: "D0T6OQ",
    names: [
      "3-Desoxy-3-Carboxamidonaltrexone",
      "CHEMBL56695",
      "SCHEMBL2785528",
      "QAICPHMNUHNDMC-WUHBCXKYSA-N",
      "BDBM50128411",
      "6-Oxo-17-(Cyclopropylmethyl)-14-Hydroxy-4,5alpha-Epoxymorphinan-3-Carboxamide",
      "(5a)-17-(Cyclopropylmethyl)-14-Hydroxy-6-Oxo-4,5-Epoxymorphinan-3-Carboxamide",
    ],
  },
  {
    primary_id: "D0T6PF",
    names: ["IMX-MEL1"],
  },
  {
    primary_id: "D0T6PV",
    names: [
      "DV-7751A",
      "10-[8(S)-Amino-6-Azaspiro[3.4]octan-6-Yl]-9-Fluoro-3(S)-Methyl-7-Oxo-2,3-Dihydro-7H-Pyrido[1,2,3-De][1,4]benzoxazine-6-Carboxylic Acid Hemihydrate",
    ],
  },
  {
    primary_id: "D0T6QX",
    names: ["ZOTEPINE"],
  },
  {
    primary_id: "D0T6RC",
    names: [
      "Dihydrocodeine",
      "Dihydrocodeine Bitartrate",
      "DF 118",
      "Dihydrocodeine (Oral)",
      "S-8115",
      "Dihydrocodeine (Oral), Shionogi",
      "(-)-Dihydrocodeine",
    ],
  },
  {
    primary_id: "D0T6RG",
    names: ["SAR113244"],
  },
  {
    primary_id: "D0T6SC",
    names: ["N-Methylhistamine"],
  },
  {
    primary_id: "D0T6SD",
    names: ["PMID25482888-Compound-56"],
  },
  {
    primary_id: "D0T6SP",
    names: ["Benzimidazole Derivative 9"],
  },
  {
    primary_id: "D0T6ST",
    names: ["Ac-I[CV(2Nal)QDWGAHRC]T-NH2"],
  },
  {
    primary_id: "D0T6SU",
    names: [
      "Phendimetrazine",
      "Adphen",
      "Antapentan",
      "Dyrexan",
      "Fendimetrazina",
      "Hyrex",
      "Mephenmetrazine",
      "Phendimetrazinum",
      "Sedafamen",
      "Wehless",
      "Adipost (TN)",
      "Adphen (Base)",
      "Appecon (TN)",
      "Bontril PDM (TN)",
      "Fendimetrazina [INN-Spanish]",
      "Melfiat (TN)",
      "Obezine (TN)",
      "Phendiet (TN)",
      "Phendimetrazine (INN)",
      "Phendimetrazine [INN:BAN]",
      "Phendimetrazinum [INN-Latin]",
      "Plegine (TN)",
      "Statobex (TN)",
      "X-Trozine",
      "Anorex-SR (TN)",
      "Prelu-2 (TN)",
      "Phendimetrazine, (2S-Trans)-Isomer",
      "D-2-Phenyl-3,4-Dimethylmorpholine",
      "Morpholine, 3,4-Dimethyl-2-Phenyl",
      "(+)-3,4-Dimethyl-2-Phenylmorpholine",
      "(+)-Phendimetrazine",
      "(2R-Trans)-3,4-Dimethyl-2-Phenylmorpholine",
      "(2S,3S)-3,4-Dimethyl-2-Phenylmorpholine",
      "3,4-Dimethyl-2-Phenylmorpholine",
      "3,4-Dimethyl-2-Phenyltetrahydro-1,4-Oxazine",
      "3-Phenyl-2-Methylmorpholine",
    ],
  },
  {
    primary_id: "D0T6TH",
    names: ["EVT-501"],
  },
  {
    primary_id: "D0T6TQ",
    names: ["IS-0402"],
  },
  {
    primary_id: "D0T6TW",
    names: ["GSK642444"],
  },
  {
    primary_id: "D0T6UL",
    names: ["L-Histidine"],
  },
  {
    primary_id: "D0T6UW",
    names: ["Tetraoxane Derivatives"],
  },
  {
    primary_id: "D0T6UX",
    names: ["PMID25522065-Compound-24"],
  },
  {
    primary_id: "D0T6VA",
    names: [
      "SC-514",
      "354812-17-2",
      "Sc 514",
      "SC514",
      "4-Amino-[2,3'-Bithiophene]-5-Carboxamide",
      "3-Amino-5-(Thiophen-3-Yl)Thiophene-2-Carboxamide",
      "CHEMBL193093",
      "IKK-2 Inhibitor, SC-514",
      "5-(Thien-3-Yl)-3-Aminothiophene-2-Carboxamide",
      "C9H8N2OS2",
      "GK 01140",
      "Curator_000007",
      "4-Amino-[2,3-Bithiophene]-5-Carboxamide",
      "BiomolKI_000076",
      "BiomolKI2_000080",
      "AC1MD9M6",
      "3-Amino-5-Thiophen-3-Ylthiophene-2-Carboxamide",
      "Oprea1_591652",
      "SCHEMBL4082097",
      "KS-00000XLB",
      "CHEBI:92464",
      "ZINC81904",
      "CTK8E9458",
      "BDBM26098",
      "SYN5231",
    ],
  },
  {
    primary_id: "D0T6VB",
    names: ["Cynooxide Anion"],
  },
  {
    primary_id: "D0T6VD",
    names: ["Meglumine Antimoniate"],
  },
  {
    primary_id: "D0T6VU",
    names: ["ED-110"],
  },
  {
    primary_id: "D0T6WL",
    names: [
      "3,3'-(1,3-Thiazol-2,4-Diyl)Diphenol",
      "CHEMBL512196",
      "SCHEMBL1180078",
      "BDBM25836",
      "WSUUHCNQLBCNOV-UHFFFAOYSA-N",
      "Hydroxyphenyl Substituted Thiazole, 8",
      "3,3'-(1,3-Thiazole-2,4-Diyl)Diphenol",
    ],
  },
  {
    primary_id: "D0T6WN",
    names: [
      "Rilpivirine",
      "500287-72-9",
      "TMC278",
      "Edurant",
      "TMC 278",
      "UNII-FI96A8X663",
      "R278474",
      "TMC-278",
      "4-{[4-({4-[(E)-2-Cyanoethenyl]-2,6-Dimethylphenyl}amino)Pyrimidin-2-Yl]amino}benzonitrile",
      "CHEBI:68606",
      "FI96A8X663",
      "R 278474",
      "4-{[4-({4-[(E)-2-Cyanovinyl]-2,6-Dimethylphenyl}amino)Pyrimidin-2-Yl]amino}benzonitrile",
      "(E)-4-((4-((4-(2-Cyanovinyl)-2,6-Dimethylphenyl)Amino)Pyrimidin-2-Yl)Amino)Benzonitrile",
      "(E)-4-(4-(4-(2-Cyanovinyl)-2,6-Dimethylphenylamino)Pyrimidin-2-Ylamino)Benzonitrile",
      "W-202888",
      "RPV",
      "Mu O-Conotoxin",
    ],
  },
  {
    primary_id: "D0T6WT",
    names: ["Heroin Diacetylmorphine", "Diamorphine (TN)"],
  },
  {
    primary_id: "D0T6WV",
    names: ["4-(2-Butyl-Benzoimidazol-1-Ylmethyl)-Phenol", "CHEMBL71254", "SCHEMBL9129370"],
  },
  {
    primary_id: "D0T6XB",
    names: ["WS-3"],
  },
  {
    primary_id: "D0T6XC",
    names: [
      "N2-[4-(Benzyloxy)Benzyl]glycinamide",
      "2-({[4-(Benzyloxy)Phenyl]methyl}amino)Acetamide",
      "CHEMBL348597",
      "EN300-53966",
      "774506-05-7",
      "AC1Q4ZPD",
      "SCHEMBL7391510",
      "CTK7D3324",
      "MolPort-004-292-381",
      "ZINC21956233",
      "BDBM50221278",
      "AKOS000127788",
      "MCULE-6620856689",
      "NE46952",
      "BBV-007676",
      "2-(4-Benzyloxy-Benzylamino)-Acetamide",
      "2-[[4-(Benzyloxy)Benzyl]amino]acetamide",
    ],
  },
  {
    primary_id: "D0T6XD",
    names: [
      "10S-Hydroxylobel-7-Ene",
      "CHEMBL124219",
      "SCHEMBL14178950",
      "BDBM50080826",
      "(S)-1-Phenyl-2-(1-Methyl-6alpha-Trans-Styryl-2alpha-Piperidinyl)Ethanol",
    ],
  },
  {
    primary_id: "D0T6XM",
    names: [
      "1,3-Diisobutyl-3,7-Dihydro-Purine-2,6-Dione",
      "1,3-Diisobutylxanthine",
      "Xanthine, 1,3-Diisobutyl-",
      "BRN 1143460",
      "CHEMBL26350",
      "1H-Purine-2,6-Dione, 3,7-Dihydro-1,3-Bis(2-Methylpropyl)-",
      "3,7-Dihydro-1,3-Bis(2-Methylpropyl)-1H-Purine-2,6-Dione",
      "63908-24-7",
      "AC1MIMT3",
      "DTXSID70213605",
      "BDBM50022731",
      "LS-126573",
    ],
  },
  {
    primary_id: "D0T6XN",
    names: [
      "6-O-Phosphoryl Inosine Monophosphate",
      "IMO",
      "AC1L9GY0",
      "SCHEMBL4321566",
      "DB03510",
      "6-(Phosphonooxy)-9-(5-O-Phosphono-Beta-D-Ribofuranosyl)-9H-Purine",
      "[9-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Phosphonooxymethyl)Oxolan-2-Yl]purin-6-Yl] Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0T6XU",
    names: ["Myelopid"],
  },
  {
    primary_id: "D0T6XX",
    names: [
      "Pantoprazole",
      "Astropan",
      "Pantoprazol",
      "Pantoprazolum",
      "Pantoprozole",
      "Pantor",
      "Pantozol",
      "Protium",
      "Protonix",
      "Somac",
      "Pantoprazole Na",
      "Pantoprazole Sodium",
      "Protonix IV",
      "BY 1023",
      "Astropan (TN)",
      "BY-1023",
      "Controloc (TN)",
      "Inipomp (TN)",
      "Pantecta (TN)",
      "Pantoloc (TN)",
      "Pantopan (TN)",
      "Pantoprazol [INN-Spanish]",
      "Pantoprazolum [INN-Latin]",
      "Pantor (TN)",
      "Pantotab (TN)",
      "Pantozol (TN)",
      "Protium (TN)",
      "Protonix (TN)",
      "SK&F 96022",
      "SK-96022",
      "SKF-96022",
      "Somac (TN)",
      "Ulcepraz (TN)",
      "Pantoprazole (USAN/INN)",
      "Pantoprazole [USAN:BAN:INN]",
      "SK&F-96022",
      "5-(Difluoromethoxy)-2-(((3,4-Dimethoxy-2-Pyridyl)Methyl)Sulfinyl)Benzimidazole",
      "5-(Difluoromethoxy)-2-{[(3,4-Dimethoxypyridin-2-Yl)Methyl]sulfinyl}-1H-Benzimidazole",
      "6-(Difluoromethoxy)-2-[(3,4-Dimethoxypyridin-2-Yl)Methylsulfinyl]-1H-Benzimidazole",
    ],
  },
  {
    primary_id: "D0T6YJ",
    names: ["4-(3-Chlorobenzylthio)-2-Aminobutanoic Acid", "CHEMBL203616", "BDBM50179722"],
  },
  {
    primary_id: "D0T6ZX",
    names: ["HYDROSULFIDE"],
  },
  {
    primary_id: "D0T7AE",
    names: ["OMS-182410"],
  },
  {
    primary_id: "D0T7AR",
    names: [
      "2-(3-Chlorophenyl)Histamine",
      "2-(3-Chlorophenyl)-Histamine",
      "SCHEMBL3028276",
      "GTPL1195",
      "CHEMBL287052",
      "BDBM86172",
      "PDSP1_001307",
      "PDSP2_001291",
      "AKOS022543464",
      "AKOS023903196",
      "133118-40-8",
    ],
  },
  {
    primary_id: "D0T7BD",
    names: ["PF-06669571"],
  },
  {
    primary_id: "D0T7BE",
    names: ["Pyridine Derivative 19"],
  },
  {
    primary_id: "D0T7BH",
    names: ["Resorcinol Compound 34"],
  },
  {
    primary_id: "D0T7BI",
    names: ["V160"],
  },
  {
    primary_id: "D0T7BP",
    names: ["1-(4-(Methylsulfonyl)Phenyl)-3-P-Tolylurea", "CHEMBL253402", "SCHEMBL12793734"],
  },
  {
    primary_id: "D0T7BV",
    names: ["PMID22911925C2", "Compound 37 [PMID: 22468684]", "GTPL6950", "BDBM50397703", "0K9"],
  },
  {
    primary_id: "D0T7BZ",
    names: ["7-(3-(Methoxymethyl)Phenyl)-2-Methylquinoline", "CHEMBL231985"],
  },
  {
    primary_id: "D0T7CB",
    names: [
      "Alpha,beta-Methylene-DCTP",
      "2'-Deoxy-5'-O-[(S)-Hydroxy{[(S)-Hydroxy(Phosphonooxy)Phosphoryl]methyl}phosphoryl]cytidine",
      "XC5",
    ],
  },
  {
    primary_id: "D0T7CM",
    names: ["UCL-2000 And Butabindide"],
  },
  {
    primary_id: "D0T7CN",
    names: ["Ac-ICVWQD(5fW)GAHRCT-NH2"],
  },
  {
    primary_id: "D0T7CR",
    names: ["MOv19-BBz CAR T Cells"],
  },
  {
    primary_id: "D0T7DV",
    names: ["SODIUM ZINC DIHYDROLIPOYLHISTIDINATE", "CHEMBL226494"],
  },
  {
    primary_id: "D0T7DW",
    names: ["DPPA-5"],
  },
  {
    primary_id: "D0T7EJ",
    names: [
      "N2-{4-[(3-Chlorobenzyl)Oxy]benzyl}glycinamide",
      "CHEMBL156994",
      "SCHEMBL962597",
      "ZBEJIFHLSRYHPP-UHFFFAOYSA-N",
      "BDBM50221276",
      "ZINC53246395",
      "2-[4-(3-Chlorobenzyloxy)Benzyl]aminoacetamide",
      "2-[4-(3-Chlorobenzyl)Oxybenzyl] Aminoacetamide",
      "2-[4-(3-Chloro-Benzyloxy)-Benzylamino]-Acetamide",
    ],
  },
  {
    primary_id: "D0T7EZ",
    names: ["CL-387626"],
  },
  {
    primary_id: "D0T7FO",
    names: ["MOD-7023"],
  },
  {
    primary_id: "D0T7GC",
    names: [
      "Pyr3",
      "1160514-60-2",
      "GTPL4293",
      "SCHEMBL12274132",
      "CTK8E8091",
      "DTXSID60719181",
      "AOB5992",
      "MolPort-023-276-923",
      "Pyr3, &gt",
      "ZINC44607934",
      "AKOS024457787",
      "NCGC00370970-01",
      "HY-108465",
      "RT-017534",
      "CS-0028846",
      "B7494",
      "J-003374",
      "Ethyl 1-[4-(Trichloroprop-2-Enamido)Phenyl]-5-(Trifluoromethyl)-1H-Pyrazole-4-Carboxylate",
      "1-[4-(2,3,3-Trichloro-Acryloylamino)-Phenyl]-5-Trifluoromethyl-1H-Pyrazole-4-Carboxylic Acid Ethyl Ester",
      "Ethyl-1-(4-(2,3,3-Trichloroacrylamide)Phenyl)-5-(Trifluoromethyl)-1H-Pyrazole-4-Carboxylate",
    ],
  },
  {
    primary_id: "D0T7GG",
    names: ["LUF-5816"],
  },
  {
    primary_id: "D0T7GQ",
    names: ["APETX2"],
  },
  {
    primary_id: "D0T7GV",
    names: [
      "Picoplatin",
      "AMD 473",
      "JM-473",
      "ZD0473",
      "(SP-4-3)-Amminedichloro(2-Methylpyridine)Platinium",
      "Platinum, Amminedichloro(2-Methylpyridine)-, (SP-4-3)-",
      "Picoplatin [INN:BAN]",
      "UNII-B5TAN0L720",
      "ZD 0473",
      "AC1L42P3",
      "B5TAN0L720",
      "DB04874",
      "Amminedichloro(2-Methylpyridine)Platinium",
      "AN-30599",
      "LS-184068",
      "J-011604",
      "Azane",
      "2-Methylpyridine",
      "Dichloride",
    ],
  },
  {
    primary_id: "D0T7HK",
    names: ["Aryl Piperazine Derivative 15"],
  },
  {
    primary_id: "D0T7HT",
    names: ["Immunotherapeutic"],
  },
  {
    primary_id: "D0T7IG",
    names: [
      "RTI-20",
      "133627-12-0",
      "CHEMBL288336",
      "2-Chloro-N11-Ethyl-N5-Methyl-5,11-Dihydro-6H-Dipyrido(3,2-B:2',3'-E)(1,4)Diazepin-6-One",
      "2-Chloro-N11-Ethyl-N5-Methyl-5,11-Dihydro-6H-Dipyrido(3,2-B:2,3-E)(1,4)Diazepin-6-One",
      "2-Chloro-N11-Ethyl-N5-Methyl-5,11-Dihydro-6H-Dipyrido[3,2-B:2',3'-E][1,4]diazepin-6-One",
      "ACMC-20mv0r",
      "AC1L9QW9",
      "Dipyridodiazepinone Deriv. 16",
      "BDBM1529",
      "SCHEMBL8889357",
      "CTK4B8683",
      "DTXSID70158249",
      "2-Chloro-11-Ethyl-5-Methyl-Dipyrido[[?],[?]][1,4]diazepin-6-One",
    ],
  },
  {
    primary_id: "D0T7LB",
    names: ["5'-Deoxy-4'-Hydroxy-5'-Methylthio-DADMe-ImmH"],
  },
  {
    primary_id: "D0T7LR",
    names: ["2-Methoxy-4-(P-Tolyloxy)Benzonitrile", "CHEMBL501649", "SCHEMBL17354615"],
  },
  {
    primary_id: "D0T7LV",
    names: ["LIPO-4"],
  },
  {
    primary_id: "D0T7MJ",
    names: ["PF-06412562"],
  },
  {
    primary_id: "D0T7MN",
    names: ["ZYH-1"],
  },
  {
    primary_id: "D0T7MZ",
    names: ["GALE-302"],
  },
  {
    primary_id: "D0T7NO",
    names: ["Humanized Mab Program"],
  },
  {
    primary_id: "D0T7OE",
    names: ["NEO100"],
  },
  {
    primary_id: "D0T7OI",
    names: ["Anti-LAG3"],
  },
  {
    primary_id: "D0T7OQ",
    names: ["PMID29671355-Compound-73"],
  },
  {
    primary_id: "D0T7OS",
    names: ["AL-6802", "EGFR Modulators (Cancer), Advenchen/Simcere"],
  },
  {
    primary_id: "D0T7OW",
    names: [
      "Dopamine",
      "Dopamin",
      "Dopamina",
      "Dopaminum",
      "Dophamine",
      "Hydroxytyramin",
      "Hydroxytyramine",
      "Intropin",
      "LDP",
      "Oxytyramine",
      "Revimine",
      "ASL 279",
      "IP 498",
      "Dopamina [INN-Spanish]",
      "Dopamine (INN)",
      "Dopamine [INN:BAN]",
      "Dopaminum [INN-Latin]",
      "L-DOPAMINE",
      "M-Hydroxytyramine Hydrochloride",
      "Medopa (TN)",
      "Intropin [*hydrochloride*]",
      "KW-3-060",
      "Beta-(3,4-Dihydroxyphenyl)Ethylamine Hydrochloride",
      "Dopamine (USAN)(*hydrochloride*)",
      "A-(3,4-Dihydroxyphenyl)-B-Aminoethane",
      "Alpha-(3,4-Dihydroxyphenyl)-Beta-Aminoethane",
      "Pyrocatechol, 4-(2-Aminoethyl)-(8CI)",
      "Pyrocatechol, 4-(2-Aminoethyl)-, Hydrochloride",
      "(3H)-Dopamine",
      "1,2-Benzenediol, 4-(2-Aminoethyl)-(9CI)",
      "1,2-Benzenediol, 4-(2-Aminoethyl)-, Hydrochloride",
      "1,2-Benzenediol, 4-(2-Aminoethyl)-, Labeled With Tritium",
      "153C5321-5FEE-4B0B-8925-F388F0EEEBD1",
      "2-(3,4-Dihydroxyphenyl)Ethylamine",
      "2-Benzenediol",
      "3,4-Dihydroxyphenethylamine Hydrochloride",
      "3,4-Dihydroxyphenylethylamine",
      "3,4-Dihydroxyphenethylamine",
      "3-Hydroxtyramine",
      "3-Hydroxytyramine",
      "3-Hydroxytyramine Hydrobromide",
      "3-Hydroxytyramine Hydrochloride",
      "4-(2-Aminoethyl)-1",
      "4-(2-Aminoethyl)-1,2-Benzenediol",
      "4-(2-Aminoethyl)-1,2-Bezenediol",
      "4-(2-Aminoethyl)Benzene-1,2-Diol",
      "4-(2-Aminoethyl)Catechol",
      "4-(2-Aminoethyl)Pyrocatechol",
      "4-(2-Aminoethyl)Pyrocatechol Hydrochloride",
      "4-(2-Aminoethyl)-Pyrocatechol",
    ],
  },
  {
    primary_id: "D0T7PZ",
    names: ["NAI-107"],
  },
  {
    primary_id: "D0T7QC",
    names: ["TRU-016"],
  },
  {
    primary_id: "D0T7QF",
    names: [
      "(S)-Tert-Butyl 1-Oxohexan-2-Ylcarbamate",
      "CHEMBL96875",
      "((S)-1-Formyl-Pentyl)-Carbamic Acid Tert-Butyl Ester",
      "BML-244",
      "Carbamic Acid, [(1S)-1-Formylpentyl]-, 1,1-Dimethylethyl Ester",
      "SCHEMBL3285479",
      "CTK0G6629",
      "OBMGXPJNZKYOQY-VIFPVBQESA-N",
      "ZINC13588585",
      "BDBM50137790",
      "AKOS030572335",
      "Tert-Butyl(1S)-1-Formylpentylcarbamate",
      "CCG-207873",
      "2(S)-(Tert-Butoxycarbonylamino)Hexanal",
      "Tert-Butyl (1S)-1-Formylpentylcarbamate",
      "(2S)-2-(Tert-Butoxycarbonylamino)Hexanal",
      "(S)-2-(Tert-Butoxycarbonylamino)-5-Methylpentanal",
    ],
  },
  {
    primary_id: "D0T7QJ",
    names: ["BI 1361849"],
  },
  {
    primary_id: "D0T7QN",
    names: ["SPN-811"],
  },
  {
    primary_id: "D0T7QT",
    names: [
      "3'-Oxo-Adenosine",
      "3'-OXO-ADENOSINE",
      "ADY",
      "AC1L9K4U",
      "DB03273",
      "9-Beta-D-Erythro-Pentofuranosyl-3-Ulose-9H-Purin-6-Amine",
    ],
  },
  {
    primary_id: "D0T7QY",
    names: ["Pyrazole Derivative 38"],
  },
  {
    primary_id: "D0T7RJ",
    names: ["PMID28092474-Compound-34"],
  },
  {
    primary_id: "D0T7RY",
    names: [
      "6,7-Dichloro-2,3,4,5-Tetrahydro-1H-3-Benzazepine",
      "CHEMBL262862",
      "180160-89-8",
      "SCHEMBL7885461",
      "WMCWIMZEYWNBOQ-UHFFFAOYSA-N",
      "BDBM50375903",
      "L021806",
    ],
  },
  {
    primary_id: "D0T7SA",
    names: ["4-Biphenyl-2-Ylethynyl-2-Methyl-Thiazole", "CHEMBL183608"],
  },
  {
    primary_id: "D0T7SU",
    names: ["ISIS 191729"],
  },
  {
    primary_id: "D0T7TB",
    names: ["Gly-Amp-Glu"],
  },
  {
    primary_id: "D0T7UG",
    names: ["N-(2,3-Dihydro-1H-Inden-2-Yl)Isoquinolin-1-Amine", "CHEMBL470205"],
  },
  {
    primary_id: "D0T7UP",
    names: ["3-Fluorovinylglycine"],
  },
  {
    primary_id: "D0T7US",
    names: [
      "Eprosartan",
      "133040-01-4",
      "Teveten",
      "F-108566",
      "F 108566",
      "UNII-2KH13Z0S0Y",
      "(E)-4-((2-Butyl-5-(2-Carboxy-3-(Thiophen-2-Yl)Prop-1-En-1-Yl)-1H-Imidazol-1-Yl)Methyl)Benzoic Acid",
      "(E)-2-Butyl-1-(P-Carboxybenzyl)-Alpha-2-Thenylimidazole-5-Acrylic Acid",
      "CHEMBL813",
      "SK-108566",
      "2KH13Z0S0Y",
      "CHEBI:4814",
      "4-({2-Butyl-5-[(1E)-2-Carboxy-3-(2-Thienyl)Prop-1-En-1-Yl]-1H-Imidazol-1-Yl}methyl)Benzoic Acid",
      "(E)-3-[2-N-Butyl-1-{(4-Carboxyphenyl)Methyl}-1H-Imidazol-5-Yl]-2-(2-Thienyl)Methyl-2-Propenoic Acid",
      "SK&F 108566",
      "SKF-108566",
      "Teveten (TN)",
      "Eprosartan (USAN/INN)",
      "Eprosartan [USAN:BAN:INN]",
      "SK&F-108566",
      "(4-Carboxybenzyl)Imidazole-5-Acrylic Acid",
      "(E)-Alpha{[2-Butyl-1-[(4-Carboxyphenyl)Methyl]-1H-Imidazole-5-Yl]methylene}-2-Thiopheneproprionic Acid",
      "4-({2-Butyl-5-[(1E)-2-Carboxy-3-(Thiophen-2-Yl)Prop-1-En-1-Yl]-1H-Imidazol-1-Yl}methyl)Benzoic Acid",
      "4-[[2-Butyl-5-[(E)-3-Hydroxy-3-Oxo-2-(Thiophen-2-Ylmethyl)Prop-1-Enyl]imidazol-1-Yl]methyl]benzoic Acid",
      "[3H]eprosartan",
    ],
  },
  {
    primary_id: "D0T7VA",
    names: [
      "NSC-50187",
      "4'-Methoxyflavanone",
      "Flavanone, 4'-Methoxy-",
      "3034-08-0",
      "2-(4-Methoxyphenyl)Chroman-4-One",
      "NSC50187",
      "97005-76-0",
      "2-(4-Methoxy-Phenyl)-Chroman-4-One",
      "CHEMBL241909",
      "2-(4-Methoxyphenyl)-2,3-Dihydrochromen-4-One",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-2-(4-Methoxyphenyl)-",
      "ST070122",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-2-(4-Methoxyphenyl)-, (S)-",
      "4''-Methoxyflavanone",
      "AC1Q6KJS",
      "AC1L2TV7",
      "Oprea1_176638",
      "Oprea1_635195",
      "MLS000574900",
      "SCHEMBL127704",
      "MEGxp0_001700",
      "CTK4G4994",
      "CHEBI:63329",
      "ACon1_000211",
      "QIUYUYOXCGBABP-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0T7VN",
    names: ["Aryl Mannoside Derivative 20"],
  },
  {
    primary_id: "D0T7VU",
    names: ["2-N-(Isopropyl)Amino-1-(4-Methylthiophenyl)Butane", "CHEMBL1077899"],
  },
  {
    primary_id: "D0T7VZ",
    names: ["2-(1-Benzyl-1H-Indol-3-Yl)-N,N-Dimethylethanamine", "CHEMBL188144", "BDBM50164758"],
  },
  {
    primary_id: "D0T7WL",
    names: ["Pirmenol"],
  },
  {
    primary_id: "D0T7WQ",
    names: ["Datelliptium Chloride", "Datelliptinium", "Detalliptinium", "SR-95156B"],
  },
  {
    primary_id: "D0T7WW",
    names: ["4-(2-Cyclohexylethoxy) Aniline Derivative 4"],
  },
  {
    primary_id: "D0T7XI",
    names: ["Methaqualone"],
  },
  {
    primary_id: "D0T7XN",
    names: [
      "E-2070",
      "Bayer E-2070",
      "64050-66-4",
      "ENT 22,742",
      "Phosphoric Acid, 2-Chloroethyl 2,2-Dichlorovinyl Propyl Ester",
      "AC1Q3VAX",
      "AC1L3IYS",
      "2-Chloroethyl 2,2-Dichlorovinyl Propyl Phosphate",
      "2-Chloroethyl 2,2-Dichloroethenyl Propyl Phosphate",
      "CTK5C0574",
      "ENT-22742",
      "LS-107547",
      "Phosphoric Acid,2-Chloroethyl 2,2-Dichloroethenyl Propyl Ester",
    ],
  },
  {
    primary_id: "D0T7XO",
    names: ["IGEL1.2"],
  },
  {
    primary_id: "D0T7YE",
    names: [
      "LYC-55716",
      "Cintirorgon",
      "UNII-LPN433P0EA",
      "LPN433P0EA",
      "2055536-64-4",
      "Cintirorgon [INN]",
      "Cintirorgon (Proposed INN)",
      "SCHEMBL18302870",
      "GTPL10045",
      "LYC55716",
      "BCP28882",
      "EX-A2635",
      "LYC 55716",
      "CS-7496",
      "Compound 32J [WO2016201225A1]",
      "HY-104037",
      "3-[(2S)-6-[3-(Difluoromethoxy)-5-Fluorophenyl]-4-[3-(Trifluoromethyl)Phenyl]sulfonyl-2,3-Dihydro-1,4-Benzoxazin-2-Yl]-2,2-Dimethylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0T7YM",
    names: ["Oleic Acid Anilide"],
  },
  {
    primary_id: "D0T7ZN",
    names: ["Dimethyl-Pent-4-Enyl-Ammonium Bromide"],
  },
  {
    primary_id: "D0T7ZQ",
    names: ["Estradiol Acetate"],
  },
  {
    primary_id: "D0T8AD",
    names: ["(S)-N2-{4-[(4-Chlorobenzyl)Oxy]benzyl}serinamide", "CHEMBL243077"],
  },
  {
    primary_id: "D0T8AI",
    names: ["Y-90 Clivatuzumab"],
  },
  {
    primary_id: "D0T8AJ",
    names: ["N-(Biphenyl-3-Yl)-6-(Sulfamoylamino)Hexanamide", "CHEMBL475301", "SCHEMBL5327672"],
  },
  {
    primary_id: "D0T8AS",
    names: ["Pyrimidine Benzenesulfonamide Derivative 1"],
  },
  {
    primary_id: "D0T8BA",
    names: ["Sulfonamide", "63-74-1"],
  },
  {
    primary_id: "D0T8BP",
    names: ["AH-9700"],
  },
  {
    primary_id: "D0T8BU",
    names: [
      "3-(2-Phenyl-2-(Piperazin-1-Yl)Ethyl)Phenol",
      "CHEMBL210026",
      "61311-64-6",
      "3-[2-Phenyl-2-(Piperazin-1-Yl)Ethyl]phenol",
      "SCHEMBL8531930",
      "CTK2E2737",
      "DTXSID30552209",
      "VPNYIGJUQBPBMU-UHFFFAOYSA-N",
      "BDBM50188056",
      "Phenol, 3-[2-Phenyl-2-(1-Piperazinyl)Ethyl]-",
      "1-[1-Phenyl-2-(3-Hydroxyphenyl)Ethyl]piperazine",
    ],
  },
  {
    primary_id: "D0T8CE",
    names: ["AMG888/U3-1287"],
  },
  {
    primary_id: "D0T8CQ",
    names: ["CP-70949"],
  },
  {
    primary_id: "D0T8DD",
    names: ["EPCAM-Targeted CAR-T Cells"],
  },
  {
    primary_id: "D0T8DH",
    names: ["Isoxazole-Based Bicyclic Compound 15"],
  },
  {
    primary_id: "D0T8DR",
    names: ["4-Hexyl-N-(Quinolin-3-Yl)Benzamide", "CHEMBL258623", "SCHEMBL846650"],
  },
  {
    primary_id: "D0T8EH",
    names: ["Pirarubicin"],
  },
  {
    primary_id: "D0T8EL",
    names: [
      "AHL",
      "AHLi-11",
      "SiRNA Therapeutics (Hearing Loss), Quark",
      "P53 Gene-Silencing SiRNA (Hearing Loss), Quark",
    ],
  },
  {
    primary_id: "D0T8GD",
    names: ["Nicotine"],
  },
  {
    primary_id: "D0T8HI",
    names: ["V-114"],
  },
  {
    primary_id: "D0T8HR",
    names: ["Naptumomab Estafenatox"],
  },
  {
    primary_id: "D0T8HU",
    names: ["LU-29252"],
  },
  {
    primary_id: "D0T8HZ",
    names: ["Pegvaliase", "BMN 165", "Pegvaliase [INN]", "UNII-N6UAH27EUV", "N6UAH27EUV"],
  },
  {
    primary_id: "D0T8IK",
    names: [
      "S-ATPO",
      "CHEMBL594840",
      "(S)-2-Amino-3-(5-Tert-Butyl-3-(Phosphonomethoxy)-4-Isoxazolyl)Propionic Acid",
      "2-AMINO-3-(5-TERT-BUTYL-3-(PHOSPHONOMETHOXY)-4-ISOXAZOLYL)PROPIONIC ACID",
      "(2S)-2-Amino-3-[5-Tert-Butyl-3-(Phosphonomethoxy)-1,2-Oxazol-4-Yl]propanoic Acid",
      "AT1",
      "1n0t",
      "AC1L9KN0",
      "(S)-ATPO",
      "SCHEMBL5457168",
      "BCP16890",
      "ZINC2047684",
      "BDBM50304093",
      "AKOS032960456",
      "DB02347",
      "A-330",
      "3-[5-Tert-Butyl-3-(Phosphonomethoxy)Isoxazol-4-Yl]-L-Alanine",
      "(S)-2-Amino-3-[5-Tertbutyl-3-(Phosphono-Methoxy)-4-Isoxazolyl]-Propionic Acid",
    ],
  },
  {
    primary_id: "D0T8IS",
    names: ["MLN-120B"],
  },
  {
    primary_id: "D0T8IW",
    names: ["Ultimab IgG1 Antibody"],
  },
  {
    primary_id: "D0T8JM",
    names: [
      "[3H]GW2331",
      "GTPL2706",
      "2-[4-[2-[(2,4-Difluoro-6-Tritiophenyl)Carbamoyl-Heptylamino]ethyl]phenoxy]-2-Methylbutanoic Acid",
    ],
  },
  {
    primary_id: "D0T8JU",
    names: [
      "1-(4-Methoxybenzyl)-5-Phenyl-1H-Imidazole",
      "1192811-38-3",
      "CHEMBL591697",
      "SCHEMBL1950743",
      "DTXSID90668214",
      "ZINC45338026",
      "BDBM50307235",
      "AKOS016012754",
      "KB-214961",
      "AJ-110482",
      "AX8249207",
    ],
  },
  {
    primary_id: "D0T8JY",
    names: ["BMS-986277"],
  },
  {
    primary_id: "D0T8KA",
    names: ["JNJ-18038683"],
  },
  {
    primary_id: "D0T8KD",
    names: ["Eberconazole", "Ebernet", "WAS-2160"],
  },
  {
    primary_id: "D0T8KF",
    names: ["SUN-8399"],
  },
  {
    primary_id: "D0T8KR",
    names: ["TWS-119"],
  },
  {
    primary_id: "D0T8LE",
    names: ["INTERCEPT Blood System"],
  },
  {
    primary_id: "D0T8LF",
    names: ["Ragweed Pollen Extract"],
  },
  {
    primary_id: "D0T8LL",
    names: ["EndocrineTherapy"],
  },
  {
    primary_id: "D0T8LP",
    names: ["Cyclic CMP", "Cytidine, Cyclic 3',5'-(Hydrogen Phosphate)"],
  },
  {
    primary_id: "D0T8LY",
    names: [
      "PYRIDINE",
      "Azabenzene",
      "110-86-1",
      "Azine",
      "Pyridin",
      "Pirydyna",
      "Piridina",
      "Pyridin [German]",
      "Pirydyna [Polish]",
      "Piridina [Italian]",
      "RCRA Waste Number U196",
      "NCI-C55301",
      "Caswell No. 717",
      "CCRIS 2926",
      "HSDB 118",
      "Pyridine-Ring",
      "CHEBI:16227",
      "FEMA Number 2966",
      "CP 32",
      "UNII-NH9L3PP67S",
      "EINECS 203-809-9",
      "UN1282",
      "NSC 406123",
      "FEMA No. 2966",
      "RCRA Waste No. U196",
      "EPA Pesticide Chemical Code 069202",
      "NH9L3PP67S",
      "AI3-01240",
      "Pyridine Fraction",
      "JUJWROOIHBZHMG-UHFFFAOYSA-N",
      "MFCD00011732",
      "Pentadeuteropyridine",
      "Pyridine [UN1282]  [Flammable Liquid]",
      "7291-22-7",
    ],
  },
  {
    primary_id: "D0T8MA",
    names: ["ATN-224", "Angiogenesis Inhibitor, Attenuon", "Superoxide Dismutase 1 Inhibitor, Attenuon"],
  },
  {
    primary_id: "D0T8MJ",
    names: ["1,2,4-Triazole [1,5-A]pyrimidin-7-Yl Derivative 2"],
  },
  {
    primary_id: "D0T8MM",
    names: ["[35S]ATPgammaS", "[35S]-ATPgammaS"],
  },
  {
    primary_id: "D0T8MV",
    names: ["(S)-1-(4-Methoxyphenethyl)-2-Methylpyrrolidine", "CHEMBL404532"],
  },
  {
    primary_id: "D0T8NS",
    names: ["AZD-8677"],
  },
  {
    primary_id: "D0T8NU",
    names: ["PMID19115845C89S", "3du8", "GTPL8114", "BDBM27380", "DB07149"],
  },
  {
    primary_id: "D0T8OD",
    names: [
      "Clobenpropit",
      "145231-45-4",
      "Vuf-9153",
      "Vuf 9153",
      "UNII-RKU631JF4H",
      "S-(3-(4(5)-Imidazolyl))Propyl-N-(4-Chlorobenzyl)Isothiourea",
      "CHEBI:64177",
      "RKU631JF4H",
      "3-(1h-Imidazol-5-Yl)Propyl N'-(4-Chlorobenzyl)Carbamimidothioate",
      "3-(1H-Imidazol-4-Yl)Propyl ((4-Chlorophenyl)Methyl)Carbamimidothioate",
      "Carbamimidothioic Acid, ((4-Chlorophenyl)Methyl)-, 3-(1H-Imidazol-4-Yl)Propyl Ester",
      "3-(1H-Imidazol-4-Yl)Propyl N-(4-Chlorobenzyl)Carbamimidothioate",
      "Carbamimidothioic Acid,N-[(4-Chlorophenyl)Methyl]-, 3-(1H-Imidazol-5-Yl)Propyl Ester",
    ],
  },
  {
    primary_id: "D0T8PK",
    names: ["Indirubin Derivative 1"],
  },
  {
    primary_id: "D0T8QB",
    names: ["GLYCYRRHIZIN"],
  },
  {
    primary_id: "D0T8QE",
    names: ["FMX102"],
  },
  {
    primary_id: "D0T8QL",
    names: ["PMID28394193-Compound-16"],
  },
  {
    primary_id: "D0T8QM",
    names: [
      "MQX-3016",
      "Actinic Keratosis Therapy (Topical/TAM), MediQuest",
      "Antizyme Inducer (Topical, Keratosis), MediQuest Therapeutics",
    ],
  },
  {
    primary_id: "D0T8QY",
    names: ["PMI-008"],
  },
  {
    primary_id: "D0T8RL",
    names: [
      "2-(2-(2-Fluorophenoxy)Phenyl)Acetic Acid",
      "CHEMBL29804",
      "BDBM50295281",
      "ZINC28767088",
      "AKOS022663030",
      "[2-(2-Fluoro-Phenoxy)-Phenyl]-Acetic Acid",
    ],
  },
  {
    primary_id: "D0T8RQ",
    names: ["166Ho-Phytate"],
  },
  {
    primary_id: "D0T8RV",
    names: ["BCMA CART And HuCART19"],
  },
  {
    primary_id: "D0T8SD",
    names: ["2-Hydroxy-Saclofen", "2-Hydroxy-S-(-)-Saclofen", "2-OH-Saclofen", "2-Hydroxysaclofen"],
  },
  {
    primary_id: "D0T8SN",
    names: [
      "4-Methoxyphenylsulfamide",
      "N-(4-Methoxyphenyl)Sulfamide",
      "CHEMBL165878",
      "10539-83-0",
      "(4-Methoxylphenyl)Sulfamide",
      "SCHEMBL1645328",
      "CTK8G5045",
      "N-(4-Methoxyphenyl)Sulfuric Diamide",
      "BDBM50124171",
      "ZINC13488096",
      "1-Methoxy-4-(Sulfamoylamino)-Benzene",
      "AKOS027393897",
      "DA-48085",
      "KB-299500",
      "FT-0726752",
      "79495-EP2305698A2",
      "79495-EP2305697A2",
      "79495-EP2305696A2",
    ],
  },
  {
    primary_id: "D0T8TC",
    names: ["VPC-8", "Autotaxin Inhibitors (Cancer/Fibrosis), Catena Pharmaceuticals"],
  },
  {
    primary_id: "D0T8TS",
    names: [
      "Hexanoic Acid 2,5-Diphenyl-Thiazol-4-Yl Ester",
      "CHEMBL67589",
      "BDBM50012391",
      "Hexanoic Acid 2,5-Diphenyl-4-Thiazolyl Ester",
    ],
  },
  {
    primary_id: "D0T8UH",
    names: ["E-3024", "DPP-IV Inhibitors (Type 2 Diabetes), Eisai", "ER-260891", "IDDBCP150088", "IDDBCP150131"],
  },
  {
    primary_id: "D0T8UR",
    names: ["3-Dimethylaminomethyl-1-Methyl-1H-Indol-4-Ol", "CHEMBL370221"],
  },
  {
    primary_id: "D0T8VE",
    names: ["Aminoazetidine Derivative 5"],
  },
  {
    primary_id: "D0T8VV",
    names: ["FLM-5011", "1-(2-Hydroxy-5-Methylphenyl)-1-Dodecanone Oxime", "2-Hydroxy-5-Methylaurophenone Oxime"],
  },
  {
    primary_id: "D0T8VY",
    names: ["Aceclofenac", "Airtal", "Barracan", "Biofenac", "Bristaflam", "Falcol", "Gerbin", "Preservex", "YT-919"],
  },
  {
    primary_id: "D0T8WU",
    names: ["Pyrido[3,2-E][1,2,4]triazolo[4,3-A]pyrazine Derivative 2"],
  },
  {
    primary_id: "D0T8XB",
    names: [
      "Anagliptin",
      "739366-20-2",
      "UNII-K726J96838",
      "Suiny",
      "CHEMBL1929396",
      "(S)-N-(2-((2-(2-Cyanopyrrolidin-1-Yl)-2-Oxoethyl)Amino)-2-Methylpropyl)-2-Methylpyrazolo[1,5-A]pyrimidine-6-Carboxamide",
      "K726J96838",
      "CHEMBL1929387",
      "N-[2-[[2-[(2S)-2-Cyanopyrrolidin-1-Yl]-2-Oxoethyl]amino]-2-Methylpropyl]-2-Methylpyrazolo[1,5-A]pyrimidine-6-Carboxamide",
      "Anagliptin [INN]",
      "N-[2-({2-[(2s)-2-Cyanopyrrolidin-1-Yl]-2-Oxoethyl}amino)-2-Methylpropyl]-2-Methylpyrazolo[1,5-A]pyrimidine-6-Carboxamide",
      "Anagliptin (JAN/INN)",
      "SCHEMBL905393",
      "Sk-0403",
    ],
  },
  {
    primary_id: "D0T8XD",
    names: ["Imidazo[1,2-B]pyridazine Derivative 1"],
  },
  {
    primary_id: "D0T8XY",
    names: ["PMID30280939-Compound-WO2015091734"],
  },
  {
    primary_id: "D0T8YK",
    names: ["EP-01492"],
  },
  {
    primary_id: "D0T8YV",
    names: [
      "LymphoRad-131",
      "Ardenermin",
      "LymphoRad",
      "LR-131",
      "Radiolabeled BLyS Protein, HGS",
      "Radiolabeled Ardenermine, HGS",
    ],
  },
  {
    primary_id: "D0T8ZA",
    names: ["Zohydro ER"],
  },
  {
    primary_id: "D0T8ZL",
    names: ["3-(2-Carboxy-3-Mercaptopropyl)Benzoic Acid"],
  },
  {
    primary_id: "D0T9BB",
    names: ["7-Methoxy-8-Propionyl-2H-Chromen-2-One", "CHEMBL1288621"],
  },
  {
    primary_id: "D0T9CD",
    names: [
      "Cobacan",
      "Cobalamin Coenzyme (Sublingual Lozenge, Pernicious Anemia), Bebaas",
      "CyaCbl-NO30amin + Hydroxycobalamin + Adenosylcobalamin (Sublingual Lozenge, Pernicious Anemia), Bebaas",
    ],
  },
  {
    primary_id: "D0T9CE",
    names: ["BCMA Nanobody CAR-T Cells"],
  },
  {
    primary_id: "D0T9CO",
    names: ["MelCancerVac"],
  },
  {
    primary_id: "D0T9CW",
    names: ["Heptulose-2-Phosphate"],
  },
  {
    primary_id: "D0T9DA",
    names: ["NGF-HTNF"],
  },
  {
    primary_id: "D0T9DI",
    names: [
      "UCCF-029",
      "2-Pyridin-4-Yl-Benzo[h]chromen-4-One",
      "2110-25-0",
      "CHEMBL177991",
      "2-(Pyridin-4-Yl)-4H-Benzo[h]chromen-4-One",
      "BAS 12813734",
      "AC1MKUU0",
      "MLS001209162",
      "ZINC9006",
      "SCHEMBL4069839",
      "GTPL4335",
      "CTK7H2722",
      "DTXSID20390354",
      "CHEBI:116557",
      "HMS2841G23",
      "BDBM50159612",
      "2-(4-Pyridyl)Benzo[h]chromen-4-One",
      "2-Pyridin-4-Ylbenzo[h]chromen-4-One",
      "AKOS000507523",
      "MCULE-4590967836",
      "2-(Pyridin-4-Yl)Benzo[h]chromen-4-One",
      "SMR000525005",
      "TR-043515",
      "ST50294170",
      "EC-000.1914",
      "4-(4-Oxo-4H-Benzo[h]chromen-2-Yl)-Pyridinium",
      "J-013846",
    ],
  },
  {
    primary_id: "D0T9DN",
    names: [
      "Dimiracetam",
      "BND-11624",
      "Dimiracetam, Neurotune",
      "NT-11624",
      "NMDA Receptor Antagonist (Oral, Neuropathic Pain/Osteoarthritic Pain), Neurotune",
    ],
  },
  {
    primary_id: "D0T9DS",
    names: ["[35S]TBPS", "35S-TBPS", "GTPL4369"],
  },
  {
    primary_id: "D0T9DT",
    names: ["Diatrizoate", "Diatrizoate Sodium"],
  },
  {
    primary_id: "D0T9DV",
    names: ["PMID28621580-Compound-WO2013036866C66"],
  },
  {
    primary_id: "D0T9FD",
    names: ["YKP-3089"],
  },
  {
    primary_id: "D0T9FE",
    names: ["5'-Fluoroindirubinoxime"],
  },
  {
    primary_id: "D0T9FQ",
    names: ["6,7-Dimethoxy-3-Pyridin-4-Yl-Quinoline", "CHEMBL280069", "BDBM50039650"],
  },
  {
    primary_id: "D0T9FU",
    names: [
      "BC-3205",
      "BC-3205 (Oral)",
      "BC-3205 (Oral), Nabriva",
      "Pleuromutilin (Oral, Respiratory Infection), Nabriva",
    ],
  },
  {
    primary_id: "D0T9GM",
    names: ["Nanoflu"],
  },
  {
    primary_id: "D0T9GQ",
    names: ["ASB-010"],
  },
  {
    primary_id: "D0T9HE",
    names: ["PMID30247903-Compound-General Structure15"],
  },
  {
    primary_id: "D0T9HG",
    names: ["GM6001"],
  },
  {
    primary_id: "D0T9IK",
    names: [
      "4-[2-(3,5-Dimethoxy-Phenyl)-Vinyl]-Pyridine",
      "CHEMBL43013",
      "SCHEMBL7047647",
      "SCHEMBL7047642",
      "BDBM50108054",
      "ZINC13471766",
      "AKOS015967530",
      "4-[(E)-3,5-Dimethoxystyryl]pyridine",
    ],
  },
  {
    primary_id: "D0T9IR",
    names: ["6-Aryl-Imidazopyridine And 6-Aryl-Triazolopyridine Carboxamide Analog 3"],
  },
  {
    primary_id: "D0T9IX",
    names: ["WT1 Immunotherapeutic"],
  },
  {
    primary_id: "D0T9JB",
    names: ["Tacrine-Coumarin Hybrid Derivative 1"],
  },
  {
    primary_id: "D0T9JK",
    names: ["VER-82160"],
  },
  {
    primary_id: "D0T9JW",
    names: ["AU101"],
  },
  {
    primary_id: "D0T9KE",
    names: ["1-Benzhydryl-4-(2-Methoxyphenyl)Piperidin-4-Ol", "CHEMBL233493"],
  },
  {
    primary_id: "D0T9KF",
    names: ["RDEA-427"],
  },
  {
    primary_id: "D0T9KR",
    names: ["PET Imaging Agent"],
  },
  {
    primary_id: "D0T9LH",
    names: ["MM-131"],
  },
  {
    primary_id: "D0T9LR",
    names: ["Liprotamase"],
  },
  {
    primary_id: "D0T9MQ",
    names: ["KN-203"],
  },
  {
    primary_id: "D0T9NX",
    names: ["1-(3-Methylpyridin-2-Yl)-4-Tosylpiperazine", "Arylsulfonylpiperazine, 24", "CHEMBL257782"],
  },
  {
    primary_id: "D0T9NZ",
    names: ["Glutathionylspermidine Disulfide"],
  },
  {
    primary_id: "D0T9OC",
    names: ["TL-118"],
  },
  {
    primary_id: "D0T9OL",
    names: ["Adamantyl Derivative 1"],
  },
  {
    primary_id: "D0T9PE",
    names: [
      "PT-310",
      "172505-79-2",
      "Thiourea, N-(2-(2-Hydroxyphenyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(2-Hydroxyphenyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT OH Deriv.",
      "PETT Analog 11",
      "AC1MHDMH",
      "SCHEMBL6957663",
      "BDBM1904",
      "CHEMBL400684",
      "DTXSID60169329",
      "1-[2-(2-Hydroxyphenyl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "1-(2-Thiazolyl)-3-[2-Hydroxyphenethyl]thiourea",
      "1-[2-(2-Hydroxyphenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "N-(2-(2-Hydroxyphenethyl))-N -(2-Thiazolyl)Thiourea",
    ],
  },
  {
    primary_id: "D0T9PL",
    names: ["BLV-0703", "BLV-200703", "KTP-NH2", "TAU-Targeting Compound (CNS Disorders), Bioalvo"],
  },
  {
    primary_id: "D0T9QW",
    names: ["Oxametacin"],
  },
  {
    primary_id: "D0T9RD",
    names: ["(Z)-2,6-Diisopropyl-N-Phenyloctadec-9-Enamide", "CHEMBL272675"],
  },
  {
    primary_id: "D0T9SJ",
    names: [
      "CEP-4186",
      "CB-1093",
      "CEP-3265",
      "GS-1590",
      "Neurotrophic Compounds, Cephalon/LEO Pharma",
      "Neurotrophic Factors, Cephalon/LEO Pharma",
    ],
  },
  {
    primary_id: "D0T9SK",
    names: ["Tetramethylammonium Ion"],
  },
  {
    primary_id: "D0T9TC",
    names: ["PMID17276684C22"],
  },
  {
    primary_id: "D0T9TE",
    names: [
      "Ombitasvir + Paritaprevir + Ritonavir",
      "ABT-267/ABT-450/Ritonavir",
      "ABT-267/Ritonavir/ABT-450",
      "ABT-450/ABT-267/Ritonavir",
      "ABT-450/R + NS5A",
      "ABT-450/R/ABT-267",
      "ABT-450/Ritonavir/ABT-267",
      "HOLKIRA PAK",
      "OBV/PTV/R",
      "Ombitasvir/ABT 450/Ritonavir",
      "Ombitasvir/Ritonavir/ABT-450",
      "Ombitasvir/Ritonavir/Veruprevir",
      "Paritaprevir/Ombitasvir/Ritonavir",
      "Ritonavir/ABT 450/Ombitasvir",
      "Ritonavir/ABT-267/ABT-450",
      "Ritonavir/ABT-450/ABT-267",
      "Ritonavir/Ombitasvir/ABT-450",
      "Ritonavir/Ombitasvir/Paritaprevir",
      "Ritonavir/Veruprevir/Ombitasvir",
      "TECHNIVIE",
      "Veruprevir/Ombitasvir/Ritonavir",
      "Viekira Pak (Ombitasvir/Paritaprevir/Ritonavir Combination Therapy)",
      "Viekirax",
    ],
  },
  {
    primary_id: "D0T9TJ",
    names: ["Orlistat"],
  },
  {
    primary_id: "D0T9VC",
    names: ["PMID24900283C8a"],
  },
  {
    primary_id: "D0T9WZ",
    names: ["PMID24556381C10f"],
  },
  {
    primary_id: "D0T9XC",
    names: ["Five-Membered Heteroaromatic Compound 2"],
  },
  {
    primary_id: "D0T9YF",
    names: ["A-74187"],
  },
  {
    primary_id: "D0T9YU",
    names: ["Imino Sugars"],
  },
  {
    primary_id: "D0T9YV",
    names: ["STD-06"],
  },
  {
    primary_id: "D0T9ZD",
    names: ["Adrenoleucodystrophy"],
  },
  {
    primary_id: "D0T9ZG",
    names: ["PLD-180"],
  },
  {
    primary_id: "D0T9ZL",
    names: ["Chelerythrine", "Broussonpapyrine", "Cheleritrine", "Chelerythrine Hydroxide", "Toddalin"],
  },
  {
    primary_id: "D0T9ZN",
    names: ["1-Adamantan-1-Yl-3-(4-Hydroxy-Decyl)-Urea", "CHEMBL195796"],
  },
  {
    primary_id: "D0T9ZO",
    names: ["PMID26924192-Compound-55"],
  },
  {
    primary_id: "D0T9ZQ",
    names: [
      "5-Chloro-1H-Indazole",
      "698-26-0",
      "1H-Indazole, 5-Chloro-",
      "5-Chloroindazole",
      "5-CHLORO INDAZOLE",
      "EINECS 211-812-1",
      "NSC 78434",
      "BRN 0003260",
      "CHEMBL246746",
      "FVNCILPDWNBPLK-UHFFFAOYSA-N",
      "AK-23822",
      "5-Chloro-Indazole",
      "2H-Indazole, 5-Chloro-",
      "NSC78434",
      "5-Chloro-2H-Indazole",
      "AC1L2CF8",
      "5-23-06-00175 (Beilstein Handbook Reference)",
      "5-CHLORO(1H)INDAZOLE",
      "SCHEMBL3540025",
      "SCHEMBL16523221",
      "CTK2F6769",
      "DTXSID70220091",
      "MolPort-000-002-983",
      "5-Chloro-1H-Indazole, AldrichCPR",
      "ZX-CM006597",
      "ZX-AT013670",
      "ZINC4876880",
      "BCP00023",
      "ACT10091",
      "FCH837623",
      "BDBM50209242",
    ],
  },
  {
    primary_id: "D0TA0J",
    names: [
      "3,3'-Thiene-2,5-Diyldiphenol",
      "SCHEMBL1181010",
      "CHEMBL511530",
      "BDBM25851",
      "SVBBCXVMACPXGW-UHFFFAOYSA-N",
      "ZINC40393198",
      "Hydroxyphenyl Substituted Thiophene, 23",
    ],
  },
  {
    primary_id: "D0TA0L",
    names: ["FR-181157"],
  },
  {
    primary_id: "D0TA3V",
    names: ["ADC-5510", "ADC-02265510", "ADL-5510", "Centrally Acting Mu Opoid Receptor Antagonists, Adolor"],
  },
  {
    primary_id: "D0TA4A",
    names: ["GLPG0974", "Compound 99 [PMID 25380412]"],
  },
  {
    primary_id: "D0TA4N",
    names: ["PMID28394193-Compound-13"],
  },
  {
    primary_id: "D0TA8F",
    names: ["(S,S)-Oxandrine Hydrochloride", "CHEMBL445011", "(S,S)-Oxandrine HCl"],
  },
  {
    primary_id: "D0TA8H",
    names: ["Istodaxromidepsin"],
  },
  {
    primary_id: "D0TA8O",
    names: ["LTT462"],
  },
  {
    primary_id: "D0TA8W",
    names: ["Benzene Sulfonamide Derivative 11"],
  },
  {
    primary_id: "D0TB1E",
    names: ["(S)(+)-7-Methyl-2-P-Tolylchroman-4-One", "CHEMBL600384"],
  },
  {
    primary_id: "D0TB1L",
    names: ["Indium In-111 Chloride"],
  },
  {
    primary_id: "D0TB1X",
    names: ["AL-0671", "AL-0670"],
  },
  {
    primary_id: "D0TB2H",
    names: ["Heteroaryl-Carboxamide Derivative 10"],
  },
  {
    primary_id: "D0TB2L",
    names: ["PMID27607364-Compound-61"],
  },
  {
    primary_id: "D0TB4A",
    names: ["Dmt-Pro-Emp-Phe-NH2", "CHEMBL374818"],
  },
  {
    primary_id: "D0TB4C",
    names: ["DNA Vaccine, University Of Southampton/Inovio"],
  },
  {
    primary_id: "D0TB8C",
    names: ["Nadrolone Phenylpropionate"],
  },
  {
    primary_id: "D0TC1E",
    names: ["RU82209"],
  },
  {
    primary_id: "D0TC1T",
    names: ["Ro-40-1641"],
  },
  {
    primary_id: "D0TC4I",
    names: [
      "(S)-2-Amino-4-Hydroxy-N-(4-Octylphenyl)Butanamide",
      "CHEMBL583979",
      "SCHEMBL1311702",
      "QJTVTVAQRCRXSM-KRWDZBQOSA-N",
    ],
  },
  {
    primary_id: "D0TC4R",
    names: ["Desmoteplase"],
  },
  {
    primary_id: "D0TC4Z",
    names: [
      "ALPHA-HYDROXYFARNESYLPHOSPHONIC ACID",
      "Alpha-Hydroxyfarnesylphosphonic Acid",
      "HFPA",
      "MONZTFSZTWQCKH-IJFRVEDASA-N",
      "140633-12-1",
      "148796-53-6",
      "Alpha-Hydroxyfarnesylphosphonic Aicd",
      "Alpha-Hydroxy Farnesyl Phosphonic Acid",
      "AC1NS45W",
      "C15H27O4P",
      "MolPort-003-941-743",
      "HMS3649I13",
      "1609AH",
      ".alpha.-Hydroxy Farnesyl Phosphonic Acid",
      "RT-011376",
      "C-28812",
      "SR-01000946574",
      "J-008515",
      "SR-01000946574-1",
      "L-704272",
      "1-Hydroxy-3,7,11-Trimethyl-2E,6E,10-Dodecatriene-1-Phosphonic Acid",
      "[1-Hydroxy-(E,E)-3,7,11-Trimethyl-2,6,10-Dodecatrienyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0TC5E",
    names: [
      "2-Methylhistamine",
      "56975-07-6",
      "Tetrahydro-5-(2-Hydroxyethyl)-1,3-Bis(Hydroxymethyl)-1,3,5-Triazine-2(1H)-Thione",
      "5-(2-Hydroxyethyl)-1,3-Bis(Hydroxymethyl)-1,3,5-Triazinane-2-Thione",
      "EINECS 260-494-0",
      "AC1L3OH4",
      "GTPL1268",
      "AC1Q7F21",
      "CTK5A6094",
      "DTXSID70205580",
      "1,3,5-Triazine-2(1H)-Thione,tetrahydro-5-(2-Hydroxyethyl)-1,3-Bis(Hydroxymethyl)-",
    ],
  },
  {
    primary_id: "D0TC5H",
    names: ["3-(6-Morpholino-9H-Purin-2-Yl)Phenol", "CHEMBL593515", "SCHEMBL4443377", "FUDQNOGEMXSUSQ-UHFFFAOYSA-N"],
  },
  {
    primary_id: "D0TC6X",
    names: ["Anti-CD30-CAR T Cells"],
  },
  {
    primary_id: "D0TC7C",
    names: ["Hidrosmin"],
  },
  {
    primary_id: "D0TC7N",
    names: ["PENECA", "2-Phenylethynyl-NECA", "2-PhenylethynylNECA"],
  },
  {
    primary_id: "D0TC7X",
    names: ["Isoquinoline Derivative 1"],
  },
  {
    primary_id: "D0TC8O",
    names: ["ISIS 116508"],
  },
  {
    primary_id: "D0TD0K",
    names: ["ISIS 116507"],
  },
  {
    primary_id: "D0TD0O",
    names: ["Anti-CD19 CAR-T"],
  },
  {
    primary_id: "D0TD1R",
    names: ["Lusutrombopag"],
  },
  {
    primary_id: "D0TD2F",
    names: ["ES-6864"],
  },
  {
    primary_id: "D0TD2G",
    names: ["Canakinumab"],
  },
  {
    primary_id: "D0TD6L",
    names: ["Pretarget Technology"],
  },
  {
    primary_id: "D0TD7J",
    names: [
      "ALKS 8700",
      "Diroximel Fumarate",
      "1577222-14-0",
      "2-(2,5-Dioxopyrrolidin-1-Yl)Ethyl Methyl Fumarate",
      "UNII-K0N0Z40J3W",
      "K0N0Z40J3W",
      "ALKS-8700",
      "Diroximel Fumarete",
      "Diroximel Fumarate [INN]",
      "ALKS8700",
      "SCHEMBL15499960",
      "CHEMBL3989944",
      "SCHEMBL15511916",
      "Diroximel Fumarate [USAN:INN]",
      "RDC5108",
      "MolPort-046-033-659",
      "Diroximel Fumarete(ALKS-8700)",
      "RDC-5108",
      "ZINC215286156",
      "AKOS027256112",
      "AS-55046",
      "TZ001258",
      "2-(2,5-Dioxopyrrolidin-1-Yl)Ethyl Methyl (2E)-But- 2-Enedioate",
      "2-Butenedioic Acid (2E)-, 1-(2-(2,5-Dioxo-1-Pyrrolidinyl)Ethyl) 4-Methyl Ester",
    ],
  },
  {
    primary_id: "D0TD8R",
    names: ["3-Phenyl Isoquinolin-1(2H) Derivative 1"],
  },
  {
    primary_id: "D0TE0L",
    names: ["4SCAR19 Cells"],
  },
  {
    primary_id: "D0TE2D",
    names: ["PMID29334795-Compound-25"],
  },
  {
    primary_id: "D0TE4S",
    names: ["PMID26666989-Compound-Figure11Ala Down"],
  },
  {
    primary_id: "D0TE5Q",
    names: ["Threo-1-Aza-5-Phenyl[4.4.0]decane Hydrochloride", "CHEMBL537206"],
  },
  {
    primary_id: "D0TE6A",
    names: ["1,5-Dihydro-Imidazo[2,1-B]quinazolin-2-One"],
  },
  {
    primary_id: "D0TE8W",
    names: ["NSD-801"],
  },
  {
    primary_id: "D0TE9B",
    names: [
      "D-Proline",
      "344-25-2",
      "H-D-Pro-OH",
      "(R)-Pyrrolidine-2-Carboxylic Acid",
      "(2R)-Pyrrolidine-2-Carboxylic Acid",
      "D-(+)-Proline",
      "D-Prolin",
      "R-Proline",
      "D-Pro",
      "H-Pros-OH",
      "H-DPro-OH",
      "CHEBI:16313",
      "(R)-2-CARBOXYPYRROLIDINE",
      "EINECS 206-452-7",
      "(R)-PROLINE",
      "MFCD00064317",
      "(2R)-2-Carboxypyrrolidine",
      "D-Pyrrolidine-2-Carboxylic Acid",
      "D-2-Pyrrolidinecarboxylic Acid",
      "SBB006712",
      "7005-20-1",
      "D(+)-Proline, 99+%",
      "(D)-Proline",
      "D(+)-Proline",
      "PubChem10979",
      "D-D-Pro-OH",
      "R)-2-Carboxypyrrolidine",
      "AC1Q5QVZ",
      "AC1Q71DR",
      "AC1L1S2V",
      "NCIStruc2_000132",
      "NCIStruc1_001918",
    ],
  },
  {
    primary_id: "D0TE9G",
    names: ["PF-4957325-00"],
  },
  {
    primary_id: "D0TF0T",
    names: ["Thiazolidine Dione Crystalline Derivative 1"],
  },
  {
    primary_id: "D0TF0V",
    names: [
      "ALDISIN",
      "72908-87-3",
      "Aldisin",
      "Aldisine",
      "6,7-Dihydropyrrolo[2,3-C]azepine-4,8(1H,5H)-Dione",
      "6,7-Dihydro-1H,5H-Pyrrolo[2,3-C]azepine-4,8-Dione",
      "CHEMBL357047",
      "AK134979",
      "Pyrrolo[2,3-C]azepine-4,8(1H,5H)-Dione, 6,7-Dihydro-",
      "Pyrrolo(2,3-C)Azepine-4,8(1H,5H)-Dione, 6,7-Dihydro-",
      "AC1MJ57G",
      "SCHEMBL7844916",
      "6,7-Dihydro-Pyrrolo[2,3-C]azepine-4,8(1H,5H)-Dione",
      "CTK5D7039",
      "KS-00000FCK",
      "DTXSID40223211",
      "Pyrrolo[2,3-C]azepine-4,8(1H,5H)-Dione,6,7-Dihydro-",
      "AAPGLCCSVSGLFH-UHFFFAOYSA-N",
      "MolPort-000-003-213",
      "ZINC13310235",
      "BDBM50108777",
      "FCH858612",
    ],
  },
  {
    primary_id: "D0TF1E",
    names: ["A-366833", "5-[(1R,5S)-3,6-Diazabicyclo[3.2.0]hept-6-Yl]pyridine-3-Carbonitrile"],
  },
  {
    primary_id: "D0TF2D",
    names: ["CDX-301 + CDX-1401"],
  },
  {
    primary_id: "D0TF2N",
    names: ["Isoxazole-Based Bicyclic Compound 16"],
  },
  {
    primary_id: "D0TF7R",
    names: ["(Sar)WTLNSAGYLLGPKKKK", "CHEMBL508036"],
  },
  {
    primary_id: "D0TF9P",
    names: ["Thieno Analogue Of Kenpaullone"],
  },
  {
    primary_id: "D0TF9R",
    names: ["2-(O-Toluidino)-5-Isopropylthiazol-4(5H)-One"],
  },
  {
    primary_id: "D0TG0I",
    names: [
      "1-(Phenylsulfonyl)-4-(Piperazin-1-Yl)-1H-Indole",
      "CHEMBL370779",
      "1-(Phenylsulfonyl)-4-(1-Piperazinyl)-1h-Indole",
      "412049-71-9",
      "1-Benzenesulfonyl-4-Piperazin-1-Yl-1H-Indole",
      "SCHEMBL1136504",
      "ZINC3210",
      "DTXSID90436238",
      "OPAMDWUUNKYGOR-UHFFFAOYSA-N",
      "BDBM50174269",
      "1-(Phenylsulfonyl)-4-Piperazin-1-Yl-Indole",
      "FT-0724295",
      "1-(Benzenesulfonyl)-4-(1-Piperazinyl)Indole",
      "1-(Phenylsulfonyl)-4-Piperzin-1-Yl-1H-Indole",
      "1-(Phenylsulfonyl)-4-Piperazin-1-Yl-1H-Indole",
      "1-(Phenylsulfonyl)-4-Piperazin-1-Y1-1H-Indole",
    ],
  },
  {
    primary_id: "D0TG1H",
    names: ["Diclofenac", "Diclofenac (Sodium Matrix Patch, Pain)"],
  },
  {
    primary_id: "D0TG4K",
    names: [
      "Oxycyte",
      "Blood Substitute, Oxygen Biotherapeutics",
      "Blood Substitute, Synthetic Blood International",
      "Oxygen Carrier, OxygenBiotherapeutics",
      "Oxygen Carrier, Synthetic Blood International",
    ],
  },
  {
    primary_id: "D0TG4S",
    names: ["ASN002"],
  },
  {
    primary_id: "D0TG7I",
    names: ["Erythromycin/Sulfisoxazole"],
  },
  {
    primary_id: "D0TG9Q",
    names: ["MK-0812", "C-6448", "MK-812", "CCR2 Antagonist (Rheumatoid Arthritis/Multiple Sclerosis), Merck"],
  },
  {
    primary_id: "D0TH1I",
    names: ["4-(6-Phenyl-2,4'-Bipyridin-4-Yl)Phenol"],
  },
  {
    primary_id: "D0TH1Z",
    names: [
      "Naxagolide",
      "Dopazinol",
      "Nazagolide",
      "PHNO",
      "Naxagolide Hydrochloride",
      "L-647339",
      "MK-458",
      "(+)-PHNO",
    ],
  },
  {
    primary_id: "D0TH2W",
    names: ["PMID25468267-Compound-49"],
  },
  {
    primary_id: "D0TH3L",
    names: ["PMID28092474-Compound-32q"],
  },
  {
    primary_id: "D0TH5H",
    names: [
      "Dapansutrile",
      "54863-37-5",
      "OLT1177",
      "UNII-2Z03364G96",
      "2Z03364G96",
      "Dapansutrile [INN]",
      "Dapansutrile [USAN:INN]",
      "Dapansutrile (USAN/INN)",
      "3-Methylsulfonylpropionitrile",
      "3-Methanesulfonylpropanenitrile",
      "3-Methylsulphonyl-Propionitrile",
      "SCHEMBL9934796",
      "CHEMBL3989943",
      "LQFRYKBDZNPJSW-UHFFFAOYSA-N",
      "MolPort-008-545-498",
      "ZINC32005410",
      "Propanenitrile, 3-(Methylsulfonyl)-",
      "AKOS010140086",
      "D10920",
      "Z2719319073",
    ],
  },
  {
    primary_id: "D0TH5W",
    names: ["PMID26004420-Compound-WO2013062900C"],
  },
  {
    primary_id: "D0TH6L",
    names: ["N-Desalkylquetiapine"],
  },
  {
    primary_id: "D0TH6M",
    names: ["PMID30259754-Compound-LY2562175"],
  },
  {
    primary_id: "D0TH6O",
    names: ["AR-132"],
  },
  {
    primary_id: "D0TH7L",
    names: ["5-Hexyl-2-(2-Nitrophenoxy)Phenol", "CHEMBL264415"],
  },
  {
    primary_id: "D0TH7S",
    names: ["2-(2-Chlorophenyl)-2H-Indazole-7-Carboxamide", "CHEMBL1094952", "SCHEMBL2265265"],
  },
  {
    primary_id: "D0TH7T",
    names: ["Bipyridine Derivative 1"],
  },
  {
    primary_id: "D0TH8D",
    names: [
      "8-Methoxy-2-Piperazin-1-Yl-Quinoline",
      "CHEMBL80691",
      "Quinoline, 8-Methoxy-2-(1-Piperazinyl)-",
      "104090-79-1",
      "8-Methoxyquipazine",
      "ACMC-20m6vj",
      "SCHEMBL6767019",
      "CTK0G6599",
      "DTXSID30627183",
      "CUULVNAZRYFNIT-UHFFFAOYSA-N",
      "ZINC26467647",
      "BDBM50025465",
      "AKOS022401904",
    ],
  },
  {
    primary_id: "D0TH8E",
    names: ["M3P6S", "CHEMBL213228"],
  },
  {
    primary_id: "D0TI0R",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 21"],
  },
  {
    primary_id: "D0TI3Q",
    names: ["1,2,4,5-Tetra-Substituted Imidazole Derivative 2"],
  },
  {
    primary_id: "D0TI3R",
    names: ["FLX-787"],
  },
  {
    primary_id: "D0TI4C",
    names: ["AK002"],
  },
  {
    primary_id: "D0TI4U",
    names: ["EGFR/IGFR Tandem Adnectin", "BMS-964210"],
  },
  {
    primary_id: "D0TJ0R",
    names: ["TA-CIN"],
  },
  {
    primary_id: "D0TJ2L",
    names: ["RO-147437"],
  },
  {
    primary_id: "D0TJ3M",
    names: [
      "NSC-281612",
      "Benzaldehyde Dimethane Sulfonate",
      "Dimethane Sulfonate",
      "Methylene Dimethane Sulfonate",
      "DMS-612",
    ],
  },
  {
    primary_id: "D0TJ3X",
    names: ["Nell-1 Protein Therapeutic"],
  },
  {
    primary_id: "D0TJ4D",
    names: ["PMID30107136-Compound-Example58"],
  },
  {
    primary_id: "D0TJ6Z",
    names: ["2-(1H-Imidazol-4-Ylmethyl)-4-Phenyl-Thiazole", "CHEMBL289060", "BDBM50014174"],
  },
  {
    primary_id: "D0TJ7R",
    names: [
      "1-Benzyl-4-Phenylpiperidin-4-Ol",
      "63843-83-4",
      "1-Benzyl-4-Phenyl-4-Piperidinol",
      "4-Piperidinol, 1-Benzyl-4-Phenyl-",
      "1-Benzyl-4-Phenyl-Piperidin-4-Ol",
      "EINECS 264-512-8",
      "1-Benzyl-4-Hydroxy-4-Phenylpiperidine",
      "1-Benzyl-4-Hydroxy-4-Phenyl Piperidine",
      "BRN 0235839",
      "CHEMBL230141",
      "F1446-0078",
      "NSC116051",
      "AC1L3YAO",
      "Oprea1_213406",
      "Oprea1_173104",
      "SCHEMBL1411499",
      "AC1Q7B56",
      "CTK5B9985",
      "DTXSID60213290",
      "MolPort-001-954-320",
      "FCLMXEAPEVBCKQ-UHFFFAOYSA-N",
      "ZINC320034",
      "HMS1672E06",
      "BCP13431",
      "ANW-45516",
      "BDBM50210409",
      "STK081775",
      "AKOS000541462",
    ],
  },
  {
    primary_id: "D0TJ7S",
    names: ["PDC-41"],
  },
  {
    primary_id: "D0TJ9I",
    names: ["HSB-13"],
  },
  {
    primary_id: "D0TJ9S",
    names: ["N-Methanocarbathymidine"],
  },
  {
    primary_id: "D0TK0L",
    names: ["PMID25991433-Compound-E2"],
  },
  {
    primary_id: "D0TK0W",
    names: ["Ro-46-5934"],
  },
  {
    primary_id: "D0TK2J",
    names: ["BN-AM-206"],
  },
  {
    primary_id: "D0TK2N",
    names: ["PMID25522065-Compound-45"],
  },
  {
    primary_id: "D0TK2O",
    names: ["PMID25726713-Compound-10"],
  },
  {
    primary_id: "D0TK3S",
    names: ["PMID25522065-Compound-43"],
  },
  {
    primary_id: "D0TK3W",
    names: ["Isoxazoles And Isoxazoline Derivative 3"],
  },
  {
    primary_id: "D0TK4X",
    names: ["PMID18800762C47", "Bisphosphonate, 47", "GTPL3197", "BDBM25300"],
  },
  {
    primary_id: "D0TK5G",
    names: ["Benzodiazepine Derivative 2"],
  },
  {
    primary_id: "D0TK5U",
    names: ["Aminopyrimidine Derivative 6"],
  },
  {
    primary_id: "D0TK7R",
    names: ["LGX818"],
  },
  {
    primary_id: "D0TL0V",
    names: ["3-Oximo-Olean-12-En-29-Oic Acid", "3-(Hydroxyimino)Oleana-12-Ene-29-Oic Acid"],
  },
  {
    primary_id: "D0TL1G",
    names: ["PMID29053063-Compound-15"],
  },
  {
    primary_id: "D0TL1L",
    names: ["Fendrix"],
  },
  {
    primary_id: "D0TL2C",
    names: ["Resorcinol Compound 18"],
  },
  {
    primary_id: "D0TL3N",
    names: ["5-(Biphenyl-4-Yl)-3-Methoxypentanoic Acid", "CHEMBL583771"],
  },
  {
    primary_id: "D0TL3S",
    names: ["3,4-Benzo-7-Acetonyloxy-8-Methoxycoumarin", "CHEMBL520061"],
  },
  {
    primary_id: "D0TL3X",
    names: ["Cyclo(1,10)EIYDPGDDIK", "CHEMBL499996"],
  },
  {
    primary_id: "D0TL4F",
    names: ["PMID29649907-Compound-23"],
  },
  {
    primary_id: "D0TL4O",
    names: [
      "7-METHYL-8-OXO-5-P-TOLYL-7,8-DIHYDRO-[1,7]NAPHTHYRIDINE-6-CARBOXYLIC ACID (S)-[(S)-1-(3,5-BIS-TRIFLUOROMETHYL-PHENYL)-ETHYL]-METHYL-AMIDE (ENANTIOMERIC MIX)",
      "CHEMBL336589",
      "BDBM50067516",
      "7-Methyl-8-Oxo-5-P-Tolyl-7,8-Dihydro-[1,7]naphthyridine-6-Carboxylic Acid (S)-[(S)-1-(3,5-Bis-Trifluoromethyl-Phenyl)-Ethyl]-Methyl-Amide",
      "7-Methyl-8-Oxo-5-P-Tolyl-7,8-Dihydro-[1,7]naphthyridine-6-Carboxylic Acid (R)-[(S)-1-(3,5-Bis-Trifluoromethyl-Phenyl)-Ethyl]-Methyl-Amide",
    ],
  },
  {
    primary_id: "D0TL6C",
    names: ["T22", "[Tyr5,12,Lys7]-Polyphemusin II"],
  },
  {
    primary_id: "D0TL7L",
    names: [
      "Dexloxiglumide",
      "119817-90-2",
      "(R)-4-(3,4-Dichlorobenzamido)-5-((3-Methoxypropyl)(Pentyl)Amino)-5-Oxopentanoic Acid",
      "UNII-69DY40RH9B",
      "CR-2017",
      "69DY40RH9B",
      "Dexloxiglumidum",
      "Dexloxiglumida",
      "Dexloxiglumide [INN]",
      "Dexloxiglumidum [INN-Latin]",
      "Dexloxiglumida [INN-Spanish]",
      "PubChem16049",
      "AC1Q3MXI",
      "GTPL889",
      "SCHEMBL366142",
      "CHEMBL550781",
      "AC1L24A8",
      "DTXSID50152604",
      "MolPort-006-170-001",
      "CHEBI:135747",
      "ZINC3801027",
      "KS-000007QQ",
      "AKOS000279054",
      "PB12713",
      "DB04856",
      "CC-26372",
      "AJ-45649",
      "AK129760",
      "AS-35102",
      "KB-210163",
      "AX8123879",
      "CS-0054881",
      "FT-0603098",
      "Q-4119",
    ],
  },
  {
    primary_id: "D0TM0C",
    names: ["LIMAZOCIC"],
  },
  {
    primary_id: "D0TM0N",
    names: ["UCM 765"],
  },
  {
    primary_id: "D0TM0Y",
    names: ["FT-1101"],
  },
  {
    primary_id: "D0TM0Z",
    names: ["1H-Indole-4,7-Dione"],
  },
  {
    primary_id: "D0TM1Z",
    names: ["2-[(2-Methoxy-5-Methylphenoxy)Methyl]pyridine", "SCHEMBL12409072", "DB07427"],
  },
  {
    primary_id: "D0TM4K",
    names: [
      "3-[3-Methyl-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 36a",
      "CHEMBL195688",
      "AC1O707D",
      "BDBM8646",
      "SCHEMBL13877113",
      "3-[(E)-(3-Methyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0TM4R",
    names: ["Piperazine Carbamate/Urea Derivative 6"],
  },
  {
    primary_id: "D0TN0A",
    names: ["1-Oxazolo[4,5-B]pyridin-2-Yl-6-Phenyl-Hexan-1-One"],
  },
  {
    primary_id: "D0TN0D",
    names: ["METAZOCINE"],
  },
  {
    primary_id: "D0TN0G",
    names: ["PMID30247903-Compound-General Structure46"],
  },
  {
    primary_id: "D0TN0O",
    names: ["PRTT-300", "Abuse Deterrent Opioid Combination, Pro-Tect Pharmaceuticals"],
  },
  {
    primary_id: "D0TN1F",
    names: [
      "4'-Trifluoromethyl-Biphenyl-4-Ylamine",
      "57688-34-3",
      "4'-(Trifluoromethyl)-[1,1'-Biphenyl]-4-Amine",
      "4'-Trifluoromethylbiphenyl-4-Ylamine",
      "4-[4-(Trifluoromethyl)Phenyl]aniline",
      "[1,1'-Biphenyl]-4-Amine, 4'-(Trifluoromethyl)-",
      "CHEMBL242142",
      "4'-(Trifluoromethyl)Biphenyl-4-Amine",
      "4'-(Trifluoromethyl)[1,1'-Biphenyl]-4-Amine",
      "[1,1'-Biphenyl]-4-Amine,4'-(Trifluoromethyl)-",
      "ACMC-1AWVA",
      "AC1MD2WS",
      "SCHEMBL2655246",
      "CTK5A7284",
      "DTXSID50382227",
      "PDKIAMYXBRKPBW-UHFFFAOYSA-N",
      "MolPort-000-159-036",
      "4-Amino-4'-Trifluoromethyldiphenyl",
      "ZINC2574122",
    ],
  },
  {
    primary_id: "D0TN1J",
    names: ["MENK"],
  },
  {
    primary_id: "D0TN1L",
    names: ["JWH-146"],
  },
  {
    primary_id: "D0TN1N",
    names: ["NY-ESO-TCR"],
  },
  {
    primary_id: "D0TN2R",
    names: [
      "3-Methoxymethyl-1,2,3,4-Tetrahydro-Isoquinoline",
      "CHEMBL294562",
      "Isoquinoline, 1,2,3,4-Tetrahydro-3-(Methoxymethyl)-",
      "245125-98-8",
      "3-(Methoxymethyl)-1,2,3,4-Tetrahydroisoquinoline",
      "SCHEMBL7585285",
    ],
  },
  {
    primary_id: "D0TN3D",
    names: ["AZD-7140"],
  },
  {
    primary_id: "D0TN3Q",
    names: ["LY3214996"],
  },
  {
    primary_id: "D0TN4F",
    names: ["PMID30247903-Compound-General Structure6"],
  },
  {
    primary_id: "D0TN4M",
    names: ["8H9 I-124 MAb"],
  },
  {
    primary_id: "D0TN4X",
    names: ["LY178002"],
  },
  {
    primary_id: "D0TN5W",
    names: ["HER2-CAR T Cells"],
  },
  {
    primary_id: "D0TN6E",
    names: ["PMID29671355-Compound-18"],
  },
  {
    primary_id: "D0TN6K",
    names: ["LSZ102"],
  },
  {
    primary_id: "D0TN7S",
    names: ["BAY-1075553"],
  },
  {
    primary_id: "D0TO0C",
    names: ["FPA150"],
  },
  {
    primary_id: "D0TO0E",
    names: ["SN-28", "CHEMBL552308", "SCHEMBL16248949"],
  },
  {
    primary_id: "D0TO0F",
    names: ["VLDM-01"],
  },
  {
    primary_id: "D0TO2R",
    names: ["Fludarabine Phosphate"],
  },
  {
    primary_id: "D0TO3O",
    names: ["PMID29671355-Compound-16"],
  },
  {
    primary_id: "D0TO4N",
    names: ["1-(Thiophen-2-Yl)-3-Aza-Bicyclo[3.1.0]hexane", "CHEMBL500739"],
  },
  {
    primary_id: "D0TO6S",
    names: [
      "Bisindolylmaleimide-I",
      "Bisindolylmaleimide I",
      "133052-90-1",
      "GF 109203X",
      "GF109203X",
      "Go 6850",
      "GF-109203X",
      "RBT205 INHIBITOR",
      "Go-6850",
      "UNII-L79H6N0V6C",
      "6850",
      "Bisindolylmaleimide I (GF 109203X)",
      "CHEMBL7463",
      "3-{1-[3-(DIMETHYLAMINO)PROPYL]-1H-INDOL-3-YL}-4-(1H-INDOL-3-YL)-1H-PYRROLE-2,5-DIONE",
      "3-(1-(3-(Dimethylamino)Propyl)-1H-Indol-3-Yl)-4-(1H-Indol-3-Yl)-1H-Pyrrole-2,5-Dione",
      "L79H6N0V6C",
      "QMGUOJYZJKLOLH-UHFFFAOYSA-N",
      "2-[1-(3-Dimethylaminopropyl)Indol-3-Yl]-3-(Indol-3-Yl)Maleimide",
      "GF-109203",
      "Go6850",
    ],
  },
  {
    primary_id: "D0TO9J",
    names: ["PMID26004420-Compound-US20140031349A"],
  },
  {
    primary_id: "D0TP0S",
    names: ["Isoflavone Derivative 3"],
  },
  {
    primary_id: "D0TP0X",
    names: ["Peptide Analog 63"],
  },
  {
    primary_id: "D0TP1H",
    names: [
      "2-(7-Phenylheptanoyl)Oxazole-4-Carboxylic Acid",
      "CHEMBL460317",
      "SCHEMBL2162839",
      "UJGLCAJZAMDFHN-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0TP1P",
    names: ["CPP -15", "CPP-15"],
  },
  {
    primary_id: "D0TP2W",
    names: ["Thymopentin"],
  },
  {
    primary_id: "D0TP5H",
    names: [
      "Esketamine",
      "(S)-Ketamine",
      "L-Ketamine",
      "(S)-(-)-Ketamine",
      "33643-46-8",
      "S-Ketamine",
      "UNII-50LFG02TXD",
      "50LFG02TXD",
      "(S)-2-(O-Chlorophenyl)-2-(Methylamino)Cyclohexanone",
      "CHEBI:60799",
      "Esketamine HCl",
      "(2S)-2-(2-Chlorophenyl)-2-(Methylamino)Cyclohexanone",
      "Cyclohexanone, 2-(2-Chlorophenyl)-2-(Methylamino)-, (2S)-",
      "6740-88-1 (Parent)",
      "Esketamine [USAN:INN:BAN]",
      "S-Ketamin",
      "S-(-)-Ketamine",
      "Esketamine (USAN/INN)",
      "AC1L4AD8",
      "DSSTox_CID_27787",
      "DSSTox_RID_82562",
      "DSSTox_GSID_47810",
      "SCHEMBL5512024",
      "GTPL9152",
      "CHEMBL395091",
      "DTXSID6047810",
      "YQEZ",
    ],
  },
  {
    primary_id: "D0TP7S",
    names: ["Quinazoline Derivative 7"],
  },
  {
    primary_id: "D0TP7T",
    names: ["SCY-641"],
  },
  {
    primary_id: "D0TP8R",
    names: ["Iobenguane Sulfate I-131"],
  },
  {
    primary_id: "D0TP8S",
    names: [
      "3-(3-(Carbamoyl)Benzamido)-3-Phenylpropanoic Acid",
      "CHEMBL220128",
      "BDBM50323318",
      "3-Phenyl-3-(3-(1,2,3,4-Tetrahydroisoquinolin-7-Ylcarbamoyl)Benzamido)Propanoic Acid",
    ],
  },
  {
    primary_id: "D0TP8T",
    names: ["PMID26651364-Compound-113"],
  },
  {
    primary_id: "D0TP9A",
    names: ["CB-30865"],
  },
  {
    primary_id: "D0TP9M",
    names: [
      "ACY-1215",
      "1316214-52-4",
      "Ricolinostat",
      "Rocilinostat (ACY-1215)",
      "2-(Diphenylamino)-N-(7-(Hydroxyamino)-7-Oxoheptyl)Pyrimidine-5-Carboxamide",
      "UNII-WKT909C62B",
      "ACY-63",
      "WKT909C62B",
      "2-(Diphenylamino)-N-[7-(Hydroxyamino)-7-Oxoheptyl]-5-Pyrimidinecarboxamide",
      "AK151416",
      "N-[7-(Hydroxyamino)-7-Oxoheptyl]-2-(N-Phenylanilino)Pyrimidine-5-Carboxamide",
      "2-(Diphenylamino)-N-[7-(Hydroxyamino)-7-Oxoheptyl]pyrimidine-5-Carboxamide",
      "Ricolinostat [USAN:INN]",
      "AH4",
      "Ricolinostat (USAN/INN)",
      "MLS006011181",
      "SCHEMBL574580",
      "GTPL7010",
    ],
  },
  {
    primary_id: "D0TP9X",
    names: ["852"],
  },
  {
    primary_id: "D0TQ1F",
    names: ["ISIS 116363"],
  },
  {
    primary_id: "D0TQ1G",
    names: ["Rimantadine"],
  },
  {
    primary_id: "D0TQ4Y",
    names: ["PF-06648671"],
  },
  {
    primary_id: "D0TQ8V",
    names: ["Tricyclic Indole Compound 4"],
  },
  {
    primary_id: "D0TQ9U",
    names: ["DLX-903/1008", "Anti-VEGF Antibody Fragment, Delenex", "Anti-VEGF Antibody, Delenex"],
  },
  {
    primary_id: "D0TR0R",
    names: [
      "9H-Carbazole-2-Carbaldehyde",
      "9H-CARBAZOLE-2-CARBALDEHYDE",
      "9H-Carbazole-2-Carboxaldehyde",
      "99585-18-9",
      "CHEMBL1172395",
      "SCHEMBL7963280",
      "CTK3I5755",
      "DTXSID90479100",
      "LCENSQOWDHIKAN-UHFFFAOYSA-N",
      "ZINC22003786",
      "BDBM50322588",
      "AKOS006329833",
      "DB-080615",
      "AB0108318",
    ],
  },
  {
    primary_id: "D0TR0U",
    names: ["Tyr-D-Phe-Gly-D-Trp-NMeNle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D0TR0Z",
    names: [
      "MOGUISTEINE",
      "BBR-2173",
      "Moguisteine",
      "Rac-2-[(2-Methoxyphenoxy)Methyl]-Beta-Oxo-3-Thiazolidinepropionic Acid Ethyl Ester",
      "Rac-3-[2-(Ethoxycarbonyl)Acetyl]-2-(2-Methoxyphenoxymethyl)Thiazolidine",
    ],
  },
  {
    primary_id: "D0TR3Y",
    names: ["Ovapuldencel-T"],
  },
  {
    primary_id: "D0TR4N",
    names: ["LVVAPWT", "CHEMBL241983"],
  },
  {
    primary_id: "D0TR4Y",
    names: ["LN-145"],
  },
  {
    primary_id: "D0TR4Z",
    names: ["Zindoxifene", "D-16726"],
  },
  {
    primary_id: "D0TR5T",
    names: ["Heterocycle-Containing Compound 1"],
  },
  {
    primary_id: "D0TR5X",
    names: ["Fluoxetine"],
  },
  {
    primary_id: "D0TR7N",
    names: ["PMID25399762-Compound-Table 7-Veratraldehyde"],
  },
  {
    primary_id: "D0TR9T",
    names: ["PMID27539678-Compound-17"],
  },
  {
    primary_id: "D0TS0R",
    names: [
      "CD19xCD3 DART",
      "CD19xTCR DART",
      "Bispecific Antibodies (DART, Cancer)",
      "Bispecific Antibodies (DART, Cancer), MacroGenics/ Pfizer",
    ],
  },
  {
    primary_id: "D0TS1Z",
    names: ["Floxuridine"],
  },
  {
    primary_id: "D0TS2B",
    names: ["CAR-T Cells Targeting CD117"],
  },
  {
    primary_id: "D0TS3S",
    names: ["Heterocyclic-Substituted 3-Alkyl Azetidine Derivative 3"],
  },
  {
    primary_id: "D0TS5I",
    names: ["JWH-245"],
  },
  {
    primary_id: "D0TS6S",
    names: ["ISIS 103622"],
  },
  {
    primary_id: "D0TS7G",
    names: [
      "4-(3-Cyclohexylureido)Butanoic Acid",
      "4-{[(Cyclohexylamino)Carbonyl]amino}butanoic Acid",
      "4-{[(CYCLOHEXYLAMINO)CARBONYL]AMINO}BUTANOIC ACID",
      "4-[(Cyclohexylcarbamoyl)Amino]butanoic Acid",
      "511552-46-8",
      "4-(3-Cyclohexyl-Ureido)-Butyric Acid",
      "CHEMBL219695",
      "4-([(Cyclohexylamino)Carbonyl]amino)Butanoic Acid",
      "4-(3-CYCLOHEXYLURIEDO)-BUTYRIC ACID",
      "NC4",
      "1zd3",
      "AC1O4WJY",
      "AC1Q75I7",
      "SCHEMBL1065092",
      "WSVFRGGLURJIMG-UHFFFAOYSA-N",
      "MolPort-004-290-136",
      "HMS3604L06",
      "ZINC8754389",
      "BDBM50192936",
      "AKOS000125413",
      "4-(3-Cyclohexylur-Eido)-Butyric Acid",
      "NE13821",
      "MCULE-1918218076",
      "DB08257",
    ],
  },
  {
    primary_id: "D0TS8E",
    names: ["ADX71149"],
  },
  {
    primary_id: "D0TS8S",
    names: [
      "1-Cyclopentylidenethiosemicarbazide",
      "Cyclopentanone Thiosemicarbazone",
      "7283-39-8",
      "Hydrazinecarbothioamide, 2-Cyclopentylidene-",
      "(Cyclopentylideneamino)Thiourea",
      "Cyclopentanone, Thiosemicarbazone",
      "2-Cyclopentylidenehydrazinecarbothioamide",
      "CHEMBL483262",
      "PKHFATUAFMXAKP-UHFFFAOYSA-N",
      "Amino[(Cyclopentylideneazamethyl)Amino]methane-1-Thione",
      "NSC8269",
      "AC1Q7EGW",
      "AC1LEPE5",
      "SCHEMBL4100479",
      "MolPort-000-248-728",
      "KS-000027YL",
      "NSC-8269",
      "STK391362",
      "BDBM50268200",
      "SBB041172",
      "ZINC17730060",
      "AKOS000269515",
      "MCULE-1053883982",
    ],
  },
  {
    primary_id: "D0TT0B",
    names: ["1,2,4-Triazole Derivative 2"],
  },
  {
    primary_id: "D0TT3K",
    names: ["NSC-645811"],
  },
  {
    primary_id: "D0TT4A",
    names: ["PMID29671355-Compound-53"],
  },
  {
    primary_id: "D0TT4H",
    names: ["E6005"],
  },
  {
    primary_id: "D0TT5I",
    names: ["FAS-20013", "ATP Synthase Inhibitor (Oral, Mycobacterium Tuberculosis Infection), FASgen"],
  },
  {
    primary_id: "D0TT5K",
    names: ["MDL-27531", "4-Methyl-5-(Methylsulfonyl)-3-Phenyl-1,2,4-Triazole"],
  },
  {
    primary_id: "D0TT5V",
    names: ["MM3B6S", "CHEMBL211374"],
  },
  {
    primary_id: "D0TT6L",
    names: ["SF0166"],
  },
  {
    primary_id: "D0TT6X",
    names: ["ISIS 109354"],
  },
  {
    primary_id: "D0TT7U",
    names: ["PMID29053063-Compound-7c"],
  },
  {
    primary_id: "D0TT9H",
    names: ["NS3763"],
  },
  {
    primary_id: "D0TU0F",
    names: ["LAP-001"],
  },
  {
    primary_id: "D0TU0X",
    names: ["PMID26394986-Compound-10"],
  },
  {
    primary_id: "D0TU1E",
    names: ["EB-101", "Beta Amyloid Vaccine (Alzheimer's Disease), Atlas Pharmaceuticals"],
  },
  {
    primary_id: "D0TU2Z",
    names: [
      "2-Tosylbenzene-1,4-Diol",
      "2-Tosylhydroquinone",
      "30958-16-8",
      "CHEMBL462375",
      "2-(4-Methylbenzenesulfonyl)Benzene-1,4-Diol",
      "2-[(4-Methylphenyl)Sulfonyl]benzene-1,4-Diol",
      "AC1LDN5E",
      "CBMicro_029952",
      "Cambridge Id 5869439",
      "Oprea1_104843",
      "Oprea1_717162",
      "SCHEMBL10489221",
      "ZINC36613",
      "MolPort-015-161-937",
      "JDVLOEPBUIGUKP-UHFFFAOYSA-N",
      "MolPort-000-279-703",
      "BDBM50245925",
      "STK739706",
      "AKOS000727738",
      "MCULE-7472387969",
      "BAS 00095777",
      "ST001815",
      "BIM-0030021.P001",
      "2-(Toluene-4-Sulfonyl)-Benzene-1,4-Diol",
      "2-(4-Methylphenyl)Sulfonylbenzene-1,4-Diol",
    ],
  },
  {
    primary_id: "D0TU3N",
    names: ["PMID27744724-Compound-18"],
  },
  {
    primary_id: "D0TU5R",
    names: ["PMID29865878-Compound-49"],
  },
  {
    primary_id: "D0TU5W",
    names: ["4-(4-Benzyl-Piperazin-1-Yl)-5-Chloro-1H-Indole", "CHEMBL311539"],
  },
  {
    primary_id: "D0TU6D",
    names: ["PMID29053063-Compound-11c"],
  },
  {
    primary_id: "D0TU7B",
    names: [
      "PQR309",
      "Bimiralisib",
      "1225037-39-7",
      "PI3K-IN-2",
      "PQR-309",
      "UNII-6Z3QHB00LB",
      "6Z3QHB00LB",
      "5-(4,6-Dimorpholino-1,3,5-Triazin-2-Yl)-4-(Trifluoromethyl)Pyridin-2-Amine",
      "5-[bis(Morpholin-4-Yl)-1,3,5-Triazin-2-Yl]-4-(Trifluoromethyl)Pyridin-2-Amine",
      "5-(4,6-Dimorpholin-4-Yl-1,3,5-Triazin-2-Yl)-4-(Trifluoromethyl)Pyridin-2-Amine",
      "Bimiralisib [INN]",
      "Bimiralisib [USAN]",
      "Bimiralisib [WHO-DD]",
      "NCB5",
      "SCHEMBL1309049",
      "GTPL8383",
      "Bimiralisib Free Base",
      "ADGGYDAFIHSYFI-UHFFFAOYSA-N",
      "EX-A2018",
      "BCP15887",
      "PQR-309(PI3K-IN-2)",
    ],
  },
  {
    primary_id: "D0TU7G",
    names: ["SUVN-1004028"],
  },
  {
    primary_id: "D0TU7I",
    names: [
      "(E)-8-(3-Chlorostyryl)-Caffeine",
      "8-[(E)-2-(3-Chlorophenyl)Ethenyl]-1,3,7-Trimethyl-3,7-Dihydro-1H-Purine-2,6-Dione",
      "8-(3-Chlorostyryl)Caffeine",
      "Csc Cpd",
      "8-CSC",
      "CSC",
      "148589-13-3",
      "CHEMBL26138",
      "CHEBI:53115",
      "8-(2-(3-Chlorophenyl)Methyl)-3,7-Dihydro-1,3,7-Trimethyl-1H-Purine-2,6-Dione",
      "1H-Purine-2,6-Dione, 8-(2-(3-Chlorophenyl)Methyl)-3,7-Dihydro-1,3,7-Trimethyl-",
      "Lopac0_000393",
      "8-(3-Chlorostyryl) Caffine",
      "MLS000860039",
      "SCHEMBL537055",
      "8-[2-(3-Chlorophenyl)Ethenyl]-1,3,7-Trimethylpurine-2,6-Dione",
      "AC1NS448",
      "GTPL5609",
      "Cid_5353365",
      "AOB5970",
      "DTXSID60872447",
    ],
  },
  {
    primary_id: "D0TU7V",
    names: ["MB-12066", "B-Lapachone (Obesity), Mazence", "Beta-Lapachone (Obesity), Mazence"],
  },
  {
    primary_id: "D0TU9W",
    names: ["BIIB054"],
  },
  {
    primary_id: "D0TV0C",
    names: ["Pasireotide"],
  },
  {
    primary_id: "D0TV0E",
    names: ["JNJ-64457107"],
  },
  {
    primary_id: "D0TV0V",
    names: ["(-)-Alpha-Thujone", "Alpha-Thujone"],
  },
  {
    primary_id: "D0TV1R",
    names: ["Flufenamate"],
  },
  {
    primary_id: "D0TV4N",
    names: ["T-226296"],
  },
  {
    primary_id: "D0TV4O",
    names: ["Aryl Methoxyacrylate Derivative 2"],
  },
  {
    primary_id: "D0TV6H",
    names: ["HuM-195-Ac-225"],
  },
  {
    primary_id: "D0TV6I",
    names: [
      "Safinamide",
      "133865-89-1",
      "Xadago",
      "(S)-2-((4-((3-Fluorobenzyl)Oxy)Benzyl)Amino)Propanamide",
      "Fce-26743",
      "UNII-90ENL74SIG",
      "(2S)-2-[[4-[(3-Fluorophenyl)Methoxy]phenyl]methylamino]propanamide",
      "EMD 1195686",
      "90ENL74SIG",
      "CHEMBL396778",
      "EMD-1195686",
      "AK-77847",
      "2(S)-(4-(3-Fluorobenzyloxy)Benzylamino)Propionamide",
      "CHEMBL48582",
      "Safinamide [USAN:INN]",
      "2-(4-(3-Fluorobenzyloxy)Benzylamino)Propionamide",
      "AC1L2ZLK",
      "Safinamide (USAN/INN)",
      "(S)-2-(((4-((3-Fluorophenyl)Methoxy)Phenyl)Methyl)Amino)Propanamide",
      "EC 603-772-2",
      "SCHEMBL69350",
      "Fce 26743",
      "ON 019190.Na",
    ],
  },
  {
    primary_id: "D0TV9B",
    names: ["PMID25776143-Compound-2"],
  },
  {
    primary_id: "D0TV9G",
    names: ["Amidopyrazole Derivative 4"],
  },
  {
    primary_id: "D0TV9K",
    names: ["Propiverine"],
  },
  {
    primary_id: "D0TV9X",
    names: ["Propan-2-One O-4-(Octyloxy)Phenylcarbamoyl Oxime", "CHEMBL597295"],
  },
  {
    primary_id: "D0TW0I",
    names: ["(E)-3-[2-(2-Methyl-4-Thiazolyl)Vinyl]pyridine", "CHEMBL382527", "SCHEMBL4148449", "SCHEMBL4148438"],
  },
  {
    primary_id: "D0TW1Q",
    names: ["DP-3005"],
  },
  {
    primary_id: "D0TW2J",
    names: ["CMX-020"],
  },
  {
    primary_id: "D0TW3L",
    names: [
      "CD20Bi AATC",
      "CD20Bi Activated T-Cells (Non-Hodgkin's Lymphoma), Wayne State University/Barbara Ann Karmanos Cancer Institute",
      "CD20Bi-Armed ATC (Non-Hodgkin's Lymphoma), Wayne State University/Barbara Ann Karmanos Cancer Institute",
      "Anti-CD3/Anti-CD20 Bispecific Antibody-Armed Activated T-Cells (Non-Hodgkin's Lymphoma)",
      "Anti-CD3/Anti-CD20 Bispecific Antibody-Armed Activated T-Cells (Non-Hodgkin's Lymphoma), Wayne State University/Barbara Ann Karmanos Cancer Institute",
    ],
  },
  {
    primary_id: "D0TW7K",
    names: ["PMID29053063-Compound-7e"],
  },
  {
    primary_id: "D0TW9N",
    names: ["2-((2-Ethoxyphenoxy)Methyl)-4-Isopropylmorpholine", "CHEMBL495543"],
  },
  {
    primary_id: "D0TX1S",
    names: ["PMID25656651-Compound-33a"],
  },
  {
    primary_id: "D0TX2G",
    names: ["Isoxazole Derivative 2"],
  },
  {
    primary_id: "D0TX5P",
    names: ["ITX-5061"],
  },
  {
    primary_id: "D0TX7T",
    names: ["Sulglycotide"],
  },
  {
    primary_id: "D0TX8L",
    names: [
      "QA1",
      "CHEMBL1210313",
      "GTPL5815",
      "SCHEMBL2407215",
      "BDBM50322839",
      "NCGC00189027-01",
      "NCGC00187765-01",
      "Rac-N-(3-Methyl-1-Morpholinopentan-3-Yl)-N-((1-Methyl-2-Oxo-1,2-Dihydroquinolin-3-Yl)Methyl)Cyclohexanecarboxamide",
      "N-(3-Methyl-1-Morpholinopentan-3-Yl)-N-((1-Methyl-2-Oxo-1,2-Dihydroquinolin-3-Yl)Methyl)Cyclohexanecarboxamide",
    ],
  },
  {
    primary_id: "D0TX8W",
    names: [
      "4-(Piperazin-1-Yl)-1H-Indole",
      "4-(1-Piperazinyl)-1H-Indole",
      "84807-09-0",
      "4-Piperazino-1H-Indole",
      "1H-Indole, 4-(1-Piperazinyl)-",
      "4-(Piperazino)Indole",
      "4-Piperazin-1-Yl-1H-Indole",
      "1-(4-Indolyl)Piperazine",
      "CHEMBL200234",
      "YZKSXUIOKWQABW-UHFFFAOYSA-N",
      "AK-26360",
      "W-206959",
      "1H-Indole-4-(1-Piperazinyl)Dihydrochloride",
      "4-Piperazinoindole",
      "4-Piperazino-Indole",
      "PubChem22668",
      "AC1LC5AN",
      "1H-Indol-4-Ylpiperazine",
      "4-(1-Piperazinyl)Indole",
      "1-(Indol-4yl)Piperazine",
      "1-(Indol-4yl)-Piperazine",
      "1-(Indol-4-Yl)Piperazine",
      "1-(4-Indolyl)-Piperazine",
    ],
  },
  {
    primary_id: "D0TX8Z",
    names: ["FLOSATIDIL"],
  },
  {
    primary_id: "D0TX9E",
    names: ["PMID25399762-Compound-Figure1-Aphanamixoid A"],
  },
  {
    primary_id: "D0TX9P",
    names: [
      "3-(1H-Pyrazol-3-Yl)Benzoic Acid",
      "3-(1H-Pyrazol-5-Yl)Benzoic Acid",
      "850375-11-0",
      "3-(2H-Pyrazol-3-Yl)Benzoic Acid",
      "Benzoic Acid, 3-(1H-Pyrazol-3-Yl)-",
      "CHEMBL497801",
      "5-(3-Carboxyphenyl)-1H-Pyrazole",
      "PubChem22748",
      "AC1MDTM0",
      "Buttpark 44\\01-87",
      "3-Pyrazol-5-Ylbenzoic Acid",
      "3-Pyrazol-3-Ylbenzoic Acid",
      "SCHEMBL939810",
      "CTK5F3854",
      "CTK7I8615",
      "DTXSID80383762",
      "MolPort-000-142-934",
      "RXZRBZWATRFHCS-UHFFFAOYSA-N",
      "MolPort-009-196-824",
      "3-(2h-Pyrazol-3-Yl)-Benzoicacid",
      "KS-00002A0D",
      "ZX-AT019147",
      "ZINC4200665",
      "3-(1H-Pyrazole-3-Yl)Benzoic Acid",
    ],
  },
  {
    primary_id: "D0TY0G",
    names: ["Mumps Skin Test Antigen"],
  },
  {
    primary_id: "D0TY0P",
    names: ["G-573"],
  },
  {
    primary_id: "D0TY1Q",
    names: ["Mannoside Derivative 9"],
  },
  {
    primary_id: "D0TY1U",
    names: ["TDI-0103"],
  },
  {
    primary_id: "D0TY1Y",
    names: ["PfSPZ-Cvac"],
  },
  {
    primary_id: "D0TY2O",
    names: ["ISIS 25075"],
  },
  {
    primary_id: "D0TY3L",
    names: ["KX-01 Ointment"],
  },
  {
    primary_id: "D0TY4L",
    names: ["PMID26666989-Compound-Figure9middle06"],
  },
  {
    primary_id: "D0TY5K",
    names: ["NWP-09"],
  },
  {
    primary_id: "D0TY5N",
    names: [
      "Cotinine",
      "(-)-Cotinine",
      "486-56-6",
      "Cotinina",
      "Cotininum",
      "(S)-(-)-Cotinine",
      "(5S)-1-Methyl-5-(Pyridin-3-Yl)Pyrrolidin-2-One",
      "Cotinine (-)",
      "(S)-Cotinine",
      "UNII-K5161X06LL",
      "(S)-1-Methyl-5-(3-Pyridinyl)-2-Pyrrolidinone",
      "2-Pyrrolidinone, 1-Methyl-5-(3-Pyridinyl)-, (5S)-",
      "CHEBI:68641",
      "(S)-1-Methyl-5-(3-Pyridyl)-2-Pyrrolidinone",
      "UIKROCXWUNQSPJ-VIFPVBQESA-N",
      "K5161X06LL",
      "Cotinine [INN]",
      "MFCD00077696",
      "Cotininum [INN-Latin]",
      "Cotinina [INN-Spanish]",
      "S(-)-1-Methyl-5-(3-Pyridyl)-2-Pyrrolidone",
      "(5S)-1-Methyl-5-Pyridin-3-Ylpyrrolidin-2-One",
    ],
  },
  {
    primary_id: "D0TY6U",
    names: ["BPX-601"],
  },
  {
    primary_id: "D0TY6Y",
    names: ["Avicine", "CTP-37"],
  },
  {
    primary_id: "D0TY8B",
    names: ["PGGA-PTX"],
  },
  {
    primary_id: "D0TY8S",
    names: ["PMID25522065-Compound-22"],
  },
  {
    primary_id: "D0TY8X",
    names: ["4-(2-Aminopyrimidin-4-Ylamino)Benzenesulfonamide"],
  },
  {
    primary_id: "D0TZ1C",
    names: ["Curemisinin", "Curemisinin (TN)"],
  },
  {
    primary_id: "D0TZ1G",
    names: [
      "Procaine",
      "Allocaine",
      "Anticort",
      "Anuject",
      "Duracaine",
      "Gerokit",
      "Gerovital",
      "Jenacain",
      "Jenacaine",
      "Nissocaine",
      "Norocaine",
      "Novocain",
      "Novocaine",
      "Procain",
      "Procaina",
      "Procainum",
      "Scurocaine",
      "Spinocaine",
      "Solution Of Novocain",
      "Factor H3",
      "SP01",
      "SP01A",
      "Stoff H3",
      "Vitamin H3",
      "Anticort (TM)",
      "Diethylaminoethyl P-Aminobenzoate",
      "Gerovital H-3",
      "Novocain (TN)",
      "P-Aminobenzoyldiethylaminoethanol",
      "P-Aminobenzyoyldiethylaminoethanol",
      "Procaina [INN-Spanish]",
      "Procaine (INN)",
      "Procaine [INN:BAN]",
      "Procaine, Base",
      "Procainum [INN-Latin]",
      "SP-01A",
      "Solution Of Novocain (TN)",
      "Beta-Diethylaminoethyl 4-Aminobenzoate",
      "P-Aminobenzoic Acid 2-Diethylaminoethyl Ester",
      "Beta-(Diethylamino)Ethyl 4-Aminobenzoate",
      "Beta-(Diethylamino)Ethyl P-Aminobenzoate",
      "BENZOIC ACID,4-AMINO,2-DIETHYLAMINOETHYL ESTER PROCAIN BASE",
      "Benzoic Acid, 4-Amino-, 2-(Diethylamino)Ethyl Ester",
      "Benzoic Acid, P-Amino-, 2-(Diethylamino)Ethyl Ester",
      "2-(Diethylamino)Ethyl 4-Aminobenzoate",
      "2-(Diethylamino)Ethyl P-Aminobenzoate",
      "2-(Diethylamino)Ethyl-4-Aminobenzoate",
      "2-Diethylaminoethyl 4-Aminobenzoate",
      "2-Diethylaminoethyl P-Aminobenzoate",
      "2-Diethylaminoethylester Kyseliny P-Aminobenzoove",
      "2-Diethylaminoethylester Kyseliny P-Aminobenzoove [Czech]",
      "4-Aminobenzoesaeure-Beta-Diethylaminoethylester",
      "4-Aminobenzoic Acid 2-(Diethylamino) Ethyl Ester",
      "4-Aminobenzoic Acid Diethylaminoethyl Ester",
    ],
  },
  {
    primary_id: "D0TZ1J",
    names: ["UDP-N-Acetylgalactosamine"],
  },
  {
    primary_id: "D0TZ1Y",
    names: ["Mycophenolic Acid/Nucleotide Derivative 7"],
  },
  {
    primary_id: "D0TZ2W",
    names: ["PMID25666693-Compound-38"],
  },
  {
    primary_id: "D0TZ4M",
    names: ["TRPA1 Antagonists"],
  },
  {
    primary_id: "D0TZ5E",
    names: ["Glycine Type-1 Transporter Inhibitors"],
  },
  {
    primary_id: "D0TZ5V",
    names: ["(3Z)-1H-Indole-2,3-Dione 3-Thiosemicarbazone"],
  },
  {
    primary_id: "D0TZ6J",
    names: ["PMID25522065-Compound-26"],
  },
  {
    primary_id: "D0TZ8C",
    names: ["6-(2-Imidazol-1-Yl-Vinyl)-1H-Quinolin-2-One", "CHEMBL347379", "SCHEMBL10461779"],
  },
  {
    primary_id: "D0TZ8J",
    names: ["Clade A HIV Vaccine"],
  },
  {
    primary_id: "D0U0AM",
    names: ["VGV-1"],
  },
  {
    primary_id: "D0U0AU",
    names: ["AZD-9819"],
  },
  {
    primary_id: "D0U0AY",
    names: [
      "AV-101",
      "Anti-Epileptic, VistaGen",
      "L-4-Chlorokynurenine",
      "NMDA Receptor Antagonists, University Of Maryland/VistaGen",
      "4-Cl-Kynurenine, NIH/VistaGen",
      "7-Chlorokynurenic Acid",
      "7-Cl-KYN",
      "7-Cl-KYNA, NIH",
    ],
  },
  {
    primary_id: "D0U0BA",
    names: ["AERAS-407 RBCG", "AERAS-422", "Modified BCG Vaccine, Aeras"],
  },
  {
    primary_id: "D0U0BN",
    names: ["JCAR014"],
  },
  {
    primary_id: "D0U0BP",
    names: ["DFP 13318"],
  },
  {
    primary_id: "D0U0BU",
    names: ["TOK-001"],
  },
  {
    primary_id: "D0U0BV",
    names: ["1,2-Bis(3,4-Difluorophenyl)-2-Hydroxyethanone", "CHEMBL394557"],
  },
  {
    primary_id: "D0U0CC",
    names: ["PMID29649907-Compound-11"],
  },
  {
    primary_id: "D0U0CF",
    names: ["CNTX-0290"],
  },
  {
    primary_id: "D0U0CV",
    names: [
      "Dodecane-1-Sulfonyl Fluoride",
      "Dodecanesulfonyl Fluoride",
      "CHEMBL97038",
      "Dodecyl Sulfonyl Fluoride",
      "1-(Fluorosulfonyl)Dodecane",
      "SCHEMBL1470419",
      "BDBM50132715",
    ],
  },
  {
    primary_id: "D0U0DD",
    names: ["Phleum Pratense-Containing Vaccine"],
  },
  {
    primary_id: "D0U0EC",
    names: ["TRemelimumab + MEDI0562"],
  },
  {
    primary_id: "D0U0EM",
    names: ["Reformulated Raltegravir"],
  },
  {
    primary_id: "D0U0EX",
    names: ["CT-5357"],
  },
  {
    primary_id: "D0U0EZ",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Ser-D-Pro-)", "CHEMBL393961"],
  },
  {
    primary_id: "D0U0FH",
    names: ["Amitivir"],
  },
  {
    primary_id: "D0U0GC",
    names: ["ZP-012"],
  },
  {
    primary_id: "D0U0GE",
    names: [
      "4-(2,4-Dimethyl-Thiazol-5-Yl)-Pyrimidin-2-Ylamine",
      "364334-94-1",
      "4-(2,4-Dimethylthiazol-5-Yl)Pyrimidin-2-Amine",
      "4-(2,4-DIMETHYL-1,3-THIAZOL-5-YL)PYRIMIDIN-2-AMINE",
      "4-(2,4-DIMETHYL-THIAZOL-5-YL)-PYRIMIDIN-2-YLAMINE",
      "CTFDMGIBHFQWKB-UHFFFAOYSA-N",
      "4-(2,4-Dimethylthiazol-5-Yl)Pyrimidin-2-Ylamine",
      "CK2",
      "4-(Dimethyl-1,3-Thiazol-5-Yl)Pyrimidin-2-Amine",
      "1pxj",
      "2c5o",
      "AC1L9LFI",
      "Maybridge3_001247",
      "CHEMBL47302",
      "CS12",
      "SCHEMBL4314069",
      "BDBM8037",
      "CTK4H6460",
      "DTXSID70332264",
      "MolPort-002-896-655",
      "HMS1434I15",
      "ZINC141286",
      "HMS3604E21",
      "ANW-58550",
      "4623AB",
      "AKOS013063215",
      "MCULE-3137548628",
      "DB02091",
      "CCG-243780",
    ],
  },
  {
    primary_id: "D0U0GY",
    names: ["1-(4-Heptylphenyl)Prop-2-En-1-One", "CHEMBL237035"],
  },
  {
    primary_id: "D0U0GZ",
    names: ["Filibuvir"],
  },
  {
    primary_id: "D0U0HO",
    names: ["Edetic"],
  },
  {
    primary_id: "D0U0HX",
    names: ["Molgramostim"],
  },
  {
    primary_id: "D0U0IH",
    names: ["PMID27599163-Compound-52"],
  },
  {
    primary_id: "D0U0IS",
    names: ["NP-02"],
  },
  {
    primary_id: "D0U0JL",
    names: ["Neo-1507", "Analgesic (Orthopedic Pain), Neorphys"],
  },
  {
    primary_id: "D0U0KJ",
    names: ["MRS-2160"],
  },
  {
    primary_id: "D0U0KR",
    names: [
      "CF102",
      "2-Cl-IB-Meca",
      "163042-96-4",
      "Cl-IB-MECA",
      "Namodenoson",
      "C-Ibza-MU",
      "CF-102",
      "CI-IB-MECA",
      "(2S,3S,4R,5R)-5-(2-Chloro-6-((3-Iodobenzyl)Amino)-9H-Purin-9-Yl)-3,4-Dihydroxy-N-Methyltetrahydrofuran-2-Carboxamide",
      "UNII-Z07JR07J6C",
      "2Cl-IB-MECA",
      "1-[2-CHLORO-6-[[(3-IODOPHENYL)METHYL]AMINO]-9H-PURIN-9-YL]-1-DEOXY-N-METHYL-BETA-D-RIBOFURANURONAMIDE",
      "CHEMBL431733",
      "Z07JR07J6C",
      "2-Chloro-N(6)-(3-Iodobenzyl)Adenosine-5'-N-Methyluronamide",
    ],
  },
  {
    primary_id: "D0U0KS",
    names: ["Minaxolone"],
  },
  {
    primary_id: "D0U0KW",
    names: ["Ramelteon"],
  },
  {
    primary_id: "D0U0KY",
    names: [
      "8-(2,6-Dichloro-Phenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL318601",
      "8-(2,6-Dichlorophenyl)-9H-Purine-2,6-Diamine",
      "8-(2,6-Dichlorophenyl)-7H-Purine-2,6-Diamine",
      "AC1LAR9F",
      "CTK7E1079",
      "BDBM50059926",
    ],
  },
  {
    primary_id: "D0U0LG",
    names: [
      "Bicyclolide Antibiotics",
      "Bicyclolide Antibiotics (Respiratory Tract Infection)",
      "EP-001304",
      "EP-01304",
      "EP-013159",
      "EP-013529",
      "EP-014413",
      "EP-014598",
      "EP-014831",
      "EP-014887",
      "EP-015024",
      "EP-015037",
      "EP-017796",
      "EP-1112",
      "EP-12344",
      "EP-12355",
      "EP-12371",
      "EP-12996",
      "EP-13015",
      "EP-1304",
      "EP-13043",
      "EP-13065",
      "EP-13097",
      "EP-13118",
      "EP-13159",
      "EP-13366",
      "EP-13417",
      "EP-13543",
      "EP-13645",
      "EP-13958",
      "EP-13994",
      "EP-14007",
      "EP-14008",
      "EP-14124",
      "EP-14389",
      "EP-14395",
      "EP-14401",
      "EP-14413",
      "EP-14414",
      "EP-14424",
      "EP-14452",
      "EP-14524",
      "EP-14541",
      "EP-1475;EP-14823",
      "EP-1562",
      "EP-263",
      "EP-935",
      "SuperMac-1",
      "Bicyclic Macrolide Antibiotics (Respiratory Tract Infection), Enanta",
      "Bicyclolide Antibiotics (Respiratory Tract Infection), Enanta",
      "Bicyclolides (Respiratory Tract Infection), Enanta",
      "Bridged Bicyclic Ketolides (Respiratory Tract Infections), Enanta",
      "Bridged Bicyclic Macrolides (Respiratory Tract Infections), Enanta",
    ],
  },
  {
    primary_id: "D0U0LH",
    names: ["18-Dimethylaminocoronaridine"],
  },
  {
    primary_id: "D0U0LL",
    names: ["PT-316"],
  },
  {
    primary_id: "D0U0MZ",
    names: ["Lactam Derivative 2"],
  },
  {
    primary_id: "D0U0NS",
    names: ["Myeloma Cancer Vaccine"],
  },
  {
    primary_id: "D0U0NT",
    names: ["ER-119884"],
  },
  {
    primary_id: "D0U0OE",
    names: ["ISIS 188755"],
  },
  {
    primary_id: "D0U0OF",
    names: ["TPI-273"],
  },
  {
    primary_id: "D0U0OL",
    names: ["Alzheimer's Disease Therapy, Reyon"],
  },
  {
    primary_id: "D0U0OT",
    names: ["Melevodopa", "Chf-1301", "Levomet (TN)", "L-Dopa-Methyester"],
  },
  {
    primary_id: "D0U0PV",
    names: ["M-40401"],
  },
  {
    primary_id: "D0U0QC",
    names: ["ABBV-2737"],
  },
  {
    primary_id: "D0U0QI",
    names: ["GYKI-16084"],
  },
  {
    primary_id: "D0U0QQ",
    names: ["Tetra-Substituted Urea Derivative 1"],
  },
  {
    primary_id: "D0U0QW",
    names: ["2-(Benzylideneamino)Ethanesulfonamide", "CHEMBL574337"],
  },
  {
    primary_id: "D0U0RJ",
    names: ["Leukocyte Interleukin"],
  },
  {
    primary_id: "D0U0RX",
    names: ["MK-7162"],
  },
  {
    primary_id: "D0U0RZ",
    names: [
      "Phentermine",
      "Duromine",
      "Fastin",
      "Fentermina",
      "Inoamin",
      "Ionamin",
      "Ionamine",
      "Linyl",
      "Lipopill",
      "Lonamin",
      "Mirapront",
      "Normephentermine",
      "Obenix",
      "Obermine",
      "Omnibex",
      "Ortetamin",
      "Ortetamina",
      "Ortetaminum",
      "Phentercot",
      "Phenterminum",
      "Phentride",
      "Phentrol",
      "Wilpo",
      "Zantryl",
      "Ona Mast",
      "Ortetamine [INN]",
      "Phentermine HCl",
      "Phentermine Resin Complex",
      "AmbkkkkK744",
      "Phentermine Resin 30",
      "Phentrol 2",
      "Phentrol 3",
      "Phentrol 4",
      "Adipex P (TN)",
      "Adipex-P",
      "Alpha-Benzylisopropylamine",
      "Duromine (TN)",
      "Fastin (TN)",
      "Fentermina [INN-Spanish]",
      "Ionamin (TN)",
      "Mirapront (TN)",
      "Obenix (TN)",
      "Obephen (TN)",
      "Obermine (TN)",
      "Oby-Trim",
      "Panbesy (TN)",
      "Phenterex (TN)",
      "Phentermine (TN)",
      "Phenterminum [INN-Latin]",
      "Phentremene (TN)",
      "Phentrol (TN)",
      "Phentromin (TN)",
      "Pro-Fast",
      "Redusa (TN)",
      "Sinpet (TN)",
      "Supremin (TN)",
      "Teramine (TN)",
      "Trenker (TN)",
      "Umine (TN)",
      "Weltmine (TN)",
      "Zantryl (TN)",
      "Alpha,alpha-Dimethylbenzeneethanamine",
      "Alpha,alpha-Dimethylphenethylamine",
      "Anoxine-AM (TN)",
      "Obestin-30 (TN)",
      "Oby-Trim (TN)",
      "Phentermine (USAN/INN)",
      "Phentermine [USAN:INN:BAN]",
      "Phenyl-Tert-Butylamine",
      "Phenyl-Tertiary-Butylamine",
      "Pro-Fast SA (TN)",
      "Alpha,alpha-Dimethyl-Beta-Phenylethylamine",
      "Alpha.,.alpha.-Dimethyl-Benzeneethanamine",
      "(Alpha,alpha)-Dimethylphenethylamine",
      "1,1-Dimethyl-2-Phenylethylamine",
      "1-(2-Methylphenyl)-2-Propylamin",
      "1-(2-Tolyl)-2-Propylamine",
      "2-Amino-2-Methyl-1-Phenylpropane",
      "2-Methyl-1-Phenyl-2-Propanamine",
      "2-Phenyl-Tert-Butylamine",
    ],
  },
  {
    primary_id: "D0U0SD",
    names: ["RG-125", "AZD4076"],
  },
  {
    primary_id: "D0U0SK",
    names: ["GZ402668"],
  },
  {
    primary_id: "D0U0ST",
    names: ["PD159790"],
  },
  {
    primary_id: "D0U0TD",
    names: ["Gedocarnil"],
  },
  {
    primary_id: "D0U0TS",
    names: ["TCS-OX2-29", "Compound 29 [PMID: 14643355]", "TCS OX2 29", "KB-11822"],
  },
  {
    primary_id: "D0U0UB",
    names: [
      "2-Methyl-4-O-Tolylethynyl-Thiazole",
      "CHEMBL181915",
      "Thiazole, 2-Methyl-4-[2-(2-Methylphenyl)Ethynyl]-",
      "BDBM50149797",
    ],
  },
  {
    primary_id: "D0U0VF",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp27-]", "CHEMBL272436"],
  },
  {
    primary_id: "D0U0VH",
    names: ["U92016A"],
  },
  {
    primary_id: "D0U0VO",
    names: ["N-[Tosyl-D-Prolinyl]Amino-Ethanethiol"],
  },
  {
    primary_id: "D0U0VU",
    names: ["SILODOSIN"],
  },
  {
    primary_id: "D0U0VY",
    names: ["CNTO-2476"],
  },
  {
    primary_id: "D0U0WU",
    names: ["13-(3-Pentyluredo)Tridec-8(Z)-Enoic Acid", "CHEMBL558097", "SCHEMBL13193158"],
  },
  {
    primary_id: "D0U0XD",
    names: ["Estradiol Cypionate", "Depo-Estradiol"],
  },
  {
    primary_id: "D0U0XG",
    names: [
      "N-(Tert-Butoxycarbonyl)-Methionyl-Glycine-Nitrile",
      "Dipeptide-Derived Nitrile, 12",
      "CHEMBL381847",
      "BDBM20091",
      "Tert-Butyl N-[(1S)-1-[(Cyanomethyl)Carbamoyl]-3-(Methylsulfanyl)Propyl]carbamate",
    ],
  },
  {
    primary_id: "D0U0XL",
    names: ["NN-7415"],
  },
  {
    primary_id: "D0U0YE",
    names: [
      "2-(2-(2,6-Dimethylphenylamino)Phenyl)Acetic Acid",
      "CHEMBL76154",
      "SCHEMBL6239733",
      "CTK8H7205",
      "ICJTVRXTYOYEOT-UHFFFAOYSA-N",
      "BDBM50375601",
      "ZINC26399260",
      "2-(2,6-Dimethylanilino)Benzeneacetic Acid",
      "2-[(2,6-Dimethylphenyl]amino]phenylacetic Acid",
      "2-[(2,6-Dimethylphenyl] Amino] Phenylacetic Acid",
    ],
  },
  {
    primary_id: "D0U0YR",
    names: ["SNAP-7941"],
  },
  {
    primary_id: "D0U0ZE",
    names: ["3-Hydroxy-8,10-Dimethyl-6H-Benzo[c]chromen-6-One", "CHEMBL380720"],
  },
  {
    primary_id: "D0U0ZN",
    names: ["PMID26004420-Compound-US20140275020D"],
  },
  {
    primary_id: "D0U1AN",
    names: [
      "4-Isothiocyanatobenzenesulfonamide",
      "51908-29-3",
      "4-Isothiocyanatobenzene-1-Sulfonamide",
      "4-Isothiocyanato-Benzenesulfonamide",
      "Benzenesulfonamide, 4-Isothiocyanato-",
      "CHEMBL144818",
      "IMDUFDNFSJWYQT-UHFFFAOYSA-N",
      "4-Isothiocyanatobenzene Sulfonamide",
      "4-Icsa",
      "AC1LAVHK",
      "SCHEMBL1476527",
      "CTK4J5100",
      "DTXSID90199871",
      "MolPort-000-156-692",
      "KS-00000HK9",
      "ZINC2571868",
      "ALBB-003064",
      "4-(Isothiocyanato)Benzenesulfonamide",
      "Benzenesulfonamide,4-Isothiocyanato-",
      "GEO-03379",
      "BDBM50094812",
      "ANW-51072",
      "STK501629",
      "4-(Aminosulfonyl)Phenylisothiocyanate",
      "SBB046978",
    ],
  },
  {
    primary_id: "D0U1BA",
    names: ["Ac-Cys-Ile-Phe(4-NO2)-Lys-Tyr-Phe(4-NO2)"],
  },
  {
    primary_id: "D0U1BR",
    names: ["Herpes Simplex DNA Vaccine"],
  },
  {
    primary_id: "D0U1BU",
    names: [
      "HPP-851",
      "11-Beta HSD-1 (Metabolic Disorders), High Point",
      "11-Beta HSD-1 (Metabolic Disorders), TransTech Pharma",
      "11-Beta Hydroxysteroid Dehydrogenase-1 Inhibitors (Metabolic Disorders), Novo Nordisk",
      "11-Beta Hydroxysteroid Dehydrogenase-1 Inhibitors (Metabolic Disorders), TransTech Pharma",
    ],
  },
  {
    primary_id: "D0U1BW",
    names: ["SIVIG"],
  },
  {
    primary_id: "D0U1CL",
    names: [
      "PGX-200097",
      "COMT/Dopamine D2 Receptor Inhibitors (Schizophrenia), Psychiatric Genomics",
      "Catechol-O-Methyltransferase/Dopamine D2 Receptor Inhibitors (Schizophrenia), Psychiatric Genomics",
    ],
  },
  {
    primary_id: "D0U1CN",
    names: ["LY2365109"],
  },
  {
    primary_id: "D0U1DD",
    names: ["KiroVAX-003"],
  },
  {
    primary_id: "D0U1DR",
    names: ["VUF 4904"],
  },
  {
    primary_id: "D0U1DU",
    names: ["NVX-207"],
  },
  {
    primary_id: "D0U1DY",
    names: ["Benzenamine Derivative 1"],
  },
  {
    primary_id: "D0U1EV",
    names: [
      "N5-[4-(Phenylmethoxy)Phenyl]-D-Glutamine",
      "Amino Acid Analog, 4g",
      "CHEMBL478534",
      "BDBM24257",
      "(2R)-2-Amino-4-{[4-(Benzyloxy)Phenyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D0U1FR",
    names: ["PBI-1393", "BCH-1393"],
  },
  {
    primary_id: "D0U1FZ",
    names: ["EUK-207"],
  },
  {
    primary_id: "D0U1GM",
    names: ["NexVas VI"],
  },
  {
    primary_id: "D0U1GP",
    names: ["CD56 CAR T Cells"],
  },
  {
    primary_id: "D0U1HA",
    names: ["PMID27774824-Compound-Figure3Example18"],
  },
  {
    primary_id: "D0U1HE",
    names: ["PEGylated PYY-3-36"],
  },
  {
    primary_id: "D0U1HN",
    names: [
      "AM-211",
      "UNII-SZB129M7SZ",
      "1175526-27-8",
      "SZB129M7SZ",
      "CHEMBL2181753",
      "AM211",
      "[1,1'-Biphenyl]-3-Acetic Acid, 2'-[[ethyl[[(Phenylmethyl)Amino]carbonyl]amino]methyl]-6-Methoxy-4'-(Trifluoromethyl)-",
      "SCHEMBL138450",
      "DTXSID80151807",
      "OPXIRFWNLBDKQB-UHFFFAOYSA-N",
      "BDBM50397640",
      "SB17010",
      "HY-13213",
      "CS-0003178",
      "[2'-(3-Benzyl-1-Ethyl-Ureidomethyl)-6-Methoxy-4'-Trifluoromethyl-Biphenyl-3-Yl]-Acetic Acid",
    ],
  },
  {
    primary_id: "D0U1HP",
    names: ["Org-24461"],
  },
  {
    primary_id: "D0U1HR",
    names: ["Donor-Derived CD19/22 Bispecific CAR-T Cells"],
  },
  {
    primary_id: "D0U1HX",
    names: ["DIMS-0150"],
  },
  {
    primary_id: "D0U1IJ",
    names: ["6,7'-Oxybis(2-Phenyl-4H-Chromen-4-One)", "CHEMBL466800", "BDBM50254285"],
  },
  {
    primary_id: "D0U1IR",
    names: ["AP-5346"],
  },
  {
    primary_id: "D0U1KE",
    names: ["FF-10502-01"],
  },
  {
    primary_id: "D0U1LQ",
    names: ["Rotavirus Vaccine"],
  },
  {
    primary_id: "D0U1MF",
    names: ["3,4-Dihydroxybenzaldehyde-O-Ethyloxime"],
  },
  {
    primary_id: "D0U1MN",
    names: [
      "1-Ethyl-4-(2-Methoxy-Phenyl)-Piperazine",
      "CHEMBL91689",
      "1-Ethyl-4-(2-Methoxyphenyl)Piperazine",
      "SCHEMBL4617829",
      "ZINC13738285",
      "BDBM50039788",
    ],
  },
  {
    primary_id: "D0U1MO",
    names: ["CERE-120"],
  },
  {
    primary_id: "D0U1NA",
    names: ["Fadolmidine"],
  },
  {
    primary_id: "D0U1OE",
    names: [
      "Thioridazine",
      "Aldazine",
      "Mallorol",
      "Malloryl",
      "Meleril",
      "Mellaril",
      "Mellarit",
      "Mellerets",
      "Mellerette",
      "Melleretten",
      "Melleril",
      "Melleryl",
      "Metlaril",
      "Novoridazine",
      "Orsanil",
      "Ridazine",
      "Sonapax",
      "Stalleril",
      "Thioridazin",
      "Thioridazinum",
      "Thioxidazine",
      "Tioridazin",
      "Tioridazina",
      "Thioridazine Hcl",
      "Thioridazine Hcl Intensol",
      "Thioridazine Prolongatum",
      "Thoridazine Hydrochloride",
      "Dl-Thioridazine",
      "Mellaril (TN)",
      "Mellaril-S",
      "Melleril (Liquid)",
      "Novoridazine (TN)",
      "TP-21",
      "Thioridazine, Prolongatum",
      "Thioridazinum [INN-Latin]",
      "Thioril (TN)",
      "Tioridazina [INN-Spanish]",
      "Mellaril-S (TN)",
      "Thioridazine (USP/INN)",
      "Thioridazine [USAN:INN:BAN]",
      "Mellaril (*Hydrochloride*)",
      "(+-)-Thioridazine",
      "10-(2-(1-Methyl-2-Piperidyl)Ethyl)-2-(Methylthio)Phenothiazine",
      "10-[2-(1-Methyl-2-Piperidyl)Ethyl]-2-Methylsulfanyl-Phenothiazine",
      "10-[2-(1-Methylpiperidin-2-Yl)Ethyl]-2-(Methylsulfanyl)-10H-Phenothiazine",
      "10-[2-(1-Methylpiperidin-2-Yl)Ethyl]-2-(Methylthio)-10H-Phenothiazine",
      "10-[2-(1-Methylpiperidin-2-Yl)Ethyl]-2-Methylsulfanylphenothiazine",
      "2-Methylmercapto-10-(2-(N-Methyl-2-Piperidyl)Ethyl)Phenothiazine",
      "3-Methylmercapto-N-(2'-(N-Methyl-2-Piperidyl)Ethyl)Phenothiazine",
    ],
  },
  {
    primary_id: "D0U1OJ",
    names: ["2MeSATP", "2-Methylthio-ATP", "2-MeSATP", "2-Methylthio-Adenosine-5'-Triphosphate"],
  },
  {
    primary_id: "D0U1OM",
    names: [
      "Bromfenac",
      "Bromfenaco",
      "Bromfenacum",
      "Duract",
      "Xibrom",
      "BROMFENAC SODIUM",
      "Bromfenac [INN]",
      "Bromfenaco [Spanish]",
      "Bromfenacum [Latin]",
      "AHR-10282",
      "Bromfenac (INN)",
      "Duract (TN)",
      "Xibrom (TN)",
      "Sodium 2-Amino-3-(4-Bromobenzoyl) Phenylacetate Sesquihydrate",
      "[2-Amino-3-(4-Bromobenzoyl)Phenyl]acetic Acid",
      "{2-Amino-3-[(4-Bromophenyl)Carbonyl]phenyl}acetic Acid",
      "[2-Amino-3-(4-Bromo-Benzoyl)-Phenyl]-Acetic Acid",
      "2-Amino-3-(4-Bromobenzoyl)Benzeneacetic Acid",
      "2-[2-Amino-3-(4-Bromobenzoyl)Phenyl]acetic Acid",
    ],
  },
  {
    primary_id: "D0U1OO",
    names: [
      "ARM210",
      "Arm-210",
      "UNII-1033GN605L",
      "1033GN605L",
      "Rycal Dmd",
      "SCHEMBL15326996",
      "ARM-210",
      "JIGDAUOKKYKRKO-UHFFFAOYSA-N",
      "S48168",
      "S-48168",
      "4-((7-Methoxy-2,3-Dihydro-1,4-Benzothiazepin-4(5H)-Yl)Methyl)Benzoic Acid",
      "Benzoic Acid, 4-((2,3-Dihydro-7-Methoxy-1,4-Benzothiazepin-4(5H)-Yl)Methyl)-",
      "4-((7-Methoxy-2,3-Dihydrobenzo[f][1,4]thiazepin-4(5H)-Yl)Methyl)Benzoic Acid",
      "1467605-57-7",
    ],
  },
  {
    primary_id: "D0U1OX",
    names: ["8-(3,3-Dimethyl-Butoxy)-Quinolin-2-Ylamine", "CHEMBL364104", "SCHEMBL5884452"],
  },
  {
    primary_id: "D0U1PE",
    names: [
      "6-Isopropyl-2-Phenyl-Chromen-4-One",
      "288401-05-8",
      "6-ISO-PROPYLFLAVONE",
      "6-Isopropylflavone",
      "CHEMBL133949",
      "CTK4G2222",
      "DTXSID70658395",
      "ZINC2573996",
      "AKOS030553653",
      "2-Phenyl-6-(Propan-2-Yl)-4H-1-Benzopyran-4-One",
    ],
  },
  {
    primary_id: "D0U1QE",
    names: ["VR736"],
  },
  {
    primary_id: "D0U1QU",
    names: ["ISIS 29156"],
  },
  {
    primary_id: "D0U1SA",
    names: ["KTP-001"],
  },
  {
    primary_id: "D0U1SE",
    names: ["Hexyl-Trimethyl-Ammonium"],
  },
  {
    primary_id: "D0U1SF",
    names: ["BMY 45778", "BMY45778", "BMY-45778"],
  },
  {
    primary_id: "D0U1SI",
    names: ["Bimoclomol", "ABT-822", "BRLP-42", "BRX-211"],
  },
  {
    primary_id: "D0U1TA",
    names: ["RHuEPO"],
  },
  {
    primary_id: "D0U1TJ",
    names: ["NKTR-194"],
  },
  {
    primary_id: "D0U1UN",
    names: ["DWP-10292"],
  },
  {
    primary_id: "D0U1UP",
    names: [
      "3,4-Dibenzyloxy-2'-Hydroxychalcone",
      "CHEMBL492554",
      "SCHEMBL17509393",
      "SCHEMBL17509394",
      "LHMDXKJKKAITQJ-BMRADRMJSA-N",
      "BDBM50257724",
      "3,4-Dibenzyloxy-2''-Hydroxychalcone",
    ],
  },
  {
    primary_id: "D0U1US",
    names: ["18F-FEDAA-1106"],
  },
  {
    primary_id: "D0U1UV",
    names: [
      "N1-[4-(Phenylmethoxy)Phenyl]-D-Aspartamine",
      "Amino Acid Analog, 4d",
      "CHEMBL478360",
      "BDBM24254",
      "(3R)-3-Amino-3-{[4-(Benzyloxy)Phenyl]carbamoyl}propanoic Acid",
    ],
  },
  {
    primary_id: "D0U1UZ",
    names: ["C(His-D-Phe-Arg-Trp-Abu)", "CHEMBL379531"],
  },
  {
    primary_id: "D0U1VR",
    names: ["AFX-9154"],
  },
  {
    primary_id: "D0U1VZ",
    names: ["Flurpiridaz F 18"],
  },
  {
    primary_id: "D0U1WH",
    names: ["N-Butylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL446837"],
  },
  {
    primary_id: "D0U1WL",
    names: ["MERIOLIN 2"],
  },
  {
    primary_id: "D0U1WP",
    names: ["MV-CEA"],
  },
  {
    primary_id: "D0U1WU",
    names: ["ETBX-011"],
  },
  {
    primary_id: "D0U1XG",
    names: ["Long-Acting HGH Conjugate"],
  },
  {
    primary_id: "D0U1XJ",
    names: ["HBR-985"],
  },
  {
    primary_id: "D0U1YQ",
    names: ["Antifungal Therapy, NanoElectroplex", "Antifungal Therapy (NanoElectroplex)"],
  },
  {
    primary_id: "D0U1YU",
    names: ["(S)-Tert-Butyl 4-(2-Oxohexadecanamido)Pentanoate", "CHEMBL462362"],
  },
  {
    primary_id: "D0U1ZD",
    names: ["Ioversol"],
  },
  {
    primary_id: "D0U1ZV",
    names: ["NERATINIB MALEATE"],
  },
  {
    primary_id: "D0U2AM",
    names: ["N5-[4-Benzylphenyl]-L-Glutamamide", "CHEMBL478379", "Modified Amino Acid Analog, 8f", "BDBM24271"],
  },
  {
    primary_id: "D0U2BH",
    names: [
      "Epigallocatechin Gallate",
      "(-)-Epigallocatechin Gallate",
      "EGCG",
      "989-51-5",
      "Epigallocatechin 3-Gallate",
      "Epigallocatechin-3-Gallate",
      "Tea Catechin",
      "(-)-Epigallocatechin-3-O-Gallate",
      "Teavigo",
      "Epigallocatechin-3-Monogallate",
      "(-)-Epigallocatechol Gallate",
      "(2R,3R)-5,7-Dihydroxy-2-(3,4,5-Trihydroxyphenyl)Chroman-3-Yl 3,4,5-Trihydroxybenzoate",
      "Catechin Deriv",
      "UNII-BQM438CTEL",
      "Green Tea Extract",
      "CCRIS 3729",
      "(-)-Epigallocatechin 3-Gallate",
      "C22H18O11",
      "BQM438CTEL",
      "(2R,3R)-5,7-Dihydroxy-2-(3,4,5-Trihydroxyphenyl)-3,4-Dihydro-2H-Chromen-3-Yl",
      "EGCG Analogs",
      "EGCG, Anagen",
      "Epigallocatechin Gallate Analogs, Anagen",
      "Epigallocatechin Gallate, Anagen",
      "GTPs,Anagen",
      "Green Tea Polyphenols, Anagen",
      "EPIGALOCATECHIN GALLATE",
    ],
  },
  {
    primary_id: "D0U2CB",
    names: ["Ibuproxam"],
  },
  {
    primary_id: "D0U2CE",
    names: ["RM-131"],
  },
  {
    primary_id: "D0U2CJ",
    names: ["PMID23083103C4"],
  },
  {
    primary_id: "D0U2CK",
    names: ["Quinoline Derivative 1"],
  },
  {
    primary_id: "D0U2CT",
    names: [
      "EMITEFUR",
      "BOF-A2",
      "Emitefur",
      "Last-F (Otsuka, JP, US, US, US)",
      "3-[3-(6-Benzoyloxy-3-Cyano-2-Pyridyloxycarbonyl)Benzoyl]-1-(Ethoxymethyl)-5-Fluorouracil",
    ],
  },
  {
    primary_id: "D0U2CV",
    names: ["Trapidil"],
  },
  {
    primary_id: "D0U2DA",
    names: ["BNC-210", "BNC-10002", "GABA-A Modulators (Oral, Anxiety), Bionomics"],
  },
  {
    primary_id: "D0U2DH",
    names: [
      "CC-1088",
      "CDC801",
      "CHEMBL88153",
      "SCHEMBL185853",
      "CDC-801",
      "DDYUBCCTNHWSQM-UHFFFAOYSA-N",
      "BDBM50216298",
      "HY-U00179",
      "CS-7247",
      "192819-27-5",
    ],
  },
  {
    primary_id: "D0U2DO",
    names: ["FP-1039"],
  },
  {
    primary_id: "D0U2DY",
    names: ["Recombinant TSH Superagonists"],
  },
  {
    primary_id: "D0U2EF",
    names: ["ACN-1052", "Adenosine A3 Antagonists (Ophthalmic, Glaucoma), Acorn"],
  },
  {
    primary_id: "D0U2FD",
    names: ["HIV-PV"],
  },
  {
    primary_id: "D0U2FN",
    names: ["Romazarit"],
  },
  {
    primary_id: "D0U2GG",
    names: [
      "5-(3-Chlorophenylethynyl)-5-Methyl[1,2,4]triazine",
      "CHEMBL390802",
      "SCHEMBL4290682",
      "1,2,4-Triazine, 3-[2-(3-Chlorophenyl)Ethynyl]-5-Methyl-",
      "BDBM50215448",
      "945915-51-5",
    ],
  },
  {
    primary_id: "D0U2GJ",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 22"],
  },
  {
    primary_id: "D0U2GT",
    names: ["SA3C"],
  },
  {
    primary_id: "D0U2GX",
    names: ["4-(4-Decyloxy-Phenyl)-1,1,1-Trifluoro-Butan-2-One", "CHEMBL462600"],
  },
  {
    primary_id: "D0U2HN",
    names: ["ISIS 183883"],
  },
  {
    primary_id: "D0U2HZ",
    names: ["6'-Methylepibatidine", "CHEMBL235261", "6''-Methylepibatidine", "SCHEMBL6359134"],
  },
  {
    primary_id: "D0U2IL",
    names: ["2-Methyl-6-Pyrrolidin-1-Yl-Hex-4-Ynal Oxime"],
  },
  {
    primary_id: "D0U2JP",
    names: [
      "Sitagliptin",
      "486460-32-6",
      "Januvia",
      "Xelevia",
      "Sitagliptan",
      "MK-0431",
      "UNII-QFP0P1DV7Z",
      "Tesavel",
      "QFP0P1DV7Z",
      "(3R)-3-AMINO-1-[3-(TRIFLUOROMETHYL)-5H,6H,7H,8H-[1,2,4]TRIAZOLO[4,3-A]PYRAZIN-7-YL]-4-(2,4,5-TRIFLUOROPHENYL)BUTAN-1-ONE",
      "(2R)-4-OXO-4-[3-(TRIFLUOROMETHYL)-5,6-DIHYDRO[1,2,4]TRIAZOLO[4,3-A]PYRAZIN-7(8H)-YL]-1-(2,4,5-TRIFLUOROPHENYL)BUTAN-2-AMINE",
      "CHEBI:40237",
      "(R)-3-AMINO-1-(3-(TRIFLUOROMETHYL)-5,6-DIHYDRO-[1,2,4]TRIAZOLO[4,3-A]PYRAZIN-7(8H)-YL)-4-(2,4,5-TRIFLUOROPHENYL)BUTAN-1-ONE",
      "Janumet",
      "Sitagliptin Phosphate",
      "Januvia (TN)",
      "Sitagliptin (PropINN)",
      "Januvia (Merck & Co)",
      "(3R)-3-Amino-1-[3-(Trifluoromethyl)-5,6,7,8-Tetrahydro-1,2,4-Triazolo[4,3-A]pyrazin-7-Yl]-4-(2,4,5-Trifluorophenyl)Butan-1-One",
      "(3R)-3-Amino-1-[3-(Trifluoromethyl)-5,6-Dihydro[1,2,4]triazolo[4,3-A]pyrazin-7(8H)-Yl]-4-(2,4,5-Trifluorophenyl)Butan-1-One",
      "(3R)-3-Amino-1-[3-(Trifluoromethyl)-6,8-Dihydro-5H-[1,2,4]triazolo[4,3-A]pyrazin-7-Yl]-4-(2,4,5-Trifluorophenyl)Butan-1-One",
      "Sitagliptin/Atovastatin",
    ],
  },
  {
    primary_id: "D0U2JU",
    names: ["5-Azolone Derivative 1"],
  },
  {
    primary_id: "D0U2KT",
    names: [
      "5'-Deoxythymidine",
      "3458-14-8",
      "Thymidine, 5'-Deoxy-",
      "5'-Ddthd",
      "2',5'-Dideoxythymidine",
      "5'-Deoxythymidine, 98%",
      "AC1L22MY",
      "SCHEMBL2056693",
      "CHEMBL465901",
      "CTK1C3983",
      "DTXSID70188127",
      "MolPort-003-930-221",
      "ZINC5765072",
      "AKOS015892581",
      "5 Inverted Exclamation Marka-Deoxythymidine",
      "FT-0772769",
      "C-44347",
      "I03-1540",
      "5''-Deoxythymidine",
      "SureCN2056693;",
      "2 Inverted Exclamation Marka,5 Inverted Exclamation Marka-Dideoxythymidine",
      "1-[(2R,4S,5R)-4-Hydroxy-5-Methyloxolan-2-Yl]-5-Methylpyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0U2KX",
    names: ["Aloradine"],
  },
  {
    primary_id: "D0U2LO",
    names: ["PMID25656651-Compound-23b"],
  },
  {
    primary_id: "D0U2MP",
    names: ["BP-Inter-014"],
  },
  {
    primary_id: "D0U2NV",
    names: ["C[RGD-(R)-Alpha-TfmfV]", "CHEMBL377066"],
  },
  {
    primary_id: "D0U2OO",
    names: [
      "Buspirone",
      "Ansial",
      "Ansiced",
      "Anxiron",
      "Axoren",
      "Bespar",
      "Buspirona",
      "Buspironum",
      "Buspisal",
      "Ansial (TN)",
      "Ansiced (TN)",
      "Anxiron (TN)",
      "Axoren (TN)",
      "Bespar (TN)",
      "BuSpar (TN)",
      "Buspimen (TN)",
      "Buspinol (TN)",
      "Buspiron (TN)",
      "Buspirona [INN-Spanish]",
      "Buspirone (INN)",
      "Buspirone [INN:BAN]",
      "Buspirone-MDTS",
      "Buspironum [INN-Latin]",
      "Buspisal (TN)",
      "Gen-Buspirone",
      "Narol (TN)",
      "Sorbon (TN)",
      "Spamilan (TN)",
      "Spitomin (TN)",
      "Gen-Buspirone (TN)",
      "MJ-9022-1",
      "N-(4-(4-(2-Pyrimidinyl)-1-Piperazinyl)Butyl)-1-Cyclopentanediacetamide",
      "8-(4-(4-(2-Pyrimidinyl)-1-Piperizinyl)Butyl)-8-Azaspiro(4,5)Decane-7,9-Dione",
      "8-[4-(4-Pyrimidin-2-Yl-Piperazin-1-Yl)-Butyl]-8-Aza-Spiro[4.5]decane-7,9-Dione",
      "8-[4-(4-Pyrimidin-2-Ylpiperazin-1-Yl)Butyl]-8-Azaspiro[4.5]decane-7,9-Dione",
      "8-{4-[4-(Pyrimidin-2-Yl)Piperazin-1-Yl]butyl}-8-Azaspiro[4.5]decane-7,9-Dione",
    ],
  },
  {
    primary_id: "D0U2OQ",
    names: ["X-071-NAB"],
  },
  {
    primary_id: "D0U2OS",
    names: ["GNE-652"],
  },
  {
    primary_id: "D0U2PS",
    names: [
      "HER2p63-71 Peptide Vaccine",
      "Cancer Vaccine, Mie University",
      "HER2p63-71 Peptide Vaccine, Mie University",
      "HER2p780-788 Peptide Vaccine, Mie University",
    ],
  },
  {
    primary_id: "D0U2PU",
    names: ["Anti-BCMA CAR T Cells"],
  },
  {
    primary_id: "D0U2PY",
    names: ["UTI Vaccine"],
  },
  {
    primary_id: "D0U2QI",
    names: ["METH-Mab"],
  },
  {
    primary_id: "D0U2QN",
    names: ["AMG 596"],
  },
  {
    primary_id: "D0U2QP",
    names: ["4-(3-Hexylureido)-N-Phenylbenzenesulfonamide", "CHEMBL1077716"],
  },
  {
    primary_id: "D0U2QT",
    names: ["Src Kinase Inhibitor I"],
  },
  {
    primary_id: "D0U2QX",
    names: [
      "4-Ethynyl-2-Fluoro-Deoxyadenosine",
      "EFdA",
      "Nucleoside Reverse Transcriptase Inhibitor (AIDS/HIV)",
      "Nucleoside Reverse Transcriptase Inhibitor (AIDS/HIV), Yamasa Shoyu",
    ],
  },
  {
    primary_id: "D0U2QY",
    names: ["1,1,1,2,2-Pentafluoro-8-Phenyl-Octan-3-One"],
  },
  {
    primary_id: "D0U2RR",
    names: ["NLP-83"],
  },
  {
    primary_id: "D0U2SI",
    names: ["PMID27109571-Compound-21"],
  },
  {
    primary_id: "D0U2SS",
    names: ["MDL-2170"],
  },
  {
    primary_id: "D0U2TH",
    names: ["Prophage Cancer Vaccine G-Series"],
  },
  {
    primary_id: "D0U2TQ",
    names: [
      "CGP-60474",
      "CGP60474",
      "164658-13-3",
      "UNII-16IP6G5MLC",
      "CGP 60474",
      "GNF-Pf-88",
      "16IP6G5MLC",
      "3-[[4-[2-[(3-Chlorophenyl)Amino]-4-Pyrimidinyl]-2-Pyridinyl]amino]-1-Propanol",
      "MLS000911536",
      "SMR000463552",
      "3-[[4-[2-(3-Chloroanilino)Pyrimidin-4-Yl]-2-Pyridyl]amino]propan-1-Ol",
      "3-[[4-[2-(3-Chloroanilino)Pyrimidin-4-Yl]pyridin-2-Yl]amino]propan-1-Ol",
      "1-Propanol, 3-((4-(2-((3-Chlorophenyl)Amino)-4-Pyrimidinyl)-2-Pyridinyl)Amino)-",
      "1-Propanol, 3-[[4-[2-[(3-Chlorophenyl)Amino]-4-Pyrimidinyl]-2-Pyridinyl]amino]-",
      "AC1LD8S6",
      "MLS003105942",
    ],
  },
  {
    primary_id: "D0U2TZ",
    names: ["NYX-2925"],
  },
  {
    primary_id: "D0U2UJ",
    names: [
      "1-(2-Hydroxy-4-Nitrophenyl)-3-Phenylurea",
      "80883-76-7",
      "CHEMBL280711",
      "NSC157449",
      "AC1MUHEY",
      "1-(2-Hydroxy-4-Nitro-Phenyl)-3-Phenyl-Urea",
      "SKampF 83589",
      "GTPL834",
      "SCHEMBL5595180",
      "CTK9A5291",
      "DTXSID00394355",
      "ZINC3936851",
      "BDBM50140794",
      "AKOS024384917",
      "NSC-157449",
      "MCULE-1458670709",
      "NSC 157449",
      "N-(2-Hydroxy-4-Nitrophenyl)-N'-Phenylurea",
      "ST51035446",
      "[(2-Hydroxy-4-Nitrophenyl)Amino]-N-Benzamide",
      "N-(2-Hydroxy-4-Nitrophenyl)-N'-(Phenyl)",
      "SK&F 83589",
    ],
  },
  {
    primary_id: "D0U2VA",
    names: ["2-(5-Hydroxy-Naphthalen-1-Yl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D0U2WO",
    names: ["ONO-2231"],
  },
  {
    primary_id: "D0U2WQ",
    names: ["Dihydropyrimidinone Derivative 4"],
  },
  {
    primary_id: "D0U2WU",
    names: [
      "3-Iodyl-Benzoic Acid",
      "3-Iodylbenzoic Acid",
      "CHEMBL332161",
      "64297-65-0",
      "M-Iodoxybenzoic Acid",
      "AC1MTEHD",
      "Benzoic Acid, 3-Iodyl-",
      "SCHEMBL752292",
      "CTK2A6346",
      "DTXSID40393544",
    ],
  },
  {
    primary_id: "D0U2XB",
    names: ["PGC GLP-1"],
  },
  {
    primary_id: "D0U2XR",
    names: [
      "4-Hydroxy-3,5-Diisopropyl-Benzaldehyde",
      "4-Hydroxy-3,5-Diisopropylbenzaldehyde",
      "10537-86-7",
      "4-Hydroxy-3,5-Bis(Isopropyl)Benzaldehyde",
      "4-Hydroxy-3,5-Di(Propan-2-Yl)Benzaldehyde",
      "3,5-Diisopropyl-4-Hydroxybenzaldehyde",
      "WVGDLTQPAQUBMO-UHFFFAOYSA-N",
      "EINECS 234-111-2",
      "CDS1_003950",
      "CBMicro_017454",
      "Cambridge Id 5318853",
      "AC1L33YK",
      "CHEMBL55354",
      "DivK1c_004990",
      "AC1Q6Q36",
      "SCHEMBL2597919",
      "CTK4A3796",
      "DTXSID50147095",
      "MolPort-002-144-464",
      "ZINC297726",
      "CCG-6041",
      "ANW-58021",
      "CD-932",
      "3,5-Diisopropy-4-Hydroxylbenzaldehyde",
      "AKOS000100686",
      "MCULE-9155785460",
      "3,5-Diisopropyl-4-Hydroxylbenzaldehyde",
    ],
  },
  {
    primary_id: "D0U2XU",
    names: ["XEN-2174"],
  },
  {
    primary_id: "D0U2YE",
    names: ["PMID28870136-Compound-56"],
  },
  {
    primary_id: "D0U2YL",
    names: ["CGS-23885", "134822-78-9"],
  },
  {
    primary_id: "D0U3AL",
    names: ["Interleukin-12 Gene Therapy"],
  },
  {
    primary_id: "D0U3CR",
    names: ["Guanadrel Sulfate"],
  },
  {
    primary_id: "D0U3DE",
    names: ["NT-FV-007"],
  },
  {
    primary_id: "D0U3DP",
    names: [
      "VS-5584",
      "5-(9-Isopropyl-8-Methyl-2-Morpholino-9H-Purin-6-Yl)Pyrimidin-2-Amine",
      "1246560-33-7",
      "VS-5584 (SB2343)",
      "UNII-W71J4X250V",
      "SB-2343",
      "SB2343",
      "CHEMBL3393066",
      "W71J4X250V",
      "5-(8-Methyl-2-Morpholin-4-Yl-9-Propan-2-Ylpurin-6-Yl)Pyrimidin-2-Amine",
      "C17H22N8O",
      "QYBGBLQCOOISAR-UHFFFAOYSA-N",
      "SCHEMBL539098",
      "GTPL8382",
      "EX-A288",
      "DTXSID10677328",
      "MolPort-035-757-944",
      "HMS3652B16",
      "BCP08247",
      "2797AH",
      "ZINC95644685",
      "S7016",
      "VS5584",
      "BDBM50059635",
      "AKOS024465057",
      "5-(9-Isopropyl-8-Methyl-2-Morpholin-4-Yl-9H-Purin-6-Yl)-Pyrimidin-2-Ylamine",
      "SB16877",
    ],
  },
  {
    primary_id: "D0U3DU",
    names: [
      "Dexmedetomidine",
      "Dexmedetomidina",
      "Dexmedetomidinum",
      "MPV 1440",
      "MPV-1440",
      "Precedex (TN)",
      "Dexmedetomidine (USAN/INN)",
      "(+)-4-((S)-Alpha,2,3-Trimethylbenzyl)Imidazole",
      "4-[(1S)-1-(2,3-Dimethylphenyl)Ethyl]-1H-Imidazole",
      "5-[(1S)-1-(2,3-Dimethylphenyl)Ethyl]-1H-Imidazole",
    ],
  },
  {
    primary_id: "D0U3DW",
    names: [
      "ANHYDRORYANIDINE",
      "Anhydroryanidine",
      "CHEMBL283783",
      "BDBM50044991",
      "2,6,9,11,13,14-Hexahydroxy-11-Isopropyl-7,10-Dimethyl-3-Methylene-(2R,7S,12R)-15-Oxapentacyclo[7.5.1.01,6.07,13.010,14]pentadec-12-Yl 1H-2-Pyrrolecarboxylate(Dehydroryanodine)",
      "2,6,9,11,13,14-Hexahydroxy-11-Isopropyl-7,10-Dimethyl-3-Methylene-(1R,7S,9S,12R)-15-Oxapentacyclo[7.5.1.01,6.07,13.010,14]pentadec-12-Yl 1H-2-Pyrrolecarboxylate",
    ],
  },
  {
    primary_id: "D0U3DX",
    names: ["Mannose Phosphate"],
  },
  {
    primary_id: "D0U3EA",
    names: ["BVT 933"],
  },
  {
    primary_id: "D0U3EC",
    names: [
      "Benazepril",
      "Benazapril",
      "Benazeprilum",
      "Benzazepril",
      "Benazepril Sandoz",
      "Benazeprilum [Latin]",
      "Forteekor [veterinary]",
      "Benazepril (INN)",
      "Benazepril Sandoz (TN)",
      "Benazepril [INN:BAN]",
      "Forteekor [veterinary] (TN)",
      "Fortekor (TN)",
      "Lotensin (TN)",
      "CGS-14824-A",
      "[(3S)-3-{[(1S)-1-(Ethoxycarbonyl)-3-Phenylpropyl]amino}-2-Oxo-2,3,4,5-Tetrahydro-1H-1-Benzazepin-1-Yl]acetic Acid",
      "[(3S)-3-({(1S)-1-[(Ethyloxy)Carbonyl]-3-Phenylpropyl}amino)-2-Oxo-2,3,4,5-Tetrahydro-1H-1-Benzazepin-1-Yl]acetic Acid",
      "[(3S)-3-{[(2S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Yl]amino}-2-Oxo-2,3,4,5-Tetrahydro-1H-1-Benzazepin-1-Yl]acetic Acid",
      "2-[(3S)-3-[[(2S)-1-Ethoxy-1-Oxo-4-Phenylbutan-2-Yl]amino]-2-Oxo-4,5-Dihydro-3H-1-Benzazepin-1-Yl]acetic Acid",
    ],
  },
  {
    primary_id: "D0U3ED",
    names: ["E-2007"],
  },
  {
    primary_id: "D0U3EJ",
    names: ["Vaccine, Avian Influenza"],
  },
  {
    primary_id: "D0U3EP",
    names: ["SRT501"],
  },
  {
    primary_id: "D0U3FP",
    names: ["Her-2/Neu Vaccine"],
  },
  {
    primary_id: "D0U3GA",
    names: ["SPC5001"],
  },
  {
    primary_id: "D0U3GB",
    names: ["LIQ-001"],
  },
  {
    primary_id: "D0U3GH",
    names: [
      "2-Amino-6-P-Tolylsulfanyl-Benzonitrile",
      "Benzonitrile, 2-Amino-6-[(4-Methylphenyl)Thio]-",
      "168910-27-8",
      "AC1LAE0E",
      "2-Amino-6-(4-Methylphenyl)Sulfanylbenzonitrile",
      "CHEMBL51912",
      "BDBM1737",
      "CTK0A8438",
      "DTXSID00333143",
      "ZINC5930943",
      "2-Amino-6-(P-Tolylthio)Benzonitrile",
      "2-Amino-6-(P-Tolylsulfanyl)Benzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1g",
      "2-Amino-6-[(4-Methylphenyl)Sulfanyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0U3GL",
    names: [
      "Oxandrolone",
      "Anavar",
      "Lonavar",
      "Ossandrolone",
      "Oxandrin",
      "Oxandrolona",
      "Oxandrolonum",
      "Protivar",
      "Provitar",
      "Vasorome",
      "Ossandrolone [DCIT]",
      "SC 11585",
      "Anavar (TN)",
      "Oxandrin (TN)",
      "Oxandrolona [INN-Spanish]",
      "Oxandrolonum [INN-Latin]",
      "SC-11585",
      "Oxandrin, Anavar, Oxandrolone",
      "Oxandrolone (JAN/USP/INN)",
      "Oxandrolone [USAN:INN:BAN:JAN]",
      "Dodecahydro-3-Hydroxy-6-(Hydroxy-Methyl)-3,3a,6-Trimethyl-1H-Benz(E)Indene-7-Acetic Acid Delta-Lactone",
      "(1S,3aS,3bR,5aS,9aS,9bS,11aS)-1-Hydroxy-1,9a,11a-Trimethyl-2,3,3a,3b,4,5,5a,6,9,9b,10,11-Dodecahydroindeno[4,5-H]isochromen-7-One",
      "(4aS,4bS,6aS,7S,9aS,9bR,11aS)-7-Hydroxy-4a,6a,7-Trimethyltetradecahydroindeno[4,5-H]isochromen-2(1H)-One",
      "17-Beta-Hydroxy-17-Methyl-2-Oxa-Androstan-3-One",
      "17-Beta-Hydroxy-17-Methyl-2-Oxa-Androstan-3-One (9CI)",
      "17beta-Hydroxy-17-Methyl-2-Oxa-5alpha-Androstan-3-One",
      "17beta-Hydroxy-17alpha-Methyl-2-Oxa-5alpha-Androstan-3-One",
      "2-Oxa-5alpha-Androstan-3-One, 17beta-Hydroxy-17-Methyl-(8CI)",
      "2-Oxaandrostan-3-One, 17-Hydroxy-17-Methyl-, (5-Alpha,17-Beta)-(9CI)",
    ],
  },
  {
    primary_id: "D0U3GT",
    names: ["MK-8342B"],
  },
  {
    primary_id: "D0U3GW",
    names: ["PXVX0047"],
  },
  {
    primary_id: "D0U3GZ",
    names: [
      "2,5-Dimethoxy-4-Bromophenethylamine",
      "4-Bromo-2,5-Dimethoxyphenethylamine",
      "66142-81-2",
      "BDMPEA",
      "2-(4-Bromo-2,5-Dimethoxyphenyl)Ethylamine",
      "2C-B",
      "2-(4-Bromo-2,5-Dimethoxyphenyl)Ethanamine",
      "Nexus",
      "4-Bromo-2,5-Dimethoxyphenylethylamine",
      "UNII-V77772N32H",
      "Benzeneethanamine, 4-Bromo-2,5-Dimethoxy-",
      "CHEMBL292821",
      "2-CB",
      "CHEBI:189669",
      "V77772N32H",
      "Eros [Street Name]",
      "Venus [Street Name]",
      "Nexus [Street Name]",
      "Nexus (Pharmaceutical)",
      "Spectrum [Street Name]",
      "NCGC00168259-02",
      "Toonies [Street Name]dd",
      "DEA No. 7392",
    ],
  },
  {
    primary_id: "D0U3HP",
    names: ["ABANOQUIL"],
  },
  {
    primary_id: "D0U3HU",
    names: ["LH Superagonists"],
  },
  {
    primary_id: "D0U3IG",
    names: [
      "Cetrimide",
      "Cetrimonium Bromide",
      "57-09-0",
      "Cetyltrimethylammonium Bromide",
      "Hexadecyltrimethylammonium Bromide",
      "CTAB",
      "Centimide",
      "CETAB",
      "Bromat",
      "Ctmab",
      "Cee Dee",
      "Cetavlon",
      "Hexadecyl Trimethyl Ammonium Bromide",
      "Trimethylcetylammonium Bromide",
      "Cetyl Trimethyl Ammonium Bromide",
      "Quamonium",
      "Lissolamine",
      "Cetaflon",
      "Lauroseptol",
      "Suticide",
      "Pollacid",
      "Cetarol",
      "Micol",
      "Cetrimide Bp",
      "Lissolamine A",
      "Lissolamin V",
      "Cetavlon Bromide",
      "Cycloton V",
      "Cirrasol OD",
      "Acetoquat CTAB",
      "Softex KW",
      "Cetrimonii Bromidum",
      "N-Cetyltrimethylammonium Bromide",
      "Bromuro De Cetrimonio",
      "Cetrimida",
    ],
  },
  {
    primary_id: "D0U3IS",
    names: ["Benzonaphthyridine Derivative 1"],
  },
  {
    primary_id: "D0U3JD",
    names: ["1-Methyl-1H-Indole-2,3-Dione"],
  },
  {
    primary_id: "D0U3JZ",
    names: ["PMX-30024", "Defensin Mimetic Antimalarial Compounds, PolyMedix", "PMX-70008"],
  },
  {
    primary_id: "D0U3KF",
    names: ["NRT-700"],
  },
  {
    primary_id: "D0U3KP",
    names: ["HT-100"],
  },
  {
    primary_id: "D0U3KT",
    names: ["PMID25468267-Compound-55"],
  },
  {
    primary_id: "D0U3KV",
    names: ["Triazolo-Pyridine Derivative 2"],
  },
  {
    primary_id: "D0U3KX",
    names: ["PMID29473428-Compound-70"],
  },
  {
    primary_id: "D0U3LA",
    names: ["2-(2-Benzoyl-4-Bromophenoxy)Acetic Acid", "CHEMBL217591"],
  },
  {
    primary_id: "D0U3LK",
    names: [
      "Metformin Glycinate",
      "DMMET-01",
      "Metformin Glycinate (Diabetes)",
      "Type 2 Diabetes Therapy, Laboratorios Silanes",
      "Metformin Glycinate (Diabetes), Laboratorios Silanes",
    ],
  },
  {
    primary_id: "D0U3LS",
    names: [
      "Triazolam",
      "Clorazolam",
      "Halcion",
      "Hypnostat",
      "Novidorm",
      "Novodorm",
      "Songar",
      "Triazolamum",
      "Trilam",
      "Apo Triazo",
      "Apotex Brand Of Triazolam",
      "Gen Triazolam",
      "Genpharm Brand Of Triazolam",
      "Gerard Brand Of Triazolam",
      "Pfizer Brand Of Triazolam",
      "Triazolam Pfizer Brand",
      "TGAR01H",
      "U 33030",
      "Alti-Triazolam",
      "Apo-Triazo",
      "Gen-Triazolam",
      "Halcion (TN)",
      "Halcion (Triazolam)",
      "Hypam (TN)",
      "Novo-Triolam",
      "Triazolamum [INN-Latin]",
      "Trilam (TN)",
      "U 33,030",
      "U-33030",
      "U33,030",
      "Apo-Triazo (TN)",
      "U-33,030",
      "Triazolam (JAN/USP/INN)",
      "Triazolam [USAN:BAN:INN:JAN]",
      "8-Chloro-6-(2-Chlorophenyl)-1-Methyl-4H-(1,2,4)Triazolo(4,3-A)(1,4)Benzodiazepine",
      "8-Chloro-6-(2-Chlorophenyl)-1-Methyl-4H-[1,2,4]triazolo[4,3-A][1,4]benzodiazepine",
      "8-Chloro-6-(O-Chlorophenyl)-1-Methyl-4H-S-Triazolo(4,3-A)(1,4)Benzodiazepine",
      "8-Chloro-6-(O-Chlorophenyl)-1-Methyl-4H-S-Triazolo[4,3-A][1,4]benzodiazepine",
      "8-Chloro-6-[2-Chlorophenyl]-1-Methyl-4H-[1,2,4]triazolo[4,3-A][1,4]benzodiazepine",
    ],
  },
  {
    primary_id: "D0U3MB",
    names: ["SK-683"],
  },
  {
    primary_id: "D0U3ME",
    names: ["4'-(1,1-Dimethyl-Heptyl)-3,5-Dimethyl-Biphenyl", "CHEMBL61038", "BDBM50213142"],
  },
  {
    primary_id: "D0U3ML",
    names: ["ISIS 102660"],
  },
  {
    primary_id: "D0U3MQ",
    names: ["UX001 Sialic Acid"],
  },
  {
    primary_id: "D0U3NI",
    names: ["BIX 02565", "BIX02565", "BIX-02565"],
  },
  {
    primary_id: "D0U3OY",
    names: ["Rovelizumab"],
  },
  {
    primary_id: "D0U3PC",
    names: [
      "1-Benzo[b]thiophen-2-Yl-Cycloheptylamine",
      "CHEMBL18811",
      "NCI60_038329",
      "AC1L9GG6",
      "CTK7D5124",
      "ZINC1657601",
      "BDBM50040194",
      "1-(Benzothiophen-2-Yl)Cycloheptan-1-Amine",
      "1-(1-Benzothiophen-2-Yl)Cycloheptan-1-Amine",
    ],
  },
  {
    primary_id: "D0U3PM",
    names: ["Keytruda Pembrolizumab"],
  },
  {
    primary_id: "D0U3PR",
    names: ["Immunotoxin Conjugate"],
  },
  {
    primary_id: "D0U3QK",
    names: ["OMS824"],
  },
  {
    primary_id: "D0U3RD",
    names: ["Pyrido[3,2-D]pyrimidine Derivative 3"],
  },
  {
    primary_id: "D0U3RM",
    names: ["AL-508"],
  },
  {
    primary_id: "D0U3RU",
    names: ["MK-1107"],
  },
  {
    primary_id: "D0U3RV",
    names: ["Motexafin Lutetium"],
  },
  {
    primary_id: "D0U3SB",
    names: ["2-(N-Pyrrolidinyl)-3'-Bromopropiophenone", "CHEMBL599230", "BDBM50308117"],
  },
  {
    primary_id: "D0U3SK",
    names: ["Tiapamil"],
  },
  {
    primary_id: "D0U3SY",
    names: [
      "Alectinib",
      "1256580-46-7",
      "CH5424802",
      "CH 5424802",
      "AF-802",
      "Alecensa",
      "UNII-LIJ4CT1Z3Y",
      "AF 802",
      "LIJ4CT1Z3Y",
      "Alectinib (CH5424802)",
      "9-Ethyl-6,6-Dimethyl-8-(4-Morpholinopiperidin-1-Yl)-11-Oxo-6,11-Dihydro-5H-Benzo[b]carbazole-3-Carbonitrile",
      "CHEMBL1738797",
      "AF802",
      "9-Ethyl-6,6-Dimethyl-8-[4-(Morpholin-4-Yl)Piperidin-1-Yl]-11-Oxo-6,11-Dihydro-5h-Benzo[b]carbazole-3-Carbonitrile",
      "9-Ethyl-6,11-Dihydro-6,6-Dimethyl-8-[4-(4-Morpholinyl)-1-Piperidinyl]-11-Oxo-5H-Benzo[b]carbazole-3-Carbonitrile",
      "AK170451",
      "C30H34N4O2",
      "9-Ethyl-6,6-Dimethyl-",
    ],
  },
  {
    primary_id: "D0U3TE",
    names: ["J-555Y"],
  },
  {
    primary_id: "D0U3UJ",
    names: ["CHT-25"],
  },
  {
    primary_id: "D0U3UM",
    names: ["ETONITAZENE"],
  },
  {
    primary_id: "D0U3VH",
    names: ["STEBVax"],
  },
  {
    primary_id: "D0U3VU",
    names: ["Aminoazetidine Derivative 9"],
  },
  {
    primary_id: "D0U3WK",
    names: ["SB452533"],
  },
  {
    primary_id: "D0U3WO",
    names: ["RTA 408"],
  },
  {
    primary_id: "D0U3WQ",
    names: ["AGL 2043", "AGL-2043", "HMS3229A05", "AGL2043"],
  },
  {
    primary_id: "D0U3WW",
    names: ["L-163,101"],
  },
  {
    primary_id: "D0U3XI",
    names: ["FT-061"],
  },
  {
    primary_id: "D0U3XL",
    names: ["JNJ-26483327"],
  },
  {
    primary_id: "D0U3YB",
    names: ["Masoprocol"],
  },
  {
    primary_id: "D0U3YC",
    names: ["LY-2318912", "CHEMBL486037", "BDBM50242639"],
  },
  {
    primary_id: "D0U3YQ",
    names: ["NS 2028"],
  },
  {
    primary_id: "D0U3YU",
    names: [
      "Ademetionine",
      "Ceritan",
      "Samyr",
      "Transmetil",
      "Ademetionine Butandisulphonate",
      "Ademetionine Disulfate Tosylate",
      "Ademetionine Sulfate Tosilate",
      "FD-1561",
      "FO-1561",
    ],
  },
  {
    primary_id: "D0U3YW",
    names: [
      "ADC-9971",
      "ADS-9971",
      "CRTh2 Antagonist (Oral, COPD/Allergic Asthma/Allergic Rhinitis), Argenta Discovery",
      "Prostaglandin D2 Receptor Antagonist (Oral, Allergic Asthma/Allergic Rhinitis/COPD), Argenta Discovery",
    ],
  },
  {
    primary_id: "D0U3YY",
    names: ["Pyrano[2,3-B]pyridine Derivative 2"],
  },
  {
    primary_id: "D0U3ZL",
    names: [
      "CARBOXYFENTANYL",
      "CHEBI:61106",
      "CHEMBL216239",
      "4-Oxo-4-{phenyl[1-(2-Phenylethyl)Piperidin-4-Yl]amino}butanoic Acid",
      "4-Anilidopiperidine, 8",
      "Epitope ID:153518",
      "SCHEMBL5163413",
      "BDBM21114",
      "ZINC14975441",
      "4-Oxo-4-[N-(1-Phenethylpiperidine-4-Yl)Anilino]butyric Acid",
      "3-{phenyl[1-(2-Phenylethyl)Piperidin-4-Yl]carbamoyl}propanoic Acid",
    ],
  },
  {
    primary_id: "D0U3ZP",
    names: [
      "5-(4-Methoxy-Phenyl)-1-Phenyl-1H-Benzoimidazole",
      "Benzimidazole 2",
      "AC1NS6L8",
      "CHEMBL81026",
      "SCHEMBL7683792",
      "BDBM5295",
      "MolPort-028-745-333",
      "ZINC13536068",
      "5-(4-Methoxyphenyl)-1-Phenylbenzimidazole",
      "1-Phenyl-5-(4-Methoxyphenyl)-1H-Benzimidazole",
    ],
  },
  {
    primary_id: "D0U4BB",
    names: [
      "HERNIARIN",
      "7-Methoxycoumarin",
      "Herniarin",
      "531-59-9",
      "7-Methoxy-2H-Chromen-2-One",
      "Ayapanin",
      "Herniarine",
      "Methylumbelliferone",
      "7-Methoxy-2H-1-Benzopyran-2-One",
      "7-Methoxychromen-2-One",
      "2H-1-BENZOPYRAN-2-ONE, 7-METHOXY-",
      "Coumarin, 7-Methoxy-",
      "METHOXYCOURMARIN, 7-",
      "Herniarin (6CI)",
      "Methyl Umbelliferyl Ether",
      "UNII-DGK72G008A",
      "7-Methoxy-Coumarin",
      "7-Methoxy Coumarin",
      "NSC 404559",
      "7-(Methyloxy)-2H-Chromen-2-One",
      "EINECS 208-513-3",
      "7-Methoxy-Chromen-2-One",
      "BRN 0141728",
      "CHEMBL49732",
      "CHEBI:5679",
      "DGK72G008A",
      "Coumarin, 7-Methoxy- (8CI)",
    ],
  },
  {
    primary_id: "D0U4BO",
    names: ["WVTHRLAGLLSRSGGVVKNNFVPTNVGSKAF-NH2", "CHEMBL500283"],
  },
  {
    primary_id: "D0U4BQ",
    names: [
      "2-Amino-6-(2-Chloro-Benzenesulfonyl)-Benzonitrile",
      "AC1LA8EC",
      "BDBM1788",
      "CHEMBL301086",
      "CTK7C6580",
      "ZINC5933964",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3h",
      "2-Amino-6-(2-Chlorophenyl)Sulfonylbenzonitrile",
      "2-(2-Chlorophenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(2-Chlorophenyl)Sulfonyl-Benzonitrile",
      "2-Amino-6-[(2-Chlorophenyl)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0U4CE",
    names: [
      "Bosentan",
      "Actelion",
      "Bosentanum",
      "Tracleer",
      "Bosentan (INN)",
      "KS-5062",
      "Ro 47-0203",
      "Tracleer (TN)",
      "Bosentan [USAN:INN:BAN]",
      "Ro 47-0203/039",
      "Ro-47-0203",
      "Ro-47-0203/029",
      "Ro-47-0203/039",
      "P-Tert-Butyl-N-[6-(2-Hydroxyethoxy)",
      "P-Tert-Butyl-N-(6-(2-Hydroxyethoxy)-5-(O-Methoxyphenoxy)-2-(2-Pyrimidinyl)-4-Pyrimidinyl)Benzenesulfonamide",
      "4-(1,1-Dimethylethyl)-N-(6-(2-Hydroxyethoxy)-5-(2-Methoxyphenoxy)-(2,2'-Bipyrimidin)-4-Yl) Benzenesulfornamide",
      "4-(1,1-Dimethylethyl)-N-(6-(2-Hydroxyethoxy)-5-(2-Methoxyphenoxy)-(2,2'-Bipyrimidin)-4-Yl)Benzenesulfornamide",
      "4-T-Butyl-N-(6-(2-Hydroxyethoxy)-5-(2-Methoxyphenoxy)-2,2'-Bipyrimidin-4-Yl)Benzenesulfonamide",
      "4-Tert-Butyl-N-[6-(2-Hydroxyethoxy)-5-(2-Methoxyphenoxy)-2,2'-Bipyrimidin-4-Yl]benzenesulfonamide",
      "4-Tert-Butyl-N-[6-(2-Hydroxyethoxy)-5-(2-Methoxyphenoxy)-2-Pyrimidin-2-Ylpyrimidin-4-Yl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0U4DT",
    names: ["PMID27019002-Compound-7"],
  },
  {
    primary_id: "D0U4EU",
    names: ["SB-743921"],
  },
  {
    primary_id: "D0U4FF",
    names: [
      "BMS-599626",
      "714971-09-2",
      "AC480",
      "AC-480",
      "BMS 599626",
      "(S)-Morpholin-3-Ylmethyl (4-((1-(3-Fluorobenzyl)-1H-Indazol-5-Yl)Amino)-5-Methylpyrrolo[2,1-F][1,2,4]triazin-6-Yl)Carbamate",
      "UNII-2252724U5N",
      "CHEMBL1645462",
      "BMS599626",
      "AC480 (BMS-599626)",
      "2252724U5N",
      "J-502499",
      "C27H27FN8O3",
      "AC 480",
      "AC1480 (Free Base)",
      "PubChem22432",
      "GTPL7647",
      "SCHEMBL12065349",
      "CTK8B6780",
      "DTXSID60221714",
      "SYN1142",
      "AOB5594",
      "MolPort-023-279-418",
      "EX-A2296",
      "ZINC6717782",
      "KS-000008GE",
      "BDBM50333373",
      "S1056",
      "C27H44O11",
      "ANW-54337",
      "AKOS015999853",
      "CS-0407",
      "RL05125",
      "BCP9000428",
      "SB21703",
    ],
  },
  {
    primary_id: "D0U4FW",
    names: [
      "DU 125530",
      "UNII-ZB05V621UD",
      "CHEMBL79261",
      "DU-125530",
      "ZB05V621UD",
      "161611-99-0",
      "DU125530",
      "SCHEMBL1082516",
      "BDBM85079",
      "DTXSID00167208",
      "MolPort-044-830-638",
      "PDSP1_000932",
      "PDSP2_000918",
      "1,2-Benzisothiazol-3(2H)-One, 2-(4-(4-(7-Chloro-2,3-Dihydro-1,4-Benzodioxin-5-Yl)-1-Piperazinyl)Butyl)-, 1,1-Dioxide",
      "L001571",
      "2-[4-[4-(7-Chloro-2,3-Dihydro-1,4-Benzodioxin-5-Yl)-1-Piperazinyl]-Butyl]-1,2-Benzisothiazol-3(2h)-One-1,1-Dioxide",
      "2-[4-[4-[(7-Chloro-2,3-Dihydro-1,4-Benzodioxin)-5-Yl]-1-Piperazinyl]butyl]-1,2-Benzisothiazol-3(2H)-One 1,",
    ],
  },
  {
    primary_id: "D0U4GL",
    names: ["GLWL-01"],
  },
  {
    primary_id: "D0U4HG",
    names: ["COCHINCHINENIN B", "CHEMBL254648", "BDBM50222763"],
  },
  {
    primary_id: "D0U4HM",
    names: ["TPMPA", "(1,2,5,6-Tetrahydropyridine-4-Yl)Methylphosphinic Acid"],
  },
  {
    primary_id: "D0U4HZ",
    names: [
      "Bilive",
      "Combined Hepatitis A And B Vaccine, Sinovac",
      "Hepatitis A+B Vaccine, Sinovac",
      "Vaccine (Hepatitis A And B), Sinovac",
    ],
  },
  {
    primary_id: "D0U4IG",
    names: ["AVP-4", "AVP-04", "Cell Surface Glycoprotein (Cancer), AviPep"],
  },
  {
    primary_id: "D0U4IV",
    names: ["Elfamycin"],
  },
  {
    primary_id: "D0U4JD",
    names: ["(S)-N2-[4-(Benzyloxy)Benzyl]serinamide", "CHEMBL243058", "SCHEMBL7394895", "ZINC28711471"],
  },
  {
    primary_id: "D0U4JI",
    names: ["JWH-337"],
  },
  {
    primary_id: "D0U4JL",
    names: ["6-(4-Hydroxyphenyl)-2-Morpholino-4H-Chromen-4-One", "CHEMBL371103"],
  },
  {
    primary_id: "D0U4JP",
    names: ["PMID30247903-Compound-General Structure31"],
  },
  {
    primary_id: "D0U4JW",
    names: [
      "1-(Phenylsulfonyl)-1H-Pyrrolo[3,2-B]pyridine",
      "1-(Phenylsulfonyl)-4-Azaindole",
      "677302-44-2",
      "CHEMBL556118",
      "SCHEMBL3705420",
      "HGOGRVVSSAWHTO-UHFFFAOYSA-N",
      "BDBM50295729",
      "ZINC42923799",
      "AKOS032961360",
      "KB-3354225",
    ],
  },
  {
    primary_id: "D0U4JX",
    names: ["Padoporfin Photodynamic Therapy"],
  },
  {
    primary_id: "D0U4JY",
    names: ["Protease Activated Prodrug"],
  },
  {
    primary_id: "D0U4KJ",
    names: ["MTV273"],
  },
  {
    primary_id: "D0U4KM",
    names: [
      "1-(1-Adamantyl)-3-(1-Propionylpiperidin-4-Yl)Urea",
      "CHEMBL214943",
      "US8501783, 1163",
      "SCHEMBL2731282",
      "BDBM100426",
      "BDBM100363",
      "BDBM50191887",
    ],
  },
  {
    primary_id: "D0U4LI",
    names: ["Tricyclic Compound 4"],
  },
  {
    primary_id: "D0U4LP",
    names: [
      "Curcumin-ND",
      "Curcumin-ND (Mantle Cell Lymphoma)",
      "Curcumin-ND (Mantle Cell Lymphoma), Lypro",
      "Curcumin-NanoDisks (Mantle Cell Lymphoma), Lypro",
    ],
  },
  {
    primary_id: "D0U4LQ",
    names: ["HyperAcute Lung"],
  },
  {
    primary_id: "D0U4LU",
    names: ["H-Dmt-Tic-NH-(CH2)6-NH-Phe-H", "CHEMBL198909"],
  },
  {
    primary_id: "D0U4LV",
    names: ["SIDEROXYLONAL B"],
  },
  {
    primary_id: "D0U4PG",
    names: [
      "AEOL-11207",
      "AEOL-11203",
      "AEOL-11216",
      "Catalytic Antioxidants, Aeolus",
      "Catalytic Antioxidants, Incara",
    ],
  },
  {
    primary_id: "D0U4QQ",
    names: ["TNFcept"],
  },
  {
    primary_id: "D0U4QZ",
    names: ["EU-517"],
  },
  {
    primary_id: "D0U4SG",
    names: ["PMID12723943C12"],
  },
  {
    primary_id: "D0U4SM",
    names: ["NASVAC"],
  },
  {
    primary_id: "D0U4SS",
    names: ["CD-160130"],
  },
  {
    primary_id: "D0U4TA",
    names: [
      "(S)-2-Amino-N-(1-Cyano-2-Phenylethyl)Acetamide",
      "CHEMBL379542",
      "Acetamide, 2-Amino-N-[(1S)-1-Cyano-2-Phenylethyl]-",
      "BDBM50186094",
    ],
  },
  {
    primary_id: "D0U4TN",
    names: ["2-(2-Hexylphenyl)Isoindoline-1,3-Dione", "CHEMBL245943", "PP60", "BDBM23841"],
  },
  {
    primary_id: "D0U4TY",
    names: ["4-Cycloheptyl-6-Propylpyrimidine-2-Carbonitrile", "CHEMBL1085423", "SCHEMBL5796281"],
  },
  {
    primary_id: "D0U4UQ",
    names: [
      "Hydrochlorothiazide",
      "Acesistem",
      "Acuilix",
      "Acuretic",
      "Aldazida",
      "Aldoril",
      "Apresazide",
      "Aquarills",
      "Aquarius",
      "Bremil",
      "Briazide",
      "Caplaril",
      "Carozide",
      "Catiazida",
      "Chlorizide",
      "Chlorosulthiadil",
      "Chlorzide",
      "Chlothia",
      "Cidrex",
      "Clorana",
      "Condiuren",
      "Diaqua",
      "Dichlorosal",
      "Dichlorotride",
      "Dichlothiazide",
      "Dichlotiazid",
      "Dichlotride",
      "Diclotride",
      "Dicyclotride",
      "Didral",
      "Dihydran",
      "Dihydrochlorothiazid",
      "Dihydrochlorothiazide",
      "Dihydrochlorothiazidum",
      "Dihydrochlorurit",
      "Dihydrochlorurite",
      "Dihydroxychlorothiazidum",
      "Direma",
      "Disalunil",
      "Disothiazid",
      "Diurogen",
      "Dixidrasi",
      "Drenol",
      "Esidrex",
      "Esidrix",
      "Esoidrina",
      "Fluvin",
      "HCT",
      "HCTZ",
      "HCZ",
      "Hidril",
      "Hidrochlortiazid",
      "Hidroclorotiazida",
      "Hidroronol",
      "Hidrosaluretil",
      "Hidrotiazida",
      "Hyclosid",
      "Hydril",
      "HydroDIURIL",
      "Hydrochlorat",
      "Hydrochlorot",
      "Hydrochlorothiazid",
      "Hydrochlorothiazidum",
      "Hydrochlorthiazide",
      "Hydrochlorthiazidum",
      "Hydrocot",
      "Hydrodiuretic",
      "Hydropres",
      "Hydrosaluric",
      "Hydrothide",
      "Hydrozide",
      "Hypothiazid",
      "Hypothiazide",
      "Hytrid",
      "Idroclorotiazide",
      "Idrotiazide",
      "Indroclor",
      "Ivaugan",
      "Manuril",
      "Maschitt",
      "Medozide",
      "Megadiuril",
      "Microzide",
      "Mictrin",
      "Mikorten",
      "Modurcen",
      "Moduretic",
      "Natrinax",
      "Nefrix",
      "Nefrol",
      "Neoflumen",
      "Newtolide",
      "Novodiurex",
      "Oretic",
      "Pantemon",
      "Panurin",
      "Roxane",
      "Saldiuril",
      "Sectrazide",
      "Selozide",
      "Servithiazid",
      "Spironazide",
      "Tandiur",
      "Thiaretic",
      "Thiuretic",
      "Thlaretic",
      "Timolide",
      "Unazid",
      "Urodiazin",
      "Urozide",
      "Vaseretic",
      "Vetidrex",
      "Aquazide H",
      "Chlorsulfonamidodihydrobenzothiadiazine Dioxide",
      "Component Of Aldactazide",
      "Component Of Aldoril",
      "Component Of Butizide Prestabs",
      "Component Of Caplaril",
      "Component Of Cyclex",
      "Component Of Dyazide",
      "Component Of Esimil",
      "Concor Plus",
      "Diclot Ride",
      "Hydro Par",
      "Hydrochlorothiazide Intensol",
      "Idroclorotiazide [DCIT]",
      "Lotensin HCT",
      "Panurin Dichloride",
      "Raunova Plus",
      "Diu 25 Vigt",
      "H 4759",
      "MaybridgeCompound 10",
      "Mazide 25 Mg",
      "Su 5879",
      "Aldactazide 25/25",
      "Aldectazide 50/50",
      "Apo-Hydro",
      "Aquazide H (TN)",
      "Aquazide-H",
      "Dichlotride (TN)",
      "Diu-Melusin",
      "Esidrex (TN)",
      "Esidrix (TN)",
      "HCT-Isis",
      "Hidro-Niagrin",
      "Hidroclorotiazida [INN-Spanish]",
      "Hydrex-Semi",
      "Hydro-Aquil",
      "Hydro-D",
      "Hydro-Diuril",
      "Hydro-Saluric",
      "Hydro-T",
      "Hydro-Chlor",
      "HydroSaluric (TN)",
      "Hydrochlorothiazidum [INN-Latin]",
      "Hydrodiuril (TN)",
      "Hydrozide Injection, Veterinary",
      "Jen-Diril",
      "Microzide (TN)",
      "Neo-Flumen",
      "Neo-Minzil",
      "Neo-Codema",
      "Oretic (TN)",
      "Ro-Hydrazide",
      "AF-614/30832002",
      "Apo-Hydro (TN)",
      "Hydrochlorothiazide [INN:BAN:JAN]",
      "Hydrochlorothiazide (JP15/USP/INN)",
      "Dro-2H-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "3,4-Dihydro-6-Chloro-7-Sulfamyl-1,2, 4-Benzothi",
      "3,4-Dihydrochlorothiazide",
      "6-Chloro-7-Sulfamoyl-3, 4-Dihy",
    ],
  },
  {
    primary_id: "D0U4VL",
    names: [
      "Artepillin C",
      "Artepillin",
      "72944-19-5",
      "(E)-3-[4-Hydroxy-3,5-Bis(3-Methylbut-2-Enyl)Phenyl]prop-2-Enoic Acid",
      "CCRIS 8746",
      "AC1NV86P",
      "SCHEMBL1229229",
      "CHEMBL456309",
      "GTPL6302",
      "NSC718732",
      "3-(4-Hydroxy-3,5-Bis(3-Methyl-2-Butenyl)Phenyl)-2-Propenoic Acid",
      "BDBM50362836",
      "NSC-718732",
      "3,5-Bis(3-Methyl-2-Butenyl)-4-Hydroxybenzenepropenoic Acid",
      "2-Propenoic Acid, 3-(4-Hydroxy-3,5-Bis(3-Methyl-2-Butenyl)Phenyl)-, (E)-",
    ],
  },
  {
    primary_id: "D0U4VT",
    names: [
      "Trimethadione",
      "Absentol",
      "Absetil",
      "Convenixa",
      "Convexina",
      "Edion",
      "Epidione",
      "Epidone",
      "Epixal",
      "Etydion",
      "Minoaleuiatin",
      "Minoaleviatin",
      "Petidion",
      "Petidon",
      "Petilep",
      "Petimalin",
      "Pitmal",
      "Ptimal",
      "Tioxanona",
      "Tredione",
      "Tricione",
      "Tridilona",
      "Tridion",
      "Tridione",
      "Tridone",
      "Trilidona",
      "Trimedal",
      "Trimedone",
      "Trimetadiona",
      "Trimetadione",
      "Trimethadion",
      "Trimethadionum",
      "Trimethdione",
      "Trimethin",
      "Trimethinum",
      "Trimetin",
      "Trioksal",
      "Trioxanona",
      "Triozanona",
      "Tromedone",
      "Troxidone",
      "Abbott Brand Of Trimethadione",
      "Trimetadione [DCIT]",
      "A 2297",
      "Mino-Aleviatin",
      "Neo-Absentol",
      "Tridione (TN)",
      "Trimetadiona [INN-Spanish]",
      "Trimethadione [INN:JAN]",
      "Trimethadionum [INN-Latin]",
      "Trimethadione (JP15/INN)",
      "3,3,5-Trimethyl-2,4-Diketooxazolidine",
      "3,5,5,-Trimethyloxazolidine-2,4-Dione",
      "3,5,5-TRIMETHYL-OXAZOLIDINE-2,4-DIONE",
      "3,5,5-Trimethyl-1,3-Oxazolidine-2,4-Dione",
      "3,5,5-Trimethyl-2,4-Oxazolidinedione",
      "3,5,5-Trojmetylooksazolidyno-2,4-Dion",
      "3,5,5-Trojmetylooksazolidyno-2,4-Dion [Polish]",
    ],
  },
  {
    primary_id: "D0U4VW",
    names: ["BX 048", "BX-048", "BX048"],
  },
  {
    primary_id: "D0U4WB",
    names: ["TLC-C-53"],
  },
  {
    primary_id: "D0U4WF",
    names: ["HCG Contraceptive Vaccine"],
  },
  {
    primary_id: "D0U4WG",
    names: ["SLV-307"],
  },
  {
    primary_id: "D0U4WP",
    names: ["YM44781"],
  },
  {
    primary_id: "D0U4WY",
    names: [
      "BILD-1357",
      "BILD 1357",
      "AC1LAKBO",
      "1-[[2-(2-{2-[(2-Benzyl-4-Phenyl-Butyryl)-Methyl-Amino]-3-Methyl-Butyrylamino}-3,3-Dimethyl-Butyrylamino)-4-Oxo-4-Pyrrolidin-1-Yl-Butyrylamino]-(1-Ethyl-2,2-Dimethyl-Propylcarbamoyl)-Methyl]-Cyclopentanecarboxylic Acid",
      "1-[(1S)-1-[[(2S)-2-[[(2S)-2-[[(2S)-2-[(2-Benzyl-4-Phenylbutanoyl)-Methylamino]-3-Methylbutanoyl]amino]-3,3-Dimethylbutanoyl]amino]-4-Oxo-4-Pyrrolidin-1-Ylbutanoyl]amino]-2-[[(3R)-2,2-Dimethylpentan-3-Yl]amino]-2-Oxoethyl]cyclopentane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0U4XC",
    names: ["Carticel", "Cartilage Repair, Genzyme Tissue Repair"],
  },
  {
    primary_id: "D0U4XJ",
    names: ["Luliconazole"],
  },
  {
    primary_id: "D0U4YD",
    names: ["YKP10461"],
  },
  {
    primary_id: "D0U4YG",
    names: ["Penicillin G Benzathine", "Bicillin L-A", "Permapen", "Bicillin"],
  },
  {
    primary_id: "D0U4ZO",
    names: ["PMID26161698-Compound-10"],
  },
  {
    primary_id: "D0U4ZS",
    names: ["PMID20167483C22e"],
  },
  {
    primary_id: "D0U4ZT",
    names: ["ISIS 25118"],
  },
  {
    primary_id: "D0U4ZV",
    names: ["2-Cl-ADP(Alpha-BH3)", "GTPL8447", "CHEMBL2386491", "BDBM50435011"],
  },
  {
    primary_id: "D0U4ZY",
    names: [
      "DMP-757",
      "CHEMBL65617",
      "BDBM50285199",
      "[(5S,11S,14S)-11-(3-Guanidino-Propyl)-14-Isopropyl-12-Methyl-4,7,10,13,16-Pentaoxo-3,6,9,12,15-Pentaaza-Bicyclo[15.3.1]henicosa-1(20),17(21),18-Trien-5-Yl]-Acetic Acid",
    ],
  },
  {
    primary_id: "D0U5AN",
    names: ["ImmuFact IMP321"],
  },
  {
    primary_id: "D0U5AQ",
    names: ["ATLCAR.CD30.CCR4 Cells"],
  },
  {
    primary_id: "D0U5BE",
    names: ["Tetra-Hydro-Quinoline Derivative 2"],
  },
  {
    primary_id: "D0U5BF",
    names: ["ALPHA-FMH", "Alpha-(Fluoromethyl)Histidine"],
  },
  {
    primary_id: "D0U5BV",
    names: ["AKT-10082"],
  },
  {
    primary_id: "D0U5CE",
    names: ["Capsaicin", "Capsaicin (Transdermal Patch Formulation, Neuropathic Pain)"],
  },
  {
    primary_id: "D0U5CK",
    names: ["PMID27376512-Compound-MTC-433"],
  },
  {
    primary_id: "D0U5CQ",
    names: ["PMID25666693-Compound-54"],
  },
  {
    primary_id: "D0U5CS",
    names: [
      "LY354740",
      "Eglumetad",
      "Eglumegad",
      "176199-48-7",
      "(1S,2S,5R,6S)-2-Aminobicyclo[310]hexane-2,6-Dicarboxylic Acid",
      "Eglumegad [INN]",
      "UNII-ONU5A67T2S",
      "LY-354740",
      "LY 354740",
      "ONU5A67T2S",
      "CHEMBL8759",
      "Eglumegad (INN)",
      "(1S,2S,5R,6S)-2-Aminobicyclo(310)Hexane-2,6-Dicarboxylic Acid",
      "(1R,4S,5S,6S)-4-Aminobicyclo[310]hexane-4,6-Dicarboxylic Acid",
      "(1S,2S,5R,6S)-2-Amino-Bicyclo[310]hexane-2,6-Dicarboxylic Acid",
      "[3H]Eglumegad",
      "LY366563",
      "Eglumetad [USAN:INN]",
      "(+)-2-Aminobicyclo(310)Hexane-2,6-Dicarboxylic Acid",
      "40F",
      "PubChem18158",
      "Eglumegad [USAN]",
      "Eglumegad Hydrate",
      "Eglumetad Hydrate",
      "LY 314582",
      "LY 366563",
      "LY314582",
      "Eglumegad (USAN)",
      "LY-314582",
      "LY-354740 Monohydrate",
      "LY-366563",
      "Eglumetad (INN/USAN)",
      "(1R,4S,5S,6S)-4-Aminobicyclo[310]hexane-4,6-Dicarboxylic Acid Hydrate",
      "(1S,2S,5R,6S)-2-Aminobicyclo(310)Hexane-2,6-Dicarboxylic Acid Monohydrate",
      "2-Aminobicyclo(310)Hexane-2,6-Dicarboxylic Acid",
      "4-Aminobicyclo[310]hexane-4,6-Dicarboxylic Acid",
      "[3H]eglumegad",
    ],
  },
  {
    primary_id: "D0U5DH",
    names: ["(+/-)-Threo-N-(4-Chlorobenzyl)Methylphenidate", "CHEMBL1253840"],
  },
  {
    primary_id: "D0U5EU",
    names: [
      "SC-45662",
      "CHEMBL84566",
      "UNII-TPG7IO601I Component ADVNUIUADIXWMI-QWHCGFSZSA-N",
      "2-[[(1S,2R)-2-[[3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl]thio]-1-Methylpropyl]oxy]acetic Acid",
    ],
  },
  {
    primary_id: "D0U5EY",
    names: ["Org-37684"],
  },
  {
    primary_id: "D0U5FF",
    names: ["Leuprolide"],
  },
  {
    primary_id: "D0U5FS",
    names: ["Fluspirilene"],
  },
  {
    primary_id: "D0U5FZ",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 7"],
  },
  {
    primary_id: "D0U5GB",
    names: [
      "Lopinavir",
      "Aluviran",
      "Koletra",
      "LPV",
      "A 157378",
      "ABT 157378",
      "ABT 378",
      "AIDS032937",
      "A 157378.0",
      "ABT-378",
      "Aluvia (TN)",
      "Kaletra (TN)",
      "RS-346",
      "A-157378-0",
      "A-157378.0",
      "ABT-378, LOPINAVIR",
      "Lopinavir [USAN:INN:BAN]",
      "Lopinavir (JAN/USAN/INN)",
      "N-(4-(((2,6-Dimethylphenoxy)Acetyl)Amino)-3-Hydroxy-5-Phenyl-1-(Phenylmethyl)Pentyl)Tetrahydro-Alpha-(1-Methylethyl)-2-Oxo-1(2H)-Pydrimidineacetamide",
      "N-{1-BENZYL-4-[2-(2,6-DIMETHYL-PHENOXY)-ACETYLAMINO]-3-HYDROXY-5-PHENYL-PENTYL}-3-METHYL-2-(2-OXO-TETRAHYDRO-PYRIMIDIN-1-YL)-BUTYRAMIDE",
      "(1S-(1R*(R*),3R*,4R*))-N-(4-(((2,6-Dimethylphenoxy)Acetyl)Amino)-3-Hydroxy-5-Phenyl-1-(Phenylmethyl)Pentyl)Tetrahydro-Alpha-(1-Methylethyl)-2-Oxo-1(2H)-Pyrimidineacetamide",
      "(2S)-N-[(2S,4S,5S)-5-[[2-(2,6-Dimethylphenoxy)Acetyl]amino]-4-Hydroxy-1,6-Diphenylhexan-2-Yl]-3-Methyl-2-(2-Oxo-1,3-Diazinan-1-Yl)Butanamide",
      "(AlphaS)-Tetrahydro-N-((AlphaS)-Alpha-((2S,3S)-2-Hydroxy-4-Phenyl-3-(2-(2,6-Xylyloxy)Acetamido)Butyl)Phenethyl)-Alpha-Isopropyl-2-Oxo-1(2H)-Pyrimidineacetamide",
      "1(2H)-Pyrimidineacetamide, N-[(1S,3S,4S)-4-[[(2,6-Dimethylphenoxy)Acetyl]amino]-3-Hydroxy-5-Phenyl-1-(Phenylmethyl)Pentyl]tetrahydro-Alpha-(1-Methylethyl)-2-Oxo-, (AlphaS)-(9CI)",
    ],
  },
  {
    primary_id: "D0U5GF",
    names: [
      "2-(1-Imidazol-1-Yl-Ethyl)-9H-Carbazole",
      "CHEMBL499882",
      "SCHEMBL9020993",
      "VCFYTQOGKYSBGI-UHFFFAOYSA-N",
      "BDBM50272211",
      "YM 55208",
      "2-[1-(1H-Imidazol-1-Yl) Ethyl]carbazole",
    ],
  },
  {
    primary_id: "D0U5GI",
    names: ["RP-66784"],
  },
  {
    primary_id: "D0U5GU",
    names: ["PMID26815044-Compound-125"],
  },
  {
    primary_id: "D0U5GX",
    names: ["CXB-724", "Ayrene"],
  },
  {
    primary_id: "D0U5GZ",
    names: ["Zadaxin/Transarterial Chemoembolization(TACE)(Cisplatin)"],
  },
  {
    primary_id: "D0U5HB",
    names: ["Thiazole Carboxamide Derivative 5"],
  },
  {
    primary_id: "D0U5HC",
    names: ["L-Leucinol"],
  },
  {
    primary_id: "D0U5HG",
    names: ["Alpha-2c Adrenergic Receptor Antagonist"],
  },
  {
    primary_id: "D0U5HQ",
    names: ["[2-(6-Methoxy-Indol-1-Yl)-Ethyl]-Dimethyl-Amine", "CHEMBL38742", "BDBM50108320", "ZINC13586848"],
  },
  {
    primary_id: "D0U5HS",
    names: ["L748328"],
  },
  {
    primary_id: "D0U5HU",
    names: [
      "Cisplatin",
      "Abiplatin",
      "Biocisplatinum",
      "Briplatin",
      "Cismaplat",
      "Cisplatine",
      "Cisplatino",
      "Cisplatinum",
      "Cisplatyl",
      "Citoplationo",
      "Lederplatin",
      "Neoplatin",
      "Plastin",
      "Platamine",
      "Platidiam",
      "Platinoxan",
      "Randa",
      "Cis-DDP",
      "Cis-Diamminedichloroplatinum",
      "Peyrone's Chloride",
      "Peyrone's Salt",
      "Cis-Dichlorodiammineplatinum(II)",
      "Cis-[PtCl2(NH3)2]",
      "Cis-Diamminedichloridoplatinum(II)",
      "Trans-Diamminedichloridoplatinum(II)",
      "(SP-4-1)-Diamminedichloridoplatinum",
      "(SP-4-1)-Diamminedichloroplatinum",
      "(SP-4-2)-Diamminedichloridoplatinum",
      "(SP-4-2)-Diamminedichloroplatinum",
      "Cisplatin (Chemotherapy)",
    ],
  },
  {
    primary_id: "D0U5HX",
    names: ["CHLOROCYCLINONE D", "CHEMBL400132", "BDBM50228361"],
  },
  {
    primary_id: "D0U5HY",
    names: ["Anti-TAG-72 Antibody"],
  },
  {
    primary_id: "D0U5JJ",
    names: ["KT-105"],
  },
  {
    primary_id: "D0U5JP",
    names: ["MEM-3454"],
  },
  {
    primary_id: "D0U5JR",
    names: [
      "H7N3 Avian Influenza Virus Live Attenuated Vaccine",
      "Attenuated Vaccine",
      "H7N3 Avian Influenza Virus Live Attenuated Vaccine (Intranasal)",
    ],
  },
  {
    primary_id: "D0U5KF",
    names: ["RU79073"],
  },
  {
    primary_id: "D0U5KM",
    names: [
      "Ac-His-DPhe-Arg-Trp-NH2",
      "Ac-His-Phe-Arg-Trp-NH2",
      "CHEMBL50056",
      "Ac-His-Phe-Arg-Trp-NH(2)",
      "L-Tryptophanamide, N-Acetyl-L-Histidyl-L-Phenylalanyl-L-Arginyl-",
      "Ac-His-D-Phe-Arg-D-Trp-NH2",
      "CTK0D4815",
      "2-{2-[2-Acetylamino-3-(3H-Imidazol-4-Yl)-Propionylamino]-3-Phenyl-Propionylamino}-5-Guanidino-Pentanoic Acid [1-Carbamoyl-2-(1H-Indol-3-Yl)-Ethyl]-Amide",
      "110392-38-6",
      "BDBM50034910",
      "(S)-2-{(S)-2-[(S)-2-Acetylamino-3-(3H-Imidazol-4-Yl)-Propionylamino]-3-Phenyl-Propionylamino}-5-Guanidino-Pentanoic Acid [(S)-1-Carbamoyl-2-(1H-Indol-3-Yl)-Ethyl]-Amide",
    ],
  },
  {
    primary_id: "D0U5LL",
    names: [
      "Cannabinol",
      "521-35-7",
      "Cannabinolo [DCIT]",
      "Cannabinolum [INN-Latin]",
      "Cannabinol [INN:BAN]",
      "UNII-7UYP6MC9GH",
      "6H-Dibenzo(B,d)Pyran-1-Ol, 6,6,9-Trimethyl-3-Pentyl-",
      "NSC 134455",
      "3-Amyl-1-Hydroxy-6,6,9-Trimethyl-6H-Dibenzo(B,d)Pyran",
      "7UYP6MC9GH",
      "BRN 0237145",
      "6,6,9-Trimethyl-3-Pentyl-6H-Dibenzo(B,d)Pyran-1-Ol",
      "CHEMBL74415",
      "CHEBI:3360",
      "VBGLYOIFKLUMQG-UHFFFAOYSA-N",
      "6,6,9-Trimethyl-3-Pentylbenzo[c]chromen-1-Ol",
      "6,6,9-Trimethyl-3-Pentyl-6H-Dibenzo[b,d]pyran-1-Ol",
      "6H-Dibenzo[b,d]pyran-1-Ol, 6,6,9-Trimethyl-3-Pentyl-",
    ],
  },
  {
    primary_id: "D0U5ML",
    names: [
      "A-740003",
      "861393-28-4",
      "A 740003",
      "N-(1-(((Cyanoamino)(5-Quinolinylimino)Methyl)Amino)-2,2-Dimethylpropyl)-3,4-Dimethoxybenzeneacetamide",
      "(E)-N-(1-((Cyanamido(Quinolin-5-Ylamino)Methylene)Amino)-2,2-Dimethylpropyl)-2-(3,4-Dimethoxyphenyl)Acetamide",
      "CHEMBL255787",
      "SCHEMBL3357263",
      "MolPort-044-567-676",
      "EX-A1789",
      "AKOS022184697",
      "RL05324",
      "CS-0297",
      "Benzeneacetamide, N-(1-(((Cyanoamino)(5-Quinolinylamino)Methylene)Amino)-2,2-Dimethylpropyl)-3,4-Dimethoxy-",
      "HY-50697",
      "Benzeneacetamide, N-(1-(((Cyanoamino)(5-Quinolinylimino)Methyl)Ami",
    ],
  },
  {
    primary_id: "D0U5NA",
    names: ["RP-69758"],
  },
  {
    primary_id: "D0U5NO",
    names: ["CAR-CD19 T Cell"],
  },
  {
    primary_id: "D0U5NU",
    names: ["GLENVASTATIN"],
  },
  {
    primary_id: "D0U5NW",
    names: [
      "BVT-24834",
      "BVT-1960",
      "Fatty Acid-Binding Protein 4 Inhibitors, Biovitrum",
      "FABP-4 Inhibitors (Obesity), Biovitrum",
    ],
  },
  {
    primary_id: "D0U5NY",
    names: ["JTT-553", "DGAT1 Inhibitor (Oral, Obesity) Japan Tobacco"],
  },
  {
    primary_id: "D0U5OB",
    names: [
      "Charybdotoxin",
      "ChTX",
      "CTX Toxin",
      "Quinquestriatus Toxin",
      "Charybdotoxin (Reduced)",
      "Toxin, CTX",
      "Toxin, Quinquestriatus",
    ],
  },
  {
    primary_id: "D0U5OE",
    names: [
      "Loxapine",
      "Cloxazepine",
      "Dibenzacepin",
      "Dibenzoazepine",
      "Lossapina",
      "Loxapin",
      "Loxapina",
      "Loxapinsuccinate",
      "Loxapinum",
      "Loxepine",
      "Oxilapine",
      "Lossapina [DCIT]",
      "Loxitane IM",
      "CL 62362",
      "HF 3170",
      "HF3170",
      "Hydrofluoride 3170",
      "LW 3170",
      "S 805",
      "SUM 3170",
      "CL-62362",
      "Loxapac (TN)",
      "Loxapina [INN-Spanish]",
      "Loxapinum [INN-Latin]",
      "Loxitane (TN)",
      "S-805;SUM-3170",
      "CL-71,563",
      "Loxapine (USAN/INN)",
      "Loxapine [USAN:BAN:INN]",
      "Loxapine [USAN:INN:BAN]",
      "2-Chloro-11-(4-Methyl-1-Piperazinyl)-Dibenz(B,f)(1,4)Oxazepine",
      "2-Chloro-11-(4-Methyl-1-Piperazinyl)Dibenz(B,f)(1,4)Oxazepine",
      "2-Chloro-11-(4-Methylpiperazino)Dibenzo(B,f)(1,4)Oxazepine",
      "2-Chloro-11-(4-Methylpiperazin-1-Yl)Dibenzo[b,f][1,4]oxazepine",
      "8-Chloro-6-(4-Methylpiperazin-1-Yl)Benzo[b][1,4]benzoxazepine",
      "8-Chloro-6-(4-Methylpiperazin-1-Yl)Benzo[b][1,5]benzoxazepine",
    ],
  },
  {
    primary_id: "D0U5ON",
    names: ["PMID25666693-Compound-125"],
  },
  {
    primary_id: "D0U5OP",
    names: ["1-[3-(3-Biphenyl)-(1,2,4-Triazol-4-Yl) ]-3-Phenol", "CHEMBL212743", "SCHEMBL14506371"],
  },
  {
    primary_id: "D0U5OU",
    names: [
      "Naproxcinod",
      "Beprana",
      "Nitronaproxen",
      "AR-P900758XX",
      "AZD-3582",
      "HCT-3012",
      "Nitronaproxen, NicOx",
      "NO-Naproxen, AstraZeneca",
      "NO-Naproxen, NicOx",
      "NO-NSAID, NicOx/AstraZeneca",
      "Nitric Oxide-Donating Derivative Of Naproxen (Oral, Osteoarthritis), NicOx",
    ],
  },
  {
    primary_id: "D0U5PC",
    names: ["4-(4-Chloro-2-Cyclohexylphenoxy)Butanoic Acid", "CHEMBL394496", "BDBM50213928"],
  },
  {
    primary_id: "D0U5PR",
    names: ["Seridopidine", "ACR-343"],
  },
  {
    primary_id: "D0U5PS",
    names: ["ISIS 101986"],
  },
  {
    primary_id: "D0U5PT",
    names: ["GS-9901"],
  },
  {
    primary_id: "D0U5QK",
    names: [
      "Acetaminophen",
      "4-Acetamidophenol",
      "Paracetamol",
      "103-90-2",
      "Tylenol",
      "N-(4-Hydroxyphenyl)Acetamide",
      "APAP",
      "Acetaminofen",
      "Panadol",
      "Datril",
      "P-Hydroxyacetanilide",
      "N-Acetyl-P-Aminophenol",
      "P-Acetamidophenol",
      "Algotropyl",
      "Naprinol",
      "Lonarid",
      "4'-Hydroxyacetanilide",
      "Multin",
      "Acenol",
      "Acamol",
      "Anelix",
      "P-Acetaminophenol",
      "Liquagesic",
      "Acetagesic",
      "Gelocatil",
      "Servigesic",
      "Acetalgin",
      "Abensanil",
      "Pyrinazine",
      "Injectapap",
      "Clixodyne",
      "Valgesic",
      "Tussapap",
      "Finimal",
      "Paracet",
      "Homoolan",
      "Febrolin",
      "Febrilix",
      "Febridol",
      "Dymadon",
      "Anaflon",
      "Apamide",
      "Valadol",
      "Tralgon",
      "Tabalgin",
      "Lestemp",
      "Alvedon",
      "Abenol",
      "Abrol",
      "Abrolet",
      "Acephen",
      "Acertol",
      "Acetaco",
      "Acetamol",
      "Acetavance",
      "Acetofen",
      "Actamin",
      "Actimol",
      "Afebrin",
      "Afebryl",
      "Aferadol",
      "Algesidal",
      "Algina",
      "Algomol",
      "Alpiny",
      "Alpinyl",
      "Amadil",
      "Aminofen",
      "Analter",
      "Anapap",
      "Andox",
      "Anhiba",
      "Antidol",
      "Anuphen",
      "Apacet",
      "Apadon",
      "Apitrelal",
      "Arfen",
      "Arthralgen",
      "Asetam",
      "Asomal",
      "Aspac",
      "Asplin",
      "Atasol",
      "Atralidon",
      "Babikan",
      "Bacetamol",
      "Banesin",
      "Benmyo",
      "Biocetamol",
      "Cadafen",
      "Calapol",
      "Calmanticold",
      "Calonal",
      "Calpol",
      "Capital",
      "Captin",
      "Causalon",
      "Cefalex",
      "Cetadol",
      "Codabrol",
      "Codalgin",
      "Codapane",
      "Codicet",
      "Codisal",
      "Codoliprane",
      "Cofamol",
      "Conacetol",
      "Cosutone",
      "Cuponol",
      "Curadon",
      "Curpol",
      "Custodial",
      "Dafalgan",
      "Darocet",
      "Darvocet",
      "Daygrip",
      "Deminofen",
      "Democyl",
      "Demogripal",
      "Desfebre",
      "Dhamol",
      "Dimindol",
      "Dirox",
      "Disprol",
      "Dolcor",
      "Dolefin",
      "Dolegrippin",
      "Dolgesic",
      "Doliprane",
      "Dolko",
      "Dolofugin",
      "Doloreduct",
      "Dolorfug",
      "Dolorstop",
      "Dolotec",
      "Dolprone",
      "Dorocoff",
      "Dresan",
      "Dristancito",
      "Duaneo",
      "Dularin",
      "Duorol",
      "Duracetamol",
      "Durapan",
      "Dypap",
      "Ecosetol",
      "Elixodyne",
      "Empracet",
      "Enelfa",
      "Eneril",
      "Excipain",
      "Exdol",
      "Fanalgic",
      "Farmadol",
      "Febranine",
      "Febrectal",
      "Febrectol",
      "Febrex",
      "Febricet",
      "Febrin",
      "Febrinol",
      "Fendon",
      "Fensum",
      "Fepanil",
      "Fevor",
      "Finiweh",
      "Fluparmol",
      "Geluprane",
      "Genapap",
      "Genebs",
      "Grippostad",
      "Gynospasmine",
      "Hedex",
      "Ildamol",
      "Inalgex",
      "Infadrops",
      "Janupap",
      "Kataprin",
      "Korum",
      "Labamol",
      "Lekadol",
      "Lemgrip",
      "Lemsip",
      "Liqiprine",
      "Lupocet",
      "Lyteca",
      "Magnidol",
      "Malgis",
      "Malidens",
      "Maxadol",
      "Medocodene",
      "Mexalen",
      "Minafen",
      "Minoset",
      "Miralgin",
      "Momentum",
      "NEBS",
      "Napafen",
      "Nealgyl",
      "NeoCitran",
      "Neodol",
      "Neodolito",
      "Neopap",
      "Neotrend",
      "Neuridon",
      "NilnOcen",
      "Nina",
      "Nobedon",
      "Nodolex",
      "Noral",
      "Ofirmev",
      "Oltyl",
      "Oralgan",
      "Ortensan",
      "Oxycocet",
      "Paceco",
      "Pacemo",
      "Pacemol",
      "Pacet",
      "Pacimol",
      "Paedialgon",
      "Paedol",
      "Painex",
      "Paldesic",
      "Pamol",
      "Panacete",
      "Panadeine",
      "Panadiene",
      "Panaleve",
      "Panamax",
      "Panasorb",
      "Panasorbe",
      "Panets",
      "Panex",
      "Panodil",
      "Panofen",
      "Pantalgin",
      "Paracemol",
      "Paracenol",
      "Paracetamole",
      "Paracetamolo",
      "Paracetanol",
      "Paracetol",
      "Paracin",
      "Paracod",
      "Paracodol",
      "Parador",
      "Paradrops",
      "Parakapton",
      "Parake",
      "Paralen",
      "Paralief",
      "Paralink",
      "Paralyoc",
      "Paramol",
      "Paramolan",
      "Paranox",
      "Parapan",
      "Parasedol",
      "Parasin",
      "Paraspen",
      "Parcetol",
      "Parelan",
      "Parmol",
      "Parogal",
      "Paroma",
      "Pasolind",
      "Pediapirin",
      "Pediatrix",
      "Pedric",
      "Perfalgan",
      "Phendon",
      "Phenipirin",
      "Phogoglandin",
      "Pinex",
      "Piramin",
      "Pirinasol",
      "Plicet",
      "Polmofen",
      "Predimol",
      "Predualito",
      "Prodol",
      "Prompt",
      "Prontina",
      "Puernol",
      "Pulmofen",
      "Pyrigesic",
      "Pyromed",
      "Redutemp",
      "Reliv",
      "Remedol",
      "Rivalgyl",
      "Robigesic",
      "Rounox",
      "RubieMol",
      "Rubophen",
      "Rupemol",
      "Salzone",
      "Sanicet",
      "Sanicopyrine",
      "Scanol",
      "Scentalgyl",
      "Schmerzex",
      "Sedalito",
      "Semolacin",
      "Seskamol",
      "Setakop",
      "Setamol",
      "Setol",
      "Sifenol",
      "Sinaspril",
      "Sinedol",
      "Sinmol",
      "Stanback",
      "Stopain",
      "Sunetheton",
      "Supofen",
      "Suppap",
      "TYL",
      "Tachiprina",
      "Tapanol",
      "Tapar",
      "Tazamol",
      "Temlo",
      "Tempanal",
      "Tempra",
      "Termacet",
      "Termalgin",
      "Termalgine",
      "Termofren",
      "Tiffy",
      "Titralgan",
      "Treuphadol",
      "Tricoton",
      "Tylex",
      "Tylol",
      "Tymol",
      "Upsanol",
      "Utragin",
      "Valorin",
      "Veralgina",
      "Vermidon",
      "Verpol",
      "Vips",
      "Viruflu",
      "Vivimed",
      "Volpan",
      "Zatinol",
      "Zolben",
      "Aceta Elixir",
      "Actifed Plus",
      "Aspirin Free Anacin",
      "Bayer Select",
      "D Oliprane",
      "Dymadon Co",
      "Fortalidon P",
      "Gattaphen T",
      "Gripin Bebe",
      "Helon N",
      "Influbene N",
      "Jin Gang",
      "Lonarid Mono",
      "Lyteca Syrup",
      "Malex N",
      "Panadeine Co",
      "Panale Ve",
      "Pasolind N",
      "Spalt N",
      "Supadol Mono",
      "Toximer P",
      "Treupel N",
      "Treupel Mon",
      "Ty Lenol",
      "Tylex CD",
      "Anacin 3",
      "A-Per",
      "Accu-Tap",
      "Ultracet",
    ],
  },
  {
    primary_id: "D0U5QL",
    names: ["Grandisine D"],
  },
  {
    primary_id: "D0U5RT",
    names: [
      "Primidone",
      "Cyral",
      "Desoxyphenobarbital",
      "Desoxyphenobarbitone",
      "Hexadiona",
      "Lepimidin",
      "Lepsiral",
      "Liskantin",
      "Majsolin",
      "Midone",
      "Milepsin",
      "Misodine",
      "Misolyne",
      "Mizodin",
      "Mizolin",
      "Myidone",
      "Mylepsin",
      "Mylepsinum",
      "Mysedon",
      "Mysoline",
      "Neurosyn",
      "Prilepsin",
      "Primacione",
      "Primaclone",
      "Primacone",
      "Primakton",
      "Primidon",
      "Primidona",
      "Primidonum",
      "Primoline",
      "Prysoline",
      "Resimatil",
      "Sertan",
      "Apotex Brand Of Primidone",
      "Astra Brand Of Primidone",
      "AstraZeneca Brand Of Primidone",
      "Desitin Brand Of Primidone",
      "Draxis Brand Of Primidone",
      "Holsten Brand Of Primidone",
      "Pms Primidone",
      "Primidon Holsten",
      "Primidone Methanol Solution",
      "Xcel Brand Of Primidone",
      "Zeneca Brand Of Primidone",
      "P 7295",
      "Roe 101",
      "Apo-Primidone",
      "Hexamidine (The Antispasmodic)",
      "Hexamidine(The Antispasmodic)",
      "Liskantin (TN)",
      "Medi-Pets",
      "Mylepsinum (TN)",
      "Mysoline (TN)",
      "Primidona [INN-Spanish]",
      "Primidone (Primaclone)",
      "Primidonum [INN-Latin]",
      "Prysoline (TN)",
      "Pyrimidone Medi-Pets",
      "Resimatil (TN)",
      "Sanofi-Synthelabo Brand Of Primidone",
      "Sertan (TN)",
      "Apo-Primidone (TN)",
      "Pyrimidone (Medi-Pets)",
      "Primidone (JP15/USP/INN)",
      "Primidone [USAN:INN:BAN:JAN]",
      "2-Deoxyphenobarbital",
      "2-Desoxyphenobarbital",
      "4,4'-(Hexamethylenedioxy)Dibenzamidine",
      "5-Aethyl-5-Phenyl-Hexahydropyrimidin-4,6-Dion",
      "5-Aethyl-5-Phenyl-Hexahydropyrimidin-4,6-Dion [German]",
      "5-Ethyl-5-Phenylhexahydropyrimidine-4,6-Dione",
      "5-Ethyldihydro-5-Phenyl-4,6(1H,5H)-Pyrimidinedione",
      "5-Ethylhexahydro-4,6-Dioxo-5-Phenylpyrimidine",
      "5-Ethylhexahydro-5-Phenylpyrimidine-4,6-Dione",
      "5-Phenyl-5-Aethylhexahydropyrimidindion-(4,6)",
      "5-Phenyl-5-Aethylhexahydropyrimidindion-(4,6) [German]",
      "5-Phenyl-5-Ethyl-Hexahydropyrimidine-4,6-Dione",
      "5-Ethyl-5-Phenyl-1,3-Diazinane-4,6-Dione",
      "5-Ethyl-5-Phenyldihydropyrimidine-4,6(1H,5H)-Dione",
      "5-Ethylhe Xahydro-4,6-Dioxo-5-Phenylpyrimidine",
    ],
  },
  {
    primary_id: "D0U5SC",
    names: ["CT-102"],
  },
  {
    primary_id: "D0U5SI",
    names: [
      "Procainamide",
      "Biocoryl",
      "Novocainamid",
      "Novocainamide",
      "Novocamid",
      "Procainamida",
      "Procainamidum",
      "Procamide",
      "Procan",
      "Procanbid",
      "Procapan",
      "Pronestyl",
      "Novocaine Amide",
      "Procaine Amide",
      "P-Aminobenzoic Diethylaminoethylamide",
      "Procainamida [INN-Spanish]",
      "Procainamide (INN)",
      "Procainamide [INN:BAN]",
      "Procainamidum [INN-Latin]",
      "Procan (TN)",
      "Procanbid (TN)",
      "Procapan (Free Base)",
      "Pronestyl (TN)",
      "Pronestyl-Sr",
      "P-Amino-N-(2-Diethylaminoethyl)Benzamide",
      "Benzamide, 4-Amino-N-(2-(Diethylamino)Ethyl)-(9CI)",
      "2-Diethylaminoethylamid Kyseliny P-Aminobenzoove",
      "2-Diethylaminoethylamid Kyseliny P-Aminobenzoove [Czech]",
      "4-Amino-N-(2-(Diethylamino)Ethyl)Benzamide Sulfate",
      "4-Amino-N-(2-(Diethylamino)Ethyl)Benzamide",
      "4-Amino-N-[2-(Diethylamino)Ethyl]benzamide",
      "4-Amino-N-(2-Diethylaminoethyl)-Benzamide",
      "4-Amino-N-(2-Diethylaminoethyl)Benzamide",
    ],
  },
  {
    primary_id: "D0U5SU",
    names: ["FM-301"],
  },
  {
    primary_id: "D0U5TK",
    names: ["BIBO3304"],
  },
  {
    primary_id: "D0U5TP",
    names: ["20S-Hydroxycholesterol", "20S-OHC", "SCHEMBL670743", "GTPL3434"],
  },
  {
    primary_id: "D0U5TX",
    names: ["PMID25980951-Compound-13"],
  },
  {
    primary_id: "D0U5UC",
    names: ["KD025"],
  },
  {
    primary_id: "D0U5US",
    names: [
      "Dodecyl-Phosphonic Acid",
      "Dodecylphosphonic Acid",
      "N-Dodecylphosphonic Acid",
      "5137-70-2",
      "Phosphonic Acid, Dodecyl-",
      "N-Dodecanephosphonic Acid",
      "Dodecane Phosphonic Acid",
      "1-Dodecanephosphonic Acid",
      "N-Dodecyl Phosphonic Acid",
      "Phosphonic Acid, P-Dodecyl-",
      "1-Dodecylphosphonic Acid",
      "EINECS 225-897-8",
      "NSC 407872",
      "BRN 1778341",
      "CHEMBL363067",
      "N-DODECYLPHOSPHONICACID",
      "AC1L2W0V",
      "12",
      "SCHEMBL194178",
      "DTXSID2063715",
      "CTK3J3207",
      "MolPort-004-285-423",
      "KS-00000W0I",
      "ZINC1600143",
      "MFCD00015832",
      "BDBM50170845",
      "SBB057480",
      "NSC407872",
      "AKOS015899842",
    ],
  },
  {
    primary_id: "D0U5UY",
    names: ["PMID30247903-Compound-General Structure18"],
  },
  {
    primary_id: "D0U5VM",
    names: ["HBN-1"],
  },
  {
    primary_id: "D0U5VX",
    names: ["Human AM/AMBP-1"],
  },
  {
    primary_id: "D0U5VZ",
    names: [
      "Bepotastine",
      "Betotastine",
      "Tau 284",
      "TAU-284DS",
      "Talion (TN)",
      "(S)-4-(4-((4-Chlorophenyl)(2-Pyridil)Methoxy)Piperidino)Butylic Acid",
      "4-((4-Chlorophenyl)-2-Pyridinylmethoxy)-1-Piperidinebutanoic Acid",
      "4-[4-[(4-Chlorophenyl)-Pyridin-2-Ylmethoxy]piperidin-1-Yl]butanoic Acid",
    ],
  },
  {
    primary_id: "D0U5WB",
    names: [
      "Alpha-Type-1 Polarized Dendritic Cells",
      "Alpha DC1 Cells (CLL), University Of Pittsburgh",
      "Alpha-Type-1 Polarized Dendritic Cells (Chronic Lymphocytic Leukemia)",
      "Alpha-Type-1 Polarized Dendritic Cells (Chronic Lymphocytic Leukemia), University Of Pittsburgh",
    ],
  },
  {
    primary_id: "D0U5WS",
    names: ["MRNA-4157"],
  },
  {
    primary_id: "D0U5WY",
    names: ["2-(3-Piperidin-1-Yl-Propyl)-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0U5XI",
    names: ["PMID28270021-Compound-WO2015042088Example11"],
  },
  {
    primary_id: "D0U5YJ",
    names: ["14F7 Humanized Mab, The Center Of Molecular Immunology"],
  },
  {
    primary_id: "D0U5YM",
    names: ["ONO-6950"],
  },
  {
    primary_id: "D0U5YT",
    names: ["(S)-3HPG", "3-Hydroxyphenylglycine"],
  },
  {
    primary_id: "D0U5YW",
    names: ["Travoprost Punctal Plug"],
  },
  {
    primary_id: "D0U5ZV",
    names: ["AT-3375"],
  },
  {
    primary_id: "D0U6AI",
    names: ["I-A09"],
  },
  {
    primary_id: "D0U6AL",
    names: ["BI-L-357", "BI-L-226"],
  },
  {
    primary_id: "D0U6AW",
    names: ["1-(Biphenyl-4-Yl-Methyl)-1H-Imidazole Derivative 1"],
  },
  {
    primary_id: "D0U6BB",
    names: [
      "L-703014",
      "CHEMBL325687",
      "BDBM50092114",
      "4-(1H-Indol-3-Yl)-2-[2-(4-Piperidin-4-Yl-Butyrylamino)-Acetylamino]-Butyric Acid (L-703014)",
    ],
  },
  {
    primary_id: "D0U6BJ",
    names: ["Desoxyribonuclease"],
  },
  {
    primary_id: "D0U6BQ",
    names: ["Anti-VEGFR2 CD8 Cell Therapy", "Anti-VEGFR2 CD8 Cell Therapy (Cancer)"],
  },
  {
    primary_id: "D0U6BT",
    names: ["Rolicyclidine"],
  },
  {
    primary_id: "D0U6CL",
    names: ["CC-10015"],
  },
  {
    primary_id: "D0U6CU",
    names: ["Sulfonamide Derivative 9"],
  },
  {
    primary_id: "D0U6DC",
    names: ["PF-4967544"],
  },
  {
    primary_id: "D0U6DZ",
    names: [
      "L-Erythro-3-Hydroxyaspartate",
      "(3R)-3-Hydroxy-L-Aspartate",
      "Erythro-3-Hydroxy-L-Aspartate",
      "L-Erythro-Beta-Hydroxyaspartate",
      "Erythro-3-Hydroxy-Aspartic Acid",
      "CHEBI:58196",
      "(3R)-3-Hydroxy-L-Aspartate Anion",
      "(3R)-3-Hydroxy-L-Aspartate(1-)",
      "ZB015320",
    ],
  },
  {
    primary_id: "D0U6EX",
    names: ["Quadracel"],
  },
  {
    primary_id: "D0U6FH",
    names: [
      "4-Chloro-2,6-Diisopropyl-Phenol",
      "CHEMBL292402",
      "2,6-Diisopropyl-4-Chlorophenol",
      "91561-75-0",
      "4-Chloro-2,6-Diisopropylphenol",
      "SCHEMBL4714270",
      "ZINC13779576",
      "BDBM50064419",
      "DA-01221",
    ],
  },
  {
    primary_id: "D0U6FM",
    names: ["Hex-RYYRIK-NH2", "CHEMBL438383"],
  },
  {
    primary_id: "D0U6FT",
    names: ["GSI-136"],
  },
  {
    primary_id: "D0U6GQ",
    names: ["Anticancer Pro-Soft Drugs", "Anticancer Pro-Soft Drugs (Metastasis)"],
  },
  {
    primary_id: "D0U6HC",
    names: ["ISIS 19217"],
  },
  {
    primary_id: "D0U6IL",
    names: [
      "1-Aminomethyl-Isochroman-5,6-Diol",
      "CHEMBL99361",
      "SCHEMBL8726506",
      "1H-2-Benzopyran-5,6-Diol, 1-(Aminomethyl)-3,4-Dihydro-",
      "BDBM50010891",
      "83694-56-8",
      "1-Aminomethyl-3,4-Dihydro-1H-2-Benzopyran-5,6-Diol",
    ],
  },
  {
    primary_id: "D0U6IT",
    names: [
      "5-[5-Methoxy-Indan-(1Z)-Ylidenemethyl]-Thiazole",
      "CHEMBL195354",
      "BDBM8633",
      "AC1O706T",
      "(5-Thiazolemethylene)Indane 27b",
      "5-{[(1Z)-5-Methoxy-2,3-Dihydro-1H-Inden-1-Ylidene]methyl}-1,3-Thiazole",
    ],
  },
  {
    primary_id: "D0U6JI",
    names: ["4'-(1-(Pyridin-4-Yl)Propyl)Biphenyl-3-Ol", "CHEMBL1172883", "SCHEMBL18770306", "BDBM50322795"],
  },
  {
    primary_id: "D0U6KE",
    names: [
      "1,6-Bis(4'-Aminophenyl)-Hexa-1,3,5-Triene",
      "CHEMBL208456",
      "SCHEMBL9750157",
      "ZINC13686481",
      "BDBM50185992",
    ],
  },
  {
    primary_id: "D0U6KH",
    names: [
      "Figitumumab",
      "AC1OCENC",
      "(2R)-3-[(4S,6R,7R,10S)-4-[(E,2R)-4-[(2S,2'S,4R,4aS,6R,8aR)-4-Hydroxy-2-[(1S,3S)-1-Hydroxy-3-[(6S,9R,10S)-9-Methyl-5,11-Dioxaspiro[5.5]undecan-10-Yl]butyl]-3-Methylidenespiro[4a,7,8,8a-Tetrahydro-4H-Pyrano[3,2-B]pyran-6,5'-Oxolane]-2'-Yl]but-3-En-2-Yl]-7-Hydroxy-2-Methyl-5,11-Dioxaspiro[5.5]undec-1-En-10-Yl]-2-Hydroxy-2-Methylpropanoic Acid",
    ],
  },
  {
    primary_id: "D0U6LM",
    names: [
      "Midazolam",
      "Dazolam",
      "Dormicum",
      "Midanium",
      "Midazolamum",
      "Midosed",
      "Versed",
      "Midazolam Base",
      "Dormicum (TN)",
      "Hypnovel (TN)",
      "Midacum (TN)",
      "Midazolamum [INN-Latin]",
      "Ro 21-3981",
      "Versed (TN)",
      "Midazolam (JAN/INN)",
      "Midazolam [INN:BAN:JAN]",
      "4H-Imidazo[1,5-A][1,4]benzodiazepine, 8-Chloro-6-(2-Fluoro-Phenyl)-1-Methyl-, (Z)-2-Butenedioate",
      "8-Chlor-6-(2-Fluorphenyl)-1-Methyl-4H-Imidazo(1,5-A)(1,4)Benzodiazepin",
      "8-Chloro-6-(2-Fluorophenyl)-1-Methyl-4H-Imidazo[1,5-A][1,4]benzodiazepine",
      "8-Chloro-6-(2-Fluorophenyl)-1-Methyl-4H-Imidazo[1,5a][1,4]benzodiazepine Hydrochloride",
      "8-Chloro-6-(O-Fluorophenyl)-1-Methyl-4H-Imidazo[1,5-A][1,4]-Benzodiazepine",
      "8-Chloro-6-(O-Fluorophenyl)-1-Methyl-4H-Imidazo(1,5-A)(1,4)Benzodiazepine",
    ],
  },
  {
    primary_id: "D0U6ML",
    names: [
      "CKD602",
      "Belotecan Hydrochloride",
      "213819-48-8",
      "Belotecan",
      "CKD-602",
      "Belotecan HCl",
      "Camtobell Hydrochloride",
      "Belotecan (Hydrochloride)",
      "CKD 602",
      "UNII-01DZ4127G7",
      "01DZ4127G7",
      "Belotecan Hydrochloride (USAN)",
      "Belotecan Hydrochloride [USAN]",
      "CHEMBL2107315",
      "DTXSID60175647",
      "BCP28717",
      "HY-13566A",
      "AKOS027420465",
      "API0009359",
      "CS-6955",
      "(S)-4-Ethyl-4-Hydroxy-11-(2-(Isopropylamino)Ethyl)-1H-Pyrano[3',4':6,7]indolizino[1,2-B]quinoline-3,14(4H,12H)-Dione Hydrochloride",
      "AN-29684",
      "ACM213819488",
      "FT-0662513",
      "D03225",
      "819C488",
      "J-014022",
    ],
  },
  {
    primary_id: "D0U6NC",
    names: ["PJ-016"],
  },
  {
    primary_id: "D0U6NJ",
    names: [
      "4-(2-(1H-Imidazol-1-Yl)Ethoxy)-2H-Chromen-2-One",
      "CHEMBL225178",
      "2H-1-Benzopyran-2-One, 4-[2-(1H-Imidazol-1-Yl)Ethoxy]-",
      "828265-57-2",
      "Coumarin Deriv. 22",
      "BDBM9473",
      "CTK3D6006",
      "DTXSID60462004",
    ],
  },
  {
    primary_id: "D0U6NR",
    names: ["CD4 Red Blood Cell Electroinsertion, Sheffield"],
  },
  {
    primary_id: "D0U6NV",
    names: ["ISIS-1082"],
  },
  {
    primary_id: "D0U6NZ",
    names: [
      "[125I]APNEA",
      "CHEMBL326958",
      "2-[6-[2-(4-Aminophenyl)Ethylamino]purin-9-Yl]-5-(Hydroxymethyl)Oxolane-3,4-Diol",
      "[3H]N6-2-(4-Aminophenyl)Ethyladenosine",
      "AC1L1I2P",
      "AC1Q4Y3F",
      "Lopac0_000118",
      "GTPL417",
      "GTPL462",
      "ChEMBL_198532",
      "SCHEMBL16585344",
      "HMS3260G18",
      "N-[2-(4-Aminophenyl)Ethyl]-9-Pentofuranosyl-9h-Purin-6-Amine",
      "Tox21_500118",
      "BDBM50037785",
      "LP00118",
      "CCG-204213",
      "NCGC00260803-01",
      "NCGC00093612-03",
      "NCGC00015017-04",
      "NCGC00093612-02",
      "NCGC00093612-01",
      "NCGC00015017-05",
      "NCGC00015017-03",
      "NCGC00015017-02",
    ],
  },
  {
    primary_id: "D0U6OA",
    names: ["Tyr-D-Ala-Gly-Trp-Nle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D0U6OY",
    names: ["Piperazinyl Norbenzomorphane Compound 2"],
  },
  {
    primary_id: "D0U6PA",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 9"],
  },
  {
    primary_id: "D0U6PD",
    names: ["Bip-Tyr-Ala-Pro-Lys-Thr(Obzl)-Gly", "CHEMBL277108"],
  },
  {
    primary_id: "D0U6PJ",
    names: ["PC-SOD"],
  },
  {
    primary_id: "D0U6PK",
    names: [
      "ACY-001",
      "Autologous CD133+ HSC (Ischemic Heart Disease) Therapy, Arteriocyte",
      "Autologous CD133+ Hematopoietic Stem Cells (Ischemic Heart Disease) Therapy, Arteriocyte",
    ],
  },
  {
    primary_id: "D0U6PW",
    names: ["Antibodie Derivative 8"],
  },
  {
    primary_id: "D0U6PZ",
    names: ["VS1-011"],
  },
  {
    primary_id: "D0U6QC",
    names: ["Porphobilinogen"],
  },
  {
    primary_id: "D0U6QM",
    names: [
      "1-(4-Iodobenzyl)-1H-Imidazole",
      "4-Iodobenzyl Imidazole",
      "CHEMBL427399",
      "SCHEMBL2393534",
      "MolPort-023-162-403",
      "WZKZRSOWLZKJCL-UHFFFAOYSA-N",
      "1-(4-Iodo-Benzyl)-1H-Imidazole",
      "ZINC34849755",
      "BDBM50188092",
      "AKOS014827972",
      "MCULE-7536799973",
      "1-[(4-Iodophenyl)Methyl]-1H-Imidazole",
      "906478-69-1",
    ],
  },
  {
    primary_id: "D0U6QN",
    names: ["ACH-3102"],
  },
  {
    primary_id: "D0U6RP",
    names: ["AL-072"],
  },
  {
    primary_id: "D0U6SQ",
    names: [
      "5-Propyl-1H-Pyrazole-3-Carboxylic Acid",
      "3-Propyl-1H-Pyrazole-5-Carboxylic Acid",
      "76424-47-0",
      "5-Propyl-2H-Pyrazole-3-Carboxylic Acid",
      "CHEMBL237788",
      "3-N-Propyl-1H-Pyrazole-5-Carboxylic Acid",
      "AC1LDWNQ",
      "BAS 01543590",
      "SCHEMBL1092",
      "AC1Q2V2W",
      "Oprea1_206890",
      "ZINC39600",
      "CTK6D4610",
      "KS-00003OFY",
      "CTK2H6772",
      "DTXSID20350296",
      "MolPort-001-965-385",
      "QYPSYPPSHXDFLV-UHFFFAOYSA-N",
      "MolPort-000-929-573",
      "HMS1697E16",
      "3-Propylpyrazole-5-Carboxylic Acid",
      "BDBM50220850",
      "BBL031087",
      "STL244838",
      "KM1764",
      "9410AE",
      "AKOS000136076",
      "AKOS000646963",
      "MS-2036",
      "MCULE-2411097064",
    ],
  },
  {
    primary_id: "D0U6TM",
    names: ["2-Adamantan-1-Yl-N-Decyl-Acetamide", "CHEMBL192549", "SCHEMBL12931880"],
  },
  {
    primary_id: "D0U6TV",
    names: ["UltraGITRL"],
  },
  {
    primary_id: "D0U6UK",
    names: ["Tabalumab"],
  },
  {
    primary_id: "D0U6WB",
    names: [
      "BPIQ-I",
      "Bpiq-I",
      "174709-30-9",
      "N-(3-Bromophenyl)-3-Methyl-3H-Imidazo[4,5-G]quinazolin-8-Amine",
      "8-[(3-Bromophenyl)Amino]-3-Methyl-3H-Imidazo[4,5-G]-Quinazoline",
      "Imidazoquinazoline Deriv. 12",
      "AC1L1DNH",
      "GTPL5939",
      "CHEMBL174426",
      "BDBM3574",
      "SCHEMBL17931472",
      "CTK8E6742",
      "DTXSID60274366",
      "MolPort-044-561-412",
      "HMS3229C05",
      "ZINC2391775",
      "IN1402",
      "HSCI1_000090",
      "AKOS025394452",
      "CCG-206740",
      "ACM174709309",
      "RT-011807",
      "J-011030",
      "BRD-K72211743-001-01-1",
    ],
  },
  {
    primary_id: "D0U6WC",
    names: ["RM-4864"],
  },
  {
    primary_id: "D0U6WQ",
    names: ["PRALNACASAN"],
  },
  {
    primary_id: "D0U6XE",
    names: ["PAN-90806"],
  },
  {
    primary_id: "D0U6XP",
    names: ["Ac-Nle-C[Asp-His-DNaI(2')-Pro-Trp-Lys]-NH2", "CHEMBL194552", "BDBM50268798"],
  },
  {
    primary_id: "D0U6ZD",
    names: ["L-Homocysteine"],
  },
  {
    primary_id: "D0U7AA",
    names: ["ISIS 109490"],
  },
  {
    primary_id: "D0U7AH",
    names: ["NurOwn"],
  },
  {
    primary_id: "D0U7BH",
    names: ["(+)-Norfenfluramine", "(S)-Norfenfluramine"],
  },
  {
    primary_id: "D0U7BI",
    names: ["SZ(+)-(S)-202-791"],
  },
  {
    primary_id: "D0U7BJ",
    names: ["2-[3-(4-Imidazol-1-Yl-Phenoxy)-Propyl]-Pyridine", "CHEMBL115876", "SCHEMBL14129965", "BDBM50138233"],
  },
  {
    primary_id: "D0U7BQ",
    names: ["O-Tolyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL481688"],
  },
  {
    primary_id: "D0U7BW",
    names: [
      "Carbachol",
      "Carbach",
      "Carbacholin",
      "Carbacholine",
      "Carbacholinum",
      "Carbacholum",
      "Carbacol",
      "Carbacolina",
      "Carbacolo",
      "Carbamiotin",
      "Carbamoylcholine",
      "Carbastat",
      "Carbochol",
      "Carbocholin",
      "Carbocholine",
      "Carbyl",
      "Carcholin",
      "Coletyl",
      "Doryl",
      "Jestryl",
      "Karbachol",
      "Lentin",
      "Lentine",
      "Miostat",
      "Moryl",
      "Rilentol",
      "Vasoperif",
      "CARBACHOL CHLORIDE",
      "Carbachol Hydrochloride",
      "Carbacholine Chloride",
      "Carbacholini Chloridum",
      "Carbacholinium Chloratum",
      "Carbacholum Chloratum",
      "Carbacolo [DCIT]",
      "Carbaminocholine Chloride",
      "Carbaminoylcholine Chloride",
      "Carbamoylcholine Chloride",
      "Carbamylcholine Chloride",
      "Choline Carbamate Chloride",
      "Choline Chloride Carbamate",
      "Choline Chlorine Carbamate",
      "Isopto Carbachol",
      "Karbachol [Czech]",
      "Karbamoylcholin Chlorid",
      "Karbamoylcholin Chlorid [Czech]",
      "Lentine [French]",
      "Mistura C",
      "C 4382",
      "TL 457",
      "C-1770",
      "Carbacholum [INN-Latin]",
      "Carbacol [INN-Spanish]",
      "Carbamic Acid, Ester With Choline Chloride",
      "Carbamoylcholine-Hydrochloride",
      "Carbastat (TN)",
      "Carboptic (TN)",
      "Choline Chloride, Carbamate",
      "Doryl (VAN)",
      "Doryl (Pharmaceutical)",
      "Gamma-Carbamoyl Choline Chloride",
      "Isopto Carbachol (TN)",
      "Miostat (TN)",
      "Carbachol [INN:BAN:JAN]",
      "Choline, Chloride Carbamate(Ester)",
      "Choline, Chloride, Carbamate",
      "P. V. Carbachol",
      "Carbachol (JAN/USP/INN)",
      "Choline, Chloride, Carbamate, Hydrochloride",
      "Ethanaminium, 2-(Aminocarbonyl)Oxy-N,N,N-Trimethyl-, Chloride",
      "Ethanaminium, 2-[(Aminocarbonyl)Oxy]-N,N,N-Trimethyl-, Chloride",
      "Ethanaminium, 2-((Aminocarbonyl)Oxy)-N,N,N-Trimethyl-, Chloride",
      "Ethanaminium, 2-((Aminocarbonyl)Oxy)-N,N,N-Trimethyl-, Chloride (1:1)",
      "(2-Carbamoyloxy-Ethyl)-Trimethyl-Ammonium",
      "(2-Carbamoyloxy-Ethyl)-Trimethyl-Ammonium(Carbachol)",
      "(2-Carbamoyloxyethyl)Trimethylammonium Chloride",
      "(2-Hydroxyethyl)Trimethyl Ammonium Chloride Carbamate",
      "(2-Hydroxyethyl)Trimethylammonium Chloride Carbamate",
      "(Carbachol)(2-Carbamoyloxy-Ethyl)-Trimethyl-Ammonium",
      "2-((Aminocarbonyl)Oxy)-N,N,N-Trimethylethanaminium Chloride",
      "2-((Aminocarbonyl)Oxy)-N,N,N-Trimethylethanaminum Chloride",
      "2-(Carbamoyloxy)-N,N,N-Trimethylethanaminium Chloride",
      "2-[(Aminocarbonyl)Oxy]-N,N,N-Trimethylethanaminium Chloride",
      "2-Carbamoyloxyethyl(Trimethyl)Azanium Chloride",
    ],
  },
  {
    primary_id: "D0U7BY",
    names: ["Oxalic Acid"],
  },
  {
    primary_id: "D0U7CD",
    names: ["Roche 28-2653"],
  },
  {
    primary_id: "D0U7CJ",
    names: ["4-(2,6-Diamino-9H-Purin-8-Yl)-2,6-Dimethoxyphenol"],
  },
  {
    primary_id: "D0U7CO",
    names: ["CRE-319M2"],
  },
  {
    primary_id: "D0U7CP",
    names: ["KX-03"],
  },
  {
    primary_id: "D0U7DI",
    names: ["SM-10661"],
  },
  {
    primary_id: "D0U7DJ",
    names: ["16-(4-Cyano-Benzylidene)-Estradiol"],
  },
  {
    primary_id: "D0U7DO",
    names: [
      "1na",
      "3946/1/8",
      "Methyl 2-Acetamido-2-Deoxy-Beta-D-Glucopyranoside",
      "N-((2R,3R,4R,5S,6R)-4,5-Dihydroxy-6-(Hydroxymethyl)-2-Methoxytetrahydro-2H-Pyran-3-Yl)Acetamide",
      "Alpha-Methyl-N-Acetyl-D-Glucosamine",
      "Beta-Methyl-N-Acetyl-D-Glucosamine",
      "Methyl 2-Acetamido-2-Deoxy-B-D-Glucopyranoside",
      "AC1L9IIR",
      "SCHEMBL1673962",
      "ZEVOCXOZYFLVKN-JGKVKWKGSA-N",
      "MolPort-000-189-911",
      "ZINC5234411",
      "Methyl N-Acetyl-Beta-D-Glucosaminide",
      "AKOS002687842",
      "N-ACETYL-O-METHYL-D-GLUCOSAMINE",
      "DB04426",
      "N-Acetyl-1-O-Methyl-Beta-D-Glucosamine",
    ],
  },
  {
    primary_id: "D0U7EG",
    names: [
      "7-Methyl-2-M-Tolyl-1H-[1,8]naphthyridin-4-One",
      "CHEMBL45620",
      "NSC676188",
      "AC1NV5AM",
      "SCHEMBL7920165",
      "ZINC6491122",
      "BDBM50059974",
      "NSC-676188",
      "NCI60_026915",
      "2-(3-Methylphenyl)-7-Methyl-1,8-Naphthyridin-4(1H)-One",
    ],
  },
  {
    primary_id: "D0U7FE",
    names: ["N-(4-Tert-Butylthiazol-2-Yl)Quinolin-5-Amine", "CHEMBL207857", "SCHEMBL14433246"],
  },
  {
    primary_id: "D0U7GA",
    names: ["Recombinant Human Fibrinogen"],
  },
  {
    primary_id: "D0U7GE",
    names: ["KUR-116"],
  },
  {
    primary_id: "D0U7GK",
    names: [
      "Thalidomide",
      "Algosediv",
      "Asmadion",
      "Asmaval",
      "Bonbrain",
      "Bonbrrin",
      "Calmore",
      "Calmorex",
      "Contergan",
      "Corronarobetin",
      "Distaval",
      "Distaxal",
      "Distoval",
      "Ectiluran",
      "Enterosediv",
      "Gastrinide",
      "Glupan",
      "Glutanon",
      "Grippex",
      "Hippuzon",
      "Imidene",
      "Isomin",
      "Kedavon",
      "Kevadon",
      "Neaufatin",
      "Neosedyn",
      "Neosydyn",
      "Nerosedyn",
      "Neufatin",
      "Neurodyn",
      "Neurosedin",
      "Neurosedym",
      "Neurosedyn",
      "Nevrodyn",
      "Nibrol",
      "Noctosediv",
      "Noxodyn",
      "Pangul",
      "Pantosediv",
      "Polygripan",
      "Profarmil",
      "Psycholiquid",
      "Psychotablets",
      "Quetimid",
      "Quietoplex",
      "Sandormin",
      "Sedalis",
      "Sedimide",
      "Sedin",
      "Sedisperil",
      "Sedoval",
      "Shinnibrol",
      "Sleepan",
      "Slipro",
      "Softenil",
      "Softenon",
      "Synovir",
      "Talargan",
      "Talidomida",
      "Talidomide",
      "Talimol",
      "Talismol",
      "Talizer",
      "Telagan",
      "Telargan",
      "Telargean",
      "Tensival",
      "Thaled",
      "Thalidomidum",
      "Thalin",
      "Thalinette",
      "Thalomid",
      "Thalomide",
      "Theophilcholine",
      "Valgis",
      "Valgraine",
      "Yodomin",
      "Celgene Brand Of Thalidomide",
      "Talidomide [DCIT]",
      "Thalidomide Celgene",
      "Thalidomide Pharmion",
      "Asidon 3",
      "ENMD 0995",
      "IN1061",
      "Thalidomine USP26",
      "Alpha-Phthalimidoglutarimide",
      "E-217",
      "Imida-Lab",
      "Imidan (Peyta)",
      "N-Phthalimidoglutamic Acid Imide",
      "N-Phthaloylglutamimide",
      "N-Phthalylglutamic Acid Imide",
      "Poly-Giron",
      "Predni-Sediv",
      "Pro-Bam M",
      "Pro-Ban M",
      "Sedalis Sedi-Lab",
      "Shin-Naito S",
      "THALIDOMIDE (AIDS INITIATIVE)",
      "Talidomida [INN-Spanish]",
      "Thaled (TN)",
      "Thalidomide (Soluble Form)",
      "Thalidomidum [INN-Latin]",
      "Thalomid (TM)",
      "Thalomid (TN)",
      "Thalomid, Thalidomide",
      "Alpha-N-Phthalylglutaramide",
      "Thalidomide [USAN:INN:BAN]",
      "Alpha-(N-Phthalimido)Glutarimide",
      "N-Phthalyl-Glutaminsaeure-Imid",
      "N-Phthalyl-Glutaminsaeure-Imid [German]",
      "Thalidomide (+ And-)",
      "Thalidomide (JAN/USP/INN)",
      "N-(2,6-Dioxo-3-Piperidyl)Phthalimide",
      "(+)-Thalidomide",
      "(+-)-Thalidomide",
      "(+/-)-THALIDOMIDE",
      "(Inverted Question Mark)-Thalidomide",
      "2,6-Dioxo-3-Phthalimidopiperidine",
      "3-Phthalimidoglutarimide",
    ],
  },
  {
    primary_id: "D0U7GL",
    names: ["N-(2-Flouro-Benzyl)-4-Sulfamoyl-Benzamide"],
  },
  {
    primary_id: "D0U7GP",
    names: ["RAUWOLFIA SERPENTINA ROOT"],
  },
  {
    primary_id: "D0U7GW",
    names: ["PF-431499"],
  },
  {
    primary_id: "D0U7HD",
    names: ["SQ-33961"],
  },
  {
    primary_id: "D0U7HJ",
    names: ["Certoparin Sodium", "Alphaparin (TN)"],
  },
  {
    primary_id: "D0U7HL",
    names: ["N-5984"],
  },
  {
    primary_id: "D0U7IG",
    names: ["SD-281", "Anti-Inflammatory Agents, Biocopea"],
  },
  {
    primary_id: "D0U7IL",
    names: ["LX-4211"],
  },
  {
    primary_id: "D0U7IO",
    names: ["N-(3-Chlorophenyl)-6-Fluoroquinazolin-4-Amine", "CHEMBL1081540", "SCHEMBL4738649", "BDBM50311880"],
  },
  {
    primary_id: "D0U7JS",
    names: ["H-Cpa-Gly-Gly-Phe-Met-NH2", "CHEMBL246956"],
  },
  {
    primary_id: "D0U7KH",
    names: ["RG7845"],
  },
  {
    primary_id: "D0U7KM",
    names: [
      "Boostrix",
      "Bustrix",
      "DTPa Booster Vaccine, GlaxoSmithKline",
      "DTPa Booster Vaccine, SB",
      "DTaP Booster Vaccine, GlaxoSmithKline",
      "DTaP Booster Vaccine, SB",
    ],
  },
  {
    primary_id: "D0U7KR",
    names: ["FR-118487"],
  },
  {
    primary_id: "D0U7KV",
    names: ["4-(3-(4-Hydroxyphenyl)-1H-Indol-2-Yl)Phenol"],
  },
  {
    primary_id: "D0U7LL",
    names: ["Salicylic Acid Derivative 2"],
  },
  {
    primary_id: "D0U7LW",
    names: ["Thiazole Carboxamide Derivative 26"],
  },
  {
    primary_id: "D0U7MR",
    names: ["4-(2,4-Dichlorophenoxy)-3-Hydroxybenzonitrile"],
  },
  {
    primary_id: "D0U7NG",
    names: ["BAY 86-8050"],
  },
  {
    primary_id: "D0U7OD",
    names: [
      "PF-526014",
      "CHEMBL403441",
      "CHEMBL534723",
      "BDBM50188098",
      "(R)-1-(2-(2-Ethoxyphenyl)-1-Phenylethyl)Piperazine Hydrochloride",
    ],
  },
  {
    primary_id: "D0U7OO",
    names: ["6-Fluoromevalonate 5-Diphosphate"],
  },
  {
    primary_id: "D0U7OS",
    names: [
      "CARD-024",
      "1-Alpha-Hydroxyvitamin D5 (Cardiovascular Diseases), Cardiavent",
      "1a(OH)VitD5 (Cardiovascular Diseases), Cardiavent",
    ],
  },
  {
    primary_id: "D0U7OU",
    names: ["EMODIN"],
  },
  {
    primary_id: "D0U7PM",
    names: [
      "OPL-CCL2-LPM",
      "CCR2 Targeting Agent (Arthritis/Nephritis/Cardiovascular/Pulmonaryl/CNS)",
      "Leukocyte Population Modulator (Arthritis/Nephritis/Cardiovascular/Pulmonary/CNS), Osprey",
    ],
  },
  {
    primary_id: "D0U7PS",
    names: ["GSK256066"],
  },
  {
    primary_id: "D0U7PT",
    names: [
      "ANALOG OF DYNORPHIN A",
      "Dynorphin A (1-13)",
      "72957-38-1",
      "Analog Of Dynorphin A",
      "UNII-VFC23V742Z",
      "Porcine Dynorphin A(1-13)",
      "Dynorphin (1-13)",
      "VFC23V742Z",
      "CHEMBL405618",
      "Dinorphin A (1-13)",
      "Dynorphin 1-13",
      "Dynorphin A(1-13)",
      "BDBM50241435",
      "Dynorphin A 1-13",
      "1-13-Dynorphin A (Swine)",
      "Dynorphin A1-13",
      "YGGFLRRIRPKLK",
      "YGGFLRRXRPKLK",
      "Dynorphin-(1-13)",
      "DYNORPHIN(1-13)-OH",
      "BDBM224024",
      "BDBM214798",
      "L-Lysine, L-Tyrosylglycylglycyl-L-Phenylalanyl-L-Leucyl-L-Arginyl-L-Arginyl-L-Isoleucyl-L-Arginyl-L-Prolyl-L-Lysyl-L-Leucyl-",
      "NCGC00163681-02",
      "NCGC00163681-01",
      "957H381",
    ],
  },
  {
    primary_id: "D0U7PV",
    names: [
      "4-Sulfamoyloxy-Benzoic Acid Cyclopentyl Ester",
      "CHEMBL347202",
      "BDBM50139115",
      "4-(Sulfamoyloxy)Benzoic Acid Cyclopentyl Ester",
    ],
  },
  {
    primary_id: "D0U7QA",
    names: [
      "Pyroglutamic Acid",
      "DL-Pyroglutamic Acid",
      "149-87-1",
      "5-Oxopyrrolidine-2-Carboxylic Acid",
      "2-Pyrrolidone-5-Carboxylic Acid",
      "DL-Proline, 5-Oxo-",
      "5-OXOPROLINE",
      "5-Oxo-DL-Proline",
      "Pyroglutamate",
      "D-(+)-Pyroglutamic Acid",
      "DL-Glutamic Acid Lactam",
      "Pyrrolidonecarboxylic Acid",
      "CHEBI:16010",
      "ODHCTXKNWHHXJC-UHFFFAOYSA-N",
      "MFCD00064322",
      "5-Oxoprolinate",
      "DL-Pidolic Acid",
      "(+)-Pyroglutamic Acid",
      "5-OXO-2-PYRROLIDINECARBOXYLIC ACID",
      "D-Pyroglutamate",
      "(+)-2-Pyrrolidone-5-Carboxylic Acid",
      "DL-5-Oxoproline",
      "(D-5-Oxo-2-Pyrrolidinecarboxylic Acid)",
      "Proline,5-Oxo-",
      "5-Oxoproline #",
    ],
  },
  {
    primary_id: "D0U7QE",
    names: ["[Tdf1]AngII", "CHEMBL1076613"],
  },
  {
    primary_id: "D0U7RR",
    names: [
      "3-[5-Bromo-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 9b",
      "BDBM8591",
      "AC1O704N",
      "CHEMBL176118",
      "SCHEMBL4231365",
      "3-[(Z)-(5-Bromo-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0U7RS",
    names: [
      "2-Fluorophenylboronic Acid",
      "1993/3/9",
      "(2-Fluorophenyl)Boronic Acid",
      "2-Fluorobenzeneboronic Acid",
      "(2-Fluorophenyl)Boranediol",
      "Boronic Acid, B-(2-Fluorophenyl)-",
      "O-Fluorophenylboronic Acid",
      "2-Fluorophenyl Boronic Acid",
      "2-Fluoro-Phenyl-Boronic Acid",
      "BORONIC ACID, (2-FLUOROPHENYL)-",
      "MFCD00674013",
      "2-Fluorophenylboronic Acid, 98%",
      "1934/3/7",
      "PubChem1856",
      "2-Fluorophenylboronicacid",
      "O-Fluorobenzeneboronic Acid",
      "ACMC-1BR1I",
      "O -Fluorophenylboronic Acid",
      "2-Fluoro-Phenylboronic Acid",
      "AC1MC0Y0",
      "2-Fluoro Phenylboronic Acid",
    ],
  },
  {
    primary_id: "D0U7SH",
    names: [
      "Desmopressin",
      "Ddavp",
      "DESMOPRESSIN",
      "DDAVP",
      "DAVP",
      "Minirin",
      "Adiuretin SD",
      "Noctiva",
      "Adiuretin",
      "Deamino Arginine Vasopressin",
      "Desmopressinum",
      "Deamino-Vasopressin",
      "[deamino1]AVP",
      "Desmopressin [INN:BAN]",
      "[deamino-Cys1]AVP",
      "Desmopressinum [INN-Latin]",
      "Desmopressine [INN-French]",
      "Desmopresina [INN-Spanish]",
      "Vasopressin, 1-(3-Mercaptopropanoic Acid)-8-D-Arginine-",
      "Desnopressin",
      "Vasopressin, Deamino Arginine",
      "EINECS 240-726-7",
      "1-Desamino-8-D-Arginine Vasopressin",
      "Desamino-8-D-Arginine Vasopressin, L-",
      "Deamino-8-D-Arginine, L-, Vasopressin",
      "Desmopressin Melt",
      "Minrin Melt",
      "FE-992026",
      "Desmopressin (Fast-Dissolving, Nocturia/Diabetes Insipidus)",
      "Desmopressin (Fast-Dissolving, Nocturia/Diabetes Insipidus), Ferring Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0U7SR",
    names: ["RO-4998452"],
  },
  {
    primary_id: "D0U7SU",
    names: ["Interferon-Alpha Lozenge"],
  },
  {
    primary_id: "D0U7TM",
    names: ["[Cyclopentyl-(2-Nitro-Benzoyl)-Amino]-Acetic Acid", "CHEMBL324703", "BDBM50020839"],
  },
  {
    primary_id: "D0U7TR",
    names: ["[125I]ZM-241385", "GTPL430"],
  },
  {
    primary_id: "D0U7UD",
    names: [
      "5-Chloro-N-(Pyridin-3-Yl)Indoline-1-Carboxamide",
      "CHEMBL290395",
      "SCHEMBL8527508",
      "BYXYRVSXGVLRKM-UHFFFAOYSA-N",
      "BDBM50321877",
      "5-Chloro-1-(3-Pyridylcarbamoyl)Indoline",
      "N-(3-Pyridinyl)-5-Chloro-2,3-Dihydro-1H-Indole-1-Carboxamide",
      "5-Chloro-2,3-Dihydro-Indole-1-Carboxylic Acid Pyridin-3-Ylamide",
    ],
  },
  {
    primary_id: "D0U7UY",
    names: ["CEP-1374"],
  },
  {
    primary_id: "D0U7VC",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 7"],
  },
  {
    primary_id: "D0U7VO",
    names: ["CNS-1307"],
  },
  {
    primary_id: "D0U7VS",
    names: ["CAR-T Cells Targeting CD19"],
  },
  {
    primary_id: "D0U7VU",
    names: ["MLN0264"],
  },
  {
    primary_id: "D0U7VZ",
    names: ["TD-2749"],
  },
  {
    primary_id: "D0U7WF",
    names: ["16,16-Dimethyl-PGE2", "16,16-Dimethylprostaglandin E2"],
  },
  {
    primary_id: "D0U7WN",
    names: ["A-80987", "ABT-987"],
  },
  {
    primary_id: "D0U7WQ",
    names: ["PMID26666989-Compound-Figure11Ala Up"],
  },
  {
    primary_id: "D0U7WU",
    names: [
      "2-Morpholino-4H-Benzo[g]chromen-4-One",
      "CHEMBL175883",
      "503467-97-8",
      "SCHEMBL3536668",
      "CTK1G6923",
      "DTXSID10465904",
      "BDBM50159668",
      "2-Morpholin-4-Yl-Benzo[g]chromen-4-One",
      "4H-Naphtho[2,3-B]pyran-4-One, 2-(4-Morpholinyl)-",
    ],
  },
  {
    primary_id: "D0U7WY",
    names: ["Anti-CD19/BCMA CAR-T Cells"],
  },
  {
    primary_id: "D0U7XP",
    names: ["Alpha,beta-Methylene-DGTP"],
  },
  {
    primary_id: "D0U7YB",
    names: ["Phosphatidylethanolamine", "Cephalin"],
  },
  {
    primary_id: "D0U7YK",
    names: ["KRP 203-Phosphate"],
  },
  {
    primary_id: "D0U7ZY",
    names: ["SP-MET-1"],
  },
  {
    primary_id: "D0U8AD",
    names: ["TLV-1"],
  },
  {
    primary_id: "D0U8AR",
    names: ["DMP-851"],
  },
  {
    primary_id: "D0U8AS",
    names: ["Telapristone"],
  },
  {
    primary_id: "D0U8AT",
    names: [
      "Echothiophate Iodide",
      "Echodide",
      "Phospholine",
      "Diethoxyphosphinylthiocholine Iodide",
      "ECOTHIOPATE IODIDE",
      "Ecostigmini Jodidum",
      "Ecothiopati Iodidum",
      "Ecothiophate Iodide",
      "Ioduro De Ecotiopato",
      "Phospholine Iodide",
      "Diethoxyphosphoryl-Thiocholine Iodide",
      "Echothiophate Iodide (USP)",
      "Ecothiopati Iodidum [INN-Latin]",
      "Iodide, Echothiophate",
      "Iodide, Ecothiopate",
      "Iodide, Ecothiophate",
      "Iodide, Phospholine",
      "Iodure D'ecothiopate",
      "Ioduro De Ecotiopato [INN-Spanish]",
      "Phospholine (The Pharmaceutical)",
      "Phospholine Iodide (TN)",
      "Ecothiopate Iodide (JP15/INN)",
      "Iodure D'ecothiopate [INN-French]",
      "N-(2-(Diethoxyphosphinylthio)Ethyl)Trimethylammonium Iodide",
      "O,O-Diethyl S-2-Trimethylammonium Ethylphosphonothiolate Iodide",
      "S-Ester Of (2-Mercaptoethyl)Trimethylammonium Iodide With O,O-Diethyl Phosphorothioate",
      "S-Beta-Dimethylaminoethyl-O,O-Diethylthionophosphate Methiodide",
      "S-(2-Dimethylaminoethyl)-O.O-Diethylphosphorothioate Methiodide",
      "S-(2-Dimethylaminoethyl)-O,O-Diethylphosphorothioate Methiodide",
      "Ammonium, (2-(O,O-Diethylphosphorothio)Ethyl)Trimethyl-, Iodide",
      "Ammonium, (2-Mercaptoethyl)Trimethyl-, Iodide, S-Ester With O,O-Diethylphosphorothioate",
      "S-(2-(N,N,N-Trimethylammonio)Ethyl) O,O-Diethylphosphorothiolate Iodide",
      "Ethanaminium, 2-((Diethoxyphosphinyl)Thio)-N,N,N-Trimethyl-, Iodide",
      "(2-Mercaptoethyl)Trimethylammonium Iodide S-Ester With O,O-Diethyl Phosphorothioate",
      "(2-Mercaptoethyl)Trimethylammonium Iodidie O,O-Diethyl Phosphorothioate",
      "2-((Diethoxyphosphinyl)Thio)-N,N,N,-Trimethylethanaminium Iodide",
      "2-Diaethoxyphosphinyl-Thioaethyl-Trimethyl-Ammonium-Jodid",
      "2-Diaethoxyphosphinyl-Thioaethyl-Trimethyl-Ammonium-Jodid [German]",
      "2-Diethoxy-Phosphinylthioethyl-Trimethylammonium Iodide",
      "2-[(Diethoxyphosphinyl)Thio]-N,N,N-Trimethylethanaminium Iodide",
      "2-[(Diethoxyphosphoryl)Sulfanyl]-N,N,N-Trimethylethanaminium Iodide",
      "2-Diethoxyphosphorylsulfanylethyl(Trimethyl)Azanium Iodide",
      "2-{[bis(Ethyloxy)Phosphoryl]thio}-N,N,N-Trimethylethanaminium Iodide",
      "217 MI",
      "217-Mi",
    ],
  },
  {
    primary_id: "D0U8AU",
    names: ["MACLURAXANTHONE"],
  },
  {
    primary_id: "D0U8BD",
    names: ["PMID28447479-Compound-23"],
  },
  {
    primary_id: "D0U8BM",
    names: [
      "N,N'-Bis-(4-Sec-Butyl-Phenyl)-Guanidine",
      "CHEMBL110909",
      "CHEMBL320199",
      "SCHEMBL4979578",
      "BDBM50066057",
      "1,3-Bis(4-Sec-Butylphenyl)Guanidine",
      "AKOS030602595",
      "N,N''-Bis-(4-Sec-Butyl-Phenyl)-Guanidine",
    ],
  },
  {
    primary_id: "D0U8CB",
    names: ["SPANTIDE"],
  },
  {
    primary_id: "D0U8CM",
    names: [
      "2-Methyl-1,2-Dihydro-Indazol-3-One",
      "1848-40-4",
      "1,2-Dihydro-2-Methyl-3H-Indazol-3-One",
      "2-Methyl-1H-Indazol-3(2H)-One",
      "3H-Indazol-3-One, 1,2-Dihydro-2-Methyl-",
      "CHEMBL3144583",
      "FPNXGZRMUROVNY-UHFFFAOYSA-N",
      "2-Methyl-1,2-Dihydroindazol-3-One",
      "3-Indazolinone, 2-Methyl-",
      "2-Methyl-1H-Indazol-3-One",
      "AC1LBX43",
      "CHEMBL8364",
      "SCHEMBL2673327",
      "CTK0E2434",
      "DTXSID30343553",
      "MolPort-028-748-594",
      "BDBM50009002",
      "ZINC13820683",
      "AKOS023543896",
      "MB14910",
      "CM10169",
      "2-Methyl-1,2-Dihydro-3H-Indazol-3-One #",
      "2-METHYL-2,3-DIHYDRO-1H-INDAZOL-3-ONE",
      "INDAZOL-3-ONE, 1,",
    ],
  },
  {
    primary_id: "D0U8CR",
    names: ["S-06911"],
  },
  {
    primary_id: "D0U8DW",
    names: ["1-Butyl-8-Phenyl-3,7-Dihydro-Purine-2,6-Dione", "CHEMBL8789"],
  },
  {
    primary_id: "D0U8DY",
    names: ["ISIS 32002"],
  },
  {
    primary_id: "D0U8EA",
    names: [
      "S-41744",
      "Dual 5-HT Reuptake Inhibitor/NK1 Antagonist (Depression)",
      "Dual 5-HT Reuptake Inhibitor/NK1 Antagonist(Depression), Servier",
    ],
  },
  {
    primary_id: "D0U8ET",
    names: ["3-(2-Bromophenyl)-6-Methylcoumarin", "CHEMBL1221929"],
  },
  {
    primary_id: "D0U8FC",
    names: ["N-(2-Aminophenyl)Benzamide"],
  },
  {
    primary_id: "D0U8FG",
    names: ["APS-001", "Cytosine Deaminase Modulator (Cancer), Anaeropharma Science"],
  },
  {
    primary_id: "D0U8GH",
    names: ["LPCN-1056"],
  },
  {
    primary_id: "D0U8GR",
    names: ["AP-21967", "CHEMBL525042", "SCHEMBL18176922", "C-16-(S)-7-Methylindolerapamycin"],
  },
  {
    primary_id: "D0U8GZ",
    names: ["Tamarixetin 3-Glucoside-7-Sulfate"],
  },
  {
    primary_id: "D0U8HD",
    names: ["2-Chloroadenosine-5-Triphosphate", "2-Chloro-ATP"],
  },
  {
    primary_id: "D0U8IF",
    names: ["[(125)I] RB129"],
  },
  {
    primary_id: "D0U8IJ",
    names: ["SDZ-PCO-400"],
  },
  {
    primary_id: "D0U8IL",
    names: ["Phenylpyrrolidinone Derivative 2"],
  },
  {
    primary_id: "D0U8IU",
    names: ["PerioPatch"],
  },
  {
    primary_id: "D0U8JC",
    names: ["MORACHALCONE A"],
  },
  {
    primary_id: "D0U8JD",
    names: ["ISIS 23705"],
  },
  {
    primary_id: "D0U8JN",
    names: ["Pegaspargase"],
  },
  {
    primary_id: "D0U8KA",
    names: [
      "CGP-43371",
      "Cgp 43371",
      "UNII-V97741GKF2",
      "V97741GKF2",
      "Cgp-43371",
      "123036-23-7",
      "Cgp43371",
      "N,15-Didehydro-15-Deoxo-1-Deoxy-1,15-Epoxy-4-O-Methyl-3-(4-((2,4,6-Trimethyl)Methyl)-1-Piperazinyl)Rifamycin 8-(2,2-Dimethylpropanoate)",
      "Rifamycin, N,15-Didehydro-15-Deoxo-1-Deoxy-1,15-Epoxy-3-(4-((2,4,6-Trimethylphenyl)Methyl)-1-Piperazinyl)-, 8-(2,2-Dimethylpropanoate)",
    ],
  },
  {
    primary_id: "D0U8KW",
    names: ["LUF-6258"],
  },
  {
    primary_id: "D0U8LD",
    names: ["MGN-1706"],
  },
  {
    primary_id: "D0U8MF",
    names: ["Theradigm-HPV"],
  },
  {
    primary_id: "D0U8NF",
    names: [
      "AFM-11",
      "Anti-CD19 And Anti-CD3 TandAb (B-Cell Lymphoblastic Leukemia), Affimed",
      "Anti-CD19 And Anti-CD3 TandAb (Non-Hodgkin's Lymphoma), Affimed",
    ],
  },
  {
    primary_id: "D0U8NN",
    names: ["RBP-6000", "Buprenorphine Depot"],
  },
  {
    primary_id: "D0U8OA",
    names: [
      "(E)-4-Methyl-6-(Prop-1-Enyl)Pyridin-2-Amine",
      "CHEMBL501620",
      "SCHEMBL15091007",
      "SCHEMBL15091006",
      "QHUQCHMVBGZYHI-ONEGZZNKSA-N",
      "ZINC40424901",
      "BDBM50267274",
    ],
  },
  {
    primary_id: "D0U8OD",
    names: [
      "CIMICOXIB",
      "Cimicoxib",
      "265114-23-6",
      "UNII-W7FHJ107MC",
      "UR-8880",
      "W7FHJ107MC",
      "CHEMBL435381",
      "CHEBI:76127",
      "4-[4-Chloro-5-(3-Fluoro-4-Methoxyphenyl)Imidazol-1-Yl]benzenesulfonamide",
      "4-[4-Chloro-5-(3-Fluoro-4-Methoxyphenyl)-1H-Imidazol-1-Yl]benzenesulfonamide",
      "4-(4-Chloro-5-(3-Fluoro-4-Methoxyphenyl)Imidazol-1-Yl)Benzenesulfonamide",
      "Cimicoxib [INN]",
      "4-(4-Chloro-5-(3-Fluoro-4-Methoxyphenyl)-1H-Imidazol-1-Yl)Benzenesulfonamide",
      "AC1Q6UZ8",
      "AC1L4U5V",
      "SCHEMBL3123310",
      "CTK4F8038",
      "DTXSID30181093",
      "KYXDNECMRLFQMZ-UHFFFAOYSA-N",
      "BCP24933",
      "ZINC1494105",
    ],
  },
  {
    primary_id: "D0U8OK",
    names: ["[3H]mesulergine", "AC1L1HDA", "GTPL232", "SCHEMBL11890532"],
  },
  {
    primary_id: "D0U8OS",
    names: ["N-(3-(3-Methoxyphenyl)Propyl)Acetamide"],
  },
  {
    primary_id: "D0U8PE",
    names: ["CAR19 T Cells Carrying Cytoplasmic Activated PD-1"],
  },
  {
    primary_id: "D0U8PR",
    names: ["HPP-593"],
  },
  {
    primary_id: "D0U8QB",
    names: ["JTT-851"],
  },
  {
    primary_id: "D0U8QC",
    names: ["AS-210"],
  },
  {
    primary_id: "D0U8QH",
    names: ["GDC-0349"],
  },
  {
    primary_id: "D0U8RR",
    names: [
      "2-Deoxy-Glucose-6-Phosphate",
      "D6G",
      "2-Deoxy-6-O-Phosphono-Alpha-D-Arabino-Hexopyranose",
      "2pri",
      "AC1L9N1X",
      "ZINC4097095",
      "2-Deoxy-Alpha-D-Glucopyranose 6-Phosphate",
      "[(2R,3S,4R,6S)-3,4,6-Trihydroxyoxan-2-Yl]methyl Dihydrogen Phosphate",
    ],
  },
  {
    primary_id: "D0U8RZ",
    names: ["2-[1-(2-Chloro-Phenyl)-1H-Pyrrol-2-Yl]-Ethylamine", "CHEMBL360277"],
  },
  {
    primary_id: "D0U8TM",
    names: ["4,5,6,7-Tetrafluoro-3-Perfluorophenyl-1H-Indazole", "CHEMBL595197", "SCHEMBL20472960", "BDBM50304151"],
  },
  {
    primary_id: "D0U8TW",
    names: ["APV-001", "Cell Apoptosis Inhibitor (Viral Infection),Shanghai Ambrosia Pharmaceutical"],
  },
  {
    primary_id: "D0U8UH",
    names: ["RU79072"],
  },
  {
    primary_id: "D0U8UK",
    names: [
      "PMID21596927C101",
      "Cmpd101",
      "Takeda Compound 101",
      "865608-11-3",
      "3pvu",
      "QRW",
      "GTPL8437",
      "MolPort-039-338-070",
      "ZINC38226912",
      "AKOS027470239",
    ],
  },
  {
    primary_id: "D0U8US",
    names: ["Interferon Gamma-1b"],
  },
  {
    primary_id: "D0U8UV",
    names: ["Dimenhydrinate"],
  },
  {
    primary_id: "D0U8VL",
    names: ["Octagam 10%"],
  },
  {
    primary_id: "D0U8VY",
    names: [
      "Quinazoline Deriv. 1",
      "Aurora Kinase Inhibitor II",
      "Quinazoline Deriv 1",
      "331770-21-9",
      "CHEMBL382590",
      "-Benzamidoanilino)-6,7-Dimethoxyquinazoline",
      "N-{4-[(6,7-Dimethoxyquinazolin-4-Yl)Amino]phenyl}benzamide",
      "N-[4-[(6,7-Dimethoxyquinazolin-4-Yl)Amino]phenyl]benzamide",
      "Anilinoquinazoline1",
      "K00590a",
      "CBiol_002061",
      "4-(4'-Benzamidoanilino)-6,7-Dimethoxyquinazoline",
      "GTPL5930",
      "SCHEMBL1066538",
      "AC1O7M16",
      "CTK8E8821",
      "BDBM12403",
      "DTXSID00425012",
      "MolPort-044-561-446",
      "Bio1_000347",
      "ZINC590935",
      "HMS3229A17",
      "Bio1_001325",
      "Bio1_000836",
      "IN1528",
      "CCG-206735",
    ],
  },
  {
    primary_id: "D0U8WW",
    names: ["NLCQ-1"],
  },
  {
    primary_id: "D0U8XD",
    names: ["KIN-806"],
  },
  {
    primary_id: "D0U8XW",
    names: [
      "APX-3330",
      "AR-03",
      "E-3330",
      "APE1 Inhibitors (Cancer/Retinopathy), ApeX Therapeutics",
      "APX-3330 Analogs (Cancer/Retinopathy),ApeX Therapeutics",
      "E-3330 Analogs (Cancer/Retinopathy), ApeX Therapeutics",
    ],
  },
  {
    primary_id: "D0U8XY",
    names: ["3-(4-(4-Hydroxyphenyl)Phthalazin-1-Ylamino)Phenol"],
  },
  {
    primary_id: "D0U8YK",
    names: ["NN5401"],
  },
  {
    primary_id: "D0U8YN",
    names: ["NU-6027"],
  },
  {
    primary_id: "D0U8YP",
    names: ["PMID30247903-Compound-General Structure28"],
  },
  {
    primary_id: "D0U8YQ",
    names: [
      "Benzyl-(2-Pyridin-4-Yl-Quinazolin-4-Yl)-Amine",
      "CHEMBL118462",
      "AC1LJDWH",
      "N-Benzyl-2-Pyridin-4-Ylquinazolin-4-Amine",
      "SCHEMBL7277479",
      "MolPort-000-723-072",
      "ZINC509842",
      "BDBM50033302",
      "AKOS030512862",
    ],
  },
  {
    primary_id: "D0U8YU",
    names: ["Tyr-Pro-Phe-Phe-OCH2OH", "CHEMBL522293"],
  },
  {
    primary_id: "D0U8YV",
    names: ["1-(1,2,4-Oxadiazol-5-Yl)-7-Phenylheptan-1-One", "CHEMBL448922", "SCHEMBL2161958"],
  },
  {
    primary_id: "D0U8YZ",
    names: ["PMID25666693-Compound-45"],
  },
  {
    primary_id: "D0U8ZD",
    names: ["OPT-822/OPT-821"],
  },
  {
    primary_id: "D0U8ZQ",
    names: [
      "Benzyl 4-Aminobutyl(3-Aminopropyl)Carbamate",
      "CHEMBL418644",
      "NSC685957",
      "Benzyl N-(4-Aminobutyl)-N-(3-Aminopropyl)Carbamate",
      "AC1L8YAY",
      "SCHEMBL7535687",
      "CTK8D1272",
      "ZINC1650370",
      "BDBM50184788",
      "NSC-685957",
      "NCI60_030939",
      "Benzyl(4-Aminobutyl)(3-Aminopropyl)Carbamate",
      "3-Aminopropyl(4-Aminobutyl)Carbamic Acid Benzyl Ester",
      "(4-Amino-Butyl)-(3-Amino-Propyl)-Carbamic Acid Benzyl Ester",
    ],
  },
  {
    primary_id: "D0U9AE",
    names: ["AIKa1"],
  },
  {
    primary_id: "D0U9AU",
    names: [
      "(-)-Phenethylcymserine",
      "CHEMBL54577",
      "BDBM50077263",
      "(4-Isopropyl-Phenyl)-Carbamic Acid (S)-3a,8-Dimethyl-1-Phenethyl-1,2,3,3a,8,8a-Hexahydro-Pyrrolo[2,3-B]indol-5-Yl Ester",
    ],
  },
  {
    primary_id: "D0U9DD",
    names: ["2-Phenoxy-1-(2-P-Tolylthiazolidin-3-Yl)Ethanone", "CHEMBL217530"],
  },
  {
    primary_id: "D0U9EN",
    names: ["Actimmune"],
  },
  {
    primary_id: "D0U9ET",
    names: [
      "6,6-Dimethyl-2-Oxa-Spiro[4.4]nonan-1-One",
      "2-Oxaspiro[4.4]nonan-1-One, 6,6-Dimethyl-",
      "CHEMBL94885",
      "153579-89-6",
    ],
  },
  {
    primary_id: "D0U9EW",
    names: ["Glyoxalate, Glyoxylate"],
  },
  {
    primary_id: "D0U9FC",
    names: ["ASP-9831"],
  },
  {
    primary_id: "D0U9FD",
    names: ["Trospium Chloride"],
  },
  {
    primary_id: "D0U9FJ",
    names: ["KA-40X"],
  },
  {
    primary_id: "D0U9GN",
    names: ["PMID18754614C17"],
  },
  {
    primary_id: "D0U9GZ",
    names: ["Recombinant Protein Subunit Vaccine, Emergent"],
  },
  {
    primary_id: "D0U9HE",
    names: ["RS-102,221"],
  },
  {
    primary_id: "D0U9HM",
    names: ["GSK-1322888", "1322888"],
  },
  {
    primary_id: "D0U9HN",
    names: [
      "DYSIDINE",
      "Dysidine",
      "CHEBI:65816",
      "2-[(4-Hydroxy-3,6-Dioxo-5-{[(1R,2R,4aS,8aS)-1,2,4a,5-Tetramethyl-1,2,3,4,4a,7,8,8a-Octahydronaphthalen-2-Yl]methyl}cyclohexa-1,4-Dien-1-Yl)Amino]ethanesulfonic Acid",
      "Dicidin",
    ],
  },
  {
    primary_id: "D0U9IB",
    names: ["Eledoisin", "ELD 950", "Eledone Peptide", "ELD-950"],
  },
  {
    primary_id: "D0U9IJ",
    names: ["Indanidine"],
  },
  {
    primary_id: "D0U9IQ",
    names: ["PF-477736"],
  },
  {
    primary_id: "D0U9IR",
    names: ["4-(O-Tolylthio)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL462025", "BDBM50248593"],
  },
  {
    primary_id: "D0U9IZ",
    names: ["Alkaline Phosphatase"],
  },
  {
    primary_id: "D0U9JC",
    names: ["1-(4-Octylphenyl)Prop-2-En-1-One", "CHEMBL237241"],
  },
  {
    primary_id: "D0U9JL",
    names: ["LY-223982", "CGS-23131", "LY-223980", "LY-255253", "SKF-107324"],
  },
  {
    primary_id: "D0U9JR",
    names: ["ISIS 156471"],
  },
  {
    primary_id: "D0U9LD",
    names: ["PMID18800762C14", "Bisphosphonate, 14", "GTPL3194", "BDBM25258"],
  },
  {
    primary_id: "D0U9LL",
    names: ["NKS-01", "14alpha-Hydroxy-4-Androstene-3,6,17-Trione"],
  },
  {
    primary_id: "D0U9LS",
    names: ["Polycyclic Compound 1"],
  },
  {
    primary_id: "D0U9LT",
    names: ["Y-39983"],
  },
  {
    primary_id: "D0U9LV",
    names: ["LY-2428757"],
  },
  {
    primary_id: "D0U9MJ",
    names: ["Melanoma Vaccine"],
  },
  {
    primary_id: "D0U9MN",
    names: ["ACAM-Cdiff)Toxoid Vaccine)"],
  },
  {
    primary_id: "D0U9MR",
    names: ["Tilidine"],
  },
  {
    primary_id: "D0U9NN",
    names: ["OG987SC"],
  },
  {
    primary_id: "D0U9OC",
    names: ["ASP8374"],
  },
  {
    primary_id: "D0U9OD",
    names: [
      "CreaVax-RA",
      "Autologous DC Therapy (Injectable, Rheumatoid Arthritis), CreaGene",
      "Autologous Dendritic Cell Therapy (Injectable, Rheumatoid Arthritis), CreaGene",
    ],
  },
  {
    primary_id: "D0U9OH",
    names: ["PMID28394193-Compound-44"],
  },
  {
    primary_id: "D0U9OJ",
    names: ["GSK-326416"],
  },
  {
    primary_id: "D0U9OQ",
    names: ["FF-10501"],
  },
  {
    primary_id: "D0U9PV",
    names: ["CXB-909"],
  },
  {
    primary_id: "D0U9PX",
    names: [
      "3,5 DIBROMOTYROSINE",
      "3,5-DIBROMOTYROSINE",
      "2-Amino-3-(3,5-Dibromo-4-Hydroxyphenyl)Propanoic Acid",
      "537-24-6",
      "3,5-Dibromo-Tyrosine",
      "NSC210786",
      "3,5-Dibromo-DL-Tyrosine",
      "Bromotiren",
      "CHEMBL1253341",
      "CHEBI:80912",
      "L-Tyrosine,5-Dibromo-",
      "Tyrosine,5-Dibromo-, L-",
      "Dibromotirina",
      "Biotiren",
      "NSC-210786",
      "EINECS 208-661-9",
      "Tyrosine,5-Dibromo-",
      ".beta.-(3,5-Dibromo-4-Hydroxyphenyl)Alanine",
      "ACMC-209hco",
      "AC1L1W1Q",
      "SCHEMBL145218",
      "CTK7I4423",
      "MolPort-002-887-590",
      "NSC97935",
      "ZX-AN012875",
      "NSC39452",
      "ALBB-014135",
      "NSC-97935",
      "NSC-39452",
      "BDBM50326563",
      "AKOS005109959",
      "MS-3024",
    ],
  },
  {
    primary_id: "D0U9QB",
    names: [
      "DEHYDROLEUCODIN",
      "Dehydroleucodin",
      "Dehydroleucodine",
      "36150-07-9",
      "NSC 180034",
      "CHEMBL441260",
      "Azuleno(4,5-B)Furan-2,7-Dione, 3,3a,4,5,9a,9b-Hexahydro-6,9-Dimethyl-3-Methylene-, (3aS-(3aalpha,9aalpha,9bbeta))-",
      "Lidbeckialactone",
      "Mesatlantin E",
      "11,13-Dehydroleucodin",
      "AC1L2JV1",
      "AC1Q69JZ",
      "(3as,9as,9bs)-6,9-Dimethyl-3-Methylidene-3,3a,4,5,9a,9b-Hexahydroazuleno[4,5-B]furan-2,7-Dione",
      "SCHEMBL4998391",
      "CTK4H5993",
      "ZINC5202250",
      "Dehydroleucodine, &gt",
      "BDBM50318392",
      "NCGC00485131-01",
      "LS-182153",
    ],
  },
  {
    primary_id: "D0U9QD",
    names: [
      "6-Fluorotryptophan",
      "6-Fluoro-DL-Tryptophan",
      "7730-20-3",
      "2-Amino-3-(6-Fluoro-1H-Indol-3-Yl)Propanoic Acid",
      "DL-Tryptophan, 6-Fluoro-",
      "DL-6-Fluorotryptophane",
      "(+-)-6-Fluorotryptophan",
      "Tryptophan, 6-Fluoro-, DL-",
      "NSC 9364",
      "6-Fluoro-D,L-Tryptophan",
      "EINECS 231-788-6",
      "BRN 0482552",
      "Tryptophan, 6-Fluoro-",
      "ST072163",
      "2-Amino-3-(6-Fluoroindol-3-Yl)Propanoic Acid",
      "6-Fluoro-DL-Tryptophan, 97%",
      "D-Tryptophan, 6-Fluoro-",
      "343-92-0",
      "6-Fluorotryptophan, DL-",
      "ACMC-20mbi4",
      "AC1Q5S5W",
      "SCHEMBL248870",
      "GTPL5126",
      "CHEMBL472222",
      "AC1L3T80",
      "SCHEMBL18029199",
    ],
  },
  {
    primary_id: "D0U9QL",
    names: ["[Nphe(1)]-Nociceptin (1-13)-NH(2)"],
  },
  {
    primary_id: "D0U9QT",
    names: ["UMN-03"],
  },
  {
    primary_id: "D0U9QU",
    names: ["Ibopamine Hci"],
  },
  {
    primary_id: "D0U9RB",
    names: ["7-Azaindole Derivative 6"],
  },
  {
    primary_id: "D0U9RR",
    names: ["Quinoxaline"],
  },
  {
    primary_id: "D0U9SM",
    names: ["OX-641"],
  },
  {
    primary_id: "D0U9TM",
    names: ["Ponalrestat"],
  },
  {
    primary_id: "D0U9TR",
    names: ["Chalcone Derivative 1"],
  },
  {
    primary_id: "D0U9UF",
    names: ["A-967079"],
  },
  {
    primary_id: "D0U9UH",
    names: ["Pyrimidine Derivative 24"],
  },
  {
    primary_id: "D0U9UL",
    names: ["5-Chloro-N-(4-Ethylphenyl)Benzo[d]oxazol-2-Amine", "CHEMBL1269621", "SCHEMBL12095512"],
  },
  {
    primary_id: "D0U9VO",
    names: ["PF-2575799"],
  },
  {
    primary_id: "D0U9VV",
    names: ["Opdivonivolumab"],
  },
  {
    primary_id: "D0U9VX",
    names: [
      "9-(3-Aminopropoxy)-7H-Furo[3,2-G]chromen-7-One",
      "CHEMBL382645",
      "119802-72-1",
      "7H-Furo[3,2-G][1]benzopyran-7-One, 9-(3-Aminopropoxy)-",
      "ACMC-20moke",
      "SCHEMBL17373028",
      "CTK0F9398",
      "DTXSID90470955",
    ],
  },
  {
    primary_id: "D0U9WS",
    names: ["LEO-32731"],
  },
  {
    primary_id: "D0U9WX",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 30"],
  },
  {
    primary_id: "D0U9XC",
    names: ["(3-Amino-Phenyl)-Phosphonic Acid Diphenyl Ester", "CHEMBL158955"],
  },
  {
    primary_id: "D0U9YV",
    names: ["PAT-PM-1"],
  },
  {
    primary_id: "D0U9YY",
    names: ["4-(1H-Indol-5-Ylamino)-5-Styrylnicotinonitrile", "CHEMBL579201", "SCHEMBL2935239"],
  },
  {
    primary_id: "D0U9ZW",
    names: ["8-Methyl-4-Morpholin-4-Yl-Chromen-2-One", "CHEMBL369452"],
  },
  {
    primary_id: "D0UA0I",
    names: ["Metergolin"],
  },
  {
    primary_id: "D0UA0U",
    names: ["CAR-T Cells Targeting CD30"],
  },
  {
    primary_id: "D0UA0Y",
    names: ["HKI-46F08"],
  },
  {
    primary_id: "D0UA2Z",
    names: ["Epicillin", "Dexacillin (TN)"],
  },
  {
    primary_id: "D0UA3E",
    names: ["N-Methylstephuline"],
  },
  {
    primary_id: "D0UA3V",
    names: ["PMID25399762-Compound-Table 7-Vanillic Acid"],
  },
  {
    primary_id: "D0UA4Y",
    names: ["PMID25828189-Compound-19"],
  },
  {
    primary_id: "D0UA6W",
    names: ["Pyrazole Derivative 33"],
  },
  {
    primary_id: "D0UA9J",
    names: ["VT-1129"],
  },
  {
    primary_id: "D0UB0T",
    names: [
      "PD176078",
      "CHEMBL452325",
      "WHSQHGGETBFMLM-FTBISJDPSA-N",
      "(S)-4-Methyl-2-Methylamino-Pentanoic Acid [4,4-Bis-(4-Fluoro-Phenyl)-Butyl]-Amide Monohydrochloride",
      "(S)-4-Methyl-2-(Methylamino)Pentanoic Acid [4,4-Bis(4-Fluorophenyl)Butyl]amide Hydrochloride",
    ],
  },
  {
    primary_id: "D0UB1J",
    names: ["BRL-44385", "BRL-55792"],
  },
  {
    primary_id: "D0UB2J",
    names: [
      "[3H]cAMP",
      "Cyclic AMP",
      "CAMP",
      "60-92-4",
      "Adenosine 3',5'-Cyclic Monophosphate",
      "3',5'-Cyclic AMP",
      "Adenosine 3',5'-Phosphate",
      "Adenosine 3',5'-Cyclophosphate",
      "Cyclic 3',5'-AMP",
      "Adenosine Cyclic Monophosphate",
      "Cyclic 3',5'-Adenylic Acid",
      "Adenosine 3',5'-Monophosphate",
      "Adenosine-3',5'-Cyclophosphate",
      "Adenosine Cyclophosphate",
      "Cyclic Adenosine Monophosphate",
      "Cyclic Adenosine 3',5'-Phosphate",
      "Cyclic Adenylic Acid",
      "Adenosine Cyclic 3',5'-Monophosphate",
      "3',5'-AMP",
      "Cyclic Adenosine 3',5'-Monophosphate",
      "Adenosine Cyclic 3',5'-Phosphate",
      "[3H]-CAMP",
    ],
  },
  {
    primary_id: "D0UB3V",
    names: ["NSC-640584"],
  },
  {
    primary_id: "D0UB5A",
    names: ["IC14"],
  },
  {
    primary_id: "D0UB5F",
    names: ["Streptococcus Pneumoniae Vaccine, Sanofi Pasteur"],
  },
  {
    primary_id: "D0UB6O",
    names: ["[125I]SB-258585"],
  },
  {
    primary_id: "D0UB6U",
    names: [
      "L-687414",
      "(3R,4R)-3-Amino-1-Hydroxy-4-Methylpyrrolidin-2-One",
      "130931-65-6",
      "CHEMBL283819",
      "132619-43-3",
      "Cis-3-Amino-1-Hydroxy-4-Methylpyrrolidin-2-One",
      "SCHEMBL561932",
      "DTXSID00437953",
      "SKYSFPFYQBZGDC-QWWZWVQMSA-N",
      "ZINC3805040",
      "BDBM50038169",
      "AKOS006239328",
      "KB-78073",
      "AJ-45657",
      "3-Amino-1-Hydroxy-4-Methyl-Pyrrolidin-2-One",
      "R(+)-Cis-Beta-Methyl-3-Amino-1-Hydroxypyrrolid-2-One",
    ],
  },
  {
    primary_id: "D0UB8U",
    names: ["Alkyl Mannoside Derivative 6"],
  },
  {
    primary_id: "D0UB9C",
    names: ["Bevacizumab Biosimilar", "BI 695502"],
  },
  {
    primary_id: "D0UC2S",
    names: ["Indazole Derivative 4"],
  },
  {
    primary_id: "D0UC4G",
    names: [
      "PSB-716",
      "78510-27-7",
      "Sodium 1-Amino-4-O-Methoxyanilino-9,10-Dihydro-9,10-Dioxoanthracene-2-Sulphonate",
      "CHEMBL257495",
      "EINECS 278-930-3",
      "SCHEMBL790524",
      "DTXSID00229133",
      "1-Amino-2-(Sodiooxysulfonyl)-4-(2-Methoxyphenylamino)-9,10-Anthraquinone",
      "Sodium 1-Amino-4-O-Methoxyanilino-9,10-Dihydro-9,10-Dioxoanthracene-2-Sulfonate",
    ],
  },
  {
    primary_id: "D0UC5C",
    names: ["DP-1038"],
  },
  {
    primary_id: "D0UC5G",
    names: ["MBN-101"],
  },
  {
    primary_id: "D0UC6H",
    names: ["Chlamydocin"],
  },
  {
    primary_id: "D0UC8I",
    names: ["Tetrazole Derivative 1"],
  },
  {
    primary_id: "D0UD1J",
    names: ["EDP1066"],
  },
  {
    primary_id: "D0UD1U",
    names: ["Erysubin D"],
  },
  {
    primary_id: "D0UD1Y",
    names: ["Neladenoson Bialanate"],
  },
  {
    primary_id: "D0UD3K",
    names: ["Urea And Carbamate Bioisostere Derivative 3"],
  },
  {
    primary_id: "D0UD4X",
    names: ["Ketoheterocycle Derivative 1"],
  },
  {
    primary_id: "D0UD4Y",
    names: ["PQ-912"],
  },
  {
    primary_id: "D0UD5P",
    names: ["BPR-DC-2"],
  },
  {
    primary_id: "D0UD5X",
    names: ["(E)-2-(4-(Methylsulfonyl)Styryl)Furan", "CHEMBL1288742"],
  },
  {
    primary_id: "D0UD6S",
    names: ["NY-ESO-1/LAGE-1-Specific T-Cells"],
  },
  {
    primary_id: "D0UD6X",
    names: ["Imidazopyridazine Derivative 3"],
  },
  {
    primary_id: "D0UD7H",
    names: ["1,6-Hexamethylene Diisocyanate"],
  },
  {
    primary_id: "D0UD9H",
    names: [
      "AM-281",
      "AM 281",
      "202463-68-1",
      "AM281",
      "UNII-SBP4A4DYH2",
      "SBP4A4DYH2",
      "1-(2,4-Dichlorophenyl)-5-(4-Iodophenyl)-4-Methyl-N-4-Morpholinyl-1H-Pyrazole-3-Carboxamide",
      "CHEMBL476833",
      "1-(2,4-Dichlorophenyl)-5-(4-Iodophenyl)-4-Methyl-N-Morpholin-4-Ylpyrazole-3-Carboxamide",
      "1-(2,4-Dichlorophenyl)-5-(4-Iodophenyl)-4-Methyl-N-Morpholino-1H-Pyrazole-3-Carboxamide",
      "Tocris-1115",
      "AC1N7ICF",
      "GTPL741",
      "SCHEMBL1517458",
      "CTK8E9266",
      "CHEBI:93039",
      "AJFFBPZYXRNAIC-UHFFFAOYSA-N",
      "MolPort-003-940-033",
      "HMS3267K18",
      "EX-A1546",
      "ZINC2575699",
      "BCP07951",
      "BN0064",
    ],
  },
  {
    primary_id: "D0UD9R",
    names: ["PMID29671355-Compound-57"],
  },
  {
    primary_id: "D0UE3F",
    names: ["Biphenyl Mannoside Derivative 28"],
  },
  {
    primary_id: "D0UE4K",
    names: ["PMID27019002-Compound-21c"],
  },
  {
    primary_id: "D0UE6R",
    names: ["Kidney Disease Medicine"],
  },
  {
    primary_id: "D0UE7H",
    names: ["C-[-Arg-Gly-Asp-Acpca33-]", "CHEMBL534712"],
  },
  {
    primary_id: "D0UE8H",
    names: ["Midazolam Intranasal Spray"],
  },
  {
    primary_id: "D0UE8P",
    names: ["Recombinant Grass Pollen Allergens"],
  },
  {
    primary_id: "D0UE9B",
    names: ["PMID29671355-Compound-12"],
  },
  {
    primary_id: "D0UE9K",
    names: ["PMID25666693-Compound-83"],
  },
  {
    primary_id: "D0UE9X",
    names: ["Gamolenic Acid"],
  },
  {
    primary_id: "D0UF0N",
    names: ["ACP-319"],
  },
  {
    primary_id: "D0UF0U",
    names: ["3-Ethylquinoline-8-Carboxamide", "CHEMBL453989", "8-Quinolinecarboxamide, 3-Ethyl-", "BDBM50255269"],
  },
  {
    primary_id: "D0UF1D",
    names: ["RG7986"],
  },
  {
    primary_id: "D0UF1R",
    names: [
      "1,2,3,4-Tetrahydro-Naphthalen-1-Ylamine",
      "1,2,3,4-Tetrahydro-1-Naphthylamine",
      "2217-40-5",
      "1,2,3,4-Tetrahydronaphthalen-1-Amine",
      "1-Aminotetralin",
      "Aminotetralin",
      "1-Amino-1,2,3,4-Tetrahydro-Naphthalene",
      "Aminotetralin [Czech]",
      "1,2,3,4-Tetrahydronaphthylamine",
      "JRZGPXSSNPTNMA-UHFFFAOYSA-N",
      "EINECS 220-973-7",
      "Beta-1,2,3,4-Tetrahydronaphthylamine",
      "Naphthalenamine, 1,2,3,4-Tetrahydro-",
      "BRN 1102357",
      "1-Amino-1,2,3,4-Tetrahydronaphthalene",
      "1,2,3,4-Tetrahydronaphthalen-1-Ylamine",
      "1-Naphthalenamine, 1,2,3,4-Tetrahydro-",
    ],
  },
  {
    primary_id: "D0UF2B",
    names: ["2-(3,4-Difluorophenyl)-2,2-Diphenylacetamide", "CHEMBL272040", "SCHEMBL14437487"],
  },
  {
    primary_id: "D0UF2N",
    names: ["DIADENOSINE TETRAPHOSPHATE", "Ap4A", "F-1500 (TetraNa Salt)", "Diadenosine 5',5''-P1,P4-Tetraphosphate"],
  },
  {
    primary_id: "D0UF5I",
    names: ["MPC-0920"],
  },
  {
    primary_id: "D0UF6M",
    names: ["Thiacetazone"],
  },
  {
    primary_id: "D0UF8E",
    names: [
      "XOMA-629",
      "Acne Therapy, XOMA",
      "BPI Peptides, XOMA",
      "XMP-629",
      "XMP.629",
      "BPI Peptide (Acne, Staphylococcus Infection, Impetigo), Xoma",
      "Bactericidal Permeability Increasing Protein (Acne, Staphylococcus Infection, Impetigo), XOMA",
    ],
  },
  {
    primary_id: "D0UF8M",
    names: ["IMO-9200"],
  },
  {
    primary_id: "D0UF8Z",
    names: ["Aryl Pyrimidine Derivative 9"],
  },
  {
    primary_id: "D0UF9K",
    names: [
      "BIS(9)-HUPERZINE B",
      "Bis(9)-Huperzine B",
      "Bis-Huperzine B 5b",
      "Bis(9)-Hup B",
      "CHEMBL367977",
      "BDBM10942",
    ],
  },
  {
    primary_id: "D0UF9W",
    names: ["Pegaptanib"],
  },
  {
    primary_id: "D0UG0K",
    names: ["Trastuzumab Emtansine"],
  },
  {
    primary_id: "D0UG1U",
    names: ["UR-PG55B", "CHEMBL513883", "GTPL1202", "BDBM50413176"],
  },
  {
    primary_id: "D0UG1W",
    names: ["Fused Aromatic Compound 1"],
  },
  {
    primary_id: "D0UG3F",
    names: ["Toll-Like Receptor 4 Inhibition"],
  },
  {
    primary_id: "D0UG3Q",
    names: ["VBP-245 Topical Gel"],
  },
  {
    primary_id: "D0UG4P",
    names: ["Egaptivon Pegol"],
  },
  {
    primary_id: "D0UG4S",
    names: ["L-Betagamma-MeATP"],
  },
  {
    primary_id: "D0UG4T",
    names: [
      "CDP840",
      "Cdp-840",
      "Cdp 840",
      "CHEMBL32442",
      "4-(2-(3-(Cyclopentyloxy)-4-Methoxyphenyl)-2-Phenylethyl)Pyridine",
      "(R)-4-(2-(3-(Cyclopentyloxy)-4-Methoxyphenyl)-2-Phenylethyl)Pyridine",
      "4-[(2R)-2-(3-Cyclopentyloxy-4-Methoxyphenyl)-2-Phenylethyl]pyridine",
      "Pyridine, 4-(2-(3-(Cyclopentyloxy)-4-Methoxyphenyl)-2-Phenylethyl)-, (R)-",
      "162542-90-7",
      "AC1L2SW5",
      "SCHEMBL84490",
      "4-[2-(3-Cyclopentyloxy-4-Methoxy-Phenyl)-2-Phenyl-Ethyl]-Pyridine",
      "GTPL9330",
      "UTUUPXBCDMQYRR-HSZRJFAPSA-N",
      "ZINC599006",
      "PDSP2_001272",
      "BDBM50064858",
      "PDSP1_001288",
    ],
  },
  {
    primary_id: "D0UG5M",
    names: ["JDTic"],
  },
  {
    primary_id: "D0UG5O",
    names: ["PMID28394193-Compound-20"],
  },
  {
    primary_id: "D0UG5Q",
    names: ["PMID27019002-Compound-42a"],
  },
  {
    primary_id: "D0UG6N",
    names: [
      "7-METHYL-8-OXO-5-P-TOLYL-7,8-DIHYDRO-[1,7]NAPHTHYRIDINE-6-CARBOXYLIC ACID (S)-[(R)-1-(3,5-BIS-TRIFLUOROMETHYL-PHENYL)-ETHYL]-METHYL-AMIDE (ENANTIOMERIC MIX)",
      "CHEMBL135572",
      "BDBM50067521",
      "5-(4-Methylphenyl)-7,N-Dimethyl-N-[(R)-Alpha-Methyl-3,5-Bis(Trifluoromethyl)Benzyl]-8-Oxo-7,8-Dihydro-1,7-Naphthyridine-6-Carboxamide",
      "7-Methyl-8-Oxo-5-P-Tolyl-7,8-Dihydro-[1,7]naphthyridine-6-Carboxylic Acid (S)-[(R)-1-(3,5-Bis-Trifluoromethyl-Phenyl)-Ethyl]-Methyl-Amide",
    ],
  },
  {
    primary_id: "D0UG8H",
    names: ["SN-11"],
  },
  {
    primary_id: "D0UG8S",
    names: ["Thiazolopyridine Derivative 1"],
  },
  {
    primary_id: "D0UG9S",
    names: ["Biphenyl Mannoside Derivative 7"],
  },
  {
    primary_id: "D0UH0Q",
    names: [
      "3-[3-(2-Hydroxy-Ethoxy)-Phenyl]-1H-Quinolin-2-One",
      "CHEMBL97218",
      "AC1OCFMJ",
      "3-[3-(2-Hydroxyethoxy)Phenyl]-1H-Quinolin-2-One",
      "ZINC13584303",
      "BDBM50105767",
    ],
  },
  {
    primary_id: "D0UH0S",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0UH3D",
    names: ["Fluoro Loxoprofen"],
  },
  {
    primary_id: "D0UH3W",
    names: ["OCOPHYLLALS A"],
  },
  {
    primary_id: "D0UH4P",
    names: ["Vasculan"],
  },
  {
    primary_id: "D0UH4T",
    names: ["GW-493838"],
  },
  {
    primary_id: "D0UH5B",
    names: ["PMID25416646-Compound-Figure2-J"],
  },
  {
    primary_id: "D0UH5I",
    names: ["ISIS 6443"],
  },
  {
    primary_id: "D0UH6R",
    names: ["(N-(3-Phenoxycinnamyl)-Acetohydroxamic Acid", "CHEMBL529218"],
  },
  {
    primary_id: "D0UH7R",
    names: ["Etrolizumab"],
  },
  {
    primary_id: "D0UH8F",
    names: ["GCCSNPVCHLEHSNLC*", "CHEMBL265198"],
  },
  {
    primary_id: "D0UH8J",
    names: ["PMID27774824-Compound-Figure12Example10"],
  },
  {
    primary_id: "D0UH9E",
    names: ["PMID:18477808CB", "Cpd-B", "Cpd B", "GTPL3367", "1065478-14-9"],
  },
  {
    primary_id: "D0UI0B",
    names: ["VTX-763"],
  },
  {
    primary_id: "D0UI0I",
    names: ["BL-3010"],
  },
  {
    primary_id: "D0UI1P",
    names: ["2-Cinnamamido-N4-Hexyl-N1-Hydroxysuccinamide", "CHEMBL595209"],
  },
  {
    primary_id: "D0UI3G",
    names: ["Succinic"],
  },
  {
    primary_id: "D0UI3L",
    names: ["Dihydroergocryptine", "Dihydroergokryptine"],
  },
  {
    primary_id: "D0UI3T",
    names: [
      "Elagolix",
      "834153-87-6",
      "UNII-5B2546MB5Z",
      "NBI-56418",
      "5B2546MB5Z",
      "NBI56418",
      "NBI 56418",
      "Elagolix [USAN:INN]",
      "Orilissa",
      "ELA GOLIX",
      "Elagolix (USAN/INN)",
      "SCHEMBL1642523",
      "GTPL8362",
      "CHEMBL1208155",
      "DTXSID40232348",
      "ABT-620",
      "4-[[(1R)-2-[5-(2-Fluoro-3-Methoxyphenyl)-3-[[2-Fluoro-6-(Trifluoromethyl)Phenyl]methyl]-4-Methyl-2,6-Dioxopyrimidin-1-Yl]-1-Phenylethyl]amino]butanoic Acid",
      "EX-A1765",
      "BCP08827",
      "ZINC49888891",
      "CS-5329",
      "SB16700",
      "DB11979",
      "Compound 10b [PMID 19006286]",
      "HY-14789",
      "AN-27202",
      "D09335",
      "Z-3253",
      "153E876",
      "Butanoic Acid, 4-(((1R)-2-(5-(2-Flu",
    ],
  },
  {
    primary_id: "D0UI3Z",
    names: ["H1N1 Influenza A Inactivated Split Virion Vaccine"],
  },
  {
    primary_id: "D0UI4K",
    names: ["VB4-847"],
  },
  {
    primary_id: "D0UI4L",
    names: ["ND-0801"],
  },
  {
    primary_id: "D0UI4S",
    names: ["PF-3475952", "CD44 Targeted Monoclonal Antibody (Inflammation), Pfizer"],
  },
  {
    primary_id: "D0UI6L",
    names: ["Fully Human Mabs"],
  },
  {
    primary_id: "D0UI7K",
    names: ["Emapalumab"],
  },
  {
    primary_id: "D0UI7U",
    names: ["ML315"],
  },
  {
    primary_id: "D0UI8K",
    names: ["Apogen MS"],
  },
  {
    primary_id: "D0UI8Q",
    names: [
      "5-Bromo-8-Imidazol-1-Ylmethyl-Chromen-4-One",
      "CHEMBL161530",
      "BDBM50097371",
      "8-((1H-Imidazol-1-Yl)Methyl)-5-Bromo-4H-Chromen-4-One",
    ],
  },
  {
    primary_id: "D0UI8Y",
    names: ["Encapsulated Human Islet Allografts"],
  },
  {
    primary_id: "D0UI9T",
    names: ["Carbamide Derivative 13"],
  },
  {
    primary_id: "D0UJ0D",
    names: ["ISIS-FGFR4Rx"],
  },
  {
    primary_id: "D0UJ2C",
    names: ["2-(Phenylsulfonyl)-1-(Thiophen-3-Yl)Ethanone", "CHEMBL238851", "BDBM50212533"],
  },
  {
    primary_id: "D0UJ3C",
    names: ["CD38-Specific Gene-Engineered T Cells"],
  },
  {
    primary_id: "D0UJ5D",
    names: ["Imidazopyridine Derivative 7"],
  },
  {
    primary_id: "D0UJ6V",
    names: ["KSG-LDTKNYKQTSV", "CHEMBL505051"],
  },
  {
    primary_id: "D0UJ8E",
    names: [
      "Sch-49209",
      "Diepoxin Sigma",
      "Sch 49209",
      "152697-41-1",
      "AC1L31VX",
      "Spiro(2a,6a-Epoxynaphth(2,3-B)Oxirene-2(1aH),2'-Naphtho(1,8-De)(1,3)Dioxin)-3,7(6H,7aH)-Dione, 6-Hydroxy-, (1aS-(1aalpha,2abeta,6alpha,6abeta,7aalpha))-",
    ],
  },
  {
    primary_id: "D0UJ8Z",
    names: ["Cyclopropane 1-Carboxylic Acid Derivative 5"],
  },
  {
    primary_id: "D0UJ9J",
    names: ["PMID30247903-Compound-General Structure16"],
  },
  {
    primary_id: "D0UJ9V",
    names: ["1-Adamantan-1-Yl-3-(5-Hydroxypentyl)Urea", "CHEMBL245141", "BDBM50223381"],
  },
  {
    primary_id: "D0UK0F",
    names: [
      "6-(4-Methylpiperazin-1-Yl)Pyrimidine-2,4-Diamine",
      "CHEMBL473662",
      "BAS 06575347",
      "AC1LFG9R",
      "2,4-Pyrimidinediamine, 6-(4-Methyl-1-Piperazinyl)-",
      "SCHEMBL2169898",
      "MolPort-002-005-545",
      "ZINC205303",
      "BDBM50304501",
      "CCG-24528",
      "AKOS000670805",
      "4940-96-9",
      "VU0047515-1",
    ],
  },
  {
    primary_id: "D0UK0O",
    names: ["D[Arg4,Dab8]VP", "CHEMBL385739"],
  },
  {
    primary_id: "D0UK1L",
    names: ["EP-217609"],
  },
  {
    primary_id: "D0UK1Q",
    names: ["Prosaptide TX14(A)"],
  },
  {
    primary_id: "D0UK1S",
    names: ["PMID26651364-Compound-126a"],
  },
  {
    primary_id: "D0UK2N",
    names: ["CMT-02"],
  },
  {
    primary_id: "D0UK2Z",
    names: ["Vitespen"],
  },
  {
    primary_id: "D0UK4O",
    names: ["KATSUMADAIN A"],
  },
  {
    primary_id: "D0UK5A",
    names: [
      "1,2-Di-P-Tolyl-Ethane-1,2-Dione",
      "4,4'-Dimethylbenzil",
      "3457-48-5",
      "1,2-Di-P-Tolylethane-1,2-Dione",
      "P-Tolil",
      "1,2-Bis(4-Methylphenyl)Ethane-1,2-Dione",
      "Ethanedione, Bis(4-Methylphenyl)-",
      "4,4'-Dimethyldibenzoyl",
      "Ethandione, Bis(P-Tolyl)-",
      "1,2-Dip-Tolylethane-1,2-Dione",
      "BCWCEHMHCDCJAD-UHFFFAOYSA-N",
      "MFCD00008554",
      "AK105959",
      "1,2-Bis(4-Methylphenyl)-1,2-Ethanedione",
      "AE-562/06268025",
      "Di-P-Tolylethanedione",
      "EINECS 222-388-2",
      "AC1Q5DTX",
      "ACMC-209i8d",
      "AC1L2S4L",
      "Benzil-Based Compound, 18",
      "SCHEMBL167254",
      "4,4'-Dimethylbenzil, 97%",
      "CHEMBL192625",
      "BDBM22740",
      "DTXSID60188116",
    ],
  },
  {
    primary_id: "D0UK7B",
    names: ["REGN1400"],
  },
  {
    primary_id: "D0UK7K",
    names: ["PMID27454349-Compound-91"],
  },
  {
    primary_id: "D0UK9D",
    names: ["Ac-I[CV(2Igl)QDWGAHRC]T-NH2"],
  },
  {
    primary_id: "D0UK9U",
    names: ["PMID30074415-Compound-12"],
  },
  {
    primary_id: "D0UL0K",
    names: ["Dihydrobenzoxazine And Tetrahydroquinoxaline Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D0UL0R",
    names: ["BK-C-0701"],
  },
  {
    primary_id: "D0UL2H",
    names: [
      "Androgen Restored Contraceptive",
      "A-ENDO",
      "ARC, Pantarhei",
      "Androgen Restored Contraceptive (Oral, Contraception)",
      "Androgen Restored Contraceptive (Oral, Contraception), Pantarhei",
      "Drug Combination (Oral, Endometriosis), Pantarhei",
      "Androgen/Estrogen/Progestogen Combination (Oral, Endometriosis), Pantarhei",
      "Dehydroepiandrosterone/Estrogen/Progestogen Combination (Oral, Endometriosis), Pantarhei",
    ],
  },
  {
    primary_id: "D0UL2I",
    names: ["PF-06427878"],
  },
  {
    primary_id: "D0UL2L",
    names: [
      "Lornoxicam",
      "CTX",
      "Chlortenoxicam",
      "Lorcam",
      "Safem",
      "Telos",
      "Xefo",
      "Xefo Rapid",
      "HN-10000",
      "TS-110",
      "Ro-13-9297",
    ],
  },
  {
    primary_id: "D0UL2R",
    names: ["PMID25399762-Compound-Table1-C20"],
  },
  {
    primary_id: "D0UL2V",
    names: ["PMID27828716-Compound-15"],
  },
  {
    primary_id: "D0UL2X",
    names: ["CC-90010"],
  },
  {
    primary_id: "D0UL5X",
    names: ["SK-7041"],
  },
  {
    primary_id: "D0UL6C",
    names: [
      "3,4-Dichlorobenzaldehyde Thiosemicarbazone",
      "CHEMBL415311",
      "6292-74-6",
      "2-(3,4-Dichlorobenzylidene)Hydrazine-1-Carbothioamide",
      "NSC9937",
      "AC1OR2L6",
      "HMS556D17",
      "DTXSID30428879",
      "OMVGKAWQTXZISA-UUILKARUSA-N",
      "MolPort-002-909-527",
      "NSC-9937",
      "BDBM50114648",
      "ZINC20164022",
      "AKOS008939634",
      "CCG-247690",
      "KB-279883",
    ],
  },
  {
    primary_id: "D0UL6I",
    names: [
      "3-(Octahydro-Quinolizin-1-Yl)-Phenol",
      "CHEMBL7381",
      "SCHEMBL7351093",
      "BDBM50010614",
      "AKOS022657925",
      "3-[(Octahydro-4H-Quinolizin)-1-Yl]phenol",
      "Isomer-23-(Octahydro-Quinolizin-1-Yl)-Phenol",
    ],
  },
  {
    primary_id: "D0UL6Y",
    names: ["Cis-2-(Para-Fluorophenyl)Cyclopropylamine", "CHEMBL446771"],
  },
  {
    primary_id: "D0UL7L",
    names: ["Recombinant Human Erbb3 Fragment Therapeutic Tumor Vaccine"],
  },
  {
    primary_id: "D0UL7N",
    names: [
      "3-DIPHENOL-6-NITRO-3H-BENZO[DE]ISOCHROMEN-1-ONE",
      "CHEMBL303950",
      "MR2",
      "3,3-Bis-(4-Hydroxy-Phenyl)-6-Nitro-3H-Benzo[de]isochromen-1-One",
      "1tsm",
      "AC1L9MC8",
      "Bis(4-Hydroxyphenyl)-Nitro-[?]one",
      "BDBM50077849",
      "DB08204",
      "3,3-Bis(4-Hydroxyphenyl)-6-Nitro-1H,3H-Benzo[de]isochromen-1-One",
      "3,3-Bis(4-Hydroxyphenyl)-6-Nitro-1H,3H-Naphtho[1,8-Cd]pyran-1-One",
    ],
  },
  {
    primary_id: "D0UL7R",
    names: ["SKA-31", "2-Aminonaphthiazole", "2-Amino-Beta-Naphthothiazole"],
  },
  {
    primary_id: "D0UL9R",
    names: ["Tesamorelin", "Egrifta (TN)"],
  },
  {
    primary_id: "D0UL9U",
    names: ["N-(2-Phenylcyclopropyl) Amino Acid Derivative 1"],
  },
  {
    primary_id: "D0UL9W",
    names: ["Heteroaryl-Azepine Derivative 3"],
  },
  {
    primary_id: "D0UM1I",
    names: [
      "4-Hydrazino-3-Pyridinesulfonamide",
      "4-Hydrazinylpyridine-3-Sulfonamide",
      "73742-76-4",
      "CHEMBL1164967",
      "4-Hydrazinopyridine-3-Sulfonamide",
      "MolPort-004-759-861",
      "3-Pyridinesulfonamide,4-Hydrazino-",
      "ZINC39054028",
      "BDBM50320510",
      "AKOS006341241",
      "AJ-97667",
      "3-Pyridinesulfonamide,4-Hydrazino-(9CI)",
      "KB-184865",
      "ST24043768",
      "FT-0705130",
    ],
  },
  {
    primary_id: "D0UM1Y",
    names: ["SNX-2"],
  },
  {
    primary_id: "D0UM3M",
    names: ["AQST-120"],
  },
  {
    primary_id: "D0UM4R",
    names: [
      "(2-Chloro-9-Methyl-9H-Purin-6-Yl)-Phenyl-Amine",
      "CHEMBL98779",
      "2-Chloro-9-Methyl-N6-Phenyladenine",
      "135394-17-1",
      "SCHEMBL183230",
      "DBTWDYPKBBODMU-UHFFFAOYSA-N",
      "BDBM50009679",
      "2-Chloro-9-Methyl-N-Phenyl-9H-Purin-6-Amine",
    ],
  },
  {
    primary_id: "D0UM4S",
    names: ["ASG-15ME"],
  },
  {
    primary_id: "D0UM6O",
    names: [
      "2-Chloro-4-(Thiazol-2-Yl)Thieno[3,2-D]pyrimidine",
      "CHEMBL260773",
      "2-Chloro-4-(2-Thiazolyl)Thieno[3,2-D]pyrimidine",
      "443148-15-0",
      "SCHEMBL3679573",
      "GWBPGRWRSLAHEX-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0UM7H",
    names: ["BIA-12"],
  },
  {
    primary_id: "D0UM7O",
    names: [
      "Ketamine",
      "Calypsol",
      "Cetamina",
      "Green",
      "Ketaject",
      "Ketalar",
      "Ketaminum",
      "Ketanest",
      "Ketoject",
      "Ketolar",
      "Tekam",
      "KETAMINE HCL",
      "Ketalar Base",
      "Ketamine Base",
      "Special K",
      "Special K [street Name]",
      "CLSTA 20",
      "T385",
      "Cetamina [INN-Spanish]",
      "Dl-Ketamine",
      "Ketalar (TN)",
      "Ketamine (INN)",
      "Ketamine [INN:BAN]",
      "Ketaminum [INN-Latin]",
      "Ketanest (TN)",
      "Ketaset (TN)",
      "Tekam (TN)",
      "Cyclohexanone, 2-(2-Chlorophenyl)-2-(Methylamino)-(9CI)",
      "Cyclohexanone, 2-(2-Chlorophenyl)-2-(Methylamino)-, (+-)-(9CI)",
      "Cyclohexanone, 2-(O-Chlorophenyl)-2-(Methylamino)-, (+/-)-(8CI)",
      "(+/-)-2-(2-Chlorophenyl)-2-(Methylamino)Cyclohexanone",
      "(+/-)-2-(O-Chlorophenyl)-2-(Methylamino)Cyclohexanone",
      "2-(2-Chlorophenyl)-2-(Methylamino)Cyclohexanone",
      "2-(2-Chlorophenyl)-2-(Methylamino)Cyclohexan-1-One",
      "2-(Methylamino)-2-(2-Chlorophenyl)Cyclohexanone",
      "2-(O-Chlorophenyl)-2-(Methylamino)-Cyclohexanone",
      "2-(O-Chlorophenyl)-2-(Methylamino)Cyclohexanone",
    ],
  },
  {
    primary_id: "D0UM8Q",
    names: ["UCART019"],
  },
  {
    primary_id: "D0UN0E",
    names: ["PHENYLDIFLUOROMETHANESULFONAMIDE"],
  },
  {
    primary_id: "D0UN0R",
    names: ["Kazinol F"],
  },
  {
    primary_id: "D0UN1S",
    names: ["Tigapotide"],
  },
  {
    primary_id: "D0UN4M",
    names: ["Six-Membered Heterocyclic Benzamide Derivative 5"],
  },
  {
    primary_id: "D0UN6P",
    names: ["N-(3-Chlorophenyl)-6-Nitroquinazolin-4-Amine"],
  },
  {
    primary_id: "D0UN8H",
    names: ["IC261"],
  },
  {
    primary_id: "D0UN8L",
    names: ["OPB-111077"],
  },
  {
    primary_id: "D0UN9O",
    names: ["GTP-14564"],
  },
  {
    primary_id: "D0UN9X",
    names: ["Imidazopyridazine Derivative 4"],
  },
  {
    primary_id: "D0UO0C",
    names: ["MAK683"],
  },
  {
    primary_id: "D0UO0Y",
    names: ["Cypate-[(RGD)3-NH2]2"],
  },
  {
    primary_id: "D0UO1E",
    names: ["Para-Sirtinol"],
  },
  {
    primary_id: "D0UO2H",
    names: ["NMS-P715"],
  },
  {
    primary_id: "D0UO2N",
    names: ["BUTIXOCORT"],
  },
  {
    primary_id: "D0UO2P",
    names: [
      "BLU-285",
      "Avapritinib",
      "1703793-34-3",
      "UNII-513P80B4YJ",
      "513P80B4YJ",
      "(1S)-1-(4-Fluorophenyl)-1-[2-[4-[6-(1-Methylpyrazol-4-Yl)Pyrrolo[2,1-F][1,2,4]triazin-4-Yl]piperazin-1-Yl]pyrimidin-5-Yl]ethanamine",
      "Avapritinib [INN]",
      "BLU-285 (Avapritinib)",
      "SCHEMBL16652297",
      "EX-A1366",
      "BCP20175",
      "CS-7577",
      "ACN-051245",
      "HY-101561",
      "(S)-1-(4-Fluorophenyl)-1-(2-(4-(6-(1-Methyl-1H-Pyrazol-4-Yl)Pyrrolo[2,1-F][1,2,4]triazin-4-Yl)Piperazin-1-Yl)Pyrimidin-5-Yl)Ethanamine",
    ],
  },
  {
    primary_id: "D0UO2Q",
    names: ["Calhex 231", "Calhex-231"],
  },
  {
    primary_id: "D0UO2Z",
    names: ["4-(2-(4-Cyclohexylphenylamino)Ethyl)-1H-Imidazole", "CHEMBL1098229"],
  },
  {
    primary_id: "D0UO3I",
    names: ["HEPT"],
  },
  {
    primary_id: "D0UO4B",
    names: ["RQ-00000005", "Capromorelin"],
  },
  {
    primary_id: "D0UO6M",
    names: ["ReN-004"],
  },
  {
    primary_id: "D0UO6Q",
    names: ["2-Methyl-7-Phenylquinoline", "CHEMBL244418", "7-Phenylchinaldin"],
  },
  {
    primary_id: "D0UO7V",
    names: ["DW-800"],
  },
  {
    primary_id: "D0UO9T",
    names: ["VR-147"],
  },
  {
    primary_id: "D0UP0A",
    names: ["CAL-201"],
  },
  {
    primary_id: "D0UP1U",
    names: [
      "Recombinant Plasmin",
      "Delta [K2-K5] Plasmin",
      "Recombinant Plasmin (Vascular Occlusion/Ocular Disease)",
      "RPlasmin (Vascularocclusion/Ocular Disease), Talecris Biotherapeutics/Bausch & Lomb",
      "Recombinant Plasmin (Vascular Occlusion/Ocular Disease), Talecris Biotherapeutics/Bausch & Lomb",
    ],
  },
  {
    primary_id: "D0UP1Z",
    names: ["GDC-0032"],
  },
  {
    primary_id: "D0UP2Y",
    names: ["RVEc"],
  },
  {
    primary_id: "D0UP5N",
    names: ["PN-2107"],
  },
  {
    primary_id: "D0UP6G",
    names: [
      "4-Amino-Biphenyl-3-Carboxylic Acid Amide",
      "CHEMBL207940",
      "5-Phenylanthranilamide",
      "2-Amino-5-Phenylbenzamide",
      "SCHEMBL3781950",
      "Amino-Substituted Benzamide, 8c",
      "BDBM27462",
      "ZINC13686738",
    ],
  },
  {
    primary_id: "D0UP8U",
    names: ["Anti-CD123 CAR-T Treatment"],
  },
  {
    primary_id: "D0UP8X",
    names: ["(4S,5R)-4,5-Diethyl-Oxazolidin-(2Z)-Ylideneamine", "CHEMBL108319", "BDBM50138198"],
  },
  {
    primary_id: "D0UP9M",
    names: ["Gusperimus Trihydrochloride"],
  },
  {
    primary_id: "D0UQ1R",
    names: ["Eloctate"],
  },
  {
    primary_id: "D0UQ2G",
    names: ["Tri-Substituted Urea Derivative 1"],
  },
  {
    primary_id: "D0UQ2R",
    names: ["Pyrido[1,2-A]indole-1.-Carboxylic Acid Analog 2"],
  },
  {
    primary_id: "D0UQ5B",
    names: [
      "GSK-1437173A",
      "Adult Herpes Zoster Vaccine (Shingles Prophylaxis), GlaxoSmithKline",
      "Adult Varicella Zoster Vaccine (Attenuated Recombinant Viralvaccine), GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D0UQ5I",
    names: [
      "Botulinum Toxin Vaccine",
      "Botulinum Toxin Vaccine, Molecular Targeting Technologies",
      "Recombinant Rabies Virus Expressing Chimeric BoNT/A HC50, Molecular Targeting Technologies",
    ],
  },
  {
    primary_id: "D0UQ6A",
    names: ["PMID2231594C3q"],
  },
  {
    primary_id: "D0UQ6J",
    names: ["RO-194603"],
  },
  {
    primary_id: "D0UQ6U",
    names: [
      "Abietic Acid",
      "ABIETIC ACID",
      "514-10-3",
      "Sylvic Acid",
      "Abietate",
      "L-Abietic Acid",
      "Rosin Acid",
      "7,13-Abietadien-18-Oic Acid",
      "Kyselina Abietova",
      "Kyselina Abietova [Czech]",
      "UNII-V3DHX33184",
      "CCRIS 3183",
      "Abieta-7,13-Dien-18-Oic Acid",
      "Abietic Acid, Technical",
      "13-Isopropylpodocarpa-7,13-Dien-15-Oic Acid",
      "EINECS 208-178-3",
      "NSC 25149",
      "Abietinic Acid",
      "AI3-17273",
      "CHEBI:28987",
      "V3DHX33184",
      "NSC25149",
      "(-)-Abietic Acid",
      "MFCD03423567",
      "Abietic Acid, 85%",
      "NCGC00166273-01",
      "1-Phenanthrenecarboxylic Acid, 1,2,3,4,4a,4b,5,6,10,10a-Decahydro-1,4a-Dimethyl-7-(1-Methylethyl)-,",
    ],
  },
  {
    primary_id: "D0UR1H",
    names: ["Peptide Analog 54"],
  },
  {
    primary_id: "D0UR1Z",
    names: [
      "OncoVAX",
      "Cancer Vaccine, Vaccinogen",
      "OncoVAX, Vaccinogen",
      "Autologous Vaccine (Colon Cancer), Vaccinogen",
      "OncoVAX-CL, Intracel",
      "Vaccine(Colon Cancer), Intracel",
      "Vaccine (Colon Cancer), Vaccinogen",
    ],
  },
  {
    primary_id: "D0UR3U",
    names: [
      "NAS181",
      "CHEMBL1201939",
      "AC1N6EAL",
      "CHEMBL57406",
      "SCHEMBL3366880",
      "GTPL3226",
      "CHEBI:91593",
      "BDBM50369357",
      "L000434",
      "BRD-A23683907-334-01-0",
      "4-{[8-(Morpholin-2-Ylmethoxy)-2H-Chromen-3-Yl]methyl}morpholine",
    ],
  },
  {
    primary_id: "D0UR4K",
    names: [
      "Cenerimod",
      "1262414-04-9",
      "UNII-Y333RS1786",
      "ACT-334441",
      "Y333RS1786",
      "Cenerimod [INN]",
      "Cenerimod [USAN]",
      "SCHEMBL2671193",
      "GTPL9824",
      "MolPort-044-723-844",
      "KS-000006AE",
      "ZINC167253016",
      "AKOS030630068",
      "CS-6364",
      "DB12705",
      "1,2-Propanediol, 3-(4-(5-(2-Cyclopentyl-6-Methoxy-4-Pyridinyl)-1,2,4-Oxadiazol-3-Yl)-2-Ethyl-6-Methylphenoxy)-, (2S)-",
      "(S)-3-((4-(5-(2-Cyclopentyl-6-Methoxypyridin-4-Yl)(1,2,4)Oxadiazol-3-Yl)-2-Ethyl-6-Methylphenyl)Oxy)Propane-1,2-Diol",
      "HY-17606",
      "AS-35045",
      "(S)-3-(4-(5-(2-Cyclopentyl-6-Methoxypyridin-4-Yl)-1,2,4-Oxadiazol",
    ],
  },
  {
    primary_id: "D0UR4L",
    names: ["SF-110"],
  },
  {
    primary_id: "D0UR6C",
    names: ["3-[5-(4-Fluorophenyl)-2-Thienyl]phenol", "CHEMBL570368", "SCHEMBL1180762"],
  },
  {
    primary_id: "D0UR7D",
    names: ["Benzyl Derivative Of M6G", "CHEMBL366917"],
  },
  {
    primary_id: "D0UR7K",
    names: ["1-Aryl-4-Methyl-[1,2,4]triazolo[4,3-A]quinoxaline Derivative 1"],
  },
  {
    primary_id: "D0UR7U",
    names: ["Cudratricusxanthone F"],
  },
  {
    primary_id: "D0UR9D",
    names: ["3-Benzyl-2-Mercapto-6-Nitroquinazolin-4(3H)-One", "CHEMBL1093884", "BDBM50316110"],
  },
  {
    primary_id: "D0US0J",
    names: [
      "CORDOIN",
      "Cordoin",
      "Derricidin",
      "Trans-1-(2-Hydroxy-4-((3-Methyl-2-Butenyl)Oxy)Phenyl)-3-Phenyl-2-Propen-1-One",
      "2-Propen-1-One, 1-(2-Hydroxy-4-((3-Methyl-2-Butenyl)Oxy)Phenyl)-3-Phenyl-, (E)-",
      "38965-74-1",
      "Substituted Chalcone, 5a",
      "AC1NZGI9",
      "AC1Q5EJC",
      "CHEMBL450771",
      "SCHEMBL18265486",
      "BDBM29137",
      "51619-65-9",
      "2'-Hydroxy-4'-(Prenyloxy)Chalcone",
      "NSC270894",
      "LMPK12120040",
      "NSC-270894",
      "1-{2-Hydroxy-4-[(3-Methylbut-2-En-1-Yl)Oxy]phenyl}-3-Phenylprop-2-En-1-One",
      "LS-123926",
      "J3.608.736F",
    ],
  },
  {
    primary_id: "D0US1X",
    names: ["OCT-1547"],
  },
  {
    primary_id: "D0US2X",
    names: [
      "3-(4-Fluorophenyl)-2-Nortropene",
      "CHEMBL570638",
      "3-(4-Fluorophenyl)-8-Azabicyclo[3.2.1]oct-3-Ene",
      "SCHEMBL6993886",
      "LKSXLNMBLGXHQS-UHFFFAOYSA-N",
      "BDBM50303306",
      "163630-91-9",
    ],
  },
  {
    primary_id: "D0US3C",
    names: ["ZK-90695"],
  },
  {
    primary_id: "D0US4S",
    names: [
      "[3H]5-Oxo-ETE",
      "5-Oxo-ETE",
      "5-Oxoete",
      "5-Oxo-6E,8Z,11Z,14Z-Eicosatetraenoic Acid",
      "5-Kete",
      "5-Oxoeicosatetraenoic Acid",
      "5-Ketoeicosatetraenoic Acid",
      "CHEMBL18028",
      "CHEBI:52449",
      "(6E,8Z,11Z,14Z)-5-Oxoicosa-6,8,11,14-Tetraenoic Acid",
      "106154-18-1",
      "5-Oxo-Eicosatetraenoate",
      "5-Oxoicosatetraenoic Acid",
      "[3H]-5-Oxo-ETE",
      "5-Keto-6,8,11,14-Eicosatetraenoic Acid",
      "5-Keto-ETE",
      "ETE-5-Oxo",
      "AC1NR1NN",
      "BSPBio_001453",
      "SCHEMBL139762",
      "BML1-D12",
      "GTPL3391",
      "GTPL3416",
      "MEASLHGILYBXFO-XTDASVJISA-N",
      "HMS3402I15",
      "HMS1791I15",
      "HMS1989I15",
      "HMS1361I15",
      "ZINC4655377",
      "LMFA03060011",
    ],
  },
  {
    primary_id: "D0US5I",
    names: ["C-Linked Disaccharide Biphenyl Mannoside Derivative 2"],
  },
  {
    primary_id: "D0US8M",
    names: ["OX-CLI", "BLX-CLI", "Anti-Inflammatory (Asthma), Biolipox", "Anti-Inflammatory (Asthma), Orexo"],
  },
  {
    primary_id: "D0US9O",
    names: ["PMID24359159C19a"],
  },
  {
    primary_id: "D0UT0X",
    names: ["Aromatic Hydrazine Carboxyimidoamide Derivative 2"],
  },
  {
    primary_id: "D0UT1G",
    names: ["PMID25980951-Compound-12"],
  },
  {
    primary_id: "D0UT1X",
    names: [
      "1,2-Bis-(3-Nitro-Phenyl)-Ethane-1,2-Dione",
      "3,3'-Dinitrobenzil",
      "3,5'-Dinitrobenzil",
      "Benzil, 3,5'-Dinitro-",
      "Bis(M-Nitrophenyl)Ethanedione",
      "1,2-Bis(3-Nitrophenyl)Ethane-1,2-Dione",
      "5913/6/4",
      "Ethanedione, Bis(3-Nitrophenyl)-",
      "NSC 408976",
      "BRN 2003620",
      "1,2-Bis{3-Nitrophenyl}-1,2-Ethanedione",
      "AI3-61771",
      "NSC408976",
      "Benzil,5'-Dinitro-",
      "AC1L2QPJ",
      "Bis-(M,m'-Nitrobenzil)",
      "Benzil-Based Compound, 29",
      "AC1Q5B0Z",
      "SCHEMBL2156825",
      "CHEMBL191796",
      "BDBM22751",
      "CTK7F3460",
      "DTXSID90207869",
      "MolPort-003-803-953",
      "ZINC1601014",
      "NSC-408976",
      "1,2-Bis(M-Nitrophenyl)Ethane-1,2-Dione",
      "LS-32448",
      "Ethanedione, Bis(3",
    ],
  },
  {
    primary_id: "D0UT2X",
    names: [
      "3-Chloro-1-(4-Hydroxyphenyl)Propan-1-One",
      "7182-38-9",
      "3-Chloro-4'-Hydroxypropiophenone",
      "CHEMBL500579",
      "NSC46501",
      "AC1Q5ECM",
      "SCHEMBL2392675",
      "AC1L650T",
      "CTK5D5119",
      "DTXSID20286561",
      "QRXBKSZGQHXBDO-UHFFFAOYSA-N",
      "MolPort-022-892-113",
      "ZINC1678195",
      "BDBM50255245",
      "NSC-46501",
      "AKOS022516648",
      "KS-000009D4",
      "SY028371",
      "CS-11693",
      "1-Propanone,3-Chloro-1-(4-Hydroxyphenyl)-",
      "MFCD18393259 (97%)",
      "3-Chloro-4 Inverted Exclamation Mark -Hydroxypropiophenone",
    ],
  },
  {
    primary_id: "D0UT3F",
    names: ["Nolpitantium Besilate"],
  },
  {
    primary_id: "D0UT4W",
    names: ["BGB-3111"],
  },
  {
    primary_id: "D0UT5A",
    names: ["SGN-CD19A"],
  },
  {
    primary_id: "D0UT7F",
    names: ["3K3A-APC"],
  },
  {
    primary_id: "D0UT7X",
    names: ["Tideglusib"],
  },
  {
    primary_id: "D0UT7Y",
    names: ["N1-(3-Phenoxybenzyl)-N3-Hydroxymalonamide", "CHEMBL220392"],
  },
  {
    primary_id: "D0UT9H",
    names: ["Cyclohexyl Carbamate Derivative 5"],
  },
  {
    primary_id: "D0UU0V",
    names: ["1,5-Di-Substituted Pyridine Derivative 1"],
  },
  {
    primary_id: "D0UU1E",
    names: ["CC-100"],
  },
  {
    primary_id: "D0UU1I",
    names: ["Heme"],
  },
  {
    primary_id: "D0UU2U",
    names: ["Isoxazole-Based Bicyclic Compound 11"],
  },
  {
    primary_id: "D0UU3J",
    names: ["RWJ-37868"],
  },
  {
    primary_id: "D0UU4B",
    names: ["CLS006"],
  },
  {
    primary_id: "D0UU4D",
    names: ["Peniel-5000"],
  },
  {
    primary_id: "D0UU5N",
    names: ["PMID28594589-Compound-TABLE3C14"],
  },
  {
    primary_id: "D0UU5O",
    names: [
      "5-HT 2c Receptor Agonists (Obesity/Schizophrenia), Galenea",
      "5-HT 2c Receptor Agonists (Obesity), Organix/Galenea",
    ],
  },
  {
    primary_id: "D0UU7G",
    names: ["PMID25980951-Compound-45"],
  },
  {
    primary_id: "D0UU9Y",
    names: ["Propentofylline Propionate"],
  },
  {
    primary_id: "D0UV1C",
    names: ["3,6-Bis(Dmt-Tic-NH-Butyl)-2(1H)-Pyrazinone", "CHEMBL373668"],
  },
  {
    primary_id: "D0UV1S",
    names: ["O-Methyldauricine"],
  },
  {
    primary_id: "D0UV2E",
    names: ["VOLT02"],
  },
  {
    primary_id: "D0UV2N",
    names: ["1,1,1,2,2,3,3-Heptafluoro-8-Phenyloctan-4-Ol"],
  },
  {
    primary_id: "D0UV3I",
    names: ["Imidazo Pyridine Derivative 2"],
  },
  {
    primary_id: "D0UV4H",
    names: ["GSK-706769"],
  },
  {
    primary_id: "D0UV6L",
    names: ["[3H](S)-Zacopride", "GTPL4074"],
  },
  {
    primary_id: "D0UV8T",
    names: [
      "4-(1H-1,2,3-Triazol-5-Yl)Pyridine",
      "(4-Pyridyl)Triazole",
      "SCHEMBL4806159",
      "CHEMBL364898",
      "ZINC27317",
      "BDBM17472",
      "1,2,3-Triazole Analogue, 28",
      "AKOS027406024",
    ],
  },
  {
    primary_id: "D0UV9F",
    names: ["GSK-894281"],
  },
  {
    primary_id: "D0UV9G",
    names: [
      "6-Phenyl-Naphthalen-2-Ol",
      "6-Phenyl-2-Naphthol",
      "6-Phenylnaphthalene-2-Ol",
      "CHEMBL191714",
      "SCHEMBL3019399",
      "PZOXFBPKKGTQDZ-UHFFFAOYSA-N",
      "ZINC13644999",
    ],
  },
  {
    primary_id: "D0UW3X",
    names: ["Laxatives"],
  },
  {
    primary_id: "D0UW4A",
    names: [
      "Benzimate",
      "AMP-507",
      "ATI-0312",
      "FB-532",
      "FB-633",
      "FB-639",
      "FB-642",
      "FB-645",
      "FB-649",
      "FB-651",
      "FB-655",
      "PG-026",
      "PG-045",
      "Benzimidazole Series (Cancer), AmpliMed",
      "AMP-500 Series (Cancer), AmpliMed",
    ],
  },
  {
    primary_id: "D0UW5J",
    names: ["PMID27215781-Compound-1"],
  },
  {
    primary_id: "D0UW6S",
    names: ["PMID29053063-Compound-4"],
  },
  {
    primary_id: "D0UW7D",
    names: ["Varicella Zoster Virus Vaccine Live"],
  },
  {
    primary_id: "D0UW8Q",
    names: ["PMID29649907-Compound-32"],
  },
  {
    primary_id: "D0UW9I",
    names: ["(S)-2-Methyl-1-(2-M-Tolyl-Ethyl)-Pyrrolidine", "CHEMBL257914"],
  },
  {
    primary_id: "D0UX0T",
    names: ["Milameline"],
  },
  {
    primary_id: "D0UX1S",
    names: ["PMID28067079-Compound-95"],
  },
  {
    primary_id: "D0UX2H",
    names: ["HG-1109"],
  },
  {
    primary_id: "D0UX2V",
    names: ["BMS-817399"],
  },
  {
    primary_id: "D0UX3T",
    names: [
      "3-(5-Thiophen-3-Yl-Pyridin-3-Yl)-1H-Indole",
      "3-(5-Thien-3-Ylpyridin-3-Yl)-1Hindole 3a",
      "AC1NS6N5",
      "SCHEMBL7506919",
      "BDBM5318",
      "CHEMBL327518",
      "3-[5-(3-Thienyl)-3-Pyridinyl]-1H-Indole",
      "3-(5-Thiophen-3-Ylpyridin-3-Yl)-1H-Indole",
    ],
  },
  {
    primary_id: "D0UX4A",
    names: ["HU-433", "CB2 Activator (Osteoporosis)", "CB2 Activator (Osteoporosis), Yissum"],
  },
  {
    primary_id: "D0UX4C",
    names: ["KNI-10316"],
  },
  {
    primary_id: "D0UX4J",
    names: ["Dimethoxybenzylidene-2-Thio-Imidazole-4-One Derivative 1"],
  },
  {
    primary_id: "D0UX5I",
    names: ["PMID27967267-Compound-14"],
  },
  {
    primary_id: "D0UX5M",
    names: [
      "N,N-Dimethyl(Pyridin-3-Yl)Methanamine",
      "Dimethyl-Pyridin-3-Ylmethyl-Amine",
      "2055-21-2",
      "N,N-Dimethyl-1-(Pyridin-3-Yl)Methanamine",
      "CHEMBL331904",
      "N,N-Dimethyl-3-Pyridinemethanamine",
      "3-Pyridinemethanamine, N,N-Dimethyl-",
      "AC1OK4VN",
      "3-Pyridylmethyl Dimethylamine",
      "3-Pyridinylmethyldimethylamine",
      "SCHEMBL3460939",
      "Dimethyl(Pyridin-3-Ylmethyl)Amine",
      "MolPort-000-165-254",
      "ZINC4716433",
      "BDBM50119559",
      "AKOS009071365",
      "N,N-Dimethyl-1-Pyridin-3-Ylmethanamine",
      "DB-066238",
    ],
  },
  {
    primary_id: "D0UX6Z",
    names: [
      "Heparin Sodium",
      "(2S,3S,4R,5R,6R)-6-{[(2S,3S,4S,5R,6S)-6-{[(2R,3S,4S,5R)-2-Carboxy-4,6-Dihydroxy-5-(Sulfooxy)Oxan-3-Yl]oxy}-2-Hydroxy-4-(Sulfomethyl)-5-(Sulfooxy)Oxan-3-Yl]oxy}-3-{[(2R,3R,4R,5S,6R)-3-Acetamido-4,5-Dihydroxy-6-[(Sulfooxy)Methyl]oxan-2-Yl]oxy}-4,5-Dihydroxyoxane-2-Carboxylic Acid",
      "HepFlush",
      "GTPL4214",
      "CHEMBL526514",
      "Heparin Sodium 10,000 Units And Dextrose 5% In Plastic Container",
    ],
  },
  {
    primary_id: "D0UY1A",
    names: ["PMID26004420-Compound-US20140142115B"],
  },
  {
    primary_id: "D0UY3J",
    names: ["PMID27454349-Compound-96"],
  },
  {
    primary_id: "D0UY7A",
    names: ["Pyridine-Amide Derivative 1"],
  },
  {
    primary_id: "D0UY8K",
    names: ["PMID28766366-Compound-Scheme8NCGC-00185981"],
  },
  {
    primary_id: "D0UY8U",
    names: ["SNX-002"],
  },
  {
    primary_id: "D0UY9Y",
    names: ["PMID25991433-Compound-P2"],
  },
  {
    primary_id: "D0UZ0X",
    names: ["ACH-2892"],
  },
  {
    primary_id: "D0UZ1I",
    names: ["S-33138"],
  },
  {
    primary_id: "D0UZ2S",
    names: ["PMID26651364-Compound-6d"],
  },
  {
    primary_id: "D0UZ4G",
    names: ["99mTc-RBitistatin"],
  },
  {
    primary_id: "D0UZ4J",
    names: ["SB-334867"],
  },
  {
    primary_id: "D0UZ4S",
    names: ["Peptide Analog 22"],
  },
  {
    primary_id: "D0UZ5N",
    names: ["WAY-125971"],
  },
  {
    primary_id: "D0UZ5W",
    names: [
      "4-Chloro-N-(2-Chloro-Benzoyl)-Benzenesulfonamide",
      "AC1LJL3L",
      "CHEMBL189826",
      "MolPort-000-293-674",
      "ZINC617836",
      "AKOS002786758",
      "MCULE-4723966083",
      "2-Chloro-N-(4-Chlorophenyl)Sulfonylbenzamide",
      "SR-01000270508",
    ],
  },
  {
    primary_id: "D0UZ6C",
    names: ["Five-Membered Heteroaromatic Compound 3"],
  },
  {
    primary_id: "D0UZ7I",
    names: ["N-Naphtyl-N-Benzylurea Derivative 1"],
  },
  {
    primary_id: "D0UZ8R",
    names: [
      "1,2-Bis-(4-Methoxy-Phenyl)-Ethane-1,2-Dione",
      "4,4'-Dimethoxybenzil",
      "1226-42-2",
      "P-Anisil",
      "Anisil",
      "1,2-Bis(4-Methoxyphenyl)Ethane-1,2-Dione",
      "Bis(4-Methoxyphenyl)Ethanedione",
      "Ethanedione, Bis(4-Methoxyphenyl)-",
      "Di-P-Anisoyl",
      "P,p'-Dimethoxybenzil",
      "1,2-Ethanedione, 1,2-Bis(4-Methoxyphenyl)-",
      "YNANGXWUZWWFKX-UHFFFAOYSA-N",
      "MFCD00008405",
      "1,2-Bis(4-Methoxyphenyl)-1,2-Ethanedione",
      "1,2-Bis(4-Methoxyphenyl)-Ethane-1,2-Dione",
      "AE-641/01115007",
      "W-108428",
      "4,4'-Dimethoxybenzil, 99+%",
      "EINECS 214-960-5",
      "NSC 19218",
      "ACMC-1BZIL",
      "4,4\\'-Dimethoxybenzil",
    ],
  },
  {
    primary_id: "D0UZ9U",
    names: ["PREMETREXED", "[3H]pemetrexed"],
  },
  {
    primary_id: "D0V0AF",
    names: ["Seviprotimut-L"],
  },
  {
    primary_id: "D0V0AI",
    names: ["Oxalacetic Acid"],
  },
  {
    primary_id: "D0V0AR",
    names: ["HLA-A, B7.1-Transfected Adenocarcinoma Vaccine"],
  },
  {
    primary_id: "D0V0AW",
    names: ["TC-2559"],
  },
  {
    primary_id: "D0V0BC",
    names: ["N-Substituted Pyrazole Derivative 2"],
  },
  {
    primary_id: "D0V0BD",
    names: ["GFT-1229"],
  },
  {
    primary_id: "D0V0BO",
    names: ["Anti-FGFR3"],
  },
  {
    primary_id: "D0V0CJ",
    names: ["CO-101"],
  },
  {
    primary_id: "D0V0DB",
    names: ["9-Hydroxy-7,8-Benzoflavone", "CHEMBL1097042"],
  },
  {
    primary_id: "D0V0DI",
    names: [
      "CT-2584",
      "Apra (Cell Therapeutics, US, US)",
      "1-[11-(Dodecylamino)-10-Hydroxyundecyl]-3,7-Dimethylxanthine",
    ],
  },
  {
    primary_id: "D0V0FE",
    names: ["GMI-1359"],
  },
  {
    primary_id: "D0V0FG",
    names: [
      "4-Cyclohexyl-4-Hydroxy-But-2-Enoic Acid",
      "CHEMBL171331",
      "4-Cyclohexyl-4-Hydroxybut-2-Enoic Acid",
      "NSC400155",
      "AC1NTOGK",
      "AC1Q5T8F",
      "BDBM50023573",
      "AKOS022641700",
      "NSC-400155",
      "(E)-4-Cyclohexyl-4-Hydroxybut-2-Enoic Acid",
    ],
  },
  {
    primary_id: "D0V0FQ",
    names: ["Brain Derived Neurotrophic Factor"],
  },
  {
    primary_id: "D0V0FY",
    names: ["8-Propionyl-7-Propoxy-2H-Chromen-2-One", "CHEMBL1288623"],
  },
  {
    primary_id: "D0V0GF",
    names: [
      "Antiparasitic Agents",
      "Antiparasitic Agents (Natural Products, Malaria)",
      "Antiparasitic Agents (Plasmodium Infection), Mycosynthetix/University Of South Florida",
      "Antiparasitic Agents (Natural Products, Malaria), Mycosynthetix/University Of South Florida",
    ],
  },
  {
    primary_id: "D0V0GM",
    names: ["Oxalyl-DAP Derivative"],
  },
  {
    primary_id: "D0V0GZ",
    names: ["RG7440"],
  },
  {
    primary_id: "D0V0HH",
    names: ["Azelaoyl-PAF", "Azelaoyl PAF"],
  },
  {
    primary_id: "D0V0HJ",
    names: [
      "8-Amino-1,3-Dimethyl-3,7-Dihydropurine-2,6-Dione",
      "8-Aminotheophylline",
      "8-Aminoteofiliny",
      "Theophylline, 8-Amino-",
      "19410-53-8",
      "8-Aminoteofiliny [Polish]",
      "8-AMINO-1,3-DIMETHYL-3,7-DIHYDROPURINE-2,6-DIONE",
      "1H-Purine-2,6-Dione, 8-Amino-3,7-Dihydro-1,3-Dimethyl-",
      "8-Amino-1,3-Dimethyl-3,7-Dihydro-1H-Purine-2,6-Dione",
      "8-Amino-1,3-Dimethyl-7H-Purine-2,6-Dione",
      "8-Amino-1,3-Dimethyl-3,9-Dihydro-1H-Purine-2,6-Dione",
      "8-Amino-1,3-Dimethyl-2,3,6,7-Tetrahydro-1H-Purine-2,6-Dione",
      "8-Aminotheophyline",
      "1rs2",
      "AC1L3TFO",
      "AC1Q6LC1",
      "Oprea1_659892",
      "Oprea1_298061",
      "Oprea1_442990",
      "CHEMBL298737",
    ],
  },
  {
    primary_id: "D0V0HM",
    names: ["Adp[-Trp-Arg-Nva-Arg-Tyr-NH2]2"],
  },
  {
    primary_id: "D0V0HU",
    names: [
      "IFN Alpha Kinoid",
      "Antiferon",
      "IFNa Kinoid",
      "IFN-K",
      "Inactivated IFN Alpha + Keyhole Limpet Hemocyanin (SLE/HIV), Neovacs",
      "Anti-Interferonalpha (HIV/SLE), Neovacs/Sanofi Pasteur",
    ],
  },
  {
    primary_id: "D0V0IG",
    names: ["Human Recombinant Polyclonal Anitbodies"],
  },
  {
    primary_id: "D0V0IO",
    names: ["NN9223"],
  },
  {
    primary_id: "D0V0IX",
    names: ["Epoprostenol"],
  },
  {
    primary_id: "D0V0JC",
    names: ["C[RGD-(S)-Alpha-TfmfV]", "CHEMBL379056"],
  },
  {
    primary_id: "D0V0JN",
    names: ["PMID25416646-Compound-Figure5-C"],
  },
  {
    primary_id: "D0V0JQ",
    names: ["CHF 5407"],
  },
  {
    primary_id: "D0V0JR",
    names: ["Cadi-05"],
  },
  {
    primary_id: "D0V0JV",
    names: ["MabionHER2"],
  },
  {
    primary_id: "D0V0KA",
    names: ["PMID20462760C22"],
  },
  {
    primary_id: "D0V0KI",
    names: ["Cycloalkyl Nitrile Pyrazole Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0V0KJ",
    names: ["IDDBCP161883"],
  },
  {
    primary_id: "D0V0LA",
    names: [
      "CGP-35582",
      "CHEMBL113396",
      "SCHEMBL341418",
      "Phosphinic Acid, P-(3-Amino-1-Methylpropyl)-P-Methyl-",
      "CCFBFTKQKRGULP-UHFFFAOYSA-N",
      "BDBM50032971",
      "133345-75-2",
      "P-(4-Aminobut-2-Yl)-P-Methyl-Phosphinic Acid",
      "(3-Amino-1-Methyl-Propyl)-Methyl-Phosphinic Acid",
    ],
  },
  {
    primary_id: "D0V0LB",
    names: [
      "Cysteamine",
      "Becaptan",
      "Cisteamina",
      "Cystavision",
      "Cysteamide",
      "Cysteamin",
      "Cysteinamine",
      "Decarboxycysteine",
      "Ethanethiolamine",
      "Lambraten",
      "Lambratene",
      "MEA",
      "Mecramine",
      "Mercamin",
      "Mercamine",
      "Mercaptamin",
      "Mercaptamina",
      "Mercaptamine",
      "Mercaptaminum",
      "Mercaptoethylamine",
      "Merkamin",
      "Riacon",
      "Thioethanolamine",
      "Aminoethyl Mercaptan",
      "Cisteamina [Italian]",
      "Cysteamine Bitartate",
      "L 1573",
      "WR 347",
      "Beta-Aminoethanethiol",
      "Beta-Aminoethylthiol",
      "Beta-MEA",
      "Beta-Mercaptoethylamine",
      "C-9500",
      "Cysteamine (USAN)",
      "Cysteamine [USAN:BAN]",
      "L-1573",
      "MEA (Mercaptan)",
      "Mercaptamina [INN-Spanish]",
      "Mercaptamine (INN)",
      "Mercaptaminum [INN-Latin]",
      "(2-Mercaptoethyl)Amine",
      "(Mercaptoethyl)Ammonium Toluene-P-Sulphonate",
      "1-Amino-2-Mercaptoethylamine",
      "2-AMINO-ETHANETHIOL",
      "2-Amino-1-Ethanethiol",
      "2-Aminoethanethiol",
      "2-Aminoethyl Mercaptan",
      "2-Mercaptoethanamine",
      "2-Mercaptoethylamine",
      "2-Mercaptoethylamine, Polymer-Bound",
      "641022_ALDRICH",
    ],
  },
  {
    primary_id: "D0V0LU",
    names: ["Ohioensin C", "CHEMBL465069"],
  },
  {
    primary_id: "D0V0LY",
    names: [
      "Des-Arg10-Leu9-Kallidin",
      "71800-37-8",
      "Lys-(Des-Arg9, Leu8)-Bradykinin Trifluoroacetate Salt",
      "Lys-(Des-Arg9,Leu8)-Bradykinin",
      "AC1NSJPW",
      "Lys-[Leu...8...][des-Arg...9...]Bradykinin",
      "Des(Arg10, Leu9)-Kallidin",
      "CHEMBL384721",
      "ZINC169731077",
      "CP-0298",
      "[Leu8]des-Arg10-Kallidin (Lys-[Leu8]des-Arg9-Bradykinin)",
      "Lys-(Des-Arg9, Leu8)-Bradykinin Trifluoroacetate Salt, &gt",
    ],
  },
  {
    primary_id: "D0V0MH",
    names: [
      "1-(4-Phenoxyphenyl)Piperazine",
      "CHEMBL576512",
      "62755-61-7",
      "EINECS 263-715-9",
      "AC1MI4LW",
      "Oprea1_372692",
      "SCHEMBL2156502",
      "1-(4-Phenoxylphenyl)Piperazine",
      "1-(4-Phenoxyphenyl)-Piperazine",
      "CEDIEUVYUSGIDQ-UHFFFAOYSA-N",
      "Piperazine, 1-(4-Phenoxyphenyl)-",
      "BDBM50303652",
      "ZINC11632570",
      "AKOS000365585",
    ],
  },
  {
    primary_id: "D0V0NJ",
    names: [
      "2,3,4,5-Tetrahydro-1H-Benzo[e][1,4]diazepine",
      "5946-39-4",
      "2,3,4,5-Tetrahydro-1H-1,4-Benzodiazepine",
      "CHEMBL323692",
      "1H-1,4-Benzodiazepine, 2,3,4,5-Tetrahydro-",
      "2,3,4,5-Tetrahydro-(1h)-1,4-Benzodiazepine",
      "2,3,4,5-Tetrahydro-1h Benzo[e][1,4]diazepine",
      "AC1MCKFZ",
      "PubChem14772",
      "ACMC-1ANOK",
      "Tetrahydrobenzo[1,4]diazepine",
      "SCHEMBL379799",
      "BEN206",
      "BEN025",
      "AC1Q1I93",
      "KS-00001OTC",
      "CTK1G9067",
      "DTXSID20378071",
      "MolPort-000-006-540",
      "MLXBHOCKBUILHN-UHFFFAOYSA-N",
      "ZINC2511438",
      "STL554215",
      "CT-889",
      "BDBM50052888",
      "MFCD03789577",
      "BBL100421",
      "AKOS005254212",
    ],
  },
  {
    primary_id: "D0V0NL",
    names: [
      "Aminoguanidine",
      "Pimagedine",
      "Hydrazinecarboximidamide",
      "Guanyl Hydrazine",
      "Monoaminoguanidine",
      "2-Aminoguanidine",
      "79-17-4",
      "Imino Semicarbazide",
      "Aminate Base",
      "2-Azanylguanidine",
      "Pimagedine [INN]",
      "GUANIDINE, AMINO-",
      "1-Aminoguanidine",
      "UNII-SCQ4EZQ113",
      "Hydrazinecarboximidamide(9CI)",
      "CCRIS 3511",
      "EINECS 201-183-1",
      "Aminoguanidine, Hemisulfate",
      "SCQ4EZQ113",
      "CHEMBL225304",
      "CHEBI:40618",
      "HAMNKKUPIHEESI-UHFFFAOYSA-N",
      "Guanylhydrazine",
      "GER-11",
      "AGU",
      "Amino Guanidine",
      "1-Amino-Guanidine",
      "Aminoguanidine (AG)",
      "Tocris-0787",
      "Carbonohydrazonic Diamide",
      "INCB3284",
    ],
  },
  {
    primary_id: "D0V0NM",
    names: ["JATRORRHIZINE"],
  },
  {
    primary_id: "D0V0NO",
    names: [
      "(+/-)-Threo-N-(3-Methylthiopene)Methylphenidate",
      "CHEMBL1254928",
      "BDBM50327130",
      "(AlphaR)-Alpha-[(2R)-1-(3-Thienylmethyl)-2beta-Piperidinyl]benzeneacetic Acid Methyl Ester",
    ],
  },
  {
    primary_id: "D0V0OD",
    names: ["PMID26004420-Compound-WO2014015495C"],
  },
  {
    primary_id: "D0V0OM",
    names: ["[18F]FP-TZTP", "[18F]FP-TZTP (PET Ligand)"],
  },
  {
    primary_id: "D0V0OP",
    names: ["Polyphenon E"],
  },
  {
    primary_id: "D0V0PR",
    names: ["4-(2-(Phenoxymethyl)Phenyl)Piperidine", "CHEMBL574348", "SCHEMBL12965384"],
  },
  {
    primary_id: "D0V0PS",
    names: ["9-Amino-1,2,3,4-Tetrahydro-Acridine-1,7-Diol", "CHEMBL354601", "1,7-Dihydroxytacrine", "SCHEMBL10846147"],
  },
  {
    primary_id: "D0V0PY",
    names: ["M3S"],
  },
  {
    primary_id: "D0V0QJ",
    names: ["NSC-686549"],
  },
  {
    primary_id: "D0V0RB",
    names: ["Tyr-D-Nle-Gly-D-Trp-Nle-Asp-Phe-NH2"],
  },
  {
    primary_id: "D0V0RO",
    names: ["Ac-I[CVYQDWGAHRC]T-NH2"],
  },
  {
    primary_id: "D0V0RS",
    names: ["SECOCULARIDINE"],
  },
  {
    primary_id: "D0V0RW",
    names: ["BB-1433", "BB-2014", "BB-2116", "BB-2284", "BB-2633", "BB-3029", "BB-3050", "BB-3103", "BB-3241"],
  },
  {
    primary_id: "D0V0RY",
    names: ["Factor Ix Human", "Alphanine (TN)"],
  },
  {
    primary_id: "D0V0SD",
    names: [
      "2-Morpholin-4-Yl-Pyrido[1,2-A]pyrimidin-4-One",
      "CHEMBL179043",
      "2-(Morpholin-4-Yl)-4h-Pyrido[1,2-A]pyrimidin-4-One",
      "17326-31-7",
      "NSC110390",
      "AC1Q6IJU",
      "AC1L6MD6",
      "2-Morpholin-4-Ylpyrido[1,2-A]pyrimidin-4-One",
      "SCHEMBL4544196",
      "CTK4D4568",
      "DTXSID80296606",
      "ZINC1702791",
      "BDBM50159642",
      "AKOS024361168",
      "NSC-110390",
      "MCULE-7092692912",
      "ST50991317",
    ],
  },
  {
    primary_id: "D0V0SL",
    names: ["Abexinostat"],
  },
  {
    primary_id: "D0V0TN",
    names: ["N-Benzyl,N-Methyl-1H-Indole-2-Carboxamide", "CHEMBL456107", "BDBM50273490", "AKOS008916266"],
  },
  {
    primary_id: "D0V0TT",
    names: ["CS-204", "CS-00088", "PPAR Alpha/Gamma/Delta Agonist (Type 2 Diabetes), Chipscreen Bioscience"],
  },
  {
    primary_id: "D0V0UH",
    names: ["AMG 557"],
  },
  {
    primary_id: "D0V0UK",
    names: ["SULFAMIDE"],
  },
  {
    primary_id: "D0V0UW",
    names: ["Pyridotriazolopyrimidine Derivative 2"],
  },
  {
    primary_id: "D0V0UX",
    names: [
      "RVLYSIN",
      "BRVLYSIN",
      "CERVLYSIN",
      "CORVLYSIN",
      "HERVLYSIN",
      "LURVLYSIN",
      "MERVLYSIN",
      "OVARVLYSIN",
      "PRORVLYSIN",
      "Apoptosis Inducer (Recombinant Virus, Cancer), CZ Biomed",
    ],
  },
  {
    primary_id: "D0V0VI",
    names: [
      "11C-AZD-2184",
      "AZD-2184-[11C]",
      "AZD-2184-[3H]",
      "Tritium-AZD-2184",
      "Beta Amyloid Modulator (Iv PET Ligand, AD), AstraZeneca",
      "Carbon-11-AZD-2184",
      "Hydrogen-3-AZD-2184",
      "Beta Amyloid Modulator (Iv PET Ligand, Alzheimer's Disease), AstraZeneca",
      "3H-AZD-2184",
    ],
  },
  {
    primary_id: "D0V0VR",
    names: ["CMVpp65-A0201", "CMV Vaccine (Pp65 Antigen), City Of Hope"],
  },
  {
    primary_id: "D0V0WB",
    names: [
      "(+)-5-Deoxyadeenophorine",
      "CHEMBL380089",
      "262615-98-5",
      "5-Deoxyadenophorine",
      "SCHEMBL17249944",
      "CTK0I6251",
      "DTXSID00441676",
      "BDBM50185228",
      "AKOS030538296",
      "3,4-Piperidinediol, 6-Ethyl-2-(Hydroxymethyl)-, (2R,3S,4S,6R)-",
    ],
  },
  {
    primary_id: "D0V0WE",
    names: ["NIA-114"],
  },
  {
    primary_id: "D0V0WT",
    names: ["PMID25991433-Compound-G5"],
  },
  {
    primary_id: "D0V0XV",
    names: ["FLU-V"],
  },
  {
    primary_id: "D0V0YA",
    names: ["Veltuzumab/Epratuzumab Y-90"],
  },
  {
    primary_id: "D0V0YH",
    names: ["Flu Vaccine, GlaxoSmithKline"],
  },
  {
    primary_id: "D0V0YM",
    names: ["MK-5596", "CB1 Inverse Agonists (Obesity), Merck"],
  },
  {
    primary_id: "D0V0YP",
    names: ["3-(Thiophen-3-Yl)-1H-Indol-6-Amine", "CHEMBL206475", "SCHEMBL20561548"],
  },
  {
    primary_id: "D0V1AV",
    names: ["JNJ-61186372"],
  },
  {
    primary_id: "D0V1BY",
    names: ["EPI-6"],
  },
  {
    primary_id: "D0V1CB",
    names: ["PMID25991433-Compound-A5"],
  },
  {
    primary_id: "D0V1CG",
    names: ["MLI-1018"],
  },
  {
    primary_id: "D0V1CL",
    names: [
      "Cartazolate",
      "CARTAZOLATE",
      "34966-41-1",
      "UNII-8K93Z46WPY",
      "CHEMBL8184",
      "SQ 65396",
      "8K93Z46WPY",
      "SQ-65396",
      "Cartazolato",
      "Cartazolatum",
      "Ethyl 4-(Butylamino)-1-Ethyl-1H-Pyrazolo(3,4-B)-Pyridine-5-Carboxylate",
      "4-Butylamino-1-Ethyl-1H-Pyrazolo[3,4-B]pyridine-5-Carboxylic Acid Ethyl Ester",
      "Cartazolatum [INN-Latin]",
      "Cartazolate [USAN:INN]",
      "Cartazolato [INN-Spanish]",
      "Ethyl 4-(Butylamino)-1-Ethyl-1H-Pyrazolo[3,4-B]-Pyridine-5-Carboxylate",
      "NSC 166873",
      "AC1L1WUF",
      "Cartazolate (USAN/INN)",
      "SCHEMBL123839",
      "DTXSID20188511",
      "IQNQAOGGWGCROX-UHFFFAOYSA-N",
      "BDBM50098221",
    ],
  },
  {
    primary_id: "D0V1CP",
    names: ["L-Cystine", "Cystine", "(-)-Cystine", "L-Dicysteine"],
  },
  {
    primary_id: "D0V1CW",
    names: ["PMID30247903-Compound-General Structure35"],
  },
  {
    primary_id: "D0V1DG",
    names: ["EG-Decorin", "Decorin (Dermatological, Pressure Ulcer), EyeGene"],
  },
  {
    primary_id: "D0V1DW",
    names: [
      "3,4,5-Tribromo-2-(2', 4'-Dibromo-Phenoxy)Phenol",
      "CHEMBL464577",
      "CHEBI:68326",
      "3,4,5-Tribromo-2-(2,4-Dibromophenoxy)Phenol",
      "35162-01-7",
      "Dysidea Substance A",
      "6-Hydroxy-2,2',3,4,4'-Pentabromodiphenyl Ether",
      "SCHEMBL5527228",
      "CTK1B7172",
      "DTXSID60474586",
      "BDBM50292443",
      "ACM35162017",
      "3,4,5-Tribromo-2-(2',4'-Dibromophenoxy)Phenol",
      "Phenol, 3,4,5-Tribromo-2-(2,4-Dibromophenoxy)-",
      "3,4,5-Tribromo-2-(2'', 4''-Dibromo-Phenoxy)Phenol",
    ],
  },
  {
    primary_id: "D0V1EJ",
    names: ["6-(2-Dipropylamino-Ethyl)-Biphenyl-2,3,4'-Triol", "CHEMBL60861", "BDBM50025203"],
  },
  {
    primary_id: "D0V1EK",
    names: [
      "2-PHENYLAMINO-4-METHYL-5-ACETYL THIAZOLE",
      "31609-42-4",
      "2-Phenylamino-4-Methyl-5-Acetyl Thiazole",
      "5-Acetyl-4-Methyl-2-(Phenylamino)Thiazole",
      "2-Phenylamino-4-Methyl-5-Acetylthiazole",
      "CHEMBL1235108",
      "1-(4-Methyl-2-(Phenylamino)Thiazol-5-Yl)Ethanone",
      "5-Acetyl-4-Methyl-2-(Phenylamino)-1,3-Thiazole",
      "1-(2-Anilino-4-Methyl-1,3-Thiazol-5-Yl)Ethanone",
      "Ethanone,1-[4-Methyl-2-(Phenylamino)-5-Thiazolyl]-",
      "1-[4-Methyl-2-(Phenylamino)-1,3-Thiazol-5-Yl]ethanone",
      "1-[4-Methyl-2-(Phenylamino)-1,3-Thiazol-5-Yl]ethan-1-One",
      "5-Acetyl-4-Methyl-2-Phenylamino-Thiazole",
      "2vba",
      "AC1LEGRW",
    ],
  },
  {
    primary_id: "D0V1ES",
    names: ["ALM-301", "Akt Inhibitors (Cancer), Almac"],
  },
  {
    primary_id: "D0V1FF",
    names: [
      "Davunetide Intranasal Spray",
      "Davunetide",
      "NAP",
      "AL-108",
      "AL-208",
      "Davunetide (Intranasal Spray), Allon",
      "NAPVSIPQ Eight Amino Acid Peptide (Intranasal Spray), Allon",
      "Davunetide (Intravenous-Infused), Allon Therapeutics",
      "NAP Eight Amino Acid Peptide (Neuroprotection/Cognitive Impairment), Allon",
      "NAPVSIPQ Eight Amino Acid Peptide (Neuroprotection/Cognitive Impairment), Allon",
      "Central Nervous System Therapeutic (Alzheimer's Disease/Schizophrenia), Allon",
      "Central Nervous System Therapeutic (Post-Cardiac Artery Bypass Graft/Mild Cognitive Impairment), Allon",
      "NAPVSIPQ Eight Amino Acid Peptide (Intravenous-Infused/Subcutaneous Depot Formulation), Allon Therapeutics",
      "Davunetide (Iv/Sc, Alzheimer's Disease), Allon Therapeutics",
    ],
  },
  {
    primary_id: "D0V1FP",
    names: ["RHB-104"],
  },
  {
    primary_id: "D0V1GK",
    names: ["KB-5359"],
  },
  {
    primary_id: "D0V1HA",
    names: [
      "N-Hydroxy-3-(Naphthalen-1-Yl)Acrylamide",
      "CHEMBL245548",
      "SCHEMBL14106948",
      "BDBM50207560",
      "(E)-3-(1-Naphthalenyl)-2-Propenehydroximic Acid",
    ],
  },
  {
    primary_id: "D0V1HK",
    names: ["Berlafenone", "Bipranol", "GK-23G"],
  },
  {
    primary_id: "D0V1IM",
    names: ["L-791943"],
  },
  {
    primary_id: "D0V1JS",
    names: ["Diclofenac Sodium Patch"],
  },
  {
    primary_id: "D0V1JT",
    names: [
      "Ac-His-DPhe(PI)-Arg-Trp-NH2",
      "CHEMBL322610",
      "BDBM50115373",
      "JRH-322-18",
      "2-[2-[2-Acetylamino-3-(3H-Imidazol-4-Yl)-Propionylamino]-3-(4-Iodo-Phenyl)-Propionylamino]-5-Guanidino-Pentanoic Acid [1-Carbamoyl-2-(1H-Indol-3-Yl)-Ethyl]-Amide",
    ],
  },
  {
    primary_id: "D0V1KA",
    names: ["MTL-104"],
  },
  {
    primary_id: "D0V1KD",
    names: ["ALT-022"],
  },
  {
    primary_id: "D0V1KP",
    names: ["N-Acetylardeemin"],
  },
  {
    primary_id: "D0V1KS",
    names: [
      "AZD-2836",
      "A-00060058",
      "A-00060617",
      "A-48504",
      "A-48756",
      "A-48773",
      "A-831",
      "HCV NS5A Inhibitors, Arrow Therapeutics",
      "Hepatitis C Virus Therapeutics (NS5A Inhibitors), Arrow",
    ],
  },
  {
    primary_id: "D0V1NI",
    names: ["AM-66"],
  },
  {
    primary_id: "D0V1NK",
    names: ["VER-54505", "Cdk2 Inhibitors, Vernalis", "RBT-0049985"],
  },
  {
    primary_id: "D0V1OC",
    names: ["DA-3880"],
  },
  {
    primary_id: "D0V1OG",
    names: ["Glycosaminoglycans"],
  },
  {
    primary_id: "D0V1OY",
    names: ["BC-DU-04"],
  },
  {
    primary_id: "D0V1PD",
    names: ["Cooling Agent 10", "(-)-Menthoxypropane-1,2-Diol"],
  },
  {
    primary_id: "D0V1PE",
    names: [
      "8-Chloro-3-Hydroxy-1H-Benzo[b]azepine-2,5-Dione",
      "CHEMBL93798",
      "1H-1-Benzazepine-2,5-Dione, 8-Chloro-3-Hydroxy-",
      "SCHEMBL7350621",
      "PUWRZSGQAPPNEC-UHFFFAOYSA-N",
      "BDBM50054631",
      "144066-23-9",
      "3-Hydroxy-8-Chloro-1H-1-Benzazepine-2,5-Dione",
      "8-Chloro-3-Hydroxy-2,5-Dihydro-1h-Benzazepine-2,5-Dione",
    ],
  },
  {
    primary_id: "D0V1PK",
    names: ["ISIS 134563"],
  },
  {
    primary_id: "D0V1QC",
    names: ["Ethacrynic"],
  },
  {
    primary_id: "D0V1QH",
    names: ["AMG 330"],
  },
  {
    primary_id: "D0V1QX",
    names: ["Axogenesis Factor-1"],
  },
  {
    primary_id: "D0V1RX",
    names: [
      "Mivazerol",
      "125472-02-8",
      "Mivazerol [INN]",
      "UNII-W5P1SSA8KD",
      "Mivazerolum [INN-Latin]",
      "Alpha-Imidazol-4-Yl-2,3-Cresotamide",
      "2-HYDROXY-3-(1H-IMIDAZOL-4-YLMETHYL)-BENZAMIDE",
      "W5P1SSA8KD",
      "3-((1H-Imidazol-4-Yl)Methyl)-2-Hydroxybenzamide",
      "Benzamide,2-Hydroxy-3-(1H-Imidazol-5-Ylmethyl)-",
      "Mivazerolum",
      "Ucb 22073",
      "Mivazerol[inn]",
      "ACMC-20mrix",
      "AC1L1TX2",
      "2-Hydroxy-3-(1H-Imidazol-5-Ylmethyl)Benzamide",
      "SCHEMBL593707",
      "SCHEMBL8097939",
      "AC1Q5D40",
      "CHEMBL2105189",
      "CTK4B4380",
      "DTXSID30154782",
      "ZINC4674535",
      "Closantel 6",
    ],
  },
  {
    primary_id: "D0V1SA",
    names: ["FANG Vaccine", "Bi-ShRNAfurin", "TAG Vaccine", "TGFbeta2 Antisense + RhGMCSF Vaccine", "Vigil"],
  },
  {
    primary_id: "D0V1SD",
    names: ["BP4.879a"],
  },
  {
    primary_id: "D0V1TS",
    names: ["1-Benzyl-1H-1,3,4b,9-Tetraaza-Fluorene-2,4-Dione", "CHEMBL112256"],
  },
  {
    primary_id: "D0V1TV",
    names: [
      "CGEN-991",
      "Anti-CGEN-991 Antibody (Ovarian Cancer)",
      "CGEN-991 (Ovarian Cancer), Compugen",
      "Anti-CGEN-991 Antibody (Ovarian Cancer), Compugen",
    ],
  },
  {
    primary_id: "D0V1UL",
    names: [
      "6-(2-Phenylsulfanyl-Ethyl)-Pteridine-2,4-Diamine",
      "CHEMBL50379",
      "6-(2-Phenylsulfanylethyl)Pteridine-2,4-Diamine",
      "AC1L9YC2",
      "BDBM50127151",
      "2,4-Pteridinediamine, 6-[2-(Phenylthio)Ethyl]-",
    ],
  },
  {
    primary_id: "D0V1UQ",
    names: ["FT-101"],
  },
  {
    primary_id: "D0V1UV",
    names: [
      "N-885",
      "Deacetylravidomycin",
      "Virenomycin V",
      "HIF-1 Inhibitors (Cancer)",
      "Hypoxia Inducible Factor 1 Inhibitors (Cancer), National Cancer Institute/MerLion",
      "HIF-1 Inhibitors (Cancer), MerLion/NCI",
    ],
  },
  {
    primary_id: "D0V1UW",
    names: ["Nitisinone"],
  },
  {
    primary_id: "D0V1UY",
    names: [
      "CGS-18320B",
      "Cgs-18320B",
      "CHEMBL29482",
      "AC1L33QO",
      "SCHEMBL1507825",
      "ZINC3775824",
      "CGS-18320",
      "BDBM50047260",
      "4,4''-((1H-Imidazol-1-Yl)Methylene)Dibenzonitrile",
      "4-[4-Cyanophenyl(1H-1-Imidazolyl)Methyl]benzonitrile",
      "4-[(4-Cyanophenyl)-Imidazol-1-Ylmethyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0V1UZ",
    names: ["BF/PC-21", "Cathepsin X Inhibitors (Inflammation), Biofrontera"],
  },
  {
    primary_id: "D0V1VG",
    names: [
      "AMRS-001",
      "CNS-001",
      "MANF-Based Therapeutics (Parkinson's Disease), CNS Protein Therapeutics",
      "MANF-Based Therapeutics (Diabetes), Amaranthus/ Generex",
      "MANF-Based Therapeutics (Parkinson's Disease/ Ischemic Heart Disease), Amaranthus",
    ],
  },
  {
    primary_id: "D0V1VT",
    names: ["Tetra-Hydro-Naphthalene Derivative 2"],
  },
  {
    primary_id: "D0V1WL",
    names: [
      "Debio-0826",
      "Cell Fate Signaling Pathway Inhibitors (Cancer), Ecole Polytechnique Federale De Lausanne/Debiopharm",
    ],
  },
  {
    primary_id: "D0V1WQ",
    names: ["TAK-875"],
  },
  {
    primary_id: "D0V1XY",
    names: ["PMID27977313-Compound-43"],
  },
  {
    primary_id: "D0V2AQ",
    names: ["ISIS 113131"],
  },
  {
    primary_id: "D0V2BG",
    names: ["ISIS 9066"],
  },
  {
    primary_id: "D0V2CN",
    names: ["LAS-37779"],
  },
  {
    primary_id: "D0V2CO",
    names: ["ML SA1"],
  },
  {
    primary_id: "D0V2DC",
    names: [
      "ST-0703",
      "Acetylsalicylic Acid/Nitirc Oxide Donor (Cardiovascular Disease), Solvotrin",
      "Aspirin Prodrug/Nitric Oxide Donor (Cardiovascular Disease), Trinity College Dublin/Solvotrin",
    ],
  },
  {
    primary_id: "D0V2DT",
    names: [
      "D-Citrulline",
      "13594-51-9",
      "H-D-Cit-OH",
      "(R)-2-Amino-5-Ureidopentanoic Acid",
      "(2R)-2-Amino-5-(Carbamoylamino)Pentanoic Acid",
      "D-Citruline",
      "H-D-Orn(Carbamoyl)-OH",
      "UNII-KNS2VUH6P2",
      "KNS2VUH6P2",
      "N(5)-Carbamoyl-D-Ornithine",
      "D-Ornithine,N5-(Aminocarbonyl)-",
      "W-204205",
      "J-300336",
      "C6H13N3O3",
      "D-Citrullin",
      "NSC-206276",
      "Citrulline, D-",
      "AC1LCUKE",
      "Z,D-CITRULLINE",
      "PubChem17499",
      "SCHEMBL437142",
      "D-Citrulline, &gt",
      "GTPL4683",
      "N5-(Aminocarbonyl)-D-Ornithine",
      "CHEBI:49007",
      "CTK4C0068",
      "DTXSID90348463",
      "MolPort-003-928-964",
      "D-Ornithine, N5-(Aminocarbonyl)-",
    ],
  },
  {
    primary_id: "D0V2DV",
    names: [
      "Sodium Phosphate Dibasic/Sodium Phosphate Monobasic",
      "Anhydrous Sodium Acid Phosphate",
      "Dipotassium Phosphate Anhydrous",
      "Monobasic Potassium Acid Phosphate",
      "Monobasic Sodium Phosphate",
      "Potassium Phosphate",
      "Sodium Phosphate",
    ],
  },
  {
    primary_id: "D0V2ER",
    names: ["KNI-10080"],
  },
  {
    primary_id: "D0V2FZ",
    names: ["111In-HEGF"],
  },
  {
    primary_id: "D0V2GH",
    names: ["Nicergoline"],
  },
  {
    primary_id: "D0V2GT",
    names: ["BIBP 3226"],
  },
  {
    primary_id: "D0V2GZ",
    names: [
      "BioVant",
      "CAP Vaccine Adjuvant, BioSante",
      "Calcium Phosphate Nanoparticles, BioSante",
      "Nanoparticle Adjuvant Technology, BioSante",
    ],
  },
  {
    primary_id: "D0V2IR",
    names: ["8-(1-Ethyl-Propoxy)-Quinolin-2-Ylamine", "CHEMBL185096", "SCHEMBL5884073"],
  },
  {
    primary_id: "D0V2JK",
    names: ["Nomegestrol Acetate"],
  },
  {
    primary_id: "D0V2JR",
    names: ["PMID27967267-Compound-Neotuberostemonine"],
  },
  {
    primary_id: "D0V2KB",
    names: ["Predicentrine Methiodide"],
  },
  {
    primary_id: "D0V2KD",
    names: ["CAT-3000"],
  },
  {
    primary_id: "D0V2KE",
    names: ["Oralgen"],
  },
  {
    primary_id: "D0V2KR",
    names: ["Yttrium (90Y) Clivatuzumab Tetraxetan"],
  },
  {
    primary_id: "D0V2KV",
    names: ["3-(2-Phenyl-6-(Thiophen-3-Yl)Pyridin-4-Yl)-Phenol", "CHEMBL1094745"],
  },
  {
    primary_id: "D0V2LK",
    names: [
      "6-Nitro-2-(4-Nitro-Phenyl)-Chromen-4-One",
      "CHEMBL64704",
      "158555-11-4",
      "4',6-Dinitroflavone",
      "SCHEMBL7538912",
      "CTK0B0325",
      "DTXSID60437292",
      "BDBM50037398",
      "AKOS030553425",
      "4H-1-Benzopyran-4-One, 6-Nitro-2-(4-Nitrophenyl)-",
    ],
  },
  {
    primary_id: "D0V2LL",
    names: ["PF-06873600"],
  },
  {
    primary_id: "D0V2MB",
    names: ["GRNCHND-1"],
  },
  {
    primary_id: "D0V2MR",
    names: [
      "Bicine",
      "150-25-4",
      "N,N-Bis(2-Hydroxyethyl)Glycine",
      "Diethylolglycine",
      "Dihydroxyethylglycine",
      "2-(Bis(2-Hydroxyethyl)Amino)Acetic Acid",
      "Diethanol Glycine",
      "N,N-Di(2-Hydroxyethyl)Glycine",
      "Bicene",
      "N,N-Dihydroxyethylglycine",
      "N,N-Dihydroxyethyl Glycine",
      "Fe-3-Specific",
      "DHEG",
      "Glycine, N,N-Bis(2-Hydroxyethyl)-",
      "Bis(2-Hydroxyethyl)Glycine",
      "N,N-(2-Hydroxyethyl)Glycine",
      "2-[bis(2-Hydroxyethyl)Amino]acetic Acid",
      "N,N-Bis(2-Hydroxyethyl)Aminoacetic Acid",
      "N,N-Bis(Beta-Hydroxyethyl)Glycine",
      "NSC 7342",
      "GLYCINE, N,N-DIHYDROXYETHYL-",
      "UNII-1J484QFI1O",
    ],
  },
  {
    primary_id: "D0V2NC",
    names: ["PMID25399762-Compound-Table1-C14"],
  },
  {
    primary_id: "D0V2NH",
    names: ["1-Phenethyl-3,7-Dihydro-Purine-2,6-Dione", "CHEMBL67949", "BDBM50042215"],
  },
  {
    primary_id: "D0V2NJ",
    names: ["Somatropin Biosimilar"],
  },
  {
    primary_id: "D0V2NK",
    names: ["Clade B HIV Vaccine"],
  },
  {
    primary_id: "D0V2NQ",
    names: [
      "N-(3-Phenyl)Propyl-2,2-Diphenylacetamide",
      "2,2-Diphenyl-N-(3-Phenylpropyl)Acetamide",
      "CHEMBL476336",
      "AC1M5U8J",
      "MolPort-003-249-505",
      "ZINC3269206",
      "STK437441",
      "BDBM50263151",
      "AKOS001062446",
      "MCULE-9627491940",
      "N-(3-Phenylpropyl)-2,2-Diphenylacetamide",
      "ST50913753",
      "Z26437312",
    ],
  },
  {
    primary_id: "D0V2OL",
    names: ["NN1841"],
  },
  {
    primary_id: "D0V2OS",
    names: ["DX-9065", "DX-9065 (Enteric-Coated Tablet)"],
  },
  {
    primary_id: "D0V2OX",
    names: ["Afamelanotide", "[Nle4,dPhe7]alpha-MSH", "Alpha-NDP-MSH", "NDP-MSH"],
  },
  {
    primary_id: "D0V2PB",
    names: [
      "[3H]Ro 63-0563",
      "4-Amino-N-[2,6-Bis(Methylamino)Pyridin-4-Yl]benzenesulfonamide",
      "Ro-63-0563",
      "Ro 63-0563",
      "CHEMBL46071",
      "AC1NSM3I",
      "GTPL275",
      "SCHEMBL679027",
      "GTPL3246",
      "Ro630563",
      "BDBM50090524",
      "Ro-630563",
      "L000518",
      "4-Amino-N-(2,6-Diamino-Pyridin-4-Yl)-Benzenesulfonamide",
      "N-(2,6-Bis(Methylamino)Pyridin-4-Yl)-4-Aminobenzenesulfonamide",
      "4-Amino-N-(2,6 Bis-Methylamino-Pyridin-4-Yl)-Benzene Sulfonamide",
    ],
  },
  {
    primary_id: "D0V2PG",
    names: ["ALD-701"],
  },
  {
    primary_id: "D0V2PK",
    names: ["PHY-808"],
  },
  {
    primary_id: "D0V2PM",
    names: ["H-Dmt-Tic-NH-CH2-Imid", "CHEMBL407957"],
  },
  {
    primary_id: "D0V2QF",
    names: ["5'-Deoxy-5'-[(3-Hydrazinopropyl)Methylamino]adenosine"],
  },
  {
    primary_id: "D0V2QH",
    names: ["6-(3-Hydroxyphenyl)-1-Phenyl-2-Naphthol", "CHEMBL469227", "SCHEMBL3008799"],
  },
  {
    primary_id: "D0V2QL",
    names: ["ISIS 109110"],
  },
  {
    primary_id: "D0V2QP",
    names: ["2-(4-(Methylsulfonyl)Phenyl)-3-Phenylquinoline", "CHEMBL598330"],
  },
  {
    primary_id: "D0V2QU",
    names: ["Anti-CD22/CD19 Mab-Toxin Conjugate"],
  },
  {
    primary_id: "D0V2RF",
    names: ["Shigella Conjugated And Outer Membrane Vaccine"],
  },
  {
    primary_id: "D0V2RK",
    names: ["NKP-46"],
  },
  {
    primary_id: "D0V2SP",
    names: ["INGN-225", "Cancer Vaccine (P53), Introgen"],
  },
  {
    primary_id: "D0V2VB",
    names: ["ISIS 102458"],
  },
  {
    primary_id: "D0V2VM",
    names: ["Pyridine Derivative 17"],
  },
  {
    primary_id: "D0V2VU",
    names: ["Ethyl 2-Amino-4,5-Diphenylthiophene-3-Carboxylate"],
  },
  {
    primary_id: "D0V2VW",
    names: ["Sky Kinase Inhibitors"],
  },
  {
    primary_id: "D0V2WE",
    names: ["Steroidal Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0V2WK",
    names: [
      "A-70826",
      "AC1OCF5L",
      "7-[3-[[(2S)-2-[[(2S)-2-Aminopentanoyl]amino]pentanoyl]amino]pyrrolidin-1-Yl]-1-(2,4-Difluorophenyl)-6-Fluoro-4-Oxo-1,8-Naphthyridine-3-Carboxylic Acid Hydrochloride",
    ],
  },
  {
    primary_id: "D0V2WO",
    names: [
      "PC-DAC:Insulin",
      "Capsulin",
      "Intesulin",
      "Macrulin",
      "Nodlin",
      "SuliXen",
      "AERx Diabetes Management System",
      "AERx IDMS",
      "Capsulin IR",
      "Capsulin OAD",
      "Capsulin Insulin Replacement",
      "Insulin Tregopil",
      "Ultrafast Insulin Program",
      "HIM2",
      "AERx (Insulin)",
      "AT-1391",
      "AT-2391",
      "Analog-PH20",
      "CADDYS (Insulin)",
      "CJC-1530",
      "CJC-1531",
      "CJC-1575",
      "CJC-1576",
      "Capsulin Oral Anti-Diabetic",
      "DTY-001",
      "GW-843362",
      "GW-843362M",
      "Hexyl Insulin Monoconjugate, Nobex",
      "IN-105",
      "Inhaled Insulin, Andaris",
      "Insulin-PH20",
      "Intesulin-1",
      "Intesulin-IVE",
      "NB-058",
      "NIN-058",
      "NN-1998",
      "ORMD-0801",
      "Oral Insulin, Emisphere",
      "Oral Insulin, Nobex",
      "PC-Insulin",
      "Inhaled Insulin, QDose/BMS",
      "Inhaled Insulin, Quadrant/MicroDose",
      "Insulin (Inhaled), Aradigm",
      "Insulin (Inhaled), QDose",
      "Insulin (Inhaled),Translational Research",
      "Insulin (Oral), Biocon",
      "Insulin (Oral), Coremed",
      "Insulin (Oral), Protein Delivery Inc",
      "Insulin (Pulmonary), Translational Research",
      "MicroPor (Insulin), Altea",
    ],
  },
  {
    primary_id: "D0V2YC",
    names: ["OC42-92"],
  },
  {
    primary_id: "D0V2YE",
    names: ["Vivia-009"],
  },
  {
    primary_id: "D0V2YJ",
    names: ["MK-0731", "C-4251", "MK-731"],
  },
  {
    primary_id: "D0V2YR",
    names: [
      "5'-O-[(L-Methionyl)-Sulphamoyl]Adenosine",
      "CHEMBL1163059",
      "1pfy",
      "AC1L9LA4",
      "SCHEMBL4308351",
      "BDBM50222901",
      "5'-O-[N-(L-Methionyl)-Sulfamoyl]adenosine",
      "[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl N-[(2S)-2-Amino-4-Methylsulfanylbutanoyl]sulfamate",
    ],
  },
  {
    primary_id: "D0V2ZB",
    names: ["2-(1-(Aminomethyl)-3-Butylcyclopentyl)Acetic Acid", "CHEMBL602385"],
  },
  {
    primary_id: "D0V2ZH",
    names: ["PAV-611"],
  },
  {
    primary_id: "D0V2ZJ",
    names: ["BCX-25", "BCX-14"],
  },
  {
    primary_id: "D0V2ZO",
    names: ["5-(4-Chloro-Phenyl)-Pentanoic Acid Hydroxyamide", "CHEMBL84288"],
  },
  {
    primary_id: "D0V3BI",
    names: [
      "Aom-0890",
      "Aom-0891",
      "PDE5 Inhibitors (Oral/Tablet, Erectile Dysfunction)",
      "PDE5 Inhibitors (Oral/Tablet, Erectile Dysfunction), Hangzhou Adamerck",
    ],
  },
  {
    primary_id: "D0V3BS",
    names: [
      "AT-002",
      "CBAS-173",
      "Activated Leukocyte Cell Adhesion Molecule Targeting Human MAb (Cancer/Autoimmune Disease/Inflammatory Disease), Affitech",
      "Anti-ALCAM Human Monoclonal Antibody (Cancer/Autoimmune Disease/Inflammatory Disease), Affitech",
      "Anti-CD166 Human Monoclonal Antibody (Cancer/Autoimmune Disease/Inflammatory Disease), Affitech",
    ],
  },
  {
    primary_id: "D0V3BZ",
    names: [
      "ME-1071",
      "CP-3242",
      "MBL Inhibitor (Intravenous, Bacterial Infection), Meiji Seika Kaisha",
      "Metallo-Beta-Lactamase Inhibitor (Intravenous, Bacterial Infection), Meiji Seika Kaisha",
    ],
  },
  {
    primary_id: "D0V3CK",
    names: ["IMD-1041"],
  },
  {
    primary_id: "D0V3CM",
    names: ["Opaviraline"],
  },
  {
    primary_id: "D0V3DO",
    names: ["1,2-Bis-[R-(-)-Apomorphine-2'-Oxy]ethane", "CHEMBL442926", "BDBM50251330"],
  },
  {
    primary_id: "D0V3EQ",
    names: ["AD03 Vaccine"],
  },
  {
    primary_id: "D0V3EY",
    names: [
      "Dinucleotide Analog Prodrug",
      "Dinucleotide Analog Prodrug (HBV Infection)",
      "Anti-HBV Dinucleotide Analog Oral Prodrug,Spring Bank Pharmaceuticals",
      "Dinucleotide Analog Prodrug (HBV Infection), Spring Bank Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0V3FF",
    names: ["D[Leu4,Orn8]VP", "CHEMBL375188"],
  },
  {
    primary_id: "D0V3GA",
    names: [
      "Acetyldigitoxin",
      "Acedigal",
      "Acetildigitoxina",
      "Acetyldiginatin",
      "Acetyldigitoxinum",
      "Acigoxin",
      "Acylanid",
      "Acylanide",
      "Adicin",
      "Acetyldigitoxin [INN]",
      "Desglucolanatoside A",
      "Acetildigitoxina [INN-Spanish]",
      "Acetyldigitoxin (INN)",
      "Acetyldigitoxinum [INN-Latin]",
      "Acylanid (TN)",
      "Alpha-Acetyldigitoxin",
      "Alpha-Acetyldigitoxins",
      "Alpha-Monoacetyldigitoxin",
      "Acetyl-Digitoxin-Alpha",
      "Digitoxin 3'''-Acetate",
      "Digitoxin, 3'''-Acetate",
      "(3beta,5beta)-3-((O-3-O-Acetyl-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1-4)-O-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1-4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl)Oxy)-14-Hydroxycard-20(22)-Enolide",
      "(3beta,5beta)-3-((O-3-O-Acetyl-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-O-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1-4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl)Oxy)-14-Hydroxycard-20(22)-Enolide",
      "(3beta,5beta)-3-{[3-O-Acetyl-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl]oxy}-14-Hydroxycard-20(22)-Enolide",
      "3beta-[3-O-Acetyl-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyl-(1->4)-2,6-Dideoxy-Beta-D-Ribo-Hexopyranosyloxy]-14-Hydroxy-5beta-Card-20(22)-Enolide",
      "[3-Hydroxy-6-[4-Hydroxy-6-[4-Hydroxy-6-[[14-Hydroxy-10,13-Dimethyl-17-(5-Oxo-2H-Furan-3-Yl)-1,2,3,4,5,6,7,8,9,11,12,15,16,17-Tetradecahydrocyclopenta[a]phenanthren-3-Yl]oxy]-2-Methyloxan-3-Yl]oxy-2-Methyloxan-3-Yl]oxy-2-Methyloxan-4-Yl] Acetate",
    ],
  },
  {
    primary_id: "D0V3GN",
    names: ["Tyr-Pro-Phe-Phe-NHNH2", "CHEMBL180777"],
  },
  {
    primary_id: "D0V3GT",
    names: [
      "BEMESETRON",
      "3-Tropanyl-3,5-Dichlorobenzoate",
      "MDL 72222",
      "MDL-72222",
      "C15H17Cl2NO2",
      "CHEMBL376379",
      "40796-97-2",
      "Tropyl 3,5-Dichlorobenzoate",
      "8-Methyl-8-Azabicyclo[3.2.1]oct-3-Yl 3,5-Dichlorobenzoate",
      "Bemesetron [USAN:INN]",
      "Bemesetronum [INN-Latin]",
      "3,5-Dichloro-Benzoic Acid 8-Methyl-8-Aza-Bicyclo[3.2.1]oct-3-Yl Ester",
      "SR-01000075587",
      "Tropanyl 3,5-Dichlorobenzoate",
      "1alphaH,5alphaH-Tropan-3alpha-Yl 3,5-Dichlorobenzoate",
      "Endo-8-Methyl-8-Azabicyclo(3.2.1)Oct-3-Yl 3,5-Dichlorobenzoate",
      "(8-Methyl-8-Azabicyclo[3.2.1]octan-3-Yl) 3,5-Dichlorobenzo",
    ],
  },
  {
    primary_id: "D0V3HC",
    names: [
      "Anti-CD40-XTEN",
      "CD40 Ligand Receptor Antagonist (XTEN, Rheumatoid Arthritis/Transplant Rejection), Amunix",
    ],
  },
  {
    primary_id: "D0V3HE",
    names: ["PMID28074661-Compound-US20158969342C84"],
  },
  {
    primary_id: "D0V3HG",
    names: [
      "CV-6504",
      "117574-40-0",
      "CV 6504",
      "2,3,5-Trimethyl-6-(3-Pyridylmethyl)-1,4-Benzoquinone",
      "2,5-Cyclohexadiene-1,4-Dione,2,3,5-Trimethyl-6-(3-Pyridinylmethyl)-",
      "ACMC-20mn9v",
      "AC1L3QEW",
      "ZINC4541",
      "SCHEMBL2715544",
      "CTK4B0337",
      "DTXSID80151827",
      "IPGAFOVEIIWXFR-UHFFFAOYSA-N",
      "EX-A2681",
      "6-(3-Pyridylmethyl)-2,3,5-Trimethyl-1,4-Benzoquinone",
      "2,5-Cyclohexadiene-1,4-Dione, 2,3,5-Trimethyl-6-(3-Pyridinylmethyl)-",
    ],
  },
  {
    primary_id: "D0V3HP",
    names: [
      "MP-0112",
      "DARPin (Age Related Macular Degeneration), Molecular Partners/Allergan",
      "VEGF-A Specific DARPin, Molecular Partners/Allergan",
    ],
  },
  {
    primary_id: "D0V3HZ",
    names: ["BSP-C728", "BSP-C728 Series (Lipopeptide Antibiotics, Gram-Positive Bacterial Infections)"],
  },
  {
    primary_id: "D0V3IT",
    names: ["SNAP-5150"],
  },
  {
    primary_id: "D0V3JS",
    names: ["FGFR Inhibitors"],
  },
  {
    primary_id: "D0V3KH",
    names: ["PMID29473428-Compound-41"],
  },
  {
    primary_id: "D0V3LC",
    names: ["24-Ethylcholest-6-Ene-3,5-Diol", "CHEMBL201866"],
  },
  {
    primary_id: "D0V3LD",
    names: [
      "DIO-901",
      "CAM-4044",
      "VLD Glucagon (Diabetes), DiObex",
      "Very Low Dose Glucagon (Diabetes), DiObex",
      "VLD Glucagon (FluidCrystal, Diabetes), DiObex",
      "VLD Glucagon (Extended-Release, Diabetes) DiObex",
    ],
  },
  {
    primary_id: "D0V3LF",
    names: ["ECCS-50"],
  },
  {
    primary_id: "D0V3LM",
    names: ["P54"],
  },
  {
    primary_id: "D0V3LR",
    names: ["D-166A", "CHEMBL260151"],
  },
  {
    primary_id: "D0V3MV",
    names: ["1-(4-Nitro-2-Phenoxybenzyl)-1H-Imidazole", "CHEMBL1083653"],
  },
  {
    primary_id: "D0V3MX",
    names: ["PMID28594589-Compound-TABLE3C1"],
  },
  {
    primary_id: "D0V3NI",
    names: ["KYS-05074"],
  },
  {
    primary_id: "D0V3NT",
    names: ["TOLOXATONE"],
  },
  {
    primary_id: "D0V3OD",
    names: ["PMID25522065-Compound-32"],
  },
  {
    primary_id: "D0V3OJ",
    names: [
      "2-Phenyl-N-(Pyridin-4-Yl)Quinazolin-4-Amine",
      "CHEMBL564660",
      "AC1O5DWY",
      "SCHEMBL412953",
      "QGWNGFYEPGNYNY-UHFFFAOYSA-N",
      "MolPort-002-121-406",
      "BDBM50293513",
      "ZINC13633702",
      "STL388203",
      "AKOS003232028",
      "AKOS002341780",
      "MCULE-2242877238",
      "4-(4-Pyridylamino)-2-Penyl Quinazoline",
      "4-(4-Pyridvlamino)-2-Phenyl Quinazoline",
      "4-(4-Pyridylamino)-2-Phenyl Quinazoline",
    ],
  },
  {
    primary_id: "D0V3OR",
    names: ["3,6-Dihydroxy-2-(4-Hydroxy-Phenyl)-Inden-1-One"],
  },
  {
    primary_id: "D0V3OY",
    names: ["[3H]U69593"],
  },
  {
    primary_id: "D0V3PA",
    names: ["Salicylic Acid Derivative 7"],
  },
  {
    primary_id: "D0V3PG",
    names: [
      "CYANIDIN",
      "Cyanidin",
      "13306-05-3",
      "UNII-7732ZHU564",
      "3,5,7,3',4'-Pentahydroxyflavylium",
      "2-(3,4-Dihydroxyphenyl)Chromenylium-3,5,7-Triol",
      "CHEMBL404515",
      "2-(3,4-Dihydroxyphenyl)-3,5,7-Trihydroxy-1-Benzopyrylium",
      "CHEBI:27843",
      "3,3',4',5,7-Pentahydroxyflavylium",
      "7732ZHU564",
      "2-(3,4-Dihydroxyphenyl)-3,5,7-Trihydroxychromenylium",
      "Cyanidin Cation",
      "3,3',4,5,7-Pentahydroxyflavylium Chloride",
      "Cyanidin(1+)",
      "Flavylium, 3,3',4',5,7-Pentahydroxy-",
      "AC1L2UL2",
      "SCHEMBL20799",
      "528-58-5 (Chloride)",
      "DTXSID10157933",
      "MolPort-044-559-814",
    ],
  },
  {
    primary_id: "D0V3PI",
    names: ["PMID25666693-Compound-36"],
  },
  {
    primary_id: "D0V3PN",
    names: ["Withaferin A"],
  },
  {
    primary_id: "D0V3QE",
    names: ["NSL-036"],
  },
  {
    primary_id: "D0V3RZ",
    names: ["Crisnatol", "Crisnatol Mesylate", "BW-770", "BW-770U82", "BW-77OU82", "BW-A770U", "770U82"],
  },
  {
    primary_id: "D0V3SA",
    names: ["Pyridine Derivative 14"],
  },
  {
    primary_id: "D0V3SB",
    names: ["Prolastin"],
  },
  {
    primary_id: "D0V3TE",
    names: ["AdoC(Beta-Ala)Arg6", "CHEMBL611122"],
  },
  {
    primary_id: "D0V3TO",
    names: ["JWH-348"],
  },
  {
    primary_id: "D0V3UN",
    names: ["CP-80794"],
  },
  {
    primary_id: "D0V3UU",
    names: ["YM-175735"],
  },
  {
    primary_id: "D0V3WE",
    names: ["Pseudomonas Aeruginosa Protein Vaccine"],
  },
  {
    primary_id: "D0V3WN",
    names: ["BMN-103", "Alpha-Glucosidase (Pompe Disease), BioMarin"],
  },
  {
    primary_id: "D0V3WQ",
    names: ["Sch-207758"],
  },
  {
    primary_id: "D0V3WS",
    names: ["(S,R)-Antioquine Hydrochloride", "CHEMBL446564", "(S,R)-Antioquine HCl"],
  },
  {
    primary_id: "D0V3XK",
    names: ["SWT-02.100", "Chronic Wound Therapy, Switch Biotech"],
  },
  {
    primary_id: "D0V3XO",
    names: ["Rogaratinib"],
  },
  {
    primary_id: "D0V3XU",
    names: ["CHLOROPHENYLPIPERAZINE"],
  },
  {
    primary_id: "D0V3XZ",
    names: ["8-(4-Chlorophenyl)-2-Morpholin-4-Ylchromen-4-One", "CHEMBL200733"],
  },
  {
    primary_id: "D0V3YB",
    names: ["3,4-Diarylpyrazoline Derivative"],
  },
  {
    primary_id: "D0V3YH",
    names: [
      "3-[4-Chloro-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "AC1O705J",
      "SCHEMBL4231098",
      "SCHEMBL4231088",
      "CHEMBL176287",
      "BDBM8608",
      "(3-Pyridylmethylene)Indane 25a",
      "3-[(E)-(4-Chloro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0V3YT",
    names: [
      "BOCEPREVIR",
      "Boceprevir",
      "Victrelis",
      "394730-60-0",
      "SCH 503034",
      "EBP 520",
      "UNII-89BT58KELH",
      "SCH-503034",
      "89BT58KELH",
      "CHEBI:68621",
      "(1R,2S,5S)-N-(4-Amino-1-Cyclobutyl-3,4-Dioxobutan-2-Yl)-3-[N-(Tert-Butylcarbamoyl)-3-Methyl-L-Valyl]-6,6-Dimethyl-3-Azabicyclo[3.1.0]hexane-2-Carboxamide",
      "3-{[(1R,2S,5S)-3-[(2S)-2-[(Tert-Butylcarbamoyl)Amino]-3,3-Dimethylbutanoyl]-6,6-Dimethyl-3-Azabicyclo[3.1.0]hexan-2-Yl]formamido}-4-Cyclobutyl-2-Oxobutanamide",
    ],
  },
  {
    primary_id: "D0V3YX",
    names: ["A-794278", "Compound 1ac [PMID: 16279797]", "A 794278", "A794278"],
  },
  {
    primary_id: "D0V3YY",
    names: ["NN9828"],
  },
  {
    primary_id: "D0V3ZA",
    names: ["Dihydroergotamine"],
  },
  {
    primary_id: "D0V3ZG",
    names: ["RO5101576"],
  },
  {
    primary_id: "D0V3ZK",
    names: ["GX-H9"],
  },
  {
    primary_id: "D0V3ZN",
    names: ["AG-SPT201"],
  },
  {
    primary_id: "D0V3ZX",
    names: ["DA-3607", "Ad-StTRAIL", "TRAIL Adenoviral Gene Therapy (Cancer), Dong-A"],
  },
  {
    primary_id: "D0V4AR",
    names: ["2-(4-Chloro-Phenyl)-3H-Imidazo[4,5-C]quinoline", "CHEMBL316782", "SCHEMBL10771178", "ZINC13756970"],
  },
  {
    primary_id: "D0V4AS",
    names: ["Cellulose Sodium Phosphate", "Calcibind"],
  },
  {
    primary_id: "D0V4AX",
    names: ["3-Hydroxydesaminokynurenine"],
  },
  {
    primary_id: "D0V4BE",
    names: ["N-Ethyl Estrone-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0V4BR",
    names: [
      "4-Chloro-N-(2-Methyl-Benzoyl)-Benzenesulfonamide",
      "CHEMBL187769",
      "MolPort-000-480-083",
      "ZINC16740680",
      "AKOS016874521",
      "MCULE-6213547895",
      "N-(4-Chlorobenzenesulfonyl)-2-Methylbenzamide",
      "Z56665599",
    ],
  },
  {
    primary_id: "D0V4CL",
    names: ["GN-1140"],
  },
  {
    primary_id: "D0V4DH",
    names: ["Estriol E3"],
  },
  {
    primary_id: "D0V4DX",
    names: [
      "PF-06700841",
      "BUWBRTXGQRBBHG-RUXDESIVSA-N",
      "2140301-96-6",
      "PF-06700841 Free Base",
      "EX-A2762",
      "1883299-62-4",
      "((S)-2,2-Difluorocyclopropyl)(3-(2-((1-Methyl-1H-Pyrazol-4-Yl)Amino)Pyrimidin-4-Yl)-3,8-Diazabicyclo[3.2.1]octan-8-Yl)Methanone",
    ],
  },
  {
    primary_id: "D0V4EK",
    names: [
      "1-Hydroxy-3-Methylbutane",
      "3-Methyl-1-Butanol",
      "Isoamyl Alcohol",
      "Isopentyl Alcohol",
      "3-Methylbutan-1-Ol",
      "Isopentanol",
      "123-51-3",
      "3-Methylbutanol",
      "1-Butanol, 3-Methyl-",
      "Isoamylol",
      "Isobutylcarbinol",
      "Fusel Oil",
      "Iso-Amylalkohol",
      "Isobutyl Carbinol",
      "Iso-Amyl Alcohol",
      "2-Methyl-4-Butanol",
      "ISOAMYLALCOHOL",
      "Alcool Isoamylique",
      "Fermentation Amyl Alcohol",
      "Isoamyl Alkohol",
      "Amylowy Alkohol",
      "Alcool Amilico",
      "I-Amyl Alcohol",
      "Primary Isoamyl Alcohol",
      "3-Metil-Butanolo",
      "Isopentan-1-Ol",
      "Isoamyl Alcohol (Natural)",
      "Isoamyl Alcohol, Primary",
      "FEMA Number 2057",
    ],
  },
  {
    primary_id: "D0V4EX",
    names: [
      "AC-710",
      "CSF1R Inhibitor (Cancer), Ambit Biosciences",
      "CSF1R Inhibitor (Cancer/Autoimmune/Inflammatory Disease), Ambit Biosciences",
    ],
  },
  {
    primary_id: "D0V4FC",
    names: ["PMID7650673C4q"],
  },
  {
    primary_id: "D0V4FE",
    names: ["Piperidine Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0V4FK",
    names: ["SM 11044"],
  },
  {
    primary_id: "D0V4FP",
    names: ["OX-30"],
  },
  {
    primary_id: "D0V4FT",
    names: ["MRS2567"],
  },
  {
    primary_id: "D0V4FW",
    names: ["{2-[3-(Phenylsulfonyl)-1H-Indol-4-Yl]ethyl}amine"],
  },
  {
    primary_id: "D0V4FZ",
    names: ["SKF-75670"],
  },
  {
    primary_id: "D0V4GI",
    names: [
      "CGS-9896",
      "Cgs-9896",
      "Cgs 9896",
      "77779-36-3",
      "UNII-86PWQ4PVN0",
      "86PWQ4PVN0",
      "AC1Q3J9C",
      "AC1L337H",
      "SCHEMBL7308514",
      "AC1Q3T13",
      "2-(4-Chlorophenyl)-1H-Pyrazolo[4,3-C]quinolin-3-One",
      "SCHEMBL17236324",
      "CTK5E4889",
      "BDBM84957",
      "AOB4759",
      "MolPort-009-714-150",
      "CQINXWYVIOMBEI-UHFFFAOYSA-N",
      "PDSP2_001748",
      "2-(4-Chlorophenyl)-1,2-Dihydro-3h-Pyrazolo[4,3-C]quinolin-3-One",
      "PDSP1_001765",
      "ZINC100367629",
      "AKOS021973584",
      "MCULE-9708849911",
      "A10022",
      "2-(P-Chlorophenyl)-Pyrazolo[4,3-C]quinolin-3(5H)-One",
      "F3406-4901",
      "2-(4-Chlorophenyl)-1H-Pyrazolo[4,5-C]quinolin-3-One",
    ],
  },
  {
    primary_id: "D0V4GQ",
    names: [
      "Adenosine-5'-O-(2-Thiodiphosphate)",
      "Adpbetas",
      "Adenosine 5'-O-(2-Thiodiphosphate)",
      "Beta-Thio-ADP",
      "CHEMBL335206",
      "35094-45-2",
      "ADP-Beta-S",
      "73536-95-5",
      "[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Dihydroxyphosphinothioyl Hydrogen Phosphate",
      "[(2R,3S,4R,5R)-5-(6-Aminopurin-9-Yl)-3,4-Dihydroxy-Tetrahydrofuran-2-Yl]methyl Dihydroxyphosphinothioyl Hydrogen Phosphate",
      "ADP Beta S",
      "5'-Adps",
      "5'-Adenosine Diphosphate Beta-S",
      "ADP-Gamma-S",
      "AT4",
      "EINECS 277-530-6",
      "AC1NSJSN",
      "(32S)Adenosine 5'-O-(2-Thiodiphosphate)",
    ],
  },
  {
    primary_id: "D0V4GT",
    names: ["CMI-206"],
  },
  {
    primary_id: "D0V4GY",
    names: [
      "Chlorhexidine",
      "AVAGARD",
      "Chlorhexidin",
      "Chlorhexidinum",
      "Cloresidina",
      "Clorhexidina",
      "Fimeil",
      "Hexadol",
      "Hibiclens",
      "Hibispray",
      "Hibistat;Nolvasan",
      "Novalsan",
      "Peridex",
      "Periogard",
      "Rotersept",
      "Savloclens",
      "Soretol",
      "Sterido",
      "Sterilon",
      "Superspray",
      "Tubulicid",
      "Chlorhexidin [Czech]",
      "Chlorhexidine Base",
      "Chlorhexidine Gluconate",
      "Cloresidina [DCIT]",
      "Decanoylacetaldehyde Sodium Sulfide",
      "Dentisept [veterinary]",
      "Savlon Babycare",
      "Sebidin A",
      "Sodium Houttuyfonamide",
      "Avagard (TN)",
      "BIOPATCH (TN)",
      "ChloraPrep (TN)",
      "Chlorhexamed (TN)",
      "Chlorhexidine (INN);Chlorhexidine [INN:BAN]",
      "Chlorhexidinum [INN-Latin]",
      "Chlorohex (TN)",
      "Clohex (TN)",
      "Clorexil (TN)",
      "Clorhexidina [INN-Spanish]",
      "Corsodyl (TN)",
      "Dentisept [veterinary] (TN)",
      "Dexidin (TN)",
      "Exidine (TN)",
      "Hibiclens (TN)",
      "Hibiscrub (TN)",
      "MK-412A",
      "Merfen-Incolore",
      "Oro-Clense",
      "Oronine (TN)",
      "Perichlor (TN)",
      "Peridex (TN)",
      "Peridont (TN)",
      "Periochip (TN)",
      "Periogard Oral Rinse (TN)",
      "Perioxidina (TN)",
      "Savacol (TN)",
      "Suthol (TN)",
      "Dejavu-MW (TN)",
      "Merfen-Incolore (TN)",
      "Lisium (*Dihydrochloride*)",
      "Nolvasan (*Diacetate*)",
      "SOLU-I.V (TN)",
      "N,N'-Bis(4-Chlorophenyl)-3,12-Diimino-2,4,11,13-Tetraazatetradecanediimidamide",
      "N,N''''-Hexane-1,6-Diylbis[N'-(4-Chlorophenyl)(Imidodicarbonimidic Diamide)]",
      "N,N'-Bis(4-Chlorophenyl)-3,12-Diimino-2,4,11,13-Tetraazatetradeca-Nediimidamide",
      "N',N'''''-Hexane-1,6-Diylbis[N-(4-Chlorophenyl)(Imidodicarbonimidic Diamide)]",
      "1,1 Inverted Exclamation Marka-Hexamethylenebis[5-(4-Chlorophenyl)Biguanide]",
      "1,1'-Hexamethylene Bis(5-(P-Chlorophenyl)Biguanide)",
      "1,1'-Hexamethylenebis(5-(P-Chlorophenyl)Biguanide)",
      "1,1'-Hexamethylenebis[5-(4-Chlorophenyl)Biguanide",
      "1,6-Bis(5-(P-Chlorophenyl)Biguandino)Hexane",
      "1,6-Bis(P-Chlorophenyldiguanido)Hexane",
      "1,6-Di(4'-Chlorophenyldiguanido)Hexane",
      "1,6-Di(N-P-Chlorophenyldiguanido)Hexane",
    ],
  },
  {
    primary_id: "D0V4HB",
    names: ["Demotensin 2"],
  },
  {
    primary_id: "D0V4HO",
    names: ["Avoralstat"],
  },
  {
    primary_id: "D0V4IB",
    names: [
      "Itraconazole",
      "Canadiol",
      "Hyphanox",
      "ITCZ",
      "ITZ",
      "Intraconazole",
      "Itraconazol",
      "Itraconazolum",
      "Itrizole",
      "Oriconazole",
      "Orungal",
      "Prokanazol",
      "Sempera",
      "Spherazole",
      "Sporal",
      "Sporanos",
      "Sporanox",
      "Sporonox",
      "Triasporn",
      "Itraconazol [Spanish]",
      "Itraconazole Oral Solution",
      "Itraconazolum [Latin]",
      "R 51211",
      "Cis-Itraconazole",
      "Itraconazole & Bovine Lactoferrin",
      "Itraconazole & Nyotran",
      "Itrizole (TN)",
      "R-51211",
      "Sporanox (TN)",
      "Itraconazole & Nyotran(Liposomal Nystatin)",
      "Itraconazole (JAN/USAN)",
      "Oriconazole, R51211, Sporanox",
      "Itraconazole [USAN:BAN:INN:JAN]",
      "(+-)-1-Sec-Butyl-4-(P-(4-(P-(((2R*,4S*)-2-(2,4-Dichlorophenyl)-2-(1H-1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl)Methoxy)Phenyl)-1-Piperazinyl)Phenyl)-Delta(Sup 2)-1,2,4-Triazolin-5-One",
      "(1)-Cis-4-(4-(4-(4-((2-(2,4-Dichlorophenyl)-2-(1H-1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl)Methoxy)Phenyl)Piperazin-1-Yl)Phenyl)-2,4-Dihydro-2-Sec-Butyl-3H-1,2,4-Triazol-3-One",
      "2-(Butan-2-Yl)-4-{4-[4-(4-{[(2R,4S)-2-(2,4-Dichlorophenyl)-2-(1H-1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl]methoxy}phenyl)Piperazin-1-Yl]phenyl}-2,4-Dihydro-3H-1,2,4-Triazol-3-One",
      "2-Butan-2-Yl-4-[4-[4-[4-[[(2R,4S)-2-(2,4-Dichlorophenyl)-2-(1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl]methoxy]phenyl]piperazin-1-Yl]phenyl]-1,2,4-Triazol-3-One",
      "2-Butan-2-Yl-4-[4-[4-[4-[[(2S,4R)-2-(2,4-Dichlorophenyl)-2-(1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl]methoxy]phenyl]piperazin-1-Yl]phenyl]-1,2,4-Triazol-3-One",
      "2-Butan-2-Yl-4-[4-[4-[4-[[2-(2,4-Dichlorophenyl)-2-(1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl]methoxy]phenyl]piperazin-1-Yl]phenyl]-1,2,4-Triazol-3-One",
      "3H-1,2,4-Triazol-3-One, 4-[4-[4-[4-[[2-(2,4-Dichlorophenyl)-2-(1H-1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl]methoxy]phenyl]-1-Pipera-Zinyl]phenyl]-2,4-Dihydro-2-(1-Methylpropyl)",
      "4-(4-{4-[4-({[(2R,4S)-2-(2,4-Dichlorophenyl)-2-(1H-1,2,4-Triazol-1-Ylmethyl)-1,3-Dioxolan-4-Yl]methyl}oxy)Phenyl]piperazin-1-Yl}phenyl)-2-(1-Methylpropyl)-2,4-Dihydro-3H-1,2,4-Triazol-3-One",
    ],
  },
  {
    primary_id: "D0V4IH",
    names: ["CEP1348"],
  },
  {
    primary_id: "D0V4IJ",
    names: ["AKCEA-APOCIII-LRX"],
  },
  {
    primary_id: "D0V4IQ",
    names: ["RhGDF-5"],
  },
  {
    primary_id: "D0V4JO",
    names: [
      "AMPEROZIDE",
      "Amperozide",
      "75558-90-6",
      "Amperozidum [INN-Latin]",
      "Amperozida [INN-Spanish]",
      "UNII-0M2W3TAG39",
      "FG 5606",
      "0M2W3TAG39",
      "CHEMBL1079935",
      "4-(4,4-Bis(P-Fluorophenyl)Butyl)-N-Ethyl-1-Piperazinecarboxamide",
      "4-(4,4-Bis(4-Fluorphenyl)Butyl)-N-Ethyl-1-Piperazinecarboxamid",
      "Amperozidum",
      "Amperozida",
      "4-[4,4-Bis(4-Fluorophenyl)Butyl]-N-Ethylpiperazine-1-Carboxamide",
      "1-Piperazinecarboxamide, 4-(4,4-Bis(P-Fluorophenyl)Butyl)-N-Ethyl-",
      "Amperozide [INN:BAN]",
      "Amperozide [BAN:INN]",
      "4-(4,4-Bis(4-Fluorophenyl)Butyl)-N-Ethylpiperazine-1-Carboxamide",
    ],
  },
  {
    primary_id: "D0V4JQ",
    names: [
      "Interferon Beta-1a",
      "Avonex",
      "Avonex (TN)",
      "Cinnovex (TN)",
      "Rebif (TN)",
      "Interferon Beta-1a (USAN)",
      "Interferon Beta-1a (Genetical Recombination)",
      "Interferon Beta-1a (Genetical Recombination) (JAN)",
    ],
  },
  {
    primary_id: "D0V4JR",
    names: ["Fibrinogen"],
  },
  {
    primary_id: "D0V4JV",
    names: ["PMID30247903-Compound-General Structure27"],
  },
  {
    primary_id: "D0V4JW",
    names: ["SRPIN-340"],
  },
  {
    primary_id: "D0V4KJ",
    names: ["N-Substituted 9-Azabicyclo[3.3.1]nonan-3alpha-Yl-Phenylcarbamate Analog 1"],
  },
  {
    primary_id: "D0V4KL",
    names: ["VAR-10200"],
  },
  {
    primary_id: "D0V4LG",
    names: ["RS-8891"],
  },
  {
    primary_id: "D0V4MB",
    names: ["Remogliflozin Etabonate"],
  },
  {
    primary_id: "D0V4NQ",
    names: ["S-Ethylisothiourea"],
  },
  {
    primary_id: "D0V4NU",
    names: [
      "4-Trimethylammoniobutanal",
      "4-TRIMETHYLAMMONIOBUTANAL",
      "Trimethylaminobutyraldehyde",
      "Gamma-Trimethylaminobutyraldehyde",
      "Trimethyl-(4-Oxobutyl)Ammonium",
      "N,N,N-Trimethyl-4-Oxobutan-1-Aminium",
      "Trimethyl(4-Oxobutyl)Azanium",
      "64595-66-0",
      "1-Butanaminium, N,N,N-Trimethyl-4-Oxo-",
      "AC1Q6QJW",
      "4-(Trimethylamino)Butanal",
      "AC1L18JX",
      "SCHEMBL640712",
      "Trimethyl-(4-Oxobutyl)Azanium",
      "4-N-Trimethylaminobutyraldehyde",
      "GTPL6604",
      "CTK5C1452",
      "CHEBI:18020",
      "DTXSID20214844",
      "N,N,N-Trimethyl-4-Oxo-1-Butanaminium",
      "1-Butanaminium,N,N,N-Trimethyl-4-Oxo-",
      "C01149",
    ],
  },
  {
    primary_id: "D0V4NV",
    names: ["Folipastatin", "139959-71-0"],
  },
  {
    primary_id: "D0V4NW",
    names: ["N-(4-Dipropylaminobutyl)-4-Biphenylcarboxamide", "CHEMBL484204"],
  },
  {
    primary_id: "D0V4NY",
    names: [
      "Cathepsin C Inhibitors",
      "Cathepsin C Inhibitors (Inflammation)",
      "Cathepsin C Inhibitors (Inflammation), Merck & Co",
    ],
  },
  {
    primary_id: "D0V4PB",
    names: [
      "4-(4-FLUOROBENZYL)PIPERIDINE",
      "4-(4'-Fluorobenzyl)Piperidine",
      "92822-02-1",
      "4-[(4-Fluorophenyl)Methyl]piperidine",
      "4-(4-Fluorobenzyl)Piperidine",
      "4-(4-Fluoro-Benzyl)-Piperidine",
      "PIPERIDINE, 4-[(4-FLUOROPHENYL)METHYL]-",
      "CHEMBL144527",
      "Piperidine 4",
      "PubChem12570",
      "AC1Q4MDN",
      "Chembrdg-Bb 4010301",
      "AC1MWI24",
      "SCHEMBL895063",
      "4-(4-Fluorobenzyl)-Piperidine",
      "4-(4'-Fluorobenzyl) Piperidine",
      "CTK8F5377",
      "BDBM15788",
      "DTXSID30395219",
      "JLAKCHGEEBPDQI-UHFFFAOYSA-N",
      "MolPort-002-053-743",
      "HMS3604B15",
      "4-(4-Flourophenylmethyl)Piperidine",
      "ZINC2512916",
      "ALBB-014951",
      "KS-00002B8F",
    ],
  },
  {
    primary_id: "D0V4PH",
    names: ["Tolerimab (Anti-CD80/CD86)"],
  },
  {
    primary_id: "D0V4PJ",
    names: ["9alpha-Acetoxy-Miller-1(10)Z-Enolide", "CHEMBL433971"],
  },
  {
    primary_id: "D0V4PL",
    names: [
      "3-(3,3-Dimethyl-2-Oxoindolin-5-Yl)Benzonitrile",
      "CHEMBL117187",
      "SCHEMBL3518135",
      "BDBM50121164",
      "3,3-Dimethyl-5-(3-Cyanophenyl)Indoline-2-One",
    ],
  },
  {
    primary_id: "D0V4PZ",
    names: ["PGD3"],
  },
  {
    primary_id: "D0V4QE",
    names: [
      "BN-58705",
      "BN 58705",
      "153448-17-0",
      "4-O-Butanoyl-4'-Demethylpodophyllotoxin",
      "Butanoic Acid, 5,5a,6,8a,9-Hexahydro-9-(4-Hydroxy-3,5-Dimethoxyphenyl)-8-Oxofuro(3',4':6,7)Naphtho(2,3-D)-1,3-Dioxol-5-Yl Ester, (5R-(5-Alpha,5a-Alpha,8a-Beta,9-Alpha))-",
      "AC1L4NID",
      "Bn58705",
      "CTK4C7848",
      "Butanoic Acid,(5R,5aR,8aR,9R)-5,5a,6,8,8a,9-Hexahydro-9-(4-Hydroxy-3,5-Dimethoxyphenyl)-8-Oxofuro[3',4':6,7]naphtho[2,3-D]-1,3-Dioxol-5-Ylester",
      "LS-46310",
    ],
  },
  {
    primary_id: "D0V4QS",
    names: ["Olanzapine"],
  },
  {
    primary_id: "D0V4RD",
    names: [
      "(4-Nitrophenyl)(10H-Phenothiazin-10-Yl)Methanone",
      "CHEMBL239834",
      "AC1MJHMR",
      "Oprea1_338082",
      "Oprea1_035764",
      "SCHEMBL10490281",
      "ZINC4725769",
      "BDBM50219213",
      "AKOS000731103",
      "BAS 00604089",
      "(4-Nitrophenyl)-Phenothiazin-10-Ylmethanone",
    ],
  },
  {
    primary_id: "D0V4RH",
    names: ["Lobucavir"],
  },
  {
    primary_id: "D0V4RK",
    names: ["DPP-IV Inhibitors, Toray", "DPP-IV Inhibitors (Type 2 Diabetes), Toray"],
  },
  {
    primary_id: "D0V4RO",
    names: [
      "3-(3-Pyridin-2-Yl-Pyrazol-1-Yl)-Benzonitrile",
      "CHEMBL182558",
      "SCHEMBL3922587",
      "FUXJRPCONXOULZ-UHFFFAOYSA-N",
      "BDBM50151895",
      "ZINC13582319",
    ],
  },
  {
    primary_id: "D0V4RP",
    names: [
      "1-(2-Methoxy-Phenyl)-Piperazine",
      "1-(2-Methoxyphenyl)Piperazine",
      "35386-24-4",
      "1-(O-Methoxyphenyl)Piperazine",
      "1-(2-Methoxyphenyl)-Piperazine",
      "2-MPP",
      "UNII-81NJO1330A",
      "N-(2-Methoxyphenyl)Piperazine",
      "1-(2-Methoxy Phenyl) Piperazine",
      "Piperazine, 1-(2-Methoxyphenyl)-",
      "2-Methoxyphenylpiperazine",
      "1-(2-METHOXYPHENYL) PIPERAZINE",
      "CHEMBL9666",
      "VNZLQLYBRIOLFZ-UHFFFAOYSA-N",
      "81NJO1330A",
      "MFCD00005958",
      "1-(2-Methoxyphenyl)Piperazinehydrochloride",
      "2-Methoxy-1-Piperazinylbenzene",
      "1-(2-Methoxyphenyl)Piperazine, 98+%",
      "1-(2-Methoxyphenyl)Piperazin",
    ],
  },
  {
    primary_id: "D0V4RZ",
    names: ["WAY-214950"],
  },
  {
    primary_id: "D0V4SG",
    names: [
      "3-Benzyl-5-Methylbenzo[d]oxazol-2(3H)-One",
      "3-Benzyl-5-Methyl-3H-Benzooxazol-2-One",
      "CHEMBL523238",
      "F1633-0044",
      "107235-17-6",
      "AC1LLOHB",
      "BAS 07089306",
      "MLS001205717",
      "SCHEMBL10874258",
      "MolPort-000-473-697",
      "ZINC806272",
      "HMS2816L07",
      "BDBM50265523",
      "ZINC 00806272",
      "AKOS000649124",
      "MCULE-9514588723",
      "CCG-142613",
      "SMR000523953",
      "3-Benzyl-5-Methyl-1,3-Benzoxazol-2-One",
      "5-Methyl-3-Benzyl-3-Hydrobenzoxazol-2-One",
    ],
  },
  {
    primary_id: "D0V4TK",
    names: ["N-(6-Methylpyridin-2-Yl)-6-Phenylnicotinamide", "CHEMBL525384"],
  },
  {
    primary_id: "D0V4TV",
    names: [
      "CBLB-502",
      "CBLB-501",
      "Mini-Flagellin, Cleveland BioLabs",
      "Recombinant Flagellin Proteins (Injury Protection), Cleveland BioLabs",
    ],
  },
  {
    primary_id: "D0V4TY",
    names: ["Oxidized Glutathione"],
  },
  {
    primary_id: "D0V4UF",
    names: ["Pramoxine"],
  },
  {
    primary_id: "D0V4UO",
    names: [
      "M-16209",
      "1-(3-Bromo-Benzo[b]furan-2-Ylsulfonyl)Imidazolidine-2,4-Dione",
      "1-(3-Bromobenzo[b]furan-2-Ylsulfonyl)Hydantoin",
    ],
  },
  {
    primary_id: "D0V4UR",
    names: ["NS-1546"],
  },
  {
    primary_id: "D0V4UU",
    names: ["6-Hydroxy-7,8-Dihydro Purine Nucleoside"],
  },
  {
    primary_id: "D0V4VQ",
    names: ["WASP Gene Therapy"],
  },
  {
    primary_id: "D0V4WB",
    names: [
      "(2S,3R)-3-(6-Amino-9H-Purin-9-Yl)Nonan-2-Ol",
      "CHEMBL296435",
      "(2S,3R)-EHNA",
      "CHEBI:63059",
      "(2S,3R)-3-(6-Aminopurin-9-Yl)Nonan-2-Ol",
      "79813-69-7",
      "(S,R)-6-Amino-Beta-Hexyl-Alpha-Methyl-9H-Purine-9-Ethanol",
      "HWC 46",
      "2z7g",
      "AC1L3ZQL",
      "(+)-Erythro-9-(2-Hydroxy-3-Nonyl)Adenine",
      "SCHEMBL1742728",
      "CTK2H9986",
      "BDBM28393",
      "IOSAAWHGJUZBOG-WDEREUQCSA-N",
      "ZINC1558334",
      "EH9",
      "(S-(R*,S*))-6-Amino-Beta-Hexyl-Alpha-Methyl-9H-Purine-9-Ethanol",
      "AKOS030531307",
      "9H-Purine-9-Ethanol, 6-Amino-Beta-Hexyl-Alpha-Methyl-, (S-(R*,S*))-",
      "DB07711",
    ],
  },
  {
    primary_id: "D0V4WD",
    names: ["Ulipristal", "Ella (TN)"],
  },
  {
    primary_id: "D0V4WG",
    names: ["VR-909"],
  },
  {
    primary_id: "D0V4WJ",
    names: ["JNJ-42847922"],
  },
  {
    primary_id: "D0V4WT",
    names: [
      "Paxilline",
      "4b-Hydroxy-2-(1-Hydroxy-1-Methylethyl)-12b,12c-Dimethyl-5,6,6a,7,12,12b,12c,13,14,14a-Decahydro-2H-Chromeno[5',6':6,7]indeno[1,2-B]indol-3(4bH)-One",
      "Paxillin",
      "AC1L1IR1",
      "GTPL2309",
      "BDBM86263",
      "CTK7H1286",
      "ACNHBCIZLNNLRS-UHFFFAOYSA-N",
      "NCGC00168476-01",
      "CAS_57186-25-1",
      "Hydroxy-(1-Hydroxy-1-Methyl-Ethyl)-Dimethyl-[?]one",
      "4b-Hydroxy-2-(1-Hydroxy-1-Methylethyl)-12b,12c-Dimethyl-5,6,6a,7,12,12b,12c,13,14,14a-Decahydro-2H-Chromeno[5',6':6,7]indeno[1,2-B]indol-3(4bh)-One #",
    ],
  },
  {
    primary_id: "D0V4XA",
    names: ["ATH-90879"],
  },
  {
    primary_id: "D0V4XG",
    names: ["GSK2793660", "2793660"],
  },
  {
    primary_id: "D0V4XL",
    names: ["BMEC-1217B"],
  },
  {
    primary_id: "D0V4XT",
    names: ["IONIS-HTTRX"],
  },
  {
    primary_id: "D0V4XY",
    names: ["S-222611"],
  },
  {
    primary_id: "D0V4YA",
    names: ["KHK4083"],
  },
  {
    primary_id: "D0V4YE",
    names: ["Technetium Tc-99m Pyro/Trimeta Phosphates Kit"],
  },
  {
    primary_id: "D0V4YO",
    names: ["ROCICLOVIR"],
  },
  {
    primary_id: "D0V4YQ",
    names: ["Vaxfectin"],
  },
  {
    primary_id: "D0V4YT",
    names: ["CytoRet"],
  },
  {
    primary_id: "D0V4YW",
    names: ["Hepcidin Mab"],
  },
  {
    primary_id: "D0V4ZA",
    names: ["Bisphosphonates"],
  },
  {
    primary_id: "D0V4ZC",
    names: ["MLN4760"],
  },
  {
    primary_id: "D0V4ZN",
    names: ["Cobicistat/Darunavir"],
  },
  {
    primary_id: "D0V4ZQ",
    names: ["SAM-531"],
  },
  {
    primary_id: "D0V5AG",
    names: ["PMID28270010-Compound-Figure5-3"],
  },
  {
    primary_id: "D0V5AJ",
    names: ["Antithrombin Iii"],
  },
  {
    primary_id: "D0V5AL",
    names: ["LC-1", "Dimethylamino-Parthenolide", "Parthenolide Analog (Leukemia), Leuchemix"],
  },
  {
    primary_id: "D0V5AN",
    names: ["6-Tosyl-1,2,3,4,5,6-Hexahydroazepino[4,5-B]indole", "CHEMBL479349"],
  },
  {
    primary_id: "D0V5AQ",
    names: ["2,2-Dibenzylcyclopentanol"],
  },
  {
    primary_id: "D0V5BJ",
    names: ["T-2000", "DMMDPB", "Diphenylbarbituric Acid Prodrug (Epilepsy/Myoclonus/Tremor), Taro"],
  },
  {
    primary_id: "D0V5BV",
    names: ["Benzimidazole Derivative 1"],
  },
  {
    primary_id: "D0V5BX",
    names: ["NITRAQUAZONE"],
  },
  {
    primary_id: "D0V5DD",
    names: ["CART-Meso-19 T Cells"],
  },
  {
    primary_id: "D0V5DX",
    names: ["GR-79236"],
  },
  {
    primary_id: "D0V5FQ",
    names: ["Axitirome", "CGP-26214", "CGS-25845", "CGS-26214"],
  },
  {
    primary_id: "D0V5GB",
    names: ["DOV 102677", "DOV 102,677"],
  },
  {
    primary_id: "D0V5GO",
    names: ["Allox"],
  },
  {
    primary_id: "D0V5GR",
    names: ["Tetra-Hydro-Pyrazolopyrazine Derivative 4"],
  },
  {
    primary_id: "D0V5HA",
    names: ["VX-128"],
  },
  {
    primary_id: "D0V5HF",
    names: [
      "5-Methoxyflavone",
      "42079-78-7",
      "5-Methoxy-2-Phenyl-4h-Chromen-4-One",
      "5-Methoxy-2-Phenylchromen-4-One",
      "5-Methoxy-2-Phenyl-4-Benzopyrone",
      "CHEMBL16685",
      "4H-1-Benzopyran-4-One, 5-Methoxy-2-Phenyl-",
      "5-Methoxy-2-Phenyl-Chromen-4-One",
      "MFCD00016942",
      "ST069360",
      "EINECS 255-652-0",
      "5 - Methoxyflavone",
      "ACMC-20am2h",
      "AC1Q6AKD",
      "AC1L3SE9",
      "Oprea1_859852",
      "Oprea1_842256",
      "BIDD:ER0473",
      "Ghl.PD_Mitscher_Leg0.1155",
      "ZINC5954",
      "MEGxp0_001704",
      "SCHEMBL1629391",
      "ACon1_000175",
      "DTXSID40194917",
      "XRQSPUXANRGDAV-UHFFFAOYSA-N",
      "MolPort-001-742-472",
      "KS-000014KU",
      "BDBM50296862",
    ],
  },
  {
    primary_id: "D0V5HG",
    names: [
      "7,8-Dichloroquinoline-4-One-3-Carboxylic Acid",
      "300675-28-9",
      "7,8-DICHLORO-4-HYDROXYQUINOLINE-3-CARBOXYLIC ACID",
      "144061-33-6",
      "7,8-Dichloro-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "7,8-Dichloro-4-Oxo-1H-Quinoline-3-Carboxylic Acid",
      "CHEMBL218566",
      "7,8-Dichloro-4-Oxo-1,4-Dihydro-Quinoline-3-Carboxylic Acid",
      "J-650168",
      "7,8-Dichloro-1,4-Dihydro-4-Oxo-3-Quinolinecarboxylic Acid",
      "AC1LEVOV",
      "Oprea1_635608",
      "Oprea1_583373",
      "SCHEMBL9654331",
      "SCHEMBL17502115",
      "CTK7I6370",
      "CHEBI:94582",
      "DTXSID00351180",
      "MolPort-000-680-344",
      "MolPort-000-651-074",
      "ZINC3945161",
      "STK539875",
      "MFCD01909987",
    ],
  },
  {
    primary_id: "D0V5HI",
    names: ["N-(5-Amino-6-Oxo-Heptyl)-Acetamidine"],
  },
  {
    primary_id: "D0V5HV",
    names: [
      "TFMPP",
      "1-[3-(Trifluoromethyl)Phenyl]piperazine",
      "15532-75-9",
      "1-(3-(Trifluoromethyl)Phenyl)Piperazine",
      "1-(3-Trifluoromethylphenyl)Piperazine",
      "N-(3-Trifluoromethylphenyl)Piperazine",
      "3-Trifluoromethylphenylpiperazine",
      "Legal E [Street Name]",
      "Piperazine, 1-[3-(Trifluoromethyl)Phenyl]-",
      "UNII-25R3ONU51C",
      "1-(M-Trifluoromethylphenyl)Piperazine",
      "1-(3-Trifluoromethyl-Phenyl)-Piperazine",
      "EINECS 239-574-4",
      "N-(Alpha,alpha,alpha-Trifluoro-M-Tolyl)Piperazine",
      "NSC 128882",
      "N-(Alpha,alpha,alpha-Trifluoro-3-Tolyl)Piperazine",
    ],
  },
  {
    primary_id: "D0V5IQ",
    names: ["PMID29671355-Compound-27"],
  },
  {
    primary_id: "D0V5IW",
    names: [
      "Secnidazole",
      "3366-95-8",
      "Secnidazol",
      "Secnidazolum",
      "Flagentyl",
      "Secnidazolum [INN-Latin]",
      "Solosec",
      "RP 14539",
      "Secnidazol [INN-Spanish]",
      "PM 185184;Secnidazole (Flagentyl)",
      "SYM-1219",
      "EINECS 222-134-0",
      "Seknidazol",
      "MLS000559043",
      "PM-185184",
      "NCGC00095158-01",
      "SMR000149359",
      "RP-14539",
    ],
  },
  {
    primary_id: "D0V5IZ",
    names: [
      "4-{2-[Ethyl-(4-Phenyl-Butyl)-Amino]-Ethyl}-Phenol",
      "CHEMBL327075",
      "SCHEMBL7374092",
      "BDBM50066540",
      "ZINC13781001",
    ],
  },
  {
    primary_id: "D0V5JH",
    names: [
      "ALT-801",
      "ALT-801 (Donor Lymphocyte Infusion, Cancer)",
      "ALT-801 (Donor Lymphocyte Infusion, Cancer), Altor",
      "STAR IL-2 Conjugate (Donor Lymphocyte Infusion, Cancer), Altor",
      "STAR-Ck (Donor Lymphocyte Infusion, Cancer), Altor",
      "Soluble T-Cell Antigen Receptor IL-2 Conjugate (Donor Lymphocyte Infusion, Cancer), Altor",
    ],
  },
  {
    primary_id: "D0V5JO",
    names: ["T-0128"],
  },
  {
    primary_id: "D0V5JQ",
    names: ["COL-172"],
  },
  {
    primary_id: "D0V5JV",
    names: ["N-Ethyl Estradiol-16-Methyl Carboxamide"],
  },
  {
    primary_id: "D0V5KJ",
    names: [
      "Interferon Beta 1a Inhalation - Synairgen",
      "Aerosolised Interferon Beta 1a",
      "AZD-9412",
      "Inhaled Interferon Beta 1a",
      "SNG-001",
    ],
  },
  {
    primary_id: "D0V5KW",
    names: ["EZN-2279"],
  },
  {
    primary_id: "D0V5KY",
    names: ["PMID29338548-Compound-45"],
  },
  {
    primary_id: "D0V5LG",
    names: ["ChronVac-C Hepatitis C DNA Vaccine", "ChronVac-C (TN)"],
  },
  {
    primary_id: "D0V5LJ",
    names: [
      "JNJ-10329670",
      "Cathepsin S Inhibitors (Autoimmune Disease)",
      "Cathepsin S Inhibitors (Autoimmune Disease), J&J PRD",
      "Cathepsin S Inhibitors (Autoimmune Disease), Johnson & Johnson",
    ],
  },
  {
    primary_id: "D0V5NK",
    names: ["PMID28870136-Compound-54"],
  },
  {
    primary_id: "D0V5NZ",
    names: [
      "5'-(N-Ethyl-N-Isopropyl)Amiloride",
      "EIPA",
      "5-(N-Ethyl-N-Isopropyl)Amiloride",
      "1154-25-2",
      "Ethylisopropylamiloride",
      "5-(Ethylisopropyl)Amiloride",
      "Ethyl Isopropyl Amiloride",
      "5-(N-Ethyl-N-Isopropyl)-Amiloride",
      "3-Amino-N-(Aminoiminomethyl)-6-Chloro-5-[ethyl(1-Methylethyl)Amino]-2-Pyrazinecarboxamide",
      "N-Amidino-3-Amino-5-Ethylisopropylamino-6-Chloropyrazine Carboxamide",
      "C11H18ClN7O",
      "CHEMBL517986",
      "5-(N-Ethyl-N-Isopropyl) Amiloride",
      "3-Amino-N-(Aminoiminomethyl)-6-Chloro-5-(Ethyl(1-Methylethyl)Amino)Pyrazinecarboxamide",
    ],
  },
  {
    primary_id: "D0V5OG",
    names: ["BE-14106", "BE-14106 Analogs (Cancer)", "BE-14106 Analogs (Cancer), Biosergen"],
  },
  {
    primary_id: "D0V5OS",
    names: ["H87/07"],
  },
  {
    primary_id: "D0V5OW",
    names: [
      "4-(Carbamolymethylthio)Pyridine-3-Sulfonamide",
      "CHEMBL1163376",
      "4-(Carbamoylmethylthio)Pyridine-3-Sulfonamide",
      "1078160-11-8",
    ],
  },
  {
    primary_id: "D0V5OY",
    names: [
      "N-(1-Benzylpiperidine-4-Yl)-2-Naphthamide",
      "CHEMBL228972",
      "N-(1-Benzylpiperidin-4-Yl)Naphthalene-2-Carboxamide",
      "MolPort-005-630-484",
      "BDBM50203383",
      "ZINC12985947",
      "STK491618",
      "AKOS003382305",
      "MCULE-1935524858",
      "N-(1-Benzylpiperidin-4-Yl)-2-Naphthamide",
      "ST50877721",
      "2-Naphthyl-N-[1-Benzyl(4-Piperidyl)]carboxamide",
      "Z32353863",
    ],
  },
  {
    primary_id: "D0V5PR",
    names: [
      "AS-602704",
      "339990-02-2",
      "AIPs (Alzheimers), Axonyx/Serono",
      "Ac-LPFFD-NH2",
      "Amyloid-Inhibiting Peptides, Serono/Axonyx",
      "Beta-Amyloid Inhibitors, Serono/Axonyx",
      "Beta-Sheet Breaker Peptides (Alzheimers), Serono/Axonyx",
    ],
  },
  {
    primary_id: "D0V5QH",
    names: ["ONO-9054"],
  },
  {
    primary_id: "D0V5QY",
    names: ["StaphTAME"],
  },
  {
    primary_id: "D0V5SC",
    names: ["Liproca Depot"],
  },
  {
    primary_id: "D0V5SQ",
    names: ["Spiramide", "AMI-193", "R-5808"],
  },
  {
    primary_id: "D0V5TC",
    names: [
      "1-(3-Nitropyridin-2-Yl)-4-Tosylpiperazine",
      "AC1PKJ5N",
      "Arylsulfonylpiperazine, 26",
      "CHEMBL257825",
      "BDBM32554",
      "MolPort-005-603-659",
      "1-(4-Methylphenyl)Sulfonyl-4-(3-Nitropyridin-2-Yl)Piperazine",
      "ZINC24082236",
      "AKOS034151568",
      "MCULE-2914079043",
      "AB00774122-01",
      "Z31097365",
      "1-(4-Methylbenzenesulfonyl)-4-(3-Nitropyridin-2-Yl)Piperazine",
    ],
  },
  {
    primary_id: "D0V5TP",
    names: ["Adipose-Derived Stem Cell Therapy", "Adipose-Derived Stem Cell Therapy (Celution System)"],
  },
  {
    primary_id: "D0V5VA",
    names: ["CIGB-814"],
  },
  {
    primary_id: "D0V5VR",
    names: ["LEO 90100"],
  },
  {
    primary_id: "D0V5WL",
    names: ["Met-Enkephalin"],
  },
  {
    primary_id: "D0V5XJ",
    names: ["AG-AFZ709"],
  },
  {
    primary_id: "D0V5XL",
    names: [
      "KRI-1314",
      "CHEMBL3350322",
      "Kri-1314",
      "CHEMBL91826",
      "4-Cyclohexyl-2-Hydroxy-3-[3-(1H-Imidazol-4-Yl)-2-(4-Morpholin-4-Yl-2-Naphthalen-1-Ylmethyl-4-Oxo-Butyrylamino)-Propionylamino]-Butyric Acid Isopropyl Ester",
      "BDBM50012951",
      "(2R,3S)-3-[N-[(2R)-3-(Morpholinocarbonyl)-2-[(Naphthalen-1-Yl)Methyl]propionyl]-L-Histidyl]amino-4-Cyclohexyl-2-Hydroxybutanoic Acid Isopropyl Ester",
    ],
  },
  {
    primary_id: "D0V5YA",
    names: ["TA-381"],
  },
  {
    primary_id: "D0V5YQ",
    names: ["TS Antisense + Raltitrexed"],
  },
  {
    primary_id: "D0V5YV",
    names: ["C(His-L-Phe-Arg-Trp-Aoc)", "CHEMBL211131"],
  },
  {
    primary_id: "D0V5ZG",
    names: ["TCN-202"],
  },
  {
    primary_id: "D0V5ZI",
    names: ["WR-289012"],
  },
  {
    primary_id: "D0V5ZP",
    names: ["ATC0065", "ATC-0065", "ATC 0065"],
  },
  {
    primary_id: "D0V5ZZ",
    names: [
      "Propantheline",
      "Corrigast",
      "Ercorax",
      "Ercotina",
      "Ketaman",
      "Kivatin",
      "Neometantyl",
      "Neopepulsan",
      "Pantas",
      "Pantheline",
      "Pervagal",
      "Probanthine",
      "Probantine",
      "Prodixamon",
      "Propantel",
      "Propanthel",
      "Propanthelinium",
      "Propanthelinum",
      "Bromure De Propantheline",
      "Bromuro De Proantelina",
      "PROPANTHELINE BROMIDE",
      "Propantelina Bromuro",
      "Propantelina Bromuro [DCIT];Propanthelini Bromidum",
      "P 8891",
      "Bromure De Propantheline [INN-French]",
      "Bromuro De Proantelina [INN-Spanish]",
      "Pro-Banthine",
      "Pro-Gastron",
      "Propanthelini Bromidum [INN-Latin]",
      "SC-3171",
      "Pro-Banthine (TN);Propantheline Bromide (JP15/USP/INN)",
      "Propantheline Bromide [USAN:INN:BAN:JAN]",
      "Diisopropyl(2-Hydroxyethyl)Methylammonium Bromide Xanthene-9-Carboxylate",
      "Xanthene-9-Carboxylic Acid, Ester With (2-Hydroxyethyl)Diisopropylmethylammonium Bromide",
      "Ammonium, (2-Hydroxyethyl)Diisopropylmethyl-, Xanthene-9-Carboxylate (Ester)",
      "Ammonium, Diisopropyl(2-Hydroxyethyl)Methyl-, Bromide, Xanthene-9-Carboxylate",
      "Methyl-Di(Propan-2-Yl)-[2-(9H-Xanthene-9-Carbonyloxy)Ethyl]azanium",
      "Methyl-Di(Propan-2-Yl)-[2-(9H-Xanthene-9-Carbonyloxy)Ethyl]azanium Bromide",
      "N-Methyl-N-(1-Methylethyl)-N-{2-[(9H-Xanthen-9-Ylcarbonyl)Oxy]ethyl}propan-2-Aminium Bromide",
      "(2-Hydroxyethyl)Diisopropylmethylammonium Bromide Xanthene-9-Carboxylate",
      "(2-Hydroxyethyl)Diisopropylmethylammonium Bromide Xanthene-9-Carboxylate Bromide",
      "2-Hydroxyethyl]diisopropylmethyl-Ammonium Bromide Xanthene-9-Carboxylate",
      "2-Propanaminium, N-Methyl-N-(1-Methylethyl)-N-(2-((9H-Xanthen-9-Ylcarbonyl)Oxy)Ethyl)-, Bromide",
      "2-Propanaminium, N-Methyl-N-(1-Methylethyl)-N-(2-((9H-Xanthen-9-Ylcarbonyl)Oxy)Ethyl)-, Bromide (1:1)",
    ],
  },
  {
    primary_id: "D0V6AB",
    names: ["XR-1500"],
  },
  {
    primary_id: "D0V6AH",
    names: ["Endostatin"],
  },
  {
    primary_id: "D0V6AN",
    names: ["Encapsulated Islet Cell Transplantation Therapy"],
  },
  {
    primary_id: "D0V6BE",
    names: ["ALC-919"],
  },
  {
    primary_id: "D0V6BS",
    names: ["Jemdel"],
  },
  {
    primary_id: "D0V6CE",
    names: ["GBT-009"],
  },
  {
    primary_id: "D0V6CM",
    names: [
      "2,3,4,11-Tetrahydro-1H-Benzo[a]carbazole",
      "CHEMBL1173621",
      "Tetrahydrobenzcarbazol",
      "SCHEMBL17775042",
      "BDBM50322598",
    ],
  },
  {
    primary_id: "D0V6CY",
    names: ["ET-005"],
  },
  {
    primary_id: "D0V6CZ",
    names: ["XG-5XX"],
  },
  {
    primary_id: "D0V6DE",
    names: [
      "(11E)-OCTADEC-11-ENOIC ACID",
      "Cis-Vaccenic Acid",
      "(Z)-Octadec-11-Enoic Acid",
      "Cis-11-Octadecenoic Acid",
      "506-17-2",
      "11Z-Octadecenoic Acid",
      "(11E)-Octadec-11-Enoic Acid",
      "Asclepic Acid",
      "(Z)-11-Octadecenoic Acid",
      "11-Octadecenoic Acid, (Z)-",
      "UNII-400K7322UW",
      "CHEBI:50464",
      "400K7322UW",
      "Cis-Octadec-11-Enoic Acid",
      "VCA",
      "Trans-11-Octadecensaeure",
      "11-Cis-Octadecenoic Acid",
      "C18:1n-7",
      "Cis-11-Vaccenic Acid",
      "AC1NR0QT",
      "11(Z)-Octadecenoic Acid",
      "Cis-D11-Octadecenoic Acid",
      "SCHEMBL97463",
      "(11Z)Octadec-11-Enoic Acid",
      "(11Z)-Octadec-11-Enoic Acid",
      "CHEMBL1236642",
      "UWHZIFQPPBDJPM-FPLPWBNLSA-N",
    ],
  },
  {
    primary_id: "D0V6DF",
    names: ["LJP-1207"],
  },
  {
    primary_id: "D0V6DJ",
    names: ["5-Methylurapidil", "5 Methylurapidil", "5-Methyl-Urapidil"],
  },
  {
    primary_id: "D0V6DX",
    names: ["Isoquinoline-1,3,4(2H)-Trione"],
  },
  {
    primary_id: "D0V6EC",
    names: ["INOmax Nitric Oxide Inhalation"],
  },
  {
    primary_id: "D0V6EH",
    names: [
      "Clorsulon",
      "CLORSULON",
      "60200-06-8",
      "Curatrem",
      "Clorsulonum [Latin]",
      "Clorsulone [French]",
      "Clorsulon [Spanish]",
      "UNII-EG1ZDO6LRD",
      "EG1ZDO6LRD",
      "EINECS 262-100-2",
      "MK 401",
      "MK-401",
      "BRN 2821757",
      "4-Amino-6-(1,2,2-Trichloroethenyl)Benzene-1,3-Disulfonamide",
      "4-Amino-6-(Trichlorovinyl)-M-Benzenedisulfonamide",
      "L 631529",
      "L-631,529",
      "4-Amino-6-(Trichlorovinyl)-1,3-Benzenedisulfonamide",
      "4-Amino-6-(Trichloroethenyl)-1,3-Benzenedisulfonamide",
      "1,3-Benzenedisulfonamide, 4-Amino-6-(Trichloroethenyl)-",
      "NCGC00016893-01",
      "NCGC00182084-01",
    ],
  },
  {
    primary_id: "D0V6EV",
    names: ["PF-4554878"],
  },
  {
    primary_id: "D0V6EY",
    names: [
      "Pumiliotoxin 251D",
      "73376-35-9",
      "AC1O5TO7",
      "(+-)-Pumiliotoxin 251D",
      "CHEMBL358960",
      "PTX 251D",
      "8-Indolizinol, Octahydro-8-Methyl-6-((2R)-2-Methylhexylidene)-, (6Z,8S,8aS)-",
      "C20031",
    ],
  },
  {
    primary_id: "D0V6FV",
    names: ["OcuXan", "Antioxidant Prodrug (AMD), Cardax Pharmaceuticals"],
  },
  {
    primary_id: "D0V6GT",
    names: [
      "IDN-6439",
      "Anti Erb2 DNA Vaccine (Mouth Tumors), Indena",
      "Anti Erbb2 DNA Vaccine (Mouth Tumors), Indena",
    ],
  },
  {
    primary_id: "D0V6GX",
    names: ["BX-201", "Indolinone Based Inhibitor, 3l", "CHEMBL442229", "SCHEMBL5728132", "BDBM16981"],
  },
  {
    primary_id: "D0V6HE",
    names: ["FGGFTGARKSARKLARK", "CHEMBL499157"],
  },
  {
    primary_id: "D0V6HG",
    names: ["3-(6-(Phenylamino)-9H-Purin-8-Yl)Benzonitrile", "CHEMBL1210236", "BDBM50322829"],
  },
  {
    primary_id: "D0V6HS",
    names: [
      "R-82913",
      "9-Cl-Tibo",
      "9-Cmbib",
      "R82913",
      "R 82913",
      "UNII-4FOI87E52P",
      "4-CHLORO-8-METHYL-7-(3-METHYL-BUT-2-ENYL)-6,7,8,9-TETRAHYDRO-2H-2,7,9A-TRIAZA-BENZO[CD]AZULENE-1-THIONE",
      "NSC 637653",
      "CHEMBL293498",
      "4FOI87E52P",
      "126347-69-1",
      "9-Chloro-4,5,6,7-Tetrahydro-5-Methyl-6-(3-Methyl-2-Butenyl)Imidazo(4,5,1-Jk)(1,4)Benzodiazepine-2-(1H)-Thione",
      "4,5,6,7-Tetrahydro-9-Chloro-5-Methyl-6-(3-Methyl-2-Butenyl)Imidazo(4,5,1-Jk)(1,4)Benzodiazepin-2-(1H)-Thione",
      "TB9",
      "Imidazo(4,5,1-Jk)(1,4)Benzodiazepine-2(1H)-Thione, 9-Chloro-4,5,6,7-Tetrahydro-5-",
    ],
  },
  {
    primary_id: "D0V6IE",
    names: ["Pentanoic Acid"],
  },
  {
    primary_id: "D0V6IG",
    names: [
      "(S)-WAY 100135",
      "CHEMBL22564",
      "N-Tert-Butyl-3-[4-(2-Methoxyphenyl)Piperazin-1-Yl]-2-Phenylpropanamide",
      "AC1L3GJK",
      "GTPL79",
      "SCHEMBL2230435",
      "CHEBI:104131",
      "BDBM50047472",
      "PDSP2_000562",
      "PDSP1_000564",
      "N-Tert-Butyl-3-(4-(2-Methoxyphenyl)-Piperazin-1-Yl)-2-Phenylpropanamide",
      "1-Piperazinepropanamide, N-(1,1-Dimethylethyl)-4-(2-Methoxyphenyl)-Alpha-Phenyl-",
      "NCGC00386535-01",
      "AN-16953",
      "LS-119206",
      "FT-0643189",
      "L001169",
    ],
  },
  {
    primary_id: "D0V6IM",
    names: ["SBG"],
  },
  {
    primary_id: "D0V6IN",
    names: ["PV-267"],
  },
  {
    primary_id: "D0V6JF",
    names: ["SAR-104772"],
  },
  {
    primary_id: "D0V6JJ",
    names: ["LP-1810"],
  },
  {
    primary_id: "D0V6JP",
    names: ["Endixaprine"],
  },
  {
    primary_id: "D0V6KF",
    names: ["LHRH"],
  },
  {
    primary_id: "D0V6KJ",
    names: [
      "6-(2,6-Difluoro-4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL192621",
      "SCHEMBL3924515",
      "AUZLISXBMGKYGA-UHFFFAOYSA-N",
      "ZINC13645047",
      "BDBM50168333",
    ],
  },
  {
    primary_id: "D0V6KL",
    names: ["CLP-635", "CLP-656"],
  },
  {
    primary_id: "D0V6KN",
    names: ["PMID25313322C15"],
  },
  {
    primary_id: "D0V6KU",
    names: [
      "CMV Glycoprotein B Subunit Vaccine",
      "CMV GB Subunit Vaccine, GlaxoSmithKline",
      "CMV Glycoprotein B Subunit Vaccine, GlaxoSmithKline",
      "Cytomegalovirus Glycoprotein B Subunit Vaccine, GlaxoSmithKline",
      "GSK-1492903-A",
    ],
  },
  {
    primary_id: "D0V6KV",
    names: ["HL-2353"],
  },
  {
    primary_id: "D0V6LF",
    names: ["NN9928"],
  },
  {
    primary_id: "D0V6LH",
    names: ["PBI-1101"],
  },
  {
    primary_id: "D0V6LP",
    names: ["Indirubin-3-Methoxime"],
  },
  {
    primary_id: "D0V6LW",
    names: ["Pyrazolo[3,4-D]pyrimidine Derivative 4"],
  },
  {
    primary_id: "D0V6MQ",
    names: ["Burkholderia Pseudomallei Therapeutics"],
  },
  {
    primary_id: "D0V6NX",
    names: ["P2X7 Antagonists"],
  },
  {
    primary_id: "D0V6OA",
    names: ["Lurbinectedin"],
  },
  {
    primary_id: "D0V6OH",
    names: ["MTSEA"],
  },
  {
    primary_id: "D0V6OK",
    names: ["PMID28048944-Compound-5"],
  },
  {
    primary_id: "D0V6OZ",
    names: [
      "CN-105",
      "5-Nitroisoquinoline",
      "607-32-9",
      "Isoquinoline, 5-Nitro-",
      "5-Nitro-Isoquinoline",
      "MLS002637661",
      "PYGMPFQCCWBTJQ-UHFFFAOYSA-N",
      "5-Nitroisochinolin",
      "EINECS 210-133-8",
      "5-Nitro Isoquinoline",
      "PubChem6272",
      "AI3-61887",
      "Maybridge1_001820",
      "ACMC-1AV1Q",
      "AC1Q1X2S",
      "5-Nitroisoquinoline, 98%",
      "Oprea1_032003",
      "SCHEMBL1010663",
      "AC1Q201M",
      "AC1L2B31",
      "CHEMBL353253",
      "PYGMPFQCCWBTJQ-UHFFFAOYSA-",
      "KS-00000DJC",
      "CTK2F2846",
      "ZINC93213",
      "HMS546K16",
      "DTXSID00209511",
      "NSC3017",
      "MolPort-001-761-771",
      "HMS3078A13",
      "ACT09900",
      "ALBB-021274",
      "NSC-3017",
      "ANW-33564",
      "SBB066313",
      "MFCD00006905",
    ],
  },
  {
    primary_id: "D0V6QA",
    names: [
      "Methyl Arachidonyl Fluorophosphonate",
      "(5Z,8Z,11Z,14Z)-Icosa-5,8,11,14-Tetraenyl Methyl Phosphorofluoridate",
      "SCHEMBL177496",
      "GTPL5218",
      "Methylarachidonoylflurophosphonate",
      "Methyl Arachidonyl Fluorophosphate",
      "(5Z,8Z,11Z,14Z)-Icosa-5,8,11,14-Tetraen-1-Yl Methyl Fluorophosphonate",
      "Fluorophosphonic Acid Methyl(5Z,8Z,11Z,14Z)-5,8,11,14-Icosatetrenyl Ester",
    ],
  },
  {
    primary_id: "D0V6QN",
    names: ["1-(4-Cyanobenzyl)-5-(4-Methylphenyl)-1H-Imidazole", "CHEMBL607567"],
  },
  {
    primary_id: "D0V6QZ",
    names: ["2-Nitro-N-(Quinolin-8-Yl)Benzenesulfonamide"],
  },
  {
    primary_id: "D0V6RC",
    names: ["Satumomab Pendetide"],
  },
  {
    primary_id: "D0V6RE",
    names: ["BCH-371", "BCH-1230"],
  },
  {
    primary_id: "D0V6RL",
    names: ["Stempeucel"],
  },
  {
    primary_id: "D0V6RT",
    names: [
      "Cardiolipin",
      "Cardiolipins(20:4w3/20:4w6/18:2w6/18:2w6)",
      "Cardiolipins(20:4n3/20:4n6/18:2n6/18:2n6)",
      "Cardiolipin(20:4w3/20:4w6/18:2w6/18:2w6)",
      "Cardiolipin(20:4n3/20:4n6/18:2n6/18:2n6)",
      "CL(20:4w3/20:4w6/18:2w6/18:2w6)",
      "CL(20:4n3/20:4n6/18:2n6/18:2n6)",
      "CL(20:4(8Z,11Z,14Z,17Z)/20:4(5Z,8Z,11Z,14Z)/18:2(9Z,12Z)/16:0)",
      "CL(1'-[20:4(8Z,11Z,14Z,17Z)/20:4(5Z,8Z,11Z,14Z)],3'-[18:2(9Z,12Z)/18:2(9Z,12Z)])",
    ],
  },
  {
    primary_id: "D0V6SI",
    names: ["RESP-4000"],
  },
  {
    primary_id: "D0V6SS",
    names: [
      "2-Amino-2-(2-Fluorophenyl)Acetic Acid",
      "84145-28-8",
      "Amino(2-Fluorophenyl)Acetic Acid",
      "Amino-(2-Fluoro-Phenyl)-Acetic Acid",
      "2-(2-FLUOROPHENYL)GLYCINE",
      "CHEMBL383715",
      "DL-2-FLUOROPHENYLGLYCINE",
      "CGNMJIBUVDGMIY-UHFFFAOYSA-N",
      "Bionet2_000821",
      "Dl-O-Fluorophenylglycine",
      "AC1LBC1Z",
      "2-Fluoro-Dl-Phenylglycine",
      "2-(O-Fluorophenyl)Glycine",
      "AC1Q4O3E",
      "Dl-(2-Fluorophenyl)-Glycine",
      "2-Fluoro-Dl-",
      "SCHEMBL2628067",
      "AC1Q505A",
      "2-Fluoro-Dl-Alpha-Phenylglycine",
      "CTK8D0864",
      "MolPort-000-000-424",
      "HMS1366F07",
      "2-Fluoro-Dl-.alpha.-Phenylglycine",
    ],
  },
  {
    primary_id: "D0V6SV",
    names: ["PG-201"],
  },
  {
    primary_id: "D0V6TF",
    names: ["PMID25399762-Compound-Figure1-Neonectrolide A"],
  },
  {
    primary_id: "D0V6TM",
    names: ["PMID14697765C11a"],
  },
  {
    primary_id: "D0V6TP",
    names: ["Humanized SMART Anti-IL-12 Antibody"],
  },
  {
    primary_id: "D0V6TX",
    names: ["FR236913"],
  },
  {
    primary_id: "D0V6TY",
    names: ["MYO-201"],
  },
  {
    primary_id: "D0V6UC",
    names: ["Cysteine Sulfenic Acid"],
  },
  {
    primary_id: "D0V6VD",
    names: ["Anti-CD20-CAR Vector-Transduced Autologous T Cells"],
  },
  {
    primary_id: "D0V6VL",
    names: [
      "Cilofungin",
      "Cilofungina",
      "Cilofunginum",
      "L-646991",
      "LY-121019",
      "Cilofungin (USAN/INN)",
      "L-649,991",
      "1-[(4R,5R)-4,5-Dihydroxy-N2-[4-(Octyloxy)Benzoyl]-L-Ornithine]echinocandin B",
      "5.1:6-Anhydro{(4R,5R)-4,5-Dihydroxy-N(2)-[4-(Octyloxy)Benzoyl]-L-Ornithyl-L-Threonyl-(4R)-4-Hydroxy-L-Prolyl-(4S)-4-Hydroxy-4-(4-Hydroxyphenyl)-L-Threonyl-L-Threonyl-(3S,4S)-3-Hydroxy-4-Methyl-L-Proline}",
    ],
  },
  {
    primary_id: "D0V6VQ",
    names: ["PF-956980"],
  },
  {
    primary_id: "D0V6WU",
    names: ["CAR-T Cells Targeting CD38"],
  },
  {
    primary_id: "D0V6WW",
    names: ["CAR-T Cells Targeting CD56"],
  },
  {
    primary_id: "D0V6WZ",
    names: ["PN-201"],
  },
  {
    primary_id: "D0V6XA",
    names: ["5-Methoxy-4,9-Dihydro-3H-Beta-Carboline", "CHEMBL6591", "SCHEMBL19407272", "BDBM50136491"],
  },
  {
    primary_id: "D0V6XJ",
    names: ["Hemsleyadin"],
  },
  {
    primary_id: "D0V6XW",
    names: ["Teglarinad"],
  },
  {
    primary_id: "D0V6YJ",
    names: ["RNAi Therapeutic, AlphaGEN Co Ltd"],
  },
  {
    primary_id: "D0V6YT",
    names: [
      "SR-33805A",
      "3-Isopropyl-2-[4-[3-[N-[2-(3,4-Dimethoxyphenyl)Ethyl]-N-Methylamino]propoxy]phenylsulfonyl]-1-Methylindole Oxalate",
    ],
  },
  {
    primary_id: "D0V6ZC",
    names: ["Aurograb", "Anti-MRSA MAbs, NeuTec"],
  },
  {
    primary_id: "D0V6ZQ",
    names: ["VG-LC"],
  },
  {
    primary_id: "D0V7AA",
    names: [
      "CATECHIN",
      "Cianidanol",
      "(+)-Catechin",
      "154-23-4",
      "Catechuic Acid",
      "Cyanidanol",
      "Catechinic Acid",
      "D-Catechin",
      "Catergen",
      "Cianidol",
      "(+)-Cyanidanol",
      "(+)-Cyanidan-3-Ol",
      "Biocatechin",
      "(+)-Catechin Hydrate",
      "D-(+)-Catechin",
      "Dexcyanidanol",
      "Catechin (Flavan)",
      "Catechol (Flavan)",
      "D-Catechol",
      "(2R,3S)-Catechin",
      "(2R,3S)-2-(3,4-Dihydroxyphenyl)Chroman-3,5,7-Triol",
      "Gambier",
      "3-Cyanidanol, (+)-",
      "Cianidanolum",
      "Catechu",
      "Transepar",
      "Katha",
      "(2R,3S)-(+)-Catechin",
      "Cutch (Dye)",
      "(+)-Cianidanol",
      "3,3',4',5,7-Flavanpentol",
      "KB-53",
      "(+)-Cyanidanol-3",
      "CCRIS 6855",
    ],
  },
  {
    primary_id: "D0V7BD",
    names: ["HuCART19"],
  },
  {
    primary_id: "D0V7BH",
    names: ["FG-4497"],
  },
  {
    primary_id: "D0V7CD",
    names: ["QNEQCPQVSac", "CHEMBL506458"],
  },
  {
    primary_id: "D0V7CF",
    names: [
      "Indinavir",
      "Crixivan",
      "IDV",
      "Indinavir [USAN]",
      "L 735524",
      "Crixivan (TM)",
      "Crixivan (TN)",
      "L-735 524",
      "L-735524",
      "MK-639",
      "Propolis+Indinavir",
      "RS-253",
      "L-735,524",
      "Indinavir, Sulfate (1:1)",
      "Indinavir (*1:1 Sulfate Salt*)",
      "N-[2(R)-HYDROXY-1(S)-INDANYL]-5-[(2(S)-TERTIARY BUTYLAMINOCARBONYL)-4(3-PYRIDYLMETHYL)PIPERAZINO]-4(S)-HYDROXY-2(R)-PHENYLMETHYLPENTANAMIDE",
      "Propolis & 4-Hydroxy-N-(2-Hydroxy-2,3-Dihydro-1H-1-Indanyl)-N'-(1,1-Dimethylethyl)-2-Phenylmethyl-5-[4-(3-Pyridylmethyl)-1-Piperzinyl]hexanediamide",
      "(1(1S,2R),5(S))-2,3,5-Trideoxy-N-(2,3-Dihydro-2-Hydroxy-1H-Inden-1-Yl)-5-(2-(((1,1-Dimethylethyl)Amino)Carbonyl)-4-(3-Pyridinylmethyl)-1-Piperazinyl)-2-(Phenylmethyl)-D-Erythro-Pentonamide",
      "(2S)-1-[(2S,4S)-4-Benzyl-2-Hydroxy-5-[[(1S,2R)-2-Hydroxy-2,3-Dihydro-1H-Inden-1-Yl]amino]-5-Oxopentyl]-N-Tert-Butyl-4-(Pyridin-3-Ylmethyl)Piperazine-2-Carboxamide",
    ],
  },
  {
    primary_id: "D0V7DQ",
    names: ["CD40LGVAX"],
  },
  {
    primary_id: "D0V7DW",
    names: ["Meningococcal Groups A, C, Y And W-135 Conjugate Vaccine"],
  },
  {
    primary_id: "D0V7EA",
    names: [
      "3-(4-Benzoylpiperazine-1-Carbonyl)Coumarin",
      "CHEMBL453852",
      "3-(4-Benzoyl-Piperazine-1-Carbonyl)-Chromen-2-One",
      "Oprea1_255077",
      "Oprea1_655896",
      "MLS001221424",
      "MolPort-000-517-247",
      "ZINC887160",
      "HMS2917O18",
      "HMS1690L15",
      "BDBM50244614",
      "AKOS000599038",
      "MCULE-2543571988",
      "SMR000611504",
      "BAS 02911887",
      "ST50014713",
      "3-{[4-(Phenylcarbonyl)Piperazinyl]carbonyl}chromen-2-One",
    ],
  },
  {
    primary_id: "D0V7EK",
    names: [
      "Phenyl 10H-Phenothiazine-10-Carboxylate",
      "CHEMBL481687",
      "66721-07-1",
      "10H-Phenothiazine-10-Carboxylic Acid, Phenyl Ester",
      "Phenyl Phenothiazine-10-Carboxylate",
      "AC1MXYWS",
      "SCHEMBL4441681",
      "CTK1H9487",
      "DTXSID00396233",
      "BDBM50292620",
    ],
  },
  {
    primary_id: "D0V7ES",
    names: ["DKC-215"],
  },
  {
    primary_id: "D0V7FG",
    names: ["SPH-1285"],
  },
  {
    primary_id: "D0V7FX",
    names: ["RLP-073"],
  },
  {
    primary_id: "D0V7GB",
    names: ["MMP-13 Inhibitors"],
  },
  {
    primary_id: "D0V7GC",
    names: ["Rolafagrel"],
  },
  {
    primary_id: "D0V7GS",
    names: ["RG7461"],
  },
  {
    primary_id: "D0V7HB",
    names: ["Modified CCR5-Transduced Autologous Hematopoietic Stem Cells"],
  },
  {
    primary_id: "D0V7HD",
    names: [
      "BN-50726",
      "BN 50726",
      "127279-06-5",
      "4H-Pyrido[4',3':4,5]thieno[3,2-F][1,2,4]triazolo[4,3-A][1,4]diazepine,6-(2-Chlorophenyl)-9-(Hexadecylsulfonyl)-7,8,9,10-Tetrahydro-1-Methyl-",
      "ACMC-20c9sz",
      "AC1L4UGJ",
      "AC1Q6VN1",
      "Bn50726",
      "CTK4B5547",
      "DTXSID80155486",
      "AKOS030566818",
    ],
  },
  {
    primary_id: "D0V7HI",
    names: [
      "PRINOMIDE TROMETHAMINE",
      "3-(1-Methylpyrrol-2-Yl)-3-Oxo-2-(N-Phenylcarbamoyl)Propionitrile Tromethamine Salt",
    ],
  },
  {
    primary_id: "D0V7HJ",
    names: [
      "3-[2-(N,N,N-Trimethylammonium)Ethoxy]pyridine",
      "CHEMBL345732",
      "BDBM50135703",
      "3-[2-(Trimethylaminio)Ethoxy]pyridine",
      "Trimethyl-[2-(Pyridin-3-Yloxy)-Ethyl]-Ammonium",
    ],
  },
  {
    primary_id: "D0V7HQ",
    names: ["ANA-02"],
  },
  {
    primary_id: "D0V7HS",
    names: ["PMID26394986-Compound-53"],
  },
  {
    primary_id: "D0V7IE",
    names: ["Pamiteplase"],
  },
  {
    primary_id: "D0V7IS",
    names: ["Pumafentrine"],
  },
  {
    primary_id: "D0V7KJ",
    names: [
      "Recombinant Clostridium Botulinum A/B Vaccine",
      "Bivalent Vaccine (Clostridium Botulinum Infection), Dynport",
      "Bivalent Vaccine (Clostridium Botulinum), Dynport",
      "RBV A/B Vaccine, DynPort",
      "Recombinant Clostridium Botulinum A/B Vaccine, DynPort",
    ],
  },
  {
    primary_id: "D0V7KU",
    names: ["2-(3,5-Dichlorophenyl)Pyrido[2,3-D]pyrimidine", "CHEMBL393049"],
  },
  {
    primary_id: "D0V7LZ",
    names: ["S-Propyl Propane-1-Sulfinothioate"],
  },
  {
    primary_id: "D0V7MA",
    names: [
      "AMESERGIDE",
      "Amesergide < Rec INN",
      "LY-237733",
      "N-Cyclohexyl-1-Isopropyl-6-Methylergoline-8beta-Carboxamide",
      "N-Cyclohexyl-4-Isopropyl-7-Methyl-4,6,6abeta,7,8,9,10,10aalpha-Octahydroindolo[4,3-Fg]quinoline-9beta-Carboxamide",
    ],
  },
  {
    primary_id: "D0V7MX",
    names: [
      "9-Cycloheptyl-9H-Adenine",
      "9-Cycloheptylpurin-6-Amine",
      "9-Cycloheptyl-9h-Purin-6-Amine",
      "CHEMBL62835",
      "6961-60-0",
      "NSC62733",
      "AC1L6KRZ",
      "AC1Q4VH9",
      "CTK5D0614",
      "DTXSID20289678",
      "6-Amino-9-Cycloheptyl-9H-Purine",
      "9-Cycloheptyl-9H-Purin-6-Ylamine",
      "9H-Purin-6-Amine, 9-Cycloheptyl-",
      "BDBM50013702",
      "NSC-62733",
    ],
  },
  {
    primary_id: "D0V7NH",
    names: ["Flavonoid Derivative 1"],
  },
  {
    primary_id: "D0V7OE",
    names: ["PMID28870136-Compound-58"],
  },
  {
    primary_id: "D0V7OF",
    names: ["Pyrimidine Derivative 6"],
  },
  {
    primary_id: "D0V7OH",
    names: ["Ceftiofur"],
  },
  {
    primary_id: "D0V7OK",
    names: [
      "4-(1,2-Diphenyl-But-1-Enyl)-Phenol",
      "CHEMBL50995",
      "4-(1,2-Diphenyl-1-Butenyl)Phenol",
      "Monophenoltamoxifen",
      "4-[(Z)-1,2-Diphenylbut-1-Enyl]phenol",
      "AC1O4GB8",
      "SCHEMBL5354173",
      "BDBM50121319",
      "ZINC29469549",
      "69967-80-2",
      "LS-104581",
    ],
  },
  {
    primary_id: "D0V7OO",
    names: ["L-760790"],
  },
  {
    primary_id: "D0V7OU",
    names: ["PMID26882240-Compound-8"],
  },
  {
    primary_id: "D0V7OY",
    names: ["Paracetamol Prodrugs"],
  },
  {
    primary_id: "D0V7PL",
    names: ["Lefradafiban", "BIBU-104", "BIBU-104XX", "BIBV-104"],
  },
  {
    primary_id: "D0V7PO",
    names: ["PMID29053063-Compound-14"],
  },
  {
    primary_id: "D0V7PX",
    names: ["Apogossypol", "BI-79D10", "BI-97C1", "CNDO-103", "CNDO-133"],
  },
  {
    primary_id: "D0V7PY",
    names: ["Giracodazole"],
  },
  {
    primary_id: "D0V7QA",
    names: ["1,4-Dihydroindeno[1,2-C]-Pyrazole", "CHEMBL218957", "SCHEMBL14746942", "BDBM50176977"],
  },
  {
    primary_id: "D0V7QO",
    names: ["BI-836880"],
  },
  {
    primary_id: "D0V7QT",
    names: ["AMI-121"],
  },
  {
    primary_id: "D0V7QU",
    names: ["Ureido-Phenyl-Substituted Triazine Derivative 2"],
  },
  {
    primary_id: "D0V7QW",
    names: ["RG7882"],
  },
  {
    primary_id: "D0V7QZ",
    names: ["2-(2-Chloro-4-Hydroxy-Phenyl)-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D0V7RD",
    names: ["DYSINOSIN A", "Dysinosin A", "CHEMBL502639"],
  },
  {
    primary_id: "D0V7RG",
    names: ["4-Substituted 2,4-Dioxobutanoic Acids"],
  },
  {
    primary_id: "D0V7RH",
    names: ["Calcium Levofolinate"],
  },
  {
    primary_id: "D0V7RI",
    names: ["2,2-Bis-(2-Fluorophenyl)-2-Phenylacetamide", "CHEMBL261606", "SCHEMBL2332073"],
  },
  {
    primary_id: "D0V7RM",
    names: ["(6-Butoxy-2-Naphthyl)-2-Aminopropane", "CHEMBL447882"],
  },
  {
    primary_id: "D0V7RN",
    names: ["CDR-134D123"],
  },
  {
    primary_id: "D0V7RP",
    names: ["5-Fluoro-N-(Pyridin-2-Yl)Pyridin-2-Amine Derivative 1"],
  },
  {
    primary_id: "D0V7SO",
    names: ["PF-06291874"],
  },
  {
    primary_id: "D0V7SU",
    names: ["DD-20207", "DD-161920", "DD-201912", "DD-20197", "DD-20199", "DD-202012", "DD-202021"],
  },
  {
    primary_id: "D0V7SY",
    names: ["Neuronal Transplant Cells"],
  },
  {
    primary_id: "D0V7SZ",
    names: ["ISIS 113905"],
  },
  {
    primary_id: "D0V7TA",
    names: ["2-Phenyl-2H-Pyrazolo[4,3-D]pyrimidin-7(6H)-One"],
  },
  {
    primary_id: "D0V7TC",
    names: ["5-(2-(3-Chlorophenyl)Ethynyl)Pyrimidine", "CHEMBL487870", "SCHEMBL13732174"],
  },
  {
    primary_id: "D0V7TH",
    names: ["PMID24418773C37"],
  },
  {
    primary_id: "D0V7UG",
    names: ["[N-Ac,des-Sar]Gal-B2", "CHEMBL578317"],
  },
  {
    primary_id: "D0V7UQ",
    names: ["PMID25991433-Compound-Q1"],
  },
  {
    primary_id: "D0V7US",
    names: ["NKP 608", "AV608)"],
  },
  {
    primary_id: "D0V7VF",
    names: ["N-(3-(3-Methoxyphenyl)-3-Phenylallyl)Acetamide", "CHEMBL218708", "BDBM50241105", "AKOS030564511"],
  },
  {
    primary_id: "D0V7VX",
    names: ["ALN-HTT"],
  },
  {
    primary_id: "D0V7WD",
    names: ["Androstanol", "5alpha-Androstan-3alpha-Ol"],
  },
  {
    primary_id: "D0V7WS",
    names: ["Zotarolimus", "Abt-578", "Zotarolimus (TN)"],
  },
  {
    primary_id: "D0V7WT",
    names: ["MRS2603"],
  },
  {
    primary_id: "D0V7XC",
    names: ["R-1518"],
  },
  {
    primary_id: "D0V7XF",
    names: ["Dopexamine", "Dopacard (TN)"],
  },
  {
    primary_id: "D0V7YA",
    names: ["GMX1777"],
  },
  {
    primary_id: "D0V7YD",
    names: ["4-Benzyl-1-Methyl-Piperidine Hydrochloride"],
  },
  {
    primary_id: "D0V7YG",
    names: ["EHT/AGN-0003"],
  },
  {
    primary_id: "D0V7YI",
    names: ["Enzyme Mabs"],
  },
  {
    primary_id: "D0V7YK",
    names: ["ImBryon"],
  },
  {
    primary_id: "D0V7YM",
    names: ["Drug 311952", "311952"],
  },
  {
    primary_id: "D0V7YQ",
    names: ["4-((1H-Imidazol-4-Yl)Methyl)-1-Heptylpiperidine", "CHEMBL424847"],
  },
  {
    primary_id: "D0V7ZI",
    names: ["4-(7-Chloro-1-Cyclopentyl-1H-Indazol-3-Yl)Phenol"],
  },
  {
    primary_id: "D0V8AC",
    names: ["SD-208"],
  },
  {
    primary_id: "D0V8AG",
    names: ["Poly-ICLC"],
  },
  {
    primary_id: "D0V8AP",
    names: ["TRC-4186"],
  },
  {
    primary_id: "D0V8AR",
    names: ["Candin"],
  },
  {
    primary_id: "D0V8BQ",
    names: ["Anagrelide Controlled-Release"],
  },
  {
    primary_id: "D0V8BU",
    names: ["P-2202"],
  },
  {
    primary_id: "D0V8CR",
    names: [
      "3-[5-Fluoro-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 5a",
      "SCHEMBL4220971",
      "AC1O704D",
      "CHEMBL193997",
      "BDBM8586",
      "SCHEMBL4220959",
      "ZINC13610768",
      "3-[(E)-(5-Fluoro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0V8DB",
    names: ["8-(3-Azido-Phenyl)-6-Iodo-Quinoline", "CHEMBL127994", "6-Iodo-8-(3-Azidophenyl)Quinoline"],
  },
  {
    primary_id: "D0V8DH",
    names: ["PF-4840154"],
  },
  {
    primary_id: "D0V8DJ",
    names: ["6-(4-Nitro-Benzylsulfanyl)-9-Phenethyl-9H-Purine", "CHEMBL362511"],
  },
  {
    primary_id: "D0V8DQ",
    names: [
      "3',4'-Dichloroacetophenonethiosemicarbazone",
      "CHEMBL90512",
      "HMS544L20",
      "MolPort-000-519-093",
      "18087-41-7",
      "BDBM50114653",
      "AKOS002268894",
      "CCG-244059",
      "ST50170879",
      "[(E)-1-(3,4-Dichlorophenyl)Ethylideneamino]thiourea",
      "1-(3,4-DICHLOROPHENYL)ETHANONE THIOSEMICARBAZONE",
      "(1E)-1-(3,4-Dichlorophenyl)Ethan-1-One Thiosemicarbazone",
    ],
  },
  {
    primary_id: "D0V8DT",
    names: ["Hu5B3.v3"],
  },
  {
    primary_id: "D0V8EH",
    names: ["Isoxazol"],
  },
  {
    primary_id: "D0V8EQ",
    names: ["1-Adamantan-1-Yl-3-(3-Methoxy-Phenyl)-Urea", "CHEMBL516890", "ZINC8167942", "BDBM50267096"],
  },
  {
    primary_id: "D0V8FC",
    names: ["PMID25522065-Compound-30"],
  },
  {
    primary_id: "D0V8FG",
    names: [
      "4-Nitrobenzo[c][1,2,5]thiadiazole",
      "4-Nitro-2,1,3-Benzothiadiazole",
      "6583/6/8",
      "4-Nitropiazthiole",
      "2,1,3-Benzothiadiazole, 4-Nitro-",
      "NSC404747",
      "CHEMBL383084",
      "4-Nitro-1,2,3-Benzothiadiazole",
      "4-Nitro-Benzo[1,2,5]thiadiazole",
      "4-Nitrobenzo[c]1,2,5-Thiadiazole",
      "ACMC-1B59L",
      "Oprea1_370043",
      "4-Bromo-2,8-Dimethylphenol",
      "C6H3N3O2S",
      "4-Nitro-2,3-Benzothiadiazole",
      "SCHEMBL2213406",
      "AC1Q202C",
      "AC1L30J9",
      "CTK5C3163",
      "DTXSID10216019",
      "4-Nitrobenzo-2,1,3-Thiadiazole",
      "MolPort-004-288-376",
      "IWQKAMJGVIHECB-UHFFFAOYSA-N",
      "MolPort-001-779-862",
    ],
  },
  {
    primary_id: "D0V8GT",
    names: ["E-0116"],
  },
  {
    primary_id: "D0V8GX",
    names: ["AZD8542", "AZD 8542", "AZD-8542"],
  },
  {
    primary_id: "D0V8HA",
    names: ["Mecamylamine"],
  },
  {
    primary_id: "D0V8HB",
    names: ["Food Allergy Epicutaneous Immunotherapy"],
  },
  {
    primary_id: "D0V8HF",
    names: ["GS-9160"],
  },
  {
    primary_id: "D0V8HJ",
    names: [
      "Fostamatinib",
      "901119-35-5",
      "R788",
      "Tavalisse",
      "UNII-SQ8A3S5101",
      "R-788 Free Acid",
      "R 788",
      "R-788",
      "R-935788 Free Acid",
      "SQ8A3S5101",
      "R7935788",
      "Fostamatinib [USAN:INN]",
    ],
  },
  {
    primary_id: "D0V8HK",
    names: ["H-NOX Proteins"],
  },
  {
    primary_id: "D0V8IZ",
    names: ["2-(N-Tert-Butylamino)-3'-Chlorohexanophenone", "CHEMBL576622", "SCHEMBL634045", "BDBM50302931"],
  },
  {
    primary_id: "D0V8JJ",
    names: ["WR-289016"],
  },
  {
    primary_id: "D0V8JZ",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 17"],
  },
  {
    primary_id: "D0V8KA",
    names: ["PMID20638279C7", "Compound 7 [PMID: 20180624]", "1105039-00-6", "GTPL6232", "BDBM50353293"],
  },
  {
    primary_id: "D0V8KB",
    names: ["CM-4612", "CM-ADHD"],
  },
  {
    primary_id: "D0V8KD",
    names: ["P-1101"],
  },
  {
    primary_id: "D0V8KH",
    names: ["ISIS 11158"],
  },
  {
    primary_id: "D0V8KI",
    names: ["Fenspiride Hydrochloride"],
  },
  {
    primary_id: "D0V8KZ",
    names: ["ISIS 103601"],
  },
  {
    primary_id: "D0V8MD",
    names: [
      "8-PHENYL THEOPHYLLINE",
      "8-Phenyltheophylline",
      "1,3-Dimethyl-8-Phenylxanthine",
      "961-45-5",
      "Theophylline, 8-Phenyl-",
      "UNII-E6M543P3BL",
      "NSC 14127",
      "CHEMBL62350",
      "E6M543P3BL",
      "MFCD00005582",
      "1,3-Dimethyl-8-Phenyl-3,7-Dihydro-1H-Purine-2,6-Dione",
      "1,3-Dimethyl-8-Phenyl-7H-Purine-2,6-Dione",
      "1H-Purine-2,6-Dione, 2,3,6,7-Tetrahydro-1,3-Dimethyl-8-Phenyl-",
      "AC1L1CIX",
      "Lopac-P-2278",
      "Oprea1_390706",
      "Lopac0_000917",
      "MLS000069624",
      "SCHEMBL516432",
      "8-PT",
      "8-Phenyl-1,3-Dimethylxanthine",
      "1,3-Dimethyl-8-Phenyl-1H-Purine-2,6(3H,7H)-Dione",
      "BDBM82015",
      "DTXSID90242119",
      "MolPort-002-605-303",
    ],
  },
  {
    primary_id: "D0V8MJ",
    names: [
      "4-(6-Cyclopentyl-Hex-3-Ynyl)-1H-Imidazole",
      "CHEMBL274977",
      "SCHEMBL7621345",
      "PDSP1_000105",
      "ZINC13795227",
      "BDBM50070220",
      "PDSP2_000105",
      "GT-2286",
      "GT 2286",
    ],
  },
  {
    primary_id: "D0V8MX",
    names: [
      "3,4,5-Trimethoxy-N-(Thiazol-2-Yl)Benzamide",
      "3,4,5-Trimethoxy-N-(1,3-Thiazol-2-Yl)Benzamide",
      "50591-71-4",
      "AC1LCU6P",
      "Cambridge Id 5149479",
      "Oprea1_821264",
      "MLS000027375",
      "CHEMBL225354",
      "ZINC45264",
      "XSAYHNGEJLPKEX-UHFFFAOYSA-N",
      "MolPort-000-653-421",
      "HMS2331F23",
      "STK731712",
      "AKOS001625155",
      "MCULE-9743171840",
      "NCGC00018998-01",
      "NCGC00018998-02",
      "ST067007",
      "SMR000034190",
      "Benzamide, 3,4,5-Trimethoxy-N-2-Thiazolyl-",
      "Thiazole, 2-(3,4,5-Trimethoxybenzoylamino)-",
      "3,4,5-Trimethoxy-N-1,3-Thiazol-2-Ylbenzamide",
      "SR-01000000944",
      "SR-01000000944-2",
      "Z28173733",
    ],
  },
  {
    primary_id: "D0V8NL",
    names: ["AMG 673"],
  },
  {
    primary_id: "D0V8NS",
    names: ["ARC239"],
  },
  {
    primary_id: "D0V8PA",
    names: ["NERIDRONIC ACID"],
  },
  {
    primary_id: "D0V8PE",
    names: ["Butyl 10H-Phenothiazine-10-Carboxylate", "CHEMBL481142"],
  },
  {
    primary_id: "D0V8PN",
    names: ["Droxinavir Hydrochloride"],
  },
  {
    primary_id: "D0V8PQ",
    names: ["NGN-9078"],
  },
  {
    primary_id: "D0V8QI",
    names: ["7-Methoxy-3-Thiophen-3-Yl-Quinoline", "CHEMBL68185", "SCHEMBL8500071", "ZINC8076"],
  },
  {
    primary_id: "D0V8QT",
    names: [
      "Chlorambucil",
      "Ambochlorin",
      "Amboclorin",
      "Chlocambucil",
      "Chloorambucol",
      "Chlorambucilum",
      "Chloraminophen",
      "Chloraminophene",
      "Chlorbutin",
      "Chlorbutine",
      "Chlorbutinum",
      "Chloroambucil",
      "Chlorobutin",
      "Chlorobutine",
      "Clorambucile",
      "Clorambucilo",
      "Ecloril",
      "Elcoril",
      "Elcorin",
      "Leukeran",
      "Leukersan",
      "Leukoran",
      "Linfolizin",
      "Linfolysin",
      "Lympholysin",
      "Clorambucile [DCIT]",
      "Glaxo Wellcome Brand Of Chlorambucil",
      "GlaxoSmithKline Brand Of Chlorambucil",
      "Leuk Ersan",
      "Leukeran Tablets",
      "Phenylbutyric Acid Nitrogen Mustard",
      "Wellcome Brand Of Chlorambucil",
      "C0253",
      "CB 1348",
      "CB1348",
      "Cb L348",
      "CB-1348",
      "Chlorambucil [INN:BAN]",
      "Chlorambucilum [INN-Latin]",
      "Clorambucilo [INN-Spanish]",
      "LEUKERAN (TN)",
      "Leukeran (TN)",
      "Phenylbuttersaeure-Lost",
      "Phenylbuttersaeure-Lost[German]",
      "Chlorambucil (USP/INN)",
    ],
  },
  {
    primary_id: "D0V8RV",
    names: ["Rotavin"],
  },
  {
    primary_id: "D0V8RY",
    names: ["KR-30450"],
  },
  {
    primary_id: "D0V8SM",
    names: [
      "8-Imidazol-1-Ylmethyl-5-Nitro-Chromen-4-One",
      "CHEMBL351167",
      "BDBM50097362",
      "8-((1H-Imidazol-1-Yl)Methyl)-5-Nitro-4H-Chromen-4-One",
    ],
  },
  {
    primary_id: "D0V8TQ",
    names: [
      "Aluminium",
      "ALUMINUM",
      "Adom",
      "Aluminio",
      "Metana",
      "AO Al",
      "Al Alloy",
      "Alaun [German]",
      "Allbri Aluminum Paste And Powder",
      "Alumina Fibre",
      "Aluminium Bronze",
      "Aluminium Flake",
      "Aluminum Alloy",
      "Aluminum Dehydrated",
      "Aluminum Dust",
      "Aluminum Metal",
      "Aluminum Powder",
      "Aluminum Production",
      "Aluminum Pyro Powders",
      "Aluminum Soluble Salts",
      "Aluminum Welding Fumes",
      "Emanay Atomized Aluminum Powder",
      "Metana Aluminum Paste",
      "Noral Extra Fine Lining Grade",
      "Noral Ink Grade Aluminum",
      "Noral Aluminium",
      "Noral Aluminum",
      "Noral Ink Grade Aluminium",
      "A 00",
      "A 95",
      "A 99",
      "A 995",
      "A 999",
      "AA 1099",
      "AA1199",
      "AD 1",
      "AD1M",
      "AO A1",
      "AV00",
      "AV000",
      "Aa1193",
      "Aluminum 27",
      "Aluminum A00",
      "Aluminum Hydride [UN2463] [Dangerous When Wet]",
      "JISC 3108",
      "JISC 3110",
      "L16",
      "NA9260",
      "Pigment Metal 1",
      "ALUMINUM, ATOMIZED",
      "Al(0)",
      "Aluminium(0)",
      "Aluminium, Elementar",
      "Aluminum (Dust Or Fume)",
      "Aluminum (Fume Or Dust)",
      "Aluminum (Metal)",
      "Aluminum Metal, Alkyls",
      "Aluminum Metal, Pyro Powders",
      "Aluminum Metal, Respirable Fraction",
      "Aluminum Metal, Soluble Salts",
      "Aluminum Metal, Total Dust",
      "Aluminum Metal, Welding Fumes",
      "Aluminum Powder, Coated [UN1309] [Flammable Solid]",
      "Aluminum Powder, Uncoated [UN1396] [Dangerous When Wet]",
      "Aluminum, Elemental",
      "Aluminum, Molten [NA9260] [Class 9]",
      "Aluminum, Pyro Powders",
      "Aluminum, Soluble Salts",
      "Aluminum, Welding Fumes",
      "C-Pigment 1",
      "C-Pigment 1 [German]",
      "Noral Non-Leafing Grade",
      "Aluminum, Pyro Powders/Welding Fumes",
      "C.I. 77000",
      "ALUMINUM (SEE ALSO ALUMINUM OXIDE 1344-28-1)",
      "ALUMINUM, 99.999%",
      "AQUANAL-Plus Aluminum (Al) 0.02-0.2 Mg/L",
      "AQUANAL(R)-Plus Aluminum (Al) 0.02-0.2 Mg/L",
      "11008_ALDRICH",
      "11008_RIEDEL",
      "11009_ALDRICH",
      "11009_RIEDEL",
      "11010_ALDRICH",
      "11010_RIEDEL",
      "13Al",
    ],
  },
  {
    primary_id: "D0V8UG",
    names: ["H-Dmt-Tic-NH-CH2-Boa", "CHEMBL258458"],
  },
  {
    primary_id: "D0V8UJ",
    names: ["PEP-6409"],
  },
  {
    primary_id: "D0V8UV",
    names: ["N-Hydroxy-N-Methyl-3-Naphthalen-1-Yl-Acrylamide", "CHEMBL175134", "BDBM50015100"],
  },
  {
    primary_id: "D0V8VY",
    names: ["2-Pyrrolidin-1-Yl-Benzo[h]chromen-4-One"],
  },
  {
    primary_id: "D0V8WE",
    names: [
      "4-(4,5-Dihydrothiazol-2-Yl)Phenyl Butylcarbamate",
      "CHEMBL457882",
      "AC1MCGW6",
      "MolPort-002-923-829",
      "HMS1669K08",
      "ZINC4394394",
      "BDBM50274981",
      "CCG-56239",
      "MCULE-6668458781",
      "SR-01000645209-1",
      "[4-(4,5-Dihydro-1,3-Thiazol-2-Yl)Phenyl] N-Butylcarbamate",
    ],
  },
  {
    primary_id: "D0V8XD",
    names: ["(-)-SNAP-5399"],
  },
  {
    primary_id: "D0V8XG",
    names: ["PMID28117607-Compound-6"],
  },
  {
    primary_id: "D0V8YH",
    names: ["RvD1-ME"],
  },
  {
    primary_id: "D0V8ZD",
    names: ["SQ32,910"],
  },
  {
    primary_id: "D0V8ZU",
    names: [
      "8alpha,19-Dihydroxylabd-13 E-En-15-Oic Acid",
      "CHEBI:65789",
      "(2E)-5-[(1R,2R,4aR,5R,8aS)-2-Hydroxy-5-(Hydroxymethyl)-2,5,8a-Trimethyldecahydronaphthalen-1-Yl]-3-Methylpent-2-Enoic Acid",
      "CHEMBL507405",
      "8alpha-19-Dihydroxylabd-13E-Ene-15-Oic Acid",
      "8alpha,19-Dihydroxylabd-13E-Ene-15-Oic Acid",
      "(13E)-8,18-Dihydroxylabda-13-Ene-15-Oic Acid",
    ],
  },
  {
    primary_id: "D0V9AB",
    names: ["Cyclic Sulfonamide Derivative 4"],
  },
  {
    primary_id: "D0V9AJ",
    names: [
      "1-(2-Methoxyphenethyl)Pyrrolidine",
      "CHEMBL257913",
      "2-(2-Methoxyphenyl)-1-(Pyrrolidin-1-Yl)Ethane",
      "123902-10-3",
      "ZINC29130875",
      "BDBM50372328",
    ],
  },
  {
    primary_id: "D0V9AL",
    names: ["MCAF5352A"],
  },
  {
    primary_id: "D0V9AU",
    names: ["1-(4-Aminosulfonylphenyl)-2-(2-Pyridyl)Acetylene", "CHEMBL265444"],
  },
  {
    primary_id: "D0V9BB",
    names: ["Des-His1[Glu9]glucagon-NH2"],
  },
  {
    primary_id: "D0V9BD",
    names: [
      "Bicalutamide",
      "Casodex",
      "Cosudex",
      "Propanamide",
      "Raffolutil",
      "Astra Brand Of Bicalutamide",
      "AstraZeneca Brand Of Bicalutamide",
      "Zeneca Brand Of Bicalutamide",
      "Calutide (TN)",
      "Casodex (TN)",
      "Cosudex (TN)",
      "KS-1161",
      "Kalumid (TN)",
      "Bicalutamide [USAN:INN:BAN]",
      "Bicalutamide (JAN/USP/INN)",
      "Casodex, Cosudex, Calutide, Kalumid, Bicalutamide",
      "N-[4-Cyano-3-(Trifluoromethyl)Phenyl]-3-(4-Fluorophenyl)Sulfonyl-2-Hydroxy-2-Methylpropanamide",
      "(+-)-4'-Cyano-Alpha,alpha,alpha-Trifluoro-3-((P-Fluorophenyl)Sulfonyl)-2-Methyl-M-Lactotoluidide",
      "4'-Cyano-3-(4-Fluorophenylsulfonyl)-2-Hydroxy-2-Methyl-3'-(Trifluoromethyl)Propionanilide",
    ],
  },
  {
    primary_id: "D0V9BP",
    names: ["M-410"],
  },
  {
    primary_id: "D0V9BU",
    names: [
      "ABT-492",
      "Delafloxacin",
      "189279-58-1",
      "WQ-3034",
      "Abt 492",
      "1-(6-AMINO-3,5-DIFLUOROPYRIDIN-2-YL)-8-CHLORO-6-FLUORO-7-(3-HYDROXYAZETIDIN-1-YL)-4-OXO-1,4-DIHYDROQUINOLINE-3-CARBOXYLIC ACID",
      "ABT492",
      "RX-3341",
      "UNII-6315412YVF",
      "6315412YVF",
      "1-(6-Amino-3,5-Difluoro-2-Pyridyl)-8-Chloro-6-Fluoro-7-(3-Hydroxyazetidin-1-Yl)-4-Oxo-Quinoline-3-Carboxylic Acid",
      "1-(6-Amino-3,5-Difluoropyridin-2-Yl)-8-Chloro-6-Fluoro-7-(3-Hydroxyazetidin-1-Yl)-4-Oxoquinoline-3-Carboxylic Acid",
      "Delafloxacin [USAN:INN]",
      "Delafloxacinum",
      "Delafloxacin (USAN)",
    ],
  },
  {
    primary_id: "D0V9BX",
    names: ["TD-6989"],
  },
  {
    primary_id: "D0V9CG",
    names: ["KKHT-10318"],
  },
  {
    primary_id: "D0V9DQ",
    names: [
      "[3-(5-Phenyl-Oxazol-2-Ylamino)-Phenyl]-Methanol",
      "3-(5-Phenyl-Oxazol-2-Ylamino)Phenylmethanol",
      "681002-56-2",
      "[3-[(5-Phenyl-1,3-Oxazol-2-Yl)Amino]phenyl]methanol",
      "{3-[(5-Phenyl-1,3-Oxazol-2-Yl)Amino]phenyl}methanol",
      "2-Anilino-5-Aryloxazole 12",
      "AC1NS7RB",
      "CHEMBL194113",
      "BDBM5851",
      "SCHEMBL5185668",
      "ZINC13611262",
      "KB-305372",
      "N-(3-Hydroxymethylphenyl)-5-Phenyl-1,3-Oxazol-2-Amine Hydrochloride",
    ],
  },
  {
    primary_id: "D0V9DY",
    names: ["H-Tyr-D-Ala-Gly-Phe-NH-NH-D-Trp-Nle-Asp-Phe-H", "CHEMBL386212"],
  },
  {
    primary_id: "D0V9DZ",
    names: ["Fluprednisolone", "Alphadrol"],
  },
  {
    primary_id: "D0V9EE",
    names: ["2-Amino-6-Furan-2-Yl-4-Phenyl-Nicotinonitrile", "CHEMBL468370", "SCHEMBL6254165"],
  },
  {
    primary_id: "D0V9EH",
    names: [
      "PT-317",
      "149486-80-6",
      "Thiourea, N-(4-(1-Methylethyl)-2-Thiazolyl)-N'-(2-Phenylethyl)-",
      "PETT Analog 40",
      "Thiourea, N-[4-(1-Methylethyl)-2-Thiazolyl]-N'-(2-Phenylethyl)-",
      "PETT I-Pr Deriv.",
      "AC1MHDKR",
      "CHEMBL254839",
      "BDBM1873",
      "DTXSID90164307",
      "ZINC13744897",
      "1-Phenethyl-3-(4-Isopropyl-2-Thiazolyl)Thiourea",
      "1-(4-Isopropylthiazol-2-Yl)-3-Phenethyl-Thiourea",
      "N-(2-Phenethyl)-N -(2-(4-Isopropylthiazolyl))Thiourea",
      "1-Phenethyl-3-(4-Propan-2-Yl-1,3-Thiazol-2-Yl)Thiourea",
    ],
  },
  {
    primary_id: "D0V9EN",
    names: [
      "3,4-Dihydroxycinnamic Acid",
      "Caffeic Acid",
      "331-39-5",
      "3-(3,4-Dihydroxyphenyl)Acrylic Acid",
      "501-16-6",
      "(E)-3-(3,4-Dihydroxyphenyl)Acrylic Acid",
      "3,4-Dihydroxybenzeneacrylic Acid",
      "(2E)-3-(3,4-Dihydroxyphenyl)Prop-2-Enoic Acid",
      "Trans-Caffeic Acid",
      "Trans-Caffeate",
      "3,4-Dihydroxy-Trans-Cinnamate",
      "2-Propenoic Acid, 3-(3,4-Dihydroxyphenyl)-",
      "Cinnamic Acid, 3,4-Dihydroxy-",
      "3-(3,4-Dihydroxyphenyl)Propenoic Acid",
      "UNII-U2S3A33KVM",
      "3-(3,4-Dihydroxyphenyl)-2-Propenoic Acid",
      "4-(2-Carboxyethenyl)-1,2-Dihydroxybenzene",
    ],
  },
  {
    primary_id: "D0V9EX",
    names: ["Mirvetuximab Soratansine"],
  },
  {
    primary_id: "D0V9FU",
    names: ["WST11"],
  },
  {
    primary_id: "D0V9FZ",
    names: ["L-2-Amino-4-(Guanidinooxy)Butyric Acid"],
  },
  {
    primary_id: "D0V9GL",
    names: ["Imidazopyridine Acetamide Analog 5"],
  },
  {
    primary_id: "D0V9GS",
    names: [
      "4-Amino-3-(4-(Hexyloxy)Phenyl)-4-Oxobutanoic Acid",
      "CHEMBL448246",
      "3-(4-Hexyloxy-Phenyl)-Succinamic Acid",
      "AC1LCKXS",
      "SMR000008837",
      "MLS000073581",
      "Cid_651854",
      "HMS2162F22",
      "HMS3313H20",
      "BDBM50247490",
      "AKOS000505159",
      "AKOS030483668",
      "BAS 00404306",
      "4-Amino-3-(4-Hexoxyphenyl)-4-Oxobutanoic Acid",
      "SR-01000514729",
    ],
  },
  {
    primary_id: "D0V9HH",
    names: ["Taprizosin"],
  },
  {
    primary_id: "D0V9HI",
    names: ["Irindalone"],
  },
  {
    primary_id: "D0V9HL",
    names: ["BMS-241027"],
  },
  {
    primary_id: "D0V9IE",
    names: ["ZD2079"],
  },
  {
    primary_id: "D0V9JR",
    names: ["Viloxazine"],
  },
  {
    primary_id: "D0V9JS",
    names: [
      "3-(1,1-Dimethyl-2-Phenyl-Ethyl)-Pyridine",
      "CHEMBL440299",
      "87372-78-9",
      "CTK3C4529",
      "DTXSID20658229",
      "ZINC29556563",
      "BDBM50028169",
      "3-(2-Methyl-1-Phenylpropan-2-Yl)Pyridine",
      "Pyridine, 3-(1,1-Dimethyl-2-Phenylethyl)-",
    ],
  },
  {
    primary_id: "D0V9KF",
    names: ["8-(3,3,3-Trifluoropropoxy)Quinolin-2-Amine", "CHEMBL187165", "SCHEMBL5884488"],
  },
  {
    primary_id: "D0V9LF",
    names: [
      "Anti-CD28",
      "Anti-CD28 (Immune Disease)",
      "Anti-CD28 Antibody (Immune Disorder), BMS",
      "Anti-CD28 (Immune Disease), Bristol-Myers Squibb",
    ],
  },
  {
    primary_id: "D0V9LJ",
    names: ["Flufirvitide-3 Nasal Spray"],
  },
  {
    primary_id: "D0V9LR",
    names: ["PMID22420767C42"],
  },
  {
    primary_id: "D0V9LY",
    names: ["Bertilimumab", "CAT-213", "ICo-008"],
  },
  {
    primary_id: "D0V9ME",
    names: ["VT-111"],
  },
  {
    primary_id: "D0V9MG",
    names: ["KY-382"],
  },
  {
    primary_id: "D0V9MH",
    names: ["ISIS 32015"],
  },
  {
    primary_id: "D0V9MI",
    names: ["BAY-60-7550"],
  },
  {
    primary_id: "D0V9MO",
    names: ["Ono 3"],
  },
  {
    primary_id: "D0V9MR",
    names: [
      "XL888",
      "5-((R)-Sec-Butylamino)-N1-(8-(5-(Cyclopropanecarbonyl)Pyridin-2-Yl)-8-Azabicyclo[3.2.1]octan-3-Yl)-2-Methylterephthalamide",
      "SCHEMBL2425587",
      "CHEMBL2204502",
      "MolPort-035-395-767",
      "EX-A1668",
      "S7122",
      "AKOS027253610",
      "NCGC00386261-01",
      "HY-13313",
      "KB-81507",
      "SC-95577",
      "CS-0003194",
      "SW219489-1",
    ],
  },
  {
    primary_id: "D0V9MT",
    names: ["PU-AD"],
  },
  {
    primary_id: "D0V9MV",
    names: ["IDDBCP226234"],
  },
  {
    primary_id: "D0V9NB",
    names: [
      "Non-Hodgkin Lymphoma Vaccine",
      "B-Cell Lymphoma Vaccine, Large Scale Biology",
      "Non-Hodgkin Lymphoma Vaccine, Large Scale Biology",
    ],
  },
  {
    primary_id: "D0V9ND",
    names: ["MCP-1"],
  },
  {
    primary_id: "D0V9NE",
    names: ["Tanaproget"],
  },
  {
    primary_id: "D0V9NW",
    names: ["Second Generation Neuregulin Mutants"],
  },
  {
    primary_id: "D0V9OL",
    names: ["534U87", "Anticonvulsant, Glaxo Wellcome", "BW-534U87"],
  },
  {
    primary_id: "D0V9OO",
    names: [
      "(R,S)-PHPNECA",
      "(R,S)-2-Phenylhydroxypropynyl-NECA",
      "2-(3-Hydroxy-3-Phenyl)Propyn-1-Yladenosine-5'-N-Ethyluronamide",
    ],
  },
  {
    primary_id: "D0V9OZ",
    names: ["Sulphasalazine"],
  },
  {
    primary_id: "D0V9PB",
    names: ["Gilotrifafatinib"],
  },
  {
    primary_id: "D0V9QR",
    names: [
      "Apricitabine",
      "160707-69-7",
      "AVX754",
      "AVX 754",
      "UNII-K1YX059ML1",
      "BCH 10618",
      "(-)-DOTC",
      "(-)-BCH 10652",
      "K1YX059ML1",
      "AVX-754",
      "SPD-754",
      "BCH-10618",
      "4-Amino-1-[(2R,4R)-2-(Hydroxymethyl)-1,3-Oxathiolan-4-Yl]pyrimidin-2-One",
      "4-Amino-1-[(2R,4R)-2-(Hydroxymethyl)-1,3-Oxathiolan-4-Yl]-2(1H)-Pyrimidinone",
      "BCH-10652",
      "2(1H)-Pyrimidinone, 4-Amino-1-((2R,4R)-2-(Hydroxymethyl)-1,3-Oxathiolan-4-Yl)-",
      "2(1H)-Pyrimidinone, 4-Amino-1-(2-(Hydroxymethyl)-1,3-Oxathiolan-4-Yl)-, (2R-Cis)-",
      "Apricitabine [INN]",
      "SPD754",
      "BCH-10619",
      "SPD 754",
      "AC1L9S1I",
      "(+/-)DOTC",
    ],
  },
  {
    primary_id: "D0V9QT",
    names: ["SC-51322", "146032-79-3"],
  },
  {
    primary_id: "D0V9RB",
    names: ["TriAb", "Anti-11D10, Trilex"],
  },
  {
    primary_id: "D0V9RM",
    names: ["PMID25666693-Compound-89"],
  },
  {
    primary_id: "D0V9RU",
    names: ["ENIPORIDE"],
  },
  {
    primary_id: "D0V9SV",
    names: ["POLYGALATENOSIDE B", "CHEMBL462805", "BDBM50241785"],
  },
  {
    primary_id: "D0V9SW",
    names: [
      "4-Cyclohexyliden(4-Hydroxyphenyl)Methylphenol",
      "4,4'-(Cyclohexylidenemethylene)Diphenol",
      "5189-40-2",
      "Cyclofenil Diphenol",
      "4,4'-Cyclohexylidenemethylenediphenol",
      "UNII-00W4083OML",
      "EINECS 225-972-5",
      "BRN 2055864",
      "F 6060",
      "4-[cyclohexylidene(4-Hydroxyphenyl)Methyl]phenol",
      "4-(Cyclohexylidene(4-Hydroxyphenyl)Methyl)Phenol",
      "00W4083OML",
      "4,4-Cyclohexylidenemethylenediphenol",
      "Phenol, 4-(Cyclohexylidene(4-Hydroxyphenyl)Methyl)-",
      "Alpha-Cyclohexylidene-Alpha-(P-Hydroxyphenyl)-P-Cresol",
      "P-CRESOL, Alpha-CYCLOHEXYLIDENE-Alpha-(P-HYDROXYPHENYL)-",
      "AC1L2HYB",
    ],
  },
  {
    primary_id: "D0V9TE",
    names: ["GPI-2138", "Dopamine Uptake Modulators, Guilford", "GPI-2227", "GPI-2305"],
  },
  {
    primary_id: "D0V9TN",
    names: ["Aromatic Ammonia Spirits"],
  },
  {
    primary_id: "D0V9TZ",
    names: ["MRS2802"],
  },
  {
    primary_id: "D0V9UA",
    names: ["OligoG CF-5/20"],
  },
  {
    primary_id: "D0V9UR",
    names: ["IL-28B Immunoadjuvant"],
  },
  {
    primary_id: "D0V9US",
    names: ["6-Phenylsulfanylmethyl-Pteridine-2,4-Diamine"],
  },
  {
    primary_id: "D0V9UY",
    names: ["Technetium Tc-99m Sulfur Colloid Kit"],
  },
  {
    primary_id: "D0V9VG",
    names: ["BMS-927711"],
  },
  {
    primary_id: "D0V9VJ",
    names: ["N-Methyl-Pyridinium-2-Aldoxime"],
  },
  {
    primary_id: "D0V9WA",
    names: ["BBI-608"],
  },
  {
    primary_id: "D0V9WE",
    names: ["TS23"],
  },
  {
    primary_id: "D0V9WF",
    names: [
      "Lestaurtinib",
      "A 1544750",
      "CEP 701",
      "KT 5555",
      "KT5555",
      "SP 924",
      "CEP-701",
      "KT-5555",
      "SPM-924",
      "Lestaurtinib (USAN/INN)",
    ],
  },
  {
    primary_id: "D0V9WX",
    names: ["KarXT"],
  },
  {
    primary_id: "D0V9XE",
    names: ["SB236057"],
  },
  {
    primary_id: "D0V9XF",
    names: ["PICROTIN"],
  },
  {
    primary_id: "D0V9YR",
    names: [
      "Sulfamethazine",
      "Azolmetazin",
      "Cremomethazine",
      "Diazil",
      "Dimezathine",
      "Intradine",
      "Kelametazine",
      "Mermeth",
      "Neasina",
      "Neazina",
      "Pirmazin",
      "Primazin",
      "Solfadimidina",
      "Spanbolet",
      "Sulfadimerazine",
      "Sulfadimesin",
      "Sulfadimesine",
      "Sulfadimethyldiazine",
      "Sulfadimethylpyrimidine",
      "Sulfadimezin",
      "Sulfadimezine",
      "Sulfadimezinum",
      "Sulfadimidin",
      "Sulfadimidina",
      "Sulfadimidine",
      "Sulfadimidinum",
      "Sulfadine",
      "Sulfametazina",
      "Sulfametazyny",
      "Sulfamethiazine",
      "Sulfamezathine",
      "Sulfodimesin",
      "Sulfodimezine",
      "Sulphadimethylpyrimidine",
      "Sulphadimidine",
      "Sulphamethasine",
      "Sulphamethazine",
      "Sulphamezathine",
      "Sulphamidine",
      "Sulphodimezine",
      "Superseptil",
      "Superseptyl",
      "Vertolan",
      "Calfspan Tablets",
      "Sa III",
      "Solfadimidina [DCIT]",
      "SulfaSURE SR Bolus",
      "Sulfadimidine Solution",
      "Sulfametazina[Italian]",
      "Sulfametazyny [Polish]",
      "Sulfamethazine Solution",
      "Sulka S Boluses",
      "BN 2409",
      "HC210279",
      "A-502",
      "Diazil (The Sulfanilamide)",
      "Dimidin-R",
      "Hava-Span",
      "Sulfa-Isodimerazine",
      "Sulfadimidina [INN-Spanish]",
      "Sulfadimidine (INN)",
      "Sulfadimidine [INN:BAN]",
      "Sulfadimidinum [INN-Latin]",
      "Sulfamethazine (USP)",
      "Sulfamezathine (TN)",
      "N-(4,6-Dimethyl-2-Pyrimidyl)Sulfanilamide",
      "N(1)-(4,6-Dimethyl-2-Pyrimidinyl)Sulfanilamide",
      "N(1)-(4,6-Dimethyl-2-Pyrimidyl)Sulfanilamide",
      "N(Sup1)-(4,6-Dimethyl-2-Pyrimidinyl)Sulfanilamide",
      "N(Sup1)-(4,6-Dimethyl-2-Pyrimidyl)Sulfanilamide",
      "N(Sup 1)-(4,6-Dimethyl-2-Pyrimidinyl)Sulfanilamide",
      "N(Sup 1)-(4,6-Dimethyl-2-Pyrimidyl)Sulfanilamide",
      "N(Sup1)-(2,6-Dimethylpyrimid-4-Yl)Sulfanilamide",
      "[(4-Aminophenyl)Sulfonyl](4,6-Dimethylpyrimidin-2-Yl)Amine",
      "(P-Aminobenzolsulfonyl)-2-Amino-4,6-Dimethylpyrimidin",
      "(P-Aminobenzolsulfonyl)-2-Amino-4,6-Dimethylpyrimidin [German]",
      "2-(4-Aminobenzenesulfonamido)-4,6-Dimethylpyrimidine",
      "2-(P-Aminobenzenesulfonamido)-4,6-Dimethylpyrimidine",
      "2-Sulfanilamido-4,6-Dimethylpyrimidine",
      "4,6-Dimethyl-2-Sulfanilamidopyrimidine",
      "4,6-Dimethylsulfadiazine",
      "4-Amino-N-(2,6-Dimethyl-4-Pyrimidinyl)Benzenesulfonamide",
      "4-Amino-N-(4,6-Dimethyl-2-Pyrimidinyl)Benzenesulfonamide",
      "4-Amino-N-(4,6-Dimethyl-2-Pyrimidyl)Benzenesulfonamide",
      "4-Amino-N-(4,6-Dimethyl-Pyrimidin-2-Yl)-Benzenesulfonamide",
      "4-Amino-N-[4,6-Dimethyl-2-Pyrimidinyl]-Benzenesulfonamide",
      "4-Amino-N-(4,6-Dimethylpyrimidin-2-Yl)Benzene-1-Sulfonamide",
      "4-Amino-N-(4,6-Dimethylpyrimidin-2-Yl)Benzenesulfonamide",
      "6-(4'-Aminobenzol-Sulfonamido)-2,4-Dimethylpyrimidin",
      "6-(4'-Aminobenzol-Sulfonamido)-2,4-Dimethylpyrimidin [German]",
    ],
  },
  {
    primary_id: "D0V9YW",
    names: ["GYKI-47261"],
  },
  {
    primary_id: "D0V9ZG",
    names: ["Cetraxate"],
  },
  {
    primary_id: "D0V9ZJ",
    names: ["Pyrazolo[1,5-A]pyridine Derivative 2"],
  },
  {
    primary_id: "D0V9ZL",
    names: ["FCE-28161"],
  },
  {
    primary_id: "D0V9ZR",
    names: [
      "GNI-105",
      "ED-009",
      "EP-2230",
      "HPV Vaccine (Therapeutic), GENimmune",
      "HPV Vaccine (Therapeutic), Epimmune/Genencor",
      "Human Papilloma Virus Vaccine (Therapeutic), Epimmune/Genecor",
      "HPV Vaccine (Therapeutic), Innogenetics/ IDM/Pharmexa",
      "Human Papilloma Virus Vaccine (Therapeutic), Innogenetics/ IDM/Pharmexa",
    ],
  },
  {
    primary_id: "D0VA0I",
    names: ["Vorapaxar"],
  },
  {
    primary_id: "D0VA0J",
    names: ["3,4-Dichloro-N-(3,3-Diphenyl-Allyl)-Benzamide", "CHEMBL8893", "BDBM50005468"],
  },
  {
    primary_id: "D0VA1D",
    names: ["Alkyl Mannoside Derivative 1"],
  },
  {
    primary_id: "D0VA1R",
    names: ["4-Aryl Quinol Derivative 5"],
  },
  {
    primary_id: "D0VA2G",
    names: ["Pyrazino[2,1-A]isoquinolin Derivative 3"],
  },
  {
    primary_id: "D0VA2I",
    names: ["CRx-191"],
  },
  {
    primary_id: "D0VA2O",
    names: ["4-(2,5-Dichlorobenzylthio)-2-Aminobutanoic Acid", "CHEMBL205126", "BDBM50179736"],
  },
  {
    primary_id: "D0VA3U",
    names: ["Lincosamide Antibacterials"],
  },
  {
    primary_id: "D0VA3Z",
    names: ["Anti-TNF Mab"],
  },
  {
    primary_id: "D0VA4Y",
    names: ["Phentermine+topiramate"],
  },
  {
    primary_id: "D0VA5V",
    names: ["18-Methoxycoronaridinate 2-Hydroxyethylamide"],
  },
  {
    primary_id: "D0VA7C",
    names: ["PMID21273063C1"],
  },
  {
    primary_id: "D0VA9F",
    names: ["Mulberrofuran C"],
  },
  {
    primary_id: "D0VA9Z",
    names: [
      "ND0612",
      "Carbidopa And Levodopa",
      "Duodopa",
      "Nakom",
      "Nacom",
      "Sinemet CR",
      "Dopabain",
      "Isicom",
      "Co-Careldopa",
      "Levodopa-Carbidopa (10:1)",
      "Carbidopa-Levodopa (1:10)",
      "CARBIDOPA AND LEVODOPA",
      "CHF 1512",
      "Rytary",
      "Duopa",
      "Sinemet CR4",
      "L-Tyrosine, 3-Hydroxy-, Mixt. With (S)-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methylbenzenepropanoic Acid (10:1)",
      "L-Tyrosine, 3-Hydroxy-, Mixt. With (S)-Alpha-Hydrazino-3,4-Dihydroxy-Alpha-Methylbenzenepropanoic Acid",
      "Levodopa / Carbidopa",
      "Carbidopa / Levodopa",
      "Carbidopa-Levodopa ER",
      "Carbidopa-L-Dopa Mixt.",
      "Carbidopa-Levodopa Mixt.",
      "Levodopa/Ca",
    ],
  },
  {
    primary_id: "D0VB0U",
    names: ["Chlorphenesin"],
  },
  {
    primary_id: "D0VB2K",
    names: ["SPX-601"],
  },
  {
    primary_id: "D0VB2M",
    names: ["1,5-Diphenylpyrrolidin-2-One Derivative 1"],
  },
  {
    primary_id: "D0VB2W",
    names: [
      "5-Nitroindazole",
      "5-Nitro-1H-Indazole",
      "5401-94-5",
      "1H-INDAZOLE, 5-NITRO-",
      "UNII-235Y7P37ZD",
      "CCRIS 4134",
      "NSC 5032",
      "EINECS 226-451-5",
      "BRN 0007936",
      "CHEMBL165372",
      "WSGURAYTCUVDQL-UHFFFAOYSA-N",
      "235Y7P37ZD",
      "MFCD00005693",
      "5NI",
      "2H-Indazole, 5-Nitro-",
      "5-Nitroindazol",
      "5-Nitro Indazole",
      "5-Nitro-Indazole",
      "5-Nitro-1H-Indazol",
      "5-Nitro-IH-Indazole",
      "5-Nitro-2H-Indazole",
      "PubChem15672",
      "4lm0",
      "AC1L2IGS",
      "ACMC-209lc9",
      "DSSTox_RID_83391",
      "DSSTox_CID_29272",
      "5-Nitroindazole, &gt",
      "DSSTox_GSID_49316",
      "KSC490O7N",
      "5-Nitro-1H-Indazole 99%",
      "MLS000728712",
      "5-23-06-00180 (B",
    ],
  },
  {
    primary_id: "D0VB2X",
    names: ["PMID27539678-Compound-9"],
  },
  {
    primary_id: "D0VB3P",
    names: ["PMID26924192-Compound-33"],
  },
  {
    primary_id: "D0VB3Y",
    names: ["Manganese Sulfate"],
  },
  {
    primary_id: "D0VB4T",
    names: ["Aryl Mannoside Derivative 9"],
  },
  {
    primary_id: "D0VB4X",
    names: ["VTP-43742"],
  },
  {
    primary_id: "D0VB6X",
    names: [
      "(1H-Imidazo[4,5-C]quinolin-4-Yl)-Phenyl-Amine HCl",
      "CHEMBL19533",
      "BDBM50011598",
      "ZINC13821052",
      "4-Phenylamino-1H-Imidazo[4,5-C]quinoline",
    ],
  },
  {
    primary_id: "D0VB7F",
    names: ["PMID27019002-Compound-37a"],
  },
  {
    primary_id: "D0VB8K",
    names: ["NSC-339580"],
  },
  {
    primary_id: "D0VB9J",
    names: ["ISIS 14803"],
  },
  {
    primary_id: "D0VB9M",
    names: ["Peptide Analog 1"],
  },
  {
    primary_id: "D0VC0I",
    names: ["Oxindole 94"],
  },
  {
    primary_id: "D0VC0P",
    names: ["Piperazinyl Norbenzomorphane Compound 3"],
  },
  {
    primary_id: "D0VC1K",
    names: [
      "N,N-Dimethyl(2-Phenoxyphenyl)Methanamine",
      "CHEMBL256058",
      "AC1LGTAP",
      "MolPort-002-800-902",
      "ZINC346601",
      "N,N-Dimethyl-2-Phenoxybenzylamine",
      "BDBM50371640",
      "AKOS005066321",
      "N,N-Dimethyl-1-(2-Phenoxyphenyl)Methanamine",
    ],
  },
  {
    primary_id: "D0VC2M",
    names: [
      "Adamantan-1-Yl-Pyrrolidin-1-Yl-Methanone",
      "CHEMBL392866",
      "1-Adamantyl(1-Pyrrolidinyl)Methanone",
      "22508-51-6",
      "(3r,5r,7r)-Adamantan-1-Yl(Pyrrolidin-1-Yl)Methanone",
      "1-Adamantyl(Pyrrolidin-1-Yl)Methanone",
      "AC1MCDV9",
      "CBMicro_007397",
      "Cambridge Id 5142982",
      "Oprea1_111627",
      "Oprea1_295021",
      "MLS000526196",
      "Adamantanyl Pyrrolidinyl Ketone",
      "SCHEMBL3456185",
      "MolPort-001-930-821",
      "HMS2477C05",
      "HMS1608M03",
      "ZINC4031845",
      "SMSF0003738",
      "STK091400",
      "BDBM50207788",
      "1-(Adamantane-1-Carbonyl)Pyrrolidine",
      "AKOS000612243",
      "CCG-246226",
      "CB09819",
      "MCULE-7009966643",
    ],
  },
  {
    primary_id: "D0VC3H",
    names: ["BT-V003"],
  },
  {
    primary_id: "D0VC3N",
    names: ["Vaccine, HSV-2 Infection"],
  },
  {
    primary_id: "D0VC4F",
    names: ["HG-1226"],
  },
  {
    primary_id: "D0VC8H",
    names: [
      "KI-0003",
      "Alpha-V/Beta-3 Integrin Targeted 19F MRI Agent (Cancer), Kereos",
      "19F MRI Agent (Cancer), Kereos",
    ],
  },
  {
    primary_id: "D0VC8T",
    names: ["AZ-05"],
  },
  {
    primary_id: "D0VC8Y",
    names: ["SAR428926"],
  },
  {
    primary_id: "D0VC8Z",
    names: ["Trehalose"],
  },
  {
    primary_id: "D0VC9V",
    names: ["2-Alkyloxy-3-Phenylethynyl-4a,5-Dihydropyrido[2,3-B]pyrazine Derivative 1"],
  },
  {
    primary_id: "D0VD2C",
    names: [
      "(Z)-2-Methyl-3-Tetradecyl-But-2-Enedioic Acid",
      "Chaetomellic Acid A",
      "CHEMBL103955",
      "148796-51-4",
      "AC1MI36H",
      "ZINC14276303",
      "BDBM50059863",
      "(Z)-2-Methyl-3-Tetradecylbut-2-Enedioic Acid",
      "2-Butenedioic Acid, 2-Methyl-3-Tetradecyl-, (Z)-",
    ],
  },
  {
    primary_id: "D0VD3M",
    names: [
      "15-Deoxygoiazensolide",
      "Calaxin",
      "CHEBI:3302",
      "30412-86-3",
      "C09353",
      "CHEMBL189790",
      "2-Propenoic Acid, 2-Methyl-, 2,3,3a,4,5,6,7,11a-Octahydro-6,10-Dimethyl-3-Methylene-2,7-Dioxo-6,9-Epoxycyclodeca(B)Furan-4-Yl Ester, (3aR-(3aR*,4R*,6R*,10Z,11aR*))-",
    ],
  },
  {
    primary_id: "D0VD3P",
    names: ["Amidopyrazole Derivative 5"],
  },
  {
    primary_id: "D0VD4L",
    names: ["2-[1,4]Diazepan-1-Yl-6-Nitro-Quinoline", "CHEMBL43139", "BDBM50090215", "AKOS009510173"],
  },
  {
    primary_id: "D0VD5I",
    names: ["Aminopyrimidine Derivative 2"],
  },
  {
    primary_id: "D0VD5J",
    names: ["PMID28270021-Compound-WO2015042088Example12"],
  },
  {
    primary_id: "D0VD5N",
    names: [
      "6-Chloropurine Riboside, 5'-Monophosphate",
      "6-Cl-Purine Ribotide",
      "CHEMBL1683023",
      "6-Chloropurine Riboside 5'-Phosphate",
      "6-Chloropurine Riboside 5'-Monophosphate",
      "6-Chloropurine Ribotide",
      "AC1L4ML7",
      "AC1Q3SV8",
      "6-Chloroinosine Monophosphate",
      "SCHEMBL622623",
      "6-Chloro-9-(5-O-Phosphono-",
      "A-D-Ribofuranosyl)-9h-Purine",
      "ALOBOMYIOYNCBS-KQYNXXCUSA-N",
      "ZINC13526959",
      "BDBM50222697",
      "A8843",
      "6-Chloropurine 9-Beta-D-Ribofuranosyl 5'-Monophosphate",
      "6-Chloro-9-(5-O-Phosphono-Beta-D-Ribofuranosyl)-9H-Purine",
      "[(2R,3S,4R,5R)-5-(6-Chloropurin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl Dihydrogen Pho",
    ],
  },
  {
    primary_id: "D0VD5O",
    names: [
      "2-Fluoro-5-(3-Methylthiophen-2-Yl)Pyridine",
      "CHEMBL179621",
      "837376-37-1",
      "Pyridine, 2-Fluoro-5-(3-Methyl-2-Thienyl)-",
      "SCHEMBL957977",
      "BDBM12354",
      "CTK3D1374",
      "DTXSID90456895",
      "RYGQGRAICBWYPW-UHFFFAOYSA-N",
      "Nicotine 3-Heteroaromatic Analogue 7",
      "US8609708,10",
    ],
  },
  {
    primary_id: "D0VD5X",
    names: ["KI-0001"],
  },
  {
    primary_id: "D0VD6Q",
    names: [
      "RP103",
      "Cysteamine Bitartrate",
      "UNII-QO84GZ3TST",
      "QO84GZ3TST",
      "CYSTEAMINE BITARTRATE",
      "Procysbi",
      "Procysbi (TN)",
      "EINECS 248-641-7",
      "Cysteamine Bitartrate (JAN)",
      "Beta-Mercaptoethylamine Bitartrate",
      "AKOS032954217",
      "Beta-Mercaptoethylamine Hydrogen Tartrate",
      "D10468",
      "Ethanethiol, 2-Amino-, Tartrate (1:1) (Salt)",
    ],
  },
  {
    primary_id: "D0VD7H",
    names: [
      "CL-5343",
      "5-Amino-1,3,4-Thiadiazole-2-Sulfonamide",
      "14949-00-9",
      "Tio-Urasin",
      "CL 5343",
      "UNII-F687N81LIZ",
      "NSC 22979",
      "2-Amino-1,3,4-Thiadiazole-5-Sulfonamide",
      "CHEMBL265674",
      "F687N81LIZ",
      "1,3,4-Thiadiazole-2-Sulfonamide, 5-Amino-",
      "5-Amino-TDSNH2",
      "PubChem15758",
      "Acetazolamide Impurity D",
      "AC1Q6UUX",
      "1,3,4-Thiadiazole-5-Sulfonamide, 2-Amino-",
      "SCHEMBL282413",
      "AC1L382D",
      "CTK4C6227",
      "BDBM10868",
      "DTXSID10164324",
      "MolPort-022-374-081",
      "VGMVBPQOACUDRU-UHFFFAOYSA-N",
      "NSC22979",
      "ZINC16969869",
      "NSC-22979",
      "1,3,4-Thiadiazole-2-Sulfonamide 15",
      "Aromatic/Heteroaromatic",
      "Carbonic Anhydrase Inhibitors, Universita Degli Studi Di Firenze",
      "Sulfonamide CA Inhibitors, Universita Degli Studi Di Firenze",
      "Sulfonamide CA Inhibitors, University Of Florence",
      "Carbonic Anhydrase Inhibitors (Cancer/Epilepsy/Glaucoma/Obesity)",
      "Carbonic Anhydrase Inhibitors (Cancer/Epilepsy/Glaucoma/Obesity), University Of Florence/ULS",
    ],
  },
  {
    primary_id: "D0VD7R",
    names: ["C[Nle-Pro-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL381739"],
  },
  {
    primary_id: "D0VD8B",
    names: ["NSC-625987"],
  },
  {
    primary_id: "D0VD8S",
    names: ["Oral-Lyn"],
  },
  {
    primary_id: "D0VD9X",
    names: ["AstroStem"],
  },
  {
    primary_id: "D0VE0F",
    names: [
      "(S)-1-Benzylcyclopentyl 1-Oxohexan-2-Ylcarbamate",
      "CHEMBL117658",
      "1-(PHENYLMETHYL)CYCLOPENTYL[(1S)-1-FORMYLPENTYL]CARBAMATE",
      "2auz",
      "BDBM50148292",
      "DB07593",
      "1-Benzylcyclopentyl [(1S)-1-Formylpentyl]carbamate",
      "1-Benzylcyclopentyl N-[(2S)-1-Oxohexan-2-Yl]carbamate",
      "((S)-1-Formyl-Pentyl)-Carbamic Acid 1-Benzyl-Cyclopentyl Ester",
    ],
  },
  {
    primary_id: "D0VE0Q",
    names: ["Sophoraflavanone G"],
  },
  {
    primary_id: "D0VE1W",
    names: ["SB-623", "(E)-3-[1-(2-Diethylaminoethyl)-2-Phenethylbenzimidazol-5-Yl]-N-Hydroxyprop-2-Enamide"],
  },
  {
    primary_id: "D0VE3H",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 17"],
  },
  {
    primary_id: "D0VE3U",
    names: ["JT02"],
  },
  {
    primary_id: "D0VE3Y",
    names: ["PMID25991433-Compound-A3"],
  },
  {
    primary_id: "D0VE4O",
    names: ["EVP-4473"],
  },
  {
    primary_id: "D0VE4Z",
    names: ["7-(2-Dipropylamino-Ethyl)-3H-Benzothiazol-2-One", "CHEMBL418548", "SCHEMBL10986865"],
  },
  {
    primary_id: "D0VE6A",
    names: ["4-(Isopentylthio)-2-(Trifluoromethyl)Benzonitrile", "CHEMBL503687"],
  },
  {
    primary_id: "D0VE6X",
    names: ["R1549 (Formerly Pemtumomab)"],
  },
  {
    primary_id: "D0VE7B",
    names: ["DSP-2230"],
  },
  {
    primary_id: "D0VE8R",
    names: ["Fused Aryl Carbocycle Derivative 1"],
  },
  {
    primary_id: "D0VE9F",
    names: ["SBP-101"],
  },
  {
    primary_id: "D0VE9G",
    names: ["IBC 293"],
  },
  {
    primary_id: "D0VF0M",
    names: ["PMID28394193-Compound-19"],
  },
  {
    primary_id: "D0VF3C",
    names: ["NPC-01"],
  },
  {
    primary_id: "D0VF4H",
    names: [
      "Hydroxocobalamin",
      "AlphaRedisol",
      "Axion",
      "Axlon",
      "Cobalex",
      "Codroxomin",
      "Cyanokit",
      "Depogamma",
      "Docclan",
      "Docelan",
      "Docelvita",
      "Docevita",
      "Duradoce",
      "Hidroxocobalamina",
      "Hydrobamine",
      "Hydrocobalamin",
      "Hydrogrisevit",
      "Hydrovit",
      "Hydroxocobalaminacetat",
      "Hydroxocobalamine",
      "Hydroxocobalaminum",
      "Hydroxocobemine",
      "Hydroxomin",
      "Hydroxycobalamin",
      "Hydroxycobalamine",
      "Hyxobamine",
      "Idrogrisevit",
      "Idrossocobalamina",
      "Oxobemin",
      "Vibeden",
      "Vitadurin",
      "Alpha Cobione",
      "Ciplamin H",
      "Hydro Cobex",
      "Hydroxocobalamin Acetate",
      "Hydroxocobalamin Monohydrochloride",
      "Hydroxy Cobal",
      "Idrossocobalamina [DCIT]",
      "Primabalt RP",
      "Hydroxocobalamin Vitamin B12",
      "Hydroxy Vitamin B12",
      "Ohb12",
      "Vitamin B12a",
      "Vitamin B12b",
      "Acti-B12",
      "AlphaRedisol (TN)",
      "Benzimidazolyl Ribofuranosyl Phosphate Deriv.",
      "Cyanokit (TN)",
      "Ducobee-Hy",
      "Duralta-12",
      "Hidroxocobalamina [INN-Spanish]",
      "Hydroxocobalamine [INN-French]",
      "Hydroxocobalaminum [INN-Latin]",
      "Hydroxycob(Lll)Alamin",
      "Lyovit-H",
      "Neo-Betalin 12",
      "Neo-Cytamen",
      "Neo-Macrabin",
      "Neo-Rojamin",
      "OH-Cbl",
      "OH-Duphar",
      "Oxolamine (Arcum)",
      "Redisol-H",
      "Sytobex-H",
      "Vitamin B(Sub 12a)",
      "Vitamin B-12b",
      "Hydroxocobalamin (JAN/USP/INN)",
      "Hydroxocobalamin [USAN:INN:BAN:JAN]",
      "Alpha-(5,6-Dimethylbenzimidazolyl)Hydroxocobamide",
      "Hydroxocobalamin(Alkaline Soln.), OH-Replaces CN-In Cyanocobalamin)",
      "Coalpha-[alpha-(5,6-Dimethylbenzimidazolyl)]-Cobeta-Hydroxocobamide",
      "Cobinamide Hydroxide Phosphate 3'-Ester With 5,6-Dimethyl-1-Alpha-D-Ribofuranosylbenzimidazole Inner Salt",
      "Cobinamide Dihydroxide Dihydrogen Phosphate (Ester), Mono(Inner Salt), 3'-Ester With 5,6-Dimethyl-1-Alpha-D-Ribofuranosylbenzimidazole",
      "Cobinamide, Dihydroxide, Dihydrogen Phosphate (Ester), Mono (Inner Salt), 3'-Ester With 5,6-Dimethyl-1-Alpha-D-Ribofuranosylbenzimidazole",
      "Cobinamide, Hydroxide, Dihydrogen Phosphate (Ester), Inner Salt, 3'-Ester With 5,6-Dimethyl-1-Alpha-D-Ribofuranosyl-1H-Benzimidazole",
      "Cobinamide, Dihydroxide, Dihydrogen Phosphate (Ester), Mono(Inner Salt), 3'-Ester With 5,6-Dimethyl-1-Alpha-D-Ribofuranosyl-1H-Benzimidazole",
      "Cobinamide, Co-Hydroxy-, Dihydrogen Phosphate (Ester), Inner Salt, 3'-Ester With (5,6-Dimethyl-1-Alpha-D-Ribofuranosyl-1H-Benzimidazole-KappaN3)",
      "Cobinamide, Co-Hydroxy-, F-(Dihydrogen Phosphate), Inner Salt, 3'-Ester With (5,6-Dimethyl-1-Alpha-D-Ribofuranosyl-1H-Benzimidazole-KappaN3)",
    ],
  },
  {
    primary_id: "D0VF4Q",
    names: ["Diaminopropyl Sulfate"],
  },
  {
    primary_id: "D0VF5B",
    names: ["PMID26651364-Compound-107"],
  },
  {
    primary_id: "D0VF5V",
    names: ["PS020990"],
  },
  {
    primary_id: "D0VF6E",
    names: ["PD173074"],
  },
  {
    primary_id: "D0VF6F",
    names: [
      "N-(3-Phenoxypyridin-4-Yl)Methanesulfonamide",
      "CHEMBL148542",
      "N-(3-Phenoxy-4-Pyridinyl)Methanesulfonamide",
      "180194-78-9",
      "CTK0E3181",
      "DTXSID30437284",
      "BDBM50120980",
      "N-(3-Phenoxy-4-Pyridyl)Methanesulfonamide",
      "N-(3-Phenoxy-Pyridin-4-Yl)-Methanesulfonamide",
      "Methanesulfonamide, N-(3-Phenoxy-4-Pyridinyl)-",
    ],
  },
  {
    primary_id: "D0VF8Z",
    names: ["SRD-326"],
  },
  {
    primary_id: "D0VG0D",
    names: [
      "BEZ235",
      "BEZ-235",
      "S14-0511",
      "NVP-BEZ-235",
      "NVP-BEZ235, BEZ235",
      "2-(4-(2,3-Dihydro-3-Methyl-2-Oxo-8-(Quinolin-3-Yl)Imidazo[4,5-C]quinolin-1-Yl)Phenyl)-2-Methylpropanenitrile",
    ],
  },
  {
    primary_id: "D0VG0F",
    names: ["Biphenyl Mannoside Derivative 23"],
  },
  {
    primary_id: "D0VG0G",
    names: ["PeproStat"],
  },
  {
    primary_id: "D0VG6L",
    names: [
      "4-Oxosebacic Acid",
      "4-OXOSEBACIC ACID",
      "Decanedioic Acid, 4-Oxo-",
      "3-Oxo-1,8-Octanedicarboxylic Acid",
      "AC1L1C60",
      "SCHEMBL1053787",
      "CTK7G1425",
      "XTQIBFVBYWIHIP-UHFFFAOYSA-N",
      "ZINC3074811",
      "AKOS024339724",
      "DB02260",
      "MCULE-8689766454",
    ],
  },
  {
    primary_id: "D0VG7I",
    names: ["MENK"],
  },
  {
    primary_id: "D0VG7Y",
    names: [
      "Elagolix Sodium",
      "Elagolix Sodium Salt",
      "UNII-5948VUI423",
      "Elagolix Sodium [USAN]",
      "5948VUI423",
      "Elagolix Sodium (USAN)",
      "SCHEMBL1641994",
      "NBI 56418NA",
      "NBI-56418-NA",
      "MolPort-003-984-965",
      "NBI-56418 NA",
      "DQYGXRQUFSRDCH-UQIIZPHYSA-M",
      "AKOS030524154",
      "VA12044",
      "KS-0000063K",
      "KB-76766",
      "HY-14369",
      "AC-29671",
      "CS-0003317",
      "D09336",
    ],
  },
  {
    primary_id: "D0VG9P",
    names: ["4-Dimethylamino-2,6-Diisopropyl-Phenol", "CHEMBL55136", "ZINC13779581"],
  },
  {
    primary_id: "D0VH0X",
    names: ["Tuberculosis Vaccine"],
  },
  {
    primary_id: "D0VH3D",
    names: ["SC-599"],
  },
  {
    primary_id: "D0VH3H",
    names: ["TM38837"],
  },
  {
    primary_id: "D0VH3M",
    names: ["Cyclo[Cys6,Cys10]N/OFQ(1-13)NH2", "CHEMBL269029"],
  },
  {
    primary_id: "D0VH4T",
    names: ["ONO-7475"],
  },
  {
    primary_id: "D0VH4V",
    names: ["Teplizumab"],
  },
  {
    primary_id: "D0VH6V",
    names: ["P53 Cell Pentrating Peptide"],
  },
  {
    primary_id: "D0VH8A",
    names: ["N-(4-(3-(Pyridin-2-Yl)Acryloyl)Phenyl)Acetamide", "AC1O17NE", "CHEMBL425789"],
  },
  {
    primary_id: "D0VH8M",
    names: ["Phthalic Acid"],
  },
  {
    primary_id: "D0VH9C",
    names: ["Synuclere"],
  },
  {
    primary_id: "D0VI0S",
    names: ["4-(Piperazin-1-Yl)-3-Tosyl-1H-Indazole", "CHEMBL1277834"],
  },
  {
    primary_id: "D0VI1H",
    names: [
      "COLCHINOL",
      "Colchinol",
      "(S)-5-Amino-6,7-Dihydro-9,10,11-Trimethoxy-5H-Dibenzo(A,c)Cyclohepten-3-Ol",
      "477-31-6",
      "CHEMBL198808",
      "AC1Q7B91",
      "AC1L4V44",
      "CTK4J0211",
      "5H-Dibenzo(A,c)Cyclohepten-3-Ol, 5-Amino-6,7-Dihydro-9,10,11-Trimethoxy-, (S)-",
    ],
  },
  {
    primary_id: "D0VI1V",
    names: ["Carbamide Derivative 20"],
  },
  {
    primary_id: "D0VI2A",
    names: ["PMID18068976C25"],
  },
  {
    primary_id: "D0VI3F",
    names: ["Imidazo[1,2-A]pyridine Derivative 1"],
  },
  {
    primary_id: "D0VI4N",
    names: [
      "JNJ-54175446",
      "CWFVVQFVGMFTBD-SECBINFHSA-N",
      "UNII-32524GLF40",
      "32524GLF40",
      "1627902-21-9",
      "SCHEMBL16036477",
      "BDBM254266",
      "AKOS032947046",
      "AS-35269",
      "J3.655.327H",
      "US9464084, 158",
      "(R)-(2-Chloro-3-(Trifluoromethyl)Phenyl)(1-(5-Fluoropyrimidin-2-Yl)-4-Methyl-6,7-Dihydro-1H-[1,2,3]triazolo[4,5-C]pyridin-5(4H)-Yl)Methanone",
      "(R)-(2-Chloro-3-(Trifluoromethyl)Phenyl)(1-(5-Fluoropyrimidin-2-Yl)-4-Methyl-1,4,6,7-Tetrahydro-5h-[1,2,3]triazolo[4,5-C]pyridin-5-Yl)Methanone",
      "Methanone, (2-Chloro-3-(Trifluoromethyl)Phenyl)((4R)-1-(5-Fluoro-2-Py",
    ],
  },
  {
    primary_id: "D0VI4S",
    names: ["Benzene Sulfonamide Derivative 4"],
  },
  {
    primary_id: "D0VI6N",
    names: ["Isosorbide-2-Benzylcarbamate-5-Tosylate", "CHEMBL523852"],
  },
  {
    primary_id: "D0VI7E",
    names: ["Aromatic Bicyclic Compound 2"],
  },
  {
    primary_id: "D0VI7P",
    names: ["PP2-Pro-Phe-N-MeHis-LVA-Ile-Amp-(O)"],
  },
  {
    primary_id: "D0VI7R",
    names: ["Selinexor Oral"],
  },
  {
    primary_id: "D0VI8T",
    names: ["4SCAR-PSMA"],
  },
  {
    primary_id: "D0VI9P",
    names: ["OPN-305"],
  },
  {
    primary_id: "D0VJ0M",
    names: [
      "Basal Lamina-Targeting Antiangiogenic Agent",
      "Angiocol",
      "Basal Lamina-Targeting Antiangiogenic Agent (Cancer)",
      "Basal Lamina-Targeting Antiangiogenic Agent (Cancer), Biostratum",
    ],
  },
  {
    primary_id: "D0VJ0N",
    names: ["PMID26666989-Compound-Figure9left02"],
  },
  {
    primary_id: "D0VJ0Q",
    names: [
      "MuStDO 5",
      "DNA Vaccine (Malaria)",
      "DNA Vaccine (Malaria), Vical",
      "Malaria Vaccine, Vical/PMC",
      "Naked DNA (Malaria), Vical",
      "Naked DNA (Malaria), Vical/PMC",
    ],
  },
  {
    primary_id: "D0VJ1R",
    names: ["Imidazo[4,5-C]pyridine Derivative 2"],
  },
  {
    primary_id: "D0VJ2S",
    names: ["JNK-IN-8"],
  },
  {
    primary_id: "D0VJ4N",
    names: ["CIGB-230"],
  },
  {
    primary_id: "D0VJ5M",
    names: ["Pyrazolopyridine Derivative 1"],
  },
  {
    primary_id: "D0VJ5N",
    names: ["SB-705498"],
  },
  {
    primary_id: "D0VJ5W",
    names: ["6,7-Dimethoxy-4-M-Tolyloxy-Quinoline", "CHEMBL98839", "SCHEMBL19373176"],
  },
  {
    primary_id: "D0VJ7A",
    names: ["ZK-114043", "105120-95-4"],
  },
  {
    primary_id: "D0VJ7G",
    names: ["TECHNETIUM-99M-MRP20"],
  },
  {
    primary_id: "D0VJ9X",
    names: ["AXS-06"],
  },
  {
    primary_id: "D0VK1L",
    names: ["Metal Complex Derivative 2"],
  },
  {
    primary_id: "D0VK6S",
    names: ["PMID28048944-Compound-6"],
  },
  {
    primary_id: "D0VK7M",
    names: ["AZD5582", "AZD 5582", "AZD-5582"],
  },
  {
    primary_id: "D0VK9H",
    names: ["Lantibiotic-Based Proteins"],
  },
  {
    primary_id: "D0VK9O",
    names: [
      "Sofpironium Bromide",
      "BBI-4000",
      "1628106-94-4",
      "Sofpironium Bromide [USAN]",
      "CHEMBL3707223",
      "Sofpironium Bromide (JAN/USAN/INN)",
      "D10989",
      "Pyrrolidinium, 3-(((2R)-2-Cyclopentyl-2-Hydroxy-2-Phenylacetyl)Oxy)-1-(2-Ethoxy-2-Oxoethyl)-1-Methyl-, Bromide (1:1), (3R)-",
    ],
  },
  {
    primary_id: "D0VK9Y",
    names: [
      "Apamin",
      "08APA001_SMARTOX",
      "GTPL2311",
      "08APA001",
      "Apamin, Synthetic, &gt",
      "Apamin, From Bee Venom, &gt",
    ],
  },
  {
    primary_id: "D0VL3L",
    names: ["DFD-03"],
  },
  {
    primary_id: "D0VL3R",
    names: ["3-(5,6,7,8-Tetrahydronaphthalen-2-Yl)Pyridine", "CHEMBL461457", "SCHEMBL8081400", "ZINC40863212"],
  },
  {
    primary_id: "D0VL4M",
    names: ["AMG0103"],
  },
  {
    primary_id: "D0VL4V",
    names: ["SR-123781A"],
  },
  {
    primary_id: "D0VL5E",
    names: ["Aminoazetidine Derivative 3"],
  },
  {
    primary_id: "D0VL8Q",
    names: ["Tiropramide"],
  },
  {
    primary_id: "D0VM0N",
    names: [
      "AcAsp-D-Glu-Leu-Glu-Cha-Cys",
      "CHEMBL60967",
      "AC1LAOO4",
      "BDBM50096410",
      "AcAsp-D-Glu-Leu-Glu-.beta.-Cyclohexylalanine-Cys",
      "Ac-L-Asp-D-Glu-L-Leu-L-Glu-3-Cyclohexyl-L-Ala-L-Cys-OH",
      "(4S)-4-[[(2S)-2-[[(2R)-2-[[(2S)-2-Acetamido-4-Hydroxy-4-Oxobutanoyl]amino]-5-Hydroxy-5-Oxopentanoyl]amino]-4-Methylpentanoyl]amino]-5-[[(2S)-3-Cyclohexyl-1-[[(2R)-1-Hydroxy-1-Oxo-3-Sulfanylpropan-2-Yl]amino]-1-Oxopropan-2-Yl]amino]-5-Oxopentanoic Acid",
    ],
  },
  {
    primary_id: "D0VM0T",
    names: ["NVP-PDF386 (VRC4887)"],
  },
  {
    primary_id: "D0VM2L",
    names: [
      "Zoledronate",
      "Aclasta",
      "Reclast",
      "ZOL",
      "Zometa",
      "Novartis Brand Of Zoledronic Acid",
      "Zoledronic Acid",
      "Zometa Concentrate",
      "Bisphosphonate 3",
      "CGP 42446",
      "CGP 42446A",
      "Aclasta (TN)",
      "CGP 42'446",
      "CGP-42446",
      "KS-1132",
      "Reclast (TN)",
      "Zoledronic Acid (INN)",
      "Zoledronic Acid [USAN:INN]",
      "Zomera (TN)",
      "Zometa (Novartis)",
      "Zometa (TN)",
      "CGP-42'446",
      "Zometa, Zomera, Aclasta And Reclast, Zoledronic Acid",
      "[1-Hydroxy-2-(1H-Imidazol-1-Yl)Ethane-1,1-Diyl]bis(Phosphonic Acid)",
      "(1-Hydroxy-2-Imidazol-1-Ylethylidene)Diphosphonic Acid",
      "(1-Hydroxy-2-(1H-Imidazol-1-Yl)Ethylidene)Bisphosphonic Acid",
      "(1-Hydroxy-2-Imidazol-1-Yl-1-Phosphonoethyl)Phosphonic Acid",
      "(1-Hydroxy-2-Imidazol-1-Yl-Phosphonoethyl)Phosphonic Acid Monohydrate",
      "2-(Imidazol-1-Yl)-1-Hydroxyethane-1,1-Diphosphonic Acid",
      "2-(Imidazol-1-Yl)-1-Hydroxyethylidene-1,1-Bisphosphonic Acid",
    ],
  },
  {
    primary_id: "D0VM5U",
    names: ["KJM429"],
  },
  {
    primary_id: "D0VM5Z",
    names: ["Ethynyl Compound 2"],
  },
  {
    primary_id: "D0VM8B",
    names: ["NTP-2014"],
  },
  {
    primary_id: "D0VM8G",
    names: ["PMID29130358-Compound-Figure15(7)"],
  },
  {
    primary_id: "D0VM8K",
    names: ["Sodium Ferric Gluconate Complex"],
  },
  {
    primary_id: "D0VN0H",
    names: ["AcGlu-Asp-Val-Val-Leu-Cys-Iqc-Nle-Thr-TyrNH2", "CHEMBL386885"],
  },
  {
    primary_id: "D0VN2N",
    names: ["Recombinant Opticin", "Angiogenesis Inhibitor (Cancer, Ocular Disease), University Of Manchester"],
  },
  {
    primary_id: "D0VN2R",
    names: [
      "6-(4-Hydroxy-Phenyl)-1-Phenyl-Naphthalen-2-Ol",
      "CHEMBL192102",
      "SCHEMBL3927262",
      "INNGVLJQICTRAF-UHFFFAOYSA-N",
      "BDBM50168362",
    ],
  },
  {
    primary_id: "D0VN2U",
    names: ["Amidine Compound 1"],
  },
  {
    primary_id: "D0VN2Y",
    names: ["BL-1825", "Anti-Parkinsons (Aerosol), Bioglan"],
  },
  {
    primary_id: "D0VN3C",
    names: [
      "H2S-Releasing L-Dopa",
      "ACS-83",
      "ACS-84",
      "ACS-85",
      "ACS-86",
      "H2S-Releasing L-Dopa (Parkinson's Disease)",
      "H2S-Releasing Levodopa Derivative (Parkinson's), CTG Pharma",
      "H2S-Releasing L-Dopa (Parkinson's Disease), CTG Pharma",
    ],
  },
  {
    primary_id: "D0VN3O",
    names: ["Lmprime P66"],
  },
  {
    primary_id: "D0VN4K",
    names: [
      "PK 11195",
      "1-(2-Chlorophenyl)-N-Methyl-N-(1-Methylpropyl)-3-Isoquinolinecarboxamide",
      "85532-75-8",
      "PK-11195",
      "PK11195",
      "RP 52028",
      "BRN 4264456",
      "CHEBI:73290",
      "N-Sec-Butyl-1-(2-Chlorophenyl)-N-Methylisoquinoline-3-Carboxamide",
      "N-Butan-2-Yl-1-(2-Chlorophenyl)-N-Methylisoquinoline-3-Carboxamide",
      "N-(Butan-2-Yl)-1-(2-Chlorophenyl)-N-Methylisoquinoline-3-Carboxamide",
      "3-Isoquinolinecarboxamide, 1-(2-Chlorophenyl)-N-Methyl-N-(1-Methylpropyl)-",
      "SMR000058427",
      "SR-01000076240",
      "Biomol-NT_000287",
      "AC1L1B9T",
      "DSSTox_RID_79625",
      "Interferon Alfa (IFN-Alpha)",
    ],
  },
  {
    primary_id: "D0VN4W",
    names: ["FHT-1206"],
  },
  {
    primary_id: "D0VN5D",
    names: ["PMID17929793C11c"],
  },
  {
    primary_id: "D0VN6O",
    names: ["N-65 Lacritin"],
  },
  {
    primary_id: "D0VN7G",
    names: ["Hexahydro-Pyrrolizin-(3E)-Ylideneamine"],
  },
  {
    primary_id: "D0VN8V",
    names: ["4-[2-(4-Benzylphenoxy)Ethyl]pyridine", "CHEMBL578131"],
  },
  {
    primary_id: "D0VN9U",
    names: ["Phenyl 4-(Decyloxy)Phenylcarbamate"],
  },
  {
    primary_id: "D0VO0A",
    names: ["INV-21"],
  },
  {
    primary_id: "D0VO0T",
    names: [
      "Delanzomib",
      "((R)-1-((2S,3R)-3-Hydroxy-2-(6-Phenylpicolinamido)Butanamido)-3-Methylbutan-2-Yl)Boronic Acid",
      "S1157",
      "ZINC202536926",
      "SB16695",
      "SW219161-1",
      "Boronic Acid, B-[(1r)-1-[[(2s,3r)-3-Hydroxy-1-Oxo-2-[[(6-Phenyl-2-Pyridinyl)Carbonyl]amino]butyl]amino]-3-Methylbutyl]-",
    ],
  },
  {
    primary_id: "D0VO2O",
    names: ["Multiple Sclerosis Therapeutic"],
  },
  {
    primary_id: "D0VO3H",
    names: ["CG7060"],
  },
  {
    primary_id: "D0VO5S",
    names: ["LY-2584702"],
  },
  {
    primary_id: "D0VO5V",
    names: ["NNZ-4945"],
  },
  {
    primary_id: "D0VO6J",
    names: ["FAD-104"],
  },
  {
    primary_id: "D0VO7C",
    names: [
      "HTyr-Gly-Gly-Phe-Leu-Arg-Arg-Lle-Arg-Pro-LysNH2",
      "CHEMBL216640",
      "Dynorphin A (1-11) Amide",
      "Dynorphin A(1-11) Amide",
      "79985-48-1",
      "Dyn A(1-11)-NH2",
      "Linear Dyn A-(1-11)NH2",
      "BDBM50010704",
      "BDBM50033143",
      "H-Tyr-Gly-Gly-Phe-Leu-Arg-Arg-Ile-Arg-Pro-Lys-NH2",
      "Tyr-Gly-Gly-Phe-Leu-Arg-Arg-Ile-Arg-Pro-D-Lys-NH2",
      "TYR-GLY-GLY-PHE-LEU-ARG-ARG-ILE-ARG-PRO-LYS-LEU-LYS-TRP-ASP-ASN-GLN-NH2",
    ],
  },
  {
    primary_id: "D0VO7K",
    names: ["Capzola"],
  },
  {
    primary_id: "D0VO7R",
    names: [
      "AjvW2",
      "2,6-Piperazinedione, 4,4'-(1,2-Dimethyl-1,2-Ethanediyl)Bis-, Rel-",
      "Icrf 193",
      "ICRF-193",
      "21416-88-6",
      "BRN 3981691",
      "2,6-Piperazinedione, 4,4'-(1,2-Dimethylethylene)Di-, Meso-",
      "2,6-Piperazinedione, 4,4'-(1,2-Dimethyl-1,2-Ethanediyl)Bis-, (R*,S*)-",
      "AC1L3O6B",
      "Meso-2,3-Bis(3,5-Dioxopiperazine-1-Yl)Butane",
      "KBioSS_000487",
      "BSPBio_001147",
      "KBioGR_000487",
      "SCHEMBL438498",
      "CHEMBL264684",
      "KBio3_000893",
      "KBio3_000894",
      "KBio2_003055",
      "CHEBI:93771",
      "KBio2_000487",
      "KBio2_005623",
      "HMS1990I09",
      "HMS1792I09",
      "Bio2_000884",
      "HMS3403I09",
      "Bio2_000404",
      "HMS1362I09",
      "ICRF",
    ],
  },
  {
    primary_id: "D0VO9O",
    names: [
      "Talizumab",
      "AL-901",
      "CGP-51901",
      "HU-901",
      "TNX-901",
      "Anti-IgE MAbs, Tanox",
      "Anti-Allergy MAbs, Tanox",
    ],
  },
  {
    primary_id: "D0VP1V",
    names: [
      "Aclidinium",
      "UNII-K17VY42F6C",
      "K17VY42F6C",
      "CHEBI:65346",
      "727649-81-2",
      "CHEMBL551466",
      "(3R)-3-[2-Hydroxy(Di-2-Thienyl)Acetoxy]-1-(3-Phenoxypropyl)-1-Azoniabicyclo[2.2.2]octane",
      "(3R)-3-{[Hydroxy(Di-2-Thienyl)Acetyl]oxy}-1-(3-Phenoxypropyl)-1-Azoniabicyclo[2.2.2]octane Bromide",
      "SCHEMBL72141",
      "GTPL7449",
      "SCHEMBL15096073",
      "CHEMBL1194325",
      "DTXSID00223070",
      "ZINC30691727",
      "BDBM50296331",
      "DB08897",
      "AJ-84146",
      "AB01565828_02",
      "1-(3-Phenoxypropyl)-3alpha-[hydroxybis(2-Thienyl)Acetyloxy]-1-Azoniabicyclo[2.2.2]octane",
    ],
  },
  {
    primary_id: "D0VP2T",
    names: ["KU-2285", "2,2-Difluoro-N-(2-Hydroxyethyl)-3-(2-Nitro-1-Imidazolyl)Propionamide"],
  },
  {
    primary_id: "D0VP5X",
    names: ["Research Programme: Peroxisome Proliferator-Activated Receptor Gamma Agonist, Omeros"],
  },
  {
    primary_id: "D0VP6M",
    names: [
      "1-(3,3-Dimethyl-2-Oxo-Butyl)-1H-Indole-2,3-Dione",
      "1-(3,3-Dimethyl-2-Oxobutyl)-1H-Indole-2,3-Dione",
      "1-(3,3-Dimethyl-2-Oxobutyl)Indole-2,3-Dione",
      "214417-82-0",
      "CHEMBL82686",
      "AC1MU297",
      "CTK7F3764",
      "MolPort-003-013-041",
      "ZINC3037941",
      "ALBB-006420",
      "STK506481",
      "AKOS001570268",
      "MCULE-7778783398",
      "CCG-198354",
      "TR-059060",
      "SR-01000550574",
      "1-(2-Oxo-3,3-Dimethylbutyl)-2,3-Indolinedione",
    ],
  },
  {
    primary_id: "D0VP7D",
    names: ["HR-004-1"],
  },
  {
    primary_id: "D0VP8P",
    names: ["Vaccine, Streptococcus Pneumoniae"],
  },
  {
    primary_id: "D0VP9E",
    names: ["Cyrtominetin"],
  },
  {
    primary_id: "D0VQ2N",
    names: ["PMID29757691-Compound-8c"],
  },
  {
    primary_id: "D0VQ3O",
    names: ["1-(Oxazol-2-Yl)-3-(4-Phenoxyphenyl)Propan-1-One", "CHEMBL441735"],
  },
  {
    primary_id: "D0VQ4G",
    names: [
      "N'-Acridin-9-Yl-N,N-Diethyl-Butane-1,4-Diamine",
      "CHEMBL61092",
      "1046-70-4",
      "SCHEMBL8288058",
      "CTK0D7916",
      "DTXSID50658232",
      "BDBM50143356",
      "1,4-Butanediamine, N'-9-Acridinyl-N,N-Diethyl-",
      "N''-Acridin-9-Yl-N,N-Diethyl-Butane-1,4-Diamine",
    ],
  },
  {
    primary_id: "D0VQ7P",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 11"],
  },
  {
    primary_id: "D0VQ7Y",
    names: ["SPK-CHM"],
  },
  {
    primary_id: "D0VQ8Q",
    names: ["Ladiratuzumab Vedotin"],
  },
  {
    primary_id: "D0VR1K",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 3"],
  },
  {
    primary_id: "D0VR1O",
    names: ["7-Azaindole Derivative 2"],
  },
  {
    primary_id: "D0VR3K",
    names: [
      "4-Amino-3-(4-Fluoro-Phenyl)-Butyric Acid",
      "(R)-4-AMINO-3-(4-FLUOROPHENYL)BUTANOIC ACID",
      "741217-33-4",
      "CHEMBL301492",
      "(3R)-4-Amino-3-(4-Fluorophenyl)Butanoic Acid",
      "AK-87533",
      "PubChem18292",
      "SCHEMBL351702",
      "CTK5D9380",
      "KS-00000QGP",
      "DTXSID00557064",
      "MolPort-023-332-505",
      "ZINC6093177",
      "ANW-65813",
      "1505AC",
      "BDBM50368295",
      "AKOS006325793",
      "LS30154",
      "CA-2040",
      "DS-2490",
      "AC-22391",
      "AJ-55750",
      "TC-154558",
      "AX8236708",
      "KB-210177",
      "ST24020736",
      "(R)-3-(4-Fluorophenyl)-4-Aminobutyric Acid",
    ],
  },
  {
    primary_id: "D0VR4A",
    names: [
      "2,7-Bis[2-(Dimethylamino)Acetamido]anthraquinone",
      "SCHEMBL4291524",
      "CHEMBL494283",
      "BQUQDCVNESTXAU-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0VR5T",
    names: ["BEPH"],
  },
  {
    primary_id: "D0VR6E",
    names: ["Pyrrolo-Pyridine Derivative 3"],
  },
  {
    primary_id: "D0VR7F",
    names: ["CNTX-6970"],
  },
  {
    primary_id: "D0VR7W",
    names: ["Gemifloxacin"],
  },
  {
    primary_id: "D0VR8J",
    names: ["ONO-AP-324"],
  },
  {
    primary_id: "D0VR8N",
    names: ["Imidazo Bicyclic Iminium Derivative 3"],
  },
  {
    primary_id: "D0VR9K",
    names: ["Angiotensin I", "Ang I"],
  },
  {
    primary_id: "D0VR9L",
    names: ["4,4'-Dihydroxyoctafluoroazobenzene"],
  },
  {
    primary_id: "D0VS0G",
    names: ["JWCAR029"],
  },
  {
    primary_id: "D0VS0Z",
    names: ["SP2456"],
  },
  {
    primary_id: "D0VS1P",
    names: ["DRL-21995"],
  },
  {
    primary_id: "D0VS1V",
    names: [
      "N-Dodecylgallate",
      "Dodecyl Gallate",
      "Lauryl Gallate",
      "1166-52-5",
      "Dodecyl 3,4,5-Trihydroxybenzoate",
      "Nipagallin LA",
      "Progallin LA",
      "Gallic Acid, Dodecyl Ester",
      "Gallic Acid, Lauryl Ester",
      "N-Dodecyl Gallate",
      "BENZOIC ACID, 3,4,5-TRIHYDROXY-, DODECYL ESTER",
      "Gallic Acid Dodecyl Ester",
      "Lauryl 3,4,5-Trihydroxybenzoate",
      "Antioxidant E 312",
      "E-312 Antioxidant",
      "E 312 Antioxidant",
      "Dodecylester Kyseliny Gallove",
      "Antioxidant E-312",
      "NSC 133463",
      "CCRIS 5568",
      "GALLIC ACID LAURYL ESTER",
      "UNII-45612DY463",
      "EINECS 214-620-6",
      "Dodecylester Kyseliny Gallove [Czech",
    ],
  },
  {
    primary_id: "D0VS1Y",
    names: ["Hydromorphone Intrathecal"],
  },
  {
    primary_id: "D0VS2M",
    names: ["ALS-886"],
  },
  {
    primary_id: "D0VS2R",
    names: ["Tovaxin"],
  },
  {
    primary_id: "D0VS2W",
    names: ["YSIL6"],
  },
  {
    primary_id: "D0VS3A",
    names: ["Hydroxyqunoline Analog 4"],
  },
  {
    primary_id: "D0VS3J",
    names: ["BM-17.0249.2NA", "BM-17.0249 (Disodium)", "2,2,13,13-Tetrachlorotetradecanedioic Acid Disodium Salt"],
  },
  {
    primary_id: "D0VS3U",
    names: ["TAK-020"],
  },
  {
    primary_id: "D0VS3W",
    names: ["KAR5585"],
  },
  {
    primary_id: "D0VS7X",
    names: ["Azetidine-Benzoxazin-3(4H)-One Derivative 3"],
  },
  {
    primary_id: "D0VS9E",
    names: ["GDC-5573"],
  },
  {
    primary_id: "D0VT0T",
    names: ["NLX-P101"],
  },
  {
    primary_id: "D0VT1S",
    names: ["TTP889"],
  },
  {
    primary_id: "D0VT2B",
    names: ["ALS-8176"],
  },
  {
    primary_id: "D0VT2C",
    names: ["FK-505"],
  },
  {
    primary_id: "D0VT6X",
    names: ["ABBV-2451"],
  },
  {
    primary_id: "D0VT8P",
    names: ["Famciclovir"],
  },
  {
    primary_id: "D0VT9O",
    names: ["AR28"],
  },
  {
    primary_id: "D0VU2M",
    names: [
      "TA-2005",
      "Carmoterol Hydrochloride",
      "CHF 4226.01",
      "8-Hydroxy-5-((1R)-1-Hydroxy-2-(N-(1R)-2-(P-Methoxyphenyl)Isopropylamino)Ethyl)Carbostyril HCl",
      "8-Hydroxy-5-(1-Hydroxy-2-(N-(2-(4-Methoxyphenyl)-1-Methylethyl)Amino)Ethyl)Carbostyril Hydrochloride",
      "8-Hydroxy-5-[(1R)-1-Hydroxy-2-[[(2R)-1-(4-Methoxyphenyl)Propan-2-Yl]amino]ethyl]-1H-Quinolin-2-One Hydrochloride",
      "8-Hydroxy-5-[(1r)-1-Hydroxy-2-{[(2r)-1-(4-Methoxyphenyl)Propan-2-Yl]amino}ethyl]quinolin-2(1h)-One Hydrochloride(1:1)",
    ],
  },
  {
    primary_id: "D0VU2X",
    names: [
      "Conivaptan",
      "Conivaptan [INN]",
      "YM 087",
      "YM087",
      "Conivaptan (INN)",
      "Vaprisol (TN)",
      "YM-087",
      "N-[4-(2-Methyl-4,5-Dihydro-3H-Imidazo[4,5-D][1]benzazepine-6-Carbonyl)Phenyl]-2-Phenylbenzamide",
      "N-{4-[(2-Methyl-4,5-Dihydroimidazo[4,5-D][1]benzazepin-6(1H)-Yl)Carbonyl]phenyl}biphenyl-2-Carboxamide",
      "4'-((4,5-Dihydro-2-Methylimidazo(4,5-D)(1)Benzazepin-6(1H)-Yl)Carbonyl)-2-Biphenylcarboxanilide",
    ],
  },
  {
    primary_id: "D0VU3I",
    names: ["Hypersulfated Disaccharide Compound 1"],
  },
  {
    primary_id: "D0VU5M",
    names: ["Imiglitazar"],
  },
  {
    primary_id: "D0VU8K",
    names: ["REIC Gene Therapy Vaccine"],
  },
  {
    primary_id: "D0VU8Q",
    names: [
      "Mebeverine",
      "Arluy",
      "Mebeverina",
      "Mebeverinum",
      "Arluy (TN)",
      "Mebeverina [INN-Spanish]",
      "Mebeverine (INN)",
      "Mebeverine [INN:BAN]",
      "Mebeverinum [INN-Latin]",
      "Mebeverine (USAN)(*hydrochloride*)",
      "Veratric Acid, 4-[ethyl(P-Me",
      "Veratric Acid, 4-((Ethyl(P-Methoxy-Alpha-Methylphenethyl))Amino)Butyl Ester",
      "Veratric Acid, 4-((Ethyl(P-Methoxy-.alpha.-Methylphenethyl))Amino)Butyl Ester",
      "Benzoic Acid, 3,4-Dimethoxy-, 4-[ethyl[2-(4-Methoxyphenyl)-1-Methylethyl]amino]butyl Ester",
      "Benzoic Acid, 3,4-Dimethoxy-, {4-[ethyl[2-(4-Methoxyphenyl)-1-Methylethyl]amino]butyl} Ester",
      "Benzoic Acid, 3,4-Dimethoxy-, 4-(Ethyl(2-(4-Methoxyphenyl)-1-Methylethyl)Amino)Butyl Ester",
      "4-(Ethyl(2-(4-Methoxyphenyl)-1-Methylethyl)Amino)Butyl 3,4-Dimethoxybenzoate;4-(Ethyl-(4-Methoxy-Alpha-Methylphenethyl)Aminobutyl) Veratrate",
      "4-(Ethyl{1-Methyl-2-[4-(Methyloxy)Phenyl]ethyl}amino)Butyl 3,4-Bis(Methyloxy)Benzoate",
      "4-[ethyl-[1-(4-Methoxyphenyl)Propan-2-Yl]amino]butyl 3,4-Dimethoxybenzoate",
    ],
  },
  {
    primary_id: "D0VV2D",
    names: [
      "B-06",
      "Anti-Angiomotin Antibodies (Angiogenesis-Related Diseases)",
      "Anti-Angiomotin Antibodies (Angiogenesis-Related Diseases), BioInvent",
      "Anti-Angiomotin Antibodies (Angiogenesis-Related Diseases), Karolinska Institute",
    ],
  },
  {
    primary_id: "D0VV3O",
    names: ["GNI-104", "Ed-004", "Neutralizing Monoclonal Antibodies (HCV), Innogenetics"],
  },
  {
    primary_id: "D0VV5X",
    names: ["EZN-3920"],
  },
  {
    primary_id: "D0VV5Z",
    names: ["Axalimogene Filolisbac", "AXAL"],
  },
  {
    primary_id: "D0VV6V",
    names: ["Reovirus"],
  },
  {
    primary_id: "D0VW0A",
    names: ["Dermacyn"],
  },
  {
    primary_id: "D0VW0I",
    names: [
      "Cyclopentenylcytosine",
      "Cyclopentenyl Cytosine",
      "90597-22-1",
      "NSC-375575",
      "CPE-C",
      "UNII-69MO0NDN8K",
      "NSC 375575",
      "NSC375575",
      "BRN 4258358",
      "69MO0NDN8K",
      "CHEMBL63647",
      "1-((1R,2S,3R)-4-Hydroxymethyl-2,3-Dihydroxy-4-Cyclopenten-1-Yl)Cytosine",
      "4-Amino-1-[(1R,4R,5S)-4,5-Dihydroxy-3-(Hydroxymethyl)Cyclopent-2-En-1-Yl]pyrimidin-2-One",
      "4-Amino-1-[(1r,4r,5s)-4,5-Dihydroxy-3-(Hydroxymethyl)Cyclopent-2-En-1-Yl]pyrimidin-2(1h)-One",
      "CPE-Cytosine",
      "2(1H)-Pyrimidinone, 4-Amino-1-((1R,4R,5S)-4,5-Dihydroxy-3-(Hydroxymethyl)-2-Cyclopenten-1-Yl)-",
    ],
  },
  {
    primary_id: "D0VW0X",
    names: ["TRPM8 Antagonists"],
  },
  {
    primary_id: "D0VW1I",
    names: ["GLS-5700"],
  },
  {
    primary_id: "D0VW2F",
    names: ["Sutentsunitinib"],
  },
  {
    primary_id: "D0VW2Z",
    names: ["Nerelimomab", "BAY-X-1351"],
  },
  {
    primary_id: "D0VW3F",
    names: ["Targeted Nanoparticles"],
  },
  {
    primary_id: "D0VW4J",
    names: ["NT0102"],
  },
  {
    primary_id: "D0VW5G",
    names: ["Hydantocidin-5'-Monophosphate"],
  },
  {
    primary_id: "D0VW5M",
    names: ["Piperidine-4-Sulphonic Acid"],
  },
  {
    primary_id: "D0VW6H",
    names: ["6-Amino-5-Chlorouracil Hydrochloride", "CHEMBL541900"],
  },
  {
    primary_id: "D0VW6Y",
    names: ["1-Adamantan-1-Yl-3-(3-Hexyloxypropyl)Urea", "CHEMBL245127", "SCHEMBL12931853"],
  },
  {
    primary_id: "D0VW7E",
    names: ["TPR-100-Rx"],
  },
  {
    primary_id: "D0VW9H",
    names: ["2-Hydroxybenzamide Derivative 2"],
  },
  {
    primary_id: "D0VX0H",
    names: ["Isoquinoline Sulfonyl Derivative 1"],
  },
  {
    primary_id: "D0VX1V",
    names: ["2-N-Methoxyamino-1-(4-Methylthiophenyl)Propane", "CHEMBL1077961", "1204749-12-1"],
  },
  {
    primary_id: "D0VX5O",
    names: ["Neboglamine", "CR-224"],
  },
  {
    primary_id: "D0VX5Y",
    names: ["Indoline Derivative 15"],
  },
  {
    primary_id: "D0VX6P",
    names: ["Piperidine Derivative 1"],
  },
  {
    primary_id: "D0VX9R",
    names: ["Benzylserine"],
  },
  {
    primary_id: "D0VY1Y",
    names: ["Benzene Sulfonamide Derivative 12"],
  },
  {
    primary_id: "D0VY2Z",
    names: ["Adenosine-3'-5'-Bisphosphate", "Adenosine 3',5'-Diphosphate", "Adenosine 3',5'-Bisphosphate"],
  },
  {
    primary_id: "D0VY5N",
    names: ["Azole Benzene Derivative 1"],
  },
  {
    primary_id: "D0VY5O",
    names: [
      "A-620223",
      "A-866111",
      "A-966492",
      "ABT-472",
      "ABT-999",
      "PARP Inhibitor, Abbott",
      "PARP Inhibitors, Abbott",
      "Poly (ADP)Ribose Polymer Inhibitor, Abbott",
      "Poly (ADP)Ribose Inhibitors (Cancer), Abbott",
    ],
  },
  {
    primary_id: "D0VY7R",
    names: ["Azetidinyl-Piperidine Derivative 2"],
  },
  {
    primary_id: "D0VY9K",
    names: ["2-(N-Pyrrolidinyl)-3'-Nitropropiophenone", "CHEMBL599233", "SCHEMBL6055564", "BDBM50308120"],
  },
  {
    primary_id: "D0VY9O",
    names: ["3-(Phenoxymethyl) Benzylamine Derivative 2"],
  },
  {
    primary_id: "D0VZ0I",
    names: ["ZD1604"],
  },
  {
    primary_id: "D0VZ0U",
    names: ["H5N1 Pandemic Influenza Vaccine"],
  },
  {
    primary_id: "D0VZ1C",
    names: ["H-103"],
  },
  {
    primary_id: "D0VZ2A",
    names: ["SQ-609"],
  },
  {
    primary_id: "D0VZ3D",
    names: [
      "AQ-RA-741",
      "AQ-RA 741",
      "123548-16-3",
      "11-[[4-[4-(DIETHYLAMINO)BUTYL]-1-PIPERIDINYL]ACETYL]-5,11-DIHYDRO-6H-PYRIDO[2,3-B][1,4]BENZODIAZEPIN-6-ONE",
      "6H-Pyrido[2,3-B][1,4]benzodiazepin-6-One,11-[2-[4-[4-(Diethylamino)Butyl]-1-Piperidinyl]acetyl]-5,11-Dihydro-",
      "11-(2-(4-(4-(Diethylamino)Butyl)Piperidin-1-Yl)Acetyl)-5H-Benzo[e]pyrido[3,2-B][1,4]diazepin-6(11H)-One",
      "11-((4-(4-(Diethylamino)Butyl)-1-Piperidinyl)Acetyl)-5,11-Dihydro-6H-Pyrido(2,3-B)(1,4)Benzodiazepin-6-One",
      "BCUGCHZRMKTPMU-UHFFFAOYSA-N",
      "AC1L2WKT",
      "ACMC-20ej93",
    ],
  },
  {
    primary_id: "D0VZ4N",
    names: ["Guadecitabine"],
  },
  {
    primary_id: "D0VZ5B",
    names: ["Bempedoic Acid + Ezetimibe"],
  },
  {
    primary_id: "D0VZ5X",
    names: ["BI-44370 TA", "BI-44370", "Calcitonin Gene-Related Peptide Antagonist (Migraine), Boehringer Ingelheim"],
  },
  {
    primary_id: "D0VZ8S",
    names: ["KA-398"],
  },
  {
    primary_id: "D0VZ9S",
    names: [
      "CROMOGLYCATE LISETIL HYDROCHLORIDE",
      "Diethyl L-Lysylcromoglycate Dihydrochloride",
      "KY-556",
      "N-556",
      "Cromoglycate Lisetil Hydrochloride",
    ],
  },
  {
    primary_id: "D0W0AF",
    names: ["Des-AA1,2,5-[D-Trp8,(NalphaMe)IAmp9]SRIF", "CHEMBL425465"],
  },
  {
    primary_id: "D0W0AM",
    names: [
      "SB 235699",
      "4-(4-(4-Fluorophenyl)-1-(Piperidin-4-Yl)-1H-Imidazol-5-Yl)Pyridine",
      "VK-19911",
      "CHEMBL279416",
      "UNII-NP7J08ZRYY",
      "NP7J08ZRYY",
      "180869-32-3",
      "SCHEMBL140202",
      "HEP 689",
      "Sb235699",
      "BDBM50099331",
      "SB-235699",
      "4-(5-(4-Fluorophenyl)-3-(4-Piperidyl)Imidazol-4-Yl)Pyridine",
      "Pyridine, 4-(4-(4-Fluorophenyl)-1-(4-Piperidinyl)-1H-Imidazol-5-Yl)-",
    ],
  },
  {
    primary_id: "D0W0BF",
    names: [
      "Anastrozole",
      "Anastrazole",
      "Anastrole",
      "Anastrozol",
      "Arimidex",
      "Asiolex",
      "Astra Brand Of Anastrozole",
      "AstraZeneca Brand Of Anastrozole",
      "Zeneca Brand Of Anastrozole",
      "ZD 1033",
      "ZD1033",
      "Zeneca ZD 1033",
      "Arimidex (TN)",
      "Arimidex (Zeneca)",
      "Arimidex, Anastrozole",
      "ZD-1033",
      "Anastrozole [USAN:INN:BAN]",
      "Anastrozole (JAN/USAN/INN)",
      "Alpha,alpha,alpha',alpha'-Tetramethyl-5(1H-1,2,4-Triazol-1-Ylmethyl)-M-Benzenediacetonitrile",
      "Alpha,alpha,alpha',alpha'-Tetramethyl-5-(1H-1,2,4-Triazol-1-Ylmethyl)-M-Benzenediacetonitrile",
      "1,3-Benzenediacetonitrile, A, A,a', A'-Tetramethyl-5-(1H-1,2,4-Triazol-1-Ylmethyl)",
      "2,2'-(5-(1H-1,2,4-Triazol-1-Ylmethyl)-1,3-Phenylene)Bis(2-Methylpropionitrile)",
      "2,2'-[5-(1H-1,2,4-Triazol-1-Ylmethyl)-1,3-Phenylene]bis(2-Methylpropanenitrile)",
      "2,2'-[5-(1H-1,2,4-Triazol-1-Ylmethyl)Benzene-1,3-Diyl]bis(2-Methylpropanenitrile)",
      "2-[3-(2-Cyanopropan-2-Yl)-5-(1,2,4-Triazol-1-Ylmethyl)Phenyl]-2-Methylpropanenitrile",
    ],
  },
  {
    primary_id: "D0W0BK",
    names: ["TERT-BUTYL 2-CYANO-2-METHYLHYDRAZINECARBOXYLATE"],
  },
  {
    primary_id: "D0W0BL",
    names: ["3-((3,5-Diamino-1H-Pyrazol-4-Yl)Diazenyl)Phenol"],
  },
  {
    primary_id: "D0W0BN",
    names: ["NM-2014"],
  },
  {
    primary_id: "D0W0BO",
    names: ["Cyclopentane Amide Derivatives 4"],
  },
  {
    primary_id: "D0W0CO",
    names: ["17-(Cyclobutylmethyl)-N-Phenylmorphinan-3-Amine", "CHEMBL570225"],
  },
  {
    primary_id: "D0W0DY",
    names: [
      "Agrippal S1/MF-59 Influenza Vaccine",
      "Adiugrip",
      "Begrivac",
      "Chiromas",
      "Fluad",
      "Influpozzi Adiuvato",
      "Influenza Vaccine, Chiron",
      "Agrippal S1/MF-59 Influenza Vaccine, Chiron Vaccines",
      "Agrippal S1/MF-59 Influenza Vaccine, Novartis Vaccines & Diagnostics",
    ],
  },
  {
    primary_id: "D0W0ED",
    names: ["Eflucimibe"],
  },
  {
    primary_id: "D0W0FI",
    names: [
      "N'-(Phenylsulfonyl)Quinoline-6-Carbohydrazide",
      "CHEMBL437697",
      "SCHEMBL6235703",
      "YPRPLJAGGWUKMX-UHFFFAOYSA-N",
      "BDBM50172936",
      "N''-(Phenylsulfonyl)Quinoline-6-Carbohydrazide",
      "Quinoline-6-Carboxylic Acid 2-(Phenylsulfonyl)Hydrazide",
    ],
  },
  {
    primary_id: "D0W0FX",
    names: ["NE11808"],
  },
  {
    primary_id: "D0W0GE",
    names: [
      "CR-002",
      "CR-002 Liquid API",
      "Alpha 1 Proteinase Inhibitor (Inhaled Solution), CSL Behring",
      "CR-002, CSL Behring",
    ],
  },
  {
    primary_id: "D0W0HN",
    names: [
      "4-[2-(4-Phenyl-Butylamino)-Ethyl]-Phenol",
      "CHEMBL118969",
      "SCHEMBL7379499",
      "ZINC13781000",
      "BDBM50066539",
    ],
  },
  {
    primary_id: "D0W0HZ",
    names: ["TZ-101sm"],
  },
  {
    primary_id: "D0W0IN",
    names: ["NN-9925"],
  },
  {
    primary_id: "D0W0IX",
    names: [
      "2-Tosylnaphthalene-1,4-Dione",
      "2-[4-Toluenesulfonyl]-1,4-Naphthoquinone",
      "CHEMBL454282",
      "2-(4-Methylphenyl)Sulfonylnaphthalene-1,4-Dione",
      "40852-77-5",
      "NSC124929",
      "AC1L5KCI",
      "AC1Q6TN2",
      "CTK4I3907",
      "DTXSID00298642",
      "UCFUGRHGHVYUOA-UHFFFAOYSA-N",
      "ZINC1714192",
      "BDBM50245928",
      "NSC-124929",
      "2-(P-Tolylsulfonyl)-1,4-Naphthoquinone",
      "2-[(4-Methylphenyl)Sulfonyl]naphthoquinone #",
    ],
  },
  {
    primary_id: "D0W0JJ",
    names: ["AKB-9788"],
  },
  {
    primary_id: "D0W0JP",
    names: ["OMS405"],
  },
  {
    primary_id: "D0W0KW",
    names: ["Ro-24-8138"],
  },
  {
    primary_id: "D0W0LG",
    names: ["8-N-Propylnaringenin"],
  },
  {
    primary_id: "D0W0LS",
    names: ["TAK-779"],
  },
  {
    primary_id: "D0W0MD",
    names: ["MBX2329"],
  },
  {
    primary_id: "D0W0MF",
    names: ["Talbutal"],
  },
  {
    primary_id: "D0W0MT",
    names: ["PMID30107136-Compound-Example51"],
  },
  {
    primary_id: "D0W0NE",
    names: ["6-Hydroxymethyl-7,8-Dihydropterin"],
  },
  {
    primary_id: "D0W0NJ",
    names: ["PMID17532216C2f"],
  },
  {
    primary_id: "D0W0NU",
    names: [
      "NIC5-15",
      "D-Pinitol",
      "Pinitol",
      "10284-63-6",
      "3-O-Methyl-D-Chiro-Inositol",
      "(+)-Pinitol",
      "D-(+)-Pinitol",
      "Inzitol",
      "Methylinositol",
      "Sennitol",
      "1-D-4-O-METHYL-MYO-INOSITOL",
      "Pinit",
      "D-Ononitol",
      "Ononitol",
      "1D-4-O-Methyl-Myo-Inositol",
      "1D-3-O-Methyl-Chiro-Inositol",
      "D-Chiro-Inositol, 3-O-Methyl-",
      "4-O-Methyl-Myo-Inositol",
      "UNII-TF9HZN9T0M",
      "UNII-A998ME07KR",
      "5D-5-O-Methyl-Chiro-Inositol",
      "6090-97-7",
      "(1r,2s,4s,5s)-6-Methoxycyclohexane-1,2,3,4,5-Pentol",
      "TF9HZN9T0M",
      "484-68-4",
      "Matezit",
      "Sennit",
      "CHEMBL493737",
      "A998ME07KR",
      "CHEBI:28548",
      "DSCFFEYYQKSRSV-KLJZZCKASA-N",
      "NSC 43336",
    ],
  },
  {
    primary_id: "D0W0OJ",
    names: [
      "6-Chloro-1H-Indazole",
      "698-25-9",
      "6-Chloroindazole",
      "1H-Indazole, 6-Chloro-",
      "1H-INDAZOLE,6-CHLORO-",
      "CHEMBL392184",
      "VUZQHUVRBPILAX-UHFFFAOYSA-N",
      "AK-27924",
      "6-Chloroazaindole",
      "6-Chloro-Indazole",
      "NSC92743",
      "PubChem20592",
      "AC1L9DR4",
      "SCHEMBL3533806",
      "SCHEMBL16523332",
      "CTK2F6854",
      "DTXSID70329119",
      "MolPort-000-002-984",
      "HMS1629J03",
      "6-Chloro-1H-Indazole, AldrichCPR",
      "ACT06345",
      "ZINC4878475",
      "BCP00929",
      "EBD48419",
      "NSC-92743",
      "BDBM50209236",
      "BBL101057",
      "STL554851",
      "ANW-50721",
      "FCH835101",
      "AKOS005256021",
      "AKOS024124978",
      "RP01765",
      "CS-W005017",
      "VI20061",
      "FS-2891",
      "QC-2958",
      "PB34228",
    ],
  },
  {
    primary_id: "D0W0PC",
    names: ["1,2,4-Oxadiazole Derivative 7"],
  },
  {
    primary_id: "D0W0PF",
    names: [
      "2-Amino-6-Chloropyrazine",
      "2-AMINO-6-CHLOROPYRAZINE",
      "33332-28-4",
      "6-Chloropyrazin-2-Amine",
      "2-Chloro-6-Aminopyrazine",
      "Pyrazinamine, 6-Chloro-",
      "2-Pyrazinamine, 6-Chloro-",
      "6-Chloro-Pyrazin-2-Ylamine",
      "6-Chloropyrazin-2-Ylamine",
      "2-Amino-6-Chloro-Pyrazine",
      "CHEMBL191632",
      "6-CHLORO-2-PYRAZINAMINE",
      "CIG",
      "6-Chlorpyrazin-2-Amin",
      "1wcc",
      "PubChem8543",
      "Zlchem 1337",
      "AI3-61778",
      "AC1Q3PRB",
      "AC1L3MZH",
      "ACMC-209i0e",
      "6-Chloropyrazine-2-Ylamine",
      "SCHEMBL67803",
      "6-Chloro-Pyrazin-2-Yl-Amine",
      "6-Chloropyrazin-2-Amine, 5",
      "KSC222G6J",
      "AC1Q52Q1",
      "Jsp006119",
      "DTXSID0067761",
      "CTK1C2364",
      "BDBM24626",
      "ZLE0115",
    ],
  },
  {
    primary_id: "D0W0PH",
    names: ["SCH-48375"],
  },
  {
    primary_id: "D0W0QF",
    names: [
      "4-Methyl-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "4-Methyl-N-(4-Phenyl-1,3-Thiazol-2-Yl)Benzamide",
      "CHEMBL294175",
      "NERONVMZIDSIRT-UHFFFAOYSA-N",
      "87614-00-4",
      "(Z)-4-Methyl-N-(4-Phenylthiazol-2(3H)-Ylidene)Benzamide",
      "AC1Q2JVA",
      "AC1LB6OY",
      "Oprea1_598164",
      "Oprea1_751162",
      "Oprea1_000114",
      "SCHEMBL233522",
      "ARONIS019641",
      "2-[(4-Methylphenyl)Carboxamido]-4-Phenyl-1,3-Thiazole",
      "KS-00004BJP",
      "MolPort-003-009-217",
      "MolPort-001-029-741",
      "ZINC4031557",
      "BDBM50101781",
      "STK084905",
      "AKOS024573976",
      "AKOS000486758",
      "MCULE-8350259880",
      "ST036564",
    ],
  },
  {
    primary_id: "D0W0QO",
    names: ["ISIS 19639"],
  },
  {
    primary_id: "D0W0QS",
    names: ["ISIS 112580"],
  },
  {
    primary_id: "D0W0RT",
    names: [
      "PTX-008313",
      "Chorismate Synthase Inhibitors (Antibacterial)",
      "PTX-110130",
      "Chorismate Synthase Inhibitors (Antibacterial), PanTherix",
    ],
  },
  {
    primary_id: "D0W0SE",
    names: ["PF750"],
  },
  {
    primary_id: "D0W0SL",
    names: ["LY2940680"],
  },
  {
    primary_id: "D0W0SM",
    names: ["CRL-8131"],
  },
  {
    primary_id: "D0W0SS",
    names: ["ISIS 101996"],
  },
  {
    primary_id: "D0W0TH",
    names: ["R 80122"],
  },
  {
    primary_id: "D0W0UF",
    names: ["Imidazo[1,2-B]pyridazine Derivative 6"],
  },
  {
    primary_id: "D0W0UU",
    names: ["CPG23DIAB"],
  },
  {
    primary_id: "D0W0UW",
    names: ["CLT-002"],
  },
  {
    primary_id: "D0W0VD",
    names: [
      "Beta-Lapachone",
      "ARQ 501",
      "SL 11001",
      "A-Lapachone",
      "LAPACHONE, BETA",
      "AK-693/21096016",
      "2,2-Dimethyl-3,4-Dihydrobenzo[h]chromene-5,6-Dione",
      "3,4-Dihydro-2,2-Dimethyl-2H-Naphtho(1,2-B)Pyran-5,6-Dione",
      "3,4-Dihydro-2,2-Dimethyl-2H-Naphtho[1,2-B]pyran-5,6-Dione",
    ],
  },
  {
    primary_id: "D0W0VK",
    names: [
      "3-Phenyl-4-Hydroxyquinolin-2(1H)-One",
      "14933-29-0",
      "3-Phenyl-2,4-Quinolinediol",
      "2,4-Dihydroxy-3-Phenylquinoline",
      "NSC16582",
      "UNII-98SFL4LU2U",
      "98SFL4LU2U",
      "3-Phenylquinoline-2,4-Diol",
      "CHEMBL324519",
      "4-Hydroxy-3-Phenylquinolin-2(1H)-One",
      "4-Hydroxy-3-Phenyl-1H-Quinolin-2-One",
      "2,4-Quinolinediol, 3-Phenyl-",
      "NSC 16582",
      "Maybridge1_005460",
      "AC1L5EN7",
      "Oprea1_589873",
      "MLS000850648",
      "DivK1c_001748",
      "SCHEMBL625364",
      "AC1Q6B83",
      "HMS557A04",
      "CTK4C6143",
      "DTXSID90164221",
      "MolPort-002-043-580",
      "MolPort-000-808-473",
      "HMS2269O16",
      "NSC-16582",
      "BDBM50001260",
      "STK587488",
    ],
  },
  {
    primary_id: "D0W0VR",
    names: ["PFK-158"],
  },
  {
    primary_id: "D0W0WL",
    names: ["1,6-Bis(4'-Methylaminophenyl)-Hexa-1,3,5-Triene", "CHEMBL208457", "ZINC13686485", "BDBM50185991"],
  },
  {
    primary_id: "D0W0WP",
    names: ["LL-3858"],
  },
  {
    primary_id: "D0W0WU",
    names: [
      "Diethylnorspermine",
      "BENSPM",
      "DENSPM",
      "BE-333",
      "DE-333",
      "N1,N11-Diethylnorspermine",
      "N1,N11-Bis(Ethyl)Norspermine",
      "1,11-Bis(Ethylamino)-4,8-Diazaundecane",
    ],
  },
  {
    primary_id: "D0W0XK",
    names: ["(+/-)-3-(Pent-4-Enyl)Azetidin-2-One", "CHEMBL505129"],
  },
  {
    primary_id: "D0W0XM",
    names: ["NPS-2390"],
  },
  {
    primary_id: "D0W0XS",
    names: ["PolyPEPI1018"],
  },
  {
    primary_id: "D0W0YI",
    names: ["BI-54903/Olodaterol Fixed-Dose Combination"],
  },
  {
    primary_id: "D0W1AR",
    names: ["ERC-1671"],
  },
  {
    primary_id: "D0W1AW",
    names: [
      "D609",
      "O-(Octahydro-4,7-Methano-1H-Inden-5-Yl) Carbonopotassium Dithioate",
      "D609 Potassium Salt",
      "Carbonodithioic Acid, O-(Octahydro-4,7-Methano-1H-Inden-5-Yl) Ester Potassium",
      "SCHEMBL15720168",
      "Tricyclodecan-9-Yl Xanthogenate K",
      "Tricyclodecan-9-Yl Xanthogenate Potassium Salt",
      "O-Tricyclo[5.2.1.0(2,6)]dec-9-Yl Dithiocarbonate Potassium Salt",
    ],
  },
  {
    primary_id: "D0W1AX",
    names: ["PMID28092474-Compound-32l"],
  },
  {
    primary_id: "D0W1BE",
    names: [
      "4-(2-Nitrovinyl)Phenol",
      "3179/8/6",
      "4-Hydroxy-B-Nitrostyrene",
      "(E)-4-(2-Nitrovinyl)Phenol",
      "4-Hydroxy-Beta-Nitrostyrene",
      "Trans-4-Hydroxy-Beta-Nitrostyrene",
      "22568-49-6",
      "Phenol, P-(2-Nitrovinyl)-",
      "4-[(E)-2-Nitroethenyl]phenol",
      "4-[(E)-2-Nitrovinyl]phenol",
      "CHEMBL307481",
      "CTJKRKMPTRJAIT-AATRIKPKSA-N",
      "NSC81594",
      "1-(4-Hydroxyphenyl)-2-Nitroethene",
      "4-(2-(Hydroxy(Oxido)Amino)Vinyl)Phenol",
      "4-(2-Nitro-Vinyl)-Phenol",
      "NSC30299",
      "AC1LCWDH",
      "4-Hydroxy--Nitrostyrene",
      "4'-Hydroxy-Beta-Nitrostyrene",
      "Phenol,4-(2-Nitroethenyl)-",
    ],
  },
  {
    primary_id: "D0W1BV",
    names: ["Acel-Immune", "Acel-Imune", "Pertussis Vaccine, Takeda", "DTaP, Wyeth-Lederle"],
  },
  {
    primary_id: "D0W1BY",
    names: ["(D)Phe-Gln-Trp-Ala-Val-Gly-His-Leu-Leu-NH2", "CHEMBL269024"],
  },
  {
    primary_id: "D0W1CC",
    names: [
      "(R)-(+)-(4,5,6-Trimethoxyindan-1-Yl)Methanamine",
      "CHEMBL379780",
      "890309-57-6",
      "Jimscaline",
      "CTK2I1398",
      "DTXSID30470414",
      "ZINC35569551",
      "BDBM50190615",
      "1H-Indene-1-Methanamine, 2,3-Dihydro-4,5,6-Trimethoxy-, (1R)-",
    ],
  },
  {
    primary_id: "D0W1DI",
    names: [
      "AS-1670542",
      "AVATROMBOPAG",
      "570406-98-3",
      "AKR-501",
      "UNII-3H8GSZ4SQL",
      "3H8GSZ4SQL",
      "AKR 501",
      "Avatrombopag [USAN:INN]",
      "Doptelet",
      "AS 1670542",
      "Avatrombopag (USAN/INN)",
      "GTPL9953",
      "DTXSID30205667",
      "ZINC72190218",
      "AKOS027323962",
      "CS-3397",
      "HY-13463",
      "FT-0728753",
      "D10306",
      "AS1670542",
    ],
  },
  {
    primary_id: "D0W1DK",
    names: ["Golden Phosphorous Acetyletic Compound 1"],
  },
  {
    primary_id: "D0W1FM",
    names: ["4-(3H-Indol-4-Ylamino)-5-Styrylnicotinonitrile"],
  },
  {
    primary_id: "D0W1GH",
    names: ["Ins(3,4,5,6)P4", "1D-Myo-Inositol 3,4,5,6-Tetrakisphosphate"],
  },
  {
    primary_id: "D0W1GP",
    names: ["Autologous Anti-BCMA-CAR-Expressing CD4+/CD8+ T-Lymphocytes FCARH143"],
  },
  {
    primary_id: "D0W1GR",
    names: [
      "4-Hept-3-Ynyl-1H-Imidazole",
      "CHEMBL276804",
      "1H-Imidazole, 5-(3-Heptyn-1-Yl)-",
      "SCHEMBL7620435",
      "4-(3-Heptynyl)-1H-Imidazole",
      "BDBM50070215",
    ],
  },
  {
    primary_id: "D0W1HB",
    names: [
      "BAY-61-2116",
      "Anticancer Therapeutics, Millennium/Bayer",
      "ICAST (Cancer), Millennium/Bayer",
      "Inhibitor Of CAMP Signal Transduction (Cancer), Millennium/Bayer",
    ],
  },
  {
    primary_id: "D0W1HM",
    names: ["Nafadotride"],
  },
  {
    primary_id: "D0W1HZ",
    names: ["PD164333"],
  },
  {
    primary_id: "D0W1JJ",
    names: ["GS-9669"],
  },
  {
    primary_id: "D0W1JY",
    names: ["NAPQI"],
  },
  {
    primary_id: "D0W1MU",
    names: ["TBC-M4"],
  },
  {
    primary_id: "D0W1MX",
    names: ["BMS-187745", "157124-84-0", "CHEMBL351151"],
  },
  {
    primary_id: "D0W1NH",
    names: ["Thiophene Sulfonamide"],
  },
  {
    primary_id: "D0W1OA",
    names: ["BEMA Buprenorphine Transmucosal"],
  },
  {
    primary_id: "D0W1OQ",
    names: [
      "3beta-Hydroxyrus-12,19(29)-Dien-28-Oic Acid",
      "CHEMBL517522",
      "BDBM50259781",
      "3beta-Hydroxyurs-12,19(29)-Diene-28-Oic Acid",
    ],
  },
  {
    primary_id: "D0W1OR",
    names: ["3,5-Dihydroxybenzoic Acid"],
  },
  {
    primary_id: "D0W1OX",
    names: [
      "Biphenyl-3-Ylcarbamic Acid Cyclohexyl Ester",
      "565460-15-3",
      "URB602",
      "Cyclohexyl Biphenyl-3-Ylcarbamate",
      "Cyclohexyl [1,1'-Biphenyl]-3-Ylcarbamate",
      "URB-602",
      "UNII-B8371SFA9K",
      "CHEMBL77767",
      "Monoacylglycerol Lipase Inhibitor, URB602",
      "B8371SFA9K",
      "URB 602",
      "N-Biphenyl-3-Ylcarbamic Acid, Cyclohexyl Ester",
      "N-[1,1'-Biphenyl]-3-Yl-Carbamic Acid Cyclohexyl Ester",
      "SCHEMBL4274165",
      "Cyclohexylbiphenyl-3-Ylcarbamate",
      "CTK8C4433",
      "DTXSID90450611",
      "HHVUFQYJOSFTEH-UHFFFAOYSA-N",
      "MolPort-003-850-812",
      "EX-A1228",
      "BCP19101",
      "BDBM50128581",
      "ANW-71925",
      "ZINC13520725",
    ],
  },
  {
    primary_id: "D0W1PF",
    names: ["LUF-5980"],
  },
  {
    primary_id: "D0W1PV",
    names: [
      "Apicidin",
      "CHEMBL430060",
      "OSI-2040",
      "(3S,6S,9S,12R)-3-[(2S)-Butan-2-Yl]-6-[(1-Methoxyindol-3-Yl)Methyl]-9-(6-Oxooctyl)-1,4,7,10-Tetrazabicyclo[10.4.0]hexadecane-2,5,8,11-Tetrone",
      "Acipidin",
      "AC1OCFAM",
      "Apicidin _120249",
      "SCHEMBL147997",
      "ChEMBL_275631",
      "GTPL7495",
      "DTXSID40274182",
      "MolPort-005-944-562",
      "BDBM50238632",
      "ZINC17654900",
      "AKOS030622920",
      "MCULE-8969470747",
      "NCGC00165733-01",
    ],
  },
  {
    primary_id: "D0W1QA",
    names: ["GC-1102"],
  },
  {
    primary_id: "D0W1QI",
    names: [
      "Butizide",
      "BUTHIAZIDE",
      "Thiabutazide",
      "Eunephran",
      "Isobutylhydrochlorothiazide",
      "Saltucin",
      "Butizid",
      "2043-38-1",
      "Buthiazide [USAN]",
      "Butizidum [INN-Latin]",
      "Butizida [INN-Spanish]",
      "EINECS 218-048-8",
      "SU 6187",
      "S 3500",
      "Butizide [INN]",
      "Butizide (INN)",
      "Buthiazide (USAN)",
      "6-Chloro-3,4-Dihydro-3-Isobutyl-2H-1,2,4-Benzothiadiazine-7-Sulfonamide-1,1-Dioxide",
      "6-Chloro-3,4-Dihydro-3-Isobutyl-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3,4-Dihydro-3-(2-Methylpropyl)-, 1,1-Dioxide",
    ],
  },
  {
    primary_id: "D0W1RQ",
    names: ["PMID30107136-Compound-Example44"],
  },
  {
    primary_id: "D0W1RY",
    names: ["Hydroxyamphetamine Hydrobromide"],
  },
  {
    primary_id: "D0W1SD",
    names: ["124I-Labeled 11-1F4"],
  },
  {
    primary_id: "D0W1SE",
    names: ["PMID26651364-Compound-5b"],
  },
  {
    primary_id: "D0W1SL",
    names: [
      "BUTYLATEDHYDROXYTOLUENE",
      "2,6-Di-Tert-Butyl-4-Methylphenol",
      "128-37-0",
      "Butylated Hydroxytoluene",
      "Butylhydroxytoluene",
      "2,6-Di-Tert-Butyl-P-Cresol",
      "2,6-Di-T-Butyl-4-Methylphenol",
      "Ionol",
      "DBPC",
      "Stavox",
      "Dibunol",
      "BHT",
      "Ionol CP",
      "Topanol",
      "Impruvol",
      "Vianol",
      "Dalpac",
      "Ionole",
      "Deenax",
      "Antioxidant KB",
      "3,5-Di-Tert-Butyl-4-Hydroxytoluene",
      "Sumilizer BHT",
      "Antioxidant 4K",
      "Topanol O",
      "Topanol OC",
      "Vanlube PC",
      "Vanlube PCX",
      "Sustane BHT",
      "Tenamene 3",
      "Antioxidant DBPC",
      "Antioxidant 29",
      "Antioxidant 30",
      "Tenox BHT",
      "Nonox TBC",
      "Chemanox 11",
      "Ionol 1",
      "Agidol",
      "Catalin CAO-3",
      "Advastab 401",
      "Ionol (Antioxidan",
    ],
  },
  {
    primary_id: "D0W1SM",
    names: ["C[Nle-Gln-D-Phe-Arg-Trp-Glu]-NH2", "CHEMBL203602"],
  },
  {
    primary_id: "D0W1SP",
    names: ["(Z)-2-(1-Decyl-2-Oxoindolin-3-Ylidene)Acetic Acid", "CHEMBL493798"],
  },
  {
    primary_id: "D0W1ST",
    names: [
      "Ethyl 1-[(1H-Benzimidazol-2(3H)One-5-Yl)Sulfonyl]-1H-Pyrrole-2-Carboxylate",
      "2-(Morpholin-4-Yl)-Benzo[h]chromen-4-One",
      "154447-35-5",
      "NU7026",
      "NU 7026",
      "DNA-PK Inhibitor II",
      "NU-7026",
      "2-Morpholino-4H-Benzo[h]chromen-4-One",
      "LY293646",
      "LY-293646",
      "2-(4-Morpholinyl)-4H-Naphthol[1,2-B]pyran-4-One",
      "2-(4-Morpholinyl)-4H-Naphtho[1,2-B]pyran-4-One",
      "CHEMBL104468",
      "AK186905",
      "DNA-Dependent Protein Kinase Inhibitor II",
      "2-Morpholin-4-Ylbenzo[h]chromen-4-One",
      "SCHEMBL610237",
      "ZINC9230",
      "GTPL5959",
      "KS-00000XHI",
      "CTK0E7833",
      "CHEBI:92165",
      "DTXSID10432010",
      "AOB2835",
      "MolPort-009-019-548",
      "HMS3229C11",
      "EX-A1100",
      "BCP04736",
      "IN1364",
      "S2893",
    ],
  },
  {
    primary_id: "D0W1SX",
    names: [
      "3-Bromo-N-(6-Methylpyridin-2-Yl)Benzamide",
      "CHEMBL377195",
      "188747-28-6",
      "AC1LCNCE",
      "TimTec1_002275",
      "Oprea1_606042",
      "CTK0A3803",
      "DTXSID30345279",
      "MolPort-002-316-872",
      "BOCBDQIZNZCOPX-UHFFFAOYSA-N",
      "HMS1540H09",
      "ZINC138486",
      "STK182187",
      "BDBM50186324",
      "AKOS000204447",
      "MCULE-1961959863",
      "ST031112",
      "3-Bromo-N~1~-(6-Methyl-2-Pyridyl)Benzamide",
      "3-Bromo-N-(6-Methyl-Pyridin-2-Yl)-Benzamide",
      "3-Bromo-N-(6-Methyl-2-Pyridinyl)Benzamide #",
      "Benzamide, 3-Bromo-N-(6-Methyl-2-Pyridinyl)-",
      "Z57716131",
      "(3-Bromophenyl)-N-(6-Methyl(2-Pyridyl))Carboxamide",
    ],
  },
  {
    primary_id: "D0W1TK",
    names: ["NKTR-105"],
  },
  {
    primary_id: "D0W1TL",
    names: ["BB-MPI-03"],
  },
  {
    primary_id: "D0W1TU",
    names: ["SB-699551-A"],
  },
  {
    primary_id: "D0W1US",
    names: ["PLECONARIL"],
  },
  {
    primary_id: "D0W1VL",
    names: [
      "N-(Naphthalen-1-Yl)-4-Phenylthiazol-2-Amine",
      "CHEMBL208452",
      "AC1N24LJ",
      "BDBM50183105",
      "AKOS001583269",
      "EU-0001073",
      "N-Naphthalen-1-Yl-4-Phenyl-1,3-Thiazol-2-Amine",
      "SR-01000396392",
    ],
  },
  {
    primary_id: "D0W1WE",
    names: ["N-Methyl-N-Phenyl-2-Oxo-2H-Chromene-3-Carboxamide"],
  },
  {
    primary_id: "D0W1WF",
    names: [
      "4-Iodo-6-Phenylpyrimidine",
      "41270-96-6",
      "CHEMBL497802",
      "AC-907/25004997",
      "AC1LG8ZQ",
      "SCHEMBL244108",
      "CHEBI:91213",
      "CTK7C3610",
      "MolPort-003-800-888",
      "ZINC331218",
      "BDBM50265608",
      "AKOS005266491",
      "FCH1331127",
      "4-IPP, &gt",
    ],
  },
  {
    primary_id: "D0W1WQ",
    names: [
      "(R)-3-(4-Isobutyl-Phenyl)-Butan-2-One",
      "2-Butanone,3-[4-(2-Methylpropyl)Phenyl]-,(3R)-",
      "SCHEMBL4471196",
      "CHEMBL371421",
      "ZINC13646153",
    ],
  },
  {
    primary_id: "D0W1XD",
    names: ["ISIS 1827"],
  },
  {
    primary_id: "D0W1XJ",
    names: ["Liposome Encapsulated Paclitaxel"],
  },
  {
    primary_id: "D0W1YD",
    names: ["H-Poa-Ser-Gly-Phe-Leu-Thr-OH", "CHEMBL246740"],
  },
  {
    primary_id: "D0W1YI",
    names: ["M3M3FBS"],
  },
  {
    primary_id: "D0W1YM",
    names: ["AS-1406"],
  },
  {
    primary_id: "D0W1YT",
    names: ["7-Chloro-6H-Chromeno[4,3-B]quinoline-3,9-Diol"],
  },
  {
    primary_id: "D0W1YV",
    names: ["PLX-5622"],
  },
  {
    primary_id: "D0W1YX",
    names: [
      "BBT-031",
      "BBT-005",
      "PEGylated Growth Hormone, Bolder BioTechnology Inc",
      "Long-Acting Growth Hormone (Growth Hormone Deficiency, Short Stature, HARS), Bolder",
    ],
  },
  {
    primary_id: "D0W1YZ",
    names: ["TSERaM"],
  },
  {
    primary_id: "D0W1ZW",
    names: ["VPC03090-P"],
  },
  {
    primary_id: "D0W2AN",
    names: ["Emepronium"],
  },
  {
    primary_id: "D0W2BF",
    names: ["Vadastuximab Talirine"],
  },
  {
    primary_id: "D0W2BI",
    names: ["8-Prenylquercetin", "CHEMBL193059", "8-Prenyl-Quercetin", "SCHEMBL2686819", "BDBM50240974"],
  },
  {
    primary_id: "D0W2BK",
    names: ["ICo-007"],
  },
  {
    primary_id: "D0W2BP",
    names: ["N-Benzyl-5-(4-Chlorophenyl)Nicotinamide", "CHEMBL1271417"],
  },
  {
    primary_id: "D0W2CX",
    names: ["CG-100649"],
  },
  {
    primary_id: "D0W2DN",
    names: ["MCL-444"],
  },
  {
    primary_id: "D0W2DV",
    names: [
      "BM-13677",
      "BM 13677",
      "129393-59-5",
      "BM 13,677",
      "2-((3-Phenylpropoxy)Imino)Butanoic Acid",
      "Butanoic Acid, 2-((3-Phenylpropoxy)Imino)-",
    ],
  },
  {
    primary_id: "D0W2EA",
    names: ["Theradigm-Melanoma", "CY-2010", "Theradigm-MAGE-3"],
  },
  {
    primary_id: "D0W2EB",
    names: ["Colistimethate Sodium (Dry Powder Inhalation Formulation), Pharmax", "Colobreathe"],
  },
  {
    primary_id: "D0W2ED",
    names: ["L-731735", "CHEMBL2052018", "SCHEMBL2723563"],
  },
  {
    primary_id: "D0W2EG",
    names: [
      "ATL-1222",
      "Adenosine A2a Receptor Agonists (Acute Inflammation), Clinical Data",
      "Adenosine Receptor Modulator (Inflammation), Clinical Data",
    ],
  },
  {
    primary_id: "D0W2EI",
    names: ["C[Glu21-Lys26][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0W2EK",
    names: ["Ixabepilone", "Azaepothilone B", "BMS-247550", "Ixempra (TN)"],
  },
  {
    primary_id: "D0W2EP",
    names: ["JMV 1801"],
  },
  {
    primary_id: "D0W2EX",
    names: ["(S)-2-Amino-4-(Benzylthio)Butanoic Acid"],
  },
  {
    primary_id: "D0W2FG",
    names: [
      "OPHD-001",
      "C Difficile Therapy, Ophidian/Lilly",
      "CDAD Antitoxin, Ophidian/Lilly",
      "Clostridium Difficile Therapy, Ophidian/Lilly",
    ],
  },
  {
    primary_id: "D0W2FM",
    names: ["(2'Z,3'E)-5-Fluoro-5'-Methyl-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0W2FN",
    names: [
      "BBT-018",
      "BBT-001",
      "Long-Acting GCSF-IgG-Fc Immunofusion Protein (Neutropenia), Bolder BioTechnology Inc",
    ],
  },
  {
    primary_id: "D0W2FU",
    names: ["ML277"],
  },
  {
    primary_id: "D0W2FY",
    names: ["ISIS 17152"],
  },
  {
    primary_id: "D0W2FZ",
    names: ["Solithromycin Oral"],
  },
  {
    primary_id: "D0W2GG",
    names: ["PMID17929793C23i"],
  },
  {
    primary_id: "D0W2HD",
    names: ["Mogamulizumab"],
  },
  {
    primary_id: "D0W2HG",
    names: ["Urocortin 2"],
  },
  {
    primary_id: "D0W2HJ",
    names: ["1-(4-Methoxyphenethyl)Pyrrolidine", "CHEMBL272307"],
  },
  {
    primary_id: "D0W2HW",
    names: [
      "N,N-Diethyl-2-(1H-Indol-3-Yl)Ethanamine",
      "Diethyltryptamine",
      "N,N-Diethyltryptamine",
      "61-51-8",
      "3-(2-Diethylaminoethyl)Indole",
      "UNII-916E8V4S2V",
      "INDOLE, 3-(2-(DIETHYLAMINO)ETHYL)-",
      "BRN 0153320",
      "D.E.T.",
      "CHEMBL142936",
      "DEA No. 7434",
      "916E8V4S2V",
      "1H-Indole-3-Ethanamine, N,N-Diethyl-",
      "AC1L1LS6",
      "Oprea1_105894",
      "5-22-10-00050 (Beilstein Handbook Reference)",
      "SCHEMBL517713",
      "DTXSID9052763",
      "CTK5B3364",
      "LSSUMOWDTKZHHT-UHFFFAOYSA-N",
      "ZINC1999162",
      "BDBM50094676",
      "STK368075",
      "AKOS005444987",
      "1H-Indole-3-Ethanamine,N,N-Diethyl-",
      "63938-63-6 (Mono-Hydrochloride)",
    ],
  },
  {
    primary_id: "D0W2IU",
    names: ["AVE-0847"],
  },
  {
    primary_id: "D0W2JT",
    names: ["LXR-017"],
  },
  {
    primary_id: "D0W2JY",
    names: ["IC-47"],
  },
  {
    primary_id: "D0W2KA",
    names: ["ANTI-TWEAK"],
  },
  {
    primary_id: "D0W2KG",
    names: ["NT-69-L"],
  },
  {
    primary_id: "D0W2KL",
    names: ["PMID28394193-Compound-37"],
  },
  {
    primary_id: "D0W2KR",
    names: [
      "CS-0240",
      "CS-0600",
      "HDAC Inhibitors (Neurodegenerative Diseases/Viral Infections/Immune Diseases), Shenzhen Chipscreen Biosciences",
    ],
  },
  {
    primary_id: "D0W2LR",
    names: ["Influenza Virus Vaccine"],
  },
  {
    primary_id: "D0W2LW",
    names: ["NOX-151"],
  },
  {
    primary_id: "D0W2MI",
    names: ["Anti-CD30-CAR-Transduced T Cells"],
  },
  {
    primary_id: "D0W2NM",
    names: ["Flunitrazepam"],
  },
  {
    primary_id: "D0W2NZ",
    names: [
      "VLI-04A",
      "Ad-AlphaPA/Alpha Spore PK7",
      "Modified And Shielded Recombinant Adenoviral Vector Vaccine (Anthrax), VectorLogics",
    ],
  },
  {
    primary_id: "D0W2OP",
    names: [
      "2-(4-Benzylphenoxy)Ethanamine",
      "CHEMBL163807",
      "2-(4-Benzylphenoxy)Ethylamine",
      "SCHEMBL7138354",
      "MJTXDDKAEQAMNA-UHFFFAOYSA-N",
      "2-(4-Benzyl-Phenoxy)-Ethylamine",
      "BDBM50085290",
    ],
  },
  {
    primary_id: "D0W2OT",
    names: [
      "BUTAPROST",
      "Butaprost",
      "(R)-Butaprost",
      "69648-38-0",
      "UNII-HP16WVP23Y",
      "TR-4979",
      "HP16WVP23Y",
      "NCGC00165753-01",
      "Butaprostum",
      "(1R,2R,3R)-3-Hydroxy-2-[(1E,4R)-4-Hydroxy-4-(1-Propylcyclobutyl)-1-Butenyl]-5-Oxo-Cyclopentaneheptanoic Acid Methyl Ester",
      "Butaprostum [Latin]",
      "Bay Q 4218",
      "Butaprost [USAN:INN:BAN]",
      "Butaprost (Free Acid)",
      "AC1NSJVB",
      "Butaprost (USAN/INN)",
      "DSSTox_RID_83186",
      "DSSTox_CID_28919",
      "SCHEMBL94764",
      "DSSTox_GSID_48993",
      "CHEMBL271896",
      "GTPL3379",
      "DTXSID8048993",
      "BDBM85602",
      "BAY-Q-4218",
      "ZINC4215145",
      "Tox21_113480",
      "MFCD00867055",
      "1090AH",
      "PDSP2_001674",
    ],
  },
  {
    primary_id: "D0W2PK",
    names: [
      "Beloxepin",
      "ADL-6906",
      "Beloxepin (Oral, Pain)",
      "Beloxepin (Oral, Depression), Organon",
      "Beloxepin (Oral, Pain), Adolor",
    ],
  },
  {
    primary_id: "D0W2PQ",
    names: ["4-Acrylamido-N-(3-Bromophenyl)-2-Hydroxybenzamide", "CHEMBL200958"],
  },
  {
    primary_id: "D0W2PX",
    names: ["N-(4-Amino-4'-Methoxybiphenyl-3-Yl)Benzamide", "CHEMBL1095412", "BDBM50317998"],
  },
  {
    primary_id: "D0W2PZ",
    names: ["Parasin I"],
  },
  {
    primary_id: "D0W2QN",
    names: [
      "2-Ethyl-5-Methoxy-3-Piperidin-4-Yl-1H-Indole",
      "CHEMBL194412",
      "1h-Indole,2-Ethyl-5-Methoxy-3-(4-Piperidinyl)-",
      "866404-90-2",
      "ZINC28526364",
      "BDBM50171247",
    ],
  },
  {
    primary_id: "D0W2RI",
    names: ["JTE-451"],
  },
  {
    primary_id: "D0W2RY",
    names: ["G530L"],
  },
  {
    primary_id: "D0W2SO",
    names: ["Orazipone"],
  },
  {
    primary_id: "D0W2SV",
    names: ["Inhibitor 1 [Colombo Et Al., 2012]", "CHEMBL2086421", "GTPL8624", "SCHEMBL14232816", "BDBM50420334"],
  },
  {
    primary_id: "D0W2SX",
    names: ["NP-0336", "Butyrylcholineesterase Inhibitor (Cognitive Disorder), Noscira"],
  },
  {
    primary_id: "D0W2TQ",
    names: ["PPG"],
  },
  {
    primary_id: "D0W2UK",
    names: ["IK-862"],
  },
  {
    primary_id: "D0W2UX",
    names: ["Talminogene Laherparepvec"],
  },
  {
    primary_id: "D0W2UY",
    names: [
      "E-5050",
      "E5050",
      "121808-24-0",
      "AC1L2VQ6",
      "SCHEMBL9546047",
      "N-(3-(4'-(2'',6''-Dimethylheptyl)Phenyl)Butanoyl)Ethanolamine",
      "E 50-50-",
      "3-[4-(2,6-Dimethylheptyl)Phenyl]-N-(2-Hydroxyethyl)Butanamide",
      "Benzenepropanamide, 4-(2,6-Dimethylheptyl)-N-(2-Hydroxyethyl)-Beta-Methyl-",
    ],
  },
  {
    primary_id: "D0W2VS",
    names: ["RGI-4100"],
  },
  {
    primary_id: "D0W2XO",
    names: [
      "Sodium Maleate",
      "Disodium But-2-Enedioate",
      "371-47-1",
      "Disodiumfumarate",
      "2-Butenedioic Acid (2Z)-, Sodium Salt (1:2)",
      "ACMC-209ir7",
      "ACMC-209e0o",
      "AC1L1TP7",
      "AC1N5G1J",
      "CTK4D3501",
      "CTK8B1559",
      "CTK4D7533",
      "DTXSID10168802",
      "KS-00000WA6",
      "ANW-22438",
      "ANW-28577",
      "AKOS030228400",
      "AN-18678",
      "2-Butenedioic Acid(2Z)-, Sodium Salt (1:?)",
      "2-Butenedioic Acid(2E)-, Sodium Salt (1:2)",
    ],
  },
  {
    primary_id: "D0W2YK",
    names: ["PMID29338548-Compound-40"],
  },
  {
    primary_id: "D0W2YN",
    names: [
      "1-Naphthalen-2-Yl-Piperazine",
      "1-(Naphthalen-2-Yl)Piperazine",
      "57536-91-1",
      "1-(2-NAPHTHYL)PIPERAZINE",
      "CHEMBL278509",
      "1-Naphthalen-2-Ylpiperazine",
      "Oprea1_405543",
      "AC1MC234",
      "SCHEMBL3138664",
      "LWLBVIFUVSUSAY-UHFFFAOYSA-N",
      "ZINC3716177",
      "SBB076290",
      "BDBM50007411",
      "AKOS003591179",
      "MCULE-5668438904",
    ],
  },
  {
    primary_id: "D0W2YR",
    names: ["3-(5-Hydroxy-Benzooxazol-2-Yl)-Benzene-1,2-Diol"],
  },
  {
    primary_id: "D0W2ZM",
    names: ["SC-68376"],
  },
  {
    primary_id: "D0W2ZX",
    names: ["UK-387618"],
  },
  {
    primary_id: "D0W3AX",
    names: ["SB-281832"],
  },
  {
    primary_id: "D0W3BA",
    names: ["Methanesulfonyl Fluoride"],
  },
  {
    primary_id: "D0W3BC",
    names: ["ATL1103"],
  },
  {
    primary_id: "D0W3BE",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 31"],
  },
  {
    primary_id: "D0W3BX",
    names: [
      "2-Phenyl-5H-Indeno[1,2-D]pyrimidine",
      "CHEMBL241206",
      "Aza-Heterocyclic Derivative, 3b",
      "SCHEMBL18015923",
      "BDBM19179",
    ],
  },
  {
    primary_id: "D0W3DV",
    names: ["Sotatercept"],
  },
  {
    primary_id: "D0W3EJ",
    names: ["Peptide Analog 69"],
  },
  {
    primary_id: "D0W3FF",
    names: ["2-(4-Hydroxy-Naphthalen-1-Yl)-Benzooxazol-6-Ol"],
  },
  {
    primary_id: "D0W3FQ",
    names: ["6-Nitro-2-Phenyl-Chromen-4-One", "6-Nitroflavone", "CHEMBL26742"],
  },
  {
    primary_id: "D0W3GE",
    names: ["Plague Vaccine Injectable"],
  },
  {
    primary_id: "D0W3GL",
    names: ["SR-147778"],
  },
  {
    primary_id: "D0W3GR",
    names: ["Prexigebersen"],
  },
  {
    primary_id: "D0W3IO",
    names: ["DS3032b"],
  },
  {
    primary_id: "D0W3IQ",
    names: ["D[Val4]AVP", "CHEMBL221436"],
  },
  {
    primary_id: "D0W3JA",
    names: ["LA-8045"],
  },
  {
    primary_id: "D0W3JC",
    names: ["Heterocyclic Derivative 7"],
  },
  {
    primary_id: "D0W3JH",
    names: ["CM2489"],
  },
  {
    primary_id: "D0W3KK",
    names: ["Urea And Carbamate Bioisostere Derivative 13"],
  },
  {
    primary_id: "D0W3KR",
    names: [
      "(R)-4-[2-[3-(4-Methoxy-Benzoylamino)-Benzyl]-Piperidin-1-Ylmethyl]piperidine-1-Carboxylic Acid Amide (Ro-320-6206)",
    ],
  },
  {
    primary_id: "D0W3LG",
    names: [
      "Broussoflavonol F",
      "CHEMBL464007",
      "162558-94-3",
      "Biooussoflavonol F",
      "SCHEMBL6822778",
      "MolPort-039-338-587",
      "ZINC14727564",
      "LMPK12111986",
    ],
  },
  {
    primary_id: "D0W3LI",
    names: ["LY333531"],
  },
  {
    primary_id: "D0W3LM",
    names: ["2-(4-Hydroxyphenyl)-7-Methoxychroman-4-One"],
  },
  {
    primary_id: "D0W3ME",
    names: ["PNU-142372"],
  },
  {
    primary_id: "D0W3MH",
    names: ["BAY-94-9392", "18F-Labeled PET Tracer (Cancer/Inflammation), Bayer"],
  },
  {
    primary_id: "D0W3MT",
    names: ["8-Propyl-2,6-Diphenyl-9H-Purine", "CHEMBL424869"],
  },
  {
    primary_id: "D0W3NA",
    names: [
      "Coenzyme A",
      "CoASH",
      "CoA-SH",
      "Zeel",
      "85-61-0",
      "Depot-Zeel",
      "CoA",
      "HSCoA",
      "Coenzym A",
      "Coenzyme ASH",
      "Co-Enzyme-A",
      "UNII-SAA04E81UX",
      "HS-CoA",
      "SAA04E81UX",
      "CHEBI:15346",
      "3'-Phosphoadenosine-(5')Diphospho(4')Pantatheine",
      "Coenzyme A Hydrate",
      "Co-A",
      "Co-A-SH",
      "Reduced CoA",
      "3'-Phosphoadenosine 5'-{3-[(3R)-3-Hydroxy-2,2-Dimethyl-4-Oxo-4-({3-Oxo-3-[(2-Sulfanylethyl)Amino]propyl}amino)Butyl] Dihydrogen Diphosphate}",
    ],
  },
  {
    primary_id: "D0W3NN",
    names: ["Polygeline"],
  },
  {
    primary_id: "D0W3NR",
    names: ["BetaGRAFT", "BetaGRAFT (Cell Therapy, Diabetes)"],
  },
  {
    primary_id: "D0W3NX",
    names: ["Isoxazole-Based Bicyclic Compound 12"],
  },
  {
    primary_id: "D0W3ON",
    names: [
      "5-Aminoquinolin-8-Ol",
      "13207-66-4",
      "5-Amino-8-Hydroxyquinoline",
      "8-Quinolinol, 5-Amino-",
      "5-Amino-8-Quinolinol",
      "5-Amino-Quinolin-8-Ol",
      "5-Azanylquinolin-8-Ol",
      "YDEUKNRKEYICTH-UHFFFAOYSA-N",
      "AS-0308-225",
      "5-Aminoquinoline-8-Ol",
      "AC1L9ASV",
      "5-Amino-8-Quinolinol #",
      "SCHEMBL688655",
      "5'-Amino-8'-Hydroxyquinoline",
      "CHEMBL449298",
      "AMOT0046",
      "AC1Q523Z",
      "Cid_416002",
      "BDBM32142",
      "CTK0H3311",
      "DTXSID20328975",
      "MolPort-000-000-986",
      "ZINC120515",
      "ALBB-024871",
      "5-Amino-8-Hydroxyquinoline HClsalt",
      "ANW-61190",
      "5928AA",
      "BBL028356",
      "STK737829",
      "SBB005680",
      "AKOS000111586",
    ],
  },
  {
    primary_id: "D0W3OQ",
    names: ["BMS-182566"],
  },
  {
    primary_id: "D0W3OS",
    names: ["Tibolone"],
  },
  {
    primary_id: "D0W3OU",
    names: ["VY-AADC01 Gene Therapy"],
  },
  {
    primary_id: "D0W3OY",
    names: ["S-(4-Nitrobenzyl)Isothiourea Hydrochloride"],
  },
  {
    primary_id: "D0W3PR",
    names: ["Tyr-Pro-Trp-D-Ala-Bn", "CHEMBL577747"],
  },
  {
    primary_id: "D0W3PY",
    names: ["ACPT-I", "1-Aminocyclopentane-1,3,4-Tricarboxylic Acid"],
  },
  {
    primary_id: "D0W3QB",
    names: ["2-Morpholin-4-Yl-Benzo[h]chromene-4-Thione", "CHEMBL176233", "SCHEMBL13346492"],
  },
  {
    primary_id: "D0W3RA",
    names: ["Phenylsulfonyl Derivative 1"],
  },
  {
    primary_id: "D0W3RE",
    names: ["[35S]ATPalphaS", "[35S]-ATPalphaS"],
  },
  {
    primary_id: "D0W3RP",
    names: ["ISIS 32010"],
  },
  {
    primary_id: "D0W3RR",
    names: ["2-Morpholin-4-Yl-8-Phenylethynyl-Chromen-4-One", "2-Morpholino-8-(2-Phenylethynyl)-4H-Chromen-4-One"],
  },
  {
    primary_id: "D0W3RV",
    names: ["ISIS 347573"],
  },
  {
    primary_id: "D0W3RW",
    names: ["MK-2206"],
  },
  {
    primary_id: "D0W3SQ",
    names: ["H-101"],
  },
  {
    primary_id: "D0W3SX",
    names: ["FSHR NAM", "ADX-68692", "ADX-68693"],
  },
  {
    primary_id: "D0W3TC",
    names: ["ITV-3"],
  },
  {
    primary_id: "D0W3TL",
    names: ["Fused Benzoheterocycle Amide Derivative 2"],
  },
  {
    primary_id: "D0W3TS",
    names: ["Benzamide Derivative 11"],
  },
  {
    primary_id: "D0W3TZ",
    names: ["Tgth-0100"],
  },
  {
    primary_id: "D0W3UH",
    names: ["QLT-091568"],
  },
  {
    primary_id: "D0W3UL",
    names: [
      "2-Thiomethyl-3-Phenylpropanoic Acid",
      "2-THIOMETHYL-3-PHENYLPROPANOIC ACID",
      "CHEMBL90403",
      "(2S)-2-Benzyl-3-Sulfanylpropanoic Acid",
      "S-BMPA",
      "AC1NRAFB",
      "GTPL8676",
      "SCHEMBL1464853",
      "BDBM50281176",
      "DB02953",
      "(S)-2-Mercaptomethyl-3-Phenyl-Propionic Acid",
      "(S)-Alpha-(Mercaptomethyl)Benzenepropionic Acid",
      "(2S)-2-(Phenylmethyl)-3-Sulfanylpropanoic",
    ],
  },
  {
    primary_id: "D0W3UV",
    names: ["ABI-009"],
  },
  {
    primary_id: "D0W3VM",
    names: [
      "N-(4-Tert-Butylphenyl)-4-(Pyridin-2-Yl)Benzamide",
      "CHEMBL183121",
      "SCHEMBL3873012",
      "JWPYMHAYISNKIC-UHFFFAOYSA-N",
      "BDBM50160036",
      "N-(4-Tert-Butylphenyl)-4-(2-Pyridinyl)Benzamide",
    ],
  },
  {
    primary_id: "D0W3VT",
    names: ["1-Benzhydryl-4-(4-Chlorophenyl)Piperidin-4-Ol", "CHEMBL392753"],
  },
  {
    primary_id: "D0W3WB",
    names: ["CWF-0808"],
  },
  {
    primary_id: "D0W3WD",
    names: ["VRC-HIVADV027-00-VP"],
  },
  {
    primary_id: "D0W3WW",
    names: ["LFA703"],
  },
  {
    primary_id: "D0W3XD",
    names: ["N',2-Diphenylquinoline-4-Carbohydrazide", "Compound 2g [PMID: 16950620]"],
  },
  {
    primary_id: "D0W3XP",
    names: ["Garveatin C"],
  },
  {
    primary_id: "D0W3YL",
    names: [
      "Bentoquatam",
      "Bentone 34",
      "Benclay",
      "Bentolite L",
      "Dioxosilane",
      "Oxo(Oxoalumanyloxy)Alumane",
      "Smectite",
      "Ivy Block",
      "Otaylite",
      "Askangel",
      "Wilkinite",
      "Volclay",
      "Aquagel",
      "Wilkonite",
      "Tixoton",
      "Magbond",
      "Filgel",
      "Culvin",
      "Bengel",
      "BentoGrout",
      "Akajo",
      "Bentonite Magma",
      "Asama",
      "IvyBlock",
      "Colloidal Clay",
      "Soap Clay",
      "Bentonit T",
      "Bentolite HS",
      "Bulgarben BA",
      "Accugel F",
      "Bengel A",
      "Altonit SF",
      "Southern Bentonite",
      "Bengel FW",
      "Bengel HVP",
      "Bentosolon 82",
      "Aquagel Gold Seal",
      "Detercol P 2",
      "Detercal G 2FC",
      "Detercal G 1FC",
      "Bentone 27",
      "HI-Jel",
      "Cosintam 403",
      "Accofloc 352",
      "Bengel 15",
    ],
  },
  {
    primary_id: "D0W3YW",
    names: ["DRF 10945"],
  },
  {
    primary_id: "D0W3ZN",
    names: ["PD-169316"],
  },
  {
    primary_id: "D0W3ZW",
    names: ["6-Amino-Uridine Monophosphate"],
  },
  {
    primary_id: "D0W4AA",
    names: ["PMID25666693-Compound-33"],
  },
  {
    primary_id: "D0W4AR",
    names: ["KUR-211"],
  },
  {
    primary_id: "D0W4CC",
    names: ["CPI-005", "Cardiosphere-Derived Stem Cell Therapy (Chronic Heart Failure), Capricor"],
  },
  {
    primary_id: "D0W4CN",
    names: ["Ac-Phe-D-Thr(PO3H2)-Pip-Nal-Gln-NH2", "CHEMBL436759"],
  },
  {
    primary_id: "D0W4EB",
    names: ["Endoxifen"],
  },
  {
    primary_id: "D0W4FF",
    names: ["ML-3403"],
  },
  {
    primary_id: "D0W4GL",
    names: ["SiRNA Therapy, Lung Injury/Lung Cancer/Neurodegenerative Disease"],
  },
  {
    primary_id: "D0W4GM",
    names: [
      "(1-Phenylcyclopentyl)Methanamine",
      "17511-89-6",
      "1-Phenylcyclopentanemethylamine",
      "1-(1-PHENYLCYCLOPENTYL)METHANAMINE",
      "(1-Phenylcyclopentyl)Methylamine",
      "1-(1-Phenylcyclopentyl)Methylamine",
      "1-METHYLAMINE-1-BENZYL-CYCLOPENTANE",
      "1-Phenylcyclopentanemethanamine",
      "C-(1-Phenyl-Cyclopentyl)-Methylamine",
      "Cyclopentanemethylamine, 1-Phenyl-",
      "BRN 2936636",
      "Cyclopentanemethanamine,1-Phenyl-",
      "CHEMBL382127",
      "CHEBI:39505",
      "Cyclopentanemethanamine, 1-Phenyl-",
      "(Phenylcyclopentyl)Methylamine",
      "(1-Phenylcyclopentyl)Methylamine Hydrochloride",
      "2bua",
    ],
  },
  {
    primary_id: "D0W4GQ",
    names: ["3-(2-((E)-3-Phenylprop-1-Enyl)Phenyl)Acrylic Acid"],
  },
  {
    primary_id: "D0W4GY",
    names: [
      "4'-(Trifluoromethyl)-4-Biphenylol",
      "10355-13-2",
      "4-(4-TRIFLUOROMETHYLPHENYL)PHENOL",
      "4'-(Trifluoromethyl)[1,1'-Biphenyl]-4-Ol",
      "CHEMBL244264",
      "4'-(Trifluoromethyl)Biphenyl-4-Ol",
      "4'-(Trifluoromethyl)-[1,1'-Biphenyl]-4-Ol",
      "[1,1'-Biphenyl]-4-Ol, 4'-(Trifluoromethyl)-",
      "ACMC-1BRC7",
      "SCHEMBL678508",
      "CTK0D8481",
      "DTXSID50573870",
      "4'-Trifluoromethyl-Biphenyl-4-Ol",
      "AFPOYLCQXONCPX-UHFFFAOYSA-N",
      "ZINC13840578",
      "BDBM50220207",
      "4'-(Trifluoromethyl) Biphenyl-4-Ol",
      "4''-(Trifluoromethyl)-4-Biphenylol",
      "ANW-14928",
      "AKOS004116417",
      "SEL12447598",
      "4-Hydroxy-4'-(Trifluoromethyl)Biphenyl",
      "AJ-64481",
    ],
  },
  {
    primary_id: "D0W4HA",
    names: ["MDX-018"],
  },
  {
    primary_id: "D0W4HJ",
    names: ["MSX-3"],
  },
  {
    primary_id: "D0W4HO",
    names: ["IMT-504"],
  },
  {
    primary_id: "D0W4HW",
    names: ["SB-9000"],
  },
  {
    primary_id: "D0W4HZ",
    names: ["Lonafarnib"],
  },
  {
    primary_id: "D0W4IB",
    names: [
      "2-Imidazol-1-Yl-7-Methoxy-3-Phenyl-Chromen-4-One",
      "CHEMBL192306",
      "Azole Isoflavone Analog 2a",
      "BDBM9891",
      "2-(1H-Imidazol-1-Yl)-7-Methoxy-3-Phenyl-4H-Chromen-4-One",
      "2-(1H-Imidazol-1-Yl)-7-Methoxy-3-Phenyl-4H-1-Benzopyran4-One",
      "2-(1h-Imidazol-1-Yl)-7-Methoxy-3-Phenyl-4h-1-Benzo Pyran-4-One",
    ],
  },
  {
    primary_id: "D0W4IK",
    names: [
      "3-(Prop-2-Ene-1-Sulfinyl)-Propene-1-Thiol",
      "AJ3",
      "DB02895",
      "(1E)-3-[(R)-Prop-2-Ene-1-Sulfinyl]prop-1-Ene-1-Thiol",
    ],
  },
  {
    primary_id: "D0W4IL",
    names: ["NORCANTHARIDIN"],
  },
  {
    primary_id: "D0W4JC",
    names: ["[3H]ZM 241385", "[3H]-ZM241385"],
  },
  {
    primary_id: "D0W4JI",
    names: ["INS-50589"],
  },
  {
    primary_id: "D0W4JS",
    names: ["RO3244794"],
  },
  {
    primary_id: "D0W4JY",
    names: [
      "CPZEN-45",
      "CPZEN-48",
      "CPZEN-51",
      "Caprazamycin Analogs (Tuberculosis), Microbial Chemistry Research Foundation/Lilly TB Drug Discovery Initiative",
    ],
  },
  {
    primary_id: "D0W4MN",
    names: ["Isoquinoline 1,3-Dione Derivative 1"],
  },
  {
    primary_id: "D0W4MW",
    names: ["PMID28394193-Compound-Figure3bII"],
  },
  {
    primary_id: "D0W4MZ",
    names: ["L-Leucine"],
  },
  {
    primary_id: "D0W4NM",
    names: [
      "Oltipraz",
      "CB-1400",
      "Oltipraz (Mucositis/Radiation Sickness/Cancer)",
      "Oltipraz (Mucositis/Radiation Sickness/Cancer), Canopus Biopharma",
      "Syntheticdithiolethione (Oral, Acute Radiation Syndrome/Mucositis/Cancer), Canopus",
    ],
  },
  {
    primary_id: "D0W4OF",
    names: ["Diisopropyl Mercapto(Phenyl)Methylphosphonate", "CHEMBL1170023"],
  },
  {
    primary_id: "D0W4OL",
    names: ["FK-633"],
  },
  {
    primary_id: "D0W4OZ",
    names: ["GG-8573"],
  },
  {
    primary_id: "D0W4QB",
    names: ["EPI-12323 Combination Therapy"],
  },
  {
    primary_id: "D0W4RB",
    names: ["BIOO-3"],
  },
  {
    primary_id: "D0W4SM",
    names: ["CD19 And CD22 CAR-T Cells"],
  },
  {
    primary_id: "D0W4SS",
    names: ["Interferon Beta"],
  },
  {
    primary_id: "D0W4TG",
    names: ["Recombinant Botulinum Neurotoxin Vaccine"],
  },
  {
    primary_id: "D0W4TW",
    names: ["7-Bromo-4,9-Dihydro-3H-Beta-Carboline", "CHEMBL428999", "BDBM50136497"],
  },
  {
    primary_id: "D0W4UQ",
    names: [
      "(Z)-5-Amino-3-(4-Methoxybenzylidene)Indolin-2-One",
      "CHEMBL591696",
      "(3Z)-5-AMINO-3-[(4-METHOXYPHENYL)METHYLIDENE]-1,3-DIHYDRO-2H-INDOL-2-ONE",
      "2x2l",
    ],
  },
  {
    primary_id: "D0W4UX",
    names: ["BMS-187308", "153624-15-8"],
  },
  {
    primary_id: "D0W4VH",
    names: ["Peptide-T"],
  },
  {
    primary_id: "D0W4VM",
    names: [
      "AR-C70484XX",
      "CHEMBL30108",
      "SCHEMBL3688521",
      "BDBM50110846",
      "4-[3-[4-(Decyloxy)Phenoxy]acetonyloxy]benzoic Acid",
      "4-[3-(4-Decyloxy-Phenoxy)-2-Oxo-Propoxy]-Benzoic Acid",
      "4-(3-(4-(Decyloxy)Phenoxy)-2-Oxopropoxy)Benzoic Acid",
      "4-[3-(4-Decyloxy-Phenoxy)-2-Oxo-Propoxy]-Benzoic Acid(AR-C70484XX)",
    ],
  },
  {
    primary_id: "D0W4WB",
    names: ["Isosorbide-2-Benzyl Carbamate"],
  },
  {
    primary_id: "D0W4WF",
    names: [
      "EFFUSOL",
      "73166-28-6",
      "NSC 371300",
      "5-Ethenyl-1-Methyl-9,10-Dihydrophenanthrene-2,7-Diol",
      "UNII-S436Y000RU",
      "CHEMBL205119",
      "S436Y000RU",
      "1-Methyl-5-Vinyl-9,10-Dihydrophenanthrene-2,7-Diol",
      "NSC-371300",
      "2,7-Phenanthrenediol, 5-Ethenyl-9,10-Dihydro-1-Methyl-",
      "NSC371300",
      "AC1L2PII",
      "AC1Q7B8H",
      "CTK8D4698",
      "DTXSID00223419",
      "MolPort-042-624-482",
      "ZINC1588038",
      "BDBM50180512",
      "FT-0667825",
    ],
  },
  {
    primary_id: "D0W4WN",
    names: ["BT-101"],
  },
  {
    primary_id: "D0W4WP",
    names: [
      "CVT-10216",
      "CVT-10083",
      "CVT-10200",
      "CVT-10992",
      "ALDH2 Antagonists (Alcoholism), CV Therapeutics",
      "ALDH2 Antagonists (Alcoholism), Gilead Palo Alto",
      "Aldehyde Dehydrogenase-2 Antagonists (Alcoholism), CV Therapeutics",
    ],
  },
  {
    primary_id: "D0W4XP",
    names: ["ISIS 29154"],
  },
  {
    primary_id: "D0W4XY",
    names: ["PRX-08066"],
  },
  {
    primary_id: "D0W4YO",
    names: ["NOCICEPTIN"],
  },
  {
    primary_id: "D0W4ZU",
    names: ["KURARINOL"],
  },
  {
    primary_id: "D0W5AW",
    names: ["SC-79407"],
  },
  {
    primary_id: "D0W5BS",
    names: ["FerriSeltz"],
  },
  {
    primary_id: "D0W5BY",
    names: ["MK-767 (KRP-297)"],
  },
  {
    primary_id: "D0W5CI",
    names: ["DWJ-209"],
  },
  {
    primary_id: "D0W5CQ",
    names: ["ISONICOTINIC ACID"],
  },
  {
    primary_id: "D0W5CR",
    names: [
      "EM-1745",
      "BDBM17290",
      "E2-Adenosine Hybrid Compound, 8",
      "EM1745",
      "[5-(6-Amino-9H-Purin-9-Yl)-3,4-Dihydroxyoxolan-2-Yl]methyl 9-[(1S,10R,11S,14S,15S)-5,14-Dihydroxy-15-Methyltetracyclo[8.7.0.0",
      "{11,15}]heptadeca-2,4,6-Trien-13-Yl]nonanoate",
    ],
  },
  {
    primary_id: "D0W5CT",
    names: ["Jak2 Inhibitors"],
  },
  {
    primary_id: "D0W5CZ",
    names: [
      "4-(Furan-2-Yl)-7H-Pyrrolo[2,3-D]pyrimidin-2-Amine",
      "CHEMBL406499",
      "4-(Furan-2-Yl)-7H-Pyrrolo[2,3-D]pyrimidin-2-Ylamine",
      "442847-46-3",
      "SCHEMBL4369855",
      "SCHEMBL13668449",
      "YOPVCNSOKMOXST-UHFFFAOYSA-N",
      "BDBM50239028",
      "J3.571.237B",
      "L021771",
      "4-(2-Furyl)-7H-Pyrrolo[2,3-D]pyrimidine-2-Amine",
    ],
  },
  {
    primary_id: "D0W5DU",
    names: ["PMID26666989-Compound-Figure9toprightR02"],
  },
  {
    primary_id: "D0W5DZ",
    names: ["CHLOROPUUPEHENONE", "Chloropuupehenone", "(+)-Chloropuupehenone"],
  },
  {
    primary_id: "D0W5ED",
    names: ["(Z)-5-Butylidene-2-Thioxothiazolidin-4-One"],
  },
  {
    primary_id: "D0W5EF",
    names: ["MOLASSAMIDE"],
  },
  {
    primary_id: "D0W5EH",
    names: ["Sarizotan"],
  },
  {
    primary_id: "D0W5EY",
    names: ["LpxCi-4"],
  },
  {
    primary_id: "D0W5FG",
    names: ["EP-100"],
  },
  {
    primary_id: "D0W5FH",
    names: ["ISIS 133243"],
  },
  {
    primary_id: "D0W5FM",
    names: ["PMID1527791C29"],
  },
  {
    primary_id: "D0W5GP",
    names: ["LN-020", "Autologous Cell Vaccine (Solid Tumor), LipoNova"],
  },
  {
    primary_id: "D0W5GX",
    names: ["PMID26394986-Compound-57"],
  },
  {
    primary_id: "D0W5HA",
    names: ["XEP-018", "Conus Consors Venom (Pain/Anesthesia), Atheris"],
  },
  {
    primary_id: "D0W5HK",
    names: ["Sorafenib"],
  },
  {
    primary_id: "D0W5HL",
    names: ["PMID16242323C15b"],
  },
  {
    primary_id: "D0W5HT",
    names: ["8-N-Nonylnaringenin"],
  },
  {
    primary_id: "D0W5IC",
    names: ["SF-103"],
  },
  {
    primary_id: "D0W5IF",
    names: [
      "CH-4933468",
      "CH-5137291",
      "Androgen Receptor Antagonists (Oral, Prostate Tumor)",
      "Androgen Receptor Antagonists (Oral, Prostate Tumor), Chugai",
    ],
  },
  {
    primary_id: "D0W5IN",
    names: ["(2-Methyl-Indol-1-Yl)-Propyl-Pyridin-4-Yl-Amine", "CHEMBL154945", "SCHEMBL7608050", "BDBM50048573"],
  },
  {
    primary_id: "D0W5IP",
    names: ["GNF-PF-3645"],
  },
  {
    primary_id: "D0W5JE",
    names: ["4-(3,5-Difluorophenylethynyl)-2-Methylthiazole", "CHEMBL202436", "SCHEMBL4161912"],
  },
  {
    primary_id: "D0W5JT",
    names: ["[D-Gln3,Ncy(Isopropyl)7]acyline", "CHEMBL228197"],
  },
  {
    primary_id: "D0W5KA",
    names: ["Cefpiroxime", "DQ-2556"],
  },
  {
    primary_id: "D0W5KO",
    names: ["MesoTarg"],
  },
  {
    primary_id: "D0W5LC",
    names: ["Forigerimod", "CEP-33457"],
  },
  {
    primary_id: "D0W5LG",
    names: ["ALT-020"],
  },
  {
    primary_id: "D0W5LS",
    names: [
      "Desoxycorticosterone Pivalate",
      "DOCP",
      "DTMA",
      "Cortexone M",
      "Deoxycorticosterone Pivalate",
      "Deoxycorticosterone Trimethylacetate",
      "Deoxycortolone Pivalate",
      "Deoxycortone Pivalate",
      "Deoxycortone Trimethylacetate",
      "Desoxycorticosterone Trimethylacetate",
      "Desoxycortone Pivalate",
      "Percorten M",
      "Percorten Pivalate",
      "Desoxycorticosterone Pivalate (USP)",
      "Neodin-Depositum",
      "Percorten (TN)",
      "Corticosterone, Deoxy-, Pivalate (6CI,7CI)",
      "[2-(10,13-Dimethyl-3-Oxo-1,2,6,7,8,9,11,12,14,15,16,17-Dodecahydrocyclopenta[a]phenanthren-17-Yl)-2-Oxoethyl] 2,2-Dimethylpropanoate",
      "11-Deoxycorticosterone Pivalate",
      "11-Deoxycorticosterone, Pivalate",
      "11-Deoxycorticosterone, Pivalate (8CI)",
      "21-(2,2-Dimethyl-1-Oxopropoxy)Pregn-4-Ene-3,20-Dione",
      "3,20-Dioxopregn-4-En-21-Yl 2,2-Dimethylpropanoate",
    ],
  },
  {
    primary_id: "D0W5MX",
    names: [
      "DTP Vaccine",
      "Acelluvax",
      "Pertugen",
      "TriAcelluvax",
      "Tricelluvax",
      "DTP Vaccine, Chiron",
      "Vaccine (DTaP), Chiron",
    ],
  },
  {
    primary_id: "D0W5NB",
    names: [
      "1h-Benoximidazole-2-Carboxylic Acid",
      "1H-Benzimidazole-2-Carboxylic Acid",
      "2849-93-6",
      "2-Benzimidazolecarboxylic Acid",
      "1H-Benzo[d]imidazole-2-Carboxylic Acid",
      "Benzimidazole-2-Carboxylic Acid",
      "1H-Benzoimidazole-2-Carboxylic Acid",
      "1H-1,3-Benzodiazole-2-Carboxylic Acid",
      "Benzoimidazole-2-Carboxylic Acid",
      "Benzimidazole-2-Yl-Carboxylic Acid",
      "1H-BENOXIMIDAZOLE-2-CARBOXYLIC ACID",
      "1h-1,3-Benzimidazole-2-Carboxylic Acid",
      "RHXSYTACTOMVLJ-UHFFFAOYSA-N",
      "PubChem8062",
      "AC1L5PLZ",
      "Benzimidazolecarboxylic Acid",
      "AC1Q1HJ4",
      "Benzimidazole Carboxylic Acid",
      "2-Carboxy-1H-Benzimidazole",
    ],
  },
  {
    primary_id: "D0W5ND",
    names: ["Azachalcone Derivative 1"],
  },
  {
    primary_id: "D0W5NP",
    names: ["Anatumomab Mafenatox", "ABR-214936", "Fab5T4V13-SEAm9", "PNU-214936", "TTS-CD2", "CD-2, Active Biotech"],
  },
  {
    primary_id: "D0W5NW",
    names: [
      "CMX-001",
      "Brincidofovir",
      "CMX001",
      "444805-28-1",
      "Hexadecyloxypropyl-Cidofovir",
      "CMX 001",
      "Hexadecyloxypropyl Cidofovir",
      "UNII-6794O900AX",
      "6794O900AX",
      "HDP-Cidofovir",
      "Cidofovir-HDP",
      "[(2S)-1-(4-Amino-2-Oxopyrimidin-1-Yl)-3-Hydroxypropan-2-Yl]oxymethyl-(3-Hexadecoxypropoxy)Phosphinic Acid",
      "Brincidofovir [USAN:INN]",
      "Hexadecyloxypropyl - Cidofovir",
      "Cidofovir Prodrug",
      "HDP-HPMPC",
      "Brincidofovir (USAN)",
      "AC1LA8GF",
      "3-(Hexadecyloxy)Propyl Hydrogen ((((S)-1-(4-Amino-2-Oxopyrimidin-1(2H)-Yl)-3-Hydroxypropan-2-Yl)Oxy)",
    ],
  },
  {
    primary_id: "D0W5OM",
    names: ["4-Tert-Butyl-N-O-Tolylthiazol-2-Amine", "CHEMBL382244", "BDBM50183109"],
  },
  {
    primary_id: "D0W5OV",
    names: ["DF-1111301", "2-[2-(Dimethylamino)Ethyl]-1-(2-Ethoxyethyl)Benzimidazole Dihydrochloride"],
  },
  {
    primary_id: "D0W5PG",
    names: [
      "Anti-VEGFR 1 Mab",
      "Anti-VEGFR 1 MAb (Undisclosed Indication)",
      "Anti-VEGFR 1 MAb (Undisclosed Indication), PharmAbcine",
      "Vascular Endothelial Growth Factor Receptor 1 Inhibitor (MAb, Undisclosed Indication), PharmAbcine",
    ],
  },
  {
    primary_id: "D0W5PN",
    names: ["Radezolid Iv"],
  },
  {
    primary_id: "D0W5QJ",
    names: ["Anti-HIV Ribozyme Therapy"],
  },
  {
    primary_id: "D0W5QR",
    names: [
      "1-(3-Tritylaminopropyl)Uracil",
      "CHEMBL211905",
      "1-[3-(Tritylamino)Propyl]pyrimidine-2,4-Dione",
      "SCHEMBL913253",
    ],
  },
  {
    primary_id: "D0W5RE",
    names: ["SUN-13837"],
  },
  {
    primary_id: "D0W5RL",
    names: ["Asoprisnil"],
  },
  {
    primary_id: "D0W5RQ",
    names: [
      "2'-Amino-3,4,4',5-Tetramethoxy-(Z)-Stillbene",
      "CHEMBL217041",
      "SCHEMBL5071915",
      "ZINC38145012",
      "BDBM50147364",
      "J3.552.112G",
    ],
  },
  {
    primary_id: "D0W5SZ",
    names: [
      "NSC-158324",
      "Acediasulfone",
      "UNII-30YP2YHH8W",
      "1980/3/5",
      "30YP2YHH8W",
      "CHEMBL48396",
      "N-[4-[(4-AMINOPHENYL)SULPHONYL]PHENYL]GLYCINE",
      "2-[4-(4-Aminophenyl)Sulfonylanilino]acetic Acid",
      "Acediasulfonum",
      "N-(4-((4-Aminophenyl)Sulphonyl)Phenyl)Glycine",
      "EINECS 201-243-7",
      "AC1L25EF",
      "ZINC862",
      "SCHEMBL143660",
      "CTK5E7379",
      "DTXSID00229991",
      "CHEBI:135300",
      "BDBM50099670",
      "AKOS027327086",
      "DB08926",
      "Glycine,N-[4-[(4-Aminophenyl)Sulfonyl]phenyl]-",
      "{4-[(4-Aminophenyl)Sulfonyl]anilino}acetic Acid",
      "2-(4-(4-Aminophenylsulfonyl)Phenylamino)Acetic Acid",
    ],
  },
  {
    primary_id: "D0W5TY",
    names: ["5-(3-Phenyl-Propyl)-1H-Pyrazole-3-Carboxylic Acid", "CHEMBL129326", "ZINC13526614"],
  },
  {
    primary_id: "D0W5UD",
    names: ["Adipose Stem Cell Therapy"],
  },
  {
    primary_id: "D0W5UJ",
    names: ["(S)-2-Amino-6-[(E)-Ethylimino]-Hexanoic Acid"],
  },
  {
    primary_id: "D0W5UV",
    names: ["Ethacrynate Sodium"],
  },
  {
    primary_id: "D0W5VV",
    names: ["NVXT Topical"],
  },
  {
    primary_id: "D0W5WA",
    names: ["Phylomer Peptides"],
  },
  {
    primary_id: "D0W5WG",
    names: [
      "JNJ-1661010",
      "681136-29-8",
      "JNJ 1661010",
      "JNJ1661010",
      "N-Phenyl-4-(3-Phenyl-1,2,4-Thiadiazol-5-Yl)Piperazine-1-Carboxamide",
      "UNII-62521S57AU",
      "1-Piperazinecarboxamide, N-Phenyl-4-(3-Phenyl-1,2,4-Thiadiazol-5-Yl)-",
      "N-PHENYL-4-(3-PHENYL-1,2,4-THIADIAZOL-5-YL)-1-PIPERAZINECARBOXAMIDE",
      "CHEMBL460273",
      "62521S57AU",
      "BHBOSTKQCZEAJM-UHFFFAOYSA-N",
      "ACMC-20emff",
      "AC1MDCTI",
      "AC1Q5LMB",
      "Oprea1_746619",
      "MLS001111246",
      "SCHEMBL1164398",
      "GTPL5206",
      "CTK1J2536",
      "Cid_2809273",
      "DTXSID00384599",
      "MolPort-001-806-365",
      "HMS3651L15",
      "HMS1662H10",
      "HMS2801K13",
      "BCP06935",
      "EX-A2218",
    ],
  },
  {
    primary_id: "D0W5WH",
    names: ["ET-004"],
  },
  {
    primary_id: "D0W5XD",
    names: ["EC18", "SCHEMBL1028017", "GTPL6262"],
  },
  {
    primary_id: "D0W5XT",
    names: ["ARC-183", "Anti-Thrombin Aptamer (CABG Surgery), Archemix/Nuvelo"],
  },
  {
    primary_id: "D0W5YC",
    names: ["Vacc-5q"],
  },
  {
    primary_id: "D0W5YP",
    names: ["WVE-120101"],
  },
  {
    primary_id: "D0W5YY",
    names: ["PM-060184"],
  },
  {
    primary_id: "D0W5ZK",
    names: ["L-767679"],
  },
  {
    primary_id: "D0W5ZQ",
    names: ["PMID27551786-Compound-I"],
  },
  {
    primary_id: "D0W5ZU",
    names: ["Antova"],
  },
  {
    primary_id: "D0W6AB",
    names: ["NC-100150", "Clariscan"],
  },
  {
    primary_id: "D0W6AC",
    names: ["PMID23357634C24d"],
  },
  {
    primary_id: "D0W6AF",
    names: ["YM-264"],
  },
  {
    primary_id: "D0W6AQ",
    names: ["TZP-201"],
  },
  {
    primary_id: "D0W6BS",
    names: ["PRO-2000"],
  },
  {
    primary_id: "D0W6CA",
    names: [
      "Cefpodoxime",
      "CPDX",
      "Cefpodoxima",
      "Cefpodoximum",
      "Epoxim",
      "Cefpodoxim Acid",
      "Cefpodoxima [Spanish]",
      "Cefpodoximum [Latin]",
      "RU 51807",
      "Cefpodoxime (INN)",
      "Cefpodoxime [INN:BAN]",
      "Epoxim (TN)",
      "Vantin (TN)",
      "(6R,7R)-7-({(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(Methyloxy)Imino]acetyl}amino)-3-[(Methyloxy)Methyl]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2E)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-Methoxyiminoacetyl]amino]-3-(Methoxymethyl)-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-Methoxyiminoacetyl]amino]-3-(Methoxymethyl)-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-{[(2-Amino-1,3-Thiazol-4-Yl)(Methoxyimino)Acetyl]amino}-3-(Methoxymethyl)-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0W6CE",
    names: ["10-Hydroxy-18-Methoxybetaenone", "CHEMBL498247", "BDBM50269144"],
  },
  {
    primary_id: "D0W6CJ",
    names: ["C[-Arg-Gly-Asp-Acpca20-]", "CHEMBL540618"],
  },
  {
    primary_id: "D0W6DG",
    names: ["Pentazocine"],
  },
  {
    primary_id: "D0W6DY",
    names: [
      "Diphenylpyraline",
      "Allergen",
      "Belfene",
      "Dafen",
      "Dayfen",
      "Diafen",
      "Difenilpiralina",
      "Diphenylpyralamine",
      "Diphenylpyralinum",
      "Diphenylpyrilene",
      "Hispril",
      "Histryl",
      "Histyn",
      "Hystryl",
      "Lergobine",
      "Lyssipoll",
      "Mepiben",
      "Neargal",
      "AN 1041",
      "Difenilpiralina [INN-Spanish]",
      "Diphenylpyraline (INN)",
      "Diphenylpyraline [INN:BAN]",
      "Diphenylpyralinum [INN-Latin];Diphenylpyraline Maleate (2:1)",
      "N-Methylpiperidyl-(4)-Benzhydrylaether Salzsauren Salze",
      "N-Methylpiperidyl-(4)-Benzhydrylaether Salzsauren Salze [German]",
      "1-Methyl-4-Piperidyl Benzhydryl Ether",
      "1-Methyl-4-Hydroxypiperidine Benzhydryl Ether",
      "4-(Benzhydryloxy)-1-Methylpiperidine",
      "4-(Diphenylmethoxy)-1-Methylpiperidine",
      "4-(Benzhydryloxy)-N-Methylpiperidine",
      "4-DIPHENYLMETHOXY-1-METHYLPIPERIDINE",
      "4-[(Diphenylmethyl)Oxy]-1-Methylpiperidine",
      "4-Benzhydryloxy-1-Methylpiperidine",
    ],
  },
  {
    primary_id: "D0W6EP",
    names: ["AMG-889436"],
  },
  {
    primary_id: "D0W6ER",
    names: ["Alzheimers Disease Therapy, SK Life Science"],
  },
  {
    primary_id: "D0W6EV",
    names: ["ICGD"],
  },
  {
    primary_id: "D0W6FC",
    names: ["8-Bromo-9-(2-Hydroxypropyl)-9H-Adenine", "CHEMBL475617", "98411-78-0"],
  },
  {
    primary_id: "D0W6GB",
    names: [
      "Benzomate",
      "Benzoxamate",
      "Acarmate",
      "Artaban",
      "Citrazon",
      "Azomate",
      "Benzoximate [BSI:ISO]",
      "UNII-32TAI9ZK5N",
      "32TAI9ZK5N",
      "NA-53M",
      "NA-53",
      "EINECS 249-439-1",
      "BRN 2783319",
      "BENZOHYDROXAMIC ACID, O-BENZOYL-3-CHLORO-2,6-DIMETHOXY-N-ETHYL-",
      "Benzoic Acid, Anhydride With 3-Chloro-N-Ethoxy-2,6-Dimethoxybenzimidic Acid",
      "AC1L1RMU",
      "67011-39-6",
      "SCHEMBL9806359",
      "HOERQTQCTISLFR-UHFFFAOYSA-N",
      "LS-35432",
      "C19021",
      "N-(Benzoyloxy)-3-Chloro-N-Ethyl-2,6-Dimethoxybenzamide",
      "Benzoic Acid Anhydride With 3-Chloro-N-Ethoxy-2,6-Dimethoxybenzenecarboximidic Acid (9CI)",
      "Benzoic Aci",
    ],
  },
  {
    primary_id: "D0W6GE",
    names: ["N-(Naphthalen-1-Yl)-4-Neopentylthiazol-2-Amine", "CHEMBL424689", "SCHEMBL14433281"],
  },
  {
    primary_id: "D0W6GR",
    names: ["H1N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0W6HB",
    names: [
      "4-(4-Phenoxybenzoyl)Benzoic Acid",
      "CHEMBL36392",
      "SCHEMBL7822422",
      "BDBM50180894",
      "4-(4-Phenoxy-Benzoyl)-Benzoic Acid",
    ],
  },
  {
    primary_id: "D0W6HI",
    names: ["PMID24775305C7u"],
  },
  {
    primary_id: "D0W6HL",
    names: ["Beta-Phe Compound 1"],
  },
  {
    primary_id: "D0W6HO",
    names: ["IMO-2055"],
  },
  {
    primary_id: "D0W6KM",
    names: ["Dasotraline"],
  },
  {
    primary_id: "D0W6LI",
    names: [
      "Cosyntropin",
      "Cortrosyn",
      "Tetracosactide",
      "Cortrosyn (TN)",
      "Cosyntropin (USAN)",
      "Tetracosactide (INN)",
    ],
  },
  {
    primary_id: "D0W6LJ",
    names: [
      "1-Benzyl-5'-O-Tritylinosine",
      "CHEMBL441540",
      "5'-O-Trityl-1-Benzylinosine",
      "1-Benzyl-5''-O-Tritylinosine",
      "AUYLAWYEMLZGBM-QSYCCZFCSA-N",
    ],
  },
  {
    primary_id: "D0W6LW",
    names: [
      "BIT225",
      "BIT-225",
      "917909-71-8",
      "SCHEMBL3109704",
      "CHEMBL1242296",
      "WVROWPPEIMRGAB-UHFFFAOYSA-N",
      "ZINC43151503",
      "2-Naphthalenecarboxamide, N-(Aminoiminomethyl)-5-(1-Methyl-1H-Pyrazol-4-Yl)-",
      "SB16851",
      "5-(1-Methyl-1H-Pyrazol-4-Yl)-2-Naphthoylguanidine",
    ],
  },
  {
    primary_id: "D0W6MA",
    names: ["E-2050"],
  },
  {
    primary_id: "D0W6NW",
    names: ["SD-118", "DRP-043", "NSL-043"],
  },
  {
    primary_id: "D0W6OB",
    names: ["ASB Formulation", "ASB Formulation (Oral, Cocaine Addiction)"],
  },
  {
    primary_id: "D0W6OP",
    names: ["H-Tyr-C[D-Cys-Gly-Phe-D-Cys]NH2", "CHEMBL220327"],
  },
  {
    primary_id: "D0W6PE",
    names: ["Dihydropyrimidinone Derivative 1"],
  },
  {
    primary_id: "D0W6PM",
    names: ["CNV-NT"],
  },
  {
    primary_id: "D0W6PP",
    names: ["Galnon"],
  },
  {
    primary_id: "D0W6PT",
    names: ["Chlorphrifos Oxon", "Diethyl 3,5,6-Trichloropyridin-2-Yl Phosphate"],
  },
  {
    primary_id: "D0W6QN",
    names: ["Choroid Plexus Cell Therapy"],
  },
  {
    primary_id: "D0W6RV",
    names: ["WAY-163909"],
  },
  {
    primary_id: "D0W6SS",
    names: ["Choriogonadotropin Alfa"],
  },
  {
    primary_id: "D0W6TB",
    names: ["MK-7285"],
  },
  {
    primary_id: "D0W6TG",
    names: ["PSB-09120", "CHEMBL486478", "BDBM50268151"],
  },
  {
    primary_id: "D0W6TX",
    names: ["N-(4-Iodophenyl)-4-(Trifluoromethoxy)Benzamide", "CHEMBL252013", "AC1OW6PA"],
  },
  {
    primary_id: "D0W6UV",
    names: ["FP-289"],
  },
  {
    primary_id: "D0W6VA",
    names: [
      "4-(1H-IMIDAZOL-1-YL)PHENOL",
      "4-(Imidazol-1-Yl)Phenol",
      "10041-02-8",
      "4-(1h-Imidazol-1-Yl)Phenol",
      "4-Imidazol-1-Yl-Phenol",
      "4-(1-Imidazolyl)Phenol",
      "Phenol, 4-(1H-Imidazol-1-Yl)-",
      "1-(P-Hydroxyphenyl)Imidazole",
      "1-(4-Hydroxyphenyl)Imidazole",
      "P-(1-Imidazolyl)Phenol",
      "CYKCUAPYWQDIKR-UHFFFAOYSA-N",
      "4-Imidazolylphenol",
      "(1H&amp",
      "Imidazol&amp",
      "1&amp",
      "Yl)Phenol (Compound 1)",
      "EINECS 233-121-4",
      "4-Imidazol-1-Ylphenol",
      "4m5r",
      "P-(Imidazol-1-Yl)Phenol",
      "ACMC-209vy5",
      "AC1Q78RW",
      "AC1Q4YG7",
      "4-(1H-Imidazol-Yl)Phenol",
      "4(1H-Imidazol-1-Yl)Phenol",
    ],
  },
  {
    primary_id: "D0W6WC",
    names: ["Apolizumab"],
  },
  {
    primary_id: "D0W6WD",
    names: ["KP106"],
  },
  {
    primary_id: "D0W6WL",
    names: ["1-(1,3-Diphenylpropyl)Piperazine", "CHEMBL209707", "SCHEMBL5444533"],
  },
  {
    primary_id: "D0W6WU",
    names: ["Cyclo[Ac-Cys-Ile-Phe]-Lys-Tyr-Tyr"],
  },
  {
    primary_id: "D0W6XL",
    names: ["SB-209670"],
  },
  {
    primary_id: "D0W6XV",
    names: ["AbGn-7"],
  },
  {
    primary_id: "D0W6YE",
    names: ["2-Iodo-[125I]5MCA-NAT", "2-Iodo-[125I]-5MCA-NAT"],
  },
  {
    primary_id: "D0W6YQ",
    names: ["SB Therapeutics Induced Gene Therapy, Ischemia"],
  },
  {
    primary_id: "D0W6YR",
    names: ["PF-06817024"],
  },
  {
    primary_id: "D0W6YS",
    names: ["Vaccine, Lyme Disease"],
  },
  {
    primary_id: "D0W6YX",
    names: [
      "(3,4-Dimethylphenyl)(4-Phenoxyphenyl)Methanone",
      "CHEMBL557722",
      "CBDivE_001397",
      "AC1MOFBF",
      "AC1Q2DSA",
      "Oprea1_371703",
      "Oprea1_274103",
      "MolPort-001-813-132",
      "(3,4-DIMETHYL-PHENYL)-(4-PHENOXY-PHENYL)-METHANONE",
      "ZINC2496519",
      "STK333119",
      "BDBM50296685",
      "AKOS024330770",
      "MCULE-5159255282",
    ],
  },
  {
    primary_id: "D0W6ZF",
    names: ["Sevoflurane"],
  },
  {
    primary_id: "D0W6ZO",
    names: ["PMID26815044-Compound-30"],
  },
  {
    primary_id: "D0W6ZS",
    names: ["Pyrrolo-Benzo-Diazine Derivative 1"],
  },
  {
    primary_id: "D0W6ZV",
    names: ["HF-0420"],
  },
  {
    primary_id: "D0W7AB",
    names: ["Anti-BCMA CAR T Cells"],
  },
  {
    primary_id: "D0W7AE",
    names: ["HL-018"],
  },
  {
    primary_id: "D0W7BI",
    names: ["(2,5-Diphenyl-Furan-3-Yl)-Phenyl-Methanone", "2,5-Diphenyl-3-Benzoylfuran", "CHEMBL109280"],
  },
  {
    primary_id: "D0W7CF",
    names: [
      "Ac-RYYRIK-NH2",
      "200959-48-4",
      "CHEMBL437723",
      "Ac-Arg-Tyr-Tyr-Arg-Ile-Lys-NH(2)",
      "Acetyl-Arg-Tyr-Tyr-Arg-Ile-Lys-NH(2)",
      "Ac-RYYRIK-NH",
      "BDBM85192",
      "BDBM50190305",
      "ZINC169289387",
      "AKOS024456481",
      "CA-1321",
      "CAS_200959-48-4",
    ],
  },
  {
    primary_id: "D0W7CR",
    names: ["EZN-3889"],
  },
  {
    primary_id: "D0W7DD",
    names: ["BAY-44-3428", "APC-1167"],
  },
  {
    primary_id: "D0W7DK",
    names: ["ISIS 110064"],
  },
  {
    primary_id: "D0W7DM",
    names: ["2-THIOSALVINORIN B", "2-Thiosalvinorin B", "CHEMBL231536", "SCHEMBL13415898"],
  },
  {
    primary_id: "D0W7DP",
    names: ["TDI-0107"],
  },
  {
    primary_id: "D0W7FC",
    names: ["MLN-2704"],
  },
  {
    primary_id: "D0W7FG",
    names: [
      "ALF-421",
      "CORM-A1",
      "Macromolecular Carrier-Linked Carbon Monoxide Releasing Molecules (Inflammation)",
      "Carbon Monoxide-Releasing Molecules (Inflammatory Diseases), Alfama",
      "Macromolecular Carrier-Linked CORMs (Inflammation), Alfama",
      "Macromolecular Carrier-Linked Carbon Monoxide Releasing Molecules (Inflammation), Alfama",
    ],
  },
  {
    primary_id: "D0W7FS",
    names: ["PMID29338548-Compound-21"],
  },
  {
    primary_id: "D0W7GH",
    names: ["IC-747"],
  },
  {
    primary_id: "D0W7GQ",
    names: ["EGIS-7625"],
  },
  {
    primary_id: "D0W7GU",
    names: [
      "2,5-Dimethoxy-4'-Amino-Trans-Stilbene",
      "BRN 2855929",
      "4-(2,5-Dimethoxy)Stilbenamine",
      "4'-Stilbenamine, 2,5-Dimethoxy-",
      "4-(2,5-Dimethoxyphenethyl)Aniline",
      "2,5-Dimethoxy-4'-Aminostilbene(Trans)",
      "4'-AMINO-2,5-DIMETHOXY STILBENE",
      "CHEMBL1173699",
      "4-(2-(2,5-Dimethoxyphenyl)Ethyl)Benzenamine",
      "Stilbenamine, 2',5'-Dimethoxy-",
      "2',5'-Dimethoxy-4-Stilbenamine",
      "23435-31-6",
      "4-[(E)-2-(2,5-Dimethoxyphenyl)Ethenyl]aniline",
      "32180-65-7",
      "4-[2-(2,5-Dimethoxyphenyl)Vinyl]aniline",
      "4-[2-(2,5-Dimethoxyphenyl)Ethenyl]aniline",
      "2,5-Dimethoxy-4'-Aminostilbene",
      "5803-51-0",
      "DMBAS",
      "AC1NTB1S",
    ],
  },
  {
    primary_id: "D0W7HE",
    names: [
      "BYL719",
      "Alpelisib",
      "1217486-61-7",
      "BYL-719",
      "UNII-08W5N2C97Q",
      "BYL 719",
      "Alpelisib (BYL719)",
      "(S)-N1-(4-Methyl-5-(2-(1,1,1-Trifluoro-2-Methylpropan-2-Yl)Pyridin-4-Yl)Thiazol-2-Yl)Pyrrolidine-1,2-Dicarboxamide",
      "NVP-BYL719",
      "(2S)-N1-[4-Methyl-5-[2-(2,2,2-Trifluoro-1,1-Dimethylethyl)-4-Pyridinyl]-2-Thiazolyl]-1,2-Pyrrolidinedicarboxamide",
      "CHEMBL2396661",
      "08W5N2C97Q",
      "AK146107",
      "C19H22F3N5O2S",
      "(S)-N1-(4-Methyl-5-(2-(1,1,1-Trifluoro-2-Methylpropan-2-Yl)-Pyridin-4-Yl)Thiazol-2-Yl)Pyrrolidine-1,2-Dicarboxamide",
    ],
  },
  {
    primary_id: "D0W7HO",
    names: ["PMID29130358-Compound-LonimacranthoideVII"],
  },
  {
    primary_id: "D0W7IL",
    names: ["Thrombopoietin"],
  },
  {
    primary_id: "D0W7IN",
    names: ["6-Methoxy-3-Thiophen-3-Yl-Quinoline", "CHEMBL303184", "ZINC3832230"],
  },
  {
    primary_id: "D0W7IR",
    names: ["DMP-543"],
  },
  {
    primary_id: "D0W7JA",
    names: [
      "CALCEOLARIOSIDE B",
      "Calceolarioside B",
      "105471-98-5",
      "Calceorioside B",
      "CHEMBL518414",
      "CHEBI:68345",
      "Nuomioside A",
      "Calceolarioside-B",
      "AC1NRV68",
      "MEGxp0_000508",
      "ACon1_000407",
      "DTXSID70414938",
      "MolPort-001-740-673",
      "ZINC14512219",
      "BDBM50269516",
      "AKOS016010662",
      "MCULE-4912944466",
      "NCGC00169106-01",
      "AJ-64948",
      "KB-279900",
      "ST24046273",
      "Y0045",
      "3,4-Dihydroxyphenethyl 6-O-[3-(3,4-Dihydroxyphenyl)Propenoyl]-Beta-D-Glucopyranoside",
      "NCGC00169106-02![(2R,3S,4S,5R,6R)-6-[2-(3,4-Dihydroxyphenyl)Ethoxy]-3,4,5-Trihydroxyoxan-2-Yl]methyl (E)-3-(3,4-Dihydroxyphenyl)Prop-2-Enoate",
    ],
  },
  {
    primary_id: "D0W7JJ",
    names: ["2-(Methylsulfonyl)Naphthalene-1,4-Diol", "CHEMBL491752"],
  },
  {
    primary_id: "D0W7JZ",
    names: ["Tivozanib", "ASP4130/AV-951"],
  },
  {
    primary_id: "D0W7KD",
    names: [
      "FANDOFLOXACIN HYDROCHLORIDE",
      "DW-116",
      "Fandofloxacin Hydrochloride",
      "6-Fluoro-1-(5-Fluoropyridin-2-Yl)-7-(4-Methylpiperazin-1-Yl)-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid Hydrochloride",
    ],
  },
  {
    primary_id: "D0W7LK",
    names: ["2-Propanol, Isopropanol"],
  },
  {
    primary_id: "D0W7LO",
    names: ["PF9601N"],
  },
  {
    primary_id: "D0W7LW",
    names: ["Y-C[D-Pen-(2S,3S)-2-Me-(2')Nal-GSFC]KR-NH2", "CHEMBL414159", "BDBM50064843"],
  },
  {
    primary_id: "D0W7MD",
    names: ["Glucocorticoid Antagonists"],
  },
  {
    primary_id: "D0W7MG",
    names: ["PMID25991433-Compound-F1"],
  },
  {
    primary_id: "D0W7ND",
    names: [
      "2-Phenyl-3H-Imidazo[4,5-C]quinoline",
      "3H-Imidazo[4,5-C]quinoline,2-Phenyl-",
      "CHEMBL95085",
      "SCHEMBL1423662",
    ],
  },
  {
    primary_id: "D0W7NL",
    names: ["PF-06647263"],
  },
  {
    primary_id: "D0W7NV",
    names: ["TDI-0043"],
  },
  {
    primary_id: "D0W7NY",
    names: [
      "2-(7-Phenylheptanoyl)Oxazole-5-Carbonitrile",
      "CHEMBL220770",
      "2-(7-Phenylheptanoyl)-1,3-Oxazole-5-Carbonitrile",
      "SCHEMBL3144301",
      "JOWNZAGZZQQDKI-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0W7OE",
    names: [
      "Aprindine",
      "Aprindin",
      "Aprindina",
      "Aprindinum",
      "Aprinidine",
      "Fibocil",
      "AC 1802",
      "Aprindina [INN-Spanish]",
      "Aprindinum [INN-Latin]",
      "Aprindine (USAN/INN)",
      "Aprindine [USAN:BAN:INN]",
      "N-(3-(Diethylamino)Propyl)-N-Phenyl-2-Indanamine",
      "N,N-Diethyl-N'-2-Indanyl-N'-Phenyl-1,3-Propanediamine",
      "N,N-Diethyl-N'-2-Indanyl-N'-Phenyl-1,3-Propanediaminemonohydrochloride",
      "N'-(2,3-Dihydro-1H-Inden-2-Yl)-N,N-Diethyl-N'-Phenylpropane-1,3-Diamine",
      "N-(2,3-Dihydro-1H-Inden-2-Yl)-N',N'-Diethyl-N-Phenyl-1,3-Propanediamine",
      "N-(2,3-Dihydro-1H-Inden-2-Yl)-N',N'-Diethyl-N-Phenylpropane-1,3-Diamine",
    ],
  },
  {
    primary_id: "D0W7OG",
    names: ["HPMA-Gemcitabine Conjugate"],
  },
  {
    primary_id: "D0W7OR",
    names: ["2-((1H-Imidazol-4-Yl)Methyl)Pyridine", "CHEMBL81644", "BDBM50326296"],
  },
  {
    primary_id: "D0W7PP",
    names: [
      "ST-0701",
      "Acetylsalicylic Acid Prodrug (Cardiovascular Disease), Solvotron",
      "Aspirin Prodrug (Cardiovascular Disease), Solvotrin",
    ],
  },
  {
    primary_id: "D0W7PQ",
    names: ["Glufosfomide"],
  },
  {
    primary_id: "D0W7RI",
    names: ["Carbacyclin", "Carboprostacyclin"],
  },
  {
    primary_id: "D0W7RJ",
    names: ["Hetacillin"],
  },
  {
    primary_id: "D0W7RZ",
    names: ["ST-05"],
  },
  {
    primary_id: "D0W7SX",
    names: ["CNTO-3649"],
  },
  {
    primary_id: "D0W7TK",
    names: ["P-1118"],
  },
  {
    primary_id: "D0W7TQ",
    names: ["IDX-719"],
  },
  {
    primary_id: "D0W7TU",
    names: ["NSC-73306"],
  },
  {
    primary_id: "D0W7UC",
    names: [
      "VAL-101",
      "BCL2-Targeting Cancer Therapy (GeneICE), Chronos/Cancer Research Technology",
      "BCL2-Targeting Cancer Therapy (GeneICE), ValiPharma/Cancer Research Technology",
    ],
  },
  {
    primary_id: "D0W7UD",
    names: ["Anti-PD-L1 CSR T Cells"],
  },
  {
    primary_id: "D0W7UN",
    names: ["BI-409306"],
  },
  {
    primary_id: "D0W7UO",
    names: [
      "CGP71683A",
      "CGP-71683A",
      "N-[[4-[[(4-Aminoquinazolin-2-Yl)Amino]methyl]cyclohexyl]methyl]naphthalene-1-Sulfonamide",
    ],
  },
  {
    primary_id: "D0W7VE",
    names: ["Biphenyl Mannoside Derivative 20"],
  },
  {
    primary_id: "D0W7VG",
    names: ["AMG 108"],
  },
  {
    primary_id: "D0W7VQ",
    names: [
      "Imab-468",
      "Anti-GT468 MAbs (Cancer), Ganymed",
      "Anti-GT468 Monoclonal Antibodies (Cancer), Ganymed",
      "Anti-PLAC-1 Monoclonal Antibodies (Cancer), Ganymed",
      "Anti-PLAC-1mAbs (Cancer), Ganymed",
    ],
  },
  {
    primary_id: "D0W7VX",
    names: ["Statin"],
  },
  {
    primary_id: "D0W7WC",
    names: ["Ivacaftor"],
  },
  {
    primary_id: "D0W7WG",
    names: ["Hormone-Dependent Vaccine"],
  },
  {
    primary_id: "D0W7WV",
    names: ["L-869298"],
  },
  {
    primary_id: "D0W7XH",
    names: ["DR2313"],
  },
  {
    primary_id: "D0W7XK",
    names: [
      "7-Fluoro-2-(4-Methoxyphenyl)-4H-Chromen-4-One",
      "CHEMBL599149",
      "7-Fluoro-2-(4-Methoxyphenyl)Chromen-4-One",
      "AC1LAD85",
      "4'-Methoxy-7-Fluoroflavanone",
      "BDBM50310186",
      "MB10522",
    ],
  },
  {
    primary_id: "D0W7XL",
    names: ["9-O-[3-(Phenylol-1-Yloxy)Propyl]berberine Bromide", "CHEMBL602663"],
  },
  {
    primary_id: "D0W7XR",
    names: ["LY-2183240"],
  },
  {
    primary_id: "D0W7XX",
    names: [
      "HS-HCV",
      "Antiviral Therapy (HCV Infection, HeatShock), Heat Biologics",
      "Endoplasmin Modulator (Hepatitis C Virus Infection, HeatShock/Fusion Protein/Antigen), Heat Biologics",
      "Gp-96-Ig + Unspecified Viral Antigen Secreting Live Cell Vaccine (Hepatitis C Virus Infection, HeatShock), Heat Biologics",
    ],
  },
  {
    primary_id: "D0W7YA",
    names: ["PP-202"],
  },
  {
    primary_id: "D0W7ZB",
    names: ["N-(3-(2,5-Dimethoxyphenyl)Propyl)Acetamide", "CHEMBL1092400"],
  },
  {
    primary_id: "D0W7ZM",
    names: ["8-(3-Methyl-Butoxy)-Quinolin-2-Ylamine", "CHEMBL184476", "SCHEMBL5884316"],
  },
  {
    primary_id: "D0W7ZN",
    names: ["SIM-916"],
  },
  {
    primary_id: "D0W7ZS",
    names: ["Del 60 Tetramer"],
  },
  {
    primary_id: "D0W8AD",
    names: [
      "1-[7-(Triphenylmethoxy)Heptyl]thymine",
      "CHEMBL219367",
      "921587-94-2",
      "CTK3G1843",
      "DTXSID20582671",
      "BDBM50200997",
      "5-Methyl-1-[7-(Triphenylmethoxy)Heptyl]pyrimidine-2,4(1H,3H)-Dione",
      "2,4(1H,3H)-Pyrimidinedione, 5-Methyl-1-[7-(Triphenylmethoxy)Heptyl]-",
    ],
  },
  {
    primary_id: "D0W8AG",
    names: ["TPIV 100/110 Vaccine"],
  },
  {
    primary_id: "D0W8AI",
    names: ["Andromustine"],
  },
  {
    primary_id: "D0W8BJ",
    names: ["Trans-3-(O-Tolyloxy)-2,3-Dihydro-1H-Inden-1-Amine", "CHEMBL486043"],
  },
  {
    primary_id: "D0W8BL",
    names: ["PMID25991433-Compound-P5"],
  },
  {
    primary_id: "D0W8BO",
    names: ["RA101495"],
  },
  {
    primary_id: "D0W8BP",
    names: ["C[Cpa19-Lys26][Gly8]GLP-1(7-37)-NH2"],
  },
  {
    primary_id: "D0W8CA",
    names: [
      "Genz-667348",
      "DHODH Inhibitors (Malaria)",
      "DHODH Inhibitors (Malaria), Genzyme/Medicines For Malaria Venture",
      "Dihydroorotate Dehydrogenase Inhibitors (Malaria), Genzyme/Medicines For Malaria Venture",
    ],
  },
  {
    primary_id: "D0W8CW",
    names: ["D-5519"],
  },
  {
    primary_id: "D0W8DA",
    names: ["BI-54903"],
  },
  {
    primary_id: "D0W8DG",
    names: ["L-19-PDT"],
  },
  {
    primary_id: "D0W8DN",
    names: ["PMID27774822-Compound-Figure10Example19"],
  },
  {
    primary_id: "D0W8EB",
    names: ["3-(3-Dimethylamino-Propyl)-1H-Indol-4-Ol", "CHEMBL198617"],
  },
  {
    primary_id: "D0W8EC",
    names: ["Dihydropyrido Phthalazinone Derivative 3"],
  },
  {
    primary_id: "D0W8EP",
    names: ["SDZ-205-557", "137196-67-9"],
  },
  {
    primary_id: "D0W8FE",
    names: ["CV-301"],
  },
  {
    primary_id: "D0W8FK",
    names: ["Idelalisib"],
  },
  {
    primary_id: "D0W8FQ",
    names: ["2-Thioethenamine", "AC1NXIBI", "(E)-2-Aminoethenethiol"],
  },
  {
    primary_id: "D0W8FU",
    names: ["Autologous Cartilage System", "BioCart (TN)"],
  },
  {
    primary_id: "D0W8GP",
    names: [
      "DP-3590",
      "DP-4157",
      "DP-4693",
      "DP-4756",
      "C-Met Kinase Inhibitors (Solid Tumor/Metastatic Cancer)",
      "C-Met Kinase Inhibitors (Solid Tumor/Metastatic Cancer), Deciphera",
    ],
  },
  {
    primary_id: "D0W8GT",
    names: ["SP160412"],
  },
  {
    primary_id: "D0W8GU",
    names: ["De-O-Sulfonated Kotalanol"],
  },
  {
    primary_id: "D0W8GY",
    names: ["Bromsulphthalein"],
  },
  {
    primary_id: "D0W8HN",
    names: ["Bavencio"],
  },
  {
    primary_id: "D0W8HP",
    names: ["MEDI-543"],
  },
  {
    primary_id: "D0W8HW",
    names: ["CAL-301"],
  },
  {
    primary_id: "D0W8IY",
    names: [
      "P-276",
      "CDK4 Inhibitor (Cancer), Nicholas Piramal",
      "P-664-02",
      "CDK4 Inhibitor (Iv, Cancer), Piramal Life Sciences",
      "CDK4 Inhibitor(Iv, Cancer), NPIL Research & Development",
    ],
  },
  {
    primary_id: "D0W8IZ",
    names: ["1-(4-Chlorobenzyl)-5-Phenyl-1H-Imidazole", "CHEMBL597101", "SCHEMBL1949527"],
  },
  {
    primary_id: "D0W8JE",
    names: [
      "Depudecin",
      "(1R)-1-[(2S,3S)-3-[(E)-2-[(3S)-3-[(1R)-1-Hydroxyethyl]oxiran-2-Yl]ethenyl]oxiran-2-Yl]prop-2-En-1-Ol",
      "139508-73-9",
    ],
  },
  {
    primary_id: "D0W8JK",
    names: ["PMID27336223-Compound-11"],
  },
  {
    primary_id: "D0W8JP",
    names: ["1-Phenethyl-3-[1,3,4]thiadiazol-2-Yl-Thiourea"],
  },
  {
    primary_id: "D0W8JY",
    names: ["IL-4"],
  },
  {
    primary_id: "D0W8KT",
    names: ["AVI-4065"],
  },
  {
    primary_id: "D0W8MN",
    names: ["Biaryl Thiophenes"],
  },
  {
    primary_id: "D0W8MP",
    names: ["H-Dmt-Tic-Glu-NH2"],
  },
  {
    primary_id: "D0W8MS",
    names: ["Injectable MPL Allergy Vaccine"],
  },
  {
    primary_id: "D0W8MX",
    names: ["Ac-Phe-Thr(PO3H2)-Pip-Nal-Gln-NH2", "CHEMBL383244"],
  },
  {
    primary_id: "D0W8NB",
    names: ["SC-005"],
  },
  {
    primary_id: "D0W8NK",
    names: ["9-Ethyl-2-Methyl-Beta-Carboline-2-Ium Iodide", "CHEMBL1097826"],
  },
  {
    primary_id: "D0W8NP",
    names: ["SAR-115740"],
  },
  {
    primary_id: "D0W8NW",
    names: ["ADCT-301"],
  },
  {
    primary_id: "D0W8NZ",
    names: ["SC-112"],
  },
  {
    primary_id: "D0W8OH",
    names: ["SKF-96231"],
  },
  {
    primary_id: "D0W8OJ",
    names: ["A-348441", "CHEMBL219684", "SCHEMBL699533", "BDBM50195155", "KB-3305"],
  },
  {
    primary_id: "D0W8PL",
    names: ["SMC-0006 Prodrug"],
  },
  {
    primary_id: "D0W8PU",
    names: ["Prohibitin Targeting Peptide-1"],
  },
  {
    primary_id: "D0W8QG",
    names: ["FR-229934"],
  },
  {
    primary_id: "D0W8QH",
    names: ["ONO-4817"],
  },
  {
    primary_id: "D0W8QV",
    names: ["FK-613"],
  },
  {
    primary_id: "D0W8RB",
    names: ["8-Bromo-9-(But-3-Enyl)-9H-Adenine", "CHEMBL508561"],
  },
  {
    primary_id: "D0W8RP",
    names: ["Monoisopropylphosphorylserine"],
  },
  {
    primary_id: "D0W8RS",
    names: ["C-(1-Azido-Alpha-D-Glucopyranosyl) Formamide"],
  },
  {
    primary_id: "D0W8RV",
    names: ["PMID26815044-Compound-116"],
  },
  {
    primary_id: "D0W8RX",
    names: ["Sitimagene Ceradenovec", "Cerepro", "EG-009", "HSV Thymidine Kinase Gene Therapy, Ark"],
  },
  {
    primary_id: "D0W8SB",
    names: [
      "Penbutolol",
      "Betapressin",
      "Levatol",
      "Levatolol",
      "Levopenbutol",
      "Lobeta",
      "Paginol",
      "Penbutololum",
      "PENBUTOLOL SULFATE",
      "HOE 893",
      "HOE 893d",
      "Betapressin (TN)",
      "Hostabloc (TN)",
      "L-Penbutolol",
      "Levatol (TN)",
      "Levatolol (TN)",
      "Lobeta (TN)",
      "Paginol (TN)",
      "Penbutolol (INN)",
      "Penbutolol [INN:BAN]",
      "Penbutololum [INN-Latin]",
      "Penbutolol Sulfate (2:1)",
      "S(-)-Penbutolol",
      "(-)-Penbutolol",
      "(2S)-1-(Tert-Butylamino)-3-(2-Cyclopentylphenoxy)Propan-2-Ol",
      "1-(Tert-Butylamino)-3-(O-Cyclopentylphenoxy)Propan-2-Ol",
    ],
  },
  {
    primary_id: "D0W8SJ",
    names: [
      "Minaprine",
      "Brantur",
      "Cantor",
      "Minaprina",
      "Minaprinum",
      "Minaprine Dihydrochloride",
      "AGR 1240",
      "CB 30038",
      "Cantor (TN)",
      "Minaprina [INN-Spanish]",
      "Minaprinum [INN-Latin]",
      "Minaprine (USAN/INN)",
      "Minaprine [USAN:BAN:INN]",
      "N-(4-Methyl-6-Phenyl-3-Pyridazinyl)-4-Morpholineethanamine",
      "3-(Morpholinoethyl)Amino-4-Methyl-6-Phenylpyridazine",
      "4-(2-((4-Methyl-6-Phenyl-3-Pyridazinyl)Amino)Ethyl)Morpholine",
      "4-Methyl-3-(2-Morpholinoethylamino)-6-Phenylpyridazin",
      "4-Methyl-N-(2-Morpholin-4-Ylethyl)-6-Phenylpyridazin-3-Amine",
    ],
  },
  {
    primary_id: "D0W8SX",
    names: [
      "Beta-Alanine",
      "107-95-9",
      "3-Aminopropanoic Acid",
      "3-Aminopropionic Acid",
      "Beta Alanine",
      "Abufene",
      "H-Beta-Ala-OH",
      "Beta-Aminopropionic Acid",
      "2-Carboxyethylamine",
      ".beta.-Alanine",
      "Propanoic Acid, 3-Amino-",
      "Alanine, Beta-",
      "3-Aminopropionsaeure",
      "Beta-Ala",
      "Beta-Aminopropionsaeure",
      "3-Aminopropanoate",
      "Omega-Aminopropionic Acid",
      "B-ALANINE",
      "FEMA No. 3252",
      "3-Amino-Propionic Acid",
      "Beta-Alaine",
      "AI3-18470",
      ".beta.-Aminopropionic Acid",
      "NSC 7603",
      "UNII-11P2JDE17B",
      "Beta-Alanine, 99%",
      "EINECS 203-536-5",
      "MFCD00008200",
      "11P2JDE17B",
      "CHEMBL297569",
      "CHEBI:16958",
      "NSC7603",
    ],
  },
  {
    primary_id: "D0W8TQ",
    names: ["SL-701"],
  },
  {
    primary_id: "D0W8TS",
    names: ["SNF-9007"],
  },
  {
    primary_id: "D0W8UV",
    names: ["NP-313"],
  },
  {
    primary_id: "D0W8UW",
    names: [
      "AMG-628",
      "UNII-G8JW58493W",
      "G8JW58493W",
      "N-{4-[(6-{4-[(1R)-1-(4-Fluorophenyl)Ethyl]piperazin-1-Yl}pyrimidin-4-Yl)Oxy]-1,3-Benzothiazol-2-Yl}acetamide",
      "862269-93-0",
      "AMG 628",
      "AMG628",
      "AMG-628, (R)-",
      "SCHEMBL3841997",
      "GTPL4130",
      "CHEMBL226574",
      "BDBM20581",
      "QUHZTEMPQQZPNB-MRXNPFEDSA-N",
      "MolPort-029-996-161",
      "KS-00003MQ7",
      "Piperazinylpyrimidine Analogue, 16p",
      "ZINC14974185",
      "AKOS022193590",
      "MB-0047",
      "Acetamide, N-(4-((6-(4-((1R)-1-(4-Fluorophenyl)Ethyl)-1-Piperazinyl)-4-Pyrimidinyl)Oxy)-2-Benzothiazolyl)-",
      "UNII-28J966TN3X Component QUHZTEMPQQZPNB-M",
    ],
  },
  {
    primary_id: "D0W8VC",
    names: ["PA-1637", "Copper Chelating Agents (Neurodegenerative Disease), Palumed"],
  },
  {
    primary_id: "D0W8VI",
    names: ["Co-122739", "Co-121068"],
  },
  {
    primary_id: "D0W8VX",
    names: ["NSC-180969"],
  },
  {
    primary_id: "D0W8VY",
    names: [
      "A-86719.1",
      "ABT-719",
      "AC1OCF8N",
      "8-[(3S)-3-Aminopyrrolidin-1-Yl]-1-Cyclopropyl-7-Fluoro-9-Methyl-4-Oxoquinolizine-3-Carboxylic Acid Hydrate Hydrochloride",
    ],
  },
  {
    primary_id: "D0W8WB",
    names: [
      "Berberine",
      "2086-83-1",
      "Berberin",
      "Umbellatine",
      "UNII-0I8Y3P32UF",
      "0I8Y3P32UF",
      "CHEBI:16118",
      "EINECS 218-229-1",
      "Berberal",
      "BRN 3570374",
      "ST055798",
      "9,10-Dimethoxy-2,3-(Methylenedioxy)-7,8,13,13a-Tetrahydroberbinium",
      "Benzo(G)-1,3-Benzodioxolo(5,6-A)Quinolizinium, 5,6-Dihydro-9,10-Dimethoxy-",
      "9,10-Dimethoxy-5,6-Dihydro[1,3]dioxolo[4,5-G]isoquino[3,2-A]isoquinolin-7-Ium",
      "Berbamine Sulphate Acid",
      "CHEMBL12089",
      "7,8,13,13a-Tetradehydro-9,10-Dimethoxy-2,3-(Methylenedioxy)Berbinium",
      "BERBINIUM, 7,8,13,13a-TETRAHYDRO-9,10-DIMETHOXY-2,3-(METHYLE",
    ],
  },
  {
    primary_id: "D0W8WG",
    names: ["CD19-Targeting CAR T Cells"],
  },
  {
    primary_id: "D0W8WZ",
    names: ["PMID:18445527C67"],
  },
  {
    primary_id: "D0W8XH",
    names: ["PI-103"],
  },
  {
    primary_id: "D0W8XI",
    names: ["Hexahydrodifenidol"],
  },
  {
    primary_id: "D0W8XM",
    names: ["PMID27019002-Compound-44"],
  },
  {
    primary_id: "D0W8XT",
    names: ["Mibefradil"],
  },
  {
    primary_id: "D0W8YE",
    names: ["BED"],
  },
  {
    primary_id: "D0W8YI",
    names: ["CC-93269"],
  },
  {
    primary_id: "D0W8YO",
    names: ["4-(2-Fluoro-6-(2-Fluorophenoxy)Phenyl)Piperidine", "CHEMBL598346"],
  },
  {
    primary_id: "D0W8YY",
    names: ["HI-Con1"],
  },
  {
    primary_id: "D0W8YZ",
    names: ["IPZ-010"],
  },
  {
    primary_id: "D0W8ZE",
    names: ["G3139 + Dexamethasone"],
  },
  {
    primary_id: "D0W8ZW",
    names: ["PA-342"],
  },
  {
    primary_id: "D0W9AB",
    names: ["Imidazopyridazine Derivative 6"],
  },
  {
    primary_id: "D0W9AI",
    names: ["MTC-DOX"],
  },
  {
    primary_id: "D0W9BD",
    names: ["JWH-314"],
  },
  {
    primary_id: "D0W9BF",
    names: ["RPR-101511"],
  },
  {
    primary_id: "D0W9BH",
    names: [
      "N-Hydroxy-E-3-(4'-Cyanobiphenyl-4-Yl)-Acrylamide",
      "CHEMBL538710",
      "SCHEMBL3292721",
      "SCHEMBL3292715",
      "BDBM50293355",
    ],
  },
  {
    primary_id: "D0W9CU",
    names: ["HLM-008182"],
  },
  {
    primary_id: "D0W9GA",
    names: [
      "Levormeloxifene Non-Steroidal",
      "Centchroman",
      "Centron",
      "Levormeloxifene",
      "Ormeloxifene",
      "Saheli",
      "NNC-46-0020",
      "Non-Steroidal (Oral, Contraception/Cancer)",
      "Non-Steroidal (Oral, Contraception/Cancer), Central Drug Research Institute (CDRI)",
    ],
  },
  {
    primary_id: "D0W9GL",
    names: ["Carbamate Derivative 7"],
  },
  {
    primary_id: "D0W9IH",
    names: [
      "Ad35-GRIN",
      "Adenovirus Serotype 35 Vector-Delivered Gag/Rt/Int/Nef Vaccine (HIV Infection), International AIDS Vaccine Initiative",
    ],
  },
  {
    primary_id: "D0W9II",
    names: ["EISAI-1"],
  },
  {
    primary_id: "D0W9IT",
    names: ["NXN-462"],
  },
  {
    primary_id: "D0W9IZ",
    names: ["PF-06273588"],
  },
  {
    primary_id: "D0W9JC",
    names: [
      "1-(5-(Pyridin-2-Yl)Oxazol-2-Yl)Dodecan-1-One",
      "CHEMBL177757",
      "SCHEMBL3184225",
      "VESZWTBYTDWAEO-UHFFFAOYSA-N",
      "BDBM50163165",
    ],
  },
  {
    primary_id: "D0W9JH",
    names: ["BMY-30123"],
  },
  {
    primary_id: "D0W9JK",
    names: ["(S)-N-Isobutyl-N-(Pyrrolidin-3-Yl)-2-Naphthamide", "CHEMBL567932", "SCHEMBL4634335"],
  },
  {
    primary_id: "D0W9JM",
    names: ["KNI-272"],
  },
  {
    primary_id: "D0W9JP",
    names: [
      "Anti-Clostridium Perfringens Toxin Mabs",
      "Anti-Clostridium Perfringens Epsilon Toxin MAbs, Mapp Biopharmaceutical/University Of Arizona/University Of California",
      "Anti-Clostridium Perfringens Epsilon Toxin Monoclonal Antibodies, Mapp Biopharmaceutical/University Of Arizona/University Of California",
      "Anti-Clostridium Perfringens Toxin MAbs (Lyophilized/Injectable, Clostridium Infection)",
      "Anti-Clostridium Perfringens Toxin MAbs (Lyophilized/Injectable, Clostridium Infection), Mapp/UAZ/UC Davis",
    ],
  },
  {
    primary_id: "D0W9KE",
    names: ["AS-1708727"],
  },
  {
    primary_id: "D0W9KO",
    names: ["4-((1H-Indazol-6-Ylamino)Methyl)Benzene-1,2-Diol", "CHEMBL1240676", "BDBM50326004"],
  },
  {
    primary_id: "D0W9LJ",
    names: ["Metiamide"],
  },
  {
    primary_id: "D0W9LP",
    names: ["LC-9018"],
  },
  {
    primary_id: "D0W9LS",
    names: ["ACI-35"],
  },
  {
    primary_id: "D0W9LX",
    names: [
      "Carvedilol",
      "Artist",
      "Carvedilolum",
      "Coreg",
      "Coropres",
      "Coropress",
      "Dibloc",
      "Dilatrend",
      "Eucardic",
      "Kredex",
      "Querto",
      "Atlana Pharma Brand Of Carvedilol",
      "Carvedilolum [Latin]",
      "Coreg CR",
      "GlaxoSmithKline Brand Of Carvedilol",
      "Lakeside Brand Of Carvedilol",
      "Roche Brand Of Carvedilol",
      "BM 14190",
      "DQ 2466",
      "SKF 105517",
      "Artist (TN)",
      "BM-14190",
      "Carloc (TN)",
      "Coreg (TN)",
      "DQ-2466",
      "Dilatrend (TN)",
      "EG-P042",
      "Eucardic (TN)",
      "BM-14-190",
      "BM-14.190",
      "Carvedilol, 14C-Labeled",
      "SK&F-105517",
      "Carvedilol (JAN/USAN/INN)",
      "Carvedilol [USAN:INN:BAN:JAN]",
      "Carvedilol, (R)-Isomer",
      "Carvedilol, (S)-Isomer",
      "Carvedilol, (+-)-Isomer",
      "(+-)-1-(Carbazol-4-Yloxy)-3-((2-(O-Methoxyphenoxy)Ethyl)Amino)-2-Propanol",
      "(+-)-1-Carbazol-4-Yloxy)-3-((2-(O-Methoxyphenoxy)Ethyl)Amino)-2-Propanol",
      "(+/-)-1-(Carbazol-4-Yloxy)-3-[[2-(O-Methoxyphenoxy)Ethyl]amino]-2-Propanol",
      "1-(9H-Carbazol-4-Yloxy)-3-[[2-(2-Methoxyphenoxy)Ethyl]amino]-2-Propanol",
      "1-(9H-Carbazol-4-Yloxy)-3-[(2-{[2-(Methyloxy)Phenyl]oxy}ethyl)Amino]propan-2-Ol",
      "1-(9H-Carbazol-4-Yloxy)-3-[2-(2-Methoxyphenoxy)Ethylamino]propan-2-Ol",
      "1-(9H-Carbazol-4-Yloxy)-3-{[2-(2-Methoxyphenoxy)Ethyl]amino}propan-2-Ol",
    ],
  },
  {
    primary_id: "D0W9MJ",
    names: ["TPIV 110"],
  },
  {
    primary_id: "D0W9MM",
    names: ["Vinblastine"],
  },
  {
    primary_id: "D0W9NH",
    names: [
      "Tacedinaline",
      "Acetyldinaline",
      "Tacedinalina",
      "Goe 5549",
      "PD 123654",
      "Ci-994",
      "Goe-5549",
      "PD-123654",
      "Tacedinalina [INN-Spanish]",
      "Tacedinaline [USAN:INN]",
      "C.I. 994",
      "Tacedinaline (USAN/INN)",
      "4-(Acetylamino)-N-(2-Aminophenyl)Benzamide",
      "4-Acetamido-N-(2-Aminophenyl)Benzamide",
      "4-Acetamido-N-(2-Aminophenyl)Benzamid",
      "4-Acetylamino-N-(2'-Aminophenyl)Benzamide",
    ],
  },
  {
    primary_id: "D0W9NO",
    names: ["YT-146", "Adenosine A2 Agonists, Yamasa"],
  },
  {
    primary_id: "D0W9NZ",
    names: ["NO-Containing Nanoparticles"],
  },
  {
    primary_id: "D0W9OC",
    names: ["PMID29757691-Compound-7"],
  },
  {
    primary_id: "D0W9OR",
    names: ["OspA Lipoprotein"],
  },
  {
    primary_id: "D0W9OW",
    names: ["RAP-310"],
  },
  {
    primary_id: "D0W9PF",
    names: [
      "ANDROSTENEDONE",
      "Androsta-1,4-Diene-3,17-Dione",
      "Boldione",
      "1,4-Androstadiene-3,17-Dione",
      "897-06-3",
      "Androstadienedione",
      "1-Dehydroandrostenedione",
      "NSC 49080",
      "UNII-2166Q8568W",
      "EINECS 212-977-2",
      "ANDROSTA-1,4-DIEN-3,17-DIONE",
      "CHEBI:40799",
      "LUJVUUWNAPIQQI-QAGGRKNESA-N",
      "Androstra-1,4-Diene-3,17-Dione",
      "2166Q8568W",
      "(8R,9S,10R,13S,14S)-10,13-Dimethyl-7,8,9,11,12,14,15,16-Octahydro-6H-Cyclopenta[a]phenanthrene-3,17-Dione",
      "(8R,9S,10R,13S,14S)-10,13-Dimethyl-7,8,9,10,11,12,13,14,15,16-Decahydro-3H-Cyclopenta[a]phenanthrene-3,17(6H)-Dione",
      "ANB",
    ],
  },
  {
    primary_id: "D0W9PG",
    names: ["Pyrazinoylguanidine"],
  },
  {
    primary_id: "D0W9PY",
    names: ["PMID27215781-Compound-2"],
  },
  {
    primary_id: "D0W9QG",
    names: ["SBI-087"],
  },
  {
    primary_id: "D0W9QI",
    names: ["LErafAON"],
  },
  {
    primary_id: "D0W9RH",
    names: ["PMID28270010-Compound-Figure16-B-1"],
  },
  {
    primary_id: "D0W9RL",
    names: ["Corplex Memantine"],
  },
  {
    primary_id: "D0W9RM",
    names: [
      "4-Methyl-3-(2-Oxo-Azetidin-1-Yl)-Benzoic Acid",
      "CHEMBL124880",
      "88072-24-6",
      "4-Methyl-3-(2-Oxoazetidin-1-Yl)Benzoic Acid",
      "CTK3B8559",
      "DTXSID20539468",
      "BDBM50022785",
      "Benzoic Acid, 4-Methyl-3-(2-Oxo-1-Azetidinyl)-",
    ],
  },
  {
    primary_id: "D0W9RR",
    names: ["UNC1215"],
  },
  {
    primary_id: "D0W9SL",
    names: ["LY2523355"],
  },
  {
    primary_id: "D0W9SN",
    names: ["Pertuzumab + Trastuzumab"],
  },
  {
    primary_id: "D0W9SZ",
    names: ["T611"],
  },
  {
    primary_id: "D0W9TD",
    names: ["N-Oxo-2-(Phenylsulfonylamino)Ethanamide"],
  },
  {
    primary_id: "D0W9TE",
    names: ["Pentaerythritol Tetranitrate"],
  },
  {
    primary_id: "D0W9UV",
    names: [
      "2-Amino-4,6-Dimethylpyridine",
      "2-AMINO-4,6-DIMETHYLPYRIDINE",
      "5407-87-4",
      "4,6-Dimethylpyridin-2-Amine",
      "6-Amino-2,4-Lutidine",
      "2-Pyridinamine, 4,6-Dimethyl-",
      "4,6-Dimethyl-2-Pyridinamine",
      "4,6-Dimethyl-2-Pyridylamine",
      "Pyridine, 2-Amino-4,6-Dimethyl-",
      "2,4-Lutidine, 6-Amino-",
      "NSC 10731",
      "2-Amino-4,6-Dimehtylpyridine",
      "EINECS 226-470-9",
      "UNII-7NVZ9DW9Q5",
      "BRN 0002048",
      "7NVZ9DW9Q5",
      "CHEMBL59925",
      "4,6-Dimethyl-2-Aminopyridine",
      "2-Amino-4,6-Dimethyl Pyridine",
      "4,6-Dimethyl-Pyridin-2-Ylamine",
      "BRBUBVKGJRPRRD-UHFFFAOYSA-N",
      "2-Amino-4,6-Dimethylpyridine, 99%",
      "PubChem19956",
      "AC1Q2ICQ",
    ],
  },
  {
    primary_id: "D0W9VA",
    names: [
      "1,2-NAPHTHOQUINONE",
      "524-42-5",
      "1,2-Naphthalenedione",
      "Naphthalene-1,2-Dione",
      "O-Naphthoquinone",
      "Beta-Naphthoquinone",
      "1,2-Naphthaquinone",
      "1,2-Naftochinon",
      ".beta.-Naphthoquinone",
      "1,2-Naftochinon [Czech]",
      "CCRIS 1558",
      "UNII-804K62F61Q",
      "HSDB 2036",
      "1,2-Dihydronaphthalene-1,2-Dione",
      "NSC 9831",
      "EINECS 208-360-2",
      "1,2-Dihydro-1,2-Diketo-Naphthalene",
      "BRN 0606546",
      "CHEMBL52347",
      "MLS000069467",
      "AI3-14930",
      "CHEBI:34055",
      "C10H6O2",
      "KETQAJRQOHHATG-UHFFFAOYSA-N",
      "804K62F61Q",
      "SMR000059112",
      "1,2-Naphthoquinone, 95%, Tech.",
      "NAPHTHALENEDIONE",
      "B-Naphthoquinone",
    ],
  },
  {
    primary_id: "D0W9VB",
    names: [
      "Diphenidol",
      "Avomol",
      "Defenidol",
      "Difenidol",
      "Difenidolo",
      "Difenidolum",
      "Nometic",
      "Vontrol",
      "Difenidol HCl",
      "Difenidolo [DCIT]",
      "SKF 478",
      "Difenidolum [INN-Latin]",
      "Diphenidol [USAN:BAN]",
      "SK-478",
      "Diphenidol (USAN/INN)",
      "SK&F-478",
      "Alpha,alpha-Diphenyl-1-Piperidinebutanol",
      "Diphenyl(3-(1-Piperidyl)Propyl)Carbinol",
      "1,1-Diphenyl-4-Piperidin-1-Ylbutan-1-Ol",
      "1,1-Diphenyl-4-Piperidin-1-Ylbutan-1-Ol Hydrochloride",
    ],
  },
  {
    primary_id: "D0W9VC",
    names: ["4-[2-(2-Thienyl)Acetamido]benzenesulfonamide"],
  },
  {
    primary_id: "D0W9VF",
    names: ["Cyclopropylamine Derivative 10"],
  },
  {
    primary_id: "D0W9VN",
    names: [
      "VU0364770",
      "61350-00-3",
      "VU 0364770",
      "N-(3-Chlorophenyl)Picolinamide",
      "N-(3-Chlorophenyl)-2-Pyridinecarboxamide",
      "N-(3-Chlorophenyl)Pyridine-2-Carboxamide",
      "CHEMBL556667",
      "2-Pyridinecarboxamide, N-(3-Chlorophenyl)-",
      "AC1LHD0L",
      "GTPL6234",
      "SCHEMBL2530324",
      "Cid_836002",
      "KS-00001CNQ",
      "CTK2E1861",
      "DTXSID00356877",
      "MolPort-002-826-420",
      "ZINC362766",
      "HMS3651P19",
      "EX-A1099",
      "BCP19097",
      "S2862",
      "2565AH",
      "BDBM50293743",
      "AKOS003020541",
      "CS-6072",
      "KB-276111",
      "HY-100588",
      "VU-0364770",
      "N-(3-Chloro-Phenyl)-Pyridine-2-Carboxamide",
      "SW219537-1",
    ],
  },
  {
    primary_id: "D0W9VU",
    names: ["Sulfonamide-Thiadiazole Derivative 1"],
  },
  {
    primary_id: "D0W9VW",
    names: ["TZP-101"],
  },
  {
    primary_id: "D0W9VZ",
    names: ["PMID27376512-Compound-MTC-423"],
  },
  {
    primary_id: "D0W9WF",
    names: [
      "Ketoprofen",
      "Actron",
      "Alrheumat",
      "Alrheumum",
      "Alrheumun",
      "Aneol",
      "Capisten",
      "Dexal",
      "Epatec",
      "Fastum",
      "Kefenid",
      "Ketoprofene",
      "Ketoprofeno",
      "Ketoprofenum",
      "Ketopron",
      "Ketoprophene",
      "Lertus",
      "Menamin",
      "Meprofen",
      "Orudis",
      "Orugesic",
      "Oruvail",
      "Oscorel",
      "Profenid",
      "Toprec",
      "Toprek",
      "Benzoylhydratropic Acid",
      "Orudis KT",
      "K 1751",
      "RP 19583",
      "RP19583",
      "RU 4733",
      "Arthril (TN)",
      "Fastum (TN)",
      "Fastum Gel (TN)",
      "Iso-K",
      "Keto (TN)",
      "Ketoflam (TN)",
      "Ketomex (TN)",
      "Ketonal (TN)",
      "Ketoprofenas (TN)",
      "Ketoprofene (TN)",
      "Ketoprofene [INN-French]",
      "Ketoprofeno [INN-Spanish]",
      "Ketoprofenum (TN)",
      "Ketoprofenum [INN-Latin]",
      "Ketorin (TN)",
      "Ketospray (TN)",
      "Lasonil (TN)",
      "M-Benzoylhydratropic Acid",
      "Oki (TN)",
      "Orudis (TN)",
      "Oruvail (TN)",
      "RP-19583",
      "Racemic-Ketoprofen",
      "Zon (TN)",
      "Bi-Profnid (TN)",
      "RP, 19,583",
      "Ketoprofen (JP15/USP/INN)",
      "Ketoprofen [USAN:INN:BAN:JAN]",
      "Orudis, Oruvail, Ketoflam, Orudis KT, Ketoprofen",
      "Acide (Benzoyl-3-Phenyl)-2-Propionique",
      "Acide (Benzoyl-3-Phenyl)-2-Propionique [French]",
      "L'Acide (Benzoyl-3-Phenyl)-2-Propionique",
      "(+-)-M-Benzoylhydratropic Acid",
      "2-(3-Benzoylphenyl)Propanoic Acid",
      "2-(3-Benzoylphenyl)Propionic Acid",
      "2-(M-Benzoylphenyl)Propionic Acid",
      "2-[3-(Benzoyl)Phenyl]propanoic Acid",
      "2-[3-(Phenylcarbonyl)Phenyl]propanoic Acid",
      "2-[3-Benzoylphenyl]propionic Acid",
      "3-Benzoylhydratropic Acid",
    ],
  },
  {
    primary_id: "D0W9WG",
    names: ["5,5-Diethyl-2-(Phenethylamino)Oxazol-4(5H)-One"],
  },
  {
    primary_id: "D0W9WU",
    names: [
      "(E)-10-Nitrohexadec-9-Enoic Acid",
      "CHEMBL564368",
      "10-Nitropalmitoleic Acid",
      "E-10-Nitropalmitoleic Acid",
      "SCHEMBL15633718",
      "WZWPBDQYCAJWSX-FYWRMAATSA-N",
    ],
  },
  {
    primary_id: "D0W9WW",
    names: ["6-Fluoro-2-(3-Nitro-Phenyl)-Chromen-4-One", "CHEMBL55412", "AKOS003616217"],
  },
  {
    primary_id: "D0W9XF",
    names: ["LRN-281"],
  },
  {
    primary_id: "D0W9YC",
    names: [
      "2-Amino-6-(2-Methoxy-Phenylsulfanyl)-Benzonitrile",
      "6-Amino-2-(2-Methoxyphenylthio)Benzenecarbonitrile",
      "AC1LADZB",
      "BDBM1732",
      "CHEMBL292464",
      "CTK7B1781",
      "ZINC5929241",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 1b",
      "2-Amino-6-[(2-Methoxyphenyl)Thio]benzonitrile",
      "2-Amino-6-(2-Methoxyphenyl)Sulfanylbenzonitrile",
      "2-Amino-6-[(2-Methoxyphenyl)Sulfanyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0W9YW",
    names: ["BI655130"],
  },
  {
    primary_id: "D0W9ZC",
    names: ["4-Imidazol-1-Ylmethyl-1-Nitrothioxanthen-9-One", "CHEMBL1083665"],
  },
  {
    primary_id: "D0W9ZF",
    names: ["Quinagolide"],
  },
  {
    primary_id: "D0W9ZH",
    names: ["NSC-720622"],
  },
  {
    primary_id: "D0W9ZS",
    names: ["Zn-DPA-H"],
  },
  {
    primary_id: "D0W9ZT",
    names: ["ADL-5859"],
  },
  {
    primary_id: "D0W9ZU",
    names: ["Galantamine Analog 1"],
  },
  {
    primary_id: "D0W9ZV",
    names: ["AW-00430"],
  },
  {
    primary_id: "D0W9ZY",
    names: [
      "CE-326597",
      "UNII-1FGZ6L9SF2",
      "1FGZ6L9SF2",
      "CHEMBL1269258",
      "CE 326597",
      "CE-326,597",
      "GTPL9055",
      "BDBM50329179",
      "DB12694",
      "Compound 4a [PMID: 20851601]",
      "6H-(1,2,4)Triazolo(4,3-A)(1,5)Benzodiazepine-6-Acetamide, 4,5-Dihydro-4-(1H-Indol-3-Ylmethyl)-N-(1-Methylethyl)-5-Oxo-1-Phenyl-N-(Phenylmethyl)-, (-)-",
      "6H-(1,2,4)Triazolo(4,3-A)(1,5)Benzodiazepine-6-Acetamide, 4,5-Dihydro-4-(1H-Indol-3-Ylmethyl)-N-(1-Methylethyl)-5-Oxo-1-Phenyl-N-(Phenylmethyl)-, (S)-",
      "J3.552.307C",
    ],
  },
  {
    primary_id: "D0WA1R",
    names: ["Keratinocyte Growth Factor (KGF)"],
  },
  {
    primary_id: "D0WA1V",
    names: ["Pyrimidopyridazinone Derivative 2"],
  },
  {
    primary_id: "D0WA3E",
    names: ["IOX2", "Compound 6"],
  },
  {
    primary_id: "D0WA3T",
    names: ["CBNU-06"],
  },
  {
    primary_id: "D0WA9S",
    names: [
      "DIOXINODEHYDROECKOL",
      "Eckstolonol",
      "UNII-N5P71K0GE3",
      "CHEMBL559870",
      "N5P71K0GE3",
      "CHEBI:65820",
      "5,8,13,14-Tetraoxa-Pentaphene-1,3,6,9,11-Pentol",
      "[1,4]benzodioxino[2,3-A]oxanthrene-1,3,6,9,11-Pentol",
      "SCHEMBL14848420",
      "BDBM50319629",
      "5,8,13,14-Tetraoxa-Pentaphene-1,3,6,9,11-Pentaol",
      "Benzo(1,2-B:3,4-B')Bis(1,4)Benzodioxin-1,3,6,9,11-Pentol",
    ],
  },
  {
    primary_id: "D0WA9U",
    names: ["PMID29649907-Compound-1"],
  },
  {
    primary_id: "D0WB0R",
    names: ["KPP203"],
  },
  {
    primary_id: "D0WB1D",
    names: ["4-[4-(Benzyloxy)Piperidino]butyl-4-Fluorobenzoate", "CHEMBL229105", "SCHEMBL4026157"],
  },
  {
    primary_id: "D0WB2S",
    names: ["Pyrimidine Derivative 19"],
  },
  {
    primary_id: "D0WB5U",
    names: ["PMID27774822-Compound-Figure2Example4-3"],
  },
  {
    primary_id: "D0WB6P",
    names: ["R-NORDULOXETINE", "CHEMBL594585", "BDBM50304393"],
  },
  {
    primary_id: "D0WB8E",
    names: ["Pyrazolone, 1", "Compound 43 [PMID:23160941]", "Compound 43 [PMID:16697190]"],
  },
  {
    primary_id: "D0WB8J",
    names: ["PF-06647020", "Cofetuzumab Pelidotin"],
  },
  {
    primary_id: "D0WB9V",
    names: [
      "Barbiturate",
      "Barbituric Acid",
      "BARBITURIC ACID",
      "67-52-7",
      "Malonylurea",
      "6-Hydroxyuracil",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione",
      "Pyrimidinetrione",
      "Pyrimidine-2,4,6(1H,3H,5H)-Trione",
      "2,4,6-Pyrimidinetrione",
      "Malonylharnstoff",
      "2,4,6-Trioxohexahydropyrimidine",
      "1,3-Diazinane-2,4,6-Trione",
      "Barbitursaeure",
      "Hexahydropyrimidine-2,4,6-Trione",
      "Hydrouracil, 6-Hydroxy-",
      "Barbituric Acid (VAN)",
      "Urea, N,N'-(1,3-Dioxo-1,3-Propanediyl)-",
      "NSC 7889",
      "UNII-WQ92Y2793G",
      "2,4,6-Pyrimidinetrione(1H,3H,5H)",
      "Barbituric Acid (VAN) (8CI)",
      "CHEBI:16294",
      "EINECS 200-658-0",
      "Pyrimidinetriol",
      "Barbiturate Anion",
      "Sodiumbarbiturate",
      "AG-670/31547005",
      "Hexahydro-Pyrimidine-2,4,6-Trione",
      "1,2,3,4,5,6-Hexahydro-2,4,6-Pyrimidinetrione",
      "11709_FLUKA",
      "2,4,6-(1H,3H,5H)-Pyrimidinetrione",
      "2,4,6-Pyrimidinetriol",
      "2,4,6-Trihydroxypyrimidine",
      "2,4,6-Trioxotetrahydro-2H-Pyrimidin-1-Ide",
      "4,6-Dioxo-1H-Pyrimidin-2-Olate",
    ],
  },
  {
    primary_id: "D0WC4Y",
    names: ["FCX-007"],
  },
  {
    primary_id: "D0WC4Z",
    names: ["N-Oxalyl-D-Tyrosine Derivative 8"],
  },
  {
    primary_id: "D0WC5L",
    names: ["3-Butylaminocarbonyl-6-Ethyl-4-Quinolone", "CHEMBL205536"],
  },
  {
    primary_id: "D0WC7S",
    names: ["Unsaturated Long Chain Fatty Acids"],
  },
  {
    primary_id: "D0WC7Z",
    names: ["Imprime PGG"],
  },
  {
    primary_id: "D0WC8M",
    names: ["Diarylamine And Arylheteroarylamine Pyrazole Derivative 1"],
  },
  {
    primary_id: "D0WD0M",
    names: ["PMID29757691-Compound-2a-I"],
  },
  {
    primary_id: "D0WD1E",
    names: [
      "Soraphen A",
      "122547-72-2",
      "Soraphen A(1alpha)",
      "AC1O5R15",
      "(1S,2S,3Z,5R,6S,11S,14S,15R,16R,17S,18S)-15,17-Dihydroxy-5,6,16-Trimethoxy-2,14,18-Trimethyl-11-Phenyl-12,19-Dioxabicyclo[13.3.1]nonadec-3-En-13-One",
      "4,19-Dioxabicyclo(13.3.1)Nonadec-12-En-3-One, 1,17-Dihydroxy-10,11,18-Trimethoxy-2,14,16-Trimethyl-5-Phenyl-, (1R-(1R*,2S*,5S*,10S*,11R*,12E,14S*,15S*,16S*,17S*,18R*))-",
    ],
  },
  {
    primary_id: "D0WD1F",
    names: ["JWH-205"],
  },
  {
    primary_id: "D0WD1Z",
    names: ["Curcumin Analog 1"],
  },
  {
    primary_id: "D0WD6J",
    names: ["1-Benzyl-4-(2-Oxazol-5-Yl-Pyrrol-1-Yl)-Piperidine", "CHEMBL104453", "BDBM50082166"],
  },
  {
    primary_id: "D0WD6K",
    names: [
      "VPM-4-001",
      "Allogenic LNCaP Cell Vaccine (Prostate Tumor), Vakzine Projekt Management",
      "Cell Vaccine (Prostate Cancer), Vakzine Projekt Management",
      "Allogenic LNCaP Cells (IL-2, IFN-Gamma), Vakzine Projekt Management",
    ],
  },
  {
    primary_id: "D0WD8M",
    names: ["Connexyn"],
  },
  {
    primary_id: "D0WD8R",
    names: ["MDP-01"],
  },
  {
    primary_id: "D0WD8Z",
    names: ["Piperazine Derivative 8"],
  },
  {
    primary_id: "D0WE3O",
    names: [
      "Morphine",
      "Avinza",
      "Kadian",
      "Morphine Sulfate",
      "Depodur",
      "Astramorph Pf",
      "Duramorph Pf",
      "Infumorph",
      "Ms Contin",
      "Oramorph Sr",
    ],
  },
  {
    primary_id: "D0WE4C",
    names: ["K185"],
  },
  {
    primary_id: "D0WE5J",
    names: ["Aryl Azepine Derivative 1"],
  },
  {
    primary_id: "D0WE8K",
    names: ["Peptide Analog 47"],
  },
  {
    primary_id: "D0WF1F",
    names: ["PMID29865878-Compound-45"],
  },
  {
    primary_id: "D0WF1S",
    names: ["Tomeglovir", "BAY-38-4766", "BAY-40-1007", "BAY-43-9695"],
  },
  {
    primary_id: "D0WF2C",
    names: ["Bbs-Arg-(D-Pip)-Gly-S-(GS)9-Gly-Hir", "CHEMBL441004"],
  },
  {
    primary_id: "D0WF7H",
    names: ["ICT-111", "Cancer Stem Cell Vaccine, ImmunoCellular Therapeutics"],
  },
  {
    primary_id: "D0WF7I",
    names: ["BMS-480404"],
  },
  {
    primary_id: "D0WF7K",
    names: ["Candoxatril"],
  },
  {
    primary_id: "D0WF7L",
    names: ["Brilinta"],
  },
  {
    primary_id: "D0WG0O",
    names: ["Des-AA1,2,4,5,6,12,13-[D-Trp8]SRIF", "CHEMBL367125"],
  },
  {
    primary_id: "D0WG1T",
    names: ["IC 1001"],
  },
  {
    primary_id: "D0WG2R",
    names: ["Doravirine + Lamivudine + Tenofovir Disoproxil Fumarate", "Delstrigo"],
  },
  {
    primary_id: "D0WG3X",
    names: ["HAC-1 Vaccine"],
  },
  {
    primary_id: "D0WG5P",
    names: ["PMID28627961-Compound-44"],
  },
  {
    primary_id: "D0WG7H",
    names: ["PMID19191557C8"],
  },
  {
    primary_id: "D0WG8A",
    names: ["Long-Actingloop Diuretic"],
  },
  {
    primary_id: "D0WG8L",
    names: ["[D-Gln3,D-Ncy(SO,isopropyl)7]acyline", "CHEMBL415380"],
  },
  {
    primary_id: "D0WG8P",
    names: ["Ac-YR[CEH(PF-DF)RWC]-NH2", "CHEMBL407809"],
  },
  {
    primary_id: "D0WG9Y",
    names: ["A2P5P", "2,5-ADP", "Adenosine 2',5'-Bisphosphate", "Adenosine-2'-5'-Diphosphate"],
  },
  {
    primary_id: "D0WH1K",
    names: ["Amidine Compound 2"],
  },
  {
    primary_id: "D0WH4J",
    names: ["Isosorbide-2-(Propylcarbamate)-5-Mononitrate", "CHEMBL494519"],
  },
  {
    primary_id: "D0WH4L",
    names: ["AVN 211"],
  },
  {
    primary_id: "D0WH4P",
    names: ["ENASIDENIB MESYLATE"],
  },
  {
    primary_id: "D0WH4V",
    names: ["Dapivirine Vaginal Ring"],
  },
  {
    primary_id: "D0WH9U",
    names: ["FK-070"],
  },
  {
    primary_id: "D0WI0P",
    names: ["PMID27788040-Compound-5c"],
  },
  {
    primary_id: "D0WI1G",
    names: ["Isoflavone Derivative 2"],
  },
  {
    primary_id: "D0WI1L",
    names: ["Arthromir"],
  },
  {
    primary_id: "D0WI3T",
    names: ["Saquinavir"],
  },
  {
    primary_id: "D0WI3V",
    names: ["1-(3-Benzyl-6,7-Dichloroquinoxalin-2-Yl)Hydrazine", "CHEMBL382886"],
  },
  {
    primary_id: "D0WI3W",
    names: [
      "Cystamine",
      "Cystamine Dihydrochloride",
      "56-17-7",
      "2,2'-Disulfanediyldiethanamine Dihydrochloride",
      "Ethanamine, 2,2'-Dithiobis-, Dihydrochloride",
      "Cystamin Dihydrochloride",
      "Bis(2-Aminoethyl) Disulfide Dihydrochloride",
      "USAF CB-34",
      "2-Aminoethyl Disulfide Dihydrochloride",
      "Cystamine 2HCL",
      "UNII-I90T518457",
      "EINECS 200-260-7",
      "NSC 39322",
      "2,2'-Dithiodi(Ethylammonium) Dichloride",
      "Decarboxycystine Dihydrochloride",
      "2,2'-Dithio-Bis-(Ethylamine) Dihydrochloride",
      "Usafcb-34",
      "MFCD00012905",
      "2-Aminoethyl Dislufide Dihydrochloride",
    ],
  },
  {
    primary_id: "D0WI4D",
    names: ["LUF-5962"],
  },
  {
    primary_id: "D0WI7N",
    names: ["Imidazo Pyridine Derivative 1"],
  },
  {
    primary_id: "D0WI8M",
    names: ["C[CO-(CH2)2-CO-Nle-D-Nal(2)-Arg-Trp-Lys]-NH2", "CHEMBL267265"],
  },
  {
    primary_id: "D0WI9M",
    names: ["Imidazolidine Derivative 1"],
  },
  {
    primary_id: "D0WI9W",
    names: ["CPC-250"],
  },
  {
    primary_id: "D0WJ0T",
    names: ["Ketoheterocycle Derivative 2"],
  },
  {
    primary_id: "D0WJ1H",
    names: ["Replication-Defective HIV Vaccine"],
  },
  {
    primary_id: "D0WJ1N",
    names: ["SRI-63-675", "109516-82-7", "SDZ-63-675"],
  },
  {
    primary_id: "D0WJ3C",
    names: ["Hepatitis B Prophylactic Vaccine"],
  },
  {
    primary_id: "D0WJ3F",
    names: ["N,N-Methylenebis-2-Phenylacetamide And Benzenesulfonamide Derivative 2"],
  },
  {
    primary_id: "D0WJ3T",
    names: ["Peptide Analog 15"],
  },
  {
    primary_id: "D0WJ3W",
    names: ["PMID25726713-Compound-48"],
  },
  {
    primary_id: "D0WJ5M",
    names: ["Bimekizumab"],
  },
  {
    primary_id: "D0WJ5T",
    names: ["2,7-Bis(Butyramido)Anthraquinone", "SCHEMBL4298269", "CHEMBL480780"],
  },
  {
    primary_id: "D0WJ7H",
    names: ["AGEN2034"],
  },
  {
    primary_id: "D0WJ8I",
    names: ["3-[(2-Methyl-4-Thiazolyl)Ethynyl]phenol", "CHEMBL201974", "SCHEMBL4152997", "BDBM50181784"],
  },
  {
    primary_id: "D0WJ8M",
    names: ["U3-1402"],
  },
  {
    primary_id: "D0WJ8R",
    names: ["PMID25666693-Compound-91"],
  },
  {
    primary_id: "D0WK0P",
    names: ["Topiroxostat"],
  },
  {
    primary_id: "D0WK1M",
    names: ["Baltaleucel-T"],
  },
  {
    primary_id: "D0WK2F",
    names: ["C[RGDf-(S,R)-Alpha-Dfm-F]", "CHEMBL379911"],
  },
  {
    primary_id: "D0WK2S",
    names: ["Tricyclic Isoxazoloquinazoline Derivative 3"],
  },
  {
    primary_id: "D0WK3N",
    names: ["4-(Methyl(Nonyl)Amino)-N-(Quinolin-3-Yl)Benzamide", "CHEMBL407233", "SCHEMBL846831"],
  },
  {
    primary_id: "D0WK6V",
    names: ["PF-05231023"],
  },
  {
    primary_id: "D0WK7B",
    names: ["FP-0220"],
  },
  {
    primary_id: "D0WK7Y",
    names: ["Men ABCWY Vaccine"],
  },
  {
    primary_id: "D0WK8I",
    names: ["Sulfonylated Piperazine Derivative 2"],
  },
  {
    primary_id: "D0WK9J",
    names: ["Binuclear Gold(I) Compound 2"],
  },
  {
    primary_id: "D0WK9Q",
    names: ["4-(3,5-Dimethoxyphenethyl)Benzenamine", "CHEMBL1171818", "SCHEMBL10384348"],
  },
  {
    primary_id: "D0WK9R",
    names: [
      "PF-06447475",
      "1527473-33-1",
      "PF06447475",
      "3-[4-(Morpholin-4-Yl)-7H-Pyrrolo[2,3-D]pyrimidin-5-Yl]benzonitrile",
      "CHEMBL3393348",
      "3FE",
      "GTPL8054",
      "SCHEMBL15401353",
      "EX-A560",
      "AOB5482",
      "BHTWDJBVZQBRKP-UHFFFAOYSA-N",
      "MolPort-039-137-476",
      "BCP14890",
      "BDBM50059277",
      "S8202",
      "ZINC210747484",
      "AKOS025404704",
      "SB17260",
      "CS-3553",
      "NCGC00390733-02",
      "BC600699",
      "DA-44137",
      "HY-12477",
      "AK174244",
      "QC-11808",
      "FT-0700214",
      "PF 06447475",
      "PF-06447475, &gt",
      "J-690197",
      "3-(4-Morpholino-7H-Pyrrolo[2,3-D]pyrimidin-5-Yl)Benzonitrile",
    ],
  },
  {
    primary_id: "D0WL0G",
    names: ["PYN-17", "Altirex"],
  },
  {
    primary_id: "D0WL0Q",
    names: ["GS-5745"],
  },
  {
    primary_id: "D0WL1C",
    names: ["Hementin"],
  },
  {
    primary_id: "D0WL1U",
    names: ["PMID25656651-Compound-5"],
  },
  {
    primary_id: "D0WL1Z",
    names: ["Isoxazoles And Isoxazoline Derivative 6"],
  },
  {
    primary_id: "D0WL3H",
    names: ["LY03005"],
  },
  {
    primary_id: "D0WL4D",
    names: ["STX-100"],
  },
  {
    primary_id: "D0WL5E",
    names: ["PMID28447479-Compound-27"],
  },
  {
    primary_id: "D0WL5F",
    names: [
      "Grazax",
      "Alutard SQ",
      "MK-7243",
      "SCH-697243",
      "Grass Pollen Allergy Desensitization Tablet, ALK-Abello",
      "Biological Grass Pollen Allergen (Sublingual, Zydis), ALK-Abello",
      "Grass Pollen Allergy Desensitization Tablet, ALK-Abello/Schering-Plough",
      "Immunotherapy (Grass Pollen), ALK-Abello/Schering-Plough",
      "Biological Grass Pollen Allergen (Sublingual, Zydis), ALK-Abello/Schering-Plough",
      "Sublingual Tablet-Based Immunotherapy (Grass Pollen Allergies), Schering-Plough/ALK-Abello",
    ],
  },
  {
    primary_id: "D0WL5P",
    names: ["Antibodie Derivative 9"],
  },
  {
    primary_id: "D0WL5T",
    names: ["BIIB076"],
  },
  {
    primary_id: "D0WL5V",
    names: ["LAROPIPRANT"],
  },
  {
    primary_id: "D0WL7F",
    names: ["Tricyclic Indole Compound 1"],
  },
  {
    primary_id: "D0WL7V",
    names: ["ME-3738"],
  },
  {
    primary_id: "D0WL8I",
    names: ["CJ-30001"],
  },
  {
    primary_id: "D0WM1A",
    names: ["ISIS 113021"],
  },
  {
    primary_id: "D0WM3D",
    names: ["TAR-200"],
  },
  {
    primary_id: "D0WM3E",
    names: ["INP-341"],
  },
  {
    primary_id: "D0WM3Y",
    names: ["Isoxazole-Based Bicyclic Compound 2"],
  },
  {
    primary_id: "D0WM3Z",
    names: ["TI-061"],
  },
  {
    primary_id: "D0WM4K",
    names: ["16-(Pyridin-2-Yl)Methyl-Estradiol"],
  },
  {
    primary_id: "D0WM5H",
    names: ["OP-06"],
  },
  {
    primary_id: "D0WM5O",
    names: [
      "G1",
      "CHEMBL569766",
      "1-[(3aS,4R,9bR)-4-(6-Bromo-1,3-Benzodioxol-5-Yl)-3a,4,5,9b-Tetrahydro-3H-Cyclopenta[c]quinolin-8-Yl]ethanone",
      "1-(4-(6-Bromobenzo[1,3]dioxol-5-Yl)-3a,4,5,9b-Tetrahydro-3H-Cyclopenta[c]quinolin-8-Yl)-Ethanone",
      "SR-01000506928",
      "AC1NT1SF",
      "GTPL1014",
      "SCHEMBL12700948",
      "Cid_5322399",
      "Cid_3136849",
      "MolPort-003-004-976",
      "ML051",
      "HMS3650I22",
      "ZINC4172149",
      "BDBM50303803",
      "STK330808",
      "AKOS015969580",
      "HY-107216",
      "CS-0027669",
      "UNM000011063001",
      "UNM000000675701",
      "SR-01000506928-1",
      "SR-01000506928-3",
      "925419-53-0",
    ],
  },
  {
    primary_id: "D0WM6E",
    names: ["3,5-Diiodothyropropionic Acid", "DITPA)"],
  },
  {
    primary_id: "D0WM6I",
    names: ["Analatro"],
  },
  {
    primary_id: "D0WM7M",
    names: ["Anti-CD19 CAR-T Cell Therapy"],
  },
  {
    primary_id: "D0WM8D",
    names: ["CAD-1833"],
  },
  {
    primary_id: "D0WM8R",
    names: ["ISIS 1751"],
  },
  {
    primary_id: "D0WN0U",
    names: [
      "Felodipine",
      "Agon",
      "Felobeta",
      "Felocor",
      "Feloday",
      "Felodipina",
      "Felodipinum",
      "Felodur",
      "Felogamma",
      "Felogard",
      "Fensel",
      "Flodil",
      "Hydac",
      "Lexxel",
      "Logimax",
      "Modip",
      "Munobal",
      "Penedil",
      "Perfudal",
      "Plandil",
      "Plendil",
      "Preslow",
      "Prevex",
      "Renedil",
      "Splendil",
      "AGON SR",
      "AbZ Brand Of Felodipine",
      "Aliud Brand Of Felodipine",
      "Alphapharm Brand Of Felodipine",
      "Alpharma Brand Of Felodipine",
      "Astra Brand Of Felodipine",
      "AstraZeneca Brand Of Felodipine",
      "Aventis Brand Of Felodipine",
      "Azupharma Brand Of Felodipine",
      "BC Brand Of Felodipine",
      "Betapharm Brand Of Felodipine",
      "Ct Arzneimittel Brand Of Felodipine",
      "Felo Biochemie",
      "Felo Puren",
      "Felodipin AL",
      "Felodipin AZU",
      "Felodipin AbZ",
      "Felodipin Heumann",
      "Felodipin Stada",
      "Felodipin Dura",
      "Felodipin Ratiopharm",
      "Felodipin Von Ct",
      "FelodurER",
      "Heumann Brand Of Felodipine",
      "Hexal Brand Of Felodipine",
      "Hoechst Brand Of Felodipine",
      "Merck Dura Brand Of Felodipine",
      "Munobal Retard",
      "Pharmaceutica Astra Brand Of Felodipine",
      "Pharmacia Spain Brandof Felodipine",
      "Plendil Depottab",
      "Plendil ER",
      "Plendil Retard",
      "Promed Brand Of Felodipine",
      "Ratiopharm Brand Of Felodipine",
      "Stadapharm Brand Of Felodipine",
      "TheraPharm Brand Of Felodipine",
      "Worwag Brand Of Felodipine",
      "F 9677",
      "Felodipin 1A Pharma",
      "H 154 82",
      "H 15482",
      "CGH-869",
      "Ct-Arzneimittel Brand Of Felodipine",
      "Dl-Felodipine",
      "Felo-Puren",
      "Felodipin-Ratiopharm",
      "Felodipina [INN-Spanish]",
      "Felodipinum [INN-Latin]",
      "H 154-82",
      "H 154/82",
      "Heumann, Felodipin",
      "Plendil (TN)",
      "AE-641/11429675",
      "Felodipine [USAN:BAN:INN]",
      "Felodipine [USAN:INN:BAN]",
      "H-154/82",
      "Felodipine (JAN/USP/INN)",
      "Plendil, Renedil, Feloday, Felodipine",
      "Ethyl Methyl 4-(2,3-Dichlorophenyl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "(+-)-Ethyl Methyl 4-(2,3-Dichlorophenyl)-1,4-Dihydro-2,6-Dimethyl-3,5-Pyridinedicarboxylate",
      "(+/-)-Ethylmethyl 4-(2,3-Dichlorophenyl)-1,4-Dihydro-2,6-Dimethyl-3,5-Pyridinedicarboxylate",
      "1A Brand Of Felodipine",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-4-(2,3-Dichlorophenyl)-2,6-Dimethyl-, Ethyl Methylester",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-4-(2,3-Dichlorophenyl)-2,6-Dimethyl-,ethyl Methyl Ester",
      "3,5-Pyridinedicarboxylic Acid, 4-(2,3-Dichlorophenyl)-1,4-Dihydro-2,6-Dimethyl-, Ethyl Methyl Ester",
      "3-Ethyl 5-Methyl 4-(2,3-Dichlorophenyl)-2,6-Dimethyl-1,4-Dihydro-3,5-Pyridinedicarboxylate",
      "3-Ethyl 5-Methyl 4-(2,3-Dichlorophenyl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "4-(2,3-Dichlorophenyl)-1,4-Dihydro-2,6-Dimethyl-3,5-Pyridinecarboxylic Acid Ethyl Methyl Ester",
      "4-(2,3-Dichloro-Phenyl)-2,6-Dimethyl-1,4-Dihydro-Pyridine-3,5-Dicarboxylic Acid 3-Ethyl Ester 5-Methyl Ester",
      "4-(2,3-Dichlorophenyl)-1,4-Dihydro-2,6-Dimethyl-3,5-Pyridinedicarboxylic Acid Ethyl Methyl Ester",
      "5-O-Ethyl 3-O-Methyl 4-(2,3-Dichlorophenyl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
    ],
  },
  {
    primary_id: "D0WN1N",
    names: [
      "DM-204",
      "Anti-GSK-3beta MAbs, DiaMedica",
      "Anti-GSK-3beta MAbs, Sanomune",
      "Anti-GSK-3beta Monoclonal Antibodies, DiaMedica",
      "Anti-GSK-3beta Monoclonal Antibodies, Sanomune",
    ],
  },
  {
    primary_id: "D0WN1T",
    names: ["99mTc-Labelled Adrenomedullin (Pulmonary Disease), PulmoScience"],
  },
  {
    primary_id: "D0WN1Y",
    names: ["PMID28454500-Compound-10"],
  },
  {
    primary_id: "D0WN2X",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 18"],
  },
  {
    primary_id: "D0WN2Y",
    names: ["CAR-T Cells Targeting Glypican-3 (GPC3)"],
  },
  {
    primary_id: "D0WN3O",
    names: [
      "N-(4,6-Diphenylpyrimidin-2-Yl)-2-Ethylbutyramide",
      "CHEMBL374366",
      "820961-71-5",
      "SCHEMBL6497567",
      "CTK3E2637",
      "DTXSID20461437",
      "BDBM50157660",
      "AKOS030569446",
      "Butanamide, N-(4,6-Diphenyl-2-Pyrimidinyl)-2-Ethyl-",
    ],
  },
  {
    primary_id: "D0WN7T",
    names: ["MFH-147"],
  },
  {
    primary_id: "D0WN7V",
    names: ["NSC-180246"],
  },
  {
    primary_id: "D0WN9P",
    names: ["4'-(Pyridin-4-Ylmethyl)Biphenyl-3-Amine", "CHEMBL1171987", "SCHEMBL18770411", "BDBM50322783"],
  },
  {
    primary_id: "D0WO2I",
    names: ["PMID26651364-Compound-111"],
  },
  {
    primary_id: "D0WO3A",
    names: ["Methylfurmethide", "5-Methylfurmithide", "5 MFT"],
  },
  {
    primary_id: "D0WO3P",
    names: ["2-(4-Azido-Phenyl)-8-Methoxy-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0WO4T",
    names: ["PMID27998201-Compound-24"],
  },
  {
    primary_id: "D0WO4V",
    names: ["ISIS 112599"],
  },
  {
    primary_id: "D0WO5W",
    names: ["PMID27019002-Compound-47"],
  },
  {
    primary_id: "D0WO6C",
    names: ["PMID26651364-Compound-49"],
  },
  {
    primary_id: "D0WO7R",
    names: ["ABBV-181"],
  },
  {
    primary_id: "D0WO8A",
    names: ["1,3,5-Tris(4-Chlorophenyl)Imidazolidine-2,4-Dione"],
  },
  {
    primary_id: "D0WO8I",
    names: ["PD-2015"],
  },
  {
    primary_id: "D0WO8W",
    names: [
      "Mepivacaine",
      "Carbocaine",
      "Mepivacaina",
      "Mepivacainum",
      "Mepivicaine",
      "Scandicain",
      "Scandicaine",
      "Scandicane",
      "Arestocaine HCL",
      "Carboplyin Dental",
      "Isocaine HCL",
      "Carbocaine (TN)",
      "Carboplyin Dental (TN)",
      "D-Mepivacaine",
      "DL-Mepivacaine",
      "Mepivacaina [INN-Spanish]",
      "Mepivacaine (INN)",
      "Mepivacaine [INN:BAN]",
      "Mepivacainum [INN-Latin]",
      "Polocaine (TN)",
      "Polocaine-Mpf",
      "S-Ropivacaine Mesylate",
      "D(-)-Mepivacaine",
      "N-Methyl-2-Pipecolic Acid, 2,6-Dimethylanilide",
      "N-Methyl-2-Pipecolic Acid, 2,6-Xylidide",
      "N-Methylhexahydro-2-Picolinic Acid, 2,6-Dimethylanilide",
      "N-(2,6-Dimethylphenyl)-1-Methyl-2-Piperidinecarboxamide",
      "N-(2,6-Dimethylphenyl)-1-Methylpiperidine-2-Carboxamide",
      "(+-)-1-Methyl-2',6'-Pipecoloxylidide",
      "1-METHYL-2',6'-PIPECOLOXYLIDIDE",
    ],
  },
  {
    primary_id: "D0WP0B",
    names: ["Acemannan", "Acemannan Hydrogel (TN)"],
  },
  {
    primary_id: "D0WP0U",
    names: ["CMB305"],
  },
  {
    primary_id: "D0WP1L",
    names: ["NPS-3"],
  },
  {
    primary_id: "D0WP1P",
    names: ["PMID26004420-Compound-WO2014085210A"],
  },
  {
    primary_id: "D0WP2R",
    names: ["AC-201"],
  },
  {
    primary_id: "D0WP4E",
    names: ["RO-5271983"],
  },
  {
    primary_id: "D0WP5S",
    names: ["N-Substituted Pyrazole Derivative 3"],
  },
  {
    primary_id: "D0WP6A",
    names: [
      "3-Hydroxy-4-Methyl-6H-Benzo[c]chromen-6-One",
      "3-Hydroxy-4-Methylbenzo[c]chromen-6-One",
      "CHEMBL203519",
      "76244-77-4",
      "CBMicro_031406",
      "AC1NT8BO",
      "AC1Q2GPY",
      "Oprea1_200366",
      "SCHEMBL6825266",
      "MolPort-000-688-996",
      "ALBB-015456",
      "ZINC5921519",
      "BDBM50180504",
      "STL466134",
      "AKOS002377900",
      "MCULE-5900160059",
      "NS-02841",
      "BIM-0031475.P001",
      "R5445",
      "4-Methyl-3-Hydroxy-6H-Dibenzo[b,d]pyran-6-One",
      "SR-01000229483",
      "SR-01000229483-1",
      "6H-Dibenzo[b,d]pyran-6-One, 3-Hydroxy-4-Methyl-",
    ],
  },
  {
    primary_id: "D0WP7P",
    names: ["NNC-55-0118"],
  },
  {
    primary_id: "D0WP9A",
    names: ["BIIB068"],
  },
  {
    primary_id: "D0WQ0R",
    names: ["Anti-T-Lymphocyte Immune Globulin"],
  },
  {
    primary_id: "D0WQ4G",
    names: ["Melanin"],
  },
  {
    primary_id: "D0WQ5S",
    names: ["U-99363E"],
  },
  {
    primary_id: "D0WQ5U",
    names: ["MTR107"],
  },
  {
    primary_id: "D0WQ7U",
    names: [
      "3-Phosphoglyceric Acid",
      "3-Phospho-D-Glycerate",
      "3-Phosphoglycerate",
      "D-Glycerate 3-Phosphate",
      "3-Phospho-(R)-Glycerate",
      "CHEBI:17794",
      "3-Phospho-D-Glyceric Acid",
      "(2R)-2-Hydroxy-3-(Phosphonooxy)Propanoic Acid",
      "2-D-Hydroxy-3-Phosphonooxy-Propanoic Acid",
      "3443-58-1",
      "3PG",
      "(2R)-2-Hydroxy-3-(Phosphonatooxy)Propanoate",
      "1iih",
      "820-11-1",
      "Bmse000007",
      "SCHEMBL2497743",
      "AC1L96U5",
      "CHEMBL1160563",
      "CTK1B7705",
      "OSJPPGNTCRNQQC-UWTATZPHSA-N",
      "ZINC3869934",
      "BDBM50216218",
      "DB04510",
      "(2R)-2-Hydroxy-3-Phosphonooxypropanoic Acid",
      "C00197",
      "Propanoic Acid, 2-Hydroxy-3-(Ph",
    ],
  },
  {
    primary_id: "D0WQ9A",
    names: ["18-Methoxycoronaridinate 2-Methoxyethylamide"],
  },
  {
    primary_id: "D0WQ9X",
    names: ["PMID30107136-Compound-Example1"],
  },
  {
    primary_id: "D0WR0Q",
    names: ["Pyrimidine Derivative 2"],
  },
  {
    primary_id: "D0WR1I",
    names: ["1,2,3,4-Tetrahydroisoquinoline Derivative 1"],
  },
  {
    primary_id: "D0WR3D",
    names: ["Abetimus Sodium", "Abetimus Sodium (USAN)", "Riquent (TN)"],
  },
  {
    primary_id: "D0WR6N",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 28"],
  },
  {
    primary_id: "D0WR7B",
    names: ["Imidazo Isoindole Derivative 1"],
  },
  {
    primary_id: "D0WR7C",
    names: ["PH-94B"],
  },
  {
    primary_id: "D0WR7F",
    names: ["Chromene Derivative 1"],
  },
  {
    primary_id: "D0WR7U",
    names: ["REN-1053"],
  },
  {
    primary_id: "D0WR9T",
    names: ["Oxime Derivative 1"],
  },
  {
    primary_id: "D0WR9U",
    names: ["MEDI7352"],
  },
  {
    primary_id: "D0WS1V",
    names: ["L-751250"],
  },
  {
    primary_id: "D0WS1X",
    names: [
      "Cefdinir",
      "CFDN",
      "Cefdinirum",
      "Cefdinyl",
      "Cefdirnir",
      "Cefzon",
      "Omnicef",
      "BMY 28488",
      "FK 482",
      "PD 134393",
      "Cefdinir [USAN:INN]",
      "Cefdinirum [INN-Latin]",
      "Cefzon (TN)",
      "FK-482",
      "FR-80482",
      "KS-1038",
      "Omnicef (TN)",
      "PD-134393",
      "Cefdinir (JP15/USAN/INN)",
      "Omnicef, FK-482, BMY-28488, PD 134393, CI-983, Cefdinir",
      "(-)-(6R,7R)-7-(2-(2-Amino-4-Thiazolyl)Glyoxylamido)-8-Oxo-3-Vinyl-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid, 7(Sup 2)-(Z)-Oxime",
      "(6R,7R)-7-[[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-Hydroxyiminoacetyl]amino]-3-Ethenyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Hydroxyimino)Acetyl]amino}-3-Ethenyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7-(2 (2-Aminothiazol-4-Yl)-2-Hydroxyiminoacetamido)-3-Vinyl-3-Cephem-4-Carboxylic Acid",
      "7beta-[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Hydroxyimino)Acetamido]-3-Ethenyl-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0WS4E",
    names: ["COR-388"],
  },
  {
    primary_id: "D0WS4U",
    names: ["Epoxysuccinate Derivative 3"],
  },
  {
    primary_id: "D0WS5L",
    names: ["BIA-13"],
  },
  {
    primary_id: "D0WS6Y",
    names: ["Tricyclic Pyrrolidine Derivative 4"],
  },
  {
    primary_id: "D0WS8K",
    names: ["Ilatreotide"],
  },
  {
    primary_id: "D0WT0E",
    names: [
      "VasoPET",
      "AppCHFppA-[18F]",
      "Fluoro-18-AppCHFppA",
      "PET Contrast Agent (Coronary Artery Disease Detection), FluoroPharma",
      "18F-AppCHFppA",
    ],
  },
  {
    primary_id: "D0WT1C",
    names: ["Benzothiazole Analog 2"],
  },
  {
    primary_id: "D0WT1W",
    names: ["PMID25666693-Compound-132"],
  },
  {
    primary_id: "D0WT1Z",
    names: ["PMID27019002-Compound-17"],
  },
  {
    primary_id: "D0WT2Z",
    names: ["(O10eq)-Benzyloxycarbonyl-Beta-Alanylryanodine", "CHEMBL435162"],
  },
  {
    primary_id: "D0WT3F",
    names: ["PMID29338548-Compound-37"],
  },
  {
    primary_id: "D0WT4I",
    names: ["PMID27454349-Compound-101"],
  },
  {
    primary_id: "D0WT5D",
    names: ["(S)-EF-1520", "GTPL6513"],
  },
  {
    primary_id: "D0WT5M",
    names: [
      "3-Benzylamino-5-Ethyl-6-Methyl-1H-Pyridin-2-One",
      "CHEMBL86064",
      "3-(Benzylamino)-5-Ethyl-6-Methyl-1H-Pyridin-2-One",
      "AC1L9RKU",
      "2-Pyridinone Deriv. 28",
      "BDBM1298",
      "ZINC5887250",
      "3-Aminopyridin-2(1H)-One Analogue 18",
      "3-(Benzylamino)-5-Ethyl-6-Methyl-2(1H)-Pyridinone",
      "3-(Benzylamino)-5-Ethyl-6-Methylpyridin-2(1H)-One",
    ],
  },
  {
    primary_id: "D0WT6A",
    names: ["Ethylene Diamine"],
  },
  {
    primary_id: "D0WT7D",
    names: ["Imidazo Pyridine Derivative 3"],
  },
  {
    primary_id: "D0WT7H",
    names: [
      "Beta-Funaltrexamine",
      "Beta-Fna",
      "Naltrexone Fumarate Methyl Ester",
      "72782-05-9",
      "CHEMBL473136",
      "CHEBI:81527",
      "2-Butenoic Acid, 4-(((5alpha,6beta)-17-(Cyclopropylmethyl)-4,5-Epoxy-3,14-Dihydroxymorphinan-6-Yl)Amino)-4-Oxo-, Methyl Ester, (E)-",
      "Beta Funaltrexamine",
      "Beta-Funeltrexamine",
      "SCHEMBL1240811",
      "GTPL1631",
      "ZINC3995756",
      "PDSP2_001549",
      "PDSP2_000394",
      "BDBM50247803",
      "NCGC00163180-01",
      "LS-47116",
      "C18127",
      "Methyl 3-[4-Cyclopropylmethyl-10,17-Dihydroxy-12-Oxa-4-Azapentacyclo[9.6.1.01,13.05,17.07,18]octadeca-7(18),8,10-Trien-14-Ylcarbamoyl]-",
    ],
  },
  {
    primary_id: "D0WT7M",
    names: ["PMID29649907-Compound-38"],
  },
  {
    primary_id: "D0WT8Z",
    names: ["TDI-0115"],
  },
  {
    primary_id: "D0WT9A",
    names: ["Vero-Cell H5N1 Vaccine"],
  },
  {
    primary_id: "D0WT9J",
    names: ["Heteroaryl-Carboxamide Derivative 8"],
  },
  {
    primary_id: "D0WU0K",
    names: ["Sporeamicins"],
  },
  {
    primary_id: "D0WU1O",
    names: ["G1T38"],
  },
  {
    primary_id: "D0WU1S",
    names: ["I-BET151"],
  },
  {
    primary_id: "D0WU2Y",
    names: ["ImmuStem"],
  },
  {
    primary_id: "D0WU3G",
    names: ["99mTc-RP-527"],
  },
  {
    primary_id: "D0WU7X",
    names: ["EMB-001"],
  },
  {
    primary_id: "D0WU8Z",
    names: ["BB-476"],
  },
  {
    primary_id: "D0WU9R",
    names: ["L-741742"],
  },
  {
    primary_id: "D0WV0Q",
    names: ["ML169"],
  },
  {
    primary_id: "D0WV1W",
    names: ["PMID27454349-Compound-94"],
  },
  {
    primary_id: "D0WV3D",
    names: [
      "(+)-Myristinin D",
      "CHEMBL448072",
      "Myristinis D",
      "Myristinin D",
      "AC1LAXHR",
      "BDBM50250427",
      "9-Phenyl-1-[2,4,6-Trihydroxy-3-[(2S,4R)-7-Hydroxy-2-(4-Hydroxyphenyl)Chroman-4-Yl]phenyl]nonan-1-One",
      "9-Phenyl-1-[2,4,6-Trihydroxy-3-[(2S,4R)-7-Hydroxy-2-(4-Hydroxyphenyl)-3,4-Dihydro-2H-Chromen-4-Yl]phenyl]nonan-1-One",
      "1-Nonanone, 1-[3-[(2S,4R)-3,4-Dihydro-7-Hydroxy-2-(4-Hydroxyphenyl)-2H-1-Benzopyran-4-Yl]-2,4,6-Trihydroxyphenyl]-9-Phenyl-",
    ],
  },
  {
    primary_id: "D0WV3U",
    names: ["Prazosin"],
  },
  {
    primary_id: "D0WV4H",
    names: ["Hemomex I"],
  },
  {
    primary_id: "D0WV4M",
    names: ["Propicillin"],
  },
  {
    primary_id: "D0WV4N",
    names: ["AHR-14042"],
  },
  {
    primary_id: "D0WV4Z",
    names: ["1,5,-IP2"],
  },
  {
    primary_id: "D0WV5J",
    names: ["L-888,291"],
  },
  {
    primary_id: "D0WV5R",
    names: [
      "Cyanide",
      "CYANIDE ION",
      "Isocyanide",
      "Cyanide Anion",
      "Nitrile Anion",
      "Cyanide Ions",
      "1957/12/5",
      "Cyanure [French]",
      "Cyanide(1-) Ion",
      "Cyanide(1-)",
      "Cyanide (Anion)",
      "UNII-OXN4E7L11K",
      "RCRA Waste Number P030",
      "Cyanide (CN(Sup 1-))",
      "Carbon Nitride Ion (CN1-)",
      "Hydrocyanic Acid, Ion(1-)-",
      "Carbon Nitride Ion (CN(Sup 1-))",
      "UN1935",
      "RCRA Waste No. P030",
      "BRN 1900509",
      "OXN4E7L11K",
      "CN-",
      "Cyanides (Soluble Salts And Complexes) Not Otherwise Specified",
      "Cyanure",
      "Cyanide, Free",
      "Iminomethanide",
      "Isonitrile",
      "Prussiate",
      "Zyanid",
      "Cyano",
      "Nitridocarbonate(1-)",
      "Cyanide With Pota",
    ],
  },
  {
    primary_id: "D0WV8K",
    names: [
      "(+/-)-Cis-2-CAMP",
      "215597-45-8",
      "2-(Aminomethyl)Cyclopropanecarboxylic Acid",
      "2-(Aminomethyl)Cyclopropane-1-Carboxylic Acid",
      "Cyclopropanecarboxylicacid, 2-(Aminomethyl)-",
      "(Plusmn)-Cis-2-CAMP",
      "AC1N3MNT",
      "SCHEMBL3943045",
      "GTPL4067",
      "CHEMBL284310",
      "CTK1A0098",
      "BDBM50087269",
      "AKOS006277884",
      "KB-163238",
      "(Cis)-2-Aminomethyl-Cyclopropanecarboxylic Acid",
      "A29700",
      "(Trans)-2-Aminomethyl-Cyclopropanecarboxylic Acid",
      "(+)-2-(Aminomethyl)-Cyclopropane-1-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0WV8Z",
    names: ["MEDI4166"],
  },
  {
    primary_id: "D0WV9J",
    names: ["Beta-Cyclodextrin Conjugate Derivative 5"],
  },
  {
    primary_id: "D0WW1K",
    names: ["2-(4-Hydroxylphenyl)-3-(3,5-Dihydroxylphenyl) Propenoic Acid (NNU-Hdpa)"],
  },
  {
    primary_id: "D0WW1O",
    names: ["FluBIok"],
  },
  {
    primary_id: "D0WW1V",
    names: ["MM-310"],
  },
  {
    primary_id: "D0WW1Z",
    names: ["PMID26394986-Compound-54"],
  },
  {
    primary_id: "D0WW2D",
    names: ["NSC-356819"],
  },
  {
    primary_id: "D0WW2H",
    names: ["Triazolo[4,3-B]pyridazine Derivative 1"],
  },
  {
    primary_id: "D0WW2X",
    names: ["ESI-09"],
  },
  {
    primary_id: "D0WW3O",
    names: ["XEN1101"],
  },
  {
    primary_id: "D0WW4F",
    names: ["METYRAPOL"],
  },
  {
    primary_id: "D0WW5X",
    names: ["N-Octylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL487317"],
  },
  {
    primary_id: "D0WW7A",
    names: [
      "SB-381320",
      "CHEMBL56954",
      "BDBM50102161",
      "1-{2-[4-(3,4-Dichloro-Phenyl)-Piperazin-1-Yl]-2-Oxo-Ethyl}-2-(4-Fluoro-Benzylsulfanyl)-5-(2-Methoxy-Pyrimidin-5-Ylmethyl)-1H-Pyrimidin-4-One",
    ],
  },
  {
    primary_id: "D0WW7U",
    names: ["BFPT-12603", "BFPT-2603"],
  },
  {
    primary_id: "D0WW8M",
    names: ["6,7-Dimethoxy-3-Phenylethynyl-Quinoline", "CHEMBL67059", "ZINC3834029"],
  },
  {
    primary_id: "D0WW9B",
    names: ["Aryl Mannoside Derivative 18"],
  },
  {
    primary_id: "D0WX0K",
    names: ["Indazole Derivative 6"],
  },
  {
    primary_id: "D0WX1B",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 1"],
  },
  {
    primary_id: "D0WX1I",
    names: ["ProVax"],
  },
  {
    primary_id: "D0WX1N",
    names: ["Ensemblin Macrocycle"],
  },
  {
    primary_id: "D0WX3N",
    names: ["KRP-110"],
  },
  {
    primary_id: "D0WX4H",
    names: ["Heterocyclic Derivative 19"],
  },
  {
    primary_id: "D0WX4U",
    names: ["PMID26004420-Compound-US20140142115A"],
  },
  {
    primary_id: "D0WX4W",
    names: ["PMID25666693-Compound-120"],
  },
  {
    primary_id: "D0WX5V",
    names: ["LP-205A1"],
  },
  {
    primary_id: "D0WX6J",
    names: ["PMID30247903-Compound-General Structure3"],
  },
  {
    primary_id: "D0WX6R",
    names: [
      "5,6-Dinitroacenaphthoquinone",
      "5,6-Dinitroacenaphthylene-1,2-Dione",
      "AC1MQHVL",
      "5,6-Dinitroacenaphthenequinone",
      "CHEMBL235289",
      "SCHEMBL2699746",
      "BDBM22854",
      "MolPort-003-895-771",
      "1,2-Dione-Based Compound, 11",
      "AKOS024343013",
      "MCULE-5132922926",
      "27471-02-9",
      "5,6-Dinitro-1,2-Dihydroacenaphthylenedione",
    ],
  },
  {
    primary_id: "D0WX7F",
    names: ["PMID28447479-Compound-10"],
  },
  {
    primary_id: "D0WX7S",
    names: ["SIDEROXYLONAL A"],
  },
  {
    primary_id: "D0WY0N",
    names: ["ALKASAR-18"],
  },
  {
    primary_id: "D0WY1B",
    names: ["Vaccine, Non-Hodgkin's Lymphoma"],
  },
  {
    primary_id: "D0WY1E",
    names: ["MB-106"],
  },
  {
    primary_id: "D0WY2L",
    names: ["GSK1018921"],
  },
  {
    primary_id: "D0WY3J",
    names: ["Pfizer 4"],
  },
  {
    primary_id: "D0WY5Q",
    names: ["Rivastigmine"],
  },
  {
    primary_id: "D0WY9F",
    names: ["MenAfriVac"],
  },
  {
    primary_id: "D0WY9H",
    names: [
      "ZD-0892",
      "CHEMBL55210",
      "SCHEMBL7363455",
      "CTK4D4091",
      "BDBM50058391",
      "HY-19254",
      "171964-73-1",
      "CS-0014993",
      "L-Prolinamide,N-(4-Methoxybenzoyl)-L-Valyl-N-[(1S)-3,3,3-Trifluoro-1-(1-Methylethyl)-2-Oxopropyl]-",
      "(S)-1-[(S)-2-(4-Methoxy-Benzoylamino)-3-Methyl-Butyryl]-Pyrrolidine-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0WY9N",
    names: [
      "Agrimophol",
      "BRN 2195872",
      "65792-05-4",
      "2,4-Cyclohexadien-1-One, 6-((2,6-Dihydroxy-4-Methoxy-3-Methyl-5-(1-Oxobutyl)Phenyl)Methyl)-3,5-Dihydroxy-4,6-Dimethyl-2-(2-Methyl-1-Oxobutyl)-",
      "2,4-Cyclohexadien-1-One, 3,5-Dihydroxy-6-((2,6-Dihydroxy-4-Methoxy-3-Methyl-5-(1-Oxobutyl)Phenyl)Methyl)-4,6-Dimethyl-2-(2-Methyl-1-Oxobutyl)-",
      "AC1Q5BL0",
      "AC1L2TJ9",
      "SCHEMBL2313302",
      "MEGxp0_000406",
      "AKOS015965215",
      "API0000358",
      "LS-56319",
      "AC-20174",
    ],
  },
  {
    primary_id: "D0WZ0E",
    names: ["TV-Kidney-1"],
  },
  {
    primary_id: "D0WZ0X",
    names: ["ABP 980"],
  },
  {
    primary_id: "D0WZ0Z",
    names: [
      "Cardiometabolic Therapeutics",
      "Cardiometabolic Therapeutics, Abbott",
      "Cardiometabolic Therapeutics, Solvay",
      "LF-242594",
      "LF-246691",
      "LF-250033",
    ],
  },
  {
    primary_id: "D0WZ3G",
    names: ["Entolimod"],
  },
  {
    primary_id: "D0WZ3T",
    names: [
      "1-(2-Pyridin-2-Yl-Ethyl)-3-Thiazol-2-Yl-Thiourea",
      "149486-07-7",
      "Thiourea, N-(2-(2-Pyridinyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(2-Pyridinyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 25",
      "AC1MHDKE",
      "CHEMBL145709",
      "SCHEMBL6956252",
      "BDBM1918",
      "DTXSID70164300",
      "1-(2-Pyridin-2-Ylethyl)-3-(1,3-Thiazol-2-Yl)Thiourea",
      "1-[2-(2-Pyridyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
      "1-(2-Thiazolyl)-3-[2-(2-Pyridyl)Ethyl]thiourea",
      "N-(2-(2-Pyridylethyl))-N -(2-Thiazolyl)Thiourea",
    ],
  },
  {
    primary_id: "D0WZ4J",
    names: ["CYC-800"],
  },
  {
    primary_id: "D0WZ4Y",
    names: ["Topical Ruxolitinib"],
  },
  {
    primary_id: "D0WZ7J",
    names: ["M3A6S", "CHEMBL214040", "3-Acetylmorphine 6-Sulfate", "3-O-Acetylmorphine-6-Sulfate"],
  },
  {
    primary_id: "D0WZ8N",
    names: ["PMID27774824-Compound-Figure9Example2up"],
  },
  {
    primary_id: "D0WZ9R",
    names: ["PRS-343"],
  },
  {
    primary_id: "D0WZ9T",
    names: [
      "CGP-44532",
      "CGP 44532",
      "[(2R)-3-Amino-2-Hydroxypropyl]-Methylphosphinic Acid",
      "FUUPFUIGNBPCAY-SCSAIBSYSA-N",
      "AC1NSJP2",
      "133345-68-3",
      "SCHEMBL185739",
      "GTPL1082",
      "CHEMBL113348",
      "Phosphinic Acid, P-[(2R)-3-Amino-2-Hydroxypropyl]-P-Methyl-",
      "CGP 44533",
      "ZINC13742955",
      "LS-106096",
      "P-[3-Amino-2(R)-Hydroxy-Propyl]-P-Methyl-Phosphinic Acid",
    ],
  },
  {
    primary_id: "D0X0AM",
    names: ["ZK159222", "C410228", "ZK 159222"],
  },
  {
    primary_id: "D0X0BI",
    names: [
      "BRADYKININ",
      "Kallidin I",
      "58-82-2",
      "L-Bradykinin",
      "Synthetic Bradykinin",
      "Bradykinin (Synthetic)",
      "Callidin I",
      "UNII-S8TIM42R2W",
      "Kallidin 9",
      "BRS 640",
      "PRS 640",
      "S8TIM42R2W",
      "CHEBI:3165",
      "CHEMBL406291",
      "Bradykinin, Lysyl",
      "BK",
      "Arg-Pro-Pro-Gly-Phe-Ser-Pro-Phe-Arg",
      "EINECS 200-398-8",
      "L-Arginyl-L-Prolyl-L-Prolylglycyl-L-Phenylalanyl-L-Seryl-L-Prolyl-L-Phenylalanyl-L-Arginine",
    ],
  },
  {
    primary_id: "D0X0CB",
    names: ["Lumacaftor"],
  },
  {
    primary_id: "D0X0CH",
    names: ["Ganaxolone"],
  },
  {
    primary_id: "D0X0CS",
    names: ["2,3,4-Trihydroxy-5-Isopropyl-N-Phenyl-Benzamide", "CHEMBL214839", "BDBM50196033"],
  },
  {
    primary_id: "D0X0EF",
    names: ["XGP-210"],
  },
  {
    primary_id: "D0X0ER",
    names: [
      "7-Chloro-1H-Indole-2,3-Dione",
      "7-Chloroisatin",
      "7477-63-6",
      "7-Chloroindoline-2,3-Dione",
      "7-Chloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "7-Chloro-2,3-Indolinedione",
      "MLS003171490",
      "1H-INDOLE-2,3-DIONE, 7-CHLORO-",
      "1H-Indole-2,3-Dione,7-Chloro-",
      "MFCD00022796",
      "7-Chloro-1H-Benzo[d]azolidine-2,3-Dione",
      "7-Chlorisatin",
      "7-Chloro Isatin",
      "NSC400868",
      "PubChem15537",
      "7-Chloroisatin, 97%",
      "AC1Q3HYB",
      "AC1L7ZVK",
      "AC1Q3KGY",
      "ACMC-209zr4",
      "7-Chloroindole-2,3-Dione",
      "Isatin-Based Compound, 43",
      "KSC640I8T",
      "7-Chloro-Indoline-2,3-Dione",
      "SCHEMBL1247382",
      "CHEMBL222190",
    ],
  },
  {
    primary_id: "D0X0EU",
    names: [
      "E-5531",
      "E-1-O-Phosphate",
      "2-Deoxy-6-O-[2-Deoxy-3-O-[3(R)-[5(Z)-Dodecenoyloxy]decyl]-6-O-Methyl-2-(3-Oxotetradecanamido)-4-O-Phosphono-Beta-D-Glucopyranosyl]-3-O-[3(R)-Hydroxydecyl]-2-(3-Oxotetradecanamido)-Alpha-D-Glucopyranos",
    ],
  },
  {
    primary_id: "D0X0FB",
    names: ["JWH-206"],
  },
  {
    primary_id: "D0X0FU",
    names: ["KP-1461"],
  },
  {
    primary_id: "D0X0FW",
    names: ["B1-655075"],
  },
  {
    primary_id: "D0X0GB",
    names: ["Letibotulinumtoxina"],
  },
  {
    primary_id: "D0X0GG",
    names: ["PMID1656041C4rr"],
  },
  {
    primary_id: "D0X0GI",
    names: ["Gadoversetamide"],
  },
  {
    primary_id: "D0X0GV",
    names: [
      "GLPDGG Peptide",
      "CHEMBL412295",
      "GLPDGG",
      "GTPL2980",
      "BDBM50226164",
      "GLPDGG Peptide [PMID: 20494585]",
      "(3S)-3-{[(2S)-1-[(2S)-2-(2-Aminoacetamido)-4-Methylpentanoyl]pyrrolidin-2-Yl]formamido}-3-({[(Carboxymethyl)Carbamoyl]methyl}carbamoyl)Propanoic Acid",
    ],
  },
  {
    primary_id: "D0X0HN",
    names: ["LY-317644"],
  },
  {
    primary_id: "D0X0IF",
    names: ["COCHINCHINENENE B", "CHEMBL399481", "BDBM50222765"],
  },
  {
    primary_id: "D0X0IH",
    names: [
      "BENZYLIMIDAZOLE",
      "1-Benzylimidazole",
      "1-Benzyl-1H-Imidazole",
      "4238-71-5",
      "N-Benzylimidazole",
      "Imidazole, 1-Benzyl-",
      "1H-Imidazole, 1-(Phenylmethyl)-",
      "1-BENZYL IMIDAZOLE",
      "CCRIS 5821",
      "EINECS 224-200-4",
      "1-Benzylimidazole, 99%",
      "NSC 217337",
      "NSC 126828",
      "CHEMBL14192",
      "AI3-52572",
      "MLS000069473",
      "KKKDZZRICRFGSD-UHFFFAOYSA-N",
      "MFCD00005296",
      "SMR000059044",
      "1BN",
      "1-Benzylimdazole",
      "2afx",
      "1-Benzyl-Imidazole",
      "3pb9",
      "1-Benzylimidazole (BI)",
      "Maybridge3_003880",
      "Opera_ID_1637",
      "SCHEMBL414",
      "ACMC-209jp9",
      "AC1Q28WR",
      "AC1L2U3N",
      "AC1Q4X5Z",
      "AC1Q28WS",
      "KSC492M7H",
      "MLS001424205",
    ],
  },
  {
    primary_id: "D0X0IQ",
    names: ["Modified GDNF"],
  },
  {
    primary_id: "D0X0IT",
    names: [
      "Diphenylacetic Acid",
      "DIPHENYLACETIC ACID",
      "2,2-Diphenylacetic Acid",
      "117-34-0",
      "Diphenylethanoic Acid",
      "Acetic Acid, Diphenyl-",
      "Benzeneacetic Acid, .alpha.-Phenyl-",
      "1,1-Diphenylacetic Acid",
      "UNII-658NCZ0NKO",
      "Diphenyl-Acetic Acid",
      "Alpha-Toluic Acid, Alpha-Phenyl-",
      "Benzeneacetic Acid, Alpha-Phenyl-",
      "EINECS 204-185-0",
      "NSC 120417",
      "BRN 1910978",
      "658NCZ0NKO",
      "AI3-23777",
      "CHEBI:41967",
      "PYHXGXCGESYPCW-UHFFFAOYSA-N",
      ".alpha.,.alpha.-Diphenylacetic Acid",
      "MFCD00004251",
      ".alpha.-Toluic Acid, .alpha.-Phenyl-",
      "A-Toluic Acid, A-Phenyl-",
      "Diphenylacetic Acid, 99+%",
    ],
  },
  {
    primary_id: "D0X0IU",
    names: ["LMB-2"],
  },
  {
    primary_id: "D0X0IY",
    names: ["Rolofylline"],
  },
  {
    primary_id: "D0X0KJ",
    names: [
      "Chlorotrianisene",
      "Anisene",
      "Chloortrianisestrol",
      "Chlorestrolo",
      "Chlorotrianisenum",
      "Chlorotrianisestrol",
      "Chlorotrianisine",
      "Chlorotrianizen",
      "Chlorotrisin",
      "Chlortrianisen",
      "Chlortrianisene",
      "Chlortrianisenum",
      "Chlortrianisestrol",
      "Chlortrianisoestrolum",
      "Chlortrianizen",
      "Clorestrolo",
      "Clorotrianisene",
      "Clorotrianiseno",
      "Clorotrisin",
      "Hormonisene",
      "Khlortrianizen",
      "Merbentul",
      "Metace",
      "Rianil",
      "TACE",
      "Triagen",
      "Trianisestrol",
      "Chlorotrianisene [Nonsteroidal Oestrogens]",
      "Clorotrianisene [DCIT]",
      "Chlorotrianisene (INN)",
      "Chlorotrianisene [BAN:INN]",
      "Chlorotrianisene [INN:BAN]",
      "Chlorotrianisenum [INN-Latin]",
      "Clorotrianiseno [INN-Spanish]",
      "TACE (TN)",
      "Tace (Pharmaceutical)",
      "Tace-Fn",
      "Chlorotris(P-Methoxyphenyl)Ethylene",
      "Tri-P-Anisylchloroethylene",
      "Tris(P-Methoxyphenyl)Chloroethylene",
      "1,1',1''-(1-Chloro-1-Ethenyl-2-Ylidene)-Tris(4-Methoxybenzene)",
      "1,1',1''-(2-Chloroethene-1,1,2-Triyl)Tris(4-Methoxybenzene)",
      "1,1',1''-(2-Chloroethene-1,1,2-Triyl)Tris[4-(Methyloxy)Benzene]",
      "1-[1-Chloro-2,2-Bis(4-Methoxyphenyl)Ethenyl]-4-Methoxybenzene",
    ],
  },
  {
    primary_id: "D0X0KM",
    names: ["Tasisulam"],
  },
  {
    primary_id: "D0X0KZ",
    names: [
      "4,4'-Biphenyldiboronic Acid",
      "44'-Biphenyldiboronic Acid",
      "SCHEMBL2460602",
      "ZINC199832994",
      "KB-187838",
      "A825578",
      "(1-Borono-4-Phenyl-1-Cyclohexa-2,4-Dienyl)Boronic Acid",
      "[1-(Dihydroxyboranyl)-4-Phenyl-Cyclohexa-2,4-Dien-1-Yl]boronic Acid",
    ],
  },
  {
    primary_id: "D0X0NA",
    names: ["R-524"],
  },
  {
    primary_id: "D0X0OF",
    names: ["Pennvax-G"],
  },
  {
    primary_id: "D0X0OP",
    names: [
      "Alkyl OMPT",
      "GTPL2913",
      "CHEMBL3621962",
      "[(2S)-2-Methoxy-3-[(9Z)-Octadec-9-En-1-Yloxy]propoxy](Sulfanylidene)Phosphonous Acid",
    ],
  },
  {
    primary_id: "D0X0OT",
    names: ["Needle-Free ETEC Vaccine"],
  },
  {
    primary_id: "D0X0PA",
    names: [
      "1-(4-Ethoxyphenyl)Propan-2-Amine",
      "4-Ethoxyamphetamine",
      "Dl-P-Ethoxyamphetamine",
      "129476-58-0",
      "CHEMBL161985",
      "135014-85-6",
      "1-(4-Ethoxyphenyl)Prop-2-Ylamine",
      "Para-Ethoxyamphetamine",
      "Benzeneethanamine, 4-Ethoxy-Alpha-Methyl-",
      "AC1L2OHT",
      "ACMC-1BZ3A",
      "Benzeneethanamine, 4-Ethoxy-Alpha-Methyl-, (+-)-",
      "SCHEMBL2553013",
      "CTK0H5621",
      "CCAMEVFYMFXHEN-UHFFFAOYSA-N",
      "BDBM50005270",
      "AKOS016298265",
      "AKOS017269155",
      "AKOS000155579",
      "1-(4-Ethoxyphenyl)-2-Propanamine #",
      "2-(4-Ethoxy-Phenyl)-1-Methyl-Ethylamine",
    ],
  },
  {
    primary_id: "D0X0PF",
    names: ["Undecanoic Acid"],
  },
  {
    primary_id: "D0X0PO",
    names: ["Sulfamic Acid 6-Sulfamoyloxy-Hexyl Ester", "CHEMBL182276", "SCHEMBL10486465"],
  },
  {
    primary_id: "D0X0PP",
    names: ["AMG 579"],
  },
  {
    primary_id: "D0X0PQ",
    names: ["NHS-IL2-LT"],
  },
  {
    primary_id: "D0X0PW",
    names: ["Revamilast"],
  },
  {
    primary_id: "D0X0QC",
    names: ["DES-6"],
  },
  {
    primary_id: "D0X0QK",
    names: ["NSI-566RSC"],
  },
  {
    primary_id: "D0X0RE",
    names: [
      "4-Methylstyrylboronic Acid",
      "72316-17-7",
      "Trans-2-(4-Methylphenyl)Vinylboronic Acid",
      "(4-Methylstyryl)Boronic Acid",
      "(E)-(4-Methylstyryl)Boronic Acid",
      "CHEMBL551649",
      "AK-96341",
      "AC1O0LYH",
      "AC1Q2JL5",
      "SCHEMBL350162",
      "JJOBVKVXRDHVRP-VOTSOKGWSA-N",
      "[(E)-4-Methylstyryl]boronic Acid",
      "1219AC",
      "BDBM50294579",
      "ZINC169876972",
      "AKOS015893172",
      "AB15070",
      "FCH1119774",
      "FCH4624789",
      "OR360466",
      "KB-61861",
      "BBV-43860393",
      "AX8240132",
      "TX-012607",
      "TC-143527",
      "(E)-2-(4-Methylphenyl)Ethenylboronic Acid",
      "[(E)-2-(4-Methylphenyl)Ethenyl]boranediol",
    ],
  },
  {
    primary_id: "D0X0RI",
    names: ["Mexiletine"],
  },
  {
    primary_id: "D0X0RJ",
    names: ["JNJ-26529126"],
  },
  {
    primary_id: "D0X0RV",
    names: ["PMID26815044-Compound-47"],
  },
  {
    primary_id: "D0X0SK",
    names: ["GSK-2302025A"],
  },
  {
    primary_id: "D0X0TM",
    names: ["Trichuris Suis Ova"],
  },
  {
    primary_id: "D0X0UR",
    names: [
      "LPA",
      "2-Hydroxy-3-(Phosphonooxy)Propyl Oleate",
      "Lysophosphatidic Acid",
      "1-Oleoyl-Lyso-Phosphatidic Acid",
      "Monooleylphosphatidic Acid",
      "Oleoyl Lysophosphatidic Acid",
      "1-Oleyllysophosphatidic Acid",
      "22002-87-5",
      "(2-Hydroxy-3-Phosphonooxypropyl) (Z)-Octadec-9-Enoate",
      "1-Oleoyl-LPA",
      "Monooleylphosphatidate",
      "[3H]1-Oleoyl-LPA",
      "[3H]lysophosphatidic Acid",
      "[3H]LPA",
      "1-Oleoyl-Lysophosphatidic Acid",
      "[3H]-LPA",
      "AC1NUZKL",
      "Lysophosphophatidic Acid",
      "Oleoyl Lysophosphatidate",
      "1-Oleyllysophosphatidate",
      "1-Oleoyl-Lysophosphatidate",
    ],
  },
  {
    primary_id: "D0X0VH",
    names: ["PMID25656651-Compound-28a"],
  },
  {
    primary_id: "D0X0WQ",
    names: ["SHP680"],
  },
  {
    primary_id: "D0X0WU",
    names: [
      "Tolperisone",
      "Agileo",
      "Viveo",
      "AV-650",
      "SPH-3047",
      "Tolperisone (Controlled-Release)",
      "Tolperisone (Controlled-Release), Sanochemia",
      "Tolperisone (Controlled-Release), Sanochemia/Avigen",
    ],
  },
  {
    primary_id: "D0X0XV",
    names: ["Dmt-Pro-Dmp-Phe-NH2", "CHEMBL228409"],
  },
  {
    primary_id: "D0X0YJ",
    names: ["Pyrrolidinyl Urea Derivative 7"],
  },
  {
    primary_id: "D0X0ZC",
    names: ["PMID28766366-Compound-Scheme15-3"],
  },
  {
    primary_id: "D0X0ZD",
    names: [
      "EXRD-4605",
      "DIRA-7471",
      "Epoxide Hydrolase Inhibitors (Hypertension)",
      "Epoxide Hydrolase Inhibitors (Hypertension), Boehringer Ingelheim",
    ],
  },
  {
    primary_id: "D0X0ZK",
    names: [
      "HG-1343",
      "DNA Mismatch Repair Protein PMS1 Modulator (Cancer), Human Genome Sciences",
      "HMLH2 Modulator (Cancer), Human Genome Sciences",
      "HNPCC3 Modulator (Cancer), Human Genome Sciences",
      "Human Mut L Homologue 2 Modulator (Cancer), Human Genome Sciences",
      "Mismatch Repair Gene PMSL1 Modulator (Cancer), Human Genome Sciences",
      "PMS1 Modulator (Cancer), Human Genome Sciences",
      "PMSL1 Modulator (Cancer), Human Genome Sciences",
      "Postmeiotic Segregation 1 Modulator (Cancer), Human Genome Sciences",
      "Postmeiotic Segregation Increased S Cerevisiae 1 Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0X1AF",
    names: ["PMID27998201-Compound-6"],
  },
  {
    primary_id: "D0X1BJ",
    names: [
      "Denibulin",
      "Denibulin Hydrochloride",
      "ANG-600 Series",
      "ANG-615",
      "MN-029",
      "Second Generation VTAs, Angiogene Pharmaceuticals",
      "Second Generation VTAs, MediciNova",
      "Vascular Targeting Agents (Benzimidazole Carbamates), Angiogene Pharmaceuticals",
      "Vascular Targeting Agents (Benzimidazole Carbamates), MediciNova",
    ],
  },
  {
    primary_id: "D0X1BR",
    names: ["MEDI-550"],
  },
  {
    primary_id: "D0X1BT",
    names: ["(S)-3-(Aminomethyl)-4-(Furan-2-Yl)Butanoic Acid", "CHEMBL208216", "SCHEMBL6375241"],
  },
  {
    primary_id: "D0X1BX",
    names: ["HESC-Derived Pancreatic Endoderm (VC-01)"],
  },
  {
    primary_id: "D0X1CB",
    names: ["Trapoxin"],
  },
  {
    primary_id: "D0X1DO",
    names: ["3-[(1-Carboxy-4-Mercaptobutyl)Thio]benzoic Acid"],
  },
  {
    primary_id: "D0X1DV",
    names: ["1-(1-(4'-Ethylbiphenyl-4-Yl)Propyl)-1H-Imidazole", "CHEMBL496257", "BDBM50272583"],
  },
  {
    primary_id: "D0X1EC",
    names: ["JWH-248"],
  },
  {
    primary_id: "D0X1EG",
    names: ["13-(3-N-Pentylureido)Tridec-5(Z)-Enoic Acid", "CHEMBL551162"],
  },
  {
    primary_id: "D0X1EV",
    names: ["TNT009"],
  },
  {
    primary_id: "D0X1EX",
    names: ["TIBENELAST"],
  },
  {
    primary_id: "D0X1EY",
    names: ["6-Iodo-4'-Hydroxyflavone", "CHEMBL388844", "6-Iodo-4''-Hydroxyflavone", "SCHEMBL10144252"],
  },
  {
    primary_id: "D0X1EZ",
    names: [
      "Cyclofenil",
      "2624-43-3",
      "Ondogyne",
      "Cyclofenyl",
      "Cyclophenyl",
      "Cyclopenil",
      "Fertodur",
      "Sanocrisin",
      "Cyclophenil",
      "Sexovar",
      "Rehibin",
      "Sexadieno",
      "Sexovid",
      "Ondonid",
      "Oginex",
      "(Cyclohexylidenemethylene)Bis(4,1-Phenylene) Diacetate",
      "Neoclym",
      "ICI 48213",
      "Cyclofenilum [INN-Latin]",
      "Ciclofenilo [INN-Spanish]",
      "NSC 86464",
      "H 3452",
      "F 6066",
      "UNII-J468V64WZ1",
      "Cyclofenil [INN:BAN:DCF:JAN]",
      "4,4'-(Cyclohexylidenemethylene)Diphenol Diacetate Ester",
      "C23H24O4",
      "EINECS 220-089-1",
      "Bis(P-Hydroxyphenyl)Cyclohexyldienemethane Diacetate",
      "BRN 2014687",
      "AI3-52271",
      "F-6066",
    ],
  },
  {
    primary_id: "D0X1FD",
    names: ["Tricyclic Heterocycle Derivative 1"],
  },
  {
    primary_id: "D0X1FT",
    names: ["BPH-715", "BPH 715", "BPH715"],
  },
  {
    primary_id: "D0X1FX",
    names: ["JWH-441"],
  },
  {
    primary_id: "D0X1GN",
    names: ["BMS-986189"],
  },
  {
    primary_id: "D0X1HE",
    names: ["Ozenoxacin"],
  },
  {
    primary_id: "D0X1HX",
    names: [
      "9-Hydroxypropyladenine, R-Isomer",
      "14047-28-0",
      "(R)-9-(2-Hydroxypropyl)Adenine",
      "(R)-1-(6-Amino-9H-Purin-9-Yl)Propan-2-Ol",
      "(R)-(+)-9-(2-HYDROXYPROPYL)ADENINE",
      "(2R)-1-(6-Aminopurin-9-Yl)Propan-2-Ol",
      "UNII-43H6SBP55W",
      "9-HYDROXYPROPYLADENINE, R-ISOMER",
      "(R)-9-(2-Hydroxypropyl) Adenine",
      "9H-Purine-9-Ethanol, 6-Amino-Alpha-Methyl-, (AlphaR)-",
      "43H6SBP55W",
      "AK-59150",
      "(2R)-1-(6-Amino-9H-Purin-9-Yl)Propan-2-Ol",
      "ARP",
      "W-201193",
      "9-(2-Hydroxypropyl)Adenine, (R)-",
      "PubChem9984",
      "R-9-(2-Hydroxypropyl)Adenine [WHO-DD]",
      "AC1L9HLR",
      "9H-Purine-9-Ethanol, 6-Amino-Alpha-Methyl-, D-",
    ],
  },
  {
    primary_id: "D0X1IB",
    names: ["111In-Labeled LFA-1 Targeted Imaging Agent (Lymphoma/Leukemia), NuView/University Of New Mexico"],
  },
  {
    primary_id: "D0X1IL",
    names: ["SPC4955"],
  },
  {
    primary_id: "D0X1IU",
    names: ["4-Indan-1-Ylmethyl-Phenylamine", "CHEMBL364089"],
  },
  {
    primary_id: "D0X1JG",
    names: [
      "3-(2,5-Dimethyl-1H-Pyrrol-1-Yl)Benzoic Acid",
      "26180-28-9",
      "3-(2,5-Dimethylpyrrol-1-Yl)Benzoic Acid",
      "3-(2,5-Dimethyl-Pyrrol-1-Yl)-Benzoic Acid",
      "MLS000053036",
      "Benzoic Acid, M-(2,5-Dimethylpyrrol-1-Yl)-",
      "SMR000068999",
      "M-(2,5-Dimethylpyrrol-1-Yl)Benzoic Acid",
      "Benzoic Acid,3-(2,5-Dimethyl-1H-Pyrrol-1-Yl)-",
      "3-(2,5-Dimethylpyrrolyl)Benzoic Acid",
      "SR-01000430237",
      "3-(2,5-Dimethylpyrrol-1-Yl) Benzoic Acd",
      "Benzoic Acid, 3-(2,5-Dimethyl-1h-Pyrrol-1-Yl)-",
      "BAS 00687694",
      "AC1L4UOW",
      "Probes2_000222",
      "Probes2_000239",
      "Probes1_000276",
    ],
  },
  {
    primary_id: "D0X1JO",
    names: ["IN1479"],
  },
  {
    primary_id: "D0X1KN",
    names: ["Venetoclax"],
  },
  {
    primary_id: "D0X1LE",
    names: ["ST-383"],
  },
  {
    primary_id: "D0X1LO",
    names: ["EMR-62203"],
  },
  {
    primary_id: "D0X1LP",
    names: ["N-Acylpiperidine Ether Derivative 3"],
  },
  {
    primary_id: "D0X1LW",
    names: ["Ragweed Pollen Allergen Sublingual Immunotherapy"],
  },
  {
    primary_id: "D0X1MJ",
    names: ["Bidentate Pyrazolopyrimidine Acetamide Analog 1"],
  },
  {
    primary_id: "D0X1MK",
    names: ["PMID28870136-Compound-37"],
  },
  {
    primary_id: "D0X1MY",
    names: [
      "5-Amino-[1,3,4]thiadiazole-2-Thiol",
      "5-Amino-1,3,4-Thiadiazole-2-Thiol",
      "2349-67-9",
      "2-Amino-5-Mercapto-1,3,4-Thiadiazole",
      "USAF PD-25",
      "1,3,4-Thiadiazole-2(3H)-Thione, 5-Amino-",
      "NSC 21402",
      "5-Amino-1,3,4-Thiadiazole-2(3H)-Thione",
      "5-Amino-2-Mercapto-1,3,4-Thiadiazole",
      "UNII-B1HEG7V21S",
      "2-AMINO-1,3,4-THIADIAZOLE-5-THIOL",
      "2-Thiol-5-Amino-1,3,4-Thiadiazole",
      "NSC 209061",
      "CCRIS 6893",
      "5-Amino-1,3,4-Thiadiazoline-2-Thione",
      "2-Mercapto-5-Amino-1,3,4-Thiadiazole",
      "1,3,4-Thiadiazole-2-Thiol, 5-Amino-",
      "EINECS 219-078-4",
      "B1HEG7V21S",
      "5-Amino-3H-1,3,4-Thiadiazole-2-Thione",
    ],
  },
  {
    primary_id: "D0X1NL",
    names: ["ISIS 101998"],
  },
  {
    primary_id: "D0X1NW",
    names: [
      "1-(9-Oxo-9H-Fluoren-4-Yl)-3-Pyridin-2-Yl-Urea",
      "Diarylurea Deriv. 15",
      "AC1NS9HQ",
      "BDBM6655",
      "CHEMBL140589",
      "SCHEMBL12856374",
      "ZINC13471114",
      "1-(9-Oxofluoren-4-Yl)-3-Pyridin-2-Ylurea",
      "3-(9-Oxo-9H-Fluoren-4-Yl)-1-Pyridin-2-Ylurea",
      "1-(9-Oxo-9H-Fluorene-4-Yl)-3-(2-Pyridyl)Urea",
      "N-(9-Oxo-9H-Fluoren-4-Yl)-N -Pyridin-2-Ylurea",
    ],
  },
  {
    primary_id: "D0X1NX",
    names: [
      "ACE-920",
      "Campylobacter/ETEC Combination Vaccine (Traveller's Diarrhea), ACE BioSciences",
      "Recombinant ACE-527 Enterotoxigenic E Coli (ETEC) Strain Vaccine Expressing ACE-393 C Jejuni Antigen, ACE BioSciences",
    ],
  },
  {
    primary_id: "D0X1OO",
    names: ["PMID25666693-Compound-39"],
  },
  {
    primary_id: "D0X1OV",
    names: ["HO/07/09"],
  },
  {
    primary_id: "D0X1OW",
    names: [
      "AZD9496",
      "DFBDRVGWBHBJNR-BBNFHIFMSA-N",
      "1639042-08-2",
      "UNII-DA9P7LN909",
      "DA9P7LN909",
      "CHEMBL3623004",
      "AZD-9496",
      "(E)-3-(3,5-Difluoro-4-((1R,3R)-2-(2-Fluoro-2-Methylpropyl)-3-Methyl-2,3,4,9-Tetrahydro-1H-Pyrido[3,4-B]indol-1-Yl)Phenyl)Acrylic Acid",
      "(E)-3-(3,5-Difluoro-4-((1R,3R)-2-(2-Fluoro-2-Methylpropyl)-3-Methyl-2,3,4,9-Tetrahydro-1H-Pyrido(3,4-B)Indol-1-Yl)Phenyl)Acrylic Acid",
      "SCHEMBL16266273",
      "SCHEMBL16266275",
      "MolPort-044-560-384",
      "EX-A1326",
      "S8372",
      "BDBM50125052",
      "ZINC219669733",
      "AKOS030526632",
      "AC-29011",
      "HY-12870",
      "Selective Estrogen",
    ],
  },
  {
    primary_id: "D0X1OY",
    names: [
      "(3,4-Dichlorophenyl)(1H-Pyrazol-1-Yl)Methanone",
      "AC1LDSBN",
      "BAS 02052919",
      "N-Benzoylpyrazole Deriv., 8",
      "CHEMBL244917",
      "ZINC37156",
      "BDBM23706",
      "MolPort-001-906-669",
      "3,4-Dichlorophenyl Pyrazolyl Ketone",
      "STK044137",
      "AKOS000577014",
      "MCULE-6190861509",
      "ST033189",
      "(3,4-Dichlorophenyl)-Pyrazol-1-Ylmethanone",
      "(3,4-Dichloro-Phenyl)-Pyrazol-1-Yl-Methanone",
      "1-[(3,4-Dichlorophenyl)Carbonyl]-1H-Pyrazole",
      "SR-01000521426",
      "SR-01000521426-1",
    ],
  },
  {
    primary_id: "D0X1QM",
    names: ["AT-001"],
  },
  {
    primary_id: "D0X1QN",
    names: ["Neurotrophic Factor-Producing Stem Cells"],
  },
  {
    primary_id: "D0X1QS",
    names: [
      "3-(4-Methyl-3,4-Dihydronaphthalen-2-Yl)Pyridine",
      "Dihydronaphthalene 9",
      "AC1O70HH",
      "SCHEMBL4507080",
      "CHEMBL206070",
    ],
  },
  {
    primary_id: "D0X1QW",
    names: [
      "(+)-AJ76",
      "AJ 76",
      "AJ-76",
      "5-Methoxy-1-Methyl-2-(N-Propylamino)Tetralin",
      "85379-09-5",
      "CHEMBL27441",
      "(+)-AJ 76",
      "CHEBI:64117",
      "(1S,2R)-5-Methoxy-1-Methyl-N-Propyl-1,2,3,4-Tetrahydronaphthalen-2-Amine",
      "Cis-(+)-5-Methoxy-1-Methyl-2-(Propylamino)Tetralin",
      "(1S,2R)-5-Methoxy-1-Methyl-2-(Propylamino)Tetralin",
      "2-Naphthalenamine, 1,2,3,4-Tetrahydro-5-Methoxy-1-Methyl-N-Propyl-, (1S-Cis)-",
      "Tocris-0678",
      "AC1L3UEJ",
      "A J76",
      "Biomol-NT_000004",
      "GTPL970",
      "SCHEMBL276252",
      "AJ76",
      "BPBio1_001129",
      "DTXSID1043889",
      "BDBM81797",
      "ZINC3995669",
      "PDSP2_000777",
      "BDBM50041959",
    ],
  },
  {
    primary_id: "D0X1RG",
    names: ["[3H]alanine", "56-41-7", "2-Aminopropanoic Acid, L-", "[14C]alanine"],
  },
  {
    primary_id: "D0X1RH",
    names: ["GSK579289A", "Compound 25 [PMID 19237286]", "GSK-579289A"],
  },
  {
    primary_id: "D0X1RQ",
    names: ["MTR105"],
  },
  {
    primary_id: "D0X1RR",
    names: ["Coccidioidin"],
  },
  {
    primary_id: "D0X1RZ",
    names: ["[D-4Aph(CO-NH-(C2H4O)2-C2H5)6]degarelix", "CHEMBL438239"],
  },
  {
    primary_id: "D0X1SN",
    names: [
      "H-Tyr-D-Ala-Aic-Asp-Val-Val-Gly-NH2",
      "CHEMBL124435",
      "BDBM50001462",
      "3-[(2-{2-[2-Amino-3-(4-Hydroxy-Phenyl)-Propionylamino]-Propionylamino}-Indane-2-Carbonyl)-Amino]-N-{1-[1-(Carbamoylmethyl-Carbamoyl)-2-Methyl-Propylcarbamoyl]-2-Methyl-Propyl}-Succinamic Acid",
    ],
  },
  {
    primary_id: "D0X1ST",
    names: ["ESR0114"],
  },
  {
    primary_id: "D0X1SV",
    names: ["Epratuzumab Y-90"],
  },
  {
    primary_id: "D0X1TD",
    names: ["RemeStim-CEA"],
  },
  {
    primary_id: "D0X1TN",
    names: ["Sergliflozin"],
  },
  {
    primary_id: "D0X1VJ",
    names: [
      "AGI-1067",
      "AGZ-1067",
      "Succinic Acid 2,6-Di-Tert-Butyl-4-[1-(3,5-Di-Tert-Butyl-4-Hydroxyphenylsulfanyl)-1-Methylethylsulfanyl]phenyl Monoester",
    ],
  },
  {
    primary_id: "D0X1VL",
    names: ["F(4-Fluoro)VAE", "CHEMBL258583", "F(4-Fluro)VAE"],
  },
  {
    primary_id: "D0X1WJ",
    names: ["Josamycin"],
  },
  {
    primary_id: "D0X1XA",
    names: [
      "62Cu-ETS",
      "Copper-62-Labeled PET Renal Perfusion Imaging Agent, Proportional Technologies",
      "62Cu-Labeled PET Renal Perfusion Imaging Agent, Proportional Technologies",
    ],
  },
  {
    primary_id: "D0X1XK",
    names: ["CPC-212"],
  },
  {
    primary_id: "D0X1XM",
    names: [
      "LU302872",
      "CHEMBL102405",
      "AC1O5FKI",
      "SCHEMBL3894792",
      "Lu224332",
      "BDBM50079417",
      "L017653",
      "3-[2-(3,4-Dimethoxyphenyl)Ethoxy]-2-(4,6-Dimethylpyrimidin-2-Yl)Oxy-3,3-Diphenylpropanoic Acid",
      "3-[2-(3,4-Dimethoxy-Phenyl)-Ethoxy]-2-(4,6-Dimethyl-Pyrimidin-2-Yloxy)-3,3-Diphenyl-Propionic Acid",
      "2-[(4,6-Dimethylpyrimidine-2-Yl)Oxy]-3-[2-(3,4-Dimethoxyphenyl)Ethoxy]-3,3-Diphenylpropionic Acid",
    ],
  },
  {
    primary_id: "D0X1YF",
    names: ["T-1095"],
  },
  {
    primary_id: "D0X1YL",
    names: ["Ac-YRC(Me)*EHdFRWC(Me)NH2", "CHEMBL427666"],
  },
  {
    primary_id: "D0X1YZ",
    names: ["KM-4056", "Anti-LGR5 Monoclonal Antibody (Cancer), Kyowa Hakko Kirin"],
  },
  {
    primary_id: "D0X1ZA",
    names: ["PMID29338548-Compound-28"],
  },
  {
    primary_id: "D0X1ZC",
    names: [
      "IRL-1841",
      "CHEMBL439759",
      "169544-71-2",
      "SCHEMBL8721943",
      "CTK0E5016",
      "DTXSID80431752",
      "BDBM50071431",
      "L-Tryptophan, N-[N-(3,5-Dimethylbenzoyl)-N-Methyl-D-Phenylalanyl]-",
      "(S)-2-{(R)-2-[(3,5-Dimethyl-Benzoyl)-Methyl-Amino]-3-Phenyl-Propionylamino}-3-(1H-Indol-3-Yl)-Propionic Acid",
    ],
  },
  {
    primary_id: "D0X1ZP",
    names: [
      "5-Fluorouridine",
      "316-46-1",
      "5-Fur",
      "Uridine, 5-Fluoro-",
      "FUrd",
      "1-((2R,3R,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl)-5-Fluoropyrimidine-2,4(1H,3H)-Dione",
      "5-Fluoro-Uridine",
      "UNII-4K0M952561",
      "EINECS 206-260-3",
      "77180-80-4",
      "5-Fluorouracil 1beta-D-Ribofuranoside",
      "NSC 146604",
      "Fluorouridine",
      "BRN 0033662",
      "CHEMBL54918",
      "CHEBI:40154",
      "FHIDNBAQOFJWCA-UAKXSSHOSA-N",
      "5-Fluoro-1-(Beta-D-Ribofuranosyl)Uracil",
      "4K0M952561",
      "C9H11FN2O6",
      "5UD",
      "1-[(4S,2R,3R,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-5-Fluoro-1,3-Dihy Dropyrimidine-2,4-",
    ],
  },
  {
    primary_id: "D0X1ZV",
    names: [
      "Trimidox",
      "3,4,5-Trihydroxybenzamidoxime",
      "CCRIS 7910",
      "Benzenecarboximidamide, N,3,4,5-Tetrahydroxy-",
      "95933-74-7",
      "AC1NUZ8S",
      "SCHEMBL4168968",
      "CHEMBL1214553",
      "CHEBI:94508",
      "N,3,4,5-Tetrahydroxybenzamidine",
      "MolPort-044-561-997",
      "N',3,4,5-Tetrahydroxybenzimidamide",
      "3,4,5-Trihydroxybenzamidoxamic Acid",
      "LS-29453",
      "BRD-K11801786-003-01-7",
      "T01312",
    ],
  },
  {
    primary_id: "D0X2AT",
    names: ["Rhopressa"],
  },
  {
    primary_id: "D0X2AU",
    names: ["Selenium Compound 1"],
  },
  {
    primary_id: "D0X2BO",
    names: ["PMID25435179-Compound-WO2012149608USYDS1"],
  },
  {
    primary_id: "D0X2BP",
    names: ["Antibodie Derivative 1"],
  },
  {
    primary_id: "D0X2CC",
    names: [
      "1-(9H-Beta-Carbolin-3-Yl)-Butan-1-One",
      "CHEMBL69719",
      "AC1NFSYI",
      "3-Butyryl-Beta-Carboline",
      "CHEMBL510764",
      "ZINC7260873",
      "BDBM50001474",
    ],
  },
  {
    primary_id: "D0X2CX",
    names: ["KNI-10062"],
  },
  {
    primary_id: "D0X2DH",
    names: [
      "3-(1H-Indol-3-Yl)-4-Phenylamino-Pyrrole-2,5-Dione",
      "CHEMBL183310",
      "SCHEMBL7840920",
      "1H-Pyrrole-2,5-Dione,3-(1H-Indol-3-Yl)-4-(Phenylamino)-",
      "BDBM50153450",
      "257878-66-3",
    ],
  },
  {
    primary_id: "D0X2DK",
    names: [
      "Phenoxybenzamine",
      "Bensylyt",
      "Bensylyte",
      "Benzylyt",
      "Dibenylene",
      "Dibenylin",
      "Dibenyline",
      "Dibenziran",
      "Dibenzylene",
      "Dibenzylin",
      "Dibenzyran",
      "Fenossibenzamina",
      "Fenoxibenzamina",
      "Phenoxybenzaminum",
      "Bensylyt NEN",
      "Fenossibenzamina [DCIT]",
      "POB HCl",
      "Phenoxybenzamine Hcl",
      "A 688",
      "Dibenzyline (TN)",
      "Fenoxibenzamina [INN-Spanish]",
      "Phenoxybenzamine (INN);Phenoxybenzamine [INN:BAN]",
      "Phenoxybenzaminum [INN-Latin]",
      "N-Phenoxyisopropyl-N-Benzyl-Beta-Chloroethylamine",
      "Benzyl(2-Chloroethyl)-(1-Methyl-2-Phenoxyethyl)Amine",
      "N-(2-Chloroethyl)-N-(1-Methyl-2-Phenoxyethyl)Benzenemethanamine",
      "N-(2-Chloroethyl)-N-(1-Methyl-2-Phenoxyethyl)Benzylamine",
      "N-Benzyl-N-(2-Chloroethyl)-1-Phenoxypropan-2-Amine",
      "N-(2-Chloroethyl)-N-(Phenylmethyl)-1-(Phenyloxy)Propan-2-Amine",
      "2-(N-Benzyl-2-Chloroethylamino)-1-Phenoxypropane",
      "688A",
    ],
  },
  {
    primary_id: "D0X2EM",
    names: ["UNI-PH (1-34)"],
  },
  {
    primary_id: "D0X2FT",
    names: ["Gadoxetate Disodium"],
  },
  {
    primary_id: "D0X2GF",
    names: ["9-Deazaguanine"],
  },
  {
    primary_id: "D0X2GM",
    names: ["PRX-12256"],
  },
  {
    primary_id: "D0X2HA",
    names: ["H-Dmt-Tic-NH-CH[(CH2)4-NH-Ac]-Bid"],
  },
  {
    primary_id: "D0X2HD",
    names: ["ELS-120"],
  },
  {
    primary_id: "D0X2HE",
    names: ["GlycoPEG-GCSF"],
  },
  {
    primary_id: "D0X2HI",
    names: ["ISIS 28405"],
  },
  {
    primary_id: "D0X2HJ",
    names: ["PTI-125"],
  },
  {
    primary_id: "D0X2HK",
    names: ["N-{4-[2-(4-Hydroxyphenyl)Ethyl]phenyl}phthalimide", "CHEMBL575857"],
  },
  {
    primary_id: "D0X2HT",
    names: ["M3IBu6S", "CHEMBL211576"],
  },
  {
    primary_id: "D0X2IE",
    names: ["Dimercaprol"],
  },
  {
    primary_id: "D0X2IG",
    names: [
      "2-Amino-6-Benzenesulfonyl-Benzonitrile",
      "2-Amino-6-(Benzenesulfonyl)Benzonitrile",
      "6-Amino-2-(Phenylsulfonyl)Benzenecarbonitrile",
      "AC1LAE3B",
      "CHEMBL55271",
      "BDBM1781",
      "CTK7C6577",
      "ZINC5939149",
      "2-(Phenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3a",
    ],
  },
  {
    primary_id: "D0X2JG",
    names: [
      "(2-Chloro-6-Phenoxyphenyl)Methanamine",
      "2-Chloro-6-Phenoxybenzylamine",
      "175136-89-7",
      "CHEMBL203105",
      "AC1MCRJE",
      "Rarechem Al Bw 1398",
      "SCHEMBL112755",
      "AC1Q53T0",
      "2-Chloro-6-Phenoxy-Benzylamine",
      "CTK4D5311",
      "DTXSID20380412",
      "SNTOZVXKDWQFEW-UHFFFAOYSA-N",
      "MolPort-000-144-285",
      "ZINC159665",
      "ZX-AT024720",
      "2-Aminomethyl-3-Chlorodiphenylether",
      "SBB098143",
      "BDBM50178429",
      "(6-Chloro-2-Phenoxyphenyl)Methylamine",
      "2-Chloro-6-Phenoxybenzylamine, 95+%",
      "AKOS009159323",
      "Benzenemethanamine,2-Chloro-6-Phenoxy-",
      "KB-68526",
      "SC-40136",
      "AJ-15516",
      "ACM175136897",
      "DB-030613",
    ],
  },
  {
    primary_id: "D0X2JM",
    names: ["XL418"],
  },
  {
    primary_id: "D0X2JU",
    names: ["N-Methoxyquinuclidine-3-Carboximidoyl Fluoride", "CHEMBL341766"],
  },
  {
    primary_id: "D0X2JY",
    names: ["Multiclade DNA Recombinant Adenovirus-5 (RAd5) Vaccine"],
  },
  {
    primary_id: "D0X2KY",
    names: [
      "99mTc-Hynic-Annexin V",
      "Apomate",
      "Annexin V",
      "Hynic-Annexin V",
      "Technetium-99m-Annexin V",
      "Technetium-99m-Hynic-Annexin V",
      "Technetium-99m-Rh-Annexin V",
      "99mTc-Annexin V",
      "99mTc-Rh-Annexin V",
    ],
  },
  {
    primary_id: "D0X2LB",
    names: ["IDP-120 Gel"],
  },
  {
    primary_id: "D0X2LN",
    names: ["FKB-001"],
  },
  {
    primary_id: "D0X2LQ",
    names: ["LY03004"],
  },
  {
    primary_id: "D0X2LV",
    names: ["Betamethasone Butyrate Propion", "Antebate (TN)"],
  },
  {
    primary_id: "D0X2LW",
    names: ["DPT/Hib Vaccine"],
  },
  {
    primary_id: "D0X2MB",
    names: [
      "Oxprenolol",
      "Coretal",
      "Koretal",
      "Laracor",
      "Osprenololo",
      "Oxprenololum",
      "Tevacor",
      "Trasacor",
      "Trasicor",
      "Osprenololo [DCIT]",
      "Slow Trasicor",
      "Trasicor 80",
      "Captol (TN)",
      "Corbeton (TN)",
      "Coretal (TN)",
      "Dl-Alprenolol",
      "Dl-Oxprenolol",
      "Laracor (TN)",
      "Oxprenolol (INN)",
      "Oxprenolol (TN)",
      "Oxprenolol [INN:BAN]",
      "Oxprenololum [INN-Latin]",
      "Slow-Pren",
      "Tevacor (TN);Trasacor (TN)",
      "Trasicor (TN)",
      "Trasidex (TN)",
      "Trasitensin (TN)",
      "Slow-Pren (TN)",
      "Slow-Trasicor (TN)",
      "Coretal (*hydrochloride*)",
      "Laracor (*hydrochloride*)",
      "Oxprenolol Ba 39089 (*hydrochloride*)",
      "Trasacor (*hydrochloride*)",
      "Trasicor (*hydrochloride*)",
      "Slow-Pren (*hydrochloride*)",
      "(+)-1-(O-(Allyloxy)Phenoxy)-3-(Isopropylamino)Propan-2-Ol",
      "(+-)-Oxprenolol",
      "(1)-1-(O-(Allyloxy)Phenoxy)-3-(Isopropylamino)Propan-2-Ol",
      "1-((1-Methylethyl)Amino)-3-(2-(2-Propenyloxy)Phenoxy)-2-Propanol",
      "1-(Isopropylamino)-2-Hydroxy-3-(O-(Allyloxy)Phenoxy)Propane",
      "1-(O-(Allyloxy)Phenoxy)-3-(Isopropylamino)-2-Propanol",
      "1-(Propan-2-Ylamino)-3-(2-Prop-2-Enoxyphenoxy)Propan-2-Ol",
      "1-[(1-Methylethyl)Amino]-3-[2-(2-Propenyloxy)Phenoxy]-2-Propanol",
      "1-[2-(Allyloxy)Phenoxy]-3-(Isopropylamino)-2-Propanol",
    ],
  },
  {
    primary_id: "D0X2MF",
    names: ["Indoline Derivative 20"],
  },
  {
    primary_id: "D0X2MQ",
    names: [
      "17-Phenyl-Omega-Trinor-PGE2",
      "17-Phenyl-Omega-Trinor-Prostaglandin E2",
      "17-Phenyl-Trinor-PGE2",
      "17-Ph-Omega-Trinor-PGE2",
      "17-Phenyl-PGE2",
    ],
  },
  {
    primary_id: "D0X2MZ",
    names: ["Syn-1020"],
  },
  {
    primary_id: "D0X2NB",
    names: ["AZD-6319", "Alpha 7 Neuronal Nicotinic Receptor Agonist (Alzheimer's Disease), AstraZeneca"],
  },
  {
    primary_id: "D0X2NE",
    names: ["PAT-SM3"],
  },
  {
    primary_id: "D0X2NH",
    names: ["NSC-126445", "CHEMBL376528", "BDBM50158382", "ZINC118925239"],
  },
  {
    primary_id: "D0X2NN",
    names: ["BIBT986"],
  },
  {
    primary_id: "D0X2NO",
    names: ["(+)-ADTN", "(+)-ADTN (Aminotetralin-6,7-Diol)"],
  },
  {
    primary_id: "D0X2OD",
    names: [
      "18beta-Glycyrrhetic Acid",
      "18b-Glycyrrhetic Acid",
      "CHEMBL208873",
      "18b-Glycyrrhetinic Acid",
      "Glycyrrhetinic Acid, 8",
      "18",
      "A-Glycyrrhetintic Acid",
      "SCHEMBL231125",
      "BDBM23195",
      "Pentacyclic Triterpene Compound 8",
      "MolPort-023-220-126",
      "AKOS016009607",
      "Cent-GLYCYRRHETINTIC ACID",
      "AS-11631",
      "SC-17800",
      "(2S,4aS,6aS,6bR,10S,12aS,12bR,14bR)-10-Hydroxy-2,4a,6a,6b,9,9,12a-Heptamethyl-13-Oxo-1,2,3,4,4a,5,6,6a,6b,7,8,8a,9,10,11,12,12a,12b,13,14b-Icosahydropicene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0X2OV",
    names: [
      "ONO-8711",
      "6-[(2R,3S)-3-(4-Chloro-2-Methylphenylsulfonamidomethyl)Bicyclo[2.2.2]oct-2-Yl]-5(Z)-Hexenoic Acid",
    ],
  },
  {
    primary_id: "D0X2PQ",
    names: ["IX207-887"],
  },
  {
    primary_id: "D0X2PR",
    names: [
      "MEDI-541",
      "Anti-HMGB-1 MAb",
      "HMGB-1 Antagonists, Cornerstone Therapeutics",
      "HMGB-1 Antibodies, Critical Therapeutics",
      "High Mobility Group Box Chromosomal Protein 1 Antagonists, MedImmune/Cornerstone Therapeutics",
      "High Mobility Group Box Chromosomal Protein 1 Antagonists, MedImmune/Critical Therapeutics",
      "HMGB-1 Antagonists, MedImmune/Cornerstone Therapeutics",
      "HMGB-1 Antagonists, MedImmune/Critical Therapeutics",
      "MGB-1 Antagonists (Human Monoclonal Antibodies), Critical Therapeutics/MedImmune",
    ],
  },
  {
    primary_id: "D0X2QH",
    names: ["PBI-1402"],
  },
  {
    primary_id: "D0X2RI",
    names: ["HYG-112"],
  },
  {
    primary_id: "D0X2RJ",
    names: ["Technetium TC 99M Votumumab"],
  },
  {
    primary_id: "D0X2RL",
    names: ["LY3012212"],
  },
  {
    primary_id: "D0X2SG",
    names: ["PMID25991433-Compound-P4"],
  },
  {
    primary_id: "D0X2SY",
    names: [
      "CR-3663",
      "CR-3663 Series (Osteoarthritis)",
      "NMDA Receptor Antagonists (Osteoarthritis), Rottapharm Madaus",
      "CR-3663 Series (Osteoarthritis), Rottapharm Madaus",
    ],
  },
  {
    primary_id: "D0X2TO",
    names: [
      "Adatanserin",
      "Adatanserin [INN]",
      "WY 50324",
      "N-[2-(4-Pyrimidin-2-Ylpiperazin-1-Yl)Ethyl]adamantane-1-Carboxamide",
    ],
  },
  {
    primary_id: "D0X2TZ",
    names: ["Streptococcus Pneumoniae Vaccine"],
  },
  {
    primary_id: "D0X2UE",
    names: ["Sulprostone"],
  },
  {
    primary_id: "D0X2UI",
    names: ["LIK-066"],
  },
  {
    primary_id: "D0X2UK",
    names: ["Affitope AD-01", "AD-01", "Amyloid Beta 40-42 Peptide Vaccine (Sc, Alzheimer's), AFFiRiS"],
  },
  {
    primary_id: "D0X2UN",
    names: ["Radiprodil"],
  },
  {
    primary_id: "D0X2UO",
    names: ["1-Aryl-4-Methyl-[1,2,4]triazolo[4,3-A]quinoxaline Derivative 5"],
  },
  {
    primary_id: "D0X2VK",
    names: [
      "2-(4-Hydroxyphenyl)-1,2'-Spirobi[1H-Indene]-5-Ol",
      "CHEMBL281499",
      "SCHEMBL6286186",
      "BDBM50123205",
      "2-(4-Hydroxyphenyl)-1,2''-Spirobi[1H-Indene]-5-Ol",
    ],
  },
  {
    primary_id: "D0X2VN",
    names: ["Formycin B"],
  },
  {
    primary_id: "D0X2XF",
    names: ["Piboserod"],
  },
  {
    primary_id: "D0X2XQ",
    names: ["1-(2-Amino-Benzothiazol-6-Yl)-2-Ethyl-Isothiourea", "CHEMBL233022", "SCHEMBL6067873"],
  },
  {
    primary_id: "D0X2XZ",
    names: ["CFI-400945", "CFI 400945", "CFI400945"],
  },
  {
    primary_id: "D0X2YI",
    names: ["IPLl576092"],
  },
  {
    primary_id: "D0X2ZM",
    names: ["T1117"],
  },
  {
    primary_id: "D0X2ZW",
    names: ["IKs124"],
  },
  {
    primary_id: "D0X2ZY",
    names: ["N-Quinolin-Benzene Sulphonamide Derivative 2"],
  },
  {
    primary_id: "D0X3AK",
    names: ["GL-438"],
  },
  {
    primary_id: "D0X3AT",
    names: ["CCP"],
  },
  {
    primary_id: "D0X3BG",
    names: ["Acid Sphingomyelinase"],
  },
  {
    primary_id: "D0X3CD",
    names: ["Thiarabine"],
  },
  {
    primary_id: "D0X3CF",
    names: ["HINOKININ"],
  },
  {
    primary_id: "D0X3DK",
    names: ["KSP-BCS-1-Alpha-CHF2-1624F2-2", "CHEMBL220140"],
  },
  {
    primary_id: "D0X3ER",
    names: ["OP-145"],
  },
  {
    primary_id: "D0X3FC",
    names: ["ISIS 101995"],
  },
  {
    primary_id: "D0X3FF",
    names: ["HALOXYSTEROL B"],
  },
  {
    primary_id: "D0X3FT",
    names: ["Coumermycin"],
  },
  {
    primary_id: "D0X3FX",
    names: ["Naloxone"],
  },
  {
    primary_id: "D0X3GA",
    names: ["NN8717"],
  },
  {
    primary_id: "D0X3GD",
    names: ["LPCVD"],
  },
  {
    primary_id: "D0X3GG",
    names: [
      "Ro48-8071",
      "[4-({6-[Allyl(Methyl)Amino]hexyl}oxy)-2-Fluorophenyl](4-Bromophenyl)Methanone",
      "Ro 48-8071",
      "161582-11-2",
      "Ro-48-8071",
      "[4-({6-[allyl(Methyl)Amino]hexyl}oxy)-2-Fluorophenyl](4-Bromophenyl)Methanone",
      "CHEMBL304858",
      "R048-8071",
      "R71",
      "1gsz",
      "(4-Bromophenyl)[2-Fluoro-4-({6-[methyl(Prop-2-En-1-Yl)Amino]hexyl}oxy)Phenyl]methanone",
      "C23H27BRFNO2",
      "{4-[6-(Allyl-Methyl-Amino)-Hexyloxy]-2-Fluoro-Phenyl}-(4-Bromo-Phenyl)-Methanone",
      "(4-((6-(Allyl(Methyl)Amino)Hexyl)Oxy)-2-Fluorophenyl)(4-Bromophenyl)Methanone",
      "[4-[6-(Allyl-Methyl-Amino)-Hexyloxy]-2-Fluoro-Phenyl]-(4-Bromo-Phenyl)-Methanone",
    ],
  },
  {
    primary_id: "D0X3HB",
    names: ["KKHT-10609"],
  },
  {
    primary_id: "D0X3HV",
    names: ["BI-695500"],
  },
  {
    primary_id: "D0X3IJ",
    names: ["CaspaCIDe DLI"],
  },
  {
    primary_id: "D0X3JB",
    names: [
      "1-[3-(Methylsulfonyl)Phenyl]-4-Propylpiperazine",
      "CHEMBL596801",
      "SCHEMBL3453749",
      "IIFAQXPNYXJBMU-UHFFFAOYSA-N",
      "BDBM50308027",
    ],
  },
  {
    primary_id: "D0X3JK",
    names: ["V120083"],
  },
  {
    primary_id: "D0X3JU",
    names: ["Semaglutide Oral"],
  },
  {
    primary_id: "D0X3JV",
    names: ["MalariVax"],
  },
  {
    primary_id: "D0X3JY",
    names: ["NTX-010"],
  },
  {
    primary_id: "D0X3KX",
    names: ["U-80816"],
  },
  {
    primary_id: "D0X3LD",
    names: ["Valtorcitabine"],
  },
  {
    primary_id: "D0X3MK",
    names: ["DZNep", "3-Deazaneplanocin A"],
  },
  {
    primary_id: "D0X3NK",
    names: ["MP-PN-1"],
  },
  {
    primary_id: "D0X3NW",
    names: ["PMID21444206C8g"],
  },
  {
    primary_id: "D0X3OF",
    names: ["NPT-1162"],
  },
  {
    primary_id: "D0X3OI",
    names: ["TAK-700"],
  },
  {
    primary_id: "D0X3OU",
    names: ["Parkinsons Therapeutic Peptides"],
  },
  {
    primary_id: "D0X3PD",
    names: ["I-ABOPX", "BW-A522"],
  },
  {
    primary_id: "D0X3PN",
    names: [
      "DR-5/DR-4 Cross Reactive Mabs",
      "DR-5/DR-4 Cross Reactive MAbs (Cancer)",
      "Death Receptor-5/4 Cross Reactive MAbs (Cancer), Crystal Bioscience",
      "DR-5/DR-4 Cross Reactive MAbs (Cancer), Crystal Bioscience",
    ],
  },
  {
    primary_id: "D0X3QL",
    names: ["PD-3766"],
  },
  {
    primary_id: "D0X3QR",
    names: ["AVA-101"],
  },
  {
    primary_id: "D0X3RK",
    names: ["LLY-507"],
  },
  {
    primary_id: "D0X3RW",
    names: ["Demotensin 4", "CHEMBL375002"],
  },
  {
    primary_id: "D0X3RZ",
    names: ["L-Tryptophan-L-Leucine"],
  },
  {
    primary_id: "D0X3SE",
    names: ["Isopropyl-Methyl-Prop-2-Ynyl-Amine Hydrochloride"],
  },
  {
    primary_id: "D0X3SO",
    names: [
      "1-(Propan-2-Ylidene)Thiosemicarbazide",
      "Acetone Thiosemicarbazone",
      "1752-30-3",
      "Thiosemicarbazone Acetone",
      "ACETONE THIOSEMICARBAZIDE",
      "Acetone, Thiosemicarbazone",
      "Hydrazinecarbothioamide, 2-(1-Methylethylidene)-",
      "Acetonthiosemikarbazon",
      "Acetonethiosemicarbazone",
      "2-(1-Methylethylidene)Hydrazinecarbothioamide",
      "NSC 711",
      "Acetonthiosemikarbazon [Czech]",
      "UNII-C3U604L47F",
      "HSDB 6422",
      "EINECS 217-137-9",
      "(Isopropylideneamino)Thiourea",
      "AI3-22961",
      "CHEMBL500557",
      "(Propan-2-Ylideneamino)Thiourea",
      "C3U604L47F",
      "2-(Propan-2-Ylidene)Hydrazinecarbothioamide",
      "WLN: SUYZMNUY1&amp",
      "AC1MCI32",
    ],
  },
  {
    primary_id: "D0X3TF",
    names: ["CP55,667", "CP 55,667", "CP-55,667"],
  },
  {
    primary_id: "D0X3TI",
    names: ["SRT3025"],
  },
  {
    primary_id: "D0X3TL",
    names: ["3,14-DIDEHYDROGINKGOLIDE A", "3,14-Didehydroginkgolide A", "CHEMBL373969"],
  },
  {
    primary_id: "D0X3TO",
    names: ["PMID20598884C8"],
  },
  {
    primary_id: "D0X3TX",
    names: ["ISIS 112694"],
  },
  {
    primary_id: "D0X3UG",
    names: [
      "Antivaginolysin Single Chain Variable Fragments",
      "Antivaginolysin Single Chain Variable Fragments (Bacterial Vaginosis)",
    ],
  },
  {
    primary_id: "D0X3UL",
    names: ["RT-400"],
  },
  {
    primary_id: "D0X3UW",
    names: ["Saurestat"],
  },
  {
    primary_id: "D0X3VM",
    names: ["VU0155041"],
  },
  {
    primary_id: "D0X3XE",
    names: ["CGP 40336A"],
  },
  {
    primary_id: "D0X3XV",
    names: [
      "AdCMV-PR8.ha",
      "AdhVN1203/04.H5",
      "Avian Influenza Vaccine (Adenovirus Vector, Nasal), Vaxin",
      "H5N1 Influenza Vaccine (Adenovirusvector, Nasal), Vaxin",
      "Avian Influenza Vaccine (Adenovirus Vector, Nasal), Vaxin/ Kolmar Korea",
      "H5N1 Influenza Vaccine (Adenovirus Vector, Nasal), Vaxin/ Kolmar Korea",
    ],
  },
  {
    primary_id: "D0X3YY",
    names: ["OMP-52M51"],
  },
  {
    primary_id: "D0X4AL",
    names: [
      "1,4-Dideoxy-5-Dehydro-O2-Sulfo-Glucuronic Acid",
      "AC1NRDG1",
      "(3S,4S)-4-Hydroxy-3-Sulfooxy-3,4-Dihydro-2H-Pyran-6-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0X4AN",
    names: ["CGEN-791"],
  },
  {
    primary_id: "D0X4AV",
    names: ["GV-150013"],
  },
  {
    primary_id: "D0X4BJ",
    names: [
      "2-(4-Amino-2-Chlorophenoxy)-5-Chlorophenol",
      "2-(4-AMINO-2-CHLOROPHENOXY)-5-CHLOROPHENOL",
      "CHEMBL383166",
      "TN5",
      "AC1OA9T5",
    ],
  },
  {
    primary_id: "D0X4BT",
    names: ["NSC-172033"],
  },
  {
    primary_id: "D0X4BZ",
    names: ["Virulizin"],
  },
  {
    primary_id: "D0X4CE",
    names: ["NMK-36"],
  },
  {
    primary_id: "D0X4CI",
    names: [
      "2,3,4,5-Tetrabromo-6-(2,4-Dibromo-Phenoxy)-Phenol",
      "CHEMBL362761",
      "Phenol, 2,3,4,5-Tetrabromo-6-(2,4-Dibromophenoxy)-",
      "111863-67-3",
      "ACMC-20meye",
      "2,3,4,5-Tetrabromo-6-(2,4-Dibromophenoxy)Phenol",
      "CTK0G1675",
      "DTXSID50433647",
      "BDBM50150785",
      "2,2',3,4,4',5-Hexabromo-6-Hydroxydiphenyl Ether",
      "2,3,4,5-Tetrabromo-6-(2',4'-Dibromophenoxy)Phenol",
      "2-(2'',4''-Dibromophenoxy-3,4,5,6-Tetrabromophenol",
    ],
  },
  {
    primary_id: "D0X4CO",
    names: ["CHIR-98023", "CT-98014"],
  },
  {
    primary_id: "D0X4CS",
    names: ["Cefroxadine"],
  },
  {
    primary_id: "D0X4CU",
    names: ["Rituxan"],
  },
  {
    primary_id: "D0X4DB",
    names: ["Drisapersen", "2402968"],
  },
  {
    primary_id: "D0X4DE",
    names: ["GBR 12530"],
  },
  {
    primary_id: "D0X4DH",
    names: ["Neuroendocrine Agent"],
  },
  {
    primary_id: "D0X4DJ",
    names: ["Tumor-Associated Macrophage Immunotherapy"],
  },
  {
    primary_id: "D0X4DY",
    names: ["2-NONYL-4-HYDROXYQUINOLINE N-OXIDE"],
  },
  {
    primary_id: "D0X4EB",
    names: [
      "2-Deoxy-2fluoro-Glucose",
      "2-Deoxy-2-Fluoro-Beta-D-Glucopyranose",
      "2-DEOXY-2FLUORO-GLUCOSE",
      "UNII-41XAV5II5H",
      "41XAV5II5H",
      "CHEBI:49128",
      "2-Deoxy-2-Fluoro-Beta-D-Glucose",
      "NSC-240588",
      "AC1NRBA5",
      "Beta-D-Fludeoxyglucopyranose",
      "SCHEMBL4323343",
      "CHEMBL1235932",
      "ZINC4329335",
      "G2F",
      "2-Fluoro-2-Deoxy-Beta-D-Glucopyranose",
      "Beta-D-Glucopyranose, 2-Deoxy-2-Fluoro-",
      "WURCS=2.0/1,1,0/[a2122h-1b_1-5_2*F]/1/",
    ],
  },
  {
    primary_id: "D0X4EC",
    names: ["Benzoic Acid Linked Peptide Analog 5"],
  },
  {
    primary_id: "D0X4EE",
    names: ["AcDRGDS", "CHEMBL241297", "Acetyl-Asp-Arg-Gly-Asp-Ser-OH"],
  },
  {
    primary_id: "D0X4EV",
    names: [
      "8-Chloro-1,2,3,4-Tetrahydro-Acridin-9-Ylamine",
      "CHEMBL134057",
      "8-Chloro-1,2,3,4-Tetrahydroacridin-9-Amine",
      "122994-74-5",
      "SCHEMBL9584574",
      "MolPort-039-242-295",
      "ZINC27416388",
      "BDBM50279984",
      "AKOS033887449",
      "9-Amino-8-Chloro-1,2,3,4-Tetrahydro-Acridinium",
    ],
  },
  {
    primary_id: "D0X4FM",
    names: [
      "Docusate",
      "DOCUSATE",
      "Docusate Hydrogen",
      "Dioctyl Sulfosuccinic Acid",
      "Spolion 8",
      "1,4-Bis(2-Ethylhexyl) Sulfosuccinate",
      "10041-19-7",
      "EINECS 233-124-0",
      "CHEBI:534",
      "1,4-Bis(2-Ethylhexyl) 2-Sulphosuccinate",
      "Butanedioic Acid, Sulfo-, 1,4-Bis(2-Ethylhexyl) Ester",
      "HNSDLXPSAYFUHK-UHFFFAOYSA-N",
      "Butanedionic Acid, Sulfo-, 1,4-Bis(2-Ethylhexyl) Ester",
      "Butanedioic Acid, 2-Sulfo-, 1,4-Bis(2-Ethylhexyl) Ester",
      "1,4-Bis[(2-Ethylhexyl)Oxy]-1,4-Dioxobutane-2-Sulfonic Acid",
      "Doc-Q-Lace",
      "Dioctylsulfosuccinsaeure",
      "1-(2-Tolyl)Ethanone",
    ],
  },
  {
    primary_id: "D0X4FO",
    names: [
      "6-Nitro-2-Piperazin-1-Yl-4-Vinyl-Quinoline",
      "CHEMBL166870",
      "SCHEMBL6365141",
      "BFHGMHUBVIINKT-UHFFFAOYSA-N",
      "BDBM50110583",
      "2-Piperazino-4-Vinyl-6-Nitroquinoline",
    ],
  },
  {
    primary_id: "D0X4FW",
    names: ["AT9183"],
  },
  {
    primary_id: "D0X4FY",
    names: [
      "Cis-Tetracosenoyl Sulfatide",
      "Cis-Tetracosenoyl Sulfatide (Amyotrophic Lateral Sclerosis)",
      "Cis-Tetracosenoyl Sulfatide (Amyotrophic Lateral Sclerosis), Glycoregimmune",
    ],
  },
  {
    primary_id: "D0X4HG",
    names: ["XANTHOSTIGMINE"],
  },
  {
    primary_id: "D0X4HJ",
    names: ["Peptide Analog 20"],
  },
  {
    primary_id: "D0X4IA",
    names: ["FK778"],
  },
  {
    primary_id: "D0X4IH",
    names: ["Fluciclatide F-18"],
  },
  {
    primary_id: "D0X4IO",
    names: ["Sulforaphane"],
  },
  {
    primary_id: "D0X4IV",
    names: ["PSD-508"],
  },
  {
    primary_id: "D0X4JN",
    names: ["Pelubiprofen"],
  },
  {
    primary_id: "D0X4JO",
    names: ["EncorStat"],
  },
  {
    primary_id: "D0X4KN",
    names: ["BI-836845"],
  },
  {
    primary_id: "D0X4KR",
    names: ["8-O-(4-Toluenesulfonyl)Manzamine A", "CHEMBL403561"],
  },
  {
    primary_id: "D0X4LA",
    names: ["N-Acylpyrrolidine Ether Derivative 1"],
  },
  {
    primary_id: "D0X4LI",
    names: ["4,5-Bis(4-Methoxyphenyl)-3H-1,2-Dithiol-3-One", "CHEMBL490292"],
  },
  {
    primary_id: "D0X4LQ",
    names: [
      "3-MeSO2-DDE",
      "1-Chloro-4-(2,2-Dichloro-1-(4-Chlorophenyl)Ethenyl)-3-(Methylsulfonyl)Benzene",
      "Meso2-Dde",
      "62938-14-1",
      "3-Methylsulfonyl-Dde",
      "3-Meso(2)-Dde",
      "AC1Q3QDJ",
      "AC1L3O8E",
      "CTK2F6395",
      "DTXSID20212143",
      "5-Chloro-2-[2,2-Dichloro-1-(4-Chlorophenyl)Vinyl]phenyl Methyl Sulfone",
      "2-(2-Methylsulphonyl-4-Chlorophenyl)-2-(4-Chlorophenyl)-1,1-Dichloroethene",
      "Benzene, 4-Chloro-1-(2,2-Dichloro-1-(4-Chlorophenyl)Ethenyl)-2-(Methylsulfonyl)-",
    ],
  },
  {
    primary_id: "D0X4MC",
    names: [
      "AMG 076",
      "AMG-076 Free Base",
      "UNII-HX4T7JEV3D",
      "HX4T7JEV3D",
      "CHEMBL2032049",
      "693823-79-9",
      "SCHEMBL2742722",
      "DTXSID70219485",
      "BDBM50383522",
      "Cyclohexanecarboxylic Acid, 1-(2-((4aR,11R,11aS)-1,3,4,4a,5,6,11,11a-Octahydro-11-Methyl-9-(Trifluoromethyl)-2H-Pyrido(4,3-B)Carbazol-2-Yl)Ethyl)-",
      "(4aR)-2-[2-(1-Carboxycyclohexyl)Ethyl]-2,3,4,4abeta,5,6,11,11aalpha-Octahydro-9-(Trifluoromethyl)-11alpha-Methyl-1H-Pyrido[4,3-B]carbazole",
    ],
  },
  {
    primary_id: "D0X4MN",
    names: ["CC-930"],
  },
  {
    primary_id: "D0X4MO",
    names: ["SPVF-2801-30"],
  },
  {
    primary_id: "D0X4NF",
    names: ["Radiosensitizer Gene Therapy"],
  },
  {
    primary_id: "D0X4NG",
    names: ["13,14-Dihydro-15-Keto-PGF2alpha", "Dhk-Pgf2alpha"],
  },
  {
    primary_id: "D0X4NX",
    names: ["Polyporic Acid"],
  },
  {
    primary_id: "D0X4OJ",
    names: ["AdipoCell"],
  },
  {
    primary_id: "D0X4OM",
    names: ["SPD-701"],
  },
  {
    primary_id: "D0X4OP",
    names: ["1-Allyl-2-[3-(Isopropylamino)Propoxy]-9H-Carbazole", "GTPL3136", "SCHEMBL8249304"],
  },
  {
    primary_id: "D0X4OU",
    names: [
      "4557W",
      "EGFR/ErbB-2 Inhibitor",
      "179248-61-4",
      "4-(4-Benzyloxyanilino)-6,7-Dimethoxyquinazoline",
      "SCHEMBL242224",
      "GTPL5964",
      "CHEMBL563845",
      "CTK8E8822",
      "DTXSID40431718",
      "MolPort-018-661-221",
      "HMS3229C21",
      "ZINC1485577",
      "IN1350",
      "HSCI1_000238",
      "AKOS002350936",
      "CCG-206748",
      "RT-012453",
      "J-011438",
      "BRD-K76819286-001-01-0",
      "N-[4-(Benzyloxy)Phenyl]-6,7-Dimethoxyquinazoline-4-Amine",
      "N-[4-(Benzyloxy)Phenyl]-6,7-Dimethoxyquinazolin-4-Amine",
    ],
  },
  {
    primary_id: "D0X4OW",
    names: [
      "2-Oxa-Spiro[4.4]nonan-1-One",
      "2-Oxaspiro[4.4]nonan-1-One",
      "5732-98-9",
      "2-Oxaspiro[4,4]nonan-1-One",
      "CHEMBL330370",
      "SCHEMBL11055887",
      "CTK1H4141",
      "DTXSID90574422",
      "MolPort-028-950-793",
      "ZINC13734068",
    ],
  },
  {
    primary_id: "D0X4PH",
    names: ["LP-44", "Compound 5 [PMID 17649988]"],
  },
  {
    primary_id: "D0X4PP",
    names: ["EPX-300"],
  },
  {
    primary_id: "D0X4PX",
    names: [
      "Antifungals",
      "Antifungals, Glaxo Wellcome",
      "GW-471552",
      "GW-471558",
      "GW-506540",
      "GW-531920",
      "GW-560849",
      "GW-587270",
    ],
  },
  {
    primary_id: "D0X4QC",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Ph4-D-Pro-)", "CHEMBL391383"],
  },
  {
    primary_id: "D0X4RC",
    names: [
      "PHENYLPIPERAZINE",
      "1-PHENYLPIPERAZINE",
      "92-54-6",
      "N-Phenylpiperazine",
      "Phenylpiperazine",
      "Piperazine, 1-Phenyl-",
      "1-Phenyl-Piperazine",
      "1-Fenylpiperazin [Czech]",
      "UNII-J9225CBI7D",
      "N-Phenyl Piperazine",
      "1-Phenyl Piperazine",
      "CCRIS 4334",
      "N-Phenyldiethylenediamine",
      "EINECS 202-165-6",
      "BRN 0132157",
      "CHEMBL9434",
      "AI3-26332",
      "J9225CBI7D",
      "YZTJYBJCZXZGCT-UHFFFAOYSA-N",
      "MFCD00005957",
      "1-Phenylpiperazine, 97%",
      "1-Fenylpiperazin",
      "N-Phenylpiperazin",
      "N-PHENYLPIPERAZINE HYDROCHLORIDE",
      "1-Phenylpiperazinehydrochloride",
      "Phenyl Piperazine",
      "Phenyl-Piperazine",
      "N-Phenylpyperazine",
    ],
  },
  {
    primary_id: "D0X4RN",
    names: [
      "Lidocaine",
      "Alphacaine",
      "Anestacon",
      "Cappicaine",
      "Cuivasil",
      "Dalcaine",
      "Dentipatch",
      "DermaFlex",
      "Dilocaine",
      "Duncaine",
      "EMBOLEX",
      "Esracaine",
      "Gravocain",
      "Isicaina",
      "Isicaine",
      "Jetocaine",
      "LIDOPEN",
      "LQZ",
      "Lanabiotic",
      "Leostesin",
      "Lidocaina",
      "Lidocainum",
      "Lidocaton",
      "Lidoderm",
      "Lignocaine",
      "Lignocainum",
      "Lingocaine",
      "Maricaine",
      "Octocaine",
      "Remicaine",
      "Rucaina",
      "Solcain",
      "Xilina",
      "Xilocaina",
      "Xllina",
      "Xycaine",
      "Xylestesin",
      "Xylesthesin",
      "Xylocain",
      "Xylocaine",
      "Xylocard",
      "Xylocitin",
      "Xylotox",
      "Zingo",
      "After Burn Double Strength Gel",
      "After Burn Double Strength Spray",
      "After Burn Gel",
      "After Burn Spray",
      "Anestacon Jelly",
      "Cito Optadren",
      "Emla Cream",
      "Lidocaine Carbonate",
      "Lidocaine Hydrocarbonate",
      "Lidocaine Monohydrochloride",
      "Norwood Sunburn Spray",
      "Rocephin Kit",
      "Solarcaine Aloe Extraburn Relief Cream",
      "Xilocaina [Italian]",
      "Xylocaine Dental Ointment",
      "Xylocaine Endotracheal",
      "Xylocaine Test Dose",
      "Xylocaine Viscous",
      "CDS1_000283",
      "L1026_SIGMA",
      "Xylocaine CO2",
      "Dentipatch (TN)",
      "ELA-Max",
      "L-Caine",
      "Lida-Mantle",
      "Lidocaina [INN-Spanish]",
      "Lidocaine (VAN)",
      "Lidocainum [INN-Latin]",
      "Lidoject-1",
      "Lidoject-2",
      "Octocaine-100",
      "Octocaine-50",
      "Xylocaine (TN)",
      "Xylocaine 5% Spinal",
      "Xylocaine-Mpf",
      "Xylocaine-Mpf With Glucose",
      "Xyloneural (Free Base)",
      "Zilactin-L",
      "Lidocaine [USAN:INN:JAN]",
      "Diethylaminoaceto-2,6-Xylidide",
      "Lidocaine (JP15/USP/INN)",
      "Alfa-Dietilamino-2,6-Dimetilacetanilide",
      "Alfa-Dietilamino-2,6-Dimetilacetanilide [Italian]",
      "Alpha-Diethylamino-2,6-Dimethylacetanilide",
      "Alpha-Diethylaminoaceto-2,6-Xylidide",
      "LIDOCAINE (73-58-6 (MONOHYDROCHLORIDE)",
      "Omega-Diethylamino-2,6-Dimethylacetanilide",
      "Alpha-(Diethylamino)-2,6-Acetoxylidide",
      "N-(2,6-Dimethylphenyl)-N(2),N(2)-Diethylglycinamide",
      "N-(2,6-Dimethylphenyl)-N~2~,N~2~-Diethylglycinamide",
      "2-(Diethylamino)-2',6'-Acetoxylidide",
      "2-(Diethylamino)-N-(2,6-Dimethylphenyl)Acetamide",
      "2-Diethylamino-N-(2,6-Dimethyl-Phenyl)-Acetamide",
      "2-Diethylamino-N-(2,6-Dimethylphenyl)Acetamide",
    ],
  },
  {
    primary_id: "D0X4RS",
    names: ["Cortisone Acetate", "Cortone"],
  },
  {
    primary_id: "D0X4RT",
    names: ["DS-7423"],
  },
  {
    primary_id: "D0X4RU",
    names: ["KRP-100"],
  },
  {
    primary_id: "D0X4RV",
    names: ["NPTS", "(N-[3-Phenyl-3-(4'-(4-Toluoyl) Phenoxy)Propyl]sarcosine"],
  },
  {
    primary_id: "D0X4SF",
    names: [
      "2-Amino-6-(2-Bromo-Benzenesulfonyl)-Benzonitrile",
      "AC1LA8EL",
      "BDBM1791",
      "CHEMBL293780",
      "CTK7C6578",
      "ZINC5933963",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3k",
      "2-(2-Bromophenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(2-Bromophenyl)Sulfonylbenzonitrile",
      "2-Amino-6-(2-Bromophenyl)Sulfonyl-Benzonitrile",
      "2-Amino-6-[(2-Bromophenyl)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0X4SI",
    names: [
      "RS-504393",
      "300816-15-3",
      "RS 504393",
      "6-Methyl-1'-(2-(5-Methyl-2-Phenyloxazol-4-Yl)Ethyl)Spiro[benzo[d][1,3]oxazine-4,4'-Piperidin]-2(1H)-One",
      "RS504393",
      "CHEMBL134074",
      "6-Methyl-1'-(2-(5-Methyl-2-Phenyloxazol-4-Yl)Ethyl)Spiro-[benzo[d][1,3]oxazine-4,4'-Piperidin]-2(1H)-One",
      "6-Methyl-1'-[2-(5-Methyl-2-Phenyl-4-Oxazolyl)Ethyl]spiro[4H-3,1-Benzoxazine-4,4'-Piperidin]-2(1H)-One",
      "ACMC-20a25e",
      "GTPL781",
      "SCHEMBL9972645",
      "CTK4G4374",
      "CHEBI:93525",
      "DTXSID20433290",
      "MolPort-021-804-998",
      "BCPP000086",
      "HMS3269M19",
      "BCP02713",
      "ZINC13527116",
      "ABP000463",
    ],
  },
  {
    primary_id: "D0X4SX",
    names: ["1-Benzhydryl-4-Phenyl-4-Propoxypiperidine", "CHEMBL229017"],
  },
  {
    primary_id: "D0X4TK",
    names: ["Nadofaragene Firadenovec"],
  },
  {
    primary_id: "D0X4TV",
    names: ["PMID26651364-Compound-118"],
  },
  {
    primary_id: "D0X4UA",
    names: ["DISC GM-CSF"],
  },
  {
    primary_id: "D0X4UE",
    names: [
      "N4-(3-Methylphenyl)-4,6-Quinazolinediamine",
      "CHEMBL336113",
      "Anilinoquinazoline, 4a",
      "6-Amino-4-[(3-Methylphenyl)Amino]quinazoline",
      "SMA52",
      "SCHEMBL159209",
      "ZINC6328",
      "SMA-52",
      "NFBCSWGEYDCCDW-UHFFFAOYSA-N",
      "BDBM50133384",
      "AKOS013914431",
      "N*4*-M-Tolyl-Quinazoline-4,6-Diamine",
      "153436-70-5",
      "6-Amino-4-(3'-Methylanilino)Quinazoline",
      "6-Amino-4-(3-Methylphenylamino)-Quinazoline",
      "FT-0765557",
      "6-Amino-4[(3-Methylphenyl)Amino]quinazoline",
    ],
  },
  {
    primary_id: "D0X4UH",
    names: ["S-8184"],
  },
  {
    primary_id: "D0X4UN",
    names: [
      "3-Methyl-N-(6-Methylpyridin-2-Yl)Benzamide",
      "CHEMBL212724",
      "AC1LJ0IB",
      "Oprea1_323038",
      "Oprea1_123319",
      "SCHEMBL8557860",
      "MolPort-003-351-883",
      "ZINC579315",
      "BDBM50186319",
      "AKOS001316653",
      "MCULE-8428043810",
      "346720-66-9",
      "ST51016902",
      "N-(6-Methyl(2-Pyridyl))(3-Methylphenyl)Carboxamide",
    ],
  },
  {
    primary_id: "D0X4UP",
    names: [
      "Liposomal Amikacin",
      "AriKayce",
      "AC1L9V75",
      "SMP1_000015",
      "Amikacin Containing Liposome Consisting Of Soy Phosphatidylcholine(SPC) And Phosphatidylglycerol(PG)",
      "4-Amino-N-[(1R,2S,3S,5S)-5-Amino-2-[(2S,3R,4S,5S,6R)-4-Amino-3,5-Dihydroxy-6-(Hydroxymethyl)Tetrahydropyran-2-Yl]oxy-4-[(2R,3R,4S,5S,6R)-6-(Aminomethyl)-3,4,5-Trihydroxy-Tetrahydropyran-2-Yl]oxy-3-Hydroxy-Cyclohexyl]-2-Hydroxy-Butanamide",
      "4-Amino-N-[(1R,2S,3S,5S)-5-Amino-2-[(2S,3R,4S,5S,6R)-4-Amino-3,5-Dihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxy-4-[(2R,3R,4S,5S,6R)-6-(Amin",
    ],
  },
  {
    primary_id: "D0X4VA",
    names: ["Imlygictalimogene Laherparepvec"],
  },
  {
    primary_id: "D0X4VG",
    names: [
      "Anti-Notch-1 Receptor Mabs",
      "AV-323",
      "Anti-Notch-1 Receptor MAbs (Cancer)",
      "Anti-Notch-1 Receptor MAbs (Cancer), AVEO Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0X4VN",
    names: [
      "6-(3-Fluoro-4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL193727",
      "SCHEMBL3924993",
      "QTDYCKGQBCKMGC-UHFFFAOYSA-N",
      "ZINC13645068",
      "BDBM50168372",
    ],
  },
  {
    primary_id: "D0X4VO",
    names: ["N-[5-(Formyl-Hydroxy-Amino)-Pentyl]-Benzamide", "CHEMBL337584", "TWZ-109"],
  },
  {
    primary_id: "D0X4WV",
    names: ["IDV-007"],
  },
  {
    primary_id: "D0X4XX",
    names: [
      "1-(3,4-Difluorobenzyl)-1H-Imidazole",
      "1-(3,4-Difluorobenzyl)Imidazole",
      "CHEMBL373588",
      "906478-71-5",
      "ZINC36322898",
      "BDBM50188090",
    ],
  },
  {
    primary_id: "D0X4YC",
    names: ["H-Dmt-Tic-NH-(CH2)6-NH-Tic-H", "CHEMBL380590"],
  },
  {
    primary_id: "D0X4YJ",
    names: ["ImCOOH"],
  },
  {
    primary_id: "D0X4YY",
    names: ["1,1-Bisphosphonate Esters"],
  },
  {
    primary_id: "D0X4ZL",
    names: ["AN-1792", "Betabloc", "AIP-001"],
  },
  {
    primary_id: "D0X4ZR",
    names: [
      "Bupropion",
      "Amfebutamone",
      "34911-55-2",
      "Amfebutamon",
      "2-(Tert-Butylamino)-1-(3-Chlorophenyl)Propan-1-One",
      "Amfebutamonum",
      "Amfebutamona",
      "(+-)-Bupropion",
      "Wellbatrin",
      "Bupropion [INN:BAN]",
      "34841-39-9",
      "Elontril",
      "Amfebutamonum [INN-Latin]",
      "Zyban",
      "Amfebutamona [INN-Spanish]",
      "Bupropion SR",
      "AMFEBUTAMONE HCl",
      "Alpha-(Tert-Butylamino)-M-Chloropropiophenone",
      "CHEMBL894",
      "BRN 2101062",
      "(+-)-1-(3-Chlorophenyl)-2-((1,1-Dimethylethyl)Amino)-1-Propanone",
      "CHEBI:3219",
      "SNPPWIUOZRMYNY-UHFFFAOYSA-N",
      "1-(3-Chlorophenyl)-2-[(1,1-Dimethylethyl)Amino]propan-1-One",
      "Elont",
      "Bupropion Hcl",
      "Bupropion Hydrocloride",
      "Bupropion (INN)",
      "Bupropion (Old RN)",
      "Bupropion (USAN)",
      "(-)-2-(Tert-Butylamino)-3'-Chloropropiophenone",
      "(-)-2-(Tert-Butylamino)-3'-Chlorpropiophenon",
      "1-Propanone",
      "2-(Tert-Butylamino)-3'-Chloropropiophenone",
    ],
  },
  {
    primary_id: "D0X4ZV",
    names: ["BAX-499", "ARC-19499", "Tissue Factor Pathway Inhibitor (Hemophilia A/Hemophilia B), Archemix"],
  },
  {
    primary_id: "D0X5AA",
    names: [
      "AMIFLAMINE",
      "Amiflamine",
      "UNII-NE25WV9C8S",
      "77518-07-1",
      "NE25WV9C8S",
      "4-[(2S)-2-Aminopropyl]-N,N,3-Trimethylaniline",
      "Amiflaminum",
      "Amiflamina",
      "Amiflamine [INN]",
      "Amiflaminum [Latin]",
      "Amiflamina [Spanish]",
      "Astra FLA 336",
      "(+)-FLA 336",
      "AC1Q4TOK",
      "AC1L2FRS",
      "AC1Q29JB",
      "ZINC915",
      "SCHEMBL198456",
      "CHEMBL3989773",
      "DTXSID00228222",
      "AKOS006272872",
      "(+)-4-(Dimethylamino)-",
      "A,2-Dimethylphenethylamine",
      "(+)-4-(Dimethylamino)-Alpha-2-Dimethylphenethylamine",
      "Benzeneethanamine, 4-(Dimethylamino)-Alpha,2-Dimethyl-, (S)",
      "Benzeneethanamine, 4-(Dimethylamino)-Alpha,2-Dimethyl-,",
    ],
  },
  {
    primary_id: "D0X5AN",
    names: ["U300"],
  },
  {
    primary_id: "D0X5AY",
    names: ["1-(4-Methoxyphenyl)-3-(5-Methylthiazol-2-Yl)Urea", "AC1LGH35", "CHEMBL226324"],
  },
  {
    primary_id: "D0X5BC",
    names: ["DT-320"],
  },
  {
    primary_id: "D0X5BD",
    names: ["AVE-5997", "AVE-1734", "Dopamine D3 Antagonists, Aventis", "HMR-2554"],
  },
  {
    primary_id: "D0X5CM",
    names: [
      "Triazolopyridine",
      "CP-808844",
      "6-[4-(4-FLUOROPHENYL)-1,3-OXAZOL-5-YL]-3-ISOPROPYL[1,2,4]TRIAZOLO[4,3-A]PYRIDINE",
      "1zzl",
      "AC1NRDFA",
      "Triazolopyridine Deriv. 25",
      "SCHEMBL40860",
      "CHEMBL194322",
      "BDBM15414",
      "DB04797",
      "6-[4-(4-Fluorophenyl)-1,3-Oxazol-5-Yl]-3-(1-Methylethyl)[1,2,4]triazolo[4,3-A]pyridine",
      "6-[4-(4-Fluoro-Phenyl)-Oxazol-5-Yl]-3-Isopropyl-[1,2,4]-Triazolo[4,3-A]pyridine",
      "4-(4-Fluorophenyl)-5-[3-(Propan-2-Yl)-[1,2,4]triazolo[3,4-A]pyridin-6-Yl]-1,3-Oxazole",
    ],
  },
  {
    primary_id: "D0X5CU",
    names: [
      "AM-491",
      "AM-495",
      "AM-892",
      "AV-491",
      "AVX-491",
      "HBV Non-Nucleoside Inhibitors, AMRAD",
      "HBV Non-Nucleoside Inhibitors, Avexa",
    ],
  },
  {
    primary_id: "D0X5CX",
    names: ["Fusidic Aci"],
  },
  {
    primary_id: "D0X5DB",
    names: ["4-(2-(4-Tert-Butylphenylamino)Ethyl)-1H-Imidazole", "CHEMBL1096516"],
  },
  {
    primary_id: "D0X5DC",
    names: ["Linalool"],
  },
  {
    primary_id: "D0X5DT",
    names: [
      "2-(2-Phenethylphenyl)Isoindoline-1,3-Dione",
      "CHEMBL246144",
      "PP2P",
      "SCHEMBL2464969",
      "BDBM23840",
      "N-(2-Phenethylphenyl)Phthalimide",
    ],
  },
  {
    primary_id: "D0X5ES",
    names: ["NCX 950"],
  },
  {
    primary_id: "D0X5FS",
    names: ["Piperidine Derivative 4"],
  },
  {
    primary_id: "D0X5FV",
    names: ["V-950", "Anti-Amyloid Beta Vaccine (Alzheimer's Disease), Merck"],
  },
  {
    primary_id: "D0X5HL",
    names: ["N1-(Naphthalen-1-Yl)Ethane-1,2-Diamine"],
  },
  {
    primary_id: "D0X5IV",
    names: ["1,2,4-Oxadiazole Derivative 6"],
  },
  {
    primary_id: "D0X5JF",
    names: ["AR-H050642"],
  },
  {
    primary_id: "D0X5JR",
    names: [
      "4-[3-(3-Benzyloxy-Pyridin-2-Yl)-Ureido]-Benzamide",
      "CHEMBL201718",
      "BDBM50181477",
      "4-[(3-Benzyloxy-2-Pyridyl)Carbamoylamino]benzamide",
      "Benzamide, 4-[[[[3-(Phenylmethoxy)-2-Pyridinyl]amino]carbonyl]amino]-",
    ],
  },
  {
    primary_id: "D0X5KA",
    names: ["PMID27967267-Compound-51"],
  },
  {
    primary_id: "D0X5KF",
    names: ["Hydrocodone"],
  },
  {
    primary_id: "D0X5KQ",
    names: ["H-SMGLPCVVM-OH", "CHEMBL87258"],
  },
  {
    primary_id: "D0X5LG",
    names: ["ABJ-879"],
  },
  {
    primary_id: "D0X5LJ",
    names: [
      "3-(N-Propylpiperidin-4-Yl)Phenol",
      "CHEMBL592618",
      "SCHEMBL3454031",
      "HPUYFCNUROWQBG-UHFFFAOYSA-N",
      "BDBM50308024",
    ],
  },
  {
    primary_id: "D0X5LL",
    names: ["WBI-2000"],
  },
  {
    primary_id: "D0X5LN",
    names: [
      "7,8,3',4'-Tetrahydroxyflavone",
      "3440-24-2",
      "2-(3,4-Dihydroxyphenyl)-7,8-Dihydroxy-4H-Chromen-4-One",
      "3',4',7,8-Tetrahydroxyflavone",
      "CHEMBL222541",
      "ARYCMKPCDNHQCL-UHFFFAOYSA-N",
      "ST059620",
      "2-(3,4-Dihydroxyphenyl)-7,8-Dihydroxychromen-4-One",
      "AC1LEMTC",
      "AC1Q6AJ1",
      "SCHEMBL35318",
      "CTK4H2335",
      "ZINC57844",
      "DTXSID20350973",
      "2-(3,4-Dihydroxyphenyl)-7,8-Dihydroxy-4h-Chromen-4-On",
      "CHEBI:125596",
      "7,8,3'',4''-Tetrahydroxyflavone",
      "BDBM50157547",
      "AKOS024283046",
      "ACM3440242",
      "MCULE-5496936655",
      "ZB002260",
      "DB-048618",
      "FT-0614178",
      "C-57754",
    ],
  },
  {
    primary_id: "D0X5LR",
    names: ["BMN-673"],
  },
  {
    primary_id: "D0X5MA",
    names: ["I-BOP"],
  },
  {
    primary_id: "D0X5MW",
    names: ["PMID28766366-Compound-Scheme1WO2011135259"],
  },
  {
    primary_id: "D0X5NX",
    names: [
      "Clenbuterol",
      "Clenbuterolum",
      "Contraspasmin",
      "Planipart",
      "Planipart [veterinary]",
      "Clenbuterol (INN)",
      "Clenbuterol [BAN:INN]",
      "Clenbuterolum [INN-Latin]",
      "Contraspasmin (TN)",
      "NAB-365",
      "Planipart [veterinary] (TN)",
      "(+)-4-Amino-Alpha-((Tert-Butylamino)Methyl)-3,5-Dichlorobenzyl Alcohol",
      "(+-)-Clenbuterol",
      "(-)-4-Amino-Alpha-((Tert-Butylamino)Methyl)-3,5-Dichlorobenzyl Alcohol",
      "1-(4-Amino-3,5-Dichloro-Phenyl)-2-Tert-Butylamino-Ethanol",
      "1-(4-Amino-3,5-Dichlorophenyl)-2-(Tert-Butylamino)Ethanol",
      "1-(4-Amino-3,5-Dichlorophenyl)-2-[(1,1-Dimethylethyl)Amino]ethanol",
      "4-Amino-3,5-Dichloro-Alpha-(((1,1-Dimethylethyl)Amino)Methyl)Benzenemethanol",
      "4-Amino-Alpha-((Tert-Butylamino)Methyl)-3,5-Dichlorobenzyl Alcohol",
    ],
  },
  {
    primary_id: "D0X5OC",
    names: ["PMID27172114-Compound-49"],
  },
  {
    primary_id: "D0X5OV",
    names: ["TTA-A8"],
  },
  {
    primary_id: "D0X5OY",
    names: ["S-Adenosyl-L-Methioninamine"],
  },
  {
    primary_id: "D0X5PJ",
    names: ["AS16", "AS 16", "AS-16"],
  },
  {
    primary_id: "D0X5PX",
    names: ["MDL-105519"],
  },
  {
    primary_id: "D0X5PZ",
    names: ["Calquenceacalabrutinib"],
  },
  {
    primary_id: "D0X5QJ",
    names: [
      "3-Bromo-7-Nitro-1H-Indazole",
      "3-Bromo-7-Nitroindazole",
      "74209-34-0",
      "1H-Indazole,3-Bromo-7-Nitro-",
      "3-Bromo-7-Nitro-2H-Indazole",
      "CHEMBL479014",
      "NFSTZPMYAZRZPC-UHFFFAOYSA-N",
      "MFCD00159910",
      "AK-27879",
      "SR-01000075708",
      "3-Bromo-7NI",
      "Tocris-0735",
      "3-Br-7-Nitroindazole",
      "AC1L1BXC",
      "Lopac-B-2050",
      "3-Br-7NI",
      "Lopac0_000161",
      "BSPBio_002434",
      "BSPBio_001502",
      "KBioGR_000222",
      "KBioSS_000222",
      "SPECTRUM1505105",
      "SCHEMBL499422",
      "SCHEMBL499421",
      "GTPL5113",
      "KBio3_000444",
      "KBio3_000443",
      "KBio2_002790",
      "CTK5D9545",
      "BCBcMAP01_000129",
      "KBio2_005358",
      "KBio2_000222",
      "DTXSID90274319",
      "OR6862T",
    ],
  },
  {
    primary_id: "D0X5RA",
    names: [
      "6-Amino-5-Bromouracil",
      "6312-73-8",
      "5-Bromo-6-Aminouracil",
      "6-Amino-5-Bromopyrimidine-2,4(1H,3H)-Dione",
      "6-Amino-5-Bromo-1H-Pyrimidine-2,4-Dione",
      "6-Amino-5-Bromo-1,2,3,4-Tetrahydropyrimidine-2,4-Dione",
      "CHEMBL64909",
      "6-Amino-5-Bromopyrimidine-2,4-Diol",
      "6-Amino-5-Bromo-2,4(1H,3H)-Pyrimidinedione",
      "6-Amino-5-Bromo-1,3-Dihydropyrimidine-2,4-Dione",
      "NSC40394",
      "EINECS 228-638-7",
      "NSC 27652",
      "NSC 40394",
      "AC1Q24DM",
      "AC1Q50HI",
      "AC1L2ZP9",
      "SCHEMBL2894070",
      "CTK5B7686",
      "KS-00001QBO",
      "DTXSID60212460",
      "MolPort-000-873-593",
      "FSLBEEVCUZFKRL-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0X5RK",
    names: ["ALT-801 (Bolus Injection), Altor"],
  },
  {
    primary_id: "D0X5RR",
    names: ["(11H-Dibenzo[b,e][1,4]dioxepin-7-Yl)-Acetic Acid", "CHEMBL32961", "ZINC29223888"],
  },
  {
    primary_id: "D0X5SD",
    names: [
      "Disulfiram",
      "Abstenisil",
      "Abstensil",
      "Abstinil",
      "Abstinyl",
      "Alcophobin",
      "Antabus",
      "Antabuse",
      "Antadix",
      "Antaenyl",
      "Antaethan",
      "Antaethyl",
      "Antaetil",
      "Antalcol",
      "Antetan",
      "Antethyl",
      "Antetil",
      "Anteyl",
      "Anthethyl",
      "Antiaethan",
      "Anticol",
      "Antietanol",
      "Antiethanol",
      "Antietil",
      "Antikol",
      "Antivitium",
      "Aversan",
      "Averzan",
      "Bonibal",
      "Contralin",
      "Contrapot",
      "Cronetal",
      "Dicupral",
      "Disetil",
      "Disulfan",
      "Disulfirame",
      "Disulfiramo",
      "Disulfiramum",
      "Disulfirm",
      "Disulfram",
      "Disulfuram",
      "Disulphuram",
      "Ephorran",
      "Espenal",
      "Esperal",
      "Etabus",
      "Ethyldithiourame",
      "Ethyldithiurame",
      "Exhoran",
      "Exhorran",
      "Gababentin",
      "Hoca",
      "Krotenal",
      "Nocbin",
      "Nocceler",
      "Noxal",
      "Refusal",
      "Stopaethyl",
      "Stopethyl",
      "Stopety",
      "Stopetyl",
      "TATD",
      "TETD",
      "THIOCID",
      "TTD",
      "TTS",
      "Tenurid",
      "Tenutex",
      "Tetidis",
      "Tetradin",
      "Tetradine",
      "Tetraethylthiuram",
      "Tetraetil",
      "Teturam",
      "Teturamin",
      "Thireranide",
      "Thiuranide",
      "Tillram",
      "Tiuram",
      "Accel TET",
      "Akrochem TETD",
      "Ancazide ET",
      "Antab Use",
      "Disulfirame [DCIT]",
      "Ekagom DTET",
      "Ekagom TEDS",
      "Ekagom TETDS",
      "Ekaland TETD",
      "Esperal [France]",
      "Eta Bus",
      "Ethyl Thiram",
      "Ethyl Thiudad",
      "Ethyl Thiurad",
      "Ethyl Tuads Rodform",
      "Ethyl Tuex",
      "Ethyl Tuads",
      "Etyl Tuex",
      "Nocceler TET",
      "Perkacit TETD",
      "Perkait TETD",
      "Robac TET",
      "Sanceler TET",
      "Soxinol TET",
      "TTS X",
      "Tet Raethylthiuram",
      "Thiuram E",
      "Dupon 4472",
      "T 1132",
      "Accel TET-R",
      "Alk-Aubs",
      "Antabus (TN)",
      "Antabuse (TN)",
      "Anti-Ethyl",
      "Antivitium (Spain)",
      "ENT 27,340",
      "Nocceler TET-G",
      "Noxal (VAN)",
      "Ro-Sulfiram",
      "Sanceler TET-G",
      "Tuads, Ethyl",
      "Usaf B-33",
      "Ro-Sulfram-500 (USA)",
    ],
  },
  {
    primary_id: "D0X5SI",
    names: ["Carbocisteine", "Carbocysteine", "Siroxyl", "Carbocisteine, Sopar"],
  },
  {
    primary_id: "D0X5SJ",
    names: ["Alacepril", "Cetapril (TN)"],
  },
  {
    primary_id: "D0X5SQ",
    names: ["PNU-87663", "157012-18-5", "U-87663", "U-87663E"],
  },
  {
    primary_id: "D0X5SZ",
    names: ["PMID17189691C42"],
  },
  {
    primary_id: "D0X5TB",
    names: ["H-Cdp-Ser-Gly-Phe-Leu-Thr-OH", "CHEMBL247760"],
  },
  {
    primary_id: "D0X5UH",
    names: [
      "ADOZELESIN",
      "Adosar",
      "Adozelesin < Rec INN",
      "U-73975",
      "(7bR,8aS)-N-[2-[(1,2,4,5,8,8a-Hexahydro-7-Methyl-4-Oxocyclopropa[c]pyrrolo[3,2-E]indol-2-Yl)Carbonyl]-1H-Indol-5-Yl]-2-Benzofurancarboxamide",
    ],
  },
  {
    primary_id: "D0X5UL",
    names: ["Salmonella Typhi Vaccine"],
  },
  {
    primary_id: "D0X5UN",
    names: ["Mirabegron"],
  },
  {
    primary_id: "D0X5US",
    names: [
      "IOX1",
      "5852-78-8",
      "8-Hydroxyquinoline-5-Carboxylic Acid",
      "8-Hydroxy-5-Quinolinecarboxylic Acid",
      "5-Carboxy-8-Hydroxyquinoline",
      "IOX 1",
      "UNII-JM015YQC1C",
      "IOX-1",
      "5-Carboxy-8HQ",
      "5-Quinolinecarboxylic Acid, 8-Hydroxy-",
      "JM015YQC1C",
      "CHEMBL1230640",
      "4bio",
      "4jht",
      "8XQ",
      "4ie4",
      "AC1LA0UV",
      "MLS002729056",
      "GTPL8230",
      "SCHEMBL6068195",
      "KS-00000PPH",
      "CHEBI:93239",
      "CTK1E0142",
      "DTXSID20207236",
      "AOB6499",
      "JGRPKOGHYBAVMW-UHFFFAOYSA-N",
      "MolPort-006-673-354",
      "HMS3653E21",
      "ZINC5933707",
      "BCP16996",
      "S7234",
      "BDBM50396018",
      "2184AH",
      "IOX1, &gt",
      "AKOS016371793",
    ],
  },
  {
    primary_id: "D0X5UY",
    names: ["HL-146"],
  },
  {
    primary_id: "D0X5VH",
    names: ["Monoamine Derivative 4"],
  },
  {
    primary_id: "D0X5VI",
    names: ["PMID25726713-Compound-47"],
  },
  {
    primary_id: "D0X5WD",
    names: ["3-Isopropyl-4-(Phenylamino)Naphthalene-1,2-Dione", "CHEMBL204939"],
  },
  {
    primary_id: "D0X5WJ",
    names: ["Levodropropizine"],
  },
  {
    primary_id: "D0X5WP",
    names: ["LE135"],
  },
  {
    primary_id: "D0X5XU",
    names: [
      "Decitabine",
      "AzadC",
      "Dacogen",
      "Dezocitidine",
      "NCGC_5ADOC",
      "Dacogen (TN)",
      "E-7373",
      "Decitabine (USAN/INN)",
      "Dacogen, 5-Aza-2'-Deoxycytidine,NSC 127716, Dacogen, DAC, Decitabine",
      "2'-Deoxy-5-Azacytidine",
      "4-Amino-1-(2-Deoxy-Beta-D-Erythro-Pentofuranosyl)-1,3,5-Triazin-2(1H)-One",
      "4-Amino-1-(2-Deoxy-Beta-D-Erythro-Pentofuranosyl)-S-Triazin-2(1H)-One",
      "4-Amino-1-(2-Deoxy-Beta-D-Ribofuranosyl)-1,3,5-Triazin-2(1H)-One",
      "4-Amino-1-[(2R,4S,5R)-4-Hydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-1,3,5-Triazin-2-One",
      "5-AZAdC",
      "5-Aza-2&prime;-Deoxycytidine",
      "5-Aza-2'-Deoxycytidine",
      "5-Azadeoxycytidine",
      "5-Deoxy-2&prime;-Azacytidine",
      "5-Aza-2-Deoxycytidine",
      "5-Aza-CdR",
      "5-Aza-DC",
      "5A2dc",
    ],
  },
  {
    primary_id: "D0X5YM",
    names: [
      "2alpha-Hydroxyolean-12-En-28-Oic Acid",
      "CHEMBL1077726",
      "BDBM50310519",
      "2alpha-Hydroxyoleana-12-Ene-28-Oic Acid",
    ],
  },
  {
    primary_id: "D0X5YN",
    names: ["CHS-1420"],
  },
  {
    primary_id: "D0X5YU",
    names: ["KOS-2484"],
  },
  {
    primary_id: "D0X5ZA",
    names: ["N-Methylmesoporphyrin"],
  },
  {
    primary_id: "D0X5ZH",
    names: ["LC-445"],
  },
  {
    primary_id: "D0X5ZI",
    names: ["Ambrisentan"],
  },
  {
    primary_id: "D0X5ZO",
    names: ["SSR-162369"],
  },
  {
    primary_id: "D0X5ZS",
    names: ["Cyano(Dimethyl)Methyl Isoxazoles And [1,3,4]-Thiadiazoles Derivative 2"],
  },
  {
    primary_id: "D0X5ZU",
    names: ["ET-01 Topical"],
  },
  {
    primary_id: "D0X6AU",
    names: ["FAS-031"],
  },
  {
    primary_id: "D0X6BK",
    names: [
      "2,5-Imino-2,5,6-Trideoxy-D-Manno-Heptitol",
      "CHEMBL407337",
      "6-DeoxyhomoDMDP",
      "SCHEMBL2437378",
      "BDBM50234571",
      "2alpha-Hydroxymethyl-3beta,4alpha-Dihydroxypyrrolidine 5beta-Ethanol",
    ],
  },
  {
    primary_id: "D0X6BT",
    names: ["L-Leucyl-L-Prolinamide"],
  },
  {
    primary_id: "D0X6BU",
    names: ["H-Tyr-C[D-Cys-Gly-Phe-L-Cys]NH2", "CHEMBL373572"],
  },
  {
    primary_id: "D0X6BV",
    names: ["Vilazodone"],
  },
  {
    primary_id: "D0X6BX",
    names: [
      "ABC294640",
      "915385-81-8",
      "Opaganib",
      "ABC-294640",
      "CHEMBL2158685",
      "ABC 294640",
      "UNII-DRG21OQ517",
      "DRG21OQ517",
      "Yeliva",
      "SCHEMBL1548333",
      "GTPL6624",
      "CHEBI:124965",
      "MolPort-044-560-286",
      "HMS3402P05",
      "BCP08959",
      "EX-A1962",
      "BDBM50393642",
      "3-(4-Chlorophenyl)-N-(Pyridin-4-Ylmethyl)Adamantane-1-Carboxamide",
      "AKOS027327311",
      "CS-0877",
      "SB17167",
      "DB12764",
      "API0017247",
      "HY-16015",
      "S7174",
      "BRD-A70814879-003-01-8",
    ],
  },
  {
    primary_id: "D0X6DC",
    names: ["STM 434"],
  },
  {
    primary_id: "D0X6DE",
    names: ["FemiVac"],
  },
  {
    primary_id: "D0X6DK",
    names: ["TDI-0098"],
  },
  {
    primary_id: "D0X6DL",
    names: ["PMID27774822-Compound-Figure6Example2"],
  },
  {
    primary_id: "D0X6DO",
    names: ["L-826266"],
  },
  {
    primary_id: "D0X6EI",
    names: ["PMID17936624C36"],
  },
  {
    primary_id: "D0X6EM",
    names: ["T2c-001"],
  },
  {
    primary_id: "D0X6EX",
    names: [
      "OX-MPI",
      "Chronic Pain Therapeutics, Biolipox/Boehringer Ingelheim",
      "PGE2 Synthesis Inhibitors (Pain/Inflammation)",
      "BLX-MPI Series, Boehringer/Biolipox",
      "OX-MPI Series, Boehringer/Orexo",
      "PGE2 Synthesis Inhibitors (Pain/Inflammation), Boehringer",
      "Prostaglandin E Synthase Inhibitors (Chronic Pain/Inflammation), Orexo/BoehringerIngelheim",
    ],
  },
  {
    primary_id: "D0X6FB",
    names: ["KI-0903"],
  },
  {
    primary_id: "D0X6FF",
    names: ["SPPI-339"],
  },
  {
    primary_id: "D0X6FU",
    names: ["C-CAR011"],
  },
  {
    primary_id: "D0X6FX",
    names: ["PMID28074661-Compound-US20100022547C83"],
  },
  {
    primary_id: "D0X6GG",
    names: ["PMID29130358-Compound-Figure18(14a)"],
  },
  {
    primary_id: "D0X6GN",
    names: [
      "Loteprednol Etabonate",
      "Alrex",
      "Locort",
      "Loteflam",
      "Lotemax",
      "Loterox",
      "CDDD-5604",
      "HGP-1",
      "IDR-90102",
      "IDR-90103",
      "Lotemax (TN)",
      "P-5604",
      "Alrex, Lotemax, Loteprednol Etabonate",
      "Loteprednol Etabonate (JAN/USAN)",
      "Chloromethyl 17alpha-[(Ethoxycarbonyl)Oxy]-11beta-Hydroxy-3-Oxoandrosta-1,4-Diene-17beta-Carboxylate",
      "Chloromethyl (8S,9S,10R,11S,13S,14S,17R)-17-Ethoxycarbonyloxy-11-Hydroxy-10,13-Dimethyl-3-Oxo-7,8,9,11,12,14,15,16-Octahydro-6H-Cyclopenta[a]phenanthrene-17-Carboxylate",
    ],
  },
  {
    primary_id: "D0X6GP",
    names: ["PF-04577806"],
  },
  {
    primary_id: "D0X6HD",
    names: [
      "Anileridine",
      "Adopol",
      "Alidine",
      "Anileridina",
      "Anileridinum",
      "Apidol",
      "Apodol",
      "Leritin",
      "Nipecotan",
      "Anileridina [INN-Spanish]",
      "Anileridine [INN:BAN]",
      "Anileridinum [INN-Latin]",
      "Leritine (TN)",
      "Anileridine (USP/INN)",
      "N-Beta-(P-Aminophenyl)Ethylnormeperidine",
      "Ethyl 1-(4-Aminophenethyl)-4-Phenylisonipecotate",
      "Ethyl 1-(P-Aminophenethyl)-4-Phenylisonipecotate",
      "Ethyl 1-[2-(4-Aminophenyl)Ethyl]-4-Phenylpiperidine-4-Carboxylate",
      "Isonipecotic Acid, 1-(P-Aminophenethyl)-4-Phenyl-, Ethyl Ester",
      "Ethyl 1-(2-(4-Aminophenyl)Ethyl)-4-Phenyl-4-Piperidinecarboxylate",
      "N-(Beta-(P-Aminophenyl)Ethyl)-4-Phenyl-4-Carbethoxypiperidine",
      "1-(2-(4-Aminophenyl)Ethyl)-4-Phenyl-4-Piperidinecarboxylic Acid Ethyl Ester",
      "1-(P-Aminophenethyl)-4-Phenylisonipecotic Acid Ethyl Ester",
      "1-(P-Aminophenethyl)-4-Phenylpiperidine-4-Carboxylic Acid Ethyl Ester",
      "1-[2-(4-Aminophenyl)Ethyl]-4-Phenyl-4-Piperidinecarboxlic Acid Ethyl Ester",
      "4-Piperidinecarboxylic Acid, 1-(2-(4-Aminophenyl)Ethyl)-4-Phenyl-, Ethyl Ester",
      "4-Piperidinecarboxylic Acid, 1-[2-(4-Aminophenyl)Ethyl]-4-Phenyl-, Ethyl Ester",
    ],
  },
  {
    primary_id: "D0X6IE",
    names: [
      "4-Amino-N-(4-Sulfamoylbenzyl)Benzenesulfonamide",
      "CHEMBL266026",
      "4-[[(4-Aminophenyl)Sulfonylamino]methyl]benzenesulfonamide",
      "AC1NA7ER",
      "SCHEMBL3789773",
      "BDBM10871",
      "ZINC6392199",
      "Aromatic/Heteroaromatic Sulfonamide 16",
      "AKOS024362676",
      "MCULE-5136189306",
      "ST50993685",
      "N-(4-Sulfamoylbenzyl)-4-Aminobenzenesulfonamide",
      "4-Amino-N-[(4-Sulfamoylphenyl)Methyl]benzene-1-Sulfonamide",
      "4-({[(4-Aminophenyl)Sulfonyl]amino}methyl)Benzenesulfonamide",
      "Benzenesulfonamide, 4-Amino-N-[[4-(Aminosulfonyl)Phenyl]methyl]-",
    ],
  },
  {
    primary_id: "D0X6IU",
    names: [
      "Chloramphenicol",
      "Alficetyn",
      "Ambofen",
      "Amphenicol",
      "Amphicol",
      "Amseclor",
      "Anacetin",
      "Aquamycetin",
      "Austracil",
      "Austracol",
      "Biocetin",
      "Biophenicol",
      "CAF",
      "CLM",
      "Catilan",
      "Chemiceticol",
      "Chemicetin",
      "Chemicetina",
      "Chlomin",
      "Chlomycol",
      "Chloramex",
      "Chloramfenikol",
      "Chloramficin",
      "Chloramfilin",
      "Chloramphenicolum",
      "Chloramsaar",
      "Chlorasol",
      "Chloricol",
      "Chlornitromycin",
      "Chloroamphenicol",
      "Chlorocaps",
      "Chlorocid",
      "Chlorocide",
      "Chlorocol",
      "Chlorofair",
      "Chloromax",
      "Chloromycetin",
      "Chloromycetny",
      "Chloromyxin",
      "Chloronitrin",
      "Chloroptic",
      "Chlorovules",
      "Chlorsig",
      "Cidocetine",
      "Ciplamycetin",
      "Cloramfen",
      "Cloramfenicol",
      "Cloramfenicolo",
      "Cloramficin",
      "Cloramical",
      "Cloramicol",
      "Cloramidina",
      "Cloranfenicol",
      "Cloroamfenicolo",
      "Clorocyn",
      "Cloromisan",
      "Cloromissan",
      "Clorosintex",
      "Comycetin",
      "Cylphenicol",
      "Desphen",
      "Detreomycin",
      "Detreomycine",
      "Dextromycetin",
      "Doctamicina",
      "Duphenicol",
      "Econochlor",
      "Embacetin",
      "Emetren",
      "Enicol",
      "Enteromycetin",
      "Erbaplast",
      "Ertilen",
      "Farmicetina",
      "Fenicol",
      "Globenicol",
      "Glorous",
      "Halomycetin",
      "Hortfenicol",
      "Interomycetine",
      "Intramycetin",
      "Intramyctin",
      "Isicetin",
      "Ismicetina",
      "Isophenicol",
      "Juvamycetin",
      "Kamaver",
      "Kemicetina",
      "Kemicetine",
      "Kloramfenikol",
      "Klorita",
      "Laevomycetinum",
      "Leukamycin",
      "Leukomyan",
      "Leukomycin",
      "Levomicetina",
      "Levomitsetin",
      "Levomycetin",
      "Loromicetina",
      "Loromisan",
      "Loromisin",
      "Mastiphen",
      "Mediamycetine",
      "Medichol",
      "Micloretin",
      "Micochlorine",
      "Micoclorina",
      "Microcetina",
      "Mychel",
      "Mycinol",
      "Myscel",
      "Novochlorocap",
      "Novomycetin",
      "Novophenicol",
      "Oftalent",
      "Oleomycetin",
      "Opclor",
      "Opelor",
      "Ophthochlor",
      "Ophtochlor",
      "Optomycin",
      "Otachron",
      "Otophen",
      "Pantovernil",
      "Paraxin",
      "Pentamycetin",
      "Quemicetina",
      "Rivomycin",
      "Romphenil",
      "Ronfenil",
      "Ronphenil",
      "Septicol",
      "Sificetina",
      "Sintomicetina",
      "Stanomycetin",
      "Synthomycetin",
      "Synthomycetine",
      "Synthomycine",
      "Syntomycin",
      "Tevcocin",
      "Tevcosin",
      "Tifomycin",
      "Tifomycine;Tiromycetin",
      "Treomicetina",
      "Unimycetin",
      "Veticol",
      "Viceton",
      "Ch Loramex",
      "Chloramfenikol [Czech]",
      "Chloramphenicol Crystalline",
      "Chlormycetin R",
      "Chlorocid S",
      "Chlorocidin C",
      "Chlorocidin C Tetran",
      "Chloroject L",
      "Chloromycetny [Polish]",
      "Cloramfenicolo [DCIT]",
      "Cloroamfenicolo [Italian]",
      "F Armicetina",
      "Isopto Fenicol",
      "Klorocid S",
      "Normimycin V",
      "Sintomicetine R",
      "Sno Phenicol",
      "Vice Ton",
      "I 337A",
      "Ak-Chlor",
      "Alficetyn (TN)",
      "Amphicol (TN)",
      "Biomicin (TN)",
      "Brochlor (TN)",
      "CAF (Pharmaceutical)",
      "Cedoctine (TN)",
      "Chlora-Tabs",
      "Chloramex (TN)",
      "Chloramphenicol & VRC3375",
      "Chloramphenicolum [INN-Latin]",
      "Chlorbiotic (Veterinary)",
      "Chlornitromycin (TN)",
      "Chloro-25 Vetag",
      "Chloromycetin (TN)",
      "Chlorsig (TN)",
      "Cloramfenicol [INN-Spanish]",
      "D-Chloramphenicol",
      "Dispersadron C (TN)",
      "Econochlor (TN)",
      "Elase-Chloromycetin",
      "Fenicol (TN)",
      "Golden Eye (TN)",
      "Isoptophenicol (TN)",
      "Kemicetine (TN)",
      "Laevomycetin (TN)",
      "Medicom (TN)",
      "Mychel-Vet",
      "Nevimycin (TN)",
      "Oftan Chlora (TN)",
      "Optrex Infected Eyes (TN)",
      "Orchadexoline (TN)",
      "Phenicol (TN)",
      "Renicol (TN)",
      "Silmycetin (TN)",
      "Sno-Phenicol",
      "Synthomycine (TN)",
      "Tea-Cetin",
      "Tega-Cetin",
      "Tifomycine (TN)",
      "U-6062",
      "Vernacetin (TN)",
      "Veticol (TN)",
      "C.A.F",
      "CHLOROPTIC S.O.P",
      "Chloramphenicol [INN:BAN:JAN]",
      "Chloromycetin&#174",
      "D-Threo-Chloramphenicol",
      "Alficetyn, Chlornitromycin, Chloromycetin, Chloramphenicol",
      "Chloramphenicol (JP15/USP/INN)",
      "Chloroptic S.O.P.",
      "D(-)-Threo-Chloramphenicol",
      "D-(-)-Chloramphenicol",
      "D-(-)-Threo-Chloramphenicol",
      "D(-)-Threo-2-Dichloroacetamido-1-P-Nitrophen Yl-Propanediol",
      "D(-)-Threo-2-Dichloroacetamido-1-P-Nitrophenyl-Propanediol",
    ],
  },
  {
    primary_id: "D0X6JG",
    names: ["Antithrombotics"],
  },
  {
    primary_id: "D0X6KS",
    names: [
      "N-[4-(3-Amino-1H-Indazol-4-Yl)Phenyl]benzamide",
      "CHEMBL223185",
      "N-(4-(3-Amino-1H-Indazol-4-Yl)Phenyl)Benzamide",
      "Benzamide,n-[4-(3-Amino-1h-Indazol-4-Yl)Phenyl]-",
      "BDBM50207504",
      "ZINC35324132",
      "935660-76-7",
    ],
  },
  {
    primary_id: "D0X6LC",
    names: ["Monteplase", "Cleactor (TN)"],
  },
  {
    primary_id: "D0X6LG",
    names: ["MC-1002"],
  },
  {
    primary_id: "D0X6LK",
    names: [
      "5-Hydroxy-2-Phenylisoindoline-1,3-Dione",
      "CHEMBL276030",
      "4-Hydroxy-N-Phenylphthalimide",
      "3975-50-6",
      "5-Hydroxy-2-Phenyl-Isoindole-1,3-Dione",
      "SCHEMBL8615277",
      "CTK1B3719",
      "DTXSID80445430",
      "HCUAWJNHCZEMJS-UHFFFAOYSA-N",
      "ZINC26020837",
      "BDBM50088673",
      "5-Hydroxy-2-Phenyl-1H-Isoindole-1,3(2H)-Dione",
      "1H-Isoindole-1,3(2H)-Dione, 5-Hydroxy-2-Phenyl-",
    ],
  },
  {
    primary_id: "D0X6LS",
    names: [
      "6-P-Tolylamino-1H-Pyrimidine-2,4-Dione",
      "CHEMBL57397",
      "6948/11/4",
      "6-[(4-Methylphenyl)Amino]pyrimidine-2,4(1h,3h)-Dione",
      "NSC55724",
      "AC1Q6LTF",
      "CBMicro_020485",
      "Oprea1_316803",
      "Oprea1_212741",
      "SCHEMBL5298937",
      "AC1L6E57",
      "CTK5D0097",
      "2,4(1H,3H)-Pyrimidinedione, 6-[(4-Methylphenyl)Amino]-",
      "MolPort-003-722-204",
      "ZINC227430",
      "CCG-8301",
      "NSC-55724",
      "BDBM50022161",
      "AKOS001620102",
      "MCULE-6764538026",
      "BIM-0020481.P001",
      "EU-0034750",
      "SR-01000400155",
      "SR-01000400155-1",
    ],
  },
  {
    primary_id: "D0X6LZ",
    names: ["3-Chlorotyrosine"],
  },
  {
    primary_id: "D0X6MB",
    names: [
      "3-Methyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL359703",
      "CHEMBL545291",
      "3-Methylpyrrolidine-2-Imine",
      "BDBM50066781",
      "AKOS006353988",
      "3-Methyl-Pyrrolidin-(2E)-Ylideneamine",
    ],
  },
  {
    primary_id: "D0X6MO",
    names: ["Ralitoline"],
  },
  {
    primary_id: "D0X6MT",
    names: ["METHIOTHEPIN"],
  },
  {
    primary_id: "D0X6NU",
    names: ["Debio-1142"],
  },
  {
    primary_id: "D0X6NZ",
    names: ["AFN-1720"],
  },
  {
    primary_id: "D0X6OV",
    names: ["Toxaphene"],
  },
  {
    primary_id: "D0X6OW",
    names: ["Cefminox Sodium"],
  },
  {
    primary_id: "D0X6OX",
    names: [
      "CAB-051",
      "CAB-052",
      "Anti-ErbB2 Humanized Antibodies (Solid Tumor), Biotecnol",
      "Anti-HER-2 Humanized Antibodies (Solid Tumor), Biotecnol",
    ],
  },
  {
    primary_id: "D0X6PG",
    names: ["LY-274614"],
  },
  {
    primary_id: "D0X6PH",
    names: ["5-(6-Hydroxy-2-Naphthyl)-1,2,3-Benzenetriol", "CHEMBL246045", "BDBM50197243"],
  },
  {
    primary_id: "D0X6PY",
    names: ["EP 157"],
  },
  {
    primary_id: "D0X6QL",
    names: ["KGP94", "CHEMBL1269632", "BDBM50330030"],
  },
  {
    primary_id: "D0X6QQ",
    names: ["Peptidomimetic Analog 4"],
  },
  {
    primary_id: "D0X6QY",
    names: ["4-Ethyl-5-Methyl-Pyrrolidin-(2Z)-Ylideneamine", "CHEMBL363477", "BDBM50150933"],
  },
  {
    primary_id: "D0X6RO",
    names: [
      "4-(4-Fluoro-Benzylideneamino)-Benzenesulfonamide",
      "CHEMBL427835",
      "4-[(4-Fluorobenzylidene)Amino]benzenesulfonamide",
      "4-{[(E)-(4-Fluorophenyl)Methylidene]amino}benzenesulfonamide",
      "NSC720519",
      "AC1L8N2R",
      "SCHEMBL5311683",
      "CTK7B9640",
      "BKZPDMSMFWCHPI-UHFFFAOYSA-N",
      "MolPort-003-705-944",
      "ZINC18102137",
      "BDBM50371768",
      "ZINC95919526",
      "AKOS001613042",
      "ZINC253618202",
      "AKOS016038195",
      "NSC-720519",
      "MCULE-4378033729",
      "ST009698",
      "KB-117288",
      "N-(4-Fluorobenzylidene)-4-Sulfamoylaniline",
      "EU-0002043",
      "SR-01000079257",
      "SR-01000079257-1",
      "4-[(4-Fluoro-Benzylidene)-Amino]-Benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0X6SA",
    names: [
      "2-Naphthalen-2-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "CHEMBL3144577",
      "CHEMBL8355",
      "SCHEMBL9645961",
      "BDBM50009006",
    ],
  },
  {
    primary_id: "D0X6SJ",
    names: ["Selenium Compound 3"],
  },
  {
    primary_id: "D0X6SR",
    names: ["(R)-4-(2-(2-Methylpyrrolidin-1-Yl)Ethyl)Pyridine", "CHEMBL271722"],
  },
  {
    primary_id: "D0X6SZ",
    names: ["GNTI"],
  },
  {
    primary_id: "D0X6TF",
    names: ["(+/-)-Threo-Ritalinol", "CHEMBL1188734", "BDBM50212386"],
  },
  {
    primary_id: "D0X6TZ",
    names: ["RCD-1"],
  },
  {
    primary_id: "D0X6UV",
    names: ["4-Fluorobenzaldehyde O-Benzoyloxime", "CHEMBL180720", "SCHEMBL3225285", "SCHEMBL3225274"],
  },
  {
    primary_id: "D0X6VC",
    names: ["HIV Vaccine"],
  },
  {
    primary_id: "D0X6WQ",
    names: ["BENZOYL-TYROSINE-ALANINE-METHYL KETONE", "Benzoyl-Tyrosine-Alanine-Methyl Ketone"],
  },
  {
    primary_id: "D0X6WV",
    names: ["1-(4-Methyloxazol-2-Yl)-7-Phenylheptan-1-One", "CHEMBL462405", "SCHEMBL2162404"],
  },
  {
    primary_id: "D0X6XC",
    names: ["Lm Ft"],
  },
  {
    primary_id: "D0X6XF",
    names: ["VTP-201227"],
  },
  {
    primary_id: "D0X6XH",
    names: ["CM-2395"],
  },
  {
    primary_id: "D0X6XP",
    names: ["JIN-2011"],
  },
  {
    primary_id: "D0X6YR",
    names: ["N-(5-Chlorobenzo[d]oxazol-2-Yl)Benzenesulfonamide", "CHEMBL207096"],
  },
  {
    primary_id: "D0X6ZG",
    names: ["XEN-D0101"],
  },
  {
    primary_id: "D0X6ZY",
    names: [
      "Cis-1-Adamantan-1-Yl-3-(4-Hydroxycyclohexyl)Urea",
      "CHEMBL242257",
      "CHEMBL395714",
      "SCHEMBL3260841",
      "SCHEMBL1066689",
      "SCHEMBL1065034",
      "SCHEMBL1065036",
      "YHZXOUHNBSOYQD-QVTUNDJTSA-N",
      "YHZXOUHNBSOYQD-PIYFJZFMSA-N",
      "BDBM50217474",
      "BDBM50217447",
      "AKOS031057010",
      "AKOS009538033",
      "Trans-1-Adamantan-1-Yl-3-(4-Hydroxycyclohexyl)Urea",
      "Cis-1-(4-Hydroxy-Cyclohexyl)-3-Tricyclo[3.3.1.13,7]decan-1-Yl-Urea",
    ],
  },
  {
    primary_id: "D0X7AM",
    names: ["SC-0030"],
  },
  {
    primary_id: "D0X7AZ",
    names: [
      "SPT",
      "8-Pspt",
      "8-(P-Sulfophenyl)Theophylline Hydrate",
      "MLS000859938",
      "CHEMBL1697922",
      "HMS3373H03",
      "HMS2230M12",
      "AKOS024360233",
      "SMR000326799",
      "8-(P-Sulfophenyl)Theophylline Hydrate, Solid",
      "ST50990065",
      "4-(1,3-Dimethyl-2,6-Dioxo-1,3-Dihydropurin-8-Yl)Benzenesulfonic Acid, Hydrate",
      "4-(1,3-Dimethyl-2,6-Dioxo-2,3,6,9-Tetrahydro-1H-Purin-8-Yl)Benzenesulfonic Acid Hemihydrate",
    ],
  },
  {
    primary_id: "D0X7BG",
    names: ["SM 21"],
  },
  {
    primary_id: "D0X7BN",
    names: ["AP32788"],
  },
  {
    primary_id: "D0X7BP",
    names: [
      "3-Carbamoyl-6-Chloro-1H-Indole-2-Carboxylic Acid",
      "CHEMBL42415",
      "BDBM50280277",
      "6-Chloro-3-Carbamoyl-1H-Indole-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0X7BT",
    names: ["PMID25399762-Compound-Table 7-4-Hydroxybenzyl Alcohol"],
  },
  {
    primary_id: "D0X7CI",
    names: ["EP HIV-1090"],
  },
  {
    primary_id: "D0X7CV",
    names: ["PTX-200"],
  },
  {
    primary_id: "D0X7CW",
    names: [
      "3-Phenyl-Cyclosal-D4TMP",
      "CHEMBL221156",
      "AC1LAXDT",
      "BDBM50206640",
      "5-Methyl-1-[(2R,5S)-5-[(2-Oxo-8-Phenyl-4H-1,3,2",
      "5-Methyl-1-[(2R,5S)-5-(2-Oxo-8-Phenyl-4H-2.lambda. 5-Benzo[1,3,2]dioxaphosphinin-2-Yloxymethyl)-2,5-Dihydro-Furan-2-Yl]-1H-Pyrimidine-2,4-Dione",
      "5-Methyl-1-[(2R,5S)-5-(2-Oxo-8-Phenyl-4H-2lambda*5*-Benzo[1,3,2]dioxaphosphinin-2-Yloxymethyl)-2,5-Dihydro-Furan-2-Yl]-1H-Pyrimidine-2,4-Dione",
    ],
  },
  {
    primary_id: "D0X7CX",
    names: ["DHbetaE", "Dihydro-Beta-Erythroidine"],
  },
  {
    primary_id: "D0X7DE",
    names: ["Nefazodone"],
  },
  {
    primary_id: "D0X7DJ",
    names: [
      "6-(2,5-Difluoro-4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL195289",
      "SCHEMBL3930290",
      "VLJUOFGVEJAFFK-UHFFFAOYSA-N",
      "ZINC13645040",
      "BDBM50168359",
    ],
  },
  {
    primary_id: "D0X7DN",
    names: ["ISIS 113872"],
  },
  {
    primary_id: "D0X7DZ",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 1"],
  },
  {
    primary_id: "D0X7EG",
    names: ["OC-000459"],
  },
  {
    primary_id: "D0X7EQ",
    names: ["Resorcinol Compound 19"],
  },
  {
    primary_id: "D0X7FV",
    names: ["ISIS 100750"],
  },
  {
    primary_id: "D0X7GA",
    names: ["RO-6036"],
  },
  {
    primary_id: "D0X7GL",
    names: ["Retigabine"],
  },
  {
    primary_id: "D0X7GU",
    names: ["Rizaport"],
  },
  {
    primary_id: "D0X7GX",
    names: ["Dendritic Cancer Vaccine"],
  },
  {
    primary_id: "D0X7HD",
    names: [
      "CP-810123",
      "UNII-E6G4550EC4",
      "CHEMBL604798",
      "E6G4550EC4",
      "BSNKYWSMUAGMDO-UHFFFAOYSA-N",
      "439608-12-5",
      "SCHEMBL1459339",
      "BDBM50309861",
      "1,4-Diazabicyclo(3.2.2)Nonane, 4-(5-Methyloxazolo(4,5-B)Pyridin-2-Yl)-",
      "4-(5-Methyloxazolo[4,5-B]pyridin-2-Yl)-1,4-Diazabicyclo[3.2.2]nonane",
      "4-(5-Methyloxazolo[4,5-B]pyridin-2-Yl)-1,4-Diazabicyclo[3.2.2]-Nonane",
      "4-(5-Methyloxazolo[4,5-B]pyridin-2-Yl)-1,4-Diaza-Bicyclo[3.2.2]nonane",
    ],
  },
  {
    primary_id: "D0X7HM",
    names: [
      "Carboplatin",
      "Azanide",
      "Carbopaltin",
      "Carboplatine",
      "Carboplatino",
      "Carboplatinum",
      "Cbdca",
      "Ercar",
      "Paraplatin",
      "Carboplatine [French]",
      "Carboplatino [Spanish]",
      "Carboplatinum [Latin]",
      "C 2538",
      "JM 8",
      "Carboplatin (USAN)",
      "IUPAC: Azane",
      "JM-8",
      "Paraplatin (TN)",
      "Paraplatin, Carboplatin",
      "Paraplatin-AQ",
      "Cis-Diammine(Cyclobutanedicarboxylato)PlatinumII",
      "Platinum(+2) Cation",
      "Carboplatin (JAN/USP/INN)",
      "Carboplatin [USAN:INN:BAN:JAN]",
      "Cyclobutane-1,1-Dicarboxylate",
      "Cyclobutane-1,1-Dicarboxylic Acid",
      "Diammine-1,1-Cyclobutane Dicarboxylate Platinum II",
      "Cis-Diamine[1,1-Cyclobutanedicarboxylato]platinum(II)",
      "Cis-Diammine(1,1-Cyclobutanedicarboxylato) Platinum",
      "Cis-Diammine(1,1-Cyclobutanedicarboxylato)Platinum",
      "Cis-Diammine[1,1-Cyclobutane-Dicarboxylato] Platinum",
      "Diammine(1,1-Cyclobutanedicarboxylato)Platinum (II)",
      "Platinum, {diammine[1,1-Cyclobut",
      "Cis-(1,1-Cyclobutanedicarboxylato)Diammineplatinum(II)",
      "Cis-Diamine(1,1-Cyclobutanedicarboxylato)Platinum(II)",
      "Cis-Diammine(1,1-Cyclobutanedicarboxylato)Platinum(II)",
      "Platinum(II), (1, 1-Cyclobutanedicar",
      "Diammine[cyclobutane-1,1-Dicarboxylato(2-)-K2O1,O1]platinum",
      "Diammine(Cyclobutane-1,1-Dicarboxylato(2-)-O,O')Platinum",
      "Platinum, Diammine(1,1-Cyclobutanedicarboxylato(2-)-O,O')-, (SP-4-2)",
      "(SP-4-2)-Diammine[cyclobutane-1,1-Dicarboxylato(2-)-Kappa(2)O,O']platinum",
      "1,1-Cyclobutanedicarboxylate Diammine Platinum (II)",
      "1,1-Cyclobutanedicarboxylate Diammine Platinum(II)",
    ],
  },
  {
    primary_id: "D0X7HN",
    names: ["PMID26651364-Compound-50"],
  },
  {
    primary_id: "D0X7HP",
    names: ["2-Fluorophenyl 4-Phenoxyphenylcarbamate", "CHEMBL599179", "BDBM50309730"],
  },
  {
    primary_id: "D0X7HQ",
    names: ["NitroSense PA6"],
  },
  {
    primary_id: "D0X7HV",
    names: ["ZD-6169"],
  },
  {
    primary_id: "D0X7IC",
    names: ["NG-25"],
  },
  {
    primary_id: "D0X7IM",
    names: [
      "1,3-DIPHENYLUREA",
      "1,3-Diphenylurea",
      "102-07-8",
      "N,N'-Diphenylurea",
      "CARBANILIDE",
      "Diphenylurea",
      "Diphenylcarbamide",
      "Urea, N,N'-Diphenyl-",
      "S-Diphenylurea",
      "Sym-Diphenylurea",
      "Acardite I",
      "Acardite",
      "N-Phenyl-N'-Phenylurea",
      "1,3-Diphenylcarbamide",
      "Karbanilid",
      "N,N'-Difenylmocovina",
      "USAF EK-534",
      "Urea, 1,3-Diphenyl-",
      "Karbanilid [Czech]",
      "1,3-Diphenyl-Urea",
      "AD 30",
      "UNII-94YD8RMX5B",
      "NSC 227401",
      "N,N'-Difenylmocovina [Czech]",
      "CCRIS 4634",
      "HSDB 2757",
      "Urea-Based Compound, 7",
      "EINECS 203-003-7",
      "BRN 0782650",
      "94YD8RMX5B",
      "AI3-52320",
      "CHEBI:41320",
      "GWEHVDNNLFDJLR-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0X7IN",
    names: [
      "BGT226",
      "BGT-226 Free Base",
      "915020-55-2",
      "UNII-ZXE7F2GMJJ",
      "BGT226 Free Base",
      "ZXE7F2GMJJ",
      "BGT-226",
      "8-(6-Methoxypyridin-3-Yl)-3-Methyl-1-[4-(Piperazin-1-Yl)-3-Trifluoromethylphenyl]-1,3-Dihydroimidazo[4,5-C]quinolin-2-One",
      "CHEBI:71967",
      "BGT 226",
      "NVPBGT226",
      "8-(6-Methoxypyridin-3-Yl)-3-Methyl-1-[4-(Piperazin-1-Yl)-3-(Trifluoromethyl)Phenyl]-1,3-Dihydro-2H-Imidazo[4,5-C]quinolin-2-One",
      "8-(6-Methoxy-Pyridin-3-Yl)-3-Methyl-1-(4-Piperazin-1-Yl-3-Trifluoromethyl-Phenyl)-1,3-Dihydro-Imidazo[4,5-C]quinolin-2-One",
      "NPV-BGT226",
      "SCHEMBL146939",
    ],
  },
  {
    primary_id: "D0X7IS",
    names: ["Dihydropyridine Compound 5"],
  },
  {
    primary_id: "D0X7IT",
    names: ["Ioxaglate"],
  },
  {
    primary_id: "D0X7IW",
    names: [
      "Tolerizing Peptide",
      "Allergy Therapeutic Peptide, Acambis",
      "PTL-0901",
      "Tolerizing Peptide, Acambis",
      "Tolerizing Peptide (Rye Grass), Acambis",
    ],
  },
  {
    primary_id: "D0X7IZ",
    names: ["N-Benzoyl-N'-Beta-D-Glucopyranosyl Urea"],
  },
  {
    primary_id: "D0X7JA",
    names: ["Alpha-Ketoisocaproate", "Alpha-Ketoisocaproic Acid", "2-Oxoisohexanoate", "Alpha-Oxoisohexanoate"],
  },
  {
    primary_id: "D0X7JC",
    names: ["BAY-86-4367"],
  },
  {
    primary_id: "D0X7JK",
    names: ["TCS-359"],
  },
  {
    primary_id: "D0X7JN",
    names: [
      "Clavulanate",
      "Clavulansaeure",
      "Clavulox",
      "Acide Clavulanique",
      "Acido Clavulanico",
      "Acidum Clavulanicum",
      "CLAVULANIC ACID",
      "Clavulansaeure [INN]",
      "Clavulinic Acid",
      "Sodium Clavulanate",
      "Antibiotic MM 14151",
      "MM 14151",
      "Acide Clavulanique [INN-French]",
      "Acido Clavulanico [INN-Spanish]",
      "Acidum Clavulanicum [INN-Latin]",
      "BRL-14151",
      "Clavulanic Acid (INN)",
      "Clavulanic Acid [BAN:INN]",
      "Clavulox (TN)",
      "MM-14151",
      "(2R,3Z,5R)-3-(2-Hydroxyethylidene)-7-Oxo-4-Oxa-1-Azabicyclo[3.2.0]heptane-2-Carboxylic Acid",
      "(Z)-(2R,5R)-3-(2-Hydroxyethyliden)-7-Oxo-4-Oxa-1-Azabicyclo(3.2.0)Heptan-2-Carbonsaeure",
      "(Z)-(2R,5R)-3-(2-Hydroxyethylidene)-7-Oxo-4-Oxa-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid",
      "3008-B",
      "4-Oxa-1-Azabicyclo(3.2.0)Heptane-2-Carboxylic Acid, 3-(2-Hydroxyethylidene)-7-Oxo-, (2R-(2alpha,3Z,5alpha))",
    ],
  },
  {
    primary_id: "D0X7JR",
    names: [
      "Eflornithine",
      "EFLORNITHINE",
      "Ornidyl",
      "70052-12-9",
      "2-(Difluoromethyl)Ornithine",
      "Alpha-Difluoromethylornithine",
      "Difluromethylornithine",
      "Eflornitina [Spanish]",
      "Eflornithinum [Latin]",
      "2,5-Diamino-2-(Difluoromethyl)Pentanoic Acid",
      "Alpha-(Difluoromethyl)-DL-Ornithine",
      "N-Difluoromethylornithine",
      "DFMO (Growth Regulator)",
      "67037-37-0",
      "Eflornithine [INN:BAN]",
      "2-(Difluoromethyl)-DL-Ornithine",
      "DL-Alpha-(Difluoromethyl)Ornithine",
      "MDL 71782",
      "CCRIS 3295",
      "Ornithine, 2-(Difluoromethyl)-",
      "Eflornithinum",
      "Eflornitina",
      "DFMO Hydrochloride",
      "DFMO Hydrochloride Hydrate",
      "EFLORNITHINE HYDROCHLORIDE",
      "BMS 203522",
      "Alpha-DFMO HCl",
      "Alpha-Difluoromethylornithine Hydrochloride",
      "CPP-1X",
      "Eflornithine (INN)",
      "Ornidyl (TN)",
      "DL-Alpha-Difluoromethylornithine",
      "DL-Alpha-Difluoromethylornithine Hydrochloride Hydrate",
      "D,L-Alpha-Difluoromethylornithine",
      "Alpha,delta-Diamino-Alpha-(Difluoromethyl)Valeric Acid",
      "Ornithine, 2-(Difluoromethyl)-, Monohydrochloride",
      "DL-Ornithine, 2-(Difluoromethyl)-, Monohydrochloride",
      "DL-Ornithine, 2-(Difluoromethyl)-, Monohydrochloride, Monohydrate",
      "2,5-Diamino-2-(Difluoromethyl)Pentanoic Acid Hydrochloride",
      "2-(Difluoromethyl)-DL-Ornithine HCl",
      "2-(Difluoromethyl)-DL-Ornithine Hydrochloride",
      "2-(Difluoromethyl)-DL-Ornithine Monohydrochloride",
      "2-(Difluoromethyl)Ornithine Hydrochloride Hydrate",
    ],
  },
  {
    primary_id: "D0X7KB",
    names: ["Lisuride"],
  },
  {
    primary_id: "D0X7KZ",
    names: [
      "Strontium Ranelate",
      "Bivalos",
      "Osseor",
      "Protaxos",
      "Protelos",
      "Protos",
      "FK-481",
      "S 12911-2",
      "S-12911",
    ],
  },
  {
    primary_id: "D0X7LC",
    names: [
      "Tyr-Gly-Gly-Trp-NMeNle-Asp-Phe-NH2",
      "CHEMBL336381",
      "CCK-Opioid Peptide, 6",
      "BDBM21136",
      "(3S)-3-[(2S)-2-[(2S)-2-(2-{2-[(2S)-2-Amino-3-(4-Hydroxyphenyl)Propanamido]acetamido}acetamido)-3-(1H-Indol-3-Yl)-N-Methylpropanamido]hexanamido]-3-{[(1S)-1-Carbamoyl-2-Phenylethyl]carbamoyl}propanoic Acid",
    ],
  },
  {
    primary_id: "D0X7LY",
    names: [
      "CT-100",
      "CT-100, CisThera",
      "PAI-1 Modulator (Humanized Antibody, Kidney Disease), CisThera",
      "Plasminogen Activator Inhibitor-1 Modulator (Humanized Antibody, Kidney Disease), CisThera",
    ],
  },
  {
    primary_id: "D0X7MB",
    names: ["Syn-BrP-LPA"],
  },
  {
    primary_id: "D0X7MU",
    names: ["ISIS 13727"],
  },
  {
    primary_id: "D0X7MV",
    names: ["Pyrrolidinyl Urea Derivative 1"],
  },
  {
    primary_id: "D0X7MY",
    names: ["AGS-16 Mab,", "AGS-16M18"],
  },
  {
    primary_id: "D0X7NT",
    names: ["SAF-312"],
  },
  {
    primary_id: "D0X7NU",
    names: ["Pralidoxime Chloride"],
  },
  {
    primary_id: "D0X7OF",
    names: ["PMID25991433-Compound-N2"],
  },
  {
    primary_id: "D0X7OS",
    names: ["S-P-Nitrobenzyloxycarbonylglutathione"],
  },
  {
    primary_id: "D0X7PH",
    names: ["Darunavir STR"],
  },
  {
    primary_id: "D0X7PP",
    names: ["ERITADENINE"],
  },
  {
    primary_id: "D0X7QQ",
    names: [
      "Benperidol",
      "BENPERIDOL",
      "Anquil",
      "2062-84-2",
      "Glianimon",
      "Frenactil",
      "Frenactyl",
      "Concilium",
      "Benzoperidol",
      "McN-JR-4584",
      "Benzperidol",
      "Glianimon Mite",
      "R-4584",
      "Anquil (VAN)",
      "Benperidolo [DCIT]",
      "Benperidol Hydrochloride",
      "Benzeridol",
      "Benperidolum [INN-Latin]",
      "R 4584",
      "Benperidol [USAN:INN:BAN]",
      "McN-JR 4584",
      "CB 8089",
      "UNII-97O6X78C53",
      "8089 C. B.",
      "EINECS 218-172-2",
      "NSC 170982",
      "BRN 0900981",
      "1-{1-[4-(4-Fluorophenyl)-4-Oxobutyl]piperidin-4-Yl}-1h-Benzo[d]imidazol-2(3h)-One",
      "8089 C.B.",
      "C22H24FN3O2",
      "FEBOTPHFXYHVPL-UHFFFAOYSA-N",
      "NSC170982",
      "2H-Benzimidazol-2-One, 1-[1-[4",
    ],
  },
  {
    primary_id: "D0X7SD",
    names: [
      "2-(Biphenyl-2-Yloxy)-N,N-Dimethylethanamine",
      "CHEMBL20666",
      "N,N-Dimethyl-2-(2-Phenylphenoxy)Ethanamine",
      "AC1LHJ83",
      "ZINC4913607",
      "BDBM50307494",
      "MCULE-6428005896",
    ],
  },
  {
    primary_id: "D0X7SR",
    names: ["8-Prenylapigenin"],
  },
  {
    primary_id: "D0X7TB",
    names: ["2-(2-Methyl-Morpholin-4-Yl)-Benzo[h]chromen-4-One", "CHEMBL435507", "SCHEMBL3545107"],
  },
  {
    primary_id: "D0X7VB",
    names: [
      "MBX-102",
      "Arhalofenate",
      "Mbx-102",
      "MBX 102",
      "24136-23-0",
      "UNII-1P01UJR9X1",
      "JNJ-39659100",
      "1P01UJR9X1",
      "CB-102",
      "M-102",
      "Arhalofenate [USAN:INN]",
      "Arhalofenate (USAN/INN)",
      "SCHEMBL3302781",
      "CHEMBL2103824",
      "BJBCSGQLZQGGIQ-QGZVFWFLSA-N",
      "ZINC2012859",
      "BDBM50093473",
      "AKOS032945719",
      "DB11811",
      "CS-6812",
      "SB16866",
      "(-)-2-(Acetylamino)Ethyl (2R)-(4-Chlorophenyl)(3-(Trifluoromethyl)Phenoxy)Acetate",
      "CJ-31172",
      "Benzeneacetic Acid, 4-Chloro-Alpha-(3-(Trifluoromethyl)Phenoxy)-, 2-(Acetylamino)Ethyl Ester, (-)-",
      "HY-14831",
      "D09579",
      "UNII-K9TZK4MNO6 Component BJBCSGQLZQGGIQ-QGZV",
    ],
  },
  {
    primary_id: "D0X7VM",
    names: ["KYS-05090"],
  },
  {
    primary_id: "D0X7WK",
    names: ["AZD-6553"],
  },
  {
    primary_id: "D0X7XG",
    names: ["Fusidic Acid"],
  },
  {
    primary_id: "D0X7YC",
    names: ["IGE-026"],
  },
  {
    primary_id: "D0X7YR",
    names: ["PGCvax"],
  },
  {
    primary_id: "D0X7ZC",
    names: [
      "9-Aminomethyl-9H-Fluorene-3,4-Diol",
      "103692-53-1",
      "CHEMBL55693",
      "9H-Fluorene-3,4-Diol,9-(Aminomethyl)-",
      "3,4-Dfma",
      "F 103243",
      "9-(Aminomethyl)-9H-Fluorene-3,4-Diol",
      "Skf 103243",
      "F-103243",
      "ACMC-20m6ix",
      "3,4-Dihydroxy-9H-Fluorene-9-Methanamine",
      "AC1L2TS9",
      "CTK4A2321",
      "BDBM50025201",
      "9H-Fluorene-3,4-Diol, 9-(Aminomethyl)-",
    ],
  },
  {
    primary_id: "D0X7ZE",
    names: ["AdVac Malaria Vaccine"],
  },
  {
    primary_id: "D0X7ZG",
    names: ["Ledgins"],
  },
  {
    primary_id: "D0X7ZH",
    names: [
      "PAT-CM-1",
      "CM-1",
      "Human Monoclonal Antibody (Colon Cancer), Patrys",
      "CM-1 Antibody, OncoMab/Acceptys",
      "Human MAb (Colon Cancer), OncoMab/ Acceptys",
      "Human Monoclonal Antibody (Colon Cancer), OncoMab/Acceptys",
    ],
  },
  {
    primary_id: "D0X7ZL",
    names: ["Sodium Salicylate"],
  },
  {
    primary_id: "D0X7ZS",
    names: [
      "MAT-303",
      "Anti-CD160 Monoclonal Antibody (NK Leukemia/Paroxysmal Nocturnal Hemoglobinuria), MAT Biopharma",
      "Anti-CD160 Monoclonal Antibody (Cancer/Autoimmune Diseases), MAT Biopharma",
    ],
  },
  {
    primary_id: "D0X8AB",
    names: ["Mannoside Derivative 4"],
  },
  {
    primary_id: "D0X8AU",
    names: ["KNI-764"],
  },
  {
    primary_id: "D0X8BS",
    names: [
      "2-(4-Butoxy-Phenoxy)-N-Hydroxy-Acetamide",
      "CHEMBL175410",
      "Acetamide, 2-(4-Butoxyphenoxy)-N-Hydroxy-",
      "BDBM50015105",
    ],
  },
  {
    primary_id: "D0X8BU",
    names: ["PMID23489211C20"],
  },
  {
    primary_id: "D0X8CH",
    names: ["GSK2330672"],
  },
  {
    primary_id: "D0X8DF",
    names: ["N-(2-Diethylamino-Ethyl)-4-Hexyloxy-Benzamide", "CHEMBL122865", "BDBM50106170", "AKOS016689088"],
  },
  {
    primary_id: "D0X8DU",
    names: [
      "Clinprost",
      "Arteon",
      "Isocarbacyclin Methyl Ester",
      "TEI-9090",
      "TTC-909",
      "Clinprost (Liposomal), Taisho",
      "Clinprost (Liposomal), Teijin",
    ],
  },
  {
    primary_id: "D0X8DV",
    names: ["SCH-900931"],
  },
  {
    primary_id: "D0X8DW",
    names: ["6-Nitro-4-Phenyl-2-Piperazin-1-Yl-Quinoline", "CHEMBL167487", "BDBM50110575"],
  },
  {
    primary_id: "D0X8ED",
    names: [
      "Benserazide",
      "Serazide",
      "322-35-0",
      "Benseraszide",
      "Benserazida",
      "Benserazidum",
      "Ro 4-4602",
      "Ro 44602",
      "DL-Serine 2-(2,3,4-Trihydroxybenzyl)Hydrazide",
      "Benserazidum [INN-Latin]",
      "Benserazida [INN-Spanish]",
      "Benserazide [USAN:INN:BAN]",
      "DL-Seryltrihydroxybenzylhydrazine",
      "C10H15N3O5",
      "BRN 3984490",
      "DL-Serine, 2-((2,3,4-Trihydroxyphenyl)Methyl)Hydrazide",
      "CHEBI:64187",
      "DL-Serine 2-((2,3,4-Trihydroxyphenyl)Methyl)Hydrazide",
      "Serine, 2-(2,3,4-Trihydroxybenzyl)Hyrazide, DL-",
      "DL-Serine 2-[(2,3,4-Trihydroxyphenyl)Methyl]hydrazide",
    ],
  },
  {
    primary_id: "D0X8EE",
    names: ["VitiGam"],
  },
  {
    primary_id: "D0X8FV",
    names: ["N-Isopropyl-5,6-Dip-Tolylpyrazine-2-Carboxamide", "CHEMBL246477"],
  },
  {
    primary_id: "D0X8GB",
    names: ["ISIS 298701"],
  },
  {
    primary_id: "D0X8GC",
    names: ["N-Cyclobutyl-N-(Piperidin-4-Yl)-2-Naphthamide", "CHEMBL578346"],
  },
  {
    primary_id: "D0X8GJ",
    names: [
      "TMA-230",
      "(+)-(5R,6S)-6-[1(R)-Hydroxyethyl]-2-(3-Pyridyl)-2-Penem-3-Carboxylic Acid Acetoxymethyl Ester",
      "113141-80-3",
    ],
  },
  {
    primary_id: "D0X8HB",
    names: ["NAV5001"],
  },
  {
    primary_id: "D0X8HH",
    names: ["(E)-5-(3-Fluorostyryl)Isatin", "CHEMBL506301"],
  },
  {
    primary_id: "D0X8HP",
    names: [
      "TRIDMAC",
      "65-23-6",
      "3,4-Pyridinedimethanol, 5-Hydroxy-6-Methyl-",
      "2-Picoline-4,5-Dimethanol, 3-Hydroxy-",
    ],
  },
  {
    primary_id: "D0X8ID",
    names: ["SEL-24"],
  },
  {
    primary_id: "D0X8JD",
    names: ["PMID27788040-Compound-5"],
  },
  {
    primary_id: "D0X8JE",
    names: ["N-(3-(4-Hexyl-2-Hydroxyphenoxy)Phenyl)Acetamide", "CHEMBL404983"],
  },
  {
    primary_id: "D0X8JN",
    names: ["GFC-003"],
  },
  {
    primary_id: "D0X8KC",
    names: ["Arsenite", "Arsenenous Acid", "13768-07-5", "Arsenite (Ion)", "AC1L2NJ5"],
  },
  {
    primary_id: "D0X8KY",
    names: [
      "Fosinopril",
      "Acecor",
      "Fosenopril",
      "Monopril",
      "Staril",
      "FOSINOPRIL SODIUM",
      "Fosinopril (INN)",
      "Fosinopril [INN:BAN]",
      "Monopril (TN)",
      "SQ-28555",
      "SQ-28,555",
      "Fosinopril, (1(S*(R*)),2 Alpha,4 Beta)-Isomer",
      "(2S,4S)-4-Cyclohexyl-1-[2-[(2-Methyl-1-Propanoyloxypropoxy)-(4-Phenylbutyl)Phosphoryl]acetyl]pyrrolidine-2-Carboxylic Acid",
      "(2S,4S)-4-Cyclohexyl-1-[2-[[(1S)-2-Methyl-1-Propanoyloxypropoxy]-(4-Phenylbutyl)Phosphoryl]acetyl]pyrrolidine-2-Carboxylic Acid",
      "(2S,4S)-4-Cyclohexyl-1-{2-[(2-Methyl-1-Propionyloxy-Propoxy)-(4-Phenyl-Butyl)-Phosphinoyl]-Acetyl}-Pyrrolidine-2-Carboxylic Acid",
      "(4S)-4-Cyclohexyl-1-({[2-Methyl-1-(Propanoyloxy)Propoxy](4-Phenylbutyl)Phosphoryl}acetyl)-L-Proline",
      "(4S)-4-Cyclohexyl-1-{[{[2-Methyl-1-(Propanoyloxy)Propyl]oxy}(4-Phenylbutyl)Phosphoryl]acetyl}-L-Proline",
      "4-Cyclohexyl-1-[2-[(2-Methyl-1-Propanoyloxypropoxy)-(4-Phenylbutyl)Phosphoryl]acetyl]pyrrolidine-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0X8LG",
    names: ["1-Naphthalen-2-Yl-2-Pyrrolidin-1-Yl-Pentan-1-One"],
  },
  {
    primary_id: "D0X8LX",
    names: ["Aminopyridine Derivative 2"],
  },
  {
    primary_id: "D0X8MI",
    names: ["Berupipam"],
  },
  {
    primary_id: "D0X8MZ",
    names: ["Alvameline", "Alvameline Maleate", "LU-25077", "LU-25109", "LU-31126", "LU-32181", "Lu-25109M"],
  },
  {
    primary_id: "D0X8NK",
    names: ["Tripropeptin C"],
  },
  {
    primary_id: "D0X8NN",
    names: ["PMID28766366-Compound-Scheme11"],
  },
  {
    primary_id: "D0X8OL",
    names: [
      "N-(Quinolin-8-Yl)Thiophene-2-Sulfonamide",
      "CHEMBL257234",
      "AC1LH19W",
      "Oprea1_700618",
      "SCHEMBL7190921",
      "MolPort-003-066-216",
      "ZINC353084",
      "BDBM50372518",
      "AKOS001422726",
      "N-Quinolin-8-Ylthiophene-2-Sulfonamide",
      "MCULE-3746564892",
      "N-(8-Quinolyl)-2-Thiophenesulfonamide",
      "NCGC00161717-01",
      "SR-01000012975",
      "CU-00000000139-1",
      "SR-01000012975-1",
      "Z115639124",
    ],
  },
  {
    primary_id: "D0X8OW",
    names: ["QBX258"],
  },
  {
    primary_id: "D0X8PD",
    names: ["XGP-510", "CysLT1 Antagonist + Repositioned Compound (Allergy), Xaragen"],
  },
  {
    primary_id: "D0X8PU",
    names: ["Leukozene"],
  },
  {
    primary_id: "D0X8QD",
    names: ["VT-464"],
  },
  {
    primary_id: "D0X8QI",
    names: ["UP-116-77"],
  },
  {
    primary_id: "D0X8QO",
    names: ["PL-3994"],
  },
  {
    primary_id: "D0X8QP",
    names: ["FOSOPAMINE"],
  },
  {
    primary_id: "D0X8RA",
    names: [
      "Anti-BCR Mabs",
      "Anti-BCR MAbs (NHL)",
      "MAbs (NHL), Genitope",
      "Anti-BCR MAbs (NHL), Genitope",
      "Monoclonal Antibodies (Non-Hodgkin Lymphoma), Genitope",
      "Anti-B Cell Receptor Monoclonal Antibodies (Non-Hodgkin Lymphoma), Genitope",
    ],
  },
  {
    primary_id: "D0X8RN",
    names: [
      "Cis-2-Phenylcyclopropylamine",
      "Tranylcypromine, Cis-",
      "CHEMBL467794",
      "13531-35-6",
      "Cis-Cypromine",
      "CIS-1-AMINO-2-PHENYLCYCLOPROPANE",
      "DL-Cis-2-Phenylcyclopropylamine",
      "Cyclopropanamine, 2-Phenyl-, Cis-",
      "(+/-)-Cis-2-Phenylcyclopropylamine",
      "AC1ODZ9B",
      "Tranylcypromine Related Compound A Free Base",
      "Cyclopropanamine, 2-Phenyl-, (1R,2R)-Rel-",
      "Cis-2-Phenylcyclopropanamine",
      "SCHEMBL1720497",
      "CTK0F4162",
      "DTXSID80159308",
      "(-) Cis-2-Phenylcyclopropylamine",
      "AELCINSCMGFISI-RKDXNWHRSA-N",
      "(1R,2R)-2-Phenylcyclopropanamine",
      "ZINC1639567",
      "BDBM50261814",
    ],
  },
  {
    primary_id: "D0X8RS",
    names: ["RG6125"],
  },
  {
    primary_id: "D0X8SM",
    names: [
      "Anti-TNF Human Mabs",
      "Anti-TNF Human MAbs (Autoimmune Disease)",
      "Anti-TNF Human MAbs (Autoimmune Disease), Xencor",
      "Anti-TNF Human Monoclonal Antibodies (Autoimmune Disease), Xencor",
    ],
  },
  {
    primary_id: "D0X8SY",
    names: ["Oxazyme"],
  },
  {
    primary_id: "D0X8TS",
    names: ["RRx-001"],
  },
  {
    primary_id: "D0X8TY",
    names: ["Bidentate Pyrazolopyrimidine Acetamide Analog 4"],
  },
  {
    primary_id: "D0X8UK",
    names: [
      "5-(2-(1H-Imidazol-1-Yl)Ethyl)Quinoline",
      "CHEMBL54803",
      "Quinoline 33",
      "BDBM10028",
      "ZINC13808193",
      "5-[2-(Imidazol-1-Yl)Ethyl]quinoline",
      "5-[2-(1H-Imidazol-1-Yl)Ethyl]quinoline",
    ],
  },
  {
    primary_id: "D0X8UW",
    names: [
      "TRN-101",
      "Dual ROCK1/ROCK2 Inhibitor (Inhalant, COPD), Theron Pharmaceuticals",
      "Dual Rho Kinase 1/ Rho Kinase2 Inhibitor (Inhalant, COPD), Theron Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0X8VL",
    names: ["Isoxazoline Derivative 3"],
  },
  {
    primary_id: "D0X8VQ",
    names: ["3-(7-Methyl-1,8-Naphthyridin-2-Yl)Benzonitrile", "CHEMBL235837"],
  },
  {
    primary_id: "D0X8VV",
    names: ["Flobufen"],
  },
  {
    primary_id: "D0X8VX",
    names: ["DV281"],
  },
  {
    primary_id: "D0X8XA",
    names: ["Sulconazole"],
  },
  {
    primary_id: "D0X8XO",
    names: [
      "Z-VAD-CHO",
      "CHEMBL320954",
      "ZVAD-CHO",
      "BDBM50176519",
      "(S)-3-[(S)-2-((S)-2-Benzyloxycarbonylamino-3-Methyl-Butyrylamino)-Propionylamino]-4-Oxo-Butyric Acid",
    ],
  },
  {
    primary_id: "D0X8XT",
    names: [
      "CS-0777",
      "UNII-KCH74QG79A",
      "KCH74QG79A",
      "827344-05-8",
      "CS 0777",
      "SCHEMBL1454408",
      "CHEMBL1951587",
      "YXEQXPNSBUIRDZ-OAQYLSRUSA-N",
      "ZINC59872504",
      "1192731-63-7",
      "1-Butanone, 1-(5-((3R)-3-Amino-4-Hydroxy-3-Methylbutyl)-1-Methyl-1H-Pyrrol-2-Yl)-4-(4-Methylphenyl)-",
    ],
  },
  {
    primary_id: "D0X8XU",
    names: ["1-(Isoquinolin-5-Yl)-3-(1-Phenylpropyl)Urea"],
  },
  {
    primary_id: "D0X8YK",
    names: ["BPH-742", "Bisphosphonate, 9", "BPH742", "BPH 742"],
  },
  {
    primary_id: "D0X8YT",
    names: ["PMID26004420-Compound-WO2014150132A"],
  },
  {
    primary_id: "D0X8YV",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 35"],
  },
  {
    primary_id: "D0X8ZA",
    names: [
      "Cyanopindolol",
      "69906-85-0",
      "CYANOPINDOLOL HEMIFUMARATE",
      "4-[3-(Tert-Butylamino)-2-Hydroxypropoxy]-1H-Indole-2-Carbonitrile",
      "(+-)-Cyanopindolol",
      "(-)-Cyanopindolol",
      "AC1L4E3D",
      "CYANOPINDOLOL(+/-)",
      "GTPL132",
      "SCHEMBL353756",
      "CHEMBL378501",
      "CTK8E9328",
      "BDBM81499",
      "CHEBI:125406",
      "PDSP1_001601",
      "PDSP2_001585",
      "PDSP1_001094",
      "PDSP2_001078",
      "NSC707473",
      "CAS_155346",
      "NSC_155346",
      "NSC-707473",
      "1H-Indole-2-Carbonitrile, 4-(3-((1,1-Dimethylethyl)Amino)-2-Hydroxypropoxy)-",
      "NCI60_038172",
      "RT-012114",
      "SR-01000597973",
      "L000210",
      "SR-01000597973-1",
      "BRD-A47884604-001-01-7",
    ],
  },
  {
    primary_id: "D0X8ZF",
    names: ["Imidazole Derivative 9"],
  },
  {
    primary_id: "D0X8ZO",
    names: [
      "1-(Oxazolo[4,5-B]pyridin-2-Yl)Dodecan-1-One",
      "1-OXAZOLO[4,5-B]PYRIDIN-2-YL-1-DODECANONE",
      "288862-73-7",
      "CHEMBL257307",
      "SCHEMBL3964457",
      "MolPort-009-018-944",
      "HMS3649B18",
      "BDBM50350547",
      "1312AH",
      "ZINC34803705",
      "AKOS027301548",
      "ACM288862737",
      "SR-01000946699",
      "1-[1,3]oxazolo[4,5-B]pyridin-2-Yldodecan-1-One",
    ],
  },
  {
    primary_id: "D0X8ZS",
    names: [
      "3-Methylphenylalanine",
      "5472-70-8",
      "2-Amino-3-(M-Tolyl)Propanoic Acid",
      "2-Amino-3-M-Tolyl-Propionic Acid",
      "3-Methy-DL-Phenylalanine",
      "DL-3-ME-PHE-OH",
      "2-Amino-3-(3-Methylphenyl)Propanoic Acid",
      "3-METHYLPHENYLALANINE",
      "3-Methylphe",
      "NSC14793",
      "AC1L5E1A",
      "SCHEMBL42906",
      "AC1Q5S51",
      "Ss-M-Tolyl-A-Amino-Propionsaure",
      "CTK8E3667",
      "KS-00000JHC",
      "3-METHYL-DL-PHENYLALANINE",
      "MolPort-003-990-079",
      "2-Amino-3-(M-Tolyl)Propionic Acid",
      "2283-42-3",
      "NSC29447",
      "NSC-29447",
      "NSC-14793",
      "AKOS016843161",
      "AKOS000174493",
      "MCULE-1997175050",
      "AM83420",
      "AB21660",
      "2-AMINO-3-M-TOLYLPROPANOIC ACID",
    ],
  },
  {
    primary_id: "D0X8ZY",
    names: ["BIA-10"],
  },
  {
    primary_id: "D0X8ZZ",
    names: ["Cardioprotectants", "Cardioprotectants (Nitroxyl Technology, Congestive Heart Failure)"],
  },
  {
    primary_id: "D0X9CH",
    names: [
      "Telaprevir",
      "402957-28-2",
      "VX-950",
      "Incivek",
      "Telaprevir (VX-950)",
      "Incivo",
      "MP-424",
      "VX 950",
      "Telavic",
      "VX-950(Telaprevir)",
      "LY-570310",
      "UNII-655M5O3W0U",
      "VRT-111950",
      "CHEMBL231813",
      "CHEBI:68595",
      "655M5O3W0U",
      "S-Telaprevir",
      "(1S,3aR,6aS)-2-((S)-2-((S)-2-Cyclohexyl-2-(Pyrazine-6-Carboxamido)Acetamido)-3,3-Dimethylbutanoyl)-N-((S)-1-(Cyclopropylamino)-1,2-Dioxohexan-3-Yl)-Octahydrocyclopenta[c]pyrrole-1-Carboxamide",
    ],
  },
  {
    primary_id: "D0X9CR",
    names: ["PMID29757691-Compound-10"],
  },
  {
    primary_id: "D0X9DL",
    names: ["Carboxamide Derivative 9"],
  },
  {
    primary_id: "D0X9DP",
    names: ["5-Hydroxy-2-(Propylthiomethyl)-4H-Pyran-4-One", "CHEMBL1269350"],
  },
  {
    primary_id: "D0X9EH",
    names: ["ISIS 2491"],
  },
  {
    primary_id: "D0X9FO",
    names: ["KC-399"],
  },
  {
    primary_id: "D0X9GC",
    names: ["Pyrazole Derivative 80"],
  },
  {
    primary_id: "D0X9GE",
    names: ["CIGB-500", "Cytoprotectant (GHRP-6, Ischemia-Reperfusion Damage), CIGB"],
  },
  {
    primary_id: "D0X9GL",
    names: ["Carbamate Derivative 5"],
  },
  {
    primary_id: "D0X9GV",
    names: ["ANA-012"],
  },
  {
    primary_id: "D0X9HB",
    names: ["MKT-077"],
  },
  {
    primary_id: "D0X9HF",
    names: ["ABT-354"],
  },
  {
    primary_id: "D0X9IQ",
    names: ["BND-002"],
  },
  {
    primary_id: "D0X9IS",
    names: ["FGI-102"],
  },
  {
    primary_id: "D0X9JR",
    names: ["QR-313"],
  },
  {
    primary_id: "D0X9KB",
    names: ["ACER-002"],
  },
  {
    primary_id: "D0X9KE",
    names: [
      "A-78773",
      "141579-67-1",
      "A 78773",
      "CHEMBL59598",
      "N-Hydroxy-N-(4-(5-(4-Fluorophenoxy)-2-Furyl)-3-Butyn-2-Yl)Urea",
      "N-(3-(5-(4-Fluorophenoxy)-2-Furanyl)-1-Methyl-2-Propynyl)-N-Hydroxyurea",
      "1-{4-[5-(4-Fluorophenoxy)Furan-2-Yl]but-3-Yn-2-Yl}-1-Hydroxyurea",
      "1-[4-[5-(4-Fluorophenoxy)Furan-2-Yl]but-3-Yn-2-Yl]-1-Hydroxyurea",
      "Urea,N-[3-[5-(4-Fluorophenoxy)-2-Furanyl]-1-Methyl-2-Propyn-1-Yl]-N-Hydroxy-",
      "CCRIS 8531",
    ],
  },
  {
    primary_id: "D0X9KI",
    names: ["Technetium Tc-99m Disofenin Kit"],
  },
  {
    primary_id: "D0X9LC",
    names: ["IC-41"],
  },
  {
    primary_id: "D0X9LJ",
    names: [
      "4-Fluoro-N-(4-Sulfamoylbenzyl)Benzenesulfonamide",
      "CHEMBL511186",
      "4-({[(4-Fluorophenyl)Sulfonyl]amino}methyl)Benzenesulfonamide",
      "4-[[[(4-Fluorophenyl)Sulfonyl]amino]methyl]benzenesulfonamide",
      "BAS 02065178",
      "AC1LG4NC",
      "Cambridge Id 6100956",
      "Oprea1_631950",
      "Oprea1_795607",
      "MolPort-001-510-834",
      "ZINC288074",
      "STK396016",
      "BDBM50247721",
      "AKOS000644386",
      "MCULE-1179145360",
      "ST50706913",
      "AB00102538-01",
      "Z45537946",
      "N-[4-(Aminosulfonyl)Benzyl]-4-Fluoro-1-Benzenesulfonamide",
      "4-[[(4-Fluorophenyl)Sulfonylamino]methyl]benzenesulfonamide",
    ],
  },
  {
    primary_id: "D0X9LK",
    names: ["Iralukast", "CGP-45715A"],
  },
  {
    primary_id: "D0X9MP",
    names: ["Zipeprol"],
  },
  {
    primary_id: "D0X9MW",
    names: ["SCH 44643"],
  },
  {
    primary_id: "D0X9MZ",
    names: ["Cosalane Derivative"],
  },
  {
    primary_id: "D0X9NG",
    names: ["M2del11 H1N1"],
  },
  {
    primary_id: "D0X9OV",
    names: ["2-Amino-3,4',5-Trimethoxy-(Z)-Stillbene", "CHEMBL216433", "SCHEMBL4224804"],
  },
  {
    primary_id: "D0X9PA",
    names: ["Heberkinasa", "Cardiovascular Therapy, York Medical", "Recombinant Streptokinase, York Medical"],
  },
  {
    primary_id: "D0X9PB",
    names: ["HO/04/09"],
  },
  {
    primary_id: "D0X9PF",
    names: ["Bremelanotide", "Bremelanotide (USAN/INN)"],
  },
  {
    primary_id: "D0X9PI",
    names: ["PMID24900699C68"],
  },
  {
    primary_id: "D0X9PW",
    names: [
      "Tesmilifene",
      "DPPE",
      "DPPE Hydrocloride",
      "Tesmilifene Hydrochloride",
      "BMS 217380-01",
      "BMS-217380",
      "BMY-33419",
      "DPPE, BMS",
      "YMB-1002",
      "Anticancer, BMS/Manitoba Univ/MTCR",
    ],
  },
  {
    primary_id: "D0X9QI",
    names: ["MRPE Allergic Rhinitis (Oral), Curalogic"],
  },
  {
    primary_id: "D0X9QV",
    names: ["LY341770"],
  },
  {
    primary_id: "D0X9RA",
    names: ["Piperidine Derivative 5"],
  },
  {
    primary_id: "D0X9RG",
    names: [
      "Butorphanol",
      "Beforal",
      "Butorfanol",
      "Butorphanolum",
      "Moradol",
      "Stadol",
      "Butorphanol Tartrate",
      "BC-2627",
      "Beforal (TN)",
      "Butorfanol [INN-Spanish]",
      "Butorphanolum [INN-Latin]",
      "L-BC 2627",
      "Moradol (TN)",
      "Butorphanol (USAN/INN)",
      "Butorphanol [USAN:BAN:INN]",
      "Levo-BC-2627",
      "(-)-17-(Cyclobutylmethyl)Morphinan-3,14-Diol",
      "(-)-N-Cyclobutylmethyl-3,14-Dihydroxymorphinan",
      "(-)-Butorphanol",
      "17-(CYCLOBUTYLMETHYL)MORPHINAN-3,14-DIOL",
    ],
  },
  {
    primary_id: "D0X9RL",
    names: ["BB-823", "139133-28-1"],
  },
  {
    primary_id: "D0X9RM",
    names: [
      "2-Thiophen-2-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "CHEMBL3144717",
      "1,2-Dihydro-2-(2-Thienylmethyl)-3H-Indazol-3-One",
      "120273-89-4",
      "CHEMBL269658",
      "BDBM50008989",
    ],
  },
  {
    primary_id: "D0X9RN",
    names: ["Robatumumab"],
  },
  {
    primary_id: "D0X9RY",
    names: ["Benzoic Acid"],
  },
  {
    primary_id: "D0X9SP",
    names: ["D-Phe-Cys-Tyr-D-Trp-Lys-Thr-Pen-Thr-NH2(CTP)", "CHEMBL437283"],
  },
  {
    primary_id: "D0X9TF",
    names: ["N,N'-(1',2'-Ethylene)-Bis-(-)-Nor-MEP", "CHEMBL411226"],
  },
  {
    primary_id: "D0X9TG",
    names: ["Diamide"],
  },
  {
    primary_id: "D0X9TM",
    names: ["LPMD"],
  },
  {
    primary_id: "D0X9TT",
    names: ["Tyr-(S)-Spiro-Aba-Gly-Phe-NH2", "CHEMBL401143"],
  },
  {
    primary_id: "D0X9UA",
    names: ["Vatelizumab"],
  },
  {
    primary_id: "D0X9UJ",
    names: ["PD-068235", "CHEMBL455856", "BDBM50266362", "PD-068253"],
  },
  {
    primary_id: "D0X9VH",
    names: ["Azaindole Derivative 1"],
  },
  {
    primary_id: "D0X9VP",
    names: ["Furnidipine"],
  },
  {
    primary_id: "D0X9VR",
    names: ["BGB-324", "BGB-001", "SiRNA Therapeutic (Metastasis Cancer), BiobergenBio"],
  },
  {
    primary_id: "D0X9VS",
    names: ["PMID28270010-Compound-Figure24-A"],
  },
  {
    primary_id: "D0X9VY",
    names: ["Acetic Acid 2-Heptylsulfanyl-Phenyl Ester", "CHEMBL143339", "SCHEMBL6535129", "BDBM50068166"],
  },
  {
    primary_id: "D0X9WC",
    names: ["ISIS-DMPK"],
  },
  {
    primary_id: "D0X9XP",
    names: ["Diosmin", "D3908"],
  },
  {
    primary_id: "D0X9YB",
    names: ["OCV-105", "Cancer Vaccine (Pancreas), Otsuka/OncoTherapy"],
  },
  {
    primary_id: "D0X9YD",
    names: [
      "ALLICIN",
      "Allicin",
      "539-86-6",
      "Diallyl Thiosulfinate",
      "Diallyldisulfid-S-Oxid",
      "Thio-2-Propene-1-Sulfinic Acid S-Allyl Ester",
      "S-Allyl Acrylo-1-Sulphinothioate",
      "Allylthiosulphinic Acid Allyl Ester",
      "DADSO",
      "S-Allyl Prop-2-Ene-1-Sulfinothioate",
      "Diallyl Disulfide-Oxide",
      "S-Allyl 2-Propene-1-Sulfinothioate",
      "C6H10OS2",
      "CCRIS 9053",
      "EINECS 208-727-7",
      "2-Propene-1-Sulfinothioic Acid, S-2-Propenyl Ester",
      "BRN 1752823",
      "3-Prop-2-Enylsulfinylsulfanylprop-1-Ene",
      "CHEMBL359965",
      "CHEBI:28411",
      "3-Allylsulfinylsulfanylprop-1-Ene",
      "2-Propene-1-Sulfinic Acid, Thio-, S-",
    ],
  },
  {
    primary_id: "D0X9YP",
    names: ["AZD7594"],
  },
  {
    primary_id: "D0X9YS",
    names: ["AS-19", "AS 19", "AS19"],
  },
  {
    primary_id: "D0X9ZC",
    names: [
      "Cefadroxil",
      "CDX",
      "Cefadroxilo",
      "Cefadroxilum",
      "Cephadroxil",
      "Sumacef",
      "Cefadroxil Anhydrous",
      "S 578",
      "BL-S 578",
      "BL-S578",
      "Cefadroxil (JP15)",
      "Cefadroxilo [INN-Spanish]",
      "Cefadroxilum [INN-Latin]",
      "Curisafe (TN)",
      "D-Cefadroxil",
      "S-578",
      "Sumacef (TN)",
      "MJF-11567-3",
      "(6R,7R)-7-((R)-2-Amino-2-(P-Hydroxyphenyl)Acetamido)-3-Methyl-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetyl]amino]-3-Methyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetyl]amino}-3-Methyl-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-{[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetyl]amino}-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0XA0N",
    names: ["PMID14640538C3"],
  },
  {
    primary_id: "D0XA1B",
    names: ["RTA-301"],
  },
  {
    primary_id: "D0XA2G",
    names: ["INDUS-860"],
  },
  {
    primary_id: "D0XA3L",
    names: ["Naloxone Buccal Gel"],
  },
  {
    primary_id: "D0XA3W",
    names: ["Calcium-Sensing Receptor Antagonists", "Calcium-Sensing Receptor Antagonists (Osteoporosis), Pfizer"],
  },
  {
    primary_id: "D0XA4U",
    names: ["Ebola Recombinant Viral Vector Vaccine"],
  },
  {
    primary_id: "D0XA5N",
    names: ["NVP-LDE276"],
  },
  {
    primary_id: "D0XB0Q",
    names: ["Dihydropyrrolopyrazole Derivative 1"],
  },
  {
    primary_id: "D0XB0T",
    names: [
      "Acolbifene",
      "EM-652",
      "182167-02-8",
      "UNII-815LJ9X0D1",
      "815LJ9X0D1",
      "EM 652",
      "Acolbifene [INN:BAN]",
      "SCH 57068",
      "AC1L4EAA",
      "SCHEMBL406183",
      "CHEMBL68055",
      "CTK4F5320",
      "SCH-57068",
      "HY-16023A",
      "( )-(2S)-3-(4-Hydroxyphenyl)-4-Methyl-2-(4-(2-(Piperidin-1-Yl)Ethoxy)Phenyl)-2H-1-Benzopyran-7-Ol",
      "CS-0007143",
      "(2S)-3-(4-Hydroxyphenyl)-4-Methyl-2-[4-(2-Piperidin-1-Ylethoxy)Phenyl]-2H-Chromen-7-Ol",
      "(S)-3-(4-Hydroxyphenyl)-4-Methyl-2-(4-(2-(Piperidin-1-Yl)Ethoxy)Phenyl)-2H-Chromen-7-Ol",
    ],
  },
  {
    primary_id: "D0XB1H",
    names: ["Guanosine-5',3'-Tetraphosphate"],
  },
  {
    primary_id: "D0XB1M",
    names: ["MSH-372"],
  },
  {
    primary_id: "D0XB1S",
    names: ["PMID25991433-Compound-H1"],
  },
  {
    primary_id: "D0XB2O",
    names: ["Tricyclic Indole Compound 8"],
  },
  {
    primary_id: "D0XB2W",
    names: ["6-Aryl-Imidazopyridine And 6-Aryl-Triazolopyridine Carboxamide Analog 2"],
  },
  {
    primary_id: "D0XB2Z",
    names: [
      "3-(4-Methoxybenzyloxy)-2-Phenylthiazolidin-4-One",
      "CHEMBL386837",
      "921611-05-4",
      "CTK3H0752",
      "DTXSID80658821",
      "BDBM50198253",
      "AKOS030552092",
      "4-Thiazolidinone, 3-[(4-Methoxyphenyl)Methoxy]-2-Phenyl-",
    ],
  },
  {
    primary_id: "D0XB3U",
    names: [
      "BM-573",
      "UNII-8E9L957C3G",
      "CHEMBL210602",
      "8E9L957C3G",
      "AC1NSM3C",
      "SCHEMBL3393325",
      "BM 573",
      "BDBM50188619",
      "NSC732094",
      "NSC-732094",
      "284464-83-1",
      "Benzenesulfonamide, N-(((1,1-Dimethylethyl)Amino)Carbonyl)-2-((4-Methylphenyl)Amino)-5-Nitro-",
      "LS-191119",
      "1-(2-(P-Toluidino)-5-Nitrophenylsulfonyl)-3-Tert-Butylurea",
      "1-Tert-Butyl-3-[2-(4-Methylanilino)-5-Nitrophenyl]sulfonylurea",
      "N-Tert-Butyl-N''-[2-(4-Methylphenylamino)-5-Nitrobenzenesulfonyl]urea",
      "N-Tertbutyl-N'-[2-(4'-Methylphenylamino)-5-Nitrobenzenesulfo Nyl]urea",
    ],
  },
  {
    primary_id: "D0XB4D",
    names: ["NTC-100"],
  },
  {
    primary_id: "D0XB4P",
    names: [
      "HTS-466284",
      "4-(3-Pyridin-2-YL-1H-Pyrazol-4-YL)Quinoline",
      "396129-53-6",
      "LY-364947",
      "LY364947",
      "4-(3-(Pyridin-2-Yl)-1H-Pyrazol-4-Yl)Quinoline",
      "4-(3-PYRIDIN-2-YL-1H-PYRAZOL-4-YL)QUINOLINE",
      "TbetaR-I Inhibitor",
      "LY 364947",
      "ALK5 Inhibitor I",
      "TGF-Beta RI Kinase Inhibitor",
      "4-[3-(Pyridin-2-Yl)-1H-Pyrazol-4-Yl]quinoline",
      "CHEMBL261454",
      "Transforming Growth Factor-Beta Type I Receptor Kinase Inhibitor",
      "4-(3-Pyridin-2-Yl)(1h)-Pyrazol-4-Yl Quinoline",
      "4-[3-(2-Pyridinyl)-1H-Pyrazol-4-Yl]quinoline",
      "[3-(Pyridin-2-Yl)-4-(4-Quinonyl)]-1H-Pyrazole",
      "4-(3-Pyridin-2-Yl-1h-Pyrazol-4-Yl)Quinoline",
    ],
  },
  {
    primary_id: "D0XB7H",
    names: ["PNU177836"],
  },
  {
    primary_id: "D0XB8P",
    names: ["Betaine", "Cystadane"],
  },
  {
    primary_id: "D0XB8Y",
    names: ["COL-195"],
  },
  {
    primary_id: "D0XC1F",
    names: ["CX-9051"],
  },
  {
    primary_id: "D0XC2D",
    names: ["Carboxamide Derivative 5"],
  },
  {
    primary_id: "D0XC3V",
    names: ["4-[1-(4-Hydroxyphenyl)-2-Phenylhex-1-Enyl]phenol", "CHEMBL356693"],
  },
  {
    primary_id: "D0XC6G",
    names: [
      "2-(1H-Indazol-3-Yl)-1H-Benzo[d]imidazole",
      "3-(1H-BENZIMIDAZOL-2-YL)-1H-INDAZOLE",
      "CHEMBL383990",
      "3-(1H-1,3-Benzodiazol-2-Yl)-2H-Indazole",
      "2-(2H-Indazol-3-Yl)-1H-1,3-Benzodiazole",
      "IDZ",
      "1097816-83-5",
      "Indazole Compound 2",
      "2c3l",
      "AC1Q4XAS",
      "AC1NS1P1",
      "SCHEMBL311148",
      "BDBM16590",
      "3-Benzimidazol-2-Yl-1h-Indazole",
      "JTKFRFMSUBOCIQ-UHFFFAOYSA-N",
      "MolPort-016-542-829",
      "ZINC14961821",
      "AKOS009356321",
      "NE25942",
      "MCULE-6782886882",
      "DB07959",
      "2-(1H-Indazol-3-Yl)-1H-Benzimidazole",
      "3-(1H-Benzoimidazol-2-Yl)-1H-Indazole",
      "2-(1H-Indazole-3-Yl)-1H-Benzoimidazole",
      "EN300-70692",
    ],
  },
  {
    primary_id: "D0XC9U",
    names: ["MRS1042"],
  },
  {
    primary_id: "D0XD0N",
    names: ["REL-1028"],
  },
  {
    primary_id: "D0XD1Q",
    names: ["6-(4-Fluoro-Benzenesulfonyl)-2H-Pyridazin-3-One"],
  },
  {
    primary_id: "D0XD4I",
    names: [
      "AZD0156",
      "AOTRIQLYUAFVSC-UHFFFAOYSA-N",
      "1821428-35-6",
      "AZD-0156",
      "UNII-P5T0XWC07Z",
      "P5T0XWC07Z",
      "GTPL9942",
      "CHEMBL3960662",
      "SCHEMBL17246146",
      "AZD 0156 [WHO-DD]",
      "MolPort-044-560-374",
      "BDBM245474",
      "BCP18990",
      "EX-A1321",
      "S8375",
      "ZINC498035578",
      "AKOS030629510",
      "CS-5889",
      "SB19769",
      "AZD0156 (AZD-0156)",
      "AS-35329",
      "Compound 64  [PMID: 29683659]",
      "HY-100016",
      "US9428503, 1",
      "8-[6-[3-(Dimethylamino)Propoxy]pyridin-3-Yl]-3-Methyl-1-(Oxan-4-Yl)Imidazo[4,5-C]quinolin-2-One",
      "8-(6-(3-(Dimethylamino)Propoxy)Pyridin-3-Yl)-3-Methyl-1-(Tetrahydro-2H-Pyran-4-Yl)-1H-Imidazo[",
    ],
  },
  {
    primary_id: "D0XD4N",
    names: ["ERB-002"],
  },
  {
    primary_id: "D0XD5A",
    names: ["E-101"],
  },
  {
    primary_id: "D0XD5N",
    names: ["LY3202626"],
  },
  {
    primary_id: "D0XD7V",
    names: ["FLX475"],
  },
  {
    primary_id: "D0XE0U",
    names: ["14C-Urea"],
  },
  {
    primary_id: "D0XE0X",
    names: [
      "Aplindore Fumarate",
      "Aplindore",
      "Aplindore Fumarate (USAN)",
      "WAY-DAB-452",
      "(2S)-2-[(Benzylamino)Methyl]-2,3,7,9-Tetrahydro-[1,4]dioxino[2,3-E]indol-8-One",
      "8H-1,4-Dioxino(2,3-E)Indol-8-One,2,3,7,9-Tetrahydro-2-(((Phenylmethyl)Amino)Methyl)-,2(S)-, (2E)-2-Butenediotate (1:1)",
    ],
  },
  {
    primary_id: "D0XE1C",
    names: ["Regadenoson"],
  },
  {
    primary_id: "D0XE1I",
    names: ["AGN 211334", "AGN-211334", "AGN211334"],
  },
  {
    primary_id: "D0XE2O",
    names: ["BIIB063"],
  },
  {
    primary_id: "D0XE4B",
    names: ["4-(4-Methoxy-Benzenesulfonyl)-Butane-2-Thiol", "CHEMBL172912", "SCHEMBL7212305"],
  },
  {
    primary_id: "D0XE4G",
    names: ["Mesenchymal Stromal Cells Secreting Neurotrophic Factors"],
  },
  {
    primary_id: "D0XE4W",
    names: ["Actimab-M", "Actinium-225", "Actinium, Isotope Of Mass 225", "225Ac", "AC1L4Z7V", "14265-85-1"],
  },
  {
    primary_id: "D0XE5L",
    names: ["Rispenzepine"],
  },
  {
    primary_id: "D0XE5M",
    names: ["SAR247799"],
  },
  {
    primary_id: "D0XE8H",
    names: ["Von Willebrand Factor"],
  },
  {
    primary_id: "D0XE8T",
    names: ["Anthoptilide C", "CHEMBL496433"],
  },
  {
    primary_id: "D0XF0A",
    names: ["N-Formyl-Methionyl-Leucyl-Phenylalanine"],
  },
  {
    primary_id: "D0XF4B",
    names: ["RIGS CC49"],
  },
  {
    primary_id: "D0XF5P",
    names: ["(R)-3-(Naphthalen-2-Ylmethoxy)Pyrrolidine", "CHEMBL469809"],
  },
  {
    primary_id: "D0XF5X",
    names: ["3-Amino-3-(Methoxycarbonyl)-1,5-Pentandioic Acid", "CHEMBL541497"],
  },
  {
    primary_id: "D0XF6C",
    names: [
      "2-Phenyl-1H-Imidazole-4-Carboxylic Acid",
      "77498-98-7",
      "2-Phenyl-1H-Imidazole-5-Carboxylic Acid",
      "1H-Imidazole-5-Carboxylic Acid, 2-Phenyl-",
      "CHEMBL1232889",
      "1H-Imidazole-4-Carboxylic Acid, 2-Phenyl-",
      "UNII-HGH57KDY8T",
      "HGH57KDY8T",
      "2-Phenylimidazole-4-Carboxylic Acid",
      "G14",
      "2-Phenyl-3H-Imidazole-4-Carboxylic Acid",
      "AC1MC8EL",
      "SCHEMBL1080850",
      "CTK4I4538",
      "CTK2I0991",
      "XCAXKZJNJCKTQH-UHFFFAOYSA-N",
      "MolPort-000-146-264",
      "HMS3604D08",
      "ZINC164985",
      "CS-D0591",
      "ALBB-012261",
      "CP-011",
      "BDBM50329857",
      "STK897779",
      "ANW-57703",
      "AKOS007930827",
      "AKOS005173647",
      "NE61009",
    ],
  },
  {
    primary_id: "D0XF7N",
    names: ["TCI15"],
  },
  {
    primary_id: "D0XF8W",
    names: [
      "Pyrazinamide",
      "Aldinamid",
      "Aldinamide",
      "Braccopiral",
      "Corsazinmid",
      "Dipimide",
      "Eprazin",
      "Farmizina",
      "Isopas",
      "Lynamide",
      "Novamid",
      "Pezetamid",
      "Pharozinamide",
      "Piraldina",
      "Pirazimida",
      "Pirazinamid",
      "Pirazinamida",
      "Pirazinamide",
      "Prazina",
      "Pyrafat",
      "Pyramide",
      "Pyrazide",
      "Pyrazinamdie",
      "Pyrazinamidum",
      "Pyrazineamide",
      "Pyrazinecarboxamide",
      "Rozide",
      "Tebrazid",
      "Tebrazio",
      "Tisamid",
      "Unipyranamide",
      "Zinamide",
      "Zinastat",
      "P Ezetamid",
      "Pirazinamide [DCIT]",
      "Pyrazine Carboxamide",
      "Pyrazine Carboxylamide",
      "Pyrazinecarboxylic Acid Amide",
      "Pyrazinoic Acid Am Ide",
      "Pyrazinoic Acid Amide",
      "DRG 0124",
      "MK 56",
      "P 7136",
      "Pyrazinamide BP 2000",
      "T 165",
      "AZT + Pyrazinamide Combination",
      "D-50",
      "Pirazinamida [INN-Spanish]",
      "Pms-Pyrazinamide",
      "Pyrazinamide (TN)",
      "Pyrazinamidum [INN-Latin]",
      "D-50 (VAN)",
      "Pyrazinamide [INN:BAN:JAN]",
      "Pyrazine-2-Carboxamide",
      "Pyrazinamide (JP15/USP/INN)",
      "Pyrazinoic Acid Amide, Pezetamid, Pyrafat, Zinamide, Tebrazid, Pyrafat, Pyrazinamide",
      "2-Carbamylpyrazine",
      "2-Carbamyl Pyrazine",
    ],
  },
  {
    primary_id: "D0XF9F",
    names: ["Methyl-Piperidino-Pyrazole"],
  },
  {
    primary_id: "D0XG0G",
    names: ["1-Adamantan-1-Yl-3-Decyl-Urea", "CHEMBL69663", "SCHEMBL4445372"],
  },
  {
    primary_id: "D0XG0L",
    names: ["Anti-CD19 CAR T Cells"],
  },
  {
    primary_id: "D0XG0Z",
    names: ["A-82186"],
  },
  {
    primary_id: "D0XG3Y",
    names: ["TZP-102"],
  },
  {
    primary_id: "D0XG4K",
    names: ["KI-0804"],
  },
  {
    primary_id: "D0XG6U",
    names: ["LSP1-2111"],
  },
  {
    primary_id: "D0XG7H",
    names: ["IB-09A149"],
  },
  {
    primary_id: "D0XG7K",
    names: ["PMID27215781-Compound-29"],
  },
  {
    primary_id: "D0XG8H",
    names: ["PMID29338548-Compound-49"],
  },
  {
    primary_id: "D0XG8I",
    names: ["PSI-879"],
  },
  {
    primary_id: "D0XH0I",
    names: ["PMID27109571-Compound-38"],
  },
  {
    primary_id: "D0XH0K",
    names: [
      "Mitemcinal Fumarate",
      "(2S,4R,5R,8R,9S,10S,11R,12R)-5-Ethyl-9-[(2R,4R,5S,6S)-5-Hydroxy-4-Methoxy-4,6-Dimethyloxan-2-Yl]oxy-11-[(2S,3R,4S,6R)-3-Hydroxy-6-Methyl-4-[methyl(Propan-2-Yl)Amino]oxan-2-Yl]oxy-4-Methoxy-2,4,8,10,12,14-Hexamethyl-6,15-Dioxabicyclo[10.2.1]pentadec-1(14)-Ene-3,7-Dione",
    ],
  },
  {
    primary_id: "D0XH1F",
    names: ["4-Fluorophenyl 4-Butoxybenzylcarbamate", "CHEMBL607558", "SCHEMBL5216504"],
  },
  {
    primary_id: "D0XH1H",
    names: ["DBO-17"],
  },
  {
    primary_id: "D0XH1N",
    names: ["ICA-105574"],
  },
  {
    primary_id: "D0XH1P",
    names: ["CRx-102"],
  },
  {
    primary_id: "D0XH2N",
    names: ["RS-1748"],
  },
  {
    primary_id: "D0XH3V",
    names: ["Inebilizumab"],
  },
  {
    primary_id: "D0XH4K",
    names: ["C-Linked Disaccharide Biphenyl Mannoside Derivative 3"],
  },
  {
    primary_id: "D0XH5O",
    names: ["Imidazo Bicyclic Iminium Derivative 7"],
  },
  {
    primary_id: "D0XH6P",
    names: ["Encepur (New Formulation)"],
  },
  {
    primary_id: "D0XH7H",
    names: ["N-Methylmethanesulfonamide Derivative 1"],
  },
  {
    primary_id: "D0XH8X",
    names: ["APC-8015F", "CD54-Postive Autologous Cell Immunotherapy (Hormone Refractory Prostate Cancer), Dendreon"],
  },
  {
    primary_id: "D0XH9B",
    names: ["Tabimorelin"],
  },
  {
    primary_id: "D0XH9K",
    names: ["PMID25991433-Compound-E4"],
  },
  {
    primary_id: "D0XH9M",
    names: ["N-(2-Phenylcyclopropyl) Amino Acid Derivative 2"],
  },
  {
    primary_id: "D0XI1B",
    names: ["NBI-18"],
  },
  {
    primary_id: "D0XI1P",
    names: ["Ketoprofen Topical"],
  },
  {
    primary_id: "D0XI2Z",
    names: ["PPA"],
  },
  {
    primary_id: "D0XI3E",
    names: ["INCB01158"],
  },
  {
    primary_id: "D0XI3W",
    names: ["OICR-9429"],
  },
  {
    primary_id: "D0XI4P",
    names: ["BMS-932481"],
  },
  {
    primary_id: "D0XI4U",
    names: ["6-Methoxy-2-Methyl-9H-Beta-Carbolin-2-Ium", "CHEMBL376434", "AC1NSY4Y", "BDBM50194438"],
  },
  {
    primary_id: "D0XI9O",
    names: ["RWJ-46458"],
  },
  {
    primary_id: "D0XJ0B",
    names: ["Enzoxazolinone Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D0XJ0P",
    names: [
      "BCX-140",
      "4-(Acetylamino)-3-Guanidinobenzoic Acid",
      "BANA-113",
      "UNII-V02KF5948M",
      "BANA 113",
      "CHEMBL55440",
      "170447-93-5",
      "V02KF5948M",
      "Benzoic Acid, 4-(Acetylamino)-3-((Aminoiminomethyl)Amino)-",
      "4-Acetamido-3-Guanidino-Benzoic Acid",
      "Benzoic Acid, 4-(Acetylamino)-3-[(Aminoiminomethyl)Amino]-",
      "1inf",
      "AC1L9JI9",
      "4-Acetamido-3-(Diaminomethylideneamino)Benzoic Acid",
      "SCHEMBL141675",
      "BDBM4707",
      "UDQJOWCVSMIZJP-UHFFFAOYSA-N",
      "4-Acetylamino-3-Guanidino-Benzoic Acid",
      "3-Carbamimidamido-4-Acetamidobenzoic Acid",
      "3-(2,2-Diaminoimino)-4-Methylcarboxamidobenzoate",
      "BANA-153",
      "BANA-205",
      "BANA-206",
      "Neuraminidase Inhibitors, BioCryst",
    ],
  },
  {
    primary_id: "D0XJ0S",
    names: [
      "PD-134308",
      "CI-988",
      "130332-27-3",
      "CI 988",
      "UNII-2637PDX9SI",
      "2637PDX9SI",
      "CHEMBL287735",
      "CHEMBL2062154",
      "PD 134308",
      "4-(((R)-2-((R)-2-(((Adamantan-2-Yloxy)Carbonyl)Amino)-3-(1H-Indol-3-Yl)-2-Methylpropanamido)-1-Phenylethyl)Amino)-4-Oxobutanoic Acid",
      "4-[[(1R)-2-[[(2R)-3-(1H-Indol-3-Yl)-2-Methyl-1-Oxo-2-[[(Tricyclo[3.3.1.13,7]dec-2-Yloxy)Carbonyl]amino]propyl]amino]-1-Phenylethyl]amino]-4-Oxobutanoic Acid",
    ],
  },
  {
    primary_id: "D0XJ1C",
    names: [
      "5-Ethyl-4-Methyl-Pyrrolidin-(2Z)-Ylideneamine",
      "CHEMBL185024",
      "CHEMBL552935",
      "190909-71-8",
      "SCHEMBL7624096",
      "BDBM50066778",
      "AKOS027401526",
      "AKOS006376472",
      "DA-08771",
      "FT-0744003",
      "2-Ethyl-3,4-Dihydro-3-Methyl-2H-Pyrrol-5-Amine",
      "5-Ethyl-4-Methyl-Pyrrolidin-(2E)-Ylideneamine",
    ],
  },
  {
    primary_id: "D0XJ3S",
    names: ["PMID25514969-Compound-Figure2-2"],
  },
  {
    primary_id: "D0XJ4G",
    names: ["PF-3049423"],
  },
  {
    primary_id: "D0XJ4J",
    names: ["1-Aryl-4-Methyl-[1,2,4]triazolo[4,3-A]quinoxaline Derivative 2"],
  },
  {
    primary_id: "D0XJ5I",
    names: ["DNA-C"],
  },
  {
    primary_id: "D0XJ6C",
    names: [
      "DIAPLASININ",
      "Diaplasinin < USAN",
      "PAI-749",
      "1-Benzyl-3-Pentyl-2-[6-(1H-Tetrazol-5-Ylmethoxy)Naphthalen-2-Yl]-1H-Indole",
    ],
  },
  {
    primary_id: "D0XJ6D",
    names: [
      "4-(3-Methyl-Indole-1-Sulfonyl)-Phenylamine",
      "CHEMBL328816",
      "SCHEMBL14642142",
      "BDBM34150",
      "N1-(4-Aminobenzenesulfonyl)Skatole",
      "N-Arenesulfonylindole Antagonist, 9",
      "4-(3-Methyl-1H-Indole-1-Sulfonyl)Aniline, 8",
    ],
  },
  {
    primary_id: "D0XJ7I",
    names: ["Purpurin"],
  },
  {
    primary_id: "D0XJ8K",
    names: ["Recombinant Soluble Human CD5"],
  },
  {
    primary_id: "D0XJ9P",
    names: [
      "Phosphoric Acid Monododecyl Ester",
      "Dodecyl Dihydrogen Phosphate",
      "Monolauryl Phosphate",
      "Phosphoric Acid, Monododecyl Ester",
      "Monododecyl Phosphate",
      "2627-35-2",
      "Dihydrogen Monolauryl Phosphate",
      "Lauryl Dihydrogen Phosphate",
      "Mono-N-Dodecyl Phosphate",
      "12751-23-4",
      "UNII-0K08L8GE2H",
      "CHEMBL187711",
      "0K08L8GE2H",
      "TVACALAUIQMRDF-UHFFFAOYSA-N",
      "EINECS 220-095-4",
      "NSC 41919",
      "Dodecylphosphoric Acid",
      "Mono-Lauryl Phosphate",
      "Mono-Dodecyl Phosphate",
      "Momo-Dodecyl Phosphate",
      "Dodecyl Phosphoric Acid",
    ],
  },
  {
    primary_id: "D0XJ9U",
    names: ["BMS-986242"],
  },
  {
    primary_id: "D0XK0T",
    names: [
      "WAY 100135",
      "CHEMBL38288",
      "(2S)-N-Tert-Butyl-3-[4-(2-Methoxyphenyl)Piperazin-1-Yl]-2-Phenylpropanamide",
      "Tocris-1253",
      "AC1O7GY4",
      "GTPL126",
      "SCHEMBL4087689",
      "BDBM82369",
      "ZINC52957106",
      "BDBM50047471",
      "NCGC00025081-01",
      "AJ-112145",
      "CAS_133025-23-7",
      "(S)-N-T-Butyl-3-(4-(2-Methoxyphenyl)-Piperazin-1-Yl)-2-Phenylpropanamide",
      "(S)-N-Tert-Butyl-2-Phenyl-3-[4-(2-Methoxyphenyl)Piperazino]propanamide",
    ],
  },
  {
    primary_id: "D0XK1F",
    names: ["S-16961"],
  },
  {
    primary_id: "D0XK2K",
    names: ["Siramesine"],
  },
  {
    primary_id: "D0XK2N",
    names: ["Sinbaro"],
  },
  {
    primary_id: "D0XK4R",
    names: [
      "EFLETIRIZINE",
      "Efletirizine < Rec INN",
      "Ucb-28754",
      "2-[2-[4-[Bis(4-Fluorophenyl)Methyl]piperazin-1-Yl]ethoxy]acetic Acid",
    ],
  },
  {
    primary_id: "D0XK6O",
    names: ["SMaRT FVIII"],
  },
  {
    primary_id: "D0XK6P",
    names: ["HS-210"],
  },
  {
    primary_id: "D0XK7G",
    names: [
      "N-Hydroxy-2-(4-Methoxyphenylsulfonamido)Acetamide",
      "CHEMBL83508",
      "N-Hydroxy-N~2~-[(4-Methoxyphenyl)Sulfonyl]glycinamide",
      "Hydroxamate 9",
      "Z79",
      "3lk8",
      "SCHEMBL7410575",
      "BDBM11328",
      "(4-Methoxyphenylsulfonylamino)Acetohydroximic Acid",
    ],
  },
  {
    primary_id: "D0XK7H",
    names: ["PMID26004420-Compound-US20140336177E"],
  },
  {
    primary_id: "D0XL0E",
    names: ["NVX-272"],
  },
  {
    primary_id: "D0XL0H",
    names: ["[D-Gln3,D-Ncy(SO2,isopropyl)7]acyline", "CHEMBL373725"],
  },
  {
    primary_id: "D0XL1M",
    names: ["6,6-Fused Nitrogenous Heterocyclic Compound 3"],
  },
  {
    primary_id: "D0XL1R",
    names: [
      "ETHYLPHENSERINE",
      "2-Ethylphenserine.tartaric Acid",
      "Homotolserine",
      "2-Ethylphenserine",
      "Eseroline 2-Ethylphenylcarbamate",
      "CHEMBL340391",
      "BDBM10961",
      "(3aS,8aR)-1,3a,8-Trimethyl-1H,2H,3H,3aH,8H,8aH-Pyrrolo[2,3-B]indol-5-Yl N-(2-Ethylphenyl)Carbamate",
    ],
  },
  {
    primary_id: "D0XL1T",
    names: ["PDT With Photofrin"],
  },
  {
    primary_id: "D0XL2V",
    names: ["Trepenoid Derivative 1"],
  },
  {
    primary_id: "D0XL3N",
    names: ["PMID30247903-Compound-General Structure10"],
  },
  {
    primary_id: "D0XL4I",
    names: ["ER-319711-15"],
  },
  {
    primary_id: "D0XL5D",
    names: ["5-Methoxy-N-Phenylbenzo[d]oxazol-2-Amine", "CHEMBL1269705"],
  },
  {
    primary_id: "D0XL5K",
    names: [
      "Benzothiazole-2-Sulfonic Acid Amide",
      "2-Benzothiazolesulfonamide",
      "433-17-0",
      "Benzo[d]thiazole-2-Sulfonamide",
      "1,3-Benzothiazole-2-Sulfonamide",
      "BENZOTHIAZOLE-2-SULFONAMIDE",
      "CHEMBL168552",
      "CHEBI:34262",
      "2-BTS",
      "6-Hydrogen-2-Benzothiazolesulfonamide",
      "AC1Q1HM9",
      "AC1L28OQ",
      "SCHEMBL81178",
      "AC1Q55G4",
      "CTK1D5756",
      "DTXSID30195812",
      "MolPort-006-728-089",
      "SDYMYAFSQACTQP-UHFFFAOYSA-N",
      "3s73",
      "ZINC1847418",
      "ANW-70718",
      "5987AB",
      "BDBM50160671",
      "AKOS010998801",
      "MCULE-9059575102",
      "EN001332",
      "AM100818",
      "TC-159463",
      "KB-142553",
      "EN300-60766",
      "C14181",
    ],
  },
  {
    primary_id: "D0XL6C",
    names: [
      "SB-M00026",
      "Chronic Hepatitis B Pharmaccine, GlaxoSmithKline",
      "Chronic Hepatitis B Therapeutic Vaccine, SmithKline Beecham",
      "HBV (Chronic) Therapeutic Vaccine, GlaxoSmithKline",
      "Pharmaccine (Chronic HBV), GlaxoSmithKline",
      "Therapeutic Vaccine (Chronic HBV), SmithKline Beecham",
    ],
  },
  {
    primary_id: "D0XL6P",
    names: ["2-Morpholino-6-(Naphthalen-1-Yl)-4H-Chromen-4-One", "CHEMBL197475"],
  },
  {
    primary_id: "D0XL7C",
    names: [
      "4-Pyridin-2-Yl-5-Quinolin-4-Yl-Thiazol-2-Ylamine",
      "CHEMBL181640",
      "SCHEMBL410002",
      "UPYLCJSMNYCTLZ-UHFFFAOYSA-N",
      "BDBM50151348",
      "4-(Pyridin-2-Yl)-5-(Quinolin-4-Yl)Thiazol-2-Amine",
    ],
  },
  {
    primary_id: "D0XL7R",
    names: ["GLP-17-(7-36) Derivative"],
  },
  {
    primary_id: "D0XL8C",
    names: ["5-Oxo-ODE", "5-Oxo-(6E,8Z)-Octadecadienoic Acid"],
  },
  {
    primary_id: "D0XL8K",
    names: ["Ro-37-9790"],
  },
  {
    primary_id: "D0XL8W",
    names: ["ASN003"],
  },
  {
    primary_id: "D0XL9J",
    names: ["Ro-11-04253", "CHEMBL360688", "Ro-1104253", "SCHEMBL7821569", "BDBM50160164"],
  },
  {
    primary_id: "D0XL9W",
    names: ["CGS-7181", "200345-93-3", "CGS-7184", "CGS-7590", "CGS-7725"],
  },
  {
    primary_id: "D0XM1A",
    names: ["SAFINAMIDE MESYLATE"],
  },
  {
    primary_id: "D0XM1U",
    names: ["PMID21273063C36"],
  },
  {
    primary_id: "D0XM3S",
    names: ["CX-1501", "AMPA Receptor Modulators (ADHD, AD, Sleep Disorders), Cortex Pharmaceuticals"],
  },
  {
    primary_id: "D0XM3W",
    names: ["HRA-072033"],
  },
  {
    primary_id: "D0XM4G",
    names: [
      "Bindarit",
      "Bindarit, Angelini Pharmaceuticals",
      "CCL2 Inhibitor (Inflammatory Disorders/Kidney Disease), Angelini Pharmaceuticals",
      "MCP-1 Inhibitor (Inflammatory Disorder/Kidney Disease), Angelini Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0XM6L",
    names: ["OT-010"],
  },
  {
    primary_id: "D0XM8B",
    names: ["PMID26924192-Compound-21"],
  },
  {
    primary_id: "D0XM8F",
    names: ["PMID26004420-Compound-WO2012058134B"],
  },
  {
    primary_id: "D0XM9E",
    names: ["Human Plasminogen"],
  },
  {
    primary_id: "D0XM9M",
    names: ["Methyl-Piperidine Compound 1"],
  },
  {
    primary_id: "D0XM9Y",
    names: ["Pinazepam"],
  },
  {
    primary_id: "D0XN1F",
    names: [
      "Nimodipine",
      "Admon",
      "Brainal",
      "Calnit",
      "Kenesil",
      "Modus",
      "NimoGel",
      "NimodipinISIS",
      "Nimodipino",
      "Nimodipinum",
      "Nimotop",
      "Periplum",
      "Remontal",
      "Almirall Brand Of Nimodipine",
      "Alpharma Brand Of Nimodipine",
      "Andromaco Brand Of Nimodipine",
      "Bayer Brand Of Nimodipine",
      "Bayvit Brand Of Nimodipine",
      "Cantabria Brand Of Nimodipine",
      "Elan Brand Of Nimodipine",
      "Esteve Brand Of Nimodipine",
      "Hexal Brand Of Nimodipine",
      "Nimodipin Hexal",
      "Nimodipin ISIS",
      "Nimodipino Bayvit",
      "Vita Brand Of Nimodipine",
      "Bay-E 9736",
      "DRG-0139",
      "EG-1961",
      "N-149",
      "NIMOTOP (TN)",
      "Nimodipin-ISIS",
      "Nimodipino [INN-Spanish]",
      "Nimodipinum [INN-Latin]",
      "Nimotop (TN)",
      "Nimotop(TM)",
      "Nimotop, Nimodipine",
      "Bay-E-9736",
      "Nimodipine (USAN/INN)",
      "Nimodipine [USAN:BAN:INN]",
      "Periplum, Nimotop, BRN 0459792, DRG-0139",
      "Isopropyl 2-Methoxyethyl 1,4-Dihydro-2,6-Dimethyl-4-(M-Nitrophenyl)-3,5-Pyridinedicarboxylate",
      "1,4-Dihydro-2,6-Dimethyl-4-(3&prime;-Nitrophenyl)-3,5-Pyridinedicarboxylic Acid 2-Methoxyethyl-1-Methylethyl Ester",
      "1,4-Dihydro-2,6-Dimethyl-4-(3-Nitrophenyl)-3,5-Pyridinedicarboxylic Acid",
      "1,4-Dihydro-2,6-Dimethyl-4-(3-Nitrophenyl)-3,5-Pyridinedicarboxylic Acid 2-Methoxyethyl 1-Methylethyl Ester;1-Methylethyl 2-(Methyloxy)Ethyl 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "2-Methoxyethyl-1-Methylethyl Ester",
      "2-Methoxyethyl Propan-2-Yl 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(3-Nitrophenyl)-, 2-Methoxyethyl 1-Methylethyl Ester",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(3-Nitrophenyl)-,2-Methoxyethyl 1-Methylethyl Ester",
      "3,5-Pyridinecarboxylic Acid 2-Methoxyethyl 1-Methylethyl Ester",
      "3,5-Pyridinedicarboxylic Acid, 1,4-Dihydro-2,6-Dimethyl-4-(3-Nitrophenyl), 2-Methoxyethyl 1-Methylethyl Ester",
      "3-(2-Methoxyethyl) 5-Propan-2-Yl 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "3-O-(2-Methoxyethyl) 5-O-Propan-2-Yl 2,6-Dimethyl-4-(3-Nitrophenyl)-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "4-[4-(4-Chlorophenyl)-4-Hydroxy-1-Piperidyl]-N,N-Dimethyl-2,2-Diphenyl-Butanamide",
    ],
  },
  {
    primary_id: "D0XN1T",
    names: ["PMID8709131C23"],
  },
  {
    primary_id: "D0XN2L",
    names: ["M-119"],
  },
  {
    primary_id: "D0XN2W",
    names: ["Diaryl Amine Derivative 4"],
  },
  {
    primary_id: "D0XN3O",
    names: ["PMID25482888-Compound-38"],
  },
  {
    primary_id: "D0XN4W",
    names: ["2-(3,4-Dimethylphenyl)-1,8-Naphthyridine", "CHEMBL235398"],
  },
  {
    primary_id: "D0XN5L",
    names: [
      "Albutropin",
      "Albumin-Based Fusion Protein (Growth Deficiency), Teva Pharmaceutical",
      "Recombinant Human Growth Hormone (HGH, Long Acting),Human Genome Sciences",
      "Albumin-Based Fusion Protein (Growth Deficiency), Principia (HGS)",
      "Recombinant Human Growth Hormone (HGH, Long-Acting), Teva Pharmaceutical",
    ],
  },
  {
    primary_id: "D0XN8A",
    names: ["INFRACTOPICRIN"],
  },
  {
    primary_id: "D0XN8C",
    names: ["Rosaprostol"],
  },
  {
    primary_id: "D0XN8W",
    names: ["LB-101"],
  },
  {
    primary_id: "D0XO0T",
    names: ["ISIS 103375"],
  },
  {
    primary_id: "D0XO1O",
    names: ["CC8464", "ASP1807"],
  },
  {
    primary_id: "D0XO1T",
    names: ["[Sar1,Bpa8]AngII", "CHEMBL1076632"],
  },
  {
    primary_id: "D0XO2E",
    names: ["HI-236"],
  },
  {
    primary_id: "D0XO2G",
    names: ["AMG 9810", "AMG-9810", "AMG9810"],
  },
  {
    primary_id: "D0XO3E",
    names: ["Gene Therapy, Neurodegenerative Diseases"],
  },
  {
    primary_id: "D0XO4I",
    names: ["3-(Phenoxymethyl) Benzylamine Derivative 3"],
  },
  {
    primary_id: "D0XO4K",
    names: ["TA-HPV"],
  },
  {
    primary_id: "D0XO7I",
    names: ["PF-06753512"],
  },
  {
    primary_id: "D0XO7J",
    names: ["Isoamylamine"],
  },
  {
    primary_id: "D0XO7Q",
    names: ["Alequel", "Antigens (Colitis), Enzo", "Antigen-Specific Immune Regulation Therapy (Crohn`s), Enzo"],
  },
  {
    primary_id: "D0XO8D",
    names: ["PJJ-34"],
  },
  {
    primary_id: "D0XO9F",
    names: ["FS118"],
  },
  {
    primary_id: "D0XP0J",
    names: ["Dimethyl 2,2,2-Trifluoro-1-Phenylethyl Phosphate", "CHEMBL575281"],
  },
  {
    primary_id: "D0XP0Q",
    names: ["FCE-24265"],
  },
  {
    primary_id: "D0XP3A",
    names: ["BFPT-16864", "BFPT-6864", "BRFD-104"],
  },
  {
    primary_id: "D0XP3W",
    names: ["Ro-24-7429"],
  },
  {
    primary_id: "D0XP4A",
    names: ["Labetuzumab I-131"],
  },
  {
    primary_id: "D0XP5P",
    names: ["LY063518"],
  },
  {
    primary_id: "D0XP5X",
    names: ["Mannoside Derivative 5"],
  },
  {
    primary_id: "D0XP7T",
    names: ["NEOD001"],
  },
  {
    primary_id: "D0XP7V",
    names: ["TWIN"],
  },
  {
    primary_id: "D0XQ0Q",
    names: [
      "Cefsulodin",
      "(6R,7R)-3-{[4-(Aminocarbonyl)Pyridinium-1-Yl]methyl}-8-Oxo-7-{[phenyl(Sulfo)Acetyl]amino}-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylate",
      "AC1NR4MP",
      "SCHEMBL65524",
      "DTXSID6022769",
      "Cefsulodin Free Acid (SCE-129), Antibiotic For Culture Media Use Only",
    ],
  },
  {
    primary_id: "D0XQ0V",
    names: ["DC-9703"],
  },
  {
    primary_id: "D0XQ1W",
    names: ["SDZ-62-434", "5-[4-(Piperidinomethyl)Phenyl]-2,3-Dihydroimidazo[2,1-A]isoquinoline Dihydrochloride"],
  },
  {
    primary_id: "D0XQ2T",
    names: ["Drug 311440", "311440"],
  },
  {
    primary_id: "D0XQ2X",
    names: ["Calcium Chloride", "Calcium Chloride 10% In Plastic Container"],
  },
  {
    primary_id: "D0XQ3F",
    names: ["Peptidomimetic Analog 5"],
  },
  {
    primary_id: "D0XQ3W",
    names: ["Mycophenolic Acid/Nucleotide Derivative 3"],
  },
  {
    primary_id: "D0XQ4B",
    names: ["Aryl Urea Derivative 4"],
  },
  {
    primary_id: "D0XQ4T",
    names: ["2-(Substituted Ethynyl)Quinoline Derivative 3"],
  },
  {
    primary_id: "D0XQ5E",
    names: ["Quinoxaline Derivative 4"],
  },
  {
    primary_id: "D0XQ5F",
    names: ["PMID25656651-Compound-21b"],
  },
  {
    primary_id: "D0XQ5U",
    names: ["C[CO-2,3-Pyrazine-CO-D-Nal(2)-Arg-Trp-Lys]-NH2", "CHEMBL253789"],
  },
  {
    primary_id: "D0XQ5X",
    names: ["Halaven"],
  },
  {
    primary_id: "D0XQ6J",
    names: ["99mTC-NC-100668"],
  },
  {
    primary_id: "D0XQ6T",
    names: ["7,8-Dimethoxy-1,4-Dimethylquinolin-2(1H)-One", "Casimiroin Analogue, 1h", "CHEMBL453233"],
  },
  {
    primary_id: "D0XQ6V",
    names: ["APD-515", "ACA-1515"],
  },
  {
    primary_id: "D0XQ7J",
    names: ["C-101,606"],
  },
  {
    primary_id: "D0XQ8A",
    names: ["TPN-729"],
  },
  {
    primary_id: "D0XQ8P",
    names: [
      "2-Thiazol-5-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "2-(Thiazol-5-Ylmethyl)-1H-Indazol-3(2H)-One",
      "120273-62-3",
      "CHEMBL3144715",
      "1,2-Dihydro-2-(5-Thiazolylmethyl)-3H-Indazol-3-One",
      "CHEMBL267524",
      "SCHEMBL16160907",
      "BDBM50008996",
      "ZINC100161259",
      "AKOS022188370",
      "AJ-64420",
      "AJ-64421",
      "AX8285840",
    ],
  },
  {
    primary_id: "D0XR0B",
    names: ["SPI-205"],
  },
  {
    primary_id: "D0XR0L",
    names: [
      "2,3-Dihydro-1,4-Dithiin-1,1,4,4-Tetroxide",
      "GTPL3486",
      "SCHEMBL10948770",
      "5,6-Dihydro-1,4-Dithiin-1,1,4,4-Tetraoxide",
      "2,3-Dihydro-1,4-Dithiine 1,1,4,4-Tetraoxide",
    ],
  },
  {
    primary_id: "D0XR0V",
    names: ["DWP-451"],
  },
  {
    primary_id: "D0XR0Z",
    names: ["Aryl Pyrimidine Derivative 6"],
  },
  {
    primary_id: "D0XR1B",
    names: ["Vyndaqel"],
  },
  {
    primary_id: "D0XR1C",
    names: ["Biaryl Mannoside Derivative 28"],
  },
  {
    primary_id: "D0XR2J",
    names: ["1-Amino-3-(Aminoxy)-2-Propanol Dihydrochloride", "CHEMBL1209777"],
  },
  {
    primary_id: "D0XR4J",
    names: [
      "H-Phe-Phe-NH2",
      "CHEMBL108438",
      "15893-46-6",
      "Phe-Phe-Amide",
      "Phenylalanylphenylalanylamide",
      "L-Phe-L-Phe-Amide",
      "L-Phenylalanine-L-Phenylalanylamide",
      "L-Phe-L-Phe-NH2",
      "AC1L38WX",
      "XXPXQEQOAZMUDD-HOTGVXAUSA-N",
      "L-Phenylalaninamide, L-Phenylalanyl-",
      "BDBM50188489",
      "(S)-2-Amino-N-((S)-1-Carbamoyl-2-Phenyl-Ethyl)-3-Phenyl-Propionamide",
    ],
  },
  {
    primary_id: "D0XR6A",
    names: ["KwFwLL-NH2", "CHEMBL506986"],
  },
  {
    primary_id: "D0XR6B",
    names: ["ACH-2881"],
  },
  {
    primary_id: "D0XR6I",
    names: ["TDI-0113"],
  },
  {
    primary_id: "D0XR7C",
    names: ["YM-598"],
  },
  {
    primary_id: "D0XR7R",
    names: ["RG7386"],
  },
  {
    primary_id: "D0XR7S",
    names: ["Tricyclic 5-Quinolone Derivative 1"],
  },
  {
    primary_id: "D0XR7V",
    names: ["TG-B"],
  },
  {
    primary_id: "D0XR8Q",
    names: ["GW2433"],
  },
  {
    primary_id: "D0XR9O",
    names: ["Pyrrolo-Pyridinone Derivative 1"],
  },
  {
    primary_id: "D0XR9Y",
    names: ["Pyrrolo-Pyrrolone Derivative 5"],
  },
  {
    primary_id: "D0XR9Z",
    names: ["SB251023"],
  },
  {
    primary_id: "D0XS0R",
    names: ["EPIERENONE"],
  },
  {
    primary_id: "D0XS0X",
    names: ["3-Trans-P-Coumaroyl Maslinic Acid", "CHEMBL453751"],
  },
  {
    primary_id: "D0XS0Z",
    names: ["Carbamide Derivative 21"],
  },
  {
    primary_id: "D0XS1I",
    names: ["PMID29338548-Compound-47"],
  },
  {
    primary_id: "D0XS3N",
    names: ["Fluorinated Donepezil Derivative 1"],
  },
  {
    primary_id: "D0XS3T",
    names: ["Vagonixen"],
  },
  {
    primary_id: "D0XS3W",
    names: ["1-Adamantan-1-Yl-3-(1-Ethyl-Piperidin-4-Yl)-Urea", "CHEMBL214534", "SCHEMBL5146577"],
  },
  {
    primary_id: "D0XS4G",
    names: ["Caprylic Acid"],
  },
  {
    primary_id: "D0XS4X",
    names: ["Ibodutant"],
  },
  {
    primary_id: "D0XS6T",
    names: [
      "1-(4-Aminophenyl)-2-(1H-Imidazol-1-Yl)Ethanone",
      "1-(4-Aminophenyl)-2-(1h-Imidazol-1-Yl)Ethanone",
      "CHEMBL162431",
      "AC1Q5E94",
      "AC1L1F89",
      "SCHEMBL9663730",
      "BDBM50322067",
      "AKOS022646799",
      "73932-42-0",
      "1-(4-Aminophenyl)-2-Imidazol-1-Ylethanone",
      "1-(4-Amino-Phenyl)-2-Imidazol-1-Yl-Ethanone",
      "Alpha-(1H-Imidazole-1-Yl)-4'-Aminoacetophenone",
      "Ethanone, 1-(4-Aminophenyl)-2-(1H-Imidazol-1-Yl)-",
    ],
  },
  {
    primary_id: "D0XS7I",
    names: ["PF-06939926"],
  },
  {
    primary_id: "D0XS9E",
    names: [
      "(5-Hydroxy-1H-Indol-2-Yl)(1H-Indol-2-Yl)Methanone",
      "CHEMBL7735",
      "PDGF Receptor Tyrosine Kinase Inhibitor I",
      "D-64406",
      "(5-Hydroxy-1H-2-Indolyl)(1H-2-Indolyl)-Methanone",
      "249762-62-7",
      "AC1NS9CN",
      "BDBM6581",
      "SCHEMBL4456469",
      "CHEBI:92486",
      "ZINC24261",
      "CTK0J4476",
      "DTXSID20416205",
      "Bis(1H-2-Indolyl)Methanone 39",
      "IN1158",
      "HSCI1_000198",
      "NCGC00185731-01",
      "5-Hydroxy-[2,2'-Carbonylbis(1H-Indole)]",
      "2-(1H-Indol-2-Ylcarbonyl)-1H-Indol-5-Ol",
      "BRD-K27665173-001-01-3",
      "(5-Hydroxy-1H-Indol-2-Yl)-(1H-Indol-2-Yl)Methanone",
      "Methanone, (5-Hydroxy-1H-Indol-2-Yl)-1H-Indol-2-Yl-",
    ],
  },
  {
    primary_id: "D0XT0I",
    names: ["BIA"],
  },
  {
    primary_id: "D0XT0W",
    names: ["Debio 1347"],
  },
  {
    primary_id: "D0XT1M",
    names: ["VALROCEMIDE"],
  },
  {
    primary_id: "D0XT2X",
    names: ["[14C]histidine", "[14C]-Histidine"],
  },
  {
    primary_id: "D0XT3G",
    names: ["ET-009"],
  },
  {
    primary_id: "D0XT3V",
    names: ["Aromatic Ring Compound 1"],
  },
  {
    primary_id: "D0XT3Z",
    names: ["KN-93"],
  },
  {
    primary_id: "D0XT4J",
    names: ["7-(3-Chlorophenyl)-2-Methylquinoline", "CHEMBL231987"],
  },
  {
    primary_id: "D0XT4V",
    names: ["PMID29473428-Compound-17"],
  },
  {
    primary_id: "D0XT5F",
    names: ["Onyvax-O"],
  },
  {
    primary_id: "D0XT5G",
    names: ["Phytocannabinoids"],
  },
  {
    primary_id: "D0XT5Q",
    names: ["AGS-16C3F"],
  },
  {
    primary_id: "D0XT5Y",
    names: ["Sargramostim"],
  },
  {
    primary_id: "D0XT6W",
    names: [
      "PDX-101",
      "Belinostat",
      "414864-00-9",
      "PXD101",
      "PXD-101",
      "Belinostat (PXD101)",
      "Beleodaq",
      "866323-14-0",
      "(E)-N-Hydroxy-3-(3-(N-Phenylsulfamoyl)Phenyl)Acrylamide",
      "PXD 101",
      "N-HYDROXY-3-(3-PHENYLSULFAMOYLPHENYL)ACRYLAMIDE",
      "UNII-F4H96P17NZ",
      "Belinostat(Random Configuration)",
      "NSC726630",
      "PX105684",
      "PX 105684",
      "F4H96P17NZ",
      "CHEBI:61076",
      "(2E)-N-Hydroxy-3-[3-(Phenylsulfamoyl)Phenyl]prop-2-Enamide",
      "N-HYDROXY-3-[3-[(PHENYLAMINO)SULFONYL]PHENYL]-2-PROPENAMIDE",
      "(E)-N-Hydroxy-3-[3-(Phenylsulfamoyl)Phenyl]prop-2-Enamide",
      "PX-105684",
    ],
  },
  {
    primary_id: "D0XT7O",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 29"],
  },
  {
    primary_id: "D0XT8V",
    names: ["Calcium Nanoparticles", "Calcium Nanoparticles (Cancer Detection)"],
  },
  {
    primary_id: "D0XT8W",
    names: ["SOM3355"],
  },
  {
    primary_id: "D0XU0G",
    names: ["N-(Quinolin-3-Yl)-6-(Sulfamoylamino)Hexanamide", "CHEMBL475714", "SCHEMBL5458681"],
  },
  {
    primary_id: "D0XU1I",
    names: ["G3139 + Dacarbazine"],
  },
  {
    primary_id: "D0XU3A",
    names: ["PMID25656651-Compound-23c"],
  },
  {
    primary_id: "D0XU3D",
    names: ["IDP-124 Lotion"],
  },
  {
    primary_id: "D0XU4O",
    names: ["NVX-412"],
  },
  {
    primary_id: "D0XU4Y",
    names: [
      "NSC-66209",
      "5407-46-5",
      "Methyl Fluorone Black",
      "2,6,7-Trihydroxy-9-Methylxanthen-3-One",
      "2,6,7-Trihydroxy-9-Methyl-Xanthen-3-One",
      "NSC 5426",
      "UNII-KQC69H1NAG",
      "KQC69H1NAG",
      "3H-Xanthen-3-One, 2,6,7-Trihydroxy-9-Methyl-",
      "MLS000736515",
      "MLS000123248",
      "CHEMBL175266",
      "2,6,7-Trihydroxy-9-Methyl-3H-Xanthen-3-One",
      "NSC5426",
      "NSC66209",
      "9-Methyl-2,3,7-Trihydroxy-6-Fluorone Hemisulfate",
      "ST057760",
      "SMR000123893",
      "BAS 00532150",
      "9-METHYL-2,3,7-TRIHYDROXY-6-FLUORONE",
      "EINECS 226-468-8",
      "NSC 66209",
      "AC1Q69RP",
      "AC1L2IJ2",
      "Cid_72721",
      "SCHEMBL2781454",
      "CTK4J9473",
    ],
  },
  {
    primary_id: "D0XU5Y",
    names: ["HL-025"],
  },
  {
    primary_id: "D0XU6H",
    names: ["KNI-10372"],
  },
  {
    primary_id: "D0XU7M",
    names: ["NSC-663284"],
  },
  {
    primary_id: "D0XU7N",
    names: ["10-CF3C(OH)2-DDACTHF"],
  },
  {
    primary_id: "D0XU9F",
    names: ["G3139 + Cytarabine (ARA-C)"],
  },
  {
    primary_id: "D0XV0T",
    names: [
      "17-Allylamino-17-Desmethoxy-Geldanamycin",
      "75747-14-7",
      "NSC-330507",
      "17-Demethoxy-17-Allylamino Geldanamycin",
      "KOS-953",
      "UNII-4GY0AVT3L4",
      "CHEBI:64153",
      "17-AAG (Tanespimycin)",
      "BMS-722782",
      "4GY0AVT3L4",
      "NSC 330507",
      "NCGC00163424-01",
      "17-N-Allylamino-17-Demethoxygeldanamycin",
      "Geldanamycin, 17-Demethoxy-17-(2-Propenylamino)-",
      "NSC330507",
    ],
  },
  {
    primary_id: "D0XV0Y",
    names: ["CT-P6"],
  },
  {
    primary_id: "D0XV1M",
    names: ["ISIS 25549"],
  },
  {
    primary_id: "D0XV2A",
    names: ["PMID25399762-Compound-Table 7-Benzyl Cinnamate"],
  },
  {
    primary_id: "D0XV2U",
    names: ["BIIB093"],
  },
  {
    primary_id: "D0XV3B",
    names: ["Methionine Sulfoxide"],
  },
  {
    primary_id: "D0XV3Z",
    names: ["Allervax Ragweed"],
  },
  {
    primary_id: "D0XV4L",
    names: ["Disulfide Compound 1"],
  },
  {
    primary_id: "D0XV4Z",
    names: ["TD-4208"],
  },
  {
    primary_id: "D0XV5B",
    names: ["Human Insulin Zinc Suspension"],
  },
  {
    primary_id: "D0XV6L",
    names: ["Kanglaite Injection"],
  },
  {
    primary_id: "D0XV7Y",
    names: ["1,3-Dihydroxy Phenyl Derivative 2"],
  },
  {
    primary_id: "D0XV8M",
    names: [
      "CVT-012000",
      "CVT-012296",
      "CVT-013064",
      "Sodium Channel Inhibitor DHP-3",
      "Late INa Modulators (Cardiovascular), CVTherapeutics",
      "Late Sodium Current Modulators (Oral, Coronary Artery Disease)",
      "Late Sodium Current Modulators (Oral, Coronary Artery Disease), CV Therapeutics",
      "Late Sodium Current Modulators (Oral, Coronary Artery Disease), Gilead Palo Alto",
    ],
  },
  {
    primary_id: "D0XW2H",
    names: ["Mometasone Furoate/ Formoterol Fumarate", "Dulera (TN)"],
  },
  {
    primary_id: "D0XW4G",
    names: ["Pyridic Ketone Derivative 1"],
  },
  {
    primary_id: "D0XW4W",
    names: ["SEP-227900"],
  },
  {
    primary_id: "D0XW6B",
    names: ["Autologous Dendritic Cell Vaccine", "Autologous Dendritic Cell Vaccine (HIV-1 Infection)"],
  },
  {
    primary_id: "D0XW6Y",
    names: ["S-38093"],
  },
  {
    primary_id: "D0XW8R",
    names: ["11C-R-129144"],
  },
  {
    primary_id: "D0XW8Y",
    names: ["CNTX-4975"],
  },
  {
    primary_id: "D0XW9L",
    names: ["Rifalazil"],
  },
  {
    primary_id: "D0XW9U",
    names: [
      "N-Methyl-2-(Pyridin-2-Yl)Ethanamine",
      "Betahistine",
      "5638-76-6",
      "2-(2-METHYLAMINOETHYL)PYRIDINE",
      "Vasomotal",
      "Serc Base",
      "2-Pyridineethanamine, N-Methyl-",
      "Beta-Histine",
      "PT 9 Base",
      "Sinmenier (Free Base)",
      "Methyl[2-(Pyridin-2-Yl)Ethyl]amine",
      "Betahistina",
      "Betahistinum",
      "2-[2-(Methylamino)Ethyl]pyridine",
      "N-Methyl-2-Pyridineethylamine",
      "N-Methyl-2-Pyridineethanamine",
      "Betaistina [DCIT]",
      "Serc",
      "Betahistine [INN:BAN]",
      "2-(2-(Methylamino)Ethyl)Pyridine",
      "N-Methyl-2-Pyridin-2-Ylethanamine",
      "Betahistinum [INN-Latin]",
      "Betahistina [INN-Spanish]",
    ],
  },
  {
    primary_id: "D0XX1Y",
    names: ["4-Phenyl-1-(Phenyl(P-Tolyl)Methyl)Piperidin-4-Ol", "CHEMBL228793"],
  },
  {
    primary_id: "D0XX2I",
    names: [
      "NPH-30907",
      "Antifungal Agents, Nicholas Piramal",
      "Antifungal Agents, Piramal Life Sciences",
      "PP-04",
      "Antifungal Agents, NPIL Research & Development",
    ],
  },
  {
    primary_id: "D0XX2U",
    names: [
      "8-Methoxy-4-Methyl-3,4-Dihydroquinazolin-2-Amine",
      "CHEMBL408152",
      "SCHEMBL1382388",
      "SCKPQZMXGISHHF-UHFFFAOYSA-N",
      "BDBM50371445",
      "918136-34-2",
      "8-Methoxy-4-Methyl-3,4-Dihydro-Quinazolin-2-Ylamine",
      "2-Quinazolinamine, 1,4-Dihydro-8-Methoxy-4-Methyl-",
    ],
  },
  {
    primary_id: "D0XX5S",
    names: [
      "DICHLOROISOPROTERENOL",
      "Dichloroisoproterenol",
      "Dichlorisoproterenol",
      "Dichloroisoprenaline",
      "Dichlorisoprenaline [German]",
      "59-61-0",
      "3,4-Dichlor-Isoproterenol [German]",
      "1-(3,4-Dichlorophenyl)-2-Isopropylaminoethanol",
      "BRN 2807251",
      "CHEMBL30816",
      "Beta-Hydroxy-N-Isopropyl-3,4-Dichlorophenethylamine",
      "3,4-Dichloro-Alpha-(Isopropylaminomethyl)Benzyl Alcohol",
      "N-(Beta-(3,4-Dichlorophenyl)-Beta-Hydroxyethyl)Isopropylamine",
      "3,4-Dichloro-Alpha-(((1-Methylethyl)Amino)Methyl)Benzenemethanol",
      "1-(3,4-Dichlorophenyl)-2-(Isopropylamino)Ethanol",
      "BENZYL ALCOHOL, 3,4-D",
    ],
  },
  {
    primary_id: "D0XX6Y",
    names: [
      "Pipecuronium",
      "Arduan",
      "Pipecurium",
      "Arduan (TN)",
      "Piperazinium, 4,4'-((2beta,3alpha,5alpha,16beta,17beta)-3,17-Bis(Acetyloxy)Androstane-2,16-Diyl)Bis(1,1-Dimethyl)",
      "[(2S,3S,5S,8R,9S,10S,13S,14S,16S,17R)-17-Acetyloxy-2,16-Bis(4,4-Dimethylpiperazin-4-Ium-1-Yl)-10,13-Dimethyl-2,3,4,5,6,7,8,9,11,12,14,15,16,17-Tetradecahydro-1H-Cyclopenta[a]phenanthren-3-Yl] Acetate",
    ],
  },
  {
    primary_id: "D0XX7E",
    names: ["RNA-144101", "AMD Agent (Intravitreous), University Of Kentucky"],
  },
  {
    primary_id: "D0XX7J",
    names: ["7,8,9,10-Tetrahydro-6H-Furo-[3,2-G][3]benzazepine", "CHEMBL261489"],
  },
  {
    primary_id: "D0XX8R",
    names: ["PMID25666693-Compound-161"],
  },
  {
    primary_id: "D0XX9C",
    names: [
      "AL7099A",
      "(S)-3,4-Dihydro-2-(3-Methoxyphenyl)-4-Methylamino-2h-Thieno[3,2-E]-1,2-Thiazine-6-Sulfonamide-1,1-Dioxide",
      "1bnv",
      "AC1N9ZNC",
      "SCHEMBL678443",
      "BDBM11937",
      "AL7099",
      "DB03221",
      "(4S)-2-(3-Methoxyphenyl)-4-(Methylamino)-1,1-Dioxo-3,4-Dihydrothieno[3,2-E]thiazine-6-Sulfonamide",
      "(4S)-2-(3-Methoxyphenyl)-4-(Methylamino)-3,4-Dihydro-2H-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide 1,1-Dioxide",
      "(4S)-2-(3-Methoxyphenyl)-4-(Methylamino)-1,1-Dioxo-2H,3H,4H-1$l^{6},7,2-Thieno[3,2-E][1,2]thiazine-6-Sulfonamide",
    ],
  },
  {
    primary_id: "D0XX9G",
    names: [
      "(3-Amino-Propyl)-Hydroxymethyl-Phosphinic Acid",
      "AC1MTETN",
      "SCHEMBL341290",
      "MolPort-002-514-611",
      "STL513587",
      "ZINC20112625",
      "AKOS030493595",
      "MCULE-2944312169",
      "3-Aminopropyl(Hydroxymethyl)Phosphinic Acid",
      "(Hydroxymethyl)(3-Aminopropyl)Phosphinic Acid",
      "(3-Aminopropyl)(Hydroxymethyl)Phosphinic Acid",
    ],
  },
  {
    primary_id: "D0XX9N",
    names: ["Tetra-Hydro-Oxazolopyridine Derivative 6"],
  },
  {
    primary_id: "D0XX9Y",
    names: [
      "DH97",
      "DH 97",
      "343263-95-6",
      "N-[2-(2-Benzyl-1H-Indol-3-Yl)Ethyl]pentanamide",
      "N-(2-(2-Benzyl-1H-Indol-3-Yl)Ethyl)Pentanamide",
      "Luzindole,N-Pentanoyl",
      "Tocris-1218",
      "AC1MRF3Z",
      "220339-00-4",
      "N-Pentanoyl 2-Benzyltryptamine",
      "N-Pentanoyl-2-Benzyltryptamine",
      "SCHEMBL1626901",
      "GTPL3366",
      "CHEMBL1327247",
      "CHEBI:92107",
      "CTK8E7620",
      "BDBM85384",
      "DTXSID90392749",
      "MolPort-003-983-554",
      "HMS3267D11",
      "ZINC2581407",
      "BN0182",
      "AKOS024456472",
      "NCGC00025049-01",
      "NCGC00025049-02",
      "ACM343263956",
      "RT-012298",
      "FT-0763744",
      "SR-01000597373",
      "SR-01000597373-1",
      "J-019556",
    ],
  },
  {
    primary_id: "D0XY0Z",
    names: [
      "Thiophosphoric Acid (E)-Dodec-9-Enyl Ester",
      "CHEMBL190484",
      "SCHEMBL193155",
      "BDBM50170847",
      "9-Dodecen-1-Ol, Dihydrogen Phosphorothioate",
    ],
  },
  {
    primary_id: "D0XY1J",
    names: ["Pyrrolo-Pyrimidine Derivative 11"],
  },
  {
    primary_id: "D0XY2M",
    names: ["ML213"],
  },
  {
    primary_id: "D0XY3G",
    names: ["2-(2-Thiazolyl)Ethanamine", "2-Thiazoleethanamine", "2-Thiazolylethylamine", "2-(2-Aminoethyl)Thiazole"],
  },
  {
    primary_id: "D0XY4A",
    names: ["BPS-804", "Anti-Sclerostin Antibody (Osteoporosis/Hypophophatasia), Novartis"],
  },
  {
    primary_id: "D0XY4Q",
    names: ["Cholazol", "Cholestran"],
  },
  {
    primary_id: "D0XY6I",
    names: ["PMID25399762-Compound-Figure4-Aphanamgrandiol A"],
  },
  {
    primary_id: "D0XY6T",
    names: ["Humanized CD19 CAR-T Cells"],
  },
  {
    primary_id: "D0XY7C",
    names: ["Azole Benzene Derivative 4"],
  },
  {
    primary_id: "D0XY8C",
    names: ["Encapsulated Cell Therapy"],
  },
  {
    primary_id: "D0XY8K",
    names: ["ZP-007"],
  },
  {
    primary_id: "D0XY9E",
    names: [
      "2-(N-Tert-Butylamino)-3'-Chloropentanophenone",
      "CHEMBL569465",
      "2-(Tert-Butylamino)-1-(3-Chlorophenyl)Pentan-1-One",
      "AC1MHOFP",
      "SCHEMBL769847",
      "BDBM50302927",
    ],
  },
  {
    primary_id: "D0XZ1D",
    names: ["BLU-554"],
  },
  {
    primary_id: "D0XZ2P",
    names: ["KSP-BCS-1-Alpha-CHF2-20-Epi-22-Oxabishomo-26-OH", "CHEMBL376093"],
  },
  {
    primary_id: "D0XZ2S",
    names: ["CAR-T Cells Targeting MAGE-A4"],
  },
  {
    primary_id: "D0XZ3W",
    names: ["SEL-120"],
  },
  {
    primary_id: "D0XZ5D",
    names: ["2-(3-Chlorophenyl)-2H-Indazole-7-Carboxamide", "CHEMBL1094953", "SCHEMBL2265628"],
  },
  {
    primary_id: "D0XZ9H",
    names: ["LY2951742"],
  },
  {
    primary_id: "D0XZ9Q",
    names: ["8-Mercapto-Octanoic Acid Phenylamide", "8-Mercapto-N-Phenyloctanamide", "CHEMBL326433", "ZINC13609343"],
  },
  {
    primary_id: "D0Y0AP",
    names: ["GR-144053"],
  },
  {
    primary_id: "D0Y0BB",
    names: [
      "3-(4-Chlorophenyl)-2-Nortropene",
      "CHEMBL576508",
      "189746-53-0",
      "SCHEMBL6214667",
      "CTK0A2712",
      "DTXSID10431947",
      "QBSBBGLHCMPGNO-UHFFFAOYSA-N",
      "BDBM50303307",
      "8-Azabicyclo[3.2.1]oct-2-Ene, 3-(4-Chlorophenyl)-",
      "Racemic 3-(4-Chloro-Phenyl)-8-Aza-Bicyclo[3.2.1]oct-2-Ene",
    ],
  },
  {
    primary_id: "D0Y0BC",
    names: ["XL-541"],
  },
  {
    primary_id: "D0Y0BK",
    names: [
      "DORSOMORPHIN",
      "Dorsomorphin",
      "866405-64-3",
      "6-(4-(2-(Piperidin-1-Yl)Ethoxy)Phenyl)-3-(Pyridin-4-Yl)Pyrazolo[1,5-A]pyrimidine",
      "BML-275",
      "Compound C",
      "AMPK Inhibitor, Compound C",
      "6-[4-(2-Piperidin-1-Ylethoxy)Phenyl]-3-Pyridin-4-Ylpyrazolo[1,5-A]pyrimidine",
      "CHEMBL478629",
      "CHEBI:78510",
      "4-(6-{4-[2-(Piperidin-1-Yl)Ethoxy]phenyl}pyrazolo[1,5-A]pyrimidin-3-Yl)Pyridine",
      "6-[4-(2-Piperidin-1-Yl-Ethoxy)-Phenyl)]-3-Pyridin-4-Yl-Pyrrazolo[1,5-A]-Pyrimidine",
      "AMPK Inhibitor",
    ],
  },
  {
    primary_id: "D0Y0BZ",
    names: ["PUP-1"],
  },
  {
    primary_id: "D0Y0CA",
    names: ["2-Oxoguaia-1,4(15), 11(13)-Trien-12,8beta-Olide", "CHEMBL189428"],
  },
  {
    primary_id: "D0Y0CT",
    names: [
      "3-[3-Phenyl-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 37a",
      "BDBM8648",
      "AC1O707H",
      "CHEMBL193555",
      "3-[(E)-(3-Phenyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0Y0DG",
    names: ["Uracil"],
  },
  {
    primary_id: "D0Y0EJ",
    names: ["ISIS 3307"],
  },
  {
    primary_id: "D0Y0EK",
    names: [
      "7-Hydroxy-2-(3-Hydroxyphenyl)Chroman-4-One",
      "CHEMBL255110",
      "62252-06-6",
      "4H-1-Benzopyran-4-One, 2,3-Dihydro-7-Hydroxy-2-(3-Hydroxyphenyl)-",
      "AC1LDLCQ",
      "CTK2C3909",
      "DTXSID60347362",
      "MXNJBOFUFMJXOB-UHFFFAOYSA-N",
      "BDBM50374256",
      "AKOS030552838",
      "7-Hydroxy-2-(3-Hydroxyphenyl)-2,3-Dihydrochromen-4-One",
      "2,3-Dihydro-7-Hydroxy-2-(3-Hydroxyphenyl)-4H-1-Benzopyran-4-One",
      "7-Hydroxy-2-(3-Hydroxyphenyl)-2,3-Dihydro-4H-Chromen-4-One #",
    ],
  },
  {
    primary_id: "D0Y0EP",
    names: [
      "Lanabecestat",
      "1383982-64-6",
      "UNII-X8SPJ492VF",
      "X8SPJ492VF",
      "LY3314814",
      "Lanabecestat [USAN]",
      "Lanabecestat (USAN)",
      "SCHEMBL9947930",
      "GTPL7789",
      "SCHEMBL9947926",
      "CHEMBL3261045",
      "SCHEMBL10249890",
      "CHEMBL3989948",
      "CHEMBL3349234",
      "BDBM41542",
      "BDBM41537",
      "MolPort-044-560-403",
      "BDBM136733",
      "EX-A1471",
      "S8193",
      "ZINC95576075",
      "BDBM50012629",
      "US8865911, 20a Isomer 1",
      "CS-7494",
      "Lanabecestat(AZD3293,LY-3314814)",
      "HY-100740",
      "LY 3314814",
      "D10946",
      "US8865911, 122",
      "US8865911, 114",
      "4-Methoxy-5'-Methyl-6'-(5-(Prop-1-Yn-1-Yl)Pyridin-3-Yl)-3'H-Dispiro(Cyclohexane",
    ],
  },
  {
    primary_id: "D0Y0ER",
    names: ["Thiethylperazine"],
  },
  {
    primary_id: "D0Y0ES",
    names: ["Indirubin-3'-Monoxime"],
  },
  {
    primary_id: "D0Y0FC",
    names: ["O-Benzylsulfonyl-Serine", "AC1MNK8J", "SCHEMBL4313869", "2-Amino-3-Benzylsulfonyloxypropanoic Acid"],
  },
  {
    primary_id: "D0Y0FE",
    names: ["PWT-33597"],
  },
  {
    primary_id: "D0Y0FN",
    names: ["3-(Decylsulfonyl)-1,1,1-Trifluoropropan-2-One", "CHEMBL272075"],
  },
  {
    primary_id: "D0Y0FR",
    names: ["Quinoline Carboxamide Derivative 10"],
  },
  {
    primary_id: "D0Y0FS",
    names: [
      "6-Nitroindazole",
      "6-Nitro-1H-Indazole",
      "7597-18-4",
      "6-Nitro-2H-Indazole",
      "6-Nitroisoindazole",
      "1H-INDAZOLE, 6-NITRO-",
      "65750-02-9",
      "2H-Indazole, 6-Nitro-",
      "CCRIS 3263",
      "EINECS 231-500-9",
      "NSC 35066",
      "NSC 56816",
      "BRN 0007812",
      "CHEMBL54277",
      "MLS000069593",
      "ORZRMRUXSPNQQL-UHFFFAOYSA-N",
      "SMR000059016",
      "6NI",
      "6-Nitro-Indazol",
      "Indazole,6-Nitro",
      "Tocris-0710",
      "PubChem20594",
      "Opera_ID_909",
      "2H-Indazole,6-Nitro-",
      "AC1Q1XVW",
      "6-Nitroindazole, 97%",
      "ACMC-209p0z",
      "AC1L2NB1",
      "AC1Q1Y5L",
      "MLS001148387",
      "KSC379M3P",
      "5-23-06-00183 (Beilstein Handbook Reference)",
      "SCHEMBL271522",
    ],
  },
  {
    primary_id: "D0Y0FZ",
    names: ["PF-05230905", "ATN-192"],
  },
  {
    primary_id: "D0Y0GG",
    names: ["Epanolol"],
  },
  {
    primary_id: "D0Y0GH",
    names: ["Mitomycin"],
  },
  {
    primary_id: "D0Y0GS",
    names: ["PMA-406"],
  },
  {
    primary_id: "D0Y0HC",
    names: ["PF-06671008"],
  },
  {
    primary_id: "D0Y0HJ",
    names: ["HCV NS5B Polymerase Inhibitors [PMCID:PMC2928549]"],
  },
  {
    primary_id: "D0Y0HR",
    names: ["XOMA 358"],
  },
  {
    primary_id: "D0Y0ID",
    names: [
      "6-Azacytidine, National Academy Of Sciences Of Ukraine",
      "6-Azacytidine Analogs, National Academy Of Sciences Of Ukraine",
    ],
  },
  {
    primary_id: "D0Y0IE",
    names: ["FGGFTGARKSARKL", "CHEMBL437915"],
  },
  {
    primary_id: "D0Y0JH",
    names: [
      "Salsalate",
      "Diacesal",
      "Diplosal",
      "Disalcid",
      "Disalicylsaeure",
      "Disalyl",
      "Nobacid",
      "Salflex",
      "Salical",
      "Salina",
      "Saloxium",
      "Salsalato",
      "Salsalatum;Salysal",
      "Sasapirin",
      "Sasapyrin",
      "Sasapyrine",
      "Sasapyrinum",
      "Disalicylic Acid",
      "Sal Ester Sal",
      "Salicylic Acid Salicylate",
      "Salicyloylsalicylic Acid",
      "Salicylsalicylic Acid",
      "Disalcid (TN)",
      "O-Salicylcylsalicylsaeure",
      "O-Salicyloylsalicylic Acid",
      "O-Salicylsalicylic Acid",
      "Salflex (TN)",
      "Salicylic Acid, Bimolecular Ester",
      "Salicylic Acid, Salicylate",
      "Salsalato [INN-Spanish]",
      "Salsalatum [INN-Latin]",
      "Salsitab (TN)",
      "Sasapyrine (JAN)",
      "Mono-Gesic (TN)",
      "Salsalate (USP/INN)",
      "Salsalate [USAN:INN:BAN]",
      "Benzoic Acid, 2-Hydroxy-, 2-Carboxyphenyl Ester",
      "2-((2-Hydroxybenzoyl)Oxy)Benzoic Acid",
      "2-(2-Hydroxybenzoyl)Oxybenzoic Acid",
      "2-Carboxyphenyl Salicylate",
      "2-Salicyloyloxybenzoic Acid",
      "2-{[(2-Hydroxyphenyl)Carbonyl]oxy}benzoic Acid",
    ],
  },
  {
    primary_id: "D0Y0JM",
    names: ["Benzyl-Methyl-[1-(1H-Pyrrol-2-Yl)-Vinyl]-Amine", "CHEMBL171809"],
  },
  {
    primary_id: "D0Y0KF",
    names: [
      "1,5-Dihydroxy-10H-Anthracen-9-One",
      "1,5-Dihydroxyanthrone",
      "CHEMBL333452",
      "SCHEMBL8756171",
      "MYYRAEQRSBLQQO-UHFFFAOYSA-N",
      "ZINC13606493",
      "BDBM50060873",
    ],
  },
  {
    primary_id: "D0Y0KU",
    names: ["(+/-)-7-Fluoro-2-P-Tolylchroman-4-One", "CHEMBL600391"],
  },
  {
    primary_id: "D0Y0KV",
    names: [
      "RS-118641",
      "A-500359A",
      "A-500359E",
      "Capuramycin Derivative Translocase Inhibitors (Tuberculosis)",
      "SQ-641",
      "Capuramycin Derivative Translocase Inhibitors (Tuberculosis), Sequella",
      "SQ-641-Aua",
      "SQ-922-Aua",
      "Translocase Inhibitors (Tuberculosis), Sankyo",
    ],
  },
  {
    primary_id: "D0Y0KY",
    names: ["ISIS-APO(A)"],
  },
  {
    primary_id: "D0Y0LA",
    names: ["PG2-2000"],
  },
  {
    primary_id: "D0Y0LD",
    names: [
      "DTD",
      "DITHIANE DIOL",
      "Trans-1,2-Dithiane-4,5-Diol",
      "Oxidized Dithiothreitol",
      "(4R,5R)-1,2-Dithiane-4,5-Diol",
      "D-4,5-Dihydroxy-1,2-Dithiane",
      "16096-98-3",
      "NSC 667089",
      "Trans-4,5-Dihydroxy-1,2-Dithiane",
      "O-DITHIANE, 4,5-DIHYDROXY-, D-",
      "14193-38-5",
      "51621-02-4",
      "1qtn",
      "2izx",
      "AC1L97AN",
      "Trans-O-Dithiane-4,5-Diol",
      "Threo-O-Dithiane-4,5-Diol",
      "SCHEMBL1628862",
      "(4R,5R)-Dithiane-4,5-Diol",
      "YPGMOWHXEQDBBV-IMJSIDKUSA-",
      "CHEBI:42147",
      "1,2-Dithiane-4alpha,5beta-Diol",
      "YPGMOWHXEQDBBV-IMJSIDKUSA-N",
      "1,2-Dithiane-4,5-Diol, Trans-",
      "ZINC4095547",
      "AKOS022180307",
      "DB01822",
    ],
  },
  {
    primary_id: "D0Y0LI",
    names: ["PMID29649907-Compound-40"],
  },
  {
    primary_id: "D0Y0LQ",
    names: ["N-((1H-Indol-2-Yl)Methyl)-2-Phenylethanamine", "CHEMBL446960"],
  },
  {
    primary_id: "D0Y0ME",
    names: ["Amphotericin B Liposomal Oral"],
  },
  {
    primary_id: "D0Y0MY",
    names: ["JWH-402"],
  },
  {
    primary_id: "D0Y0NM",
    names: ["CGNLSTCMLGTYTQDFNPKHTFPQTAIGVGAP-Amide"],
  },
  {
    primary_id: "D0Y0OA",
    names: ["OCHNAFLAVONE"],
  },
  {
    primary_id: "D0Y0OQ",
    names: ["PMID26394986-Compound-13"],
  },
  {
    primary_id: "D0Y0PE",
    names: ["PMID25656651-Compound-46"],
  },
  {
    primary_id: "D0Y0PY",
    names: ["PI-2301"],
  },
  {
    primary_id: "D0Y0QB",
    names: [
      "PF-4522625",
      "Ballistic DNA Vaccine (Influenza), Pfizer",
      "Ballistic DNA Vaccine (Influenza), PowderMed",
      "DNA Vaccine (Influenza), PowderJect",
      "Influenza DNA Vaccine (PPJV1671 Vector), PowderMed",
      "Influenza DNA Vaccine (Powder Injection, PMED), Pfizer",
      "Influenza DNA Vaccine (Powder Injection, PMED), PowderMed",
      "Influenza DNA Vaccine (PPJV1671 Vector, DEI-LT Adjuvant), Pfizer",
    ],
  },
  {
    primary_id: "D0Y0QI",
    names: [
      "5-((1H-Imidazol-1-Yl)Methyl)-7,8-Dihydroquinoline",
      "CHEMBL53068",
      "Dihydroquinoline 44",
      "BDBM10025",
      "ZINC13808186",
      "5-(1H-Imidazol-1-Ylmethyl)-7,8-Dihydroquinoline",
      "5-[(Imidazol-1-Yl)Methyl]-7,8-Dihydroquinoline",
    ],
  },
  {
    primary_id: "D0Y0RF",
    names: ["Dendritic Cancer Cell Vaccine"],
  },
  {
    primary_id: "D0Y0RG",
    names: ["PMID25684022-Compound-US20130053382 37(4-6)"],
  },
  {
    primary_id: "D0Y0RS",
    names: ["PMID25522065-Compound-20"],
  },
  {
    primary_id: "D0Y0RW",
    names: ["Alniditan"],
  },
  {
    primary_id: "D0Y0SA",
    names: ["1-((R)-2-Aminopropyl)-1H-Indazol-6-Ol"],
  },
  {
    primary_id: "D0Y0SI",
    names: ["Aryl Mannoside Derivative 7"],
  },
  {
    primary_id: "D0Y0SO",
    names: [
      "DVDAVP",
      "1-Deamino-4-Val-8-Arg-Vasopressin",
      "Vasopressin, 1-Deamino-4-Val-8-Arg-",
      "1-Deamino-4-Valyl-8-Arginine Vasopressin",
      "Vasopressin, 1-Deamino-4-Valyl-8-Arginine-",
      "(1-Deamino,4-Valine)-8-D-Argininevasopressin",
      "51980-16-6",
      "Vasopressin 1-(3-Mercaptopropanoic Acid)-4-L-Valine-8-L-Arginine-",
    ],
  },
  {
    primary_id: "D0Y0SW",
    names: [
      "Flutamide",
      "Apimid",
      "ApoFlutamide",
      "Cebatrol",
      "Chimax",
      "Cytamid",
      "Drogenil",
      "Eulexin",
      "Eulexine",
      "Fluken",
      "Flulem",
      "Flumid",
      "FlutaGRY",
      "Flutacell",
      "Flutamida",
      "Flutamidum",
      "Flutamin",
      "Flutandrona",
      "Flutaplex",
      "Flutexin",
      "Fugerel",
      "METHOXYCHLOR",
      "NFBA",
      "Niftholide",
      "Niftolid",
      "Niftolide",
      "NovoFlutamide",
      "Odyne",
      "Oncosal",
      "Prostacur",
      "Prostandril",
      "Prostica",
      "Prostogenat",
      "Testotard",
      "Alphapharm Brand Of Flutamide",
      "Apo Flutamide",
      "Apogepha Brand Of Flutamide",
      "Apotex Brand Of Flutamide",
      "Azupharma Brand Of Flutamide",
      "Cell Pharm Brand Of Flutamide",
      "Chephasaar Brand Of Flutamide",
      "Chiron Brand Of Flutamide",
      "Ciclum Brand Of Flutamide",
      "Esparma Brand Of Flutamide",
      "Essex Brand Of Flutamide",
      "Fluta GRY",
      "Fluta Cell",
      "Gry Brand Of Flutamide",
      "Hexal Brand Of Flutamide",
      "InibsaBrand Of Flutamide",
      "Ipsen Brand Of Flutamide",
      "Juta Brand Of Flutamide",
      "Kendrick Brand Of Flutamide",
      "Lemery Brand Of Flutamide",
      "Novo Flutamide",
      "Novopharm Brand Of Flutamide",
      "PMS Flutamide",
      "Pharmascience Brand Of Flutamide",
      "Prasfarma Brand Of Flutamide",
      "Q Pharm Brand Of Flutamide",
      "Schering Brand Of Flutamide",
      "Schering Plough Brand Of Flutamide",
      "TAD Brand Of Flutamide",
      "Tedec Meiji Brand Of Flutamide",
      "F 9397",
      "F0663",
      "Fluta 1A Pharma",
      "Flutamide USP25",
      "SCH13521",
      "Sch 13521",
      "Apo-Flutamide",
      "Cebatrol, Veterinary",
      "Eulexin (TN)",
      "Fluta-GRY",
      "Fluta-Cell",
      "Flutamida [INN-Spanish]",
      "Flutamide(Pubertal Study)",
      "Flutamidum [INN-Latin]",
      "NK-601",
      "Novo-Flutamide",
      "PMS-Flutamide",
      "PUBERTAL FLUTAMIDE STUDY (PUBERTAL STUDIES OF VINCLOZOLIN",
      "Q-Pharm Brand Of Flutamide",
      "Sch-13521",
      "Schering-Plough Brand Of Flutamide",
      "Flutamide [USAN:BAN:INN]",
      "Ham's F-12 Medium",
      "Eulexin, Flutamin, Drogenil,Flutamide",
      "Flutamide (JAN/USP/INN)",
      "Alpha,alpha,alpha-Trifluoro-2-Methyl-4'-Nitro-M-Propionotoluidide",
      "M-Propionotoluidide,alpha,alpha,alpha-Trifluoro-2-Methyl-4'-Nitro",
      "M-Propionotoluidide, Alpha,alpha,alpha-Trifluoro-2-Methyl-4'-Nitro-(8CI)",
      "1A Brand Of Flutamide",
      "2-Methyl-N-(4-Nitro-3-[trifluoromethyl]phenyl)Propanamide",
      "2-Methyl-N-[4-Nitro-3-(Trifluoromethyl)Phenyl]propanamide",
      "2-Methyl-N-[4-Nitro-3-(Trifluoromethyl)Phenyl]propionamide",
      "4'-Nitro-3'-(Trifluoromethyl)Isobutyranilide",
      "4'-Nitro-3'-Trifluoromethylisobutyramilide",
      "4'-Nitro-3'-Trifluoromethylisobutyranilide",
      "4-Nitro-3-(Trifluoromethyl)Isobutyranilide",
      "Flutamide (AR Inhibitor)",
    ],
  },
  {
    primary_id: "D0Y0SY",
    names: ["E-3620"],
  },
  {
    primary_id: "D0Y0TQ",
    names: ["AGX-1053"],
  },
  {
    primary_id: "D0Y0UK",
    names: ["MX-6"],
  },
  {
    primary_id: "D0Y0UV",
    names: [
      "N-O-Tolyl-1'H-Phenothiazine-1'-Carboxamide",
      "CHEMBL600460",
      "ZINC13717070",
      "BDBM50308406",
      "AKOS004107642",
    ],
  },
  {
    primary_id: "D0Y0VC",
    names: ["NLS-1"],
  },
  {
    primary_id: "D0Y0VQ",
    names: ["N,N'-(1',8'-Octylene)-Bis-(-)-Nor-MEP", "CHEMBL410915"],
  },
  {
    primary_id: "D0Y0WW",
    names: ["FM-303"],
  },
  {
    primary_id: "D0Y0XJ",
    names: ["PMID28270021-Compound-WO2013009582Example76"],
  },
  {
    primary_id: "D0Y0XQ",
    names: [
      "2,3-Dioxoindoline-7-Carboxylic Acid",
      "25128-35-2",
      "Isatin-7-Carboxylic Acid",
      "2,3-DIOXO-2,3-DIHYDRO-1H-INDOLE-7-CARBOXYLIC ACID",
      "2,3-Dioxo-1H-Indole-7-Carboxylic Acid",
      "CHEMBL375078",
      "1H-INDOLE-7-CARBOXYLIC ACID, 2,3-DIHYDRO-2,3-DIOXO-",
      "2,3-Dioxo-1H-Benzo[d]azolidine-7-Carboxylic Acid",
      "2,3-Dioxoindoline-7-Carboxylicacid",
      "7-Isatincarboxylic Acid",
      "PubChem24310",
      "AC1MCVF6",
      "Isatin-Based Compound, 46",
      "AC1Q72QN",
      "SCHEMBL1742133",
      "BDBM22826",
      "CTK1A1532",
      "DTXSID00381427",
      "ROODQCZSWXEDJL-UHFFFAOYSA-N",
      "MolPort-000-144-864",
      "ZINC2496760",
      "KS-00000FO0",
    ],
  },
  {
    primary_id: "D0Y0ZB",
    names: ["SB-219825"],
  },
  {
    primary_id: "D0Y0ZQ",
    names: ["Ecabapide", "Ecabamide", "Muralis", "DQ-2511"],
  },
  {
    primary_id: "D0Y1AA",
    names: ["Chymopapain"],
  },
  {
    primary_id: "D0Y1AJ",
    names: ["D(CH2)5[Tyr(Me)2,Thr4,Orn8(5/6C-Flu),Tyr-NH29]VT"],
  },
  {
    primary_id: "D0Y1BF",
    names: ["APG-201"],
  },
  {
    primary_id: "D0Y1BI",
    names: ["TT-124", "C03/TT-124"],
  },
  {
    primary_id: "D0Y1BS",
    names: ["TGFTX-3"],
  },
  {
    primary_id: "D0Y1CJ",
    names: ["4-(3-Ethoxy-Phenoxy)-6,7-Dimethoxy-Quinoline", "CHEMBL98169"],
  },
  {
    primary_id: "D0Y1DR",
    names: ["4-(2-(1H-Indol-6-Yl)-9H-Purin-6-Yl)Morpholine", "CHEMBL611630"],
  },
  {
    primary_id: "D0Y1EX",
    names: ["Org-33201", "148714-92-5"],
  },
  {
    primary_id: "D0Y1FG",
    names: ["HSV-Tk"],
  },
  {
    primary_id: "D0Y1FI",
    names: ["SP-03"],
  },
  {
    primary_id: "D0Y1FO",
    names: [
      "Rubitecan",
      "9-Nitrocamptothecin",
      "91421-42-0",
      "Orathecin",
      "9-Nitro-20(S)-Camptothecin",
      "Camptogen",
      "9-NC",
      "RFS 2000",
      "RFS-2000",
      "UNII-H19C446XXB",
      "Nitrocamptothecin",
      "H19C446XXB",
      "CHEBI:90225",
      "C20H15N3O6",
      "(S)-4-Ethyl-4-Hydroxy-10-Nitro-1H-Pyrano[3',4':6,7]indolizino[1,2-B]quinoline-3,14(4H,12H)-Dione",
      "NCGC00167969-01",
      "(S)-4-Ethyl-4-Hydroxy-10-Nitro-1h-Pyrano[3',4':6,7]-Indolizino[1,2-B]quinoline-3,14(4h,12h)-Dione",
      "9NC",
      "Q-100889",
      "(4s)-4-Ethyl-4-Hydroxy-10-Nitro-1h-Pyrano[3',4':6,7]indolizino[1,2-B]quinoline-3,14(4h,12h)-Dione",
      "Inhaled Orathecin",
      "L9NC",
      "Rubitecan (Inhaled)",
      "Aerosolized Liposomal 9 Nitro-20 (S) Camptothecin",
      "Rubitecan (Inhaled), SuperGen",
      "9-Nitrocamptothecin (9-NC)",
    ],
  },
  {
    primary_id: "D0Y1FP",
    names: ["RO-5310074"],
  },
  {
    primary_id: "D0Y1FU",
    names: ["GDC0134"],
  },
  {
    primary_id: "D0Y1GJ",
    names: ["MLN-576"],
  },
  {
    primary_id: "D0Y1GL",
    names: [
      "8-Methoxy-4,9-Dihydro-3H-Beta-Carboline",
      "CHEMBL269367",
      "BDBM50136509",
      "8-Methoxy-3,4-Dihydro-Beta-Carboline",
    ],
  },
  {
    primary_id: "D0Y1GW",
    names: ["Picrotoxinin"],
  },
  {
    primary_id: "D0Y1HN",
    names: ["A192621", "A-192621", "A 192621"],
  },
  {
    primary_id: "D0Y1HZ",
    names: ["IMD-026260", "Cancer Therapeutics, InterMed Discovery", "IMD-000344", "IMD-014591", "IMD-019064"],
  },
  {
    primary_id: "D0Y1IF",
    names: ["FR-193262"],
  },
  {
    primary_id: "D0Y1IO",
    names: ["Galectin-3C"],
  },
  {
    primary_id: "D0Y1IT",
    names: ["Sodium Thiosalicylate"],
  },
  {
    primary_id: "D0Y1JB",
    names: ["E-Pentenylbutanedioic Acid 32"],
  },
  {
    primary_id: "D0Y1KF",
    names: ["Feraheme/Rienso"],
  },
  {
    primary_id: "D0Y1LD",
    names: ["RX-1792", "EGF Antagonist, Rexahn", "RX-0183"],
  },
  {
    primary_id: "D0Y1LJ",
    names: ["2-(3-(Phenylsulfonyl)-1H-Indol-1-Yl)Ethanamine", "CHEMBL188182", "SCHEMBL5787166", "BDBM50155140"],
  },
  {
    primary_id: "D0Y1LO",
    names: ["PMID29130358-Compound-Figure11(3)"],
  },
  {
    primary_id: "D0Y1MH",
    names: ["2-(4-Hydroxy-Phenyl)-8-Methyl-3H-Quinazolin-4-One"],
  },
  {
    primary_id: "D0Y1MJ",
    names: ["(RS/SR)-2-[1-(4-Chlorophenyl)Ethyl]piperidine", "CHEMBL219224", "BDBM50202385"],
  },
  {
    primary_id: "D0Y1MK",
    names: [
      "99mTc-Nitrocade",
      "CISNOET",
      "Technetium Tc 99m Nitridocade",
      "TcN-NOET",
      "ZK-131608",
      "ZK-304712",
      "Technetium-99 N-Noet",
      "Technetium-99m-Nitridocade",
      "Technetium-99m-N-NOET",
      "99Tc-N-NOET",
      "99mTc-N-NOET",
      "99mTc-Nitridocade",
    ],
  },
  {
    primary_id: "D0Y1MM",
    names: [
      "N-Arachidonoyl-O-(2-Hydroxyethyl)Hydroxylamine",
      "CHEMBL382444",
      "883296-70-6",
      "MolPort-009-019-187",
      "HMS3650G10",
      "BDBM50185034",
      "ZINC13685181",
      "1875AH",
      "SR-01000946282",
      "SR-01000946282-1",
    ],
  },
  {
    primary_id: "D0Y1MW",
    names: ["Difluoromethylornithine"],
  },
  {
    primary_id: "D0Y1NC",
    names: ["CHS-828", "Cancer Therapeutics, LEO Pharma", "GMX-1778"],
  },
  {
    primary_id: "D0Y1NH",
    names: ["Azaindazole Amide Derivative 1"],
  },
  {
    primary_id: "D0Y1NM",
    names: ["(R)-2-(Biphenyl-4-Sulfonylamino)-Propionic Acid"],
  },
  {
    primary_id: "D0Y1OF",
    names: ["VA-045"],
  },
  {
    primary_id: "D0Y1PG",
    names: ["BRL 37344"],
  },
  {
    primary_id: "D0Y1PT",
    names: ["Ala6-SRIF-14-Amide"],
  },
  {
    primary_id: "D0Y1RN",
    names: ["AC-1204"],
  },
  {
    primary_id: "D0Y1RO",
    names: ["Novo Nordisk A/S Compound"],
  },
  {
    primary_id: "D0Y1RU",
    names: ["CAL-102-R"],
  },
  {
    primary_id: "D0Y1SI",
    names: ["PMID25666693-Compound-100"],
  },
  {
    primary_id: "D0Y1SR",
    names: ["WH-4-023"],
  },
  {
    primary_id: "D0Y1TA",
    names: [
      "2,4'-Diacetoxy-5,3'-Di-(2-Propenyl)-Biphenyl",
      "CHEMBL570311",
      "Diacetylhonokiol",
      "SCHEMBL18313888",
      "BDBM50295922",
    ],
  },
  {
    primary_id: "D0Y1UC",
    names: ["Trofosfamide"],
  },
  {
    primary_id: "D0Y1UG",
    names: ["OCOPHYLLALS B"],
  },
  {
    primary_id: "D0Y1UH",
    names: ["Vesigenurtucel-L"],
  },
  {
    primary_id: "D0Y1UL",
    names: [
      "(E)-3-(4-Hydroxybenzylidene)Indolin-2-One",
      "CHEMBL103307",
      "3-(4-Hydroxybenzylidenyl)Indolin-2-One",
      "SCHEMBL16442341",
      "BDBM50131995",
      "ZINC12568108",
      "AKOS001309473",
      "3-(4-Hydroxybenzylidene)Indolin-2-One",
      "NCGC00343741-01",
      "ACM293302144",
      "AB00756566-01",
    ],
  },
  {
    primary_id: "D0Y1UO",
    names: [
      "VS-6063",
      "Defactinib Hydrochloride",
      "1073160-26-5",
      "Defactinib (Hydrochloride)",
      "UNII-L2S469LM49",
      "Defactinib Hydrochloride [USAN]",
      "L2S469LM49",
      "Defactinib Hydrochloride (USAN)",
      "Benzamide, N-Methyl-4-[[4-[[[3-[methyl(Methylsulfonyl)Amino]-2-Pyrazinyl]methyl]amino]-5-(Trifluoromethyl)-2-Pyrimidinyl]amino]-, Hydrochloride",
      "Defactinib HCl",
      "Benzamide, N-Methyl-4-((4-(((3-(Methyl(Methylsulfonyl)Amino)-2-Pyrazinyl)Methyl)Amino)-5-(Trifluoromethyl)-2-Pyrimidinyl)Amino)-, Hydrochloride (1:1)",
      "Benzamide, N-Methyl-4-[[4-[[[3-[methyl(Methylsu",
    ],
  },
  {
    primary_id: "D0Y1UV",
    names: [
      "1-(2,6-Dimethoxy-4-Methylphenyl)Propan-2-Amine",
      "CHEMBL293399",
      "80888-36-4",
      "Psi-DOM",
      "SCHEMBL2562975",
      "DTXSID20548454",
      "2,6-Dimethoxy-4-Methylamphetamine",
      "BDBM50115832",
      "2,6-Dimethoxy-Alpha,4-Dimethylbenzeneethanamine",
      "Benzeneethanamine, 2,6-Dimethoxy-Alpha,4-Dimethyl-",
    ],
  },
  {
    primary_id: "D0Y1VB",
    names: ["VU0152099", "3-Amino-N-(Benzo[d][1,3]dioxol-5-Ylmethyl)-4,6-Dimethylthieno[2,3-B]pyridine Carboxamide"],
  },
  {
    primary_id: "D0Y1VG",
    names: ["KNI-10369"],
  },
  {
    primary_id: "D0Y1VI",
    names: ["SJ-3249"],
  },
  {
    primary_id: "D0Y1VT",
    names: ["VEGF-2 Gene Therapy"],
  },
  {
    primary_id: "D0Y1VZ",
    names: ["GT-389255"],
  },
  {
    primary_id: "D0Y1WD",
    names: ["[D-Ncy(2-Naphthyl)1]acyline", "CHEMBL227861"],
  },
  {
    primary_id: "D0Y1WV",
    names: [
      "DCG IV",
      "Dcg-IV",
      "147782-19-2",
      "CHEMBL284193",
      "(1r,2r)-3-[(S)-Amino(Carboxy)Methyl]cyclopropane-1,2-Dicarboxylic Acid",
      "(1R,2R)-3-[(1S)-1-Amino-2-Hydroxy-2-Oxoethyl]cyclopropane-1,2-Dicarboxylic Acid",
      "DCGIV",
      "AC1NSJRH",
      "(2S,2'R,3'R)-2-(2',3'-Dicarboxycyclopropyl)Glycine",
      "SCHEMBL658075",
      "GTPL1377",
      "1,2-Cyclopropanedicarboxylicacid, 3-[(S)-Aminocarboxymethyl]-, (1R,2R)-",
      "CTK0H7426",
      "MATPZHBYOVDBLI-JJYYJPOSSA-N",
      "ZINC3995770",
      "BDBM50034503",
      "AKOS030230932",
      "API0015797",
      "B5039",
      "CS-0021174",
      "(2S,2''R,3''R)-2-(2'',3'')-Dicarboxycyclopropylglycine",
    ],
  },
  {
    primary_id: "D0Y1XD",
    names: ["ProCord", "Autologous Activated Macrophage Therapy"],
  },
  {
    primary_id: "D0Y1XM",
    names: ["2-(Benzyloxyamino)-N-Hydroxyhexanamide", "CHEMBL228230"],
  },
  {
    primary_id: "D0Y1XR",
    names: ["CY-403", "Antibacterials (S Aureus), CytoGenix"],
  },
  {
    primary_id: "D0Y1XS",
    names: [
      "3,4-Benzo-7-Acetonyloxy-8-Methylcoumarin",
      "4-Methyl-3-(2-Oxopropoxy)-6H-Benzo[c]chromen-6-One",
      "307551-49-1",
      "CHEMBL488072",
      "CHEMBRDG-BB 6146055",
      "CBMicro_001512",
      "AC1LDVAV",
      "ZINC83619",
      "MolPort-000-689-004",
      "ZX-CM016545",
      "SMSF0015505",
      "STL457381",
      "BDBM50246269",
      "AKOS002377781",
      "MCULE-6636508935",
      "CB03255",
      "ACM307551491",
      "AJ-10649",
      "BIM-0001590.P001",
      "W-9060",
      "4-Methyl-3-(2-Oxopropoxy)Benzo[c]chromen-6-One",
      "SR-01000229085",
    ],
  },
  {
    primary_id: "D0Y1XT",
    names: ["SC-44463"],
  },
  {
    primary_id: "D0Y1YA",
    names: ["Human Embryonic Stem Cell-Derived Hepatocytes"],
  },
  {
    primary_id: "D0Y1YP",
    names: ["DOQUALAST"],
  },
  {
    primary_id: "D0Y1YR",
    names: ["Gingerol", "[6]-Gingerol", "6-Gingerol"],
  },
  {
    primary_id: "D0Y1YX",
    names: ["AZD-9935"],
  },
  {
    primary_id: "D0Y1YZ",
    names: [
      "4-(2-Imidazol-1-Yl-Ethoxy)-Benzamide",
      "CHEMBL30395",
      "SCHEMBL3005716",
      "DVALIYTWWUIPGC-UHFFFAOYSA-N",
      "BDBM50025995",
      "75912-57-1",
      "1-[2-(4-Carbamoylphenoxy)Ethyl]imidazole",
      "4-(2-(1H-Imidazol-1-Yl)Ethoxy)Benzamide",
      "1-[2-(4-Carbamoyl Phenoxy)Ethyl]imidazole",
    ],
  },
  {
    primary_id: "D0Y1ZU",
    names: ["SA-237"],
  },
  {
    primary_id: "D0Y2AK",
    names: [
      "1-Phenyl-2-Pyridin-3-Yl-Propan-1-One",
      "CHEMBL53248",
      "62144-16-5",
      "Alpha-(3-Pyridyl)Propiophenone",
      "SCHEMBL4810062",
      "CTK2C6291",
      "DTXSID40463372",
      "BDBM50028159",
      "1-Phenyl-2-(3-Pyridinyl)-1-Propanone",
      "1-Propanone, 1-Phenyl-2-(3-Pyridinyl)-",
    ],
  },
  {
    primary_id: "D0Y2AM",
    names: ["(3-Bromo-Phenyl)-(5-Nitro-Quinazolin-4-Yl)-Amine"],
  },
  {
    primary_id: "D0Y2AO",
    names: [
      "AMINOTHIAZOLINE",
      "2-AMINOTHIAZOLE",
      "Aminothiazole",
      "96-50-4",
      "Thiazol-2-Amine",
      "2-Thiazolamine",
      "1,3-Thiazol-2-Amine",
      "2-Thiazolylamine",
      "Abadole",
      "Basedol",
      "Abadol",
      "2-Thiazylamine",
      "4-Thiazolin-2-Onimine",
      "2-Amino-1,3-Thiazole",
      "Aminothiazol",
      "Aminotiazol",
      "Aminothiazolum",
      "Thiazole, 2-Amino-",
      "2-Aminothiazol",
      "2-Amino Thiazole",
      "USAF EK-P-5501",
      "Aminothiazole [INN]",
      "Aminotiazolo [DCIT]",
      "Thiazolamine",
      "RP 2921",
      "Aminothiazol [INN-French]",
      "Aminotiazol [INN-Spanish]",
      "NSC 1900",
      "Aminothiazolum [INN-Latin]",
      "Thiazol-2-Ylamine",
      "UNII-5K8WKN668K",
      "CCRIS 1279",
      "2-Aminothiazole, 97%",
    ],
  },
  {
    primary_id: "D0Y2BD",
    names: ["1-Imidazol-1-Yl-3-(4-Octylphenoxy)Propan-2-One", "CHEMBL598974"],
  },
  {
    primary_id: "D0Y2BL",
    names: [
      "7-(2-Amino-Ethyl)-4-Hydroxy-3H-Benzothiazol-2-One",
      "CHEMBL17357",
      "108773-04-2",
      "SCHEMBL4740762",
      "2(3H)-BENZOTHIAZOLONE,7-(2-AMINOETHYL)-4-HYDROXY-",
      "ZINC26894070",
      "BDBM50020679",
      "AKOS027394867",
      "KB-293910",
      "7-(2-Aminoethyl)-1,3-Benzothiazole-2,4-Diol",
      "2(3H)-Benzothiazolone, 7-(2-Aminoethyl)-4-Hydroxy-",
    ],
  },
  {
    primary_id: "D0Y2CJ",
    names: [
      "Minoxidil",
      "Alopexil",
      "Alostil",
      "Loniten",
      "Lonolox",
      "Minodyl",
      "Minossidile",
      "Minoxidilum",
      "Minoxigaine",
      "Minoximen",
      "Neoxidil",
      "Normoxidil",
      "Pierminox",
      "Prexidil",
      "Regaine",
      "RiUP",
      "Rogaine",
      "Theroxidil",
      "Tricoxidil",
      "Trocoxidil",
      "MINOXIDIL EXTRA STRENGTH FOR MEN",
      "Men S Rogaine Foam",
      "Mens Rogaine Foam",
      "Minossidile [Italian]",
      "Minoxidil Pfizer Brand",
      "PfizerBrand Of Minoxidil",
      "Rogaine Extra Strength For Men",
      "Rogaine For Men",
      "Rogaine For Women",
      "M 4145",
      "M1389",
      "U 10858",
      "Apo-Gain",
      "Gen-Minoxidil",
      "Loniten (TN)",
      "MINOXIDIL (FOR MEN)",
      "MINOXIDIL (FOR WOMEN)",
      "MINOXIDIL EXTRA STRENGTH (FOR MEN)",
      "Men's Rogaine",
      "Minoxidilum [INN-Latin]",
      "ROGAINE (FOR MEN)",
      "ROGAINE (FOR WOMEN)",
      "ROGAINE EXTRA STRENGTH (FOR MEN)",
      "Regaine (TN)",
      "Riup (TN)",
      "Rogaine (TN)",
      "TM-160",
      "U-10858",
      "Minoxidil (USP/INN)",
      "Minoxidil [USAN:BAN:INN]",
      "U-10,858",
      "Rogaine, Regaine, Avacor And Mintop, Minoxidil",
      "Pyrimidine, 2,4-Diamino-6-Piperidino-, 3-Oxide",
      "2,3-Dihydro-3-Hydroxy-2-Imino-6-(1-Piperidinyl)-4-Pyrimidinamine",
      "2,4-Diamino-6-Piperidinilpirimidina-3-Ossido",
      "2,4-Diamino-6-Piperidinilpirimidina-3-Ossido [Italian]",
      "2,4-Diamino-6-Piperidino-Pyrimidine-3-Oxide",
      "2,4-Diamino-6-Piperidinopyrimidine 3-N-Oxide",
      "2,4-Diamino-6-Piperidinopyrimidine 3-Oxide",
      "2,4-Pyrimidinediamine, 6-(1-Piperidinyl)-, 3-Oxide",
      "2,6-Diamino-4-Piperidinopyrimidin-1-Oxid",
      "3-Hydroxy-2-Imino-6-(1-Piperidyl)Pyrimidin-4-Amine",
      "3-Hydroxy-2-Imino-6-Piperidin-1-Ylpyrimidin-4-Amine",
      "6-(1-Piperidinyl)-2,4-Pyrimidinediamine 3-Oxide",
      "6-(Piperidin-1-Yl)Pyrimidine-2,4-Diamine 3-Oxide",
      "6-Amino-1,2-Dihydro-1-Hydroxy-2-Imino-4-Piperidinopyrimidine",
      "6-Piperidino-2,4-Diaminopyrimidine 3-Oxide",
      "6-[1-Piperidinyl]pyrimidine-2,4-Diamine 3 Oxide",
    ],
  },
  {
    primary_id: "D0Y2DI",
    names: [
      "Chlorproguanil",
      "Chlorproguanilum",
      "Clorpreguanile",
      "Clorproguanil",
      "Lapudrine",
      "Clorpreguanile [DCIT]",
      "Unspecified HCl Of Chlorproguanil",
      "Chlorproguanil [INN:BAN]",
      "Chlorproguanilum [INN-Latin]",
      "Clorproguanil [INN-Spanish]",
      "N-(3,4-Dichlorophenyl)-N'-(1-Methylethyl)Imidodicarbonimidic Diamide",
      "1-(3,4-Dichlorophenyl)-5-Isopropylbiguanide",
    ],
  },
  {
    primary_id: "D0Y2DW",
    names: ["CHAP31"],
  },
  {
    primary_id: "D0Y2EN",
    names: ["IB-09A133"],
  },
  {
    primary_id: "D0Y2ES",
    names: ["Bepafant"],
  },
  {
    primary_id: "D0Y2FF",
    names: ["1-Thiazol-2-Yl-3-(2-O-Tolyl-Ethyl)-Thiourea"],
  },
  {
    primary_id: "D0Y2FY",
    names: ["Pediarix Vaccine"],
  },
  {
    primary_id: "D0Y2GE",
    names: ["3-Cyano-5-Fluoro-N-(Pyridin-2-Yl)Benzamide", "CHEMBL1209204"],
  },
  {
    primary_id: "D0Y2GJ",
    names: ["DSP-1053"],
  },
  {
    primary_id: "D0Y2HA",
    names: ["Pramiconazole", "Azoline", "Antifungals, Janssen Research Foundation", "R-126638"],
  },
  {
    primary_id: "D0Y2HH",
    names: ["KC706"],
  },
  {
    primary_id: "D0Y2HI",
    names: ["H-Dmt-Aba-Gly-NH-Ph", "CHEMBL377392"],
  },
  {
    primary_id: "D0Y2HR",
    names: [
      "Astemizole",
      "Alermizol",
      "Astemina",
      "Astemisan",
      "Astemisol",
      "Astemison",
      "Astemizol",
      "Astemizolum",
      "Astesen",
      "Emdar",
      "Esmacen",
      "Fustermizol",
      "Hestazol",
      "Hismanal",
      "Histamen",
      "Histaminos",
      "Histazol",
      "Hubermizol",
      "Kelp",
      "Laridal",
      "Metodih",
      "Metodik",
      "Paralergin",
      "Retolen",
      "Rifedot",
      "Rimbol",
      "Romadin",
      "Simprox",
      "Urdrim",
      "Wareezol",
      "Waruzol",
      "AlacanBrand Of Astemizole",
      "Alonga Brand Of Astemizole",
      "Astemizol Alonga",
      "Astemizol [German]",
      "Astemizol Ratiopharm",
      "Astemizole Alacan Brand",
      "Astemizole Alonga Brand",
      "Astemizole Byk Brand",
      "Astemizole Diba Brand",
      "Astemizole Elfar Brand",
      "Astemizole Esteve Brand",
      "Astemizole Fustery Brand",
      "Astemizole ICN Brand",
      "Astemizole Janssen Brand",
      "Astemizole Lesvi Brand",
      "Astemizole McNeil Brand",
      "Astemizole Medinsa Brand",
      "Astemizole Merck Brand",
      "Astemizole Senosiain Brand",
      "Astemizole Septa Brand",
      "Astemizole Smaller Brand",
      "Astemizole Urbion Brand",
      "Astemizole Vita Brand",
      "Astemizole Ratiopharm Brand",
      "Byk Brand Of Astemizole",
      "Diba Brand Of Astemizole",
      "Elfar Brand Of Astemizole",
      "Esteve Brand Of Astemizole",
      "Fustery Brand Of Astemizole",
      "ICN Brand Of Astemizole",
      "Janssen Brand Of Astemizole",
      "Lesvi Brand Of Astemizole",
      "McNeil Brand Of Astemizole",
      "Medinsa Brand Of Astemizole",
      "Merck Brand Of Astemizole",
      "Ratiopharm Brand Of Astemizole",
      "Reig Jofre Brand Of Astemizole",
      "Senosiain Brand Of Astemizole",
      "Septa Brand Of Astemizole",
      "Smaller Brand Of Astemizole",
      "Urbion Brand Of Astemizole",
      "Vita Brand Of Astemizole",
      "R 42512",
      "[3H]Astemizole",
      "Alonga, Astemizol",
      "Astemizol [INN-Spanish]",
      "Astemizolum [INN-Latin]",
      "HISMANAL (TN)",
      "Hismanal (TN)",
      "MJD-30",
      "Nono-Nastizol A",
      "Novo-Mastizol A",
      "Ratiopharm, Astemizol",
      "Astemizole [USAN:BAN:INN]",
      "GNF-PF-2461",
      "Astemizole (JAN/USP/INN)",
      "Hestazol, Kelp, Laridal, Retolen, Wareezol, HSBD 6799, BRN 4830190",
      "1-(P-Fluorobenzyl)-2-((1-(P-Methoxyphenethyl)-4-Piperidyl)Amino)Benzimidazole",
    ],
  },
  {
    primary_id: "D0Y2IE",
    names: [
      "Cefuroxime",
      "Anaptivan",
      "Biociclin",
      "Biofuroksym",
      "Bioxima",
      "CXM",
      "Cefofix",
      "Cefumax",
      "Cefurex",
      "Cefuril",
      "Cefuroxim",
      "Cefuroximesodium",
      "Cefuroximine",
      "Cefuroximo",
      "Cefuroximum",
      "Cephuroxime",
      "Cetroxil",
      "Colifossim",
      "Curocef",
      "Curoxim",
      "Curoxima",
      "Curoxime",
      "Froxal",
      "Furoxil",
      "Kesint",
      "Ketocef",
      "Lifurox",
      "Medoxim",
      "Sharox",
      "Spectrazolr",
      "Ultroxim",
      "Zinacef;CEFUROXIME AND DEXTROSE IN DUPLEX CONTAINER",
      "CEFUROXIME SODIUM",
      "Cefuroxim AJ",
      "Cefuroxim Fresenius",
      "Cefuroxim Genericsn",
      "Cefuroxim Hexal",
      "Cefuroxim Lilly",
      "Cefuroxim MN",
      "Cefuroxim Norcox",
      "Cefuroxim Curasan",
      "Cefuroxima Fabra",
      "Cefuroxima Richet",
      "Cefuroxime For Injection And Dextrose For Injection In Duplex Container",
      "Cefuroxime Na",
      "Cefuroxime Sodium Salt",
      "KEFUROX IN PLASTIC CONTAINER",
      "Sodium Cefuroxime",
      "ZINACEF IN PLASTIC CONTAINER",
      "Zinacef Danmark",
      "Ceftin (TN)",
      "Cefuroxim Norcox [inj.]",
      "Cefuroxime (TN)",
      "Cefuroximo [INN-Spanish]",
      "Cefuroximum [INN-Latin]",
      "Cetroxil [inj.]",
      "Froxal [inj.]",
      "KS-1040",
      "Sharox [inj.]",
      "Zinacef (TN)",
      "Zinnat (TN)",
      "Zinnat [inj.]",
      "Cefuroxime (USAN/INN)",
      "Cefuroxime [USAN:INN:BAN]",
      "Cefuroxime Sodium (JP15/USP)",
      "Cefuroxime Sodium [USAN:BAN:JAN]",
      "Sodium (6R-(6alpha,7beta(Z)))-3-(((Aminocarbonyl)Oxy)Methyl)-7-(2-Furyl(Methoxyimino)Acetamido)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylate",
      "Sodium (6R,7R)-7-(2-(2-Furyl)Glyoxylamido)-3-(Hydroxymethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylate, 7(Sup 2)-(Z)-(O-Methyloxime), Carbamate (Ester)",
      "(6R,7R)-3-(Carbamoyloxymethyl)-7-[[(2Z)-2-(Furan-2-Yl)-2-Methoxyiminoacetyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-[(Carbamoyloxy)Methyl]-7-[(2Z)-2-(Furan-2-Yl)-2-(Methoxyimino)Acetamido]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-3-[(Carbamoyloxy)Methyl]-7-{[(2Z)-2-Furan-2-Yl-2-(Methoxyimino)Acetyl]amino}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-(2-(2-Furyl)Glyoxylamido)-3-(Hydroxymethyl)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Ene-2-Carboxylic Acid 7(Sup 2)-(Z)-(O-Methyloxime) Carbamate (Ester)",
      "3-[(Carbamoyloxy)Methyl]-7beta-[(2Z)-2-(Furan-2-Yl)-2-(Methoxyimino)Acetamido]-3,4-Didehydrocepham-4-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0Y2IF",
    names: ["AZD-6357"],
  },
  {
    primary_id: "D0Y2IH",
    names: ["LY-255283"],
  },
  {
    primary_id: "D0Y2IM",
    names: ["N-(Cyanomethyl)-4-(2-(Phenylamino)Pyrimidin-4-Yl)Benzamide Derivative 1"],
  },
  {
    primary_id: "D0Y2IN",
    names: ["DORANIDAZOLE"],
  },
  {
    primary_id: "D0Y2IP",
    names: [
      "HLA-B7.75-84",
      "Allotrap-07",
      "Arginyl-Glutamyl-Seryl-Leucyl-Arginyl-Asparaginyl-Leucyl-Arginyl-Glycyl-Tyrosine",
    ],
  },
  {
    primary_id: "D0Y2IV",
    names: ["Antibodie Derivative 4"],
  },
  {
    primary_id: "D0Y2IZ",
    names: [
      "Trichlormethiazide",
      "Achletin",
      "Anistadin",
      "Aponorin",
      "Aquazide",
      "Carvacron",
      "Chlopolidine",
      "Cretonin",
      "Diurazida",
      "Diurese",
      "Diuroral;Esmarin",
      "Eurinol",
      "Fluitran",
      "Flurese",
      "Flutra",
      "Gangesol",
      "Hydrotrichlorothiazide",
      "Intromene",
      "Isestran",
      "Kubacron",
      "Metahydrin",
      "Metatensin",
      "Nakva",
      "Naqua",
      "Naquasone",
      "Schebitran",
      "Tachionin",
      "Tolcasone",
      "Trichlordiuride",
      "Trichlorex",
      "Trichlormas",
      "Trichlormetazid",
      "Trichlormethiazid",
      "Trichlormethiazidum",
      "Trichloromethiadiazide",
      "Trichloromethiazide",
      "Triclordiuride",
      "Triclormetiazida",
      "Triclormetiazide",
      "Triflumen;American Urologicals Brand Of Trichloromethiazide",
      "Jones Brand Of Trichloromethiazide",
      "Schering Brand Of Trichlormethiazide",
      "Triclormetiazide [DCIT]",
      "Triclormetiazide [Italian]",
      "Achletin (TN)",
      "Ciba 7057-Su",
      "Diu-Hydrin",
      "Flurese (VAN)",
      "Naqua (TN)",
      "Salurin (Wadel)",
      "Trichlormethiazide W/ Reserpine",
      "Trichlormethiazide [INN:JAN]",
      "Trichlormethiazidum [INN-Latin]",
      "Trichloromethiazide, 6",
      "Triclormetiazida[INN-Spanish]",
      "Triflumen (TN)",
      "Diu-Hydrin (TN)",
      "Trichlormethiazide (JP15/USP/INN)",
      "(+-)-6-Chloro-3-(Dichloromethyl)-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3-(Dichloromethyl)-3,4-Dihydro-, 1,1-Dioxide",
      "2H-1,2,4-Benzothiadiazine-7-Sulfonamide, 6-Chloro-3-(Dichloromethyl)-3,4-Dihydro-,1,1-Dioxide",
      "3-(Dichloromethyl)-6-Chloro-7-Sulfamoyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "3-(Dichloromethyl)-6-Chloro-7-Sulfamoyl-3,4-Dihydro-1,2,4-Benzothiadiazine-1,1-Dioxide",
      "3-Dichloromethyl-6-Chloro-7-Sulfamoyl-3,4-Dihydro-1,2,4-Benzothiadiazine-1,1-Dioxide",
      "3-Dichloromethyl-6-Chloro-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine 1,1-Dioxide",
      "3-Dichloromethyl-6-Chloro-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine1,1-Dioxide",
      "3-Dichloromethylhydrochlorothiazide",
      "6-Chloro-3-(Dichloromethyl)-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3-(Dichloromethyl)-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide1,1-Dioxide",
      "6-Chloro-3-(Dichloromethyl)3,4-Dihydro-7-Sulfamoyl-1,2,4-Benzothiadiazine-1,1-Dioxide",
      "6-Chloro-3-(Dichloromethyl)3,4-Dihydro-7-Sulfamyl-1,2,4-Benzothiadiazine-1,1-Dioxide",
      "6-Chloro-3-[dichloromethyl]-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide-1,1-Dioxide",
      "6-Chloro-3-Dichloromethyl-3,4-Dihydro-2H-1,2,4-Benzothiadiazine-7-Sulfonamide 1,1-Dioxide",
      "6-Chloro-3-Dichloromethyl-7-Sulfamyl-3,4-Dihydro-1,2,4-Benzothiadiazine1,1-Dioxide",
    ],
  },
  {
    primary_id: "D0Y2JI",
    names: ["Xylazine", "Bayer 1470", "Narcoxyl", "Rompun", "Xylapan", "Xylasol", "Xylzin", "BAY-VA-1470"],
  },
  {
    primary_id: "D0Y2JM",
    names: ["Soluble Ferric Pyrophosphate"],
  },
  {
    primary_id: "D0Y2JY",
    names: ["CIFEA", "Cyclohexylmethyl-Indenofurane-Ethylacetamide"],
  },
  {
    primary_id: "D0Y2KX",
    names: ["COL-1777"],
  },
  {
    primary_id: "D0Y2LJ",
    names: ["8-(2-Methylpropyl)Naringenin"],
  },
  {
    primary_id: "D0Y2LR",
    names: [
      "Doxylamine",
      "Dossilamina",
      "Doxilamina",
      "Doxilminio",
      "Doxylaminum",
      "Dossilamina [DCIT]",
      "Diclectin (TN)",
      "Dolased (TN)",
      "Donormyl (TN)",
      "Dormidina (TN)",
      "Doxilminio [INN-Spanish]",
      "Doxylamine (INN)",
      "Doxylamine [INN:BAN]",
      "Doxylaminum [INN-Latin]",
      "Dozile (TN)",
      "Evanorm (TN)",
      "Mersyndol (TN)",
      "Restavit (TN)",
      "Somnil (TN)",
      "Syndol (TN)",
      "Unisom-2 (TN)",
      "Phenyl-2-Pyridylmethyl-Beta-N,N-Dimethylaminoethyl Ether",
      "N,N-Dimethyl-2-(1-Phenyl-1-(2-Pyridinyl)Ethoxy)Ethanamine",
      "N,N-Dimethyl-2-(1-Phenyl-1-Pyridin-2-Ylethoxy)Ethanamine",
      "N,N-Dimethyl-2-[(1-Phenyl-1-Pyridin-2-Ylethyl)Oxy]ethanamine",
      "N,N-Dimethyl-2-[1-Phenyl-1-(Pyridin-2-Yl)Ethoxy]ethanamine",
      "2-(Alpha-(2-(Dimethylamino)Ethoxy)-Alpha-Methylbenzyl)Pyridine",
      "2-Dimethylaminoethoxyphenylmethyl-2-Picoline",
    ],
  },
  {
    primary_id: "D0Y2MC",
    names: ["Alkavervir"],
  },
  {
    primary_id: "D0Y2MP",
    names: [
      "E-5110",
      "CHEMBL12594",
      "E 5110",
      "N-Methoxy-3-(3,5-Di-Tert-Butyl-4-Hydroxybenzylidene)-2-Pyrrolidone",
      "3-((3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl)Methylene)-1-Methoxy-2-Pyrrolidinone",
      "2-Pyrrolidinone, 3-((3,5-Bis(1,1-Dimethylethyl)-4-Hydroxyphenyl)Methylene)-1-Methoxy-",
      "107746-52-1",
      "AC1O5P5J",
      "SCHEMBL5323330",
      "BDBM50008954",
      "LS-138638",
      "3-(3,5-Di-Tert-Butyl-4-Hydroxy-Benzylidene)-1-Methoxy-Pyrrolidin-2-One",
      "N-Methoxy-3-(3,5-Di-Tert-Butyl-4-Hydroxybenzylidene)Pyrrolidin-2-One",
    ],
  },
  {
    primary_id: "D0Y2MR",
    names: ["F-G-G-F-T-G-Aib-R-K-S-Aib-R-K-L-A-N-Q-CONH2"],
  },
  {
    primary_id: "D0Y2MT",
    names: ["Ac-RYYRIK-K-(NH2)-YAFGYPS", "CHEMBL404792"],
  },
  {
    primary_id: "D0Y2NA",
    names: ["[D-Gln3,Ncy(SO2,isopropyl)7]acyline", "CHEMBL373724"],
  },
  {
    primary_id: "D0Y2NE",
    names: [
      "Diethylstilbestrol",
      "Acnestrol",
      "Agostilben",
      "Antigestil",
      "Apstil",
      "Bufon",
      "Climaterine",
      "Comestrol",
      "Cyren",
      "DES",
      "Desma",
      "Destrol",
      "Diaethylstilboestrolum",
      "Diastyl",
      "Dibestrol",
      "Dicorvin",
      "Diethylstilbesterol",
      "Diethylstilbestrolum",
      "Diethylstilboesterol",
      "Dietilestilbestrol",
      "Dietilstilbestrolo",
      "Distilbene",
      "Domestrol",
      "Dyestrol",
      "Estril",
      "Estrobene",
      "Estrogenine",
      "Estromenin",
      "Estrosyn",
      "Fonatol",
      "Grafestrol",
      "Gynopharm",
      "Hibestrol",
      "Idroestril",
      "Iscovesco",
      "Makarol",
      "Menostilbeen",
      "Micrest",
      "Microest",
      "Milestrol",
      "OeKolp",
      "Oestrogenine",
      "Oestrolmensil",
      "Oestromenin",
      "Oestromensil",
      "Oestromensyl",
      "Oestromienin",
      "Oestromon",
      "Pabestrol",
      "Palestrol",
      "Protectona",
      "Sedestran",
      "Serral",
      "Sexocretin",
      "Sibol",
      "Sintestrol",
      "Stibilium",
      "Stilbestroform",
      "Stilbestrol",
      "Stilbestrone",
      "Stilbetin",
      "Stilboefral",
      "Stilboestroform",
      "Stilboestrol",
      "Stilbofolin",
      "Stilbofollin",
      "Stilbol",
      "Stilkap",
      "Strobene",
      "Synestrin",
      "Synthestrin",
      "Synthoestrin",
      "Synthofolin",
      "Syntofolin",
      "Tampovagan",
      "Tylosterone",
      "Vagestrol",
      "APS Brand Of Diethylstilbestrol",
      "Anti Gestil",
      "Co Pharma Brand Of Diethylstilbestrol",
      "Comestrol Estrobene",
      "Cyren A",
      "Diethylstilbestrol BP",
      "Dietilestilbestrol [Spanish]",
      "Dietilstilbestrolo [DCIT]",
      "Estilbin MCO",
      "Gerda Brand Of Diethylstilbestrol",
      "Oestrol Vetag",
      "Percutatrine Oestrogenique Iscovesco",
      "ST IL",
      "Stilbene Estrogen",
      "Tampovagan Stilboestrol",
      "DiBestrol 2 Premix",
      "MG 137",
      "Rumestrol 1",
      "Rumestrol 2",
      "Cis-Des",
      "Cis-Diethylstilbesterol",
      "Cis-Diethylstilbestrol",
      "Co-Pharma Brand Of Diethylstilbestrol",
      "DES (Synthetic Estrogen)",
      "Dawe's Destrol",
      "Di-Estryl",
      "Diethylstilbestrol (DES)",
      "Diethylstilbestrol [USAN:INN]",
      "Diethylstilbestrol, Disodium Salt",
      "Diethylstilbestrolum [INN-Latin]",
      "Dietilestilbestrol [INN-Spanish]",
      "E-Diethylstilbestrol",
      "Estilbin (MCO)",
      "Estrogen, Stilbene",
      "Hi-Bestrol",
      "Neo-Oestranol 1",
      "Neo-Oestranol I",
      "Neo-Oe Stranol 1",
      "New-Estranol 1",
      "Stil-Rol",
      "Stilbestrol (TN)",
      "TRANS-DIETHYSTILBESTEROL",
      "TRANSGENIC MODEL EVALUATION (DES)",
      "Trans-Diethylstilbesterol",
      "Trans-Diethylstilbestrol",
      "Trans-Diethylstilboesterol",
      'DiBestrol "2" Premix',
      "Dibestrol '2' Premix",
      "Diethylstilbestrol (USP/INN)",
      "Alpha,alpha'-Diethylstilbenediol",
      "Diethylstilbestrol, (Z)-Isomer",
      "Alpha,alpha'-Diethyl-4,4'-Stilbenediol",
      "(E)-3,4-Bis(4-Hydroxyphenyl)-3-Hexene",
      "(E)-4,4'-(Hex-3-Ene-3,4-Diyl)Diphenol",
      "(E)-Diethylstilbestrol",
      "3,4'(4,4'-Dihydroxyphenyl)Hex-3-Ene",
      "3,4-Bis(P-Hydroxyphenyl)-3-Hexene",
      "3,4-Bis(4-Hydroxyphenyl)Hex-3-Ene",
      "4,4'-(3E)-Hex-3-Ene-3,4-Diyldiphenol",
      "4,4'-Dihydroxydiethylstilbene",
      "4,4'-Hex-3-Ene-3,4-Diyldiphenol",
    ],
  },
  {
    primary_id: "D0Y2NN",
    names: ["Replication-Defective HIV-1 Vaccine"],
  },
  {
    primary_id: "D0Y2NR",
    names: ["Bicyclo-Heptan-2-Amine Derivative 4"],
  },
  {
    primary_id: "D0Y2NS",
    names: ["MLN1117"],
  },
  {
    primary_id: "D0Y2NU",
    names: ["ALX-40-4C", "AV-9"],
  },
  {
    primary_id: "D0Y2OB",
    names: [
      "Benzofuran-2-Yl(Indolin-1-Yl)Methanone",
      "CHEMBL496155",
      "AC1LFLO9",
      "Oprea1_781741",
      "SCHEMBL2757847",
      "MolPort-002-146-725",
      "ZINC272979",
      "BDBM50265522",
      "ZINC 00272979",
      "AKOS001474069",
      "MCULE-1146733513",
      "SR-01000206937",
      "1-Benzofuran-2-Yl(2,3-Dihydroindol-1-Yl)Methanone",
    ],
  },
  {
    primary_id: "D0Y2OG",
    names: ["YM-511"],
  },
  {
    primary_id: "D0Y2PO",
    names: ["Tri-Substituted Purine Derivative 1"],
  },
  {
    primary_id: "D0Y2PQ",
    names: ["Corticorelin Ovine Triflutate", "Acthrel"],
  },
  {
    primary_id: "D0Y2QO",
    names: ["PMID28870136-Compound-62"],
  },
  {
    primary_id: "D0Y2RF",
    names: ["Lnfluvac TC"],
  },
  {
    primary_id: "D0Y2RL",
    names: [
      "4-[5-(3-Hydroxyphenyl)-2-Thienyl)-2-Methyl]phenol",
      "1122660-25-6",
      "4-(5-(3-Hydroxyphenyl)Thiophen-2-Yl)-2-Methylphenol",
      "3-(5-(4-Hydroxy-3-Methylphenyl)Thiophen-2-Yl)Phenol",
      "CHEMBL570596",
      "AK160974",
      "3-(5-(4-Hydroxy-3-Methylphenyl)-Thiophen-2-Yl)Phenol",
      "SCHEMBL1180399",
      "MolPort-028-616-334",
      "ZINC45254284",
      "BDBM50299633",
      "AKOS024463871",
      "DS-9818",
      "QC-10086",
      "AJ-110394",
      "AX8293985",
    ],
  },
  {
    primary_id: "D0Y2RM",
    names: [
      "ISOGUANOSINE",
      "2-Hydroxyadenosine",
      "Crotonoside",
      "Isoguanosine",
      "1818-71-9",
      "Crotonosid",
      "Isoguanine Riboside",
      "Adenosine, 1,2-Dihydro-2-Oxo-",
      "1,2-Dihydro-2-Oxoadenosine",
      "Isoguanosine (7CI,8CI)",
      "Isoguanine, 9-Beta-D-Ribofuranosyl-",
      "NSC 12161",
      "CHEBI:3927",
      "9H-Purin-2-Ol, 6-Amino-9-Beta-D-Ribofuranosyl-",
      "6-Amino-9-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Tetrahydrofuran-2-Yl]-1H-Purin-2-One",
      "Isoguanosin",
      "CCRIS 8240",
      "Isoguanineriboside",
      "Isoguanine, 9-.beta.-D-Ribofuranosyl-",
      "Isoguanosine (VAN)",
      "2-Oxo-3-Hydroadenosine",
      "2-Oxoado (Isoguanosine)",
    ],
  },
  {
    primary_id: "D0Y2RN",
    names: ["PMID25553724-Compound-US2011788838110"],
  },
  {
    primary_id: "D0Y2RR",
    names: [
      "1,3-Bis(3,4-Dihydroxyphenyl)Prop-2-En-1-One",
      "CHEMBL225618",
      "3,3',4,4'-Tetrahydroxychalcone",
      "SCHEMBL109015",
      "BDBM50157562",
      "3,3'',4,4''-Tetrahydroxychalcone",
      "ZINC13541162",
    ],
  },
  {
    primary_id: "D0Y2SE",
    names: ["Alpha-Glucosidase"],
  },
  {
    primary_id: "D0Y2SW",
    names: ["Ixazomib"],
  },
  {
    primary_id: "D0Y2TE",
    names: ["4-[4-(Benzyloxy)Piperidino]butyl-4-Nitrobenzoate", "CHEMBL228301", "SCHEMBL4025407"],
  },
  {
    primary_id: "D0Y2TI",
    names: ["Glionitrin A"],
  },
  {
    primary_id: "D0Y2TK",
    names: ["Tetanus Toxoid"],
  },
  {
    primary_id: "D0Y2UE",
    names: ["APL-2 Subcutaneous"],
  },
  {
    primary_id: "D0Y2UR",
    names: ["Methylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL486290", "SCHEMBL12133520", "BDBM50270688"],
  },
  {
    primary_id: "D0Y2VA",
    names: ["Nefecon"],
  },
  {
    primary_id: "D0Y2WP",
    names: [
      "OTS964",
      "(R)-9-(4-(1-(Dimethylamino)Propan-2-Yl)Phenyl)-8-Hydroxy-6-Methylthieno[2,3-C]quinolin-4(5H)-One",
      "1338545-07-5",
      "SCHEMBL2548561",
      "GTPL7813",
      "CHEMBL3672369",
      "MolPort-044-561-385",
      "ZINC149881647",
      "SB19795",
      "NCGC00484799-01",
      "9-{4-[(2R)-1-(Dimethylamino)Propan-2-Yl]phenyl}-8-Hydroxy-6-Methyl-4H,5H-Thieno[2,3-C]quinolin-4-One",
    ],
  },
  {
    primary_id: "D0Y2XQ",
    names: ["PF-3526299"],
  },
  {
    primary_id: "D0Y2XY",
    names: ["12R-HETE", "12r-HETE"],
  },
  {
    primary_id: "D0Y2XZ",
    names: ["MK-4166"],
  },
  {
    primary_id: "D0Y2YH",
    names: ["(+/-)-Threo-N-Benzyl-3',4'-Dichlororitalinol", "CHEMBL1254675", "BDBM50327175"],
  },
  {
    primary_id: "D0Y2YP",
    names: [
      "Flurandrenolide",
      "Cordran",
      "Drenison",
      "Drocort",
      "Floudroxycortide",
      "Fludrossicortide",
      "Fludroxicortida",
      "Fludroxicortidum",
      "Fludroxycortide",
      "Fludroxycortidum",
      "Fluorandrenolone",
      "Fluradrenolide",
      "Flurandrenolone",
      "Haelan",
      "Sermaka",
      "CORDRAN SP",
      "Fludrossicortide [DCIT]",
      "Fluorandrenolone Acetonide",
      "Flurandrenolide [USAN]",
      "Flurandrenolone Acetonide",
      "L 33379",
      "Alondra-F",
      "CORDRAN (TN)",
      "Cordran Tape (TN)",
      "Fludroxicortida [INN-Spanish]",
      "Fludroxycortidum [INN-Latin]",
      "Flurandrenolide (USP)",
      "Haldrone-F",
      "Fludroxycortide (JAN/INN)",
      "Acetonide Of 6alpha-Fluoro-16alpha-Hydroxyhydrocortisone",
      "Pregn-4-Ene-3,20-Dione, 6alpha-Fluoro-11beta,16alpha,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone",
      "Pregn-4-Ene-3,20-Dione, 6-Alpha-Fluoro-11-Beta,16-Alpha,17,21-Tetrahydroxy-, Cyclic 16,17-Acetal With Acetone",
      "6alpha-Fluoro-11beta,16alpha,17,21-Tetrahydroxypregn-4-Ene-3,20-Dione, Cyclic 16,17-Acetal With Acetone",
      "6alpha-Fluoro-11beta,16alpha,17,21-Tetrahydroxyprogesterone Cyclic 16,17-Acetal With Acetone",
      "6alpha-Fluoro-16alpha-Hydroxyhydrocortisone 16,17-Acetonide",
    ],
  },
  {
    primary_id: "D0Y2YV",
    names: ["BECIPARCIL", "Beciparcil", "4-(5-Thio-Beta-D-Xylopyranosylthio)Benzonitrile"],
  },
  {
    primary_id: "D0Y2ZC",
    names: [
      "ND-2110",
      "CHEMBL3361254",
      "2-[(3~{R})-12-(4-Morpholin-4-Ylcyclohexyl)Oxy-7-Thia-9,11-Diazatricyclo[6.4.0.0^{2,6}]dodeca-1(8),2(6),9,11-Tetraen-3-Yl]ethanamide",
      "GTPL8082",
      "SCHEMBL15228032",
      "SCHEMBL10344171",
      "BDBM50040806",
      "ZINC299825077",
      "2-[(3R)-12-{[(1r,4r)-4-(Morpholin-4-Yl)Cyclohexyl]oxy}-7-Thia-9,11-Diazatricyclo[6.4.0.0(2),]dodeca-1(12),2(6),8,10-Tetraen-3-Yl]acetamide",
    ],
  },
  {
    primary_id: "D0Y2ZN",
    names: ["Oral Azacitidine"],
  },
  {
    primary_id: "D0Y2ZS",
    names: ["PMID27607364-Compound-63"],
  },
  {
    primary_id: "D0Y3AG",
    names: ["VIR-576"],
  },
  {
    primary_id: "D0Y3AR",
    names: [
      "RhASM Enzyme Replacement Therapy (Acid Sphingomyelinase Deficiency)",
      "Deficiency",
      "RhASM Enzyme Replacement Therapy (Acid Sphingomyelinase Deficiency (Niemann-Pick Disease)), Genzyme",
    ],
  },
  {
    primary_id: "D0Y3AV",
    names: ["3-Ethoxy-5-(6-Methoxynaphthalen-2-Yl)Pyridine", "CHEMBL445259"],
  },
  {
    primary_id: "D0Y3AZ",
    names: ["MK-2637"],
  },
  {
    primary_id: "D0Y3BI",
    names: ["2-(Aminomethyl)-5-(1'-Naphthyl)Tetrahydrofuran", "CHEMBL456638", "SCHEMBL2689095", "BDBM50276458"],
  },
  {
    primary_id: "D0Y3BU",
    names: ["VAK-694"],
  },
  {
    primary_id: "D0Y3DN",
    names: ["Flavonoid Derivative 4"],
  },
  {
    primary_id: "D0Y3DR",
    names: [
      "1-(1-Benzo[b]thiophen-2-Yl-Cyclopentyl)-Azepane",
      "CHEMBL18025",
      "AC1LCXWM",
      "XZXHOFZYYWGLCJ-UHFFFAOYSA-N",
      "BDBM50040198",
      "1-[1-(1-Benzothien-2-Yl)Cyclopentyl]azepane #",
      "1-[1-(1-Benzothiophen-2-Yl)Cyclopentyl]azepane",
      "Thionaphthene, 2-[1-(1-Azacycloheptyl)Cyclopentyl]-",
    ],
  },
  {
    primary_id: "D0Y3DW",
    names: ["[3H]cytisine", "[3H]CYTISINE"],
  },
  {
    primary_id: "D0Y3EG",
    names: ["3-MATIDA", "Alpha-Amino-5-Carboxy-3-Methyl-2-Thiopheneacetic Acid"],
  },
  {
    primary_id: "D0Y3HP",
    names: ["CD20 CAR T Cells"],
  },
  {
    primary_id: "D0Y3JI",
    names: ["DG-17", "DG-35", "Nar DG-35 Prodrug", "DG-35-VIII"],
  },
  {
    primary_id: "D0Y3JR",
    names: ["ISIS 16507"],
  },
  {
    primary_id: "D0Y3KG",
    names: [
      "Valproate",
      "99-66-1",
      "Dipropylacetic Acid",
      "Depakine",
      "Depakene",
      "2-Propylvaleric Acid",
      "Ergenyl",
      "Di-N-Propylacetic Acid",
      "Mylproin",
      "Pentanoic Acid, 2-Propyl-",
      "4-Heptanecarboxylic Acid",
      "Propylvaleric Acid",
      "N-Dipropylacetic Acid",
      "Myproic Acid",
      "Di-N-Propylessigsaure",
      "N-DPA",
      "Dipropylacetate",
      "Convulex",
      "Depakin",
      "Vupral",
      "Acido Valproico",
      "Acide Valproique",
      "Acidum Valproicum",
      "Stavzor",
      "Avugane",
      "Baceca",
      "Kyselina 2-Propylvalerova",
      "2-N-Propyl-N-Valeric Acid",
      "Acetic Acid, Dipropyl-",
      "Abbott 44090",
      "Valproinsaeure",
      "Savicol",
      "Convulsofin",
      "Deproic",
      "VPA",
      "Depakin Chrono",
      "Dipropyl Acetate",
      "Med Valproic",
      "Valproic Acid USP",
      "Valproic Acid USP24",
      "Acide Valproique [INN-French]",
      "Acido Valproico [INN-Spanish]",
      "Acidum Valproicum [INN-Latin]",
      "Alti-Valproic",
      "Depakote (TM)",
      "Dom-Valproic",
      "G2M-777",
      "Kyselina 2-Propylvalerova [Czech]",
      "Novo-Valproic",
      "Nu-Valproic",
      "PMS-Valproic Acid",
      "Penta-Valproic",
      "Valproic Acid (USP)",
      "Di-N-Propylessigsaeure",
      "Di-N-Propylessigsaure [German]",
      "Valproic Acid [USAN:INN:BAN]",
      "Valproic Acid, SodiumSalt (2:1)",
      "2 PP (Base)",
    ],
  },
  {
    primary_id: "D0Y3KR",
    names: ["GSK-2302024A"],
  },
  {
    primary_id: "D0Y3LJ",
    names: [
      "AZD5904",
      "UNII-62A9CG81VN",
      "62A9CG81VN",
      "AZD-5904",
      "3-[[(2R)-Oxolan-2-Yl]methyl]-2-Sulfanylidene-7H-Purin-6-One",
      "3-(((2R)-Oxolan-2-Yl)Methyl)-2-Sulfanylidene-7H-Purin-6-One",
      "2-Thioxanthine, TX4",
      "SCHEMBL2288062",
      "GTPL7728",
      "TX-4",
      "BDBM92469",
      "AZD 5904",
      "618913-30-7",
      "HY-111341",
      "CS-0035112",
      "6H-Purin-6-One, 1,2,3,9-Tetrahydro-3-(((2R)-Tetrahydro-2-Furanyl)Methyl)-2-Thioxo-",
      "(R)-3-((Tetrahydrofuran-2-Yl)Methyl)-2-Thioxo-1,2,3,7-Tetrahydro-6H-Purin-6-One",
      "6H-Purin-6-One, 1,2,3,7-Tetrahydro-3-(((2R)-Tetrahydro-2-Furanyl)Methyl)-2-Thioxo-",
    ],
  },
  {
    primary_id: "D0Y3LL",
    names: ["Taprenepag"],
  },
  {
    primary_id: "D0Y3LO",
    names: [
      "(S,S)-(-)-Bis(10)-Hupyridone",
      "E10",
      "(S,s)-(-)-N,n'-Di-5'-[5',6',7',8'-Tetrahydro- 2'(1'h)-Quinolynyl]-1,10-Diaminodecane Dihydrochloride",
      "AC1L9IVU",
      "CHEMBL483090",
      "BDBM10439",
      "1h22",
      "5,5'-(1,10-Decanediylbisimino)Bis[(5S)-5,6,7,8-Tetrahydro-2(1H)-Quinolinone]",
      "(5S,5'S)-5,5'-(Decane-1,10-Diyldiimino)Di(5,6,7,8-Tetrahydroquinolin-2(1H)-One)",
      "(5S)-5-[10-[[(5S)-2-Oxo-5,6,7,8-Tetrahydro-1H-Quinolin-5-Yl]amino]decylamino]-5,6,7,8-Tetrahydro-1H-Quinolin-2-One",
    ],
  },
  {
    primary_id: "D0Y3ME",
    names: ["Oxaliplatin"],
  },
  {
    primary_id: "D0Y3MO",
    names: [
      "Amikacin",
      "Amicacin",
      "Amikacina",
      "Amikacine",
      "Amikacinum",
      "Amikavet",
      "Amikin",
      "Arikace",
      "Briclin",
      "Kaminax",
      "Lukadin",
      "Mikavir",
      "AMIKACIN SULFATE",
      "Amikacin Base",
      "Amikacin Dihydrate",
      "ANTIBIOTIC BB-K8",
      "Amiglyde-V",
      "Amikacin & Tumor Necrosis Factor",
      "Amikacin (USP)",
      "Amikacina [INN-Spanish]",
      "Amikacine [INN-French]",
      "Amikacinum [INN-Latin]",
      "Amikin(Disulfate)",
      "Antibiotic BB-K 8",
      "BB-K 8",
      "BB-K8",
      "Amiglyde-V (TN)",
      "Amikacin (USP/INN)",
      "Amikacin [USAN:BAN:INN]",
      "O-3-Amino-3-Deoxy-Alpha-D-Glucopyranosyl-(1-4)-O-(6-Amino-6-Deoxy-Alpha-D-Glucopyranosyl-(1-6))-N(Sup 3)-(4-Amino-L-2-Hydroxybutyryl)-2-Deoxy-L-Streptamine",
      "O-3-Amino-3-Deoxy-Alpha-D-Glucopyranosyl-(1->4)-O-(6-Amino-6-Deoxy-Alpha-D-Glucopyranosyl-(1->6))-N(3)-(4-Amino-L-2-Hydroxybutyryl)-2-Deoxy-L-Streptamine",
      "O-3-Amino-3-Deoxy-.alpha.-D-Glucopyranosyl-(1->6)-O-[6-Amino-6-Deoxy-.alpha.-D-Glucopyranosyl-(1->4)]-1-(4-Amino-2-Hydroxy-1-Oxobutyl)-2-Deoxy-D-Streptamine",
      "(2S)-4-Amino-N-[(1R,2S,3S,4R,5S)-5-Amino-2-(3-Amino-3-Deoxy-Alpha-D-Glucopyranosyloxy)-4-(6-Amino-6-Deoxy-Alpha-D-Glucopyranosyloxy)-3-Hydroxycyclohexyl]-2-Hydroxybutanamide",
      "(2S)-4-Amino-N-[(1R,2S,3S,4R,5S)-5-Amino-2-[(2S,3R,4S,5S,6R)-4-Amino-3,5-Dihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxy-4-[(2R,3R,4S,5S,6R)-6-(Aminomethyl)-3,4,5-Trihydroxyoxan-2-Yl]oxy-3-Hydroxycyclohexyl]-2-Hydroxybutanamide",
      "(2S)-4-Amino-N-{(1R,2S,3S,4R,5S)-5-Amino-2-[(3-Amino-3-Deoxy-Alpha-D-Glucopyranosyl)Oxy]-4-[(6-Amino-6-Deoxy-Alpha-D-Glucopyranosyl)Oxy]-3-Hydroxycyclohexyl}-2-Hydroxybutanamide",
      "1-N-(L(-)-Gamma-Amino-Alpha-Hydroxybutyryl)Kanamycin A",
    ],
  },
  {
    primary_id: "D0Y3MW",
    names: ["Tolecine"],
  },
  {
    primary_id: "D0Y3NZ",
    names: ["PMID29649907-Compound-42"],
  },
  {
    primary_id: "D0Y3OO",
    names: ["NU-7432"],
  },
  {
    primary_id: "D0Y3OQ",
    names: ["AZM-145"],
  },
  {
    primary_id: "D0Y3OR",
    names: ["SQ-33600"],
  },
  {
    primary_id: "D0Y3OV",
    names: ["ISIS 113873"],
  },
  {
    primary_id: "D0Y3OX",
    names: ["TN-14003"],
  },
  {
    primary_id: "D0Y3PH",
    names: [
      "9-Hydroxypyrrolo[3,4-C]carbazole-1,3(2H,6H)-Dione",
      "CHEMBL209907",
      "SCHEMBL5829446",
      "IJHAYZZOYYQESB-UHFFFAOYSA-N",
      "BDBM50192426",
    ],
  },
  {
    primary_id: "D0Y3PP",
    names: ["NIS-62949"],
  },
  {
    primary_id: "D0Y3PV",
    names: ["2-Phenylethynyl-5,6,7,8-Tetrahydro-Quinolin-5-Ol", "CHEMBL253157"],
  },
  {
    primary_id: "D0Y3QR",
    names: ["BQS-481", "Eg5 Inhibitor (Cancer), Novartis"],
  },
  {
    primary_id: "D0Y3RN",
    names: ["BAY-Y-1015"],
  },
  {
    primary_id: "D0Y3RY",
    names: ["R-1578"],
  },
  {
    primary_id: "D0Y3TB",
    names: ["ALGRX 2872"],
  },
  {
    primary_id: "D0Y3TM",
    names: [
      "Iron",
      "Eisen",
      "Feronate",
      "Ferretts",
      "Ferrousal",
      "Ferrum",
      "Hemocyte",
      "Hierro",
      "LOHA",
      "PZhO",
      "Remko",
      "Siderol",
      "Yieronia",
      "Ancor B",
      "Armco Iron",
      "Carbonyl Iron",
      "Ferrous Iron",
      "Ferrovac E",
      "Hoeganaes EH",
      "Iron Monocation",
      "Iron Standard For AAS",
      "ATW 230",
      "ATW 432",
      "Atomel 28",
      "Atomel 300M200",
      "Atomel 500M",
      "Atomel 95",
      "Atomiron 44MR",
      "Atomiron 5M",
      "Atomiron AFP 25",
      "Atomiron AFP 5",
      "DSP 1000",
      "DSP 128B",
      "DSP 135",
      "DSP 135C",
      "DSP 138",
      "EF 1000",
      "EF 250",
      "EFV 250",
      "GS 6",
      "HS 4849",
      "Hoeganaes ATW 230",
      "NC 100",
      "PZh1M1",
      "PZh2M",
      "PZh2M1",
      "PZh2M2",
      "PZh3",
      "PZh3M",
      "PZh4M",
      "Ancoren 80/150",
      "Copy Powder CS 105-175",
      "Diseases, Iron Overload",
      "EFV 200/300",
      "EFV 250/400",
      "F 60 (Metal)",
      "FT 3 (Element)",
      "Fe-40",
      "Fe1+",
      "Ferro-Caps",
      "Ferro-Time",
      "HF 2 (Element)",
      "HL (Iron)",
      "HQ (Metal)",
      "HS (Iron)",
      "Iron (Fe)",
      "Iron(III) Nitrate Solution",
      "Iron, Elemental",
      "PZh-1M3",
      "PZh-2",
      "SUY-B 2",
      "Vitedyn-Slo",
      "Diseases (Animal), Iron Overload",
      "Ed-In-Sol",
      "Iron (Fe1+)",
      "Iron Ion (Fe+)",
      "Iron Ion(1+)",
      "Iron(1+)",
      "Iron(1+) Ion",
      "Iron, Ion (Fe1+)",
      "Iron, Ion (Fe1+) (8CI,9CI)",
      "3ZhP",
    ],
  },
  {
    primary_id: "D0Y3TU",
    names: ["7H-Pyrrolo[3,2-F]quinazoline-1,3-Diamine"],
  },
  {
    primary_id: "D0Y3TX",
    names: ["Apovasc", "CM-121", "CM-125", "CM-121 + CM-125"],
  },
  {
    primary_id: "D0Y3TY",
    names: ["NAB-001"],
  },
  {
    primary_id: "D0Y3TZ",
    names: ["GALLICACID"],
  },
  {
    primary_id: "D0Y3UB",
    names: ["PMID26651364-Compound-7d"],
  },
  {
    primary_id: "D0Y3UL",
    names: [
      "3-(3,5-Dimethyl-Phenyl)-Piperidine",
      "3-(3,5-Dimethylphenyl)Piperidine",
      "CHEMBL342345",
      "219704-07-1",
      "BDBM50068427",
      "AKOS006308924",
    ],
  },
  {
    primary_id: "D0Y3UT",
    names: ["N-(6-Methylpyridin-2-Yl)-5-Phenylpicolinamide", "CHEMBL396078"],
  },
  {
    primary_id: "D0Y3VE",
    names: ["3-M-Tolyl-1,5-Naphthyridine", "CHEMBL236263"],
  },
  {
    primary_id: "D0Y3VN",
    names: ["Ii-Key/MHC Class II Epitope Hybrid Peptides"],
  },
  {
    primary_id: "D0Y3VQ",
    names: ["Ryzodeq"],
  },
  {
    primary_id: "D0Y3VT",
    names: ["ORB-101"],
  },
  {
    primary_id: "D0Y3WN",
    names: ["MRS2768"],
  },
  {
    primary_id: "D0Y3WY",
    names: ["RG7841"],
  },
  {
    primary_id: "D0Y3XA",
    names: ["Ge2270a"],
  },
  {
    primary_id: "D0Y3XB",
    names: ["RhuCD40L"],
  },
  {
    primary_id: "D0Y3XF",
    names: ["Stem Cell-Derived Astrocytes"],
  },
  {
    primary_id: "D0Y3XR",
    names: ["NE58043"],
  },
  {
    primary_id: "D0Y3YK",
    names: ["PMID26560530-Compound-5"],
  },
  {
    primary_id: "D0Y3ZC",
    names: ["CBT-501"],
  },
  {
    primary_id: "D0Y3ZP",
    names: [
      "Terodiline",
      "Bicor",
      "Microl",
      "Mictrol",
      "Micturin",
      "Micturol",
      "Terolin",
      "Terodiline Hydrochloride",
      "TD-758",
    ],
  },
  {
    primary_id: "D0Y3ZX",
    names: ["D-21775"],
  },
  {
    primary_id: "D0Y4AS",
    names: [
      "DABCO",
      "1,4-Diazabicyclo[2.2.2]octane",
      "Triethylenediamine",
      "280-57-9",
      "Dabco",
      "Dabco 33LV",
      "1,4-Ethylenepiperazine",
      "1,4-DIAZABICYCLO(2.2.2)OCTANE",
      "Dabco Crystal",
      "TEDA",
      "Texacat TD 100",
      "N,N'-Endo-Ethylenepiperazine",
      "Dabco S-25",
      "D 33LV",
      "Dabco EG",
      "1,4-Diazabicyclo-Octane",
      "Dabco R-8020",
      "1,4-Diazobicyclo(2.2.2)Octane",
      "Thancat TD 33",
      "Bicyclo(2,2,2)-1,4-Diazaoctane",
      "1,4-Diazabicyclooctane",
      "1,4-Diazabicyclo[2,2,2]octane",
      "1,4-Diaza-Bicyclo[2.2.2]octane",
      "UNII-X8M57R0JS5",
      "NSC 56362",
      "CCRIS 6692",
      "TED",
      "HSDB 5556",
      "1,4-Diaza[2.2.2]bicyclooctane",
      "EINECS 205-999-9",
    ],
  },
  {
    primary_id: "D0Y4AU",
    names: ["CCNCSAKWCRDHSRCC", "CHEMBL503290"],
  },
  {
    primary_id: "D0Y4AW",
    names: ["Meprobamate"],
  },
  {
    primary_id: "D0Y4AZ",
    names: ["GALOCITABINE"],
  },
  {
    primary_id: "D0Y4BF",
    names: ["Pentraxin 2/Analog"],
  },
  {
    primary_id: "D0Y4BL",
    names: ["JNJ-28312141"],
  },
  {
    primary_id: "D0Y4BO",
    names: ["ABI-013"],
  },
  {
    primary_id: "D0Y4CC",
    names: ["N-[4-(1H-Benzoimidazol-2-Yl)-Benzoyl]-Guanidine", "CHEMBL233862"],
  },
  {
    primary_id: "D0Y4CG",
    names: ["Decyle Nelycorine Dibromo Salt Derivative 1"],
  },
  {
    primary_id: "D0Y4CN",
    names: ["IMC-TR1"],
  },
  {
    primary_id: "D0Y4DY",
    names: ["Xylometazoline"],
  },
  {
    primary_id: "D0Y4EM",
    names: ["Thrombomodulin"],
  },
  {
    primary_id: "D0Y4EU",
    names: ["PMID25656651-Compound-11a"],
  },
  {
    primary_id: "D0Y4EW",
    names: ["N/OFQ-(1-13)-NH2"],
  },
  {
    primary_id: "D0Y4FL",
    names: [
      "Roxithromycin",
      "Assoral",
      "Overall",
      "RXM",
      "Rossitrol",
      "Roxithromycine",
      "Roxithromycinum",
      "Roxitromicina",
      "Rulid",
      "Rulide",
      "Surlid",
      "Roxithromycine [French]",
      "Roxithromycinum [Latin]",
      "Roxitromicina [Spanish]",
      "RC2952",
      "RU 28965",
      "RU 965",
      "Biaxsig (TN)",
      "Coroxin (TN)",
      "RU-28965",
      "RU-965",
      "Roxar (TN)",
      "Roximycin (TN)",
      "Roxithromycin & Tumor Necrosis Factor",
      "Roxo (TN)",
      "Rulide (TN)",
      "Surlid (TN)",
      "Tirabicin (TN)",
      "Roxithromycin [USAN:INN:JAN]",
      "Roxl-150 (TN)",
      "Roxithromycin (JP15/USAN/INN)",
      "Erythromycin 9-(-O-[2-Methoxyethoxy]methyloxime);Erythromycin 9-(O-((2-Methoxyethoxy)Methyl)Oxime)",
      "Erythromycin, 9-(O-((2-Methoxyethoxy)Methyl)Oxime)",
      "9-(O-((2-Methoxyethoxy)Methyl)Oxime)Erythromycin",
      "9-[O-(2-Methoxyethoxymethyl)-Oxime] Of Erythromycin",
    ],
  },
  {
    primary_id: "D0Y4GJ",
    names: ["AVE-5883"],
  },
  {
    primary_id: "D0Y4GL",
    names: [
      "CP-271485",
      "2-(1,3-DIOXO-1,3-DIHYDRO-2H-ISOINDOL-2-YL) ETHYL-4-(4'-ETHOXY [1,1'-BIPHENYL]-4-YL)-4-OXOBUTANOIC ACID",
      "CP8",
      "(6R)-4-BENZYL-6-(1-METHYL-2,2-DIOXIDO-1,3-DIHYDRO-2,1-BENZISOTHIAZOL-5-YL)MORPHOLIN-3-ONE",
      "1utt",
      "AC1L9MIT",
      "DB02118",
      "CP 271485",
      "(6S)-4-Benzyl-6-(1-Methyl-2,2-Dioxo-3H-2,1-Benzothiazol-5-Yl)Morpholin-3-One",
      "3-Morpholinone, 6-(1,3-Dihydro-1-Methyl-2,1-Benzisothiazol-5-Yl)-4-(Phenylmethyl)-, S,S-Dioxid",
      "35580-46-2",
      "(6S)-4-Benzyl-6-(1-Methyl-2,2-Dioxido-1,3-Dihydro-2,1-Benzisothiazol-5-Yl)Morpholin-3-One",
    ],
  },
  {
    primary_id: "D0Y4GO",
    names: [
      "Pemetrexed",
      "Alimta",
      "LYA",
      "LY 231514",
      "LY231514",
      "Alimta (TN)",
      "LY 231,514",
      "LY-2315",
      "LY-231514",
      "Pemetrexed (INN)",
      "Pemetrexed [INN:BAN]",
      "LY-231,514",
      "N-(4-(2-(2-Amino-3,4-Dihydro-4-Oxo-7H-Pyrrolo(2,3-D)Pyrimdin-5-Yl)Ethyl)Benzoyl)Glutamic Acid",
      "N-{4-[2-(2-Amino-4-Oxo-4,7-Dihydro-1h-Pyrrolo[2,3-D]pyrimidin-5-Yl)Ethyl]benzoyl}-D-Glutamic Acid",
      "(2R)-2-[[4-[2-(2-Amino-4-Oxo-1,7-Dihydropyrrolo[2,3-D]pyrimidin-5-Yl)Ethyl]benzoyl]amino]pentanedioic Acid",
      "(2S)-2-[[4-[2-(2-Amino-4-Oxo-1,7-Dihydropyrrolo[2,3-D]pyrimidin-5-Yl)Ethyl]benzoyl]amino]pentanedioic Acid",
      "2-[[4-[2-(2-Amino-4-Oxo-1,7-Dihydropyrrolo[2,3-D]pyrimidin-5-Yl)Ethyl]benzoyl]amino]pentanedioic Acid",
      "2-{4-[2-(2-AMINO-4-OXO-4,7-DIHYDRO-3H-PYRROLO[2,3-D]PYRIMIDIN-5-YL)-ETHYL]-BENZOYLAMINO}-PENTANEDIOIC ACID",
    ],
  },
  {
    primary_id: "D0Y4HL",
    names: ["[Phe3]OT", "[Phe3]oxytocin", "Oxypressin"],
  },
  {
    primary_id: "D0Y4HO",
    names: ["LMV-601"],
  },
  {
    primary_id: "D0Y4HR",
    names: ["PMID25991433-Compound-E3"],
  },
  {
    primary_id: "D0Y4II",
    names: ["ISIS 3306"],
  },
  {
    primary_id: "D0Y4IR",
    names: ["UBC1967"],
  },
  {
    primary_id: "D0Y4IX",
    names: [
      "XEL-002BI",
      "Bulleyaconitine A (Drug Implant, Pain), Xel Pharmaceuticals",
      "Voltage Gated Sodium Channel Inhibitor (Drug Implant, Pain), Xel Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0Y4JJ",
    names: [
      "Bepridil",
      "Bepadin",
      "Vascor",
      "CERM 1978",
      "Bepadin (TN)",
      "Bepridil (INN)",
      "Vascor (TN)",
      "N-Benzyl-N-[3-(2-Methylpropoxy)-2-Pyrrolidin-1-Ylpropyl]aniline",
      "Beta-((2-Methylpropoxy)Methyl)-N-Phenyl-N-(Phenylmethyl)-1-Pyrrolidineethanamine",
      "N-Benzyl-N-[3-(2-Methylpropoxy)-2-Pyrrolidin-1-Yl-Propyl]aniline Hydrochloride",
      "N-Benzyl-N-[3-(2-Methylpropoxy)-2-(Pyrrolidin-1-Yl)Propyl]aniline",
      "N-{3-[(2-Methylpropyl)Oxy]-2-Pyrrolidin-1-Ylpropyl}-N-(Phenylmethyl)Aniline",
    ],
  },
  {
    primary_id: "D0Y4JQ",
    names: ["N-(3-Phenylpropyl)-1H-Pyrrole-2-Carboxamide"],
  },
  {
    primary_id: "D0Y4KA",
    names: ["Probodies"],
  },
  {
    primary_id: "D0Y4KB",
    names: ["PMID26666989-Compound-Figure12bottommiddle02"],
  },
  {
    primary_id: "D0Y4KE",
    names: ["Collagenase"],
  },
  {
    primary_id: "D0Y4KJ",
    names: ["5-Benzyloxy-Benzo[b]thiophene-2-Carboxamidine", "CHEMBL598385", "SCHEMBL13709665"],
  },
  {
    primary_id: "D0Y4KU",
    names: ["PMID25991433-Compound-B2"],
  },
  {
    primary_id: "D0Y4LI",
    names: ["PF-4708671"],
  },
  {
    primary_id: "D0Y4LU",
    names: ["MK-912"],
  },
  {
    primary_id: "D0Y4LX",
    names: ["T-705"],
  },
  {
    primary_id: "D0Y4MA",
    names: ["CART-138 Cells"],
  },
  {
    primary_id: "D0Y4MC",
    names: ["9-OH-Risperidone", "9-Hydroxy-Risperidone"],
  },
  {
    primary_id: "D0Y4MD",
    names: [
      "Edoxudine",
      "5-Ethyl-1-[4-Hydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-1,3-Dihydropyrimidine-2,4-D Ione",
      "ORF-15817",
      "RWJ-15817",
      "Edurid (Salt/Mix)",
      "1-(2-Deoxypentofuranosyl)-5-Ethylpyrimidine-2,4(1h,3h)-Dione",
      "AC1L1CAR",
      ".beta.-5-Ethyldeoxyuridine",
      "TimTec1_004024",
      "SCHEMBL65580",
      "MLS001360450",
      "AC1Q69H9",
      "5-Ethyl-1-[4-Hydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]pyrimidine-2,4-Dione",
      ".beta.-5-Ethyl-2'-Deoxyuridine",
      "XACKNLSZYYIACO-UHFFFAOYSA-N",
      "HMS3056J10",
      "HMS3369L22",
      "HMS1545G20",
      "EDU",
      "AKOS024282522",
      "5-Ethyl-2'-Deoxyuridine",
      "MCULE-3445830855",
      "ST056929",
    ],
  },
  {
    primary_id: "D0Y4MJ",
    names: ["KQ-791"],
  },
  {
    primary_id: "D0Y4NJ",
    names: ["Y134"],
  },
  {
    primary_id: "D0Y4NY",
    names: [
      "Research Programme: Small Molecule Therapeutics, Boehringer Ingelheim",
      "CB2 Agonists (Inflammation, Pain)",
      "CB2 Agonists (Inflammation, Pain),Evotec/Boehringer",
    ],
  },
  {
    primary_id: "D0Y4OI",
    names: ["SKF-105809"],
  },
  {
    primary_id: "D0Y4PL",
    names: ["QAP-642", "CCR3 Antagonist (Allergic Rhinitis), Novartis"],
  },
  {
    primary_id: "D0Y4QG",
    names: ["Bedaquiline"],
  },
  {
    primary_id: "D0Y4QP",
    names: ["Research Programme: MAP Kinase Kinase 2 Inhibitors, Pfizer"],
  },
  {
    primary_id: "D0Y4QU",
    names: ["Pharmacological Chaperones"],
  },
  {
    primary_id: "D0Y4QY",
    names: [
      "Long-Acting IL-11 Analog",
      "BBT-030",
      "BBT-045",
      "Long-Acting IL-11 Analog (PEGylated, Thrombocytopenia)",
      "Long-Acting IL-11 Analog (PEGylated, Thrombocytopenia), Bolder BioTechnology",
    ],
  },
  {
    primary_id: "D0Y4RJ",
    names: ["Nasal Influenza Vaccine"],
  },
  {
    primary_id: "D0Y4RS",
    names: ["ISIS 4719"],
  },
  {
    primary_id: "D0Y4SD",
    names: ["N-(2-Amino-5-(Thiazol-2-Yl)Phenyl)Benzamide", "CHEMBL1097278"],
  },
  {
    primary_id: "D0Y4SI",
    names: [
      "Ponesimod",
      "854107-55-4",
      "UNII-5G7AKV2MKP",
      "5G7AKV2MKP",
      "CHEMBL1096146",
      "Ponesimod [USAN:INN]",
      "Ponesimod,ACT-128800",
      "Ponesimod (ACT-128800)",
      "GTPL9320",
      "SCHEMBL15477937",
      "SCHEMBL15477934",
      "DTXSID50234631",
      "MolPort-035-681-391",
      "MolPort-046-033-541",
      "EX-A1417",
      "ZINC34509627",
      "S8241",
      "BDBM50316768",
      "AKOS022180266",
      "DB12016",
      "Compound 8bo [PMID:20446681]",
      "HY-10569",
      "KB-72962",
      "AS-35140",
      "AJ-89002",
      "(2Z,5Z)-5-(3-Chloro-4-((2R)-2,3-Dihydroxypropoxy)Phenylmethylidene)-3-(2-Methylphenyl)-2-(Propylimino)-1,3-Thiazolidin-",
    ],
  },
  {
    primary_id: "D0Y4SO",
    names: ["9-[9-(Beta-Carboline-9-Yl)Nonyl]-Beta-Carboline", "CHEMBL1098471", "BDBM50317203"],
  },
  {
    primary_id: "D0Y4SP",
    names: ["ICI 154129"],
  },
  {
    primary_id: "D0Y4SQ",
    names: ["Salicylidene Salicylhydrazide"],
  },
  {
    primary_id: "D0Y4TE",
    names: ["NSC-369087"],
  },
  {
    primary_id: "D0Y4TF",
    names: [
      "5-Hydroxyvaleric Acid",
      "5-HYDROXYPENTANOIC ACID",
      "13392-69-3",
      "5-Hydroxyvalerate",
      "5-Hydroxy-Pentanoic Acid",
      "Delta-Hydroxypentanoic Acid",
      "Pentanoic Acid, 5-Hydroxy-",
      "5-Hydroxy Valeric Acid",
      "5-Hydroxy-Pentansaeure",
      "Omega-Hydroxyvaleric Acid",
      "Delta-Hydroxyvaleric Acid",
      "5-Hydroxy-Valeriansaeure",
      "4-Oxy-Butan-Carbonsaeure",
      "Omega-Hydroxypentanoic Acid",
      "Pentanoic Acid,5-Hydroxy-",
      "5-HYDROXYLAEVULINIC ACID",
      "CHEBI:45564",
      "Delta-Hydroxyvalerate",
      "Omega-Hydroxyvalerate",
      "Omega-Hydroxypentanoate",
      "Delta-Hydroxypentanoate",
    ],
  },
  {
    primary_id: "D0Y4TQ",
    names: ["VK-11"],
  },
  {
    primary_id: "D0Y4TS",
    names: ["ISIS 111993"],
  },
  {
    primary_id: "D0Y4UG",
    names: ["2-(2-Cyclohexyl-4-Fluorophenoxy)Acetic Acid", "CHEMBL246312", "SCHEMBL4886140"],
  },
  {
    primary_id: "D0Y4WH",
    names: [
      "3-(Phenylsulfonyl)-1-(Pyrrolidin-3-Yl)-1H-Indole",
      "CHEMBL1076665",
      "651335-54-5",
      "SCHEMBL4067463",
      "CTK1J9030",
      "DTXSID20431690",
      "BDBM50313139",
      "1H-Indole, 3-(Phenylsulfonyl)-1-(3-Pyrrolidinyl)-",
    ],
  },
  {
    primary_id: "D0Y4WI",
    names: ["KKHT-11018"],
  },
  {
    primary_id: "D0Y4WR",
    names: ["R-1"],
  },
  {
    primary_id: "D0Y4XN",
    names: ["AGX-1009"],
  },
  {
    primary_id: "D0Y4YG",
    names: ["Iodixanol", "Acupaque", "Visipaque", "DU-6807", "2-5410-3A"],
  },
  {
    primary_id: "D0Y4YN",
    names: [
      "Astaxanthin Derivative",
      "Cardax",
      "Heptax",
      "Prostax",
      "XanCor",
      "Lycophyll Dilysinate",
      "Astaxanthin Derivative (Liver And Cardiovascular Damage)",
      "CDX-085",
      "ROS Therapeutics, Hawaii Biotech",
      "Reactive Oxygen Species Therapeutics, Hawaii Biotech",
      "Astaxanthin Derivative (Cardiovascular Inflammation), Cardax Pharmaceuticals",
      "Astaxanthin Derivative (Liver And Cardiovascular Damage), Cardax Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0Y4YV",
    names: ["Schisandrin A", "(-)-Deoxyschisandrin", "(-)-Dimethylgomisin J"],
  },
  {
    primary_id: "D0Y4ZE",
    names: ["BGC-20-0582"],
  },
  {
    primary_id: "D0Y4ZF",
    names: ["6-Benzyl-2-Cyclopentylsulfanyl-3H-Pyrimidin-4-One"],
  },
  {
    primary_id: "D0Y4ZI",
    names: ["Silicon-Modified Indomethacin"],
  },
  {
    primary_id: "D0Y4ZS",
    names: [
      "Endovion",
      "Angiogenesis Inhibitor, NeuroSearch",
      "Chloride Channel Antagonist, NeuroSearch",
      "NS-1652",
      "NS-3728",
    ],
  },
  {
    primary_id: "D0Y5AM",
    names: ["Darifenacin"],
  },
  {
    primary_id: "D0Y5AT",
    names: ["FGGFTGARKSARKKKNQ"],
  },
  {
    primary_id: "D0Y5AZ",
    names: ["Benzothiopyranoindole"],
  },
  {
    primary_id: "D0Y5BR",
    names: ["Phylomers"],
  },
  {
    primary_id: "D0Y5BV",
    names: ["AXImab", "AXIMAB", "Anti-Human Factor XI Monoclonal Antibody, Aronora"],
  },
  {
    primary_id: "D0Y5CG",
    names: ["1-(2-((3-Fluorophenoxy)Methyl)Phenyl)Piperazine", "CHEMBL575700", "SCHEMBL2191560"],
  },
  {
    primary_id: "D0Y5DC",
    names: ["ZJM-289"],
  },
  {
    primary_id: "D0Y5DN",
    names: ["PMID25435285-Compound-41"],
  },
  {
    primary_id: "D0Y5DO",
    names: [
      "Citalopram",
      "Akarin",
      "Celapram",
      "Celexa",
      "Celius",
      "Ciazil",
      "Cilift",
      "Cipram",
      "Ciprapine",
      "Citabax",
      "Citadur",
      "Citalec",
      "Citalopramum",
      "Citopam",
      "Cytalopram",
      "Dalsan",
      "Humorup",
      "Nitalapram",
      "Oropram",
      "Pramcit",
      "Recital",
      "Seropram",
      "Talam",
      "Talohexal",
      "Temperax",
      "Vodelax",
      "Zentius",
      "Zetalo",
      "Citalopram [Celexa]",
      "[3H]Citalopram",
      "Akarin (TN)",
      "Celapram (TN)",
      "Celexa (TN)",
      "Celius (TN)",
      "Ciazil (TN)",
      "Cilift (TN)",
      "Cipram (TN)",
      "Cipramil (TN)",
      "Ciprapine (TN)",
      "Citabax (TN)",
      "Citadur (TN)",
      "Citalec (TN)",
      "Citalopram [INN:BAN]",
      "Citalopramum [INN-Latin]",
      "Citaxin (TN)",
      "Citol (TN)",
      "Citopam (TN)",
      "Citox (TN)",
      "Citrol (TN)",
      "Dalsan (TN)",
      "Lu 10-171",
      "Recital (TN)",
      "Seropram (TN)",
      "Talam (TN)",
      "Zentius (TN)",
      "Zetalo (TN)",
      "AE-641/00603021",
      "Citalopram (USP/INN)",
      "Lu-10-171",
      "1,3-Dihydro-1-(3-(Dimethylamino)Propyl)-1-(4-Fluorophenyl)-5-Isobenzofurancarbonitrile",
      "1,3-Dihydro[3,4]benzofuran-5-Carbonitrile",
      "1-(3-(Dimethylamino)Propyl)-1-(P-Fluorophenyl)-5-Phthalancarbonitrile",
      "1-[3-(Dimethylamino)Propyl]-1-(4-Fluorophenyl)-1,3-Dihydro-2-Benzofuran-5-Carbonitrile",
      "1-[3-(Dimethylamino)Propyl]-1-(4-Fluorophenyl)-3H-2-Benzofuran-5-Carbonitrile",
    ],
  },
  {
    primary_id: "D0Y5DU",
    names: ["Aromatic Ring Compound 2"],
  },
  {
    primary_id: "D0Y5DW",
    names: ["Neuroprotectant Therapeutics"],
  },
  {
    primary_id: "D0Y5ED",
    names: ["AV-203"],
  },
  {
    primary_id: "D0Y5EX",
    names: [
      "DuP 714",
      "Acetylphenylalanyl-Prolyl-Boroarginine",
      "Dup-714",
      "Dup 714",
      "Ac-Phe-Pro-Boroarg-OH",
      "AC-(D)PHE-PRO-BOROARG-OH",
      "CHEMBL290376",
      "130982-43-3",
      "L-Prolinamide, N-Acetyl-D-Phenylalanyl-N-(4-((Aminoiminomethyl)Amino)-1-Boronobutyl)-, (S)-",
      "[(1R)-1-[[(2S)-1-[(2R)-2-Acetamido-3-Phenylpropanoyl]pyrrolidine-2-Carbonyl]amino]-4-(Diaminomethylideneamino)Butyl]boronic Acid",
      "Dup714",
      "AC1L3UBV",
      "SCHEMBL4454615",
      "GTPL8760",
      "CHEBI:42142",
      "DTXSID70156826",
      "BDBM50288406",
      "BDBM50451005",
      "LS-186929",
      "LS-187579",
    ],
  },
  {
    primary_id: "D0Y5FO",
    names: ["[HO1][Thr4,Lys8(5/6C-Flu)]VT"],
  },
  {
    primary_id: "D0Y5GA",
    names: ["ISIS 119264"],
  },
  {
    primary_id: "D0Y5GK",
    names: [
      "Bromodiphenhydramine",
      "Amodryl",
      "Bromanautine",
      "Bromazin",
      "Bromazina",
      "Bromazine",
      "Bromazinum",
      "Bromdiphenhydramine",
      "Bromdiphenhydraminum",
      "Deserol",
      "Histabromamine",
      "Ambodryl Hydrochloride",
      "Bromdiphenhydramine Hydrochloride",
      "Bromdiphenylhydramine Hydrochloride",
      "Bromodiphenhydramine Hydrochloride",
      "Ambrodyl (TN)",
      "Bromazina [INN-Spanish]",
      "Bromazine [INN:BAN]",
      "Bromazinum [INN-Latin]",
      "Bromo-Benadryl",
      "Bromo-Benzdryl",
      "Neo-Benadryl",
      "Beta-Dimethylaminoethyl P-Bromobenzhydryl Ether",
      "Beta-(P-Bromobenzhydryloxy)Ethyldimethylamine",
      "2-(P-Bromo-Alpha-Phenylbenzyloxy)-N,N-Dimethylethylamine",
      "2-[(4-Bromophenyl)(Phenyl)Methoxy]-N,N-Dimethylethanamine",
      "2-[(4-Bromophenyl)-Phenylmethoxy]-N,N-Dimethylethanamine",
      "2-{[(4-Bromophenyl)(Phenyl)Methyl]oxy}-N,N-Dimethylethanamine",
    ],
  },
  {
    primary_id: "D0Y5IH",
    names: ["MPDL-3280A"],
  },
  {
    primary_id: "D0Y5IQ",
    names: ["GRASSYSTATIN A"],
  },
  {
    primary_id: "D0Y5IW",
    names: ["2,7-Bis[2-(Ethylamino)Acetamido]anthraquinone", "SCHEMBL4291481", "CHEMBL494482"],
  },
  {
    primary_id: "D0Y5JB",
    names: [
      "8-(2,3,4-Trimethoxyphenyl)-9H-Purine-2,6-Diamine",
      "CHEMBL105759",
      "AC1LAR8U",
      "CTK7A1388",
      "BDBM50059922",
      "2,6-Diamino-8-(2',3',4'-Trimethoxyphenyl)Purine",
      "8-(2,3,4-Trimethoxy-Phenyl)-9H-Purine-2,6-Diamine",
      "8-(2,3,4-Trimethoxyphenyl)-7H-Purine-2,6-Diamine",
    ],
  },
  {
    primary_id: "D0Y5JC",
    names: ["Avanafil"],
  },
  {
    primary_id: "D0Y5JZ",
    names: ["JB991"],
  },
  {
    primary_id: "D0Y5KJ",
    names: ["6-Methyl-2-Pyridin-3-Yl-Chromen-4-One", "CHEMBL337443"],
  },
  {
    primary_id: "D0Y5KL",
    names: ["RI-001"],
  },
  {
    primary_id: "D0Y5KN",
    names: ["Isosorbide-2-(Butylcarbamate)-5-Benzoate", "CHEMBL494684"],
  },
  {
    primary_id: "D0Y5KQ",
    names: ["Cis-[PtCl(NH3)2(N7-Acyclovir)]+"],
  },
  {
    primary_id: "D0Y5KX",
    names: ["4'-(Pyridin-4-Ylmethyl)Biphenyl-4-Amine", "CHEMBL1215665", "BDBM50324612"],
  },
  {
    primary_id: "D0Y5LJ",
    names: ["1-Benzhydryl-4-(Furan-2-Yl)Piperidin-4-Ol", "CHEMBL231874"],
  },
  {
    primary_id: "D0Y5LR",
    names: ["AEN-100"],
  },
  {
    primary_id: "D0Y5LX",
    names: ["4-Cyclohexyl-6-Propylpyrimidine-2-Carbonitrile", "CHEMBL1085896"],
  },
  {
    primary_id: "D0Y5MF",
    names: ["BND-001"],
  },
  {
    primary_id: "D0Y5MR",
    names: ["2-Fluorophenyl 1-(4-Butoxyphenyl)Propylcarbamate", "CHEMBL597030", "SCHEMBL5216198"],
  },
  {
    primary_id: "D0Y5MS",
    names: ["VERONGAMINE"],
  },
  {
    primary_id: "D0Y5MY",
    names: ["GRN1005"],
  },
  {
    primary_id: "D0Y5NA",
    names: ["PF-06751979"],
  },
  {
    primary_id: "D0Y5NT",
    names: ["VT-224", "Cc-Chemokine Binding Protein (Inflammation), Viron"],
  },
  {
    primary_id: "D0Y5OB",
    names: ["7-Fluoro-2-(4-Fluorophenyl)-4H-Chromene-4-Thione", "CHEMBL599545"],
  },
  {
    primary_id: "D0Y5OP",
    names: ["18F-Fluoromethylallylcholine"],
  },
  {
    primary_id: "D0Y5OY",
    names: [
      "MK-3641",
      "ALK Ragweed Tablet",
      "Ragweed AIT",
      "SCH-039641",
      "SCH-39641",
      "Ragweed Allergy Desensitization Tablet, ALK-Abello",
      "Amb-A-1-U",
      "Allergy Immunotherapy Tablet (Ragweed Allergies), Merck & Co/ALK Abello",
      "Biological Ragweed Allergen (Sublingual, Zydis), ALK-Abello",
      "Ragweed Allergy Desensitization Tablet, ALK-Abello/Schering-Plough",
      "AIT (Ragweedallergy), Schering-Plough/ALK-Abello",
      "Allergy Immunotherapy Tablet (Ragweed Allergies), Schering-Plough/ALK-Abello",
      "Biological Ragweed Allergen (Sublingual, Zydis), ALK-Abello/Schering-Plough",
    ],
  },
  {
    primary_id: "D0Y5QA",
    names: ["AVB-S6-500"],
  },
  {
    primary_id: "D0Y5QD",
    names: ["ENMD-1198"],
  },
  {
    primary_id: "D0Y5QO",
    names: ["GK-128"],
  },
  {
    primary_id: "D0Y5QR",
    names: [
      "8R-Lisuride",
      "BKRGVLQUQGGVSM-RDTXWAMCSA-N",
      "S-(-)-Lisuride",
      "NCGC00163158-01",
      "Biomol-NT_000024",
      "Lopac0_000781",
      "BPBio1_001092",
      "CHEMBL1528238",
      "CHEBI:93550",
      "BDBM22867",
      "CCG-204866",
      "L-122",
      "EU-0100781",
      "SR-01000075971",
      "SR-01000075971-1",
      "BRD-K88871508-001-01-9",
      "1,1-Diethyl-3-(9,10-Didehydro-6-Methyl-8beta-Ergolinyl)Urea",
      "N'-(9,10-Didehydro-6-Methylergoline-8beta-Yl)-N,N-Diethylurea",
      "1,1-Diethyl-3-[(8beta)-6-Methyl-9,10-Didehydroergolin-8-Yl]urea",
      "S(-)-N'-[(8a)-9,10-Didehydro-6-Methylergolin-8-Yl]-N,N-Diethyl-Urea",
    ],
  },
  {
    primary_id: "D0Y5RO",
    names: ["NU-6102"],
  },
  {
    primary_id: "D0Y5RZ",
    names: ["Brovincamine Fumarate"],
  },
  {
    primary_id: "D0Y5SB",
    names: [
      "2-(Oxalyl-Amino)-Benzoic Acid",
      "2-(Oxaloamino)Benzoic Acid",
      "2-(OXALYL-AMINO)-BENZOIC ACID",
      "CHEMBL139050",
      "CHEBI:44493",
      "2-[(Carboxycarbonyl)Amino]benzoic Acid",
      "OBA",
      "2-Oxaloaminobenzoic Acid",
      "AC1L9GUO",
      "SCHEMBL4315667",
      "2-(Carboxyformamido)Benzoic Acid",
      "1c85",
      "ZINC2007905",
      "BDBM50118789",
      "AKOS022649621",
      "DB02622",
      "SC-57508",
      "5651/1/4",
      "BENZOIC ACID,2[(CARBOXYCARBONYL)AMINO]-",
    ],
  },
  {
    primary_id: "D0Y5TF",
    names: ["Tyr-Pro-Emp-Phe-NH2", "CHEMBL375274"],
  },
  {
    primary_id: "D0Y5TL",
    names: [
      "ALLOXAZINE",
      "Alloxazine",
      "Isoalloxazine",
      "490-59-5",
      "Alloxazin",
      "Benzo[g]pteridine-2,4(1H,3H)-Dione",
      "1H-Benzo[g]pteridine-2,4-Dione",
      "Benzo(G)Pteridine-2,4(1H,3H)-Dione",
      "UNII-880W3VF9YW",
      "Benzo[g]pteridine-2,4(3H,10H)-Dione",
      "CHEMBL68500",
      "880W3VF9YW",
      "CHEBI:37325",
      "HAUGRYOERYOXHX-UHFFFAOYSA-N",
      "Benzo[g]pteridine-2,4[1H,3H]-Dione",
      "1H,2H,3H,4H-Benzo[g]pteridine-2,4-Dione",
      "Benzo[g]pteridine-2,3H)-Dione",
      "WLN: T C666 BN DMVMV INJ",
      "1,3-Dihydrobenzo[g]pteridine-2,4-Dione",
      "SR-01000075199",
      "Alloxazine, 96%",
      "EINECS 207-714-3",
      "NSC 203056",
      "AC1NSYUA",
      "Lopac-A-242",
    ],
  },
  {
    primary_id: "D0Y5TO",
    names: [
      "[3H]azietomidate",
      "CHEMBL23812",
      "AZIETOMIDATE",
      "SCHEMBL1044050",
      "GTPL5404",
      "2-(3-Methyl-3H-Diazirin-3-Yl)Ethyl 1-(1-Phenylethyl)-1H-Imidazole-5-Carboxylate",
    ],
  },
  {
    primary_id: "D0Y5UC",
    names: ["PC-24", "Anti-Cancer Agent (3), ProCell Theraeputics"],
  },
  {
    primary_id: "D0Y5UF",
    names: ["5-Bromo-6-(Cyclopropylamino)Uracil Hydrochloride", "CHEMBL537864"],
  },
  {
    primary_id: "D0Y5UG",
    names: [
      "Amitriptyline",
      "Adepress",
      "Adepril",
      "Amitril",
      "Amitriprolidine",
      "Amitriptilina",
      "Amitriptylin",
      "Amitriptylinum",
      "Amitryptiline",
      "Amitryptyline",
      "Amytriptiline",
      "Amytriptylin",
      "Damilan",
      "Damilen",
      "Damitriptyline",
      "Elanil",
      "Elavil",
      "Flavyl",
      "Lantron",
      "Laroxil",
      "Laroxyl",
      "Proheptadiene",
      "Redomex",
      "Sarotex",
      "Seroten",
      "Sylvemid",
      "Triptanol",
      "Triptilin",
      "Triptisol",
      "Tryptanol",
      "Amitriptilina [Italian]",
      "Amitriptylin [German]",
      "MK 230",
      "N 750",
      "TP0",
      "Amitriptilina [INN-Spanish]",
      "Amitriptyline (INN)",
      "Amitriptyline [INN:BAN]",
      "Amitriptylinum [INN-Latin]",
      "Elavil (TN)",
      "Laroxyl (TN)",
      "Ro 4-1575",
      "[3-(10,11-Dihydro-Dibenzo[a,d]cyclohepten-5-Ylidene)-Propyl]-Dimethyl-Amine",
      "10,11-Dihydro-5-(Gamma-Dimethylaminopropylidene)-5H-Dibenzo(A,d)Cycloheptene",
      "10,11-Dihydro-N,N-Dimethyl-5H-Dibenzo(A,d)Heptalene-Delta(5),gamma-Propylamine",
      "10,11-Dihydro-5-(Gamma-Dimethylaminopropylidene)-5H-Dibenzo[a,d]cycloheptene",
      "3-(10,11-Dihydro-5H-Dibenzo(A,d)Cyclohepten-5-Yliden)-N,N-Dimethylpropylamin",
      "3-(10,11-Dihydro-5H-Dibenzo[a,d][7]annulen-5-Ylidene)-N,N-Dimethylpropan-1-Amine",
      "5-(3'-Dimethylaminopropylidene)-Dibenzo-(A,d)(1,4)-Cycloheptadiene",
      "5-(3'-Dimethylaminopropylidene)-Dibenzo-[a,d][1,4]-Cycloheptadiene",
      "5-(3-Dimethylaminopropylidene)-10,11-Dihydro-5H-Dibenzo(A,d)Cycloheptatriene",
      "5-(3-Dimethylaminopropylidene)-10,11-Dihydro-5H-Dibenzo(A,d)Cycloheptene",
      "5-(3-Dimethylaminopropylidene)-5H-Dibenzo[a,d]-10,11-Dihydrocycloheptene",
      "5-(3-Dimethylaminopropylidene)Dibenzo[a,d][1,4]-Cycloheptadiene",
      "5-(Gamma-Dimethylaminopropylidene)-10,11-Dihydro-5H-Dibenzo(A,d)Cycloheptene",
      "5-(Gamma-Dimethylaminopropylidene)-5H-Dibenzo[a,d][1,4]cycloheptadiene",
      "5-(Gamma-Dimethylaminopropylidene)-5H-Dibenzo(A,d)(1,4)Cycloheptadiene",
      "5-(Gamma-Dimethylaminopropylidene)-5H-Dibenzo[a,d]10,11-Dihydrocycloheptene",
      "5-(Gamma-Dimethylaminopropylidine)-5H-Dibenzo(A,d)(1,4)Cycloheptadiene",
    ],
  },
  {
    primary_id: "D0Y5UN",
    names: ["Tyr-Pro-L-Phe-D-Pro-NH2"],
  },
  {
    primary_id: "D0Y5UO",
    names: ["ISIS 173840"],
  },
  {
    primary_id: "D0Y5VR",
    names: [
      "H5N1 Influenza Vaccine",
      "Daronrix",
      "GSK-1119711A",
      "H5N1 Influenza Vaccine (Inactivated Whole Virus Adjuvanted)",
      "Avian Influenzavaccine (Inactivated Whole Virus Adjuvanted), GlaxoSmithKline",
      "H5N1 Influenza Vaccine (Inactivated Whole Virus Adjuvanted), GlaxoSmithKline",
    ],
  },
  {
    primary_id: "D0Y5WA",
    names: ["Bip-Tyr-Thr-Pro-Lys-Thr(Obzl)-Gly", "CHEMBL428483"],
  },
  {
    primary_id: "D0Y5WD",
    names: ["1-(3-(4-(Fluoromethyl)Phenoxy)Propyl)Piperidine", "CHEMBL469388"],
  },
  {
    primary_id: "D0Y5WL",
    names: ["TTL-1177"],
  },
  {
    primary_id: "D0Y5WQ",
    names: ["PSB603"],
  },
  {
    primary_id: "D0Y5WX",
    names: ["GBR 310"],
  },
  {
    primary_id: "D0Y5ZA",
    names: ["Arteether", "Beta Arteether", "Artecef (TN)", "Artemotil (TN)"],
  },
  {
    primary_id: "D0Y5ZG",
    names: ["Theradigm-HIV"],
  },
  {
    primary_id: "D0Y5ZP",
    names: ["N-(4-Iodophenyl)-N'-(17-Methylmorphinan-3-Yl)Urea", "CHEMBL569724", "BDBM50303601"],
  },
  {
    primary_id: "D0Y5ZT",
    names: ["KRP-203"],
  },
  {
    primary_id: "D0Y6BO",
    names: ["Milatuzumab-Doxorubicin Conjugate"],
  },
  {
    primary_id: "D0Y6CE",
    names: ["Metocurine Iodide"],
  },
  {
    primary_id: "D0Y6CR",
    names: ["Bacteriophages", "Bacteriophages (Burns Dressing, Bacterial Infection)"],
  },
  {
    primary_id: "D0Y6CU",
    names: ["Methotrexate Subcutaneous Auto-Injection"],
  },
  {
    primary_id: "D0Y6DJ",
    names: ["Rawsonol", "125111-69-5"],
  },
  {
    primary_id: "D0Y6DO",
    names: ["Streptokinase Rectal Suppository"],
  },
  {
    primary_id: "D0Y6DX",
    names: [
      "6-CYCLOHEXYLMETHYLOXY-2-(4'-HYDROXYANILINO)PURINE",
      "6-Cyclohexylmethyloxy-2-(4'-Hydroxyanilino)Purine",
      "CHEMBL340813",
      "4-{[6-(Cyclohexylmethoxy)-9H-Purin-2-Yl]amino}phenol",
      "N20",
      "NU-6086",
      "1oi9",
      "AC1L9L2Q",
      "BDBM5541",
      "SCHEMBL6792176",
      "O6-Cyclohexylmethylguanine Deriv 25",
      "DB08233",
      "6-Cyclohexylmethoxy-2-(4 -Hydroxyanilino)Purine",
    ],
  },
  {
    primary_id: "D0Y6EL",
    names: ["Carbamoyl Phosphate Disodium"],
  },
  {
    primary_id: "D0Y6EY",
    names: ["PMID25726713-Compound-18"],
  },
  {
    primary_id: "D0Y6FA",
    names: ["CP734432", "CP 734432", "CP-734432"],
  },
  {
    primary_id: "D0Y6GJ",
    names: ["ZM-241385"],
  },
  {
    primary_id: "D0Y6GQ",
    names: ["1-(1-Phenyl-2-(Pyridin-2-Yl)Ethyl)Piperazine", "CHEMBL376952"],
  },
  {
    primary_id: "D0Y6HA",
    names: [
      "CYC-103",
      "Cyclin Groove Inhibitors, Cyclacel",
      "CYC-103 (Pimetics Series)",
      "CYC-103 Cyclin Groove Inhibitors, Cyclacel",
      "CYC-103 Program, Cyclacel",
    ],
  },
  {
    primary_id: "D0Y6HD",
    names: ["PMID24946214C3b"],
  },
  {
    primary_id: "D0Y6HQ",
    names: ["N6-Methoxy-2-[(3-Pyridinyl)Ethynyl]-Adenosine", "CHEMBL223328", "SCHEMBL4384438"],
  },
  {
    primary_id: "D0Y6HV",
    names: [
      "EMD-53998",
      "5-[1-(3,4-Dimethoxybenzoyl)-1,2,3,4-Tetrahydroquinolin-6-Yl]-6-Methyl-3,6-Dihydro-2H-1,3,4-Thiadiazin-2-One",
    ],
  },
  {
    primary_id: "D0Y6HW",
    names: ["Ecopipam"],
  },
  {
    primary_id: "D0Y6HY",
    names: [
      "A-364",
      "A-358",
      "Dehydroquinate Synthase Inhibitors (Antibacterial)",
      "Dehydroquinate Synthase Inhibitors (Antibacterial), Arrow",
    ],
  },
  {
    primary_id: "D0Y6IJ",
    names: ["Ro-48-5545", "Bridged Beta Lactamase Inhibitors, Roche", "Ro-47-5325", "Ro-48-1256", "Ro-48-7953"],
  },
  {
    primary_id: "D0Y6IQ",
    names: ["9-Methyl-2-(Trifluoromethyl)-9H-Carbazole", "CHEMBL1173162", "ZINC53295380"],
  },
  {
    primary_id: "D0Y6IV",
    names: ["4-(4-Propoxyphenoxy)Phenol", "CHEMBL472893", "SCHEMBL19848981"],
  },
  {
    primary_id: "D0Y6KO",
    names: ["Methyldopate Hydrochloride"],
  },
  {
    primary_id: "D0Y6KY",
    names: ["Optiquel"],
  },
  {
    primary_id: "D0Y6LK",
    names: ["Deamido-NAD"],
  },
  {
    primary_id: "D0Y6LO",
    names: ["NNC-13-8119"],
  },
  {
    primary_id: "D0Y6LS",
    names: ["Carbocyclic Thromboxane A2", "9alpha,11alpha,11a-Dicarba-TXA2", "CTA-2"],
  },
  {
    primary_id: "D0Y6MD",
    names: ["NRC-2694"],
  },
  {
    primary_id: "D0Y6MH",
    names: ["Tebipenem"],
  },
  {
    primary_id: "D0Y6MS",
    names: ["JWH-297"],
  },
  {
    primary_id: "D0Y6MY",
    names: ["Toluene-1-Diazonium-3-Sulfonic Acid Anion"],
  },
  {
    primary_id: "D0Y6NL",
    names: ["Arepanrix"],
  },
  {
    primary_id: "D0Y6NO",
    names: ["Anti-CD326 Humanized Mab", "Anti-CD326 Humanized MAb (Cancer)"],
  },
  {
    primary_id: "D0Y6OA",
    names: ["COUMATE"],
  },
  {
    primary_id: "D0Y6OC",
    names: ["REC-1819"],
  },
  {
    primary_id: "D0Y6OE",
    names: [
      "A-85380",
      "A 85380",
      "161416-98-4",
      "CHEMBL59986",
      "(S)-3-(AZETIDIN-2-YLMETHOXY)PYRIDINE",
      "AC1NSJQT",
      "Pyridine, 3-[(2S)-2-Azetidinylmethoxy]-",
      "SCHEMBL676026",
      "A-159470",
      "GTPL5460",
      "ZINC3805142",
      "3-(2(S)-Azetidinylmethoxy)Pyridine",
      "BDBM50049750",
      "AKOS030230435",
      "NCGC00387223-01",
      "3-[(2S)-Azetidin-2-Ylmethoxy]pyridine",
      "3-[[(2S)-Azetidin-2-Yl]methoxy]pyridine",
      "3-((S)-1-Azetidin-2-Ylmethoxy)-Pyridine",
      "Pyridine, 3-(2-Azetidinylmethoxy)-, (S)-",
    ],
  },
  {
    primary_id: "D0Y6OH",
    names: ["Davunetide Intranasal"],
  },
  {
    primary_id: "D0Y6OK",
    names: ["Quinizarin"],
  },
  {
    primary_id: "D0Y6ON",
    names: ["Pyrrolo-Pyrazine Derivative 4"],
  },
  {
    primary_id: "D0Y6OX",
    names: ["PGJ2", "9-Deoxy-Delta-9-Prostaglandin D2", "Prostaglandin J2"],
  },
  {
    primary_id: "D0Y6PH",
    names: ["Small Molecule I", "CETP Inhibitors (Dyslipidemia)", "CETP Inhibitors (Dyslipidemia), Bayer/Merck"],
  },
  {
    primary_id: "D0Y6QC",
    names: ["Dapagliflozin Propanediol; Saxagliptin Hydrochloride"],
  },
  {
    primary_id: "D0Y6QO",
    names: ["Antipruritic Agents"],
  },
  {
    primary_id: "D0Y6QX",
    names: ["FOLIGO 002"],
  },
  {
    primary_id: "D0Y6RZ",
    names: [
      "1,2-Bis(3-Fluorophenyl)Ethane-1,2-Dione",
      "CHEMBL242067",
      "3,3'-Difluorobenzil",
      "SCHEMBL3169331",
      "NIOZEPOIIGVTTO-UHFFFAOYSA-N",
      "BDBM50209414",
    ],
  },
  {
    primary_id: "D0Y6SN",
    names: ["SB-277011"],
  },
  {
    primary_id: "D0Y6SQ",
    names: [
      "Camostat",
      "59721-28-7",
      "Camostate",
      "Camostat [INN]",
      "Camostatum [INN-Latin]",
      "UNII-0FD207WKDU",
      "CCRIS 7219",
      "4-(2-(2-(Dimethylamino)-2-Oxoethoxy)-2-Oxoethyl)Phenyl 4-Guanidinobenzoate",
      "0FD207WKDU",
      "C20H22N4O5",
      "Camostat (INN)",
      "Foipan",
      "FOY 305",
      "FOY-305",
      "Dimethylcarbamoylmethyl 4-(4-Guqnidinobenzoyloxy)Phenylacetat",
      "Foypan",
      "Camostatum",
      "CHEMBL85164",
      "Camostate-Mesilate",
      "[4-[2-[2-(Dimethylamino)-2-Oxoethoxy]-2-Oxoethyl]phenyl] 4-(Diaminomethylideneamino)Benzoate",
      "P-Guanidinobenzoic Acid, Ester With (P-Hydroxyphenyl)Acetic Acid, Ester With N",
    ],
  },
  {
    primary_id: "D0Y6ST",
    names: ["SAG", "1-Stearoyl-2-Arachidonoyl-Sn-Glycerol"],
  },
  {
    primary_id: "D0Y6TI",
    names: ["YM-355179"],
  },
  {
    primary_id: "D0Y6TX",
    names: ["PMID26560530-Compound-32"],
  },
  {
    primary_id: "D0Y6UB",
    names: [
      "2-PMPA",
      "2-(Phosphonomethyl)Pentanedioic Acid",
      "173039-10-6",
      "PMPA (NAALADase Inhibitor)",
      "CHEMBL47009",
      "2-(PHOSPHONOMETHYL)-PENTANEDIOIC ACID",
      "PMPA(NAALADaseinhibitor)",
      "2-PMPA(NAALADaseinhibitor)",
      "SCHEMBL383173",
      "BDBM17659",
      "KS-00001CPM",
      "CTK0E4395",
      "DTXSID90436036",
      "MolPort-023-276-085",
      "ISEYJGQFXSTPMQ-UHFFFAOYSA-N",
      "HMS3267D10",
      "2-Phosphonomethyl-Pentanedioic Acid",
      "EX-A1248",
      "Pentanedioic Acid Analogue, (RS)-1",
      "ANW-62579",
      "AKOS016004257",
      "API0001123",
      "2-PMPA, &gt",
      "MP-2042",
      "CS-6202",
      "Pentanedioic Acid, 2-(Phosphonomethyl)-",
    ],
  },
  {
    primary_id: "D0Y6UU",
    names: ["Atrasentan"],
  },
  {
    primary_id: "D0Y6WJ",
    names: ["CD2665", "CD 2665", "CD-2665"],
  },
  {
    primary_id: "D0Y6XD",
    names: ["B-247"],
  },
  {
    primary_id: "D0Y6XG",
    names: ["Atu-195"],
  },
  {
    primary_id: "D0Y6ZK",
    names: ["IB-MECA"],
  },
  {
    primary_id: "D0Y6ZS",
    names: ["Esterom"],
  },
  {
    primary_id: "D0Y7AN",
    names: [
      "2-(3-Mercapto-Propyl)-Pentanedioic Acid",
      "2-(3-Mercaptopropyl)Pentanedioic Acid",
      "2-MPPA",
      "2-(3-Sulfanylpropyl)Pentanedioic Acid",
      "254737-29-6",
      "CHEMBL303352",
      "Pentanedioic Acid, 2-(3-Mercaptopropyl)-",
      "2-[3-Mercaptopropyl]pentanedioic Acid",
      "GPI 5693",
      "SCHEMBL787800",
      "CTK0J4030",
      "BDBM17755",
      "FNLNSQHJKVQCBP-UHFFFAOYSA-N",
      "MolPort-035-765-922",
      "Pentanedioic Acid Analogue, (RS)-3",
      "AKOS024458465",
    ],
  },
  {
    primary_id: "D0Y7AV",
    names: ["2-Ethyl-4-(Furan-3-Yl)Thieno[3,2-D]pyrimidine", "CHEMBL259338", "SCHEMBL3684676"],
  },
  {
    primary_id: "D0Y7BD",
    names: [
      "Ceftizoxime",
      "Ceftix",
      "Ceftizoxima",
      "Ceftizoximum",
      "Eposerin",
      "Ceftizoxime Monosodium Salt",
      "FK749",
      "FR 13749",
      "Cefizox (TN)",
      "Ceftizoxima[INN-Spanish]",
      "Ceftizoxime (INN)",
      "Ceftizoxime [INN:BAN]",
      "Ceftizoximum [INN-Latin]",
      "FK-749",
      "FR-13479",
      "FR-13749",
      "SKF-88373",
      "SK&F 88373-2",
      "Syn-7-(2-(2-Amino-4-Thiazolyl)-2-Methoxyiminoacetamido)-3-Cephem-4-Carboxylic Acid",
      "(6R,7R)-7-(2-(2-Amino-4-Thiazolyl)-2Z-(Methoxyimino)Acetamido)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-En-2-Carbonsaeure",
      "(6R,7R)-7-(2-(2-Amino-4-Thiazolyl)Glyoxyamido)-8-Oxo-5-Thia-1-Azabicyclo(4.2.0)Oct-2-Carbonsaeure-7-(Z)-(O-Methyloxim)",
      "(6R,7R)-7-({(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-[(Methyloxy)Imino]acetyl}amino)-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-[[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-Methoxyiminoacetyl]amino]-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetyl]amino}-8-Oxo-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "7beta-{[(2Z)-2-(2-Amino-1,3-Thiazol-4-Yl)-2-(Methoxyimino)Acetyl]amino}-2,3-Didehydropenam-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0Y7BV",
    names: ["Florifenine"],
  },
  {
    primary_id: "D0Y7CI",
    names: ["SNC-80"],
  },
  {
    primary_id: "D0Y7CO",
    names: ["ABT-072"],
  },
  {
    primary_id: "D0Y7CS",
    names: ["2-(Tert-Butylamino)-1-M-Tolylpropan-1-One", "CHEMBL566832", "SCHEMBL769882", "BDBM50302942"],
  },
  {
    primary_id: "D0Y7DM",
    names: ["RGN-352 Injectable"],
  },
  {
    primary_id: "D0Y7DP",
    names: ["Uridine"],
  },
  {
    primary_id: "D0Y7EM",
    names: ["Felbinac"],
  },
  {
    primary_id: "D0Y7FK",
    names: ["Peptide Analog 53"],
  },
  {
    primary_id: "D0Y7FX",
    names: [
      "BENZIL",
      "134-81-6",
      "Diphenylethanedione",
      "Dibenzoyl",
      "Diphenylglyoxal",
      "1,2-Diphenylethane-1,2-Dione",
      "Bibenzoyl",
      "Ethanedione, Diphenyl-",
      "1,2-Diphenylethanedione",
      "Diphenylethane-1,2-Dione",
      "Glyoxal, Diphenyl-",
      "Diphenyldiketon",
      "1,2-Ethanedione, 1,2-Diphenyl-",
      "Diphenyl-Alpha,beta-Diketone",
      "NSC 220315",
      "CCRIS 6179",
      "UNII-S85X61172J",
      "EINECS 205-157-0",
      "WY-20910",
      "AI3-00898",
      "CHEMBL189886",
      "CHEBI:51507",
      "1,2-Diphenyl-Ethane-1,2-Dione",
      "Diphenyl-.alpha.,.beta.-Diketone",
      "WURBFLDFSFBTLW-UHFFFAOYSA-N",
      "C14H10O2",
      "MFCD00003080",
      "S85X61172J",
      "1,2-Dione",
      "Benzil, 99+",
    ],
  },
  {
    primary_id: "D0Y7HC",
    names: ["PMID:12127536C20"],
  },
  {
    primary_id: "D0Y7HG",
    names: ["GALANGIN"],
  },
  {
    primary_id: "D0Y7HW",
    names: [
      "Clomet",
      "DMPEN (Cancer), Xanthus Pharmaceuticals",
      "Demethylpenclomedine (Cancer), Xanthus Pharmaceuticals",
    ],
  },
  {
    primary_id: "D0Y7IC",
    names: [
      "Baricitinib",
      "1187594-09-7",
      "INCB028050",
      "LY3009104",
      "Olumiant",
      "INCB 028050",
      "INCB-028050",
      "UNII-ISP4442I3Y",
      "LY-3009104",
      "ISP4442I3Y",
      "Baricitinib (LY3009104, INCB028050)",
      "J-503551",
      "C16H17N7O2S",
      "Baricitinib [USAN:INN]",
      "Olumiant (TN)",
    ],
  },
  {
    primary_id: "D0Y7IU",
    names: [
      "Budesonide",
      "Bidien",
      "Budenofalk",
      "Budeson",
      "Budesonido",
      "Budesonidum",
      "Budiair",
      "Cortivent",
      "Entocort",
      "Horacort",
      "Inflammide",
      "Micronyl",
      "Miflonide",
      "Preferid",
      "Pulmaxan",
      "Respules",
      "Rhinocort",
      "Spirocort",
      "UDB",
      "Budecort Inhaler",
      "Budesonide Easyhaler",
      "Budesonide MMX",
      "Entocort EC",
      "GionaEasyhaler",
      "Pulmaxan Turbohaler",
      "Pulmicort Flexhaler",
      "Pulmicort Nebuamp",
      "Pulmicort Respules",
      "Pulmicort Topinasal",
      "Pulmicort Turbuhaler",
      "Rhinocort Aqua",
      "Rhinocort Turbuhaler",
      "Rhinocort Alpha",
      "Unit Dosebudesonide",
      "B 7777",
      "S 1320",
      "Budesonido [INN-Spanish]",
      "Budesonidum [INN-Latin]",
      "Entocort (TN)",
      "Entocort EC (TN)",
      "MAP-0010",
      "Noex (TN)",
      "Pulmicort (TN)",
      "Rhinocort (TN)",
      "S-1320",
      "Budesonide (JAN/USAN/INN)",
      "Budesonide [USAN:INN:BAN:JAN]",
      "Rhinocort, Pulmicort , Entocort, Symbicort, Noex. Entocort EC, Budesonide",
      "Pregna-1,4-Diene-3,20-Dione, 16,17-Butylidenebis(Oxy)-11,21-Dihydroxy-, (11beta,16alpha(R))-,and 16alpha,17-((S)-Butylidenebis(Oxy))-11beta,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "(11-Beta,16-Alpha)-16,17-(Butylidenebis(Oxy))-11,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "(11beta,16alpha)-16,17-(Butylidenebis(Oxy))-11,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "(4aR,4bS,5S,6aS,6bS,8R,9aR,10aS,10bS)-5-Hydroxy-6b-(Hydroxyacetyl)-4a,6a-Dimethyl-8-Propyl-4a,4b,5,6,6a,6b,9a,10,10a,10b,11,12-Dodecahydro-2H-Naphtho[2',1':4,5]indeno[1,2-D][1,3]dioxol-2-One",
      "(4aR,4bS,5S,6aS,6bS,9aR,10aS,10bS)-5-Hydroxy-6b-(Hydroxyacetyl)-4a,6a-Dimethyl-8-Propyl-4a,4b,5,6,6a,6b,9a,10,10a,10b,11,12-Dodecahydro-2H-Naphtho[2',1':4,5]indeno[1,2-D][1,3]dioxol-2-One",
      "(R,S)-11b,16a,17,21,tetrahydroxypregna-1,4-Diene-3,20-Dione Cyclic 16,17-Acetal With Buty Raldehyde",
      "(RS)-(11beta,16alpha)-16,17-[Butylidenebis(Oxy)]-11,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "(RS)-11beta,16alpha,17,21-Tetrahydroxypregna-1,4-Diene-3,20-Dione Cyclic 16,17-Acetal With Butyraldehyde",
      "(S)-16alpha,17-(Butylidenedioxy)-11beta,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "11beta,21-Dihydroxy-16alpha,17alpha-(Butane-1,1-Diyldioxy)Pregna-1,4-Diene-3,20-Dione",
      "16,17-Butylidenebis(Oxy)-11,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "16,17-Butylidenebis(Oxy)-11-,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "16-Alpha,17-Alpha-Butylidenedioxy-11-Beta,21-Dihydroxy-1,4-Pregnadiene-3,20-Dione",
      "16alpha(R),17-(Butylidenebis(Oxy))-11beta,21-Dihydroxypregna-1,4-Diene-3,20-Dione",
      "16alpha-,17alpha-Butylidenedioxypregna-1,4-Diene-11beta-,21-Diol-3,20-Dione",
    ],
  },
  {
    primary_id: "D0Y7JU",
    names: [
      "GW685698X",
      "Allermist",
      "Avamys",
      "Veramyst",
      "Fluticasone Furoate",
      "GW 685698X",
      "GW6",
      "GW-685698",
      "GW-685698X",
      "Veramyst (TN)",
      "Fluticasone Furoate (JAN/USAN/INN)",
      "(6alpha,11beta,16alpha,17alpha)-6,9-Difluoro-17-(((Fluoromethyl)Thio)Carbonyl)-11-Hydroxy-16-Methyl-3-Oxoandrosta-1,4-Dien-17-Yl-2-Furancarboxylate",
      "6alpha,9-Difluoro-17-(((Fluoromethyl)Sulfanyl)Carbonyl)-11beta-Hydroxy-16alpha-Methyl-3-Oxoandrosta-1,4-Dien-17alpha-Yl Furan-2-Carboxylate",
    ],
  },
  {
    primary_id: "D0Y7KE",
    names: ["1-Benzhydryl-4-(4-Ethylphenyl)Piperidin-4-Ol", "CHEMBL234543"],
  },
  {
    primary_id: "D0Y7KH",
    names: [
      "DEGARELIX",
      "Degarelix",
      "214766-78-6",
      "UNII-SX0XJI3A11",
      "SX0XJI3A11",
      "CHEMBL415606",
      "Uglypeptide1",
      "Degarelix (INN/USAN)",
      "Degarelix [USAN:INN:BAN]",
      "SCHEMBL1397034",
      "GTPL5585",
      "HSDB 7817",
      "CHEBI:135961",
      "HY-16168A",
      "BDBM50102450",
      "AKOS030526717",
      "AN-5181",
      "RL02641",
      "DB06699",
      "CS-5350",
      "FE200486 (AS ACETATE SALT)",
      "QC-10170",
      "Z-3147",
      "D08901",
      "766D786",
      "Ac-D-Nal-[D-(PCl)Phe]-D-Pal-Ser-[Aph(DHor)]-D-[Aph(Cbm)]-Leu-ILys-Pro-DAla-NH2",
    ],
  },
  {
    primary_id: "D0Y7LD",
    names: [
      "Sitosterol",
      "BETA-SITOSTEROL",
      "83-46-5",
      "Cupreol",
      "Quebrachol",
      "22,23-Dihydrostigmasterol",
      "Triastonal",
      "Azuprostat",
      "Rhamnol",
      "Cinchol",
      "Harzol",
      "Beta-Sitosterin",
      "Alpha-Dihydrofucosterol",
      "Nimbosterol",
      "Stigmast-5-En-3beta-Ol",
      "B-Sitosterol",
      "Sito-Lande",
      "(-)-Beta-Sitosterol",
      "(3beta)-Stigmast-5-En-3-Ol",
      ".beta.-Sitosterol",
      "Angelicin (Steroid)",
      "24alpha-Ethylcholesterol",
      "A-Dihydrofucosterol",
      ".beta.-Sitosterin",
      "Phytosterol",
      "Prostasal",
      "NSC8096",
      "SITOSTEROL, BETA",
      "(24R)-Stigmast-5-En-3beta-Ol",
      ".alpha.-Dihydrofucosterol",
      "(24R)-Ethylcholest-5-En-3beta-Ol",
    ],
  },
  {
    primary_id: "D0Y7LM",
    names: ["Carbamate Derivative 10"],
  },
  {
    primary_id: "D0Y7LV",
    names: ["Anti-CD38 CAR-T Cell Therapy"],
  },
  {
    primary_id: "D0Y7MC",
    names: ["Benzene Sulfonamide Derivative 15"],
  },
  {
    primary_id: "D0Y7NA",
    names: ["INCB9471"],
  },
  {
    primary_id: "D0Y7ND",
    names: [
      "4-Amino-N-(2-Chlorophenyl)Benzenesulfonamide",
      "19837-85-5",
      "4-Amino-N-(2-Chlorophenyl)Benzene-1-Sulfonamide",
      "4-Amino-N-(2-Chloro-Phenyl)-Benzenesulfonamide",
      "Benzenesulfonamide, 4-Amino-N-(2-Chlorophenyl)-",
      "AC1MP5DG",
      "AC1Q51WZ",
      "SCHEMBL1970942",
      "N1-2-Chlorophenylsulphanilamide",
      "CHEMBL227597",
      "CTK4E2501",
      "DTXSID20392191",
      "XZQHDUUDKCHQIL-UHFFFAOYSA-N",
      "MolPort-002-463-078",
      "ZINC3885452",
      "STL420385",
      "BBL034801",
      "AKOS000115492",
      "NE25153",
      "MCULE-7275329899",
      "KB-289984",
      "FT-0719132",
      "N-(2-Chlorophenyl)-4-Aminobenzenesulfonamide",
      "EN300-02335",
    ],
  },
  {
    primary_id: "D0Y7NV",
    names: [
      "5-Biphenyl-4-Yl-5-Ethyl-Pyrimidine-2,4,6-Trione",
      "CHEMBL367524",
      "94209-48-0",
      "2,4,6(1H,3H,5H)-Pyrimidinetrione, 5-[1,1'-Biphenyl]-4-Yl-5-Ethyl-",
      "ACMC-20lyhc",
      "AC1M3WRE",
      "Oprea1_691960",
      "MLS001000776",
      "NIOSH/CQ0532020",
      "CTK3G9325",
      "DTXSID20367041",
      "MolPort-002-095-507",
      "HMS2827O03",
      "BDBM50099119",
      "ZINC96299878",
      "STK760260",
      "AKOS005616072",
      "MCULE-2406616532",
      "Acido 5-Etil 5-(P-Difenilil)Barbiturici",
      "NCGC00245692-01",
      "5-(4-Biphenylyl)-5-Ethylbarbituric Acid",
      "SMR000498104",
      "LS-23825",
      "M.G. 3419",
      "CQ05320200",
      "Barbituric Acid, 5-(4-Biphenylyl)-5-Ethyl-",
    ],
  },
  {
    primary_id: "D0Y7OI",
    names: ["Vialinin A"],
  },
  {
    primary_id: "D0Y7OJ",
    names: ["LU302146"],
  },
  {
    primary_id: "D0Y7PG",
    names: ["Tolcapone"],
  },
  {
    primary_id: "D0Y7PJ",
    names: ["ELS-110"],
  },
  {
    primary_id: "D0Y7PW",
    names: [
      "6-(4-Bromo-Benzenesulfonyl)-2H-Pyridazin-3-One",
      "CHEMBL413566",
      "BDBM16597",
      "MolPort-035-757-148",
      "HMS3437C04",
      "ZINC11536222",
      "AKOS024439117",
      "AKOS001782802",
      "MCULE-5808217949",
      "Phenyl-Substituted Sulfonylpyridazinone, 8b",
      "6-[(4-Bromophenyl)Sulfonyl]-3(2H)-Pyridazinone",
    ],
  },
  {
    primary_id: "D0Y7PY",
    names: ["Ac-Bth-Thr(PO3H2)-Pip-Nal-Gln-NH2", "CHEMBL380685"],
  },
  {
    primary_id: "D0Y7QW",
    names: ["PMID25991433-Compound-K1"],
  },
  {
    primary_id: "D0Y7QY",
    names: [
      "(E)-3-(4-Tert-Butyl-Phenyl)-N-Phenyl-Acrylamide",
      "CHEMBL426634",
      "(2E)-3-[4-(Tert-Butyl)Phenyl]-N-Phenylprop-2-Enamide",
      "AC1NYN2M",
      "SCHEMBL4006434",
      "SCHEMBL4006429",
      "MolPort-006-388-008",
      "AVMIAJAOFXDOSN-SDNWHVSQSA-N",
      "ZINC5903656",
      "STK411584",
      "BDBM50158648",
      "AKOS003298323",
      "3-(4-Tert-Butylphenyl)-N-Phenylacrylamide",
      "ST50943451",
      "(E)-3-(4-Tert-Butylphenyl)-N-Phenylprop-2-Enamide",
    ],
  },
  {
    primary_id: "D0Y7QZ",
    names: ["4-(Quinolin-3-Yl)-N-P-Tolylpyrimidin-2-Amine", "CHEMBL1080666"],
  },
  {
    primary_id: "D0Y7RB",
    names: [
      "3-[6-Methyl-Indan-(1Z)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 21b",
      "CHEMBL366570",
      "BDBM8605",
      "AC1O705D",
      "3-[(Z)-(6-Methyl-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0Y7RR",
    names: ["XGP-515", "Allergy Therapy, Xaragen"],
  },
  {
    primary_id: "D0Y7RV",
    names: [
      "Benzoquinazolinone 12",
      "SCHEMBL2404136",
      "GTPL8549",
      "3-[(1S,2S)-2-Hydroxycyclohexyl]-6-{[6-(1-Methyl-1H-Pyrazol-4-Yl)Pyridin-3-Yl]methyl}-3H,4H-Benzo[h]quinazolin-4-One",
    ],
  },
  {
    primary_id: "D0Y7RW",
    names: [
      "Phenobarbital",
      "Adonal",
      "Aephenal",
      "Agrypnal",
      "Amylofene",
      "Antrocol",
      "Aphenylbarbit",
      "Aphenyletten",
      "Austrominal",
      "Barbapil",
      "Barbellen",
      "Barbellon",
      "Barbenyl",
      "Barbilehae",
      "Barbinal",
      "Barbipenyl",
      "Barbiphen",
      "Barbiphenyl",
      "Barbipil",
      "Barbita",
      "Barbivis",
      "Barbonal",
      "Barbophen",
      "Bardorm",
      "Bartol",
      "Bialminal",
      "Cabronal",
      "Calmetten",
      "Calminal",
      "Cardenal",
      "Chinoin",
      "Codibarbita",
      "Coronaletta",
      "Cratecil",
      "Damoral",
      "Dezibarbitur",
      "Donphen",
      "Dormina",
      "Dormiral",
      "Dormital",
      "Doscalun",
      "Duneryl",
      "Ensobarb",
      "Ensodorm",
      "Epanal",
      "Epidorm",
      "Epilol",
      "Episedal",
      "Epsylone",
      "Eskabarb",
      "Etilfen",
      "Euneryl",
      "Ezibarbitur",
      "Fenbital",
      "Fenemal",
      "Fenobarbital",
      "Fenobarbitale",
      "Fenosed",
      "Fenylettae",
      "Gardenal",
      "Gardepanyl",
      "Glysoletten",
      "Haplopan",
      "Haplos",
      "Helional",
      "Hennoletten",
      "Henotal",
      "Hypnaletten",
      "Hypnette",
      "Hypnogen",
      "Hypnolone",
      "Hypnoltol",
      "Hysteps",
      "Lefebar",
      "Leonal",
      "Lephebar",
      "Lepinal",
      "Lepinaletten",
      "Linasen",
      "Liquital",
      "Lixophen",
      "Lubergal",
      "Lubrokal",
      "Lumen",
      "Lumesettes",
      "Lumesyn",
      "Luminal",
      "Lumofridetten",
      "Luphenil",
      "Luramin",
      "Molinal",
      "Neurobarb",
      "Nirvonal",
      "Noptil",
      "Nunol",
      "PHOB",
      "Parkotal",
      "Pharmetten",
      "Phenaemal",
      "Phenemal",
      "Phenemalum",
      "Phenobal",
      "Phenobarb",
      "Phenobarbitalum",
      "Phenobarbitol",
      "Phenobarbitone",
      "Phenobarbitonum",
      "Phenobarbyl",
      "Phenoluric",
      "Phenolurio",
      "Phenomet",
      "Phenonyl",
      "Phenoturic",
      "Phenylaethylbarbitursaeure",
      "Phenylethylbarbiturate",
      "Phenylethylbarbitursaeure",
      "Phenylethylmalonylurea",
      "Phenyletten",
      "Phenylral",
      "Phenyral",
      "Polcominal",
      "Promptonal",
      "Sedabar",
      "Sedicat",
      "Sedizorin",
      "Sedlyn",
      "Sedofen",
      "Sedonal",
      "Sedonettes",
      "Sedophen",
      "Sevenal",
      "Solfoton",
      "Sombutol",
      "Somnolens",
      "Somnoletten",
      "Somnosan",
      "Somonal",
      "Spasepilin",
      "Starifen",
      "Starilettae",
      "Stental",
      "Talpheno",
      "Teolaxin",
      "Teoloxin",
      "Thenobarbital",
      "Theoloxin",
      "Theominal",
      "Triabarb",
      "Tridezibarbitur",
      "Triphenatol",
      "Versomnal",
      "Zadoletten",
      "Zadonal",
      "Aephe Nal",
      "Component Of Antrocol",
      "Component Of Bronkotabs",
      "Component Of Hecadrol",
      "Component Of Primatene P",
      "Component Of Slowten",
      "Component Of Tedral",
      "Elixir Of Phenobarbital",
      "Fen Osed",
      "Fenobarbitale [DCIT]",
      "Le Phebar",
      "Levsin PB Drops And Tablets",
      "PUBERTAL PHENOBARBITAL STUDY",
      "Pamine PB",
      "Phenobarbitalum [INN]",
      "Phenobarbituric Acid",
      "Phenylethyl Barbituric Acid",
      "Phenylethylbarbituric Acid",
      "Solfoton Talpheno",
      "Stental Extentabs",
      "Stental Exte Ntabs",
      "Arco-Lase Plus",
      "Barbilehae (Barbilettae)",
      "Blu-Phen",
      "Chardonna-2",
      "Component Of Valpin 50-PB",
      "Fenobarbital [INN-Spanish]",
      "Gustase-Plus",
      "Hypno-Tablinetten",
      "Luminal (TN)",
      "Nova-Pheno",
      "Phazyme-PB",
      "Phen-Bar",
      "Phenobarbital (PB)",
      "Phenobarbital, Monosodium Salt",
      "Phenobarbitalum [INN-Latin]",
      "SK-Phenobarbital",
      "Seda-Tablinen",
      "Seda-Tabl Inen",
      "Sedonal (Sedative)",
      "Solu-Barb",
      "Valprin 50-PB",
      "Phenobarbital [USAN:INN:JAN]",
      "Phenyl-Ethyl-Barbituric Acid",
      "Acido 5-Fenil-5-Etilbarbiturico",
      "Acido 5-Fenil-5-Etilbarbiturico [Italian]",
      "Phenobarbital (JP15/USP/INN)",
      "5-Ethyl-5-Phenyl-2,4,6(1H,3H,5H)-Pyrimidinetrione;5-Ethyl-5-Phenyl-2,4,6-(1H,3H,5H)Pyrimidinetrione",
      "5-Ethyl-5-Phenyl-2,4,6-Pyrimidinetrione",
      "5-Ethyl-5-Phenyl-Pyrimidine-2,4,6-Trione",
      "5-Ethyl-5-Phenylbarbituric Acid",
      "5-Phenyl-5-Ethylbarbituric Acid;5-Ethyl-5-Phenyl-1,3-Diazinane-2,4,6-Trione",
      "5-Ethyl-5-Phenylpyrimidine-2,4,6(1H,3H,5H)-Trione",
    ],
  },
  {
    primary_id: "D0Y7SN",
    names: ["PTI-188"],
  },
  {
    primary_id: "D0Y7TC",
    names: [
      "Cancer Vaccine",
      "Cancer Vaccine, AVAX/Thomas Jefferson University",
      "Vaccine (Melanoma), Thomas Jefferson",
      "Vaccine (DNP-Modified), Thomas Jefferson",
    ],
  },
  {
    primary_id: "D0Y7TO",
    names: ["Doxacurium"],
  },
  {
    primary_id: "D0Y7TS",
    names: ["Trimetrexate"],
  },
  {
    primary_id: "D0Y7UG",
    names: ["N,N'-(1',6-Hexylene)-Bis-(-)-Nor-MEP", "CHEMBL258505"],
  },
  {
    primary_id: "D0Y7UJ",
    names: [
      "1-(2-(Naphthalen-2-Yl)Ethyl)Piperazine",
      "CHEMBL503234",
      "194943-70-9",
      "SCHEMBL8671948",
      "BDBM50278561",
      "ZINC40379086",
      "AKOS011777831",
      "DA-08636",
    ],
  },
  {
    primary_id: "D0Y7VA",
    names: ["ISIS 101984"],
  },
  {
    primary_id: "D0Y7VJ",
    names: ["L-NIL"],
  },
  {
    primary_id: "D0Y7VM",
    names: ["Forskolin"],
  },
  {
    primary_id: "D0Y7VP",
    names: ["VRS-317"],
  },
  {
    primary_id: "D0Y7WA",
    names: ["SYN-1003", "Analgesic, Synt:em"],
  },
  {
    primary_id: "D0Y7WJ",
    names: [
      "DODECANESULFONATE ION",
      "1-DODECANESULFONIC ACID",
      "1510-16-3",
      "Dodecane-1-Sulphonic Acid",
      "Dodecylsulfonic Acid",
      "Dodecane-1-Sulfonic Acid",
      "UNII-YGB5540EYF",
      "YGB5540EYF",
      "NSC238164",
      "38480-64-7",
      "Dodecylsulfonate",
      "Benzenemethanaminium, N-(C12-C16-Alkyl)-N,N-Dimethyl-, 1-Dodecanesulfonate",
      "3300-34-3",
      "Dodecyl Sulfonic Acid",
      "NSC29062",
      "1-Dodecanesulfonic Acid, Ion(1-)",
      "EINECS 216-146-5",
      "NSC 29062",
      "NSC 238164",
      "Dodecane Sulfonic Acid",
      "AC1L25CW",
      "SCHEMBL16659",
      "CHEMBL1208337",
      "DTXSID3073262",
      "CTK0H6625",
      "LDMOEFOXLIZJOW-UHFFFAOYSA-N",
      "ZINC1651926",
      "2386-53-0 (Hydrochloride Salt)",
    ],
  },
  {
    primary_id: "D0Y7WL",
    names: ["Lorglumide"],
  },
  {
    primary_id: "D0Y7WU",
    names: ["6-(4-Methoxyphenyl)-4-Morpholino-2H-Pyran-2-One", "CHEMBL221410", "SCHEMBL3538432"],
  },
  {
    primary_id: "D0Y7XC",
    names: ["Talipexole"],
  },
  {
    primary_id: "D0Y7XG",
    names: ["Ocriplasmin"],
  },
  {
    primary_id: "D0Y7XJ",
    names: ["Isoquinolone Derivative 1"],
  },
  {
    primary_id: "D0Y7XK",
    names: ["2-(Cinnamyloxy)Pyrido[2,3-D]pyrimidin-4(3H)-One"],
  },
  {
    primary_id: "D0Y7XQ",
    names: [
      "1-[(Prop-2-Ylamino)Ethyl]-1,1-Bisphosphonicacid",
      "CHEMBL407221",
      "SCHEMBL8675602",
      "BDBM50374764",
      "[2-(Isopropylamino)-1-Phosphono-Ethyl]phosphonic Acid",
    ],
  },
  {
    primary_id: "D0Y7YB",
    names: ["HIV Recombinant Vaccine"],
  },
  {
    primary_id: "D0Y7YC",
    names: ["Benzothiazepine Analog 10"],
  },
  {
    primary_id: "D0Y7YD",
    names: [
      "SMT-C1100",
      "BMN-195",
      "Utrophin Modulators (Duchenne Muscular Dystrophy)",
      "VOXC-1100",
      "Utrophin Modulators (Duchennemuscular Dystrophy), VASTox",
      "Utorphin Upregulators (Duchenne Muscular Dystrophy), VASTox/University Of Oxford",
      "Utrophin Modulators (Duchenne Muscular Dystrophy), Summit/BioMarin",
      "Utropine Inducer (Duchenne Muscular Dystrophy), Summit/BioMarin",
    ],
  },
  {
    primary_id: "D0Y7YH",
    names: ["TK-54"],
  },
  {
    primary_id: "D0Y7YL",
    names: [
      "5'-([(Z)-4-Amino-2-Butenyl]methylamino)-5'-Deoxyadenosine",
      "123642-27-3",
      "AC1O6FAJ",
      "SCHEMBL13092296",
      "2-[[[(Z)-4-Aminobut-2-Enyl]-Methylamino]methyl]-5-(6-Aminopurin-9-Yl)Oxolane-3,4-Diol",
    ],
  },
  {
    primary_id: "D0Y7YR",
    names: ["CAT-1920"],
  },
  {
    primary_id: "D0Y7ZD",
    names: [
      "Aminolevulinic Acid Hci",
      "Aminolevulinic Acid",
      "5-Aminolevulinic Acid",
      "5-Amino-4-Oxopentanoic Acid",
      "106-60-5",
      "5-Aminolevulinate",
      "Delta-Aminolevulinic Acid",
      "Pentanoic Acid, 5-Amino-4-Oxo-",
      "Aladerm",
      "5-Amino-4-Oxovaleric Acid",
      "Kerastick",
      "Ameluz",
      "Aminolevulinate",
      "5-ALA",
      "Delta-ALA",
      "5-Amino-4-Oxopentanoate",
      "5-Amino-Levulinate",
      "5-Amino-Levulinic Acid",
      "5-Amino-4-Oxo-Pentanoic Acid",
      "CCRIS 8958",
      "UNII-88755TAZ87",
      "EINECS 203-414-1",
      "Levulinic Acid, 5-Amino-",
      "CHEMBL601",
      "4-Oxo-5-Amino-Pentanoic Acid",
      "CHEBI:17549",
      "ZGXJTSGNIOSYLO-UHFFFAOYSA-N",
      "88755TAZ87",
      "Levulan (TN)",
    ],
  },
  {
    primary_id: "D0Y7ZU",
    names: [
      "Dacarbazine",
      "Biocarbazin",
      "Biocarbazine",
      "DTIC",
      "DTICDome",
      "DTIE",
      "Dacarbazino",
      "Dacarbazinum",
      "Dacatic",
      "Decarbazine",
      "Deticene",
      "Dimethyltriazenoimidazolecarboxamide",
      "ICDMT",
      "ICDT",
      "Biocarbazine R",
      "DTIC Dome",
      "Dimethyl Imidazole Carboxamide",
      "Dimethyl Triazeno Imidazole Carboxamide",
      "Imidazole Carboxamide",
      "HE1150000",
      "Carboxamide (TN)",
      "Carboxamide, Dimethyl Imidazole",
      "DIC (TN)",
      "DTIC (TN)",
      "DTIC-Dome",
      "Dacarbazino [INN-Spanish]",
      "Dacarbazinum [INN-Latin]",
      "Imidazole (TN)",
      "Imidazole Carboxamide, Dimethyl",
      "NPFAPI-05",
      "DTIC-Dome (TN)",
      "Di-Me-Triazenoimidazolecarboxamide",
      "Di-Methyl-Triazenoimidazolecarboxamide",
      "Dtic-Dome (TN)",
      "DTIC, DTIC-Dome, Dacarbazine",
      "Dacarbazine (JAN/USP/INN)",
      "Dacarbazine [USAN:INN:BAN:JAN]",
      "(5E)-5-(Dimethylaminohydrazinylidene)Imidazole-4-Carboxamide",
      "(5Z)-5-(Dimethylaminohydrazinylidene)Imidazole-4-Carboxamide",
      "(Dimethyltriazeno)Imidazolecarboxamide",
      "4(5)-(3,3-Dimethyl-1-Triazeno)Imidazole-4-Carboxamide",
      "4(5)-(3,3-Dimethyl-1-Triazeno)Imidazole-5(4)-Carboxamide",
      "4-(3,3-Dimethyl-1-Triazeno)Imidazole-5-Carboxamide",
      "4-(3,3-Dimethyltriazeno)Imidazole-5-Carboxamide",
      "4-(5)-(3,3-Dimethyl-1-Triazeno)Imidazole-5(4)-Carboxamide",
      "4-(Dimethyltriazeno)Imidazole-5-C Arboxamide",
      "4-(Dimethyltriazeno)Imidazole-5-Carboxamide",
      "4-(Or 5)-(3,3-Dimethyl-1-Triazeno)Imidazole-5(Or 4)-Carboxamide",
      "4-[(1E)-3,3-Dimethyltriaz-1-En-1-Yl]-1H-Imidazole-5-Carboxamide",
      "4-[3,3-Dimethyltriaz-1-En-1-Yl]-1H-Imidazole-5-Carboxamide",
      "5(Or 4)-(Dimethyltriazeno)Imidazol E-4(Or 5)-Carboxamide",
      "5(Or 4)-(Dimethyltriazeno)Imidazole-4(Or 5)-Carboxamide",
      "5-(3,3-Dimethyl-1-Triazeno)Imidazole-4-Carboxamide",
      "5-(3,3-Dimethyl-1-Triazenyl)-1H-Imidazole-4-Carboxamide",
      "5-(3,3-Dimethyl-1-Triazenyl)Imidazole-4-Carboxamide",
      "5-(3,3-Dimethyltri Azeno)Imidazole-4-Carboxamide",
      "5-(3,3-Dimethyltriazeno)-Imidazole-4-Carbamide",
      "5-(3,3-Dimethyltriazeno)Imidazole-4-Carboxamide",
      "5-(3,3-Dimethyltriaz-1-En-1-Yl)-1H-Imidazole-4-Carboxamide",
      "5-(Dimethyltriazeno)-4-Imidazolecarboxamide",
      "5-(Dimethyltriazeno)Imidazole-4-Carboxamide",
      "5-(Dimethyltriazeno)Imidazole-4-Carboximide",
      "5-(Dimethylaminohydrazinylidene)Imidazole-4-Carboxamide",
      "5-[3,3-Dimethyl-1-Triazenyl]imidazole-4-Carboxamide",
    ],
  },
  {
    primary_id: "D0Y8AB",
    names: ["2-Amino-2-Phenyl-1,1-Di(Pyridin-3-Yl)Ethanol", "CHEMBL1090816"],
  },
  {
    primary_id: "D0Y8AN",
    names: [
      "Deprenyl",
      "2323-36-6",
      "CHEBI:50217",
      "N,alpha-Dimethyl-N-2-Propynylphenethylamine",
      "N,alpha-Dimethyl-N-2-Propynylbenzeneethanamine",
      "Methyl(1-Phenylpropan-2-Yl)(Prop-2-Yn-1-Yl)Amine",
      "PHENETHYLAMINE, N,alpha-DIMETHYL-N-2-PROPYNYL-",
      "N-Methyl-1-Phenyl-N-Prop-2-Ynylpropan-2-Amine",
      "Dl-Deprenyl",
      "Phenylisopropyl-N-Methylpropinylamine",
      "AC1L1JT6",
      "CHEMBL8663",
      "SCHEMBL74753",
      "Cid_92913",
      "4-Fluorodeprenyl, (-)-Isomer",
      "BDBM39862",
      "MEZLKOACVSPNER-UHFFFAOYSA-N",
      "HMS2089B08",
      "AC1Q2862",
      "MCULE-3188554308",
      "ACM2323366",
    ],
  },
  {
    primary_id: "D0Y8AW",
    names: ["CB3304"],
  },
  {
    primary_id: "D0Y8BJ",
    names: ["CT-112", "CT-112 (Inflammation)", "CT-112, Curative Technologies", "CT-112 (Inflammation), Cytomedix"],
  },
  {
    primary_id: "D0Y8BW",
    names: ["TMC-647055"],
  },
  {
    primary_id: "D0Y8CU",
    names: [
      "5,6,8-Trichloroquinoline-4-One-3-Carboxylic Acid",
      "5,6,8-Trichloro-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "AC1LEUE0",
      "Oprea1_066465",
      "CHEMBL553336",
      "5,6,8-Trichloro-4-Oxo-1H-Quinoline-3-Carboxylic Acid",
      "SCHEMBL19615682",
      "MolPort-000-563-469",
      "ZINC117346",
      "STK970936",
    ],
  },
  {
    primary_id: "D0Y8DJ",
    names: [
      "(1,2,3,4-Tetrahydro-Isoquinolin-3-Yl)-Methanol",
      "63006-93-9",
      "(1,2,3,4-Tetrahydroisoquinolin-3-Yl)Methanol",
      "1,2,3,4-Tetrahydroisoquinoline-3-Methanol",
      "1,2,3,4-Tetrahydroisoquinolin-3-Ylmethanol",
      "(S)-(-)-1,2,3,4-Tetrahydro-3-Isoquinolinemethanol",
      "62928-94-3",
      "CHEMBL60068",
      "1,2,3,4-Tetrahydro-3-Isoquinolinemethanol",
      "3-Isoquinolinemethanol, 1,2,3,4-Tetrahydro-",
      "3-Hydroxymethyl-1,2,3,4-Tetrahydroisoquinoline",
      "F2147-1627",
      "PubChem5794",
      "AC1MWNWL",
      "(S)-1,2,3,4-Tetrahydroisoquinolylmethan-3-Ol",
      "ACMC-209err",
      "ChemDiv2_003241",
    ],
  },
  {
    primary_id: "D0Y8DP",
    names: ["Rorifone"],
  },
  {
    primary_id: "D0Y8DT",
    names: ["Glycyl-H 1152"],
  },
  {
    primary_id: "D0Y8DZ",
    names: [
      "4-Ethyl-5,6-Dihydro-1H-Pyridin-(2Z)-Ylideneamine",
      "CHEMBL260277",
      "BDBM50237936",
      "AKOS006359116",
      "4-Ethyl-1,2,5,6-Tetrahydropyridine-2-Imine",
    ],
  },
  {
    primary_id: "D0Y8EU",
    names: ["MRS-1220"],
  },
  {
    primary_id: "D0Y8GL",
    names: ["APF-580"],
  },
  {
    primary_id: "D0Y8GN",
    names: ["PMID25772215-Compound-US02014179750M3"],
  },
  {
    primary_id: "D0Y8IE",
    names: [
      "AR-13165",
      "AR-13165 Series",
      "Dual-Targeting Compounds (Glaucoma)",
      "Dual-Targeting Compounds (Glaucoma), Aerie",
    ],
  },
  {
    primary_id: "D0Y8IF",
    names: ["MX-781"],
  },
  {
    primary_id: "D0Y8IJ",
    names: ["Antifungal Agents", "Antifungal Agents, Daiichi Sankyo"],
  },
  {
    primary_id: "D0Y8IS",
    names: ["VP-025"],
  },
  {
    primary_id: "D0Y8JF",
    names: ["PMID25726713-Compound-50"],
  },
  {
    primary_id: "D0Y8JN",
    names: ["2-(3-Methyl-Indole-1-Sulfonyl)-Benzoic Acid", "CHEMBL361251"],
  },
  {
    primary_id: "D0Y8KM",
    names: ["STP-900"],
  },
  {
    primary_id: "D0Y8KN",
    names: ["Ilaris Canakinumab"],
  },
  {
    primary_id: "D0Y8KY",
    names: ["RP73870"],
  },
  {
    primary_id: "D0Y8KZ",
    names: ["AIM-501"],
  },
  {
    primary_id: "D0Y8LD",
    names: ["AS94", "AS 94", "AS-94"],
  },
  {
    primary_id: "D0Y8LO",
    names: ["Clik60"],
  },
  {
    primary_id: "D0Y8LT",
    names: ["AstaFactor", "Astaxanthin"],
  },
  {
    primary_id: "D0Y8ND",
    names: ["VERED"],
  },
  {
    primary_id: "D0Y8NO",
    names: ["RTI-5989-31"],
  },
  {
    primary_id: "D0Y8NZ",
    names: ["Efatutazone"],
  },
  {
    primary_id: "D0Y8OK",
    names: ["Peginterferon Alfa-2a"],
  },
  {
    primary_id: "D0Y8PH",
    names: ["CI-996"],
  },
  {
    primary_id: "D0Y8PM",
    names: ["LJP-1082"],
  },
  {
    primary_id: "D0Y8PT",
    names: ["UFT"],
  },
  {
    primary_id: "D0Y8PY",
    names: ["Glucosamine 6-Phosphate"],
  },
  {
    primary_id: "D0Y8QU",
    names: ["CVS-1578", "174960-52-2", "186318-81-0", "CV-1778", "CVS-1778", "CVS-1832", "CVS-1897", "CVS-2097"],
  },
  {
    primary_id: "D0Y8RM",
    names: ["(1R)-1,2,2-TRIMETHYLPROPYL (R)-METHYLPHOSPHINATE"],
  },
  {
    primary_id: "D0Y8RS",
    names: ["DSP-6745"],
  },
  {
    primary_id: "D0Y8SB",
    names: ["L-249313"],
  },
  {
    primary_id: "D0Y8SC",
    names: ["PMID25726713-Compound-34"],
  },
  {
    primary_id: "D0Y8SK",
    names: [
      "DYNORPHIN A",
      "Dynorphin A",
      "80448-90-4",
      "Dynorphin A Porcine",
      "Dynorphin-(1-17)",
      "UNII-9M18T0TD14",
      "9M18T0TD14",
      "MFCD00076351",
      "Dynorphin A1-17",
      "Dynorphin A (Swine)",
      "Dynorphin Acetate",
      "Dynorphin A (1-17), Porcine",
      "JMNJYGMAUMANNW-FIXZTSJVSA-N",
      "BDBM50096785",
      "AKOS024457469",
      "Dynorphin A Porcine, &gt",
      "NCGC00167144-01",
    ],
  },
  {
    primary_id: "D0Y8SS",
    names: ["MufroSyn"],
  },
  {
    primary_id: "D0Y8TZ",
    names: ["PF-06410293"],
  },
  {
    primary_id: "D0Y8UB",
    names: ["Agomelatine"],
  },
  {
    primary_id: "D0Y8UC",
    names: [
      "DIFLUOROBENZTROPINE",
      "CHEMBL317757",
      "4,4'-DifluoroBZT",
      "AC1MZYZJ",
      "Biomol-NT_000053",
      "Lopac0_000439",
      "SCHEMBL6478352",
      "BPBio1_001309",
      "SCHEMBL12648564",
      "BDBM86701",
      "CHEBI:110199",
      "NCGC00024872-02",
      "LS-190982",
      "BRD-A85216385-003-01-6",
      "3-[bis(4-Fluorophenyl)Methoxy]-8-Methyl-8-Azabicyclo[3.2.1]octane",
      "3-[Bis-(4-Fluoro-Phenyl)-Methoxy]-8-Methyl-8-Azonia-Bicyclo[3.2.1]octane",
      "3-(Bis(4-Fluorophenyl)Methoxy)-8-Methyl-8-Aza-Bicyclo[3.2.1]octane",
    ],
  },
  {
    primary_id: "D0Y8UD",
    names: ["Cypate-[(RGD)4-NH2]1"],
  },
  {
    primary_id: "D0Y8UF",
    names: ["Propylpyrazoletriol"],
  },
  {
    primary_id: "D0Y8UP",
    names: ["2-Furan-2-Yl-7-Methyl-1H-[1,8]naphthyridin-4-One", "9008-02-0", "CHEMBL130391"],
  },
  {
    primary_id: "D0Y8UV",
    names: ["EMD-1204831"],
  },
  {
    primary_id: "D0Y8UY",
    names: ["Ac-W-F-F-N-Y-Y-W", "CHEMBL405677"],
  },
  {
    primary_id: "D0Y8VC",
    names: ["M-5200", "Anti-Allergic Dermatitis Program, Maruho"],
  },
  {
    primary_id: "D0Y8VJ",
    names: ["ISIS 1821"],
  },
  {
    primary_id: "D0Y8VQ",
    names: ["Lobatamide F"],
  },
  {
    primary_id: "D0Y8XJ",
    names: [
      "Docosapentaenoic Acid",
      "Docosa-4,7,10,13,16-Pentaenoic Acid",
      "2313-14-6",
      "25182-74-5",
      "4-7-10-13-16 Docsapentaenoic Acid",
      "CTK1A5509",
      "CTK1A4905",
      "AVKOENOBFIYBSA-UHFFFAOYSA-N",
      "4,7,10,13,16-Docosapentaenoicacid",
      "4,7,10,13,16-Docosapentaenoicacid, (4Z,7Z,10Z,13Z,16Z)-",
      "CH3(CH2)4-CH=CHCH2CH=CHCH2CH=CHCH2CH=CHCH2CH=CH(CH2)2COOH",
    ],
  },
  {
    primary_id: "D0Y8YD",
    names: ["Antifungal Oligonucleotides", "Antifungal Oligonucleotides (Oral Candidiasis)"],
  },
  {
    primary_id: "D0Y8YE",
    names: ["Lysine-Specific Demethylase 1 Inhibitors"],
  },
  {
    primary_id: "D0Y8YN",
    names: [
      "(3-Amino-Propyl)-Hexyl-Phosphinic Acid",
      "3-Aminopropyl(Hexyl)Phosphinic Acid",
      "3-Aphpa",
      "123691-31-6",
      "3-Aminopropyl(N-Hexyl)Phosphinic Acid",
      "CHEMBL113571",
      "Phosphinic Acid,(3-Aminopropyl)Hexyl- (9CI)",
      "ACMC-20mqpl",
      "AC1L2WN2",
      "SCHEMBL6354602",
      "CTK4B3595",
      "DTXSID90154066",
    ],
  },
  {
    primary_id: "D0Y8YW",
    names: ["P53-MDM2 Binding Inhibitors"],
  },
  {
    primary_id: "D0Y8ZN",
    names: ["Ofatumumab", "Arzerra (TN)"],
  },
  {
    primary_id: "D0Y8ZZ",
    names: ["GI-10001"],
  },
  {
    primary_id: "D0Y9AQ",
    names: [
      "DCB-3503",
      "UNII-TXF835U41K",
      "NSC-716802",
      "CHEMBL399454",
      "TXF835U41K",
      "NSC716802",
      "AC1L8JU6",
      "(13aS,14S)-Tylophorinine",
      "87302-58-7",
      "SCHEMBL12389871",
      "CTK7A0055",
      "BDBM50213933",
      "Dibenzo(F,H)Pyrrolo(1,2-B)Isoquinolin-14-Ol, 9,11,12,13,13a,14-Hexahydro-2,3,6,7-Tetramethoxy-, (13aS,14S)-",
      "NCI60_040362",
      "Dibenzo(F,H)Pyrrolo(1,2-B)Isoquinolin-14-Ol, 9,11,12,13,13a,14-Hexahydro-2,3,6,7-Tetramethoxy-, (13as-Trans)-",
      "(13aS,14S)-2,3,6,7-Tetramethoxy-9,11,12,13,13a,14-Hexahydrophenanthro[9,10-F]indolizin-14-Ol",
      "Dibenzo[f,2-B]isoquinolin-14-Ol, 9",
    ],
  },
  {
    primary_id: "D0Y9BO",
    names: ["WAY-169916"],
  },
  {
    primary_id: "D0Y9BU",
    names: ["CCG-100602", "Compound 4g [PMID: 19963382]", "CCG100602", "CCG 100602"],
  },
  {
    primary_id: "D0Y9BX",
    names: ["Peptide P277"],
  },
  {
    primary_id: "D0Y9CC",
    names: ["TM-31"],
  },
  {
    primary_id: "D0Y9CV",
    names: ["Ketolides"],
  },
  {
    primary_id: "D0Y9ES",
    names: ["AD-0802"],
  },
  {
    primary_id: "D0Y9EU",
    names: [
      "4-Heptyl-N-(Quinolin-3-Yl)Benzamide",
      "CHEMBL406860",
      "AC1MYO1K",
      "SCHEMBL846864",
      "4-Heptyl-N-Quinolin-3-Ylbenzamide",
      "BDBM50376279",
    ],
  },
  {
    primary_id: "D0Y9EW",
    names: ["Vemurafenib"],
  },
  {
    primary_id: "D0Y9FA",
    names: ["PMID29338548-Compound-19"],
  },
  {
    primary_id: "D0Y9FF",
    names: ["Human Factor Viii"],
  },
  {
    primary_id: "D0Y9FX",
    names: ["Example 7 [US8664233]"],
  },
  {
    primary_id: "D0Y9GA",
    names: [
      "Dehydroxymethylepoxyquinomicin",
      "DHMEQ",
      "DHM2EQ",
      "DHM3EQ",
      "Epoxyquinomicin Derivatives, Keio/Mercian",
      "NFkappaB Inhibitors, Keio/Mercian",
      "(-)-DHM2EQ",
    ],
  },
  {
    primary_id: "D0Y9GF",
    names: ["Entyvio"],
  },
  {
    primary_id: "D0Y9GK",
    names: [
      "4-(4-Aminophenyl)-1H-Indazol-3yl-Amine",
      "4-(4-Aminophenyl)-1H-Indazol-3-Amine",
      "819058-89-4",
      "CHEMBL222778",
      "Flt3 Inhibitor IV",
      "SCHEMBL421242",
      "DTXSID90583110",
      "3-Amino-4-(4-Aminophenyl)Indazole",
      "BDBM50207472",
      "ZINC35324130",
      "AKOS025401748",
      "QC-1103",
      "AC-25079",
      "1H-Indazol-3-Amine,4-(4-Aminophenyl)-",
    ],
  },
  {
    primary_id: "D0Y9GM",
    names: ["Pexiganan"],
  },
  {
    primary_id: "D0Y9HD",
    names: ["NSC-625409"],
  },
  {
    primary_id: "D0Y9HH",
    names: [
      "SIB-1508Y",
      "CHEMBL111580",
      "3-Ethynyl-5-(1-Methylpyrrolidin-2-Yl)Pyridine",
      "179120-51-5",
      "5-Ethynyl-3-(1-Methyl-2-Pyrrolidinyl)Pyridine",
      "5-Ethynyl Nicotine",
      "179120-92-4",
      "3-Ethynyl-5-(1-Methyl-2-Pyrrolidinyl)Pyridine",
      "AC1L326X",
      "SCHEMBL1155706",
      "BDBM86544",
      "NUPUDYKEEJNZRG-UHFFFAOYSA-N",
      "CHEBI:125396",
      "NSC_6442277",
      "CAS_192231-16-6",
      "3-Ethynyl-5-(1-Methylpyrrolidin-2-Yl)-Pyridine",
      "3-(1-Methylpyrrolidine-2-Yl)-5-Ethynylpyridine",
      "A812432",
    ],
  },
  {
    primary_id: "D0Y9HT",
    names: ["JWH-363"],
  },
  {
    primary_id: "D0Y9HW",
    names: ["ISIS-TTR"],
  },
  {
    primary_id: "D0Y9IB",
    names: ["UR-PG153", "CHEMBL499301", "GTPL1201", "BDBM50413181"],
  },
  {
    primary_id: "D0Y9JY",
    names: ["XGP-410", "Acne Therapy, Xaragen"],
  },
  {
    primary_id: "D0Y9KR",
    names: ["SSR-103800"],
  },
  {
    primary_id: "D0Y9LL",
    names: ["Lorcainide"],
  },
  {
    primary_id: "D0Y9MP",
    names: ["IL-2 Antibody (Anti-Tumor), Hybritech", "DMS-1.10", "Anti-Human RIL-2 Antibody, Hybritech"],
  },
  {
    primary_id: "D0Y9NA",
    names: ["MN-2011"],
  },
  {
    primary_id: "D0Y9NE",
    names: ["NWP-08"],
  },
  {
    primary_id: "D0Y9OS",
    names: ["Euro-Celtique 1"],
  },
  {
    primary_id: "D0Y9PH",
    names: [
      "Phenylacetyl-Arg-Val-Arg-4-Amidinobenzylamide",
      "CHEMBL566340",
      "SCHEMBL7920067",
      "GTPL6564",
      "BDBM50303774",
    ],
  },
  {
    primary_id: "D0Y9PQ",
    names: ["AM-1101"],
  },
  {
    primary_id: "D0Y9PZ",
    names: ["Efungumab"],
  },
  {
    primary_id: "D0Y9RD",
    names: ["H-Tyr-Pro-Ala-Phe-NH2"],
  },
  {
    primary_id: "D0Y9RN",
    names: ["Salinosporamide B"],
  },
  {
    primary_id: "D0Y9RO",
    names: ["Dexanabinol"],
  },
  {
    primary_id: "D0Y9RY",
    names: [
      "4,6-Dideoxy-4-Amino-Alpha-D-Glucose",
      "4,6-DIDEOXY-4-AMINO-ALPHA-D-GLUCOSE",
      "AC1L9FSA",
      "SCHEMBL1543515",
      "DB03439",
      "4-Amino-4,6-Dideoxy-Alpha-D-Glucopyranose",
      "AGL",
      "(2S,3R,4S,5S,6R)-5-Amino-6-Methyloxane-2,3,4-Triol",
    ],
  },
  {
    primary_id: "D0Y9SB",
    names: ["RG7444"],
  },
  {
    primary_id: "D0Y9SE",
    names: ["KT 5823"],
  },
  {
    primary_id: "D0Y9SI",
    names: ["3-(4-Hexylthiosemicarbazono)Methyl-Benzoic Acid", "CHEMBL228317"],
  },
  {
    primary_id: "D0Y9ST",
    names: ["PMID25666693-Compound-64"],
  },
  {
    primary_id: "D0Y9TF",
    names: ["Ac-ICV(5MeW)QDWGAHRCT-NH2"],
  },
  {
    primary_id: "D0Y9TL",
    names: [
      "5-Chloro-3,4-Dihydroquinazolin-2-Amine",
      "CHEMBL401541",
      "2-Amino-5-Chlor-3,4-Dihydrochinazolin",
      "109319-86-0",
    ],
  },
  {
    primary_id: "D0Y9TP",
    names: ["PMID28870136-Compound-63"],
  },
  {
    primary_id: "D0Y9TQ",
    names: ["Cytotect CP"],
  },
  {
    primary_id: "D0Y9TS",
    names: [
      "Ceruletide",
      "Caerulein",
      "Cerulein",
      "Ceruletida",
      "Ceruletidum",
      "Ceruletida [INN-Spanish]",
      "Ceruletidum [INN-Latin]",
      "Ceruletide (USAN/INN)",
      "Ceruletide [USAN:INN:BAN]",
      "5-Oxo-L-Prolyl-L-Glutaminyl-L-Alpha-Aspartyl-O-Sulfo-L-Tyrosyl-L-Threonylglycyl-L-Tryptophyl-L-Methionyl-L-Alpha-Aspartyl-L-Phenylalaninamide",
      "5-Oxo-L-Prolyl-L-Glutaminyl-L-Aspartyl-L-Tyrosyl-L-Threonylglycyl-L-Tryptophyl-L-Methionyl-L-Aspartylphenyl-L-Alaninamide 4-(Hydrogen Sulfate) (Ester)",
      "5-Oxo-L-Prolyl-L-Glutaminyl-L-A-Aspartyl-O-Sulfo-L-Tyrosyl-L-Threonylglycyl-L-Tryptophyl-L-Methionyl-L-A-Aspartyl-L-Phenylalaninamide",
    ],
  },
  {
    primary_id: "D0Y9UV",
    names: ["Peptide Analog 72"],
  },
  {
    primary_id: "D0Y9VB",
    names: ["PMID25399762-Compound-Figure1-Chukrasone B"],
  },
  {
    primary_id: "D0Y9VC",
    names: ["IMT-1012 Immunotherapeutic Vaccine"],
  },
  {
    primary_id: "D0Y9WZ",
    names: ["SB-298"],
  },
  {
    primary_id: "D0Y9XD",
    names: ["Dalvastatin"],
  },
  {
    primary_id: "D0Y9XK",
    names: ["1-Phenyl-2-(1-Phenyl-1H-Tetrazol-5-Yloxy)Ethanone", "CHEMBL1082497"],
  },
  {
    primary_id: "D0Y9XO",
    names: ["SDZ-FOX-988"],
  },
  {
    primary_id: "D0Y9XS",
    names: ["Indanone And Indandione Derivative 1"],
  },
  {
    primary_id: "D0Y9XU",
    names: ["Turgenpumatucel-L"],
  },
  {
    primary_id: "D0Y9YG",
    names: ["SLV-342"],
  },
  {
    primary_id: "D0Y9YN",
    names: ["Velimogene Aliplasmid", "Allovectin (TN)"],
  },
  {
    primary_id: "D0Y9ZE",
    names: [
      "Milrinone",
      "Corotrop",
      "Corotrope",
      "Milrila",
      "Milrinona",
      "Milrinonum",
      "Primacor",
      "Milrinona [Spanish]",
      "Milrinonum [Latin]",
      "M 4659",
      "M1663",
      "YM 018",
      "MILRINONE LACTATE IN DEXTROSE 5% IN PLASTIC CONTAINER",
      "Milrila (TN)",
      "PRIMACOR IN DEXTROSE 5% IN PLASTIC CONTAINER",
      "Primacor (TN)",
      "WIN 47203-2",
      "Win-47203",
      "Milrinone [USAN:BAN:INN]",
      "Win-47203-2",
      "Milrinone (JAN/USAN/INN)",
      "1,6-Dihydro-2-Methyl-6-Oxo(3,4'-Bipyridine)-5-Carbonitrile",
      "1,6-Dihydro-2-Methyl-6-Oxo-(3,4&prime;-Bipyridine)-5-Carbonitrile",
      "1,6-Dihydro-2-Methyl-6-Oxo-(3,4'-Bipyridine)-5-Carbonitrile",
      "1,6-Dihydro-2-Methyl-6-Oxo-3,4-Bipyridine-5-Carbonitrile",
      "1,6-Dihydro-2-Methyl-6-Oxo-[3,4'-Bipyridine]-5-Carbonitrile",
      "111GE027",
      "2-Methyl-6-Oxo-1,6-Dihydro-3,4'-Bipyridine-5-Carbonitrile",
      "3-Cyano-6-Methyl-5-(4-Pyridyl)-2-Pyridone",
      "6-Methyl-5-(4-Pyridyl)-2-Pyridone-3-Carbonitrile",
      "6-Methyl-2-Oxo-5-Pyridin-4-Yl-1H-Pyridine-3-Carbonitrile",
    ],
  },
  {
    primary_id: "D0Y9ZO",
    names: [
      "4-(4-Fluoro-Phenyliminomethyl)-Benzenesulfonamide",
      "CHEMBL272622",
      "SCHEMBL5312313",
      "JAKLWQFGJXPTHN-UHFFFAOYSA-N",
      "BDBM50371742",
    ],
  },
  {
    primary_id: "D0Y9ZR",
    names: ["ISIS 103635"],
  },
  {
    primary_id: "D0YA0N",
    names: ["BR3-Fc"],
  },
  {
    primary_id: "D0YA1B",
    names: ["Flotetuzumab"],
  },
  {
    primary_id: "D0YA1U",
    names: ["Trabectedin"],
  },
  {
    primary_id: "D0YA2L",
    names: ["Teriparatide"],
  },
  {
    primary_id: "D0YA6M",
    names: ["Aza-Phenalene-3-Ketone Derivative 1"],
  },
  {
    primary_id: "D0YA6U",
    names: ["ELN-864709"],
  },
  {
    primary_id: "D0YA7R",
    names: [
      "MeTRH",
      "38983-06-1",
      "His(1-Methyl)-TRH",
      "[3H]MeTRH",
      "(2S)-N-[(2S)-1-[(2S)-2-Carbamoylpyrrolidin-1-Yl]-3-(3H-Imidazol-4-Yl)-1-Oxopropan-2-Yl]-1-Methyl-5-Oxopyrrolidine-2-Carboxamide",
      "[3H]Me-Thyrotropin-Releasing Hormone",
      "AC1NSKDH",
      "His(1-Methyl)-Thyrotropin-Releasing Hormone",
      "GTPL2141",
      "GTPL2137",
      "CTK1A8669",
      "DTXSID50415524",
      "PGlu-His(1(Tau)-Methyl)-ProNH2",
      "[3H]pGlu-His(1(Tau)-Methyl)-ProNH2",
      "L-Prolinamide, 1-Methyl-5-Oxo-L-Prolyl-L-Histidyl-",
      "PGlu-His(1(T)-Methyl)-ProNH2",
    ],
  },
  {
    primary_id: "D0YA8C",
    names: ["2-Benzyl-N1-Hydroxy-N3-(3-Phenylpropyl)Malonamide", "CHEMBL331784", "SCHEMBL7357043", "BDBM50062453"],
  },
  {
    primary_id: "D0YA8J",
    names: ["Grayanotoxin III"],
  },
  {
    primary_id: "D0YA9Z",
    names: [
      "Ampicillin",
      "ABPC",
      "Acillin",
      "Adobacillin",
      "Alpen",
      "Amblosin",
      "Amcill",
      "Amfipen",
      "Aminobenzylpenicillin",
      "Ampen",
      "Ampichel",
      "Ampicil",
      "Ampicilina",
      "Ampicillanyl",
      "Ampicillina",
      "Ampicilline",
      "Ampicillinum",
      "Ampicin",
      "Ampifarm",
      "Ampikel",
      "Ampimed",
      "Ampipenin",
      "Ampiscel",
      "Ampisyn",
      "Ampivax",
      "Ampivet",
      "Amplacilina",
      "Amplin",
      "Amplipenyl",
      "Amplisom",
      "Amplital",
      "Austrapen",
      "Binotal",
      "Bonapicillin",
      "Britacil",
      "Campicillin",
      "Cimex",
      "Copharcilin",
      "Delcillin",
      "Deripen",
      "Divercillin",
      "Doktacillin",
      "Duphacillin",
      "Grampenil",
      "Guicitrina",
      "Guicitrine",
      "Lifeampil",
      "Morepen",
      "Norobrittin",
      "Nuvapen",
      "Omnipen",
      "Orbicilina",
      "Penbristol",
      "Penbritin",
      "Penbrock",
      "Penicline",
      "Penimic",
      "Pensyn",
      "Pentrex",
      "Pentrexl",
      "Pentrexyl",
      "Polycillin",
      "Ponecil",
      "Princillin",
      "Principen",
      "QIDamp",
      "Racenacillin",
      "Rosampline;Roscillin",
      "Semicillin",
      "Servicillin",
      "Sumipanto",
      "Supen",
      "Synpenin",
      "Texcillin",
      "Tokiocillin",
      "Tolomol",
      "Totacillin",
      "Totalciclina",
      "Totapen",
      "Trifacilina",
      "Ukapen",
      "Ultrabion",
      "Ultrabron",
      "Vampen",
      "Viccillin",
      "Wypicil",
      "Amfipen V",
      "Amipenix S",
      "Ampicillin A",
      "Ampicillin Anhydrous",
      "Ampicillin Base",
      "Ampicillin Acid",
      "Ampicillin Anhydrate",
      "Ampicillina [DCIT]",
      "Anhydrous Ampicillin",
      "Olin Kid",
      "Pen A",
      "Pen A Oral",
      "Pen Ampil;Penbritin Paediatric",
      "Penbritin Syrup",
      "Pfizerpen A",
      "Semicillin R",
      "Viccillin S",
      "AY 6108",
      "BA 7305",
      "BRL 1341",
      "Bayer 5427",
      "HI 63",
      "P 50",
      "Principen 125",
      "Principen 250",
      "Principen 500",
      "SQ 17382",
      "AB-PC",
      "AB-PCSol",
      "AY-6108",
      "Ambidrin (TN)",
      "Ampi-Co",
      "Ampi-Tab",
      "Ampi-Bol",
      "Ampicilina [INN-Spanish]",
      "Ampicilline [INN-French]",
      "Ampicillinum [INN-Latin]",
      "Ampipenin, Nt3",
      "Ampy-Penyl",
      "Anhydrous Ampicillin (JP15)",
      "BRL-1341",
      "D-Ampicillin",
      "D-Cillin",
      "KS-R1",
      "Novo-Ampicillin",
      "OMNIPEN (AMPICILLIN)",
      "Omnipen (TN)",
      "Omnipen-N",
      "P-50",
      "Penbritin-S",
      "Penicillin, Aminobenzyl",
      "Pfizerpen-A",
      "Polycillin-N",
      "Polyflex (Veterinary)",
      "Ro-Ampen",
      "SK-Ampicillin",
      "Totacillin (Sodium)",
      "Totacillin-N",
      "WY-5103",
      "Ampicillin (USP/INN)",
      "AMPICILLIN (SEE ALSO AMPICILLIN TRIHYDRATE 7177-48-2)",
      "Ampicillin [USAN:BAN:INN:JAN]",
      "Ampicillin [USAN:INN:BAN:JAN];D-(-)-Ampicillin",
      "D-(-)-Alpha-Aminobenzylpenicillin",
      "D-(-)-Alpha-Aminopenicillin",
      "D-(-)-6-(Alpha-Aminophenylacetamido)Penicillanic Acid",
      "6-(D(-)-Alpha-Aminophenylacetamido)Penicillanic Acid",
      "6beta-[(2R)-2-Amino-2-Phenylacetamido]-2,2-Dimethylpenam-3alpha-Carbonyl",
      "6beta-[(2R)-2-Amino-2-Phenylacetamido]-2,2-Dimethylpenam-3alpha-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0YB0K",
    names: ["7-Trans-OH-PIPAT", "8-OH-PIPAT"],
  },
  {
    primary_id: "D0YB1G",
    names: ["Fentiazac"],
  },
  {
    primary_id: "D0YB2Y",
    names: ["AMG 853"],
  },
  {
    primary_id: "D0YB3D",
    names: ["CytoregUNO"],
  },
  {
    primary_id: "D0YB3N",
    names: ["HTNV + PUUV DNA Vaccine"],
  },
  {
    primary_id: "D0YB3W",
    names: [
      "CI-1033",
      "Canertinib",
      "Canertinib HCl",
      "Canertinib Dihydrochloride",
      "Canertinib Dihydrochloride [USAN]",
      "CI1033",
      "PD 183805",
      "Canertinib Dihydrochloride (USAN)",
      "PD-0183805",
      "PD-183805",
      "Canertinib, PD-183805, CI1033, PD183805",
      "N-[4-(3-Chloro-4-Fluorophenylamino)-7-(3-Morpholin-4-Ylpropoxy)Quinazolin-6-Yl]acrylamide Dihydrochloride",
      "N-[4-[(3-Chloro-4-Fluorophenyl)Amino]-7-[3-(4-Morpholinyl)Propoxy]-6-Quinazolinyl]-2-Propenamide Dihydrochloride",
      "N-[4-(3-Chloro-4-Fluoroanilino)-7-(3-Morpholin-4-Ylpropoxy)Quinazolin-6-Yl]prop-2-Enamide",
      "N-[4-(3-Chloro-4-Fluoroanilino)-7-(3-Morpholin-4-Ylpropoxy)Quinazolin-6-Yl]prop-2-Enamide Dihydrochloride",
      "N-(4-(3-Chloro-4-Fluorophenyl)Amino)-7-(3-Morpholin-4-Yl)Propoxy)Quinazolin-6-Yl)Prop-2-Enamide Dihydrochloride",
      "N-{4-[(3-Chloro-4-Fluorophenyl)Amino]-7-[3-(Morpholin-4-Yl)Propoxy]quinazolin-6-Yl}prop-2-Enamide",
      "N-(4-((3-Chloro-4-Fluorophenyl)Amino)-7-(3-(Morpholin-4-Yl)Propoxy)Quinazolin-6-Yl)Prop-2-Enamide",
      "2-Propenamide, N-(4-((3-Chloro-4-Fluorophenyl) Amino)-7-(3-(4-Morpholinyl) Propoxy)-6-Quinazolinyl)-, Dihydrochloride",
      "2-Propenamide, N-(4-((3-Chloro-4-Fluorophenyl)Amino)-7-(3-(4-Morpholinyl)Propoxy)-6-Quinazolinyl)-, Dihydrochloride",
    ],
  },
  {
    primary_id: "D0YB3X",
    names: [
      "3-Chloro-N-(4-Phenyl-Thiazol-2-Yl)-Benzamide",
      "CHEMBL61222",
      "AC1LFIX3",
      "Oprea1_263322",
      "Oprea1_095427",
      "SCHEMBL2754070",
      "2-[(3-Chlorophenyl)Carboxamido]-4-Phenyl-1,3-Thiazole",
      "OHXHEHNTSIBWMV-UHFFFAOYSA-N",
      "MolPort-001-997-617",
      "ZINC208080",
      "BDBM50101789",
      "AKOS000651093",
      "MCULE-4878334658",
      "BAS 04456777",
      "50591-72-5",
      "N-(4-Phenyl-2-Thiazolyl)-3-Chlorobenzamide",
    ],
  },
  {
    primary_id: "D0YB4D",
    names: ["Eldacimibe", "ACA-147", "WAY-ACA-147"],
  },
  {
    primary_id: "D0YB5G",
    names: ["Sodium Cellulose Po4", "Calcibind (TN)"],
  },
  {
    primary_id: "D0YB5S",
    names: ["DCVax-Prostate Cancer Vaccine"],
  },
  {
    primary_id: "D0YB5Z",
    names: ["ARX-Trail"],
  },
  {
    primary_id: "D0YB6P",
    names: ["YP-004"],
  },
  {
    primary_id: "D0YB7F",
    names: ["MT-II"],
  },
  {
    primary_id: "D0YB7P",
    names: ["IFN-Kinoid"],
  },
  {
    primary_id: "D0YB9P",
    names: [
      "GLUTAMATE",
      "DL-Glutamic Acid",
      "617-65-2",
      "Glutamic Acid, DL-",
      "Glutamic Acid DL-Form",
      "DL-Glu",
      "NSC 9967",
      "Glutaminsaeure",
      "H-DL-Glu-OH",
      "NSC 206301",
      "EINECS 210-522-2",
      "BRN 1723799",
      "WHUUTDBJXJRKMK-UHFFFAOYSA-N",
      "NSC620337",
      "Poly-L-Glutamic Acid (MW50,600)",
      "DL-Glutamicacid",
      "2-Azanylpentanedioic Acid",
      "L -2-Aminopentanedioic Acid",
      "Glutamic Acid, D-",
      "Poly-L-Glutamic Acid",
      "L-(5-14c)Glutamic Acid",
    ],
  },
  {
    primary_id: "D0YC1R",
    names: ["BMS-520"],
  },
  {
    primary_id: "D0YC1Z",
    names: ["Doxorubicin-Hemoglobin Conjugate"],
  },
  {
    primary_id: "D0YC2M",
    names: ["PMID28092474-Compound-32s"],
  },
  {
    primary_id: "D0YC4F",
    names: ["K-828-AB"],
  },
  {
    primary_id: "D0YC4O",
    names: [
      "Xylopyranose",
      "A-D-Xylopyranose",
      "Alpha-D-Xylopyranose",
      "6763-34-4",
      "Alpha-D-Xylose",
      "(2S,3R,4S,5R)-Oxane-2,3,4,5-Tetrol",
      "UNII-C791ZE5K1W",
      "C791ZE5K1W",
      "SRBFZHDQGSBBOR-LECHCGJUSA-N",
      "EINECS 247-395-8",
      "A-Xylopyranose",
      "Pentopyranose #",
      "XYS",
      "130550-15-1",
      "Holzzucker",
      "Alpha-Xylose",
      "Losan",
      "Alpha-Xylopyranose",
      "Bmse000898",
      "Bmse000026",
      "AC1L1LN3",
      "AC1Q2AP1",
      "SCHEMBL345574",
      "CCRIS 9027",
      "CTK4B6799",
      "KS-00000PYU",
      "CHEBI:28518",
      "MolPort-004-780-273",
      "Tetrahydropyran-2,3,4,5-Tetraol",
      "ZINC1529214",
      "AKOS024462556",
      "MCULE-4707689321",
      "RP21333",
      "NE16693",
      "DB03389",
      "AK-93509",
    ],
  },
  {
    primary_id: "D0YC6Z",
    names: ["PMID30247903-Compound-General Structure34"],
  },
  {
    primary_id: "D0YC7C",
    names: ["Dihydropyridine Compound 3"],
  },
  {
    primary_id: "D0YC7U",
    names: ["Eptaplatin"],
  },
  {
    primary_id: "D0YC8W",
    names: ["Folotynpralatrexate"],
  },
  {
    primary_id: "D0YC9M",
    names: ["AllerT"],
  },
  {
    primary_id: "D0YD1Q",
    names: ["PMID25684022-Compound-EP20041486488"],
  },
  {
    primary_id: "D0YD6H",
    names: ["HYRTIOSULAWESINE"],
  },
  {
    primary_id: "D0YD6K",
    names: [
      "APC-200",
      "CPC-200",
      "MDL-72527",
      "Acetyl Polyamine Oxidase Inhibitor (Prostate Cancer), University Of Wisconsin/Colby",
    ],
  },
  {
    primary_id: "D0YD7B",
    names: ["PMID29671355-Compound-38b"],
  },
  {
    primary_id: "D0YD9E",
    names: ["PF-00212062"],
  },
  {
    primary_id: "D0YD9F",
    names: [
      "4-Benzenesulfonylamino-Benzenesulfonamide",
      "CHEMBL23796",
      "4461/11/4",
      "N4-(Benzenesulfonyl)Sulfanilamide",
      "4-(Benzenesulfonamido)Benzenesulfonamide",
      "AC1LGK72",
      "BDBM16658",
      "Aromatic Sulfonamide Compound 23",
      "CTK1D2369",
      "DTXSID20356379",
      "MolPort-001-887-057",
      "ZINC340351",
      "AKOS001088105",
      "1-N-Benzenebenzene-1,4-Disulfonamide",
      "MCULE-1421025287",
      "4-[(Phenylsulfonyl)Amino]benzenesulfonamide",
      "Benzenesulfonamide, N-[4-(Aminosulfonyl)Phenyl]-",
    ],
  },
  {
    primary_id: "D0YD9J",
    names: [
      "4-[5-Chloro-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(4-Pyridylmethylene)Indane 8a",
      "SCHEMBL4231646",
      "CHEMBL175554",
      "BDBM8618",
      "SCHEMBL4231651",
      "AC1O705Z",
      "ZINC13610827",
      "4-[(E)-(5-Chloro-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0YD9W",
    names: ["(+/-)-Threo-N-(3-Phenylpropyl)Methylphenidate", "CHEMBL1254196"],
  },
  {
    primary_id: "D0YE0P",
    names: ["PMID30273516-Compound-14"],
  },
  {
    primary_id: "D0YE1X",
    names: [
      "4-(3,4-Dimethoxy-Phenoxy)-6,7-Dimethoxy-Quinoline",
      "CHEMBL327300",
      "Ki-6783",
      "6,7-Dimethoxy-4-(3,4-Dimethoxyphenoxy)Quinoline",
      "SCHEMBL925876",
      "ZQSHUGGLESWJFP-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0YE2N",
    names: ["Eperezolid"],
  },
  {
    primary_id: "D0YE2Z",
    names: [
      "(S)-Enastron",
      "AC1LFOER",
      "2x7c",
      "ZINC2601603",
      "(4S)-4-(3-Hydroxyphenyl)-2-Sulfanylidene-1,3,4,6,7,8-Hexahydroquinazolin-5-One",
    ],
  },
  {
    primary_id: "D0YE4B",
    names: ["1-Phenyl-3-(2-(Pyridin-4-Yl)Thiazol-4-Yl)Urea", "Acyclic Urea, 1", "CHEMBL392228", "BDBM20816"],
  },
  {
    primary_id: "D0YE5K",
    names: ["N-(3-Phenyl-[1,2,4]thiadiazol-5-Yl)-Benzamide"],
  },
  {
    primary_id: "D0YE6C",
    names: ["BN-Brachyury Cancer Vaccine"],
  },
  {
    primary_id: "D0YE8Q",
    names: ["PMID26666989-Compound-Figure9middle01"],
  },
  {
    primary_id: "D0YF1U",
    names: ["Catalytic Ribozymes"],
  },
  {
    primary_id: "D0YF2M",
    names: ["N-Oxalyl-D-Tyrosine Derivative 6"],
  },
  {
    primary_id: "D0YF2T",
    names: [
      "HepaVaxx C",
      "Chimigen Vaccine (HCV), ViRexx",
      "Therapeutic Hepatitis C Virus Vaccine (Chimigen), Paladin",
    ],
  },
  {
    primary_id: "D0YF3X",
    names: ["Ethamsylate"],
  },
  {
    primary_id: "D0YF4I",
    names: ["MN-221", "Bedoradrine"],
  },
  {
    primary_id: "D0YF6E",
    names: ["Fused Benzoheterocycle Amide Derivative 1"],
  },
  {
    primary_id: "D0YF6K",
    names: ["HER2-Specific CAR T Cell"],
  },
  {
    primary_id: "D0YF6L",
    names: ["KST-5468"],
  },
  {
    primary_id: "D0YF6S",
    names: [
      "5-HEPTYL-6-HYDROXY-1,3-BENZOTHIAZOLE-4,7-DIONE",
      "AC1NRAPN",
      "DB07636",
      "5-Heptyl-6-Hydroxybenzothiazole-4,7-Dione",
      "5-N-Heptyl-6-Hydroxy-4,7-Dioxobenzothiazole",
    ],
  },
  {
    primary_id: "D0YF7A",
    names: ["PMID27967267-Compound-43"],
  },
  {
    primary_id: "D0YF7Q",
    names: ["Indoline Derivative 7"],
  },
  {
    primary_id: "D0YF9S",
    names: ["Sarcosine"],
  },
  {
    primary_id: "D0YF9W",
    names: ["FST-200", "Anti-Inflammatory (Otic Suspension, Otitis), Foresight"],
  },
  {
    primary_id: "D0YG0D",
    names: [
      "1'-Tosylspiro[indene-1,4'-Piperidine]",
      "CHEMBL341490",
      "SCHEMBL7381332",
      "VRVIZJNFYYAUKC-UHFFFAOYSA-N",
      "ZINC13728182",
      "BDBM50001405",
      "L-342643",
      "1'-((4-Methylphenyl)Sulfonyl)Spiro(1H-Indene-1,4'-Piperidine)",
    ],
  },
  {
    primary_id: "D0YG1E",
    names: ["Etisulergine"],
  },
  {
    primary_id: "D0YG3K",
    names: ["Soluble Insulin"],
  },
  {
    primary_id: "D0YG3U",
    names: ["Tpl2 Kinase Inhibitors"],
  },
  {
    primary_id: "D0YG4I",
    names: ["Bevasiranib"],
  },
  {
    primary_id: "D0YG4P",
    names: ["Anti-OX40 Mab"],
  },
  {
    primary_id: "D0YG4Q",
    names: ["OG-9"],
  },
  {
    primary_id: "D0YG7H",
    names: ["CM-2359"],
  },
  {
    primary_id: "D0YG7M",
    names: ["Ketotifen"],
  },
  {
    primary_id: "D0YH0I",
    names: ["EP HIV-1090 Vaccine"],
  },
  {
    primary_id: "D0YH0N",
    names: ["Metadoxine"],
  },
  {
    primary_id: "D0YH4T",
    names: [
      "2-(6-Hydroxy-Naphthalen-1-Yl)-Benzooxazol-6-Ol",
      "CHEMBL189077",
      "2-(6-Hydroxynaphthalen-1-Yl)Benzo[d]oxazol-6-Ol",
      "SCHEMBL3926798",
      "ZRGCERZQAHMXNC-UHFFFAOYSA-N",
      "BDBM50154135",
      "595566-69-1",
      "6-Benzoxazolol,2-(6-Hydroxy-1-Naphthalenyl)-",
    ],
  },
  {
    primary_id: "D0YH5F",
    names: ["Ridostin"],
  },
  {
    primary_id: "D0YH5L",
    names: ["CART-56 Cells"],
  },
  {
    primary_id: "D0YH5V",
    names: ["MK-8189"],
  },
  {
    primary_id: "D0YH7K",
    names: ["ABL127", "ABL-127", "ABL 127"],
  },
  {
    primary_id: "D0YH8F",
    names: ["RG7347"],
  },
  {
    primary_id: "D0YH8T",
    names: ["MI-09018"],
  },
  {
    primary_id: "D0YH9A",
    names: ["TPT-43"],
  },
  {
    primary_id: "D0YI0V",
    names: ["PMID27019002-Compound-21a"],
  },
  {
    primary_id: "D0YI1Z",
    names: ["PMID19831390C22m"],
  },
  {
    primary_id: "D0YI3V",
    names: [
      "2-(3'-Vinyl-Biphenyl-4-Yl)-Propionic Acid",
      "CHEMBL432782",
      "BDBM50074925",
      "2-(3'-Vinyl-4-Biphenylyl)Propanoic Acid",
      "2-(3''-Vinyl-Biphenyl-4-Yl)-Propionic Acid",
    ],
  },
  {
    primary_id: "D0YI3Z",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 4"],
  },
  {
    primary_id: "D0YI4F",
    names: ["Vecabrutinib"],
  },
  {
    primary_id: "D0YI4T",
    names: ["2-Methyl-1-(Pyridin-3-Yl)Pent-1-En-3-One Oxime"],
  },
  {
    primary_id: "D0YI5C",
    names: ["S-(N-Hexyl-N-Hydroxycarbamoyl)Glutathione", "CHEMBL127840", "SCHEMBL3281719", "BDBM50092827"],
  },
  {
    primary_id: "D0YI5E",
    names: ["Bydureon Dual Chamber Pen"],
  },
  {
    primary_id: "D0YI6W",
    names: ["SPN-805"],
  },
  {
    primary_id: "D0YJ1O",
    names: ["Pyrazole N-1 Aryl And Heteroaryl Derivative 1"],
  },
  {
    primary_id: "D0YJ2G",
    names: ["ADXS11-001"],
  },
  {
    primary_id: "D0YJ3Q",
    names: [
      "2-(3,4-Dihydroxy-Phenyl)-7-Hydroxy-Chromen-4-One",
      "7,3',4'-Trihydroxyflavone",
      "2150-11-0",
      "2-(3,4-Dihydroxyphenyl)-7-Hydroxy-4H-Chromen-4-One",
      "3',4',7-Trihydroxyflavone",
      "BRN 0253031",
      "CHEMBL301624",
      "4H-1-Benzopyran-4-One, 2-(3,4-Dihydroxyphenyl)-7-Hydroxy-",
      "Flavone, 7,3',4'-Trihydroxy-",
      "2-(3,4-Dihydroxyphenyl)-7-Hydroxy-4H-1-Benzopyran-4-One",
      "2-(3,4-Dihydroxyphenyl)-7-Hydroxychromen-4-One",
      "Q-100553",
      "4hlf",
      "AC1NT15U",
      "3',4',7-Trihydroxyflavon",
      "SCHEMBL34405",
      "7, 3',4'-Trihydroxyflavone",
      "3'',4'',7-Trihydroxyflavon",
      "Trihydroxyflavone, 7",
    ],
  },
  {
    primary_id: "D0YJ4D",
    names: ["Pyrrole Derivative 7"],
  },
  {
    primary_id: "D0YJ5F",
    names: ["PT-112phosphaplatin"],
  },
  {
    primary_id: "D0YJ5H",
    names: ["EX-1311"],
  },
  {
    primary_id: "D0YJ5W",
    names: ["N2-(3-Trifluoromethylphenyl)Guanine"],
  },
  {
    primary_id: "D0YJ6C",
    names: ["D[Orn4,Orn8]VP", "CHEMBL219272"],
  },
  {
    primary_id: "D0YJ9T",
    names: [
      "DU-6681",
      "DU-6681a (Na Salt)",
      "(1R,5S,6S)-2-[6,7-Dihydro-5H-Pyrrolo[1,2-A]imidazol-6(S)-Ylsulfanyl]-6-[1(R)-Hydroxyethyl]-1-Methyl-1-Carba-2-Penem-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0YK1E",
    names: [
      "S-(-)CPB",
      "2-(4-Chlorophenoxy)Butanoic Acid",
      "10310-19-7",
      "2-(4-Chlorophenoxy)Butyric Acid",
      "34385-92-7",
      "Butanoic Acid, 2-(4-Chlorophenoxy)-",
      "Butanoic Acid,2-(4-Chlorophenoxy)-",
      "P-Chlorophenoxybutyric Acid",
      "AC1L46GO",
      "CHEMBL23477",
      "SCHEMBL3028298",
      "GTPL4097",
      "CTK4A1742",
      "CEJKAKCQVUWNNA-UHFFFAOYSA-N",
      "MolPort-000-889-545",
      "ZX-AN000853",
      "ALBB-000862",
      "ZX-AV000272",
      "SP4251",
      "SBB020800",
      "STK501060",
      "BBL002691",
      "7737AC",
      "ETHYL(4-CHLOROPHENOXY)ACETATE",
      "AKOS000103672",
      "AKOS016345013",
      "MCULE-4592400851",
      "NCGC00327569-01",
      "(2RS)-2-(4-Chlorophenoxy)Butyric Acid",
      "U469",
    ],
  },
  {
    primary_id: "D0YK4H",
    names: ["Secreted/Transmembrane Receptor Proteins"],
  },
  {
    primary_id: "D0YK4L",
    names: ["PAC-10649"],
  },
  {
    primary_id: "D0YK5E",
    names: ["Oxymorphone Semicarbazone Hydrochloride", "CHEMBL1202432", "Oxymorphone Semicarbazone HCl"],
  },
  {
    primary_id: "D0YK5U",
    names: ["N-(2,4-Dichlorophenyl)-4-Methyl-3-Nitrobenzamide"],
  },
  {
    primary_id: "D0YK6K",
    names: ["PMID27376512-Compound-Table1Example30"],
  },
  {
    primary_id: "D0YK7C",
    names: ["XR-5118"],
  },
  {
    primary_id: "D0YK7J",
    names: ["CDP-492"],
  },
  {
    primary_id: "D0YK8M",
    names: ["1,3,4-Oxadiazole Derivative 3"],
  },
  {
    primary_id: "D0YK9D",
    names: ["CHIR-99021", "CHIR99021", "CHIR 99021", "CT-99021", "CT99021"],
  },
  {
    primary_id: "D0YK9Q",
    names: ["PMID29649907-Compound-37"],
  },
  {
    primary_id: "D0YK9T",
    names: ["L-165041"],
  },
  {
    primary_id: "D0YL0L",
    names: [
      "G-256",
      "AWD-G-256",
      "(Z)-2-Amino-5-Chlorobenzophenone Amidinohydrazone Monoacetate",
      "(Z)-2-[(2-Amino-5-Chlorophenyl)Phenylmethylene]hydrazinecarboxyimidamide Monoacetate",
    ],
  },
  {
    primary_id: "D0YL3E",
    names: ["GSK159802"],
  },
  {
    primary_id: "D0YL4X",
    names: ["KNI-10341"],
  },
  {
    primary_id: "D0YL6T",
    names: ["[Mpa1, D-Tic2, Aib7]OT", "CHEMBL394268"],
  },
  {
    primary_id: "D0YL8U",
    names: ["Omecamtiv Mecarbil"],
  },
  {
    primary_id: "D0YL8Z",
    names: [
      "C646",
      "328968-36-1",
      "CHEMBL1797936",
      "4-[(4Z)-4-[[5-(4,5-Dimethyl-2-Nitrophenyl)Furan-2-Yl]methylidene]-3-Methyl-5-Oxopyrazol-1-Yl]benzoic Acid",
      "C24H19N3O6",
      "4-[4-[[5-(4,5-Dimethyl-2-Nitrophenyl)-2-Furanyl]methylene]-4,5-Dihydro-3-Methyl-5-Oxo-1H-Pyrazol-1-Yl]benzoic Acid",
      "C 646",
      "AC1LPE4V",
      "GTPL7004",
      "SCHEMBL12092858",
      "QCR-235",
      "DTXSID30361651",
      "MolPort-002-171-380",
      "CHEBI:132974",
      "ZINC8780879",
      "S7152",
      "2295AH",
      "AKOS000113052",
      "SB19338",
      "ST020490",
      "C646, &gt",
      "J-018925",
      "BRD-K73383190-001-03-1",
    ],
  },
  {
    primary_id: "D0YL9G",
    names: ["TS-091"],
  },
  {
    primary_id: "D0YM0G",
    names: ["Pyrazole Derivative 84"],
  },
  {
    primary_id: "D0YM1M",
    names: ["PMID25666693-Compound-67"],
  },
  {
    primary_id: "D0YM2K",
    names: ["Atezolizumab"],
  },
  {
    primary_id: "D0YM2S",
    names: ["PMID28270010-Compound-Figure16-B-2"],
  },
  {
    primary_id: "D0YM3G",
    names: ["Pyrrolo-Pyrrolone Derivative 1"],
  },
  {
    primary_id: "D0YM4A",
    names: [
      "N-(6-Chloro-5-Phenyl-1H-Indazol-3-Yl)Butyramide",
      "CHEMBL1095040",
      "N-(6-Chloro-5-Phenyl-1H-Indazol-3-Yl)Butanamide",
      "3lfs",
      "SCHEMBL1461347",
      "WGVVIVGNBSSANI-UHFFFAOYSA-N",
      "BDBM50313661",
      "N-(5-Phenyl-6-Chloro-1H-Indazol-3-Yl)Butanamide",
    ],
  },
  {
    primary_id: "D0YM4F",
    names: ["ACC-002"],
  },
  {
    primary_id: "D0YM5S",
    names: ["GPI 18214"],
  },
  {
    primary_id: "D0YM6B",
    names: [
      "5'-Deoxy-5'-(N,N-Dimethylamino)-8-Methyladenosine",
      "5'-Deoxy-5'-(Dimethylamino)-8-Methyladenosine",
      "SCHEMBL955366",
      "CHEMBL516214",
      "AdoMet Substrate Analogue, 23a",
      "BDBM28452",
      "BDRUNDKEQQVJJG-QYVSTXNMSA-N",
      "(2R,3R,4S,5R)-2-(6-Amino-8-Methyl-9H-Purin-9-Yl)-5-[(Dimethylamino)Methyl]oxolane-3,4-Diol",
    ],
  },
  {
    primary_id: "D0YM7U",
    names: ["Rituxan Hemotalogy/Oncology"],
  },
  {
    primary_id: "D0YM8F",
    names: ["[D-Asp7,Lys10]N/OFQ(1-13)NH2", "CHEMBL507653"],
  },
  {
    primary_id: "D0YM8K",
    names: [
      "[99mTc]Met",
      "99mTc-Labeled Met Receptor Tyrosine Kinase Targeting Peptide (Cancer, Imaging, Diagnostic), NCI",
    ],
  },
  {
    primary_id: "D0YM8O",
    names: ["JWH-209"],
  },
  {
    primary_id: "D0YN0I",
    names: ["PMID28270021-Compound-WO2014078408Example26"],
  },
  {
    primary_id: "D0YN1A",
    names: [
      "PMID24900526C1",
      "107491-28-1",
      "AC1LHVED",
      "BAS 06984707",
      "CBKinase1_016190",
      "CBKinase1_003790",
      "MLS000124060",
      "GTPL7870",
      "CTK7J5343",
      "MolPort-002-007-625",
      "ZINC470145",
      "MFCD03876460",
      "BDBM50400562",
      "AKOS000302705",
      "MCULE-5295604199",
      "SMR000124614",
      "DB-016663",
      "TR-042640",
      "ST50281836",
    ],
  },
  {
    primary_id: "D0YN1E",
    names: ["3,6-Di-Pyridin-4-Yl-Pyrazolo[1,5-A]pyrimidine"],
  },
  {
    primary_id: "D0YN1X",
    names: ["PMID25980951-Compound-44"],
  },
  {
    primary_id: "D0YN2F",
    names: [
      "HG-1209",
      "ADAM30 Modulator (Cancer/Inflammation), Human Genome Sciences",
      "Disintegrin And Metalloproteinase Domain 30 Isoform 2 Modulator (Cancer/Inflammation), Human Genome Sciences",
      "Disintegrin And Metalloproteinase Domain 30 Modulator (Cancer/Inflammation), Human Genome Sciences",
      "Svph4 Modulator (Cancer/Inflammation), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0YN2I",
    names: ["Pyrazole Derivative 54"],
  },
  {
    primary_id: "D0YN2T",
    names: ["PMID29671355-Compound-45a"],
  },
  {
    primary_id: "D0YN3T",
    names: ["Tamiphosphor"],
  },
  {
    primary_id: "D0YN4S",
    names: ["BI 754111"],
  },
  {
    primary_id: "D0YN4Y",
    names: ["PTI-202"],
  },
  {
    primary_id: "D0YN5P",
    names: ["8-Aminoquinolines"],
  },
  {
    primary_id: "D0YN8Q",
    names: ["UCM 724"],
  },
  {
    primary_id: "D0YN9H",
    names: ["VVP-100X"],
  },
  {
    primary_id: "D0YN9Y",
    names: ["MB-101"],
  },
  {
    primary_id: "D0YO0R",
    names: ["2-Benzyl-N1-Hydroxy-N3-(4-Phenylbutyl)Malonamide", "CHEMBL220996", "BDBM50197497"],
  },
  {
    primary_id: "D0YO0Z",
    names: ["IB-08A099"],
  },
  {
    primary_id: "D0YO2D",
    names: ["CADPR", "Cyclic ADP Ribose"],
  },
  {
    primary_id: "D0YO2N",
    names: [
      "MP-0260",
      "DARPin (Eye Drops, Ocular Disease), Molecular Partners",
      "Designed Ankyrin Repeat Protein (Eye Drops, Ocular Disease), Molecular Partners",
    ],
  },
  {
    primary_id: "D0YO2O",
    names: ["Heteroaryl-Azepine Derivative 6"],
  },
  {
    primary_id: "D0YO3X",
    names: ["Benzamide Derivative 14"],
  },
  {
    primary_id: "D0YO4B",
    names: ["Cabiralizumab"],
  },
  {
    primary_id: "D0YO4C",
    names: ["Xiionix"],
  },
  {
    primary_id: "D0YO4D",
    names: [
      "LG100268",
      "AmbkkkkK580",
      "LG 100268",
      "LG2",
      "LG-100268",
      "6-(1-(3,5,5,8,8-Pentamethyl-5,6,7,8-Tetrahydronaphthalen-2-Yl)Cyclopropyl)Nicotinic Acid",
      "6-[1-(3,5,5,8,8-PENTAMETHYL-5,6,7,8-TETRAHYDRONAPHTHALEN-2-YL)CYCLOPROPYL]PYRIDINE-3-CARBOXYLIC ACID",
      "6-[1-(3,5,5,8,8-Pentamethyl-5,6,7,8-Tetrahydro-Naphthalen-2-Yl)-Cyclopropyl]-Nicotinic Acid",
      "6-[1-(3,5,5,8,8-Pentamethyl-6,7-Dihydronaphthalen-2-Yl)Cyclopropyl]pyridine-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0YO5Z",
    names: ["UshStat"],
  },
  {
    primary_id: "D0YO6E",
    names: ["PD-132301-2"],
  },
  {
    primary_id: "D0YO6M",
    names: ["N-(2-Aminophenyl)-4-Methoxybenzamide"],
  },
  {
    primary_id: "D0YO6R",
    names: ["ADXS-DUAL"],
  },
  {
    primary_id: "D0YO7C",
    names: ["N-(2-Phenylethyl)-1H-Indole-2-Carboxamide"],
  },
  {
    primary_id: "D0YO7Y",
    names: ["DAS-181"],
  },
  {
    primary_id: "D0YO8B",
    names: ["MCCG"],
  },
  {
    primary_id: "D0YP0A",
    names: [
      "Cl-Amidine",
      "CHEMBL1962361",
      "N-[(1S)-1-(Aminocarbonyl)-4-[(2-Chloro-1-Iminoethyl)Amino]butyl]-Benzamide",
      "913723-61-2",
      "CHEMBL1910972",
      "GTPL8685",
      "US8969333, CI-Amidine",
      "SCHEMBL1979577",
      "BDBM144279",
      "ZINC71746281",
      "BDBM50355657",
      "4373AJ",
      "NE62957",
      "HY-100574",
      "CS-0019714",
      "FT-0700077",
      "(2S)-5-(2-Chloroethanimidamido)-2-(Phenylformamido)Pentanamide",
    ],
  },
  {
    primary_id: "D0YP0C",
    names: ["DT-TX-30", "DT-TX-30-SE"],
  },
  {
    primary_id: "D0YP0P",
    names: [
      "2-Amino-6-(2-Fluoro-Benzenesulfonyl)-Benzonitrile",
      "AC1LA8EU",
      "CHEMBL53684",
      "BDBM1794",
      "CTK7C6581",
      "ZINC5933932",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3n",
      "2-(2-Fluorophenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(2-Fluorophenyl)Sulfonylbenzonitrile",
      "2-Amino-6-(2-Fluorophenyl)Sulfonyl-Benzonitrile",
      "2-Amino-6-[(2-Fluorophenyl)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0YP0T",
    names: ["PMID27336223-Compound-15"],
  },
  {
    primary_id: "D0YP2R",
    names: [
      "99mTc-Teboroxime",
      "CardioTec",
      "Teboroxime",
      "Technetium Tc 99m Teboroxime",
      "SQ-30217",
      "Tc-TEBO",
      "Technetium (99mTc) Teboroxime",
      "Technetium-99m Teboroxime",
    ],
  },
  {
    primary_id: "D0YP4A",
    names: [
      "(5-Pyridin-3-Yl-Furan-2-Yl)Methanethiol",
      "2-Furanmethanethiol, 5-(3-Pyridinyl)-",
      "859239-19-3",
      "CHEMBL214784",
      "SCHEMBL3611340",
      "CTK2I3858",
      "BDBM12360",
      "DTXSID30475409",
      "GLMNDKCXHHOKIQ-UHFFFAOYSA-N",
      "Nicotine 3-Heteroaromatic Analogue 14",
      "[5-(Pyridin-3-Yl)Furan-2-Yl]methanethiol",
    ],
  },
  {
    primary_id: "D0YP4F",
    names: ["N1-Hydroxy-N8-(4-Phenylthiazol-2-Yl)Octanediamide", "CHEMBL511212", "BDBM50258645"],
  },
  {
    primary_id: "D0YP5U",
    names: ["3-Substituted-2-Furancarboxylic Acid Hydrazide Derivative 5"],
  },
  {
    primary_id: "D0YP5Z",
    names: ["Streptococcus Pneumoniae Vaccine, Cytovax"],
  },
  {
    primary_id: "D0YP6F",
    names: ["AZD-4121"],
  },
  {
    primary_id: "D0YP7A",
    names: ["Isothiocyanate Derivative 1"],
  },
  {
    primary_id: "D0YP7B",
    names: ["Setmelanotide"],
  },
  {
    primary_id: "D0YP7L",
    names: ["SB-269970"],
  },
  {
    primary_id: "D0YP8F",
    names: ["Glypican3-ADC"],
  },
  {
    primary_id: "D0YQ0G",
    names: ["2,4,6 Trinitrobenzene Sulfonate 1,3-Bis (2-Chlorethyl)-1-Nitrosourea"],
  },
  {
    primary_id: "D0YQ0V",
    names: ["Cefpirome Sulfate", "Cefrom (TN)"],
  },
  {
    primary_id: "D0YQ2A",
    names: ["UNDECYLAMINE-N,N-DIMETHYL-N-OXIDE"],
  },
  {
    primary_id: "D0YQ3M",
    names: ["EP-2104R"],
  },
  {
    primary_id: "D0YQ5L",
    names: ["Iofetamine Hydrochloride I-123"],
  },
  {
    primary_id: "D0YQ7U",
    names: [
      "Cenicriviroc",
      "497223-25-3",
      "TBR-652",
      "TAK-652",
      "Cenicriviroc Mesylate",
      "UNII-15C116UA4Y",
      "TAK652",
      "15C116UA4Y",
      "TBR652",
      "Cenicriviroc (USAN/INN)",
      "SCHEMBL3157768",
      "SCHEMBL3157748",
      "CHEMBL2110727",
      "MolPort-044-723-655",
      "MolPort-044-649-359",
      "EX-A1608",
      "BDBM50422828",
      "AKOS027250788",
      "CS-6148",
      "DB11758",
      "SB16976",
      "HY-14882",
      "AS-35184",
      "D09878",
      "UNII-15C116UA4Y Component PNDKCRDVVKJPKG-WHERJAGFSA-N",
      "1-Benzazocine-5-Carboxamide, 8-[4-(2-Butoxyethoxy)Phenyl]-1,2,3,4-Tetrahydro-1-(2-Methylpropyl)-N-[4-[[(1-Propyl-1H-Imidazol-5-Yl)Methyl]sulfinyl]phenyl]-, (5E)-",
    ],
  },
  {
    primary_id: "D0YQ8U",
    names: [
      "(5-Phenylfuran-2-Yl)Methanamine",
      "39170-18-8",
      "2-Furanmethanamine, 5-Phenyl-",
      "5-Phenylfurfurylamine",
      "AC1Q53ZI",
      "SCHEMBL720013",
      "(5-Phenyl-2-Furyl)Methylamine",
      "CHEMBL214859",
      "CTK7E5186",
      "BDBM12363",
      "MolPort-004-306-048",
      "1-(5-Phenyl-2-Furyl)Methanamine",
      "ZINC14949858",
      "Nicotine 3-Heteroaromatic Analogue 22",
      "AKOS000143046",
      "MCULE-3484411839",
      "AJ-65506",
      "EN300-49879",
    ],
  },
  {
    primary_id: "D0YQ8V",
    names: ["PMID24044867C8"],
  },
  {
    primary_id: "D0YQ9G",
    names: ["GT-16-239"],
  },
  {
    primary_id: "D0YQ9I",
    names: ["FF-10101-01"],
  },
  {
    primary_id: "D0YR1V",
    names: ["5-Butyl-8-Phenyl-3H-[1,2,4]triazolo[5,1-I]purine"],
  },
  {
    primary_id: "D0YR4E",
    names: ["PMID25666693-Compound-21"],
  },
  {
    primary_id: "D0YR4O",
    names: ["E Coli Toxin-Based Adjuvants"],
  },
  {
    primary_id: "D0YR8S",
    names: ["PMID27774824-Compound-Figure11Example1up"],
  },
  {
    primary_id: "D0YS0A",
    names: [
      "Cardiovascular Therapeutics",
      "Actelion-4",
      "Actelion-5",
      "Actelion-6",
      "Cardiovascular Therapeutics, Actelion",
    ],
  },
  {
    primary_id: "D0YS0B",
    names: ["DF-1012"],
  },
  {
    primary_id: "D0YS1G",
    names: ["1-Adamantan-1-Yl-3-(2-Heptyloxyethyl)Urea", "CHEMBL388004", "SCHEMBL12931851"],
  },
  {
    primary_id: "D0YS1U",
    names: ["Sparsentan"],
  },
  {
    primary_id: "D0YS2F",
    names: [
      "3-[7-Methoxy-Indan-(1E)-Ylidenemethyl]-Pyridine",
      "(3-Pyridylmethylene)Indane 26a",
      "SCHEMBL4221327",
      "SCHEMBL4221321",
      "CHEMBL175791",
      "BDBM8600",
      "AC1O7053",
      "3-[(E)-(7-Methoxy-2,3-Dihydroinden-1-Ylidene)Methyl]pyridine",
    ],
  },
  {
    primary_id: "D0YS2W",
    names: ["PT005"],
  },
  {
    primary_id: "D0YS3H",
    names: ["Debio-0617"],
  },
  {
    primary_id: "D0YS7D",
    names: [
      "Isosorbide Mononitrate",
      "Conpin",
      "Corangin",
      "Duride",
      "Edistol",
      "Elantan",
      "Epicordin",
      "Etimonis",
      "IHD",
      "ISMN",
      "ISMO",
      "Imazin",
      "Imdur",
      "Imodur",
      "Imtrate",
      "Ismexin",
      "Ismox",
      "Isomon",
      "Isomonat",
      "Isomonit",
      "Iturol",
      "Medocor",
      "Monicor",
      "Monis",
      "Monisid",
      "Monit",
      "Monizid",
      "MonoSigma",
      "Monocedocard",
      "Monocinque",
      "Monoclair",
      "Monocord",
      "Monoket",
      "Monolong",
      "Monomax",
      "Mononit",
      "Monopront",
      "Monosorb",
      "Monosorbitrate",
      "Monosordil",
      "Monotrate",
      "Multitab",
      "Nitex",
      "Nitramin",
      "Olicard",
      "Olicardin",
      "Orasorbil",
      "Percorina",
      "Pertil",
      "Plodin",
      "Promocard",
      "Sigacora",
      "Sorbimon",
      "Titarane",
      "Turimonit",
      "Uniket",
      "Vasdilat",
      "Vasotrate",
      "Conpin Retardkaps",
      "Corangin SR",
      "Elantan Long",
      "Elantan Retard",
      "ISMN AL",
      "ISMN AbZ",
      "ISMN Apogepha",
      "ISMN Atid",
      "ISMN Basics",
      "ISMN Heumann",
      "ISMN Hexal",
      "ISMN Lannacher",
      "ISMN Stada",
      "Imdur Durules",
      "Isosorbidi Mononitras",
      "Isosorbidi Mononitras [Latin]",
      "Mono Corax",
      "Mono Corax Retard",
      "Mono Mack",
      "Monodur Durules",
      "Monoket OD",
      "Monoket Retard",
      "Mononitrato De Isosorbida",
      "Mononitrato De Isosorbida [Spanish]",
      "AHR 4698",
      "IS 5MN",
      "Imdur 60",
      "Monit 20",
      "Mono Mac 50D",
      "Monocord 20",
      "Monocord 40",
      "Monocord 50 SR",
      "Monolong 40",
      "Monolong 60",
      "Mononit 20",
      "Mononit 40",
      "Mononit Retard 50",
      "Monosorb XL 60;Olicard 40",
      "Pentacard 20",
      "AHR-4698",
      "BM 22-145",
      "BM 22.145",
      "Chemydur (TN)",
      "Fem-Mono",
      "IS 5-MN",
      "Imdur (TN)",
      "Ismo (TN)",
      "Ismo-20",
      "Isopen-20",
      "Isosorbide 5-Mononitrate",
      "Isosorbide 5-Nitrate",
      "Mono-Mack",
      "Mono-Sanorania",
      "Mononitrate D'isosorbide",
      "Mononitrate D'isosorbide [French]",
      "Olicard-Retard",
      "BM-22-145",
      "Isosobide-5-Mononitrate [UN3251] [Flammable Solid]",
      "Isosorbide-5-Mononitrate",
      "Isosorbide-5-Nitrate",
      "Isosorbide Mononitrate (JAN/USP/INN)",
      "Isosorbide Mononitrate [USAN:BAN:INN:JAN]",
      "Isosorbide Mononitrate [USAN:INN:BAN:JAN]",
      "D-Glucitol, 1,4:3,6-Dianhydro-, 5-Nitrate",
      "[(3S,3aR,6R,6aS)-3-Hydroxy-2,3,3a,5,6,6a-Hexahydrofuro[3,2-B]furan-6-Yl] Nitrate",
      "1,4:3,6-Dianhydro-D-Glucitol 5-Nitrate",
      "1,4:3,6-Dianhydro-5-O-Nitro-D-Glucitol",
      "5-ISMN Durules",
      "5-Ismn",
    ],
  },
  {
    primary_id: "D0YS8C",
    names: ["BTA-188"],
  },
  {
    primary_id: "D0YS9E",
    names: ["4-Phenyl-5-Piperidin-4-Yl-Isoxazol-3-Ol", "4-Phenyl-5-(4-Piperidyl)-3-Isoxazolol"],
  },
  {
    primary_id: "D0YS9J",
    names: ["Slotoxin"],
  },
  {
    primary_id: "D0YT1P",
    names: ["SGX-301 Ointment"],
  },
  {
    primary_id: "D0YT1S",
    names: ["PMID28766366-Compound-Scheme6Pyrrolo[2,3-D]pyrimidines"],
  },
  {
    primary_id: "D0YT3Z",
    names: ["AR-7947"],
  },
  {
    primary_id: "D0YT6O",
    names: ["Beta-L-Hydroxybutyric Acid", "Beta-L-Hydroxybutyrate"],
  },
  {
    primary_id: "D0YT6Z",
    names: ["Mirisetron Maleate"],
  },
  {
    primary_id: "D0YT8O",
    names: ["PMID29671355-Compound-42"],
  },
  {
    primary_id: "D0YT8P",
    names: [
      "JNJ-38877605",
      "C-Met Inhibitor, Ortho Biotech Oncology Research & Development",
      "C-Met Inhibitor (Solid Tumors), ORD/J&J PRD",
    ],
  },
  {
    primary_id: "D0YU0L",
    names: ["GPA-TriMAR-T Cells"],
  },
  {
    primary_id: "D0YU1L",
    names: ["MP-124"],
  },
  {
    primary_id: "D0YU1M",
    names: ["G-207 Virus Construct"],
  },
  {
    primary_id: "D0YU2R",
    names: ["Pyrimidinyl Compound 5"],
  },
  {
    primary_id: "D0YU4D",
    names: ["Propan-2-One O-4-(Pentyloxy)Phenylcarbamoyl Oxime", "CHEMBL597087"],
  },
  {
    primary_id: "D0YU5H",
    names: [
      "6-(4-((Benzylamino)Methyl)Phenoxy)Nicotinamide",
      "CHEMBL238316",
      "SCHEMBL3700197",
      "LUBVSQAMVPXMBJ-UHFFFAOYSA-N",
      "BDBM50219916",
    ],
  },
  {
    primary_id: "D0YU5R",
    names: ["PMID27788040-Compound-6a"],
  },
  {
    primary_id: "D0YU6I",
    names: ["Infanrix-DTaP-IPV-Hib"],
  },
  {
    primary_id: "D0YU8J",
    names: ["4-Amino-3-Chloro-Benzenesulfonamide", "4-Amino-3-Chlorobenzenesulfonamide"],
  },
  {
    primary_id: "D0YU9B",
    names: ["PMID25666693-Compound-151"],
  },
  {
    primary_id: "D0YU9L",
    names: ["4-Amino-1,2,5-Oxadiazole-3-Carboximidamide"],
  },
  {
    primary_id: "D0YU9Z",
    names: [
      "1-(4-HEXYLPHENYL)PROP-2-EN-1-ONE",
      "1-(4-Hexylphenyl)Prop-2-En-1-One",
      "1-(4-Hexyphenyl)-2-Propane-1-One",
      "131906-57-5",
      "1-(4-HEXYLPHENYL)-2-PROPEN-1-ONE",
      "Cpd L3",
      "Enone, 1",
      "L3 Antagonist TR-SIR-2",
      "SCHEMBL2583757",
      "CHEMBL237600",
      "BDBM18825",
      "DTXSID40565143",
      "MFCD08702653",
      "ZINC13829389",
      "DB08085",
      "NCGC00165805-01",
      "NCGC00165805-02",
      "KB-214837",
      "J-006082",
      "1-(4-Hexyphenyl)-2-Propane-1-One, &gt;=98% (HPLC), Oil",
    ],
  },
  {
    primary_id: "D0YV0N",
    names: ["GSK2857916"],
  },
  {
    primary_id: "D0YV1Q",
    names: [
      "Kanamycin",
      "Aspidium",
      "KAN",
      "Kanamicina",
      "Kanamycine",
      "Kanamycinum",
      "Kantrex",
      "Klebcil",
      "KANAMYCIN A",
      "Kanamicina [Italian]",
      "Kanamycin A Tetracation",
      "Kanamycin Base",
      "Kanamycin Monosulfate",
      "Kanamycin Sulfate",
      "Kenamycin A",
      "Liposomal Kanamycin",
      "KM (The Antibiotic)",
      "Kanamycin [INN:BAN]",
      "Kanamycin Monosulfate (JP15)",
      "Kanamycinsulfate (JP15)",
      "Kanamycin Sulfate (TN)",
      "Kanamycin Sulfate (USP)",
      "Kanamycine [INN-French]",
      "Kanamycinum [INN-Latin]",
      "Kantrex (TN)",
      "Kantrex (1:1 Sulfate)",
      "Klebcil (1:1 Sulfate)",
      "O-3-Amino-3-Deoxy-Alpha-D-Glucopyranosyl-(1->6)-O-(6-Amino-6-Deoxy-Alpha-D-Glucopyranosyl-(1->4))-2-Deoxy-D-Streptamine",
      "O-3-Amino-3-Deoxy-.alpha.-D-Glucopyranosyl-(1->6)-O-[6-Amino-6-Deoxy-.alpha.-D-Glucopyranosyl-(1->4)]-2-Deoxy-D-Streptamine",
      "(1S,2R,3R,4S,6R)-4,6-Diamino-3-(6-Amino-6-Deoxy-Alpha-D-Glucopyranosyloxy)-2-Hydroxycyclohexyl 3-Amino-3-Deoxy-Alpha-D-Glucopyranoside",
      "(1S,2R,3R,4S,6R)-4,6-Diamino-3-[(6-Amino-6-Deoxy-Alpha-D-Glucopyranosyl)Oxy]-2-Hydroxycyclohexyl 3-Amino-3-Deoxy-Alpha-D-Glucopyranoside",
      "(1S,2R,3R,4S,6R)-4,6-Diazaniumyl-3-(6-Azaniumyl-6-Deoxy-Alpha-D-Glucopyranosyloxy)-2-Hydroxycyclohexyl 3-Azaniumyl-3-Deoxy-Alpha-D-Glucopyranoside",
      "(2R,3S,4S,5R,6R)-2-(Aminomethyl)-6-[(1R,2R,3S,4R,6S)-4,6-Diamino-3-[(2S,3R,4S,5S,6R)-4-Amino-3,5-Dihydroxy-6-(Hydroxymethyl)Oxan-2-Yl]oxy-2-Hydroxycyclohexyl]oxyoxane-3,4,5-Triol",
      "4,6-Diamino-2-Hydroxy-1,3-Cyclohexane 3,6'diamino-3,6'-Dideoxydi-Alpha-D-Glucoside",
      "4,6-Diamino-2-Hydroxy-1,3-Cyclohexylene 3,6'-Diamino-3,6'-Dideoxydi-D-Glucopyranoside",
    ],
  },
  {
    primary_id: "D0YV1R",
    names: ["WRC-0571"],
  },
  {
    primary_id: "D0YV4F",
    names: ["Ocrelizumab"],
  },
  {
    primary_id: "D0YV6S",
    names: ["PMID28048944-Compound-2"],
  },
  {
    primary_id: "D0YV6X",
    names: ["PMID29473428-Compound-72"],
  },
  {
    primary_id: "D0YW3K",
    names: ["Pyrazole Derivative 16"],
  },
  {
    primary_id: "D0YW4C",
    names: ["2-(4-Hydroxy-Phenyl)-7-Vinyl-Benzooxazol-5-Ol"],
  },
  {
    primary_id: "D0YW4R",
    names: ["IDM-2", "Bexidem", "MAK Anticancer Agents (2), Immuno-Designed Molecules/IDM Pharma"],
  },
  {
    primary_id: "D0YW4T",
    names: ["Aryl Sulphoxide Imine Derivative 1"],
  },
  {
    primary_id: "D0YW6B",
    names: ["AM-679"],
  },
  {
    primary_id: "D0YW6P",
    names: ["RU-101"],
  },
  {
    primary_id: "D0YW9C",
    names: ["1-Benzyl-2,3-Dimethyl-1H-Indole-7-Carboxylic Acid", "CHEMBL515599", "SCHEMBL6686678"],
  },
  {
    primary_id: "D0YX0O",
    names: ["PMID27537201-Compound-Figure11"],
  },
  {
    primary_id: "D0YX0S",
    names: ["PMID27841045-Compound-135"],
  },
  {
    primary_id: "D0YX1C",
    names: ["Thiazole Derivative 1"],
  },
  {
    primary_id: "D0YX2B",
    names: ["PMID28460551-Compound-2"],
  },
  {
    primary_id: "D0YX2Y",
    names: ["DNA Topical Patch Vaccine", "DermaVir Patch (TN)"],
  },
  {
    primary_id: "D0YX3D",
    names: ["Olipudase Alfa"],
  },
  {
    primary_id: "D0YX4L",
    names: ["PMID26651364-Compound-126b"],
  },
  {
    primary_id: "D0YX4R",
    names: ["PMID27019002-Compound-37b"],
  },
  {
    primary_id: "D0YX4S",
    names: [
      "Cefprozil",
      "Arzimol",
      "Brisoral",
      "Cefprozilo",
      "Cefprozilum",
      "Cefzil",
      "Cronocef",
      "Procef",
      "Serozil",
      "Cefprozil Anhydrous",
      "BMY 28100",
      "Cefprozil (INN)",
      "Cefprozil (TN)",
      "Cefprozilo [INN-Spanish]",
      "Cefprozilum [INN-Latin]",
      "Cefzil (TN)",
      "Procef (TN)",
      "(6R,7R)-7-[[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetyl]amino]-8-Oxo-3-[(E)-Prop-1-Enyl]-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
      "(6R,7R)-7-{[(2R)-2-Amino-2-(4-Hydroxyphenyl)Acetyl]amino}-8-Oxo-3-(Prop-1-En-1-Yl)-5-Thia-1-Azabicyclo[4.2.0]oct-2-Ene-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0YX4U",
    names: ["Pterin-6-Yl-Methyl-Monophosphate"],
  },
  {
    primary_id: "D0YX4V",
    names: ["Z-YVAD-FMK", "Benzyloxycarbonyl-Tyr-Val-Ala-Asp(OMe)-Fluoromethylketone", "Caspase-1 Inhibitor VI"],
  },
  {
    primary_id: "D0YX4Z",
    names: ["IC-43"],
  },
  {
    primary_id: "D0YX5H",
    names: ["Intravenous Minocycline"],
  },
  {
    primary_id: "D0YX5U",
    names: ["ZM-39923"],
  },
  {
    primary_id: "D0YX7E",
    names: ["Ortataxel"],
  },
  {
    primary_id: "D0YX8X",
    names: ["MG-1104"],
  },
  {
    primary_id: "D0YX9D",
    names: ["N-Carboxymethionine"],
  },
  {
    primary_id: "D0YY2L",
    names: ["Diisopropyl 1-Mercaptopropylphosphonate", "CHEMBL1173339"],
  },
  {
    primary_id: "D0YY2W",
    names: [
      "1-Phenyl-3-(1,3,4-Thiadiazol-2-Yl)Thiourea",
      "CHEMBL1253381",
      "AC1LHWR1",
      "SCHEMBL13779380",
      "MolPort-002-153-004",
      "ZINC436272",
      "BDBM50326573",
      "AKOS000986880",
      "MCULE-5997854144",
      "SDCCGMLS-0064737.P001",
      "ST50980881",
      "AB00086777-01",
      "SR-01000210707",
      "SR-01000210707-1",
      "Z56753795",
      "(Phenylamino)(1,3,4-Thiadiazol-2-Ylamino)Methane-1-Thione",
    ],
  },
  {
    primary_id: "D0YY3T",
    names: ["Ac-SFWKYS-NH2", "CHEMBL1163474"],
  },
  {
    primary_id: "D0YY4R",
    names: ["PMID29473428-Compound-54"],
  },
  {
    primary_id: "D0YY4Y",
    names: ["Nomega-Hydroxy-Nor-L-Arginine"],
  },
  {
    primary_id: "D0YY6O",
    names: ["CLIC-1901"],
  },
  {
    primary_id: "D0YY8N",
    names: ["PMID25470667-Compound-GO-CoA-Tat"],
  },
  {
    primary_id: "D0YY9M",
    names: [
      "N-(6-Hydroxycarbamoyl-Hexyl)-Benzamide",
      "CHEMBL57107",
      "174664-71-2",
      "SCHEMBL573254",
      "CTK0A7470",
      "DTXSID00433435",
      "BDBM50220823",
      "ZINC13490043",
      "7-(Benzoylamino)Heptanehydroxamic Acid",
      "AKOS030580013",
      "Benzamide, N-[7-(Hydroxyamino)-7-Oxoheptyl]-",
    ],
  },
  {
    primary_id: "D0YZ0B",
    names: ["HT-2678"],
  },
  {
    primary_id: "D0YZ0L",
    names: ["LORNEIC ACID A"],
  },
  {
    primary_id: "D0YZ1B",
    names: ["PMID25468267-Compound-50"],
  },
  {
    primary_id: "D0YZ1J",
    names: [
      "APG-2305",
      "APG-2303",
      "APG-2307",
      "APG-2309",
      "IL-23 Receptor Inhibitor (Oral, Autoimmune Disease), Allostera",
    ],
  },
  {
    primary_id: "D0YZ1Q",
    names: ["RGX-104"],
  },
  {
    primary_id: "D0YZ3E",
    names: ["1-(10H-Phenothiazin-10-Yl)-4-Phenylbutan-1-One", "CHEMBL394755"],
  },
  {
    primary_id: "D0YZ3U",
    names: ["Sterol Derivative 2"],
  },
  {
    primary_id: "D0YZ4T",
    names: [
      "SFLT-01",
      "AAV2-SFLT-01",
      "VEGF/PIGF Suppression Gene Therapy (Cancer), Genzyme",
      "SFLT-01 Gene Therapy (Wet AMD), AGTC/Genzyme",
      "VEGF Suppression Gene Therapy (Wet Age-Related Macular Degeneration), Applied Genetic Technologies/ Genzyme",
    ],
  },
  {
    primary_id: "D0YZ5G",
    names: ["Esculetin", "Aesculetin", "6,7-Dihydroxycoumarin", "Cichorigenin"],
  },
  {
    primary_id: "D0YZ5T",
    names: ["NT-345"],
  },
  {
    primary_id: "D0YZ7D",
    names: ["PMID25435285-Compound-50"],
  },
  {
    primary_id: "D0YZ7H",
    names: ["JNJ-10198409"],
  },
  {
    primary_id: "D0YZ8G",
    names: ["5-Chloro-2-(2-Methylquinolin-7-Yl)Benzonitrile", "CHEMBL1099112"],
  },
  {
    primary_id: "D0YZ8I",
    names: ["JWH-015"],
  },
  {
    primary_id: "D0YZ9Y",
    names: ["(2'Z,3'E)-5-Nitro-5'-Hydroxy-Indirubin-3'-Oxime"],
  },
  {
    primary_id: "D0Z0AA",
    names: ["Methyl Salicylate"],
  },
  {
    primary_id: "D0Z0AM",
    names: ["PSI-697"],
  },
  {
    primary_id: "D0Z0AS",
    names: ["CDRI-93/478"],
  },
  {
    primary_id: "D0Z0BB",
    names: [
      "3,4-Dichlorobenzenemethanethiol",
      "3,4-Dichlorobenzyl Mercaptan",
      "36480-40-7",
      "(3,4-Dichlorophenyl)Methanethiol",
      "3,4-Dichlorotoluene-Alpha-Thiol",
      "CHEMBL1224626",
      "CEGBRSQPRQXALB-UHFFFAOYSA-N",
      "Benzenemethanethiol, 3,4-Dichloro-",
      "EINECS 253-054-4",
      "AC1L3NZT",
      "ACMC-1AEX5",
      "AC1Q3O9X",
      "3,4-Dichlorobenzylmercaptan",
      "SCHEMBL232137",
      "CTK3J7596",
      "3,4-Dichloro-Alpha-Toluenethiol",
      "DTXSID50189990",
      "MolPort-002-498-119",
      "ZINC4802670",
      "(3,4-Dichlorophenyl)Methanethiol #",
      "(3,4-Dichloro-Phenyl)-Methanethiol",
      "BDBM50325571",
      "MFCD00039651",
      "GEO-00980",
    ],
  },
  {
    primary_id: "D0Z0CH",
    names: [
      "Almokalant",
      "123955-10-2",
      "Almokalantum",
      "CHEMBL362103",
      "Almokalant [INN]",
      "4-(3-(Ethyl(3-(Propylsulfinyl)Propyl)Amino)-2-Hydroxypropoxy)Benzonitrile",
      "Almokalantum [INN-Latin]",
      "Almoklant",
      "Benzonitrile, 4-(3-(Ethyl(3-(Propylsulfinyl)Propyl)Amino)-2-Hydroxypropoxy)-",
      "(+-)-P-(3-(Ethyl(3-(Propylsulfinyl)Propyl)Amino)-2-Hydroxypropoxy)Benzonitrile",
      "AC1MHWND",
      "SCHEMBL247121",
      "H 234-09",
      "ZMHOBBKJBYLXFR-UHFFFAOYSA-N",
      "BDBM50151869",
      "LS-38713",
      "HY-106855",
      "CS-0026741",
      "4-[3-[ethyl[3-(Propylsulfinyl)Propyl]amino]-2-Hydroxypropoxy]-Benzonitrile",
    ],
  },
  {
    primary_id: "D0Z0CU",
    names: [
      "Cx-621",
      "Allogeneic Adipose-Derived Mesenchymal Stem Cells (Intralymphatic, Inflammatory/Autoimmune Disease), Cellerix",
      "Allogeneic Adipose-Derived Mesenchymal Stem Cells (Intralymphatic, Inflammatory/Autoimmune Disease), TiGenix",
    ],
  },
  {
    primary_id: "D0Z0DW",
    names: ["Folic Acid"],
  },
  {
    primary_id: "D0Z0DX",
    names: ["Benzothiazole Analog 5"],
  },
  {
    primary_id: "D0Z0EF",
    names: ["3,5-Diiodo-L-Tyrosine", "3,5-Diiodotyrosine", "Diotyrosine"],
  },
  {
    primary_id: "D0Z0EH",
    names: [
      "PheTQS",
      "Alpha7 Nicotinic Acetylcholine Receptor Positive Allosteric Modulators (PAMs) (CNS Disorders), GlaxoSmithKline",
      "Alpha7 Nicotinic Acetylcholine Receptor Positive Allosteric Modulators (PAMs) (CNS Disorders), Proximagen",
    ],
  },
  {
    primary_id: "D0Z0EP",
    names: ["CIGB-M3", "131I-Labeled Anti-CEA Diabody (Cancer), CIGB"],
  },
  {
    primary_id: "D0Z0FA",
    names: ["MLN7243"],
  },
  {
    primary_id: "D0Z0FP",
    names: ["PMID29649907-Compound-34"],
  },
  {
    primary_id: "D0Z0GH",
    names: ["AZD-2563"],
  },
  {
    primary_id: "D0Z0GX",
    names: ["SUVN-502"],
  },
  {
    primary_id: "D0Z0HH",
    names: ["Gossypol"],
  },
  {
    primary_id: "D0Z0HL",
    names: ["Lixisenatide + Lantus"],
  },
  {
    primary_id: "D0Z0HS",
    names: ["Hu Dreg 55"],
  },
  {
    primary_id: "D0Z0IR",
    names: [
      "3-Phenylcyclopentanecarboxylic Acid",
      "91495-75-9",
      "3-Phenylcyclopentane-1-Carboxylic Acid",
      "CHEMBL554283",
      "3-Phenyl-Cyclopentanecarboxylic Acid",
      "SCHEMBL1073107",
      "CTK3I5725",
      "DTXSID80605012",
      "MolPort-020-002-441",
      "BDBM50293437",
      "CP-858",
      "Cyclopentanecarboxylicacid, 3-Phenyl-",
      "3-Phenyl-1-Cyclopentanecarboxylic Acid",
      "AKOS022536861",
      "A843950",
    ],
  },
  {
    primary_id: "D0Z0JA",
    names: ["TDI-0046"],
  },
  {
    primary_id: "D0Z0JC",
    names: ["NS-640"],
  },
  {
    primary_id: "D0Z0JF",
    names: ["M-5MPEP"],
  },
  {
    primary_id: "D0Z0JU",
    names: ["(3-Ethoxy-Pyridin-2-Yl)-Pyridin-2-Yl-Amine", "CHEMBL194743", "SCHEMBL5582608"],
  },
  {
    primary_id: "D0Z0KD",
    names: ["PD-0183812"],
  },
  {
    primary_id: "D0Z0KE",
    names: ["BX-044"],
  },
  {
    primary_id: "D0Z0KH",
    names: ["Azole"],
  },
  {
    primary_id: "D0Z0KL",
    names: ["1,3,4-Oxadiazole Derivative 6"],
  },
  {
    primary_id: "D0Z0KY",
    names: ["GSK494581A"],
  },
  {
    primary_id: "D0Z0LJ",
    names: [
      "ABT-107",
      "Alpha 7 NNR (CNS Diseases, Schizophrenia, ADHD), Abbott",
      "Alpha 7 NNR (CNS Diseases, Schizophrenia, ADHD), NeuroSearch",
      "Alpha 7 Neuronal Nicotinic Receptors (Central Nervous System Diseases, Schizophrenia, ADHD), Abbott",
      "Alpha 7 Neuronal Nicotinic Receptors (Central Nervous System Diseases, Schizophrenia, ADHD), NeuroSearch",
      "NAChR Alpha 7 (CNS Diseases, Schizophrenia, ADHD), Abbott",
      "NAChR Alpha 7 (CNS Diseases, Schizophrenia, ADHD), NeuroSearch",
      "Nicotinic Acetylcholine Receptor Alpha 7 (Central Nervous System Diseases, Schizophrenia, ADHD), Abbott",
      "Nicotinic Acetylcholine Receptor Alpha 7 (Central Nervous System Diseases, Schizophrenia, ADHD), NeuroSearch",
    ],
  },
  {
    primary_id: "D0Z0LP",
    names: [
      "8R-Hydroxylobelane",
      "CHEMBL125511",
      "SCHEMBL14178948",
      "BDBM50080821",
      "(R)-1-Phenyl-2-(1-Methyl-6beta-Phenethyl-2beta-Piperidinyl)Ethanol",
    ],
  },
  {
    primary_id: "D0Z0LV",
    names: [
      "VU0360172",
      "CHEMBL1684242",
      "N-Cyclobutyl-6-((3-Fluorophenyl)Ethynyl)Nicotinamide",
      "GTPL6400",
      "SCHEMBL19411346",
      "ZINC66138253",
      "BDBM50337958",
      "NCGC00379106-01",
      "VU-0360172",
    ],
  },
  {
    primary_id: "D0Z0LX",
    names: ["4-Methoxyphenyl 1-(4-Butoxyphenyl)Propylcarbamate", "CHEMBL591447", "SCHEMBL5214948"],
  },
  {
    primary_id: "D0Z0LZ",
    names: ["IT-121"],
  },
  {
    primary_id: "D0Z0MG",
    names: ["Carglumic Acid", "Carbaglu (TN)"],
  },
  {
    primary_id: "D0Z0MI",
    names: ["Iomab-B CD45"],
  },
  {
    primary_id: "D0Z0NC",
    names: ["PMID28394193-Compound-26"],
  },
  {
    primary_id: "D0Z0NX",
    names: ["AR-06"],
  },
  {
    primary_id: "D0Z0OF",
    names: ["Des-AA1,2,5-[D-Trp8,IAmp9,m-I-Tyr11]Cbm-SRIF", "CHEMBL413419"],
  },
  {
    primary_id: "D0Z0OT",
    names: ["ONO-4057"],
  },
  {
    primary_id: "D0Z0PB",
    names: ["NGD-8243"],
  },
  {
    primary_id: "D0Z0PR",
    names: [
      "L-006235-1",
      "294623-49-7",
      "L 006235",
      "CHEMBL426819",
      "N-(1-((Cyanomethyl)Carbamoyl)Cyclohexyl)-4-(2-(4-Methylpiperazin-1-Yl)Thiazol-4-Yl)Benzamide",
      "L006235",
      "N-[1-[[(Cyanomethyl)Amino]carbonyl]cyclohexyl]-4-[2-(4-Methyl-1-Piperazinyl)-4-Thiazolyl]benzamide",
      "SCHEMBL6183485",
      "CTK8E9371",
      "BDBM19854",
      "DTXSID90432735",
      "MolPort-023-276-653",
      "BCP28510",
      "ZINC3993799",
      "AKOS024457410",
      "Basic Piperazine-Containing Compound, 1",
      "NCGC00371088-01",
      "RT-013466",
      "CRA-013783/L-006235",
      "J-017526",
      "L-006235",
      "L-006,235",
    ],
  },
  {
    primary_id: "D0Z0QC",
    names: [
      "Propan-2-One O-4-Ethoxyphenylcarbamoyl Oxime",
      "CHEMBL591682",
      "Propan-2-One, O-[[(4-Ethoxy-Phenyl)Amino]carbonyl]oxime",
      "BDBM50309759",
    ],
  },
  {
    primary_id: "D0Z0QD",
    names: ["LVVYPWT"],
  },
  {
    primary_id: "D0Z0QO",
    names: ["SX-PCK9"],
  },
  {
    primary_id: "D0Z0QS",
    names: ["PMID28394193-Compound-17"],
  },
  {
    primary_id: "D0Z0RR",
    names: ["VGV-X", "APi-1177", "Second-Generation HIV Therapy, Viral Genetics"],
  },
  {
    primary_id: "D0Z0RU",
    names: [
      "APC-6336",
      "CRA-6336",
      "HCV Therapy, BMS/Arris",
      "HCV Therapy, BMS/Axys",
      "Hepatitis C Therapy, Axys/BMS",
      "Protease Inhibitors, BMS/Arris",
      "Protease Inhibitors, BMS/Axys",
    ],
  },
  {
    primary_id: "D0Z0SI",
    names: ["TBD-2"],
  },
  {
    primary_id: "D0Z0SY",
    names: ["Indoline Derivative 12"],
  },
  {
    primary_id: "D0Z0TU",
    names: ["ITCA-638"],
  },
  {
    primary_id: "D0Z0UC",
    names: ["1,2-Bis-(4-Hydroxy-Phenyl)-3H-Inden-5-Ol", "CHEMBL26865"],
  },
  {
    primary_id: "D0Z0UQ",
    names: [
      "5-Mercapto-Pentanoic Acid",
      "5-Sulfanylpentanoic Acid",
      "5-Mercaptopentanoic Acid",
      "30247-98-4",
      "Pentanoic Acid, 5-Mercapto-",
      "CHEMBL294565",
      "5-Mercaptovaleric Acid",
      "SCHEMBL204062",
      "CTK1C0536",
      "DTXSID90447945",
      "GCIZMJUHGHGRNW-UHFFFAOYSA-N",
      "MolPort-013-659-967",
      "BDBM50127619",
      "ZINC13519063",
      "AKOS010615958",
    ],
  },
  {
    primary_id: "D0Z0VF",
    names: [
      "(S)-2-Amino-2-Phenylpropanoic Acid",
      "13398-26-0",
      "(2S)-2-Amino-2-Phenylpropanoic Acid",
      "(S)-2-AMINO-2-PHENYL-PROPIONIC ACID",
      "Benzeneacetic Acid, A-Amino-A-Methyl-, (AS)-",
      "(S)-ALPHA-METHYL-PHENYLGLYCINE",
      "2-Phenyl-D-Alanine",
      "2-(S)-Phenylalanine",
      "AC1LGXHO",
      "(+)-2-Phenyl-D-Alanine",
      "SCHEMBL222049",
      "CHEMBL3085306",
      "CTK4B8907",
      "(S)-2-Methyl-2-Phenyl Glycine",
      "HTCSFFGLRQDZDE-VIFPVBQESA-N",
      "MolPort-021-795-057",
      "ZINC8461952",
      "KS-00001G1D",
      "AKOS006287023",
      "AB33354",
      "SC-84682",
      "AJ-57568",
      "KB-04049",
      "(+)-(2S)-2-Amino-2-Phenylpropionic Acid",
      "FT-0695117",
    ],
  },
  {
    primary_id: "D0Z0VL",
    names: [
      "Carboxyatractyloside",
      "CATR",
      "SCHEMBL1612956",
      "GTPL4572",
      "AQFATIOBERWBDY-LNQSNDDKSA-N",
      "(2alpha,8alpha,10alpha,13alpha,15beta)-15-Hydroxy-2-{[2-O-(3-Methylbutanoyl)-3,4-Di-O-Sulfo-Beta-D-Glucopyranosyl]oxy}kaur-16-Ene-18,19-Dioic Acid",
      "(1R,4S,7S,9S,10S,13R,15S)-15-Hydroxy-7-{[(2R,3R,4R,5R,6R)-6-(Hydroxymethyl)-3-[(3-Methylbutanoyl)Oxy]-4,5-Bis(Sulfooxy)Oxan-2-Yl]oxy}-9-Methyl-14-Methylidenetetracyclo[11.2.1.0^{1,10}.0^{4,9}]hexadecane-5,5-Dicarboxylic Acid",
    ],
  },
  {
    primary_id: "D0Z0WQ",
    names: ["Alpha1-Proteinase Inhibitor"],
  },
  {
    primary_id: "D0Z0XJ",
    names: ["ISIS 19675"],
  },
  {
    primary_id: "D0Z0XL",
    names: ["N-(Naphthalen-2-Yl)Benzo[d]isoxazol-3-Amine", "CHEMBL577471"],
  },
  {
    primary_id: "D0Z0XM",
    names: ["CBL0137"],
  },
  {
    primary_id: "D0Z0XQ",
    names: ["Ro-43-5054"],
  },
  {
    primary_id: "D0Z0YB",
    names: ["N-Benzyl-9-Oxo-9,10-Dihydroacridine-3-Carboxamide", "Acridone-Based Inhibitor, 8c", "CHEMBL230199"],
  },
  {
    primary_id: "D0Z0ZJ",
    names: ["M-108101"],
  },
  {
    primary_id: "D0Z0ZM",
    names: ["SB-202235", "139149-55-6", "SB-202236"],
  },
  {
    primary_id: "D0Z0ZP",
    names: ["5-(2-(2,5-Dimethylphenyl)Ethynyl)Pyrimidine", "CHEMBL453979"],
  },
  {
    primary_id: "D0Z0ZT",
    names: ["CTX-4430"],
  },
  {
    primary_id: "D0Z0ZU",
    names: ["(S)-2-Amino-1-(4-Butylthiophenyl)-Propane", "CHEMBL387778"],
  },
  {
    primary_id: "D0Z1AB",
    names: ["ABBV-927"],
  },
  {
    primary_id: "D0Z1AL",
    names: [
      "2-[2-(3,5-Dimethoxy-Phenyl)-Vinyl]-Thiophene",
      "CHEMBL42428",
      "SCHEMBL7042974",
      "SCHEMBL7042968",
      "ZINC13471769",
      "BDBM50108048",
      "AKOS015967551",
      "(E)-2-(3,5-Dimethoxystyryl)Thiophene",
      "2-[(E)-3,5-Dimethoxystyryl]thiophene",
    ],
  },
  {
    primary_id: "D0Z1AT",
    names: ["16-Beta-Hydroxymethyl-Estradiol"],
  },
  {
    primary_id: "D0Z1BM",
    names: ["LY-2382770"],
  },
  {
    primary_id: "D0Z1BQ",
    names: ["Cefluprenam", "E-1077"],
  },
  {
    primary_id: "D0Z1CL",
    names: [
      "4-Bromophenylboronic Acid",
      "5467-74-3",
      "(4-Bromophenyl)Boronic Acid",
      "4-Bromobenzeneboronic Acid",
      "4-Bromophenylboric Acid",
      "P-Bromophenylboronic Acid",
      "P-Bromophenylboric Acid",
      "P-Bromobenzeneboronic Acid",
      "Boronic Acid, (4-Bromophenyl)-",
      "Benzeneboronic Acid, P-Bromo-",
      "(P-Bromophenyl)Boronic Acid",
      "4-Bromo Phenyl Boronic Acid",
      "4-Bromophenyl Boronic Acid",
      "4-Bromo-Phenylboronic Acid",
      "Dihydroxy-4-Bromophenylborane",
      "EINECS 226-779-9",
      "NSC 25407",
      "4-Bromo Phenylboronic Acid",
      "BRN 2936347",
      "CHEMBL20866",
      "AI3-32763",
      "Boronic Acid,B-(4-Romophenyl)",
    ],
  },
  {
    primary_id: "D0Z1CQ",
    names: ["HumaRAD-OV"],
  },
  {
    primary_id: "D0Z1CS",
    names: ["Recombinant Glucocerebrosidase Enzyme"],
  },
  {
    primary_id: "D0Z1CZ",
    names: ["IR502"],
  },
  {
    primary_id: "D0Z1DH",
    names: [
      "LY2090314",
      "603288-22-8",
      "LY-2090314",
      "UNII-822M3GYM67",
      "Kinome_3681",
      "LY 2090314",
      "CHEMBL362558",
      "822M3GYM67",
      "3-(9-Fluoro-2-(Piperidine-1-Carbonyl)-1,2,3,4-Tetrahydro-[1,4]diazepino[6,7,1-Hi]indol-7-Yl)-4-(Imidazo[1,2-A]pyridin-3-Yl)-1H-Pyrrole-2,5-Dione",
      "SCHEMBL633455",
      "GTPL7958",
      "DTXSID90209085",
      "MolPort-035-944-332",
      "EX-A2214",
      "ZINC3817327",
      "BCP07855",
      "S7063",
      "BDBM50150699",
      "AKOS032950045",
      "AKOS026750195",
      "CS-1633",
      "DB11913",
      "SB16558",
      "NCGC00378942-05",
      "NCGC00378942-02",
      "BC600682",
      "QC-11735",
      "HY-16294",
      "KB-78238",
      "FT-0698670",
      "LY2090314, &gt",
    ],
  },
  {
    primary_id: "D0Z1DQ",
    names: [
      "[3H]A317491",
      "A-317491",
      "475205-49-3",
      "UNII-H327N08IPV",
      "H327N08IPV",
      "A 317491",
      "5-{[(3-Phenoxyphenyl)Methyl][(1S)-1,2,3,4-Tetrahydronaphthalen-1-Yl]carbamoyl}benzene-1,2,4-Tricarboxylic Acid",
      "A317491",
      "VQGBOYBIENNKMI-LJAQVGFWSA-N",
      "Antagonist A-317491",
      "[3H]A-317491",
      "[3H]A 317491",
      "SCHEMBL1160095",
      "GTPL5407",
      "GTPL4115",
      "CHEMBL596234",
      "CTK4J0070",
      "BDBM86478",
      "DTXSID40197185",
      "MolPort-028-749-920",
      "ZINC3990058",
      "S8519",
      "475205-49-3 (Free Acid)",
      "AKOS030526820",
      "CS-1250",
      "NCGC00165956-01",
      "1,2,4-Benzenetricarboxylic Acid,",
    ],
  },
  {
    primary_id: "D0Z1EN",
    names: ["CIGB-166", "Anti-VEGF (Recombinant Antibody, Angiogenesis) CIGB)"],
  },
  {
    primary_id: "D0Z1FX",
    names: [
      "Estriol",
      "Aacifemine",
      "Destriol",
      "Estratriol",
      "Estriel",
      "Estriolo",
      "Gynaesan",
      "Hemostyptanon",
      "Holin",
      "Hormomed",
      "Hormonin",
      "Klimoral",
      "Oestratriol",
      "Oestriol",
      "Oestriolum",
      "Orestin",
      "Orgastyptin",
      "Overstin",
      "Ovestin",
      "Ovestrion",
      "Stiptanon",
      "Synapause",
      "Theelol",
      "Thulol",
      "Tridestrin",
      "Trihydroxyestrin",
      "Trihydroxyoestrin",
      "Triodurin",
      "Triovex",
      "Deuslon A",
      "Estriolo [Italian]",
      "Folicular Hormone",
      "Follicular Hormone Hydrate",
      "Oestriol [Steroidal Oestrogens]",
      "A 13610",
      "E0218",
      "OE3",
      "Deuslon-A",
      "Estriel (TN)",
      "Estriol [USAN:JAN]",
      "Estriol, Unconjugated",
      "Ortho-Gynest",
      "Estriol (JP15/USP)",
      "Estra-1,3,5(10)-Trien-3,16alpha,17beta-Triol",
      "Estra-1,3,5(10)-Triene-3,16,17-Triol",
      "Estra-1,3,5(10)-Triene-3,16alpha,17beta-Triol",
      "Oestra-1,3,5(10)-Triene-3,16alpha,17beta-Triol",
      "Oestra-1,3,5(10)-Triene-3,16-Alpha,17-Beta-Triol",
      "Estra-1,3,5(10)-Trien-3,16.alpha., 17.beta.-Triol",
      "Estra-1,3,5(10)-Trien-3,16.alpha.,17.beta.-Triol",
      "Estra-1,3,5(10)-Triene-3,16.alpha., 17.beta.-Triol",
      "Estra-1,3,5(10)-Triene-3,16.alpha.,17.beta.-Triol",
      "Oestra-1,3,5(10)-Triene-3,16.alpha., 17.beta.-Triol",
      "Oestra-1,3,5(10)-Triene-3,16.alpha.,17.beta.-Triol",
      "(16.alpha.,17.beta.)-Estra-1,3,5(10)-Triene-3,16,17-Triol",
      "(16.alpha.,17.beta.)-Oestra-1,3,5(10)-Triene-3,16,17-Triol",
      "(16alpha,17beta)-Estra-1,3,5(10)-Triene-3,16,17-Triol",
      "(16alpha,17beta)-Oestra-1,3,5(10)-Triene-3,16,17-Triol",
      "1,3,5(10)-ESTRATRIENE-3,16,17-TRIOL",
      "1,3,5(10)-Estratriene-3,16-Alpha,17beta-Triol",
      "1,3,5(10)-Estratriene-3,16.alpha., 17.beta.-Triol",
      "1,3,5(10)-Estratriene-3,16.alpha.,17.beta.-Triol",
      "1,3,5(10)-Estratriene-3,16alpha,17beta-Triol",
      "1,3,5-Estratriene-3.beta.,16-.alpha.,17-.beta.-Triol",
      "1,3,5-Estratriene-3beta,16alpha,17beta-Triol",
      "1,3,5-Oestratriene-3-.beta.,16.alpha.,17.beta.-Triol",
      "1,3,5-Oestratriene-3beta,16alpha,17beta-Triol",
      "16,17-Epiestriol",
      "16-Epiestriol",
      "16-Hydroxyestradiol",
      "16-Alpha,17-Beta-Estriol",
      "16-Alpha,17-Beta-Oestriol",
      "16-Alpha-Hydroxyestradiol",
      "16-Alpha-Hydroxyoestradiol",
      "16.alpha.,17.beta.-Estriol",
      "16.alpha.,17.beta.-Oestriol",
      "16.alpha.-Estriol",
      "16.alpha.-Hydroxy-17.beta.-Estradiol",
      "16.alpha.-Hydroxyestradiol",
      "16.alpha.-Hydroxyoestradiol",
      "16alpha,17beta-Estriol",
      "16alpha,17beta-Oestriol",
      "16alpha-Hydroxy-17beta-Estradiol",
      "16alpha-Hydroxyestradiol",
      "16alpha-Hydroxyoestradiol",
      "3,16-Alpha,17-Beta-Estriol",
      "3,16-Alpha,17-Beta-Oestriol",
      "3,16-Alpha,17-Beta-Trihydroxy-Delta-1,3,5-Estratriene",
      "3,16-Alpha,17-Beta-Trihydroxy-Delta-1,3,5-Oestratriene",
      "3,16-Alpha,17-Beta-Trihydroxyestra-1,3,5(10)-Triene",
      "3,16-Alpha,17-Beta-Trihydroxyoestra-1,3,5(10)-Triene",
      "3,16.alpha.,17.beta.-Estriol",
      "3,16.alpha.,17.beta.-Trihydroxy-.delta.-1,3,5-Estratriene",
      "3,16.alpha.,17.beta.-Trihydroxy-.delta.-1,3,5-Oestratriene",
      "3,16.alpha.,17.beta.-Trihydroxy-1,3,5(10)-Estratriene",
      "3,16.alpha.,17.beta.-Trihydroxyestra-1,3,5(10)-Triene",
      "3,16alpha,17beta-Estriol",
      "3,16alpha,17beta-Trihydroxy-1,3,5(10)-Estratriene",
      "3,16alpha,17beta-Trihydroxy-Delta-1,3,5-Oestratriene",
      "3,16alpha,17beta-Trihydroxy-Delta(1,3,5)-Estratriene",
    ],
  },
  {
    primary_id: "D0Z1GP",
    names: ["FT-1050-Treated Umbilical Cord Stem Cell Therapy"],
  },
  {
    primary_id: "D0Z1HA",
    names: ["TDI-0020"],
  },
  {
    primary_id: "D0Z1HQ",
    names: ["PNU156804"],
  },
  {
    primary_id: "D0Z1HV",
    names: ["Pomolic Acid"],
  },
  {
    primary_id: "D0Z1HY",
    names: [
      "4-Amino-6-Chlorobenzene-1,3-Disulfonamide",
      "121-30-2",
      "4-Amino-6-Chloro-1,3-Benzenedisulfonamide",
      "Chloraminophenamide",
      "Idorese",
      "Salmid",
      "Chloroaminophenamide",
      "1,3-Benzenedisulfonamide, 4-Amino-6-Chloro-",
      "5-Chloro-2,4-Disulfamylaniline",
      "4-Amino-6-Chloro-M-Benzenedisulfonamide",
      "3-Chloro-4,6-Disulfamoylaniline",
      "5-Chloro-2,4-Disulfamoylaniline",
      "UNII-3A52O8YREJ",
      "Su 5683",
      "4-Amino-6-Chloro-1,3-Benzendisulfonamide",
      "3A52O8YREJ",
      "CHEBI:3602",
      "CHEMBL266240",
      "4-Amino-6-Chlorobenzene-1,3-Disulphonamide",
    ],
  },
  {
    primary_id: "D0Z1ID",
    names: ["Furan-2-Ylmethylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL449538"],
  },
  {
    primary_id: "D0Z1KC",
    names: ["Interferon Gamma"],
  },
  {
    primary_id: "D0Z1KU",
    names: ["Benzothiazepine Analog 1"],
  },
  {
    primary_id: "D0Z1LJ",
    names: ["3-(Phenoxymethyl) Benzylamine Derivative 1"],
  },
  {
    primary_id: "D0Z1LY",
    names: [
      "[3H]p-MPPF",
      "155204-26-5",
      "P-MPPF",
      "MPPF",
      "CHEMBL286607",
      "UNII-81KHI46E5L",
      "81KHI46E5L",
      "4-Fluoro-N-[2-[4-(2-Methoxyphenyl)Piperazin-1-Yl]ethyl]-N-Pyridin-2-Ylbenzamide",
      "4-Fluoro-N-{2-[4-(2-Methoxyphenyl)-1-Piperazinyl]ethyl}-N-(2-Pyridinyl)Benzamide",
      "4-Fluoro-N-{2-[4-(2-Methoxy-Phenyl)-Piperazin-1-Yl]-Ethyl}-N-Pyridin-2-Yl-Benzamide",
      "MPPF Authentic",
      "Lopac-M-226",
      "GTPL81",
      "AC1O7G4Q",
      "Lopac0_000825",
      "SCHEMBL4014204",
      "CTK4C8576",
      "DTXSID20424966",
      "ZINC52542163",
      "BDBM50035513",
      "AKOS022184256",
      "CCG-204909",
      "FC-1062",
      "NCGC00162265-01",
      "NCGC00015641-03",
    ],
  },
  {
    primary_id: "D0Z1MJ",
    names: ["3,3',3''-Thiene-2,3,5-Triyltriphenol", "CHEMBL576682", "BDBM50299657"],
  },
  {
    primary_id: "D0Z1MM",
    names: ["KP-9928"],
  },
  {
    primary_id: "D0Z1NF",
    names: ["BFPET"],
  },
  {
    primary_id: "D0Z1NH",
    names: [
      "Cis-N-Oleoylcyclopropanolamide",
      "Cyclopropanolamide, 13b",
      "Cyclopropanolamide, Rac-10b",
      "CHEMBL472661",
      "BDBM29081",
    ],
  },
  {
    primary_id: "D0Z1NY",
    names: [
      "BMS214662",
      "BMV",
      "BMS 214662",
      "BMS-214662",
      "(3R)-3-Benzyl-1-(1H-Imidazol-5-Ylmethyl)-4-Thiophen-2-Ylsulfonyl-3,5-Dihydro-2H-1,4-Benzodiazepine-7-Carbonitrile",
      "(R)-1-((1H-Imidazol-5-Yl)Methyl)-3-Benzyl-4-(Thiophen-2-Ylsulfonyl)-2,3,4,5-Tetrahydro-1H-Benzo[e][1,4]diazepine-7-Carbonitrile",
      "3-BENZYL-1-(1H-IMIDAZOL-4-YLMETHYL)-4-(THIEN-2-YLSULFONYL)-2,3,4,5-TETRAHYDRO-1H-1,4-BENZODIAZEPINE-7-CARBONITRILE",
      "7-Cyano-2,3,4,5-Tetrahydro-1-(1H-Imidazol-4-Ylmethyl)-3-(Phenylmethyl)-4-(2-Thienylsulfonyl)-1H-1,4-Benzodiazepine",
    ],
  },
  {
    primary_id: "D0Z1OC",
    names: ["3-Cyano-N-(3-Cyanophenyl)-5-Fluorobenzamide", "CHEMBL1209548"],
  },
  {
    primary_id: "D0Z1OF",
    names: ["LP-12", "Compound 21 [PMID 17649988]"],
  },
  {
    primary_id: "D0Z1OI",
    names: [
      "3-(4-Amino-Phenyl)-3-Ethyl-Pyrrolidine-2,5-Dione",
      "CHEMBL151212",
      "91567-07-6",
      "3-(4-Aminophenyl)-3-Ethylpyrrolidine-2,5-Dione",
      "3-Apepd",
      "AC1Q6LV5",
      "AC1L3YU4",
      "3-(4-Aminophenyl)-3-Ethyl-2,5-Pyrrolidinedione",
      "3-(4'-Aminophenyl)-3-Ethylpyrollidine-2,5-Dione",
      "SCHEMBL9634038",
      "BDBM50025152",
      "DA-01219",
      "FT-0716174",
      "3-Ethyl-3-(4-Aminophenyl)Pyrrolidine-2,5-Dione",
      "2,5-Pyrrolidinedione, 3-(4-Aminophenyl)-3-Ethyl-",
    ],
  },
  {
    primary_id: "D0Z1OR",
    names: ["IPP-204106"],
  },
  {
    primary_id: "D0Z1OS",
    names: ["ARI-2243"],
  },
  {
    primary_id: "D0Z1OT",
    names: ["ALD-403"],
  },
  {
    primary_id: "D0Z1PA",
    names: ["GG-818"],
  },
  {
    primary_id: "D0Z1PY",
    names: ["4-(2-Fluorophenylethynyl)-2-Methylthiazole", "CHEMBL370453", "SCHEMBL4137694"],
  },
  {
    primary_id: "D0Z1QC",
    names: ["Monoctanoin"],
  },
  {
    primary_id: "D0Z1QR",
    names: ["TD-1473"],
  },
  {
    primary_id: "D0Z1QU",
    names: ["MCL-129"],
  },
  {
    primary_id: "D0Z1RC",
    names: ["GSK484"],
  },
  {
    primary_id: "D0Z1RJ",
    names: ["Adaphostin"],
  },
  {
    primary_id: "D0Z1RQ",
    names: [
      "L-365260",
      "1-(1-Methyl-2-Oxo-5-Phenyl-3H-1,4-Benzodiazepin-3-Yl)-3-(3-Methylphenyl)Urea",
      "CHEMBL289498",
      "L 365260",
      "L365260",
      "AC1L2XQK",
      "[3H]L365260",
      "[3H]L-365,260",
      "GTPL879",
      "GTPL3477",
      "SCHEMBL1650330",
      "KDFQABSFVYLGPM-UHFFFAOYSA-N",
      "BDBM50452555",
      "(R)-L 365260",
      "L000333",
      "1-Methyl-3-[3-(3-Methylphenyl)Ureido]-5-Phenyl-1H-1,4-Benzodiazepin-2(3H)-One",
    ],
  },
  {
    primary_id: "D0Z1RV",
    names: [
      "Clozapine",
      "Asaleptin",
      "CLOZARIL",
      "Clorazil",
      "Clozapin",
      "Clozapina",
      "Clozapinum",
      "Fazaclo",
      "Iprox",
      "Leponex",
      "Lepotex",
      "Fazaclo ODT",
      "C 6305",
      "HF1854",
      "Clopine (TN)",
      "Clozapina [INN-Spanish]",
      "Clozapine(Tautomer)",
      "Clozapinum [INN-Latin]",
      "Clozaril (TN)",
      "Denzapine (TN)",
      "Fazaclo (TN)",
      "Froidir (TN)",
      "HF-1854",
      "KS-1166",
      "Klozapol (TN)",
      "LEX-123",
      "LX 100-129",
      "Leponex (TN)",
      "W-801",
      "Zaponex (TN)",
      "Clozapine [USAN:INN:BAN]",
      "Clozapine (JAN/USP/INN)",
      "3-Chloro-6-(4-Methylpiperazin-1-Yl)-5H-Benzo[b][1,4]benzodiazepine",
      "8-Chloro-11-(4-Methyl)-1-Piperazinyl)-5H-Dibenzo[b,e][1,4]diazepine",
      "8-Chloro-11-(4-Methyl-1-Piperazinyl)-5H-Dibenzo (B,e)(1,4)Diazepine",
      "8-Chloro-11-(4-Methyl-1-Piperazinyl)-5H-Dibenzo(B,e)(1,4)Diazepine",
      "8-Chloro-11-(4-Methyl-1-Piperazinyl)-5H-Dibenzo[b,e][1,4]-Diazepine",
      "8-Chloro-11-(4-Methyl-Piperazin-1-Yl)-5H-Dibenzo[b,e][1,4]diazepine",
      "8-Chloro-11-(4-Methylpiperazin-1-Yl)-5H-Dibenzo[b,e][1,4]diazepine",
    ],
  },
  {
    primary_id: "D0Z1SL",
    names: ["Bazedoxifene/ Conjugated Estrogens", "Aprela (TN)"],
  },
  {
    primary_id: "D0Z1TF",
    names: ["Ro-25-4094"],
  },
  {
    primary_id: "D0Z1UA",
    names: [
      "Nebivolol",
      "Bystolic",
      "Lobivon",
      "Nebilet",
      "Nebilong",
      "Nebipill",
      "Nebivololum",
      "Nubeta",
      "Nebivololum [Latin]",
      "R 67555",
      "Bystolic (TN)",
      "Nebicard-5;Nebilet (TN)",
      "Nebilong (TN)",
      "Nodon (TN)",
      "Nubeta (TN)",
      "PI-21858",
      "R-67555",
      "R65,824",
      "Nebicard-5 (TN)",
      "Nebivolol (USAN/INN)",
      "Nebivolol [USAN:INN:BAN]",
      "Alpha,alpha'-(Iminodimethylene)Bis(6-Fluoro-2-Chromanmethanol)",
      "Alpha,alpha'-(Iminobis(Methylene))Bis(6-Fluoro-3,4-Dihydro-2H-1-Benzopyran-2-Methanol)",
      "Alpha,alpha'-(Iminobis(Methylene))Bis(6-Fluoro-3,4-Dihydro)-2H-1-Benzopyran-2-Methanol",
      "1-(6-Fluoro-3,4-Dihydro-2H-Chromen-2-Yl)-2-[[2-(6-Fluoro-3,4-Dihydro-2H-Chromen-2-Yl)-2-Hydroxyethyl]amino]ethanol",
      "2,2'-Iminobis[1-(6-Fluoro-3,4-Dihydro-2H-Chromen-2-Yl)Ethanol]",
    ],
  },
  {
    primary_id: "D0Z1UB",
    names: ["NAI-Acne"],
  },
  {
    primary_id: "D0Z1UD",
    names: ["MK-2118"],
  },
  {
    primary_id: "D0Z1UW",
    names: ["TTPABC"],
  },
  {
    primary_id: "D0Z1VE",
    names: ["SER-203"],
  },
  {
    primary_id: "D0Z1VM",
    names: ["PMID26560530-Compound-46"],
  },
  {
    primary_id: "D0Z1VS",
    names: ["RN6G"],
  },
  {
    primary_id: "D0Z1VU",
    names: ["Second Generation Therapeutics"],
  },
  {
    primary_id: "D0Z1WA",
    names: ["Procaterol"],
  },
  {
    primary_id: "D0Z1WF",
    names: [
      "4-((4-Methoxyphenyl)Diazenyl)Benzenesulfonamide",
      "CHEMBL213267",
      "4-[(4-Methoxyphenyl)Diazenyl]benzenesulfonamide",
      "NSC722840",
      "AC1L8P6K",
      "CTK7A1570",
      "MolPort-002-934-354",
      "ZINC6484196",
      "STK037234",
      "BDBM50190339",
      "AKOS005382697",
      "NSC-722840",
      "MCULE-3486440340",
      "4-(4-Methoxy-Phenylazo)-Benzenesulfonamide",
      "4-[(E)-(4-Methoxyphenyl)Diazenyl]benzenesulfonamide",
      "Benzenesulfonamide, 4-[(E)-2-(4-Methoxyphenyl)Diazenyl]-",
    ],
  },
  {
    primary_id: "D0Z1WH",
    names: ["CRAM 1-2"],
  },
  {
    primary_id: "D0Z1WQ",
    names: [
      "3,5-Di-Tert-Butyl-[1,2]benzoquinone",
      "3383-21-9",
      "3,5-Di-Tert-Butyl-O-Benzoquinone",
      "3,5-Di-Tert-Butyl-1,2-Benzoquinone",
      "3,5-Di(Tert-Butyl)Benzo-1,2-Quinone",
      "3,5-Cyclohexadiene-1,2-Dione, 3,5-Bis(1,1-Dimethylethyl)-",
      "3,5-Di-Tert-Butylcyclohexa-3,5-Diene-1,2-Dione",
      "O-Benzoquinone, 3,5-Di-Tert-Butyl-",
      "3,5-Di-Tert-Butylbenzo-1,2-Quinone",
      "NOUZOVBGCDDMSX-UHFFFAOYSA-N",
      "3,5-Di-T-Butyl-1,2-Benzoquinone",
      "3,5-Ditert-Butylbenzo-1,2-Quinone",
      "MFCD00001647",
      "3,5-Bis(Tert-Butyl)Cyclohexa-3,5-Diene-1,2-Dione",
      "Benzil-Related Compound, 54",
      "EINECS 222-189-0",
      "NSC 149061",
      "AC1Q6BRK",
    ],
  },
  {
    primary_id: "D0Z1WW",
    names: ["(S)-Cetirizine"],
  },
  {
    primary_id: "D0Z1WY",
    names: ["2-Chloro-2'-C-Methyl-Tecadenoson", "CHEMBL400782", "2-Chloro-2''-C-Methyl-Tecadenoson"],
  },
  {
    primary_id: "D0Z1XD",
    names: ["Methyltestosterone", "Android (TN)"],
  },
  {
    primary_id: "D0Z1XE",
    names: ["JWH-324"],
  },
  {
    primary_id: "D0Z1XR",
    names: ["2-Hydroxy-5-Isopropyl-2,4,6-Cycloheptatrien-1-One"],
  },
  {
    primary_id: "D0Z1XY",
    names: ["Des-AA1,2,5-[D-Trp8,IAmp9,(NalphaMe)Ser13]SRIF", "CHEMBL413442"],
  },
  {
    primary_id: "D0Z1YF",
    names: ["Biphenyl Mannoside Derivative 16"],
  },
  {
    primary_id: "D0Z1YK",
    names: ["EM-703"],
  },
  {
    primary_id: "D0Z1ZA",
    names: ["Mannoside Derivative 2"],
  },
  {
    primary_id: "D0Z1ZI",
    names: [
      "3-Nitropropionate",
      "3-Nitropropanoate",
      "3-Nitro-1-Propionate",
      "CHEMBL451226",
      "3-Nitropropanoate Anion",
      "3-Nitropropionate Anion",
      "3-Nitropropanoate(1-)",
      "3-Nitropropionate(1-)",
      "AC1ODZ5Z",
      "SCHEMBL3292643",
      "CHEBI:59899",
      "BDBM50274515",
    ],
  },
  {
    primary_id: "D0Z1ZM",
    names: [
      "Clarithromycin",
      "Abbotic",
      "Adel",
      "Astromen",
      "Biaxin",
      "Bicrolid",
      "CTY",
      "Clacine",
      "Clambiotic",
      "Claribid",
      "Claricide",
      "Clarith",
      "Clarithromycina",
      "Clarithromycine",
      "Clarithromycinum",
      "Claritromicina",
      "Clathromycin",
      "Cyllid",
      "Cyllind",
      "Helas",
      "Heliclar",
      "Klacid",
      "Klaciped",
      "Klaricid",
      "Klarid",
      "Klarin",
      "Klax",
      "Kofron",
      "Mabicrol",
      "Macladin",
      "Maclar",
      "Mavid",
      "Naxy;Veclam",
      "Zeclar",
      "Biaxin HP",
      "Biaxin XL",
      "Biaxin Filmtab",
      "Biaxin Xl Filmtab",
      "Clarithromycin Extended Release",
      "Clarithromycin Suspension Or Tablets",
      "Klaricid Pediatric",
      "Klaricid XL",
      "TE031",
      "A-56268",
      "ANX-015",
      "Abbott-56268",
      "Biaxin (TN)",
      "Clacid (TN)",
      "Claridar (TN)",
      "Claripen (TN)",
      "Clarithromycine [INN-French]",
      "Clarithromycinum [INN-Latin]",
      "Claritromicina [INN-Spanish]",
      "Crixan (TN)",
      "DRG-0099",
      "Fromilid (TN);Infex (TN)",
      "Klabax (TN)",
      "Klaricid (TN)",
      "Klaricid H.P",
      "Lactoferrin B & Clarithromycin",
      "Lactoferrin H & Clarithromycin",
      "SDP-015",
      "TE-031",
      "Vikrol (TN)",
      "CLM & IL-12",
      "CRL-1605 & Clarithromycin",
      "Clarithromycin & Interleukin-12",
      "Klaricid H.P.",
      "O(6)-Methylerythromycin",
      "Clarithromycin (JP15/USP/INN)",
      "Clarithromycin [USAN:INN:BAN:JAN]",
      "Hydro-2H-Pyran-2-Yl]oxy}-7-Methoxy-3,5,7,9,11,13-Hexame",
      "(14R)-14-Hydroxyclarithromycin",
      "6-O-Methylerythromycin",
      "6-O-Methylerythromycin A",
    ],
  },
  {
    primary_id: "D0Z2AY",
    names: ["RLIP76 Protein"],
  },
  {
    primary_id: "D0Z2BI",
    names: ["MRNA-1851"],
  },
  {
    primary_id: "D0Z2BO",
    names: ["CX-072"],
  },
  {
    primary_id: "D0Z2BT",
    names: [
      "DP-266",
      "Cancer Therapeutics (LipidoMimetix)",
      "DP-388",
      "DP-411",
      "Cancer Therapeutics (LipidoMimetix), D-Pharm",
    ],
  },
  {
    primary_id: "D0Z2CL",
    names: ["2-Aminoquinazoline-4-Carboxy-(4-Bromophenyl)Amide", "CHEMBL235988"],
  },
  {
    primary_id: "D0Z2CM",
    names: ["Lutropin Alfa"],
  },
  {
    primary_id: "D0Z2CN",
    names: ["ISOGRANULATIMIDE"],
  },
  {
    primary_id: "D0Z2CP",
    names: ["PF-05"],
  },
  {
    primary_id: "D0Z2CY",
    names: ["PLOGLITAZONE"],
  },
  {
    primary_id: "D0Z2EV",
    names: ["Conantokin-R"],
  },
  {
    primary_id: "D0Z2FB",
    names: ["Piclamilast"],
  },
  {
    primary_id: "D0Z2FD",
    names: [
      "BMS-908662",
      "XL281",
      "BMS 908662",
      "BMS-908662 Free Base",
      "870603-16-0",
      "GTPL7968",
      "SCHEMBL10049428",
      "SCHEMBL20299316",
      "CHEMBL3545027",
      "EXEL-2819",
      "Carbamic Acid, (5-(2-(5-Chloro-2-Methylphenyl)-2,3-Dihydro-1-Hydro",
      "BMS908662",
      "XL-281",
      "XL 281",
      "DB12854",
      "Carbamic Acid, N-(6-(2-(5-Chloro-2-Methylphenyl)-2,3-Dihydro-1-Hydroxy-3-Oxo-1H-Isoindol-1-Yl)-1H-Benzimidazol-2-Yl)-, Methyl Ester",
      "Methyl (5-(2-(5-Chloro-2-Methylphenyl)-1-Hydroxy-3-Oxo-2,3-Dihydro-1H-Isoindol-1-Yl)-1H-Benzimidazol-2-Yl)Carbamate, (+/-)-",
      "Methyl (5-(2-(5-Chloro-2-Methyl",
    ],
  },
  {
    primary_id: "D0Z2FF",
    names: ["AR244555", "AR 244555", "AR-244555"],
  },
  {
    primary_id: "D0Z2FX",
    names: ["AL-209"],
  },
  {
    primary_id: "D0Z2GF",
    names: [
      "NI-101",
      "BART",
      "Ch12F6A",
      "MAb (AD), Neurimmune/Biogen Idec",
      "Monoclonal Antibodies (Alzheimer!?s Disease), Neurimmune Therapeutics/Biogen Idec",
      "Anti-Beta Amyloid MAbs (Alzheimer!?s Disease), Neurimmune Therapeutics/Biogen Idec",
    ],
  },
  {
    primary_id: "D0Z2GH",
    names: [
      "AS-1387392",
      "AS-1429716",
      "FR-235222",
      "Histone Deacetylase Inhibitors (Immunosuppressants)",
      "Histone Deacetylase Inhibitors (Immunosuppressants), Astellas",
    ],
  },
  {
    primary_id: "D0Z2GQ",
    names: ["DV-601"],
  },
  {
    primary_id: "D0Z2HG",
    names: ["V950 Vaccine"],
  },
  {
    primary_id: "D0Z2IO",
    names: ["PMID27841045-Compound-143"],
  },
  {
    primary_id: "D0Z2IY",
    names: ["TMX-201"],
  },
  {
    primary_id: "D0Z2JF",
    names: ["Nivocasan"],
  },
  {
    primary_id: "D0Z2JJ",
    names: ["Aryl Pyrimidine Derivative 5"],
  },
  {
    primary_id: "D0Z2JN",
    names: ["N1,2-Dibenzyl-N3-Hydroxymalonamide", "CHEMBL113827", "2N-Dibenzyl-N''-Hydroxy-Malonamide", "BDBM50062474"],
  },
  {
    primary_id: "D0Z2JP",
    names: [
      "Vi-CRM197",
      "Bivalent Vaccine (Salmonella Typhi/Salmonella Paratyphi Infection/Typhoid Fever)",
      "Broad-Range Vaccine (Salmonella Infections), Novartis Vaccines Institute For Global Health",
      "S Tyhphi/CRM-197 Conjugate Vaccine, Novartis",
      "Bivalent Vaccine (Salmonella Typhi/Salmonella Paratyphi Infection/Typhoid Fever), Novartis Vaccines Institute For Global Health",
    ],
  },
  {
    primary_id: "D0Z2JR",
    names: ["LG-911"],
  },
  {
    primary_id: "D0Z2KB",
    names: ["Methionine Phosphonate"],
  },
  {
    primary_id: "D0Z2KU",
    names: ["S-288310"],
  },
  {
    primary_id: "D0Z2LG",
    names: ["Sennoside A"],
  },
  {
    primary_id: "D0Z2MB",
    names: ["Cilansetron", "Calmactin", "DU-123265", "KC-9946", "Cilansetron (USAN/INN)"],
  },
  {
    primary_id: "D0Z2MJ",
    names: [
      "2-Amino-6-(3-Bromo-Benzenesulfonyl)-Benzonitrile",
      "AC1LA8EO",
      "CHEMBL293989",
      "SCHEMBL2641656",
      "BDBM1792",
      "CTK7C6572",
      "ZINC20483",
      "2-Amino-6-Arylthiobenzonitrile Deriv. 3l",
      "2-Amino-6-(3-Bromophenyl)Sulfonylbenzonitrile",
      "2-(3-Bromophenylsulfonyl)-6-Aminobenzonitrile",
      "2-Amino-6-(3-Bromophenyl)Sulfonyl-Benzonitrile",
      "2-Amino-6-[(3-Bromophenyl)Sulfonyl]benzonitrile",
    ],
  },
  {
    primary_id: "D0Z2MX",
    names: ["Acyl Oxymethyl Acrylamide Ester Derivative 1"],
  },
  {
    primary_id: "D0Z2NA",
    names: ["AT-005"],
  },
  {
    primary_id: "D0Z2NJ",
    names: ["ASN100"],
  },
  {
    primary_id: "D0Z2NN",
    names: ["Heptyl-Methyl-Prop-2-Ynyl-Amine Hydrochloride"],
  },
  {
    primary_id: "D0Z2NY",
    names: ["Aom-0763", "Aim-0763", "Xanthine Oxidase Inhibitor (Oral/Tablet, Gout), Hangzhou Adamerck Pharmlabs"],
  },
  {
    primary_id: "D0Z2OW",
    names: ["ISIS 10639"],
  },
  {
    primary_id: "D0Z2PC",
    names: ["Pyrrolo[2,3-D]pyrimidine Derivative 24"],
  },
  {
    primary_id: "D0Z2PJ",
    names: ["4-Benzyloxybenzo[b]thiophene-2-Carboxamidine", "CHEMBL598191", "SCHEMBL13709500"],
  },
  {
    primary_id: "D0Z2QM",
    names: ["7-Isopropyl-7H-Adenine", "CHEMBL448396", "55146-09-3", "SCHEMBL756635"],
  },
  {
    primary_id: "D0Z2QO",
    names: ["ISIS 102482"],
  },
  {
    primary_id: "D0Z2QR",
    names: ["DS-7250"],
  },
  {
    primary_id: "D0Z2QV",
    names: ["Thiophosphoric Acid Decyl Ester"],
  },
  {
    primary_id: "D0Z2RA",
    names: [
      "CANTHARIDIN",
      "DSSTox_CID_21752",
      "DSSTox_RID_79835",
      "DSSTox_GSID_41752",
      "CAS-56-25-7",
      "NCGC00016247-01",
      "Spectrum_001114",
      "SpecPlus_000537",
      "Spectrum4_000920",
      "Spectrum2_000630",
      "Prestwick0_000885",
      "Spectrum3_000621",
      "Prestwick1_000885",
      "Spectrum5_001618",
      "Prestwick2_000885",
      "KBioSS_001594",
      "KBioGR_001420",
      "BSPBio_002182",
      "MLS002153505",
      "SCHEMBL152261",
      "DivK1c_006633",
      "SPBio_002889",
      "SPBio_000600",
      "CHEMBL299846",
      "DTXSID7041752",
      "KBio2_006730",
      "KBio3_001682",
      "KBio2_004162",
      "KBio2_001594",
      "KBio1_001577",
      "MolPort-003-665-556",
      "HMS1570B12",
      "Tox21_110326",
      "CCG-39927",
      "Tox21_110326_1",
    ],
  },
  {
    primary_id: "D0Z2RM",
    names: ["ISIS 101997"],
  },
  {
    primary_id: "D0Z2RR",
    names: ["2-Aminomethyl-Pyrrolidine-3,4-Diol", "CHEMBL79727"],
  },
  {
    primary_id: "D0Z2SL",
    names: ["Stachyflin"],
  },
  {
    primary_id: "D0Z2TG",
    names: ["PEDUNCULAGIN"],
  },
  {
    primary_id: "D0Z2TH",
    names: ["IMD-015213"],
  },
  {
    primary_id: "D0Z2TV",
    names: ["ASN04885796", "ASN 04885796", "ASN-04885796"],
  },
  {
    primary_id: "D0Z2UD",
    names: ["RS-100975"],
  },
  {
    primary_id: "D0Z2UQ",
    names: [
      "AZD2014",
      "1009298-59-2",
      "Vistusertib",
      "AZD-2014",
      "AZD 2014",
      "UNII-0BSC3P4H5X",
      "0BSC3P4H5X",
      "Cc-551",
      "3-[2,4-Bis((3S)-3-Methylmorpholin-4-Yl)Pyrido[5,6-E]pyrimidin-7-Yl]-N-Methylbenzamide",
      "CHEMBL2336325",
      "3-[2,4-Bis((3S)-3-MethyLmorpholin-4-Yl)Pyrido-[5,6-E]pyrimidin-7-Yl]-N-Methylbenzamide",
      "C25H30N6O3",
      "3-(2,4-Bis((S)-3-Methylmorpholino)Pyrido[2,3-D]pyrimidin-7-Yl)-N-Methylbenzamide",
      "3-[2,4-Bis[(3S)-3-Methylmorpholin-4-Yl]pyrido[2,3-D]pyrimidin-7-Yl]-N-Methylbenzamide",
      "Vistusertib [INN]",
      "Vistusertib [USAN]",
      "Vistusertib (JAN/INN)",
    ],
  },
  {
    primary_id: "D0Z2UT",
    names: ["BNP-FIX"],
  },
  {
    primary_id: "D0Z2UW",
    names: ["Nicaraven", "AVS", "Antevan", "Antevas"],
  },
  {
    primary_id: "D0Z2UY",
    names: ["Antigen-Specific Melanoma Vaccine"],
  },
  {
    primary_id: "D0Z2VP",
    names: [
      "N-Hydroxy-9-Oxo-9-Phenylnonanamide",
      "CHEMBL99810",
      "SCHEMBL1521077",
      "BDBM50114824",
      "9-Oxo-9-Phenyl-Nonanoic Acid Hydroxyamide",
    ],
  },
  {
    primary_id: "D0Z2WQ",
    names: ["Quinelorane"],
  },
  {
    primary_id: "D0Z2WV",
    names: ["ASP6981"],
  },
  {
    primary_id: "D0Z2XR",
    names: ["(+/-)-7-Methyl-2-P-Tolylchroman-4-One", "CHEMBL600597"],
  },
  {
    primary_id: "D0Z2YB",
    names: ["Pyrido[1,2-A]indole-1.-Carboxylic Acid Analog 6"],
  },
  {
    primary_id: "D0Z2YQ",
    names: ["Patiromer Calcium"],
  },
  {
    primary_id: "D0Z2ZY",
    names: ["SL-401"],
  },
  {
    primary_id: "D0Z3AB",
    names: [
      "Anti-PSA Mabs",
      "Anti-PSA IgG1",
      "Anti-PSA IgG3",
      "Anti-PSA MAbs (Prostate Cancer)",
      "Anti-PSA MAbs (Prostate Cancer), University Of California/AIT",
      "Anti-PSA Monoclonal Antibodies (Prostate Cancer), University Of California/Advanced Immune Therapeutics",
    ],
  },
  {
    primary_id: "D0Z3AK",
    names: [
      "NPC-17923",
      "147960-65-4",
      "N-(9H-(2,7-Dichlorofluorenyl)-9-Ethoxycarbonyl)-4-Aminobenzoic Acid",
      "Benzoic Acid, 4-((3-((2,7-Dichloro-9H-Fluoren-9-Yl)Oxy)-1-Oxopropyl)Amino)-",
    ],
  },
  {
    primary_id: "D0Z3BM",
    names: ["7-(Biphenyl-4-Yloxy)-1,1,1-Trifluoro-Heptan-2-One", "CHEMBL326529", "SCHEMBL7365237", "BDBM50217957"],
  },
  {
    primary_id: "D0Z3BT",
    names: [
      "LUF-5764",
      "CHEMBL222504",
      "820961-49-7",
      "SCHEMBL4822595",
      "CTK3E2654",
      "DTXSID00466708",
      "BDBM50157682",
      "AKOS030569391",
      "L023251",
      "N-(2,6-Diphenylpyrimidin-4-Yl)-3,3-Dimethylbutyramide",
      "Butanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-3,3-Dimethyl-",
    ],
  },
  {
    primary_id: "D0Z3BU",
    names: ["Drug 2878175", "2878175"],
  },
  {
    primary_id: "D0Z3CC",
    names: ["2-Morpholino-6-(Quinolin-8-Yl)-4H-Pyran-4-One", "CHEMBL223041"],
  },
  {
    primary_id: "D0Z3CK",
    names: ["PMID22533316C3"],
  },
  {
    primary_id: "D0Z3CZ",
    names: [
      "5-(2-Methylpiperazin-1-Ylsulfonyl)Isoquinoline",
      "1-(5-Isoquinolinylsulfonyl)-2-Methylpiperazine",
      "1-(5-ISOQUINOLINESULFONYL)-2-METHYLPIPERAZINE",
      "84477-87-2",
      "H-7",
      "5-(2-Methylpiperazine-1-Sulfonyl)Isoquinoline",
      "H 7",
      "H7",
      "Piperazine, 1-(5-Isoquinolinylsulfonyl)-2-Methyl-",
      "BRN 5482740",
      "MLS000069615",
      "CHEMBL323556",
      "CHEBI:43385",
      "Isoquinoline,5-[(2-Methyl-1-Piperazinyl)Sulfonyl]-,hydrochloride (1:2)",
      "5-[(2-Methylpiperazin-1-Yl)Sulfonyl]isoquinoline",
      "SMR000058749",
      "Isoquinoline-5-Sulfonic 2-Methyl-1-Piperazide",
      "5-(2-Methylpiperazin-1-Yl)Sulfonylisoquinoline",
      "Piperazine, 1-(5-Isoquino",
    ],
  },
  {
    primary_id: "D0Z3DW",
    names: [
      "Bis(Benzo[b]furan-2-Yl)Methanone",
      "CHEMBL226417",
      "Bis-Benzofuranyl Ketone",
      "AC1PLNEG",
      "SCHEMBL244107",
      "Bis(1-Benzofuran-2-Yl)Methanone",
      "MolPort-000-640-603",
      "ZINC8048290",
      "BDBM50202218",
      "AKOS002385499",
      "MCULE-1633046929",
      "2-(1-Benzofuran-2-Carbonyl)-1-Benzofuran",
      "Z232333564",
    ],
  },
  {
    primary_id: "D0Z3DY",
    names: [
      "Benzbromarone",
      "Acifugan",
      "Azubromaron",
      "Benzbromaron",
      "Benzbromaronratiopharm",
      "Benzbromaronum",
      "Benzobromarona",
      "Besuric",
      "Desuric",
      "Exurate",
      "Harolan",
      "Hipurik",
      "Minuric",
      "Narcaricin",
      "Normurat",
      "Uricovac",
      "Urinorm",
      "Uroleap",
      "Aliud Brand Of Benzbromarone",
      "Benzbromaron AL",
      "Benzbromaron Ratiopharm",
      "Benzbromarone Aliud Brand",
      "Benzbromarone Heumann Brand",
      "Benzbromarone Sanfer Brand",
      "Benzbromarone Ratiopharm Brand",
      "Heumann Brand Of Benzbromarone",
      "Ratiopharm Brand Of Benzbromarone",
      "Sanfer Brand Of Benzbromarone",
      "Sanofi Winthrop Brand Of Benzbromarone",
      "L 2214",
      "L2214",
      "MJ 10061",
      "NCI85433",
      "AL, Benzbromaron",
      "Benzbromaron-Ratiopharm",
      "Benzbromaronum [INN-Latin]",
      "Benzobromarona [INN-Spanish]",
      "L 2214-Labaz",
      "L-2214",
      "Uroleap (TN)",
      "Benzbromarone [USAN:INN:BAN]",
      "Methanone, (3",
      "Benzbromarone (JP15/USAN/INN)",
      "KETONE, 3,5-DIBROMO-4-HYDROXYPHENYL 2-ETHYL-3-BENZOFURANYL",
      "Ketone, 3,5-Dibromo-4-Hydroxyphenyl 2-Ethyl-3-Benzofuranyl",
      "(2-Ethyl-3-Benzofuranyl)-(3,5-Dibrom-4-Hydroxyphenyl)Keton",
      "(3,5-Dibromo-4-Hydroxyphenyl)(2-Ethyl-1-Benzofuran-3-Yl)Methanone",
      "(3,5-Dibromo-4-Hydroxyphenyl)-(2-Ethyl-1-Benzofuran-3-Yl)Methanone",
      "2-Ethyl-3-(3,5-Dibrom-4-Hydroxybenzoyl)Benzofuran",
      "3, 5-Dibromo-4-Hydroxyphenyl-2-Ethyl-3-Benzofuranyl Ketone",
      "3,5-Dibromo-4-Hydroxyphenyl-2-Ethyl-3-Benzofuranyl Ketone",
      "3-(3,5-Dibromo-4-Hydroxybenzoyl)-2-Ethylbenzofuran",
      "3-[3,5-DIBROMO-4-HYDROXYBENZOYL]-2-ETHYLBENZOFURAN",
    ],
  },
  {
    primary_id: "D0Z3EG",
    names: ["4-Methylhomoibotenic Acid", "(R,S)-4-Methylhomoibotenic Acid"],
  },
  {
    primary_id: "D0Z3EI",
    names: ["Quinolones", "AC1MI0KP"],
  },
  {
    primary_id: "D0Z3ES",
    names: ["Melanoma Vaccine, University Of Virginia"],
  },
  {
    primary_id: "D0Z3EV",
    names: ["Imbruvicaibrutinib"],
  },
  {
    primary_id: "D0Z3FP",
    names: ["NBBCC"],
  },
  {
    primary_id: "D0Z3FV",
    names: ["MPL-Containing Pollinex Allergy Desensitization Vaccine"],
  },
  {
    primary_id: "D0Z3FX",
    names: ["Patuletin 3-O-Beta-D-Galactoside", "AC1NSZGN", "CHEMBL483837"],
  },
  {
    primary_id: "D0Z3GC",
    names: ["KNI-10760"],
  },
  {
    primary_id: "D0Z3GX",
    names: ["4-(4-(Thiophen-3-Yl)Benzyl)Pyridine", "CHEMBL1214735", "SCHEMBL18770375"],
  },
  {
    primary_id: "D0Z3HF",
    names: ["AE-IG"],
  },
  {
    primary_id: "D0Z3HM",
    names: ["FGGFTGARKSARKKANQ"],
  },
  {
    primary_id: "D0Z3HW",
    names: ["Thiazole Carboxamide Derivative 30"],
  },
  {
    primary_id: "D0Z3IZ",
    names: ["N*6*-Cyclohexyl-N*2*-Ethyl-9H-Purine-2,6-Diamine", "CHEMBL191551"],
  },
  {
    primary_id: "D0Z3JC",
    names: ["Dual-Action Antibiotics", "Dual-Action Antibiotics, Roche", "Ro-24-6778"],
  },
  {
    primary_id: "D0Z3JI",
    names: ["N1,N14-Bis((S-Methyl)Isothioureido)Tetradecane"],
  },
  {
    primary_id: "D0Z3KA",
    names: ["THIOCOLCHICINE"],
  },
  {
    primary_id: "D0Z3KV",
    names: ["NSC-270718"],
  },
  {
    primary_id: "D0Z3LC",
    names: ["SR-4554", "CRC-94/17", "N-(3,3,3-Trifluoro-2-Hydroxypropyl)-2-(2-Nitro-1-Imidazolyl)Acetamide"],
  },
  {
    primary_id: "D0Z3LN",
    names: ["LT-1945"],
  },
  {
    primary_id: "D0Z3LY",
    names: ["SOM-0010"],
  },
  {
    primary_id: "D0Z3LZ",
    names: ["Des-AA1,2,4,5,13-[D-Trp8]-SRIF", "CHEMBL386784"],
  },
  {
    primary_id: "D0Z3MT",
    names: ["MG149"],
  },
  {
    primary_id: "D0Z3NC",
    names: ["HPTE"],
  },
  {
    primary_id: "D0Z3NE",
    names: ["PMID24099220C5i"],
  },
  {
    primary_id: "D0Z3OD",
    names: [
      "8-Amino-9-Benzylguanine",
      "100890-94-6",
      "NSC650999",
      "CHEMBL8230",
      "NSC-650999",
      "6H-Purin-6-One,2,8-Diamino-1,9-Dihydro-9-(Phenylmethyl)-",
      "8-Amino-9-Benzyl-Guanine",
      "8-ABG",
      "2,8-Diamino-9-Benzyl-3H-Purin-6-One",
      "ACMC-20m3xx",
      "AC1L2SFN",
      "SCHEMBL6359274",
      "CTK3J9334",
      "ZINC16958226",
      "BDBM50005799",
      "AKOS030559033",
      "NCI60_017833",
      "2-Amino-9-Benzyl-8-Imino-7H-Purin-6-Ol",
      "2-Amino-9-Benzyl-8-Imino-8,9-Dihydro-7H-Purin-6-Ol",
      "6H-Purin-6-One,",
    ],
  },
  {
    primary_id: "D0Z3OO",
    names: ["PneuGEM"],
  },
  {
    primary_id: "D0Z3PB",
    names: ["2-Ethylquinoline-8-Carboxamide", "CHEMBL526128", "8-Quinolinecarboxamide, 2-Ethyl-", "BDBM50255330"],
  },
  {
    primary_id: "D0Z3PC",
    names: ["Patisiran"],
  },
  {
    primary_id: "D0Z3PD",
    names: ["Purified Secretory Immunoglobulin"],
  },
  {
    primary_id: "D0Z3PG",
    names: [
      "Dihydrolipoic Acid",
      "Dihydrolipoate",
      "Dihydrothioctic Acid",
      "6,8-Disulfanyloctanoic Acid",
      "DHLA",
      "462-20-4",
      "6,8-Dimercaptooctanoic Acid",
      "Reduced Lipoic Acid",
      "6,8-Dihydrothioctic Acid",
      "Reduced Thioctic Acid",
      "Lipoic Acid, Dihydro-",
      "Thioctic Acid, Dihydro-",
      "Dl-Dihydro-Alpha-6-Thioctic Acid",
      "USAF XR-12",
      "Dihydro-Lipoic Acid",
      "6,8-Bis-Sulfanyloctanoic Acid",
      "D,L-Dihydrolipoic Acid",
      "Dihydro-Alpha-Lipoic Acid",
      "Thiocytic Acid, Dihydro-",
      "OCTANOIC ACID, 6,8-DIMERCAPTO-",
      "6,8-Bismercaptooctanoic Acid",
      "6,8-Dimercapto-Octanoic Acid",
      "DL-Alpha-Lipoic A",
    ],
  },
  {
    primary_id: "D0Z3PO",
    names: ["BIIB014"],
  },
  {
    primary_id: "D0Z3QG",
    names: ["(Sar)WTLNSAGYLLGPKK(Lys-Stearoyl)K", "CHEMBL450827"],
  },
  {
    primary_id: "D0Z3RF",
    names: [
      "UCB-11056",
      "127390-77-6",
      "Ucb-11056",
      "Ucb 11056",
      "2-(4-Morpholino-6-Propyl-1,3,5-Triazin-2-Yl)Aminoethanol",
      "UNII-MU6BC50P47",
      "MU6BC50P47",
      "2-((4-Morpholino-6-Propyl-1,3,5-Triazin-2-Yl)Amino)Ethanol",
      "2-((4-(4-Morpholinyl)-6-Propyl-1,3,5-Triazin-2-Yl)Amino)Ethanol",
      "Ethanol,2-[[4-(4-Morpholinyl)-6-Propyl-1,3,5-Triazin-2-Yl]amino]-",
      "Ethanol, 2-((4-(4-Morpholinyl)-6-Propyl-1,3,5-Triazin-2-Yl)Amino)-",
      "NERMEVBNTXXDNG-UHFFFAOYSA-N",
      "ACMC-20msf9",
      "AC1L2Y9Y",
      "SCHEMBL3365592",
      "CTK4B5589",
      "DTXSID60155545",
      "ZINC3803539",
    ],
  },
  {
    primary_id: "D0Z3RZ",
    names: ["EISO Ointment"],
  },
  {
    primary_id: "D0Z3SV",
    names: [
      "Bcx-1812",
      "330600-85-6",
      "229614-55-5",
      "UNII-9ZS94HQO3B",
      "RAPIVAB",
      "CHEBI:85202",
      "9ZS94HQO3B",
      "(1S,2S,3R,4R)-3-((S)-1-Acetamido-2-Ethylbutyl)-4-Guanidino-2-Hydroxycyclopentanecarboxylic Acid",
      "229614-56-6",
    ],
  },
  {
    primary_id: "D0Z3TJ",
    names: ["GSK2033"],
  },
  {
    primary_id: "D0Z3TU",
    names: ["ISIS 6435"],
  },
  {
    primary_id: "D0Z3UP",
    names: ["Dabuzalgron"],
  },
  {
    primary_id: "D0Z3UX",
    names: [
      "Albumin-Binding Paclitaxel Prodrug",
      "Albumin-Binding Paclitaxel Prodrug (Prostate Cancer)",
      "PSA-Activated Paclitaxel Prodrug, TumorBiology Center Freiburg",
      "Albumin-Binding Paclitaxel Prodrug (Prostate Cancer), ProQinase",
    ],
  },
  {
    primary_id: "D0Z3WA",
    names: [
      "Delta1-Dihydrotestosterone",
      "1-Testosterone",
      "1-Testo",
      "1965/6/5",
      "17beta-Hydroxy-5alpha-Androst-1-En-3-One",
      "UNII-Y984BV1Q0G",
      "Y984BV1Q0G",
      "5alpha-Androst-1-En-17beta-Ol-3-One",
      "1-T [Steroid]",
      "5.alpha.-Androst-1-En-3-One, 17.beta.-Hydroxy-",
      "AC1L5X4C",
      "Delta(1)-Dihydrotestosterone",
      "SCHEMBL867595",
      "CHEBI:59714",
      "DTXSID80215312",
      "OKJCFMUGMSVJBG-ABEVXSGRSA-N",
      "NSC39366",
      "ZINC4791927",
      "NSC121140",
      "NSC-39366",
      "LMST02020110",
      "NSC-121140",
      "DB01481",
      "LS-193302",
      "(5alpha,17beta)-Hydroxyandrost-1-En-3-One",
      "C15377",
      "171T609",
      "1-T",
    ],
  },
  {
    primary_id: "D0Z3XC",
    names: [
      "N-(2,6-Diphenylpyrimidin-4-Yl)Acetamide",
      "CHEMBL426565",
      "820961-40-8",
      "SCHEMBL4823120",
      "CTK3E2663",
      "DTXSID10458098",
      "ZINC13586450",
      "BDBM50157676",
      "AKOS030563968",
      "Acetamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D0Z3XJ",
    names: [
      "3-(2-(Naphthalen-2-Ylmethyl)Phenyl)Acrylic Acid",
      "CHEMBL423815",
      "SCHEMBL7922112",
      "SCHEMBL7922115",
      "BDBM50097792",
      "2-(2-Naphthylmethyl)Benzeneacrylic Acid",
      "(E)-3-(2-Naphthalen-2-Ylmethyl-Phenyl)-Acrylic Acid",
    ],
  },
  {
    primary_id: "D0Z3XO",
    names: ["MP-136"],
  },
  {
    primary_id: "D0Z3YP",
    names: ["ALGRX-1207"],
  },
  {
    primary_id: "D0Z3YY",
    names: [
      "LUF-5735",
      "CHEMBL222719",
      "820961-65-7",
      "N-(4,6-Diphenylpyrimidin-2-Yl)Butanamide",
      "SCHEMBL6499771",
      "CTK3E2643",
      "DTXSID60464548",
      "MolPort-007-705-553",
      "ZINC6716086",
      "BDBM50157673",
      "AKOS001552900",
      "MCULE-5627024228",
      "N-(4,6-Diphenylpyrimidin-2-Yl)Butyramide",
      "Butanamide, N-(4,6-Diphenyl-2-Pyrimidinyl)-",
    ],
  },
  {
    primary_id: "D0Z3ZF",
    names: ["TOCA-621"],
  },
  {
    primary_id: "D0Z3ZZ",
    names: [
      "(+/-)-2-Methyl-1-(1-Phenylethyl)-1H-Imidazole",
      "Efetozole",
      "CHEMBL1269155",
      "Efetozole [INN]",
      "2-Methyl-1-(1-Phenylethyl)-1H-Imidazole",
      "AC1L4LLD",
      "SCHEMBL2109012",
      "MolPort-020-890-666",
      "CGP-22364A",
      "BDBM50330055",
      "2-Methyl-1-(1-Phenylethyl)Imidazole",
      "AKOS013440375",
      "90408-21-2",
      "(+-)-2-Methyl-1-(Alpha-Methylbenzyl)Imidazole",
      "(- )-2-Methyl-1-(Alpha-Methylbenzyl)Imidazole.",
    ],
  },
  {
    primary_id: "D0Z4AC",
    names: ["2-Amino-N1-Benzyl-N3-Hydroxymalonamide", "CHEMBL219940"],
  },
  {
    primary_id: "D0Z4AU",
    names: ["GTPgammaS"],
  },
  {
    primary_id: "D0Z4BH",
    names: [
      "Celtura",
      "A/H1N1v",
      "A/H1N1v Vaccine (Influenza Virus Infection), Novartis",
      "H1N1 Pandemic Influenza A Vaccine (MF59-Adjuvanted, Cell Culture), Novartis",
    ],
  },
  {
    primary_id: "D0Z4BP",
    names: ["Human Liver-Engrafting Cells"],
  },
  {
    primary_id: "D0Z4BV",
    names: ["Oxiracetam"],
  },
  {
    primary_id: "D0Z4CC",
    names: ["ISIS 122990"],
  },
  {
    primary_id: "D0Z4CY",
    names: ["PEN-221"],
  },
  {
    primary_id: "D0Z4EI",
    names: ["Mifepristone"],
  },
  {
    primary_id: "D0Z4FE",
    names: ["MIN-117"],
  },
  {
    primary_id: "D0Z4GF",
    names: ["SDZ-PTS-893", "155383-07-6", "PTH Agonist, Sandoz"],
  },
  {
    primary_id: "D0Z4GJ",
    names: ["AR-C118925XX", "AR-C118925"],
  },
  {
    primary_id: "D0Z4GR",
    names: [
      "4-(2,2-Diphenyl-Vinyl)-Pyridine",
      "4-(2,2-Diphenylethenyl)Pyridine",
      "4-Pyridylmethylene 35",
      "AC1NEND1",
      "CHEMBL381194",
      "BDBM8645",
      "SCHEMBL16946606",
    ],
  },
  {
    primary_id: "D0Z4HK",
    names: ["BC-264", "BC264", "BC 264"],
  },
  {
    primary_id: "D0Z4HV",
    names: ["7-Chloro-3-Pyridin-4-Yl-Quinoline", "CHEMBL86664", "7-Chloro-3-(4-Pyridyl)Quinoline"],
  },
  {
    primary_id: "D0Z4IA",
    names: ["Pyrido[1,2,4]triazolo[4,3-A]pyrazine Derivative 1"],
  },
  {
    primary_id: "D0Z4IH",
    names: ["8S-Hydroxylobel-9-Ene", "CHEMBL213613", "SCHEMBL14178965"],
  },
  {
    primary_id: "D0Z4JG",
    names: [
      "4-Methoxynaphthalen-1-Amine",
      "16430-99-2",
      "1-Naphthalenamine, 4-Methoxy-",
      "1-Amino-4-Methoxynaphthalene",
      "CHEMBL572058",
      "JVMUPDOMGALPOW-UHFFFAOYSA-N",
      "F2146-0572",
      "4-Methoxy Naphthylamine",
      "ACMC-209dqv",
      "4-Methoxy-1-Naphthalenamine",
      "1-Methoxy-4-Naphthalenamine",
      "SCHEMBL331453",
      "1 -Amino-4-Methoxy-Naphthalene",
      "CTK8B0935",
      "DTXSID80454284",
      "ZINC11919966",
      "BDBM50303938",
      "ANW-22085",
      "AKOS009236876",
      "MCULE-9788991699",
      "4-Methoxynaphthalen-1-Amine, AldrichCPR",
      "CJ-13724",
      "AJ-60498",
      "TC-110830",
      "FT-0750226",
    ],
  },
  {
    primary_id: "D0Z4JL",
    names: ["KKHT-10618"],
  },
  {
    primary_id: "D0Z4JT",
    names: ["Example 92 (WO2012095521)"],
  },
  {
    primary_id: "D0Z4KU",
    names: ["7-(4,6-Dimethylpyrimidin-2-Yl)-2-Methylquinoline", "CHEMBL245236"],
  },
  {
    primary_id: "D0Z4KX",
    names: [
      "Farampator",
      "CX 691",
      "CX-691",
      "Farampator (USAN/INN)",
      "1-(Benzofurazan-5-Ylcarbonyl)Piperidine",
      "2,1,3-Benzoxadiazol-5-Yl(Piperidin-1-Yl)Methanone",
    ],
  },
  {
    primary_id: "D0Z4LO",
    names: ["Ro 28-2653"],
  },
  {
    primary_id: "D0Z4LP",
    names: ["ISIS 31996"],
  },
  {
    primary_id: "D0Z4MH",
    names: ["ZY H2"],
  },
  {
    primary_id: "D0Z4NA",
    names: ["EG-P119", "ADHD Therapeutic (Oral Controlled Release), Egalet"],
  },
  {
    primary_id: "D0Z4NI",
    names: ["Zinc Acetate"],
  },
  {
    primary_id: "D0Z4NU",
    names: ["2-Cinnamamido-N1-Hydroxy-N4-Octylsuccinamide", "CHEMBL594848"],
  },
  {
    primary_id: "D0Z4NW",
    names: ["(E)-6-Nitrooctadec-5-Enoic Acid", "CHEMBL569372"],
  },
  {
    primary_id: "D0Z4NX",
    names: ["FR-171113", "CTK8E8568"],
  },
  {
    primary_id: "D0Z4OQ",
    names: ["TAK-783"],
  },
  {
    primary_id: "D0Z4OS",
    names: [
      "N-Benzyl-2-Morpholinopyrimidin-4-Amine",
      "CHEMBL1085781",
      "BDBM50319969",
      "AKOS028689215",
      "SR-01000647481",
    ],
  },
  {
    primary_id: "D0Z4PE",
    names: ["Zinostatin Stimalamer"],
  },
  {
    primary_id: "D0Z4QC",
    names: ["SDZ-SER-082"],
  },
  {
    primary_id: "D0Z4QJ",
    names: ["PMID25623274-Compound-WO2014132220C1"],
  },
  {
    primary_id: "D0Z4QQ",
    names: ["PMID25666693-Compound-160"],
  },
  {
    primary_id: "D0Z4QT",
    names: ["3-Substituted-1,2,4-Oxadiazole Derivative 1"],
  },
  {
    primary_id: "D0Z4QV",
    names: ["DUP-734", "1-(Cyclopropylmethyl)-4-[2-(4-Fluorophenyl)-2-Oxoethyl]piperidine Hydrobromide"],
  },
  {
    primary_id: "D0Z4RC",
    names: [
      "CUDC-101",
      "1012054-59-9",
      "CUDC 101",
      "CUDC101",
      "7-(4-(3-Ethynylphenylamino)-7-Methoxyquinazolin-6-Yloxy)-N-Hydroxyheptanamide",
      "UNII-1A7Y9MP123",
      "CHEMBL598797",
      "1A7Y9MP123",
      "7-[[4-(3-Ethynylphenylamino)-7-Methoxyquinazolin-6-Yl]oxy]-N-Hydroxyheptanamide",
      "AK174946",
      "7-((4-(3-Ethynylphenylamino)-7-Methoxyquinazolin-6-Yl)Oxy)-N-Hydroxyheptanamide",
      "7-({4-[(3-Ethynylphenyl)Amino]-7-Methoxyquinazolin-6-Yl}oxy)-N-Hydroxyheptanamide",
      "7-((4-((3-Ethynylphenyl)Amino)-7-Methoxyquinazolin-6-Yl)Oxy)-N-Hydroxyheptanamide",
      "PubChem19149",
    ],
  },
  {
    primary_id: "D0Z4RI",
    names: ["ISIS 112021"],
  },
  {
    primary_id: "D0Z4RS",
    names: ["PMID25470667-Compound-Roselipins"],
  },
  {
    primary_id: "D0Z4RX",
    names: ["HuMax-CMet", "CMet-Targeting Human IgG1 Antibody (Cancer), Genmab"],
  },
  {
    primary_id: "D0Z4RZ",
    names: ["Adamant-1-Ylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL485883"],
  },
  {
    primary_id: "D0Z4SA",
    names: ["2-(2-Phenyl-1H-Indol-3-Yl)-N,N-Dipropyl-Acetamide", "CHEMBL327286", "BDBM50045857"],
  },
  {
    primary_id: "D0Z4SB",
    names: [
      "Glipizide",
      "Aldiab",
      "Digrin",
      "Dipazide",
      "Glibenese",
      "Glibetin",
      "Glican",
      "Glide",
      "Glidiab",
      "Glidiazinamide",
      "Glipid",
      "Glipizida",
      "Glipizidum",
      "Glucolip",
      "Glucotrol",
      "Glucozide",
      "Glupitel",
      "Glupizide",
      "Glyde",
      "Glydiazinamide",
      "Glypidizine",
      "Melizide",
      "Metaglip",
      "Mindiab",
      "Minidab",
      "Minidiab",
      "Minodiab",
      "Napizide",
      "Ozidia",
      "Semiglynase",
      "Sucrazide",
      "Alphapharm Brand Of Glipizide",
      "Glibenese Brand Of Glipizide",
      "Glipizide Kenfarma Brand",
      "Glucotrol XL",
      "Kenfarma Brand Of Glipizide",
      "Lacer Brand Of Glipizide",
      "Lilly Brand Of Glipizide",
      "CP 28720",
      "K 4024",
      "K4024",
      "PfizerBrand 1 Of Glipizide",
      "Pfizer Brand 2 Of Glipizide",
      "TK 1320",
      "CP 28,720",
      "CP-28720",
      "G-117",
      "Glipizida [INN-Spanish]",
      "Glipizide Extended-Release Tablets",
      "Glipizidum [INN-Latin]",
      "Gluco-Rite",
      "Glucotrol (TN)",
      "K-4024",
      "KS-1068",
      "Samarium(III) Ionophore I",
      "Glipizide (USP/INN)",
      "Glipizide [USAN:BAN:INN]",
      "Glucotrol XL, Glucotrol, Glipizide",
      "N-[2-[4-(Cyclohexylcarbamoylsulfamoyl)Phenyl]ethyl]-5-Methylpyrazine-2-Carboxamide",
      "N-(2-{4-[(Cyclohexylcarbamoyl)Sulfamoyl]phenyl}ethyl)-5-Methylpyrazine-2-Carboxamide",
      "N-[2-(4-{[(Cyclohexylcarbamoyl)Amino]sulfonyl}phenyl)Ethyl]-5-Methylpyrazine-2-Carboxamide",
      "N-(4-(Beta-(5-Methylpyrazine-2-Carboxamido)Ethyl)Benzenesulphonyl)-N'-Cyclohexylurea",
      "1-Cyclohexyl-3-((P-(2-(5-Methylpyrazinecarboxamido)Ethyl)Phenyl)Sulfonyl)Urea",
      "1-Cyclohexyl-3-{4-[2-(5-Methylpyrazine-2-Carboxamido)Ethyl]phenylsulfonyl}urea",
    ],
  },
  {
    primary_id: "D0Z4SP",
    names: ["RG7426", "BHT-3021"],
  },
  {
    primary_id: "D0Z4SS",
    names: ["[125I]-BDZ2", "CHEMBL515288", "GTPL6667"],
  },
  {
    primary_id: "D0Z4SW",
    names: ["Benzimidazole Derivative 8"],
  },
  {
    primary_id: "D0Z4UF",
    names: ["RTI-219"],
  },
  {
    primary_id: "D0Z4UN",
    names: [
      "Pimecrolimus",
      "Elidel",
      "ASM 981",
      "SDZ ASM 981",
      "ASM-981",
      "ASM-998",
      "Elidel (TN)",
      "SDZ-ASM 981",
      "Pimecrolimus [USAN:INN:BAN]",
      "SDZ-ASM-981",
      "Pimecrolimus (JAN/USAN/INN)",
      "33-Epi-Chloro-33-Desoxyascomycin",
    ],
  },
  {
    primary_id: "D0Z4UY",
    names: ["Sodium Acetate Anhydrous"],
  },
  {
    primary_id: "D0Z4VA",
    names: ["UD-CG-212"],
  },
  {
    primary_id: "D0Z4VD",
    names: ["3-(2,4-Dimethyl-Phenyl)-1-Propyl-Piperidine", "CHEMBL144302", "BDBM50068425"],
  },
  {
    primary_id: "D0Z4VG",
    names: [
      "HG-1092",
      "CCL23 Modulator (Immune Disorders), Human Genome Sciences",
      "Chemokine Purification Modulator (Immune Disorders), Human Genome Sciences",
      "MIP3 Modulator (Immune Disorders), Human Genome Sciences",
      "MPIF Modulator (Immune Disorders), Human Genome Sciences",
      "MPIF1 Modulator (Immune Disorders), Human Genome Sciences",
      "Macrophageinflammatory Protein 3 Modulator (Immune Disorders), Human Genome Sciences",
      "Myeloid Progenitor Inhibitory Factor 1 Modulator (Immune Disorders), Human Genome Sciences",
      "SCYA23 Modulator (Immune Disorders), Human Genome Sciences",
      "C6 Beta-Chemokine Modulator (Immune Disorders), Human Genome Sciences",
      "Ckb-8 Modulator (Immune Disorders), Human Genome Sciences",
      "Hmrp-2a Modulator (Immune Disorders), Human Genome Sciences",
      "Human Chemokine Beta-8 Modulator (Immune Disorders), Human Genome Sciences",
      "CK-Beta-8 Modulator (Immune Disorders), Human Genome Sciences",
      "Chemokine (C-C Motif) Ligand 23 Modulator(Immune Disorders), Human Genome Sciences",
      "Small Inducible Cytokine Subfamily A (Cys-Cys), Member 23 Modulator (Immune Disorders), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0Z4VX",
    names: ["BI 695501", "BI-695501)"],
  },
  {
    primary_id: "D0Z4XI",
    names: ["Anti-Depressant Medicine"],
  },
  {
    primary_id: "D0Z4XW",
    names: ["Topetecan"],
  },
  {
    primary_id: "D0Z4YK",
    names: ["SMT-C2100"],
  },
  {
    primary_id: "D0Z4ZT",
    names: [
      "Drospirenone",
      "DRSP",
      "Dehydrospirorenone",
      "Dihydrospirorenone",
      "Drospirenona",
      "Drospirenonum",
      "Drospirenone [INN]",
      "ZK 30595",
      "ZK30595",
      "Angeliq, Drospirenone",
      "Drospirenona [INN-Spanish]",
      "Drospirenonum [INN-Latin]",
      "SH-470",
      "ZK-30595",
      "Drospirenone (JAN/USAN/INN)",
      "(6R,7R,8R,9S,10R,13S,14S,15S,16S,17S)-1,3',4',6,6a,7,8,9,10,11,12,13,14,15,15a,16-Hexadecahydro-10,13-Dimethylspiro-(17H-Dicyclopropa(6,7:15,16)Cyclopenta(A)Phenanthrene-17,2'(5'H)-Furan)-3,5'(2H)-Dione",
      "1,2-Dihydrospirorenone",
      "1,2-Dihydro-Spirorenone",
      "17-Hydroxy-6beta,7beta:15beta,16beta-Dimethylene-3-Oxo-17alpha-Pregn-4-Ene-21-Carboxylic Acid, Gamma-Lactone",
      "3-Oxo-6alpha,7alpha,15alpha,16alpha-Tetrahydro-7'H,16'H-Dicyclopropa[6,7;15,16]-17alpha-Pregn-4-Ene-21,17-Carbolactone",
      "6-Beta,7-Beta;15-Beta,16-Beta-Dimethylene-3-Oxo-17-Alpha-Pregn-4-Ene-21,17-Carbolactone",
      "6beta,7beta,15beta,16beta-Dimethylen-3-Oxo-17alpha-Pregn-4-En-21,17-Carbolacton",
      "6beta,7beta,15beta,16beta-Dimethylene-3-Oxo-17alpha-Pregn-4-Ene-21,17 Carbolactone",
      "6beta,7beta;15beta,16beta-Dimethylene-3-Oxo-17alpha-Pregn-4-Ene-21,17-Carbolactone",
      "Spiro(17H-Dicyclopropa(6,7:15,16)Cyclopenta(A)Phenanthrene-17,2'(5'H)-Furan)-3,5'(2H)-Dione, 1,3',4',6,7,8,9,10,11,12,13,14,15,16,20,21-Hexadecahydro-10,13-Dimethyl-, (6R-(6alpha,7alpha,8beta,9alpha,10beta,13beta,14alpha,15alpha,16alpha,17beta",
    ],
  },
  {
    primary_id: "D0Z5AC",
    names: ["Gonadimmune"],
  },
  {
    primary_id: "D0Z5AO",
    names: ["2,7-Bis[2-(Propylamino)Acetamido]anthraquinone", "SCHEMBL4297885", "CHEMBL494284"],
  },
  {
    primary_id: "D0Z5AV",
    names: [
      "E5501",
      "Avatrombopag Maleate",
      "UNII-GDW7M2P1IS",
      "E5501 MALEATE",
      "GDW7M2P1IS",
      "677007-74-8",
      "Avatrombopag Maleate [USAN]",
      "Doptelet (TN)",
      "AKR-501 Monomaleate",
      "SCHEMBL19610454",
      "CHEMBL2105758",
      "Avatrombopag Maleate (JAN/USAN)",
      "D10307",
      "4-Piperidinecarboxylic Acid, 1-(3-Chloro-5-(((4-(4-Chloro-2-Thienyl)-5-(4-Cyclohexyl-1- Piperazinyl)-2-Thiazolyl)Amino)Carbonyl)-2-Pyridinyl)-, (2Z)-2-Butenedioate (1:1)",
      "1-(3-Chloro-5-((4-(4-Chlorothiophen-2-Yl)-5-(4-Cyclohexylpiperazin-1-Yl)Thiazol-2- Yl)Carbamoyl)Pyridin-2-Yl)Piperidine-4-C",
    ],
  },
  {
    primary_id: "D0Z5BC",
    names: ["Undecylenic Acid"],
  },
  {
    primary_id: "D0Z5BD",
    names: ["CER-627"],
  },
  {
    primary_id: "D0Z5BQ",
    names: ["PMID17709461C4g"],
  },
  {
    primary_id: "D0Z5BU",
    names: ["(+/-)-2-(4'-Propoxyphenyl)Thiomorpholin-5-One", "CHEMBL596955", "BDBM50307368"],
  },
  {
    primary_id: "D0Z5CX",
    names: [
      "N-(3,3-Diphenyl)Propyl-2,2-Diphenylacetamide",
      "CHEMBL476529",
      "N-(3,3-Diphenylpropyl)-2,2-Diphenylacetamide",
      "AC1N5W5U",
      "Oprea1_456266",
      "ZINC3166084",
      "BDBM50263033",
      "AKOS003236993",
      "MCULE-9181870252",
      "ST45008037",
      "SR-01000512723",
    ],
  },
  {
    primary_id: "D0Z5DI",
    names: ["4-Carboxamido-Isoindolinone Derivative 4"],
  },
  {
    primary_id: "D0Z5DJ",
    names: ["Long-Acting Oxyntomodulin"],
  },
  {
    primary_id: "D0Z5DM",
    names: ["IMD-0354"],
  },
  {
    primary_id: "D0Z5EJ",
    names: ["PSMA-617"],
  },
  {
    primary_id: "D0Z5EM",
    names: [
      "Cephalexin",
      "Adcadina",
      "Ades[prex",
      "Alcephin",
      "Alexin",
      "Alsporin",
      "Ambal",
      "Aristosporin",
      "Azabort",
      "Bactopenor",
      "Beliam",
      "Biocef",
      "CEX",
      "Carnosporin",
      "Cefablan",
      "Cefacet",
      "Cefadal",
      "Cefadin",
      "Cefadina",
      "Cefalekey",
      "Cefaleksin",
      "Cefalessina",
      "Cefalexgobens",
      "Cefalexin",
      "Cefalexina",
      "Cefalexine",
      "Cefalexinum",
      "Cefalin",
      "Cefalival",
      "Cefaloto",
      "Cefaseptin",
      "Cefax",
      "Ceffanex",
      "Cefibacter",
      "Ceflax",
      "Ceforal",
      "Cefovit",
      "Celexin",
      "Cepastar",
      "Cepexin",
      "Cephacillin",
      "Cephalexine",
      "Cephalexinum",
      "Cephalobene",
      "Cephanasten",
      "Cephaxin",
      "Cephin",
      "Cepol",
      "Ceporex",
      "Ceporexin",
      "Ceporexine",
      "Check",
      "Cophalexin",
      "Domucef",
      "Doriman",
      "Durantel",
      "Efemida",
      "Erocetin",
      "Factagard",
      "Felexin",
      "Fexin",
      "Ibilex",
      "Ibrexin",
      "Inphalex",
      "Karilexina",
      "Kefalospes",
      "Keflet",
      "Keflex",
      "Kefolan",
      "Keforal",
      "Keftab",
      "Kekrinal",
      "Kidolex",
      "Lafarine",
      "Larixin",
      "Lenocef",
      "Lexibiotico",
      "Loisine",
      "Lonflex",
      "Lopilexin",
      "Losporal",
      "Madlexin",
      "Maksipor",
      "Mamalexin",
      "Mamlexin",
      "Medolexin",
      "Medoxine",
      "Neokef",
      "Neolexina",
      "Noveol",
      "Novolexin",
      "Nufex",
      "Oracef",
      "Oriphex",
      "Oroxin",
      "Ortisporina",
      "Ospexin",
      "Palitrex",
      "Pectril",
      "Prindex",
      "Pyassan",
      "Rilexine",
      "Roceph",
      "Rogevil",
      "Sanaxin",
      "Sartosona",
      "Sencephalin",
      "Sepexin",
      "Servicef",
      "Servispor",
      "Sialexin",
      "Sinthecillin",
      "Sintolexyn",
      "Sporicef",
      "Sporidex",
      "Syncl",
      "Syncle",
      "Synecl",
      "Tepaxin",
      "Theratrex",
      "Tokiolexin",
      "Uphalexin",
      "Viosporine",
      "Voxxim",
      "Winlex",
      "Zabytrex",
      "Zozarine",
      "Cefalessina [DCIT]",
      "Cefalexin Scand Pharm",
      "Cefalexin Sodium",
      "Cefalexin Generics",
      "Cefalexin Hydrate",
      "Cefalexin Monohydrate",
      "Cefalexina Northia",
      "Cefalexina Richet",
      "Cephalex Von Ct",
      "Cephalexin Hydrate",
      "Cephalexin Monohydrate",
      "Ceporex Forte",
      "Durantel DS",
      "Henina Oral",
      "Panixine Disperdose",
      "Roceph Distab",
      "Lilly 66873",
      "S 6437",
      "SQ 20248",
      "Cefa-Iskia",
      "Cefalexin (JP15)",
      "Cefalexin.H2O",
      "Cefalexina [INN-Spanish]",
      "Cefalexine [INN-French]",
      "Cefalexinum [INN-Latin]",
      "Cephalexin(USP)",
      "Cephalexin (Anhydrous)",
      "Cephalexin 1-Hydrate",
      "Cephalexin 1-Wasser",
      "Cephalexin [USAN:BAN]",
      "Cephalexin.H2O",
      "Ceporexin-E",
      "Cusisporina-Cefalox",
      "Ed A-Ceph",
      "KS-1134",
      "Keflex (TN)",
      "Keftab (TN)",
      "L-Keflex",
      "Panixine Disperdose (TN)",
      "Sporidex (TN)",
      "Cephalexin, (6R-(6alpha,7beta))-Isomer",
      "7-(D-2-Amino-2-Phenylacetamido)-3-Methyl-Delta (Sup 3)-Cephem-4-Carboxylic Acid",
      "7-(D-2-Amino-2-Phenylacetamido)-3-Methyl-Delta3-Cephem-4-Carboxylic Acid",
      "7-(D-Alpha-Aminophenylacetamido)Desacetoxycephalosporanic Acid",
    ],
  },
  {
    primary_id: "D0Z5FC",
    names: [
      "Allogeneic Cytotoxic T-Cell Therapy",
      "Allogeneic Cytotoxic T-Cell Therapy (EBV-Related Post-Transplantation Lymphoproliferative Disease)",
    ],
  },
  {
    primary_id: "D0Z5GE",
    names: ["4-(2-Phenylacetamido)-3-Bromobenzenesulfonamide"],
  },
  {
    primary_id: "D0Z5GR",
    names: ["1-(2-(2-Chlorophenoxy)Pyridin-3-Yl)Piperazine", "CHEMBL599593"],
  },
  {
    primary_id: "D0Z5HA",
    names: ["VLTS-934"],
  },
  {
    primary_id: "D0Z5HB",
    names: ["FH-510"],
  },
  {
    primary_id: "D0Z5HH",
    names: ["PMID25666693-Compound-144"],
  },
  {
    primary_id: "D0Z5HL",
    names: ["Thiazole Carboxamide Derivative 6"],
  },
  {
    primary_id: "D0Z5HR",
    names: ["VX-661"],
  },
  {
    primary_id: "D0Z5HX",
    names: ["PMID15300839C17"],
  },
  {
    primary_id: "D0Z5IP",
    names: ["Ixiaro"],
  },
  {
    primary_id: "D0Z5IU",
    names: [
      "Sulindac",
      "Aclin",
      "Aflodac",
      "Arthrobid",
      "Arthrocine",
      "Chibret",
      "Clinoril",
      "Copal",
      "Copals",
      "Kenalin",
      "Klinoril",
      "Mobilin",
      "Sulindaco",
      "Sulindacum",
      "Sulindal",
      "Alphapharm Brand Of Sulindac",
      "Apo Sulin",
      "Apotex Brand Of Sulindac",
      "Cahill May Roberts Brand Of Sulindac",
      "Chemia Brand Of Sulindac",
      "Copal Resin",
      "Copal Rosin Varnish",
      "Gum Copal",
      "KendrickBrand Of Sulindac",
      "Merck Brand Of Sulindac",
      "Novo Sundac",
      "Novopharm Brand Of Sulindac",
      "Nu Pharm Brand Of Sulindac",
      "Nu Sulindac",
      "Resin Copal",
      "Sulindac Sulfoxide",
      "MK 231",
      "MK231",
      "S 8139",
      "Apo-Sulin",
      "Clinoril (TN)",
      "MK-231",
      "Merck Sharp & Dohme Brand Of Sulindac",
      "Novo-Sundac",
      "Nu-Pharm Brand Of Sulindac",
      "Nu-Sulindac",
      "Sulindaco [INN-Spanish]",
      "Sulindacum [INN-Latin]",
      "Sulindac (JAN/USP/INN)",
      "Sulindac [USAN:BAN:INN:JAN]",
      "Z-5-Fluoro-2-Methyl-1-[p-(Methlsulfinyl)Benzylidene]indene-3-Acetic Acid",
      "Cis-5-Fluoro-2-Methyl-1-((P-Methylsulfinyl)Benzylidene)Indene-3-Acetic Acid",
      "{5-Fluoro-2-Methyl-1-[4-(Methylsulfinyl)Benzylidene]-1h-Inden-3-Yl}acetic Acid",
      "Cis-5-Fluoro-2-Methyl-1-((4-(Methylsulfinyl)Phenyl)Methylene)-1H-Indene-3-Acetic Acid",
      "[(1E)-5-Fluoro-2-Methyl-1-{[4-(Methylsulfinyl)Phenyl]methylidene}-1H-Inden-3-Yl]acetic Acid",
      "{(1E)-5-Fluoro-2-Methyl-1-[4-(Methylsulfinyl)Benzylidene]-1H-Inden-3-Yl}acetic Acid",
      "{(1Z)-5-Fluoro-2-Methyl-1-[4-(Methylsulfinyl)Benzylidene]-1H-Inden-3-Yl}acetic Acid",
      "(E)-(1)-5-Fluoro-2-Methyl-1-((4-(Methylsulphinyl)Phenyl)Methylene)-1H-Indene-3-Acetic Acid",
      "(Z)-5-Fluoro-2-Methyl-1-((P-(Methylsulfinyl)Phenyl)Methylene)-1H-Indene-3-Acetic Acid",
      "(Z)-5-Fluoro-2-Methyl-1-[[4-(Methyl-Sulfinyl)Phenyl]methylene]-1H-Indene-3-Acetic Acid",
      "(Z)-5-Fluoro-2-Methyl-1-[p-(Methylsulfinyl)Benzylidene]indene-3-Acetic Acid",
      "2-[(3E)-6-Fluoro-2-Methyl-3-[(4-Methylsulfinylphenyl)Methylidene]inden-1-Yl]acetic Acid",
      "2-[(3Z)-6-Fluoro-2-Methyl-3-[(4-Methylsulfinylphenyl)Methylidene]inden-1-Yl]acetic Acid",
      "2-[6-Fluoro-2-Methyl-3-[(4-Methylsulfinylphenyl)Methylidene]inden-1-Yl]acetic Acid",
      "5-Fluoro-2-Methyl-1-((4-(Methylsulphinyl)Phenyl)Methylene)-1H-Indene-3-Acetic Acid",
    ],
  },
  {
    primary_id: "D0Z5JC",
    names: ["HL-144"],
  },
  {
    primary_id: "D0Z5JD",
    names: [
      "[Leu5]enkephalin",
      "[Leu5]-Enkephalin",
      "58822-25-6",
      "Enkephalin",
      "Enkephalin L",
      "[Leu 5]enkephalin",
      "UNII-RI01R707R6",
      "[5-Leucine]Enkephalin",
      "Tyr-Gly-Gly-Phe-Leu-OH",
      "CHEMBL8234",
      "H-Tyr-Gly-Gly-Phe-Leu-OH",
      "CHEBI:89656",
      "RI01R707R6",
      "Enkephalins",
      "L-Leucine, N-(N-(N-(N-L-Tyrosylglycyl)Glycyl)-L-Phenylalanyl)-",
      "Leucyl-Enkephalin",
    ],
  },
  {
    primary_id: "D0Z5JT",
    names: ["VU0001171"],
  },
  {
    primary_id: "D0Z5KO",
    names: ["Urea Derivative"],
  },
  {
    primary_id: "D0Z5KQ",
    names: [
      "BENZOIN",
      "119-53-9",
      "2-Hydroxy-1,2-Diphenylethanone",
      "2-Hydroxy-2-Phenylacetophenone",
      "Benzoylphenylcarbinol",
      "Benzoin Tincture",
      "Ethanone, 2-Hydroxy-1,2-Diphenyl-",
      "Bitter Almond Oil Camphor",
      "Phenylbenzoyl Carbinol",
      "2-Hydroxy-1,2-Diphenylethan-1-One",
      "(+-)-Benzoin",
      "DL-BENZOIN",
      "Alpha-Hydroxybenzyl Phenyl Ketone",
      "579-44-2",
      "Alpha-Hydroxy-Alpha-Phenylacetophenone",
      "Benzoin Resin",
      "Phenyl-Alpha-Hydroxybenzyl Ketone",
      "NCI-C50011",
      "Acetophenone, 2-Hydroxy-2-Phenyl-",
      "Benzoin Gum",
      "Bitter-Almond-Oil Camphor",
      "Benzoin Siam",
      "Benzoin Extract (Resinoid)",
    ],
  },
  {
    primary_id: "D0Z5KX",
    names: ["7-Mercapto-Heptanoic Acid Benzothiazol-2-Ylamide", "CHEMBL178779"],
  },
  {
    primary_id: "D0Z5LB",
    names: ["AT1022"],
  },
  {
    primary_id: "D0Z5LI",
    names: ["MEDI9447"],
  },
  {
    primary_id: "D0Z5LN",
    names: ["ZRCCNCSSKWCRDHSRCC"],
  },
  {
    primary_id: "D0Z5LR",
    names: ["SiRNA Therapy, HCV Infection"],
  },
  {
    primary_id: "D0Z5LU",
    names: ["NSC-119913"],
  },
  {
    primary_id: "D0Z5LZ",
    names: ["Tilmacoxib"],
  },
  {
    primary_id: "D0Z5MJ",
    names: ["Ketoheterocycle Derivative 4"],
  },
  {
    primary_id: "D0Z5MR",
    names: ["KNI-10527"],
  },
  {
    primary_id: "D0Z5NN",
    names: ["ISIS 25544"],
  },
  {
    primary_id: "D0Z5NP",
    names: ["Phenylpyrrolidinone Derivative 4"],
  },
  {
    primary_id: "D0Z5OE",
    names: [
      "Mitotane",
      "Chloditan",
      "Chlodithan",
      "Chlodithane",
      "Khloditan",
      "Khlodithan",
      "Lysodren",
      "Mitotan",
      "Mitotano",
      "Mitotanum",
      "Mytotan",
      "Bristol Myers Squibb Brand Of Mitotane",
      "C 3010",
      "CB 313",
      "CB313",
      "PS694_SUPELCO",
      "Bristol-Myers Squibb Brand Of Mitotane",
      "CB-313",
      "Lysodren (TN)",
      "Mitotano [INN-Spanish]",
      "Mitotanum [INN-Latin]",
      "Ortho,para DDD",
      "Mitotane [USAN:INN:JAN]",
      "O,p-DDD",
      "O,p-Tde",
      "Ortho,para-DDD",
      "Mitotane (JAN/USP/INN)",
      "O,p'-DDD",
      "O,p'-Dichlorodiphenyldichloroethane",
      "O,p'-TDE",
      "Ethane, 1,1-Dichloro-2-(O-Chlorophenyl)-2-(P-Chlorophenyl)-(8CI)",
      "(+-)-1,1-Dichloro-2-(O-Chlorophenyl)-2-(P-Chlorophenyl)Ethane",
      "(2,4'-Dichlorodiphenyl)Dichloroethane",
      "(O,p)-DDD",
      "1,1-Dichloro-2,2-Bis(2,4'-Dichlorophenyl)Ethane",
      "1,1-Dichloro-2-(O-Chlorophenyl)-2-(P-Chlorophenyl)Ethane",
      "1,1-Dichloro-2-(P-Chlorophenyl)-2-(O-Chlorophenyl)Ethane",
      "1-(2-Chlorophenyl)-1-(4-Chlorophenyl)-2,2-Dichloroethane",
      "1-Chloro-2-(2,2-Dichloro-1-(4-Chlorophenyl)Ethyl)Benzene",
      "1-Chloro-2-[2,2-Dichloro-1-(4-Chlorophenyl)Ethyl]benzene",
      "2,2-Bis(2-Chlorophenyl-4-Chlorophenyl)-1,1-Dichloroethane",
      "2,4'-Ddd",
      "2,4'-Dichlorodiphenyldichloroethane",
      "2,4'-Dichlorophenyldichlorethane",
      "2-(2-Chlorophenyl)-2-(4-Chlorophenyl)-1,1-Dichloroethane",
      "2-(O-Chlorophenyl)-2-(P-Chlorophenyl)-1,1-Dichloroethane",
    ],
  },
  {
    primary_id: "D0Z5OJ",
    names: ["NMI-150"],
  },
  {
    primary_id: "D0Z5OV",
    names: [
      "Delorazepam",
      "Chlordemethyldiazepam",
      "2894-67-9",
      "B1, Benzodiazepine",
      "Cl-DMDZ",
      "Delorazepam [INN]",
      "Clordesmetildiazepam",
      "2-Chloronordiazepam",
      "2'-Chloronordiazepam",
      "EN [Anticonvulsant]",
      "Ro 5-3027",
      "Delorazepamum [INN-Latin]",
      "7-Chloro-5-(2-Chloro-Phenyl)-1,3-Dihydro-2H-1,4-Benzodiazepin-2-One",
      "NSC 169895",
      "Clordesmetildiazepam [Spanish]",
      "UNII-O91W32476G",
      "RV-12165",
      "2H-1,4-Benzodiazepin-2-One, 7-Chloro-5-(2-Chlorophenyl)-1,3-Dihydro-",
      "EINECS 220-771-9",
      "BRN 0754062",
      "CHEMBL268254",
      "DEA No. 2754",
      "CHIFCDOIPRCHCF-UHFFFAOYSA-N",
    ],
  },
  {
    primary_id: "D0Z5PA",
    names: ["Pyrazolo[1,5-A]pyrimidine Derivative 20"],
  },
  {
    primary_id: "D0Z5PF",
    names: ["Tetracyclines", "60-54-8", "Tetracycline Base"],
  },
  {
    primary_id: "D0Z5PM",
    names: ["PMID29473428-Compound-80"],
  },
  {
    primary_id: "D0Z5QA",
    names: [
      "12S-HETE",
      "12S-Hete",
      "12(S)-HETE",
      "54397-83-0",
      "12(S)-Hydroxyeicosatetraenoic Acid",
      "CHEBI:34146",
      "12-Hete",
      "(5Z,8Z,10E,12S,14Z)-12-Hydroxyicosa-5,8,10,14-Tetraenoic Acid",
      "12S-Hydroxy-5Z,8Z,10E,14Z-Eicosatetraenoic Acid",
      "12-Hydroxyeicosatetraenoic Acid",
      "12(S)-Hydroxy-(5Z,8Z,10E,14Z)-Eicosatetraenoic Acid",
      "(5Z,8Z,10E,14Z)-(12S)-12-Hydroxyicosa-5,8,10,14-Tetraenoic Acid",
      "(5Z,8Z,10E,14Z)-(12S)-12-Hydroxyeicosa-5,8,10,14-Tetraenoic Acid",
      "(S)-12-HETE",
      "12S-[3H]HETE",
      "12 Hete",
      "12-L-Hydroxy-5,8,10,14-Eicosatetraenoic Acid",
      "AC1NR1NB",
    ],
  },
  {
    primary_id: "D0Z5QI",
    names: ["Fentanyl/Droperidol"],
  },
  {
    primary_id: "D0Z5QM",
    names: ["Benzaldehyde O-4-Propoxyphenylcarbamoyl Oxime", "CHEMBL592635"],
  },
  {
    primary_id: "D0Z5RQ",
    names: [
      "CREMASTRINE",
      "Cremastrine",
      "CHEMBL480464",
      "AC1LCUUY",
      "BDBM50259847",
      "Pentanoic Acid, 2-Hydroxy-3-Methyl-, [(1S,7aS)-Hexahydro-1H-Pyrrolizin-1-Yl]methyl Ester, (2R,3R)-",
      "[(1S,8S)-2,3,5,6,7,8-Hexahydro-1H-Pyrrolizin-1-Yl]methyl (2R,3R)-2-Hydroxy-3-Methylpentanoate",
    ],
  },
  {
    primary_id: "D0Z5SB",
    names: ["8-(3-Azido-Phenyl)-6-Pyridin-4-Ylmethyl-Quinoline", "CHEMBL339083", "BDBM50092630"],
  },
  {
    primary_id: "D0Z5SC",
    names: ["Dengue Fever Vaccine"],
  },
  {
    primary_id: "D0Z5SL",
    names: ["ISIS 116505"],
  },
  {
    primary_id: "D0Z5SM",
    names: ["Sodium Tetradecyl Sulfate"],
  },
  {
    primary_id: "D0Z5SR",
    names: [
      "Fosfructose",
      "Cordox",
      "Fosfructose Trisodium",
      "CPC-111",
      "FDP, Questcor",
      "Fructose-1,6-Diphosphate",
      "Fructose-1,6-Diphosphate, Questcor",
    ],
  },
  {
    primary_id: "D0Z5TP",
    names: ["[D-Ncy(SO,methyl)4]acyline", "CHEMBL389945"],
  },
  {
    primary_id: "D0Z5TQ",
    names: ["GW-5074"],
  },
  {
    primary_id: "D0Z5TS",
    names: ["KI-0503"],
  },
  {
    primary_id: "D0Z5TV",
    names: [
      "(9Z,11E,13S)-13-Hydroxyoctadeca-9,11-Dienoic Acid",
      "13(S)-Hydroxyoctadeca-9Z,11E-Dienoic Acid",
      "29623-28-7",
      "13(S)-HODE",
      "(13S)-Hydroxyoctadecadienoic Acid",
      "13S-HODE",
      "13-Hydroxyoctadecadienoic Acid",
      "CHEMBL451721",
      "CHEBI:34154",
      "13S-Hydroxy-9Z,11E-Octadecadienoic Acid",
      "13(S)-Hydroxy-9(Z),11(E)-Octadecadienoic Acid",
      "(9Z, 11E)-(13S)-13-Hydroxyoctadeca-9,11-Dienoic Acid",
      "(9Z,11E)-(13S)-13-Hydroxyoctadeca-9,11-Dienoic Acid",
      "9,11-Octadecadienoic Acid, 13-Hydroxy-, (R-(E,Z))-",
      "5204-88-6",
      "10219-69-9",
      "(S)-Coriolic Acid",
      "AC1O5YAH",
    ],
  },
  {
    primary_id: "D0Z5TW",
    names: ["Parietaria Judaica-Containing Vaccine"],
  },
  {
    primary_id: "D0Z5UB",
    names: [
      "CX-2076",
      "AMPA Receptor Modulators (ADHD), Cortex",
      "AMPAKINE Compounds (Attention Deficit Hyperactivity Disorder), Cortex",
    ],
  },
  {
    primary_id: "D0Z5UD",
    names: ["MIP-1375"],
  },
  {
    primary_id: "D0Z5UG",
    names: ["H-Tyr-Tic-Cha-Phe-OH"],
  },
  {
    primary_id: "D0Z5UT",
    names: ["INOpulse"],
  },
  {
    primary_id: "D0Z5VD",
    names: ["Coumarin/Resveratrol Hybrid Derivative 2"],
  },
  {
    primary_id: "D0Z5VG",
    names: [
      "CZ-48",
      "Camptothecin-20-O-Propionate",
      "UNII-4S145C552U",
      "194414-69-2",
      "4S145C552U",
      "SF 6A",
      "CZ48",
      "Camptothecin-20(S)-O-Propionate",
      "CZ 48",
      "SCHEMBL1618775",
      "CHEMBL124162",
      "DTXSID30173074",
      "1H-Pyrano(3',4':6,7)Indolizino(1,2-B)Quinoline-3,14(4H,12H)-Dione, 4-Ethyl-4-(1-Oxopropoxy)-, (S)-",
    ],
  },
  {
    primary_id: "D0Z5VI",
    names: ["2-(2-Phosphonooxy-Ethyl)-Pentanedioic Acid", "CHEMBL58232", "SCHEMBL8060048"],
  },
  {
    primary_id: "D0Z5VL",
    names: [
      "PPMX-2017",
      "Anti-Human CDH3 Specific Mouse IgG2a Antibody, Perseus Proteomics",
      "Anti-Human Adherin 3/P-Cadherin Specific Mouse IgG2a Antibody, Perseus Proteomics",
    ],
  },
  {
    primary_id: "D0Z5VQ",
    names: ["Terutroban Sodium"],
  },
  {
    primary_id: "D0Z5VW",
    names: ["H3B-6545"],
  },
  {
    primary_id: "D0Z5WG",
    names: ["FAICAR"],
  },
  {
    primary_id: "D0Z5WP",
    names: ["FD-1"],
  },
  {
    primary_id: "D0Z5XD",
    names: [
      "2-Pyridin-4-Ylmethyl-1,2-Dihydro-Indazol-3-One",
      "CHEMBL3144718",
      "CHEMBL274331",
      "BDBM50008994",
      "ZINC107084657",
      "AKOS023543920",
    ],
  },
  {
    primary_id: "D0Z5XT",
    names: ["Imexon", "Amplimexon (TN)"],
  },
  {
    primary_id: "D0Z5YI",
    names: ["AVI-5038"],
  },
  {
    primary_id: "D0Z5YO",
    names: ["N-Benzyl-1H-Indole-2-Carboxamide"],
  },
  {
    primary_id: "D0Z6AE",
    names: ["3,4-Dihydroxymandelic Acid", "DOMA"],
  },
  {
    primary_id: "D0Z6AM",
    names: ["Ipafricept"],
  },
  {
    primary_id: "D0Z6AR",
    names: ["Descartes-08"],
  },
  {
    primary_id: "D0Z6AS",
    names: ["ISIS 16121"],
  },
  {
    primary_id: "D0Z6AX",
    names: ["BAY-27-9955"],
  },
  {
    primary_id: "D0Z6BF",
    names: ["Grastek"],
  },
  {
    primary_id: "D0Z6BO",
    names: ["10-OBn-Epi-Ginkgolide C", "10-OBn-Epi-GC"],
  },
  {
    primary_id: "D0Z6BT",
    names: ["4-[3-(Benzyloxy)Benzoyl]benzoic Acid", "CHEMBL382649"],
  },
  {
    primary_id: "D0Z6BY",
    names: ["JWH-243"],
  },
  {
    primary_id: "D0Z6BZ",
    names: ["Biphenyl-3-Ylcarbamic Acid Biphenyl-3-Yl Ester", "CHEMBL451443", "URB602, 3j"],
  },
  {
    primary_id: "D0Z6CZ",
    names: [
      "4,5-Dichloro-1H-Indole-2,3-Dione",
      "4,5-Dichloroisatin",
      "1677-47-0",
      "4,5-Dichloroindoline-2,3-Dione",
      "1H-Indole-2,3-Dione, 4,5-Dichloro-",
      "4,5-Dichloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "4,5-Dichlorisatin",
      "AC1NOKZI",
      "AC1Q3HQG",
      "Isatin-Based Compound, 49",
      "SCHEMBL734955",
      "CHEMBL385799",
      "CTK4D2746",
      "BDBM22829",
      "DTXSID90408697",
      "MolPort-001-828-451",
      "WWSWHYAXEFLETD-UHFFFAOYSA-N",
      "ZINC4530445",
      "8556AA",
      "4,5-Dichloro-1H-Indol-2,3-Dione",
      "ANW-58782",
      "AKOS015850110",
      "FCH1330653",
      "1H-Indole-2,3-Dione,4,5-Dichloro-",
      "QC-9708",
      "MCULE-6649049490",
      "KB-35588",
      "SY030680",
    ],
  },
  {
    primary_id: "D0Z6DB",
    names: ["L-Valinol"],
  },
  {
    primary_id: "D0Z6DE",
    names: ["RPSGL-Ig"],
  },
  {
    primary_id: "D0Z6DG",
    names: ["T13"],
  },
  {
    primary_id: "D0Z6DK",
    names: ["ISOELAEOCARPINE"],
  },
  {
    primary_id: "D0Z6DL",
    names: [
      "N5-[4-(N-Phenylamino)Phenyl]-L-Glutamine",
      "CHEMBL517211",
      "Modified Amino Acid Analog, 8g",
      "SCHEMBL20238887",
      "BDBM24272",
      "(2S)-2-Amino-4-{[4-(Phenylamino)Phenyl]carbamoyl}butanoic Acid",
    ],
  },
  {
    primary_id: "D0Z6EQ",
    names: ["ZP-009"],
  },
  {
    primary_id: "D0Z6GG",
    names: ["N-Benzyl-2-(Pyrrolidin-1-Yl)Pyrimidin-4-Amine", "CHEMBL1085780", "BDBM50319968"],
  },
  {
    primary_id: "D0Z6GW",
    names: ["TMC649128"],
  },
  {
    primary_id: "D0Z6HB",
    names: ["Targeted Vascular Disrupting Agent"],
  },
  {
    primary_id: "D0Z6HR",
    names: ["Cyclo(-L-Am7(S2Py)-L-2MePhe-L-Ala-D-Pro-)", "CHEMBL393464"],
  },
  {
    primary_id: "D0Z6IB",
    names: ["AVP-786"],
  },
  {
    primary_id: "D0Z6IS",
    names: ["Isoflavone Derivative 4"],
  },
  {
    primary_id: "D0Z6KF",
    names: ["TAS-114"],
  },
  {
    primary_id: "D0Z6KK",
    names: ["AZD-5847"],
  },
  {
    primary_id: "D0Z6LB",
    names: ["Pyrrolidinyl Urea Derivative 13"],
  },
  {
    primary_id: "D0Z6LI",
    names: ["Samarium SM-153 Lexidronam"],
  },
  {
    primary_id: "D0Z6LQ",
    names: ["Ar5Y_2"],
  },
  {
    primary_id: "D0Z6LR",
    names: ["2,5-Dichloro-N-Phenylthiophene-3-Sulfonamide", "CHEMBL1171971", "SCHEMBL7579927", "BDBM50321450"],
  },
  {
    primary_id: "D0Z6MI",
    names: ["Cyclo(-L-Am7(S2Py)-Aib-L-Phe-D-Pro-)", "CHEMBL238829", "BDBM50222732"],
  },
  {
    primary_id: "D0Z6MT",
    names: ["Famitinib"],
  },
  {
    primary_id: "D0Z6MW",
    names: ["ODS-656"],
  },
  {
    primary_id: "D0Z6NK",
    names: ["CHB-111"],
  },
  {
    primary_id: "D0Z6OI",
    names: ["DS-6930"],
  },
  {
    primary_id: "D0Z6OT",
    names: ["Mumbaistatin"],
  },
  {
    primary_id: "D0Z6OW",
    names: ["Staphylococcus Aureus Vaccine"],
  },
  {
    primary_id: "D0Z6PF",
    names: ["Alkyl Mannoside Derivative 2"],
  },
  {
    primary_id: "D0Z6QG",
    names: ["Pipotiazine"],
  },
  {
    primary_id: "D0Z6RI",
    names: ["Benzothiazepine Analog 4"],
  },
  {
    primary_id: "D0Z6RT",
    names: ["LAWSARITOL"],
  },
  {
    primary_id: "D0Z6RW",
    names: [
      "LMP-1/LMP-2 CTLs",
      "Ad5f35LMPd1-2",
      "LMP-1/LMP-2 CTLs, Baylor College Of Medicine/NCI",
      "LMP-1/LMP-2 Specific Cytotoxic T-Lymphocytes (Gene Therapy,nasopharyngeal Cancer/Lymphoma), Baylor College Of Medicine/NCI",
    ],
  },
  {
    primary_id: "D0Z6SP",
    names: ["Andecaliximab"],
  },
  {
    primary_id: "D0Z6SS",
    names: [
      "1-Adamantan-1-Yl-3-Phenyl-Urea",
      "CHEMBL516415",
      "AC1MJEST",
      "BAS 00102691",
      "AC1Q5LN2",
      "Oprea1_605405",
      "Oprea1_553434",
      "SCHEMBL1420838",
      "IFLab1_001789",
      "1-(1-Adamantyl)-3-Phenylurea",
      "1-Adamantan-1-Yl-3-Phenylurea",
      "N-(1-Adamantyl)-N'-Phenylurea",
      "1-(1-Adamantyl)-3-Phenyl-Urea",
      "BOKJLWGPHOTBQZ-UHFFFAOYSA-N",
      "MolPort-000-434-175",
      "HMS1417B07",
      "3-(Adamantan-1-Yl)-1-Phenylurea",
      "ZINC6645734",
      "N-Adamantanyl(Phenylamino)Carboxamide",
      "STL263876",
      "BDBM50267067",
      "AKOS003625202",
      "AKOS001215839",
      "MCULE-9094796377",
      "UPCMLD0ENAT5533559:001",
      "ST50049182",
      "SR-01000363562",
    ],
  },
  {
    primary_id: "D0Z6ST",
    names: ["Buflomedil"],
  },
  {
    primary_id: "D0Z6SY",
    names: ["5-Oxo-20-HETE", "5-Oxo-20-Hydroxy-6E, 8Z,11Z, 14Z-Eicosatetraenoic Acid"],
  },
  {
    primary_id: "D0Z6TG",
    names: ["ZD-6021", "255049-08-2"],
  },
  {
    primary_id: "D0Z6TM",
    names: [
      "4-Azidobenzenesulfonamide",
      "4-Azidobenzene-1-Sulfonamide",
      "CHEMBL222837",
      "36326-86-0",
      "Diazosulfanilamide",
      "Azide 1",
      "AC1MSFSV",
      "4-Azidophenylsulfonamide",
      "4-Azido-Benzenesulfonamide",
      "SCHEMBL15155897",
      "BDBM15222",
      "MolPort-023-499-930",
      "NSC80928",
      "ZINC12410597",
      "NSC-80928",
    ],
  },
  {
    primary_id: "D0Z6UC",
    names: ["Sumatriptan"],
  },
  {
    primary_id: "D0Z6UE",
    names: ["AVVYPWT", "CHEMBL238310"],
  },
  {
    primary_id: "D0Z6UK",
    names: ["SC-50998"],
  },
  {
    primary_id: "D0Z6VR",
    names: ["Buthionine Sulfoximine", "AC1MS6SH"],
  },
  {
    primary_id: "D0Z6VT",
    names: ["ML380"],
  },
  {
    primary_id: "D0Z6WI",
    names: [
      "EP-51216",
      "(2S)-6-Amino-2-[[(2S)-2-[[(2R)-2-[[(2R)-2-(4-Aminobutanoylamino)-3-(2-Methyl-1H-Indol-3-Yl)Propanoyl]amino]-3-(2-Methyl-1H-Indol-3-Yl)Propanoyl]amino]-3-(2-Methyl-1H-Indol-3-Yl)Propanoyl]amino]hexanamide",
    ],
  },
  {
    primary_id: "D0Z6WN",
    names: ["NDM-10107"],
  },
  {
    primary_id: "D0Z6WU",
    names: ["1,10-Bis(Pyridinium)-Decane Dibromide"],
  },
  {
    primary_id: "D0Z6WZ",
    names: ["Cyclo-[-Arg-Gly-Asp-Amp23-]", "CHEMBL410050"],
  },
  {
    primary_id: "D0Z6XQ",
    names: ["Recombinant Human Zinc Alpha-2 Glycoprotein (ZAG)"],
  },
  {
    primary_id: "D0Z6YA",
    names: ["1-(2-(2-Ethoxyphenyl)-1-Phenylethyl)Piperazine", "CHEMBL386224", "SCHEMBL5450115"],
  },
  {
    primary_id: "D0Z6ZA",
    names: ["ISIS 101960"],
  },
  {
    primary_id: "D0Z6ZF",
    names: ["PEGylated Recombinant IFN Beta 1b"],
  },
  {
    primary_id: "D0Z6ZH",
    names: ["PMID25399762-Compound-Table1-C1"],
  },
  {
    primary_id: "D0Z7AB",
    names: [
      "Olodaterol/Tiotropium Bromide",
      "136310-93-5",
      "UNII-XX112XZP0J",
      "XX112XZP0J",
      "(1R,2R,4S,5S,7s)-7-(2-Hydroxy-2,2-Di(Thiophen-2-Yl)Acetoxy)-9,9-Dimethyl-3-Oxa-9-Azatricyclo[3.3.1.02,4]nonan-9-Ium Bromide",
      "AK-72842",
      "(1R,2R,4S,5S,7S)-7-(2-Hydroxy-2,2-Di(Thiophen-2-Yl)Acetoxy)-9,9-Dimethyl-3-Oxa-9-Azatricyclo[3.3.1.0",
    ],
  },
  {
    primary_id: "D0Z7AF",
    names: ["L-162782"],
  },
  {
    primary_id: "D0Z7AM",
    names: [
      "Ether Lipid",
      "Edelfosine",
      "AP-121",
      "Ether Lipid (Liposomal)",
      "ET-18-OCH3",
      "Ether Lipid (Liposomal), TLC",
      "TLC-ELL-12",
    ],
  },
  {
    primary_id: "D0Z7AN",
    names: ["Ro-19-8022"],
  },
  {
    primary_id: "D0Z7AW",
    names: [
      "CP-293019",
      "CHEMBL66227",
      "GTPL977",
      "SCHEMBL6844226",
      "BDBM50069041",
      "CP293019",
      "CP 293019",
      "7-(4-Fluoro-Phenoxymethyl)-2-(5-Fluoro-Pyrimidin-2-Yl)-Octahydro-Pyrido[1,2-A]pyrazine (CP-293019)",
      "(7R,9aalpha)-2-(5-Fluoro-2-Pyrimidinyl)-7alpha-(4-Fluorophenoxymethyl)Octahydro-6H-Pyrido[1,2-A]pyrazine",
      "(7R,9aS)-7-[(4-Fluorophenoxy)Methyl]-2-(5-Fluoropyrimidin-2-Yl)-1,3,4,6,7,8,9,9a-Octahydropyrido[1,2-A]pyrazine",
    ],
  },
  {
    primary_id: "D0Z7BC",
    names: ["VCP-28"],
  },
  {
    primary_id: "D0Z7BE",
    names: [
      "H-100",
      "Antibacterial Agent (Antibiotic Resistant Bacteria), Humanetics",
      "Antibacterial Agent (Bacterial Infection), Humanetics",
    ],
  },
  {
    primary_id: "D0Z7BK",
    names: [
      "CLEMATOMANDSHURICA SAPONIN B",
      "CHEMBL525269",
      "BDBM50241848",
      "6)-[(2-O-Isoferuloyl)-Beta-D-Glucopyranosyl]-(1-&gt",
      "3)-Alpha-L-Rhamnopyranosyl-(1-&gt",
      "2)-Alpha-L-Arabinopyranosyloleanolic Acid-28-O-Alpha-L-Rhamnopyranosyl-(1-&gt",
    ],
  },
  {
    primary_id: "D0Z7BO",
    names: ["ISIS 19634"],
  },
  {
    primary_id: "D0Z7CZ",
    names: ["L-CCG-I", "3,4-Cyclopropylglutamate", "CCPG", "L-2-(Carboxypropyl)Glycine"],
  },
  {
    primary_id: "D0Z7DA",
    names: ["PMID25656651-Compound-18"],
  },
  {
    primary_id: "D0Z7EA",
    names: ["[3H]rauwolscine"],
  },
  {
    primary_id: "D0Z7EF",
    names: ["CL82198", "CL-82198", "CL 82198"],
  },
  {
    primary_id: "D0Z7EL",
    names: ["GOSSYPETIN"],
  },
  {
    primary_id: "D0Z7EV",
    names: ["Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0Z7FF",
    names: ["JWH-246"],
  },
  {
    primary_id: "D0Z7FK",
    names: ["XMT-1001"],
  },
  {
    primary_id: "D0Z7FL",
    names: ["Piperazine Carbamic Compound 5"],
  },
  {
    primary_id: "D0Z7FM",
    names: [
      "5-Chloro-2-(2-Chloro-4-Nitrophenoxy)Phenol",
      "5-CHLORO-2-(2-CHLORO-4-NITROPHENOXY)PHENOL",
      "CHEMBL370595",
      "TN2",
      "AC1OA9SQ",
      "Phenol, 5-Chloro-2-(2-Chloro-4-Nitrophenoxy)-",
      "BDBM50174770",
      "36859-73-1",
      "5-Chloro-(2-Chloro-4-Nitrophenoxy)Phenol",
    ],
  },
  {
    primary_id: "D0Z7FU",
    names: ["INS 316"],
  },
  {
    primary_id: "D0Z7FY",
    names: ["CEM-301"],
  },
  {
    primary_id: "D0Z7FZ",
    names: ["VN-180"],
  },
  {
    primary_id: "D0Z7GH",
    names: ["PD-0173958"],
  },
  {
    primary_id: "D0Z7GJ",
    names: ["EUK-418"],
  },
  {
    primary_id: "D0Z7GN",
    names: ["PMID28394193-Compound-34"],
  },
  {
    primary_id: "D0Z7GS",
    names: [
      "6-Fluoromelatonin",
      "62106-00-7",
      "CHEMBL333886",
      "N-(2-(6-Fluoro-5-Methoxy-1H-Indol-3-Yl)Ethyl)Acetamide",
      "N-[2-(6-Fluoro-5-Methoxy-1H-Indol-3-Yl)Ethyl]acetamide",
      "ACETAMIDE, N-(2-(6-FLUORO-5-METHOXY-1H-INDOL-3-YL)ETHYL)-",
      "AC1L2ASC",
      "SCHEMBL1772618",
      "SCHEMBL1772619",
      "DTXSID10211165",
      "BTTITVUHHXXLFM-UHFFFAOYSA-N",
      "ZINC2032560",
      "BDBM50330858",
      "LS-9627",
      "N-[2-(5-Methoxy-6-Fluoroindol-3-Yl)Ethyl]acetamide",
    ],
  },
  {
    primary_id: "D0Z7GX",
    names: ["BBT-032"],
  },
  {
    primary_id: "D0Z7HE",
    names: ["PMID27019002-Compound-46"],
  },
  {
    primary_id: "D0Z7IJ",
    names: ["Triethyl 2-Aminopropane-1,2,3-Tricarboxylate", "CHEMBL509901"],
  },
  {
    primary_id: "D0Z7IW",
    names: ["JI-101"],
  },
  {
    primary_id: "D0Z7KB",
    names: ["HPMA-Docetaxel Conjugate"],
  },
  {
    primary_id: "D0Z7KE",
    names: [
      "Isradipine",
      "Clivoten",
      "DynaCirc",
      "DynaCire",
      "Dynacrine",
      "Esradin",
      "Icaz",
      "Isradipin",
      "Isradipino",
      "Isradipinum",
      "Isrodipine",
      "Lomir",
      "Prescal",
      "Rebriden",
      "DynaCire CR",
      "Dynacirc CR",
      "Isradipino [Spanish]",
      "Isradipinum [Latin]",
      "PN 205 033",
      "PN 205 034",
      "PN 205033",
      "PN 205034",
      "PN205033",
      "PN205034",
      "DynaCirc (TN)",
      "PN 200-110",
      "PN 205-033",
      "PN 205-034",
      "PN-200110",
      "Prescal (TN)",
      "Isradipine (USP/INN)",
      "Isradipine [USAN:INN:BAN]",
      "PN-200-110",
      "PN-205-033",
      "PN-205-034",
      "Isradipine, (R)-Isomer",
      "Isradipine, (S)-Isomer",
      "Isradipine, (+-)-Isomer",
      "DynaCirc, Prescal, PN-200-110, Clivoten, Esradin, Isradipine",
      "Isopropyl Methyl (+-)-4-(4-Benzofurazanyl)-1,4-Dihydro-2,6-Dimethyl-3,5-Pyridinedicarboxylate",
      "Isopropyl 4-(2,1,3-Benzoxadiazol-4-Yl)-1,4-Dihydro-5-Methoxycarbonyl-2,6-Dimethyl-3-Pyridinecarboxylate",
      "Methyl 1-Methylethyl 4-(2,1,3-Benzoxadiazol-4-Yl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "O5-Methyl O3-Propan-2-Yl 4-(2,1,3-Benzoxadiazol-7-Yl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "(+-)-Isradipine",
      "(+/-)-Isradipine",
      "2,1,3-Benzoxadiazole, 3,5-Pyridinedicarboxylic Acid Deriv.",
      "3,5-Pyridinedicarboxylic Acid, 4-(2,1,3-Benzoxadiazol-4-Yl)-1,4-Dihydro-2,6-Dimethyl-, 3-Methyl 5-(1-Methylethyl) Ester",
      "3,5-Pyridinedicarboxylic Acid, 4-(2,1,3-Benzoxadiazol-4-Yl)-1,4-Dihydro-2,6-Dimethyl-, Methyl 1-Methylethyl Ester",
      "3,5-Pyridinedicarboxylic Acid, 4-(2,1,3-Benzoxadiazol-4-Yl)-1,4-Dihydro-2,6-Dimethyl-, Methyl 1-Methylethyl Ester (9CI)",
      "3,5-Pyridinedicarboxylic Acid, 4-(4-Benzofurazanyl)-1,4-Dihydro-2,6-Dimethyl-, Methyl 1-Methylethyl Ester",
      "3-O-Methyl 5-O-Propan-2-Yl 4-(2,1,3-Benzoxadiazol-4-Yl)-2,6-Dimethyl-1,4-Dihydropyridine-3,5-Dicarboxylate",
      "4-(2,1,3-Benzooxadiazol-4-Yl)-2.6-Dimethyl-1,4-Dihydro-3-Isopropyloxycarbonylpyridine-5-Carboxylic Acid Methyl Ester",
      "4-(4-Benzofurazanyl)-1,-4-Dihydro-2,6-Dimethyl-3,5-Pyridinedicarboxylic Acid Methyl 1-Methhylethyl Ester",
      "4-(4-Benzofurazanyl)-1,-4-Dihydro-2,6-Dimethyl-3,5-Pyridinedicarboxylic Acid Methyl 1-Methylethyl Ester",
      "4-(4-Benzofurazanyl)-1,4-Dihydro-2,-6-Dimethyl-3,5-Pyridinedicarboxylic Acid Methyl 1-Methylethyl Ester",
    ],
  },
  {
    primary_id: "D0Z7KW",
    names: ["RS-30199"],
  },
  {
    primary_id: "D0Z7LK",
    names: ["GSC-1", "Alpha-Glucosidase Inhibitor (Hyperglycemia), Dharma Biomedical"],
  },
  {
    primary_id: "D0Z7LS",
    names: ["3,3-Diethyl-1-O-Tolylazetidine-2,4-Dione", "CHEMBL566918"],
  },
  {
    primary_id: "D0Z7MJ",
    names: ["Prednisolone Sodium Metazoate"],
  },
  {
    primary_id: "D0Z7NC",
    names: [
      "2-Phenethyl-4,5-Dihydro-1H-Imidazole",
      "Beta-Phenylethylimidazoline",
      "2-Phenethyl-2-Imidazoline",
      "2-IMIDAZOLINE, 2-PHENETHYL-",
      "BRN 0129593",
      "1H-Imidazole, 4,5-Dihydro-2-(2-Phenylethyl)-",
      "26038-62-0",
      "CHEMBL14210",
      "2-(Beta-Phenylaethyl)Imidazolin [German]",
      "Phenyzoline",
      "2-(Beta-Phenylaethyl)Imidazolin",
      "2-Phenethyl-1-Imidazoline",
      "AC1L1PA8",
      "5-23-07-00011 (Beilstein Handbook Reference)",
      "SCHEMBL3172677",
      "DTXSID30180682",
      "BDBM50138499",
      "AKOS022535828",
      "LS-79674",
    ],
  },
  {
    primary_id: "D0Z7NZ",
    names: ["AMG 570"],
  },
  {
    primary_id: "D0Z7OB",
    names: ["(E)-N-(3,5-Dimethoxyphenethyl)Undec-2-Enamide", "CHEMBL373056"],
  },
  {
    primary_id: "D0Z7OX",
    names: ["LY-465608"],
  },
  {
    primary_id: "D0Z7PI",
    names: ["GR-233548"],
  },
  {
    primary_id: "D0Z7QI",
    names: ["PMID29671355-Compound-28"],
  },
  {
    primary_id: "D0Z7QK",
    names: ["UCART123"],
  },
  {
    primary_id: "D0Z7QU",
    names: ["GWTLNSAGYLLGPRHYINLITRQRY-CONH2"],
  },
  {
    primary_id: "D0Z7QZ",
    names: ["N-(Thiazol-2-Yl)Benzamide"],
  },
  {
    primary_id: "D0Z7RA",
    names: ["GLY-220"],
  },
  {
    primary_id: "D0Z7RB",
    names: ["PMID23634668C14"],
  },
  {
    primary_id: "D0Z7RR",
    names: [
      "ARRY-770",
      "ARRY-Pim-100",
      "ARRY-Pim-300",
      "ARRY-Pim-I",
      "Pim Kinase Inhibitors (Cancer/Inflammatory Disease)",
      "Pim Kinase Inhibitors (Cancer/Inflammatory Disease), Array BioPharma",
    ],
  },
  {
    primary_id: "D0Z7SK",
    names: ["Urea And Carbamate Bioisostere Derivative 14"],
  },
  {
    primary_id: "D0Z7SL",
    names: ["Indole Naphthyridinone"],
  },
  {
    primary_id: "D0Z7TM",
    names: [
      "BMS-645737",
      "651744-16-0",
      "BMS645737",
      "5-Isopropyl-6-(5-Methyl-1,3,4-Oxadiazol-2-Yl)-N-(2-Methyl-1H-Pyrrolo[2,3-B]pyridin-5-Yl)Pyrrolo[2,1-F][1,2,4]triazin-4-Amine",
      "UNII-86H6HFH34L",
      "86H6HFH34L",
      "CHEMBL261592",
      "6-(5-Methyl-1,3,4-Oxadiazol-2-Yl)-N-{2-Methyl-1h-Pyrrolo[2,3-B]pyridin-5-Yl}-5-(Propan-2-Yl)Pyrrolo[2,1-F][1,2,4]triazin-4-Amine",
      "5-Isopropyl-6-(5-Methyl-1,3,4-Oxadiazol-2-Yl)-N-(2-Methyl-1H-Pyrrolo-[2,3-B]pyridin-5-Yl)Pyrrolo[2,1-F][1,2,4]triazin-4-Amine",
      "C20H20N8O",
    ],
  },
  {
    primary_id: "D0Z7TR",
    names: ["ACP-01"],
  },
  {
    primary_id: "D0Z7UN",
    names: ["H5N1 Influenza Vaccine"],
  },
  {
    primary_id: "D0Z7VF",
    names: ["PMID25470667-Compound-PF-04620110"],
  },
  {
    primary_id: "D0Z7WK",
    names: [
      "1,1,1-Trifluoro-3-(Hexylthio)Propan-2-One",
      "CHEMBL440542",
      "133311-65-6",
      "1,1,1-Trifluoro-3-Hexylsulfanylpropan-2-One",
      "2-Propanone, 1,1,1-Trifluoro-3-(Hexylthio)-",
      "ACMC-20muvv",
      "CTK0C0416",
      "DTXSID00434189",
    ],
  },
  {
    primary_id: "D0Z7XI",
    names: [
      "3-Methyl-1,2,3,4-Tetrahydro-Isoquinoline",
      "3-Methyl-1,2,3,4-Tetrahydroisoquinoline",
      "29726-60-1",
      "1,2,3,4-Tetrahydro-3-Methylisoquinoline",
      "Isoquinoline, 1,2,3,4-Tetrahydro-3-Methyl-",
      "CHEMBL60434",
      "UEKQPSAKUNXFHL-UHFFFAOYSA-N",
      "BAS 03334621",
      "AC1Q1HKS",
      "AC1LC3M2",
      "SCHEMBL1011182",
      "SCHEMBL15334650",
      "KS-00000JVD",
      "MolPort-001-990-837",
      "HMS1698C16",
      "BDBM50023304",
      "ANW-75096",
      "AKOS016843414",
      "AKOS000650337",
      "AB30586",
      "NE31770",
      "MCULE-3517571472",
      "NCGC00184271-01",
      "DA-07231",
      "KB-70931",
      "BS-13528",
      "TC-163841",
      "ST24042290",
      "FT-0729461",
      "EN300-86328",
    ],
  },
  {
    primary_id: "D0Z7YI",
    names: ["2-Propionyl-1H-Indole-6-Carboxamidine", "CHEMBL321960"],
  },
  {
    primary_id: "D0Z7YK",
    names: ["RU28362"],
  },
  {
    primary_id: "D0Z7YS",
    names: ["N-Hydroxy-4-(Pentanoylamino-Methyl)-Benzamide", "CHEMBL143102"],
  },
  {
    primary_id: "D0Z7ZM",
    names: ["Rhucin"],
  },
  {
    primary_id: "D0Z7ZZ",
    names: ["4-Trimethylammoniobutanoate"],
  },
  {
    primary_id: "D0Z8AA",
    names: ["Propiolactone", "Betaprone"],
  },
  {
    primary_id: "D0Z8AE",
    names: ["Chromic Chloride", "Chromic Chloride In Plastic Container"],
  },
  {
    primary_id: "D0Z8BD",
    names: ["Flavonoid Derivative 2"],
  },
  {
    primary_id: "D0Z8BI",
    names: ["IMGN-007"],
  },
  {
    primary_id: "D0Z8CP",
    names: [
      "Dithiipin-1,1,4,4-Tetroxide Analogue 7",
      "GTPL6124",
      "CHEMBL3906195",
      "2-(4-Methylphenyl)-6,7-Dihydro-5H-1,4-Dithiepine 1,1,4,4-Tetraoxide",
      "2-(4-Methylphenyl)-6,7-Dihydro-5H-1,4-Dithiepin 1,1,4,4-Tetraoxide",
    ],
  },
  {
    primary_id: "D0Z8CQ",
    names: ["Sarilumab"],
  },
  {
    primary_id: "D0Z8DJ",
    names: ["Bip-Tyr-Thr-Pro-Ala-Thr(Obzl)-Gly", "CHEMBL219358"],
  },
  {
    primary_id: "D0Z8DM",
    names: ["ISIS 109115"],
  },
  {
    primary_id: "D0Z8DO",
    names: ["SPD-556"],
  },
  {
    primary_id: "D0Z8EA",
    names: ["FMX104"],
  },
  {
    primary_id: "D0Z8EI",
    names: [
      "6-Allyloxy-9H-Purin-2-Ylamine",
      "CHEMBL325053",
      "50663-54-2",
      "O6-Allylguanine",
      "O6-Substituted Guanine Deriv. 15",
      "6-Prop-2-Enoxy-7H-Purin-2-Amine",
      "NU 2028",
      "AC1L45IB",
      "SCHEMBL1263549",
      "BDBM5475",
      "6-(Prop-2-En-1-Yloxy)-9H-Purin-2-Amine",
      "9H-Purin-2-Amine, 6-(2-Propen-1-Yloxy)-",
    ],
  },
  {
    primary_id: "D0Z8EJ",
    names: [
      "KOS-1815",
      "CRM1 And Nuclear Export Inhibitors (Cancer)",
      "KOS-2385",
      "KOS-2464",
      "CRM1 And Nuclear Export Inhibitors (Cancer), Kosan",
    ],
  },
  {
    primary_id: "D0Z8EU",
    names: ["NE58018"],
  },
  {
    primary_id: "D0Z8EX",
    names: [
      "Stavudine",
      "DdeThd",
      "DdeTyd",
      "Dideoxydidehydrothymidine",
      "Estavudina",
      "STV",
      "Sanilvudine",
      "Stavudinum",
      "Zent",
      "Zerit",
      "Zerit Xr",
      "Zerut XR",
      "BMY 27857",
      "BMY27857",
      "D 1413",
      "D 4T",
      "BMY-27857",
      "Bristol-Myers Brand Of Stavudine",
      "Bristol-Myers Squibb Brand Of Stavudine",
      "D 4T (Nucleoside)",
      "Estavudina [INN-Spanish]",
      "Sanilvudine (JAN)",
      "Stavudine, Monosodium Salt",
      "Stavudinum [INN-Latin]",
      "Zerit (TN)",
      "Zerit(TM)",
      "D4T & GM-CSF",
      "D4TMBY-27857-3",
      "Stavudine (USAN/INN)",
      "Stavudine [USAN:BAN:INN]",
      "Stavudine [USAN:INN:BAN]",
      "Thymine, 1-(2,3-Dideoxy-Beta-D-Glycero-Pent-2-Enofuranosyl)-(7CI,8CI)",
      "1-(2,3-Dideoxy-Beta-D-Glycero-2-Pentenofuranosyl)Thymine",
      "1-(2,3-Dideoxy-Beta-D-Glycero-Pent-2-Enofuranosyl)Thymine",
      "1-[(2R,5S)-5-(Hydroxymethyl)-2,5-Dihydrofuran-2-Yl]-5-Methylpyrimidine-2,4(1H,3H)-Dione",
      "1-[(2R,5S)-5-(Hydroxymethyl)-2,5-Dihydrofuran-2-Yl]-5-Methylpyrimidine-2,4-Dione",
      "1-[5-(Hydroxymethyl)-2,5-Dihydro-2-Furanyl]-5-Methyl-2,4(1H,3H)-Pyrimidinedione & Colony-Stimulating Factor 2",
      "2',3' Didehydro 3' Deoxythymidine",
      "2',3'-Anhydrothymidine",
      "2',3'-DIDEHYDRO-3'-DEOXYTHYMIDINE (DDI)",
      "2',3'-Didehydro-2',3'-Dideoxythmidine",
      "2',3'-Didehydro-3'-Deoxythimidine",
      "2',3'-Didehydro-3'-Deoxythymidine",
      "3'-Azido-3'-Deoxythymidine & Granulocyte-Macrophage Colony-Stimulating Factor",
      "3'-Deoxy-2',3'-Didehydrothymidine",
      "3'-Deoxy-2'-Thymidinene",
      "D4T",
    ],
  },
  {
    primary_id: "D0Z8GD",
    names: ["CD19 CAR T Cells"],
  },
  {
    primary_id: "D0Z8GS",
    names: ["G-25"],
  },
  {
    primary_id: "D0Z8HG",
    names: ["Vecuronium"],
  },
  {
    primary_id: "D0Z8HX",
    names: [
      "6,7-Dichloro-1H-Indole-2,3-Dione",
      "18711-12-1",
      "6,7-Dichloroindoline-2,3-Dione",
      "1H-Indole-2,3-Dione,6,7-Dichloro-",
      "6,7-Dichloro-2,3-Dihydro-1H-Indole-2,3-Dione",
      "6,7-Dichlorisatin",
      "6,7-Dichloroisatin",
      "AC1Q3HQA",
      "Isatin-Based Compound, 54",
      "SCHEMBL1248801",
      "CHEMBL222292",
      "CTK4D9416",
      "BDBM22834",
      "DTXSID30588487",
      "MolPort-004-304-808",
      "XTXIILHWOQZVAQ-UHFFFAOYSA-N",
      "STL381782",
      "ZINC14979616",
      "AKOS000139553",
      "MCULE-1304306967",
      "1H-Indole-2,3-Dione, 6,7-Dichloro-",
      "AJ-65537",
      "KB-335070",
      "FT-0620847",
      "EN300-36024",
      "Y-0718",
    ],
  },
  {
    primary_id: "D0Z8IJ",
    names: ["Muscimol"],
  },
  {
    primary_id: "D0Z8IR",
    names: ["Tetra-Cyclic Pyridophthalazinone Derivative 1"],
  },
  {
    primary_id: "D0Z8JR",
    names: ["BMS-936564"],
  },
  {
    primary_id: "D0Z8JX",
    names: ["C[L-Phe-D-Pro-L-MTyr-D-Trp]", "CHEMBL509201"],
  },
  {
    primary_id: "D0Z8KS",
    names: ["Zanolimumab"],
  },
  {
    primary_id: "D0Z8KU",
    names: ["SGN-2FF"],
  },
  {
    primary_id: "D0Z8LA",
    names: ["Cyclocreatine", "Cyclocreatinine", "AM-285"],
  },
  {
    primary_id: "D0Z8LE",
    names: [
      "17-Dehydroxyriccardin C",
      "Riccardin C Derivative, 20b",
      "CHEMBL409652",
      "BDBM23849",
      "2',4-[Ethylene-1,4-Phenylene-Oxy-(6-Hydroxy-1,3-Phenylene)-Ethylene]-1,1'-Biphenyl-2-Ol",
    ],
  },
  {
    primary_id: "D0Z8LN",
    names: ["Ebola Virus Vaccine"],
  },
  {
    primary_id: "D0Z8MC",
    names: ["Botulinum Toxin Type A Gel"],
  },
  {
    primary_id: "D0Z8MX",
    names: [
      "5-Benzyl-1,3,4-Oxadiazole-2(3H)-Thione",
      "5-Benzyl-1,3,4-Oxadiazole-2-Thiol",
      "23288-90-6",
      "5-Benzyl-[1,3,4]oxadiazole-2-Thiol",
      "CHEMBL1164364",
      "5-Benzyl-1,3,4-Oxadiazol-2-Yl Hydrosulfide",
      "1,3,4-Oxadiazole-2(3H)-Thione,5-(Phenylmethyl)-",
      "2-Benzyl-1,3,4-Oxadiazole-5-Thiol",
      "AC1Q7GGP",
      "ChemDiv2_001277",
      "AC1M00UR",
      "SCHEMBL7023603",
      "SCHEMBL11835354",
      "CTK4F1247",
      "DTXSID60365768",
      "MolPort-000-473-148",
      "HMS1649K04",
      "HMS1372M01",
      "ZINC2379869",
      "BDBM50320724",
      "STK498480",
      "CCG-21122",
      "BBL007772",
      "AKOS000100218",
      "AKOS001054851",
      "MCULE-7500395690",
    ],
  },
  {
    primary_id: "D0Z8NC",
    names: ["LUF-5981"],
  },
  {
    primary_id: "D0Z8OD",
    names: ["RWJ-38063"],
  },
  {
    primary_id: "D0Z8PO",
    names: ["KPE-06001", "Asthma/Arthritis Therapy, Kemin"],
  },
  {
    primary_id: "D0Z8PT",
    names: ["CCT244747", "Compound 26 [PMID 23082860]"],
  },
  {
    primary_id: "D0Z8PX",
    names: ["LARGAZOLE"],
  },
  {
    primary_id: "D0Z8RH",
    names: [
      "2-Benzyl-3-Phenyl-7-(Trifluoromethyl)-2H-Indazole",
      "SCHEMBL1407219",
      "CHEMBL464189",
      "BDBM26064",
      "LGRUCHCYEMOENE-UHFFFAOYSA-N",
      "2-Benzyl-3-Aryl-7-Trifluoromethylindazole, 5",
    ],
  },
  {
    primary_id: "D0Z8RL",
    names: ["1-Adamantan-1-Yl-3-(6-Hydroxyhexyl)Urea", "CHEMBL245143"],
  },
  {
    primary_id: "D0Z8SF",
    names: ["Sarkomycin"],
  },
  {
    primary_id: "D0Z8TR",
    names: ["IM-094261"],
  },
  {
    primary_id: "D0Z8UJ",
    names: ["TDI-0111"],
  },
  {
    primary_id: "D0Z8UQ",
    names: ["DM-83"],
  },
  {
    primary_id: "D0Z8VP",
    names: [
      "Beta-Hydroxy Aspartic Acid",
      "71653-06-0",
      "2-Amino-3-Hydroxysuccinic Acid",
      "Malic Acid, 3-Amino-",
      "2-Amino-3-Hydroxybutanedioic Acid",
      "D,l-Threo-3-HYDROXYASPARTIC ACID",
      "NSC618260",
      "1860-87-3",
      "CHEBI:83981",
      "2-Amino-3-Hydroxy-Butanedioic Acid",
      "3-Hydroxy-DL-Aspartic Acid",
      "Aspartic Acid, 3-Hydroxy-",
      "D,L-Threo-B-Hydroxyaspartic Acid",
      "DL-Threo-3-Hydroxyaspartic Acid",
      "3-Aminomalic Acid",
      "EINECS 275-771-1",
    ],
  },
  {
    primary_id: "D0Z8VU",
    names: ["8-Bromo-9-(Sec-Butyl)-9H-Adenine", "CHEMBL509367"],
  },
  {
    primary_id: "D0Z8VY",
    names: ["Triazolo-Pyridine Derivative 3"],
  },
  {
    primary_id: "D0Z8WF",
    names: ["Pyridotriazolopyrimidine Derivative 3"],
  },
  {
    primary_id: "D0Z8XG",
    names: ["Cancer Therapy, ProXara Biotechnology Ltd"],
  },
  {
    primary_id: "D0Z8XI",
    names: [
      "HG-1077",
      "DNA Mismatch Repair Gene PMSL2 Modulator (Cancer), Human Genome Sciences",
      "HMLH3 Modulator (Cancer), Human Genome Sciences",
      "HNPCC4modulator (Cancer), Human Genome Sciences",
      "Human Mut L Homologue 3 Modulator (Cancer), Human Genome Sciences",
      "PMS2 Modulator (Cancer), Human Genome Sciences",
      "PMS2L8 Modulator (Cancer), Human Genome Sciences",
      "PMSR2 Modulator (Cancer), Human Genome Sciences",
      "Postmeiotic Segregation Increased 2 Modulator (Cancer), Human Genome Sciences",
      "H_DJ0042M02.9 Modulator (Cancer), Human Genome Sciences",
    ],
  },
  {
    primary_id: "D0Z8XJ",
    names: ["6-Benzyl-2-Sec-Butylsulfanyl-3H-Pyrimidin-4-One"],
  },
  {
    primary_id: "D0Z8YM",
    names: ["QNZ 46"],
  },
  {
    primary_id: "D0Z8YZ",
    names: ["AC265347", "AC-265347", "AC 265347"],
  },
  {
    primary_id: "D0Z8ZW",
    names: [
      "EGF-SLiP Fusion Protein",
      "BiP Inhibitor (Adjunct, Breast Cancer), SibTech",
      "EGF-SLiP Fusion Protein (Adjunct, Breast Cancer)",
      "GRP78 Inhibitor (Adjunct, Breast Cancer), SibTech",
      "EGF-SLiP Fusion Protein (Adjunct, Breast Cancer), SibTech",
      "Glucose-Regulated Protein 78 Inhibitor (Adjunct, Breast Cancer), SibTech",
      "Human Epidermal Growth Factor-SLip Fusion Protein (Adjunct, Breast Cancer), SibTech",
      "Immunoglobulin Heavy-Chain Binding Protein Inhibitor (Adjunct, Breast Cancer), SibTech",
    ],
  },
  {
    primary_id: "D0Z9AV",
    names: ["H5N1 Vaccine"],
  },
  {
    primary_id: "D0Z9BN",
    names: ["NBRI-16716a"],
  },
  {
    primary_id: "D0Z9BU",
    names: [
      "99mTc-EC-0652",
      "EC-0652",
      "PSMA-Targeted Imaging Agent (Prostate Cancer), Endocyte",
      "Prostate-Specific Membrane Antigen Targeted Imaging Agent (Prostate Cancer), Endocyte",
    ],
  },
  {
    primary_id: "D0Z9CC",
    names: ["ZONAMPANEL"],
  },
  {
    primary_id: "D0Z9DY",
    names: ["2-Phenylquinoline-8-Carboxamide", "CHEMBL480429", "SCHEMBL6442515", "ZINC3939668"],
  },
  {
    primary_id: "D0Z9EO",
    names: ["Metreleptin"],
  },
  {
    primary_id: "D0Z9GI",
    names: ["1-[bis(4-Chlorophenyl)Methyl]-3-Phenylthiourea", "CHEMBL391003", "ZINC13676247"],
  },
  {
    primary_id: "D0Z9GM",
    names: ["LY-2940094"],
  },
  {
    primary_id: "D0Z9HW",
    names: ["AZD6280"],
  },
  {
    primary_id: "D0Z9JO",
    names: ["Uridine-5'-Diphosphate-Mannose"],
  },
  {
    primary_id: "D0Z9JS",
    names: ["1-(3-Oxocyclohexylidene)Thiosemicarbazide"],
  },
  {
    primary_id: "D0Z9JY",
    names: ["[R-(-)-Apomorphine-2-Yl]-(2'-Hydroxy-Ethyl)Ether", "CHEMBL457275", "BDBM50251328"],
  },
  {
    primary_id: "D0Z9KR",
    names: ["WDuo3", "1,1'-Trimethylenebis[4-(Phthalimidylmethoxyiminomethyl)Pyridinium]"],
  },
  {
    primary_id: "D0Z9LD",
    names: ["SC-49992", "(8-Guanidinooctanoyl)-L-Aspartyl-L-Phenylalanine"],
  },
  {
    primary_id: "D0Z9LP",
    names: ["RP101"],
  },
  {
    primary_id: "D0Z9MG",
    names: ["NSC-359466"],
  },
  {
    primary_id: "D0Z9MR",
    names: ["TG-1042"],
  },
  {
    primary_id: "D0Z9NS",
    names: ["ISIS 19212"],
  },
  {
    primary_id: "D0Z9NY",
    names: [
      "API-023",
      "AGN-201904",
      "Proton Pump Inhibitor Prodrug, Alevium",
      "Proton Pump Inhibitor Prodrug, Allergan",
      "AGN-201904-Z",
      "Omeprazole Prodrug (GERD), Alevium",
    ],
  },
  {
    primary_id: "D0Z9NZ",
    names: [
      "Glutethimide",
      "Alfimid",
      "Doriden",
      "Dorimide",
      "Elrodorm",
      "Gimid",
      "Glimid",
      "Glutathimid",
      "Glutethimid",
      "Glutethimidum",
      "Glutetimid",
      "Glutetimida",
      "Glutetimide",
      "Glutetimidu",
      "Gluthetimide",
      "Noxiron",
      "Noxyron",
      "Ondasil",
      "Rigenox",
      "Sarodormin",
      "Glutetimide [DCIT]",
      "Glutetimidu [Polish]",
      "CC 11511",
      "LT00114358",
      "Doriden (TN)",
      "Doriden-Sed",
      "Glutethimide [INN:BAN]",
      "Glutethimidum [INN-Latin]",
      "Glutetimida [INN-Spanish]",
      "Glutethimide (JAN/INN)",
      "Phenyl-Aethyl-Glutarsaeureimid",
      "Phenyl-Aethyl-Glutarsaeureimid [German]",
      "Alpha-Ethyl-Alpha-Phenylglutarimide",
      "Alpha-Phenyl-Alpha-Ethylglutaric Acid Imide",
      "Alpha-Phenyl-Alpha-Ethylglutarimide",
      "2-Ethyl-2-Phenylglutarimide",
      "2-Phenyl-2-Ethylglutaric Acid Imide",
      "3-Ethyl-3-Phenyl-2,6-Diketopiperidine",
      "3-Ethyl-3-Phenyl-2,6-Dioxopiperidine",
      "3-Ethyl-3-Phenyl-2,6-Piperidinedione",
      "3-Phenyl-3-Ethyl-2,6-Diketopiperidine",
      "3-Phenyl-3-Ethyl-2,6-Dioxopiperidine",
      "3-Ethyl-3-Phenylpiperidine-2,6-Dione",
    ],
  },
  {
    primary_id: "D0Z9OS",
    names: ["BT-VACC", "Botulinum Toxin Vaccine, DOR BioPharma", "Botulinum Toxin Vaccine, Soligenix"],
  },
  {
    primary_id: "D0Z9OX",
    names: ["1-(2-(2-Chlorophenyl)-1-Phenylethyl)Piperazine", "CHEMBL213287", "SCHEMBL5444180"],
  },
  {
    primary_id: "D0Z9PB",
    names: ["Trisnorsqualene Alcohol"],
  },
  {
    primary_id: "D0Z9PX",
    names: ["Isopropamide Iodide"],
  },
  {
    primary_id: "D0Z9QC",
    names: ["DIDEAZAFOLATE"],
  },
  {
    primary_id: "D0Z9QI",
    names: [
      "CGP 62349",
      "AKUSEWDIEMJQBM-BEFAXECRSA-N",
      "3-[(1R)-1-[[(2S)-2-Hydroxy-3-[hydroxy-[(4-Methoxyphenyl)Methyl]phosphoryl]propyl]-Methylamino]ethyl]benzoic Acid",
      "10-31-1",
      "CGP62349",
      "[3H]CGP62349",
      "[3H]CGP 62349",
      "[3H]-CGP 62349",
      "AC1NSJP8",
      "GTPL1072",
      "GTPL3429",
      "SCHEMBL12477448",
      "CGP-62349",
    ],
  },
  {
    primary_id: "D0Z9QR",
    names: ["Zalcitabine"],
  },
  {
    primary_id: "D0Z9QX",
    names: ["CTX-1301"],
  },
  {
    primary_id: "D0Z9QZ",
    names: [
      "2-Methyl-4-P-Tolylethynyl-Thiazole",
      "CHEMBL185813",
      "Thiazole, 2-Methyl-4-[2-(4-Methylphenyl)Ethynyl]-",
      "SCHEMBL4456131",
      "BDBM50149803",
    ],
  },
  {
    primary_id: "D0Z9RJ",
    names: ["GS 9667"],
  },
  {
    primary_id: "D0Z9TM",
    names: [
      "2'-Deoxyuridine 5'-Alpha,Beta-Imido-Triphosphate",
      "2'-DEOXYURIDINE 5'-ALPHA,BETA-IMIDO-TRIPHOSPHATE",
      "CHEMBL1232397",
      "DUP",
      "Alpha,beta-Imino-DUTP",
      "AC1L9LWH",
      "BDBM50386619",
      "DB01965",
      "2'-Deoxy-5'-O-[(S)-Hydroxy{[(R)-Hydroxy(Phosphonooxy)Phosphoryl]amino}phosphoryl]uridine",
      "[(2R,3S,5R)-5-(2,4-Dioxopyrimidin-1-Yl)-3-Hydroxyoxolan-2-Yl]methoxy-N-[hydroxy(Phosphonooxy)Phosphoryl]phosphonamidic Acid",
    ],
  },
  {
    primary_id: "D0Z9TO",
    names: ["Sarcodictyin A"],
  },
  {
    primary_id: "D0Z9TY",
    names: ["SK549"],
  },
  {
    primary_id: "D0Z9UB",
    names: ["Bexlosteride"],
  },
  {
    primary_id: "D0Z9UJ",
    names: ["Peptide Analog 4"],
  },
  {
    primary_id: "D0Z9VB",
    names: ["Halazepam"],
  },
  {
    primary_id: "D0Z9VY",
    names: ["UCN-01"],
  },
  {
    primary_id: "D0Z9WK",
    names: ["Iodoaminopotentidine"],
  },
  {
    primary_id: "D0Z9WP",
    names: ["ZP-3022", "Dual Glucagon-Like Peptide 1/Gastrin Peptide Agonist (Diabetes), Zealand Pharma"],
  },
  {
    primary_id: "D0Z9WS",
    names: ["S-(N-Phenyl-N-Hydroxycarbamoyl)Glutathione", "CHEMBL128935", "SCHEMBL3280199", "BDBM50092825"],
  },
  {
    primary_id: "D0Z9WV",
    names: ["Sharon-1000"],
  },
  {
    primary_id: "D0Z9WY",
    names: ["Aryl Piperazine Derivative 6"],
  },
  {
    primary_id: "D0Z9XV",
    names: ["RO1138452"],
  },
  {
    primary_id: "D0Z9YE",
    names: ["[Tyr-Pro-Phe-NH-]2", "CHEMBL179946"],
  },
  {
    primary_id: "D0Z9YF",
    names: ["Recombinant Bet V 1 Immunotherapy"],
  },
  {
    primary_id: "D0Z9YK",
    names: [
      "Chebulinic Acid",
      "18942-26-2",
      "UNII-HVC8VQJ6EK",
      "HVC8VQJ6EK",
      "AC1L2HS1",
      "CHEMBL501154",
      "NSC69862",
      "NSC 69862",
      "CHEBI:3584",
      "ZINC195763592",
      "FT-0697896",
      "C10215",
      "Q-100932",
      "Beta-D-Glucopyranose, Cyclic 2,4-Ester With 3-(6-Carboxy-2,3,4-Trihydroxyphenyl)-4-Hydroxy-1,2,4-Butanetricarboxylic Acid, 1,3,6-Tris(3,4,5-Trihydroxybenzoate)",
    ],
  },
  {
    primary_id: "D0Z9YV",
    names: ["BT-D005"],
  },
  {
    primary_id: "D0Z9YW",
    names: [
      "FK-3311",
      "116686-15-8",
      "FK 3311",
      "FK3311",
      "COX-2 Inhibitor V, FK3311",
      "N-[4-Acetyl-2-(2,4-Difluorophenoxy)Phenyl]methanesulfonamide",
      "N-(4-Acetyl-2-(2,4-Difluorophenoxy)Phenyl)Methanesulfonamide",
      "4'-Acetyl-2'-(2,4-Difluorophenoxy)Methanesulfonanilide",
      "Methanesulfonamide, N-[4-Acetyl-2-(2,4-Difluorophenoxy)Phenyl]-",
      "C15H13F2NO4S",
      "Methanesulfonamide, N-(4-Acetyl-2-(2,4-Difluorophenoxy)Phenyl)-",
      "DIIYLGZNZGPXRR-UHFFFAOYSA-N",
      "AC1L4U0O",
      "AC1Q6W4K",
      "SCHEMBL441676",
      "ZINC3880",
      "DIIYLGZNZGPXRR-UHFFFAOYSA-",
      "CTK8E9207",
      "EX-A545",
      "DTXSID90151474",
    ],
  },
  {
    primary_id: "D0Z9ZT",
    names: ["MDNA55"],
  },
  {
    primary_id: "D0Z9ZY",
    names: ["4-Hydroxy-N,N-Diphenylbenzenesulfonamide", "CHEMBL203061", "SCHEMBL2114599"],
  },
  {
    primary_id: "D0ZA0W",
    names: ["PSA/IL-2/GM-CSF Vaccine"],
  },
  {
    primary_id: "D0ZA1K",
    names: ["BPX-701"],
  },
  {
    primary_id: "D0ZA2L",
    names: [
      "ER-35786",
      "E-1010",
      "(1R,5S,6S)-6-[1(R)-Hydroxymethyl]-2-[2(S)-[1(R)-Hydroxy-1-[pyrrolidin-3(R)-Yl]methyl]pyrrolidin-4(S)-Ylsulfanyl]-1-Methyl-1-Carba-2-Penem-3-Carboxylic Acid Monohydrochloride",
    ],
  },
  {
    primary_id: "D0ZA3S",
    names: ["Peptide Analog 58"],
  },
  {
    primary_id: "D0ZA4G",
    names: ["Varicella-Zoster Immune Globulin"],
  },
  {
    primary_id: "D0ZA4J",
    names: ["5,6-Dichloro-N-(3-Morpholinopropyl)Nicotinamide", "CHEMBL597764", "BDBM50307204"],
  },
  {
    primary_id: "D0ZA6G",
    names: ["Radicicol", "AC1L1JGN", "CHEMBL3972342", "CTK6B6269"],
  },
  {
    primary_id: "D0ZA6P",
    names: ["CBT-501 + CBT-502"],
  },
  {
    primary_id: "D0ZA6V",
    names: ["MNPR-201"],
  },
  {
    primary_id: "D0ZB0H",
    names: ["Hypoxic Response Inhibitors"],
  },
  {
    primary_id: "D0ZB2E",
    names: ["NV-07a"],
  },
  {
    primary_id: "D0ZB6O",
    names: ["Folate Gamma-L-Proline-Hydroxamic Acid", "CHEMBL388878"],
  },
  {
    primary_id: "D0ZB7K",
    names: [
      "Zopiclone",
      "Amoban",
      "Amovane",
      "Imovance",
      "Imovane",
      "Limovan",
      "Optidorm",
      "Rhovane",
      "Siaten",
      "Sopivan",
      "Ximovan",
      "Zileze",
      "Zimoclone",
      "Zimovane",
      "Zopicalm",
      "Zopicalma",
      "Zopiclodura",
      "Zopiclona",
      "Zopiclonum",
      "Zopitan",
      "Zorclone",
      "AbZ Brand Of Zopiclone",
      "Aliud Brand Of Zopiclone",
      "Alpharma Brand Of Zopiclone",
      "Aventis Pharma Brand Of Zopiclone",
      "Aventis Brand Of Zopiclone",
      "Azupharma Brand Of Zopiclone",
      "Betapharm Brand Of Zopiclone",
      "Ciclum Brand Of Zopiclone",
      "Clonmel Brand Of Zopiclone",
      "Dolorgeit Brand Of Zopiclone",
      "Gerard Brand Of Zopiclone",
      "Hexal Brand Of Zopiclone",
      "Hormosan Brand Of Zopiclone",
      "Italfarmaco Brand Of Zopiclone",
      "Merck Dura Brand Of Zopiclone",
      "Neuraxpharm Brand Of Zopiclone",
      "Norton Brand Of Zopiclone",
      "Opus Brand Of Zopiclone",
      "Pinewood Brand Of Zopiclone",
      "Ratiopharm Brand Of Zopiclone",
      "Rhodiapharm Brand Of Zopiclone",
      "Stadapharm Brand Of Zopiclone",
      "TAD Brand Of Zopiclone",
      "Temmler Brand Of Zopiclone",
      "Teva Brand Of Zopiclone",
      "Zopiclon AL",
      "Zopiclon AZU",
      "Zopiclon AbZ",
      "Zopiclon Stada",
      "Zopiclon TAD",
      "Zopiclon Beta",
      "Zopiclon Von Ct",
      "RP 27 267",
      "RP 27267",
      "Z 4900",
      "Z4900_SIGMA",
      "Amoban (TN)",
      "Ct-Arzneimittel Brand Of Zopiclone",
      "Imovane (TN)",
      "Novo-Zopiclone",
      "Nu-Pharm Brand Of Zopiclone",
      "Nu-Zopiclone",
      "RP-27267",
      "Ran-Zopiclone",
      "Ratio-Zopiclone",
      "Rhone-Poulenc Rorer Brand Of Zopiclone",
      "Zimovane (TN)",
      "Zopi-Puren",
      "Zopiclon-TEVA",
      "Zopiclon-Neuraxpharm",
      "Zopiclon-Ratiopharm",
      "Zopiclona [INN-Spanish]",
      "Zopiclone (TN)",
      "Zopiclonum [INN-Latin]",
      "Zopinox (TN)",
      "Zopiclone (JAN/INN)",
      "Zopiclone [BAN:INN:JAN]",
      "Zopiclone, Imovane, Zimovane, Lunesta",
      "[6-(5-Chloropyridin-2-Yl)-5-Oxo-7H-Pyrrolo[3,4-B]pyrazin-7-Yl] 4-Methylpiperazine-1-Carboxylate",
      "1-Piperazinecarboxylic Acid, 4-Methyl-, 6-(5-Chloro-2-Pyridinyl)-6,7-Dihydro-7-Oxo-5H-Pyrrolo(3,4-B)Pyrazin-5-Yl Ester",
      "1-Piperazinecarboxylic Acid, 4-Methyl-, 6-(5-Chloro-2-Pyridinyl)-6,7-Dihydro-7-Oxo-5H-Pyrrolo[3,4-B]pyrazin-5-Yl Ester",
      "4-Methyl-1-Piperazinecarboxylic Acid 6-(5-Chloro-2-Pyridinyl)-6,7-Dihydro-7-Oxo-5H-Pyrrolo[3,4-B]pyrazin-5-Yl Ester",
      "4-Methyl-1-Piperazinecarboxylic Acid Ester With 6-(5-Chloro-2-Pyridyl)-6,7-Dihydro-7-Hydroxy-5H-Pyrrolo(3,4-B)Pyrazin-5-One",
      "6-(5-Chloro-2-Pyridinyl)-7-Oxo-6,7-Dihydro-5H-Pyrrolo[3,4-B]pyrazin-5-Yl 4-Methyl-1-Piperazinecarboxylate",
      "6-(5-Chloro-2-Pyridyl)-6,7-Dihydro-7-Oxo-5H-Pyrrolo(3,4-B)Pyrazin-5-Yl 4-Methyl-1-Piperazinecarboxylate",
      "6-(5-Chloropyrid-2-Yl)-5-(4-Methylpiperazin-1-Yl)Carbonyloxy-7-Oxo-6,7-Dihydro-5H-Pyrrolo[3,4-B]pyrazine",
      "6-(5-Chloropyridin-2-Yl)-7-Oxo-6,7-Dihydro-5H-Pyrrolo[3,4-B]pyrazin-5-Yl 4-Methylpiperazine-1-Carboxylate",
    ],
  },
  {
    primary_id: "D0ZB7Z",
    names: ["XCT790"],
  },
  {
    primary_id: "D0ZB8S",
    names: ["SC-50135"],
  },
  {
    primary_id: "D0ZB9D",
    names: ["ER-21036"],
  },
  {
    primary_id: "D0ZB9T",
    names: ["Aryl Pyrimidine Derivative 3"],
  },
  {
    primary_id: "D0ZC0M",
    names: ["GSK561679"],
  },
  {
    primary_id: "D0ZC1O",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 6"],
  },
  {
    primary_id: "D0ZC2K",
    names: ["PMID27109571-Compound-1"],
  },
  {
    primary_id: "D0ZC3C",
    names: ["GTS-21"],
  },
  {
    primary_id: "D0ZC3K",
    names: [
      "Anti-Ricin Mabs",
      "Anti-Ricin MAbs, Cangene",
      "Immune Globulin (Ricin), Cangene",
      "Antibody-Based Ricin Therapy, Cangene/TwinstrandTherapeutics",
    ],
  },
  {
    primary_id: "D0ZC3Q",
    names: [
      "5-(3-BENZYLPYRROLIDIN-3-YL)-1H-INDOLE (STRUCTURAL MIX)",
      "CHEMBL487355",
      "SCHEMBL982204",
      "UZKWACFLBGIUSH-UHFFFAOYSA-N",
      "BDBM50275438",
      "5-(3-Benzylpyrrolidin-3-Yl)-1H-Indole",
      "5-(3-Benzyl-Pyrrolidin-3-Yl)-1H-Indole",
      "(-)-5-(3-Benzylpyrrolidin-3-Yl)-1H-Indole",
    ],
  },
  {
    primary_id: "D0ZC3W",
    names: [
      "P-TOLUENESULFONAMIDE",
      "4-Methylbenzenesulfonamide",
      "70-55-3",
      "4-Toluenesulfonamide",
      "Tosylamide",
      "P-Tosylamide",
      "Toluene-4-Sulfonamide",
      "P-Tolylsulfonamide",
      "Benzenesulfonamide, 4-Methyl-",
      "Tolylsulfonamide",
      "P-Toluenesulfamide",
      "4-Methylbenzene-1-Sulfonamide",
      "P-Methylbenzenesulfonamide",
      "P-Toluenesulfonylamide",
      "Toluene-P-Sulphonamide",
      "Para-Toluenesulfonamide",
      "4-Toluenesulfanamide",
      "Toluene-4-Sulphonamide",
      "UNII-I8266RI90M",
      "4-Toluenesulfonic Acid, Amide",
      "HSDB 5203",
      "NSC 9908",
      "EINECS 200-741-1",
      "CHEMBL574",
      "BRN 0472689",
      "4-Toluenesulfonic Acid Amide",
    ],
  },
  {
    primary_id: "D0ZC4S",
    names: [
      "(1H-Imidazol-2-Yl)-Quinoxalin-6-Yl-Amine",
      "CHEMBL74449",
      "6-Quinoxalinamine, N-1H-Imidazol-2-Yl-",
      "BDBM50055838",
    ],
  },
  {
    primary_id: "D0ZC6X",
    names: ["PRTX-100"],
  },
  {
    primary_id: "D0ZC7P",
    names: ["BAMAQUIMAST"],
  },
  {
    primary_id: "D0ZC7V",
    names: [
      "Alpha-7-Deoxyhomonojirimycin",
      "CHEMBL503021",
      "7-Deoxyhomonojirimycin",
      "Alpha-1-C-Methyl-DNJ",
      "SCHEMBL2438896",
      "BDBM18359",
    ],
  },
  {
    primary_id: "D0ZC7W",
    names: ["Phenylsulfonyl Derivative 4"],
  },
  {
    primary_id: "D0ZC8K",
    names: [
      "3,5-Di(Pyridin-4-Yl)-1H-1,2,4-Triazole",
      "4329-78-6",
      "CHEMBL1078978",
      "4,4'-(1H-1,2,4-Triazole-3,5-Diyl)Dipyridine",
      "3,5-DI(4-Pyridyl)-1,2,4-Triazole",
      "3,5-Bis(Pyridin-4-Yl)-1,2,4-Triazole",
      "AC1M7KCV",
      "MLS000999673",
      "4-(3-Pyridin-4-Yl-1H-1,2,4-Triazol-5-Yl)Pyridine",
      "SCHEMBL10000033",
      "CTK1D2724",
      "QUKGHTHKDNHSOX-UHFFFAOYSA-",
      "DTXSID00368692",
      "QUKGHTHKDNHSOX-UHFFFAOYSA-N",
      "MolPort-000-263-064",
      "ZINC8048404",
      "BDBM50311280",
      "STK246494",
      "AKOS002269457",
      "AKOS001080808",
      "MCULE-5677308017",
      "3,5-Bis(4-Pyridyl)-1,2,4-Triazole",
      "SMR000498789",
      "ST092086",
    ],
  },
  {
    primary_id: "D0ZC9Q",
    names: ["[2-(3H-Indol-1-Yl)-Ethyl]-Dimethyl-Amine", "CHEMBL363035"],
  },
  {
    primary_id: "D0ZD0W",
    names: ["D[Orn4]AVP", "CHEMBL375096"],
  },
  {
    primary_id: "D0ZD1B",
    names: ["PMID7473541C21"],
  },
  {
    primary_id: "D0ZD2M",
    names: ["MLN4924"],
  },
  {
    primary_id: "D0ZD3C",
    names: [
      "Gemigliptin",
      "911637-19-9",
      "UNII-5DHU18M5D6",
      "5DHU18M5D6",
      "(S)-1-(2-Amino-4-(2,4-Bis(Trifluoromethyl)-5,6-Dihydropyrido[3,4-D]pyrimidin-7(8H)-Yl)-4-Oxobutyl)-5,5-Difluoropiperidin-2-One",
      "(S)-1-(2-Amino-4-(2,4-Bis(Trifluoromethyl)-5,8-Dihydropyrido[3,4-D]pyrimidin-7(6H)-Yl)-4-Oxobutyl)-5,5-Difluoropiperidin-2-One",
      "Gemigliptin [INN]",
      "Gemigliptin (Prop.INN)",
      "SCHEMBL1262740",
      "CHEMBL3707235",
      "CHEBI:134731",
      "ZINC68245464",
      "AKOS025290873",
      "PB11419",
      "DB12412",
      "API0013914",
      "AK170799",
      "2-Piperidinone, 1-((2S)-2-Amino-4-(5,8-Dihydro-2,4-",
    ],
  },
  {
    primary_id: "D0ZD3M",
    names: ["Ac-YCit[CEHdFRWC]-NH2", "CHEMBL412494"],
  },
  {
    primary_id: "D0ZD4F",
    names: [
      "N,N'-Bis-(4-Hexyl-Phenyl)-Guanidine",
      "CHEMBL326741",
      "SCHEMBL4985473",
      "N,N'-Bis(4-Hexylphenyl)Guanidine",
      "BDBM50066419",
    ],
  },
  {
    primary_id: "D0ZD4M",
    names: [
      "4-Amino-2-P-Tolylisoindoline-1,3-Dione",
      "4-Amino-2-P-Tolylisoindole-1,3-Dione",
      "CHEMBL600405",
      "20871-07-2",
      "4-Amino-2-P-Tolyl-Isoindole-1,3-Dione",
      "4-Amino-2-(P-Tolyl)Isoindoline-1,3-Dione",
      "AC1LHA6Z",
      "Oprea1_716953",
      "Oprea1_214378",
      "MolPort-000-151-255",
      "ZINC360744",
      "BDBM50306747",
      "AKOS000727908",
      "MCULE-4928093173",
      "BAS 00084697",
      "KB-189090",
    ],
  },
  {
    primary_id: "D0ZD6N",
    names: ["Biaryl Mannoside Derivative 3"],
  },
  {
    primary_id: "D0ZD6Y",
    names: ["RUS 3108"],
  },
  {
    primary_id: "D0ZD6Z",
    names: [
      "3-Methoxyphenylboronic Acid",
      "10365-98-7",
      "(3-Methoxyphenyl)Boronic Acid",
      "3-Methoxybenzeneboronic Acid",
      "3-Methoxyphenyl Boronic Acid",
      "(3-Methoxyphenyl)Boranediol",
      "3-Boronoanisole",
      "3-Methoxypenylboronic Acid",
      "M-Methoxyphenylboronic Acid",
      "Boronic Acid, (3-Methoxyphenyl)-",
      "3-Methoxy Phenylboronic Acid",
      "3-Methoxyphenylboronic Acid, 97%",
      "3-Methoxyphenylboronicacid",
      "3-Boronanisole",
      "Phenylboronic Acid, 6",
      "AC1MC0ZC",
      "ACMC-1BR3R",
      "3-Methoxyphenylbornoic Acid",
      "M-Methoxy Phenylboronic Acid",
      "3-Methoxylphenylboronic Acid",
      "SCHEMBL5140",
    ],
  },
  {
    primary_id: "D0ZD9P",
    names: ["Biliverdin"],
  },
  {
    primary_id: "D0ZE0S",
    names: ["BMS-986183"],
  },
  {
    primary_id: "D0ZE1M",
    names: ["PMID25666693-Compound-107"],
  },
  {
    primary_id: "D0ZE1O",
    names: ["Plinabulin"],
  },
  {
    primary_id: "D0ZE2J",
    names: ["Oxetane 3,3-Dicarboxamide Compound 2"],
  },
  {
    primary_id: "D0ZE2W",
    names: ["1-Benzhydryl-4-Benzylpiperidin-4-Ol", "CHEMBL231661"],
  },
  {
    primary_id: "D0ZE3S",
    names: ["RESP-6000"],
  },
  {
    primary_id: "D0ZE4C",
    names: ["2-(N-Cyclopentylamino)-3'-Methoxypropiophenone", "CHEMBL589250", "BDBM50308121"],
  },
  {
    primary_id: "D0ZE5U",
    names: ["Indolinone Derivative 1"],
  },
  {
    primary_id: "D0ZE5V",
    names: ["FPL-55712"],
  },
  {
    primary_id: "D0ZE6H",
    names: ["Six-Membered Heterocyclic Benzamide Derivative 1"],
  },
  {
    primary_id: "D0ZE7B",
    names: ["VLD-631"],
  },
  {
    primary_id: "D0ZE7D",
    names: ["Depatuxizumab Mafodotin"],
  },
  {
    primary_id: "D0ZE8B",
    names: ["Azetidine-1-Carboxamide Derivative 2"],
  },
  {
    primary_id: "D0ZE8C",
    names: ["Ohioensin G", "CHEMBL403411"],
  },
  {
    primary_id: "D0ZE8Q",
    names: ["Mabs Targeting PAR-1"],
  },
  {
    primary_id: "D0ZE9C",
    names: [
      "PAZINACLONE",
      "A-77000",
      "DN-2327",
      "Pazinaclone < Rec INN",
      "Rac-8-[[2-(7-Chloro-1,8-Naphthyridin-2-Yl)-2,3-Dihydro-3-Oxo-1H-Isoindol-1-Yl]acetyl]-1,4-Dioxa-8-Azaspiro[4.5]decane",
    ],
  },
  {
    primary_id: "D0ZE9M",
    names: ["N-(4-Tert-Butylthiazol-2-Yl)Isoquinolin-5-Amine", "CHEMBL378272"],
  },
  {
    primary_id: "D0ZE9T",
    names: ["DA-3811", "Beta-Glucocerebrosidase Analog (Gaucher Disease), Dong-A"],
  },
  {
    primary_id: "D0ZF0Z",
    names: ["PMID26609882-Compound-34"],
  },
  {
    primary_id: "D0ZF1D",
    names: ["Ambasilide"],
  },
  {
    primary_id: "D0ZF1N",
    names: ["PMID25726713-Compound-11"],
  },
  {
    primary_id: "D0ZF1W",
    names: [
      "N-1-Isopropyltryptamine",
      "1-Isopropyltryptamine",
      "2-(1-Propan-2-Ylindol-3-Yl)Ethanamine",
      "AC1NOM56",
      "GTPL159",
      "SCHEMBL7749047",
      "BDBM84943",
      "MolPort-001-788-641",
      "ZINC4600317",
      "SBB081239",
      "STK075425",
      "AKOS000276209",
      "MCULE-5027387412",
      "40619-60-1",
      "2-[1-(Methylethyl)Indol-3-Yl]ethylamine",
    ],
  },
  {
    primary_id: "D0ZF2W",
    names: ["ISIS 3303"],
  },
  {
    primary_id: "D0ZF8F",
    names: ["HIP-2B"],
  },
  {
    primary_id: "D0ZF8N",
    names: ["4-Methoxy-2-Phenyl-2H-Pyrazolo[4,3-C]quinoline", "CHEMBL188052"],
  },
  {
    primary_id: "D0ZF9J",
    names: ["HuCART-Meso Cells"],
  },
  {
    primary_id: "D0ZG0K",
    names: ["Thiazole Carboxamide Derivative 13"],
  },
  {
    primary_id: "D0ZG1Y",
    names: [
      "RG-7167",
      "CIF",
      "R-7167",
      "CIF (Solid Tumors), Chugai/Roche",
      "MEK Inhibitor (Oral, Solid Tumors), Roche/Chugai",
    ],
  },
  {
    primary_id: "D0ZG3D",
    names: ["PMID29649907-Compound-30"],
  },
  {
    primary_id: "D0ZG3O",
    names: ["Ethyl 3-(Pyridin-4-Yl)-1H-Indole-6-Carboxylate", "CHEMBL208261"],
  },
  {
    primary_id: "D0ZG3Q",
    names: ["2-(Adamantan-1-Ylamino)-5,5-Diethyl-Oxazol-4-One"],
  },
  {
    primary_id: "D0ZG3S",
    names: [
      "Reproxalap",
      "2-(3-Amino-6-Chloroquinolin-2-Yl)Propan-2-Ol",
      "Reproxalap [USAN]",
      "UNII-F0GIZ22IJH",
      "F0GIZ22IJH",
      "SCHEMBL12228833",
      "ALD-102",
      "ADX-102",
      "2-Quinolinemethanol, 3-Amino-6-Chloro-Alpha,alpha-Dimethyl-",
      "NS2",
    ],
  },
  {
    primary_id: "D0ZG4G",
    names: [
      "FR-180204",
      "865362-74-9",
      "FR 180204",
      "FR180204",
      "ERK Inhibitor II, FR180204",
      "5-(2-Phenyl-Pyrazolo[1,5-A]pyridin-3-Yl)-1H-Pyrazolo[3,4-C]pyridazin-3-Ylamine",
      "CHEMBL259551",
      "5-(2-PHENYLPYRAZOLO[1,5-A]PYRIDIN-3-YL)-1H-PYRAZOLO[3,4-C]PYRIDAZIN-3-AMINE",
      "C18H13N7",
      "5-{2-Phenylpyrazolo[1,5-A]pyridin-3-Yl}-1H-Pyrazolo[3,4-C]pyridazin-3-Amine",
      "5-{2-Phenylpyrazolo[1,5-A]pyridin-3-Yl}-2H-Pyrazolo[3,4-C]pyridazin-3-Amine",
      "5-(2-Phenylpyrazolo[1,5-A]pyridin-3-Yl)-2H-Pyrazolo[3,4-C]pyridazin-3-Amine",
      "ERK Inhibitor II",
      "MLS002607685",
    ],
  },
  {
    primary_id: "D0ZG5K",
    names: ["Kiacta"],
  },
  {
    primary_id: "D0ZG5X",
    names: ["PMID29671355-Compound-13"],
  },
  {
    primary_id: "D0ZG7E",
    names: ["PMID28460551-Compound-3"],
  },
  {
    primary_id: "D0ZG7S",
    names: ["BXL-01-0029"],
  },
  {
    primary_id: "D0ZG7W",
    names: ["Abbott 14c", "2,4-Diaminopyrimidine-Based Antagonist, 14c"],
  },
  {
    primary_id: "D0ZG9I",
    names: ["Salvinorin A (Ester)"],
  },
  {
    primary_id: "D0ZG9N",
    names: ["SC-53116"],
  },
  {
    primary_id: "D0ZH0I",
    names: ["Thymidine-5'-Phosphate"],
  },
  {
    primary_id: "D0ZH0K",
    names: ["PGF2-1-Ethanolamide"],
  },
  {
    primary_id: "D0ZH0T",
    names: [
      "(+/-)-2-(4-Fluorophenyl)Chroman-4-One",
      "4'-(Fluoro)Flavanone",
      "CHEMBL60720",
      "1840-02-4",
      "4h-1-Benzopyran-4-One,2-(4-Fluorophenyl)-2,3-Dihydro-",
      "4'-Fluoroflavanone",
      "SCHEMBL2474548",
      "CTK0E2543",
      "DTXSID90452670",
      "RFDPVHCUOLBALB-UHFFFAOYSA-N",
      "BDBM50310197",
      "AKOS030552931",
      "KB-290728",
      "4H-1-Benzopyran-4-One, 2-(4-Fluorophenyl)-2,3-Dihydro-",
    ],
  },
  {
    primary_id: "D0ZH0X",
    names: ["IPH-24"],
  },
  {
    primary_id: "D0ZH1U",
    names: [
      "TYRPHOSTIN AG-1478",
      "AG-1478",
      "153436-53-4",
      "Tyrphostin AG 1478",
      "N-(3-Chlorophenyl)-6,7-Dimethoxyquinazolin-4-Amine",
      "175178-82-2",
      "Tyrphostin AG-1478",
      "4-(3-Chloroanilino)-6,7-Dimethoxyquinazoline",
      "AG 1478",
      "AG1478",
      "TYRPHOSTIN",
      "4-Quinazolinamine, N-(3-Chlorophenyl)-6,7-Dimethoxy-",
      "UNII-SUH0SEZ9HY",
      "SUH0SEZ9HY",
      "AG-1478 Hydrochloride",
      "AG-1478 (Tyrphostin AG-1478)",
      "CHEMBL7917",
      "CHEBI:75404",
      "N-(3-Chlorophenyl)-6,7-Dimethoxy-4-Quinazolinamine",
      "NSC-693255",
      "AK-63142",
      "N-(3-Chlorophenyl)-6,7-Dimethoxy-Quinazolin-4-Amine",
      "BRD6408",
      "BRD-6408",
      "SR-01000076156",
      "NSC693255",
    ],
  },
  {
    primary_id: "D0ZH2L",
    names: ["Autologous Fat-Derived Stem Cell Therapy"],
  },
  {
    primary_id: "D0ZH3G",
    names: ["3-Methylfentanyl"],
  },
  {
    primary_id: "D0ZH3O",
    names: ["PMID29865878-Compound-40"],
  },
  {
    primary_id: "D0ZH4I",
    names: ["PMID25656651-Compound-42"],
  },
  {
    primary_id: "D0ZH4J",
    names: ["MOB015B"],
  },
  {
    primary_id: "D0ZH9W",
    names: ["Pellitorin"],
  },
  {
    primary_id: "D0ZI0C",
    names: ["Dersalazine"],
  },
  {
    primary_id: "D0ZI0K",
    names: ["NMI-870"],
  },
  {
    primary_id: "D0ZI0S",
    names: [
      "7-Methoxy-2-P-Tolyl-4H-Chromen-4-One",
      "CHEMBL17052",
      "7-Methoxy-2-(4-Methylphenyl)Chromen-4-One",
      "7-Methoxy-2-(4-Methylphenyl)-4H-Chromen-4-One",
      "108980-49-0",
      "AC1LIGD7",
      "MolPort-000-450-862",
      "ZINC522064",
      "STK893155",
      "BDBM50310185",
      "7-Methoxy-2-P-Tolyl-Chromen-4-One",
      "AKOS002182021",
      "MCULE-3043616847",
      "NCGC00318207-01",
      "7-Methoxy-2-(P-Tolyl)-4H-Chromen-4-One",
      "ST50070846",
      "VU0510077-1",
      "AB01311569-01",
      "2-(4-Methylphenyl)-7-Methoxy-4H-1-Benzopyran-4-One",
    ],
  },
  {
    primary_id: "D0ZI1F",
    names: ["Tivantinib"],
  },
  {
    primary_id: "D0ZI4H",
    names: ["Ornoprostil", "Alloca (TN)"],
  },
  {
    primary_id: "D0ZI4L",
    names: ["CX-01"],
  },
  {
    primary_id: "D0ZI5A",
    names: ["PF-00337210"],
  },
  {
    primary_id: "D0ZI5U",
    names: ["XeriJect"],
  },
  {
    primary_id: "D0ZI5Z",
    names: ["H-Tyr-Pro-Dap(6DMN)-Phe-NH2", "CHEMBL207661"],
  },
  {
    primary_id: "D0ZI7F",
    names: [
      "6-Methoxy-1,9-Dimethyl-9H-Pyrido[3,4-B]indole",
      "CHEMBL375077",
      "AC1L4QAL",
      "SCHEMBL20159056",
      "BDBM50194445",
      "22574-29-4",
    ],
  },
  {
    primary_id: "D0ZI7Q",
    names: ["Miboplatin"],
  },
  {
    primary_id: "D0ZI8Y",
    names: ["3 Beta-O-Acetyloleanolic Acid", "CHEMBL503071"],
  },
  {
    primary_id: "D0ZJ0A",
    names: ["2164U90", "BW-2164", "BW-2164U90"],
  },
  {
    primary_id: "D0ZJ0R",
    names: ["PDC-APB"],
  },
  {
    primary_id: "D0ZJ1C",
    names: ["(S)-(+)-Dimethindene Maleate"],
  },
  {
    primary_id: "D0ZJ4M",
    names: ["Resorcinol Compound 3"],
  },
  {
    primary_id: "D0ZJ5K",
    names: ["VP-343"],
  },
  {
    primary_id: "D0ZJ5V",
    names: [
      "N-(9H-Beta-Carbolin-3-Yl)-Formamide",
      "95935-50-5",
      "Formamide, N-9H-Pyrido[3,4-B]indol-3-Yl-",
      "CHEMBL353110",
    ],
  },
  {
    primary_id: "D0ZJ6W",
    names: ["Ragweed Peptide Allergy Desensitization"],
  },
  {
    primary_id: "D0ZJ6Y",
    names: ["YM44778"],
  },
  {
    primary_id: "D0ZJ8G",
    names: ["FM-501"],
  },
  {
    primary_id: "D0ZJ9S",
    names: [
      "2-(3-Hydroxycarbamoyl-Propyl)-Pentanedioic Acid",
      "CHEMBL433298",
      "SCHEMBL13996819",
      "BDBM50129199",
      "2-[4-Oxo-4-(Hydroxyamino)Butyl]pentanedioic Acid",
    ],
  },
  {
    primary_id: "D0ZK0N",
    names: ["BMS-931699"],
  },
  {
    primary_id: "D0ZK2F",
    names: ["Kojic Acid-Phenylalanine Amide"],
  },
  {
    primary_id: "D0ZK2G",
    names: ["Monastrol"],
  },
  {
    primary_id: "D0ZK2Q",
    names: ["PMID27607364-Compound-10"],
  },
  {
    primary_id: "D0ZK3L",
    names: ["BZ5"],
  },
  {
    primary_id: "D0ZK3X",
    names: ["BBI-2000"],
  },
  {
    primary_id: "D0ZK5H",
    names: ["PW-4156"],
  },
  {
    primary_id: "D0ZK7B",
    names: ["Tesofensine"],
  },
  {
    primary_id: "D0ZK8H",
    names: ["Gardasil"],
  },
  {
    primary_id: "D0ZL0Z",
    names: [
      "PT-304",
      "149486-04-4",
      "Thiourea, N-(2-(3-Fluorophenyl)Ethyl)-N'-2-Thiazolyl-",
      "Thiourea, N-[2-(3-Fluorophenyl)Ethyl]-N'-2-Thiazolyl-",
      "PETT Analog 3",
      "AC1MHDMA",
      "SCHEMBL6964163",
      "CHEMBL253995",
      "BDBM1896",
      "DTXSID20164299",
      "1-[2-(3-Fluorophenyl)Ethyl]-3-(1,3-Thiazol-2-Yl)Thiourea",
      "AKOS030030941",
      "1-(2-Thiazolyl)-3-[3-Fluorophenethyl]thiourea",
      "N-(2-(3-Fluorophenethyl))-N -(2-Thiazolyl)Thiourea",
      "1-[2-(3-Fluorophenyl)Ethyl]-3-Thiazol-2-Yl-Thiourea",
    ],
  },
  {
    primary_id: "D0ZL1I",
    names: ["GI-5005-02"],
  },
  {
    primary_id: "D0ZL1T",
    names: ["SK-NBP601"],
  },
  {
    primary_id: "D0ZL2A",
    names: ["MRS1523"],
  },
  {
    primary_id: "D0ZL2P",
    names: ["JWH-369"],
  },
  {
    primary_id: "D0ZL3U",
    names: ["Allogeneic CART-33"],
  },
  {
    primary_id: "D0ZL3Z",
    names: ["CC-90002"],
  },
  {
    primary_id: "D0ZL4F",
    names: [
      "R-KETOPROFEN",
      "(R)-Ketoprofen",
      "56105-81-8",
      "(R)-Ketoprophen",
      "UNII-S03709D0TH",
      "(R)-(-)-Ketoprofen",
      "(R)-2-(3-Benzoylphenyl)Propanoic Acid",
      "CHEMBL372052",
      "S03709D0TH",
      "(R)-2-(3-Benzoylphenyl)Propionic Acid",
      "Benzeneacetic Acid,3-Benzoyl-A-Methyl-, (AR)-",
      "Ketoprofen, (R)-",
      "NCGC00016757-01",
      "CAS-22071-15-4",
      "(2R)-2-(3-Benzoylphenyl)Propanoic Acid",
      "Lopac-K-1751",
      "SCHEMBL195303",
      "ZINC2272",
      "AC1L47D6",
      "CTK5A4646",
      "DTXSID50204652",
      "DKYWVDODHFEZIM-LLVKDONJSA-N",
      "BDBM50169048",
      "AKOS022182275",
    ],
  },
  {
    primary_id: "D0ZL5J",
    names: ["XCUR17"],
  },
  {
    primary_id: "D0ZL5U",
    names: ["Gly-B7Pro-Glu"],
  },
  {
    primary_id: "D0ZL6B",
    names: [
      "1-Hexadecyl-1H-Indole-2,3-Dione",
      "C24H37NO2",
      "1-Hexadecylindole-2,3-Dione",
      "AC1MXWVN",
      "Isatin-Based Compound, 63",
      "CHEMBL221512",
      "BDBM22843",
      "MolPort-000-704-286",
      "STK527744",
      "ZINC14979621",
      "AKOS005460820",
      "MCULE-4252873887",
      "28035-35-0",
    ],
  },
  {
    primary_id: "D0ZL8O",
    names: ["ISIS 29244"],
  },
  {
    primary_id: "D0ZL9B",
    names: [
      "Diaspirin Crosslinked Hemoglobin",
      "DCLHb",
      "HemAssist",
      "DCLHb, Baxter",
      "Diaspirin Crosslinked Hemoglobin, Baxter",
    ],
  },
  {
    primary_id: "D0ZL9I",
    names: ["9-O-[3-(4-Bromo-Phenoxyl)Butyl]-Berberine Bromide", "CHEMBL1163985"],
  },
  {
    primary_id: "D0ZL9P",
    names: ["Benzene Sulfonamide Derivative 1"],
  },
  {
    primary_id: "D0ZM1H",
    names: ["Isoquinoline Derivative 3"],
  },
  {
    primary_id: "D0ZM1Q",
    names: [
      "(4-Benzylpiperidin-1-Yl)(3-Chlorophenyl)Methanone",
      "(4-Benzyl-Piperidin-1-Yl)-(3-Chloro-Phenyl)-Methanone",
      "Piperidine, B1",
      "Oprea1_631709",
      "Oprea1_092044",
      "AC1LG526",
      "CHEMBL240318",
      "SCHEMBL17730065",
      "BDBM25790",
      "MolPort-001-632-510",
      "GHPWLZRRFXHNMA-UHFFFAOYSA-N",
      "ZINC327248",
      "STK093701",
      "AKOS000612405",
      "MCULE-5547370853",
      "3-Chlorophenyl 4-Benzylpiperidyl Ketone",
      "BAS 00406738",
      "ST50299727",
      "4-Benzyl-1-[(3-Chlorophenyl)Carbonyl]piperidine",
      "AB00087773-01",
      "SR-01000478204",
      "SR-01000478204-1",
    ],
  },
  {
    primary_id: "D0ZM1T",
    names: ["VER-156085"],
  },
  {
    primary_id: "D0ZM2S",
    names: ["R1204"],
  },
  {
    primary_id: "D0ZM5B",
    names: ["AG-021541"],
  },
  {
    primary_id: "D0ZM5E",
    names: ["VCL-1M01"],
  },
  {
    primary_id: "D0ZM5O",
    names: ["Pexacerfont"],
  },
  {
    primary_id: "D0ZM5W",
    names: ["ESUPRONE"],
  },
  {
    primary_id: "D0ZM6R",
    names: ["2,2,4-Trimethyl-6-Phenyl-1,2-Dihydro-Quinoline"],
  },
  {
    primary_id: "D0ZM8B",
    names: ["FK-409"],
  },
  {
    primary_id: "D0ZM8F",
    names: ["ASP7374"],
  },
  {
    primary_id: "D0ZM8H",
    names: ["PMID25666693-Compound-18"],
  },
  {
    primary_id: "D0ZM9M",
    names: ["1-[4-(2-Oxo-2-Phenylethyl)Phenyl]guanidine", "SCHEMBL20553177", "DB07122"],
  },
  {
    primary_id: "D0ZN1R",
    names: ["Delphinidin"],
  },
  {
    primary_id: "D0ZN3M",
    names: ["PMID27998201-Compound-19"],
  },
  {
    primary_id: "D0ZO0D",
    names: ["Cannabinoid Quinone Derivative 1"],
  },
  {
    primary_id: "D0ZO1R",
    names: [
      "CIRSIMARIN",
      "Cirsimarin",
      "Cirsitakaoside",
      "13020-19-4",
      "CHEMBL517637",
      "2-(4-(Beta-D-Glucopyranosyloxy)Phenyl)-5-Hydroxy-6,7-Dimethoxy-4H-1-Benzopyran-4-One",
      "4H-1-Benzopyran-4-One, 2-(4-(Beta-D-Glucopyranosyloxy)Phenyl)-5-Hydroxy-6,7-Dimethoxy-",
      "AC1L4LVL",
      "AC1Q6CLV",
      "SCHEMBL2016369",
      "MolPort-019-937-034",
      "BDBM50250467",
      "ZINC31459932",
      "MCULE-1339456605",
      "4-(5-Hydroxy-6,7-Dimethoxy-4-Oxo-4h-Chromen-2-Yl)Phenyl",
      "LS-39681",
    ],
  },
  {
    primary_id: "D0ZO2Z",
    names: ["Lingliptin + Pioglitazone"],
  },
  {
    primary_id: "D0ZO3C",
    names: ["Cyclo(-D-Tyr-D-Arg-L-Arg-L-Nal-Gly-)", "CHEMBL219339"],
  },
  {
    primary_id: "D0ZO5K",
    names: ["MEDI0562"],
  },
  {
    primary_id: "D0ZO5L",
    names: ["Therapy, Gram-Negative Bacterial Infection"],
  },
  {
    primary_id: "D0ZO7A",
    names: ["Dorgenmeltucel-L"],
  },
  {
    primary_id: "D0ZO7D",
    names: ["PMID30247903-Compound-General Structure32"],
  },
  {
    primary_id: "D0ZO8Z",
    names: [
      "2-(3,4-Dimethoxybenzamido)Thiophene-3-Carboxamide",
      "CHEMBL210622",
      "MolPort-003-062-950",
      "ZINC4275736",
      "AC1N3474",
      "BDBM50185082",
      "AKOS024605336",
      "MCULE-1480938066",
      "SR-01000011723",
      "SR-01000011723-1",
      "F1358-0295",
      "2-[(3,4-Dimethoxybenzoyl)Amino]thiophene-3-Carboxamide",
    ],
  },
  {
    primary_id: "D0ZO9C",
    names: ["1,2-Diamino Cyclopentane-Based Derivative 28"],
  },
  {
    primary_id: "D0ZP2C",
    names: ["PMID25666693-Compound-105"],
  },
  {
    primary_id: "D0ZP2D",
    names: ["N-1-Isopropyl-5-MeOT"],
  },
  {
    primary_id: "D0ZP2E",
    names: ["HinsBet"],
  },
  {
    primary_id: "D0ZP3Q",
    names: ["Multikine"],
  },
  {
    primary_id: "D0ZP4B",
    names: ["RP-3035"],
  },
  {
    primary_id: "D0ZP5X",
    names: ["4-(1-Naphthalen-1-Yl-Propyl)-1H-Imidazole"],
  },
  {
    primary_id: "D0ZP7D",
    names: [
      "GenePro",
      "Delta4SHIVku2",
      "DNA Vaccine (HIV Promoter + HIV Component Protein-Encoding Genes), ImmunoGenetix",
    ],
  },
  {
    primary_id: "D0ZP8Z",
    names: ["CART22-65s Cells"],
  },
  {
    primary_id: "D0ZQ0X",
    names: ["PMID27336223-Compound-9"],
  },
  {
    primary_id: "D0ZQ0Y",
    names: ["ISIS 29237"],
  },
  {
    primary_id: "D0ZQ2W",
    names: ["PMID25666693-Compound-96"],
  },
  {
    primary_id: "D0ZQ3B",
    names: ["PMID2296027C29"],
  },
  {
    primary_id: "D0ZQ3K",
    names: ["Anti-CD22 CAR-T Cells"],
  },
  {
    primary_id: "D0ZQ3Q",
    names: [
      "1-(4-Ethyl-2,5-Dimethoxyphenyl)Propan-2-Amine",
      "2,5-Dimethoxy-4-Ethylamphetamine",
      "22004-32-6",
      "DOET",
      "CHEMBL8224",
      "NCGC00247691-01",
      "SCHEMBL1740704",
      "DOET,(-)",
      "AC1L1D54",
      "CTK4E8216",
      "BDBM81965",
      "HXJKWPGVENNMCC-UHFFFAOYSA-N",
      "4-Ethyl-2,5-Dimethoxyamphetamine",
      "PDSP2_000785",
      "CAS_62066",
      "PDSP1_001371",
      "PDSP2_001355",
      "PDSP1_000797",
      "NSC_62066",
      "DB01467",
      "ACM22004326",
      "2,5-Dimethoxy-4-Ethylamphetamine (''DOEt'')",
      "L001150",
      "Benzeneethanamine,4-Ethyl-2,5-Dimethoxy-A-Methyl-",
      "1-(4-Ethyl-2,5-Dimethoxyphenyl)-2-Propanamine #",
      "Phenethylamine, 4-Ethyl-2,5-Dimeth",
    ],
  },
  {
    primary_id: "D0ZQ6N",
    names: ["CAR-T Cells Targeting EGFRvIII"],
  },
  {
    primary_id: "D0ZQ7L",
    names: ["H-Dmt-Tic-(2R,3S)-Beta-MeCha-Phe-NH2", "CHEMBL218782"],
  },
  {
    primary_id: "D0ZQ7P",
    names: ["Sch-49210"],
  },
  {
    primary_id: "D0ZQ8T",
    names: ["PMID26394986-Compound-Figure16"],
  },
  {
    primary_id: "D0ZQ8Z",
    names: [
      "7-Methoxy-2-Morpholin-4-Yl-Chromen-4-One",
      "CHEMBL175459",
      "130735-66-9",
      "4H-1-Benzopyran-4-One, 7-Methoxy-2-(4-Morpholinyl)-",
      "ACMC-20mts5",
      "4H-1-Benzopyran-4-One,7-Methoxy-2-(4-Morpholinyl)-",
      "SCHEMBL3536694",
      "CTK0C1164",
      "DTXSID00466243",
      "BDBM50159634",
    ],
  },
  {
    primary_id: "D0ZR1P",
    names: ["Protein C Concentrate", "Ceprotin (TN)"],
  },
  {
    primary_id: "D0ZR1V",
    names: ["EM-1424", "CHEMBL490978"],
  },
  {
    primary_id: "D0ZR1Z",
    names: ["IMGN779"],
  },
  {
    primary_id: "D0ZR2O",
    names: ["Biaryl Mannoside Derivative 6"],
  },
  {
    primary_id: "D0ZR2T",
    names: [
      "5-(Trifluoromethoxy)-1H-Indole-2,3-Dione",
      "5-(Trifluoromethoxy)Isatin",
      "169037-23-4",
      "5-(TRIFLUOROMETHOXY)INDOLINE-2,3-DIONE",
      "5-Trifluoromethoxyisatin",
      "5-Trifluoromethoxy-1H-Indole-2,3-Dione",
      "1H-Indole-2,3-Dione, 5-(Trifluoromethoxy)-",
      "MFCD00192524",
      "5-(Trifluoromethoxy)-2,3-Indolinedione",
      "AK-33632",
      "5-(Trifluoromethoxy)Isatin, 97%",
      "AC1MBXBJ",
      "5-Trifluoromethoxy Isatin",
      "Buttpark 34\\07-90",
      "ACMC-209dz9",
      "Isatin-Based Compound, 36",
      "5-(Trifluoromethoxy)-Isatin",
      "KSC498C3D",
      "SCHEMBL457929",
      "CHEMBL116649",
      "KS-00000IZD",
      "BDBM22816",
    ],
  },
  {
    primary_id: "D0ZR2W",
    names: ["S-47445", "CX-1632", "AMPA Modulator (Cognitive Disorders), Cortex/Servier"],
  },
  {
    primary_id: "D0ZR3N",
    names: ["Pro 542"],
  },
  {
    primary_id: "D0ZR4M",
    names: [
      "4-Hydroxyphenethyl 3,4,5-Trihydroxybenzoate",
      "CHEMBL147069",
      "2-(4-Hydroxyphenyl)Ethyl 3,4,5-Trihydroxybenzoate",
      "AC1NQOM4",
      "SCHEMBL4738028",
      "Gallic Acid 4-Hydroxyphenethyl Ester",
      "BDBM50109690",
      "2-(4-Hydroxyphenyl) Ethyl 3,4,5-Trihydroxybenzoate",
      "3,4,5-Trihydroxy-Benzoic Acid 2-(4-Hydroxy-Phenyl)-Ethyl Ester",
      "Benzoic Acid, 3,4,5-Trihydroxy-, 2-(4-Hydroxyphenyl)Ethyl Ester",
    ],
  },
  {
    primary_id: "D0ZR4Q",
    names: ["SSS-08"],
  },
  {
    primary_id: "D0ZR4W",
    names: ["Nuedexta"],
  },
  {
    primary_id: "D0ZR6E",
    names: ["TVI-Brain-1 Cancer Vaccine"],
  },
  {
    primary_id: "D0ZR8T",
    names: ["MSB 11022"],
  },
  {
    primary_id: "D0ZS1I",
    names: ["MAX-10129"],
  },
  {
    primary_id: "D0ZS3E",
    names: ["PMID25666693-Compound-152"],
  },
  {
    primary_id: "D0ZS3F",
    names: [
      "6,7-Dimethoxy-4-(3-Nitro-Phenoxy)-Quinoline",
      "CHEMBL99272",
      "SCHEMBL7482913",
      "OMYGYNKHKZLDSH-UHFFFAOYSA-N",
      "BDBM50290844",
    ],
  },
  {
    primary_id: "D0ZS3M",
    names: ["Indol-1-Yl-Prop-2-Ynyl-Pyridin-4-Yl-Amine", "CHEMBL348034", "SCHEMBL7609255", "BDBM50048602"],
  },
  {
    primary_id: "D0ZS5F",
    names: ["Shikimate-3-Phosphate"],
  },
  {
    primary_id: "D0ZS5X",
    names: ["SHP610"],
  },
  {
    primary_id: "D0ZS6R",
    names: ["Technetium Tc-99m Apcitide", "Acutect"],
  },
  {
    primary_id: "D0ZS7M",
    names: ["ERYBREADIN B"],
  },
  {
    primary_id: "D0ZS8N",
    names: ["THPG", "(RS)-3,4,5-Trihydroxyphenylglycine"],
  },
  {
    primary_id: "D0ZS8P",
    names: [
      "Clomipramine",
      "Chlomipramine",
      "Chlorimipramine",
      "Clomicalm",
      "Clomipramina",
      "Clomipraminum",
      "Hydiphen",
      "Monochlorimipramine",
      "Anafranil Base",
      "G 34586",
      "Anafranil (TN)",
      "Anafranil (Free Base)",
      "Clomipramina [INN-Spanish]",
      "Clomipramine (INN)",
      "Clomipramine [INN:BAN]",
      "Clomipraminum [INN-Latin]",
      "10,11-Dihydro-3-Chloro-5-(3-(Dimethylamino)Propyl)-5H-Dibenz(B,f)Azepine",
      "3-(2-Chloro-5,6-Dihydrobenzo[b][1]benzazepin-11-Yl)-N,N-Dimethylpropan-1-Amine",
      "3-(3-Chloro-10,11-Dihydro-5H-Dibenzo[b,f]azepin-5-Yl)-N,N-Dimethyl-1-Propanamine",
      "3-(3-Chloro-10,11-Dihydro-5H-Dibenzo[b,f]azepin-5-Yl)-N,N-Dimethylpropan-1-Amine",
      "3-Chloro-5-(3-(Dimethylamino)Propyl)-10,11-Dihydro-5H-Dibenz(B,f)Azepine",
      "3-Chloro-5-[3-(Dimethylamino)Propyl]-10,11-Dihydro-5H-Dibenz[b,f]azepine",
      "3-Chloroimipramine",
      "3-Chloro-10,11-Dihydro-N,N-Dimethyl-5H-Dibenz(B,f)Azepine-5-Propanamine",
      "5H-Dibenz(B,f)Azepine, 3-Chloro-5-(3-(Dimethylamino)Propyl)-10,11-Dihydro-(8CI)",
    ],
  },
  {
    primary_id: "D0ZS8Q",
    names: ["PMID26394986-Compound-Figure17"],
  },
  {
    primary_id: "D0ZS8S",
    names: ["CT-750"],
  },
  {
    primary_id: "D0ZT0F",
    names: ["PMID7966163C6d"],
  },
  {
    primary_id: "D0ZT0G",
    names: [
      "Cinaciguat",
      "329773-35-5",
      "BAY 58-2667",
      "BAY582667",
      "UNII-59K0Y58UAD",
      "BAY-58-2667",
      "59K0Y58UAD",
      "4-({(4-Carboxybutyl)[2-(2-{[4-(2-Phenylethyl)Benzyl]oxy}phenyl)Ethyl]amino}methyl)Benzoic Acid",
      "Cinaciguat [INN:JAN]",
      "Cinaciguatum",
      "Z90",
      "Cinaciguat (JAN/INN)",
      "SCHEMBL249267",
      "GTPL5168",
      "CHEMBL1236936",
      "QCR-279",
      "Cinaciguat (BAY 58-2667)",
      "MolPort-039-139-611",
      "CHEBI:142433",
      "ZINC3934935",
      "BCP07942",
      "3550AH",
      "AKOS026750293",
      "AN-1845",
      "CS-1169",
      "HY-14181",
      "KB-40005",
      "AB0095741",
      "D07577",
      "W-5794",
    ],
  },
  {
    primary_id: "D0ZT1L",
    names: ["AP33"],
  },
  {
    primary_id: "D0ZT3U",
    names: ["Resorcinol Compound 29"],
  },
  {
    primary_id: "D0ZT4J",
    names: ["JSM 6427"],
  },
  {
    primary_id: "D0ZT4U",
    names: [
      "1-Fluoro-6-(4-Hydroxy-Phenyl)-Naphthalen-2-Ol",
      "CHEMBL192782",
      "SCHEMBL3925203",
      "VQATUPWMFYRKSD-UHFFFAOYSA-N",
      "BDBM50168380",
      "ZINC13645014",
    ],
  },
  {
    primary_id: "D0ZT5C",
    names: ["1,2-Bis(2,4-Difluorophenyl)-2-Hydroxyethanone", "CHEMBL244425"],
  },
  {
    primary_id: "D0ZT5T",
    names: ["NTHi-Pneumo Vaccine"],
  },
  {
    primary_id: "D0ZT8A",
    names: ["T3D-959"],
  },
  {
    primary_id: "D0ZT8D",
    names: ["TRAMAZOLINE"],
  },
  {
    primary_id: "D0ZT9D",
    names: [
      "Cochliobolic Acid",
      "CHEMBL464721",
      "185846-15-5",
      "AC1O516J",
      "BDBM50292386",
      "2-Furancarboxylic Acid, Tetrahydro-3-Hydroxy-5-(17-Methyl-13,14-Dioxo-1,3,5,7,9,11,15-Nonadecaheptaenyl)-, (2alpha,3beta,5alpha(1E,3E,5E,7E,9E,11E,15E))-(Partial)-",
      "(2R,3R,5S)-3-Hydroxy-5-[(1E,3E,5E,7E,9E,11E,15E)-17-Methyl-13,14-Dioxononadeca-1,3,5,7,9,11,15-Heptaenyl]oxolane-2-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0ZT9I",
    names: ["Benzimidazole Derivative 12"],
  },
  {
    primary_id: "D0ZT9N",
    names: ["PMID25772215-Compound-EP20082149552C9"],
  },
  {
    primary_id: "D0ZU0O",
    names: ["SEW2871"],
  },
  {
    primary_id: "D0ZU2X",
    names: ["K-510"],
  },
  {
    primary_id: "D0ZU3R",
    names: ["Carbamate Derivative 4"],
  },
  {
    primary_id: "D0ZU3S",
    names: ["PS-777621"],
  },
  {
    primary_id: "D0ZU4S",
    names: ["Trifluoromethionine"],
  },
  {
    primary_id: "D0ZU4U",
    names: ["MLR-1023"],
  },
  {
    primary_id: "D0ZU5F",
    names: ["SGX523"],
  },
  {
    primary_id: "D0ZU5K",
    names: ["SF-51"],
  },
  {
    primary_id: "D0ZU5W",
    names: [
      "1-(2-(Benzyloxy)Phenyl)Piperazine",
      "96221-84-0",
      "1-(2-Benzyloxy-Phenyl)-Piperazine",
      "CHEMBL582928",
      "1-[2-(Benzyloxy)Phenyl]piperazine",
      "SCHEMBL4673484",
      "1-(2-Benzyloxyphenyl)Piperazine",
      "CTK7D1689",
      "ZINC2527252",
      "BDBM50299797",
      "9395AH",
      "AKOS010941582",
      "AB19552",
      "AJ-37911",
      "DA-00144",
      "BBV-33344054",
      "KB-212806",
      "AX8291986",
      "FT-0752442",
      "Z-7800",
    ],
  },
  {
    primary_id: "D0ZU6O",
    names: [
      "T-1106",
      "AC1NRS1H",
      "SCHEMBL6384698",
      "CHEMBL261459",
      "XVXWUBIIHFDOJO-KQYNXXCUSA-N",
      "4-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Oxolan-2-Yl]-3-Oxopyrazine-2-Carboxamide",
      "4-((2R,3R,4S,5R)-3,4-Dihydroxy-5-Hydroxymethyl-Tetrahydro-Furan-2-Yl)-3-Oxo-3,4-Dihydro-Pyrazine-2-Carboxylic Acid Amide",
      "4-[(2R,3R,4S,5R)-3,4-Dihydroxy-5-(Hydroxymethyl)Tetrahydro-2-Furanyl]-3-Oxo-3,4-Dihydro-2-Pyrazinecarboxamide",
    ],
  },
  {
    primary_id: "D0ZU8L",
    names: ["PMID25991433-Compound-J5"],
  },
  {
    primary_id: "D0ZU9P",
    names: ["NAPIRIMUS"],
  },
  {
    primary_id: "D0ZU9R",
    names: ["Ritonavir"],
  },
  {
    primary_id: "D0ZU9T",
    names: ["NSC-134754"],
  },
  {
    primary_id: "D0ZU9Z",
    names: ["LY320135"],
  },
  {
    primary_id: "D0ZV0R",
    names: ["F-200"],
  },
  {
    primary_id: "D0ZV0Z",
    names: [
      "Moxifloxacin",
      "Avelox",
      "MFX",
      "MXF",
      "MXFX",
      "Vigamox",
      "Avalox (TN)",
      "Avelon (TN)",
      "Avelox (TN)",
      "Moxifloxacin [INN:BAN]",
      "Vigamox (TN)",
      "Avelox I.V.",
      "Actira (*Hydrochloride*)",
      "Avelox (*Hydrochloride*)",
      "(1'S,6'S)-1-Cyclopropyl-7-(2,8-Diazabicyclo[4.3.0]non-8-Yl)-6-Fluoro-8-Methoxy-4-Oxo-1,4-Dihydroquinoline-3-Carboxylic Acid",
      "1-Cyclopropyl-6-Fluoro-1,4-Dihydro-8-Methoxy-7-((4aS,7aS)-Octahydro-6H-Pyrrolo(3,4-B)Pyridin-6-Yl)-4-Oxo-3-Quinolinecarboxylic Acid",
      "7-[(4aS,7aS)-1,2,3,4,4a,5,7,7a-Octahydropyrrolo[3,4-B]pyridin-6-Yl]-1-Cyclopropyl-6-Fluoro-8-Methoxy-4-Oxoquinoline-3-Carboxylic Acid",
    ],
  },
  {
    primary_id: "D0ZV1F",
    names: ["Ro-09-1227"],
  },
  {
    primary_id: "D0ZV1T",
    names: [
      "N-(2,6-Diphenylpyrimidin-4-Yl)-2-Ethylbutyramide",
      "CHEMBL390535",
      "820961-46-4",
      "SCHEMBL4827158",
      "CTK3E2657",
      "DTXSID00463914",
      "BDBM50157671",
      "AKOS030569389",
      "Butanamide, N-(2,6-Diphenyl-4-Pyrimidinyl)-2-Ethyl-",
    ],
  },
  {
    primary_id: "D0ZV3Y",
    names: ["[Mpa1, D-Tyr(Et)2, D-Tic7, Aib9]OT", "CHEMBL438849"],
  },
  {
    primary_id: "D0ZV4I",
    names: ["HL-020"],
  },
  {
    primary_id: "D0ZV6F",
    names: ["Imidazo[1,5-A]pyridine Derivative 1"],
  },
  {
    primary_id: "D0ZV6W",
    names: ["Albupoietin"],
  },
  {
    primary_id: "D0ZV7Y",
    names: ["MorphPlus"],
  },
  {
    primary_id: "D0ZV9P",
    names: ["Pyrido[4,3-D]pyrimidin-5(6H)-One Derivative 1"],
  },
  {
    primary_id: "D0ZW1W",
    names: ["SoluMatrix Naproxen"],
  },
  {
    primary_id: "D0ZW2F",
    names: ["NSC-119910"],
  },
  {
    primary_id: "D0ZW2I",
    names: ["RVT-104"],
  },
  {
    primary_id: "D0ZW3R",
    names: ["TG-4010"],
  },
  {
    primary_id: "D0ZW4W",
    names: [
      "(+)-JQ1",
      "1268524-70-4",
      "(+)-JQ-1",
      "JQ1 Compound",
      "JQ-1",
      "UNII-1MRH0IMX0W",
      "(S)-Tert-Butyl 2-(4-(4-Chlorophenyl)-2,3,9-TriMethyl-6H-Thieno[3,2-F][1,2,4]triazolo[4,3-A][1,4]diazepin-6-Yl)Acetate",
      "(S)-JQ1",
      "1MRH0IMX0W",
      "JQ1",
      "CHEMBL1957266",
      "AK109409",
      "(S)-(+)-Tert-Butyl 2-(4-(4-Chlorophenyl)-2,3,9-Trimethyl-6H-Thieno[3,2-F][1,2,4]triazolo[4,3-A][1,4]diazepin-6-Yl)Acetate",
      "(S)-(+)-Tert-Butyl 2-(4-(4-Chlorophenyl)-2,3,9-Trimethyl-6H-Thieno(3,2-F)(1,2,4)Triazolo(4,3-A)(1,4)Diazepin-6-Yl)Acetate",
      "(S)-Tert-Butyl 2-(4-(4-Chlorophenyl)-2,3",
    ],
  },
  {
    primary_id: "D0ZW5B",
    names: ["Azaindazole Derivative 4"],
  },
  {
    primary_id: "D0ZW5D",
    names: ["LY-2409021"],
  },
  {
    primary_id: "D0ZW5H",
    names: ["2,2-Bipyridyl Derivative 4"],
  },
  {
    primary_id: "D0ZW5Y",
    names: ["Recombinant Pox Virus Vaccine"],
  },
  {
    primary_id: "D0ZW6X",
    names: ["Anti-GITR Antibody"],
  },
  {
    primary_id: "D0ZW8B",
    names: ["Human Anti-Rabies Mab"],
  },
  {
    primary_id: "D0ZW8E",
    names: ["L-Isoleucyl-L-Prolinamide"],
  },
  {
    primary_id: "D0ZW9L",
    names: ["5,7-Diphenyl-3H-Imidazo[4,5-B]pyridin-2-Ol", "CHEMBL374938", "SCHEMBL14164225", "BDBM50205282"],
  },
  {
    primary_id: "D0ZW9O",
    names: ["Peptide Analog 62"],
  },
  {
    primary_id: "D0ZX0C",
    names: ["Tetanus Vaccine"],
  },
  {
    primary_id: "D0ZX0Y",
    names: [
      "4-Hexylphenyl Propiolate",
      "4-Hexylphenyl Prop-2-Ynoate",
      "Propiolic Acid Derivative, 8b",
      "CHEMBL236338",
      "BDBM18854",
    ],
  },
  {
    primary_id: "D0ZX1E",
    names: ["CNT0-5825"],
  },
  {
    primary_id: "D0ZX1P",
    names: [
      "FADROZOLE",
      "102676-47-1",
      "CGS-16949A",
      "Fadrozole [INN]",
      "Fadrozolum [INN-Latin]",
      "Fadrozol [INN-Spanish]",
      "CHEMBL9298",
      "Fadrozole (INN)",
      "5-P-Cyanophenyl-5,6,7,8-Tetrahydroimidazo[1,5-A]pyridine",
      "Benzonitrile, 4-(5,6,7,8-Tetrahydroimidazo(1,5-A)Pyridin-5-Yl)-",
      "Fadrozolum",
      "Fadrozol",
      "4-{5H,6H,7H,8H-Imidazo[1,5-A]pyridin-5-Yl}benzonitrile",
      "Benzonitrile, 4-(5,6,7,8-Tetrahydroimidazo[1,5-A]pyridin-5-Yl)-",
    ],
  },
  {
    primary_id: "D0ZX2D",
    names: ["11C-6-Me-BTA-1", "Carbon-11-6-Me-BTA-1", "11C-BTA-1"],
  },
  {
    primary_id: "D0ZX2G",
    names: [
      "Hexachlorophene",
      "Acigena",
      "Almederm",
      "Armohex",
      "Bilevon",
      "Bivelon",
      "Cotofilm",
      "Dermadex",
      "Distodin",
      "Eleven",
      "Esaclorofene",
      "Exofene",
      "Fascol",
      "Fomac",
      "Fostril",
      "Gamophen",
      "Gamophene",
      "Hexabalm",
      "Hexachlorofen",
      "Hexachlorophane",
      "Hexachlorophen",
      "Hexachlorophenum",
      "Hexaclorofeno",
      "Hexafen",
      "Hexascrub",
      "Hexide",
      "Hexophene",
      "Hexosan",
      "Isobac",
      "Nabac",
      "PHisoHex",
      "Phisodan",
      "Ritosept",
      "Septisol",
      "Septofen",
      "Steral",
      "Steraskin",
      "Surofene",
      "TRICHLOROPHENE",
      "Tersaseptic",
      "Trisophen",
      "Turgex",
      "Blockade Anti Bacterial Finish",
      "Brevity Blue Liquid Bacteriostatic Scouring Cream",
      "Brevity Blue Liquid Sanitizing Scouring Cream",
      "Enditch Pet Shampoo",
      "Esaclorofene [DCIT]",
      "Fo Stril",
      "Hexachlorofen [Czech]",
      "Hexachlorophene [INN]",
      "Hilo Cat Flea Powder",
      "Hilo Flea Powder",
      "Neosept V",
      "Pedigree Dog Shampoo Bar",
      "Scrubteam Surgical Spongebrush",
      "Staphene O",
      "AT 7",
      "B 32",
      "H3P",
      "Hexachlorophene [UN2875] [Poison]",
      "KUC106447N",
      "M0219",
      "Nabac 25 Ec",
      "AT-7",
      "AT17 (TN)",
      "AT7 (TN)",
      "Acigena (TN)",
      "Almederm (TN)",
      "At-17",
      "B & B Flea Kontroller For Dogs Only",
      "B 32 (VAN)",
      "Bilevon (TN)",
      "E-Z Scrub",
      "En-Viron D Concentrated Phenolic Disinfectant",
      "Exofene (TN)",
      "Fesia-Sin",
      "Fostril (TN)",
      "G-11",
      "G-Eleven",
      "G-II",
      "Gamophen (TN)",
      "Germa-Medica",
      "Hexa-Germ",
      "Hexachlorophene, Pharma",
      "Hexachlorophenum [INN-Latin]",
      "Hexaclorofeno [INN-Spanish]",
      "Hexaphene-LV",
      "Hexosan (TN)",
      "PRE-OP II",
      "Phiso-Scrub",
      "Phisohex(TN)",
      "Pre-Op",
      "Septi-Soft",
      "Septisol (TN)",
      "Solu-Heks",
      "Soy-Dome",
      "Ster-Zac",
      "Surgi-Cen",
      "Surgi-Cin",
      "Surofene (TN)",
      "Thera-Groom Pet Shampoo For Dogs For Veterinary Use Only",
      "G-11 (TN)",
      "GERMA-MEDICA (MG)",
      "Germa-Medica (TN)",
      "Hexachlorophene (USP/INN)",
      "KSC-19-051",
      "Methylenebis(3,4,6-Trichlorophenol)",
      "Bis(2,3,5-Tric Hloro-6-Hydroxyphenyl)Methane",
      "Bis(2,3,5-Trichloro-6-Hydroxyphenyl)Methane",
      "Bis(2-Hydroxy-3,5,6-Trichlorophenyl)Methane",
      "Bis(3,5,6-Trichloro-2-Hydroxyphenyl)Methane",
      "Bis-2,3,5-Trichlor-6-Hydroxyfenylmethan",
      "Bis-2,3,5-Trichloro-6-Hydroxyfenylmethan",
      "Bis-2,3,5-Trichloro-6-Hydroxyfenylmethan [Czech]",
      "Bis(3,5,6-Trichloro-2-Hydroxyphenyl)-Methane",
      "Methane, Bis(2,3,5-Trichloro-6-Hydroxyphenyl)",
      "2,2',3,3',5,5'-Hexachloro-6,6'-Dihydroxydiphenylmethane",
      "2,2'-Dihydroxy-3,5,6,3',5',6'-Hexachlorodiphenylmethane",
      "2,2'-Methylene Bis(3,4,6-Trichlorophenol)",
      "2,2'-Methylenebis(3,4,6-Trichlorophenol)",
      "2,2'-Methylenebis[3,4,6-Trichlorophenol]",
      "2,2'-Methanediylbis(3,4,6-Trichlorophenol)",
      "2,2-Methylene-Bis-[3,4,6-Trichlorophenol]",
      "3,3',5,5',6,6'-Hexachloro-2,2'-Dihydroxydiphenylmethane",
      "3,4,6-Trichloro-2-[(2,3,5-Trichloro-6-Hydroxyphenyl)Methyl]phenol",
    ],
  },
  {
    primary_id: "D0ZX2L",
    names: ["GSK2831781"],
  },
  {
    primary_id: "D0ZX5D",
    names: [
      "DNA Gyrase Inhibitors",
      "DNA Gyrase Inhibitors (Gram-Positive Bacteria Infection)",
      "DNA Gyrase Inhibitors (Gram-Positive Bacteria Infection), AstraZeneca",
    ],
  },
  {
    primary_id: "D0ZX5I",
    names: ["MTOR Inhibitors (Cancer), Amgen"],
  },
  {
    primary_id: "D0ZX6S",
    names: ["PMID26004420-Compound-WO2013028474A"],
  },
  {
    primary_id: "D0ZX7I",
    names: ["SR-57227"],
  },
  {
    primary_id: "D0ZX8C",
    names: ["PMID27967267-Compound-28"],
  },
  {
    primary_id: "D0ZY0Z",
    names: ["ABT-560"],
  },
  {
    primary_id: "D0ZY2T",
    names: ["PO3 2-Leu-Nal-O-3K", "CHEMBL280917"],
  },
  {
    primary_id: "D0ZY3C",
    names: ["LOPHOCLADINE A"],
  },
  {
    primary_id: "D0ZY3E",
    names: ["INO-1001"],
  },
  {
    primary_id: "D0ZY3R",
    names: ["MEDI8111"],
  },
  {
    primary_id: "D0ZY4C",
    names: ["N-Phenethyl-2-(Pyrrolidin-1-Yl)Pyrimidin-4-Amine", "CHEMBL1082978", "BDBM50319973"],
  },
  {
    primary_id: "D0ZY4T",
    names: ["Aryl Pyrimidine Derivative 8"],
  },
  {
    primary_id: "D0ZY5M",
    names: ["Indole Carboxamide Derivative 1"],
  },
  {
    primary_id: "D0ZY6C",
    names: ["Diaryl Piperazine Derivative 1"],
  },
  {
    primary_id: "D0ZY7I",
    names: ["MAPKAP Kinase 2 Inhibitors"],
  },
  {
    primary_id: "D0ZY7X",
    names: ["GNF-PF-2094"],
  },
  {
    primary_id: "D0ZY8G",
    names: ["NCFP"],
  },
  {
    primary_id: "D0ZY8H",
    names: ["HI-280"],
  },
  {
    primary_id: "D0ZY8V",
    names: ["4-Butyl-1-[3-(Phenylpropoxy)Propyl]piperidine", "CHEMBL460309"],
  },
  {
    primary_id: "D0ZY9U",
    names: ["SLV-305"],
  },
  {
    primary_id: "D0ZZ2L",
    names: ["BS 9106", "CHEMBL206647", "CHEMBL1790047"],
  },
  {
    primary_id: "D0ZZ3P",
    names: ["Antiadhesion Barrier Gel"],
  },
  {
    primary_id: "D0ZZ3Q",
    names: ["AOB101"],
  },
  {
    primary_id: "D0ZZ3U",
    names: ["Voltage-Gated Sodium Channel Blockers"],
  },
  {
    primary_id: "D0ZZ4S",
    names: ["1-Methoxy-9-Aminomethyl-9,10-Dihydroanthracene", "CHEMBL497156", "BDBM50264948"],
  },
  {
    primary_id: "D0ZZ4Z",
    names: ["3-(2-Pyrrolidin-1-Yl-Ethyl)-1H-Indol-4-Ol", "CHEMBL364061", "BDBM50171265", "AKOS022506696"],
  },
  {
    primary_id: "D0ZZ6C",
    names: ["EB-001A"],
  },
  {
    primary_id: "D0ZZ6N",
    names: ["Palmarumycin Derivative 3"],
  },
  {
    primary_id: "D0ZZ8F",
    names: ["PMID25666693-Compound-68"],
  },
  {
    primary_id: "D0ZZ9M",
    names: [
      "AF150(S)",
      "SCHEMBL1328277",
      "AF-150S",
      "VGGGBQVTSUMURJ-UHFFFAOYSA-N",
      "AF 150(S)",
      "AF-150(S)",
      "L007073",
      "1-Methylpiperidine-4-Spiro-(2'-Methylthiazoline)",
      "2,8-Dimethyl-1-Thia-3,8-Diaza-Spiro[4.5]dec-2-Ene",
      "1-Thia-3,8-Diazaspiro(4.5)Dec-2-Ene, 2,8-Dimethyl-",
    ],
  },
];
