interface ROA {
  art_administration_id: string;
  name: string;
  keywords: string[];
  mesh_description: string;
  fda_description: string;
  fda_codes: string[];
  nci_ids: string[];
  mesh_uis: string[];
}

export const roas: ROA[] = [
  {
    art_administration_id: "ADMC6CADA3D7B",
    name: "biolistics",
    keywords: ["biolistics"],
    mesh_description:
      "Techniques where DNA is delivered directly into organelles at high speed using projectiles coated with nucleic acid, shot from a helium-powered gun (gene gun). One of these techniques involves immunization by DNA VACCINES, which delivers DNA-coated gold beads to the epidermis.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D019470"],
  },
  {
    art_administration_id: "ADM74CC0C3C5E",
    name: "buccal",
    keywords: ["buccal"],
    mesh_description:
      "Administration of a soluble dosage form between the cheek and gingiva. It may involve direct application of a drug onto the buccal mucosa, as by painting or spraying.",
    fda_description: "Administration directed toward the cheek, generally from within the mouth.",
    fda_codes: ["030"],
    nci_ids: ["C38193"],
    mesh_uis: ["D000278"],
  },
  {
    art_administration_id: "ADMB081A4A1C8",
    name: "chemotherapy",
    keywords: ["chemotherapy"],
    mesh_description:
      "Neoplasm drug therapy involving an extracorporeal circuit with temporary exclusion of the tumor-bearing area from the general circulation during which high concentrations of the drug are perfused to the isolated part.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D010478"],
  },
  {
    art_administration_id: "ADMBD32C06D30",
    name: "conjunctival",
    keywords: ["conjunctival"],
    mesh_description: "",
    fda_description:
      "Administration to the conjunctiva, the delicate membrane that lines the eyelids and covers the exposed surface of the eyeball.",
    fda_codes: ["068"],
    nci_ids: ["C38194"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM5B9F5EC38B",
    name: "cutaneous",
    keywords: ["cutaneous"],
    mesh_description: "The application of suitable drug dosage forms to the skin for either local or systemic effects.",
    fda_description: "Administration to the skin.",
    fda_codes: ["130"],
    nci_ids: ["C38675"],
    mesh_uis: ["D000279"],
  },
  {
    art_administration_id: "ADM1FD36A58A6",
    name: "dental",
    keywords: ["dental"],
    mesh_description: "",
    fda_description: "Administration to a tooth or teeth.",
    fda_codes: ["038"],
    nci_ids: ["C38197"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADME7373AEDF3",
    name: "electroosmosis",
    keywords: ["electroosmosis", "electro-osmosis"],
    mesh_description: "",
    fda_description: "Administration of through the diffusion of substance through a membrane in an electric field.",
    fda_codes: ["357"],
    nci_ids: ["C38633"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM42CE3DCAE2",
    name: "endocervical",
    keywords: ["endocervical", "endo-cervical"],
    mesh_description: "",
    fda_description: "Administration within the canal of the cervix uteri.  Synonymous with the term intracervical..",
    fda_codes: ["131"],
    nci_ids: ["C38205"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMCEFD18D5C3",
    name: "endosinusial",
    keywords: ["endosinusial", "endo-sinusial"],
    mesh_description: "",
    fda_description: "Administration within the nasal sinuses of the head.",
    fda_codes: ["133"],
    nci_ids: ["C38206"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMFB6EC1A309",
    name: "endotracheal",
    keywords: ["endotracheal", "endo-tracheal"],
    mesh_description: "",
    fda_description: "Administration directly into the trachea.",
    fda_codes: ["401"],
    nci_ids: ["C38208"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM3F71FCB9D1",
    name: "enteral",
    keywords: ["enteral"],
    mesh_description: "",
    fda_description: "Administration directly into the intestines.",
    fda_codes: ["313"],
    nci_ids: ["C38209"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM3EEBE1CC1F",
    name: "epidural",
    keywords: ["epidural"],
    mesh_description:
      "The injection of autologous blood into the epidural space either as a prophylactic treatment immediately following an epidural puncture or for treatment of headache as a result of an epidural puncture.",
    fda_description: "Administration upon or over the dura mater.",
    fda_codes: ["009"],
    nci_ids: ["C38210"],
    mesh_uis: ["D007268", "D017217"],
  },
  {
    art_administration_id: "ADM2DA3568B01",
    name: "extraamniotic",
    keywords: ["extraamniotic", "extra-amniotic"],
    mesh_description: "",
    fda_description: "Administration to the outside of the membrane enveloping the fetus",
    fda_codes: ["402"],
    nci_ids: ["C38211"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADME86BC03BFE",
    name: "extracorporeal",
    keywords: ["extracorporeal", "extra-corporeal"],
    mesh_description: "",
    fda_description: "Administration outside of the body.",
    fda_codes: ["057"],
    nci_ids: ["C38212"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM13C6AC8F04",
    name: "hemodialysis",
    keywords: ["hemodialysis"],
    mesh_description: "",
    fda_description: "Administration through hemodialysate fluid.",
    fda_codes: ["140"],
    nci_ids: ["C38200"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM860667FE06",
    name: "hypodermoclysis",
    keywords: ["hypodermoclysis"],
    mesh_description:
      "Technique for treating DEHYDRATION and WATER-ELECTROLYTE IMBALANCE by subcutaneous infusion of REHYDRATION SOLUTIONS.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D055103"],
  },
  {
    art_administration_id: "ADMB673C90C16",
    name: "infiltration",
    keywords: ["infiltration"],
    mesh_description: "",
    fda_description: "Administration that results in substances passing into tissue spaces or into cells.",
    fda_codes: ["361"],
    nci_ids: ["C38215"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM05EE66D581",
    name: "inhalation",
    keywords: ["respiratory", "inhalation"],
    mesh_description:
      "The administration of drugs by the respiratory route. It includes insufflation into the respiratory tract.",
    fda_description:
      "Administration within the respiratory tract by inhaling orally or nasally for local or systemic effect.",
    fda_codes: ["136"],
    nci_ids: ["C38216"],
    mesh_uis: ["D000280"],
  },
  {
    art_administration_id: "ADMCC72ED96BD",
    name: "injection",
    keywords: ["injection"],
    mesh_description: "Introduction of substances into the body using a needle and syringe.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D007267"],
  },
  {
    art_administration_id: "ADM1A163EE5A0",
    name: "interstitial",
    keywords: ["interstitial", "inter-stitial"],
    mesh_description: "",
    fda_description: "Administration to or in the interstices of a tissue.",
    fda_codes: ["088"],
    nci_ids: ["C38219"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM16B1D22591",
    name: "instillation",
    keywords: ["instillation"],
    mesh_description:
      "The administration of therapeutic agents drop by drop, as eye drops, ear drops, or nose drops. It is also administered into a body space or cavity through a catheter. It differs from THERAPEUTIC IRRIGATION in that the irrigate is removed within minutes, but the instillate is left in place.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D007322"],
  },
  {
    art_administration_id: "ADM7B13C669C6",
    name: "intraabdominal",
    keywords: ["intraabdominal", "intra-abdominal"],
    mesh_description: "",
    fda_description: "Administration within the abdomen.",
    fda_codes: ["056"],
    nci_ids: ["C38220"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM7233B8251F",
    name: "intraamniotic",
    keywords: ["intraamniotic", "intra-amniotic"],
    mesh_description: "",
    fda_description: "Administration within the amnion.",
    fda_codes: ["060"],
    nci_ids: ["C38221"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM79B93D96B9",
    name: "intraarterial",
    keywords: ["intraarterial", "intra-arterial"],
    mesh_description:
      "Regional infusion of drugs via an arterial catheter. Often a pump is used to impel the drug through the catheter. Used in therapy of cancer, upper gastrointestinal hemorrhage, infection, and peripheral vascular disease.",
    fda_description: "Administration within an artery or arteries.",
    fda_codes: ["037"],
    nci_ids: ["C38222"],
    mesh_uis: ["D007269", "D007261"],
  },
  {
    art_administration_id: "ADME6ACFF6BFA",
    name: "intraarticular",
    keywords: ["intraarticular", "intra-articular"],
    mesh_description: "Methods of delivering drugs into a joint space.",
    fda_description: "Administration within a joint.",
    fda_codes: ["007"],
    nci_ids: ["C38223"],
    mesh_uis: ["D007270"],
  },
  {
    art_administration_id: "ADMC58B4CA4BA",
    name: "intrabiliary",
    keywords: ["intrabiliary", "intra-biliary"],
    mesh_description: "",
    fda_description: "Administration within the bile, bile ducts or gallbladder.",
    fda_codes: ["362"],
    nci_ids: ["C38224"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM72FA520392",
    name: "intrabronchial",
    keywords: ["intrabronchial", "intra-bronchial"],
    mesh_description: "",
    fda_description: "Administration within a bronchus.",
    fda_codes: ["067"],
    nci_ids: ["C38225"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM1525CFD2B2",
    name: "intrabursal",
    keywords: ["intrabursal", "intra-bursal"],
    mesh_description: "",
    fda_description: "Administration within a bursa.",
    fda_codes: ["025"],
    nci_ids: ["C38226"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMEB6C70AE79",
    name: "intracardiac",
    keywords: ["intracardiac", "intra-cardiac"],
    mesh_description: "",
    fda_description: "Administration with the heart.",
    fda_codes: ["027"],
    nci_ids: ["C38227"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM03EAEB651B",
    name: "intracartilaginous",
    keywords: ["intracartilaginous", "intra-cartilaginous"],
    mesh_description: "",
    fda_description: "Administration within a cartilage; endochondral.",
    fda_codes: ["363"],
    nci_ids: ["C38228"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM248F28A419",
    name: "intracaudal",
    keywords: ["intracaudal", "intra-caudal"],
    mesh_description: "",
    fda_description: "Administration within the cauda equina.",
    fda_codes: ["413"],
    nci_ids: ["C38229"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMD6C5DA001E",
    name: "intracavernous",
    keywords: ["intracavernous", "intra-cavernous"],
    mesh_description: "",
    fda_description: "Administration within a pathologic cavity, such as  occurs in the lung in tuberculosis.",
    fda_codes: ["132"],
    nci_ids: ["C38230"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMA0EDCD054D",
    name: "intracavitary",
    keywords: ["intracavitary", "intra-cavitary"],
    mesh_description: "",
    fda_description:
      "Administration within a non-pathologic cavity, such as that of the cervix, uterus, or penis, or such as that which is formed as the result of a wound.",
    fda_codes: ["023"],
    nci_ids: ["C38231"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMCBA7C86A21",
    name: "intracerebral",
    keywords: ["intracerebral", "intra-cerebral"],
    mesh_description: "",
    fda_description: "Administration within the cerebrum.",
    fda_codes: ["404"],
    nci_ids: ["C38232"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM502C8141CD",
    name: "intracisternal",
    keywords: ["intracisternal", "intra-cisternal"],
    mesh_description: "",
    fda_description: "Administration within the cisterna magna cerebellomedularis.",
    fda_codes: ["405"],
    nci_ids: ["C38233"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM93787D0E9C",
    name: "intracorneal",
    keywords: ["intracorneal", "intra-corneal"],
    mesh_description: "",
    fda_description:
      "Administration within the cornea (the transparent structure forming the anterior part of the fibrous tunic of the eye).",
    fda_codes: ["406"],
    nci_ids: ["C38234"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM7B263ECC85",
    name: "intra-coronal",
    keywords: ["intracoronal", "intra-coronal", "dental"],
    mesh_description: "",
    fda_description:
      "Administration of a drug within a portion of a tooth which is covered by enamel and which is separated from the roots by a slightly constricted region known as the neck.",
    fda_codes: ["117"],
    nci_ids: ["C38217"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM7FE9B87F82",
    name: "intracoronary",
    keywords: ["intracoronary", "intra-coronary"],
    mesh_description: "",
    fda_description: "Administration within the coronary arteries.",
    fda_codes: ["119"],
    nci_ids: ["C38218"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM0200B6C3F4",
    name: "intracorporus",
    keywords: ["intracorporus", "intra-corporus", "cavernosum"],
    mesh_description: "",
    fda_description: "Administration within the dilatable spaces of the corporus cavernosa of the penis.",
    fda_codes: ["403"],
    nci_ids: ["C38235"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMBF6CC02EBA",
    name: "intradermal",
    keywords: ["intradermal", "intra-dermal"],
    mesh_description:
      "The forcing into the skin of liquid medication, nutrient, or other fluid through a hollow needle, piercing the top skin layer.",
    fda_description: "Administration within the dermis.",
    fda_codes: ["008"],
    nci_ids: ["C38238"],
    mesh_uis: ["D007271"],
  },
  {
    art_administration_id: "ADMFD56099930",
    name: "intradiscal",
    keywords: ["intradiscal", "intra-discal"],
    mesh_description: "",
    fda_description: "Administration within a disc.",
    fda_codes: ["121"],
    nci_ids: ["C38239"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMB8ADDBAF6D",
    name: "intraductal",
    keywords: ["intraductal", "intra-ductal"],
    mesh_description: "",
    fda_description: "Administration within the duct of a gland.",
    fda_codes: ["123"],
    nci_ids: ["C38240"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM46A5F8A75C",
    name: "intraduodenal",
    keywords: ["intraduodenal", "intra-duodenal"],
    mesh_description: "",
    fda_description: "Administration within the duodenum.",
    fda_codes: ["047"],
    nci_ids: ["C38241"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMDC9903D164",
    name: "intradural",
    keywords: ["intradural", "intra-dural"],
    mesh_description: "",
    fda_description: "Administration within or beneath the dura.",
    fda_codes: ["052"],
    nci_ids: ["C38242"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMC36032C111",
    name: "intraepidermal",
    keywords: ["intraepidermal", "intra-epidermal"],
    mesh_description: "",
    fda_description: "Administration within the epidermis.",
    fda_codes: ["127"],
    nci_ids: ["C38243"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM5FD3B05553",
    name: "intraesophageal",
    keywords: ["intraesophageal", "intra-esophageal"],
    mesh_description: "",
    fda_description: "Administration within the esophagus.",
    fda_codes: ["072"],
    nci_ids: ["C38245"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM9A6C7A7A69",
    name: "intragastric",
    keywords: ["intragastric", "intra-gastric"],
    mesh_description: "",
    fda_description: "Administration within the stomach.",
    fda_codes: ["046"],
    nci_ids: ["C38246"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMDB000466DB",
    name: "intragingival",
    keywords: ["intragingival", "intra-gingival"],
    mesh_description: "",
    fda_description: "Administration within the gingivae.",
    fda_codes: ["307"],
    nci_ids: ["C38247"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMFF75D52A1D",
    name: "intraileal",
    keywords: ["intraileal", "intra-ileal"],
    mesh_description: "",
    fda_description: "Administration within the distal portion of the small intestine, from the jejunum to the cecum.",
    fda_codes: ["365"],
    nci_ids: ["C38249"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM23F0777399",
    name: "intralesional",
    keywords: ["intralesional", "intra-lesional"],
    mesh_description:
      "The administration of medication or fluid directly into localized lesions, by means of gravity flow or INFUSION PUMPS.",
    fda_description: "Administration within or introduced directly into a localized lesion.",
    fda_codes: ["042"],
    nci_ids: ["C38250"],
    mesh_uis: ["D015552", "D036502"],
  },
  {
    art_administration_id: "ADM95E6D8BD55",
    name: "intraluminal",
    keywords: ["intraluminal", "intra-luminal"],
    mesh_description: "",
    fda_description: "Administration within the lumen of a tube.",
    fda_codes: ["310"],
    nci_ids: ["C38251"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM6E14DCD01F",
    name: "intralymphatic",
    keywords: ["intralymphatic", "intra-lymphatic"],
    mesh_description: "Injections into the lymph nodes or the lymphatic system.",
    fda_description: "Administration within the lymph.",
    fda_codes: ["352"],
    nci_ids: ["C38252"],
    mesh_uis: ["D007272"],
  },
  {
    art_administration_id: "ADM4C753453A9",
    name: "intramedullary",
    keywords: ["intramedullary", "intra-medullary"],
    mesh_description: "",
    fda_description: "Administration within the marrow cavity of a bone.",
    fda_codes: ["408"],
    nci_ids: ["C38253"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM3894AA8773",
    name: "intrameningeal",
    keywords: ["intrameningeal", "intra-meningeal"],
    mesh_description: "",
    fda_description:
      "Administration within the meninges (the three membranes that envelope the brain and spinal cord).",
    fda_codes: ["409"],
    nci_ids: ["C38254"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMCFB707310E",
    name: "intramuscular",
    keywords: ["intramuscular", "intra-muscular"],
    mesh_description:
      "Forceful administration into a muscle of liquid medication, nutrient, or other fluid through a hollow needle piercing the muscle and any tissue covering it.",
    fda_description: "Administration within a muscle.",
    fda_codes: ["005"],
    nci_ids: ["C28161"],
    mesh_uis: ["D007273"],
  },
  {
    art_administration_id: "ADM95D8C7CB34",
    name: "intraocular",
    keywords: ["intraocular", "intra-ocular"],
    mesh_description: "The administration of substances into the eye with a hypodermic syringe.",
    fda_description: "Administration within the eye.",
    fda_codes: ["036"],
    nci_ids: ["C38255"],
    mesh_uis: ["D056965"],
  },
  {
    art_administration_id: "ADMAB77F6B0FB",
    name: "intraosseous",
    keywords: ["intraosseous"],
    mesh_description:
      "The administration of medication or fluid through a needle directly into the bone marrow. The technique is especially useful in the management of pediatric emergencies when intravenous access to the systemic circulation is difficult.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D017148"],
  },
  {
    art_administration_id: "ADMC383A67C6D",
    name: "intraovarian",
    keywords: ["intraovarian", "intra-ovarian"],
    mesh_description: "",
    fda_description: "Administration within the ovary.",
    fda_codes: ["354"],
    nci_ids: ["C38256"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADME3E8F3BCBA",
    name: "intrapericardial",
    keywords: ["intrapericardial", "intra-pericardial"],
    mesh_description: "",
    fda_description: "Administration within the pericardium.",
    fda_codes: ["314"],
    nci_ids: ["C38257"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMB360EB4203",
    name: "intraperitoneal",
    keywords: ["intraperitoneal", "intra-peritoneal"],
    mesh_description:
      "Forceful administration into the peritoneal cavity of liquid medication, nutrient, or other fluid through a hollow needle piercing the abdominal wall.",
    fda_description: "Administration within the peritoneal cavity.",
    fda_codes: ["004"],
    nci_ids: ["C38258"],
    mesh_uis: ["D007274"],
  },
  {
    art_administration_id: "ADMFD56811EE4",
    name: "intrapleural",
    keywords: ["intrapleural", "intra-pleural"],
    mesh_description: "",
    fda_description: "Administration within the pleura.",
    fda_codes: ["043"],
    nci_ids: ["C38259"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM2489A00C17",
    name: "intraprostatic",
    keywords: ["intraprostatic", "intra-prostatic"],
    mesh_description: "",
    fda_description: "Administration within the prostate gland.",
    fda_codes: ["061"],
    nci_ids: ["C38260"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM89499E4C71",
    name: "intrapulmonary",
    keywords: ["intrapulmonary", "intra-pulmonary"],
    mesh_description: "",
    fda_description: "Administration within the lungs or its bronchi.",
    fda_codes: ["414"],
    nci_ids: ["C38261"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM21EBA7913B",
    name: "intrasinal",
    keywords: ["intrasinal", "intra-sinal"],
    mesh_description: "",
    fda_description: "Administration within the nasal or periorbital sinuses.",
    fda_codes: ["010"],
    nci_ids: ["C38262"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM2BB481884B",
    name: "intraspinal",
    keywords: ["intraspinal", "intra-spinal"],
    mesh_description: "",
    fda_description: "Administration within the vertebral column.",
    fda_codes: ["022"],
    nci_ids: ["C38263"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMD3F763E51F",
    name: "intrasynovial",
    keywords: ["intrasynovial", "intra-synovial"],
    mesh_description: "",
    fda_description: "Administration within the synovial cavity of a joint.",
    fda_codes: ["019"],
    nci_ids: ["C38264"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM99F5E5B09D",
    name: "intratendinous",
    keywords: ["intratendinous", "intra-tendinous"],
    mesh_description: "",
    fda_description: "Administration within a tendon.",
    fda_codes: ["049"],
    nci_ids: ["C38265"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMBB7F3BE847",
    name: "intratesticular",
    keywords: ["intratesticular", "intra-testicular"],
    mesh_description: "",
    fda_description: "Administration within the testicle.",
    fda_codes: ["110"],
    nci_ids: ["C38266"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM52A3F6BD19",
    name: "intrathecal",
    keywords: ["intrathecal", "intra-thecal"],
    mesh_description: "Introduction of therapeutic agents into the spinal region using a needle and syringe.",
    fda_description:
      "Administration within the cerebrospinal fluid at any level of the cerebrospinal axis, including injection into the cerebral ventricles.",
    fda_codes: ["103"],
    nci_ids: ["C38267"],
    mesh_uis: ["D007278"],
  },
  {
    art_administration_id: "ADMDAF2576E5D",
    name: "intrathoracic",
    keywords: ["intrathoracic", "intra-thoracic"],
    mesh_description: "",
    fda_description: "Administration within the thorax (internal to the ribs); synonymous with the term endothoracic.",
    fda_codes: ["006"],
    nci_ids: ["C38207"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM9BC6CE59F3",
    name: "intratubular",
    keywords: ["intratubular", "intra-tubular"],
    mesh_description: "",
    fda_description: "Administration within the tubules of an organ.",
    fda_codes: ["353"],
    nci_ids: ["C38268"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM06F9AEFB13",
    name: "intratumor",
    keywords: ["intratumor", "intra-tumor"],
    mesh_description: "",
    fda_description: "Administration within a tumor.",
    fda_codes: ["020"],
    nci_ids: ["C38269"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMF25256EEA0",
    name: "intratympanic",
    keywords: ["intratympanic", "intra-tympanic"],
    mesh_description: "The administration of  liquid substances into the TYMPANIC CAVITY with a hypodermic syringe.",
    fda_description: "Administration within the aurus media.",
    fda_codes: ["366"],
    nci_ids: ["C38270"],
    mesh_uis: ["D000069555"],
  },
  {
    art_administration_id: "ADM8AC2ED6161",
    name: "intrauterine",
    keywords: ["intrauterine", "intra-uterine"],
    mesh_description: "",
    fda_description: "Administration within the uterus.",
    fda_codes: ["028"],
    nci_ids: ["C38272"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMF11CCBA7B1",
    name: "intravascular",
    keywords: ["intravascular", "intra-vascular"],
    mesh_description: "",
    fda_description: "Administration within a vessel or vessels.",
    fda_codes: ["021"],
    nci_ids: ["C38273"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM640BF8B4E8",
    name: "intravenous",
    keywords: ["intravenous", "intra-venous"],
    mesh_description:
      "The long-term (minutes to hours) administration of a fluid into the vein through venipuncture, either by letting the fluid flow by gravity or by pumping it.",
    fda_description: "Administration within or into a vein or veins.",
    fda_codes: ["002"],
    nci_ids: ["C38276"],
    mesh_uis: ["D061605", "D007275", "D007262"],
  },
  {
    art_administration_id: "ADM2BDCB1721B",
    name: "intraventricular",
    keywords: ["intraventricular", "intra-ventricular"],
    mesh_description: "The delivery of a drug into a fluid-filled cavity of the brain.",
    fda_description: "Administration within a ventricle.",
    fda_codes: ["048"],
    nci_ids: ["C38277"],
    mesh_uis: ["D007276", "D057967"],
  },
  {
    art_administration_id: "ADM080CAF9807",
    name: "intravesical",
    keywords: ["intravesical", "intra-vesical"],
    mesh_description:
      "The instillation or other administration of drugs into the bladder, usually to treat local disease, including neoplasms.",
    fda_description: "Administration within the bladder.",
    fda_codes: ["128"],
    nci_ids: ["C38278"],
    mesh_uis: ["D000283"],
  },
  {
    art_administration_id: "ADM5FA156E764",
    name: "intravitreal",
    keywords: ["intravitreal", "intra-vitreal"],
    mesh_description: "The administration of substances into the VITREOUS BODY of the eye with a hypodermic syringe.",
    fda_description: "Administration within the vitreous body of the eye.",
    fda_codes: ["311"],
    nci_ids: ["C38280"],
    mesh_uis: ["D058449"],
  },
  {
    art_administration_id: "ADM1F651AE14C",
    name: "iontophoresis",
    keywords: ["iontophoresis"],
    mesh_description:
      "Therapeutic introduction of ions of soluble salts into tissues by means of electric current. In medical literature it is commonly used to indicate the process of increasing the penetration of drugs into surface tissues by the application of electric current. It has nothing to do with ION EXCHANGE; AIR IONIZATION nor PHONOPHORESIS, none of which requires current.",
    fda_description:
      "Administration by means of an electric current where ions of soluble salts migrate into the tissues of the body.",
    fda_codes: ["055"],
    nci_ids: ["C38203"],
    mesh_uis: ["D007478"],
  },
  {
    art_administration_id: "ADM4F7C4F206A",
    name: "irrigation",
    keywords: ["irrigation"],
    mesh_description: "",
    fda_description: "Administration to bathe or flush open wounds or body cavities.",
    fda_codes: ["032"],
    nci_ids: ["C38281"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMAACE07F777",
    name: "jet",
    keywords: ["jet"],
    mesh_description:
      "The injection of solutions into the skin by compressed air devices so that only the solution pierces the skin.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D007277"],
  },
  {
    art_administration_id: "ADMA798E42FA2",
    name: "laryngeal",
    keywords: ["laryngeal"],
    mesh_description: "",
    fda_description: "Administration directly upon the larynx.",
    fda_codes: ["364"],
    nci_ids: ["C38282"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM4C80CEB411",
    name: "microinjection",
    keywords: ["microinjection"],
    mesh_description:
      "The injection of very small amounts of fluid, often with the aid of a microscope and microsyringes.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D008845"],
  },
  {
    art_administration_id: "ADM341E42ADAA",
    name: "mucosal",
    keywords: ["mucosal"],
    mesh_description:
      "Delivery of a drug or other substance into the body through the epithelium lining of MUCOUS MEMBRANE involved with absorption and secretion.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D058956"],
  },
  {
    art_administration_id: "ADMD5F16527A9",
    name: "nasal",
    keywords: ["nasal"],
    mesh_description: "Delivery of medications through the nasal mucosa.",
    fda_description: "Administration to the nose; administered by way of the nose.",
    fda_codes: ["014"],
    nci_ids: ["C38284"],
    mesh_uis: ["D000281"],
  },
  {
    art_administration_id: "ADMC36938F6BB",
    name: "nasogastric",
    keywords: ["nasogastric"],
    mesh_description: "",
    fda_description: "Administration through the nose and into the stomach, usually by means of a tube.",
    fda_codes: ["071"],
    nci_ids: ["C38285"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM217EEB830C",
    name: "occlusive",
    keywords: ["occlusive", "dressing", "technique"],
    mesh_description: "",
    fda_description: "Administration by the topical route which is then covered by a dressing which occludes the area.",
    fda_codes: ["134"],
    nci_ids: ["C38286"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM78CB68C1EC",
    name: "ocular",
    keywords: ["ocular"],
    mesh_description: "Application of pharmaceutically active agents on the tissues of the EYE.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D060433"],
  },
  {
    art_administration_id: "ADMABEC822DE3",
    name: "ophthalmic",
    keywords: ["ophthalmic"],
    mesh_description: "Application of pharmaceutically active agents on the tissues of the EYE.",
    fda_description: "Administration  to the external eye.",
    fda_codes: ["012"],
    nci_ids: ["C38287"],
    mesh_uis: ["D060433"],
  },
  {
    art_administration_id: "ADMF4EAFD5EF1",
    name: "oral",
    keywords: ["oral"],
    mesh_description: "The giving of drugs, chemicals, or other substances by mouth.",
    fda_description: "Administration to or by way of the mouth.",
    fda_codes: ["001"],
    nci_ids: ["C38288"],
    mesh_uis: ["D000284"],
  },
  {
    art_administration_id: "ADMC45442D5DF",
    name: "oropharyngeal",
    keywords: ["oropharyngeal"],
    mesh_description: "",
    fda_description: "Administration directly to the mouth and pharynx.",
    fda_codes: ["410"],
    nci_ids: ["C38289"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMAC0C022F1D",
    name: "otic",
    keywords: ["auricular", "otic"],
    mesh_description: "The administration of  liquid substances into the TYMPANIC CAVITY with a hypodermic syringe.",
    fda_description: "Administration to or by way of the ear.",
    fda_codes: ["013"],
    nci_ids: ["C38192"],
    mesh_uis: ["D000069555"],
  },
  {
    art_administration_id: "ADM0430A068EE",
    name: "parenteral",
    keywords: ["parenteral"],
    mesh_description:
      "The administration of liquid medication, nutrient, or other fluid through some other route than the alimentary canal, usually over minutes or hours, either by gravity flow or often by infusion pumping.",
    fda_description: "Administration by injection, infusion, or implantation.",
    fda_codes: ["411"],
    nci_ids: ["C38291"],
    mesh_uis: ["D007263"],
  },
  {
    art_administration_id: "ADM8A14218C81",
    name: "percutaneous",
    keywords: ["percutaneous"],
    mesh_description: "",
    fda_description: "Administration through the skin.",
    fda_codes: ["113"],
    nci_ids: ["C38676"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM9DA1D47AD6",
    name: "periarticular",
    keywords: ["periarticular"],
    mesh_description: "",
    fda_description: "Administration around a joint.",
    fda_codes: ["045"],
    nci_ids: ["C38292"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM87AACD916A",
    name: "peridural",
    keywords: ["peridural"],
    mesh_description: "",
    fda_description: "Administration to the outside of the dura mater of the spinal cord..",
    fda_codes: ["050"],
    nci_ids: ["C38677"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM1C96503044",
    name: "perineural",
    keywords: ["perineural"],
    mesh_description: "",
    fda_description: "Administration surrounding a nerve or nerves.",
    fda_codes: ["412"],
    nci_ids: ["C38293"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM8E405D1E67",
    name: "periodontal",
    keywords: ["periodontal"],
    mesh_description: "",
    fda_description: "Administration around a tooth.",
    fda_codes: ["040"],
    nci_ids: ["C38294"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM2A54625901",
    name: "phonophoresis",
    keywords: ["phonophoresis"],
    mesh_description: "Use of ultrasound to increase the percutaneous adsorption of drugs.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D015612"],
  },
  {
    art_administration_id: "ADMC48BAEB5E9",
    name: "rectal",
    keywords: ["rectal"],
    mesh_description:
      "The insertion of drugs into the rectum, usually for confused or incompetent patients, like children, infants, and the very old or comatose.",
    fda_description: "Administration to the rectum.",
    fda_codes: ["016"],
    nci_ids: ["C38295"],
    mesh_uis: ["D000285"],
  },

  {
    art_administration_id: "ADM41FFDE3D9C",
    name: "retrobulbar",
    keywords: ["retrobulbar"],
    mesh_description: "",
    fda_description: "Administration behind the pons or behind the eyeball.",
    fda_codes: ["034"],
    nci_ids: ["C38296"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMC284697476",
    name: "spinal",
    keywords: ["spinal"],
    mesh_description: "Introduction of therapeutic agents into the spinal region using a needle and syringe.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D007278", "D060186"],
  },
  {
    art_administration_id: "ADMBAE6714C7E",
    name: "subarachnoid",
    keywords: ["subarachnoid", "sub-arachnoid"],
    mesh_description: "",
    fda_description: "Administration beneath the arachnoid.",
    fda_codes: ["066"],
    nci_ids: ["C38297"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM46D1B10B8A",
    name: "subconjunctival",
    keywords: ["subconjunctival", "sub-conjunctival"],
    mesh_description: "",
    fda_description: "Administration beneath the conjunctiva.",
    fda_codes: ["096"],
    nci_ids: ["C38298"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM4A50637FBB",
    name: "subcutaneous",
    keywords: ["subcutaneous", "sub-cutaneous"],
    mesh_description:
      "The administration of liquid medication or nutrients under the skin, usually over minutes or hours.",
    fda_description: "Administration beneath the skin; hypodermic.  Synonymous with the term SUBDERMAL.",
    fda_codes: ["003"],
    nci_ids: ["C38299"],
    mesh_uis: ["D007279", "D055104"],
  },
  {
    art_administration_id: "ADM625911E9E6",
    name: "sublingual",
    keywords: ["sublingual", "sub-lingual"],
    mesh_description: "Administration of a soluble dosage form by placement under the tongue.",
    fda_description: "Administration beneath the tongue.",
    fda_codes: ["024"],
    nci_ids: ["C38300"],
    mesh_uis: ["D000286"],
  },
  {
    art_administration_id: "ADMFA6D998DCE",
    name: "submucosal",
    keywords: ["submucosal", "sub-mucosal"],
    mesh_description: "",
    fda_description: "Administration beneath the mucous membrane.",
    fda_codes: ["053"],
    nci_ids: ["C38301"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM04F4D75817",
    name: "topical",
    keywords: ["topical"],
    mesh_description:
      "The application of drug preparations to the surfaces of the body, especially the skin (ADMINISTRATION, CUTANEOUS) or mucous membranes. This method of treatment is used to avoid systemic side effects when high doses are required at a localized area or as an alternative systemic administration route, to avoid hepatic processing for example.",
    fda_description:
      "Administration to a particular spot on the outer surface of the body.  The E2B term TRANSMAMMARY is a subset of the term TOPICAL.",
    fda_codes: ["011"],
    nci_ids: ["C38304"],
    mesh_uis: ["D000287"],
  },
  {
    art_administration_id: "ADM20CF7864FE",
    name: "transdermal",
    keywords: ["transdermal", "trans-dermal"],
    mesh_description: "",
    fda_description: "Administration through the dermal layer of the skin to the systemic circulation by diffusion.",
    fda_codes: ["358"],
    nci_ids: ["C38305"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMBC61CDA4D2",
    name: "transmucosal",
    keywords: ["transmucosal", "trans-mucosal"],
    mesh_description: "",
    fda_description: "Administration across the mucosa.",
    fda_codes: ["122"],
    nci_ids: ["C38283"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM94165DB03B",
    name: "transplacental",
    keywords: ["transplacental", "trans-placental"],
    mesh_description: "",
    fda_description: "Administration through or across the placenta.",
    fda_codes: ["415"],
    nci_ids: ["C38307"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADMB5F435BF2C",
    name: "transtracheal",
    keywords: ["transtracheal", "trans-tracheal"],
    mesh_description: "",
    fda_description: "Administration through the wall of the trachea.",
    fda_codes: ["355"],
    nci_ids: ["C38308"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM403AC8B30A",
    name: "transtympanic",
    keywords: ["transtympanic", "trans-tympanic"],
    mesh_description: "",
    fda_description: "Administration across or through the tympanic cavity.",
    fda_codes: ["124"],
    nci_ids: ["C38309"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM78A1F680FF",
    name: "ureteral",
    keywords: ["ureteral"],
    mesh_description: "",
    fda_description: "Administration into the ureter.",
    fda_codes: ["112"],
    nci_ids: ["C38312"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM33D7D741CA",
    name: "urethral",
    keywords: ["urethral"],
    mesh_description: "",
    fda_description: "Administration into the urethra.",
    fda_codes: ["017"],
    nci_ids: ["C38271"],
    mesh_uis: [],
  },
  {
    art_administration_id: "ADM5E1F6FC673",
    name: "vaginal",
    keywords: ["vaginal"],
    mesh_description:
      "The insertion of drugs into the vagina to treat local infections, neoplasms, or to induce labor. The dosage forms may include medicated pessaries, irrigation fluids, and suppositories.",
    fda_description: "Administration into the vagina.",
    fda_codes: ["015"],
    nci_ids: ["C38313"],
    mesh_uis: ["D000282"],
  },
  {
    art_administration_id: "ADM489B6B4B0C",
    name: "viscosupplement",
    keywords: ["viscosupplement"],
    mesh_description:
      "A therapeutic treatment typically involving INTRA-ARTICULAR INJECTIONS of HYALURONIC ACID and related compounds. The procedure is commonly used in the treatment of OSTEOARTHRITIS with the therapeutic goal to restore the viscoelasticity of SYNOVIAL FLUID, decrease pain, improve mobility and restore the natural protective functions of hyaluronan in the joint.",
    fda_description: "",
    fda_codes: [],
    nci_ids: [],
    mesh_uis: ["D055676"],
  },
];
