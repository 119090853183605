export const getStudySummaries = `
  query ($page: Int, $pageSize: Int, $params: StudySearchParams) {
    getStudySummaries(page: $page, pageSize: $pageSize, params: $params) {
      id
      id_list {
        primary_id
      }
      dates {
        name
        is_actual
        date
      }
      brief_summary
      brief_title
      detailed_description
      phase
      overall_status
      study_type
      sponsors {
        agency
      }
      conditions
      countries {
        name
      }
      ancestry
      eligibility {
        gender
        accepts_healthy
        inclusion_criteria
        exclusion_criteria
        min_age
        min_age_num
        max_age
        max_age_num
      }
      interventions {
        name
        description
        routes_of_administration {
          name
          fda_description
        }
        drugs {
          synonyms
          name
        }
      }
    }
  }
`;

export const getStudiesCount = `
  query ($params: StudySearchParams){
    getStudiesCount(params:$params) {
      count
    }
  }
`;

export const getTimelines = `
  query ($params: StudySearchParams){
    getTimelines(params:$params) {
      id
      phase
      brief_title
      start
      end
    }
  }
`;

export const getPhaseStats = `
  query ($params: StudySearchParams){
    getPhaseStats(params:$params) {
      name
      count
    }
  }
`;

export const getSponsorStats = `
  query ($params: StudySearchParams){
    getSponsorStats(params:$params) {
      name
      count 
    }
  }
`;

export const getStatusStats = `
  query ($params: StudySearchParams){
    getStatusStats(params:$params) {
      name
      count
    }
  }
`;

export const getInterventionsStats = `
  query ($params: StudySearchParams){
    getInterventionsStats(params:$params) {
      name
      count 
    }
  }
`;

export const getStudy = `
  query ($id: String){
    getStudy(id:$id) {
      id
      acronym
      dates {
        name
        date
        is_actual
      }
      brief_summary
      brief_title
      detailed_description
      phase
      overall_status
      sponsors {
        is_lead
        agency
      }
      responsible_party {
        name
        affiliation
      }
      therapeutic_areas {
        heading
      }
      conditions
      countries {
        name
      }
      ancestry
      eligibility {
        gender
        accepts_healthy
        min_age
        min_age_num
        max_age
        max_age_num
      }
      arms {
        label
        type
        description
      }
      study_type
      ancestry
      countries {
        name
        alpha2
      }
      interventions {
        name
        description
        arm_group_labels
        routes_of_administration {
          name
          fda_description
        }
        drugs {
          name
          targets {
            moa
            target {
              name
              gene
              types 
            }
            drug {
              name
            }
          }
        }
      }
    }
  }
`;
