import { useContext, useEffect, useState } from "react";
import { Button, Card, Pagination, Row, Space, Table, Tooltip, Typography, message } from "antd";
import { DownloadOutlined, FileDoneOutlined, FileTextOutlined, GlobalOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import ButtonPanel from "./ResultsControlPanel";
import Summary from "./details/Summary";
import Eligibility from "./details/Eligibility";
import Locations from "./details/Locations";
import { PaginationState } from "../../../data/interfaces/FunctionalStates";
import Break from "../../../assets/components/other/Break";
import { StudySummary } from "../../../data/interfaces/study/Study";
import SearchContext from "../../../data/api/SearchContext";
import { getStudySummaries, getStudiesCount } from "../../../data/api/queries";
import { headers, sendQuery } from "../../../data/api/config";
import { PhaseEnum, StatusEnum, StudyTypeEnum } from "../../../data/local/enums";
import { capitalizeFirstLetter } from "../../../assets/js/utils";

interface StudiesSearchResultState {
  isLoading: boolean;
  data: StudySummary[];
}

const { Link, Text, Title, Paragraph } = Typography;

const Studies: React.FC = () => {
  const { getSearchParams } = useContext(SearchContext);
  const searchParams = getSearchParams();
  console.log("Search params in Results", searchParams);
  const [total, setTotal] = useState();
  const [paginationState, setPaginationState] = useState<PaginationState>({
    currentPage: 1,
    pageSize: 10,
  });
  const [state, setState] = useState<StudiesSearchResultState>({
    isLoading: false,
    data: [],
  });

  const [study, setStudy] = useState<StudySummary>();
  const [isSummaryVisible, setSummaryVisible] = useState(false);
  const onSummaryClose = () => {
    setSummaryVisible(false);
  };
  const [isEligibilityVisible, setEligibilityVisible] = useState(false);
  const onEligibilityClose = () => {
    setEligibilityVisible(false);
  };
  const [isDownloadVisible, setDownloadVisible] = useState(false);
  const onDownloadClose = () => {
    setDownloadVisible(false);
  };
  const [areLocationsVisible, setLocationsVisible] = useState(false);
  const onLocationsClose = () => {
    setLocationsVisible(false);
  };

  const getTotalCount = () => {
    const query = getStudiesCount;
    const variables = { params: searchParams };
    sendQuery(query, variables, headers)
      .then((data) => {
        if ("errors" in data) {
          message.error("Failed to fetch the total count");
        } else {
          if (data.data?.getStudiesCount?.count) {
            setTotal(data.data?.getStudiesCount?.count);
          }
        }
      })
      .catch((err) => {
        message.error("Connection error: " + err.message);
      });
  };

  const getData = (page: number, pageSize: number, searchParams: any) => {
    setState({
      ...state,
      isLoading: true,
    });
    const query = getStudySummaries;
    const variables = { page: page, pageSize: pageSize, params: searchParams };
    console.log(searchParams);
    sendQuery(query, variables, headers)
      .then((data) => {
        console.log(data);
        if ("errors" in data) {
          setState({
            ...state,
            isLoading: false,
          });
          message.error("Failed to fetch the data");
        } else {
          let results;
          if (data?.data?.getStudySummaries) {
            results = data.data.getStudySummaries.map((entry: StudySummary) => {
              entry["key"] = entry.id;
              return entry;
            });
          }
          setState({
            ...state,
            isLoading: false,
            data: results ? results : [],
          });
        }
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        message.error("Connection error: " + err.message);
      });
  };

  const updateData = () => {
    const params = getSearchParams();
    getData(paginationState.currentPage, paginationState.pageSize, params);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setPaginationState({
      ...paginationState,
      currentPage: page,
      pageSize: pageSize,
    });
    getData(page, pageSize, searchParams);
  };

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    getTotalCount();
  }, [searchParams]);

  const columns: ColumnsType<StudySummary> = [
    {
      key: "id",
      title: "Primary ID",
      align: "center",
      width: 120,
      render: (record: StudySummary) => (
        <Link href={"/study/" + record.id_list.primary_id} target="_blank">
          {record.id_list.primary_id}
        </Link>
      ),
    },
    {
      key: "title",
      title: "Brief Title",
      dataIndex: "brief_title",
      align: "center",
      width: 200,
      render: (title: string) => (
        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: "more" }}>{title}</Paragraph>
      ),
    },
    {
      key: "phase",
      title: "Phase",
      dataIndex: "phase",
      align: "center",
      width: 90,
      render: (phase: "EP1" | "P1" | "P12" | "P2" | "P23" | "P3" | "P4" | "NA") => <Text>{PhaseEnum[phase]}</Text>,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "overall_status",
      align: "center",
      width: 100,
      render: (
        status: "C" | "R" | "US" | "T" | "NYR" | "ANR" | "WD" | "EBI" | "S" | "WH" | "NLA" | "A" | "AFM" | "TNA" | "NA"
      ) => <Text>{StatusEnum[status]}</Text>,
    },
    {
      key: "condition",
      title: "Conditions",
      dataIndex: "conditions",
      align: "center",
      width: 200,
      render: (conditions: string[]) => (
        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: "more" }}>
          {conditions
            ? // ? conditions.map((condition) => {
              //     return <Text>{condition}; </Text>;
              //   })
              conditions.join("; ")
            : "N/A"}
        </Paragraph>
      ),
    },
    {
      key: "intervention",
      title: "Interventions",
      dataIndex: "interventions",
      align: "center",
      width: 150,
      render: (interventions: any[]) => (
        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: "more" }}>
          {interventions
            ? interventions
                .map((intervention) => {
                  return intervention
                    ? capitalizeFirstLetter(
                        intervention.name.replace(" ,", ", ").replace(",", ", ").replace(",  ", ", ")
                      )
                    : null;
                })
                .filter((condition) => condition)
                .join(", ")
            : "N/A"}
        </Paragraph>
      ),
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "study_type",
      align: "center",
      width: 120,
      render: (type: "I" | "O" | "OPR" | "EA" | "EAIP" | "EAISP" | "EATIP" | "NA") => (
        <Text>{StudyTypeEnum[type]}</Text>
      ),
    },
    {
      key: "sponsors",
      title: "Sponsor(s)",
      align: "center",
      width: 130,
      render: (record: StudySummary) => (
        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: "more" }}>
          {record.sponsors
            .map((s) => {
              return s.agency ? s.agency : null;
            })
            .filter((s) => s)
            .join(", ")}
        </Paragraph>
      ),
    },
    {
      key: "actions",
      title: "More",
      align: "center",
      width: 130,
      render: (record: StudySummary) => (
        <Space direction="vertical">
          <Space>
            <Tooltip title="View summary">
              <Button
                key="details-btn"
                size="small"
                icon={<FileTextOutlined />}
                onClick={() => {
                  setStudy(record);
                  setSummaryVisible(true);
                }}
              ></Button>
            </Tooltip>
            <Tooltip title="View locations">
              <Button
                key="countries-btn"
                size="small"
                icon={<GlobalOutlined />}
                onClick={() => {
                  setStudy(record);
                  setLocationsVisible(true);
                }}
              ></Button>
            </Tooltip>
          </Space>
          <Space>
            <Tooltip title="Eligibility criteria">
              <Button
                key="other-btn"
                size="small"
                icon={<FileDoneOutlined />}
                onClick={() => {
                  setStudy(record);
                  setEligibilityVisible(true);
                }}
              ></Button>
            </Tooltip>
            <Tooltip title="Download study">
              <Button
                key="download-btn"
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => {
                  setStudy(record);
                  setDownloadVisible(true);
                }}
              ></Button>
            </Tooltip>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <Card key="study-results" className="frosted-card" bordered={false}>
      <Space>
        <Title key="studies-title" level={4}>
          Search results
        </Title>
      </Space>
      <Break key="break-1" height={12} />
      <ButtonPanel total={total} updateData={updateData} data={state.data} />
      <Table
        key="studies-table"
        pagination={false}
        bordered={false}
        scroll={{ x: "1000", y: 1100 }}
        rowSelection={{
          type: "checkbox",
        }}
        columns={columns}
        dataSource={state.data}
        loading={state.isLoading}
        footer={() => ""}
      />
      <Break key="break-2" height={12} />
      <Row key="pagination-row" justify="end">
        <Pagination
          hideOnSinglePage={true}
          pageSize={paginationState.pageSize}
          current={paginationState.currentPage}
          total={total ? total : 100}
          onChange={onPageChange}
        />
      </Row>
      <Summary isVisible={isSummaryVisible} onClose={onSummaryClose} study={study} />
      <Eligibility isVisible={isEligibilityVisible} onClose={onEligibilityClose} study={study} />
      <Locations isVisible={areLocationsVisible} onClose={onLocationsClose} study={study} />
    </Card>
  );
};

export default Studies;
