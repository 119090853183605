import { useState } from "react";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getGlossaryTermByKeyword, getEnumOptionsByKeyword } from "../../js/utils";

interface AInputProps {
  keyword: "target" | "age";
  disabled?: boolean;
  defaultValue?: { key: string; input: string };
}

const { Option } = Select;

const AInputWithPreselect: React.FC<AInputProps> = ({ keyword, disabled, defaultValue }) => {
  let term = getGlossaryTermByKeyword(keyword);
  if (!term) {
    term = {
      keyword: "undefined",
      term: "Undefined",
      definition: "Undefined",
      source: "Undefined",
      url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
    };
  }

  const [preselectChoice, setPreselectChoice] = useState("1");

  const onPreselectChange = (value: any) => {
    setPreselectChoice(value);
  };

  let initialValue;
  const key = keyword + preselectChoice;
  if (defaultValue?.key === key) {
    initialValue = defaultValue.input;
  }

  let input = (
    <Input
      defaultValue={initialValue}
      disabled={disabled ? true : false}
      allowClear
      placeholder={`Enter ${term.keyword}`}
    />
  );

  switch (true) {
    case keyword === "age" && preselectChoice === "1":
      input = (
        <InputNumber
          disabled={disabled ? true : false}
          min={0}
          max={100}
          style={{ width: "100%" }}
          placeholder={`Enter ${term.keyword}`}
        />
      );
      break;
    case keyword === "age" && preselectChoice === "2":
      let enumOptions = getEnumOptionsByKeyword(keyword)?.options;
      if (!enumOptions) {
        enumOptions = { NA: "undefined" };
      }
      let options = [];
      for (const [key, value] of Object.entries(enumOptions)) {
        options.push(
          <Option key={key} value={key}>
            {value}
          </Option>
        );
      }
      input = (
        <Select disabled={disabled ? true : false} placeholder={`Select ${term.keyword} group`}>
          {options}
        </Select>
      );
      break;
  }

  return (
    <Form.Item
      label={term.term}
      tooltip={{
        title: term.definition,
        icon: <InfoCircleOutlined />,
      }}
      key={term.keyword}
    >
      <Row gutter={8}>
        <Col key="preselect-col" span={8}>
          <Select defaultValue="1" onChange={onPreselectChange}>
            <Option value="1">{keyword === "target" ? "By name" : "Age"}</Option>
            <Option value="2">{keyword === "target" ? "By gene" : "Age group"}</Option>
          </Select>
        </Col>
        <Col key="input-col" span={16}>
          <Form.Item noStyle name={term.keyword + preselectChoice}>
            {input}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default AInputWithPreselect;
