import { Button, Card, Modal, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { StudySummary } from "../../../../data/interfaces/study/Study";
import Break from "../../../../assets/components/other/Break";

const { Text, Title } = Typography;

interface EligibilityProps {
  isVisible: boolean;
  onClose: () => void;
  study?: StudySummary;
}

const Eligibility: React.FC<EligibilityProps> = ({ isVisible, onClose, study }) => {
  return (
    <Modal
      style={{ top: 50 }}
      width={700}
      title={"Study Eligibility: " + study?.id_list.primary_id}
      closeIcon={<CloseCircleOutlined />}
      visible={isVisible}
      bodyStyle={{
        backgroundColor: "lightgray",
        boxShadow: "inset 0 -3px 5px 0px rgb(0 0 0 / 20%), inset 0 3px 5px 2px rgb(0 0 0 / 20%)",
      }}
      footer={[
        <Button key="btn-ok" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      <Card
        headStyle={{
          color: "#ffffff",
          fontWeight: "900",
          fontSize: "14px",
          background: "linear-gradient(-0deg, #48a9fe, #02c0db)",
        }}
        title="Details"
        bordered={false}
      >
        <Text strong>Accepts healthy: </Text>
        <Text>{study?.eligibility?.accepts_healthy?.toString()}</Text>
        <Break height={0} />
        <Text strong>Sex: </Text>
        <Text>{study?.eligibility?.gender}</Text>
      </Card>
      <Break height={16} />
      <Card
        headStyle={{
          color: "#ffffff",
          fontWeight: "900",
          fontSize: "14px",
          background: "linear-gradient(-0deg, #48a9fe, #02c0db)",
        }}
        title="Inclusion / exclusion criteria"
        bordered={false}
      >
        <Text></Text>
        {study?.brief_title}
      </Card>
    </Modal>
  );
};

export default Eligibility;
