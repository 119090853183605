import { Button, Card, Modal, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { StudySummary } from "../../../../data/interfaces/study/Study";

const { Title } = Typography;

interface LocationsProps {
  isVisible: boolean;
  onClose: () => void;
  study?: StudySummary;
}

const Locations: React.FC<LocationsProps> = ({ isVisible, onClose, study }) => {
  return (
    <Modal
      style={{ top: 50 }}
      width={1000}
      title="Study Locations"
      closeIcon={<CloseCircleOutlined />}
      visible={isVisible}
      bodyStyle={{
        backgroundColor: "lightgray",
        boxShadow: "inset 0 -3px 5px 0px rgb(0 0 0 / 20%), inset 0 3px 5px 2px rgb(0 0 0 / 20%)",
      }}
      footer={[
        <Button key="btn-ok" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      <Card key="study-locations" className="frosted-card" bordered={false}>
        {study ? <Title level={5}>{study?.id_list.primary_id}</Title> : <Title level={5}>No study</Title>}
      </Card>
    </Modal>
  );
};

export default Locations;
