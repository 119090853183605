import { Card, Typography } from "antd";
import AInput from "../../../../assets/components/inputs/AInput";
import ASelect from "../../../../assets/components/inputs/ASelect";
import Break from "../../../../assets/components/other/Break";

const { Title } = Typography;

const StudySearch: React.FC = () => {
  return (
    <Card key="study-search" bordered={false}>
      <Title level={5}>Study Details</Title>
      <Break height={8} />
      <AInput keyword="nct" />
      <AInput keyword="title" />
      <ASelect keyword="status" select multiple />
      <ASelect keyword="phase" multiple />
      <AInput keyword="sponsor" />
      <ASelect keyword="type" select />
    </Card>
  );
};

export default StudySearch;
