import { countries } from "./countries";
import { states } from "./states";
import { roas } from "./roas";
import { capitalizeFirstLetter } from "../../assets/js/utils";

export const OutcomeTypeEnum = {
  P: "Primary",
  S: "Secondary",
  O: "Other",
};

export const AgeEnum = {
  child: "Child (birth-17)",
  adult: "Adult (18-64)",
  senior: "Older Adult (65+)",
};

export const HealthyEnum = {
  true: "Healthy volunteers may participate",
  false: "Only those with condition/disease are accepted",
};

export const PhaseEnum = {
  P4: "Phase 4",
  P3: "Phase 3",
  P23: "Phase 2/Phase 3",
  P2: "Phase 2",
  P12: "Phase 1/Phase 2",
  P1: "Phase 1",
  EP1: "Early Phase 1",
  NA: "Not available",
};

export const StatusEnum = {
  R: "Recruiting",
  NYR: "Not yet recruiting",
  EBI: "Enrolling by invitation",
  ANR: "Active, not recruiting",
  C: "Completed",
  S: "Suspended",
  T: "Terminated",
  WD: "Withdrawn",
  WH: "Withheld",
  A: "Available",
  NLA: "No longer available",
  AFM: "Approved for marketing",
  TNA: "Temporarily not available",
  NA: "Not available",
  US: "Unknown status",
};

export const StudyTypeEnum = {
  I: "Interventional",
  O: "Observational",
  OPR: "Observational [Patient Registry]",
  EA: "Expanded Access Only",
  EAIP: "Expanded Access [Individual Patients]",
  EAISP: "Expanded Access [Intermediate-Size Population]",
  EATIP: "Expanded Access [Treatment IND/Protocol]",
  NA: "Study type is not available",
};

export const AgencyClassEnum = {
  O: "Other",
  I: "Industry",
  USF: "Other U.S. Federal agency",
  NIH: "NIH",
};

export const InterventionTypeEnum = {
  DR: "Drug",
  B: "Behavioral",
  BIO: "Biological",
  CP: "Combination Product",
  D: "Device",
  DT: "Diagnostic Test",
  DS: "Dietary Supplement",
  G: "Genetic",
  O: "Other",
  P: "Procedure",
  R: "Radiation",
};

export const DrugGroupEnum = {
  A: "Approved",
  N: "Investigational",
  IL: "Illicit",
  IN: "Investigational",
  WD: "Withdrawn",
  E: "Experimental",
};

export const GenderEnum = {
  ALL: "All",
  F: "Female",
  M: "Male",
};

export const RaceEnum = {
  C: "Caucasian | White",
  B: "Black | African-American",
  I: "Native Hawaiian | Pacific Islander",
  A: "Asian",
  N: "Native Alaskan | American-Indian",
  MU: "Mixed | Undefined",
};

export const TherapeuticAreaEnum = {
  C01: "Infections [C01]",
  C04: "Neoplasms [C04]",
  C05: "Musculoskeletal Diseases [C05]",
  C06: "Digestive System Diseases [C06]",
  C07: "Stomatognathic Diseases [C07]",
  C08: "Respiratory Tract Diseases [C08]",
  C09: "Otorhinolaryngologic Diseases [C09]",
  C10: "Nervous System Diseases [C10]",
  C11: "Eye Diseases [C11]",
  C12: "Male Urogenital Diseases [C12]",
  C13: "Female Urogenital Diseases and Pregnancy Complications [C13]",
  C14: "Cardiovascular Diseases [C14]",
  C15: "Hemic and Lymphatic Diseases [C15]",
  C16: "Congenital, Hereditary, and Neonatal Diseases and Abnormalities [C16]",
  C17: "Skin and Connective Tissue Diseases [C17]",
  C18: "Nutritional and Metabolic Diseases [C18]",
  C19: "Endocrine System Diseases [C19]",
  C20: "Immune System Diseases [C20]",
  C21: "Disorders of Environmental Origin [C21]",
  C22: "Animal Diseases [C22]",
  C23: "Pathological Conditions, Signs and Symptoms [C23]",
  C24: "Occupational Diseases [C24]",
  C25: "Chemically-Induced Disorders [C25]",
  C26: "Wounds and Injuries [C26]",
  F03: "Mental Disorders [F03]",
};

export const DocumentTypeEnum = {
  AC: "Analytic Code",
  SP: "Study protocol",
  SPA: "Study protocol: Amendment",
  SPO: "Study protocol: Original",
  ICF: "Informed Consent Form",
  ICFSC: "Informed Consent Form (Standard Consent)",
  ICFDC: "Informed Consent Form (Donor Consent)",
  ICFGC: "Informed Consent Form (Genetics Consent)",
  ICFRC: "Informed Consent Form (Recipient Consent)",
  ICFCAC: "Informed Consent Form (Child and Adult Consent)",
  ICFERC: "Informed Consent Form (Early Response Consent)",
  ICFRMC: "Informed Consent Form (Residual Mass Consent)",
  SAP: "Statistical Analysis Plan",
  CSR: "Clinical Study Report",
};

export const DateNameEnum = {
  SD: "start date",
  CD: "completion date",
  PCD: "primary completion date",
  VD: "verification date",
  DFS: "disposition first submitted",
  DFSQ: "disposition first submitted qc",
  DFP: "disposition first posted",
  SFS: "study first submitted",
  SFSQ: "study first submitted qc",
  SFP: "study first posted",
  RFS: "results first submitted",
  RFSQ: "results first submitted qc",
  RFP: "results first posted",
  LUS: "last update submitted",
  LUSQ: "last update submitted qc",
  LUP: "last update posted",
};

export const ContactTypeEnum = {
  FC: "Facility contact",
  OC: "Overall contact",
  OCB: "Overall contact backup",
};

export const PatientDataSharingEnum = {
  Y: "Yes",
  N: "No",
  U: "Undecided",
};

export const BiospecEnum = {
  NR: "None Retained",
  SWD: "Samples With DNA",
  SWOD: "Samples Without DNA",
};

export const ActualEnum = {
  AC: "Actual",
  AN: "Anticipated",
  ES: "Estimate",
  NA: "N/A default",
};

export const SamplingMethodEnum = {
  PS: "Probability Sample",
  NPS: "Non-Probability Sample",
};

export const MaskingEnum = {
  NOL: "None (Open Label)",
  S: "Single",
  D: "Double",
  T: "Triple",
  Q: "Quadruple",
  NA: "N/A default",
};

export const MasksEnum = {
  S: "Subject",
  C: "Caregiver",
  I: "Investigator",
  A: "Assessor",
};

export const TimePerspectiveEnum = {
  P: "Prospective",
  R: "Retrospective",
  CS: "Cross-Sectional",
  O: "Other",
};

export const PrimaryPurposeEnum = {
  S: "Screening",
  D: "Diagnostic",
  P: "Prevention",
  HSR: "Health Services Research",
  T: "Treatment",
  ECT: "Educational/Counseling/Training",
  DF: "Device Feasibility",
  SC: "Supportive Care",
  BS: "Basic Science",
  O: "Other",
};

export const AllocationEnum = {
  R: "Randomized",
  NR: "Non-Randomized",
  NA: "N/A",
};

export const InterventionModelEnum = {
  PA: "Parallel Assignment",
  CRA: "Crossover Assignment",
  SA: "Sequential Assignment",
  FA: "Factorial Assignment",
  SGA: "Single Group Assignment",
};

export const ObservationalModelEnum = {
  FB: "Family-Based",
  NH: "Natural History",
  DP: "Defined Population",
  O: "Other",
  CC: "Case-Control",
  CCR: "Case-Crossover",
  CO: "Case-Only",
  C: "Cohort",
  EOC: "Ecologic or Community",
};

export const SeverityEnum = {
  M: "Mild",
  MD: "Moderate",
  S: "Severe",
  AC: "Acute",
  UN: "Uncomplicated",
  C: "Chronic",
  P: "Primary - cancer",
  AD: "Advanced - cancer",
  MT: "Metastatic - cancer",
  ML: "Malignant - cancer",
  UD: "Undefined",
};

let RawCountryEnum = {};

countries.forEach((country) => {
  const newKey = {
    [country.alpha2]: country.name,
  };
  RawCountryEnum = { ...RawCountryEnum, ...newKey };
});

export const CountryEnum = RawCountryEnum;

let RawUSStateEnum = {};

states.forEach((state) => {
  if (state.country_code === "US" && state.state_code.length === 2) {
    const newKey = {
      [state.state_code]: state.name,
    };
    RawUSStateEnum = { ...RawUSStateEnum, ...newKey };
  }
});

export const USStateEnum = RawUSStateEnum;

let RawAUStateEnum = {};

states.forEach((state) => {
  if (state.country_code === "AU") {
    const newKey = {
      [state.state_code]: state.name,
    };
    RawAUStateEnum = { ...RawAUStateEnum, ...newKey };
  }
});

export const AUStateEnum = RawAUStateEnum;

let RawRoaEnum = {};

roas.forEach((roa) => {
  const newKey = {
    [roa.name]: capitalizeFirstLetter(roa.name),
  };
  RawRoaEnum = { ...RawRoaEnum, ...newKey };
});

export const RoaNamesEnum = RawRoaEnum;

interface EnumOptions {
  keyword: string;
  options: Record<string, string>;
}

export const enums: EnumOptions[] = [
  {
    keyword: "outcome",
    options: OutcomeTypeEnum,
  },
  {
    keyword: "phase",
    options: PhaseEnum,
  },
  {
    keyword: "status",
    options: StatusEnum,
  },
  {
    keyword: "type",
    options: StudyTypeEnum,
  },
  {
    keyword: "agency",
    options: AgencyClassEnum,
  },
  {
    keyword: "intervention",
    options: InterventionTypeEnum,
  },
  {
    keyword: "drug_group",
    options: DrugGroupEnum,
  },
  {
    keyword: "sex",
    options: GenderEnum,
  },
  {
    keyword: "ancestry",
    options: RaceEnum,
  },
  {
    keyword: "roa",
    options: RoaNamesEnum,
  },
  {
    keyword: "document",
    options: DocumentTypeEnum,
  },
  {
    keyword: "dates",
    options: DateNameEnum,
  },
  {
    keyword: "contact",
    options: ContactTypeEnum,
  },
  {
    keyword: "patient",
    options: PatientDataSharingEnum,
  },
  {
    keyword: "biospect",
    options: BiospecEnum,
  },
  {
    keyword: "actual",
    options: ActualEnum,
  },
  {
    keyword: "sampling",
    options: SamplingMethodEnum,
  },
  {
    keyword: "masking",
    options: MaskingEnum,
  },
  {
    keyword: "masks",
    options: MasksEnum,
  },
  {
    keyword: "perspective",
    options: TimePerspectiveEnum,
  },
  {
    keyword: "purpose",
    options: PrimaryPurposeEnum,
  },
  {
    keyword: "allocation",
    options: AllocationEnum,
  },
  {
    keyword: "intervention_model",
    options: InterventionModelEnum,
  },
  {
    keyword: "observational_model",
    options: ObservationalModelEnum,
  },
  {
    keyword: "severity",
    options: SeverityEnum,
  },
  {
    keyword: "country",
    options: CountryEnum,
  },
  {
    keyword: "healthy",
    options: HealthyEnum,
  },
  {
    keyword: "area",
    options: TherapeuticAreaEnum,
  },
  {
    keyword: "age",
    options: AgeEnum,
  },
];
