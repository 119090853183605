import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "./layouts/auth/AuthLayout";
import PublicLayout from "./layouts/public/PublicLayout";
import Search from "./pages/public/search/Search";
import Results from "./pages/public/results/Results";
import Study from "./pages/public/study/Study";
import Home from "./pages/public/home/Home";
import Error from "./pages/public/other/Error";
import { SearchContextProvider } from "./data/api/SearchContext";

const App: React.FC = () => {
  return (
    <SearchContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicLayout />}>
            <Route path="/search" element={<Search />} />
            <Route path="/studies" element={<Results />} />
            <Route path="/study/*" element={<Study />} />
            <Route index element={<Search />} />
            <Route path="/404" element={<Error error="404" />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="user/*" element={<Home />} />
          </Route>
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </SearchContextProvider>
  );
};

export default App;
