import { useContext, useEffect, useState } from "react";
import { Card, Spin, Typography, message } from "antd";
import { Column } from "@ant-design/plots";
import Break from "../../../../assets/components/other/Break";
import { StatsState, StatsWithKey } from "../../../../data/interfaces/Stats";
import { getPhaseStats } from "../../../../data/api/queries";
import { headers, sendQuery } from "../../../../data/api/config";
import SearchContext from "../../../../data/api/SearchContext";
import { getEnumOptionsByKeyword } from "../../../../assets/js/utils";
import { config } from "./config";

const { Title } = Typography;

const PhaseChart: React.FC = () => {
  const [state, setState] = useState<StatsState>({
    isLoading: false,
    data: [],
  });
  const { getSearchParams } = useContext(SearchContext);
  const searchParams = getSearchParams();
  const searchString = JSON.stringify(searchParams);

  useEffect(() => {
    const phaseEnums = getEnumOptionsByKeyword("phase")?.options;
    const getStats = () => {
      setState({
        ...state,
        isLoading: true,
      });
      const query = getPhaseStats;
      const variables = { params: searchParams };
      sendQuery(query, variables, headers)
        .then((data) => {
          if ("errors" in data) {
            setState({
              ...state,
              isLoading: false,
            });
            message.error("Failed to fetch the data");
          } else {
            let results;
            if (data?.data?.getPhaseStats) {
              results = data.data.getPhaseStats.map((entry: StatsWithKey) => {
                entry["key"] = entry.name;
                entry.name = phaseEnums ? phaseEnums[entry.name] : entry.name;
                return entry;
              });
            }
            setState({
              ...state,
              isLoading: false,
              data: results ? results : [],
            });
          }
        })
        .catch((err) => {
          setState({
            ...state,
            isLoading: false,
          });
          message.error("Connection error: " + err.message);
        });
    };
    getStats();
  }, [searchString]);

  return (
    <Card key="study-results-" className="slightly-frosted-card top-squared" bordered={false}>
      <Title key="phase-title" level={5}>
        Phase
      </Title>
      <Break key="phase-break" height={24} />
      <Column
        loading={state.isLoading}
        loadingTemplate={<Spin />}
        key="phase-chart"
        data={state.data}
        {...config}
        label={{
          position: "middle",
        }}
      />
    </Card>
  );
};

export default PhaseChart;
