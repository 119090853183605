import { Result, Button } from "antd";

interface ErrorProps {
  error: "404" | "500";
}

const Error: React.FC<ErrorProps> = ({ error }) => {
  const subTitle = error === "404" ? "Sorry, the page you visited does not exist" : "Sorry, something went wrong";
  return (
    <Result
      status={error}
      title={error}
      subTitle={subTitle}
      extra={
        <Button href="/" type="primary">
          Back Home
        </Button>
      }
    />
  );
};

export default Error;
