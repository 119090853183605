import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Collapse, Skeleton, Spin, Typography, message } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import Break from "../../../assets/components/other/Break";
import { FullStudy } from "../../../data/interfaces/study/Study";
import { headers, sendQuery } from "../../../data/api/config";
import { getStudy } from "../../../data/api/queries";
import Details from "./Details";

interface StudyState {
  isLoading: boolean;
  study: FullStudy | null;
}

const { Panel } = Collapse;
const { Text, Title } = Typography;

const Study: React.FC = () => {
  const { pathname } = useLocation();
  const id = pathname.split("study/")[1];

  const [state, setState] = useState<StudyState>({
    isLoading: false,
    study: null,
  });

  const getStudyDetails = (studyId: string) => {
    setState({
      ...state,
      isLoading: true,
    });
    const query = getStudy;
    const variables = { id: studyId };
    sendQuery(query, variables, headers)
      .then((data) => {
        if ("errors" in data) {
          setState({
            ...state,
            isLoading: false,
          });
          message.error("Failed to fetch the data");
        } else {
          let result;
          if (data?.data?.getStudy) {
            result = data.data.getStudy;
          }
          console.log(result);
          setState({
            ...state,
            isLoading: false,
            study: result,
          });
        }
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        message.error("Connection error: " + err.message);
      });
  };

  useEffect(() => {
    getStudyDetails(id);
  }, []);

  return (
    <Card>
      <Title key="study-title" level={5}>
        {"Study " + id + ": " + state.study?.brief_title}
      </Title>
      <Break key="study-title-break" height={4} />

      <Collapse
        key="study-collapse"
        className="custom-collapse"
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        bordered={false}
      >
        <Panel header="Study Details" key="1" className="custom-panel">
          <Details key="details" study={state.study} loading={state.isLoading} />
          <Break key="highlight-tables-break" height={20} />
          <Text key="study-summary-title" strong>
            Brief summary
          </Text>
          <Break key="study-summary-title-break" height={4} />
          {state.isLoading ? <Skeleton active /> : <Text key="study-summary">{state.study?.brief_summary}</Text>}
          <Break key="study-summary-break" height={16} />
          <Text key="study-description-title" strong>
            Detailed description
          </Text>
          <Break key="study-description-title-break" height={4} />
          <Text key="study-description">
            {state.isLoading ? (
              <Skeleton active />
            ) : (
              state.study?.detailed_description.split("*#*").map((paragraph, index) => {
                return (
                  <div key={"desc-container-" + index}>
                    <Text key={"desc-paragraph-" + index}>{paragraph}</Text>
                    <Break key={"desc-break-" + index} height={4} />
                  </div>
                );
              })
            )}
          </Text>

          <Break key="study-description-break" height={8} />
        </Panel>
        <Panel key="2" header="Condition/Treatment Details" className="custom-panel">
          Under Development
        </Panel>
        <Panel key="3" header="Eligibility Criteria" className="custom-panel">
          Under Development
        </Panel>
        <Panel key="4" header="Patient Details" className="custom-panel">
          Under Development
        </Panel>
        <Panel key="5" header="Documents" className="custom-panel">
          Under Development
        </Panel>
      </Collapse>
    </Card>
  );
};

export default Study;
