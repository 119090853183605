import { Button, Modal, Table } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

interface ComparisonProps {
  isVisible: boolean;
  onClose: () => void;
  data: any[];
}

const columns: ColumnsType<any> = [{ key: "a" }];

const Comparison: React.FC<ComparisonProps> = ({ isVisible, onClose, data }) => {
  return (
    <Modal
      style={{ top: 50 }}
      width={1000}
      title="Comparison"
      closeIcon={<CloseCircleOutlined />}
      visible={isVisible}
      bodyStyle={{
        backgroundColor: "lightgray",
        boxShadow: "inset 0 -3px 5px 0px rgb(0 0 0 / 20%), inset 0 3px 5px 2px rgb(0 0 0 / 20%)",
      }}
      footer={[
        <Button key="btn-ok" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      <Table
        key="studies-table"
        pagination={false}
        bordered={false}
        scroll={{ x: "1000", y: 1100 }}
        // className="trials-table"
        rowSelection={{
          type: "checkbox",
        }}
        columns={columns}
        dataSource={data}
        loading={true}
        footer={() => ""}
      />
    </Modal>
  );
};

export default Comparison;
