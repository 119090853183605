import { useState } from "react";
import { Form, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { conditions } from "../../../data/local/conditions";
import { drugs } from "../../../data/local/drugs";
import { getGlossaryTermByKeyword } from "../../js/utils";

interface ASelectWithInputSearchProps {
  keyword: "condition" | "drug";
  multiple?: boolean;
  disabled?: boolean;
  defaultName?: string;
}

interface SelectOption {
  ui: string;
  name: string;
}

interface ASelectWithInputSearchState {
  value: string;
  options: SelectOption[];
}

const { Option } = Select;

const ASelectWithInputSearch: React.FC<ASelectWithInputSearchProps> = ({
  keyword,
  multiple,
  disabled,
  defaultName,
}) => {
  let term = getGlossaryTermByKeyword(keyword);
  if (!term) {
    term = {
      keyword: "undefined",
      term: "Undefined",
      definition: "Undefined",
      source: "Undefined",
      url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
    };
  }

  const [state, setState] = useState<ASelectWithInputSearchState>({
    value: "",
    options: defaultName ? [] : [],
  });

  const findCondition = (input: string) => {
    const foundOptions: SelectOption[] = [];
    for (let condition of conditions) {
      if (condition.heading.toLowerCase().includes(input.toLowerCase()) && foundOptions.length <= 10) {
        const newOption = {
          ui: condition.ui,
          name: condition.heading,
        };
        foundOptions.push(newOption);
      }
      if (condition.synonyms) {
        condition.synonyms.forEach((synonym) => {
          if (synonym.toLowerCase().includes(input.toLowerCase())) {
            const newOption = {
              ui: condition.ui,
              name: synonym,
            };
            foundOptions.push(newOption);
          }
        });
      }
      if (foundOptions.length > 10) {
        break;
      }
    }
    setState({
      ...state,
      options: foundOptions,
    });
  };

  const findDrug = (input: string) => {
    const foundOptions: SelectOption[] = [];
    for (let drug of drugs) {
      drug.names?.forEach((name) => {
        if (name?.toLowerCase().includes(input.toLowerCase())) {
          const newOption = {
            ui: drug.primary_id,
            name: name,
          };
          foundOptions.push(newOption);
        }
      });

      if (foundOptions.length > 10) {
        break;
      }
    }
    setState({
      ...state,
      options: foundOptions,
    });
  };

  const onClear = () => {
    setState({
      ...state,
      value: "",
      options: [],
    });
  };

  const onSearch = (input: string) => {
    if (input.length >= 3) {
      setState({
        ...state,
        value: input,
      });
      keyword === "condition" ? findCondition(input) : findDrug(input);
    } else {
      setState({
        ...state,
        value: "",
        options: [],
      });
    }
  };

  return (
    <Form.Item
      label={term.term}
      tooltip={{
        title: term.definition,
        icon: <InfoCircleOutlined />,
      }}
      name={term.keyword}
      key={term.keyword}
    >
      <Select
        disabled={disabled ? true : false}
        value={state.value}
        mode={multiple ? "multiple" : undefined}
        showSearch
        optionFilterProp="children"
        allowClear
        placeholder={`Type to select ${term.keyword}`}
        filterOption={false}
        onSearch={onSearch}
        onClear={onClear}
      >
        {state.options.map((entry, index) => (
          <Option key={entry.ui + index} value={entry.ui + "-" + index}>
            {entry.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ASelectWithInputSearch;
