import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import logo from "./logo-text.png";
import "./PublicLayoutLogo.css";

const PublicLayoutLogo: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Button
      type="link"
      onClick={() => {
        navigate("/");
      }}
    >
      <img src={logo} className="home-logo" alt="logo" />
    </Button>
  );
};

export default PublicLayoutLogo;
