import { Button, Card, Col, Row, Space } from "antd";

interface SearchControlPanelProps {
  index: number;
  form: any;
  clearSearchParams: () => void;
  updateData: () => void;
}

const SearchControlPanel: React.FC<SearchControlPanelProps> = ({ index, form, clearSearchParams, updateData }) => {
  return (
    <Row className="mg-btm-16" key={"btn-panel-" + index} gutter={16} justify="space-between">
      <Col span={24}>
        <Card bordered={false}>
          <Space>
            <Button key={"btn-search-" + index} type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              key={"btn-clear-" + index}
              onClick={() => {
                form.resetFields();
                clearSearchParams();
                updateData();
              }}
            >
              Clear
            </Button>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default SearchControlPanel;
