import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Form, Row, Space, Typography } from "antd";
import SearchControlPanel from "./SearchControlPanel";
import StudySearch from "./modules/StudySearch";
import ConditionSearch from "./modules/ConditionSearch";
import PatientSearch from "./modules/PatientSearch";
import Break from "../../../assets/components/other/Break";
import SearchContext from "../../../data/api/SearchContext";
import { SearchParams } from "../../../data/interfaces/SearchParams";
import { getParamsByValues, getValuesByParams } from "../../../assets/js/utils";

const { Title } = Typography;

const Search: React.FC = () => {
  let navigate = useNavigate();
  const { getSearchParams, setSearchParams, clearSearchParams } = useContext(SearchContext);
  const searchParams = getSearchParams();
  console.log("Search Params in Search", searchParams);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Values in Search", values);
    const newSearchParams: SearchParams = getParamsByValues(values);
    console.log("New Search Params in Search", newSearchParams);
    setSearchParams(newSearchParams);
    navigate("/studies");
  };

  useEffect(() => {
    const defaultValues = getValuesByParams(searchParams);
    form.setFieldsValue(defaultValues);
  }, [form, searchParams]);

  return (
    <Card key="study-search" className="frosted-card" bordered={false}>
      <Space>
        <Title key="search-title" level={4}>
          Search all studies
        </Title>
        <Title style={{ fontWeight: "100" }} type="secondary" key="search-title-comment" level={4}>
          (all fields are optional)
        </Title>
      </Space>
      <Break height={12} />
      <Form form={form} name="search-form" layout="vertical" onFinish={onFinish}>
        {/* {buttons(1)} */}
        <SearchControlPanel index={1} form={form} clearSearchParams={clearSearchParams} />
        <Row className="mg-btm-16" key="main-search" gutter={16}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <StudySearch />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <ConditionSearch />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <PatientSearch />
          </Col>
        </Row>
        <SearchControlPanel index={2} form={form} clearSearchParams={clearSearchParams} />
      </Form>
    </Card>
  );
};

export default Search;
