import { Glossary } from "../interfaces/Glossary";

export const glossary: Glossary[] = [
  {
    keyword: "nct",
    term: "NCT Number",
    definition:
      "A unique identification code given to each clinical study record registered on ClinicalTrials.gov. The format is 'NCT' followed by an 8-digit number (for example, NCT00000419). Also called the ClinicalTrials.gov identifier.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "type",
    term: "Study Type",
    definition:
      "Describes the nature of a clinical study. Study types include interventional studies (also called clinical trials), observational studies (including patient registries), and expanded access.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "condition",
    term: "Condition or Disease",
    definition:
      "The disease, disorder, syndrome, illness, or injury that is being studied. Conditions may also include other health-related issues, such as lifespan, quality of life, and health risks.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "area",
    term: "Therapeutic Area",
    definition:
      "The grouping of similar diseases or conditions under a generalised heading, such as diabetes, oncology, or cardiovascular diseases. Therapeutic Area is an inferred search feature. It is based on the MeSH classification and is parsed by the tree number of the MeSH condition specified in the study.",
    source: "Aucrum",
    url: "",
  },
  {
    keyword: "terms",
    term: "Other terms",
    definition:
      "In the search feature, the Other (Additional) Terms field is used to narrow a search. For example, you may enter the name of a drug or the NCT number of a clinical study to limit the search to study records that contain these words.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "severity",
    term: "Severity",
    definition:
      "Severity of illness (SOI) is defined as the extent of organ system derangement or physiologic decompensation for a patient. It gives a medical classification into minor, moderate, major, and extreme. The SOI class is meant to provide a basis for evaluating hospital resource use or to establish patient care guidelines. Note: severity is a parameter parsed from the study description. It is based on mentions of keywords, such as 'mild', 'severe', or 'metastatic'",
    source: "Wikipedia",
    url: "https://en.wikipedia.org/wiki/Severity_of_illness",
  },
  {
    keyword: "intervention",
    term: "Intervention / Treatment",
    definition:
      "A process or action that is the focus of a clinical study. Interventions include drugs, medical devices, procedures, vaccines, and other products that are either investigational or already available. Interventions can also include noninvasive approaches, such as education or modifying diet and exercise.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "drug",
    term: "Drug",
    definition:
      "A drug is any chemical substance that causes a change in an organism's physiology or psychology when consumed. As a search parameter, it is more specific than intervention / treatment.",
    source: "Wikipedia",
    url: "https://en.wikipedia.org/wiki/Drug",
  },
  {
    keyword: "roa",
    term: "Routes of Administration",
    definition:
      "A route of administration is the path by which a drug, fluid, or other substance is taken into the body.",
    source: "Wikipedia",
    url: "https://en.wikipedia.org/wiki/Route_of_administration",
  },
  {
    keyword: "target",
    term: "Target",
    definition:
      "A target is anything within a living organism to which some other entity (like an endogenous ligand or a drug) is directed and/or binds, resulting in a change in its behavior or function. Examples of common classes of biological targets are proteins and nucleic acids. Biological targets are most commonly proteins such as enzymes, ion channels, and receptors.",
    source: "Wikipedia",
    url: "https://en.wikipedia.org/wiki/Biological_target",
  },
  {
    keyword: "title",
    term: "Title or Acronym",
    definition:
      "The official title of a protocol used to identify a clinical study or a short title written in language intended for the lay public. The acronym or initials used to identify a clinical study (not all studies have one). For example, the title acronym for the Women's Health Initiative is 'WHI.'",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "status",
    term: "Recruitment Status",
    definition: "Indicates the current recruitment status",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "phase",
    term: "Phase",
    definition:
      "The stage of a clinical trial studying a drug or biological product, based on definitions developed by the U.S. Food and Drug Administration (FDA). The phase is based on the study's objective, the number of participants, and other characteristics. There are five phases: Early Phase 1 (formerly listed as Phase 0), Phase 1, Phase 2, Phase 3, and Phase 4. Not Applicable is used to describe trials without FDA-defined phases, including trials of devices or behavioral interventions.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "sponsor",
    term: "Sponsor / Collaborators",
    definition:
      "Sponsor is the organization or person who initiates the study and who has authority and control over the study. Collaborator is an organization other than the sponsor that provides support for a clinical study. This support may include activities related to funding, design, implementation, data analysis, or reporting.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "results",
    term: "Study Results",
    definition:
      "A study record that includes the summary results posted in the ClinicalTrials.gov results database. Summary results information includes participant flow, baseline characteristics, outcome measures, and adverse events (including serious adverse events).",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "age",
    term: "Age",
    definition:
      "A type of eligibility criteria that indicates the age a person must be to participate in a clinical study. This may be indicated by a specific age or the following age groups: Child (birth-17); Adult (18-64); Older Adult (65+).",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "group",
    term: "Age Group",
    definition:
      "A type of eligibility criteria that indicates the age a person must be to participate in a clinical study. This may be indicated by a specific age or the following age groups: Child (birth-17); Adult (18-64); Older Adult (65+).",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "healthy",
    term: "Is Healthy?",
    definition:
      "A type of eligibility criteria that indicates whether people who do not have the condition/disease being studied can participate in that clinical study.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "sex",
    term: "Sex",
    definition:
      "A type of eligibility criteria that indicates the sex of people who may participate in a clinical study (all, female, male). Sex is a person's classification as female or male based on biological distinctions.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "ancestry",
    term: "Ancestry / Race",
    definition:
      "A race is a categorization of humans based on shared physical or social qualities into groups generally viewed as distinct within a given society. Ancestry is a parsed parameter. It is based on either explicit mentions in the eligibility inclusion/exclusion criteria, or the prevailing ancestry of the population in the locations where the study was conducted.",
    source: "Wikipedia",
    url: "https://en.wikipedia.org/wiki/Race_(human_categorization)",
  },
  {
    keyword: "country",
    term: "Country",
    definition:
      "In the search feature, the Country field is used to find clinical studies with locations in a specific country. For example, if you choose the United States, you can then narrow your search by selecting a state and identifying a city and distance.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "state",
    term: "State",
    definition:
      "In the search feature, the State field is used to find clinical studies with locations in a specific state within the United States. If you choose United States in the Country field, you can search for studies with locations in a specific state.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
  {
    keyword: "city",
    term: "City",
    definition:
      "In the search feature, the City field is used to find clinical studies with locations in a specific city.",
    source: "ClinicalTrials.gov",
    url: "https://clinicaltrials.gov/ct2/about-studies/glossary",
  },
];
