import { Card, Col, Form, Row, Space, Typography } from "antd";
import World from "../../../assets/components/other/world/World";
import BasicSearch from "./basicsearch/BasicSearch";

const Home: React.FC = () => {
  return (
    <Card key="study-search" className="frosted-card">
      <Row className="mg-btm-16" key="main-home" gutter={16}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <World />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <BasicSearch />
        </Col>
      </Row>
    </Card>
  );
};

export default Home;
