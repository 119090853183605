let url = process.env.REACT_APP_API_HOSTNAME!;

export const headers: HeadersInit = {
  "Content-Type": "application/json",
  Authorization: "",
};

export async function sendQuery(query: string, variables: any, headers: HeadersInit): Promise<any> {
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ query, variables }), // Coordinate the body type with 'Content-Type'
  });
  return await response.json(); // convert to JSON
}
