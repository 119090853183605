export const config = {
  height: 300,
  xField: "name",
  yField: "count",
  maxColumnWidth: 40,
  columnStyle: {
    radius: [4, 4, 0, 0],
    shadowColor: "grey",
    shadowBlur: 10,
    shadowOffsetX: 5,
    shadowOffsetY: 3,
  },
  xAxis: {
    label: {
      offset: 24,
      formatter: (name: string) => {
        return name.length > 10 ? name.substring(0, 10) + "..." : name;
      },
    },
    line: {
      style: {
        stroke: "lightgrey",
        lineWidth: 2,
      },
    },
  },
  color: "l(270) 0:#48a9fe 1:#02c0db",
  label: {
    position: "middle",
    style: {
      fill: "#FFFFFF",
      opacity: 0.8,
      shadowColor: "gray",
      shadowBlur: 5,
    },
  },
};
