import { Card, Typography } from "antd";
import AInput from "../../../../assets/components/inputs/AInput";
import ASelect from "../../../../assets/components/inputs/ASelect";
import AInputWithPreselect from "../../../../assets/components/inputs/AInputWithPreselect";
import Break from "../../../../assets/components/other/Break";

const { Title } = Typography;

const PatientSearch: React.FC = () => {
  return (
    <Card key="study-search" bordered={false}>
      <Title level={5}>Patient Details</Title>
      <Break height={8} />
      <AInputWithPreselect keyword="age" />
      <ASelect keyword="healthy" />
      <ASelect keyword="sex" />
      <ASelect keyword="ancestry" multiple />
      <ASelect keyword="country" select />
      <AInput keyword="city" disabled />
    </Card>
  );
};

export default PatientSearch;
