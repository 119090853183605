import { useContext, useEffect } from "react";
import { Collapse, Drawer, Form } from "antd";
import { CaretRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import AInput from "../../../../assets/components/inputs/AInput";
import AInputWithPreselect from "../../../../assets/components/inputs/AInputWithPreselect";
import ASelect from "../../../../assets/components/inputs/ASelect";
import ASelectWithInputSearch from "../../../../assets/components/inputs/ASelectWithInputSearch";
import SearchContext from "../../../../data/api/SearchContext";
import { SearchParams } from "../../../../data/interfaces/SearchParams";
import { getParamsByValues, getValuesByParams } from "../../../../assets/js/utils";
import FacetedSearchControlPanel from "./FacetedSearchControlPanel";

interface FacetedSearchProps {
  isVisible: boolean;
  onClose: () => void;
  updateData: () => void;
}

const { Panel } = Collapse;

const FacetedSearch: React.FC<FacetedSearchProps> = ({ isVisible, onClose, updateData }) => {
  const [form] = Form.useForm();
  const { getSearchParams, setSearchParams, clearSearchParams } = useContext(SearchContext);
  const searchParams = getSearchParams();

  const onFinish = (values: any) => {
    const newSearchParams: SearchParams = getParamsByValues(values);
    setSearchParams(newSearchParams);
    updateData();
  };

  useEffect(() => {
    const defaultValues = getValuesByParams(searchParams);
    form.setFieldsValue(defaultValues);
  }, [form, searchParams]);

  return (
    <Drawer
      title="Search"
      placement="right"
      bodyStyle={{ backgroundColor: "rgba(245, 255, 255, 0.5)" }}
      onClose={onClose}
      visible={isVisible}
      closeIcon={<CloseCircleOutlined />}
    >
      <Form form={form} name="search-form" layout="vertical" onFinish={onFinish}>
        <FacetedSearchControlPanel
          index={1}
          form={form}
          clearSearchParams={clearSearchParams}
          updateData={updateData}
        />
        <Collapse
          className="custom-collapse"
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          bordered={false}
        >
          <Panel header="Study Details" key="1" className="custom-panel">
            <ASelect keyword="status" select multiple />
            <ASelect keyword="phase" multiple />
            <AInput keyword="sponsor" />
            <ASelect keyword="type" select />
          </Panel>
          <Panel header="Condition/Treatment Details" key="2" className="custom-panel">
            <ASelectWithInputSearch keyword="condition" />
            <ASelect keyword="area" select />
            <ASelect keyword="severity" multiple />
            <AInput keyword="intervention" />
            {/* <ASelectWithInputSearch keyword="drug" /> */}
            <ASelect keyword="roa" select multiple />
            <AInput keyword="target" />
          </Panel>
          <Panel header="Patient Details" key="3" className="custom-panel">
            <AInputWithPreselect keyword="age" />
            <ASelect keyword="healthy" />
            <ASelect keyword="sex" />
            <ASelect keyword="ancestry" multiple />
            <ASelect keyword="country" select />
          </Panel>
        </Collapse>
      </Form>
    </Drawer>
  );
};

export default FacetedSearch;
