export interface Condition {
  ui: string;
  heading: string;
  tree_numbers: string[];
  synonyms?: string[];
}

export const conditions: Condition[] = [
  {
    ui: "D000006",
    heading: "Abdomen, Acute",
    tree_numbers: ["C23.888.592.612.054.200", "C23.888.821.030.249"],
  },
  {
    ui: "D000007",
    heading: "Abdominal Injuries",
    tree_numbers: ["C26.017"],
  },
  {
    ui: "D000008",
    heading: "Abdominal Neoplasms",
    tree_numbers: ["C04.588.033"],
  },
  {
    ui: "D000012",
    heading: "Abetalipoproteinemia",
    tree_numbers: ["C16.320.565.398.500.440.500", "C18.452.584.500.875.440.500", "C18.452.648.398.500.440.500"],
    synonyms: ["Familial Hypobetalipoproteinemia", "Microsomal Triglyceride Transfer Protein Deficiency Disease"],
  },
  {
    ui: "D000013",
    heading: "Congenital Abnormalities",
    tree_numbers: ["C16.131"],
  },
  {
    ui: "D000014",
    heading: "Abnormalities, Drug-Induced",
    tree_numbers: ["C16.131.042"],
  },
  {
    ui: "D000015",
    heading: "Abnormalities, Multiple",
    tree_numbers: ["C16.131.077"],
  },
  {
    ui: "D000016",
    heading: "Abnormalities, Radiation-Induced",
    tree_numbers: [
      "C16.131.080",
      "C26.733.031",
      "G01.750.748.500.031",
      "N06.850.460.350.850.500.031",
      "N06.850.810.300.360.031",
    ],
  },
  {
    ui: "D000022",
    heading: "Abortion, Spontaneous",
    tree_numbers: ["C13.703.039", "G08.686.784.769.496.125"],
  },
  {
    ui: "D000026",
    heading: "Abortion, Habitual",
    tree_numbers: ["C13.703.039.089"],
  },
  {
    ui: "D000027",
    heading: "Abortion, Incomplete",
    tree_numbers: ["C13.703.039.093"],
  },
  {
    ui: "D000030",
    heading: "Abortion, Missed",
    tree_numbers: ["C13.703.039.173"],
  },
  {
    ui: "D000031",
    heading: "Abortion, Septic",
    tree_numbers: ["C01.674.173", "C13.703.039.256", "C13.703.700.173"],
  },
  {
    ui: "D000033",
    heading: "Abortion, Threatened",
    tree_numbers: ["C13.703.090"],
    synonyms: ["Abruptio Placenta", "Abruptio Placentae"],
  },
  {
    ui: "D000034",
    heading: "Abortion, Veterinary",
    tree_numbers: ["C13.703.039.422", "C22.021"],
  },
  {
    ui: "D000037",
    heading: "Abruptio Placentae",
    tree_numbers: ["C13.703.420.078", "C13.703.590.132"],
  },
  {
    ui: "D000038",
    heading: "Abscess",
    tree_numbers: ["C01.830.025", "C23.550.470.756.100"],
  },
  {
    ui: "D000039",
    heading: "Peritonsillar Abscess",
    tree_numbers: [
      "C01.748.561.750.500",
      "C01.830.025.675",
      "C07.550.781.750.500",
      "C08.730.561.750.500",
      "C09.775.649.750.500",
    ],
  },
  {
    ui: "D000051",
    heading: "Acantholysis",
    tree_numbers: ["C17.800.865.070", "C23.550.035"],
  },
  {
    ui: "D000052",
    heading: "Acanthosis Nigricans",
    tree_numbers: ["C17.800.621.430.530.100"],
    synonyms: ["Keratosis Nigricans", "Keratosis Nigricans", "Acanthosis Nigricans", "AN - Acanthosis Nigricans"],
  },
  {
    ui: "D000067011",
    heading: "Severe Acute Malnutrition",
    tree_numbers: ["C18.654.521.719"],
  },
  {
    ui: "D000067073",
    heading: "Psychological Trauma",
    tree_numbers: ["F03.950.750.375"],
  },
  {
    ui: "D000067208",
    heading: "Shellfish Hypersensitivity",
    tree_numbers: ["C20.543.480.370.763"],
  },
  {
    ui: "D000067251",
    heading: "Symptom Flare Up",
    tree_numbers: ["C23.550.291.937.500"],
  },
  {
    ui: "D000067329",
    heading: "Obesity, Metabolically Benign",
    tree_numbers: [
      "C18.654.726.500.650",
      "C23.888.144.699.500.250",
      "E01.370.600.115.100.160.120.699.500.375",
      "G07.100.100.160.120.699.500.375",
    ],
  },
  {
    ui: "D000067390",
    heading: "Cold Injury",
    tree_numbers: ["C26.212"],
  },
  {
    ui: "D000067398",
    heading: "War-Related Injuries",
    tree_numbers: ["C26.946", "I01.880.735.950.500.951.500", "N06.850.460.350.600.903.500"],
  },
  {
    ui: "D000067404",
    heading: "Social Communication Disorder",
    tree_numbers: ["F03.625.374.250"],
  },
  {
    ui: "D000067454",
    heading: "Childhood-Onset Fluency Disorder",
    tree_numbers: ["F03.625.374.125"],
  },
  {
    ui: "D000067559",
    heading: "Specific Learning Disorder",
    tree_numbers: ["F03.625.562.700"],
  },
  {
    ui: "D000067562",
    heading: "Late Onset Disorders",
    tree_numbers: ["C23.550.291.883"],
  },
  {
    ui: "D000067836",
    heading: "Hoarding Disorder",
    tree_numbers: ["F03.080.600.250"],
  },
  {
    ui: "D000067877",
    heading: "Autism Spectrum Disorder",
    tree_numbers: ["F03.625.164.113"],
  },
  {
    ui: "D000068079",
    heading: "Motor Disorders",
    tree_numbers: ["F03.608"],
  },
  {
    ui: "D000068099",
    heading: "Trauma and Stressor Related Disorders",
    tree_numbers: ["F03.950"],
  },
  {
    ui: "D000068105",
    heading: "Bipolar and Related Disorders",
    tree_numbers: ["F03.084"],
  },
  {
    ui: "D000068116",
    heading: "Gender Dysphoria",
    tree_numbers: ["F03.835.550"],
  },
  {
    ui: "D000068376",
    heading: "Compassion Fatigue",
    tree_numbers: ["C23.888.369.500.500", "F01.145.126.937.500", "F01.145.126.990.734.500", "F02.830.900.666.750"],
  },
  {
    ui: "D000069076",
    heading: "Fractures, Multiple",
    tree_numbers: ["C26.404.280", "C26.640.500"],
  },
  {
    ui: "D000069279",
    heading: "Drug Resistant Epilepsy",
    tree_numbers: ["C10.228.140.490.125"],
  },
  {
    ui: "D000069281",
    heading: "Autoimmune Hypophysitis",
    tree_numbers: ["C10.228.140.617.738.275.500", "C19.700.419.500", "C20.111.273"],
  },
  {
    ui: "D000069282",
    heading: "Canaliculitis",
    tree_numbers: ["C11.496.221.500"],
  },
  {
    ui: "D000069290",
    heading: "Incisional Hernia",
    tree_numbers: ["C23.300.707.945", "C23.550.767.500"],
  },
  {
    ui: "D000069293",
    heading: "Plasmablastic Lymphoma",
    tree_numbers: ["C04.557.386.480.150.585.500", "C15.604.515.569.480.150.585.500", "C20.683.515.761.480.150.585.500"],
  },
  {
    ui: "D000069295",
    heading: "Mammary Analogue Secretory Carcinoma",
    tree_numbers: ["C04.557.470.200.588"],
  },
  {
    ui: "D000069316",
    heading: "Acne Conglobata",
    tree_numbers: ["C17.800.030.150.500", "C17.800.794.111.500"],
  },
  {
    ui: "D000069337",
    heading: "Fused Kidney",
    tree_numbers: ["C12.706.445", "C13.351.875.420", "C16.131.939.445"],
  },
  {
    ui: "D000069451",
    heading: "Long Term Adverse Effects",
    tree_numbers: ["C23.550.543"],
  },
  {
    ui: "D000069544",
    heading: "Infectious Encephalitis",
    tree_numbers: ["C01.207.399", "C10.228.140.430.520", "C10.228.228.399"],
  },
  {
    ui: "D000069578",
    heading: "Waterborne Diseases",
    tree_numbers: ["C01.936"],
  },
  {
    ui: "D000069584",
    heading: "Unilateral Breast Neoplasms",
    tree_numbers: ["C04.588.180.800", "C17.800.090.500.682"],
  },
  {
    ui: "D000069836",
    heading: "Degloving Injuries",
    tree_numbers: ["C26.808.500"],
  },
  {
    ui: "D000069856",
    heading: "Staghorn Calculi",
    tree_numbers: [
      "C12.777.419.600.500.500",
      "C12.777.967.249.500.500",
      "C12.777.967.500.503.500",
      "C13.351.968.419.600.500.500",
      "C13.351.968.967.249.500.500",
      "C13.351.968.967.500.503.500",
      "C23.300.175.850.550.500",
    ],
  },
  {
    ui: "D000070558",
    heading: "Talipes",
    tree_numbers: ["C05.330.488.655", "C05.330.495.681", "C05.660.585.512.380.813", "C16.131.621.585.512.500.681"],
  },
  {
    ui: "D000070589",
    heading: "Talipes Cavus",
    tree_numbers: [
      "C05.330.488.655.500",
      "C05.330.495.681.500",
      "C05.660.585.512.380.813.500",
      "C16.131.621.585.512.500.681.500",
    ],
  },
  {
    ui: "D000070591",
    heading: "Metatarsal Valgus",
    tree_numbers: ["C05.330.663"],
  },
  {
    ui: "D000070592",
    heading: "Metatarsus Varus",
    tree_numbers: ["C05.330.711"],
  },
  {
    ui: "D000070598",
    heading: "Anterior Cruciate Ligament Injuries",
    tree_numbers: ["C26.558.554.213"],
  },
  {
    ui: "D000070599",
    heading: "Shoulder Injuries",
    tree_numbers: ["C26.803"],
  },
  {
    ui: "D000070600",
    heading: "Tibial Meniscus Injuries",
    tree_numbers: ["C26.558.781"],
  },
  {
    ui: "D000070603",
    heading: "Coxa Magna",
    tree_numbers: ["C05.116.296"],
  },
  {
    ui: "D000070604",
    heading: "Tarsal Coalition",
    tree_numbers: [
      "C05.116.099.370.894.909",
      "C05.330.495.787",
      "C05.660.585.512.380.875",
      "C05.660.906.909",
      "C16.131.621.585.512.500.787",
      "C16.131.621.906.909",
    ],
  },
  {
    ui: "D000070607",
    heading: "Morton Neuroma",
    tree_numbers: [
      "C05.360.500.500",
      "C05.550.610.500",
      "C10.668.829.600.375",
      "C23.888.592.612.540.500",
      "C23.888.592.612.664.275",
    ],
  },
  {
    ui: "D000070617",
    heading: "Microtrauma, Physical",
    tree_numbers: ["C26.599"],
  },
  {
    ui: "D000070624",
    heading: "Brain Contusion",
    tree_numbers: ["C10.228.140.199.444.375", "C10.900.300.087.235.375", "C26.915.300.200.194.375", "C26.974.250.500"],
  },
  {
    ui: "D000070625",
    heading: "Brain Injuries, Diffuse",
    tree_numbers: ["C10.228.140.199.388", "C10.900.300.087.219", "C26.915.300.200.188"],
  },
  {
    ui: "D000070627",
    heading: "Chronic Traumatic Encephalopathy",
    tree_numbers: [
      "C10.228.140.199.444.500",
      "C10.228.140.199.500.500",
      "C10.574.250",
      "C10.900.300.087.235.500",
      "C10.900.300.087.250.500",
      "C26.915.300.200.194.500",
      "C26.915.300.200.200.500",
    ],
  },
  {
    ui: "D000070630",
    heading: "Diastasis, Muscle",
    tree_numbers: ["C05.550.518.288", "C26.289.288"],
  },
  {
    ui: "D000070631",
    heading: "Diastasis, Bone",
    tree_numbers: ["C05.550.518.192", "C26.289.192"],
  },
  {
    ui: "D000070636",
    heading: "Rotator Cuff Injuries",
    tree_numbers: ["C26.761.340", "C26.803.063", "C26.874.400"],
  },
  {
    ui: "D000070639",
    heading: "Elbow Tendinopathy",
    tree_numbers: ["C05.651.869.435", "C26.874.800.500"],
  },
  {
    ui: "D000070642",
    heading: "Brain Injuries, Traumatic",
    tree_numbers: ["C10.228.140.199.444", "C10.900.300.087.235", "C26.915.300.200.194"],
  },
  {
    ui: "D000070656",
    heading: "Rotator Cuff Tear Arthropathy",
    tree_numbers: ["C05.550.114.264.500", "C05.550.354.250"],
  },
  {
    ui: "D000070657",
    heading: "Crystal Arthropathies",
    tree_numbers: ["C05.550.354"],
  },
  {
    ui: "D000070676",
    heading: "Enthesopathy",
    tree_numbers: ["C05.651.869.653", "C26.874.800.750"],
  },
  {
    ui: "D000070779",
    heading: "Giant Cell Tumor of Tendon Sheath",
    tree_numbers: ["C04.557.450.565.380.690", "C05.550.870.445", "C05.651.869.762"],
    synonyms: [
      "Benign Tumor Of Synovium",
      "Synovioma, Benign",
      "Localized Giant Cell Tumor Of Tenosynovium",
      "Benign Synovioma",
    ],
  },
  {
    ui: "D000070896",
    heading: "Bankart Lesions",
    tree_numbers: ["C26.404.625.500", "C26.803.250.500"],
  },
  {
    ui: "D000071017",
    heading: "Hyperekplexia",
    tree_numbers: ["C10.228.590"],
    synonyms: ["Hkpx1"],
  },
  {
    ui: "D000071056",
    heading: "Anorectal Malformations",
    tree_numbers: ["C06.198.025", "C16.131.314.047"],
    synonyms: ["Anorectal Stricture", "Stenosis Of Rectum And Anus"],
  },
  {
    ui: "D000071057",
    heading: "Tardive Dyskinesia",
    tree_numbers: ["C10.228.662.262.500.500", "C10.597.350.275.500", "C23.888.592.350.275.500"],
  },
  {
    ui: "D000071064",
    heading: "Alert Fatigue, Health Personnel",
    tree_numbers: [
      "C23.888.369.500.250",
      "F01.100.050.500",
      "F01.145.126.937.250",
      "N04.761.700.075",
      "N05.300.100.169",
      "N05.700.075",
    ],
  },
  {
    ui: "D000071067",
    heading: "Pneumonia, Necrotizing",
    tree_numbers: ["C01.748.610.608", "C08.381.677.608", "C08.730.610.608"],
  },
  {
    ui: "D000071069",
    heading: "Multiple Chronic Conditions",
    tree_numbers: ["C23.550.291.500.500"],
  },
  {
    ui: "D000071070",
    heading: "Familial Multiple Lipomatosis",
    tree_numbers: ["C16.320.298", "C17.800.463.375", "C18.452.584.718.625"],
  },
  {
    ui: "D000071071",
    heading: "Microaneurysm",
    tree_numbers: ["C14.907.055.817"],
  },
  {
    ui: "D000071072",
    heading: "Acute Febrile Encephalopathy",
    tree_numbers: ["C10.228.140.021"],
  },
  {
    ui: "D000071074",
    heading: "Neonatal Sepsis",
    tree_numbers: ["C01.757.580", "C16.614.627", "C23.550.470.790.500.470"],
  },
  {
    ui: "D000071075",
    heading: "Small Fiber Neuropathy",
    tree_numbers: ["C10.668.829.860"],
  },
  {
    ui: "D000071078",
    heading: "Stenosis, Pulmonary Vein",
    tree_numbers: ["C14.907.798"],
  },
  {
    ui: "D000071079",
    heading: "Stenosis, Pulmonary Artery",
    tree_numbers: ["C14.907.137.825"],
  },
  {
    ui: "D000071243",
    heading: "Zika Virus Infection",
    tree_numbers: ["C01.920.500.990", "C01.925.081.990", "C01.925.782.350.250.990"],
    synonyms: ["Zika Virus Disease"],
  },
  {
    ui: "D000071257",
    heading: "Emergence Delirium",
    tree_numbers: [
      "C10.597.606.337.500.500",
      "C23.550.767.181",
      "C23.888.592.604.339.500.500",
      "F01.700.250.500.500",
      "F03.615.350.500",
    ],
  },
  {
    ui: "D000071297",
    heading: "Acute Retroviral Syndrome",
    tree_numbers: [
      "C01.221.250.875.044",
      "C01.221.812.640.400.044",
      "C01.778.640.400.044",
      "C01.925.782.815.616.400.044",
      "C01.925.813.400.044",
      "C20.673.480.044",
    ],
  },
  {
    ui: "D000071298",
    heading: "Body Remains",
    tree_numbers: ["C23.550.260.127", "I01.076.368.792", "I01.198.780.937.883"],
  },
  {
    ui: "D000071378",
    heading: "Bunion",
    tree_numbers: ["C05.330.488.050"],
  },
  {
    ui: "D000071380",
    heading: "Fibromatosis, Plantar",
    tree_numbers: ["C04.557.450.565.590.340.705", "C05.360.375", "C05.651.197.369", "C17.300.349", "C17.800.321.063"],
    synonyms: ["Dupuytren's Contracture Of Foot", "Ledderhose's Disease", "Plantar Fascial Fibromatosis"],
  },
  {
    ui: "D000071562",
    heading: "Fractures, Avulsion",
    tree_numbers: ["C26.404.038"],
  },
  {
    ui: "D000071576",
    heading: "Crush Injuries",
    tree_numbers: ["C26.257"],
  },
  {
    ui: "D000071697",
    heading: "Cellulite",
    tree_numbers: ["C23.888.885.281"],
  },
  {
    ui: "D000071698",
    heading: "Latent Autoimmune Diabetes in Adults",
    tree_numbers: ["C18.452.394.750.714", "C19.246.656", "C20.111.576"],
  },
  {
    ui: "D000071699",
    heading: "Bilateral Vestibulopathy",
    tree_numbers: ["C09.218.568.900.442", "C10.597.057", "C23.888.592.057"],
  },
  {
    ui: "D000071700",
    heading: "Cone-Rod Dystrophies",
    tree_numbers: ["C11.270.152", "C11.768.585.658.250", "C16.320.290.152"],
  },
  {
    ui: "D000071896",
    heading: "Medically Unexplained Symptoms",
    tree_numbers: ["C23.888.541"],
  },
  {
    ui: "D000071956",
    heading: "Myocardial Contusions",
    tree_numbers: ["C26.891.375.750", "C26.974.250.875"],
  },
  {
    ui: "D000071960",
    heading: "Breast Carcinoma In Situ",
    tree_numbers: ["C04.557.470.200.240.187", "C04.588.180.130", "C17.800.090.500.130"],
    synonyms: ["Carcinoma In Situ Of Breast", "Non-Infiltrating Carcinoma Of Breast"],
  },
  {
    ui: "D000072039",
    heading: "Fracture Dislocation",
    tree_numbers: ["C05.550.518.336", "C26.289.336", "C26.404.026"],
  },
  {
    ui: "D000072042",
    heading: "Salter-Harris Fractures",
    tree_numbers: ["C05.550.518.336.875", "C26.289.336.875", "C26.404.026.500"],
  },
  {
    ui: "D000072281",
    heading: "Lymphadenopathy",
    tree_numbers: ["C15.604.338"],
  },
  {
    ui: "D000072656",
    heading: "Breast Cancer Lymphedema",
    tree_numbers: ["C15.604.496.160", "C23.550.767.082"],
  },
  {
    ui: "D000072657",
    heading: "ST Elevation Myocardial Infarction",
    tree_numbers: ["C14.280.647.500.875", "C14.907.585.500.875", "C23.550.513.355.750.875", "C23.550.717.489.750.875"],
  },
  {
    ui: "D000072658",
    heading: "Non-ST Elevated Myocardial Infarction",
    tree_numbers: ["C14.280.647.500.469", "C14.907.585.500.656", "C23.550.513.355.750.469", "C23.550.717.489.750.469"],
  },
  {
    ui: "D000072659",
    heading: "Hypophysitis",
    tree_numbers: ["C10.228.140.617.738.275", "C19.700.419"],
  },
  {
    ui: "D000072660",
    heading: "Teratozoospermia",
    tree_numbers: ["C12.294.365.700.877"],
    synonyms: ["Spgf6"],
  },
  {
    ui: "D000072661",
    heading: "Ciliopathies",
    tree_numbers: ["C16.131.077.245", "C16.320.184"],
  },
  {
    ui: "D000072662",
    heading: "Margins of Excision",
    tree_numbers: ["A10.830", "C23.149.625"],
  },
  {
    ui: "D000072663",
    heading: "Spontaneous Perforation",
    tree_numbers: ["C23.300.940"],
  },
  {
    ui: "D000072676",
    heading: "Ankyloglossia",
    tree_numbers: ["C07.160"],
  },
  {
    ui: "D000072716",
    heading: "Cancer Pain",
    tree_numbers: ["C23.888.592.612.212"],
  },
  {
    ui: "D000072717",
    heading: "Tertiary Lymphoid Structures",
    tree_numbers: ["A10.549.725", "A15.382.520.604.725", "C23.300.955"],
  },
  {
    ui: "D000072736",
    heading: "Propofol Infusion Syndrome",
    tree_numbers: ["C25.100.844"],
  },
  {
    ui: "D000072742",
    heading: "Invasive Fungal Infections",
    tree_numbers: ["C01.150.703.492"],
  },
  {
    ui: "D000072836",
    heading: "Surgical Wound",
    tree_numbers: ["C26.859"],
  },
  {
    ui: "D000072837",
    heading: "Chromothripsis",
    tree_numbers: ["C23.550.210.310", "G05.365.590.175.310"],
  },
  {
    ui: "D000072861",
    heading: "Phobia, Social",
    tree_numbers: ["F03.080.725.500"],
  },
  {
    ui: "D000073296",
    heading: "Noncommunicable Diseases",
    tree_numbers: ["C23.550.291.898"],
  },
  {
    ui: "D000073376",
    heading: "Epileptic Syndromes",
    tree_numbers: ["C10.228.140.490.493"],
  },
  {
    ui: "D000073397",
    heading: "Occupational Stress",
    tree_numbers: ["C24.580", "F01.145.126.990.734", "F02.830.900.666"],
  },
  {
    ui: "D000073436",
    heading: "Microvascular Rarefaction",
    tree_numbers: ["C23.300.818"],
  },
  {
    ui: "D000073496",
    heading: "Frailty",
    tree_numbers: ["C23.550.359"],
  },
  {
    ui: "D000073605",
    heading: "Spotted Fever Group Rickettsiosis",
    tree_numbers: ["C01.150.252.400.789.725.400", "C01.920.930.887"],
    synonyms: [
      "North Asian Tick Fever",
      "North Asian Tick Typhus",
      "Rickettsia Sibirica Spotted Fever",
      "Manchurian Typhus",
    ],
  },
  {
    ui: "D000073617",
    heading: "Transfusion-Related Acute Lung Injury",
    tree_numbers: ["C08.381.520.500.500", "C15.378.962.500", "C20.920.500"],
  },
  {
    ui: "D000073618",
    heading: "Varicella Zoster Virus Infection",
    tree_numbers: ["C01.925.256.466.930"],
  },
  {
    ui: "D000073818",
    heading: "Pain, Procedural",
    tree_numbers: ["C23.888.592.612.860"],
  },
  {
    ui: "D000073839",
    heading: "Trisomy 13 Syndrome",
    tree_numbers: [
      "C10.597.606.360.835",
      "C14.240.400.970",
      "C14.280.400.970",
      "C16.131.077.919",
      "C16.131.240.400.965",
      "C16.131.260.923",
      "C16.320.180.923",
    ],
    synonyms: ["D1 Trisomy", "Trisomy 13"],
  },
  {
    ui: "D000073842",
    heading: "Trisomy 18 Syndrome",
    tree_numbers: [
      "C14.240.400.975",
      "C14.280.400.975",
      "C16.131.077.929",
      "C16.131.240.400.968",
      "C16.131.260.932",
      "C16.320.180.932",
    ],
    synonyms: ["Complete Trisomy 18 Syndrome", "E3 Trisomy", "Trisomy 18"],
  },
  {
    ui: "D000073872",
    heading: "Vascular Ring",
    tree_numbers: ["C14.240.850.992", "C14.907.109.239.825", "C16.131.240.850.984"],
  },
  {
    ui: "D000073923",
    heading: "Food Intolerance",
    tree_numbers: ["C23.888.821.387"],
  },
  {
    ui: "D000073932",
    heading: "Food Addiction",
    tree_numbers: ["F01.145.527.100.120.500", "F03.400.813"],
  },
  {
    ui: "D000074009",
    heading: "Tubular Sweat Gland Adenomas",
    tree_numbers: ["C04.557.470.035.175.900", "C04.557.470.550.175.900"],
    synonyms: ["Papillary Syringadenoma", "Papillary Syringadenoma", "Syringocystadenoma Papilliferum"],
  },
  {
    ui: "D000074021",
    heading: "Interatrial Block",
    tree_numbers: ["C14.280.067.558.430", "C14.280.123.500.430", "C23.550.073.425.270"],
  },
  {
    ui: "D000074042",
    heading: "Cerebral Intraventricular Hemorrhage",
    tree_numbers: ["C10.228.140.300.535.200.600", "C14.907.253.573.200.600", "C23.550.414.913.100.600"],
  },
  {
    ui: "D000074043",
    heading: "Night Eating Syndrome",
    tree_numbers: ["F03.400.844"],
  },
  {
    ui: "D000074079",
    heading: "Undifferentiated Connective Tissue Diseases",
    tree_numbers: ["C17.300.849", "C20.111.904"],
  },
  {
    ui: "D000074270",
    heading: "Meconium Ileus",
    tree_numbers: ["C06.405.469.531.788"],
  },
  {
    ui: "D000074607",
    heading: "Opium Dependence",
    tree_numbers: ["C25.775.643.500.800", "F03.900.647.500.800"],
  },
  {
    ui: "D000074609",
    heading: "Marijuana Use",
    tree_numbers: ["F01.145.610", "F03.900.643"],
  },
  {
    ui: "D000074723",
    heading: "Oncogene Addiction",
    tree_numbers: ["C04.697.850", "C23.550.727.850"],
  },
  {
    ui: "D000074742",
    heading: "Median Arcuate Ligament Syndrome",
    tree_numbers: ["C06.198.929", "C14.240.850.922", "C14.907.137.527", "C16.131.240.850.898"],
    synonyms: ["Celiac Artery Compression Syndrome", "Harjola-Marable Syndrome", "Marable's Syndrome"],
  },
  {
    ui: "D000074924",
    heading: "Nut and Peanut Hypersensitivity",
    tree_numbers: ["C20.543.480.370.572"],
  },
  {
    ui: "D000074962",
    heading: "Kounis Syndrome",
    tree_numbers: ["C14.280.647.375", "C14.907.585.375", "C20.543.560"],
  },
  {
    ui: "D000075067",
    heading: "Substance Abuse, Oral",
    tree_numbers: ["C25.775.814", "F03.900.809"],
  },
  {
    ui: "D000075122",
    heading: "Smoldering Multiple Myeloma",
    tree_numbers: ["C04.834.794", "C15.378.147.542.820", "C15.378.147.780.838", "C20.683.460.820", "C20.683.780.838"],
  },
  {
    ui: "D000075222",
    heading: "Essential Hypertension",
    tree_numbers: ["C14.907.489.165"],
    synonyms: ["Primary Hypertension", "Idiopathic Hypertension"],
  },
  {
    ui: "D000075224",
    heading: "Cardiac Conduction System Disease",
    tree_numbers: ["C14.280.123"],
  },
  {
    ui: "D000075262",
    heading: "Hypoadrenocorticism, Familial",
    tree_numbers: ["C19.053.500.263.500"],
  },
  {
    ui: "D000075322",
    heading: "Heavy Metal Poisoning",
    tree_numbers: ["C25.723.522"],
  },
  {
    ui: "D000075363",
    heading: "Immunoglobulin Light-chain Amyloidosis",
    tree_numbers: ["C04.557.595.250", "C18.452.845.500.550", "C20.683.515.507", "C20.683.780.565"],
  },
  {
    ui: "D000075529",
    heading: "Solitary Kidney",
    tree_numbers: ["C12.706.846", "C13.351.875.846", "C23.300.925"],
  },
  {
    ui: "D000075562",
    heading: "Charles Bonnet Syndrome",
    tree_numbers: ["C10.597.606.762.300.500", "C23.888.592.604.764.300.500", "F01.700.750.300.500"],
  },
  {
    ui: "D000075662",
    heading: "Injection Site Reaction",
    tree_numbers: ["C23.550.340.500", "C25.100.781"],
  },
  {
    ui: "D000075902",
    heading: "Clinical Deterioration",
    tree_numbers: ["C23.550.291.656.350"],
  },
  {
    ui: "D000076042",
    heading: "Alcoholic Korsakoff Syndrome",
    tree_numbers: [
      "C10.597.606.525.400.500",
      "C10.720.112.100.250",
      "C23.888.592.604.529.400.500",
      "C25.723.705.150.100.250",
      "C25.775.100.087.193.100.250",
      "F01.700.625.400.500",
      "F03.615.200.131",
      "F03.900.100.050.250",
    ],
  },
  {
    ui: "D000076082",
    heading: "Travel-Related Illness",
    tree_numbers: ["C23.888.914", "I03.883.855"],
  },
  {
    ui: "D000076263",
    heading: "Communicable Diseases, Imported",
    tree_numbers: ["C01.221.625", "C23.550.291.531.875"],
  },
  {
    ui: "D000076385",
    heading: "Diverticular Diseases",
    tree_numbers: ["C06.405.205.282"],
  },
  {
    ui: "D000076984",
    heading: "Neonatal Brachial Plexus Palsy",
    tree_numbers: ["C10.668.829.100.750", "C16.614.131.294", "C26.141.294"],
  },
  {
    ui: "D000077162",
    heading: "Neurolymphomatosis",
    tree_numbers: ["C10.551.568"],
  },
  {
    ui: "D000077192",
    heading: "Adenocarcinoma of Lung",
    tree_numbers: ["C04.557.470.200.025.022", "C04.588.894.797.520.055"],
    synonyms: ["Bronchogenic Lung Adenocarcinoma", "Nonsmall Cell Adenocarcinoma"],
  },
  {
    ui: "D000077195",
    heading: "Squamous Cell Carcinoma of Head and Neck",
    tree_numbers: ["C04.557.470.200.400.565", "C04.588.443.177"],
    synonyms: ["Squamous Cell Carcinoma Of Larynx", "Epidermoid Carcinoma Of The Larynx"],
  },
  {
    ui: "D000077207",
    heading: "Chondrosarcoma, Clear Cell",
    tree_numbers: ["C04.557.450.565.280.140", "C04.557.450.795.300.140"],
    synonyms: ["Clear Cell Chondrosarcoma"],
  },
  {
    ui: "D000077216",
    heading: "Carcinoma, Ovarian Epithelial",
    tree_numbers: [
      "C04.557.470.200.295",
      "C04.588.322.455.199",
      "C13.351.500.056.630.705.350",
      "C13.351.937.418.685.350",
      "C19.344.410.199",
      "C19.391.630.705.350",
    ],
  },
  {
    ui: "D000077260",
    heading: "Sleepiness",
    tree_numbers: ["C23.888.900"],
  },
  {
    ui: "D000077273",
    heading: "Thyroid Cancer, Papillary",
    tree_numbers: [
      "C04.557.470.200.025.085.612",
      "C04.588.322.894.400",
      "C04.588.443.915.400",
      "C19.344.894.400",
      "C19.874.788.400",
    ],
    synonyms: ["Papillary Carcinoma Of The Thyroid Gland"],
  },
  {
    ui: "D000077274",
    heading: "Nasopharyngeal Carcinoma",
    tree_numbers: [
      "C04.557.470.200.623",
      "C04.588.443.665.710.650.500",
      "C07.550.350.650.500",
      "C07.550.745.650.500",
      "C09.647.710.650.500",
      "C09.775.350.650.500",
      "C09.775.549.650.500",
    ],
  },
  {
    ui: "D000077275",
    heading: "Craniofacial Fibrous Dysplasia",
    tree_numbers: ["C05.116.099.708.375.286"],
  },
  {
    ui: "D000077277",
    heading: "Esophageal Squamous Cell Carcinoma",
    tree_numbers: [
      "C04.557.470.200.400.330",
      "C04.557.470.700.400.565",
      "C04.588.274.476.205.500",
      "C04.588.443.353.500",
      "C06.301.371.205.500",
      "C06.405.117.430.500",
      "C06.405.249.205.500",
    ],
    synonyms: ["Scc Of Oesophagus", "Oesophagus Squamous Cell Carcinoma", "Scc Of Esophagus"],
  },
  {
    ui: "D000077295",
    heading: "Urinary Bladder, Underactive",
    tree_numbers: ["C12.777.829.893", "C13.351.968.829.867", "C23.888.942.343.790"],
  },
  {
    ui: "D000077299",
    heading: "Healthcare-Associated Pneumonia",
    tree_numbers: ["C01.248.250", "C01.748.610.300", "C08.381.677.300", "C08.730.610.300", "C23.550.291.875.500.500"],
  },
  {
    ui: "D000077342",
    heading: "Post-Lyme Disease Syndrome",
    tree_numbers: ["C01.150.252.400.536.850", "C01.150.252.400.794.352.250.850", "C01.920.930.513.850"],
  },
  {
    ui: "D000077428",
    heading: "GATA2 Deficiency",
    tree_numbers: ["C15.378.190.625.265", "C16.320.314"],
    synonyms: [
      "Combined Immunodeficiency With Susceptibility To Mycobacterial, Viral And Fungal Infections",
      "Gata2 Deficiency",
      "Dcml",
      "Monomac",
      "Dendritic Cell, Monocyte, B And Nk Lymphoid Deficiency",
      "Monocytopenia With Susceptibility To Infections",
      "Monocytopenia And Mycobacterial Infection Syndrome",
      "Imd21",
      "Monocyte-B-Natural Killer-Dendritic Cell Deficiency Syndrome",
    ],
  },
  {
    ui: "D000077684",
    heading: "Cauda Equina Syndrome",
    tree_numbers: ["C10.668.829.550.350", "C10.668.829.800.750.150"],
  },
  {
    ui: "D000077733",
    heading: "Immunoglobulin G4-Related Disease",
    tree_numbers: ["C20.111.572"],
  },
  {
    ui: "D000077765",
    heading: "Cone Dystrophy",
    tree_numbers: ["C11.270.151", "C11.768.216"],
  },
  {
    ui: "D000077777",
    heading: "Myopericytoma",
    tree_numbers: ["C04.557.450.565.545", "C04.557.645.380.500", "C17.300.680.770"],
  },
  {
    ui: "D000077779",
    heading: "Pancreatic Intraductal Neoplasms",
    tree_numbers: [
      "C04.557.470.615.665",
      "C04.588.274.761.875",
      "C04.588.322.475.875",
      "C06.301.761.875",
      "C06.689.667.812",
      "C19.344.421.875",
    ],
  },
  {
    ui: "D000077962",
    heading: "Body-Weight Trajectory",
    tree_numbers: ["C23.888.144.243.463", "G07.345.249.314.120.200.463"],
  },
  {
    ui: "D000078064",
    heading: "Gestational Weight Gain",
    tree_numbers: ["C23.888.144.243.926.500", "G07.345.249.314.120.200.926.500", "G08.686.330"],
  },
  {
    ui: "D000079262",
    heading: "Obesity, Maternal",
    tree_numbers: [
      "C13.703.417",
      "C18.654.726.500.633",
      "C23.888.144.699.500.125",
      "E01.370.600.115.100.160.120.699.500.312",
      "G07.100.100.160.120.699.500.312",
    ],
  },
  {
    ui: "D000079263",
    heading: "Vaccine-Preventable Diseases",
    tree_numbers: ["C01.918"],
  },
  {
    ui: "D000079426",
    heading: "Vector Borne Diseases",
    tree_numbers: ["C01.920"],
  },
  {
    ui: "D000079524",
    heading: "Narcotic-Related Disorders",
    tree_numbers: ["C25.775.643", "F03.900.647"],
  },
  {
    ui: "D000079562",
    heading: "Rumination Syndrome",
    tree_numbers: ["C06.405.609", "F03.400.968"],
    synonyms: ["Psychogenic Rumination"],
  },
  {
    ui: "D000079564",
    heading: "Globus Sensation",
    tree_numbers: ["F03.875.300.500"],
  },
  {
    ui: "D000079689",
    heading: "Opioid-Induced Constipation",
    tree_numbers: ["C23.888.821.150.500", "C25.775.643.250", "F03.900.647.250"],
  },
  {
    ui: "D000079690",
    heading: "Postoperative Cognitive Complications",
    tree_numbers: ["C23.550.767.831", "F03.615.250.700.500"],
  },
  {
    ui: "D000079822",
    heading: "Extranodal Extension",
    tree_numbers: ["C04.697.650.280", "C23.550.727.650.280"],
  },
  {
    ui: "D000080037",
    heading: "Historical Trauma",
    tree_numbers: ["F02.830.900.500", "F03.950.750.375.500"],
  },
  {
    ui: "D000080038",
    heading: "Anomalous Left Coronary Artery",
    tree_numbers: ["C14.240.400.210.125", "C14.280.400.210.125", "C16.131.240.400.210.125"],
  },
  {
    ui: "D000080039",
    heading: "Univentricular Heart",
    tree_numbers: ["C14.240.400.990", "C14.280.400.990", "C16.131.240.400.975"],
  },
  {
    ui: "D000080041",
    heading: "Congenitally Corrected Transposition of the Great Arteries",
    tree_numbers: ["C14.240.400.915.150", "C14.280.400.915.150", "C16.131.240.400.915.150"],
  },
  {
    ui: "D000080146",
    heading: "Avoidant Restrictive Food Intake Disorder",
    tree_numbers: ["F03.400.157"],
  },
  {
    ui: "D000080203",
    heading: "Hamman-Rich Syndrome",
    tree_numbers: ["C08.381.765.500.500"],
    synonyms: ["Idiopathic Fibrosing Alveolitis", "Diffuse Idiopathic Pulmonary Fibrosis"],
  },
  {
    ui: "D000080222",
    heading: "Pancreaticobiliary Maljunction",
    tree_numbers: ["C06.130.120.250.640", "C06.198.964", "C16.131.314.778"],
  },
  {
    ui: "D000080223",
    heading: "Chronic Urticaria",
    tree_numbers: ["C17.800.862.945.533", "C20.543.480.904.533"],
  },
  {
    ui: "D000080311",
    heading: "Synesthesia",
    tree_numbers: ["C10.597.606.762.850"],
  },
  {
    ui: "D000080322",
    heading: "Cyclodialysis Clefts",
    tree_numbers: ["C11.250.105.250", "C11.941.160.241.250"],
  },
  {
    ui: "D000080323",
    heading: "Uveal Effusion Syndrome",
    tree_numbers: ["C11.250.105.500", "C11.941.160.241.500"],
  },
  {
    ui: "D000080324",
    heading: "Choroidal Effusions",
    tree_numbers: ["C11.250.105", "C11.941.160.241"],
  },
  {
    ui: "D000080343",
    heading: "Meibomian Gland Dysfunction",
    tree_numbers: ["C11.338.780"],
  },
  {
    ui: "D000080344",
    heading: "Optic Nerve Hypoplasia",
    tree_numbers: [
      "C10.292.700.463",
      "C10.500.760",
      "C11.270.588",
      "C11.640.522",
      "C16.131.666.763",
      "C16.320.290.612",
    ],
    synonyms: ["Isolated Optic Nerve Hypoplasia/aplasia", "Onh", "Familial Bilateral Optic Nerve Hypoplasia"],
  },
  {
    ui: "D000080345",
    heading: "Familial Exudative Vitreoretinopathies",
    tree_numbers: ["C11.250.345", "C11.270.238", "C11.768.337", "C16.131.384.424", "C16.320.290.352"],
  },
  {
    ui: "D000080346",
    heading: "Retinal Arterial Macroaneurysm",
    tree_numbers: ["C11.768.346.500", "C14.907.055.908", "C14.907.489.815.500"],
  },
  {
    ui: "D000080362",
    heading: "Stargardt Disease",
    tree_numbers: ["C11.270.872", "C11.768.585.439.339", "C16.320.290.724"],
  },
  {
    ui: "D000080363",
    heading: "White Dot Syndromes",
    tree_numbers: ["C11.941.879.780.900.650"],
  },
  {
    ui: "D000080364",
    heading: "Multifocal Choroiditis",
    tree_numbers: ["C11.941.160.478.550", "C11.941.879.780.900.300.489", "C11.941.879.780.900.650.500"],
  },
  {
    ui: "D000080365",
    heading: "Birdshot Chorioretinopathy",
    tree_numbers: [
      "C11.768.773.348.500",
      "C11.941.160.478.400.500",
      "C11.941.879.780.900.300.318.500",
      "C11.941.879.780.900.650.250",
      "C20.111.303",
    ],
    synonyms: [
      "Birdshot Retinochoroiditis",
      "Vitiliginous Choroiditis",
      "Birdshot Retinochoroidopathy",
      "Bscr",
      "Birdshot Chorioretinitis",
    ],
  },
  {
    ui: "D000080422",
    heading: "Locked-In Syndrome",
    tree_numbers: ["C10.597.622.760.500", "C10.668.416", "C23.888.592.636.786.500"],
    synonyms: ["Locked In Syndrome", "Locked-In State"],
  },
  {
    ui: "D000080424",
    heading: "Cytokine Release Syndrome",
    tree_numbers: ["C23.550.470.790.250", "C23.550.835.900.356"],
  },
  {
    ui: "D000080443",
    heading: "Diffuse Intrinsic Pontine Glioma",
    tree_numbers: [
      "C04.557.465.625.600.380.185",
      "C04.557.470.670.380.185",
      "C04.557.580.625.600.380.185",
      "C04.588.614.250.195.411.100.500",
      "C10.228.140.211.500.100.500",
      "C10.551.240.250.400.200.500",
    ],
  },
  {
    ui: "D000080445",
    heading: "Asthma-Chronic Obstructive Pulmonary Disease Overlap Syndrome",
    tree_numbers: ["C08.127.108.688", "C08.381.495.108.500", "C08.381.495.389.250", "C08.674.095.688", "C20.543.103"],
  },
  {
    ui: "D000080485",
    heading: "Sudden Unexpected Death in Epilepsy",
    tree_numbers: ["C10.228.140.490.746", "C23.550.260.322.812"],
  },
  {
    ui: "D000080822",
    heading: "Relative Energy Deficiency in Sport",
    tree_numbers: ["F03.400.937"],
  },
  {
    ui: "D000080842",
    heading: "Undiagnosed Diseases",
    tree_numbers: ["C23.550.291.968"],
  },
  {
    ui: "D000080874",
    heading: "Synucleinopathies",
    tree_numbers: ["C10.574.928", "C18.452.845.650"],
  },
  {
    ui: "D000080883",
    heading: "Shoulder Dystocia",
    tree_numbers: ["C13.703.420.288.364"],
  },
  {
    ui: "D000080887",
    heading: "Diabulimia",
    tree_numbers: ["F03.400.375"],
  },
  {
    ui: "D000080888",
    heading: "Specific Language Disorder",
    tree_numbers: ["C10.597.606.150.500.550.500", "C23.888.592.604.150.500.550.500", "C23.888.592.604.150.550.600"],
  },
  {
    ui: "D000080902",
    heading: "Mandibular Nerve Injuries",
    tree_numbers: [
      "C07.465.299.625.500.349.750",
      "C10.292.200.875.750",
      "C10.292.319.625.700.349.750",
      "C10.900.300.218.775.750",
      "C26.915.300.400.825.750",
    ],
  },
  {
    ui: "D000080942",
    heading: "Post-Cardiac Arrest Syndrome",
    tree_numbers: ["C10.228.140.199.850", "C14.280.778", "C14.907.725.638", "C23.550.767.877.625"],
  },
  {
    ui: "D000080983",
    heading: "Bone Marrow Failure Disorders",
    tree_numbers: ["C15.378.190.223"],
  },
  {
    ui: "D000080984",
    heading: "Congenital Bone Marrow Failure Syndromes",
    tree_numbers: ["C15.378.190.223.500", "C16.614.183"],
  },
  {
    ui: "D000081003",
    heading: "Shwachman-Diamond Syndrome",
    tree_numbers: ["C06.689.276.500", "C15.378.190.223.500.875", "C18.452.584.718.875"],
  },
  {
    ui: "D000081011",
    heading: "Hepatic Infarction",
    tree_numbers: ["C06.552.289", "C23.550.513.355.500", "C23.550.717.489.500"],
    synonyms: ["Infarct Of Liver"],
  },
  {
    ui: "D000081012",
    heading: "Autoimmune Pancreatitis",
    tree_numbers: ["C06.689.750.830.500", "C20.111.296"],
  },
  {
    ui: "D000081015",
    heading: "Ototoxicity",
    tree_numbers: ["C09.218.788", "C23.550.753", "C25.100.656", "C26.733.650", "G01.750.748.500.650"],
  },
  {
    ui: "D000081028",
    heading: "Toxic Optic Neuropathy",
    tree_numbers: [
      "C10.292.200.750.500",
      "C10.292.700.475.500",
      "C10.900.300.218.550.500",
      "C11.640.530.500",
      "C23.550.877",
      "C25.100.937",
      "C26.733.902",
      "G01.750.748.500.902",
    ],
  },
  {
    ui: "D000081029",
    heading: "Pulmonary Arterial Hypertension",
    tree_numbers: ["C08.381.423.847"],
  },
  {
    ui: "D000081030",
    heading: "Myotoxicity",
    tree_numbers: [
      "C05.651.682",
      "C10.668.491.628",
      "C23.550.706",
      "C25.100.828",
      "C26.733.411",
      "G01.750.748.500.411",
    ],
  },
  {
    ui: "D000081032",
    heading: "Pancreatitis, Acute Hemorrhagic",
    tree_numbers: ["C06.689.750.325"],
  },
  {
    ui: "D000081042",
    heading: "Body Integrity Identity Disorder",
    tree_numbers: ["F03.875.225"],
  },
  {
    ui: "D000081084",
    heading: "Accidental Injuries",
    tree_numbers: ["C26.040"],
  },
  {
    ui: "D000081207",
    heading: "Primary Immunodeficiency Diseases",
    tree_numbers: ["C16.320.798", "C20.673.795"],
  },
  {
    ui: "D000081208",
    heading: "Hereditary Complement Deficiency Diseases",
    tree_numbers: ["C16.320.798.500", "C20.673.795.500"],
    synonyms: ["Complement Deficiency", "Complement Deficiency Disease"],
  },
  {
    ui: "D000081483",
    heading: "Squamous Intraepithelial Lesions",
    tree_numbers: ["C23.149.688"],
  },
  {
    ui: "D000082002",
    heading: "Sexual Trauma",
    tree_numbers: ["F03.950.750.438"],
  },
  {
    ui: "D000082122",
    heading: "Internal Hernia",
    tree_numbers: ["C23.300.707.960"],
  },
  {
    ui: "D000082123",
    heading: "Paraduodenal Hernia",
    tree_numbers: ["C23.300.707.960.750"],
  },
  {
    ui: "D000082242",
    heading: "Kimura Disease",
    tree_numbers: ["C07.465.815.178", "C15.604.315.459"],
    synonyms: ["Kimura's Disease"],
  },
  {
    ui: "D000082602",
    heading: "Developmental Dysplasia of the Hip",
    tree_numbers: ["C05.550.518.384.500", "C05.660.297", "C16.131.621.297"],
  },
  {
    ui: "D000082643",
    heading: "Cerebral Cortical Thinning",
    tree_numbers: ["C10.597.210", "C23.300.220", "C23.888.592.206"],
  },
  {
    ui: "D000082802",
    heading: "Warburg Effect, Oncologic",
    tree_numbers: [
      "C04.697.098.500.580",
      "C23.550.727.098.500.580",
      "G02.111.158.750.500",
      "G03.191.750.500",
      "G03.197.810",
      "G03.295.436.500",
      "G03.493.360.500",
      "G04.270.810",
    ],
  },
  {
    ui: "D000082843",
    heading: "Ovarian Torsion",
    tree_numbers: ["C13.351.500.056.630.728", "C19.391.630.728", "C23.300.970.625"],
  },
  {
    ui: "D000082862",
    heading: "Aortic Valve Disease",
    tree_numbers: ["C14.280.484.048"],
  },
  {
    ui: "D000082882",
    heading: "Bicuspid Aortic Valve Disease",
    tree_numbers: ["C14.240.400.186", "C14.280.400.186", "C14.280.484.048.875", "C16.131.240.400.186"],
  },
  {
    ui: "D000082902",
    heading: "Quadricuspid Aortic Valve",
    tree_numbers: ["C14.240.400.818", "C14.280.400.818", "C14.280.484.048.937", "C16.131.240.400.817"],
  },
  {
    ui: "D000082903",
    heading: "Aortico-Ventricular Tunnel",
    tree_numbers: ["C14.240.400.118", "C14.280.400.118", "C16.131.240.400.118"],
  },
  {
    ui: "D000083023",
    heading: "Colitis-Associated Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.307.180.400",
      "C06.301.371.411.307.180.400",
      "C06.405.249.411.307.180.400",
      "C06.405.469.158.356.180.400",
      "C06.405.469.491.307.180.400",
    ],
  },
  {
    ui: "D000083082",
    heading: "Popliteal Artery Entrapment Syndrome",
    tree_numbers: ["C14.907.137.671"],
  },
  {
    ui: "D000083083",
    heading: "Laminopathies",
    tree_numbers: ["C16.320.488"],
  },
  {
    ui: "D000083102",
    heading: "Reinjuries",
    tree_numbers: ["C26.741"],
  },
  {
    ui: "D000083143",
    heading: "X-Linked Emery-Dreifuss Muscular Dystrophy",
    tree_numbers: [
      "C05.651.534.500.350.500",
      "C10.668.491.175.500.350.500",
      "C16.320.322.625.500",
      "C16.320.577.350.500",
    ],
  },
  {
    ui: "D000083144",
    heading: "Autosomal Emery-Dreifuss Muscular Dystrophy",
    tree_numbers: ["C05.651.534.500.350.250", "C10.668.491.175.500.350.250", "C16.320.488.500", "C16.320.577.350.250"],
  },
  {
    ui: "D000083182",
    heading: "Chronic Exertional Compartment Syndrome",
    tree_numbers: ["C05.651.180.180", "C14.907.303.180"],
  },
  {
    ui: "D000083242",
    heading: "Ischemic Stroke",
    tree_numbers: ["C10.228.140.300.775.400", "C14.907.253.855.400"],
  },
  {
    ui: "D000083244",
    heading: "Thrombotic Stroke",
    tree_numbers: ["C10.228.140.300.775.400.750", "C14.907.253.855.400.750"],
  },
  {
    ui: "D000083262",
    heading: "Embolic Stroke",
    tree_numbers: ["C10.228.140.300.775.400.375", "C14.907.253.855.400.375"],
  },
  {
    ui: "D000083302",
    heading: "Hemorrhagic Stroke",
    tree_numbers: ["C10.228.140.300.775.300", "C14.907.253.855.300"],
  },
  {
    ui: "D000083402",
    heading: "Persistent Left Superior Vena Cava",
    tree_numbers: ["C14.240.850.930", "C16.131.240.850.902"],
  },
  {
    ui: "D000083682",
    heading: "Opiate Overdose",
    tree_numbers: ["C25.775.383.500", "C25.775.643.500.700", "E02.319.306.500.500.500", "F03.900.647.500.700"],
  },
  {
    ui: "D000084002",
    heading: "Tinea cruris",
    tree_numbers: [
      "C01.150.703.302.720.745",
      "C01.800.200.720.745",
      "C17.800.174.640.500",
      "C17.800.815.650.500",
      "C17.800.838.208.883.608",
    ],
  },
  {
    ui: "D000084063",
    heading: "Reinfection",
    tree_numbers: ["C23.550.291.937.250"],
  },
  {
    ui: "D000084122",
    heading: "Cardiac Papillary Fibroelastoma",
    tree_numbers: ["C04.588.894.309.250", "C14.280.459.250"],
  },
  {
    ui: "D000084202",
    heading: "Chemotherapy-Related Cognitive Impairment",
    tree_numbers: ["C25.100.429", "F03.615.250.700.250"],
  },
  {
    ui: "D000084322",
    heading: "Semicircular Canal Dehiscence",
    tree_numbers: ["C09.218.568.900.663"],
  },
  {
    ui: "D000084462",
    heading: "Hyperthermia",
    tree_numbers: ["C23.888.119.455", "C26.522.750"],
  },
  {
    ui: "D000085142",
    heading: "Severe Fever with Thrombocytopenia Syndrome",
    tree_numbers: ["C01.920.930.831", "C01.925.782.147.905"],
  },
  {
    ui: "D000085343",
    heading: "Latent Infection",
    tree_numbers: ["C01.550"],
  },
  {
    ui: "D000085583",
    heading: "Hyperferritinemia",
    tree_numbers: ["C18.452.565.300"],
  },
  {
    ui: "D000086002",
    heading: "Mesothelioma, Malignant",
    tree_numbers: [
      "C04.557.470.035.510.757",
      "C04.557.470.660.510.757",
      "C04.588.894.797.520.173",
      "C04.588.894.797.640.350",
      "C08.381.540.144",
      "C08.785.520.124",
      "C08.785.640.350",
    ],
    synonyms: [
      "Advanced Malignant Mesothelioma",
      "Diffuse Malignant Mesothelioma",
      "Malignant Tumor Of Mesothelium",
      "Asbestos-Related Malignant Mesothelioma",
    ],
  },
  {
    ui: "D000086382",
    heading: "COVID-19",
    tree_numbers: [
      "C01.748.214",
      "C01.748.610.763.500",
      "C01.925.705.500",
      "C01.925.782.600.550.200.163",
      "C08.381.677.807.500",
      "C08.730.214",
      "C08.730.610.763.500",
    ],
    synonyms: [
      "Wuhan Seafood Market Pneumonia Virus Infection",
      "2019-Ncov Infection",
      "Covid19",
      "2019 Novel Coronavirus (2019-Ncov)",
      "Sars-Cov-2 Infection",
      "Wuhan Coronavirus Infection",
    ],
  },
  {
    ui: "D000086582",
    heading: "Anosmia",
    tree_numbers: ["C10.597.751.600.500", "C23.888.592.763.550.500"],
  },
  {
    ui: "D000086722",
    heading: "Rhinorrhea",
    tree_numbers: ["C23.888.852.834"],
  },
  {
    ui: "D000086965",
    heading: "Viral Zoonoses",
    tree_numbers: ["C01.925.933", "C01.973.500", "C22.969.500"],
  },
  {
    ui: "D000086966",
    heading: "Bacterial Zoonoses",
    tree_numbers: ["C01.150.252.162", "C01.973.250", "C22.969.250"],
  },
  {
    ui: "D000086982",
    heading: "Blood-Borne Infections",
    tree_numbers: ["C01.221.250", "C23.550.291.531.500"],
  },
  {
    ui: "D000087122",
    heading: "Mania",
    tree_numbers: ["C10.597.606.483", "C23.888.592.604.487", "F01.700.548"],
  },
  {
    ui: "D000126",
    heading: "Achlorhydria",
    tree_numbers: ["C06.405.748.045", "C18.452.076.087"],
  },
  {
    ui: "D000130",
    heading: "Achondroplasia",
    tree_numbers: ["C05.116.099.343.110", "C05.116.099.708.017", "C16.320.240.500"],
    synonyms: ["Saddan Dysplasia", "Severe Achondroplasia With Developmental Delay And Acanthosis Nigricans"],
  },
  {
    ui: "D000137",
    heading: "Acid-Base Imbalance",
    tree_numbers: ["C18.452.076"],
  },
  {
    ui: "D000138",
    heading: "Acidosis",
    tree_numbers: ["C18.452.076.176"],
  },
  {
    ui: "D000140",
    heading: "Acidosis, Lactic",
    tree_numbers: ["C18.452.076.176.180"],
  },
  {
    ui: "D000141",
    heading: "Acidosis, Renal Tubular",
    tree_numbers: ["C12.777.419.815.093", "C13.351.968.419.815.093", "C16.320.831.093", "C18.452.076.176.210"],
  },
  {
    ui: "D000142",
    heading: "Acidosis, Respiratory",
    tree_numbers: ["C08.618.846.093", "C18.452.076.176.310"],
  },
  {
    ui: "D000151",
    heading: "Acinetobacter Infections",
    tree_numbers: ["C01.150.252.400.560.022"],
  },
  {
    ui: "D000152",
    heading: "Acne Vulgaris",
    tree_numbers: ["C17.800.030.150", "C17.800.794.111"],
    synonyms: ["Acne Varioliformis", "Acne Vulgaris", "Frontalis Acne"],
  },
  {
    ui: "D000153",
    heading: "Acne Keloid",
    tree_numbers: ["A10.165.450.300.425.125", "C17.300.200.425.125", "C17.800.030.030", "C17.800.329.500.261"],
  },
  {
    ui: "D000160",
    heading: "Vestibulocochlear Nerve Diseases",
    tree_numbers: ["C09.218.807.800", "C10.292.910"],
    synonyms: ["Acoustic Nerve Disease"],
  },
  {
    ui: "D000163",
    heading: "Acquired Immunodeficiency Syndrome",
    tree_numbers: [
      "C01.221.250.875.040",
      "C01.221.812.640.400.040",
      "C01.778.640.400.040",
      "C01.925.782.815.616.400.040",
      "C01.925.813.400.040",
      "C01.925.839.040",
      "C20.673.480.040",
    ],
    synonyms: ["Acquired Immune Deficiency", "Aids"],
  },
  {
    ui: "D000168",
    heading: "Acrocephalosyndactylia",
    tree_numbers: [
      "C05.116.099.370.894.232.015",
      "C05.116.099.370.894.819.100",
      "C05.660.207.240.100",
      "C05.660.585.800.100",
      "C05.660.906.364.100",
      "C05.660.906.819.100",
      "C16.131.621.207.240.100",
      "C16.131.621.585.800.100",
      "C16.131.621.906.364.100",
      "C16.131.621.906.819.100",
    ],
    synonyms: ["Apert Syndrome"],
  },
  {
    ui: "D000169",
    heading: "Acrodermatitis",
    tree_numbers: ["C16.131.831.066", "C17.800.174.100", "C17.800.804.066"],
  },
  {
    ui: "D000170",
    heading: "Acrodynia",
    tree_numbers: ["C10.668.829.025", "C10.720.475.600.150", "C25.723.522.875.500.100"],
  },
  {
    ui: "D000172",
    heading: "Acromegaly",
    tree_numbers: ["C05.116.132.082", "C10.228.140.617.738.250.100", "C19.700.355.179"],
  },
  {
    ui: "D000182",
    heading: "ACTH Syndrome, Ectopic",
    tree_numbers: ["C04.730.713.317"],
  },
  {
    ui: "D000187",
    heading: "Actinobacillosis",
    tree_numbers: ["C01.150.252.400.700.030.178", "C22.039"],
  },
  {
    ui: "D000189",
    heading: "Actinobacillus Infections",
    tree_numbers: ["C01.150.252.400.700.030"],
  },
  {
    ui: "D000193",
    heading: "Actinomycetales Infections",
    tree_numbers: ["C01.150.252.410.040"],
  },
  {
    ui: "D000196",
    heading: "Actinomycosis",
    tree_numbers: ["C01.150.252.410.040.137"],
    synonyms: [
      "Actinomycotic Infection",
      "Actinomycotic Mycetema",
      "Actinomycotic Madura Foot",
      "Madura Foot Due To Actinomadura",
      "Actinomycotic Mycetoma Of Foot",
    ],
  },
  {
    ui: "D000197",
    heading: "Actinomycosis, Cervicofacial",
    tree_numbers: ["C01.150.252.410.040.137.316", "C01.150.252.819.110", "C01.800.720.110", "C17.800.838.765.110"],
  },
  {
    ui: "D000208",
    heading: "Acute Disease",
    tree_numbers: ["C23.550.291.125"],
  },
  {
    ui: "D000210",
    heading: "Acute-Phase Reaction",
    tree_numbers: ["C23.550.470.099"],
  },
  {
    ui: "D000219",
    heading: "Adams-Stokes Syndrome",
    tree_numbers: ["C14.280.067.558.137", "C14.280.123.500.137", "C23.550.073.425.025"],
  },
  {
    ui: "D000224",
    heading: "Addison Disease",
    tree_numbers: ["C19.053.500.263", "C20.111.163"],
    synonyms: [
      "Primary Adrenocortical Insufficiency",
      "Hypoadrenocorticism, Familial",
      "Primary Hypoadrenalism",
      "Addison Disease",
      "Addison Disease, Chronic Adrenal Insufficiency",
    ],
  },
  {
    ui: "D000230",
    heading: "Adenocarcinoma",
    tree_numbers: ["C04.557.470.200.025"],
    synonyms: ["Tubular Carcinoma"],
  },
  {
    ui: "D000231",
    heading: "Adenocarcinoma, Papillary",
    tree_numbers: ["C04.557.470.200.025.085"],
    synonyms: [
      "Papillary Adenocarcinoma",
      "Infiltrating And Papillary Adenocarcinoma",
      "Infiltrating Papillary Adenocarcinoma",
    ],
  },
  {
    ui: "D000232",
    heading: "Adenofibroma",
    tree_numbers: ["C04.557.450.565.590.595.050", "C04.557.470.625.050"],
  },
  {
    ui: "D000235",
    heading: "Adenolymphoma",
    tree_numbers: ["C04.557.435.075"],
  },
  {
    ui: "D000236",
    heading: "Adenoma",
    tree_numbers: ["C04.557.470.035"],
    synonyms: ["Microcystic Adenoma"],
  },
  {
    ui: "D000237",
    heading: "Adenoma, Basophil",
    tree_numbers: ["C04.557.465.625.650.075", "C04.557.470.035.075", "C04.557.580.625.650.075"],
    synonyms: ["Pituitary Gland Basophilic Adenoma"],
  },
  {
    ui: "D000238",
    heading: "Adenoma, Chromophobe",
    tree_numbers: ["C04.557.465.625.650.095", "C04.557.470.035.095", "C04.557.580.625.650.095"],
    synonyms: ["Chromophobe Adenoma Of The Pituitary Gland"],
  },
  {
    ui: "D000239",
    heading: "Adenoma, Acidophil",
    tree_numbers: ["C04.557.465.625.650.025", "C04.557.470.035.025", "C04.557.580.625.650.025"],
    synonyms: ["Eosinophil Adenoma"],
  },
  {
    ui: "D000257",
    heading: "Adenoviridae Infections",
    tree_numbers: ["C01.925.256.076"],
  },
  {
    ui: "D000258",
    heading: "Adenovirus Infections, Human",
    tree_numbers: ["C01.925.256.076.045"],
    synonyms: [
      "Pharyngo-Conjunctival Fever",
      "Pharyngoconjunctival Fever",
      "Pharyngoconjunctival Fever",
      "Adenoviral Pharyngoconjunctivitis",
    ],
  },
  {
    ui: "D000267",
    heading: "Tissue Adhesions",
    tree_numbers: ["C23.550.355.274.840"],
  },
  {
    ui: "D000270",
    heading: "Adie Syndrome",
    tree_numbers: ["C10.177.045", "C10.292.562.700.250", "C11.590.436.200", "C11.710.800.180"],
    synonyms: ["Adie's Pupil Or Syndrome", "Holmes-Adie Syndrome", "Adie's Syndrome", "Adie's Pupil Syndrome"],
  },
  {
    ui: "D000274",
    heading: "Adiposis Dolorosa",
    tree_numbers: ["C17.800.463.249", "C18.452.584.718.500"],
    synonyms: ["Dercum Disease"],
  },
  {
    ui: "D000275",
    heading: "Adjustment Disorders",
    tree_numbers: ["F03.950.500"],
    synonyms: [
      "Depressive Neurosis",
      "Neurotic Depression Reactive Type",
      "Neurotic Depressive State",
      "Neurotic Depression",
      "Psychoneurosis",
      "Neurosis",
      "Reactive Depression",
    ],
  },
  {
    ui: "D000291",
    heading: "Adnexal Diseases",
    tree_numbers: ["C13.351.500.056"],
  },
  {
    ui: "D000292",
    heading: "Pelvic Inflammatory Disease",
    tree_numbers: ["C01.635.500", "C13.351.500.056.750"],
    synonyms: ["Pid"],
  },
  {
    ui: "D000303",
    heading: "Adrenal Cortex Diseases",
    tree_numbers: ["C19.053.098"],
  },
  {
    ui: "D000306",
    heading: "Adrenal Cortex Neoplasms",
    tree_numbers: ["C04.588.322.078.265", "C19.053.098.265", "C19.053.347.500", "C19.344.078.265"],
    synonyms: [
      "Malignant Tumour Of Adrenal Cortex",
      "Adrenal Cortical Tumors",
      "Malignant Neoplasm Of Adrenal Cortex",
      "Neoplasm Of Adrenal Cortex",
      "Malignant Adrenocortical Tumor",
    ],
  },
  {
    ui: "D000307",
    heading: "Adrenal Gland Diseases",
    tree_numbers: ["C19.053"],
  },
  {
    ui: "D000308",
    heading: "Adrenocortical Hyperfunction",
    tree_numbers: ["C19.053.800"],
    synonyms: ["Hypercortisolism", "Adrenocortical Hyperfunction", "Hyperadrenalism"],
  },
  {
    ui: "D000309",
    heading: "Adrenal Insufficiency",
    tree_numbers: ["C19.053.500"],
  },
  {
    ui: "D000310",
    heading: "Adrenal Gland Neoplasms",
    tree_numbers: ["C04.588.322.078", "C19.053.347", "C19.344.078"],
    synonyms: [
      "Adrenal Neoplasm",
      "Malignant Neoplasm Of Adrenal Gland",
      "Tumor Of The Adrenal Gland",
      "Adrenal Cancer",
      "Neoplasm Of Adrenal Gland",
      "Malignant Adrenal Tumor",
    ],
  },
  {
    ui: "D000312",
    heading: "Adrenal Hyperplasia, Congenital",
    tree_numbers: [
      "C12.706.316.090.500",
      "C13.351.875.253.090.500",
      "C16.131.939.316.129.500",
      "C16.320.033",
      "C16.320.565.925.249",
      "C18.452.648.925.249",
      "C19.053.440",
      "C19.391.119.090.500",
    ],
  },
  {
    ui: "D000314",
    heading: "Adrenal Rest Tumor",
    tree_numbers: ["C04.557.470.035.232"],
    synonyms: ["Adrenal Rest Neoplasm"],
  },
  {
    ui: "D000326",
    heading: "Adrenoleukodystrophy",
    tree_numbers: [
      "C10.228.140.163.100.084",
      "C10.228.140.163.100.362.250",
      "C10.228.140.695.625.250",
      "C10.314.400.250",
      "C10.597.606.360.455.124",
      "C16.320.322.500.124",
      "C16.320.400.525.124",
      "C16.320.565.189.084",
      "C16.320.565.189.362.250",
      "C16.320.565.663.100",
      "C18.452.132.100.084",
      "C18.452.132.100.362.250",
      "C18.452.648.189.084",
      "C18.452.648.189.362.250",
      "C18.452.648.663.100",
      "C19.053.500.270",
    ],
    synonyms: [
      "Bronze Schilder Disease",
      "Encephalitis Periaxialis, Schilder's",
      "Siemerling-Creutzfeldt Disease",
      "X-Linked Adrenoleukodystrophy",
      "Sudanophilic Cerebral Sclerosis",
      "Ald",
      "Diffuse Sclerosis",
      "Encephalitis Periaxialis Concentrica",
    ],
  },
  {
    ui: "D000334",
    heading: "Aerophagy",
    tree_numbers: ["C23.888.821.061"],
  },
  {
    ui: "D000341",
    heading: "Affective Disorders, Psychotic",
    tree_numbers: ["F03.700.150"],
  },
  {
    ui: "D000343",
    heading: "Afferent Loop Syndrome",
    tree_numbers: ["C06.405.469.531.099", "C23.550.767.050"],
    synonyms: ["Afferent Limb Syndrome"],
  },
  {
    ui: "D000347",
    heading: "Afibrinogenemia",
    tree_numbers: ["C15.378.100.100.056", "C15.378.100.141.072", "C15.378.463.067", "C16.320.099.056"],
    synonyms: ["Factor I Deficiency", "Fibrinogen Deficiency"],
  },
  {
    ui: "D000355",
    heading: "African Horse Sickness",
    tree_numbers: ["C01.920.500.030", "C01.925.081.030", "C01.925.782.791.142", "C22.488.088"],
  },
  {
    ui: "D000357",
    heading: "African Swine Fever",
    tree_numbers: ["C01.920.930.125", "C01.925.081.078", "C01.925.256.142", "C22.905.072"],
  },
  {
    ui: "D000361",
    heading: "Agammaglobulinemia",
    tree_numbers: ["C15.378.147.142", "C15.604.515.032", "C20.673.088"],
    synonyms: ["Hypogammaglobulinemia", "Ighm", "Mu Heavy Chain Deficiency"],
  },
  {
    ui: "D000370",
    heading: "Ageusia",
    tree_numbers: ["C10.597.751.861.184", "C23.888.592.763.861.184"],
  },
  {
    ui: "D000377",
    heading: "Agnosia",
    tree_numbers: ["C10.597.606.762.100", "C23.888.592.604.764.100", "F01.700.750.100"],
    synonyms: ["Dyspraxia", "Dyspraxia Syndrome"],
  },
  {
    ui: "D000379",
    heading: "Agoraphobia",
    tree_numbers: ["F03.080.100"],
    synonyms: ["Fear Of Open Spaces"],
  },
  {
    ui: "D000380",
    heading: "Agranulocytosis",
    tree_numbers: ["C15.378.553.546.184"],
    synonyms: ["Granulocytopenia", "Granulocytopenic Disorder", "Granulopenia"],
  },
  {
    ui: "D000381",
    heading: "Agraphia",
    tree_numbers: ["C10.597.606.150.500.050", "C23.888.592.604.150.500.050"],
  },
  {
    ui: "D000382",
    heading: "Agricultural Workers' Diseases",
    tree_numbers: ["C24.080"],
  },
  {
    ui: "D000386",
    heading: "AIDS-Related Complex",
    tree_numbers: [
      "C01.221.250.875.080",
      "C01.221.812.640.400.080",
      "C01.778.640.400.080",
      "C01.925.782.815.616.400.080",
      "C01.925.813.400.080",
      "C01.925.839.080",
      "C20.673.480.080",
    ],
  },
  {
    ui: "D000387",
    heading: "Ainhum",
    tree_numbers: ["C05.116.264.143"],
    synonyms: ["Dactylolysis Spontanea", "Ainhum"],
  },
  {
    ui: "D000402",
    heading: "Airway Obstruction",
    tree_numbers: ["C08.618.846.185"],
  },
  {
    ui: "D000405",
    heading: "Akinetic Mutism",
    tree_numbers: ["C10.228.140.042"],
    synonyms: ["Coma Vigilans"],
  },
  {
    ui: "D000417",
    heading: "Albinism",
    tree_numbers: [
      "C11.270.040",
      "C16.320.290.040",
      "C16.320.565.100.102",
      "C16.320.850.080",
      "C17.800.621.440.102",
      "C17.800.827.080",
      "C18.452.648.100.102",
    ],
  },
  {
    ui: "D000419",
    heading: "Albuminuria",
    tree_numbers: ["C12.777.934.734.269", "C13.351.968.934.734.269", "C23.888.942.750.269"],
  },
  {
    ui: "D000425",
    heading: "Alcohol Amnestic Disorder",
    tree_numbers: ["C10.720.112.100", "C25.723.705.150.100", "C25.775.100.087.193.100", "F03.900.100.050"],
  },
  {
    ui: "D000430",
    heading: "Alcohol Withdrawal Delirium",
    tree_numbers: [
      "C10.720.112.200",
      "C25.723.705.150.200",
      "C25.775.100.087.193.200",
      "C25.775.835.250",
      "F03.900.100.100",
      "F03.900.825.500",
    ],
  },
  {
    ui: "D000435",
    heading: "Alcoholic Intoxication",
    tree_numbers: ["C25.775.100.175", "F03.900.100.300"],
  },
  {
    ui: "D000437",
    heading: "Alcoholism",
    tree_numbers: ["C25.775.100.250", "F03.900.100.350"],
    synonyms: ["Alcohol Abuse", "Ethanol Abuse"],
  },
  {
    ui: "D000453",
    heading: "Aleutian Mink Disease",
    tree_numbers: ["C01.925.256.700.091", "C01.925.839.091", "C11.270.040.772.270", "C20.673.774.257.270", "C22.062"],
    synonyms: ["Aleutian Disease"],
  },
  {
    ui: "D000471",
    heading: "Alkalosis",
    tree_numbers: ["C18.452.076.354"],
  },
  {
    ui: "D000472",
    heading: "Alkalosis, Respiratory",
    tree_numbers: ["C08.618.501.271", "C18.452.076.354.271"],
  },
  {
    ui: "D000474",
    heading: "Alkaptonuria",
    tree_numbers: ["C16.320.565.100.187", "C18.452.648.100.187"],
    synonyms: ["Homogentisate 1,2-Dioxygenase Deficiency", "Alcaptonuria"],
  },
  {
    ui: "D000505",
    heading: "Alopecia",
    tree_numbers: ["C17.800.329.937.122", "C23.300.035"],
  },
  {
    ui: "D000506",
    heading: "Alopecia Areata",
    tree_numbers: ["C17.800.329.937.122.147"],
    synonyms: ["Circumscribed Alopecia"],
  },
  {
    ui: "D000507",
    heading: "Mucinosis, Follicular",
    tree_numbers: ["C17.300.550.550", "C17.800.329.937.122.550", "C17.800.794.550"],
    synonyms: ["Alopecia Mucinosa"],
  },
  {
    ui: "D000532",
    heading: "Altitude Sickness",
    tree_numbers: ["C08.618.020"],
  },
  {
    ui: "D000542",
    heading: "Alveolitis, Extrinsic Allergic",
    tree_numbers: ["C08.381.483.125", "C08.674.055", "C20.543.480.680.075"],
    synonyms: ["Hypersensitivity Pneumonitis", "Alveolitis"],
  },
  {
    ui: "D000544",
    heading: "Alzheimer Disease",
    tree_numbers: ["C10.228.140.380.100", "C10.574.945.249", "F03.615.400.100"],
    synonyms: ["Alzheimer Disease", "Alzheimers Dementia"],
  },
  {
    ui: "D000550",
    heading: "Amblyopia",
    tree_numbers: ["C10.228.140.055", "C10.597.751.941.073", "C11.966.073", "C23.888.592.763.941.073"],
    synonyms: ["Strabismic Amblyopia"],
  },
  {
    ui: "D000562",
    heading: "Amebiasis",
    tree_numbers: ["C01.610.752.049"],
    synonyms: ["Entamoebiasis", "Amoebiasis"],
  },
  {
    ui: "D000564",
    heading: "Ameloblastoma",
    tree_numbers: ["C04.557.695.065"],
  },
  {
    ui: "D000567",
    heading: "Amelogenesis Imperfecta",
    tree_numbers: ["C07.650.800.255.500", "C07.793.700.255.500", "C16.131.850.800.255.500"],
  },
  {
    ui: "D000568",
    heading: "Amenorrhea",
    tree_numbers: ["C23.550.568.500"],
    synonyms: ["Absence Of Menstruation", "Amenia"],
  },
  {
    ui: "D000592",
    heading: "Amino Acid Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.100", "C18.452.648.100"],
    synonyms: ["Inborn Errors Of Amino Acid Metabolism"],
  },
  {
    ui: "D000608",
    heading: "Renal Aminoacidurias",
    tree_numbers: ["C12.777.419.815.885", "C13.351.968.419.815.885", "C16.320.831.885"],
  },
  {
    ui: "D000647",
    heading: "Amnesia",
    tree_numbers: ["C10.597.606.525.100", "C23.888.592.604.529.100", "F01.700.625.100", "F03.615.200"],
    synonyms: ["Amnestic Syndrome", "Korsakoff's Psychosis Or Syndrome", "Amnesia"],
  },
  {
    ui: "D000648",
    heading: "Amnesia, Retrograde",
    tree_numbers: ["C10.597.606.525.100.150", "C23.888.592.604.529.100.150", "F01.700.625.100.150", "F03.615.200.150"],
  },
  {
    ui: "D000652",
    heading: "Amniotic Band Syndrome",
    tree_numbers: ["C16.614.042"],
  },
  {
    ui: "D000673",
    heading: "Amputation, Traumatic",
    tree_numbers: ["C26.062"],
  },
  {
    ui: "D000686",
    heading: "Amyloidosis",
    tree_numbers: ["C18.452.845.500"],
    synonyms: ["Amyloid Disease"],
  },
  {
    ui: "D000690",
    heading: "Amyotrophic Lateral Sclerosis",
    tree_numbers: ["C10.228.854.139", "C10.574.562.250", "C10.574.950.050", "C10.668.467.250", "C18.452.845.800.050"],
    synonyms: [
      "Amyotrophic Lateral Sclerosis-Parkinsonism-Dementia Of Guam Syndrome",
      "Pdals",
      "Parkinsonism-Dementia-Als Complex",
      "Amyotrophic Lateral Sclerosis-Parkinsonism/dementia Complex Of Guam",
      "Lytico-Bodig Disease",
      "Guam Disease",
      "Als-Pdc",
    ],
  },
  {
    ui: "D000694",
    heading: "Anal Gland Neoplasms",
    tree_numbers: [
      "C04.588.083",
      "C04.588.274.476.411.307.790.040.040",
      "C06.301.371.411.307.790.040.040",
      "C06.405.249.411.307.790.040.040",
      "C06.405.469.491.307.790.040.040",
      "C06.405.469.860.101.163.083",
      "C06.405.469.860.180.500.040.040",
      "C22.073",
    ],
  },
  {
    ui: "D000699",
    heading: "Pain Insensitivity, Congenital",
    tree_numbers: ["C10.668.829.700", "C16.320.775"],
    synonyms: ["Hsan5", "Hereditary Sensory And Autonomic Neuropathy Type V"],
  },
  {
    ui: "D000707",
    heading: "Anaphylaxis",
    tree_numbers: ["C20.543.480.099"],
  },
  {
    ui: "D000708",
    heading: "Anaplasia",
    tree_numbers: ["C04.697.045", "C23.550.727.045"],
  },
  {
    ui: "D000711",
    heading: "Anaplasmataceae Infections",
    tree_numbers: ["C01.150.252.400.054"],
  },
  {
    ui: "D000712",
    heading: "Anaplasmosis",
    tree_numbers: ["C01.150.252.400.054.500", "C01.150.252.400.082", "C01.920.930.163", "C22.085"],
  },
  {
    ui: "D000724",
    heading: "Ancylostomiasis",
    tree_numbers: ["C01.610.335.508.700.775.455.154"],
  },
  {
    ui: "D000740",
    heading: "Anemia",
    tree_numbers: ["C15.378.071"],
    synonyms: ["Anaemia"],
  },
  {
    ui: "D000741",
    heading: "Anemia, Aplastic",
    tree_numbers: ["C15.378.071.085", "C15.378.190.223.250"],
  },
  {
    ui: "D000742",
    heading: "Anemia, Dyserythropoietic, Congenital",
    tree_numbers: ["C15.378.071.141.150.095", "C16.320.070.095"],
    synonyms: [
      "Anaemia With Multinucleated Erythroblasts",
      "Congenital Dyserythropoietic Anemia Type 3",
      "Cda Type Iii",
      "Hereditary Benign Erythroreticulosis",
      "Congenital Dyserythropoietic Anaemia Type Iii",
      "Cda Iii",
      "Congenital Dyserythropoietic Anaemia Type 3",
      "Cda Type 3",
      "Anemia With Multinucleated Erythroblasts",
      "Cdan3",
    ],
  },
  {
    ui: "D000743",
    heading: "Anemia, Hemolytic",
    tree_numbers: ["C15.378.071.141"],
    synonyms: ["Anemia Hemolytic"],
  },
  {
    ui: "D000744",
    heading: "Anemia, Hemolytic, Autoimmune",
    tree_numbers: ["C15.378.071.141.125", "C20.111.175"],
    synonyms: [
      "Autoimmune Hemolytic Anemia",
      "Autoimmune Haemolytic Anaemia",
      "Autoimmune Hemolytic Anaemia",
      "Autoimmune Hemolytic Anaemia",
    ],
  },
  {
    ui: "D000745",
    heading: "Anemia, Hemolytic, Congenital",
    tree_numbers: ["C15.378.071.141.150", "C16.320.070"],
    synonyms: ["Hereditary Hemolytic Anaemia", "Hereditary Hemolytic Anemia", "Congenital Hemolytic Anaemia"],
  },
  {
    ui: "D000746",
    heading: "Anemia, Hemolytic, Congenital Nonspherocytic",
    tree_numbers: ["C15.378.071.141.150.100", "C16.320.070.100"],
    synonyms: [
      "Hnsha",
      "Congenital Nonspherocytic Hemolytic Anaemia",
      "Hereditary Nonspherocytic Hemolytic Anaemia",
      "Hereditary Nonspherocytic Hemolytic Anemia",
    ],
  },
  {
    ui: "D000747",
    heading: "Anemia, Hypochromic",
    tree_numbers: ["C15.378.071.196"],
    synonyms: ["Anemia Hypochromic"],
  },
  {
    ui: "D000748",
    heading: "Anemia, Macrocytic",
    tree_numbers: ["C15.378.071.252"],
    synonyms: ["Macrocytic Anaemia", "Anemia Macrocytic"],
  },
  {
    ui: "D000749",
    heading: "Anemia, Megaloblastic",
    tree_numbers: ["C15.378.071.252.196"],
    synonyms: [
      "Recessive Hereditary Megaloblastic Anemia 1",
      "Mga1 Norwegian Type",
      "Grasbeck-Imerslund Syndrome",
      "Imerslund-Grasbeck Syndrome",
      "Megaloblastic Anaemia",
      "Rh-Mga1",
      "Recessive Hereditary Megaloblastic Anaemia 1",
    ],
  },
  {
    ui: "D000750",
    heading: "Anemia, Myelophthisic",
    tree_numbers: ["C15.378.071.307", "C15.378.190.636.085"],
    synonyms: ["Leukoerythroblastosis", "Anemia, Leukoerythroblastic", "Anemia Leukoerythroblastic"],
  },
  {
    ui: "D000751",
    heading: "Anemia, Neonatal",
    tree_numbers: ["C15.378.071.363", "C16.614.053"],
    synonyms: ["Anaemia Neonatal", "Neonatal Anaemia", "Anemia Neonatal"],
  },
  {
    ui: "D000752",
    heading: "Anemia, Pernicious",
    tree_numbers: ["C15.378.071.252.196.500", "C18.654.521.500.133.699.923.280"],
    synonyms: ["Addison's Anaemia", "Biermer's Anaemia", "Anemia Pernicious", "Pernicious Anaemia", "Biermer's Anemia"],
  },
  {
    ui: "D000753",
    heading: "Anemia, Refractory",
    tree_numbers: ["C15.378.071.400", "C15.378.190.625.062"],
  },
  {
    ui: "D000754",
    heading: "Anemia, Refractory, with Excess of Blasts",
    tree_numbers: ["C15.378.071.400.080", "C15.378.190.625.062.080"],
  },
  {
    ui: "D000755",
    heading: "Anemia, Sickle Cell",
    tree_numbers: ["C15.378.071.141.150.150", "C15.378.420.155", "C16.320.070.150", "C16.320.365.155"],
    synonyms: [
      "Haemoglobin Sc Disease",
      "Hemoglobin Sc Disease",
      "Hemoglobin S Disease Without Crisis",
      "Sickle-Cell/hb-C Disease Without Crisis",
      "Hb-Ss Disease Without Crisis",
      "Hb-S/hb-C Disease",
      "Drepanocytosis",
      "Sickle Cell Anaemia",
      "Hb Sc Disease",
    ],
  },
  {
    ui: "D000756",
    heading: "Anemia, Sideroblastic",
    tree_numbers: ["C15.378.071.419", "C15.378.190.625.070"],
    synonyms: ["Anemia Sideroblastic", "Anemia, Hypochromic With Iron Loading"],
  },
  {
    ui: "D000757",
    heading: "Anencephaly",
    tree_numbers: ["C10.500.680.196", "C16.131.085.197", "C16.131.666.680.196"],
  },
  {
    ui: "D000782",
    heading: "Aneuploidy",
    tree_numbers: ["C23.550.210.050", "G05.365.590.175.050", "G05.700.131"],
  },
  {
    ui: "D000783",
    heading: "Aneurysm",
    tree_numbers: ["C14.907.055"],
    synonyms: ["Vascular Tissue Disease"],
  },
  {
    ui: "D000784",
    heading: "Aneurysm, Dissecting",
    tree_numbers: ["C14.907.055.050"],
  },
  {
    ui: "D000785",
    heading: "Aneurysm, Infected",
    tree_numbers: ["C01.069", "C14.907.055.131"],
  },
  {
    ui: "D000787",
    heading: "Angina Pectoris",
    tree_numbers: ["C14.280.647.187", "C14.907.585.187", "C23.888.592.612.233.500"],
  },
  {
    ui: "D000788",
    heading: "Angina Pectoris, Variant",
    tree_numbers: ["C14.280.647.187.150.150", "C14.907.585.187.150.500", "C23.888.592.612.233.500.150.150"],
    synonyms: [
      "Variant Angina Pectoris",
      "Prinzmetal's Angina",
      "Angina Inversa",
      "Prinzmetal's Variant Angina",
      "Variant Angina",
    ],
  },
  {
    ui: "D000789",
    heading: "Angina, Unstable",
    tree_numbers: ["C14.280.647.187.150", "C14.907.585.187.150", "C23.888.592.612.233.500.150"],
    synonyms: [
      "Anginal Chest Pain At Rest",
      "Angina At Rest",
      "Preinfarction Angina",
      "Impending Infarction",
      "Unstable Angina",
      "Worsening Angina",
    ],
  },
  {
    ui: "D000793",
    heading: "Angioid Streaks",
    tree_numbers: ["C11.768.094"],
  },
  {
    ui: "D000794",
    heading: "Angiokeratoma",
    tree_numbers: ["C04.557.645.115"],
    synonyms: ["Angiokeratoma", "Cutaneous Angiokeratoma", "Angiokeratoma Of Skin", "Skin Angiokeratoma"],
  },
  {
    ui: "D000795",
    heading: "Fabry Disease",
    tree_numbers: [
      "C10.228.140.163.100.435.825.200",
      "C10.228.140.300.275.374",
      "C14.907.253.329.374",
      "C16.320.322.124",
      "C16.320.565.189.435.825.200",
      "C16.320.565.398.641.803.300",
      "C16.320.565.595.554.825.200",
      "C18.452.132.100.435.825.200",
      "C18.452.584.687.803.300",
      "C18.452.648.189.435.825.200",
      "C18.452.648.398.641.803.300",
      "C18.452.648.595.554.825.200",
    ],
    synonyms: [
      "Alpha Galactosidase Deficiency",
      "Alpha-Galactosidase A Deficiency",
      "Fabry's Disease",
      "Deficiency Of Melibiase",
      "Angiokeratoma Corporis Diffusum",
    ],
  },
  {
    ui: "D000796",
    heading: "Angiolymphoid Hyperplasia with Eosinophilia",
    tree_numbers: ["C15.378.553.231.085", "C15.604.515.292.007", "C17.800.060"],
    synonyms: ["Angiolymphoid Cutaneous Hyperplasia", "Epithelioid Hemangioma Of Skin"],
  },
  {
    ui: "D000798",
    heading: "Angiomatosis",
    tree_numbers: ["C14.907.077"],
  },
  {
    ui: "D000799",
    heading: "Angioedema",
    tree_numbers: ["C14.907.079", "C17.800.862.945.066", "C20.543.480.904.066"],
    synonyms: ["Quincke's Edema", "Angioneurotic Edema", "Angioneurotic Oedema", "Giant Urticaria"],
  },
  {
    ui: "D000820",
    heading: "Animal Diseases",
    tree_numbers: ["C22"],
  },
  {
    ui: "D000839",
    heading: "Aniseikonia",
    tree_numbers: ["C11.744.116"],
  },
  {
    ui: "D000844",
    heading: "Ankylosis",
    tree_numbers: ["C05.550.069"],
  },
  {
    ui: "D000848",
    heading: "Anodontia",
    tree_numbers: ["C07.650.800.100", "C07.793.700.100", "C16.131.850.800.100"],
    synonyms: ["Familial Tooth Agenesis", "Selective Tooth Agenesis", "Hypodontia", "Oligodontia"],
  },
  {
    ui: "D000849",
    heading: "Anomia",
    tree_numbers: ["C10.597.606.150.500.090", "C23.888.592.604.150.500.090"],
    synonyms: ["Anomic Aphasia", "Anomia"],
  },
  {
    ui: "D000853",
    heading: "Anophthalmos",
    tree_numbers: ["C11.250.080", "C16.131.384.159"],
  },
  {
    ui: "D000855",
    heading: "Anorexia",
    tree_numbers: ["C23.888.821.108"],
  },
  {
    ui: "D000856",
    heading: "Anorexia Nervosa",
    tree_numbers: ["F03.400.125"],
  },
  {
    ui: "D000857",
    heading: "Olfaction Disorders",
    tree_numbers: ["C10.597.751.600", "C23.888.592.763.550"],
  },
  {
    ui: "D000858",
    heading: "Anovulation",
    tree_numbers: ["C13.351.500.056.630.050", "C19.391.630.050", "G08.686.784.690.080"],
  },
  {
    ui: "D000860",
    heading: "Hypoxia",
    tree_numbers: ["C23.888.852.079"],
  },
  {
    ui: "D000868",
    heading: "Anterior Compartment Syndrome",
    tree_numbers: ["C05.651.180.063", "C14.907.303.063"],
    synonyms: ["Anterior Compartment Syndrome"],
  },
  {
    ui: "D000874",
    heading: "Anthracosilicosis",
    tree_numbers: [
      "C08.381.483.581.062.500",
      "C08.381.483.581.760.125",
      "C08.381.520.702.062.500",
      "C08.381.520.702.760.125",
      "C24.800.834.201",
    ],
  },
  {
    ui: "D000881",
    heading: "Anthrax",
    tree_numbers: ["C01.150.252.410.090.072"],
  },
  {
    ui: "D000987",
    heading: "Antisocial Personality Disorder",
    tree_numbers: ["F03.675.050"],
    synonyms: [
      "Sociopathic Personality",
      "Psychopath.personality",
      "Psychopathic Personality",
      "Asocial Personality",
      "Dissocial Personality Disorder",
      "Psychopathic Personality Disorder",
    ],
  },
  {
    ui: "D001002",
    heading: "Anuria",
    tree_numbers: ["C12.777.419.078", "C12.777.934.141", "C13.351.968.419.078", "C13.351.968.934.070"],
    synonyms: ["Suppression Of Urinary Secretion"],
  },
  {
    ui: "D001004",
    heading: "Anus Diseases",
    tree_numbers: ["C06.405.469.860.101"],
    synonyms: [
      "Anal Ulcer",
      "Anal Fissure",
      "Ulcer Of Anus",
      "Solitary Anal Ulcer",
      "Anal Disease",
      "Nontraumatic Tear Of Anus",
      "Fissure In Ano",
      "Anal Fissure And Fistula",
    ],
  },
  {
    ui: "D001005",
    heading: "Anus Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.307.790.040",
      "C06.301.371.411.307.790.040",
      "C06.405.249.411.307.790.040",
      "C06.405.469.491.307.790.040",
      "C06.405.469.860.101.163",
      "C06.405.469.860.180.500.040",
    ],
    synonyms: ["Ca Anus", "Carcinoma Of Anus"],
  },
  {
    ui: "D001006",
    heading: "Anus, Imperforate",
    tree_numbers: ["C06.198.050", "C16.131.314.094"],
    synonyms: [
      "Imperforate Anus",
      "Congenital Atresia Of Anus",
      "Anal Atresia",
      "Congenital Or Infantile Occlusion Of Anus",
    ],
  },
  {
    ui: "D001008",
    heading: "Anxiety Disorders",
    tree_numbers: ["F03.080"],
    synonyms: ["Anxiety", "Anxiety State"],
  },
  {
    ui: "D001010",
    heading: "Anxiety, Separation",
    tree_numbers: ["F03.080.300", "F03.625.047"],
  },
  {
    ui: "D001014",
    heading: "Aortic Aneurysm",
    tree_numbers: ["C14.907.055.239", "C14.907.109.139"],
    synonyms: ["Ruptured Aortic Aneurysm"],
  },
  {
    ui: "D001015",
    heading: "Aortic Arch Syndromes",
    tree_numbers: ["C14.907.109.239"],
    synonyms: [
      "Takayasu Arteritis",
      "Idiopathic Aortitis",
      "Aortic Arch Syndrome",
      "Aortic Arch Arteritis",
      "Takayasu's Disease",
      "Aortic Arch Syndrome",
    ],
  },
  {
    ui: "D001017",
    heading: "Aortic Coarctation",
    tree_numbers: ["C14.240.400.090", "C14.280.400.090", "C16.131.240.400.090"],
  },
  {
    ui: "D001018",
    heading: "Aortic Diseases",
    tree_numbers: ["C14.907.109"],
    synonyms: ["Aortic Disorder", "Disorder Of The Aorta"],
  },
  {
    ui: "D001019",
    heading: "Aortic Rupture",
    tree_numbers: ["C14.907.055.185.125", "C14.907.055.239.175", "C14.907.109.139.175", "C26.761.125"],
    synonyms: ["Ruptured Aortic Aneurysm"],
  },
  {
    ui: "D001020",
    heading: "Aortic Stenosis, Subvalvular",
    tree_numbers: ["C14.280.484.048.750.070", "C14.280.955.249.070"],
  },
  {
    ui: "D001022",
    heading: "Aortic Valve Insufficiency",
    tree_numbers: ["C14.280.484.048.500"],
    synonyms: [
      "Aortic Incompetence",
      "Rheumatic Aortic Regurgitation",
      "Aortic Regurgitation",
      "Rheumatic Aortic Valve Regurgitation",
      "Rheumatic Aortic Valve Insufficiency",
      "Rheumatic Aortic Insufficiency",
      "Aortic Insufficiency",
    ],
  },
  {
    ui: "D001023",
    heading: "Aortic Valve Prolapse",
    tree_numbers: ["C14.280.484.048.625", "C14.280.484.400.100"],
  },
  {
    ui: "D001024",
    heading: "Aortic Valve Stenosis",
    tree_numbers: ["C14.280.484.048.750", "C14.280.955.249"],
    synonyms: ["Rheumatic Aortic Stenosis", "Rheumatic Aortic Valve Stenosis", "Aortic Stenosis"],
  },
  {
    ui: "D001025",
    heading: "Aortitis",
    tree_numbers: ["C14.907.109.320", "C14.907.940.080"],
  },
  {
    ui: "D001028",
    heading: "Aortopulmonary Septal Defect",
    tree_numbers: ["C14.240.400.560.098", "C14.280.400.560.098", "C16.131.240.400.560.098"],
  },
  {
    ui: "D001035",
    heading: "Aphakia",
    tree_numbers: ["C11.510.103"],
  },
  {
    ui: "D001036",
    heading: "Aphakia, Postcataract",
    tree_numbers: ["C11.510.103.110"],
  },
  {
    ui: "D001037",
    heading: "Aphasia",
    tree_numbers: ["C10.597.606.150.500.800.100", "C23.888.592.604.150.500.800.100"],
  },
  {
    ui: "D001039",
    heading: "Aphasia, Broca",
    tree_numbers: ["C10.597.606.150.500.800.100.100", "C23.888.592.604.150.500.800.100.100"],
  },
  {
    ui: "D001041",
    heading: "Aphasia, Wernicke",
    tree_numbers: ["C10.597.606.150.500.800.100.166", "C23.888.592.604.150.500.800.100.166"],
  },
  {
    ui: "D001044",
    heading: "Aphonia",
    tree_numbers: ["C08.360.940.160", "C09.400.940.160", "C10.597.975.100", "C23.888.592.979.100"],
  },
  {
    ui: "D001049",
    heading: "Apnea",
    tree_numbers: ["C08.618.085", "C23.888.852.130"],
  },
  {
    ui: "D001063",
    heading: "Appendiceal Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.184.290",
      "C06.301.371.411.184.290",
      "C06.405.249.411.184.290",
      "C06.405.469.110.417.290",
      "C06.405.469.491.184.290",
    ],
    synonyms: ["Appendix Neoplasm", "Neoplasm Of Appendix"],
  },
  {
    ui: "D001064",
    heading: "Appendicitis",
    tree_numbers: ["C01.463.099", "C06.405.205.099", "C06.405.469.110.207"],
    synonyms: [
      "Acute Appendicitis With Generalized Peritonitis",
      "Acute Appendicitis",
      "Acute Appendicitis With Peritoneal Abscess",
    ],
  },
  {
    ui: "D001068",
    heading: "Feeding and Eating Disorders",
    tree_numbers: ["F03.400"],
  },
  {
    ui: "D001072",
    heading: "Apraxias",
    tree_numbers: ["C10.597.606.881.350", "C23.888.592.604.882.350", "F01.700.875.350"],
  },
  {
    ui: "D001079",
    heading: "Apudoma",
    tree_numbers: ["C04.557.465.625.650.135", "C04.557.470.035.250", "C04.557.580.625.650.135"],
  },
  {
    ui: "D001098",
    heading: "Spider Bites",
    tree_numbers: ["C25.723.127.723", "C26.176.790"],
  },
  {
    ui: "D001100",
    heading: "Arachnoiditis",
    tree_numbers: ["C10.228.614.097"],
  },
  {
    ui: "D001102",
    heading: "Arbovirus Infections",
    tree_numbers: ["C01.920.500", "C01.925.081"],
    synonyms: ["Viral Disease", "Viral Infection", "Virus Infection"],
  },
  {
    ui: "D001112",
    heading: "Arcus Senilis",
    tree_numbers: ["C11.204.299.070"],
    synonyms: ["Arcus Of Cornea", "Corneal Arcus"],
  },
  {
    ui: "D001117",
    heading: "Arenaviridae Infections",
    tree_numbers: ["C01.925.782.082"],
  },
  {
    ui: "D001129",
    heading: "Argyria",
    tree_numbers: ["C17.800.621.166", "C25.723.522.313"],
  },
  {
    ui: "D001134",
    heading: "Arm Injuries",
    tree_numbers: ["C26.088"],
  },
  {
    ui: "D001139",
    heading: "Arnold-Chiari Malformation",
    tree_numbers: ["C10.500.680.291", "C16.131.666.680.291"],
  },
  {
    ui: "D001145",
    heading: "Arrhythmias, Cardiac",
    tree_numbers: ["C14.280.067", "C23.550.073"],
  },
  {
    ui: "D001146",
    heading: "Arrhythmia, Sinus",
    tree_numbers: ["C14.280.067.093", "C23.550.073.093"],
  },
  {
    ui: "D001157",
    heading: "Arterial Occlusive Diseases",
    tree_numbers: ["C14.907.137"],
    synonyms: ["Arterial Occlusive Disease"],
  },
  {
    ui: "D001159",
    heading: "Arterio-Arterial Fistula",
    tree_numbers: ["C14.240.850.984.500", "C14.907.933.110", "C16.131.240.850.500", "C23.300.575.950.150"],
  },
  {
    ui: "D001161",
    heading: "Arteriosclerosis",
    tree_numbers: ["C14.907.137.126"],
    synonyms: ["Arteriosclerosis", "Arteriosclerotic Vascular Disease"],
  },
  {
    ui: "D001162",
    heading: "Arteriosclerosis Obliterans",
    tree_numbers: ["C14.907.137.126.114"],
    synonyms: ["Arteriosclerosis Obliterans"],
  },
  {
    ui: "D001164",
    heading: "Arteriovenous Fistula",
    tree_numbers: [
      "C14.240.850.750.147",
      "C14.240.850.984.750",
      "C14.907.150.125",
      "C14.907.933.555",
      "C16.131.240.850.750.125",
      "C23.300.575.950.250",
    ],
  },
  {
    ui: "D001165",
    heading: "Arteriovenous Malformations",
    tree_numbers: ["C14.240.850.750", "C14.907.150", "C16.131.240.850.750"],
  },
  {
    ui: "D001167",
    heading: "Arteritis",
    tree_numbers: ["C14.907.940.090"],
  },
  {
    ui: "D001168",
    heading: "Arthritis",
    tree_numbers: ["C05.550.114"],
    synonyms: ["Inflammatory Disorder Of Joint"],
  },
  {
    ui: "D001169",
    heading: "Arthritis, Experimental",
    tree_numbers: ["C05.550.114.015", "E05.598.500.249"],
  },
  {
    ui: "D001170",
    heading: "Arthritis, Infectious",
    tree_numbers: ["C01.100", "C05.550.114.099"],
    synonyms: ["Infectious Arthritis"],
  },
  {
    ui: "D001171",
    heading: "Arthritis, Juvenile",
    tree_numbers: ["C05.550.114.122", "C05.799.056", "C17.300.775.049", "C20.111.198"],
  },
  {
    ui: "D001172",
    heading: "Arthritis, Rheumatoid",
    tree_numbers: ["C05.550.114.154", "C05.799.114", "C17.300.775.099", "C20.111.199"],
    synonyms: ["Arthritis Or Polyarthritis, Rheumatic", "Atrophic Arthritis"],
  },
  {
    ui: "D001176",
    heading: "Arthrogryposis",
    tree_numbers: ["C05.550.150", "C05.651.102", "C05.660.077", "C16.131.621.077"],
  },
  {
    ui: "D001177",
    heading: "Arthropathy, Neurogenic",
    tree_numbers: ["C05.550.186"],
    synonyms: ["Arthropathy Associated With Neurological Disorder", "Charcot's Arthropathy", "Neuropathic Arthropathy"],
  },
  {
    ui: "D001183",
    heading: "Arthus Reaction",
    tree_numbers: ["C20.543.520.100"],
    synonyms: ["Arthus Type Urticaria", "Arthus Phenomenon", "Arthus Phenomenon", "Arthus Reaction"],
  },
  {
    ui: "D001184",
    heading: "Articulation Disorders",
    tree_numbers: ["C10.597.606.150.500.800.150", "C23.888.592.604.150.500.800.150"],
    synonyms: ["Phonological Disorder", "Articulation Impairment"],
  },
  {
    ui: "D001195",
    heading: "Asbestosis",
    tree_numbers: ["C08.381.483.581.125", "C08.381.520.702.125", "C24.800.127"],
  },
  {
    ui: "D001196",
    heading: "Ascariasis",
    tree_numbers: ["C01.610.335.508.700.100.070"],
    synonyms: ["Ascariasis - Roundworm"],
  },
  {
    ui: "D001198",
    heading: "Ascaridiasis",
    tree_numbers: ["C01.610.335.508.700.100.080"],
  },
  {
    ui: "D001201",
    heading: "Ascites",
    tree_numbers: ["C23.550.081"],
  },
  {
    ui: "D001206",
    heading: "Ascorbic Acid Deficiency",
    tree_numbers: ["C18.654.521.500.133.115"],
  },
  {
    ui: "D001228",
    heading: "Aspergillosis",
    tree_numbers: ["C01.150.703.080"],
    synonyms: ["Infection Due To Aspergillus"],
  },
  {
    ui: "D001229",
    heading: "Aspergillosis, Allergic Bronchopulmonary",
    tree_numbers: [
      "C01.150.703.080.768.500",
      "C01.150.703.534.850.500",
      "C01.748.435.090",
      "C08.381.472.850.500",
      "C08.674.060",
      "C08.730.435.090",
      "C20.543.480.680.085",
    ],
    synonyms: ["Pulmonary Aspergillus Disease"],
  },
  {
    ui: "D001237",
    heading: "Asphyxia",
    tree_numbers: ["C23.550.260.095", "C26.103"],
  },
  {
    ui: "D001238",
    heading: "Asphyxia Neonatorum",
    tree_numbers: ["C16.614.092"],
    synonyms: ["Birth Asphyxia", "Postnatal Asphyxia", "Asphyxia - Birth", "Asphyxia, In Liveborn Infant"],
  },
  {
    ui: "D001247",
    heading: "Asthenia",
    tree_numbers: ["C23.888.089"],
  },
  {
    ui: "D001248",
    heading: "Asthenopia",
    tree_numbers: ["C11.093"],
  },
  {
    ui: "D001249",
    heading: "Asthma",
    tree_numbers: ["C08.127.108", "C08.381.495.108", "C08.674.095", "C20.543.480.680.095"],
    synonyms: [
      "Bronchial Hyperreactivity",
      "Exercise Induced Asthma",
      "Chronic Obstructive Asthma With Status Asthmaticus",
      "Chronic Obstructive Asthma",
      "Exercise-Induced Asthma",
      "Chronic Obstructive Asthma With Acute Exacerbation",
    ],
  },
  {
    ui: "D001250",
    heading: "Asthma, Exercise-Induced",
    tree_numbers: ["C08.127.108.110", "C08.674.095.110", "C20.543.480.680.095.110"],
  },
  {
    ui: "D001251",
    heading: "Astigmatism",
    tree_numbers: ["C11.744.212"],
  },
  {
    ui: "D001254",
    heading: "Astrocytoma",
    tree_numbers: ["C04.557.465.625.600.380.080", "C04.557.470.670.380.080", "C04.557.580.625.600.380.080"],
    synonyms: ["Gemistocytic Astrocytic Tumor", "Gemistocytic Astrocytoma"],
  },
  {
    ui: "D001259",
    heading: "Ataxia",
    tree_numbers: ["C10.597.350.090", "C23.888.592.350.090"],
  },
  {
    ui: "D001260",
    heading: "Ataxia Telangiectasia",
    tree_numbers: [
      "C10.228.140.252.190.530.060",
      "C10.562.100",
      "C10.597.350.090.500.530.060",
      "C14.907.823.213",
      "C16.320.080",
      "C16.320.798.250",
      "C18.452.284.060",
      "C20.673.795.250",
    ],
    synonyms: ["Louis Bar Syndrome", "Boder-Sedgwick Syndrome"],
  },
  {
    ui: "D001261",
    heading: "Pulmonary Atelectasis",
    tree_numbers: ["C08.381.730"],
  },
  {
    ui: "D001264",
    heading: "Athetosis",
    tree_numbers: ["C10.597.350.110", "C23.888.592.350.110"],
  },
  {
    ui: "D001265",
    heading: "Athletic Injuries",
    tree_numbers: ["C26.115"],
  },
  {
    ui: "D001281",
    heading: "Atrial Fibrillation",
    tree_numbers: ["C14.280.067.198", "C23.550.073.198"],
    synonyms: ["A-Fib"],
  },
  {
    ui: "D001282",
    heading: "Atrial Flutter",
    tree_numbers: ["C14.280.067.248", "C23.550.073.248"],
  },
  {
    ui: "D001284",
    heading: "Atrophy",
    tree_numbers: ["C23.300.070"],
  },
  {
    ui: "D001289",
    heading: "Attention Deficit Disorder with Hyperactivity",
    tree_numbers: ["F03.625.094.150"],
    synonyms: ["Attention Deficit Disorder", "Hyperkinetic Disorder", "Adhd"],
  },
  {
    ui: "D001304",
    heading: "Auditory Diseases, Central",
    tree_numbers: ["C09.218.807.186", "C10.228.140.068"],
  },
  {
    ui: "D001308",
    heading: "Auditory Perceptual Disorders",
    tree_numbers: [
      "C09.218.807.186.094",
      "C10.228.140.068.094",
      "C10.597.606.762.200",
      "C23.888.592.604.764.200",
      "F01.700.750.200",
      "F03.615.250.100",
    ],
  },
  {
    ui: "D001321",
    heading: "Autistic Disorder",
    tree_numbers: ["F03.625.164.113.500"],
    synonyms: [
      "Childhood Autism",
      "Autism",
      "Infantile Autism",
      "Autistic Disorder Of Childhood Onset",
      "Kanner's Syndrome",
    ],
  },
  {
    ui: "D001327",
    heading: "Autoimmune Diseases",
    tree_numbers: ["C20.111"],
  },
  {
    ui: "D001329",
    heading: "Autolysis",
    tree_numbers: ["C23.550.260.224.617.236"],
  },
  {
    ui: "D001342",
    heading: "Autonomic Nervous System Diseases",
    tree_numbers: ["C10.177"],
  },
  {
    ui: "D001353",
    heading: "Avian Leukosis",
    tree_numbers: ["C01.925.782.815.096", "C01.925.928.120", "C04.557.337.372.216", "C04.619.531.216", "C22.131.094"],
  },
  {
    ui: "D001357",
    heading: "Sarcoma, Avian",
    tree_numbers: [
      "C01.925.782.815.800",
      "C01.925.928.800",
      "C04.557.450.795.830.800",
      "C04.619.857.800",
      "C22.131.800",
    ],
  },
  {
    ui: "D001361",
    heading: "Avitaminosis",
    tree_numbers: ["C18.654.521.500.133"],
  },
  {
    ui: "D001404",
    heading: "Babesiosis",
    tree_numbers: [
      "C01.610.701.688.122",
      "C01.610.752.075",
      "C01.610.752.625.122",
      "C01.920.930.182",
      "C22.674.710.122",
    ],
    synonyms: ["Babesiasis", "Piroplasmosis", "Infection By Babesia"],
  },
  {
    ui: "D001416",
    heading: "Back Pain",
    tree_numbers: ["C23.888.592.612.107"],
  },
  {
    ui: "D001423",
    heading: "Bacterial Infections and Mycoses",
    tree_numbers: ["C01.150"],
  },
  {
    ui: "D001424",
    heading: "Bacterial Infections",
    tree_numbers: ["C01.150.252"],
  },
  {
    ui: "D001437",
    heading: "Bacteriuria",
    tree_numbers: ["C01.915.219", "C12.777.892.219", "C13.351.968.892.219"],
  },
  {
    ui: "D001442",
    heading: "Bacteroides Infections",
    tree_numbers: ["C01.150.252.400.110.109"],
  },
  {
    ui: "D001446",
    heading: "Balanitis",
    tree_numbers: ["C12.294.494.136"],
  },
  {
    ui: "D001447",
    heading: "Balantidiasis",
    tree_numbers: ["C01.610.432.146", "C01.610.752.200.146", "C06.405.469.452.146"],
  },
  {
    ui: "D001449",
    heading: "Balkan Nephropathy",
    tree_numbers: ["C12.777.419.570.643.150", "C13.351.968.419.570.643.150"],
    synonyms: ["Balkan Endemic Nephropathy", "Danubian Endemic Familial Nephropathy", "Defn"],
  },
  {
    ui: "D001469",
    heading: "Barotrauma",
    tree_numbers: ["C26.120"],
  },
  {
    ui: "D001471",
    heading: "Barrett Esophagus",
    tree_numbers: ["C04.834.154", "C06.405.117.102"],
    synonyms: [
      "Barrett's Ulcer Of Esophagus",
      "Barretts Syndrome",
      "Ulcerative Esophagitis",
      "Barrett's Oesophagus",
      "Barrett's Esophagus With Esophagitis",
      "Barrett Esophagus",
    ],
  },
  {
    ui: "D001474",
    heading: "Bartonella Infections",
    tree_numbers: ["C01.150.252.400.126.100"],
    synonyms: ["Bartonelliasis", "Rochalimaea Infection", "Bartonella Infectious Disease"],
  },
  {
    ui: "D001476",
    heading: "Bartonellaceae Infections",
    tree_numbers: ["C01.150.252.400.126"],
  },
  {
    ui: "D001477",
    heading: "Bartter Syndrome",
    tree_numbers: ["C12.777.419.815.279", "C13.351.968.419.815.279", "C19.053.800.604.249"],
    synonyms: ["Aldosteronism With Hyperplasia Of The Adrenal Cortex", "Bartter's Syndrome"],
  },
  {
    ui: "D001478",
    heading: "Basal Cell Nevus Syndrome",
    tree_numbers: [
      "C04.182.089.530.690.150",
      "C04.557.470.200.165.150",
      "C04.557.470.565.165.150",
      "C04.700.175",
      "C05.116.099.105",
      "C05.500.470.690.150",
      "C07.320.450.670.130",
      "C16.131.077.130",
      "C16.320.700.175",
    ],
    synonyms: ["Basal Cell Nevus Syndrome", "Nbccs", "Gorlin Syndrome"],
  },
  {
    ui: "D001480",
    heading: "Basal Ganglia Diseases",
    tree_numbers: ["C10.228.140.079"],
  },
  {
    ui: "D001497",
    heading: "Battered Child Syndrome",
    tree_numbers: ["C26.130", "F03.950.750.124"],
  },
  {
    ui: "D001506",
    heading: "Beckwith-Wiedemann Syndrome",
    tree_numbers: ["C16.131.077.133", "C16.131.260.080", "C16.320.180.080"],
  },
  {
    ui: "D001523",
    heading: "Mental Disorders",
    tree_numbers: ["F03"],
  },
  {
    ui: "D001528",
    heading: "Behcet Syndrome",
    tree_numbers: [
      "C07.465.075",
      "C11.941.879.780.880.200",
      "C14.907.940.100",
      "C16.320.382.250",
      "C17.800.827.368.250",
      "C17.800.862.150",
    ],
    synonyms: ["Behet's Syndrome", "Adamantiades-Behcet Disease", "Behcet Syndrome", "Triple Symptom Complex"],
  },
  {
    ui: "D001602",
    heading: "Beriberi",
    tree_numbers: ["C18.654.521.500.133.699.827.223"],
  },
  {
    ui: "D001606",
    heading: "Bernard-Soulier Syndrome",
    tree_numbers: ["C15.378.100.100.080", "C15.378.140.120", "C15.378.463.080", "C16.320.099.080"],
    synonyms: [
      "Giant Platelet Syndrome",
      "Hemorrhagic Dystrophic Thrombocytopenia",
      "Bernard - Soulier Thrombopathy",
      "Thrombopathy, Bernard-Soulier",
      "Bernard Soulier Syndrome",
    ],
  },
  {
    ui: "D001607",
    heading: "Berylliosis",
    tree_numbers: ["C08.381.483.581.225", "C08.381.520.702.225", "C24.800.225"],
    synonyms: ["Beryllium Poisoning"],
  },
  {
    ui: "D001630",
    heading: "Bezoars",
    tree_numbers: ["C26.392.183"],
  },
  {
    ui: "D001649",
    heading: "Bile Duct Diseases",
    tree_numbers: ["C06.130.120"],
    synonyms: ["Disorder Of Bile Duct", "Bile Duct Disorder"],
  },
  {
    ui: "D001650",
    heading: "Bile Duct Neoplasms",
    tree_numbers: ["C04.588.274.120.250", "C06.130.120.120", "C06.130.320.120", "C06.301.120.250"],
    synonyms: ["Malignant Neoplasm Of The Extrahepatic Bile Duct", "Bile Duct Tumor", "Ca Extrahepatic Bile Ducts"],
  },
  {
    ui: "D001651",
    heading: "Cholestasis, Extrahepatic",
    tree_numbers: ["C06.130.120.135.150"],
    synonyms: ["Extrahepatic Biliary Stasis", "Extrahepatic Obstructive Biliary Disease"],
  },
  {
    ui: "D001655",
    heading: "Bile Reflux",
    tree_numbers: ["C06.130.140", "C06.405.748.240.140"],
  },
  {
    ui: "D001656",
    heading: "Biliary Atresia",
    tree_numbers: ["C06.130.120.123", "C06.198.125", "C16.131.314.125"],
    synonyms: ["Biliary Atresia, Congenital", "Congenital Biliary Atresia", "Atresia Of Bile Duct"],
  },
  {
    ui: "D001657",
    heading: "Biliary Dyskinesia",
    tree_numbers: ["C06.130.120.250.098"],
    synonyms: ["Sphincter Of Oddi Dysfunction"],
  },
  {
    ui: "D001658",
    heading: "Biliary Fistula",
    tree_numbers: ["C06.267.150", "C23.300.575.185.150"],
  },
  {
    ui: "D001660",
    heading: "Biliary Tract Diseases",
    tree_numbers: ["C06.130"],
  },
  {
    ui: "D001661",
    heading: "Biliary Tract Neoplasms",
    tree_numbers: ["C04.588.274.120", "C06.130.320", "C06.301.120"],
    synonyms: ["Malignant Tumour Of Biliary Tract"],
  },
  {
    ui: "D001714",
    heading: "Bipolar Disorder",
    tree_numbers: ["F03.084.500"],
    synonyms: [
      "Manic Depression",
      "Mixed Bipolar Disorder",
      "Manic Bipolar Affective Disorder",
      "Manic Depressive Disorder",
      "Manic Bipolar I Disorder",
      "Bipolar Disorder Manic Phase",
      "Manic Disorder",
      "Bipolar Depression",
    ],
  },
  {
    ui: "D001715",
    heading: "Bird Diseases",
    tree_numbers: ["C22.131"],
  },
  {
    ui: "D001716",
    heading: "Bird Fancier's Lung",
    tree_numbers: ["C08.381.483.125.125", "C08.674.055.125", "C20.543.480.680.075.125", "C24.125"],
    synonyms: [
      "Bird-Fancier's Lung",
      "Bird-Fanciers' Lung",
      "Bird Breeder's Lung",
      "Pigeon Breeder's Lung",
      "Bird Fancier Lung",
      "Poultry Worker's Lung",
      "Avian Hypersensitivity Pneumonitis",
    ],
  },
  {
    ui: "D001720",
    heading: "Birth Injuries",
    tree_numbers: ["C16.614.131", "C26.141"],
  },
  {
    ui: "D001724",
    heading: "Birth Weight",
    tree_numbers: [
      "C23.888.144.186",
      "E01.370.600.115.100.160.120.186",
      "E05.041.124.160.750.149",
      "G07.100.100.160.120.186",
      "G07.345.249.314.120.186",
    ],
  },
  {
    ui: "D001733",
    heading: "Bites and Stings",
    tree_numbers: ["C25.723.127", "C26.176"],
  },
  {
    ui: "D001734",
    heading: "Bites, Human",
    tree_numbers: ["C26.176.071"],
  },
  {
    ui: "D001742",
    heading: "Blackwater Fever",
    tree_numbers: ["C01.610.752.530.650.228", "C01.920.875.650.228"],
    synonyms: ["Blackwater Fever", "Hemoglobinuric, Malaria", "Malarial Hemoglobinuria", "Black Water Fever"],
  },
  {
    ui: "D001744",
    heading: "Urinary Bladder Calculi",
    tree_numbers: [
      "C12.777.829.720",
      "C12.777.967.500.925",
      "C13.351.968.829.521",
      "C13.351.968.967.500.925",
      "C23.300.175.850.875",
    ],
  },
  {
    ui: "D001745",
    heading: "Urinary Bladder Diseases",
    tree_numbers: ["C12.777.829", "C13.351.968.829"],
    synonyms: ["Urinary Bladder Disease"],
  },
  {
    ui: "D001746",
    heading: "Bladder Exstrophy",
    tree_numbers: ["C12.706.132", "C12.777.829.132", "C13.351.875.132", "C13.351.968.829.132", "C16.131.939.132"],
  },
  {
    ui: "D001747",
    heading: "Urinary Bladder Fistula",
    tree_numbers: [
      "C12.706.881.312",
      "C12.777.829.733",
      "C13.351.875.881.312",
      "C13.351.968.829.548",
      "C23.300.575.825.250",
    ],
  },
  {
    ui: "D001748",
    heading: "Urinary Bladder Neck Obstruction",
    tree_numbers: ["C12.777.767.700.962", "C12.777.829.760", "C13.351.968.767.700.850", "C13.351.968.829.601"],
    synonyms: ["Obstruction Of Bladder Neck Or Vesicourethral Orifice"],
  },
  {
    ui: "D001749",
    heading: "Urinary Bladder Neoplasms",
    tree_numbers: [
      "C04.588.945.947.960",
      "C12.758.820.968",
      "C12.777.829.813",
      "C13.351.937.820.945",
      "C13.351.968.829.707",
    ],
    synonyms: ["Tumor Of The Bladder", "Bladder Cancer"],
  },
  {
    ui: "D001750",
    heading: "Urinary Bladder, Neurogenic",
    tree_numbers: ["C10.597.900", "C12.777.829.839", "C13.351.968.829.760", "C23.888.592.900"],
    synonyms: [
      "Neurogenic Urinary Bladder Disorder",
      "Neuropathic Bladder",
      "Neurogenic Dysfunction Of The Urinary Bladder",
    ],
  },
  {
    ui: "D001752",
    heading: "Blast Crisis",
    tree_numbers: [
      "C04.557.337.539.250.100",
      "C04.697.098.500.110",
      "C15.378.190.636.370.100",
      "C23.550.727.098.500.110",
    ],
  },
  {
    ui: "D001753",
    heading: "Blast Injuries",
    tree_numbers: ["C26.120.126"],
  },
  {
    ui: "D001759",
    heading: "Blastomycosis",
    tree_numbers: [
      "C01.150.703.302.055",
      "C01.150.703.534.350",
      "C01.748.435.395",
      "C01.800.200.055",
      "C08.381.472.350",
      "C08.730.435.395",
      "C17.800.838.208.055",
    ],
    synonyms: [
      "Gilchrist's Disease",
      "Blastomycotic Infection",
      "Chicago Disease",
      "Infection By Blastomyces Dermatitidis",
      "North American Blastomycosis",
      "Blastomyces Dermatitidis Infection",
    ],
  },
  {
    ui: "D001762",
    heading: "Blepharitis",
    tree_numbers: ["C11.338.133"],
  },
  {
    ui: "D001763",
    heading: "Blepharoptosis",
    tree_numbers: ["C11.338.204"],
    synonyms: ["Drooping Eyelid", "Blepharoptosis"],
  },
  {
    ui: "D001764",
    heading: "Blepharospasm",
    tree_numbers: ["C11.338.250"],
  },
  {
    ui: "D001765",
    heading: "Blind Loop Syndrome",
    tree_numbers: ["C06.405.469.637.145", "C18.452.603.145"],
    synonyms: ["Blind Loop Syndrome", "Bacterial Overgrowth Syndrome"],
  },
  {
    ui: "D001766",
    heading: "Blindness",
    tree_numbers: ["C10.597.751.941.162", "C11.966.075", "C23.888.592.763.941.162"],
  },
  {
    ui: "D001768",
    heading: "Blister",
    tree_numbers: ["C17.800.865.187", "C23.300.122"],
  },
  {
    ui: "D001778",
    heading: "Blood Coagulation Disorders",
    tree_numbers: ["C15.378.100"],
    synonyms: [
      "Postpartum Coagulation Defect With Delivery",
      "Coagulation Protein Disease",
      "Postpartum Coagulation Defect",
    ],
  },
  {
    ui: "D001791",
    heading: "Blood Platelet Disorders",
    tree_numbers: ["C15.378.140"],
    synonyms: ["Platelet Disorder", "Thrombocytopathy"],
  },
  {
    ui: "D001796",
    heading: "Blood Protein Disorders",
    tree_numbers: ["C15.378.147"],
    synonyms: ["Blood Protein Disorder"],
  },
  {
    ui: "D001816",
    heading: "Bloom Syndrome",
    tree_numbers: ["C16.131.077.137", "C16.320.798.313", "C18.452.284.100", "C20.673.795.313"],
    synonyms: ["Congenital Telangiectatic Erythema Syndrome", "Bloom-Torre-Machacek Syndrome"],
  },
  {
    ui: "D001819",
    heading: "Bluetongue",
    tree_numbers: ["C01.920.500.125", "C01.925.081.125", "C01.925.782.791.315", "C22.836.120"],
  },
  {
    ui: "D001832",
    heading: "Body Temperature Changes",
    tree_numbers: ["C23.888.119"],
  },
  {
    ui: "D001835",
    heading: "Body Weight",
    tree_numbers: [
      "C23.888.144",
      "E01.370.600.115.100.160.120",
      "E05.041.124.160.750",
      "G07.100.100.160.120",
      "G07.345.249.314.120",
    ],
  },
  {
    ui: "D001836",
    heading: "Body Weight Changes",
    tree_numbers: ["C23.888.144.243", "G07.345.249.314.120.200"],
  },
  {
    ui: "D001845",
    heading: "Bone Cysts",
    tree_numbers: ["C04.182.089", "C05.116.070"],
  },
  {
    ui: "D001847",
    heading: "Bone Diseases",
    tree_numbers: ["C05.116"],
    synonyms: [],
  },
  {
    ui: "D001848",
    heading: "Bone Diseases, Developmental",
    tree_numbers: ["C05.116.099"],
  },
  {
    ui: "D001849",
    heading: "Bone Diseases, Endocrine",
    tree_numbers: ["C05.116.132", "C19.149"],
  },
  {
    ui: "D001850",
    heading: "Bone Diseases, Infectious",
    tree_numbers: ["C01.160", "C05.116.165"],
  },
  {
    ui: "D001851",
    heading: "Bone Diseases, Metabolic",
    tree_numbers: ["C05.116.198", "C18.452.104"],
  },
  {
    ui: "D001855",
    heading: "Bone Marrow Diseases",
    tree_numbers: ["C15.378.190"],
    synonyms: ["Bone Marrow Disorder"],
  },
  {
    ui: "D001859",
    heading: "Bone Neoplasms",
    tree_numbers: ["C04.588.149", "C05.116.231"],
    synonyms: [
      "Malignant Neoplasm Of Bone",
      "Malignant Osseous Tumor",
      "Bone Tumour",
      "Neoplasm Of Bone",
      "Bone Neoplasm",
      "Malignant Bone Tumour",
      "Ca - Bone Cancer",
      "Osseous Tumor",
      "Malignant Bone Neoplasm",
    ],
  },
  {
    ui: "D001862",
    heading: "Bone Resorption",
    tree_numbers: ["C05.116.264", "G11.427.213.150"],
  },
  {
    ui: "D001882",
    heading: "Border Disease",
    tree_numbers: ["C01.925.782.350.675.100", "C22.836.160"],
  },
  {
    ui: "D001883",
    heading: "Borderline Personality Disorder",
    tree_numbers: ["F03.675.100"],
  },
  {
    ui: "D001885",
    heading: "Bordetella Infections",
    tree_numbers: ["C01.150.252.400.143"],
    synonyms: ["Whooping Cough", "Bordetella Infection", "Wc - Whooping Cough"],
  },
  {
    ui: "D001890",
    heading: "Borna Disease",
    tree_numbers: ["C01.925.782.580.124", "C22.152"],
    synonyms: ["Enzootic Encephalomyelitis"],
  },
  {
    ui: "D001899",
    heading: "Borrelia Infections",
    tree_numbers: ["C01.150.252.400.794.352"],
  },
  {
    ui: "D001906",
    heading: "Botulism",
    tree_numbers: ["C01.150.252.410.222.151", "C10.668.758.200", "C10.720.150", "C25.723.415.151"],
    synonyms: [
      "Foodborne Botulism",
      "Intoxication With Clostridium Botulinum Toxin",
      "Botulism Poisoning",
      "Infection Due To Clostridium Botulinum",
      "Food Poisoning Due To Clostridium Botulinum",
    ],
  },
  {
    ui: "D001907",
    heading: "Boutonneuse Fever",
    tree_numbers: ["C01.150.252.400.789.725.400.250", "C01.920.930.887.250"],
    synonyms: [
      "African Tick Typhus",
      "Kenya Tick Typhus",
      "African Tick Typhus",
      "South African Tick-Bite Fever",
      "Rickettsia Conorii Spotted Fever",
      "Marseilles Fever",
    ],
  },
  {
    ui: "D001912",
    heading: "Bovine Virus Diarrhea-Mucosal Disease",
    tree_numbers: ["C01.925.782.350.675.106", "C22.196.106"],
  },
  {
    ui: "D001913",
    heading: "Bowen's Disease",
    tree_numbers: ["C04.557.470.200.400.130", "C04.557.470.700.400.130"],
  },
  {
    ui: "D001919",
    heading: "Bradycardia",
    tree_numbers: ["C14.280.067.319", "C23.550.073.300"],
  },
  {
    ui: "D001922",
    heading: "Brain Abscess",
    tree_numbers: ["C01.207.090", "C01.830.025.160", "C10.228.140.116", "C10.228.228.090"],
  },
  {
    ui: "D001924",
    heading: "Brain Concussion",
    tree_numbers: [
      "C10.228.140.199.444.250",
      "C10.900.300.087.235.250",
      "C10.900.300.350.300",
      "C26.915.300.200.194.250",
      "C26.915.300.450.500",
      "C26.974.382.200",
    ],
  },
  {
    ui: "D001925",
    heading: "Brain Damage, Chronic",
    tree_numbers: ["C10.228.140.140"],
  },
  {
    ui: "D001926",
    heading: "Brain Death",
    tree_numbers: ["C10.228.140.151", "C10.597.606.358.800.200.100", "C23.550.260.159"],
  },
  {
    ui: "D001927",
    heading: "Brain Diseases",
    tree_numbers: ["C10.228.140"],
    synonyms: ["Encephalopathy"],
  },
  {
    ui: "D001928",
    heading: "Brain Diseases, Metabolic",
    tree_numbers: ["C10.228.140.163", "C18.452.132"],
  },
  {
    ui: "D001929",
    heading: "Brain Edema",
    tree_numbers: ["C10.228.140.187"],
    synonyms: ["Wet Brain", "Intracranial Swelling"],
  },
  {
    ui: "D001930",
    heading: "Brain Injuries",
    tree_numbers: ["C10.228.140.199", "C10.900.300.087", "C26.915.300.200"],
  },
  {
    ui: "D001932",
    heading: "Brain Neoplasms",
    tree_numbers: ["C04.588.614.250.195", "C10.228.140.211", "C10.551.240.250"],
    synonyms: [
      "Malignant Tumor Of Adult Brain",
      "Brain Neoplasm",
      "Brain Neoplasm, Adult",
      "Malignant Primary Brain Neoplasm",
      "Adult Malignant Brain Neoplasm",
      "Primary Brain Tumor",
      "Adult Brain Tumor",
      "Tumor Of The Brain",
      "Primary Brain Neoplasm",
      "Primary Malignant Neoplasm Of Brain",
      "Bt - Brain Tumour",
      "Malignant Tumor Of Brain",
      "Malignant Brain Tumour",
      "Neoplasm Of Brain",
      "Malignant Primary Brain Tumor",
    ],
  },
  {
    ui: "D001935",
    heading: "Branchioma",
    tree_numbers: ["C04.182.117"],
  },
  {
    ui: "D001941",
    heading: "Breast Diseases",
    tree_numbers: ["C17.800.090"],
  },
  {
    ui: "D001943",
    heading: "Breast Neoplasms",
    tree_numbers: ["C04.588.180", "C17.800.090.500"],
    synonyms: [
      "Mammary Cancer",
      "Breast Tumor",
      "Mammary Tumor",
      "Malignant Tumor Of The Breast",
      "Malignant Neoplasm Of Breast",
      "Primary Breast Cancer",
    ],
  },
  {
    ui: "D001946",
    heading: "Breech Presentation",
    tree_numbers: ["C13.703.420.183", "G08.686.520.150", "G08.686.784.769.326.520.150"],
  },
  {
    ui: "D001948",
    heading: "Brenner Tumor",
    tree_numbers: [
      "C04.557.450.565.590.595.150",
      "C04.557.470.625.150",
      "C13.351.500.056.630.705.265",
      "C13.351.937.418.685.265",
      "C19.391.630.705.265",
    ],
    synonyms: ["Benign Ovarian Brenner Tumor", "Ovarian Brenner Tumour", "Benign Ovarian Brenner Tumour"],
  },
  {
    ui: "D001982",
    heading: "Bronchial Diseases",
    tree_numbers: ["C08.127"],
  },
  {
    ui: "D001983",
    heading: "Bronchial Fistula",
    tree_numbers: ["C08.127.196", "C08.702.196", "C23.300.575.687.225"],
  },
  {
    ui: "D001984",
    heading: "Bronchial Neoplasms",
    tree_numbers: ["C04.588.894.797.520.109", "C08.127.265", "C08.785.520.100"],
    synonyms: ["Neoplasm Of Bronchus", "Bronchus Neoplasm"],
  },
  {
    ui: "D001986",
    heading: "Bronchial Spasm",
    tree_numbers: ["C08.127.321"],
  },
  {
    ui: "D001987",
    heading: "Bronchiectasis",
    tree_numbers: ["C08.127.384"],
    synonyms: ["Polynesian Bronchiectasis"],
  },
  {
    ui: "D001988",
    heading: "Bronchiolitis",
    tree_numbers: ["C01.748.099.135", "C08.127.446.135", "C08.381.495.146.135", "C08.730.099.135"],
  },
  {
    ui: "D001989",
    heading: "Bronchiolitis Obliterans",
    tree_numbers: ["C08.127.446.135.140", "C08.381.495.146.135.140"],
    synonyms: ["Obliterative Bronchiolitis", "Bronchiolitis Exudativa", "Bronchiolitis Fibrosa Obliterans"],
  },
  {
    ui: "D001990",
    heading: "Bronchiolitis, Viral",
    tree_numbers: [
      "C01.748.099.135.321",
      "C01.925.109",
      "C08.127.446.135.321",
      "C08.381.495.146.135.321",
      "C08.730.099.135.321",
    ],
  },
  {
    ui: "D001991",
    heading: "Bronchitis",
    tree_numbers: ["C01.748.099", "C08.127.446", "C08.381.495.146", "C08.730.099"],
    synonyms: [
      "Ci - Chest Infection",
      "Chronic Bronchitis",
      "Recurrent Wheezy Bronchitis",
      "Chest Cold",
      "Acute Bronchitis",
      "Chest Infection",
    ],
  },
  {
    ui: "D001994",
    heading: "Bronchogenic Cyst",
    tree_numbers: ["C04.182.195", "C08.127.480", "C08.695.195", "C16.131.740.195"],
  },
  {
    ui: "D001996",
    heading: "Bronchopneumonia",
    tree_numbers: ["C01.748.610.127", "C08.127.509", "C08.381.677.127", "C08.730.610.127"],
    synonyms: ["Bronchial Pneumonia", "Chest Infection - Bronchopneumonia", "Lobular Pneumonia"],
  },
  {
    ui: "D001997",
    heading: "Bronchopulmonary Dysplasia",
    tree_numbers: ["C08.381.520.750.500", "C16.614.521.125"],
  },
  {
    ui: "D001998",
    heading: "Bronchopulmonary Sequestration",
    tree_numbers: ["C08.695.214", "C16.131.740.214"],
  },
  {
    ui: "D002006",
    heading: "Brucellosis",
    tree_numbers: ["C01.150.252.400.167"],
    synonyms: ["Maltese Fever", "Undulant Fever"],
  },
  {
    ui: "D002007",
    heading: "Brucellosis, Bovine",
    tree_numbers: ["C01.150.252.400.167.322", "C22.021.322", "C22.196.148"],
  },
  {
    ui: "D002012",
    heading: "Bruxism",
    tree_numbers: ["C07.793.099", "F01.145.466.132", "F01.470.315.500"],
    synonyms: ["Teeth Grinding", "Sleep Related Bruxism", "Grinding Teeth", "Bruxism - Teeth Grinding"],
  },
  {
    ui: "D002032",
    heading: "Bulimia",
    tree_numbers: ["C23.888.821.645.500"],
    synonyms: ["Hyperorexia Nervosa"],
  },
  {
    ui: "D002037",
    heading: "Bundle-Branch Block",
    tree_numbers: ["C14.280.067.558.323", "C14.280.123.500.323", "C23.550.073.425.100"],
  },
  {
    ui: "D002044",
    heading: "Bunyaviridae Infections",
    tree_numbers: ["C01.925.782.147"],
  },
  {
    ui: "D002051",
    heading: "Burkitt Lymphoma",
    tree_numbers: [
      "C01.925.256.466.313.165",
      "C01.925.928.313.165",
      "C04.557.386.480.150.165",
      "C15.604.515.569.480.150.165",
      "C20.683.515.761.480.150.165",
    ],
    synonyms: [
      "Burkitt's Lymphoma",
      "Small Non-Cleaved Cell Lymphoma, Burkitt's Type",
      "Malignant Lymphoma, Burkitt's Type",
      "Burkitt Lymphoma/leukaemia",
      "Burkitt's Tumor Or Lymphoma",
      "Burkitt's Tumor",
    ],
  },
  {
    ui: "D002054",
    heading: "Burning Mouth Syndrome",
    tree_numbers: ["C07.465.114"],
    synonyms: ["Stomatopyrosis", "Orodynia"],
  },
  {
    ui: "D002055",
    heading: "Burnout, Professional",
    tree_numbers: ["C24.580.500", "F01.145.126.990.367.500", "F02.830.900.333.500"],
  },
  {
    ui: "D002056",
    heading: "Burns",
    tree_numbers: ["C26.200"],
  },
  {
    ui: "D002057",
    heading: "Burns, Chemical",
    tree_numbers: ["C26.200.156"],
  },
  {
    ui: "D002058",
    heading: "Burns, Electric",
    tree_numbers: ["C26.200.239", "C26.324.323"],
  },
  {
    ui: "D002059",
    heading: "Burns, Inhalation",
    tree_numbers: ["C26.200.322"],
  },
  {
    ui: "D002062",
    heading: "Bursitis",
    tree_numbers: ["C05.550.251"],
    synonyms: ["Adhesive Capsulitis Of Shoulder", "Adhesions-Capsulitis,shoulder"],
  },
  {
    ui: "D002095",
    heading: "Byssinosis",
    tree_numbers: ["C08.381.483.581.275", "C08.381.520.702.275", "C24.800.323"],
    synonyms: ["Flax-Dressers' Disease", "Stripper's Asthma", "Cotton Mill Fever"],
  },
  {
    ui: "D002100",
    heading: "Cachexia",
    tree_numbers: ["C23.888.144.243.963.500.500"],
  },
  {
    ui: "D002102",
    heading: "Cadaver",
    tree_numbers: ["C23.550.260.224"],
  },
  {
    ui: "D002105",
    heading: "Cadmium Poisoning",
    tree_numbers: ["C25.723.522.625"],
  },
  {
    ui: "D002114",
    heading: "Calcinosis",
    tree_numbers: ["C18.452.174.130"],
    synonyms: ["Pathologically Calcified Structure", "Pathologic Calcification"],
  },
  {
    ui: "D002115",
    heading: "Calciphylaxis",
    tree_numbers: ["C18.452.174.130.186"],
  },
  {
    ui: "D002128",
    heading: "Calcium Metabolism Disorders",
    tree_numbers: ["C18.452.174"],
  },
  {
    ui: "D002137",
    heading: "Calculi",
    tree_numbers: ["C23.300.175"],
  },
  {
    ui: "D002145",
    heading: "Callosities",
    tree_numbers: ["C17.800.428.200"],
  },
  {
    ui: "D002169",
    heading: "Campylobacter Infections",
    tree_numbers: ["C01.150.252.400.177"],
    synonyms: ["Campylobacteriosis"],
  },
  {
    ui: "D002177",
    heading: "Candidiasis",
    tree_numbers: ["C01.150.703.160"],
    synonyms: ["Systemic Candidiasis", "Disseminated Candidiasis"],
  },
  {
    ui: "D002178",
    heading: "Candidiasis, Chronic Mucocutaneous",
    tree_numbers: ["C01.150.703.160.088", "C01.150.703.302.100", "C01.800.200.100", "C17.800.838.208.165"],
    synonyms: ["Chronic Mucocutaneous Candidiasis"],
  },
  {
    ui: "D002179",
    heading: "Candidiasis, Cutaneous",
    tree_numbers: ["C01.150.703.160.132", "C01.150.703.302.105", "C01.800.200.105", "C17.800.838.208.170"],
  },
  {
    ui: "D002180",
    heading: "Candidiasis, Oral",
    tree_numbers: ["C01.150.703.160.180", "C07.465.130"],
    synonyms: ["Oral Moniliasis", "Candidiasis Of Mouth", "Thrush", "Thrush, Oral"],
  },
  {
    ui: "D002181",
    heading: "Candidiasis, Vulvovaginal",
    tree_numbers: ["C01.150.703.160.190", "C13.351.500.894.906.820.500", "C13.351.500.944.902.737.500"],
    synonyms: [
      "Monilial Vulvovaginitis",
      "Candidal Vulvovaginitis",
      "Candidal: Cervix",
      "Vulvovaginal Candidiasis",
      "Candidiasis Of Vulva And Vagina",
    ],
  },
  {
    ui: "D002189",
    heading: "Marijuana Abuse",
    tree_numbers: ["C25.775.635", "F03.900.635"],
    synonyms: ["Marijuana Abuse"],
  },
  {
    ui: "D002194",
    heading: "Capgras Syndrome",
    tree_numbers: ["F03.700.300"],
    synonyms: ["Capgras Delusion Theory"],
  },
  {
    ui: "D002205",
    heading: "Caplan Syndrome",
    tree_numbers: [
      "C05.550.114.154.219",
      "C05.799.114.219",
      "C08.381.483.581.300",
      "C08.381.520.702.300",
      "C17.300.775.099.219",
      "C24.800.340",
    ],
    synonyms: ["Rheumatoid Pneumoconiosis", "Caplans Syndrome", "Caplan's Disease", "Caplan Syndrome"],
  },
  {
    ui: "D002239",
    heading: "Carbohydrate Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.202", "C18.452.648.202"],
    synonyms: [
      "Inborn Carbohydrate Metabolism Disorder",
      "Disorder Of Carbohydrate Transport And Metabolism",
      "Inborn Errors Of Carbohydrate Metabolism",
    ],
  },
  {
    ui: "D002249",
    heading: "Carbon Monoxide Poisoning",
    tree_numbers: ["C25.723.455.245"],
  },
  {
    ui: "D002252",
    heading: "Carbon Tetrachloride Poisoning",
    tree_numbers: ["C25.723.177"],
  },
  {
    ui: "D002270",
    heading: "Carbuncle",
    tree_numbers: [
      "C01.150.252.410.868.951.270.200",
      "C01.150.252.819.770.270.200",
      "C01.800.720.770.270.200",
      "C17.800.838.765.770.270.200",
    ],
  },
  {
    ui: "D002275",
    heading: "Carcinoid Heart Disease",
    tree_numbers: [
      "C04.557.465.625.650.200.500.205",
      "C04.557.470.200.025.200.500.205",
      "C04.557.580.625.650.200.500.205",
      "C14.280.104",
    ],
  },
  {
    ui: "D002276",
    heading: "Carcinoid Tumor",
    tree_numbers: ["C04.557.465.625.650.200", "C04.557.470.200.025.200", "C04.557.580.625.650.200"],
  },
  {
    ui: "D002277",
    heading: "Carcinoma",
    tree_numbers: ["C04.557.470.200"],
    synonyms: ["Epithelioma", "Malignant Epithelioma", "Epithelial Cancer"],
  },
  {
    ui: "D002278",
    heading: "Carcinoma in Situ",
    tree_numbers: ["C04.557.470.200.240"],
  },
  {
    ui: "D002279",
    heading: "Carcinoma 256, Walker",
    tree_numbers: ["C04.557.435.290.210", "C04.557.450.795.290.210", "C04.619.045"],
  },
  {
    ui: "D002280",
    heading: "Carcinoma, Basal Cell",
    tree_numbers: ["C04.557.470.200.165", "C04.557.470.565.165"],
  },
  {
    ui: "D002281",
    heading: "Carcinoma, Basosquamous",
    tree_numbers: ["C04.557.470.200.170", "C04.557.470.565.170"],
    synonyms: ["Metatypical Carcinoma", "Basisquamous Cell Carcinoma", "Basosquamous Cell Carcinoma"],
  },
  {
    ui: "D002282",
    heading: "Adenocarcinoma, Bronchiolo-Alveolar",
    tree_numbers: ["C04.557.470.200.025.022.500", "C04.588.894.797.520.055.500"],
    synonyms: [
      "Minimally Invasive Lung Adenocarcinoma",
      "Bronchioloalveolar Carcinoma",
      "Bronchioalveolar Lung Carcinoma",
    ],
  },
  {
    ui: "D002283",
    heading: "Carcinoma, Bronchogenic",
    tree_numbers: ["C04.588.894.797.520.109.220", "C08.381.540.140", "C08.785.520.100.220"],
    synonyms: ["Bc - Bronchogenic Carcinoma"],
  },
  {
    ui: "D002284",
    heading: "Carcinoma, Brown-Pearce",
    tree_numbers: ["C04.619.124"],
  },
  {
    ui: "D002285",
    heading: "Carcinoma, Intraductal, Noninfiltrating",
    tree_numbers: ["C04.557.470.200.025.275", "C04.557.470.200.240.187.250", "C04.557.470.615.275"],
  },
  {
    ui: "D002286",
    heading: "Carcinoma, Ehrlich Tumor",
    tree_numbers: ["C04.557.470.200.200", "C04.619.169"],
    synonyms: ["Ehrlich's Tumour", "Ehrlich Tumour Carcinoma", "Ehrlich's Tumor"],
  },
  {
    ui: "D002287",
    heading: "Carcinoma, Krebs 2",
    tree_numbers: ["C04.557.470.200.255", "C04.619.214"],
  },
  {
    ui: "D002288",
    heading: "Adenocarcinoma, Mucinous",
    tree_numbers: ["C04.557.470.200.025.075", "C04.557.470.590.075"],
    synonyms: [
      "Mucin-Secreting Adenocarcinoma",
      "Pseudomyxoma Peritonei With Unknown Primary Site",
      "Mucous Carcinoma",
      "Mucin-Producing Adenocarcinoma",
      "Mucin-Producing Adenocarcinoma",
      "Mucin-Secreting Carcinoma",
    ],
  },
  {
    ui: "D002289",
    heading: "Carcinoma, Non-Small-Cell Lung",
    tree_numbers: ["C04.588.894.797.520.109.220.249", "C08.381.540.140.500", "C08.785.520.100.220.500"],
    synonyms: ["Non-Small Cell Lung Carcinoma", "Non-Small Cell Lung Cancer", "Nsclc"],
  },
  {
    ui: "D002291",
    heading: "Carcinoma, Papillary",
    tree_numbers: ["C04.557.470.200.360", "C04.557.470.700.360"],
    synonyms: ["Papillary Carcinoma"],
  },
  {
    ui: "D002292",
    heading: "Carcinoma, Renal Cell",
    tree_numbers: [
      "C04.557.470.200.025.390",
      "C04.588.945.947.535.160",
      "C12.758.820.750.160",
      "C12.777.419.473.160",
      "C13.351.937.820.535.160",
      "C13.351.968.419.473.160",
    ],
    synonyms: ["Hypernephroma", "Rcc", "Adenocarcinoma Of Kidney"],
  },
  {
    ui: "D002293",
    heading: "Adenocarcinoma, Scirrhous",
    tree_numbers: ["C04.557.470.200.025.095"],
    synonyms: ["Adenocarcinoma With Productive Fibrosis", "Scirrhous Adenocarcinoma"],
  },
  {
    ui: "D002294",
    heading: "Carcinoma, Squamous Cell",
    tree_numbers: ["C04.557.470.200.400", "C04.557.470.700.400"],
    synonyms: [
      "Malignant Squamous Cell Tumor",
      "Squamous Carcinoma",
      "Squamous Cell Cancer",
      "Epidermoid Carcinoma",
      "Squamous Cell Epithelioma",
    ],
  },
  {
    ui: "D002295",
    heading: "Carcinoma, Transitional Cell",
    tree_numbers: ["C04.557.470.200.430"],
    synonyms: ["Urothelial Cell Carcinoma", "Transitional Cell Tumor", "Transitional Carcinoma"],
  },
  {
    ui: "D002296",
    heading: "Carcinosarcoma",
    tree_numbers: ["C04.557.435.290", "C04.557.450.795.290"],
    synonyms: [
      "Mesodermal Mixed Tumor",
      "Mesodermal Mixed Tumor",
      "Malignant Mixed Mesodermal (Mullerian) Tumor",
      "Mmmt",
      "Mixed Mesodermal (Mullerian) Tumor",
      "Mullerian Mixed Tumor",
      "Malignant Mixed Mullerian Tumor",
    ],
  },
  {
    ui: "D002303",
    heading: "Cardiac Output, Low",
    tree_numbers: ["C14.280.148", "C23.888.192"],
  },
  {
    ui: "D002305",
    heading: "Cardiac Tamponade",
    tree_numbers: ["C14.280.155"],
    synonyms: ["Pericardial Tamponade", "Rose's Tamponade"],
  },
  {
    ui: "D002310",
    heading: "Cardiomyopathy, Alcoholic",
    tree_numbers: ["C14.280.238.057", "C25.775.100.087.250"],
    synonyms: [
      "Alcoholic Cardiomyopathy",
      "Dilated Cardiomyopathy Secondary To Alcohol",
      "Alcohol-Induced Heart Muscle Disease",
    ],
  },
  {
    ui: "D002311",
    heading: "Cardiomyopathy, Dilated",
    tree_numbers: ["C14.280.195.160", "C14.280.238.070", "C16.320.488.750"],
    synonyms: ["Primary Dilated Cardiomyopathy"],
  },
  {
    ui: "D002312",
    heading: "Cardiomyopathy, Hypertrophic",
    tree_numbers: ["C14.280.238.100", "C14.280.484.048.750.070.160"],
    synonyms: ["Hypertrophic Obstructive Cardiomyopathy", "Familial Hypertrophic Cardiomyopathy"],
  },
  {
    ui: "D002313",
    heading: "Cardiomyopathy, Restrictive",
    tree_numbers: ["C14.280.238.160"],
    synonyms: ["Primary Restrictive Cardiomyopathy", "Cardiomyopathy, Constrictive", "Restrictive Cardiomyopathy"],
  },
  {
    ui: "D002318",
    heading: "Cardiovascular Diseases",
    tree_numbers: ["C14"],
    synonyms: ["Disease Of Subdivision Of Hemolymphoid System"],
  },
  {
    ui: "D002340",
    heading: "Carotid Artery Diseases",
    tree_numbers: ["C10.228.140.300.200", "C14.907.253.123"],
    synonyms: ["Disorder Of Carotid Artery"],
  },
  {
    ui: "D002341",
    heading: "Carotid Artery Thrombosis",
    tree_numbers: ["C10.228.140.300.200.355", "C14.907.253.123.355", "C14.907.253.566.206", "C14.907.355.590.213.206"],
    synonyms: ["Carotid Artery Thrombosis"],
  },
  {
    ui: "D002345",
    heading: "Carotid Body Tumor",
    tree_numbers: ["C04.557.465.625.650.700.705.220", "C04.557.580.625.650.700.705.220"],
  },
  {
    ui: "D002349",
    heading: "Carpal Tunnel Syndrome",
    tree_numbers: ["C10.668.829.500.500.200", "C10.668.829.550.200", "C26.844.150.206"],
    synonyms: [
      "Cts - Carpal Tunnel Syndrome",
      "Carpal Tunnel Median Neuropathy",
      "Carpal Tunnel Syndrome",
      "Median Nerve Entrapment",
    ],
  },
  {
    ui: "D002357",
    heading: "Cartilage Diseases",
    tree_numbers: ["C05.182", "C17.300.182"],
    synonyms: ["Chondropathy", "Cartilage Disorder"],
  },
  {
    ui: "D002371",
    heading: "Cat Diseases",
    tree_numbers: ["C22.180"],
  },
  {
    ui: "D002372",
    heading: "Cat-Scratch Disease",
    tree_numbers: ["C01.150.252.400.126.100.150", "C15.604.315.249"],
    synonyms: [
      "Benign Lymphoreticulosis",
      "Debre-Mollaret Syndrome",
      "Foshay-Mollaret Cat Scratch Fever",
      "Debre's Syndrome",
      "Cat Scratch Fever",
    ],
  },
  {
    ui: "D002375",
    heading: "Catalepsy",
    tree_numbers: ["C10.597.350.200", "C23.888.592.350.200"],
  },
  {
    ui: "D002385",
    heading: "Cataplexy",
    tree_numbers: ["C10.886.425.800.200.750.500", "F03.870.400.800.200.750.500"],
  },
  {
    ui: "D002386",
    heading: "Cataract",
    tree_numbers: ["C11.510.245"],
  },
  {
    ui: "D002388",
    heading: "Catastrophic Illness",
    tree_numbers: ["C23.550.291.250"],
  },
  {
    ui: "D002389",
    heading: "Catatonia",
    tree_numbers: ["C10.597.606.115", "C23.888.592.604.115", "F01.145.126.156", "F01.700.165"],
  },
  {
    ui: "D002418",
    heading: "Cattle Diseases",
    tree_numbers: ["C22.196"],
  },
  {
    ui: "D002422",
    heading: "Causalgia",
    tree_numbers: ["C10.177.195.200", "C10.668.829.250.200", "C10.668.829.600.200"],
    synonyms: ["Complex Regional Pain Syndrome, Type Ii"],
  },
  {
    ui: "D002429",
    heading: "Cecal Diseases",
    tree_numbers: ["C06.405.469.110"],
    synonyms: ["Disorder Of Cecum"],
  },
  {
    ui: "D002430",
    heading: "Cecal Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.184",
      "C06.301.371.411.184",
      "C06.405.249.411.184",
      "C06.405.469.110.417",
      "C06.405.469.491.184",
    ],
    synonyms: ["Neoplasm Of Caecum", "Cecum Neoplasm"],
  },
  {
    ui: "D002446",
    heading: "Celiac Disease",
    tree_numbers: ["C06.405.469.637.250", "C18.452.603.250"],
    synonyms: ["Idiopathic Steatorrhea", "Coeliac Disease", "Celiac Sprue"],
  },
  {
    ui: "D002471",
    heading: "Cell Transformation, Neoplastic",
    tree_numbers: ["C04.697.098.500", "C23.550.727.098.500"],
  },
  {
    ui: "D002472",
    heading: "Cell Transformation, Viral",
    tree_numbers: ["C04.697.098.500.160", "C23.550.727.098.500.160", "G06.920.143"],
  },
  {
    ui: "D002481",
    heading: "Cellulitis",
    tree_numbers: ["C01.800.130", "C01.830.200", "C17.300.185", "C23.550.470.756.200"],
  },
  {
    ui: "D002485",
    heading: "Cementoma",
    tree_numbers: ["C04.557.695.210"],
  },
  {
    ui: "D002493",
    heading: "Central Nervous System Diseases",
    tree_numbers: ["C10.228"],
  },
  {
    ui: "D002494",
    heading: "Central Nervous System Infections",
    tree_numbers: ["C01.207", "C10.228.228"],
  },
  {
    ui: "D002524",
    heading: "Cerebellar Ataxia",
    tree_numbers: ["C10.228.140.252.190", "C10.597.350.090.500", "C23.888.592.350.090.200"],
  },
  {
    ui: "D002526",
    heading: "Cerebellar Diseases",
    tree_numbers: ["C10.228.140.252"],
  },
  {
    ui: "D002527",
    heading: "Myoclonic Cerebellar Dyssynergia",
    tree_numbers: ["C10.228.140.252.700.250", "C10.228.854.787.500", "C10.574.500.825.250", "C16.320.400.780.500"],
    synonyms: ["Dyssynergia Cerebellaris Myoclonica", "Progressive Cerebellar Tremor"],
  },
  {
    ui: "D002528",
    heading: "Cerebellar Neoplasms",
    tree_numbers: [
      "C04.588.614.250.195.411.211",
      "C10.228.140.211.500.200",
      "C10.228.140.252.200",
      "C10.551.240.250.400.300",
    ],
    synonyms: ["Cerebellar Cancer", "Malignant Tumor Of Cerebellum"],
  },
  {
    ui: "D002532",
    heading: "Intracranial Aneurysm",
    tree_numbers: ["C10.228.140.300.510.600", "C14.907.055.635", "C14.907.253.560.300"],
    synonyms: ["Brain Aneurysm"],
  },
  {
    ui: "D002534",
    heading: "Hypoxia, Brain",
    tree_numbers: ["C10.228.140.624", "C23.888.852.079.797"],
  },
  {
    ui: "D002537",
    heading: "Intracranial Arteriosclerosis",
    tree_numbers: ["C10.228.140.300.510.800", "C14.907.137.126.372", "C14.907.253.560.350"],
    synonyms: ["Cerebral Atherosclerosis"],
  },
  {
    ui: "D002538",
    heading: "Intracranial Arteriovenous Malformations",
    tree_numbers: [
      "C10.228.140.300.520",
      "C10.500.190.500",
      "C14.240.850.750.295",
      "C14.240.850.875.500",
      "C14.907.150.295",
      "C14.907.253.560.400",
      "C16.131.240.850.750.295",
      "C16.131.240.850.875.500",
      "C16.131.666.190.500",
    ],
    synonyms: ["Intracranial Arteriovenous Malformation", "Cerebral Arteriovenous Malformation"],
  },
  {
    ui: "D002539",
    heading: "Cerebral Arterial Diseases",
    tree_numbers: ["C10.228.140.300.510.200", "C14.907.253.560.200"],
  },
  {
    ui: "D002542",
    heading: "Intracranial Embolism and Thrombosis",
    tree_numbers: ["C10.228.140.300.525", "C14.907.253.566", "C14.907.355.590.213"],
  },
  {
    ui: "D002543",
    heading: "Cerebral Hemorrhage",
    tree_numbers: ["C10.228.140.300.535.200", "C14.907.253.573.200", "C23.550.414.913.100"],
  },
  {
    ui: "D002544",
    heading: "Cerebral Infarction",
    tree_numbers: [
      "C10.228.140.300.150.477.200",
      "C10.228.140.300.775.200.200",
      "C14.907.253.092.477.200",
      "C14.907.253.855.200.200",
      "C23.550.513.355.250.200",
      "C23.550.717.489.250.200",
    ],
    synonyms: ["Cerebral Infarct", "Cerebral Infarction", "Cva - Cerebral Infarction"],
  },
  {
    ui: "D002545",
    heading: "Brain Ischemia",
    tree_numbers: ["C10.228.140.300.150", "C14.907.253.092"],
    synonyms: ["Ischaemic Encephalopathy", "Cerebral Ischemia", "Ischemic Encephalopathy"],
  },
  {
    ui: "D002546",
    heading: "Ischemic Attack, Transient",
    tree_numbers: ["C10.228.140.300.150.836", "C14.907.253.092.836"],
    synonyms: [
      "Transient Ischemic Attacks",
      "Transient Ischemic Attack",
      "Tia - Transient Ischaemic Attack",
      "Transient Cerebral Ischemia",
      "Transient Ischemic Attack",
      "Transient Cerebral Ischaemia",
      "Tia",
    ],
  },
  {
    ui: "D002547",
    heading: "Cerebral Palsy",
    tree_numbers: ["C10.228.140.140.254"],
    synonyms: ["Infantile Cerebral Palsy"],
  },
  {
    ui: "D002549",
    heading: "Diffuse Cerebral Sclerosis of Schilder",
    tree_numbers: [
      "C10.114.375.112",
      "C10.228.140.400",
      "C10.228.140.695.562.112",
      "C10.314.350.112",
      "C20.111.258.250.175",
    ],
    synonyms: ["Tumefactive Multiple Sclerosis", "Balo's Concentric Sclerosis", "Balo Disease"],
  },
  {
    ui: "D002551",
    heading: "Cerebral Ventricle Neoplasms",
    tree_numbers: ["C04.588.614.250.195.205", "C10.228.140.211.280", "C10.551.240.250.200"],
    synonyms: ["Intraventricular Tumor Of Brain", "Cerebral Ventricle Neoplasm"],
  },
  {
    ui: "D002558",
    heading: "Cerebrospinal Fluid Otorrhea",
    tree_numbers: ["C10.597.114.500", "C10.900.300.109.500", "C23.888.592.114.249", "C26.915.300.225.500"],
  },
  {
    ui: "D002559",
    heading: "Cerebrospinal Fluid Rhinorrhea",
    tree_numbers: [
      "C10.597.114.750",
      "C10.900.300.109.750",
      "C23.888.592.114.624",
      "C23.888.852.834.500",
      "C26.915.300.225.750",
    ],
  },
  {
    ui: "D002561",
    heading: "Cerebrovascular Disorders",
    tree_numbers: ["C10.228.140.300", "C14.907.253"],
    synonyms: ["Stroke", "Cerebrovascular Disorder", "Cerebrovascular Accident", "Cerebrovascular Accident", "Cva"],
  },
  {
    ui: "D002573",
    heading: "Cervical Rib Syndrome",
    tree_numbers: ["C10.668.829.550.850.200", "C14.907.863.200", "C16.131.621.174"],
  },
  {
    ui: "D002575",
    heading: "Uterine Cervicitis",
    tree_numbers: ["C13.351.500.852.593.150"],
  },
  {
    ui: "D002577",
    heading: "Uterine Cervical Diseases",
    tree_numbers: ["C13.351.500.852.593"],
  },
  {
    ui: "D002578",
    heading: "Uterine Cervical Dysplasia",
    tree_numbers: ["C04.834.818", "C13.351.500.852.593.074"],
  },
  {
    ui: "D002579",
    heading: "Uterine Cervical Erosion",
    tree_numbers: ["C13.351.500.852.593.112"],
    synonyms: ["Erosion Of Cervix"],
  },
  {
    ui: "D002581",
    heading: "Uterine Cervical Incompetence",
    tree_numbers: ["C13.351.500.852.593.120", "C13.703.039.089.339"],
  },
  {
    ui: "D002583",
    heading: "Uterine Cervical Neoplasms",
    tree_numbers: [
      "C04.588.945.418.948.850",
      "C13.351.500.852.593.131",
      "C13.351.500.852.762.850",
      "C13.351.937.418.875.850",
    ],
    synonyms: [
      "Cervix Uteri Cancer",
      "Tumor Of The Cervix Uteri",
      "Neoplasm Of Uterine Cervix",
      "Cervical Neoplasm",
      "Uterine Cervical Neoplasm",
      "Cervix Cancer",
    ],
  },
  {
    ui: "D002590",
    heading: "Cestode Infections",
    tree_numbers: ["C01.610.335.190"],
  },
  {
    ui: "D002598",
    heading: "Chagas Cardiomyopathy",
    tree_numbers: ["C01.610.752.300.900.200.190", "C01.920.625.190", "C14.280.238.190"],
  },
  {
    ui: "D002601",
    heading: "Chancre",
    tree_numbers: ["C01.150.252.400.794.840.500.500", "C01.150.252.400.840.500.500"],
    synonyms: ["Syphilitic Chancre"],
  },
  {
    ui: "D002602",
    heading: "Chancroid",
    tree_numbers: [
      "C01.150.252.400.700.433.308",
      "C01.150.252.734.201",
      "C01.221.812.281.201",
      "C01.778.281.201",
      "C12.294.668.281.201",
      "C13.351.500.711.281.201",
    ],
    synonyms: ["Ulcus Molle, Skin"],
  },
  {
    ui: "D002607",
    heading: "Charcot-Marie-Tooth Disease",
    tree_numbers: [
      "C10.500.300.200",
      "C10.574.500.495.200",
      "C10.668.829.800.300.200",
      "C16.131.666.300.200",
      "C16.320.400.375.200",
    ],
    synonyms: ["Cmt - Charcot-Marie-Tooth Disease"],
  },
  {
    ui: "D002609",
    heading: "Chediak-Higashi Syndrome",
    tree_numbers: ["C11.270.040.772", "C15.378.553.774.257", "C16.320.798.375", "C20.673.774.257", "C20.673.795.375"],
    synonyms: ["Chs", "Chediak - Steinbrinck Anomaly"],
  },
  {
    ui: "D002613",
    heading: "Cheilitis",
    tree_numbers: ["C07.465.409.215"],
  },
  {
    ui: "D002636",
    heading: "Cherubism",
    tree_numbers: ["C05.116.099.708.375.199", "C05.500.174", "C07.320.173", "C16.131.621.207.540.170", "C16.320.170"],
  },
  {
    ui: "D002637",
    heading: "Chest Pain",
    tree_numbers: ["C23.888.592.612.233"],
  },
  {
    ui: "D002639",
    heading: "Cheyne-Stokes Respiration",
    tree_numbers: ["C08.618.182", "C23.888.852.227"],
  },
  {
    ui: "D002640",
    heading: "Chiari-Frommel Syndrome",
    tree_numbers: ["C13.703.844.506.389.500", "C17.800.090.937.439.500"],
    synonyms: ["Hyperprolactinaemia", "Chiari-Frommel Syndrome", "Pregnancy-Related A-G Syndrome"],
  },
  {
    ui: "D002644",
    heading: "Chickenpox",
    tree_numbers: ["C01.925.256.466.930.250"],
    synonyms: ["Varicella"],
  },
  {
    ui: "D002647",
    heading: "Chilblains",
    tree_numbers: ["C26.212.500.217", "C26.417.217"],
  },
  {
    ui: "D002653",
    heading: "Child Behavior Disorders",
    tree_numbers: ["F03.625.141"],
  },
  {
    ui: "D002658",
    heading: "Developmental Disabilities",
    tree_numbers: ["F03.625.421"],
  },
  {
    ui: "D002659",
    heading: "Child Development Disorders, Pervasive",
    tree_numbers: ["F03.625.164"],
  },
  {
    ui: "D002690",
    heading: "Chlamydia Infections",
    tree_numbers: [
      "C01.150.252.400.210.125",
      "C01.150.252.734.301",
      "C01.221.812.281.301",
      "C01.778.281.301",
      "C12.294.668.281.301",
      "C13.351.500.711.281.301",
    ],
  },
  {
    ui: "D002694",
    heading: "Chlamydiaceae Infections",
    tree_numbers: ["C01.150.252.400.210"],
  },
  {
    ui: "D002754",
    heading: "Choanal Atresia",
    tree_numbers: ["C08.460.171", "C08.695.271", "C09.603.171", "C16.131.740.271"],
    synonyms: ["Posterior Choanal Atresia", "Imperforate Nares", "Atresia Of Nares"],
  },
  {
    ui: "D002759",
    heading: "Adenoma, Bile Duct",
    tree_numbers: ["C04.557.470.035.085"],
    synonyms: ["Cholangioadenoma"],
  },
  {
    ui: "D002761",
    heading: "Cholangitis",
    tree_numbers: ["C06.130.120.200"],
  },
  {
    ui: "D002764",
    heading: "Cholecystitis",
    tree_numbers: ["C06.130.564.263"],
    synonyms: [
      "Acute On Chronic Cholecystitis",
      "Acute And Chronic Cholecystitis",
      "Acute Cholecystitis",
      "Chronic Cholecystitis",
    ],
  },
  {
    ui: "D002769",
    heading: "Cholelithiasis",
    tree_numbers: ["C06.130.409"],
  },
  {
    ui: "D002771",
    heading: "Cholera",
    tree_numbers: ["C01.150.252.400.959.347"],
    synonyms: ["Vibrio Cholerae", "Cholera", "Cholera - Vibrio Cholerae", "Cholera Due To Vibrio Cholerae"],
  },
  {
    ui: "D002779",
    heading: "Cholestasis",
    tree_numbers: ["C06.130.120.135"],
    synonyms: ["Bile Occlusion", "Obstruction Of Bile Duct"],
  },
  {
    ui: "D002780",
    heading: "Cholestasis, Intrahepatic",
    tree_numbers: ["C06.130.120.135.250", "C06.552.150"],
    synonyms: ["Neonatal Intrahepatic Cholestasis"],
  },
  {
    ui: "D002781",
    heading: "Cholesteatoma",
    tree_numbers: ["C17.800.428.260"],
  },
  {
    ui: "D002796",
    heading: "Choline Deficiency",
    tree_numbers: ["C18.654.521.500.133.699.160"],
    synonyms: ["Choline Deficiency", "Choline Deficiency"],
  },
  {
    ui: "D002804",
    heading: "Chondroblastoma",
    tree_numbers: ["C04.557.450.565.250"],
    synonyms: ["Chondroblastoma Of Bone"],
  },
  {
    ui: "D002805",
    heading: "Chondrocalcinosis",
    tree_numbers: ["C05.550.114.264", "C05.550.354.125"],
  },
  {
    ui: "D002806",
    heading: "Chondrodysplasia Punctata",
    tree_numbers: ["C05.116.099.708.195"],
    synonyms: ["Chondrodysplasia Punctata Congenita"],
  },
  {
    ui: "D002812",
    heading: "Chondroma",
    tree_numbers: ["C04.557.450.565.265"],
    synonyms: ["Central Chondroma"],
  },
  {
    ui: "D002813",
    heading: "Chondrosarcoma",
    tree_numbers: ["C04.557.450.565.280", "C04.557.450.795.300"],
    synonyms: ["Cartilaginous Cancer", "Primary Chondrosarcoma Of The Bone", "Chondrosarcoma Of Bone"],
  },
  {
    ui: "D002817",
    heading: "Chordoma",
    tree_numbers: ["C04.557.465.220"],
    synonyms: ["Notochordoma"],
  },
  {
    ui: "D002819",
    heading: "Chorea",
    tree_numbers: ["C10.228.662.262.249", "C10.597.350.250", "C23.888.592.350.250"],
  },
  {
    ui: "D002820",
    heading: "Hydatidiform Mole, Invasive",
    tree_numbers: ["C04.557.465.955.416.812.500", "C04.850.908.416.750.500", "C13.703.720.949.416.875.500"],
  },
  {
    ui: "D002821",
    heading: "Chorioamnionitis",
    tree_numbers: ["C13.703.277.030", "C13.703.420.339.260", "C13.703.590.268", "C16.300.030"],
  },
  {
    ui: "D002822",
    heading: "Choriocarcinoma",
    tree_numbers: ["C04.557.465.955.207", "C04.557.470.200.025.455", "C04.850.908.208", "C13.703.720.949.208"],
    synonyms: ["Chorioepithelioma"],
  },
  {
    ui: "D002825",
    heading: "Chorioretinitis",
    tree_numbers: ["C11.768.773.348", "C11.941.160.478.400", "C11.941.879.780.900.300.318"],
    synonyms: ["Retinochoroiditis"],
  },
  {
    ui: "D002828",
    heading: "Choristoma",
    tree_numbers: ["C23.300.250"],
  },
  {
    ui: "D002830",
    heading: "Choroid Neoplasms",
    tree_numbers: ["C04.588.364.978.223", "C11.319.494.198", "C11.941.160.238", "C11.941.855.198"],
    synonyms: [
      "Choroid Neoplasm",
      "Malignant Tumor Of The Choroid",
      "Choroidal Tumor",
      "Malignant Tumor Of Choroid",
      "Neoplasm Of Choroid",
    ],
  },
  {
    ui: "D002832",
    heading: "Choroid Hemorrhage",
    tree_numbers: ["C11.290.195", "C11.941.160.177", "C23.550.414.756.300"],
  },
  {
    ui: "D002833",
    heading: "Choroiditis",
    tree_numbers: ["C11.941.160.478", "C11.941.879.780.900.300"],
  },
  {
    ui: "D002836",
    heading: "Hemophilia B",
    tree_numbers: [
      "C15.378.100.100.510",
      "C15.378.100.141.510",
      "C15.378.463.510",
      "C16.320.099.510",
      "C16.320.322.235",
    ],
    synonyms: [
      "Deficiency, Functional Factor Ix",
      "Congenital Factor Ix Disorder",
      "Congenital Factor Ix Deficiency",
      "Factor Ix Deficiency",
    ],
  },
  {
    ui: "D002862",
    heading: "Chromoblastomycosis",
    tree_numbers: ["C01.150.703.302.110", "C01.800.200.110", "C17.800.838.208.241"],
    synonyms: ["Chromomycosis", "Mossy Foot Disease"],
  },
  {
    ui: "D002869",
    heading: "Chromosome Aberrations",
    tree_numbers: ["C23.550.210", "G05.365.590.175"],
  },
  {
    ui: "D002872",
    heading: "Chromosome Deletion",
    tree_numbers: [
      "C23.550.210.050.500.500",
      "G05.365.590.029.530.175",
      "G05.365.590.175.050.500.500",
      "G05.365.590.762.180",
      "G05.558.800.180",
      "G05.700.131.500.500",
    ],
  },
  {
    ui: "D002873",
    heading: "Chromosome Fragility",
    tree_numbers: ["C23.550.210.110.180", "C23.550.362.180.180", "G05.365.590.175.165.180", "G05.370.180.180"],
  },
  {
    ui: "D002908",
    heading: "Chronic Disease",
    tree_numbers: ["C23.550.291.500"],
  },
  {
    ui: "D002915",
    heading: "Chylous Ascites",
    tree_numbers: ["C06.844.200"],
  },
  {
    ui: "D002916",
    heading: "Chylothorax",
    tree_numbers: ["C08.528.142"],
  },
  {
    ui: "D002921",
    heading: "Cicatrix",
    tree_numbers: ["A10.165.450.300", "C23.550.355.274", "G16.762.891.249"],
  },
  {
    ui: "D002925",
    heading: "Ciliary Motility Disorders",
    tree_numbers: ["C08.200", "C09.150", "C16.131.077.245.500", "C16.320.184.500"],
    synonyms: ["Ciliary Motility Disorder", "Immotile Ciliary Syndrome"],
  },
  {
    ui: "D002971",
    heading: "Cleft Lip",
    tree_numbers: ["C07.465.409.225", "C07.465.525.164", "C07.650.525.164", "C16.131.850.525.164"],
    synonyms: [
      "Cleft Lip, Unilateral, Complete",
      "Cheiloschisis",
      "Complete Unilateral Cleft Lip",
      "Hare Lip",
      "Labium Leporinum",
    ],
  },
  {
    ui: "D002972",
    heading: "Cleft Palate",
    tree_numbers: [
      "C05.500.460.185",
      "C05.660.207.540.460.185",
      "C07.320.440.185",
      "C07.465.525.185",
      "C07.650.500.460.185",
      "C07.650.525.185",
      "C16.131.621.207.540.460.185",
      "C16.131.850.500.460.185",
      "C16.131.850.525.185",
    ],
    synonyms: ["Palatoschisis"],
  },
  {
    ui: "D002973",
    heading: "Cleidocranial Dysplasia",
    tree_numbers: ["C05.116.099.708.207", "C05.660.207.207", "C16.131.621.207.207"],
    synonyms: ["Marie-Sainton Disease", "Cleidocranial Dysostosis"],
  },
  {
    ui: "D003003",
    heading: "Clonorchiasis",
    tree_numbers: ["C01.610.335.865.148"],
    synonyms: ["Oriental Liver Fluke Disease"],
  },
  {
    ui: "D003015",
    heading: "Clostridium Infections",
    tree_numbers: ["C01.150.252.410.222"],
  },
  {
    ui: "D003025",
    heading: "Clubfoot",
    tree_numbers: [
      "C05.330.488.655.063",
      "C05.330.495.681.063",
      "C05.660.585.512.380.813.063",
      "C16.131.621.585.512.500.681.063",
    ],
    synonyms: [
      "Congenital Talipes Equinovarus",
      "Equinovarus Deformity Of Foot",
      "Congenital Clubfoot",
      "Congenital Equinovarus",
    ],
  },
  {
    ui: "D003027",
    heading: "Cluster Headache",
    tree_numbers: ["C10.228.140.546.399.937.500"],
  },
  {
    ui: "D003043",
    heading: "Cocarcinogenesis",
    tree_numbers: ["C04.697.098.875", "C23.550.727.098.750"],
  },
  {
    ui: "D003047",
    heading: "Coccidioidomycosis",
    tree_numbers: ["C01.150.703.203"],
    synonyms: ["Primary Extrapulmonary Coccidioidomycosis"],
  },
  {
    ui: "D003048",
    heading: "Coccidiosis",
    tree_numbers: ["C01.610.752.250"],
    synonyms: ["Coccidiosis"],
  },
  {
    ui: "D003057",
    heading: "Cockayne Syndrome",
    tree_numbers: [
      "C05.116.099.343.250",
      "C10.574.500.362",
      "C16.131.077.250",
      "C16.320.240.562",
      "C16.320.400.200",
      "C18.452.284.250",
    ],
    synonyms: ["Neill-Dingwall Syndrome"],
  },
  {
    ui: "D003072",
    heading: "Cognition Disorders",
    tree_numbers: ["F03.615.250"],
  },
  {
    ui: "D003074",
    heading: "Solitary Pulmonary Nodule",
    tree_numbers: ["C08.381.884"],
    synonyms: ["Coin Lesion Lung", "Coin Lesion Of Lung"],
  },
  {
    ui: "D003085",
    heading: "Colic",
    tree_numbers: ["C16.614.166"],
  },
  {
    ui: "D003092",
    heading: "Colitis",
    tree_numbers: ["C06.405.205.265", "C06.405.469.158.188"],
  },
  {
    ui: "D003093",
    heading: "Colitis, Ulcerative",
    tree_numbers: ["C06.405.205.265.231", "C06.405.205.731.249", "C06.405.469.158.188.231", "C06.405.469.432.249"],
    synonyms: ["Left-Sided Ulcerative Colitis"],
  },
  {
    ui: "D003095",
    heading: "Collagen Diseases",
    tree_numbers: ["C17.300.200"],
    synonyms: ["Collagen Disorder"],
  },
  {
    ui: "D003100",
    heading: "Colles' Fracture",
    tree_numbers: ["C05.550.518.336.500", "C26.289.336.500", "C26.404.562.356"],
  },
  {
    ui: "D003103",
    heading: "Coloboma",
    tree_numbers: ["C11.250.110", "C11.270.147", "C16.131.384.282"],
    synonyms: ["Coloboma Of Eye", "Coloboma Of Macula", "Congenital Ocular Coloboma"],
  },
  {
    ui: "D003108",
    heading: "Colonic Diseases",
    tree_numbers: ["C06.405.469.158"],
    synonyms: ["Colon Disorder"],
  },
  {
    ui: "D003109",
    heading: "Colonic Diseases, Functional",
    tree_numbers: ["C06.405.469.158.272"],
  },
  {
    ui: "D003110",
    heading: "Colonic Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.307.180",
      "C06.301.371.411.307.180",
      "C06.405.249.411.307.180",
      "C06.405.469.158.356.180",
      "C06.405.469.491.307.180",
    ],
    synonyms: ["Colon Neoplasm", "Colonic Tumor", "Neoplasm Of Colon", "Colonic Mass"],
  },
  {
    ui: "D003111",
    heading: "Colonic Polyps",
    tree_numbers: ["C23.300.825.411.235"],
  },
  {
    ui: "D003112",
    heading: "Colonic Pseudo-Obstruction",
    tree_numbers: ["C06.405.469.158.272.217", "C06.405.469.531.492.500.217"],
    synonyms: ["Primary Chronic Pseudo-Obstruction Of Colon"],
  },
  {
    ui: "D003117",
    heading: "Color Vision Defects",
    tree_numbers: ["C10.597.751.941.256", "C11.270.151.500", "C11.966.256", "C23.888.592.763.941.256"],
    synonyms: ["Reduced Red-Green Discrimination", "Deutan Defect", "Deuteranopia"],
  },
  {
    ui: "D003121",
    heading: "Colorado Tick Fever",
    tree_numbers: ["C01.920.930.200", "C01.925.081.234", "C01.925.782.791.482"],
    synonyms: ["Tick Fever, American Mountain"],
  },
  {
    ui: "D003123",
    heading: "Colorectal Neoplasms, Hereditary Nonpolyposis",
    tree_numbers: [
      "C04.588.274.476.411.307.190",
      "C04.700.250",
      "C06.301.371.411.307.190",
      "C06.405.249.411.307.190",
      "C06.405.469.158.356.190",
      "C06.405.469.491.307.190",
      "C16.320.700.250",
      "C18.452.284.255",
    ],
    synonyms: [
      "Hereditary Nonpolyposis Colorectal Neoplasm",
      "Coca 1",
      "Hereditary Nonpolyposis Colorectal Cancer",
      "Hereditary Defective Mismatch Repair Syndrome",
      "Hnpcc - Hereditary Nonpolyposis Colon Cancer",
    ],
  },
  {
    ui: "D003128",
    heading: "Coma",
    tree_numbers: ["C10.597.606.358.800.200", "C23.888.592.604.359.800.200"],
  },
  {
    ui: "D003130",
    heading: "Combat Disorders",
    tree_numbers: ["F03.950.750.249"],
    synonyms: ["Combat Neurosis"],
  },
  {
    ui: "D003137",
    heading: "Common Bile Duct Diseases",
    tree_numbers: ["C06.130.120.250"],
  },
  {
    ui: "D003138",
    heading: "Common Bile Duct Neoplasms",
    tree_numbers: [
      "C04.588.274.120.250.250",
      "C06.130.120.120.280",
      "C06.130.120.250.280",
      "C06.130.320.120.280",
      "C06.301.120.250.250",
    ],
    synonyms: ["Neoplasm Of Common Bile Duct"],
  },
  {
    ui: "D003139",
    heading: "Common Cold",
    tree_numbers: ["C01.748.162", "C01.925.782.687.207", "C08.730.162"],
    synonyms: [
      "Rhino-Sinusitis",
      "Acute Coryza",
      "Nasopharyngitis, Acute",
      "Nasopharyngitis - Acute",
      "Acute Rhinitis",
      "Acute Viral Rhinopharyngitis",
      "Acute Nasopharyngitis",
      "Acute Nasopharyngitis",
    ],
  },
  {
    ui: "D003141",
    heading: "Communicable Diseases",
    tree_numbers: ["C01.221", "C23.550.291.531"],
  },
  {
    ui: "D003147",
    heading: "Communication Disorders",
    tree_numbers: ["C10.597.606.150", "C23.888.592.604.150", "F03.625.374"],
  },
  {
    ui: "D003161",
    heading: "Compartment Syndromes",
    tree_numbers: ["C05.651.180", "C14.907.303"],
    synonyms: ["Compartmental Syndrome", "Compartment Syndrome"],
  },
  {
    ui: "D003193",
    heading: "Compulsive Personality Disorder",
    tree_numbers: ["F03.675.150"],
    synonyms: ["Ocpd"],
  },
  {
    ui: "D003218",
    heading: "Condylomata Acuminata",
    tree_numbers: [
      "C01.221.812.640.220",
      "C01.778.640.220",
      "C01.925.256.650.810.217",
      "C01.925.813.220",
      "C01.925.825.810.110",
      "C01.925.928.914.217",
      "C17.800.838.790.810.110",
    ],
    synonyms: [
      "Anogenital Warts",
      "Genital Wart Virus Infectious Disease",
      "Anogenital Human Papilloma Virus Infectious Disease",
      "Genital Warts",
      "Venereal Wart",
    ],
  },
  {
    ui: "D003221",
    heading: "Confusion",
    tree_numbers: ["C10.597.606.337", "C23.888.592.604.339", "F01.700.250"],
  },
  {
    ui: "D003229",
    heading: "Conjunctival Diseases",
    tree_numbers: ["C11.187"],
  },
  {
    ui: "D003230",
    heading: "Conjunctival Neoplasms",
    tree_numbers: ["C04.588.364.235", "C11.187.169", "C11.319.217"],
    synonyms: [
      "Malignant Tumor Of Conjunctiva",
      "Neoplasm Of Conjunctiva",
      "Malignant Conjunctival Tumor",
      "Malignant Neoplasm Of Conjunctiva",
      "Conjunctival Tumor",
    ],
  },
  {
    ui: "D003231",
    heading: "Conjunctivitis",
    tree_numbers: ["C11.187.183"],
    synonyms: [],
  },
  {
    ui: "D003232",
    heading: "Conjunctivitis, Acute Hemorrhagic",
    tree_numbers: [
      "C01.375.725.250.250",
      "C01.925.325.250.250",
      "C01.925.782.687.359.201",
      "C11.187.183.240.216",
      "C11.294.800.250.250",
    ],
    synonyms: ["Apollo Disease", "Epidemic Hemorrhagic Conjunctivitis"],
  },
  {
    ui: "D003233",
    heading: "Conjunctivitis, Allergic",
    tree_numbers: ["C11.187.183.200", "C20.543.480.200"],
  },
  {
    ui: "D003234",
    heading: "Conjunctivitis, Bacterial",
    tree_numbers: ["C01.150.252.289.225", "C01.375.354.220", "C11.187.183.220", "C11.294.354.220"],
    synonyms: ["Purulent Conjunctivitis"],
  },
  {
    ui: "D003235",
    heading: "Conjunctivitis, Inclusion",
    tree_numbers: [
      "C01.150.252.289.225.250",
      "C01.150.252.400.210.125.245",
      "C01.375.354.220.250",
      "C11.187.183.220.250",
      "C11.294.354.220.250",
    ],
    synonyms: [
      "Inclusion Blenorrhea",
      "Inclusion Blennorrhoea",
      "Adult Inclusion Conjunctivitis",
      "Chlamydial Conjunctivitis",
    ],
  },
  {
    ui: "D003236",
    heading: "Conjunctivitis, Viral",
    tree_numbers: ["C01.375.725.250", "C01.925.325.250", "C11.187.183.240", "C11.294.800.250"],
  },
  {
    ui: "D003240",
    heading: "Connective Tissue Diseases",
    tree_numbers: ["C17.300"],
    synonyms: ["Connective Tissue Disorder", "Disorder Of Connective Tissue"],
  },
  {
    ui: "D003244",
    heading: "Consciousness Disorders",
    tree_numbers: ["C10.597.606.358", "C23.888.592.604.359", "F01.700.315", "F03.615.300"],
  },
  {
    ui: "D003248",
    heading: "Constipation",
    tree_numbers: ["C23.888.821.150"],
  },
  {
    ui: "D003251",
    heading: "Constriction, Pathologic",
    tree_numbers: ["C23.300.287"],
  },
  {
    ui: "D003286",
    heading: "Contracture",
    tree_numbers: ["C05.550.323", "C05.651.197"],
  },
  {
    ui: "D003288",
    heading: "Contusions",
    tree_numbers: ["C26.974.250"],
  },
  {
    ui: "D003289",
    heading: "Convalescence",
    tree_numbers: ["C23.550.291.562"],
  },
  {
    ui: "D003291",
    heading: "Conversion Disorder",
    tree_numbers: ["F03.875.300"],
    synonyms: [
      "Hysterical Neurosis, Conversion Type",
      "Conversion Hysterical Neurosis",
      "Conversion Hysteria Or Reaction",
    ],
  },
  {
    ui: "D003294",
    heading: "Seizures, Febrile",
    tree_numbers: ["C10.597.742.571", "C23.888.592.742.571"],
  },
  {
    ui: "D003310",
    heading: "Cor Triatriatum",
    tree_numbers: ["C14.240.400.200", "C14.280.400.200", "C16.131.240.400.200"],
  },
  {
    ui: "D003316",
    heading: "Corneal Diseases",
    tree_numbers: ["C11.204"],
  },
  {
    ui: "D003317",
    heading: "Corneal Dystrophies, Hereditary",
    tree_numbers: ["C11.204.236", "C11.270.162", "C16.320.290.162"],
    synonyms: ["Fehr Corneal Dystrophy", "Macular Dystrophy, Corneal, 1"],
  },
  {
    ui: "D003318",
    heading: "Corneal Opacity",
    tree_numbers: ["C11.204.299"],
  },
  {
    ui: "D003320",
    heading: "Corneal Ulcer",
    tree_numbers: ["C01.375.177", "C11.204.564.225", "C11.294.177"],
  },
  {
    ui: "D003323",
    heading: "Coronary Aneurysm",
    tree_numbers: ["C14.280.647.250.250", "C14.907.055.395", "C14.907.585.250.250"],
    synonyms: [
      "Aneurysmal Lesion Of Coronary Artery",
      "Arteriovenous Aneurysm Of Coronary Vessels",
      "Aneurysm Of Coronary Vessels",
    ],
  },
  {
    ui: "D003324",
    heading: "Coronary Artery Disease",
    tree_numbers: ["C14.280.647.250.260", "C14.907.137.126.339", "C14.907.585.250.260"],
    synonyms: ["Coronary Disease", "Chd", "Coronary Arteriosclerosis", "Coronary Heart Disease"],
  },
  {
    ui: "D003327",
    heading: "Coronary Disease",
    tree_numbers: ["C14.280.647.250", "C14.907.585.250"],
    synonyms: ["Coronary Disease", "Chd", "Coronary Arteriosclerosis", "Coronary Heart Disease"],
  },
  {
    ui: "D003328",
    heading: "Coronary Thrombosis",
    tree_numbers: ["C14.280.647.250.290", "C14.907.355.830.220", "C14.907.585.250.290"],
    synonyms: ["Coronary Artery Thrombosis"],
  },
  {
    ui: "D003329",
    heading: "Coronary Vasospasm",
    tree_numbers: ["C14.280.647.250.295", "C14.907.585.250.295"],
    synonyms: ["Coronary Artery Spasm", "Coronary Vasospasm"],
  },
  {
    ui: "D003330",
    heading: "Coronary Vessel Anomalies",
    tree_numbers: ["C14.240.400.210", "C14.280.400.210", "C16.131.240.400.210"],
  },
  {
    ui: "D003333",
    heading: "Coronaviridae Infections",
    tree_numbers: ["C01.925.782.600.550"],
  },
  {
    ui: "D003354",
    heading: "Corynebacterium Infections",
    tree_numbers: ["C01.150.252.410.040.246"],
    synonyms: ["Corynebacterium Infection"],
  },
  {
    ui: "D003371",
    heading: "Cough",
    tree_numbers: ["C08.618.248", "C23.888.852.293"],
  },
  {
    ui: "D003384",
    heading: "Coxsackievirus Infections",
    tree_numbers: ["C01.925.782.687.359.213"],
  },
  {
    ui: "D003387",
    heading: "Cracked Tooth Syndrome",
    tree_numbers: ["C07.793.850.750.300", "C26.900.750.300"],
  },
  {
    ui: "D003389",
    heading: "Cranial Nerve Diseases",
    tree_numbers: ["C10.292"],
    synonyms: ["Cranial Nerve Palsy", "Cranial Nerve Paralysis"],
  },
  {
    ui: "D003390",
    heading: "Cranial Nerve Neoplasms",
    tree_numbers: ["C04.588.614.300", "C04.588.614.596.240", "C10.292.225", "C10.551.360", "C10.551.775.250"],
    synonyms: [
      "Malignant Neoplasm Of Cranial Nerve",
      "Malignant Tumor Of The Cranial Nerve",
      "Malignant Tumor Of Cranial Nerve",
      "Malignant Neoplasm Of Cranial Nerves",
    ],
  },
  {
    ui: "D003394",
    heading: "Craniofacial Dysostosis",
    tree_numbers: ["C05.116.099.370.231", "C05.660.207.231", "C16.131.621.207.231"],
    synonyms: ["Craniofacial Dysostosis"],
  },
  {
    ui: "D003397",
    heading: "Craniopharyngioma",
    tree_numbers: ["C04.557.465.625.200", "C04.557.580.625.200"],
    synonyms: [
      "Adamantinous Craniopharyngioma",
      "Craniopharyngioma, Adamantinomatous",
      "Adamantinous Rathke's Pouch Tumor",
    ],
  },
  {
    ui: "D003398",
    heading: "Craniosynostoses",
    tree_numbers: [
      "C05.116.099.370.894.232",
      "C05.660.207.240",
      "C05.660.906.364",
      "C16.131.621.207.240",
      "C16.131.621.906.364",
    ],
    synonyms: ["Premature Closure Of Cranial Sutures"],
  },
  {
    ui: "D003409",
    heading: "Congenital Hypothyroidism",
    tree_numbers: ["C05.116.099.343.347", "C05.116.132.256", "C16.320.240.625", "C19.297.155", "C19.874.482.281"],
  },
  {
    ui: "D003410",
    heading: "Cri-du-Chat Syndrome",
    tree_numbers: ["C10.597.606.360.180", "C16.131.077.262", "C16.131.260.190", "C16.320.180.190"],
    synonyms: [
      "Chromosome 5 Short Arm Deletion Syndrome",
      "Cri-Du-Chat Syndrome",
      "Chromosome 5p Deletion Syndrome",
      "5p Partial Monosomy Syndrome",
      "5p Deletion Syndrome",
    ],
  },
  {
    ui: "D003414",
    heading: "Crigler-Najjar Syndrome",
    tree_numbers: ["C16.320.565.300.281", "C18.452.648.300.281"],
    synonyms: [
      "Bilirubin Udp Glucuronyl Transferase Deficiency",
      "Crigler-Najjar Syndrome, Type I",
      "Crigler Najjar Syndrome",
    ],
  },
  {
    ui: "D003420",
    heading: "Crisscross Heart",
    tree_numbers: ["C14.240.400.220", "C14.280.400.220", "C16.131.240.400.220"],
  },
  {
    ui: "D003424",
    heading: "Crohn Disease",
    tree_numbers: ["C06.405.205.731.500", "C06.405.469.432.500"],
    synonyms: [
      "Crohn's Disease Of Colon",
      "Crohn Disease",
      "Granulomatous Colitis",
      "Pediatric Crohn's Disease",
      "Crohn's Disease Of Large Bowel",
    ],
  },
  {
    ui: "D003428",
    heading: "Cross Infection",
    tree_numbers: ["C01.248", "C23.550.291.875.500"],
  },
  {
    ui: "D003440",
    heading: "Croup",
    tree_numbers: ["C08.360.535.365", "C09.400.535.365"],
    synonyms: [
      "Laryngotracheobronchitis",
      "Acute Laryngotracheobronchitis",
      "Croup Syndrome",
      "Acute Obstructive Laryngitis",
    ],
  },
  {
    ui: "D003444",
    heading: "Crush Syndrome",
    tree_numbers: ["C26.257.500", "C26.797.240"],
  },
  {
    ui: "D003449",
    heading: "Cryoglobulinemia",
    tree_numbers: ["C14.907.454.140", "C15.378.147.780.243", "C15.378.463.515.140", "C20.683.780.250"],
    synonyms: ["Cryoimmunoglobulinaemia", "Cryoglobulinemia"],
  },
  {
    ui: "D003453",
    heading: "Cryptococcosis",
    tree_numbers: ["C01.150.703.248"],
    synonyms: ["Busse-Buschke's Disease", "Torulosis", "European Cryptococcosis", "Cryptococcal Infection", "Torula"],
  },
  {
    ui: "D003456",
    heading: "Cryptorchidism",
    tree_numbers: ["C12.294.829.258", "C12.706.258", "C16.131.939.258", "C19.391.829.258"],
    synonyms: ["Undescended Testicles", "Cryptorchism", "Undescended Testicle", "Undescended Testis"],
  },
  {
    ui: "D003457",
    heading: "Cryptosporidiosis",
    tree_numbers: [
      "C01.610.432.269",
      "C01.610.701.688.235",
      "C01.610.752.250.269",
      "C01.610.752.625.235",
      "C06.405.469.452.269",
      "C22.674.710.235",
    ],
    synonyms: ["Infection By Cryptosporidium", "Cryptosporidial Gastroenteritis", "Intestinal Cryptosporidiosis"],
  },
  {
    ui: "D003480",
    heading: "Cushing Syndrome",
    tree_numbers: ["C19.053.800.367"],
    synonyms: ["Hyperaldosteronism", "Cushing's Syndrome", "Cushing Syndrome"],
  },
  {
    ui: "D003483",
    heading: "Cutis Laxa",
    tree_numbers: ["C16.320.850.180", "C17.300.230", "C17.800.827.180"],
    synonyms: ["Loose Skin", "Cutis Laxa"],
  },
  {
    ui: "D003490",
    heading: "Cyanosis",
    tree_numbers: ["C23.888.248"],
  },
  {
    ui: "D003527",
    heading: "Cyclothymic Disorder",
    tree_numbers: ["F03.600.500"],
    synonyms: ["Affective Personality Disorder", "Cycloid Personality", "Cyclothymia", "Cyclothymic Personality"],
  },
  {
    ui: "D003528",
    heading: "Carcinoma, Adenoid Cystic",
    tree_numbers: ["C04.557.470.200.025.220"],
    synonyms: ["Adenoid Cystic Cancer", "Adenoid Cystic Carcinoma", "Cylindroma"],
  },
  {
    ui: "D003536",
    heading: "Cystadenocarcinoma",
    tree_numbers: ["C04.557.470.200.025.480", "C04.557.470.590.480"],
  },
  {
    ui: "D003537",
    heading: "Cystadenoma",
    tree_numbers: ["C04.557.470.035.320", "C04.557.470.590.485"],
    synonyms: ["Cystadenoma", "Cystoma"],
  },
  {
    ui: "D003550",
    heading: "Cystic Fibrosis",
    tree_numbers: ["C06.689.202", "C08.381.187", "C16.320.190", "C16.614.213"],
    synonyms: ["Mucoviscidosis", "Cf"],
  },
  {
    ui: "D003551",
    heading: "Cysticercosis",
    tree_numbers: ["C01.610.335.190.902.185"],
    synonyms: [
      "Tapeworm Infection: Pork",
      "Intestinal Taenia Solium Infection",
      "Tapeworm Infection: Intestinal Taenia Solum",
      "Tenia Solium Infectious Disease",
      "Pork Tapeworm Infection",
      "Neurocysticercosis",
    ],
  },
  {
    ui: "D003554",
    heading: "Cystinosis",
    tree_numbers: ["C16.320.565.595.377", "C18.452.648.595.377"],
    synonyms: ["Cystine Storage Disease"],
  },
  {
    ui: "D003555",
    heading: "Cystinuria",
    tree_numbers: ["C12.777.419.815.885.250", "C13.351.968.419.815.885.250", "C16.320.831.885.250"],
  },
  {
    ui: "D003556",
    heading: "Cystitis",
    tree_numbers: ["C12.777.829.495", "C13.351.968.829.495"],
  },
  {
    ui: "D003557",
    heading: "Phyllodes Tumor",
    tree_numbers: ["C04.557.450.795.650"],
    synonyms: [
      "Cystosarcoma Phyllodes",
      "Benign Phyllodes Neoplasm Of The Breast",
      "Phyllodes Neoplasm",
      "Benign Cystosarcoma Phyllodes",
      "Benign Phyllodes Neoplasm",
    ],
  },
  {
    ui: "D003560",
    heading: "Cysts",
    tree_numbers: ["C04.182", "C23.300.306"],
  },
  {
    ui: "D003586",
    heading: "Cytomegalovirus Infections",
    tree_numbers: ["C01.925.256.466.245"],
  },
  {
    ui: "D003607",
    heading: "Dacryocystitis",
    tree_numbers: ["C11.496.221"],
  },
  {
    ui: "D003616",
    heading: "Dandy-Walker Syndrome",
    tree_numbers: ["C10.228.140.252.300", "C10.228.140.602.500", "C10.500.205", "C16.131.666.205"],
    synonyms: ["Atresia Of Foramina Of Magendie And Luschka"],
  },
  {
    ui: "D003635",
    heading: "De Lange Syndrome",
    tree_numbers: ["C10.597.606.360.210", "C16.131.077.272", "C16.131.260.210", "C16.320.180.210"],
    synonyms: ["De Lange Syndrome", "Brachmann De Lange Syndrome"],
  },
  {
    ui: "D003638",
    heading: "Deafness",
    tree_numbers: ["C09.218.458.341.186", "C10.597.751.418.341.186", "C23.888.592.763.393.341.186"],
  },
  {
    ui: "D003639",
    heading: "Hearing Loss, Sudden",
    tree_numbers: ["C09.218.458.341.900", "C10.597.751.418.341.900", "C23.888.592.763.393.341.900"],
  },
  {
    ui: "D003643",
    heading: "Death",
    tree_numbers: ["C23.550.260"],
  },
  {
    ui: "D003645",
    heading: "Death, Sudden",
    tree_numbers: ["C23.550.260.322"],
  },
  {
    ui: "D003649",
    heading: "Decalcification, Pathologic",
    tree_numbers: ["C05.116.198.247.400", "C18.452.104.247.400", "C18.452.174.289"],
  },
  {
    ui: "D003655",
    heading: "Decerebrate State",
    tree_numbers: ["C10.597.305", "C23.888.592.298"],
  },
  {
    ui: "D003665",
    heading: "Decompression Sickness",
    tree_numbers: ["C26.120.248"],
  },
  {
    ui: "D003668",
    heading: "Pressure Ulcer",
    tree_numbers: ["C17.800.893.665"],
    synonyms: ["Decubitus Ulcer Any Site", "Pressure Ulcer", "Pressure Sores", "Decubitus (Pressure) Ulcer"],
  },
  {
    ui: "D003677",
    heading: "Deficiency Diseases",
    tree_numbers: ["C18.654.521.500"],
  },
  {
    ui: "D003680",
    heading: "Deglutition Disorders",
    tree_numbers: ["C06.405.117.119", "C09.775.174"],
  },
  {
    ui: "D003681",
    heading: "Dehydration",
    tree_numbers: ["C18.452.950.179", "C23.550.274"],
  },
  {
    ui: "D003693",
    heading: "Delirium",
    tree_numbers: ["C10.597.606.337.500", "C23.888.592.604.339.500", "F01.700.250.500", "F03.615.350"],
    synonyms: ["Subacute Delirium"],
  },
  {
    ui: "D003699",
    heading: "Hepatitis D",
    tree_numbers: ["C01.925.440.450", "C01.925.782.450", "C06.552.380.705.450"],
    synonyms: ["Delta Hepatitis"],
  },
  {
    ui: "D003704",
    heading: "Dementia",
    tree_numbers: ["C10.228.140.380", "F03.615.400"],
  },
  {
    ui: "D003711",
    heading: "Demyelinating Diseases",
    tree_numbers: ["C10.314"],
    synonyms: ["Demyelinating Disorder"],
  },
  {
    ui: "D003715",
    heading: "Dengue",
    tree_numbers: ["C01.920.500.270", "C01.925.081.270", "C01.925.782.350.250.214", "C01.925.782.417.214"],
    synonyms: ["Classic Dengue", "Breakbone Fever", "Dengue Fever"],
  },
  {
    ui: "D003719",
    heading: "Dens in Dente",
    tree_numbers: ["C07.650.800.250", "C07.793.700.250", "C16.131.850.800.250"],
  },
  {
    ui: "D003728",
    heading: "Dental Calculus",
    tree_numbers: ["C07.793.208.250", "C23.300.175.350"],
  },
  {
    ui: "D003731",
    heading: "Dental Caries",
    tree_numbers: ["C07.793.720.210"],
    synonyms: ["Dental Caries Pit And Fissure", "Dental Caries Extending Into Pulp", "Dental Caries Of Smooth Surface"],
  },
  {
    ui: "D003741",
    heading: "Dental Deposits",
    tree_numbers: ["C07.793.208"],
  },
  {
    ui: "D003744",
    heading: "Dental Enamel Hypoplasia",
    tree_numbers: ["C07.650.800.255", "C07.793.700.255", "C16.131.850.800.255"],
    synonyms: ["Enamel Hypoplasia", "Enamel Hypoplasia"],
  },
  {
    ui: "D003750",
    heading: "Dental Fissures",
    tree_numbers: ["C07.793.720.210.220"],
  },
  {
    ui: "D003751",
    heading: "Dental Fistula",
    tree_numbers: ["C07.465.614.187", "C23.300.575.500.275"],
  },
  {
    ui: "D003763",
    heading: "Dental Leakage",
    tree_numbers: ["C07.793.221"],
  },
  {
    ui: "D003769",
    heading: "Dental Occlusion, Traumatic",
    tree_numbers: ["C07.793.494.293"],
  },
  {
    ui: "D003773",
    heading: "Dental Plaque",
    tree_numbers: ["C07.793.208.377"],
  },
  {
    ui: "D003784",
    heading: "Dental Pulp Calcification",
    tree_numbers: ["C07.793.237.252"],
    synonyms: ["Pulp Calcifications", "Pulpal Calcifications", "Pulp Calcification"],
  },
  {
    ui: "D003788",
    heading: "Dental Pulp Diseases",
    tree_numbers: ["C07.793.237"],
    synonyms: ["Dental Pulp Disorder", "Disorder Of Pulp Of Tooth", "Pulp Disorder"],
  },
  {
    ui: "D003789",
    heading: "Dental Pulp Exposure",
    tree_numbers: ["C07.793.237.283"],
  },
  {
    ui: "D003790",
    heading: "Dental Pulp Necrosis",
    tree_numbers: ["C07.793.237.315", "C23.550.717.182"],
    synonyms: ["Necrotic Pulp", "Pulp Necrosis", "Necrosis Of The Pulp"],
  },
  {
    ui: "D003803",
    heading: "Dentigerous Cyst",
    tree_numbers: ["C04.182.089.530.690.310", "C05.500.470.690.310", "C07.320.450.670.275"],
  },
  {
    ui: "D003805",
    heading: "Dentin Dysplasia",
    tree_numbers: ["C07.650.800.260", "C07.793.700.260", "C16.131.850.800.260"],
    synonyms: ["Dentinal Dysplasia"],
  },
  {
    ui: "D003807",
    heading: "Dentin Sensitivity",
    tree_numbers: ["C07.793.266"],
    synonyms: ["Sensitive Dentin"],
  },
  {
    ui: "D003809",
    heading: "Dentin, Secondary",
    tree_numbers: ["A14.549.167.900.280.280", "C07.793.237.378"],
  },
  {
    ui: "D003811",
    heading: "Dentinogenesis Imperfecta",
    tree_numbers: ["C07.650.800.270", "C07.793.700.270", "C16.131.850.800.270"],
  },
  {
    ui: "D003859",
    heading: "Dependent Personality Disorder",
    tree_numbers: ["F03.675.200"],
  },
  {
    ui: "D003865",
    heading: "Depressive Disorder, Major",
    tree_numbers: ["F03.600.300.375"],
  },
  {
    ui: "D003866",
    heading: "Depressive Disorder",
    tree_numbers: ["F03.600.300"],
    synonyms: ["Unipolar Depression", "Clinical Depression"],
  },
  {
    ui: "D003872",
    heading: "Dermatitis",
    tree_numbers: ["C17.800.174"],
    synonyms: ["Eczema", "Skin Inflammation"],
  },
  {
    ui: "D003873",
    heading: "Dermatitis, Exfoliative",
    tree_numbers: ["C17.800.174.318", "C17.800.815.318"],
  },
  {
    ui: "D003874",
    heading: "Dermatitis Herpetiformis",
    tree_numbers: ["C17.800.174.360", "C17.800.865.360", "C20.111.318"],
    synonyms: ["Dermatosis Herpetiformis", "Dermatitis Herpetiformis", "Duhring's Disease"],
  },
  {
    ui: "D003875",
    heading: "Drug Eruptions",
    tree_numbers: ["C17.800.174.600", "C20.543.206.380", "C25.100.468.380"],
  },
  {
    ui: "D003876",
    heading: "Dermatitis, Atopic",
    tree_numbers: ["C16.320.850.210", "C17.800.174.193", "C17.800.815.193", "C17.800.827.210", "C20.543.480.343"],
    synonyms: [
      "Atopic Neurodermatitis",
      "Allergic Dermatitis",
      "Atopic Eczema",
      "Atopic Dermatitis",
      "Besnier's Prurigo",
    ],
  },
  {
    ui: "D003877",
    heading: "Dermatitis, Contact",
    tree_numbers: ["C17.800.174.255", "C17.800.815.255"],
    synonyms: [
      "Contact Dermatitis/eczema",
      "Contact Dermatitis",
      "Dermatitis, Venenata",
      "Dermatitis Venenata",
      "Contact Eczema",
    ],
  },
  {
    ui: "D003881",
    heading: "Dermatomycoses",
    tree_numbers: ["C01.150.703.302", "C01.800.200", "C17.800.838.208"],
  },
  {
    ui: "D003882",
    heading: "Dermatomyositis",
    tree_numbers: ["C05.651.594.819.500", "C10.668.491.562.575.500", "C17.300.250", "C17.800.185"],
    synonyms: ["Polymyositis With Skin Involvement", "Dermatopolymyositis"],
  },
  {
    ui: "D003884",
    heading: "Dermoid Cyst",
    tree_numbers: ["C04.182.201", "C04.557.465.910.250"],
    synonyms: [
      "Teratoma, Benign",
      "Dermoid Choristoma",
      "Mature Cystic Teratoma",
      "Dermoid Tumour",
      "Cystic Dermoid Choristoma",
    ],
  },
  {
    ui: "D003914",
    heading: "Dextrocardia",
    tree_numbers: ["C14.240.400.280", "C14.280.400.280", "C16.131.240.400.280", "C16.131.810.250"],
    synonyms: ["Heart Predominantly In Right Hemithorax"],
  },
  {
    ui: "D003919",
    heading: "Diabetes Insipidus",
    tree_numbers: ["C12.777.419.135", "C13.351.968.419.135", "C19.700.159"],
  },
  {
    ui: "D003920",
    heading: "Diabetes Mellitus",
    tree_numbers: ["C18.452.394.750", "C19.246"],
  },
  {
    ui: "D003921",
    heading: "Diabetes Mellitus, Experimental",
    tree_numbers: ["C18.452.394.750.074", "C19.246.240", "E05.598.500.374"],
  },
  {
    ui: "D003922",
    heading: "Diabetes Mellitus, Type 1",
    tree_numbers: ["C18.452.394.750.124", "C19.246.267", "C20.111.327"],
    synonyms: ["Insulin-Dependent Diabetes Mellitus", "Type I Diabetes Mellitus", "Iddm"],
  },
  {
    ui: "D003923",
    heading: "Diabetes Mellitus, Lipoatrophic",
    tree_numbers: ["C18.452.394.750.149.500", "C19.246.300.500"],
    synonyms: ["Lipoatrophic Diabetes"],
  },
  {
    ui: "D003924",
    heading: "Diabetes Mellitus, Type 2",
    tree_numbers: ["C18.452.394.750.149", "C19.246.300"],
    synonyms: [
      "Type Ii Diabetes Mellitus",
      "Type 2 Diabetes",
      "Insulin Resistance",
      "Niddm",
      "Non-Insulin-Dependent Diabetes Mellitus",
    ],
  },
  {
    ui: "D003925",
    heading: "Diabetic Angiopathies",
    tree_numbers: ["C14.907.320", "C19.246.099.500"],
    synonyms: ["Diabetic Peripheral Angiopathy", "Diabetic Vascular Disorder"],
  },
  {
    ui: "D003926",
    heading: "Diabetic Coma",
    tree_numbers: ["C19.246.099.750"],
  },
  {
    ui: "D003928",
    heading: "Diabetic Nephropathies",
    tree_numbers: ["C12.777.419.192", "C13.351.968.419.192", "C19.246.099.875"],
  },
  {
    ui: "D003929",
    heading: "Diabetic Neuropathies",
    tree_numbers: ["C10.668.829.300", "C19.246.099.937"],
    synonyms: ["Diabetes Mellitus With Polyneuropathy", "Polyneuropathy In Diabetes", "Diabetic Polyneuropathy"],
  },
  {
    ui: "D003930",
    heading: "Diabetic Retinopathy",
    tree_numbers: ["C11.768.257", "C14.907.320.382", "C19.246.099.500.382"],
    synonyms: ["Retinal Abnormality - Diabetes-Related"],
  },
  {
    ui: "D003963",
    heading: "Diaper Rash",
    tree_numbers: ["C17.800.174.255.400.250", "C17.800.815.255.400.250"],
    synonyms: [
      "Irritant Contact Dermatitis Due To Contact With Urine And/or Faeces",
      "Diaper Or Napkin Rash",
      "Diaper Rash (Disorder)",
      "Jacquet's Dermatitis",
      "Diaper Or Napkin Erythema",
    ],
  },
  {
    ui: "D003965",
    heading: "Diaphragmatic Eventration",
    tree_numbers: ["C06.198.257", "C16.131.314.244"],
  },
  {
    ui: "D003966",
    heading: "Camurati-Engelmann Syndrome",
    tree_numbers: ["C05.116.099.708.180", "C16.320.144"],
    synonyms: ["Progressive Diaphyseal Dysplasia", "Diaphyseal Dysplasia", "Engelman's Disease"],
  },
  {
    ui: "D003967",
    heading: "Diarrhea",
    tree_numbers: ["C23.888.821.214"],
  },
  {
    ui: "D003968",
    heading: "Diarrhea, Infantile",
    tree_numbers: ["C23.888.821.214.500"],
  },
  {
    ui: "D003969",
    heading: "Vipoma",
    tree_numbers: [
      "C04.557.465.625.650.240.847",
      "C04.557.470.200.025.370.847",
      "C04.588.274.761.500.750",
      "C04.588.322.475.500.750",
      "C06.301.761.500.750",
      "C06.689.667.500.750",
      "C19.344.421.500.750",
    ],
    synonyms: [
      "Vipoma, Malignant",
      "Vip- Secreting Tumor",
      "Vasoactive Intestinal Peptide-Secreting Tumor",
      "Malignant Vasoactive Intestinal Peptide-Secreting Tumor",
    ],
  },
  {
    ui: "D003970",
    heading: "Diastema",
    tree_numbers: ["C07.650.800.320", "C07.793.700.320", "C16.131.850.800.320"],
  },
  {
    ui: "D004011",
    heading: "Dicrocoeliasis",
    tree_numbers: ["C01.610.335.865.224"],
    synonyms: ["Disease Due To Dicrocoeliidae"],
  },
  {
    ui: "D004022",
    heading: "Dictyocaulus Infections",
    tree_numbers: ["C01.610.335.349.315", "C01.610.335.508.700.775.825.315", "C01.610.701.377.315", "C22.674.377.315"],
  },
  {
    ui: "D004030",
    heading: "Dientamoebiasis",
    tree_numbers: ["C01.610.432.275", "C01.610.752.275", "C06.405.469.452.275"],
    synonyms: ["Intestinal Trichomoniasis"],
  },
  {
    ui: "D004057",
    heading: "Hyperostosis, Diffuse Idiopathic Skeletal",
    tree_numbers: ["C05.116.540.410", "C05.116.900.815.651"],
    synonyms: ["Ankylosing Vertebral Hyperostosis", "Dish", "Disseminated Idiopathic Skeletal Hyperostosis"],
  },
  {
    ui: "D004062",
    heading: "DiGeorge Syndrome",
    tree_numbers: [
      "C05.660.207.103.500",
      "C14.240.400.021.500",
      "C14.280.400.044.500",
      "C15.604.451.249.500",
      "C16.131.077.019.500",
      "C16.131.240.400.021.500",
      "C16.131.260.019.500",
      "C16.131.482.249.500",
      "C16.131.621.207.103.500",
      "C16.320.180.019.500",
      "C19.642.482.500.500",
    ],
    synonyms: ["Shprintzen Syndrome", "Vcf-Velocardiofacial Syndrome"],
  },
  {
    ui: "D004065",
    heading: "Digestive System Abnormalities",
    tree_numbers: ["C06.198", "C16.131.314"],
  },
  {
    ui: "D004066",
    heading: "Digestive System Diseases",
    tree_numbers: ["C06"],
    synonyms: [
      "Gastrointestinal Disease",
      "Alimentary System Disease",
      "Git Disease",
      "Gastrointestinal Disorder",
      "Digestive System Disorder",
      "Gastroenteropathy",
    ],
  },
  {
    ui: "D004067",
    heading: "Digestive System Neoplasms",
    tree_numbers: ["C04.588.274", "C06.301"],
    synonyms: ["Gi Tumor", "Gastrointestinal Tract Cancer", "Digestive System Cancer"],
  },
  {
    ui: "D004108",
    heading: "Dilatation, Pathologic",
    tree_numbers: ["C23.300.325"],
  },
  {
    ui: "D004154",
    heading: "Dipetalonema Infections",
    tree_numbers: ["C01.610.335.508.700.750.361.275"],
    synonyms: ["Infection By Dipetalonema", "Dipetalonema Infectious Disease", "Infection By Dipetalonema Perstans"],
  },
  {
    ui: "D004165",
    heading: "Diphtheria",
    tree_numbers: ["C01.150.252.410.040.246.388"],
  },
  {
    ui: "D004169",
    heading: "Diphyllobothriasis",
    tree_numbers: ["C01.610.335.190.304"],
    synonyms: ["Fish Tapeworm", "Diphyllobothrium Infection"],
  },
  {
    ui: "D004172",
    heading: "Diplopia",
    tree_numbers: ["C10.597.751.941.339", "C11.966.339", "C23.888.592.763.941.339"],
  },
  {
    ui: "D004184",
    heading: "Dirofilariasis",
    tree_numbers: ["C01.610.335.349.320", "C01.610.335.508.700.750.361.290", "C01.610.701.377.320", "C22.674.377.320"],
    synonyms: ["Dirofilaria Infectious Disease", "Infection By Dirofilaria"],
  },
  {
    ui: "D004194",
    heading: "Disease",
    tree_numbers: ["C23.550.288"],
  },
  {
    ui: "D004195",
    heading: "Disease Models, Animal",
    tree_numbers: ["C22.232", "E05.598.500", "E05.599.395.080"],
  },
  {
    ui: "D004198",
    heading: "Disease Susceptibility",
    tree_numbers: ["C23.550.291.687", "G07.100.250"],
  },
  {
    ui: "D004200",
    heading: "Diseases in Twins",
    tree_numbers: ["C23.550.291.750"],
  },
  {
    ui: "D004204",
    heading: "Joint Dislocations",
    tree_numbers: ["C05.550.518", "C26.289"],
  },
  {
    ui: "D004211",
    heading: "Disseminated Intravascular Coagulation",
    tree_numbers: ["C15.378.100.220", "C15.378.463.250", "C15.378.925.220"],
    synonyms: ["Dic", "Defibrination Syndrome", "Diffuse Or Disseminated Intravascular Coagulation"],
  },
  {
    ui: "D004213",
    heading: "Dissociative Disorders",
    tree_numbers: ["F03.300"],
    synonyms: ["Dissociative Reaction", "Dissociative Disease"],
  },
  {
    ui: "D004216",
    heading: "Distemper",
    tree_numbers: ["C01.925.782.580.600.500.285", "C22.268.265"],
  },
  {
    ui: "D004238",
    heading: "Diverticulitis",
    tree_numbers: ["C06.405.205.282.500"],
  },
  {
    ui: "D004239",
    heading: "Diverticulitis, Colonic",
    tree_numbers: ["C06.405.205.282.500.250", "C06.405.469.158.587.500"],
    synonyms: ["Colonic Diverticular Disease"],
  },
  {
    ui: "D004240",
    heading: "Diverticulum",
    tree_numbers: ["C06.405.205.282.750", "C23.300.415"],
  },
  {
    ui: "D004241",
    heading: "Diverticulum, Colon",
    tree_numbers: ["C06.405.205.282.750.500", "C23.300.415.500"],
  },
  {
    ui: "D004244",
    heading: "Dizziness",
    tree_numbers: ["C23.888.592.763.237"],
  },
  {
    ui: "D004266",
    heading: "DNA Virus Infections",
    tree_numbers: ["C01.925.256"],
  },
  {
    ui: "D004283",
    heading: "Dog Diseases",
    tree_numbers: ["C22.268"],
  },
  {
    ui: "D004310",
    heading: "Double Outlet Right Ventricle",
    tree_numbers: [
      "C14.240.400.560.540.500",
      "C14.240.400.915.300",
      "C14.280.400.560.540.500",
      "C14.280.400.915.300",
      "C16.131.240.400.560.540.500",
      "C16.131.240.400.915.300",
    ],
    synonyms: [
      "Dextrotransposition Of Aorta",
      "Double Outlet Right Ventricle",
      "Double Outlet Right Ventricle With Subpulmonary Ventricular Septal Defect",
      "Taussig-Bing Syndrome Or Defect",
    ],
  },
  {
    ui: "D004313",
    heading: "Dourine",
    tree_numbers: ["C01.610.701.688.367", "C01.610.752.300.900.226", "C01.610.752.625.367", "C22.674.710.367"],
  },
  {
    ui: "D004314",
    heading: "Down Syndrome",
    tree_numbers: ["C10.597.606.360.220", "C16.131.077.327", "C16.131.260.260", "C16.320.180.260"],
    synonyms: [
      "Trisomy 21 Syndrome",
      "Down's Syndrome - Trisomy 21",
      "Downs Syndrome",
      "Down's Syndrome",
      "G Trisomy",
      "Complete Trisomy 21 Syndrome",
    ],
  },
  {
    ui: "D004320",
    heading: "Dracunculiasis",
    tree_numbers: ["C01.610.335.508.700.750.299"],
    synonyms: ["Infection By Dracunculus Medinensis", "Dracontiasis"],
  },
  {
    ui: "D004332",
    heading: "Drowning",
    tree_numbers: ["C23.550.260.393", "C26.304", "N06.850.135.696"],
  },
  {
    ui: "D004342",
    heading: "Drug Hypersensitivity",
    tree_numbers: ["C20.543.206", "C25.100.468"],
  },
  {
    ui: "D004368",
    heading: "Dry Socket",
    tree_numbers: ["C07.465.227"],
    synonyms: ["Alveolar Periostitis", "Dry Tooth Socket", "Dry Socket", "Alveolitis Of Jaw"],
  },
  {
    ui: "D004370",
    heading: "Duane Retraction Syndrome",
    tree_numbers: ["C10.292.562.250", "C11.270.235", "C11.590.224", "C16.320.290.235"],
    synonyms: ["Stilling-Turk-Duane Syndrome", "Duane's Syndrome"],
  },
  {
    ui: "D004374",
    heading: "Ductus Arteriosus, Patent",
    tree_numbers: ["C14.240.400.340", "C14.280.400.340", "C16.131.240.400.340"],
    synonyms: ["Patent Ductus Botalli"],
  },
  {
    ui: "D004377",
    heading: "Dumping Syndrome",
    tree_numbers: ["C06.405.748.630.310", "C23.550.767.812.500"],
    synonyms: ["Dumping (Jejunal) Syndrome", "Jejunal Syndrome"],
  },
  {
    ui: "D004378",
    heading: "Duodenal Diseases",
    tree_numbers: ["C06.405.469.275"],
    synonyms: ["Duodenal Disease", "Duodenum Disorder"],
  },
  {
    ui: "D004379",
    heading: "Duodenal Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.445",
      "C06.301.371.411.445",
      "C06.405.249.411.445",
      "C06.405.469.275.270",
      "C06.405.469.491.445",
    ],
    synonyms: ["Neoplasm Of Duodenum", "Neoplasm Of The Duodenum"],
  },
  {
    ui: "D004380",
    heading: "Duodenal Obstruction",
    tree_numbers: ["C06.405.469.275.395", "C06.405.469.531.311"],
  },
  {
    ui: "D004381",
    heading: "Duodenal Ulcer",
    tree_numbers: ["C06.405.469.275.800.348", "C06.405.748.586.349"],
    synonyms: ["Curling's Ulcers", "Curling Ulcer", "Stress Ulcer"],
  },
  {
    ui: "D004382",
    heading: "Duodenitis",
    tree_numbers: ["C06.405.205.462.249", "C06.405.469.275.600", "C06.405.469.326.750"],
    synonyms: ["Hemorrhagic Duodenitis"],
  },
  {
    ui: "D004383",
    heading: "Duodenogastric Reflux",
    tree_numbers: ["C06.405.469.275.700", "C06.405.748.240"],
    synonyms: ["Duodenogastric Reflux"],
  },
  {
    ui: "D004387",
    heading: "Dupuytren Contracture",
    tree_numbers: ["C04.557.450.565.590.340.173", "C05.651.197.270", "C17.300.270"],
  },
  {
    ui: "D004392",
    heading: "Dwarfism",
    tree_numbers: ["C05.116.099.343", "C16.320.240", "C19.297"],
  },
  {
    ui: "D004393",
    heading: "Dwarfism, Pituitary",
    tree_numbers: [
      "C05.116.099.343.445",
      "C05.116.132.358",
      "C10.228.140.617.738.300.300",
      "C19.297.312",
      "C19.700.482.311",
    ],
    synonyms: [
      "Familial Isolated Growth Hormone Deficiency",
      "Congenital Isolated Growth Hormone Deficiency",
      "Ighd",
      "Congenital Ighd",
      "Congenital Isolated Gh Deficiency",
      "Non-Acquired Isolated Growth Hormone Deficiency",
    ],
  },
  {
    ui: "D004401",
    heading: "Dysarthria",
    tree_numbers: ["C10.597.606.150.500.800.150.200", "C23.888.592.604.150.500.800.150.200"],
  },
  {
    ui: "D004402",
    heading: "Dysautonomia, Familial",
    tree_numbers: [
      "C10.177.575.300",
      "C10.500.250.309",
      "C10.574.500.493.250",
      "C10.668.829.800.175.250",
      "C16.131.666.310.309",
      "C16.320.400.415.309",
    ],
    synonyms: ["Familial Autonomic Nervous Dysfunction", "Hsan Iii", "Familial Dysautonomia"],
  },
  {
    ui: "D004403",
    heading: "Dysentery",
    tree_numbers: ["C06.405.205.331", "C06.405.469.300"],
    synonyms: ["Infectious Diarrhea"],
  },
  {
    ui: "D004404",
    heading: "Dysentery, Amebic",
    tree_numbers: [
      "C01.610.432.396",
      "C01.610.752.049.328",
      "C06.405.205.331.312",
      "C06.405.469.300.312",
      "C06.405.469.452.396",
    ],
  },
  {
    ui: "D004405",
    heading: "Dysentery, Bacillary",
    tree_numbers: ["C01.150.252.400.310.229", "C06.405.205.331.479", "C06.405.469.300.479"],
    synonyms: [
      "Bacillary Dysentery",
      "Shigella Flexneri Infectious Disease",
      "Shigella Sonnei Infectious Disease",
      "Shigella Gastroenteritis",
      "Shigella Boydii Infectious Disease",
    ],
  },
  {
    ui: "D004406",
    heading: "Dysgammaglobulinemia",
    tree_numbers: ["C15.378.147.333", "C20.673.430"],
    synonyms: ["Dysgammaglobulinemia"],
  },
  {
    ui: "D004407",
    heading: "Dysgerminoma",
    tree_numbers: ["C04.557.465.330.300"],
  },
  {
    ui: "D004408",
    heading: "Dysgeusia",
    tree_numbers: ["C10.597.751.861.479", "C23.888.592.763.861.479"],
  },
  {
    ui: "D004409",
    heading: "Dyskinesia, Drug-Induced",
    tree_numbers: [
      "C10.228.662.262.500",
      "C10.597.350.275",
      "C10.720.312",
      "C23.888.592.350.275",
      "C25.100.750",
      "C25.723.705.200",
    ],
  },
  {
    ui: "D004410",
    heading: "Dyslexia",
    tree_numbers: [
      "C10.597.606.150.500.300",
      "C10.597.606.150.550.200",
      "C23.888.592.604.150.500.300",
      "C23.888.592.604.150.550.200",
      "F03.625.562.400",
    ],
    synonyms: ["Acquired Dyslexia", "Aphemesthaesia"],
  },
  {
    ui: "D004411",
    heading: "Dyslexia, Acquired",
    tree_numbers: [
      "C10.597.606.150.500.300.200",
      "C10.597.606.150.550.200.500",
      "C23.888.592.604.150.500.300.200",
      "C23.888.592.604.150.550.200.500",
      "F03.615.700",
      "F03.625.562.400.500",
    ],
    synonyms: ["Acquired Dyslexia", "Aphemesthaesia"],
  },
  {
    ui: "D004412",
    heading: "Dysmenorrhea",
    tree_numbers: ["C23.550.568.750", "C23.888.592.612.944.500"],
  },
  {
    ui: "D004413",
    heading: "Dysostoses",
    tree_numbers: ["C05.116.099.370"],
  },
  {
    ui: "D004414",
    heading: "Dyspareunia",
    tree_numbers: ["C12.294.644.242", "C13.351.500.665.313", "F03.835.199"],
  },
  {
    ui: "D004415",
    heading: "Dyspepsia",
    tree_numbers: ["C23.888.821.236"],
  },
  {
    ui: "D004416",
    heading: "Dysplastic Nevus Syndrome",
    tree_numbers: ["C04.557.665.560.260", "C04.700.305", "C16.320.700.305"],
    synonyms: ["Familial Atypical Multiple Mole-Melanoma", "Famm Syndrome"],
  },
  {
    ui: "D004417",
    heading: "Dyspnea",
    tree_numbers: ["C08.618.326", "C23.888.852.371"],
  },
  {
    ui: "D004418",
    heading: "Dyspnea, Paroxysmal",
    tree_numbers: ["C08.618.326.396", "C14.280.434.313", "C23.888.852.371.396"],
  },
  {
    ui: "D004420",
    heading: "Dystocia",
    tree_numbers: ["C13.703.420.288"],
  },
  {
    ui: "D004421",
    heading: "Dystonia",
    tree_numbers: ["C10.597.350.300", "C23.888.592.350.300"],
    synonyms: ["Dystonic Disease"],
  },
  {
    ui: "D004422",
    heading: "Dystonia Musculorum Deformans",
    tree_numbers: ["C10.228.140.079.357", "C10.228.662.300.200", "C10.574.500.393", "C16.320.400.330"],
    synonyms: ["Familial Dystonia", "Fragments Of Torsion Dystonia"],
  },
  {
    ui: "D004426",
    heading: "Ear Deformities, Acquired",
    tree_numbers: ["C09.218.271"],
  },
  {
    ui: "D004427",
    heading: "Ear Diseases",
    tree_numbers: ["C09.218"],
    synonyms: ["Ear And Mastoid Disease"],
  },
  {
    ui: "D004428",
    heading: "Ear Neoplasms",
    tree_numbers: ["C04.588.443.665.312", "C09.218.334", "C09.647.312"],
    synonyms: ["Malignant Ear Neoplasm", "Auricular Cancer", "Malignant Tumor Of Ear"],
  },
  {
    ui: "D004433",
    heading: "Earache",
    tree_numbers: ["C09.218.350", "C23.888.592.612.302"],
  },
  {
    ui: "D004437",
    heading: "Ebstein Anomaly",
    tree_numbers: ["C14.240.400.395", "C14.280.400.395", "C16.131.240.400.395"],
    synonyms: [
      "Ebstein's Anomaly Of Common Atrioventricular Valve",
      "Ebstein's Anomaly",
      "Ebstein's Anomaly Of Right Atrioventricular Valve",
      "Ebstein's Anomaly Of Tricuspid Valve",
    ],
  },
  {
    ui: "D004438",
    heading: "Ecchymosis",
    tree_numbers: ["C15.378.100.452", "C23.550.414.625", "C23.888.885.312"],
  },
  {
    ui: "D004443",
    heading: "Echinococcosis",
    tree_numbers: ["C01.610.335.190.396"],
    synonyms: [
      "Hydatid Disease",
      "Liver Echinococcus",
      "Echinococcosis Of Liver",
      "Echinococcal Disease",
      "Hydatidosis",
      "Pulmonary Echinococcosis",
      "Hepatic Echinococcosis",
    ],
  },
  {
    ui: "D004444",
    heading: "Echinococcosis, Hepatic",
    tree_numbers: ["C01.610.335.190.396.314", "C01.610.518.314", "C06.552.664.272"],
  },
  {
    ui: "D004445",
    heading: "Echinococcosis, Pulmonary",
    tree_numbers: [
      "C01.610.335.190.396.480",
      "C01.610.582.314",
      "C01.748.450.314",
      "C08.381.517.314",
      "C08.730.450.314",
    ],
  },
  {
    ui: "D004451",
    heading: "Echinostomiasis",
    tree_numbers: ["C01.610.335.865.282"],
    synonyms: ["Infection By Echinochasmus"],
  },
  {
    ui: "D004454",
    heading: "Echolalia",
    tree_numbers: ["C10.597.606.150.500.800.300", "C23.888.592.604.150.500.800.300"],
  },
  {
    ui: "D004457",
    heading: "Echovirus Infections",
    tree_numbers: ["C01.925.782.687.359.347"],
  },
  {
    ui: "D004461",
    heading: "Eclampsia",
    tree_numbers: ["C13.703.395.124"],
    synonyms: ["Eclampsia In Puerperium", "Postpartum Eclampsia", "Eclampsia, Postpartum"],
  },
  {
    ui: "D004473",
    heading: "Ecthyma",
    tree_numbers: [
      "C01.150.252.410.890.168",
      "C01.150.252.819.210",
      "C01.800.720.210",
      "C17.800.695.210",
      "C17.800.838.765.210",
    ],
  },
  {
    ui: "D004474",
    heading: "Ecthyma, Contagious",
    tree_numbers: ["C01.925.256.743.193", "C22.836.259"],
    synonyms: ["Ecthyma Contagiosum", "Sheep Pox", "Orf", "Thistle Disease", "Ecthyma, Contagious", "Scabby Mouth"],
  },
  {
    ui: "D004476",
    heading: "Ectodermal Dysplasia",
    tree_numbers: ["C16.131.077.350", "C16.131.831.350", "C16.320.850.250", "C17.800.804.350", "C17.800.827.250"],
    synonyms: ["Congenital Ectodermal Dysplasia", "Congenital Ectodermal Defect"],
  },
  {
    ui: "D004478",
    heading: "Ectoparasitic Infestations",
    tree_numbers: ["C01.610.858.211"],
    synonyms: ["Ectoparasitism"],
  },
  {
    ui: "D004479",
    heading: "Ectopia Lentis",
    tree_numbers: ["C11.250.300", "C11.510.598.373", "C16.131.384.405"],
    synonyms: ["Iel", "Familial Ectopia Lentis"],
  },
  {
    ui: "D004480",
    heading: "Ectromelia",
    tree_numbers: ["C05.660.585.350", "C16.131.621.585.350"],
  },
  {
    ui: "D004482",
    heading: "Ectromelia, Infectious",
    tree_numbers: ["C01.925.256.743.239", "C22.795.239"],
  },
  {
    ui: "D004483",
    heading: "Ectropion",
    tree_numbers: ["C11.338.362"],
    synonyms: ["Ectropion Of Eyelid", "Everted Margin"],
  },
  {
    ui: "D004485",
    heading: "Eczema",
    tree_numbers: ["C17.800.174.620", "C17.800.815.620"],
  },
  {
    ui: "D004487",
    heading: "Edema",
    tree_numbers: ["C23.888.277"],
  },
  {
    ui: "D004488",
    heading: "Edema Disease of Swine",
    tree_numbers: ["C22.905.260"],
  },
  {
    ui: "D004489",
    heading: "Edema, Cardiac",
    tree_numbers: ["C14.280.434.482", "C23.888.277.197"],
  },
  {
    ui: "D004535",
    heading: "Ehlers-Danlos Syndrome",
    tree_numbers: [
      "C14.907.454.240",
      "C15.378.463.515.240",
      "C16.131.831.428",
      "C16.320.850.260",
      "C17.300.200.310",
      "C17.800.804.428",
      "C17.800.827.260",
    ],
    synonyms: ["Elastic Skin", "Cutis Hyperelastica"],
  },
  {
    ui: "D004541",
    heading: "Eisenmenger Complex",
    tree_numbers: ["C14.240.400.450", "C14.280.400.450", "C16.131.240.400.450"],
  },
  {
    ui: "D004556",
    heading: "Electric Injuries",
    tree_numbers: ["C26.324"],
  },
  {
    ui: "D004604",
    heading: "Elephantiasis",
    tree_numbers: ["C15.604.496.320"],
    synonyms: ["Elephantiasis"],
  },
  {
    ui: "D004605",
    heading: "Elephantiasis, Filarial",
    tree_numbers: ["C01.610.335.508.700.750.361.350", "C01.920.750", "C15.604.496.490"],
    synonyms: [
      "Elephantiasis Of Eyelid",
      "Bancroftian Elephantiasis",
      "Elephantiasis Of Eyelid",
      "Bancroftian Filarial Chyluria",
      "Lymphatic Filariasis",
    ],
  },
  {
    ui: "D004612",
    heading: "Elliptocytosis, Hereditary",
    tree_numbers: ["C15.378.071.141.150.365", "C16.320.070.365"],
    synonyms: ["Congenital Elliptocytosis", "Ovalocytosis"],
  },
  {
    ui: "D004613",
    heading: "Ellis-Van Creveld Syndrome",
    tree_numbers: [
      "C05.116.099.708.327",
      "C16.131.077.350.398",
      "C16.131.831.350.398",
      "C16.320.850.250.398",
      "C17.800.804.350.398",
      "C17.800.827.250.398",
    ],
    synonyms: ["Chondroectodermal Dysplasia", "Mesoectodermal Dysplasia", "Ellis-Van Creveld Syndrome"],
  },
  {
    ui: "D004614",
    heading: "Emaciation",
    tree_numbers: ["C23.888.144.243.963.500"],
  },
  {
    ui: "D004617",
    heading: "Embolism",
    tree_numbers: ["C14.907.355.350"],
  },
  {
    ui: "D004618",
    heading: "Embolism, Air",
    tree_numbers: ["C14.907.355.350.254"],
  },
  {
    ui: "D004619",
    heading: "Embolism, Amniotic Fluid",
    tree_numbers: ["C13.703.634.404", "C14.583.404", "C14.907.355.350.354"],
  },
  {
    ui: "D004620",
    heading: "Embolism, Fat",
    tree_numbers: ["C14.907.355.350.454"],
  },
  {
    ui: "D004630",
    heading: "Emergencies",
    tree_numbers: ["C23.550.291.781", "N06.230.100.083", "N06.850.376"],
  },
  {
    ui: "D004646",
    heading: "Emphysema",
    tree_numbers: ["C23.550.325"],
  },
  {
    ui: "D004652",
    heading: "Empty Sella Syndrome",
    tree_numbers: ["C10.228.140.617.738.200", "C19.700.320"],
    synonyms: ["Empty Sella Syndrome"],
  },
  {
    ui: "D004653",
    heading: "Empyema",
    tree_numbers: ["C01.830.305", "C23.550.470.756.305"],
  },
  {
    ui: "D004654",
    heading: "Empyema, Tuberculous",
    tree_numbers: [
      "C01.748.265.320",
      "C01.748.912.405",
      "C01.830.305.310.320",
      "C08.528.240.320",
      "C08.528.928.405",
      "C08.730.265.320",
      "C08.730.912.405",
      "C23.550.470.756.305.250.320",
    ],
    synonyms: ["Tuberculous Empyema"],
  },
  {
    ui: "D004660",
    heading: "Encephalitis",
    tree_numbers: ["C10.228.140.430"],
  },
  {
    ui: "D004670",
    heading: "Encephalitis, California",
    tree_numbers: [
      "C01.207.245.340.300.200",
      "C01.207.399.750.300.200",
      "C01.920.500.343.340",
      "C01.925.081.343.340",
      "C01.925.182.525.300.200",
      "C01.925.782.147.340",
      "C01.925.782.310.340",
      "C10.228.140.430.520.750.300.200",
      "C10.228.228.245.340.300.200",
      "C10.228.228.399.750.300.200",
    ],
    synonyms: [
      "California Encephalitis",
      "Neuroinvasive California Encephalitis Virus Infection",
      "California Virus Encephalitis",
    ],
  },
  {
    ui: "D004671",
    heading: "Encephalitis, Arbovirus",
    tree_numbers: [
      "C01.207.245.340.300",
      "C01.207.399.750.300",
      "C01.920.500.343",
      "C01.925.081.343",
      "C01.925.182.525.300",
      "C01.925.782.310",
      "C10.228.140.430.520.750.300",
      "C10.228.228.245.340.300",
      "C10.228.228.399.750.300",
    ],
    synonyms: ["Epidemic Encephalitis"],
  },
  {
    ui: "D004672",
    heading: "Encephalitis, Japanese",
    tree_numbers: [
      "C01.207.245.340.300.400",
      "C01.207.399.750.300.400",
      "C01.920.500.343.345",
      "C01.925.081.343.345",
      "C01.925.182.525.300.250",
      "C01.925.782.310.345",
      "C01.925.782.350.250.300",
      "C10.228.140.430.520.750.300.400",
      "C10.228.228.245.340.300.400",
      "C10.228.228.399.750.300.400",
    ],
    synonyms: ["Japanese B Encephalitis"],
  },
  {
    ui: "D004673",
    heading: "Encephalomyelitis, Acute Disseminated",
    tree_numbers: ["C10.114.375.225", "C10.228.140.695.562.225", "C10.314.350.225", "C20.111.258.250.350"],
    synonyms: ["Encephalitis Following Immunization Procedures", "Postvaccinal Encephalomyelitis"],
  },
  {
    ui: "D004674",
    heading: "Encephalitis, St. Louis",
    tree_numbers: [
      "C01.207.245.340.300.550",
      "C01.207.399.750.300.550",
      "C01.920.500.343.350",
      "C01.925.081.343.350",
      "C01.925.182.525.300.300",
      "C01.925.782.310.350",
      "C01.925.782.350.250.450",
      "C10.228.140.430.520.750.300.550",
      "C10.228.228.245.340.300.550",
      "C10.228.228.399.750.300.550",
    ],
    synonyms: ["Neuroinvasive St. Louis Encephalitis Virus Infection"],
  },
  {
    ui: "D004675",
    heading: "Encephalitis, Tick-Borne",
    tree_numbers: [
      "C01.207.245.340.300.775",
      "C01.207.399.750.300.775",
      "C01.920.500.343.360",
      "C01.920.930.400",
      "C01.925.081.343.360",
      "C01.925.182.525.300.350",
      "C01.925.782.310.360",
      "C01.925.782.350.250.500",
      "C10.228.140.430.520.750.300.775",
      "C10.228.228.245.340.300.775",
      "C10.228.228.399.750.300.775",
    ],
    synonyms: [
      "Far Eastern Tbe",
      "Taiga Encephalitis",
      "Central European Encephalitis",
      "Russian Spring-Summer Encephalitis",
      "Siberian Tick-Borne Encephalitis",
      "Western European Tick-Borne Encephalitis",
      "West-Siberian Encephalitis",
    ],
  },
  {
    ui: "D004677",
    heading: "Encephalocele",
    tree_numbers: ["C10.500.680.488", "C16.131.666.680.488", "C23.300.707.186"],
  },
  {
    ui: "D004678",
    heading: "Encephalomalacia",
    tree_numbers: ["C10.228.140.461"],
  },
  {
    ui: "D004679",
    heading: "Encephalomyelitis",
    tree_numbers: ["C01.207.291", "C10.228.228.291", "C10.228.440"],
    synonyms: ["Encephalitis &/or Myelitis"],
  },
  {
    ui: "D004681",
    heading: "Encephalomyelitis, Autoimmune, Experimental",
    tree_numbers: [
      "C10.114.703.300",
      "C10.228.140.695.562.250",
      "C10.314.350.250",
      "C20.111.258.625.300",
      "E05.598.500.500.500",
    ],
  },
  {
    ui: "D004682",
    heading: "Encephalomyelitis, Enzootic Porcine",
    tree_numbers: ["C01.925.782.687.359.456", "C22.905.323"],
  },
  {
    ui: "D004683",
    heading: "Encephalomyelitis, Equine",
    tree_numbers: [
      "C01.207.245.340.450",
      "C01.207.291.323",
      "C01.207.399.750.450",
      "C01.920.500.078.370",
      "C01.920.500.343.655",
      "C01.925.081.343.655",
      "C01.925.182.525.450",
      "C01.925.782.930.100.370",
      "C10.228.140.430.520.750.450",
      "C10.228.228.245.340.450",
      "C10.228.228.291.323",
      "C10.228.228.399.750.450",
      "C10.228.440.406",
    ],
  },
  {
    ui: "D004684",
    heading: "Leukoencephalitis, Acute Hemorrhagic",
    tree_numbers: [
      "C10.114.375.225.500",
      "C10.114.375.362",
      "C10.228.140.695.562.225.500",
      "C10.228.140.695.562.375",
      "C10.314.350.225.500",
      "C10.314.350.375",
      "C20.111.258.250.350.500",
      "C20.111.258.250.425",
    ],
    synonyms: [
      "Acute Haemorrhagic Leucoencephalitis Of Weston Hurst",
      "Acute Hemorrhagic Encephalomyelitis",
      "Acute Necrotizing Hemorrhagic Leukoencephalitis",
      "Weston-Hurst Syndrome",
      "Ahl",
    ],
  },
  {
    ui: "D004685",
    heading: "Encephalomyelitis, Venezuelan Equine",
    tree_numbers: [
      "C01.207.245.340.450.600",
      "C01.207.291.323.325",
      "C01.207.399.750.450.600",
      "C01.920.500.078.370.325",
      "C01.920.500.343.655.355",
      "C01.925.081.343.655.355",
      "C01.925.182.525.450.250",
      "C01.925.782.930.100.370.325",
      "C10.228.140.430.520.750.450.600",
      "C10.228.228.245.340.450.600",
      "C10.228.228.291.323.325",
      "C10.228.228.399.750.450.600",
      "C10.228.440.406.225",
    ],
    synonyms: [],
  },
  {
    ui: "D004687",
    heading: "Enchondromatosis",
    tree_numbers: ["C05.116.099.708.338"],
    synonyms: [
      "Kast's Syndrome",
      "Enchondromatosis, Multiple",
      "Dyschondroplasia",
      "Enchondromatosis With Haemangiomata",
      "Osteochondromatosis",
    ],
  },
  {
    ui: "D004688",
    heading: "Encopresis",
    tree_numbers: ["C23.888.821.266", "F01.145.126.837", "F03.388.300"],
  },
  {
    ui: "D004692",
    heading: "Endarteritis",
    tree_numbers: ["C14.907.940.090.340"],
  },
  {
    ui: "D004694",
    heading: "Endocardial Cushion Defects",
    tree_numbers: ["C14.240.400.560.350", "C14.280.400.560.350", "C16.131.240.400.560.350"],
    synonyms: ["Atrioventricular Canal Defect", "Endocardial Cushion Defect", "Avsd", "Ecd", "Avcd"],
  },
  {
    ui: "D004695",
    heading: "Endocardial Fibroelastosis",
    tree_numbers: ["C14.280.238.281"],
    synonyms: ["Elastomyofibrosis"],
  },
  {
    ui: "D004696",
    heading: "Endocarditis",
    tree_numbers: ["C14.280.282"],
  },
  {
    ui: "D004697",
    heading: "Endocarditis, Bacterial",
    tree_numbers: ["C01.150.252.245", "C01.190.249", "C14.260.249", "C14.280.282.407"],
  },
  {
    ui: "D004698",
    heading: "Endocarditis, Subacute Bacterial",
    tree_numbers: [
      "C01.150.252.245.500",
      "C01.150.252.410.890.335",
      "C01.190.249.407",
      "C14.260.249.407",
      "C14.280.282.407.407",
    ],
    synonyms: [
      "Endocarditis Lenta",
      "Subacute Bacterial Endocarditis",
      "Subacute Endocarditis, Lenta",
      "Sbe - Subacute Bacterial Endocarditis",
    ],
  },
  {
    ui: "D004700",
    heading: "Endocrine System Diseases",
    tree_numbers: ["C19"],
  },
  {
    ui: "D004701",
    heading: "Endocrine Gland Neoplasms",
    tree_numbers: ["C04.588.322", "C19.344"],
    synonyms: [
      "Malignant Tumour Of Endocrine Gland",
      "Endocrine Tumor",
      "Neoplasm Of Endocrine Gland",
      "Malignant Endocrine Tumor",
      "Neoplasm Of Endocrine System",
      "Endocrine Neoplasm",
      "Malignant Neoplasm Of Endocrine Gland",
    ],
  },
  {
    ui: "D004714",
    heading: "Endometrial Hyperplasia",
    tree_numbers: ["C13.351.500.852.228"],
  },
  {
    ui: "D004715",
    heading: "Endometriosis",
    tree_numbers: ["C13.351.500.163"],
  },
  {
    ui: "D004716",
    heading: "Endometritis",
    tree_numbers: ["C13.351.500.056.750.249", "C13.351.500.852.299"],
  },
  {
    ui: "D004719",
    heading: "Endomyocardial Fibrosis",
    tree_numbers: ["C14.280.238.406"],
    synonyms: [
      "Obscure African Cardiomyopathy",
      "Obscure African Cardiomyopathy",
      "Becker's Disease",
      "Endomyocardial Sclerosis",
      "African Endomyocardial Fibrosis",
    ],
  },
  {
    ui: "D004749",
    heading: "Entamoebiasis",
    tree_numbers: ["C01.610.752.049.407"],
  },
  {
    ui: "D004751",
    heading: "Enteritis",
    tree_numbers: ["C06.405.205.462", "C06.405.469.326"],
  },
  {
    ui: "D004753",
    heading: "Enteritis, Transmissible, of Turkeys",
    tree_numbers: ["C01.925.782.600.550.200.325", "C22.131.728.250"],
  },
  {
    ui: "D004756",
    heading: "Enterobacteriaceae Infections",
    tree_numbers: ["C01.150.252.400.310"],
  },
  {
    ui: "D004760",
    heading: "Enterocolitis",
    tree_numbers: ["C06.405.205.596", "C06.405.469.363"],
  },
  {
    ui: "D004761",
    heading: "Enterocolitis, Pseudomembranous",
    tree_numbers: ["C01.150.252.410.222.310", "C06.405.205.596.800", "C06.405.469.363.800"],
    synonyms: ["Pseudomembranous Colitis"],
  },
  {
    ui: "D004767",
    heading: "Enterotoxemia",
    tree_numbers: ["C01.150.252.410.222.325", "C22.313"],
  },
  {
    ui: "D004769",
    heading: "Enterovirus Infections",
    tree_numbers: ["C01.925.782.687.359"],
  },
  {
    ui: "D004774",
    heading: "Entropion",
    tree_numbers: ["C11.338.443"],
  },
  {
    ui: "D004775",
    heading: "Enuresis",
    tree_numbers: ["C12.777.934.284", "C13.351.968.934.252", "F01.145.126.856", "F03.388.400"],
  },
  {
    ui: "D004802",
    heading: "Eosinophilia",
    tree_numbers: ["C15.378.553.231"],
    synonyms: ["Eosinophilia", "Eosinophilic Leukocytosis"],
  },
  {
    ui: "D004803",
    heading: "Eosinophilic Granuloma",
    tree_numbers: [
      "C05.116.391",
      "C08.381.483.375.500",
      "C15.378.553.231.348",
      "C15.604.250.400.360",
      "C23.550.382.250",
    ],
  },
  {
    ui: "D004806",
    heading: "Ependymoma",
    tree_numbers: ["C04.557.465.625.600.380.290", "C04.557.470.670.380.290", "C04.557.580.625.600.380.290"],
    synonyms: ["Who Grade Ii Ependymal Tumor", "Epithelial Ependymoma"],
  },
  {
    ui: "D004810",
    heading: "Ephemeral Fever",
    tree_numbers: ["C01.925.782.580.830.375", "C22.196.274"],
  },
  {
    ui: "D004814",
    heading: "Epidermal Cyst",
    tree_numbers: ["C04.182.254"],
  },
  {
    ui: "D004818",
    heading: "Epidermitis, Exudative, of Swine",
    tree_numbers: ["C22.905.382"],
  },
  {
    ui: "D004819",
    heading: "Epidermodysplasia Verruciformis",
    tree_numbers: ["C01.925.256.650.810.345", "C01.925.825.810.260", "C01.925.928.914.345", "C17.800.838.790.810.260"],
  },
  {
    ui: "D004820",
    heading: "Epidermolysis Bullosa",
    tree_numbers: ["C16.131.831.493", "C16.320.850.275", "C17.800.804.493", "C17.800.827.275", "C17.800.865.410"],
    synonyms: ["Acantholysis Bullosa", "Epidermolysis Bullosa"],
  },
  {
    ui: "D004823",
    heading: "Epididymitis",
    tree_numbers: ["C12.294.199"],
  },
  {
    ui: "D004826",
    heading: "Epiglottitis",
    tree_numbers: ["C01.748.798.200", "C08.730.798.200"],
    synonyms: ["Acute Epiglottitis And Supraglottitis", "Acute Epiglottitis"],
  },
  {
    ui: "D004827",
    heading: "Epilepsy",
    tree_numbers: ["C10.228.140.490"],
    synonyms: ["Epileptic Syndrome", "Epilepsy Syndrome"],
  },
  {
    ui: "D004828",
    heading: "Epilepsies, Partial",
    tree_numbers: ["C10.228.140.490.360"],
    synonyms: ["Partial Epilepsy", "Localisation-Related Epilepsy"],
  },
  {
    ui: "D004829",
    heading: "Epilepsy, Generalized",
    tree_numbers: ["C10.228.140.490.375"],
    synonyms: ["Generalised Epilepsy"],
  },
  {
    ui: "D004830",
    heading: "Epilepsy, Tonic-Clonic",
    tree_numbers: ["C10.228.140.490.375.290"],
    synonyms: ["Tonic-Clonic Epilepsy", "Grand Mal Epilepsy", "Epileptic Seizures, Tonic-Clonic"],
  },
  {
    ui: "D004831",
    heading: "Epilepsies, Myoclonic",
    tree_numbers: ["C10.228.140.490.375.130", "C10.228.140.490.493.063"],
    synonyms: [
      "Epileptic Seizures, Myoclonic",
      "Epileptic Seizures - Myoclonic",
      "Myoclonic Seizure",
      "Myoclonic Epilepsy",
      "Myoclonic Seizure Disorder",
      "Myoclonia Epileptica",
    ],
  },
  {
    ui: "D004832",
    heading: "Epilepsy, Absence",
    tree_numbers: ["C10.228.140.490.375.260", "C10.228.140.490.493.125"],
    synonyms: ["Petit Mal Seizure", "Pyknolepsy"],
  },
  {
    ui: "D004833",
    heading: "Epilepsy, Temporal Lobe",
    tree_numbers: ["C10.228.140.490.360.290", "C10.228.140.490.493.375"],
    synonyms: ["Epilepsy, Temporal Lobe"],
  },
  {
    ui: "D004834",
    heading: "Epilepsy, Post-Traumatic",
    tree_numbers: ["C10.228.140.199.650", "C10.228.140.490.380", "C10.900.300.087.600", "C26.915.300.200.475"],
  },
  {
    ui: "D004839",
    heading: "Epiphyses, Slipped",
    tree_numbers: ["C05.116.425"],
  },
  {
    ui: "D004842",
    heading: "Epispadias",
    tree_numbers: ["C12.706.374", "C12.777.767.374", "C13.351.875.374", "C13.351.968.767.374", "C16.131.939.374"],
  },
  {
    ui: "D004844",
    heading: "Epistaxis",
    tree_numbers: ["C08.460.261", "C09.603.261", "C23.550.414.712", "C23.888.852.040"],
  },
  {
    ui: "D004859",
    heading: "Equine Infectious Anemia",
    tree_numbers: ["C01.925.782.815.616.300", "C01.925.839.375", "C22.488.304"],
  },
  {
    ui: "D004863",
    heading: "Equinus Deformity",
    tree_numbers: [
      "C05.330.488.655.125",
      "C05.330.495.681.125",
      "C05.660.585.512.380.813.125",
      "C16.131.621.585.512.500.681.125",
    ],
  },
  {
    ui: "D004881",
    heading: "Ergotism",
    tree_numbers: ["C25.723.680.262"],
  },
  {
    ui: "D004884",
    heading: "Eructation",
    tree_numbers: ["C23.888.821.297"],
  },
  {
    ui: "D004886",
    heading: "Erysipelas",
    tree_numbers: ["C01.150.252.410.890.503", "C01.150.252.819.260", "C01.800.720.260", "C17.800.838.765.260"],
  },
  {
    ui: "D004887",
    heading: "Erysipeloid",
    tree_numbers: ["C01.150.252.410.334.329"],
  },
  {
    ui: "D004889",
    heading: "Erysipelothrix Infections",
    tree_numbers: ["C01.150.252.410.334", "C22.331"],
  },
  {
    ui: "D004890",
    heading: "Erythema",
    tree_numbers: ["C17.800.229", "C23.888.885.328"],
  },
  {
    ui: "D004891",
    heading: "Erythema Induratum",
    tree_numbers: [
      "C01.150.252.819.820.260",
      "C01.800.720.820.260",
      "C17.300.710.329",
      "C17.800.229.329",
      "C17.800.566.329",
      "C17.800.838.765.820.320",
    ],
  },
  {
    ui: "D004892",
    heading: "Erythema Multiforme",
    tree_numbers: ["C17.800.229.400", "C17.800.865.475"],
  },
  {
    ui: "D004893",
    heading: "Erythema Nodosum",
    tree_numbers: ["C17.800.174.600.375", "C17.800.229.413", "C20.543.206.380.375", "C25.100.468.380.375"],
  },
  {
    ui: "D004894",
    heading: "Erythrasma",
    tree_numbers: ["C01.150.252.410.040.246.694", "C01.150.252.819.320", "C01.800.720.320", "C17.800.838.765.320"],
    synonyms: ["Infection Due To Corynebacterium Minutissimum"],
  },
  {
    ui: "D004899",
    heading: "Erythroblastosis, Fetal",
    tree_numbers: ["C13.703.277.060", "C15.378.295", "C16.300.060", "C16.614.304", "C20.306"],
    synonyms: [
      "Ef - Erythroblastosis Foetalis",
      "Rhesus Isoimmunisation Of The Newborn",
      "Haemolytic Disease Due To Rhesus Isoimmunisation",
      "Erythroblastosis Fetalis",
    ],
  },
  {
    ui: "D004915",
    heading: "Leukemia, Erythroblastic, Acute",
    tree_numbers: ["C04.557.337.539.275.325", "C15.378.190.636.276"],
  },
  {
    ui: "D004916",
    heading: "Erythromelalgia",
    tree_numbers: ["C14.907.617.500"],
    synonyms: ["Erythermalgia"],
  },
  {
    ui: "D004919",
    heading: "Erythroplasia",
    tree_numbers: ["C04.834.288"],
  },
  {
    ui: "D004927",
    heading: "Escherichia coli Infections",
    tree_numbers: ["C01.150.252.400.310.330"],
  },
  {
    ui: "D004931",
    heading: "Esophageal Achalasia",
    tree_numbers: ["C06.405.117.119.500.432"],
    synonyms: [
      "Achalasia Of Esophagus",
      "Cardiospasm",
      "Achalasia Of Cardia",
      "Hypertensive Lower Esophageal Sphincter",
      "Esophageal Achalasia",
      "Lack Of Reflex Relaxation Of Lower Oesophageal Sphincter",
    ],
  },
  {
    ui: "D004932",
    heading: "Esophageal and Gastric Varices",
    tree_numbers: ["C06.405.117.240", "C06.552.494.414"],
  },
  {
    ui: "D004933",
    heading: "Esophageal Atresia",
    tree_numbers: ["C06.198.330", "C06.405.117.260", "C16.131.314.330"],
    synonyms: [
      "Oesophageal Atresia",
      "Imperforate Esophagus",
      "Congenital Imperforate Esophagus",
      "Congenital Atresia Of Esophagus",
    ],
  },
  {
    ui: "D004934",
    heading: "Esophageal Cyst",
    tree_numbers: ["C04.182.281", "C06.405.117.316"],
  },
  {
    ui: "D004935",
    heading: "Esophageal Diseases",
    tree_numbers: ["C06.405.117"],
    synonyms: ["Esophageal Ulcer"],
  },
  {
    ui: "D004936",
    heading: "Diverticulum, Esophageal",
    tree_numbers: ["C06.405.205.282.750.625", "C23.300.415.625"],
  },
  {
    ui: "D004937",
    heading: "Esophageal Fistula",
    tree_numbers: ["C06.267.250", "C06.405.117.367", "C23.300.575.185.250"],
  },
  {
    ui: "D004938",
    heading: "Esophageal Neoplasms",
    tree_numbers: ["C04.588.274.476.205", "C04.588.443.353", "C06.301.371.205", "C06.405.117.430", "C06.405.249.205"],
    synonyms: [
      "Malignant Neoplasm Of Proximal Third Of Esophagus",
      "Malignant Neoplasm Of Lower Third Of Oesophagus",
      "Malignant Neoplasm Of Upper Third Esophagus",
      "Malignant Tumor Of Distal Third Of Esophagus",
      "Ca Lower Third Oesophagus",
      "Ca Middle Third Oesophagus",
      "Malignant Neoplasm Of Middle Third Of Oesophagus",
      "Malignant Tumor Of Proximal Third Of Esophagus",
      "Esophagus Cancer",
      "Malignant Tumor Of Abdominal Esophagus",
      "Malignant Neoplasm Of Distal Third Of Esophagus",
      "Malignant Tumor Of The Middle Third Of The Esophagus",
    ],
  },
  {
    ui: "D004939",
    heading: "Esophageal Perforation",
    tree_numbers: ["C06.405.117.468", "C26.348"],
  },
  {
    ui: "D004940",
    heading: "Esophageal Stenosis",
    tree_numbers: ["C06.405.117.544"],
  },
  {
    ui: "D004941",
    heading: "Esophagitis",
    tree_numbers: ["C06.405.117.620", "C06.405.205.663"],
    synonyms: ["Acute Esophagitis"],
  },
  {
    ui: "D004942",
    heading: "Esophagitis, Peptic",
    tree_numbers: ["C06.405.117.620.420", "C06.405.205.663.420", "C06.405.469.275.800.523", "C06.405.748.586.524"],
    synonyms: ["Peptic Esophagitis", "Peptic Reflux Disease", "Reflux Oesophagitis", "Reflux Esophagitis"],
  },
  {
    ui: "D004948",
    heading: "Esotropia",
    tree_numbers: ["C10.292.562.887.300", "C11.590.810.400"],
    synonyms: ["Convergence In Manifest Squint", "Crossed Eyes", "Internal Strabismus"],
  },
  {
    ui: "D005058",
    heading: "Eunuchism",
    tree_numbers: ["C19.391.482.293"],
  },
  {
    ui: "D005067",
    heading: "Euthyroid Sick Syndromes",
    tree_numbers: ["C19.874.255"],
    synonyms: ["Euthyroid Sick Syndrome", "Sick-Euthyroid Syndrome"],
  },
  {
    ui: "D005076",
    heading: "Exanthema",
    tree_numbers: ["C17.800.257"],
    synonyms: ["Rash", "Exanthema"],
  },
  {
    ui: "D005077",
    heading: "Exanthema Subitum",
    tree_numbers: ["C01.925.256.466.850.290", "C01.925.825.290", "C17.800.257.335", "C17.800.838.790.290"],
    synonyms: ["Roseola Infantum", "Sixth Disease"],
  },
  {
    ui: "D005084",
    heading: "Exhibitionism",
    tree_numbers: ["F03.657.300"],
  },
  {
    ui: "D005094",
    heading: "Exophthalmos",
    tree_numbers: ["C11.675.349"],
    synonyms: ["Proptosis"],
  },
  {
    ui: "D005096",
    heading: "Exostoses",
    tree_numbers: ["C05.116.540.310"],
    synonyms: ["Bony Outgrowth", "Swimmer's Exostosis", "Orbital Exostosis", "Osteophyte", "Bone Spur"],
  },
  {
    ui: "D005097",
    heading: "Exostoses, Multiple Hereditary",
    tree_numbers: [
      "C04.557.450.565.575.610.615.325",
      "C04.700.330",
      "C05.116.099.708.670.615.325",
      "C05.116.540.310.500",
      "C16.320.700.330",
    ],
    synonyms: [
      "Osteochondromatosis Syndrome",
      "Multiple Exostosis Syndromes",
      "Hereditary Multiple Exostoses 3",
      "Hereditary Multiple Exostoses 2",
      "Multiple Ostechondromas",
      "Hereditary Multiple Exostoses 1",
      "Multiple Congenital Exostosis",
    ],
  },
  {
    ui: "D005099",
    heading: "Exotropia",
    tree_numbers: ["C10.292.562.887.650", "C11.590.810.440"],
    synonyms: ["Divergent Strabismus", "Divergent Concomitant Strabismus"],
  },
  {
    ui: "D005117",
    heading: "Cardiac Complexes, Premature",
    tree_numbers: ["C14.280.067.325", "C14.280.123.375", "C23.550.073.325"],
  },
  {
    ui: "D005119",
    heading: "Extravasation of Diagnostic and Therapeutic Materials",
    tree_numbers: ["C23.550.340", "C26.371"],
  },
  {
    ui: "D005124",
    heading: "Eye Abnormalities",
    tree_numbers: ["C11.250", "C16.131.384"],
  },
  {
    ui: "D005126",
    heading: "Eye Burns",
    tree_numbers: ["C10.900.300.284.250.250", "C26.200.503", "C26.915.300.425.250.250"],
  },
  {
    ui: "D005128",
    heading: "Eye Diseases",
    tree_numbers: ["C11"],
  },
  {
    ui: "D005129",
    heading: "Eye Foreign Bodies",
    tree_numbers: ["C10.900.300.284.250.260", "C26.392.421", "C26.915.300.425.250.260"],
  },
  {
    ui: "D005130",
    heading: "Eye Hemorrhage",
    tree_numbers: ["C11.290", "C23.550.414.756", "C23.888.307.500"],
  },
  {
    ui: "D005131",
    heading: "Eye Injuries",
    tree_numbers: ["C10.900.300.284.250", "C11.297", "C26.915.300.425.250"],
  },
  {
    ui: "D005132",
    heading: "Eye Manifestations",
    tree_numbers: ["C11.300", "C23.888.307"],
  },
  {
    ui: "D005134",
    heading: "Eye Neoplasms",
    tree_numbers: ["C04.588.364", "C11.319"],
    synonyms: ["Neoplasm Of Eye Proper", "Malignant Eye Neoplasm", "Ocular Tumor", "Eye Neoplasm", "Neoplasm Of Eye"],
  },
  {
    ui: "D005141",
    heading: "Eyelid Diseases",
    tree_numbers: ["C11.338"],
  },
  {
    ui: "D005142",
    heading: "Eyelid Neoplasms",
    tree_numbers: ["C04.588.443.392.500", "C11.319.421", "C11.338.526"],
    synonyms: ["Tumor Of The Eyelid"],
  },
  {
    ui: "D005146",
    heading: "Facial Asymmetry",
    tree_numbers: ["C23.300.505"],
  },
  {
    ui: "D005148",
    heading: "Facial Dermatoses",
    tree_numbers: ["C17.800.271"],
  },
  {
    ui: "D005150",
    heading: "Facial Hemiatrophy",
    tree_numbers: ["C07.465.284", "C07.465.299.375", "C10.292.319.375"],
    synonyms: ["Parry-Romberg Syndrome"],
  },
  {
    ui: "D005151",
    heading: "Facial Injuries",
    tree_numbers: ["C10.900.300.284", "C26.915.300.425"],
  },
  {
    ui: "D005153",
    heading: "Facial Neoplasms",
    tree_numbers: ["C04.588.443.392"],
  },
  {
    ui: "D005155",
    heading: "Facial Nerve Diseases",
    tree_numbers: ["C07.465.299", "C10.292.319"],
  },
  {
    ui: "D005156",
    heading: "Facial Neuralgia",
    tree_numbers: ["C07.465.299.625", "C10.292.319.625"],
  },
  {
    ui: "D005157",
    heading: "Facial Pain",
    tree_numbers: ["C23.888.592.612.330"],
  },
  {
    ui: "D005158",
    heading: "Facial Paralysis",
    tree_numbers: ["C07.465.327", "C10.597.622.214", "C23.888.592.636.214"],
    synonyms: ["Facial Palsy"],
  },
  {
    ui: "D005162",
    heading: "Factitious Disorders",
    tree_numbers: ["F03.875.375"],
  },
  {
    ui: "D005166",
    heading: "Factor V Deficiency",
    tree_numbers: ["C15.378.100.100.300", "C15.378.100.141.300", "C15.378.463.300", "C16.320.099.300"],
    synonyms: [
      "Hereditary Hypoproaccelerinaemia",
      "Proaccelerin Deficiency",
      "Labile Factor Deficiency",
      "Deficiency, Labile",
    ],
  },
  {
    ui: "D005168",
    heading: "Factor VII Deficiency",
    tree_numbers: ["C15.378.100.100.310", "C15.378.100.141.310", "C15.378.463.310", "C16.320.099.310"],
    synonyms: ["Factor Vii Deficiency", "Deficiency, Stable"],
  },
  {
    ui: "D005171",
    heading: "Factor X Deficiency",
    tree_numbers: ["C15.378.100.100.320", "C15.378.100.141.320", "C15.378.463.320", "C16.320.099.320"],
    synonyms: ["Disease, Stuart-Prower", "Factor X Deficiency"],
  },
  {
    ui: "D005173",
    heading: "Factor XI Deficiency",
    tree_numbers: ["C15.378.100.100.325", "C15.378.100.141.325", "C15.378.463.325", "C16.320.099.325"],
    synonyms: [
      "Congenital Factor Xi Deficiency",
      "Plasma Thromboplastin Antecedent Deficiency",
      "Rosenthal's Disease",
      "Hereditary Factor Xi Deficiency Disease",
      "Hemophilia C",
    ],
  },
  {
    ui: "D005175",
    heading: "Factor XII Deficiency",
    tree_numbers: ["C15.378.100.100.330", "C15.378.100.141.330", "C15.378.463.330", "C16.320.099.330"],
    synonyms: ["Factor Xii Deficiency Disease", "Deficiency, Hageman", "Hageman Factor Deficiency"],
  },
  {
    ui: "D005177",
    heading: "Factor XIII Deficiency",
    tree_numbers: ["C15.378.100.100.335", "C15.378.100.141.335", "C15.378.463.335", "C16.320.099.335"],
    synonyms: [
      "Hereditary Factor Xiii Deficiency Disease",
      "Deficiency, Laki-Lorand Factor",
      "Factor Xiii Deficiency Disease",
    ],
  },
  {
    ui: "D005183",
    heading: "Failure to Thrive",
    tree_numbers: ["C23.888.338"],
  },
  {
    ui: "D005184",
    heading: "Fallopian Tube Diseases",
    tree_numbers: ["C13.351.500.056.390"],
  },
  {
    ui: "D005185",
    heading: "Fallopian Tube Neoplasms",
    tree_numbers: ["C04.588.945.418.365", "C13.351.500.056.390.390", "C13.351.937.418.365"],
    synonyms: [
      "Tumor, Fallopian Tube, Malignant",
      "Neoplasm Of Fallopian Tube",
      "Malignant Neoplasm Of Uterine Tube",
      "Fallopian Tube Neoplasm",
      "Malignant Tumor Of Fallopian Tube",
      "Tumor Of The Fallopian Tube",
      "Malignant Tumour Of Fallopian Tube",
    ],
  },
  {
    ui: "D005198",
    heading: "Fanconi Syndrome",
    tree_numbers: ["C12.777.419.815.450", "C13.351.968.419.815.450", "C16.320.831.450"],
    synonyms: [
      "Adult Fanconi Syndrome",
      "Adult Fanconi Anemia",
      "Detoni Fanconi Syndrome",
      "Congenital Fanconi Syndrome",
      "De Toni-Fanconi Syndrome",
      "Infantile Nephropathic Cystinosis",
      "Fanconi-De Toni Syndrome",
      "Fanconi-De-Toni Syndrome",
      "Lignac-Fanconi Syndrome",
    ],
  },
  {
    ui: "D005199",
    heading: "Fanconi Anemia",
    tree_numbers: ["C15.378.071.085.080.280", "C15.378.190.223.500.500.280", "C16.320.077.280", "C18.452.284.280"],
    synonyms: [
      "Fanconi's Anemia",
      "Fanconi Panmyelopathy",
      "Fanconi Anaemia",
      "Fanconi's Anaemia",
      "Fanconi Pancytopenia",
    ],
  },
  {
    ui: "D005203",
    heading: "Farmer's Lung",
    tree_numbers: ["C08.381.483.125.365", "C08.674.055.365", "C20.543.480.680.075.365", "C24.080.365"],
    synonyms: ["Farmer Lung", "Farmers Lung"],
  },
  {
    ui: "D005207",
    heading: "Fasciculation",
    tree_numbers: ["C10.597.613.250", "C23.888.592.608.250"],
  },
  {
    ui: "D005208",
    heading: "Fasciitis",
    tree_numbers: ["C05.321"],
  },
  {
    ui: "D005211",
    heading: "Fascioliasis",
    tree_numbers: ["C01.610.335.865.354", "C01.610.518.424", "C06.552.664.424"],
    synonyms: ["Liver Flukes", "Fasciola Hepatica Infection", "Infection By Fasciola", "Sheep Liver Fluke Infection"],
  },
  {
    ui: "D005213",
    heading: "Fascioloidiasis",
    tree_numbers: ["C01.610.335.349.444", "C01.610.335.865.399", "C01.610.701.377.444", "C22.674.377.444"],
  },
  {
    ui: "D005218",
    heading: "Fat Necrosis",
    tree_numbers: ["C23.550.717.365"],
  },
  {
    ui: "D005221",
    heading: "Fatigue",
    tree_numbers: ["C23.888.369"],
  },
  {
    ui: "D005222",
    heading: "Mental Fatigue",
    tree_numbers: ["C23.888.369.500", "F01.145.126.937"],
  },
  {
    ui: "D005234",
    heading: "Fatty Liver",
    tree_numbers: ["C06.552.241"],
    synonyms: ["Alcoholic Fatty Liver", "Steatosis Of Liver", "Hepatic Lipidosis", "Fatty Change Of Liver"],
  },
  {
    ui: "D005235",
    heading: "Fatty Liver, Alcoholic",
    tree_numbers: ["C06.552.241.390", "C06.552.645.390", "C25.775.100.087.645.390"],
    synonyms: ["Alcoholic Fatty Liver", "Steatosis Of Liver", "Hepatic Lipidosis", "Fatty Change Of Liver"],
  },
  {
    ui: "D005236",
    heading: "Favism",
    tree_numbers: [
      "C15.378.071.141.150.480.370",
      "C15.378.071.141.370",
      "C16.320.070.480.370",
      "C25.723.415.341",
      "C25.723.756.375",
    ],
  },
  {
    ui: "D005242",
    heading: "Fecal Incontinence",
    tree_numbers: ["C06.405.469.860.300"],
  },
  {
    ui: "D005244",
    heading: "Fecal Impaction",
    tree_numbers: ["C06.405.469.531.424"],
  },
  {
    ui: "D005254",
    heading: "Feline Panleukopenia",
    tree_numbers: ["C01.925.256.700.363", "C22.180.460"],
  },
  {
    ui: "D005258",
    heading: "Felty Syndrome",
    tree_numbers: ["C05.550.114.154.389", "C05.799.114.389", "C17.300.775.099.389", "C20.111.199.389"],
    synonyms: ["Rheumatoid Arthritis With Splenoadenomegaly And Leukopenia", "Felty Syndrome"],
  },
  {
    ui: "D005261",
    heading: "Female Urogenital Diseases and Pregnancy Complications",
    tree_numbers: ["C13"],
  },
  {
    ui: "D005262",
    heading: "Feminization",
    tree_numbers: ["C23.888.378"],
  },
  {
    ui: "D005264",
    heading: "Femoral Fractures",
    tree_numbers: ["C26.404.061", "C26.558.276"],
  },
  {
    ui: "D005265",
    heading: "Femoral Neck Fractures",
    tree_numbers: ["C26.404.061.425.500", "C26.531.750.500", "C26.558.276.425.500"],
  },
  {
    ui: "D005266",
    heading: "Femoral Neoplasms",
    tree_numbers: ["C04.588.149.276", "C05.116.231.343"],
    synonyms: ["Neoplasm Of Femur"],
  },
  {
    ui: "D005271",
    heading: "Femur Head Necrosis",
    tree_numbers: ["C05.116.852.175", "C23.550.717.732.368"],
  },
  {
    ui: "D005311",
    heading: "Fetal Hypoxia",
    tree_numbers: ["C13.703.277.390", "C16.300.420", "C23.888.852.079.594"],
  },
  {
    ui: "D005313",
    heading: "Fetal Death",
    tree_numbers: ["C13.703.223", "C23.550.260.585"],
  },
  {
    ui: "D005315",
    heading: "Fetal Diseases",
    tree_numbers: ["C13.703.277", "C16.300"],
  },
  {
    ui: "D005316",
    heading: "Fetal Distress",
    tree_numbers: ["C23.888.380"],
  },
  {
    ui: "D005317",
    heading: "Fetal Growth Retardation",
    tree_numbers: ["C13.703.277.370", "C16.300.390", "C23.550.393.450"],
  },
  {
    ui: "D005320",
    heading: "Fetal Macrosomia",
    tree_numbers: [
      "C13.703.170.500",
      "C13.703.277.570",
      "C13.703.726.570",
      "C16.300.570",
      "C19.246.099.968",
      "C23.888.144.186.500",
    ],
  },
  {
    ui: "D005322",
    heading: "Fetal Membranes, Premature Rupture",
    tree_numbers: ["C13.703.420.339"],
  },
  {
    ui: "D005327",
    heading: "Fetal Resorption",
    tree_numbers: ["C13.703.223.300", "C23.550.260.585.260"],
  },
  {
    ui: "D005329",
    heading: "Fetishism, Psychiatric",
    tree_numbers: ["F03.657.350"],
  },
  {
    ui: "D005330",
    heading: "Fetofetal Transfusion",
    tree_numbers: ["C15.378.071.363.344", "C16.614.053.344"],
    synonyms: [
      "Twin-To-Twin Blood Transfer",
      "Fetal Blood Loss From Fetal Hemorrhage Into Co-Twin",
      "Fetal Hemorrhage Into Co-Twin",
      "Twin To Twin Transfusion",
    ],
  },
  {
    ui: "D005331",
    heading: "Fetomaternal Transfusion",
    tree_numbers: ["C15.378.071.363.511", "C16.614.053.511"],
  },
  {
    ui: "D005334",
    heading: "Fever",
    tree_numbers: ["C23.888.119.344"],
  },
  {
    ui: "D005335",
    heading: "Fever of Unknown Origin",
    tree_numbers: ["C23.888.119.344.345"],
  },
  {
    ui: "D005348",
    heading: "Fibrocystic Breast Disease",
    tree_numbers: ["C17.800.090.750"],
    synonyms: ["Adenosis Of The Breast", "Adenosis - Breast"],
  },
  {
    ui: "D005350",
    heading: "Fibroma",
    tree_numbers: ["C04.557.450.565.590.340"],
  },
  {
    ui: "D005351",
    heading: "Fibromatosis, Gingival",
    tree_numbers: ["C07.465.525.304", "C07.465.714.258.428.200", "C07.650.525.304", "C16.131.850.525.304"],
  },
  {
    ui: "D005352",
    heading: "Fibromuscular Dysplasia",
    tree_numbers: ["C14.907.137.372"],
  },
  {
    ui: "D005354",
    heading: "Fibrosarcoma",
    tree_numbers: ["C04.557.450.565.590.350", "C04.557.450.795.350"],
    synonyms: ["Fibrous Tissue Neoplasm", "Fibrocytic Tumor", "Fibrosarcoma Of Soft Tissue"],
  },
  {
    ui: "D005355",
    heading: "Fibrosis",
    tree_numbers: ["C23.550.355"],
  },
  {
    ui: "D005356",
    heading: "Fibromyalgia",
    tree_numbers: ["C05.651.324", "C05.799.321", "C10.668.491.425"],
  },
  {
    ui: "D005357",
    heading: "Fibrous Dysplasia of Bone",
    tree_numbers: ["C05.116.099.708.375"],
  },
  {
    ui: "D005358",
    heading: "Fibrous Dysplasia, Monostotic",
    tree_numbers: ["C05.116.099.708.375.372"],
  },
  {
    ui: "D005359",
    heading: "Fibrous Dysplasia, Polyostotic",
    tree_numbers: ["C05.116.099.708.375.381"],
    synonyms: ["Polyostotic Fibrous Dysplasia", "Osteitis Fibrosa Disseminata", "Fibrous Dysplasia Of Bone"],
  },
  {
    ui: "D005368",
    heading: "Filariasis",
    tree_numbers: ["C01.610.335.508.700.750.361"],
    synonyms: ["Disease Due To Superfamily Filarioidea"],
  },
  {
    ui: "D005383",
    heading: "Finger Injuries",
    tree_numbers: ["C26.448.429"],
  },
  {
    ui: "D005391",
    heading: "Firesetting Behavior",
    tree_numbers: ["F03.250.300"],
    synonyms: ["Firesetting Behavior", "Pathological Firesetting"],
  },
  {
    ui: "D005393",
    heading: "Fish Diseases",
    tree_numbers: ["C22.362"],
  },
  {
    ui: "D005401",
    heading: "Fissure in Ano",
    tree_numbers: ["C06.405.469.860.101.430"],
  },
  {
    ui: "D005402",
    heading: "Fistula",
    tree_numbers: ["C23.300.575"],
  },
  {
    ui: "D005409",
    heading: "Flail Chest",
    tree_numbers: ["C26.891.315"],
  },
  {
    ui: "D005413",
    heading: "Flatfoot",
    tree_numbers: [
      "C05.330.488.655.250",
      "C05.330.495.681.250",
      "C05.660.585.512.380.813.250",
      "C16.131.621.585.512.500.681.250",
    ],
    synonyms: [
      "Congenital Convex Foot",
      "Congenital Convex Pes Valgus",
      "Cvt",
      "Rocker-Bottom Foot Deformity",
      "Congenital Rocker-Bottom Foot",
    ],
  },
  {
    ui: "D005414",
    heading: "Flatulence",
    tree_numbers: ["C23.888.821.360"],
  },
  {
    ui: "D005458",
    heading: "Fluoride Poisoning",
    tree_numbers: ["C25.723.380"],
  },
  {
    ui: "D005483",
    heading: "Flushing",
    tree_numbers: ["C23.888.885.344"],
  },
  {
    ui: "D005489",
    heading: "Focal Dermal Hypoplasia",
    tree_numbers: [
      "C05.116.099.370.380",
      "C16.131.077.350.424",
      "C16.131.831.350.424",
      "C16.320.322.186",
      "C16.320.850.250.424",
      "C17.800.804.350.424",
      "C17.800.827.250.424",
    ],
    synonyms: ["Goltz Syndrome", "Goltz-Gorlin Syndrome", "Fdh", "Fodh"],
  },
  {
    ui: "D005490",
    heading: "Focal Infection",
    tree_numbers: ["C01.392"],
  },
  {
    ui: "D005491",
    heading: "Focal Infection, Dental",
    tree_numbers: ["C01.392.433", "C07.793.348"],
  },
  {
    ui: "D005494",
    heading: "Folic Acid Deficiency",
    tree_numbers: ["C18.654.521.500.133.699.308"],
  },
  {
    ui: "D005497",
    heading: "Follicular Cyst",
    tree_numbers: ["C04.182.300"],
  },
  {
    ui: "D005499",
    heading: "Folliculitis",
    tree_numbers: ["C17.800.329.500"],
  },
  {
    ui: "D005512",
    heading: "Food Hypersensitivity",
    tree_numbers: ["C20.543.480.370"],
    synonyms: ["Food Hypersensitivity"],
  },
  {
    ui: "D005517",
    heading: "Foodborne Diseases",
    tree_numbers: ["C25.723.415"],
  },
  {
    ui: "D005530",
    heading: "Foot Deformities",
    tree_numbers: ["C05.330"],
  },
  {
    ui: "D005531",
    heading: "Foot Deformities, Acquired",
    tree_numbers: ["C05.330.488"],
  },
  {
    ui: "D005532",
    heading: "Foot Deformities, Congenital",
    tree_numbers: ["C05.330.495", "C05.660.585.512.380", "C16.131.621.585.512.500"],
  },
  {
    ui: "D005533",
    heading: "Foot Dermatoses",
    tree_numbers: ["C17.800.321.125"],
  },
  {
    ui: "D005534",
    heading: "Foot Diseases",
    tree_numbers: ["C05.360", "C17.800.321"],
  },
  {
    ui: "D005535",
    heading: "Foot Rot",
    tree_numbers: ["C01.150.252.400.369", "C22.394"],
  },
  {
    ui: "D005536",
    heading: "Foot-and-Mouth Disease",
    tree_numbers: ["C01.925.782.687.484", "C22.380"],
  },
  {
    ui: "D005543",
    heading: "Forearm Injuries",
    tree_numbers: ["C26.088.268"],
  },
  {
    ui: "D005547",
    heading: "Foreign Bodies",
    tree_numbers: ["C26.392"],
  },
  {
    ui: "D005548",
    heading: "Foreign-Body Migration",
    tree_numbers: ["C26.392.500"],
  },
  {
    ui: "D005549",
    heading: "Foreign-Body Reaction",
    tree_numbers: ["C23.550.470.251", "C26.392.560"],
  },
  {
    ui: "D005585",
    heading: "Influenza in Birds",
    tree_numbers: ["C01.925.782.620.300", "C22.131.450"],
    synonyms: ["Bird Flu", "Avian Flu"],
  },
  {
    ui: "D005586",
    heading: "Fowlpox",
    tree_numbers: ["C01.925.256.743.366", "C22.131.321"],
  },
  {
    ui: "D005588",
    heading: "Fox-Fordyce Disease",
    tree_numbers: ["C17.800.946.492.285"],
    synonyms: ["Fox Fordyce Disease"],
  },
  {
    ui: "D005596",
    heading: "Fractures, Closed",
    tree_numbers: ["C26.404.124"],
  },
  {
    ui: "D005597",
    heading: "Fractures, Open",
    tree_numbers: ["C26.404.311"],
  },
  {
    ui: "D005598",
    heading: "Fractures, Spontaneous",
    tree_numbers: ["C26.404.374"],
  },
  {
    ui: "D005599",
    heading: "Fractures, Ununited",
    tree_numbers: ["C26.404.468"],
  },
  {
    ui: "D005600",
    heading: "Fragile X Syndrome",
    tree_numbers: [
      "C10.597.606.360.455.500",
      "C16.131.260.830.300",
      "C16.320.180.830.300",
      "C16.320.322.500.500",
      "C16.320.400.525.500",
    ],
    synonyms: ["Marker X Syndrome", "Fragile X Mental Retardation Syndrome", "Martin-Bell Syndrome"],
  },
  {
    ui: "D005611",
    heading: "Freemartinism",
    tree_numbers: [
      "C12.706.316.795.124",
      "C13.351.875.253.795.124",
      "C16.131.939.316.795.124",
      "C19.391.119.795.124",
      "C22.196.339",
    ],
  },
  {
    ui: "D005621",
    heading: "Friedreich Ataxia",
    tree_numbers: [
      "C10.228.140.252.700.150",
      "C10.228.854.787.200",
      "C10.574.500.825.200",
      "C16.320.400.780.200",
      "C18.452.660.300",
    ],
    synonyms: ["Friedreich Ataxia 1", "Friedreich's Ataxia", "Friedreich's Tabes"],
  },
  {
    ui: "D005627",
    heading: "Frostbite",
    tree_numbers: ["C26.212.500", "C26.417"],
  },
  {
    ui: "D005633",
    heading: "Fructose Intolerance",
    tree_numbers: ["C16.320.565.202.251.271", "C18.452.648.202.251.271"],
    synonyms: ["Fructose-1,6-Bisphosphate Aldolase B Deficiency", "Fructosaemia", "Fructosemia"],
  },
  {
    ui: "D005642",
    heading: "Fuchs' Endothelial Dystrophy",
    tree_numbers: ["C11.204.236.438", "C11.270.162.438", "C16.320.290.162.410"],
    synonyms: ["Fuchs' Endothelial Corneal Dystrophy", "Fuchs' Corneal Dystrophy", "Fced"],
  },
  {
    ui: "D005645",
    heading: "Fucosidosis",
    tree_numbers: [
      "C10.228.140.163.100.435.295",
      "C16.320.565.189.435.295",
      "C16.320.565.202.303",
      "C16.320.565.595.554.295",
      "C18.452.132.100.435.295",
      "C18.452.648.189.435.295",
      "C18.452.648.202.303",
      "C18.452.648.595.554.295",
    ],
    synonyms: ["A-Fucosidase Deficiency", "Alpha Fucosidase Deficiency"],
  },
  {
    ui: "D005660",
    heading: "Funnel Chest",
    tree_numbers: ["C05.116.099.386", "C05.660.386", "C16.131.621.386"],
  },
  {
    ui: "D005667",
    heading: "Furunculosis",
    tree_numbers: [
      "C01.150.252.410.868.951.270",
      "C01.150.252.819.770.270",
      "C01.800.720.770.270",
      "C17.800.838.765.770.270",
      "C22.362.224",
    ],
  },
  {
    ui: "D005671",
    heading: "Fused Teeth",
    tree_numbers: ["C07.650.800.370", "C07.793.700.370", "C16.131.850.800.370"],
  },
  {
    ui: "D005674",
    heading: "Fusobacterium Infections",
    tree_numbers: ["C01.150.252.400.388.350"],
    synonyms: ["Human Necrobacillosis", "Postanginal Sepsis", "Lemierre Syndrome"],
  },
  {
    ui: "D005683",
    heading: "Gagging",
    tree_numbers: ["C23.888.821.414", "F02.830.702.157", "G10.261.338", "G11.561.731.251"],
  },
  {
    ui: "D005687",
    heading: "Galactorrhea",
    tree_numbers: ["C13.703.844.506.389", "C17.800.090.937.439"],
  },
  {
    ui: "D005693",
    heading: "Galactosemias",
    tree_numbers: [
      "C10.228.140.163.100.320",
      "C16.320.565.189.320",
      "C16.320.565.202.355",
      "C18.452.132.100.320",
      "C18.452.648.189.320",
      "C18.452.648.202.355",
    ],
    synonyms: [
      "Udp-Galactose-4-Epimerase Deficiency",
      "Gale Deficiency",
      "Uridine Diphosphate Galactose-4-Epimerase Deficiency",
      "Epimerase Deficiency Galactosemia",
      "Gale-D",
      "Galactosemia Type 3",
      "Galactosemia Iii",
    ],
  },
  {
    ui: "D005705",
    heading: "Gallbladder Diseases",
    tree_numbers: ["C06.130.564"],
  },
  {
    ui: "D005706",
    heading: "Gallbladder Neoplasms",
    tree_numbers: ["C04.588.274.120.401", "C06.130.320.401", "C06.130.564.401", "C06.301.120.401"],
    synonyms: [
      "Gallbladder Neoplasm",
      "Malignant Neoplasm Of Gallbladder",
      "Malignant Tumour Of Gallbladder",
      "Gallbladder Ca",
      "Malignant Tumor Of The Gallbladder",
      "Localized Malignant Gallbladder Neoplasm",
      "Tumor Of The Gallbladder",
    ],
  },
  {
    ui: "D005715",
    heading: "Gambling",
    tree_numbers: ["F01.145.722.408", "F03.250.400"],
    synonyms: ["Compulsive Gambling", "Pathological Gambling"],
  },
  {
    ui: "D005729",
    heading: "Ganglioneuroma",
    tree_numbers: ["C04.557.465.625.600.355", "C04.557.470.670.355", "C04.557.580.625.600.355"],
    synonyms: ["Gangliocytoma Of Central Nervous System"],
  },
  {
    ui: "D005733",
    heading: "Gangliosidoses",
    tree_numbers: [
      "C10.228.140.163.100.435.825.300",
      "C16.320.565.189.435.825.300",
      "C16.320.565.398.641.803.350",
      "C16.320.565.595.554.825.300",
      "C18.452.132.100.435.825.300",
      "C18.452.584.687.803.350",
      "C18.452.648.189.435.825.300",
      "C18.452.648.398.641.803.350",
      "C18.452.648.595.554.825.300",
    ],
  },
  {
    ui: "D005734",
    heading: "Gangrene",
    tree_numbers: ["C23.550.717.427"],
  },
  {
    ui: "D005736",
    heading: "Gardner Syndrome",
    tree_numbers: [
      "C04.557.470.035.215.100.500",
      "C04.588.274.476.411.307.089.393",
      "C04.700.100.392",
      "C06.301.371.411.307.090.500",
      "C06.405.249.411.307.090.500",
      "C06.405.469.158.356.090.500",
      "C06.405.469.491.307.090.500",
      "C06.405.469.578.249.393",
      "C16.131.077.393",
      "C16.320.700.100.393",
    ],
  },
  {
    ui: "D005738",
    heading: "Gas Gangrene",
    tree_numbers: ["C01.150.252.410.222.440"],
    synonyms: ["Gas Bacillus Infection", "Gas Gangrene", "Myonecrosis"],
  },
  {
    ui: "D005739",
    heading: "Gas Poisoning",
    tree_numbers: ["C25.723.455"],
  },
  {
    ui: "D005747",
    heading: "Gastric Fistula",
    tree_numbers: ["C06.267.375", "C23.300.575.185.375"],
  },
  {
    ui: "D005756",
    heading: "Gastritis",
    tree_numbers: ["C06.405.205.697", "C06.405.748.398"],
    synonyms: ["Erosive Gastritis", "Erosive Gastropathy", "Acute Gastric Mucosal Erosion"],
  },
  {
    ui: "D005757",
    heading: "Gastritis, Atrophic",
    tree_numbers: ["C06.405.205.697.394", "C06.405.748.398.394"],
    synonyms: ["Gastric Atrophy"],
  },
  {
    ui: "D005758",
    heading: "Gastritis, Hypertrophic",
    tree_numbers: ["C06.405.205.697.410", "C06.405.748.398.410"],
    synonyms: [
      "Hypertrophic Gastritis",
      "Familial Giant Hypertrophic Gastritis",
      "Hypertrophic Gastropathy",
      "Giant Rugal Hypertrophy Of Stomach",
      "Menetrier Disease",
    ],
  },
  {
    ui: "D005759",
    heading: "Gastroenteritis",
    tree_numbers: ["C06.405.205"],
    synonyms: ["Infectious Colitis, Enteritis And Gastroenteritis", "Cholera Morbus"],
  },
  {
    ui: "D005761",
    heading: "Gastroenteritis, Transmissible, of Swine",
    tree_numbers: ["C01.925.782.600.550.200.400", "C22.905.469"],
  },
  {
    ui: "D005764",
    heading: "Gastroesophageal Reflux",
    tree_numbers: ["C06.405.117.119.500.484"],
    synonyms: [
      "Gerd",
      "Gastresophageal Reflux",
      "Acid Reflux",
      "Gastroesophageal Reflux",
      "Gastroesophageal Reflux Disease",
      "Gastro-Esophageal Reflux",
      "Gerd - Gastro-Esophageal Reflux Disease",
    ],
  },
  {
    ui: "D005767",
    heading: "Gastrointestinal Diseases",
    tree_numbers: ["C06.405"],
  },
  {
    ui: "D005770",
    heading: "Gastrointestinal Neoplasms",
    tree_numbers: ["C04.588.274.476", "C06.301.371", "C06.405.249"],
    synonyms: ["Gi Tumor", "Gastrointestinal Tract Cancer", "Digestive System Cancer"],
  },
  {
    ui: "D005776",
    heading: "Gaucher Disease",
    tree_numbers: [
      "C10.228.140.163.100.435.825.400",
      "C16.320.565.189.435.825.400",
      "C16.320.565.398.641.803.441",
      "C16.320.565.595.554.825.400",
      "C18.452.132.100.435.825.400",
      "C18.452.584.687.803.441",
      "C18.452.648.189.435.825.400",
      "C18.452.648.398.641.803.441",
      "C18.452.648.595.554.825.400",
    ],
    synonyms: [
      "Acid Beta-Glucosidase Deficiency",
      "Kerasin Thesaurismosis",
      "Glocucerebrosidase Deficiency",
      "Gaucher Disease",
      "Glucosylceramide Beta-Glucosidase Deficiency",
    ],
  },
  {
    ui: "D005831",
    heading: "Genital Diseases, Female",
    tree_numbers: ["C13.351.500"],
  },
  {
    ui: "D005832",
    heading: "Genital Diseases, Male",
    tree_numbers: ["C12.294"],
  },
  {
    ui: "D005833",
    heading: "Genital Neoplasms, Female",
    tree_numbers: ["C04.588.945.418", "C13.351.937.418"],
    synonyms: [
      "Malignant Gynecologic Tumor",
      "Female Reproductive Cancer",
      "Malignant Neoplasm Of Female Genital Organ",
    ],
  },
  {
    ui: "D005834",
    heading: "Genital Neoplasms, Male",
    tree_numbers: ["C04.588.945.440", "C12.294.260", "C12.758.409"],
    synonyms: [
      "Neoplasm Of Male Genital Organ",
      "Tumor Of Male Reproductive System",
      "Malignant Tumor Of Male Reproductive System",
      "Male Reproductive System Neoplasm",
      "Malignant Tumor Of Male Genital Organ",
      "Malignant Neoplasm Of Male Genital Organ Or Tract",
      "Malignant Neoplasm Of Male Genital Organs",
      "Male Genital Cancer",
      "Malignant Neoplasm Of Male Genital Organ",
    ],
  },
  {
    ui: "D005847",
    heading: "Geotrichosis",
    tree_numbers: ["C01.150.703.392"],
    synonyms: ["Geotrichosis"],
  },
  {
    ui: "D005862",
    heading: "Gerstmann Syndrome",
    tree_numbers: ["C10.597.606.762.100.300", "C23.888.592.604.764.100.300", "F01.700.750.100.300"],
    synonyms: ["Aphasia-Angular Gyrus Syndrome"],
  },
  {
    ui: "D005870",
    heading: "Giant Cell Tumors",
    tree_numbers: ["C04.557.450.565.380"],
  },
  {
    ui: "D005871",
    heading: "Castleman Disease",
    tree_numbers: ["C15.604.515.245", "C20.683.515.250"],
    synonyms: [
      "Angiofollicular Lymph Hyperplasia",
      "Angiofollicular Lymph Node Hyperplasia",
      "Giant Lymph Node Hyperplasia",
      "Lymphoid Hamartoma",
    ],
  },
  {
    ui: "D005873",
    heading: "Giardiasis",
    tree_numbers: ["C01.610.432.481", "C01.610.752.400", "C06.405.469.452.481"],
    synonyms: ["Infection By Giardia Lamblia", "Beaver Feaver"],
  },
  {
    ui: "D005877",
    heading: "Gigantism",
    tree_numbers: ["C05.116.099.492", "C05.116.132.479", "C19.700.355.528"],
  },
  {
    ui: "D005878",
    heading: "Gilbert Disease",
    tree_numbers: ["C16.320.565.300.528", "C18.452.648.300.528"],
    synonyms: [
      "Gilbert's Syndrome",
      "Constitutional Hyperbilirubinemia",
      "Gilbert-Meulengracht Syndrome",
      "Hereditary Nonhemolytic Jaundice",
      "Gilbert's Disease",
    ],
  },
  {
    ui: "D005879",
    heading: "Tourette Syndrome",
    tree_numbers: [
      "C10.228.140.079.898",
      "C10.228.662.825.800",
      "C10.574.500.850",
      "C16.320.400.820",
      "F03.625.992.850",
    ],
    synonyms: ["Tourette Syndrome", "Motor-Verbal Tic Disorder", "Psychogenic Tics", "Guinon's Disease"],
  },
  {
    ui: "D005882",
    heading: "Gingival Diseases",
    tree_numbers: ["C07.465.714.258"],
    synonyms: ["Gum Disease"],
  },
  {
    ui: "D005884",
    heading: "Gingival Hemorrhage",
    tree_numbers: ["C07.465.625.446", "C07.465.714.258.250", "C23.550.414.922.500"],
  },
  {
    ui: "D005885",
    heading: "Gingival Hyperplasia",
    tree_numbers: ["C07.465.714.258.428.250"],
  },
  {
    ui: "D005886",
    heading: "Gingival Hypertrophy",
    tree_numbers: ["C07.465.714.258.428.260"],
    synonyms: ["Hypertrophy Of Gingivae"],
  },
  {
    ui: "D005887",
    heading: "Gingival Neoplasms",
    tree_numbers: ["C04.588.443.591.402", "C07.465.530.402", "C07.465.714.258.409"],
    synonyms: ["Congenital Epulis", "Congenital Epulides"],
  },
  {
    ui: "D005888",
    heading: "Gingival Pocket",
    tree_numbers: ["C01.408.360", "C07.465.714.258.480.360"],
  },
  {
    ui: "D005889",
    heading: "Gingival Recession",
    tree_numbers: ["C07.465.714.258.447", "C07.465.714.354.625"],
    synonyms: [
      "Gingival Recession, Localized",
      "Minimal Gingival Recession",
      "Localized Gingival Recession",
      "Moderate Gingival Recession",
      "Severe Gingival Recession",
      "Gingival Recession",
    ],
  },
  {
    ui: "D005891",
    heading: "Gingivitis",
    tree_numbers: ["C01.408", "C07.465.714.258.480"],
    synonyms: ["Chronic Gingivitis", "Acute Gingivitis"],
  },
  {
    ui: "D005892",
    heading: "Gingivitis, Necrotizing Ulcerative",
    tree_numbers: ["C01.150.252.400.388.350.350", "C01.408.446", "C07.465.714.258.480.446"],
    synonyms: [
      "Acute Necrotizing Ulcerative Gingivostomatitis",
      "Acute Ulceromembranous Gingivitis",
      "Vincent's Angina",
      "Vincent's Disease",
      "Trench Mouth",
      "Vincent's Angina",
      "Trench Mouth",
      "Acute Necrotizing Ulcerative Gingivitis",
      "Vincent Angina",
      "Anug",
      "Acute Necrotising Ulcerative Gingivitis",
      "Vincent's Angina - Pharyngitis",
      "Vincent's Infection, Any Site",
      "Angina - Vincents",
      "Early Acute Necrotising Gingivitis",
      "Acute Necrotising Ulcerative Gingivostomatitis",
    ],
  },
  {
    ui: "D005896",
    heading: "Glanders",
    tree_numbers: ["C01.150.252.400.170.400", "C22.488.409"],
    synonyms: ["Infection Due To Pseudomonas Mallei", "Farcy Pipes"],
  },
  {
    ui: "D005901",
    heading: "Glaucoma",
    tree_numbers: ["C11.525.381"],
  },
  {
    ui: "D005902",
    heading: "Glaucoma, Open-Angle",
    tree_numbers: ["C11.525.381.407"],
    synonyms: ["Wide-Angle Glaucoma", "Open Angle Glaucoma", "Glaucoma Simplex", "Pigmentary Glaucoma"],
  },
  {
    ui: "D005909",
    heading: "Glioblastoma",
    tree_numbers: ["C04.557.465.625.600.380.080.335", "C04.557.470.670.380.080.335", "C04.557.580.625.600.380.080.335"],
    synonyms: ["Monstrocellular Sarcoma"],
  },
  {
    ui: "D005910",
    heading: "Glioma",
    tree_numbers: ["C04.557.465.625.600.380", "C04.557.470.670.380", "C04.557.580.625.600.380"],
    synonyms: [
      "Neuroglial Tumor",
      "Glial Cell Tumor",
      "Malignant Glioma",
      "Glioma, Malignant",
      "Malignant Neuroglial Tumor",
    ],
  },
  {
    ui: "D005911",
    heading: "Gliosis",
    tree_numbers: ["C23.550.369"],
  },
  {
    ui: "D005918",
    heading: "Glomus Tumor",
    tree_numbers: ["C04.557.645.350"],
    synonyms: ["Glomus Tumour", "Glomus Neoplasm"],
  },
  {
    ui: "D005921",
    heading: "Glomerulonephritis",
    tree_numbers: ["C12.777.419.570.363", "C13.351.968.419.570.363"],
  },
  {
    ui: "D005922",
    heading: "Glomerulonephritis, IGA",
    tree_numbers: ["C12.777.419.570.363.608", "C13.351.968.419.570.363.608", "C20.111.525"],
    synonyms: [
      "Iga Glomerulonephritis",
      "Primary Iga Nephropathy",
      "Berger's Iga Or Igg Nephropathy",
      "Iga Nephropathy",
      "Focal Glomerulonephritis",
      "Segmental Glomerulonephritis",
    ],
  },
  {
    ui: "D005923",
    heading: "Glomerulosclerosis, Focal Segmental",
    tree_numbers: ["C12.777.419.570.363.660", "C13.351.968.419.570.363.640"],
    synonyms: ["Fsgs", "Focal Glomerulosclerosis", "Fgs", "Focal Glomerular Sclerosis"],
  },
  {
    ui: "D005925",
    heading: "Glomus Jugulare Tumor",
    tree_numbers: ["C04.557.465.625.650.700.705.340", "C04.557.580.625.650.700.705.340"],
  },
  {
    ui: "D005926",
    heading: "Glossalgia",
    tree_numbers: ["C07.465.910.280", "C23.888.592.612.414"],
  },
  {
    ui: "D005928",
    heading: "Glossitis",
    tree_numbers: ["C07.465.910.363"],
  },
  {
    ui: "D005929",
    heading: "Glossitis, Benign Migratory",
    tree_numbers: ["C07.465.910.363.447"],
    synonyms: ["Pityriasis Linguae", "Benign Migratory Glossitis", "Glossitis Areata Exfoliativa"],
  },
  {
    ui: "D005935",
    heading: "Glucagonoma",
    tree_numbers: [
      "C04.557.470.200.025.290.750",
      "C04.588.274.761.500.249",
      "C04.588.322.475.500.249",
      "C06.301.761.500.249",
      "C06.689.667.500.249",
      "C19.344.421.500.249",
    ],
  },
  {
    ui: "D005953",
    heading: "Glycogen Storage Disease Type I",
    tree_numbers: ["C16.320.565.202.449.448", "C18.452.648.202.449.448"],
    synonyms: [
      "Glycogen Storage Disease Type I",
      "Von Gierke Disease",
      "Deficiency Of Glucose-6-Phosphatase",
      "Von Gierke's Disease",
      "Glycogen Storage Disease, Type I",
      "Glycogenosis Type I",
    ],
  },
  {
    ui: "D005955",
    heading: "Glucosephosphate Dehydrogenase Deficiency",
    tree_numbers: ["C15.378.071.141.150.480", "C16.320.070.480", "C16.320.565.202.402", "C18.452.648.202.402"],
    synonyms: ["Deficiency Of G-6pd", "Glucose-6-Phosphate Dehydrogenase Deficiency"],
  },
  {
    ui: "D006008",
    heading: "Glycogen Storage Disease",
    tree_numbers: ["C16.320.565.202.449", "C18.452.648.202.449"],
    synonyms: ["Glycogenosis"],
  },
  {
    ui: "D006009",
    heading: "Glycogen Storage Disease Type II",
    tree_numbers: [
      "C10.228.140.163.100.435.340",
      "C16.320.565.189.435.340",
      "C16.320.565.202.449.500",
      "C16.320.565.595.554.340",
      "C18.452.132.100.435.340",
      "C18.452.648.189.435.340",
      "C18.452.648.202.449.500",
      "C18.452.648.595.554.340",
    ],
    synonyms: [
      "Pompe's Disease",
      "Glycogen Storage Disease, Type Ii",
      "Deficiency Of Glucoamylase",
      "Generalized Glycogenosis",
      "Glycogenosis, Type 2",
      "Glycogen Storage Disease Type Ii",
      "Deficiency Of Maltase",
      "Acid Maltase Deficiency",
      "Lysosomal Alpha-1,4-Glucosidase Deficiency",
    ],
  },
  {
    ui: "D006010",
    heading: "Glycogen Storage Disease Type III",
    tree_numbers: ["C16.320.565.202.449.520", "C18.452.648.202.449.520"],
    synonyms: [
      "Deficiency Of Dextrin",
      "Deficiency Of Debranching Enzyme",
      "Glycogen Storage Disease, Type Iii",
      "Amylo 1,6 Glucosidase Deficiency",
    ],
  },
  {
    ui: "D006011",
    heading: "Glycogen Storage Disease Type IV",
    tree_numbers: ["C16.320.565.202.449.540", "C18.452.648.202.449.540"],
    synonyms: [
      "Amylopectinosis",
      "Glycogen Storage Disease, Type Iv",
      "Branching-Transferase Deficiency Glycogenosis",
      "Brancher Deficiency Glycogenosis",
      "Deficiency Of 1,4-Alpha-Glucan Branching Enzyme",
    ],
  },
  {
    ui: "D006012",
    heading: "Glycogen Storage Disease Type V",
    tree_numbers: ["C16.320.565.202.449.560", "C18.452.648.202.449.560"],
    synonyms: [
      "Myophosphorylase Deficiency",
      "Glycogen Storage Disease, Type V",
      "Mcardle's Disease",
      "Glycogen Storage Disease Type V",
    ],
  },
  {
    ui: "D006013",
    heading: "Glycogen Storage Disease Type VI",
    tree_numbers: ["C16.320.565.202.449.580", "C18.452.648.202.449.580"],
    synonyms: [
      "Glycogen Storage Disease Type Vi",
      "Hepatic Glycogen Phosphorylase Deficiency",
      "Hers' Disease",
      "Hepatophosphorylase Deficiency Glycogenosis",
    ],
  },
  {
    ui: "D006014",
    heading: "Glycogen Storage Disease Type VII",
    tree_numbers: [
      "C05.651.534.500.149",
      "C10.668.491.175.500.112",
      "C16.320.565.202.449.600",
      "C16.320.577.149",
      "C18.452.648.202.449.600",
    ],
    synonyms: [
      "Glycogen Storage Disease, Type Vii",
      "Phosphofructokinase Myopathy",
      "Muscle Phosphofructokinase Deficiency",
      "Glycogen Storage Disease Type Vii",
    ],
  },
  {
    ui: "D006015",
    heading: "Glycogen Storage Disease Type VIII",
    tree_numbers: ["C16.320.322.217", "C16.320.565.202.449.620", "C18.452.648.202.449.620"],
    synonyms: [
      "Hepatic Glycogen Phosphorylase Kinase Deficiency",
      "Glycogenosis Type Viii",
      "Glycogen Storage Disease Type Viii",
      "Glycogen Storage Disease Type Viii",
    ],
  },
  {
    ui: "D006029",
    heading: "Glycosuria",
    tree_numbers: ["C12.777.934.363", "C13.351.968.934.363", "C18.452.394.937"],
  },
  {
    ui: "D006030",
    heading: "Glycosuria, Renal",
    tree_numbers: [
      "C12.777.419.815.532",
      "C12.777.934.363.450",
      "C13.351.968.419.815.532",
      "C13.351.968.934.363.450",
      "C16.320.831.532",
      "C18.452.394.937.450",
    ],
    synonyms: ["Renal Diabetes"],
  },
  {
    ui: "D006042",
    heading: "Goiter",
    tree_numbers: ["C19.874.283"],
    synonyms: ["Goitre"],
  },
  {
    ui: "D006043",
    heading: "Goiter, Endemic",
    tree_numbers: ["C19.874.283.300"],
    synonyms: ["Simple Goitre", "Iodine-Deficiency-Related Endemic Goitre"],
  },
  {
    ui: "D006044",
    heading: "Goiter, Nodular",
    tree_numbers: ["C19.874.283.501"],
    synonyms: ["Goiter, Nodular", "Nodular Goitre"],
  },
  {
    ui: "D006045",
    heading: "Goiter, Substernal",
    tree_numbers: ["C19.874.283.601"],
    synonyms: [
      "Retrosternal Thyroid Goitre",
      "Substernal Goitre",
      "Substernal Goitre",
      "Retrosternal Thyroid Goiter",
      "Substernal Goiter",
    ],
  },
  {
    ui: "D006053",
    heading: "Goldenhar Syndrome",
    tree_numbers: ["C05.116.099.370.231.576.410", "C05.660.207.231.576.410", "C16.131.621.207.231.576.410"],
    synonyms: [
      "Facio-Auriculo-Vertebral Spectrum",
      "Hemifacial Microsomia",
      "Otomandibular Dysostosis",
      "First Arch Syndrome",
      "First And Second Branchial Arch Syndrome",
      "Oav (Oculoauriculovertebral) Dysplasia",
    ],
  },
  {
    ui: "D006058",
    heading: "Gonadal Disorders",
    tree_numbers: ["C19.391"],
  },
  {
    ui: "D006059",
    heading: "Gonadal Dysgenesis",
    tree_numbers: ["C12.706.316.309", "C13.351.875.253.309", "C16.131.939.316.309", "C19.391.119.309"],
    synonyms: ["Gonadal Dysgenesis Syndrome"],
  },
  {
    ui: "D006060",
    heading: "Gonadal Dysgenesis, Mixed",
    tree_numbers: [
      "C12.706.316.309.391",
      "C12.706.316.795.249",
      "C13.351.875.253.309.391",
      "C13.351.875.253.795.249",
      "C16.131.260.830.835.249",
      "C16.131.939.316.309.391",
      "C16.131.939.316.795.249",
      "C16.320.180.830.835.249",
      "C19.391.119.309.391",
      "C19.391.119.795.249",
    ],
  },
  {
    ui: "D006061",
    heading: "Gonadal Dysgenesis, 46,XY",
    tree_numbers: [
      "C12.706.316.096.687",
      "C12.706.316.309.388",
      "C13.351.875.253.096.687",
      "C13.351.875.253.309.388",
      "C16.131.939.316.096.687",
      "C16.131.939.316.309.388",
      "C19.391.119.096.687",
      "C19.391.119.309.388",
    ],
    synonyms: ["46 Xy Gonadal Dysgenesis", "Swyer Syndrome", "Pure Gonadal Dysgenesis 46,xy"],
  },
  {
    ui: "D006069",
    heading: "Gonorrhea",
    tree_numbers: [
      "C01.150.252.400.625.275",
      "C01.150.252.734.401",
      "C01.221.812.281.401",
      "C01.778.281.401",
      "C12.294.668.281.401",
      "C13.351.500.711.281.401",
    ],
    synonyms: [
      "Chronic Gonococcal Infectious Disease Of Upper Genitourinary Tract",
      "Chronic Gonococcal Infectious Disease Of Lower Genitourinary Tract",
    ],
  },
  {
    ui: "D006073",
    heading: "Gout",
    tree_numbers: ["C05.550.114.423", "C05.550.354.500", "C05.799.414", "C16.320.565.798.368", "C18.452.648.798.368"],
    synonyms: ["Gouty Arthritis", "Gouty Arthropathy", "Articular Gout"],
  },
  {
    ui: "D006083",
    heading: "Graft Occlusion, Vascular",
    tree_numbers: ["C23.550.767.400"],
  },
  {
    ui: "D006086",
    heading: "Graft vs Host Disease",
    tree_numbers: ["C20.452"],
  },
  {
    ui: "D006099",
    heading: "Granuloma",
    tree_numbers: ["C15.604.515.292", "C23.550.382"],
  },
  {
    ui: "D006100",
    heading: "Granuloma Inguinale",
    tree_numbers: [
      "C01.150.252.400.310.417",
      "C01.150.252.734.451",
      "C01.150.252.819.360",
      "C01.221.812.281.451",
      "C01.778.281.451",
      "C01.800.720.360",
      "C12.294.668.281.451",
      "C13.351.500.711.281.451",
      "C17.800.838.765.360",
    ],
    synonyms: ["Granuloma Inguinale", "Granuloma Inguinale", "Donovanosis", "Pudendal Ulcer", "Pudendal Ulcer"],
  },
  {
    ui: "D006101",
    heading: "Granuloma, Giant Cell",
    tree_numbers: ["C05.500.368", "C07.320.391", "C07.465.714.258.557", "C23.550.382.468"],
    synonyms: [
      "Central Giant Cell (Reparative) Granuloma",
      "Central Giant Cell Granuloma",
      "Central Giant Cell Reparative Granuloma Of Jaw",
    ],
  },
  {
    ui: "D006102",
    heading: "Granuloma, Laryngeal",
    tree_numbers: ["C08.280.400", "C08.360.232", "C08.618.846.414", "C09.400.232", "C23.550.382.968.500"],
  },
  {
    ui: "D006103",
    heading: "Granuloma, Lethal Midline",
    tree_numbers: ["C08.460.393", "C09.603.393"],
    synonyms: ["Midfacial Necrotising Lesion", "Malignant Granuloma Of Face"],
  },
  {
    ui: "D006104",
    heading: "Granuloma, Plasma Cell",
    tree_numbers: ["C23.550.382.875"],
  },
  {
    ui: "D006105",
    heading: "Granulomatous Disease, Chronic",
    tree_numbers: ["C15.378.553.774.535", "C16.320.322.233", "C20.673.774.535"],
    synonyms: ["Quie Syndrome", "Bridges-Good Syndrome", "Congenital Dysphagocytosis", "Cgd"],
  },
  {
    ui: "D006106",
    heading: "Granulosa Cell Tumor",
    tree_numbers: [
      "C04.557.475.750.656",
      "C04.588.322.455.398",
      "C13.351.500.056.630.705.398",
      "C13.351.937.418.685.398",
      "C19.344.410.398",
      "C19.391.630.705.398",
    ],
    synonyms: [
      "Granulosa Cell Neoplasm",
      "Granulosa Cell Tumor, Adult Type",
      "Malignant Granulosa Cell Neoplasm",
      "Granulosa Cell Tumour, Sarcomatoid",
    ],
  },
  {
    ui: "D006111",
    heading: "Graves Disease",
    tree_numbers: ["C11.675.349.500", "C19.874.283.605", "C19.874.397.370", "C20.111.555"],
    synonyms: ["Exophthalmic Goiter", "Graves Disease", "Grave's Disease"],
  },
  {
    ui: "D006130",
    heading: "Growth Disorders",
    tree_numbers: ["C23.550.393"],
  },
  {
    ui: "D006175",
    heading: "Gynatresia",
    tree_numbers: ["C13.351.500.320"],
  },
  {
    ui: "D006177",
    heading: "Gynecomastia",
    tree_numbers: ["C17.800.090.875"],
    synonyms: ["Breast Engorgement In Newborn", "Neonatal Gynaecomastia"],
  },
  {
    ui: "D006188",
    heading: "Haemonchiasis",
    tree_numbers: ["C01.610.335.508.700.775.825.400"],
  },
  {
    ui: "D006192",
    heading: "Haemophilus Infections",
    tree_numbers: ["C01.150.252.400.700.433"],
  },
  {
    ui: "D006201",
    heading: "Hair Diseases",
    tree_numbers: ["C17.800.329"],
  },
  {
    ui: "D006209",
    heading: "Halitosis",
    tree_numbers: ["C23.888.821.475"],
  },
  {
    ui: "D006210",
    heading: "Hallermann's Syndrome",
    tree_numbers: ["C05.116.099.370.231.427", "C05.660.207.231.427", "C16.131.621.207.231.427"],
    synonyms: ["Hallerman - Streiff Syndrome", "Hallermann's Syndrome", "Francois Dyscephalic Syndrome"],
  },
  {
    ui: "D006211",
    heading: "Pantothenate Kinase-Associated Neurodegeneration",
    tree_numbers: [
      "C10.228.140.079.800",
      "C10.228.140.744.320",
      "C10.228.662.575",
      "C10.574.500.700",
      "C16.320.400.650",
    ],
    synonyms: [
      "Brain Iron Accumulation Type I Syndrome",
      "Nbia1",
      "Hallervorden-Spatz Disease",
      "Hallervorden-Spatz Syndrome",
      "Pigmentary Pallidal Degeneration",
      "Neurodegeneration With Brain Iron Accumulation 1",
    ],
  },
  {
    ui: "D006212",
    heading: "Hallucinations",
    tree_numbers: ["C10.597.606.762.300", "C23.888.592.604.764.300", "F01.700.750.300"],
  },
  {
    ui: "D006215",
    heading: "Hallux Valgus",
    tree_numbers: ["C05.330.610"],
  },
  {
    ui: "D006222",
    heading: "Hamartoma",
    tree_numbers: ["C04.445"],
  },
  {
    ui: "D006223",
    heading: "Hamartoma Syndrome, Multiple",
    tree_numbers: ["C04.445.435", "C04.651.435", "C04.700.435", "C16.320.700.435"],
    synonyms: [
      "Cowden Syndrome 1",
      "Ruvalcaba-Myhre-Smith Syndrome",
      "Riley-Smith Syndrome",
      "Bannayan-Zonana Syndrome",
    ],
  },
  {
    ui: "D006226",
    heading: "Hand Deformities",
    tree_numbers: ["C05.390"],
  },
  {
    ui: "D006227",
    heading: "Hand Deformities, Acquired",
    tree_numbers: ["C05.390.110"],
  },
  {
    ui: "D006228",
    heading: "Hand Deformities, Congenital",
    tree_numbers: ["C05.390.408", "C05.660.585.988.425", "C16.131.621.585.988.500"],
  },
  {
    ui: "D006229",
    heading: "Hand Dermatoses",
    tree_numbers: ["C17.800.338"],
  },
  {
    ui: "D006230",
    heading: "Hand Injuries",
    tree_numbers: ["C26.448"],
  },
  {
    ui: "D006232",
    heading: "Hand, Foot and Mouth Disease",
    tree_numbers: ["C01.925.782.687.359.213.331"],
    synonyms: ["Vesicular Stomatitis And Exanthem"],
  },
  {
    ui: "D006250",
    heading: "Hartnup Disease",
    tree_numbers: [
      "C10.228.140.163.100.355",
      "C12.777.419.815.885.457",
      "C13.351.968.419.815.885.625",
      "C16.320.565.151.355",
      "C16.320.565.189.355",
      "C16.320.831.885.457",
      "C18.452.132.100.355",
      "C18.452.648.151.355",
      "C18.452.648.189.355",
    ],
    synonyms: [
      "Neutral Amino Acid Transport Defect",
      "Deficiency Of Tryptophan Oxygenase",
      "Neutral 1 Amino Acid Transport Defect",
    ],
  },
  {
    ui: "D006255",
    heading: "Rhinitis, Allergic, Seasonal",
    tree_numbers: ["C08.460.799.315.750", "C08.674.453.750", "C09.603.799.315.750", "C20.543.480.680.443.750"],
  },
  {
    ui: "D006258",
    heading: "Head and Neck Neoplasms",
    tree_numbers: ["C04.588.443"],
    synonyms: ["Head/neck Neoplasm", "Tumor Of Head And Neck", "Head And Neck Tumours", "Head And Neck Neoplasm"],
  },
  {
    ui: "D006259",
    heading: "Craniocerebral Trauma",
    tree_numbers: ["C10.900.300", "C26.915.300"],
  },
  {
    ui: "D006261",
    heading: "Headache",
    tree_numbers: ["C23.888.592.612.441"],
  },
  {
    ui: "D006311",
    heading: "Hearing Disorders",
    tree_numbers: ["C09.218.458", "C10.597.751.418", "C23.888.592.763.393"],
  },
  {
    ui: "D006312",
    heading: "Hearing Loss, Bilateral",
    tree_numbers: ["C09.218.458.341.374", "C10.597.751.418.341.374", "C23.888.592.763.393.341.374"],
  },
  {
    ui: "D006313",
    heading: "Hearing Loss, Central",
    tree_numbers: [
      "C09.218.458.341.887.432",
      "C09.218.807.186.432",
      "C10.228.140.068.432",
      "C10.597.751.418.341.887.432",
      "C23.888.592.763.393.341.887.432",
    ],
  },
  {
    ui: "D006314",
    heading: "Hearing Loss, Conductive",
    tree_numbers: ["C09.218.458.341.562", "C10.597.751.418.341.562", "C23.888.592.763.393.341.562"],
  },
  {
    ui: "D006315",
    heading: "Hearing Loss, Functional",
    tree_numbers: ["C09.218.458.341.750", "C10.597.751.418.341.750", "C23.888.592.763.393.341.750", "F01.145.126.875"],
  },
  {
    ui: "D006316",
    heading: "Hearing Loss, High-Frequency",
    tree_numbers: ["C09.218.458.341.812", "C10.597.751.418.341.812", "C23.888.592.763.393.341.812"],
  },
  {
    ui: "D006317",
    heading: "Hearing Loss, Noise-Induced",
    tree_numbers: ["C09.218.458.341.887.460", "C10.597.751.418.341.887.460", "C23.888.592.763.393.341.887.460"],
  },
  {
    ui: "D006319",
    heading: "Hearing Loss, Sensorineural",
    tree_numbers: ["C09.218.458.341.887", "C10.597.751.418.341.887", "C23.888.592.763.393.341.887"],
    synonyms: [
      "High Frequency Hearing Loss",
      "Sensory Hearing Loss",
      "Perceptive Deafness",
      "Perceptive Hearing Loss Or Deafness",
      "High Frequency Deafness",
      "Sensorineural Deafness",
      "High-Frequency Hearing Loss",
      "Perceptive Hearing Loss",
      "Central Hearing Loss",
    ],
  },
  {
    ui: "D006322",
    heading: "Heart Aneurysm",
    tree_numbers: ["C14.280.358", "C14.907.055.608"],
    synonyms: ["Cardiac Aneurysm"],
  },
  {
    ui: "D006323",
    heading: "Heart Arrest",
    tree_numbers: ["C14.280.383"],
    synonyms: ["Circulatory Arrest", "Cardiopulmonary Arrest"],
  },
  {
    ui: "D006327",
    heading: "Heart Block",
    tree_numbers: ["C14.280.067.558", "C14.280.123.500", "C23.550.073.425"],
  },
  {
    ui: "D006330",
    heading: "Heart Defects, Congenital",
    tree_numbers: ["C14.240.400", "C14.280.400", "C16.131.240.400"],
    synonyms: [
      "Heart Malformation",
      "Heart Defect",
      "Congenital Heart Defect",
      "Congenital Anomaly Of Heart",
      "Congenital Heart Defects",
    ],
  },
  {
    ui: "D006331",
    heading: "Heart Diseases",
    tree_numbers: ["C14.280"],
  },
  {
    ui: "D006332",
    heading: "Cardiomegaly",
    tree_numbers: ["C14.280.195", "C23.300.775.250"],
  },
  {
    ui: "D006333",
    heading: "Heart Failure",
    tree_numbers: ["C14.280.434"],
    synonyms: ["Weak Heart", "Cardiac Failure Congestive", "Congestive Heart Disease", "Chf"],
  },
  {
    ui: "D006335",
    heading: "Heart Injuries",
    tree_numbers: ["C26.891.375"],
  },
  {
    ui: "D006337",
    heading: "Heart Murmurs",
    tree_numbers: ["C23.888.447"],
  },
  {
    ui: "D006338",
    heading: "Heart Neoplasms",
    tree_numbers: ["C04.588.894.309", "C14.280.459"],
    synonyms: ["Cardiac Tumor", "Malignant Neoplasm Of Heart", "Tumour Of Heart", "Malignant Cardiac Tumor"],
  },
  {
    ui: "D006341",
    heading: "Heart Rupture",
    tree_numbers: ["C14.280.470"],
  },
  {
    ui: "D006342",
    heading: "Heart Rupture, Post-Infarction",
    tree_numbers: ["C14.280.470.475"],
  },
  {
    ui: "D006343",
    heading: "Heart Septal Defects",
    tree_numbers: ["C14.240.400.560", "C14.280.400.560", "C16.131.240.400.560"],
    synonyms: ["Congenital Septal Defect Of Heart", "Septal Defect", "Cardiac Septal Defects", "Septal Defect"],
  },
  {
    ui: "D006344",
    heading: "Heart Septal Defects, Atrial",
    tree_numbers: ["C14.240.400.560.375", "C14.280.400.560.375", "C16.131.240.400.560.375"],
    synonyms: [
      "Auricular Septal Defect",
      "Interauricular Septal Defect",
      "Atrioseptal Defect",
      "Atrial Septal Defect",
      "Interatrial Septal Defect",
      "Congenital Atrial Septal Defect",
    ],
  },
  {
    ui: "D006345",
    heading: "Heart Septal Defects, Ventricular",
    tree_numbers: ["C14.240.400.560.540", "C14.280.400.560.540", "C16.131.240.400.560.540"],
    synonyms: ["Interventricular Septal Defect", "Ventricular Septal Abnormality"],
  },
  {
    ui: "D006349",
    heading: "Heart Valve Diseases",
    tree_numbers: ["C14.280.484"],
    synonyms: ["Valvular Heart Disease"],
  },
  {
    ui: "D006356",
    heading: "Heartburn",
    tree_numbers: ["C23.888.821.525"],
  },
  {
    ui: "D006357",
    heading: "Heartwater Disease",
    tree_numbers: ["C01.150.252.400.054.750.500", "C01.150.252.400.285.500", "C01.920.930.300.500", "C22.434"],
  },
  {
    ui: "D006359",
    heading: "Heat Exhaustion",
    tree_numbers: ["C26.522.250"],
  },
  {
    ui: "D006362",
    heading: "Heavy Chain Disease",
    tree_numbers: ["C15.378.147.780.490", "C15.604.515.435", "C20.683.780.490"],
  },
  {
    ui: "D006373",
    heading: "Helminthiasis",
    tree_numbers: ["C01.610.335"],
    synonyms: ["Worm Infection", "Helminthiasis", "Helminth Infection", "Helminthosis"],
  },
  {
    ui: "D006374",
    heading: "Helminthiasis, Animal",
    tree_numbers: ["C01.610.335.349", "C01.610.701.377", "C22.674.377"],
  },
  {
    ui: "D006390",
    heading: "Hemangioendothelioma",
    tree_numbers: ["C04.557.645.375.370"],
  },
  {
    ui: "D006391",
    heading: "Hemangioma",
    tree_numbers: ["C04.557.645.375"],
    synonyms: ["Epithelioid Hemangioma", "Angiolymphoid Hyperplasia With Eosinophilia", "Epithelioid Haemangioma"],
  },
  {
    ui: "D006392",
    heading: "Hemangioma, Cavernous",
    tree_numbers: ["C04.557.645.375.385", "C14.907.454.385", "C15.378.463.515.385"],
    synonyms: ["Cavernous Haemangioma", "Cavernoma", "Cavernous Hemangioma"],
  },
  {
    ui: "D006393",
    heading: "Hemangiopericytoma",
    tree_numbers: ["C04.557.645.380"],
    synonyms: ["Malignant Hemangiopericytoma", "Hemangiopericytoma, Malignant", "Haemangiopericytic Meningioma"],
  },
  {
    ui: "D006394",
    heading: "Hemangiosarcoma",
    tree_numbers: ["C04.557.450.795.390", "C04.557.645.390"],
    synonyms: ["Hemangiosarcoma"],
  },
  {
    ui: "D006395",
    heading: "Hemarthrosis",
    tree_numbers: ["C05.550.459", "C23.550.414.794"],
    synonyms: [
      "Hemarthrosis Involving Forearm",
      "Hemarthrosis Of The Forearm",
      "Hemarthrosis Involving Shoulder Region",
      "Hemarthrosis Of The Shoulder Region",
      "Haemarthrosis Of Shoulder Joint",
      "Hemarthrosis Involving Pelvic Region And Thigh",
      "Hemarthrosis Of The Pelvic Region And Thigh",
      "Hemarthrosis Of Shoulder Region",
      "Hemarthrosis Of Shoulder",
      "Hemarthrosis Of The Lower Leg",
      "Hemarthrosis Of Forearm",
      "Hemarthrosis Involving Lower Leg",
      "Hemarthrosis Involving Ankle And Foot",
      "Hemarthrosis Of The Ankle And/or Foot",
      "Hemarthrosis Of The Ankle And Foot",
      "Hemarthrosis Involving Upper Arm",
      "Hemarthrosis Of Lower Leg",
      "Hemarthrosis Of Hand",
      "Hemarthrosis Of The Upper Arm",
      "Hemarthrosis Of Ankle And/or Foot",
      "Haemarthrosis Of The Pelvic Region And Thigh",
      "Hemarthrosis Of Upper Arm",
      "Haemarthrosis Of The Ankle And Foot",
      "Hemarthrosis Involving Hand",
      "Hemarthrosis Of The Hand",
    ],
  },
  {
    ui: "D006396",
    heading: "Hematemesis",
    tree_numbers: ["C06.405.227.400", "C23.550.414.788.400", "C23.888.821.937.019"],
  },
  {
    ui: "D006398",
    heading: "Hematocele",
    tree_numbers: ["C12.294.287", "C23.550.414.817"],
    synonyms: ["Male Hematocele", "Hematocele"],
  },
  {
    ui: "D006399",
    heading: "Hematocolpos",
    tree_numbers: ["C13.351.500.894.300"],
  },
  {
    ui: "D006402",
    heading: "Hematologic Diseases",
    tree_numbers: ["C15.378"],
    synonyms: [
      "Haematopoietic System Disease",
      "Disease Of Haematopoietic System",
      "Disease Of The Blood And Blood-Forming Organs",
      "Blood Disorder",
      "Blood Disease",
      "Blood Dyscrasia",
      "Hematological Disease",
      "Disease Of Hematopoietic System",
    ],
  },
  {
    ui: "D006406",
    heading: "Hematoma",
    tree_numbers: ["C23.550.414.838"],
  },
  {
    ui: "D006407",
    heading: "Hematoma, Epidural, Cranial",
    tree_numbers: [
      "C10.228.140.300.535.450.300",
      "C10.900.300.837.300",
      "C14.907.253.573.400.400",
      "C23.550.414.838.349",
      "C23.550.414.913.400",
      "C26.915.300.490.400",
    ],
  },
  {
    ui: "D006408",
    heading: "Hematoma, Subdural",
    tree_numbers: [
      "C10.228.140.300.535.450.400",
      "C10.900.300.837.600",
      "C14.907.253.573.400.450",
      "C23.550.414.838.700",
      "C23.550.414.913.700",
      "C26.915.300.490.450",
    ],
  },
  {
    ui: "D006409",
    heading: "Hematometra",
    tree_numbers: ["C13.351.500.852.495"],
    synonyms: ["Hematometra"],
  },
  {
    ui: "D006417",
    heading: "Hematuria",
    tree_numbers: ["C12.777.934.442", "C13.351.968.934.442", "C23.550.414.849"],
  },
  {
    ui: "D006423",
    heading: "Hemianopsia",
    tree_numbers: ["C10.597.751.941.512", "C11.966.075.500", "C23.888.592.763.941.512"],
  },
  {
    ui: "D006425",
    heading: "Hemic and Lymphatic Diseases",
    tree_numbers: ["C15"],
  },
  {
    ui: "D006429",
    heading: "Hemiplegia",
    tree_numbers: ["C10.597.622.295", "C23.888.592.636.312"],
    synonyms: ["Infantile Hemiplegia", "Postnatal Infantile Hemiplegia"],
  },
  {
    ui: "D006431",
    heading: "Hemobilia",
    tree_numbers: ["C23.550.414.864"],
  },
  {
    ui: "D006432",
    heading: "Hemochromatosis",
    tree_numbers: ["C16.320.565.618.337", "C18.452.565.500.480", "C18.452.648.618.337"],
    synonyms: ["Diabetes Bronze", "Haemochromatosis", "Iron Storage Disorder"],
  },
  {
    ui: "D006445",
    heading: "Hemoglobin C Disease",
    tree_numbers: ["C15.378.071.141.150.490", "C15.378.420.463", "C16.320.070.490", "C16.320.365.463"],
    synonyms: ["Hemoglobin C Disease", "Hb-C Disease"],
  },
  {
    ui: "D006450",
    heading: "Hemoglobin SC Disease",
    tree_numbers: ["C15.378.071.141.150.150.440", "C15.378.420.155.440", "C16.320.070.150.440", "C16.320.365.155.440"],
    synonyms: [
      "Haemoglobin Sc Disease",
      "Hemoglobin Sc Disease",
      "Hemoglobin S Disease Without Crisis",
      "Sickle-Cell/hb-C Disease Without Crisis",
      "Hb-Ss Disease Without Crisis",
      "Hb-S/hb-C Disease",
      "Drepanocytosis",
      "Sickle Cell Anaemia",
      "Hb Sc Disease",
    ],
  },
  {
    ui: "D006453",
    heading: "Hemoglobinopathies",
    tree_numbers: ["C15.378.420", "C16.320.365"],
    synonyms: ["Hemoglobinopathies"],
  },
  {
    ui: "D006456",
    heading: "Hemoglobinuria",
    tree_numbers: ["C12.777.934.734.634", "C13.351.968.934.734.634", "C23.888.942.750.634"],
  },
  {
    ui: "D006457",
    heading: "Hemoglobinuria, Paroxysmal",
    tree_numbers: ["C15.378.071.141.560", "C15.378.190.625.460"],
  },
  {
    ui: "D006461",
    heading: "Hemolysis",
    tree_numbers: ["C23.550.403", "G12.122.545"],
  },
  {
    ui: "D006463",
    heading: "Hemolytic-Uremic Syndrome",
    tree_numbers: ["C12.777.419.936.463", "C13.351.968.419.936.463", "C15.378.071.141.610", "C15.378.140.855.925.500"],
    synonyms: ["Haemolytic-Uraemic Syndrome", "Hemolytic Uremic Syndrome"],
  },
  {
    ui: "D006465",
    heading: "Hemoperitoneum",
    tree_numbers: ["C06.844.400", "C23.550.414.888"],
  },
  {
    ui: "D006467",
    heading: "Hemophilia A",
    tree_numbers: ["C15.378.100.100.500", "C15.378.100.141.500", "C15.378.463.500", "C16.320.099.500"],
    synonyms: ["Classic Hemophilia A", "Congenital Factor Viii Disorder", "Hemophilia A", "Subhemophilia"],
  },
  {
    ui: "D006468",
    heading: "Hemopneumothorax",
    tree_numbers: ["C08.528.338", "C23.550.414.904.500"],
    synonyms: ["Haemopneumothorax"],
  },
  {
    ui: "D006469",
    heading: "Hemoptysis",
    tree_numbers: ["C08.381.348", "C23.550.414.896", "C23.888.852.430"],
  },
  {
    ui: "D006470",
    heading: "Hemorrhage",
    tree_numbers: ["C23.550.414"],
  },
  {
    ui: "D006471",
    heading: "Gastrointestinal Hemorrhage",
    tree_numbers: ["C06.405.227", "C23.550.414.788"],
  },
  {
    ui: "D006472",
    heading: "Oral Hemorrhage",
    tree_numbers: ["C07.465.625", "C23.550.414.922", "C23.888.619.500"],
  },
  {
    ui: "D006473",
    heading: "Postpartum Hemorrhage",
    tree_numbers: ["C13.703.420.725", "C13.703.844.700", "C23.550.414.993.850"],
  },
  {
    ui: "D006474",
    heading: "Hemorrhagic Disorders",
    tree_numbers: ["C15.378.463"],
    synonyms: ["Hemorrhagic Diathesis", "Hemorrhagic Disease"],
  },
  {
    ui: "D006475",
    heading: "Vitamin K Deficiency Bleeding",
    tree_numbers: [
      "C15.378.100.920.360",
      "C15.378.463.841.464",
      "C16.614.940",
      "C18.654.422.360",
      "C18.654.521.500.133.912.360",
    ],
  },
  {
    ui: "D006478",
    heading: "Hemorrhagic Fever, American",
    tree_numbers: ["C01.925.782.082.440", "C01.925.782.417.400"],
  },
  {
    ui: "D006479",
    heading: "Hemorrhagic Fever, Crimean",
    tree_numbers: [
      "C01.920.500.528",
      "C01.920.930.430",
      "C01.925.081.522",
      "C01.925.782.147.444",
      "C01.925.782.417.412",
    ],
    synonyms: ["Congo-Crimean Hemorrhagic Fever", "Crimean Hemorrhagic Fever", "Chf Congo Virus"],
  },
  {
    ui: "D006480",
    heading: "Hemorrhagic Fever with Renal Syndrome",
    tree_numbers: ["C01.925.782.147.420.400", "C01.925.782.417.300"],
    synonyms: ["Hfrs", "Hemorrhagic Fever, Russian", "Puumala Virus Nephropathy", "Hemorrhagic Nephrosonephritis"],
  },
  {
    ui: "D006481",
    heading: "Hemorrhagic Fever, Omsk",
    tree_numbers: ["C01.920.930.440", "C01.925.081.611", "C01.925.782.350.250.560", "C01.925.782.417.435"],
  },
  {
    ui: "D006482",
    heading: "Hemorrhagic Fevers, Viral",
    tree_numbers: ["C01.925.782.417"],
  },
  {
    ui: "D006483",
    heading: "Hemorrhagic Septicemia",
    tree_numbers: [
      "C01.150.252.100.375",
      "C01.150.252.400.700.662.500",
      "C01.757.100.375",
      "C23.550.470.790.500.100.375",
    ],
  },
  {
    ui: "D006484",
    heading: "Hemorrhoids",
    tree_numbers: ["C06.405.469.860.401", "C14.907.449"],
    synonyms: ["Hemorrhoidal Disease"],
  },
  {
    ui: "D006486",
    heading: "Hemosiderosis",
    tree_numbers: ["C18.452.565.500.500"],
    synonyms: ["Haemosiderosis"],
  },
  {
    ui: "D006491",
    heading: "Hemothorax",
    tree_numbers: ["C08.528.380", "C23.550.414.904"],
  },
  {
    ui: "D006501",
    heading: "Hepatic Encephalopathy",
    tree_numbers: ["C06.552.308.500.356", "C10.228.140.163.360", "C18.452.132.360"],
    synonyms: ["Hepatocerebral Intoxication"],
  },
  {
    ui: "D006502",
    heading: "Budd-Chiari Syndrome",
    tree_numbers: ["C06.552.347", "C14.907.355.830.925.275"],
    synonyms: ["Hepatic Vein Thrombosis"],
  },
  {
    ui: "D006504",
    heading: "Hepatic Veno-Occlusive Disease",
    tree_numbers: ["C06.552.360", "C14.907.460"],
  },
  {
    ui: "D006505",
    heading: "Hepatitis",
    tree_numbers: ["C06.552.380"],
  },
  {
    ui: "D006506",
    heading: "Hepatitis A",
    tree_numbers: ["C01.925.440.420", "C01.925.782.687.359.500", "C06.552.380.705.422"],
    synonyms: ["Viral Hepatitis, Type A"],
  },
  {
    ui: "D006509",
    heading: "Hepatitis B",
    tree_numbers: ["C01.221.250.500", "C01.925.256.430.400", "C01.925.440.435", "C06.552.380.705.437"],
    synonyms: ["Hepatitis B Infection", "Chronic Hepatitis B"],
  },
  {
    ui: "D006519",
    heading: "Hepatitis, Alcoholic",
    tree_numbers: ["C06.552.380.290", "C06.552.645.490", "C25.775.100.087.645.490"],
  },
  {
    ui: "D006520",
    heading: "Hepatitis, Animal",
    tree_numbers: ["C01.436", "C06.552.380.315", "C22.467"],
  },
  {
    ui: "D006521",
    heading: "Hepatitis, Chronic",
    tree_numbers: ["C06.552.380.350"],
    synonyms: [
      "Acute And Subacute Liver Necrosis",
      "Chronic Hepatitis",
      "Chronic Persistent Hepatitis",
      "Acute/subac. Necrosis Of Liver",
      "Animal Hepatitis",
      "Acute Hepatitis",
    ],
  },
  {
    ui: "D006522",
    heading: "Hepatitis, Infectious Canine",
    tree_numbers: [
      "C01.436.430.440",
      "C01.925.256.076.381",
      "C01.925.407.432",
      "C06.552.380.315.430.440",
      "C22.268.465",
      "C22.467.435.442",
    ],
  },
  {
    ui: "D006524",
    heading: "Hepatitis, Viral, Animal",
    tree_numbers: ["C01.436.430", "C01.925.407", "C06.552.380.315.430", "C22.467.435"],
    synonyms: ["Animal Viral Hepatitis", "Viral Hepatitis With Hepatic Coma", "Human Viral Hepatitis"],
  },
  {
    ui: "D006525",
    heading: "Hepatitis, Viral, Human",
    tree_numbers: ["C01.925.440", "C06.552.380.705"],
  },
  {
    ui: "D006526",
    heading: "Hepatitis C",
    tree_numbers: ["C01.221.250.750", "C01.925.440.440", "C01.925.782.350.350", "C06.552.380.705.440"],
    synonyms: ["Nanbh", "Hepatitis C Infection", "Viral Hepatitis C", "Hepatitis Nona Nonb", "Chronic Hepatitis C"],
  },
  {
    ui: "D006527",
    heading: "Hepatolenticular Degeneration",
    tree_numbers: [
      "C06.552.413",
      "C10.228.140.079.493",
      "C10.228.140.163.100.360",
      "C10.228.662.400",
      "C10.574.500.487",
      "C16.320.400.361",
      "C16.320.565.189.360",
      "C16.320.565.618.403",
      "C18.452.132.100.360",
      "C18.452.648.189.360",
      "C18.452.648.618.403",
    ],
    synonyms: [
      "Cerebral Pseudosclerosis",
      "Wilson's Disease",
      "Westphal Pseudosclerosis",
      "Hepatolenticular Degeneration",
      "Westphal-Strumpell Syndrome",
    ],
  },
  {
    ui: "D006528",
    heading: "Carcinoma, Hepatocellular",
    tree_numbers: ["C04.557.470.200.025.255", "C04.588.274.623.160", "C06.301.623.160", "C06.552.697.160"],
  },
  {
    ui: "D006529",
    heading: "Hepatomegaly",
    tree_numbers: ["C06.552.416", "C23.300.775.525"],
  },
  {
    ui: "D006530",
    heading: "Hepatorenal Syndrome",
    tree_numbers: ["C06.552.465", "C12.777.419.291", "C13.351.968.419.291"],
  },
  {
    ui: "D006547",
    heading: "Hernia",
    tree_numbers: ["C23.300.707"],
    synonyms: ["Vaginal Enterocele"],
  },
  {
    ui: "D006548",
    heading: "Hernia, Diaphragmatic",
    tree_numbers: ["C23.300.707.960.500"],
    synonyms: ["Diaphragmatic Hernia"],
  },
  {
    ui: "D006549",
    heading: "Hernia, Diaphragmatic, Traumatic",
    tree_numbers: ["C23.300.707.960.500.233", "C26.017.258"],
  },
  {
    ui: "D006550",
    heading: "Hernia, Femoral",
    tree_numbers: ["C23.300.707.374.750"],
  },
  {
    ui: "D006551",
    heading: "Hernia, Hiatal",
    tree_numbers: ["C23.300.707.960.500.467"],
    synonyms: ["Hiatal Hernia", "Diaphragmatic - Hiatus -Hernia"],
  },
  {
    ui: "D006552",
    heading: "Hernia, Inguinal",
    tree_numbers: ["C23.300.707.374.875"],
  },
  {
    ui: "D006553",
    heading: "Hernia, Obturator",
    tree_numbers: ["C23.300.707.937"],
  },
  {
    ui: "D006554",
    heading: "Hernia, Umbilical",
    tree_numbers: ["C16.614.378", "C23.300.707.374.937.500"],
    synonyms: ["Omphalocoele"],
  },
  {
    ui: "D006555",
    heading: "Hernia, Ventral",
    tree_numbers: ["C23.300.707.374.937"],
  },
  {
    ui: "D006556",
    heading: "Heroin Dependence",
    tree_numbers: ["C25.775.643.500.400", "F03.900.647.500.300"],
  },
  {
    ui: "D006557",
    heading: "Herpangina",
    tree_numbers: ["C01.925.782.687.359.213.466", "C01.925.782.687.359.347.500"],
    synonyms: ["Vesicular Pharyngitis"],
  },
  {
    ui: "D006558",
    heading: "Herpes Genitalis",
    tree_numbers: [
      "C01.221.812.640.350",
      "C01.778.640.350",
      "C01.925.256.466.382.290",
      "C01.925.813.350",
      "C12.294.329",
      "C13.351.500.342",
    ],
    synonyms: ["Virus-Genital Herpes", "Herpes Genitalis", "Genital Herpes Simplex", "Venereal Herpes"],
  },
  {
    ui: "D006559",
    heading: "Pemphigoid Gestationis",
    tree_numbers: ["C13.703.570", "C17.800.865.700"],
    synonyms: ["Herpes Gestationis", "Gestational Herpes"],
  },
  {
    ui: "D006560",
    heading: "Herpes Labialis",
    tree_numbers: ["C01.925.256.466.382.316", "C01.925.825.320.320", "C07.465.409.466", "C17.800.838.790.320.320"],
  },
  {
    ui: "D006561",
    heading: "Herpes Simplex",
    tree_numbers: ["C01.925.256.466.382", "C01.925.825.320", "C17.800.838.790.320"],
    synonyms: ["Herpesvirus Hominis Disease"],
  },
  {
    ui: "D006562",
    heading: "Herpes Zoster",
    tree_numbers: ["C01.925.256.466.930.750"],
    synonyms: ["Shingles", "Herpes Zona"],
  },
  {
    ui: "D006563",
    heading: "Herpes Zoster Ophthalmicus",
    tree_numbers: ["C01.375.725.450", "C01.925.256.466.930.750.466", "C01.925.325.450", "C11.294.800.450"],
  },
  {
    ui: "D006566",
    heading: "Herpesviridae Infections",
    tree_numbers: ["C01.925.256.466"],
  },
  {
    ui: "D006606",
    heading: "Hiccup",
    tree_numbers: ["C23.888.821.578"],
  },
  {
    ui: "D006607",
    heading: "Adenoma, Sweat Gland",
    tree_numbers: ["C04.557.470.035.175", "C04.557.470.550.175"],
    synonyms: ["Sweat Gland Adenoma", "Hidradenoma Of Skin", "Syringoadenoma"],
  },
  {
    ui: "D006610",
    heading: "High Pressure Neurological Syndrome",
    tree_numbers: ["C10.228.566", "C24.410"],
  },
  {
    ui: "D006616",
    heading: "Hip Contracture",
    tree_numbers: ["C05.550.323.468", "C05.651.197.468"],
  },
  {
    ui: "D006617",
    heading: "Hip Dislocation",
    tree_numbers: ["C05.550.518.384", "C26.289.384", "C26.531.500"],
  },
  {
    ui: "D006618",
    heading: "Hip Dislocation, Congenital",
    tree_numbers: ["C05.660.297.500", "C16.131.621.297.500", "C16.131.621.449"],
  },
  {
    ui: "D006619",
    heading: "Hip Dysplasia, Canine",
    tree_numbers: ["C22.268.485"],
  },
  {
    ui: "D006620",
    heading: "Hip Fractures",
    tree_numbers: ["C26.404.061.425", "C26.531.750", "C26.558.276.425"],
  },
  {
    ui: "D006623",
    heading: "von Hippel-Lindau Disease",
    tree_numbers: ["C10.562.925", "C14.907.077.925", "C16.131.077.245.750", "C16.320.184.750"],
    synonyms: ["Hippel Lindau Syndrome", "Von Hippel-Lindau Syndrome", "Von Hippel-Lindau Syndrome"],
  },
  {
    ui: "D006627",
    heading: "Hirschsprung Disease",
    tree_numbers: ["C06.198.439", "C06.405.469.158.701.439", "C16.131.314.439"],
    synonyms: [
      "Macrocolon",
      "Aganglionic Megacolon",
      "Total Intestinal Aganglionosis",
      "Congenital Megacolon",
      "Pelvirectal Achalasia",
      "Hirschsprung Disease",
    ],
  },
  {
    ui: "D006628",
    heading: "Hirsutism",
    tree_numbers: ["C17.800.329.750", "C23.888.971.468"],
  },
  {
    ui: "D006646",
    heading: "Histiocytosis, Langerhans-Cell",
    tree_numbers: ["C08.381.483.375", "C15.604.250.400"],
    synonyms: [
      "Letterer-Siwe Disease Of Lymph Nodes Of Inguinal Region Amd/or Lower Limb",
      "Letterer-Siwe Disease Of Intrapelvic Lymph Nodes",
      "Langerhans Cell Granulomatosis",
      "Letterer-Siwe Disease Involving Lymph Nodes Of Head, Face And Neck",
      "Letterer-Siwe Disease Of Lymph Nodes Of Inguinal Region And Lower Limb",
      "Histiocytosis X",
      "Langerhan's Cell Histiocytosis",
      "Letterer-Siwe Disease Of Lymph Nodes Of Head, Face And/or Neck",
      "Letterer-Siwe Disease Of Lymph Nodes Of Inguinal Region And/or Lower Limb",
      "Letterer-Siwe Disease Of Intra-Abdominal Lymph Nodes",
      "Letterer-Siwe Disease Of Lymph Nodes Of Head, Face And Neck",
      "Letterer-Siwe Disease Involving Intrapelvic Lymph Nodes",
      "Letterer-Siwe Disease Involving Lymph Nodes Of Axilla And Upper Limb",
      "Letterer-Siwe Disease Involving Lymph Nodes Of Head, Face, And Neck",
      "Letterer-Siwe Disease Of Lymph Nodes Of Axilla And/or Upper Limb",
      "Letterer-Siwe Disease Involving Intra-Abdominal Lymph Nodes",
      "Letterer-Siwe Disease Of Lymph Nodes Of Multiple Sites",
      "Letterer-Siwe Disease Of Lymph Nodes Of Axilla And Upper Limb",
      "Letterer-Siwe Disease Involving Spleen",
      "Letterer-Siwe Disease Involving Lymph Nodes Of Inguinal Region And Lower Limb",
      "Letterer-Siwe Disease Of Intrathoracic Lymph Nodes",
      "Letterer-Siwe Disease",
      "Letterer-Siwe Disease Of Spleen",
      "Letterer-Siwe Disease Involving Lymph Nodes Of Multiple Sites",
      "Letterer-Siwe Disease Involving Intrathoracic Lymph Nodes",
    ],
  },
  {
    ui: "D006660",
    heading: "Histoplasmosis",
    tree_numbers: ["C01.150.703.450"],
  },
  {
    ui: "D006677",
    heading: "Histrionic Personality Disorder",
    tree_numbers: ["F03.675.400"],
  },
  {
    ui: "D006679",
    heading: "HIV Seropositivity",
    tree_numbers: [
      "C01.221.250.875.500",
      "C01.221.812.640.400.500",
      "C01.778.640.400.500",
      "C01.925.782.815.616.400.500",
      "C01.925.813.400.500",
      "C20.673.480.500",
    ],
  },
  {
    ui: "D006685",
    heading: "Hoarseness",
    tree_numbers: [
      "C08.360.940.490",
      "C08.618.490",
      "C09.400.940.490",
      "C10.597.975.550",
      "C23.888.592.979.550",
      "C23.888.852.490",
    ],
  },
  {
    ui: "D006689",
    heading: "Hodgkin Disease",
    tree_numbers: ["C04.557.386.355", "C15.604.515.569.355", "C20.683.515.761.355"],
    synonyms: ["Hodgkin's Disease, Mixed Cellularity"],
  },
  {
    ui: "D006691",
    heading: "Classical Swine Fever",
    tree_numbers: ["C01.925.782.350.675.200", "C22.905.170"],
  },
  {
    ui: "D006712",
    heading: "Homocystinuria",
    tree_numbers: [
      "C10.228.140.163.100.365",
      "C16.320.565.100.480.500",
      "C16.320.565.189.365",
      "C17.300.428",
      "C18.452.132.100.365",
      "C18.452.648.100.480.500",
      "C18.452.648.189.365",
    ],
    synonyms: ["Cbs Deficiency", "Cystathionine Beta Synthase Deficiency", "Cystathionine Synthase Deficiency"],
  },
  {
    ui: "D006725",
    heading: "Hookworm Infections",
    tree_numbers: ["C01.610.335.508.700.775.455"],
  },
  {
    ui: "D006726",
    heading: "Hordeolum",
    tree_numbers: ["C01.150.252.289.400", "C01.375.354.400", "C11.294.354.400", "C11.338.648"],
    synonyms: ["Stye", "Furuncle Of Eyelid", "Boil Of Eyelid"],
  },
  {
    ui: "D006732",
    heading: "Horner Syndrome",
    tree_numbers: ["C10.177.350", "C10.597.690.362.500", "C11.710.528.500", "C23.888.592.708.362.500"],
    synonyms: ["Bernard Horner Syndrome", "Horner Syndrome", "Cervical Sympathetic Paralysis"],
  },
  {
    ui: "D006734",
    heading: "Horse Diseases",
    tree_numbers: ["C22.488"],
  },
  {
    ui: "D006800",
    heading: "Deltaretrovirus Infections",
    tree_numbers: ["C01.925.782.815.200", "C20.673.483"],
  },
  {
    ui: "D006810",
    heading: "Humeral Fractures",
    tree_numbers: ["C26.088.390", "C26.404.500"],
  },
  {
    ui: "D006816",
    heading: "Huntington Disease",
    tree_numbers: [
      "C10.228.140.079.545",
      "C10.228.140.380.278",
      "C10.228.662.262.249.750",
      "C10.574.500.497",
      "C16.320.400.430",
      "F03.615.250.400",
      "F03.615.400.390",
    ],
    synonyms: ["Huntington's Chorea", "Hd", "Huntington Disease"],
  },
  {
    ui: "D006819",
    heading: "Hyaline Membrane Disease",
    tree_numbers: ["C08.381.840.500.475", "C08.618.840.500.475", "C16.614.521.563.475"],
    synonyms: [
      "Pulmonary Hypoperfusion Syndrome Of Newborn",
      "Hyaline Membrane Disease",
      "Hmd - Hyaline Membrane Disease",
      "Respiratory Distress Syndrome Of Newborn",
      "Neonatal Respiratory Distress Syndrome",
      "Pulmonary Hyaline Membrane Disease",
    ],
  },
  {
    ui: "D006828",
    heading: "Hydatidiform Mole",
    tree_numbers: ["C04.557.465.955.416.812", "C04.850.908.416.750", "C13.703.720.949.416.875"],
    synonyms: ["Gestational Trophoblastic Neoplasia", "Hydatidiform Mole", "Molar Pregnancy"],
  },
  {
    ui: "D006831",
    heading: "Polyhydramnios",
    tree_numbers: ["C13.703.610"],
  },
  {
    ui: "D006832",
    heading: "Hydranencephaly",
    tree_numbers: ["C10.500.450", "C16.131.666.450"],
  },
  {
    ui: "D006833",
    heading: "Hydrarthrosis",
    tree_numbers: ["C05.550.509"],
  },
  {
    ui: "D006837",
    heading: "Hydroa Vacciniforme",
    tree_numbers: ["C17.800.600.425", "C17.800.865.575"],
  },
  {
    ui: "D006848",
    heading: "Testicular Hydrocele",
    tree_numbers: ["C12.294.882"],
  },
  {
    ui: "D006849",
    heading: "Hydrocephalus",
    tree_numbers: ["C10.228.140.602"],
    synonyms: ["Hydrocephalus, X-Linked", "Hydrocephalus, Nonsyndromic, Autosomal Recessive"],
  },
  {
    ui: "D006850",
    heading: "Hydrocephalus, Normal Pressure",
    tree_numbers: ["C10.228.140.602.750"],
    synonyms: ["Low Pressure Hydrocephalus"],
  },
  {
    ui: "D006869",
    heading: "Hydronephrosis",
    tree_numbers: ["C12.777.419.307", "C13.351.968.419.307"],
  },
  {
    ui: "D006871",
    heading: "Hydrophthalmos",
    tree_numbers: ["C11.250.480", "C11.525.381.407.480", "C16.131.384.480", "C16.614.438"],
  },
  {
    ui: "D006872",
    heading: "Hydropneumothorax",
    tree_numbers: ["C08.528.434"],
  },
  {
    ui: "D006876",
    heading: "Hydrothorax",
    tree_numbers: ["C08.528.476"],
  },
  {
    ui: "D006925",
    heading: "Hymenolepiasis",
    tree_numbers: ["C01.610.335.190.519"],
    synonyms: ["Dwarf Tapeworm Infection", "Hymenolepis Infectious Disease"],
  },
  {
    ui: "D006929",
    heading: "Hyperaldosteronism",
    tree_numbers: ["C19.053.800.604"],
    synonyms: ["Hyperaldosteronism", "Cushing's Syndrome", "Cushing Syndrome"],
  },
  {
    ui: "D006930",
    heading: "Hyperalgesia",
    tree_numbers: ["C10.597.751.791.400", "C23.888.592.763.770.400"],
  },
  {
    ui: "D006932",
    heading: "Hyperbilirubinemia",
    tree_numbers: ["C23.550.429"],
    synonyms: ["Hyperbilirubinemia", "Hereditary Hyperbilirubinemia"],
  },
  {
    ui: "D006933",
    heading: "Hyperbilirubinemia, Hereditary",
    tree_numbers: ["C16.320.565.300", "C18.452.648.300"],
    synonyms: ["Hyperbilirubinemia", "Hereditary Hyperbilirubinemia"],
  },
  {
    ui: "D006934",
    heading: "Hypercalcemia",
    tree_numbers: ["C18.452.174.451", "C18.452.950.340"],
  },
  {
    ui: "D006935",
    heading: "Hypercapnia",
    tree_numbers: ["C23.888.852.544"],
  },
  {
    ui: "D006936",
    heading: "Hypercementosis",
    tree_numbers: ["C07.793.422"],
    synonyms: ["Cementation Hyperplasia"],
  },
  {
    ui: "D006937",
    heading: "Hypercholesterolemia",
    tree_numbers: ["C18.452.584.500.500.396"],
  },
  {
    ui: "D006938",
    heading: "Hyperlipoproteinemia Type II",
    tree_numbers: ["C16.320.565.398.481", "C18.452.584.500.500.644.475", "C18.452.648.398.481"],
    synonyms: [
      "Hyperbetalipoproteinemia",
      "Familial Hypercholesteremia",
      "Familial Hyperbetalipoproteinaemia",
      "Type Ii Hyperlipidemia",
      "Fredrickson Type Iia Hyperlipoproteinemia",
      "Fredrickson Type Iia Lipidaemia",
    ],
  },
  {
    ui: "D006939",
    heading: "Hyperemesis Gravidarum",
    tree_numbers: ["C13.703.407.500", "C23.888.821.937.049.500"],
  },
  {
    ui: "D006940",
    heading: "Hyperemia",
    tree_numbers: ["C14.907.474"],
  },
  {
    ui: "D006941",
    heading: "Hyperesthesia",
    tree_numbers: ["C10.597.751.791.450", "C23.888.592.763.770.450"],
  },
  {
    ui: "D006942",
    heading: "Hypergammaglobulinemia",
    tree_numbers: ["C15.378.147.542", "C20.683.460", "C23.888.512"],
  },
  {
    ui: "D006943",
    heading: "Hyperglycemia",
    tree_numbers: ["C18.452.394.952"],
  },
  {
    ui: "D006944",
    heading: "Hyperglycemic Hyperosmolar Nonketotic Coma",
    tree_numbers: ["C19.246.099.750.490"],
  },
  {
    ui: "D006945",
    heading: "Hyperhidrosis",
    tree_numbers: ["C17.800.946.350"],
  },
  {
    ui: "D006946",
    heading: "Hyperinsulinism",
    tree_numbers: ["C18.452.394.968"],
    synonyms: ["Hyperinsulinemia"],
  },
  {
    ui: "D006947",
    heading: "Hyperkalemia",
    tree_numbers: ["C18.452.950.396"],
  },
  {
    ui: "D006948",
    heading: "Hyperkinesis",
    tree_numbers: ["C10.597.350.350", "C23.888.592.350.350"],
  },
  {
    ui: "D006949",
    heading: "Hyperlipidemias",
    tree_numbers: ["C18.452.584.500.500"],
    synonyms: ["Familial Hyperlipoproteinemia", "Hyperlipemia"],
  },
  {
    ui: "D006950",
    heading: "Hyperlipidemia, Familial Combined",
    tree_numbers: ["C16.320.565.398.450", "C18.452.584.500.500.438", "C18.452.648.398.450"],
    synonyms: [
      "Hyperbetalipoproteinemia With Prebetalipoproteinemia",
      "Familial Multiple Lipoprotein-Type Hyperlipidemia",
      "Type Iib Hyperlipoproteinemia",
      "Mixed Hyperlipidaemia",
    ],
  },
  {
    ui: "D006951",
    heading: "Hyperlipoproteinemias",
    tree_numbers: ["C18.452.584.500.500.644"],
    synonyms: ["Familial Hyperlipoproteinemia", "Hyperlipemia"],
  },
  {
    ui: "D006952",
    heading: "Hyperlipoproteinemia Type III",
    tree_numbers: ["C16.320.565.398.483", "C18.452.584.500.500.644.485", "C18.452.648.398.483"],
    synonyms: [
      "Remnant Hyperlipidemia",
      "Familial Hypercholesterolaemia With Hyperlipaemia",
      "Carbohydrate Induced Hyperlipemia",
      "Familial Type 3 Hyperlipoproteinemia",
    ],
  },
  {
    ui: "D006953",
    heading: "Hyperlipoproteinemia Type IV",
    tree_numbers: [
      "C16.320.565.398.487",
      "C18.452.584.500.500.644.490",
      "C18.452.584.500.500.851.500",
      "C18.452.648.398.487",
    ],
    synonyms: [
      "Fredrickson Type Iv Lipidaemia",
      "Fredrickson Type Iv Hyperlipoproteinemia",
      "Endogenous Hyperlipidaemia",
      "Familial Hypertriglyceridemia",
      "Vldl Hyperlipoproteinemia",
      "Fredrickson Type Iv Lipidemia",
    ],
  },
  {
    ui: "D006954",
    heading: "Hyperlipoproteinemia Type V",
    tree_numbers: [
      "C16.320.565.398.493",
      "C18.452.584.500.500.644.495",
      "C18.452.584.500.500.851.750",
      "C18.452.648.398.493",
    ],
    synonyms: [
      "Familial Hyperlipoproteinemia Type V",
      "Familial Type 5 Hyperlipoproteinemia",
      "Fredrickson Type V Lipaemia",
    ],
  },
  {
    ui: "D006955",
    heading: "Hypernatremia",
    tree_numbers: ["C18.452.950.452"],
  },
  {
    ui: "D006956",
    heading: "Hyperopia",
    tree_numbers: ["C11.744.479"],
    synonyms: ["Far-Sightedness", "Hypermetropia"],
  },
  {
    ui: "D006957",
    heading: "Hyperostosis Frontalis Interna",
    tree_numbers: ["C05.116.099.708.486", "C05.116.540.420"],
  },
  {
    ui: "D006958",
    heading: "Hyperostosis, Cortical, Congenital",
    tree_numbers: ["C05.116.099.708.479", "C05.116.540.400", "C16.614.465"],
    synonyms: ["Cortical Congenital Hyperostosis", "Infantile Cortical Hyperostosis"],
  },
  {
    ui: "D006959",
    heading: "Hyperoxaluria",
    tree_numbers: ["C12.777.419.313", "C13.351.968.419.313"],
  },
  {
    ui: "D006960",
    heading: "Hyperoxaluria, Primary",
    tree_numbers: ["C12.777.419.313.500", "C13.351.968.419.313.500", "C16.320.565.202.460", "C18.452.648.202.460"],
  },
  {
    ui: "D006961",
    heading: "Hyperparathyroidism",
    tree_numbers: ["C19.642.355"],
  },
  {
    ui: "D006962",
    heading: "Hyperparathyroidism, Secondary",
    tree_numbers: ["C19.642.355.480"],
  },
  {
    ui: "D006963",
    heading: "Hyperphagia",
    tree_numbers: ["C23.888.821.645"],
  },
  {
    ui: "D006964",
    heading: "Hyperpituitarism",
    tree_numbers: ["C10.228.140.617.738.250", "C19.700.355"],
  },
  {
    ui: "D006965",
    heading: "Hyperplasia",
    tree_numbers: ["C23.550.444"],
  },
  {
    ui: "D006966",
    heading: "Hyperprolactinemia",
    tree_numbers: ["C10.228.140.617.738.250.450", "C19.700.355.600"],
    synonyms: ["Hyperprolactinaemia", "Chiari-Frommel Syndrome", "Pregnancy-Related A-G Syndrome"],
  },
  {
    ui: "D006967",
    heading: "Hypersensitivity",
    tree_numbers: ["C20.543"],
    synonyms: ["Allergic Hypersensitivity Disease", "Hypersensitivity", "Hypersensitivity Reaction Type I Disease"],
  },
  {
    ui: "D006968",
    heading: "Hypersensitivity, Delayed",
    tree_numbers: ["C20.543.418"],
  },
  {
    ui: "D006969",
    heading: "Hypersensitivity, Immediate",
    tree_numbers: ["C20.543.480"],
  },
  {
    ui: "D006970",
    heading: "Disorders of Excessive Somnolence",
    tree_numbers: ["C10.886.425.800.200", "F03.870.400.800.200"],
    synonyms: ["Primary Recurrent Hypersomnia"],
  },
  {
    ui: "D006971",
    heading: "Hypersplenism",
    tree_numbers: ["C15.604.744.293"],
    synonyms: ["Hypersplenia"],
  },
  {
    ui: "D006972",
    heading: "Hypertelorism",
    tree_numbers: ["C05.116.099.370.231.480", "C05.660.207.231.480", "C16.131.621.207.231.480"],
  },
  {
    ui: "D006973",
    heading: "Hypertension",
    tree_numbers: ["C14.907.489"],
    synonyms: ["Hyperpiesia", "Htn", "High Blood Pressure", "Vascular Hypertensive Disorder"],
  },
  {
    ui: "D006974",
    heading: "Hypertension, Malignant",
    tree_numbers: ["C14.907.489.330"],
  },
  {
    ui: "D006975",
    heading: "Hypertension, Portal",
    tree_numbers: ["C06.552.494"],
  },
  {
    ui: "D006976",
    heading: "Hypertension, Pulmonary",
    tree_numbers: ["C08.381.423", "C14.907.489.556"],
  },
  {
    ui: "D006977",
    heading: "Hypertension, Renal",
    tree_numbers: ["C12.777.419.331", "C13.351.968.419.331", "C14.907.489.631"],
  },
  {
    ui: "D006978",
    heading: "Hypertension, Renovascular",
    tree_numbers: ["C12.777.419.331.490", "C13.351.968.419.331.490", "C14.907.489.631.485"],
    synonyms: ["Renovascular Hypertension"],
  },
  {
    ui: "D006980",
    heading: "Hyperthyroidism",
    tree_numbers: ["C19.874.397"],
    synonyms: ["Overactive Thyroid"],
  },
  {
    ui: "D006981",
    heading: "Hyperthyroxinemia",
    tree_numbers: ["C19.874.410"],
  },
  {
    ui: "D006983",
    heading: "Hypertrichosis",
    tree_numbers: ["C17.800.329.875"],
  },
  {
    ui: "D006984",
    heading: "Hypertrophy",
    tree_numbers: ["C23.300.775"],
  },
  {
    ui: "D006985",
    heading: "Hyperventilation",
    tree_numbers: ["C08.618.501", "C23.888.852.591"],
  },
  {
    ui: "D006986",
    heading: "Hypervitaminosis A",
    tree_numbers: ["C18.654.301"],
  },
  {
    ui: "D006987",
    heading: "Hypesthesia",
    tree_numbers: ["C10.597.751.791.500", "C23.888.592.763.770.500"],
  },
  {
    ui: "D006988",
    heading: "Hyphema",
    tree_numbers: ["C11.290.484", "C23.550.414.756.550"],
  },
  {
    ui: "D006994",
    heading: "Hypoaldosteronism",
    tree_numbers: ["C19.053.500.480"],
  },
  {
    ui: "D006995",
    heading: "Hypobetalipoproteinemias",
    tree_numbers: ["C16.320.565.398.500.440", "C18.452.584.500.875.440", "C18.452.648.398.500.440"],
    synonyms: ["Hypo-Beta-Lipoproteinemia"],
  },
  {
    ui: "D006996",
    heading: "Hypocalcemia",
    tree_numbers: ["C18.452.174.509", "C18.452.950.509"],
  },
  {
    ui: "D006998",
    heading: "Hypochondriasis",
    tree_numbers: ["F03.875.450"],
    synonyms: ["Hypochondriacal Disorder", "Hypochondriacal Neurosis", "Hypochondria"],
  },
  {
    ui: "D007000",
    heading: "Hypodermyiasis",
    tree_numbers: ["C01.610.858.211.503.398"],
  },
  {
    ui: "D007003",
    heading: "Hypoglycemia",
    tree_numbers: ["C18.452.394.984"],
    synonyms: ["Hypoglycaemia"],
  },
  {
    ui: "D007006",
    heading: "Hypogonadism",
    tree_numbers: ["C19.391.482"],
  },
  {
    ui: "D007007",
    heading: "Hypohidrosis",
    tree_numbers: ["C17.800.946.370"],
    synonyms: ["Absence Of Sweating", "Adiaphoresis"],
  },
  {
    ui: "D007008",
    heading: "Hypokalemia",
    tree_numbers: ["C18.452.950.565"],
  },
  {
    ui: "D007009",
    heading: "Hypolipoproteinemias",
    tree_numbers: ["C16.320.565.398.500", "C18.452.584.500.875", "C18.452.648.398.500"],
    synonyms: ["Hypolipoproteinaemia"],
  },
  {
    ui: "D007010",
    heading: "Hyponatremia",
    tree_numbers: ["C18.452.950.620"],
  },
  {
    ui: "D007011",
    heading: "Hypoparathyroidism",
    tree_numbers: ["C19.642.482"],
  },
  {
    ui: "D007012",
    heading: "Hypopharyngeal Neoplasms",
    tree_numbers: ["C04.588.443.665.710.485", "C07.550.745.436", "C09.647.710.485", "C09.775.549.485"],
    synonyms: [
      "Malignant Tumor Of Hypopharynx",
      "Malignant Hypopharyngeal Tumor",
      "Malignant Neoplasm Of Hypopharynx",
      "Hypopharyngeal Cancer",
      "Malignant Tumour Of Hypopharynx",
    ],
  },
  {
    ui: "D007014",
    heading: "Hypophosphatasia",
    tree_numbers: ["C16.320.565.618.482", "C18.452.648.618.482"],
    synonyms: ["Childhood Hypophosphatasia", "Deficiency Of Alkaline Phosphatase", "Hypophospatasia, Childhood"],
  },
  {
    ui: "D007015",
    heading: "Hypophosphatemia, Familial",
    tree_numbers: [
      "C12.777.419.815.647",
      "C13.351.968.419.815.647",
      "C16.320.565.618.544",
      "C16.320.831.647",
      "C18.452.648.618.544",
      "C18.452.750.400.500",
    ],
  },
  {
    ui: "D007018",
    heading: "Hypopituitarism",
    tree_numbers: ["C10.228.140.617.738.300", "C19.700.482"],
    synonyms: ["Sheehan's Syndrome", "Postpartum Hypopituitarism"],
  },
  {
    ui: "D007019",
    heading: "Hypoproteinemia",
    tree_numbers: ["C15.378.147.607"],
  },
  {
    ui: "D007020",
    heading: "Hypoprothrombinemias",
    tree_numbers: ["C15.378.100.100.550", "C15.378.100.141.550", "C15.378.463.550", "C16.320.099.550"],
  },
  {
    ui: "D007021",
    heading: "Hypospadias",
    tree_numbers: ["C12.294.494.400", "C12.706.516", "C13.351.875.466", "C16.131.939.516"],
    synonyms: ["Familial Hypospadias"],
  },
  {
    ui: "D007022",
    heading: "Hypotension",
    tree_numbers: ["C14.907.514"],
  },
  {
    ui: "D007024",
    heading: "Hypotension, Orthostatic",
    tree_numbers: ["C10.177.575.600.450", "C14.907.514.482"],
  },
  {
    ui: "D007027",
    heading: "Hypothalamic Diseases",
    tree_numbers: ["C10.228.140.617"],
    synonyms: [
      "Babinski-Froelich Syndrome",
      "Froehlich Syndrome",
      "Froehlich's Syndrome",
      "Froelich's Syndrome",
      "Adiposogenital Syndrome",
    ],
  },
  {
    ui: "D007029",
    heading: "Hypothalamic Neoplasms",
    tree_numbers: [
      "C04.588.614.250.195.885.500",
      "C10.228.140.211.885.500",
      "C10.228.140.617.477",
      "C10.551.240.250.700.500",
    ],
    synonyms: ["Neoplasm Of The Hypothalamus", "Tumor Of Hypothalamus"],
  },
  {
    ui: "D007035",
    heading: "Hypothermia",
    tree_numbers: ["C23.888.119.565"],
  },
  {
    ui: "D007037",
    heading: "Hypothyroidism",
    tree_numbers: ["C19.874.482"],
    synonyms: ["Thyroid Insufficiency", "Thyroid Deficiency"],
  },
  {
    ui: "D007039",
    heading: "Hypotrichosis",
    tree_numbers: ["C17.800.329.937"],
  },
  {
    ui: "D007040",
    heading: "Hypoventilation",
    tree_numbers: ["C08.618.846.565", "C23.888.852.638"],
  },
  {
    ui: "D007046",
    heading: "Hysteria",
    tree_numbers: ["F03.675.400.500"],
  },
  {
    ui: "D007049",
    heading: "Iatrogenic Disease",
    tree_numbers: ["C23.550.291.875"],
  },
  {
    ui: "D007057",
    heading: "Ichthyosis",
    tree_numbers: ["C16.131.831.512", "C16.614.492", "C17.800.428.333", "C17.800.804.512"],
    synonyms: ["Non-Syndromic Ichthyosis", "Ichthyoses"],
  },
  {
    ui: "D007077",
    heading: "Ileal Diseases",
    tree_numbers: ["C06.405.469.420"],
  },
  {
    ui: "D007078",
    heading: "Ileal Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.501",
      "C06.301.371.411.501",
      "C06.405.249.411.501",
      "C06.405.469.420.501",
      "C06.405.469.491.501",
    ],
    synonyms: ["Ileal Tumor"],
  },
  {
    ui: "D007079",
    heading: "Ileitis",
    tree_numbers: ["C06.405.205.462.624", "C06.405.469.326.875", "C06.405.469.420.520"],
    synonyms: ["Crohn's Ileitis"],
  },
  {
    ui: "D007088",
    heading: "Illusions",
    tree_numbers: ["C10.597.606.762.400", "C23.888.592.604.764.400", "F01.700.750.400", "F02.463.593.446"],
  },
  {
    ui: "D007102",
    heading: "Immersion Foot",
    tree_numbers: ["C17.800.321.500"],
  },
  {
    ui: "D007105",
    heading: "Immune Complex Diseases",
    tree_numbers: ["C20.543.520"],
    synonyms: ["Immune Complex Disease"],
  },
  {
    ui: "D007119",
    heading: "Immunoblastic Lymphadenopathy",
    tree_numbers: ["C15.604.338.500", "C15.604.515.509", "C20.683.515.501"],
  },
  {
    ui: "D007153",
    heading: "Immunologic Deficiency Syndromes",
    tree_numbers: ["C20.673"],
    synonyms: ["Immune Deficiency Disorder", "Hypoimmunity", "Immunodeficiency Syndrome"],
  },
  {
    ui: "D007154",
    heading: "Immune System Diseases",
    tree_numbers: ["C20"],
  },
  {
    ui: "D007160",
    heading: "Immunoproliferative Disorders",
    tree_numbers: ["C20.683"],
    synonyms: [],
  },
  {
    ui: "D007161",
    heading: "Immunoproliferative Small Intestinal Disease",
    tree_numbers: [
      "C04.557.386.390",
      "C06.301.371.411.512",
      "C06.405.249.411.512",
      "C06.405.469.491.505",
      "C15.378.147.780.490.512",
      "C15.604.515.435.512",
      "C20.683.515.512",
      "C20.683.780.490.512",
    ],
  },
  {
    ui: "D007169",
    heading: "Impetigo",
    tree_numbers: [
      "C01.150.252.410.868.951.360",
      "C01.150.252.410.890.587",
      "C01.150.252.819.770.360",
      "C01.800.720.770.360",
      "C17.800.838.765.770.360",
    ],
  },
  {
    ui: "D007172",
    heading: "Erectile Dysfunction",
    tree_numbers: ["C12.294.644.486", "F03.835.400"],
  },
  {
    ui: "D007174",
    heading: "Disruptive, Impulse Control, and Conduct Disorders",
    tree_numbers: ["F03.250"],
    synonyms: ["Pathological Stealing"],
  },
  {
    ui: "D007177",
    heading: "Inappropriate ADH Syndrome",
    tree_numbers: ["C10.228.140.617.738.320", "C18.452.950.626", "C19.700.490"],
    synonyms: [
      "Syndrome Of Inappropriate Secretion Of Antidiuretic Hormone",
      "Syndrome Of Inappropriate Antidiuretic Hormone Secretion",
      "Syndrome Of Inappropriate Vasopressin Secretion",
      "Syndrome Of Inappropriate Secretion Of Adh",
    ],
  },
  {
    ui: "D007184",
    heading: "Incontinentia Pigmenti",
    tree_numbers: [
      "C16.131.077.445",
      "C16.131.831.580",
      "C16.320.850.420",
      "C17.800.621.497",
      "C17.800.804.580",
      "C17.800.827.420",
    ],
    synonyms: ["Incontinentia Pigmenti Syndrome", "Incontinentia Pigmenti"],
  },
  {
    ui: "D007222",
    heading: "Inert Gas Narcosis",
    tree_numbers: ["C24.426", "C25.723.455.571"],
  },
  {
    ui: "D007228",
    heading: "Infant Nutrition Disorders",
    tree_numbers: ["C18.654.422"],
  },
  {
    ui: "D007232",
    heading: "Infant, Newborn, Diseases",
    tree_numbers: ["C16.614"],
  },
  {
    ui: "D007235",
    heading: "Infant, Premature, Diseases",
    tree_numbers: ["C16.614.521"],
  },
  {
    ui: "D007238",
    heading: "Infarction",
    tree_numbers: ["C23.550.513.355", "C23.550.717.489"],
  },
  {
    ui: "D007239",
    heading: "Infections",
    tree_numbers: ["C01"],
  },
  {
    ui: "D007241",
    heading: "Infectious Bovine Rhinotracheitis",
    tree_numbers: ["C01.925.256.466.488", "C22.196.429"],
  },
  {
    ui: "D007244",
    heading: "Infectious Mononucleosis",
    tree_numbers: ["C01.925.256.466.313.400", "C15.378.553.381", "C15.604.515.516", "C20.683.515.515"],
    synonyms: [
      "Mononucleosis",
      "Pfeiffer's Disease",
      "Filatov's Disease",
      "Gammaherpesviral Mononucleosis",
      "Glandular Fever",
      "Monocytic Angina",
    ],
  },
  {
    ui: "D007246",
    heading: "Infertility",
    tree_numbers: ["C12.294.365", "C13.351.500.365"],
  },
  {
    ui: "D007247",
    heading: "Infertility, Female",
    tree_numbers: ["C13.351.500.365.700"],
  },
  {
    ui: "D007248",
    heading: "Infertility, Male",
    tree_numbers: ["C12.294.365.700"],
  },
  {
    ui: "D007249",
    heading: "Inflammation",
    tree_numbers: ["C23.550.470"],
  },
  {
    ui: "D007251",
    heading: "Influenza, Human",
    tree_numbers: ["C01.748.310", "C01.925.782.620.365", "C08.730.310"],
    synonyms: [
      "Flu",
      "Influenza With Other Manifestations",
      "Influenza With Non-Respiratory Manifestation",
      "Influenza With Non-Respiratory Manifestation",
    ],
  },
  {
    ui: "D007280",
    heading: "Disorders of Environmental Origin",
    tree_numbers: ["C21"],
  },
  {
    ui: "D007299",
    heading: "Insect Bites and Stings",
    tree_numbers: ["C25.723.127.071", "C26.176.143"],
  },
  {
    ui: "D007319",
    heading: "Sleep Initiation and Maintenance Disorders",
    tree_numbers: ["C10.886.425.800.800", "F03.870.400.800.800"],
  },
  {
    ui: "D007331",
    heading: "Insulin Coma",
    tree_numbers: ["C10.597.606.358.800.200.600", "C18.452.394.984.492"],
  },
  {
    ui: "D007333",
    heading: "Insulin Resistance",
    tree_numbers: ["C18.452.394.968.500", "G07.690.773.984.617"],
  },
  {
    ui: "D007340",
    heading: "Insulinoma",
    tree_numbers: [
      "C04.557.470.035.100.852",
      "C04.588.274.761.249.500",
      "C04.588.322.475.249.500",
      "C06.301.761.249.500",
      "C06.689.667.249.500",
      "C19.344.421.249.500",
    ],
    synonyms: ["Islet Cell Adenoma", "Insulin-Producing Tumor Of Islet Cells"],
  },
  {
    ui: "D007383",
    heading: "Intermittent Claudication",
    tree_numbers: ["C14.907.137.126.669", "C23.888.531"],
    synonyms: ["Intermittent Claudication", "Charcot's Syndrome"],
  },
  {
    ui: "D007402",
    heading: "Intertrigo",
    tree_numbers: ["C17.800.174.640", "C17.800.815.650"],
  },
  {
    ui: "D007405",
    heading: "Intervertebral Disc Displacement",
    tree_numbers: ["C05.116.900.307", "C23.300.707.952"],
  },
  {
    ui: "D007409",
    heading: "Intestinal Atresia",
    tree_numbers: ["C06.198.719", "C06.405.469.445", "C16.131.314.466"],
  },
  {
    ui: "D007410",
    heading: "Intestinal Diseases",
    tree_numbers: ["C06.405.469"],
  },
  {
    ui: "D007411",
    heading: "Intestinal Diseases, Parasitic",
    tree_numbers: ["C01.610.432", "C06.405.469.452"],
  },
  {
    ui: "D007412",
    heading: "Intestinal Fistula",
    tree_numbers: ["C06.267.550", "C06.405.469.471", "C23.300.575.185.550"],
  },
  {
    ui: "D007414",
    heading: "Intestinal Neoplasms",
    tree_numbers: ["C04.588.274.476.411", "C06.301.371.411", "C06.405.249.411", "C06.405.469.491"],
    synonyms: ["Intestine Growth", "Intestinal Tumors", "Neoplasm Of Intestinal Tract"],
  },
  {
    ui: "D007415",
    heading: "Intestinal Obstruction",
    tree_numbers: ["C06.405.469.531"],
  },
  {
    ui: "D007416",
    heading: "Intestinal Perforation",
    tree_numbers: ["C06.405.469.557"],
    synonyms: ["Perforation Of Intestine"],
  },
  {
    ui: "D007417",
    heading: "Intestinal Polyps",
    tree_numbers: ["C23.300.825.411"],
  },
  {
    ui: "D007418",
    heading: "Intestinal Pseudo-Obstruction",
    tree_numbers: ["C06.405.469.531.492.500"],
    synonyms: ["Chronic Intestinal Pseudo-Obstruction", "Neuronal Intestinal Dysplasia"],
  },
  {
    ui: "D007431",
    heading: "Intraoperative Complications",
    tree_numbers: ["C23.550.505"],
  },
  {
    ui: "D007443",
    heading: "Intussusception",
    tree_numbers: ["C06.405.469.531.577"],
    synonyms: ["Intussusception Of Intestine", "Intussusception", "Invagination Of Intestine Or Colon"],
  },
  {
    ui: "D007446",
    heading: "Chromosome Inversion",
    tree_numbers: ["C23.550.210.190", "G05.365.590.175.190", "G05.365.590.770.500", "G05.558.805.500"],
  },
  {
    ui: "D007499",
    heading: "Iris Diseases",
    tree_numbers: ["C11.941.375"],
  },
  {
    ui: "D007500",
    heading: "Iritis",
    tree_numbers: ["C11.941.375.385", "C11.941.879.780.880.448"],
  },
  {
    ui: "D007511",
    heading: "Ischemia",
    tree_numbers: ["C23.550.513"],
  },
  {
    ui: "D007516",
    heading: "Adenoma, Islet Cell",
    tree_numbers: [
      "C04.557.470.035.100",
      "C04.588.274.761.249",
      "C04.588.322.475.249",
      "C06.301.761.249",
      "C06.689.667.249",
      "C19.344.421.249",
    ],
    synonyms: [
      "Malignant Tumor Of Endocrine Pancreas",
      "Pancreatic Endocrine Neoplasm",
      "Malignant Tumour Of Endocrine Pancreas",
      "Islet Cell Tumor",
      "Malignant Pancreatic Endocrine Tumour",
      "Islet Cell Tumour",
      "Malignant Pancreatic Endocrine Tumor",
      "Islet Cell Tumour",
      "Islet Cell Neoplasm",
      "Endocrine Pancreas Cancer",
    ],
  },
  {
    ui: "D007562",
    heading: "Creutzfeldt-Jakob Syndrome",
    tree_numbers: ["C01.207.800.230", "C10.228.140.380.165", "C10.228.228.800.230", "F03.615.400.300"],
    synonyms: [
      "Creutzfeldt-Jacob Disease",
      "Creutzfeldt Jacob Syndrome",
      "Jakob-Creutzfeldt Disease",
      "Cjd",
      "Subacute Spongiform Encephalopathy",
      "Transmissible Virus Dementia",
      "Creutzfeldt Jakob Disease",
    ],
  },
  {
    ui: "D007565",
    heading: "Jaundice",
    tree_numbers: ["C23.550.429.500", "C23.888.885.375"],
  },
  {
    ui: "D007566",
    heading: "Jaundice, Chronic Idiopathic",
    tree_numbers: ["C16.320.565.300.764", "C16.614.451.500.250", "C18.452.648.300.764"],
    synonyms: ["Chronic Idiopathic Jaundice", "Dubin Johnson Syndrome"],
  },
  {
    ui: "D007567",
    heading: "Jaundice, Neonatal",
    tree_numbers: ["C16.614.451.500", "C23.550.429.249.500"],
    synonyms: ["Neonatal Icterus", "Neonatal Hyperbilirubinemia"],
  },
  {
    ui: "D007569",
    heading: "Jaw Abnormalities",
    tree_numbers: [
      "C05.500.460",
      "C05.660.207.540.460",
      "C07.320.440",
      "C07.650.500.460",
      "C16.131.621.207.540.460",
      "C16.131.850.500.460",
    ],
  },
  {
    ui: "D007570",
    heading: "Jaw Cysts",
    tree_numbers: ["C04.182.089.530", "C05.500.470", "C07.320.450"],
  },
  {
    ui: "D007571",
    heading: "Jaw Diseases",
    tree_numbers: ["C05.500", "C07.320"],
  },
  {
    ui: "D007572",
    heading: "Jaw Fractures",
    tree_numbers: ["C10.900.300.284.500.400", "C26.404.750.467", "C26.915.300.425.500.400"],
  },
  {
    ui: "D007573",
    heading: "Jaw Neoplasms",
    tree_numbers: ["C04.588.149.721.450", "C05.116.231.754.450", "C05.500.499", "C07.320.515"],
    synonyms: ["Neoplasm Of Jaw"],
  },
  {
    ui: "D007575",
    heading: "Jaw, Edentulous",
    tree_numbers: ["C05.500.480", "C07.320.550", "C07.465.550.425", "C07.793.597.425"],
  },
  {
    ui: "D007576",
    heading: "Jaw, Edentulous, Partially",
    tree_numbers: ["C05.500.480.450", "C07.320.550.450", "C07.465.550.425.450", "C07.793.597.425.450"],
  },
  {
    ui: "D007579",
    heading: "Jejunal Diseases",
    tree_numbers: ["C06.405.469.600"],
  },
  {
    ui: "D007580",
    heading: "Jejunal Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.523",
      "C06.301.371.411.523",
      "C06.405.249.411.523",
      "C06.405.469.491.523",
      "C06.405.469.600.523",
    ],
    synonyms: ["Tumor Of Jejunum", "Neoplasm Of Jejunum", "Jejunal Tumor"],
  },
  {
    ui: "D007589",
    heading: "Job Syndrome",
    tree_numbers: ["C15.378.553.774.600", "C16.320.798.688", "C20.673.774.600", "C20.673.795.688"],
    synonyms: ["Hyperimmunoglobulin E Syndrome", "Job Syndrome", "Job's Syndrome"],
  },
  {
    ui: "D007592",
    heading: "Joint Diseases",
    tree_numbers: ["C05.550"],
    synonyms: [
      "Ankylosis Of Ankle And Foot Joint",
      "Ankylosis Of Joint Of Hand",
      "Ankylosis Of Forearm Joint",
      "Joint Ankylosis Of The Lower Leg",
      "Ankylosis Of Multiple Joints",
      "Ankylosis Of Joint Of Forearm",
      "Ankylosis Of Hand Joint",
      "Ankylosis Of Joint Of Pelvic Region And Thigh",
      "Ankylosis Of Joint Of Shoulder Region",
      "Joint Ankylosis Of The Ankle And Foot",
      "Joint Ankylosis Of The Upper Arm",
      "Ankylosis Of Lower Leg Joint",
      "Joint Ankylosis Of The Ankle And/or Foot",
      "Ankylosis Of Joint Of Shoulder Region",
      "Ankylosis Of Upper Arm Joint",
      "Joint Ankylosis Of The Hand",
      "Ankylosis Of Joint Of Lower Leg",
      "Joint Ankylosis Of The Forearm",
      "Ankylosis Of Joint Of Multiple Sites",
      "Joint Ankylosis Of The Pelvic Region And Thigh",
      "Ankylosis Of Joint Of Upper Arm",
      "Joint Ankylosis Of The Shoulder Region",
      "Ankylosis Of Joint Of Multiple Sites",
      "Ankylosis Of Joint Of Ankle And/or Foot",
      "Infectious Arthropathy",
    ],
  },
  {
    ui: "D007593",
    heading: "Joint Instability",
    tree_numbers: ["C05.550.521"],
  },
  {
    ui: "D007594",
    heading: "Joint Loose Bodies",
    tree_numbers: ["C05.550.535"],
  },
  {
    ui: "D007617",
    heading: "Kaposi Varicelliform Eruption",
    tree_numbers: ["C01.925.256.466.382.410", "C01.925.825.320.410", "C17.800.838.790.320.410"],
    synonyms: [
      "Herpes Simplex Virus Dermatitis",
      "Herpes Simplex Dermatitis Of Eyelid",
      "Eczema Herpeticum",
      "Herpes Simplex Virus Eyelid Dermatitis",
      "Herpes Simplex Eyelid Dermatitis",
      "Herpes Simplex Dermatitis",
    ],
  },
  {
    ui: "D007619",
    heading: "Kartagener Syndrome",
    tree_numbers: [
      "C08.127.384.500",
      "C08.200.531",
      "C08.695.501",
      "C09.150.531",
      "C14.240.400.280.500",
      "C14.280.400.280.500",
      "C16.131.077.245.500.531",
      "C16.131.240.400.280.500",
      "C16.131.740.501",
      "C16.131.810.250.500",
      "C16.320.184.500.531",
      "C16.320.480",
    ],
    synonyms: ["Kartagener's Syndrome"],
  },
  {
    ui: "D007625",
    heading: "Kearns-Sayre Syndrome",
    tree_numbers: [
      "C05.651.460.700.500",
      "C10.292.562.750.250.500",
      "C10.597.622.447.511.500",
      "C10.668.491.500.700.500",
      "C11.590.472.250.500",
      "C11.768.585.658.500.627",
      "C14.280.238.510",
      "C18.452.660.410",
      "C18.452.660.560.700.500",
      "C23.888.592.636.447.511.500",
    ],
  },
  {
    ui: "D007627",
    heading: "Keloid",
    tree_numbers: ["A10.165.450.300.425", "C17.300.200.425", "C23.550.355.274.510"],
  },
  {
    ui: "D007634",
    heading: "Keratitis",
    tree_numbers: ["C11.204.564"],
  },
  {
    ui: "D007635",
    heading: "Keratitis, Dendritic",
    tree_numbers: [
      "C01.375.725.465.450",
      "C01.925.256.466.382.465.450",
      "C01.925.325.465.450",
      "C11.204.564.425.450",
      "C11.294.800.475.450",
    ],
    synonyms: ["Dendritic Keratitis"],
  },
  {
    ui: "D007636",
    heading: "Keratoacanthoma",
    tree_numbers: ["C17.800.417"],
  },
  {
    ui: "D007637",
    heading: "Keratoconjunctivitis",
    tree_numbers: ["C11.187.183.394", "C11.204.564.585"],
  },
  {
    ui: "D007638",
    heading: "Keratoconjunctivitis Sicca",
    tree_numbers: ["C11.187.183.394.500", "C11.204.564.585.630", "C11.496.260.394"],
    synonyms: ["Kcs"],
  },
  {
    ui: "D007639",
    heading: "Keratoconjunctivitis, Infectious",
    tree_numbers: [
      "C01.150.252.289.450",
      "C01.375.354.450",
      "C11.187.183.394.520",
      "C11.204.564.585.500",
      "C11.294.354.450",
      "C22.500",
    ],
  },
  {
    ui: "D007640",
    heading: "Keratoconus",
    tree_numbers: ["C11.204.627"],
    synonyms: ["Conical Cornea"],
  },
  {
    ui: "D007642",
    heading: "Keratosis",
    tree_numbers: ["C17.800.428"],
  },
  {
    ui: "D007644",
    heading: "Darier Disease",
    tree_numbers: ["C16.320.850.190", "C17.800.428.275", "C17.800.827.190"],
    synonyms: ["Keratosis Follicularis", "Darier's Disease", "Darier-White Disease"],
  },
  {
    ui: "D007645",
    heading: "Keratoderma, Palmoplantar",
    tree_numbers: ["C16.320.850.475", "C17.800.428.435", "C17.800.827.475"],
  },
  {
    ui: "D007647",
    heading: "Kernicterus",
    tree_numbers: [
      "C10.228.140.163.480",
      "C15.378.295.502",
      "C16.614.304.502",
      "C18.452.132.480",
      "C20.306.502",
      "C23.550.429.750",
    ],
    synonyms: ["Bilirubin Encephalopathy"],
  },
  {
    ui: "D007662",
    heading: "Ketosis",
    tree_numbers: ["C18.452.076.176.652"],
  },
  {
    ui: "D007669",
    heading: "Kidney Calculi",
    tree_numbers: [
      "C12.777.419.600.500",
      "C12.777.967.249.500",
      "C12.777.967.500.503",
      "C13.351.968.419.600.500",
      "C13.351.968.967.249.500",
      "C13.351.968.967.500.503",
      "C23.300.175.850.550",
    ],
  },
  {
    ui: "D007673",
    heading: "Kidney Cortex Necrosis",
    tree_numbers: ["C12.777.419.393", "C13.351.968.419.393"],
    synonyms: ["Renal Cortical Necrosis"],
  },
  {
    ui: "D007674",
    heading: "Kidney Diseases",
    tree_numbers: ["C12.777.419", "C13.351.968.419"],
    synonyms: ["Nephropathy", "Impaired Renal Function Disease"],
  },
  {
    ui: "D007676",
    heading: "Kidney Failure, Chronic",
    tree_numbers: ["C12.777.419.780.750.500", "C13.351.968.419.780.750.500"],
    synonyms: [
      "Chronic Renal Disease",
      "Ckd",
      "Chronic Kidney Failure",
      "Chronic Renal Failure Syndrome",
      "Renal Failure - Chronic",
      "Crf",
    ],
  },
  {
    ui: "D007680",
    heading: "Kidney Neoplasms",
    tree_numbers: [
      "C04.588.945.947.535",
      "C12.758.820.750",
      "C12.777.419.473",
      "C13.351.937.820.535",
      "C13.351.968.419.473",
    ],
    synonyms: ["Malignant Tumour Of Kidney", "Renal Cancer", "Malignant Neoplasm Of Kidney Except Pelvis"],
  },
  {
    ui: "D007681",
    heading: "Kidney Papillary Necrosis",
    tree_numbers: ["C12.777.419.493", "C13.351.968.419.493"],
    synonyms: ["Necrotizing Renal Papillitis", "Renal Papillitis Necrotizing", "Papillary Necrosis"],
  },
  {
    ui: "D007683",
    heading: "Kidney Tubular Necrosis, Acute",
    tree_numbers: ["C12.777.419.780.050.500", "C13.351.968.419.780.050.500"],
    synonyms: [
      "Acute Renal Failure With Tubular Necrosis",
      "Acute Renal Failure With Lesion Of Tubular Necrosis",
      "Acute Tubular Necrosis",
      "Acute Tubule Necrosis",
      "Atn - Acute Tubular Necrosis",
    ],
  },
  {
    ui: "D007690",
    heading: "Polycystic Kidney Diseases",
    tree_numbers: ["C12.777.419.403.875", "C13.351.968.419.403.875", "C16.131.077.717", "C16.320.184.625"],
    synonyms: ["Congenital Biliary Ectasias", "Polycystic Kidney And Hepatic Disease 1"],
  },
  {
    ui: "D007691",
    heading: "Medullary Sponge Kidney",
    tree_numbers: ["C12.777.419.403.500", "C13.351.968.419.403.500"],
  },
  {
    ui: "D007706",
    heading: "Menkes Kinky Hair Syndrome",
    tree_numbers: [
      "C10.228.140.163.100.540",
      "C10.597.606.360.455.687",
      "C16.320.322.500.687",
      "C16.320.400.525.687",
      "C16.320.565.189.540",
      "C16.320.565.618.590",
      "C17.800.329.968",
      "C18.452.132.100.540",
      "C18.452.648.189.540",
      "C18.452.648.618.590",
    ],
    synonyms: ["Menkes Kinky-Hair Syndrome", "Copper Transport Disease", "Steely Hair Syndrome"],
  },
  {
    ui: "D007710",
    heading: "Klebsiella Infections",
    tree_numbers: ["C01.150.252.400.310.503"],
    synonyms: ["Pneumonia Due To Klebsiella Pneumoniae", "Pneumonia Due To Klebsiella Pneumoniae (Disorder)"],
  },
  {
    ui: "D007713",
    heading: "Klinefelter Syndrome",
    tree_numbers: [
      "C12.706.316.795.500",
      "C13.351.875.253.795.500",
      "C16.131.260.830.835.500",
      "C16.131.939.316.795.500",
      "C16.320.180.830.835.500",
      "C19.391.119.795.500",
      "C19.391.482.629",
    ],
    synonyms: ["Xxy Trisomy", "Xxy Syndrome", "Hypogonadotropic Hypogonadism", "Klinefelter's Syndrome", "47, Xxy"],
  },
  {
    ui: "D007714",
    heading: "Klippel-Feil Syndrome",
    tree_numbers: ["C05.116.099.370.535", "C05.660.551", "C16.131.621.551"],
    synonyms: [
      "Congenital Synostosis Of Cervical Vertebrae",
      "Klippel-Feil And Turner Syndrome",
      "Congenital Dystrophia Brevicollis",
      "Autosomal Dominant Klippel-Feil Syndrome",
      "Klippel-Feil Deformity, Deafness And Facial Asymmetry",
    ],
  },
  {
    ui: "D007715",
    heading: "Klippel-Trenaunay-Weber Syndrome",
    tree_numbers: ["C14.907.077.410"],
    synonyms: ["Klippel-Trenaunay-Weber Syndrome", "Angioosteohypertrophy Syndrome", "Haemangiectatic Hypertrophy"],
  },
  {
    ui: "D007718",
    heading: "Knee Injuries",
    tree_numbers: ["C26.558.554"],
  },
  {
    ui: "D007724",
    heading: "Vulvar Lichen Sclerosus",
    tree_numbers: ["C13.351.500.944.815"],
  },
  {
    ui: "D007725",
    heading: "Krukenberg Tumor",
    tree_numbers: ["C04.557.470.200.025.415.410", "C04.557.470.590.415.410"],
    synonyms: ["Krukenberg Neoplasm"],
  },
  {
    ui: "D007729",
    heading: "Kuru",
    tree_numbers: ["C01.207.800.435", "C10.228.228.800.435", "C10.574.843.625"],
    synonyms: ["Kuru Encephalopathy"],
  },
  {
    ui: "D007732",
    heading: "Kwashiorkor",
    tree_numbers: ["C18.654.521.719.500"],
    synonyms: [
      "Nutritional Edema With Dyspigmentation Of Skin And Hair",
      "Kwashiokor",
      "Nutritional Oedema With Dyspigmentation Of Skin And/or Hair",
    ],
  },
  {
    ui: "D007733",
    heading: "Kyasanur Forest Disease",
    tree_numbers: ["C01.920.930.475", "C01.925.081.656", "C01.925.782.350.250.635", "C01.925.782.417.475"],
  },
  {
    ui: "D007738",
    heading: "Kyphosis",
    tree_numbers: ["C05.116.900.800.500"],
  },
  {
    ui: "D007744",
    heading: "Obstetric Labor Complications",
    tree_numbers: ["C13.703.420"],
  },
  {
    ui: "D007752",
    heading: "Obstetric Labor, Premature",
    tree_numbers: ["C13.703.420.491"],
  },
  {
    ui: "D007757",
    heading: "Laboratory Infection",
    tree_numbers: ["C01.503", "C24.506"],
  },
  {
    ui: "D007759",
    heading: "Labyrinth Diseases",
    tree_numbers: ["C09.218.568"],
    synonyms: ["Labyrinthine Disease"],
  },
  {
    ui: "D007762",
    heading: "Labyrinthitis",
    tree_numbers: ["C09.218.568.558", "C09.218.705.371"],
  },
  {
    ui: "D007766",
    heading: "Lacrimal Apparatus Diseases",
    tree_numbers: ["C11.496"],
    synonyms: ["Excessive Tear Production", "Epiphora", "Watering Eye"],
  },
  {
    ui: "D007767",
    heading: "Lacrimal Duct Obstruction",
    tree_numbers: ["C11.496.456"],
    synonyms: ["Obstruction Of Lacrimal Canaliculus", "Obstruction Of Lacrimal Ducts", "Blocked Lacrimal Canaliculus"],
  },
  {
    ui: "D007775",
    heading: "Lactation Disorders",
    tree_numbers: ["C13.703.844.506", "C17.800.090.937"],
  },
  {
    ui: "D007787",
    heading: "Lactose Intolerance",
    tree_numbers: ["C06.405.469.637.506", "C16.320.565.202.589", "C18.452.603.506", "C18.452.648.202.589"],
    synonyms: ["Lm - Lactose Malabsorption"],
  },
  {
    ui: "D007794",
    heading: "Lameness, Animal",
    tree_numbers: ["C22.510"],
  },
  {
    ui: "D007805",
    heading: "Language Development Disorders",
    tree_numbers: ["C10.597.606.150.500.550", "C23.888.592.604.150.500.550"],
  },
  {
    ui: "D007806",
    heading: "Language Disorders",
    tree_numbers: ["C10.597.606.150.500", "C23.888.592.604.150.500"],
  },
  {
    ui: "D007815",
    heading: "Larva Migrans",
    tree_numbers: ["C01.610.335.508.523", "C01.610.858.424", "C17.800.838.775.424"],
  },
  {
    ui: "D007816",
    heading: "Larva Migrans, Visceral",
    tree_numbers: ["C01.610.335.508.523.780", "C01.610.335.508.700.100.868.420"],
    synonyms: ["Toxocara Infection", "Visceral Larva Migrans", "Infection By Toxascaris"],
  },
  {
    ui: "D007818",
    heading: "Laryngeal Diseases",
    tree_numbers: ["C08.360", "C09.400"],
  },
  {
    ui: "D007819",
    heading: "Laryngeal Edema",
    tree_numbers: ["C08.360.313", "C09.400.313"],
  },
  {
    ui: "D007822",
    heading: "Laryngeal Neoplasms",
    tree_numbers: ["C04.588.443.665.481", "C08.360.369", "C08.785.481", "C09.400.369", "C09.647.481"],
    synonyms: ["Larynx Neoplasm", "Neoplasm Of Larynx", "Laryngeal Tumor"],
  },
  {
    ui: "D007826",
    heading: "Laryngismus",
    tree_numbers: ["C08.360.895.500", "C08.618.980.500", "C09.400.895.500"],
  },
  {
    ui: "D007827",
    heading: "Laryngitis",
    tree_numbers: ["C01.748.368", "C08.360.535", "C08.730.368", "C09.400.535"],
  },
  {
    ui: "D007829",
    heading: "Laryngostenosis",
    tree_numbers: ["C08.360.591", "C09.400.591", "C16.131.740.658"],
    synonyms: ["Stenosis Of Larynx"],
  },
  {
    ui: "D007835",
    heading: "Lassa Fever",
    tree_numbers: ["C01.925.782.082.545", "C01.925.782.417.505"],
  },
  {
    ui: "D007842",
    heading: "Lathyrism",
    tree_numbers: ["C25.723.756.558"],
  },
  {
    ui: "D007849",
    heading: "Laurence-Moon Syndrome",
    tree_numbers: ["C10.228.140.617.500", "C16.131.077.509"],
    synonyms: ["Lnms"],
  },
  {
    ui: "D007855",
    heading: "Lead Poisoning",
    tree_numbers: ["C25.723.522.750"],
  },
  {
    ui: "D007859",
    heading: "Learning Disabilities",
    tree_numbers: ["C10.597.606.150.550", "C23.888.592.604.150.550", "F03.625.562"],
    synonyms: ["Learning Disorder", "Academic Skill Disorder"],
  },
  {
    ui: "D007863",
    heading: "Lecithin Cholesterol Acyltransferase Deficiency",
    tree_numbers: ["C16.320.565.398.500.330.500", "C18.452.584.500.875.330.500", "C18.452.648.398.500.330.500"],
    synonyms: ["Lecithin Acyltransferase Deficiency", "Lecithin:cholesterol Acyltransferase Deficiency"],
  },
  {
    ui: "D007868",
    heading: "Leg Dermatoses",
    tree_numbers: ["C17.800.446"],
  },
  {
    ui: "D007869",
    heading: "Leg Injuries",
    tree_numbers: ["C26.558"],
  },
  {
    ui: "D007870",
    heading: "Leg Length Inequality",
    tree_numbers: ["C05.116.099.655", "C23.300.808"],
  },
  {
    ui: "D007871",
    heading: "Leg Ulcer",
    tree_numbers: ["C17.800.893.592"],
  },
  {
    ui: "D007873",
    heading: "Legg-Calve-Perthes Disease",
    tree_numbers: ["C05.116.852.175.570"],
    synonyms: [
      "Juvenile Osteochondrosis Of Hip And Pelvis",
      "Perthe's Disease",
      "Juvenile Osteochondrosis Of Hip And/or Pelvis",
      "Perthes Disease",
      "Calve - Perthes' Disease",
      "Juvenile Osteochond-Hip/pelvis",
      "Pseudocoxalgia",
      "Pseudocoxalgia",
      "Osteochondrosis Of Legg-Calve-Perthes",
      "Coxa Plana",
    ],
  },
  {
    ui: "D007876",
    heading: "Legionellosis",
    tree_numbers: ["C01.150.252.400.500", "C01.748.382", "C08.730.382"],
    synonyms: ["Legionella Infection"],
  },
  {
    ui: "D007877",
    heading: "Legionnaires' Disease",
    tree_numbers: ["C01.150.252.400.500.501", "C01.748.382.380", "C08.730.382.380"],
    synonyms: ["Infection By Legionella Pneumophilia", "Legionella Pneumonia", "Legionnaire's Disease", "Legionella"],
  },
  {
    ui: "D007888",
    heading: "Leigh Disease",
    tree_numbers: [
      "C10.228.140.163.100.412",
      "C16.320.565.189.412",
      "C16.320.565.202.810.444",
      "C18.452.132.100.412",
      "C18.452.648.189.412",
      "C18.452.648.202.810.444",
      "C18.452.660.520",
    ],
    synonyms: [
      "Juvenile Subacute Necrotizing Encephalomyelopathy",
      "Leigh Syndrome",
      "Infantile Necrotizing Encephalomyelopathy",
    ],
  },
  {
    ui: "D007889",
    heading: "Leiomyoma",
    tree_numbers: ["C04.557.450.590.450"],
    synonyms: ["Leiomyomatous Neoplasm", "Leiomyomatous Tumor"],
  },
  {
    ui: "D007890",
    heading: "Leiomyosarcoma",
    tree_numbers: ["C04.557.450.590.455", "C04.557.450.795.455"],
    synonyms: ["Leiomyosarcomas"],
  },
  {
    ui: "D007896",
    heading: "Leishmaniasis",
    tree_numbers: ["C01.610.752.300.500", "C01.610.858.560", "C01.920.813", "C17.800.838.775.560"],
    synonyms: [],
  },
  {
    ui: "D007897",
    heading: "Leishmaniasis, Mucocutaneous",
    tree_numbers: [
      "C01.610.752.300.500.400.385",
      "C01.610.858.560.400.385",
      "C01.920.813.400.385",
      "C17.800.838.775.560.400.385",
    ],
    synonyms: [
      "American Mucocutaneous Leishmaniasis",
      "Cutaneous Leishmaniasis, American",
      "American Cutaneous Leishmaniasis",
      "American Cutaneous Leishmaniasis",
      "Mucocutaneous Leishmaniasis, American",
      "Mucocutaneous Leishmaniasis",
      "New World Cutaneous Leishmaniasis",
    ],
  },
  {
    ui: "D007898",
    heading: "Leishmaniasis, Visceral",
    tree_numbers: ["C01.610.752.300.500.510", "C01.920.813.510"],
    synonyms: ["Kala-Azar", "Infection By Visceral Leishmaniasis"],
  },
  {
    ui: "D007905",
    heading: "Lens Diseases",
    tree_numbers: ["C11.510"],
  },
  {
    ui: "D007906",
    heading: "Lens Subluxation",
    tree_numbers: ["C11.510.598"],
    synonyms: ["Subluxation Of Lens"],
  },
  {
    ui: "D007911",
    heading: "Lentigo",
    tree_numbers: ["C17.800.621.430.530.550"],
  },
  {
    ui: "D007918",
    heading: "Leprosy",
    tree_numbers: ["C01.150.252.410.040.552.475.371"],
  },
  {
    ui: "D007922",
    heading: "Leptospirosis",
    tree_numbers: ["C01.150.252.400.794.511"],
    synonyms: [
      "Leptospirosis Icterohaemorrhagica",
      "Weil's Disease",
      "Rat Catcher's Yellows",
      "Nanukayami Fever",
      "Fort Bragg Fever",
      "Spirochetal Jaundice",
    ],
  },
  {
    ui: "D007925",
    heading: "Leriche Syndrome",
    tree_numbers: ["C14.907.109.661", "C14.907.137.427"],
  },
  {
    ui: "D007926",
    heading: "Lesch-Nyhan Syndrome",
    tree_numbers: [
      "C10.228.140.163.100.425",
      "C10.597.606.360.455.625",
      "C16.320.322.500.625",
      "C16.320.400.525.625",
      "C16.320.565.189.425",
      "C16.320.565.798.594",
      "C18.452.132.100.425",
      "C18.452.648.189.425",
      "C18.452.648.798.594",
    ],
    synonyms: [
      "Complete Hypoxanthine-Guanine Phosphoribosyltransferase Deficiency",
      "Hypoxanthine-Guanine-Phosphoribosyltransferase Deficiency",
      "Hypoxanthine-Guanine Phosphoribosyltransferase Deficiency",
      "Hg-Prt Deficiency",
      "Deficiency Of Imp Pyrophosphorylase",
      "X-Linked Hyperuricemia",
      "Hypoxanthine Guanine Phosphoribosyltransferase Deficiency",
      "Lesch - Nyhan Syndrome",
    ],
  },
  {
    ui: "D007938",
    heading: "Leukemia",
    tree_numbers: ["C04.557.337"],
  },
  {
    ui: "D007939",
    heading: "Leukemia L1210",
    tree_numbers: ["C04.557.337.372.594", "C04.619.531.594"],
  },
  {
    ui: "D007940",
    heading: "Leukemia L5178",
    tree_numbers: ["C04.557.337.372.602", "C04.619.531.602"],
  },
  {
    ui: "D007941",
    heading: "Leukemia P388",
    tree_numbers: ["C04.557.337.372.782", "C04.619.531.782"],
  },
  {
    ui: "D007942",
    heading: "Leukemia, Experimental",
    tree_numbers: ["C04.557.337.372", "C04.619.531", "E05.598.500.496.500"],
  },
  {
    ui: "D007943",
    heading: "Leukemia, Hairy Cell",
    tree_numbers: ["C04.557.337.415", "C15.604.515.553", "C20.683.515.517"],
  },
  {
    ui: "D007945",
    heading: "Leukemia, Lymphoid",
    tree_numbers: ["C04.557.337.428", "C15.604.515.560", "C20.683.515.528"],
    synonyms: ["Lymphoblastic Leukaemia", "Lymphoid Leukemia", "Lymphoblastic Leukemia", "Lymphocytic Leukaemia"],
  },
  {
    ui: "D007946",
    heading: "Leukemia, Mast-Cell",
    tree_numbers: ["C04.557.337.440", "C04.557.337.539.275.440", "C04.557.450.565.465.750.500"],
  },
  {
    ui: "D007947",
    heading: "Leukemia, Megakaryoblastic, Acute",
    tree_numbers: ["C04.557.337.539.275.450"],
    synonyms: ["Megakaryocytic Myelosis", "Thrombocytic Leukaemia"],
  },
  {
    ui: "D007948",
    heading: "Leukemia, Monocytic, Acute",
    tree_numbers: ["C04.557.337.539.275.484"],
    synonyms: [
      "Acute Monocytic Leukemia Without Mention Of Remission",
      "Acute Monocytic Leukemia, Fab M5",
      "Acute Monocytic Leukaemia",
      "Acute Monoblastic Leukemia And Acute Monocytic Leukemia",
      "Acute Monocytic Leukaemia Without Mention Of Remission",
      "Acute Monocytic Leukemia, Morphology",
    ],
  },
  {
    ui: "D007951",
    heading: "Leukemia, Myeloid",
    tree_numbers: ["C04.557.337.539"],
    synonyms: [
      "Leukemia Myelogenous",
      "Leukaemia Myelogenous",
      "Myeloid Granulocytic Leukaemia",
      "Myeloid Granulocytic Leukemia",
      "Non-Lymphocytic Leukemia",
      "Myeloid Leukaemia",
    ],
  },
  {
    ui: "D007952",
    heading: "Leukemia, Plasma Cell",
    tree_numbers: ["C04.557.337.595", "C04.557.595.500.500", "C20.683.515.845.500"],
    synonyms: ["Plasmacytic Leukemia", "Plasmacytic Leukaemia", "Plasma Cell Leukaemia"],
  },
  {
    ui: "D007953",
    heading: "Leukemia, Radiation-Induced",
    tree_numbers: ["C04.557.337.650", "C04.682.512", "C26.733.345", "G01.750.748.500.345"],
  },
  {
    ui: "D007955",
    heading: "Leukemoid Reaction",
    tree_numbers: ["C15.378.190.636.484", "C15.378.553.475.428"],
    synonyms: [],
  },
  {
    ui: "D007960",
    heading: "Leukocyte Disorders",
    tree_numbers: ["C15.378.553"],
  },
  {
    ui: "D007964",
    heading: "Leukocytosis",
    tree_numbers: ["C15.378.553.475", "C23.550.526"],
  },
  {
    ui: "D007965",
    heading: "Leukodystrophy, Globoid Cell",
    tree_numbers: [
      "C10.228.140.163.100.362.500",
      "C10.228.140.163.100.435.825.590",
      "C10.228.140.695.625.500",
      "C10.314.400.500",
      "C16.320.565.189.362.500",
      "C16.320.565.189.435.825.590",
      "C16.320.565.398.641.803.585",
      "C16.320.565.595.554.825.590",
      "C18.452.132.100.362.500",
      "C18.452.132.100.435.825.590",
      "C18.452.584.687.803.585",
      "C18.452.648.189.362.500",
      "C18.452.648.189.435.825.590",
      "C18.452.648.398.641.803.585",
      "C18.452.648.595.554.825.590",
    ],
    synonyms: [
      "Krabbe's Leukodystrophy",
      "Globoid Cell Leukoencephalopathy",
      "Galactosylceramide Beta-Galactosidase Deficiency",
      "Krabbe's Disease",
      "Globoid Cell Leukodystrophy",
      "Diffuse Globoid Body Sclerosis",
      "Beta Galactocerebrosidase Deficiency",
    ],
  },
  {
    ui: "D007966",
    heading: "Leukodystrophy, Metachromatic",
    tree_numbers: [
      "C10.228.140.163.100.362.550",
      "C10.228.140.163.100.435.825.850.500",
      "C10.228.140.695.625.550",
      "C10.314.400.550",
      "C16.320.565.189.362.550",
      "C16.320.565.189.435.825.850.500",
      "C16.320.565.398.641.803.925.500",
      "C16.320.565.595.554.825.850.500",
      "C18.452.132.100.362.550",
      "C18.452.132.100.435.825.850.500",
      "C18.452.584.687.803.925.500",
      "C18.452.648.189.362.550",
      "C18.452.648.189.435.825.850.500",
      "C18.452.648.398.641.803.925.500",
      "C18.452.648.595.554.825.850.500",
    ],
    synonyms: [
      "Mld",
      "Arylsulfatase A Deficiency",
      "Deficiency Of Cerebroside-Sulfatase",
      "Scholz Cerebral Sclerosis",
      "Sulfatide Lipoidosis",
    ],
  },
  {
    ui: "D007967",
    heading: "Leukoedema, Oral",
    tree_numbers: ["C07.465.385"],
    synonyms: ["Leukedema Of Mouth"],
  },
  {
    ui: "D007968",
    heading: "Leukoencephalopathy, Progressive Multifocal",
    tree_numbers: [
      "C01.207.245.340.500",
      "C01.207.399.750.500",
      "C01.925.182.525.500",
      "C01.925.256.721.500",
      "C01.925.839.550",
      "C10.228.140.430.520.750.500",
      "C10.228.140.695.750",
      "C10.228.228.245.340.500",
      "C10.228.228.399.750.500",
      "C10.314.450",
    ],
    synonyms: [],
  },
  {
    ui: "D007969",
    heading: "Leukomalacia, Periventricular",
    tree_numbers: ["C10.228.140.300.700", "C10.228.140.461.550", "C14.907.253.612", "C16.614.521.450"],
  },
  {
    ui: "D007970",
    heading: "Leukopenia",
    tree_numbers: ["C15.378.553.546"],
    synonyms: ["Leucopenia"],
  },
  {
    ui: "D007971",
    heading: "Leukoplakia",
    tree_numbers: ["C04.834.512", "C23.300.816"],
  },
  {
    ui: "D007972",
    heading: "Leukoplakia, Oral",
    tree_numbers: ["C04.588.443.591.545", "C04.834.512.513", "C07.465.530.545", "C23.300.816.513"],
  },
  {
    ui: "D007973",
    heading: "Leukorrhea",
    tree_numbers: ["C13.351.500.894.700.500"],
    synonyms: ["Discharge - Leukorrhea", "Leukorrhea Of Vagina"],
  },
  {
    ui: "D007979",
    heading: "Levocardia",
    tree_numbers: ["C14.240.400.701", "C14.280.400.701", "C16.131.240.400.701", "C16.131.810.700"],
  },
  {
    ui: "D007984",
    heading: "Leydig Cell Tumor",
    tree_numbers: [
      "C04.557.475.750.847.249",
      "C04.588.322.762.500.249",
      "C04.588.945.440.915.500.249",
      "C12.294.260.937.500.249",
      "C12.758.409.937.500.249",
      "C19.344.762.500.249",
      "C19.391.829.782.500.249",
    ],
    synonyms: ["Leydig Cell Neoplasm"],
  },
  {
    ui: "D008010",
    heading: "Lichen Planus",
    tree_numbers: ["C17.800.859.475.560"],
    synonyms: ["Lichen Ruber Planus", "Lichen, Ruber Planus"],
  },
  {
    ui: "D008039",
    heading: "Linitis Plastica",
    tree_numbers: ["C04.557.470.200.025.095.410"],
    synonyms: ["Linitis Plastica", "Leather-Bottle Stomach"],
  },
  {
    ui: "D008047",
    heading: "Lip Diseases",
    tree_numbers: ["C07.465.409"],
    synonyms: ["Disease Of Lips"],
  },
  {
    ui: "D008048",
    heading: "Lip Neoplasms",
    tree_numbers: ["C04.588.443.591.550", "C07.465.409.640", "C07.465.530.550"],
    synonyms: [
      "Malignant Tumour Of Labial Commissure",
      "Malignant Neoplasm Of Lip",
      "Malignant Neoplasm Of Commissure Of Lip",
      "Malignant Neoplasm Of Lower Lip, Mucosa",
      "Malignant Tumor Of Lower Labial Mucosa",
      "Malignant Neoplasm Of Lower Lip, Inner Aspect",
      "Malignant Tumor Of The Lip",
      "Malignant Tumour Of Lip",
      "Malignant Neoplasm Of Lip, External",
      "Malignant Tumor Of Labial Mucosa",
      "Malignant Tumor Of Commissure Of Lip",
      "Malignant Neoplasm Of Lip, Inner Aspect",
      "Malignant Neoplasm Of Vermilion Border Of Lip",
      "Malignant Tumor Of Lip",
      "Malignant Neoplasm Of External Lip, Not Specified As Upper Or Lower",
      "Malignant Neoplasm Of Labial Commissure Of Lip",
      "Malignant Neoplasm Of Other Sites Of Lip",
      "Malignant Neoplasm Of Lip, Vermilion Border",
      "Malignant Neoplasm Of Lower Lip, Buccal Aspect",
      "Malignant Neoplasm Of Lower Lip, Oral Aspect",
      "Malignant Neoplasm Of Oral Aspect Of Lip, Not Specified Whether Upper Or Lower",
    ],
  },
  {
    ui: "D008052",
    heading: "Lipid Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.398", "C18.452.584.562", "C18.452.648.398"],
    synonyms: ["Dyslipidemia", "Fatty Acid Metabolism Disorder"],
  },
  {
    ui: "D008059",
    heading: "Mucopolysaccharidosis I",
    tree_numbers: [
      "C16.320.565.202.715.640",
      "C16.320.565.595.600.640",
      "C17.300.550.575.640",
      "C18.452.648.202.715.640",
      "C18.452.648.595.600.640",
    ],
    synonyms: ["Mucopolysaccharidosis Type 1h/s", "Mps1h/s", "Mpsih/s"],
  },
  {
    ui: "D008060",
    heading: "Lipodystrophy",
    tree_numbers: ["C17.800.849.391", "C18.452.584.625", "C18.452.880.391"],
  },
  {
    ui: "D008061",
    heading: "Whipple Disease",
    tree_numbers: ["C01.150.252.410.040.137.631", "C06.405.469.637.925", "C18.452.603.925"],
    synonyms: ["Intestinal Lipodystrophy", "Whipple's Disease"],
  },
  {
    ui: "D008064",
    heading: "Lipidoses",
    tree_numbers: ["C16.320.565.398.641", "C18.452.584.687", "C18.452.648.398.641"],
    synonyms: ["Lipoidosis", "Inborn Lipid Storage Disorder", "Lipoidosis", "Lipoid Storage Diseas"],
  },
  {
    ui: "D008065",
    heading: "Lipoid Proteinosis of Urbach and Wiethe",
    tree_numbers: ["C08.618.490.500", "C16.320.850.595"],
    synonyms: ["Urbach-Wiethe Disease", "Lipoid Proteinosis", "Lipid Proteinosis"],
  },
  {
    ui: "D008067",
    heading: "Lipoma",
    tree_numbers: ["C04.557.450.550.400"],
    synonyms: [
      "Lipomatosis, Familial Multiple",
      "Benign Tumor Of Adipose Tissue",
      "Benign Lipomatous Tumor",
      "Tumor Of Adipose Tissue",
      "Multiple Lipomatosis",
      "Lipomatous Neoplasm",
      "Lipomatous Tumor",
    ],
  },
  {
    ui: "D008068",
    heading: "Lipomatosis",
    tree_numbers: ["C17.800.463", "C18.452.584.718"],
  },
  {
    ui: "D008069",
    heading: "Lipomatosis, Multiple Symmetrical",
    tree_numbers: ["C17.800.463.500", "C18.452.584.718.750"],
    synonyms: [
      "Launois-Bensaude's Lipomatosis",
      "Multiple Symmetric Lipomatosis",
      "Multiple Symmetrical Lipomatosis",
      "Madelung's Neck",
      "Lipomatosis, Familial Benign Cervical",
      "Lipodystrophy, Cephalothoracic",
      "Cervical Symmetrical Lipomatosis",
    ],
  },
  {
    ui: "D008072",
    heading: "Hyperlipoproteinemia Type I",
    tree_numbers: ["C16.320.565.398.465", "C18.452.584.500.500.644.237", "C18.452.648.398.465"],
    synonyms: [
      "Hyperlipoproteinemia, Type 1b",
      "Hyperlipoproteinemia, Type Ib",
      "Familial Apoc-Ii Deficiency",
      "Familial Apoc2 Deficiency",
      "C-Ii Anapolipoproteinemia",
    ],
  },
  {
    ui: "D008080",
    heading: "Liposarcoma",
    tree_numbers: ["C04.557.450.550.420", "C04.557.450.795.465"],
    synonyms: ["Lipomatous Cancer"],
  },
  {
    ui: "D008088",
    heading: "Listeriosis",
    tree_numbers: ["C01.150.252.410.514"],
    synonyms: ["Listeria Infection", "Infection By Listeria Monocytogenes"],
  },
  {
    ui: "D008100",
    heading: "Liver Abscess",
    tree_numbers: ["C01.830.025.020.455", "C06.552.597"],
  },
  {
    ui: "D008101",
    heading: "Liver Abscess, Amebic",
    tree_numbers: [
      "C01.610.518.600",
      "C01.610.752.049.703",
      "C01.830.025.020.455.460",
      "C06.552.597.517",
      "C06.552.664.642",
    ],
  },
  {
    ui: "D008103",
    heading: "Liver Cirrhosis",
    tree_numbers: ["C06.552.630", "C23.550.355.412"],
    synonyms: ["Cirrhosis Of Liver", "Cirrhosis"],
  },
  {
    ui: "D008104",
    heading: "Liver Cirrhosis, Alcoholic",
    tree_numbers: ["C06.552.630.380", "C06.552.645.590", "C23.550.355.412.380", "C25.775.100.087.645.550"],
    synonyms: [
      "Laennec's Cirrhosis",
      "Laennec's Cirrhosis, Alcoholic",
      "Alcoholic Cirrhosis Of Liver",
      "Alcoholic Cirrhosis",
      "Portal Cirrhosis",
    ],
  },
  {
    ui: "D008105",
    heading: "Liver Cirrhosis, Biliary",
    tree_numbers: ["C06.130.120.135.250.250", "C06.552.150.250", "C06.552.630.400", "C23.550.355.412.400"],
    synonyms: [
      "Primary Biliary Cirrhosis",
      "Biliary Liver Cirrhosis",
      "Chronic Nonsuppurative Destructive Cholangitis",
      "Cholestatic Cirrhosis",
    ],
  },
  {
    ui: "D008106",
    heading: "Liver Cirrhosis, Experimental",
    tree_numbers: ["C06.552.630.467", "C23.550.355.412.467", "E05.598.500.468"],
  },
  {
    ui: "D008107",
    heading: "Liver Diseases",
    tree_numbers: ["C06.552"],
    synonyms: ["Disorder Of Liver", "Hepatic Disorder"],
  },
  {
    ui: "D008108",
    heading: "Liver Diseases, Alcoholic",
    tree_numbers: ["C06.552.645", "C25.775.100.087.645"],
  },
  {
    ui: "D008109",
    heading: "Liver Diseases, Parasitic",
    tree_numbers: ["C01.610.518", "C06.552.664"],
  },
  {
    ui: "D008113",
    heading: "Liver Neoplasms",
    tree_numbers: ["C04.588.274.623", "C06.301.623", "C06.552.697"],
    synonyms: [
      "Primary Liver Cancer",
      "Hepatic Cancer",
      "Ca Liver - Primary",
      "Ca Liver - Primary",
      "Hepatic Neoplasm",
      "Malignant Tumor Of Liver",
      "Primary Malignant Neoplasm Of Liver",
      "Primary Malignant Neoplasm Of Liver",
      "Neoplasm Of Liver",
      "Resectable Malignant Neoplasm Of The Liver",
      "Non-Resectable Primary Hepatic Malignant Neoplasm",
      "Malignant Hepato-Biliary Neoplasm",
      "Malignant Neoplasm Of Liver, Primary",
      "Malignant Neoplasm Of Liver, Not Specified As Primary Or Secondary",
      "Malignant Neoplasm Of Liver",
      "Resectable Malignant Neoplasm Of Liver",
    ],
  },
  {
    ui: "D008114",
    heading: "Liver Neoplasms, Experimental",
    tree_numbers: ["C04.588.274.623.460", "C04.619.540", "C06.301.623.460", "C06.552.697.580", "E05.598.500.496.750"],
  },
  {
    ui: "D008118",
    heading: "Loiasis",
    tree_numbers: ["C01.610.335.508.700.750.361.518"],
  },
  {
    ui: "D008133",
    heading: "Long QT Syndrome",
    tree_numbers: ["C14.280.067.565", "C14.280.123.625", "C16.131.240.400.715", "C23.550.073.547"],
    synonyms: ["Lqt", "Romano-Ward Syndrome", "Long Q-T Syndrome"],
  },
  {
    ui: "D008141",
    heading: "Lordosis",
    tree_numbers: ["C05.116.900.800.750"],
  },
  {
    ui: "D008146",
    heading: "Louping Ill",
    tree_numbers: ["C01.925.782.350.250.650", "C22.836.435"],
    synonyms: ["Louping Ill"],
  },
  {
    ui: "D008151",
    heading: "Lown-Ganong-Levine Syndrome",
    tree_numbers: ["C14.280.067.780.560", "C14.280.123.750.560"],
    synonyms: [
      "Atrial Tachyarrhythmia With Short Pr Interval",
      "Syndrome Of Short P-R Interval, Normal Qrs Complexes, And Supraventricular Tachycardias",
    ],
  },
  {
    ui: "D008158",
    heading: "Ludwig's Angina",
    tree_numbers: ["C01.800.130.500", "C07.465.433"],
    synonyms: ["Cellulitis Of Floor Of Mouth", "Ludwig Angina"],
  },
  {
    ui: "D008166",
    heading: "Lumpy Skin Disease",
    tree_numbers: ["C01.925.256.743.494", "C22.196.497"],
  },
  {
    ui: "D008169",
    heading: "Lung Abscess",
    tree_numbers: ["C01.748.407", "C01.830.025.490", "C08.381.450", "C08.730.407"],
  },
  {
    ui: "D008171",
    heading: "Lung Diseases",
    tree_numbers: ["C08.381"],
  },
  {
    ui: "D008172",
    heading: "Lung Diseases, Fungal",
    tree_numbers: ["C01.150.703.534", "C01.748.435", "C08.381.472", "C08.730.435"],
  },
  {
    ui: "D008173",
    heading: "Lung Diseases, Obstructive",
    tree_numbers: ["C08.381.495"],
    synonyms: ["Respiratory Airway Obstruction"],
  },
  {
    ui: "D008174",
    heading: "Lung Diseases, Parasitic",
    tree_numbers: ["C01.610.582", "C01.748.450", "C08.381.517", "C08.730.450"],
  },
  {
    ui: "D008175",
    heading: "Lung Neoplasms",
    tree_numbers: ["C04.588.894.797.520", "C08.381.540", "C08.785.520"],
    synonyms: ["Tumor Of The Lung"],
  },
  {
    ui: "D008177",
    heading: "Lupus Vulgaris",
    tree_numbers: ["C01.150.252.819.820.470", "C01.800.720.820.470", "C17.800.838.765.820.470"],
  },
  {
    ui: "D008178",
    heading: "Lupus Erythematosus, Cutaneous",
    tree_numbers: ["C17.300.475", "C17.800.480"],
  },
  {
    ui: "D008179",
    heading: "Lupus Erythematosus, Discoid",
    tree_numbers: ["C17.300.475.479", "C17.800.480.479"],
  },
  {
    ui: "D008180",
    heading: "Lupus Erythematosus, Systemic",
    tree_numbers: ["C17.300.480", "C20.111.590"],
    synonyms: [
      "Disseminated Lupus Erythematosus",
      "Sle - Lupus Erythematosus, Systemic",
      "Lupus Erythematosus, Systemic",
    ],
  },
  {
    ui: "D008181",
    heading: "Lupus Nephritis",
    tree_numbers: ["C12.777.419.570.363.680", "C13.351.968.419.570.363.680", "C17.300.480.680", "C20.111.590.560"],
  },
  {
    ui: "D008185",
    heading: "Lutembacher Syndrome",
    tree_numbers: ["C14.240.400.560.375.518", "C14.280.400.560.375.518", "C16.131.240.400.560.375.518"],
    synonyms: ["Lutembacher Syndrome", "Lutembacher's Anomaly"],
  },
  {
    ui: "D008193",
    heading: "Lyme Disease",
    tree_numbers: ["C01.150.252.400.536", "C01.150.252.400.794.352.250", "C01.920.930.513"],
    synonyms: [
      "Neuroborreliosis",
      "Bannwarth Syndrome",
      "Lyme Borreliosis",
      "Neurological Lyme Disease",
      "Lyme Neuroborreliosis",
      "Bannworth's Syndrome",
    ],
  },
  {
    ui: "D008199",
    heading: "Lymphadenitis",
    tree_numbers: ["C15.604.315"],
    synonyms: [
      "Inflammation Of Lymph Node",
      "Acute Lymphadenitis",
      "Adenitis",
      "Acute Adenitis",
      "Chronic Adenitis",
      "Chronic Lymphadenitis",
    ],
  },
  {
    ui: "D008200",
    heading: "Lymphangiectasis",
    tree_numbers: ["C15.604.360"],
  },
  {
    ui: "D008201",
    heading: "Lymphangiectasis, Intestinal",
    tree_numbers: ["C15.604.360.500", "C15.604.451.500", "C16.131.482.500"],
  },
  {
    ui: "D008202",
    heading: "Lymphangioma",
    tree_numbers: ["C04.557.375.450"],
    synonyms: ["Congenital Lymphangioma", "Benign Lymphangioma"],
  },
  {
    ui: "D008203",
    heading: "Lymphangiomyoma",
    tree_numbers: ["C04.557.375.460", "C15.604.515.562", "C20.683.515.710"],
  },
  {
    ui: "D008204",
    heading: "Lymphangiosarcoma",
    tree_numbers: ["C04.557.375.480", "C04.557.450.795.480"],
    synonyms: [
      "Stewart-Treves Syndrome",
      "Lymphangiosarcoma Of Stewart And Treves",
      "Malignant Lymphangioendothelioma",
      "Skin Lymphangiosarcoma",
    ],
  },
  {
    ui: "D008205",
    heading: "Lymphangitis",
    tree_numbers: ["C15.604.406"],
  },
  {
    ui: "D008206",
    heading: "Lymphatic Diseases",
    tree_numbers: ["C15.604"],
    synonyms: [
      "Disorder Of Lymphatic System",
      "Lymphatic Disorder",
      "Lymphatic Disorder",
      "Disease Of Lympoid System",
      "Adenopathy",
      "Lympoid System Disease",
      "Lymphatic Disease",
      "Lymphangiopathy",
      "Disorder Of Lymphoid System",
      "Disorder Of Lymph Node And Lymphatics",
    ],
  },
  {
    ui: "D008207",
    heading: "Lymphatic Metastasis",
    tree_numbers: ["C04.697.650.560", "C23.550.727.650.560"],
  },
  {
    ui: "D008209",
    heading: "Lymphedema",
    tree_numbers: ["C15.604.496"],
    synonyms: ["Lymphoedema", "Lymphatic Edema"],
  },
  {
    ui: "D008210",
    heading: "Lymphocele",
    tree_numbers: ["C04.182.430", "C15.604.510"],
    synonyms: ["Lymph Cyst"],
  },
  {
    ui: "D008216",
    heading: "Lymphocytic Choriomeningitis",
    tree_numbers: [
      "C01.207.245.500.500",
      "C01.925.182.550.500",
      "C01.925.782.082.580",
      "C10.228.228.245.500.500",
      "C10.228.614.400.500",
    ],
    synonyms: [
      "Lcm",
      "Lymphocytic Choriomeningitis Virus Encephalomyelitis",
      "Lymphocytic Meningitis",
      "Lymphocytic Meningoencephalitis",
    ],
  },
  {
    ui: "D008218",
    heading: "Lymphocytosis",
    tree_numbers: ["C15.378.553.475.604"],
  },
  {
    ui: "D008219",
    heading: "Lymphogranuloma Venereum",
    tree_numbers: [
      "C01.150.252.400.210.125.490",
      "C01.150.252.734.301.490",
      "C01.221.812.281.301.490",
      "C01.778.281.301.490",
      "C12.294.668.281.301.490",
      "C13.351.500.711.281.301.490",
    ],
    synonyms: [
      "Lymphogranuloma Inguinale",
      "Poradenitis Inguinale",
      "Climatic Or Tropical Bubo",
      "Durand-Nicolas-Favre Disease",
      "Strumous Bubo",
    ],
  },
  {
    ui: "D008223",
    heading: "Lymphoma",
    tree_numbers: ["C04.557.386", "C15.604.515.569", "C20.683.515.761"],
    synonyms: ["Lymphoid Cancer"],
  },
  {
    ui: "D008224",
    heading: "Lymphoma, Follicular",
    tree_numbers: ["C04.557.386.480.350", "C15.604.515.569.480.350", "C20.683.515.761.480.350"],
  },
  {
    ui: "D008228",
    heading: "Lymphoma, Non-Hodgkin",
    tree_numbers: ["C04.557.386.480", "C15.604.515.569.480", "C20.683.515.761.480"],
    synonyms: ["Large-Cell Lymphomas", "Histiocytic Lymphoma"],
  },
  {
    ui: "D008230",
    heading: "Lymphomatoid Granulomatosis",
    tree_numbers: [
      "C04.557.386.480.150.600",
      "C04.834.567",
      "C15.604.515.569.480.150.600",
      "C20.683.515.761.480.150.600",
    ],
  },
  {
    ui: "D008231",
    heading: "Lymphopenia",
    tree_numbers: ["C15.378.553.546.605", "C20.673.627"],
    synonyms: ["Lymphocytopenia"],
  },
  {
    ui: "D008232",
    heading: "Lymphoproliferative Disorders",
    tree_numbers: ["C15.604.515", "C20.683.515"],
    synonyms: [
      "Systemic Ebv-Positive T-Cell Lymphoma Of Childhood",
      "Ebv-Positive T-Cell Lymphoproliferative Disorder Of Childhood",
    ],
  },
  {
    ui: "D008258",
    heading: "Waldenstrom Macroglobulinemia",
    tree_numbers: [
      "C04.557.595.925",
      "C14.907.454.960",
      "C15.378.147.780.925",
      "C15.378.463.515.960",
      "C15.604.515.925",
      "C20.683.780.925",
    ],
    synonyms: ["Lymphoplasmacytic Lymphoma With Igm Gammopathy", "Waldenstrom Macroglobulinemia"],
  },
  {
    ui: "D008260",
    heading: "Macroglossia",
    tree_numbers: ["C07.465.910.460"],
  },
  {
    ui: "D008265",
    heading: "Macrostomia",
    tree_numbers: ["C07.465.525.480", "C07.650.525.480", "C16.131.850.525.480"],
  },
  {
    ui: "D008268",
    heading: "Macular Degeneration",
    tree_numbers: ["C11.768.585.439"],
    synonyms: [
      "Age-Related Macular Degeneration",
      "Age Related Maculopathy",
      "Senile Macular Degeneration",
      "Age Related Maculopathies",
      "Senile Macular Retinal Degeneration",
    ],
  },
  {
    ui: "D008269",
    heading: "Macular Edema",
    tree_numbers: ["C11.768.585.439.245"],
    synonyms: ["Macular Edema", "Macular Oedema", "Macular Retinal Oedema"],
  },
  {
    ui: "D008271",
    heading: "Mycetoma",
    tree_numbers: [
      "C01.150.252.410.040.692.500",
      "C01.150.252.819.557",
      "C01.150.703.302.500",
      "C01.800.200.500",
      "C01.800.720.557",
      "C17.800.838.208.557",
      "C17.800.838.765.557",
    ],
    synonyms: ["Eumycetoma", "Mycotic Mycetoma", "Maduromycosis", "Maduromycosis, Mycotic", "Madura Foot"],
  },
  {
    ui: "D008275",
    heading: "Magnesium Deficiency",
    tree_numbers: ["C18.654.521.500.439"],
  },
  {
    ui: "D008286",
    heading: "Malabsorption Syndromes",
    tree_numbers: ["C06.405.469.637", "C18.452.603"],
  },
  {
    ui: "D008287",
    heading: "Malacoplakia",
    tree_numbers: ["C23.550.548"],
  },
  {
    ui: "D008288",
    heading: "Malaria",
    tree_numbers: ["C01.610.752.530", "C01.920.875"],
    synonyms: ["Induced Malaria"],
  },
  {
    ui: "D008289",
    heading: "Malaria, Avian",
    tree_numbers: ["C01.610.752.530.606", "C01.920.875.606", "C22.131.498"],
  },
  {
    ui: "D008303",
    heading: "Malignant Carcinoid Syndrome",
    tree_numbers: ["C04.557.465.625.650.200.500", "C04.557.470.200.025.200.500", "C04.557.580.625.650.200.500"],
  },
  {
    ui: "D008304",
    heading: "Malignant Catarrh",
    tree_numbers: ["C01.925.256.466.606", "C22.196.540"],
  },
  {
    ui: "D008305",
    heading: "Malignant Hyperthermia",
    tree_numbers: ["C23.550.505.700", "C23.550.767.600"],
    synonyms: ["Anesthesia Related Hyperthermia", "Malignant Hyperpyrexia Due To Anesthesia"],
  },
  {
    ui: "D008309",
    heading: "Mallory-Weiss Syndrome",
    tree_numbers: ["C06.405.117.468.524"],
  },
  {
    ui: "D008310",
    heading: "Malocclusion",
    tree_numbers: ["C07.793.494"],
  },
  {
    ui: "D008311",
    heading: "Malocclusion, Angle Class I",
    tree_numbers: ["C07.793.494.610"],
  },
  {
    ui: "D008312",
    heading: "Malocclusion, Angle Class II",
    tree_numbers: ["C07.793.494.630"],
  },
  {
    ui: "D008313",
    heading: "Malocclusion, Angle Class III",
    tree_numbers: ["C07.793.494.650"],
  },
  {
    ui: "D008325",
    heading: "Mammary Neoplasms, Experimental",
    tree_numbers: ["C04.588.531.500", "C04.619.590", "E05.598.500.496.843"],
  },
  {
    ui: "D008336",
    heading: "Mandibular Diseases",
    tree_numbers: ["C05.500.607", "C07.320.610"],
  },
  {
    ui: "D008337",
    heading: "Mandibular Fractures",
    tree_numbers: ["C10.900.300.284.500.400.255", "C26.404.750.467.441", "C26.915.300.425.500.400.255"],
  },
  {
    ui: "D008338",
    heading: "Mandibular Injuries",
    tree_numbers: ["C10.900.300.284.500.500", "C26.915.300.425.500.500"],
  },
  {
    ui: "D008339",
    heading: "Mandibular Neoplasms",
    tree_numbers: [
      "C04.588.149.721.450.583",
      "C05.116.231.754.450.583",
      "C05.500.499.583",
      "C05.500.607.442",
      "C07.320.515.583",
      "C07.320.610.583",
    ],
    synonyms: [
      "Mandible Cancer",
      "Malignant Neoplasm Of Lower Jaw Bone",
      "Neoplasm Of Mandible",
      "Malignant Neoplasm Of Inferior Maxilla",
    ],
  },
  {
    ui: "D008342",
    heading: "Mandibulofacial Dysostosis",
    tree_numbers: ["C05.116.099.370.231.576", "C05.660.207.231.576", "C11.270.147.750", "C16.131.621.207.231.576"],
    synonyms: ["Franceschetti Syndrome", "Mandibulofacial Dysostosis", "Mandibulofacial Dysostosis"],
  },
  {
    ui: "D008363",
    heading: "alpha-Mannosidosis",
    tree_numbers: [
      "C16.320.565.202.607.500",
      "C16.320.565.595.577.500",
      "C18.452.648.202.607.500",
      "C18.452.648.595.577.500",
    ],
    synonyms: ["Deficiency Of Alpha-Mannosidase", "Alpha-D-Mannosidosis", "Alpha-Mannosidase Deficiency"],
  },
  {
    ui: "D008368",
    heading: "Mansonelliasis",
    tree_numbers: ["C01.610.335.508.700.750.361.588"],
  },
  {
    ui: "D008375",
    heading: "Maple Syrup Urine Disease",
    tree_numbers: [
      "C10.228.140.163.100.520",
      "C16.320.565.100.608",
      "C16.320.565.189.520",
      "C18.452.132.100.520",
      "C18.452.648.100.608",
      "C18.452.648.189.520",
    ],
    synonyms: ["Ketoacidaemia", "Branched Chain Ketoaciduria"],
  },
  {
    ui: "D008379",
    heading: "Marburg Virus Disease",
    tree_numbers: ["C01.925.782.417.560", "C01.925.782.580.250.500", "C22.735.500.500"],
    synonyms: ["Marburg Virus Disease", "Marburg Disease"],
  },
  {
    ui: "D008380",
    heading: "Marek Disease",
    tree_numbers: ["C01.925.256.466.650", "C01.925.928.489", "C15.604.515.700", "C20.683.515.840", "C22.131.546"],
  },
  {
    ui: "D008382",
    heading: "Marfan Syndrome",
    tree_numbers: [
      "C05.116.099.674",
      "C14.240.400.725",
      "C14.280.400.725",
      "C16.131.077.550",
      "C16.131.240.400.720",
      "C16.320.540",
      "C17.300.500",
    ],
    synonyms: ["Marfan's Syndrome"],
  },
  {
    ui: "D008398",
    heading: "Masochism",
    tree_numbers: ["F03.657.500"],
  },
  {
    ui: "D008413",
    heading: "Mastitis",
    tree_numbers: ["C13.703.844.603", "C17.800.090.968"],
    synonyms: ["Inflammatory Breast Disease", "Inflammatory Disease Of Breast", "Breast Inflammation"],
  },
  {
    ui: "D008414",
    heading: "Mastitis, Bovine",
    tree_numbers: ["C22.196.581"],
  },
  {
    ui: "D008415",
    heading: "Mastocytosis",
    tree_numbers: ["C04.557.450.565.465", "C17.800.508", "C20.543.520.350"],
    synonyms: ["Mast Cell Hyperplasia"],
  },
  {
    ui: "D008417",
    heading: "Mastoiditis",
    tree_numbers: ["C01.160.495.500", "C05.116.165.495.249", "C09.218.705.663.652"],
  },
  {
    ui: "D008439",
    heading: "Maxillary Diseases",
    tree_numbers: ["C05.500.693", "C07.320.660"],
  },
  {
    ui: "D008440",
    heading: "Maxillary Fractures",
    tree_numbers: ["C10.900.300.284.500.400.510", "C26.404.750.467.611", "C26.915.300.425.500.400.510"],
  },
  {
    ui: "D008441",
    heading: "Maxillary Neoplasms",
    tree_numbers: [
      "C04.588.149.721.450.601",
      "C05.116.231.754.450.601",
      "C05.500.499.601",
      "C05.500.693.528",
      "C07.320.515.601",
      "C07.320.660.601",
    ],
    synonyms: [],
  },
  {
    ui: "D008444",
    heading: "Maxillary Sinus Neoplasms",
    tree_numbers: [
      "C04.588.443.665.650.693.575",
      "C08.460.669.693.575",
      "C08.460.692.503.503",
      "C08.785.600.693.575",
      "C09.603.669.693.575",
      "C09.603.692.503.503",
      "C09.647.685.693.575",
    ],
    synonyms: ["Tumor Of Maxillofacial Sinus", "Neoplasm Of Maxillary Sinus"],
  },
  {
    ui: "D008446",
    heading: "Maxillofacial Injuries",
    tree_numbers: ["C10.900.300.284.500", "C26.915.300.425.500"],
  },
  {
    ui: "D008457",
    heading: "Measles",
    tree_numbers: ["C01.925.782.580.600.500.500"],
    synonyms: ["Morbilli"],
  },
  {
    ui: "D008467",
    heading: "Meckel Diverticulum",
    tree_numbers: [
      "A03.556.124.684.249.612",
      "A03.556.249.124.612",
      "C06.198.859",
      "C06.405.205.282.750.750",
      "C16.131.314.556",
      "C23.300.415.750",
    ],
    synonyms: ["Meckel Diverticulum", "Persistent Vitelline Duct"],
  },
  {
    ui: "D008471",
    heading: "Meconium Aspiration Syndrome",
    tree_numbers: ["C08.381.520.687", "C08.618.580", "C13.703.277.785", "C16.300.580", "C16.614.580"],
    synonyms: ["Neonatal Aspiration Of Meconium", "Meconium Aspiration"],
  },
  {
    ui: "D008476",
    heading: "Mediastinal Cyst",
    tree_numbers: ["C04.182.444", "C08.846.187.145"],
  },
  {
    ui: "D008477",
    heading: "Mediastinal Diseases",
    tree_numbers: ["C08.846.187"],
  },
  {
    ui: "D008478",
    heading: "Mediastinal Emphysema",
    tree_numbers: ["C08.846.187.290", "C23.550.325.250"],
  },
  {
    ui: "D008479",
    heading: "Mediastinal Neoplasms",
    tree_numbers: ["C04.588.894.479", "C08.846.187.580"],
    synonyms: ["Mediastinum Cancer", "Neoplasm Of Mediastinum", "Tumour Of Mediastinum", "Mediastinal Tumor"],
  },
  {
    ui: "D008480",
    heading: "Mediastinitis",
    tree_numbers: ["C08.846.187.790"],
  },
  {
    ui: "D008527",
    heading: "Medulloblastoma",
    tree_numbers: [
      "C04.557.465.625.600.380.515",
      "C04.557.465.625.600.590.500",
      "C04.557.470.670.380.515",
      "C04.557.470.670.590.500",
      "C04.557.580.625.600.380.515",
      "C04.557.580.625.600.590.500",
    ],
    synonyms: ["Medulloblastoma, Melanotic", "Melanotic Medulloblastoma"],
  },
  {
    ui: "D008531",
    heading: "Megacolon",
    tree_numbers: ["C06.405.469.158.701"],
    synonyms: ["Dilatation Of Colon"],
  },
  {
    ui: "D008532",
    heading: "Megacolon, Toxic",
    tree_numbers: ["C06.405.469.158.701.591"],
  },
  {
    ui: "D008538",
    heading: "Meige Syndrome",
    tree_numbers: ["C10.228.140.079.590", "C10.228.662.300.500"],
  },
  {
    ui: "D008539",
    heading: "Meigs Syndrome",
    tree_numbers: [
      "C04.588.322.455.531",
      "C13.351.500.056.630.705.531",
      "C13.351.937.418.685.531",
      "C19.344.410.531",
      "C19.391.630.705.531",
    ],
  },
  {
    ui: "D008545",
    heading: "Melanoma",
    tree_numbers: ["C04.557.465.625.650.510", "C04.557.580.625.650.510", "C04.557.665.510"],
    synonyms: ["Malignant Melanoma", "Naevocarcinoma"],
  },
  {
    ui: "D008546",
    heading: "Melanoma, Experimental",
    tree_numbers: [
      "C04.557.465.625.650.510.525",
      "C04.557.580.625.650.510.525",
      "C04.557.665.510.525",
      "C04.619.600",
      "E05.598.500.496.937",
    ],
  },
  {
    ui: "D008548",
    heading: "Melanosis",
    tree_numbers: ["C17.800.621.430.530"],
  },
  {
    ui: "D008551",
    heading: "Melena",
    tree_numbers: ["A12.459.764", "C06.405.227.600", "C23.550.414.788.600"],
  },
  {
    ui: "D008554",
    heading: "Melioidosis",
    tree_numbers: ["C01.150.252.400.170.531"],
    synonyms: [
      "Subacute And Chronic Melioidosis",
      "Acute And Fulminating Melioidosis",
      "Pseudoglanders",
      "Nightcliff Gardener's Disease",
      "Whitmore's Disease",
    ],
  },
  {
    ui: "D008556",
    heading: "Melkersson-Rosenthal Syndrome",
    tree_numbers: ["C07.465.299.800", "C07.465.466", "C10.292.319.800"],
    synonyms: ["Cheilitis Granulomatosa Of Mescher-Melkersson-Rosenthal", "Melkersson's Syndrome"],
  },
  {
    ui: "D008557",
    heading: "Melorheostosis",
    tree_numbers: ["C05.116.099.708.702.593"],
  },
  {
    ui: "D008569",
    heading: "Memory Disorders",
    tree_numbers: ["C10.597.606.525", "C23.888.592.604.529", "F01.700.625"],
  },
  {
    ui: "D008575",
    heading: "Meniere Disease",
    tree_numbers: ["C09.218.568.217.500"],
    synonyms: ["Meniere Disease", "Otogenic Vertigo", "Mnire's Vertigo"],
  },
  {
    ui: "D008577",
    heading: "Meningeal Neoplasms",
    tree_numbers: ["C04.588.614.250.580", "C10.551.240.500"],
  },
  {
    ui: "D008579",
    heading: "Meningioma",
    tree_numbers: ["C04.557.580.520", "C04.557.645.520", "C04.588.614.250.580.500", "C10.551.240.500.500"],
    synonyms: ["Meningioma Of The Olfactory Groove"],
  },
  {
    ui: "D008580",
    heading: "Meningism",
    tree_numbers: ["C10.597.544", "C23.888.592.544"],
  },
  {
    ui: "D008581",
    heading: "Meningitis",
    tree_numbers: ["C10.228.614"],
  },
  {
    ui: "D008582",
    heading: "Meningitis, Aseptic",
    tree_numbers: ["C10.228.614.220"],
    synonyms: ["Acute Aseptic Meningitis"],
  },
  {
    ui: "D008583",
    heading: "Meningitis, Haemophilus",
    tree_numbers: [
      "C01.150.252.223.500.425",
      "C01.150.252.400.700.433.615",
      "C01.207.180.500.425",
      "C10.228.228.180.500.425",
      "C10.228.614.280.393",
    ],
  },
  {
    ui: "D008584",
    heading: "Meningitis, Listeria",
    tree_numbers: [
      "C01.150.252.223.500.500",
      "C01.150.252.410.514.533",
      "C01.207.180.500.500",
      "C10.228.228.180.500.500",
      "C10.228.614.280.449",
    ],
  },
  {
    ui: "D008585",
    heading: "Meningitis, Meningococcal",
    tree_numbers: [
      "C01.150.252.223.500.750",
      "C01.150.252.400.625.549.449",
      "C01.207.180.500.750",
      "C10.228.228.180.500.750",
      "C10.228.614.280.505",
    ],
  },
  {
    ui: "D008586",
    heading: "Meningitis, Pneumococcal",
    tree_numbers: [
      "C01.150.252.223.500.875",
      "C01.150.252.410.890.670.595",
      "C01.207.180.500.875",
      "C10.228.228.180.500.875",
      "C10.228.614.280.560",
    ],
  },
  {
    ui: "D008587",
    heading: "Meningitis, Viral",
    tree_numbers: ["C01.207.245.500", "C01.925.182.550", "C10.228.228.245.500", "C10.228.614.400"],
  },
  {
    ui: "D008588",
    heading: "Meningocele",
    tree_numbers: ["C10.500.680.598", "C16.131.666.680.598", "C23.300.707.968"],
  },
  {
    ui: "D008589",
    heading: "Meningococcal Infections",
    tree_numbers: ["C01.150.252.400.625.549"],
  },
  {
    ui: "D008590",
    heading: "Meningoencephalitis",
    tree_numbers: [
      "C01.207.245.550",
      "C01.207.570",
      "C10.228.140.430.550",
      "C10.228.228.245.550",
      "C10.228.228.570",
      "C10.228.614.500",
    ],
    synonyms: ["Meningoencephalitis"],
  },
  {
    ui: "D008591",
    heading: "Meningomyelocele",
    tree_numbers: ["C10.500.680.610", "C16.131.666.680.610"],
  },
  {
    ui: "D008594",
    heading: "Menopause, Premature",
    tree_numbers: ["C13.351.500.056.630.250", "G08.686.157.500.500", "G08.686.841.249.500.500"],
    synonyms: ["Menopause - Premature", "Menopause Praecox"],
  },
  {
    ui: "D008595",
    heading: "Menorrhagia",
    tree_numbers: ["C13.351.500.852.691.449", "C23.550.414.993.350", "C23.550.568.875"],
  },
  {
    ui: "D008599",
    heading: "Menstruation Disturbances",
    tree_numbers: ["C23.550.568"],
  },
  {
    ui: "D008607",
    heading: "Intellectual Disability",
    tree_numbers: ["C10.597.606.360", "C23.888.592.604.646", "F01.700.687", "F03.625.539"],
  },
  {
    ui: "D008630",
    heading: "Mercury Poisoning",
    tree_numbers: ["C25.723.522.875"],
  },
  {
    ui: "D008637",
    heading: "Mesenchymoma",
    tree_numbers: ["C04.557.435.500"],
  },
  {
    ui: "D008639",
    heading: "Mesenteric Cyst",
    tree_numbers: ["C04.182.473", "C06.844.620.500"],
  },
  {
    ui: "D008640",
    heading: "Mesenteric Lymphadenitis",
    tree_numbers: ["C06.844.520", "C15.604.315.618"],
    synonyms: ["Mesenteric Adenitis", "Mesenteric Lymphadenitis"],
  },
  {
    ui: "D008641",
    heading: "Mesenteric Vascular Occlusion",
    tree_numbers: ["C06.405.469.675", "C06.844.550", "C14.907.137.534"],
  },
  {
    ui: "D008644",
    heading: "Mesial Movement of Teeth",
    tree_numbers: ["C07.465.714.836.535", "G10.549.830.535"],
  },
  {
    ui: "D008649",
    heading: "Mesonephroma",
    tree_numbers: ["C04.557.465.510"],
    synonyms: [
      "Water-Clear Cell Carcinoma",
      "Mesonephroid Clear Cell Carcinoma",
      "Malignant Mesonephroma",
      "Water-Clear Cell Adenocarcinoma",
      "Water-Clear Cell Adenocarcinoma",
      "Mesonephroma",
      "Mesonephroma",
      "Wolffian Duct Neoplasm",
      "Mesonephroma, Malignant",
      "Clear Cell Adenocarcinoma",
    ],
  },
  {
    ui: "D008654",
    heading: "Mesothelioma",
    tree_numbers: ["C04.557.470.035.510", "C04.557.470.660.510"],
    synonyms: ["Benign Tumor Of Mesothelium"],
  },
  {
    ui: "D008659",
    heading: "Metabolic Diseases",
    tree_numbers: ["C18.452"],
    synonyms: ["Metabolic Disease"],
  },
  {
    ui: "D008661",
    heading: "Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565", "C18.452.648"],
    synonyms: ["Inborn Errors Of Metabolism", "Metabolic Hereditary Disorder", "Inborn Metabolism Disorder"],
  },
  {
    ui: "D008664",
    heading: "Metal Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.618", "C18.452.648.618"],
    synonyms: ["Inborn Metal Metabolism Disorder"],
  },
  {
    ui: "D008679",
    heading: "Metaplasia",
    tree_numbers: ["C23.550.589"],
  },
  {
    ui: "D008708",
    heading: "Methemoglobinemia",
    tree_numbers: ["C15.378.619"],
  },
  {
    ui: "D008796",
    heading: "Metrorrhagia",
    tree_numbers: ["C13.351.500.852.691.622", "C23.550.414.993.700"],
  },
  {
    ui: "D008831",
    heading: "Microcephaly",
    tree_numbers: ["C05.660.207.620", "C10.500.507.400.500", "C16.131.621.207.620", "C16.131.666.507.400.500"],
    synonyms: ["Microcephalus", "Microencephaly"],
  },
  {
    ui: "D008844",
    heading: "Micrognathism",
    tree_numbers: [
      "C05.500.460.457",
      "C05.660.207.540.460.457",
      "C07.320.440.457",
      "C07.650.500.460.457",
      "C16.131.621.207.540.460.457",
      "C16.131.850.500.460.457",
    ],
  },
  {
    ui: "D008850",
    heading: "Microphthalmos",
    tree_numbers: ["C11.250.566", "C16.131.384.666"],
    synonyms: ["Simple Microphthalmos", "Microphthalmos"],
  },
  {
    ui: "D008865",
    heading: "Microstomia",
    tree_numbers: ["C07.465.525.520", "C07.650.525.520", "C16.131.850.525.520"],
  },
  {
    ui: "D008878",
    heading: "Middle Lobe Syndrome",
    tree_numbers: ["C08.381.730.542"],
  },
  {
    ui: "D008881",
    heading: "Migraine Disorders",
    tree_numbers: ["C10.228.140.546.399.750"],
    synonyms: ["Migraine With Or Without Aura", "Migraine Variant", "Migraine Disorder"],
  },
  {
    ui: "D008882",
    heading: "Mikulicz' Disease",
    tree_numbers: ["C07.465.815.355"],
    synonyms: ["Mikulicz's Disease", "Igg4-Related Dacryoadenitis And Sialadenitis"],
  },
  {
    ui: "D008883",
    heading: "Miliaria",
    tree_numbers: ["C17.800.946.492"],
    synonyms: ["Sweat Rash", "Heat Rash", "Eccrine Miliaria"],
  },
  {
    ui: "D008924",
    heading: "Mite Infestations",
    tree_numbers: ["C01.610.858.211.480"],
  },
  {
    ui: "D008944",
    heading: "Mitral Valve Insufficiency",
    tree_numbers: ["C14.280.484.461"],
    synonyms: [
      "Congenital Mitral Regurgitation",
      "Congenital Insufficiency Of Mitral Valve",
      "Mitral Regurgitation",
      "Mitral Valve Incompetence",
      "Congenital Mitral Insufficiency",
    ],
  },
  {
    ui: "D008945",
    heading: "Mitral Valve Prolapse",
    tree_numbers: ["C14.280.484.400.500"],
    synonyms: ["Floppy Mitral Valve", "Barlow's Syndrome"],
  },
  {
    ui: "D008946",
    heading: "Mitral Valve Stenosis",
    tree_numbers: ["C14.280.484.517"],
    synonyms: ["Mitral Stenosis"],
  },
  {
    ui: "D008947",
    heading: "Mixed Connective Tissue Disease",
    tree_numbers: ["C17.300.540"],
    synonyms: ["Connective Tissue Disease Overlap Syndrome", "Mixed Collagen Vascular Disease"],
  },
  {
    ui: "D008949",
    heading: "Adenoma, Pleomorphic",
    tree_numbers: ["C04.557.435.090", "C04.557.470.035.155"],
    synonyms: ["Mixed Tumor Of The Salivary Gland", "Pleomorphic Adenoma"],
  },
  {
    ui: "D008976",
    heading: "Molluscum Contagiosum",
    tree_numbers: ["C01.925.256.743.611", "C01.925.825.550", "C17.800.838.790.550"],
  },
  {
    ui: "D008989",
    heading: "Monieziasis",
    tree_numbers: ["C01.610.335.190.628", "C01.610.335.349.656", "C01.610.701.377.656", "C22.674.377.656"],
  },
  {
    ui: "D008992",
    heading: "Monkey Diseases",
    tree_numbers: ["C22.735.500"],
  },
  {
    ui: "D008998",
    heading: "Monoclonal Gammopathy of Undetermined Significance",
    tree_numbers: ["C15.378.147.542.640", "C15.378.147.780.570", "C20.683.460.640", "C20.683.780.640"],
  },
  {
    ui: "D009006",
    heading: "Monosomy",
    tree_numbers: ["C23.550.210.050.500", "G05.365.590.175.050.500", "G05.700.131.500"],
  },
  {
    ui: "D009008",
    heading: "Abnormalities, Severe Teratoid",
    tree_numbers: ["C16.131.085"],
  },
  {
    ui: "D009011",
    heading: "Monteggia's Fracture",
    tree_numbers: ["C05.550.518.336.750", "C26.289.336.750", "C26.404.937.547"],
  },
  {
    ui: "D009021",
    heading: "Morphine Dependence",
    tree_numbers: ["C25.775.643.500.600", "F03.900.647.500.600"],
  },
  {
    ui: "D009041",
    heading: "Motion Sickness",
    tree_numbers: ["C23.888.571"],
    synonyms: ["Travel Sickness"],
  },
  {
    ui: "D009050",
    heading: "Fluorosis, Dental",
    tree_numbers: ["C07.793.330"],
    synonyms: ["Intrinsic Enamel Discolouration Of Fluorosis", "Mottled Teeth", "Mottling Of Enamel"],
  },
  {
    ui: "D009056",
    heading: "Mouth Abnormalities",
    tree_numbers: ["C07.465.525", "C07.650.525", "C16.131.850.525"],
  },
  {
    ui: "D009057",
    heading: "Stomatognathic Diseases",
    tree_numbers: ["C07"],
  },
  {
    ui: "D009058",
    heading: "Mouth Breathing",
    tree_numbers: ["C08.618.659", "C23.888.852.761"],
  },
  {
    ui: "D009059",
    heading: "Mouth Diseases",
    tree_numbers: ["C07.465"],
  },
  {
    ui: "D009062",
    heading: "Mouth Neoplasms",
    tree_numbers: ["C04.588.443.591", "C07.465.530"],
  },
  {
    ui: "D009066",
    heading: "Mouth, Edentulous",
    tree_numbers: ["C07.465.550", "C07.793.597"],
  },
  {
    ui: "D009069",
    heading: "Movement Disorders",
    tree_numbers: ["C10.228.662"],
  },
  {
    ui: "D009072",
    heading: "Moyamoya Disease",
    tree_numbers: [
      "C10.228.140.300.200.600",
      "C10.228.140.300.510.200.737",
      "C14.907.137.615",
      "C14.907.253.123.620",
      "C14.907.253.560.200.737",
    ],
    synonyms: ["Progressive Intracranial Arterial Occlusion"],
  },
  {
    ui: "D009078",
    heading: "Mucocele",
    tree_numbers: ["C04.182.511"],
  },
  {
    ui: "D009080",
    heading: "Mucocutaneous Lymph Node Syndrome",
    tree_numbers: ["C14.907.940.560", "C15.604.560", "C17.800.862.560"],
    synonyms: [
      "Acute Febrile Mcls",
      "Mucocutaneous Lymph Node Syndrome",
      "Acute Febrile Mucocutaneous Lymph Node Syndrome",
      "Kawasaki's Disease",
      "Mlns",
    ],
  },
  {
    ui: "D009081",
    heading: "Mucolipidoses",
    tree_numbers: [
      "C05.116.198.371",
      "C10.228.140.163.100.435.590",
      "C16.320.565.189.435.590",
      "C16.320.565.202.670",
      "C16.320.565.595.554.590",
      "C18.452.132.100.435.590",
      "C18.452.648.189.435.590",
      "C18.452.648.202.670",
      "C18.452.648.595.554.590",
    ],
    synonyms: ["Mucolipidosis Type I", "Sialidosis"],
  },
  {
    ui: "D009083",
    heading: "Mucopolysaccharidoses",
    tree_numbers: [
      "C16.320.565.202.715",
      "C16.320.565.595.600",
      "C17.300.550.575",
      "C18.452.648.202.715",
      "C18.452.648.595.600",
    ],
  },
  {
    ui: "D009084",
    heading: "Mucopolysaccharidosis III",
    tree_numbers: [
      "C16.320.565.202.715.650",
      "C16.320.565.595.600.650",
      "C17.300.550.575.650",
      "C18.452.648.202.715.650",
      "C18.452.648.595.600.650",
    ],
    synonyms: [
      "Mucopolysaccharidosis Type Iiia (Sanfilippo A)",
      "Mps3a",
      "Mucopolysaccharidosis Iii-A",
      "Sanfilippo Syndrome Type A",
      "Heparan Sulfamidase Deficiency",
      "Mpsiiia",
      "Mucopolysaccharidosis Type 3a",
    ],
  },
  {
    ui: "D009085",
    heading: "Mucopolysaccharidosis IV",
    tree_numbers: [
      "C16.320.565.202.715.655",
      "C16.320.565.595.600.655",
      "C17.300.550.575.655",
      "C18.452.648.202.715.655",
      "C18.452.648.595.600.655",
    ],
    synonyms: ["Mps4a", "Mps Iva", "Morquio Syndrome A", "Morquio A Disease", "Galns Deficiency"],
  },
  {
    ui: "D009087",
    heading: "Mucopolysaccharidosis VI",
    tree_numbers: [
      "C16.320.565.202.715.670",
      "C16.320.565.595.600.670",
      "C17.300.550.575.670",
      "C18.452.648.202.715.670",
      "C18.452.648.595.600.670",
    ],
    synonyms: [
      "Maroteaux - Lamy Syndrome",
      "Arylsulfatase B Deficiency",
      "Deficiency Of N-Acetylgalactosamine-4-Sulfatase",
      "Mps Vi - Maroteaux-Lamy Syndrome",
      "Maroteaux-Lamy Syndrome",
    ],
  },
  {
    ui: "D009091",
    heading: "Mucormycosis",
    tree_numbers: ["C01.150.703.980.600"],
  },
  {
    ui: "D009100",
    heading: "Multiple Carboxylase Deficiency",
    tree_numbers: ["C16.320.565.100.620", "C16.320.565.202.720", "C18.452.648.100.620", "C18.452.648.202.720"],
  },
  {
    ui: "D009101",
    heading: "Multiple Myeloma",
    tree_numbers: [
      "C04.557.595.500",
      "C14.907.454.460",
      "C15.378.147.780.650",
      "C15.378.463.515.460",
      "C20.683.515.845",
      "C20.683.780.650",
    ],
    synonyms: ["Plasma Cell Myeloma"],
  },
  {
    ui: "D009102",
    heading: "Multiple Organ Failure",
    tree_numbers: ["C23.550.835.525"],
  },
  {
    ui: "D009103",
    heading: "Multiple Sclerosis",
    tree_numbers: ["C10.114.375.500", "C10.314.350.500", "C20.111.258.250.500"],
    synonyms: ["Insular Sclerosis", "Generalized Multiple Sclerosis"],
  },
  {
    ui: "D009104",
    heading: "Multiple Trauma",
    tree_numbers: ["C26.640"],
  },
  {
    ui: "D009105",
    heading: "Dissociative Identity Disorder",
    tree_numbers: ["F03.300.500"],
    synonyms: ["Dissociative Identity Disorder"],
  },
  {
    ui: "D009107",
    heading: "Mumps",
    tree_numbers: ["C01.925.782.580.600.680.500", "C07.465.815.470.800.630"],
  },
  {
    ui: "D009110",
    heading: "Munchausen Syndrome",
    tree_numbers: ["F03.875.375.600"],
  },
  {
    ui: "D009120",
    heading: "Muscle Cramp",
    tree_numbers: ["C05.651.475", "C10.597.613.500", "C23.888.592.608.500"],
  },
  {
    ui: "D009122",
    heading: "Muscle Hypertonia",
    tree_numbers: ["C10.597.613.550", "C23.888.592.608.550"],
  },
  {
    ui: "D009123",
    heading: "Muscle Hypotonia",
    tree_numbers: ["C10.597.613.575", "C23.888.592.608.575"],
  },
  {
    ui: "D009127",
    heading: "Muscle Rigidity",
    tree_numbers: ["C05.651.504", "C10.597.613.550.500", "C23.888.592.608.550.500"],
  },
  {
    ui: "D009128",
    heading: "Muscle Spasticity",
    tree_numbers: ["C05.651.512", "C10.597.613.550.550", "C23.888.592.608.550.550"],
  },
  {
    ui: "D009133",
    heading: "Muscular Atrophy",
    tree_numbers: ["C10.597.613.612", "C23.300.070.500", "C23.888.592.608.612"],
    synonyms: ["Muscle Wasting", "Amyotrophia", "Wasting - Muscle"],
  },
  {
    ui: "D009134",
    heading: "Muscular Atrophy, Spinal",
    tree_numbers: ["C10.228.854.468", "C10.574.562.500", "C10.668.467.500"],
    synonyms: ["Pure Progressive Muscular Atrophy", "Progressive Spinal Muscular Atrophy"],
  },
  {
    ui: "D009135",
    heading: "Muscular Diseases",
    tree_numbers: ["C05.651", "C10.668.491"],
  },
  {
    ui: "D009136",
    heading: "Muscular Dystrophies",
    tree_numbers: ["C05.651.534.500", "C10.668.491.175.500", "C16.320.577"],
  },
  {
    ui: "D009137",
    heading: "Muscular Dystrophy, Animal",
    tree_numbers: ["C22.595"],
  },
  {
    ui: "D009139",
    heading: "Musculoskeletal Abnormalities",
    tree_numbers: ["C05.660", "C16.131.621"],
  },
  {
    ui: "D009140",
    heading: "Musculoskeletal Diseases",
    tree_numbers: ["C05"],
  },
  {
    ui: "D009145",
    heading: "Mushroom Poisoning",
    tree_numbers: ["C25.723.415.551", "C25.723.680.551"],
  },
  {
    ui: "D009155",
    heading: "Mutism",
    tree_numbers: ["C10.597.606.150.500.800.500", "C23.888.592.604.150.500.800.500", "F03.625.875"],
  },
  {
    ui: "D009157",
    heading: "Myasthenia Gravis",
    tree_numbers: [
      "C04.588.614.550.500",
      "C04.730.856.490",
      "C10.114.656",
      "C10.574.781.588",
      "C10.668.758.725",
      "C20.111.258.500",
    ],
  },
  {
    ui: "D009164",
    heading: "Mycobacterium Infections",
    tree_numbers: ["C01.150.252.410.040.552"],
  },
  {
    ui: "D009165",
    heading: "Mycobacterium Infections, Nontuberculous",
    tree_numbers: ["C01.150.252.410.040.552.475"],
  },
  {
    ui: "D009175",
    heading: "Mycoplasma Infections",
    tree_numbers: ["C01.150.252.400.610.610"],
  },
  {
    ui: "D009180",
    heading: "Mycoplasmatales Infections",
    tree_numbers: ["C01.150.252.400.610"],
  },
  {
    ui: "D009181",
    heading: "Mycoses",
    tree_numbers: ["C01.150.703"],
    synonyms: ["Mycosis"],
  },
  {
    ui: "D009182",
    heading: "Mycosis Fungoides",
    tree_numbers: ["C04.557.386.480.750.800.550", "C15.604.515.569.480.750.800.550", "C20.683.515.761.480.750.800.550"],
    synonyms: ["Mycosis Fungoides Lymphoma", "Mycosis Fungoides"],
  },
  {
    ui: "D009187",
    heading: "Myelitis",
    tree_numbers: ["C01.207.618", "C10.228.228.618", "C10.228.854.525"],
  },
  {
    ui: "D009188",
    heading: "Myelitis, Transverse",
    tree_numbers: [
      "C01.207.618.250",
      "C04.588.614.550.550",
      "C04.730.856.543",
      "C10.114.375.600",
      "C10.228.228.618.250",
      "C10.228.854.525.553",
      "C10.314.350.600",
      "C10.574.781.625",
      "C20.111.258.250.550",
    ],
  },
  {
    ui: "D009190",
    heading: "Myelodysplastic Syndromes",
    tree_numbers: ["C15.378.190.625"],
  },
  {
    ui: "D009196",
    heading: "Myeloproliferative Disorders",
    tree_numbers: ["C15.378.190.636"],
    synonyms: [
      "Malignant Neoplasm Of Bone Marrow",
      "Bone Marrow Tumor",
      "Bone Marrow Neoplasm",
      "Malignant Bone Marrow Tumor",
    ],
  },
  {
    ui: "D009198",
    heading: "Myiasis",
    tree_numbers: ["C01.610.858.211.503"],
    synonyms: ["Infestation By Fly Larvae", "Infestation By Maggots", "Maggot Infestation"],
  },
  {
    ui: "D009202",
    heading: "Cardiomyopathies",
    tree_numbers: ["C14.280.238"],
    synonyms: ["Cardiomyopathies"],
  },
  {
    ui: "D009203",
    heading: "Myocardial Infarction",
    tree_numbers: ["C14.280.647.500", "C14.907.585.500", "C23.550.513.355.750", "C23.550.717.489.750"],
    synonyms: ["Heart Attack", "Myocardial Infarct"],
  },
  {
    ui: "D009205",
    heading: "Myocarditis",
    tree_numbers: ["C14.280.238.625"],
    synonyms: ["Myocardial Inflammation"],
  },
  {
    ui: "D009207",
    heading: "Myoclonus",
    tree_numbers: ["C10.597.350.500", "C23.888.592.350.500"],
  },
  {
    ui: "D009208",
    heading: "Myoepithelioma",
    tree_numbers: ["C04.557.435.585"],
    synonyms: ["Benign Myoepithelioma", "Myoepithelial Adenoma", "Myoepithelial Neoplasm"],
  },
  {
    ui: "D009209",
    heading: "Myofascial Pain Syndromes",
    tree_numbers: ["C05.651.550"],
  },
  {
    ui: "D009212",
    heading: "Myoglobinuria",
    tree_numbers: ["C05.651.807.628"],
  },
  {
    ui: "D009214",
    heading: "Myoma",
    tree_numbers: ["C04.557.450.590.540"],
    synonyms: ["Benign Neoplasm Of The Muscle"],
  },
  {
    ui: "D009216",
    heading: "Myopia",
    tree_numbers: ["C11.744.636"],
    synonyms: ["Near-Sightedness", "Short-Sightedness", "Near Vision"],
  },
  {
    ui: "D009217",
    heading: "Myosarcoma",
    tree_numbers: ["C04.557.450.590.550", "C04.557.450.795.550"],
    synonyms: [
      "Myosarcoma",
      "Malignant Tumor Of Muscle",
      "Malignant Tumor Of The Muscle",
      "Malignant Neoplasm Of Muscle",
    ],
  },
  {
    ui: "D009220",
    heading: "Myositis",
    tree_numbers: ["C05.651.594", "C10.668.491.562"],
    synonyms: ["Inflammatory Disorder Of Muscle"],
  },
  {
    ui: "D009221",
    heading: "Myositis Ossificans",
    tree_numbers: ["C05.651.594.638"],
    synonyms: ["Myisitis Ossificans", "Ossification - Muscle"],
  },
  {
    ui: "D009222",
    heading: "Myotonia",
    tree_numbers: ["C10.597.613.700", "C23.888.592.608.700"],
  },
  {
    ui: "D009223",
    heading: "Myotonic Dystrophy",
    tree_numbers: [
      "C05.651.534.500.500",
      "C05.651.662.750",
      "C10.574.500.547",
      "C10.668.491.175.500.500",
      "C10.668.491.606.750",
      "C16.320.400.542",
      "C16.320.577.500",
    ],
    synonyms: [
      "Congenital Myotonic Dystrophy",
      "Myotonic Dystrophy Of Steinert",
      "Steinert Disease",
      "Dystrophia Myotonica",
    ],
  },
  {
    ui: "D009224",
    heading: "Myotonia Congenita",
    tree_numbers: ["C05.651.662.500", "C10.574.500.545", "C10.668.491.606.500", "C16.320.400.540"],
    synonyms: ["Thomsen Disease", "Thomsen's Disease", "Congenital Myotonia, Autosomal Dominant Form"],
  },
  {
    ui: "D009230",
    heading: "Myxedema",
    tree_numbers: ["C17.300.550.590", "C19.874.482.638"],
  },
  {
    ui: "D009232",
    heading: "Myxoma",
    tree_numbers: ["C04.557.450.565.550"],
  },
  {
    ui: "D009234",
    heading: "Myxomatosis, Infectious",
    tree_numbers: ["C01.925.256.743.665", "C22.627"],
  },
  {
    ui: "D009236",
    heading: "Myxosarcoma",
    tree_numbers: ["C04.557.450.565.560", "C04.557.450.795.560"],
    synonyms: ["Myxosarcoma"],
  },
  {
    ui: "D009260",
    heading: "Nail Diseases",
    tree_numbers: ["C17.800.529"],
  },
  {
    ui: "D009261",
    heading: "Nail-Patella Syndrome",
    tree_numbers: ["C05.550.629", "C16.131.077.606", "C16.320.600", "C17.800.529.400"],
    synonyms: [
      "Nail Patella Syndrome",
      "Hereditary Onychoostedysplasia",
      "Turner-Kiser Syndrome",
      "Fong Disease",
      "Iliac Horn Syndrome",
    ],
  },
  {
    ui: "D009263",
    heading: "Nails, Ingrown",
    tree_numbers: ["C17.800.529.406"],
  },
  {
    ui: "D009264",
    heading: "Nails, Malformed",
    tree_numbers: ["C23.300.820"],
  },
  {
    ui: "D009265",
    heading: "Nairobi Sheep Disease",
    tree_numbers: ["C01.920.500.614", "C01.920.930.550", "C01.925.081.678", "C01.925.782.147.633", "C22.836.583"],
  },
  {
    ui: "D009290",
    heading: "Narcolepsy",
    tree_numbers: ["C10.886.425.800.200.750", "F03.870.400.800.200.750"],
    synonyms: ["Narcolepsy, Without Cataplexy", "Paroxysmal Sleep"],
  },
  {
    ui: "D009293",
    heading: "Opioid-Related Disorders",
    tree_numbers: ["C25.775.643.500", "F03.900.647.500"],
    synonyms: ["Opioid Type Dependence"],
  },
  {
    ui: "D009298",
    heading: "Nasal Polyps",
    tree_numbers: ["C08.460.572", "C09.603.557", "C23.300.825.557"],
  },
  {
    ui: "D009302",
    heading: "Nasopharyngeal Diseases",
    tree_numbers: ["C07.550.350", "C09.775.350"],
  },
  {
    ui: "D009303",
    heading: "Nasopharyngeal Neoplasms",
    tree_numbers: [
      "C04.588.443.665.710.650",
      "C07.550.350.650",
      "C07.550.745.650",
      "C09.647.710.650",
      "C09.775.350.650",
      "C09.775.549.650",
    ],
    synonyms: [
      "Malignant Neoplasm Of Nasopharynx",
      "Malignant Nasopharyngeal Tumor",
      "Nasopharyngeal Carcinoma",
      "Nasopharynx Cancer",
    ],
  },
  {
    ui: "D009304",
    heading: "Nasopharyngitis",
    tree_numbers: [
      "C01.748.561.500",
      "C07.550.350.700",
      "C07.550.781.500",
      "C08.730.561.500",
      "C09.775.350.700",
      "C09.775.649.500",
    ],
    synonyms: ["Chronic Nasopharyngitis"],
  },
  {
    ui: "D009325",
    heading: "Nausea",
    tree_numbers: ["C23.888.821.712"],
  },
  {
    ui: "D009332",
    heading: "Necatoriasis",
    tree_numbers: ["C01.610.335.508.700.775.455.683"],
  },
  {
    ui: "D009335",
    heading: "Necrobiosis Lipoidica",
    tree_numbers: ["C17.300.200.495.545", "C17.800.550.545", "C17.800.849.495", "C18.452.880.495"],
    synonyms: ["Necrobiosis Lipoidica"],
  },
  {
    ui: "D009336",
    heading: "Necrosis",
    tree_numbers: ["C23.550.717"],
  },
  {
    ui: "D009347",
    heading: "Nelson Syndrome",
    tree_numbers: [
      "C04.588.322.609.145.500",
      "C10.228.140.617.738.675.149.500",
      "C19.344.609.145.500",
      "C19.700.734.145.500",
    ],
    synonyms: ["Nelson's Syndrome"],
  },
  {
    ui: "D009349",
    heading: "Nematode Infections",
    tree_numbers: ["C01.610.335.508"],
  },
  {
    ui: "D009357",
    heading: "Neonatal Abstinence Syndrome",
    tree_numbers: ["C16.614.610", "C25.775.650", "F03.900.650"],
    synonyms: ["Drug Withdrawal Syndrome In Newborn"],
  },
  {
    ui: "D009358",
    heading: "Congenital, Hereditary, and Neonatal Diseases and Abnormalities",
    tree_numbers: ["C16"],
  },
  {
    ui: "D009360",
    heading: "Neoplastic Cells, Circulating",
    tree_numbers: ["A11.642", "C04.697.650.900", "C23.550.727.650.900"],
  },
  {
    ui: "D009361",
    heading: "Neoplasm Invasiveness",
    tree_numbers: ["C04.697.645", "C23.550.727.645"],
  },
  {
    ui: "D009362",
    heading: "Neoplasm Metastasis",
    tree_numbers: ["C04.697.650", "C23.550.727.650"],
  },
  {
    ui: "D009364",
    heading: "Neoplasm Recurrence, Local",
    tree_numbers: ["C04.697.655", "C23.550.727.655"],
  },
  {
    ui: "D009365",
    heading: "Neoplasm Regression, Spontaneous",
    tree_numbers: ["C04.697.670", "C23.550.727.670", "G16.767.500"],
  },
  {
    ui: "D009366",
    heading: "Neoplasm Seeding",
    tree_numbers: ["C04.697.650.830", "C23.550.727.650.830"],
  },
  {
    ui: "D009369",
    heading: "Neoplasms",
    tree_numbers: ["C04"],
    synonyms: ["Malignant Neoplasm", "Malignant Tumor", "Primary Cancer"],
  },
  {
    ui: "D009370",
    heading: "Neoplasms by Histologic Type",
    tree_numbers: ["C04.557"],
  },
  {
    ui: "D009371",
    heading: "Neoplasms by Site",
    tree_numbers: ["C04.588"],
  },
  {
    ui: "D009372",
    heading: "Neoplasms, Connective Tissue",
    tree_numbers: ["C04.557.450.565", "C17.300.680"],
    synonyms: [
      "Connective Tissue Neoplasm",
      "Neoplasm Of Connective Tissues",
      "Mesenchymal Tissue Malignant Neoplasm",
      "Tumour Of Connective Tissue",
    ],
  },
  {
    ui: "D009373",
    heading: "Neoplasms, Germ Cell and Embryonal",
    tree_numbers: ["C04.557.465"],
    synonyms: ["Malignant Tumor Of The Germ Cell"],
  },
  {
    ui: "D009374",
    heading: "Neoplasms, Experimental",
    tree_numbers: ["C04.619", "E05.598.500.496"],
  },
  {
    ui: "D009375",
    heading: "Neoplasms, Glandular and Epithelial",
    tree_numbers: ["C04.557.470"],
    synonyms: ["Epithelioma", "Malignant Epithelioma", "Epithelial Cancer"],
  },
  {
    ui: "D009376",
    heading: "Neoplasms, Hormone-Dependent",
    tree_numbers: ["C04.626"],
  },
  {
    ui: "D009377",
    heading: "Multiple Endocrine Neoplasia",
    tree_numbers: ["C04.588.322.400", "C04.651.600", "C04.700.630", "C16.320.700.630", "C19.344.400"],
  },
  {
    ui: "D009378",
    heading: "Neoplasms, Multiple Primary",
    tree_numbers: ["C04.651"],
  },
  {
    ui: "D009379",
    heading: "Neoplasms, Muscle Tissue",
    tree_numbers: ["C04.557.450.590"],
    synonyms: [
      "Muscle Neoplasm",
      "Neoplasm Of Muscle",
      "Myomatous Tumor",
      "Myomatous Neoplasm",
      "Muscle Tissue Neoplasm",
    ],
  },
  {
    ui: "D009380",
    heading: "Neoplasms, Nerve Tissue",
    tree_numbers: ["C04.557.580"],
    synonyms: [
      "Tumor Of The Nervous System",
      "Neural Neoplasm",
      "Nervous System Neoplasm",
      "Malignant Neoplasm Of Nervous System",
      "Neural Tumor",
      "Neoplasm Of Nervous System",
    ],
  },
  {
    ui: "D009381",
    heading: "Neoplasms, Radiation-Induced",
    tree_numbers: ["C04.682", "C26.733.476", "G01.750.748.500.476"],
  },
  {
    ui: "D009382",
    heading: "Neoplasms, Unknown Primary",
    tree_numbers: ["C04.697.650.895", "C23.550.727.650.895"],
  },
  {
    ui: "D009383",
    heading: "Neoplasms, Vascular Tissue",
    tree_numbers: ["C04.557.645"],
  },
  {
    ui: "D009384",
    heading: "Paraneoplastic Endocrine Syndromes",
    tree_numbers: ["C04.588.322.490", "C04.730.713", "C19.344.473"],
  },
  {
    ui: "D009385",
    heading: "Neoplastic Processes",
    tree_numbers: ["C04.697", "C23.550.727"],
  },
  {
    ui: "D009386",
    heading: "Neoplastic Syndromes, Hereditary",
    tree_numbers: ["C04.700", "C16.320.700"],
  },
  {
    ui: "D009389",
    heading: "Neovascularization, Pathologic",
    tree_numbers: ["C23.550.589.500"],
  },
  {
    ui: "D009393",
    heading: "Nephritis",
    tree_numbers: ["C12.777.419.570", "C13.351.968.419.570"],
  },
  {
    ui: "D009394",
    heading: "Nephritis, Hereditary",
    tree_numbers: [
      "C12.706.742",
      "C12.777.419.570.620",
      "C13.351.875.742",
      "C13.351.968.419.570.620",
      "C16.131.939.742",
      "C17.300.200.517",
    ],
    synonyms: ["Hereditary Nephritis"],
  },
  {
    ui: "D009395",
    heading: "Nephritis, Interstitial",
    tree_numbers: ["C12.777.419.570.643", "C13.351.968.419.570.643"],
    synonyms: ["Renal Tubulo-Interstitial Disease"],
  },
  {
    ui: "D009396",
    heading: "Wilms Tumor",
    tree_numbers: [
      "C04.557.435.595",
      "C04.588.945.947.535.585",
      "C04.700.900",
      "C12.758.820.750.585",
      "C12.777.419.473.585",
      "C13.351.937.820.535.585",
      "C13.351.968.419.473.585",
      "C16.320.700.900",
    ],
    synonyms: ["Adult Nephroblastoma"],
  },
  {
    ui: "D009397",
    heading: "Nephrocalcinosis",
    tree_numbers: ["C12.777.419.590", "C13.351.968.419.590", "C18.452.174.130.560"],
  },
  {
    ui: "D009400",
    heading: "Nephrosclerosis",
    tree_numbers: ["C12.777.419.610", "C13.351.968.419.610"],
    synonyms: ["Renal Sclerosis"],
  },
  {
    ui: "D009401",
    heading: "Nephrosis",
    tree_numbers: ["C12.777.419.630", "C13.351.968.419.630"],
  },
  {
    ui: "D009402",
    heading: "Nephrosis, Lipoid",
    tree_numbers: ["C12.777.419.630.477", "C13.351.968.419.630.477"],
    synonyms: [
      "Minimal Change Disease",
      "Nephrotic Syndrome With Lesion Of Minimal Change Glomerulonephritis",
      "Minimal Change Glomerulonephritis",
      "Nephrotic Syndrome With Lesion Of Minimal Change Nephrotic Syndrome",
    ],
  },
  {
    ui: "D009404",
    heading: "Nephrotic Syndrome",
    tree_numbers: ["C12.777.419.630.643", "C13.351.968.419.630.643"],
  },
  {
    ui: "D009408",
    heading: "Nerve Compression Syndromes",
    tree_numbers: ["C10.668.829.550"],
    synonyms: [
      "Entrapment Neuropathy",
      "Entrapment Neuropathy",
      "Compression Neuropathy",
      "Peripheral Nerve Entrapment Syndrome",
    ],
  },
  {
    ui: "D009410",
    heading: "Nerve Degeneration",
    tree_numbers: ["C23.550.737"],
  },
  {
    ui: "D009421",
    heading: "Nervous System Malformations",
    tree_numbers: ["C10.500", "C16.131.666"],
  },
  {
    ui: "D009422",
    heading: "Nervous System Diseases",
    tree_numbers: ["C10"],
  },
  {
    ui: "D009423",
    heading: "Nervous System Neoplasms",
    tree_numbers: ["C04.588.614", "C10.551"],
  },
  {
    ui: "D009436",
    heading: "Neural Tube Defects",
    tree_numbers: ["C10.500.680", "C16.131.666.680"],
  },
  {
    ui: "D009437",
    heading: "Neuralgia",
    tree_numbers: ["C10.668.829.600", "C23.888.592.612.664"],
  },
  {
    ui: "D009440",
    heading: "Neurasthenia",
    tree_numbers: ["F03.875.600"],
  },
  {
    ui: "D009442",
    heading: "Neurilemmoma",
    tree_numbers: ["C04.557.465.625.650.595", "C04.557.580.600.610.595", "C04.557.580.625.650.595"],
    synonyms: ["Schwannoma", "Psammomatous Schwannoma"],
  },
  {
    ui: "D009443",
    heading: "Neuritis",
    tree_numbers: ["C10.668.829.650"],
    synonyms: ["Peripheral Neuritis"],
  },
  {
    ui: "D009444",
    heading: "Neuritis, Autoimmune, Experimental",
    tree_numbers: ["C10.114.703.700", "C10.668.829.650.500", "C20.111.258.625.700", "E05.598.500.500.875"],
  },
  {
    ui: "D009447",
    heading: "Neuroblastoma",
    tree_numbers: ["C04.557.465.625.600.590.650.550", "C04.557.470.670.590.650.550", "C04.557.580.625.600.590.650.550"],
  },
  {
    ui: "D009449",
    heading: "Neurocirculatory Asthenia",
    tree_numbers: ["F03.080.500"],
    synonyms: [
      "Cardiovascular Malfunction Arising From Mental Factors",
      "Krishaber's Disease",
      "Cardiovascular Neurosis",
      "Da Costa's Syndrome",
    ],
  },
  {
    ui: "D009450",
    heading: "Neurodermatitis",
    tree_numbers: ["C17.800.174.660", "C17.800.815.660"],
  },
  {
    ui: "D009455",
    heading: "Neurofibroma",
    tree_numbers: ["C04.557.580.600.580", "C10.551.775.500.750", "C10.668.829.725.500.600"],
  },
  {
    ui: "D009456",
    heading: "Neurofibromatosis 1",
    tree_numbers: [
      "C04.557.580.600.580.590.650",
      "C04.700.631.650",
      "C10.562.600.500",
      "C10.574.500.549.400",
      "C10.668.829.675",
      "C16.320.400.560.400",
      "C16.320.700.633.650",
    ],
    synonyms: [
      "Von Recklinghausen Disease",
      "Familial Spinal Neurofibromatosis",
      "Neurofibromatosis Type I",
      "Nf1",
      "Fsnf",
      "Peripheral Neurofibromatosis",
    ],
  },
  {
    ui: "D009459",
    heading: "Neuroleptic Malignant Syndrome",
    tree_numbers: ["C10.228.140.079.737", "C10.720.737", "C25.723.705.600"],
  },
  {
    ui: "D009461",
    heading: "Neurologic Manifestations",
    tree_numbers: ["C10.597", "C23.888.592"],
  },
  {
    ui: "D009463",
    heading: "Neuroma",
    tree_numbers: ["C04.557.580.600.610"],
  },
  {
    ui: "D009464",
    heading: "Neuroma, Acoustic",
    tree_numbers: [
      "C04.557.465.625.650.595.610",
      "C04.557.580.600.610.595.610",
      "C04.557.580.625.650.595.610",
      "C04.588.614.300.015",
      "C04.588.614.596.240.015",
      "C09.218.807.800.675",
      "C09.647.675",
      "C10.292.225.750",
      "C10.292.910.600",
    ],
    synonyms: ["Vestibular Schwannoma", "Vestibular Neurilemmoma"],
  },
  {
    ui: "D009468",
    heading: "Neuromuscular Diseases",
    tree_numbers: ["C10.668"],
  },
  {
    ui: "D009471",
    heading: "Neuromyelitis Optica",
    tree_numbers: [
      "C10.114.375.600.500",
      "C10.114.375.800",
      "C10.292.700.550.500",
      "C10.314.350.600.500",
      "C10.314.350.800",
      "C11.640.576.695",
      "C20.111.258.250.550.500",
      "C20.111.258.250.775",
    ],
    synonyms: ["Devic's Disease", "Devic's Syndrome"],
  },
  {
    ui: "D009472",
    heading: "Neuronal Ceroid-Lipofuscinoses",
    tree_numbers: [
      "C10.574.500.550",
      "C16.320.400.600",
      "C16.320.565.398.641.509",
      "C18.452.584.687.509",
      "C18.452.648.398.641.509",
    ],
    synonyms: ["Hereditary Ceroid Lipofuscinosis"],
  },
  {
    ui: "D009477",
    heading: "Hereditary Sensory and Autonomic Neuropathies",
    tree_numbers: ["C10.500.250", "C10.574.500.493", "C10.668.829.800.175", "C16.131.666.310", "C16.320.400.415"],
    synonyms: ["Peripheral Sensory Neuropathy", "Sensory Neuropathy", "Sensory Neuropathy"],
  },
  {
    ui: "D009494",
    heading: "Neurosyphilis",
    tree_numbers: [
      "C01.150.252.223.600",
      "C01.150.252.400.794.840.500.750",
      "C01.150.252.400.840.500.750",
      "C01.207.180.600",
      "C10.228.228.180.600",
    ],
    synonyms: ["Juvenile Neurosyphilis"],
  },
  {
    ui: "D009497",
    heading: "Neurotic Disorders",
    tree_numbers: ["F03.080.550", "F03.650"],
    synonyms: [
      "Depressive Neurosis",
      "Neurotic Depression Reactive Type",
      "Neurotic Depressive State",
      "Neurotic Depression",
      "Psychoneurosis",
      "Neurosis",
      "Reactive Depression",
    ],
  },
  {
    ui: "D009503",
    heading: "Neutropenia",
    tree_numbers: ["C15.378.553.546.184.564"],
  },
  {
    ui: "D009506",
    heading: "Nevus",
    tree_numbers: ["C04.557.665.560"],
  },
  {
    ui: "D009507",
    heading: "Nevus of Ota",
    tree_numbers: ["C04.557.665.560.615.585"],
  },
  {
    ui: "D009508",
    heading: "Nevus, Pigmented",
    tree_numbers: ["C04.557.665.560.615"],
  },
  {
    ui: "D009521",
    heading: "Newcastle Disease",
    tree_numbers: ["C01.925.782.580.600.080.600", "C22.131.630"],
    synonyms: ["Newcastle's Disease", "Pseudo-Fowlpest"],
  },
  {
    ui: "D009542",
    heading: "Niemann-Pick Diseases",
    tree_numbers: [
      "C10.228.140.163.100.435.825.700",
      "C15.604.250.410.625",
      "C16.320.565.189.435.825.700",
      "C16.320.565.398.641.803.730",
      "C16.320.565.595.554.825.700",
      "C18.452.132.100.435.825.700",
      "C18.452.584.687.803.730",
      "C18.452.648.189.435.825.700",
      "C18.452.648.398.641.803.730",
      "C18.452.648.595.554.825.700",
    ],
    synonyms: ["Lipoid Histiocytosis", "Sphingomyelinase Deficiency Disease", "Sphingomyelin Lipidosis"],
  },
  {
    ui: "D009617",
    heading: "Nocardia Infections",
    tree_numbers: ["C01.150.252.410.040.692"],
    synonyms: ["Nocardia Infectious Disease"],
  },
  {
    ui: "D009625",
    heading: "Noma",
    tree_numbers: ["C07.465.604"],
    synonyms: ["Cancrum Oris", "Gangrenous Stomatitis"],
  },
  {
    ui: "D009630",
    heading: "Nondisjunction, Genetic",
    tree_numbers: ["C23.550.210.645", "G05.113.220.625.620", "G05.558.620"],
  },
  {
    ui: "D009631",
    heading: "Nonodontogenic Cysts",
    tree_numbers: ["C04.182.089.530.660", "C05.500.470.660", "C07.320.450.640"],
  },
  {
    ui: "D009634",
    heading: "Noonan Syndrome",
    tree_numbers: [
      "C05.660.207.690",
      "C14.240.400.787",
      "C14.280.400.787",
      "C16.131.240.400.784",
      "C16.131.621.207.690",
      "C17.300.690",
    ],
    synonyms: ["Turner's Phenotype, Karyotype Normal"],
  },
  {
    ui: "D009667",
    heading: "Nose Deformities, Acquired",
    tree_numbers: ["C08.460.619", "C09.603.619"],
  },
  {
    ui: "D009668",
    heading: "Nose Diseases",
    tree_numbers: ["C08.460", "C09.603"],
    synonyms: ["Disorder Of The Nose", "Nasal Disorder"],
  },
  {
    ui: "D009669",
    heading: "Nose Neoplasms",
    tree_numbers: [
      "C04.588.149.721.600",
      "C04.588.443.665.650",
      "C05.116.231.754.600",
      "C08.460.669",
      "C08.785.600",
      "C09.603.669",
      "C09.647.685",
    ],
  },
  {
    ui: "D009748",
    heading: "Nutrition Disorders",
    tree_numbers: ["C18.654"],
    synonyms: ["Nutritional Disorder"],
  },
  {
    ui: "D009750",
    heading: "Nutritional and Metabolic Diseases",
    tree_numbers: ["C18"],
  },
  {
    ui: "D009755",
    heading: "Night Blindness",
    tree_numbers: ["C11.966.671"],
    synonyms: ["Nyctalopia"],
  },
  {
    ui: "D009759",
    heading: "Nystagmus, Pathologic",
    tree_numbers: ["C10.292.562.675", "C11.590.400"],
    synonyms: ["Searching Eye Movements", "Visual Deprivation Nystagmus", "Ocular Nystagmus"],
  },
  {
    ui: "D009765",
    heading: "Obesity",
    tree_numbers: [
      "C18.654.726.500",
      "C23.888.144.699.500",
      "E01.370.600.115.100.160.120.699.500",
      "G07.100.100.160.120.699.500",
    ],
  },
  {
    ui: "D009767",
    heading: "Obesity, Morbid",
    tree_numbers: [
      "C18.654.726.500.700",
      "C23.888.144.699.500.500",
      "E01.370.600.115.100.160.120.699.500.500",
      "G07.100.100.160.120.699.500.500",
    ],
    synonyms: ["Severe Obesity"],
  },
  {
    ui: "D009771",
    heading: "Obsessive-Compulsive Disorder",
    tree_numbers: ["F03.080.600"],
    synonyms: ["Anancastic Neurosis", "Obsessive Compulsive Disorder"],
  },
  {
    ui: "D009783",
    heading: "Dermatitis, Occupational",
    tree_numbers: ["C17.800.174.255.700", "C17.800.815.255.700", "C24.270"],
    synonyms: [
      "Occupational Eczema",
      "Occupational Allergic Contact Dermatitis",
      "Occupational Dermatitis",
      "Occupational Dermatitis",
    ],
  },
  {
    ui: "D009784",
    heading: "Occupational Diseases",
    tree_numbers: ["C24"],
  },
  {
    ui: "D009794",
    heading: "Ochronosis",
    tree_numbers: ["C23.550.744"],
  },
  {
    ui: "D009798",
    heading: "Ocular Hypertension",
    tree_numbers: ["C11.525"],
  },
  {
    ui: "D009800",
    heading: "Oculocerebrorenal Syndrome",
    tree_numbers: [
      "C10.228.140.163.100.640",
      "C12.777.419.815.720",
      "C13.351.968.419.815.720",
      "C16.131.077.662",
      "C16.320.322.750",
      "C16.320.565.151.600",
      "C16.320.565.189.640",
      "C16.320.709",
      "C16.320.831.750",
      "C18.452.132.100.640",
      "C18.452.648.151.600",
      "C18.452.648.189.640",
    ],
    synonyms: ["Lowe Syndrome", "Lowe Oculocerebrorenal Syndrome", "Oculocerebrorenal Syndrome Of Lowe"],
  },
  {
    ui: "D009807",
    heading: "Odontogenic Cysts",
    tree_numbers: ["C04.182.089.530.690", "C05.500.470.690", "C07.320.450.670"],
  },
  {
    ui: "D009808",
    heading: "Odontogenic Tumors",
    tree_numbers: ["C04.557.695"],
  },
  {
    ui: "D009810",
    heading: "Odontoma",
    tree_numbers: ["C04.557.695.610"],
  },
  {
    ui: "D009814",
    heading: "Oesophagostomiasis",
    tree_numbers: ["C01.610.335.508.700.775.583"],
    synonyms: ["Infection By Oesophagostomum"],
  },
  {
    ui: "D009837",
    heading: "Oligodendroglioma",
    tree_numbers: ["C04.557.465.625.600.380.590", "C04.557.470.670.380.590", "C04.557.580.625.600.380.590"],
    synonyms: ["Pediatric Oligodendroglioma"],
  },
  {
    ui: "D009839",
    heading: "Oligomenorrhea",
    tree_numbers: ["C23.550.568.937"],
  },
  {
    ui: "D009845",
    heading: "Oligospermia",
    tree_numbers: ["C12.294.365.700.508"],
  },
  {
    ui: "D009846",
    heading: "Oliguria",
    tree_numbers: ["C12.777.934.600", "C13.351.968.934.600", "C23.888.942.400"],
  },
  {
    ui: "D009849",
    heading: "Olivopontocerebellar Atrophies",
    tree_numbers: [
      "C10.177.575.550.375",
      "C10.228.140.079.612.600",
      "C10.228.140.252.700.650",
      "C10.228.662.550.600",
      "C10.228.854.787.750",
      "C10.574.500.825.650",
      "C16.320.400.780.750",
    ],
    synonyms: ["Wadia-Swami Syndrome", "Dejerine-Thomas Syndrome", "Thomas' Syndrome"],
  },
  {
    ui: "D009855",
    heading: "Onchocerciasis",
    tree_numbers: ["C01.610.335.508.700.750.361.699", "C01.610.858.650", "C17.800.838.775.690"],
    synonyms: ["Infection By Onchocerca Volvulus", "Onchocerca Volvulus Infection", "Volvulosis"],
  },
  {
    ui: "D009869",
    heading: "Oophoritis",
    tree_numbers: ["C13.351.500.056.630.450", "C13.351.500.056.750.500", "C19.391.630.450"],
  },
  {
    ui: "D009877",
    heading: "Endophthalmitis",
    tree_numbers: ["C01.375.265", "C11.294.265"],
  },
  {
    ui: "D009878",
    heading: "Ophthalmia Neonatorum",
    tree_numbers: [
      "C01.150.252.289.225.625",
      "C01.150.252.400.625.774",
      "C01.375.354.220.625",
      "C11.187.183.220.538",
      "C11.294.354.220.625",
      "C16.614.677",
    ],
  },
  {
    ui: "D009879",
    heading: "Ophthalmia, Sympathetic",
    tree_numbers: ["C11.941.879.780.500", "C20.111.709"],
    synonyms: ["Sympathetic Uveitis"],
  },
  {
    ui: "D009886",
    heading: "Ophthalmoplegia",
    tree_numbers: ["C10.292.562.750", "C10.597.622.447", "C11.590.472", "C23.888.592.636.447"],
    synonyms: ["Oculomotor Paralysis", "Total Ophthalmoplegia"],
  },
  {
    ui: "D009889",
    heading: "Opisthorchiasis",
    tree_numbers: ["C01.610.335.865.685"],
    synonyms: ["Infection By Opisthorchis"],
  },
  {
    ui: "D009894",
    heading: "Opportunistic Infections",
    tree_numbers: ["C01.597", "C01.610.684", "C01.925.597"],
  },
  {
    ui: "D009896",
    heading: "Optic Atrophy",
    tree_numbers: ["C10.292.700.225", "C11.640.451"],
    synonyms: ["Atrophy Of Optic Disc"],
  },
  {
    ui: "D009901",
    heading: "Optic Nerve Diseases",
    tree_numbers: ["C10.292.700", "C11.640"],
    synonyms: ["Disorder Of The Second Nerve", "Optic Nerve Disorder", "Optic Neuropathy"],
  },
  {
    ui: "D009902",
    heading: "Optic Neuritis",
    tree_numbers: ["C10.292.700.550", "C11.640.576"],
  },
  {
    ui: "D009912",
    heading: "Oral Manifestations",
    tree_numbers: ["C07.465.634", "C23.888.619"],
  },
  {
    ui: "D009914",
    heading: "Oral Submucous Fibrosis",
    tree_numbers: ["C07.465.654"],
    synonyms: [
      "Oral Cavity Submucous Fibrosis",
      "Oral Submucosal Fibrosis, Including Of Tongue",
      "Oral Submucosal Fibrosis",
    ],
  },
  {
    ui: "D009916",
    heading: "Orbital Diseases",
    tree_numbers: ["C11.675"],
  },
  {
    ui: "D009917",
    heading: "Orbital Fractures",
    tree_numbers: ["C10.900.300.284.500.550", "C26.404.750.684", "C26.915.300.425.500.550"],
  },
  {
    ui: "D009918",
    heading: "Orbital Neoplasms",
    tree_numbers: ["C04.588.149.721.656", "C04.588.364.659", "C05.116.231.754.659", "C11.319.457", "C11.675.659"],
    synonyms: ["Malignant Neoplasm Of Orbit", "Orbital Tumor", "Neoplasm Of Orbit Proper", "Orbit Cancer"],
  },
  {
    ui: "D009920",
    heading: "Orchitis",
    tree_numbers: ["C12.294.829.493", "C19.391.829.493"],
    synonyms: ["Inflammation Of Testis", "Orchititis"],
  },
  {
    ui: "D009956",
    heading: "Psittacosis",
    tree_numbers: ["C01.150.252.400.210.250.600"],
    synonyms: ["Psittacosis"],
  },
  {
    ui: "D009957",
    heading: "Oroantral Fistula",
    tree_numbers: ["C07.465.614.421", "C23.300.575.500.550"],
  },
  {
    ui: "D009958",
    heading: "Orofaciodigital Syndromes",
    tree_numbers: [
      "C05.116.099.370.652",
      "C05.660.207.700",
      "C16.131.077.676",
      "C16.131.260.830.670",
      "C16.131.621.207.700",
      "C16.320.180.830.670",
      "C16.320.714",
    ],
    synonyms: ["Oral-Facial-Digital Syndrome"],
  },
  {
    ui: "D009959",
    heading: "Oropharyngeal Neoplasms",
    tree_numbers: ["C04.588.443.665.710.684", "C07.550.745.671", "C09.647.710.685", "C09.775.549.685"],
  },
  {
    ui: "D009976",
    heading: "Orthomyxoviridae Infections",
    tree_numbers: ["C01.925.782.620"],
  },
  {
    ui: "D009999",
    heading: "Ossification, Heterotopic",
    tree_numbers: ["C23.550.751"],
  },
  {
    ui: "D010000",
    heading: "Osteitis",
    tree_numbers: ["C05.116.680"],
    synonyms: ["Inflammatory Disorder Of Bone", "Osteitis", "Bone Inflammatory Disease"],
  },
  {
    ui: "D010001",
    heading: "Osteitis Deformans",
    tree_numbers: ["C05.116.692"],
    synonyms: ["Osseous Paget's Disease", "Paget's Bone Disease", "Osteitis Deformans", "Paget Disease Of Bone"],
  },
  {
    ui: "D010002",
    heading: "Osteitis Fibrosa Cystica",
    tree_numbers: ["C05.116.132.684"],
    synonyms: [
      "Osteitis Fibrosa Cystica Generalisata",
      "Von Recklinghausen's Bone Disease",
      "Hyperparathyroid Bone Disease",
    ],
  },
  {
    ui: "D010003",
    heading: "Osteoarthritis",
    tree_numbers: ["C05.550.114.606", "C05.799.613"],
  },
  {
    ui: "D010004",
    heading: "Osteoarthropathy, Primary Hypertrophic",
    tree_numbers: ["C05.116.725", "C05.550.648", "C16.320.718"],
    synonyms: ["Pachydermoperiostosis Syndrome", "Pachydermoperiostosis Of Nail"],
  },
  {
    ui: "D010005",
    heading: "Osteoarthropathy, Secondary Hypertrophic",
    tree_numbers: ["C05.116.758", "C05.550.684"],
    synonyms: [
      "Hypertrophic Pulmonary Osteoarthropathy",
      "Marie Bamberger Disease",
      "Hpoa - Hypertrophic Pulmonary Osteoarthropathy",
      "Bamberger-Marie Disease",
    ],
  },
  {
    ui: "D010007",
    heading: "Osteochondritis",
    tree_numbers: ["C05.116.791", "C05.182.520", "C17.300.182.520"],
    synonyms: ["Ocd"],
  },
  {
    ui: "D010008",
    heading: "Osteochondritis Dissecans",
    tree_numbers: ["C05.116.791.668"],
    synonyms: ["Ocd"],
  },
  {
    ui: "D010009",
    heading: "Osteochondrodysplasias",
    tree_numbers: ["C05.116.099.708", "C16.320.728"],
    synonyms: ["Melnick-Needles Osteodysplasty", "Mns", "Osteodysplasty Of Melnick And Needles"],
  },
  {
    ui: "D010013",
    heading: "Osteogenesis Imperfecta",
    tree_numbers: ["C05.116.099.708.685", "C16.320.737", "C17.300.200.540"],
    synonyms: ["Oi1", "Osteogenesis Imperfecta Type I"],
  },
  {
    ui: "D010014",
    heading: "Osteolysis",
    tree_numbers: ["C05.116.264.579", "G11.427.213.150.570"],
  },
  {
    ui: "D010015",
    heading: "Osteolysis, Essential",
    tree_numbers: ["C05.116.099.736", "C05.116.264.579.704"],
    synonyms: [
      "Phantom Bone Disease",
      "Massive Osteolysis",
      "Gorham Disease",
      "Disappearing Bone Disease",
      "Essential Osteolysis",
    ],
  },
  {
    ui: "D010016",
    heading: "Osteoma",
    tree_numbers: ["C04.557.450.565.575.625"],
  },
  {
    ui: "D010017",
    heading: "Osteoma, Osteoid",
    tree_numbers: ["C04.557.450.565.575.625.625"],
  },
  {
    ui: "D010018",
    heading: "Osteomalacia",
    tree_numbers: [
      "C05.116.198.816.640",
      "C18.452.104.816.640",
      "C18.452.174.845.640",
      "C18.654.521.500.133.770.734.640",
    ],
  },
  {
    ui: "D010019",
    heading: "Osteomyelitis",
    tree_numbers: ["C01.160.495", "C05.116.165.495"],
  },
  {
    ui: "D010020",
    heading: "Osteonecrosis",
    tree_numbers: ["C05.116.852", "C23.550.717.732"],
    synonyms: ["Aseptic Necrosis", "Avascular Necrosis Of Bone", "Bone Necrosis"],
  },
  {
    ui: "D010022",
    heading: "Osteopetrosis",
    tree_numbers: ["C05.116.099.708.702.678"],
    synonyms: [
      "Autosomal Dominant Albers-Schonberg Disease",
      "Opta2",
      "Albers-Schonberg Osteopetrosis",
      "Osteopetrosis Autosomal Dominant Type 2",
      "Autosomal Dominant Osteopetrosis Type Ii",
    ],
  },
  {
    ui: "D010023",
    heading: "Osteopoikilosis",
    tree_numbers: ["C05.116.099.708.702.685", "C17.300.705"],
  },
  {
    ui: "D010024",
    heading: "Osteoporosis",
    tree_numbers: ["C05.116.198.579", "C18.452.104.579"],
  },
  {
    ui: "D010025",
    heading: "Osteoradionecrosis",
    tree_numbers: ["C26.733.579", "G01.750.748.500.579"],
  },
  {
    ui: "D010026",
    heading: "Osteosclerosis",
    tree_numbers: ["C05.116.099.708.702"],
  },
  {
    ui: "D010029",
    heading: "Ostertagiasis",
    tree_numbers: ["C01.610.335.508.700.775.825.580"],
  },
  {
    ui: "D010031",
    heading: "Otitis",
    tree_numbers: ["C09.218.705"],
  },
  {
    ui: "D010032",
    heading: "Otitis Externa",
    tree_numbers: ["C09.218.705.496"],
  },
  {
    ui: "D010033",
    heading: "Otitis Media",
    tree_numbers: ["C09.218.705.663"],
  },
  {
    ui: "D010034",
    heading: "Otitis Media with Effusion",
    tree_numbers: ["C09.218.705.663.670"],
  },
  {
    ui: "D010035",
    heading: "Otitis Media, Suppurative",
    tree_numbers: ["C01.830.694", "C09.218.705.663.680"],
    synonyms: ["Otitis Media With Effusion - Purulent", "Purulent Otitis Media"],
  },
  {
    ui: "D010038",
    heading: "Otorhinolaryngologic Diseases",
    tree_numbers: ["C09"],
  },
  {
    ui: "D010039",
    heading: "Otorhinolaryngologic Neoplasms",
    tree_numbers: ["C04.588.443.665", "C09.647"],
  },
  {
    ui: "D010040",
    heading: "Otosclerosis",
    tree_numbers: ["C09.218.768"],
  },
  {
    ui: "D010048",
    heading: "Ovarian Cysts",
    tree_numbers: ["C04.182.612", "C13.351.500.056.630.580", "C19.391.630.580"],
  },
  {
    ui: "D010049",
    heading: "Ovarian Diseases",
    tree_numbers: ["C13.351.500.056.630", "C19.391.630"],
  },
  {
    ui: "D010051",
    heading: "Ovarian Neoplasms",
    tree_numbers: [
      "C04.588.322.455",
      "C13.351.500.056.630.705",
      "C13.351.937.418.685",
      "C19.344.410",
      "C19.391.630.705",
    ],
    synonyms: [
      "Ovarian Neoplasm",
      "Primary Ovarian Cancer",
      "Malignant Ovarian Tumor",
      "Malignant Tumour Of Ovary",
      "Ovary Neoplasm",
      "Tumor Of The Ovary",
    ],
  },
  {
    ui: "D010123",
    heading: "Oxyuriasis",
    tree_numbers: ["C01.610.335.508.700.550.550"],
    synonyms: ["Oxyuris Vermicularis Infection", "Threadworm Infection"],
  },
  {
    ui: "D010144",
    heading: "Paget's Disease, Mammary",
    tree_numbers: ["C04.557.470.200.240.187.500", "C04.557.470.615.275.625"],
  },
  {
    ui: "D010145",
    heading: "Paget Disease, Extramammary",
    tree_numbers: ["C04.557.470.200.025.660", "C04.557.470.615.660"],
    synonyms: ["Extramammary Paget's Disease", "Cutaneous Paget Disease", "Paget's Disease Of Skin"],
  },
  {
    ui: "D010146",
    heading: "Pain",
    tree_numbers: ["C23.888.592.612", "F02.830.816.444", "G11.561.790.444"],
  },
  {
    ui: "D010148",
    heading: "Pain, Intractable",
    tree_numbers: ["C23.888.592.612.776"],
  },
  {
    ui: "D010149",
    heading: "Pain, Postoperative",
    tree_numbers: ["C23.550.767.700", "C23.888.592.612.832"],
  },
  {
    ui: "D010157",
    heading: "Palatal Neoplasms",
    tree_numbers: [
      "C04.588.149.721.450.692",
      "C04.588.443.591.692",
      "C05.116.231.754.450.692",
      "C05.500.499.692",
      "C07.320.515.692",
      "C07.465.530.692",
    ],
  },
  {
    ui: "D010167",
    heading: "Pallor",
    tree_numbers: ["C23.888.885.500"],
  },
  {
    ui: "D010178",
    heading: "Pancoast Syndrome",
    tree_numbers: ["C04.588.894.797.520.734", "C08.381.540.734", "C08.785.520.734"],
    synonyms: [
      "Pulmonary Sulcus Tumor",
      "Malignant Superior Sulcus Tumour",
      "Pancoast's Tumour",
      "Pancoast Tumour",
      "Superior Pulmonary Sulcus Syndrome",
      "Malignant Superior Sulcus Tumor",
      "Pancoast's Syndrome",
      "Pulmonary Sulcus Tumour",
    ],
  },
  {
    ui: "D010181",
    heading: "Pancreatic Cyst",
    tree_numbers: ["C04.182.640", "C06.689.500"],
  },
  {
    ui: "D010182",
    heading: "Pancreatic Diseases",
    tree_numbers: ["C06.689"],
  },
  {
    ui: "D010185",
    heading: "Pancreatic Fistula",
    tree_numbers: ["C06.267.775", "C06.689.583", "C23.300.575.185.775"],
  },
  {
    ui: "D010188",
    heading: "Exocrine Pancreatic Insufficiency",
    tree_numbers: ["C06.689.276"],
    synonyms: ["Exocrine Pancreatic Insufficiency"],
  },
  {
    ui: "D010190",
    heading: "Pancreatic Neoplasms",
    tree_numbers: ["C04.588.274.761", "C04.588.322.475", "C06.301.761", "C06.689.667", "C19.344.421"],
    synonyms: [
      "Ca Head Of Pancreas",
      "Ca Tail Of Pancreas",
      "Pancreatic Neoplasm",
      "Pancreatic Tumor",
      "Malignant Neoplasm Of Head Of Pancreas",
      "Malignant Neoplasm Of Tail Of Pancreas",
      "Pancreas Neoplasm",
      "Ca Body Of Pancreas",
      "Malignant Neoplasm Of Body Of Pancreas",
    ],
  },
  {
    ui: "D010192",
    heading: "Pancreatic Pseudocyst",
    tree_numbers: ["C04.182.640.692", "C06.689.500.692"],
  },
  {
    ui: "D010195",
    heading: "Pancreatitis",
    tree_numbers: ["C06.689.750"],
    synonyms: ["Acute Necrotizing Pancreatitis", "Pancreatitis Necrotizing"],
  },
  {
    ui: "D010198",
    heading: "Pancytopenia",
    tree_numbers: ["C15.378.700"],
  },
  {
    ui: "D010201",
    heading: "Panniculitis, Nodular Nonsuppurative",
    tree_numbers: ["C17.300.710.500", "C17.800.566.500"],
    synonyms: [
      "Weber-Christian Disease",
      "Weber - Christian Disease",
      "Relapsing Febrile Nodular Nonsuppurative Panniculitis",
      "Nodular Non-Suppurative Febrile Panniculitis",
    ],
  },
  {
    ui: "D010202",
    heading: "Panophthalmitis",
    tree_numbers: [
      "C01.150.252.289.900.675",
      "C01.150.703.320.900.675",
      "C01.375.354.900.675",
      "C01.375.450.900.675",
      "C11.294.354.900.675",
      "C11.294.450.900.675",
      "C11.941.879.960.580",
    ],
  },
  {
    ui: "D010211",
    heading: "Papilledema",
    tree_numbers: ["C10.292.700.900", "C11.640.710"],
    synonyms: ["Papillitis"],
  },
  {
    ui: "D010212",
    heading: "Papilloma",
    tree_numbers: ["C04.557.470.700.600"],
    synonyms: ["Epidermoid Papilloma"],
  },
  {
    ui: "D010214",
    heading: "Papillon-Lefevre Disease",
    tree_numbers: ["C16.320.850.475.600", "C17.800.428.435.600", "C17.800.827.475.600"],
    synonyms: ["Papillon Lefevre Syndrome", "Papillon-Lefvre Syndrome"],
  },
  {
    ui: "D010217",
    heading: "Phlebotomus Fever",
    tree_numbers: ["C01.920.500.700", "C01.925.081.700", "C01.925.782.147.700"],
    synonyms: [
      "Sandfly-Borne Bunyavirus Fever",
      "Pappataci Fever",
      "Sandfly Fever",
      "Sandfly-Borne Phleboviral Disease",
      "Sandfly-Borne Arboviral Fever",
    ],
  },
  {
    ui: "D010229",
    heading: "Paracoccidioidomycosis",
    tree_numbers: ["C01.150.703.700"],
    synonyms: [
      "Paracoccidioidomycosis",
      "Mucocutaneous-Lymphangitic Paracoccidioidomycosis",
      "Paracoccidioidal Mycosis",
    ],
  },
  {
    ui: "D010235",
    heading: "Paraganglioma",
    tree_numbers: ["C04.557.465.625.650.700", "C04.557.580.625.650.700"],
    synonyms: ["Chemodectoma", "Glomus Body Tumor"],
  },
  {
    ui: "D010236",
    heading: "Paraganglioma, Extra-Adrenal",
    tree_numbers: ["C04.557.465.625.650.700.705", "C04.557.580.625.650.700.705"],
  },
  {
    ui: "D010237",
    heading: "Paragonimiasis",
    tree_numbers: ["C01.610.335.865.741"],
    synonyms: ["Infection By Paragonimus", "Pulmonary Paragonimiasis", "Lung Fluke Disease", "Lung Fluke Infection"],
  },
  {
    ui: "D010241",
    heading: "Parakeratosis",
    tree_numbers: ["C17.800.428.736"],
  },
  {
    ui: "D010243",
    heading: "Paralysis",
    tree_numbers: ["C10.597.622", "C23.888.592.636"],
  },
  {
    ui: "D010244",
    heading: "Bulbar Palsy, Progressive",
    tree_numbers: ["C10.574.562.300", "C10.668.467.300"],
  },
  {
    ui: "D010245",
    heading: "Paralyses, Familial Periodic",
    tree_numbers: ["C05.651.701", "C10.668.491.650", "C16.320.565.618.711", "C18.452.648.618.711"],
  },
  {
    ui: "D010246",
    heading: "Paralysis, Obstetric",
    tree_numbers: ["C16.614.131.587", "C26.141.587"],
  },
  {
    ui: "D010249",
    heading: "Parametritis",
    tree_numbers: ["C13.351.500.056.750.750"],
    synonyms: ["Pelvic Cellulitis"],
  },
  {
    ui: "D010253",
    heading: "Respirovirus Infections",
    tree_numbers: ["C01.925.782.580.600.600"],
  },
  {
    ui: "D010254",
    heading: "Paranasal Sinus Diseases",
    tree_numbers: ["C08.460.692", "C09.603.692"],
    synonyms: ["Disorder Of Nasal Sinus"],
  },
  {
    ui: "D010255",
    heading: "Paranasal Sinus Neoplasms",
    tree_numbers: [
      "C04.588.443.665.650.693",
      "C08.460.669.693",
      "C08.460.692.503",
      "C08.785.600.693",
      "C09.603.669.693",
      "C09.603.692.503",
      "C09.647.685.693",
    ],
    synonyms: ["Neoplasm Of Accessory Sinus", "Tumor Of Accessory Sinus"],
  },
  {
    ui: "D010257",
    heading: "Paraneoplastic Syndromes",
    tree_numbers: ["C04.730"],
  },
  {
    ui: "D010259",
    heading: "Paranoid Disorders",
    tree_numbers: ["F03.700.450"],
  },
  {
    ui: "D010260",
    heading: "Paranoid Personality Disorder",
    tree_numbers: ["F03.675.600"],
  },
  {
    ui: "D010262",
    heading: "Paraphilic Disorders",
    tree_numbers: ["F03.657"],
  },
  {
    ui: "D010263",
    heading: "Paraphimosis",
    tree_numbers: ["C12.294.494.684.587"],
  },
  {
    ui: "D010264",
    heading: "Paraplegia",
    tree_numbers: ["C10.597.622.669", "C23.888.592.636.637"],
    synonyms: ["Paraplegia, Lower"],
  },
  {
    ui: "D010265",
    heading: "Paraproteinemias",
    tree_numbers: ["C15.378.147.780", "C20.683.780"],
    synonyms: ["Plasma Cell Tumour", "Plasma Cell Dyscrasia", "Plasmacytic Tumor"],
  },
  {
    ui: "D010267",
    heading: "Parapsoriasis",
    tree_numbers: ["C17.800.859.575"],
  },
  {
    ui: "D010272",
    heading: "Parasitic Diseases",
    tree_numbers: ["C01.610"],
  },
  {
    ui: "D010273",
    heading: "Parasitic Diseases, Animal",
    tree_numbers: ["C01.610.701", "C22.674"],
  },
  {
    ui: "D010279",
    heading: "Parathyroid Diseases",
    tree_numbers: ["C19.642"],
    synonyms: ["Disease Of Parathyroid Glands"],
  },
  {
    ui: "D010282",
    heading: "Parathyroid Neoplasms",
    tree_numbers: ["C04.588.322.525", "C04.588.443.680", "C19.344.525", "C19.642.713"],
    synonyms: [
      "Parathyroid Gland Neoplasm",
      "Malignant Tumor Of Parathyroid Gland",
      "Neoplasm Of Parathyroid Gland",
      "Carcinoma Of Parathyroid Gland",
      "Parathyroid Neoplasm",
      "Malignant Neoplasm Of Parathyroid Gland",
      "Parathyroid Gland Cancer",
      "Malignant Neoplasm Of The Parathyroid",
    ],
  },
  {
    ui: "D010283",
    heading: "Paratuberculosis",
    tree_numbers: ["C01.150.252.410.040.552.475.747", "C22.688"],
  },
  {
    ui: "D010284",
    heading: "Paratyphoid Fever",
    tree_numbers: ["C01.150.252.400.310.821.438"],
    synonyms: [
      "Paratyphoid",
      "Paratyphoid Fever C",
      "Paratyphoid Fever C",
      "Paratyphoid C",
      "Paratyphoid C Fever",
      "Paratyphoid Fever B",
      "Paratyphoid B Fever",
      "Paratyphoid Fever B",
      "Paratyphoid B",
      "Paratyphoid Fever A",
      "Paratyphoid Fever A",
      "Paratyphoid A Fever",
      "Paratyphoid A",
    ],
  },
  {
    ui: "D010291",
    heading: "Paresis",
    tree_numbers: ["C10.597.636", "C23.888.592.643"],
  },
  {
    ui: "D010292",
    heading: "Paresthesia",
    tree_numbers: ["C10.597.751.791.875", "C23.888.592.763.770.875"],
  },
  {
    ui: "D010300",
    heading: "Parkinson Disease",
    tree_numbers: ["C10.228.140.079.862.500", "C10.228.662.600.400", "C10.574.928.750"],
    synonyms: ["Paralysis Agitans", "Parkinson Disease"],
  },
  {
    ui: "D010301",
    heading: "Parkinson Disease, Postencephalitic",
    tree_numbers: ["C10.228.140.079.862.800.600", "C10.228.662.600.700.500"],
    synonyms: ["Postencephalitic Parkinsonism", "Postencephalitic Parkinsonism"],
  },
  {
    ui: "D010302",
    heading: "Parkinson Disease, Secondary",
    tree_numbers: ["C10.228.140.079.862.800", "C10.228.662.600.700"],
    synonyms: [
      "Symptomatic Parkinsonism",
      "Secondary Parkinsonism",
      "Secondary Parkinsonism",
      "Disorder Presenting Primarily With Parkinsonism",
    ],
  },
  {
    ui: "D010304",
    heading: "Paronychia",
    tree_numbers: ["C01.800.460", "C17.800.529.639", "C17.800.838.486"],
    synonyms: [
      "Infected Nailfold",
      "Fungal Nail Infection",
      "Onychia And Paronychia Of Finger",
      "Onychia And Paronychia Of Toe",
    ],
  },
  {
    ui: "D010305",
    heading: "Parotid Diseases",
    tree_numbers: ["C07.465.815.470"],
  },
  {
    ui: "D010307",
    heading: "Parotid Neoplasms",
    tree_numbers: ["C04.588.443.591.824.695", "C07.465.530.824.695", "C07.465.815.470.770", "C07.465.815.718.589"],
    synonyms: [
      "Parotid Cancer",
      "Cancer Of Parotid Gland",
      "Malignant Neoplasm Of The Parotid",
      "Malignant Tumor Of Parotid Gland",
    ],
  },
  {
    ui: "D010309",
    heading: "Parotitis",
    tree_numbers: ["C07.465.815.470.800", "C07.465.815.793.500"],
  },
  {
    ui: "D010310",
    heading: "Parovarian Cyst",
    tree_numbers: ["C04.182.668", "C23.300.306.500"],
    synonyms: ["Cyst, Fimbrial", "Embryonic Fimbrial Cyst"],
  },
  {
    ui: "D010319",
    heading: "Parturient Paresis",
    tree_numbers: ["C22.695"],
  },
  {
    ui: "D010322",
    heading: "Parvoviridae Infections",
    tree_numbers: ["C01.925.256.700"],
  },
  {
    ui: "D010324",
    heading: "Passive-Aggressive Personality Disorder",
    tree_numbers: ["F03.675.625"],
  },
  {
    ui: "D010326",
    heading: "Pasteurella Infections",
    tree_numbers: ["C01.150.252.400.700.662"],
    synonyms: ["Pasteurella Infection", "Pasteurella Infectious Disease"],
  },
  {
    ui: "D010335",
    heading: "Pathologic Processes",
    tree_numbers: ["C23.550"],
  },
  {
    ui: "D010373",
    heading: "Lice Infestations",
    tree_numbers: ["C01.610.858.211.465", "C17.800.838.775.580"],
    synonyms: [
      "Pediculosis And Phthirus Infections",
      "Pediculosis",
      "Pediculosis + Lice",
      "Pediculosis And Phthirus Infestation",
      "Pediculosis And Phthirus Infection",
      "Pediculosis And Phthirus Infection",
      "Infestation By Pediculus",
      "Mixed Pediculosis Infestation",
      "Mixed Pediculosis",
      "Louse Infestation",
      "Lice Infestation",
    ],
  },
  {
    ui: "D010378",
    heading: "Pedophilia",
    tree_numbers: ["F03.657.600"],
    synonyms: ["Paedophilia"],
  },
  {
    ui: "D010381",
    heading: "Pelger-Huet Anomaly",
    tree_numbers: ["C15.378.553.696", "C16.320.784"],
  },
  {
    ui: "D010382",
    heading: "Peliosis Hepatis",
    tree_numbers: ["C06.552.802", "C14.907.609"],
    synonyms: ["Hepatic Peliosis"],
  },
  {
    ui: "D010383",
    heading: "Pellagra",
    tree_numbers: ["C18.654.521.500.133.699.529"],
    synonyms: ["Niacin Deficiency", "Pellagra", "Niacin-Tryptophan Deficiency"],
  },
  {
    ui: "D010386",
    heading: "Pelvic Neoplasms",
    tree_numbers: ["C04.588.699"],
  },
  {
    ui: "D010390",
    heading: "Pemphigoid, Benign Mucous Membrane",
    tree_numbers: ["C11.187.482", "C17.800.865.670"],
    synonyms: [
      "Ocular Pemphigoid",
      "Ocular Pemphigus",
      "Benign Mucous Membrane Pemphigoid With Ocular Involvement",
      "Benign Mucous Membrane Pemphigoid",
      "Cicatricial Pemphigoid With Ocular Involvement",
    ],
  },
  {
    ui: "D010391",
    heading: "Pemphigoid, Bullous",
    tree_numbers: ["C17.800.865.690", "C20.111.730"],
    synonyms: ["Bullous Pemphigoid"],
  },
  {
    ui: "D010392",
    heading: "Pemphigus",
    tree_numbers: ["C17.800.865.716", "C20.111.736"],
    synonyms: ["Familial Pemphigus Vulgaris"],
  },
  {
    ui: "D010409",
    heading: "Penile Diseases",
    tree_numbers: ["C12.294.494"],
  },
  {
    ui: "D010411",
    heading: "Penile Induration",
    tree_numbers: ["C12.294.494.508", "C17.300.715"],
    synonyms: ["Peyronie Disease", "Induratio Penis Plastica", "Peyronie's Fibromatosis"],
  },
  {
    ui: "D010412",
    heading: "Penile Neoplasms",
    tree_numbers: ["C04.588.945.440.715", "C12.294.260.500", "C12.294.494.591", "C12.758.409.500"],
    synonyms: ["Penile Tumor", "Neoplasm Of Penis"],
  },
  {
    ui: "D010437",
    heading: "Peptic Ulcer",
    tree_numbers: ["C06.405.469.275.800", "C06.405.748.586"],
    synonyms: [
      "Acute Peptic Ulcer Without Hemorrhage And Without Perforation",
      "Acute Peptic Ulcer Without Hemorrhage And Without Perforation",
      "Acute Peptic Ulcer With Hemorrhage",
      "Acute Peptic Ulcer With Hemorrhage And Perforation",
    ],
  },
  {
    ui: "D010438",
    heading: "Peptic Ulcer Hemorrhage",
    tree_numbers: ["C06.405.227.700", "C23.550.414.788.700"],
  },
  {
    ui: "D010439",
    heading: "Peptic Ulcer Perforation",
    tree_numbers: ["C06.405.469.275.800.698", "C06.405.748.586.698"],
    synonyms: ["Peptic Ulcer With Perforation", "Acute Peptic Ulcer With Perforation", "Perforated Peptic Ulcer"],
  },
  {
    ui: "D010468",
    heading: "Perceptual Disorders",
    tree_numbers: ["C10.597.606.762", "C23.888.592.604.764", "F01.700.750"],
  },
  {
    ui: "D010482",
    heading: "Periapical Abscess",
    tree_numbers: ["C01.830.025.650", "C07.320.830.700.700", "C07.465.714.306.700.700", "C07.465.714.533.487.700"],
    synonyms: ["Apical Abscess", "Periapical Abscess", "Dentoalveolar Abscess", "Suppurative Apical Periodontitis"],
  },
  {
    ui: "D010483",
    heading: "Periapical Diseases",
    tree_numbers: ["C07.320.830", "C07.465.714.306"],
  },
  {
    ui: "D010484",
    heading: "Periapical Granuloma",
    tree_numbers: ["C07.320.830.700.740", "C07.465.714.306.700.740", "C07.465.714.533.487.740"],
    synonyms: ["Apical Granuloma", "Periapical Granuloma"],
  },
  {
    ui: "D010485",
    heading: "Periapical Periodontitis",
    tree_numbers: ["C07.320.830.700", "C07.465.714.306.700", "C07.465.714.533.487"],
    synonyms: ["Apical Periodontitis"],
  },
  {
    ui: "D010488",
    heading: "Polyarteritis Nodosa",
    tree_numbers: ["C14.907.940.090.720", "C14.907.940.897.500", "C17.800.862.625"],
  },
  {
    ui: "D010489",
    heading: "Periarthritis",
    tree_numbers: ["C05.550.114.678", "C05.550.251.595"],
  },
  {
    ui: "D010490",
    heading: "Pericardial Effusion",
    tree_numbers: ["C14.280.695"],
    synonyms: ["Haemopericardium"],
  },
  {
    ui: "D010493",
    heading: "Pericarditis",
    tree_numbers: ["C14.280.720"],
  },
  {
    ui: "D010494",
    heading: "Pericarditis, Constrictive",
    tree_numbers: ["C14.280.720.595"],
    synonyms: ["Constrictive Pericarditis"],
  },
  {
    ui: "D010495",
    heading: "Pericarditis, Tuberculous",
    tree_numbers: ["C01.150.252.410.040.552.846.554.595", "C01.190.750.595", "C14.260.750.595", "C14.280.720.801"],
    synonyms: ["Tuberculous Pericarditis"],
  },
  {
    ui: "D010497",
    heading: "Pericoronitis",
    tree_numbers: ["C07.465.714.258.771"],
  },
  {
    ui: "D010501",
    heading: "Perinephritis",
    tree_numbers: ["C12.777.419.685", "C13.351.968.419.685"],
  },
  {
    ui: "D010505",
    heading: "Familial Mediterranean Fever",
    tree_numbers: ["C16.320.382.625"],
    synonyms: ["Benign Paroxysmal Peritonitis"],
  },
  {
    ui: "D010508",
    heading: "Periodontal Abscess",
    tree_numbers: ["C01.830.025.665", "C07.465.714.533.650"],
  },
  {
    ui: "D010509",
    heading: "Periodontal Cyst",
    tree_numbers: ["C04.182.089.530.690.790", "C05.500.470.690.790", "C07.320.450.670.513", "C07.465.714.470"],
  },
  {
    ui: "D010510",
    heading: "Periodontal Diseases",
    tree_numbers: ["C07.465.714"],
    synonyms: ["Disease Of Supporting Structures Of Teeth", "Periodontium Disorder", "Periodontal Disease"],
  },
  {
    ui: "D010514",
    heading: "Periodontal Pocket",
    tree_numbers: ["C07.465.714.533.750"],
  },
  {
    ui: "D010518",
    heading: "Periodontitis",
    tree_numbers: ["C07.465.714.533"],
    synonyms: ["Chronic Pericementitis"],
  },
  {
    ui: "D010520",
    heading: "Aggressive Periodontitis",
    tree_numbers: ["C07.465.714.533.161"],
    synonyms: ["Juvenile Periodontitis"],
  },
  {
    ui: "D010522",
    heading: "Periostitis",
    tree_numbers: ["C01.160.595", "C05.116.165.595"],
  },
  {
    ui: "D010523",
    heading: "Peripheral Nervous System Diseases",
    tree_numbers: ["C10.668.829"],
  },
  {
    ui: "D010524",
    heading: "Peripheral Nervous System Neoplasms",
    tree_numbers: ["C04.588.614.596", "C10.551.775", "C10.668.829.725"],
    synonyms: ["Nerve Sheath Neoplasm", "Neoplasm Of Peripheral Nerve", "Tumor Of Pns"],
  },
  {
    ui: "D010532",
    heading: "Peritoneal Diseases",
    tree_numbers: ["C06.844"],
  },
  {
    ui: "D010534",
    heading: "Peritoneal Neoplasms",
    tree_numbers: ["C04.588.033.513", "C04.588.274.780", "C06.301.780", "C06.844.620"],
  },
  {
    ui: "D010538",
    heading: "Peritonitis",
    tree_numbers: ["C01.463.600", "C06.844.640"],
  },
  {
    ui: "D010547",
    heading: "Persistent Fetal Circulation Syndrome",
    tree_numbers: ["C08.381.423.694", "C16.614.694"],
    synonyms: [
      "Persistent Fetal Circulation Syndrome",
      "Fetal Circulation",
      "Persistent Foetal Circulation",
      "Persistent Foetal Circulation Syndrome",
      "Congenital Alveolar Capillary Dysplasia With Misalignment Of Pulmonary Veins",
      "Persistent Foetal Circulation Syndrome",
      "Persistent Fetal Circulation",
      "Persistent Pulmonary Hypertension Of The Newborn",
      "Persistent Pulmonary Hypertension Of The Newborn",
    ],
  },
  {
    ui: "D010554",
    heading: "Personality Disorders",
    tree_numbers: ["F03.675"],
    synonyms: ["Anxious Personality Disorder"],
  },
  {
    ui: "D010580",
    heading: "Peutz-Jeghers Syndrome",
    tree_numbers: ["C04.700.633", "C06.405.469.578.750", "C16.320.700.667", "C17.800.621.430.530.550.625"],
    synonyms: [
      "Colonic Hamartomatous Polyp",
      "Peutz-Jeghers Polyp Of Small Intestine",
      "Gastric Peutz-Jeghers Polyp",
      "Peutz Jeghers Polyp",
      "Peutz-Jeghers Small Bowel Hamartoma",
      "Peutz Jeghers Colon Polyp",
    ],
  },
  {
    ui: "D010585",
    heading: "Phagocyte Bactericidal Dysfunction",
    tree_numbers: ["C15.378.553.774", "C20.673.774"],
    synonyms: ["Phagocytic Dysfunction"],
  },
  {
    ui: "D010591",
    heading: "Phantom Limb",
    tree_numbers: [
      "C10.597.606.762.700",
      "C23.550.767.700.500",
      "C23.888.592.604.764.700",
      "C23.888.592.612.832.500",
      "F01.700.750.700",
    ],
  },
  {
    ui: "D010608",
    heading: "Pharyngeal Diseases",
    tree_numbers: ["C07.550", "C09.775"],
    synonyms: [
      "Acute Sore Throat",
      "Pharyngeal Disorder",
      "Persistent Sore Throat",
      "Chronic Pharyn/nasopharyngitis",
      "Chronic Pharyngitis",
      "Sore Throat - Chronic",
      "Pharyngitis - Acute",
      "Chronic Sore Throat",
      "Chronic Pharyngitis And Nasopharyngitis",
      "Acute Pharyngitis",
      "Inflamed Throat",
    ],
  },
  {
    ui: "D010610",
    heading: "Pharyngeal Neoplasms",
    tree_numbers: ["C04.588.443.665.710", "C07.550.745", "C09.647.710", "C09.775.549"],
    synonyms: ["Pharynx Neoplasm", "Pharyngeal Neoplasm"],
  },
  {
    ui: "D010612",
    heading: "Pharyngitis",
    tree_numbers: ["C01.748.561", "C07.550.781", "C08.730.561", "C09.775.649"],
    synonyms: [
      "Acute Sore Throat",
      "Pharyngeal Disorder",
      "Persistent Sore Throat",
      "Chronic Pharyn/nasopharyngitis",
      "Chronic Pharyngitis",
      "Sore Throat - Chronic",
      "Pharyngitis - Acute",
      "Chronic Sore Throat",
      "Chronic Pharyngitis And Nasopharyngitis",
      "Acute Pharyngitis",
      "Inflamed Throat",
    ],
  },
  {
    ui: "D010623",
    heading: "Phencyclidine Abuse",
    tree_numbers: ["C25.775.700", "F03.900.700"],
    synonyms: ["Phencyclidine Abuse", "Pcp Abuse"],
  },
  {
    ui: "D010661",
    heading: "Phenylketonurias",
    tree_numbers: [
      "C10.228.140.163.100.687",
      "C16.320.565.100.766",
      "C16.320.565.189.687",
      "C18.452.132.100.687",
      "C18.452.648.100.766",
      "C18.452.648.189.687",
    ],
    synonyms: ["Phenylalaninemia", "Folling's Disease", "Pku", "Maternal Phenylketonuria"],
  },
  {
    ui: "D010673",
    heading: "Pheochromocytoma",
    tree_numbers: ["C04.557.465.625.650.700.725", "C04.557.580.625.650.700.725"],
    synonyms: ["Phaeochromocytoma"],
  },
  {
    ui: "D010677",
    heading: "Philadelphia Chromosome",
    tree_numbers: [
      "A11.284.187.520.300.325.345.500",
      "A11.284.187.520.300.505.515.500",
      "C23.550.210.870.680",
      "G05.360.162.520.300.325.345.700",
      "G05.360.162.520.300.505.515.700",
      "G05.365.590.175.870.680",
    ],
  },
  {
    ui: "D010688",
    heading: "Phimosis",
    tree_numbers: ["C12.294.494.684"],
    synonyms: ["Tight Foreskin", "Tight Frenulum"],
  },
  {
    ui: "D010689",
    heading: "Phlebitis",
    tree_numbers: ["C14.907.617.718", "C14.907.940.740"],
  },
  {
    ui: "D010698",
    heading: "Phobic Disorders",
    tree_numbers: ["F03.080.725"],
  },
  {
    ui: "D010760",
    heading: "Phosphorus Metabolism Disorders",
    tree_numbers: ["C18.452.750"],
    synonyms: ["Phosphorus Metabolism Disorder", "Disorder Of Phosphorus Metabolism", "Phosphorus Disorder"],
  },
  {
    ui: "D010787",
    heading: "Photosensitivity Disorders",
    tree_numbers: ["C17.800.600"],
    synonyms: ["Photodermatitis"],
  },
  {
    ui: "D010842",
    heading: "Pica",
    tree_numbers: ["C23.888.821.825", "F03.400.875"],
  },
  {
    ui: "D010845",
    heading: "Obesity Hypoventilation Syndrome",
    tree_numbers: [
      "C08.618.085.852.850.500",
      "C08.618.846.565.500",
      "C10.886.425.800.750.850.500",
      "C18.654.726.500.600",
    ],
  },
  {
    ui: "D010850",
    heading: "Picornaviridae Infections",
    tree_numbers: ["C01.925.782.687"],
  },
  {
    ui: "D010854",
    heading: "Piedra",
    tree_numbers: ["C01.150.703.753", "C17.800.329.992"],
    synonyms: ["Piedra", "Steroid-Modified Tinea Infection"],
  },
  {
    ui: "D010855",
    heading: "Pierre Robin Syndrome",
    tree_numbers: [
      "C05.500.460.606",
      "C05.660.207.540.460.606",
      "C07.320.440.606",
      "C07.650.500.460.606",
      "C16.131.621.207.540.460.606",
      "C16.131.850.500.460.606",
    ],
    synonyms: ["Pierre Robin Malformation", "Piere-Robin Syndrome"],
  },
  {
    ui: "D010859",
    heading: "Pigmentation Disorders",
    tree_numbers: ["C17.800.621", "C23.550.755"],
    synonyms: [
      "Nevus Of Ito",
      "Incontinentia Pigmenti Achromians",
      "Ito's Nevus",
      "Bloch-Siemans Syndrome",
      "Incontinentia Pigmenti Achromians Syndrome",
    ],
  },
  {
    ui: "D010864",
    heading: "Pilonidal Sinus",
    tree_numbers: ["C04.182.710"],
  },
  {
    ui: "D010871",
    heading: "Pinealoma",
    tree_numbers: [
      "C04.557.465.625.600.657",
      "C04.557.470.670.657",
      "C04.557.580.625.600.657",
      "C04.588.614.250.195.766",
      "C10.228.140.211.788",
      "C10.551.240.250.625",
    ],
    synonyms: ["Pineal Pnet"],
  },
  {
    ui: "D010874",
    heading: "Pinta",
    tree_numbers: [
      "C01.150.252.400.794.840.250",
      "C01.150.252.400.840.250",
      "C01.150.252.819.630",
      "C01.800.720.630",
      "C17.800.838.765.630",
    ],
  },
  {
    ui: "D010899",
    heading: "Pituitary Apoplexy",
    tree_numbers: ["C10.228.140.300.535.625", "C10.228.140.617.738.350", "C14.907.253.573.600", "C19.700.725"],
    synonyms: ["Pituitary Apoplexy"],
  },
  {
    ui: "D010900",
    heading: "Pituitary Diseases",
    tree_numbers: ["C10.228.140.617.738", "C19.700"],
  },
  {
    ui: "D010911",
    heading: "Pituitary Neoplasms",
    tree_numbers: [
      "C04.588.322.609",
      "C04.588.614.250.195.885.500.600",
      "C10.228.140.211.885.500.600",
      "C10.228.140.617.477.600",
      "C10.228.140.617.738.675",
      "C10.551.240.250.700.500.500",
      "C19.344.609",
      "C19.700.734",
    ],
    synonyms: [
      "Neoplasm Of Pituitary Gland",
      "Malignant Pituitary Neoplasm",
      "Pituitary Neoplasm",
      "Pituitary Gland Cancer",
      "Malignant Tumor Of Pituitary Gland",
      "Pituitary Gland Neoplasm",
    ],
  },
  {
    ui: "D010915",
    heading: "Pityriasis",
    tree_numbers: ["C17.800.859.600"],
  },
  {
    ui: "D010916",
    heading: "Pityriasis Rubra Pilaris",
    tree_numbers: ["C17.800.859.600.685"],
    synonyms: ["Devergie's Disease", "Pityriasis Rubra Pilaris"],
  },
  {
    ui: "D010921",
    heading: "Placenta Accreta",
    tree_numbers: ["C13.703.420.643", "C13.703.590.609"],
  },
  {
    ui: "D010922",
    heading: "Placenta Diseases",
    tree_numbers: ["C13.703.590"],
  },
  {
    ui: "D010923",
    heading: "Placenta Previa",
    tree_numbers: ["C13.703.420.714", "C13.703.590.734"],
  },
  {
    ui: "D010927",
    heading: "Placental Insufficiency",
    tree_numbers: ["C13.703.590.800"],
  },
  {
    ui: "D010930",
    heading: "Plague",
    tree_numbers: ["C01.150.252.400.310.980.390", "C01.920.906"],
    synonyms: ["Black Death"],
  },
  {
    ui: "D010939",
    heading: "Plant Poisoning",
    tree_numbers: ["C25.723.756"],
  },
  {
    ui: "D010954",
    heading: "Plasmacytoma",
    tree_numbers: ["C04.557.595.600", "C20.683.515.880"],
    synonyms: ["Solitary Plasmacytoma", "Solitary Myeloma", "Myeloma, Solitary", "Myeloma - Solitary"],
  },
  {
    ui: "D010981",
    heading: "Platelet Storage Pool Deficiency",
    tree_numbers: ["C15.378.100.685", "C15.378.140.735", "C15.378.463.735"],
    synonyms: ["Platelet Storage Pool Defect", "Platelet Dense Granule Deficiency", "Dense Body Defect"],
  },
  {
    ui: "D010985",
    heading: "Platybasia",
    tree_numbers: ["C05.116.099.742", "C05.116.900.540", "C05.660.207.720", "C16.131.621.207.720"],
  },
  {
    ui: "D010995",
    heading: "Pleural Diseases",
    tree_numbers: ["C08.528"],
    synonyms: ["Non-Neoplastic Pleural Disease", "Disorder Of Pleura"],
  },
  {
    ui: "D010996",
    heading: "Pleural Effusion",
    tree_numbers: ["C08.528.652"],
  },
  {
    ui: "D010997",
    heading: "Pleural Neoplasms",
    tree_numbers: ["C04.588.894.797.640", "C08.528.694", "C08.785.640"],
    synonyms: ["Pleural Tumor", "Neoplasm Of Pleura"],
  },
  {
    ui: "D010998",
    heading: "Pleurisy",
    tree_numbers: ["C01.748.582", "C08.528.735", "C08.730.582"],
  },
  {
    ui: "D011000",
    heading: "Pleurodynia, Epidemic",
    tree_numbers: ["C01.925.782.687.359.213.737"],
    synonyms: [
      "Epidemic Myalgia",
      "Devil's Grip",
      "Bornholm Disease",
      "Epidemic Pleurisy",
      "Bamble Disease",
      "Epidemic, Myositis",
    ],
  },
  {
    ui: "D011001",
    heading: "Pleuropneumonia",
    tree_numbers: [
      "C01.748.582.473",
      "C01.748.610.473",
      "C08.381.677.473",
      "C08.528.735.473",
      "C08.730.582.473",
      "C08.730.610.473",
    ],
  },
  {
    ui: "D011002",
    heading: "Pleuropneumonia, Contagious",
    tree_numbers: ["C01.150.252.400.610.610.717", "C22.717"],
  },
  {
    ui: "D011004",
    heading: "Plummer-Vinson Syndrome",
    tree_numbers: ["C06.405.117.119.500.742"],
  },
  {
    ui: "D011006",
    heading: "Pneumatosis Cystoides Intestinalis",
    tree_numbers: ["C06.405.469.778"],
    synonyms: ["Pneumatosis Cystoides Intestinalis"],
  },
  {
    ui: "D011007",
    heading: "Pneumocephalus",
    tree_numbers: ["C10.228.140.199.700", "C10.228.806", "C10.900.300.087.700", "C26.915.300.200.650"],
  },
  {
    ui: "D011008",
    heading: "Pneumococcal Infections",
    tree_numbers: ["C01.150.252.410.890.670"],
  },
  {
    ui: "D011009",
    heading: "Pneumoconiosis",
    tree_numbers: ["C08.381.483.581", "C08.381.520.702", "C24.800"],
    synonyms: ["Sugar Cane Worker Pneumonitis"],
  },
  {
    ui: "D011014",
    heading: "Pneumonia",
    tree_numbers: ["C01.748.610", "C08.381.677", "C08.730.610"],
    synonyms: ["Acute Pneumonia"],
  },
  {
    ui: "D011015",
    heading: "Pneumonia, Aspiration",
    tree_numbers: ["C01.748.610.529", "C08.381.677.529", "C08.730.610.529"],
    synonyms: ["Chemical Pneumonitis", "Mendelson's Syndrome"],
  },
  {
    ui: "D011016",
    heading: "Pneumonia, Atypical Interstitial, of Cattle",
    tree_numbers: ["C01.748.085.660", "C08.730.085.660", "C22.196.090.660"],
  },
  {
    ui: "D011017",
    heading: "Pneumonia, Lipid",
    tree_numbers: ["C01.748.610.529.612", "C08.381.677.529.612", "C08.730.610.529.612"],
    synonyms: ["Lipoid Pneumonitis", "Exogenous Lipoid Pneumonia"],
  },
  {
    ui: "D011018",
    heading: "Pneumonia, Pneumococcal",
    tree_numbers: [
      "C01.150.252.410.890.670.750",
      "C01.150.252.620.550",
      "C01.748.610.540.550",
      "C08.381.677.540.550",
      "C08.730.610.540.550",
    ],
  },
  {
    ui: "D011019",
    heading: "Pneumonia, Mycoplasma",
    tree_numbers: [
      "C01.150.252.400.610.610.760",
      "C01.150.252.620.500",
      "C01.748.610.540.545",
      "C08.381.677.540.500",
      "C08.730.610.540.545",
    ],
    synonyms: [
      "Pneumonia Due To Eaton's Agent",
      "Mycoplasma Pneumonia",
      "Mycoplasmal Pneumonia",
      "Cold Agglutinin Positive Pneumonia",
      "Pneumonia Due To Mycoplasma Pneumoniae (Disorder)",
      "Pneumonia Due To Mycoplasma Pneumoniae",
    ],
  },
  {
    ui: "D011020",
    heading: "Pneumonia, Pneumocystis",
    tree_numbers: [
      "C01.150.703.534.700",
      "C01.150.703.770.700",
      "C01.748.435.700",
      "C01.748.610.675",
      "C08.381.472.700",
      "C08.381.677.675",
      "C08.730.435.700",
      "C08.730.610.675",
    ],
    synonyms: [
      "Pneumocystis Pneumonia",
      "Pneumocystis Jirovecii Pneumonia",
      "Pneumocystosis Pneumonia",
      "Pulmonary Pneumocystosis",
      "Pneumocystosis",
      "Pneumocystis Carinii Pneumonia",
    ],
  },
  {
    ui: "D011021",
    heading: "Pneumonia, Progressive Interstitial, of Sheep",
    tree_numbers: ["C01.925.782.815.616.660", "C01.925.839.660", "C22.836.660"],
  },
  {
    ui: "D011022",
    heading: "Pneumonia, Rickettsial",
    tree_numbers: [
      "C01.150.252.400.789.600",
      "C01.748.610.540.600",
      "C01.920.914.600",
      "C08.381.677.540.600",
      "C08.730.610.540.600",
    ],
  },
  {
    ui: "D011023",
    heading: "Pneumonia, Staphylococcal",
    tree_numbers: [
      "C01.150.252.410.868.675",
      "C01.150.252.620.620",
      "C01.748.610.540.620",
      "C08.381.677.540.620",
      "C08.730.610.540.620",
    ],
  },
  {
    ui: "D011024",
    heading: "Pneumonia, Viral",
    tree_numbers: ["C01.748.610.763", "C01.925.705", "C08.381.677.807", "C08.730.610.763"],
  },
  {
    ui: "D011026",
    heading: "Pneumopericardium",
    tree_numbers: ["C14.280.763"],
  },
  {
    ui: "D011027",
    heading: "Pneumoperitoneum",
    tree_numbers: ["C06.844.670"],
  },
  {
    ui: "D011030",
    heading: "Pneumothorax",
    tree_numbers: ["C08.528.778"],
  },
  {
    ui: "D011038",
    heading: "Rothmund-Thomson Syndrome",
    tree_numbers: [
      "C16.131.831.775",
      "C16.320.850.765",
      "C16.614.760",
      "C17.800.804.775",
      "C17.800.827.775",
      "C18.452.284.760",
    ],
    synonyms: ["Congenital Poikiloderma", "Rts"],
  },
  {
    ui: "D011040",
    heading: "Dermatitis, Toxicodendron",
    tree_numbers: ["C17.800.174.255.100.700", "C17.800.815.255.100.700", "C20.543.418.150.700"],
    synonyms: ["Rhus Dermatitis", "Contact Dermatitis Due To Genus Toxicodendron"],
  },
  {
    ui: "D011041",
    heading: "Poisoning",
    tree_numbers: ["C25.723"],
  },
  {
    ui: "D011045",
    heading: "Poland Syndrome",
    tree_numbers: [
      "C05.116.099.370.894.819.756",
      "C05.660.585.800.756",
      "C05.660.906.819.756",
      "C16.131.621.585.800.756",
      "C16.131.621.906.819.756",
    ],
    synonyms: ["Poland's Syndactyly"],
  },
  {
    ui: "D011051",
    heading: "Poliomyelitis",
    tree_numbers: [
      "C01.207.618.750",
      "C01.925.782.687.359.764",
      "C10.228.228.618.750",
      "C10.228.854.525.850",
      "C10.668.864",
    ],
  },
  {
    ui: "D011052",
    heading: "Poliomyelitis, Bulbar",
    tree_numbers: [
      "C01.207.618.750.500",
      "C01.925.782.687.359.764.614",
      "C10.228.228.618.750.500",
      "C10.228.854.525.850.500",
      "C10.668.864.250",
    ],
    synonyms: ["Bulbar Poliomyelitis"],
  },
  {
    ui: "D011081",
    heading: "Polychondritis, Relapsing",
    tree_numbers: ["C05.182.531", "C17.300.182.531"],
    synonyms: ["Chondromalacia, Systemic"],
  },
  {
    ui: "D011085",
    heading: "Polycystic Ovary Syndrome",
    tree_numbers: ["C04.182.612.765", "C13.351.500.056.630.580.765", "C19.391.630.580.765"],
    synonyms: [
      "Polycystic Ovary",
      "Multicystic Ovaries",
      "Stein-Leventhal Syndrome",
      "Polycystic Ovaries",
      "Polycystic Ovarian Disease",
      "Pcos",
      "Stein-Leventhal Synd.",
      "Polycystic Ovary Syndrome",
    ],
  },
  {
    ui: "D011086",
    heading: "Polycythemia",
    tree_numbers: ["C15.378.738"],
    synonyms: ["Erythrocythemia"],
  },
  {
    ui: "D011087",
    heading: "Polycythemia Vera",
    tree_numbers: ["C04.588.448.200.500", "C15.378.190.250.500", "C15.378.190.636.753", "C15.378.400.200.500"],
    synonyms: [
      "Polycythaemia Rubra Vera",
      "Osler-Vaquez Syndrome",
      "Chronic Erythremia",
      "Proliferative Polycythaemia",
    ],
  },
  {
    ui: "D011111",
    heading: "Polymyalgia Rheumatica",
    tree_numbers: ["C05.651.742", "C05.799.720", "C17.300.775.720"],
    synonyms: ["Polymyalgia Rheumatica"],
  },
  {
    ui: "D011115",
    heading: "Polyneuropathies",
    tree_numbers: ["C10.668.829.800"],
  },
  {
    ui: "D011123",
    heading: "Polyploidy",
    tree_numbers: ["C23.550.210.702", "G05.365.590.175.677", "G05.700.740"],
  },
  {
    ui: "D011125",
    heading: "Adenomatous Polyposis Coli",
    tree_numbers: [
      "C04.557.470.035.215.100",
      "C04.588.274.476.411.307.089",
      "C04.700.100",
      "C06.301.371.411.307.090",
      "C06.405.249.411.307.090",
      "C06.405.469.158.356.090",
      "C06.405.469.491.307.090",
      "C06.405.469.578.249",
      "C16.320.700.100",
    ],
    synonyms: ["Adenomatous Polyposis Of The Colon"],
  },
  {
    ui: "D011127",
    heading: "Polyps",
    tree_numbers: ["C23.300.825"],
  },
  {
    ui: "D011128",
    heading: "Polyradiculopathy",
    tree_numbers: ["C10.668.829.800.750.700"],
    synonyms: ["Polyradiculopathy"],
  },
  {
    ui: "D011129",
    heading: "Polyradiculoneuropathy",
    tree_numbers: ["C10.114.750", "C10.314.750", "C10.668.829.800.750", "C20.111.258.750"],
  },
  {
    ui: "D011141",
    heading: "Polyuria",
    tree_numbers: ["C12.777.934.616", "C13.351.968.934.616", "C23.888.942.600"],
  },
  {
    ui: "D011146",
    heading: "Eczema, Dyshidrotic",
    tree_numbers: ["C17.800.174.620.300", "C17.800.815.620.300", "C17.800.865.385"],
    synonyms: ["Dyshydrotic Eczema", "Cheiropompholyx", "Dyshidrosis", "Vesicular Eczema Of Hands And/or Feet"],
  },
  {
    ui: "D011151",
    heading: "Popliteal Cyst",
    tree_numbers: ["C04.182.867.500"],
  },
  {
    ui: "D011164",
    heading: "Porphyrias",
    tree_numbers: ["C18.452.811"],
    synonyms: [
      "Hematoporphyria",
      "Disorder Of Porphyrin And Hem Metabolism",
      "Porphyrinopathy",
      "Disorder Of Porphyrin Metabolism",
    ],
  },
  {
    ui: "D011178",
    heading: "Postgastrectomy Syndromes",
    tree_numbers: ["C06.405.748.630", "C23.550.767.812"],
    synonyms: ["Postgastric Surgery Syndrome"],
  },
  {
    ui: "D011180",
    heading: "Postmortem Changes",
    tree_numbers: ["C23.550.260.224.617"],
  },
  {
    ui: "D011183",
    heading: "Postoperative Complications",
    tree_numbers: ["C23.550.767"],
  },
  {
    ui: "D011185",
    heading: "Postpericardiotomy Syndrome",
    tree_numbers: ["C14.280.793", "C23.550.767.863"],
  },
  {
    ui: "D011186",
    heading: "Postphlebitic Syndrome",
    tree_numbers: ["C14.907.617.718.760", "C14.907.952.760"],
    synonyms: [
      "Postphlebitic Syndrome",
      "Venous Stress Disorder",
      "Postphlebetic Syndrome With Inflammation",
      "Postphlebetic Syndrome With Ulcer And Inflammation",
      "Postphlebetic Syndrome With Ulcer",
    ],
  },
  {
    ui: "D011191",
    heading: "Potassium Deficiency",
    tree_numbers: ["C18.654.521.500.617"],
  },
  {
    ui: "D011201",
    heading: "Poultry Diseases",
    tree_numbers: ["C22.131.728"],
  },
  {
    ui: "D011213",
    heading: "Poxviridae Infections",
    tree_numbers: ["C01.925.256.743"],
    synonyms: ["Milkers' Node", "Paravaccinia", "Milker Nodule"],
  },
  {
    ui: "D011218",
    heading: "Prader-Willi Syndrome",
    tree_numbers: [
      "C10.597.606.360.690",
      "C16.131.077.730",
      "C16.131.260.700",
      "C16.320.180.700",
      "C18.654.726.500.740",
    ],
    synonyms: ["Prader Willi Syndrome"],
  },
  {
    ui: "D011225",
    heading: "Pre-Eclampsia",
    tree_numbers: ["C13.703.395.249"],
    synonyms: [
      "Pre-Eclamptic Toxaemia",
      "Hypertension Induced By Pregnancy",
      "Pregnancy Toxemia",
      "Preeclampsia",
      "Gestational Hypertension",
      "Pregnancy Associated Hypertension",
      "Preeclampsia/eclampsia",
      "Toxaemia Of Pregnancy",
      "Proteinuric Hypertension Of Pregnancy",
    ],
  },
  {
    ui: "D011226",
    heading: "Pre-Excitation Syndromes",
    tree_numbers: ["C14.280.067.780", "C14.280.123.750"],
  },
  {
    ui: "D011227",
    heading: "Pre-Excitation, Mahaim-Type",
    tree_numbers: ["C14.280.067.780.770", "C14.280.123.750.770"],
  },
  {
    ui: "D011230",
    heading: "Precancerous Conditions",
    tree_numbers: ["C04.834"],
  },
  {
    ui: "D011236",
    heading: "Prediabetic State",
    tree_numbers: ["C18.452.394.750.774", "C19.246.774"],
    synonyms: ["Impaired Glucose Tolerance", "Prediabetic State", "Prediabetes"],
  },
  {
    ui: "D011248",
    heading: "Pregnancy Complications",
    tree_numbers: ["C13.703"],
  },
  {
    ui: "D011249",
    heading: "Pregnancy Complications, Cardiovascular",
    tree_numbers: ["C13.703.634", "C14.583"],
  },
  {
    ui: "D011250",
    heading: "Pregnancy Complications, Hematologic",
    tree_numbers: ["C13.703.667", "C15.378.785"],
  },
  {
    ui: "D011251",
    heading: "Pregnancy Complications, Infectious",
    tree_numbers: ["C01.674", "C13.703.700"],
  },
  {
    ui: "D011252",
    heading: "Pregnancy Complications, Neoplastic",
    tree_numbers: ["C04.850", "C13.703.720"],
  },
  {
    ui: "D011254",
    heading: "Pregnancy in Diabetics",
    tree_numbers: ["C13.703.726"],
  },
  {
    ui: "D011269",
    heading: "Pregnancy, Abdominal",
    tree_numbers: ["C13.703.733.536"],
  },
  {
    ui: "D011271",
    heading: "Pregnancy, Ectopic",
    tree_numbers: ["C13.703.733"],
    synonyms: ["Eccyesis"],
  },
  {
    ui: "D011273",
    heading: "Pregnancy, Prolonged",
    tree_numbers: ["C13.703.805"],
  },
  {
    ui: "D011274",
    heading: "Pregnancy, Tubal",
    tree_numbers: ["C13.703.733.703"],
  },
  {
    ui: "D011289",
    heading: "Preleukemia",
    tree_numbers: ["C04.834.770", "C15.378.800"],
  },
  {
    ui: "D011293",
    heading: "Premenstrual Syndrome",
    tree_numbers: ["C23.550.568.968"],
  },
  {
    ui: "D011297",
    heading: "Prenatal Exposure Delayed Effects",
    tree_numbers: ["C13.703.824.500"],
  },
  {
    ui: "D011304",
    heading: "Presbycusis",
    tree_numbers: ["C09.218.458.341.887.772", "C10.597.751.418.341.887.772", "C23.888.592.763.393.341.887.772"],
  },
  {
    ui: "D011305",
    heading: "Presbyopia",
    tree_numbers: ["C11.744.786"],
  },
  {
    ui: "D011317",
    heading: "Priapism",
    tree_numbers: ["C12.294.494.786"],
    synonyms: ["Mentulagra"],
  },
  {
    ui: "D011349",
    heading: "Proctitis",
    tree_numbers: ["C06.405.205.865", "C06.405.469.860.622"],
    synonyms: [],
  },
  {
    ui: "D011350",
    heading: "Proctocolitis",
    tree_numbers: [
      "C06.405.205.265.767",
      "C06.405.205.865.790",
      "C06.405.469.158.188.767",
      "C06.405.469.158.850.790",
      "C06.405.469.860.622.790",
    ],
  },
  {
    ui: "D011371",
    heading: "Progeria",
    tree_numbers: ["C16.320.488.875", "C16.320.565.753", "C18.452.648.753"],
    synonyms: [
      "Hgps",
      "Hutchinson-Gilford Disease",
      "Hutchinson-Gilford Progeria Syndrome",
      "Hutchinson Gilford Syndrome",
    ],
  },
  {
    ui: "D011378",
    heading: "Prognathism",
    tree_numbers: [
      "C05.500.460.655",
      "C05.500.607.655",
      "C05.660.207.540.460.655",
      "C07.320.440.655",
      "C07.320.610.655",
      "C07.650.500.460.655",
      "C16.131.621.207.540.460.655",
      "C16.131.850.500.460.655",
    ],
  },
  {
    ui: "D011391",
    heading: "Prolapse",
    tree_numbers: ["C23.300.842"],
  },
  {
    ui: "D011469",
    heading: "Prostatic Diseases",
    tree_numbers: ["C12.294.565"],
  },
  {
    ui: "D011470",
    heading: "Prostatic Hyperplasia",
    tree_numbers: ["C12.294.565.500"],
    synonyms: ["Prostate Adenoma", "Benign Adenoma Of Prostate", "Adenoma - Prostate", "Adenoma Of Prostate"],
  },
  {
    ui: "D011471",
    heading: "Prostatic Neoplasms",
    tree_numbers: ["C04.588.945.440.770", "C12.294.260.750", "C12.294.565.625", "C12.758.409.750"],
    synonyms: [
      "Prostate Neoplasm",
      "Prostate Cancer, Familial",
      "Tumor Of The Prostate",
      "Malignant Tumor Of The Prostate",
      "Prostatic Cancer",
      "Hereditary Prostate Cancer",
      "Prostatic Neoplasm",
      "Ngp - New Growth Of Prostate",
    ],
  },
  {
    ui: "D011472",
    heading: "Prostatitis",
    tree_numbers: ["C12.294.565.750"],
  },
  {
    ui: "D011475",
    heading: "Prosthesis Failure",
    tree_numbers: ["C23.550.767.865", "E05.325.771"],
  },
  {
    ui: "D011488",
    heading: "Protein Deficiency",
    tree_numbers: ["C18.654.521.500.708"],
  },
  {
    ui: "D011502",
    heading: "Protein-Energy Malnutrition",
    tree_numbers: ["C18.654.521.500.708.626"],
    synonyms: ["Nutritional Atrophy", "Nutritional Marasmus"],
  },
  {
    ui: "D011504",
    heading: "Protein-Losing Enteropathies",
    tree_numbers: ["C06.405.469.818"],
    synonyms: ["Enteropathy, Exudative", "Protein-Losing Enteropathy", "Exudative Enteropathy"],
  },
  {
    ui: "D011507",
    heading: "Proteinuria",
    tree_numbers: ["C12.777.934.734", "C13.351.968.934.734", "C23.888.942.750"],
  },
  {
    ui: "D011512",
    heading: "Proteus Infections",
    tree_numbers: ["C01.150.252.400.310.708"],
  },
  {
    ui: "D011528",
    heading: "Protozoan Infections",
    tree_numbers: ["C01.610.752"],
    synonyms: ["Sarcomastigophora Infectious Disease", "Mastigophora Infectious Disease"],
  },
  {
    ui: "D011529",
    heading: "Protozoan Infections, Animal",
    tree_numbers: ["C01.610.701.688", "C01.610.752.625", "C22.674.710"],
  },
  {
    ui: "D011535",
    heading: "Prune Belly Syndrome",
    tree_numbers: ["C16.131.077.745"],
    synonyms: ["Eagle-Barret Syndrome", "Obrisnksy Syndrome", "Abdominal Muscle Deficiency Syndrome"],
  },
  {
    ui: "D011536",
    heading: "Prurigo",
    tree_numbers: ["C17.800.674"],
  },
  {
    ui: "D011537",
    heading: "Pruritus",
    tree_numbers: ["C17.800.685", "C23.888.885.625"],
  },
  {
    ui: "D011538",
    heading: "Pruritus Ani",
    tree_numbers: ["C06.405.469.860.101.752", "C17.800.685.544"],
  },
  {
    ui: "D011539",
    heading: "Pruritus Vulvae",
    tree_numbers: ["C13.351.500.944.626", "C17.800.685.710"],
  },
  {
    ui: "D011542",
    heading: "Pseudarthrosis",
    tree_numbers: ["C26.404.468.627"],
  },
  {
    ui: "D011546",
    heading: "Pseudohypoaldosteronism",
    tree_numbers: ["C12.777.419.815.770", "C13.351.968.419.815.770", "C16.320.831.770"],
    synonyms: ["Pha1b", "Autosomal Recessive Pha 1"],
  },
  {
    ui: "D011547",
    heading: "Pseudohypoparathyroidism",
    tree_numbers: [
      "C05.116.198.709",
      "C16.320.565.618.815",
      "C18.452.104.709",
      "C18.452.174.766",
      "C18.452.648.618.815",
    ],
  },
  {
    ui: "D011552",
    heading: "Pseudomonas Infections",
    tree_numbers: ["C01.150.252.400.739"],
  },
  {
    ui: "D011553",
    heading: "Pseudomyxoma Peritonei",
    tree_numbers: ["C04.557.470.200.025.075.500", "C04.557.470.590.075.500"],
    synonyms: ["Mucinous Ascites", "Pseudomyxoma Peritonei"],
  },
  {
    ui: "D011556",
    heading: "Pseudopseudohypoparathyroidism",
    tree_numbers: [
      "C05.116.198.709.628",
      "C16.320.565.618.815.815",
      "C18.452.104.709.628",
      "C18.452.174.766.815",
      "C18.452.648.618.815.815",
    ],
    synonyms: ["Pseudopseudohypoparathyroidism", "Normocalcemic Pseudohypoparathyroidism"],
  },
  {
    ui: "D011557",
    heading: "Pseudorabies",
    tree_numbers: ["C01.207.245.710", "C01.925.182.710", "C01.925.256.466.793", "C10.228.228.245.710", "C22.742"],
  },
  {
    ui: "D011559",
    heading: "Pseudotumor Cerebri",
    tree_numbers: ["C10.228.140.631.750"],
    synonyms: [
      "Benign Intracran. Hypt.",
      "Idiopathic Intracranial Hypertension",
      "Benign Intracranial Hypertension",
      "Pseudotumor Cerebri",
    ],
  },
  {
    ui: "D011561",
    heading: "Pseudoxanthoma Elasticum",
    tree_numbers: [
      "C14.907.454.530",
      "C15.378.463.515.530",
      "C16.131.831.766",
      "C16.320.850.750",
      "C17.300.766",
      "C17.800.804.766",
      "C17.800.827.750",
    ],
    synonyms: ["Gronblad-Strandberg Syndrome"],
  },
  {
    ui: "D011565",
    heading: "Psoriasis",
    tree_numbers: ["C17.800.859.675"],
    synonyms: ["Acropustulosis", "Pustular Psoriasis Of The Palms And/or Soles", "Palmoplantar Pustulosis"],
  },
  {
    ui: "D011595",
    heading: "Psychomotor Agitation",
    tree_numbers: [
      "C10.597.350.600",
      "C10.597.606.881.700",
      "C23.888.592.350.600",
      "C23.888.592.604.882.700",
      "F01.700.875.700",
    ],
  },
  {
    ui: "D011596",
    heading: "Psychomotor Disorders",
    tree_numbers: ["C10.597.606.881", "C23.888.592.604.882", "F01.700.875"],
  },
  {
    ui: "D011602",
    heading: "Psychophysiologic Disorders",
    tree_numbers: ["C23.888.592.700"],
  },
  {
    ui: "D011604",
    heading: "Psychoses, Alcoholic",
    tree_numbers: ["C25.723.809.750", "C25.775.100.087.750", "F03.700.675.600.750", "F03.900.100.750"],
    synonyms: ["Alcoholic Psychoses"],
  },
  {
    ui: "D011605",
    heading: "Psychoses, Substance-Induced",
    tree_numbers: ["C25.723.809", "C25.775.746", "F03.700.675.600", "F03.900.746"],
    synonyms: ["Drug-Induced Psychotic Disorder", "Drug-Induced Psychosis"],
  },
  {
    ui: "D011618",
    heading: "Psychotic Disorders",
    tree_numbers: ["F03.700.675"],
  },
  {
    ui: "D011625",
    heading: "Pterygium",
    tree_numbers: ["C11.187.781"],
  },
  {
    ui: "D011628",
    heading: "Puberty, Delayed",
    tree_numbers: ["C19.391.690"],
  },
  {
    ui: "D011629",
    heading: "Puberty, Precocious",
    tree_numbers: ["C19.391.693"],
    synonyms: ["Gonadotropin-Dependant Precocious Puberty", "Cpp"],
  },
  {
    ui: "D011644",
    heading: "Puerperal Disorders",
    tree_numbers: ["C13.703.844"],
  },
  {
    ui: "D011645",
    heading: "Puerperal Infection",
    tree_numbers: ["C01.674.715", "C13.703.700.715", "C13.703.844.757"],
  },
  {
    ui: "D011648",
    heading: "Pulmonary Adenomatosis, Ovine",
    tree_numbers: ["C01.925.782.815.725", "C01.925.928.740", "C04.557.470.200.025.715", "C22.836.715"],
  },
  {
    ui: "D011649",
    heading: "Pulmonary Alveolar Proteinosis",
    tree_numbers: ["C08.381.719"],
  },
  {
    ui: "D011654",
    heading: "Pulmonary Edema",
    tree_numbers: ["C08.381.742"],
  },
  {
    ui: "D011655",
    heading: "Pulmonary Embolism",
    tree_numbers: ["C08.381.746", "C14.907.355.350.700"],
    synonyms: ["Pulmonary Artery Embolism", "Pulmonary Embolus"],
  },
  {
    ui: "D011656",
    heading: "Pulmonary Emphysema",
    tree_numbers: ["C08.381.495.389.750"],
  },
  {
    ui: "D011657",
    heading: "Pulmonary Eosinophilia",
    tree_numbers: ["C08.381.750", "C15.378.553.231.549.750"],
    synonyms: ["Loffler's Syndrome", "Loeffler's Pneumonia"],
  },
  {
    ui: "D011658",
    heading: "Pulmonary Fibrosis",
    tree_numbers: ["C08.381.765"],
    synonyms: ["Fibrosis Of Lung"],
  },
  {
    ui: "D011660",
    heading: "Pulmonary Heart Disease",
    tree_numbers: ["C14.280.832"],
    synonyms: ["Cardiopulmonary Disease", "Pulmonary Heart Disease"],
  },
  {
    ui: "D011662",
    heading: "Pulmonary Subvalvular Stenosis",
    tree_numbers: ["C14.280.484.716.762", "C14.280.955.750.762"],
    synonyms: [
      "Subvalvular Pulmonic Stenosis",
      "Congenital Infundibular Stenosis",
      "Pulmonary Infundibular Stenosis",
      "Infundibular Pulmonic Stenosis, Congenital",
      "Infundibular Pulmonic Stenosis",
    ],
  },
  {
    ui: "D011665",
    heading: "Pulmonary Valve Insufficiency",
    tree_numbers: ["C14.280.484.660"],
    synonyms: [
      "Pulmonary Incompetence, Non-Rheumatic",
      "Pulmonary Incompetence",
      "Pulmonic Valve Regurgitation",
      "Pulmonary Regurgitation",
      "Pulmonary Insufficiency Following Trauma And Surgery",
      "Pulmonary Regurg.",
      "Pulmonic Insufficiency",
    ],
  },
  {
    ui: "D011666",
    heading: "Pulmonary Valve Stenosis",
    tree_numbers: ["C14.280.484.716", "C14.280.955.750"],
  },
  {
    ui: "D011668",
    heading: "Pulmonary Veno-Occlusive Disease",
    tree_numbers: ["C08.381.780", "C14.907.690"],
    synonyms: ["Pulmonary Veno-Occlusive Disease"],
  },
  {
    ui: "D011671",
    heading: "Pulpitis",
    tree_numbers: ["C07.793.237.820"],
  },
  {
    ui: "D011681",
    heading: "Pupil Disorders",
    tree_numbers: ["C10.597.690", "C11.710", "C23.888.592.708"],
    synonyms: ["Pupillary Disorder"],
  },
  {
    ui: "D011686",
    heading: "Purine-Pyrimidine Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.798", "C18.452.648.798"],
  },
  {
    ui: "D011693",
    heading: "Purpura",
    tree_numbers: ["C15.378.100.802", "C23.550.414.950", "C23.888.885.687"],
    synonyms: ["Purpuric Disorder"],
  },
  {
    ui: "D011694",
    heading: "Purpura, Hyperglobulinemic",
    tree_numbers: [
      "C14.907.454.550",
      "C15.378.100.802.250",
      "C15.378.463.515.550",
      "C23.550.414.950.250",
      "C23.888.885.687.250",
    ],
  },
  {
    ui: "D011695",
    heading: "Purpura, Schoenlein-Henoch",
    tree_numbers: [
      "C14.907.940.777",
      "C15.378.100.802.375",
      "C15.378.463.515.580",
      "C20.543.520.600",
      "C23.550.414.950.375",
      "C23.888.885.687.375",
    ],
    synonyms: [
      "Allergic Purpura",
      "Henoch-Sch?nlein Purpura",
      "Henoch-Schonlein Purpura",
      "Henoch-Scholein Purpura",
      "Purpura, Autoimmune",
      "Autoimmune Purpura",
      "Henoch-Sch@nlein Purpura",
    ],
  },
  {
    ui: "D011696",
    heading: "Purpura, Thrombocytopenic",
    tree_numbers: [
      "C15.378.100.802.687",
      "C15.378.140.855.925.750",
      "C20.841",
      "C23.550.414.950.687",
      "C23.888.885.687.687",
    ],
  },
  {
    ui: "D011697",
    heading: "Purpura, Thrombotic Thrombocytopenic",
    tree_numbers: [
      "C15.378.100.802.687.680",
      "C15.378.140.855.925.750.680",
      "C15.378.925.850",
      "C23.550.414.950.687.680",
      "C23.888.885.687.687.680",
    ],
    synonyms: ["Moschcowitz's Syndrome"],
  },
  {
    ui: "D011702",
    heading: "Pyelitis",
    tree_numbers: ["C12.777.419.570.821", "C13.351.968.419.570.821"],
  },
  {
    ui: "D011704",
    heading: "Pyelonephritis",
    tree_numbers: [
      "C12.777.419.570.643.790",
      "C12.777.419.570.821.717",
      "C13.351.968.419.570.643.790",
      "C13.351.968.419.570.821.717",
    ],
  },
  {
    ui: "D011705",
    heading: "Pyelonephritis, Xanthogranulomatous",
    tree_numbers: [
      "C12.777.419.570.643.790.810",
      "C12.777.419.570.821.717.810",
      "C13.351.968.419.570.643.790.810",
      "C13.351.968.419.570.821.717.810",
    ],
    synonyms: ["Xanthogranulomatous Pyelonephritis"],
  },
  {
    ui: "D011707",
    heading: "Pyloric Stenosis",
    tree_numbers: ["C06.405.748.340.690"],
  },
  {
    ui: "D011711",
    heading: "Pyoderma",
    tree_numbers: ["C17.800.695"],
  },
  {
    ui: "D011776",
    heading: "Pyuria",
    tree_numbers: ["C01.915.719", "C12.777.892.719", "C13.351.968.892.719"],
    synonyms: ["Pus Cells In Urine"],
  },
  {
    ui: "D011778",
    heading: "Q Fever",
    tree_numbers: ["C01.150.252.400.755"],
    synonyms: ["Infection Due To Coxiella Burnetii"],
  },
  {
    ui: "D011782",
    heading: "Quadriplegia",
    tree_numbers: ["C10.597.622.760", "C23.888.592.636.786"],
    synonyms: ["Tetraplegia"],
  },
  {
    ui: "D011818",
    heading: "Rabies",
    tree_numbers: ["C01.925.782.580.830.750"],
    synonyms: ["Lyssa"],
  },
  {
    ui: "D011832",
    heading: "Radiation Injuries",
    tree_numbers: ["C26.733", "G01.750.748.500", "N06.850.460.350.850.500", "N06.850.810.300.360"],
  },
  {
    ui: "D011833",
    heading: "Radiation Injuries, Experimental",
    tree_numbers: [
      "C26.733.720",
      "E05.598.500.750",
      "G01.750.748.500.720",
      "N06.850.460.350.850.500.285",
      "N06.850.810.300.360.285",
    ],
  },
  {
    ui: "D011842",
    heading: "Radicular Cyst",
    tree_numbers: [
      "C04.182.089.530.690.790.820",
      "C05.500.470.690.790.820",
      "C07.320.450.670.513.811",
      "C07.320.830.820",
      "C07.465.714.306.820",
    ],
  },
  {
    ui: "D011843",
    heading: "Radiculopathy",
    tree_numbers: ["C10.668.829.820"],
    synonyms: ["Pinched Nerve"],
  },
  {
    ui: "D011855",
    heading: "Radiodermatitis",
    tree_numbers: ["C17.800.174.826", "C26.733.804", "G01.750.748.500.804"],
  },
  {
    ui: "D011885",
    heading: "Radius Fractures",
    tree_numbers: ["C26.088.268.556", "C26.404.562"],
  },
  {
    ui: "D011900",
    heading: "Ranula",
    tree_numbers: ["C04.182.766", "C07.465.780"],
  },
  {
    ui: "D011906",
    heading: "Rat-Bite Fever",
    tree_numbers: ["C01.150.252.400.771"],
    synonyms: ["Spirillary Fever"],
  },
  {
    ui: "D011928",
    heading: "Raynaud Disease",
    tree_numbers: ["C14.907.617.812"],
    synonyms: ["Raynaud's Syndrome", "Raynaud's Disease"],
  },
  {
    ui: "D012001",
    heading: "Hyperacusis",
    tree_numbers: ["C09.218.458.505", "C10.597.751.418.505", "C23.888.592.763.393.505"],
  },
  {
    ui: "D012002",
    heading: "Rectal Diseases",
    tree_numbers: ["C06.405.469.860"],
  },
  {
    ui: "D012003",
    heading: "Rectal Fistula",
    tree_numbers: ["C06.267.550.600", "C06.405.469.471.600", "C06.405.469.860.752", "C23.300.575.185.550.600"],
  },
  {
    ui: "D012004",
    heading: "Rectal Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.307.790",
      "C06.301.371.411.307.790",
      "C06.405.249.411.307.790",
      "C06.405.469.491.307.790",
      "C06.405.469.860.180.500",
    ],
    synonyms: ["Rectal Tumor", "Neoplasm Of Rectum", "Rectum Neoplasm"],
  },
  {
    ui: "D012005",
    heading: "Rectal Prolapse",
    tree_numbers: ["C06.405.469.860.800", "C23.300.842.624.500"],
    synonyms: ["Procidentia, Rectum"],
  },
  {
    ui: "D012006",
    heading: "Rectovaginal Fistula",
    tree_numbers: [
      "C06.267.550.600.650",
      "C06.405.469.471.600.650",
      "C06.405.469.860.752.650",
      "C13.351.500.894.767.249",
      "C23.300.575.185.550.600.650",
      "C23.300.575.925.558",
    ],
  },
  {
    ui: "D012008",
    heading: "Recurrence",
    tree_numbers: ["C23.550.291.937"],
  },
  {
    ui: "D012010",
    heading: "Red-Cell Aplasia, Pure",
    tree_numbers: ["C15.378.071.750"],
    synonyms: ["Primary Red Cell Aplasia", "Red Cell Hypoplasia", "Pure Red Cell Aplasia", "Pure Red Cell Aplasia"],
  },
  {
    ui: "D012019",
    heading: "Reflex Sympathetic Dystrophy",
    tree_numbers: ["C10.177.195.800", "C10.668.829.250.800"],
  },
  {
    ui: "D012021",
    heading: "Reflex, Abnormal",
    tree_numbers: [
      "C10.597.704",
      "C23.888.592.717",
      "E01.370.376.550.650.655",
      "E01.370.600.550.650.655",
      "G11.561.731.587",
    ],
  },
  {
    ui: "D012030",
    heading: "Refractive Errors",
    tree_numbers: ["C11.744"],
  },
  {
    ui: "D012035",
    heading: "Refsum Disease",
    tree_numbers: [
      "C10.228.140.163.100.813",
      "C10.500.300.780",
      "C10.574.500.495.780",
      "C10.668.829.800.300.780",
      "C16.131.666.300.780",
      "C16.320.400.375.780",
      "C16.320.565.189.813",
      "C16.320.565.663.760",
      "C18.452.132.100.813",
      "C18.452.648.189.813",
      "C18.452.648.663.760",
    ],
    synonyms: ["Hsmn Iv", "Refsum's Disease", "Heredopathia Atactica Polyneuritiformis", "Hmsn Type Iv"],
  },
  {
    ui: "D012061",
    heading: "Relapsing Fever",
    tree_numbers: ["C01.150.252.400.794.352.500", "C01.920.930.775"],
    synonyms: ["Louse-Borne Relapsing Fever", "Relapsing Fever, Louse-Borne"],
  },
  {
    ui: "D012075",
    heading: "Remission, Spontaneous",
    tree_numbers: ["C23.550.291.656.700", "G16.767"],
  },
  {
    ui: "D012078",
    heading: "Renal Artery Obstruction",
    tree_numbers: ["C12.777.419.775", "C13.351.968.419.775", "C14.907.137.727"],
  },
  {
    ui: "D012080",
    heading: "Chronic Kidney Disease-Mineral and Bone Disorder",
    tree_numbers: [
      "C05.116.198.816.750",
      "C12.777.419.080",
      "C13.351.968.419.795",
      "C18.452.104.816.750",
      "C18.452.174.845.750",
      "C18.654.521.500.133.770.734.750",
      "C19.642.355.480.500",
    ],
    synonyms: ["Renal Rickets"],
  },
  {
    ui: "D012088",
    heading: "Reoviridae Infections",
    tree_numbers: ["C01.925.782.791"],
  },
  {
    ui: "D012090",
    heading: "Cumulative Trauma Disorders",
    tree_numbers: ["C26.844.150"],
  },
  {
    ui: "D012120",
    heading: "Respiration Disorders",
    tree_numbers: ["C08.618"],
  },
  {
    ui: "D012127",
    heading: "Respiratory Distress Syndrome, Newborn",
    tree_numbers: ["C08.381.840.500", "C08.618.840.500", "C16.614.521.563"],
  },
  {
    ui: "D012128",
    heading: "Respiratory Distress Syndrome",
    tree_numbers: ["C08.381.840", "C08.618.840"],
    synonyms: [
      "Non-Cardiogenic Pulmonary Edema",
      "Shock Lung",
      "Ards",
      "Adult Rds",
      "Acute Respiratory Distress Syndrome",
    ],
  },
  {
    ui: "D012130",
    heading: "Respiratory Hypersensitivity",
    tree_numbers: ["C08.674", "C20.543.480.680"],
  },
  {
    ui: "D012131",
    heading: "Respiratory Insufficiency",
    tree_numbers: ["C08.618.846"],
  },
  {
    ui: "D012133",
    heading: "Respiratory Paralysis",
    tree_numbers: ["C08.618.846.812", "C10.597.622.812", "C23.888.592.636.812"],
  },
  {
    ui: "D012135",
    heading: "Respiratory Sounds",
    tree_numbers: ["C23.888.852.779", "E01.370.386.720", "G09.772.775"],
  },
  {
    ui: "D012140",
    heading: "Respiratory Tract Diseases",
    tree_numbers: ["C08"],
  },
  {
    ui: "D012141",
    heading: "Respiratory Tract Infections",
    tree_numbers: ["C01.748", "C08.730"],
  },
  {
    ui: "D012142",
    heading: "Respiratory Tract Neoplasms",
    tree_numbers: ["C04.588.894.797", "C08.785"],
  },
  {
    ui: "D012148",
    heading: "Restless Legs Syndrome",
    tree_numbers: ["C10.803", "C10.886.425.800.700", "C10.886.659.634", "F03.870.400.800.700", "F03.870.664.634"],
    synonyms: ["Wed", "Willis-Ekbom Disease", "Wittmaack-Ekbom Syndrome"],
  },
  {
    ui: "D012162",
    heading: "Retinal Degeneration",
    tree_numbers: ["C11.270.612", "C11.768.585"],
    synonyms: ["Degeneration Of Retina"],
  },
  {
    ui: "D012163",
    heading: "Retinal Detachment",
    tree_numbers: ["C11.768.648"],
  },
  {
    ui: "D012164",
    heading: "Retinal Diseases",
    tree_numbers: ["C11.768"],
  },
  {
    ui: "D012166",
    heading: "Retinal Hemorrhage",
    tree_numbers: ["C11.290.807", "C11.768.710", "C23.550.414.756.775"],
  },
  {
    ui: "D012167",
    heading: "Retinal Perforations",
    tree_numbers: ["C11.768.740"],
    synonyms: ["Retinal Break", "Retinal Dialysis", "Retinal Tear"],
  },
  {
    ui: "D012170",
    heading: "Retinal Vein Occlusion",
    tree_numbers: ["C11.768.760", "C14.907.355.830.925.650", "C14.907.760"],
    synonyms: ["Retinal Vein Occlusion", "Occlusion, Of Retinal Vein"],
  },
  {
    ui: "D012173",
    heading: "Retinitis",
    tree_numbers: ["C11.768.773"],
    synonyms: [
      "Papilloretinitis",
      "Focal Retinitis And Retinochoroiditis, Juxtapapillary",
      "Juxtapapillary Focal Retinitis And Retinochoroiditis",
    ],
  },
  {
    ui: "D012174",
    heading: "Retinitis Pigmentosa",
    tree_numbers: ["C11.270.684", "C11.768.585.658.500", "C16.320.290.684"],
    synonyms: ["Pericentral Pigmentary Retinopathy"],
  },
  {
    ui: "D012175",
    heading: "Retinoblastoma",
    tree_numbers: [
      "C04.557.465.625.600.725",
      "C04.557.470.670.725",
      "C04.557.580.625.600.725",
      "C04.588.364.818.760",
      "C11.270.862",
      "C11.319.475.760",
      "C11.768.717.760",
    ],
    synonyms: ["Hereditary Retinoblastoma"],
  },
  {
    ui: "D012178",
    heading: "Retinopathy of Prematurity",
    tree_numbers: ["C11.768.836", "C16.614.521.731"],
    synonyms: ["Retrolental Fibroplasia", "Premature Retinopathy"],
  },
  {
    ui: "D012181",
    heading: "Retrocochlear Diseases",
    tree_numbers: ["C09.218.807"],
  },
  {
    ui: "D012183",
    heading: "Retrograde Degeneration",
    tree_numbers: ["C23.550.737.500"],
  },
  {
    ui: "D012185",
    heading: "Retroperitoneal Fibrosis",
    tree_numbers: ["C23.550.355.700"],
  },
  {
    ui: "D012186",
    heading: "Retroperitoneal Neoplasms",
    tree_numbers: ["C04.588.033.731"],
    synonyms: [
      "Malignant Tumor Of Peritoneum And Retroperitoneum",
      "Retroperitoneal Neoplasm",
      "Malignant Neoplasm Of Retroperitoneum And Peritoneum",
      "Tumor Of Retroperitoneum",
      "Neoplasm Of Retroperitoneum",
      "Neoplasm Of The Retroperitoneum",
    ],
  },
  {
    ui: "D012188",
    heading: "Retropneumoperitoneum",
    tree_numbers: ["C23.550.794", "C26.748"],
  },
  {
    ui: "D012192",
    heading: "Retroviridae Infections",
    tree_numbers: ["C01.925.782.815"],
  },
  {
    ui: "D012202",
    heading: "Reye Syndrome",
    tree_numbers: ["C06.552.241.649", "C10.228.140.163.780", "C18.452.132.780"],
    synonyms: ["Reye's Syndrome"],
  },
  {
    ui: "D012206",
    heading: "Rhabdomyolysis",
    tree_numbers: ["C05.651.807"],
  },
  {
    ui: "D012207",
    heading: "Rhabdomyoma",
    tree_numbers: ["C04.557.450.590.540.700"],
  },
  {
    ui: "D012208",
    heading: "Rhabdomyosarcoma",
    tree_numbers: ["C04.557.450.590.550.660", "C04.557.450.795.550.660"],
  },
  {
    ui: "D012213",
    heading: "Rheumatic Fever",
    tree_numbers: ["C01.150.252.410.890.731", "C05.550.114.843", "C05.799.825"],
    synonyms: ["Rhf - Rheumatic Fever", "Acute Rheumatic Fever"],
  },
  {
    ui: "D012214",
    heading: "Rheumatic Heart Disease",
    tree_numbers: ["C01.150.252.410.890.731.649", "C14.280.874"],
  },
  {
    ui: "D012215",
    heading: "Rheumatic Nodule",
    tree_numbers: ["C05.550.114.843.566", "C05.799.825.566"],
  },
  {
    ui: "D012216",
    heading: "Rheumatic Diseases",
    tree_numbers: ["C05.799", "C17.300.775"],
  },
  {
    ui: "D012218",
    heading: "Rheumatoid Nodule",
    tree_numbers: ["C05.550.114.154.683", "C05.799.114.683", "C17.300.775.099.683"],
  },
  {
    ui: "D012220",
    heading: "Rhinitis",
    tree_numbers: ["C01.748.674", "C08.460.799", "C08.730.674", "C09.603.799"],
    synonyms: [],
  },
  {
    ui: "D012221",
    heading: "Rhinitis, Allergic, Perennial",
    tree_numbers: ["C08.460.799.315.500", "C08.674.453.500", "C09.603.799.315.500", "C20.543.480.680.443.500"],
  },
  {
    ui: "D012222",
    heading: "Rhinitis, Atrophic",
    tree_numbers: ["C08.460.799.649", "C09.603.799.649"],
    synonyms: ["Rhinitis, Atrophic", "Ozaena", "Dry Rhinitis", "Ozena"],
  },
  {
    ui: "D012223",
    heading: "Rhinitis, Vasomotor",
    tree_numbers: ["C08.460.799.910", "C09.603.799.910"],
  },
  {
    ui: "D012224",
    heading: "Rhinophyma",
    tree_numbers: ["C17.800.716.500", "C17.800.794.650"],
  },
  {
    ui: "D012226",
    heading: "Rhinoscleroma",
    tree_numbers: [
      "C01.150.252.400.310.503.500",
      "C01.150.252.819.705",
      "C01.748.702",
      "C01.800.720.705",
      "C08.460.850",
      "C08.730.702",
      "C09.603.850",
      "C17.800.838.765.705",
    ],
  },
  {
    ui: "D012227",
    heading: "Rhinosporidiosis",
    tree_numbers: ["C01.610.600.700"],
    synonyms: ["Rhinosporidiosis", "Infection By Rhinosporidium Seeberi"],
  },
  {
    ui: "D012253",
    heading: "Rib Fractures",
    tree_numbers: ["C26.404.593", "C26.891.733"],
  },
  {
    ui: "D012257",
    heading: "Riboflavin Deficiency",
    tree_numbers: ["C18.654.521.500.133.699.713"],
    synonyms: ["Ariboflavinosis", "Vitamin B2 Deficiency"],
  },
  {
    ui: "D012279",
    heading: "Rickets",
    tree_numbers: ["C05.116.198.816", "C18.452.104.816", "C18.452.174.845", "C18.654.521.500.133.770.734"],
    synonyms: ["Active Rickets"],
  },
  {
    ui: "D012282",
    heading: "Rickettsia Infections",
    tree_numbers: ["C01.150.252.400.789.725", "C01.920.914.725"],
  },
  {
    ui: "D012288",
    heading: "Rickettsiaceae Infections",
    tree_numbers: ["C01.150.252.400.789", "C01.920.914"],
  },
  {
    ui: "D012295",
    heading: "Rift Valley Fever",
    tree_numbers: [
      "C01.436.430.812",
      "C01.920.500.770",
      "C01.925.081.810",
      "C01.925.407.810",
      "C01.925.782.147.810",
      "C01.925.782.417.762",
      "C06.552.380.315.430.812",
      "C22.467.435.812",
    ],
  },
  {
    ui: "D012298",
    heading: "Rigor Mortis",
    tree_numbers: ["C23.550.260.224.617.839"],
  },
  {
    ui: "D012301",
    heading: "Rinderpest",
    tree_numbers: ["C01.925.782.580.600.500.700", "C22.780"],
  },
  {
    ui: "D012303",
    heading: "Ring Chromosomes",
    tree_numbers: ["A11.284.187.788", "C23.550.210.760", "G05.360.162.788", "G05.365.590.175.760"],
  },
  {
    ui: "D012327",
    heading: "RNA Virus Infections",
    tree_numbers: ["C01.925.782"],
  },
  {
    ui: "D012373",
    heading: "Rocky Mountain Spotted Fever",
    tree_numbers: ["C01.150.252.400.789.725.400.500", "C01.920.930.887.500"],
  },
  {
    ui: "D012376",
    heading: "Rodent Diseases",
    tree_numbers: ["C22.795"],
  },
  {
    ui: "D012391",
    heading: "Root Resorption",
    tree_numbers: ["C07.793.901.653", "G10.549.855.653"],
  },
  {
    ui: "D012393",
    heading: "Rosacea",
    tree_numbers: ["C17.800.716"],
    synonyms: ["Acne, Erythematosa", "Acne Roscea"],
  },
  {
    ui: "D012400",
    heading: "Rotavirus Infections",
    tree_numbers: ["C01.925.782.791.814"],
  },
  {
    ui: "D012409",
    heading: "Rubella",
    tree_numbers: ["C01.925.782.930.700.700"],
    synonyms: ["German Measles"],
  },
  {
    ui: "D012410",
    heading: "Rubella Syndrome, Congenital",
    tree_numbers: ["C01.925.782.930.700.700.700", "C16.131.077.790"],
  },
  {
    ui: "D012415",
    heading: "Rubinstein-Taybi Syndrome",
    tree_numbers: [
      "C05.116.099.370.797",
      "C05.660.207.850",
      "C10.597.606.360.700",
      "C16.131.077.804",
      "C16.131.260.790",
      "C16.131.621.207.850",
      "C16.320.180.790",
    ],
    synonyms: ["Proximal Chromosome 16p13.3 Deletion Syndrome", "Broad Thumb-Hallux Syndrome", "Rubinstein Syndrome"],
  },
  {
    ui: "D012421",
    heading: "Rupture",
    tree_numbers: ["C26.761"],
  },
  {
    ui: "D012422",
    heading: "Rupture, Spontaneous",
    tree_numbers: ["C23.300.909"],
  },
  {
    ui: "D012448",
    heading: "Sadism",
    tree_numbers: ["F03.657.700"],
  },
  {
    ui: "D012465",
    heading: "Salivary Duct Calculi",
    tree_numbers: ["C07.465.815.497.325", "C23.300.175.700.325"],
  },
  {
    ui: "D012466",
    heading: "Salivary Gland Diseases",
    tree_numbers: ["C07.465.815"],
  },
  {
    ui: "D012467",
    heading: "Salivary Gland Fistula",
    tree_numbers: ["C07.465.614.655", "C07.465.815.655", "C23.300.575.500.775"],
  },
  {
    ui: "D012468",
    heading: "Salivary Gland Neoplasms",
    tree_numbers: ["C04.588.443.591.824", "C07.465.530.824", "C07.465.815.718"],
  },
  {
    ui: "D012478",
    heading: "Salmonella Food Poisoning",
    tree_numbers: ["C01.150.252.400.310.821.606", "C25.723.415.738"],
  },
  {
    ui: "D012480",
    heading: "Salmonella Infections",
    tree_numbers: ["C01.150.252.400.310.821"],
  },
  {
    ui: "D012481",
    heading: "Salmonella Infections, Animal",
    tree_numbers: ["C01.150.252.400.310.821.706", "C22.812"],
  },
  {
    ui: "D012488",
    heading: "Salpingitis",
    tree_numbers: ["C13.351.500.056.390.890", "C13.351.500.056.750.875"],
  },
  {
    ui: "D012497",
    heading: "Sandhoff Disease",
    tree_numbers: [
      "C10.228.140.163.100.435.825.300.300.249",
      "C16.320.565.189.435.825.300.300.249",
      "C16.320.565.398.641.803.350.300.700",
      "C16.320.565.595.554.825.300.300.800",
      "C18.452.132.100.435.825.300.300.249",
      "C18.452.584.687.803.350.300.700",
      "C18.452.648.189.435.825.300.300.249",
      "C18.452.648.398.641.803.350.300.700",
      "C18.452.648.595.554.825.300.300.800",
    ],
    synonyms: ["Sandhoff Jatzkewitz Disease"],
  },
  {
    ui: "D012507",
    heading: "Sarcoidosis",
    tree_numbers: ["C15.604.515.827"],
    synonyms: ["Lymphogranulomatosis", "Boeck Sarcoid"],
  },
  {
    ui: "D012509",
    heading: "Sarcoma",
    tree_numbers: ["C04.557.450.795"],
    synonyms: ["Epithelioid Cell Sarcoma"],
  },
  {
    ui: "D012510",
    heading: "Sarcoma 180",
    tree_numbers: ["C04.557.450.795.830.780", "C04.619.857.656"],
  },
  {
    ui: "D012511",
    heading: "Sarcoma 37",
    tree_numbers: ["C04.557.450.795.830.760", "C04.619.857.573"],
  },
  {
    ui: "D012512",
    heading: "Sarcoma, Ewing",
    tree_numbers: ["C04.557.450.565.575.650.800", "C04.557.450.795.620.800"],
    synonyms: [
      "Ewings Sarcoma",
      "Ewing's Tumor",
      "Localized Ewing's Sarcoma",
      "Ewings Sarcoma-Primitive Neuroectodermal Tumor",
      "Localized Ewing Sarcoma",
      "Ewing's Family Localized Tumor",
      "Localized Ewing's Tumor",
      "Localized Peripheral Primitive Neuroectodermal Tumor",
      "Peripheral Primitive Neuroectodermal Tumor",
      "Pnet Of Thoracopulmonary Region",
      "Localized Ewing's Sarcoma/peripheral Primitive Neuroectodermal Tumor",
      "Ewing's Sarcoma/peripheral Primitive Neuroectodermal Tumor",
    ],
  },
  {
    ui: "D012513",
    heading: "Sarcoma, Experimental",
    tree_numbers: ["C04.557.450.795.830", "C04.619.857", "E05.598.500.496.968"],
  },
  {
    ui: "D012514",
    heading: "Sarcoma, Kaposi",
    tree_numbers: ["C01.925.256.466.860", "C04.557.450.795.850", "C04.557.645.750"],
    synonyms: [
      "Cutaneous Kaposi's Sarcoma",
      "Cardiac Kaposi's Sarcoma",
      "Kaposi's Sarcoma, Skin",
      "Penis Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Soft Tissues",
      "Palate Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Central Nervous System",
      "Soft Tissue Kaposi's Sarcoma",
      "Palate Kaposi's Sarcoma",
      "Cutaneous Kaposi's Sarcoma",
      "Intestinal Kaposi's Sarcoma",
      "Pulmonary Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Cornea",
      "Kaposi's Sarcoma Of The Gallbladder",
      "Corneal Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Gastrointestinal Sites",
      "Corneal Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of The Prostate",
      "Kaposi's Sarcoma Of Penis",
      "Kaposi's Sarcoma Of Soft Tissue",
      "Prostate Kaposi's Sarcoma",
      "Anal Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Palate",
      "Kaposi's Sarcoma Of Anus",
      "Kaposi Sarcoma",
      "Central Nervous System Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Conjunctiva",
      "Gastric Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Heart",
      "Esophageal Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Lung",
      "Gallbladder Kaposi's Sarcoma",
      "Kaposi's Sarcoma, Lung",
      "Kaposi's Sarcoma Of Lymph Nodes",
      "Lymphadenopathic Kaposi's Sarcoma",
      "Conjunctival Kaposi's Sarcoma",
      "Lymph Node Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of Esophagus",
      "Kaposi's Sarcoma Of Skin",
      "Conjunctival Kaposi's Sarcoma",
      "Kaposi's Sarcoma Of The Cns",
    ],
  },
  {
    ui: "D012515",
    heading: "Mast-Cell Sarcoma",
    tree_numbers: ["C04.557.450.565.465.124"],
  },
  {
    ui: "D012516",
    heading: "Osteosarcoma",
    tree_numbers: ["C04.557.450.565.575.650", "C04.557.450.795.620"],
    synonyms: ["Bone Tissue Neoplasm", "Osteoid Sarcoma", "Skeletal Sarcoma", "Osteogenic Sarcoma"],
  },
  {
    ui: "D012517",
    heading: "Sarcoma, Yoshida",
    tree_numbers: ["C04.557.450.795.830.850", "C04.619.857.822"],
  },
  {
    ui: "D012523",
    heading: "Sarcocystosis",
    tree_numbers: ["C01.610.752.250.634"],
    synonyms: ["Sarcosporidiosis"],
  },
  {
    ui: "D012532",
    heading: "Scabies",
    tree_numbers: ["C01.610.858.211.480.708", "C17.800.838.775.800"],
    synonyms: ["Sarcoptic Itch", "Infestation By Sarcoptes Scabiei Var Hominis", "Infestation By Sarcoptes Scabiei"],
  },
  {
    ui: "D012536",
    heading: "Scalp Dermatoses",
    tree_numbers: ["C17.800.738"],
    synonyms: ["Dermatosis Of Scalp"],
  },
  {
    ui: "D012541",
    heading: "Scarlet Fever",
    tree_numbers: ["C01.150.252.410.890.823"],
    synonyms: ["Scarlatina"],
  },
  {
    ui: "D012544",
    heading: "Scheuermann Disease",
    tree_numbers: ["C05.116.821.500.500", "C05.116.900.800.500.500", "C05.116.900.808.500"],
    synonyms: [
      "Sherman's Disease",
      "Juvenile Osteochondrosis Of Scheurermann",
      "Juvenile Osteochondritis Of The Spine",
      "Scheuermann's Kyphosis",
      "Juvenile Osteochondrosis Of Spine",
    ],
  },
  {
    ui: "D012552",
    heading: "Schistosomiasis",
    tree_numbers: ["C01.610.335.865.859", "C01.920.922"],
  },
  {
    ui: "D012553",
    heading: "Schistosomiasis haematobia",
    tree_numbers: [
      "C01.610.335.865.859.427",
      "C01.915.775",
      "C01.920.922.427",
      "C12.777.892.775",
      "C13.351.968.892.775",
    ],
    synonyms: [
      "Schistosoma Hematobium Infectious Disease",
      "Schistosoma Haematobium",
      "Schistosomiasis Of Bladder",
      "Vesical Schistosomiasis",
      "Cystitis With Bilharziasis",
      "Schistosoma Hematobium Infection",
      "Schistosomiasis Due To Schistosoma Haematobium",
      "Bladder Schistosomiasis",
    ],
  },
  {
    ui: "D012554",
    heading: "Schistosomiasis japonica",
    tree_numbers: ["C01.610.335.865.859.521", "C01.920.922.521"],
    synonyms: [
      "Katayama Fever",
      "Schistosoma Mansoni Infectious Disease",
      "Schistosoma Japonicum Infection",
      "Schistosomiasis Japonica",
    ],
  },
  {
    ui: "D012555",
    heading: "Schistosomiasis mansoni",
    tree_numbers: ["C01.610.335.865.859.576", "C01.920.922.576"],
    synonyms: [
      "Katayama Fever",
      "Schistosoma Mansoni Infectious Disease",
      "Schistosoma Japonicum Infection",
      "Schistosomiasis Japonica",
    ],
  },
  {
    ui: "D012557",
    heading: "Schizoid Personality Disorder",
    tree_numbers: ["F03.675.700"],
  },
  {
    ui: "D012559",
    heading: "Schizophrenia",
    tree_numbers: ["F03.700.750"],
    synonyms: ["Schizophrenia-1"],
  },
  {
    ui: "D012560",
    heading: "Schizophrenia, Catatonic",
    tree_numbers: ["F03.700.750.300"],
  },
  {
    ui: "D012561",
    heading: "Schizophrenia, Childhood",
    tree_numbers: ["F03.625.968"],
  },
  {
    ui: "D012562",
    heading: "Schizophrenia, Disorganized",
    tree_numbers: ["F03.700.750.350"],
  },
  {
    ui: "D012563",
    heading: "Schizophrenia, Paranoid",
    tree_numbers: ["F03.700.750.600"],
    synonyms: [
      "Paraphrenia - Late",
      "Paraphrenic Schizophrenia",
      "Paranoid Type Schizophrenia Subchronic State",
      "Paranoid Type Schizophrenia",
      "Chronic Paranoid Schizophrenia",
    ],
  },
  {
    ui: "D012569",
    heading: "Schizotypal Personality Disorder",
    tree_numbers: ["F03.675.725"],
  },
  {
    ui: "D012585",
    heading: "Sciatica",
    tree_numbers: ["C10.668.829.500.675.800", "C10.668.829.600.800", "C23.888.592.612.664.800"],
  },
  {
    ui: "D012587",
    heading: "Scimitar Syndrome",
    tree_numbers: [
      "C08.381.844",
      "C08.695.815",
      "C14.240.850.968",
      "C14.907.780",
      "C16.131.240.850.937",
      "C16.131.740.815",
    ],
    synonyms: [
      "Pulmonary Venolobar Syndrome",
      "Congenital Venolobar Syndrome",
      "Vena Cava Bronchovascular Syndrome",
      "Mirror-Image Lung Syndrome",
      "Hypogenetic Lung Syndrome",
      "Halasz Syndrome",
      "Total Anomalous Pulmonary Venous Return",
    ],
  },
  {
    ui: "D012592",
    heading: "Scleredema Adultorum",
    tree_numbers: ["C17.300.550.750", "C17.800.751"],
    synonyms: ["Buschke's Scleredema"],
  },
  {
    ui: "D012593",
    heading: "Sclerema Neonatorum",
    tree_numbers: ["C16.131.831.812", "C16.614.810", "C17.800.804.812"],
  },
  {
    ui: "D012594",
    heading: "Scleroderma, Localized",
    tree_numbers: ["C17.300.787", "C17.800.767"],
    synonyms: ["Dermatosclerosis"],
  },
  {
    ui: "D012595",
    heading: "Scleroderma, Systemic",
    tree_numbers: ["C17.300.799", "C17.800.784"],
    synonyms: ["Scleroderma", "Scleroderma Syndrome", "Progressive Systemic Sclerosis", "Systemic Sclerosis"],
  },
  {
    ui: "D012598",
    heading: "Sclerosis",
    tree_numbers: ["C23.550.823"],
  },
  {
    ui: "D012600",
    heading: "Scoliosis",
    tree_numbers: ["C05.116.900.800.875"],
  },
  {
    ui: "D012607",
    heading: "Scotoma",
    tree_numbers: ["C10.597.751.941.811", "C11.966.811", "C23.888.592.763.941.811"],
  },
  {
    ui: "D012608",
    heading: "Scrapie",
    tree_numbers: ["C01.207.800.717", "C10.228.228.800.717", "C10.574.843.850", "C22.836.799"],
  },
  {
    ui: "D012610",
    heading: "Screw Worm Infection",
    tree_numbers: ["C01.610.858.211.503.744"],
  },
  {
    ui: "D012612",
    heading: "Scrub Typhus",
    tree_numbers: ["C01.150.252.400.789.850", "C01.920.914.850"],
    synonyms: [
      "Scrub Mite-Borne Typhus",
      "Tsutsugamushi",
      "Kedani Fever",
      "Tsutsugamushi Disease",
      "Japanese River Fever",
    ],
  },
  {
    ui: "D012614",
    heading: "Scurvy",
    tree_numbers: ["C14.907.454.800", "C15.378.463.515.800", "C18.654.521.500.133.115.661"],
  },
  {
    ui: "D012618",
    heading: "Sea-Blue Histiocyte Syndrome",
    tree_numbers: [
      "C10.228.140.163.100.435.825.775",
      "C15.604.250.410.800",
      "C16.320.565.189.435.825.775",
      "C16.320.565.398.641.803.850",
      "C16.320.565.595.554.825.775",
      "C18.452.132.100.435.825.775",
      "C18.452.584.687.803.850",
      "C18.452.648.189.435.825.775",
      "C18.452.648.398.641.803.850",
      "C18.452.648.595.554.825.775",
    ],
    synonyms: ["Sea-Blue Histiocyte Syndrome"],
  },
  {
    ui: "D012625",
    heading: "Sebaceous Gland Diseases",
    tree_numbers: ["C17.800.794"],
  },
  {
    ui: "D012626",
    heading: "Sebaceous Gland Neoplasms",
    tree_numbers: ["C04.588.805.578", "C17.800.794.712", "C17.800.882.712"],
    synonyms: ["Sebaceous Neoplasm"],
  },
  {
    ui: "D012628",
    heading: "Dermatitis, Seborrheic",
    tree_numbers: ["C17.800.174.580", "C17.800.794.230", "C17.800.815.580", "C17.800.859.350"],
    synonyms: ["Seborrhea", "Skin Seborrheic", "Seborrhoeic Eczema", "Seborrhoeic Dermatitis"],
  },
  {
    ui: "D012640",
    heading: "Seizures",
    tree_numbers: ["C10.597.742", "C23.888.592.742"],
    synonyms: ["Epilepsy, Visual"],
  },
  {
    ui: "D012652",
    heading: "Self Mutilation",
    tree_numbers: ["C26.780", "F01.145.126.980.750"],
  },
  {
    ui: "D012678",
    heading: "Sensation Disorders",
    tree_numbers: ["C10.597.751", "C23.888.592.763"],
  },
  {
    ui: "D012700",
    heading: "Serositis",
    tree_numbers: ["C23.550.470.646"],
  },
  {
    ui: "D012707",
    heading: "Sertoli Cell Tumor",
    tree_numbers: [
      "C04.557.475.750.847.500",
      "C04.588.322.762.500.500",
      "C04.588.945.440.915.500.500",
      "C12.294.260.937.500.500",
      "C12.758.409.937.500.500",
      "C19.344.762.500.500",
      "C19.391.829.782.500.500",
    ],
    synonyms: ["Tubular Androblastoma"],
  },
  {
    ui: "D012713",
    heading: "Serum Sickness",
    tree_numbers: ["C17.800.174.600.800", "C20.543.206.380.800", "C20.543.520.770", "C25.100.468.380.800"],
  },
  {
    ui: "D012719",
    heading: "Setariasis",
    tree_numbers: ["C01.610.335.349.820", "C01.610.335.508.700.750.361.852", "C01.610.701.377.820", "C22.674.377.820"],
    synonyms: ["Infectious Disease By Setaria"],
  },
  {
    ui: "D012729",
    heading: "Sex Chromosome Aberrations",
    tree_numbers: ["C23.550.210.815", "G05.365.590.175.815"],
  },
  {
    ui: "D012734",
    heading: "Disorders of Sex Development",
    tree_numbers: ["C12.706.316", "C13.351.875.253", "C16.131.939.316", "C19.391.119"],
    synonyms: ["Sex Differentiation Disease", "Sex Development Disorder"],
  },
  {
    ui: "D012735",
    heading: "Sexual Dysfunction, Physiological",
    tree_numbers: ["C12.294.644", "C13.351.500.665"],
  },
  {
    ui: "D012749",
    heading: "Sexually Transmitted Diseases",
    tree_numbers: ["C01.221.812", "C01.778", "C12.294.668", "C13.351.500.711", "C23.550.291.531.937"],
  },
  {
    ui: "D012751",
    heading: "Sezary Syndrome",
    tree_numbers: [
      "C04.557.386.480.750.800.775",
      "C15.604.515.569.480.750.800.775",
      "C15.604.515.841",
      "C20.683.515.761.480.750.800.775",
      "C20.683.515.920",
    ],
    synonyms: ["Sezary Disease", "Sezary Syndrome"],
  },
  {
    ui: "D012753",
    heading: "Shared Paranoid Disorder",
    tree_numbers: ["F03.700.750.700"],
  },
  {
    ui: "D012757",
    heading: "Sheep Diseases",
    tree_numbers: ["C22.836"],
  },
  {
    ui: "D012766",
    heading: "Pasteurellosis, Pneumonic",
    tree_numbers: [
      "C01.150.252.400.700.675",
      "C01.748.085.600",
      "C01.925.782.580.600.600.648",
      "C08.730.085.600",
      "C22.196.090.600",
    ],
  },
  {
    ui: "D012769",
    heading: "Shock",
    tree_numbers: ["C23.550.835"],
  },
  {
    ui: "D012770",
    heading: "Shock, Cardiogenic",
    tree_numbers: [
      "C14.280.647.500.750",
      "C14.907.585.500.750",
      "C23.550.513.355.750.750",
      "C23.550.717.489.750.750",
      "C23.550.835.550",
    ],
  },
  {
    ui: "D012771",
    heading: "Shock, Hemorrhagic",
    tree_numbers: ["C23.550.414.980", "C23.550.835.650"],
  },
  {
    ui: "D012772",
    heading: "Shock, Septic",
    tree_numbers: ["C01.757.800", "C23.550.470.790.500.800", "C23.550.835.900.712"],
    synonyms: ["Toxic Shock", "Toxic Shock Syndrome", "Tss", "Toxic Shock Syndrome"],
  },
  {
    ui: "D012773",
    heading: "Shock, Surgical",
    tree_numbers: ["C23.550.767.879", "C23.550.835.775"],
  },
  {
    ui: "D012774",
    heading: "Shock, Traumatic",
    tree_numbers: ["C23.550.835.888", "C26.797"],
  },
  {
    ui: "D012778",
    heading: "Short Bowel Syndrome",
    tree_numbers: ["C06.405.469.637.832", "C23.550.767.882"],
    synonyms: ["Short Gut Syndrome", "Acquired Short Bowel Syndrome"],
  },
  {
    ui: "D012779",
    heading: "Short Rib-Polydactyly Syndrome",
    tree_numbers: ["C05.116.099.708.857", "C05.660.585.600.750", "C16.131.077.850", "C16.131.621.585.600.750"],
  },
  {
    ui: "D012783",
    heading: "Shoulder Dislocation",
    tree_numbers: ["C05.550.518.750", "C26.289.750", "C26.803.125"],
  },
  {
    ui: "D012784",
    heading: "Shoulder Fractures",
    tree_numbers: ["C26.404.625", "C26.803.250"],
  },
  {
    ui: "D012790",
    heading: "Shwartzman Phenomenon",
    tree_numbers: ["C14.907.454.810", "C14.907.940.890", "C15.378.463.515.810"],
    synonyms: ["Shwartzman Reaction"],
  },
  {
    ui: "D012791",
    heading: "Shy-Drager Syndrome",
    tree_numbers: ["C10.177.575.550.750", "C10.228.140.079.612.700", "C10.228.662.550.700", "C14.907.514.741"],
    synonyms: ["Multiple System Atrophy", "Shy-Drager Syndrome"],
  },
  {
    ui: "D012793",
    heading: "Sialadenitis",
    tree_numbers: ["C07.465.815.793"],
    synonyms: ["Sialoadenitis"],
  },
  {
    ui: "D012797",
    heading: "Sialometaplasia, Necrotizing",
    tree_numbers: ["C07.465.815.802"],
    synonyms: ["Necrotizing Sialometaplasia"],
  },
  {
    ui: "D012798",
    heading: "Sialorrhea",
    tree_numbers: ["C07.465.815.815"],
  },
  {
    ui: "D012804",
    heading: "Sick Sinus Syndrome",
    tree_numbers: [
      "C14.280.067.093.249",
      "C14.280.067.558.536",
      "C14.280.123.500.536",
      "C23.550.073.093.249",
      "C23.550.073.425.440",
    ],
    synonyms: ["Sinus Node Infection"],
  },
  {
    ui: "D012805",
    heading: "Sickle Cell Trait",
    tree_numbers: ["C15.378.071.141.150.150.670", "C15.378.420.155.668", "C16.320.070.150.670", "C16.320.365.155.668"],
  },
  {
    ui: "D012806",
    heading: "Siderosis",
    tree_numbers: ["C08.381.483.581.750", "C08.381.520.702.750", "C24.800.773"],
    synonyms: ["Pulmonary Siderosis"],
  },
  {
    ui: "D012810",
    heading: "Sigmoid Diseases",
    tree_numbers: ["C06.405.469.158.850"],
  },
  {
    ui: "D012811",
    heading: "Sigmoid Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.307.180.800",
      "C06.301.371.411.307.180.800",
      "C06.405.249.411.307.180.800",
      "C06.405.469.158.356.180.800",
      "C06.405.469.158.850.850",
      "C06.405.469.491.307.180.800",
    ],
    synonyms: ["Neoplasm Of Sigmoid Colon", "Tumor Of Sigmoid Colon"],
  },
  {
    ui: "D012816",
    heading: "Signs and Symptoms",
    tree_numbers: ["C23.888"],
  },
  {
    ui: "D012817",
    heading: "Signs and Symptoms, Digestive",
    tree_numbers: ["C23.888.821"],
  },
  {
    ui: "D012818",
    heading: "Signs and Symptoms, Respiratory",
    tree_numbers: ["C23.888.852"],
  },
  {
    ui: "D012829",
    heading: "Silicosis",
    tree_numbers: ["C08.381.483.581.760", "C08.381.520.702.760", "C24.800.834"],
    synonyms: [
      "Silicotic Fibrosis Of Lung",
      "Silicotuberculosis",
      "Silica Pneumoconiosis",
      "Pneumoconiosis Due To Silicates",
    ],
  },
  {
    ui: "D012830",
    heading: "Silicotuberculosis",
    tree_numbers: [
      "C01.150.252.410.040.552.846.899.669",
      "C01.748.939.669",
      "C08.381.483.581.760.750",
      "C08.381.520.702.760.750",
      "C08.381.922.669",
      "C08.730.939.669",
      "C24.800.834.752",
    ],
  },
  {
    ui: "D012832",
    heading: "Silo Filler's Disease",
    tree_numbers: ["C08.381.483.125.682", "C24.080.752"],
    synonyms: ["Silo-Fillers' Disease", "Silo Filler Disease"],
  },
  {
    ui: "D012848",
    heading: "Sinoatrial Block",
    tree_numbers: ["C14.280.067.558.750", "C14.280.123.500.750", "C23.550.073.425.780"],
  },
  {
    ui: "D012851",
    heading: "Sinus Thrombosis, Intracranial",
    tree_numbers: ["C10.228.140.300.525.425.500", "C14.907.253.566.350.500", "C14.907.355.590.213.350.500"],
  },
  {
    ui: "D012852",
    heading: "Sinusitis",
    tree_numbers: ["C01.748.749", "C08.460.692.752", "C08.730.749", "C09.603.692.752"],
  },
  {
    ui: "D012857",
    heading: "Situs Inversus",
    tree_numbers: ["C16.131.810"],
    synonyms: ["Complete Transposition", "Laterality Sequence", "Situs Inversus Viscerum"],
  },
  {
    ui: "D012859",
    heading: "Sjogren's Syndrome",
    tree_numbers: [
      "C05.550.114.154.774",
      "C05.799.114.774",
      "C07.465.815.929.669",
      "C11.496.260.719",
      "C17.300.775.099.774",
      "C20.111.199.774",
    ],
    synonyms: ["Sicca Syndrome", "Xerodermosteosis", "Sjogren Syndrome"],
  },
  {
    ui: "D012868",
    heading: "Skin Abnormalities",
    tree_numbers: ["C16.131.831", "C17.800.804"],
  },
  {
    ui: "D012871",
    heading: "Skin Diseases",
    tree_numbers: ["C17.800"],
    synonyms: ["Genodermatosis", "Skin And Subcutaneous Tissue Disease"],
  },
  {
    ui: "D012872",
    heading: "Skin Diseases, Vesiculobullous",
    tree_numbers: ["C17.800.865"],
    synonyms: ["Subcorneal Pustular Dermatosis", "Sneddon-Wilkinson Disease Or Syndrome"],
  },
  {
    ui: "D012873",
    heading: "Skin Diseases, Genetic",
    tree_numbers: ["C16.320.850", "C17.800.827"],
    synonyms: ["Genodermatosis", "Skin And Subcutaneous Tissue Disease"],
  },
  {
    ui: "D012874",
    heading: "Skin Diseases, Infectious",
    tree_numbers: ["C01.800", "C17.800.838"],
  },
  {
    ui: "D012875",
    heading: "Skin Diseases, Metabolic",
    tree_numbers: ["C17.800.849", "C18.452.880"],
  },
  {
    ui: "D012876",
    heading: "Skin Diseases, Parasitic",
    tree_numbers: ["C01.610.858", "C17.800.838.775"],
  },
  {
    ui: "D012877",
    heading: "Skin Manifestations",
    tree_numbers: ["C23.888.885"],
  },
  {
    ui: "D012878",
    heading: "Skin Neoplasms",
    tree_numbers: ["C04.588.805", "C17.800.882"],
    synonyms: ["Ca - Skin Cancer", "Malignant Neoplasm Of Skin", "Melanoma And Non-Melanoma Skin Cancer"],
  },
  {
    ui: "D012883",
    heading: "Skin Ulcer",
    tree_numbers: ["C17.800.893"],
  },
  {
    ui: "D012887",
    heading: "Skull Fractures",
    tree_numbers: ["C10.900.300.918", "C26.404.750", "C26.915.300.745"],
  },
  {
    ui: "D012888",
    heading: "Skull Neoplasms",
    tree_numbers: ["C04.588.149.721", "C05.116.231.754"],
  },
  {
    ui: "D012891",
    heading: "Sleep Apnea Syndromes",
    tree_numbers: ["C08.618.085.852", "C10.886.425.800.750"],
    synonyms: ["Complex Sleep Apnea"],
  },
  {
    ui: "D012892",
    heading: "Sleep Deprivation",
    tree_numbers: ["C10.886.425.175", "C23.888.592.796.772", "F02.830.855.671", "F03.870.400.099"],
  },
  {
    ui: "D012893",
    heading: "Sleep Wake Disorders",
    tree_numbers: ["C10.886", "C23.888.592.796", "F03.870"],
  },
  {
    ui: "D012897",
    heading: "Slow Virus Diseases",
    tree_numbers: ["C01.925.839"],
  },
  {
    ui: "D012899",
    heading: "Smallpox",
    tree_numbers: ["C01.925.256.743.826"],
    synonyms: ["Milkpox", "Cottonpox", "Whitepox", "Variola Minor", "Alastrim"],
  },
  {
    ui: "D012909",
    heading: "Snake Bites",
    tree_numbers: ["C25.723.127.442", "C26.176.724"],
  },
  {
    ui: "D012912",
    heading: "Sneezing",
    tree_numbers: ["C23.888.852.889", "G09.772.832"],
  },
  {
    ui: "D012913",
    heading: "Snoring",
    tree_numbers: ["C23.888.852.779.850"],
  },
  {
    ui: "D012983",
    heading: "Soft Tissue Neoplasms",
    tree_numbers: ["C04.588.839"],
    synonyms: [
      "Soft Tissue Benign Neoplasm",
      "Neoplasm Of Soft Tissues",
      "Tumor Of The Soft Tissue",
      "Neoplasm Of Soft Tissue",
      "Mesenchymal Tissue Neoplasm",
    ],
  },
  {
    ui: "D013001",
    heading: "Somatoform Disorders",
    tree_numbers: ["F03.875"],
    synonyms: ["Briquet's Disorder", "Somatisation Disorder"],
  },
  {
    ui: "D013005",
    heading: "Somatostatinoma",
    tree_numbers: [
      "C04.557.465.625.650.240.695",
      "C04.557.470.200.025.370.695",
      "C04.588.274.761.500.500",
      "C04.588.322.475.500.500",
      "C06.301.761.500.500",
      "C06.689.667.500.500",
      "C19.344.421.500.500",
    ],
    synonyms: ["Somatostatin Cell Tumour", "Somatostatin Cell Neoplasm"],
  },
  {
    ui: "D013009",
    heading: "Somnambulism",
    tree_numbers: ["C10.886.659.635.700", "F03.870.664.635.700"],
  },
  {
    ui: "D013031",
    heading: "Sparganosis",
    tree_numbers: ["C01.610.335.190.304.780"],
    synonyms: ["Sparganosis", "Infection By Sparganum"],
  },
  {
    ui: "D013035",
    heading: "Spasm",
    tree_numbers: ["C10.597.613.750", "C23.888.592.608.750"],
  },
  {
    ui: "D013036",
    heading: "Spasms, Infantile",
    tree_numbers: ["C10.228.140.490.375.760", "C10.228.140.490.493.875"],
    synonyms: [],
  },
  {
    ui: "D013064",
    heading: "Speech Disorders",
    tree_numbers: ["C10.597.606.150.500.800", "C23.888.592.604.150.500.800"],
  },
  {
    ui: "D013086",
    heading: "Spermatic Cord Torsion",
    tree_numbers: ["C12.294.693"],
    synonyms: ["Torsion Of Testicle", "Torsion Of Testis", "Testicular Torsion"],
  },
  {
    ui: "D013088",
    heading: "Spermatocele",
    tree_numbers: ["C12.294.731", "C23.300.306.750"],
  },
  {
    ui: "D013103",
    heading: "Spherocytosis, Hereditary",
    tree_numbers: ["C15.378.071.141.150.785", "C16.320.070.785"],
    synonyms: ["Minkowski Chauffard Syndrome", "Congenital Spherocytic Hemolytic Anemia", "Spherocytic Anemia"],
  },
  {
    ui: "D013106",
    heading: "Sphingolipidoses",
    tree_numbers: [
      "C10.228.140.163.100.435.825",
      "C16.320.565.189.435.825",
      "C16.320.565.398.641.803",
      "C16.320.565.595.554.825",
      "C18.452.132.100.435.825",
      "C18.452.584.687.803",
      "C18.452.648.189.435.825",
      "C18.452.648.398.641.803",
      "C18.452.648.595.554.825",
    ],
    synonyms: ["Sphingolipidosis", "Sphingolipidoses"],
  },
  {
    ui: "D013117",
    heading: "Spinal Cord Compression",
    tree_numbers: ["C10.228.854.761", "C26.819.678"],
  },
  {
    ui: "D013118",
    heading: "Spinal Cord Diseases",
    tree_numbers: ["C10.228.854"],
    synonyms: [],
  },
  {
    ui: "D013119",
    heading: "Spinal Cord Injuries",
    tree_numbers: ["C10.228.854.763", "C10.900.850", "C26.819"],
  },
  {
    ui: "D013120",
    heading: "Spinal Cord Neoplasms",
    tree_numbers: ["C04.588.614.250.803", "C10.228.854.765", "C10.551.240.750"],
    synonyms: [
      "Spinal Cord Cancer",
      "Malignant Tumor Of The Spinal Cord",
      "Spinal Cord Neoplasm",
      "Tumor Of The Spinal Cord",
      "Intraspinal Tumor",
    ],
  },
  {
    ui: "D013121",
    heading: "Spinal Curvatures",
    tree_numbers: ["C05.116.900.800"],
  },
  {
    ui: "D013122",
    heading: "Spinal Diseases",
    tree_numbers: ["C05.116.900"],
  },
  {
    ui: "D013124",
    heading: "Spinal Injuries",
    tree_numbers: ["C26.117.500"],
  },
  {
    ui: "D013125",
    heading: "Spinal Neoplasms",
    tree_numbers: ["C04.588.149.828", "C05.116.231.828", "C05.116.900.801"],
    synonyms: [
      "Spinal Cord Cancer",
      "Malignant Tumor Of The Spinal Cord",
      "Spinal Cord Neoplasm",
      "Tumor Of The Spinal Cord",
      "Intraspinal Tumor",
    ],
  },
  {
    ui: "D013128",
    heading: "Spinal Osteophytosis",
    tree_numbers: ["C05.116.900.815"],
  },
  {
    ui: "D013130",
    heading: "Spinal Stenosis",
    tree_numbers: ["C05.116.900.825"],
    synonyms: ["Lumbar Spinal Stenosis", "Cervical Spinal Stenosis", "Spinal Stenosis Of Lumbar Region"],
  },
  {
    ui: "D013132",
    heading: "Spinocerebellar Degenerations",
    tree_numbers: ["C10.228.140.252.700", "C10.228.854.787", "C10.574.500.825", "C16.320.400.780"],
  },
  {
    ui: "D013145",
    heading: "Spirochaetales Infections",
    tree_numbers: ["C01.150.252.400.794"],
  },
  {
    ui: "D013158",
    heading: "Splenic Diseases",
    tree_numbers: ["C15.604.744"],
    synonyms: ["Spleen Disease", "Dyssplenism"],
  },
  {
    ui: "D013159",
    heading: "Splenic Infarction",
    tree_numbers: ["C14.907.795", "C15.604.744.617", "C23.550.513.355.937", "C23.550.717.489.937"],
    synonyms: ["Splenic Infarct", "Splenic Infarction"],
  },
  {
    ui: "D013160",
    heading: "Splenic Neoplasms",
    tree_numbers: ["C04.588.842", "C15.604.744.680"],
    synonyms: ["Splenic Neoplasm", "Malignant Tumour Of Spleen", "Spleen Neoplasm", "Malignant Splenic Tumor"],
  },
  {
    ui: "D013161",
    heading: "Splenic Rupture",
    tree_numbers: ["C15.604.744.742", "C26.017.680", "C26.761.555"],
  },
  {
    ui: "D013163",
    heading: "Splenomegaly",
    tree_numbers: ["C23.300.775.750"],
  },
  {
    ui: "D013166",
    heading: "Spondylitis",
    tree_numbers: ["C01.160.762", "C05.116.165.762", "C05.116.900.853"],
  },
  {
    ui: "D013167",
    heading: "Spondylitis, Ankylosing",
    tree_numbers: ["C05.116.900.853.625.800.850", "C05.550.069.680", "C05.550.114.865.800.850"],
    synonyms: ["Bekhterev's Disease", "Marie-Strumpell Disease", "Bekhterev Syndrome"],
  },
  {
    ui: "D013168",
    heading: "Spondylolisthesis",
    tree_numbers: ["C05.116.900.938.500.500"],
  },
  {
    ui: "D013169",
    heading: "Spondylolysis",
    tree_numbers: ["C05.116.900.938.500"],
  },
  {
    ui: "D013174",
    heading: "Sporotrichosis",
    tree_numbers: ["C01.150.703.302.675", "C01.800.200.675", "C17.800.838.208.675"],
  },
  {
    ui: "D013180",
    heading: "Sprains and Strains",
    tree_numbers: ["C26.844"],
  },
  {
    ui: "D013182",
    heading: "Sprue, Tropical",
    tree_numbers: ["C06.405.469.637.850", "C18.452.603.850"],
    synonyms: ["Tropical Steatorrhea", "Sprue - Tropical"],
  },
  {
    ui: "D013202",
    heading: "Staphylococcal Food Poisoning",
    tree_numbers: ["C01.150.252.410.868.806", "C25.723.415.846"],
    synonyms: ["Staphylococcal Toxaemia Due To Food", "Staphyloenterotoxicosis", "Staphylococcal Food Poisoning"],
  },
  {
    ui: "D013203",
    heading: "Staphylococcal Infections",
    tree_numbers: ["C01.150.252.410.868"],
  },
  {
    ui: "D013206",
    heading: "Staphylococcal Scalded Skin Syndrome",
    tree_numbers: [
      "C01.150.252.410.868.951.770",
      "C01.150.252.819.770.770",
      "C01.800.720.770.770",
      "C17.800.838.765.770.770",
    ],
    synonyms: [
      "Dermatitis Exfoliativa Neonatorum",
      "Scalded Skin Syndrome",
      "Ritter Disease",
      "Pemphigus Neonatorum",
      "Staphylococcal Scalded Skin Syndrome",
      "Toxic Epidermal Necrolysis, Subcorneal Type",
    ],
  },
  {
    ui: "D013207",
    heading: "Staphylococcal Skin Infections",
    tree_numbers: ["C01.150.252.410.868.951", "C01.150.252.819.770", "C01.800.720.770", "C17.800.838.765.770"],
  },
  {
    ui: "D013217",
    heading: "Starvation",
    tree_numbers: ["C18.654.521.750"],
  },
  {
    ui: "D013224",
    heading: "Status Asthmaticus",
    tree_numbers: ["C08.127.108.880", "C08.674.095.880", "C20.543.480.680.095.880"],
    synonyms: ["Severe Asthma Attack", "Asthma With Status Asthmaticus"],
  },
  {
    ui: "D013226",
    heading: "Status Epilepticus",
    tree_numbers: ["C10.597.742.785", "C23.888.592.742.785"],
    synonyms: ["Grand Mal Status"],
  },
  {
    ui: "D013231",
    heading: "Steatitis",
    tree_numbers: ["C18.654.521.500.133.841.682", "C22.880"],
  },
  {
    ui: "D013262",
    heading: "Stevens-Johnson Syndrome",
    tree_numbers: [
      "C07.465.864.500",
      "C17.800.174.600.900",
      "C17.800.229.400.683",
      "C17.800.865.475.683",
      "C20.543.206.380.900",
      "C25.100.468.380.900",
    ],
  },
  {
    ui: "D013271",
    heading: "Gastric Dilatation",
    tree_numbers: ["C06.405.748.300"],
    synonyms: ["Stomach Dilatation"],
  },
  {
    ui: "D013272",
    heading: "Stomach Diseases",
    tree_numbers: ["C06.405.748"],
    synonyms: ["Gastropathy", "Stomach Disorder"],
  },
  {
    ui: "D013273",
    heading: "Diverticulum, Stomach",
    tree_numbers: ["C06.405.205.282.750.687", "C23.300.415.687"],
  },
  {
    ui: "D013274",
    heading: "Stomach Neoplasms",
    tree_numbers: ["C04.588.274.476.767", "C06.301.371.767", "C06.405.249.767", "C06.405.748.789"],
    synonyms: ["Gastric Cancer", "Gastric Neoplasm"],
  },
  {
    ui: "D013275",
    heading: "Stomach Rupture",
    tree_numbers: ["C06.405.748.824", "C26.017.809", "C26.761.684"],
  },
  {
    ui: "D013276",
    heading: "Stomach Ulcer",
    tree_numbers: ["C06.405.469.275.800.849", "C06.405.748.586.849"],
    synonyms: [
      "Acute Gastric Ulcer Without Mention Of Hemorrhage Or Perforation, Without Mention Of Obstruction",
      "Chronic Gastric Ulcer Without Hemorrhage And Without Perforation",
      "Acute Gastric Ulcer With Hemorrhage, With Perforation And With Obstruction",
      "Acute Gastric Ulcer With Hemorrhage And Perforation",
      "Acute Gastric Ulcer With Hemorrhage And Perforation",
      "Bleeding Acute Gastric Ulcer",
      "Acute Gastric Ulcer With Perforation",
      "Acute Gastric Ulcer With Haemorrhage And Perforation",
      "Acute Gastric Ulcer With Hemorrhage And Obstruction",
      "Acute Gastric Ulcer With Hemorrhage And Obstruction",
      "Chronic Gastric Ulcer Without Mention Of Hemorrhage Or Perforation, With Obstruction",
      "Acute Gastric Ulcer With Hemorrhage, With Obstruction",
      "Acute Gastric Ulcer With Hemorrhage And Perforation, With Obstruction",
      "Acute Gastric Ulcer With Perforation And Obstruction",
      "Acute Gastric Ulcer Without Hemorrhage, Without Perforation And Without Obstruction",
      "Acute Gastric Ulcer Without Hemorrhage And Without Perforation",
      "Acute Gastric Ulcer With Hemorrhage And With Perforation But Without Obstruction",
      "Acute Gastric Ulcer With Hemorrhage And Perforation, Without Mention Of Obstruction",
      "Acute Gastric Ulcer With Perforation, With Obstruction",
      "Chronic Gastric Ulcer Without Hemorrhage And Without Perforation But With Obstruction",
    ],
  },
  {
    ui: "D013277",
    heading: "Stomach Volvulus",
    tree_numbers: ["C06.405.748.895"],
  },
  {
    ui: "D013280",
    heading: "Stomatitis",
    tree_numbers: ["C07.465.864"],
  },
  {
    ui: "D013281",
    heading: "Stomatitis, Aphthous",
    tree_numbers: ["C07.465.864.750"],
    synonyms: ["Aphtha", "Aphthous Ulceration", "Canker Sore", "Oral Ulcer", "Oral Aphthae"],
  },
  {
    ui: "D013282",
    heading: "Stomatitis, Denture",
    tree_numbers: ["C07.465.864.875"],
    synonyms: ["Denture Sore Mouth"],
  },
  {
    ui: "D013283",
    heading: "Stomatitis, Herpetic",
    tree_numbers: ["C01.925.256.466.382.834", "C07.465.864.937"],
  },
  {
    ui: "D013285",
    heading: "Strabismus",
    tree_numbers: ["C10.292.562.887", "C11.590.810"],
  },
  {
    ui: "D013290",
    heading: "Streptococcal Infections",
    tree_numbers: ["C01.150.252.410.890"],
  },
  {
    ui: "D013313",
    heading: "Stress Disorders, Post-Traumatic",
    tree_numbers: ["F03.950.750.500"],
    synonyms: ["Traumatic Neurosis", "Ptsd"],
  },
  {
    ui: "D013319",
    heading: "Strongyle Infections, Equine",
    tree_numbers: [
      "C01.610.335.349.840",
      "C01.610.335.508.700.775.773",
      "C01.610.701.377.840",
      "C22.488.861",
      "C22.674.377.840",
    ],
  },
  {
    ui: "D013322",
    heading: "Strongyloidiasis",
    tree_numbers: ["C01.610.335.508.700.700.799"],
    synonyms: ["Infection By Strongyloides", "Disseminated Strongyloidiasis"],
  },
  {
    ui: "D013330",
    heading: "Struma Ovarii",
    tree_numbers: ["C04.557.465.910.850"],
    synonyms: ["Struma Ovarii"],
  },
  {
    ui: "D013341",
    heading: "Sturge-Weber Syndrome",
    tree_numbers: ["C04.557.645.375.850", "C10.562.800", "C14.907.077.850"],
    synonyms: [
      "Encephalofacial Angiomatosis",
      "Sturge-Weber-Krabbe Syndrome",
      "Encephalotrigeminal Angiomatosis",
      "Meningeal Capillary Angiomatosis",
      "Sturge-Weber-Dimitri Syndrome",
      "Leptomeningeal Angiomatosis",
      "Sws",
      "Fourth Phacomatosis",
      "Sturge-Weber-Krabbe Angiomatosis",
    ],
  },
  {
    ui: "D013342",
    heading: "Stuttering",
    tree_numbers: ["C10.597.606.150.500.800.750", "C23.888.592.604.150.500.800.750"],
    synonyms: ["Familial Persistent Stuttering", "Stammering"],
  },
  {
    ui: "D013344",
    heading: "Subacute Sclerosing Panencephalitis",
    tree_numbers: [
      "C01.207.245.340.600",
      "C01.207.399.750.600",
      "C01.925.182.525.600",
      "C01.925.782.580.600.500.500.800",
      "C01.925.839.862",
      "C10.228.140.430.520.750.600",
      "C10.228.228.245.340.600",
      "C10.228.228.399.750.600",
    ],
    synonyms: [
      "Subacute Sclerosing Panencephalitis",
      "Van Bogaert's Sclerosing Leukoencephalitis",
      "Subacute Sclerosing Panencephalitis",
      "Immunosuppressive Measles Encephalitis",
      "Subacute Sclerosing Leukoencephalopathy",
    ],
  },
  {
    ui: "D013345",
    heading: "Subarachnoid Hemorrhage",
    tree_numbers: ["C10.228.140.300.535.800", "C14.907.253.573.800", "C23.550.414.913.850"],
  },
  {
    ui: "D013349",
    heading: "Subclavian Steal Syndrome",
    tree_numbers: ["C10.228.140.300.150.956.700", "C14.907.253.092.956.700"],
    synonyms: ["Subclavian Artery Stenosis", "Subclavian Steal Steno-Occlusive Disease", "Subclavian Steal Phenomenon"],
  },
  {
    ui: "D013352",
    heading: "Subcutaneous Emphysema",
    tree_numbers: ["C23.550.325.500"],
  },
  {
    ui: "D013353",
    heading: "Subdural Effusion",
    tree_numbers: ["C01.207.709.675", "C10.228.140.870", "C10.228.228.709.675"],
  },
  {
    ui: "D013354",
    heading: "Empyema, Subdural",
    tree_numbers: ["C01.207.709.300", "C01.830.305.330", "C10.228.228.709.300", "C23.550.470.756.305.500"],
    synonyms: ["Subdural Abscess"],
  },
  {
    ui: "D013362",
    heading: "Sublingual Gland Neoplasms",
    tree_numbers: ["C04.588.443.591.824.882", "C07.465.530.824.882", "C07.465.815.718.870"],
  },
  {
    ui: "D013364",
    heading: "Submandibular Gland Diseases",
    tree_numbers: ["C07.465.815.882"],
  },
  {
    ui: "D013365",
    heading: "Submandibular Gland Neoplasms",
    tree_numbers: ["C04.588.443.591.824.885", "C07.465.530.824.885", "C07.465.815.718.885", "C07.465.815.882.500"],
  },
  {
    ui: "D013369",
    heading: "Subphrenic Abscess",
    tree_numbers: ["C01.463.600.500", "C01.830.025.020.810", "C06.844.640.500"],
  },
  {
    ui: "D013375",
    heading: "Substance Withdrawal Syndrome",
    tree_numbers: ["C25.775.835", "F03.900.825"],
  },
  {
    ui: "D013398",
    heading: "Sudden Infant Death",
    tree_numbers: ["C23.550.260.322.625", "C23.550.260.657.500"],
    synonyms: [
      "Sids",
      "Sudden Death Of Nonspecific Cause In Infancy",
      "Crib Death",
      "Cot Death",
      "Sudden Infant Death Syndrome",
    ],
  },
  {
    ui: "D013436",
    heading: "Sulfhemoglobinemia",
    tree_numbers: ["C15.378.896"],
    synonyms: ["Sulfemoglobinemia", "Sulfhemoglobinemia"],
  },
  {
    ui: "D013471",
    heading: "Sunburn",
    tree_numbers: ["C17.800.600.725", "C26.200.855"],
  },
  {
    ui: "D013474",
    heading: "Sunstroke",
    tree_numbers: ["C26.522.500.500"],
  },
  {
    ui: "D013478",
    heading: "Superior Mesenteric Artery Syndrome",
    tree_numbers: ["C06.405.469.275.395.890"],
    synonyms: ["Superior Mesenteric Artery Syndrome"],
  },
  {
    ui: "D013479",
    heading: "Superior Vena Cava Syndrome",
    tree_numbers: ["C14.907.800"],
  },
  {
    ui: "D013492",
    heading: "Suppuration",
    tree_numbers: ["C01.830", "C23.550.470.756"],
  },
  {
    ui: "D013494",
    heading: "Supranuclear Palsy, Progressive",
    tree_numbers: [
      "C10.228.140.079.882",
      "C10.228.662.700",
      "C10.292.562.750.500",
      "C10.574.945.500",
      "C10.597.622.447.690",
      "C11.590.472.500",
      "C23.888.592.636.447.690",
    ],
    synonyms: ["Steele-Richardson-Olszewski Syndrome", "Progressive Supranuclear Ophthalmoplegia"],
  },
  {
    ui: "D013529",
    heading: "Surgical Wound Dehiscence",
    tree_numbers: ["C23.550.767.887"],
  },
  {
    ui: "D013530",
    heading: "Surgical Wound Infection",
    tree_numbers: ["C01.947.692", "C23.550.767.925"],
  },
  {
    ui: "D013540",
    heading: "Swayback",
    tree_numbers: ["C18.654.521.500.857", "C22.836.886"],
    synonyms: ["Human Swayback"],
  },
  {
    ui: "D013543",
    heading: "Sweat Gland Diseases",
    tree_numbers: ["C17.800.946"],
  },
  {
    ui: "D013544",
    heading: "Sweat Gland Neoplasms",
    tree_numbers: ["C04.588.805.776", "C17.800.882.743", "C17.800.946.743"],
    synonyms: ["Sweat Gland Tumor", "Tumor Of The Sweat Gland", "Neoplasm Of Sweat Gland"],
  },
  {
    ui: "D013547",
    heading: "Sweating, Gustatory",
    tree_numbers: ["C10.177.825", "C17.800.946.350.843"],
    synonyms: ["Gustatory Hyperhidrosis", "Baillarger Syndrome", "Gustatory Sweating"],
  },
  {
    ui: "D013553",
    heading: "Swine Diseases",
    tree_numbers: ["C22.905"],
  },
  {
    ui: "D013554",
    heading: "Swine Erysipelas",
    tree_numbers: ["C01.150.252.410.334.776", "C22.331.693", "C22.905.832"],
  },
  {
    ui: "D013555",
    heading: "Swine Vesicular Disease",
    tree_numbers: ["C01.925.782.687.359.855", "C22.905.850"],
  },
  {
    ui: "D013568",
    heading: "Pathological Conditions, Signs and Symptoms",
    tree_numbers: ["C23"],
  },
  {
    ui: "D013575",
    heading: "Syncope",
    tree_numbers: ["C10.597.606.358.800.600", "C23.888.592.604.359.800.600"],
  },
  {
    ui: "D013576",
    heading: "Syndactyly",
    tree_numbers: [
      "C05.116.099.370.894.819",
      "C05.660.585.800",
      "C05.660.906.819",
      "C16.131.621.585.800",
      "C16.131.621.906.819",
    ],
    synonyms: ["Symphalangism", "Webbing Of Digits", "Chromosome 2q35 Duplication Syndrome", "Symphalangy"],
  },
  {
    ui: "D013577",
    heading: "Syndrome",
    tree_numbers: ["C23.550.288.500"],
  },
  {
    ui: "D013580",
    heading: "Synostosis",
    tree_numbers: ["C05.116.099.370.894", "C05.660.906", "C16.131.621.906"],
  },
  {
    ui: "D013581",
    heading: "Synovial Cyst",
    tree_numbers: ["C04.182.867"],
  },
  {
    ui: "D013584",
    heading: "Sarcoma, Synovial",
    tree_numbers: ["C04.557.450.565.835", "C04.557.450.795.875"],
  },
  {
    ui: "D013585",
    heading: "Synovitis",
    tree_numbers: ["C05.550.870"],
  },
  {
    ui: "D013586",
    heading: "Synovitis, Pigmented Villonodular",
    tree_numbers: ["C04.557.450.565.380.690.500", "C05.550.870.445.500", "C05.651.869.762.500"],
    synonyms: ["Villous Tenosynovitis", "Diffuse Giant Cell Tumor Of Tenosynovium"],
  },
  {
    ui: "D013587",
    heading: "Syphilis",
    tree_numbers: [
      "C01.150.252.400.794.840.500",
      "C01.150.252.400.840.500",
      "C01.150.252.734.859",
      "C01.221.812.281.859",
      "C01.778.281.859",
      "C12.294.668.281.859",
      "C13.351.500.711.281.859",
    ],
  },
  {
    ui: "D013589",
    heading: "Syphilis, Cardiovascular",
    tree_numbers: ["C01.150.252.400.794.840.500.875", "C01.150.252.400.840.500.875", "C01.190.500", "C14.260.500"],
  },
  {
    ui: "D013590",
    heading: "Syphilis, Congenital",
    tree_numbers: ["C01.150.252.400.794.840.500.937", "C01.150.252.400.840.500.937", "C16.614.868"],
  },
  {
    ui: "D013591",
    heading: "Syphilis, Cutaneous",
    tree_numbers: [
      "C01.150.252.400.794.840.500.968",
      "C01.150.252.400.840.500.968",
      "C01.150.252.819.790",
      "C01.800.720.790",
      "C17.800.838.765.790",
    ],
  },
  {
    ui: "D013592",
    heading: "Syphilis, Latent",
    tree_numbers: ["C01.150.252.400.794.840.500.984", "C01.150.252.400.840.500.984", "C01.550.750"],
  },
  {
    ui: "D013595",
    heading: "Syringomyelia",
    tree_numbers: ["C10.228.854.833"],
  },
  {
    ui: "D013606",
    heading: "Tabes Dorsalis",
    tree_numbers: [
      "C01.150.252.223.600.800",
      "C01.150.252.400.794.840.500.750.800",
      "C01.150.252.400.840.500.750.800",
      "C01.207.180.600.800",
      "C10.228.228.180.600.800",
      "C10.228.854.889",
    ],
    synonyms: ["Posterior Spinal Sclerosis", "Tabes Dorsalis - Neurosyphilis"],
  },
  {
    ui: "D013610",
    heading: "Tachycardia",
    tree_numbers: ["C14.280.067.845", "C14.280.123.875", "C23.550.073.845"],
  },
  {
    ui: "D013611",
    heading: "Tachycardia, Atrioventricular Nodal Reentry",
    tree_numbers: ["C14.280.067.845.787.249", "C14.280.123.875.787.249", "C23.550.073.845.787.500"],
  },
  {
    ui: "D013612",
    heading: "Tachycardia, Ectopic Atrial",
    tree_numbers: ["C14.280.067.845.880.315", "C14.280.123.875.880.315", "C23.550.073.845.880.315"],
  },
  {
    ui: "D013613",
    heading: "Tachycardia, Ectopic Junctional",
    tree_numbers: ["C14.280.067.845.880.320", "C14.280.123.875.880.320", "C23.550.073.845.880.320"],
  },
  {
    ui: "D013614",
    heading: "Tachycardia, Paroxysmal",
    tree_numbers: ["C14.280.067.845.695", "C14.280.123.875.695", "C23.550.073.845.695"],
  },
  {
    ui: "D013615",
    heading: "Tachycardia, Sinoatrial Nodal Reentry",
    tree_numbers: ["C14.280.067.845.787.500", "C14.280.123.875.787.500", "C23.550.073.845.787.750"],
  },
  {
    ui: "D013616",
    heading: "Tachycardia, Sinus",
    tree_numbers: ["C14.280.067.845.880.845", "C14.280.123.875.880.845", "C23.550.073.845.880.845"],
  },
  {
    ui: "D013617",
    heading: "Tachycardia, Supraventricular",
    tree_numbers: ["C14.280.067.845.880", "C14.280.123.875.880", "C23.550.073.845.880"],
  },
  {
    ui: "D013622",
    heading: "Taeniasis",
    tree_numbers: ["C01.610.335.190.902"],
  },
  {
    ui: "D013625",
    heading: "Takayasu Arteritis",
    tree_numbers: ["C14.907.109.239.650", "C14.907.940.090.800", "C17.800.862.875"],
    synonyms: [
      "Takayasu Arteritis",
      "Idiopathic Aortitis",
      "Aortic Arch Syndrome",
      "Aortic Arch Arteritis",
      "Takayasu's Disease",
      "Aortic Arch Syndrome",
    ],
  },
  {
    ui: "D013631",
    heading: "Tangier Disease",
    tree_numbers: [
      "C10.668.829.800.875",
      "C16.320.565.398.500.330.750",
      "C18.452.584.500.875.330.750",
      "C18.452.648.398.500.330.750",
    ],
    synonyms: ["Familial High Density Lipoprotein Deficiency", "Familial Alpha-Lipoprotein Deficiency"],
  },
  {
    ui: "D013641",
    heading: "Tarsal Tunnel Syndrome",
    tree_numbers: ["C10.668.829.500.700.800", "C10.668.829.550.800"],
  },
  {
    ui: "D013651",
    heading: "Taste Disorders",
    tree_numbers: ["C10.597.751.861", "C23.888.592.763.861"],
  },
  {
    ui: "D013661",
    heading: "Tay-Sachs Disease",
    tree_numbers: [
      "C10.228.140.163.100.435.825.300.300.500",
      "C16.320.565.189.435.825.300.300.500",
      "C16.320.565.398.641.803.350.300.850",
      "C16.320.565.595.554.825.300.300.840",
      "C18.452.132.100.435.825.300.300.500",
      "C18.452.584.687.803.350.300.850",
      "C18.452.648.189.435.825.300.300.500",
      "C18.452.648.398.641.803.350.300.850",
      "C18.452.648.595.554.825.300.300.840",
    ],
    synonyms: ["Hexosaminidase A Deficiency", "Gm2 Gangliosidosis, Type 1"],
  },
  {
    ui: "D013683",
    heading: "Telangiectasia, Hereditary Hemorrhagic",
    tree_numbers: ["C14.907.454.900", "C14.907.823.780", "C15.378.463.515.900", "C16.131.240.850.968"],
    synonyms: ["Osler-Weber-Rendu Disease", "Rendu-Osler-Weber Disease", "Osler Hemorrhagic Telangiectasia Syndrome"],
  },
  {
    ui: "D013684",
    heading: "Telangiectasis",
    tree_numbers: ["C14.907.823"],
    synonyms: ["Telangiectasia"],
  },
  {
    ui: "D013700",
    heading: "Giant Cell Arteritis",
    tree_numbers: [
      "C10.114.875.700",
      "C10.228.140.300.850.500",
      "C14.907.253.946.700",
      "C14.907.940.090.530",
      "C14.907.940.907.700",
      "C17.800.862.252",
      "C20.111.258.962.800",
    ],
    synonyms: [
      "Giant Cell Arteritis",
      "Giant Cell Arteritis",
      "Giant Cell Arteritis",
      "Horton's Disease",
      "Temporal Arteritis",
    ],
  },
  {
    ui: "D013705",
    heading: "Temporomandibular Joint Disorders",
    tree_numbers: ["C05.500.607.221.897", "C05.550.905", "C05.651.243.897", "C07.320.610.291.897", "C07.678"],
  },
  {
    ui: "D013706",
    heading: "Temporomandibular Joint Dysfunction Syndrome",
    tree_numbers: [
      "C05.500.607.221.897.897",
      "C05.550.905.905",
      "C05.651.243.897.897",
      "C05.651.550.905",
      "C07.320.610.291.897.897",
      "C07.678.949",
    ],
  },
  {
    ui: "D013708",
    heading: "Tendon Injuries",
    tree_numbers: ["C26.874"],
  },
  {
    ui: "D013716",
    heading: "Tennis Elbow",
    tree_numbers: ["C05.651.869.435.500", "C26.088.890", "C26.874.800.500.500"],
    synonyms: [
      "Medial Epicondylitis",
      "Lateral Epicondylitis",
      "Golfer's Elbow",
      "Andrel Epicondylitis",
      "Archer's Elbow",
      "Hockey Elbow",
    ],
  },
  {
    ui: "D013717",
    heading: "Tenosynovitis",
    tree_numbers: ["C05.651.869.870"],
    synonyms: ["Inflammation Of Tendon Sheath"],
  },
  {
    ui: "D013724",
    heading: "Teratoma",
    tree_numbers: ["C04.557.465.910"],
    synonyms: [
      "Immature Teratoma",
      "Teratoma, Malignant, Nos",
      "Malignant Extragonadal Teratoma",
      "Primary Malignant Extragonadal Teratoma",
    ],
  },
  {
    ui: "D013733",
    heading: "Testicular Diseases",
    tree_numbers: ["C12.294.829", "C19.391.829"],
    synonyms: ["Testis Disorder", "Disorder Of Testis"],
  },
  {
    ui: "D013734",
    heading: "Androgen-Insensitivity Syndrome",
    tree_numbers: [
      "C12.706.316.096.500",
      "C13.351.875.253.096.500",
      "C16.131.939.316.096.500",
      "C16.320.322.061",
      "C19.391.119.096.500",
    ],
    synonyms: [
      "Androgen Resistance Syndrome",
      "Goldberg-Maxwell Syndrome",
      "Androgen-Insensitivity Syndrome",
      "Testicular Feminization Syndrome",
      "Feminisation - Testicular",
      "Goldberg - Maxwell Syndrome",
      "Testicular Feminization",
    ],
  },
  {
    ui: "D013736",
    heading: "Testicular Neoplasms",
    tree_numbers: [
      "C04.588.322.762",
      "C04.588.945.440.915",
      "C12.294.260.937",
      "C12.758.409.937",
      "C19.344.762",
      "C19.391.829.782",
    ],
    synonyms: [
      "Testis Cancer",
      "Neoplasm Of Testis",
      "Testis Neoplasm",
      "Testicular Tumor",
      "Childhood Neoplasm Of The Testis",
      "Pediatric Testicular Neoplasm",
    ],
  },
  {
    ui: "D013742",
    heading: "Tetanus",
    tree_numbers: ["C01.150.252.410.222.864"],
    synonyms: ["Infection Due To Clostridium Tetani", "Clostridial Tetanus"],
  },
  {
    ui: "D013746",
    heading: "Tetany",
    tree_numbers: ["C10.597.613.875", "C18.452.174.509.700", "C23.888.592.608.875"],
  },
  {
    ui: "D013771",
    heading: "Tetralogy of Fallot",
    tree_numbers: ["C14.240.400.849", "C14.280.400.849", "C16.131.240.400.849"],
    synonyms: [
      "Ventricular Septal Defect With Pulmonary Stenosis Or Atresia, Dextraposition Of Aorta, And Hypertrophy Of Right Ventricle",
    ],
  },
  {
    ui: "D013786",
    heading: "Thalamic Diseases",
    tree_numbers: ["C10.228.140.915"],
  },
  {
    ui: "D013789",
    heading: "Thalassemia",
    tree_numbers: ["C15.378.071.141.150.875", "C15.378.420.826", "C16.320.070.875", "C16.320.365.826"],
    synonyms: [
      "Thalassemia Hb-S Disease Without Crisis",
      "Sickle-Cell Thalassemia Without Crisis",
      "Sickle-Cell Thalassemia With Crisis",
      "Thalassemia Hb-S Disease With Crisis",
    ],
  },
  {
    ui: "D013796",
    heading: "Thanatophoric Dysplasia",
    tree_numbers: [
      "C05.116.099.343.110.500",
      "C05.116.099.708.017.500",
      "C05.660.585.984",
      "C16.131.621.585.984",
      "C16.320.240.500.500",
      "C16.614.890",
    ],
  },
  {
    ui: "D013798",
    heading: "Thecoma",
    tree_numbers: [
      "C04.557.475.750.875",
      "C04.588.322.455.765",
      "C13.351.500.056.630.705.765",
      "C13.351.937.418.685.765",
      "C19.344.410.765",
      "C19.391.630.705.765",
    ],
  },
  {
    ui: "D013801",
    heading: "Theileriasis",
    tree_numbers: [
      "C01.610.701.688.735",
      "C01.610.752.625.735",
      "C01.610.752.750",
      "C01.920.930.915",
      "C22.196.831",
      "C22.674.710.735",
    ],
    synonyms: ["Theileriosis", "Infection By Theileria"],
  },
  {
    ui: "D013832",
    heading: "Thiamine Deficiency",
    tree_numbers: ["C18.654.521.500.133.699.827"],
  },
  {
    ui: "D013851",
    heading: "Thinness",
    tree_numbers: ["C23.888.144.828", "E01.370.600.115.100.160.120.828", "G07.100.100.160.120.828"],
  },
  {
    ui: "D013896",
    heading: "Thoracic Diseases",
    tree_numbers: ["C08.846"],
  },
  {
    ui: "D013898",
    heading: "Thoracic Injuries",
    tree_numbers: ["C26.891"],
  },
  {
    ui: "D013899",
    heading: "Thoracic Neoplasms",
    tree_numbers: ["C04.588.894"],
    synonyms: ["Thorax Neoplasm", "Thoracic Tumor", "Thorax Cancer", "Tumor Of Thorax"],
  },
  {
    ui: "D013901",
    heading: "Thoracic Outlet Syndrome",
    tree_numbers: ["C10.668.829.550.850", "C14.907.863"],
    synonyms: ["Tos - Thoracic Outlet Syndrome"],
  },
  {
    ui: "D013915",
    heading: "Thrombasthenia",
    tree_numbers: ["C15.378.100.100.820", "C15.378.140.810", "C15.378.463.810", "C16.320.099.820"],
    synonyms: [
      "Deficiency Of Glycoprotein Complex Iib-Iiia",
      "Thrombasthenia Of Glanzmann And Naegeli",
      "Deficiency Of Platelet Fibrinogen Receptor",
      "Glanzmann Thrombasthenia",
      "Platelet-Type Bleeding Disorder 2",
      "Deficiency Of Gp Iib-Iiia Complex",
      "Thrombocytasthenia",
      "Bdplt2",
      "Platelet Glycoprotein Iib-Iiia Deficiency",
      "Glycoprotein Iib/iiia Defect",
    ],
  },
  {
    ui: "D013919",
    heading: "Thromboangiitis Obliterans",
    tree_numbers: ["C14.907.137.870", "C14.907.940.905"],
    synonyms: ["Thromboangiitis Obliterans", "Presenile Gangrene", "Buerger's Disease"],
  },
  {
    ui: "D013920",
    heading: "Thrombocythemia, Essential",
    tree_numbers: ["C15.378.100.832", "C15.378.140.860.800", "C15.378.190.636.860.800", "C15.378.463.825"],
    synonyms: [
      "Primary Thrombocytosis",
      "Hemorrhagic Thrombocythemia",
      "Familial Thrombocytosis",
      "Hereditary Thrombocythemia",
      "Essential Thrombocythaemia",
    ],
  },
  {
    ui: "D013921",
    heading: "Thrombocytopenia",
    tree_numbers: ["C15.378.140.855"],
  },
  {
    ui: "D013922",
    heading: "Thrombocytosis",
    tree_numbers: ["C15.378.140.860", "C15.378.190.636.860"],
    synonyms: ["Thrombocythaemia"],
  },
  {
    ui: "D013923",
    heading: "Thromboembolism",
    tree_numbers: ["C14.907.355.590"],
  },
  {
    ui: "D013924",
    heading: "Thrombophlebitis",
    tree_numbers: ["C14.907.355.830.925.770", "C14.907.617.718.788", "C14.907.940.740.910"],
    synonyms: [
      "Phlebitis And Thrombophlebitis Of Superficial Vessels Of Lower Extremities",
      "Thrombophlebitis Of A Superficial Leg Vein",
      "Superficial Thrombophlebitis Of Leg",
      "Thrombophlebitis Of Superficial Veins Of Lower Extremity",
    ],
  },
  {
    ui: "D013927",
    heading: "Thrombosis",
    tree_numbers: ["C14.907.355.830"],
  },
  {
    ui: "D013945",
    heading: "Thymoma",
    tree_numbers: ["C04.557.435.850", "C04.588.894.949.500", "C15.604.861.800"],
    synonyms: ["Thymoma, Malignant", "Malignant Thymoma", "Thymic Carcinoma"],
  },
  {
    ui: "D013952",
    heading: "Thymus Hyperplasia",
    tree_numbers: ["C15.604.816"],
  },
  {
    ui: "D013953",
    heading: "Thymus Neoplasms",
    tree_numbers: ["C04.588.894.949", "C15.604.861"],
    synonyms: ["Neoplasm Of Thymus", "Thymic Tumor", "Thymic Neoplasm"],
  },
  {
    ui: "D013955",
    heading: "Thyroglossal Cyst",
    tree_numbers: ["C04.182.902"],
  },
  {
    ui: "D013958",
    heading: "Thyroid Crisis",
    tree_numbers: ["C19.874.397.685.905"],
    synonyms: ["Thyrotoxic Crisis", "Thyroid Crisis"],
  },
  {
    ui: "D013959",
    heading: "Thyroid Diseases",
    tree_numbers: ["C19.874"],
  },
  {
    ui: "D013964",
    heading: "Thyroid Neoplasms",
    tree_numbers: ["C04.588.322.894", "C04.588.443.915", "C19.344.894", "C19.874.788"],
    synonyms: ["Adenoma Of Thyroid Gland"],
  },
  {
    ui: "D013966",
    heading: "Thyroiditis",
    tree_numbers: ["C19.874.871"],
  },
  {
    ui: "D013967",
    heading: "Thyroiditis, Autoimmune",
    tree_numbers: ["C19.874.871.102", "C20.111.809"],
    synonyms: [
      "Hashimoto Thyroiditis",
      "Hashimoto's Thyroiditis",
      "Chronic Lymphocytic Thyroiditis",
      "Hashimoto's Syndrome",
      "Hashimoto's Disease",
      "Lymphocytic Thyroiditis",
    ],
  },
  {
    ui: "D013968",
    heading: "Thyroiditis, Subacute",
    tree_numbers: ["C19.874.871.900"],
    synonyms: [
      "Giant-Cell Thyroiditis",
      "Subacute Granulomatous Thyroiditis",
      "Granulomatous Thyroiditis",
      "Subacute Thyroiditis",
      "Subacute Thyroiditis",
      "De Quervain's Thyroiditis",
      "De Quervain's Thyroiditis",
    ],
  },
  {
    ui: "D013969",
    heading: "Thyroiditis, Suppurative",
    tree_numbers: ["C01.830.840", "C19.874.871.910"],
    synonyms: ["Acute Suppurative Thyroiditis", "Suppurative Thyroiditis", "Infectious Thyroiditis"],
  },
  {
    ui: "D013971",
    heading: "Thyrotoxicosis",
    tree_numbers: ["C19.874.397.685"],
  },
  {
    ui: "D013978",
    heading: "Tibial Fractures",
    tree_numbers: ["C26.404.875", "C26.558.857"],
  },
  {
    ui: "D013981",
    heading: "Tic Disorders",
    tree_numbers: ["C10.228.662.825", "F03.625.992"],
    synonyms: ["Chronic Motor Or Vocal Tic Disorder"],
  },
  {
    ui: "D013984",
    heading: "Tick Infestations",
    tree_numbers: ["C01.610.858.211.857"],
  },
  {
    ui: "D013985",
    heading: "Tick Paralysis",
    tree_numbers: ["C25.723.127.789.500.707"],
  },
  {
    ui: "D013986",
    heading: "Tick Toxicoses",
    tree_numbers: ["C25.723.127.789.500"],
  },
  {
    ui: "D013991",
    heading: "Tietze's Syndrome",
    tree_numbers: ["C05.182.790", "C17.300.182.790"],
    synonyms: [
      "Costochondritis",
      "Costochondral Junction Syndrome",
      "Slipping Rib Syndrome",
      "Tietze's Disease",
      "Costalchondritis",
    ],
  },
  {
    ui: "D014005",
    heading: "Tinea",
    tree_numbers: ["C01.150.703.302.720", "C01.800.200.720", "C17.800.838.208.883"],
  },
  {
    ui: "D014006",
    heading: "Tinea Capitis",
    tree_numbers: ["C01.150.703.302.720.730", "C01.800.200.720.730", "C17.800.738.708", "C17.800.838.208.883.558"],
  },
  {
    ui: "D014007",
    heading: "Tinea Favosa",
    tree_numbers: [
      "C01.150.703.302.720.730.740",
      "C01.800.200.720.730.740",
      "C17.800.738.708.708",
      "C17.800.838.208.883.558.708",
    ],
    synonyms: ["Favus"],
  },
  {
    ui: "D014008",
    heading: "Tinea Pedis",
    tree_numbers: [
      "C01.150.703.302.720.760",
      "C01.800.200.720.760",
      "C17.800.321.125.500",
      "C17.800.838.208.883.658",
      "C23.888.885.625.500",
    ],
    synonyms: ["Dermatophytosis Of Foot", "Athlete's Foot"],
  },
  {
    ui: "D014009",
    heading: "Onychomycosis",
    tree_numbers: ["C01.150.703.302.720.550", "C01.800.200.720.550", "C17.800.529.550", "C17.800.838.208.883.458"],
  },
  {
    ui: "D014010",
    heading: "Tinea Versicolor",
    tree_numbers: ["C01.150.703.302.860", "C01.800.200.860", "C17.800.838.208.941"],
    synonyms: ["Pityriasis Versicolor", "Infection By Pityrosporum Furfur"],
  },
  {
    ui: "D014012",
    heading: "Tinnitus",
    tree_numbers: ["C09.218.458.670", "C10.597.751.418.670", "C23.888.592.763.393.670"],
  },
  {
    ui: "D014029",
    heading: "Tobacco Use Disorder",
    tree_numbers: ["C25.775.912", "F03.900.912"],
    synonyms: ["Tobacco Use Disorder"],
  },
  {
    ui: "D014036",
    heading: "Togaviridae Infections",
    tree_numbers: ["C01.925.782.930"],
  },
  {
    ui: "D014060",
    heading: "Tongue Diseases",
    tree_numbers: ["C07.465.910"],
  },
  {
    ui: "D014062",
    heading: "Tongue Neoplasms",
    tree_numbers: ["C04.588.443.591.925", "C07.465.530.925", "C07.465.910.470"],
    synonyms: ["Malignant Neoplasm Of Tongue"],
  },
  {
    ui: "D014063",
    heading: "Tongue, Fissured",
    tree_numbers: ["C07.465.910.708"],
    synonyms: [
      "Congenital Fissure Of Tongue",
      "Lingua Plicata",
      "Fissure Of Tongue",
      "Fissure Of Tongue, Congenital",
      "Scrotal Tongue",
      "Furrowed Tongue",
      "Geographic Tongue And Fissured Tongue",
      "Congenital Plicated Tongue",
      "Plicated Tongue",
      "Tongue, Fissured",
    ],
  },
  {
    ui: "D014064",
    heading: "Tongue, Hairy",
    tree_numbers: ["C07.465.910.791"],
    synonyms: ["Overgrowth Of Filiform Papillae", "Hairy Tongue"],
  },
  {
    ui: "D014067",
    heading: "Tonsillar Neoplasms",
    tree_numbers: ["C04.588.443.665.710.684.800", "C07.550.745.671.800", "C09.647.710.685.800", "C09.775.549.685.800"],
    synonyms: [
      "Malignant Tonsillar Tumor",
      "Malignant Neoplasm Of Palatine Tonsil",
      "Malignant Neoplasm Of Tonsil, Faucial",
      "Malignant Tumor Of Tonsil",
      "Malignant Neoplasm Of Faucial Tonsil",
    ],
  },
  {
    ui: "D014069",
    heading: "Tonsillitis",
    tree_numbers: ["C01.748.561.750", "C07.550.781.750", "C08.730.561.750", "C09.775.649.750"],
    synonyms: ["Chronic Tonsillitis", "Throat Infection - Tonsillitis"],
  },
  {
    ui: "D014071",
    heading: "Tooth Abnormalities",
    tree_numbers: ["C07.650.800", "C07.793.700", "C16.131.850.800"],
  },
  {
    ui: "D014072",
    heading: "Tooth Abrasion",
    tree_numbers: ["C07.793.818.124"],
  },
  {
    ui: "D014075",
    heading: "Tooth Discoloration",
    tree_numbers: ["C07.793.735"],
  },
  {
    ui: "D014076",
    heading: "Tooth Diseases",
    tree_numbers: ["C07.793"],
    synonyms: ["Teeth Disease"],
  },
  {
    ui: "D014077",
    heading: "Tooth Erosion",
    tree_numbers: ["C07.793.818.500"],
    synonyms: ["Generalized Erosion", "Localized Erosion"],
  },
  {
    ui: "D014079",
    heading: "Tooth Eruption, Ectopic",
    tree_numbers: ["C07.793.790"],
  },
  {
    ui: "D014082",
    heading: "Tooth Fractures",
    tree_numbers: ["C07.793.850.750", "C26.900.750"],
  },
  {
    ui: "D014084",
    heading: "Tooth Avulsion",
    tree_numbers: ["C07.793.850.725", "C26.900.725"],
  },
  {
    ui: "D014085",
    heading: "Tooth Migration",
    tree_numbers: ["C07.465.714.836", "G10.549.830"],
  },
  {
    ui: "D014086",
    heading: "Tooth Mobility",
    tree_numbers: ["C07.465.714.898", "G10.549.840"],
  },
  {
    ui: "D014091",
    heading: "Tooth Resorption",
    tree_numbers: ["C07.793.901", "G10.549.855"],
  },
  {
    ui: "D014095",
    heading: "Tooth, Impacted",
    tree_numbers: ["C07.793.905"],
  },
  {
    ui: "D014096",
    heading: "Tooth, Supernumerary",
    tree_numbers: ["C07.650.800.850", "C07.793.700.850", "C16.131.850.800.850"],
  },
  {
    ui: "D014097",
    heading: "Tooth, Unerupted",
    tree_numbers: ["A14.549.167.860.715", "C07.793.915"],
  },
  {
    ui: "D014098",
    heading: "Toothache",
    tree_numbers: ["C07.793.929", "C23.888.592.612.330.500"],
  },
  {
    ui: "D014102",
    heading: "Torsion Abnormality",
    tree_numbers: ["C23.300.970"],
  },
  {
    ui: "D014103",
    heading: "Torticollis",
    tree_numbers: ["C23.888.592.350.300.800"],
  },
  {
    ui: "D014115",
    heading: "Toxemia",
    tree_numbers: ["C01.861"],
  },
  {
    ui: "D014120",
    heading: "Toxocariasis",
    tree_numbers: ["C01.610.335.349.868", "C01.610.335.508.700.100.868", "C01.610.701.377.868", "C22.674.377.868"],
  },
  {
    ui: "D014123",
    heading: "Toxoplasmosis",
    tree_numbers: ["C01.610.752.250.800"],
    synonyms: ["Disseminated Toxoplasmosis"],
  },
  {
    ui: "D014124",
    heading: "Toxoplasmosis, Animal",
    tree_numbers: ["C01.610.701.688.817", "C01.610.752.250.800.110", "C01.610.752.625.817", "C22.674.710.817"],
  },
  {
    ui: "D014125",
    heading: "Toxoplasmosis, Congenital",
    tree_numbers: ["C01.207.205.300.900", "C01.610.752.250.800.445", "C10.228.228.205.300.900", "C16.614.909"],
    synonyms: ["Toxoplasmosis - Congen.", "Congenital Toxoplasmosis"],
  },
  {
    ui: "D014126",
    heading: "Toxoplasmosis, Ocular",
    tree_numbers: ["C01.610.300.781", "C01.610.752.250.800.640", "C11.294.725.781"],
  },
  {
    ui: "D014133",
    heading: "Tracheal Diseases",
    tree_numbers: ["C08.907"],
  },
  {
    ui: "D014134",
    heading: "Tracheal Neoplasms",
    tree_numbers: ["C04.588.443.925", "C04.588.894.797.760", "C08.785.760", "C08.907.563"],
    synonyms: ["Leiomyoma Of The Trachea"],
  },
  {
    ui: "D014135",
    heading: "Tracheal Stenosis",
    tree_numbers: ["C08.907.663"],
    synonyms: ["Stenosis Of Trachea"],
  },
  {
    ui: "D014136",
    heading: "Tracheitis",
    tree_numbers: ["C01.748.848", "C08.730.848", "C08.907.763"],
    synonyms: ["Chronic Tracheitis", "Acute Tracheitis"],
  },
  {
    ui: "D014137",
    heading: "Tracheobronchomegaly",
    tree_numbers: ["C08.127.930", "C08.695.830", "C08.907.830", "C16.131.740.830"],
  },
  {
    ui: "D014138",
    heading: "Tracheoesophageal Fistula",
    tree_numbers: ["C06.267.250.725", "C06.405.117.367.725", "C08.702.750", "C08.907.863", "C23.300.575.185.250.725"],
  },
  {
    ui: "D014141",
    heading: "Trachoma",
    tree_numbers: [
      "C01.150.252.289.225.800",
      "C01.150.252.400.210.125.745",
      "C01.375.354.220.800",
      "C11.187.183.220.889",
      "C11.204.813",
      "C11.294.354.220.800",
    ],
    synonyms: ["Trachoma Dubium", "Active Stage Trachoma"],
  },
  {
    ui: "D014178",
    heading: "Translocation, Genetic",
    tree_numbers: ["C23.550.210.870", "G05.365.590.175.870", "G05.558.860"],
  },
  {
    ui: "D014188",
    heading: "Transposition of Great Vessels",
    tree_numbers: ["C14.240.400.915", "C14.280.400.915", "C16.131.240.400.915"],
  },
  {
    ui: "D014190",
    heading: "Transvestism",
    tree_numbers: ["F03.657.800"],
    synonyms: ["Fetishistic Transvestism", "Transvestic Fetishism", "Dual-Role Transvestism", "Cross Dresser"],
  },
  {
    ui: "D014201",
    heading: "Trematode Infections",
    tree_numbers: ["C01.610.335.865"],
    synonyms: ["Infection By Fasciolopsis Buski", "Infectious Disease By Fasciolopsis"],
  },
  {
    ui: "D014202",
    heading: "Tremor",
    tree_numbers: ["C10.597.350.850", "C23.888.592.350.850"],
  },
  {
    ui: "D014205",
    heading: "Trench Fever",
    tree_numbers: ["C01.150.252.400.126.100.800"],
    synonyms: ["Shin Bone Fever", "Tibialgic Fever", "His-Werner Disease", "Wolhynian Fever", "Quintan Fever"],
  },
  {
    ui: "D014211",
    heading: "Treponemal Infections",
    tree_numbers: ["C01.150.252.400.794.840", "C01.150.252.400.840"],
    synonyms: ["Njovera", "Nonvenereal Endemic Syphilis"],
  },
  {
    ui: "D014235",
    heading: "Trichinellosis",
    tree_numbers: ["C01.610.335.508.100.275.882"],
    synonyms: ["Trichinella Spiralis Infection"],
  },
  {
    ui: "D014245",
    heading: "Trichomonas Infections",
    tree_numbers: ["C01.610.752.890"],
    synonyms: [],
  },
  {
    ui: "D014247",
    heading: "Trichomonas Vaginitis",
    tree_numbers: ["C01.610.752.890.633", "C13.351.500.894.906.633"],
    synonyms: ["Urogenital Trichomonas"],
  },
  {
    ui: "D014252",
    heading: "Trichostrongyloidiasis",
    tree_numbers: ["C01.610.335.508.700.775.825"],
  },
  {
    ui: "D014253",
    heading: "Trichostrongylosis",
    tree_numbers: ["C01.610.335.508.700.775.825.842"],
    synonyms: ["Trichostrongyliasis", "Infection By Trichostrongylus", "Infection By Trichostrongylus Species"],
  },
  {
    ui: "D014256",
    heading: "Trichotillomania",
    tree_numbers: ["F03.250.800"],
  },
  {
    ui: "D014257",
    heading: "Trichuriasis",
    tree_numbers: ["C01.610.335.508.100.275.895"],
    synonyms: [
      "Infection By Trichuris Trichura",
      "Trichuris Trichiura Infection",
      "Whipworm Disease",
      "Trichuriasis Infection",
    ],
  },
  {
    ui: "D014262",
    heading: "Tricuspid Valve Insufficiency",
    tree_numbers: ["C14.280.484.856"],
    synonyms: ["Tricuspid Regurgitation", "Tricuspid Incompetence", "Tricuspid Valve Regurgitation"],
  },
  {
    ui: "D014263",
    heading: "Tricuspid Valve Prolapse",
    tree_numbers: ["C14.280.484.400.875"],
  },
  {
    ui: "D014264",
    heading: "Tricuspid Valve Stenosis",
    tree_numbers: ["C14.280.484.911"],
    synonyms: ["Tricuspid Stenosis"],
  },
  {
    ui: "D014277",
    heading: "Trigeminal Neuralgia",
    tree_numbers: ["C07.465.299.625.500.700", "C10.292.319.625.700.700"],
    synonyms: ["Trifacial Neuralgia", "Trifocal Neuralgia", "Trigeminal Neuralgia"],
  },
  {
    ui: "D014286",
    heading: "Trilogy of Fallot",
    tree_numbers: ["C14.240.400.960", "C14.280.400.960", "C16.131.240.400.960"],
  },
  {
    ui: "D014313",
    heading: "Trismus",
    tree_numbers: ["C10.597.613.750.700", "C23.888.592.608.750.700"],
  },
  {
    ui: "D014314",
    heading: "Trisomy",
    tree_numbers: [
      "C23.550.210.050.750",
      "C23.550.210.182.500",
      "G05.365.590.175.050.750",
      "G05.365.590.175.183.500",
      "G05.700.131.750",
    ],
  },
  {
    ui: "D014323",
    heading: "Trombiculiasis",
    tree_numbers: ["C01.610.858.211.480.869"],
  },
  {
    ui: "D014328",
    heading: "Trophoblastic Neoplasms",
    tree_numbers: ["C04.557.465.955", "C04.850.908", "C13.703.720.949"],
    synonyms: ["Trophoblastic Tumor", "Trophoblastic Neoplasm"],
  },
  {
    ui: "D014339",
    heading: "Truncus Arteriosus, Persistent",
    tree_numbers: ["C14.240.400.560.098.500", "C14.280.400.560.098.500", "C16.131.240.400.560.098.500"],
  },
  {
    ui: "D014352",
    heading: "Trypanosomiasis",
    tree_numbers: ["C01.610.752.300.900"],
  },
  {
    ui: "D014353",
    heading: "Trypanosomiasis, African",
    tree_numbers: ["C01.610.752.300.900.719", "C01.920.937"],
    synonyms: ["African Sleeping Sickness", "African Trypanosomiasis"],
  },
  {
    ui: "D014354",
    heading: "Trypanosomiasis, Bovine",
    tree_numbers: [
      "C01.610.701.688.896",
      "C01.610.752.300.900.802",
      "C01.610.752.625.896",
      "C22.196.888",
      "C22.674.710.896",
    ],
  },
  {
    ui: "D014355",
    heading: "Chagas Disease",
    tree_numbers: ["C01.610.752.300.900.200", "C01.920.625"],
  },
  {
    ui: "D014375",
    heading: "Tuberculoma",
    tree_numbers: ["C01.150.252.410.040.552.846.493"],
  },
  {
    ui: "D014376",
    heading: "Tuberculosis",
    tree_numbers: ["C01.150.252.410.040.552.846"],
  },
  {
    ui: "D014379",
    heading: "Tuberculosis, Avian",
    tree_numbers: ["C01.150.252.410.040.552.846.516", "C22.131.921"],
  },
  {
    ui: "D014380",
    heading: "Tuberculosis, Bovine",
    tree_numbers: ["C01.150.252.410.040.552.846.538", "C22.196.927"],
  },
  {
    ui: "D014381",
    heading: "Tuberculosis, Cardiovascular",
    tree_numbers: ["C01.150.252.410.040.552.846.554", "C01.190.750", "C14.260.750"],
    synonyms: [],
  },
  {
    ui: "D014382",
    heading: "Tuberculosis, Cutaneous",
    tree_numbers: ["C01.150.252.410.040.552.846.588", "C01.150.252.819.820", "C01.800.720.820", "C17.800.838.765.820"],
  },
  {
    ui: "D014383",
    heading: "Tuberculosis, Endocrine",
    tree_numbers: ["C01.150.252.410.040.552.846.606", "C19.927"],
  },
  {
    ui: "D014384",
    heading: "Tuberculosis, Female Genital",
    tree_numbers: ["C01.150.252.410.040.552.846.944.596", "C13.351.500.758", "C13.351.750.940"],
  },
  {
    ui: "D014385",
    heading: "Tuberculosis, Gastrointestinal",
    tree_numbers: ["C01.150.252.410.040.552.846.628", "C06.405.874"],
    synonyms: [
      "Tuberculosis Of Gastrointestinal Tract",
      "Tuberculosis Of Intestines, Peritoneum, And Mesenteric Glands",
      "Tuberculosis Of Intestines, Peritoneum And Mesenteric Glands",
      "Tuberculosis Of Intestines, Peritoneum And Mesenteric Glands",
    ],
  },
  {
    ui: "D014386",
    heading: "Tuberculosis, Hepatic",
    tree_numbers: ["C01.150.252.410.040.552.846.651", "C06.552.933"],
    synonyms: ["Tuberculosis Of Liver"],
  },
  {
    ui: "D014387",
    heading: "Tuberculosis, Laryngeal",
    tree_numbers: ["C01.150.252.410.040.552.846.696", "C01.748.860", "C08.360.860", "C08.730.860", "C09.400.860"],
  },
  {
    ui: "D014388",
    heading: "Tuberculosis, Lymph Node",
    tree_numbers: ["C01.150.252.410.040.552.846.719"],
    synonyms: [
      "Tuberculous Lymphadenopathy",
      "Scrofula",
      "King's Evil",
      "Lymph Node Tuberculosis",
      "Tuberculous Adenitis",
    ],
  },
  {
    ui: "D014389",
    heading: "Tuberculosis, Male Genital",
    tree_numbers: ["C01.150.252.410.040.552.846.944.721", "C12.294.909", "C12.672.721"],
  },
  {
    ui: "D014390",
    heading: "Tuberculosis, Meningeal",
    tree_numbers: [
      "C01.150.252.223.500.937",
      "C01.150.252.223.850.800",
      "C01.150.252.410.040.552.846.570.600",
      "C01.207.180.500.937",
      "C01.207.180.850.800",
      "C10.228.228.180.500.937",
      "C10.228.228.180.850.800",
      "C10.228.614.280.915",
    ],
  },
  {
    ui: "D014391",
    heading: "Tuberculosis, Miliary",
    tree_numbers: ["C01.150.252.410.040.552.846.764"],
    synonyms: [
      "Acute Miliary Tuberculosis",
      "Tuberculosis Miliaris Disseminata",
      "Generalized Tuberculosis",
      "Disseminated Tuberculosis",
    ],
  },
  {
    ui: "D014392",
    heading: "Tuberculosis, Ocular",
    tree_numbers: ["C01.150.252.289.800", "C01.375.354.800", "C11.294.354.800"],
  },
  {
    ui: "D014393",
    heading: "Tuberculosis, Oral",
    tree_numbers: ["C01.150.252.410.040.552.846.809", "C07.465.943"],
    synonyms: ["Oral Tuberculosis"],
  },
  {
    ui: "D014394",
    heading: "Tuberculosis, Osteoarticular",
    tree_numbers: ["C01.150.252.410.040.552.846.843", "C01.160.886", "C05.116.165.886"],
    synonyms: ["Osteoarticular Tuberculosis"],
  },
  {
    ui: "D014395",
    heading: "Peritonitis, Tuberculous",
    tree_numbers: ["C01.150.252.410.040.552.846.308", "C01.463.600.249", "C06.844.640.249"],
  },
  {
    ui: "D014396",
    heading: "Tuberculosis, Pleural",
    tree_numbers: ["C01.150.252.410.040.552.846.877", "C01.748.912", "C08.528.928", "C08.730.912"],
    synonyms: [
      "Tuberculous Pleurisy In Primary Progressive Tuberculosis",
      "Tuberculous Pleuritis",
      "Tuberculous Pleurisy",
      "Pearly Disease",
      "Tuberculosis Of Pleura",
    ],
  },
  {
    ui: "D014397",
    heading: "Tuberculosis, Pulmonary",
    tree_numbers: ["C01.150.252.410.040.552.846.899", "C01.748.939", "C08.381.922", "C08.730.939"],
  },
  {
    ui: "D014398",
    heading: "Tuberculosis, Renal",
    tree_numbers: [
      "C01.150.252.410.040.552.846.944.847",
      "C12.672.847",
      "C12.777.419.912",
      "C13.351.750.970",
      "C13.351.968.419.912",
    ],
    synonyms: ["Tuberculosis Of Kidney"],
  },
  {
    ui: "D014399",
    heading: "Tuberculosis, Spinal",
    tree_numbers: [
      "C01.150.252.410.040.552.846.843.722",
      "C01.160.886.722",
      "C05.116.165.886.722",
      "C05.116.900.853.850",
    ],
  },
  {
    ui: "D014400",
    heading: "Tuberculosis, Splenic",
    tree_numbers: ["C01.150.252.410.040.552.846.922", "C15.604.744.909"],
  },
  {
    ui: "D014401",
    heading: "Tuberculosis, Urogenital",
    tree_numbers: ["C01.150.252.410.040.552.846.944", "C12.672", "C13.351.750"],
    synonyms: [],
  },
  {
    ui: "D014402",
    heading: "Tuberous Sclerosis",
    tree_numbers: [
      "C04.445.810",
      "C04.651.800",
      "C04.700.700",
      "C10.500.507.400.750",
      "C10.562.850",
      "C10.574.500.865",
      "C16.131.666.507.400.750",
      "C16.320.400.880",
      "C16.320.700.700",
    ],
    synonyms: [
      "Cerebral Sclerosis",
      "Tuberous Sclerosis Syndrome",
      "Tuberose Sclerosis",
      "Epiloia",
      "Bourneville's Disease",
    ],
  },
  {
    ui: "D014406",
    heading: "Tularemia",
    tree_numbers: ["C01.150.252.400.900", "C01.920.930.943"],
  },
  {
    ui: "D014412",
    heading: "Tumor Virus Infections",
    tree_numbers: ["C01.925.928"],
  },
  {
    ui: "D014424",
    heading: "Turner Syndrome",
    tree_numbers: [
      "C12.706.316.309.872",
      "C12.706.316.795.750",
      "C13.351.875.253.309.872",
      "C13.351.875.253.795.750",
      "C14.240.400.980",
      "C14.280.400.980",
      "C16.131.240.400.970",
      "C16.131.260.830.835.750",
      "C16.131.939.316.309.872",
      "C16.131.939.316.795.750",
      "C16.320.180.830.835.750",
      "C19.391.119.309.872",
      "C19.391.119.795.750",
    ],
    synonyms: [
      "Monosomy X",
      "Bonnevie-Ullrich Syndrome",
      "Gonadal Dysgenesis - Turner",
      "Karyotype 45, X",
      "Xo Syndrome",
      "Monosomy X Syndrome",
    ],
  },
  {
    ui: "D014428",
    heading: "Twins, Conjoined",
    tree_numbers: ["C16.131.085.806"],
  },
  {
    ui: "D014435",
    heading: "Typhoid Fever",
    tree_numbers: ["C01.150.252.400.310.821.873"],
    synonyms: ["Typhoid"],
  },
  {
    ui: "D014437",
    heading: "Typhus, Endemic Flea-Borne",
    tree_numbers: ["C01.150.252.400.789.725.800", "C01.920.914.725.800"],
    synonyms: [
      "Moscow Typhus",
      "Flea Typhus",
      "Epidemic Louse-Borne Typhus",
      "Louse-Borne [epidemic] Typhus",
      "Typhus Fever",
      "Endemic Flea-Borne Typhus",
      "Murine [endemic] Typhus",
      "Exanthematic Typhus Fever",
      "Endemic Typhus Fever",
    ],
  },
  {
    ui: "D014438",
    heading: "Typhus, Epidemic Louse-Borne",
    tree_numbers: ["C01.150.252.400.789.725.805", "C01.920.914.725.805"],
    synonyms: [
      "Moscow Typhus",
      "Flea Typhus",
      "Epidemic Louse-Borne Typhus",
      "Louse-Borne [epidemic] Typhus",
      "Typhus Fever",
      "Endemic Flea-Borne Typhus",
      "Murine [endemic] Typhus",
      "Exanthematic Typhus Fever",
      "Endemic Typhus Fever",
    ],
  },
  {
    ui: "D014456",
    heading: "Ulcer",
    tree_numbers: ["C23.550.891"],
  },
  {
    ui: "D014458",
    heading: "Ulna Fractures",
    tree_numbers: ["C26.088.268.807", "C26.404.937"],
  },
  {
    ui: "D014474",
    heading: "Unconsciousness",
    tree_numbers: ["C10.597.606.358.800", "C23.888.592.604.359.800"],
  },
  {
    ui: "D014496",
    heading: "Urachal Cyst",
    tree_numbers: ["C04.182.946"],
  },
  {
    ui: "D014511",
    heading: "Uremia",
    tree_numbers: ["C12.777.419.936", "C13.351.968.419.936"],
    synonyms: ["Uremia Of Renal Origin"],
  },
  {
    ui: "D014514",
    heading: "Ureteral Calculi",
    tree_numbers: [
      "C12.777.725.938.500",
      "C12.777.967.374.500",
      "C12.777.967.500.851",
      "C13.351.968.725.938.500",
      "C13.351.968.967.374.500",
      "C13.351.968.967.500.851",
      "C23.300.175.850.750",
    ],
  },
  {
    ui: "D014515",
    heading: "Ureteral Diseases",
    tree_numbers: ["C12.777.725", "C13.351.968.725"],
  },
  {
    ui: "D014516",
    heading: "Ureteral Neoplasms",
    tree_numbers: [
      "C04.588.945.947.940",
      "C12.758.820.875",
      "C12.777.725.676",
      "C13.351.937.820.875",
      "C13.351.968.725.676",
    ],
    synonyms: ["Malignant Tumour Of Ureter", "Malignant Ureteral Tumor"],
  },
  {
    ui: "D014517",
    heading: "Ureteral Obstruction",
    tree_numbers: ["C12.777.725.776", "C13.351.968.725.776"],
  },
  {
    ui: "D014518",
    heading: "Ureterocele",
    tree_numbers: ["C12.777.725.876", "C13.351.968.725.876"],
  },
  {
    ui: "D014522",
    heading: "Urethral Diseases",
    tree_numbers: ["C12.777.767", "C13.351.968.767"],
    synonyms: ["Urethra Disease"],
  },
  {
    ui: "D014523",
    heading: "Urethral Neoplasms",
    tree_numbers: [
      "C04.588.945.947.945",
      "C12.758.820.937",
      "C12.777.767.601",
      "C13.351.937.820.890",
      "C13.351.968.767.601",
    ],
    synonyms: ["Urethral Ca", "Malignant Tumour Of Urethra", "Malignant Urethral Neoplasm"],
  },
  {
    ui: "D014524",
    heading: "Urethral Obstruction",
    tree_numbers: ["C12.777.767.700", "C13.351.968.767.700"],
    synonyms: ["Obstruction Of Urethra"],
  },
  {
    ui: "D014525",
    heading: "Urethral Stricture",
    tree_numbers: ["C12.777.767.700.700", "C13.351.968.767.700.700"],
  },
  {
    ui: "D014526",
    heading: "Urethritis",
    tree_numbers: ["C12.777.767.851", "C13.351.968.767.851"],
    synonyms: ["Non-Gonococcal Urethritis", "Nongonococcal Urethritis"],
  },
  {
    ui: "D014545",
    heading: "Urinary Calculi",
    tree_numbers: ["C12.777.967.500", "C13.351.968.967.500", "C23.300.175.850"],
  },
  {
    ui: "D014548",
    heading: "Urinary Fistula",
    tree_numbers: ["C12.706.881", "C13.351.875.881", "C23.300.575.825"],
  },
  {
    ui: "D014549",
    heading: "Urinary Incontinence",
    tree_numbers: ["C12.777.934.852", "C13.351.968.934.814", "C23.888.942.343.800"],
  },
  {
    ui: "D014550",
    heading: "Urinary Incontinence, Stress",
    tree_numbers: ["C12.777.934.852.249", "C13.351.968.934.814.500", "C23.888.942.343.800.500"],
  },
  {
    ui: "D014552",
    heading: "Urinary Tract Infections",
    tree_numbers: ["C01.915", "C12.777.892", "C13.351.968.892"],
    synonyms: ["Uti"],
  },
  {
    ui: "D014555",
    heading: "Urination Disorders",
    tree_numbers: ["C12.777.934", "C13.351.968.934"],
  },
  {
    ui: "D014564",
    heading: "Urogenital Abnormalities",
    tree_numbers: ["C12.706", "C13.351.875", "C16.131.939"],
  },
  {
    ui: "D014565",
    heading: "Urogenital Neoplasms",
    tree_numbers: ["C04.588.945", "C12.758", "C13.351.937"],
  },
  {
    ui: "D014570",
    heading: "Urologic Diseases",
    tree_numbers: ["C12.777", "C13.351.968"],
  },
  {
    ui: "D014571",
    heading: "Urologic Neoplasms",
    tree_numbers: ["C04.588.945.947", "C12.758.820", "C13.351.937.820"],
    synonyms: [
      "Tumor Of Urinary Tract",
      "Urinary Tract Neoplasm",
      "Neoplasm Of Urinary System",
      "Tumor Of The Urinary System",
    ],
  },
  {
    ui: "D014581",
    heading: "Urticaria",
    tree_numbers: ["C17.800.862.945", "C20.543.480.904"],
  },
  {
    ui: "D014582",
    heading: "Urticaria Pigmentosa",
    tree_numbers: ["C04.557.450.565.465.500.850", "C17.800.508.473.850", "C17.800.621.893"],
    synonyms: ["Up/mpcm"],
  },
  {
    ui: "D014591",
    heading: "Uterine Diseases",
    tree_numbers: ["C13.351.500.852"],
  },
  {
    ui: "D014592",
    heading: "Uterine Hemorrhage",
    tree_numbers: ["C13.351.500.852.691", "C23.550.414.993"],
  },
  {
    ui: "D014593",
    heading: "Uterine Inertia",
    tree_numbers: ["C13.703.420.288.728"],
  },
  {
    ui: "D014594",
    heading: "Uterine Neoplasms",
    tree_numbers: ["C04.588.945.418.948", "C13.351.500.852.762", "C13.351.937.418.875"],
    synonyms: [
      "Malignant Uterine Tumor",
      "Neoplasm Of Uterus",
      "Uterus Neoplasm",
      "Ca - Cancer Of Uterus",
      "Uterine Tumor",
      "Tumour Of Uterus",
      "Malignant Neoplasm Of Uterus",
    ],
  },
  {
    ui: "D014595",
    heading: "Uterine Perforation",
    tree_numbers: ["C13.351.500.852.904.500", "C26.761.853.500"],
  },
  {
    ui: "D014596",
    heading: "Uterine Prolapse",
    tree_numbers: ["C13.351.500.852.833", "C23.300.842.624.750"],
  },
  {
    ui: "D014597",
    heading: "Uterine Rupture",
    tree_numbers: ["C13.351.500.852.904", "C13.703.420.904", "C26.761.853"],
  },
  {
    ui: "D014603",
    heading: "Uveal Diseases",
    tree_numbers: ["C11.941"],
  },
  {
    ui: "D014604",
    heading: "Uveal Neoplasms",
    tree_numbers: ["C04.588.364.978", "C11.319.494", "C11.941.855"],
    synonyms: ["Malignant Neoplasm Of Uveal Tract", "Uveal Tumor", "Malignant Uveal Tumor"],
  },
  {
    ui: "D014605",
    heading: "Uveitis",
    tree_numbers: ["C11.941.879"],
  },
  {
    ui: "D014606",
    heading: "Uveitis, Anterior",
    tree_numbers: ["C11.941.879.780.880"],
  },
  {
    ui: "D014607",
    heading: "Uveomeningoencephalitic Syndrome",
    tree_numbers: ["C10.114.843", "C11.941.879.980", "C20.111.258.925"],
    synonyms: ["Uveomeningoencephalitic Syndrome", "Vogt-Koyanagi Syndrome", "Harada's Disease"],
  },
  {
    ui: "D014608",
    heading: "Uveoparotid Fever",
    tree_numbers: ["C15.604.515.827.865"],
    synonyms: ["Uveoparotid Fever", "Heerfordt's Syndrome"],
  },
  {
    ui: "D014615",
    heading: "Vaccinia",
    tree_numbers: ["C01.925.256.743.929"],
  },
  {
    ui: "D014623",
    heading: "Vaginal Diseases",
    tree_numbers: ["C13.351.500.894"],
  },
  {
    ui: "D014624",
    heading: "Vaginal Fistula",
    tree_numbers: ["C13.351.500.894.767", "C23.300.575.925"],
  },
  {
    ui: "D014625",
    heading: "Vaginal Neoplasms",
    tree_numbers: ["C04.588.945.418.955", "C13.351.500.894.834", "C13.351.937.418.937"],
    synonyms: [
      "Malignant Neoplasm Of Vagina",
      "Neoplasm Of Vagina",
      "Malignant Tumor Of Vagina",
      "Vagina Neoplasm",
      "Vaginal Tumor",
      "Malignant Vaginal Tumor",
    ],
  },
  {
    ui: "D014627",
    heading: "Vaginitis",
    tree_numbers: ["C13.351.500.894.906"],
  },
  {
    ui: "D014646",
    heading: "Varicocele",
    tree_numbers: ["C12.294.936", "C14.907.903"],
    synonyms: ["Scrotal Varices"],
  },
  {
    ui: "D014647",
    heading: "Varicose Ulcer",
    tree_numbers: ["C14.907.927.730", "C17.800.893.592.730"],
  },
  {
    ui: "D014648",
    heading: "Varicose Veins",
    tree_numbers: ["C14.907.927"],
    synonyms: ["Varices", "Venous Ectasia", "Varix", "Venous Varices"],
  },
  {
    ui: "D014652",
    heading: "Vascular Diseases",
    tree_numbers: ["C14.907"],
    synonyms: ["Vascular Tissue Disease"],
  },
  {
    ui: "D014653",
    heading: "Vascular Headaches",
    tree_numbers: ["C10.228.140.300.800", "C10.228.140.546.699.500", "C14.907.253.937"],
  },
  {
    ui: "D014657",
    heading: "Vasculitis",
    tree_numbers: ["C14.907.940"],
    synonyms: ["Angiitis"],
  },
  {
    ui: "D014681",
    heading: "Velopharyngeal Insufficiency",
    tree_numbers: ["C07.465.525.955", "C07.550.966", "C07.650.525.955", "C09.775.955", "C16.131.850.525.955"],
  },
  {
    ui: "D014685",
    heading: "Venereal Tumors, Veterinary",
    tree_numbers: ["C04.588.945.956", "C22.950"],
  },
  {
    ui: "D014689",
    heading: "Venous Insufficiency",
    tree_numbers: ["C14.907.952"],
    synonyms: ["Peripheral Venous Insufficiency"],
  },
  {
    ui: "D014693",
    heading: "Ventricular Fibrillation",
    tree_numbers: ["C14.280.067.922", "C23.550.073.922"],
  },
  {
    ui: "D014694",
    heading: "Ventricular Outflow Obstruction",
    tree_numbers: ["C14.280.955"],
  },
  {
    ui: "D014715",
    heading: "Vertebrobasilar Insufficiency",
    tree_numbers: ["C10.228.140.300.150.956", "C14.907.253.092.956"],
    synonyms: ["Basilar Artery Syndrome"],
  },
  {
    ui: "D014717",
    heading: "Vertigo",
    tree_numbers: ["C09.218.568.900.883", "C10.597.951", "C23.888.592.958"],
    synonyms: ["Central Vestibular Vertigo", "Vertigo Of Central Origin"],
  },
  {
    ui: "D014718",
    heading: "Vesico-Ureteral Reflux",
    tree_numbers: ["C12.777.829.920", "C13.351.968.829.920"],
    synonyms: ["Vesico-Ureteral Reflux"],
  },
  {
    ui: "D014719",
    heading: "Vesicovaginal Fistula",
    tree_numbers: [
      "C13.351.500.894.767.500",
      "C13.351.875.881.312.733",
      "C13.351.968.829.548.733",
      "C23.300.575.825.250.775",
      "C23.300.575.925.816",
    ],
  },
  {
    ui: "D014720",
    heading: "Vesicular Exanthema of Swine",
    tree_numbers: ["C01.925.782.160.927", "C22.905.927"],
  },
  {
    ui: "D014735",
    heading: "Vibrio Infections",
    tree_numbers: ["C01.150.252.400.959"],
  },
  {
    ui: "D014766",
    heading: "Viremia",
    tree_numbers: ["C01.925.937", "C23.550.470.790.500.900"],
  },
  {
    ui: "D014770",
    heading: "Virilism",
    tree_numbers: ["C23.888.971"],
  },
  {
    ui: "D014777",
    heading: "Virus Diseases",
    tree_numbers: ["C01.925"],
    synonyms: ["Viral Disease", "Viral Infection", "Virus Infection"],
  },
  {
    ui: "D014782",
    heading: "Visceral Prolapse",
    tree_numbers: ["C06.405.937", "C23.300.842.624.875"],
  },
  {
    ui: "D014786",
    heading: "Vision Disorders",
    tree_numbers: ["C10.597.751.941", "C11.966", "C23.888.592.763.941"],
  },
  {
    ui: "D014802",
    heading: "Vitamin A Deficiency",
    tree_numbers: ["C18.654.521.500.133.628"],
  },
  {
    ui: "D014804",
    heading: "Vitamin B Deficiency",
    tree_numbers: ["C18.654.521.500.133.699"],
  },
  {
    ui: "D014806",
    heading: "Vitamin B 12 Deficiency",
    tree_numbers: ["C18.654.521.500.133.699.923"],
  },
  {
    ui: "D014808",
    heading: "Vitamin D Deficiency",
    tree_numbers: ["C18.654.521.500.133.770"],
  },
  {
    ui: "D014811",
    heading: "Vitamin E Deficiency",
    tree_numbers: ["C18.654.521.500.133.841"],
  },
  {
    ui: "D014813",
    heading: "Vitamin K Deficiency",
    tree_numbers: ["C15.378.100.920", "C15.378.463.841", "C18.654.521.500.133.912"],
    synonyms: ["Vitamin K Deficiency", "Deficiency Of Vitamin K", "Vitamin K Deficiency Hemorrhagic Disease"],
  },
  {
    ui: "D014820",
    heading: "Vitiligo",
    tree_numbers: ["C17.800.621.440.895"],
  },
  {
    ui: "D014823",
    heading: "Vitreous Hemorrhage",
    tree_numbers: ["C11.290.960", "C23.550.414.756.887"],
  },
  {
    ui: "D014826",
    heading: "Vocal Cord Paralysis",
    tree_numbers: ["C08.360.931", "C09.400.931", "C10.292.887.800", "C10.597.622.943", "C23.888.592.636.943"],
  },
  {
    ui: "D014832",
    heading: "Voice Disorders",
    tree_numbers: ["C08.360.940", "C09.400.940", "C10.597.975", "C23.888.592.979"],
  },
  {
    ui: "D014839",
    heading: "Vomiting",
    tree_numbers: ["C23.888.821.937"],
  },
  {
    ui: "D014840",
    heading: "Vomiting, Anticipatory",
    tree_numbers: ["C23.888.821.937.080"],
  },
  {
    ui: "D014842",
    heading: "von Willebrand Diseases",
    tree_numbers: [
      "C15.378.100.100.900",
      "C15.378.100.141.900",
      "C15.378.140.900",
      "C15.378.463.920",
      "C16.320.099.920",
    ],
    synonyms: [
      "Von Willebrand Disease",
      "Vascular Hemophilia",
      "Von Willebrand's-Jurgens' Disease",
      "Vascular Pseudohemophilia",
      "Von Willebrand Disorder",
      "Von Willebrand-Jrgens Disease",
    ],
  },
  {
    ui: "D014843",
    heading: "Voyeurism",
    tree_numbers: ["F03.657.900"],
  },
  {
    ui: "D014845",
    heading: "Vulvar Diseases",
    tree_numbers: ["C13.351.500.944"],
  },
  {
    ui: "D014846",
    heading: "Vulvar Neoplasms",
    tree_numbers: ["C04.588.945.418.968", "C13.351.500.944.819", "C13.351.937.418.968"],
    synonyms: [
      "Neoplasm Of Vulva",
      "Vulval Cancer",
      "Malignant Neoplasm Of Vulva",
      "Vulval Neoplasm",
      "Malignant Vulvar Tumor",
      "Ca Vulva",
      "Malignant Tumor Of Vulva",
      "Vulvar Tumor",
      "Vulvar Neoplasm",
    ],
  },
  {
    ui: "D014847",
    heading: "Vulvitis",
    tree_numbers: ["C13.351.500.944.902"],
  },
  {
    ui: "D014848",
    heading: "Vulvovaginitis",
    tree_numbers: ["C13.351.500.894.906.820", "C13.351.500.944.902.737"],
    synonyms: ["Vulvo-Vaginitis"],
  },
  {
    ui: "D014849",
    heading: "Waardenburg Syndrome",
    tree_numbers: ["C16.131.077.938"],
    synonyms: ["Van Der Hoeve Halbertsona Waardenburg Syndrome", "Waardenburg Shah Syndrome", "Waardenburg Syndrome"],
  },
  {
    ui: "D014854",
    heading: "Lateral Medullary Syndrome",
    tree_numbers: [
      "C10.228.140.300.150.477.100.500",
      "C10.228.140.300.775.200.100.500",
      "C14.907.253.092.477.100.500",
      "C14.907.253.855.200.100.500",
      "C23.550.513.355.250.100.500",
      "C23.550.717.489.250.100.500",
    ],
    synonyms: ["Wallenberg Syndrome", "Posterior Inferior Cerebellar Artery Syndrome"],
  },
  {
    ui: "D014855",
    heading: "Wallerian Degeneration",
    tree_numbers: ["C23.550.737.750"],
  },
  {
    ui: "D014860",
    heading: "Warts",
    tree_numbers: ["C01.925.256.650.810", "C01.925.825.810", "C01.925.928.914", "C17.800.838.790.810"],
  },
  {
    ui: "D014869",
    heading: "Water Intoxication",
    tree_numbers: ["C18.452.950.932", "C25.723.932"],
  },
  {
    ui: "D014883",
    heading: "Water-Electrolyte Imbalance",
    tree_numbers: ["C18.452.950"],
  },
  {
    ui: "D014884",
    heading: "Waterhouse-Friderichsen Syndrome",
    tree_numbers: [
      "C01.150.252.223.500.750.500",
      "C01.150.252.400.625.549.449.800",
      "C01.207.180.500.750.500",
      "C10.228.228.180.500.750.500",
      "C10.228.614.280.505.904",
      "C15.378.100.802.843",
      "C15.378.463.950",
      "C19.053.500.740",
      "C23.550.414.950.843",
      "C23.888.885.687.843",
    ],
    synonyms: ["Meningococcal Hemorrhagic Adrenalitis"],
  },
  {
    ui: "D014890",
    heading: "Granulomatosis with Polyangiitis",
    tree_numbers: ["C08.381.483.950", "C14.907.940.897.249.750", "C20.111.193.875"],
    synonyms: ["Necrotizing Respiratory Granulomatosis", "Wegener Granulomatosis, Formerly"],
  },
  {
    ui: "D014895",
    heading: "Weil Disease",
    tree_numbers: ["C01.150.252.400.794.511.739"],
    synonyms: [
      "Leptospirosis Icterohaemorrhagica",
      "Weil's Disease",
      "Rat Catcher's Yellows",
      "Nanukayami Fever",
      "Fort Bragg Fever",
      "Spirochetal Jaundice",
    ],
  },
  {
    ui: "D014897",
    heading: "Spinal Muscular Atrophies of Childhood",
    tree_numbers: [
      "C10.228.854.468.800",
      "C10.574.500.812",
      "C10.574.562.500.750",
      "C10.668.467.500.750",
      "C16.320.400.765",
    ],
    synonyms: ["Survival Motor Neuron Spinal Muscular Atrophy", "Spinal Muscular Atrophies Of Childhood"],
  },
  {
    ui: "D014898",
    heading: "Werner Syndrome",
    tree_numbers: ["C16.320.925", "C18.452.284.960"],
    synonyms: ["Werner's Syndrome", "Adult Progeria", "Ws", "Adult Premature Ageing Syndrome"],
  },
  {
    ui: "D014899",
    heading: "Wernicke Encephalopathy",
    tree_numbers: [
      "C10.228.140.163.960",
      "C18.452.132.960",
      "C18.654.521.500.133.699.827.822",
      "C25.775.100.625",
      "F03.900.100.875",
    ],
    synonyms: ["Wernicke's Disease", "Wernicke's Encephalopathy"],
  },
  {
    ui: "D014901",
    heading: "West Nile Fever",
    tree_numbers: [
      "C01.207.245.340.300.887",
      "C01.207.399.750.300.887",
      "C01.920.500.343.950",
      "C01.925.081.343.950",
      "C01.925.182.525.300.850",
      "C01.925.782.310.950",
      "C01.925.782.350.250.900",
      "C10.228.140.430.520.750.300.887",
      "C10.228.228.245.340.300.887",
      "C10.228.228.399.750.300.887",
    ],
    synonyms: ["West Nile Fever Encephalitis", "West Nile Fever With Encephalitis"],
  },
  {
    ui: "D014911",
    heading: "Whiplash Injuries",
    tree_numbers: ["C26.700.500"],
  },
  {
    ui: "D014912",
    heading: "White Muscle Disease",
    tree_numbers: ["C22.595.740"],
  },
  {
    ui: "D014917",
    heading: "Whooping Cough",
    tree_numbers: ["C01.150.252.400.143.500", "C01.748.969", "C08.730.969"],
  },
  {
    ui: "D014923",
    heading: "Wiskott-Aldrich Syndrome",
    tree_numbers: [
      "C15.378.100.100.970",
      "C15.378.463.960",
      "C15.378.553.546.605.900",
      "C16.320.099.970",
      "C16.320.322.937",
      "C16.320.798.875",
      "C20.673.627.900",
      "C20.673.795.875",
    ],
    synonyms: ["Wiskott Syndrome"],
  },
  {
    ui: "D014924",
    heading: "Wissler's Syndrome",
    tree_numbers: ["C05.550.114.843.823", "C05.799.825.823", "C20.543.928"],
    synonyms: ["Wissler's Subsepsis Allergica", "Wissler's Syndrome"],
  },
  {
    ui: "D014927",
    heading: "Wolff-Parkinson-White Syndrome",
    tree_numbers: ["C14.280.067.780.977", "C14.280.123.750.977", "C16.131.240.400.980"],
    synonyms: ["Anomalous Atrioventricular Excitation", "Wolff-Parkinson-White Pattern", "Anomalous A-V Excitation"],
  },
  {
    ui: "D014929",
    heading: "Wolfram Syndrome",
    tree_numbers: [
      "C09.218.458.341.186.500.750",
      "C10.292.700.225.500.980",
      "C10.574.500.662.980",
      "C10.597.751.418.341.186.500.750",
      "C10.597.751.941.162.625.750",
      "C11.270.564.980",
      "C11.640.451.451.980",
      "C11.966.075.375.750",
      "C12.777.419.135.875",
      "C13.351.968.419.135.875",
      "C16.131.077.299.750",
      "C16.320.290.564.980",
      "C16.320.400.630.980",
      "C18.452.394.750.124.960",
      "C19.246.267.960",
      "C19.700.159.875",
    ],
  },
  {
    ui: "D014946",
    heading: "Wound Infection",
    tree_numbers: ["C01.947"],
  },
  {
    ui: "D014947",
    heading: "Wounds and Injuries",
    tree_numbers: ["C26"],
  },
  {
    ui: "D014948",
    heading: "Wounds, Gunshot",
    tree_numbers: ["C26.986.900"],
  },
  {
    ui: "D014949",
    heading: "Wounds, Nonpenetrating",
    tree_numbers: ["C26.974"],
  },
  {
    ui: "D014950",
    heading: "Wounds, Penetrating",
    tree_numbers: ["C26.986"],
  },
  {
    ui: "D014951",
    heading: "Wounds, Stab",
    tree_numbers: ["C26.986.950"],
  },
  {
    ui: "D014954",
    heading: "Wrist Injuries",
    tree_numbers: ["C26.088.906"],
  },
  {
    ui: "D014972",
    heading: "Xanthogranuloma, Juvenile",
    tree_numbers: ["C15.604.250.410.900", "C17.800.973"],
    synonyms: ["Multiple Eruptive Juvenile Xanthogranuloma", "Naevoxanthoendothelioma", "Xanthoma Neviforme"],
  },
  {
    ui: "D014973",
    heading: "Xanthomatosis",
    tree_numbers: ["C18.452.584.750"],
    synonyms: ["Xanthelasmatosis"],
  },
  {
    ui: "D014983",
    heading: "Xeroderma Pigmentosum",
    tree_numbers: [
      "C04.834.867",
      "C16.131.831.936",
      "C16.320.850.970",
      "C17.800.600.925",
      "C17.800.621.936",
      "C17.800.804.936",
      "C17.800.827.970",
      "C18.452.284.975",
    ],
  },
  {
    ui: "D014985",
    heading: "Xerophthalmia",
    tree_numbers: ["C11.187.810", "C11.496.260.892"],
    synonyms: ["Conjunctival Xerosis"],
  },
  {
    ui: "D014987",
    heading: "Xerostomia",
    tree_numbers: ["C07.465.815.929"],
  },
  {
    ui: "D014997",
    heading: "XYY Karyotype",
    tree_numbers: [
      "C23.550.210.024.500",
      "C23.550.210.815.970",
      "G05.360.162.679.500.500",
      "G05.365.590.175.024.500",
      "G05.365.590.175.815.970",
    ],
  },
  {
    ui: "D015001",
    heading: "Yaws",
    tree_numbers: [
      "C01.150.252.400.794.840.750",
      "C01.150.252.400.840.750",
      "C01.150.252.819.910",
      "C01.800.720.910",
      "C17.800.838.765.910",
    ],
    synonyms: ["Frambesia Tropica", "Frambesia", "Polypapilloma Tropicum", "Bouba", "Thymosis", "Frambosie"],
  },
  {
    ui: "D015004",
    heading: "Yellow Fever",
    tree_numbers: ["C01.920.500.980", "C01.925.081.980", "C01.925.782.350.250.980", "C01.925.782.417.881"],
    synonyms: ["Jungle Yellow Fever", "Sylvatic Yellow Fever", "Urban Yellow Fever", "Yellow Fever, Sylvan"],
  },
  {
    ui: "D015009",
    heading: "Yersinia Infections",
    tree_numbers: ["C01.150.252.400.310.980"],
  },
  {
    ui: "D015012",
    heading: "Yersinia pseudotuberculosis Infections",
    tree_numbers: ["C01.150.252.400.310.980.780"],
  },
  {
    ui: "D015043",
    heading: "Zollinger-Ellison Syndrome",
    tree_numbers: [
      "C04.730.713.988",
      "C06.301.371.883",
      "C06.405.249.883",
      "C06.405.469.275.800.924",
      "C06.405.469.965",
      "C06.405.748.586.924",
      "C06.405.748.947",
    ],
  },
  {
    ui: "D015047",
    heading: "Zoonoses",
    tree_numbers: ["C01.973", "C22.969"],
  },
  {
    ui: "D015051",
    heading: "Zygomatic Fractures",
    tree_numbers: ["C10.900.300.284.500.950", "C26.404.750.959", "C26.915.300.425.500.950"],
  },
  {
    ui: "D015140",
    heading: "Dementia, Vascular",
    tree_numbers: [
      "C10.228.140.300.400",
      "C10.228.140.300.510.800.500",
      "C10.228.140.380.230",
      "C10.228.140.695.500",
      "C14.907.137.126.372.500",
      "C14.907.253.560.350.500",
      "F03.615.400.350",
    ],
    synonyms: ["Multi Infarct Dementia", "Multifocal Dementia"],
  },
  {
    ui: "D015154",
    heading: "Esophageal Motility Disorders",
    tree_numbers: ["C06.405.117.119.500"],
    synonyms: [
      "Esophageal Dysmotility",
      "Esophageal Motility Disorder",
      "Oesophageal Dysmotility",
      "Dyskinesia Of Oesophagus",
      "Oesophageal Motor Disorder",
    ],
  },
  {
    ui: "D015155",
    heading: "Esophageal Spasm, Diffuse",
    tree_numbers: ["C06.405.117.119.500.450"],
  },
  {
    ui: "D015160",
    heading: "Hydrops Fetalis",
    tree_numbers: [
      "C13.703.277.060.480",
      "C15.378.295.480",
      "C15.378.420.826.100.350",
      "C16.300.060.480",
      "C16.320.365.826.100.350",
      "C20.306.480",
      "C23.888.277.395",
    ],
  },
  {
    ui: "D015161",
    heading: "Dementia, Multi-Infarct",
    tree_numbers: [
      "C10.228.140.300.150.477.200.199",
      "C10.228.140.300.400.408",
      "C10.228.140.300.775.200.200.199",
      "C10.228.140.380.230.250",
      "C14.907.253.092.477.200.199",
      "C14.907.253.855.200.200.199",
      "C23.550.513.355.250.200.199",
      "C23.550.717.489.250.200.199",
      "F03.615.400.350.400",
    ],
    synonyms: ["Multi Infarct Dementia", "Multifocal Dementia"],
  },
  {
    ui: "D015163",
    heading: "Superinfection",
    tree_numbers: ["C01.597.880", "C01.610.684.880", "C01.925.597.880"],
  },
  {
    ui: "D015168",
    heading: "Lightning Injuries",
    tree_numbers: ["C26.324.550"],
  },
  {
    ui: "D015173",
    heading: "Supratentorial Neoplasms",
    tree_numbers: ["C04.588.614.250.195.885", "C10.228.140.211.885", "C10.551.240.250.700"],
    synonyms: ["Brain Neoplasm, Supratentorial", "Malignant Supratentorial Tumor"],
  },
  {
    ui: "D015174",
    heading: "Epidural Neoplasms",
    tree_numbers: ["C04.588.614.250.803.342", "C10.228.854.765.342", "C10.551.240.750.200"],
    synonyms: ["Extradural Tumor"],
  },
  {
    ui: "D015175",
    heading: "Prolactinoma",
    tree_numbers: [
      "C04.557.470.035.625",
      "C04.588.322.609.792",
      "C10.228.140.617.738.675.800",
      "C19.344.609.792",
      "C19.700.734.792",
    ],
    synonyms: ["Familial Prolactinoma", "Pituitary Adenoma, Prolactin-Secreting", "Prolactinoma Of Pituitary Gland"],
  },
  {
    ui: "D015179",
    heading: "Colorectal Neoplasms",
    tree_numbers: [
      "C04.588.274.476.411.307",
      "C06.301.371.411.307",
      "C06.405.249.411.307",
      "C06.405.469.158.356",
      "C06.405.469.491.307",
      "C06.405.469.860.180",
    ],
  },
  {
    ui: "D015192",
    heading: "Infratentorial Neoplasms",
    tree_numbers: ["C04.588.614.250.195.411", "C10.228.140.211.500", "C10.551.240.250.400"],
    synonyms: ["Brain Neoplasm, Infratentorial", "Malignant Infratentorial Tumors"],
  },
  {
    ui: "D015207",
    heading: "Osteoarthritis, Hip",
    tree_numbers: ["C05.550.114.606.400", "C05.799.613.400"],
  },
  {
    ui: "D015208",
    heading: "Smoke Inhalation Injury",
    tree_numbers: ["C26.200.322.800"],
  },
  {
    ui: "D015209",
    heading: "Cholangitis, Sclerosing",
    tree_numbers: ["C06.130.120.200.110"],
    synonyms: ["Fibrosing Cholangitis"],
  },
  {
    ui: "D015210",
    heading: "Arthritis, Gouty",
    tree_numbers: [
      "C05.550.114.423.410",
      "C05.550.354.500.500",
      "C05.799.414.410",
      "C16.320.565.798.368.410",
      "C18.452.648.798.368.410",
    ],
    synonyms: ["Gouty Arthritis", "Gouty Arthropathy", "Articular Gout"],
  },
  {
    ui: "D015211",
    heading: "Zellweger Syndrome",
    tree_numbers: [
      "C06.552.970",
      "C10.228.140.163.100.968",
      "C12.777.419.978",
      "C13.351.968.419.978",
      "C16.131.077.970",
      "C16.320.565.189.968",
      "C16.320.565.663.970",
      "C18.452.132.100.968",
      "C18.452.648.189.968",
      "C18.452.648.663.970",
    ],
    synonyms: ["Peroxisome Biogenesis Disorder", "Congenital Iron Overload", "Cerebrohepatorenal Syndrome"],
  },
  {
    ui: "D015212",
    heading: "Inflammatory Bowel Diseases",
    tree_numbers: ["C06.405.205.731", "C06.405.469.432"],
  },
  {
    ui: "D015217",
    heading: "Cholesterol Ester Storage Disease",
    tree_numbers: [
      "C16.320.565.398.641.201",
      "C16.320.565.595.201",
      "C18.452.584.687.201",
      "C18.452.648.398.641.201",
      "C18.452.648.595.201",
    ],
  },
  {
    ui: "D015218",
    heading: "Hyperostosis, Sternocostoclavicular",
    tree_numbers: ["C05.116.540.600", "C05.799.440", "C17.300.775.440"],
  },
  {
    ui: "D015223",
    heading: "Wolman Disease",
    tree_numbers: [
      "C16.320.565.398.641.201.500",
      "C16.320.565.595.201.500",
      "C16.614.947",
      "C18.452.584.687.201.500",
      "C18.452.648.398.641.201.500",
      "C18.452.648.595.201.500",
    ],
    synonyms: [
      "Wolman Xanthomatosis",
      "Acid Lipase Deficiency",
      "Wolman's Disease",
      "Wolman's Or Triglyceride Storage Type Iii Disease",
      "Acid Esterase Deficiency",
      "Xanthomatosis, Familial",
    ],
  },
  {
    ui: "D015228",
    heading: "Hypertriglyceridemia",
    tree_numbers: ["C18.452.584.500.500.851"],
  },
  {
    ui: "D015229",
    heading: "Sexually Transmitted Diseases, Viral",
    tree_numbers: ["C01.221.812.640", "C01.778.640", "C01.925.813", "C23.550.291.531.937.640"],
  },
  {
    ui: "D015231",
    heading: "Sexually Transmitted Diseases, Bacterial",
    tree_numbers: [
      "C01.150.252.734",
      "C01.221.812.281",
      "C01.778.281",
      "C12.294.668.281",
      "C13.351.500.711.281",
      "C23.550.291.531.937.281",
    ],
  },
  {
    ui: "D015266",
    heading: "Carcinoma, Merkel Cell",
    tree_numbers: [
      "C01.925.256.721.150",
      "C01.925.928.216",
      "C04.557.465.625.650.240.325",
      "C04.557.470.200.025.370.325",
      "C04.557.580.625.650.240.325",
    ],
  },
  {
    ui: "D015267",
    heading: "Churg-Strauss Syndrome",
    tree_numbers: ["C14.907.940.897.249.249", "C15.604.515.292.015", "C20.111.193.500"],
    synonyms: ["Allergic Granulomatosis Angiitis", "Allergic Granulomatous Angiitis", "Churg-Strauss Vasculitis"],
  },
  {
    ui: "D015270",
    heading: "Mycobacterium avium-intracellulare Infection",
    tree_numbers: ["C01.150.252.410.040.552.475.495"],
    synonyms: [
      "Infection Due To Mycobacterium Intracellulare",
      "Mycobacterium Avium Complex",
      "Mycobacterium Avium Infection",
      "Mac Disease",
    ],
  },
  {
    ui: "D015275",
    heading: "Tumor Lysis Syndrome",
    tree_numbers: ["C15.604.515.880", "C20.683.515.950"],
  },
  {
    ui: "D015299",
    heading: "Discitis",
    tree_numbers: ["C01.160.762.301", "C05.116.165.762.301", "C05.116.900.853.500"],
  },
  {
    ui: "D015318",
    heading: "Fructose Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.202.251", "C18.452.648.202.251"],
  },
  {
    ui: "D015319",
    heading: "Fructose-1,6-Diphosphatase Deficiency",
    tree_numbers: ["C16.320.565.202.251.221", "C18.452.648.202.251.221"],
    synonyms: ["Fructose 1 Phosphate Aldolase Deficiency", "Fructose-1,6-Diphosphatase Deficiency"],
  },
  {
    ui: "D015323",
    heading: "Pyruvate Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.202.810", "C18.452.648.202.810"],
  },
  {
    ui: "D015324",
    heading: "Pyruvate Carboxylase Deficiency Disease",
    tree_numbers: [
      "C10.228.140.163.100.725",
      "C16.320.565.189.725",
      "C16.320.565.202.810.666",
      "C18.452.132.100.725",
      "C18.452.648.189.725",
      "C18.452.648.202.810.666",
      "C18.452.660.705",
    ],
    synonyms: ["Deficiency Of Pyruvic Carboxylase"],
  },
  {
    ui: "D015325",
    heading: "Pyruvate Dehydrogenase Complex Deficiency Disease",
    tree_numbers: [
      "C10.228.140.163.100.750",
      "C10.597.606.360.455.875",
      "C16.320.322.500.875",
      "C16.320.400.525.875",
      "C16.320.565.189.750",
      "C16.320.565.202.810.766",
      "C18.452.132.100.750",
      "C18.452.648.189.750",
      "C18.452.648.202.810.766",
      "C18.452.660.710",
    ],
    synonyms: [
      "Deficiency Of Pyruvic Dehydrogenase",
      "Pyruvate Dehydrogenase Deficiency",
      "Pyruvate Dehydrogenase Complex Deficiency Disease",
    ],
  },
  {
    ui: "D015352",
    heading: "Dry Eye Syndromes",
    tree_numbers: ["C11.496.260"],
    synonyms: ["Tear Film Insufficiency", "Dry Eye Disease"],
  },
  {
    ui: "D015354",
    heading: "Vision, Low",
    tree_numbers: ["C10.597.751.941.905", "C11.966.905", "C23.888.592.763.941.848"],
  },
  {
    ui: "D015355",
    heading: "Glaucoma, Neovascular",
    tree_numbers: ["C11.525.381.348"],
    synonyms: ["Secondary Angle-Closure Glaucoma With Rubeosis"],
  },
  {
    ui: "D015356",
    heading: "Retinal Artery Occlusion",
    tree_numbers: ["C11.768.400", "C14.907.137.780"],
    synonyms: [
      "Arterial Retinal Branch Occlusion",
      "Retinal Arterial Branch Occlusion",
      "Retinal Arterial Branch Occlusion",
    ],
  },
  {
    ui: "D015362",
    heading: "Child Nutrition Disorders",
    tree_numbers: ["C18.654.180"],
  },
  {
    ui: "D015408",
    heading: "Gastrinoma",
    tree_numbers: [
      "C04.557.470.200.025.290.500",
      "C04.588.274.761.500.124",
      "C04.588.322.475.500.124",
      "C06.301.761.500.124",
      "C06.689.667.500.124",
      "C19.344.421.500.124",
    ],
    synonyms: ["Gastrin Cell Tumour", "Gastrin Secreting Tumor", "Malignant Gastrinoma"],
  },
  {
    ui: "D015417",
    heading: "Hereditary Sensory and Motor Neuropathy",
    tree_numbers: ["C10.500.300", "C10.574.500.495", "C10.668.829.800.300", "C16.131.666.300", "C16.320.400.375"],
    synonyms: [
      "Neuropathic Muscular Atrophy",
      "Hsmn - Hereditary Sensory And Motor Neuropathy",
      "Hereditary Motor And Sensory Neuropathy",
      "Hsmn",
    ],
  },
  {
    ui: "D015418",
    heading: "Optic Atrophies, Hereditary",
    tree_numbers: [
      "C10.292.700.225.500",
      "C10.574.500.662",
      "C11.270.564",
      "C11.640.451.451",
      "C16.320.290.564",
      "C16.320.400.630",
    ],
  },
  {
    ui: "D015419",
    heading: "Spastic Paraplegia, Hereditary",
    tree_numbers: [
      "C10.500.300.820",
      "C10.574.500.495.820",
      "C10.668.829.800.300.820",
      "C16.131.666.300.820",
      "C16.320.400.375.820",
    ],
    synonyms: [
      "French Settlement Disease",
      "Hereditary Spastic Paraparesis",
      "Strumpell-Lorrain Disease",
      "Familial Spastic Paraplegia",
    ],
  },
  {
    ui: "D015422",
    heading: "Scleral Diseases",
    tree_numbers: ["C11.790"],
  },
  {
    ui: "D015423",
    heading: "Scleritis",
    tree_numbers: ["C11.790.500"],
  },
  {
    ui: "D015427",
    heading: "Reperfusion Injury",
    tree_numbers: ["C14.907.725", "C23.550.767.877"],
  },
  {
    ui: "D015428",
    heading: "Myocardial Reperfusion Injury",
    tree_numbers: ["C14.280.238.615", "C14.280.647.625", "C14.907.585.625", "C14.907.725.600", "C23.550.767.877.500"],
  },
  {
    ui: "D015430",
    heading: "Weight Gain",
    tree_numbers: ["C23.888.144.243.926", "G07.345.249.314.120.200.926"],
  },
  {
    ui: "D015431",
    heading: "Weight Loss",
    tree_numbers: ["C23.888.144.243.963", "G07.345.249.314.120.200.963"],
  },
  {
    ui: "D015432",
    heading: "Glomerulonephritis, Membranoproliferative",
    tree_numbers: ["C12.777.419.570.363.615", "C13.351.968.419.570.363.615", "C20.425"],
    synonyms: ["Chronic Glomerulonephritis, Lobular", "Lobular Glomerulonephritis"],
  },
  {
    ui: "D015433",
    heading: "Glomerulonephritis, Membranous",
    tree_numbers: ["C12.777.419.570.363.625", "C13.351.968.419.570.363.625", "C20.111.535"],
    synonyms: ["Membranous Nephropathy"],
  },
  {
    ui: "D015434",
    heading: "Panniculitis",
    tree_numbers: ["C17.300.710", "C17.800.566"],
  },
  {
    ui: "D015435",
    heading: "Panniculitis, Lupus Erythematosus",
    tree_numbers: ["C17.300.475.479.400", "C17.300.710.400", "C17.800.480.479.400", "C17.800.566.400"],
  },
  {
    ui: "D015436",
    heading: "Panniculitis, Peritoneal",
    tree_numbers: ["C06.844.600", "C17.300.710.600"],
  },
  {
    ui: "D015439",
    heading: "Leprosy, Borderline",
    tree_numbers: ["C01.150.252.410.040.552.475.371.850.249"],
    synonyms: ["Borderline Or Dimorphous Leprosy", "Borderline Leprosy", "Midborderline Leprosy"],
  },
  {
    ui: "D015440",
    heading: "Leprosy, Lepromatous",
    tree_numbers: ["C01.150.252.410.040.552.475.371.775.500"],
    synonyms: ["Type L Leprosy", "Lepromatous Leprosy"],
  },
  {
    ui: "D015441",
    heading: "Leprosy, Tuberculoid",
    tree_numbers: ["C01.150.252.410.040.552.475.371.850.500"],
    synonyms: ["Tuberculoid Leprosy", "Type T Leprosy", "Smooth Leprosy"],
  },
  {
    ui: "D015448",
    heading: "Leukemia, B-Cell",
    tree_numbers: ["C04.557.337.428.080", "C15.604.515.560.080", "C20.683.515.528.080"],
  },
  {
    ui: "D015451",
    heading: "Leukemia, Lymphocytic, Chronic, B-Cell",
    tree_numbers: ["C04.557.337.428.080.125", "C15.604.515.560.080.125", "C20.683.515.528.080.125"],
    synonyms: [
      "Chronic Lymphatic Leukemia",
      "Lymphoplasmacytic Leukemia",
      "B-Cell Chronic Lymphocytic Leukemia",
      "Cll",
      "B-Cell Chronic Lymphocytic Leukaemia",
      "Lymphoplasmacytic Leukaemia",
      "Chronic Lymphocytic Leukaemia",
      "Chronic Lymphatic Leukaemia",
    ],
  },
  {
    ui: "D015452",
    heading: "Precursor B-Cell Lymphoblastic Leukemia-Lymphoma",
    tree_numbers: ["C04.557.337.428.600.600", "C15.604.515.560.600.600", "C20.683.515.528.600.600"],
  },
  {
    ui: "D015456",
    heading: "Leukemia, Biphenotypic, Acute",
    tree_numbers: ["C04.557.337.428.100", "C15.604.515.560.100", "C20.683.515.528.100"],
  },
  {
    ui: "D015458",
    heading: "Leukemia, T-Cell",
    tree_numbers: ["C04.557.337.428.580", "C15.604.515.560.575", "C20.683.515.528.582"],
    synonyms: ["T-Cell Leukemia"],
  },
  {
    ui: "D015459",
    heading: "Leukemia-Lymphoma, Adult T-Cell",
    tree_numbers: ["C04.557.337.428.580.100", "C15.604.515.560.575.100", "C20.683.515.528.582.100"],
    synonyms: ["Adult T-Cell Leukemia/lymphoma"],
  },
  {
    ui: "D015461",
    heading: "Leukemia, Prolymphocytic, T-Cell",
    tree_numbers: [
      "C04.557.337.428.565.750",
      "C04.557.337.428.580.075",
      "C15.604.515.560.550.750",
      "C15.604.515.560.575.125",
      "C20.683.515.528.565.750",
      "C20.683.515.528.582.125",
    ],
  },
  {
    ui: "D015463",
    heading: "Leukemia, Prolymphocytic",
    tree_numbers: ["C04.557.337.428.565", "C15.604.515.560.550", "C20.683.515.528.565"],
  },
  {
    ui: "D015464",
    heading: "Leukemia, Myelogenous, Chronic, BCR-ABL Positive",
    tree_numbers: ["C04.557.337.539.250", "C15.378.190.636.370"],
  },
  {
    ui: "D015465",
    heading: "Leukemia, Myeloid, Accelerated Phase",
    tree_numbers: ["C04.557.337.539.250.300", "C15.378.190.636.370.300"],
  },
  {
    ui: "D015466",
    heading: "Leukemia, Myeloid, Chronic-Phase",
    tree_numbers: ["C04.557.337.539.250.400", "C15.378.190.636.370.400"],
  },
  {
    ui: "D015467",
    heading: "Leukemia, Neutrophilic, Chronic",
    tree_numbers: ["C15.378.190.636.380"],
  },
  {
    ui: "D015470",
    heading: "Leukemia, Myeloid, Acute",
    tree_numbers: ["C04.557.337.539.275"],
    synonyms: [
      "Leukemia, Myelocytic, Acute",
      "Acute Myeloblastic Leukemia",
      "Acute Myeloid Leukaemia",
      "Acute Myeloblastic Leukaemia",
      "Aml - Acute Myeloid Leukemia",
      "Acute Myelogenous Leukaemia",
      "Acute Myelogenous Leukemia",
    ],
  },
  {
    ui: "D015471",
    heading: "Leukemia, Basophilic, Acute",
    tree_numbers: ["C04.557.337.539.275.125"],
  },
  {
    ui: "D015472",
    heading: "Leukemia, Eosinophilic, Acute",
    tree_numbers: ["C04.557.337.539.275.300"],
  },
  {
    ui: "D015473",
    heading: "Leukemia, Promyelocytic, Acute",
    tree_numbers: ["C04.557.337.539.275.700"],
    synonyms: [
      "Acute Myeloid Leukemia M3",
      "Acute Myeloblastic Leukaemia Type 3",
      "Acute Myeloid Leukaemia M3",
      "Acute Myeloblastic Leukemia Type 3",
      "Acute Promyelocytic Leukaemia",
    ],
  },
  {
    ui: "D015477",
    heading: "Leukemia, Myelomonocytic, Chronic",
    tree_numbers: ["C04.557.337.539.522", "C15.378.190.615.510"],
  },
  {
    ui: "D015479",
    heading: "Leukemia, Myelomonocytic, Acute",
    tree_numbers: ["C04.557.337.539.520"],
  },
  {
    ui: "D015490",
    heading: "HTLV-I Infections",
    tree_numbers: ["C01.925.782.815.200.470", "C20.673.483.470"],
  },
  {
    ui: "D015491",
    heading: "HTLV-II Infections",
    tree_numbers: ["C01.925.782.815.200.480", "C20.673.483.480"],
  },
  {
    ui: "D015493",
    heading: "Paraparesis, Tropical Spastic",
    tree_numbers: ["C01.207.618.500", "C01.925.782.815.200.470.710", "C10.228.228.618.500", "C10.228.854.525.700"],
    synonyms: ["Htlv-Associated Myelopathy", "Tropical Spastic Paralysis", "Tropical Spastic Paraplegia"],
  },
  {
    ui: "D015494",
    heading: "Salivary Gland Calculi",
    tree_numbers: ["C07.465.815.497.500", "C23.300.175.700.500"],
    synonyms: ["Stone Of Salivary Gland Or Duct", "Salivary Gland Stone", "Sialolith"],
  },
  {
    ui: "D015499",
    heading: "Renal Tubular Transport, Inborn Errors",
    tree_numbers: ["C12.777.419.815", "C13.351.968.419.815", "C16.320.565.893", "C16.320.831", "C18.452.648.893"],
    synonyms: ["Inborn Renal Tubular Transport Disorder"],
  },
  {
    ui: "D015508",
    heading: "Nasal Obstruction",
    tree_numbers: ["C08.460.525", "C08.618.846.185.525", "C09.603.525"],
  },
  {
    ui: "D015511",
    heading: "Goat Diseases",
    tree_numbers: ["C22.405"],
  },
  {
    ui: "D015518",
    heading: "Rett Syndrome",
    tree_numbers: ["C10.597.606.360.455.937", "C16.320.322.500.937", "C16.320.400.525.937"],
    synonyms: ["Cerebroatrophic Hyperammonemia", "Rett's Disorder"],
  },
  {
    ui: "D015521",
    heading: "Ethmoid Sinusitis",
    tree_numbers: ["C01.748.749.267", "C08.460.692.752.267", "C08.730.749.267", "C09.603.692.752.267"],
    synonyms: ["Ethmoidal Sinusitis", "Ethmoiditis"],
  },
  {
    ui: "D015522",
    heading: "Frontal Sinusitis",
    tree_numbers: ["C01.748.749.387", "C08.460.692.752.387", "C08.730.749.387", "C09.603.692.752.387"],
  },
  {
    ui: "D015523",
    heading: "Maxillary Sinusitis",
    tree_numbers: ["C01.748.749.578", "C08.460.692.752.578", "C08.730.749.578", "C09.603.692.752.578"],
  },
  {
    ui: "D015524",
    heading: "Sphenoid Sinusitis",
    tree_numbers: ["C01.748.749.827", "C08.460.692.752.827", "C08.730.749.827", "C09.603.692.752.827"],
    synonyms: ["Sphenoidal Sinusitis"],
  },
  {
    ui: "D015526",
    heading: "AIDS Dementia Complex",
    tree_numbers: [
      "C01.221.250.875.049",
      "C01.221.812.640.400.070",
      "C01.778.640.400.070",
      "C01.925.782.815.616.400.049",
      "C01.925.813.400.070",
      "C10.228.140.380.070",
      "C20.673.480.070",
      "F03.615.400.050",
    ],
  },
  {
    ui: "D015529",
    heading: "Choledochal Cyst",
    tree_numbers: ["C04.182.198", "C06.130.120.127", "C06.198.184", "C16.131.314.184"],
    synonyms: ["Congenital Choledochal Cyst"],
  },
  {
    ui: "D015535",
    heading: "Arthritis, Psoriatic",
    tree_numbers: ["C05.116.900.853.625.800.424", "C05.550.114.145", "C05.550.114.865.800.424", "C17.800.859.675.175"],
    synonyms: ["Arthropathic Psoriasis", "Arthritis Psoriatica"],
  },
  {
    ui: "D015576",
    heading: "Hyperostosis",
    tree_numbers: ["C05.116.540"],
    synonyms: ["Hypertrophy Of Bone", "Bone Hypertrophy"],
  },
  {
    ui: "D015593",
    heading: "Retinal Drusen",
    tree_numbers: ["C11.768.585.585"],
  },
  {
    ui: "D015594",
    heading: "Optic Disk Drusen",
    tree_numbers: ["C10.292.700.450", "C11.640.513"],
    synonyms: ["Optic Nerve Head Drusen", "Drusen Of Optic Disc"],
  },
  {
    ui: "D015597",
    heading: "Pregnancy Complications, Parasitic",
    tree_numbers: ["C01.610.718", "C13.703.700.680"],
  },
  {
    ui: "D015605",
    heading: "Cowpox",
    tree_numbers: ["C01.925.256.743.175"],
    synonyms: ["Yaba"],
  },
  {
    ui: "D015614",
    heading: "Histiocytosis",
    tree_numbers: ["C15.604.250"],
    synonyms: ["Chronic Histiocytosis X", "Hand Schuller Christian Disease"],
  },
  {
    ui: "D015615",
    heading: "Cystic Adenomatoid Malformation of Lung, Congenital",
    tree_numbers: ["C08.381.150", "C08.695.290", "C16.131.740.290"],
  },
  {
    ui: "D015616",
    heading: "Histiocytosis, Non-Langerhans-Cell",
    tree_numbers: ["C15.604.250.410"],
    synonyms: [
      "Reticulohistiocytoma",
      "Solitary Reticulohistiocytoma",
      "Solitary Reticulohistiocytoma",
      "Reticulohistiocytic Granuloma",
    ],
  },
  {
    ui: "D015618",
    heading: "Histiocytosis, Sinus",
    tree_numbers: ["C15.604.250.410.450"],
  },
  {
    ui: "D015619",
    heading: "Respiratory System Abnormalities",
    tree_numbers: ["C08.695", "C16.131.740"],
  },
  {
    ui: "D015620",
    heading: "Histiocytic Disorders, Malignant",
    tree_numbers: ["C04.557.227", "C15.604.250.390"],
  },
  {
    ui: "D015624",
    heading: "Lambert-Eaton Myasthenic Syndrome",
    tree_numbers: [
      "C04.588.614.550.500.225",
      "C04.730.856.490.225",
      "C10.114.656.150",
      "C10.574.781.588.225",
      "C10.668.758.725.150",
      "C20.111.258.500.150",
    ],
    synonyms: ["Lems", "Lambert-Eaton Syndrome", "Eaton-Lambert Syndrome"],
  },
  {
    ui: "D015651",
    heading: "Mycotoxicosis",
    tree_numbers: ["C25.723.680"],
  },
  {
    ui: "D015658",
    heading: "HIV Infections",
    tree_numbers: [
      "C01.221.250.875",
      "C01.221.812.640.400",
      "C01.778.640.400",
      "C01.925.782.815.616.400",
      "C01.925.813.400",
      "C20.673.480",
    ],
    synonyms: ["Hiv Infection"],
  },
  {
    ui: "D015663",
    heading: "Osteoporosis, Postmenopausal",
    tree_numbers: ["C05.116.198.579.610", "C18.452.104.579.610"],
  },
  {
    ui: "D015673",
    heading: "Fatigue Syndrome, Chronic",
    tree_numbers: ["C01.925.330", "C05.651.310", "C10.228.440.600", "C10.668.364"],
    synonyms: ["Myalgic Encephalitis", "Cfs", "Postviral Fatigue Syndrome", "Myalgic Encephalomyelitis"],
  },
  {
    ui: "D015674",
    heading: "Mammary Neoplasms, Animal",
    tree_numbers: ["C04.588.531", "C22.520"],
  },
  {
    ui: "D015701",
    heading: "Near Drowning",
    tree_numbers: ["C26.304.500", "N06.850.135.696.500"],
  },
  {
    ui: "D015715",
    heading: "Corneal Edema",
    tree_numbers: ["C11.204.267"],
    synonyms: ["Corneal Oedema"],
  },
  {
    ui: "D015745",
    heading: "Granuloma, Foreign-Body",
    tree_numbers: ["C23.550.382.437", "C26.392.560.325"],
  },
  {
    ui: "D015746",
    heading: "Abdominal Pain",
    tree_numbers: ["C23.888.592.612.054", "C23.888.821.030"],
  },
  {
    ui: "D015769",
    heading: "Granuloma, Respiratory Tract",
    tree_numbers: ["C08.280", "C23.550.382.968"],
  },
  {
    ui: "D015775",
    heading: "Fractures, Stress",
    tree_numbers: ["C26.404.437"],
  },
  {
    ui: "D015776",
    heading: "Keratoderma, Palmoplantar, Diffuse",
    tree_numbers: ["C16.320.850.475.440", "C17.800.428.435.440", "C17.800.827.475.440"],
    synonyms: [
      "Thost-Unna Syndrome",
      "Diffuse Nonepidermolytic Palmomplantar Keratoderma",
      "Unna-Thost Syndrome",
      "Tylosis",
    ],
  },
  {
    ui: "D015783",
    heading: "Aniridia",
    tree_numbers: ["C11.250.060", "C11.270.060", "C11.941.375.060", "C16.131.384.079", "C16.320.290.078"],
    synonyms: ["Aplasia Of Iris"],
  },
  {
    ui: "D015785",
    heading: "Eye Diseases, Hereditary",
    tree_numbers: ["C11.270", "C16.320.290"],
  },
  {
    ui: "D015787",
    heading: "Erythema Chronicum Migrans",
    tree_numbers: [
      "C01.150.252.400.536.400",
      "C01.150.252.400.794.352.250.400",
      "C01.150.252.819.310",
      "C01.800.720.310",
      "C01.920.930.513.400",
      "C17.800.229.200",
      "C17.800.838.765.310",
    ],
  },
  {
    ui: "D015792",
    heading: "Retinal Dysplasia",
    tree_numbers: ["C11.250.666", "C11.270.660", "C11.768.660", "C16.131.384.784", "C16.320.290.660"],
  },
  {
    ui: "D015794",
    heading: "Choroideremia",
    tree_numbers: ["C11.270.142", "C11.941.160.300", "C16.320.290.142", "C16.320.322.092"],
    synonyms: ["Progressive Choroidal Atrophy"],
  },
  {
    ui: "D015799",
    heading: "Gyrate Atrophy",
    tree_numbers: ["C11.270.468", "C11.941.160.578", "C16.320.290.468"],
    synonyms: [
      "Ornithinemia With Gyrate Atrophy",
      "Gyrate Atrophy Of The Choroid And/or Retina",
      "Gyrate Atrophy Of The Retina",
    ],
  },
  {
    ui: "D015807",
    heading: "Eye Injuries, Penetrating",
    tree_numbers: ["C10.900.300.284.250.270", "C26.915.300.425.250.270", "C26.986.450"],
  },
  {
    ui: "D015811",
    heading: "Iris Neoplasms",
    tree_numbers: ["C04.588.364.978.400", "C11.319.494.400", "C11.941.375.375", "C11.941.855.400"],
    synonyms: ["Malignant Tumor Of Iris", "Tumor Of The Iris", "Malignant Neoplasm Of The Iris"],
  },
  {
    ui: "D015812",
    heading: "Glaucoma, Angle-Closure",
    tree_numbers: ["C11.525.381.056"],
    synonyms: [
      "Angle-Closure Glaucoma",
      "Primary Open-Angle Glaucoma With Narrow Angles",
      "Narrow Cleft Glaucoma",
      "Angle Closure Glaucoma",
      "Acg - Angle-Closure Glaucoma",
      "Closed Angle Glaucoma",
    ],
  },
  {
    ui: "D015814",
    heading: "Ocular Hypotension",
    tree_numbers: ["C11.540"],
    synonyms: ["Hypotony Of Eye"],
  },
  {
    ui: "D015817",
    heading: "Eye Infections",
    tree_numbers: ["C01.375", "C11.294"],
  },
  {
    ui: "D015818",
    heading: "Eye Infections, Bacterial",
    tree_numbers: ["C01.150.252.289", "C01.375.354", "C11.294.354"],
  },
  {
    ui: "D015819",
    heading: "Substance Abuse, Intravenous",
    tree_numbers: ["C25.775.793", "F03.900.793"],
  },
  {
    ui: "D015821",
    heading: "Eye Infections, Fungal",
    tree_numbers: ["C01.150.703.320", "C01.375.450", "C11.294.450"],
  },
  {
    ui: "D015822",
    heading: "Eye Infections, Parasitic",
    tree_numbers: ["C01.610.300", "C11.294.725"],
  },
  {
    ui: "D015823",
    heading: "Acanthamoeba Keratitis",
    tree_numbers: ["C01.610.300.125", "C01.610.752.049.203", "C11.204.564.112", "C11.294.725.125"],
  },
  {
    ui: "D015826",
    heading: "Langer-Giedion Syndrome",
    tree_numbers: ["C05.116.099.708.582"],
    synonyms: [
      "Trichorhinophalangeal Syndrome Type 2",
      "Langer-Giedion Syndrome",
      "Trichorhinophalangeal Dysplasia Type Ii",
    ],
  },
  {
    ui: "D015827",
    heading: "Onchocerciasis, Ocular",
    tree_numbers: ["C01.610.300.562", "C01.610.335.508.700.750.361.699.500", "C01.920.891", "C11.294.725.562"],
  },
  {
    ui: "D015828",
    heading: "Eye Infections, Viral",
    tree_numbers: ["C01.375.725", "C01.925.325", "C11.294.800"],
  },
  {
    ui: "D015829",
    heading: "Uveitis, Suppurative",
    tree_numbers: [
      "C01.150.252.289.900",
      "C01.150.703.320.900",
      "C01.375.265.500",
      "C01.375.354.900",
      "C01.375.450.900",
      "C01.830.900",
      "C11.294.265.500",
      "C11.294.354.900",
      "C11.294.450.900",
      "C11.941.879.960",
    ],
  },
  {
    ui: "D015831",
    heading: "Osteochondroma",
    tree_numbers: ["C04.557.450.565.575.610", "C05.116.099.708.670"],
  },
  {
    ui: "D015834",
    heading: "Cochlear Diseases",
    tree_numbers: ["C09.218.568.120"],
  },
  {
    ui: "D015835",
    heading: "Ocular Motility Disorders",
    tree_numbers: ["C10.228.758", "C10.292.562", "C11.590"],
    synonyms: ["Disorder Of Eye Movements", "Eye Movement Disorder"],
  },
  {
    ui: "D015837",
    heading: "Vestibular Diseases",
    tree_numbers: ["C09.218.568.900"],
    synonyms: ["Vertigo, Vestibular Disorder"],
  },
  {
    ui: "D015838",
    heading: "Chondromatosis, Synovial",
    tree_numbers: ["C05.550.287"],
  },
  {
    ui: "D015840",
    heading: "Oculomotor Nerve Diseases",
    tree_numbers: ["C10.292.562.700", "C11.590.436"],
    synonyms: [
      "Third Or Oculomotor Nerve Palsy, Partial",
      "Partial Third Nerve Palsy",
      "Third Nerve Palsy With Pupil Sparing",
    ],
  },
  {
    ui: "D015841",
    heading: "Enophthalmos",
    tree_numbers: ["C11.675.319"],
  },
  {
    ui: "D015845",
    heading: "Tonic Pupil",
    tree_numbers: ["C10.597.690.575", "C11.710.800", "C23.888.592.708.575"],
  },
  {
    ui: "D015858",
    heading: "Anisometropia",
    tree_numbers: ["C11.744.126"],
  },
  {
    ui: "D015861",
    heading: "Retinal Neovascularization",
    tree_numbers: ["C11.768.725", "C23.550.589.500.725"],
  },
  {
    ui: "D015862",
    heading: "Choroid Diseases",
    tree_numbers: ["C11.941.160"],
  },
  {
    ui: "D015863",
    heading: "Iridocyclitis",
    tree_numbers: ["C11.941.375.360", "C11.941.879.780.880.400"],
  },
  {
    ui: "D015864",
    heading: "Panuveitis",
    tree_numbers: ["C11.941.879.780"],
    synonyms: ["Diffuse Uveitis"],
  },
  {
    ui: "D015866",
    heading: "Uveitis, Posterior",
    tree_numbers: ["C11.941.879.780.900"],
    synonyms: ["Uveitis, Posterior"],
  },
  {
    ui: "D015867",
    heading: "Uveitis, Intermediate",
    tree_numbers: ["C11.941.879.900"],
    synonyms: ["Peripheral Uveoretinitis", "Chronic Cyclitis"],
  },
  {
    ui: "D015868",
    heading: "Pars Planitis",
    tree_numbers: ["C11.941.160.478.700", "C11.941.879.780.900.300.659", "C11.941.879.900.500"],
    synonyms: ["Posterior Cyclitis"],
  },
  {
    ui: "D015875",
    heading: "Anisocoria",
    tree_numbers: ["C10.597.690.150", "C11.710.090", "C23.888.592.708.150"],
  },
  {
    ui: "D015877",
    heading: "Miosis",
    tree_numbers: ["C10.597.690.362", "C11.710.528", "C23.888.592.708.362"],
  },
  {
    ui: "D015878",
    heading: "Mydriasis",
    tree_numbers: ["C11.710.570"],
  },
  {
    ui: "D015882",
    heading: "Retinal Necrosis Syndrome, Acute",
    tree_numbers: ["C11.768.773.674"],
    synonyms: ["Acute Retinal Necrosis"],
  },
  {
    ui: "D016055",
    heading: "Urinary Retention",
    tree_numbers: ["C12.777.934.880", "C13.351.968.934.880"],
  },
  {
    ui: "D016063",
    heading: "Blood Loss, Surgical",
    tree_numbers: ["C23.550.414.300", "C23.550.505.300"],
  },
  {
    ui: "D016066",
    heading: "Pleural Effusion, Malignant",
    tree_numbers: ["C04.588.894.797.640.700", "C08.528.652.700", "C08.528.694.700", "C08.785.640.700"],
  },
  {
    ui: "D016080",
    heading: "Arachnoid Cysts",
    tree_numbers: [
      "C04.182.044",
      "C04.588.614.250.387.100",
      "C10.500.142.100",
      "C10.551.240.375.100",
      "C16.131.666.142.100",
    ],
  },
  {
    ui: "D016097",
    heading: "Simian Acquired Immunodeficiency Syndrome",
    tree_numbers: ["C01.925.782.815.616.850", "C01.925.839.850", "C22.735.500.850"],
  },
  {
    ui: "D016098",
    heading: "Gerstmann-Straussler-Scheinker Disease",
    tree_numbers: ["C01.207.800.350", "C10.228.228.800.350", "C10.574.500.425", "C10.574.843.400", "C16.320.400.350"],
    synonyms: ["Prion Dementia", "Gerstmann-Straussler-Scheinker Disease"],
  },
  {
    ui: "D016103",
    heading: "Spinal Fractures",
    tree_numbers: ["C26.117.500.500", "C26.404.812"],
  },
  {
    ui: "D016104",
    heading: "Oligohydramnios",
    tree_numbers: ["C13.703.560"],
    synonyms: ["Delivered Oligohydramnios", "Oligohydramnios - Delivered", "Antepartum Oligohydramnios"],
  },
  {
    ui: "D016107",
    heading: "Epidermolysis Bullosa Acquisita",
    tree_numbers: ["C16.131.831.493.080", "C17.800.804.493.080", "C17.800.827.275.080", "C17.800.865.410.080"],
    synonyms: ["Acquired Epidermolysis Bullosa"],
  },
  {
    ui: "D016108",
    heading: "Epidermolysis Bullosa Dystrophica",
    tree_numbers: [
      "C16.131.831.493.160",
      "C16.320.850.275.160",
      "C17.300.200.367",
      "C17.800.804.493.160",
      "C17.800.827.275.160",
      "C17.800.865.410.160",
    ],
    synonyms: ["Dystrophic Epidermolysis Bullosa"],
  },
  {
    ui: "D016109",
    heading: "Epidermolysis Bullosa, Junctional",
    tree_numbers: [
      "C16.131.831.493.170",
      "C16.320.850.275.170",
      "C17.800.804.493.170",
      "C17.800.827.275.170",
      "C17.800.865.410.170",
    ],
    synonyms: ["Congenital Junctional Epidermolysis Bullosa"],
  },
  {
    ui: "D016110",
    heading: "Epidermolysis Bullosa Simplex",
    tree_numbers: [
      "C16.131.831.493.180",
      "C16.320.850.275.180",
      "C17.800.804.493.180",
      "C17.800.827.275.180",
      "C17.800.865.410.180",
    ],
    synonyms: ["Epidermolysis Bullosa Simplex"],
  },
  {
    ui: "D016111",
    heading: "Sjogren-Larsson Syndrome",
    tree_numbers: [
      "C16.131.831.512.723",
      "C16.320.565.398.641.723",
      "C16.320.850.820",
      "C16.614.492.723",
      "C17.800.428.333.723",
      "C17.800.804.512.723",
      "C17.800.827.820",
      "C18.452.584.687.723",
      "C18.452.648.398.641.723",
    ],
    synonyms: [
      "Sjogren Larsson Syndrome",
      "Faldh Deficiency",
      "Fatty Acid Alcohol Oxidoreductase Deficiency",
      "Sjogren-Larsson's Syndrome",
      "Sls",
    ],
  },
  {
    ui: "D016112",
    heading: "Ichthyosis Vulgaris",
    tree_numbers: [
      "C16.131.831.512.410",
      "C16.320.850.405",
      "C17.800.428.333.410",
      "C17.800.804.512.410",
      "C17.800.827.405",
    ],
    synonyms: ["Dominant Congenital Ichthyosiform Erythroderma"],
  },
  {
    ui: "D016113",
    heading: "Ichthyosiform Erythroderma, Congenital",
    tree_numbers: [
      "C16.131.831.512.400",
      "C16.320.850.400",
      "C16.614.492.400",
      "C17.800.428.333.250",
      "C17.800.804.512.400",
      "C17.800.827.400",
    ],
  },
  {
    ui: "D016114",
    heading: "Ichthyosis, X-Linked",
    tree_numbers: [
      "C16.131.831.512.420",
      "C16.320.322.241",
      "C16.320.565.925.400",
      "C16.320.850.408",
      "C16.614.492.420",
      "C17.800.428.333.420",
      "C17.800.804.512.420",
      "C17.800.827.408",
      "C18.452.648.925.400",
    ],
    synonyms: [
      "X-Linked Placental Steryl-Sulphatase Deficiency",
      "X-Linked Ichthyosis With Steryl-Sulphatase Deficiency",
      "X-Linked Recessive Ichthyosis",
    ],
  },
  {
    ui: "D016115",
    heading: "Albinism, Oculocutaneous",
    tree_numbers: [
      "C11.270.040.545",
      "C16.320.290.040.100",
      "C16.320.565.100.102.100",
      "C16.320.850.080.100",
      "C17.800.621.440.102.100",
      "C17.800.827.080.100",
      "C18.452.648.100.102.100",
    ],
  },
  {
    ui: "D016116",
    heading: "Piebaldism",
    tree_numbers: [
      "C16.320.290.040.600",
      "C16.320.565.100.102.600",
      "C16.320.850.080.600",
      "C17.800.621.440.102.600",
      "C17.800.827.080.600",
      "C18.452.648.100.102.600",
    ],
    synonyms: ["Partial Albinism", "Piebald Trait"],
  },
  {
    ui: "D016117",
    heading: "Albinism, Ocular",
    tree_numbers: [
      "C11.270.040.090",
      "C16.320.290.040.090",
      "C16.320.565.100.102.090",
      "C16.320.850.080.090",
      "C17.800.621.440.102.090",
      "C17.800.827.080.090",
      "C18.452.648.100.102.090",
    ],
  },
  {
    ui: "D016127",
    heading: "Heart Valve Prolapse",
    tree_numbers: ["C14.280.484.400"],
    synonyms: ["Valvular Heart Disease"],
  },
  {
    ui: "D016135",
    heading: "Spinal Dysraphism",
    tree_numbers: ["C10.500.680.800", "C16.131.666.680.800"],
  },
  {
    ui: "D016136",
    heading: "Spina Bifida Occulta",
    tree_numbers: ["C10.500.680.800.750", "C16.131.666.680.800.750"],
  },
  {
    ui: "D016137",
    heading: "Spina Bifida Cystica",
    tree_numbers: ["C10.500.680.800.730", "C16.131.666.680.800.730"],
  },
  {
    ui: "D016142",
    heading: "Holoprosencephaly",
    tree_numbers: [
      "C05.660.207.410",
      "C10.500.034.875",
      "C16.131.077.410",
      "C16.131.260.380",
      "C16.131.621.207.410",
      "C16.131.666.034.875",
      "C16.320.180.380",
    ],
    synonyms: ["Holoprosencephaly Sequence"],
  },
  {
    ui: "D016154",
    heading: "Digestive System Fistula",
    tree_numbers: ["C06.267", "C23.300.575.185"],
  },
  {
    ui: "D016155",
    heading: "Oral Fistula",
    tree_numbers: ["C07.465.614", "C23.300.575.500"],
  },
  {
    ui: "D016156",
    heading: "Respiratory Tract Fistula",
    tree_numbers: ["C08.702", "C23.300.575.687"],
  },
  {
    ui: "D016157",
    heading: "Vascular Fistula",
    tree_numbers: ["C14.240.850.984", "C14.907.933", "C23.300.575.950"],
  },
  {
    ui: "D016170",
    heading: "Accelerated Idioventricular Rhythm",
    tree_numbers: ["C14.280.067.845.940.349", "C14.280.123.875.940.349", "C23.550.073.845.940.349"],
  },
  {
    ui: "D016171",
    heading: "Torsades de Pointes",
    tree_numbers: ["C14.280.067.845.940.700", "C14.280.123.875.940.700", "C23.550.073.845.940.700"],
  },
  {
    ui: "D016180",
    heading: "Lentivirus Infections",
    tree_numbers: ["C01.925.782.815.616"],
  },
  {
    ui: "D016181",
    heading: "Feline Acquired Immunodeficiency Syndrome",
    tree_numbers: ["C01.925.782.815.616.350", "C01.925.839.400", "C22.180.350"],
  },
  {
    ui: "D016182",
    heading: "Visna",
    tree_numbers: ["C01.925.782.815.616.900", "C01.925.839.900", "C22.836.900"],
  },
  {
    ui: "D016183",
    heading: "Murine Acquired Immunodeficiency Syndrome",
    tree_numbers: ["C01.925.782.815.650", "C01.925.928.650", "C22.795.650"],
  },
  {
    ui: "D016262",
    heading: "Postpoliomyelitis Syndrome",
    tree_numbers: [
      "C01.207.618.750.750",
      "C01.925.782.687.359.764.650",
      "C05.651.534.750",
      "C10.228.228.618.750.750",
      "C10.228.854.525.850.750",
      "C10.574.827",
      "C10.668.491.175.750",
      "C10.668.864.500",
    ],
    synonyms: ["Postpolio Syndrome"],
  },
  {
    ui: "D016263",
    heading: "AIDS-Associated Nephropathy",
    tree_numbers: [
      "C01.221.250.875.050",
      "C01.221.812.640.400.072",
      "C01.778.640.400.072",
      "C01.925.782.815.616.400.050",
      "C01.925.813.400.072",
      "C12.777.419.050",
      "C13.351.968.419.050",
      "C20.673.480.050",
    ],
  },
  {
    ui: "D016269",
    heading: "Milk Hypersensitivity",
    tree_numbers: ["C20.543.480.370.500"],
    synonyms: ["Milk Allergic Reaction"],
  },
  {
    ui: "D016301",
    heading: "Alveolar Bone Loss",
    tree_numbers: ["C05.116.264.150", "C07.465.714.354.500"],
  },
  {
    ui: "D016369",
    heading: "Smear Layer",
    tree_numbers: ["C07.793.208.688"],
  },
  {
    ui: "D016388",
    heading: "Tooth Loss",
    tree_numbers: ["C07.465.714.804", "C07.793.870"],
  },
  {
    ui: "D016393",
    heading: "Lymphoma, B-Cell",
    tree_numbers: ["C04.557.386.480.150", "C15.604.515.569.480.150", "C20.683.515.761.480.150"],
    synonyms: ["B-Cell Lymphocytic Neoplasm"],
  },
  {
    ui: "D016399",
    heading: "Lymphoma, T-Cell",
    tree_numbers: ["C04.557.386.480.750", "C15.604.515.569.480.750", "C20.683.515.761.480.750"],
  },
  {
    ui: "D016400",
    heading: "Lymphoma, Large-Cell, Immunoblastic",
    tree_numbers: ["C04.557.386.480.493", "C15.604.515.569.480.493", "C20.683.515.761.480.487"],
  },
  {
    ui: "D016403",
    heading: "Lymphoma, Large B-Cell, Diffuse",
    tree_numbers: ["C04.557.386.480.150.585", "C15.604.515.569.480.150.585", "C20.683.515.761.480.150.585"],
    synonyms: ["Dlbcl"],
  },
  {
    ui: "D016410",
    heading: "Lymphoma, T-Cell, Cutaneous",
    tree_numbers: ["C04.557.386.480.750.800", "C15.604.515.569.480.750.800", "C20.683.515.761.480.750.800"],
    synonyms: ["Cutaneous T-Cell Lymphoma"],
  },
  {
    ui: "D016411",
    heading: "Lymphoma, T-Cell, Peripheral",
    tree_numbers: ["C04.557.386.480.750.825", "C15.604.515.569.480.750.825", "C20.683.515.761.480.750.825"],
  },
  {
    ui: "D016459",
    heading: "Prosthesis-Related Infections",
    tree_numbers: ["C01.685", "C23.550.767.868"],
  },
  {
    ui: "D016460",
    heading: "Granuloma Annulare",
    tree_numbers: ["C17.300.200.495.380", "C17.800.550.380", "C23.550.382.375"],
    synonyms: ["Granulome Annulare", "Granuloma Annulare"],
  },
  {
    ui: "D016463",
    heading: "Sweet Syndrome",
    tree_numbers: ["C17.800.229.800"],
  },
  {
    ui: "D016464",
    heading: "Lysosomal Storage Diseases",
    tree_numbers: ["C16.320.565.595", "C18.452.648.595"],
    synonyms: [
      "Disorder Of Lysosomal Enzyme",
      "Inborn Lysosomal Enzyme Disorder",
      "Lysosomal Storage Metabolism Disorder",
    ],
  },
  {
    ui: "D016469",
    heading: "Fungemia",
    tree_numbers: ["C01.150.703.492.594", "C01.757.360", "C23.550.470.790.500.360"],
  },
  {
    ui: "D016470",
    heading: "Bacteremia",
    tree_numbers: ["C01.150.252.100", "C01.757.100", "C23.550.470.790.500.100"],
  },
  {
    ui: "D016471",
    heading: "Ovarian Hyperstimulation Syndrome",
    tree_numbers: ["C13.351.500.056.630.642", "C19.391.630.642"],
    synonyms: ["Secondary Meig's Syndrome"],
  },
  {
    ui: "D016472",
    heading: "Motor Neuron Disease",
    tree_numbers: ["C10.574.562", "C10.668.467"],
    synonyms: ["Primary Lateral Sclerosis", "Adult-Onset Primary Lateral Sclerosis"],
  },
  {
    ui: "D016481",
    heading: "Helicobacter Infections",
    tree_numbers: ["C01.150.252.400.466"],
  },
  {
    ui: "D016483",
    heading: "Lymphoma, AIDS-Related",
    tree_numbers: ["C04.557.386.480.150.450", "C15.604.515.569.480.150.450", "C20.683.515.761.480.150.450"],
  },
  {
    ui: "D016489",
    heading: "Head Injuries, Closed",
    tree_numbers: ["C10.900.300.350", "C26.915.300.450", "C26.974.382"],
  },
  {
    ui: "D016491",
    heading: "Peripheral Vascular Diseases",
    tree_numbers: ["C14.907.617"],
  },
  {
    ui: "D016506",
    heading: "Pemphigus, Benign Familial",
    tree_numbers: ["C16.320.850.700", "C17.800.827.700", "C17.800.865.858"],
    synonyms: ["Benign Chronic Pemphigus", "Pemphigus, Benign Familial"],
  },
  {
    ui: "D016510",
    heading: "Corneal Neovascularization",
    tree_numbers: ["C11.204.290", "C23.550.589.500.435"],
  },
  {
    ui: "D016511",
    heading: "Severe Combined Immunodeficiency",
    tree_numbers: ["C16.320.798.750", "C16.614.815", "C18.452.284.800", "C20.673.795.750"],
    synonyms: ["Blsii", "Scid Due To Absent Class Ii Hla Antigens", "Bare Lymphocyte Syndrome Type Ii"],
  },
  {
    ui: "D016512",
    heading: "Ankle Injuries",
    tree_numbers: ["C26.558.100"],
  },
  {
    ui: "D016518",
    heading: "Neurofibromatosis 2",
    tree_numbers: [
      "C04.557.465.625.650.595.610.500",
      "C04.557.580.600.580.590.655",
      "C04.557.580.600.610.595.610.500",
      "C04.700.631.655",
      "C09.218.807.800.675.500",
      "C09.647.675.500",
      "C10.292.225.750.500",
      "C10.292.910.600.500",
      "C10.562.600.750",
      "C10.574.500.549.700",
      "C16.320.400.560.700",
      "C16.320.700.633.655",
    ],
    synonyms: [
      "Bilateral Acoustic Neurofibromatosis",
      "Acn",
      "Familial Acoustic Neuromas",
      "Banf",
      "Neurofibromatosis Type Ii",
      "Central Neurofibromatosis",
      "Nf2",
      "Bilateral Acoustic Neurinoma",
      "Bilateral Acoustic Schwannomas",
    ],
  },
  {
    ui: "D016523",
    heading: "Foot Ulcer",
    tree_numbers: ["C17.800.321.250", "C17.800.893.592.450"],
  },
  {
    ui: "D016532",
    heading: "Mucopolysaccharidosis II",
    tree_numbers: [
      "C10.597.606.360.455.750",
      "C16.320.322.500.750",
      "C16.320.400.525.750",
      "C16.320.565.202.715.645",
      "C16.320.565.595.600.645",
      "C17.300.550.575.645",
      "C18.452.648.202.715.645",
      "C18.452.648.595.600.645",
    ],
    synonyms: [
      "Mucopolysaccharidosis, Mps-Ii",
      "Deficiency Of Iduronate-2-Sulphatase",
      "Hunter's Syndrome",
      "Hunter Syndrome",
      "Mps Ii - Hunter Syndrome",
    ],
  },
  {
    ui: "D016534",
    heading: "Cardiac Output, High",
    tree_numbers: ["C14.280.142", "C23.888.176"],
  },
  {
    ui: "D016535",
    heading: "Bronchial Hyperreactivity",
    tree_numbers: ["C08.127.210"],
  },
  {
    ui: "D016537",
    heading: "Gangliosidosis, GM1",
    tree_numbers: [
      "C10.228.140.163.100.435.825.300.400",
      "C16.320.565.189.435.825.300.400",
      "C16.320.565.398.641.803.350.360",
      "C16.320.565.595.554.825.300.400",
      "C18.452.132.100.435.825.300.400",
      "C18.452.584.687.803.350.360",
      "C18.452.648.189.435.825.300.400",
      "C18.452.648.398.641.803.350.360",
      "C18.452.648.595.554.825.300.400",
    ],
    synonyms: ["Gangliosidosis Gm1", "Beta-Galactosidase Deficiency", "Deficiency Of Beta-Galactosidase"],
  },
  {
    ui: "D016538",
    heading: "Mucopolysaccharidosis VII",
    tree_numbers: [
      "C16.320.565.202.715.675",
      "C16.320.565.595.600.675",
      "C17.300.550.575.675",
      "C18.452.648.202.715.675",
      "C18.452.648.595.600.675",
    ],
    synonyms: [
      "Deficiency Of Beta-Glucuronidase",
      "Mucopolysaccharidosis Vii",
      "Mps Vii - Sly Syndrome",
      "Beta-Glucuronidase Deficiency",
    ],
  },
  {
    ui: "D016543",
    heading: "Central Nervous System Neoplasms",
    tree_numbers: ["C04.588.614.250", "C10.551.240"],
    synonyms: [
      "Malignant Neoplasm Of Central Nervous System",
      "Malignant Tumor Of Cns",
      "Central Nervous System Tumors",
      "Neoplasm Of Central Nervous System",
      "Cns Neoplasm",
      "Central Nervous System Tumor",
    ],
  },
  {
    ui: "D016545",
    heading: "Choroid Plexus Neoplasms",
    tree_numbers: ["C04.588.614.250.195.205.200", "C10.228.140.211.280.300", "C10.551.240.250.200.200"],
    synonyms: ["Tumor Of Choroid Plexus", "Tumor Of The Choroid Plexus"],
  },
  {
    ui: "D016553",
    heading: "Purpura, Thrombocytopenic, Idiopathic",
    tree_numbers: [
      "C15.378.100.802.687.600",
      "C15.378.140.855.925.750.600",
      "C15.378.463.740",
      "C20.111.759",
      "C20.841.600",
      "C23.550.414.950.687.600",
      "C23.888.885.687.687.600",
    ],
    synonyms: [
      "Autoimmune Thrombocytopenic Purpura",
      "Primary Thrombocytopenic Purpura",
      "Immune Thrombocytopenic Purpura",
      "Idiopathic Thrombocytopenic Purpura",
    ],
  },
  {
    ui: "D016569",
    heading: "Blepharophimosis",
    tree_numbers: ["C11.250.090", "C11.338.190", "C16.131.384.190"],
  },
  {
    ui: "D016574",
    heading: "Seasonal Affective Disorder",
    tree_numbers: ["F03.600.300.775"],
    synonyms: ["Winter Depression"],
  },
  {
    ui: "D016575",
    heading: "Hidradenitis",
    tree_numbers: ["C17.800.946.315"],
    synonyms: ["Hidradenitis", "Hydradenitis"],
  },
  {
    ui: "D016582",
    heading: "Leukemia, Feline",
    tree_numbers: ["C01.925.782.815.622", "C04.557.337.385", "C22.180.500"],
  },
  {
    ui: "D016583",
    heading: "Enzootic Bovine Leukosis",
    tree_numbers: ["C01.925.782.815.200.260", "C04.557.337.100", "C20.673.483.260", "C22.196.260"],
  },
  {
    ui: "D016584",
    heading: "Panic Disorder",
    tree_numbers: ["F03.080.700"],
    synonyms: ["Panic Anxiety Syndrome"],
  },
  {
    ui: "D016585",
    heading: "Vaginosis, Bacterial",
    tree_numbers: ["C01.150.252.954", "C13.351.500.894.906.800"],
  },
  {
    ui: "D016586",
    heading: "Granular Cell Tumor",
    tree_numbers: ["C04.557.450.590.350"],
    synonyms: ["Neoplasm Of Granular Cell"],
  },
  {
    ui: "D016602",
    heading: "Needlestick Injuries",
    tree_numbers: ["C26.986.950.500"],
  },
  {
    ui: "D016603",
    heading: "Eosinophilia-Myalgia Syndrome",
    tree_numbers: ["C05.651.290", "C10.668.491.050", "C15.378.553.231.335"],
    synonyms: ["Eosinophilia Myalgia Syndrome"],
  },
  {
    ui: "D016606",
    heading: "Thyroid Nodule",
    tree_numbers: ["C04.588.322.894.800", "C04.588.443.915.800", "C19.344.894.800", "C19.874.788.800"],
  },
  {
    ui: "D016609",
    heading: "Neoplasms, Second Primary",
    tree_numbers: ["C04.692"],
  },
  {
    ui: "D016638",
    heading: "Critical Illness",
    tree_numbers: ["C23.550.291.625"],
  },
  {
    ui: "D016640",
    heading: "Diabetes, Gestational",
    tree_numbers: ["C13.703.170", "C18.452.394.750.448", "C19.246.200"],
    synonyms: ["Gdm", "Gestational Diabetes Mellitus", "Maternal Gestational Diabetes Mellitus"],
  },
  {
    ui: "D016643",
    heading: "Encephalopathy, Bovine Spongiform",
    tree_numbers: ["C01.207.800.260", "C10.228.228.800.260", "C10.574.843.300", "C22.196.250"],
    synonyms: ["Bovine Spongiform Encephalopathy", "Bovine Spongiform Encephalopathy"],
  },
  {
    ui: "D016649",
    heading: "Primary Ovarian Insufficiency",
    tree_numbers: ["C13.351.500.056.630.750", "C19.391.630.750"],
    synonyms: [
      "Hypergonadotropic Hypogonadism",
      "Premature Ovarian Failure",
      "Primary Ovarian Insufficiency",
      "Premature Ovarian Insufficiency",
      "Premature Menopause",
    ],
  },
  {
    ui: "D016657",
    heading: "Cerebral Amyloid Angiopathy",
    tree_numbers: ["C10.228.140.300.510.200.200", "C14.907.253.560.200.200", "C18.452.845.500.100"],
  },
  {
    ui: "D016659",
    heading: "Psoas Abscess",
    tree_numbers: ["C01.830.025.700"],
  },
  {
    ui: "D016672",
    heading: "Zenker Diverticulum",
    tree_numbers: ["C06.405.205.282.750.625.900", "C23.300.415.625.900"],
  },
  {
    ui: "D016697",
    heading: "Herpes Zoster Oticus",
    tree_numbers: ["C01.925.256.466.930.750.733", "C07.465.299.750", "C09.218.513", "C10.292.319.750"],
    synonyms: [
      "Geniculate Neuralgia",
      "Ramsay Hunt Syndrome Type Ii",
      "Ramsay Hunt Syndrome Type 2",
      "Nervus Intermedius Neuralgia",
      "Herpes Zoster Auricularis",
      "Herpes Zoster Oticus",
      "Herpetic Geniculate Ganglionitis",
      "Geniculate Herpes Zoster",
      "Ramsey Hunt Syndrome",
    ],
  },
  {
    ui: "D016706",
    heading: "Still's Disease, Adult-Onset",
    tree_numbers: ["C05.550.114.154.870", "C05.799.114.870", "C17.300.775.099.870", "C20.111.199.870"],
    synonyms: ["Adult Onset Still's Disease", "Adult-Onset Still Disease"],
  },
  {
    ui: "D016710",
    heading: "Yin Deficiency",
    tree_numbers: ["C23.550.972"],
  },
  {
    ui: "D016711",
    heading: "Yang Deficiency",
    tree_numbers: ["C23.550.945"],
  },
  {
    ui: "D016715",
    heading: "Proteus Syndrome",
    tree_numbers: [
      "C04.445.435.500",
      "C04.651.435.500",
      "C05.116.099.750",
      "C05.660.585.620",
      "C16.131.077.740",
      "C16.131.621.585.620",
    ],
    synonyms: ["Wiedemann's Syndrome"],
  },
  {
    ui: "D016720",
    heading: "Pneumocystis Infections",
    tree_numbers: ["C01.150.703.770"],
  },
  {
    ui: "D016724",
    heading: "Empyema, Pleural",
    tree_numbers: ["C01.748.265", "C01.830.305.310", "C08.528.240", "C08.730.265", "C23.550.470.756.305.250"],
    synonyms: [
      "Empyema With Pleural Fistula",
      "Empyema, Chest",
      "Empyema With No Fistula",
      "Pyothorax",
      "Empyema With Fistula",
      "Empyema",
      "Empyema With Fistula",
      "Empyema Of Pleura",
      "Empyema",
      "Pleural Empyema With Fistula",
      "Empyema Without Mention Of Fistula",
      "Abscess Of Thorax",
      "Purulent Pleuritis",
      "Empyema Of Pleura Without Fistula",
      "Lung Empyema",
      "Thorax Abscess",
      "Purulent Pleurisy",
      "Pleural Empyema With No Fistula",
      "Abscess Of Pleural Cavity",
    ],
  },
  {
    ui: "D016726",
    heading: "Plasma Cell Granuloma, Pulmonary",
    tree_numbers: ["C08.381.600"],
    synonyms: [
      "Sclerosing Hemangiocytoma Of Lung",
      "Granuloma, Plasma Cell, Pulmonary",
      "Lymphocytic Pseudotumor Of Lung",
    ],
  },
  {
    ui: "D016727",
    heading: "Orbital Pseudotumor",
    tree_numbers: ["C11.675.679"],
    synonyms: ["Orbital Myositis", "Pseudotumor Of Orbit"],
  },
  {
    ui: "D016731",
    heading: "Erythema Infectiosum",
    tree_numbers: ["C01.925.256.700.300", "C01.925.825.260", "C17.800.229.335", "C17.800.838.790.260"],
    synonyms: ["Fifth Disease", "Erythema Infectiosum"],
  },
  {
    ui: "D016735",
    heading: "Munchausen Syndrome by Proxy",
    tree_numbers: ["F03.875.375.800"],
  },
  {
    ui: "D016736",
    heading: "Antiphospholipid Syndrome",
    tree_numbers: ["C20.111.197"],
    synonyms: ["Antiphospholipid Antibody Syndrome", "Antiphospholipid Syndrome"],
  },
  {
    ui: "D016738",
    heading: "Alagille Syndrome",
    tree_numbers: [
      "C06.130.120.135.250.125",
      "C06.552.150.125",
      "C14.240.400.044",
      "C16.131.077.065",
      "C16.131.240.400.044",
      "C16.320.051",
    ],
    synonyms: ["Arteriohepatic Dysplasia", "Alagille-Watson Syndrome"],
  },
  {
    ui: "D016750",
    heading: "Stiff-Person Syndrome",
    tree_numbers: ["C10.114.812", "C10.228.854.790", "C10.668.900", "C20.111.258.850"],
    synonyms: ["Stiff-Man Syndrome", "Stiff Man Syndrome"],
  },
  {
    ui: "D016751",
    heading: "Hepatitis E",
    tree_numbers: ["C01.925.440.470", "C01.925.782.455", "C06.552.380.705.470"],
  },
  {
    ui: "D016757",
    heading: "Death, Sudden, Cardiac",
    tree_numbers: ["C14.280.383.220", "C23.550.260.322.250"],
  },
  {
    ui: "D016766",
    heading: "Feline Infectious Peritonitis",
    tree_numbers: ["C01.925.782.600.550.200.360", "C22.180.440"],
  },
  {
    ui: "D016767",
    heading: "Caroli Disease",
    tree_numbers: [
      "C06.130.120.127.500",
      "C06.198.184.500",
      "C16.131.077.245.250",
      "C16.131.314.184.500",
      "C16.320.184.250",
    ],
  },
  {
    ui: "D016769",
    heading: "Embolism and Thrombosis",
    tree_numbers: ["C14.907.355"],
  },
  {
    ui: "D016770",
    heading: "Ciliophora Infections",
    tree_numbers: ["C01.610.752.200"],
  },
  {
    ui: "D016773",
    heading: "Leishmaniasis, Cutaneous",
    tree_numbers: ["C01.610.752.300.500.400", "C01.610.858.560.400", "C01.920.813.400", "C17.800.838.775.560.400"],
    synonyms: ["Diffuse Cutaneous Leishmaniasis", "Asian Desert Cutaneous Leishmaniasis", "Leproid Leishmaniasis"],
  },
  {
    ui: "D016774",
    heading: "Leishmaniasis, Diffuse Cutaneous",
    tree_numbers: [
      "C01.610.752.300.500.400.350",
      "C01.610.858.560.400.350",
      "C01.920.813.400.350",
      "C17.800.838.775.560.400.350",
    ],
  },
  {
    ui: "D016776",
    heading: "Blastocystis Infections",
    tree_numbers: ["C01.610.432.250", "C01.610.752.049.250", "C06.405.469.452.250"],
  },
  {
    ui: "D016778",
    heading: "Malaria, Falciparum",
    tree_numbers: ["C01.610.752.530.650", "C01.920.875.650"],
    synonyms: ["Malaria Fever, Subtertian", "Falciparum Malaria", "Falciparum Malaria", "Malignant Tertian Fever"],
  },
  {
    ui: "D016779",
    heading: "Malaria, Cerebral",
    tree_numbers: [
      "C01.207.205.300.500",
      "C01.610.105.300.500",
      "C01.610.752.530.620",
      "C01.920.875.620",
      "C10.228.228.205.300.500",
    ],
    synonyms: ["Malarial Encephalitis"],
  },
  {
    ui: "D016780",
    heading: "Malaria, Vivax",
    tree_numbers: ["C01.610.752.530.700", "C01.920.875.700"],
    synonyms: ["Malaria By Plasmodium Vivax", "Vivax Malaria"],
  },
  {
    ui: "D016781",
    heading: "Toxoplasmosis, Cerebral",
    tree_numbers: [
      "C01.207.090.800",
      "C01.207.205.300.800",
      "C01.610.105.300.800",
      "C01.610.752.250.800.250",
      "C01.830.025.160.800",
      "C10.228.140.116.800",
      "C10.228.228.090.800",
      "C10.228.228.205.300.800",
    ],
  },
  {
    ui: "D016849",
    heading: "Keratitis, Herpetic",
    tree_numbers: [
      "C01.375.725.465",
      "C01.925.256.466.382.465",
      "C01.925.325.465",
      "C11.204.564.425",
      "C11.294.800.475",
    ],
    synonyms: ["Dendritic Keratitis"],
  },
  {
    ui: "D016857",
    heading: "Hypocapnia",
    tree_numbers: ["C23.888.852.614"],
  },
  {
    ui: "D016862",
    heading: "Tuberculoma, Intracranial",
    tree_numbers: [
      "C01.150.252.223.850.400",
      "C01.150.252.410.040.552.846.493.400",
      "C01.150.252.410.040.552.846.570.300",
      "C01.207.180.850.400",
      "C10.228.228.180.850.400",
    ],
    synonyms: [
      "Tuberculoma Of Brain",
      "Intracranial Tuberculoma",
      "Tuberculosis Of Meninges And Central Nervous System",
      "Tuberculosis Of Meninges And Central Nervous System",
      "Tuberculous Abscess Of Brain",
    ],
  },
  {
    ui: "D016863",
    heading: "Bacillaceae Infections",
    tree_numbers: ["C01.150.252.410.090"],
  },
  {
    ui: "D016864",
    heading: "Li-Fraumeni Syndrome",
    tree_numbers: ["C04.700.600", "C16.320.700.600", "C18.452.284.520"],
    synonyms: [
      "Sbla Syndrome",
      "Li-Fraumeni Familiar Cancer Susceptibility Syndrome",
      "Sarcoma, Breast, Leukaemia And Adrenal Gland Syndrome",
    ],
  },
  {
    ui: "D016866",
    heading: "Bacteroidaceae Infections",
    tree_numbers: ["C01.150.252.400.110"],
  },
  {
    ui: "D016868",
    heading: "Serratia Infections",
    tree_numbers: ["C01.150.252.400.310.850"],
  },
  {
    ui: "D016869",
    heading: "Ureaplasma Infections",
    tree_numbers: ["C01.150.252.400.610.850"],
  },
  {
    ui: "D016870",
    heading: "Neisseriaceae Infections",
    tree_numbers: ["C01.150.252.400.625"],
  },
  {
    ui: "D016871",
    heading: "Pasteurellaceae Infections",
    tree_numbers: ["C01.150.252.400.700"],
  },
  {
    ui: "D016873",
    heading: "Ehrlichiosis",
    tree_numbers: ["C01.150.252.400.054.750", "C01.150.252.400.285", "C01.920.930.300"],
    synonyms: ["Human Ehrlichiosis"],
  },
  {
    ui: "D016878",
    heading: "POEMS Syndrome",
    tree_numbers: ["C10.668.829.800.700", "C15.378.147.780.750", "C16.131.077.703", "C20.683.780.750"],
  },
  {
    ui: "D016881",
    heading: "Microsporidiosis",
    tree_numbers: ["C01.150.703.617"],
    synonyms: ["Infection By Microsporida", "Infection By Microspora", "Infection By Microsporea"],
  },
  {
    ui: "D016883",
    heading: "Diabetic Ketoacidosis",
    tree_numbers: ["C18.452.076.176.652.500", "C18.452.394.750.535", "C19.246.099.812"],
    synonyms: ["Diabetes Mellitus, Ketosis-Prone", "Ketosis-Prone Diabetes Mellitus"],
  },
  {
    ui: "D016884",
    heading: "Polyendocrinopathies, Autoimmune",
    tree_numbers: ["C19.787", "C20.111.750"],
    synonyms: ["Lloyd's Syndrome", "Autoimmune Polyendocrinopathy", "Autoimmune Polyglandular Failure"],
  },
  {
    ui: "D016888",
    heading: "Angiodysplasia",
    tree_numbers: ["C14.907.075"],
    synonyms: ["Angiodysplasia Of Stomach And Duodenum With Hemorrhage"],
  },
  {
    ui: "D016889",
    heading: "Endometrial Neoplasms",
    tree_numbers: ["C04.588.945.418.948.585", "C13.351.500.852.762.200", "C13.351.937.418.875.200"],
    synonyms: [
      "Carcinoma Of The Endometrium",
      "Endometrioid Carcinoma Of Female Reproductive System",
      "Endometrioid Carcinoma",
    ],
  },
  {
    ui: "D016890",
    heading: "Encephalitozoonosis",
    tree_numbers: ["C01.150.703.617.300"],
    synonyms: ["Infection By Encephalitozoon"],
  },
  {
    ui: "D016891",
    heading: "Polycystic Kidney, Autosomal Dominant",
    tree_numbers: [
      "C12.777.419.403.875.500",
      "C13.351.968.419.403.875.500",
      "C16.131.077.717.500",
      "C16.320.184.625.500",
    ],
  },
  {
    ui: "D016893",
    heading: "Carotid Stenosis",
    tree_numbers: ["C10.228.140.300.200.360", "C14.907.137.230", "C14.907.253.123.360"],
    synonyms: ["Carotid Artery Stenosis", "Stenosis, Carotid Artery"],
  },
  {
    ui: "D016905",
    heading: "Gram-Negative Bacterial Infections",
    tree_numbers: ["C01.150.252.400"],
  },
  {
    ui: "D016908",
    heading: "Gram-Positive Bacterial Infections",
    tree_numbers: ["C01.150.252.410"],
  },
  {
    ui: "D016916",
    heading: "Joint Deformities, Acquired",
    tree_numbers: ["C05.550.515"],
  },
  {
    ui: "D016917",
    heading: "Angiomatosis, Bacillary",
    tree_numbers: [
      "C01.150.252.400.126.100.075",
      "C01.150.252.819.150",
      "C01.800.720.150",
      "C14.907.077.060",
      "C17.800.838.765.150",
      "C17.800.862.060",
    ],
  },
  {
    ui: "D016918",
    heading: "Arthritis, Reactive",
    tree_numbers: ["C01.100.500", "C05.116.900.853.625.800.637", "C05.550.114.099.500", "C05.550.114.865.800.637"],
    synonyms: [
      "Post-Bacterial Arthropathy",
      "Reactive Arthritis",
      "Reiter Disease",
      "Reiter's Disease",
      "Postdysenteric Arthropathy",
      "Fiessinger Leroy Reiter Syndrome",
    ],
  },
  {
    ui: "D016919",
    heading: "Meningitis, Cryptococcal",
    tree_numbers: [
      "C01.150.703.181.500.500",
      "C01.150.703.248.290",
      "C01.207.198.500.500",
      "C10.228.228.198.500.500",
      "C10.228.614.300.500",
    ],
  },
  {
    ui: "D016920",
    heading: "Meningitis, Bacterial",
    tree_numbers: ["C01.150.252.223.500", "C01.207.180.500", "C10.228.228.180.500", "C10.228.614.280"],
  },
  {
    ui: "D016921",
    heading: "Meningitis, Fungal",
    tree_numbers: ["C01.150.703.181.500", "C01.207.198.500", "C10.228.228.198.500", "C10.228.614.300"],
  },
  {
    ui: "D017001",
    heading: "Tooth Demineralization",
    tree_numbers: ["C07.793.720"],
  },
  {
    ui: "D017029",
    heading: "Epilepsy, Complex Partial",
    tree_numbers: ["C10.228.140.490.360.260"],
    synonyms: [
      "Complex Partial Epileptic Seizure",
      "Psychomotor Epilepsy",
      "Epilepsy, Psychomotor",
      "Psychomotor Epilepsy",
    ],
  },
  {
    ui: "D017034",
    heading: "Epilepsy, Frontal Lobe",
    tree_numbers: ["C10.228.140.490.360.270", "C10.228.140.490.493.188"],
    synonyms: ["Frontal Lobe Epilepsy"],
  },
  {
    ui: "D017036",
    heading: "Epilepsia Partialis Continua",
    tree_numbers: ["C10.597.742.785.260", "C23.888.592.742.785.260"],
  },
  {
    ui: "D017042",
    heading: "Phenylketonuria, Maternal",
    tree_numbers: [
      "C10.228.140.163.100.687.500",
      "C13.703.575",
      "C16.320.565.100.766.500",
      "C16.320.565.189.687.500",
      "C18.452.132.100.687.500",
      "C18.452.648.100.766.500",
      "C18.452.648.189.687.500",
    ],
    synonyms: ["Phenylalaninemia", "Folling's Disease", "Pku", "Maternal Phenylketonuria"],
  },
  {
    ui: "D017043",
    heading: "Chalazion",
    tree_numbers: ["C04.182.197", "C11.338.300"],
    synonyms: ["Chalazion", "Meibomian Gland Lipogranuloma"],
  },
  {
    ui: "D017044",
    heading: "Polycystic Kidney, Autosomal Recessive",
    tree_numbers: [
      "C12.777.419.403.875.510",
      "C13.351.968.419.403.875.510",
      "C16.131.077.717.510",
      "C16.320.184.625.510",
    ],
    synonyms: [
      "Arpkd",
      "Polycystic Kidney Disease, Infantile, Type I",
      "Polycystic Kidney And Hepatic Disease 1",
      "Pkhd1",
    ],
  },
  {
    ui: "D017074",
    heading: "Common Variable Immunodeficiency",
    tree_numbers: ["C20.673.330"],
    synonyms: [
      "Cvid",
      "Common Variable Agammaglobulinemia",
      "Acquired Hypogammaglobulinemia",
      "Sporadic Hypogammaglobulinemia",
      "Acquired Agammaglobulinemia",
    ],
  },
  {
    ui: "D017085",
    heading: "alpha-Thalassemia",
    tree_numbers: ["C15.378.071.141.150.875.100", "C15.378.420.826.100", "C16.320.070.875.100", "C16.320.365.826.100"],
    synonyms: ["Alpha-Thalassemia", "Alpha Thalassaemia"],
  },
  {
    ui: "D017086",
    heading: "beta-Thalassemia",
    tree_numbers: ["C15.378.071.141.150.875.150", "C15.378.420.826.150", "C16.320.070.875.150", "C16.320.365.826.150"],
  },
  {
    ui: "D017088",
    heading: "AIDS-Related Opportunistic Infections",
    tree_numbers: [
      "C01.221.250.875.100",
      "C01.597.050",
      "C01.610.684.050",
      "C01.925.597.050",
      "C01.925.782.815.616.400.100",
      "C20.673.480.100",
    ],
  },
  {
    ui: "D017091",
    heading: "Colitis, Ischemic",
    tree_numbers: ["C06.405.205.265.115", "C06.405.469.158.188.115", "C14.907.286"],
  },
  {
    ui: "D017092",
    heading: "Porphyria, Erythropoietic",
    tree_numbers: ["C16.320.850.738", "C17.800.827.738", "C18.452.811.250"],
    synonyms: ["Erythropoietic Porphyria"],
  },
  {
    ui: "D017093",
    heading: "Liver Failure",
    tree_numbers: ["C06.552.308.500"],
  },
  {
    ui: "D017094",
    heading: "Porphyrias, Hepatic",
    tree_numbers: ["C06.552.830", "C16.320.850.742", "C17.800.827.742", "C18.452.811.400"],
    synonyms: ["Hepatic Porphyria"],
  },
  {
    ui: "D017096",
    heading: "Prion Diseases",
    tree_numbers: ["C01.207.800", "C10.228.228.800", "C10.574.843"],
    synonyms: [
      "Transmissible Spongiform Encephalopathy",
      "Prion Induced Disorder",
      "Prion Protein Disease",
      "Spongiform Encephalopathy",
      "Prion Disease Pathway",
    ],
  },
  {
    ui: "D017098",
    heading: "IgA Deficiency",
    tree_numbers: ["C15.378.147.333.500", "C20.673.430.500"],
    synonyms: [
      "Selective Iga Immunodeficiency",
      "Immunoglobulin A Deficiency",
      "Selective Immunoglobulin A Deficiency",
      "Selective Iga Immunodeficiency",
    ],
  },
  {
    ui: "D017099",
    heading: "IgG Deficiency",
    tree_numbers: ["C15.378.147.333.750", "C20.673.430.750"],
    synonyms: [
      "Selective Deficiency Of Igg",
      "Immunoglobin G Subclass Deficiency",
      "Selective Immunoglobulin G Subclass Deficiency",
      "Selective Igg Immunodeficiency",
      "Selective Immunoglobulin G Deficiency",
    ],
  },
  {
    ui: "D017109",
    heading: "Akathisia, Drug-Induced",
    tree_numbers: ["C10.228.662.037", "C10.720.075", "C23.888.592.350.600.500", "C25.100.249", "C25.723.705.100"],
  },
  {
    ui: "D017114",
    heading: "Liver Failure, Acute",
    tree_numbers: ["C06.552.308.500.750"],
  },
  {
    ui: "D017116",
    heading: "Low Back Pain",
    tree_numbers: ["C23.888.592.612.107.400"],
  },
  {
    ui: "D017118",
    heading: "Porphyria, Acute Intermittent",
    tree_numbers: ["C06.552.830.150", "C16.320.850.742.150", "C17.800.827.742.150", "C18.452.811.400.150"],
    synonyms: ["Aip - Acute Intermittent Porphyria", "Pyrroloporphyria", "Porphyria Intermittent Acute"],
  },
  {
    ui: "D017119",
    heading: "Porphyria Cutanea Tarda",
    tree_numbers: ["C06.552.830.100", "C16.320.850.742.250", "C17.800.827.742.250", "C18.452.811.400.250"],
  },
  {
    ui: "D017121",
    heading: "Porphyria, Hepatoerythropoietic",
    tree_numbers: ["C06.552.830.437", "C16.320.850.742.437", "C17.800.827.742.437", "C18.452.811.400.437"],
  },
  {
    ui: "D017129",
    heading: "Anisakiasis",
    tree_numbers: ["C01.610.335.508.700.100.060", "C01.610.432.060", "C06.405.469.452.060"],
    synonyms: ["Infection By Anisakis Larva"],
  },
  {
    ui: "D017169",
    heading: "Neoplasms, Post-Traumatic",
    tree_numbers: ["C04.666"],
  },
  {
    ui: "D017180",
    heading: "Tachycardia, Ventricular",
    tree_numbers: ["C14.280.067.845.940", "C14.280.123.875.940", "C23.550.073.845.940"],
  },
  {
    ui: "D017188",
    heading: "Adenophorea Infections",
    tree_numbers: ["C01.610.335.508.100"],
  },
  {
    ui: "D017189",
    heading: "Enoplida Infections",
    tree_numbers: ["C01.610.335.508.100.275"],
    synonyms: ["Capillaria Infection"],
  },
  {
    ui: "D017190",
    heading: "Secernentea Infections",
    tree_numbers: ["C01.610.335.508.700"],
  },
  {
    ui: "D017191",
    heading: "Ascaridida Infections",
    tree_numbers: ["C01.610.335.508.700.100"],
  },
  {
    ui: "D017192",
    heading: "Skin Diseases, Bacterial",
    tree_numbers: ["C01.150.252.819", "C01.800.720", "C17.800.838.765"],
  },
  {
    ui: "D017193",
    heading: "Skin Diseases, Viral",
    tree_numbers: ["C01.925.825", "C17.800.838.790"],
  },
  {
    ui: "D017194",
    heading: "Oxyurida Infections",
    tree_numbers: ["C01.610.335.508.700.550"],
  },
  {
    ui: "D017196",
    heading: "Rhabditida Infections",
    tree_numbers: ["C01.610.335.508.700.700"],
  },
  {
    ui: "D017202",
    heading: "Myocardial Ischemia",
    tree_numbers: ["C14.280.647", "C14.907.585"],
    synonyms: ["Coronary Disease", "Chd", "Coronary Arteriosclerosis", "Coronary Heart Disease"],
  },
  {
    ui: "D017204",
    heading: "Angelman Syndrome",
    tree_numbers: ["C10.228.662.075", "C16.131.077.095", "C16.131.260.040", "C16.320.180.040"],
    synonyms: ["Puppetlike Syndrome", "Happy Puppet Syndrome"],
  },
  {
    ui: "D017205",
    heading: "Spirurida Infections",
    tree_numbers: ["C01.610.335.508.700.750"],
  },
  {
    ui: "D017206",
    heading: "Strongylida Infections",
    tree_numbers: ["C01.610.335.508.700.775"],
  },
  {
    ui: "D017213",
    heading: "Root Caries",
    tree_numbers: ["C07.793.720.210.650"],
    synonyms: ["Root Caries", "Dental Caries Of Root Surface", "Cementum Caries"],
  },
  {
    ui: "D017219",
    heading: "Gastric Outlet Obstruction",
    tree_numbers: ["C06.405.748.340"],
    synonyms: ["Gastric Outflow Obstruction"],
  },
  {
    ui: "D017227",
    heading: "Toxascariasis",
    tree_numbers: ["C01.610.335.508.700.100.850"],
  },
  {
    ui: "D017229",
    heading: "Enterobiasis",
    tree_numbers: ["C01.610.335.508.700.550.550.375"],
  },
  {
    ui: "D017237",
    heading: "Mitochondrial Encephalomyopathies",
    tree_numbers: [
      "C05.651.460.620",
      "C10.228.140.163.540",
      "C10.668.491.500.500",
      "C18.452.132.540",
      "C18.452.660.560.620",
    ],
  },
  {
    ui: "D017240",
    heading: "Mitochondrial Myopathies",
    tree_numbers: ["C05.651.460", "C10.668.491.500", "C18.452.660.560"],
    synonyms: ["Mitochondrial Cytopathy"],
  },
  {
    ui: "D017241",
    heading: "MELAS Syndrome",
    tree_numbers: [
      "C05.651.460.620.520",
      "C10.228.140.163.100.535",
      "C10.228.140.300.275.500",
      "C10.668.491.500.500.500",
      "C14.907.253.329.500",
      "C16.320.565.189.535",
      "C18.452.132.100.535",
      "C18.452.648.189.535",
      "C18.452.660.560.620.520",
    ],
    synonyms: ["Mitochondrial Myopathy, Encephalopathy, Lactic Acidosis, And Stroke-Like Episodes"],
  },
  {
    ui: "D017243",
    heading: "MERRF Syndrome",
    tree_numbers: [
      "C05.651.460.620.530",
      "C10.228.140.163.100.545",
      "C10.228.140.490.375.130.650.700",
      "C10.228.140.490.493.063.650.700",
      "C10.668.491.500.500.550",
      "C16.320.565.189.545",
      "C18.452.132.100.545",
      "C18.452.648.189.545",
      "C18.452.660.560.620.530",
    ],
    synonyms: [
      "Myoclonic Epilepsy - Ragged Red Fibers",
      "Myoclonus Epilepsy And Ragged Red Fibers",
      "Myoclonus With Epilepsy And With Ragged Red Fibers",
      "Fukuhara Syndrome",
    ],
  },
  {
    ui: "D017246",
    heading: "Ophthalmoplegia, Chronic Progressive External",
    tree_numbers: [
      "C05.651.460.700",
      "C10.292.562.750.250",
      "C10.597.622.447.511",
      "C10.668.491.500.700",
      "C11.590.472.250",
      "C18.452.660.560.700",
      "C23.888.592.636.447.511",
    ],
    synonyms: ["Progressive External Ophthalmoplegia"],
  },
  {
    ui: "D017250",
    heading: "Caliciviridae Infections",
    tree_numbers: ["C01.925.782.160"],
  },
  {
    ui: "D017253",
    heading: "Neurofibromatoses",
    tree_numbers: [
      "C04.557.580.600.580.590",
      "C04.700.631",
      "C10.562.600",
      "C10.574.500.549",
      "C16.320.400.560",
      "C16.320.700.633",
    ],
    synonyms: [
      "Acoustic Neurofibromatosis",
      "Von Reklinghausen Disease",
      "Central Neurofibromatosis",
      "Recklinghausen's Neurofibromatosis",
      "Peripheral Neurofibromatosis",
    ],
  },
  {
    ui: "D017254",
    heading: "Leukemic Infiltration",
    tree_numbers: ["C04.697.645.500", "C23.550.727.645.500"],
  },
  {
    ui: "D017271",
    heading: "Craniomandibular Disorders",
    tree_numbers: ["C05.500.607.221", "C05.651.243", "C07.320.610.291"],
  },
  {
    ui: "D017282",
    heading: "Tick-Borne Diseases",
    tree_numbers: ["C01.920.930"],
  },
  {
    ui: "D017285",
    heading: "Polymyositis",
    tree_numbers: ["C05.651.594.819", "C10.668.491.562.575"],
  },
  {
    ui: "D017359",
    heading: "HELLP Syndrome",
    tree_numbers: ["C13.703.395.186"],
  },
  {
    ui: "D017379",
    heading: "Hypertrophy, Left Ventricular",
    tree_numbers: ["C14.280.195.400", "C23.300.775.250.400"],
  },
  {
    ui: "D017380",
    heading: "Hypertrophy, Right Ventricular",
    tree_numbers: ["C14.280.195.410", "C23.300.775.250.401"],
  },
  {
    ui: "D017436",
    heading: "Kallmann Syndrome",
    tree_numbers: [
      "C12.706.316.096.750",
      "C13.351.875.253.096.750",
      "C16.131.939.316.096.750",
      "C16.320.467",
      "C19.391.119.096.750",
      "C19.391.482.600",
    ],
    synonyms: [
      "Kallman Syndrome",
      "Kallman's Syndrome",
      "Hypogonadism With Anosmia",
      "Familial Hypogonadism With Anosmia",
    ],
  },
  {
    ui: "D017437",
    heading: "Skin and Connective Tissue Diseases",
    tree_numbers: ["C17"],
  },
  {
    ui: "D017439",
    heading: "Cicatrix, Hypertrophic",
    tree_numbers: ["A10.165.450.300.125", "C23.550.355.274.505"],
  },
  {
    ui: "D017441",
    heading: "Necrobiotic Disorders",
    tree_numbers: ["C17.300.200.495", "C17.800.550"],
  },
  {
    ui: "D017443",
    heading: "Skin Diseases, Eczematous",
    tree_numbers: ["C17.800.815"],
  },
  {
    ui: "D017444",
    heading: "Skin Diseases, Papulosquamous",
    tree_numbers: ["C17.800.859"],
  },
  {
    ui: "D017445",
    heading: "Skin Diseases, Vascular",
    tree_numbers: ["C17.800.862"],
  },
  {
    ui: "D017449",
    heading: "Dermatitis, Allergic Contact",
    tree_numbers: ["C17.800.174.255.100", "C17.800.815.255.100", "C20.543.418.150"],
  },
  {
    ui: "D017453",
    heading: "Dermatitis, Irritant",
    tree_numbers: ["C17.800.174.255.400", "C17.800.815.255.400"],
    synonyms: ["Primary Irritant Dermatitis", "Irritant Contact Dermatitis", "Irritant Contact Dermatitis"],
  },
  {
    ui: "D017454",
    heading: "Dermatitis, Photoallergic",
    tree_numbers: ["C17.800.174.255.100.600", "C17.800.600.325", "C17.800.815.255.100.600", "C20.543.418.150.600"],
    synonyms: ["Photoallergic Eczema", "Photoallergic Contact Dermatitis", "Photoallergic Dermatitis"],
  },
  {
    ui: "D017484",
    heading: "Dermatitis, Phototoxic",
    tree_numbers: ["C17.800.174.255.400.225", "C17.800.600.335", "C17.800.815.255.400.225"],
    synonyms: [
      "Photosensitive Dermatitis",
      "Phototoxic Dermatitis",
      "Photosensitiveness",
      "Photosensitisation Reaction",
    ],
  },
  {
    ui: "D017486",
    heading: "Acneiform Eruptions",
    tree_numbers: ["C17.800.030"],
  },
  {
    ui: "D017488",
    heading: "Hyperkeratosis, Epidermolytic",
    tree_numbers: [
      "C16.131.831.512.400.375",
      "C16.320.850.400.375",
      "C16.614.492.400.375",
      "C17.800.428.333.250.375",
      "C17.800.804.512.400.375",
      "C17.800.827.400.375",
    ],
    synonyms: [
      "Bullous Ichthyosiform Erythroderma",
      "Bullous Congenital Ichthyosiform Erythroderma",
      "Epidermolytic Palmoplantar Hyperkeratosis",
    ],
  },
  {
    ui: "D017490",
    heading: "Ichthyosis, Lamellar",
    tree_numbers: [
      "C16.131.831.512.400.410",
      "C16.320.850.400.410",
      "C16.614.492.400.410",
      "C17.800.428.333.250.410",
      "C17.800.804.512.400.410",
      "C17.800.827.400.410",
    ],
    synonyms: ["Arci", "Lamellar Ichthyosis", "Non Bullous Congenital Ichthyosiform Erythroderma"],
  },
  {
    ui: "D017492",
    heading: "Keratosis, Seborrheic",
    tree_numbers: ["C17.800.428.580"],
  },
  {
    ui: "D017495",
    heading: "Hyperpigmentation",
    tree_numbers: ["C17.800.621.430"],
  },
  {
    ui: "D017496",
    heading: "Hypopigmentation",
    tree_numbers: ["C17.800.621.440"],
  },
  {
    ui: "D017497",
    heading: "Hidradenitis Suppurativa",
    tree_numbers: [
      "C01.150.252.819.420",
      "C01.800.720.420",
      "C01.830.499",
      "C17.800.838.765.420",
      "C17.800.946.315.320",
    ],
    synonyms: ["Acne Inversa, Familial", "Hidradenitis Suppurativa"],
  },
  {
    ui: "D017499",
    heading: "Porokeratosis",
    tree_numbers: ["C16.320.850.730", "C17.800.428.750", "C17.800.827.730"],
    synonyms: ["Disseminated Superficial Actinic Porokeratosis"],
  },
  {
    ui: "D017511",
    heading: "Pyoderma Gangrenosum",
    tree_numbers: ["C17.800.695.675", "C17.800.862.675", "C17.800.893.675"],
    synonyms: ["Pyoderma Gangrenosum"],
  },
  {
    ui: "D017512",
    heading: "Lichenoid Eruptions",
    tree_numbers: ["C17.800.859.475"],
  },
  {
    ui: "D017513",
    heading: "Lichen Nitidus",
    tree_numbers: ["C17.800.859.475.545"],
    synonyms: ["Pinkus' Disease"],
  },
  {
    ui: "D017514",
    heading: "Pityriasis Lichenoides",
    tree_numbers: ["C17.800.859.475.650", "C17.800.859.575.650", "C17.800.859.600.650"],
  },
  {
    ui: "D017515",
    heading: "Pityriasis Rosea",
    tree_numbers: ["C17.800.859.600.675"],
    synonyms: ["Pityriasis Circinata"],
  },
  {
    ui: "D017520",
    heading: "Mucinoses",
    tree_numbers: ["C17.300.550"],
  },
  {
    ui: "D017541",
    heading: "Aneurysm, False",
    tree_numbers: ["C14.907.055.090"],
  },
  {
    ui: "D017542",
    heading: "Aneurysm, Ruptured",
    tree_numbers: ["C14.907.055.185"],
  },
  {
    ui: "D017543",
    heading: "Iliac Aneurysm",
    tree_numbers: ["C14.907.055.625"],
  },
  {
    ui: "D017544",
    heading: "Aortic Aneurysm, Abdominal",
    tree_numbers: ["C14.907.055.239.075", "C14.907.109.139.075"],
    synonyms: ["Aortic Aneurysm, Familial Abdominal 1"],
  },
  {
    ui: "D017545",
    heading: "Aortic Aneurysm, Thoracic",
    tree_numbers: ["C14.907.055.239.125", "C14.907.109.139.125"],
  },
  {
    ui: "D017562",
    heading: "Postcholecystectomy Syndrome",
    tree_numbers: ["C06.130.825", "C23.550.767.775"],
  },
  {
    ui: "D017563",
    heading: "Lung Diseases, Interstitial",
    tree_numbers: ["C08.381.483"],
    synonyms: ["Ild"],
  },
  {
    ui: "D017564",
    heading: "Radiation Pneumonitis",
    tree_numbers: ["C08.381.483.675", "C08.381.520.734", "C26.733.762", "G01.750.748.500.762"],
  },
  {
    ui: "D017565",
    heading: "Sarcoidosis, Pulmonary",
    tree_numbers: ["C08.381.483.725", "C15.604.515.827.725"],
    synonyms: ["Lung Sarcoidosis"],
  },
  {
    ui: "D017566",
    heading: "Microvascular Angina",
    tree_numbers: ["C14.280.647.187.575", "C14.907.585.187.575"],
  },
  {
    ui: "D017573",
    heading: "Focal Epithelial Hyperplasia",
    tree_numbers: ["C07.465.342"],
    synonyms: ["Heck's Disease", "Heck Disease", "Multifocal Epithelial Hyperplasia"],
  },
  {
    ui: "D017574",
    heading: "Parasystole",
    tree_numbers: ["C14.280.067.672", "C23.550.073.670"],
  },
  {
    ui: "D017577",
    heading: "Cutaneous Fistula",
    tree_numbers: ["C17.800.135", "C23.300.575.150"],
  },
  {
    ui: "D017588",
    heading: "Hyperandrogenism",
    tree_numbers: [
      "C12.706.316.064.500",
      "C12.706.316.090.750",
      "C13.351.875.253.064.500",
      "C13.351.875.253.090.750",
      "C16.131.939.316.064.500",
      "C16.131.939.316.129.750",
      "C19.391.119.064.500",
      "C19.391.119.090.750",
    ],
    synonyms: ["Hyperandrogenization Syndrome"],
  },
  {
    ui: "D017590",
    heading: "Myelinolysis, Central Pontine",
    tree_numbers: ["C10.228.140.163.560", "C10.314.500", "C18.452.132.560"],
    synonyms: ["Osmotic Demyelination Syndrome"],
  },
  {
    ui: "D017593",
    heading: "Kleine-Levin Syndrome",
    tree_numbers: ["C10.886.425.800.200.500", "F03.870.400.800.200.500"],
  },
  {
    ui: "D017599",
    heading: "Neuroectodermal Tumors",
    tree_numbers: ["C04.557.465.625", "C04.557.580.625"],
    synonyms: ["Primitive Neuroectodermal Neoplasm"],
  },
  {
    ui: "D017600",
    heading: "Neuroectodermal Tumor, Melanotic",
    tree_numbers: ["C04.557.465.625.630", "C04.557.580.625.630"],
    synonyms: [
      "Melanotic Neuroectodermal Tumour Of Infancy",
      "Melanotic Neuroectodermal Tumor",
      "Melanotic Neuroectodermal Tumour",
      "Pigmented Neuroectodermal Tumour Of Infancy",
      "Melanotic Neuroectodermal Tumor Of Infancy",
      "Melanotic Neuroectodermal Tumour",
      "Infantile Melanotic Neuroectodermal Neoplasm",
    ],
  },
  {
    ui: "D017622",
    heading: "Periodontal Attachment Loss",
    tree_numbers: ["C07.465.714.354.750"],
  },
  {
    ui: "D017624",
    heading: "WAGR Syndrome",
    tree_numbers: [
      "C04.557.435.595.950",
      "C04.588.945.947.535.585.950",
      "C04.700.900.950",
      "C10.597.606.360.969",
      "C11.250.060.950",
      "C11.270.060.950",
      "C11.941.375.060.950",
      "C12.706.316.096.875",
      "C12.758.820.750.585.950",
      "C12.777.419.473.585.950",
      "C13.351.875.253.096.875",
      "C13.351.937.820.535.585.950",
      "C13.351.968.419.473.585.950",
      "C16.131.260.940",
      "C16.131.384.079.950",
      "C16.131.939.316.096.875",
      "C16.320.180.940",
      "C16.320.290.078.950",
      "C16.320.700.900.950",
      "C19.391.119.096.875",
    ],
    synonyms: [
      "Chromosome 11p13 Deletion Syndrome",
      "Wilms Tumor-Aniridia-Genitourinary Anomalies-Mental Retardation Syndrome",
      "11p Partial Monosomy Syndrome",
    ],
  },
  {
    ui: "D017674",
    heading: "Hypophosphatemia",
    tree_numbers: ["C18.452.750.400"],
  },
  {
    ui: "D017675",
    heading: "CREST Syndrome",
    tree_numbers: [
      "C06.405.117.119.500.204",
      "C14.907.617.812.500",
      "C14.907.823.225",
      "C17.300.799.801.500",
      "C17.800.784.801.500",
      "C18.452.174.130.204",
    ],
  },
  {
    ui: "D017676",
    heading: "Lichen Planus, Oral",
    tree_numbers: ["C07.465.397", "C17.800.859.475.560.397"],
  },
  {
    ui: "D017681",
    heading: "Hypereosinophilic Syndrome",
    tree_numbers: ["C15.378.553.231.549"],
  },
  {
    ui: "D017682",
    heading: "Myocardial Stunning",
    tree_numbers: ["C14.280.671", "C23.888.582"],
  },
  {
    ui: "D017688",
    heading: "Cholera Morbus",
    tree_numbers: ["C06.405.205.200"],
  },
  {
    ui: "D017689",
    heading: "Polydactyly",
    tree_numbers: ["C05.660.585.600", "C16.131.621.585.600"],
    synonyms: ["Postaxial Polydactyly"],
  },
  {
    ui: "D017695",
    heading: "Soft Tissue Injuries",
    tree_numbers: ["C26.808"],
  },
  {
    ui: "D017696",
    heading: "Myopathies, Nemaline",
    tree_numbers: ["C05.651.575.290", "C10.668.491.550.290"],
    synonyms: ["Nemaline Rod Myopathy", "Nemaline Body Disease", "Rod Myopathy"],
  },
  {
    ui: "D017699",
    heading: "Pelvic Pain",
    tree_numbers: ["C23.888.592.612.944"],
  },
  {
    ui: "D017700",
    heading: "Embolism, Cholesterol",
    tree_numbers: ["C14.907.355.350.454.500"],
    synonyms: [
      "Cholesterol Crystal Embolism",
      "Purple Toe Syndrome",
      "Trash Foot",
      "Warfarin Blue Toe Syndrome",
      "Atheroembolism",
    ],
  },
  {
    ui: "D017703",
    heading: "Retropharyngeal Abscess",
    tree_numbers: ["C01.748.561.625", "C01.830.025.780", "C07.550.781.625", "C08.730.561.625", "C09.775.649.625"],
  },
  {
    ui: "D017714",
    heading: "Community-Acquired Infections",
    tree_numbers: ["C01.234"],
  },
  {
    ui: "D017719",
    heading: "Diabetic Foot",
    tree_numbers: ["C14.907.320.191", "C17.800.893.592.450.200", "C19.246.099.500.191", "C19.246.099.937.250"],
  },
  {
    ui: "D017726",
    heading: "Cytomegalovirus Retinitis",
    tree_numbers: [
      "C01.375.725.270",
      "C01.925.256.466.245.150",
      "C01.925.325.270",
      "C11.294.800.270",
      "C11.768.773.360",
    ],
    synonyms: ["Cmv Retinitis"],
  },
  {
    ui: "D017728",
    heading: "Lymphoma, Large-Cell, Anaplastic",
    tree_numbers: ["C04.557.386.480.750.399", "C15.604.515.569.480.750.600", "C20.683.515.761.480.750.399"],
  },
  {
    ui: "D017731",
    heading: "Lymphomatoid Papulosis",
    tree_numbers: ["C04.557.386.480.750.800.528", "C15.604.515.569.480.750.800.528", "C20.683.515.761.480.750.800.528"],
  },
  {
    ui: "D017733",
    heading: "Leukoplakia, Hairy",
    tree_numbers: [
      "C01.925.256.466.313.500",
      "C04.588.443.591.545.500",
      "C04.834.512.513.500",
      "C07.465.530.545.500",
      "C23.300.816.513.500",
    ],
    synonyms: ["Hairy Leukoplakia"],
  },
  {
    ui: "D017759",
    heading: "Fractures, Malunited",
    tree_numbers: ["C26.404.249"],
  },
  {
    ui: "D017760",
    heading: "Bone Malalignment",
    tree_numbers: ["C05.116.214"],
  },
  {
    ui: "D017769",
    heading: "Ulnar Nerve Compression Syndromes",
    tree_numbers: ["C10.668.829.500.850.600", "C10.668.829.550.925", "C26.844.150.957"],
  },
  {
    ui: "D017772",
    heading: "Amyloid Neuropathies",
    tree_numbers: ["C10.668.829.050", "C18.452.845.500.050"],
  },
  {
    ui: "D017789",
    heading: "Granuloma, Pyogenic",
    tree_numbers: ["C23.550.382.937"],
  },
  {
    ui: "D017823",
    heading: "Furcation Defects",
    tree_numbers: ["C07.465.714.204"],
  },
  {
    ui: "D017824",
    heading: "Bone Cysts, Aneurysmal",
    tree_numbers: ["C04.182.089.265", "C05.116.070.265"],
  },
  {
    ui: "D017825",
    heading: "Canavan Disease",
    tree_numbers: [
      "C10.228.140.163.100.362.375",
      "C10.228.140.695.625.375",
      "C10.314.400.375",
      "C10.574.500.300",
      "C16.320.400.150",
      "C16.320.565.189.362.375",
      "C18.452.132.100.362.375",
      "C18.452.648.189.362.375",
    ],
    synonyms: ["Spongy Degeneration Of Central Nervous System", "Canavan-Van Bogaert-Bertrand Disease"],
  },
  {
    ui: "D017827",
    heading: "Machado-Joseph Disease",
    tree_numbers: [
      "C10.228.140.252.190.530.530",
      "C10.228.140.252.700.700.500",
      "C10.228.854.787.875.500",
      "C10.574.500.825.700.500",
      "C10.597.350.090.500.530.530",
      "C16.320.400.780.875.500",
    ],
    synonyms: ["Mjd", "Spinocerebellar Ataxia 3", "Azorean Disease", "Sca3", "Spinocerebellar Ataxia Type 3"],
  },
  {
    ui: "D017880",
    heading: "Limb Deformities, Congenital",
    tree_numbers: ["C05.660.585", "C16.131.621.585"],
  },
  {
    ui: "D017887",
    heading: "Ossification of Posterior Longitudinal Ligament",
    tree_numbers: ["C05.116.900.480", "C23.550.751.500"],
  },
  {
    ui: "D017889",
    heading: "Exfoliation Syndrome",
    tree_numbers: ["C11.941.375.285"],
    synonyms: ["Pseudoexfoliation Syndrome", "Pseudoexfoliation Glaucoma"],
  },
  {
    ui: "D017890",
    heading: "Splenosis",
    tree_numbers: ["C15.604.744.742.500", "C26.017.680.500", "C26.761.555.500"],
  },
  {
    ui: "D018058",
    heading: "Tympanic Membrane Perforation",
    tree_numbers: ["C09.218.903", "C26.930"],
  },
  {
    ui: "D018088",
    heading: "Tuberculosis, Multidrug-Resistant",
    tree_numbers: ["C01.150.252.410.040.552.846.775"],
  },
  {
    ui: "D018126",
    heading: "Odontodysplasia",
    tree_numbers: ["C07.650.800.600", "C07.793.700.600", "C16.131.850.800.600"],
  },
  {
    ui: "D018149",
    heading: "Glucose Intolerance",
    tree_numbers: ["C18.452.394.952.500"],
    synonyms: ["Malabsorption Of Glucose", "Glucose: Intolerance", "Glucose: Malabsorption"],
  },
  {
    ui: "D018159",
    heading: "Endolymphatic Hydrops",
    tree_numbers: ["C09.218.568.217"],
    synonyms: ["Labyrinthine Hydrops"],
  },
  {
    ui: "D018173",
    heading: "Circoviridae Infections",
    tree_numbers: ["C01.925.256.200"],
  },
  {
    ui: "D018174",
    heading: "Arterivirus Infections",
    tree_numbers: ["C01.925.782.600.100"],
  },
  {
    ui: "D018175",
    heading: "Birnaviridae Infections",
    tree_numbers: ["C01.925.782.123"],
  },
  {
    ui: "D018176",
    heading: "Torovirus Infections",
    tree_numbers: ["C01.925.782.600.550.800"],
  },
  {
    ui: "D018177",
    heading: "Flavivirus Infections",
    tree_numbers: ["C01.925.782.350.250"],
  },
  {
    ui: "D018178",
    heading: "Flaviviridae Infections",
    tree_numbers: ["C01.925.782.350"],
  },
  {
    ui: "D018182",
    heading: "Pestivirus Infections",
    tree_numbers: ["C01.925.782.350.675"],
  },
  {
    ui: "D018184",
    heading: "Paramyxoviridae Infections",
    tree_numbers: ["C01.925.782.580.600"],
  },
  {
    ui: "D018185",
    heading: "Morbillivirus Infections",
    tree_numbers: ["C01.925.782.580.600.500"],
  },
  {
    ui: "D018186",
    heading: "Pneumovirus Infections",
    tree_numbers: ["C01.925.782.580.600.550"],
  },
  {
    ui: "D018188",
    heading: "Cardiovirus Infections",
    tree_numbers: ["C01.925.782.687.150"],
  },
  {
    ui: "D018190",
    heading: "Lymphatic Vessel Tumors",
    tree_numbers: ["C04.557.375"],
  },
  {
    ui: "D018191",
    heading: "Lymphangioma, Cystic",
    tree_numbers: ["C04.557.375.450.450"],
    synonyms: ["Cystic Hygroma"],
  },
  {
    ui: "D018192",
    heading: "Lymphangioleiomyomatosis",
    tree_numbers: ["C04.557.375.460.465", "C04.557.450.692.500", "C15.604.515.562.465", "C20.683.515.710.465"],
  },
  {
    ui: "D018193",
    heading: "Neoplasms, Complex and Mixed",
    tree_numbers: ["C04.557.435"],
  },
  {
    ui: "D018194",
    heading: "Adenomyoma",
    tree_numbers: ["C04.557.435.110"],
  },
  {
    ui: "D018195",
    heading: "Adenosarcoma",
    tree_numbers: ["C04.557.435.135", "C04.557.450.795.135"],
    synonyms: ["Mullerian Adenosarcoma", "Adenosarcoma"],
  },
  {
    ui: "D018196",
    heading: "Carcinoma, Adenosquamous",
    tree_numbers: ["C04.557.435.250", "C04.557.470.200.150"],
    synonyms: [
      "Adenosquamous Carcinoma",
      "Adenosquamous Cell Carcinoma",
      "Mixed Adenocarcinoma And Squamous Carcinoma",
    ],
  },
  {
    ui: "D018197",
    heading: "Hepatoblastoma",
    tree_numbers: ["C04.557.435.380"],
  },
  {
    ui: "D018198",
    heading: "Mixed Tumor, Malignant",
    tree_numbers: ["C04.557.435.525"],
    synonyms: ["Mixed Tumor", "Malignant Mixed Cancer", "Malignant Mixed Neoplasm", "Mixed Tumor, Malignant"],
  },
  {
    ui: "D018199",
    heading: "Mixed Tumor, Mesodermal",
    tree_numbers: ["C04.557.435.530", "C04.557.450.795.530"],
    synonyms: [
      "Mesodermal Mixed Tumor",
      "Mesodermal Mixed Tumor",
      "Malignant Mixed Mesodermal (Mullerian) Tumor",
      "Mmmt",
      "Mixed Mesodermal (Mullerian) Tumor",
      "Mullerian Mixed Tumor",
      "Malignant Mixed Mullerian Tumor",
    ],
  },
  {
    ui: "D018200",
    heading: "Mixed Tumor, Mullerian",
    tree_numbers: ["C04.557.435.540"],
    synonyms: [
      "Mesodermal Mixed Tumor",
      "Mesodermal Mixed Tumor",
      "Malignant Mixed Mesodermal (Mullerian) Tumor",
      "Mmmt",
      "Mixed Mesodermal (Mullerian) Tumor",
      "Mullerian Mixed Tumor",
      "Malignant Mixed Mullerian Tumor",
    ],
  },
  {
    ui: "D018201",
    heading: "Nephroma, Mesoblastic",
    tree_numbers: [
      "C04.557.435.600",
      "C04.588.945.947.535.790",
      "C12.758.820.750.372",
      "C12.777.419.473.372",
      "C13.351.937.820.535.790",
      "C13.351.968.419.473.372",
    ],
  },
  {
    ui: "D018202",
    heading: "Pulmonary Blastoma",
    tree_numbers: ["C04.557.435.675", "C04.588.894.797.520.867"],
    synonyms: ["Blastoma Of Lung"],
  },
  {
    ui: "D018203",
    heading: "Sarcoma, Endometrial Stromal",
    tree_numbers: [
      "C04.557.435.775",
      "C04.557.450.795.332.500",
      "C13.351.500.852.762.200.500.500",
      "C13.351.937.418.875.200.374.500",
    ],
    synonyms: ["Endometrial Stromal Sarcoma, High Grade", "Ess", "Undifferentiated Endometrial Sarcoma"],
  },
  {
    ui: "D018204",
    heading: "Neoplasms, Connective and Soft Tissue",
    tree_numbers: ["C04.557.450"],
  },
  {
    ui: "D018205",
    heading: "Neoplasms, Adipose Tissue",
    tree_numbers: ["C04.557.450.550"],
    synonyms: [
      "Lipomatosis, Familial Multiple",
      "Benign Tumor Of Adipose Tissue",
      "Benign Lipomatous Tumor",
      "Tumor Of Adipose Tissue",
      "Multiple Lipomatosis",
      "Lipomatous Neoplasm",
      "Lipomatous Tumor",
    ],
  },
  {
    ui: "D018206",
    heading: "Angiolipoma",
    tree_numbers: ["C04.557.450.550.100"],
  },
  {
    ui: "D018207",
    heading: "Angiomyolipoma",
    tree_numbers: ["C04.557.450.550.125", "C04.557.450.692.249"],
    synonyms: ["Angiomyolipoma"],
  },
  {
    ui: "D018208",
    heading: "Liposarcoma, Myxoid",
    tree_numbers: ["C04.557.450.550.420.425", "C04.557.450.795.465.425"],
    synonyms: ["Myxoid/round Cell Liposarcoma"],
  },
  {
    ui: "D018209",
    heading: "Myelolipoma",
    tree_numbers: ["C04.557.450.550.710"],
  },
  {
    ui: "D018210",
    heading: "Chondromatosis",
    tree_numbers: ["C04.557.450.565.265.270"],
  },
  {
    ui: "D018211",
    heading: "Chondrosarcoma, Mesenchymal",
    tree_numbers: ["C04.557.450.565.280.280", "C04.557.450.795.300.280"],
  },
  {
    ui: "D018212",
    heading: "Giant Cell Tumor of Bone",
    tree_numbers: ["C04.557.450.565.380.380", "C04.557.450.565.575.420"],
    synonyms: [
      "Bone Giant Cell Tumour",
      "Giant Cell Neoplasm Of Bone",
      "Giant Cell Tumor Of Bone",
      "Giant Cell Tumour Of Bone",
    ],
  },
  {
    ui: "D018213",
    heading: "Neoplasms, Bone Tissue",
    tree_numbers: ["C04.557.450.565.575"],
    synonyms: ["Bone Tissue Neoplasm", "Osteoid Sarcoma", "Skeletal Sarcoma", "Osteogenic Sarcoma"],
  },
  {
    ui: "D018214",
    heading: "Fibroma, Ossifying",
    tree_numbers: ["C04.557.450.565.575.400", "C04.557.450.565.590.340.360"],
    synonyms: ["Fibro-Osteoma"],
  },
  {
    ui: "D018215",
    heading: "Osteoblastoma",
    tree_numbers: ["C04.557.450.565.575.600"],
  },
  {
    ui: "D018216",
    heading: "Osteochondromatosis",
    tree_numbers: ["C04.557.450.565.575.610.615", "C05.116.099.708.670.615"],
  },
  {
    ui: "D018217",
    heading: "Osteosarcoma, Juxtacortical",
    tree_numbers: ["C04.557.450.565.575.650.655", "C04.557.450.795.620.655"],
    synonyms: ["Parosteal Osteogenic Sarcoma", "Juxtacortical Osteosarcoma"],
  },
  {
    ui: "D018218",
    heading: "Neoplasms, Fibrous Tissue",
    tree_numbers: ["C04.557.450.565.590"],
    synonyms: ["Fibrous Tissue Neoplasm", "Fibrocytic Tumor", "Fibrosarcoma Of Soft Tissue"],
  },
  {
    ui: "D018219",
    heading: "Histiocytoma, Benign Fibrous",
    tree_numbers: ["C04.557.450.565.590.425.350"],
    synonyms: [
      "Pleomorphic Fibroma",
      "Dermatofibroma",
      "Dermatofibroma",
      "Sclerosing Angioma Of Skin",
      "Fibrohistiocytic Tumor",
      "Benign Cutaneous Fibrous Histiocytoma",
      "Fibrohistiocytic Tumor",
      "Fibrous Xanthoma Of Skin",
      "Sclerosing Angioma",
      "Fibrous Histiocytoma Of Skin",
    ],
  },
  {
    ui: "D018220",
    heading: "Fibroma, Desmoplastic",
    tree_numbers: ["C04.557.450.565.590.340.345"],
  },
  {
    ui: "D018221",
    heading: "Fibromatosis, Abdominal",
    tree_numbers: ["C04.557.450.565.590.340.400"],
  },
  {
    ui: "D018222",
    heading: "Fibromatosis, Aggressive",
    tree_numbers: ["C04.557.450.565.590.340.410"],
  },
  {
    ui: "D018223",
    heading: "Dermatofibrosarcoma",
    tree_numbers: ["C04.557.450.565.590.350.320", "C04.557.450.795.350.320"],
  },
  {
    ui: "D018224",
    heading: "Myofibromatosis",
    tree_numbers: ["C04.557.450.565.590.550"],
  },
  {
    ui: "D018225",
    heading: "Neoplasms, Fibroepithelial",
    tree_numbers: ["C04.557.450.565.590.595", "C04.557.470.625"],
  },
  {
    ui: "D018226",
    heading: "Fibroadenoma",
    tree_numbers: ["C04.557.450.565.590.595.350", "C04.557.470.625.350"],
    synonyms: [
      "Juvenile Fibroadenoma Of Breast",
      "Fibroadenoma",
      "Fibroadenoma",
      "Complex Fibroadenoma Of Breast",
      "Juvenile Fibroadenoma",
      "Fibroadenoma Of Breast",
      "Cellular Fibroadenoma",
    ],
  },
  {
    ui: "D018227",
    heading: "Sarcoma, Clear Cell",
    tree_numbers: ["C04.557.450.565.800", "C04.557.450.795.800"],
    synonyms: [
      "Clear Cell Sarcoma Of Soft Parts",
      "Malignant Melanoma Of Soft Tissues",
      "Adult Soft Part Clear Cell Sarcoma",
      "Melanoma, Malignant, Of Soft Parts",
      "Clear Cell Sarcoma",
    ],
  },
  {
    ui: "D018228",
    heading: "Sarcoma, Small Cell",
    tree_numbers: ["C04.557.450.565.825", "C04.557.450.795.870"],
  },
  {
    ui: "D018229",
    heading: "Angiomyoma",
    tree_numbers: ["C04.557.450.590.450.125"],
    synonyms: ["Angiomyoma", "Vascular Leiomyoma"],
  },
  {
    ui: "D018230",
    heading: "Leiomyoma, Epithelioid",
    tree_numbers: ["C04.557.450.590.450.455"],
  },
  {
    ui: "D018231",
    heading: "Leiomyomatosis",
    tree_numbers: ["C04.557.450.590.450.465"],
  },
  {
    ui: "D018232",
    heading: "Rhabdomyosarcoma, Alveolar",
    tree_numbers: ["C04.557.450.590.550.660.665", "C04.557.450.795.550.660.665"],
    synonyms: ["Alveolar Childhood Rhabdomyosarcoma", "Alveolar Rhabdomyosarcoma"],
  },
  {
    ui: "D018233",
    heading: "Rhabdomyosarcoma, Embryonal",
    tree_numbers: ["C04.557.450.590.550.660.675", "C04.557.450.795.550.660.675"],
  },
  {
    ui: "D018234",
    heading: "Sarcoma, Alveolar Soft Part",
    tree_numbers: ["C04.557.450.590.775", "C04.557.450.795.775"],
    synonyms: ["Alveolar Soft Part Sarcoma"],
  },
  {
    ui: "D018235",
    heading: "Smooth Muscle Tumor",
    tree_numbers: ["C04.557.450.590.800"],
  },
  {
    ui: "D018236",
    heading: "Carcinoma, Embryonal",
    tree_numbers: ["C04.557.465.200"],
    synonyms: ["Primary Extragonadal Embryonal Carcinoma"],
  },
  {
    ui: "D018237",
    heading: "Germinoma",
    tree_numbers: ["C04.557.465.330"],
  },
  {
    ui: "D018238",
    heading: "Gonadoblastoma",
    tree_numbers: [
      "C04.557.465.420",
      "C04.557.475.395",
      "C12.706.316.096.687.500",
      "C12.706.316.309.388.500",
      "C13.351.875.253.096.687.500",
      "C13.351.875.253.309.388.500",
      "C16.131.939.316.096.687.500",
      "C16.131.939.316.309.388.500",
      "C19.391.119.096.687.500",
      "C19.391.119.309.388.500",
    ],
  },
  {
    ui: "D018239",
    heading: "Seminoma",
    tree_numbers: ["C04.557.465.330.800"],
    synonyms: ["Seminoma Testis", "Testicular Seminoma Pure", "Seminoma Of Testis"],
  },
  {
    ui: "D018240",
    heading: "Endodermal Sinus Tumor",
    tree_numbers: ["C04.557.465.510.350"],
    synonyms: [
      "Infantile Embryonal Carcinoma",
      "Yolk Sac Tumour",
      "Hepatoid Yolk Sac Tumour",
      "Yolk Sac Tumor",
      "Yolk Sac Neoplasm",
      "Yolk Sac Tumor",
      "Endodermal Sinus Tumour",
    ],
  },
  {
    ui: "D018241",
    heading: "Neuroectodermal Tumors, Primitive, Peripheral",
    tree_numbers: ["C04.557.465.625.600.590.650", "C04.557.470.670.590.650", "C04.557.580.625.600.590.650"],
    synonyms: [
      "Ewings Sarcoma",
      "Ewing's Tumor",
      "Localized Ewing's Sarcoma",
      "Ewings Sarcoma-Primitive Neuroectodermal Tumor",
      "Localized Ewing Sarcoma",
      "Ewing's Family Localized Tumor",
      "Localized Ewing's Tumor",
      "Localized Peripheral Primitive Neuroectodermal Tumor",
      "Peripheral Primitive Neuroectodermal Tumor",
      "Pnet Of Thoracopulmonary Region",
      "Localized Ewing's Sarcoma/peripheral Primitive Neuroectodermal Tumor",
      "Ewing's Sarcoma/peripheral Primitive Neuroectodermal Tumor",
    ],
  },
  {
    ui: "D018242",
    heading: "Neuroectodermal Tumors, Primitive",
    tree_numbers: ["C04.557.465.625.600.590", "C04.557.470.670.590", "C04.557.580.625.600.590"],
    synonyms: ["Ependymoblastoma"],
  },
  {
    ui: "D018243",
    heading: "Teratocarcinoma",
    tree_numbers: ["C04.557.465.900"],
    synonyms: ["Teratocarcinoma", "Mixed Embryonal Carcinoma And Teratoma"],
  },
  {
    ui: "D018245",
    heading: "Trophoblastic Tumor, Placental Site",
    tree_numbers: [
      "C04.557.465.955.207.875",
      "C04.557.470.200.025.455.875",
      "C04.850.908.208.875",
      "C13.703.720.949.208.875",
    ],
    synonyms: [
      "Placental-Site Gestational Trophoblastic Neoplasm",
      "Placental Site Trophoblastic Tumor",
      "Placental Site Trophoblastic Tumour",
      "Placental Site Trophoblastic Tumour",
    ],
  },
  {
    ui: "D018246",
    heading: "Adrenocortical Adenoma",
    tree_numbers: ["C04.588.322.078.265.500", "C19.053.098.265.500", "C19.053.347.500.500", "C19.344.078.265.500"],
  },
  {
    ui: "D018248",
    heading: "Adenoma, Liver Cell",
    tree_numbers: ["C04.557.470.035.120", "C04.588.274.623.040", "C06.301.623.040", "C06.552.697.040"],
  },
  {
    ui: "D018249",
    heading: "Adenoma, Oxyphilic",
    tree_numbers: ["C04.557.470.035.140"],
    synonyms: ["Oncocytoma", "Follicular Adenoma, Oxyphilic Cell"],
  },
  {
    ui: "D018250",
    heading: "Acrospiroma",
    tree_numbers: ["C04.557.470.035.175.125", "C04.557.470.550.175.125"],
    synonyms: ["Eccrine Hidradenoma Of Skin", "Eccrine Acrospiroma", "Poroma", "Eccrine Hidradenoma"],
  },
  {
    ui: "D018251",
    heading: "Hidrocystoma",
    tree_numbers: ["C04.557.470.035.175.375", "C04.557.470.550.175.375"],
    synonyms: [
      "Eccrine Hidrocystoma Of Skin",
      "Eccrine Cystadenoma",
      "Apocrine Cystadenoma",
      "Apocrine Cystadenoma",
      "Hidrocystoma",
    ],
  },
  {
    ui: "D018252",
    heading: "Syringoma",
    tree_numbers: ["C04.557.470.035.175.800", "C04.557.470.550.175.800"],
  },
  {
    ui: "D018253",
    heading: "Adenoma, Villous",
    tree_numbers: ["C04.557.470.035.185"],
  },
  {
    ui: "D018254",
    heading: "Adenomatoid Tumor",
    tree_numbers: ["C04.557.470.035.200", "C04.557.470.660.200"],
    synonyms: [
      "Adenomatoid Tumour",
      "Benign Localised Epithelial Mesothelioma",
      "Benign Localized Epithelial Mesothelioma",
    ],
  },
  {
    ui: "D018255",
    heading: "Adenomatosis, Pulmonary",
    tree_numbers: ["C04.557.470.035.210"],
  },
  {
    ui: "D018256",
    heading: "Adenomatous Polyps",
    tree_numbers: ["C04.557.470.035.215"],
  },
  {
    ui: "D018261",
    heading: "Mesothelioma, Cystic",
    tree_numbers: ["C04.557.470.035.510.515", "C04.557.470.660.510.515"],
  },
  {
    ui: "D018262",
    heading: "Adenocarcinoma, Clear Cell",
    tree_numbers: ["C04.557.470.200.025.045"],
    synonyms: [
      "Water-Clear Cell Carcinoma",
      "Mesonephroid Clear Cell Carcinoma",
      "Malignant Mesonephroma",
      "Water-Clear Cell Adenocarcinoma",
      "Water-Clear Cell Adenocarcinoma",
      "Mesonephroma",
      "Mesonephroma",
      "Wolffian Duct Neoplasm",
      "Mesonephroma, Malignant",
      "Clear Cell Adenocarcinoma",
    ],
  },
  {
    ui: "D018263",
    heading: "Adenocarcinoma, Follicular",
    tree_numbers: ["C04.557.470.200.025.060"],
    synonyms: [
      "Follicular Adenocarcinoma",
      "Follicular Thyroid Carcinoma",
      "Thyroid Adenocarcinoma",
      "Follicular Adenocarcinoma, Well Differentiated",
      "Follicular Carcinoma",
    ],
  },
  {
    ui: "D018265",
    heading: "Carcinoma, Papillary, Follicular",
    tree_numbers: ["C04.557.470.200.025.060.225", "C04.557.470.200.025.085.225"],
  },
  {
    ui: "D018266",
    heading: "Adenocarcinoma, Sebaceous",
    tree_numbers: ["C04.557.470.200.025.105", "C04.557.470.550.105"],
    synonyms: ["Adenocarcinoma Of The Sebaceous Gland", "Sebaceous Carcinoma"],
  },
  {
    ui: "D018267",
    heading: "Carcinoma, Acinar Cell",
    tree_numbers: ["C04.557.470.200.025.215"],
    synonyms: ["Acinic Cell Carcinoma"],
  },
  {
    ui: "D018268",
    heading: "Adrenocortical Carcinoma",
    tree_numbers: [
      "C04.557.470.200.025.152",
      "C04.588.322.078.265.750",
      "C19.053.098.265.750",
      "C19.053.347.500.750",
      "C19.344.078.265.750",
    ],
    synonyms: ["Adrenal Cortical Carcinoma", "Carcinoma Of The Adrenal Cortex"],
  },
  {
    ui: "D018269",
    heading: "Carcinoma, Endometrioid",
    tree_numbers: [
      "C04.557.470.200.025.240",
      "C04.588.945.418.948.585.124",
      "C13.351.500.056.630.705.331",
      "C13.351.937.418.685.331",
      "C13.351.937.418.875.200.124",
      "C19.391.630.705.331",
    ],
    synonyms: [
      "Carcinoma Of The Endometrium",
      "Endometrioid Carcinoma Of Female Reproductive System",
      "Endometrioid Carcinoma",
    ],
  },
  {
    ui: "D018270",
    heading: "Carcinoma, Ductal, Breast",
    tree_numbers: ["C04.557.470.200.025.232.500", "C04.557.470.615.132.500", "C04.588.180.390", "C17.800.090.500.390"],
    synonyms: ["Invasive Ductal Carcinoma, Nst", "Ductal Adenocarcinoma", "Infiltrating Ductal Carcinoma Of Breast"],
  },
  {
    ui: "D018273",
    heading: "Carcinoma, Islet Cell",
    tree_numbers: [
      "C04.557.470.200.025.290",
      "C04.588.274.761.500",
      "C04.588.322.475.500",
      "C06.301.761.500",
      "C06.689.667.500",
      "C19.344.421.500",
    ],
    synonyms: [
      "Islet Cell Carcinoma",
      "Pancreatic Neuroendocrine Carcinoma",
      "Carcinoma Of Endocrine Pancreas",
      "Malignant Neoplasm Of Islets Of Langerhans",
    ],
  },
  {
    ui: "D018275",
    heading: "Carcinoma, Lobular",
    tree_numbers: ["C04.557.470.200.025.305", "C04.557.470.615.305", "C04.588.180.437", "C17.800.090.500.437"],
    synonyms: ["Lobular Carcinoma Of Breast", "Lobular Carcinoma Of The Breast", "Lobular Carcinoma"],
  },
  {
    ui: "D018276",
    heading: "Carcinoma, Medullary",
    tree_numbers: [
      "C04.557.465.625.650.240.315",
      "C04.557.470.200.025.370.315",
      "C04.557.470.615.315",
      "C04.557.580.625.650.240.315",
    ],
  },
  {
    ui: "D018277",
    heading: "Carcinoma, Mucoepidermoid",
    tree_numbers: ["C04.557.470.200.025.340", "C04.557.470.590.340"],
    synonyms: ["Salivary Gland Mucoepidermoid Carcinoma", "Mucoepidermoid Carcinoma", "Mec"],
  },
  {
    ui: "D018278",
    heading: "Carcinoma, Neuroendocrine",
    tree_numbers: ["C04.557.465.625.650.240", "C04.557.470.200.025.370", "C04.557.580.625.650.240"],
  },
  {
    ui: "D018279",
    heading: "Carcinoma, Signet Ring Cell",
    tree_numbers: ["C04.557.470.200.025.415", "C04.557.470.590.415"],
    synonyms: ["Signet Ring Carcinoma", "Signet Ring Cell Adenocarcinoma", "Signet Ring Cell Carcinoma"],
  },
  {
    ui: "D018280",
    heading: "Carcinoma, Skin Appendage",
    tree_numbers: ["C04.557.470.200.025.420", "C04.557.470.550.420"],
  },
  {
    ui: "D018281",
    heading: "Cholangiocarcinoma",
    tree_numbers: ["C04.557.470.200.025.450"],
    synonyms: ["Cholangiosarcoma", "Adult Primary Cholangiocarcinoma", "Adult Primary Cholangiocellular Carcinoma"],
  },
  {
    ui: "D018282",
    heading: "Cystadenocarcinoma, Mucinous",
    tree_numbers: ["C04.557.470.200.025.480.225", "C04.557.470.590.480.225"],
    synonyms: ["Pseudomucinous Cystadenocarcinoma"],
  },
  {
    ui: "D018283",
    heading: "Cystadenocarcinoma, Papillary",
    tree_numbers: ["C04.557.470.200.025.480.230", "C04.557.470.590.480.230"],
    synonyms: ["Papillary Cystadenocarcinoma"],
  },
  {
    ui: "D018284",
    heading: "Cystadenocarcinoma, Serous",
    tree_numbers: ["C04.557.470.200.025.480.240", "C04.557.470.590.480.240"],
    synonyms: ["Serous Carcinoma", "Serous Adenocarcinoma"],
  },
  {
    ui: "D018285",
    heading: "Klatskin Tumor",
    tree_numbers: ["C04.557.470.200.025.450.500"],
    synonyms: [
      "Perihilar Extrahepatic Bile Duct Carcinoma",
      "Hilar Cholangiocarcinoma",
      "Klatskin Tumor",
      "Klatskin Tumour",
      "Klatskin's Tumour",
      "Hilar Cholangiocellular Carcinoma",
    ],
  },
  {
    ui: "D018286",
    heading: "Carcinoma, Giant Cell",
    tree_numbers: ["C04.557.470.200.220"],
    synonyms: ["Giant Cell Carcinoma", "Giant Cell Lung Carcinoma"],
  },
  {
    ui: "D018287",
    heading: "Carcinoma, Large Cell",
    tree_numbers: ["C04.557.470.200.260"],
  },
  {
    ui: "D018288",
    heading: "Carcinoma, Small Cell",
    tree_numbers: ["C04.557.470.200.380"],
    synonyms: ["Oat Cell Carcinoma", "Oat Cell Carcinoma"],
  },
  {
    ui: "D018289",
    heading: "Carcinoma, Verrucous",
    tree_numbers: ["C04.557.470.200.450", "C04.557.470.700.450"],
    synonyms: ["Warty Carcinoma", "Verrucous Squamous Cell Carcinoma", "Verrucous Squamous Carcinoma"],
  },
  {
    ui: "D018290",
    heading: "Cervical Intraepithelial Neoplasia",
    tree_numbers: ["C04.557.470.200.240.250"],
    synonyms: [
      "Severe Dysplasia Of Cervix",
      "Severe Dysplasia Of The Cervix Uteri",
      "Cin Iii",
      "Cin Iii - Carcinoma In Situ Of Cervix",
      "Cervical Intraepithelial Neoplasia Grade Iii With Severe Dysplasia",
      "Cin Iii - Severe Dyskaryosis",
      "Cervix Ca In Situ",
      "Squamous Intraepithelial Neoplasia, Grade Iii",
      "Carcinoma In Situ Of Cervix",
      "Carcinoma Of Cervix Stage 0",
      "Carcinoma In Situ Of Uterine Cervix",
    ],
  },
  {
    ui: "D018291",
    heading: "Cystadenoma, Mucinous",
    tree_numbers: ["C04.557.470.035.320.225", "C04.557.470.590.485.225"],
  },
  {
    ui: "D018292",
    heading: "Cystadenoma, Papillary",
    tree_numbers: ["C04.557.470.035.320.230", "C04.557.470.590.485.230"],
  },
  {
    ui: "D018293",
    heading: "Cystadenoma, Serous",
    tree_numbers: ["C04.557.470.035.320.240", "C04.557.470.590.485.240"],
  },
  {
    ui: "D018294",
    heading: "Neoplasms, Adnexal and Skin Appendage",
    tree_numbers: ["C04.557.470.550"],
  },
  {
    ui: "D018295",
    heading: "Neoplasms, Basal Cell",
    tree_numbers: ["C04.557.470.565"],
    synonyms: [
      "Basal Cell Tumor",
      "Rodent Ulcer",
      "Basal Cell Cancer",
      "Epithelioma Basal Cell",
      "Basal Cell Neoplasm",
      "Basal Cell Carcinoma Of Skin",
      "Malignant Basal Cell Tumor",
      "Malignant Basal Cell Neoplasm",
    ],
  },
  {
    ui: "D018296",
    heading: "Pilomatrixoma",
    tree_numbers: ["C04.557.470.565.625"],
    synonyms: ["Benign Pilomatricoma", "Pilomatricoma"],
  },
  {
    ui: "D018297",
    heading: "Neoplasms, Cystic, Mucinous, and Serous",
    tree_numbers: ["C04.557.470.590"],
  },
  {
    ui: "D018298",
    heading: "Mucoepidermoid Tumor",
    tree_numbers: ["C04.557.470.590.580"],
  },
  {
    ui: "D018299",
    heading: "Neoplasms, Ductal, Lobular, and Medullary",
    tree_numbers: ["C04.557.470.615"],
  },
  {
    ui: "D018300",
    heading: "Papilloma, Intraductal",
    tree_numbers: ["C04.557.470.615.670"],
    synonyms: ["Ductal Papilloma"],
  },
  {
    ui: "D018301",
    heading: "Neoplasms, Mesothelial",
    tree_numbers: ["C04.557.470.660"],
  },
  {
    ui: "D018302",
    heading: "Neoplasms, Neuroepithelial",
    tree_numbers: ["C04.557.465.625.600", "C04.557.470.670", "C04.557.580.625.600"],
    synonyms: ["Astroblastoma"],
  },
  {
    ui: "D018303",
    heading: "Ganglioglioma",
    tree_numbers: ["C04.557.465.625.600.380.350", "C04.557.470.670.380.350", "C04.557.580.625.600.380.350"],
    synonyms: ["Cns Ganglioglioma", "Childhood Ganglioglioma", "Adult Ganglioglioma"],
  },
  {
    ui: "D018304",
    heading: "Esthesioneuroblastoma, Olfactory",
    tree_numbers: [
      "C04.557.465.625.600.590.650.550.150",
      "C04.557.470.670.590.650.550.150",
      "C04.557.580.625.600.590.650.550.150",
      "C10.292.650.200",
    ],
    synonyms: [
      "Olfactory Esthesioneuroblastoma",
      "Esthesioneuroblastoma",
      "Asthesioneuroblastoma",
      "Paranasal Sinus Olfactory Neuroblastoma",
      "Esthesioneuroepithelioma",
    ],
  },
  {
    ui: "D018305",
    heading: "Ganglioneuroblastoma",
    tree_numbers: [
      "C04.557.465.625.600.590.650.550.300",
      "C04.557.470.670.590.650.550.300",
      "C04.557.580.625.600.590.650.550.300",
    ],
    synonyms: ["Ganglioneuroblastoma"],
  },
  {
    ui: "D018306",
    heading: "Neurocytoma",
    tree_numbers: [
      "C04.557.465.625.600.580",
      "C04.557.470.670.580",
      "C04.557.580.625.600.580",
      "C04.588.614.250.195.648",
      "C10.228.140.211.692",
      "C10.551.240.250.550",
    ],
    synonyms: ["Neurolipocytoma"],
  },
  {
    ui: "D018307",
    heading: "Neoplasms, Squamous Cell",
    tree_numbers: ["C04.557.470.700"],
    synonyms: [
      "Malignant Squamous Cell Tumor",
      "Squamous Carcinoma",
      "Squamous Cell Cancer",
      "Epidermoid Carcinoma",
      "Squamous Cell Epithelioma",
    ],
  },
  {
    ui: "D018308",
    heading: "Papilloma, Inverted",
    tree_numbers: ["C04.557.470.700.600.610"],
    synonyms: ["Inverted Papilloma", "Inverted Papilloma, Squamous Cell"],
  },
  {
    ui: "D018309",
    heading: "Neoplasms, Gonadal Tissue",
    tree_numbers: ["C04.557.475"],
  },
  {
    ui: "D018310",
    heading: "Sertoli-Leydig Cell Tumor",
    tree_numbers: [
      "C04.557.475.750.847",
      "C04.588.322.455.648",
      "C04.588.322.762.500",
      "C04.588.945.440.915.500",
      "C12.294.260.937.500",
      "C12.758.409.937.500",
      "C13.351.500.056.630.705.648",
      "C13.351.937.418.685.648",
      "C19.344.410.648",
      "C19.344.762.500",
      "C19.391.630.705.648",
      "C19.391.829.782.500",
    ],
  },
  {
    ui: "D018311",
    heading: "Luteoma",
    tree_numbers: [
      "C04.557.475.750.751",
      "C04.588.322.455.464",
      "C13.351.500.056.630.705.464",
      "C13.351.937.418.685.464",
      "C19.344.410.464",
      "C19.391.630.705.464",
    ],
  },
  {
    ui: "D018312",
    heading: "Sex Cord-Gonadal Stromal Tumors",
    tree_numbers: ["C04.557.475.750"],
    synonyms: [
      "Sex Cord-Stromal Neoplasm",
      "Sex Cord Stromal Tumour",
      "Specialized Gonadal Neoplasm",
      "Specialized Gonadal Tumor",
      "Sex Cord-Gonadal Stromal Tumour",
      "Specialized Gonadal Tumour",
    ],
  },
  {
    ui: "D018315",
    heading: "Glioma, Subependymal",
    tree_numbers: ["C04.557.465.625.600.380.290.390", "C04.557.470.670.380.290.390", "C04.557.580.625.600.380.290.390"],
    synonyms: ["Subependymal Astrocytoma", "Mixed Subependymoma-Ependymoma", "Who Grade I Ependymal Tumor"],
  },
  {
    ui: "D018316",
    heading: "Gliosarcoma",
    tree_numbers: ["C04.557.465.625.600.380.400", "C04.557.470.670.380.400", "C04.557.580.625.600.380.400"],
    synonyms: ["Glioblastoma With Sarcomatous Component"],
  },
  {
    ui: "D018317",
    heading: "Nerve Sheath Neoplasms",
    tree_numbers: ["C04.557.580.600", "C10.551.775.500", "C10.668.829.725.500"],
    synonyms: ["Nerve Sheath Neoplasm", "Neoplasm Of Peripheral Nerve", "Tumor Of Pns"],
  },
  {
    ui: "D018318",
    heading: "Neurofibroma, Plexiform",
    tree_numbers: ["C04.557.580.600.580.585", "C10.551.775.500.750.500", "C10.668.829.725.500.600.500"],
  },
  {
    ui: "D018319",
    heading: "Neurofibrosarcoma",
    tree_numbers: [
      "C04.557.450.565.590.350.590",
      "C04.557.450.795.350.590",
      "C04.557.580.600.580.795",
      "C10.551.775.500.750.750",
      "C10.668.829.725.500.600.600",
    ],
    synonyms: ["Malignant Neoplasm Of The Peripheral Nerve Sheath"],
  },
  {
    ui: "D018321",
    heading: "Neurothekeoma",
    tree_numbers: ["C04.557.450.565.550.625", "C04.557.580.600.625"],
  },
  {
    ui: "D018322",
    heading: "Angiofibroma",
    tree_numbers: ["C04.557.645.100"],
  },
  {
    ui: "D018323",
    heading: "Hemangioendothelioma, Epithelioid",
    tree_numbers: ["C04.557.645.375.370.380"],
  },
  {
    ui: "D018324",
    heading: "Hemangioma, Capillary",
    tree_numbers: ["C04.557.645.375.380"],
    synonyms: [
      "Strawberry Nevus Of Skin",
      "Juvenile Hemangioma",
      "Strawberry Haemangioma",
      "Cellular Hemangioma Of Infancy",
      "Infantile Hemangioma",
      "Strawberry Nevus",
      "Capillary Hemangioma",
      "Congenital Vascular Naevus",
      "Congenital Vascular Hamartoma",
    ],
  },
  {
    ui: "D018325",
    heading: "Hemangioblastoma",
    tree_numbers: ["C04.557.645.375.380.370"],
    synonyms: ["Capillary Hemangioblastoma"],
  },
  {
    ui: "D018326",
    heading: "Nevi and Melanomas",
    tree_numbers: ["C04.557.665"],
  },
  {
    ui: "D018327",
    heading: "Hutchinson's Melanotic Freckle",
    tree_numbers: ["C04.557.465.625.650.510.385", "C04.557.580.625.650.510.385", "C04.557.665.510.385"],
  },
  {
    ui: "D018328",
    heading: "Melanoma, Amelanotic",
    tree_numbers: ["C04.557.465.625.650.510.515", "C04.557.580.625.650.510.515", "C04.557.665.510.515"],
    synonyms: ["Amelanotic Melanoma"],
  },
  {
    ui: "D018329",
    heading: "Nevus, Blue",
    tree_numbers: ["C04.557.665.560.615.550"],
  },
  {
    ui: "D018330",
    heading: "Nevus, Intradermal",
    tree_numbers: ["C04.557.665.560.590"],
  },
  {
    ui: "D018331",
    heading: "Nevus, Spindle Cell",
    tree_numbers: ["C04.557.665.560.615.625"],
  },
  {
    ui: "D018332",
    heading: "Nevus, Epithelioid and Spindle Cell",
    tree_numbers: ["C04.557.665.560.615.625.585"],
  },
  {
    ui: "D018333",
    heading: "Odontogenic Cyst, Calcifying",
    tree_numbers: ["C04.182.089.530.690.605", "C04.557.695.605", "C05.500.470.690.605", "C07.320.450.670.495"],
  },
  {
    ui: "D018335",
    heading: "Rhabdoid Tumor",
    tree_numbers: ["C04.557.435.710"],
    synonyms: ["Rhabdoid Sarcoma", "Malignant Rhabdoid Tumour"],
  },
  {
    ui: "D018344",
    heading: "T-Lymphocytopenia, Idiopathic CD4-Positive",
    tree_numbers: ["C15.378.553.546.605.800", "C20.673.627.800"],
  },
  {
    ui: "D018347",
    heading: "Hepadnaviridae Infections",
    tree_numbers: ["C01.925.256.430"],
  },
  {
    ui: "D018352",
    heading: "Coronavirus Infections",
    tree_numbers: ["C01.925.782.600.550.200"],
  },
  {
    ui: "D018353",
    heading: "Rhabdoviridae Infections",
    tree_numbers: ["C01.925.782.580.830"],
  },
  {
    ui: "D018354",
    heading: "Alphavirus Infections",
    tree_numbers: ["C01.920.500.078", "C01.925.782.930.100"],
  },
  {
    ui: "D018355",
    heading: "Rubivirus Infections",
    tree_numbers: ["C01.925.782.930.700"],
  },
  {
    ui: "D018357",
    heading: "Respiratory Syncytial Virus Infections",
    tree_numbers: ["C01.925.782.580.600.550.750"],
  },
  {
    ui: "D018358",
    heading: "Neuroendocrine Tumors",
    tree_numbers: ["C04.557.465.625.650", "C04.557.580.625.650"],
    synonyms: ["Neuroendocrine Neoplasm"],
  },
  {
    ui: "D018365",
    heading: "Neoplasm, Residual",
    tree_numbers: ["C04.697.700", "C23.550.727.700"],
  },
  {
    ui: "D018366",
    heading: "Vasculitis, Leukocytoclastic, Cutaneous",
    tree_numbers: ["C14.907.940.910", "C20.543.520.910"],
  },
  {
    ui: "D018370",
    heading: "Leukocyte-Adhesion Deficiency Syndrome",
    tree_numbers: ["C16.320.798.719", "C20.673.795.719"],
    synonyms: ["Congenital Leukocyte Adherence Deficiency"],
  },
  {
    ui: "D018376",
    heading: "Cardiovascular Abnormalities",
    tree_numbers: ["C14.240", "C16.131.240"],
  },
  {
    ui: "D018382",
    heading: "Thyroid Hormone Resistance Syndrome",
    tree_numbers: ["C19.874.410.500"],
    synonyms: [
      "Thyroid Hormone Responsiveness Defect",
      "Thyroid Hormone Resistance Syndrome",
      "Refetoff Syndrome",
      "Thyroid Hormone Resistance",
      "Generalized Thyroid Hormone Resistance",
    ],
  },
  {
    ui: "D018404",
    heading: "Isochromosomes",
    tree_numbers: ["A11.284.187.570", "C23.550.210.430", "G05.360.162.570", "G05.365.590.175.430"],
  },
  {
    ui: "D018409",
    heading: "Foot Injuries",
    tree_numbers: ["C26.558.300"],
  },
  {
    ui: "D018410",
    heading: "Pneumonia, Bacterial",
    tree_numbers: ["C01.150.252.620", "C01.748.610.540", "C08.381.677.540", "C08.730.610.540"],
    synonyms: ["Pneumonia Due To Other Gram-Negative Bacteria", "Gram-Negative Pneumonia"],
  },
  {
    ui: "D018419",
    heading: "Primate Diseases",
    tree_numbers: ["C22.735"],
  },
  {
    ui: "D018420",
    heading: "Ape Diseases",
    tree_numbers: ["C22.735.050"],
  },
  {
    ui: "D018424",
    heading: "Cholesteatoma, Middle Ear",
    tree_numbers: ["C09.218.200", "C17.800.428.260.300"],
    synonyms: [
      "Cholesteatoma Of The Middle Ear",
      "Middle Ear Cholesteatoma",
      "Cholesteatoma Of Middle Ear And/or Mastoid",
      "Epidermosis Of Ear",
      "Epidermosis Of Middle Ear",
      "Cholesteatoma",
      "Cholesteatoma Of Middle Ear",
      "Cholesteatoma Of Middle Ear And Mastoid",
    ],
  },
  {
    ui: "D018437",
    heading: "Brown-Sequard Syndrome",
    tree_numbers: ["C10.597.622.669.300", "C23.888.592.636.637.300"],
    synonyms: ["Brown-Squard Syndrome"],
  },
  {
    ui: "D018438",
    heading: "Blue Toe Syndrome",
    tree_numbers: ["C14.907.355.350.454.500.200", "C14.907.617.249"],
  },
  {
    ui: "D018442",
    heading: "Lymphoma, B-Cell, Marginal Zone",
    tree_numbers: ["C04.557.386.480.150.570", "C15.604.515.569.480.150.570", "C20.683.515.761.480.150.570"],
  },
  {
    ui: "D018450",
    heading: "Disease Progression",
    tree_numbers: ["C23.550.291.656"],
  },
  {
    ui: "D018455",
    heading: "Protein S Deficiency",
    tree_numbers: ["C15.378.100.800", "C15.378.147.890", "C15.378.925.800"],
    synonyms: ["Protein S Deficiency", "Protein S Deficiency Disease"],
  },
  {
    ui: "D018457",
    heading: "Placenta, Retained",
    tree_numbers: ["C13.703.590.767"],
  },
  {
    ui: "D018458",
    heading: "Persistent Vegetative State",
    tree_numbers: ["C10.228.140.140.627", "C10.597.606.358.800.400", "C23.888.592.604.359.800.400"],
  },
  {
    ui: "D018459",
    heading: "Lichen Sclerosus et Atrophicus",
    tree_numbers: ["C17.800.859.475.605"],
  },
  {
    ui: "D018460",
    heading: "Fractures, Comminuted",
    tree_numbers: ["C26.404.186"],
  },
  {
    ui: "D018461",
    heading: "Soft Tissue Infections",
    tree_numbers: ["C01.820"],
  },
  {
    ui: "D018467",
    heading: "Positive-Pressure Respiration, Intrinsic",
    tree_numbers: ["C08.618.846.688"],
  },
  {
    ui: "D018476",
    heading: "Hypokinesia",
    tree_numbers: ["C10.597.350.400", "C23.888.592.350.400"],
  },
  {
    ui: "D018487",
    heading: "Ventricular Dysfunction, Left",
    tree_numbers: ["C14.280.945.900"],
  },
  {
    ui: "D018488",
    heading: "Bone Demineralization, Pathologic",
    tree_numbers: ["C05.116.198.247", "C18.452.104.247"],
  },
  {
    ui: "D018489",
    heading: "Space Motion Sickness",
    tree_numbers: ["C23.888.571.800"],
  },
  {
    ui: "D018496",
    heading: "Hyperoxia",
    tree_numbers: ["C23.888.852.567"],
  },
  {
    ui: "D018497",
    heading: "Ventricular Dysfunction, Right",
    tree_numbers: ["C14.280.945.910"],
  },
  {
    ui: "D018500",
    heading: "Diabetes Insipidus, Nephrogenic",
    tree_numbers: ["C12.777.419.135.500", "C13.351.968.419.135.500"],
    synonyms: [],
  },
  {
    ui: "D018512",
    heading: "Parasitemia",
    tree_numbers: ["C01.610.695", "C23.550.470.790.500.580"],
  },
  {
    ui: "D018549",
    heading: "Cryptogenic Organizing Pneumonia",
    tree_numbers: [
      "C08.127.446.135.140.200",
      "C08.381.483.487.249",
      "C08.381.495.146.135.140.200",
      "C08.381.765.500.750.249",
    ],
    synonyms: [
      "Bronchiolitis Obliterans Organising Pneumonia",
      "Cryptogenic Organizing Pneumonitis",
      "Idiopathic Bronchiolitis Obliterans With Organizing Pneumonia",
      "Boop",
      "Idiopathic Bronchiolitis Obliterans With Organising Pneumonia",
      "Cryptogenic Organising Pneumonitis",
      "Cryptogenic Organising Pneumonia",
      "Bronchiolitis Obliterans Organizing Pneumonia",
    ],
  },
  {
    ui: "D018567",
    heading: "Breast Neoplasms, Male",
    tree_numbers: ["C04.588.180.260", "C17.800.090.500.260"],
    synonyms: ["Neoplasm Of Male Breast", "Malignant Neoplasm Of Male Breast"],
  },
  {
    ui: "D018589",
    heading: "Gastroparesis",
    tree_numbers: ["C06.405.748.543", "C23.888.592.636.263"],
    synonyms: ["Gastric Atonia", "Gastroparesis Syndrome", "Gastroparalysis"],
  },
  {
    ui: "D018601",
    heading: "King's Evil",
    tree_numbers: ["C01.150.252.410.040.552.846.719.500"],
    synonyms: [
      "Tuberculous Lymphadenopathy",
      "Scrofula",
      "King's Evil",
      "Lymph Node Tuberculosis",
      "Tuberculous Adenitis",
    ],
  },
  {
    ui: "D018602",
    heading: "Milk Sickness",
    tree_numbers: ["C25.723.756.600"],
  },
  {
    ui: "D018614",
    heading: "Sweating Sickness",
    tree_numbers: ["C23.888.119.344.672"],
  },
  {
    ui: "D018630",
    heading: "Vitreoretinopathy, Proliferative",
    tree_numbers: ["C11.768.890"],
    synonyms: [
      "Proliferative Vitreoretinopathy",
      "Adniv",
      "Retinitis Proliferans",
      "Autosomal Dominant Neovascular Inflammatory Vitreoretinopathy",
    ],
  },
  {
    ui: "D018633",
    heading: "Pulmonary Atresia",
    tree_numbers: ["C14.240.850.937", "C14.280.484.640", "C16.131.240.850.906"],
  },
  {
    ui: "D018636",
    heading: "Hypoplastic Left Heart Syndrome",
    tree_numbers: ["C14.240.400.625", "C14.280.400.625", "C16.131.240.400.625"],
  },
  {
    ui: "D018640",
    heading: "Stomatognathic System Abnormalities",
    tree_numbers: ["C07.650", "C16.131.850"],
  },
  {
    ui: "D018658",
    heading: "Ventricular Septal Rupture",
    tree_numbers: ["C14.280.470.475.900"],
  },
  {
    ui: "D018677",
    heading: "Tooth Injuries",
    tree_numbers: ["C07.793.850", "C26.900"],
  },
  {
    ui: "D018701",
    heading: "Mononegavirales Infections",
    tree_numbers: ["C01.925.782.580"],
  },
  {
    ui: "D018702",
    heading: "Filoviridae Infections",
    tree_numbers: ["C01.925.782.580.250"],
  },
  {
    ui: "D018746",
    heading: "Systemic Inflammatory Response Syndrome",
    tree_numbers: ["C23.550.470.790", "C23.550.835.900"],
  },
  {
    ui: "D018754",
    heading: "Ventricular Dysfunction",
    tree_numbers: ["C14.280.945"],
  },
  {
    ui: "D018761",
    heading: "Multiple Endocrine Neoplasia Type 1",
    tree_numbers: [
      "C04.588.322.400.500",
      "C04.651.600.500",
      "C04.700.630.500",
      "C16.320.700.630.500",
      "C19.344.400.500",
    ],
    synonyms: ["Men Type I", "Wermer Syndrome", "Wermer's Syndrome"],
  },
  {
    ui: "D018771",
    heading: "Arthralgia",
    tree_numbers: ["C05.550.091", "C23.888.592.612.094", "F02.830.816.444.350", "G11.561.790.444.350"],
  },
  {
    ui: "D018777",
    heading: "Multiple Chemical Sensitivity",
    tree_numbers: ["C20.543.312.500", "C21.223.500"],
    synonyms: [
      "Total Allergy Syndrome",
      "Chemical Aids",
      "Environmental Illness",
      "20th Century Disease",
      "Idiopathic Environmental Illness",
    ],
  },
  {
    ui: "D018778",
    heading: "Hantavirus Infections",
    tree_numbers: ["C01.925.782.147.420"],
  },
  {
    ui: "D018781",
    heading: "Tension-Type Headache",
    tree_numbers: ["C10.228.140.546.399.875"],
  },
  {
    ui: "D018783",
    heading: "Impotence, Vasculogenic",
    tree_numbers: ["C12.294.644.486.500"],
  },
  {
    ui: "D018784",
    heading: "Abdominal Abscess",
    tree_numbers: ["C01.830.025.020"],
  },
  {
    ui: "D018785",
    heading: "Tricuspid Atresia",
    tree_numbers: ["C14.240.400.920", "C14.280.400.920", "C14.280.484.845", "C16.131.240.400.920"],
  },
  {
    ui: "D018792",
    heading: "Encephalitis, Viral",
    tree_numbers: [
      "C01.207.245.340",
      "C01.207.399.750",
      "C01.925.182.525",
      "C10.228.140.430.520.750",
      "C10.228.228.245.340",
      "C10.228.228.399.750",
    ],
  },
  {
    ui: "D018798",
    heading: "Anemia, Iron-Deficiency",
    tree_numbers: ["C15.378.071.196.300", "C18.452.565.100"],
  },
  {
    ui: "D018804",
    heading: "Hantavirus Pulmonary Syndrome",
    tree_numbers: ["C01.925.782.147.420.380", "C08.618.846.450"],
  },
  {
    ui: "D018805",
    heading: "Sepsis",
    tree_numbers: ["C01.757", "C23.550.470.790.500"],
  },
  {
    ui: "D018813",
    heading: "Multiple Endocrine Neoplasia Type 2a",
    tree_numbers: [
      "C04.588.322.400.505",
      "C04.651.600.505",
      "C04.700.630.505",
      "C16.320.700.630.505",
      "C19.344.400.505",
    ],
    synonyms: ["Sipple Syndrome", "Men2a", "Multiple Endocrine Neoplasia Ii"],
  },
  {
    ui: "D018814",
    heading: "Multiple Endocrine Neoplasia Type 2b",
    tree_numbers: [
      "C04.588.322.400.510",
      "C04.651.600.510",
      "C04.700.630.510",
      "C16.320.700.630.510",
      "C19.344.400.510",
    ],
    synonyms: [
      "Multiple Endocrine Neoplasia, Type 3",
      "Mucosal Neuroma Syndrome",
      "Wagenmann-Froboese Syndrome",
      "Men Type Iib",
      "Men2b",
      "Multiple Endocrine Neoplasia Type 2b",
    ],
  },
  {
    ui: "D018827",
    heading: "Carcinoma, Lewis Lung",
    tree_numbers: ["C04.557.470.200.280", "C04.619.230"],
  },
  {
    ui: "D018856",
    heading: "Cystitis, Interstitial",
    tree_numbers: ["C12.777.829.495.500", "C13.351.968.829.495.500"],
    synonyms: ["Ulcerative Cystitis"],
  },
  {
    ui: "D018860",
    heading: "Sneddon Syndrome",
    tree_numbers: ["C10.228.140.300.750", "C14.907.253.774", "C17.800.862.775"],
    synonyms: ["Idiopathic Livedo Reticularis With Systemic Involvement"],
  },
  {
    ui: "D018876",
    heading: "Environmental Illness",
    tree_numbers: ["C20.543.312", "C21.223"],
  },
  {
    ui: "D018877",
    heading: "Sick Building Syndrome",
    tree_numbers: ["C20.543.312.750", "C21.223.750"],
  },
  {
    ui: "D018879",
    heading: "Ventricular Premature Complexes",
    tree_numbers: ["C14.280.067.325.500", "C14.280.123.375.500", "C23.550.073.325.800"],
  },
  {
    ui: "D018880",
    heading: "Atrial Premature Complexes",
    tree_numbers: ["C14.280.067.325.250", "C14.280.123.375.250", "C23.550.073.325.050"],
  },
  {
    ui: "D018882",
    heading: "Heat Stress Disorders",
    tree_numbers: ["C26.522"],
  },
  {
    ui: "D018883",
    heading: "Heat Stroke",
    tree_numbers: ["C26.522.500"],
  },
  {
    ui: "D018886",
    heading: "Aphasia, Conduction",
    tree_numbers: ["C10.597.606.150.500.800.100.111", "C23.888.592.604.150.500.800.100.111"],
  },
  {
    ui: "D018887",
    heading: "Landau-Kleffner Syndrome",
    tree_numbers: ["C10.228.140.490.493.500"],
    synonyms: ["Acquired Epileptic Aphasia"],
  },
  {
    ui: "D018888",
    heading: "Aphasia, Primary Progressive",
    tree_numbers: [
      "C10.228.140.380.132",
      "C10.597.606.150.500.800.100.155",
      "C23.888.592.604.150.500.800.100.155",
      "F03.615.400.125",
    ],
  },
  {
    ui: "D018901",
    heading: "Peroxisomal Disorders",
    tree_numbers: ["C16.320.565.663", "C18.452.648.663"],
    synonyms: ["Peroxisomal Disorder"],
  },
  {
    ui: "D018902",
    heading: "Chondrodysplasia Punctata, Rhizomelic",
    tree_numbers: ["C05.116.099.708.195.200", "C16.320.565.663.265", "C18.452.648.663.265"],
    synonyms: ["Chondrodysplasia Punctata, Rhizomelic Form"],
  },
  {
    ui: "D018908",
    heading: "Muscle Weakness",
    tree_numbers: ["C05.651.515", "C10.597.613.593", "C23.550.695", "C23.888.592.608.593"],
  },
  {
    ui: "D018917",
    heading: "Optic Neuropathy, Ischemic",
    tree_numbers: ["C10.292.700.600", "C11.640.643", "C14.907.601"],
    synonyms: ["Ischemic Optic Neuropathy"],
  },
  {
    ui: "D018921",
    heading: "Leukostasis",
    tree_numbers: ["C15.378.553.560"],
    synonyms: ["Leukostasis"],
  },
  {
    ui: "D018923",
    heading: "Persian Gulf Syndrome",
    tree_numbers: ["C24.653"],
    synonyms: ["Gulf War Syndrome"],
  },
  {
    ui: "D018934",
    heading: "Fournier Gangrene",
    tree_numbers: ["C01.150.252.377", "C12.294.229"],
  },
  {
    ui: "D018979",
    heading: "Myositis, Inclusion Body",
    tree_numbers: ["C05.651.594.600", "C10.668.491.562.500"],
  },
  {
    ui: "D018980",
    heading: "Williams Syndrome",
    tree_numbers: ["C10.597.606.360.970", "C14.280.484.048.750.535.960", "C16.131.260.970", "C16.320.180.970"],
    synonyms: ["Fanconi Schlesinger Syndrome"],
  },
  {
    ui: "D018981",
    heading: "Congenital Disorders of Glycosylation",
    tree_numbers: ["C16.320.565.202.125", "C18.452.648.202.125"],
    synonyms: ["Carbohydrate-Deficient Glycoprotein Syndrome"],
  },
  {
    ui: "D019042",
    heading: "Muscle Neoplasms",
    tree_numbers: ["C04.588.839.500", "C05.651.494"],
    synonyms: [
      "Muscle Neoplasm",
      "Neoplasm Of Muscle",
      "Myomatous Tumor",
      "Myomatous Neoplasm",
      "Muscle Tissue Neoplasm",
    ],
  },
  {
    ui: "D019043",
    heading: "Vascular Neoplasms",
    tree_numbers: ["C04.588.839.750", "C14.907.936"],
    synonyms: [
      "Malignant Tumor Of Pulmonary Vein",
      "Blood Vessel Tumour Disorder",
      "Haemangiomatous Tumour",
      "Blood Vessel Tumors",
      "Malignant Vascular Tumor",
      "Blood Vessel Neoplasm",
      "Pulmonary Vein Malignant Neoplasm",
      "Blood Vessel Neoplasm",
      "Blood Vessel Tumor",
      "Malignant Vascular Neoplasm",
      "Renal Vein Leiomyosarcoma",
      "Pulmonary Artery Malignant Neoplasm",
      "Leiomyosarcoma Of The Renal Vein",
      "Vascular Tissue Neoplasm",
      "Neoplasm Of Great Vessel",
      "Malignant Great Vessel Tumor",
      "Malignant Tumor Of Pulmonary Artery",
      "Vascular Tumors",
    ],
  },
  {
    ui: "D019046",
    heading: "Bone Marrow Neoplasms",
    tree_numbers: ["C04.588.448.200", "C15.378.190.250", "C15.378.400.200"],
  },
  {
    ui: "D019048",
    heading: "Prostatic Intraepithelial Neoplasia",
    tree_numbers: ["C04.557.470.200.240.500"],
  },
  {
    ui: "D019052",
    heading: "Depression, Postpartum",
    tree_numbers: ["C13.703.844.253", "F03.600.300.350"],
    synonyms: ["Maternity Blues", "Postnatal Depression"],
  },
  {
    ui: "D019053",
    heading: "HIV Enteropathy",
    tree_numbers: [
      "C01.221.250.875.398",
      "C01.221.812.640.400.480",
      "C01.778.640.400.480",
      "C01.925.782.815.616.400.398",
      "C01.925.813.400.480",
      "C06.405.469.400",
      "C20.673.480.480",
    ],
  },
  {
    ui: "D019066",
    heading: "Facies",
    tree_numbers: ["C23.550.291.812", "E01.370.600.230"],
  },
  {
    ui: "D019080",
    heading: "Cafe-au-Lait Spots",
    tree_numbers: ["C17.800.621.250", "C23.888.885.250"],
  },
  {
    ui: "D019082",
    heading: "Smith-Lemli-Opitz Syndrome",
    tree_numbers: [
      "C16.131.077.860",
      "C16.320.565.398.850",
      "C16.320.565.925.875",
      "C18.452.584.500.937",
      "C18.452.648.398.850",
      "C18.452.648.925.875",
    ],
    synonyms: ["Smith-Opitz-Inborn Syndrome", "Rutledge Lethal Multiple Congenital Anomaly Syndrome"],
  },
  {
    ui: "D019106",
    heading: "Postoperative Hemorrhage",
    tree_numbers: ["C23.550.414.941", "C23.550.767.850"],
  },
  {
    ui: "D019115",
    heading: "Fasciitis, Necrotizing",
    tree_numbers: ["C05.321.550"],
  },
  {
    ui: "D019121",
    heading: "Burkholderia Infections",
    tree_numbers: ["C01.150.252.400.170"],
  },
  {
    ui: "D019142",
    heading: "Hemorrhagic Fever, Ebola",
    tree_numbers: ["C01.925.782.417.415", "C01.925.782.580.250.400"],
    synonyms: ["Ebola Virus Disease"],
  },
  {
    ui: "D019150",
    heading: "Neuroaxonal Dystrophies",
    tree_numbers: ["C10.228.140.744"],
  },
  {
    ui: "D019189",
    heading: "Iron Metabolism Disorders",
    tree_numbers: ["C18.452.565"],
    synonyms: ["Iron Disorder", "Disorder Of Iron Metabolism", "Iron Disorder"],
  },
  {
    ui: "D019190",
    heading: "Iron Overload",
    tree_numbers: ["C18.452.565.500"],
  },
  {
    ui: "D019214",
    heading: "Gingival Overgrowth",
    tree_numbers: ["C07.465.714.258.428"],
    synonyms: ["Gingival Enlargement"],
  },
  {
    ui: "D019217",
    heading: "Tooth Attrition",
    tree_numbers: ["C07.793.818.249"],
  },
  {
    ui: "D019226",
    heading: "Oral Ulcer",
    tree_numbers: ["C07.465.672"],
  },
  {
    ui: "D019247",
    heading: "HIV Wasting Syndrome",
    tree_numbers: [
      "C01.221.250.875.520",
      "C01.221.812.640.400.520",
      "C01.778.640.400.520",
      "C01.925.782.815.616.400.520",
      "C01.925.813.400.520",
      "C18.452.915.520",
      "C18.654.940.520",
      "C20.673.480.520",
    ],
  },
  {
    ui: "D019263",
    heading: "Dysthymic Disorder",
    tree_numbers: ["F03.600.300.400"],
    synonyms: ["Dysthymia"],
  },
  {
    ui: "D019280",
    heading: "Branchio-Oto-Renal Syndrome",
    tree_numbers: ["C16.131.077.208", "C16.131.260.090", "C16.320.180.090"],
    synonyms: [
      "Branchio-Oto-Renal Syndrome",
      "Branchio-Otorenal Dysplasia",
      "Branchiootorenal Dysplasia",
      "Melnick-Fraser Syndrome",
    ],
  },
  {
    ui: "D019282",
    heading: "Wasting Syndrome",
    tree_numbers: ["C18.452.915", "C18.654.940"],
  },
  {
    ui: "D019283",
    heading: "Pancreatitis, Acute Necrotizing",
    tree_numbers: ["C06.689.750.650"],
  },
  {
    ui: "D019292",
    heading: "Skull Base Neoplasms",
    tree_numbers: ["C04.588.149.721.828", "C05.116.231.754.829"],
    synonyms: ["Tumors Of Skull Base"],
  },
  {
    ui: "D019294",
    heading: "Xanthomatosis, Cerebrotendinous",
    tree_numbers: ["C16.320.565.398.925", "C18.452.584.750.975", "C18.452.648.398.925"],
    synonyms: ["Cholestanol Storage Disease"],
  },
  {
    ui: "D019305",
    heading: "Epilepsy, Rolandic",
    tree_numbers: ["C10.228.140.490.360.280", "C10.228.140.490.493.250"],
    synonyms: [
      "Benign Rolandic Epilepsy",
      "Bcects",
      "Benign Childhood Epilepsy With Centrotemporal Spike",
      "Rolandic Epilepsy",
      "Sylvan Seizures",
    ],
  },
  {
    ui: "D019310",
    heading: "Pseudolymphoma",
    tree_numbers: ["C15.604.613"],
  },
  {
    ui: "D019315",
    heading: "Retrobulbar Hemorrhage",
    tree_numbers: ["C11.675.700", "C23.550.414.960"],
  },
  {
    ui: "D019318",
    heading: "Porcine Reproductive and Respiratory Syndrome",
    tree_numbers: ["C01.925.782.600.100.700", "C22.905.700"],
  },
  {
    ui: "D019320",
    heading: "Embolism, Paradoxical",
    tree_numbers: ["C14.907.355.590.400"],
  },
  {
    ui: "D019337",
    heading: "Hematologic Neoplasms",
    tree_numbers: ["C04.588.448", "C15.378.400"],
    synonyms: [
      "Hematopoietic Tumors",
      "Hematopoietic Cancer",
      "Malignant Hematopoietic Neoplasm",
      "Hematologic Malignancy",
      "Hematologic Neoplasm",
      "Hematopoietic Neoplasm",
      "Hematological Tumors",
      "Hematopoietic And Lymphoid System Tumor",
      "Blood Cancer",
    ],
  },
  {
    ui: "D019339",
    heading: "Port-Wine Stain",
    tree_numbers: ["C16.131.831.675", "C17.800.804.675"],
  },
  {
    ui: "D019349",
    heading: "Roseolovirus Infections",
    tree_numbers: ["C01.925.256.466.850"],
  },
  {
    ui: "D019350",
    heading: "Astroviridae Infections",
    tree_numbers: ["C01.925.782.105"],
  },
  {
    ui: "D019351",
    heading: "Rubulavirus Infections",
    tree_numbers: ["C01.925.782.580.600.680"],
  },
  {
    ui: "D019446",
    heading: "Endotoxemia",
    tree_numbers: ["C01.757.100.275", "C01.861.375", "C23.550.470.790.500.100.275"],
  },
  {
    ui: "D019449",
    heading: "Pouchitis",
    tree_numbers: ["C06.405.205.462.624.500", "C06.405.469.326.875.500", "C06.405.469.420.520.500"],
  },
  {
    ui: "D019457",
    heading: "Chromosome Breakage",
    tree_numbers: ["C23.550.210.170", "G05.200.210.170", "G05.365.590.175.175"],
  },
  {
    ui: "D019462",
    heading: "Syncope, Vasovagal",
    tree_numbers: ["C10.177.575.600.800", "C10.597.606.358.800.600.500", "C23.888.592.604.359.800.600.500"],
  },
  {
    ui: "D019465",
    heading: "Craniofacial Abnormalities",
    tree_numbers: ["C05.660.207", "C16.131.621.207"],
  },
  {
    ui: "D019512",
    heading: "Pancreatitis, Alcoholic",
    tree_numbers: ["C06.689.750.660", "C25.775.100.087.730"],
  },
  {
    ui: "D019522",
    heading: "Vaginal Discharge",
    tree_numbers: ["C13.351.500.894.700"],
  },
  {
    ui: "D019534",
    heading: "Shoulder Impingement Syndrome",
    tree_numbers: ["C05.550.840", "C26.803.500"],
    synonyms: ["Shoulder Impingement Syndrome", "Subacromial Impingement", "Impingement Syndrome Of Shoulder Region"],
  },
  {
    ui: "D019547",
    heading: "Neck Pain",
    tree_numbers: ["C23.888.592.612.553"],
  },
  {
    ui: "D019553",
    heading: "Tooth, Nonvital",
    tree_numbers: ["C07.793.237.910"],
  },
  {
    ui: "D019557",
    heading: "Dermatitis, Perioral",
    tree_numbers: ["C17.800.174.550", "C17.800.271.250"],
  },
  {
    ui: "D019559",
    heading: "Capillary Leak Syndrome",
    tree_numbers: ["C14.907.218"],
    synonyms: ["Capillary Leak Syndrome"],
  },
  {
    ui: "D019567",
    heading: "Back Injuries",
    tree_numbers: ["C26.117"],
  },
  {
    ui: "D019568",
    heading: "Lung, Hyperlucent",
    tree_numbers: ["C08.381.570"],
  },
  {
    ui: "D019569",
    heading: "Hemifacial Spasm",
    tree_numbers: ["C07.465.364", "C10.597.613.750.400", "C23.888.592.608.750.400"],
  },
  {
    ui: "D019571",
    heading: "Arrhythmogenic Right Ventricular Dysplasia",
    tree_numbers: ["C14.240.400.145", "C14.280.238.028", "C14.280.400.145", "C16.131.240.400.145"],
    synonyms: [
      "Arvd",
      "Arvc",
      "Arrhythmogenic Right Ventricular Dysplasia",
      "Arrhythmogenic Right Ventricular Dysplasia/cardiomyopathy",
      "Arvc Cardiomyopathy",
    ],
  },
  {
    ui: "D019572",
    heading: "Retinal Neoplasms",
    tree_numbers: ["C04.588.364.818", "C11.319.475", "C11.768.717"],
    synonyms: [
      "Malignant Tumor Of Retina",
      "Retinal Tumor",
      "Malignant Neoplasm Of Retina",
      "Malignant Retinal Neoplasm",
      "Neoplasm Of Retina",
    ],
  },
  {
    ui: "D019574",
    heading: "Optic Nerve Neoplasms",
    tree_numbers: [
      "C04.588.614.300.600",
      "C04.588.614.596.240.240",
      "C10.292.225.800",
      "C10.292.700.500",
      "C10.551.360.500",
      "C10.551.775.250.500",
      "C11.640.544",
    ],
    synonyms: ["Tumour Of Optic Nerve", "Tumor Of Second Cranial Nerve", "Neoplasm Of Optic Nerve"],
  },
  {
    ui: "D019575",
    heading: "Blindness, Cortical",
    tree_numbers: ["C10.597.751.941.162.250", "C11.966.075.250", "C23.888.592.763.941.162.250"],
  },
  {
    ui: "D019578",
    heading: "Multiple System Atrophy",
    tree_numbers: ["C10.177.575.550", "C10.228.140.079.612", "C10.228.662.550", "C10.574.928.625"],
    synonyms: ["Multiple System Atrophy", "Shy-Drager Syndrome"],
  },
  {
    ui: "D019584",
    heading: "Hot Flashes",
    tree_numbers: ["C23.888.475"],
  },
  {
    ui: "D019585",
    heading: "Intracranial Hypotension",
    tree_numbers: ["C10.228.140.638"],
  },
  {
    ui: "D019586",
    heading: "Intracranial Hypertension",
    tree_numbers: ["C10.228.140.631"],
    synonyms: ["Raised Intracranial Pressure"],
  },
  {
    ui: "D019588",
    heading: "Aging, Premature",
    tree_numbers: ["C23.888.069"],
  },
  {
    ui: "D019591",
    heading: "Pseudophakia",
    tree_numbers: ["C23.888.681"],
  },
  {
    ui: "D019595",
    heading: "Severe Dengue",
    tree_numbers: [
      "C01.920.500.270.200",
      "C01.925.081.270.200",
      "C01.925.782.350.250.214.200",
      "C01.925.782.417.214.200",
    ],
    synonyms: ["Dhf"],
  },
  {
    ui: "D019636",
    heading: "Neurodegenerative Diseases",
    tree_numbers: ["C10.574"],
    synonyms: ["Degenerative Disease", "Neurodegenerative Disease"],
  },
  {
    ui: "D019687",
    heading: "Uterine Inversion",
    tree_numbers: ["C13.351.500.852.726", "C13.703.420.814"],
  },
  {
    ui: "D019693",
    heading: "Hepatitis, Autoimmune",
    tree_numbers: ["C06.552.380.350.300", "C20.111.567"],
    synonyms: [
      "Autoimmune Chronic Active Hepatitis",
      "Autoimmune Hepatitis",
      "Autoimmune Hepatitis",
      "Autoimmune Hepatitis With Centrilobular Necrosis",
    ],
  },
  {
    ui: "D019694",
    heading: "Hepatitis B, Chronic",
    tree_numbers: [
      "C01.221.250.500.100",
      "C01.925.256.430.400.100",
      "C01.925.440.435.100",
      "C06.552.380.350.100",
      "C06.552.380.705.437.100",
    ],
  },
  {
    ui: "D019698",
    heading: "Hepatitis C, Chronic",
    tree_numbers: [
      "C01.221.250.750.120",
      "C01.925.440.440.120",
      "C01.925.782.350.350.120",
      "C06.552.380.350.120",
      "C06.552.380.705.440.120",
    ],
  },
  {
    ui: "D019701",
    heading: "Hepatitis D, Chronic",
    tree_numbers: ["C01.925.440.450.100", "C01.925.782.450.100", "C06.552.380.350.220", "C06.552.380.705.450.100"],
  },
  {
    ui: "D019767",
    heading: "Maxillofacial Abnormalities",
    tree_numbers: ["C05.660.207.540", "C07.650.500", "C16.131.621.207.540", "C16.131.850.500"],
  },
  {
    ui: "D019773",
    heading: "Epiretinal Membrane",
    tree_numbers: ["C11.768.328"],
    synonyms: ["Macular Retinal Puckering", "Macular Puckering Of Retina", "Cellophane Maculopathy"],
  },
  {
    ui: "D019838",
    heading: "Neck Injuries",
    tree_numbers: ["C26.700"],
  },
  {
    ui: "D019846",
    heading: "Miller Fisher Syndrome",
    tree_numbers: [
      "C10.114.750.100.500",
      "C10.228.140.252.500",
      "C10.292.562.350",
      "C10.314.750.450.500",
      "C10.668.829.350.500",
      "C10.668.829.800.750.300.500",
      "C11.590.312",
      "C20.111.258.750.400.500",
    ],
    synonyms: ["Fisher's Syndrome", "Miller-Fisher Variant Of Guillain-Barre Syndrome"],
  },
  {
    ui: "D019851",
    heading: "Thrombophilia",
    tree_numbers: ["C15.378.925"],
    synonyms: ["Hypercoagulability State"],
  },
  {
    ui: "D019867",
    heading: "Anti-Glomerular Basement Membrane Disease",
    tree_numbers: ["C08.381.483.156", "C12.777.419.570.363.304", "C13.351.968.419.570.363.304", "C20.111.190"],
    synonyms: ["Anti-Gbm Disease", "Anti-Glomerular Basement Membrane Disease"],
  },
  {
    ui: "D019871",
    heading: "Dyskeratosis Congenita",
    tree_numbers: [
      "C15.378.190.223.500.750",
      "C16.131.831.150",
      "C16.320.322.108",
      "C16.320.850.235",
      "C17.800.804.150",
      "C17.800.827.235",
    ],
  },
  {
    ui: "D019873",
    heading: "Schnitzler Syndrome",
    tree_numbers: ["C20.683.780.640.700"],
  },
  {
    ui: "D019896",
    heading: "alpha 1-Antitrypsin Deficiency",
    tree_numbers: ["C06.552.074", "C08.381.112", "C16.320.060", "C23.550.325.500.500"],
    synonyms: ["Aat Deficiency"],
  },
  {
    ui: "D019954",
    heading: "Neurobehavioral Manifestations",
    tree_numbers: ["C10.597.606", "C23.888.592.604", "F01.700"],
  },
  {
    ui: "D019955",
    heading: "Conduct Disorder",
    tree_numbers: ["F03.625.094.300"],
  },
  {
    ui: "D019956",
    heading: "Stereotypic Movement Disorder",
    tree_numbers: ["F03.625.984"],
    synonyms: ["Stereotypy Habit Disorder", "Stereotyped Repetitive Movements"],
  },
  {
    ui: "D019957",
    heading: "Motor Skills Disorders",
    tree_numbers: ["F03.625.813"],
  },
  {
    ui: "D019958",
    heading: "Attention Deficit and Disruptive Behavior Disorders",
    tree_numbers: ["F03.625.094"],
  },
  {
    ui: "D019959",
    heading: "Feeding and Eating Disorders of Childhood",
    tree_numbers: ["F03.400.500"],
  },
  {
    ui: "D019960",
    heading: "Elimination Disorders",
    tree_numbers: ["F03.388"],
  },
  {
    ui: "D019962",
    heading: "Reactive Attachment Disorder",
    tree_numbers: ["F03.625.937"],
  },
  {
    ui: "D019964",
    heading: "Mood Disorders",
    tree_numbers: ["F03.600"],
    synonyms: ["Episodic Mood Disorder"],
  },
  {
    ui: "D019965",
    heading: "Neurocognitive Disorders",
    tree_numbers: ["F03.615"],
    synonyms: ["Cognitive Disease"],
  },
  {
    ui: "D019966",
    heading: "Substance-Related Disorders",
    tree_numbers: ["C25.775", "F03.900"],
  },
  {
    ui: "D019967",
    heading: "Schizophrenia Spectrum and Other Psychotic Disorders",
    tree_numbers: ["F03.700"],
  },
  {
    ui: "D019968",
    heading: "Sexual and Gender Disorders",
    tree_numbers: ["F03.835.750"],
  },
  {
    ui: "D019969",
    heading: "Amphetamine-Related Disorders",
    tree_numbers: ["C25.775.225", "F03.900.225"],
  },
  {
    ui: "D019970",
    heading: "Cocaine-Related Disorders",
    tree_numbers: ["C25.775.300", "F03.900.300"],
  },
  {
    ui: "D019973",
    heading: "Alcohol-Related Disorders",
    tree_numbers: ["C25.775.100", "F03.900.100"],
  },
  {
    ui: "D020016",
    heading: "Activated Protein C Resistance",
    tree_numbers: ["C15.378.100.100.037", "C15.378.100.141.036", "C15.378.925.050", "C16.320.099.037"],
  },
  {
    ui: "D020018",
    heading: "Sexual Dysfunctions, Psychological",
    tree_numbers: ["F03.835"],
    synonyms: ["Lack Or Loss Of Sexual Desire"],
  },
  {
    ui: "D020019",
    heading: "Neurocysticercosis",
    tree_numbers: [
      "C01.207.205.250.550",
      "C01.610.105.250.550",
      "C01.610.335.190.902.185.550",
      "C10.228.228.205.250.550",
    ],
  },
  {
    ui: "D020022",
    heading: "Genetic Predisposition to Disease",
    tree_numbers: ["C23.550.291.687.500", "G05.380.355"],
  },
  {
    ui: "D020031",
    heading: "Epstein-Barr Virus Infections",
    tree_numbers: ["C01.925.256.466.313", "C01.925.928.313"],
  },
  {
    ui: "D020042",
    heading: "Histiocytic Necrotizing Lymphadenitis",
    tree_numbers: ["C15.604.315.300"],
  },
  {
    ui: "D020047",
    heading: "Rectocele",
    tree_numbers: ["C06.405.469.860.810", "C23.300.707.984"],
  },
  {
    ui: "D020065",
    heading: "Hepatopulmonary Syndrome",
    tree_numbers: ["C06.552.455", "C08.381.385"],
    synonyms: ["Hepatopulmonary Syndrome"],
  },
  {
    ui: "D020069",
    heading: "Shoulder Pain",
    tree_numbers: ["C05.550.091.700", "C23.888.592.612.094.700", "F02.830.816.444.350.500", "G11.561.790.444.350.500"],
  },
  {
    ui: "D020078",
    heading: "Neurogenic Inflammation",
    tree_numbers: ["C10.597.609", "C23.550.470.448"],
  },
  {
    ui: "D020083",
    heading: "Acquired Hyperostosis Syndrome",
    tree_numbers: ["C05.116.099.708.025"],
    synonyms: ["Synovitis, Acne, Pustulosis Palmaris, Hyperostosis, Osteomyelitis Syndrome"],
  },
  {
    ui: "D020096",
    heading: "Zygomycosis",
    tree_numbers: ["C01.150.703.980"],
  },
  {
    ui: "D020132",
    heading: "Anticipation, Genetic",
    tree_numbers: ["C23.550.291.687.500.500", "G05.420.040"],
  },
  {
    ui: "D020138",
    heading: "Hyperhomocysteinemia",
    tree_numbers: ["C16.320.565.100.480", "C18.452.603.378", "C18.452.648.100.480", "C18.654.521.500.133.699.418"],
  },
  {
    ui: "D020139",
    heading: "Gastroschisis",
    tree_numbers: ["C05.660.417", "C16.131.621.417", "C23.300.707.374.500"],
  },
  {
    ui: "D020140",
    heading: "Lysosomal Storage Diseases, Nervous System",
    tree_numbers: [
      "C10.228.140.163.100.435",
      "C16.320.565.189.435",
      "C16.320.565.595.554",
      "C18.452.132.100.435",
      "C18.452.648.189.435",
      "C18.452.648.595.554",
    ],
  },
  {
    ui: "D020141",
    heading: "Hemostatic Disorders",
    tree_numbers: ["C14.907.454", "C15.378.463.515"],
  },
  {
    ui: "D020143",
    heading: "Gangliosidoses, GM2",
    tree_numbers: [
      "C10.228.140.163.100.435.825.300.300",
      "C16.320.565.189.435.825.300.300",
      "C16.320.565.398.641.803.350.300",
      "C16.320.565.595.554.825.300.300",
      "C18.452.132.100.435.825.300.300",
      "C18.452.584.687.803.350.300",
      "C18.452.648.189.435.825.300.300",
      "C18.452.648.398.641.803.350.300",
      "C18.452.648.595.554.825.300.300",
    ],
    synonyms: ["Gangliosidosis Gm2"],
  },
  {
    ui: "D020144",
    heading: "Basal Ganglia Cerebrovascular Disease",
    tree_numbers: ["C10.228.140.079.127", "C10.228.140.300.100", "C14.907.253.061"],
  },
  {
    ui: "D020145",
    heading: "Basal Ganglia Hemorrhage",
    tree_numbers: [
      "C10.228.140.079.127.500",
      "C10.228.140.300.100.200",
      "C10.228.140.300.535.200.150",
      "C14.907.253.061.200",
      "C14.907.253.573.200.150",
      "C23.550.414.913.100.200",
    ],
  },
  {
    ui: "D020146",
    heading: "Putaminal Hemorrhage",
    tree_numbers: [
      "C10.228.140.079.127.500.500",
      "C10.228.140.300.100.200.500",
      "C10.228.140.300.535.200.150.500",
      "C14.907.253.061.200.500",
      "C14.907.253.573.200.150.500",
      "C23.550.414.913.100.200.500",
    ],
  },
  {
    ui: "D020147",
    heading: "Coagulation Protein Disorders",
    tree_numbers: ["C15.378.100.141"],
  },
  {
    ui: "D020149",
    heading: "Manganese Poisoning",
    tree_numbers: ["C10.720.475.500", "C25.723.522.813"],
  },
  {
    ui: "D020150",
    heading: "Chorea Gravidarum",
    tree_numbers: ["C10.228.140.079.294", "C10.228.662.262.249.500", "C13.703.141"],
    synonyms: ["Chorea Gravidarum"],
  },
  {
    ui: "D020151",
    heading: "Protein C Deficiency",
    tree_numbers: ["C15.378.100.100.690", "C15.378.147.880", "C15.378.925.795", "C16.320.099.690"],
  },
  {
    ui: "D020152",
    heading: "Antithrombin III Deficiency",
    tree_numbers: ["C15.378.100.100.075", "C15.378.147.150", "C15.378.925.075", "C16.320.099.075"],
    synonyms: ["Hereditary Thrombophilia Due To Congenital Antithrombin Deficiency", "At Iii Deficiency"],
  },
  {
    ui: "D020157",
    heading: "Amino Acid Transport Disorders, Inborn",
    tree_numbers: ["C16.320.565.151", "C18.452.648.151"],
  },
  {
    ui: "D020158",
    heading: "Hyperglycinemia, Nonketotic",
    tree_numbers: [
      "C10.228.140.163.100.375",
      "C16.320.565.100.477",
      "C16.320.565.189.375",
      "C18.452.132.100.375",
      "C18.452.648.100.477",
      "C18.452.648.189.375",
    ],
    synonyms: ["Nonketotic Hyperglycinemia", "Non-Ketotic Hyperglycinemia"],
  },
  {
    ui: "D020159",
    heading: "Citrullinemia",
    tree_numbers: [
      "C10.228.140.163.100.937.374",
      "C16.320.565.100.940.374",
      "C16.320.565.189.937.374",
      "C18.452.132.100.937.374",
      "C18.452.648.100.940.374",
      "C18.452.648.189.937.374",
    ],
    synonyms: ["Deficiency Of Citrulline-Aspartate Ligase", "Classic Citrullinemia", "Ass Deficiency"],
  },
  {
    ui: "D020162",
    heading: "Hyperargininemia",
    tree_numbers: [
      "C10.228.140.163.100.937.500",
      "C16.320.565.100.940.500",
      "C16.320.565.189.937.500",
      "C18.452.132.100.937.437",
      "C18.452.648.100.940.437",
      "C18.452.648.189.937.437",
    ],
    synonyms: ["Arginase Deficiency", "Argininemia", "Deficiency Of Canavanase"],
  },
  {
    ui: "D020163",
    heading: "Ornithine Carbamoyltransferase Deficiency Disease",
    tree_numbers: [
      "C10.228.140.163.100.937.750",
      "C16.320.322.828",
      "C16.320.565.100.940.750",
      "C16.320.565.189.937.750",
      "C18.452.132.100.937.500",
      "C18.452.648.100.940.500",
      "C18.452.648.189.937.500",
    ],
    synonyms: ["Deficiency Of Citrulline Phosphorylase", "Ornithine Transcarbamylase Deficiency"],
  },
  {
    ui: "D020165",
    heading: "Carbamoyl-Phosphate Synthase I Deficiency Disease",
    tree_numbers: [
      "C10.228.140.163.100.937.249",
      "C16.320.565.100.940.249",
      "C16.320.565.189.937.249",
      "C18.452.132.100.937.249",
      "C18.452.648.100.940.249",
      "C18.452.648.189.937.249",
      "C18.452.660.097",
    ],
    synonyms: ["Cps I Deficiency"],
  },
  {
    ui: "D020167",
    heading: "Hyperlysinemias",
    tree_numbers: [
      "C10.228.140.163.100.380",
      "C16.320.565.100.544",
      "C16.320.565.189.380",
      "C18.452.132.100.380",
      "C18.452.648.100.544",
      "C18.452.648.189.380",
    ],
  },
  {
    ui: "D020176",
    heading: "Tyrosinemias",
    tree_numbers: [
      "C10.228.140.163.100.875",
      "C16.320.565.100.880",
      "C16.320.565.189.875",
      "C18.452.132.100.875",
      "C18.452.648.100.880",
      "C18.452.648.189.875",
    ],
  },
  {
    ui: "D020177",
    heading: "Idiopathic Hypersomnia",
    tree_numbers: ["C10.886.425.800.200.400", "F03.870.400.800.200.400"],
  },
  {
    ui: "D020178",
    heading: "Sleep Disorders, Circadian Rhythm",
    tree_numbers: ["C10.281.800", "C10.886.425.200", "C24.900", "F03.870.400.200"],
  },
  {
    ui: "D020179",
    heading: "Jet Lag Syndrome",
    tree_numbers: ["C10.281.440", "C10.886.425.200.500", "C23.888.914.500", "F03.870.400.200.500", "I03.883.855.500"],
  },
  {
    ui: "D020181",
    heading: "Sleep Apnea, Obstructive",
    tree_numbers: ["C08.618.085.852.850", "C10.886.425.800.750.850"],
    synonyms: ["Obstructive Sleep Apnea Syndrome"],
  },
  {
    ui: "D020182",
    heading: "Sleep Apnea, Central",
    tree_numbers: ["C08.618.085.852.800", "C10.886.425.800.750.800"],
    synonyms: ["Primary Central Sleep Apnea"],
  },
  {
    ui: "D020183",
    heading: "Nocturnal Paroxysmal Dystonia",
    tree_numbers: ["C10.886.659.627", "F03.870.664.627"],
  },
  {
    ui: "D020184",
    heading: "Night Terrors",
    tree_numbers: ["C10.886.659.635.600", "F03.870.664.635.600"],
  },
  {
    ui: "D020186",
    heading: "Sleep Bruxism",
    tree_numbers: ["C07.793.099.500", "C10.886.659.637", "F03.870.664.637"],
    synonyms: ["Teeth Grinding", "Sleep Related Bruxism", "Grinding Teeth", "Bruxism - Teeth Grinding"],
  },
  {
    ui: "D020187",
    heading: "REM Sleep Behavior Disorder",
    tree_numbers: ["C10.886.659.633.700", "F03.870.664.633.700"],
    synonyms: [
      "Rapid Eye Movement Sleep Behavior Disorder",
      "Rem Sleep Behaviour Disorder",
      "Rapid Eye Movement Sleep Behaviour Disorder",
    ],
  },
  {
    ui: "D020188",
    heading: "Sleep Paralysis",
    tree_numbers: ["C10.886.659.633.800", "F03.870.664.633.800"],
  },
  {
    ui: "D020189",
    heading: "Nocturnal Myoclonus Syndrome",
    tree_numbers: ["C10.886.425.800.600", "C10.886.659.618"],
    synonyms: ["Nocturnal Myoclonus"],
  },
  {
    ui: "D020190",
    heading: "Myoclonic Epilepsy, Juvenile",
    tree_numbers: ["C10.228.140.490.375.130.670", "C10.228.140.490.493.063.670"],
    synonyms: [],
  },
  {
    ui: "D020191",
    heading: "Myoclonic Epilepsies, Progressive",
    tree_numbers: ["C10.228.140.490.375.130.650", "C10.228.140.490.493.063.650"],
    synonyms: ["Amrf", "Action Myoclonus-Renal Failure Syndrome", "Epm4", "Myoclonus-Nephropathy Syndrome"],
  },
  {
    ui: "D020192",
    heading: "Lafora Disease",
    tree_numbers: [
      "C10.228.140.490.375.130.650.500",
      "C10.228.140.490.493.063.650.500",
      "C10.574.500.529",
      "C16.320.400.480",
    ],
    synonyms: ["Lafora's Disease", "Myoclonic Epilepsy Of Lafora", "Lafora Progressive Myoclonic Epilepsy"],
  },
  {
    ui: "D020194",
    heading: "Unverricht-Lundborg Syndrome",
    tree_numbers: [
      "C10.228.140.490.375.130.650.900",
      "C10.228.140.490.493.063.650.900",
      "C10.574.500.875",
      "C16.320.400.940",
    ],
    synonyms: ["Unverricht-Lundborg Disease", "Unverricht's Disease", "Unverricht - Lundborg Disease"],
  },
  {
    ui: "D020195",
    heading: "Epilepsy, Reflex",
    tree_numbers: ["C10.228.140.490.450"],
    synonyms: ["Epilepsy, Sensory-Induced"],
  },
  {
    ui: "D020196",
    heading: "Trauma, Nervous System",
    tree_numbers: ["C10.900", "C26.915"],
  },
  {
    ui: "D020197",
    heading: "Head Injuries, Penetrating",
    tree_numbers: ["C10.900.300.675", "C26.915.300.475", "C26.986.500"],
  },
  {
    ui: "D020198",
    heading: "Intracranial Hemorrhage, Traumatic",
    tree_numbers: ["C10.228.140.300.535.450", "C10.900.300.837", "C14.907.253.573.400", "C26.915.300.490"],
  },
  {
    ui: "D020199",
    heading: "Hematoma, Subdural, Acute",
    tree_numbers: [
      "C10.228.140.300.535.450.400.050",
      "C10.900.300.837.600.050",
      "C14.907.253.573.400.450.050",
      "C23.550.414.838.700.100",
      "C23.550.414.913.700.100",
      "C26.915.300.490.450.050",
    ],
  },
  {
    ui: "D020200",
    heading: "Hematoma, Subdural, Chronic",
    tree_numbers: [
      "C10.228.140.300.535.450.400.120",
      "C10.900.300.837.600.120",
      "C14.907.253.573.400.450.120",
      "C23.550.414.838.700.200",
      "C23.550.414.913.700.200",
      "C26.915.300.490.450.120",
    ],
  },
  {
    ui: "D020201",
    heading: "Brain Hemorrhage, Traumatic",
    tree_numbers: [
      "C10.228.140.199.275",
      "C10.228.140.300.535.450.200",
      "C10.900.300.087.187",
      "C10.900.300.837.150",
      "C14.907.253.573.400.150",
      "C26.915.300.200.175",
      "C26.915.300.490.150",
    ],
  },
  {
    ui: "D020202",
    heading: "Cerebral Hemorrhage, Traumatic",
    tree_numbers: [
      "C10.228.140.199.275.300",
      "C10.228.140.300.535.200.200",
      "C10.228.140.300.535.450.200.750",
      "C10.900.300.087.187.300",
      "C10.900.300.837.150.650",
      "C14.907.253.573.200.200",
      "C14.907.253.573.400.150.300",
      "C26.915.300.200.175.300",
      "C26.915.300.490.150.300",
    ],
  },
  {
    ui: "D020203",
    heading: "Brain Stem Hemorrhage, Traumatic",
    tree_numbers: [
      "C10.228.140.199.275.200",
      "C10.228.140.300.535.450.200.500",
      "C10.900.300.087.187.200",
      "C10.900.300.837.150.300",
      "C14.907.253.573.400.150.200",
      "C26.915.300.200.175.200",
      "C26.915.300.490.150.200",
    ],
  },
  {
    ui: "D020204",
    heading: "Skull Fracture, Depressed",
    tree_numbers: ["C10.900.300.918.300", "C26.404.750.890", "C26.915.300.745.350"],
  },
  {
    ui: "D020205",
    heading: "Skull Fracture, Basilar",
    tree_numbers: ["C10.900.300.918.150", "C26.404.750.821", "C26.915.300.745.300"],
  },
  {
    ui: "D020206",
    heading: "Subarachnoid Hemorrhage, Traumatic",
    tree_numbers: [
      "C10.228.140.300.535.450.650",
      "C10.228.140.300.535.800.700",
      "C10.900.300.837.800",
      "C14.907.253.535.600",
      "C14.907.253.573.400.700",
      "C14.907.253.573.800.700",
      "C26.915.300.490.700",
    ],
  },
  {
    ui: "D020207",
    heading: "Coma, Post-Head Injury",
    tree_numbers: ["C10.597.606.358.800.200.200", "C10.900.300.175", "C26.915.300.350"],
  },
  {
    ui: "D020208",
    heading: "Brain Injury, Chronic",
    tree_numbers: ["C10.228.140.140.127", "C10.228.140.199.500", "C10.900.300.087.250", "C26.915.300.200.200"],
  },
  {
    ui: "D020209",
    heading: "Cranial Nerve Injuries",
    tree_numbers: ["C10.292.200", "C10.900.300.218", "C26.915.300.400"],
  },
  {
    ui: "D020210",
    heading: "Central Cord Syndrome",
    tree_numbers: ["C10.228.854.763.500", "C10.900.850.625", "C26.819.339"],
  },
  {
    ui: "D020211",
    heading: "Autonomic Dysreflexia",
    tree_numbers: ["C10.177.090", "C10.900.850.250"],
  },
  {
    ui: "D020212",
    heading: "Carotid Artery Injuries",
    tree_numbers: [
      "C10.228.140.300.200.345",
      "C10.228.140.300.350.500",
      "C10.900.250.300",
      "C14.907.253.123.345",
      "C14.907.253.535.500",
      "C26.915.200.200",
    ],
  },
  {
    ui: "D020214",
    heading: "Cerebrovascular Trauma",
    tree_numbers: ["C10.228.140.300.350", "C10.900.250", "C14.907.253.535", "C26.915.200"],
  },
  {
    ui: "D020215",
    heading: "Carotid Artery, Internal, Dissection",
    tree_numbers: [
      "C10.228.140.300.200.345.300",
      "C10.228.140.300.350.500.300",
      "C10.900.250.300.300",
      "C14.907.055.050.150",
      "C14.907.253.123.345.300",
      "C14.907.253.535.500.300",
      "C26.915.200.200.500",
    ],
  },
  {
    ui: "D020216",
    heading: "Carotid-Cavernous Sinus Fistula",
    tree_numbers: [
      "C10.228.140.300.200.480",
      "C10.228.140.300.350.500.350",
      "C10.900.250.300.400",
      "C14.240.850.750.147.500",
      "C14.240.850.984.750.500",
      "C14.907.150.125.500",
      "C14.907.253.123.357",
      "C14.907.253.535.500.350",
      "C14.907.933.555.500",
      "C16.131.240.850.750.125.500",
      "C23.300.575.950.250.500",
      "C26.915.200.200.550",
    ],
  },
  {
    ui: "D020217",
    heading: "Vertebral Artery Dissection",
    tree_numbers: [
      "C10.228.140.300.350.875",
      "C10.900.250.650",
      "C14.907.055.050.575",
      "C14.907.253.535.800",
      "C26.915.200.600",
    ],
  },
  {
    ui: "D020220",
    heading: "Facial Nerve Injuries",
    tree_numbers: [
      "C07.465.299.500",
      "C10.292.200.500",
      "C10.292.319.500",
      "C10.900.300.218.300",
      "C26.915.300.400.300",
    ],
  },
  {
    ui: "D020221",
    heading: "Optic Nerve Injuries",
    tree_numbers: ["C10.292.200.750", "C10.292.700.475", "C10.900.300.218.550", "C11.640.530", "C26.915.300.400.650"],
  },
  {
    ui: "D020222",
    heading: "Abducens Nerve Injury",
    tree_numbers: ["C10.292.150.100", "C10.292.200.200", "C10.900.300.218.150", "C26.915.300.400.100"],
  },
  {
    ui: "D020225",
    heading: "Sagittal Sinus Thrombosis",
    tree_numbers: ["C10.228.140.300.525.425.500.750", "C14.907.253.566.350.500.750", "C14.907.355.590.213.350.500.750"],
    synonyms: ["Thrombosis Of Superior Longitudinal Sinus", "Thrombosis Of Superior Sagittal Sinus"],
  },
  {
    ui: "D020226",
    heading: "Cavernous Sinus Thrombosis",
    tree_numbers: ["C10.228.140.300.525.425.500.375", "C14.907.253.566.350.500.375", "C14.907.355.590.213.350.500.375"],
    synonyms: ["Thrombosis Of Cavernous Venous Sinus"],
  },
  {
    ui: "D020227",
    heading: "Lateral Sinus Thrombosis",
    tree_numbers: ["C10.228.140.300.525.425.500.562", "C14.907.253.566.350.500.562", "C14.907.355.590.213.350.500.562"],
    synonyms: ["Thrombosis Transverse Sinus", "Thrombosis Of Lateral Venous Sinus"],
  },
  {
    ui: "D020230",
    heading: "Serotonin Syndrome",
    tree_numbers: ["C25.100.875"],
  },
  {
    ui: "D020232",
    heading: "Kluver-Bucy Syndrome",
    tree_numbers: ["C10.228.140.380.326", "F03.615.400.431"],
    synonyms: ["Klver-Bucy Syndrome"],
  },
  {
    ui: "D020233",
    heading: "Gait Disorders, Neurologic",
    tree_numbers: ["C10.597.404", "C23.888.592.413"],
  },
  {
    ui: "D020234",
    heading: "Gait Ataxia",
    tree_numbers: ["C10.597.350.090.750", "C10.597.404.450", "C23.888.592.350.090.600", "C23.888.592.413.450"],
  },
  {
    ui: "D020235",
    heading: "Gait Apraxia",
    tree_numbers: [
      "C10.597.404.400",
      "C10.597.606.881.350.600",
      "C23.888.592.413.400",
      "C23.888.592.604.882.350.600",
      "F01.700.875.350.600",
    ],
  },
  {
    ui: "D020236",
    heading: "Amnesia, Transient Global",
    tree_numbers: [
      "C10.228.140.060",
      "C10.597.606.525.100.800",
      "C23.888.592.604.529.100.800",
      "F01.700.625.100.800",
      "F03.615.200.800",
    ],
  },
  {
    ui: "D020237",
    heading: "Alexia, Pure",
    tree_numbers: ["C10.597.606.150.500.300.200.100", "C23.888.592.604.150.500.300.200.100", "F03.615.700.500"],
  },
  {
    ui: "D020238",
    heading: "Prosopagnosia",
    tree_numbers: ["C10.597.606.762.100.650", "C23.888.592.604.764.100.650", "F01.700.750.100.650"],
  },
  {
    ui: "D020240",
    heading: "Apraxia, Ideomotor",
    tree_numbers: ["C10.597.606.881.350.200", "C23.888.592.604.882.350.200", "F01.700.875.350.200"],
    synonyms: ["Ideomotor Dyspraxia", "Transcortical Apraxia", "Classic Apraxia", "Limb-Kinetic Apraxia"],
  },
  {
    ui: "D020241",
    heading: "Encephalomyelitis, Western Equine",
    tree_numbers: [
      "C01.207.245.340.450.800",
      "C01.207.291.323.662",
      "C01.207.399.750.450.800",
      "C01.920.500.078.370.662",
      "C01.920.500.343.655.677",
      "C01.925.081.343.655.677",
      "C01.925.182.525.450.300",
      "C01.925.782.930.100.370.662",
      "C10.228.140.430.520.750.450.800",
      "C10.228.228.245.340.450.800",
      "C10.228.228.291.323.662",
      "C10.228.228.399.750.450.800",
      "C10.228.440.406.250",
    ],
    synonyms: [],
  },
  {
    ui: "D020242",
    heading: "Encephalomyelitis, Eastern Equine",
    tree_numbers: [
      "C01.207.245.340.450.200",
      "C01.207.291.323.162",
      "C01.207.399.750.450.200",
      "C01.920.500.078.370.162",
      "C01.920.500.343.655.177",
      "C01.925.081.343.655.177",
      "C01.925.782.930.100.370.162",
      "C10.228.140.430.520.750.450.200",
      "C10.228.228.245.340.450.200",
      "C10.228.228.291.323.162",
      "C10.228.228.399.750.450.200",
      "C10.228.440.406.200",
    ],
    synonyms: ["Neuroinvasive Eastern Equine Encephalitis Virus Infection"],
  },
  {
    ui: "D020243",
    heading: "Infarction, Anterior Cerebral Artery",
    tree_numbers: [
      "C10.228.140.300.150.477.200.400",
      "C10.228.140.300.510.200.325",
      "C10.228.140.300.775.200.200.400",
      "C14.907.253.092.477.200.400",
      "C14.907.253.560.200.325",
      "C14.907.253.855.200.200.400",
      "C23.550.513.355.250.200.400",
      "C23.550.717.489.250.200.400",
    ],
  },
  {
    ui: "D020244",
    heading: "Infarction, Middle Cerebral Artery",
    tree_numbers: [
      "C10.228.140.300.150.477.200.450",
      "C10.228.140.300.510.200.387",
      "C10.228.140.300.775.200.200.450",
      "C14.907.253.092.477.200.450",
      "C14.907.253.560.200.387",
      "C14.907.253.855.200.200.450",
      "C23.550.513.355.250.200.450",
      "C23.550.717.489.250.200.450",
    ],
  },
  {
    ui: "D020246",
    heading: "Venous Thrombosis",
    tree_numbers: ["C14.907.355.830.925"],
  },
  {
    ui: "D020250",
    heading: "Postoperative Nausea and Vomiting",
    tree_numbers: ["C23.550.767.859", "C23.888.821.712.700", "C23.888.821.937.059"],
  },
  {
    ui: "D020252",
    heading: "Gastric Antral Vascular Ectasia",
    tree_numbers: ["C06.405.748.280", "C14.907.075.280"],
    synonyms: ["Watermelon Stomach"],
  },
  {
    ui: "D020254",
    heading: "Tooth Ankylosis",
    tree_numbers: ["C07.793.710"],
    synonyms: ["Ankylosis Of Teeth", "Ankylosis Of Tooth"],
  },
  {
    ui: "D020255",
    heading: "Vitreous Detachment",
    tree_numbers: ["C11.980"],
  },
  {
    ui: "D020256",
    heading: "Choroidal Neovascularization",
    tree_numbers: ["C11.941.160.244", "C23.550.589.500.145"],
  },
  {
    ui: "D020257",
    heading: "Ventricular Remodeling",
    tree_numbers: ["C23.300.985", "G09.330.955.975"],
  },
  {
    ui: "D020258",
    heading: "Neurotoxicity Syndromes",
    tree_numbers: ["C10.720", "C25.723.705"],
    synonyms: ["Neurotoxicity", "Neurotoxicity Syndrome"],
  },
  {
    ui: "D020260",
    heading: "Heavy Metal Poisoning, Nervous System",
    tree_numbers: ["C10.720.475"],
  },
  {
    ui: "D020261",
    heading: "Arsenic Poisoning",
    tree_numbers: ["C10.720.475.150", "C25.723.098"],
  },
  {
    ui: "D020262",
    heading: "Mercury Poisoning, Nervous System",
    tree_numbers: ["C10.720.475.600", "C25.723.522.875.500"],
  },
  {
    ui: "D020263",
    heading: "Lead Poisoning, Nervous System",
    tree_numbers: ["C10.720.475.400", "C25.723.522.750.500"],
  },
  {
    ui: "D020264",
    heading: "Lead Poisoning, Nervous System, Childhood",
    tree_numbers: ["C10.720.475.400.700", "C25.723.522.750.500.700"],
  },
  {
    ui: "D020265",
    heading: "Lead Poisoning, Nervous System, Adult",
    tree_numbers: ["C10.720.475.400.350", "C25.723.522.750.500.400"],
  },
  {
    ui: "D020267",
    heading: "MPTP Poisoning",
    tree_numbers: ["C10.228.140.079.862.800.300", "C10.228.662.600.700.250", "C10.720.606", "C25.723.705.400"],
  },
  {
    ui: "D020268",
    heading: "Alcohol-Induced Disorders, Nervous System",
    tree_numbers: ["C10.720.112", "C25.723.705.150", "C25.775.100.087.193"],
  },
  {
    ui: "D020269",
    heading: "Alcoholic Neuropathy",
    tree_numbers: ["C10.668.829.800.050", "C10.720.112.400", "C25.723.705.150.400", "C25.775.100.087.193.400"],
    synonyms: ["Alcoholic Polyneuropathy", "Alcohol-Related Polyneuropathy"],
  },
  {
    ui: "D020270",
    heading: "Alcohol Withdrawal Seizures",
    tree_numbers: [
      "C10.597.742.143",
      "C10.720.112.300",
      "C23.888.592.742.143",
      "C25.723.705.150.300",
      "C25.775.100.087.193.300",
      "C25.775.835.500",
    ],
  },
  {
    ui: "D020271",
    heading: "Heredodegenerative Disorders, Nervous System",
    tree_numbers: ["C10.574.500", "C16.320.400"],
  },
  {
    ui: "D020274",
    heading: "Autoimmune Diseases of the Nervous System",
    tree_numbers: ["C10.114", "C20.111.258"],
    synonyms: [],
  },
  {
    ui: "D020275",
    heading: "Guillain-Barre Syndrome",
    tree_numbers: [
      "C10.114.750.100",
      "C10.314.750.450",
      "C10.668.829.350",
      "C10.668.829.800.750.300",
      "C20.111.258.750.400",
    ],
    synonyms: [
      "Postinfectious Polyneuritis",
      "Acute Postinfectious Polyneuropathy",
      "Post-Infectious Polyneuritis",
      "Infectious Neuronitis",
      "Acute Infective Polyneuritis",
      "Acute Inflammatory Demyelinating Polyradiculopathy",
    ],
  },
  {
    ui: "D020277",
    heading: "Polyradiculoneuropathy, Chronic Inflammatory Demyelinating",
    tree_numbers: ["C10.114.750.175", "C10.314.750.700", "C10.668.829.800.750.600", "C20.111.258.750.800"],
  },
  {
    ui: "D020278",
    heading: "Demyelinating Autoimmune Diseases, CNS",
    tree_numbers: ["C10.114.375", "C10.228.140.695.562", "C10.314.350", "C20.111.258.250"],
  },
  {
    ui: "D020279",
    heading: "Hereditary Central Nervous System Demyelinating Diseases",
    tree_numbers: [
      "C10.228.140.163.100.362",
      "C10.228.140.695.625",
      "C10.314.400",
      "C10.574.500.490",
      "C16.320.400.367",
      "C16.320.565.189.362",
      "C18.452.132.100.362",
      "C18.452.648.189.362",
    ],
  },
  {
    ui: "D020288",
    heading: "Papilloma, Choroid Plexus",
    tree_numbers: ["C04.588.614.250.195.205.200.500", "C10.228.140.211.280.300.500", "C10.551.240.250.200.200.500"],
    synonyms: ["Childhood Choroid Plexus Papilloma"],
  },
  {
    ui: "D020293",
    heading: "Vasculitis, Central Nervous System",
    tree_numbers: ["C10.114.875", "C10.228.140.300.850", "C14.907.253.946", "C14.907.940.907", "C20.111.258.962"],
  },
  {
    ui: "D020294",
    heading: "Myasthenic Syndromes, Congenital",
    tree_numbers: ["C10.668.758.800", "C16.320.590"],
  },
  {
    ui: "D020295",
    heading: "Brain Stem Neoplasms",
    tree_numbers: ["C04.588.614.250.195.411.100", "C10.228.140.211.500.100", "C10.551.240.250.400.200"],
    synonyms: [
      "Primary Brain Stem Neoplasm",
      "Malignant Neoplasm Of Brainstem",
      "Neoplasm Of Adult Brain Stem",
      "Malignant Neoplasm Of Brain Stem",
      "Primary Brain Stem Tumor",
      "Neoplasm Of Brain Stem",
    ],
  },
  {
    ui: "D020299",
    heading: "Intracranial Hemorrhage, Hypertensive",
    tree_numbers: ["C10.228.140.300.535.325", "C14.907.253.573.350"],
  },
  {
    ui: "D020300",
    heading: "Intracranial Hemorrhages",
    tree_numbers: ["C10.228.140.300.535", "C14.907.253.573", "C23.550.414.913"],
  },
  {
    ui: "D020301",
    heading: "Vasospasm, Intracranial",
    tree_numbers: ["C10.228.140.300.900", "C14.907.253.951"],
  },
  {
    ui: "D020306",
    heading: "Tuberculosis, Central Nervous System",
    tree_numbers: ["C01.150.252.223.850", "C01.150.252.410.040.552.846.570", "C01.207.180.850", "C10.228.228.180.850"],
  },
  {
    ui: "D020314",
    heading: "Central Nervous System Fungal Infections",
    tree_numbers: ["C01.150.703.181", "C01.207.198", "C10.228.228.198"],
  },
  {
    ui: "D020315",
    heading: "Latex Hypersensitivity",
    tree_numbers: ["C20.543.600"],
  },
  {
    ui: "D020323",
    heading: "Tics",
    tree_numbers: ["C10.597.350.700", "C23.888.592.350.700"],
  },
  {
    ui: "D020324",
    heading: "Amnesia, Anterograde",
    tree_numbers: ["C10.597.606.525.100.075", "C23.888.592.604.529.100.075", "F01.700.625.100.075", "F03.615.200.137"],
  },
  {
    ui: "D020325",
    heading: "Migraine with Aura",
    tree_numbers: ["C10.228.140.546.399.750.250"],
    synonyms: ["Classic Migraine"],
  },
  {
    ui: "D020326",
    heading: "Migraine without Aura",
    tree_numbers: ["C10.228.140.546.399.750.450"],
    synonyms: ["Common Migraine"],
  },
  {
    ui: "D020329",
    heading: "Essential Tremor",
    tree_numbers: ["C10.228.662.350"],
    synonyms: ["Shaky Hand Syndrome", "Benign Essential Tremor", "Essential Hereditary Tremor"],
  },
  {
    ui: "D020330",
    heading: "Bell Palsy",
    tree_numbers: ["C01.925.256.466.087", "C07.465.094", "C07.465.299.250", "C10.292.319.250"],
    synonyms: ["Bell's (Facial) Palsy", "Bell Palsy"],
  },
  {
    ui: "D020331",
    heading: "Mobius Syndrome",
    tree_numbers: ["C07.465.299.825", "C10.292.319.825", "C16.131.077.578", "C16.614.595"],
    synonyms: ["Oromandibular-Limb Hypogenesis Spectrum", "Moebius Congenital Oculofacial Paralysis"],
  },
  {
    ui: "D020333",
    heading: "Tolosa-Hunt Syndrome",
    tree_numbers: ["C10.292.562.900", "C11.590.905"],
    synonyms: ["Tolosa-Hunt Syndrome"],
  },
  {
    ui: "D020335",
    heading: "Paraparesis",
    tree_numbers: ["C10.597.636.500", "C23.888.592.643.500"],
  },
  {
    ui: "D020336",
    heading: "Paraparesis, Spastic",
    tree_numbers: ["C10.597.636.500.500", "C23.888.592.643.500.500"],
  },
  {
    ui: "D020338",
    heading: "Vestibular Neuronitis",
    tree_numbers: ["C09.218.807.800.837", "C10.292.910.850"],
    synonyms: ["Epidemic Neurolabyrinthitis", "Vestibular Neuritis"],
  },
  {
    ui: "D020339",
    heading: "Optic Nerve Glioma",
    tree_numbers: [
      "C04.557.465.625.600.380.795",
      "C04.557.470.670.380.795",
      "C04.557.580.625.600.380.795",
      "C04.588.614.300.600.600",
      "C04.588.614.596.240.240.500",
      "C10.292.225.800.500",
      "C10.292.700.500.500",
      "C10.551.360.500.500",
      "C10.551.775.250.500.500",
      "C11.640.544.500",
    ],
    synonyms: ["Glioma Of The Optic Nerve"],
  },
  {
    ui: "D020343",
    heading: "Hypertensive Encephalopathy",
    tree_numbers: ["C10.228.140.631.500"],
  },
  {
    ui: "D020345",
    heading: "Enterocolitis, Necrotizing",
    tree_numbers: ["C06.405.205.596.700", "C06.405.469.363.700"],
    synonyms: [
      "Enterocolitis Necrotizing",
      "Necrotizing Enterocolitis",
      "Perinatal Necrotizing Enterocolitis",
      "Necrotizing Enterocolitis In Fetus Or Newborn",
      "Pseudomembranous Enterocolitis In Newborn",
      "Perinatal Necrotising Enterocolitis",
    ],
  },
  {
    ui: "D020347",
    heading: "Lithiasis",
    tree_numbers: ["C23.550.537"],
  },
  {
    ui: "D020361",
    heading: "Paraneoplastic Syndromes, Nervous System",
    tree_numbers: ["C04.588.614.550", "C04.730.856", "C10.574.781"],
  },
  {
    ui: "D020362",
    heading: "Paraneoplastic Cerebellar Degeneration",
    tree_numbers: ["C04.588.614.550.650", "C04.730.856.650", "C10.228.140.252.620", "C10.574.781.700"],
  },
  {
    ui: "D020363",
    heading: "Limbic Encephalitis",
    tree_numbers: [
      "C01.207.245.700",
      "C04.588.614.550.450",
      "C04.730.856.437",
      "C10.228.140.430.525",
      "C10.228.228.245.700",
      "C10.574.781.550",
    ],
  },
  {
    ui: "D020364",
    heading: "Paraneoplastic Polyneuropathy",
    tree_numbers: ["C04.588.614.550.700", "C04.730.856.700", "C10.574.781.850", "C10.668.829.800.662"],
  },
  {
    ui: "D020370",
    heading: "Osteoarthritis, Knee",
    tree_numbers: ["C05.550.114.606.500", "C05.799.613.500"],
  },
  {
    ui: "D020371",
    heading: "Pelizaeus-Merzbacher Disease",
    tree_numbers: [
      "C10.228.140.163.100.362.775",
      "C10.228.140.695.625.775",
      "C10.314.400.775",
      "C16.320.322.906",
      "C16.320.565.189.362.775",
      "C18.452.132.100.362.775",
      "C18.452.648.189.362.775",
    ],
    synonyms: [
      "Hypomyelinating Leukodystrophy 1",
      "Pelizaeus Merzbacher Brain Sclerosis",
      "Pmd",
      "Leukodystrophy, Sudanophilic",
      "Diffuse Familial Brain Sclerosis",
      "Hld1",
      "Pelizaeus-Merzbacher Brain Sclerosis",
      "Sudanophilic Leukodystrophy, Paelizeus-Merzbacher Type",
    ],
  },
  {
    ui: "D020385",
    heading: "Myokymia",
    tree_numbers: ["C10.597.613.650", "C23.888.592.608.650"],
  },
  {
    ui: "D020386",
    heading: "Isaacs Syndrome",
    tree_numbers: ["C05.651.392", "C10.668.829.425"],
  },
  {
    ui: "D020388",
    heading: "Muscular Dystrophy, Duchenne",
    tree_numbers: ["C05.651.534.500.300", "C10.668.491.175.500.300", "C16.320.322.562", "C16.320.577.300"],
    synonyms: ["Muscular Dystrophy, Duchenne"],
  },
  {
    ui: "D020389",
    heading: "Muscular Dystrophy, Emery-Dreifuss",
    tree_numbers: ["C05.651.534.500.350", "C10.668.491.175.500.350", "C16.320.322.625", "C16.320.577.350"],
    synonyms: ["Edmd"],
  },
  {
    ui: "D020391",
    heading: "Muscular Dystrophy, Facioscapulohumeral",
    tree_numbers: ["C05.651.534.500.400", "C10.668.491.175.500.400", "C16.320.577.400"],
    synonyms: [
      "Landouzy-Dejerine Muscular Dystrophy",
      "Muscular Dystrophy, Landouzy-Dejerine",
      "Landouzy Dejerine Muscular Dystrophy",
    ],
  },
  {
    ui: "D020417",
    heading: "Nystagmus, Congenital",
    tree_numbers: ["C10.292.562.675.300", "C11.590.400.300", "C16.614.643"],
  },
  {
    ui: "D020421",
    heading: "Vagus Nerve Diseases",
    tree_numbers: ["C10.292.887"],
    synonyms: [
      "Disorder Of Vagus Nerve",
      "Disorder Of Pneumogastric [10th] Nerve",
      "Vagus Nerve Disorder",
      "Disorder Of Vagal Nerve",
    ],
  },
  {
    ui: "D020422",
    heading: "Mononeuropathies",
    tree_numbers: ["C10.668.829.500"],
    synonyms: ["Mononeuritis Multiplex"],
  },
  {
    ui: "D020423",
    heading: "Median Neuropathy",
    tree_numbers: ["C10.668.829.500.500"],
  },
  {
    ui: "D020424",
    heading: "Ulnar Neuropathies",
    tree_numbers: ["C10.668.829.500.850"],
    synonyms: ["Ulnar Neuropathy"],
  },
  {
    ui: "D020425",
    heading: "Radial Neuropathy",
    tree_numbers: ["C10.668.829.500.650"],
    synonyms: ["Radial Neuropathy"],
  },
  {
    ui: "D020426",
    heading: "Sciatic Neuropathy",
    tree_numbers: ["C10.668.829.500.675"],
  },
  {
    ui: "D020427",
    heading: "Peroneal Neuropathies",
    tree_numbers: ["C10.668.829.500.600"],
    synonyms: ["Peroneal Neuropathy"],
  },
  {
    ui: "D020428",
    heading: "Femoral Neuropathy",
    tree_numbers: ["C10.668.829.500.200"],
    synonyms: ["Femoral Nerve Dysfunction", "Femoral Nerve Lesions"],
  },
  {
    ui: "D020429",
    heading: "Tibial Neuropathy",
    tree_numbers: ["C10.668.829.500.700"],
    synonyms: ["Posterior Tibial Neuropathy", "Tibial Neuropathy"],
  },
  {
    ui: "D020430",
    heading: "Cubital Tunnel Syndrome",
    tree_numbers: ["C10.668.829.500.850.200", "C10.668.829.550.925.200", "C26.844.150.957.200"],
  },
  {
    ui: "D020431",
    heading: "Olfactory Nerve Diseases",
    tree_numbers: ["C10.292.650"],
    synonyms: ["Disorder Of Olfactory [1st] Nerve", "Disorder Of 1st Nerve", "Disorder Of Olfactory Nerve"],
  },
  {
    ui: "D020432",
    heading: "Trochlear Nerve Diseases",
    tree_numbers: ["C10.292.850"],
    synonyms: [
      "Trochlear Nerve Disorder",
      "Disorder Of Trochlear Nerve",
      "Superior Oblique Muscle Innervation Disorder",
    ],
  },
  {
    ui: "D020433",
    heading: "Trigeminal Nerve Diseases",
    tree_numbers: ["C07.465.299.625.500", "C10.292.319.625.700"],
  },
  {
    ui: "D020434",
    heading: "Abducens Nerve Diseases",
    tree_numbers: ["C10.292.150"],
    synonyms: [
      "Vith Nerve Paralysis",
      "Lateral Rectus Muscle Innervation Disorder",
      "Sixth Nerve Palsy",
      "Lateral Rectus Muscle Denervation Paresis",
      "Sixth Cranial Nerve Disorder",
      "Abducens Nerve Weakness",
      "Vith Nerve Disorder",
      "Sixth Or Abducens Nerve Palsy",
    ],
  },
  {
    ui: "D020435",
    heading: "Glossopharyngeal Nerve Diseases",
    tree_numbers: ["C10.292.450"],
    synonyms: ["Glossopharyngeal Neuralgia"],
  },
  {
    ui: "D020436",
    heading: "Accessory Nerve Diseases",
    tree_numbers: ["C10.292.175"],
    synonyms: [
      "Disorder Of 11th Nerve",
      "Disorder Of Accessory [11th] Nerve",
      "Disorder Of Accessory Nerve",
      "Eleventh Nerve Disorder",
    ],
  },
  {
    ui: "D020437",
    heading: "Hypoglossal Nerve Diseases",
    tree_numbers: ["C10.292.525"],
    synonyms: [
      "Disorder Of Xii Nerve",
      "Disorder Of 12th Nerve",
      "Disorder Of Hypoglossal [12th] Nerve",
      "Disorder Of Hypoglossal Nerve",
    ],
  },
  {
    ui: "D020447",
    heading: "Parasomnias",
    tree_numbers: ["C10.886.659", "F03.870.664"],
  },
  {
    ui: "D020511",
    heading: "Neuromuscular Junction Diseases",
    tree_numbers: ["C10.668.758"],
  },
  {
    ui: "D020512",
    heading: "Myopathy, Central Core",
    tree_numbers: ["C05.651.575.300", "C10.668.491.550.300"],
    synonyms: ["Central Core Myopathy"],
  },
  {
    ui: "D020513",
    heading: "Paralysis, Hyperkalemic Periodic",
    tree_numbers: ["C05.651.701.600", "C10.668.491.650.600", "C16.320.565.618.711.600", "C18.452.648.618.711.600"],
    synonyms: ["Familial Hyperkalemic Periodic Paralysis"],
  },
  {
    ui: "D020514",
    heading: "Hypokalemic Periodic Paralysis",
    tree_numbers: ["C05.651.701.450", "C10.668.491.650.450", "C16.320.565.618.711.550", "C18.452.648.618.711.550"],
    synonyms: [
      "Periodic Hypokalemic Paralysis",
      "Hypokalemic Periodic Paralysis",
      "Westphal Disease",
      "Familial Periodic Paralysis",
      "Familial Hypokalemic Periodic Paralysis",
      "Periodic Paralysis I",
      "Hypokalemic Familial Periodic Paralysis",
    ],
  },
  {
    ui: "D020516",
    heading: "Brachial Plexus Neuropathies",
    tree_numbers: ["C10.668.829.100"],
    synonyms: ["Brachial Plexopathy", "Brachial Plexus Disorder"],
  },
  {
    ui: "D020518",
    heading: "Focal Nodular Hyperplasia",
    tree_numbers: ["C06.552.270"],
  },
  {
    ui: "D020520",
    heading: "Brain Infarction",
    tree_numbers: [
      "C10.228.140.300.150.477",
      "C10.228.140.300.775.200",
      "C14.907.253.092.477",
      "C14.907.253.855.200",
      "C23.550.513.355.250",
      "C23.550.717.489.250",
    ],
  },
  {
    ui: "D020521",
    heading: "Stroke",
    tree_numbers: ["C10.228.140.300.775", "C14.907.253.855"],
  },
  {
    ui: "D020522",
    heading: "Lymphoma, Mantle-Cell",
    tree_numbers: ["C04.557.386.480.525", "C15.604.515.569.480.525", "C20.683.515.761.480.525"],
  },
  {
    ui: "D020526",
    heading: "Brain Stem Infarctions",
    tree_numbers: [
      "C10.228.140.300.150.477.100",
      "C10.228.140.300.775.200.100",
      "C14.907.253.092.477.100",
      "C14.907.253.855.200.100",
      "C23.550.513.355.250.100",
      "C23.550.717.489.250.100",
    ],
    synonyms: ["Brainstem Infarction"],
  },
  {
    ui: "D020528",
    heading: "Multiple Sclerosis, Chronic Progressive",
    tree_numbers: ["C10.114.375.500.200", "C10.314.350.500.200", "C20.111.258.250.500.200"],
    synonyms: ["Secondary-Progressive Ms", "Spms"],
  },
  {
    ui: "D020529",
    heading: "Multiple Sclerosis, Relapsing-Remitting",
    tree_numbers: ["C10.114.375.500.600", "C10.314.350.500.600", "C20.111.258.250.500.600"],
    synonyms: ["Rrms", "Relapsing-Remitting Ms"],
  },
  {
    ui: "D020567",
    heading: "Fetal Weight",
    tree_numbers: [
      "C23.888.144.300",
      "E01.370.600.115.100.160.120.300",
      "E05.041.124.160.750.300",
      "G07.100.100.160.120.300",
      "G07.345.249.314.120.300",
      "G07.345.500.325.235.937",
      "G08.686.280",
    ],
  },
  {
    ui: "D020642",
    heading: "Acatalasia",
    tree_numbers: ["C16.320.565.663.050", "C18.452.648.663.050"],
    synonyms: ["Deficiency Of Catalase"],
  },
  {
    ui: "D020720",
    heading: "Myasthenia Gravis, Autoimmune, Experimental",
    tree_numbers: [
      "C10.114.656.300",
      "C10.114.703.350",
      "C10.668.758.725.300",
      "C20.111.258.500.300",
      "C20.111.258.625.350",
      "E05.598.500.500.750",
    ],
  },
  {
    ui: "D020721",
    heading: "Nervous System Autoimmune Disease, Experimental",
    tree_numbers: ["C10.114.703", "C20.111.258.625", "E05.598.500.500"],
  },
  {
    ui: "D020734",
    heading: "Parkinsonian Disorders",
    tree_numbers: ["C10.228.140.079.862", "C10.228.662.600"],
  },
  {
    ui: "D020739",
    heading: "Brain Diseases, Metabolic, Inborn",
    tree_numbers: ["C10.228.140.163.100", "C16.320.565.189", "C18.452.132.100", "C18.452.648.189"],
  },
  {
    ui: "D020751",
    heading: "Alcohol-Induced Disorders",
    tree_numbers: ["C25.775.100.087"],
  },
  {
    ui: "D020752",
    heading: "Neurocutaneous Syndromes",
    tree_numbers: [
      "C10.562",
      "C16.131.077.350.712",
      "C16.131.831.350.712",
      "C16.320.850.250.712",
      "C17.800.804.350.712",
      "C17.800.827.250.712",
    ],
  },
  {
    ui: "D020754",
    heading: "Spinocerebellar Ataxias",
    tree_numbers: [
      "C10.228.140.252.190.530",
      "C10.228.140.252.700.700",
      "C10.228.854.787.875",
      "C10.574.500.825.700",
      "C10.597.350.090.500.530",
      "C16.320.400.780.875",
    ],
    synonyms: ["Spinocerebellar Ataxia"],
  },
  {
    ui: "D020757",
    heading: "Amaurosis Fugax",
    tree_numbers: ["C10.597.751.941.162.125", "C11.966.075.125", "C23.888.592.763.941.162.125"],
  },
  {
    ui: "D020758",
    heading: "Spinal Cord Vascular Diseases",
    tree_numbers: ["C10.228.854.785", "C14.907.790"],
    synonyms: ["Vascular Tissue Disease"],
  },
  {
    ui: "D020759",
    heading: "Anterior Spinal Artery Syndrome",
    tree_numbers: ["C10.228.854.785.650.100", "C14.907.790.550.100"],
    synonyms: ["Anterior Spinal Artery Occlusion Syndrome"],
  },
  {
    ui: "D020760",
    heading: "Spinal Cord Ischemia",
    tree_numbers: ["C10.228.854.785.650", "C14.907.790.550"],
    synonyms: ["Vascular Tissue Disease"],
  },
  {
    ui: "D020762",
    heading: "Infarction, Posterior Cerebral Artery",
    tree_numbers: [
      "C10.228.140.300.150.477.200.475",
      "C10.228.140.300.510.200.418",
      "C10.228.140.300.775.200.200.475",
      "C14.907.253.092.477.200.475",
      "C14.907.253.560.200.418",
      "C14.907.253.855.200.200.475",
      "C23.550.513.355.250.200.475",
      "C23.550.717.489.250.200.475",
    ],
  },
  {
    ui: "D020763",
    heading: "Pathological Conditions, Anatomical",
    tree_numbers: ["C23.300"],
  },
  {
    ui: "D020765",
    heading: "Intracranial Arterial Diseases",
    tree_numbers: ["C10.228.140.300.510", "C14.907.253.560"],
  },
  {
    ui: "D020766",
    heading: "Intracranial Embolism",
    tree_numbers: ["C10.228.140.300.525.400", "C14.907.253.566.300", "C14.907.355.590.213.300"],
    synonyms: ["Cerebral Embolism", "Cerebral Embolism With Cerebral Infarction"],
  },
  {
    ui: "D020767",
    heading: "Intracranial Thrombosis",
    tree_numbers: ["C10.228.140.300.525.425", "C14.907.253.566.350", "C14.907.355.590.213.350"],
    synonyms: ["Cerebral Thrombosis"],
  },
  {
    ui: "D020773",
    heading: "Headache Disorders",
    tree_numbers: ["C10.228.140.546"],
  },
  {
    ui: "D020774",
    heading: "Pick Disease of the Brain",
    tree_numbers: ["C10.228.140.380.266.299.500", "F03.615.400.380.299.500"],
    synonyms: ["Dementia In Pick's Disease", "Pick Disease", "Pick Disease Of Brain", "Lobar Atrophy Of Brain"],
  },
  {
    ui: "D020779",
    heading: "Sinus Pericranii",
    tree_numbers: ["C10.500.190.800", "C14.240.850.875.750", "C16.131.666.190.800"],
  },
  {
    ui: "D020785",
    heading: "Central Nervous System Vascular Malformations",
    tree_numbers: ["C10.500.190", "C14.240.850.875", "C16.131.240.850.875", "C16.131.666.190"],
  },
  {
    ui: "D020786",
    heading: "Hemangioma, Cavernous, Central Nervous System",
    tree_numbers: [
      "C04.557.645.375.385.500",
      "C10.500.190.200",
      "C14.240.850.875.249",
      "C14.907.454.385.500",
      "C15.378.463.515.385.500",
      "C16.131.666.190.200",
    ],
  },
  {
    ui: "D020787",
    heading: "Central Nervous System Venous Angioma",
    tree_numbers: ["C04.557.645.375.185", "C10.500.190.100", "C14.240.850.875.124", "C16.131.666.190.100"],
  },
  {
    ui: "D020788",
    heading: "Bardet-Biedl Syndrome",
    tree_numbers: ["C10.228.140.617.200", "C11.270.684.624", "C16.131.077.245.125", "C16.320.184.125"],
  },
  {
    ui: "D020790",
    heading: "Diabetes Insipidus, Neurogenic",
    tree_numbers: ["C12.777.419.135.750", "C13.351.968.419.135.750", "C19.700.159.750"],
    synonyms: [
      "Pituitary Diabetes Insipidus",
      "Vasopressin Defective Diabetes Insipidus",
      "Central Diabetes Insipidus",
      "Vasopressin Deficiency",
    ],
  },
  {
    ui: "D020792",
    heading: "Salivary Calculi",
    tree_numbers: ["C07.465.815.497", "C23.300.175.700"],
  },
  {
    ui: "D020795",
    heading: "Photophobia",
    tree_numbers: ["C10.597.751.941.661", "C11.966.741", "C23.888.592.763.941.661"],
  },
  {
    ui: "D020802",
    heading: "Epidural Abscess",
    tree_numbers: [
      "C01.207.300",
      "C01.207.709.350",
      "C01.830.025.325",
      "C10.228.228.300",
      "C10.228.228.709.350",
      "C10.228.854.303",
    ],
    synonyms: ["Abscess Epidural"],
  },
  {
    ui: "D020803",
    heading: "Encephalitis, Herpes Simplex",
    tree_numbers: [
      "C01.207.245.340.350",
      "C01.207.399.750.350",
      "C01.925.182.525.350",
      "C01.925.256.466.262",
      "C10.228.140.430.520.750.350",
      "C10.228.228.245.340.350",
      "C10.228.228.399.750.350",
    ],
  },
  {
    ui: "D020804",
    heading: "Encephalitis, Varicella Zoster",
    tree_numbers: [
      "C01.207.245.340.400",
      "C01.207.399.750.400",
      "C01.925.182.525.400",
      "C01.925.256.466.930.500",
      "C10.228.140.430.520.750.400",
      "C10.228.228.245.340.400",
      "C10.228.228.399.750.400",
    ],
  },
  {
    ui: "D020805",
    heading: "Central Nervous System Viral Diseases",
    tree_numbers: ["C01.207.245", "C01.925.182", "C10.228.228.245"],
  },
  {
    ui: "D020806",
    heading: "Central Nervous System Bacterial Infections",
    tree_numbers: ["C01.150.252.223", "C01.207.180", "C10.228.228.180"],
  },
  {
    ui: "D020807",
    heading: "Central Nervous System Parasitic Infections",
    tree_numbers: ["C01.207.205", "C01.610.105", "C10.228.228.205"],
  },
  {
    ui: "D020808",
    heading: "Central Nervous System Protozoal Infections",
    tree_numbers: ["C01.207.205.300", "C01.610.105.300", "C01.610.752.100", "C10.228.228.205.300"],
  },
  {
    ui: "D020809",
    heading: "Central Nervous System Helminthiasis",
    tree_numbers: ["C01.207.205.250", "C01.610.105.250", "C10.228.228.205.250"],
  },
  {
    ui: "D020814",
    heading: "Meningitis, Escherichia coli",
    tree_numbers: [
      "C01.150.252.223.500.350",
      "C01.150.252.400.310.330.500",
      "C01.207.180.500.350",
      "C10.228.228.180.500.350",
      "C10.228.614.280.350",
    ],
  },
  {
    ui: "D020817",
    heading: "Asperger Syndrome",
    tree_numbers: ["F03.625.164.113.250"],
  },
  {
    ui: "D020818",
    heading: "Neuroschistosomiasis",
    tree_numbers: [
      "C01.207.205.250.600",
      "C01.610.105.250.600",
      "C01.610.335.865.859.213",
      "C01.920.922.213",
      "C10.228.228.205.250.600",
    ],
  },
  {
    ui: "D020819",
    heading: "Perimeningeal Infections",
    tree_numbers: ["C01.207.709", "C10.228.228.709"],
  },
  {
    ui: "D020820",
    heading: "Dyskinesias",
    tree_numbers: ["C10.228.662.262", "C10.597.350", "C23.888.592.350"],
    synonyms: ["Oro-Facial Dyskinesia"],
  },
  {
    ui: "D020821",
    heading: "Dystonic Disorders",
    tree_numbers: ["C10.228.662.300"],
    synonyms: ["Dystonic Disease"],
  },
  {
    ui: "D020828",
    heading: "Pseudobulbar Palsy",
    tree_numbers: ["C10.597.622.714", "C23.888.592.636.711"],
    synonyms: ["Pseudobulbar Palsy", "Pseudobulbar Paralysis"],
  },
  {
    ui: "D020833",
    heading: "Diffuse Axonal Injury",
    tree_numbers: ["C10.228.140.199.388.500", "C10.900.300.087.219.500", "C26.915.300.200.188.500"],
  },
  {
    ui: "D020852",
    heading: "Lyme Neuroborreliosis",
    tree_numbers: [
      "C01.150.252.223.437",
      "C01.150.252.400.536.700",
      "C01.150.252.400.794.352.250.700",
      "C01.207.180.437",
      "C01.920.930.513.700",
      "C10.228.228.180.437",
    ],
    synonyms: [
      "Neuroborreliosis",
      "Bannwarth Syndrome",
      "Lyme Borreliosis",
      "Neurological Lyme Disease",
      "Lyme Neuroborreliosis",
      "Bannworth's Syndrome",
    ],
  },
  {
    ui: "D020857",
    heading: "Hallux Limitus",
    tree_numbers: ["C05.330.488.300", "C05.550.445", "C26.558.300.500"],
  },
  {
    ui: "D020859",
    heading: "Hallux Rigidus",
    tree_numbers: ["C05.330.488.310", "C05.550.450"],
  },
  {
    ui: "D020863",
    heading: "Central Nervous System Cysts",
    tree_numbers: ["C04.588.614.250.387", "C10.500.142", "C10.551.240.375", "C16.131.666.142"],
  },
  {
    ui: "D020879",
    heading: "Neuromuscular Manifestations",
    tree_numbers: ["C10.597.613", "C23.888.592.608"],
  },
  {
    ui: "D020886",
    heading: "Somatosensory Disorders",
    tree_numbers: ["C10.597.751.791", "C23.888.592.763.770"],
  },
  {
    ui: "D020896",
    heading: "Hypovolemia",
    tree_numbers: ["C23.550.455"],
  },
  {
    ui: "D020914",
    heading: "Myopathies, Structural, Congenital",
    tree_numbers: ["C05.651.575", "C10.668.491.550"],
    synonyms: ["Mtm1", "Cnmx", "Xlcnm", "Xlmtm", "X-Linked Myotubular Myopathy", "Myotubular Myopathy 1"],
  },
  {
    ui: "D020915",
    heading: "Korsakoff Syndrome",
    tree_numbers: ["C10.597.606.525.400", "C23.888.592.604.529.400", "F01.700.625.400"],
    synonyms: [
      "Korsakov's Psychosis",
      "Korsakov Psychosis",
      "Korsakoff's Psychosis",
      "Korsakoff Syndrome",
      "Korsakoff's Syndrome",
    ],
  },
  {
    ui: "D020918",
    heading: "Complex Regional Pain Syndromes",
    tree_numbers: ["C10.177.195", "C10.668.829.250"],
    synonyms: ["Complex Regional Pain Syndromes"],
  },
  {
    ui: "D020919",
    heading: "Sleep Disorders, Intrinsic",
    tree_numbers: ["C10.886.425.800", "F03.870.400.800"],
  },
  {
    ui: "D020920",
    heading: "Dyssomnias",
    tree_numbers: ["C10.886.425", "F03.870.400"],
  },
  {
    ui: "D020921",
    heading: "Sleep Arousal Disorders",
    tree_numbers: ["C10.886.659.635", "F03.870.664.635"],
  },
  {
    ui: "D020922",
    heading: "Sleep-Wake Transition Disorders",
    tree_numbers: ["C10.886.659.700", "F03.870.664.700"],
  },
  {
    ui: "D020923",
    heading: "REM Sleep Parasomnias",
    tree_numbers: ["C10.886.659.633", "F03.870.664.633"],
  },
  {
    ui: "D020924",
    heading: "Urological Manifestations",
    tree_numbers: ["C23.888.942"],
  },
  {
    ui: "D020925",
    heading: "Hypoxia-Ischemia, Brain",
    tree_numbers: ["C10.228.140.300.150.716", "C10.228.140.624.500", "C14.907.253.092.716", "C23.888.852.079.797.500"],
  },
  {
    ui: "D020936",
    heading: "Epilepsy, Benign Neonatal",
    tree_numbers: ["C10.228.140.490.370", "C16.614.258"],
    synonyms: ["Familial Neonatal Seizures"],
  },
  {
    ui: "D020937",
    heading: "Epilepsy, Partial, Sensory",
    tree_numbers: ["C10.228.140.490.360.275"],
  },
  {
    ui: "D020938",
    heading: "Epilepsy, Partial, Motor",
    tree_numbers: ["C10.228.140.490.360.272"],
    synonyms: ["Epilepsy, Focal Motor", "Focal Motor Seizure"],
  },
  {
    ui: "D020941",
    heading: "Myasthenia Gravis, Neonatal",
    tree_numbers: ["C10.114.656.650", "C10.668.758.725.650", "C20.111.258.500.650"],
    synonyms: ["Neonatal Myasthenia Gravis", "Neonatal Myasthenia Gravis"],
  },
  {
    ui: "D020943",
    heading: "AIDS Arteritis, Central Nervous System",
    tree_numbers: [
      "C01.221.250.875.048",
      "C01.221.812.640.400.048",
      "C01.778.640.400.048",
      "C01.925.782.815.616.400.048",
      "C01.925.813.400.048",
      "C10.114.875.175",
      "C10.228.140.300.850.125",
      "C14.907.253.946.175",
      "C14.907.940.090.170",
      "C14.907.940.907.175",
      "C20.673.480.048",
    ],
  },
  {
    ui: "D020945",
    heading: "Lupus Vasculitis, Central Nervous System",
    tree_numbers: [
      "C01.207.245.550.500",
      "C01.207.570.450",
      "C10.114.875.850",
      "C10.228.140.300.850.750",
      "C10.228.140.430.550.500",
      "C10.228.228.245.550.500",
      "C10.228.228.570.450",
      "C10.228.614.500.500",
      "C14.907.253.946.850",
      "C14.907.940.907.850",
      "C17.300.480.750",
      "C20.111.258.962.900",
      "C20.111.590.750",
    ],
  },
  {
    ui: "D020953",
    heading: "Neuroaspergillosis",
    tree_numbers: ["C01.150.703.080.537", "C01.150.703.492.750", "C01.207.198.750", "C10.228.228.198.750"],
  },
  {
    ui: "D020955",
    heading: "Striatonigral Degeneration",
    tree_numbers: ["C10.177.575.550.875", "C10.228.140.079.612.800", "C10.228.662.550.800"],
  },
  {
    ui: "D020961",
    heading: "Lewy Body Disease",
    tree_numbers: [
      "C10.228.140.079.862.400",
      "C10.228.140.380.422",
      "C10.228.662.600.200",
      "C10.574.928.500",
      "F03.615.400.512",
    ],
    synonyms: [
      "Lewy Body Disease",
      "Diffuse Lewy Body Disease",
      "Dementia With Lewy Bodies",
      "Senile Dementia Of The Lewy Body Type",
    ],
  },
  {
    ui: "D020964",
    heading: "Embryo Loss",
    tree_numbers: ["C13.703.039.711", "C23.550.260.440"],
  },
  {
    ui: "D020966",
    heading: "Muscular Disorders, Atrophic",
    tree_numbers: ["C05.651.534", "C10.668.491.175"],
  },
  {
    ui: "D020967",
    heading: "Myotonic Disorders",
    tree_numbers: ["C05.651.662", "C10.668.491.606"],
    synonyms: [
      "Paralysis Periodica Paramyotonica",
      "Eulenburg Disease",
      "Paramyotonia Congenita",
      "Pmc",
      "Myotonia Congenita Intermittens",
      "Von Eulenburg Paramyotonia Congenita",
    ],
  },
  {
    ui: "D020968",
    heading: "Brachial Plexus Neuritis",
    tree_numbers: ["C10.668.829.100.500", "C10.668.829.650.250"],
    synonyms: ["Brachial Neuritis", "Parsonage-Aldren-Turner Syndrome"],
  },
  {
    ui: "D020969",
    heading: "Disease Attributes",
    tree_numbers: ["C23.550.291"],
  },
  {
    ui: "D021081",
    heading: "Chronobiology Disorders",
    tree_numbers: ["C10.281"],
  },
  {
    ui: "D021181",
    heading: "Egg Hypersensitivity",
    tree_numbers: ["C20.543.480.370.150"],
    synonyms: ["Allergy To Eggs"],
  },
  {
    ui: "D021182",
    heading: "Wheat Hypersensitivity",
    tree_numbers: ["C20.543.480.370.850"],
    synonyms: ["Allergy To Wheat", "Wheat Allergic Reaction"],
  },
  {
    ui: "D021183",
    heading: "Peanut Hypersensitivity",
    tree_numbers: ["C20.543.480.370.572.750"],
    synonyms: ["Peanut Allergic Reaction", "Allergy To Peanuts"],
  },
  {
    ui: "D021184",
    heading: "Nut Hypersensitivity",
    tree_numbers: ["C20.543.480.370.572.500"],
    synonyms: ["Allergy To Nuts", "Nut Allergic Reaction"],
  },
  {
    ui: "D021441",
    heading: "Carcinoma, Pancreatic Ductal",
    tree_numbers: [
      "C04.557.470.200.025.232.750",
      "C04.557.470.615.132.750",
      "C04.588.274.761.750",
      "C04.588.322.475.750",
      "C06.301.761.750",
      "C06.689.667.625",
      "C19.344.421.750",
    ],
    synonyms: ["Pancreatic Duct Cancer", "Malignant Neoplasm Of Duct Of Wirsung"],
  },
  {
    ui: "D021501",
    heading: "Flank Pain",
    tree_numbers: ["C23.888.592.612.386"],
  },
  {
    ui: "D021782",
    heading: "Multicystic Dysplastic Kidney",
    tree_numbers: [
      "C12.706.629",
      "C12.777.419.403.750",
      "C13.351.875.558",
      "C13.351.968.419.403.750",
      "C16.131.939.629",
    ],
  },
  {
    ui: "D021821",
    heading: "Communicable Diseases, Emerging",
    tree_numbers: ["C01.221.500", "C23.550.291.531.750"],
  },
  {
    ui: "D021865",
    heading: "Isosporiasis",
    tree_numbers: ["C01.610.752.250.410"],
    synonyms: ["Isosporiasis", "Infection By Isospora Belli And Isospora Hominis", "Isosporosis"],
  },
  {
    ui: "D021866",
    heading: "Cyclosporiasis",
    tree_numbers: ["C01.610.752.250.280"],
  },
  {
    ui: "D021921",
    heading: "Aortic Stenosis, Supravalvular",
    tree_numbers: ["C14.280.484.048.750.535", "C14.280.955.249.535"],
    synonyms: ["Supra-Valvular Aortic Stenosis"],
  },
  {
    ui: "D021922",
    heading: "Discrete Subaortic Stenosis",
    tree_numbers: ["C14.280.484.048.750.070.210", "C14.280.955.249.070.210"],
  },
  {
    ui: "D022124",
    heading: "Hyperammonemia",
    tree_numbers: ["C23.550.421"],
  },
  {
    ui: "D022125",
    heading: "Lacerations",
    tree_numbers: ["C26.540"],
  },
  {
    ui: "D022861",
    heading: "Hermanski-Pudlak Syndrome",
    tree_numbers: [
      "C11.270.040.545.400",
      "C15.378.100.100.515",
      "C15.378.100.685.400",
      "C15.378.140.735.400",
      "C15.378.463.735.400",
      "C16.320.099.515",
      "C16.320.290.040.100.400",
      "C16.320.565.100.102.100.400",
      "C16.320.850.080.100.400",
      "C17.800.621.440.102.100.400",
      "C17.800.827.080.100.400",
      "C18.452.648.100.102.100.400",
    ],
  },
  {
    ui: "D023341",
    heading: "Chills",
    tree_numbers: ["C23.888.208"],
  },
  {
    ui: "D023521",
    heading: "Chlamydophila Infections",
    tree_numbers: ["C01.150.252.400.210.250"],
  },
  {
    ui: "D023903",
    heading: "Coronary Restenosis",
    tree_numbers: ["C14.280.647.250.285.200", "C14.907.585.250.285.200"],
  },
  {
    ui: "D023921",
    heading: "Coronary Stenosis",
    tree_numbers: ["C14.280.647.250.285", "C14.907.585.250.285"],
    synonyms: ["Coronary Artery Stenosis"],
  },
  {
    ui: "D023961",
    heading: "Gonadal Dysgenesis, 46,XX",
    tree_numbers: [
      "C12.706.316.064.249",
      "C12.706.316.309.193",
      "C13.351.875.253.064.249",
      "C13.351.875.253.309.193",
      "C16.131.939.316.064.249",
      "C16.131.939.316.309.193",
      "C19.391.119.064.249",
      "C19.391.119.309.193",
    ],
    synonyms: ["Ovarian Dysgenesis"],
  },
  {
    ui: "D023981",
    heading: "Sarcoma, Myeloid",
    tree_numbers: ["C04.557.337.539.775", "C04.557.450.795.853"],
    synonyms: ["Chloroma", "Extramedullary Myeloid Tumor", "Granulocytic Sarcoma"],
  },
  {
    ui: "D024182",
    heading: "Uniparental Disomy",
    tree_numbers: ["C23.550.210.645.890", "G05.365.590.175.935"],
  },
  {
    ui: "D024343",
    heading: "Open Bite",
    tree_numbers: ["C07.793.494.825"],
  },
  {
    ui: "D024741",
    heading: "Cardiomyopathy, Hypertrophic, Familial",
    tree_numbers: ["C14.280.238.100.500", "C14.280.484.048.750.070.160.500", "C16.320.160"],
    synonyms: ["Hypertrophic Obstructive Cardiomyopathy", "Familial Hypertrophic Cardiomyopathy"],
  },
  {
    ui: "D024801",
    heading: "Tauopathies",
    tree_numbers: ["C10.574.945"],
  },
  {
    ui: "D024821",
    heading: "Metabolic Syndrome",
    tree_numbers: ["C18.452.394.968.500.570", "C18.452.625"],
    synonyms: ["Dysmetabolic Syndrome X", "Metabolic Syndrome X", "Abdominal Obesity-Metabolic Syndrome 1"],
  },
  {
    ui: "D025063",
    heading: "Chromosome Disorders",
    tree_numbers: ["C16.131.260", "C16.320.180"],
  },
  {
    ui: "D025064",
    heading: "Sex Chromosome Disorders",
    tree_numbers: ["C16.131.260.830", "C16.320.180.830"],
  },
  {
    ui: "D025241",
    heading: "Spondylarthritis",
    tree_numbers: ["C05.116.900.853.625", "C05.550.114.865"],
  },
  {
    ui: "D025242",
    heading: "Spondylarthropathies",
    tree_numbers: ["C05.116.900.853.625.800", "C05.550.114.865.800"],
    synonyms: ["Spondarthropathy", "Spondylarthrosis"],
  },
  {
    ui: "D025861",
    heading: "Blood Coagulation Disorders, Inherited",
    tree_numbers: ["C15.378.100.100", "C16.320.099"],
  },
  {
    ui: "D025962",
    heading: "Septo-Optic Dysplasia",
    tree_numbers: ["C10.500.034.937", "C10.500.760.500", "C16.131.666.034.937", "C16.131.666.763.500"],
    synonyms: ["De Morsier Syndrome", "Septo-Optic Dysplasia", "Sod"],
  },
  {
    ui: "D025981",
    heading: "Hip Injuries",
    tree_numbers: ["C26.531"],
  },
  {
    ui: "D026681",
    heading: "Vitamin B 6 Deficiency",
    tree_numbers: ["C18.654.521.500.133.699.901"],
    synonyms: ["Vitamin B6 Deficiency Syndrome"],
  },
  {
    ui: "D027601",
    heading: "Polyomavirus Infections",
    tree_numbers: ["C01.925.256.721"],
  },
  {
    ui: "D028226",
    heading: "Amyloidosis, Familial",
    tree_numbers: ["C16.320.565.176", "C18.452.648.176", "C18.452.845.500.075"],
  },
  {
    ui: "D028227",
    heading: "Amyloid Neuropathies, Familial",
    tree_numbers: [
      "C10.574.500.050",
      "C10.668.829.050.050",
      "C16.320.400.050",
      "C16.320.565.176.050",
      "C18.452.648.176.050",
      "C18.452.845.500.050.050",
      "C18.452.845.500.075.050",
    ],
  },
  {
    ui: "D028243",
    heading: "Cerebral Amyloid Angiopathy, Familial",
    tree_numbers: [
      "C10.228.140.163.100.168",
      "C10.228.140.300.275.311",
      "C10.228.140.300.510.200.200.160",
      "C14.907.253.329.311",
      "C14.907.253.560.200.200.160",
      "C16.320.565.176.160",
      "C16.320.565.189.168",
      "C18.452.132.100.168",
      "C18.452.648.176.160",
      "C18.452.648.189.168",
      "C18.452.845.500.075.160",
      "C18.452.845.500.100.160",
    ],
    synonyms: ["Cerebral Hemorrhage, Hereditary, With Amyloidosis", "Hereditary Cerebral Hemorrhage With Amyloidosis"],
  },
  {
    ui: "D028361",
    heading: "Mitochondrial Diseases",
    tree_numbers: ["C18.452.660"],
  },
  {
    ui: "D028921",
    heading: "Biotinidase Deficiency",
    tree_numbers: [
      "C16.320.565.100.620.100",
      "C16.320.565.202.720.100",
      "C18.452.648.100.620.100",
      "C18.452.648.202.720.100",
    ],
    synonyms: [
      "Deficiency Of Biotinidase",
      "Late-Onset Multiple Carboxylase Deficiency",
      "Juvenile-Onset Multiple Carboxylase Deficiency",
      "Btd Deficiency",
    ],
  },
  {
    ui: "D028922",
    heading: "Holocarboxylase Synthetase Deficiency",
    tree_numbers: [
      "C16.320.565.100.620.380",
      "C16.320.565.202.720.380",
      "C18.452.648.100.620.380",
      "C18.452.648.202.720.380",
    ],
    synonyms: [
      "Multiple Carboxylase Deficiency - Neonatal Onset",
      "Biotin-(propionyl-Coa-Carboxylase) Ligase Deficiency",
    ],
  },
  {
    ui: "D029021",
    heading: "Peste-des-Petits-Ruminants",
    tree_numbers: ["C01.925.782.580.600.500.600", "C22.706"],
  },
  {
    ui: "D029241",
    heading: "Optic Atrophy, Autosomal Dominant",
    tree_numbers: [
      "C10.292.700.225.500.100",
      "C10.574.500.662.100",
      "C11.270.564.100",
      "C11.640.451.451.100",
      "C16.320.290.564.100",
      "C16.320.400.630.100",
      "C18.452.660.665",
    ],
  },
  {
    ui: "D029242",
    heading: "Optic Atrophy, Hereditary, Leber",
    tree_numbers: [
      "C10.292.700.225.500.400",
      "C10.574.500.662.400",
      "C11.270.564.400",
      "C11.640.451.451.400",
      "C16.320.290.564.400",
      "C16.320.400.630.400",
      "C18.452.660.670",
    ],
    synonyms: ["Leber's Hereditary Optic Neuropathy", "Leber's Optic Atrophy"],
  },
  {
    ui: "D029424",
    heading: "Pulmonary Disease, Chronic Obstructive",
    tree_numbers: ["C08.381.495.389"],
    synonyms: ["Cold", "Copd", "Chronic Obstructive Lung Disease", "Chronic Obstructive Airway Disease"],
  },
  {
    ui: "D029461",
    heading: "Sialic Acid Storage Disease",
    tree_numbers: [
      "C10.228.140.163.100.435.810",
      "C16.320.565.189.435.810",
      "C16.320.565.595.554.810",
      "C18.452.132.100.435.810",
      "C18.452.648.189.435.810",
      "C18.452.648.595.554.810",
    ],
  },
  {
    ui: "D029481",
    heading: "Bronchitis, Chronic",
    tree_numbers: [
      "C01.748.099.567",
      "C08.127.446.567",
      "C08.381.495.146.567",
      "C08.381.495.389.500",
      "C08.730.099.567",
    ],
    synonyms: [
      "Ci - Chest Infection",
      "Chronic Bronchitis",
      "Recurrent Wheezy Bronchitis",
      "Chest Cold",
      "Acute Bronchitis",
      "Chest Infection",
    ],
  },
  {
    ui: "D029502",
    heading: "Anemia, Hypoplastic, Congenital",
    tree_numbers: ["C15.378.071.085.080", "C15.378.190.223.500.500", "C16.320.077"],
    synonyms: ["Congenital Hypoplastic Anaemia", "Congenital Aplastic Anaemia", "Congenital Aplastic Anemia"],
  },
  {
    ui: "D029503",
    heading: "Anemia, Diamond-Blackfan",
    tree_numbers: ["C15.378.071.085.080.090", "C15.378.071.750.500", "C15.378.190.223.500.500.090", "C16.320.077.090"],
    synonyms: ["Chronic Constitutional Pure Red Cell Anaemia", "Blackfan - Diamond Syndrome"],
  },
  {
    ui: "D029593",
    heading: "Jervell-Lange Nielsen Syndrome",
    tree_numbers: ["C14.280.067.565.440", "C14.280.123.625.440", "C16.131.240.400.715.440"],
    synonyms: ["Jervell And Lange-Nielson Syndrome"],
  },
  {
    ui: "D029597",
    heading: "Romano-Ward Syndrome",
    tree_numbers: ["C14.280.067.565.720", "C14.280.123.625.720", "C16.131.240.400.715.720", "C23.550.073.547.720"],
  },
  {
    ui: "D030243",
    heading: "Hemorrhagic Syndrome, Bovine",
    tree_numbers: ["C01.925.782.350.675.400", "C22.196.400"],
  },
  {
    ui: "D030321",
    heading: "Denys-Drash Syndrome",
    tree_numbers: [
      "C04.557.435.595.220",
      "C04.588.945.947.535.585.220",
      "C04.700.900.220",
      "C12.706.316.096.562",
      "C12.758.820.750.585.220",
      "C12.777.419.473.585.220",
      "C13.351.875.253.096.562",
      "C13.351.937.820.535.585.220",
      "C13.351.968.419.473.585.220",
      "C16.131.939.316.096.562",
      "C16.320.700.900.220",
      "C19.391.119.096.562",
    ],
  },
  {
    ui: "D030341",
    heading: "Nidovirales Infections",
    tree_numbers: ["C01.925.782.600"],
  },
  {
    ui: "D030342",
    heading: "Genetic Diseases, Inborn",
    tree_numbers: ["C16.320"],
  },
  {
    ui: "D030361",
    heading: "Papillomavirus Infections",
    tree_numbers: ["C01.925.256.650", "C01.925.928.725"],
    synonyms: ["Hpv"],
  },
  {
    ui: "D030401",
    heading: "Cytochrome-c Oxidase Deficiency",
    tree_numbers: ["C16.320.565.240", "C18.452.660.195"],
    synonyms: ["Mitochondrial Complex Iv Deficiency"],
  },
  {
    ui: "D030981",
    heading: "Acro-Osteolysis",
    tree_numbers: ["C05.116.099.052", "C05.116.264.579.052"],
    synonyms: [
      "Sfpks",
      "Serpentine Fibula-Polycystic Kidney Syndrome",
      "Arthrodentoosteodysplasia",
      "Cheney Syndrome",
      "Acroosteolysis With Osteoporosis And Changes In Skull And Mandible",
      "Hjcys",
    ],
  },
  {
    ui: "D031221",
    heading: "Knee Dislocation",
    tree_numbers: ["C05.550.518.500", "C26.289.500", "C26.558.554.500"],
  },
  {
    ui: "D031222",
    heading: "Patellar Dislocation",
    tree_numbers: ["C05.550.518.625", "C26.289.625", "C26.558.554.750"],
  },
  {
    ui: "D031249",
    heading: "Erdheim-Chester Disease",
    tree_numbers: ["C15.604.250.410.224"],
  },
  {
    ui: "D031261",
    heading: "Poult Enteritis Mortality Syndrome",
    tree_numbers: ["C01.655", "C22.131.728.650"],
  },
  {
    ui: "D031300",
    heading: "Retinal Vasculitis",
    tree_numbers: ["C11.768.757", "C14.907.940.815"],
    synonyms: ["Retinal Vasculitis"],
  },
  {
    ui: "D031368",
    heading: "Zoster Sine Herpete",
    tree_numbers: ["C01.925.256.466.930.750.970"],
  },
  {
    ui: "D031845",
    heading: "Hajdu-Cheney Syndrome",
    tree_numbers: ["C05.116.099.052.400", "C05.116.264.579.052.400", "C16.131.621.445", "C16.320.355"],
    synonyms: [
      "Sfpks",
      "Serpentine Fibula-Polycystic Kidney Syndrome",
      "Arthrodentoosteodysplasia",
      "Cheney Syndrome",
      "Acroosteolysis With Osteoporosis And Changes In Skull And Mandible",
      "Hjcys",
    ],
  },
  {
    ui: "D031901",
    heading: "Gestational Trophoblastic Disease",
    tree_numbers: ["C04.557.465.955.416", "C04.850.908.416", "C13.703.720.949.416"],
    synonyms: ["Gestational Trophoblastic Neoplasia", "Hydatidiform Mole", "Molar Pregnancy"],
  },
  {
    ui: "D031941",
    heading: "Hemorrhagic Septicemia, Viral",
    tree_numbers: ["C01.925.782.580.830.450", "C22.362.450", "C23.550.470.790.500.900.400"],
  },
  {
    ui: "D031954",
    heading: "Choriocarcinoma, Non-gestational",
    tree_numbers: [
      "C04.557.465.955.207.438",
      "C04.557.470.200.025.455.750",
      "C04.850.908.208.438",
      "C13.703.720.949.208.438",
    ],
  },
  {
    ui: "D033461",
    heading: "Hyperuricemia",
    tree_numbers: ["C23.550.449"],
    synonyms: ["Uricacidemia", "Blood Urate Raized"],
  },
  {
    ui: "D034062",
    heading: "Insomnia, Fatal Familial",
    tree_numbers: ["C01.207.800.392", "C10.228.228.800.392", "C10.574.843.512", "C10.886.425.800.800.400"],
  },
  {
    ui: "D034081",
    heading: "Wasting Disease, Chronic",
    tree_numbers: ["C01.207.800.858", "C10.228.228.800.858", "C10.574.843.925", "C22.955"],
  },
  {
    ui: "D034141",
    heading: "Hypoalbuminemia",
    tree_numbers: ["C15.378.147.607.500"],
  },
  {
    ui: "D034161",
    heading: "Pelvic Infection",
    tree_numbers: ["C01.635"],
  },
  {
    ui: "D034321",
    heading: "Hyperamylasemia",
    tree_numbers: ["C23.550.425"],
  },
  {
    ui: "D034381",
    heading: "Hearing Loss",
    tree_numbers: ["C09.218.458.341", "C10.597.751.418.341", "C23.888.592.763.393.341"],
  },
  {
    ui: "D034701",
    heading: "Mastocytosis, Cutaneous",
    tree_numbers: ["C04.557.450.565.465.500", "C17.800.508.473"],
    synonyms: ["Diffuse Cutaneous Mastocytosis"],
  },
  {
    ui: "D034721",
    heading: "Mastocytosis, Systemic",
    tree_numbers: ["C04.557.450.565.465.750", "C20.543.520.350.750"],
    synonyms: ["Systemic Tissue Mast Cell Disease", "Smcd - Systemic Mast Cell Disease"],
  },
  {
    ui: "D034801",
    heading: "Mastocytoma",
    tree_numbers: ["C04.557.450.565.465.249", "C17.800.508.236"],
    synonyms: ["Extracutaneous Mastocytoma"],
  },
  {
    ui: "D035583",
    heading: "Rare Diseases",
    tree_numbers: ["C23.550.291.906"],
  },
  {
    ui: "D036821",
    heading: "Endometrial Stromal Tumors",
    tree_numbers: [
      "C04.557.450.565.325",
      "C04.557.450.795.332",
      "C13.351.500.852.762.200.500",
      "C13.351.937.418.875.200.374",
    ],
    synonyms: ["Endometrial Stromal Neoplasm"],
  },
  {
    ui: "D036841",
    heading: "Ciguatera Poisoning",
    tree_numbers: ["C25.723.415.246"],
  },
  {
    ui: "D036981",
    heading: "Fasciitis, Plantar",
    tree_numbers: ["C05.321.600", "C05.360.350"],
  },
  {
    ui: "D036982",
    heading: "Heel Spur",
    tree_numbers: ["C05.116.540.310.600", "C05.360.400"],
    synonyms: [],
  },
  {
    ui: "D037061",
    heading: "Metatarsalgia",
    tree_numbers: ["C05.360.500", "C05.550.610", "C23.888.592.612.540"],
  },
  {
    ui: "D037081",
    heading: "Posterior Tibial Tendon Dysfunction",
    tree_numbers: ["C05.360.750"],
  },
  {
    ui: "D037801",
    heading: "Hammer Toe Syndrome",
    tree_numbers: ["C05.330.615"],
  },
  {
    ui: "D038061",
    heading: "Lower Extremity Deformities, Congenital",
    tree_numbers: ["C05.660.585.512", "C16.131.621.585.512"],
  },
  {
    ui: "D038062",
    heading: "Upper Extremity Deformities, Congenital",
    tree_numbers: ["C05.660.585.988", "C16.131.621.585.988"],
  },
  {
    ui: "D038223",
    heading: "Post-Concussion Syndrome",
    tree_numbers: ["C10.900.300.350.300.500", "C26.915.300.450.500.500", "C26.974.382.200.500"],
  },
  {
    ui: "D038261",
    heading: "Alexander Disease",
    tree_numbers: [
      "C10.228.140.163.100.362.312",
      "C10.228.140.695.625.312",
      "C10.314.400.312",
      "C10.574.500.024",
      "C16.320.400.024",
      "C16.320.565.189.362.312",
      "C18.452.132.100.362.312",
      "C18.452.648.189.362.312",
    ],
    synonyms: ["Alexander's Disease"],
  },
  {
    ui: "D038642",
    heading: "Shaken Baby Syndrome",
    tree_numbers: ["C10.900.300.087.850", "C26.915.300.200.825"],
  },
  {
    ui: "D038901",
    heading: "Mental Retardation, X-Linked",
    tree_numbers: ["C10.597.606.360.455", "C16.320.322.500", "C16.320.400.525"],
  },
  {
    ui: "D038921",
    heading: "Coffin-Lowry Syndrome",
    tree_numbers: ["C10.597.606.360.455.249", "C16.320.322.500.249", "C16.320.400.525.249"],
  },
  {
    ui: "D039141",
    heading: "Muscular Dystrophy, Oculopharyngeal",
    tree_numbers: ["C05.651.534.500.450", "C10.668.491.175.500.450", "C16.320.577.450"],
    synonyms: ["Muscular Dystrophy, Oculopharyngeal", "Oculopharyngeal Muscular Dystrophy"],
  },
  {
    ui: "D039682",
    heading: "HIV-Associated Lipodystrophy Syndrome",
    tree_numbers: [
      "C01.221.250.875.550",
      "C01.221.812.640.400.530",
      "C01.778.640.400.530",
      "C01.925.782.815.616.400.550",
      "C01.925.813.400.530",
      "C17.800.849.391.400",
      "C18.452.584.625.400",
      "C18.452.880.391.400",
      "C20.673.480.400",
    ],
  },
  {
    ui: "D039941",
    heading: "Bifidobacteriales Infections",
    tree_numbers: ["C01.150.252.410.110"],
  },
  {
    ui: "D040181",
    heading: "Genetic Diseases, X-Linked",
    tree_numbers: ["C16.320.322"],
  },
  {
    ui: "D040701",
    heading: "Stress Disorders, Traumatic, Acute",
    tree_numbers: ["F03.950.750.550"],
  },
  {
    ui: "D040921",
    heading: "Stress Disorders, Traumatic",
    tree_numbers: ["F03.950.750"],
  },
  {
    ui: "D041441",
    heading: "Retinoschisis",
    tree_numbers: ["C11.768.585.865"],
  },
  {
    ui: "D041761",
    heading: "Cholecystolithiasis",
    tree_numbers: ["C06.130.409.178", "C06.130.564.332"],
  },
  {
    ui: "D041781",
    heading: "Jaundice, Obstructive",
    tree_numbers: ["C23.550.429.500.755", "C23.888.885.375.500"],
    synonyms: [
      "Obstructive Hyperbilirubinemia",
      "Cholestatic Jaundice Syndrome",
      "Obstructive Jaundice",
      "Cholestatic Jaundice",
    ],
  },
  {
    ui: "D041881",
    heading: "Cholecystitis, Acute",
    tree_numbers: ["C06.130.564.263.500"],
  },
  {
    ui: "D041882",
    heading: "Emphysematous Cholecystitis",
    tree_numbers: ["C06.130.564.263.500.500"],
    synonyms: ["Gaseous Pericholecystitis"],
  },
  {
    ui: "D042101",
    heading: "Acalculous Cholecystitis",
    tree_numbers: ["C06.130.564.263.249"],
    synonyms: [
      "Cholecystitis Without Calculus",
      "Acute Cholecystitis Without Calculus",
      "Acute Acalculous Cholecystitis",
    ],
  },
  {
    ui: "D042822",
    heading: "Genomic Instability",
    tree_numbers: ["C23.550.362", "G05.365.590.335", "G05.370"],
  },
  {
    ui: "D042882",
    heading: "Gallstones",
    tree_numbers: ["C06.130.409.633", "C06.130.564.332.500", "C23.300.175.525"],
  },
  {
    ui: "D042883",
    heading: "Choledocholithiasis",
    tree_numbers: ["C06.130.120.250.174", "C06.130.409.267"],
  },
  {
    ui: "D043171",
    heading: "Chromosomal Instability",
    tree_numbers: ["C23.550.210.110", "C23.550.362.180", "G05.365.590.175.165", "G05.370.180"],
  },
  {
    ui: "D043183",
    heading: "Irritable Bowel Syndrome",
    tree_numbers: ["C06.405.469.158.272.608"],
    synonyms: ["Irritable Colon", "Ibd"],
  },
  {
    ui: "D043202",
    heading: "Steroid Metabolism, Inborn Errors",
    tree_numbers: ["C16.320.565.925", "C18.452.648.925"],
  },
  {
    ui: "D043204",
    heading: "Mineralocorticoid Excess Syndrome, Apparent",
    tree_numbers: ["C16.320.565.925.500", "C18.452.648.925.500"],
    synonyms: [
      "Syndrome Of Apparent Mineralocorticoid Excess",
      "11-Beta-Hydroxysteroid Dehydrogenase Deficiency Type 2",
      "Cortisol 11-Beta-Ketoreductase Deficiency",
      "Ulick Syndrome",
    ],
  },
  {
    ui: "D043604",
    heading: "Glomus Tympanicum Tumor",
    tree_numbers: ["C04.557.465.625.650.700.705.360", "C04.557.580.625.650.700.705.360"],
  },
  {
    ui: "D043963",
    heading: "Diverticulosis, Colonic",
    tree_numbers: ["C06.405.469.158.587"],
  },
  {
    ui: "D044148",
    heading: "Lymphatic Abnormalities",
    tree_numbers: ["C15.604.451", "C16.131.482"],
  },
  {
    ui: "D044225",
    heading: "Piscirickettsiaceae Infections",
    tree_numbers: ["C01.150.252.400.719"],
  },
  {
    ui: "D044342",
    heading: "Malnutrition",
    tree_numbers: ["C18.654.521"],
  },
  {
    ui: "D044343",
    heading: "Overnutrition",
    tree_numbers: ["C18.654.726"],
  },
  {
    ui: "D044483",
    heading: "Intestinal Polyposis",
    tree_numbers: ["C06.405.469.578"],
  },
  {
    ui: "D044504",
    heading: "Enterocolitis, Neutropenic",
    tree_numbers: ["C06.405.205.596.750", "C06.405.469.363.750"],
  },
  {
    ui: "D044542",
    heading: "LEOPARD Syndrome",
    tree_numbers: [
      "C05.660.207.525",
      "C14.240.400.695",
      "C14.280.400.695",
      "C14.280.484.716.525",
      "C16.131.077.525",
      "C16.131.240.400.685",
      "C16.131.621.207.525",
      "C17.800.621.430.530.550.525",
    ],
    synonyms: [
      "Capute-Rimoin-Konigsmark-Esterly-Richardson Syndrome",
      "Moynahan Syndrome",
      "Gorlin Syndrome Ii",
      "Progressive Cardiomyopathic Lentiginosis",
      "Multiple Lentigines Syndrome",
      "Leopard Syndrome",
      "Generalized Lentiginosis",
      "Lentiginosis Profusa Syndrome",
    ],
  },
  {
    ui: "D044584",
    heading: "Carcinoma, Ductal",
    tree_numbers: ["C04.557.470.200.025.232", "C04.557.470.615.132"],
    synonyms: ["Duct Carcinoma"],
  },
  {
    ui: "D044882",
    heading: "Glucose Metabolism Disorders",
    tree_numbers: ["C18.452.394"],
    synonyms: ["Disorder Of Glucose Metabolism"],
  },
  {
    ui: "D044903",
    heading: "Congenital Hyperinsulinism",
    tree_numbers: ["C06.689.150", "C16.614.200", "C18.452.394.968.250", "C18.452.394.984.200"],
  },
  {
    ui: "D044904",
    heading: "Mannosidase Deficiency Diseases",
    tree_numbers: ["C16.320.565.202.607", "C16.320.565.595.577", "C18.452.648.202.607", "C18.452.648.595.577"],
  },
  {
    ui: "D044905",
    heading: "beta-Mannosidosis",
    tree_numbers: [
      "C16.320.565.202.607.750",
      "C16.320.565.595.577.750",
      "C18.452.648.202.607.750",
      "C18.452.648.595.577.750",
    ],
    synonyms: ["Lysosomal Beta-Mannosidase Deficiency", "Beta-D-Mannosidosis", "Beta-Mannosidase Deficiency"],
  },
  {
    ui: "D045169",
    heading: "Severe Acute Respiratory Syndrome",
    tree_numbers: ["C01.748.730", "C01.925.782.600.550.200.750", "C08.730.730"],
    synonyms: ["Sars-Cov Infection", "Sars"],
  },
  {
    ui: "D045262",
    heading: "Reticulocytosis",
    tree_numbers: ["C23.888.760", "G09.188.760"],
  },
  {
    ui: "D045463",
    heading: "Avulavirus Infections",
    tree_numbers: ["C01.925.782.580.600.080"],
  },
  {
    ui: "D045464",
    heading: "Henipavirus Infections",
    tree_numbers: ["C01.925.782.580.600.400"],
  },
  {
    ui: "D045602",
    heading: "Steatorrhea",
    tree_numbers: ["C06.405.469.637.887", "C18.452.603.887"],
  },
  {
    ui: "D045723",
    heading: "Diverticulosis, Esophageal",
    tree_numbers: ["C06.405.117.136", "C06.405.205.282.500.438"],
  },
  {
    ui: "D045724",
    heading: "Diverticulosis, Stomach",
    tree_numbers: ["C06.405.205.282.500.625", "C06.405.748.142"],
  },
  {
    ui: "D045729",
    heading: "Pneumonia of Swine, Mycoplasmal",
    tree_numbers: [
      "C01.150.252.620.330",
      "C01.748.610.540.540",
      "C08.381.677.540.400",
      "C08.730.610.540.540",
      "C22.905.626",
    ],
  },
  {
    ui: "D045743",
    heading: "Scleroderma, Diffuse",
    tree_numbers: ["C17.300.799.602", "C17.800.784.602"],
    synonyms: ["Systemic Sclerosis, Diffuse", "Diffuse Systemic Sclerosis"],
  },
  {
    ui: "D045745",
    heading: "Scleroderma, Limited",
    tree_numbers: ["C17.300.799.801", "C17.800.784.801"],
    synonyms: ["Systemic Sclerosis, Limited", "Limited Cutaneous Systemic Sclerosis"],
  },
  {
    ui: "D045822",
    heading: "Intestinal Volvulus",
    tree_numbers: ["C06.405.469.531.568", "C23.300.970.500"],
    synonyms: [
      "Volvulus",
      "Volvulus Of Midgut",
      "Familial Intestinal Malrotation",
      "Twist Of Intestine, Bowel, Or Colon",
    ],
  },
  {
    ui: "D045823",
    heading: "Ileus",
    tree_numbers: ["C06.405.469.531.492"],
    synonyms: ["Ileus Of Intestine"],
  },
  {
    ui: "D045824",
    heading: "Desulfovibrionaceae Infections",
    tree_numbers: ["C01.150.252.400.260"],
  },
  {
    ui: "D045825",
    heading: "Fusobacteriaceae Infections",
    tree_numbers: ["C01.150.252.400.388"],
  },
  {
    ui: "D045826",
    heading: "Flavobacteriaceae Infections",
    tree_numbers: ["C01.150.252.400.349"],
  },
  {
    ui: "D045827",
    heading: "Cytophagaceae Infections",
    tree_numbers: ["C01.150.252.400.245"],
  },
  {
    ui: "D045828",
    heading: "Moraxellaceae Infections",
    tree_numbers: ["C01.150.252.400.560"],
  },
  {
    ui: "D045888",
    heading: "Ganglion Cysts",
    tree_numbers: ["C04.182.347", "C17.300.550.274"],
  },
  {
    ui: "D045908",
    heading: "Monkeypox",
    tree_numbers: ["C01.925.256.743.615", "C22.735.750", "C22.795.600"],
  },
  {
    ui: "D046088",
    heading: "Hearing Loss, Unilateral",
    tree_numbers: ["C09.218.458.341.950", "C10.597.751.418.341.950", "C23.888.592.763.393.341.950"],
  },
  {
    ui: "D046089",
    heading: "Hearing Loss, Mixed Conductive-Sensorineural",
    tree_numbers: ["C09.218.458.341.849", "C10.597.751.418.341.849", "C23.888.592.763.393.341.849"],
  },
  {
    ui: "D046110",
    heading: "Hypertension, Pregnancy-Induced",
    tree_numbers: ["C13.703.395", "C14.907.489.480"],
  },
  {
    ui: "D046150",
    heading: "Laron Syndrome",
    tree_numbers: ["C05.116.099.343.679", "C16.320.240.750", "C19.297.656"],
    synonyms: ["Laron-Type Isolated Somatotropin Defect"],
  },
  {
    ui: "D046151",
    heading: "Lingual Thyroid",
    tree_numbers: ["C16.131.894.500", "C19.874.689.500"],
  },
  {
    ui: "D046152",
    heading: "Gastrointestinal Stromal Tumors",
    tree_numbers: ["C04.557.450.565.370", "C06.301.371.308", "C06.405.249.308"],
    synonyms: [
      "Gist",
      "Gastrointestinal Stromal Tumour",
      "Stromal Tumour Of Gastrointestinal Tract",
      "Stromal Tumor Of Gastrointestinal Tract",
      "Gant",
    ],
  },
  {
    ui: "D046248",
    heading: "Pyloric Stenosis, Hypertrophic",
    tree_numbers: ["C06.405.748.340.690.500"],
    synonyms: ["Congenital Or Infantile Stricture Of Pylorus", "Congenital Hypertrophic Pyloric Stenosis"],
  },
  {
    ui: "D046290",
    heading: "Liver Abscess, Pyogenic",
    tree_numbers: ["C01.830.025.020.455.730", "C06.552.597.758"],
  },
  {
    ui: "D046349",
    heading: "Coproporphyria, Hereditary",
    tree_numbers: ["C06.552.830.074", "C16.320.850.742.074", "C17.800.827.742.074", "C18.452.811.400.074"],
    synonyms: [
      "Coproporphyrinogen Oxidase Deficiency",
      "Hereditary Coproporphyria Porphyria",
      "Hereditary Coproporphyria",
    ],
  },
  {
    ui: "D046350",
    heading: "Porphyria, Variegate",
    tree_numbers: ["C06.552.830.625", "C16.320.850.742.625", "C17.800.827.742.625", "C18.452.811.400.625"],
    synonyms: ["Protoporphyrinogen Oxidase Deficiency", "Protocoproporphyria", "Variegate Porphyria"],
  },
  {
    ui: "D046351",
    heading: "Protoporphyria, Erythropoietic",
    tree_numbers: ["C06.552.830.812", "C16.320.850.742.812", "C17.800.827.742.812", "C18.452.811.400.812"],
    synonyms: ["Erythropoietic Protoporphyria", "Epp", "Protoporphyria"],
  },
  {
    ui: "D046449",
    heading: "Hernia, Abdominal",
    tree_numbers: ["C23.300.707.374"],
  },
  {
    ui: "D046548",
    heading: "Pubic Symphysis Diastasis",
    tree_numbers: ["C05.550.518.192.500", "C13.703.844.728", "C26.289.192.500"],
  },
  {
    ui: "D046589",
    heading: "CADASIL",
    tree_numbers: [
      "C10.228.140.300.150.477.200.100",
      "C10.228.140.300.275.249",
      "C10.228.140.300.400.203",
      "C10.228.140.300.510.200.175",
      "C10.228.140.300.775.200.200.100",
      "C10.228.140.380.230.124",
      "C14.907.253.092.477.200.100",
      "C14.907.253.329.249",
      "C14.907.253.560.200.175",
      "C14.907.253.855.200.200.100",
      "C16.320.129",
      "C23.550.513.355.250.200.100",
      "C23.550.717.489.250.200.100",
    ],
    synonyms: [
      "Cerebral Autosomal Dominant Arteriopathy With Subcortical Infarcts And Leukoencephalopathy",
      "Hereditary Multi-Infarct Dementia",
    ],
  },
  {
    ui: "D046608",
    heading: "Synkinesis",
    tree_numbers: ["C10.597.350.675", "C23.888.592.350.675"],
  },
  {
    ui: "D046628",
    heading: "Sphincter of Oddi Dysfunction",
    tree_numbers: ["C06.130.120.250.098.800"],
  },
  {
    ui: "D046648",
    heading: "Hematoma, Subdural, Intracranial",
    tree_numbers: [
      "C10.228.140.300.535.450.400.400",
      "C10.900.300.837.600.400",
      "C14.907.253.573.400.450.400",
      "C23.550.414.838.700.400",
      "C23.550.414.913.700.400",
      "C26.915.300.490.450.400",
    ],
  },
  {
    ui: "D046649",
    heading: "Hematoma, Subdural, Spinal",
    tree_numbers: ["C23.550.414.838.700.700"],
  },
  {
    ui: "D046728",
    heading: "Colitis, Microscopic",
    tree_numbers: ["C06.405.205.265.173", "C06.405.469.158.188.173"],
  },
  {
    ui: "D046729",
    heading: "Colitis, Collagenous",
    tree_numbers: ["C06.405.205.265.173.500", "C06.405.469.158.188.173.500"],
  },
  {
    ui: "D046730",
    heading: "Colitis, Lymphocytic",
    tree_numbers: ["C06.405.205.265.173.750", "C06.405.469.158.188.173.750"],
  },
  {
    ui: "D046748",
    heading: "Hematoma, Epidural, Spinal",
    tree_numbers: ["C23.550.414.838.355"],
  },
  {
    ui: "D046768",
    heading: "Nesidioblastosis",
    tree_numbers: ["C06.689.150.500", "C16.614.200.500", "C18.452.394.968.250.500", "C18.452.394.984.200.500"],
    synonyms: ["Nesidioblastosis", "Islet Cell Hyperplasia", "Persistent Hyperinsulinemia Hypoglycemia Of Infancy"],
  },
  {
    ui: "D046788",
    heading: "Patellofemoral Pain Syndrome",
    tree_numbers: ["C05.550.700"],
  },
  {
    ui: "D046789",
    heading: "Chondromalacia Patellae",
    tree_numbers: ["C05.182.100", "C17.300.182.100"],
    synonyms: ["Chondromalacia Of Patella", "Chondromalacia Patellae", "Softening Of Articular Cartilage Of Patella"],
  },
  {
    ui: "D047268",
    heading: "Lingual Goiter",
    tree_numbers: ["C16.131.894.500.500", "C19.874.283.802", "C19.874.689.500.500"],
  },
  {
    ui: "D047489",
    heading: "Preconception Injuries",
    tree_numbers: ["C21.676"],
  },
  {
    ui: "D047508",
    heading: "Massive Hepatic Necrosis",
    tree_numbers: ["C06.552.100.600", "C06.552.308.500.750.500"],
  },
  {
    ui: "D047688",
    heading: "Breast Cyst",
    tree_numbers: ["C04.182.156", "C17.800.090.249"],
    synonyms: ["Cyst Of The Breast"],
  },
  {
    ui: "D047708",
    heading: "Myofibroma",
    tree_numbers: ["C04.557.450.565.540", "C17.300.680.540"],
    synonyms: ["Lipoleiomyoma", "Myofibroma"],
  },
  {
    ui: "D047728",
    heading: "Myopia, Degenerative",
    tree_numbers: ["C11.744.636.500"],
    synonyms: [
      "Progressive High (Degenerative) Myopia",
      "Degenerative Progressive High Myopia",
      "Progressive High Myopia",
      "Pathological Myopia",
    ],
  },
  {
    ui: "D047748",
    heading: "Pituitary ACTH Hypersecretion",
    tree_numbers: ["C10.228.140.617.738.250.725", "C19.700.355.800"],
    synonyms: ["Pituitary-Dependent Cushing Disease", "Overproduction Of Acth"],
  },
  {
    ui: "D047808",
    heading: "Adrenogenital Syndrome",
    tree_numbers: ["C12.706.316.090", "C13.351.875.253.090", "C16.131.939.316.129", "C19.391.119.090"],
  },
  {
    ui: "D047868",
    heading: "Pulmonary Sclerosing Hemangioma",
    tree_numbers: ["C04.588.894.797.520.933", "C08.381.540.867", "C08.785.520.867"],
    synonyms: ["Pneumocytoma"],
  },
  {
    ui: "D047928",
    heading: "Premature Birth",
    tree_numbers: ["C13.703.420.491.500"],
  },
  {
    ui: "D048070",
    heading: "Fetal Nutrition Disorders",
    tree_numbers: ["C13.703.277.677", "C18.654.521.625"],
  },
  {
    ui: "D048089",
    heading: "Pneumonia of Calves, Enzootic",
    tree_numbers: [
      "C01.150.252.620.300",
      "C01.748.085.630",
      "C01.748.610.540.530",
      "C08.381.677.540.300",
      "C08.730.085.630",
      "C08.730.610.540.530",
      "C22.196.090.675",
    ],
  },
  {
    ui: "D048090",
    heading: "Bovine Respiratory Disease Complex",
    tree_numbers: ["C01.748.085", "C08.730.085", "C22.196.090"],
  },
  {
    ui: "D048550",
    heading: "Hepatic Insufficiency",
    tree_numbers: ["C06.552.308"],
  },
  {
    ui: "D048629",
    heading: "Micronuclei, Chromosome-Defective",
    tree_numbers: ["A11.284.430.106.570", "A11.284.430.214.190.875.117.570", "C23.550.210.570", "G05.365.590.175.570"],
  },
  {
    ui: "D048909",
    heading: "Diabetes Complications",
    tree_numbers: ["C19.246.099"],
  },
  {
    ui: "D048949",
    heading: "Labor Pain",
    tree_numbers: ["C23.888.592.612.451"],
  },
  {
    ui: "D048968",
    heading: "Morning Sickness",
    tree_numbers: ["C13.703.407", "C23.888.821.937.049"],
  },
  {
    ui: "D049068",
    heading: "Plagiocephaly, Nonsynostotic",
    tree_numbers: ["C05.660.207.707.624", "C16.131.621.207.707.624"],
  },
  {
    ui: "D049188",
    heading: "Prenatal Injuries",
    tree_numbers: ["C13.703.824"],
  },
  {
    ui: "D049248",
    heading: "Decapitation",
    tree_numbers: ["C26.986.224"],
  },
  {
    ui: "D049288",
    heading: "Muscular Dystrophies, Limb-Girdle",
    tree_numbers: ["C05.651.534.500.280", "C10.668.491.175.500.149", "C16.320.577.280"],
    synonyms: ["Erb's Muscular Dystrophy", "Leyden-Mbius Muscular Dystrophy", "Limb Girdle Muscular Dystrophy"],
  },
  {
    ui: "D049290",
    heading: "Tay-Sachs Disease, AB Variant",
    tree_numbers: [
      "C10.228.140.163.100.435.825.300.300.750",
      "C16.320.565.189.435.825.300.300.750",
      "C16.320.565.398.641.803.350.300.925",
      "C16.320.565.595.554.825.300.300.920",
      "C18.452.132.100.435.825.300.300.750",
      "C18.452.584.687.803.350.300.925",
      "C18.452.648.189.435.825.300.300.750",
      "C18.452.648.398.641.803.350.300.925",
      "C18.452.648.595.554.825.300.300.920",
    ],
    synonyms: ["Tay-Sachs Disease Ab Variant", "Tay-Sachs Disease, Variant Ab"],
  },
  {
    ui: "D049291",
    heading: "Seroma",
    tree_numbers: ["C23.550.470.640"],
  },
  {
    ui: "D049292",
    heading: "Leukoaraiosis",
    tree_numbers: ["C23.550.522"],
  },
  {
    ui: "D049309",
    heading: "Acanthoma",
    tree_numbers: ["C04.557.470.700.040", "C04.588.805.040"],
    synonyms: ["Pale (Clear Cell) Acanthoma", "Clear Cell Acanthoma"],
  },
  {
    ui: "D049310",
    heading: "Distal Myopathies",
    tree_numbers: ["C05.651.534.500.074", "C10.668.491.175.500.074", "C16.320.577.074"],
    synonyms: [
      "Udd Type Distal Myopathy",
      "Tardive Tibial Muscular Dystrophy",
      "Udd Myopathy",
      "Tmd",
      "Finnish Tibial Muscular Dystrophy",
      "Distal Titinopathy",
    ],
  },
  {
    ui: "D049328",
    heading: "Mongolian Spot",
    tree_numbers: ["C04.557.665.560.615.530"],
    synonyms: ["Mongolian Macula"],
  },
  {
    ui: "D049912",
    heading: "Growth Hormone-Secreting Pituitary Adenoma",
    tree_numbers: [
      "C04.557.470.035.415",
      "C04.588.322.609.292",
      "C10.228.140.617.738.675.299",
      "C19.344.609.292",
      "C19.700.734.292",
    ],
    synonyms: [
      "Somatotroph Adenoma",
      "Growth Hormone Producing Adenoma Of The Pituitary",
      "Growth Hormone Secreting Adenoma Of Pituitary",
    ],
  },
  {
    ui: "D049913",
    heading: "ACTH-Secreting Pituitary Adenoma",
    tree_numbers: [
      "C04.557.470.035.012",
      "C04.588.322.609.145",
      "C10.228.140.617.738.675.149",
      "C19.344.609.145",
      "C19.700.734.145",
    ],
    synonyms: ["Corticotroph Adenoma", "Corticotropinoma", "Acth-Producing Pituitary Adenoma"],
  },
  {
    ui: "D049914",
    heading: "DNA Repair-Deficiency Disorders",
    tree_numbers: ["C18.452.284"],
  },
  {
    ui: "D049932",
    heading: "Nijmegen Breakage Syndrome",
    tree_numbers: ["C18.452.284.600"],
    synonyms: [
      "Microcephaly-Immunodeficiency-Lymphoreticuloma Syndrome",
      "Nbs",
      "Berlin Breakage Syndrome",
      "Immunodeficiency-Microcephaly-Chromosomal Instability Syndrome",
      "Microcephaly, Normal Intelligence And Immunodeficiency",
      "Seemanova Syndrome Ii",
      "Ataxia-Telangiectasia Variant",
      "Seemanova Syndrome Type 2",
    ],
  },
  {
    ui: "D049950",
    heading: "Hyperparathyroidism, Primary",
    tree_numbers: ["C19.642.355.239"],
    synonyms: ["Familial Primary Hyperparathyroidism"],
  },
  {
    ui: "D049970",
    heading: "Graves Ophthalmopathy",
    tree_numbers: [
      "C11.270.240",
      "C11.675.349.500.500",
      "C16.320.347",
      "C19.874.283.605.500",
      "C19.874.397.370.500",
      "C20.111.555.500",
    ],
  },
  {
    ui: "D050010",
    heading: "Hyperthyroxinemia, Familial Dysalbuminemic",
    tree_numbers: ["C16.320.427", "C19.874.410.249"],
  },
  {
    ui: "D050030",
    heading: "Andersen Syndrome",
    tree_numbers: ["C14.280.067.565.070", "C14.280.123.625.070", "C16.131.240.400.715.070", "C23.550.073.547.070"],
    synonyms: [
      "Lqt7",
      "Potassium-Sensitive Cardiodysrhythmic Type",
      "Andersen Cardiodysrhythmic Periodic Paralysis",
      "Long Qt Syndrome 7",
      "Andersen Syndrome",
    ],
  },
  {
    ui: "D050031",
    heading: "Hashimoto Disease",
    tree_numbers: ["C19.874.871.102.500"],
  },
  {
    ui: "D050032",
    heading: "Postpartum Thyroiditis",
    tree_numbers: ["C13.703.844.714", "C19.874.871.102.750"],
  },
  {
    ui: "D050033",
    heading: "Thyroid Dysgenesis",
    tree_numbers: ["C16.131.894", "C19.874.689"],
  },
  {
    ui: "D050035",
    heading: "Sexual Infantilism",
    tree_numbers: [
      "C12.706.316.309.631",
      "C13.351.875.253.309.631",
      "C16.131.939.316.309.631",
      "C19.391.119.309.631",
      "C19.391.482.814",
    ],
  },
  {
    ui: "D050072",
    heading: "White Heifer Disease",
    tree_numbers: ["C22.196.963"],
  },
  {
    ui: "D050090",
    heading: "Ovotesticular Disorders of Sex Development",
    tree_numbers: ["C12.706.316.343", "C13.351.875.253.343", "C16.131.939.316.343", "C19.391.119.343"],
  },
  {
    ui: "D050171",
    heading: "Dyslipidemias",
    tree_numbers: ["C18.452.584.500"],
  },
  {
    ui: "D050174",
    heading: "Genetic Diseases, Y-Linked",
    tree_numbers: ["C16.320.338"],
  },
  {
    ui: "D050177",
    heading: "Overweight",
    tree_numbers: ["C23.888.144.699", "E01.370.600.115.100.160.120.699", "G07.100.100.160.120.699"],
  },
  {
    ui: "D050197",
    heading: "Atherosclerosis",
    tree_numbers: ["C14.907.137.126.307"],
    synonyms: ["Cardiovascular Arteriosclerosis"],
  },
  {
    ui: "D050336",
    heading: "Mulibrey Nanism",
    tree_numbers: ["C05.116.099.343.796", "C16.320.240.875"],
    synonyms: [
      "Pericardial Constriction And Growth Failure",
      "Muscle-Liver-Brain-Eye Nanism",
      "Perheentupa Syndrome",
      "Mul",
      "Mulibrey Growth Disorder",
    ],
  },
  {
    ui: "D050379",
    heading: "Arteriolosclerosis",
    tree_numbers: ["C14.907.137.126.056"],
    synonyms: ["Arteriolosclerosis"],
  },
  {
    ui: "D050380",
    heading: "Monckeberg Medial Calcific Sclerosis",
    tree_numbers: ["C18.452.174.130.780.500"],
  },
  {
    ui: "D050398",
    heading: "Adamantinoma",
    tree_numbers: ["C04.588.149.030", "C05.116.231.030"],
    synonyms: ["Adamantinoma Of Long Bones"],
  },
  {
    ui: "D050488",
    heading: "Hallux Varus",
    tree_numbers: ["C05.330.612"],
  },
  {
    ui: "D050489",
    heading: "Bunion, Tailor's",
    tree_numbers: ["C05.330.488.050.500"],
  },
  {
    ui: "D050497",
    heading: "Stillbirth",
    tree_numbers: ["C13.703.223.650", "C23.550.260.585.630", "G08.686.784.769.496.500"],
  },
  {
    ui: "D050500",
    heading: "Pancreatitis, Chronic",
    tree_numbers: ["C06.689.750.830"],
  },
  {
    ui: "D050723",
    heading: "Fractures, Bone",
    tree_numbers: ["C26.404"],
  },
  {
    ui: "D050724",
    heading: "Fractures, Cartilage",
    tree_numbers: ["C26.411"],
  },
  {
    ui: "D050738",
    heading: "Mesomycetozoea Infections",
    tree_numbers: ["C01.610.600"],
  },
  {
    ui: "D050798",
    heading: "SUNCT Syndrome",
    tree_numbers: ["C10.228.140.546.399.937.875"],
  },
  {
    ui: "D050805",
    heading: "Wandering Spleen",
    tree_numbers: ["C15.604.744.954"],
  },
  {
    ui: "D050815",
    heading: "Fractures, Compression",
    tree_numbers: ["C26.404.195"],
  },
  {
    ui: "D051261",
    heading: "Granulomatosis, Orofacial",
    tree_numbers: ["C07.465.353", "C23.550.384"],
  },
  {
    ui: "D051270",
    heading: "Headache Disorders, Primary",
    tree_numbers: ["C10.228.140.546.399"],
  },
  {
    ui: "D051271",
    heading: "Headache Disorders, Secondary",
    tree_numbers: ["C10.228.140.546.699"],
  },
  {
    ui: "D051298",
    heading: "Post-Traumatic Headache",
    tree_numbers: ["C10.228.140.546.699.249"],
  },
  {
    ui: "D051299",
    heading: "Post-Dural Puncture Headache",
    tree_numbers: ["C10.228.140.546.699.124"],
  },
  {
    ui: "D051302",
    heading: "Paroxysmal Hemicrania",
    tree_numbers: ["C10.228.140.546.399.937.750"],
  },
  {
    ui: "D051303",
    heading: "Trigeminal Autonomic Cephalalgias",
    tree_numbers: ["C10.228.140.546.399.937"],
  },
  {
    ui: "D051346",
    heading: "Mobility Limitation",
    tree_numbers: ["C23.888.550"],
  },
  {
    ui: "D051359",
    heading: "Lymphohistiocytosis, Hemophagocytic",
    tree_numbers: ["C15.604.250.410.575"],
    synonyms: ["Haemophagocytic Syndrome"],
  },
  {
    ui: "D051436",
    heading: "Renal Insufficiency, Chronic",
    tree_numbers: ["C12.777.419.780.750", "C13.351.968.419.780.750"],
  },
  {
    ui: "D051437",
    heading: "Renal Insufficiency",
    tree_numbers: ["C12.777.419.780", "C13.351.968.419.780"],
    synonyms: ["Renal Failure"],
  },
  {
    ui: "D051474",
    heading: "Neuralgia, Postherpetic",
    tree_numbers: ["C10.668.829.600.550", "C23.888.592.612.664.550"],
  },
  {
    ui: "D051516",
    heading: "Hemospermia",
    tree_numbers: ["C12.294.293"],
  },
  {
    ui: "D051527",
    heading: "Odontogenic Tumor, Squamous",
    tree_numbers: ["C04.557.695.607"],
  },
  {
    ui: "D051556",
    heading: "Hyperbilirubinemia, Neonatal",
    tree_numbers: ["C16.614.451", "C23.550.429.249"],
  },
  {
    ui: "D051642",
    heading: "Histiocytoma",
    tree_numbers: ["C04.557.450.565.590.425"],
  },
  {
    ui: "D051677",
    heading: "Histiocytoma, Malignant Fibrous",
    tree_numbers: ["C04.557.450.565.590.425.360", "C04.557.450.795.400"],
    synonyms: ["Mfh", "Fibroxanthosarcoma"],
  },
  {
    ui: "D051799",
    heading: "Delayed Graft Function",
    tree_numbers: ["C23.550.277"],
  },
  {
    ui: "D052016",
    heading: "Mucositis",
    tree_numbers: ["C06.405.205.798", "C07.465.584"],
  },
  {
    ui: "D052018",
    heading: "Bulimia Nervosa",
    tree_numbers: ["F03.400.250"],
  },
  {
    ui: "D052065",
    heading: "Vaginismus",
    tree_numbers: ["C13.351.500.665.656", "C13.351.500.894.870", "F03.835.900"],
  },
  {
    ui: "D052120",
    heading: "Glycogen Storage Disease Type IIb",
    tree_numbers: [
      "C10.597.606.360.455.562",
      "C14.280.238.458",
      "C16.320.322.201",
      "C16.320.565.202.449.510",
      "C18.452.648.202.449.510",
    ],
    synonyms: ["Antopol Disease", "Pseudoglycogenosis Ii"],
  },
  {
    ui: "D052159",
    heading: "Frasier Syndrome",
    tree_numbers: [
      "C12.706.316.096.624",
      "C12.777.419.780.750.500.500",
      "C13.351.875.253.096.624",
      "C13.351.968.419.780.750.500.500",
      "C16.131.939.316.096.624",
      "C16.320.306",
      "C19.391.119.096.624",
    ],
  },
  {
    ui: "D052177",
    heading: "Kidney Diseases, Cystic",
    tree_numbers: ["C12.777.419.403", "C13.351.968.419.403"],
    synonyms: ["Renal Cyst"],
  },
  {
    ui: "D052178",
    heading: "Cephalopelvic Disproportion",
    tree_numbers: ["C13.703.420.235"],
  },
  {
    ui: "D052202",
    heading: "Hydrocolpos",
    tree_numbers: ["C13.351.500.894.500"],
  },
  {
    ui: "D052245",
    heading: "Usher Syndromes",
    tree_numbers: [
      "C09.218.458.341.186.500.500",
      "C09.218.458.341.887.886",
      "C10.597.751.418.341.186.500.500",
      "C10.597.751.418.341.887.886",
      "C10.597.751.941.162.625.500",
      "C11.768.585.658.500.813",
      "C11.966.075.375.500",
      "C16.131.077.299.500",
      "C16.320.290.684.500",
      "C23.888.592.763.393.341.887.886",
    ],
    synonyms: ["Ush3"],
  },
  {
    ui: "D052256",
    heading: "Tendinopathy",
    tree_numbers: ["C05.651.869", "C26.874.800"],
  },
  {
    ui: "D052439",
    heading: "Lipid Metabolism Disorders",
    tree_numbers: ["C18.452.584"],
  },
  {
    ui: "D052456",
    heading: "Hypoalphalipoproteinemias",
    tree_numbers: ["C16.320.565.398.500.330", "C18.452.584.500.875.330", "C18.452.648.398.500.330"],
  },
  {
    ui: "D052476",
    heading: "Hypobetalipoproteinemia, Familial, Apolipoprotein B",
    tree_numbers: ["C18.452.584.500.875.440.750"],
  },
  {
    ui: "D052496",
    heading: "Lipodystrophy, Familial Partial",
    tree_numbers: ["C16.320.488.813", "C17.800.849.391.700", "C18.452.584.625.700", "C18.452.880.391.700"],
    synonyms: ["Koberling-Dunnigan Syndrome", "Dunnigan Syndrome"],
  },
  {
    ui: "D052497",
    heading: "Lipodystrophy, Congenital Generalized",
    tree_numbers: [
      "C16.320.565.398.745",
      "C17.800.849.391.550",
      "C18.452.584.625.550",
      "C18.452.648.398.745",
      "C18.452.880.391.550",
    ],
  },
  {
    ui: "D052516",
    heading: "Sulfatidosis",
    tree_numbers: [
      "C10.228.140.163.100.435.825.850",
      "C16.320.565.189.435.825.850",
      "C16.320.565.398.641.803.925",
      "C16.320.565.595.554.825.850",
      "C18.452.132.100.435.825.850",
      "C18.452.584.687.803.925",
      "C18.452.648.189.435.825.850",
      "C18.452.648.398.641.803.925",
      "C18.452.648.595.554.825.850",
    ],
  },
  {
    ui: "D052517",
    heading: "Multiple Sulfatase Deficiency Disease",
    tree_numbers: [
      "C10.228.140.163.100.435.825.850.750",
      "C16.320.565.189.435.825.850.750",
      "C16.320.565.398.641.803.925.750",
      "C16.320.565.595.554.825.850.750",
      "C18.452.132.100.435.825.850.750",
      "C18.452.584.687.803.925.750",
      "C18.452.648.189.435.825.850.750",
      "C18.452.648.398.641.803.925.750",
      "C18.452.648.595.554.825.850.750",
    ],
    synonyms: ["Multiple Sulfatase Deficiency Disease", "Sulfatidosis, Juvenile, Austin Type"],
  },
  {
    ui: "D052536",
    heading: "Niemann-Pick Disease, Type A",
    tree_numbers: [
      "C10.228.140.163.100.435.825.700.500",
      "C15.604.250.410.625.500",
      "C16.320.565.189.435.825.700.500",
      "C16.320.565.398.641.803.730.500",
      "C16.320.565.595.554.825.700.500",
      "C18.452.132.100.435.825.700.500",
      "C18.452.584.687.803.730.500",
      "C18.452.648.189.435.825.700.500",
      "C18.452.648.398.641.803.730.500",
      "C18.452.648.595.554.825.700.500",
    ],
  },
  {
    ui: "D052537",
    heading: "Niemann-Pick Disease, Type B",
    tree_numbers: [
      "C10.228.140.163.100.435.825.700.750",
      "C15.604.250.410.625.750",
      "C16.320.565.189.435.825.700.750",
      "C16.320.565.398.641.803.730.750",
      "C16.320.565.595.554.825.700.750",
      "C18.452.132.100.435.825.700.750",
      "C18.452.584.687.803.730.750",
      "C18.452.648.189.435.825.700.750",
      "C18.452.648.398.641.803.730.750",
      "C18.452.648.595.554.825.700.750",
    ],
  },
  {
    ui: "D052556",
    heading: "Niemann-Pick Disease, Type C",
    tree_numbers: [
      "C10.228.140.163.100.435.825.700.875",
      "C15.604.250.410.625.875",
      "C16.320.565.189.435.825.700.875",
      "C16.320.565.398.641.803.730.875",
      "C16.320.565.595.554.825.700.875",
      "C18.452.132.100.435.825.700.875",
      "C18.452.584.687.803.730.875",
      "C18.452.648.189.435.825.700.875",
      "C18.452.648.398.641.803.730.875",
      "C18.452.648.595.554.825.700.875",
    ],
  },
  {
    ui: "D052582",
    heading: "Trigger Finger Disorder",
    tree_numbers: ["C05.651.869.816.800"],
  },
  {
    ui: "D052776",
    heading: "Female Urogenital Diseases",
    tree_numbers: ["C13.351"],
  },
  {
    ui: "D052798",
    heading: "Balanitis Xerotica Obliterans",
    tree_numbers: ["C12.294.494.136.500"],
    synonyms: ["Penile Leukoplakia", "Kraurosis Of Penis"],
  },
  {
    ui: "D052801",
    heading: "Male Urogenital Diseases",
    tree_numbers: ["C12"],
  },
  {
    ui: "D052858",
    heading: "Cystocele",
    tree_numbers: ["C12.777.829.707", "C13.351.968.829.508", "C23.300.842.624.249"],
  },
  {
    ui: "D052878",
    heading: "Urolithiasis",
    tree_numbers: ["C12.777.967", "C13.351.968.967"],
  },
  {
    ui: "D052879",
    heading: "Subacute Combined Degeneration",
    tree_numbers: [
      "C10.228.854.811",
      "C10.314.875",
      "C10.574.910",
      "C18.654.521.500.133.699.923.640",
      "C23.550.737.625",
    ],
  },
  {
    ui: "D052880",
    heading: "Pyomyositis",
    tree_numbers: ["C01.830.767", "C05.651.594.909", "C10.668.491.562.787"],
    synonyms: ["Tropical Pyomyositis"],
  },
  {
    ui: "D052919",
    heading: "Refsum Disease, Infantile",
    tree_numbers: [
      "C10.228.140.163.100.844",
      "C16.320.565.189.844",
      "C16.320.565.663.865",
      "C18.452.132.100.844",
      "C18.452.648.189.844",
      "C18.452.648.663.865",
    ],
    synonyms: ["Infantile Phytanic Acid Storage Disease"],
  },
  {
    ui: "D052958",
    heading: "Tarlov Cysts",
    tree_numbers: ["C04.182.872", "C10.668.829.900"],
  },
  {
    ui: "D053018",
    heading: "Pyonephrosis",
    tree_numbers: ["C12.777.419.307.500", "C13.351.968.419.307.500"],
  },
  {
    ui: "D053039",
    heading: "Ureterolithiasis",
    tree_numbers: ["C12.777.725.938", "C12.777.967.374", "C13.351.968.725.938", "C13.351.968.967.374"],
    synonyms: ["Ureteric Stone", "Calculus Of Ureter", "Ureteric Calculus"],
  },
  {
    ui: "D053040",
    heading: "Nephrolithiasis",
    tree_numbers: ["C12.777.419.600", "C12.777.967.249", "C13.351.968.419.600", "C13.351.968.967.249"],
  },
  {
    ui: "D053098",
    heading: "Familial Hypophosphatemic Rickets",
    tree_numbers: [
      "C05.116.198.816.875.500",
      "C12.777.419.815.647.500",
      "C13.351.968.419.815.647.500",
      "C16.320.565.618.544.500",
      "C16.320.831.647.500",
      "C18.452.104.816.875.500",
      "C18.452.174.845.875.500",
      "C18.452.648.618.544.500",
      "C18.452.750.400.500.500",
      "C18.452.750.400.750.500",
      "C18.654.521.500.133.770.734.875.500",
    ],
    synonyms: [
      "Vitamin D-Resistant Rickets, X-Linked",
      "X-Linked Hypophosphatemia",
      "Hypophosphatemic Rickets X-Linked Dominant",
      "Hypophosphatemia, Vitamin D-Resistant Rickets",
    ],
  },
  {
    ui: "D053099",
    heading: "Azotemia",
    tree_numbers: ["C12.777.419.936.231", "C13.351.968.419.936.231", "C23.550.145"],
  },
  {
    ui: "D053120",
    heading: "Respiratory Aspiration",
    tree_numbers: ["C08.618.749", "C23.550.773"],
  },
  {
    ui: "D053158",
    heading: "Nocturia",
    tree_numbers: ["C23.888.942.343.550"],
  },
  {
    ui: "D053159",
    heading: "Dysuria",
    tree_numbers: ["C23.888.942.343.274"],
  },
  {
    ui: "D053201",
    heading: "Urinary Bladder, Overactive",
    tree_numbers: ["C12.777.829.866", "C13.351.968.829.813", "C23.888.942.343.780"],
  },
  {
    ui: "D053202",
    heading: "Urinary Incontinence, Urge",
    tree_numbers: ["C12.777.934.852.500", "C13.351.968.934.814.750", "C23.888.942.343.800.750"],
  },
  {
    ui: "D053206",
    heading: "Nocturnal Enuresis",
    tree_numbers: ["C12.777.934.284.500", "C13.351.968.934.252.500", "F01.145.126.856.500", "F03.388.400.500"],
  },
  {
    ui: "D053207",
    heading: "Diurnal Enuresis",
    tree_numbers: ["C12.777.934.284.249", "C13.351.968.934.252.249", "F01.145.126.856.249", "F03.388.400.249"],
  },
  {
    ui: "D053306",
    heading: "Hyper-IgM Immunodeficiency Syndrome",
    tree_numbers: ["C15.378.147.333.249", "C16.320.413", "C16.320.798.625", "C20.673.430.250", "C20.673.795.625"],
    synonyms: ["Hyperimmunoglobulin M Syndrome"],
  },
  {
    ui: "D053307",
    heading: "Hyper-IgM Immunodeficiency Syndrome, Type 1",
    tree_numbers: [
      "C15.378.147.333.249.500",
      "C16.320.322.237",
      "C16.320.798.625.500",
      "C20.673.430.250.500",
      "C20.673.795.625.500",
    ],
  },
  {
    ui: "D053358",
    heading: "Ectodermal Dysplasia 1, Anhidrotic",
    tree_numbers: [
      "C16.131.077.350.198",
      "C16.131.831.350.198",
      "C16.320.322.116",
      "C16.320.850.250.198",
      "C17.800.804.350.198",
      "C17.800.827.250.198",
    ],
    synonyms: [
      "Hed1",
      "Ed1",
      "Cst Syndrome",
      "Christ-Siemens-Touraine Syndrome",
      "Ectodermal Dysplasia 1, Hypohidrotic, X-Linked",
      "Ectodermal Dysplasia 1, Hypohidrotic/hair/tooth Type, X-Linked",
      "Ectodermal Dysplasia 1, Anhidrotic",
      "Hypohidrotic Ectodermal Dysplasia, X-Linked",
      "Xhed",
      "X-Linked Anhidrotic Ectodermal Dysplasia",
      "Xlhed",
    ],
  },
  {
    ui: "D053359",
    heading: "Ectodermal Dysplasia 3, Anhidrotic",
    tree_numbers: [
      "C16.131.077.350.298",
      "C16.131.831.350.298",
      "C16.320.850.250.298",
      "C17.800.804.350.298",
      "C17.800.827.250.298",
    ],
  },
  {
    ui: "D053360",
    heading: "Ectodermal Dysplasia, Hypohidrotic, Autosomal Recessive",
    tree_numbers: [
      "C16.131.077.350.348",
      "C16.131.831.350.348",
      "C16.320.850.250.348",
      "C17.800.804.350.348",
      "C17.800.827.250.348",
    ],
  },
  {
    ui: "D053421",
    heading: "Hand-Arm Vibration Syndrome",
    tree_numbers: ["C10.668.829.380", "C14.907.440", "C24.400"],
  },
  {
    ui: "D053447",
    heading: "Channelopathies",
    tree_numbers: ["C23.550.177"],
  },
  {
    ui: "D053448",
    heading: "Prostatism",
    tree_numbers: ["C23.888.942.343.600"],
  },
  {
    ui: "D053489",
    heading: "Mink Viral Enteritis",
    tree_numbers: ["C01.925.256.700.550", "C22.557"],
  },
  {
    ui: "D053529",
    heading: "Leukokeratosis, Hereditary Mucosal",
    tree_numbers: ["C16.320.850.542", "C17.800.827.595"],
    synonyms: ["White Sponge Nevus Of Cannon", "White Sponge Nevus"],
  },
  {
    ui: "D053546",
    heading: "Keratoderma, Palmoplantar, Epidermolytic",
    tree_numbers: ["C16.320.850.475.440.500", "C17.800.428.435.440.500", "C17.800.827.475.440.500"],
  },
  {
    ui: "D053549",
    heading: "Pachyonychia Congenita",
    tree_numbers: [
      "C16.131.077.350.856",
      "C16.131.831.350.856",
      "C16.320.850.250.856",
      "C17.800.529.594",
      "C17.800.804.350.856",
      "C17.800.827.250.856",
    ],
    synonyms: [
      "Jadassohn-Lewandowsky Syndrome",
      "Jackson-Lawler Type Pachyonychia Congenita",
      "Pachyonychia Congenita Type 1",
      "Pachyonychia Congenita, Jadassohn-Lewandowsky Type",
    ],
  },
  {
    ui: "D053559",
    heading: "Corneal Dystrophy, Juvenile Epithelial of Meesmann",
    tree_numbers: ["C11.204.236.218", "C11.270.162.218", "C16.320.290.162.204"],
    synonyms: ["Stocker-Holt Dystrophy", "Juvenile Hereditary Epithelial Dystrophy", "Mecd"],
  },
  {
    ui: "D053560",
    heading: "Ichthyosis Bullosa of Siemens",
    tree_numbers: [
      "C16.131.831.512.408",
      "C16.320.850.402",
      "C16.614.492.410",
      "C17.800.428.333.330",
      "C17.800.804.512.405",
      "C17.800.827.403",
    ],
    synonyms: ["Superficial Epidermolytic Ichthyosis", "Bullous Type Ichthyosis", "Ichthyosis Bullosa Of Siemens"],
  },
  {
    ui: "D053565",
    heading: "Hypercalciuria",
    tree_numbers: ["C23.888.942.337"],
  },
  {
    ui: "D053570",
    heading: "Porcine Postweaning Multisystemic Wasting Syndrome",
    tree_numbers: ["C01.925.256.200.500", "C22.905.690"],
  },
  {
    ui: "D053578",
    heading: "Opsoclonus-Myoclonus Syndrome",
    tree_numbers: [
      "C04.588.614.550.600",
      "C04.730.856.596",
      "C10.228.758.500",
      "C10.292.562.831",
      "C10.574.781.662",
      "C10.597.350.500.500",
      "C11.590.725",
    ],
  },
  {
    ui: "D053579",
    heading: "Gitelman Syndrome",
    tree_numbers: ["C12.777.419.815.491", "C13.351.968.419.815.491", "C16.320.831.491"],
    synonyms: ["Hypomagnesemia-Hypokalemia, Primary Renotubular, With Hypocalciuria"],
  },
  {
    ui: "D053584",
    heading: "Urinoma",
    tree_numbers: ["C23.888.942.900"],
  },
  {
    ui: "D053589",
    heading: "Nuchal Cord",
    tree_numbers: ["C13.703.413", "C16.300.790"],
  },
  {
    ui: "D053591",
    heading: "Pain, Referred",
    tree_numbers: ["C23.888.592.612.888"],
  },
  {
    ui: "D053608",
    heading: "Stupor",
    tree_numbers: ["C10.597.606.358.800.500", "C23.888.592.604.359.800.500"],
  },
  {
    ui: "D053609",
    heading: "Lethargy",
    tree_numbers: ["C10.597.606.441", "C23.888.592.604.444", "F01.700.470"],
  },
  {
    ui: "D053627",
    heading: "Asthenozoospermia",
    tree_numbers: ["C12.294.365.700.253"],
  },
  {
    ui: "D053632",
    heading: "X-Linked Combined Immunodeficiency Diseases",
    tree_numbers: ["C16.320.322.968", "C16.320.798.750.500", "C16.614.815.500", "C20.673.795.750.500"],
    synonyms: [
      "Thymic Epithelial Hypoplasia",
      "Gamma Chain Deficiency",
      "Xscid",
      "X-Linked Severe Combined Immunodeficiency",
      "Scid-X1",
    ],
  },
  {
    ui: "D053653",
    heading: "Pyelocystitis",
    tree_numbers: [
      "C12.777.419.570.821.358",
      "C12.777.829.495.750",
      "C13.351.968.419.570.821.358",
      "C13.351.968.829.495.750",
    ],
  },
  {
    ui: "D053682",
    heading: "Tendon Entrapment",
    tree_numbers: ["C05.651.869.816"],
  },
  {
    ui: "D053684",
    heading: "De Quervain Disease",
    tree_numbers: ["C05.651.869.816.200"],
    synonyms: ["Radial Styloid Tenosynovitis", "Tenosynovitis, De Quervain's"],
  },
  {
    ui: "D053706",
    heading: "Typhlitis",
    tree_numbers: ["C01.463.850", "C06.405.205.596.750.500", "C06.405.469.110.708"],
  },
  {
    ui: "D053713",
    heading: "Azoospermia",
    tree_numbers: ["C12.294.365.700.380"],
    synonyms: ["Spermatogenic Failure"],
  },
  {
    ui: "D053714",
    heading: "Aspermia",
    tree_numbers: ["C12.294.365.700.126"],
  },
  {
    ui: "D053716",
    heading: "Female Athlete Triad Syndrome",
    tree_numbers: ["F03.400.937.500"],
  },
  {
    ui: "D053717",
    heading: "Pneumonia, Ventilator-Associated",
    tree_numbers: [
      "C01.248.250.500",
      "C01.748.610.300.500",
      "C08.381.677.300.500",
      "C08.730.610.300.500",
      "C23.550.291.875.500.500.500",
    ],
  },
  {
    ui: "D053718",
    heading: "Scleromyxedema",
    tree_numbers: ["C17.300.550.875"],
  },
  {
    ui: "D053821",
    heading: "Cardiovascular Infections",
    tree_numbers: ["C01.190", "C14.260"],
  },
  {
    ui: "D053836",
    heading: "DNA Degradation, Necrotic",
    tree_numbers: ["C23.550.717.273", "G05.200.220"],
  },
  {
    ui: "D053840",
    heading: "Brugada Syndrome",
    tree_numbers: ["C14.280.067.322", "C14.280.123.250", "C16.320.100"],
    synonyms: [
      "Dream Disease",
      "Sunds",
      "Sudden Unexplained Nocturnal Death Syndrome",
      "Brugada Type Idiopathic Ventricular Fibrillation",
      "Bangungut",
      "Pokkuri Death Syndrome",
    ],
  },
  {
    ui: "D053842",
    heading: "Microsatellite Instability",
    tree_numbers: ["C23.550.362.590", "G05.365.590.335.590", "G05.370.590"],
  },
  {
    ui: "D054000",
    heading: "Nevus, Sebaceous of Jadassohn",
    tree_numbers: ["C04.557.665.560.700", "C10.562.700", "C16.131.077.633"],
  },
  {
    ui: "D054019",
    heading: "Immune Reconstitution Inflammatory Syndrome",
    tree_numbers: ["C20.608"],
  },
  {
    ui: "D054038",
    heading: "Posterior Leukoencephalopathy Syndrome",
    tree_numbers: ["C10.228.140.631.500.500", "C10.228.140.695.875"],
  },
  {
    ui: "D054039",
    heading: "Onycholysis",
    tree_numbers: ["C17.800.529.478"],
  },
  {
    ui: "D054058",
    heading: "Acute Coronary Syndrome",
    tree_numbers: ["C14.280.647.124", "C14.907.585.124"],
  },
  {
    ui: "D054059",
    heading: "Coronary Occlusion",
    tree_numbers: ["C14.280.647.250.272", "C14.907.585.250.272"],
  },
  {
    ui: "D054060",
    heading: "Pulmonary Infarction",
    tree_numbers: ["C08.381.746.500", "C14.907.355.350.700.500", "C23.550.513.355.875", "C23.550.717.489.875"],
  },
  {
    ui: "D054061",
    heading: "Ischemic Contracture",
    tree_numbers: ["C05.550.323.734", "C05.651.180.531", "C05.651.197.734", "C14.907.303.531"],
    synonyms: ["Volkmann's Ischemic Contracture"],
  },
  {
    ui: "D054062",
    heading: "Deaf-Blind Disorders",
    tree_numbers: [
      "C09.218.458.341.186.500",
      "C10.597.751.418.341.186.500",
      "C10.597.751.941.162.625",
      "C11.966.075.375",
      "C16.131.077.299",
      "C23.888.592.763.393.341.186.500",
      "C23.888.592.763.941.162.625",
    ],
  },
  {
    ui: "D054066",
    heading: "Leukemia, Large Granular Lymphocytic",
    tree_numbers: ["C04.557.337.428.580.049", "C15.604.515.560.575.049", "C20.683.515.528.582.049"],
  },
  {
    ui: "D054067",
    heading: "Dihydropyrimidine Dehydrogenase Deficiency",
    tree_numbers: ["C16.320.565.798.183", "C18.452.648.798.183"],
    synonyms: [
      "Thymine-Uracilurea",
      "Dihydropyrimidine Dehydrogenase Deficiency",
      "Dihydrouracil Dehydrogenase Deficiency",
      "Familial Pyrimidinaemia",
    ],
  },
  {
    ui: "D054068",
    heading: "Livedo Reticularis",
    tree_numbers: ["C14.907.617.625", "C17.800.862.355", "C23.888.885.437"],
  },
  {
    ui: "D054069",
    heading: "Multiple Acyl Coenzyme A Dehydrogenase Deficiency",
    tree_numbers: ["C16.320.565.100.614", "C18.452.648.100.614", "C18.452.660.612"],
    synonyms: [
      "Electron Transfer Flavoprotein Deficiency",
      "Glutaric Acidemia Type 2",
      "Electron Transfer Flavoprotein Ubiquinone Oxidoreductase Deficiency",
      "Mad Deficiency",
      "Glutaric Aciduria Type 2",
      "Madd",
    ],
  },
  {
    ui: "D054070",
    heading: "Postthrombotic Syndrome",
    tree_numbers: ["C14.907.355.830.925.462", "C14.907.952.880"],
  },
  {
    ui: "D054078",
    heading: "Mevalonate Kinase Deficiency",
    tree_numbers: [
      "C10.228.140.163.100.593",
      "C15.378.147.542.319",
      "C16.320.382.750",
      "C16.320.565.189.593",
      "C16.320.565.663.430",
      "C18.452.132.100.593",
      "C18.452.648.189.593",
      "C18.452.648.663.430",
      "C20.683.460.319",
    ],
    synonyms: ["Mevalonate Kinase Deficiency"],
  },
  {
    ui: "D054079",
    heading: "Vascular Malformations",
    tree_numbers: ["C14.240.850", "C16.131.240.850"],
  },
  {
    ui: "D054080",
    heading: "Vein of Galen Malformations",
    tree_numbers: [
      "C10.228.140.300.520.500",
      "C10.500.190.500.500",
      "C14.240.850.750.295.500",
      "C14.240.850.875.500.500",
      "C14.907.150.295.500",
      "C14.907.253.560.400.500",
      "C16.131.240.850.750.295.500",
      "C16.131.240.850.875.500.500",
      "C16.131.666.190.500.500",
    ],
  },
  {
    ui: "D054081",
    heading: "Malformations of Cortical Development, Group II",
    tree_numbers: ["C10.500.507.450", "C16.131.666.507.450"],
  },
  {
    ui: "D054082",
    heading: "Lissencephaly",
    tree_numbers: ["C10.500.507.450.499", "C16.131.666.507.450.499"],
  },
  {
    ui: "D054083",
    heading: "Ectopia Cordis",
    tree_numbers: ["C14.240.400.422", "C16.131.240.400.422"],
  },
  {
    ui: "D054084",
    heading: "Myocardial Bridging",
    tree_numbers: ["C14.240.400.210.500", "C14.280.400.210.500", "C16.131.240.400.210.500"],
  },
  {
    ui: "D054091",
    heading: "Periventricular Nodular Heterotopia",
    tree_numbers: ["C10.500.507.450.750", "C16.131.666.507.450.750"],
    synonyms: ["Periventricular Heterotopia"],
  },
  {
    ui: "D054092",
    heading: "Foramen Ovale, Patent",
    tree_numbers: ["C14.240.400.560.375.258", "C14.280.400.560.375.258", "C16.131.240.400.560.375.258"],
    synonyms: [
      "Persistent Ostium Secundum",
      "Defect, Patent Or Persistent, Ostium Secundum",
      "Atrial Septal Defect Within Oval Fossa",
      "Ostium Secundum Type Atrial Septal Defect",
      "Foramen Ovale Patent",
    ],
  },
  {
    ui: "D054098",
    heading: "Thrombocytopenia, Neonatal Alloimmune",
    tree_numbers: ["C15.378.140.855.850", "C16.614.899"],
  },
  {
    ui: "D054119",
    heading: "Arachnodactyly",
    tree_numbers: ["C05.660.585.174", "C16.131.621.585.174"],
  },
  {
    ui: "D054138",
    heading: "Sinus Arrest, Cardiac",
    tree_numbers: ["C14.280.067.093.500", "C23.550.073.093.500"],
  },
  {
    ui: "D054139",
    heading: "Tachycardia, Reciprocating",
    tree_numbers: ["C14.280.067.845.787", "C14.280.123.875.787", "C23.550.073.845.787"],
  },
  {
    ui: "D054141",
    heading: "Ventricular Flutter",
    tree_numbers: ["C14.280.067.961", "C23.550.073.961"],
  },
  {
    ui: "D054143",
    heading: "Heart Failure, Systolic",
    tree_numbers: ["C14.280.434.676"],
  },
  {
    ui: "D054144",
    heading: "Heart Failure, Diastolic",
    tree_numbers: ["C14.280.434.611"],
  },
  {
    ui: "D054160",
    heading: "Systolic Murmurs",
    tree_numbers: ["C23.888.447.500"],
  },
  {
    ui: "D054179",
    heading: "Angioedemas, Hereditary",
    tree_numbers: [
      "C14.907.079.500",
      "C16.320.798.500.500",
      "C17.800.862.945.066.500",
      "C20.543.480.904.066.500",
      "C20.673.795.500.500",
    ],
    synonyms: ["Hereditary Angioneurotic Edema", "Hereditary Angioedema", "Hane"],
  },
  {
    ui: "D054198",
    heading: "Precursor Cell Lymphoblastic Leukemia-Lymphoma",
    tree_numbers: ["C04.557.337.428.600", "C15.604.515.560.600", "C20.683.515.528.600"],
  },
  {
    ui: "D054218",
    heading: "Precursor T-Cell Lymphoblastic Leukemia-Lymphoma",
    tree_numbers: ["C04.557.337.428.600.620", "C15.604.515.560.600.620", "C20.683.515.528.600.620"],
  },
  {
    ui: "D054219",
    heading: "Neoplasms, Plasma Cell",
    tree_numbers: ["C04.557.595"],
  },
  {
    ui: "D054220",
    heading: "Malformations of Cortical Development",
    tree_numbers: ["C10.500.507", "C16.131.666.507"],
  },
  {
    ui: "D054221",
    heading: "Classical Lissencephalies and Subcortical Band Heterotopias",
    tree_numbers: [
      "C10.500.507.450.230",
      "C10.500.507.450.499.230",
      "C16.131.666.507.450.230",
      "C16.131.666.507.450.499.230",
      "C16.320.322.500.186",
    ],
    synonyms: [
      "Subcortical Laminar Heterotopia",
      "Band Heterotopia",
      "Double Cortex Syndrome",
      "Heterotopic Cortex",
      "Heco",
    ],
  },
  {
    ui: "D054222",
    heading: "Cobblestone Lissencephaly",
    tree_numbers: [
      "C10.500.507.450.249",
      "C10.500.507.450.499.249",
      "C16.131.666.507.450.249",
      "C16.131.666.507.450.499.249",
    ],
  },
  {
    ui: "D054243",
    heading: "Vesicular Stomatitis",
    tree_numbers: ["C01.925.782.580.830.825", "C07.465.864.968", "C22.952"],
  },
  {
    ui: "D054312",
    heading: "Buruli Ulcer",
    tree_numbers: ["C01.150.252.410.040.552.475.247", "C17.800.893.295"],
    synonyms: ["Searl Ulcer", "Mossman Ulcer", "Searle's Ulcer", "Daintree Ulcer", "Bairnsdale Ulcer"],
  },
  {
    ui: "D054318",
    heading: "No-Reflow Phenomenon",
    tree_numbers: ["C23.550.513.677"],
  },
  {
    ui: "D054319",
    heading: "Marchiafava-Bignami Disease",
    tree_numbers: ["C10.228.140.163.510", "C10.314.475"],
  },
  {
    ui: "D054331",
    heading: "Sertoli Cell-Only Syndrome",
    tree_numbers: ["C12.294.365.700.754"],
    synonyms: ["Germinal Cell Aplasia", "Del Castillo Syndrome"],
  },
  {
    ui: "D054363",
    heading: "Solitary Fibrous Tumor, Pleural",
    tree_numbers: ["C04.557.450.565.590.797.750", "C04.588.894.797.640.800"],
    synonyms: ["Localized Benign Fibrous Mesothelioma", "Fibrous Mesothelioma, Benign"],
  },
  {
    ui: "D054364",
    heading: "Solitary Fibrous Tumors",
    tree_numbers: ["C04.557.450.565.590.797"],
  },
  {
    ui: "D054391",
    heading: "Lymphoma, Extranodal NK-T-Cell",
    tree_numbers: ["C04.557.386.480.750.199"],
  },
  {
    ui: "D054403",
    heading: "Leukemia, Prolymphocytic, B-Cell",
    tree_numbers: [
      "C04.557.337.428.080.562",
      "C04.557.337.428.565.745",
      "C15.604.515.560.080.562",
      "C15.604.515.560.550.745",
      "C20.683.515.528.080.562",
      "C20.683.515.528.565.745",
    ],
  },
  {
    ui: "D054429",
    heading: "Leukemia, Myelomonocytic, Juvenile",
    tree_numbers: ["C04.557.337.539.525", "C15.378.190.615.520"],
  },
  {
    ui: "D054437",
    heading: "Myelodysplastic-Myeloproliferative Diseases",
    tree_numbers: ["C15.378.190.615"],
    synonyms: ["Myelodysplastic Myeloproliferative Cancer", "Myeloproliferative/myelodysplastic Syndromes"],
  },
  {
    ui: "D054438",
    heading: "Leukemia, Myeloid, Chronic, Atypical, BCR-ABL Negative",
    tree_numbers: ["C04.557.337.539.300", "C15.378.190.615.500"],
    synonyms: [
      "Atypical Chronic Myeloid Leukemia Bcr-Abl1 Negative",
      "Atypical Chronic Myeloid Leukaemia",
      "Acml",
      "Atypical Cml",
      "Atypical Chronic Myeloid Leukaemia Bcr-Abl1 Negative",
    ],
  },
  {
    ui: "D054446",
    heading: "Lymphoma, Primary Cutaneous Anaplastic Large Cell",
    tree_numbers: ["C04.557.386.480.750.800.507", "C15.604.515.569.480.750.800.507", "C20.683.515.761.480.750.800.507"],
  },
  {
    ui: "D054463",
    heading: "Trichothiodystrophy Syndromes",
    tree_numbers: ["C16.131.077.899", "C16.131.831.874", "C16.320.850.895", "C17.800.804.874", "C17.800.827.895"],
    synonyms: [
      "Sulfur-Deficient Brittle Hair Syndrome",
      "Trichothiodystrophy With Congenital Ichthyosis",
      "Ibids Syndrome",
      "Tay Syndrome",
      "Ttd-P",
    ],
  },
  {
    ui: "D054506",
    heading: "Chloracne",
    tree_numbers: ["C17.800.030.575"],
  },
  {
    ui: "D054508",
    heading: "Acute Radiation Syndrome",
    tree_numbers: ["C26.733.188", "G01.750.748.500.188", "N06.850.460.350.850.500.158", "N06.850.810.300.360.158"],
  },
  {
    ui: "D054514",
    heading: "Persistent Hyperplastic Primary Vitreous",
    tree_numbers: ["C11.250.616", "C16.131.384.725"],
  },
  {
    ui: "D054515",
    heading: "Vulvar Vestibulitis",
    tree_numbers: ["C13.351.500.944.902.368"],
  },
  {
    ui: "D054517",
    heading: "Orbital Cellulitis",
    tree_numbers: ["C11.675.387", "C17.300.185.500"],
  },
  {
    ui: "D054537",
    heading: "Atrioventricular Block",
    tree_numbers: ["C14.280.067.558.230", "C14.280.123.500.230", "C23.550.073.425.062"],
    synonyms: ["Av Block"],
  },
  {
    ui: "D054546",
    heading: "Neuroacanthocytosis",
    tree_numbers: ["C10.228.662.262.249.937", "C16.320.400.550"],
    synonyms: ["Levine-Critchley Syndrome", "Choreo-Acanthocytosis"],
  },
  {
    ui: "D054549",
    heading: "Takotsubo Cardiomyopathy",
    tree_numbers: ["C14.280.945.900.500"],
  },
  {
    ui: "D054556",
    heading: "Venous Thromboembolism",
    tree_numbers: ["C14.907.355.590.700"],
  },
  {
    ui: "D054559",
    heading: "Hyperphosphatemia",
    tree_numbers: ["C18.452.750.199"],
  },
  {
    ui: "D054685",
    heading: "Lymphoma, Primary Effusion",
    tree_numbers: ["C04.557.386.480.150.592", "C15.604.515.569.480.150.592", "C20.683.515.761.480.150.592"],
  },
  {
    ui: "D054705",
    heading: "Mastocytoma, Skin",
    tree_numbers: [
      "C04.557.450.565.465.249.500",
      "C04.557.450.565.465.500.500",
      "C17.800.508.236.500",
      "C17.800.508.473.500",
    ],
  },
  {
    ui: "D054739",
    heading: "Dendritic Cell Sarcoma, Interdigitating",
    tree_numbers: ["C04.557.227.199", "C15.604.250.390.199"],
    synonyms: ["Interdigitating Cell Sarcoma"],
  },
  {
    ui: "D054740",
    heading: "Dendritic Cell Sarcoma, Follicular",
    tree_numbers: ["C04.557.227.190", "C15.604.250.390.190"],
    synonyms: ["Follicular Dendritic Cell Tumour", "Follicular Dendritic Cell Sarcoma"],
  },
  {
    ui: "D054747",
    heading: "Histiocytic Sarcoma",
    tree_numbers: ["C04.557.227.380", "C15.604.250.390.380"],
  },
  {
    ui: "D054752",
    heading: "Langerhans Cell Sarcoma",
    tree_numbers: ["C04.557.227.500", "C15.604.250.390.500"],
  },
  {
    ui: "D054850",
    heading: "Osteophyte",
    tree_numbers: ["C05.116.540.310.800"],
  },
  {
    ui: "D054853",
    heading: "Malignant Atrophic Papulosis",
    tree_numbers: ["C14.907.137.520", "C14.907.940.530", "C17.800.862.457"],
  },
  {
    ui: "D054868",
    heading: "Jacobsen Distal 11q Deletion Syndrome",
    tree_numbers: ["C15.378.140.855.440", "C16.131.260.440", "C16.320.180.440"],
    synonyms: [
      "Jacobsen Distal 11q Deletion Syndrome",
      "Partial 11q Monosomy Syndrome",
      "Chromosome 11q Deletion Syndrome",
    ],
  },
  {
    ui: "D054877",
    heading: "Wolf-Hirschhorn Syndrome",
    tree_numbers: ["C16.131.077.944", "C16.131.260.985", "C16.320.180.985"],
    synonyms: [
      "Pitt-Rogers-Danks Syndrome",
      "Chromosome 4p16.3 Deletion Syndrome",
      "Pitt Syndrome",
      "4p Deletion Syndrome",
    ],
  },
  {
    ui: "D054880",
    heading: "Aspartylglucosaminuria",
    tree_numbers: ["C16.320.565.595.100", "C18.452.648.595.100"],
    synonyms: ["Aspartylglycosaminuria", "Aspartylglucosaminidase Deficiency", "Glycosylasparaginase Deficiency"],
  },
  {
    ui: "D054882",
    heading: "Antley-Bixler Syndrome Phenotype",
    tree_numbers: [
      "C05.116.099.370.894.115",
      "C05.660.906.181",
      "C16.131.621.906.181",
      "C16.320.565.925.324",
      "C18.452.648.925.324",
    ],
  },
  {
    ui: "D054908",
    heading: "Extensively Drug-Resistant Tuberculosis",
    tree_numbers: ["C01.150.252.410.040.552.846.775.500"],
  },
  {
    ui: "D054969",
    heading: "Primary Dysautonomias",
    tree_numbers: ["C10.177.575"],
  },
  {
    ui: "D054970",
    heading: "Pure Autonomic Failure",
    tree_numbers: ["C10.177.575.650"],
  },
  {
    ui: "D054971",
    heading: "Orthostatic Intolerance",
    tree_numbers: ["C10.177.575.600", "C23.888.592.610"],
  },
  {
    ui: "D054972",
    heading: "Postural Orthostatic Tachycardia Syndrome",
    tree_numbers: ["C10.177.575.600.625"],
    synonyms: [
      "Irritable Heart",
      "Familial Orthostatic Tachycardia Due To Norepinephrine Transporter Deficiency",
      "Soldiers Heart",
      "Orthostatic Intolerance Due To Net Deficiency",
      "Postural Tachycardia Syndrome Due To Net Deficiency",
      "Orhtostatic Intolerance",
      "Mitral Valve Prolapse Syndrome",
      "Neurocirculatory Asthenia",
    ],
  },
  {
    ui: "D054973",
    heading: "Perivascular Epithelioid Cell Neoplasms",
    tree_numbers: ["C04.557.450.692"],
    synonyms: ["Neoplasm With Perivascular Epithelioid Cell Differentiation", "Pecoma"],
  },
  {
    ui: "D054975",
    heading: "Pallister-Hall Syndrome",
    tree_numbers: [
      "C04.445.622",
      "C04.588.614.250.195.885.500.299",
      "C05.660.585.600.374",
      "C10.228.140.211.885.500.299",
      "C10.228.140.617.477.299",
      "C10.551.240.250.700.500.249",
      "C16.131.077.690",
      "C16.131.621.585.600.374",
    ],
    synonyms: [
      "A Syndrome That Is Characterized By Hypothalamic Hamartoma, Pituitary Dysfunction, Central Polydactyly, And Visceral Malformations And Has_material_basis_in Autosomal Dominant Heterozygous Mutation In The Gli3 Gene On Chromosome 7p14.",
    ],
  },
  {
    ui: "D054988",
    heading: "Idiopathic Interstitial Pneumonias",
    tree_numbers: ["C08.381.483.487", "C08.381.765.500.750"],
  },
  {
    ui: "D054989",
    heading: "Nephrogenic Fibrosing Dermopathy",
    tree_numbers: ["C17.800.553", "C23.550.355.550"],
  },
  {
    ui: "D054990",
    heading: "Idiopathic Pulmonary Fibrosis",
    tree_numbers: ["C08.381.765.500"],
    synonyms: [
      "Idiopathic Pulmonary Fibrosis, Familial",
      "Cryptogenic Fibrosing Alveolitis",
      "Fibrocystic Pulmonary Dysplasia",
    ],
  },
  {
    ui: "D055008",
    heading: "Anthracosis",
    tree_numbers: ["C08.381.483.581.062", "C08.381.520.702.062"],
    synonyms: [
      "Coal Workers' Pneumoconiosis",
      "Melanoedema",
      "Black Lung",
      "Coal Workers' Lung",
      "Coal Miner's Pneumoconiosis",
    ],
  },
  {
    ui: "D055009",
    heading: "Spondylosis",
    tree_numbers: ["C05.116.900.938"],
    synonyms: [
      "Spondylosis With Myelopathy",
      "Spondylosis With Myelopathy",
      "Lumbar Spondylosis With Myelopathy",
      "Lumbosacral Spondylosis Without Myelopathy",
      "Thoracic Or Lumbar Spondylosis With Myelopathy",
      "Spondylogenic Compression Of Lumbar Spinal Cord",
      "Spondylogenic Compression Of Thoracic Spinal Cord",
    ],
  },
  {
    ui: "D055010",
    heading: "Posterior Cervical Sympathetic Syndrome",
    tree_numbers: ["C05.116.900.596"],
    synonyms: ["Cervicocranial Syndrome", "Posterior Cervical Sympathetic Syndrome"],
  },
  {
    ui: "D055013",
    heading: "Osteoarthritis, Spine",
    tree_numbers: ["C05.116.900.853.625.399", "C05.550.114.606.750", "C05.550.114.865.399", "C05.799.613.750"],
  },
  {
    ui: "D055031",
    heading: "Primary Graft Dysfunction",
    tree_numbers: ["C14.907.725.675", "C23.550.767.877.750"],
  },
  {
    ui: "D055034",
    heading: "Osteochondrosis",
    tree_numbers: ["C05.116.821"],
    synonyms: [
      "Apophysitis",
      "Osteochondrosis",
      "Osteochondritis Juvenilis",
      "Epiphysitis",
      "Osteochondritis",
      "Epiphyseal Necrosis",
    ],
  },
  {
    ui: "D055035",
    heading: "Spinal Osteochondrosis",
    tree_numbers: ["C05.116.821.500", "C05.116.900.808"],
  },
  {
    ui: "D055036",
    heading: "Campomelic Dysplasia",
    tree_numbers: ["C05.660.142", "C16.131.621.142"],
    synonyms: ["Acampomelic Campomelic Dysplasia"],
  },
  {
    ui: "D055089",
    heading: "Tracheobronchomalacia",
    tree_numbers: ["C05.182.895", "C08.127.719", "C08.907.796", "C16.131.621.953", "C17.300.182.895"],
  },
  {
    ui: "D055090",
    heading: "Tracheomalacia",
    tree_numbers: ["C05.182.895.500", "C08.907.796.500", "C16.131.621.953.500", "C17.300.182.895.500"],
  },
  {
    ui: "D055091",
    heading: "Bronchomalacia",
    tree_numbers: ["C05.182.895.249", "C08.127.719.500", "C16.131.621.953.249", "C17.300.182.895.249"],
  },
  {
    ui: "D055092",
    heading: "Laryngomalacia",
    tree_numbers: ["C05.182.310", "C08.360.563", "C09.400.563", "C16.131.621.568", "C17.300.182.310"],
  },
  {
    ui: "D055093",
    heading: "Periodontal Atrophy",
    tree_numbers: ["C07.465.714.354"],
  },
  {
    ui: "D055111",
    heading: "Failed Back Surgery Syndrome",
    tree_numbers: ["C23.550.767.225", "C23.888.592.612.107.200"],
  },
  {
    ui: "D055112",
    heading: "Pyometra",
    tree_numbers: ["C13.351.500.852.544"],
  },
  {
    ui: "D055113",
    heading: "Chronic Periodontitis",
    tree_numbers: ["C07.465.714.533.324"],
  },
  {
    ui: "D055154",
    heading: "Dysphonia",
    tree_numbers: ["C08.360.940.325", "C09.400.940.325", "C10.597.975.325", "C23.888.592.979.325"],
  },
  {
    ui: "D055191",
    heading: "Delayed Emergence from Anesthesia",
    tree_numbers: ["C23.550.767.137"],
  },
  {
    ui: "D055331",
    heading: "Adenomyoepithelioma",
    tree_numbers: ["C04.557.435.108"],
  },
  {
    ui: "D055370",
    heading: "Lung Injury",
    tree_numbers: ["C08.381.520", "C26.891.554"],
  },
  {
    ui: "D055371",
    heading: "Acute Lung Injury",
    tree_numbers: ["C08.381.520.500"],
  },
  {
    ui: "D055397",
    heading: "Ventilator-Induced Lung Injury",
    tree_numbers: ["C08.381.520.750"],
  },
  {
    ui: "D055496",
    heading: "Neurogenic Bowel",
    tree_numbers: ["C06.405.469.158.272.804"],
  },
  {
    ui: "D055499",
    heading: "Catheter-Related Infections",
    tree_numbers: ["C01.195"],
  },
  {
    ui: "D055501",
    heading: "Macrophage Activation Syndrome",
    tree_numbers: ["C20.683.515.800"],
  },
  {
    ui: "D055534",
    heading: "Bulbo-Spinal Atrophy, X-Linked",
    tree_numbers: [
      "C10.228.854.468.399",
      "C10.574.500.175",
      "C10.574.562.500.374",
      "C10.668.467.500.186",
      "C16.320.322.076",
      "C16.320.400.100",
    ],
    synonyms: [
      "Kennedy Disease",
      "Spinal Bulbar Muscular Atrophy",
      "Sbma",
      "X-Linked Bulbo-Spinal Atrophy",
      "Spinobulbar Muscular Atrophy",
      "X-Linked Spinal And Bulbar Muscular Atrophy",
    ],
  },
  {
    ui: "D055535",
    heading: "Morgellons Disease",
    tree_numbers: ["C17.800.518", "F03.700.412"],
  },
  {
    ui: "D055538",
    heading: "delta-Thalassemia",
    tree_numbers: ["C15.378.071.141.150.875.575", "C15.378.420.826.200", "C16.320.070.875.575", "C16.320.365.826.575"],
  },
  {
    ui: "D055577",
    heading: "Farber Lipogranulomatosis",
    tree_numbers: [
      "C10.228.140.163.100.435.825.250",
      "C16.320.565.189.435.825.250",
      "C16.320.565.398.641.803.325",
      "C16.320.565.595.554.825.250",
      "C18.452.132.100.435.825.250",
      "C18.452.584.687.803.325",
      "C18.452.648.189.435.825.250",
      "C18.452.648.398.641.803.325",
      "C18.452.648.595.554.825.250",
    ],
    synonyms: ["N-Laurylsphingosine Deacylase Deficiency", "Acid Ceramidase Deficiency", "Farber Disease"],
  },
  {
    ui: "D055589",
    heading: "Pancreatitis, Graft",
    tree_numbers: ["C06.689.750.860"],
  },
  {
    ui: "D055613",
    heading: "Multiple Pulmonary Nodules",
    tree_numbers: ["C04.588.894.797.520.237", "C08.381.540.148", "C08.785.520.148"],
  },
  {
    ui: "D055622",
    heading: "Orbital Myositis",
    tree_numbers: ["C05.651.594.728", "C10.668.491.562.537", "C11.675.426"],
  },
  {
    ui: "D055623",
    heading: "Keratosis, Actinic",
    tree_numbers: ["C04.834.450", "C17.800.428.570"],
    synonyms: ["Sk - Solar Keratosis", "Senile Hyperkeratosis", "Actinic (Solar) Keratosis", "Solar Keratosis"],
  },
  {
    ui: "D055652",
    heading: "Gray Platelet Syndrome",
    tree_numbers: ["C15.378.140.427", "C16.320.099.417"],
    synonyms: ["Bdplt17", "Hereditary Thrombasthenia-Thrombocytopenia"],
  },
  {
    ui: "D055653",
    heading: "Muir-Torre Syndrome",
    tree_numbers: [
      "C04.588.805.578.500",
      "C04.700.250.500.500",
      "C16.320.700.250.500.500",
      "C17.800.794.712.500",
      "C17.800.827.610",
      "C17.800.882.712.500",
    ],
  },
  {
    ui: "D055665",
    heading: "Purpura Fulminans",
    tree_numbers: ["C15.378.100.802.230", "C23.550.414.950.230", "C23.888.885.687.230"],
    synonyms: ["Purpura Gangrenosa"],
  },
  {
    ui: "D055673",
    heading: "Acrocallosal Syndrome",
    tree_numbers: ["C10.500.034.500", "C16.131.666.034.500"],
    synonyms: ["Schinzel Syndrome 1", "Schinzel Acrocallosal Syndrome", "Acls"],
  },
  {
    ui: "D055677",
    heading: "Refeeding Syndrome",
    tree_numbers: ["C18.654.521.687"],
  },
  {
    ui: "D055728",
    heading: "Primary Myelofibrosis",
    tree_numbers: ["C15.378.190.636.765"],
    synonyms: [
      "Agnogenic Myeloid Metaplasia",
      "Bone Marrow Fibrosis",
      "Myelosclerosis",
      "Megakaryocytic Myelosclerosis",
      "Aleukemic Myelosis",
      "Primary Myelofibrosis",
    ],
  },
  {
    ui: "D055732",
    heading: "Pulmonary Aspergillosis",
    tree_numbers: ["C01.150.703.080.768", "C01.150.703.534.850", "C08.381.472.850"],
  },
  {
    ui: "D055744",
    heading: "Invasive Pulmonary Aspergillosis",
    tree_numbers: ["C01.150.703.080.768.750", "C01.150.703.492.688", "C01.150.703.534.850.750", "C08.381.472.850.750"],
  },
  {
    ui: "D055752",
    heading: "Small Cell Lung Carcinoma",
    tree_numbers: ["C04.588.894.797.520.109.220.624", "C08.381.540.140.750", "C08.785.520.100.220.750"],
  },
  {
    ui: "D055756",
    heading: "Meningeal Carcinomatosis",
    tree_numbers: ["C04.588.614.250.580.150", "C10.551.240.500.150"],
  },
  {
    ui: "D055761",
    heading: "Reticuloendotheliosis, Avian",
    tree_numbers: ["C01.925.744", "C22.131.780"],
  },
  {
    ui: "D055847",
    heading: "Lynch Syndrome II",
    tree_numbers: ["C04.700.250.500", "C16.320.700.250.500"],
  },
  {
    ui: "D055882",
    heading: "Nevus, Halo",
    tree_numbers: ["C04.557.665.560.580"],
  },
  {
    ui: "D055947",
    heading: "Loeys-Dietz Syndrome",
    tree_numbers: [
      "C05.660.207.532",
      "C14.907.055.050.362",
      "C14.907.055.239.587",
      "C14.907.109.139.587",
      "C16.131.077.537",
      "C16.320.510",
    ],
  },
  {
    ui: "D055948",
    heading: "Sarcopenia",
    tree_numbers: ["C10.597.613.612.500", "C23.300.070.500.500", "C23.888.592.608.612.500"],
  },
  {
    ui: "D055949",
    heading: "Vasa Previa",
    tree_numbers: ["C13.703.420.952"],
  },
  {
    ui: "D055952",
    heading: "Cogan Syndrome",
    tree_numbers: ["C10.292.910.299", "C11.180", "C14.907.940.320"],
    synonyms: ["Cogan's Syndrome", "Diffuse Interstitual Keratitis"],
  },
  {
    ui: "D055953",
    heading: "Microscopic Polyangiitis",
    tree_numbers: ["C10.228.140.300.275.600", "C14.907.253.329.600", "C14.907.940.897.249.500", "C20.111.193.750"],
  },
  {
    ui: "D055954",
    heading: "Corneal Endothelial Cell Loss",
    tree_numbers: ["C11.204.278", "C11.300.250", "C23.550.767.093", "C23.888.307.250"],
  },
  {
    ui: "D055955",
    heading: "Susac Syndrome",
    tree_numbers: [
      "C09.218.855",
      "C10.228.140.300.787",
      "C10.597.825",
      "C11.768.400.500",
      "C11.966.858",
      "C14.907.137.780.500",
      "C23.888.307.750",
      "C23.888.592.848",
    ],
  },
  {
    ui: "D055956",
    heading: "Diffuse Neurofibrillary Tangles with Calcification",
    tree_numbers: ["C10.228.140.380.254", "C10.574.945.374", "F03.615.400.370"],
  },
  {
    ui: "D055958",
    heading: "Piriformis Muscle Syndrome",
    tree_numbers: [
      "C10.668.829.500.675.399",
      "C10.668.829.550.500",
      "C10.668.829.600.675",
      "C23.888.592.612.664.675",
      "C23.888.592.612.944.750",
    ],
  },
  {
    ui: "D055959",
    heading: "Intervertebral Disc Degeneration",
    tree_numbers: ["C05.116.900.153"],
  },
  {
    ui: "D055963",
    heading: "Asthma, Aspirin-Induced",
    tree_numbers: ["C08.127.108.054", "C08.674.095.054", "C20.543.206.189", "C20.543.480.149", "C25.100.468.189"],
  },
  {
    ui: "D055964",
    heading: "Alien Hand Syndrome",
    tree_numbers: ["C10.597.606.881.350.099", "C23.888.592.604.882.350.099", "F01.700.875.350.099"],
  },
  {
    ui: "D055985",
    heading: "Latent Tuberculosis",
    tree_numbers: ["C01.150.252.410.040.552.846.122", "C01.550.500"],
  },
  {
    ui: "D056005",
    heading: "Leprosy, Paucibacillary",
    tree_numbers: ["C01.150.252.410.040.552.475.371.850"],
  },
  {
    ui: "D056006",
    heading: "Leprosy, Multibacillary",
    tree_numbers: ["C01.150.252.410.040.552.475.371.775"],
  },
  {
    ui: "D056104",
    heading: "Commotio Cordis",
    tree_numbers: ["C14.280.067.441", "C26.891.375.750.500", "C26.974.250.875.500"],
  },
  {
    ui: "D056124",
    heading: "Slit Ventricle Syndrome",
    tree_numbers: ["C23.550.767.884", "C23.888.592.612.441.500"],
  },
  {
    ui: "D056128",
    heading: "Obesity, Abdominal",
    tree_numbers: ["C18.654.726.500.615", "E01.370.600.115.100.160.120.699.500.249", "G07.100.100.160.120.699.500.249"],
  },
  {
    ui: "D056146",
    heading: "Corpse Dismemberment",
    tree_numbers: ["C23.550.260.224.200", "C26.986.111", "I01.198.240.194"],
  },
  {
    ui: "D056150",
    heading: "Acute Generalized Exanthematous Pustulosis",
    tree_numbers: ["C17.800.174.600.174", "C20.543.206.380.174", "C25.100.468.380.174"],
  },
  {
    ui: "D056151",
    heading: "Airway Remodeling",
    tree_numbers: ["C23.300.017", "G09.772.029"],
  },
  {
    ui: "D056266",
    heading: "Erythrokeratodermia Variabilis",
    tree_numbers: ["C16.320.850.337", "C17.800.229.606", "C17.800.428.304", "C17.800.827.337"],
    synonyms: ["Erythrokeratodermia Figurata Variabilis", "Greither Disease"],
  },
  {
    ui: "D056267",
    heading: "Pagetoid Reticulosis",
    tree_numbers: [
      "C04.557.386.480.750.800.550.600",
      "C15.604.515.569.480.750.800.550.600",
      "C20.683.515.761.480.750.800.550.600",
    ],
  },
  {
    ui: "D056304",
    heading: "Genu Valgum",
    tree_numbers: ["C05.116.482"],
  },
  {
    ui: "D056305",
    heading: "Genu Varum",
    tree_numbers: ["C05.116.511"],
  },
  {
    ui: "D056364",
    heading: "Colloid Cysts",
    tree_numbers: [
      "C04.182.199",
      "C04.588.614.250.387.200",
      "C10.500.142.200",
      "C10.551.240.375.200",
      "C16.131.666.142.200",
    ],
  },
  {
    ui: "D056486",
    heading: "Chemical and Drug Induced Liver Injury",
    tree_numbers: ["C06.552.100", "C25.100.562", "C25.723.260"],
  },
  {
    ui: "D056487",
    heading: "Chemical and Drug Induced Liver Injury, Chronic",
    tree_numbers: ["C06.552.100.200", "C25.100.562.200", "C25.723.260.200"],
    synonyms: ["Drug-Induced Chronic Hepatitis"],
  },
  {
    ui: "D056586",
    heading: "Acute Chest Syndrome",
    tree_numbers: [
      "C08.381.074",
      "C08.618.009",
      "C15.378.071.141.150.150.219",
      "C15.378.420.155.219",
      "C16.320.070.150.219",
      "C16.320.365.155.219",
    ],
    synonyms: ["Acute Chest Syndrome In Sickle Cell Disease"],
  },
  {
    ui: "D056587",
    heading: "Cryopyrin-Associated Periodic Syndromes",
    tree_numbers: ["C16.320.382.500", "C17.800.827.368.500"],
    synonyms: ["Neutrophilic Urticaria", "Mws"],
  },
  {
    ui: "D056627",
    heading: "Peritoneal Fibrosis",
    tree_numbers: ["C06.844.610", "C23.550.355.625"],
  },
  {
    ui: "D056647",
    heading: "Systemic Vasculitis",
    tree_numbers: ["C14.907.940.897"],
  },
  {
    ui: "D056648",
    heading: "Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis",
    tree_numbers: ["C14.907.940.897.249", "C20.111.193"],
  },
  {
    ui: "D056650",
    heading: "Vulvodynia",
    tree_numbers: ["C13.351.500.944.951"],
  },
  {
    ui: "D056653",
    heading: "Rheumatoid Vasculitis",
    tree_numbers: [
      "C05.550.114.154.728",
      "C05.799.114.728",
      "C14.907.940.897.750",
      "C17.300.775.099.728",
      "C20.111.199.581",
    ],
  },
  {
    ui: "D056660",
    heading: "Hereditary Autoinflammatory Diseases",
    tree_numbers: ["C16.320.382", "C17.800.827.368"],
  },
  {
    ui: "D056684",
    heading: "Yellow Nail Syndrome",
    tree_numbers: ["C16.320.962", "C17.800.529.819", "C17.800.621.968", "C23.300.820.500"],
  },
  {
    ui: "D056685",
    heading: "Costello Syndrome",
    tree_numbers: ["C05.660.207.219", "C16.131.077.256", "C16.320.188"],
    synonyms: ["Faciocutaneoskeletal Syndrome", "Fcs Syndrome"],
  },
  {
    ui: "D056693",
    heading: "Propionic Acidemia",
    tree_numbers: ["C16.320.565.100.823", "C18.452.648.100.823"],
    synonyms: [
      "Ketotic Glycinemia",
      "Propionic Aciduria",
      "Ketotic Hyperglycinemia",
      "Ketotic Ii Glycinemia",
      "Glycinemia, Ketotic",
      "Propionyl-Coa Carboxylase Deficiency",
    ],
  },
  {
    ui: "D056725",
    heading: "von Willebrand Disease, Type 1",
    tree_numbers: ["C15.378.100.100.900.100", "C15.378.100.141.900.100", "C15.378.463.920.100", "C16.320.099.920.100"],
    synonyms: ["Vwd1", "Von Willebrand Disease Type 1", "Vwd Type 1", "Von Willebrand Disease Type I"],
  },
  {
    ui: "D056728",
    heading: "von Willebrand Disease, Type 2",
    tree_numbers: ["C15.378.100.100.900.200", "C15.378.100.141.900.200", "C15.378.463.920.200", "C16.320.099.920.200"],
    synonyms: ["Vwd2", "Vwd Type 2", "Von Willebrand Disease Type Ii", "Von Willebrand Disease Type 2"],
  },
  {
    ui: "D056729",
    heading: "von Willebrand Disease, Type 3",
    tree_numbers: ["C15.378.100.100.900.300", "C15.378.100.141.900.300", "C15.378.463.920.300", "C16.320.099.920.300"],
    synonyms: ["Von Willebrand Disease Type 3", "Vwd Type 3", "Vwd3", "Von Willebrand Disease Type Iii"],
  },
  {
    ui: "D056730",
    heading: "Silver-Russell Syndrome",
    tree_numbers: ["C05.660.207.925", "C16.131.077.855", "C16.131.260.870", "C16.320.180.870", "C16.320.240.937"],
    synonyms: ["Silver Russell Dwarfism"],
  },
  {
    ui: "D056731",
    heading: "Donohue Syndrome",
    tree_numbers: ["C05.660.207.325", "C16.131.077.313", "C16.320.215", "C18.452.394.750.654", "C19.246.537"],
    synonyms: ["Leprechaunism"],
  },
  {
    ui: "D056732",
    heading: "Prolidase Deficiency",
    tree_numbers: ["C16.131.077.735", "C16.131.831.720", "C16.320.565.100.794", "C16.320.850.746"],
    synonyms: ["Imidodipeptidase Deficiency", "Hyperimidodipeptiduria", "Peptidase Deficiency"],
  },
  {
    ui: "D056733",
    heading: "Carney Complex",
    tree_numbers: [
      "C04.557.450.565.550.312",
      "C04.588.894.309.500",
      "C14.280.459.500",
      "C16.131.077.229",
      "C16.131.831.108",
    ],
    synonyms: [
      "Lamb Syndrome",
      "Carney Complex Variant",
      "Carney Syndrome",
      "Carney Complex, Type 2",
      "Name Syndrome",
      "Carney Complex, Type 1",
    ],
  },
  {
    ui: "D056734",
    heading: "Monilethrix",
    tree_numbers: ["C16.131.077.592", "C16.320.850.647", "C17.800.329.984", "C17.800.827.602"],
  },
  {
    ui: "D056735",
    heading: "Autoimmune Lymphoproliferative Syndrome",
    tree_numbers: ["C15.604.515.138", "C16.320.089", "C20.111.288", "C20.683.515.124"],
    synonyms: ["Alps", "Canale-Smith Syndrome"],
  },
  {
    ui: "D056768",
    heading: "Giant Axonal Neuropathy",
    tree_numbers: [
      "C10.500.300.490",
      "C10.574.500.495.490",
      "C10.668.829.325",
      "C10.668.829.800.300.490",
      "C16.131.666.300.490",
      "C16.320.400.375.490",
    ],
  },
  {
    ui: "D056769",
    heading: "Alstrom Syndrome",
    tree_numbers: [
      "C10.500.300.099",
      "C10.574.500.495.099",
      "C10.668.829.800.300.099",
      "C11.270.684.249",
      "C16.131.077.245.063",
      "C16.131.666.300.099",
      "C16.320.184.063",
      "C16.320.290.684.249",
      "C16.320.400.375.099",
    ],
  },
  {
    ui: "D056770",
    heading: "Netherton Syndrome",
    tree_numbers: [
      "C16.131.077.619",
      "C16.131.831.512.400.705",
      "C16.320.850.673",
      "C16.614.492.400.705",
      "C17.800.428.333.250.705",
      "C17.800.804.512.400.705",
      "C17.800.827.655",
    ],
  },
  {
    ui: "D056784",
    heading: "Leukoencephalopathies",
    tree_numbers: ["C10.228.140.695"],
  },
  {
    ui: "D056806",
    heading: "Urea Cycle Disorders, Inborn",
    tree_numbers: [
      "C10.228.140.163.100.937",
      "C16.320.565.100.940",
      "C16.320.565.189.937",
      "C18.452.132.100.937",
      "C18.452.648.100.940",
      "C18.452.648.189.937",
    ],
    synonyms: [
      "Disorder Of Metabolism Of Ornithine, Citrulline, Argininosuccinic Acid, Arginine And Ammonia",
      "Urea Cycle Defect",
      "Disorder Of Urea Cycle Metabolism",
    ],
  },
  {
    ui: "D056807",
    heading: "Argininosuccinic Aciduria",
    tree_numbers: [
      "C10.228.140.163.100.937.124",
      "C16.320.565.100.940.124",
      "C16.320.565.189.937.124",
      "C18.452.132.100.937.124",
      "C18.452.648.100.940.124",
      "C18.452.648.189.937.124",
    ],
    synonyms: [
      "Argininosuccinate Lyase Deficiency",
      "Arginosuccinase Deficiency",
      "Argininosuccinic Acidemia",
      "Deficiency Of Argininosuccinate Lyase",
    ],
  },
  {
    ui: "D056824",
    heading: "Upper Extremity Deep Vein Thrombosis",
    tree_numbers: ["C14.907.355.830.925.885"],
  },
  {
    ui: "D056828",
    heading: "Hereditary Angioedema Type III",
    tree_numbers: ["C14.907.079.500.500", "C17.800.862.945.066.500.500", "C20.543.480.904.066.500.500"],
  },
  {
    ui: "D056829",
    heading: "Hereditary Angioedema Types I and II",
    tree_numbers: ["C14.907.079.500.750", "C17.800.862.945.066.500.750", "C20.543.480.904.066.500.750"],
  },
  {
    ui: "D056830",
    heading: "Isolated Noncompaction of the Ventricular Myocardium",
    tree_numbers: [
      "C14.240.400.660",
      "C14.280.238.281.500",
      "C14.280.400.660",
      "C16.131.077.477",
      "C16.131.240.400.655",
      "C16.320.322.370",
    ],
  },
  {
    ui: "D056833",
    heading: "Central Serous Chorioretinopathy",
    tree_numbers: ["C11.768.175"],
  },
  {
    ui: "D056844",
    heading: "Renal Colic",
    tree_numbers: ["C23.888.592.612.972", "C23.888.721", "F02.830.816.444.850", "G11.561.790.444.850"],
  },
  {
    ui: "D056846",
    heading: "Weill-Marchesani Syndrome",
    tree_numbers: ["C05.116.099.343.957", "C11.270.921", "C16.131.077.941", "C16.320.290.842", "C17.300.899"],
    synonyms: [
      "Congenital Mesodermal Dystrophy",
      "Marchesani-Weill Syndrome",
      "Spherophakia Brachymorphia Syndrome",
      "Mesodermal Dysmorphodystrophy, Congenital",
      "Gemss Syndrome",
    ],
  },
  {
    ui: "D056886",
    heading: "Contrecoup Injury",
    tree_numbers: ["C10.900.300.350.300.250", "C26.224", "C26.915.300.450.500.250", "C26.974.382.200.250"],
  },
  {
    ui: "D056887",
    heading: "Pelvic Organ Prolapse",
    tree_numbers: ["C23.300.842.624"],
  },
  {
    ui: "D056889",
    heading: "Barth Syndrome",
    tree_numbers: [
      "C14.240.400.172",
      "C14.280.400.172",
      "C16.131.077.121",
      "C16.131.240.400.172",
      "C16.320.322.068",
      "C16.320.565.398.224",
      "C18.452.648.398.224",
    ],
    synonyms: ["3-Methylglutaconicaciduria Type 2", "Mga Type Ii", "Mga Type 2", "3-Methylglutaconicaciduria Type Ii"],
  },
  {
    ui: "D056912",
    heading: "Binge-Eating Disorder",
    tree_numbers: ["F03.400.188"],
  },
  {
    ui: "D056929",
    heading: "Liddle Syndrome",
    tree_numbers: ["C12.777.419.815.683", "C13.351.968.419.815.683", "C16.320.831.698"],
    synonyms: ["Pseudoaldosteronism", "Liddle's Syndrome"],
  },
  {
    ui: "D056986",
    heading: "Euglenozoa Infections",
    tree_numbers: ["C01.610.752.300"],
  },
  {
    ui: "D056987",
    heading: "Vasoplegia",
    tree_numbers: ["C14.907.946", "C23.550.767.962"],
  },
  {
    ui: "D056988",
    heading: "Anterior Wall Myocardial Infarction",
    tree_numbers: ["C14.280.647.500.093", "C14.907.585.500.093", "C23.550.513.355.750.093", "C23.550.717.489.750.093"],
  },
  {
    ui: "D056989",
    heading: "Inferior Wall Myocardial Infarction",
    tree_numbers: ["C14.280.647.500.187", "C14.907.585.500.187", "C23.550.513.355.750.187", "C23.550.717.489.750.187"],
    synonyms: ["Inferior Myocardial Infarction"],
  },
  {
    ui: "D057045",
    heading: "Laryngopharyngeal Reflux",
    tree_numbers: ["C06.405.117.119.500.484.500", "C08.360.577"],
  },
  {
    ui: "D057049",
    heading: "Thrombotic Microangiopathies",
    tree_numbers: ["C15.378.140.855.925"],
  },
  {
    ui: "D057066",
    heading: "Low Tension Glaucoma",
    tree_numbers: ["C11.525.381.703", "C11.640.225"],
    synonyms: ["Normal Tension Glaucoma"],
  },
  {
    ui: "D057068",
    heading: "Periprosthetic Fractures",
    tree_numbers: ["C26.404.550"],
  },
  {
    ui: "D057072",
    heading: "Intra-Articular Fractures",
    tree_numbers: ["C26.404.505"],
  },
  {
    ui: "D057085",
    heading: "Tooth Wear",
    tree_numbers: ["C07.793.818"],
  },
  {
    ui: "D057088",
    heading: "Anetoderma",
    tree_numbers: ["C17.300.116", "C17.800.804.108"],
  },
  {
    ui: "D057090",
    heading: "Eccrine Porocarcinoma",
    tree_numbers: ["C04.557.470.200.025.500"],
    synonyms: ["Eccrine Porocarcinoma Of Skin", "Porocarcinoma", "Malignant Eccrine Poroma"],
  },
  {
    ui: "D057091",
    heading: "Poroma",
    tree_numbers: ["C04.557.470.035.175.588", "C04.557.470.550.175.588"],
  },
  {
    ui: "D057092",
    heading: "Geographic Atrophy",
    tree_numbers: ["C11.768.585.439.122"],
  },
  {
    ui: "D057096",
    heading: "Shellfish Poisoning",
    tree_numbers: ["C25.723.415.792"],
  },
  {
    ui: "D057108",
    heading: "Corneal Wavefront Aberration",
    tree_numbers: ["C11.204.431", "C11.744.345"],
  },
  {
    ui: "D057112",
    heading: "Corneal Perforation",
    tree_numbers: ["C10.900.300.284.250.124.500", "C11.204.284.500", "C11.297.374.500", "C26.915.300.425.250.124.500"],
  },
  {
    ui: "D057129",
    heading: "Iridocorneal Endothelial Syndrome",
    tree_numbers: ["C11.204.497", "C11.941.375.322"],
    synonyms: [
      "Posterior Membrane Corneal Dystrophy",
      "Chandler's Syndrome",
      "Dystrophy Of Corneal Endothelium",
      "Endothelial Corneal Dystrophy",
    ],
  },
  {
    ui: "D057130",
    heading: "Leber Congenital Amaurosis",
    tree_numbers: ["C11.270.516", "C11.768.364"],
    synonyms: ["Leber's Disease", "Lca", "Leber's Congenital Amaurosis", "Leber's Amaurosis"],
  },
  {
    ui: "D057135",
    heading: "Wet Macular Degeneration",
    tree_numbers: ["C11.768.585.439.622"],
  },
  {
    ui: "D057165",
    heading: "Proteostasis Deficiencies",
    tree_numbers: ["C18.452.845"],
  },
  {
    ui: "D057174",
    heading: "Frontotemporal Lobar Degeneration",
    tree_numbers: ["C10.228.140.380.266", "C10.574.950.300", "C18.452.845.800.300", "F03.615.400.380"],
  },
  {
    ui: "D057177",
    heading: "TDP-43 Proteinopathies",
    tree_numbers: ["C10.574.950", "C18.452.845.800"],
  },
  {
    ui: "D057178",
    heading: "Primary Progressive Nonfluent Aphasia",
    tree_numbers: [
      "C10.228.140.380.132.600",
      "C10.228.140.380.266.600",
      "C10.574.950.300.600",
      "C10.597.606.150.500.800.100.155.600",
      "C18.452.845.800.300.600",
      "C23.888.592.604.150.500.800.100.155.600",
      "F03.615.400.125.600",
      "F03.615.400.380.600",
    ],
  },
  {
    ui: "D057180",
    heading: "Frontotemporal Dementia",
    tree_numbers: ["C10.228.140.380.266.299", "C10.574.950.300.299", "C18.452.845.800.300.299", "F03.615.400.380.299"],
  },
  {
    ui: "D057215",
    heading: "Body Dysmorphic Disorders",
    tree_numbers: ["F03.875.149"],
  },
  {
    ui: "D057667",
    heading: "Conducted Energy Weapon Injuries",
    tree_numbers: ["C26.324.436"],
  },
  {
    ui: "D057765",
    heading: "Eosinophilic Esophagitis",
    tree_numbers: ["C06.405.117.620.209", "C06.405.205.663.209", "C15.378.553.231.341", "C20.543.480.356"],
  },
  {
    ui: "D057767",
    heading: "Kashin-Beck Disease",
    tree_numbers: ["C05.116.099.708.534"],
  },
  {
    ui: "D057768",
    heading: "Brief, Resolved, Unexplained Event",
    tree_numbers: ["C08.618.846.600", "C23.888.248.500", "C23.888.852.700"],
  },
  {
    ui: "D057770",
    heading: "Hyalinosis, Systemic",
    tree_numbers: ["C16.320.850.368", "C17.800.827.384"],
    synonyms: ["Hfs", "Inherited Systemic Hyalinosis", "Puretic Syndrome", "Systemic Hyalinosis"],
  },
  {
    ui: "D057772",
    heading: "Vascular System Injuries",
    tree_numbers: ["C14.907.937", "C26.940"],
  },
  {
    ui: "D057774",
    heading: "Post-Exercise Hypotension",
    tree_numbers: ["C10.177.575.600.537", "C14.907.514.611"],
  },
  {
    ui: "D057792",
    heading: "Mirizzi Syndrome",
    tree_numbers: ["C06.130.120.135.812"],
  },
  {
    ui: "D057826",
    heading: "Vitelliform Macular Dystrophy",
    tree_numbers: ["C11.768.585.439.433", "C16.320.290.763"],
    synonyms: ["Juvenile-Onset Vitelliform Macular Dystrophy", "Best Macular Dystrophy", "Best Disease"],
  },
  {
    ui: "D057831",
    heading: "Lemierre Syndrome",
    tree_numbers: [
      "C01.150.252.400.388.350.700",
      "C07.550.174",
      "C09.775.262",
      "C14.907.355.830.925.770.500",
      "C14.907.617.718.788.500",
      "C14.907.940.740.910.500",
    ],
  },
  {
    ui: "D057851",
    heading: "Posterior Capsular Rupture, Ocular",
    tree_numbers: ["C11.297.500"],
  },
  {
    ui: "D057867",
    heading: "Endoleak",
    tree_numbers: ["C14.907.055.501", "C23.550.414.941.500", "C23.550.767.850.500"],
  },
  {
    ui: "D057868",
    heading: "Anastomotic Leak",
    tree_numbers: ["C23.550.767.071"],
  },
  {
    ui: "D057873",
    heading: "Peri-Implantitis",
    tree_numbers: ["C07.465.714.282"],
  },
  {
    ui: "D057885",
    heading: "Triploidy",
    tree_numbers: ["C23.550.210.702.500", "G05.365.590.175.677.500", "G05.700.740.500"],
  },
  {
    ui: "D057887",
    heading: "Overbite",
    tree_numbers: ["C07.793.494.630.500"],
  },
  {
    ui: "D057891",
    heading: "Tetraploidy",
    tree_numbers: ["C23.550.210.702.249", "G05.365.590.175.677.249", "G05.700.740.249"],
  },
  {
    ui: "D057896",
    heading: "Striae Distensae",
    tree_numbers: ["C23.888.885.800"],
  },
  {
    ui: "D057910",
    heading: "Implant Capsular Contracture",
    tree_numbers: ["C23.550.470.251.500", "C23.550.767.865.500"],
  },
  {
    ui: "D057925",
    heading: "Femoracetabular Impingement",
    tree_numbers: ["C05.550.384", "C23.550.347"],
  },
  {
    ui: "D057971",
    heading: "Anterior Capsular Rupture, Ocular",
    tree_numbers: ["C11.297.249"],
  },
  {
    ui: "D057973",
    heading: "Dent Disease",
    tree_numbers: ["C12.777.419.815.364", "C13.351.968.419.815.364", "C16.320.322.100", "C16.320.831.271"],
    synonyms: ["Dent Disease 2", "Dent Disease 1", "Dent's Disease"],
  },
  {
    ui: "D058065",
    heading: "Diabetic Cardiomyopathies",
    tree_numbers: ["C14.280.238.235", "C19.246.099.625"],
  },
  {
    ui: "D058066",
    heading: "Digital Dermatitis",
    tree_numbers: ["C01.150.252.819.180", "C01.800.720.180", "C17.800.838.765.180", "C22.214"],
  },
  {
    ui: "D058069",
    heading: "Neglected Diseases",
    tree_numbers: ["C23.550.291.890"],
  },
  {
    ui: "D058070",
    heading: "Asymptomatic Diseases",
    tree_numbers: ["C23.550.291.187"],
  },
  {
    ui: "D058088",
    heading: "Sarcoglycanopathies",
    tree_numbers: [
      "C05.651.534.500.280.500",
      "C08.618.923",
      "C10.668.491.175.500.149.500",
      "C14.280.238.812",
      "C16.320.577.280.500",
    ],
  },
  {
    ui: "D058165",
    heading: "22q11 Deletion Syndrome",
    tree_numbers: [
      "C05.660.207.103",
      "C14.240.400.021",
      "C14.280.400.044",
      "C15.604.451.249",
      "C16.131.077.019",
      "C16.131.240.400.021",
      "C16.131.260.019",
      "C16.131.482.249",
      "C16.131.621.207.103",
      "C16.320.180.019",
      "C19.642.482.500",
    ],
  },
  {
    ui: "D058186",
    heading: "Acute Kidney Injury",
    tree_numbers: ["C12.777.419.780.050", "C13.351.968.419.780.050"],
  },
  {
    ui: "D058225",
    heading: "Plaque, Amyloid",
    tree_numbers: ["C23.300.821"],
  },
  {
    ui: "D058226",
    heading: "Plaque, Atherosclerotic",
    tree_numbers: ["C23.300.823"],
  },
  {
    ui: "D058246",
    heading: "Prehypertension",
    tree_numbers: ["C14.907.653"],
  },
  {
    ui: "D058247",
    heading: "Loose Anagen Hair Syndrome",
    tree_numbers: ["C17.800.329.937.122.348", "C23.300.035.500"],
    synonyms: ["Loose Anagen Syndrome"],
  },
  {
    ui: "D058249",
    heading: "Birt-Hogg-Dube Syndrome",
    tree_numbers: ["C04.700.212", "C16.320.700.212"],
  },
  {
    ui: "D058252",
    heading: "Necrobiotic Xanthogranuloma",
    tree_numbers: [
      "C15.604.515.292.507",
      "C17.300.200.495.772",
      "C17.800.550.772",
      "C18.452.584.750.487",
      "C23.550.382.984",
    ],
  },
  {
    ui: "D058267",
    heading: "Flea Infestations",
    tree_numbers: ["C01.610.858.211.250"],
  },
  {
    ui: "D058285",
    heading: "Tungiasis",
    tree_numbers: ["C01.610.858.211.250.800"],
  },
  {
    ui: "D058288",
    heading: "Sister Mary Joseph's Nodule",
    tree_numbers: ["C04.588.033.740"],
  },
  {
    ui: "D058345",
    heading: "Asymptomatic Infections",
    tree_numbers: ["C01.125", "C23.550.291.187.500"],
  },
  {
    ui: "D058365",
    heading: "Candidiasis, Invasive",
    tree_numbers: ["C01.150.703.160.175", "C01.150.703.492.500"],
  },
  {
    ui: "D058387",
    heading: "Candidemia",
    tree_numbers: [
      "C01.150.703.160.175.500",
      "C01.150.703.492.500.500",
      "C01.757.360.150",
      "C23.550.470.790.500.360.150",
    ],
  },
  {
    ui: "D058405",
    heading: "Desmoplastic Small Round Cell Tumor",
    tree_numbers: ["C04.557.450.795.315"],
  },
  {
    ui: "D058426",
    heading: "Neointima",
    tree_numbers: ["C23.550.722"],
  },
  {
    ui: "D058429",
    heading: "Gnathostomiasis",
    tree_numbers: ["C01.610.335.508.700.750.380"],
    synonyms: ["Infectious Disease By Gnathostoma", "Gnathostomiasis"],
  },
  {
    ui: "D058437",
    heading: "Hypertensive Retinopathy",
    tree_numbers: ["C11.768.346", "C14.907.489.815"],
  },
  {
    ui: "D058442",
    heading: "Capsule Opacification",
    tree_numbers: ["C11.510.245.500"],
  },
  {
    ui: "D058447",
    heading: "Eye Pain",
    tree_numbers: ["C11.300.500", "C23.888.307.625", "C23.888.592.612.316"],
  },
  {
    ui: "D058456",
    heading: "Retinal Telangiectasis",
    tree_numbers: ["C11.768.748", "C14.907.823.502"],
    synonyms: ["Exudative Retinopathy", "Coats' Syndrome", "Coats' Disease"],
  },
  {
    ui: "D058457",
    heading: "Trichiasis",
    tree_numbers: ["C11.338.912"],
  },
  {
    ui: "D058489",
    heading: "46, XX Disorders of Sex Development",
    tree_numbers: ["C12.706.316.064", "C13.351.875.253.064", "C16.131.939.316.064", "C19.391.119.064"],
  },
  {
    ui: "D058490",
    heading: "Disorder of Sex Development, 46,XY",
    tree_numbers: ["C12.706.316.096", "C13.351.875.253.096", "C16.131.939.316.096", "C19.391.119.096"],
  },
  {
    ui: "D058494",
    heading: "Walker-Warburg Syndrome",
    tree_numbers: ["C10.500.507.450.499.249.500", "C11.270.881", "C16.131.666.507.450.499.249.500", "C16.320.577.750"],
    synonyms: ["Hard Syndrome", "Cerebroocular Dysplasia-Muscular Dystrophy Syndrome"],
  },
  {
    ui: "D058495",
    heading: "Sotos Syndrome",
    tree_numbers: ["C16.131.077.889", "C16.131.260.905", "C16.320.180.905"],
    synonyms: ["Cerebral Gigantism"],
  },
  {
    ui: "D058496",
    heading: "Smith-Magenis Syndrome",
    tree_numbers: ["C10.281.900", "C16.131.077.879", "C16.131.260.887", "C16.320.180.887"],
    synonyms: ["17p11.2 Microdeletion Syndrome", "Chromosome 17p11.2 Deletion Syndrome"],
  },
  {
    ui: "D058497",
    heading: "Fraser Syndrome",
    tree_numbers: [
      "C05.116.099.370.894.819.428",
      "C05.660.585.800.428",
      "C05.660.906.819.428",
      "C11.250.390",
      "C12.706.410",
      "C13.351.875.397",
      "C16.131.077.371",
      "C16.131.384.442",
      "C16.131.621.585.800.428",
      "C16.131.621.906.819.428",
      "C16.131.939.410",
    ],
    synonyms: ["Cryptophthalmos With Other Malformations"],
  },
  {
    ui: "D058499",
    heading: "Retinal Dystrophies",
    tree_numbers: ["C11.768.585.658"],
    synonyms: ["Retinal Dystrophy"],
  },
  {
    ui: "D058502",
    heading: "Pentalogy of Cantrell",
    tree_numbers: ["C10.500.680.705", "C16.131.077.696", "C16.131.666.680.705"],
  },
  {
    ui: "D058527",
    heading: "Enteropathy-Associated T-Cell Lymphoma",
    tree_numbers: ["C04.557.386.480.750.099", "C15.604.515.569.480.750.399", "C20.683.515.761.480.750.199"],
  },
  {
    ui: "D058529",
    heading: "Single Umbilical Artery",
    tree_numbers: ["C14.240.850.976", "C16.131.240.850.952"],
  },
  {
    ui: "D058531",
    heading: "46, XX Testicular Disorders of Sex Development",
    tree_numbers: ["C12.706.316.064.124", "C13.351.875.253.064.124", "C16.131.939.316.064.124", "C19.391.119.064.124"],
    synonyms: [
      "De La Chapelle Syndrome",
      "Xx, Male Syndrome",
      "Srxx",
      "46,xx Testicular Dsd",
      "46,xx Testicular Disorder Of Sex Development",
    ],
  },
  {
    ui: "D058533",
    heading: "Sex Chromosome Disorders of Sex Development",
    tree_numbers: [
      "C12.706.316.795",
      "C13.351.875.253.795",
      "C16.131.260.830.835",
      "C16.131.939.316.795",
      "C16.320.180.830.835",
      "C19.391.119.795",
    ],
  },
  {
    ui: "D058535",
    heading: "Echogenic Bowel",
    tree_numbers: ["C13.703.277.050", "C16.300.050"],
  },
  {
    ui: "D058536",
    heading: "Pyelectasis",
    tree_numbers: ["C13.703.277.838", "C16.131.939.831", "C16.300.895"],
  },
  {
    ui: "D058540",
    heading: "Aicardi Syndrome",
    tree_numbers: [
      "C10.500.034.687",
      "C11.270.019",
      "C16.131.162",
      "C16.131.666.034.687",
      "C16.320.290.019",
      "C16.320.322.030",
    ],
  },
  {
    ui: "D058545",
    heading: "Inhalant Abuse",
    tree_numbers: ["C25.775.467", "F03.900.467"],
  },
  {
    ui: "D058565",
    heading: "Cerebral Ventriculitis",
    tree_numbers: ["C01.207.245.169", "C10.228.140.430.249", "C10.228.228.245.169"],
  },
  {
    ui: "D058566",
    heading: "Sacroiliitis",
    tree_numbers: ["C05.550.114.854"],
  },
  {
    ui: "D058568",
    heading: "Necrolytic Migratory Erythema",
    tree_numbers: ["C17.800.551", "C23.888.885.468"],
  },
  {
    ui: "D058606",
    heading: "Accessory Atrioventricular Bundle",
    tree_numbers: ["C23.300.190"],
  },
  {
    ui: "D058617",
    heading: "Composite Lymphoma",
    tree_numbers: ["C04.557.386.150", "C04.557.435.295", "C15.604.515.569.150", "C20.683.515.761.150"],
  },
  {
    ui: "D058625",
    heading: "End Stage Liver Disease",
    tree_numbers: ["C06.552.308.500.177"],
  },
  {
    ui: "D058627",
    heading: "Megalencephaly",
    tree_numbers: ["C05.660.207.536", "C10.500.507.400.249", "C16.131.621.207.532", "C16.131.666.507.400.249"],
  },
  {
    ui: "D058631",
    heading: "Pycnodysostosis",
    tree_numbers: ["C05.116.099.708.779", "C16.320.565.595.800", "C16.320.812", "C18.452.648.595.800"],
  },
  {
    ui: "D058670",
    heading: "Tetrasomy",
    tree_numbers: [
      "C23.550.210.050.625",
      "C23.550.210.182.249",
      "G05.365.590.175.050.625",
      "G05.365.590.175.183.249",
      "G05.700.131.625",
    ],
  },
  {
    ui: "D058674",
    heading: "Chromosome Duplication",
    tree_numbers: ["C23.550.210.182", "G05.365.590.175.183", "G05.558.164"],
  },
  {
    ui: "D058686",
    heading: "Coronary-Subclavian Steal Syndrome",
    tree_numbers: ["C14.280.647.250.647", "C14.907.585.250.647", "C23.550.767.115"],
  },
  {
    ui: "D058687",
    heading: "Out-of-Hospital Cardiac Arrest",
    tree_numbers: ["C14.280.383.610"],
  },
  {
    ui: "D058729",
    heading: "Peripheral Arterial Disease",
    tree_numbers: ["C14.907.137.126.307.500", "C14.907.617.671"],
  },
  {
    ui: "D058734",
    heading: "Exsanguination",
    tree_numbers: ["C23.550.414.734"],
  },
  {
    ui: "D058736",
    heading: "Intrauterine Device Migration",
    tree_numbers: ["C26.392.500.500", "E05.325.578"],
  },
  {
    ui: "D058739",
    heading: "Aberrant Crypt Foci",
    tree_numbers: ["C04.834.020"],
  },
  {
    ui: "D058745",
    heading: "Iliotibial Band Syndrome",
    tree_numbers: ["C26.558.554.425", "C26.844.150.425"],
  },
  {
    ui: "D058747",
    heading: "CHARGE Syndrome",
    tree_numbers: ["C11.270.147.500", "C16.131.077.239", "C16.320.165"],
    synonyms: ["Charge Association"],
  },
  {
    ui: "D058866",
    heading: "Osteoporotic Fractures",
    tree_numbers: ["C26.404.545"],
  },
  {
    ui: "D058890",
    heading: "Granulomatous Mastitis",
    tree_numbers: ["C13.703.844.603.400", "C17.800.090.968.400"],
  },
  {
    ui: "D058922",
    heading: "Inflammatory Breast Neoplasms",
    tree_numbers: ["C04.588.180.576", "C17.800.090.500.576"],
    synonyms: ["Mastitis Carcinomatosa", "Inflammatory Carcinoma Of Breast"],
  },
  {
    ui: "D058923",
    heading: "Medial Tibial Stress Syndrome",
    tree_numbers: ["C05.651.426", "C10.668.491.087", "C26.558.705"],
  },
  {
    ui: "D058926",
    heading: "Intraoperative Awareness",
    tree_numbers: ["C23.550.505.400"],
  },
  {
    ui: "D058968",
    heading: "Pythiosis",
    tree_numbers: ["C01.610.701.844", "C22.761"],
  },
  {
    ui: "D059041",
    heading: "Plagiocephaly",
    tree_numbers: ["C05.660.207.707", "C16.131.621.207.707"],
  },
  {
    ui: "D059226",
    heading: "Nociceptive Pain",
    tree_numbers: ["C23.888.592.612.720"],
  },
  {
    ui: "D059228",
    heading: "Renal Nutcracker Syndrome",
    tree_numbers: ["C12.777.419.787", "C13.351.968.419.787"],
  },
  {
    ui: "D059245",
    heading: "Transient Tachypnea of the Newborn",
    tree_numbers: [
      "C08.381.840.500.737",
      "C08.618.840.500.737",
      "C08.618.961.500",
      "C16.614.521.563.737",
      "C23.888.852.944.500",
    ],
  },
  {
    ui: "D059246",
    heading: "Tachypnea",
    tree_numbers: ["C08.618.961", "C23.888.852.944"],
  },
  {
    ui: "D059249",
    heading: "Otomycosis",
    tree_numbers: ["C01.150.703.658", "C09.218.736"],
    synonyms: ["Singapore Ear"],
  },
  {
    ui: "D059265",
    heading: "Visceral Pain",
    tree_numbers: ["C23.888.592.612.720.500"],
  },
  {
    ui: "D059266",
    heading: "Bisphosphonate-Associated Osteonecrosis of the Jaw",
    tree_numbers: ["C05.116.852.087", "C05.500.086", "C07.320.086", "C23.550.717.732.183"],
  },
  {
    ui: "D059268",
    heading: "Atrophic Vaginitis",
    tree_numbers: ["C13.351.500.894.906.316"],
  },
  {
    ui: "D059269",
    heading: "Chilaiditi Syndrome",
    tree_numbers: ["C06.405.469.158.093"],
  },
  {
    ui: "D059270",
    heading: "Petrositis",
    tree_numbers: ["C01.160.495.750", "C05.116.165.495.500", "C09.218.705.663.841"],
    synonyms: ["Chronic Petrositis", "Acute Petrositis"],
  },
  {
    ui: "D059325",
    heading: "Intra-Abdominal Hypertension",
    tree_numbers: ["C05.651.180.297", "C14.907.303.297"],
  },
  {
    ui: "D059327",
    heading: "Brachydactyly",
    tree_numbers: ["C05.660.585.262", "C16.131.621.585.262"],
  },
  {
    ui: "D059345",
    heading: "Cerebral Small Vessel Diseases",
    tree_numbers: ["C10.228.140.300.275", "C14.907.253.329"],
  },
  {
    ui: "D059347",
    heading: "Cardio-Renal Syndrome",
    tree_numbers: ["C12.777.419.780.400", "C13.351.968.419.780.400", "C14.280.434.156"],
  },
  {
    ui: "D059348",
    heading: "Peripheral Nerve Injuries",
    tree_numbers: ["C10.668.829.712", "C10.900.575", "C26.915.650"],
  },
  {
    ui: "D059350",
    heading: "Chronic Pain",
    tree_numbers: ["C23.888.592.612.274"],
  },
  {
    ui: "D059352",
    heading: "Musculoskeletal Pain",
    tree_numbers: ["C05.651.538", "C23.888.592.612.547", "F02.830.816.353", "G11.561.790.353"],
  },
  {
    ui: "D059366",
    heading: "Asthma, Occupational",
    tree_numbers: ["C08.127.108.495", "C08.674.095.495", "C20.543.480.680.095.495", "C24.102"],
  },
  {
    ui: "D059369",
    heading: "Pott Puffy Tumor",
    tree_numbers: ["C01.160.495.875", "C05.116.165.495.750", "C11.675.689"],
  },
  {
    ui: "D059373",
    heading: "Mastodynia",
    tree_numbers: ["C23.888.592.612.530", "F02.830.816.308", "G11.561.790.308"],
  },
  {
    ui: "D059388",
    heading: "Pelvic Girdle Pain",
    tree_numbers: ["C23.888.592.612.547.500", "C23.888.592.612.944.625"],
  },
  {
    ui: "D059390",
    heading: "Breakthrough Pain",
    tree_numbers: ["C23.888.592.612.191", "F02.830.816.131", "G11.561.790.444.393"],
  },
  {
    ui: "D059407",
    heading: "Pinguecula",
    tree_numbers: ["C11.187.631"],
  },
  {
    ui: "D059409",
    heading: "Stroke, Lacunar",
    tree_numbers: [
      "C10.228.140.300.275.800",
      "C10.228.140.300.775.400.750.500",
      "C14.907.253.329.800",
      "C14.907.253.855.400.750.500",
      "C23.550.513.355.250.600",
      "C23.550.717.489.250.600",
    ],
  },
  {
    ui: "D059411",
    heading: "Lower Urinary Tract Symptoms",
    tree_numbers: ["C23.888.942.343"],
  },
  {
    ui: "D059413",
    heading: "Intraabdominal Infections",
    tree_numbers: ["C01.463"],
  },
  {
    ui: "D059445",
    heading: "Anhedonia",
    tree_numbers: ["C10.597.606.057", "C23.888.592.604.039", "F01.700.039"],
  },
  {
    ui: "D059446",
    heading: "Heterotaxy Syndrome",
    tree_numbers: ["C14.240.400.592", "C14.280.400.592", "C15.604.744.146", "C16.131.077.401", "C16.131.240.400.592"],
  },
  {
    ui: "D059466",
    heading: "White Coat Hypertension",
    tree_numbers: ["C14.907.489.907"],
  },
  {
    ui: "D059468",
    heading: "Masked Hypertension",
    tree_numbers: ["C14.907.489.861"],
  },
  {
    ui: "D059525",
    heading: "Supraglottitis",
    tree_numbers: ["C01.748.798", "C08.360.840", "C08.730.798", "C09.400.840"],
  },
  {
    ui: "D059545",
    heading: "Paraneoplastic Syndromes, Ocular",
    tree_numbers: ["C04.588.364.738", "C04.730.900", "C11.319.466"],
  },
  {
    ui: "D059606",
    heading: "Polydipsia",
    tree_numbers: ["C23.550.759", "C23.888.663"],
  },
  {
    ui: "D059607",
    heading: "Polydipsia, Psychogenic",
    tree_numbers: ["C23.550.759.500", "C23.888.663.500", "F01.145.126.968", "F01.700.812"],
  },
  {
    ui: "D059608",
    heading: "Laryngocele",
    tree_numbers: ["C08.360.549", "C08.695.520", "C09.400.549", "C16.131.740.650"],
  },
  {
    ui: "D059707",
    heading: "Aliivibrio Infections",
    tree_numbers: ["C01.150.252.400.050"],
  },
  {
    ui: "D059786",
    heading: "Abnormal Karyotype",
    tree_numbers: ["C23.550.210.024", "G05.360.162.679.500", "G05.365.590.175.024"],
  },
  {
    ui: "D059787",
    heading: "Acute Pain",
    tree_numbers: ["C23.888.592.612.081"],
  },
  {
    ui: "D059885",
    heading: "Kasabach-Merritt Syndrome",
    tree_numbers: ["C04.557.645.375.617", "C15.378.140.855.645"],
  },
  {
    ui: "D059905",
    heading: "Endocarditis, Non-Infective",
    tree_numbers: ["C14.280.282.703"],
  },
  {
    ui: "D059952",
    heading: "Pelvic Floor Disorders",
    tree_numbers: ["C12.483", "C13.351.625", "C13.703.565"],
  },
  {
    ui: "D060048",
    heading: "Slipped Capital Femoral Epiphyses",
    tree_numbers: ["C05.116.099.708.928", "C05.116.425.500"],
  },
  {
    ui: "D060050",
    heading: "Angina, Stable",
    tree_numbers: ["C14.280.647.187.362", "C14.907.585.187.362", "C23.888.592.612.233.500.575"],
  },
  {
    ui: "D060051",
    heading: "Occupational Injuries",
    tree_numbers: ["C26.716"],
  },
  {
    ui: "D060085",
    heading: "Coinfection",
    tree_numbers: ["C01.218"],
  },
  {
    ui: "D060368",
    heading: "Lobomycosis",
    tree_numbers: ["C01.150.703.302.475", "C01.800.200.475", "C17.800.838.208.475"],
    synonyms: ["Cutaneous Lobomycosis", "Infection By Loboa Loboi"],
  },
  {
    ui: "D060425",
    heading: "Cerebral Phaeohyphomycosis",
    tree_numbers: ["C01.150.703.302.383.375", "C01.800.200.383.375", "C17.800.838.208.416.375"],
  },
  {
    ui: "D060426",
    heading: "Anti-N-Methyl-D-Aspartate Receptor Encephalitis",
    tree_numbers: [
      "C04.588.614.550.112",
      "C04.730.856.112",
      "C10.228.140.430.124",
      "C10.574.781.249",
      "C20.111.258.124",
    ],
  },
  {
    ui: "D060437",
    heading: "Artificial Lens Implant Migration",
    tree_numbers: ["C11.510.174", "C26.392.500.249"],
  },
  {
    ui: "D060446",
    heading: "Phaeohyphomycosis",
    tree_numbers: ["C01.150.703.726"],
    synonyms: ["Infection By Dematiacious Fungi", "Phaehyphomycosis"],
  },
  {
    ui: "D060467",
    heading: "Disease Resistance",
    tree_numbers: ["C23.550.291.671", "G12.450.564.250", "G12.450.800.250", "G15.630.250"],
  },
  {
    ui: "D060486",
    heading: "Ophthalmoplegic Migraine",
    tree_numbers: [
      "C10.228.140.546.399.750.725",
      "C10.292.562.750.375",
      "C10.292.675",
      "C10.314.687",
      "C10.597.622.447.600",
      "C11.590.472.375",
      "C23.888.592.636.447.600",
    ],
  },
  {
    ui: "D060487",
    heading: "Alternariosis",
    tree_numbers: ["C01.150.703.302.383.125", "C01.800.200.383.125", "C17.800.838.208.416.125"],
  },
  {
    ui: "D060545",
    heading: "Pudendal Neuralgia",
    tree_numbers: ["C10.668.829.550.650", "C10.668.829.600.737", "C23.888.592.612.664.737"],
  },
  {
    ui: "D060585",
    heading: "Fusariosis",
    tree_numbers: ["C01.150.703.302.383.500", "C01.800.200.383.500", "C17.800.838.208.416.500"],
  },
  {
    ui: "D060586",
    heading: "Trichosporonosis",
    tree_numbers: ["C01.150.703.875", "C08.381.483.125.841", "C08.674.055.682", "C20.543.480.680.075.682"],
  },
  {
    ui: "D060605",
    heading: "Hyalohyphomycosis",
    tree_numbers: ["C01.150.703.302.383", "C01.800.200.383", "C17.800.838.208.416"],
  },
  {
    ui: "D060705",
    heading: "Dyscalculia",
    tree_numbers: ["C10.597.606.150.550.099", "C23.888.592.604.150.550.099", "F03.625.562.199"],
    synonyms: ["Disorder Of Arithmetical Skills", "Mathematics Disorder"],
  },
  {
    ui: "D060725",
    heading: "Uterine Retroversion",
    tree_numbers: ["C13.351.500.852.900", "C23.300.970.750"],
  },
  {
    ui: "D060737",
    heading: "Reproductive Tract Infections",
    tree_numbers: ["C01.730", "C12.294.604", "C13.351.500.630"],
  },
  {
    ui: "D060750",
    heading: "Bone Anteversion",
    tree_numbers: ["C05.116.214.500", "C23.300.970.249"],
  },
  {
    ui: "D060751",
    heading: "Bone Retroversion",
    tree_numbers: ["C05.116.214.750", "C23.300.970.374"],
  },
  {
    ui: "D060825",
    heading: "Cognitive Dysfunction",
    tree_numbers: ["F03.615.250.700"],
  },
  {
    ui: "D060831",
    heading: "Hand-Foot Syndrome",
    tree_numbers: ["C17.800.174.600.587", "C25.100.468.380.587"],
  },
  {
    ui: "D060905",
    heading: "Coxa Vara",
    tree_numbers: ["C05.116.214.500.500", "C23.300.970.249.500"],
  },
  {
    ui: "D060906",
    heading: "Coxa Valga",
    tree_numbers: ["C05.116.214.750.500", "C05.116.327", "C23.300.970.374.500"],
  },
  {
    ui: "D061085",
    heading: "Agenesis of Corpus Callosum",
    tree_numbers: ["C10.500.034", "C16.131.666.034", "C23.300.008"],
  },
  {
    ui: "D061205",
    heading: "Vascular Calcification",
    tree_numbers: ["C18.452.174.130.780"],
  },
  {
    ui: "D061206",
    heading: "Neoplasm Micrometastasis",
    tree_numbers: ["C04.697.650.695", "C23.550.727.650.695"],
  },
  {
    ui: "D061218",
    heading: "Depressive Disorder, Treatment-Resistant",
    tree_numbers: ["F03.600.300.388"],
  },
  {
    ui: "D061219",
    heading: "Olfactory Nerve Injuries",
    tree_numbers: ["C10.292.200.687", "C10.292.650.600", "C10.900.300.218.487", "C26.915.300.400.562"],
  },
  {
    ui: "D061220",
    heading: "Oculomotor Nerve Injuries",
    tree_numbers: [
      "C10.292.200.656",
      "C10.292.562.700.500",
      "C10.900.300.218.456",
      "C11.590.436.600",
      "C26.915.300.400.518",
    ],
  },
  {
    ui: "D061221",
    heading: "Trigeminal Nerve Injuries",
    tree_numbers: [
      "C07.465.299.625.500.349",
      "C10.292.200.875",
      "C10.292.319.625.700.349",
      "C10.900.300.218.775",
      "C26.915.300.400.825",
    ],
  },
  {
    ui: "D061222",
    heading: "Lingual Nerve Injuries",
    tree_numbers: [
      "C07.465.299.625.500.349.500",
      "C10.292.200.875.500",
      "C10.292.319.625.700.349.500",
      "C10.900.300.218.775.500",
      "C26.915.300.400.825.500",
    ],
  },
  {
    ui: "D061223",
    heading: "Vagus Nerve Injuries",
    tree_numbers: ["C10.292.200.937", "C10.292.887.399", "C10.900.300.218.887", "C26.915.300.400.912"],
  },
  {
    ui: "D061224",
    heading: "Laryngeal Nerve Injuries",
    tree_numbers: [
      "C08.360.424",
      "C09.400.424",
      "C10.292.200.937.750",
      "C10.900.300.218.887.750",
      "C26.915.300.400.912.750",
    ],
  },
  {
    ui: "D061226",
    heading: "Recurrent Laryngeal Nerve Injuries",
    tree_numbers: [
      "C08.360.424.500",
      "C09.400.424.500",
      "C10.292.200.937.750.500",
      "C10.900.300.218.887.750.500",
      "C26.915.300.400.912.750.500",
    ],
  },
  {
    ui: "D061227",
    heading: "Accessory Nerve Injuries",
    tree_numbers: ["C10.292.175.500", "C10.292.200.350", "C10.900.300.218.225", "C26.915.300.400.200"],
  },
  {
    ui: "D061228",
    heading: "Hypoglossal Nerve Injuries",
    tree_numbers: ["C10.292.200.562", "C10.292.525.500", "C10.900.300.218.362", "C26.915.300.400.387"],
  },
  {
    ui: "D061247",
    heading: "Trochlear Nerve Injuries",
    tree_numbers: ["C10.292.200.906", "C10.900.300.218.831", "C26.915.300.400.868"],
  },
  {
    ui: "D061270",
    heading: "Nasal Septal Perforation",
    tree_numbers: ["C08.460.595", "C09.603.588", "C26.670"],
  },
  {
    ui: "D061285",
    heading: "Vestibulocochlear Nerve Injuries",
    tree_numbers: [
      "C09.218.807.800.918",
      "C10.292.200.968",
      "C10.292.910.925",
      "C10.900.300.218.943",
      "C26.915.300.400.956",
    ],
  },
  {
    ui: "D061287",
    heading: "Glossopharyngeal Nerve Injuries",
    tree_numbers: ["C10.292.200.531", "C10.292.450.500", "C10.900.300.218.331", "C26.915.300.400.343"],
  },
  {
    ui: "D061325",
    heading: "Hereditary Breast and Ovarian Cancer Syndrome",
    tree_numbers: [
      "C04.588.180.483",
      "C04.588.322.455.431",
      "C04.700.517",
      "C13.351.500.056.630.705.431",
      "C13.351.937.418.685.431",
      "C16.320.700.517",
      "C17.800.090.500.483",
      "C19.344.410.431",
      "C19.391.630.705.431",
    ],
    synonyms: ["Brca1- And Brca2-Associated Hereditary Breast And Ovarian Cancer"],
  },
  {
    ui: "D061387",
    heading: "Chlamydial Pneumonia",
    tree_numbers: [
      "C01.150.252.400.210.125.123",
      "C01.150.252.400.210.250.299",
      "C01.150.252.620.249",
      "C01.748.610.540.249",
      "C08.381.677.540.249",
      "C08.730.610.540.249",
    ],
  },
  {
    ui: "D061686",
    heading: "Premature Ejaculation",
    tree_numbers: ["C12.294.644.743", "F03.835.700"],
  },
  {
    ui: "D062025",
    heading: "Organophosphate Poisoning",
    tree_numbers: ["C25.723.717"],
  },
  {
    ui: "D062026",
    heading: "Alice in Wonderland Syndrome",
    tree_numbers: [
      "C10.228.140.546.399.750.124",
      "C10.597.606.762.150",
      "C10.597.751.941.036",
      "C23.888.592.604.764.150",
      "C23.888.592.763.941.036",
      "F01.700.750.150",
    ],
  },
  {
    ui: "D062027",
    heading: "Linear IgA Bullous Dermatosis",
    tree_numbers: ["C17.800.865.622", "C20.111.585"],
  },
  {
    ui: "D062108",
    heading: "May-Thurner Syndrome",
    tree_numbers: ["C14.240.850.906", "C14.907.617.648", "C16.131.240.850.890"],
  },
  {
    ui: "D062625",
    heading: "Cystadenofibroma",
    tree_numbers: ["C04.557.450.565.590.595.050.500", "C04.557.470.590.482", "C04.557.470.625.050.500"],
    synonyms: ["Clear Cell Adenofibroma"],
  },
  {
    ui: "D062685",
    heading: "Steatocystoma Multiplex",
    tree_numbers: [
      "C16.131.831.350.856.500",
      "C16.320.850.250.856.500",
      "C17.800.529.594.500",
      "C17.800.804.350.856.500",
      "C17.800.827.250.856.500",
    ],
  },
  {
    ui: "D062688",
    heading: "Buschke-Lowenstein Tumor",
    tree_numbers: [
      "C01.221.812.640.220.500",
      "C01.778.640.220.500",
      "C01.925.256.650.810.217.500",
      "C01.925.813.220.500",
      "C01.925.825.810.110.500",
      "C01.925.928.914.217.500",
      "C04.557.470.200.450.500",
      "C04.557.470.700.450.500",
      "C17.800.838.790.810.110.500",
    ],
  },
  {
    ui: "D062689",
    heading: "Lipoblastoma",
    tree_numbers: ["C04.557.450.550.400.500"],
  },
  {
    ui: "D062706",
    heading: "Prodromal Symptoms",
    tree_numbers: ["C23.888.672", "E01.599"],
  },
  {
    ui: "D062787",
    heading: "Drug Overdose",
    tree_numbers: ["C25.775.383", "E02.319.306.500.500"],
  },
  {
    ui: "D062788",
    heading: "Adenomyosis",
    tree_numbers: ["C13.351.500.852.113"],
    synonyms: [
      "Endometriosis Interna",
      "Endometriosis Of Myometrium",
      "Adenomyosis",
      "Endometriosis, Myometrium",
      "Uterine Adenomyosis",
    ],
  },
  {
    ui: "D062846",
    heading: "Non-Filarial Lymphedema",
    tree_numbers: ["C15.604.496.660"],
  },
  {
    ui: "D063129",
    heading: "Parental Death",
    tree_numbers: ["C23.550.260.730", "I01.880.735.607"],
  },
  {
    ui: "D063130",
    heading: "Maternal Death",
    tree_numbers: ["C13.703.401", "C23.550.260.730.500", "I01.880.735.607.500"],
  },
  {
    ui: "D063169",
    heading: "Dentofacial Deformities",
    tree_numbers: ["C05.660.207.540.229", "C07.650.500.229", "C16.131.621.207.540.315", "C16.131.850.500.229"],
  },
  {
    ui: "D063173",
    heading: "Retrognathia",
    tree_numbers: [
      "C05.500.460.827",
      "C05.660.207.540.460.827",
      "C07.320.440.827",
      "C07.320.610.827",
      "C07.650.500.460.827",
      "C16.131.621.207.540.460.827",
      "C16.131.850.500.460.827",
    ],
  },
  {
    ui: "D063192",
    heading: "Pregnancy, Heterotopic",
    tree_numbers: ["C13.703.733.650"],
  },
  {
    ui: "D063205",
    heading: "Pneumorrhachis",
    tree_numbers: ["C10.228.854.583"],
  },
  {
    ui: "D063371",
    heading: "Myringosclerosis",
    tree_numbers: ["C09.218.636"],
  },
  {
    ui: "D063425",
    heading: "Binge Drinking",
    tree_numbers: ["C25.775.100.437", "F01.145.317.269.500", "F03.900.100.550"],
  },
  {
    ui: "D063466",
    heading: "Respiratory Aspiration of Gastric Contents",
    tree_numbers: ["C06.405.117.119.500.484.500.500", "C08.618.749.500", "C23.550.773.500"],
  },
  {
    ui: "D063485",
    heading: "Acanthocheilonemiasis",
    tree_numbers: ["C01.610.335.508.700.750.361.137"],
  },
  {
    ui: "D063646",
    heading: "Carcinogenesis",
    tree_numbers: ["C04.697.098", "C23.550.727.098"],
  },
  {
    ui: "D063647",
    heading: "Fetal Alcohol Spectrum Disorders",
    tree_numbers: ["C13.703.277.220", "C16.300.070", "C25.775.100.087.323"],
  },
  {
    ui: "D063726",
    heading: "Delusional Parasitosis",
    tree_numbers: ["F03.700.356"],
  },
  {
    ui: "D063730",
    heading: "Rickets, Hypophosphatemic",
    tree_numbers: [
      "C05.116.198.816.875",
      "C18.452.104.816.875",
      "C18.452.174.845.875",
      "C18.452.750.400.750",
      "C18.654.521.500.133.770.734.875",
    ],
  },
  {
    ui: "D063748",
    heading: "Bland White Garland Syndrome",
    tree_numbers: [
      "C14.240.400.210.249",
      "C14.240.850.984.500.500",
      "C14.280.400.210.249",
      "C14.907.933.110.500",
      "C16.131.240.400.210.249",
      "C16.131.240.850.500.500",
      "C23.300.575.950.150.500",
    ],
    synonyms: ["White-Garland Syndrome", "Alcapa", "Bland-White-Garland Syndrome"],
  },
  {
    ui: "D063766",
    heading: "Pediatric Obesity",
    tree_numbers: [
      "C18.654.726.500.720",
      "C23.888.144.699.500.750",
      "E01.370.600.115.100.160.120.699.500.750",
      "G07.100.100.160.120.699.500.750",
    ],
  },
  {
    ui: "D063806",
    heading: "Myalgia",
    tree_numbers: [
      "C05.651.542",
      "C10.668.491.525",
      "C23.888.592.612.547.249",
      "F02.830.816.353.500",
      "G11.561.790.353.500",
    ],
  },
  {
    ui: "D063807",
    heading: "Dandruff",
    tree_numbers: ["C17.800.174.146", "C17.800.738.353"],
    synonyms: [
      "Seborrhea Capitis",
      "Seborrhea Sicca",
      "Seborrhea Capitis",
      "Pityriasis Capitis",
      "Seborrhoeic Eczema Of Scalp",
      "Infantile Seborrhoeic Dermatitis",
      "Infantile Seborrheic Dermatitis",
      "Generalized Seborrheic Dermatitis Of Infants",
      "Complement 5 Dysfunction",
      "Seborrhoea Capitis",
      "Seborrhoeic Dermatitis Of Scalp",
      "Cradle Cap",
    ],
  },
  {
    ui: "D063926",
    heading: "Drug Hypersensitivity Syndrome",
    tree_numbers: ["C17.800.174.600.262", "C20.543.206.380.262", "C25.100.468.380.262"],
  },
  {
    ui: "D064068",
    heading: "Collagenous Sprue",
    tree_numbers: ["C06.405.469.637.378", "C18.452.603.314"],
  },
  {
    ui: "D064090",
    heading: "Intraocular Lymphoma",
    tree_numbers: ["C04.557.386.435", "C04.588.364.447", "C15.604.515.569.417", "C20.683.515.761.417"],
    synonyms: ["Primary Intraocular Lymphoma"],
  },
  {
    ui: "D064128",
    heading: "Nodding Syndrome",
    tree_numbers: ["C10.228.140.490.375.525"],
  },
  {
    ui: "D064129",
    heading: "Prostatic Neoplasms, Castration-Resistant",
    tree_numbers: ["C04.588.945.440.770.500", "C12.294.260.750.500", "C12.294.565.625.500", "C12.758.409.750.500"],
  },
  {
    ui: "D064146",
    heading: "Chemotherapy-Induced Febrile Neutropenia",
    tree_numbers: ["C15.378.553.546.184.564.750.500"],
  },
  {
    ui: "D064147",
    heading: "Febrile Neutropenia",
    tree_numbers: ["C15.378.553.546.184.564.750"],
  },
  {
    ui: "D064250",
    heading: "Hypertriglyceridemic Waist",
    tree_numbers: ["C18.452.584.500.500.851.875", "C23.888.521"],
  },
  {
    ui: "D064386",
    heading: "Ankle Fractures",
    tree_numbers: ["C26.404.014"],
  },
  {
    ui: "D064419",
    heading: "Chemically-Induced Disorders",
    tree_numbers: ["C25"],
  },
  {
    ui: "D064420",
    heading: "Drug-Related Side Effects and Adverse Reactions",
    tree_numbers: ["C25.100"],
  },
  {
    ui: "D064706",
    heading: "Vocal Cord Dysfunction",
    tree_numbers: ["C08.360.895", "C08.618.980", "C09.400.895"],
  },
  {
    ui: "D064726",
    heading: "Triple Negative Breast Neoplasms",
    tree_numbers: ["C04.588.180.788", "C17.800.090.500.788"],
  },
  {
    ui: "D064749",
    heading: "Retrocaval Ureter",
    tree_numbers: ["C12.706.811", "C13.351.875.811", "C16.131.939.915"],
  },
  {
    ui: "D064752",
    heading: "Atrial Remodeling",
    tree_numbers: ["C23.300.052", "C23.550.113", "G09.330.040.800"],
  },
  {
    ui: "D064793",
    heading: "Teratogenesis",
    tree_numbers: ["C23.550.863"],
  },
  {
    ui: "D064806",
    heading: "Dysbiosis",
    tree_numbers: ["C23.550.308"],
  },
  {
    ui: "D064807",
    heading: "Anticholinergic Syndrome",
    tree_numbers: ["C25.100.311"],
  },
  {
    ui: "D064927",
    heading: "Tick Bites",
    tree_numbers: ["C25.723.127.789", "C26.176.793"],
  },
  {
    ui: "D065008",
    heading: "Scorpion Stings",
    tree_numbers: ["C25.723.127.142", "C26.176.443"],
  },
  {
    ui: "D065134",
    heading: "Lipedema",
    tree_numbers: ["C17.300.451"],
  },
  {
    ui: "D065148",
    heading: "Nicolau Syndrome",
    tree_numbers: ["C17.800.174.600.693", "C20.543.206.380.587", "C25.100.468.380.693"],
  },
  {
    ui: "D065166",
    heading: "Sepsis-Associated Encephalopathy",
    tree_numbers: ["C10.228.140.807"],
  },
  {
    ui: "D065167",
    heading: "Pregnancy, Interstitial",
    tree_numbers: ["C13.703.733.703.500"],
  },
  {
    ui: "D065170",
    heading: "Pregnancy, Angular",
    tree_numbers: ["C13.703.733.619"],
  },
  {
    ui: "D065172",
    heading: "Pregnancy, Ovarian",
    tree_numbers: ["C13.703.733.661"],
  },
  {
    ui: "D065173",
    heading: "Pregnancy, Cornual",
    tree_numbers: ["C13.703.733.640"],
  },
  {
    ui: "D065227",
    heading: "Transfusion Reaction",
    tree_numbers: ["C15.378.962", "C20.920"],
  },
  {
    ui: "D065290",
    heading: "Acute-On-Chronic Liver Failure",
    tree_numbers: ["C06.552.308.500.750.249"],
  },
  {
    ui: "D065306",
    heading: "Corneal Injuries",
    tree_numbers: ["C10.900.300.284.250.124", "C11.204.284", "C11.297.374", "C26.915.300.425.250.124"],
  },
  {
    ui: "D065308",
    heading: "Morphological and Microscopic Findings",
    tree_numbers: ["C23.149"],
  },
  {
    ui: "D065309",
    heading: "Atypical Squamous Cells of the Cervix",
    tree_numbers: ["A11.436.123", "C04.834.818.249", "C13.351.500.852.593.074.249", "C23.149.500"],
  },
  {
    ui: "D065310",
    heading: "Squamous Intraepithelial Lesions of the Cervix",
    tree_numbers: ["C04.834.818.500", "C13.351.500.852.593.074.500", "C23.149.688.500"],
  },
  {
    ui: "D065311",
    heading: "Adenocarcinoma in Situ",
    tree_numbers: ["C04.557.470.200.025.014", "C04.557.470.200.240.124", "C23.149.249"],
  },
  {
    ui: "D065446",
    heading: "Premenstrual Dysphoric Disorder",
    tree_numbers: ["C23.550.568.968.500", "F03.600.300.550"],
  },
  {
    ui: "D065606",
    heading: "Metabolic Side Effects of Drugs and Substances",
    tree_numbers: ["C25.100.812", "D27.505.389"],
  },
  {
    ui: "D065626",
    heading: "Non-alcoholic Fatty Liver Disease",
    tree_numbers: ["C06.552.241.519"],
  },
  {
    ui: "D065627",
    heading: "Familial Primary Pulmonary Hypertension",
    tree_numbers: ["C08.381.423.300"],
  },
  {
    ui: "D065630",
    heading: "Hernias, Diaphragmatic, Congenital",
    tree_numbers: ["C16.131.433", "C23.300.707.960.500.116"],
  },
  {
    ui: "D065631",
    heading: "Rhinitis, Allergic",
    tree_numbers: ["C08.460.799.315", "C08.674.453", "C09.603.799.315", "C20.543.480.680.443"],
  },
  {
    ui: "D065632",
    heading: "Chikungunya Fever",
    tree_numbers: ["C01.920.500.078.184", "C01.925.081.198", "C01.925.782.930.100.184"],
  },
  {
    ui: "D065634",
    heading: "Cerebrospinal Fluid Leak",
    tree_numbers: ["C10.597.114", "C10.900.300.109", "C23.888.592.114", "C26.915.300.225"],
  },
  {
    ui: "D065635",
    heading: "Benign Paroxysmal Positional Vertigo",
    tree_numbers: ["C09.218.568.900.883.500", "C10.597.951.500", "C23.888.592.958.500"],
    synonyms: ["Benign Recurrent Vertigo", "Benign Paroxysmal Positional Nystagmus"],
  },
  {
    ui: "D065646",
    heading: "Thyroid Carcinoma, Anaplastic",
    tree_numbers: ["C04.557.470.200.725"],
  },
  {
    ui: "D065666",
    heading: "Mesenteric Ischemia",
    tree_numbers: ["C06.405.469.656", "C06.844.460", "C14.907.549"],
  },
  {
    ui: "D065703",
    heading: "Malformations of Cortical Development, Group I",
    tree_numbers: ["C10.500.507.400", "C16.131.666.507.400"],
  },
  {
    ui: "D065704",
    heading: "Malformations of Cortical Development, Group III",
    tree_numbers: ["C10.500.507.500", "C16.131.666.507.500"],
  },
  {
    ui: "D065705",
    heading: "Hemimegalencephaly",
    tree_numbers: [
      "C05.660.207.536.500",
      "C10.500.507.400.249.500",
      "C16.131.621.207.532.500",
      "C16.131.666.507.400.249.500",
    ],
  },
  {
    ui: "D065706",
    heading: "Polymicrogyria",
    tree_numbers: ["C10.500.507.500.500", "C16.131.666.507.500.500"],
  },
  {
    ui: "D065707",
    heading: "Schizencephaly",
    tree_numbers: ["C10.500.507.500.750", "C16.131.666.507.500.750"],
  },
  {
    ui: "D065708",
    heading: "Porencephaly",
    tree_numbers: ["C05.660.207.620.500", "C10.500.507.500.625", "C16.131.621.207.620.500", "C16.131.666.507.500.625"],
  },
  {
    ui: "D065710",
    heading: "Glossoptosis",
    tree_numbers: ["C07.465.910.411"],
  },
  {
    ui: "D065766",
    heading: "Atypical Hemolytic Uremic Syndrome",
    tree_numbers: [
      "C12.777.419.936.463.500",
      "C13.351.968.419.936.463.500",
      "C15.378.071.141.610.500",
      "C15.378.140.855.925.500.500",
    ],
  },
  {
    ui: "D065768",
    heading: "Lennox Gastaut Syndrome",
    tree_numbers: ["C10.228.140.490.493.750", "C16.320.495"],
  },
  {
    ui: "D065817",
    heading: "Congenital Microtia",
    tree_numbers: ["C09.218.235", "C16.131.287"],
  },
  {
    ui: "D065886",
    heading: "Neurodevelopmental Disorders",
    tree_numbers: ["F03.625"],
  },
  {
    ui: "D065906",
    heading: "Hyperlactatemia",
    tree_numbers: ["C18.452.479", "C23.888.516"],
  },
  {
    ui: "D065907",
    heading: "Karoshi Death",
    tree_numbers: ["C14.280.383.220.500", "C23.550.260.322.250.500"],
  },
  {
    ui: "D066087",
    heading: "Perinatal Death",
    tree_numbers: ["C13.703.572", "C23.550.260.865"],
  },
  {
    ui: "D066088",
    heading: "Infant Death",
    tree_numbers: ["C23.550.260.657"],
  },
  {
    ui: "D066126",
    heading: "Cardiotoxicity",
    tree_numbers: ["C14.280.260", "C23.550.161", "C25.100.389", "C26.733.266", "G01.750.748.500.266"],
  },
  {
    ui: "D066166",
    heading: "Pectus Carinatum",
    tree_numbers: ["C05.116.099.739", "C05.182.525", "C05.660.745", "C16.131.621.745", "C17.300.182.525"],
  },
  {
    ui: "D066190",
    heading: "Allesthesia",
    tree_numbers: ["C10.597.606.762.175", "C23.888.592.604.764.175", "F01.700.750.175"],
  },
  {
    ui: "D066229",
    heading: "Speech Sound Disorder",
    tree_numbers: ["F03.625.374.500"],
  },
  {
    ui: "D066253",
    heading: "Vascular Remodeling",
    tree_numbers: ["C23.300.977", "C23.550.918", "G09.330.930"],
  },
  {
    ui: "D066263",
    heading: "Protein Aggregation, Pathological",
    tree_numbers: ["C23.550.770", "G02.111.675"],
  },
];
