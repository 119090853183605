interface State {
  art_state_id: string;
  state_code: string;
  name: string;
  country_code: string;
  lat: number;
  lon: number;
}

export const states: State[] = [
  {
    art_state_id: "AST0866F847AD",
    state_code: "SN",
    name: "Southern Nations, Nationalities, and Peoples' Region",
    country_code: "ET",
    lat: 6.5156911,
    lon: 36.954107,
  },
  {
    art_state_id: "AST45CBCADF4F",
    state_code: "SO",
    name: "Somali Region",
    country_code: "ET",
    lat: 6.6612293,
    lon: 43.7908453,
  },
  {
    art_state_id: "AST010E859224",
    state_code: "AM",
    name: "Amhara Region",
    country_code: "ET",
    lat: 11.3494247,
    lon: 37.9784585,
  },
  {
    art_state_id: "AST062E07CF50",
    state_code: "TI",
    name: "Tigray Region",
    country_code: "ET",
    lat: 14.0323336,
    lon: 38.3165725,
  },
  {
    art_state_id: "ASTA2FF61773D",
    state_code: "OR",
    name: "Oromia Region",
    country_code: "ET",
    lat: 7.5460377,
    lon: 40.6346851,
  },
  {
    art_state_id: "AST2E45DB0FBB",
    state_code: "AF",
    name: "Afar Region",
    country_code: "ET",
    lat: 11.7559388,
    lon: 40.958688,
  },
  {
    art_state_id: "AST5F3441C296",
    state_code: "HA",
    name: "Harari Region",
    country_code: "ET",
    lat: 9.314866,
    lon: 42.1967716,
  },
  {
    art_state_id: "ASTA0F653B0D7",
    state_code: "DD",
    name: "Dire Dawa",
    country_code: "ET",
    lat: 9.6008747,
    lon: 41.850142,
  },
  {
    art_state_id: "ASTE54C7F1130",
    state_code: "BE",
    name: "Benishangul-Gumuz Region",
    country_code: "ET",
    lat: 10.7802889,
    lon: 35.5657862,
  },
  {
    art_state_id: "ASTA798591F77",
    state_code: "GA",
    name: "Gambela Region",
    country_code: "ET",
    lat: 7.9219687,
    lon: 34.1531947,
  },
  {
    art_state_id: "AST0E37D7DB6D",
    state_code: "AA",
    name: "Addis Ababa",
    country_code: "ET",
    lat: 8.9806034,
    lon: 38.7577605,
  },
  {
    art_state_id: "AST22964ECFA7",
    state_code: "23",
    name: "Petnjica Municipality",
    country_code: "ME",
    lat: 42.935348,
    lon: 20.0211449,
  },
  {
    art_state_id: "AST13A097DA6F",
    state_code: "02",
    name: "Bar Municipality",
    country_code: "ME",
    lat: 42.1278119,
    lon: 19.140438,
  },
  {
    art_state_id: "AST6C9BB8A423",
    state_code: "07",
    name: "Danilovgrad Municipality",
    country_code: "ME",
    lat: 42.58357,
    lon: 19.140438,
  },
  {
    art_state_id: "ASTCB170BF230",
    state_code: "17",
    name: "Rožaje Municipality",
    country_code: "ME",
    lat: 42.8408389,
    lon: 20.1670628,
  },
  {
    art_state_id: "AST0BBB69BB0F",
    state_code: "15",
    name: "Plužine Municipality",
    country_code: "ME",
    lat: 43.1593384,
    lon: 18.8551484,
  },
  {
    art_state_id: "ASTF48490F1DE",
    state_code: "12",
    name: "Nikšić Municipality",
    country_code: "ME",
    lat: 42.7997184,
    lon: 18.7600963,
  },
  {
    art_state_id: "AST1CEF980F7B",
    state_code: "18",
    name: "Šavnik Municipality",
    country_code: "ME",
    lat: 42.9603756,
    lon: 19.140438,
  },
  {
    art_state_id: "AST4B32E529F7",
    state_code: "13",
    name: "Plav Municipality",
    country_code: "ME",
    lat: 42.6001337,
    lon: 19.9407541,
  },
  {
    art_state_id: "AST0FF58BD652",
    state_code: "14",
    name: "Pljevlja Municipality",
    country_code: "ME",
    lat: 43.2723383,
    lon: 19.2831531,
  },
  {
    art_state_id: "ASTFF6E26B26C",
    state_code: "03",
    name: "Berane Municipality",
    country_code: "ME",
    lat: 42.8257289,
    lon: 19.9020509,
  },
  {
    art_state_id: "AST77F8606DC5",
    state_code: "11",
    name: "Mojkovac Municipality",
    country_code: "ME",
    lat: 42.9688018,
    lon: 19.5211063,
  },
  {
    art_state_id: "AST3A1F5BC229",
    state_code: "01",
    name: "Andrijevica Municipality",
    country_code: "ME",
    lat: 42.7362477,
    lon: 19.7859556,
  },
  {
    art_state_id: "AST4D819BBAB2",
    state_code: "22",
    name: "Gusinje Municipality",
    country_code: "ME",
    lat: 42.5563455,
    lon: 19.8306051,
  },
  {
    art_state_id: "AST751404723A",
    state_code: "04",
    name: "Bijelo Polje Municipality",
    country_code: "ME",
    lat: 43.0846526,
    lon: 19.7115472,
  },
  {
    art_state_id: "ASTF0B57A466F",
    state_code: "10",
    name: "Kotor Municipality",
    country_code: "ME",
    lat: 42.5740261,
    lon: 18.6413145,
  },
  {
    art_state_id: "AST244E3B3303",
    state_code: "16",
    name: "Podgorica Municipality",
    country_code: "ME",
    lat: 42.3693834,
    lon: 19.2831531,
  },
  {
    art_state_id: "AST44371B5A66",
    state_code: "06",
    name: "Old Royal Capital Cetinje",
    country_code: "ME",
    lat: 42.3930959,
    lon: 18.9115964,
  },
  {
    art_state_id: "AST3C18BBD32C",
    state_code: "19",
    name: "Tivat Municipality",
    country_code: "ME",
    lat: 42.42348,
    lon: 18.7185184,
  },
  {
    art_state_id: "ASTBC0EAA90D4",
    state_code: "05",
    name: "Budva Municipality",
    country_code: "ME",
    lat: 42.314072,
    lon: 18.8313832,
  },
  {
    art_state_id: "AST3DCE8F6F52",
    state_code: "09",
    name: "Kolašin Municipality",
    country_code: "ME",
    lat: 42.7601916,
    lon: 19.4259114,
  },
  {
    art_state_id: "AST730576B0C6",
    state_code: "21",
    name: "Žabljak Municipality",
    country_code: "ME",
    lat: 43.1555152,
    lon: 19.1226018,
  },
  {
    art_state_id: "ASTFA729A43BC",
    state_code: "20",
    name: "Ulcinj Municipality",
    country_code: "ME",
    lat: 41.9652795,
    lon: 19.3069432,
  },
  {
    art_state_id: "ASTC932C96F7C",
    state_code: "KU",
    name: "Kunene Region",
    country_code: "NA",
    lat: -19.4086317,
    lon: 13.914399,
  },
  {
    art_state_id: "AST36DB22739C",
    state_code: "KW",
    name: "Kavango West Region",
    country_code: "NA",
    lat: -18.271048,
    lon: 18.4276047,
  },
  {
    art_state_id: "AST910BA4B71A",
    state_code: "KE",
    name: "Kavango East Region",
    country_code: "NA",
    lat: -18.271048,
    lon: 18.4276047,
  },
  {
    art_state_id: "AST52E5FB4D7F",
    state_code: "ON",
    name: "Oshana Region",
    country_code: "NA",
    lat: -18.4305064,
    lon: 15.6881788,
  },
  {
    art_state_id: "ASTC0F51EA211",
    state_code: "HA",
    name: "Hardap Region",
    country_code: "NA",
    lat: -24.2310134,
    lon: 17.668887,
  },
  {
    art_state_id: "AST782F804A6F",
    state_code: "OS",
    name: "Omusati Region",
    country_code: "NA",
    lat: -18.4070294,
    lon: 14.8454619,
  },
  {
    art_state_id: "ASTB562465A80",
    state_code: "OW",
    name: "Ohangwena Region",
    country_code: "NA",
    lat: -17.5979291,
    lon: 16.8178377,
  },
  {
    art_state_id: "ASTFCA496812B",
    state_code: "OH",
    name: "Omaheke Region",
    country_code: "NA",
    lat: -21.8466651,
    lon: 19.1880047,
  },
  {
    art_state_id: "ASTDBC3083E15",
    state_code: "OT",
    name: "Oshikoto Region",
    country_code: "NA",
    lat: -18.4152575,
    lon: 16.912251,
  },
  {
    art_state_id: "ASTA032A392BE",
    state_code: "ER",
    name: "Erongo Region",
    country_code: "NA",
    lat: -22.2565682,
    lon: 15.4068079,
  },
  {
    art_state_id: "AST1AA815BEDB",
    state_code: "KH",
    name: "Khomas Region",
    country_code: "NA",
    lat: -22.6377854,
    lon: 17.1011931,
  },
  {
    art_state_id: "ASTC81ECE152B",
    state_code: "KA",
    name: "Karas Region",
    country_code: "NA",
    lat: -26.8429645,
    lon: 17.2902839,
  },
  {
    art_state_id: "AST1F113D02D1",
    state_code: "OD",
    name: "Otjozondjupa Region",
    country_code: "NA",
    lat: -20.5486916,
    lon: 17.668887,
  },
  {
    art_state_id: "AST520E61E9D0",
    state_code: "CA",
    name: "Zambezi Region",
    country_code: "NA",
    lat: -17.8193419,
    lon: 23.9536466,
  },
  {
    art_state_id: "AST7C5B13F632",
    state_code: "AH",
    name: "Ashanti Region",
    country_code: "GH",
    lat: 6.7470436,
    lon: -1.5208624,
  },
  {
    art_state_id: "ASTB2270A0A56",
    state_code: "WP",
    name: "Western Region",
    country_code: "GH",
    lat: 5.4261241,
    lon: -2.1286574,
  },
  {
    art_state_id: "ASTD064CBF924",
    state_code: "EP",
    name: "Eastern Region",
    country_code: "GH",
    lat: 6.446877,
    lon: -0.3770964,
  },
  {
    art_state_id: "ASTA46184F7A0",
    state_code: "NP",
    name: "Northern Region",
    country_code: "GH",
    lat: 9.6600005,
    lon: -0.3943799,
  },
  {
    art_state_id: "AST530ACD2E70",
    state_code: "CP",
    name: "Central Region",
    country_code: "GH",
    lat: 5.7244148,
    lon: -1.3761749,
  },
  {
    art_state_id: "ASTD68A1CDF6A",
    state_code: "BA",
    name: "Brong-Ahafo Region",
    country_code: "GH",
    lat: 7.9559247,
    lon: -1.6760691,
  },
  {
    art_state_id: "AST45B4391F2D",
    state_code: "AA",
    name: "Greater Accra Region",
    country_code: "GH",
    lat: 5.8142836,
    lon: 0.0746767,
  },
  {
    art_state_id: "AST1799112D75",
    state_code: "UE",
    name: "Upper East Region",
    country_code: "GH",
    lat: 10.7082499,
    lon: -0.9820668,
  },
  {
    art_state_id: "ASTBAB6A9048D",
    state_code: "TV",
    name: "Volta Region",
    country_code: "GH",
    lat: 6.5781373,
    lon: 0.4502368,
  },
  {
    art_state_id: "ASTAD6C07D565",
    state_code: "UW",
    name: "Upper West Region",
    country_code: "GH",
    lat: 10.2529757,
    lon: -2.1450245,
  },
  {
    art_state_id: "ASTB28B456CEB",
    state_code: "07",
    name: "San Marino",
    country_code: "SM",
    lat: 43.94236,
    lon: 12.457777,
  },
  {
    art_state_id: "AST945C9B1F51",
    state_code: "01",
    name: "Acquaviva",
    country_code: "SM",
    lat: 41.8671597,
    lon: 14.7469479,
  },
  {
    art_state_id: "AST49763E1EC4",
    state_code: "02",
    name: "Chiesanuova",
    country_code: "SM",
    lat: 45.4226172,
    lon: 7.6503854,
  },
  {
    art_state_id: "ASTF840C6DD40",
    state_code: "06",
    name: "Borgo Maggiore",
    country_code: "SM",
    lat: 43.9574882,
    lon: 12.4552546,
  },
  {
    art_state_id: "AST9A1FCEBDF4",
    state_code: "04",
    name: "Faetano",
    country_code: "SM",
    lat: 43.9348967,
    lon: 12.4896554,
  },
  {
    art_state_id: "AST556C0412D2",
    state_code: "08",
    name: "Montegiardino",
    country_code: "SM",
    lat: 43.9052999,
    lon: 12.4810542,
  },
  {
    art_state_id: "AST69DF65F32E",
    state_code: "03",
    name: "Domagnano",
    country_code: "SM",
    lat: 43.9501929,
    lon: 12.4681537,
  },
  {
    art_state_id: "ASTC3A9FDC6EA",
    state_code: "09",
    name: "Serravalle",
    country_code: "SM",
    lat: 44.7232084,
    lon: 8.8574005,
  },
  {
    art_state_id: "ASTD98A61D32A",
    state_code: "05",
    name: "Fiorentino",
    country_code: "SM",
    lat: 43.9078337,
    lon: 12.4581209,
  },
  {
    art_state_id: "AST6B6CB7CD44",
    state_code: "6",
    name: "Tillabéri Region",
    country_code: "NE",
    lat: 14.6489525,
    lon: 2.1450245,
  },
  {
    art_state_id: "ASTC5BBE4F1BE",
    state_code: "3",
    name: "Dosso Region",
    country_code: "NE",
    lat: 13.1513947,
    lon: 3.4195527,
  },
  {
    art_state_id: "AST5D1404FA7E",
    state_code: "7",
    name: "Zinder Region",
    country_code: "NE",
    lat: 15.1718881,
    lon: 10.2600125,
  },
  {
    art_state_id: "ASTA1BA3BB145",
    state_code: "4",
    name: "Maradi Region",
    country_code: "NE",
    lat: 13.8018074,
    lon: 7.4381355,
  },
  {
    art_state_id: "ASTDF3CB75B55",
    state_code: "1",
    name: "Agadez Region",
    country_code: "NE",
    lat: 20.6670752,
    lon: 12.0718281,
  },
  {
    art_state_id: "AST682EED2650",
    state_code: "2",
    name: "Diffa Region",
    country_code: "NE",
    lat: 13.6768647,
    lon: 12.7135121,
  },
  {
    art_state_id: "AST17892BF8A3",
    state_code: "5",
    name: "Tahoua Region",
    country_code: "NE",
    lat: 16.0902543,
    lon: 5.3939551,
  },
  {
    art_state_id: "AST0CCFCFBABD",
    state_code: "33",
    name: "Mqabba",
    country_code: "MT",
    lat: 35.8444143,
    lon: 14.4694186,
  },
  {
    art_state_id: "AST5E04193CB2",
    state_code: "49",
    name: "San Ġwann",
    country_code: "MT",
    lat: 35.9077365,
    lon: 14.4752416,
  },
  {
    art_state_id: "AST823DCB852C",
    state_code: "68",
    name: "Żurrieq",
    country_code: "MT",
    lat: 35.8216306,
    lon: 14.4810648,
  },
  {
    art_state_id: "ASTB77350439B",
    state_code: "25",
    name: "Luqa",
    country_code: "MT",
    lat: 35.8582865,
    lon: 14.4868883,
  },
  {
    art_state_id: "ASTBF4A886C63",
    state_code: "28",
    name: "Marsaxlokk",
    country_code: "MT",
    lat: 35.8411699,
    lon: 14.5393097,
  },
  {
    art_state_id: "AST554DC2B6AC",
    state_code: "42",
    name: "Qala",
    country_code: "MT",
    lat: 36.0388628,
    lon: 14.318101,
  },
  {
    art_state_id: "AST9F5FEEC6CB",
    state_code: "66",
    name: "Żebbuġ Malta",
    country_code: "MT",
    lat: 35.8764648,
    lon: 14.439084,
  },
  {
    art_state_id: "AST7D3648DC8A",
    state_code: "63",
    name: "Xgħajra",
    country_code: "MT",
    lat: 35.8868282,
    lon: 14.5472391,
  },
  {
    art_state_id: "AST8C82371561",
    state_code: "23",
    name: "Kirkop",
    country_code: "MT",
    lat: 35.8437862,
    lon: 14.4854324,
  },
  {
    art_state_id: "ASTCF7866F784",
    state_code: "46",
    name: "Rabat",
    country_code: "MT",
    lat: 33.9715904,
    lon: -6.8498129,
  },
  {
    art_state_id: "ASTEFB8EFCB40",
    state_code: "09",
    name: "Floriana",
    country_code: "MT",
    lat: 45.4952185,
    lon: -73.7139576,
  },
  {
    art_state_id: "AST06B68BCAE5",
    state_code: "65",
    name: "Żebbuġ Gozo",
    country_code: "MT",
    lat: 36.0716403,
    lon: 14.245408,
  },
  {
    art_state_id: "AST27D8EFFB52",
    state_code: "57",
    name: "Swieqi",
    country_code: "MT",
    lat: 35.9191182,
    lon: 14.4694186,
  },
  {
    art_state_id: "AST1E344BB14C",
    state_code: "50",
    name: "Saint Lawrence",
    country_code: "MT",
    lat: 38.9578056,
    lon: -95.2565689,
  },
  {
    art_state_id: "AST490138779F",
    state_code: "05",
    name: "Birżebbuġa",
    country_code: "MT",
    lat: 35.8135989,
    lon: 14.5247463,
  },
  {
    art_state_id: "ASTF0DC15F132",
    state_code: "29",
    name: "Mdina",
    country_code: "MT",
    lat: 35.888093,
    lon: 14.4068357,
  },
  {
    art_state_id: "ASTD0D3754EE4",
    state_code: "54",
    name: "Santa Venera",
    country_code: "MT",
    lat: 35.8902201,
    lon: 14.4766974,
  },
  {
    art_state_id: "AST5AE4097BF6",
    state_code: "22",
    name: "Kerċem",
    country_code: "MT",
    lat: 36.0447939,
    lon: 14.2250605,
  },
  {
    art_state_id: "ASTEFC7A886BD",
    state_code: "14",
    name: "Għarb",
    country_code: "MT",
    lat: 36.068909,
    lon: 14.2018098,
  },
  {
    art_state_id: "ASTCD3B70DAFF",
    state_code: "19",
    name: "Iklin",
    country_code: "MT",
    lat: 35.9098774,
    lon: 14.4577732,
  },
  {
    art_state_id: "ASTD286C099D7",
    state_code: "53",
    name: "Santa Luċija",
    country_code: "MT",
    lat: 35.856142,
    lon: 14.50436,
  },
  {
    art_state_id: "ASTE344BF8C95",
    state_code: "60",
    name: "Valletta",
    country_code: "MT",
    lat: 35.8989085,
    lon: 14.5145528,
  },
  {
    art_state_id: "AST67607BF758",
    state_code: "34",
    name: "Msida",
    country_code: "MT",
    lat: 35.8956388,
    lon: 14.4868883,
  },
  {
    art_state_id: "AST3A27E0A1FF",
    state_code: "04",
    name: "Birkirkara",
    country_code: "MT",
    lat: 35.8954706,
    lon: 14.4665072,
  },
  {
    art_state_id: "ASTD7CA0FED86",
    state_code: "55",
    name: "Siġġiewi",
    country_code: "MT",
    lat: 35.8463742,
    lon: 14.4315746,
  },
  {
    art_state_id: "ASTC4C85A2F47",
    state_code: "21",
    name: "Kalkara",
    country_code: "MT",
    lat: 35.8914242,
    lon: 14.5320278,
  },
  {
    art_state_id: "ASTC310CF3784",
    state_code: "48",
    name: "St. Julian's",
    country_code: "MT",
    lat: 42.2122513,
    lon: -85.8917127,
  },
  {
    art_state_id: "AST9294B8C9A1",
    state_code: "45",
    name: "Victoria",
    country_code: "MT",
    lat: 28.8052674,
    lon: -97.0035982,
  },
  {
    art_state_id: "AST864A666101",
    state_code: "30",
    name: "Mellieħa",
    country_code: "MT",
    lat: 35.9523529,
    lon: 14.3500975,
  },
  {
    art_state_id: "ASTEDAB55EDB5",
    state_code: "59",
    name: "Tarxien",
    country_code: "MT",
    lat: 35.8672552,
    lon: 14.5116405,
  },
  {
    art_state_id: "AST2E7AEF37A6",
    state_code: "56",
    name: "Sliema",
    country_code: "MT",
    lat: 35.9110081,
    lon: 14.502904,
  },
  {
    art_state_id: "ASTD76A1B288E",
    state_code: "18",
    name: "Ħamrun",
    country_code: "MT",
    lat: 35.8861237,
    lon: 14.4883442,
  },
  {
    art_state_id: "ASTC2D7A2EE93",
    state_code: "16",
    name: "Għasri",
    country_code: "MT",
    lat: 36.0668075,
    lon: 14.2192475,
  },
  {
    art_state_id: "ASTA77BB11FF6",
    state_code: "03",
    name: "Birgu",
    country_code: "MT",
    lat: 35.8879214,
    lon: 14.522562,
  },
  {
    art_state_id: "ASTD6F67377DC",
    state_code: "02",
    name: "Balzan",
    country_code: "MT",
    lat: 35.8957414,
    lon: 14.4534065,
  },
  {
    art_state_id: "AST6480A6D6F0",
    state_code: "31",
    name: "Mġarr",
    country_code: "MT",
    lat: 35.9189327,
    lon: 14.3617343,
  },
  {
    art_state_id: "ASTE0E1C739F0",
    state_code: "01",
    name: "Attard",
    country_code: "MT",
    lat: 35.8904967,
    lon: 14.4199322,
  },
  {
    art_state_id: "AST9C36FBE5F7",
    state_code: "44",
    name: "Qrendi",
    country_code: "MT",
    lat: 35.8328488,
    lon: 14.4548621,
  },
  {
    art_state_id: "AST980DE2F584",
    state_code: "38",
    name: "Naxxar",
    country_code: "MT",
    lat: 35.9317518,
    lon: 14.4315746,
  },
  {
    art_state_id: "ASTE53C8D2F5D",
    state_code: "12",
    name: "Gżira",
    country_code: "MT",
    lat: 35.905897,
    lon: 14.4953338,
  },
  {
    art_state_id: "ASTB156D0B6A6",
    state_code: "61",
    name: "Xagħra",
    country_code: "MT",
    lat: 36.050845,
    lon: 14.267482,
  },
  {
    art_state_id: "AST41763C343F",
    state_code: "39",
    name: "Paola",
    country_code: "MT",
    lat: 38.5722353,
    lon: -94.8791294,
  },
  {
    art_state_id: "AST2295D26B0E",
    state_code: "52",
    name: "Sannat",
    country_code: "MT",
    lat: 36.0192643,
    lon: 14.2599437,
  },
  {
    art_state_id: "AST95CC22D762",
    state_code: "07",
    name: "Dingli",
    country_code: "MT",
    lat: 35.8627309,
    lon: 14.3850107,
  },
  {
    art_state_id: "AST7A752B0A83",
    state_code: "11",
    name: "Gudja",
    country_code: "MT",
    lat: 35.8469803,
    lon: 14.502904,
  },
  {
    art_state_id: "ASTE29F75FB49",
    state_code: "43",
    name: "Qormi",
    country_code: "MT",
    lat: 35.8764388,
    lon: 14.4694186,
  },
  {
    art_state_id: "ASTC0E8015B35",
    state_code: "15",
    name: "Għargħur",
    country_code: "MT",
    lat: 35.9220569,
    lon: 14.4563176,
  },
  {
    art_state_id: "ASTD9EF93FE5B",
    state_code: "62",
    name: "Xewkija",
    country_code: "MT",
    lat: 36.0299236,
    lon: 14.2599437,
  },
  {
    art_state_id: "AST327243D387",
    state_code: "58",
    name: "Ta' Xbiex",
    country_code: "MT",
    lat: 35.8991448,
    lon: 14.4963519,
  },
  {
    art_state_id: "AST75F2734164",
    state_code: "64",
    name: "Żabbar",
    country_code: "MT",
    lat: 35.8724715,
    lon: 14.5451354,
  },
  {
    art_state_id: "AST9CD200E0EC",
    state_code: "17",
    name: "Għaxaq",
    country_code: "MT",
    lat: 35.8440359,
    lon: 14.516009,
  },
  {
    art_state_id: "AST5AAC58CC91",
    state_code: "40",
    name: "Pembroke",
    country_code: "MT",
    lat: 34.6801626,
    lon: -79.1950373,
  },
  {
    art_state_id: "AST9B08A96A90",
    state_code: "24",
    name: "Lija",
    country_code: "MT",
    lat: 49.180076,
    lon: -123.103317,
  },
  {
    art_state_id: "AST439198296B",
    state_code: "41",
    name: "Pietà",
    country_code: "MT",
    lat: 42.21862,
    lon: -83.734647,
  },
  {
    art_state_id: "AST15A9A3CEF3",
    state_code: "26",
    name: "Marsa",
    country_code: "MT",
    lat: 34.0319587,
    lon: -118.4455535,
  },
  {
    art_state_id: "AST0F97E01BCD",
    state_code: "08",
    name: "Fgura",
    country_code: "MT",
    lat: 35.8738269,
    lon: 14.5232901,
  },
  {
    art_state_id: "AST4A922CC2CF",
    state_code: "13",
    name: "Għajnsielem",
    country_code: "MT",
    lat: 36.0247966,
    lon: 14.2802961,
  },
  {
    art_state_id: "AST2B04EC1137",
    state_code: "35",
    name: "Mtarfa",
    country_code: "MT",
    lat: 35.8895125,
    lon: 14.3951953,
  },
  {
    art_state_id: "AST90B399A520",
    state_code: "36",
    name: "Munxar",
    country_code: "MT",
    lat: 36.0288058,
    lon: 14.2250605,
  },
  {
    art_state_id: "AST0AB900B945",
    state_code: "37",
    name: "Nadur",
    country_code: "MT",
    lat: 36.0447019,
    lon: 14.2919273,
  },
  {
    art_state_id: "AST18911F30E5",
    state_code: "10",
    name: "Fontana",
    country_code: "MT",
    lat: 34.0922335,
    lon: -117.435048,
  },
  {
    art_state_id: "AST5E716F8515",
    state_code: "67",
    name: "Żejtun",
    country_code: "MT",
    lat: 35.8548714,
    lon: 14.5363969,
  },
  {
    art_state_id: "AST9E4FFDFBA1",
    state_code: "20",
    name: "Senglea",
    country_code: "MT",
    lat: 35.8873041,
    lon: 14.5167371,
  },
  {
    art_state_id: "ASTEF4E3EA300",
    state_code: "27",
    name: "Marsaskala",
    country_code: "MT",
    lat: 35.860364,
    lon: 14.5567876,
  },
  {
    art_state_id: "AST2D12B82AA4",
    state_code: "06",
    name: "Cospicua",
    country_code: "MT",
    lat: 35.8806753,
    lon: 14.5218338,
  },
  {
    art_state_id: "AST494C1B68B8",
    state_code: "51",
    name: "St. Paul's Bay",
    country_code: "MT",
    lat: 35.936017,
    lon: 14.3966503,
  },
  {
    art_state_id: "AST98BEBCC51C",
    state_code: "32",
    name: "Mosta",
    country_code: "MT",
    lat: 35.9141504,
    lon: 14.4228427,
  },
  {
    art_state_id: "ASTC4FD12C882",
    state_code: "MAN",
    name: "Mangystau Region",
    country_code: "KZ",
    lat: 44.590802,
    lon: 53.8499508,
  },
  {
    art_state_id: "AST6E43B868F0",
    state_code: "KZY",
    name: "Kyzylorda Region",
    country_code: "KZ",
    lat: 44.6922613,
    lon: 62.6571885,
  },
  {
    art_state_id: "AST7F5915B1A7",
    state_code: "ALM",
    name: "Almaty Region",
    country_code: "KZ",
    lat: 45.0119227,
    lon: 78.4229392,
  },
  {
    art_state_id: "AST90D7ABB40A",
    state_code: "SEV",
    name: "North Kazakhstan Region",
    country_code: "KZ",
    lat: 54.1622066,
    lon: 69.9387071,
  },
  {
    art_state_id: "AST749C3BDECD",
    state_code: "AKM",
    name: "Akmola Region",
    country_code: "KZ",
    lat: 51.916532,
    lon: 69.4110494,
  },
  {
    art_state_id: "AST7FA0EC5436",
    state_code: "PAV",
    name: "Pavlodar Region",
    country_code: "KZ",
    lat: 52.2878444,
    lon: 76.9733453,
  },
  {
    art_state_id: "AST6E7E156FA2",
    state_code: "ZHA",
    name: "Jambyl Region",
    country_code: "KZ",
    lat: 44.2220308,
    lon: 72.3657967,
  },
  {
    art_state_id: "ASTD1A99549AF",
    state_code: "ZAP",
    name: "West Kazakhstan Province",
    country_code: "KZ",
    lat: 49.5679727,
    lon: 50.8066616,
  },
  {
    art_state_id: "AST69E61566CB",
    state_code: "YUZ",
    name: "Turkestan Region",
    country_code: "KZ",
    lat: 43.3666958,
    lon: 68.4094405,
  },
  {
    art_state_id: "ASTBA17547F27",
    state_code: "KAR",
    name: "Karaganda Region",
    country_code: "KZ",
    lat: 47.9022182,
    lon: 71.7706807,
  },
  {
    art_state_id: "ASTC1731F1599",
    state_code: "AKT",
    name: "Aktobe Region",
    country_code: "KZ",
    lat: 48.7797078,
    lon: 57.9974378,
  },
  {
    art_state_id: "ASTDDC539A869",
    state_code: "ALA",
    name: "Almaty",
    country_code: "KZ",
    lat: 43.2220146,
    lon: 76.8512485,
  },
  {
    art_state_id: "AST8AC2866B04",
    state_code: "ATY",
    name: "Atyrau Region",
    country_code: "KZ",
    lat: 47.1076188,
    lon: 51.914133,
  },
  {
    art_state_id: "AST3FFDD5BD8C",
    state_code: "VOS",
    name: "East Kazakhstan Region",
    country_code: "KZ",
    lat: 48.7062687,
    lon: 80.7922534,
  },
  {
    art_state_id: "AST978F074846",
    state_code: "BAY",
    name: "Baikonur",
    country_code: "KZ",
    lat: 45.9645851,
    lon: 63.3052428,
  },
  {
    art_state_id: "AST06AAAD7191",
    state_code: "AST",
    name: "Nur-Sultan",
    country_code: "KZ",
    lat: 51.1605227,
    lon: 71.4703558,
  },
  {
    art_state_id: "AST3F86AD31D9",
    state_code: "KUS",
    name: "Kostanay Region",
    country_code: "KZ",
    lat: 51.5077096,
    lon: 64.0479073,
  },
  {
    art_state_id: "ASTB5A0009A8E",
    state_code: "11",
    name: "Kakamega County",
    country_code: "KE",
    lat: 0.307894,
    lon: 34.7740793,
  },
  {
    art_state_id: "AST0B5E52CF85",
    state_code: "16",
    name: "Kisii County",
    country_code: "KE",
    lat: -0.677334,
    lon: 34.779603,
  },
  {
    art_state_id: "AST0DED84FA41",
    state_code: "200",
    name: "Central Province",
    country_code: "KE",
    lat: 1.4419683,
    lon: 38.4313975,
  },
  {
    art_state_id: "ASTCC832BB733",
    state_code: "04",
    name: "Busia County",
    country_code: "KE",
    lat: 0.4346506,
    lon: 34.2421597,
  },
  {
    art_state_id: "AST5531414877",
    state_code: "500",
    name: "North Eastern Province",
    country_code: "KE",
    lat: 7.7853051,
    lon: 81.4278984,
  },
  {
    art_state_id: "AST6F218BF135",
    state_code: "06",
    name: "Embu County",
    country_code: "KE",
    lat: -0.6560477,
    lon: 37.7237678,
  },
  {
    art_state_id: "AST577346E754",
    state_code: "20",
    name: "Laikipia County",
    country_code: "KE",
    lat: 0.3606063,
    lon: 36.7819505,
  },
  {
    art_state_id: "AST1180CF106D",
    state_code: "32",
    name: "Nandi District",
    country_code: "KE",
    lat: 0.1835867,
    lon: 35.1268781,
  },
  {
    art_state_id: "AST72A8448B9C",
    state_code: "21",
    name: "Lamu County",
    country_code: "KE",
    lat: -2.2355058,
    lon: 40.4720004,
  },
  {
    art_state_id: "ASTE7F247924D",
    state_code: "15",
    name: "Kirinyaga County",
    country_code: "KE",
    lat: -0.6590565,
    lon: 37.3827234,
  },
  {
    art_state_id: "ASTF4E08D9E39",
    state_code: "03",
    name: "Bungoma County",
    country_code: "KE",
    lat: 0.5695252,
    lon: 34.5583766,
  },
  {
    art_state_id: "AST3858B55804",
    state_code: "44",
    name: "Uasin Gishu District",
    country_code: "KE",
    lat: 0.5527638,
    lon: 35.3027226,
  },
  {
    art_state_id: "AST153E3D2204",
    state_code: "09",
    name: "Isiolo County",
    country_code: "KE",
    lat: 0.3524352,
    lon: 38.4849923,
  },
  {
    art_state_id: "ASTB61ED00FE4",
    state_code: "17",
    name: "Kisumu County",
    country_code: "KE",
    lat: -0.0917016,
    lon: 34.7679568,
  },
  {
    art_state_id: "ASTA3D104EF4E",
    state_code: "300",
    name: "Coast Province",
    country_code: "KE",
    lat: 39.1373305,
    lon: -76.7294144,
  },
  {
    art_state_id: "AST49218DBA93",
    state_code: "19",
    name: "Kwale County",
    country_code: "KE",
    lat: -4.1816115,
    lon: 39.4605612,
  },
  {
    art_state_id: "AST46069EB96F",
    state_code: "14",
    name: "Kilifi County",
    country_code: "KE",
    lat: -3.5106508,
    lon: 39.9093269,
  },
  {
    art_state_id: "ASTD1CABC76A9",
    state_code: "33",
    name: "Narok County",
    country_code: "KE",
    lat: -1.104111,
    lon: 36.0893406,
  },
  {
    art_state_id: "ASTB71E06A360",
    state_code: "39",
    name: "Taita–Taveta County",
    country_code: "KE",
    lat: -3.3160687,
    lon: 38.4849923,
  },
  {
    art_state_id: "AST8E42B274F3",
    state_code: "800",
    name: "Western Province",
    country_code: "KE",
    lat: 1.4419683,
    lon: 38.4313975,
  },
  {
    art_state_id: "AST9DF9BE1C37",
    state_code: "29",
    name: "Muranga County",
    country_code: "KE",
    lat: -0.7839281,
    lon: 37.0400339,
  },
  {
    art_state_id: "AST09B0F5FE1E",
    state_code: "700",
    name: "Rift Valley Province",
    country_code: "KE",
    lat: 11.4098737,
    lon: 41.2808577,
  },
  {
    art_state_id: "ASTFAB919BC29",
    state_code: "36",
    name: "Nyeri County",
    country_code: "KE",
    lat: -0.4196915,
    lon: 37.0400339,
  },
  {
    art_state_id: "AST01190555F5",
    state_code: "01",
    name: "Baringo County",
    country_code: "KE",
    lat: 0.8554988,
    lon: 36.0893406,
  },
  {
    art_state_id: "ASTAC16B5324B",
    state_code: "46",
    name: "Wajir County",
    country_code: "KE",
    lat: 1.6360475,
    lon: 40.3088626,
  },
  {
    art_state_id: "ASTC1B4E92D08",
    state_code: "42",
    name: "Trans-Nzoia District",
    country_code: "KE",
    lat: 1.0566667,
    lon: 34.9506625,
  },
  {
    art_state_id: "AST4D9118D56B",
    state_code: "22",
    name: "Machakos County",
    country_code: "KE",
    lat: -1.5176837,
    lon: 37.2634146,
  },
  {
    art_state_id: "AST042884C9C4",
    state_code: "41",
    name: "Tharaka Nithi County",
    country_code: "KE",
    lat: -0.2964851,
    lon: 37.7237678,
  },
  {
    art_state_id: "AST0563B4882C",
    state_code: "38",
    name: "Siaya County",
    country_code: "KE",
    lat: -0.0617328,
    lon: 34.2421597,
  },
  {
    art_state_id: "AST39CB6ADAB7",
    state_code: "24",
    name: "Mandera County",
    country_code: "KE",
    lat: 3.5737991,
    lon: 40.958688,
  },
  {
    art_state_id: "ASTCE8CF83049",
    state_code: "23",
    name: "Makueni County",
    country_code: "KE",
    lat: -2.2558734,
    lon: 37.8936663,
  },
  {
    art_state_id: "AST9E905A0864",
    state_code: "400",
    name: "Eastern Province",
    country_code: "KE",
    lat: 1.4419683,
    lon: 38.4313975,
  },
  {
    art_state_id: "ASTF46D7229BE",
    state_code: "27",
    name: "Migori County",
    country_code: "KE",
    lat: -0.9365702,
    lon: 34.4198243,
  },
  {
    art_state_id: "ASTA2F5C85E3B",
    state_code: "110",
    name: "Nairobi",
    country_code: "KE",
    lat: -1.2920659,
    lon: 36.8219462,
  },
  {
    art_state_id: "AST982147FE74",
    state_code: "35",
    name: "Nyandarua County",
    country_code: "KE",
    lat: -0.1803855,
    lon: 36.5229641,
  },
  {
    art_state_id: "AST59C8DBF4C6",
    state_code: "12",
    name: "Kericho County",
    country_code: "KE",
    lat: -0.1827913,
    lon: 35.4781926,
  },
  {
    art_state_id: "ASTDB75ADC854",
    state_code: "25",
    name: "Marsabit County",
    country_code: "KE",
    lat: 2.4426403,
    lon: 37.9784585,
  },
  {
    art_state_id: "AST6F721FA2A5",
    state_code: "08",
    name: "Homa Bay County",
    country_code: "KE",
    lat: -0.6220655,
    lon: 34.3310364,
  },
  {
    art_state_id: "AST52CDB92262",
    state_code: "07",
    name: "Garissa County",
    country_code: "KE",
    lat: -0.4532293,
    lon: 39.6460988,
  },
  {
    art_state_id: "AST0B9224A7C8",
    state_code: "10",
    name: "Kajiado County",
    country_code: "KE",
    lat: -2.0980751,
    lon: 36.7819505,
  },
  {
    art_state_id: "AST29F6BA8646",
    state_code: "26",
    name: "Meru County",
    country_code: "KE",
    lat: 0.3557174,
    lon: 37.8087693,
  },
  {
    art_state_id: "ASTF5D592E168",
    state_code: "13",
    name: "Kiambu County",
    country_code: "KE",
    lat: -1.0313701,
    lon: 36.8680791,
  },
  {
    art_state_id: "AST5FCD0DCF8B",
    state_code: "28",
    name: "Mombasa County",
    country_code: "KE",
    lat: -3.9768291,
    lon: 39.7137181,
  },
  {
    art_state_id: "AST75E4FC3366",
    state_code: "05",
    name: "Elgeyo-Marakwet County",
    country_code: "KE",
    lat: 1.0498237,
    lon: 35.4781926,
  },
  {
    art_state_id: "ASTFC62AB9B16",
    state_code: "45",
    name: "Vihiga District",
    country_code: "KE",
    lat: 0.0767553,
    lon: 34.7077665,
  },
  {
    art_state_id: "AST8F35E9126F",
    state_code: "31",
    name: "Nakuru District",
    country_code: "KE",
    lat: -0.3030988,
    lon: 36.080026,
  },
  {
    art_state_id: "ASTD466ACEE0E",
    state_code: "600",
    name: "Nyanza Province",
    country_code: "KE",
    lat: -0.0889894,
    lon: 34.7717912,
  },
  {
    art_state_id: "AST9DBD53874F",
    state_code: "40",
    name: "Tana River County",
    country_code: "KE",
    lat: -1.6518468,
    lon: 39.6518165,
  },
  {
    art_state_id: "AST9934BB02BE",
    state_code: "43",
    name: "Turkana County",
    country_code: "KE",
    lat: 3.3122477,
    lon: 35.5657862,
  },
  {
    art_state_id: "AST643C5A5A56",
    state_code: "37",
    name: "Samburu County",
    country_code: "KE",
    lat: 1.2154506,
    lon: 36.954107,
  },
  {
    art_state_id: "ASTFBFAB0EC04",
    state_code: "47",
    name: "West Pokot County",
    country_code: "KE",
    lat: 1.6210076,
    lon: 35.3905046,
  },
  {
    art_state_id: "ASTC90CDF1972",
    state_code: "34",
    name: "Nyamira District",
    country_code: "KE",
    lat: -0.5669405,
    lon: 34.9341234,
  },
  {
    art_state_id: "ASTB812BB0BC6",
    state_code: "02",
    name: "Bomet County",
    country_code: "KE",
    lat: -0.8015009,
    lon: 35.3027226,
  },
  {
    art_state_id: "AST62BC952A8D",
    state_code: "18",
    name: "Kitui County",
    country_code: "KE",
    lat: -1.6832822,
    lon: 38.3165725,
  },
  {
    art_state_id: "ASTD84DF2134C",
    state_code: "BIE",
    name: "Bié Province",
    country_code: "AO",
    lat: -12.5727907,
    lon: 17.668887,
  },
  {
    art_state_id: "AST1E47F755CD",
    state_code: "HUA",
    name: "Huambo Province",
    country_code: "AO",
    lat: -12.5268221,
    lon: 15.5943388,
  },
  {
    art_state_id: "ASTFE9C2AEBBF",
    state_code: "ZAI",
    name: "Zaire Province",
    country_code: "AO",
    lat: -6.5733458,
    lon: 13.1740348,
  },
  {
    art_state_id: "ASTAEA31970DF",
    state_code: "CNN",
    name: "Cunene Province",
    country_code: "AO",
    lat: -16.2802221,
    lon: 16.1580937,
  },
  {
    art_state_id: "AST2609C3597C",
    state_code: "CUS",
    name: "Cuanza Sul",
    country_code: "AO",
    lat: -10.595191,
    lon: 15.4068079,
  },
  {
    art_state_id: "AST69CF3A8275",
    state_code: "CNO",
    name: "Cuanza Norte Province",
    country_code: "AO",
    lat: -9.2398513,
    lon: 14.6587821,
  },
  {
    art_state_id: "AST97670AE4CA",
    state_code: "BGU",
    name: "Benguela Province",
    country_code: "AO",
    lat: -12.8003744,
    lon: 13.914399,
  },
  {
    art_state_id: "AST5D2F2DE350",
    state_code: "MOX",
    name: "Moxico Province",
    country_code: "AO",
    lat: -13.4293579,
    lon: 20.3308814,
  },
  {
    art_state_id: "AST7EA97EEC0C",
    state_code: "LSU",
    name: "Lunda Sul Province",
    country_code: "AO",
    lat: -10.2866578,
    lon: 20.7122465,
  },
  {
    art_state_id: "ASTDC1B233BA9",
    state_code: "BGO",
    name: "Bengo Province",
    country_code: "AO",
    lat: -9.1042257,
    lon: 13.7289167,
  },
  {
    art_state_id: "AST429B6CBE14",
    state_code: "LUA",
    name: "Luanda Province",
    country_code: "AO",
    lat: -9.035088,
    lon: 13.2663479,
  },
  {
    art_state_id: "ASTFDDCC8AD9C",
    state_code: "LNO",
    name: "Lunda Norte Province",
    country_code: "AO",
    lat: -8.3525022,
    lon: 19.1880047,
  },
  {
    art_state_id: "AST50905D01DB",
    state_code: "UIG",
    name: "Uíge Province",
    country_code: "AO",
    lat: -7.1736732,
    lon: 15.4068079,
  },
  {
    art_state_id: "AST6547C826A9",
    state_code: "HUI",
    name: "Huíla Province",
    country_code: "AO",
    lat: -14.9280553,
    lon: 14.6587821,
  },
  {
    art_state_id: "AST1677DB7270",
    state_code: "CCU",
    name: "Cuando Cubango Province",
    country_code: "AO",
    lat: -16.4180824,
    lon: 18.8076195,
  },
  {
    art_state_id: "ASTE3A9CB1D34",
    state_code: "MAL",
    name: "Malanje Province",
    country_code: "AO",
    lat: -9.8251183,
    lon: 16.912251,
  },
  {
    art_state_id: "AST4353456ECD",
    state_code: "CAB",
    name: "Cabinda Province",
    country_code: "AO",
    lat: -5.0248749,
    lon: 12.3463875,
  },
  {
    art_state_id: "AST798F70A4F8",
    state_code: "GA",
    name: "Gasa District",
    country_code: "BT",
    lat: 28.0185886,
    lon: 89.9253233,
  },
  {
    art_state_id: "ASTB5CD1BE3C8",
    state_code: "21",
    name: "Tsirang District",
    country_code: "BT",
    lat: 27.032207,
    lon: 90.1869644,
  },
  {
    art_state_id: "AST2ECEE74EA6",
    state_code: "24",
    name: "Wangdue Phodrang District",
    country_code: "BT",
    lat: 27.4526046,
    lon: 90.0674928,
  },
  {
    art_state_id: "AST304E7CD1AF",
    state_code: "13",
    name: "Haa District",
    country_code: "BT",
    lat: 27.2651669,
    lon: 89.1705998,
  },
  {
    art_state_id: "ASTEFF6B06CB8",
    state_code: "34",
    name: "Zhemgang District",
    country_code: "BT",
    lat: 27.076975,
    lon: 90.8294002,
  },
  {
    art_state_id: "ASTA88F2348A1",
    state_code: "44",
    name: "Lhuntse District",
    country_code: "BT",
    lat: 27.8264989,
    lon: 91.135302,
  },
  {
    art_state_id: "ASTD97A017B1E",
    state_code: "23",
    name: "Punakha District",
    country_code: "BT",
    lat: 27.6903716,
    lon: 89.8879304,
  },
  {
    art_state_id: "ASTAF4100526A",
    state_code: "41",
    name: "Trashigang District",
    country_code: "BT",
    lat: 27.2566795,
    lon: 91.7538817,
  },
  {
    art_state_id: "AST5A787CE07D",
    state_code: "11",
    name: "Paro District",
    country_code: "BT",
    lat: 27.4285949,
    lon: 89.4166516,
  },
  {
    art_state_id: "ASTB1721C6312",
    state_code: "22",
    name: "Dagana District",
    country_code: "BT",
    lat: 27.0322861,
    lon: 89.8879304,
  },
  {
    art_state_id: "AST7A5A449D03",
    state_code: "12",
    name: "Chukha District",
    country_code: "BT",
    lat: 27.0784304,
    lon: 89.4742177,
  },
  {
    art_state_id: "ASTD6176C468C",
    state_code: "33",
    name: "Bumthang District",
    country_code: "BT",
    lat: 27.641839,
    lon: 90.6773046,
  },
  {
    art_state_id: "ASTEB39884D2B",
    state_code: "15",
    name: "Thimphu District",
    country_code: "BT",
    lat: 27.4712216,
    lon: 89.6339041,
  },
  {
    art_state_id: "ASTCF0D18DACA",
    state_code: "42",
    name: "Mongar District",
    country_code: "BT",
    lat: 27.2617059,
    lon: 91.2891036,
  },
  {
    art_state_id: "AST5D0472D197",
    state_code: "45",
    name: "Samdrup Jongkhar District",
    country_code: "BT",
    lat: 26.8035682,
    lon: 91.5039207,
  },
  {
    art_state_id: "ASTD244088E79",
    state_code: "43",
    name: "Pemagatshel District",
    country_code: "BT",
    lat: 27.002382,
    lon: 91.3469247,
  },
  {
    art_state_id: "AST761826B328",
    state_code: "32",
    name: "Trongsa District",
    country_code: "BT",
    lat: 27.5002269,
    lon: 90.5080634,
  },
  {
    art_state_id: "AST66924A4B93",
    state_code: "14",
    name: "Samtse District",
    country_code: "BT",
    lat: 27.0291832,
    lon: 89.0561532,
  },
  {
    art_state_id: "AST095D4DE32A",
    state_code: "31",
    name: "Sarpang District",
    country_code: "BT",
    lat: 26.9373041,
    lon: 90.4879916,
  },
  {
    art_state_id: "ASTE1F2F359BA",
    state_code: "6",
    name: "Tombouctou Region",
    country_code: "ML",
    lat: 21.0526706,
    lon: -3.743509,
  },
  {
    art_state_id: "ASTFED7FAB6E7",
    state_code: "4",
    name: "Ségou Region",
    country_code: "ML",
    lat: 13.8394456,
    lon: -6.0679194,
  },
  {
    art_state_id: "AST674A429CA6",
    state_code: "2",
    name: "Koulikoro Region",
    country_code: "ML",
    lat: 13.8018074,
    lon: -7.4381355,
  },
  {
    art_state_id: "ASTD35E8366D9",
    state_code: "9",
    name: "Ménaka Region",
    country_code: "ML",
    lat: 15.9156421,
    lon: 2.396174,
  },
  {
    art_state_id: "AST06C61F4918",
    state_code: "1",
    name: "Kayes Region",
    country_code: "ML",
    lat: 14.0818308,
    lon: -9.9018131,
  },
  {
    art_state_id: "AST4C93E218CF",
    state_code: "BKO",
    name: "Bamako",
    country_code: "ML",
    lat: 12.6392316,
    lon: -8.0028892,
  },
  {
    art_state_id: "AST0D797612F0",
    state_code: "3",
    name: "Sikasso Region",
    country_code: "ML",
    lat: 10.8905186,
    lon: -7.4381355,
  },
  {
    art_state_id: "AST38926BE3CF",
    state_code: "5",
    name: "Mopti Region",
    country_code: "ML",
    lat: 14.6338039,
    lon: -3.4195527,
  },
  {
    art_state_id: "ASTF3A0D8A5C1",
    state_code: "10",
    name: "Taoudénit Region",
    country_code: "ML",
    lat: 22.6764132,
    lon: -3.9789143,
  },
  {
    art_state_id: "AST064F3D6AA3",
    state_code: "8",
    name: "Kidal Region",
    country_code: "ML",
    lat: 18.7986832,
    lon: 1.8318334,
  },
  {
    art_state_id: "ASTC2F77DE253",
    state_code: "7",
    name: "Gao Region",
    country_code: "ML",
    lat: 16.9066332,
    lon: 1.5208624,
  },
  {
    art_state_id: "AST4C729AA735",
    state_code: "05",
    name: "Southern Province",
    country_code: "RW",
    lat: -2.6272137,
    lon: 29.6063049,
  },
  {
    art_state_id: "AST101BCADD04",
    state_code: "04",
    name: "Western Province",
    country_code: "RW",
    lat: -2.3799057,
    lon: 29.2015345,
  },
  {
    art_state_id: "AST0DC3169900",
    state_code: "02",
    name: "Eastern Province",
    country_code: "RW",
    lat: -1.9646631,
    lon: 30.0644358,
  },
  {
    art_state_id: "AST82F3DC06E9",
    state_code: "01",
    name: "Kigali district",
    country_code: "RW",
    lat: -1.9440727,
    lon: 30.0618851,
  },
  {
    art_state_id: "AST3CECF58305",
    state_code: "03",
    name: "Northern Province",
    country_code: "RW",
    lat: -1.5809699,
    lon: 29.9269776,
  },
  {
    art_state_id: "AST3E123E16B6",
    state_code: "BZ",
    name: "Belize District",
    country_code: "BZ",
    lat: 17.5677679,
    lon: -88.4016041,
  },
  {
    art_state_id: "AST620527848C",
    state_code: "SC",
    name: "Stann Creek District",
    country_code: "BZ",
    lat: 16.8116631,
    lon: -88.4016041,
  },
  {
    art_state_id: "AST60C379B759",
    state_code: "CZL",
    name: "Corozal District",
    country_code: "BZ",
    lat: 18.1349238,
    lon: -88.2461183,
  },
  {
    art_state_id: "AST1C22F6D6C8",
    state_code: "TOL",
    name: "Toledo District",
    country_code: "BZ",
    lat: 16.2491923,
    lon: -88.864698,
  },
  {
    art_state_id: "AST794555A8DE",
    state_code: "OW",
    name: "Orange Walk District",
    country_code: "BZ",
    lat: 17.760353,
    lon: -88.864698,
  },
  {
    art_state_id: "ASTBC7145089F",
    state_code: "CY",
    name: "Cayo District",
    country_code: "BZ",
    lat: 17.0984445,
    lon: -88.9413865,
  },
  {
    art_state_id: "AST3C127E4095",
    state_code: "P",
    name: "Príncipe Province",
    country_code: "ST",
    lat: 1.6139381,
    lon: 7.4056928,
  },
  {
    art_state_id: "AST5C5856D471",
    state_code: "S",
    name: "São Tomé Province",
    country_code: "ST",
    lat: 0.3301924,
    lon: 6.733343,
  },
  {
    art_state_id: "AST4DFF7B7880",
    state_code: "03",
    name: "Havana Province",
    country_code: "CU",
    lat: 23.0540698,
    lon: -82.345189,
  },
  {
    art_state_id: "AST03ADCFBBF1",
    state_code: "13",
    name: "Santiago de Cuba Province",
    country_code: "CU",
    lat: 20.2397682,
    lon: -75.9927652,
  },
  {
    art_state_id: "AST7C18FB0CB8",
    state_code: "07",
    name: "Sancti Spíritus Province",
    country_code: "CU",
    lat: 21.9938214,
    lon: -79.4703885,
  },
  {
    art_state_id: "ASTC66E1FF2A7",
    state_code: "12",
    name: "Granma Province",
    country_code: "CU",
    lat: 20.3844902,
    lon: -76.6412712,
  },
  {
    art_state_id: "AST3188D76781",
    state_code: "16",
    name: "Mayabeque Province",
    country_code: "CU",
    lat: 22.8926529,
    lon: -81.9534815,
  },
  {
    art_state_id: "AST2670118C70",
    state_code: "01",
    name: "Pinar del Río Province",
    country_code: "CU",
    lat: 22.4076256,
    lon: -83.8473015,
  },
  {
    art_state_id: "AST2AEA517E88",
    state_code: "99",
    name: "Isla de la Juventud",
    country_code: "CU",
    lat: 21.7084737,
    lon: -82.8220232,
  },
  {
    art_state_id: "AST7D646C3A1C",
    state_code: "11",
    name: "Holguín Province",
    country_code: "CU",
    lat: 20.7837893,
    lon: -75.8069082,
  },
  {
    art_state_id: "AST31D78A0DFB",
    state_code: "05",
    name: "Villa Clara Province",
    country_code: "CU",
    lat: 22.4937204,
    lon: -79.9192702,
  },
  {
    art_state_id: "AST9FAF7D8951",
    state_code: "10",
    name: "Las Tunas Province",
    country_code: "CU",
    lat: 21.0605162,
    lon: -76.9182097,
  },
  {
    art_state_id: "ASTB6189B94FF",
    state_code: "08",
    name: "Ciego de Ávila Province",
    country_code: "CU",
    lat: 21.9329515,
    lon: -78.5660852,
  },
  {
    art_state_id: "ASTE2EEE82F72",
    state_code: "15",
    name: "Artemisa Province",
    country_code: "CU",
    lat: 22.7522903,
    lon: -82.9931607,
  },
  {
    art_state_id: "ASTA4BF97CBC1",
    state_code: "04",
    name: "Matanzas Province",
    country_code: "CU",
    lat: 22.5767123,
    lon: -81.3399414,
  },
  {
    art_state_id: "AST5A01825289",
    state_code: "14",
    name: "Guantánamo Province",
    country_code: "CU",
    lat: 20.1455917,
    lon: -74.8741045,
  },
  {
    art_state_id: "ASTC494E18E22",
    state_code: "09",
    name: "Camagüey Province",
    country_code: "CU",
    lat: 21.2167247,
    lon: -77.7452081,
  },
  {
    art_state_id: "AST711FC88FF7",
    state_code: "06",
    name: "Cienfuegos Province",
    country_code: "CU",
    lat: 22.2379783,
    lon: -80.365865,
  },
  {
    art_state_id: "ASTA27FCABC05",
    state_code: "JI",
    name: "Jigawa State",
    country_code: "NG",
    lat: 12.228012,
    lon: 9.5615867,
  },
  {
    art_state_id: "AST1F6CFC7CC8",
    state_code: "EN",
    name: "Enugu State",
    country_code: "NG",
    lat: 6.536353,
    lon: 7.4356194,
  },
  {
    art_state_id: "AST0CFB723737",
    state_code: "KE",
    name: "Kebbi State",
    country_code: "NG",
    lat: 11.4942003,
    lon: 4.2333355,
  },
  {
    art_state_id: "ASTA34BDCBC90",
    state_code: "BE",
    name: "Benue State",
    country_code: "NG",
    lat: 7.3369024,
    lon: 8.7403687,
  },
  {
    art_state_id: "ASTE32DD69EA7",
    state_code: "SO",
    name: "Sokoto State",
    country_code: "NG",
    lat: 13.0533143,
    lon: 5.3222722,
  },
  {
    art_state_id: "ASTA9E1AF6551",
    state_code: "FC",
    name: "Federal Capital Territory",
    country_code: "NG",
    lat: 8.8940691,
    lon: 7.1860402,
  },
  {
    art_state_id: "AST37718787D5",
    state_code: "KD",
    name: "Kaduna State",
    country_code: "NG",
    lat: 10.3764006,
    lon: 7.7094537,
  },
  {
    art_state_id: "AST1BFD43BECF",
    state_code: "KW",
    name: "Kwara State",
    country_code: "NG",
    lat: 8.9668961,
    lon: 4.3874051,
  },
  {
    art_state_id: "ASTB7E54ED486",
    state_code: "OY",
    name: "Oyo State",
    country_code: "NG",
    lat: 8.1573809,
    lon: 3.6146534,
  },
  {
    art_state_id: "ASTF3622F5DA8",
    state_code: "YO",
    name: "Yobe State",
    country_code: "NG",
    lat: 12.293876,
    lon: 11.4390411,
  },
  {
    art_state_id: "AST633E4823B6",
    state_code: "KO",
    name: "Kogi State",
    country_code: "NG",
    lat: 7.7337325,
    lon: 6.6905836,
  },
  {
    art_state_id: "AST6250DB57FC",
    state_code: "ZA",
    name: "Zamfara State",
    country_code: "NG",
    lat: 12.1221805,
    lon: 6.2235819,
  },
  {
    art_state_id: "ASTD926A1E7E5",
    state_code: "KN",
    name: "Kano State",
    country_code: "NG",
    lat: 11.7470698,
    lon: 8.5247107,
  },
  {
    art_state_id: "ASTC597C37241",
    state_code: "NA",
    name: "Nasarawa State",
    country_code: "NG",
    lat: 8.4997908,
    lon: 8.1996937,
  },
  {
    art_state_id: "ASTBC41F722DD",
    state_code: "PL",
    name: "Plateau State",
    country_code: "NG",
    lat: 9.2182093,
    lon: 9.5179488,
  },
  {
    art_state_id: "ASTC620608224",
    state_code: "AB",
    name: "Abia State",
    country_code: "NG",
    lat: 5.4527354,
    lon: 7.5248414,
  },
  {
    art_state_id: "ASTD79A391D5C",
    state_code: "AK",
    name: "Akwa Ibom State",
    country_code: "NG",
    lat: 4.9057371,
    lon: 7.8536675,
  },
  {
    art_state_id: "AST1BEAE63F28",
    state_code: "BY",
    name: "Bayelsa State",
    country_code: "NG",
    lat: 4.7719071,
    lon: 6.0698526,
  },
  {
    art_state_id: "AST135BB65D08",
    state_code: "LA",
    name: "Lagos",
    country_code: "NG",
    lat: 6.5243793,
    lon: 3.3792057,
  },
  {
    art_state_id: "ASTADB68419B0",
    state_code: "BO",
    name: "Borno State",
    country_code: "NG",
    lat: 11.8846356,
    lon: 13.1519665,
  },
  {
    art_state_id: "AST4C0D787C76",
    state_code: "IM",
    name: "Imo State",
    country_code: "NG",
    lat: 5.5720122,
    lon: 7.0588219,
  },
  {
    art_state_id: "AST638F204A3F",
    state_code: "EK",
    name: "Ekiti State",
    country_code: "NG",
    lat: 7.7189862,
    lon: 5.3109505,
  },
  {
    art_state_id: "AST64054DBC23",
    state_code: "GO",
    name: "Gombe State",
    country_code: "NG",
    lat: 10.3637795,
    lon: 11.1927587,
  },
  {
    art_state_id: "ASTBE57C3E755",
    state_code: "EB",
    name: "Ebonyi State",
    country_code: "NG",
    lat: 6.2649232,
    lon: 8.0137302,
  },
  {
    art_state_id: "AST236AC9EFA0",
    state_code: "BA",
    name: "Bauchi State",
    country_code: "NG",
    lat: 10.7760624,
    lon: 9.9991943,
  },
  {
    art_state_id: "AST9236D719DA",
    state_code: "KT",
    name: "Katsina State",
    country_code: "NG",
    lat: 12.3796707,
    lon: 7.6305748,
  },
  {
    art_state_id: "AST7C6A287399",
    state_code: "CR",
    name: "Cross River State",
    country_code: "NG",
    lat: 5.8701724,
    lon: 8.5988014,
  },
  {
    art_state_id: "AST818542A576",
    state_code: "AN",
    name: "Anambra State",
    country_code: "NG",
    lat: 6.2208997,
    lon: 6.9369559,
  },
  {
    art_state_id: "ASTA043374103",
    state_code: "DE",
    name: "Delta State",
    country_code: "NG",
    lat: 33.7453784,
    lon: -90.7354508,
  },
  {
    art_state_id: "ASTC259BD454E",
    state_code: "NI",
    name: "Niger State",
    country_code: "NG",
    lat: 9.9309224,
    lon: 5.598321,
  },
  {
    art_state_id: "AST599E4BA961",
    state_code: "ED",
    name: "Edo State",
    country_code: "NG",
    lat: 6.6341831,
    lon: 5.9304056,
  },
  {
    art_state_id: "AST5782DA2F8B",
    state_code: "TA",
    name: "Taraba State",
    country_code: "NG",
    lat: 7.9993616,
    lon: 10.7739863,
  },
  {
    art_state_id: "ASTEAF64FB3A2",
    state_code: "AD",
    name: "Adamawa State",
    country_code: "NG",
    lat: 9.3264751,
    lon: 12.3983853,
  },
  {
    art_state_id: "AST3FD2B781BB",
    state_code: "ON",
    name: "Ondo State",
    country_code: "NG",
    lat: 6.9148682,
    lon: 5.1478144,
  },
  {
    art_state_id: "ASTFF52F4F55A",
    state_code: "OS",
    name: "Osun State",
    country_code: "NG",
    lat: 7.5628964,
    lon: 4.5199593,
  },
  {
    art_state_id: "AST68ED736EF2",
    state_code: "OG",
    name: "Ogun State",
    country_code: "NG",
    lat: 6.9979747,
    lon: 3.4737378,
  },
  {
    art_state_id: "AST5A23D4B0EB",
    state_code: "412",
    name: "Rukungiri District",
    country_code: "UG",
    lat: -0.751849,
    lon: 29.9277947,
  },
  {
    art_state_id: "ASTDE112A61B3",
    state_code: "123",
    name: "Kyankwanzi District",
    country_code: "UG",
    lat: 1.0966037,
    lon: 31.7195459,
  },
  {
    art_state_id: "ASTBBFA5597D4",
    state_code: "405",
    name: "Kabarole District",
    country_code: "UG",
    lat: 0.5850791,
    lon: 30.2512728,
  },
  {
    art_state_id: "AST2D869CE8A1",
    state_code: "106",
    name: "Mpigi District",
    country_code: "UG",
    lat: 0.2273528,
    lon: 32.3249236,
  },
  {
    art_state_id: "AST47BBC03CE5",
    state_code: "302",
    name: "Apac District",
    country_code: "UG",
    lat: 1.8730263,
    lon: 32.6277455,
  },
  {
    art_state_id: "AST23C8747A6C",
    state_code: "314",
    name: "Abim District",
    country_code: "UG",
    lat: 2.706698,
    lon: 33.6595337,
  },
  {
    art_state_id: "AST0DE218B6B3",
    state_code: "313",
    name: "Yumbe District",
    country_code: "UG",
    lat: 3.4698023,
    lon: 31.2483291,
  },
  {
    art_state_id: "AST71925F54EA",
    state_code: "431",
    name: "Rukiga District",
    country_code: "UG",
    lat: -1.1326337,
    lon: 30.043412,
  },
  {
    art_state_id: "AST650B37B4DC",
    state_code: "N",
    name: "Northern Region",
    country_code: "UG",
    lat: 9.5439269,
    lon: -0.9056623,
  },
  {
    art_state_id: "ASTC33028C270",
    state_code: "232",
    name: "Serere District",
    country_code: "UG",
    lat: 1.4994033,
    lon: 33.5490078,
  },
  {
    art_state_id: "ASTD8F1AF4A2D",
    state_code: "205",
    name: "Kamuli District",
    country_code: "UG",
    lat: 0.9187107,
    lon: 33.1239049,
  },
  {
    art_state_id: "ASTB4646BA634",
    state_code: "316",
    name: "Amuru District",
    country_code: "UG",
    lat: 2.9667878,
    lon: 32.0837445,
  },
  {
    art_state_id: "AST295CCAE6ED",
    state_code: "213",
    name: "Kaberamaido District",
    country_code: "UG",
    lat: 1.6963322,
    lon: 33.213851,
  },
  {
    art_state_id: "AST84380B328D",
    state_code: "224",
    name: "Namutumba District",
    country_code: "UG",
    lat: 0.849261,
    lon: 33.6623301,
  },
  {
    art_state_id: "ASTB2652F43C7",
    state_code: "227",
    name: "Kibuku District",
    country_code: "UG",
    lat: 1.0452874,
    lon: 33.7992536,
  },
  {
    art_state_id: "AST764352226E",
    state_code: "417",
    name: "Ibanda District",
    country_code: "UG",
    lat: -0.096489,
    lon: 30.5739579,
  },
  {
    art_state_id: "AST662F2DEE1B",
    state_code: "203",
    name: "Iganga District",
    country_code: "UG",
    lat: 0.6600137,
    lon: 33.4831906,
  },
  {
    art_state_id: "AST3A838CB811",
    state_code: "317",
    name: "Dokolo District",
    country_code: "UG",
    lat: 1.9636421,
    lon: 33.0338767,
  },
  {
    art_state_id: "AST4236228689",
    state_code: "307",
    name: "Lira District",
    country_code: "UG",
    lat: 2.2316169,
    lon: 32.9437667,
  },
  {
    art_state_id: "AST732796BD71",
    state_code: "219",
    name: "Bukedea District",
    country_code: "UG",
    lat: 1.3556898,
    lon: 34.1086793,
  },
  {
    art_state_id: "ASTC3EAE8BD3A",
    state_code: "323",
    name: "Alebtong District",
    country_code: "UG",
    lat: 2.2545773,
    lon: 33.3486147,
  },
  {
    art_state_id: "AST1227B59CC5",
    state_code: "319",
    name: "Koboko District",
    country_code: "UG",
    lat: 3.5237058,
    lon: 31.03351,
  },
  {
    art_state_id: "AST5415DED4AA",
    state_code: "421",
    name: "Kiryandongo District",
    country_code: "UG",
    lat: 2.0179907,
    lon: 32.0837445,
  },
  {
    art_state_id: "ASTC479CE1FFE",
    state_code: "103",
    name: "Kiboga District",
    country_code: "UG",
    lat: 0.965759,
    lon: 31.7195459,
  },
  {
    art_state_id: "ASTEAE10EB204",
    state_code: "305",
    name: "Kitgum District",
    country_code: "UG",
    lat: 3.3396829,
    lon: 33.1688883,
  },
  {
    art_state_id: "AST32A8A63E94",
    state_code: "218",
    name: "Bududa District",
    country_code: "UG",
    lat: 1.0029693,
    lon: 34.3338123,
  },
  {
    art_state_id: "AST17E7D004F1",
    state_code: "209",
    name: "Mbale District",
    country_code: "UG",
    lat: 1.0344274,
    lon: 34.1976882,
  },
  {
    art_state_id: "ASTE4B385E8DF",
    state_code: "230",
    name: "Namayingo District",
    country_code: "UG",
    lat: -0.2803575,
    lon: 33.7517723,
  },
  {
    art_state_id: "ASTA1D4C7ACB7",
    state_code: "216",
    name: "Amuria District",
    country_code: "UG",
    lat: 2.03017,
    lon: 33.6427533,
  },
  {
    art_state_id: "AST62C334E5EE",
    state_code: "324",
    name: "Amudat District",
    country_code: "UG",
    lat: 1.7916224,
    lon: 34.906551,
  },
  {
    art_state_id: "ASTFCAEE6ADA5",
    state_code: "409",
    name: "Masindi District",
    country_code: "UG",
    lat: 1.4920363,
    lon: 31.7195459,
  },
  {
    art_state_id: "ASTD298184731",
    state_code: "419",
    name: "Kiruhura District",
    country_code: "UG",
    lat: -0.1927998,
    lon: 30.8039474,
  },
  {
    art_state_id: "ASTD41639B526",
    state_code: "105",
    name: "Masaka District",
    country_code: "UG",
    lat: -0.4463691,
    lon: 31.9017954,
  },
  {
    art_state_id: "AST139E30BA2F",
    state_code: "332",
    name: "Pakwach District",
    country_code: "UG",
    lat: 2.4607141,
    lon: 31.4941738,
  },
  {
    art_state_id: "AST76EB8C3EF7",
    state_code: "429",
    name: "Rubanda District",
    country_code: "UG",
    lat: -1.186119,
    lon: 29.8453576,
  },
  {
    art_state_id: "ASTA49AEE37C1",
    state_code: "212",
    name: "Tororo District",
    country_code: "UG",
    lat: 0.6870994,
    lon: 34.0641419,
  },
  {
    art_state_id: "AST7AC05E65F0",
    state_code: "413",
    name: "Kamwenge District",
    country_code: "UG",
    lat: 0.225793,
    lon: 30.4818446,
  },
  {
    art_state_id: "ASTE54A0D2C7B",
    state_code: "301",
    name: "Adjumani District",
    country_code: "UG",
    lat: 3.2548527,
    lon: 31.7195459,
  },
  {
    art_state_id: "ASTF15CABC199",
    state_code: "113",
    name: "Wakiso District",
    country_code: "UG",
    lat: 0.063019,
    lon: 32.4467238,
  },
  {
    art_state_id: "AST3D32BBCE55",
    state_code: "309",
    name: "Moyo District",
    country_code: "UG",
    lat: 3.5696464,
    lon: 31.6739371,
  },
  {
    art_state_id: "AST2740F45E5E",
    state_code: "115",
    name: "Mityana District",
    country_code: "UG",
    lat: 0.4454845,
    lon: 32.0837445,
  },
  {
    art_state_id: "AST4920FB2D8A",
    state_code: "221",
    name: "Butaleja District",
    country_code: "UG",
    lat: 0.8474922,
    lon: 33.8411288,
  },
  {
    art_state_id: "ASTA2469DF207",
    state_code: "121",
    name: "Gomba District",
    country_code: "UG",
    lat: 0.2229791,
    lon: 31.6739371,
  },
  {
    art_state_id: "AST8A3F260CB4",
    state_code: "204",
    name: "Jinja District",
    country_code: "UG",
    lat: 0.5343743,
    lon: 33.3037143,
  },
  {
    art_state_id: "AST3F2BF11840",
    state_code: "112",
    name: "Kayunga District",
    country_code: "UG",
    lat: 0.9860182,
    lon: 32.8535755,
  },
  {
    art_state_id: "AST5B145392B9",
    state_code: "228",
    name: "Kween District",
    country_code: "UG",
    lat: 1.443879,
    lon: 34.597132,
  },
  {
    art_state_id: "ASTA6FC54C15B",
    state_code: "W",
    name: "Western Region",
    country_code: "UG",
    lat: 40.7667215,
    lon: -111.8877203,
  },
  {
    art_state_id: "ASTDD01BE7445",
    state_code: "107",
    name: "Mubende District",
    country_code: "UG",
    lat: 0.5772758,
    lon: 31.5370003,
  },
  {
    art_state_id: "AST253DB22A18",
    state_code: "E",
    name: "Eastern Region",
    country_code: "UG",
    lat: 6.2374036,
    lon: -0.4502368,
  },
  {
    art_state_id: "ASTBC05B4E7EC",
    state_code: "414",
    name: "Kanungu District",
    country_code: "UG",
    lat: -0.8195253,
    lon: 29.742604,
  },
  {
    art_state_id: "AST8EBB8A4612",
    state_code: "331",
    name: "Omoro District",
    country_code: "UG",
    lat: 2.715223,
    lon: 32.4920088,
  },
  {
    art_state_id: "ASTD3DA6C9C1D",
    state_code: "118",
    name: "Bukomansimbi District",
    country_code: "UG",
    lat: -0.1432752,
    lon: 31.6054893,
  },
  {
    art_state_id: "AST3A3B7C9252",
    state_code: "114",
    name: "Lyantonde District",
    country_code: "UG",
    lat: -0.2240696,
    lon: 31.2168466,
  },
  {
    art_state_id: "ASTFD73E7E703",
    state_code: "117",
    name: "Buikwe District",
    country_code: "UG",
    lat: 0.3144046,
    lon: 32.9888319,
  },
  {
    art_state_id: "ASTA6D536DE33",
    state_code: "328",
    name: "Nwoya District",
    country_code: "UG",
    lat: 2.562444,
    lon: 31.9017954,
  },
  {
    art_state_id: "ASTBA5020AD01",
    state_code: "330",
    name: "Zombo District",
    country_code: "UG",
    lat: 2.5544293,
    lon: 30.9417368,
  },
  {
    art_state_id: "AST496D5C065A",
    state_code: "226",
    name: "Buyende District",
    country_code: "UG",
    lat: 1.2413682,
    lon: 33.1239049,
  },
  {
    art_state_id: "ASTEF90DE77F4",
    state_code: "430",
    name: "Bunyangabu District",
    country_code: "UG",
    lat: 0.4870918,
    lon: 30.2051096,
  },
  {
    art_state_id: "ASTB9BF5F2D34",
    state_code: "102",
    name: "Kampala District",
    country_code: "UG",
    lat: 0.3475964,
    lon: 32.5825197,
  },
  {
    art_state_id: "AST256B205CB9",
    state_code: "418",
    name: "Isingiro District",
    country_code: "UG",
    lat: -0.843543,
    lon: 30.8039474,
  },
  {
    art_state_id: "AST82FA1AFACD",
    state_code: "119",
    name: "Butambala District",
    country_code: "UG",
    lat: 0.17425,
    lon: 32.1064668,
  },
  {
    art_state_id: "AST7CF6021B3D",
    state_code: "220",
    name: "Bukwo District",
    country_code: "UG",
    lat: 1.2818651,
    lon: 34.7298765,
  },
  {
    art_state_id: "AST5FBDB469C4",
    state_code: "402",
    name: "Bushenyi District",
    country_code: "UG",
    lat: -0.4870918,
    lon: 30.2051096,
  },
  {
    art_state_id: "AST6783CED574",
    state_code: "201",
    name: "Bugiri District",
    country_code: "UG",
    lat: 0.5316127,
    lon: 33.7517723,
  },
  {
    art_state_id: "AST620E2AA611",
    state_code: "233",
    name: "Butebo District",
    country_code: "UG",
    lat: 1.2141124,
    lon: 33.9080896,
  },
  {
    art_state_id: "ASTB8C8CDF6EB",
    state_code: "416",
    name: "Buliisa District",
    country_code: "UG",
    lat: 2.0299607,
    lon: 31.5370003,
  },
  {
    art_state_id: "AST2C96586012",
    state_code: "329",
    name: "Otuke District",
    country_code: "UG",
    lat: 2.5214059,
    lon: 33.3486147,
  },
  {
    art_state_id: "ASTFCC03B5CE2",
    state_code: "420",
    name: "Buhweju District",
    country_code: "UG",
    lat: -0.2911359,
    lon: 30.2974199,
  },
  {
    art_state_id: "AST07F819EBDD",
    state_code: "322",
    name: "Agago District",
    country_code: "UG",
    lat: 2.925082,
    lon: 33.3486147,
  },
  {
    art_state_id: "ASTBD66D68192",
    state_code: "311",
    name: "Nakapiripirit District",
    country_code: "UG",
    lat: 1.9606173,
    lon: 34.597132,
  },
  {
    art_state_id: "ASTAC62886D67",
    state_code: "122",
    name: "Kalungu District",
    country_code: "UG",
    lat: -0.0952831,
    lon: 31.7651362,
  },
  {
    art_state_id: "AST0113431353",
    state_code: "308",
    name: "Moroto District",
    country_code: "UG",
    lat: 2.6168545,
    lon: 34.597132,
  },
  {
    art_state_id: "AST7E3CEB3E88",
    state_code: "C",
    name: "Central Region",
    country_code: "UG",
    lat: 44.296875,
    lon: -94.7401733,
  },
  {
    art_state_id: "ASTAD0302CE05",
    state_code: "321",
    name: "Oyam District",
    country_code: "UG",
    lat: 2.2776281,
    lon: 32.4467238,
  },
  {
    art_state_id: "AST11CE975B09",
    state_code: "222",
    name: "Kaliro District",
    country_code: "UG",
    lat: 1.0431107,
    lon: 33.4831906,
  },
  {
    art_state_id: "AST3C5E69A344",
    state_code: "428",
    name: "Kakumiro District",
    country_code: "UG",
    lat: 0.7808035,
    lon: 31.3241389,
  },
  {
    art_state_id: "AST009E7B7253",
    state_code: "234",
    name: "Namisindwa District",
    country_code: "UG",
    lat: 0.907101,
    lon: 34.3574037,
  },
  {
    art_state_id: "AST463E247395",
    state_code: "325",
    name: "Kole District",
    country_code: "UG",
    lat: 2.3701097,
    lon: 32.7633036,
  },
  {
    art_state_id: "ASTBAA495B251",
    state_code: "415",
    name: "Kyenjojo District",
    country_code: "UG",
    lat: 0.6092923,
    lon: 30.6401231,
  },
  {
    art_state_id: "AST1D79251A75",
    state_code: "427",
    name: "Kagadi District",
    country_code: "UG",
    lat: 0.9400761,
    lon: 30.8125638,
  },
  {
    art_state_id: "AST538D7B0F4C",
    state_code: "411",
    name: "Ntungamo District",
    country_code: "UG",
    lat: -0.9807341,
    lon: 30.2512728,
  },
  {
    art_state_id: "AST94824365C8",
    state_code: "101",
    name: "Kalangala District",
    country_code: "UG",
    lat: -0.6350578,
    lon: 32.5372741,
  },
  {
    art_state_id: "ASTF9AEECF925",
    state_code: "109",
    name: "Nakasongola District",
    country_code: "UG",
    lat: 1.3489721,
    lon: 32.4467238,
  },
  {
    art_state_id: "ASTF5B9B60A55",
    state_code: "426",
    name: "Sheema District",
    country_code: "UG",
    lat: -0.5515298,
    lon: 30.3896651,
  },
  {
    art_state_id: "AST5EFF0CD0A5",
    state_code: "312",
    name: "Pader District",
    country_code: "UG",
    lat: 2.9430682,
    lon: 32.8084496,
  },
  {
    art_state_id: "AST9B57EEE1C0",
    state_code: "408",
    name: "Kisoro District",
    country_code: "UG",
    lat: -1.220943,
    lon: 29.6499162,
  },
  {
    art_state_id: "AST419F7E96B4",
    state_code: "108",
    name: "Mukono District",
    country_code: "UG",
    lat: 0.2835476,
    lon: 32.7633036,
  },
  {
    art_state_id: "ASTED4F2A4BA0",
    state_code: "326",
    name: "Lamwo District",
    country_code: "UG",
    lat: 3.5707568,
    lon: 32.5372741,
  },
  {
    art_state_id: "AST7609654F4E",
    state_code: "210",
    name: "Pallisa District",
    country_code: "UG",
    lat: 1.2324206,
    lon: 33.7517723,
  },
  {
    art_state_id: "AST8992AC1E48",
    state_code: "304",
    name: "Gulu District",
    country_code: "UG",
    lat: 2.8185776,
    lon: 32.4467238,
  },
  {
    art_state_id: "AST364CA99D2C",
    state_code: "120",
    name: "Buvuma District",
    country_code: "UG",
    lat: -0.3764912,
    lon: 33.258793,
  },
  {
    art_state_id: "AST5591EE4959",
    state_code: "410",
    name: "Mbarara District",
    country_code: "UG",
    lat: -0.6071596,
    lon: 30.6545022,
  },
  {
    art_state_id: "AST48073DA67B",
    state_code: "315",
    name: "Amolatar District",
    country_code: "UG",
    lat: 1.6054402,
    lon: 32.8084496,
  },
  {
    art_state_id: "ASTB08228BCD1",
    state_code: "124",
    name: "Lwengo District",
    country_code: "UG",
    lat: -0.4165288,
    lon: 31.3998995,
  },
  {
    art_state_id: "AST35FE7D2905",
    state_code: "214",
    name: "Mayuge District",
    country_code: "UG",
    lat: -0.2182982,
    lon: 33.5728027,
  },
  {
    art_state_id: "AST2F9C1305C2",
    state_code: "401",
    name: "Bundibugyo District",
    country_code: "UG",
    lat: 0.6851763,
    lon: 30.0202964,
  },
  {
    art_state_id: "ASTAE5DD264F0",
    state_code: "207",
    name: "Katakwi District",
    country_code: "UG",
    lat: 1.973103,
    lon: 34.0641419,
  },
  {
    art_state_id: "AST13BE413C51",
    state_code: "320",
    name: "Maracha District",
    country_code: "UG",
    lat: 3.2873127,
    lon: 30.9403023,
  },
  {
    art_state_id: "AST9640427A9D",
    state_code: "424",
    name: "Ntoroko District",
    country_code: "UG",
    lat: 1.0788178,
    lon: 30.3896651,
  },
  {
    art_state_id: "AST8BB829415C",
    state_code: "116",
    name: "Nakaseke District",
    country_code: "UG",
    lat: 1.2230848,
    lon: 32.0837445,
  },
  {
    art_state_id: "AST227E3B47A9",
    state_code: "231",
    name: "Ngora District",
    country_code: "UG",
    lat: 1.4908115,
    lon: 33.7517723,
  },
  {
    art_state_id: "ASTECD350D59E",
    state_code: "208",
    name: "Kumi District",
    country_code: "UG",
    lat: 1.4876999,
    lon: 33.9303991,
  },
  {
    art_state_id: "AST4775B4CD6F",
    state_code: "404",
    name: "Kabale District",
    country_code: "UG",
    lat: -1.2493084,
    lon: 30.0665236,
  },
  {
    art_state_id: "ASTBAED2A4DB7",
    state_code: "111",
    name: "Sembabule District",
    country_code: "UG",
    lat: 0.0637715,
    lon: 31.3541631,
  },
  {
    art_state_id: "AST92BFBB652B",
    state_code: "225",
    name: "Bulambuli District",
    country_code: "UG",
    lat: 1.4798846,
    lon: 34.3754414,
  },
  {
    art_state_id: "ASTF46D7D2AB7",
    state_code: "215",
    name: "Sironko District",
    country_code: "UG",
    lat: 1.2302274,
    lon: 34.2491064,
  },
  {
    art_state_id: "AST30DCC62E96",
    state_code: "327",
    name: "Napak District",
    country_code: "UG",
    lat: 2.3629945,
    lon: 34.2421597,
  },
  {
    art_state_id: "ASTFC1AD012C3",
    state_code: "202",
    name: "Busia District",
    country_code: "UG",
    lat: 0.4044731,
    lon: 34.0195827,
  },
  {
    art_state_id: "ASTA66D428A65",
    state_code: "206",
    name: "Kapchorwa District",
    country_code: "UG",
    lat: 1.3350205,
    lon: 34.3976356,
  },
  {
    art_state_id: "ASTD6E9F29CFF",
    state_code: "104",
    name: "Luwero District",
    country_code: "UG",
    lat: 0.8271118,
    lon: 32.6277455,
  },
  {
    art_state_id: "AST732A693D7E",
    state_code: "318",
    name: "Kaabong District",
    country_code: "UG",
    lat: 3.5126215,
    lon: 33.9750018,
  },
  {
    art_state_id: "AST3C54466253",
    state_code: "423",
    name: "Mitooma District",
    country_code: "UG",
    lat: -0.6193276,
    lon: 30.0202964,
  },
  {
    art_state_id: "AST09F0E8B3D4",
    state_code: "407",
    name: "Kibaale District",
    country_code: "UG",
    lat: 0.9066802,
    lon: 31.0793705,
  },
  {
    art_state_id: "ASTEF57549B89",
    state_code: "422",
    name: "Kyegegwa District",
    country_code: "UG",
    lat: 0.4818193,
    lon: 31.0550093,
  },
  {
    art_state_id: "AST7E61AC22E6",
    state_code: "223",
    name: "Manafwa District",
    country_code: "UG",
    lat: 0.9063599,
    lon: 34.2866091,
  },
  {
    art_state_id: "ASTAF896BEDA1",
    state_code: "110",
    name: "Rakai District",
    country_code: "UG",
    lat: -0.7069135,
    lon: 31.5370003,
  },
  {
    art_state_id: "AST2A1EB99408",
    state_code: "406",
    name: "Kasese District",
    country_code: "UG",
    lat: 0.0646285,
    lon: 30.0665236,
  },
  {
    art_state_id: "AST8013B3D1FA",
    state_code: "217",
    name: "Budaka District",
    country_code: "UG",
    lat: 1.1016277,
    lon: 33.9303991,
  },
  {
    art_state_id: "AST6EF7AD7336",
    state_code: "425",
    name: "Rubirizi District",
    country_code: "UG",
    lat: -0.264241,
    lon: 30.1084033,
  },
  {
    art_state_id: "AST353D1076A9",
    state_code: "306",
    name: "Kotido District",
    country_code: "UG",
    lat: 3.0415679,
    lon: 33.8857747,
  },
  {
    art_state_id: "AST9B62CBC5F9",
    state_code: "211",
    name: "Soroti District",
    country_code: "UG",
    lat: 1.7229117,
    lon: 33.5280072,
  },
  {
    art_state_id: "ASTCB9238E3FD",
    state_code: "229",
    name: "Luuka District",
    country_code: "UG",
    lat: 0.7250599,
    lon: 33.3037143,
  },
  {
    art_state_id: "AST059A50292E",
    state_code: "310",
    name: "Nebbi District",
    country_code: "UG",
    lat: 2.4409392,
    lon: 31.3541631,
  },
  {
    art_state_id: "AST729C792DBB",
    state_code: "303",
    name: "Arua District",
    country_code: "UG",
    lat: 2.9959846,
    lon: 31.1710389,
  },
  {
    art_state_id: "ASTAD7685A301",
    state_code: "125",
    name: "Kyotera District",
    country_code: "UG",
    lat: -0.6358988,
    lon: 31.5455637,
  },
  {
    art_state_id: "AST6ABC92B6AA",
    state_code: "08",
    name: "Schellenberg",
    country_code: "LI",
    lat: 47.230966,
    lon: 9.5467843,
  },
  {
    art_state_id: "AST4F77F9F0FB",
    state_code: "07",
    name: "Schaan",
    country_code: "LI",
    lat: 47.120434,
    lon: 9.5941602,
  },
  {
    art_state_id: "ASTEDB2983E23",
    state_code: "02",
    name: "Eschen",
    country_code: "LI",
    lat: 40.7669574,
    lon: -73.9522821,
  },
  {
    art_state_id: "AST21A0B440C4",
    state_code: "11",
    name: "Vaduz",
    country_code: "LI",
    lat: 47.1410303,
    lon: 9.5209277,
  },
  {
    art_state_id: "ASTDA5BFCE91B",
    state_code: "06",
    name: "Ruggell",
    country_code: "LI",
    lat: 47.2529222,
    lon: 9.5402127,
  },
  {
    art_state_id: "AST947BAE1BE1",
    state_code: "05",
    name: "Planken",
    country_code: "LI",
    lat: 40.6650576,
    lon: -73.504798,
  },
  {
    art_state_id: "AST3BAC2650C2",
    state_code: "04",
    name: "Mauren",
    country_code: "LI",
    lat: 47.2189285,
    lon: 9.541735,
  },
  {
    art_state_id: "ASTCECA95D57B",
    state_code: "10",
    name: "Triesenberg",
    country_code: "LI",
    lat: 47.1224511,
    lon: 9.5701985,
  },
  {
    art_state_id: "AST597CC89D55",
    state_code: "03",
    name: "Gamprin",
    country_code: "LI",
    lat: 47.213249,
    lon: 9.5025195,
  },
  {
    art_state_id: "ASTFBF254F32E",
    state_code: "01",
    name: "Balzers",
    country_code: "LI",
    lat: 42.0528357,
    lon: -88.0366848,
  },
  {
    art_state_id: "AST3EC3822FC6",
    state_code: "09",
    name: "Triesen",
    country_code: "LI",
    lat: 47.1097988,
    lon: 9.5248296,
  },
  {
    art_state_id: "AST6BD51068B0",
    state_code: "BRC",
    name: "Brčko District",
    country_code: "BA",
    lat: 44.8405944,
    lon: 18.742153,
  },
  {
    art_state_id: "AST2F4775F305",
    state_code: "03",
    name: "Tuzla Canton",
    country_code: "BA",
    lat: 44.5343463,
    lon: 18.6972797,
  },
  {
    art_state_id: "ASTB336CEB439",
    state_code: "06",
    name: "Central Bosnia Canton",
    country_code: "BA",
    lat: 44.1381856,
    lon: 17.6866714,
  },
  {
    art_state_id: "ASTB40EE253D8",
    state_code: "07",
    name: "Herzegovina-Neretva Canton",
    country_code: "BA",
    lat: 43.5265159,
    lon: 17.763621,
  },
  {
    art_state_id: "AST2E65227CB3",
    state_code: "02",
    name: "Posavina Canton",
    country_code: "BA",
    lat: 45.0752094,
    lon: 18.3776304,
  },
  {
    art_state_id: "AST01621070C3",
    state_code: "01",
    name: "Una-Sana Canton",
    country_code: "BA",
    lat: 44.6503116,
    lon: 16.3171629,
  },
  {
    art_state_id: "AST86F201F84D",
    state_code: "09",
    name: "Sarajevo Canton",
    country_code: "BA",
    lat: 43.8512564,
    lon: 18.2953442,
  },
  {
    art_state_id: "AST5E256615B1",
    state_code: "BIH",
    name: "Federation of Bosnia and Herzegovina",
    country_code: "BA",
    lat: 43.8874897,
    lon: 17.842793,
  },
  {
    art_state_id: "AST67D4574C2B",
    state_code: "04",
    name: "Zenica-Doboj Canton",
    country_code: "BA",
    lat: 44.2127109,
    lon: 18.1604625,
  },
  {
    art_state_id: "ASTA3A84F9A8A",
    state_code: "08",
    name: "West Herzegovina Canton",
    country_code: "BA",
    lat: 43.4369244,
    lon: 17.3848831,
  },
  {
    art_state_id: "AST2E56B64C8C",
    state_code: "SRP",
    name: "Republika Srpska",
    country_code: "BA",
    lat: 44.7280186,
    lon: 17.3148136,
  },
  {
    art_state_id: "AST4A6C259C8F",
    state_code: "10",
    name: "Canton 10",
    country_code: "BA",
    lat: 43.9534155,
    lon: 16.9425187,
  },
  {
    art_state_id: "AST4F520540FA",
    state_code: "05",
    name: "Bosnian Podrinje Canton",
    country_code: "BA",
    lat: 43.68749,
    lon: 18.8244394,
  },
  {
    art_state_id: "ASTCD783FF7CF",
    state_code: "DK",
    name: "Dakar",
    country_code: "SN",
    lat: 14.716677,
    lon: -17.4676861,
  },
  {
    art_state_id: "AST37169E0D1E",
    state_code: "KD",
    name: "Kolda",
    country_code: "SN",
    lat: 12.9107495,
    lon: -14.9505671,
  },
  {
    art_state_id: "AST7FB6338612",
    state_code: "KA",
    name: "Kaffrine",
    country_code: "SN",
    lat: 14.105202,
    lon: -15.5415755,
  },
  {
    art_state_id: "ASTC16A6FDFD6",
    state_code: "MT",
    name: "Matam",
    country_code: "SN",
    lat: 15.6600225,
    lon: -13.2576906,
  },
  {
    art_state_id: "AST791BEC5C91",
    state_code: "SL",
    name: "Saint-Louis",
    country_code: "SN",
    lat: 38.6270025,
    lon: -90.1994042,
  },
  {
    art_state_id: "AST3C4FE31260",
    state_code: "ZG",
    name: "Ziguinchor",
    country_code: "SN",
    lat: 12.5641479,
    lon: -16.2639825,
  },
  {
    art_state_id: "ASTAB67C32010",
    state_code: "FK",
    name: "Fatick",
    country_code: "SN",
    lat: 14.3390167,
    lon: -16.4111425,
  },
  {
    art_state_id: "AST4C702882F1",
    state_code: "DB",
    name: "Diourbel Region",
    country_code: "SN",
    lat: 14.7283085,
    lon: -16.2522143,
  },
  {
    art_state_id: "AST2514CAFDA5",
    state_code: "KE",
    name: "Kédougou",
    country_code: "SN",
    lat: 12.5604607,
    lon: -12.1747077,
  },
  {
    art_state_id: "AST65A8277C4B",
    state_code: "SE",
    name: "Sédhiou",
    country_code: "SN",
    lat: 12.704604,
    lon: -15.5562304,
  },
  {
    art_state_id: "AST20E5BEB43B",
    state_code: "KL",
    name: "Kaolack",
    country_code: "SN",
    lat: 14.1652083,
    lon: -16.0757749,
  },
  {
    art_state_id: "AST01F13B88AA",
    state_code: "TH",
    name: "Thiès Region",
    country_code: "SN",
    lat: 14.7910052,
    lon: -16.9358604,
  },
  {
    art_state_id: "AST04D20C37CE",
    state_code: "LG",
    name: "Louga",
    country_code: "SN",
    lat: 15.6141768,
    lon: -16.22868,
  },
  {
    art_state_id: "AST1EF7D6E086",
    state_code: "TC",
    name: "Tambacounda Region",
    country_code: "SN",
    lat: 13.5619011,
    lon: -13.1740348,
  },
  {
    art_state_id: "AST2CA93C3ED3",
    state_code: "03",
    name: "Encamp",
    country_code: "AD",
    lat: 42.5359764,
    lon: 1.5836773,
  },
  {
    art_state_id: "AST71BE61E3DD",
    state_code: "07",
    name: "Andorra la Vella",
    country_code: "AD",
    lat: 42.5063174,
    lon: 1.5218355,
  },
  {
    art_state_id: "AST085904BB64",
    state_code: "02",
    name: "Canillo",
    country_code: "AD",
    lat: 42.5978249,
    lon: 1.6566377,
  },
  {
    art_state_id: "AST652EA34947",
    state_code: "06",
    name: "Sant Julià de Lòria",
    country_code: "AD",
    lat: 42.4529631,
    lon: 1.4918235,
  },
  {
    art_state_id: "AST8DF1692CBF",
    state_code: "05",
    name: "Ordino",
    country_code: "AD",
    lat: 42.5994433,
    lon: 1.5402327,
  },
  {
    art_state_id: "ASTEBAD7D516A",
    state_code: "08",
    name: "Escaldes-Engordany",
    country_code: "AD",
    lat: 42.4909379,
    lon: 1.5886966,
  },
  {
    art_state_id: "AST266518CC2D",
    state_code: "04",
    name: "La Massana",
    country_code: "AD",
    lat: 42.545625,
    lon: 1.5147392,
  },
  {
    art_state_id: "AST26B26F16F2",
    state_code: "17",
    name: "Mont Buxton",
    country_code: "SC",
    lat: -4.6166667,
    lon: 55.4457768,
  },
  {
    art_state_id: "ASTD4A2984815",
    state_code: "15",
    name: "La Digue",
    country_code: "SC",
    lat: 49.7666922,
    lon: -97.1546629,
  },
  {
    art_state_id: "AST9C793E97CA",
    state_code: "22",
    name: "Saint Louis",
    country_code: "SC",
    lat: 38.6270025,
    lon: -90.1994042,
  },
  {
    art_state_id: "AST720DA174F4",
    state_code: "06",
    name: "Baie Lazare",
    country_code: "SC",
    lat: -4.7482525,
    lon: 55.4859363,
  },
  {
    art_state_id: "ASTD0F6864066",
    state_code: "18",
    name: "Mont Fleuri",
    country_code: "SC",
    lat: -4.6356543,
    lon: 55.4554688,
  },
  {
    art_state_id: "ASTDC7F421648",
    state_code: "24",
    name: "Les Mamelles",
    country_code: "SC",
    lat: 38.8250505,
    lon: -90.4834517,
  },
  {
    art_state_id: "AST38447F4EBF",
    state_code: "13",
    name: "Grand'Anse Mahé",
    country_code: "SC",
    lat: -4.677392,
    lon: 55.463777,
  },
  {
    art_state_id: "AST411A8652E6",
    state_code: "25",
    name: "Roche Caiman",
    country_code: "SC",
    lat: -4.6396028,
    lon: 55.4679315,
  },
  {
    art_state_id: "AST2BE0495D1E",
    state_code: "05",
    name: "Anse Royale",
    country_code: "SC",
    lat: -4.7407988,
    lon: 55.5081012,
  },
  {
    art_state_id: "ASTF168066AF1",
    state_code: "12",
    name: "Glacis",
    country_code: "SC",
    lat: 47.1157303,
    lon: -70.3028183,
  },
  {
    art_state_id: "AST340E2AF2FF",
    state_code: "14",
    name: "Grand'Anse Praslin",
    country_code: "SC",
    lat: -4.3176219,
    lon: 55.7078363,
  },
  {
    art_state_id: "AST63E3A3683B",
    state_code: "10",
    name: "Bel Ombre",
    country_code: "SC",
    lat: -20.5010095,
    lon: 57.4259624,
  },
  {
    art_state_id: "AST57052BB4B4",
    state_code: "01",
    name: "Anse-aux-Pins",
    country_code: "SC",
    lat: -4.6900443,
    lon: 55.5150289,
  },
  {
    art_state_id: "AST4B0A0123C1",
    state_code: "21",
    name: "Port Glaud",
    country_code: "SC",
    lat: -4.6488523,
    lon: 55.4194753,
  },
  {
    art_state_id: "AST23663EB127",
    state_code: "04",
    name: "Au Cap",
    country_code: "SC",
    lat: -4.7059723,
    lon: 55.5081012,
  },
  {
    art_state_id: "AST9D2EB57DD7",
    state_code: "23",
    name: "Takamaka",
    country_code: "SC",
    lat: 37.9645917,
    lon: -1.2217727,
  },
  {
    art_state_id: "AST6A899724BF",
    state_code: "20",
    name: "Pointe La Rue",
    country_code: "SC",
    lat: -4.680489,
    lon: 55.5191857,
  },
  {
    art_state_id: "AST7CF4DEFF4B",
    state_code: "19",
    name: "Plaisance",
    country_code: "SC",
    lat: 45.607095,
    lon: -75.1142745,
  },
  {
    art_state_id: "ASTAA0721C5BE",
    state_code: "08",
    name: "Beau Vallon",
    country_code: "SC",
    lat: -4.6210967,
    lon: 55.4277802,
  },
  {
    art_state_id: "AST71ADDC9A57",
    state_code: "02",
    name: "Anse Boileau",
    country_code: "SC",
    lat: -4.7047268,
    lon: 55.4859363,
  },
  {
    art_state_id: "AST615AB0B34B",
    state_code: "07",
    name: "Baie Sainte Anne",
    country_code: "SC",
    lat: 47.05259,
    lon: -64.9524579,
  },
  {
    art_state_id: "ASTE5F7A9839B",
    state_code: "09",
    name: "Bel Air",
    country_code: "SC",
    lat: 34.1002455,
    lon: -118.459463,
  },
  {
    art_state_id: "AST04B3B1B1A8",
    state_code: "16",
    name: "La Rivière Anglaise",
    country_code: "SC",
    lat: -4.610615,
    lon: 55.4540841,
  },
  {
    art_state_id: "AST119714A54A",
    state_code: "11",
    name: "Cascade",
    country_code: "SC",
    lat: 44.5162821,
    lon: -116.0417983,
  },
  {
    art_state_id: "ASTDB5C51710F",
    state_code: "SA",
    name: "Shaki",
    country_code: "AZ",
    lat: 41.1974753,
    lon: 47.1571241,
  },
  {
    art_state_id: "ASTC361631806",
    state_code: "TAR",
    name: "Tartar District",
    country_code: "AZ",
    lat: 40.3443875,
    lon: 46.9376519,
  },
  {
    art_state_id: "AST9776F52803",
    state_code: "SR",
    name: "Shirvan",
    country_code: "AZ",
    lat: 39.9469707,
    lon: 48.9223919,
  },
  {
    art_state_id: "AST213149D43F",
    state_code: "QAZ",
    name: "Qazakh District",
    country_code: "AZ",
    lat: 41.0971074,
    lon: 45.3516331,
  },
  {
    art_state_id: "AST4B45AC8158",
    state_code: "SAD",
    name: "Sadarak District",
    country_code: "AZ",
    lat: 39.7105114,
    lon: 44.8864277,
  },
  {
    art_state_id: "ASTD50AEEFCBC",
    state_code: "YEV",
    name: "Yevlakh District",
    country_code: "AZ",
    lat: 40.6196638,
    lon: 47.1500324,
  },
  {
    art_state_id: "AST255814FF27",
    state_code: "XCI",
    name: "Khojali District",
    country_code: "AZ",
    lat: 39.9132553,
    lon: 46.794305,
  },
  {
    art_state_id: "AST63E0AD845E",
    state_code: "KAL",
    name: "Kalbajar District",
    country_code: "AZ",
    lat: 40.1024329,
    lon: 46.0364872,
  },
  {
    art_state_id: "ASTCCD52B1FE4",
    state_code: "QAX",
    name: "Qakh District",
    country_code: "AZ",
    lat: 41.4206827,
    lon: 46.9320184,
  },
  {
    art_state_id: "AST65BC3D7132",
    state_code: "FUZ",
    name: "Fizuli District",
    country_code: "AZ",
    lat: 39.5378605,
    lon: 47.3033877,
  },
  {
    art_state_id: "AST5947A2BA71",
    state_code: "AST",
    name: "Astara District",
    country_code: "AZ",
    lat: 38.4937845,
    lon: 48.6944365,
  },
  {
    art_state_id: "ASTA537C60494",
    state_code: "SMI",
    name: "Shamakhi District",
    country_code: "AZ",
    lat: 40.6318731,
    lon: 48.6363801,
  },
  {
    art_state_id: "ASTC864E3091B",
    state_code: "NEF",
    name: "Neftchala District",
    country_code: "AZ",
    lat: 39.3881052,
    lon: 49.2413743,
  },
  {
    art_state_id: "AST9D3D9C664D",
    state_code: "GOY",
    name: "Goychay",
    country_code: "AZ",
    lat: 40.6236168,
    lon: 47.7403034,
  },
  {
    art_state_id: "ASTD0D42C8BBB",
    state_code: "BIL",
    name: "Bilasuvar District",
    country_code: "AZ",
    lat: 39.4598833,
    lon: 48.5509813,
  },
  {
    art_state_id: "ASTBFC607ED25",
    state_code: "TOV",
    name: "Tovuz District",
    country_code: "AZ",
    lat: 40.9954523,
    lon: 45.6165907,
  },
  {
    art_state_id: "ASTFD3022F48A",
    state_code: "ORD",
    name: "Ordubad District",
    country_code: "AZ",
    lat: 38.9021622,
    lon: 46.0237625,
  },
  {
    art_state_id: "AST39AADD04CE",
    state_code: "SAR",
    name: "Sharur District",
    country_code: "AZ",
    lat: 39.5536332,
    lon: 44.984568,
  },
  {
    art_state_id: "AST00D4BCBB66",
    state_code: "SMX",
    name: "Samukh District",
    country_code: "AZ",
    lat: 40.7604631,
    lon: 46.4063181,
  },
  {
    art_state_id: "AST4EF43E54C9",
    state_code: "XIZ",
    name: "Khizi District",
    country_code: "AZ",
    lat: 40.9109489,
    lon: 49.0729264,
  },
  {
    art_state_id: "ASTFFCBAE3811",
    state_code: "YE",
    name: "Yevlakh",
    country_code: "AZ",
    lat: 40.6196638,
    lon: 47.1500324,
  },
  {
    art_state_id: "AST5206907EE3",
    state_code: "UCA",
    name: "Ujar District",
    country_code: "AZ",
    lat: 40.5067525,
    lon: 47.6489641,
  },
  {
    art_state_id: "AST8275614AC1",
    state_code: "ABS",
    name: "Absheron District",
    country_code: "AZ",
    lat: 40.3629693,
    lon: 49.2736815,
  },
  {
    art_state_id: "AST84DDDB26A9",
    state_code: "LAC",
    name: "Lachin District",
    country_code: "AZ",
    lat: 39.6383414,
    lon: 46.5460853,
  },
  {
    art_state_id: "AST4F6235C6B0",
    state_code: "QAB",
    name: "Qabala District",
    country_code: "AZ",
    lat: 40.9253925,
    lon: 47.8016106,
  },
  {
    art_state_id: "ASTE70B295528",
    state_code: "AGA",
    name: "Agstafa District",
    country_code: "AZ",
    lat: 41.2655933,
    lon: 45.5134291,
  },
  {
    art_state_id: "ASTFAE541AAEE",
    state_code: "IMI",
    name: "Imishli District",
    country_code: "AZ",
    lat: 39.8694686,
    lon: 48.0665218,
  },
  {
    art_state_id: "ASTC0100C9DB7",
    state_code: "SAL",
    name: "Salyan District",
    country_code: "AZ",
    lat: 28.3524811,
    lon: 82.12784,
  },
  {
    art_state_id: "ASTAB990B82E0",
    state_code: "LER",
    name: "Lerik District",
    country_code: "AZ",
    lat: 38.7736192,
    lon: 48.4151483,
  },
  {
    art_state_id: "ASTC4234B8CAD",
    state_code: "AGU",
    name: "Agsu District",
    country_code: "AZ",
    lat: 40.5283339,
    lon: 48.3650835,
  },
  {
    art_state_id: "AST157013A935",
    state_code: "QBI",
    name: "Qubadli District",
    country_code: "AZ",
    lat: 39.2713996,
    lon: 46.6354312,
  },
  {
    art_state_id: "ASTD7B142D57C",
    state_code: "KUR",
    name: "Kurdamir District",
    country_code: "AZ",
    lat: 40.3698651,
    lon: 48.1644626,
  },
  {
    art_state_id: "ASTEA62367FDD",
    state_code: "YAR",
    name: "Yardymli District",
    country_code: "AZ",
    lat: 38.9058917,
    lon: 48.2496127,
  },
  {
    art_state_id: "AST364C259AAA",
    state_code: "GOR",
    name: "Goranboy District",
    country_code: "AZ",
    lat: 40.5380506,
    lon: 46.5990891,
  },
  {
    art_state_id: "ASTF9ACA2D682",
    state_code: "BA",
    name: "Baku",
    country_code: "AZ",
    lat: 40.4092617,
    lon: 49.8670924,
  },
  {
    art_state_id: "AST88A3C85F39",
    state_code: "AGS",
    name: "Agdash District",
    country_code: "AZ",
    lat: 40.6335427,
    lon: 47.467431,
  },
  {
    art_state_id: "AST024E802AF9",
    state_code: "BEY",
    name: "Beylagan District",
    country_code: "AZ",
    lat: 39.7723073,
    lon: 47.6154166,
  },
  {
    art_state_id: "ASTD66567D23D",
    state_code: "MAS",
    name: "Masally District",
    country_code: "AZ",
    lat: 39.0340722,
    lon: 48.6589354,
  },
  {
    art_state_id: "AST19807522CE",
    state_code: "OGU",
    name: "Oghuz District",
    country_code: "AZ",
    lat: 41.0727924,
    lon: 47.4650672,
  },
  {
    art_state_id: "AST1528301AB5",
    state_code: "SAT",
    name: "Saatly District",
    country_code: "AZ",
    lat: 39.9095503,
    lon: 48.3595122,
  },
  {
    art_state_id: "ASTEB19EAB0EE",
    state_code: "LA",
    name: "Lankaran District",
    country_code: "AZ",
    lat: 38.7528669,
    lon: 48.8475015,
  },
  {
    art_state_id: "ASTB8EA24DA16",
    state_code: "AGM",
    name: "Agdam District",
    country_code: "AZ",
    lat: 39.9931853,
    lon: 46.9949562,
  },
  {
    art_state_id: "AST4E09475272",
    state_code: "BAL",
    name: "Balakan District",
    country_code: "AZ",
    lat: 41.7037509,
    lon: 46.4044213,
  },
  {
    art_state_id: "ASTE3487D5DA3",
    state_code: "DAS",
    name: "Dashkasan District",
    country_code: "AZ",
    lat: 40.5202257,
    lon: 46.0779304,
  },
  {
    art_state_id: "AST20F9EF0265",
    state_code: "NX",
    name: "Nakhchivan Autonomous Republic",
    country_code: "AZ",
    lat: 39.3256814,
    lon: 45.4912648,
  },
  {
    art_state_id: "ASTD9BBE79088",
    state_code: "QBA",
    name: "Quba District",
    country_code: "AZ",
    lat: 41.1564242,
    lon: 48.4135021,
  },
  {
    art_state_id: "AST0F9C220F23",
    state_code: "ISM",
    name: "Ismailli District",
    country_code: "AZ",
    lat: 40.7429936,
    lon: 48.2125556,
  },
  {
    art_state_id: "AST9524E80169",
    state_code: "SAB",
    name: "Sabirabad District",
    country_code: "AZ",
    lat: 39.9870663,
    lon: 48.4692545,
  },
  {
    art_state_id: "AST5A107B2921",
    state_code: "ZAQ",
    name: "Zaqatala District",
    country_code: "AZ",
    lat: 41.5906889,
    lon: 46.7240373,
  },
  {
    art_state_id: "AST4ACF331C3C",
    state_code: "KAN",
    name: "Kangarli District",
    country_code: "AZ",
    lat: 39.387194,
    lon: 45.1639852,
  },
  {
    art_state_id: "ASTA0EE394D1C",
    state_code: "XVD",
    name: "Martuni",
    country_code: "AZ",
    lat: 39.7914693,
    lon: 47.1100814,
  },
  {
    art_state_id: "ASTB52D018D51",
    state_code: "BAR",
    name: "Barda District",
    country_code: "AZ",
    lat: 40.3706555,
    lon: 47.1378909,
  },
  {
    art_state_id: "ASTDE8A048E0B",
    state_code: "CAB",
    name: "Jabrayil District",
    country_code: "AZ",
    lat: 39.2645544,
    lon: 46.9621562,
  },
  {
    art_state_id: "AST16F61A3ED0",
    state_code: "HAC",
    name: "Hajigabul District",
    country_code: "AZ",
    lat: 40.039377,
    lon: 48.9202533,
  },
  {
    art_state_id: "AST5B8D970493",
    state_code: "CUL",
    name: "Julfa District",
    country_code: "AZ",
    lat: 38.9604983,
    lon: 45.6292939,
  },
  {
    art_state_id: "ASTEB61B23E42",
    state_code: "QOB",
    name: "Gobustan District",
    country_code: "AZ",
    lat: 40.5326104,
    lon: 48.927375,
  },
  {
    art_state_id: "ASTBAA649BF4D",
    state_code: "GYG",
    name: "Goygol District",
    country_code: "AZ",
    lat: 40.5595378,
    lon: 46.3314953,
  },
  {
    art_state_id: "ASTC3397E7EA8",
    state_code: "BAB",
    name: "Babek District",
    country_code: "AZ",
    lat: 39.1507613,
    lon: 45.4485368,
  },
  {
    art_state_id: "AST104E8138D0",
    state_code: "ZAR",
    name: "Zardab District",
    country_code: "AZ",
    lat: 40.2148114,
    lon: 47.714944,
  },
  {
    art_state_id: "ASTBC5B5650BC",
    state_code: "AGC",
    name: "Aghjabadi District",
    country_code: "AZ",
    lat: 28.7891841,
    lon: 77.5160788,
  },
  {
    art_state_id: "AST774402F1C6",
    state_code: "CAL",
    name: "Jalilabad District",
    country_code: "AZ",
    lat: 39.2051632,
    lon: 48.5100604,
  },
  {
    art_state_id: "ASTD66E228004",
    state_code: "SAH",
    name: "Shahbuz District",
    country_code: "AZ",
    lat: 39.4452103,
    lon: 45.6568009,
  },
  {
    art_state_id: "ASTCB827A5934",
    state_code: "MI",
    name: "Mingachevir",
    country_code: "AZ",
    lat: 40.7702563,
    lon: 47.0496015,
  },
  {
    art_state_id: "AST937C578232",
    state_code: "ZAN",
    name: "Zangilan District",
    country_code: "AZ",
    lat: 39.0856899,
    lon: 46.6524728,
  },
  {
    art_state_id: "AST600B063100",
    state_code: "SM",
    name: "Sumqayit",
    country_code: "AZ",
    lat: 40.5854765,
    lon: 49.6317411,
  },
  {
    art_state_id: "AST92AE83FCCC",
    state_code: "SKR",
    name: "Shamkir District",
    country_code: "AZ",
    lat: 40.8288144,
    lon: 46.0166879,
  },
  {
    art_state_id: "ASTDF0BDE5390",
    state_code: "SIY",
    name: "Siazan District",
    country_code: "AZ",
    lat: 41.0783833,
    lon: 49.1118477,
  },
  {
    art_state_id: "ASTAFD2316055",
    state_code: "GA",
    name: "Ganja",
    country_code: "AZ",
    lat: 36.3687338,
    lon: -95.9985767,
  },
  {
    art_state_id: "ASTC32A155FEF",
    state_code: "SAK",
    name: "Shaki District",
    country_code: "AZ",
    lat: 41.1134662,
    lon: 47.1316927,
  },
  {
    art_state_id: "AST61FED3C2D9",
    state_code: "LAN",
    name: "Lankaran",
    country_code: "AZ",
    lat: 38.7528669,
    lon: 48.8475015,
  },
  {
    art_state_id: "AST289EE91114",
    state_code: "QUS",
    name: "Qusar District",
    country_code: "AZ",
    lat: 41.4266886,
    lon: 48.4345577,
  },
  {
    art_state_id: "AST19EEFCE929",
    state_code: "GAD",
    name: "Gədəbəy",
    country_code: "AZ",
    lat: 40.5699639,
    lon: 45.8106883,
  },
  {
    art_state_id: "AST350C9516B8",
    state_code: "XAC",
    name: "Khachmaz District",
    country_code: "AZ",
    lat: 41.4591168,
    lon: 48.8020626,
  },
  {
    art_state_id: "ASTF090B48734",
    state_code: "SBN",
    name: "Shabran District",
    country_code: "AZ",
    lat: 41.2228376,
    lon: 48.8457304,
  },
  {
    art_state_id: "AST60A6EAEE3C",
    state_code: "SUS",
    name: "Shusha District",
    country_code: "AZ",
    lat: 39.7537438,
    lon: 46.7464755,
  },
  {
    art_state_id: "AST67711451C3",
    state_code: "SK",
    name: "Skrapar District",
    country_code: "AL",
    lat: 40.5349946,
    lon: 20.2832217,
  },
  {
    art_state_id: "ASTC10FA66C6B",
    state_code: "KA",
    name: "Kavajë District",
    country_code: "AL",
    lat: 41.1844529,
    lon: 19.5627596,
  },
  {
    art_state_id: "AST835C4A4FDA",
    state_code: "LE",
    name: "Lezhë District",
    country_code: "AL",
    lat: 41.786073,
    lon: 19.6460758,
  },
  {
    art_state_id: "ASTD15FE8DB2E",
    state_code: "LB",
    name: "Librazhd District",
    country_code: "AL",
    lat: 41.1829232,
    lon: 20.3174769,
  },
  {
    art_state_id: "AST8D26D66E52",
    state_code: "KO",
    name: "Korçë District",
    country_code: "AL",
    lat: 40.590567,
    lon: 20.6168921,
  },
  {
    art_state_id: "AST3FF820D0E3",
    state_code: "03",
    name: "Elbasan County",
    country_code: "AL",
    lat: 41.1266672,
    lon: 20.2355647,
  },
  {
    art_state_id: "AST877CDB5C00",
    state_code: "LU",
    name: "Lushnjë District",
    country_code: "AL",
    lat: 40.941983,
    lon: 19.6996428,
  },
  {
    art_state_id: "ASTBE5485F59A",
    state_code: "HA",
    name: "Has District",
    country_code: "AL",
    lat: 42.7901336,
    lon: -83.6122012,
  },
  {
    art_state_id: "ASTDE614D6B3B",
    state_code: "07",
    name: "Kukës County",
    country_code: "AL",
    lat: 42.0807464,
    lon: 20.4142923,
  },
  {
    art_state_id: "ASTE5908C0442",
    state_code: "MM",
    name: "Malësi e Madhe District",
    country_code: "AL",
    lat: 42.4245173,
    lon: 19.6163185,
  },
  {
    art_state_id: "AST2EE4EDF5F1",
    state_code: "01",
    name: "Berat County",
    country_code: "AL",
    lat: 40.6953012,
    lon: 20.0449662,
  },
  {
    art_state_id: "ASTB5B61F720F",
    state_code: "05",
    name: "Gjirokastër County",
    country_code: "AL",
    lat: 40.0672874,
    lon: 20.1045229,
  },
  {
    art_state_id: "AST354435D5A8",
    state_code: "DI",
    name: "Dibër District",
    country_code: "AL",
    lat: 41.5888163,
    lon: 20.2355647,
  },
  {
    art_state_id: "ASTF21E249041",
    state_code: "PG",
    name: "Pogradec District",
    country_code: "AL",
    lat: 40.9015314,
    lon: 20.6556289,
  },
  {
    art_state_id: "ASTD8D311DCC0",
    state_code: "BU",
    name: "Bulqizë District",
    country_code: "AL",
    lat: 41.4942587,
    lon: 20.2147157,
  },
  {
    art_state_id: "AST13CD11E1D8",
    state_code: "DV",
    name: "Devoll District",
    country_code: "AL",
    lat: 40.6447347,
    lon: 20.9506636,
  },
  {
    art_state_id: "AST88629AFDEC",
    state_code: "08",
    name: "Lezhë County",
    country_code: "AL",
    lat: 41.7813759,
    lon: 19.8067916,
  },
  {
    art_state_id: "AST8B5DC18167",
    state_code: "09",
    name: "Dibër County",
    country_code: "AL",
    lat: 41.5888163,
    lon: 20.2355647,
  },
  {
    art_state_id: "AST9DFF56DF58",
    state_code: "10",
    name: "Shkodër County",
    country_code: "AL",
    lat: 42.150371,
    lon: 19.6639309,
  },
  {
    art_state_id: "AST7F8911E9DF",
    state_code: "KC",
    name: "Kuçovë District",
    country_code: "AL",
    lat: 40.7837063,
    lon: 19.8782348,
  },
  {
    art_state_id: "AST9C624E14FC",
    state_code: "VL",
    name: "Vlorë District",
    country_code: "AL",
    lat: 40.4660668,
    lon: 19.491356,
  },
  {
    art_state_id: "AST4DF66126C8",
    state_code: "KR",
    name: "Krujë District",
    country_code: "AL",
    lat: 41.5094765,
    lon: 19.7710732,
  },
  {
    art_state_id: "ASTB76796B4C5",
    state_code: "11",
    name: "Tirana County",
    country_code: "AL",
    lat: 41.2427598,
    lon: 19.8067916,
  },
  {
    art_state_id: "AST4535060FDB",
    state_code: "TE",
    name: "Tepelenë District",
    country_code: "AL",
    lat: 40.2966632,
    lon: 20.0181673,
  },
  {
    art_state_id: "AST000C5DA190",
    state_code: "GR",
    name: "Gramsh District",
    country_code: "AL",
    lat: 40.8669873,
    lon: 20.1849323,
  },
  {
    art_state_id: "AST258A10186A",
    state_code: "DL",
    name: "Delvinë District",
    country_code: "AL",
    lat: 39.9481364,
    lon: 20.0955891,
  },
  {
    art_state_id: "ASTB229A32808",
    state_code: "PQ",
    name: "Peqin District",
    country_code: "AL",
    lat: 41.0470902,
    lon: 19.7502384,
  },
  {
    art_state_id: "AST44F3CFEDEE",
    state_code: "PU",
    name: "Pukë District",
    country_code: "AL",
    lat: 42.0469772,
    lon: 19.8960968,
  },
  {
    art_state_id: "AST420DAD9CC5",
    state_code: "GJ",
    name: "Gjirokastër District",
    country_code: "AL",
    lat: 40.0672874,
    lon: 20.1045229,
  },
  {
    art_state_id: "ASTCE025C892F",
    state_code: "KB",
    name: "Kurbin District",
    country_code: "AL",
    lat: 41.6412644,
    lon: 19.705595,
  },
  {
    art_state_id: "AST2D34448562",
    state_code: "KU",
    name: "Kukës District",
    country_code: "AL",
    lat: 42.0807464,
    lon: 20.4142923,
  },
  {
    art_state_id: "ASTDC7306DE85",
    state_code: "SR",
    name: "Sarandë District",
    country_code: "AL",
    lat: 39.8592119,
    lon: 20.0271001,
  },
  {
    art_state_id: "AST90D0850739",
    state_code: "PR",
    name: "Përmet District",
    country_code: "AL",
    lat: 40.2361837,
    lon: 20.3517334,
  },
  {
    art_state_id: "AST9EF35C3474",
    state_code: "SH",
    name: "Shkodër District",
    country_code: "AL",
    lat: 42.0692985,
    lon: 19.5032559,
  },
  {
    art_state_id: "AST94D7E43CD3",
    state_code: "FR",
    name: "Fier District",
    country_code: "AL",
    lat: 40.727504,
    lon: 19.5627596,
  },
  {
    art_state_id: "AST2720E9A93A",
    state_code: "ER",
    name: "Kolonjë District",
    country_code: "AL",
    lat: 40.3373262,
    lon: 20.6794676,
  },
  {
    art_state_id: "AST918CC4D56B",
    state_code: "BR",
    name: "Berat District",
    country_code: "AL",
    lat: 40.7086377,
    lon: 19.9437314,
  },
  {
    art_state_id: "ASTD27A243A24",
    state_code: "06",
    name: "Korçë County",
    country_code: "AL",
    lat: 40.590567,
    lon: 20.6168921,
  },
  {
    art_state_id: "AST175BB79C28",
    state_code: "04",
    name: "Fier County",
    country_code: "AL",
    lat: 40.9191392,
    lon: 19.6639309,
  },
  {
    art_state_id: "ASTE817CD68E5",
    state_code: "02",
    name: "Durrës County",
    country_code: "AL",
    lat: 41.5080972,
    lon: 19.6163185,
  },
  {
    art_state_id: "AST387E4CCB14",
    state_code: "TR",
    name: "Tirana District",
    country_code: "AL",
    lat: 41.3275459,
    lon: 19.8186982,
  },
  {
    art_state_id: "AST786FBDA497",
    state_code: "12",
    name: "Vlorë County",
    country_code: "AL",
    lat: 40.150096,
    lon: 19.8067916,
  },
  {
    art_state_id: "AST35BACFB360",
    state_code: "MT",
    name: "Mat District",
    country_code: "AL",
    lat: 41.5937675,
    lon: 19.9973244,
  },
  {
    art_state_id: "AST4090F482B4",
    state_code: "TP",
    name: "Tropojë District",
    country_code: "AL",
    lat: 42.3982151,
    lon: 20.1625955,
  },
  {
    art_state_id: "AST534570DE65",
    state_code: "MK",
    name: "Mallakastër District",
    country_code: "AL",
    lat: 40.5273376,
    lon: 19.7829791,
  },
  {
    art_state_id: "AST1F096FB089",
    state_code: "MR",
    name: "Mirditë District",
    country_code: "AL",
    lat: 41.764286,
    lon: 19.9020509,
  },
  {
    art_state_id: "AST1DDAE2B2EE",
    state_code: "DR",
    name: "Durrës District",
    country_code: "AL",
    lat: 41.3706517,
    lon: 19.5211063,
  },
  {
    art_state_id: "AST041D8DE44B",
    state_code: "69",
    name: "Sveti Nikole Municipality",
    country_code: "MK",
    lat: 41.8980312,
    lon: 21.9999435,
  },
  {
    art_state_id: "AST36903C0A34",
    state_code: "43",
    name: "Kratovo Municipality",
    country_code: "MK",
    lat: 42.0537141,
    lon: 22.0714835,
  },
  {
    art_state_id: "ASTB8F0497FEE",
    state_code: "31",
    name: "Zajas Municipality",
    country_code: "MK",
    lat: 41.6030328,
    lon: 20.8791343,
  },
  {
    art_state_id: "AST8FBED72BA9",
    state_code: "71",
    name: "Staro Nagoričane Municipality",
    country_code: "MK",
    lat: 42.2191692,
    lon: 21.9045541,
  },
  {
    art_state_id: "AST5412FF8E6A",
    state_code: "81",
    name: "Češinovo-Obleševo Municipality",
    country_code: "MK",
    lat: 41.8639316,
    lon: 22.262246,
  },
  {
    art_state_id: "AST513A5CAB3E",
    state_code: "22",
    name: "Debarca Municipality",
    country_code: "MK",
    lat: 41.3584077,
    lon: 20.8552919,
  },
  {
    art_state_id: "AST0D0F2F0430",
    state_code: "63",
    name: "Probištip Municipality",
    country_code: "MK",
    lat: 41.9589146,
    lon: 22.166867,
  },
  {
    art_state_id: "AST253DF1C58C",
    state_code: "45",
    name: "Krivogaštani Municipality",
    country_code: "MK",
    lat: 41.3082306,
    lon: 21.3679689,
  },
  {
    art_state_id: "AST4E840FE678",
    state_code: "18",
    name: "Gevgelija Municipality",
    country_code: "MK",
    lat: 41.2118606,
    lon: 22.3814624,
  },
  {
    art_state_id: "AST943B3D38DC",
    state_code: "05",
    name: "Bogdanci Municipality",
    country_code: "MK",
    lat: 41.1869616,
    lon: 22.5960268,
  },
  {
    art_state_id: "ASTDCE1A3AFA0",
    state_code: "15",
    name: "Vraneštica Municipality",
    country_code: "MK",
    lat: 41.4829087,
    lon: 21.0579632,
  },
  {
    art_state_id: "AST8431B63754",
    state_code: "13",
    name: "Veles Municipality",
    country_code: "MK",
    lat: 41.7274426,
    lon: 21.7137694,
  },
  {
    art_state_id: "ASTB7EFFBFE21",
    state_code: "07",
    name: "Bosilovo Municipality",
    country_code: "MK",
    lat: 41.4904864,
    lon: 22.7867174,
  },
  {
    art_state_id: "ASTB092499E33",
    state_code: "53",
    name: "Mogila Municipality",
    country_code: "MK",
    lat: 41.1479645,
    lon: 21.4514369,
  },
  {
    art_state_id: "ASTA84A1E1B2C",
    state_code: "75",
    name: "Tearce Municipality",
    country_code: "MK",
    lat: 42.0777511,
    lon: 21.0534923,
  },
  {
    art_state_id: "ASTCB3C97D835",
    state_code: "24",
    name: "Demir Kapija Municipality",
    country_code: "MK",
    lat: 41.3795538,
    lon: 22.2145571,
  },
  {
    art_state_id: "ASTF26D71DCDC",
    state_code: "02",
    name: "Aračinovo Municipality",
    country_code: "MK",
    lat: 42.0247381,
    lon: 21.5766407,
  },
  {
    art_state_id: "AST9E2E67CD28",
    state_code: "28",
    name: "Drugovo Municipality",
    country_code: "MK",
    lat: 41.4408153,
    lon: 20.9268201,
  },
  {
    art_state_id: "AST46FF053B23",
    state_code: "11",
    name: "Vasilevo Municipality",
    country_code: "MK",
    lat: 41.4741699,
    lon: 22.6422128,
  },
  {
    art_state_id: "AST5C00AF2AE4",
    state_code: "48",
    name: "Lipkovo Municipality",
    country_code: "MK",
    lat: 42.2006626,
    lon: 21.6183755,
  },
  {
    art_state_id: "ASTB2BA5C4F90",
    state_code: "08",
    name: "Brvenica Municipality",
    country_code: "MK",
    lat: 41.9681482,
    lon: 20.9819586,
  },
  {
    art_state_id: "AST9D7691F2BC",
    state_code: "83",
    name: "Štip Municipality",
    country_code: "MK",
    lat: 41.7079297,
    lon: 22.1907122,
  },
  {
    art_state_id: "ASTA720024F02",
    state_code: "12",
    name: "Vevčani Municipality",
    country_code: "MK",
    lat: 41.2407543,
    lon: 20.5915649,
  },
  {
    art_state_id: "AST7FB2A3BA6B",
    state_code: "76",
    name: "Tetovo Municipality",
    country_code: "MK",
    lat: 42.027486,
    lon: 20.9506636,
  },
  {
    art_state_id: "AST09E278E885",
    state_code: "54",
    name: "Negotino Municipality",
    country_code: "MK",
    lat: 41.4989985,
    lon: 22.0953297,
  },
  {
    art_state_id: "AST8FC8D92A84",
    state_code: "41",
    name: "Konče Municipality",
    country_code: "MK",
    lat: 41.5171011,
    lon: 22.3814624,
  },
  {
    art_state_id: "AST3E3618C8B1",
    state_code: "62",
    name: "Prilep Municipality",
    country_code: "MK",
    lat: 41.2693142,
    lon: 21.7137694,
  },
  {
    art_state_id: "ASTD180F5531F",
    state_code: "68",
    name: "Saraj Municipality",
    country_code: "MK",
    lat: 41.9869496,
    lon: 21.2606554,
  },
  {
    art_state_id: "AST1264467F81",
    state_code: "30",
    name: "Želino Municipality",
    country_code: "MK",
    lat: 41.9006531,
    lon: 21.1175767,
  },
  {
    art_state_id: "ASTD9DBCDE826",
    state_code: "50",
    name: "Mavrovo and Rostuša Municipality",
    country_code: "MK",
    lat: 41.6092427,
    lon: 20.6012488,
  },
  {
    art_state_id: "AST38B865B074",
    state_code: "61",
    name: "Plasnica Municipality",
    country_code: "MK",
    lat: 41.4546349,
    lon: 21.1056539,
  },
  {
    art_state_id: "ASTCABA487355",
    state_code: "10",
    name: "Valandovo Municipality",
    country_code: "MK",
    lat: 41.3211909,
    lon: 22.5006693,
  },
  {
    art_state_id: "AST1833C63E60",
    state_code: "14",
    name: "Vinica Municipality",
    country_code: "MK",
    lat: 41.857102,
    lon: 22.5721881,
  },
  {
    art_state_id: "AST7FF89230C3",
    state_code: "33",
    name: "Zrnovci Municipality",
    country_code: "MK",
    lat: 41.8228221,
    lon: 22.4172256,
  },
  {
    art_state_id: "AST35A0BA021C",
    state_code: "37",
    name: "Karbinci",
    country_code: "MK",
    lat: 41.8180159,
    lon: 22.2324758,
  },
  {
    art_state_id: "ASTD6103AAC1F",
    state_code: "27",
    name: "Dolneni Municipality",
    country_code: "MK",
    lat: 41.4640935,
    lon: 21.4037407,
  },
  {
    art_state_id: "ASTBB86CAF9CE",
    state_code: "80",
    name: "Čaška Municipality",
    country_code: "MK",
    lat: 41.647438,
    lon: 21.6914115,
  },
  {
    art_state_id: "AST6D21668645",
    state_code: "44",
    name: "Kriva Palanka Municipality",
    country_code: "MK",
    lat: 42.2058454,
    lon: 22.3307965,
  },
  {
    art_state_id: "ASTAE473B38D0",
    state_code: "35",
    name: "Jegunovce Municipality",
    country_code: "MK",
    lat: 42.074072,
    lon: 21.1220478,
  },
  {
    art_state_id: "ASTD1525FCE00",
    state_code: "04",
    name: "Bitola Municipality",
    country_code: "MK",
    lat: 41.0363302,
    lon: 21.3321974,
  },
  {
    art_state_id: "AST47C1BF0C87",
    state_code: "84",
    name: "Šuto Orizari Municipality",
    country_code: "MK",
    lat: 42.0290416,
    lon: 21.4097027,
  },
  {
    art_state_id: "AST18E9BB62C0",
    state_code: "38",
    name: "Karpoš Municipality",
    country_code: "MK",
    lat: 41.9709661,
    lon: 21.3918168,
  },
  {
    art_state_id: "ASTA3D6036628",
    state_code: "57",
    name: "Oslomej Municipality",
    country_code: "MK",
    lat: 41.5758391,
    lon: 21.022196,
  },
  {
    art_state_id: "AST20B1F57CD9",
    state_code: "47",
    name: "Kumanovo Municipality",
    country_code: "MK",
    lat: 42.0732613,
    lon: 21.7853143,
  },
  {
    art_state_id: "AST63CDABACD9",
    state_code: "85",
    name: "Greater Skopje",
    country_code: "MK",
    lat: 41.9981294,
    lon: 21.4254355,
  },
  {
    art_state_id: "AST50ED4896E1",
    state_code: "60",
    name: "Pehčevo Municipality",
    country_code: "MK",
    lat: 41.7737132,
    lon: 22.8820489,
  },
  {
    art_state_id: "AST8BD1B7F034",
    state_code: "39",
    name: "Kisela Voda Municipality",
    country_code: "MK",
    lat: 41.92748,
    lon: 21.4931713,
  },
  {
    art_state_id: "AST26A13F7E79",
    state_code: "25",
    name: "Demir Hisar Municipality",
    country_code: "MK",
    lat: 41.227083,
    lon: 21.1414226,
  },
  {
    art_state_id: "ASTCCE990BD2C",
    state_code: "40",
    name: "Kičevo Municipality",
    country_code: "MK",
    lat: 41.5129112,
    lon: 20.9525065,
  },
  {
    art_state_id: "AST6D79A42E9E",
    state_code: "16",
    name: "Vrapčište Municipality",
    country_code: "MK",
    lat: 41.879116,
    lon: 20.83145,
  },
  {
    art_state_id: "AST995488CD95",
    state_code: "34",
    name: "Ilinden Municipality",
    country_code: "MK",
    lat: 41.9957443,
    lon: 21.5676975,
  },
  {
    art_state_id: "AST2B5BC34605",
    state_code: "67",
    name: "Rosoman Municipality",
    country_code: "MK",
    lat: 41.4848006,
    lon: 21.8807064,
  },
  {
    art_state_id: "ASTD29BA4C0DE",
    state_code: "52",
    name: "Makedonski Brod Municipality",
    country_code: "MK",
    lat: 41.5133088,
    lon: 21.2174329,
  },
  {
    art_state_id: "AST826D827FB8",
    state_code: "19",
    name: "Gostivar Municipality",
    country_code: "MK",
    lat: 41.8025541,
    lon: 20.9089378,
  },
  {
    art_state_id: "AST640F83D292",
    state_code: "09",
    name: "Butel Municipality",
    country_code: "MK",
    lat: 42.0895068,
    lon: 21.463361,
  },
  {
    art_state_id: "ASTA14FCB9FEA",
    state_code: "23",
    name: "Delčevo Municipality",
    country_code: "MK",
    lat: 41.9684387,
    lon: 22.762883,
  },
  {
    art_state_id: "ASTDEAAB95C3C",
    state_code: "55",
    name: "Novaci Municipality",
    country_code: "MK",
    lat: 41.0442661,
    lon: 21.4588894,
  },
  {
    art_state_id: "ASTA7C4C47ECA",
    state_code: "26",
    name: "Dojran Municipality",
    country_code: "MK",
    lat: 41.2436672,
    lon: 22.6913764,
  },
  {
    art_state_id: "ASTD9BA5CAA0E",
    state_code: "59",
    name: "Petrovec Municipality",
    country_code: "MK",
    lat: 41.9029897,
    lon: 21.689921,
  },
  {
    art_state_id: "AST4E01908F82",
    state_code: "58",
    name: "Ohrid Municipality",
    country_code: "MK",
    lat: 41.0682088,
    lon: 20.7599266,
  },
  {
    art_state_id: "AST9260891BD8",
    state_code: "72",
    name: "Struga Municipality",
    country_code: "MK",
    lat: 41.3173744,
    lon: 20.6645683,
  },
  {
    art_state_id: "AST1481B52E12",
    state_code: "51",
    name: "Makedonska Kamenica Municipality",
    country_code: "MK",
    lat: 42.0694604,
    lon: 22.548349,
  },
  {
    art_state_id: "ASTC263EF5F16",
    state_code: "77",
    name: "Centar Municipality",
    country_code: "MK",
    lat: 41.9698934,
    lon: 21.4216267,
  },
  {
    art_state_id: "AST2D9D071E0D",
    state_code: "01",
    name: "Aerodrom Municipality",
    country_code: "MK",
    lat: 41.9464363,
    lon: 21.4931713,
  },
  {
    art_state_id: "ASTA433087466",
    state_code: "79",
    name: "Čair Municipality",
    country_code: "MK",
    lat: 41.9930355,
    lon: 21.4365318,
  },
  {
    art_state_id: "AST4F24BE16A0",
    state_code: "49",
    name: "Lozovo Municipality",
    country_code: "MK",
    lat: 41.7818139,
    lon: 21.9000827,
  },
  {
    art_state_id: "AST4FF13C751B",
    state_code: "32",
    name: "Zelenikovo Municipality",
    country_code: "MK",
    lat: 41.8733812,
    lon: 21.602725,
  },
  {
    art_state_id: "AST5250F43C0C",
    state_code: "17",
    name: "Gazi Baba Municipality",
    country_code: "MK",
    lat: 42.0162961,
    lon: 21.4991334,
  },
  {
    art_state_id: "ASTC3F610DACB",
    state_code: "20",
    name: "Gradsko Municipality",
    country_code: "MK",
    lat: 41.5991608,
    lon: 21.8807064,
  },
  {
    art_state_id: "ASTFE53ADA9EA",
    state_code: "64",
    name: "Radoviš Municipality",
    country_code: "MK",
    lat: 41.6495531,
    lon: 22.4768287,
  },
  {
    art_state_id: "AST6FC5652A7A",
    state_code: "73",
    name: "Strumica Municipality",
    country_code: "MK",
    lat: 41.4378004,
    lon: 22.6427428,
  },
  {
    art_state_id: "AST6C5A9A27A5",
    state_code: "74",
    name: "Studeničani Municipality",
    country_code: "MK",
    lat: 41.9225639,
    lon: 21.5363965,
  },
  {
    art_state_id: "AST8B76F8C3DF",
    state_code: "66",
    name: "Resen Municipality",
    country_code: "MK",
    lat: 40.9368093,
    lon: 21.0460407,
  },
  {
    art_state_id: "AST267E742AA5",
    state_code: "36",
    name: "Kavadarci Municipality",
    country_code: "MK",
    lat: 41.2890068,
    lon: 21.9999435,
  },
  {
    art_state_id: "AST43CC382487",
    state_code: "46",
    name: "Kruševo Municipality",
    country_code: "MK",
    lat: 41.3769331,
    lon: 21.2606554,
  },
  {
    art_state_id: "ASTB9DBC79F35",
    state_code: "82",
    name: "Čučer-Sandevo Municipality",
    country_code: "MK",
    lat: 42.1483946,
    lon: 21.4037407,
  },
  {
    art_state_id: "AST8AC262192B",
    state_code: "03",
    name: "Berovo Municipality",
    country_code: "MK",
    lat: 41.6661929,
    lon: 22.762883,
  },
  {
    art_state_id: "AST4F746EA544",
    state_code: "65",
    name: "Rankovce Municipality",
    country_code: "MK",
    lat: 42.1808141,
    lon: 22.0953297,
  },
  {
    art_state_id: "AST2609BE613D",
    state_code: "56",
    name: "Novo Selo Municipality",
    country_code: "MK",
    lat: 41.432558,
    lon: 22.8820489,
  },
  {
    art_state_id: "AST9F0D56EC90",
    state_code: "70",
    name: "Sopište Municipality",
    country_code: "MK",
    lat: 41.8638492,
    lon: 21.3083499,
  },
  {
    art_state_id: "ASTC54A951F29",
    state_code: "78",
    name: "Centar Župa Municipality",
    country_code: "MK",
    lat: 41.4652259,
    lon: 20.5930548,
  },
  {
    art_state_id: "ASTB5C66C54F2",
    state_code: "06",
    name: "Bogovinje Municipality",
    country_code: "MK",
    lat: 41.9236371,
    lon: 20.9163887,
  },
  {
    art_state_id: "AST9FE3A651FD",
    state_code: "29",
    name: "Gjorče Petrov Municipality",
    country_code: "MK",
    lat: 42.0606374,
    lon: 21.3202736,
  },
  {
    art_state_id: "AST8F9DBE88AE",
    state_code: "42",
    name: "Kočani Municipality",
    country_code: "MK",
    lat: 41.9858374,
    lon: 22.4053046,
  },
  {
    art_state_id: "AST53586A6EC1",
    state_code: "11",
    name: "Požega-Slavonia County",
    country_code: "HR",
    lat: 45.3417868,
    lon: 17.8114359,
  },
  {
    art_state_id: "ASTDFF435EEF7",
    state_code: "17",
    name: "Split-Dalmatia County",
    country_code: "HR",
    lat: 43.5240328,
    lon: 16.8178377,
  },
  {
    art_state_id: "AST4158BBDB2B",
    state_code: "20",
    name: "Međimurje County",
    country_code: "HR",
    lat: 46.3766644,
    lon: 16.4213298,
  },
  {
    art_state_id: "AST1CC07E6903",
    state_code: "13",
    name: "Zadar County",
    country_code: "HR",
    lat: 44.146939,
    lon: 15.6164943,
  },
  {
    art_state_id: "ASTF6464981F2",
    state_code: "19",
    name: "Dubrovnik-Neretva County",
    country_code: "HR",
    lat: 43.0766588,
    lon: 17.5268471,
  },
  {
    art_state_id: "AST70D14F6900",
    state_code: "02",
    name: "Krapina-Zagorje County",
    country_code: "HR",
    lat: 46.1013393,
    lon: 15.8809693,
  },
  {
    art_state_id: "ASTFEDD113693",
    state_code: "15",
    name: "Šibenik-Knin County",
    country_code: "HR",
    lat: 43.9281485,
    lon: 16.1037694,
  },
  {
    art_state_id: "AST39BD9BB2A8",
    state_code: "09",
    name: "Lika-Senj County",
    country_code: "HR",
    lat: 44.6192218,
    lon: 15.4701608,
  },
  {
    art_state_id: "AST25B757778E",
    state_code: "10",
    name: "Virovitica-Podravina County",
    country_code: "HR",
    lat: 45.6557985,
    lon: 17.7932472,
  },
  {
    art_state_id: "ASTF903590025",
    state_code: "03",
    name: "Sisak-Moslavina County",
    country_code: "HR",
    lat: 45.3837926,
    lon: 16.5380994,
  },
  {
    art_state_id: "ASTB3F6983922",
    state_code: "07",
    name: "Bjelovar-Bilogora County",
    country_code: "HR",
    lat: 45.8987972,
    lon: 16.8423093,
  },
  {
    art_state_id: "AST7BD856DDDC",
    state_code: "08",
    name: "Primorje-Gorski Kotar County",
    country_code: "HR",
    lat: 45.3173996,
    lon: 14.8167466,
  },
  {
    art_state_id: "AST19E9A9DE52",
    state_code: "01",
    name: "Zagreb County",
    country_code: "HR",
    lat: 45.8706612,
    lon: 16.395491,
  },
  {
    art_state_id: "AST6BF5F02101",
    state_code: "12",
    name: "Brod-Posavina County",
    country_code: "HR",
    lat: 45.2637951,
    lon: 17.3264562,
  },
  {
    art_state_id: "AST8A2ECFD5D3",
    state_code: "21",
    name: "Zagreb",
    country_code: "HR",
    lat: 45.8150108,
    lon: 15.9819189,
  },
  {
    art_state_id: "AST9C5F360B0A",
    state_code: "05",
    name: "Varaždin County",
    country_code: "HR",
    lat: 46.2317473,
    lon: 16.3360559,
  },
  {
    art_state_id: "AST9E89F9D40B",
    state_code: "14",
    name: "Osijek-Baranja County",
    country_code: "HR",
    lat: 45.5576428,
    lon: 18.3942141,
  },
  {
    art_state_id: "AST47BD9B5F61",
    state_code: "16",
    name: "Vukovar-Syrmia County",
    country_code: "HR",
    lat: 45.1773552,
    lon: 18.8053527,
  },
  {
    art_state_id: "AST5C6907AB03",
    state_code: "06",
    name: "Koprivnica-Križevci County",
    country_code: "HR",
    lat: 46.1568919,
    lon: 16.8390826,
  },
  {
    art_state_id: "ASTA23DE7E5D2",
    state_code: "18",
    name: "Istria County",
    country_code: "HR",
    lat: 45.1286455,
    lon: 13.901542,
  },
  {
    art_state_id: "ASTE637BA41D4",
    state_code: "06",
    name: "Kyrenia District",
    country_code: "CY",
    lat: 35.299194,
    lon: 33.2363246,
  },
  {
    art_state_id: "AST67DAD9512A",
    state_code: "01",
    name: "Nicosia District",
    country_code: "CY",
    lat: 35.1855659,
    lon: 33.3822764,
  },
  {
    art_state_id: "AST435D113DB0",
    state_code: "05",
    name: "Paphos District",
    country_code: "CY",
    lat: 34.9164594,
    lon: 32.4920088,
  },
  {
    art_state_id: "ASTFF72862489",
    state_code: "03",
    name: "Larnaca District",
    country_code: "CY",
    lat: 34.8507206,
    lon: 33.4831906,
  },
  {
    art_state_id: "ASTBD2D285F44",
    state_code: "02",
    name: "Limassol District",
    country_code: "CY",
    lat: 34.7071301,
    lon: 33.0226174,
  },
  {
    art_state_id: "ASTF86B017E3D",
    state_code: "04",
    name: "Famagusta District",
    country_code: "CY",
    lat: 35.2857023,
    lon: 33.8411288,
  },
  {
    art_state_id: "AST510D62DEE5",
    state_code: "F",
    name: "Rangpur Division",
    country_code: "BD",
    lat: 25.8483388,
    lon: 88.9413865,
  },
  {
    art_state_id: "AST84B335E559",
    state_code: "11",
    name: "Cox's Bazar District",
    country_code: "BD",
    lat: 21.5640626,
    lon: 92.0282129,
  },
  {
    art_state_id: "AST5FB016317E",
    state_code: "01",
    name: "Bandarban District",
    country_code: "BD",
    lat: 21.8311002,
    lon: 92.3686321,
  },
  {
    art_state_id: "AST727F52E82C",
    state_code: "E",
    name: "Rajshahi Division",
    country_code: "BD",
    lat: 24.7105776,
    lon: 88.9413865,
  },
  {
    art_state_id: "AST9C431A37E9",
    state_code: "49",
    name: "Pabna District",
    country_code: "BD",
    lat: 24.158505,
    lon: 89.4480718,
  },
  {
    art_state_id: "ASTBC0D74F86C",
    state_code: "57",
    name: "Sherpur District",
    country_code: "BD",
    lat: 25.0746235,
    lon: 90.1494904,
  },
  {
    art_state_id: "AST74187A93D9",
    state_code: "07",
    name: "Bhola District",
    country_code: "BD",
    lat: 22.1785315,
    lon: 90.7101023,
  },
  {
    art_state_id: "AST5B5509866F",
    state_code: "22",
    name: "Jessore District",
    country_code: "BD",
    lat: 23.1634014,
    lon: 89.2181664,
  },
  {
    art_state_id: "AST127AF8306F",
    state_code: "H",
    name: "Mymensingh Division",
    country_code: "BD",
    lat: 24.71362,
    lon: 90.4502368,
  },
  {
    art_state_id: "AST71F2CE2501",
    state_code: "55",
    name: "Rangpur District",
    country_code: "BD",
    lat: 25.7467925,
    lon: 89.2508335,
  },
  {
    art_state_id: "ASTFCFF29E891",
    state_code: "C",
    name: "Dhaka Division",
    country_code: "BD",
    lat: 23.9535742,
    lon: 90.1494988,
  },
  {
    art_state_id: "AST95697FA4E2",
    state_code: "45",
    name: "Chapai Nawabganj District",
    country_code: "BD",
    lat: 24.7413111,
    lon: 88.2912069,
  },
  {
    art_state_id: "AST5083EBC778",
    state_code: "15",
    name: "Faridpur District",
    country_code: "BD",
    lat: 23.5423919,
    lon: 89.6308921,
  },
  {
    art_state_id: "AST24E2727C79",
    state_code: "08",
    name: "Comilla District",
    country_code: "BD",
    lat: 23.4575667,
    lon: 91.1808996,
  },
  {
    art_state_id: "ASTCC76931268",
    state_code: "41",
    name: "Netrokona District",
    country_code: "BD",
    lat: 24.8103284,
    lon: 90.8656415,
  },
  {
    art_state_id: "AST374650EBD9",
    state_code: "G",
    name: "Sylhet Division",
    country_code: "BD",
    lat: 24.7049811,
    lon: 91.6760691,
  },
  {
    art_state_id: "AST5A4428E88B",
    state_code: "34",
    name: "Mymensingh District",
    country_code: "BD",
    lat: 24.7538575,
    lon: 90.4072919,
  },
  {
    art_state_id: "AST21B3417798",
    state_code: "60",
    name: "Sylhet District",
    country_code: "BD",
    lat: 24.8993357,
    lon: 91.8700473,
  },
  {
    art_state_id: "AST708EBD0360",
    state_code: "09",
    name: "Chandpur District",
    country_code: "BD",
    lat: 23.2513148,
    lon: 90.8517846,
  },
  {
    art_state_id: "AST1E6058A180",
    state_code: "43",
    name: "Narail District",
    country_code: "BD",
    lat: 23.1162929,
    lon: 89.5840404,
  },
  {
    art_state_id: "AST8B4C939C42",
    state_code: "40",
    name: "Narayanganj District",
    country_code: "BD",
    lat: 23.7146601,
    lon: 90.563609,
  },
  {
    art_state_id: "ASTE0C1A420D6",
    state_code: "13",
    name: "Dhaka District",
    country_code: "BD",
    lat: 23.810514,
    lon: 90.3371889,
  },
  {
    art_state_id: "AST0056BA97C5",
    state_code: "46",
    name: "Nilphamari District",
    country_code: "BD",
    lat: 25.8482798,
    lon: 88.9414134,
  },
  {
    art_state_id: "AST4C4CBF2389",
    state_code: "53",
    name: "Rajbari District",
    country_code: "BD",
    lat: 23.715134,
    lon: 89.5874819,
  },
  {
    art_state_id: "AST204CE5A4CA",
    state_code: "30",
    name: "Kushtia District",
    country_code: "BD",
    lat: 23.8906995,
    lon: 89.1099368,
  },
  {
    art_state_id: "AST7408E4057B",
    state_code: "D",
    name: "Khulna Division",
    country_code: "BD",
    lat: 22.8087816,
    lon: 89.2467191,
  },
  {
    art_state_id: "AST48688E76E0",
    state_code: "39",
    name: "Meherpur District",
    country_code: "BD",
    lat: 23.8051991,
    lon: 88.6723578,
  },
  {
    art_state_id: "ASTDE722FC0AF",
    state_code: "51",
    name: "Patuakhali District",
    country_code: "BD",
    lat: 22.2248632,
    lon: 90.4547503,
  },
  {
    art_state_id: "ASTB40777D9D1",
    state_code: "25",
    name: "Jhalokati District",
    country_code: "BD",
    lat: 22.57208,
    lon: 90.1869644,
  },
  {
    art_state_id: "AST231921F3A1",
    state_code: "26",
    name: "Kishoreganj District",
    country_code: "BD",
    lat: 24.4260457,
    lon: 90.9820668,
  },
  {
    art_state_id: "AST51332D0AFF",
    state_code: "32",
    name: "Lalmonirhat District",
    country_code: "BD",
    lat: 25.9923398,
    lon: 89.2847251,
  },
  {
    art_state_id: "AST396171DFCF",
    state_code: "59",
    name: "Sirajganj District",
    country_code: "BD",
    lat: 24.3141115,
    lon: 89.5699615,
  },
  {
    art_state_id: "AST45E6835B3D",
    state_code: "63",
    name: "Tangail District",
    country_code: "BD",
    lat: 24.3917427,
    lon: 89.9948257,
  },
  {
    art_state_id: "AST75B28D308F",
    state_code: "14",
    name: "Dinajpur District",
    country_code: "BD",
    lat: 25.6279123,
    lon: 88.6331758,
  },
  {
    art_state_id: "ASTDD486E4CF4",
    state_code: "02",
    name: "Barguna District",
    country_code: "BD",
    lat: 22.0952915,
    lon: 90.1120696,
  },
  {
    art_state_id: "ASTFD27CB49A7",
    state_code: "10",
    name: "Chittagong District",
    country_code: "BD",
    lat: 22.5150105,
    lon: 91.7538817,
  },
  {
    art_state_id: "AST0080E40B5F",
    state_code: "29",
    name: "Khagrachari District",
    country_code: "BD",
    lat: 23.1321751,
    lon: 91.949021,
  },
  {
    art_state_id: "AST5C759A7887",
    state_code: "44",
    name: "Natore District",
    country_code: "BD",
    lat: 24.410243,
    lon: 89.0076177,
  },
  {
    art_state_id: "AST0DDE0A7771",
    state_code: "12",
    name: "Chuadanga District",
    country_code: "BD",
    lat: 23.6160512,
    lon: 88.8263006,
  },
  {
    art_state_id: "AST9CB1316AD1",
    state_code: "23",
    name: "Jhenaidah District",
    country_code: "BD",
    lat: 23.5449873,
    lon: 89.1726031,
  },
  {
    art_state_id: "ASTEC8DE5E389",
    state_code: "35",
    name: "Munshiganj District",
    country_code: "BD",
    lat: 23.4980931,
    lon: 90.4126621,
  },
  {
    art_state_id: "AST7617FB2BCD",
    state_code: "50",
    name: "Pirojpur District",
    country_code: "BD",
    lat: 22.5790744,
    lon: 89.9759264,
  },
  {
    art_state_id: "AST23211EFBC9",
    state_code: "17",
    name: "Gopalganj District",
    country_code: "BD",
    lat: 26.4831584,
    lon: 84.43655,
  },
  {
    art_state_id: "AST15AB5982BE",
    state_code: "28",
    name: "Kurigram District",
    country_code: "BD",
    lat: 25.8072414,
    lon: 89.6294746,
  },
  {
    art_state_id: "AST92C23F5B5D",
    state_code: "38",
    name: "Moulvibazar District",
    country_code: "BD",
    lat: 24.3095344,
    lon: 91.7314903,
  },
  {
    art_state_id: "ASTA0D3B3EA73",
    state_code: "19",
    name: "Gaibandha District",
    country_code: "BD",
    lat: 25.3296928,
    lon: 89.5429652,
  },
  {
    art_state_id: "AST744A8AA06A",
    state_code: "05",
    name: "Bagerhat District",
    country_code: "BD",
    lat: 22.6602436,
    lon: 89.7895478,
  },
  {
    art_state_id: "ASTB301DFE3D4",
    state_code: "03",
    name: "Bogra District",
    country_code: "BD",
    lat: 24.8510402,
    lon: 89.3697225,
  },
  {
    art_state_id: "ASTDB162F9F89",
    state_code: "18",
    name: "Gazipur District",
    country_code: "BD",
    lat: 24.0958171,
    lon: 90.4125181,
  },
  {
    art_state_id: "ASTC3033546FF",
    state_code: "58",
    name: "Satkhira District",
    country_code: "BD",
    lat: 22.3154812,
    lon: 89.1114525,
  },
  {
    art_state_id: "ASTFD5D31A8C8",
    state_code: "52",
    name: "Panchagarh District",
    country_code: "BD",
    lat: 26.2708705,
    lon: 88.5951751,
  },
  {
    art_state_id: "AST64EFEE362E",
    state_code: "62",
    name: "Shariatpur District",
    country_code: "BD",
    lat: 23.2423214,
    lon: 90.4347711,
  },
  {
    art_state_id: "ASTF0F4A082C2",
    state_code: "33",
    name: "Bahadia",
    country_code: "BD",
    lat: 23.7898712,
    lon: 90.1671483,
  },
  {
    art_state_id: "ASTB193CDECEB",
    state_code: "B",
    name: "Chittagong Division",
    country_code: "BD",
    lat: 23.1793157,
    lon: 91.9881527,
  },
  {
    art_state_id: "AST2CBC2F7D90",
    state_code: "64",
    name: "Thakurgaon District",
    country_code: "BD",
    lat: 26.0418392,
    lon: 88.4282616,
  },
  {
    art_state_id: "AST79CC45D09F",
    state_code: "20",
    name: "Habiganj District",
    country_code: "BD",
    lat: 24.4771236,
    lon: 91.4506565,
  },
  {
    art_state_id: "AST70A56CE772",
    state_code: "24",
    name: "Joypurhat District",
    country_code: "BD",
    lat: 25.0947349,
    lon: 89.0944937,
  },
  {
    art_state_id: "ASTC1E44E163C",
    state_code: "A",
    name: "Barisal Division",
    country_code: "BD",
    lat: 22.3811131,
    lon: 90.3371889,
  },
  {
    art_state_id: "ASTAC11EF9049",
    state_code: "21",
    name: "Jamalpur District",
    country_code: "BD",
    lat: 25.0830926,
    lon: 89.7853218,
  },
  {
    art_state_id: "AST798B2DFCA4",
    state_code: "56",
    name: "Rangamati Hill District",
    country_code: "BD",
    lat: 22.7324173,
    lon: 92.2985134,
  },
  {
    art_state_id: "AST3A78DEB4C5",
    state_code: "04",
    name: "Brahmanbaria District",
    country_code: "BD",
    lat: 23.9608181,
    lon: 91.1115014,
  },
  {
    art_state_id: "ASTC14B733E2C",
    state_code: "27",
    name: "Khulna District",
    country_code: "BD",
    lat: 22.6737735,
    lon: 89.3966581,
  },
  {
    art_state_id: "AST5CB7BE0683",
    state_code: "61",
    name: "Sunamganj District",
    country_code: "BD",
    lat: 25.0714535,
    lon: 91.3991627,
  },
  {
    art_state_id: "AST8884EF59D1",
    state_code: "54",
    name: "Rajshahi District",
    country_code: "BD",
    lat: 24.3733087,
    lon: 88.6048716,
  },
  {
    art_state_id: "ASTF2084EFC3F",
    state_code: "48",
    name: "Naogaon District",
    country_code: "BD",
    lat: 24.9131597,
    lon: 88.7530952,
  },
  {
    art_state_id: "AST08E8FEC4FE",
    state_code: "47",
    name: "Noakhali District",
    country_code: "BD",
    lat: 22.8723789,
    lon: 91.0973184,
  },
  {
    art_state_id: "ASTA98BD1D955",
    state_code: "16",
    name: "Feni District",
    country_code: "BD",
    lat: 22.9408784,
    lon: 91.4066646,
  },
  {
    art_state_id: "AST1089D85D5A",
    state_code: "36",
    name: "Madaripur District",
    country_code: "BD",
    lat: 23.2393346,
    lon: 90.1869644,
  },
  {
    art_state_id: "AST0BA7A624F5",
    state_code: "06",
    name: "Barisal District",
    country_code: "BD",
    lat: 22.7022098,
    lon: 90.3696316,
  },
  {
    art_state_id: "AST7B30022A36",
    state_code: "31",
    name: "Lakshmipur District",
    country_code: "BD",
    lat: 22.9446744,
    lon: 90.8281907,
  },
  {
    art_state_id: "AST22C1B3B865",
    state_code: "33",
    name: "Okayama Prefecture",
    country_code: "JP",
    lat: 34.8963407,
    lon: 133.6375314,
  },
  {
    art_state_id: "ASTA573997596",
    state_code: "12",
    name: "Chiba Prefecture",
    country_code: "JP",
    lat: 35.3354155,
    lon: 140.1832516,
  },
  {
    art_state_id: "AST2A6FADB778",
    state_code: "44",
    name: "Ōita Prefecture",
    country_code: "JP",
    lat: 33.1589299,
    lon: 131.3611121,
  },
  {
    art_state_id: "AST60FE1D11FC",
    state_code: "13",
    name: "Tokyo",
    country_code: "JP",
    lat: 35.6761919,
    lon: 139.6503106,
  },
  {
    art_state_id: "AST5676A5B961",
    state_code: "29",
    name: "Nara Prefecture",
    country_code: "JP",
    lat: 34.2975528,
    lon: 135.8279734,
  },
  {
    art_state_id: "AST6AF8922FA3",
    state_code: "22",
    name: "Shizuoka Prefecture",
    country_code: "JP",
    lat: 35.0929397,
    lon: 138.3190276,
  },
  {
    art_state_id: "AST287BBBD7AE",
    state_code: "32",
    name: "Shimane Prefecture",
    country_code: "JP",
    lat: 35.1244094,
    lon: 132.6293446,
  },
  {
    art_state_id: "AST4AEA15156B",
    state_code: "23",
    name: "Aichi Prefecture",
    country_code: "JP",
    lat: 35.0182505,
    lon: 137.2923893,
  },
  {
    art_state_id: "AST7F8265309F",
    state_code: "34",
    name: "Hiroshima Prefecture",
    country_code: "JP",
    lat: 34.8823408,
    lon: 133.0194897,
  },
  {
    art_state_id: "AST04B8BA9274",
    state_code: "05",
    name: "Akita Prefecture",
    country_code: "JP",
    lat: 40.1376293,
    lon: 140.334341,
  },
  {
    art_state_id: "AST32A757847B",
    state_code: "17",
    name: "Ishikawa Prefecture",
    country_code: "JP",
    lat: 36.3260317,
    lon: 136.5289653,
  },
  {
    art_state_id: "ASTFDA78D1EB3",
    state_code: "28",
    name: "Hyōgo Prefecture",
    country_code: "JP",
    lat: 34.8579518,
    lon: 134.5453787,
  },
  {
    art_state_id: "AST18DBDCD07D",
    state_code: "01",
    name: "Hokkaidō Prefecture",
    country_code: "JP",
    lat: 43.2203266,
    lon: 142.8634737,
  },
  {
    art_state_id: "ASTDB75CCEFCC",
    state_code: "24",
    name: "Mie Prefecture",
    country_code: "JP",
    lat: 33.8143901,
    lon: 136.0487047,
  },
  {
    art_state_id: "ASTFC186A21E3",
    state_code: "26",
    name: "Kyōto Prefecture",
    country_code: "JP",
    lat: 35.1566609,
    lon: 135.5251982,
  },
  {
    art_state_id: "ASTC17486B85D",
    state_code: "35",
    name: "Yamaguchi Prefecture",
    country_code: "JP",
    lat: 34.2796769,
    lon: 131.5212742,
  },
  {
    art_state_id: "AST78C9E7CA55",
    state_code: "36",
    name: "Tokushima Prefecture",
    country_code: "JP",
    lat: 33.9419655,
    lon: 134.3236557,
  },
  {
    art_state_id: "ASTDBB94B3F51",
    state_code: "06",
    name: "Yamagata Prefecture",
    country_code: "JP",
    lat: 38.5370564,
    lon: 140.1435198,
  },
  {
    art_state_id: "AST17181FDFFB",
    state_code: "16",
    name: "Toyama Prefecture",
    country_code: "JP",
    lat: 36.6958266,
    lon: 137.2137071,
  },
  {
    art_state_id: "ASTEE9BB96719",
    state_code: "02",
    name: "Aomori Prefecture",
    country_code: "JP",
    lat: 40.7657077,
    lon: 140.9175879,
  },
  {
    art_state_id: "ASTE5A67DC7BA",
    state_code: "46",
    name: "Kagoshima Prefecture",
    country_code: "JP",
    lat: 31.3911958,
    lon: 130.8778586,
  },
  {
    art_state_id: "AST360CCE36F9",
    state_code: "15",
    name: "Niigata Prefecture",
    country_code: "JP",
    lat: 37.5178386,
    lon: 138.9269794,
  },
  {
    art_state_id: "AST5A73D15DBB",
    state_code: "14",
    name: "Kanagawa Prefecture",
    country_code: "JP",
    lat: 35.4913535,
    lon: 139.284143,
  },
  {
    art_state_id: "ASTF2CE619FCA",
    state_code: "20",
    name: "Nagano Prefecture",
    country_code: "JP",
    lat: 36.1543941,
    lon: 137.9218204,
  },
  {
    art_state_id: "AST0859575E29",
    state_code: "30",
    name: "Wakayama Prefecture",
    country_code: "JP",
    lat: 33.9480914,
    lon: 135.3745358,
  },
  {
    art_state_id: "ASTB66D15B65E",
    state_code: "25",
    name: "Shiga Prefecture",
    country_code: "JP",
    lat: 35.3292014,
    lon: 136.0563212,
  },
  {
    art_state_id: "AST3D4B3E8A33",
    state_code: "43",
    name: "Kumamoto Prefecture",
    country_code: "JP",
    lat: 32.8594427,
    lon: 130.7969149,
  },
  {
    art_state_id: "AST940BB7A4E4",
    state_code: "07",
    name: "Fukushima Prefecture",
    country_code: "JP",
    lat: 37.3834373,
    lon: 140.1832516,
  },
  {
    art_state_id: "ASTE8C5937D85",
    state_code: "18",
    name: "Fukui Prefecture",
    country_code: "JP",
    lat: 35.896227,
    lon: 136.2111579,
  },
  {
    art_state_id: "AST8520716C99",
    state_code: "42",
    name: "Nagasaki Prefecture",
    country_code: "JP",
    lat: 33.2488525,
    lon: 129.6930912,
  },
  {
    art_state_id: "AST0C36ECE507",
    state_code: "31",
    name: "Tottori Prefecture",
    country_code: "JP",
    lat: 35.3573161,
    lon: 133.4066618,
  },
  {
    art_state_id: "AST4CC45AE3A1",
    state_code: "08",
    name: "Ibaraki Prefecture",
    country_code: "JP",
    lat: 36.2193571,
    lon: 140.1832516,
  },
  {
    art_state_id: "AST348D4D56F3",
    state_code: "19",
    name: "Yamanashi Prefecture",
    country_code: "JP",
    lat: 35.6635113,
    lon: 138.6388879,
  },
  {
    art_state_id: "ASTD01E78D24F",
    state_code: "47",
    name: "Okinawa Prefecture",
    country_code: "JP",
    lat: 26.1201911,
    lon: 127.7025012,
  },
  {
    art_state_id: "AST995F5BEF67",
    state_code: "09",
    name: "Tochigi Prefecture",
    country_code: "JP",
    lat: 36.6714739,
    lon: 139.8547266,
  },
  {
    art_state_id: "ASTB1FC6B6A57",
    state_code: "45",
    name: "Miyazaki Prefecture",
    country_code: "JP",
    lat: 32.6036022,
    lon: 131.441251,
  },
  {
    art_state_id: "AST6C1F0ACE4D",
    state_code: "03",
    name: "Iwate Prefecture",
    country_code: "JP",
    lat: 39.5832989,
    lon: 141.2534574,
  },
  {
    art_state_id: "AST09DB50A15D",
    state_code: "04",
    name: "Miyagi Prefecture",
    country_code: "JP",
    lat: 38.630612,
    lon: 141.1193048,
  },
  {
    art_state_id: "ASTDBADEA90D7",
    state_code: "21",
    name: "Gifu Prefecture",
    country_code: "JP",
    lat: 35.7437491,
    lon: 136.9805103,
  },
  {
    art_state_id: "AST51827A71E5",
    state_code: "27",
    name: "Ōsaka Prefecture",
    country_code: "JP",
    lat: 34.6413315,
    lon: 135.5629394,
  },
  {
    art_state_id: "AST52345D57D1",
    state_code: "11",
    name: "Saitama Prefecture",
    country_code: "JP",
    lat: 35.9962513,
    lon: 139.4466005,
  },
  {
    art_state_id: "AST5F68CE26FE",
    state_code: "40",
    name: "Fukuoka Prefecture",
    country_code: "JP",
    lat: 33.5662559,
    lon: 130.715857,
  },
  {
    art_state_id: "AST07099E1300",
    state_code: "10",
    name: "Gunma Prefecture",
    country_code: "JP",
    lat: 36.5605388,
    lon: 138.8799972,
  },
  {
    art_state_id: "ASTE7BF0447CD",
    state_code: "41",
    name: "Saga Prefecture",
    country_code: "JP",
    lat: 33.3078371,
    lon: 130.2271243,
  },
  {
    art_state_id: "AST4560963C61",
    state_code: "37",
    name: "Kagawa Prefecture",
    country_code: "JP",
    lat: 34.2225915,
    lon: 134.0199152,
  },
  {
    art_state_id: "AST480DBC74D7",
    state_code: "38",
    name: "Ehime Prefecture",
    country_code: "JP",
    lat: 33.6025306,
    lon: 132.7857583,
  },
  {
    art_state_id: "ASTA8B6FFF8E3",
    state_code: "ON",
    name: "Ontario",
    country_code: "CA",
    lat: 51.253775,
    lon: -85.323214,
  },
  {
    art_state_id: "AST5C5ED931C6",
    state_code: "MB",
    name: "Manitoba",
    country_code: "CA",
    lat: 53.7608608,
    lon: -98.8138762,
  },
  {
    art_state_id: "ASTF26879E7DC",
    state_code: "NB",
    name: "New Brunswick",
    country_code: "CA",
    lat: 46.5653163,
    lon: -66.4619164,
  },
  {
    art_state_id: "AST017A33E811",
    state_code: "YT",
    name: "Yukon",
    country_code: "CA",
    lat: 35.5067215,
    lon: -97.7625441,
  },
  {
    art_state_id: "ASTAAFDECACC4",
    state_code: "SK",
    name: "Saskatchewan",
    country_code: "CA",
    lat: 52.9399159,
    lon: -106.4508639,
  },
  {
    art_state_id: "ASTD7EC75E40D",
    state_code: "PE",
    name: "Prince Edward Island",
    country_code: "CA",
    lat: 46.510712,
    lon: -63.4168136,
  },
  {
    art_state_id: "AST1A8624D331",
    state_code: "AB",
    name: "Alberta",
    country_code: "CA",
    lat: 53.9332706,
    lon: -116.5765035,
  },
  {
    art_state_id: "AST2A1AD2A95E",
    state_code: "QC",
    name: "Quebec",
    country_code: "CA",
    lat: 52.9399159,
    lon: -73.5491361,
  },
  {
    art_state_id: "ASTFE56A42B53",
    state_code: "NS",
    name: "Nova Scotia",
    country_code: "CA",
    lat: 44.6819866,
    lon: -63.744311,
  },
  {
    art_state_id: "AST98399C5C3D",
    state_code: "BC",
    name: "British Columbia",
    country_code: "CA",
    lat: 53.7266683,
    lon: -127.6476205,
  },
  {
    art_state_id: "AST69DFA71628",
    state_code: "NU",
    name: "Nunavut",
    country_code: "CA",
    lat: 70.2997711,
    lon: -83.107577,
  },
  {
    art_state_id: "ASTF9F341C67C",
    state_code: "NL",
    name: "Newfoundland and Labrador",
    country_code: "CA",
    lat: 53.1355091,
    lon: -57.6604364,
  },
  {
    art_state_id: "ASTB12BD087EE",
    state_code: "NT",
    name: "Northwest Territories",
    country_code: "CA",
    lat: 64.8255441,
    lon: -124.8457334,
  },
  {
    art_state_id: "AST6E1261B141",
    state_code: "NW",
    name: "White Nile",
    country_code: "SD",
    lat: 9.3321516,
    lon: 31.46153,
  },
  {
    art_state_id: "AST6AD9E27F0A",
    state_code: "RS",
    name: "Red Sea",
    country_code: "SD",
    lat: 20.280232,
    lon: 38.512573,
  },
  {
    art_state_id: "AST7FC581C6CC",
    state_code: "KH",
    name: "Khartoum",
    country_code: "SD",
    lat: 15.5006544,
    lon: 32.5598994,
  },
  {
    art_state_id: "ASTBFFC04C478",
    state_code: "SI",
    name: "Sennar",
    country_code: "SD",
    lat: 13.567469,
    lon: 33.5672045,
  },
  {
    art_state_id: "ASTB61E9FAAEB",
    state_code: "KS",
    name: "South Kordofan",
    country_code: "SD",
    lat: 11.1990192,
    lon: 29.4179324,
  },
  {
    art_state_id: "ASTF6ED65562C",
    state_code: "KA",
    name: "Kassala",
    country_code: "SD",
    lat: 15.4581332,
    lon: 36.4039629,
  },
  {
    art_state_id: "ASTE03C77585B",
    state_code: "GZ",
    name: "Al Jazirah",
    country_code: "SD",
    lat: 14.8859611,
    lon: 33.438353,
  },
  {
    art_state_id: "AST303C58FD2E",
    state_code: "GD",
    name: "Al Qadarif",
    country_code: "SD",
    lat: 14.024307,
    lon: 35.3685679,
  },
  {
    art_state_id: "AST75F5E7F9A8",
    state_code: "NB",
    name: "Blue Nile",
    country_code: "SD",
    lat: 47.598673,
    lon: -122.334419,
  },
  {
    art_state_id: "ASTBDC49C8335",
    state_code: "DW",
    name: "West Darfur",
    country_code: "SD",
    lat: 12.8463561,
    lon: 23.0011989,
  },
  {
    art_state_id: "AST75C41389CC",
    state_code: "GK",
    name: "West Kordofan",
    country_code: "SD",
    lat: 11.1990192,
    lon: 29.4179324,
  },
  {
    art_state_id: "AST9D9F576045",
    state_code: "DN",
    name: "North Darfur",
    country_code: "SD",
    lat: 15.7661969,
    lon: 24.9042208,
  },
  {
    art_state_id: "AST9328BA83C4",
    state_code: "NR",
    name: "River Nile",
    country_code: "SD",
    lat: 23.9727595,
    lon: 32.8749206,
  },
  {
    art_state_id: "AST8D22E2152F",
    state_code: "DE",
    name: "East Darfur",
    country_code: "SD",
    lat: 14.3782747,
    lon: 24.9042208,
  },
  {
    art_state_id: "AST7D9AB78B97",
    state_code: "KN",
    name: "North Kordofan",
    country_code: "SD",
    lat: 13.8306441,
    lon: 29.4179324,
  },
  {
    art_state_id: "ASTE693E3E419",
    state_code: "DS",
    name: "South Darfur",
    country_code: "SD",
    lat: 11.6488639,
    lon: 24.9042208,
  },
  {
    art_state_id: "AST5FDCFC9025",
    state_code: "NO",
    name: "Northern",
    country_code: "SD",
    lat: 38.063817,
    lon: -84.4628648,
  },
  {
    art_state_id: "AST3807C65707",
    state_code: "DC",
    name: "Central Darfur",
    country_code: "SD",
    lat: 14.3782747,
    lon: 24.9042208,
  },
  {
    art_state_id: "AST0FF10827D6",
    state_code: "29",
    name: "Khelvachauri Municipality",
    country_code: "GE",
    lat: 41.5801926,
    lon: 41.6610742,
  },
  {
    art_state_id: "AST81B27D8A9A",
    state_code: "50",
    name: "Senaki Municipality",
    country_code: "GE",
    lat: 42.269636,
    lon: 42.0656896,
  },
  {
    art_state_id: "AST6C40EB1AC0",
    state_code: "TB",
    name: "Tbilisi",
    country_code: "GE",
    lat: 41.7151377,
    lon: 44.827096,
  },
  {
    art_state_id: "AST7E51D2EA71",
    state_code: "AJ",
    name: "Adjara",
    country_code: "GE",
    lat: 41.6005626,
    lon: 42.0688383,
  },
  {
    art_state_id: "AST777B68AB37",
    state_code: "AB",
    name: "Autonomous Republic of Abkhazia",
    country_code: "GE",
    lat: 43.0015544,
    lon: 41.023407,
  },
  {
    art_state_id: "ASTAFC6E91078",
    state_code: "MM",
    name: "Mtskheta-Mtianeti",
    country_code: "GE",
    lat: 42.1682185,
    lon: 44.6506058,
  },
  {
    art_state_id: "AST1834716229",
    state_code: "SK",
    name: "Shida Kartli",
    country_code: "GE",
    lat: 42.0756944,
    lon: 43.9540462,
  },
  {
    art_state_id: "AST560C095258",
    state_code: "KK",
    name: "Kvemo Kartli",
    country_code: "GE",
    lat: 41.4791833,
    lon: 44.6560451,
  },
  {
    art_state_id: "ASTF7FAE8370B",
    state_code: "IM",
    name: "Imereti",
    country_code: "GE",
    lat: 42.230108,
    lon: 42.9008664,
  },
  {
    art_state_id: "AST02C890A40F",
    state_code: "SJ",
    name: "Samtskhe-Javakheti",
    country_code: "GE",
    lat: 41.5479296,
    lon: 43.27764,
  },
  {
    art_state_id: "AST7EFBD84A4D",
    state_code: "GU",
    name: "Guria",
    country_code: "GE",
    lat: 41.9442736,
    lon: 42.0458091,
  },
  {
    art_state_id: "ASTB4BD385CB1",
    state_code: "SZ",
    name: "Samegrelo-Zemo Svaneti",
    country_code: "GE",
    lat: 42.7352247,
    lon: 42.1689362,
  },
  {
    art_state_id: "ASTFCE3CCF1BC",
    state_code: "RL",
    name: "Racha-Lechkhumi and Kvemo Svaneti",
    country_code: "GE",
    lat: 42.6718873,
    lon: 43.0562836,
  },
  {
    art_state_id: "AST5C757C4AA1",
    state_code: "KA",
    name: "Kakheti",
    country_code: "GE",
    lat: 41.6481602,
    lon: 45.6905554,
  },
  {
    art_state_id: "AST29CB10E23D",
    state_code: "N",
    name: "Northern Province",
    country_code: "SL",
    lat: 9.118454,
    lon: -11.485644775105136,
  },
  {
    art_state_id: "AST4E373D9CB8",
    state_code: "S",
    name: "Southern Province",
    country_code: "SL",
    lat: 7.709922199999999,
    lon: -12.00041781522788,
  },
  {
    art_state_id: "AST5C04E25B31",
    state_code: "W",
    name: "Western Area",
    country_code: "SL",
    lat: 40.2545969,
    lon: -80.2455444,
  },
  {
    art_state_id: "AST525F9B72EE",
    state_code: "E",
    name: "Eastern Province",
    country_code: "SL",
    lat: 8.2050971,
    lon: -10.84373826377347,
  },
  {
    art_state_id: "ASTF4D3075363",
    state_code: "HI",
    name: "Hiran",
    country_code: "SO",
    lat: 4.321015,
    lon: 45.2993862,
  },
  {
    art_state_id: "ASTFC15F25EED",
    state_code: "MU",
    name: "Mudug",
    country_code: "SO",
    lat: 6.5656726,
    lon: 47.7637565,
  },
  {
    art_state_id: "AST20BE46C278",
    state_code: "BK",
    name: "Bakool",
    country_code: "SO",
    lat: 4.3657221,
    lon: 44.0960311,
  },
  {
    art_state_id: "ASTA44DA680DB",
    state_code: "GA",
    name: "Galguduud",
    country_code: "SO",
    lat: 5.1850128,
    lon: 46.8252838,
  },
  {
    art_state_id: "ASTA416887EA0",
    state_code: "SA",
    name: "Sanaag Region",
    country_code: "SO",
    lat: 10.3938218,
    lon: 47.7637565,
  },
  {
    art_state_id: "AST85AD5904AA",
    state_code: "NU",
    name: "Nugal",
    country_code: "SO",
    lat: 43.2793861,
    lon: 17.0339205,
  },
  {
    art_state_id: "AST58903D2902",
    state_code: "SH",
    name: "Lower Shebelle",
    country_code: "SO",
    lat: 1.8766458,
    lon: 44.2479015,
  },
  {
    art_state_id: "ASTB4E0CDAC30",
    state_code: "JD",
    name: "Middle Juba",
    country_code: "SO",
    lat: 2.0780488,
    lon: 41.6011814,
  },
  {
    art_state_id: "ASTD07AE191A2",
    state_code: "SD",
    name: "Middle Shebelle",
    country_code: "SO",
    lat: 2.9250247,
    lon: 45.9039689,
  },
  {
    art_state_id: "AST9AC91231E0",
    state_code: "JH",
    name: "Lower Juba",
    country_code: "SO",
    lat: 0.224021,
    lon: 41.6011814,
  },
  {
    art_state_id: "AST973ED0C0FD",
    state_code: "AW",
    name: "Awdal Region",
    country_code: "SO",
    lat: 10.6334285,
    lon: 43.329466,
  },
  {
    art_state_id: "ASTC738581CF3",
    state_code: "BY",
    name: "Bay",
    country_code: "SO",
    lat: 37.0365534,
    lon: -95.6174767,
  },
  {
    art_state_id: "AST51C3867166",
    state_code: "BN",
    name: "Banaadir",
    country_code: "SO",
    lat: 2.1187375,
    lon: 45.3369459,
  },
  {
    art_state_id: "AST6FA1DE2C5B",
    state_code: "GE",
    name: "Gedo",
    country_code: "SO",
    lat: 3.5039227,
    lon: 42.2362435,
  },
  {
    art_state_id: "AST4ED4C4897E",
    state_code: "TO",
    name: "Togdheer Region",
    country_code: "SO",
    lat: 9.4460587,
    lon: 45.2993862,
  },
  {
    art_state_id: "ASTB89BB56132",
    state_code: "BR",
    name: "Bari",
    country_code: "SO",
    lat: 41.1171432,
    lon: 16.8718715,
  },
  {
    art_state_id: "ASTC2C77E1606",
    state_code: "NC",
    name: "Northern Cape",
    country_code: "ZA",
    lat: -29.0466808,
    lon: 21.8568586,
  },
  {
    art_state_id: "AST5441DD388C",
    state_code: "FS",
    name: "Free State",
    country_code: "ZA",
    lat: 37.6858525,
    lon: -97.2811256,
  },
  {
    art_state_id: "AST1209C03FFC",
    state_code: "LP",
    name: "Limpopo",
    country_code: "ZA",
    lat: -23.4012946,
    lon: 29.4179324,
  },
  {
    art_state_id: "ASTD2EA65FE42",
    state_code: "NW",
    name: "North West",
    country_code: "ZA",
    lat: 32.758852,
    lon: -97.328806,
  },
  {
    art_state_id: "ASTD178C217A9",
    state_code: "KZN",
    name: "KwaZulu-Natal",
    country_code: "ZA",
    lat: -28.5305539,
    lon: 30.8958242,
  },
  {
    art_state_id: "AST4FA2C680BA",
    state_code: "GP",
    name: "Gauteng",
    country_code: "ZA",
    lat: -26.2707593,
    lon: 28.1122679,
  },
  {
    art_state_id: "AST9F7F9F9C26",
    state_code: "MP",
    name: "Mpumalanga",
    country_code: "ZA",
    lat: -25.565336,
    lon: 30.5279096,
  },
  {
    art_state_id: "AST98EDBFA99E",
    state_code: "EC",
    name: "Eastern Cape",
    country_code: "ZA",
    lat: -32.2968402,
    lon: 26.419389,
  },
  {
    art_state_id: "AST4DE97C3974",
    state_code: "WC",
    name: "Western Cape",
    country_code: "ZA",
    lat: -33.2277918,
    lon: 21.8568586,
  },
  {
    art_state_id: "ASTE5B7B4C7FF",
    state_code: "CO",
    name: "Chontales Department",
    country_code: "NI",
    lat: 11.9394717,
    lon: -85.1894045,
  },
  {
    art_state_id: "AST5893BED382",
    state_code: "MN",
    name: "Managua Department",
    country_code: "NI",
    lat: 12.1391699,
    lon: -86.3376761,
  },
  {
    art_state_id: "ASTFA2D5617AC",
    state_code: "RI",
    name: "Rivas Department",
    country_code: "NI",
    lat: 11.402349,
    lon: -85.684578,
  },
  {
    art_state_id: "AST0D68635930",
    state_code: "GR",
    name: "Granada Department",
    country_code: "NI",
    lat: 11.9344073,
    lon: -85.9560005,
  },
  {
    art_state_id: "AST2BC1742CE1",
    state_code: "LE",
    name: "León Department",
    country_code: "NI",
    lat: 12.5092037,
    lon: -86.6611083,
  },
  {
    art_state_id: "AST8329396660",
    state_code: "ES",
    name: "Estelí Department",
    country_code: "NI",
    lat: 13.0851139,
    lon: -86.3630197,
  },
  {
    art_state_id: "ASTD9018D5926",
    state_code: "BO",
    name: "Boaco Department",
    country_code: "NI",
    lat: 12.469284,
    lon: -85.6614682,
  },
  {
    art_state_id: "ASTA91058A7FD",
    state_code: "MT",
    name: "Matagalpa Department",
    country_code: "NI",
    lat: 12.9498436,
    lon: -85.4375574,
  },
  {
    art_state_id: "AST211A7764B6",
    state_code: "MD",
    name: "Madriz Department",
    country_code: "NI",
    lat: 13.4726005,
    lon: -86.4592091,
  },
  {
    art_state_id: "ASTD1F5ED6179",
    state_code: "SJ",
    name: "Río San Juan Department",
    country_code: "NI",
    lat: 11.478161,
    lon: -84.7733325,
  },
  {
    art_state_id: "AST9AA5620331",
    state_code: "CA",
    name: "Carazo Department",
    country_code: "NI",
    lat: 11.7274729,
    lon: -86.2158497,
  },
  {
    art_state_id: "ASTB8564BB0C2",
    state_code: "AN",
    name: "North Caribbean Coast Autonomous Region",
    country_code: "NI",
    lat: 13.8394456,
    lon: -83.9320806,
  },
  {
    art_state_id: "AST9FE0B789F9",
    state_code: "AS",
    name: "South Caribbean Coast Autonomous Region",
    country_code: "NI",
    lat: 12.1918502,
    lon: -84.1012861,
  },
  {
    art_state_id: "AST1B56370A09",
    state_code: "MS",
    name: "Masaya Department",
    country_code: "NI",
    lat: 11.9759328,
    lon: -86.0733498,
  },
  {
    art_state_id: "AST45F6536648",
    state_code: "CI",
    name: "Chinandega Department",
    country_code: "NI",
    lat: 12.8820062,
    lon: -87.1422895,
  },
  {
    art_state_id: "AST39FE7D4747",
    state_code: "JI",
    name: "Jinotega Department",
    country_code: "NI",
    lat: 13.0883907,
    lon: -85.9993997,
  },
  {
    art_state_id: "AST760A863348",
    state_code: "KA",
    name: "Karak Governorate",
    country_code: "JO",
    lat: 31.1853527,
    lon: 35.7047682,
  },
  {
    art_state_id: "AST3DBE0990E8",
    state_code: "AT",
    name: "Tafilah Governorate",
    country_code: "JO",
    lat: 30.8338063,
    lon: 35.6160513,
  },
  {
    art_state_id: "ASTFD5128803D",
    state_code: "MD",
    name: "Madaba Governorate",
    country_code: "JO",
    lat: 31.7196097,
    lon: 35.7932754,
  },
  {
    art_state_id: "AST147FF4AE62",
    state_code: "AQ",
    name: "Aqaba Governorate",
    country_code: "JO",
    lat: 29.532086,
    lon: 35.0062821,
  },
  {
    art_state_id: "AST90C406BCA6",
    state_code: "IR",
    name: "Irbid Governorate",
    country_code: "JO",
    lat: 32.5569636,
    lon: 35.8478965,
  },
  {
    art_state_id: "AST4EA5796501",
    state_code: "BA",
    name: "Balqa Governorate",
    country_code: "JO",
    lat: 32.0366806,
    lon: 35.728848,
  },
  {
    art_state_id: "AST7E7D1C12AA",
    state_code: "MA",
    name: "Mafraq Governorate",
    country_code: "JO",
    lat: 32.3416923,
    lon: 36.2020175,
  },
  {
    art_state_id: "ASTF4F79C2A5D",
    state_code: "AJ",
    name: "Ajloun Governorate",
    country_code: "JO",
    lat: 32.3325584,
    lon: 35.7516844,
  },
  {
    art_state_id: "AST735773E95F",
    state_code: "MN",
    name: "Ma'an Governorate",
    country_code: "JO",
    lat: 30.1926789,
    lon: 35.7249319,
  },
  {
    art_state_id: "ASTE8AFDE2D89",
    state_code: "AM",
    name: "Amman Governorate",
    country_code: "JO",
    lat: 31.9453633,
    lon: 35.9283895,
  },
  {
    art_state_id: "AST5E59337BCD",
    state_code: "JA",
    name: "Jerash Governorate",
    country_code: "JO",
    lat: 32.2747237,
    lon: 35.8960954,
  },
  {
    art_state_id: "ASTE1B5BD9539",
    state_code: "AZ",
    name: "Zarqa Governorate",
    country_code: "JO",
    lat: 32.0608505,
    lon: 36.0942121,
  },
  {
    art_state_id: "AST07C6F2AECB",
    state_code: "MA",
    name: "Manzini District",
    country_code: "SZ",
    lat: -26.5081999,
    lon: 31.3713164,
  },
  {
    art_state_id: "ASTCEC3B58418",
    state_code: "HH",
    name: "Hhohho District",
    country_code: "SZ",
    lat: -26.1365662,
    lon: 31.3541631,
  },
  {
    art_state_id: "AST7CF0E03A0B",
    state_code: "LU",
    name: "Lubombo District",
    country_code: "SZ",
    lat: -26.7851773,
    lon: 31.8107079,
  },
  {
    art_state_id: "ASTA65AE484E9",
    state_code: "SH",
    name: "Shiselweni District",
    country_code: "SZ",
    lat: -26.9827577,
    lon: 31.3541631,
  },
  {
    art_state_id: "AST4F30B050FB",
    state_code: "JA",
    name: "Al Jahra Governorate",
    country_code: "KW",
    lat: 29.9931831,
    lon: 47.7634731,
  },
  {
    art_state_id: "AST0127541878",
    state_code: "HA",
    name: "Hawalli Governorate",
    country_code: "KW",
    lat: 29.3056716,
    lon: 48.0307613,
  },
  {
    art_state_id: "AST774ACB9FBB",
    state_code: "MU",
    name: "Mubarak Al-Kabeer Governorate",
    country_code: "KW",
    lat: 29.21224,
    lon: 48.0605108,
  },
  {
    art_state_id: "AST37AA93E4F7",
    state_code: "FA",
    name: "Al Farwaniyah Governorate",
    country_code: "KW",
    lat: 29.273357,
    lon: 47.9400154,
  },
  {
    art_state_id: "AST2B03DE9452",
    state_code: "KU",
    name: "Capital Governorate",
    country_code: "KW",
    lat: 26.2285161,
    lon: 50.5860497,
  },
  {
    art_state_id: "AST23C27E293C",
    state_code: "AH",
    name: "Al Ahmadi Governorate",
    country_code: "KW",
    lat: 28.5745125,
    lon: 48.1024743,
  },
  {
    art_state_id: "ASTE64DE85539",
    state_code: "LP",
    name: "Luang Prabang Province",
    country_code: "LA",
    lat: 20.0656229,
    lon: 102.6216211,
  },
  {
    art_state_id: "AST0D95469EF6",
    state_code: "VT",
    name: "Vientiane Prefecture",
    country_code: "LA",
    lat: 18.110541,
    lon: 102.5298028,
  },
  {
    art_state_id: "AST1D1ADB9531",
    state_code: "VI",
    name: "Vientiane Province",
    country_code: "LA",
    lat: 18.5705063,
    lon: 102.6216211,
  },
  {
    art_state_id: "ASTBADB2920A2",
    state_code: "SL",
    name: "Salavan Province",
    country_code: "LA",
    lat: 15.8171073,
    lon: 106.2522143,
  },
  {
    art_state_id: "ASTF358DFCD9B",
    state_code: "AT",
    name: "Attapeu Province",
    country_code: "LA",
    lat: 14.93634,
    lon: 107.1011931,
  },
  {
    art_state_id: "AST57E66D279E",
    state_code: "XS",
    name: "Xaisomboun Province",
    country_code: "LA",
    lat: 18.4362924,
    lon: 104.4723301,
  },
  {
    art_state_id: "AST1E260A9555",
    state_code: "XE",
    name: "Sekong Province",
    country_code: "LA",
    lat: 15.5767446,
    lon: 107.0067031,
  },
  {
    art_state_id: "AST503AA84394",
    state_code: "BL",
    name: "Bolikhamsai Province",
    country_code: "LA",
    lat: 18.4362924,
    lon: 104.4723301,
  },
  {
    art_state_id: "AST8A81D41B9B",
    state_code: "KH",
    name: "Khammouane Province",
    country_code: "LA",
    lat: 17.6384066,
    lon: 105.2194808,
  },
  {
    art_state_id: "AST63EAE957BE",
    state_code: "PH",
    name: "Phongsaly Province",
    country_code: "LA",
    lat: 21.5919377,
    lon: 102.2547919,
  },
  {
    art_state_id: "ASTD58C23EFFC",
    state_code: "OU",
    name: "Oudomxay Province",
    country_code: "LA",
    lat: 20.4921929,
    lon: 101.8891721,
  },
  {
    art_state_id: "AST169FC010E7",
    state_code: "HO",
    name: "Houaphanh Province",
    country_code: "LA",
    lat: 20.3254175,
    lon: 104.1001326,
  },
  {
    art_state_id: "ASTF8239C5136",
    state_code: "SV",
    name: "Savannakhet Province",
    country_code: "LA",
    lat: 16.5065381,
    lon: 105.5943388,
  },
  {
    art_state_id: "ASTF2B4B98FA1",
    state_code: "BK",
    name: "Bokeo Province",
    country_code: "LA",
    lat: 20.2872662,
    lon: 100.7097867,
  },
  {
    art_state_id: "ASTDECDF489CD",
    state_code: "LM",
    name: "Luang Namtha Province",
    country_code: "LA",
    lat: 20.9170187,
    lon: 101.1617356,
  },
  {
    art_state_id: "AST911F1D9F80",
    state_code: "XA",
    name: "Sainyabuli Province",
    country_code: "LA",
    lat: 19.3907886,
    lon: 101.5248055,
  },
  {
    art_state_id: "AST0016C44E9A",
    state_code: "XN",
    name: "Xaisomboun",
    country_code: "LA",
    lat: 18.8631725,
    lon: 103.0344783,
  },
  {
    art_state_id: "ASTA6B9D1DEE1",
    state_code: "XI",
    name: "Xiangkhouang Province",
    country_code: "LA",
    lat: 19.6093003,
    lon: 103.7289167,
  },
  {
    art_state_id: "AST7BE6B6F2FF",
    state_code: "CH",
    name: "Champasak Province",
    country_code: "LA",
    lat: 14.6578664,
    lon: 105.9699878,
  },
  {
    art_state_id: "ASTB873D94AED",
    state_code: "T",
    name: "Talas Region",
    country_code: "KG",
    lat: 42.2867339,
    lon: 72.5204827,
  },
  {
    art_state_id: "ASTA2B2749B25",
    state_code: "B",
    name: "Batken Region",
    country_code: "KG",
    lat: 39.9721425,
    lon: 69.8597406,
  },
  {
    art_state_id: "ASTFCB6335DF7",
    state_code: "N",
    name: "Naryn Region",
    country_code: "KG",
    lat: 41.2943227,
    lon: 75.3412179,
  },
  {
    art_state_id: "AST26F27F29A8",
    state_code: "J",
    name: "Jalal-Abad Region",
    country_code: "KG",
    lat: 41.106808,
    lon: 72.8988069,
  },
  {
    art_state_id: "AST94E44C1DFF",
    state_code: "GB",
    name: "Bishkek",
    country_code: "KG",
    lat: 42.8746212,
    lon: 74.5697617,
  },
  {
    art_state_id: "AST77D134A681",
    state_code: "Y",
    name: "Issyk-Kul Region",
    country_code: "KG",
    lat: 42.1859428,
    lon: 77.5619419,
  },
  {
    art_state_id: "AST9598A0DFEE",
    state_code: "GO",
    name: "Osh",
    country_code: "KG",
    lat: 36.0631399,
    lon: -95.9182895,
  },
  {
    art_state_id: "AST3786F2BB92",
    state_code: "C",
    name: "Chuy Region",
    country_code: "KG",
    lat: 42.5655,
    lon: 74.4056612,
  },
  {
    art_state_id: "ASTC1C616E334",
    state_code: "O",
    name: "Osh Region",
    country_code: "KG",
    lat: 39.8407366,
    lon: 72.8988069,
  },
  {
    art_state_id: "AST1407C188A5",
    state_code: "50",
    name: "Trøndelag",
    country_code: "NO",
    lat: 63.5420125,
    lon: 10.9369267,
  },
  {
    art_state_id: "AST4FC957AFAF",
    state_code: "03",
    name: "Oslo",
    country_code: "NO",
    lat: 59.9138688,
    lon: 10.7522454,
  },
  {
    art_state_id: "ASTDB585C86D0",
    state_code: "07",
    name: "Vestfold",
    country_code: "NO",
    lat: 59.1707862,
    lon: 10.1144355,
  },
  {
    art_state_id: "AST5847568BA7",
    state_code: "05",
    name: "Oppland",
    country_code: "NO",
    lat: 61.5422752,
    lon: 9.7166315,
  },
  {
    art_state_id: "AST468445ABF1",
    state_code: "16",
    name: "Sør-Trøndelag",
    country_code: "NO",
    lat: 63.0136823,
    lon: 10.3487136,
  },
  {
    art_state_id: "AST8E97989A15",
    state_code: "06",
    name: "Buskerud",
    country_code: "NO",
    lat: 60.4846025,
    lon: 8.6983764,
  },
  {
    art_state_id: "AST0555F5D1FE",
    state_code: "17",
    name: "Nord-Trøndelag",
    country_code: "NO",
    lat: 64.4370792,
    lon: 11.746295,
  },
  {
    art_state_id: "AST7F79ABE299",
    state_code: "21",
    name: "Svalbard",
    country_code: "NO",
    lat: 77.8749725,
    lon: 20.9751821,
  },
  {
    art_state_id: "AST1688231824",
    state_code: "10",
    name: "Vest-Agder",
    country_code: "NO",
    lat: 58.0999081,
    lon: 6.5869809,
  },
  {
    art_state_id: "AST31A22E4CB8",
    state_code: "19",
    name: "Troms",
    country_code: "NO",
    lat: 69.8178242,
    lon: 18.7819365,
  },
  {
    art_state_id: "AST50DFE13C6D",
    state_code: "20",
    name: "Finnmark",
    country_code: "NO",
    lat: 70.4830388,
    lon: 26.0135107,
  },
  {
    art_state_id: "AST9773467359",
    state_code: "02",
    name: "Akershus",
    country_code: "NO",
    lat: 28.3704203,
    lon: -81.5468058,
  },
  {
    art_state_id: "ASTDD7C1E8B58",
    state_code: "14",
    name: "Sogn og Fjordane",
    country_code: "NO",
    lat: 61.5539435,
    lon: 6.3325879,
  },
  {
    art_state_id: "ASTBCF1029273",
    state_code: "04",
    name: "Hedmark",
    country_code: "NO",
    lat: 61.3967311,
    lon: 11.5627369,
  },
  {
    art_state_id: "AST6C31983E58",
    state_code: "15",
    name: "Møre og Romsdal",
    country_code: "NO",
    lat: 62.8406833,
    lon: 7.007143,
  },
  {
    art_state_id: "AST1E92F1FAFA",
    state_code: "11",
    name: "Rogaland",
    country_code: "NO",
    lat: 59.1489544,
    lon: 6.0143432,
  },
  {
    art_state_id: "ASTC391AACF58",
    state_code: "01",
    name: "Østfold",
    country_code: "NO",
    lat: 59.2558286,
    lon: 11.3279006,
  },
  {
    art_state_id: "ASTBE88214023",
    state_code: "12",
    name: "Hordaland",
    country_code: "NO",
    lat: 60.2733674,
    lon: 5.7220194,
  },
  {
    art_state_id: "ASTDFBDA9B988",
    state_code: "08",
    name: "Telemark",
    country_code: "NO",
    lat: 59.3913985,
    lon: 8.3211209,
  },
  {
    art_state_id: "ASTAACA78B71C",
    state_code: "18",
    name: "Nordland",
    country_code: "NO",
    lat: 67.693058,
    lon: 12.7073936,
  },
  {
    art_state_id: "AST3AFDDBFEB1",
    state_code: "22",
    name: "Jan Mayen",
    country_code: "NO",
    lat: 71.031818,
    lon: -8.2920346,
  },
  {
    art_state_id: "AST8BD67F26E7",
    state_code: "HV",
    name: "Hódmezővásárhely",
    country_code: "HU",
    lat: 46.4181262,
    lon: 20.3300315,
  },
  {
    art_state_id: "AST1CA0C4D9BA",
    state_code: "ER",
    name: "Érd",
    country_code: "HU",
    lat: 47.3919718,
    lon: 18.904544,
  },
  {
    art_state_id: "ASTA72E854382",
    state_code: "SD",
    name: "Szeged",
    country_code: "HU",
    lat: 46.2530102,
    lon: 20.1414253,
  },
  {
    art_state_id: "ASTCA6BBA5636",
    state_code: "NK",
    name: "Nagykanizsa",
    country_code: "HU",
    lat: 46.4590218,
    lon: 16.9896796,
  },
  {
    art_state_id: "AST819B787532",
    state_code: "CS",
    name: "Csongrád County",
    country_code: "HU",
    lat: 46.416705,
    lon: 20.2566161,
  },
  {
    art_state_id: "AST540C45BB25",
    state_code: "DE",
    name: "Debrecen",
    country_code: "HU",
    lat: 47.5316049,
    lon: 21.6273124,
  },
  {
    art_state_id: "ASTAC6869B420",
    state_code: "SF",
    name: "Székesfehérvár",
    country_code: "HU",
    lat: 47.1860262,
    lon: 18.4221358,
  },
  {
    art_state_id: "AST8CA2281D08",
    state_code: "NY",
    name: "Nyíregyháza",
    country_code: "HU",
    lat: 47.9495324,
    lon: 21.7244053,
  },
  {
    art_state_id: "AST53F9CE9014",
    state_code: "SO",
    name: "Somogy County",
    country_code: "HU",
    lat: 46.554859,
    lon: 17.5866732,
  },
  {
    art_state_id: "AST433B5E800F",
    state_code: "BC",
    name: "Békéscsaba",
    country_code: "HU",
    lat: 46.6735939,
    lon: 21.0877309,
  },
  {
    art_state_id: "ASTF3A0C65E22",
    state_code: "EG",
    name: "Eger",
    country_code: "HU",
    lat: 47.9025348,
    lon: 20.3772284,
  },
  {
    art_state_id: "AST9F4A8E6D6B",
    state_code: "TO",
    name: "Tolna County",
    country_code: "HU",
    lat: 46.4762754,
    lon: 18.5570627,
  },
  {
    art_state_id: "AST9A87B5E39D",
    state_code: "VA",
    name: "Vas County",
    country_code: "HU",
    lat: 47.0929111,
    lon: 16.6812183,
  },
  {
    art_state_id: "AST068262619C",
    state_code: "HE",
    name: "Heves County",
    country_code: "HU",
    lat: 47.8057617,
    lon: 20.2038559,
  },
  {
    art_state_id: "AST8692B52C67",
    state_code: "GY",
    name: "Győr",
    country_code: "HU",
    lat: 47.6874569,
    lon: 17.6503974,
  },
  {
    art_state_id: "AST0EA7FD442A",
    state_code: "GS",
    name: "Győr-Moson-Sopron County",
    country_code: "HU",
    lat: 47.6509285,
    lon: 17.2505883,
  },
  {
    art_state_id: "AST4369304D8B",
    state_code: "JN",
    name: "Jász-Nagykun-Szolnok County",
    country_code: "HU",
    lat: 47.2555579,
    lon: 20.5232456,
  },
  {
    art_state_id: "ASTAC42E4D19D",
    state_code: "FE",
    name: "Fejér County",
    country_code: "HU",
    lat: 47.1217932,
    lon: 18.5294815,
  },
  {
    art_state_id: "ASTFEB1E3260C",
    state_code: "SZ",
    name: "Szabolcs-Szatmár-Bereg County",
    country_code: "HU",
    lat: 48.0394954,
    lon: 22.00333,
  },
  {
    art_state_id: "AST6E8A53C440",
    state_code: "ZA",
    name: "Zala County",
    country_code: "HU",
    lat: 46.7384404,
    lon: 16.9152252,
  },
  {
    art_state_id: "AST68BF1EDB39",
    state_code: "SK",
    name: "Szolnok",
    country_code: "HU",
    lat: 47.1621355,
    lon: 20.1824712,
  },
  {
    art_state_id: "AST055D71E311",
    state_code: "BK",
    name: "Bács-Kiskun County",
    country_code: "HU",
    lat: 46.5661437,
    lon: 19.4272464,
  },
  {
    art_state_id: "ASTE65DEE3215",
    state_code: "DU",
    name: "Dunaújváros",
    country_code: "HU",
    lat: 46.9619059,
    lon: 18.9355227,
  },
  {
    art_state_id: "AST04151F1DD4",
    state_code: "ZE",
    name: "Zalaegerszeg",
    country_code: "HU",
    lat: 46.8416936,
    lon: 16.8416322,
  },
  {
    art_state_id: "ASTAFFFE1D533",
    state_code: "NO",
    name: "Nógrád County",
    country_code: "HU",
    lat: 47.9041031,
    lon: 19.0498504,
  },
  {
    art_state_id: "AST83254619BE",
    state_code: "SH",
    name: "Szombathely",
    country_code: "HU",
    lat: 47.2306851,
    lon: 16.6218441,
  },
  {
    art_state_id: "AST9F73272BE5",
    state_code: "PS",
    name: "Pécs",
    country_code: "HU",
    lat: 46.0727345,
    lon: 18.232266,
  },
  {
    art_state_id: "AST9B59C81363",
    state_code: "VE",
    name: "Veszprém County",
    country_code: "HU",
    lat: 47.0930974,
    lon: 17.9100763,
  },
  {
    art_state_id: "AST4E6C7923EF",
    state_code: "BA",
    name: "Baranya County",
    country_code: "HU",
    lat: 46.0484585,
    lon: 18.2719173,
  },
  {
    art_state_id: "AST55CDA4686E",
    state_code: "KM",
    name: "Kecskemét",
    country_code: "HU",
    lat: 46.8963711,
    lon: 19.6896861,
  },
  {
    art_state_id: "AST683BA46E9B",
    state_code: "SN",
    name: "Sopron",
    country_code: "HU",
    lat: 47.6816619,
    lon: 16.5844795,
  },
  {
    art_state_id: "ASTFDE42121C7",
    state_code: "BZ",
    name: "Borsod-Abaúj-Zemplén County",
    country_code: "HU",
    lat: 48.2939401,
    lon: 20.6934112,
  },
  {
    art_state_id: "AST59830FF11F",
    state_code: "PE",
    name: "Pest County",
    country_code: "HU",
    lat: 47.4480001,
    lon: 19.4618128,
  },
  {
    art_state_id: "AST61C3220754",
    state_code: "BE",
    name: "Békés County",
    country_code: "HU",
    lat: 46.6704899,
    lon: 21.0434996,
  },
  {
    art_state_id: "AST998D99CAD8",
    state_code: "SS",
    name: "Szekszárd",
    country_code: "HU",
    lat: 46.3474326,
    lon: 18.7062293,
  },
  {
    art_state_id: "AST96A92DCCE3",
    state_code: "VM",
    name: "Veszprém",
    country_code: "HU",
    lat: 47.1028087,
    lon: 17.9093019,
  },
  {
    art_state_id: "AST7C7C329245",
    state_code: "HB",
    name: "Hajdú-Bihar County",
    country_code: "HU",
    lat: 47.4688355,
    lon: 21.5453227,
  },
  {
    art_state_id: "ASTF7668D4ED6",
    state_code: "BU",
    name: "Budapest",
    country_code: "HU",
    lat: 47.497912,
    lon: 19.040235,
  },
  {
    art_state_id: "AST7E6E1DA7EB",
    state_code: "MI",
    name: "Miskolc",
    country_code: "HU",
    lat: 48.1034775,
    lon: 20.7784384,
  },
  {
    art_state_id: "ASTC9B9DA9CE5",
    state_code: "TB",
    name: "Tatabánya",
    country_code: "HU",
    lat: 47.569246,
    lon: 18.404818,
  },
  {
    art_state_id: "ASTEB2EAA0498",
    state_code: "KV",
    name: "Kaposvár",
    country_code: "HU",
    lat: 46.3593606,
    lon: 17.7967639,
  },
  {
    art_state_id: "ASTB8926221BD",
    state_code: "ST",
    name: "Salgótarján",
    country_code: "HU",
    lat: 48.0935237,
    lon: 19.7999813,
  },
  {
    art_state_id: "AST1F551199F0",
    state_code: "TA",
    name: "County Tipperary",
    country_code: "IE",
    lat: 52.4737894,
    lon: -8.1618514,
  },
  {
    art_state_id: "AST3DB90AB5EB",
    state_code: "SO",
    name: "County Sligo",
    country_code: "IE",
    lat: 54.1553277,
    lon: -8.6064532,
  },
  {
    art_state_id: "ASTCC5A3774BB",
    state_code: "DL",
    name: "County Donegal",
    country_code: "IE",
    lat: 54.6548993,
    lon: -8.1040967,
  },
  {
    art_state_id: "AST6C452B83D2",
    state_code: "D",
    name: "County Dublin",
    country_code: "IE",
    lat: 53.3498053,
    lon: -6.2603097,
  },
  {
    art_state_id: "AST30E05F0D4E",
    state_code: "L",
    name: "Leinster",
    country_code: "IE",
    lat: 53.3271538,
    lon: -7.5140841,
  },
  {
    art_state_id: "AST0BDCBE8A77",
    state_code: "CO",
    name: "County Cork",
    country_code: "IE",
    lat: 51.8985143,
    lon: -8.4756035,
  },
  {
    art_state_id: "ASTB8617B7457",
    state_code: "MN",
    name: "County Monaghan",
    country_code: "IE",
    lat: 54.2492046,
    lon: -6.9683132,
  },
  {
    art_state_id: "AST5295074A0D",
    state_code: "LD",
    name: "County Longford",
    country_code: "IE",
    lat: 53.7274982,
    lon: -7.7931527,
  },
  {
    art_state_id: "AST08615C4678",
    state_code: "KY",
    name: "County Kerry",
    country_code: "IE",
    lat: 52.1544607,
    lon: -9.5668633,
  },
  {
    art_state_id: "ASTD39D526BE2",
    state_code: "OY",
    name: "County Offaly",
    country_code: "IE",
    lat: 53.2356871,
    lon: -7.7122229,
  },
  {
    art_state_id: "AST2E98A6FADB",
    state_code: "G",
    name: "County Galway",
    country_code: "IE",
    lat: 53.3564509,
    lon: -8.8534113,
  },
  {
    art_state_id: "AST442698AED1",
    state_code: "M",
    name: "Munster",
    country_code: "IE",
    lat: 51.9471197,
    lon: 7.584532,
  },
  {
    art_state_id: "AST8387DC39E2",
    state_code: "RN",
    name: "County Roscommon",
    country_code: "IE",
    lat: 53.7592604,
    lon: -8.2681621,
  },
  {
    art_state_id: "AST028F01D1C8",
    state_code: "KE",
    name: "County Kildare",
    country_code: "IE",
    lat: 53.2120434,
    lon: -6.8194708,
  },
  {
    art_state_id: "ASTFA51EB4D45",
    state_code: "LH",
    name: "County Louth",
    country_code: "IE",
    lat: 53.9252324,
    lon: -6.4889423,
  },
  {
    art_state_id: "AST7F10B74529",
    state_code: "MO",
    name: "County Mayo",
    country_code: "IE",
    lat: 54.0152604,
    lon: -9.4289369,
  },
  {
    art_state_id: "AST734F158B2E",
    state_code: "WW",
    name: "County Wicklow",
    country_code: "IE",
    lat: 52.9862313,
    lon: -6.3672543,
  },
  {
    art_state_id: "AST1EAA67B6C9",
    state_code: "U",
    name: "Ulster",
    country_code: "IE",
    lat: 54.7616555,
    lon: -6.9612273,
  },
  {
    art_state_id: "ASTDD41B57442",
    state_code: "C",
    name: "Connacht",
    country_code: "IE",
    lat: 53.8376243,
    lon: -8.9584481,
  },
  {
    art_state_id: "ASTC40E8C215C",
    state_code: "CN",
    name: "County Cavan",
    country_code: "IE",
    lat: 53.9765424,
    lon: -7.2996623,
  },
  {
    art_state_id: "AST58367E44E8",
    state_code: "WD",
    name: "County Waterford",
    country_code: "IE",
    lat: 52.1943549,
    lon: -7.6227512,
  },
  {
    art_state_id: "AST200D12F221",
    state_code: "KK",
    name: "County Kilkenny",
    country_code: "IE",
    lat: 52.5776957,
    lon: -7.218002,
  },
  {
    art_state_id: "ASTDFCA5221B9",
    state_code: "CE",
    name: "County Clare",
    country_code: "IE",
    lat: 43.04664,
    lon: -87.899581,
  },
  {
    art_state_id: "AST2BB154C74A",
    state_code: "MH",
    name: "County Meath",
    country_code: "IE",
    lat: 53.605548,
    lon: -6.6564169,
  },
  {
    art_state_id: "AST08FBCDE310",
    state_code: "WX",
    name: "County Wexford",
    country_code: "IE",
    lat: 52.4793603,
    lon: -6.5839913,
  },
  {
    art_state_id: "AST29EF2E037E",
    state_code: "LK",
    name: "County Limerick",
    country_code: "IE",
    lat: 52.5090517,
    lon: -8.7474955,
  },
  {
    art_state_id: "AST2868C1956F",
    state_code: "CW",
    name: "County Carlow",
    country_code: "IE",
    lat: 52.7232217,
    lon: -6.8108295,
  },
  {
    art_state_id: "AST468A18321C",
    state_code: "LS",
    name: "County Laois",
    country_code: "IE",
    lat: 52.994295,
    lon: -7.3323007,
  },
  {
    art_state_id: "ASTB8ABE6C60E",
    state_code: "WH",
    name: "County Westmeath",
    country_code: "IE",
    lat: 53.5345308,
    lon: -7.4653217,
  },
  {
    art_state_id: "AST4881B4C737",
    state_code: "17",
    name: "Djelfa",
    country_code: "DZ",
    lat: 34.6703956,
    lon: 3.2503761,
  },
  {
    art_state_id: "AST6CC172EB85",
    state_code: "39",
    name: "El Oued",
    country_code: "DZ",
    lat: 33.367811,
    lon: 6.8516511,
  },
  {
    art_state_id: "ASTB7E20CA4B2",
    state_code: "36",
    name: "El Tarf",
    country_code: "DZ",
    lat: 36.7576678,
    lon: 8.3076343,
  },
  {
    art_state_id: "AST1BCF7AA2C9",
    state_code: "31",
    name: "Oran",
    country_code: "DZ",
    lat: 35.6082351,
    lon: -0.563609,
  },
  {
    art_state_id: "AST24B01CB67E",
    state_code: "45",
    name: "Naama",
    country_code: "DZ",
    lat: 33.2667317,
    lon: -0.3128659,
  },
  {
    art_state_id: "ASTC2576958F4",
    state_code: "23",
    name: "Annaba",
    country_code: "DZ",
    lat: 36.8020508,
    lon: 7.5247243,
  },
  {
    art_state_id: "AST740C599D47",
    state_code: "10",
    name: "Bouïra",
    country_code: "DZ",
    lat: 36.3691846,
    lon: 3.9006194,
  },
  {
    art_state_id: "AST5E3CD5177F",
    state_code: "02",
    name: "Chlef",
    country_code: "DZ",
    lat: 36.1693515,
    lon: 1.2891036,
  },
  {
    art_state_id: "AST8D7A4343E8",
    state_code: "14",
    name: "Tiaret",
    country_code: "DZ",
    lat: 35.3708689,
    lon: 1.3217852,
  },
  {
    art_state_id: "AST5BABE79E56",
    state_code: "13",
    name: "Tlemcen",
    country_code: "DZ",
    lat: 34.6780284,
    lon: -1.366216,
  },
  {
    art_state_id: "AST959A58EA68",
    state_code: "08",
    name: "Béchar",
    country_code: "DZ",
    lat: 31.6238098,
    lon: -2.2162443,
  },
  {
    art_state_id: "ASTCF5FD115BF",
    state_code: "26",
    name: "Médéa",
    country_code: "DZ",
    lat: 36.2637078,
    lon: 2.7587857,
  },
  {
    art_state_id: "AST75632326C1",
    state_code: "21",
    name: "Skikda",
    country_code: "DZ",
    lat: 36.6721198,
    lon: 6.8350999,
  },
  {
    art_state_id: "AST7795468CCA",
    state_code: "09",
    name: "Blida",
    country_code: "DZ",
    lat: 36.531123,
    lon: 2.8976254,
  },
  {
    art_state_id: "AST54F661059C",
    state_code: "33",
    name: "Illizi",
    country_code: "DZ",
    lat: 26.1690005,
    lon: 8.4842465,
  },
  {
    art_state_id: "ASTCC8B3889D9",
    state_code: "18",
    name: "Jijel",
    country_code: "DZ",
    lat: 36.7179681,
    lon: 5.9832577,
  },
  {
    art_state_id: "AST5A1FF8C4D0",
    state_code: "07",
    name: "Biskra",
    country_code: "DZ",
    lat: 34.8449437,
    lon: 5.7248567,
  },
  {
    art_state_id: "AST6770938B41",
    state_code: "42",
    name: "Tipasa",
    country_code: "DZ",
    lat: 36.546265,
    lon: 2.1843285,
  },
  {
    art_state_id: "AST854BE9EE5D",
    state_code: "34",
    name: "Bordj Bou Arréridj",
    country_code: "DZ",
    lat: 36.0739925,
    lon: 4.7630271,
  },
  {
    art_state_id: "ASTFF9B1D93A6",
    state_code: "12",
    name: "Tébessa",
    country_code: "DZ",
    lat: 35.1290691,
    lon: 7.9592863,
  },
  {
    art_state_id: "ASTF7F5CE905C",
    state_code: "01",
    name: "Adrar",
    country_code: "DZ",
    lat: 26.418131,
    lon: -0.6014717,
  },
  {
    art_state_id: "AST2030D8936C",
    state_code: "44",
    name: "Aïn Defla",
    country_code: "DZ",
    lat: 36.2509429,
    lon: 1.9393815,
  },
  {
    art_state_id: "AST3B177C8B2E",
    state_code: "37",
    name: "Tindouf",
    country_code: "DZ",
    lat: 27.8063119,
    lon: -5.7299821,
  },
  {
    art_state_id: "ASTF6F31DBA1B",
    state_code: "25",
    name: "Constantine",
    country_code: "DZ",
    lat: 36.3373911,
    lon: 6.663812,
  },
  {
    art_state_id: "AST8F991BF234",
    state_code: "46",
    name: "Aïn Témouchent",
    country_code: "DZ",
    lat: 35.2992698,
    lon: -1.1392792,
  },
  {
    art_state_id: "AST5CC7CFA0C0",
    state_code: "20",
    name: "Saïda",
    country_code: "DZ",
    lat: 34.8415207,
    lon: 0.1456055,
  },
  {
    art_state_id: "ASTEA2B64854E",
    state_code: "29",
    name: "Mascara",
    country_code: "DZ",
    lat: 35.3904125,
    lon: 0.1494988,
  },
  {
    art_state_id: "AST607E915D4D",
    state_code: "35",
    name: "Boumerdès",
    country_code: "DZ",
    lat: 36.6839559,
    lon: 3.6217802,
  },
  {
    art_state_id: "AST50AD82E2C4",
    state_code: "40",
    name: "Khenchela",
    country_code: "DZ",
    lat: 35.4269404,
    lon: 7.1460155,
  },
  {
    art_state_id: "ASTFB49AD88CA",
    state_code: "47",
    name: "Ghardaïa",
    country_code: "DZ",
    lat: 32.4943741,
    lon: 3.64446,
  },
  {
    art_state_id: "AST3F0A733F4F",
    state_code: "06",
    name: "Béjaïa",
    country_code: "DZ",
    lat: 36.7515258,
    lon: 5.0556837,
  },
  {
    art_state_id: "ASTC2196BC9C1",
    state_code: "32",
    name: "El Bayadh",
    country_code: "DZ",
    lat: 32.7148824,
    lon: 0.9056623,
  },
  {
    art_state_id: "ASTE06ED624FE",
    state_code: "48",
    name: "Relizane",
    country_code: "DZ",
    lat: 35.7383405,
    lon: 0.7532809,
  },
  {
    art_state_id: "ASTA09148EAAF",
    state_code: "15",
    name: "Tizi Ouzou",
    country_code: "DZ",
    lat: 36.706911,
    lon: 4.2333355,
  },
  {
    art_state_id: "AST203C46EDD0",
    state_code: "43",
    name: "Mila",
    country_code: "DZ",
    lat: 36.3647957,
    lon: 6.1526985,
  },
  {
    art_state_id: "AST79E45DF412",
    state_code: "38",
    name: "Tissemsilt",
    country_code: "DZ",
    lat: 35.6053781,
    lon: 1.813098,
  },
  {
    art_state_id: "AST88E6A6EEAC",
    state_code: "28",
    name: "M'Sila",
    country_code: "DZ",
    lat: 35.7186646,
    lon: 4.5233423,
  },
  {
    art_state_id: "ASTE3FB7817B5",
    state_code: "11",
    name: "Tamanghasset",
    country_code: "DZ",
    lat: 22.7902972,
    lon: 5.5193268,
  },
  {
    art_state_id: "AST6C7B024C28",
    state_code: "04",
    name: "Oum El Bouaghi",
    country_code: "DZ",
    lat: 35.8688789,
    lon: 7.1108266,
  },
  {
    art_state_id: "AST3EA58FC248",
    state_code: "24",
    name: "Guelma",
    country_code: "DZ",
    lat: 36.4627444,
    lon: 7.4330833,
  },
  {
    art_state_id: "AST05CA376F5A",
    state_code: "03",
    name: "Laghouat",
    country_code: "DZ",
    lat: 33.8078341,
    lon: 2.8628294,
  },
  {
    art_state_id: "ASTA51475CAE7",
    state_code: "30",
    name: "Ouargla",
    country_code: "DZ",
    lat: 32.2264863,
    lon: 5.7299821,
  },
  {
    art_state_id: "ASTB82581650B",
    state_code: "27",
    name: "Mostaganem",
    country_code: "DZ",
    lat: 35.9583054,
    lon: 0.3371889,
  },
  {
    art_state_id: "ASTFF5B717F6A",
    state_code: "19",
    name: "Sétif",
    country_code: "DZ",
    lat: 36.3073389,
    lon: 5.5617279,
  },
  {
    art_state_id: "AST5306356935",
    state_code: "05",
    name: "Batna",
    country_code: "DZ",
    lat: 35.5965954,
    lon: 5.8987139,
  },
  {
    art_state_id: "AST7616C04907",
    state_code: "41",
    name: "Souk Ahras",
    country_code: "DZ",
    lat: 36.2801062,
    lon: 7.9384033,
  },
  {
    art_state_id: "AST23A28FAFB1",
    state_code: "16",
    name: "Algiers",
    country_code: "DZ",
    lat: 36.6997294,
    lon: 3.0576199,
  },
  {
    art_state_id: "ASTD6B159872A",
    state_code: "BU",
    name: "Burgos Province",
    country_code: "ES",
    lat: 42.3380758,
    lon: -3.5812692,
  },
  {
    art_state_id: "AST321E8D988A",
    state_code: "SA",
    name: "Salamanca Province",
    country_code: "ES",
    lat: 40.9515263,
    lon: -6.2375947,
  },
  {
    art_state_id: "AST79837DAF27",
    state_code: "P",
    name: "Palencia Province",
    country_code: "ES",
    lat: 42.0096832,
    lon: -4.5287949,
  },
  {
    art_state_id: "AST7D04CFCABE",
    state_code: "MD",
    name: "Madrid",
    country_code: "ES",
    lat: 40.4167515,
    lon: -3.7038322,
  },
  {
    art_state_id: "ASTBD9E88AED3",
    state_code: "ML",
    name: "Melilla",
    country_code: "ES",
    lat: 35.2922775,
    lon: -2.9380973,
  },
  {
    art_state_id: "ASTA05EC92109",
    state_code: "AS",
    name: "Asturias",
    country_code: "ES",
    lat: 43.3613953,
    lon: -5.8593267,
  },
  {
    art_state_id: "ASTFF4C7FFD6D",
    state_code: "ZA",
    name: "Zamora Province",
    country_code: "ES",
    lat: 41.6095744,
    lon: -5.8987139,
  },
  {
    art_state_id: "AST792C2BEAC0",
    state_code: "GA",
    name: "Galicia",
    country_code: "ES",
    lat: 42.5750554,
    lon: -8.1338558,
  },
  {
    art_state_id: "AST1C530B04EE",
    state_code: "CB",
    name: "Cantabria",
    country_code: "ES",
    lat: 43.1828396,
    lon: -3.9878427,
  },
  {
    art_state_id: "AST98C5C34BC3",
    state_code: "RI",
    name: "La Rioja",
    country_code: "ES",
    lat: 42.2870733,
    lon: -2.539603,
  },
  {
    art_state_id: "AST8EA709E631",
    state_code: "PM",
    name: "Balearic Islands",
    country_code: "ES",
    lat: 39.3587759,
    lon: 2.7356328,
  },
  {
    art_state_id: "AST68E5871E90",
    state_code: "VC",
    name: "Valencia",
    country_code: "ES",
    lat: 39.4840108,
    lon: -0.7532809,
  },
  {
    art_state_id: "AST95F1F3AF44",
    state_code: "MC",
    name: "Murcia",
    country_code: "ES",
    lat: 38.1398141,
    lon: -1.366216,
  },
  {
    art_state_id: "ASTD7C94666E2",
    state_code: "AR",
    name: "Aragon",
    country_code: "ES",
    lat: 41.5976275,
    lon: -0.9056623,
  },
  {
    art_state_id: "ASTDF41413BD7",
    state_code: "VA",
    name: "Valladolid Province",
    country_code: "ES",
    lat: 41.6517375,
    lon: -4.724495,
  },
  {
    art_state_id: "AST9A8420F03F",
    state_code: "CL",
    name: "Castile and León",
    country_code: "ES",
    lat: 41.8356821,
    lon: -4.3976357,
  },
  {
    art_state_id: "AST84199D7DBF",
    state_code: "CN",
    name: "Canary Islands",
    country_code: "ES",
    lat: 28.2915637,
    lon: -16.6291304,
  },
  {
    art_state_id: "ASTA2F916CAF2",
    state_code: "AV",
    name: "Ávila",
    country_code: "ES",
    lat: 40.6934511,
    lon: -4.8935627,
  },
  {
    art_state_id: "AST1ACA105783",
    state_code: "EX",
    name: "Extremadura",
    country_code: "ES",
    lat: 39.4937392,
    lon: -6.0679194,
  },
  {
    art_state_id: "AST25E383A095",
    state_code: "PV",
    name: "Basque Country",
    country_code: "ES",
    lat: 42.9896248,
    lon: -2.6189273,
  },
  {
    art_state_id: "ASTE0CABCE847",
    state_code: "SG",
    name: "Segovia Province",
    country_code: "ES",
    lat: 40.9429296,
    lon: -4.1088942,
  },
  {
    art_state_id: "AST5383DC69B0",
    state_code: "AN",
    name: "Andalusia",
    country_code: "ES",
    lat: 37.5442706,
    lon: -4.7277528,
  },
  {
    art_state_id: "ASTAE826FC6A1",
    state_code: "LE",
    name: "Léon",
    country_code: "ES",
    lat: 42.5987041,
    lon: -5.5670839,
  },
  {
    art_state_id: "AST7AF908FA8B",
    state_code: "CT",
    name: "Catalonia",
    country_code: "ES",
    lat: 41.5911589,
    lon: 1.5208624,
  },
  {
    art_state_id: "ASTA82B2B6045",
    state_code: "NC",
    name: "Navarra",
    country_code: "ES",
    lat: 42.6953909,
    lon: -1.6760691,
  },
  {
    art_state_id: "ASTC16952C13D",
    state_code: "CM",
    name: "Castilla La Mancha",
    country_code: "ES",
    lat: 39.2795607,
    lon: -3.097702,
  },
  {
    art_state_id: "AST5158699952",
    state_code: "CE",
    name: "Ceuta",
    country_code: "ES",
    lat: 35.8893874,
    lon: -5.3213455,
  },
  {
    art_state_id: "ASTB73D7A4DEC",
    state_code: "SO",
    name: "Soria Province",
    country_code: "ES",
    lat: 41.7665464,
    lon: -2.4790306,
  },
  {
    art_state_id: "ASTE2AF6D6085",
    state_code: "G",
    name: "Guanacaste Province",
    country_code: "CR",
    lat: 10.6267399,
    lon: -85.4436706,
  },
  {
    art_state_id: "ASTFF158757C8",
    state_code: "P",
    name: "Puntarenas Province",
    country_code: "CR",
    lat: 9.2169531,
    lon: -83.336188,
  },
  {
    art_state_id: "ASTFECB688A85",
    state_code: "C",
    name: "Provincia de Cartago",
    country_code: "CR",
    lat: 9.8622311,
    lon: -83.9214187,
  },
  {
    art_state_id: "AST6895E59A01",
    state_code: "H",
    name: "Heredia Province",
    country_code: "CR",
    lat: 10.473523,
    lon: -84.0167423,
  },
  {
    art_state_id: "ASTFB18F2D5BF",
    state_code: "L",
    name: "Limón Province",
    country_code: "CR",
    lat: 9.9896398,
    lon: -83.0332417,
  },
  {
    art_state_id: "AST14CE09471B",
    state_code: "SJ",
    name: "San José Province",
    country_code: "CR",
    lat: 9.9129727,
    lon: -84.0768294,
  },
  {
    art_state_id: "ASTCBEA359E65",
    state_code: "A",
    name: "Alajuela Province",
    country_code: "CR",
    lat: 10.391583,
    lon: -84.4382721,
  },
  {
    art_state_id: "AST5AC8B139FF",
    state_code: "BM",
    name: "Brunei-Muara District",
    country_code: "BN",
    lat: 4.9311206,
    lon: 114.9516869,
  },
  {
    art_state_id: "AST97DC0ECC8E",
    state_code: "BE",
    name: "Belait District",
    country_code: "BN",
    lat: 4.3750749,
    lon: 114.6192899,
  },
  {
    art_state_id: "ASTB8232B43DE",
    state_code: "TE",
    name: "Temburong District",
    country_code: "BN",
    lat: 4.6204128,
    lon: 115.141484,
  },
  {
    art_state_id: "AST871B74EC80",
    state_code: "TU",
    name: "Tutong District",
    country_code: "BN",
    lat: 4.7140373,
    lon: 114.6667939,
  },
  {
    art_state_id: "AST78E392FC68",
    state_code: "10",
    name: "Saint Philip",
    country_code: "BB",
    lat: 35.233114,
    lon: -89.4364042,
  },
  {
    art_state_id: "ASTF54314C7F2",
    state_code: "07",
    name: "Saint Lucy",
    country_code: "BB",
    lat: 38.7614625,
    lon: -77.4491439,
  },
  {
    art_state_id: "ASTFAB3D31DC9",
    state_code: "09",
    name: "Saint Peter",
    country_code: "BB",
    lat: 37.0827119,
    lon: -94.517125,
  },
  {
    art_state_id: "AST635F6CE142",
    state_code: "06",
    name: "Saint Joseph",
    country_code: "BB",
    lat: 39.7674578,
    lon: -94.846681,
  },
  {
    art_state_id: "ASTAA12530A81",
    state_code: "04",
    name: "Saint James",
    country_code: "BB",
    lat: 48.523566,
    lon: -1.3237885,
  },
  {
    art_state_id: "ASTDDE205FC06",
    state_code: "11",
    name: "Saint Thomas",
    country_code: "BB",
    lat: 18.3380965,
    lon: -64.8940946,
  },
  {
    art_state_id: "AST048DC8A6F9",
    state_code: "03",
    name: "Saint George",
    country_code: "BB",
    lat: 37.0965278,
    lon: -113.5684164,
  },
  {
    art_state_id: "AST1FBBB697F9",
    state_code: "05",
    name: "Saint John",
    country_code: "BB",
    lat: 45.2733153,
    lon: -66.063308,
  },
  {
    art_state_id: "AST92284EBF5D",
    state_code: "01",
    name: "Christ Church",
    country_code: "BB",
    lat: 36.0060407,
    lon: -95.921121,
  },
  {
    art_state_id: "AST635A5984C2",
    state_code: "02",
    name: "Saint Andrew",
    country_code: "BB",
    lat: 13.1500331,
    lon: -59.5250305,
  },
  {
    art_state_id: "AST42A7C11B8A",
    state_code: "08",
    name: "Saint Michael",
    country_code: "BB",
    lat: 36.035977,
    lon: -95.849052,
  },
  {
    art_state_id: "AST0F2DCB1660",
    state_code: "TA",
    name: "Ta'izz Governorate",
    country_code: "YE",
    lat: 13.5775886,
    lon: 44.0177989,
  },
  {
    art_state_id: "AST0DFB33FB1D",
    state_code: "SA",
    name: "Sana'a",
    country_code: "YE",
    lat: 15.3694451,
    lon: 44.1910066,
  },
  {
    art_state_id: "ASTAD0F6D7EB8",
    state_code: "IB",
    name: "Ibb Governorate",
    country_code: "YE",
    lat: 14.1415717,
    lon: 44.2479015,
  },
  {
    art_state_id: "AST55217B3671",
    state_code: "MA",
    name: "Ma'rib Governorate",
    country_code: "YE",
    lat: 15.515888,
    lon: 45.4498065,
  },
  {
    art_state_id: "ASTD0E7CAF5AE",
    state_code: "MW",
    name: "Al Mahwit Governorate",
    country_code: "YE",
    lat: 15.3963229,
    lon: 43.5606946,
  },
  {
    art_state_id: "AST7C88E827E8",
    state_code: "SN",
    name: "Sana'a Governorate",
    country_code: "YE",
    lat: 15.3168913,
    lon: 44.4748018,
  },
  {
    art_state_id: "AST7BA07EC654",
    state_code: "AB",
    name: "Abyan Governorate",
    country_code: "YE",
    lat: 13.6343413,
    lon: 46.0563212,
  },
  {
    art_state_id: "ASTC2D22951AA",
    state_code: "HD",
    name: "Hadhramaut Governorate",
    country_code: "YE",
    lat: 16.9304135,
    lon: 49.3653149,
  },
  {
    art_state_id: "ASTAB9A62D29C",
    state_code: "SU",
    name: "Socotra Governorate",
    country_code: "YE",
    lat: 12.4634205,
    lon: 53.8237385,
  },
  {
    art_state_id: "ASTF7FCAAF7E0",
    state_code: "BA",
    name: "Al Bayda' Governorate",
    country_code: "YE",
    lat: 14.3588662,
    lon: 45.4498065,
  },
  {
    art_state_id: "AST5E7531E119",
    state_code: "HU",
    name: "Al Hudaydah Governorate",
    country_code: "YE",
    lat: 15.3053072,
    lon: 43.0194897,
  },
  {
    art_state_id: "AST7EF7DB43B0",
    state_code: "AD",
    name: "'Adan Governorate",
    country_code: "YE",
    lat: 12.8257481,
    lon: 44.7943804,
  },
  {
    art_state_id: "AST327EB98465",
    state_code: "JA",
    name: "Al Jawf Governorate",
    country_code: "YE",
    lat: 16.7901819,
    lon: 45.2993862,
  },
  {
    art_state_id: "ASTB61085AD8B",
    state_code: "HJ",
    name: "Hajjah Governorate",
    country_code: "YE",
    lat: 16.1180631,
    lon: 43.329466,
  },
  {
    art_state_id: "AST85A5674766",
    state_code: "LA",
    name: "Lahij Governorate",
    country_code: "YE",
    lat: 13.1489588,
    lon: 44.8505495,
  },
  {
    art_state_id: "AST073C456BAB",
    state_code: "DH",
    name: "Dhamar Governorate",
    country_code: "YE",
    lat: 14.7195344,
    lon: 44.2479015,
  },
  {
    art_state_id: "AST4C32472A04",
    state_code: "SH",
    name: "Shabwah Governorate",
    country_code: "YE",
    lat: 14.7546303,
    lon: 46.516262,
  },
  {
    art_state_id: "ASTBD18B5305A",
    state_code: "RA",
    name: "Raymah Governorate",
    country_code: "YE",
    lat: 14.6277682,
    lon: 43.7142484,
  },
  {
    art_state_id: "AST3C4EE8723C",
    state_code: "SD",
    name: "Saada Governorate",
    country_code: "YE",
    lat: 16.8476528,
    lon: 43.9436788,
  },
  {
    art_state_id: "AST92EEBD6329",
    state_code: "AM",
    name: "'Amran Governorate",
    country_code: "YE",
    lat: 16.2569214,
    lon: 43.9436788,
  },
  {
    art_state_id: "AST67B798F54E",
    state_code: "MR",
    name: "Al Mahrah Governorate",
    country_code: "YE",
    lat: 16.5238423,
    lon: 51.6834275,
  },
  {
    art_state_id: "ASTBA4F887948",
    state_code: "SE",
    name: "Sangha-Mbaéré",
    country_code: "CF",
    lat: 3.4368607,
    lon: 16.3463791,
  },
  {
    art_state_id: "ASTA1D481F424",
    state_code: "KB",
    name: "Nana-Grébizi Economic Prefecture",
    country_code: "CF",
    lat: 7.1848607,
    lon: 19.3783206,
  },
  {
    art_state_id: "ASTF7C12BD1A5",
    state_code: "AC",
    name: "Ouham Prefecture",
    country_code: "CF",
    lat: 7.090911,
    lon: 17.668887,
  },
  {
    art_state_id: "ASTCAA13137F5",
    state_code: "MP",
    name: "Ombella-M'Poko Prefecture",
    country_code: "CF",
    lat: 5.1188825,
    lon: 18.4276047,
  },
  {
    art_state_id: "AST955E5D564F",
    state_code: "LB",
    name: "Lobaye Prefecture",
    country_code: "CF",
    lat: 4.3525981,
    lon: 17.4795173,
  },
  {
    art_state_id: "ASTBB4C79EAFA",
    state_code: "HS",
    name: "Mambéré-Kadéï",
    country_code: "CF",
    lat: 4.7055653,
    lon: 15.9699878,
  },
  {
    art_state_id: "AST922A7CC97D",
    state_code: "HM",
    name: "Haut-Mbomou Prefecture",
    country_code: "CF",
    lat: 6.2537134,
    lon: 25.4733554,
  },
  {
    art_state_id: "ASTA9EDBA2989",
    state_code: "BB",
    name: "Bamingui-Bangoran Prefecture",
    country_code: "CF",
    lat: 8.2733455,
    lon: 20.7122465,
  },
  {
    art_state_id: "AST0D80366869",
    state_code: "NM",
    name: "Nana-Mambéré Prefecture",
    country_code: "CF",
    lat: 5.6932135,
    lon: 15.2194808,
  },
  {
    art_state_id: "AST4403535817",
    state_code: "VK",
    name: "Vakaga Prefecture",
    country_code: "CF",
    lat: 9.5113296,
    lon: 22.2384017,
  },
  {
    art_state_id: "AST023DC8F8FC",
    state_code: "BGF",
    name: "Bangui",
    country_code: "CF",
    lat: 4.3946735,
    lon: 18.5581899,
  },
  {
    art_state_id: "AST61C7E24FD2",
    state_code: "KG",
    name: "Kémo Prefecture",
    country_code: "CF",
    lat: 5.8867794,
    lon: 19.3783206,
  },
  {
    art_state_id: "ASTFE1BAE31B1",
    state_code: "BK",
    name: "Basse-Kotto Prefecture",
    country_code: "CF",
    lat: 4.8719319,
    lon: 21.2845025,
  },
  {
    art_state_id: "AST253C77E17F",
    state_code: "UK",
    name: "Ouaka Prefecture",
    country_code: "CF",
    lat: 6.3168216,
    lon: 20.7122465,
  },
  {
    art_state_id: "ASTFBAB6D9ACE",
    state_code: "MB",
    name: "Mbomou Prefecture",
    country_code: "CF",
    lat: 5.556837,
    lon: 23.7632828,
  },
  {
    art_state_id: "AST99D4EE31FB",
    state_code: "OP",
    name: "Ouham-Pendé Prefecture",
    country_code: "CF",
    lat: 6.4850984,
    lon: 16.1580937,
  },
  {
    art_state_id: "AST04734FDB21",
    state_code: "HK",
    name: "Haute-Kotto Prefecture",
    country_code: "CF",
    lat: 7.7964379,
    lon: 23.3823545,
  },
  {
    art_state_id: "AST1752CB57ED",
    state_code: "ROM",
    name: "Romblon",
    country_code: "PH",
    lat: 12.5778016,
    lon: 122.269146,
  },
  {
    art_state_id: "ASTA343FBFFC2",
    state_code: "BUK",
    name: "Bukidnon",
    country_code: "PH",
    lat: 8.0515054,
    lon: 124.9229946,
  },
  {
    art_state_id: "AST244D55CC44",
    state_code: "RIZ",
    name: "Rizal",
    country_code: "PH",
    lat: 14.6037446,
    lon: 121.3084088,
  },
  {
    art_state_id: "ASTBC449F752E",
    state_code: "BOH",
    name: "Bohol",
    country_code: "PH",
    lat: 9.8499911,
    lon: 124.1435427,
  },
  {
    art_state_id: "AST4E2F47161B",
    state_code: "QUI",
    name: "Quirino",
    country_code: "PH",
    lat: 16.2700424,
    lon: 121.5370003,
  },
  {
    art_state_id: "ASTBCD70AAABC",
    state_code: "BIL",
    name: "Biliran",
    country_code: "PH",
    lat: 11.5833152,
    lon: 124.4641848,
  },
  {
    art_state_id: "AST2A3AE59E64",
    state_code: "QUE",
    name: "Quezon",
    country_code: "PH",
    lat: 14.0313906,
    lon: 122.1130909,
  },
  {
    art_state_id: "AST7B797DD4AC",
    state_code: "SIG",
    name: "Siquijor",
    country_code: "PH",
    lat: 9.1998779,
    lon: 123.5951925,
  },
  {
    art_state_id: "ASTC56942F62F",
    state_code: "SAR",
    name: "Sarangani",
    country_code: "PH",
    lat: 5.9267175,
    lon: 124.994751,
  },
  {
    art_state_id: "ASTE7D67B42B6",
    state_code: "BUL",
    name: "Bulacan",
    country_code: "PH",
    lat: 14.7942735,
    lon: 120.8799008,
  },
  {
    art_state_id: "ASTB992F48BBA",
    state_code: "CAG",
    name: "Cagayan",
    country_code: "PH",
    lat: 18.2489629,
    lon: 121.8787833,
  },
  {
    art_state_id: "AST92CA5EB043",
    state_code: "SCO",
    name: "South Cotabato",
    country_code: "PH",
    lat: 6.3357565,
    lon: 124.7740793,
  },
  {
    art_state_id: "ASTA3E6607B80",
    state_code: "SOR",
    name: "Sorsogon",
    country_code: "PH",
    lat: 12.9927095,
    lon: 124.0147464,
  },
  {
    art_state_id: "ASTDE2963B139",
    state_code: "SUK",
    name: "Sultan Kudarat",
    country_code: "PH",
    lat: 6.5069401,
    lon: 124.4198243,
  },
  {
    art_state_id: "AST5754FEB70A",
    state_code: "CAN",
    name: "Camarines Norte",
    country_code: "PH",
    lat: 14.1390265,
    lon: 122.7633036,
  },
  {
    art_state_id: "ASTAB3CDA844C",
    state_code: "SLE",
    name: "Southern Leyte",
    country_code: "PH",
    lat: 10.3346206,
    lon: 125.1708741,
  },
  {
    art_state_id: "AST3D0AE8CD29",
    state_code: "CAM",
    name: "Camiguin",
    country_code: "PH",
    lat: 9.1732164,
    lon: 124.7298765,
  },
  {
    art_state_id: "AST866ABB0C30",
    state_code: "SUN",
    name: "Surigao del Norte",
    country_code: "PH",
    lat: 9.514828,
    lon: 125.6969984,
  },
  {
    art_state_id: "ASTEDCAAA63B2",
    state_code: "CAS",
    name: "Camarines Sur",
    country_code: "PH",
    lat: 13.5250197,
    lon: 123.3486147,
  },
  {
    art_state_id: "AST1A8841B667",
    state_code: "SLU",
    name: "Sulu",
    country_code: "PH",
    lat: 5.9749011,
    lon: 121.03351,
  },
  {
    art_state_id: "ASTFA43427B65",
    state_code: "DAO",
    name: "Davao Oriental",
    country_code: "PH",
    lat: 7.3171585,
    lon: 126.5419887,
  },
  {
    art_state_id: "AST1012318A78",
    state_code: "EAS",
    name: "Eastern Samar",
    country_code: "PH",
    lat: 11.5000731,
    lon: 125.4999908,
  },
  {
    art_state_id: "ASTBD05F960A5",
    state_code: "DIN",
    name: "Dinagat Islands",
    country_code: "PH",
    lat: 10.1281816,
    lon: 125.6095474,
  },
  {
    art_state_id: "AST7CF5919DB6",
    state_code: "CAP",
    name: "Capiz",
    country_code: "PH",
    lat: 11.5528816,
    lon: 122.740723,
  },
  {
    art_state_id: "AST045EBFD58B",
    state_code: "TAW",
    name: "Tawi-Tawi",
    country_code: "PH",
    lat: 5.133811,
    lon: 119.950926,
  },
  {
    art_state_id: "ASTCBDE27D61A",
    state_code: "40",
    name: "Calabarzon",
    country_code: "PH",
    lat: 14.1007803,
    lon: 121.0793705,
  },
  {
    art_state_id: "AST496FF32B5A",
    state_code: "TAR",
    name: "Tarlac",
    country_code: "PH",
    lat: 15.4754786,
    lon: 120.5963492,
  },
  {
    art_state_id: "ASTA566770B71",
    state_code: "SUR",
    name: "Surigao del Sur",
    country_code: "PH",
    lat: 8.5404906,
    lon: 126.1144758,
  },
  {
    art_state_id: "AST6B1D0B80E9",
    state_code: "ZMB",
    name: "Zambales",
    country_code: "PH",
    lat: 15.5081766,
    lon: 119.9697808,
  },
  {
    art_state_id: "AST26635E4586",
    state_code: "ILN",
    name: "Ilocos Norte",
    country_code: "PH",
    lat: 18.1647281,
    lon: 120.7115592,
  },
  {
    art_state_id: "AST928AD7D91A",
    state_code: "41",
    name: "Mimaropa",
    country_code: "PH",
    lat: 9.8432065,
    lon: 118.7364783,
  },
  {
    art_state_id: "ASTB99926503C",
    state_code: "IFU",
    name: "Ifugao",
    country_code: "PH",
    lat: 16.8330792,
    lon: 121.1710389,
  },
  {
    art_state_id: "AST31907AF65A",
    state_code: "CAT",
    name: "Catanduanes",
    country_code: "PH",
    lat: 13.7088684,
    lon: 124.2421597,
  },
  {
    art_state_id: "AST2CE1768AAB",
    state_code: "ZAN",
    name: "Zamboanga del Norte",
    country_code: "PH",
    lat: 8.3886282,
    lon: 123.1688883,
  },
  {
    art_state_id: "AST2D95BBB363",
    state_code: "GUI",
    name: "Guimaras",
    country_code: "PH",
    lat: 10.5928661,
    lon: 122.6325081,
  },
  {
    art_state_id: "AST4CB3CC3856",
    state_code: "05",
    name: "Bicol Region",
    country_code: "PH",
    lat: 13.4209885,
    lon: 123.4136736,
  },
  {
    art_state_id: "ASTE3DD66EC6B",
    state_code: "06",
    name: "Western Visayas",
    country_code: "PH",
    lat: 11.0049836,
    lon: 122.5372741,
  },
  {
    art_state_id: "ASTC67F3FB28D",
    state_code: "CEB",
    name: "Cebu",
    country_code: "PH",
    lat: 10.3156992,
    lon: 123.8854366,
  },
  {
    art_state_id: "ASTD922A9483B",
    state_code: "CAV",
    name: "Cavite",
    country_code: "PH",
    lat: 14.4791297,
    lon: 120.8969634,
  },
  {
    art_state_id: "AST897F2DDAC1",
    state_code: "07",
    name: "Central Visayas",
    country_code: "PH",
    lat: 9.816875,
    lon: 124.0641419,
  },
  {
    art_state_id: "ASTAC3AE0F7E7",
    state_code: "DVO",
    name: "Davao Occidental",
    country_code: "PH",
    lat: 6.0941396,
    lon: 125.6095474,
  },
  {
    art_state_id: "AST4797A4E50D",
    state_code: "12",
    name: "Soccsksargen",
    country_code: "PH",
    lat: 6.2706918,
    lon: 124.6856509,
  },
  {
    art_state_id: "AST15B83BFA22",
    state_code: "COM",
    name: "Compostela Valley",
    country_code: "PH",
    lat: 7.512515,
    lon: 126.1762615,
  },
  {
    art_state_id: "ASTEEED832FE7",
    state_code: "KAL",
    name: "Kalinga",
    country_code: "PH",
    lat: 17.4740422,
    lon: 121.3541631,
  },
  {
    art_state_id: "AST87656FA961",
    state_code: "ISA",
    name: "Isabela",
    country_code: "PH",
    lat: 18.5007759,
    lon: -67.0243462,
  },
  {
    art_state_id: "AST4A91C33B26",
    state_code: "13",
    name: "Caraga",
    country_code: "PH",
    lat: 8.8014562,
    lon: 125.7406882,
  },
  {
    art_state_id: "AST09DC292A50",
    state_code: "ILI",
    name: "Iloilo",
    country_code: "PH",
    lat: 10.7201501,
    lon: 122.5621063,
  },
  {
    art_state_id: "ASTAC93CF9C77",
    state_code: "14",
    name: "Autonomous Region in Muslim Mindanao",
    country_code: "PH",
    lat: 6.9568313,
    lon: 124.2421597,
  },
  {
    art_state_id: "AST26397BC04B",
    state_code: "LUN",
    name: "La Union",
    country_code: "PH",
    lat: 38.8766878,
    lon: -77.1280912,
  },
  {
    art_state_id: "AST2076296CAB",
    state_code: "DAS",
    name: "Davao del Sur",
    country_code: "PH",
    lat: 6.7662687,
    lon: 125.3284269,
  },
  {
    art_state_id: "AST2B0ED7957B",
    state_code: "DAV",
    name: "Davao del Norte",
    country_code: "PH",
    lat: 7.5617699,
    lon: 125.6532848,
  },
  {
    art_state_id: "AST0330092181",
    state_code: "NCO",
    name: "Cotabato",
    country_code: "PH",
    lat: 7.2046668,
    lon: 124.2310439,
  },
  {
    art_state_id: "ASTA194A02789",
    state_code: "ILS",
    name: "Ilocos Sur",
    country_code: "PH",
    lat: 17.2278664,
    lon: 120.5739579,
  },
  {
    art_state_id: "AST32D0BFCBDE",
    state_code: "08",
    name: "Eastern Visayas",
    country_code: "PH",
    lat: 12.2445533,
    lon: 125.0388164,
  },
  {
    art_state_id: "ASTD6FE15DAD2",
    state_code: "AGN",
    name: "Agusan del Norte",
    country_code: "PH",
    lat: 8.9456259,
    lon: 125.5319234,
  },
  {
    art_state_id: "AST2321BECF93",
    state_code: "ABR",
    name: "Abra",
    country_code: "PH",
    lat: 42.497083,
    lon: -96.38441,
  },
  {
    art_state_id: "AST2C6E8A2571",
    state_code: "09",
    name: "Zamboanga Peninsula",
    country_code: "PH",
    lat: 8.154077,
    lon: 123.258793,
  },
  {
    art_state_id: "AST0A2551B95B",
    state_code: "AGS",
    name: "Agusan del Sur",
    country_code: "PH",
    lat: 8.0463888,
    lon: 126.0615384,
  },
  {
    art_state_id: "AST78F50985A4",
    state_code: "LAN",
    name: "Lanao del Norte",
    country_code: "PH",
    lat: 7.8721811,
    lon: 123.8857747,
  },
  {
    art_state_id: "AST5B9EC23B13",
    state_code: "LAG",
    name: "Laguna",
    country_code: "PH",
    lat: 33.5427189,
    lon: -117.7853568,
  },
  {
    art_state_id: "AST30F183A654",
    state_code: "MAD",
    name: "Marinduque",
    country_code: "PH",
    lat: 13.4767171,
    lon: 121.9032192,
  },
  {
    art_state_id: "AST4B66ABA6D3",
    state_code: "MAG",
    name: "Maguindanao",
    country_code: "PH",
    lat: 6.9422581,
    lon: 124.4198243,
  },
  {
    art_state_id: "AST6F15C9CD51",
    state_code: "AKL",
    name: "Aklan",
    country_code: "PH",
    lat: 11.8166109,
    lon: 122.0941541,
  },
  {
    art_state_id: "AST1649A7BC03",
    state_code: "LEY",
    name: "Leyte",
    country_code: "PH",
    lat: 10.8624536,
    lon: 124.8811195,
  },
  {
    art_state_id: "ASTAC2FC5F4F4",
    state_code: "LAS",
    name: "Lanao del Sur",
    country_code: "PH",
    lat: 7.823176,
    lon: 124.4198243,
  },
  {
    art_state_id: "AST062427D927",
    state_code: "APA",
    name: "Apayao",
    country_code: "PH",
    lat: 18.0120304,
    lon: 121.1710389,
  },
  {
    art_state_id: "AST6FCF6B984F",
    state_code: "15",
    name: "Cordillera Administrative Region",
    country_code: "PH",
    lat: 17.3512542,
    lon: 121.1718851,
  },
  {
    art_state_id: "AST9161D4E4C3",
    state_code: "ANT",
    name: "Antique",
    country_code: "PH",
    lat: 37.0358695,
    lon: -95.6361694,
  },
  {
    art_state_id: "AST0DD2E37835",
    state_code: "ALB",
    name: "Albay",
    country_code: "PH",
    lat: 13.1774827,
    lon: 123.5280072,
  },
  {
    art_state_id: "AST641DE34EC9",
    state_code: "MAS",
    name: "Masbate",
    country_code: "PH",
    lat: 12.3574346,
    lon: 123.5504076,
  },
  {
    art_state_id: "ASTA91A3244B6",
    state_code: "10",
    name: "Northern Mindanao",
    country_code: "PH",
    lat: 8.0201635,
    lon: 124.6856509,
  },
  {
    art_state_id: "AST07E8E934DD",
    state_code: "11",
    name: "Davao Region",
    country_code: "PH",
    lat: 7.3041622,
    lon: 126.0893406,
  },
  {
    art_state_id: "AST3F141EF88E",
    state_code: "AUR",
    name: "Aurora",
    country_code: "PH",
    lat: 36.970891,
    lon: -93.717979,
  },
  {
    art_state_id: "AST453B784FDA",
    state_code: "02",
    name: "Cagayan Valley",
    country_code: "PH",
    lat: 16.9753758,
    lon: 121.8107079,
  },
  {
    art_state_id: "ASTFCDFCCD15D",
    state_code: "MSC",
    name: "Misamis Occidental",
    country_code: "PH",
    lat: 8.3374903,
    lon: 123.7070619,
  },
  {
    art_state_id: "AST6AF4DDF809",
    state_code: "BAN",
    name: "Bataan",
    country_code: "PH",
    lat: 14.6416842,
    lon: 120.4818446,
  },
  {
    art_state_id: "ASTA496CB2547",
    state_code: "03",
    name: "Central Luzon",
    country_code: "PH",
    lat: 15.4827722,
    lon: 120.7120023,
  },
  {
    art_state_id: "ASTA954A50AC0",
    state_code: "BAS",
    name: "Basilan",
    country_code: "PH",
    lat: 6.4296349,
    lon: 121.9870165,
  },
  {
    art_state_id: "ASTDFCA6BF6D6",
    state_code: "NCR",
    name: "Metro Manila",
    country_code: "PH",
    lat: 14.6090537,
    lon: 121.0222565,
  },
  {
    art_state_id: "AST1A05D08632",
    state_code: "MSR",
    name: "Misamis Oriental",
    country_code: "PH",
    lat: 8.5045558,
    lon: 124.6219592,
  },
  {
    art_state_id: "ASTEA70D1F666",
    state_code: "NSA",
    name: "Northern Samar",
    country_code: "PH",
    lat: 12.3613199,
    lon: 124.7740793,
  },
  {
    art_state_id: "AST4CFDED44C4",
    state_code: "NER",
    name: "Negros Oriental",
    country_code: "PH",
    lat: 9.6282083,
    lon: 122.9888319,
  },
  {
    art_state_id: "ASTBA8FD35085",
    state_code: "NEC",
    name: "Negros Occidental",
    country_code: "PH",
    lat: 10.2925609,
    lon: 123.0246518,
  },
  {
    art_state_id: "ASTF349829061",
    state_code: "BTN",
    name: "Batanes",
    country_code: "PH",
    lat: 20.4485074,
    lon: 121.9708129,
  },
  {
    art_state_id: "AST5B1BF6B7FB",
    state_code: "MOU",
    name: "Mountain Province",
    country_code: "PH",
    lat: 40.7075437,
    lon: -73.9501033,
  },
  {
    art_state_id: "AST6559C607F9",
    state_code: "MDR",
    name: "Oriental Mindoro",
    country_code: "PH",
    lat: 13.0564598,
    lon: 121.4069417,
  },
  {
    art_state_id: "AST1E9211124A",
    state_code: "01",
    name: "Ilocos Region",
    country_code: "PH",
    lat: 16.0832144,
    lon: 120.6199895,
  },
  {
    art_state_id: "AST1738B32875",
    state_code: "MDC",
    name: "Occidental Mindoro",
    country_code: "PH",
    lat: 13.1024111,
    lon: 120.7651284,
  },
  {
    art_state_id: "ASTD38D0F1BAA",
    state_code: "ZAS",
    name: "Zamboanga del Sur",
    country_code: "PH",
    lat: 7.8383054,
    lon: 123.2966657,
  },
  {
    art_state_id: "AST5B7D0D22FF",
    state_code: "NUV",
    name: "Nueva Vizcaya",
    country_code: "PH",
    lat: 16.3301107,
    lon: 121.1710389,
  },
  {
    art_state_id: "ASTDC26DBCFAB",
    state_code: "BTG",
    name: "Batangas",
    country_code: "PH",
    lat: 13.7564651,
    lon: 121.0583076,
  },
  {
    art_state_id: "ASTE64BA7A5B9",
    state_code: "NUE",
    name: "Nueva Ecija",
    country_code: "PH",
    lat: 15.578375,
    lon: 121.1112615,
  },
  {
    art_state_id: "AST829AF79203",
    state_code: "PLW",
    name: "Palawan",
    country_code: "PH",
    lat: 9.8349493,
    lon: 118.7383615,
  },
  {
    art_state_id: "AST56F42BD88B",
    state_code: "ZSI",
    name: "Zamboanga Sibugay",
    country_code: "PH",
    lat: 7.5225247,
    lon: 122.3107517,
  },
  {
    art_state_id: "AST3BE43F22B1",
    state_code: "BEN",
    name: "Benguet",
    country_code: "PH",
    lat: 16.5577257,
    lon: 120.8039474,
  },
  {
    art_state_id: "AST903D9084DB",
    state_code: "PAN",
    name: "Pangasinan",
    country_code: "PH",
    lat: 15.8949055,
    lon: 120.2863183,
  },
  {
    art_state_id: "ASTD7A857F8DF",
    state_code: "PAM",
    name: "Pampanga",
    country_code: "PH",
    lat: 15.079409,
    lon: 120.6199895,
  },
  {
    art_state_id: "ASTF1FB740603",
    state_code: "Z",
    name: "Northern District",
    country_code: "IL",
    lat: 36.1511864,
    lon: -95.9951763,
  },
  {
    art_state_id: "AST659E86530A",
    state_code: "M",
    name: "Central District",
    country_code: "IL",
    lat: 47.6087583,
    lon: -122.2964235,
  },
  {
    art_state_id: "AST0F02AC76E0",
    state_code: "D",
    name: "Southern District",
    country_code: "IL",
    lat: 40.7137586,
    lon: -74.0009059,
  },
  {
    art_state_id: "ASTB1A748A5B8",
    state_code: "HA",
    name: "Haifa District",
    country_code: "IL",
    lat: 32.4814111,
    lon: 34.994751,
  },
  {
    art_state_id: "AST69D75C5814",
    state_code: "JM",
    name: "Jerusalem District",
    country_code: "IL",
    lat: 31.7648243,
    lon: 34.994751,
  },
  {
    art_state_id: "AST0C62116C56",
    state_code: "TA",
    name: "Tel Aviv District",
    country_code: "IL",
    lat: 32.0929075,
    lon: 34.8072165,
  },
  {
    art_state_id: "AST1FAC05C61E",
    state_code: "VLI",
    name: "Limburg",
    country_code: "BE",
    lat: 50.9977937,
    lon: 5.445357520264672,
  },
  {
    art_state_id: "ASTB5A30D2DCA",
    state_code: "VLG",
    name: "Flanders",
    country_code: "BE",
    lat: 51.0108706,
    lon: 3.7264613,
  },
  {
    art_state_id: "ASTD02BB09FBA",
    state_code: "VBR",
    name: "Flemish Brabant",
    country_code: "BE",
    lat: 50.8815434,
    lon: 4.564597,
  },
  {
    art_state_id: "ASTC22211AB95",
    state_code: "WHT",
    name: "Hainaut",
    country_code: "BE",
    lat: 50.5257076,
    lon: 4.0621017,
  },
  {
    art_state_id: "AST2E481C5D2E",
    state_code: "BRU",
    name: "Brussels-Capital Region",
    country_code: "BE",
    lat: 50.8503463,
    lon: 4.3517211,
  },
  {
    art_state_id: "AST26A9C63AC4",
    state_code: "VOV",
    name: "East Flanders",
    country_code: "BE",
    lat: 51.0362101,
    lon: 3.7373124,
  },
  {
    art_state_id: "ASTDC9AE5352F",
    state_code: "WNA",
    name: "Namur",
    country_code: "BE",
    lat: 50.4673883,
    lon: 4.8719854,
  },
  {
    art_state_id: "ASTE4FCDCFD9F",
    state_code: "WLX",
    name: "Luxembourg",
    country_code: "BE",
    lat: 49.815273,
    lon: 6.129583,
  },
  {
    art_state_id: "AST0C950BE64F",
    state_code: "WAL",
    name: "Wallonia",
    country_code: "BE",
    lat: 50.4175637,
    lon: 4.4510066,
  },
  {
    art_state_id: "ASTDF630CC2EC",
    state_code: "VAN",
    name: "Antwerp",
    country_code: "BE",
    lat: 51.2194475,
    lon: 4.4024643,
  },
  {
    art_state_id: "AST2A6601AB83",
    state_code: "WBR",
    name: "Walloon Brabant",
    country_code: "BE",
    lat: 50.633241,
    lon: 4.524315,
  },
  {
    art_state_id: "AST4BE98A6EAA",
    state_code: "VWV",
    name: "West Flanders",
    country_code: "BE",
    lat: 40.0179334,
    lon: -105.2806733,
  },
  {
    art_state_id: "ASTD6512B2DFC",
    state_code: "WLG",
    name: "Liège",
    country_code: "BE",
    lat: 50.6325574,
    lon: 5.5796662,
  },
  {
    art_state_id: "ASTD3C08EF49F",
    state_code: "5",
    name: "Darién Province",
    country_code: "PA",
    lat: 7.8681713,
    lon: -77.8367282,
  },
  {
    art_state_id: "ASTB4D6B79627",
    state_code: "3",
    name: "Colón Province",
    country_code: "PA",
    lat: 9.1851989,
    lon: -80.0534923,
  },
  {
    art_state_id: "AST9C61D29082",
    state_code: "2",
    name: "Coclé Province",
    country_code: "PA",
    lat: 8.6266068,
    lon: -80.365865,
  },
  {
    art_state_id: "ASTA3CCFB813E",
    state_code: "KY",
    name: "Guna Yala",
    country_code: "PA",
    lat: 9.2344395,
    lon: -78.192625,
  },
  {
    art_state_id: "ASTCC5C6BADE5",
    state_code: "6",
    name: "Herrera Province",
    country_code: "PA",
    lat: 7.7704282,
    lon: -80.7214417,
  },
  {
    art_state_id: "AST9999D46332",
    state_code: "7",
    name: "Los Santos Province",
    country_code: "PA",
    lat: 7.5909302,
    lon: -80.365865,
  },
  {
    art_state_id: "ASTC92A8D9746",
    state_code: "NB",
    name: "Ngöbe-Buglé Comarca",
    country_code: "PA",
    lat: 8.6595833,
    lon: -81.7787021,
  },
  {
    art_state_id: "AST56A0213B1E",
    state_code: "9",
    name: "Veraguas Province",
    country_code: "PA",
    lat: 8.1231033,
    lon: -81.0754657,
  },
  {
    art_state_id: "AST7476F2090C",
    state_code: "1",
    name: "Bocas del Toro Province",
    country_code: "PA",
    lat: 9.4165521,
    lon: -82.5207787,
  },
  {
    art_state_id: "ASTD8A9E77CB0",
    state_code: "10",
    name: "Panamá Oeste Province",
    country_code: "PA",
    lat: 9.1196751,
    lon: -79.2902133,
  },
  {
    art_state_id: "AST0E9D73837F",
    state_code: "8",
    name: "Panamá Province",
    country_code: "PA",
    lat: 9.1196751,
    lon: -79.2902133,
  },
  {
    art_state_id: "AST603CA2DCAE",
    state_code: "EM",
    name: "Emberá-Wounaan Comarca",
    country_code: "PA",
    lat: 8.3766983,
    lon: -77.6536125,
  },
  {
    art_state_id: "ASTFA9C6D3762",
    state_code: "4",
    name: "Chiriquí Province",
    country_code: "PA",
    lat: 8.584898,
    lon: -82.3885783,
  },
  {
    art_state_id: "AST83BD12D0C5",
    state_code: "UM-84",
    name: "Howland Island",
    country_code: "US",
    lat: 0.8113219,
    lon: -176.6182736,
  },
  {
    art_state_id: "ASTED89C58482",
    state_code: "DE",
    name: "Delaware",
    country_code: "US",
    lat: 38.9108325,
    lon: -75.5276699,
  },
  {
    art_state_id: "AST29EF7B1843",
    state_code: "AK",
    name: "Alaska",
    country_code: "US",
    lat: 64.2008413,
    lon: -149.4936733,
  },
  {
    art_state_id: "ASTE43D92ACCE",
    state_code: "MD",
    name: "Maryland",
    country_code: "US",
    lat: 39.0457549,
    lon: -76.6412712,
  },
  {
    art_state_id: "AST3435F47BD3",
    state_code: "UM-81",
    name: "Baker Island",
    country_code: "US",
    lat: 0.1936266,
    lon: -176.476908,
  },
  {
    art_state_id: "AST92A630E54C",
    state_code: "UM-89",
    name: "Kingman Reef",
    country_code: "US",
    lat: 6.383333,
    lon: -162.416667,
  },
  {
    art_state_id: "AST6EF6EB9AFC",
    state_code: "NH",
    name: "New Hampshire",
    country_code: "US",
    lat: 43.1938516,
    lon: -71.5723953,
  },
  {
    art_state_id: "AST76DBF226A0",
    state_code: "UM-79",
    name: "Wake Island",
    country_code: "US",
    lat: 19.279619,
    lon: 166.6499348,
  },
  {
    art_state_id: "AST616E4D5520",
    state_code: "KS",
    name: "Kansas",
    country_code: "US",
    lat: 39.011902,
    lon: -98.4842465,
  },
  {
    art_state_id: "AST3C2BD22C55",
    state_code: "TX",
    name: "Texas",
    country_code: "US",
    lat: 31.9685988,
    lon: -99.9018131,
  },
  {
    art_state_id: "AST24267F63B9",
    state_code: "NE",
    name: "Nebraska",
    country_code: "US",
    lat: 41.4925374,
    lon: -99.9018131,
  },
  {
    art_state_id: "AST6A7B6641B5",
    state_code: "VT",
    name: "Vermont",
    country_code: "US",
    lat: 44.5588028,
    lon: -72.5778415,
  },
  {
    art_state_id: "AST8EEBABD584",
    state_code: "UM-86",
    name: "Jarvis Island",
    country_code: "US",
    lat: -0.3743503,
    lon: -159.9967206,
  },
  {
    art_state_id: "ASTE6EEF8F2E5",
    state_code: "HI",
    name: "Hawaii",
    country_code: "US",
    lat: 19.8967662,
    lon: -155.5827818,
  },
  {
    art_state_id: "AST3F2D800763",
    state_code: "GU",
    name: "Guam",
    country_code: "US",
    lat: 13.444304,
    lon: 144.793731,
  },
  {
    art_state_id: "AST72A4239E48",
    state_code: "VI",
    name: "United States Virgin Islands",
    country_code: "US",
    lat: 18.335765,
    lon: -64.896335,
  },
  {
    art_state_id: "AST38D103ECB1",
    state_code: "UT",
    name: "Utah",
    country_code: "US",
    lat: 39.3209801,
    lon: -111.0937311,
  },
  {
    art_state_id: "ASTDA43AC8C4E",
    state_code: "OR",
    name: "Oregon",
    country_code: "US",
    lat: 43.8041334,
    lon: -120.5542012,
  },
  {
    art_state_id: "AST9AFD35FA66",
    state_code: "CA",
    name: "California",
    country_code: "US",
    lat: 36.778261,
    lon: -119.4179324,
  },
  {
    art_state_id: "ASTD4FDA11D16",
    state_code: "NJ",
    name: "New Jersey",
    country_code: "US",
    lat: 40.0583238,
    lon: -74.4056612,
  },
  {
    art_state_id: "ASTF16248E8B0",
    state_code: "ND",
    name: "North Dakota",
    country_code: "US",
    lat: 47.5514926,
    lon: -101.0020119,
  },
  {
    art_state_id: "AST51F742CC8C",
    state_code: "KY",
    name: "Kentucky",
    country_code: "US",
    lat: 37.8393332,
    lon: -84.2700179,
  },
  {
    art_state_id: "AST57AF6C2C0F",
    state_code: "MN",
    name: "Minnesota",
    country_code: "US",
    lat: 46.729553,
    lon: -94.6858998,
  },
  {
    art_state_id: "ASTAA025C58D9",
    state_code: "OK",
    name: "Oklahoma",
    country_code: "US",
    lat: 35.4675602,
    lon: -97.5164276,
  },
  {
    art_state_id: "ASTB496D8254D",
    state_code: "PA",
    name: "Pennsylvania",
    country_code: "US",
    lat: 41.2033216,
    lon: -77.1945247,
  },
  {
    art_state_id: "AST0F795D0766",
    state_code: "NM",
    name: "New Mexico",
    country_code: "US",
    lat: 34.5199402,
    lon: -105.8700901,
  },
  {
    art_state_id: "ASTF2B774B991",
    state_code: "AS",
    name: "American Samoa",
    country_code: "US",
    lat: -14.270972,
    lon: -170.132217,
  },
  {
    art_state_id: "ASTA0F54309B5",
    state_code: "IL",
    name: "Illinois",
    country_code: "US",
    lat: 40.6331249,
    lon: -89.3985283,
  },
  {
    art_state_id: "ASTE94B272454",
    state_code: "MI",
    name: "Michigan",
    country_code: "US",
    lat: 44.3148443,
    lon: -85.6023643,
  },
  {
    art_state_id: "ASTC3E914C1E5",
    state_code: "VA",
    name: "Virginia",
    country_code: "US",
    lat: 37.4315734,
    lon: -78.6568942,
  },
  {
    art_state_id: "AST63F5D7300C",
    state_code: "UM-67",
    name: "Johnston Atoll",
    country_code: "US",
    lat: 16.7295035,
    lon: -169.5336477,
  },
  {
    art_state_id: "AST23C547F2C6",
    state_code: "WV",
    name: "West Virginia",
    country_code: "US",
    lat: 38.5976262,
    lon: -80.4549026,
  },
  {
    art_state_id: "AST3DCA71AC6F",
    state_code: "MS",
    name: "Mississippi",
    country_code: "US",
    lat: 32.3546679,
    lon: -89.3985283,
  },
  {
    art_state_id: "AST06FAC90B7D",
    state_code: "MP",
    name: "Northern Mariana Islands",
    country_code: "US",
    lat: 15.0979,
    lon: 145.6739,
  },
  {
    art_state_id: "AST607AEB1558",
    state_code: "UM",
    name: "United States Minor Outlying Islands",
    country_code: "US",
    lat: 19.2823192,
    lon: 166.647047,
  },
  {
    art_state_id: "ASTC0F52378FC",
    state_code: "MA",
    name: "Massachusetts",
    country_code: "US",
    lat: 42.4072107,
    lon: -71.3824374,
  },
  {
    art_state_id: "AST572B44C239",
    state_code: "AZ",
    name: "Arizona",
    country_code: "US",
    lat: 34.0489281,
    lon: -111.0937311,
  },
  {
    art_state_id: "AST41825DB9B5",
    state_code: "CT",
    name: "Connecticut",
    country_code: "US",
    lat: 41.6032207,
    lon: -73.087749,
  },
  {
    art_state_id: "AST3F2C4A57F1",
    state_code: "FL",
    name: "Florida",
    country_code: "US",
    lat: 27.6648274,
    lon: -81.5157535,
  },
  {
    art_state_id: "AST4735DEF748",
    state_code: "DC",
    name: "District of Columbia",
    country_code: "US",
    lat: 38.9071923,
    lon: -77.0368707,
  },
  {
    art_state_id: "AST2994732D3F",
    state_code: "UM-71",
    name: "Midway Atoll",
    country_code: "US",
    lat: 28.2072168,
    lon: -177.3734926,
  },
  {
    art_state_id: "ASTCF48613607",
    state_code: "UM-76",
    name: "Navassa Island",
    country_code: "US",
    lat: 18.4100689,
    lon: -75.0114612,
  },
  {
    art_state_id: "AST8C08545FC5",
    state_code: "IN",
    name: "Indiana",
    country_code: "US",
    lat: 40.2671941,
    lon: -86.1349019,
  },
  {
    art_state_id: "ASTBDBC606DF6",
    state_code: "WI",
    name: "Wisconsin",
    country_code: "US",
    lat: 43.7844397,
    lon: -88.7878678,
  },
  {
    art_state_id: "ASTC9BA2EBFC8",
    state_code: "WY",
    name: "Wyoming",
    country_code: "US",
    lat: 43.0759678,
    lon: -107.2902839,
  },
  {
    art_state_id: "AST4A3880D59E",
    state_code: "SC",
    name: "South Carolina",
    country_code: "US",
    lat: 33.836081,
    lon: -81.1637245,
  },
  {
    art_state_id: "ASTF2BA5B0DF4",
    state_code: "AR",
    name: "Arkansas",
    country_code: "US",
    lat: 35.20105,
    lon: -91.8318334,
  },
  {
    art_state_id: "AST2D8632BA7D",
    state_code: "SD",
    name: "South Dakota",
    country_code: "US",
    lat: 43.9695148,
    lon: -99.9018131,
  },
  {
    art_state_id: "ASTED79E20DD4",
    state_code: "MT",
    name: "Montana",
    country_code: "US",
    lat: 46.8796822,
    lon: -110.3625658,
  },
  {
    art_state_id: "ASTE22FA9869A",
    state_code: "NC",
    name: "North Carolina",
    country_code: "US",
    lat: 35.7595731,
    lon: -79.0192997,
  },
  {
    art_state_id: "ASTC9A27EEF50",
    state_code: "UM-95",
    name: "Palmyra Atoll",
    country_code: "US",
    lat: 5.8885026,
    lon: -162.0786656,
  },
  {
    art_state_id: "ASTEF7273AD16",
    state_code: "PR",
    name: "Puerto Rico",
    country_code: "US",
    lat: 18.220833,
    lon: -66.590149,
  },
  {
    art_state_id: "ASTABE025E5C4",
    state_code: "CO",
    name: "Colorado",
    country_code: "US",
    lat: 39.5500507,
    lon: -105.7820674,
  },
  {
    art_state_id: "AST950B1D1763",
    state_code: "MO",
    name: "Missouri",
    country_code: "US",
    lat: 37.9642529,
    lon: -91.8318334,
  },
  {
    art_state_id: "ASTF4DECD79B2",
    state_code: "NY",
    name: "New York",
    country_code: "US",
    lat: 40.7127753,
    lon: -74.0059728,
  },
  {
    art_state_id: "AST79D54546E1",
    state_code: "ME",
    name: "Maine",
    country_code: "US",
    lat: 45.253783,
    lon: -69.4454689,
  },
  {
    art_state_id: "AST31196733CF",
    state_code: "TN",
    name: "Tennessee",
    country_code: "US",
    lat: 35.5174913,
    lon: -86.5804473,
  },
  {
    art_state_id: "AST236C976DD7",
    state_code: "GA",
    name: "Georgia",
    country_code: "US",
    lat: 32.1656221,
    lon: -82.9000751,
  },
  {
    art_state_id: "ASTACB5CE1C75",
    state_code: "AL",
    name: "Alabama",
    country_code: "US",
    lat: 32.3182314,
    lon: -86.902298,
  },
  {
    art_state_id: "AST5081D84BCA",
    state_code: "LA",
    name: "Louisiana",
    country_code: "US",
    lat: 30.9842977,
    lon: -91.9623327,
  },
  {
    art_state_id: "AST04C7934B43",
    state_code: "NV",
    name: "Nevada",
    country_code: "US",
    lat: 38.8026097,
    lon: -116.419389,
  },
  {
    art_state_id: "ASTC33D307DBE",
    state_code: "IA",
    name: "Iowa",
    country_code: "US",
    lat: 41.8780025,
    lon: -93.097702,
  },
  {
    art_state_id: "ASTBEEEFBDA96",
    state_code: "ID",
    name: "Idaho",
    country_code: "US",
    lat: 44.0682019,
    lon: -114.7420408,
  },
  {
    art_state_id: "ASTD4FB0ED385",
    state_code: "RI",
    name: "Rhode Island",
    country_code: "US",
    lat: 41.5800945,
    lon: -71.4774291,
  },
  {
    art_state_id: "AST8C2A0AAB44",
    state_code: "WA",
    name: "Washington",
    country_code: "US",
    lat: 47.7510741,
    lon: -120.7401385,
  },
  {
    art_state_id: "AST73E7AAE7E6",
    state_code: "22",
    name: "Shinyanga Region",
    country_code: "TZ",
    lat: -3.6809961,
    lon: 33.4271403,
  },
  {
    art_state_id: "ASTBFB71E73D9",
    state_code: "30",
    name: "Simiyu Region",
    country_code: "TZ",
    lat: -2.8308738,
    lon: 34.1531947,
  },
  {
    art_state_id: "AST13C335B0C3",
    state_code: "05",
    name: "Kagera Region",
    country_code: "TZ",
    lat: -1.3001115,
    lon: 31.2626366,
  },
  {
    art_state_id: "AST425CFE53B3",
    state_code: "03",
    name: "Dodoma Region",
    country_code: "TZ",
    lat: -6.5738228,
    lon: 36.2630846,
  },
  {
    art_state_id: "AST117E416ED7",
    state_code: "09",
    name: "Kilimanjaro Region",
    country_code: "TZ",
    lat: -4.1336927,
    lon: 37.8087693,
  },
  {
    art_state_id: "AST82A78D27DD",
    state_code: "13",
    name: "Mara Region",
    country_code: "TZ",
    lat: -1.7753538,
    lon: 34.1531947,
  },
  {
    art_state_id: "AST4381D363BC",
    state_code: "24",
    name: "Tabora Region",
    country_code: "TZ",
    lat: -5.0342138,
    lon: 32.8084496,
  },
  {
    art_state_id: "AST0F14EE7CBF",
    state_code: "16",
    name: "Morogoro Region",
    country_code: "TZ",
    lat: -8.8137173,
    lon: 36.954107,
  },
  {
    art_state_id: "AST553C793763",
    state_code: "11",
    name: "Zanzibar Central/South Region",
    country_code: "TZ",
    lat: -6.2642851,
    lon: 39.4450281,
  },
  {
    art_state_id: "AST5FA5AF6158",
    state_code: "10",
    name: "South Pemba Region",
    country_code: "TZ",
    lat: -5.3146961,
    lon: 39.7549511,
  },
  {
    art_state_id: "ASTAACDEFEFFC",
    state_code: "07",
    name: "Zanzibar North Region",
    country_code: "TZ",
    lat: -5.9395093,
    lon: 39.2791011,
  },
  {
    art_state_id: "ASTBC3D53AB80",
    state_code: "23",
    name: "Singida Region",
    country_code: "TZ",
    lat: -6.7453352,
    lon: 34.1531947,
  },
  {
    art_state_id: "AST73FD62C1F1",
    state_code: "15",
    name: "Zanzibar Urban/West Region",
    country_code: "TZ",
    lat: -6.2298136,
    lon: 39.2583293,
  },
  {
    art_state_id: "AST2105843BD7",
    state_code: "17",
    name: "Mtwara Region",
    country_code: "TZ",
    lat: -10.3398455,
    lon: 40.1657466,
  },
  {
    art_state_id: "ASTAA23C6C754",
    state_code: "20",
    name: "Rukwa Region",
    country_code: "TZ",
    lat: -8.0109444,
    lon: 31.4456179,
  },
  {
    art_state_id: "AST61806D1BA1",
    state_code: "08",
    name: "Kigoma Region",
    country_code: "TZ",
    lat: -4.8824092,
    lon: 29.6615055,
  },
  {
    art_state_id: "AST301A947030",
    state_code: "18",
    name: "Mwanza Region",
    country_code: "TZ",
    lat: -2.4671197,
    lon: 32.8986812,
  },
  {
    art_state_id: "AST9ACD195CBE",
    state_code: "29",
    name: "Njombe Region",
    country_code: "TZ",
    lat: -9.2422632,
    lon: 35.1268781,
  },
  {
    art_state_id: "AST595AE9E446",
    state_code: "27",
    name: "Geita Region",
    country_code: "TZ",
    lat: -2.8242257,
    lon: 32.2653887,
  },
  {
    art_state_id: "AST4418213694",
    state_code: "28",
    name: "Katavi Region",
    country_code: "TZ",
    lat: -6.3677125,
    lon: 31.2626366,
  },
  {
    art_state_id: "AST9578955223",
    state_code: "12",
    name: "Lindi Region",
    country_code: "TZ",
    lat: -9.2343394,
    lon: 38.3165725,
  },
  {
    art_state_id: "AST097CE7789C",
    state_code: "26",
    name: "Manyara Region",
    country_code: "TZ",
    lat: -4.3150058,
    lon: 36.954107,
  },
  {
    art_state_id: "AST337618899B",
    state_code: "19",
    name: "Pwani Region",
    country_code: "TZ",
    lat: -7.3237714,
    lon: 38.8205454,
  },
  {
    art_state_id: "ASTEA8A2D915F",
    state_code: "21",
    name: "Ruvuma Region",
    country_code: "TZ",
    lat: -10.6878717,
    lon: 36.2630846,
  },
  {
    art_state_id: "ASTB1E3BE2A05",
    state_code: "25",
    name: "Tanga Region",
    country_code: "TZ",
    lat: -5.3049789,
    lon: 38.3165725,
  },
  {
    art_state_id: "ASTDEC790D2EF",
    state_code: "06",
    name: "North Pemba Region",
    country_code: "TZ",
    lat: -5.0319352,
    lon: 39.7755571,
  },
  {
    art_state_id: "AST2ADA154E03",
    state_code: "04",
    name: "Iringa Region",
    country_code: "TZ",
    lat: -7.7887442,
    lon: 35.5657862,
  },
  {
    art_state_id: "AST427031744D",
    state_code: "02",
    name: "Dar es Salaam Region",
    country_code: "TZ",
    lat: -6.792354,
    lon: 39.2083284,
  },
  {
    art_state_id: "AST6DE23D5B86",
    state_code: "01",
    name: "Arusha Region",
    country_code: "TZ",
    lat: -3.3869254,
    lon: 36.6829927,
  },
  {
    art_state_id: "AST3068230A13",
    state_code: "IS",
    name: "Eastern Finland Province",
    country_code: "FI",
    lat: 62.5633891,
    lon: 28.5024042,
  },
  {
    art_state_id: "AST327A0BAAA7",
    state_code: "06",
    name: "Tavastia Proper",
    country_code: "FI",
    lat: 60.907015,
    lon: 24.3005498,
  },
  {
    art_state_id: "ASTEFF24A5610",
    state_code: "07",
    name: "Central Ostrobothnia",
    country_code: "FI",
    lat: 63.5621735,
    lon: 24.0013631,
  },
  {
    art_state_id: "AST862985FDF4",
    state_code: "04",
    name: "Southern Savonia",
    country_code: "FI",
    lat: 61.6945148,
    lon: 27.8005015,
  },
  {
    art_state_id: "ASTD4722DC88D",
    state_code: "05",
    name: "Kainuu",
    country_code: "FI",
    lat: 64.3736564,
    lon: 28.7437475,
  },
  {
    art_state_id: "AST87687C9807",
    state_code: "02",
    name: "South Karelia",
    country_code: "FI",
    lat: 61.1181949,
    lon: 28.1024372,
  },
  {
    art_state_id: "AST70551EE901",
    state_code: "03",
    name: "Southern Ostrobothnia",
    country_code: "FI",
    lat: 62.9433099,
    lon: 23.5285267,
  },
  {
    art_state_id: "AST14CC23ED60",
    state_code: "OL",
    name: "Oulu Province",
    country_code: "FI",
    lat: 65.0120748,
    lon: 25.4650496,
  },
  {
    art_state_id: "AST5431C2B539",
    state_code: "LL",
    name: "Lapland",
    country_code: "FI",
    lat: 67.9222304,
    lon: 26.5046438,
  },
  {
    art_state_id: "ASTD4BFEFA162",
    state_code: "17",
    name: "Satakunta",
    country_code: "FI",
    lat: 61.5932758,
    lon: 22.1483081,
  },
  {
    art_state_id: "ASTD6CE58EA1E",
    state_code: "16",
    name: "Päijänne Tavastia",
    country_code: "FI",
    lat: 61.3230041,
    lon: 25.7322496,
  },
  {
    art_state_id: "AST59B18477A3",
    state_code: "15",
    name: "Northern Savonia",
    country_code: "FI",
    lat: 63.08448,
    lon: 27.0253504,
  },
  {
    art_state_id: "AST2DC8E8E60F",
    state_code: "13",
    name: "North Karelia",
    country_code: "FI",
    lat: 62.8062078,
    lon: 30.1553887,
  },
  {
    art_state_id: "AST089E5B1613",
    state_code: "14",
    name: "Northern Ostrobothnia",
    country_code: "FI",
    lat: 65.279493,
    lon: 26.2890417,
  },
  {
    art_state_id: "ASTF44D5B5D04",
    state_code: "11",
    name: "Pirkanmaa",
    country_code: "FI",
    lat: 61.6986918,
    lon: 23.7895598,
  },
  {
    art_state_id: "ASTE4B79EF3FD",
    state_code: "19",
    name: "Finland Proper",
    country_code: "FI",
    lat: 60.3627914,
    lon: 22.4439369,
  },
  {
    art_state_id: "AST31C61ECB0B",
    state_code: "12",
    name: "Ostrobothnia",
    country_code: "FI",
    lat: 63.1181757,
    lon: 21.9061062,
  },
  {
    art_state_id: "ASTA1BA9E4D3A",
    state_code: "01",
    name: "Åland Islands",
    country_code: "FI",
    lat: 60.1785247,
    lon: 19.9156105,
  },
  {
    art_state_id: "AST817411D33A",
    state_code: "18",
    name: "Uusimaa",
    country_code: "FI",
    lat: 60.21872,
    lon: 25.271621,
  },
  {
    art_state_id: "AST488AFC98F3",
    state_code: "08",
    name: "Central Finland",
    country_code: "FI",
    lat: 62.5666743,
    lon: 25.5549445,
  },
  {
    art_state_id: "AST3B3A519961",
    state_code: "09",
    name: "Kymenlaakso",
    country_code: "FI",
    lat: 60.780512,
    lon: 26.8829336,
  },
  {
    art_state_id: "AST7D08B28129",
    state_code: "DI",
    name: "Canton of Diekirch",
    country_code: "LU",
    lat: 49.8671784,
    lon: 6.1595633,
  },
  {
    art_state_id: "ASTB03CE3EFAC",
    state_code: "L",
    name: "Luxembourg District",
    country_code: "LU",
    lat: 49.5953706,
    lon: 6.1333178,
  },
  {
    art_state_id: "ASTC719B62331",
    state_code: "EC",
    name: "Canton of Echternach",
    country_code: "LU",
    lat: 49.8114133,
    lon: 6.4175635,
  },
  {
    art_state_id: "ASTFBA1ECEF8B",
    state_code: "RD",
    name: "Canton of Redange",
    country_code: "LU",
    lat: 49.76455,
    lon: 5.88948,
  },
  {
    art_state_id: "AST36F4E3A98A",
    state_code: "ES",
    name: "Canton of Esch-sur-Alzette",
    country_code: "LU",
    lat: 49.5008805,
    lon: 5.9860925,
  },
  {
    art_state_id: "AST54E2C9C245",
    state_code: "CA",
    name: "Canton of Capellen",
    country_code: "LU",
    lat: 49.6403931,
    lon: 5.9553846,
  },
  {
    art_state_id: "ASTA3284B6211",
    state_code: "RM",
    name: "Canton of Remich",
    country_code: "LU",
    lat: 49.545017,
    lon: 6.3674222,
  },
  {
    art_state_id: "AST988DEB0672",
    state_code: "G",
    name: "Grevenmacher District",
    country_code: "LU",
    lat: 49.680851,
    lon: 6.4407524,
  },
  {
    art_state_id: "ASTEF750EB202",
    state_code: "CL",
    name: "Canton of Clervaux",
    country_code: "LU",
    lat: 50.0546313,
    lon: 6.0285875,
  },
  {
    art_state_id: "ASTB0E103C986",
    state_code: "ME",
    name: "Canton of Mersch",
    country_code: "LU",
    lat: 49.7542906,
    lon: 6.1292185,
  },
  {
    art_state_id: "AST749295A588",
    state_code: "VD",
    name: "Canton of Vianden",
    country_code: "LU",
    lat: 49.9341924,
    lon: 6.2019917,
  },
  {
    art_state_id: "ASTC24830FF5D",
    state_code: "D",
    name: "Diekirch District",
    country_code: "LU",
    lat: 49.867172,
    lon: 6.1596362,
  },
  {
    art_state_id: "ASTB799E63327",
    state_code: "GR",
    name: "Canton of Grevenmacher",
    country_code: "LU",
    lat: 49.680841,
    lon: 6.4407593,
  },
  {
    art_state_id: "AST46ACE68D8C",
    state_code: "WI",
    name: "Canton of Wiltz",
    country_code: "LU",
    lat: 49.96622,
    lon: 5.9324306,
  },
  {
    art_state_id: "ASTC7168391DE",
    state_code: "LU",
    name: "Canton of Luxembourg",
    country_code: "LU",
    lat: 49.6301025,
    lon: 6.1520185,
  },
  {
    art_state_id: "ASTE0DE77BD5B",
    state_code: "85",
    name: "Region Zealand",
    country_code: "DK",
    lat: 55.4632518,
    lon: 11.7214979,
  },
  {
    art_state_id: "ASTA5AA2D91B8",
    state_code: "83",
    name: "Region of Southern Denmark",
    country_code: "DK",
    lat: 55.3307714,
    lon: 9.0924903,
  },
  {
    art_state_id: "ASTB789E4C85B",
    state_code: "84",
    name: "Capital Region of Denmark",
    country_code: "DK",
    lat: 55.6751812,
    lon: 12.5493261,
  },
  {
    art_state_id: "ASTB3509A0FDC",
    state_code: "82",
    name: "Central Denmark Region",
    country_code: "DK",
    lat: 56.302139,
    lon: 9.302777,
  },
  {
    art_state_id: "AST1BF20761C7",
    state_code: "81",
    name: "North Denmark Region",
    country_code: "DK",
    lat: 56.8307416,
    lon: 9.4930527,
  },
  {
    art_state_id: "ASTC5D560B861",
    state_code: "X",
    name: "Gävleborg County",
    country_code: "SE",
    lat: 61.3011993,
    lon: 16.1534214,
  },
  {
    art_state_id: "ASTF1E96DFD61",
    state_code: "W",
    name: "Dalarna County",
    country_code: "SE",
    lat: 61.0917012,
    lon: 14.6663653,
  },
  {
    art_state_id: "ASTD28F58CDE4",
    state_code: "S",
    name: "Värmland County",
    country_code: "SE",
    lat: 59.7294065,
    lon: 13.2354024,
  },
  {
    art_state_id: "AST02286DFFFE",
    state_code: "E",
    name: "Östergötland County",
    country_code: "SE",
    lat: 58.3453635,
    lon: 15.5197844,
  },
  {
    art_state_id: "AST461E2D3E23",
    state_code: "K",
    name: "Blekinge",
    country_code: "SE",
    lat: 56.2783837,
    lon: 15.0180058,
  },
  {
    art_state_id: "ASTB79573A69D",
    state_code: "BD",
    name: "Norrbotten County",
    country_code: "SE",
    lat: 66.8309216,
    lon: 20.3991966,
  },
  {
    art_state_id: "ASTB062CB6182",
    state_code: "T",
    name: "Örebro County",
    country_code: "SE",
    lat: 59.535036,
    lon: 15.0065731,
  },
  {
    art_state_id: "AST5D07495A57",
    state_code: "D",
    name: "Södermanland County",
    country_code: "SE",
    lat: 59.0336349,
    lon: 16.7518899,
  },
  {
    art_state_id: "AST8B4215B0CC",
    state_code: "M",
    name: "Skåne County",
    country_code: "SE",
    lat: 55.9902572,
    lon: 13.5957692,
  },
  {
    art_state_id: "AST55CFB37350",
    state_code: "G",
    name: "Kronoberg County",
    country_code: "SE",
    lat: 56.7183403,
    lon: 14.4114673,
  },
  {
    art_state_id: "ASTFE7E428E35",
    state_code: "AC",
    name: "Västerbotten County",
    country_code: "SE",
    lat: 65.3337311,
    lon: 16.5161694,
  },
  {
    art_state_id: "AST3B87030CB3",
    state_code: "H",
    name: "Kalmar County",
    country_code: "SE",
    lat: 57.2350156,
    lon: 16.1849349,
  },
  {
    art_state_id: "AST18CC4F91EC",
    state_code: "C",
    name: "Uppsala County",
    country_code: "SE",
    lat: 60.0092262,
    lon: 17.2714588,
  },
  {
    art_state_id: "AST23B66C0539",
    state_code: "I",
    name: "Gotland County",
    country_code: "SE",
    lat: 57.4684121,
    lon: 18.4867447,
  },
  {
    art_state_id: "ASTA7B98D0442",
    state_code: "O",
    name: "Västra Götaland County",
    country_code: "SE",
    lat: 58.2527926,
    lon: 13.0596425,
  },
  {
    art_state_id: "AST5DB67A646F",
    state_code: "N",
    name: "Halland County",
    country_code: "SE",
    lat: 56.8966805,
    lon: 12.8033993,
  },
  {
    art_state_id: "AST94C0822ED9",
    state_code: "U",
    name: "Västmanland County",
    country_code: "SE",
    lat: 59.6713879,
    lon: 16.2158953,
  },
  {
    art_state_id: "ASTAD5F05FFA2",
    state_code: "F",
    name: "Jönköping County",
    country_code: "SE",
    lat: 57.3708434,
    lon: 14.3439174,
  },
  {
    art_state_id: "ASTA899B79CC8",
    state_code: "AB",
    name: "Stockholm County",
    country_code: "SE",
    lat: 59.6024958,
    lon: 18.1384383,
  },
  {
    art_state_id: "AST36E297B23E",
    state_code: "Y",
    name: "Västernorrland County",
    country_code: "SE",
    lat: 63.4276473,
    lon: 17.7292444,
  },
  {
    art_state_id: "AST6AB162A8D0",
    state_code: "35",
    name: "Plungė District Municipality",
    country_code: "LT",
    lat: 55.910784,
    lon: 21.8454069,
  },
  {
    art_state_id: "AST12D85C3B3A",
    state_code: "44",
    name: "Šiauliai District Municipality",
    country_code: "LT",
    lat: 55.9721456,
    lon: 23.0332371,
  },
  {
    art_state_id: "AST0B614DAE29",
    state_code: "12",
    name: "Jurbarkas District Municipality",
    country_code: "LT",
    lat: 55.077407,
    lon: 22.7419569,
  },
  {
    art_state_id: "AST2B70672C59",
    state_code: "KU",
    name: "Kaunas County",
    country_code: "LT",
    lat: 54.9872863,
    lon: 23.9525736,
  },
  {
    art_state_id: "AST5D174E149F",
    state_code: "26",
    name: "Mažeikiai District Municipality",
    country_code: "LT",
    lat: 56.3092439,
    lon: 22.341468,
  },
  {
    art_state_id: "ASTE40CF6B6CD",
    state_code: "PN",
    name: "Panevėžys County",
    country_code: "LT",
    lat: 55.9748049,
    lon: 25.0794767,
  },
  {
    art_state_id: "AST363D952D98",
    state_code: "08",
    name: "Elektrėnai municipality",
    country_code: "LT",
    lat: 54.7653934,
    lon: 24.7740583,
  },
  {
    art_state_id: "AST76C3DDB749",
    state_code: "49",
    name: "Švenčionys District Municipality",
    country_code: "LT",
    lat: 55.1028098,
    lon: 26.0071855,
  },
  {
    art_state_id: "AST4B1CF0AC06",
    state_code: "01",
    name: "Akmenė District Municipality",
    country_code: "LT",
    lat: 56.2455029,
    lon: 22.7471169,
  },
  {
    art_state_id: "AST0679A26230",
    state_code: "09",
    name: "Ignalina District Municipality",
    country_code: "LT",
    lat: 55.4090342,
    lon: 26.3284893,
  },
  {
    art_state_id: "AST10F1F9C4C6",
    state_code: "28",
    name: "Neringa Municipality",
    country_code: "LT",
    lat: 55.4572403,
    lon: 21.0839005,
  },
  {
    art_state_id: "AST5A15768F4E",
    state_code: "59",
    name: "Visaginas Municipality",
    country_code: "LT",
    lat: 55.594118,
    lon: 26.4307954,
  },
  {
    art_state_id: "AST856370D151",
    state_code: "16",
    name: "Kaunas District Municipality",
    country_code: "LT",
    lat: 54.9936236,
    lon: 23.6324941,
  },
  {
    art_state_id: "ASTA471263CB8",
    state_code: "06",
    name: "Biržai District Municipality",
    country_code: "LT",
    lat: 56.2017719,
    lon: 24.7560118,
  },
  {
    art_state_id: "AST98FCE17B14",
    state_code: "10",
    name: "Jonava District Municipality",
    country_code: "LT",
    lat: 55.0727242,
    lon: 24.2793337,
  },
  {
    art_state_id: "AST6D1B51B992",
    state_code: "37",
    name: "Radviliškis District Municipality",
    country_code: "LT",
    lat: 55.8108399,
    lon: 23.546487,
  },
  {
    art_state_id: "AST8EF21D23E4",
    state_code: "TE",
    name: "Telšiai County",
    country_code: "LT",
    lat: 56.1026616,
    lon: 22.1113915,
  },
  {
    art_state_id: "ASTAAD77002F7",
    state_code: "MR",
    name: "Marijampolė County",
    country_code: "LT",
    lat: 54.7819971,
    lon: 23.1341365,
  },
  {
    art_state_id: "AST13F51816F9",
    state_code: "22",
    name: "Kretinga District Municipality",
    country_code: "LT",
    lat: 55.883842,
    lon: 21.2350919,
  },
  {
    art_state_id: "ASTF1377A6EBB",
    state_code: "50",
    name: "Tauragė District Municipality",
    country_code: "LT",
    lat: 55.250366,
    lon: 22.29095,
  },
  {
    art_state_id: "ASTD25056EB8E",
    state_code: "TA",
    name: "Tauragė County",
    country_code: "LT",
    lat: 55.3072586,
    lon: 22.3572939,
  },
  {
    art_state_id: "ASTBC28C6A7EC",
    state_code: "AL",
    name: "Alytus County",
    country_code: "LT",
    lat: 54.2000214,
    lon: 24.1512634,
  },
  {
    art_state_id: "ASTF0206742ED",
    state_code: "17",
    name: "Kazlų Rūda municipality",
    country_code: "LT",
    lat: 54.7807526,
    lon: 23.4840243,
  },
  {
    art_state_id: "AST8907DD2881",
    state_code: "41",
    name: "Šakiai District Municipality",
    country_code: "LT",
    lat: 54.952671,
    lon: 23.0480199,
  },
  {
    art_state_id: "ASTF937885209",
    state_code: "42",
    name: "Šalčininkai District Municipality",
    country_code: "LT",
    lat: 54.309767,
    lon: 25.387564,
  },
  {
    art_state_id: "AST6DB34321A7",
    state_code: "36",
    name: "Prienai District Municipality",
    country_code: "LT",
    lat: 54.638358,
    lon: 23.9468009,
  },
  {
    art_state_id: "ASTD9554A7B38",
    state_code: "07",
    name: "Druskininkai municipality",
    country_code: "LT",
    lat: 53.9933685,
    lon: 24.0342438,
  },
  {
    art_state_id: "AST060EABCAF8",
    state_code: "15",
    name: "Kaunas City Municipality",
    country_code: "LT",
    lat: 54.9145326,
    lon: 23.9053518,
  },
  {
    art_state_id: "AST3DCFAE3C31",
    state_code: "11",
    name: "Joniškis District Municipality",
    country_code: "LT",
    lat: 56.236073,
    lon: 23.6136579,
  },
  {
    art_state_id: "AST7474E4D303",
    state_code: "27",
    name: "Molėtai District Municipality",
    country_code: "LT",
    lat: 55.2265309,
    lon: 25.4180011,
  },
  {
    art_state_id: "AST9A078A3978",
    state_code: "13",
    name: "Kaišiadorys District Municipality",
    country_code: "LT",
    lat: 54.8588669,
    lon: 24.4277929,
  },
  {
    art_state_id: "AST11C0921B54",
    state_code: "18",
    name: "Kėdainiai District Municipality",
    country_code: "LT",
    lat: 55.3560947,
    lon: 23.9832683,
  },
  {
    art_state_id: "ASTFF3B2D3950",
    state_code: "23",
    name: "Kupiškis District Municipality",
    country_code: "LT",
    lat: 55.8428741,
    lon: 25.0295816,
  },
  {
    art_state_id: "AST63CDF4C3FF",
    state_code: "SA",
    name: "Šiauliai County",
    country_code: "LT",
    lat: 55.9985751,
    lon: 23.1380051,
  },
  {
    art_state_id: "AST6A173FA9F9",
    state_code: "38",
    name: "Raseiniai District Municipality",
    country_code: "LT",
    lat: 55.3819499,
    lon: 23.1156129,
  },
  {
    art_state_id: "AST6F424E7314",
    state_code: "31",
    name: "Palanga City Municipality",
    country_code: "LT",
    lat: 55.920198,
    lon: 21.0677614,
  },
  {
    art_state_id: "ASTDEB3C037F5",
    state_code: "32",
    name: "Panevėžys City Municipality",
    country_code: "LT",
    lat: 55.7347915,
    lon: 24.3574774,
  },
  {
    art_state_id: "ASTC4BFDBB5F8",
    state_code: "39",
    name: "Rietavas municipality",
    country_code: "LT",
    lat: 55.7021719,
    lon: 21.9986564,
  },
  {
    art_state_id: "ASTCC4FBDBB73",
    state_code: "14",
    name: "Kalvarija municipality",
    country_code: "LT",
    lat: 54.3761674,
    lon: 23.1920321,
  },
  {
    art_state_id: "ASTC39E093DEC",
    state_code: "58",
    name: "Vilnius District Municipality",
    country_code: "LT",
    lat: 54.7732578,
    lon: 25.5867113,
  },
  {
    art_state_id: "ASTB078581736",
    state_code: "52",
    name: "Trakai District Municipality",
    country_code: "LT",
    lat: 54.6379113,
    lon: 24.9346894,
  },
  {
    art_state_id: "AST3D5161E2C5",
    state_code: "47",
    name: "Širvintos District Municipality",
    country_code: "LT",
    lat: 55.043102,
    lon: 24.956981,
  },
  {
    art_state_id: "ASTFD4A4E43F3",
    state_code: "30",
    name: "Pakruojis District Municipality",
    country_code: "LT",
    lat: 56.0732605,
    lon: 23.9389906,
  },
  {
    art_state_id: "AST2A04BAB67D",
    state_code: "53",
    name: "Ukmergė District Municipality",
    country_code: "LT",
    lat: 55.245265,
    lon: 24.7760749,
  },
  {
    art_state_id: "ASTDD9D7DADF5",
    state_code: "20",
    name: "Klaipeda City Municipality",
    country_code: "LT",
    lat: 55.7032948,
    lon: 21.1442795,
  },
  {
    art_state_id: "AST0E1A60141A",
    state_code: "54",
    name: "Utena District Municipality",
    country_code: "LT",
    lat: 55.5084614,
    lon: 25.6832642,
  },
  {
    art_state_id: "AST6C6B471208",
    state_code: "03",
    name: "Alytus District Municipality",
    country_code: "LT",
    lat: 54.3297496,
    lon: 24.1960931,
  },
  {
    art_state_id: "AST67B41D3049",
    state_code: "KL",
    name: "Klaipėda County",
    country_code: "LT",
    lat: 55.6519744,
    lon: 21.3743956,
  },
  {
    art_state_id: "AST93831A5C36",
    state_code: "VL",
    name: "Vilnius County",
    country_code: "LT",
    lat: 54.8086502,
    lon: 25.2182139,
  },
  {
    art_state_id: "AST68B835393B",
    state_code: "55",
    name: "Varėna District Municipality",
    country_code: "LT",
    lat: 54.220333,
    lon: 24.578997,
  },
  {
    art_state_id: "ASTE191A033FC",
    state_code: "05",
    name: "Birštonas Municipality",
    country_code: "LT",
    lat: 54.5669664,
    lon: 24.0093098,
  },
  {
    art_state_id: "ASTD71363C992",
    state_code: "21",
    name: "Klaipėda District Municipality",
    country_code: "LT",
    lat: 55.6841615,
    lon: 21.4416464,
  },
  {
    art_state_id: "ASTAAA6FA1021",
    state_code: "02",
    name: "Alytus City Municipality",
    country_code: "LT",
    lat: 54.3962938,
    lon: 24.0458761,
  },
  {
    art_state_id: "ASTC5DA18ED7A",
    state_code: "57",
    name: "Vilnius City Municipality",
    country_code: "LT",
    lat: 54.6710761,
    lon: 25.2878721,
  },
  {
    art_state_id: "AST5B3BA146F9",
    state_code: "46",
    name: "Šilutė District Municipality",
    country_code: "LT",
    lat: 55.350414,
    lon: 21.4659859,
  },
  {
    art_state_id: "ASTF5A4565A42",
    state_code: "51",
    name: "Telšiai District Municipality",
    country_code: "LT",
    lat: 55.9175215,
    lon: 22.345184,
  },
  {
    art_state_id: "AST1EE2E2B2C3",
    state_code: "43",
    name: "Šiauliai City Municipality",
    country_code: "LT",
    lat: 55.9349085,
    lon: 23.3136823,
  },
  {
    art_state_id: "ASTC3360BF5F9",
    state_code: "25",
    name: "Marijampolė Municipality",
    country_code: "LT",
    lat: 54.5711094,
    lon: 23.4859371,
  },
  {
    art_state_id: "AST6895C24A7B",
    state_code: "24",
    name: "Lazdijai District Municipality",
    country_code: "LT",
    lat: 54.2343267,
    lon: 23.5156505,
  },
  {
    art_state_id: "ASTE26F9D894A",
    state_code: "29",
    name: "Pagėgiai municipality",
    country_code: "LT",
    lat: 55.172132,
    lon: 21.9683614,
  },
  {
    art_state_id: "ASTEAB12249CD",
    state_code: "45",
    name: "Šilalė District Municipality",
    country_code: "LT",
    lat: 55.49268,
    lon: 22.1845559,
  },
  {
    art_state_id: "ASTDEE61D437D",
    state_code: "33",
    name: "Panevėžys District Municipality",
    country_code: "LT",
    lat: 55.6166728,
    lon: 24.3142283,
  },
  {
    art_state_id: "AST92D9D0BEE2",
    state_code: "40",
    name: "Rokiškis District Municipality",
    country_code: "LT",
    lat: 55.9555039,
    lon: 25.5859249,
  },
  {
    art_state_id: "AST57194B2EF5",
    state_code: "34",
    name: "Pasvalys District Municipality",
    country_code: "LT",
    lat: 56.0604619,
    lon: 24.396291,
  },
  {
    art_state_id: "ASTE39A38301D",
    state_code: "48",
    name: "Skuodas District Municipality",
    country_code: "LT",
    lat: 56.2702169,
    lon: 21.5214331,
  },
  {
    art_state_id: "ASTBF9C16AAA4",
    state_code: "19",
    name: "Kelmė District Municipality",
    country_code: "LT",
    lat: 55.6266352,
    lon: 22.878172,
  },
  {
    art_state_id: "ASTC9F99CAEC0",
    state_code: "60",
    name: "Zarasai District Municipality",
    country_code: "LT",
    lat: 55.7309609,
    lon: 26.245295,
  },
  {
    art_state_id: "AST14EA39EE97",
    state_code: "56",
    name: "Vilkaviškis District Municipality",
    country_code: "LT",
    lat: 54.651945,
    lon: 23.035155,
  },
  {
    art_state_id: "AST1E4A812D04",
    state_code: "UT",
    name: "Utena County",
    country_code: "LT",
    lat: 55.5318969,
    lon: 25.7904699,
  },
  {
    art_state_id: "AST37A98B271A",
    state_code: "OP",
    name: "Opole Voivodeship",
    country_code: "PL",
    lat: 50.8003761,
    lon: 17.937989,
  },
  {
    art_state_id: "AST5F046CB311",
    state_code: "SL",
    name: "Silesian Voivodeship",
    country_code: "PL",
    lat: 50.5716595,
    lon: 19.3219768,
  },
  {
    art_state_id: "ASTAEF07AE6BF",
    state_code: "PM",
    name: "Pomeranian Voivodeship",
    country_code: "PL",
    lat: 54.2944252,
    lon: 18.1531164,
  },
  {
    art_state_id: "ASTA4BB688B05",
    state_code: "KP",
    name: "Kuyavian-Pomeranian Voivodeship",
    country_code: "PL",
    lat: 53.1648363,
    lon: 18.4834224,
  },
  {
    art_state_id: "AST3CBCB2246E",
    state_code: "PK",
    name: "Podkarpackie Voivodeship",
    country_code: "PL",
    lat: 50.0574749,
    lon: 22.0895691,
  },
  {
    art_state_id: "AST4E3BDA7AD7",
    state_code: "WN",
    name: "Warmian-Masurian Voivodeship",
    country_code: "PL",
    lat: 53.8671117,
    lon: 20.7027861,
  },
  {
    art_state_id: "ASTFDFD6BEA65",
    state_code: "DS",
    name: "Lower Silesian Voivodeship",
    country_code: "PL",
    lat: 51.1339861,
    lon: 16.8841961,
  },
  {
    art_state_id: "ASTF7E59A1DA0",
    state_code: "SK",
    name: "Świętokrzyskie Voivodeship",
    country_code: "PL",
    lat: 50.6261041,
    lon: 20.9406279,
  },
  {
    art_state_id: "AST651F29672B",
    state_code: "LB",
    name: "Lubusz Voivodeship",
    country_code: "PL",
    lat: 52.2274612,
    lon: 15.2559103,
  },
  {
    art_state_id: "AST429B7848AC",
    state_code: "PD",
    name: "Podlaskie Voivodeship",
    country_code: "PL",
    lat: 53.0697159,
    lon: 22.9674639,
  },
  {
    art_state_id: "AST85FEFF8940",
    state_code: "ZP",
    name: "West Pomeranian Voivodeship",
    country_code: "PL",
    lat: 53.4657891,
    lon: 15.1822581,
  },
  {
    art_state_id: "AST64256461ED",
    state_code: "WP",
    name: "Greater Poland Voivodeship",
    country_code: "PL",
    lat: 52.279986,
    lon: 17.3522939,
  },
  {
    art_state_id: "AST3A16581F9D",
    state_code: "MA",
    name: "Lesser Poland Voivodeship",
    country_code: "PL",
    lat: 49.7225306,
    lon: 20.2503358,
  },
  {
    art_state_id: "ASTE649DAE45D",
    state_code: "LD",
    name: "Łódź Voivodeship",
    country_code: "PL",
    lat: 51.4634771,
    lon: 19.1726974,
  },
  {
    art_state_id: "ASTD09AEF1BF7",
    state_code: "MZ",
    name: "Masovian Voivodeship",
    country_code: "PL",
    lat: 51.8927182,
    lon: 21.0021679,
  },
  {
    art_state_id: "AST83AD6E0BF2",
    state_code: "LU",
    name: "Lublin Voivodeship",
    country_code: "PL",
    lat: 51.2493519,
    lon: 23.1011392,
  },
  {
    art_state_id: "AST6A275F38C4",
    state_code: "AG",
    name: "Aargau",
    country_code: "CH",
    lat: 47.3876664,
    lon: 8.2554295,
  },
  {
    art_state_id: "AST611F5214EF",
    state_code: "FR",
    name: "Canton of Fribourg",
    country_code: "CH",
    lat: 46.6816748,
    lon: 7.1172635,
  },
  {
    art_state_id: "ASTF995DEE345",
    state_code: "BL",
    name: "Basel-Landschaft",
    country_code: "CH",
    lat: 47.4418122,
    lon: 7.7644002,
  },
  {
    art_state_id: "ASTA166476F00",
    state_code: "UR",
    name: "Uri",
    country_code: "CH",
    lat: 41.4860647,
    lon: -71.5308537,
  },
  {
    art_state_id: "ASTD0480E49D5",
    state_code: "TI",
    name: "Ticino",
    country_code: "CH",
    lat: 46.331734,
    lon: 8.8004529,
  },
  {
    art_state_id: "ASTC99DF70212",
    state_code: "SG",
    name: "Canton of St. Gallen",
    country_code: "CH",
    lat: 47.1456254,
    lon: 9.3504332,
  },
  {
    art_state_id: "ASTD983EDDED6",
    state_code: "BE",
    name: "canton of Bern",
    country_code: "CH",
    lat: 46.7988621,
    lon: 7.7080701,
  },
  {
    art_state_id: "ASTC5B19F47BF",
    state_code: "ZG",
    name: "Canton of Zug",
    country_code: "CH",
    lat: 47.1661505,
    lon: 8.5154749,
  },
  {
    art_state_id: "AST1C38669FEB",
    state_code: "GE",
    name: "Canton of Geneva",
    country_code: "CH",
    lat: 46.2180073,
    lon: 6.1216925,
  },
  {
    art_state_id: "ASTF307ED4337",
    state_code: "VS",
    name: "Canton of Valais",
    country_code: "CH",
    lat: 46.1904614,
    lon: 7.5449226,
  },
  {
    art_state_id: "ASTB8668FF1D8",
    state_code: "AI",
    name: "Appenzell Innerrhoden",
    country_code: "CH",
    lat: 47.3161925,
    lon: 9.4316573,
  },
  {
    art_state_id: "AST5AFEEFA980",
    state_code: "OW",
    name: "Obwalden",
    country_code: "CH",
    lat: 46.877858,
    lon: 8.251249,
  },
  {
    art_state_id: "AST0F657192EC",
    state_code: "VD",
    name: "Canton of Vaud",
    country_code: "CH",
    lat: 46.5613135,
    lon: 6.536765,
  },
  {
    art_state_id: "AST1CD25A2790",
    state_code: "NW",
    name: "Nidwalden",
    country_code: "CH",
    lat: 46.9267016,
    lon: 8.3849982,
  },
  {
    art_state_id: "ASTD20AC21CD1",
    state_code: "SZ",
    name: "Schwyz",
    country_code: "CH",
    lat: 47.0207138,
    lon: 8.6529884,
  },
  {
    art_state_id: "ASTB451128CFE",
    state_code: "SH",
    name: "Canton of Schaffhausen",
    country_code: "CH",
    lat: 47.7009364,
    lon: 8.568004,
  },
  {
    art_state_id: "AST62C859AE46",
    state_code: "AR",
    name: "Appenzell Ausserrhoden",
    country_code: "CH",
    lat: 47.366481,
    lon: 9.3000916,
  },
  {
    art_state_id: "ASTDBBB458307",
    state_code: "ZH",
    name: "canton of Zürich",
    country_code: "CH",
    lat: 47.359536,
    lon: 8.6356452,
  },
  {
    art_state_id: "ASTD65F198217",
    state_code: "TG",
    name: "Thurgau",
    country_code: "CH",
    lat: 47.6037856,
    lon: 9.0557371,
  },
  {
    art_state_id: "AST0ECEFDB2AA",
    state_code: "JU",
    name: "Canton of Jura",
    country_code: "CH",
    lat: 47.3444474,
    lon: 7.1430608,
  },
  {
    art_state_id: "AST867AC69B12",
    state_code: "NE",
    name: "Canton of Neuchâtel",
    country_code: "CH",
    lat: 46.9899874,
    lon: 6.9292732,
  },
  {
    art_state_id: "AST8B04F76FC5",
    state_code: "GR",
    name: "Graubünden",
    country_code: "CH",
    lat: 46.6569871,
    lon: 9.5780257,
  },
  {
    art_state_id: "AST57AF556E04",
    state_code: "GL",
    name: "Glarus",
    country_code: "CH",
    lat: 47.0411232,
    lon: 9.0679,
  },
  {
    art_state_id: "AST4606AD8F12",
    state_code: "SO",
    name: "Canton of Solothurn",
    country_code: "CH",
    lat: 47.3320717,
    lon: 7.6388385,
  },
  {
    art_state_id: "ASTD5E56E1C3D",
    state_code: "LU",
    name: "Canton of Lucerne",
    country_code: "CH",
    lat: 47.0795671,
    lon: 8.1662445,
  },
  {
    art_state_id: "AST646B879458",
    state_code: "52",
    name: "Tuscany",
    country_code: "IT",
    lat: 43.7710513,
    lon: 11.2486208,
  },
  {
    art_state_id: "ASTC4ED0078FB",
    state_code: "PD",
    name: "Province of Padua",
    country_code: "IT",
    lat: 45.3661864,
    lon: 11.8209139,
  },
  {
    art_state_id: "AST62535B466B",
    state_code: "PR",
    name: "Province of Parma",
    country_code: "IT",
    lat: 44.8015322,
    lon: 10.3279354,
  },
  {
    art_state_id: "ASTA05EEF8DD6",
    state_code: "SR",
    name: "Libero consorzio comunale di Siracusa",
    country_code: "IT",
    lat: 37.0656924,
    lon: 15.2857109,
  },
  {
    art_state_id: "AST10E6F762C7",
    state_code: "PA",
    name: "Metropolitan City of Palermo",
    country_code: "IT",
    lat: 38.11569,
    lon: 13.3614868,
  },
  {
    art_state_id: "AST395D73AB4E",
    state_code: "72",
    name: "Campania",
    country_code: "IT",
    lat: 40.6670887,
    lon: 15.1068113,
  },
  {
    art_state_id: "AST869E0EB871",
    state_code: "57",
    name: "Marche",
    country_code: "IT",
    lat: 30.556707,
    lon: -81.449303,
  },
  {
    art_state_id: "ASTCDD593FF9B",
    state_code: "RC",
    name: "Metropolitan City of Reggio Calabria",
    country_code: "IT",
    lat: 38.1084396,
    lon: 15.6437048,
  },
  {
    art_state_id: "AST97CA48D729",
    state_code: "AN",
    name: "Province of Ancona",
    country_code: "IT",
    lat: 43.5493245,
    lon: 13.2663479,
  },
  {
    art_state_id: "ASTA29205FF54",
    state_code: "VE",
    name: "Metropolitan City of Venice",
    country_code: "IT",
    lat: 45.4414685,
    lon: 12.3152672,
  },
  {
    art_state_id: "AST91EE78E95D",
    state_code: "LT",
    name: "Province of Latina",
    country_code: "IT",
    lat: 41.4087476,
    lon: 13.0817903,
  },
  {
    art_state_id: "AST062FF328B6",
    state_code: "LE",
    name: "Province of Lecce",
    country_code: "IT",
    lat: 40.2347393,
    lon: 18.1428669,
  },
  {
    art_state_id: "AST07D0876EF7",
    state_code: "PV",
    name: "Province of Pavia",
    country_code: "IT",
    lat: 45.3218166,
    lon: 8.8466236,
  },
  {
    art_state_id: "ASTEBB9747090",
    state_code: "LC",
    name: "Province of Lecco",
    country_code: "IT",
    lat: 45.9382941,
    lon: 9.385729,
  },
  {
    art_state_id: "AST81C79233E1",
    state_code: "62",
    name: "Lazio",
    country_code: "IT",
    lat: 45.6991667,
    lon: -73.6558333,
  },
  {
    art_state_id: "ASTB5E94BFD7A",
    state_code: "65",
    name: "Abruzzo",
    country_code: "IT",
    lat: 42.1920119,
    lon: 13.7289167,
  },
  {
    art_state_id: "ASTA468D01AD3",
    state_code: "FI",
    name: "Metropolitan City of Florence",
    country_code: "IT",
    lat: 43.7679178,
    lon: 11.2523792,
  },
  {
    art_state_id: "AST21080A45D7",
    state_code: "AP",
    name: "Province of Ascoli Piceno",
    country_code: "IT",
    lat: 42.8638933,
    lon: 13.5899733,
  },
  {
    art_state_id: "AST3495A08315",
    state_code: "CA",
    name: "Metropolitan City of Cagliari",
    country_code: "IT",
    lat: 39.2238411,
    lon: 9.1216613,
  },
  {
    art_state_id: "AST55EA986569",
    state_code: "55",
    name: "Umbria",
    country_code: "IT",
    lat: 42.938004,
    lon: 12.6216211,
  },
  {
    art_state_id: "ASTEBC99A28B8",
    state_code: "BO",
    name: "Metropolitan City of Bologna",
    country_code: "IT",
    lat: 44.494887,
    lon: 11.3426162,
  },
  {
    art_state_id: "ASTC5B22BE546",
    state_code: "PI",
    name: "Province of Pisa",
    country_code: "IT",
    lat: 43.7228315,
    lon: 10.4017194,
  },
  {
    art_state_id: "AST8AE2E1407C",
    state_code: "BT",
    name: "Province of Barletta-Andria-Trani",
    country_code: "IT",
    lat: 41.2004543,
    lon: 16.2051484,
  },
  {
    art_state_id: "ASTB97FE8C575",
    state_code: "PT",
    name: "Province of Pistoia",
    country_code: "IT",
    lat: 43.9543733,
    lon: 10.8903099,
  },
  {
    art_state_id: "AST753CA06427",
    state_code: "75",
    name: "Apulia",
    country_code: "IT",
    lat: 40.7928393,
    lon: 17.1011931,
  },
  {
    art_state_id: "ASTEE0F4FF714",
    state_code: "BL",
    name: "Province of Belluno",
    country_code: "IT",
    lat: 46.2497659,
    lon: 12.1969565,
  },
  {
    art_state_id: "ASTF6FB8E1AFA",
    state_code: "PN",
    name: "Province of Pordenone",
    country_code: "IT",
    lat: 46.0378862,
    lon: 12.710835,
  },
  {
    art_state_id: "ASTFA37A111C5",
    state_code: "PG",
    name: "Province of Perugia",
    country_code: "IT",
    lat: 42.938004,
    lon: 12.6216211,
  },
  {
    art_state_id: "AST500383F61D",
    state_code: "AV",
    name: "Province of Avellino",
    country_code: "IT",
    lat: 40.996451,
    lon: 15.1258955,
  },
  {
    art_state_id: "ASTD475354F79",
    state_code: "PU",
    name: "Pesaro and Urbino Province",
    country_code: "IT",
    lat: 43.6130118,
    lon: 12.7135121,
  },
  {
    art_state_id: "ASTBB31CD282D",
    state_code: "PE",
    name: "Province of Pescara",
    country_code: "IT",
    lat: 42.3570655,
    lon: 13.9608091,
  },
  {
    art_state_id: "AST8A91393B1F",
    state_code: "67",
    name: "Molise",
    country_code: "IT",
    lat: 41.6738865,
    lon: 14.7520939,
  },
  {
    art_state_id: "ASTA1A14AA84C",
    state_code: "PC",
    name: "Province of Piacenza",
    country_code: "IT",
    lat: 44.8263112,
    lon: 9.5291447,
  },
  {
    art_state_id: "AST5962B1482F",
    state_code: "PZ",
    name: "Province of Potenza",
    country_code: "IT",
    lat: 40.4182194,
    lon: 15.876004,
  },
  {
    art_state_id: "AST23F97BAFA1",
    state_code: "MI",
    name: "Metropolitan City of Milan",
    country_code: "IT",
    lat: 45.458626,
    lon: 9.181873,
  },
  {
    art_state_id: "ASTE8CD12D3A8",
    state_code: "GE",
    name: "Metropolitan City of Genoa",
    country_code: "IT",
    lat: 44.4056493,
    lon: 8.9462564,
  },
  {
    art_state_id: "ASTC84F411A22",
    state_code: "PO",
    name: "Province of Prato",
    country_code: "IT",
    lat: 44.04539,
    lon: 11.1164452,
  },
  {
    art_state_id: "AST7B00469A32",
    state_code: "BN",
    name: "Benevento Province",
    country_code: "IT",
    lat: 41.2035093,
    lon: 14.7520939,
  },
  {
    art_state_id: "AST6A31C7EAF0",
    state_code: "21",
    name: "Piedmont",
    country_code: "IT",
    lat: 45.0522366,
    lon: 7.5153885,
  },
  {
    art_state_id: "AST122AEED3EA",
    state_code: "78",
    name: "Calabria",
    country_code: "IT",
    lat: 39.3087714,
    lon: 16.3463791,
  },
  {
    art_state_id: "ASTDBDEA11414",
    state_code: "BG",
    name: "Province of Bergamo",
    country_code: "IT",
    lat: 45.6982642,
    lon: 9.6772698,
  },
  {
    art_state_id: "ASTE5492862B2",
    state_code: "25",
    name: "Lombardy",
    country_code: "IT",
    lat: 45.4790671,
    lon: 9.8452433,
  },
  {
    art_state_id: "ASTCD21BDDBAC",
    state_code: "77",
    name: "Basilicata",
    country_code: "IT",
    lat: 40.6430766,
    lon: 15.9699878,
  },
  {
    art_state_id: "ASTD74D5171C2",
    state_code: "RA",
    name: "Province of Ravenna",
    country_code: "IT",
    lat: 44.4184443,
    lon: 12.2035998,
  },
  {
    art_state_id: "AST6C5C6C7CA4",
    state_code: "RE",
    name: "Province of Reggio Emilia",
    country_code: "IT",
    lat: 44.585658,
    lon: 10.5564736,
  },
  {
    art_state_id: "AST5DC1CAEE27",
    state_code: "82",
    name: "Sicily",
    country_code: "IT",
    lat: 37.5999938,
    lon: 14.0153557,
  },
  {
    art_state_id: "AST96AEBC8C3A",
    state_code: "TO",
    name: "Metropolitan City of Turin",
    country_code: "IT",
    lat: 45.063299,
    lon: 7.669289,
  },
  {
    art_state_id: "ASTAE2B5F21AE",
    state_code: "RM",
    name: "Metropolitan City of Rome",
    country_code: "IT",
    lat: 41.9027008,
    lon: 12.4962352,
  },
  {
    art_state_id: "ASTEDAEC77A28",
    state_code: "RI",
    name: "Province of Rieti",
    country_code: "IT",
    lat: 42.3674405,
    lon: 12.8975098,
  },
  {
    art_state_id: "ASTFD884F044C",
    state_code: "RN",
    name: "Province of Rimini",
    country_code: "IT",
    lat: 44.0678288,
    lon: 12.5695158,
  },
  {
    art_state_id: "AST3EF6BDC25D",
    state_code: "BR",
    name: "Province of Brindisi",
    country_code: "IT",
    lat: 40.6112663,
    lon: 17.763621,
  },
  {
    art_state_id: "AST6D73560EE9",
    state_code: "88",
    name: "Sardinia",
    country_code: "IT",
    lat: 40.1208752,
    lon: 9.0128926,
  },
  {
    art_state_id: "AST4A5740B88C",
    state_code: "23",
    name: "Aosta Valley",
    country_code: "IT",
    lat: 45.7388878,
    lon: 7.4261866,
  },
  {
    art_state_id: "AST6C713DD24B",
    state_code: "BS",
    name: "Province of Brescia",
    country_code: "IT",
    lat: 45.5415526,
    lon: 10.2118019,
  },
  {
    art_state_id: "AST110F0A1A28",
    state_code: "CL",
    name: "Libero consorzio comunale di Caltanissetta",
    country_code: "IT",
    lat: 37.486013,
    lon: 14.0614982,
  },
  {
    art_state_id: "AST7FBB192EF1",
    state_code: "RO",
    name: "Province of Rovigo",
    country_code: "IT",
    lat: 45.0241818,
    lon: 11.8238162,
  },
  {
    art_state_id: "ASTC2F0E28D06",
    state_code: "SA",
    name: "Province of Salerno",
    country_code: "IT",
    lat: 40.4287832,
    lon: 15.2194808,
  },
  {
    art_state_id: "AST442AE3C855",
    state_code: "CB",
    name: "Province of Campobasso",
    country_code: "IT",
    lat: 41.6738865,
    lon: 14.7520939,
  },
  {
    art_state_id: "ASTEE8761001B",
    state_code: "SS",
    name: "Province of Sassari",
    country_code: "IT",
    lat: 40.7967907,
    lon: 8.5750407,
  },
  {
    art_state_id: "AST36A9CA9E11",
    state_code: "EN",
    name: "Libero consorzio comunale di Enna",
    country_code: "IT",
    lat: 37.5676216,
    lon: 14.2795349,
  },
  {
    art_state_id: "AST60733B4895",
    state_code: "NA",
    name: "Metropolitan City of Naples",
    country_code: "IT",
    lat: 40.901975,
    lon: 14.332644,
  },
  {
    art_state_id: "AST0A55164A7D",
    state_code: "32",
    name: "Trentino-South Tyrol",
    country_code: "IT",
    lat: 46.4336662,
    lon: 11.1693296,
  },
  {
    art_state_id: "AST0F06A54642",
    state_code: "VB",
    name: "Province of Verbano-Cusio-Ossola",
    country_code: "IT",
    lat: 46.1399688,
    lon: 8.2724649,
  },
  {
    art_state_id: "ASTA468412306",
    state_code: "AG",
    name: "Libero consorzio comunale di Agrigento",
    country_code: "IT",
    lat: 37.3105202,
    lon: 13.5857978,
  },
  {
    art_state_id: "ASTD31BF93210",
    state_code: "CZ",
    name: "Province of Catanzaro",
    country_code: "IT",
    lat: 38.8896348,
    lon: 16.4405872,
  },
  {
    art_state_id: "AST043B55ABD8",
    state_code: "RG",
    name: "Libero consorzio comunale di Ragusa",
    country_code: "IT",
    lat: 36.9269273,
    lon: 14.7255129,
  },
  {
    art_state_id: "AST85A6432523",
    state_code: "CI",
    name: "Province of Carbonia-Iglesias",
    country_code: "IT",
    lat: 39.2534659,
    lon: 8.5721016,
  },
  {
    art_state_id: "ASTB67FE73D49",
    state_code: "CE",
    name: "Province of Caserta",
    country_code: "IT",
    lat: 41.2078354,
    lon: 14.1001326,
  },
  {
    art_state_id: "AST2064562C67",
    state_code: "SV",
    name: "Province of Savona",
    country_code: "IT",
    lat: 44.2887995,
    lon: 8.265058,
  },
  {
    art_state_id: "ASTAEE4571E7C",
    state_code: "TP",
    name: "Libero consorzio comunale di Trapani",
    country_code: "IT",
    lat: 38.0183116,
    lon: 12.5148265,
  },
  {
    art_state_id: "AST0C8FFF4B15",
    state_code: "SI",
    name: "Province of Siena",
    country_code: "IT",
    lat: 43.2937732,
    lon: 11.4339148,
  },
  {
    art_state_id: "ASTD8049CD886",
    state_code: "VT",
    name: "Province of Viterbo",
    country_code: "IT",
    lat: 42.400242,
    lon: 11.8891721,
  },
  {
    art_state_id: "AST32155B30B9",
    state_code: "VR",
    name: "Province of Verona",
    country_code: "IT",
    lat: 45.4418498,
    lon: 11.0735316,
  },
  {
    art_state_id: "AST6DB87287B0",
    state_code: "VV",
    name: "Province of Vibo Valentia",
    country_code: "IT",
    lat: 38.6378565,
    lon: 16.2051484,
  },
  {
    art_state_id: "AST1FFD3DA9B7",
    state_code: "VI",
    name: "Province of Vicenza",
    country_code: "IT",
    lat: 45.6983995,
    lon: 11.5661465,
  },
  {
    art_state_id: "ASTCEF371F78A",
    state_code: "CH",
    name: "Province of Chieti",
    country_code: "IT",
    lat: 42.0334428,
    lon: 14.3791912,
  },
  {
    art_state_id: "AST7BDE619400",
    state_code: "CO",
    name: "Province of Como",
    country_code: "IT",
    lat: 45.8080416,
    lon: 9.0851793,
  },
  {
    art_state_id: "ASTC3037B90F5",
    state_code: "SO",
    name: "Province of Sondrio",
    country_code: "IT",
    lat: 46.1727636,
    lon: 9.7994917,
  },
  {
    art_state_id: "AST1459E50C6B",
    state_code: "CS",
    name: "Province of Cosenza",
    country_code: "IT",
    lat: 39.5644105,
    lon: 16.2522143,
  },
  {
    art_state_id: "AST709D215DF4",
    state_code: "TA",
    name: "Province of Taranto",
    country_code: "IT",
    lat: 40.5740901,
    lon: 17.2429976,
  },
  {
    art_state_id: "AST9121C3F644",
    state_code: "FM",
    name: "Province of Fermo",
    country_code: "IT",
    lat: 43.0931367,
    lon: 13.5899733,
  },
  {
    art_state_id: "AST5267D2E74B",
    state_code: "LI",
    name: "Province of Livorno",
    country_code: "IT",
    lat: 43.0239848,
    lon: 10.6647101,
  },
  {
    art_state_id: "ASTE87D137FDD",
    state_code: "FE",
    name: "Province of Ferrara",
    country_code: "IT",
    lat: 44.766368,
    lon: 11.7644068,
  },
  {
    art_state_id: "AST404642B259",
    state_code: "LO",
    name: "Province of Lodi",
    country_code: "IT",
    lat: 45.2405036,
    lon: 9.5292512,
  },
  {
    art_state_id: "ASTD1AFB05F60",
    state_code: "TN",
    name: "Trentino",
    country_code: "IT",
    lat: 46.0512004,
    lon: 11.1175392,
  },
  {
    art_state_id: "ASTB363C166AC",
    state_code: "LU",
    name: "Province of Lucca",
    country_code: "IT",
    lat: 43.8376736,
    lon: 10.495053,
  },
  {
    art_state_id: "ASTD21DAB568F",
    state_code: "MC",
    name: "Province of Macerata",
    country_code: "IT",
    lat: 43.2459322,
    lon: 13.2663479,
  },
  {
    art_state_id: "ASTA26A288358",
    state_code: "CR",
    name: "Province of Cremona",
    country_code: "IT",
    lat: 45.2014375,
    lon: 9.9836582,
  },
  {
    art_state_id: "ASTC3DEA6CFF6",
    state_code: "TE",
    name: "Province of Teramo",
    country_code: "IT",
    lat: 42.5895608,
    lon: 13.6362715,
  },
  {
    art_state_id: "AST792EC462FC",
    state_code: "34",
    name: "Veneto",
    country_code: "IT",
    lat: 45.4414662,
    lon: 12.3152595,
  },
  {
    art_state_id: "ASTD39683F39C",
    state_code: "KR",
    name: "Province of Crotone",
    country_code: "IT",
    lat: 39.1309856,
    lon: 17.0067031,
  },
  {
    art_state_id: "ASTE636F334B8",
    state_code: "TR",
    name: "Province of Terni",
    country_code: "IT",
    lat: 42.5634534,
    lon: 12.5298028,
  },
  {
    art_state_id: "ASTF70C4DD770",
    state_code: "36",
    name: "Friuli–Venezia Giulia",
    country_code: "IT",
    lat: 46.2259177,
    lon: 13.1033646,
  },
  {
    art_state_id: "ASTFB8CD42566",
    state_code: "MO",
    name: "Province of Modena",
    country_code: "IT",
    lat: 44.5513799,
    lon: 10.918048,
  },
  {
    art_state_id: "AST90A9D70DC3",
    state_code: "MN",
    name: "Province of Mantua",
    country_code: "IT",
    lat: 45.1667728,
    lon: 10.7753613,
  },
  {
    art_state_id: "ASTBE07B97FB7",
    state_code: "MS",
    name: "Province of Massa and Carrara",
    country_code: "IT",
    lat: 44.2213998,
    lon: 10.0359661,
  },
  {
    art_state_id: "ASTC9173808D1",
    state_code: "MT",
    name: "Province of Matera",
    country_code: "IT",
    lat: 40.6663496,
    lon: 16.6043636,
  },
  {
    art_state_id: "ASTDF2B2547A6",
    state_code: "VS",
    name: "Province of Medio Campidano",
    country_code: "IT",
    lat: 39.5317389,
    lon: 8.704075,
  },
  {
    art_state_id: "AST87652D3C48",
    state_code: "TV",
    name: "Province of Treviso",
    country_code: "IT",
    lat: 45.6668517,
    lon: 12.2430617,
  },
  {
    art_state_id: "AST1AE40AE771",
    state_code: "TS",
    name: "Province of Trieste",
    country_code: "IT",
    lat: 45.6894823,
    lon: 13.7833072,
  },
  {
    art_state_id: "ASTE46FFAE364",
    state_code: "UD",
    name: "Province of Udine",
    country_code: "IT",
    lat: 46.1407972,
    lon: 13.1662896,
  },
  {
    art_state_id: "AST9B8DCD3423",
    state_code: "VA",
    name: "Province of Varese",
    country_code: "IT",
    lat: 45.799026,
    lon: 8.7300945,
  },
  {
    art_state_id: "ASTF37D3BD29A",
    state_code: "CT",
    name: "Metropolitan City of Catania",
    country_code: "IT",
    lat: 37.4515438,
    lon: 15.0557415,
  },
  {
    art_state_id: "ASTB39C1F2ED9",
    state_code: "BZ",
    name: "South Tyrol",
    country_code: "IT",
    lat: 46.494945,
    lon: 11.3402657,
  },
  {
    art_state_id: "AST94500DA7B0",
    state_code: "42",
    name: "Liguria",
    country_code: "IT",
    lat: 44.3167917,
    lon: 8.3964938,
  },
  {
    art_state_id: "ASTF45CF8782A",
    state_code: "MB",
    name: "Province of Monza and Brianza",
    country_code: "IT",
    lat: 45.623599,
    lon: 9.2588015,
  },
  {
    art_state_id: "ASTBDFFE5EA91",
    state_code: "ME",
    name: "Metropolitan City of Messina",
    country_code: "IT",
    lat: 38.1937335,
    lon: 15.5542057,
  },
  {
    art_state_id: "AST08B6AE96C8",
    state_code: "FG",
    name: "Province of Foggia",
    country_code: "IT",
    lat: 41.638448,
    lon: 15.5943388,
  },
  {
    art_state_id: "AST76276D9E88",
    state_code: "BA",
    name: "Metropolitan City of Bari",
    country_code: "IT",
    lat: 41.1171432,
    lon: 16.8718715,
  },
  {
    art_state_id: "ASTCB551EE43F",
    state_code: "45",
    name: "Emilia-Romagna",
    country_code: "IT",
    lat: 44.5967607,
    lon: 11.2186396,
  },
  {
    art_state_id: "AST27C0141D14",
    state_code: "NO",
    name: "Province of Novara",
    country_code: "IT",
    lat: 45.5485133,
    lon: 8.5150793,
  },
  {
    art_state_id: "AST0820F9065A",
    state_code: "CN",
    name: "Province of Cuneo",
    country_code: "IT",
    lat: 44.5970314,
    lon: 7.6114217,
  },
  {
    art_state_id: "AST6B2E4EF820",
    state_code: "FR",
    name: "Province of Frosinone",
    country_code: "IT",
    lat: 41.6576528,
    lon: 13.6362715,
  },
  {
    art_state_id: "AST125F3DF4B4",
    state_code: "GO",
    name: "Province of Gorizia",
    country_code: "IT",
    lat: 45.9053899,
    lon: 13.5163725,
  },
  {
    art_state_id: "ASTFD71DCA459",
    state_code: "BI",
    name: "Province of Biella",
    country_code: "IT",
    lat: 45.5628176,
    lon: 8.0582717,
  },
  {
    art_state_id: "ASTECF75E03EA",
    state_code: "FC",
    name: "Province of Forlì-Cesena",
    country_code: "IT",
    lat: 43.9947681,
    lon: 11.9804613,
  },
  {
    art_state_id: "AST792868E3A5",
    state_code: "AT",
    name: "Province of Asti",
    country_code: "IT",
    lat: 44.9007652,
    lon: 8.2064315,
  },
  {
    art_state_id: "ASTDCB4C4170C",
    state_code: "AQ",
    name: "Province of L'Aquila",
    country_code: "IT",
    lat: 42.1256317,
    lon: 13.6362715,
  },
  {
    art_state_id: "AST22461F102E",
    state_code: "OG",
    name: "Province of Ogliastra",
    country_code: "IT",
    lat: 39.8410536,
    lon: 9.456155,
  },
  {
    art_state_id: "AST83B408CBCE",
    state_code: "AL",
    name: "Province of Alessandria",
    country_code: "IT",
    lat: 44.8175587,
    lon: 8.7046627,
  },
  {
    art_state_id: "AST6AD1C70AFE",
    state_code: "OT",
    name: "Province of Olbia-Tempio",
    country_code: "IT",
    lat: 40.8268383,
    lon: 9.2785583,
  },
  {
    art_state_id: "AST125234398E",
    state_code: "VC",
    name: "Province of Vercelli",
    country_code: "IT",
    lat: 45.3202204,
    lon: 8.418508,
  },
  {
    art_state_id: "AST8D2A8A4765",
    state_code: "OR",
    name: "Province of Oristano",
    country_code: "IT",
    lat: 40.0599068,
    lon: 8.7481167,
  },
  {
    art_state_id: "ASTAED7E9268B",
    state_code: "GR",
    name: "Province of Grosseto",
    country_code: "IT",
    lat: 42.8518007,
    lon: 11.2523792,
  },
  {
    art_state_id: "AST788B6B744C",
    state_code: "IM",
    name: "Province of Imperia",
    country_code: "IT",
    lat: 43.941866,
    lon: 7.8286368,
  },
  {
    art_state_id: "AST322D9DDAFE",
    state_code: "IS",
    name: "Province of Isernia",
    country_code: "IT",
    lat: 41.5891555,
    lon: 14.1930918,
  },
  {
    art_state_id: "ASTD82007D43E",
    state_code: "NU",
    name: "Province of Nuoro",
    country_code: "IT",
    lat: 40.3286904,
    lon: 9.456155,
  },
  {
    art_state_id: "AST8B9A7FAB8A",
    state_code: "SP",
    name: "Province of La Spezia",
    country_code: "IT",
    lat: 44.2447913,
    lon: 9.7678687,
  },
  {
    art_state_id: "AST02698D59E4",
    state_code: "SU",
    name: "North Sumatra",
    country_code: "ID",
    lat: 2.1153547,
    lon: 99.5450974,
  },
  {
    art_state_id: "ASTED6A323560",
    state_code: "BE",
    name: "Bengkulu",
    country_code: "ID",
    lat: -3.7928451,
    lon: 102.2607641,
  },
  {
    art_state_id: "ASTCD4E9CEAFD",
    state_code: "KT",
    name: "Central Kalimantan",
    country_code: "ID",
    lat: -1.6814878,
    lon: 113.3823545,
  },
  {
    art_state_id: "AST6F0C7C5A99",
    state_code: "SN",
    name: "South Sulawesi",
    country_code: "ID",
    lat: -3.6687994,
    lon: 119.9740534,
  },
  {
    art_state_id: "ASTF0FB5AEEBB",
    state_code: "SG",
    name: "Southeast Sulawesi",
    country_code: "ID",
    lat: -4.14491,
    lon: 122.174605,
  },
  {
    art_state_id: "AST3EF8B97E17",
    state_code: "SM",
    name: "Sumatra",
    country_code: "ID",
    lat: -0.589724,
    lon: 101.3431058,
  },
  {
    art_state_id: "AST8713340B56",
    state_code: "PA",
    name: "Papua",
    country_code: "ID",
    lat: -5.0122202,
    lon: 141.3470159,
  },
  {
    art_state_id: "AST5F2C9840D5",
    state_code: "PB",
    name: "West Papua",
    country_code: "ID",
    lat: -1.3361154,
    lon: 133.1747162,
  },
  {
    art_state_id: "ASTAB89B7FD50",
    state_code: "MA",
    name: "Maluku",
    country_code: "ID",
    lat: -3.2384616,
    lon: 130.1452734,
  },
  {
    art_state_id: "ASTBAF380E8AF",
    state_code: "MU",
    name: "North Maluku",
    country_code: "ID",
    lat: 1.5709993,
    lon: 127.8087693,
  },
  {
    art_state_id: "AST9BF89C83C2",
    state_code: "JT",
    name: "Central Java",
    country_code: "ID",
    lat: -7.150975,
    lon: 110.1402594,
  },
  {
    art_state_id: "AST6270184BB1",
    state_code: "SL",
    name: "Sulawesi",
    country_code: "ID",
    lat: -1.8479,
    lon: 120.5279,
  },
  {
    art_state_id: "ASTCADDD18E41",
    state_code: "KI",
    name: "East Kalimantan",
    country_code: "ID",
    lat: 0.5386586,
    lon: 116.419389,
  },
  {
    art_state_id: "AST82DB7769F7",
    state_code: "JK",
    name: "Jakarta",
    country_code: "ID",
    lat: -6.2087634,
    lon: 106.845599,
  },
  {
    art_state_id: "ASTB9B54049A5",
    state_code: "KA",
    name: "Kalimantan",
    country_code: "ID",
    lat: 0.9618834,
    lon: 114.5548495,
  },
  {
    art_state_id: "ASTA330347437",
    state_code: "KR",
    name: "Riau Islands",
    country_code: "ID",
    lat: 3.9456514,
    lon: 108.1428669,
  },
  {
    art_state_id: "ASTB4E6847764",
    state_code: "SA",
    name: "North Sulawesi",
    country_code: "ID",
    lat: 0.6246932,
    lon: 123.9750018,
  },
  {
    art_state_id: "AST9FBD0F7BB0",
    state_code: "RI",
    name: "Riau",
    country_code: "ID",
    lat: 0.2933469,
    lon: 101.7068294,
  },
  {
    art_state_id: "AST5E92FB82C3",
    state_code: "BT",
    name: "Banten",
    country_code: "ID",
    lat: -6.4058172,
    lon: 106.0640179,
  },
  {
    art_state_id: "AST66FFF139F0",
    state_code: "LA",
    name: "Lampung",
    country_code: "ID",
    lat: -4.5585849,
    lon: 105.4068079,
  },
  {
    art_state_id: "AST4AF02D1145",
    state_code: "GO",
    name: "Gorontalo",
    country_code: "ID",
    lat: 0.5435442,
    lon: 123.0567693,
  },
  {
    art_state_id: "AST29B68F996F",
    state_code: "ST",
    name: "Central Sulawesi",
    country_code: "ID",
    lat: -1.4300254,
    lon: 121.4456179,
  },
  {
    art_state_id: "ASTE07356830D",
    state_code: "NB",
    name: "West Nusa Tenggara",
    country_code: "ID",
    lat: -8.6529334,
    lon: 117.3616476,
  },
  {
    art_state_id: "ASTC47CD9F86C",
    state_code: "JA",
    name: "Jambi",
    country_code: "ID",
    lat: -1.6101229,
    lon: 103.6131203,
  },
  {
    art_state_id: "AST85E918AAFD",
    state_code: "SS",
    name: "South Sumatra",
    country_code: "ID",
    lat: -3.3194374,
    lon: 103.914399,
  },
  {
    art_state_id: "ASTB73170EF1F",
    state_code: "SR",
    name: "West Sulawesi",
    country_code: "ID",
    lat: -2.8441371,
    lon: 119.2320784,
  },
  {
    art_state_id: "ASTD775C86E20",
    state_code: "NT",
    name: "East Nusa Tenggara",
    country_code: "ID",
    lat: -8.6573819,
    lon: 121.0793705,
  },
  {
    art_state_id: "ASTE5D2E18B0F",
    state_code: "KS",
    name: "South Kalimantan",
    country_code: "ID",
    lat: -3.0926415,
    lon: 115.2837585,
  },
  {
    art_state_id: "ASTF1FF057097",
    state_code: "BB",
    name: "Bangka Belitung Islands",
    country_code: "ID",
    lat: -2.7410513,
    lon: 106.4405872,
  },
  {
    art_state_id: "ASTDDD586FB5D",
    state_code: "NU",
    name: "Lesser Sunda Islands",
    country_code: "ID",
    lat: -9,
    lon: 120,
  },
  {
    art_state_id: "AST135DC0A7C3",
    state_code: "AC",
    name: "Aceh",
    country_code: "ID",
    lat: 4.695135,
    lon: 96.7493993,
  },
  {
    art_state_id: "ASTE0D215BE11",
    state_code: "ML",
    name: "Maluku Islands",
    country_code: "ID",
    lat: -2.8646166,
    lon: 129.5765974,
  },
  {
    art_state_id: "AST93F1DCCE03",
    state_code: "KU",
    name: "North Kalimantan",
    country_code: "ID",
    lat: 3.0730929,
    lon: 116.0413889,
  },
  {
    art_state_id: "AST997ADB3D7A",
    state_code: "JB",
    name: "West Java",
    country_code: "ID",
    lat: -7.090911,
    lon: 107.668887,
  },
  {
    art_state_id: "AST5B311B0E6F",
    state_code: "BA",
    name: "Bali",
    country_code: "ID",
    lat: -8.3405389,
    lon: 115.0919509,
  },
  {
    art_state_id: "ASTD06162CC26",
    state_code: "JI",
    name: "East Java",
    country_code: "ID",
    lat: -7.5360639,
    lon: 112.2384017,
  },
  {
    art_state_id: "AST6FE6A845E5",
    state_code: "SB",
    name: "West Sumatra",
    country_code: "ID",
    lat: -0.7399397,
    lon: 100.8000051,
  },
  {
    art_state_id: "ASTF29B918AD6",
    state_code: "YO",
    name: "Special Region of Yogyakarta",
    country_code: "ID",
    lat: -7.8753849,
    lon: 110.4262088,
  },
  {
    art_state_id: "ASTE2F2D825EF",
    state_code: "P",
    name: "Phoenix Islands",
    country_code: "KI",
    lat: 33.3284369,
    lon: -111.9824774,
  },
  {
    art_state_id: "AST1C79DFC8A6",
    state_code: "G",
    name: "Gilbert Islands",
    country_code: "KI",
    lat: 0.3524262,
    lon: 174.7552634,
  },
  {
    art_state_id: "AST99C9BF00C7",
    state_code: "L",
    name: "Line Islands",
    country_code: "KI",
    lat: 1.7429439,
    lon: -157.2132826,
  },
  {
    art_state_id: "AST551E6B9949",
    state_code: "PRI",
    name: "Primorsky Krai",
    country_code: "RU",
    lat: 45.0525641,
    lon: 135,
  },
  {
    art_state_id: "ASTF3EC2A7556",
    state_code: "NGR",
    name: "Novgorod Oblast",
    country_code: "RU",
    lat: 58.2427552,
    lon: 32.566519,
  },
  {
    art_state_id: "AST57CDD72F3F",
    state_code: "YEV",
    name: "Jewish Autonomous Oblast",
    country_code: "RU",
    lat: 48.4808147,
    lon: 131.7657367,
  },
  {
    art_state_id: "AST566DA76708",
    state_code: "NEN",
    name: "Nenets Autonomous Okrug",
    country_code: "RU",
    lat: 67.6078337,
    lon: 57.6338331,
  },
  {
    art_state_id: "AST274735A794",
    state_code: "ROS",
    name: "Rostov Oblast",
    country_code: "RU",
    lat: 47.6853247,
    lon: 41.8258952,
  },
  {
    art_state_id: "AST246327C8B8",
    state_code: "KHM",
    name: "Khanty-Mansi Autonomous Okrug",
    country_code: "RU",
    lat: 62.2287062,
    lon: 70.6410057,
  },
  {
    art_state_id: "ASTAE86B85538",
    state_code: "MAG",
    name: "Magadan Oblast",
    country_code: "RU",
    lat: 62.6643417,
    lon: 153.914991,
  },
  {
    art_state_id: "AST205B8EA890",
    state_code: "KYA",
    name: "Krasnoyarsk Krai",
    country_code: "RU",
    lat: 64.2479758,
    lon: 95.1104176,
  },
  {
    art_state_id: "AST63718E8D8A",
    state_code: "KR",
    name: "Republic of Karelia",
    country_code: "RU",
    lat: 63.1558702,
    lon: 32.9905552,
  },
  {
    art_state_id: "AST402FB76557",
    state_code: "BU",
    name: "Republic of Buryatia",
    country_code: "RU",
    lat: 54.8331146,
    lon: 112.406053,
  },
  {
    art_state_id: "AST3B21AEF17E",
    state_code: "MUR",
    name: "Murmansk Oblast",
    country_code: "RU",
    lat: 67.8442674,
    lon: 35.0884102,
  },
  {
    art_state_id: "ASTAD15C9781F",
    state_code: "KLU",
    name: "Kaluga Oblast",
    country_code: "RU",
    lat: 54.3872666,
    lon: 35.1889094,
  },
  {
    art_state_id: "ASTD8C003CAC7",
    state_code: "CHE",
    name: "Chelyabinsk Oblast",
    country_code: "RU",
    lat: 54.4319422,
    lon: 60.8788963,
  },
  {
    art_state_id: "AST9B015E9B2F",
    state_code: "OMS",
    name: "Omsk Oblast",
    country_code: "RU",
    lat: 55.0554669,
    lon: 73.3167342,
  },
  {
    art_state_id: "AST29CA6F3A03",
    state_code: "YAN",
    name: "Yamalo-Nenets Autonomous Okrug",
    country_code: "RU",
    lat: 66.0653057,
    lon: 76.9345193,
  },
  {
    art_state_id: "AST00BC29F452",
    state_code: "SA",
    name: "Sakha Republic",
    country_code: "RU",
    lat: 66.7613451,
    lon: 124.123753,
  },
  {
    art_state_id: "ASTF27F3603FF",
    state_code: "ARK",
    name: "Arkhangelsk",
    country_code: "RU",
    lat: 64.5458549,
    lon: 40.5505769,
  },
  {
    art_state_id: "AST880136C44B",
    state_code: "DA",
    name: "Republic of Dagestan",
    country_code: "RU",
    lat: 42.1431886,
    lon: 47.0949799,
  },
  {
    art_state_id: "ASTEDDD61E97B",
    state_code: "YAR",
    name: "Yaroslavl Oblast",
    country_code: "RU",
    lat: 57.8991523,
    lon: 38.8388633,
  },
  {
    art_state_id: "AST6654FE9B7F",
    state_code: "AD",
    name: "Republic of Adygea",
    country_code: "RU",
    lat: 44.8229155,
    lon: 40.1754463,
  },
  {
    art_state_id: "AST6E2C1D0FDC",
    state_code: "SE",
    name: "Republic of North Ossetia-Alania",
    country_code: "RU",
    lat: 43.0451302,
    lon: 44.2870972,
  },
  {
    art_state_id: "AST9A1BB04CA9",
    state_code: "BA",
    name: "Republic of Bashkortostan",
    country_code: "RU",
    lat: 54.2312172,
    lon: 56.1645257,
  },
  {
    art_state_id: "AST3029653E81",
    state_code: "KRS",
    name: "Kursk Oblast",
    country_code: "RU",
    lat: 51.7634026,
    lon: 35.3811812,
  },
  {
    art_state_id: "ASTF99CAF6F92",
    state_code: "ULY",
    name: "Ulyanovsk Oblast",
    country_code: "RU",
    lat: 53.9793357,
    lon: 47.7762425,
  },
  {
    art_state_id: "ASTB1165B49F1",
    state_code: "NIZ",
    name: "Nizhny Novgorod Oblast",
    country_code: "RU",
    lat: 55.7995159,
    lon: 44.0296769,
  },
  {
    art_state_id: "AST2498AED300",
    state_code: "AMU",
    name: "Amur Oblast",
    country_code: "RU",
    lat: 54.6035065,
    lon: 127.4801721,
  },
  {
    art_state_id: "AST7538CAF0E9",
    state_code: "CHU",
    name: "Chukotka Autonomous Okrug",
    country_code: "RU",
    lat: 65.6298355,
    lon: 171.6952159,
  },
  {
    art_state_id: "ASTF5E6B6EBCE",
    state_code: "TVE",
    name: "Tver Oblast",
    country_code: "RU",
    lat: 57.0021654,
    lon: 33.9853142,
  },
  {
    art_state_id: "ASTBFFDDA7DB0",
    state_code: "TA",
    name: "Republic of Tatarstan",
    country_code: "RU",
    lat: 55.1802364,
    lon: 50.7263945,
  },
  {
    art_state_id: "AST0D09E9AFC8",
    state_code: "SAM",
    name: "Samara Oblast",
    country_code: "RU",
    lat: 53.4183839,
    lon: 50.4725528,
  },
  {
    art_state_id: "AST6648AE1C1D",
    state_code: "PSK",
    name: "Pskov Oblast",
    country_code: "RU",
    lat: 56.7708599,
    lon: 29.094009,
  },
  {
    art_state_id: "AST8411CC8D42",
    state_code: "IVA",
    name: "Ivanovo Oblast",
    country_code: "RU",
    lat: 57.1056854,
    lon: 41.4830084,
  },
  {
    art_state_id: "ASTCAE71CBF4D",
    state_code: "KAM",
    name: "Kamchatka Krai",
    country_code: "RU",
    lat: 61.4343981,
    lon: 166.7884131,
  },
  {
    art_state_id: "ASTC4A5EF75D1",
    state_code: "AST",
    name: "Astrakhan Oblast",
    country_code: "RU",
    lat: 46.1321166,
    lon: 48.0610115,
  },
  {
    art_state_id: "ASTE8CF1BE91D",
    state_code: "BRY",
    name: "Bryansk Oblast",
    country_code: "RU",
    lat: 53.0408599,
    lon: 33.26909,
  },
  {
    art_state_id: "AST6DC1EA3373",
    state_code: "STA",
    name: "Stavropol Krai",
    country_code: "RU",
    lat: 44.6680993,
    lon: 43.520214,
  },
  {
    art_state_id: "AST6A7576A151",
    state_code: "KC",
    name: "Karachay-Cherkess Republic",
    country_code: "RU",
    lat: 43.8845143,
    lon: 41.7303939,
  },
  {
    art_state_id: "ASTCA1AC66967",
    state_code: "ME",
    name: "Mari El Republic",
    country_code: "RU",
    lat: 56.438457,
    lon: 47.9607758,
  },
  {
    art_state_id: "AST8BCC3E7479",
    state_code: "PER",
    name: "Perm Krai",
    country_code: "RU",
    lat: 58.8231929,
    lon: 56.5872481,
  },
  {
    art_state_id: "AST9F8D7BF887",
    state_code: "TOM",
    name: "Tomsk Oblast",
    country_code: "RU",
    lat: 58.8969882,
    lon: 82.67655,
  },
  {
    art_state_id: "ASTC123A5F1B0",
    state_code: "KHA",
    name: "Khabarovsk Krai",
    country_code: "RU",
    lat: 50.5888431,
    lon: 135,
  },
  {
    art_state_id: "AST20DEF2D472",
    state_code: "VLG",
    name: "Vologda Oblast",
    country_code: "RU",
    lat: 59.8706711,
    lon: 40.6555411,
  },
  {
    art_state_id: "AST12D5757C18",
    state_code: "SAK",
    name: "Sakhalin",
    country_code: "RU",
    lat: 50.6909848,
    lon: 142.9505689,
  },
  {
    art_state_id: "ASTFAE4D537FC",
    state_code: "AL",
    name: "Altai Republic",
    country_code: "RU",
    lat: 50.6181924,
    lon: 86.2199308,
  },
  {
    art_state_id: "AST35B694D7E6",
    state_code: "KK",
    name: "Republic of Khakassia",
    country_code: "RU",
    lat: 53.0452281,
    lon: 90.3982145,
  },
  {
    art_state_id: "AST6D2CE2BBC4",
    state_code: "TAM",
    name: "Tambov Oblast",
    country_code: "RU",
    lat: 52.6416589,
    lon: 41.4216451,
  },
  {
    art_state_id: "AST36D5797B08",
    state_code: "SPE",
    name: "Saint Petersburg",
    country_code: "RU",
    lat: 59.9310584,
    lon: 30.3609096,
  },
  {
    art_state_id: "AST0C5DAFDC23",
    state_code: "IRK",
    name: "Irkutsk",
    country_code: "RU",
    lat: 52.2854834,
    lon: 104.2890222,
  },
  {
    art_state_id: "AST480E50A65F",
    state_code: "VLA",
    name: "Vladimir Oblast",
    country_code: "RU",
    lat: 56.1553465,
    lon: 40.5926685,
  },
  {
    art_state_id: "AST896B2485AA",
    state_code: "MOS",
    name: "Moscow Oblast",
    country_code: "RU",
    lat: 55.340396,
    lon: 38.2917651,
  },
  {
    art_state_id: "ASTE072057BB9",
    state_code: "KL",
    name: "Republic of Kalmykia",
    country_code: "RU",
    lat: 46.1867176,
    lon: 45,
  },
  {
    art_state_id: "AST60FC3DB2BF",
    state_code: "IN",
    name: "Republic of Ingushetia",
    country_code: "RU",
    lat: 43.4051698,
    lon: 44.8202999,
  },
  {
    art_state_id: "ASTC28C20DF56",
    state_code: "SMO",
    name: "Smolensk Oblast",
    country_code: "RU",
    lat: 54.9882994,
    lon: 32.6677378,
  },
  {
    art_state_id: "AST03221E05E2",
    state_code: "ORE",
    name: "Orenburg Oblast",
    country_code: "RU",
    lat: 51.7634026,
    lon: 54.6188188,
  },
  {
    art_state_id: "AST1D7F68DCB0",
    state_code: "SAR",
    name: "Saratov Oblast",
    country_code: "RU",
    lat: 51.8369263,
    lon: 46.7539397,
  },
  {
    art_state_id: "ASTD27831A317",
    state_code: "NVS",
    name: "Novosibirsk",
    country_code: "RU",
    lat: 54.9832693,
    lon: 82.8963831,
  },
  {
    art_state_id: "ASTEAFB523122",
    state_code: "LIP",
    name: "Lipetsk Oblast",
    country_code: "RU",
    lat: 52.5264702,
    lon: 39.2032269,
  },
  {
    art_state_id: "ASTAD1B1EF7CE",
    state_code: "KIR",
    name: "Kirov Oblast",
    country_code: "RU",
    lat: 58.4198529,
    lon: 50.2097248,
  },
  {
    art_state_id: "AST462F019A66",
    state_code: "KDA",
    name: "Krasnodar Krai",
    country_code: "RU",
    lat: 45.6415289,
    lon: 39.7055977,
  },
  {
    art_state_id: "AST74A78383A9",
    state_code: "KB",
    name: "Kabardino-Balkar Republic",
    country_code: "RU",
    lat: 43.3932469,
    lon: 43.5628498,
  },
  {
    art_state_id: "AST8B27736803",
    state_code: "CE",
    name: "Chechen Republic",
    country_code: "RU",
    lat: 43.4023301,
    lon: 45.7187468,
  },
  {
    art_state_id: "AST460BD30556",
    state_code: "SVE",
    name: "Sverdlovsk",
    country_code: "RU",
    lat: 56.8430993,
    lon: 60.6454086,
  },
  {
    art_state_id: "ASTE8446CBF09",
    state_code: "TUL",
    name: "Tula Oblast",
    country_code: "RU",
    lat: 54.163768,
    lon: 37.5649507,
  },
  {
    art_state_id: "ASTFB6E152156",
    state_code: "LEN",
    name: "Leningrad Oblast",
    country_code: "RU",
    lat: 60.0793208,
    lon: 31.8926645,
  },
  {
    art_state_id: "AST2F68D94A27",
    state_code: "KEM",
    name: "Kemerovo Oblast",
    country_code: "RU",
    lat: 54.7574648,
    lon: 87.4055288,
  },
  {
    art_state_id: "ASTD7AB83937C",
    state_code: "MO",
    name: "Republic of Mordovia",
    country_code: "RU",
    lat: 54.2369441,
    lon: 44.068397,
  },
  {
    art_state_id: "ASTB27CEC91C6",
    state_code: "KO",
    name: "Komi Republic",
    country_code: "RU",
    lat: 63.8630539,
    lon: 54.831269,
  },
  {
    art_state_id: "AST6F1B176478",
    state_code: "TY",
    name: "Tuva Republic",
    country_code: "RU",
    lat: 51.8872669,
    lon: 95.6260172,
  },
  {
    art_state_id: "AST763F414867",
    state_code: "MOW",
    name: "Moscow",
    country_code: "RU",
    lat: 55.755826,
    lon: 37.6172999,
  },
  {
    art_state_id: "AST7D2111EEC2",
    state_code: "KGD",
    name: "Kaliningrad",
    country_code: "RU",
    lat: 54.7104264,
    lon: 20.4522144,
  },
  {
    art_state_id: "AST19A6EFC6DB",
    state_code: "BEL",
    name: "Belgorod Oblast",
    country_code: "RU",
    lat: 50.7106926,
    lon: 37.7533377,
  },
  {
    art_state_id: "ASTE346E46092",
    state_code: "ZAB",
    name: "Zabaykalsky Krai",
    country_code: "RU",
    lat: 53.0928771,
    lon: 116.9676561,
  },
  {
    art_state_id: "ASTC9B227B7EF",
    state_code: "RYA",
    name: "Ryazan Oblast",
    country_code: "RU",
    lat: 54.3875964,
    lon: 41.2595661,
  },
  {
    art_state_id: "ASTA58030927E",
    state_code: "VOR",
    name: "Voronezh Oblast",
    country_code: "RU",
    lat: 50.8589713,
    lon: 39.8644374,
  },
  {
    art_state_id: "AST162D77630D",
    state_code: "TYU",
    name: "Tyumen Oblast",
    country_code: "RU",
    lat: 56.9634387,
    lon: 66.948278,
  },
  {
    art_state_id: "ASTD0AFD22287",
    state_code: "ORL",
    name: "Oryol Oblast",
    country_code: "RU",
    lat: 52.7856414,
    lon: 36.9242344,
  },
  {
    art_state_id: "ASTC12716D93A",
    state_code: "PNZ",
    name: "Penza Oblast",
    country_code: "RU",
    lat: 53.1412105,
    lon: 44.0940048,
  },
  {
    art_state_id: "AST2B23E03963",
    state_code: "KOS",
    name: "Kostroma Oblast",
    country_code: "RU",
    lat: 58.5501069,
    lon: 43.9541102,
  },
  {
    art_state_id: "AST0733552B6F",
    state_code: "ALT",
    name: "Altai Krai",
    country_code: "RU",
    lat: 51.7936298,
    lon: 82.6758596,
  },
  {
    art_state_id: "AST5BDDF9E337",
    state_code: "UA-40",
    name: "Sevastopol",
    country_code: "RU",
    lat: 44.61665,
    lon: 33.5253671,
  },
  {
    art_state_id: "ASTA1E4D44F00",
    state_code: "UD",
    name: "Udmurt Republic",
    country_code: "RU",
    lat: 57.0670218,
    lon: 53.0277948,
  },
  {
    art_state_id: "AST5A87139A23",
    state_code: "CU",
    name: "Chuvash Republic",
    country_code: "RU",
    lat: 55.5595992,
    lon: 46.9283535,
  },
  {
    art_state_id: "AST451D68D123",
    state_code: "KGN",
    name: "Kurgan Oblast",
    country_code: "RU",
    lat: 55.4481548,
    lon: 65.1180975,
  },
  {
    art_state_id: "ASTAA86668D87",
    state_code: "06",
    name: "Lomaiviti",
    country_code: "FJ",
    lat: -17.709,
    lon: 179.091,
  },
  {
    art_state_id: "AST1FC57D7C94",
    state_code: "01",
    name: "Ba",
    country_code: "FJ",
    lat: 36.0613893,
    lon: -95.8005872,
  },
  {
    art_state_id: "AST3917E06F81",
    state_code: "14",
    name: "Tailevu",
    country_code: "FJ",
    lat: -17.8269111,
    lon: 178.293248,
  },
  {
    art_state_id: "ASTA72322E3F2",
    state_code: "08",
    name: "Nadroga-Navosa",
    country_code: "FJ",
    lat: -17.9865278,
    lon: 177.658113,
  },
  {
    art_state_id: "AST12486C6D1E",
    state_code: "12",
    name: "Rewa",
    country_code: "FJ",
    lat: 34.7923517,
    lon: -82.3609264,
  },
  {
    art_state_id: "ASTA15C00C91D",
    state_code: "N",
    name: "Northern Division",
    country_code: "FJ",
    lat: 32.8768766,
    lon: -117.2156345,
  },
  {
    art_state_id: "ASTE964B5717B",
    state_code: "07",
    name: "Macuata",
    country_code: "FJ",
    lat: -16.4864922,
    lon: 179.2847251,
  },
  {
    art_state_id: "AST7B4DE431CA",
    state_code: "W",
    name: "Western Division",
    country_code: "FJ",
    lat: 42.9662198,
    lon: -78.7021134,
  },
  {
    art_state_id: "AST37FBA11A5A",
    state_code: "03",
    name: "Cakaudrove",
    country_code: "FJ",
    lat: -16.5814105,
    lon: 179.5120084,
  },
  {
    art_state_id: "AST3E3FD3C511",
    state_code: "13",
    name: "Serua",
    country_code: "FJ",
    lat: -18.1804749,
    lon: 178.050979,
  },
  {
    art_state_id: "AST93CEDF9291",
    state_code: "11",
    name: "Ra",
    country_code: "FJ",
    lat: 37.1003153,
    lon: -95.6744246,
  },
  {
    art_state_id: "AST2685CB34F2",
    state_code: "09",
    name: "Naitasiri",
    country_code: "FJ",
    lat: -17.8975754,
    lon: 178.2071598,
  },
  {
    art_state_id: "AST324B841B12",
    state_code: "10",
    name: "Namosi",
    country_code: "FJ",
    lat: -18.0864176,
    lon: 178.1291387,
  },
  {
    art_state_id: "ASTC57C4B9FD8",
    state_code: "C",
    name: "Central Division",
    country_code: "FJ",
    lat: 34.0440066,
    lon: -118.2472738,
  },
  {
    art_state_id: "ASTB158D6AF70",
    state_code: "02",
    name: "Bua",
    country_code: "FJ",
    lat: 43.0964584,
    lon: -89.50088,
  },
  {
    art_state_id: "AST3BA58EC1DE",
    state_code: "R",
    name: "Rotuma",
    country_code: "FJ",
    lat: -12.5025069,
    lon: 177.0724164,
  },
  {
    art_state_id: "ASTC9C4A03AC0",
    state_code: "E",
    name: "Eastern Division",
    country_code: "FJ",
    lat: 32.8094305,
    lon: -117.1289937,
  },
  {
    art_state_id: "AST29C5B9E583",
    state_code: "05",
    name: "Lau",
    country_code: "FJ",
    lat: 31.6687015,
    lon: -106.3955763,
  },
  {
    art_state_id: "AST00D4EC6523",
    state_code: "04",
    name: "Kadavu",
    country_code: "FJ",
    lat: -19.0127122,
    lon: 178.1876676,
  },
  {
    art_state_id: "ASTBFF2941301",
    state_code: "15",
    name: "Labuan",
    country_code: "MY",
    lat: 5.2831456,
    lon: 115.230825,
  },
  {
    art_state_id: "ASTD7C11CD472",
    state_code: "12",
    name: "Sabah",
    country_code: "MY",
    lat: 5.9788398,
    lon: 116.0753199,
  },
  {
    art_state_id: "ASTB10713CF09",
    state_code: "13",
    name: "Sarawak",
    country_code: "MY",
    lat: 1.5532783,
    lon: 110.3592127,
  },
  {
    art_state_id: "ASTA4B70AE9A2",
    state_code: "09",
    name: "Perlis",
    country_code: "MY",
    lat: 29.9227094,
    lon: -90.1228559,
  },
  {
    art_state_id: "AST206328791A",
    state_code: "07",
    name: "Penang",
    country_code: "MY",
    lat: 5.4163935,
    lon: 100.3326786,
  },
  {
    art_state_id: "ASTC04E15B6E2",
    state_code: "06",
    name: "Pahang",
    country_code: "MY",
    lat: 3.8126318,
    lon: 103.3256204,
  },
  {
    art_state_id: "AST5A54C2E459",
    state_code: "04",
    name: "Malacca",
    country_code: "MY",
    lat: 2.189594,
    lon: 102.2500868,
  },
  {
    art_state_id: "ASTEE81D3E7A4",
    state_code: "11",
    name: "Terengganu",
    country_code: "MY",
    lat: 5.3116916,
    lon: 103.1324154,
  },
  {
    art_state_id: "ASTD5778A135D",
    state_code: "08",
    name: "Perak",
    country_code: "MY",
    lat: 4.5921126,
    lon: 101.090109,
  },
  {
    art_state_id: "ASTD32D1ACA35",
    state_code: "10",
    name: "Selangor",
    country_code: "MY",
    lat: 3.0738379,
    lon: 101.5183469,
  },
  {
    art_state_id: "ASTD1944BAF4A",
    state_code: "16",
    name: "Putrajaya",
    country_code: "MY",
    lat: 2.926361,
    lon: 101.696445,
  },
  {
    art_state_id: "ASTAD3C6DDCDE",
    state_code: "03",
    name: "Kelantan",
    country_code: "MY",
    lat: 6.1253969,
    lon: 102.238071,
  },
  {
    art_state_id: "AST97918A8D6D",
    state_code: "02",
    name: "Kedah",
    country_code: "MY",
    lat: 6.1183964,
    lon: 100.3684595,
  },
  {
    art_state_id: "AST3ADAB2F3D2",
    state_code: "05",
    name: "Negeri Sembilan",
    country_code: "MY",
    lat: 2.7258058,
    lon: 101.9423782,
  },
  {
    art_state_id: "ASTC149711459",
    state_code: "14",
    name: "Kuala Lumpur",
    country_code: "MY",
    lat: 3.139003,
    lon: 101.686855,
  },
  {
    art_state_id: "AST07461CAAFA",
    state_code: "01",
    name: "Johor",
    country_code: "MY",
    lat: 1.4853682,
    lon: 103.7618154,
  },
  {
    art_state_id: "ASTBA6AA2EE79",
    state_code: "ME",
    name: "Mashonaland East Province",
    country_code: "ZW",
    lat: -18.5871642,
    lon: 31.2626366,
  },
  {
    art_state_id: "ASTD4B0C1FF1F",
    state_code: "MS",
    name: "Matabeleland South Province",
    country_code: "ZW",
    lat: -21.052337,
    lon: 29.0459927,
  },
  {
    art_state_id: "AST31402DD44D",
    state_code: "MW",
    name: "Mashonaland West Province",
    country_code: "ZW",
    lat: -17.4851029,
    lon: 29.7889248,
  },
  {
    art_state_id: "AST4B2F071A94",
    state_code: "MN",
    name: "Matabeleland North Province",
    country_code: "ZW",
    lat: -18.5331566,
    lon: 27.5495846,
  },
  {
    art_state_id: "ASTBF053AE304",
    state_code: "MC",
    name: "Mashonaland Central Province",
    country_code: "ZW",
    lat: -16.7644295,
    lon: 31.0793705,
  },
  {
    art_state_id: "AST070BC95441",
    state_code: "BU",
    name: "Bulawayo Province",
    country_code: "ZW",
    lat: -20.1489505,
    lon: 28.5331038,
  },
  {
    art_state_id: "AST33B4CBEF11",
    state_code: "MI",
    name: "Midlands Province",
    country_code: "ZW",
    lat: -19.0552009,
    lon: 29.6035495,
  },
  {
    art_state_id: "AST9C9BE9CE00",
    state_code: "HA",
    name: "Harare Province",
    country_code: "ZW",
    lat: -17.8216288,
    lon: 31.0492259,
  },
  {
    art_state_id: "AST22CC25F0C5",
    state_code: "MA",
    name: "Manicaland",
    country_code: "ZW",
    lat: -18.9216386,
    lon: 32.174605,
  },
  {
    art_state_id: "AST36232670C2",
    state_code: "MV",
    name: "Masvingo Province",
    country_code: "ZW",
    lat: -20.6241509,
    lon: 31.2626366,
  },
  {
    art_state_id: "AST8E7B50A0B9",
    state_code: "067",
    name: "Bulgan Province",
    country_code: "MN",
    lat: 48.9690913,
    lon: 102.8831723,
  },
  {
    art_state_id: "AST2C5DE1E728",
    state_code: "037",
    name: "Darkhan-Uul Province",
    country_code: "MN",
    lat: 49.4648434,
    lon: 105.9745919,
  },
  {
    art_state_id: "AST40868A89EC",
    state_code: "061",
    name: "Dornod Province",
    country_code: "MN",
    lat: 47.4658154,
    lon: 115.392712,
  },
  {
    art_state_id: "ASTB3AAD162F1",
    state_code: "043",
    name: "Khovd Province",
    country_code: "MN",
    lat: 47.1129654,
    lon: 92.3110752,
  },
  {
    art_state_id: "AST213C61B1DD",
    state_code: "055",
    name: "Övörkhangai Province",
    country_code: "MN",
    lat: 45.7624392,
    lon: 103.0917032,
  },
  {
    art_state_id: "AST880116890D",
    state_code: "035",
    name: "Orkhon Province",
    country_code: "MN",
    lat: 49.004705,
    lon: 104.3016527,
  },
  {
    art_state_id: "AST73E72C8C9E",
    state_code: "053",
    name: "Ömnögovi Province",
    country_code: "MN",
    lat: 43.500024,
    lon: 104.2861116,
  },
  {
    art_state_id: "AST153CC95F49",
    state_code: "047",
    name: "Töv Province",
    country_code: "MN",
    lat: 47.2124056,
    lon: 106.41541,
  },
  {
    art_state_id: "AST047E843D6F",
    state_code: "071",
    name: "Bayan-Ölgii Province",
    country_code: "MN",
    lat: 48.3983254,
    lon: 89.6625915,
  },
  {
    art_state_id: "ASTCB7F9A084A",
    state_code: "059",
    name: "Dundgovi Province",
    country_code: "MN",
    lat: 45.5822786,
    lon: 106.7644209,
  },
  {
    art_state_id: "ASTEC85A0DE47",
    state_code: "046",
    name: "Uvs Province",
    country_code: "MN",
    lat: 49.6449707,
    lon: 93.2736576,
  },
  {
    art_state_id: "ASTA5D1BB456E",
    state_code: "065",
    name: "Govi-Altai Province",
    country_code: "MN",
    lat: 45.4511227,
    lon: 95.8505766,
  },
  {
    art_state_id: "AST51D06AA0B8",
    state_code: "073",
    name: "Arkhangai Province",
    country_code: "MN",
    lat: 47.8971101,
    lon: 100.7240165,
  },
  {
    art_state_id: "AST7F4A57538E",
    state_code: "039",
    name: "Khentii Province",
    country_code: "MN",
    lat: 47.6081209,
    lon: 109.9372856,
  },
  {
    art_state_id: "AST8F4FB25785",
    state_code: "041",
    name: "Khövsgöl Province",
    country_code: "MN",
    lat: 50.2204484,
    lon: 100.3213768,
  },
  {
    art_state_id: "ASTADDB10AB2A",
    state_code: "069",
    name: "Bayankhongor Province",
    country_code: "MN",
    lat: 45.1526707,
    lon: 100.1073667,
  },
  {
    art_state_id: "ASTDB427E7F3C",
    state_code: "051",
    name: "Sükhbaatar Province",
    country_code: "MN",
    lat: 46.5653163,
    lon: 113.5380836,
  },
  {
    art_state_id: "ASTC1E0FE2CA4",
    state_code: "064",
    name: "Govisümber Province",
    country_code: "MN",
    lat: 46.4762754,
    lon: 108.5570627,
  },
  {
    art_state_id: "AST9FB6371C14",
    state_code: "057",
    name: "Zavkhan Province",
    country_code: "MN",
    lat: 48.2388147,
    lon: 96.0703019,
  },
  {
    art_state_id: "ASTBB11BA16F6",
    state_code: "049",
    name: "Selenge Province",
    country_code: "MN",
    lat: 50.0059273,
    lon: 106.4434108,
  },
  {
    art_state_id: "ASTAC5707EDED",
    state_code: "063",
    name: "Dornogovi Province",
    country_code: "MN",
    lat: 43.9653889,
    lon: 109.1773459,
  },
  {
    art_state_id: "AST1CF969A639",
    state_code: "05",
    name: "Northern Province",
    country_code: "ZM",
    lat: 8.8855027,
    lon: 80.2767327,
  },
  {
    art_state_id: "ASTEB9477C183",
    state_code: "01",
    name: "Western Province",
    country_code: "ZM",
    lat: 6.9016086,
    lon: 80.0087746,
  },
  {
    art_state_id: "AST1DE512C3C8",
    state_code: "08",
    name: "Copperbelt Province",
    country_code: "ZM",
    lat: -13.0570073,
    lon: 27.5495846,
  },
  {
    art_state_id: "AST24ACA498D3",
    state_code: "06",
    name: "Northwestern Province",
    country_code: "ZM",
    lat: -13.0050258,
    lon: 24.9042208,
  },
  {
    art_state_id: "AST0D1360D906",
    state_code: "02",
    name: "Central Province",
    country_code: "ZM",
    lat: 7.2564996,
    lon: 80.7214417,
  },
  {
    art_state_id: "ASTB8C7AA3F25",
    state_code: "04",
    name: "Luapula Province",
    country_code: "ZM",
    lat: -11.564831,
    lon: 29.0459927,
  },
  {
    art_state_id: "ASTD98DFBF9D8",
    state_code: "09",
    name: "Lusaka Province",
    country_code: "ZM",
    lat: -15.3657129,
    lon: 29.2320784,
  },
  {
    art_state_id: "AST8786B6ECA4",
    state_code: "10",
    name: "Muchinga Province",
    country_code: "ZM",
    lat: -15.382193,
    lon: 28.26158,
  },
  {
    art_state_id: "ASTC1017F5386",
    state_code: "07",
    name: "Southern Province",
    country_code: "ZM",
    lat: 6.237375,
    lon: 80.543845,
  },
  {
    art_state_id: "AST4B3B03CECE",
    state_code: "03",
    name: "Eastern Province",
    country_code: "ZM",
    lat: 23.1669688,
    lon: 49.3653149,
  },
  {
    art_state_id: "AST7B861B9907",
    state_code: "13",
    name: "Capital Governorate",
    country_code: "BH",
    lat: 26.4243233,
    lon: 50.47472512345479,
  },
  {
    art_state_id: "AST7A533D0C45",
    state_code: "14",
    name: "Southern Governorate",
    country_code: "BH",
    lat: 25.9381018,
    lon: 50.5756887,
  },
  {
    art_state_id: "ASTF2DCB4F19B",
    state_code: "17",
    name: "Northern Governorate",
    country_code: "BH",
    lat: 26.1551914,
    lon: 50.4825173,
  },
  {
    art_state_id: "ASTEC816426CA",
    state_code: "15",
    name: "Muharraq Governorate",
    country_code: "BH",
    lat: 26.2685653,
    lon: 50.6482517,
  },
  {
    art_state_id: "ASTA453929C42",
    state_code: "16",
    name: "Central Governorate",
    country_code: "BH",
    lat: 26.1426093,
    lon: 50.5653294,
  },
  {
    art_state_id: "AST05511C62BD",
    state_code: "RJ",
    name: "Rio de Janeiro",
    country_code: "BR",
    lat: -22.9068467,
    lon: -43.1728965,
  },
  {
    art_state_id: "AST43539F46EB",
    state_code: "MG",
    name: "Minas Gerais",
    country_code: "BR",
    lat: -18.512178,
    lon: -44.5550308,
  },
  {
    art_state_id: "AST7D4031C8AD",
    state_code: "AP",
    name: "Amapá",
    country_code: "BR",
    lat: 0.9019925,
    lon: -52.0029565,
  },
  {
    art_state_id: "ASTA24C842EFB",
    state_code: "GO",
    name: "Goiás",
    country_code: "BR",
    lat: -15.8270369,
    lon: -49.8362237,
  },
  {
    art_state_id: "AST465CB01E3E",
    state_code: "RS",
    name: "Rio Grande do Sul",
    country_code: "BR",
    lat: -30.0346316,
    lon: -51.2176986,
  },
  {
    art_state_id: "ASTC3DFA16435",
    state_code: "BA",
    name: "Bahia",
    country_code: "BR",
    lat: 26.113539,
    lon: -80.106204,
  },
  {
    art_state_id: "AST3302343589",
    state_code: "SE",
    name: "Sergipe",
    country_code: "BR",
    lat: -10.5740934,
    lon: -37.3856581,
  },
  {
    art_state_id: "AST0F482DCB4C",
    state_code: "AM",
    name: "Amazonas",
    country_code: "BR",
    lat: -4.479925,
    lon: -63.5185396,
  },
  {
    art_state_id: "ASTE7EDDE8FA9",
    state_code: "PB",
    name: "Paraíba",
    country_code: "BR",
    lat: -7.2399609,
    lon: -36.7819505,
  },
  {
    art_state_id: "AST299343A1F6",
    state_code: "PE",
    name: "Pernambuco",
    country_code: "BR",
    lat: -8.8137173,
    lon: -36.954107,
  },
  {
    art_state_id: "AST0EC2024BE5",
    state_code: "AL",
    name: "Alagoas",
    country_code: "BR",
    lat: -9.5713058,
    lon: -36.7819505,
  },
  {
    art_state_id: "ASTAFBC13CFEC",
    state_code: "PI",
    name: "Piauí",
    country_code: "BR",
    lat: -7.7183401,
    lon: -42.7289236,
  },
  {
    art_state_id: "ASTB10481C087",
    state_code: "PA",
    name: "Pará",
    country_code: "BR",
    lat: -1.9981271,
    lon: -54.9306152,
  },
  {
    art_state_id: "AST4808756809",
    state_code: "MS",
    name: "Mato Grosso do Sul",
    country_code: "BR",
    lat: -20.7722295,
    lon: -54.7851531,
  },
  {
    art_state_id: "AST7528445D13",
    state_code: "MT",
    name: "Mato Grosso",
    country_code: "BR",
    lat: -12.6818712,
    lon: -56.921099,
  },
  {
    art_state_id: "ASTFE399C87AE",
    state_code: "AC",
    name: "Acre",
    country_code: "BR",
    lat: 32.933052,
    lon: 35.082678,
  },
  {
    art_state_id: "ASTEB374D1AAD",
    state_code: "RO",
    name: "Rondônia",
    country_code: "BR",
    lat: -11.5057341,
    lon: -63.580611,
  },
  {
    art_state_id: "AST8DBD902034",
    state_code: "SC",
    name: "Santa Catarina",
    country_code: "BR",
    lat: -27.0628367,
    lon: -51.114965,
  },
  {
    art_state_id: "AST3ABBF7F525",
    state_code: "MA",
    name: "Maranhão",
    country_code: "BR",
    lat: -4.9609498,
    lon: -45.2744159,
  },
  {
    art_state_id: "AST3768D06822",
    state_code: "CE",
    name: "Ceará",
    country_code: "BR",
    lat: -5.4983977,
    lon: -39.3206241,
  },
  {
    art_state_id: "AST7F12A18BEE",
    state_code: "DF",
    name: "Federal District",
    country_code: "BR",
    lat: -15.7997654,
    lon: -47.8644715,
  },
  {
    art_state_id: "ASTE46E98986F",
    state_code: "ES",
    name: "Espírito Santo",
    country_code: "BR",
    lat: -19.1834229,
    lon: -40.3088626,
  },
  {
    art_state_id: "AST59652103A7",
    state_code: "RN",
    name: "Rio Grande do Norte",
    country_code: "BR",
    lat: -5.4025803,
    lon: -36.954107,
  },
  {
    art_state_id: "ASTAAACBEEBF7",
    state_code: "TO",
    name: "Tocantins",
    country_code: "BR",
    lat: -10.17528,
    lon: -48.2982474,
  },
  {
    art_state_id: "ASTF0D18A7A17",
    state_code: "SP",
    name: "São Paulo",
    country_code: "BR",
    lat: -23.5505199,
    lon: -46.6333094,
  },
  {
    art_state_id: "AST43EDA55BAA",
    state_code: "PR",
    name: "Paraná",
    country_code: "BR",
    lat: -25.2520888,
    lon: -52.0215415,
  },
  {
    art_state_id: "AST06940E83FE",
    state_code: "AG",
    name: "Aragatsotn Region",
    country_code: "AM",
    lat: 40.3347301,
    lon: 44.3748296,
  },
  {
    art_state_id: "ASTDA340C5257",
    state_code: "AR",
    name: "Ararat Province",
    country_code: "AM",
    lat: 39.9139415,
    lon: 44.7200004,
  },
  {
    art_state_id: "AST26518D0041",
    state_code: "VD",
    name: "Vayots Dzor Region",
    country_code: "AM",
    lat: 39.7641996,
    lon: 45.3337528,
  },
  {
    art_state_id: "AST8A75F4B050",
    state_code: "AV",
    name: "Armavir Region",
    country_code: "AM",
    lat: 40.1554631,
    lon: 44.0372446,
  },
  {
    art_state_id: "ASTD36D9362F3",
    state_code: "SU",
    name: "Syunik Province",
    country_code: "AM",
    lat: 39.5133112,
    lon: 46.3393234,
  },
  {
    art_state_id: "AST0B63F7C89A",
    state_code: "GR",
    name: "Gegharkunik Province",
    country_code: "AM",
    lat: 40.3526426,
    lon: 45.1260414,
  },
  {
    art_state_id: "ASTF2ED7307E4",
    state_code: "LO",
    name: "Lori Region",
    country_code: "AM",
    lat: 40.9698452,
    lon: 44.4900138,
  },
  {
    art_state_id: "AST340FE62E8F",
    state_code: "ER",
    name: "Yerevan",
    country_code: "AM",
    lat: 40.1872023,
    lon: 44.515209,
  },
  {
    art_state_id: "ASTB86C58CE42",
    state_code: "SH",
    name: "Shirak Region",
    country_code: "AM",
    lat: 40.9630814,
    lon: 43.8102461,
  },
  {
    art_state_id: "AST0C54BF4444",
    state_code: "TV",
    name: "Tavush Region",
    country_code: "AM",
    lat: 40.8866296,
    lon: 45.339349,
  },
  {
    art_state_id: "AST95524CE33D",
    state_code: "KT",
    name: "Kotayk Region",
    country_code: "AM",
    lat: 40.5410214,
    lon: 44.7690148,
  },
  {
    art_state_id: "AST207749DA87",
    state_code: "H",
    name: "Cojedes",
    country_code: "VE",
    lat: 9.3816682,
    lon: -68.3339275,
  },
  {
    art_state_id: "ASTD9BE766075",
    state_code: "I",
    name: "Falcón",
    country_code: "VE",
    lat: 11.1810674,
    lon: -69.8597406,
  },
  {
    art_state_id: "ASTEB232F676C",
    state_code: "P",
    name: "Portuguesa",
    country_code: "VE",
    lat: 9.0943999,
    lon: -69.097023,
  },
  {
    art_state_id: "AST868F38E7BF",
    state_code: "M",
    name: "Miranda",
    country_code: "VE",
    lat: 42.3519383,
    lon: -71.5290766,
  },
  {
    art_state_id: "AST2E6F6F1A0B",
    state_code: "K",
    name: "Lara",
    country_code: "VE",
    lat: 33.9822165,
    lon: -118.1322747,
  },
  {
    art_state_id: "ASTB009368827",
    state_code: "F",
    name: "Bolívar",
    country_code: "VE",
    lat: 37.6144838,
    lon: -93.4104749,
  },
  {
    art_state_id: "ASTF408114EBB",
    state_code: "G",
    name: "Carabobo",
    country_code: "VE",
    lat: 10.1176433,
    lon: -68.0477509,
  },
  {
    art_state_id: "AST243E0AB30B",
    state_code: "U",
    name: "Yaracuy",
    country_code: "VE",
    lat: 10.339389,
    lon: -68.8108849,
  },
  {
    art_state_id: "AST2C2B939FAD",
    state_code: "V",
    name: "Zulia",
    country_code: "VE",
    lat: 10.2910237,
    lon: -72.1416132,
  },
  {
    art_state_id: "ASTABA980111C",
    state_code: "T",
    name: "Trujillo",
    country_code: "VE",
    lat: 36.6734343,
    lon: -121.6287588,
  },
  {
    art_state_id: "AST76972212BD",
    state_code: "Z",
    name: "Amazonas",
    country_code: "VE",
    lat: -3.4168427,
    lon: -65.8560646,
  },
  {
    art_state_id: "AST260998C371",
    state_code: "J",
    name: "Guárico",
    country_code: "VE",
    lat: 8.7489309,
    lon: -66.2367172,
  },
  {
    art_state_id: "ASTBDDF733925",
    state_code: "W",
    name: "Federal Dependencies of Venezuela",
    country_code: "VE",
    lat: 10.9377053,
    lon: -65.3569573,
  },
  {
    art_state_id: "ASTAB7DDBBB99",
    state_code: "D",
    name: "Aragua",
    country_code: "VE",
    lat: 10.0635758,
    lon: -67.2847875,
  },
  {
    art_state_id: "AST46AAD29618",
    state_code: "S",
    name: "Táchira",
    country_code: "VE",
    lat: 7.9137001,
    lon: -72.1416132,
  },
  {
    art_state_id: "AST1E6B153E65",
    state_code: "E",
    name: "Barinas",
    country_code: "VE",
    lat: 8.6231498,
    lon: -70.2371045,
  },
  {
    art_state_id: "AST35264EF78D",
    state_code: "B",
    name: "Anzoátegui",
    country_code: "VE",
    lat: 8.5913073,
    lon: -63.9586111,
  },
  {
    art_state_id: "ASTF4E2BEBB29",
    state_code: "Y",
    name: "Delta Amacuro",
    country_code: "VE",
    lat: 8.8499307,
    lon: -61.1403196,
  },
  {
    art_state_id: "AST0F9A9998E4",
    state_code: "O",
    name: "Nueva Esparta",
    country_code: "VE",
    lat: 10.9970723,
    lon: -63.9113296,
  },
  {
    art_state_id: "ASTF75A635802",
    state_code: "L",
    name: "Mérida",
    country_code: "VE",
    lat: 20.9673702,
    lon: -89.5925857,
  },
  {
    art_state_id: "AST85B561718A",
    state_code: "N",
    name: "Monagas",
    country_code: "VE",
    lat: 9.3241652,
    lon: -63.0147578,
  },
  {
    art_state_id: "ASTDC8271A592",
    state_code: "X",
    name: "Vargas",
    country_code: "VE",
    lat: 29.3052268,
    lon: -94.7913854,
  },
  {
    art_state_id: "AST13CF3EE75C",
    state_code: "R",
    name: "Sucre",
    country_code: "VE",
    lat: -19.035345,
    lon: -65.2592128,
  },
  {
    art_state_id: "ASTF09D06CC6C",
    state_code: "2",
    name: "Carinthia",
    country_code: "AT",
    lat: 46.722203,
    lon: 14.1805882,
  },
  {
    art_state_id: "AST7CF0921161",
    state_code: "4",
    name: "Upper Austria",
    country_code: "AT",
    lat: 48.025854,
    lon: 13.9723665,
  },
  {
    art_state_id: "ASTC8EDBAE6BC",
    state_code: "6",
    name: "Styria",
    country_code: "AT",
    lat: 47.3593442,
    lon: 14.4699827,
  },
  {
    art_state_id: "AST8ADAA6B074",
    state_code: "9",
    name: "Vienna",
    country_code: "AT",
    lat: 48.2081743,
    lon: 16.3738189,
  },
  {
    art_state_id: "AST096576C220",
    state_code: "5",
    name: "Salzburg",
    country_code: "AT",
    lat: 47.80949,
    lon: 13.05501,
  },
  {
    art_state_id: "AST4DCA7CC12D",
    state_code: "1",
    name: "Burgenland",
    country_code: "AT",
    lat: 47.1537165,
    lon: 16.2688797,
  },
  {
    art_state_id: "AST532C1707CA",
    state_code: "8",
    name: "Vorarlberg",
    country_code: "AT",
    lat: 47.2497427,
    lon: 9.9797373,
  },
  {
    art_state_id: "AST0CB12E1CAF",
    state_code: "7",
    name: "Tyrol",
    country_code: "AT",
    lat: 47.2537414,
    lon: 11.601487,
  },
  {
    art_state_id: "AST5F6F79A4E4",
    state_code: "3",
    name: "Lower Austria",
    country_code: "AT",
    lat: 48.108077,
    lon: 15.8049558,
  },
  {
    art_state_id: "AST593DBB9A50",
    state_code: "2",
    name: "Mid-Western Region",
    country_code: "NP",
    lat: 38.4111841,
    lon: -90.3832098,
  },
  {
    art_state_id: "ASTB909AEAA73",
    state_code: "3",
    name: "Western Region",
    country_code: "NP",
    lat: 28.1083929,
    lon: 84.0917139,
  },
  {
    art_state_id: "AST6A0C2C1C3A",
    state_code: "5",
    name: "Far-Western Development Region",
    country_code: "NP",
    lat: 29.2987871,
    lon: 80.9871074,
  },
  {
    art_state_id: "AST6316D60BAF",
    state_code: "4",
    name: "Eastern Development Region",
    country_code: "NP",
    lat: 27.3309072,
    lon: 87.0624261,
  },
  {
    art_state_id: "ASTF307AB232D",
    state_code: "ME",
    name: "Mechi Zone",
    country_code: "NP",
    lat: 26.8760007,
    lon: 87.9334803,
  },
  {
    art_state_id: "AST0FF8C54709",
    state_code: "BH",
    name: "Bheri Zone",
    country_code: "NP",
    lat: 28.517456,
    lon: 81.7787021,
  },
  {
    art_state_id: "AST3949240C8E",
    state_code: "KO",
    name: "Kosi Zone",
    country_code: "NP",
    lat: 27.0536524,
    lon: 87.3016132,
  },
  {
    art_state_id: "AST2957E8172B",
    state_code: "1",
    name: "Central Region",
    country_code: "NP",
    lat: 28.1083929,
    lon: 84.0917139,
  },
  {
    art_state_id: "AST62F6FDC9C7",
    state_code: "LU",
    name: "Lumbini Zone",
    country_code: "NP",
    lat: 27.45,
    lon: 83.25,
  },
  {
    art_state_id: "ASTF66DB38786",
    state_code: "NA",
    name: "Narayani Zone",
    country_code: "NP",
    lat: 27.3611766,
    lon: 84.8567932,
  },
  {
    art_state_id: "AST9882C488A2",
    state_code: "JA",
    name: "Janakpur Zone",
    country_code: "NP",
    lat: 27.2110899,
    lon: 86.0121573,
  },
  {
    art_state_id: "AST48BD72ECDD",
    state_code: "RA",
    name: "Rapti Zone",
    country_code: "NP",
    lat: 28.274347,
    lon: 82.3885783,
  },
  {
    art_state_id: "AST209425AAD9",
    state_code: "SE",
    name: "Seti Zone",
    country_code: "NP",
    lat: 29.6905427,
    lon: 81.3399414,
  },
  {
    art_state_id: "ASTAFBE48B520",
    state_code: "KA",
    name: "Karnali Zone",
    country_code: "NP",
    lat: 29.3862555,
    lon: 82.3885783,
  },
  {
    art_state_id: "ASTE6C56A6A41",
    state_code: "DH",
    name: "Dhaulagiri Zone",
    country_code: "NP",
    lat: 28.611176,
    lon: 83.5070203,
  },
  {
    art_state_id: "ASTCCCBD7F3F6",
    state_code: "GA",
    name: "Gandaki Zone",
    country_code: "NP",
    lat: 28.3732037,
    lon: 84.4382721,
  },
  {
    art_state_id: "ASTA542B01223",
    state_code: "BA",
    name: "Bagmati Zone",
    country_code: "NP",
    lat: 28.0367577,
    lon: 85.4375574,
  },
  {
    art_state_id: "AST400EF0840D",
    state_code: "MA",
    name: "Mahakali Zone",
    country_code: "NP",
    lat: 29.3601079,
    lon: 80.543845,
  },
  {
    art_state_id: "AST861929A28E",
    state_code: "SA",
    name: "Sagarmatha Zone",
    country_code: "NP",
    lat: 27.3238263,
    lon: 86.7416374,
  },
  {
    art_state_id: "ASTBF9F43856A",
    state_code: "UY",
    name: "Unity",
    country_code: "SS",
    lat: 37.7871276,
    lon: -122.4034079,
  },
  {
    art_state_id: "ASTEDFCBADAD7",
    state_code: "NU",
    name: "Upper Nile",
    country_code: "SS",
    lat: 9.8894202,
    lon: 32.7181375,
  },
  {
    art_state_id: "AST753344E4EE",
    state_code: "WR",
    name: "Warrap",
    country_code: "SS",
    lat: 8.0886238,
    lon: 28.6410641,
  },
  {
    art_state_id: "ASTE67D0145C4",
    state_code: "BN",
    name: "Northern Bahr el Ghazal",
    country_code: "SS",
    lat: 8.5360449,
    lon: 26.7967849,
  },
  {
    art_state_id: "AST7A374B8E46",
    state_code: "EW",
    name: "Western Equatoria",
    country_code: "SS",
    lat: 5.3471799,
    lon: 28.299435,
  },
  {
    art_state_id: "AST8347C3EC75",
    state_code: "LK",
    name: "Lakes",
    country_code: "SS",
    lat: 37.1628255,
    lon: -95.6911623,
  },
  {
    art_state_id: "AST79AC51EF6A",
    state_code: "BW",
    name: "Western Bahr el Ghazal",
    country_code: "SS",
    lat: 8.6452399,
    lon: 25.2837585,
  },
  {
    art_state_id: "AST5CE96E1828",
    state_code: "EC",
    name: "Central Equatoria",
    country_code: "SS",
    lat: 4.6144063,
    lon: 31.2626366,
  },
  {
    art_state_id: "AST779015348E",
    state_code: "EE",
    name: "Eastern Equatoria",
    country_code: "SS",
    lat: 5.0692995,
    lon: 33.438353,
  },
  {
    art_state_id: "AST96CE0D58CB",
    state_code: "JG",
    name: "Jonglei State",
    country_code: "SS",
    lat: 7.1819619,
    lon: 32.3560952,
  },
  {
    art_state_id: "ASTB0ABB3D3EF",
    state_code: "41",
    name: "Karditsa Regional Unit",
    country_code: "GR",
    lat: 39.3640258,
    lon: 21.9214049,
  },
  {
    art_state_id: "AST4890E5C7F5",
    state_code: "G",
    name: "West Greece Region",
    country_code: "GR",
    lat: 38.5115496,
    lon: 21.5706786,
  },
  {
    art_state_id: "ASTF4F01A8611",
    state_code: "54",
    name: "Thessaloniki Regional Unit",
    country_code: "GR",
    lat: 40.6400629,
    lon: 22.9444191,
  },
  {
    art_state_id: "ASTC0F023DF94",
    state_code: "12",
    name: "Arcadia Prefecture",
    country_code: "GR",
    lat: 37.5557825,
    lon: 22.3337769,
  },
  {
    art_state_id: "AST3C041B9950",
    state_code: "53",
    name: "Imathia Regional Unit",
    country_code: "GR",
    lat: 40.6060067,
    lon: 22.1430215,
  },
  {
    art_state_id: "AST608026143A",
    state_code: "56",
    name: "Kastoria Regional Unit",
    country_code: "GR",
    lat: 40.5192691,
    lon: 21.2687171,
  },
  {
    art_state_id: "AST16F5D9154D",
    state_code: "04",
    name: "Euboea",
    country_code: "GR",
    lat: 38.5236036,
    lon: 23.8584737,
  },
  {
    art_state_id: "ASTE55A773C9F",
    state_code: "51",
    name: "Grevena Prefecture",
    country_code: "GR",
    lat: 40.0837626,
    lon: 21.4273299,
  },
  {
    art_state_id: "ASTD52BE07430",
    state_code: "34",
    name: "Preveza Prefecture",
    country_code: "GR",
    lat: 38.9592649,
    lon: 20.7517155,
  },
  {
    art_state_id: "ASTEE901AEA75",
    state_code: "24",
    name: "Lefkada Regional Unit",
    country_code: "GR",
    lat: 38.8333663,
    lon: 20.7069108,
  },
  {
    art_state_id: "ASTEC9E372E1E",
    state_code: "11",
    name: "Argolis Regional Unit",
    country_code: "GR",
    lat: 38.9953683,
    lon: 21.9877132,
  },
  {
    art_state_id: "AST0DC1EEC119",
    state_code: "16",
    name: "Laconia",
    country_code: "GR",
    lat: 43.5278546,
    lon: -71.4703509,
  },
  {
    art_state_id: "AST23E3A9AA35",
    state_code: "59",
    name: "Pella Regional Unit",
    country_code: "GR",
    lat: 40.9148039,
    lon: 22.1430215,
  },
  {
    art_state_id: "AST401A2FF494",
    state_code: "C",
    name: "West Macedonia Region",
    country_code: "GR",
    lat: 40.3004058,
    lon: 21.7903559,
  },
  {
    art_state_id: "ASTC2BE016B54",
    state_code: "M",
    name: "Crete Region",
    country_code: "GR",
    lat: 35.240117,
    lon: 24.8092691,
  },
  {
    art_state_id: "ASTAC08789570",
    state_code: "D",
    name: "Epirus Region",
    country_code: "GR",
    lat: 39.5706413,
    lon: 20.7642843,
  },
  {
    art_state_id: "ASTE1305BC044",
    state_code: "57",
    name: "Kilkis Regional Unit",
    country_code: "GR",
    lat: 40.9937071,
    lon: 22.8753674,
  },
  {
    art_state_id: "ASTC38BD6D67A",
    state_code: "58",
    name: "Kozani Prefecture",
    country_code: "GR",
    lat: 40.3005586,
    lon: 21.7887737,
  },
  {
    art_state_id: "AST5729DAF50D",
    state_code: "33",
    name: "Ioannina Regional Unit",
    country_code: "GR",
    lat: 39.6650288,
    lon: 20.8537466,
  },
  {
    art_state_id: "AST0DF1DC1CAA",
    state_code: "06",
    name: "Phthiotis Prefecture",
    country_code: "GR",
    lat: 38.999785,
    lon: 22.3337769,
  },
  {
    art_state_id: "AST19B0345926",
    state_code: "94",
    name: "Chania Regional Unit",
    country_code: "GR",
    lat: 35.5138298,
    lon: 24.0180367,
  },
  {
    art_state_id: "AST2E23F5B808",
    state_code: "13",
    name: "Achaea Regional Unit",
    country_code: "GR",
    lat: 38.1158729,
    lon: 21.9522491,
  },
  {
    art_state_id: "ASTAA7E941319",
    state_code: "A",
    name: "East Macedonia and Thrace",
    country_code: "GR",
    lat: 41.1295126,
    lon: 24.8877191,
  },
  {
    art_state_id: "ASTBDF28119D1",
    state_code: "L",
    name: "South Aegean",
    country_code: "GR",
    lat: 37.0855302,
    lon: 25.1489215,
  },
  {
    art_state_id: "ASTB8DBA3E1C3",
    state_code: "J",
    name: "Peloponnese Region",
    country_code: "GR",
    lat: 37.5079472,
    lon: 22.37349,
  },
  {
    art_state_id: "ASTA9A4BA227D",
    state_code: "A2",
    name: "East Attica Regional Unit",
    country_code: "GR",
    lat: 38.2054093,
    lon: 23.8584737,
  },
  {
    art_state_id: "ASTF2E5EFF846",
    state_code: "62",
    name: "Serres Prefecture",
    country_code: "GR",
    lat: 41.0863854,
    lon: 23.5483819,
  },
  {
    art_state_id: "ASTAAD11C59E5",
    state_code: "I",
    name: "Attica Region",
    country_code: "GR",
    lat: 38.0457568,
    lon: 23.8584737,
  },
  {
    art_state_id: "ASTDDB1C0221D",
    state_code: "01",
    name: "Aetolia-Acarnania Regional Unit",
    country_code: "GR",
    lat: 38.7084386,
    lon: 21.3798928,
  },
  {
    art_state_id: "AST03956CE909",
    state_code: "22",
    name: "Corfu Prefecture",
    country_code: "GR",
    lat: 39.6249838,
    lon: 19.9223461,
  },
  {
    art_state_id: "AST65F285C65C",
    state_code: "B",
    name: "Central Macedonia",
    country_code: "GR",
    lat: 40.621173,
    lon: 23.1918021,
  },
  {
    art_state_id: "ASTB9271D5CFB",
    state_code: "03",
    name: "Boeotia Regional Unit",
    country_code: "GR",
    lat: 38.3663664,
    lon: 23.0965064,
  },
  {
    art_state_id: "AST307E307C21",
    state_code: "23",
    name: "Kefalonia Prefecture",
    country_code: "GR",
    lat: 38.1753675,
    lon: 20.5692179,
  },
  {
    art_state_id: "AST6E00E5EFC2",
    state_code: "H",
    name: "Central Greece Region",
    country_code: "GR",
    lat: 38.6043984,
    lon: 22.7152131,
  },
  {
    art_state_id: "AST1986BA92C5",
    state_code: "15",
    name: "Corinthia Regional Unit",
    country_code: "GR",
    lat: 38.9953683,
    lon: 21.9877132,
  },
  {
    art_state_id: "AST04EDA330F8",
    state_code: "52",
    name: "Drama Regional Unit",
    country_code: "GR",
    lat: 41.2340023,
    lon: 24.2390498,
  },
  {
    art_state_id: "AST40827706FE",
    state_code: "F",
    name: "Ionian Islands Region",
    country_code: "GR",
    lat: 37.9694898,
    lon: 21.3802372,
  },
  {
    art_state_id: "AST45CEEF7C90",
    state_code: "42",
    name: "Larissa Prefecture",
    country_code: "GR",
    lat: 39.6390224,
    lon: 22.4191254,
  },
  {
    art_state_id: "AST38702F9895",
    state_code: "13",
    name: "Kayin State",
    country_code: "MM",
    lat: 16.9459346,
    lon: 97.9592863,
  },
  {
    art_state_id: "AST29A5060926",
    state_code: "04",
    name: "Mandalay Region",
    country_code: "MM",
    lat: 21.5619058,
    lon: 95.8987139,
  },
  {
    art_state_id: "ASTAEEEA64800",
    state_code: "06",
    name: "Yangon Region",
    country_code: "MM",
    lat: 16.9143488,
    lon: 96.1526985,
  },
  {
    art_state_id: "ASTA0257FCF5C",
    state_code: "03",
    name: "Magway Region",
    country_code: "MM",
    lat: 19.8871386,
    lon: 94.7277528,
  },
  {
    art_state_id: "ASTD894BAEF5E",
    state_code: "14",
    name: "Chin State",
    country_code: "MM",
    lat: 22.0086978,
    lon: 93.5812692,
  },
  {
    art_state_id: "ASTD380F7DA49",
    state_code: "16",
    name: "Rakhine State",
    country_code: "MM",
    lat: 20.1040818,
    lon: 93.5812692,
  },
  {
    art_state_id: "ASTD68D5D4927",
    state_code: "17",
    name: "Shan State",
    country_code: "MM",
    lat: 22.0361985,
    lon: 98.1338558,
  },
  {
    art_state_id: "AST1F01EF4090",
    state_code: "05",
    name: "Tanintharyi Region",
    country_code: "MM",
    lat: 12.4706876,
    lon: 99.0128926,
  },
  {
    art_state_id: "AST261FABB033",
    state_code: "02",
    name: "Bago",
    country_code: "MM",
    lat: 17.3220711,
    lon: 96.4663286,
  },
  {
    art_state_id: "AST84526483FF",
    state_code: "07",
    name: "Ayeyarwady Region",
    country_code: "MM",
    lat: 17.0342125,
    lon: 95.2266675,
  },
  {
    art_state_id: "ASTB5505A0E28",
    state_code: "11",
    name: "Kachin State",
    country_code: "MM",
    lat: 25.850904,
    lon: 97.4381355,
  },
  {
    art_state_id: "AST102F5F1E6D",
    state_code: "12",
    name: "Kayah State",
    country_code: "MM",
    lat: 19.2342061,
    lon: 97.2652858,
  },
  {
    art_state_id: "ASTCD36F30CD2",
    state_code: "01",
    name: "Sagaing Region",
    country_code: "MM",
    lat: 24.428381,
    lon: 95.3939551,
  },
  {
    art_state_id: "AST09D05FED44",
    state_code: "18",
    name: "Naypyidaw Union Territory",
    country_code: "MM",
    lat: 19.9386245,
    lon: 96.1526985,
  },
  {
    art_state_id: "AST5831B6FAA8",
    state_code: "15",
    name: "Mon State",
    country_code: "MM",
    lat: 16.3003133,
    lon: 97.6982272,
  },
  {
    art_state_id: "AST8061CED10B",
    state_code: "74",
    name: "Bartın Province",
    country_code: "TR",
    lat: 41.5810509,
    lon: 32.4609794,
  },
  {
    art_state_id: "ASTBCB2EC5EDE",
    state_code: "43",
    name: "Kütahya Province",
    country_code: "TR",
    lat: 39.358137,
    lon: 29.6035495,
  },
  {
    art_state_id: "ASTEC349FFD91",
    state_code: "54",
    name: "Sakarya Province",
    country_code: "TR",
    lat: 40.788855,
    lon: 30.405954,
  },
  {
    art_state_id: "AST0857CE4361",
    state_code: "22",
    name: "Edirne Province",
    country_code: "TR",
    lat: 41.1517222,
    lon: 26.5137964,
  },
  {
    art_state_id: "ASTCC30303927",
    state_code: "65",
    name: "Van Province",
    country_code: "TR",
    lat: 38.3679417,
    lon: 43.7182787,
  },
  {
    art_state_id: "AST11FF661527",
    state_code: "12",
    name: "Bingöl Province",
    country_code: "TR",
    lat: 39.0626354,
    lon: 40.7696095,
  },
  {
    art_state_id: "AST53E084111F",
    state_code: "79",
    name: "Kilis Province",
    country_code: "TR",
    lat: 36.8204775,
    lon: 37.1687339,
  },
  {
    art_state_id: "AST1CA9FD57F9",
    state_code: "02",
    name: "Adıyaman Province",
    country_code: "TR",
    lat: 37.9078291,
    lon: 38.4849923,
  },
  {
    art_state_id: "AST02D9B46588",
    state_code: "33",
    name: "Mersin Province",
    country_code: "TR",
    lat: 36.8120858,
    lon: 34.641475,
  },
  {
    art_state_id: "AST5C0CACF517",
    state_code: "20",
    name: "Denizli Province",
    country_code: "TR",
    lat: 37.6128395,
    lon: 29.2320784,
  },
  {
    art_state_id: "ASTAC357F27C2",
    state_code: "44",
    name: "Malatya Province",
    country_code: "TR",
    lat: 38.4015057,
    lon: 37.9536298,
  },
  {
    art_state_id: "AST1A613A2F74",
    state_code: "23",
    name: "Elazığ Province",
    country_code: "TR",
    lat: 38.4964804,
    lon: 39.2199029,
  },
  {
    art_state_id: "ASTB114841E7B",
    state_code: "24",
    name: "Erzincan Province",
    country_code: "TR",
    lat: 39.7681914,
    lon: 39.0501306,
  },
  {
    art_state_id: "ASTD17C74F219",
    state_code: "05",
    name: "Amasya Province",
    country_code: "TR",
    lat: 40.6516608,
    lon: 35.9037966,
  },
  {
    art_state_id: "ASTE4CA2D2E7F",
    state_code: "49",
    name: "Muş Province",
    country_code: "TR",
    lat: 38.9461888,
    lon: 41.7538931,
  },
  {
    art_state_id: "AST1C20C21FE9",
    state_code: "16",
    name: "Bursa Province",
    country_code: "TR",
    lat: 40.0655459,
    lon: 29.2320784,
  },
  {
    art_state_id: "ASTCB13B686EE",
    state_code: "26",
    name: "Eskişehir Province",
    country_code: "TR",
    lat: 39.6329657,
    lon: 31.2626366,
  },
  {
    art_state_id: "ASTB809F2979E",
    state_code: "25",
    name: "Erzurum Province",
    country_code: "TR",
    lat: 40.0746799,
    lon: 41.6694562,
  },
  {
    art_state_id: "AST6B129C18BE",
    state_code: "76",
    name: "Iğdır Province",
    country_code: "TR",
    lat: 39.8879841,
    lon: 44.0048365,
  },
  {
    art_state_id: "AST1308353C29",
    state_code: "59",
    name: "Tekirdağ Province",
    country_code: "TR",
    lat: 41.1121227,
    lon: 27.2676116,
  },
  {
    art_state_id: "AST3DB2DE0D14",
    state_code: "18",
    name: "Çankırı Province",
    country_code: "TR",
    lat: 40.5369073,
    lon: 33.5883893,
  },
  {
    art_state_id: "AST0E3A24F16B",
    state_code: "07",
    name: "Antalya Province",
    country_code: "TR",
    lat: 37.0951672,
    lon: 31.0793705,
  },
  {
    art_state_id: "AST581B806DBF",
    state_code: "34",
    name: "Istanbul Province",
    country_code: "TR",
    lat: 41.1634302,
    lon: 28.7664408,
  },
  {
    art_state_id: "ASTB627DA159E",
    state_code: "42",
    name: "Konya Province",
    country_code: "TR",
    lat: 37.9838134,
    lon: 32.7181375,
  },
  {
    art_state_id: "ASTC4E3CC2BBB",
    state_code: "14",
    name: "Bolu Province",
    country_code: "TR",
    lat: 40.5759766,
    lon: 31.5788086,
  },
  {
    art_state_id: "AST9F621E5932",
    state_code: "19",
    name: "Çorum Province",
    country_code: "TR",
    lat: 40.4998211,
    lon: 34.5986263,
  },
  {
    art_state_id: "ASTEB4A06781D",
    state_code: "52",
    name: "Ordu Province",
    country_code: "TR",
    lat: 40.799058,
    lon: 37.3899005,
  },
  {
    art_state_id: "ASTE4CF9963F2",
    state_code: "10",
    name: "Balıkesir Province",
    country_code: "TR",
    lat: 39.7616782,
    lon: 28.1122679,
  },
  {
    art_state_id: "AST9A4365174D",
    state_code: "39",
    name: "Kırklareli Province",
    country_code: "TR",
    lat: 41.7259795,
    lon: 27.483839,
  },
  {
    art_state_id: "ASTBFB43A2148",
    state_code: "69",
    name: "Bayburt Province",
    country_code: "TR",
    lat: 40.26032,
    lon: 40.228048,
  },
  {
    art_state_id: "AST9757CB34C7",
    state_code: "71",
    name: "Kırıkkale Province",
    country_code: "TR",
    lat: 39.8876878,
    lon: 33.7555248,
  },
  {
    art_state_id: "ASTC183F210ED",
    state_code: "03",
    name: "Afyonkarahisar Province",
    country_code: "TR",
    lat: 38.7391099,
    lon: 30.7120023,
  },
  {
    art_state_id: "ASTC97CCF32F2",
    state_code: "40",
    name: "Kırşehir Province",
    country_code: "TR",
    lat: 39.2268905,
    lon: 33.9750018,
  },
  {
    art_state_id: "ASTDCFCD13D98",
    state_code: "58",
    name: "Sivas Province",
    country_code: "TR",
    lat: 39.4488039,
    lon: 37.1294497,
  },
  {
    art_state_id: "ASTFB3CBDE77A",
    state_code: "48",
    name: "Muğla Province",
    country_code: "TR",
    lat: 37.1835819,
    lon: 28.4863963,
  },
  {
    art_state_id: "AST9AC1DD7E67",
    state_code: "63",
    name: "Şanlıurfa Province",
    country_code: "TR",
    lat: 37.3569102,
    lon: 39.1543677,
  },
  {
    art_state_id: "ASTADC95981FF",
    state_code: "70",
    name: "Karaman Province",
    country_code: "TR",
    lat: 37.2436336,
    lon: 33.617577,
  },
  {
    art_state_id: "AST08FAB4691F",
    state_code: "75",
    name: "Ardahan Province",
    country_code: "TR",
    lat: 41.1112964,
    lon: 42.7831674,
  },
  {
    art_state_id: "ASTE21C0581E8",
    state_code: "28",
    name: "Giresun Province",
    country_code: "TR",
    lat: 40.6461672,
    lon: 38.5935511,
  },
  {
    art_state_id: "ASTFBA766FCD4",
    state_code: "09",
    name: "Aydın Province",
    country_code: "TR",
    lat: 37.8117033,
    lon: 28.4863963,
  },
  {
    art_state_id: "AST7437E426D9",
    state_code: "66",
    name: "Yozgat Province",
    country_code: "TR",
    lat: 39.7271979,
    lon: 35.1077858,
  },
  {
    art_state_id: "AST2B259D52CD",
    state_code: "51",
    name: "Niğde Province",
    country_code: "TR",
    lat: 38.0993086,
    lon: 34.6856509,
  },
  {
    art_state_id: "AST33BE319C35",
    state_code: "30",
    name: "Hakkâri Province",
    country_code: "TR",
    lat: 37.4459319,
    lon: 43.7449841,
  },
  {
    art_state_id: "AST67519F998F",
    state_code: "08",
    name: "Artvin Province",
    country_code: "TR",
    lat: 41.078664,
    lon: 41.7628223,
  },
  {
    art_state_id: "AST36A6F85CBB",
    state_code: "62",
    name: "Tunceli Province",
    country_code: "TR",
    lat: 39.3073554,
    lon: 39.4387778,
  },
  {
    art_state_id: "AST4C72902D9D",
    state_code: "04",
    name: "Ağrı Province",
    country_code: "TR",
    lat: 39.6269218,
    lon: 43.0215965,
  },
  {
    art_state_id: "AST4295DD5734",
    state_code: "72",
    name: "Batman Province",
    country_code: "TR",
    lat: 37.8362496,
    lon: 41.3605739,
  },
  {
    art_state_id: "AST994392FB7F",
    state_code: "41",
    name: "Kocaeli Province",
    country_code: "TR",
    lat: 40.8532704,
    lon: 29.8815203,
  },
  {
    art_state_id: "AST252167B5DF",
    state_code: "50",
    name: "Nevşehir Province",
    country_code: "TR",
    lat: 38.6939399,
    lon: 34.6856509,
  },
  {
    art_state_id: "AST524C3DF8B9",
    state_code: "37",
    name: "Kastamonu Province",
    country_code: "TR",
    lat: 41.4103863,
    lon: 33.6998334,
  },
  {
    art_state_id: "ASTB0910B3FB5",
    state_code: "45",
    name: "Manisa Province",
    country_code: "TR",
    lat: 38.8419373,
    lon: 28.1122679,
  },
  {
    art_state_id: "ASTA2077D84A4",
    state_code: "60",
    name: "Tokat Province",
    country_code: "TR",
    lat: 40.3902713,
    lon: 36.6251863,
  },
  {
    art_state_id: "ASTFE70C51FE8",
    state_code: "38",
    name: "Kayseri Province",
    country_code: "TR",
    lat: 38.6256854,
    lon: 35.7406882,
  },
  {
    art_state_id: "ASTD79DC814F1",
    state_code: "64",
    name: "Uşak Province",
    country_code: "TR",
    lat: 38.5431319,
    lon: 29.2320784,
  },
  {
    art_state_id: "AST8733F49652",
    state_code: "81",
    name: "Düzce Province",
    country_code: "TR",
    lat: 40.8770531,
    lon: 31.3192713,
  },
  {
    art_state_id: "ASTBBEA52EB06",
    state_code: "27",
    name: "Gaziantep Province",
    country_code: "TR",
    lat: 37.0763882,
    lon: 37.3827234,
  },
  {
    art_state_id: "AST0FFFD532C7",
    state_code: "29",
    name: "Gümüşhane Province",
    country_code: "TR",
    lat: 40.2803673,
    lon: 39.3143253,
  },
  {
    art_state_id: "ASTA4EF5D3363",
    state_code: "35",
    name: "İzmir Province",
    country_code: "TR",
    lat: 38.3591693,
    lon: 27.2676116,
  },
  {
    art_state_id: "AST6444A3E8AD",
    state_code: "61",
    name: "Trabzon Province",
    country_code: "TR",
    lat: 40.799241,
    lon: 39.5847944,
  },
  {
    art_state_id: "AST66E07ACC5C",
    state_code: "56",
    name: "Siirt Province",
    country_code: "TR",
    lat: 37.8658862,
    lon: 42.1494523,
  },
  {
    art_state_id: "ASTC9690CEA7F",
    state_code: "36",
    name: "Kars Province",
    country_code: "TR",
    lat: 40.2807636,
    lon: 42.9919527,
  },
  {
    art_state_id: "AST89B52B7A77",
    state_code: "15",
    name: "Burdur Province",
    country_code: "TR",
    lat: 37.4612669,
    lon: 30.0665236,
  },
  {
    art_state_id: "AST55F726312E",
    state_code: "68",
    name: "Aksaray Province",
    country_code: "TR",
    lat: 38.3352043,
    lon: 33.9750018,
  },
  {
    art_state_id: "AST704C97969D",
    state_code: "31",
    name: "Hatay Province",
    country_code: "TR",
    lat: 36.4018488,
    lon: 36.3498097,
  },
  {
    art_state_id: "ASTDADE68DD72",
    state_code: "01",
    name: "Adana Province",
    country_code: "TR",
    lat: 37.2612315,
    lon: 35.3905046,
  },
  {
    art_state_id: "ASTE604BBEB3D",
    state_code: "67",
    name: "Zonguldak Province",
    country_code: "TR",
    lat: 41.3124917,
    lon: 31.8598251,
  },
  {
    art_state_id: "AST44CA55B6BB",
    state_code: "80",
    name: "Osmaniye Province",
    country_code: "TR",
    lat: 37.2130258,
    lon: 36.1762615,
  },
  {
    art_state_id: "ASTFE84DD5252",
    state_code: "13",
    name: "Bitlis Province",
    country_code: "TR",
    lat: 38.6523133,
    lon: 42.4202028,
  },
  {
    art_state_id: "AST766A89C3E3",
    state_code: "17",
    name: "Çanakkale Province",
    country_code: "TR",
    lat: 40.0510104,
    lon: 26.9852422,
  },
  {
    art_state_id: "ASTCA6DAE0305",
    state_code: "06",
    name: "Ankara Province",
    country_code: "TR",
    lat: 39.7805245,
    lon: 32.7181375,
  },
  {
    art_state_id: "AST32192C71A8",
    state_code: "77",
    name: "Yalova Province",
    country_code: "TR",
    lat: 40.5775986,
    lon: 29.2088303,
  },
  {
    art_state_id: "AST17643F3F2B",
    state_code: "53",
    name: "Rize Province",
    country_code: "TR",
    lat: 40.9581497,
    lon: 40.9226985,
  },
  {
    art_state_id: "AST7EF080FEFB",
    state_code: "55",
    name: "Samsun Province",
    country_code: "TR",
    lat: 41.1864859,
    lon: 36.1322678,
  },
  {
    art_state_id: "AST09BA9DF8CA",
    state_code: "11",
    name: "Bilecik Province",
    country_code: "TR",
    lat: 40.0566555,
    lon: 30.0665236,
  },
  {
    art_state_id: "AST22B31838E2",
    state_code: "32",
    name: "Isparta Province",
    country_code: "TR",
    lat: 38.0211464,
    lon: 31.0793705,
  },
  {
    art_state_id: "AST41B3D0A37E",
    state_code: "78",
    name: "Karabük Province",
    country_code: "TR",
    lat: 41.187489,
    lon: 32.7417419,
  },
  {
    art_state_id: "ASTB1C0F7E3AB",
    state_code: "47",
    name: "Mardin Province",
    country_code: "TR",
    lat: 37.3442929,
    lon: 40.6196487,
  },
  {
    art_state_id: "AST32E4FCA5A4",
    state_code: "73",
    name: "Şırnak Province",
    country_code: "TR",
    lat: 37.4187481,
    lon: 42.4918338,
  },
  {
    art_state_id: "ASTD976FDE0F7",
    state_code: "21",
    name: "Diyarbakır Province",
    country_code: "TR",
    lat: 38.1066372,
    lon: 40.5426896,
  },
  {
    art_state_id: "AST9350CC7DA3",
    state_code: "46",
    name: "Kahramanmaraş Province",
    country_code: "TR",
    lat: 37.7503036,
    lon: 36.954107,
  },
  {
    art_state_id: "ASTD7F3D92C1B",
    state_code: "11",
    name: "Lisbon District",
    country_code: "PT",
    lat: 38.7223263,
    lon: -9.1392714,
  },
  {
    art_state_id: "AST564AA199FE",
    state_code: "04",
    name: "Bragança District",
    country_code: "PT",
    lat: 41.8061652,
    lon: -6.7567427,
  },
  {
    art_state_id: "AST3ED7128FC9",
    state_code: "02",
    name: "Beja District",
    country_code: "PT",
    lat: 37.9687786,
    lon: -7.87216,
  },
  {
    art_state_id: "ASTCCFB3E7626",
    state_code: "30",
    name: "Madeira",
    country_code: "PT",
    lat: 32.7607074,
    lon: -16.9594723,
  },
  {
    art_state_id: "AST9AD5331CBD",
    state_code: "12",
    name: "Portalegre District",
    country_code: "PT",
    lat: 39.2967086,
    lon: -7.4284755,
  },
  {
    art_state_id: "AST90F1A547BA",
    state_code: "20",
    name: "Azores",
    country_code: "PT",
    lat: 37.7412488,
    lon: -25.6755944,
  },
  {
    art_state_id: "AST29F8CB224C",
    state_code: "17",
    name: "Vila Real District",
    country_code: "PT",
    lat: 41.3003527,
    lon: -7.7457274,
  },
  {
    art_state_id: "AST45ED9029F1",
    state_code: "01",
    name: "Aveiro District",
    country_code: "PT",
    lat: 40.7209023,
    lon: -8.5721016,
  },
  {
    art_state_id: "AST5CC94187F8",
    state_code: "07",
    name: "Évora District",
    country_code: "PT",
    lat: 38.5744468,
    lon: -7.9076553,
  },
  {
    art_state_id: "AST749CDF2D25",
    state_code: "18",
    name: "Viseu District",
    country_code: "PT",
    lat: 40.6588424,
    lon: -7.914756,
  },
  {
    art_state_id: "AST8533EFD5E7",
    state_code: "14",
    name: "Santarém District",
    country_code: "PT",
    lat: 39.2366687,
    lon: -8.6859944,
  },
  {
    art_state_id: "AST5400FDEF99",
    state_code: "08",
    name: "Faro District",
    country_code: "PT",
    lat: 37.0193548,
    lon: -7.9304397,
  },
  {
    art_state_id: "ASTDBB2391F88",
    state_code: "10",
    name: "Leiria District",
    country_code: "PT",
    lat: 39.7709532,
    lon: -8.7921836,
  },
  {
    art_state_id: "AST4041C7BCAE",
    state_code: "05",
    name: "Castelo Branco District",
    country_code: "PT",
    lat: 39.8631323,
    lon: -7.4814163,
  },
  {
    art_state_id: "AST33B5386383",
    state_code: "15",
    name: "Setúbal District",
    country_code: "PT",
    lat: 38.5240933,
    lon: -8.8925876,
  },
  {
    art_state_id: "AST5E487D56FC",
    state_code: "13",
    name: "Porto District",
    country_code: "PT",
    lat: 41.1476629,
    lon: -8.6078973,
  },
  {
    art_state_id: "AST1EBBA748E9",
    state_code: "03",
    name: "Braga District",
    country_code: "PT",
    lat: 41.550388,
    lon: -8.4261301,
  },
  {
    art_state_id: "AST09185EA6A6",
    state_code: "16",
    name: "Viana do Castelo District",
    country_code: "PT",
    lat: 41.6918046,
    lon: -8.834451,
  },
  {
    art_state_id: "AST37E8407077",
    state_code: "06",
    name: "Coimbra District",
    country_code: "PT",
    lat: 40.2057994,
    lon: -8.41369,
  },
  {
    art_state_id: "AST5937A53646",
    state_code: "ZJ",
    name: "Zhejiang",
    country_code: "CN",
    lat: 29.1416432,
    lon: 119.7889248,
  },
  {
    art_state_id: "AST9DCD9342C6",
    state_code: "FJ",
    name: "Fujian",
    country_code: "CN",
    lat: 26.4836842,
    lon: 117.9249002,
  },
  {
    art_state_id: "AST969C89C222",
    state_code: "SH",
    name: "Shanghai",
    country_code: "CN",
    lat: 31.230416,
    lon: 121.473701,
  },
  {
    art_state_id: "ASTA1EBB31BB6",
    state_code: "JS",
    name: "Jiangsu",
    country_code: "CN",
    lat: 33.1401715,
    lon: 119.7889248,
  },
  {
    art_state_id: "AST84249CBD98",
    state_code: "AH",
    name: "Anhui",
    country_code: "CN",
    lat: 30.6006773,
    lon: 117.9249002,
  },
  {
    art_state_id: "ASTC623EE909D",
    state_code: "SD",
    name: "Shandong",
    country_code: "CN",
    lat: 37.8006064,
    lon: -122.2699918,
  },
  {
    art_state_id: "AST64B8952FD6",
    state_code: "JL",
    name: "Jilin",
    country_code: "CN",
    lat: 43.837883,
    lon: 126.549572,
  },
  {
    art_state_id: "AST3A12375EC6",
    state_code: "SX",
    name: "Shanxi",
    country_code: "CN",
    lat: 37.2425649,
    lon: 111.8568586,
  },
  {
    art_state_id: "ASTD5BC9F4B55",
    state_code: "TW",
    name: "Taiwan Province, People's Republic of China",
    country_code: "CN",
    lat: 23.69781,
    lon: 120.960515,
  },
  {
    art_state_id: "AST7CA0857EDC",
    state_code: "JX",
    name: "Jiangxi",
    country_code: "CN",
    lat: 27.0874564,
    lon: 114.9042208,
  },
  {
    art_state_id: "AST7C8A0E6B30",
    state_code: "BJ",
    name: "Beijing",
    country_code: "CN",
    lat: 39.9041999,
    lon: 116.4073963,
  },
  {
    art_state_id: "ASTFB51D2CB49",
    state_code: "HN",
    name: "Hunan",
    country_code: "CN",
    lat: 36.7341294,
    lon: -95.9344902,
  },
  {
    art_state_id: "ASTAA59DB9AAC",
    state_code: "HA",
    name: "Henan",
    country_code: "CN",
    lat: 34.2904302,
    lon: 113.3823545,
  },
  {
    art_state_id: "AST0091071B38",
    state_code: "YN",
    name: "Yunnan",
    country_code: "CN",
    lat: 24.4752847,
    lon: 101.3431058,
  },
  {
    art_state_id: "AST0FB93F8920",
    state_code: "GZ",
    name: "Guizhou",
    country_code: "CN",
    lat: 26.8429645,
    lon: 107.2902839,
  },
  {
    art_state_id: "ASTC032D79522",
    state_code: "NX",
    name: "Ningxia Hui Autonomous Region",
    country_code: "CN",
    lat: 37.198731,
    lon: 106.1580937,
  },
  {
    art_state_id: "ASTF308CB921B",
    state_code: "XJ",
    name: "Xinjiang",
    country_code: "CN",
    lat: 42.5246357,
    lon: 87.5395855,
  },
  {
    art_state_id: "AST1525651A50",
    state_code: "XZ",
    name: "Tibet Autonomous Region",
    country_code: "CN",
    lat: 30.1533605,
    lon: 88.7878678,
  },
  {
    art_state_id: "AST6F9F071229",
    state_code: "HL",
    name: "Heilongjiang",
    country_code: "CN",
    lat: 47.1216472,
    lon: 128.738231,
  },
  {
    art_state_id: "ASTA52EF2D3D6",
    state_code: "MO",
    name: "Macau",
    country_code: "CN",
    lat: 22.198745,
    lon: 113.543873,
  },
  {
    art_state_id: "AST2C7D383F8E",
    state_code: "HK",
    name: "Hong Kong",
    country_code: "CN",
    lat: 22.3193039,
    lon: 114.1693611,
  },
  {
    art_state_id: "ASTBA4D19FBFD",
    state_code: "LN",
    name: "Liaoning",
    country_code: "CN",
    lat: 41.9436543,
    lon: 122.5290376,
  },
  {
    art_state_id: "AST5601C3D0BC",
    state_code: "NM",
    name: "Inner Mongolia",
    country_code: "CN",
    lat: 43.37822,
    lon: 115.0594815,
  },
  {
    art_state_id: "ASTEF3370BE8F",
    state_code: "QH",
    name: "Qinghai",
    country_code: "CN",
    lat: 35.744798,
    lon: 96.4077358,
  },
  {
    art_state_id: "ASTEFB7A76D3B",
    state_code: "CQ",
    name: "Chongqing",
    country_code: "CN",
    lat: 29.4315861,
    lon: 106.912251,
  },
  {
    art_state_id: "ASTC643E0433B",
    state_code: "SN",
    name: "Shaanxi",
    country_code: "CN",
    lat: 35.3939908,
    lon: 109.1880047,
  },
  {
    art_state_id: "ASTA751ECBA13",
    state_code: "HI",
    name: "Hainan",
    country_code: "CN",
    lat: 19.5663947,
    lon: 109.949686,
  },
  {
    art_state_id: "AST804700C664",
    state_code: "HB",
    name: "Hubei",
    country_code: "CN",
    lat: 30.7378118,
    lon: 112.2384017,
  },
  {
    art_state_id: "ASTD42252B32F",
    state_code: "GS",
    name: "Gansu",
    country_code: "CN",
    lat: 35.7518326,
    lon: 104.2861116,
  },
  {
    art_state_id: "ASTB7C6F485EF",
    state_code: "TW-KEE",
    name: "Keelung",
    country_code: "CN",
    lat: 25.1276033,
    lon: 121.7391833,
  },
  {
    art_state_id: "ASTE2BE534F8A",
    state_code: "SC",
    name: "Sichuan",
    country_code: "CN",
    lat: 30.2638032,
    lon: 102.8054753,
  },
  {
    art_state_id: "AST3DA0A9A53E",
    state_code: "GX",
    name: "Guangxi Zhuang Autonomous Region",
    country_code: "CN",
    lat: 23.7247599,
    lon: 108.8076195,
  },
  {
    art_state_id: "AST419ABDB861",
    state_code: "GD",
    name: "Guangdong",
    country_code: "CN",
    lat: 23.3790333,
    lon: 113.7632828,
  },
  {
    art_state_id: "AST736630A640",
    state_code: "HE",
    name: "Hebei",
    country_code: "CN",
    lat: 37.8956594,
    lon: 114.9042208,
  },
  {
    art_state_id: "AST53710009A0",
    state_code: "JA",
    name: "South Governorate",
    country_code: "LB",
    lat: 33.2721479,
    lon: 35.2032778,
  },
  {
    art_state_id: "AST1F3144B20D",
    state_code: "JL",
    name: "Mount Lebanon Governorate",
    country_code: "LB",
    lat: 33.8100858,
    lon: 35.5973139,
  },
  {
    art_state_id: "AST80676FA7EC",
    state_code: "BH",
    name: "Baalbek-Hermel Governorate",
    country_code: "LB",
    lat: 34.2658556,
    lon: 36.3498097,
  },
  {
    art_state_id: "AST4795364E37",
    state_code: "AS",
    name: "North Governorate",
    country_code: "LB",
    lat: 34.4380625,
    lon: 35.8308233,
  },
  {
    art_state_id: "ASTA5846EC17E",
    state_code: "AK",
    name: "Akkar Governorate",
    country_code: "LB",
    lat: 34.5328763,
    lon: 36.1328132,
  },
  {
    art_state_id: "ASTA6AC4208E3",
    state_code: "BA",
    name: "Beirut Governorate",
    country_code: "LB",
    lat: 33.8886106,
    lon: 35.4954772,
  },
  {
    art_state_id: "AST068CB2C1CD",
    state_code: "BI",
    name: "Beqaa Governorate",
    country_code: "LB",
    lat: 33.8462662,
    lon: 35.9019489,
  },
  {
    art_state_id: "ASTBECCEEBA01",
    state_code: "NA",
    name: "Nabatieh Governorate",
    country_code: "LB",
    lat: 33.3771693,
    lon: 35.4838293,
  },
  {
    art_state_id: "AST56A4497394",
    state_code: "IOW",
    name: "Isle of Wight",
    country_code: "GB",
    lat: 50.6938479,
    lon: -1.304734,
  },
  {
    art_state_id: "ASTED5DC7DF23",
    state_code: "SHN",
    name: "St Helens",
    country_code: "GB",
    lat: 45.858961,
    lon: -122.8212356,
  },
  {
    art_state_id: "AST16112AE641",
    state_code: "BEN",
    name: "London Borough of Brent",
    country_code: "GB",
    lat: 51.5672808,
    lon: -0.2710568,
  },
  {
    art_state_id: "ASTFEA76A9BC9",
    state_code: "WLL",
    name: "Walsall",
    country_code: "GB",
    lat: 52.586214,
    lon: -1.982919,
  },
  {
    art_state_id: "AST3241A01123",
    state_code: "TRF",
    name: "Trafford",
    country_code: "GB",
    lat: 40.3856246,
    lon: -79.7589347,
  },
  {
    art_state_id: "ASTF200A446EB",
    state_code: "STH",
    name: "City of Southampton",
    country_code: "GB",
    lat: 50.9097004,
    lon: -1.4043509,
  },
  {
    art_state_id: "AST3D1A83ED90",
    state_code: "SHF",
    name: "Sheffield",
    country_code: "GB",
    lat: 36.0950743,
    lon: -80.2788466,
  },
  {
    art_state_id: "ASTB3D6A9C4C8",
    state_code: "WSX",
    name: "West Sussex",
    country_code: "GB",
    lat: 50.9280143,
    lon: -0.4617075,
  },
  {
    art_state_id: "ASTACC4FA075D",
    state_code: "PTE",
    name: "City of Peterborough",
    country_code: "GB",
    lat: 44.3093636,
    lon: -78.320153,
  },
  {
    art_state_id: "AST00EFEFC313",
    state_code: "CAY",
    name: "Caerphilly County Borough",
    country_code: "GB",
    lat: 51.6604465,
    lon: -3.2178724,
  },
  {
    art_state_id: "ASTFF2FABC4F8",
    state_code: "VGL",
    name: "Vale of Glamorgan",
    country_code: "GB",
    lat: 51.4095958,
    lon: -3.4848167,
  },
  {
    art_state_id: "ASTFA9DC5AE56",
    state_code: "ZET",
    name: "Shetland Islands",
    country_code: "GB",
    lat: 60.5296507,
    lon: -1.2659409,
  },
  {
    art_state_id: "ASTC8933236DA",
    state_code: "RCT",
    name: "Rhondda Cynon Taf",
    country_code: "GB",
    lat: 51.6490207,
    lon: -3.4288692,
  },
  {
    art_state_id: "ASTA33B9FF705",
    state_code: "POL",
    name: "Poole",
    country_code: "GB",
    lat: 50.71505,
    lon: -1.987248,
  },
  {
    art_state_id: "AST33740E20CE",
    state_code: "CBF",
    name: "Central Bedfordshire",
    country_code: "GB",
    lat: 52.0029744,
    lon: -0.4651389,
  },
  {
    art_state_id: "ASTEF5AC5AA9A",
    state_code: "DOW",
    name: "Down District Council",
    country_code: "GB",
    lat: 54.2434287,
    lon: -5.9577959,
  },
  {
    art_state_id: "AST3DEBF96B2B",
    state_code: "POR",
    name: "City of Portsmouth",
    country_code: "GB",
    lat: 36.832915,
    lon: -76.2975549,
  },
  {
    art_state_id: "AST5716094E33",
    state_code: "HRY",
    name: "London Borough of Haringey",
    country_code: "GB",
    lat: 51.5906113,
    lon: -0.1109709,
  },
  {
    art_state_id: "AST3CB665756D",
    state_code: "BEX",
    name: "London Borough of Bexley",
    country_code: "GB",
    lat: 51.4519021,
    lon: 0.1171786,
  },
  {
    art_state_id: "AST992F54813D",
    state_code: "ROT",
    name: "Rotherham",
    country_code: "GB",
    lat: 53.4326035,
    lon: -1.3635009,
  },
  {
    art_state_id: "ASTF37C520BD9",
    state_code: "HPL",
    name: "Hartlepool",
    country_code: "GB",
    lat: 54.691745,
    lon: -1.212926,
  },
  {
    art_state_id: "AST5DE895C63B",
    state_code: "TFW",
    name: "Telford and Wrekin",
    country_code: "GB",
    lat: 52.7409916,
    lon: -2.4868586,
  },
  {
    art_state_id: "AST4213892584",
    state_code: "BFS",
    name: "Belfast district",
    country_code: "GB",
    lat: 54.6170366,
    lon: -5.9531861,
  },
  {
    art_state_id: "AST0EA816E393",
    state_code: "CON",
    name: "Cornwall",
    country_code: "GB",
    lat: 50.2660471,
    lon: -5.0527125,
  },
  {
    art_state_id: "AST01B9EC0BD8",
    state_code: "STN",
    name: "London Borough of Sutton",
    country_code: "GB",
    lat: 51.3573762,
    lon: -0.1752796,
  },
  {
    art_state_id: "ASTC58D137629",
    state_code: "OMH",
    name: "Omagh District Council",
    country_code: "GB",
    lat: 54.4513524,
    lon: -7.7125018,
  },
  {
    art_state_id: "AST6FCD82C209",
    state_code: "BNB",
    name: "Banbridge",
    country_code: "GB",
    lat: 54.348729,
    lon: -6.2704803,
  },
  {
    art_state_id: "AST56F99E2682",
    state_code: "CCG",
    name: "Causeway Coast and Glens",
    country_code: "GB",
    lat: 55.043183,
    lon: -6.6741288,
  },
  {
    art_state_id: "AST73DB6AAC68",
    state_code: "NTA",
    name: "Newtownabbey Borough Council",
    country_code: "GB",
    lat: 54.6792422,
    lon: -5.9591102,
  },
  {
    art_state_id: "AST0E02B0CF4B",
    state_code: "LCE",
    name: "City of Leicester",
    country_code: "GB",
    lat: 52.6368778,
    lon: -1.1397592,
  },
  {
    art_state_id: "AST58B48F9B20",
    state_code: "ISL",
    name: "London Borough of Islington",
    country_code: "GB",
    lat: 51.5465063,
    lon: -0.1058058,
  },
  {
    art_state_id: "AST9171333A03",
    state_code: "WGN",
    name: "Metropolitan Borough of Wigan",
    country_code: "GB",
    lat: 53.5134812,
    lon: -2.6106999,
  },
  {
    art_state_id: "ASTAAE52BF86F",
    state_code: "OXF",
    name: "Oxfordshire",
    country_code: "GB",
    lat: 51.7612056,
    lon: -1.2464674,
  },
  {
    art_state_id: "AST513C9F5D18",
    state_code: "MFT",
    name: "Magherafelt District Council",
    country_code: "GB",
    lat: 54.7553279,
    lon: -6.6077487,
  },
  {
    art_state_id: "ASTC30B4EF6A0",
    state_code: "SOS",
    name: "Southend-on-Sea",
    country_code: "GB",
    lat: 51.5459269,
    lon: 0.7077123,
  },
  {
    art_state_id: "ASTD252A2870B",
    state_code: "ABC",
    name: "Armagh, Banbridge and Craigavon",
    country_code: "GB",
    lat: 54.3932592,
    lon: -6.4563401,
  },
  {
    art_state_id: "ASTCB99DE907C",
    state_code: "PKN",
    name: "Perth and Kinross",
    country_code: "GB",
    lat: 56.3953817,
    lon: -3.4283547,
  },
  {
    art_state_id: "AST1712704D83",
    state_code: "WFT",
    name: "London Borough of Waltham Forest",
    country_code: "GB",
    lat: 51.5886383,
    lon: -0.0117625,
  },
  {
    art_state_id: "ASTEC8F21F8D4",
    state_code: "RCH",
    name: "Rochdale",
    country_code: "GB",
    lat: 53.6097136,
    lon: -2.1561,
  },
  {
    art_state_id: "AST3D56310D6F",
    state_code: "MTY",
    name: "Merthyr Tydfil County Borough",
    country_code: "GB",
    lat: 51.7467474,
    lon: -3.3813275,
  },
  {
    art_state_id: "AST6501925EF0",
    state_code: "BBD",
    name: "Blackburn with Darwen",
    country_code: "GB",
    lat: 53.6957522,
    lon: -2.4682985,
  },
  {
    art_state_id: "AST624C1B4716",
    state_code: "KWL",
    name: "Knowsley",
    country_code: "GB",
    lat: 53.454594,
    lon: -2.852907,
  },
  {
    art_state_id: "AST0918D8BAD1",
    state_code: "ARM",
    name: "Armagh City and District Council",
    country_code: "GB",
    lat: 54.3932592,
    lon: -6.4563401,
  },
  {
    art_state_id: "AST003CFC4A32",
    state_code: "MDB",
    name: "Middlesbrough",
    country_code: "GB",
    lat: 54.574227,
    lon: -1.234956,
  },
  {
    art_state_id: "AST912AF4F07E",
    state_code: "ERW",
    name: "East Renfrewshire",
    country_code: "GB",
    lat: 55.7704735,
    lon: -4.3359821,
  },
  {
    art_state_id: "AST3E78D1C88E",
    state_code: "CMA",
    name: "Cumbria",
    country_code: "GB",
    lat: 54.5772323,
    lon: -2.7974835,
  },
  {
    art_state_id: "AST84CC535606",
    state_code: "SCT",
    name: "Scotland",
    country_code: "GB",
    lat: 56.4906712,
    lon: -4.2026458,
  },
  {
    art_state_id: "AST7FE42409DC",
    state_code: "ENG",
    name: "England",
    country_code: "GB",
    lat: 52.3555177,
    lon: -1.1743197,
  },
  {
    art_state_id: "ASTC4BF6238C5",
    state_code: "NIR",
    name: "Northern Ireland",
    country_code: "GB",
    lat: 54.7877149,
    lon: -6.4923145,
  },
  {
    art_state_id: "ASTA5501476E1",
    state_code: "WLS",
    name: "Wales",
    country_code: "GB",
    lat: 52.1306607,
    lon: -3.7837117,
  },
  {
    art_state_id: "AST5D4EFC6A7C",
    state_code: "BAS",
    name: "Bath and North East Somerset",
    country_code: "GB",
    lat: 51.3250102,
    lon: -2.4766241,
  },
  {
    art_state_id: "AST86BBC170E8",
    state_code: "LIV",
    name: "Liverpool",
    country_code: "GB",
    lat: 32.6564981,
    lon: -115.4763241,
  },
  {
    art_state_id: "AST77380379BC",
    state_code: "SAW",
    name: "Sandwell",
    country_code: "GB",
    lat: 52.5361674,
    lon: -2.010793,
  },
  {
    art_state_id: "ASTD237F1550A",
    state_code: "BMH",
    name: "Bournemouth",
    country_code: "GB",
    lat: 50.719164,
    lon: -1.880769,
  },
  {
    art_state_id: "ASTFE9AB066EB",
    state_code: "IOS",
    name: "Isles of Scilly",
    country_code: "GB",
    lat: 49.9277261,
    lon: -6.3274966,
  },
  {
    art_state_id: "AST715F45CE24",
    state_code: "FAL",
    name: "Falkirk",
    country_code: "GB",
    lat: 56.0018775,
    lon: -3.7839131,
  },
  {
    art_state_id: "ASTB2F6963DEF",
    state_code: "DOR",
    name: "Dorset",
    country_code: "GB",
    lat: 50.7487635,
    lon: -2.3444786,
  },
  {
    art_state_id: "AST8C79D08D95",
    state_code: "SCB",
    name: "Scottish Borders",
    country_code: "GB",
    lat: 55.5485697,
    lon: -2.7861388,
  },
  {
    art_state_id: "AST8B6646F45B",
    state_code: "HAV",
    name: "London Borough of Havering",
    country_code: "GB",
    lat: 51.577924,
    lon: 0.2120829,
  },
  {
    art_state_id: "AST266FDD4CC9",
    state_code: "MYL",
    name: "Moyle District Council",
    country_code: "GB",
    lat: 55.2047327,
    lon: -6.253174,
  },
  {
    art_state_id: "AST8DD35D9E5F",
    state_code: "CMD",
    name: "London Borough of Camden",
    country_code: "GB",
    lat: 51.5454736,
    lon: -0.1627902,
  },
  {
    art_state_id: "AST56A7626CD2",
    state_code: "NYM",
    name: "Newry and Mourne District Council",
    country_code: "GB",
    lat: 54.1742505,
    lon: -6.3391992,
  },
  {
    art_state_id: "AST825FA36A0E",
    state_code: "NTL",
    name: "Neath Port Talbot County Borough",
    country_code: "GB",
    lat: 51.5978519,
    lon: -3.7839668,
  },
  {
    art_state_id: "ASTC10609639E",
    state_code: "CWY",
    name: "Conwy County Borough",
    country_code: "GB",
    lat: 53.2935013,
    lon: -3.7265161,
  },
  {
    art_state_id: "AST75E220791E",
    state_code: "ELS",
    name: "Outer Hebrides",
    country_code: "GB",
    lat: 57.7598918,
    lon: -7.0194034,
  },
  {
    art_state_id: "ASTA00320AFC6",
    state_code: "WLN",
    name: "West Lothian",
    country_code: "GB",
    lat: 55.9070198,
    lon: -3.5517167,
  },
  {
    art_state_id: "AST2F7DDBA481",
    state_code: "LIN",
    name: "Lincolnshire",
    country_code: "GB",
    lat: 52.9451889,
    lon: -0.1601246,
  },
  {
    art_state_id: "AST168BF03CA1",
    state_code: "BDG",
    name: "London Borough of Barking and Dagenham",
    country_code: "GB",
    lat: 51.5540666,
    lon: 0.134017,
  },
  {
    art_state_id: "AST978D5BA2FE",
    state_code: "WSM",
    name: "City of Westminster",
    country_code: "GB",
    lat: 39.5765977,
    lon: -76.9972126,
  },
  {
    art_state_id: "ASTD54E15B65F",
    state_code: "LEW",
    name: "London Borough of Lewisham",
    country_code: "GB",
    lat: 51.4414579,
    lon: -0.0117006,
  },
  {
    art_state_id: "AST08261F1FFF",
    state_code: "NGM",
    name: "City of Nottingham",
    country_code: "GB",
    lat: 52.9547832,
    lon: -1.1581086,
  },
  {
    art_state_id: "ASTC0FE1D1751",
    state_code: "MRY",
    name: "Moray",
    country_code: "GB",
    lat: 57.6498476,
    lon: -3.3168039,
  },
  {
    art_state_id: "ASTC5987D849B",
    state_code: "BLY",
    name: "Ballymoney",
    country_code: "GB",
    lat: 55.0704888,
    lon: -6.5173708,
  },
  {
    art_state_id: "AST005B08168A",
    state_code: "SLK",
    name: "South Lanarkshire",
    country_code: "GB",
    lat: 55.6735909,
    lon: -3.7819661,
  },
  {
    art_state_id: "AST94036BA4AA",
    state_code: "BLA",
    name: "Ballymena Borough",
    country_code: "GB",
    lat: 54.86426,
    lon: -6.2791074,
  },
  {
    art_state_id: "AST5B8BC1EFA2",
    state_code: "DNC",
    name: "Doncaster",
    country_code: "GB",
    lat: 53.52282,
    lon: -1.128462,
  },
  {
    art_state_id: "ASTA4C180B6C8",
    state_code: "NBL",
    name: "Northumberland",
    country_code: "GB",
    lat: 55.2082542,
    lon: -2.0784138,
  },
  {
    art_state_id: "AST4699638901",
    state_code: "FMO",
    name: "Fermanagh and Omagh",
    country_code: "GB",
    lat: 54.4513524,
    lon: -7.7125018,
  },
  {
    art_state_id: "AST11944EE0A6",
    state_code: "TAM",
    name: "Tameside",
    country_code: "GB",
    lat: 53.4805828,
    lon: -2.0809891,
  },
  {
    art_state_id: "AST1BDC3BCEDF",
    state_code: "KEC",
    name: "Royal Borough of Kensington and Chelsea",
    country_code: "GB",
    lat: 51.4990805,
    lon: -0.1938253,
  },
  {
    art_state_id: "AST9798312A7A",
    state_code: "HRT",
    name: "Hertfordshire",
    country_code: "GB",
    lat: 51.8097823,
    lon: -0.2376744,
  },
  {
    art_state_id: "AST84540DCC04",
    state_code: "ERY",
    name: "East Riding of Yorkshire",
    country_code: "GB",
    lat: 53.8416168,
    lon: -0.4344106,
  },
  {
    art_state_id: "ASTBC21ED0974",
    state_code: "KIR",
    name: "Kirklees",
    country_code: "GB",
    lat: 53.5933432,
    lon: -1.8009509,
  },
  {
    art_state_id: "ASTB41B5FE8F1",
    state_code: "SND",
    name: "City of Sunderland",
    country_code: "GB",
    lat: 54.8861489,
    lon: -1.4785797,
  },
  {
    art_state_id: "ASTF86E5D7D42",
    state_code: "GLS",
    name: "Gloucestershire",
    country_code: "GB",
    lat: 51.8642112,
    lon: -2.2380335,
  },
  {
    art_state_id: "AST4C5FE16BF3",
    state_code: "EAY",
    name: "East Ayrshire",
    country_code: "GB",
    lat: 55.4518496,
    lon: -4.2644478,
  },
  {
    art_state_id: "AST2DF7E317E7",
    state_code: "UKM",
    name: "United Kingdom",
    country_code: "GB",
    lat: 55.378051,
    lon: -3.435973,
  },
  {
    art_state_id: "AST43EA8FF283",
    state_code: "HIL",
    name: "London Borough of Hillingdon",
    country_code: "GB",
    lat: 51.5351832,
    lon: -0.4481378,
  },
  {
    art_state_id: "ASTE18028AAB3",
    state_code: "SAY",
    name: "South Ayrshire",
    country_code: "GB",
    lat: 55.4588988,
    lon: -4.6291994,
  },
  {
    art_state_id: "AST8888A9DC90",
    state_code: "SH-AC",
    name: "Ascension Island",
    country_code: "GB",
    lat: -7.9467166,
    lon: -14.3559158,
  },
  {
    art_state_id: "AST4CB89338BF",
    state_code: "GWN",
    name: "Gwynedd",
    country_code: "GB",
    lat: 52.9277266,
    lon: -4.1334836,
  },
  {
    art_state_id: "ASTAFE6AA2029",
    state_code: "HNS",
    name: "London Borough of Hounslow",
    country_code: "GB",
    lat: 51.4828358,
    lon: -0.3882062,
  },
  {
    art_state_id: "ASTC18D0BD820",
    state_code: "MDW",
    name: "Medway",
    country_code: "GB",
    lat: 42.1417641,
    lon: -71.3967256,
  },
  {
    art_state_id: "AST16EA4C5EA8",
    state_code: "LMV",
    name: "Limavady Borough Council",
    country_code: "GB",
    lat: 55.051682,
    lon: -6.9491944,
  },
  {
    art_state_id: "AST56265D983C",
    state_code: "HLD",
    name: "Highland",
    country_code: "GB",
    lat: 36.2967508,
    lon: -95.8380366,
  },
  {
    art_state_id: "AST9E9D5D8AA7",
    state_code: "NEL",
    name: "North East Lincolnshire",
    country_code: "GB",
    lat: 53.5668201,
    lon: -0.0815066,
  },
  {
    art_state_id: "ASTA890659B49",
    state_code: "HRW",
    name: "London Borough of Harrow",
    country_code: "GB",
    lat: 51.5881627,
    lon: -0.3422851,
  },
  {
    art_state_id: "AST13F92682BD",
    state_code: "SOM",
    name: "Somerset",
    country_code: "GB",
    lat: 51.105097,
    lon: -2.9262307,
  },
  {
    art_state_id: "ASTD0F57BE335",
    state_code: "ANS",
    name: "Angus",
    country_code: "GB",
    lat: 37.2757886,
    lon: -95.6501033,
  },
  {
    art_state_id: "ASTA50AB35D51",
    state_code: "IVC",
    name: "Inverclyde",
    country_code: "GB",
    lat: 55.9316569,
    lon: -4.6800158,
  },
  {
    art_state_id: "ASTF1490162C7",
    state_code: "DAL",
    name: "Darlington",
    country_code: "GB",
    lat: 34.2998762,
    lon: -79.8761741,
  },
  {
    art_state_id: "AST6BC7DB5DAF",
    state_code: "TWH",
    name: "London Borough of Tower Hamlets",
    country_code: "GB",
    lat: 51.5202607,
    lon: -0.0293396,
  },
  {
    art_state_id: "AST56DB6A2EE6",
    state_code: "WIL",
    name: "Wiltshire",
    country_code: "GB",
    lat: 51.3491996,
    lon: -1.9927105,
  },
  {
    art_state_id: "ASTB634BE8D76",
    state_code: "AGB",
    name: "Argyll and Bute",
    country_code: "GB",
    lat: 56.4006214,
    lon: -5.480748,
  },
  {
    art_state_id: "ASTBACFAB468A",
    state_code: "STB",
    name: "Strabane District Council",
    country_code: "GB",
    lat: 54.8273865,
    lon: -7.4633103,
  },
  {
    art_state_id: "AST370EE9A471",
    state_code: "SKP",
    name: "Stockport",
    country_code: "GB",
    lat: 53.4106316,
    lon: -2.1575332,
  },
  {
    art_state_id: "AST84BD6A70DF",
    state_code: "BNH",
    name: "Brighton and Hove",
    country_code: "GB",
    lat: 50.8226288,
    lon: -0.137047,
  },
  {
    art_state_id: "AST5CD312F48E",
    state_code: "LBH",
    name: "London Borough of Lambeth",
    country_code: "GB",
    lat: 51.4571477,
    lon: -0.1230681,
  },
  {
    art_state_id: "ASTF2361F3D06",
    state_code: "RDB",
    name: "London Borough of Redbridge",
    country_code: "GB",
    lat: 51.5886121,
    lon: 0.0823982,
  },
  {
    art_state_id: "ASTFC2BB150F9",
    state_code: "MAN",
    name: "Manchester",
    country_code: "GB",
    lat: 53.4807593,
    lon: -2.2426305,
  },
  {
    art_state_id: "AST364ED9BF43",
    state_code: "MUL",
    name: "Mid Ulster",
    country_code: "GB",
    lat: 54.6411301,
    lon: -6.7522549,
  },
  {
    art_state_id: "ASTE164052833",
    state_code: "SGC",
    name: "South Gloucestershire",
    country_code: "GB",
    lat: 51.5264361,
    lon: -2.4728487,
  },
  {
    art_state_id: "AST3493B51DC7",
    state_code: "ABD",
    name: "Aberdeenshire",
    country_code: "GB",
    lat: 57.2868723,
    lon: -2.3815684,
  },
  {
    art_state_id: "AST545C6F1FCF",
    state_code: "MON",
    name: "Monmouthshire",
    country_code: "GB",
    lat: 51.81161,
    lon: -2.7163417,
  },
  {
    art_state_id: "AST49F19E6AC9",
    state_code: "DBY",
    name: "Derbyshire",
    country_code: "GB",
    lat: 53.1046782,
    lon: -1.5623885,
  },
  {
    art_state_id: "AST4843BA4206",
    state_code: "GLG",
    name: "Glasgow",
    country_code: "GB",
    lat: 55.864237,
    lon: -4.251806,
  },
  {
    art_state_id: "AST5885BF0A4B",
    state_code: "BKM",
    name: "Buckinghamshire",
    country_code: "GB",
    lat: 51.8072204,
    lon: -0.8127664,
  },
  {
    art_state_id: "ASTDDDA0B93B5",
    state_code: "DUR",
    name: "County Durham",
    country_code: "GB",
    lat: 54.7294099,
    lon: -1.8811598,
  },
  {
    art_state_id: "AST44B48BC38C",
    state_code: "SHR",
    name: "Shropshire",
    country_code: "GB",
    lat: 52.7063657,
    lon: -2.7417849,
  },
  {
    art_state_id: "AST5DCC010963",
    state_code: "WRL",
    name: "Wirral",
    country_code: "GB",
    lat: 53.3727181,
    lon: -3.073754,
  },
  {
    art_state_id: "ASTEB99D9C0F0",
    state_code: "STY",
    name: "South Tyneside",
    country_code: "GB",
    lat: 54.9636693,
    lon: -1.4418634,
  },
  {
    art_state_id: "AST863B84F30D",
    state_code: "ESS",
    name: "Essex",
    country_code: "GB",
    lat: 51.5742447,
    lon: 0.4856781,
  },
  {
    art_state_id: "ASTD5EE3A2EC2",
    state_code: "HCK",
    name: "London Borough of Hackney",
    country_code: "GB",
    lat: 51.573445,
    lon: -0.0724376,
  },
  {
    art_state_id: "AST5029C1AAA6",
    state_code: "ANN",
    name: "Antrim and Newtownabbey",
    country_code: "GB",
    lat: 54.6956887,
    lon: -5.9481069,
  },
  {
    art_state_id: "ASTAC827BE2F9",
    state_code: "BST",
    name: "City of Bristol",
    country_code: "GB",
    lat: 41.673522,
    lon: -72.9465375,
  },
  {
    art_state_id: "ASTDCE8A86ACF",
    state_code: "ESX",
    name: "East Sussex",
    country_code: "GB",
    lat: 50.9085955,
    lon: 0.2494166,
  },
  {
    art_state_id: "AST985C6F3611",
    state_code: "DGY",
    name: "Dumfries and Galloway",
    country_code: "GB",
    lat: 55.0701073,
    lon: -3.6052581,
  },
  {
    art_state_id: "AST1D90789E85",
    state_code: "MIK",
    name: "Milton Keynes",
    country_code: "GB",
    lat: 52.0852038,
    lon: -0.7333133,
  },
  {
    art_state_id: "ASTD6EAF958A0",
    state_code: "DRY",
    name: "Derry City Council",
    country_code: "GB",
    lat: 54.9690778,
    lon: -7.1958351,
  },
  {
    art_state_id: "AST2D84D20A2A",
    state_code: "NWM",
    name: "London Borough of Newham",
    country_code: "GB",
    lat: 51.5255162,
    lon: 0.0352163,
  },
  {
    art_state_id: "AST26728909E6",
    state_code: "WOK",
    name: "Wokingham",
    country_code: "GB",
    lat: 51.410457,
    lon: -0.833861,
  },
  {
    art_state_id: "ASTD8E315191B",
    state_code: "WRT",
    name: "Warrington",
    country_code: "GB",
    lat: 40.2492741,
    lon: -75.1340604,
  },
  {
    art_state_id: "ASTD6758652A7",
    state_code: "STT",
    name: "Stockton-on-Tees",
    country_code: "GB",
    lat: 54.5704551,
    lon: -1.3289821,
  },
  {
    art_state_id: "AST49B4AF5D2F",
    state_code: "SWD",
    name: "Swindon",
    country_code: "GB",
    lat: 51.5557739,
    lon: -1.7797176,
  },
  {
    art_state_id: "ASTB2C740FD4A",
    state_code: "CAM",
    name: "Cambridgeshire",
    country_code: "GB",
    lat: 52.2052973,
    lon: 0.1218195,
  },
  {
    art_state_id: "AST1BEF70268D",
    state_code: "LND",
    name: "City of London",
    country_code: "GB",
    lat: 51.5123443,
    lon: -0.0909852,
  },
  {
    art_state_id: "AST32AC5F6B4C",
    state_code: "BIR",
    name: "Birmingham",
    country_code: "GB",
    lat: 33.5185892,
    lon: -86.8103567,
  },
  {
    art_state_id: "AST7E013ED5E6",
    state_code: "YOR",
    name: "City of York",
    country_code: "GB",
    lat: 53.9599651,
    lon: -1.0872979,
  },
  {
    art_state_id: "AST187C319B1C",
    state_code: "SLG",
    name: "Slough",
    country_code: "GB",
    lat: 51.5105384,
    lon: -0.5950406,
  },
  {
    art_state_id: "AST390ACA2A0D",
    state_code: "EDH",
    name: "Edinburgh",
    country_code: "GB",
    lat: 55.953252,
    lon: -3.188267,
  },
  {
    art_state_id: "AST18EC00678F",
    state_code: "MEA",
    name: "Mid and East Antrim",
    country_code: "GB",
    lat: 54.9399341,
    lon: -6.1137423,
  },
  {
    art_state_id: "ASTF47B0C1050",
    state_code: "NSM",
    name: "North Somerset",
    country_code: "GB",
    lat: 51.3879028,
    lon: -2.7781091,
  },
  {
    art_state_id: "ASTA4F29E55FE",
    state_code: "GAT",
    name: "Gateshead",
    country_code: "GB",
    lat: 54.95268,
    lon: -1.603411,
  },
  {
    art_state_id: "AST30030F56AA",
    state_code: "SWK",
    name: "London Borough of Southwark",
    country_code: "GB",
    lat: 51.4880572,
    lon: -0.0762838,
  },
  {
    art_state_id: "ASTC5C775A2DC",
    state_code: "SWA",
    name: "City and County of Swansea",
    country_code: "GB",
    lat: 51.62144,
    lon: -3.943646,
  },
  {
    art_state_id: "ASTBFDD6329BD",
    state_code: "WND",
    name: "London Borough of Wandsworth",
    country_code: "GB",
    lat: 51.4568274,
    lon: -0.1896638,
  },
  {
    art_state_id: "AST4DCB3B312C",
    state_code: "HAM",
    name: "Hampshire",
    country_code: "GB",
    lat: 51.0576948,
    lon: -1.3080629,
  },
  {
    art_state_id: "ASTD678522897",
    state_code: "WRX",
    name: "Wrexham County Borough",
    country_code: "GB",
    lat: 53.0301378,
    lon: -3.0261487,
  },
  {
    art_state_id: "ASTAF5FD9E350",
    state_code: "FLN",
    name: "Flintshire",
    country_code: "GB",
    lat: 53.1668658,
    lon: -3.1418908,
  },
  {
    art_state_id: "AST6890B34F88",
    state_code: "COV",
    name: "Coventry",
    country_code: "GB",
    lat: 52.406822,
    lon: -1.519693,
  },
  {
    art_state_id: "ASTEDD44162A8",
    state_code: "CKF",
    name: "Carrickfergus Borough Council",
    country_code: "GB",
    lat: 54.7256843,
    lon: -5.8093719,
  },
  {
    art_state_id: "AST2047912CA6",
    state_code: "WDU",
    name: "West Dunbartonshire",
    country_code: "GB",
    lat: 55.9450925,
    lon: -4.5646259,
  },
  {
    art_state_id: "AST773C9FA6A6",
    state_code: "POW",
    name: "Powys",
    country_code: "GB",
    lat: 52.6464249,
    lon: -3.3260904,
  },
  {
    art_state_id: "AST1C078F2F16",
    state_code: "CHW",
    name: "Cheshire West and Chester",
    country_code: "GB",
    lat: 53.2302974,
    lon: -2.7151117,
  },
  {
    art_state_id: "AST565B402CBD",
    state_code: "RFW",
    name: "Renfrewshire",
    country_code: "GB",
    lat: 55.846654,
    lon: -4.5331259,
  },
  {
    art_state_id: "ASTBD546257FD",
    state_code: "CHE",
    name: "Cheshire East",
    country_code: "GB",
    lat: 53.1610446,
    lon: -2.2185932,
  },
  {
    art_state_id: "ASTC8F93470CA",
    state_code: "CKT",
    name: "Cookstown District Council",
    country_code: "GB",
    lat: 54.6418158,
    lon: -6.7443895,
  },
  {
    art_state_id: "AST6D6E0F444D",
    state_code: "DRS",
    name: "Derry City and Strabane",
    country_code: "GB",
    lat: 55.0047443,
    lon: -7.3209222,
  },
  {
    art_state_id: "AST2EF0111049",
    state_code: "STS",
    name: "Staffordshire",
    country_code: "GB",
    lat: 52.8792745,
    lon: -2.0571868,
  },
  {
    art_state_id: "ASTC81209A39D",
    state_code: "HMF",
    name: "London Borough of Hammersmith and Fulham",
    country_code: "GB",
    lat: 51.4990156,
    lon: -0.22915,
  },
  {
    art_state_id: "AST8C75097D10",
    state_code: "CGV",
    name: "Craigavon Borough Council",
    country_code: "GB",
    lat: 54.3932592,
    lon: -6.4563401,
  },
  {
    art_state_id: "ASTB637D5FDAB",
    state_code: "CLK",
    name: "Clackmannanshire",
    country_code: "GB",
    lat: 56.1075351,
    lon: -3.7529409,
  },
  {
    art_state_id: "ASTBD4A95A9DB",
    state_code: "BPL",
    name: "Blackpool",
    country_code: "GB",
    lat: 53.8175053,
    lon: -3.0356748,
  },
  {
    art_state_id: "AST7305F2FD89",
    state_code: "BGE",
    name: "Bridgend County Borough",
    country_code: "GB",
    lat: 51.5083199,
    lon: -3.5812075,
  },
  {
    art_state_id: "AST8F82574D07",
    state_code: "NLN",
    name: "North Lincolnshire",
    country_code: "GB",
    lat: 53.6055592,
    lon: -0.5596582,
  },
  {
    art_state_id: "ASTEF12144F33",
    state_code: "EDU",
    name: "East Dunbartonshire",
    country_code: "GB",
    lat: 55.9743162,
    lon: -4.202298,
  },
  {
    art_state_id: "AST5F41104686",
    state_code: "RDG",
    name: "Reading",
    country_code: "GB",
    lat: 36.1486659,
    lon: -95.9840012,
  },
  {
    art_state_id: "ASTA1EB4058CD",
    state_code: "NTT",
    name: "Nottinghamshire",
    country_code: "GB",
    lat: 53.100319,
    lon: -0.9936306,
  },
  {
    art_state_id: "AST51D58BE875",
    state_code: "DUD",
    name: "Dudley",
    country_code: "GB",
    lat: 42.0433661,
    lon: -71.9276033,
  },
  {
    art_state_id: "AST5073A9E2A3",
    state_code: "NET",
    name: "Newcastle upon Tyne",
    country_code: "GB",
    lat: 54.978252,
    lon: -1.61778,
  },
  {
    art_state_id: "AST607839A7D6",
    state_code: "BUR",
    name: "Bury",
    country_code: "GB",
    lat: 53.5933498,
    lon: -2.2966054,
  },
  {
    art_state_id: "AST60622D04D7",
    state_code: "LBC",
    name: "Lisburn and Castlereagh",
    country_code: "GB",
    lat: 54.4981584,
    lon: -6.1306791,
  },
  {
    art_state_id: "ASTFD7231F735",
    state_code: "CLR",
    name: "Coleraine Borough Council",
    country_code: "GB",
    lat: 55.145157,
    lon: -6.6759814,
  },
  {
    art_state_id: "ASTFA62F3360A",
    state_code: "ELN",
    name: "East Lothian",
    country_code: "GB",
    lat: 55.9493383,
    lon: -2.7704464,
  },
  {
    art_state_id: "ASTEA5D65A40F",
    state_code: "ABE",
    name: "Aberdeen",
    country_code: "GB",
    lat: 57.149717,
    lon: -2.094278,
  },
  {
    art_state_id: "AST7ADEC49F6B",
    state_code: "KEN",
    name: "Kent",
    country_code: "GB",
    lat: 41.1536674,
    lon: -81.3578859,
  },
  {
    art_state_id: "ASTADD829B38A",
    state_code: "WKF",
    name: "Wakefield",
    country_code: "GB",
    lat: 42.5039395,
    lon: -71.0723391,
  },
  {
    art_state_id: "ASTEEAD18B150",
    state_code: "HAL",
    name: "Halton",
    country_code: "GB",
    lat: 43.5325372,
    lon: -79.8744836,
  },
  {
    art_state_id: "ASTCC19B046AF",
    state_code: "SFK",
    name: "Suffolk",
    country_code: "GB",
    lat: 52.1872472,
    lon: 0.9707801,
  },
  {
    art_state_id: "ASTDB648B1DF3",
    state_code: "THR",
    name: "Thurrock",
    country_code: "GB",
    lat: 51.4934557,
    lon: 0.3529197,
  },
  {
    art_state_id: "AST3274C1F380",
    state_code: "SOL",
    name: "Solihull",
    country_code: "GB",
    lat: 52.411811,
    lon: -1.77761,
  },
  {
    art_state_id: "AST6CACD12101",
    state_code: "BRC",
    name: "Bracknell Forest",
    country_code: "GB",
    lat: 51.4153828,
    lon: -0.7536495,
  },
  {
    art_state_id: "ASTB29BA2F32A",
    state_code: "WBK",
    name: "West Berkshire",
    country_code: "GB",
    lat: 51.4308255,
    lon: -1.1444927,
  },
  {
    art_state_id: "AST57802FB31A",
    state_code: "RUT",
    name: "Rutland",
    country_code: "GB",
    lat: 43.6106237,
    lon: -72.9726065,
  },
  {
    art_state_id: "ASTD58601453C",
    state_code: "NFK",
    name: "Norfolk",
    country_code: "GB",
    lat: 36.8507689,
    lon: -76.2858726,
  },
  {
    art_state_id: "ASTD1460B771E",
    state_code: "ORK",
    name: "Orkney Islands",
    country_code: "GB",
    lat: 58.9809401,
    lon: -2.9605206,
  },
  {
    art_state_id: "ASTE93F47D87C",
    state_code: "KHL",
    name: "City of Kingston upon Hull",
    country_code: "GB",
    lat: 53.7676236,
    lon: -0.3274198,
  },
  {
    art_state_id: "AST2D544FAC76",
    state_code: "ENF",
    name: "London Borough of Enfield",
    country_code: "GB",
    lat: 51.6622909,
    lon: -0.1180651,
  },
  {
    art_state_id: "AST4CAF3EC62A",
    state_code: "OLD",
    name: "Oldham",
    country_code: "GB",
    lat: 42.2040598,
    lon: -71.2048119,
  },
  {
    art_state_id: "ASTE5DD1B7EBA",
    state_code: "TOB",
    name: "Torbay",
    country_code: "GB",
    lat: 50.4392329,
    lon: -3.5369899,
  },
  {
    art_state_id: "ASTFE507857EF",
    state_code: "FIF",
    name: "Fife",
    country_code: "GB",
    lat: 56.2082078,
    lon: -3.1495175,
  },
  {
    art_state_id: "AST9B6539997E",
    state_code: "NTH",
    name: "Northamptonshire",
    country_code: "GB",
    lat: 52.2729944,
    lon: -0.8755515,
  },
  {
    art_state_id: "AST6CCCC63FAD",
    state_code: "KTT",
    name: "Royal Borough of Kingston upon Thames",
    country_code: "GB",
    lat: 51.378117,
    lon: -0.292709,
  },
  {
    art_state_id: "AST9E0E73E3F3",
    state_code: "WNM",
    name: "Windsor and Maidenhead",
    country_code: "GB",
    lat: 51.4799712,
    lon: -0.6242565,
  },
  {
    art_state_id: "ASTC9BAB38513",
    state_code: "MRT",
    name: "London Borough of Merton",
    country_code: "GB",
    lat: 51.4097742,
    lon: -0.2108084,
  },
  {
    art_state_id: "AST47624658D1",
    state_code: "CMN",
    name: "Carmarthenshire",
    country_code: "GB",
    lat: 51.8572309,
    lon: -4.3115959,
  },
  {
    art_state_id: "AST125F78BAA2",
    state_code: "DER",
    name: "City of Derby",
    country_code: "GB",
    lat: 37.5483755,
    lon: -97.2485191,
  },
  {
    art_state_id: "AST015F13CA68",
    state_code: "PEM",
    name: "Pembrokeshire",
    country_code: "GB",
    lat: 51.674078,
    lon: -4.9088785,
  },
  {
    art_state_id: "AST04727B996D",
    state_code: "NLK",
    name: "North Lanarkshire",
    country_code: "GB",
    lat: 55.8662432,
    lon: -3.9613144,
  },
  {
    art_state_id: "AST941056EA37",
    state_code: "STG",
    name: "Stirling",
    country_code: "GB",
    lat: 56.1165227,
    lon: -3.9369029,
  },
  {
    art_state_id: "ASTA7D077C392",
    state_code: "WLV",
    name: "City of Wolverhampton",
    country_code: "GB",
    lat: 52.588912,
    lon: -2.156463,
  },
  {
    art_state_id: "AST1ADFAC736D",
    state_code: "BRY",
    name: "London Borough of Bromley",
    country_code: "GB",
    lat: 51.3679705,
    lon: 0.070062,
  },
  {
    art_state_id: "AST2DF2833D18",
    state_code: "DEV",
    name: "Devon",
    country_code: "GB",
    lat: 50.7155591,
    lon: -3.530875,
  },
  {
    art_state_id: "AST52E7B009CB",
    state_code: "GRE",
    name: "Royal Borough of Greenwich",
    country_code: "GB",
    lat: 51.4834627,
    lon: 0.0586202,
  },
  {
    art_state_id: "AST4922B0602A",
    state_code: "SLF",
    name: "Salford",
    country_code: "GB",
    lat: 53.4875235,
    lon: -2.2901264,
  },
  {
    art_state_id: "ASTC72B8BC7D7",
    state_code: "LSB",
    name: "Lisburn City Council",
    country_code: "GB",
    lat: 54.4981584,
    lon: -6.1306791,
  },
  {
    art_state_id: "AST8923494C0E",
    state_code: "LAN",
    name: "Lancashire",
    country_code: "GB",
    lat: 53.7632254,
    lon: -2.7044052,
  },
  {
    art_state_id: "ASTB911152709",
    state_code: "TOF",
    name: "Torfaen",
    country_code: "GB",
    lat: 51.7002253,
    lon: -3.0446015,
  },
  {
    art_state_id: "AST629160B2C2",
    state_code: "DEN",
    name: "Denbighshire",
    country_code: "GB",
    lat: 53.1842288,
    lon: -3.4224985,
  },
  {
    art_state_id: "AST54ECAD1892",
    state_code: "ARD",
    name: "Ards",
    country_code: "GB",
    lat: 42.1391851,
    lon: -87.8614972,
  },
  {
    art_state_id: "ASTA7A87E22A7",
    state_code: "BNS",
    name: "Barnsley",
    country_code: "GB",
    lat: 34.2994956,
    lon: -84.9845809,
  },
  {
    art_state_id: "AST13FA99F176",
    state_code: "HEF",
    name: "Herefordshire",
    country_code: "GB",
    lat: 52.0765164,
    lon: -2.6544182,
  },
  {
    art_state_id: "AST2310D3E0E5",
    state_code: "RIC",
    name: "London Borough of Richmond upon Thames",
    country_code: "GB",
    lat: 51.4613054,
    lon: -0.3037709,
  },
  {
    art_state_id: "AST0E3B0D54E0",
    state_code: "SH-HL",
    name: "Saint Helena",
    country_code: "GB",
    lat: -15.9650104,
    lon: -5.7089241,
  },
  {
    art_state_id: "ASTA3A4E46904",
    state_code: "LDS",
    name: "Leeds",
    country_code: "GB",
    lat: 53.8007554,
    lon: -1.5490774,
  },
  {
    art_state_id: "ASTEBDA88AAE8",
    state_code: "BOL",
    name: "Bolton",
    country_code: "GB",
    lat: 44.3726476,
    lon: -72.8787625,
  },
  {
    art_state_id: "AST1FD06EC243",
    state_code: "WAR",
    name: "Warwickshire",
    country_code: "GB",
    lat: 52.2671353,
    lon: -1.4675216,
  },
  {
    art_state_id: "AST408B552986",
    state_code: "STE",
    name: "City of Stoke-on-Trent",
    country_code: "GB",
    lat: 53.002668,
    lon: -2.179404,
  },
  {
    art_state_id: "AST04E446F7AF",
    state_code: "BDF",
    name: "Bedford",
    country_code: "GB",
    lat: 32.844017,
    lon: -97.1430671,
  },
  {
    art_state_id: "AST5A8BC57665",
    state_code: "DGN",
    name: "Dungannon and South Tyrone Borough Council",
    country_code: "GB",
    lat: 54.5082684,
    lon: -6.7665891,
  },
  {
    art_state_id: "AST6D0CD6DEAD",
    state_code: "CGN",
    name: "Ceredigion",
    country_code: "GB",
    lat: 52.2191429,
    lon: -3.9321256,
  },
  {
    art_state_id: "ASTE7F539D4FC",
    state_code: "WOR",
    name: "Worcestershire",
    country_code: "GB",
    lat: 52.2545225,
    lon: -2.2668382,
  },
  {
    art_state_id: "AST6B52823547",
    state_code: "DND",
    name: "Dundee",
    country_code: "GB",
    lat: 56.462018,
    lon: -2.970721,
  },
  {
    art_state_id: "AST1FA3587F01",
    state_code: "CRY",
    name: "London Borough of Croydon",
    country_code: "GB",
    lat: 51.3827446,
    lon: -0.0985163,
  },
  {
    art_state_id: "ASTD518B5FA13",
    state_code: "NDN",
    name: "North Down Borough Council",
    country_code: "GB",
    lat: 54.6536297,
    lon: -5.6724925,
  },
  {
    art_state_id: "AST0094CC1585",
    state_code: "PLY",
    name: "City of Plymouth",
    country_code: "GB",
    lat: 42.3708941,
    lon: -83.4697141,
  },
  {
    art_state_id: "ASTA2294A75C6",
    state_code: "LRN",
    name: "Larne Borough Council",
    country_code: "GB",
    lat: 54.8578003,
    lon: -5.8236224,
  },
  {
    art_state_id: "AST2D48DC74BE",
    state_code: "LEC",
    name: "Leicestershire",
    country_code: "GB",
    lat: 52.772571,
    lon: -1.2052126,
  },
  {
    art_state_id: "ASTE5EB85D1C4",
    state_code: "CLD",
    name: "Calderdale",
    country_code: "GB",
    lat: 53.7247845,
    lon: -1.8658357,
  },
  {
    art_state_id: "AST45F2A344C2",
    state_code: "SFT",
    name: "Sefton",
    country_code: "GB",
    lat: 53.5034449,
    lon: -2.970359,
  },
  {
    art_state_id: "AST4B9ACB3A7C",
    state_code: "MLN",
    name: "Midlothian",
    country_code: "GB",
    lat: 32.475335,
    lon: -97.0103181,
  },
  {
    art_state_id: "ASTBB40585A4A",
    state_code: "BNE",
    name: "London Borough of Barnet",
    country_code: "GB",
    lat: 51.6049673,
    lon: -0.2076295,
  },
  {
    art_state_id: "ASTA30565E05F",
    state_code: "NTY",
    name: "North Tyneside",
    country_code: "GB",
    lat: 55.0182399,
    lon: -1.4858436,
  },
  {
    art_state_id: "AST670D3AB8EB",
    state_code: "NYK",
    name: "North Yorkshire",
    country_code: "GB",
    lat: 53.9915028,
    lon: -1.5412015,
  },
  {
    art_state_id: "AST02754E91AF",
    state_code: "AND",
    name: "Ards and North Down",
    country_code: "GB",
    lat: 54.5899645,
    lon: -5.5984972,
  },
  {
    art_state_id: "AST7001511D78",
    state_code: "NWP",
    name: "Newport",
    country_code: "GB",
    lat: 37.5278234,
    lon: -94.1043876,
  },
  {
    art_state_id: "ASTD64D41E3DC",
    state_code: "CSR",
    name: "Castlereagh",
    country_code: "GB",
    lat: 54.575679,
    lon: -5.8884028,
  },
  {
    art_state_id: "AST169691C654",
    state_code: "SRY",
    name: "Surrey",
    country_code: "GB",
    lat: 51.3147593,
    lon: -0.5599501,
  },
  {
    art_state_id: "AST02CD6D5211",
    state_code: "RCC",
    name: "Redcar and Cleveland",
    country_code: "GB",
    lat: 54.5971344,
    lon: -1.0775997,
  },
  {
    art_state_id: "AST5AF3970878",
    state_code: "CRF",
    name: "City and County of Cardiff",
    country_code: "GB",
    lat: 51.481581,
    lon: -3.17909,
  },
  {
    art_state_id: "AST87604DEB3F",
    state_code: "BRD",
    name: "Bradford",
    country_code: "GB",
    lat: 53.795984,
    lon: -1.759398,
  },
  {
    art_state_id: "AST963A1EC7C7",
    state_code: "BGW",
    name: "Blaenau Gwent County Borough",
    country_code: "GB",
    lat: 51.7875779,
    lon: -3.2043931,
  },
  {
    art_state_id: "ASTCDF556265A",
    state_code: "FER",
    name: "Fermanagh District Council",
    country_code: "GB",
    lat: 54.3447978,
    lon: -7.6384218,
  },
  {
    art_state_id: "ASTB4AA76AEC6",
    state_code: "EAL",
    name: "London Borough of Ealing",
    country_code: "GB",
    lat: 51.5250366,
    lon: -0.3413965,
  },
  {
    art_state_id: "ASTB8EA39DB72",
    state_code: "ANT",
    name: "Antrim",
    country_code: "GB",
    lat: 54.7195338,
    lon: -6.2072498,
  },
  {
    art_state_id: "AST2AF8C6080E",
    state_code: "NMD",
    name: "Newry, Mourne and Down",
    country_code: "GB",
    lat: 54.2434287,
    lon: -5.9577959,
  },
  {
    art_state_id: "AST7FA8CBA787",
    state_code: "NAY",
    name: "North Ayrshire",
    country_code: "GB",
    lat: 55.6416731,
    lon: -4.75946,
  },
  {
    art_state_id: "AST1E855426BD",
    state_code: "TK",
    name: "Tashkent",
    country_code: "UZ",
    lat: 41.2994958,
    lon: 69.2400734,
  },
  {
    art_state_id: "AST7BF768C43C",
    state_code: "NG",
    name: "Namangan Region",
    country_code: "UZ",
    lat: 41.0510037,
    lon: 71.097317,
  },
  {
    art_state_id: "ASTFAE9E8B9E9",
    state_code: "FA",
    name: "Fergana Region",
    country_code: "UZ",
    lat: 40.4568081,
    lon: 71.2874209,
  },
  {
    art_state_id: "AST6F99639CE8",
    state_code: "XO",
    name: "Xorazm Region",
    country_code: "UZ",
    lat: 41.3565336,
    lon: 60.8566686,
  },
  {
    art_state_id: "AST1AB22CF14D",
    state_code: "AN",
    name: "Andijan Region",
    country_code: "UZ",
    lat: 40.7685941,
    lon: 72.236379,
  },
  {
    art_state_id: "AST4326D761C4",
    state_code: "BU",
    name: "Bukhara Region",
    country_code: "UZ",
    lat: 40.2504162,
    lon: 63.2032151,
  },
  {
    art_state_id: "ASTC7CBF0571C",
    state_code: "NW",
    name: "Navoiy Region",
    country_code: "UZ",
    lat: 42.6988575,
    lon: 64.6337685,
  },
  {
    art_state_id: "ASTF306384F6D",
    state_code: "QA",
    name: "Qashqadaryo Region",
    country_code: "UZ",
    lat: 38.8986231,
    lon: 66.0463534,
  },
  {
    art_state_id: "AST72A58BA46A",
    state_code: "SA",
    name: "Samarqand Region",
    country_code: "UZ",
    lat: 39.627012,
    lon: 66.9749731,
  },
  {
    art_state_id: "ASTD80712C1B1",
    state_code: "JI",
    name: "Jizzakh Region",
    country_code: "UZ",
    lat: 40.4706415,
    lon: 67.5708536,
  },
  {
    art_state_id: "ASTCCF24D4B9C",
    state_code: "SU",
    name: "Surxondaryo Region",
    country_code: "UZ",
    lat: 37.9409005,
    lon: 67.5708536,
  },
  {
    art_state_id: "AST4614CA5102",
    state_code: "SI",
    name: "Sirdaryo Region",
    country_code: "UZ",
    lat: 40.3863808,
    lon: 68.7154975,
  },
  {
    art_state_id: "ASTE7436D62B2",
    state_code: "QR",
    name: "Karakalpakstan",
    country_code: "UZ",
    lat: 43.8041334,
    lon: 59.4457988,
  },
  {
    art_state_id: "AST4E228BF185",
    state_code: "TO",
    name: "Tashkent Region",
    country_code: "UZ",
    lat: 41.2213234,
    lon: 69.8597406,
  },
  {
    art_state_id: "AST3415AD81F0",
    state_code: "12",
    name: "Ariana Governorate",
    country_code: "TN",
    lat: 36.9922751,
    lon: 10.1255164,
  },
  {
    art_state_id: "AST6A4DAE5562",
    state_code: "23",
    name: "Bizerte Governorate",
    country_code: "TN",
    lat: 37.1609397,
    lon: 9.634135,
  },
  {
    art_state_id: "ASTAACE47FDD0",
    state_code: "32",
    name: "Jendouba Governorate",
    country_code: "TN",
    lat: 36.7181862,
    lon: 8.7481167,
  },
  {
    art_state_id: "ASTC5325125E5",
    state_code: "52",
    name: "Monastir Governorate",
    country_code: "TN",
    lat: 35.7642515,
    lon: 10.8112885,
  },
  {
    art_state_id: "ASTD3D5CB3DF4",
    state_code: "11",
    name: "Tunis Governorate",
    country_code: "TN",
    lat: 36.8374946,
    lon: 10.1927389,
  },
  {
    art_state_id: "AST3DC8AF6DD3",
    state_code: "14",
    name: "Manouba Governorate",
    country_code: "TN",
    lat: 36.8446504,
    lon: 9.8571416,
  },
  {
    art_state_id: "AST1127FE7757",
    state_code: "71",
    name: "Gafsa Governorate",
    country_code: "TN",
    lat: 34.3788505,
    lon: 8.6600586,
  },
  {
    art_state_id: "AST535B411749",
    state_code: "61",
    name: "Sfax Governorate",
    country_code: "TN",
    lat: 34.8606581,
    lon: 10.3497895,
  },
  {
    art_state_id: "ASTA7EF51DE5E",
    state_code: "81",
    name: "Gabès Governorate",
    country_code: "TN",
    lat: 33.9459648,
    lon: 9.7232673,
  },
  {
    art_state_id: "AST874D5159AC",
    state_code: "83",
    name: "Tataouine Governorate",
    country_code: "TN",
    lat: 32.1344122,
    lon: 10.0807298,
  },
  {
    art_state_id: "ASTFA4793E925",
    state_code: "82",
    name: "Medenine Governorate",
    country_code: "TN",
    lat: 33.2280565,
    lon: 10.8903099,
  },
  {
    art_state_id: "AST9D31084E64",
    state_code: "33",
    name: "Kef Governorate",
    country_code: "TN",
    lat: 36.1230512,
    lon: 8.6600586,
  },
  {
    art_state_id: "AST02C469B294",
    state_code: "73",
    name: "Kebili Governorate",
    country_code: "TN",
    lat: 33.7071551,
    lon: 8.9714623,
  },
  {
    art_state_id: "ASTE076786167",
    state_code: "34",
    name: "Siliana Governorate",
    country_code: "TN",
    lat: 36.0887208,
    lon: 9.3645335,
  },
  {
    art_state_id: "AST45D9383466",
    state_code: "41",
    name: "Kairouan Governorate",
    country_code: "TN",
    lat: 35.6711663,
    lon: 10.1005469,
  },
  {
    art_state_id: "ASTD6E5263CEA",
    state_code: "22",
    name: "Zaghouan Governorate",
    country_code: "TN",
    lat: 36.4091188,
    lon: 10.1423172,
  },
  {
    art_state_id: "AST22FADFFE1B",
    state_code: "13",
    name: "Ben Arous Governorate",
    country_code: "TN",
    lat: 36.6435606,
    lon: 10.2151578,
  },
  {
    art_state_id: "AST0F09E736CD",
    state_code: "43",
    name: "Sidi Bouzid Governorate",
    country_code: "TN",
    lat: 35.0354386,
    lon: 9.4839392,
  },
  {
    art_state_id: "ASTBC4AFFCCB6",
    state_code: "53",
    name: "Mahdia Governorate",
    country_code: "TN",
    lat: 35.3352558,
    lon: 10.8903099,
  },
  {
    art_state_id: "ASTBD9666DC97",
    state_code: "72",
    name: "Tozeur Governorate",
    country_code: "TN",
    lat: 33.9789491,
    lon: 8.0465185,
  },
  {
    art_state_id: "AST29D6089737",
    state_code: "42",
    name: "Kasserine Governorate",
    country_code: "TN",
    lat: 35.0809148,
    lon: 8.6600586,
  },
  {
    art_state_id: "AST9D63C5F8E1",
    state_code: "51",
    name: "Sousse Governorate",
    country_code: "TN",
    lat: 35.9022267,
    lon: 10.3497895,
  },
  {
    art_state_id: "AST60CBB216F0",
    state_code: "31",
    name: "Kassrine",
    country_code: "TN",
    lat: 35.1722716,
    lon: 8.8307626,
  },
  {
    art_state_id: "ASTA287A2B6FB",
    state_code: "T",
    name: "Ratak Chain",
    country_code: "MH",
    lat: 10.2763276,
    lon: 170.5500937,
  },
  {
    art_state_id: "ASTFF8788FE80",
    state_code: "L",
    name: "Ralik Chain",
    country_code: "MH",
    lat: 8.136146,
    lon: 164.8867956,
  },
  {
    art_state_id: "AST46F608C3E6",
    state_code: "C",
    name: "Centrale Region",
    country_code: "TG",
    lat: 8.6586029,
    lon: 1.0586135,
  },
  {
    art_state_id: "ASTFA10436EB5",
    state_code: "M",
    name: "Maritime",
    country_code: "TG",
    lat: 41.6551493,
    lon: -83.5278467,
  },
  {
    art_state_id: "ASTE594AFE92A",
    state_code: "P",
    name: "Plateaux Region",
    country_code: "TG",
    lat: 7.6101378,
    lon: 1.0586135,
  },
  {
    art_state_id: "AST16755A0F5C",
    state_code: "S",
    name: "Savanes Region",
    country_code: "TG",
    lat: 10.5291781,
    lon: 0.5257823,
  },
  {
    art_state_id: "ASTA1D1F6F164",
    state_code: "K",
    name: "Kara Region",
    country_code: "TG",
    lat: 9.7216393,
    lon: 1.0586135,
  },
  {
    art_state_id: "AST90CE884AFB",
    state_code: "TRK",
    name: "Chuuk State",
    country_code: "FM",
    lat: 7.1386759,
    lon: 151.5593065,
  },
  {
    art_state_id: "ASTD5F6EFB1F9",
    state_code: "PNI",
    name: "Pohnpei State",
    country_code: "FM",
    lat: 6.8541254,
    lon: 158.2623822,
  },
  {
    art_state_id: "ASTEF3DB72B53",
    state_code: "YAP",
    name: "Yap State",
    country_code: "FM",
    lat: 8.671649,
    lon: 142.8439335,
  },
  {
    art_state_id: "AST207C540F5F",
    state_code: "KSA",
    name: "Kosrae State",
    country_code: "FM",
    lat: 5.3095618,
    lon: 162.9814877,
  },
  {
    art_state_id: "ASTC44D2864E7",
    state_code: "04",
    name: "Vaavu Atoll",
    country_code: "MV",
    lat: 3.3955438,
    lon: 73.5122928,
  },
  {
    art_state_id: "ASTC169574D17",
    state_code: "24",
    name: "Shaviyani Atoll",
    country_code: "MV",
    lat: 6.17511,
    lon: 73.1349605,
  },
  {
    art_state_id: "AST2C41A23B29",
    state_code: "07",
    name: "Haa Alif Atoll",
    country_code: "MV",
    lat: 6.9903488,
    lon: 72.9460566,
  },
  {
    art_state_id: "AST17919DD415",
    state_code: "02",
    name: "Alif Alif Atoll",
    country_code: "MV",
    lat: 4.085,
    lon: 72.8515479,
  },
  {
    art_state_id: "AST8413F43AB6",
    state_code: "NO",
    name: "North Province",
    country_code: "MV",
    lat: 8.8855027,
    lon: 80.2767327,
  },
  {
    art_state_id: "AST123F66BF87",
    state_code: "NC",
    name: "North Central Province",
    country_code: "MV",
    lat: 4.7064352,
    lon: 73.3287853,
  },
  {
    art_state_id: "ASTAECA1B8CCC",
    state_code: "17",
    name: "Dhaalu Atoll",
    country_code: "MV",
    lat: 2.8468502,
    lon: 72.9460566,
  },
  {
    art_state_id: "ASTB19FB3E923",
    state_code: "08",
    name: "Thaa Atoll",
    country_code: "MV",
    lat: 2.4311161,
    lon: 73.1821623,
  },
  {
    art_state_id: "AST0D0364C6B0",
    state_code: "25",
    name: "Noonu Atoll",
    country_code: "MV",
    lat: 5.8551276,
    lon: 73.323708,
  },
  {
    art_state_id: "AST5A462E3B38",
    state_code: "US",
    name: "Upper South Province",
    country_code: "MV",
    lat: 0.2307,
    lon: 73.2794846,
  },
  {
    art_state_id: "AST8EBDA01398",
    state_code: "01",
    name: "Addu Atoll",
    country_code: "MV",
    lat: -0.6300995,
    lon: 73.1585626,
  },
  {
    art_state_id: "ASTB2C2BF94E3",
    state_code: "29",
    name: "Gnaviyani Atoll",
    country_code: "MV",
    lat: -0.3006425,
    lon: 73.4239143,
  },
  {
    art_state_id: "ASTE3E9347586",
    state_code: "26",
    name: "Kaafu Atoll",
    country_code: "MV",
    lat: 4.4558979,
    lon: 73.5594128,
  },
  {
    art_state_id: "AST556354C335",
    state_code: "23",
    name: "Haa Dhaalu Atoll",
    country_code: "MV",
    lat: 6.5782717,
    lon: 72.9460566,
  },
  {
    art_state_id: "AST2644C8E3A5",
    state_code: "27",
    name: "Gaafu Alif Atoll",
    country_code: "MV",
    lat: 0.6124813,
    lon: 73.323708,
  },
  {
    art_state_id: "ASTF6A9C8A547",
    state_code: "14",
    name: "Faafu Atoll",
    country_code: "MV",
    lat: 3.2309409,
    lon: 72.9460566,
  },
  {
    art_state_id: "ASTEB6509EF70",
    state_code: "00",
    name: "Alif Dhaal Atoll",
    country_code: "MV",
    lat: 3.6543302,
    lon: 72.8042797,
  },
  {
    art_state_id: "AST90C7921ED2",
    state_code: "05",
    name: "Laamu Atoll",
    country_code: "MV",
    lat: 1.9430737,
    lon: 73.4180211,
  },
  {
    art_state_id: "AST07AFECE0C9",
    state_code: "13",
    name: "Raa Atoll",
    country_code: "MV",
    lat: 5.6006457,
    lon: 72.9460566,
  },
  {
    art_state_id: "AST48F5005A52",
    state_code: "28",
    name: "Gaafu Dhaalu Atoll",
    country_code: "MV",
    lat: 0.358804,
    lon: 73.1821623,
  },
  {
    art_state_id: "AST7D6F90D994",
    state_code: "CE",
    name: "Central Province",
    country_code: "MV",
    lat: 4.7064352,
    lon: 73.3287853,
  },
  {
    art_state_id: "ASTBBD3AE90AD",
    state_code: "SU",
    name: "South Province",
    country_code: "MV",
    lat: -21.7482006,
    lon: 166.1783739,
  },
  {
    art_state_id: "ASTE90EC1D5EE",
    state_code: "SC",
    name: "South Central Province",
    country_code: "MV",
    lat: 7.2564996,
    lon: 80.7214417,
  },
  {
    art_state_id: "AST75445F13B6",
    state_code: "03",
    name: "Lhaviyani Atoll",
    country_code: "MV",
    lat: 5.3747021,
    lon: 73.5122928,
  },
  {
    art_state_id: "ASTF1ED89BB7C",
    state_code: "12",
    name: "Meemu Atoll",
    country_code: "MV",
    lat: 3.0090345,
    lon: 73.5122928,
  },
  {
    art_state_id: "AST5C4E455CFE",
    state_code: "MLE",
    name: "Malé",
    country_code: "MV",
    lat: 46.3488867,
    lon: 10.9072489,
  },
  {
    art_state_id: "AST6119D0BA8D",
    state_code: "UT",
    name: "Utrecht",
    country_code: "NL",
    lat: 52.0907374,
    lon: 5.1214201,
  },
  {
    art_state_id: "AST60307FF971",
    state_code: "GE",
    name: "Gelderland",
    country_code: "NL",
    lat: 52.045155,
    lon: 5.8718235,
  },
  {
    art_state_id: "AST50FB50D9E8",
    state_code: "NH",
    name: "North Holland",
    country_code: "NL",
    lat: 52.5205869,
    lon: 4.788474,
  },
  {
    art_state_id: "AST6ADC33CDB4",
    state_code: "DR",
    name: "Drenthe",
    country_code: "NL",
    lat: 52.9476012,
    lon: 6.6230586,
  },
  {
    art_state_id: "AST686821CC28",
    state_code: "ZH",
    name: "South Holland",
    country_code: "NL",
    lat: 41.6008681,
    lon: -87.6069894,
  },
  {
    art_state_id: "ASTACDAFBAC88",
    state_code: "LI",
    name: "Limburg",
    country_code: "NL",
    lat: 51.4427238,
    lon: 6.0608726,
  },
  {
    art_state_id: "ASTB588044417",
    state_code: "BQ3",
    name: "Sint Eustatius",
    country_code: "NL",
    lat: 17.4890306,
    lon: -62.973555,
  },
  {
    art_state_id: "ASTF02B7CB3C5",
    state_code: "GR",
    name: "Groningen",
    country_code: "NL",
    lat: 53.2193835,
    lon: 6.5665017,
  },
  {
    art_state_id: "AST6321B51F6A",
    state_code: "OV",
    name: "Overijssel",
    country_code: "NL",
    lat: 52.4387814,
    lon: 6.5016411,
  },
  {
    art_state_id: "AST0EB0F1AC84",
    state_code: "FL",
    name: "Flevoland",
    country_code: "NL",
    lat: 52.5279781,
    lon: 5.5953508,
  },
  {
    art_state_id: "AST8CADAE63F1",
    state_code: "ZE",
    name: "Zeeland",
    country_code: "NL",
    lat: 51.4940309,
    lon: 3.8496815,
  },
  {
    art_state_id: "ASTA0849ED281",
    state_code: "BQ2",
    name: "Saba",
    country_code: "NL",
    lat: 17.6354642,
    lon: -63.2326763,
  },
  {
    art_state_id: "AST90CE97DF0D",
    state_code: "FR",
    name: "Friesland",
    country_code: "NL",
    lat: 53.1641642,
    lon: 5.7817542,
  },
  {
    art_state_id: "AST111DCB0A68",
    state_code: "NB",
    name: "North Brabant",
    country_code: "NL",
    lat: 51.4826537,
    lon: 5.2321687,
  },
  {
    art_state_id: "ASTCAF8E673ED",
    state_code: "BQ1",
    name: "Bonaire",
    country_code: "NL",
    lat: 12.2018902,
    lon: -68.2623822,
  },
  {
    art_state_id: "ASTBA2ED56953",
    state_code: "03",
    name: "Savanes Region",
    country_code: "CI",
    lat: 7.9897371,
    lon: -5.5679458,
  },
  {
    art_state_id: "AST635E2B3452",
    state_code: "16",
    name: "Agnéby",
    country_code: "CI",
    lat: 5.3224503,
    lon: -4.3449529,
  },
  {
    art_state_id: "ASTA39DD41A2E",
    state_code: "LG",
    name: "Lagunes District",
    country_code: "CI",
    lat: 5.8827334,
    lon: -4.2333355,
  },
  {
    art_state_id: "AST97FF7879F7",
    state_code: "15",
    name: "Sud-Bandama",
    country_code: "CI",
    lat: 5.5357083,
    lon: -5.5617279,
  },
  {
    art_state_id: "ASTF616811856",
    state_code: "MG",
    name: "Montagnes District",
    country_code: "CI",
    lat: 7.3762373,
    lon: -7.4381355,
  },
  {
    art_state_id: "ASTEE7C43CDAA",
    state_code: "05",
    name: "Moyen-Comoé",
    country_code: "CI",
    lat: 6.6514917,
    lon: -3.5003454,
  },
  {
    art_state_id: "ASTA108B7CDDE",
    state_code: "12",
    name: "Marahoué Region",
    country_code: "CI",
    lat: 6.8846207,
    lon: -5.8987139,
  },
  {
    art_state_id: "ASTE9D5A1578F",
    state_code: "LC",
    name: "Lacs District",
    country_code: "CI",
    lat: 48.1980169,
    lon: -80.4564412,
  },
  {
    art_state_id: "AST0F1F8540F0",
    state_code: "18",
    name: "Fromager",
    country_code: "CI",
    lat: 45.5450213,
    lon: -73.6046223,
  },
  {
    art_state_id: "ASTC5F9A7EC40",
    state_code: "AB",
    name: "Abidjan",
    country_code: "CI",
    lat: 5.3599517,
    lon: -4.0082563,
  },
  {
    art_state_id: "ASTC107266953",
    state_code: "09",
    name: "Bas-Sassandra Region",
    country_code: "CI",
    lat: 5.3567916,
    lon: -6.7493993,
  },
  {
    art_state_id: "AST21E77746C4",
    state_code: "17",
    name: "Bafing Region",
    country_code: "CI",
    lat: 8.3252047,
    lon: -7.5247243,
  },
  {
    art_state_id: "AST816E4071CA",
    state_code: "VB",
    name: "Vallée du Bandama District",
    country_code: "CI",
    lat: 8.278978,
    lon: -4.8935627,
  },
  {
    art_state_id: "AST561C3B3D5B",
    state_code: "02",
    name: "Haut-Sassandra",
    country_code: "CI",
    lat: 6.8757848,
    lon: -6.5783387,
  },
  {
    art_state_id: "AST2CDD504687",
    state_code: "01",
    name: "Lagunes region",
    country_code: "CI",
    lat: 5.8827334,
    lon: -4.2333355,
  },
  {
    art_state_id: "AST3C3C5F8C10",
    state_code: "07",
    name: "Lacs Region",
    country_code: "CI",
    lat: 47.7395866,
    lon: -70.4186652,
  },
  {
    art_state_id: "AST55064741E5",
    state_code: "ZZ",
    name: "Zanzan Region",
    country_code: "CI",
    lat: 8.8207904,
    lon: -3.4195527,
  },
  {
    art_state_id: "ASTD2632523DE",
    state_code: "10",
    name: "Denguélé Region",
    country_code: "CI",
    lat: 9.4662372,
    lon: -7.4381355,
  },
  {
    art_state_id: "AST37BBEEC18C",
    state_code: "BS",
    name: "Bas-Sassandra District",
    country_code: "CI",
    lat: 5.2798356,
    lon: -6.1526985,
  },
  {
    art_state_id: "ASTFA7A19D85E",
    state_code: "DN",
    name: "Denguélé District",
    country_code: "CI",
    lat: 48.0707763,
    lon: -68.5609341,
  },
  {
    art_state_id: "AST4650FD285D",
    state_code: "06",
    name: "Dix-Huit Montagnes",
    country_code: "CI",
    lat: 7.3762373,
    lon: -7.4381355,
  },
  {
    art_state_id: "AST62078C5387",
    state_code: "19",
    name: "Moyen-Cavally",
    country_code: "CI",
    lat: 6.5208793,
    lon: -7.6114217,
  },
  {
    art_state_id: "AST746585B50C",
    state_code: "04",
    name: "Vallée du Bandama Region",
    country_code: "CI",
    lat: 8.278978,
    lon: -4.8935627,
  },
  {
    art_state_id: "AST4322207B41",
    state_code: "SM",
    name: "Sassandra-Marahoué District",
    country_code: "CI",
    lat: 6.8803348,
    lon: -6.2375947,
  },
  {
    art_state_id: "AST9693A33FEF",
    state_code: "14",
    name: "Worodougou",
    country_code: "CI",
    lat: 8.2548962,
    lon: -6.5783387,
  },
  {
    art_state_id: "AST8FB69FBAB2",
    state_code: "WR",
    name: "Woroba District",
    country_code: "CI",
    lat: 8.2491372,
    lon: -6.9209135,
  },
  {
    art_state_id: "ASTC87FF9763E",
    state_code: "GD",
    name: "Gôh-Djiboua District",
    country_code: "CI",
    lat: 5.8711393,
    lon: -5.5617279,
  },
  {
    art_state_id: "AST7165EFDACC",
    state_code: "13",
    name: "Sud-Comoé",
    country_code: "CI",
    lat: 5.552793,
    lon: -3.2583626,
  },
  {
    art_state_id: "ASTCA2B7C185F",
    state_code: "YM",
    name: "Yamoussoukro",
    country_code: "CI",
    lat: 6.8276228,
    lon: -5.2893433,
  },
  {
    art_state_id: "AST8014DAA2AF",
    state_code: "CM",
    name: "Comoé District",
    country_code: "CI",
    lat: 5.552793,
    lon: -3.2583626,
  },
  {
    art_state_id: "AST828913AFDB",
    state_code: "11",
    name: "N'zi-Comoé",
    country_code: "CI",
    lat: 7.2456749,
    lon: -4.2333355,
  },
  {
    art_state_id: "ASTD0786DC612",
    state_code: "EN",
    name: "Far North",
    country_code: "CM",
    lat: 66.7613451,
    lon: 124.123753,
  },
  {
    art_state_id: "ASTD743F06804",
    state_code: "NW",
    name: "Northwest",
    country_code: "CM",
    lat: 36.3711857,
    lon: -94.1934606,
  },
  {
    art_state_id: "AST5C2078BAAD",
    state_code: "SW",
    name: "Southwest",
    country_code: "CM",
    lat: 36.1908813,
    lon: -95.8897448,
  },
  {
    art_state_id: "ASTC5AB4B7CEF",
    state_code: "SU",
    name: "South",
    country_code: "CM",
    lat: 37.631595,
    lon: -97.3458409,
  },
  {
    art_state_id: "ASTC1978A16C5",
    state_code: "CE",
    name: "Centre",
    country_code: "CM",
    lat: 4.686267,
    lon: 11.9414512,
  },
  {
    art_state_id: "AST4F661A0D63",
    state_code: "ES",
    name: "East",
    country_code: "CM",
    lat: 39.0185336,
    lon: -94.2792411,
  },
  {
    art_state_id: "AST6585078D7D",
    state_code: "LT",
    name: "Littoral",
    country_code: "CM",
    lat: 48.4622757,
    lon: -68.5178071,
  },
  {
    art_state_id: "AST6518876F29",
    state_code: "AD",
    name: "Adamawa",
    country_code: "CM",
    lat: 9.3264751,
    lon: 12.3983853,
  },
  {
    art_state_id: "AST4AC85536E3",
    state_code: "OU",
    name: "West",
    country_code: "CM",
    lat: 37.0364989,
    lon: -95.6705987,
  },
  {
    art_state_id: "AST7BEB1044F1",
    state_code: "NO",
    name: "North",
    country_code: "CM",
    lat: 37.09024,
    lon: -95.712891,
  },
  {
    art_state_id: "ASTB3BE05C7FA",
    state_code: "B",
    name: "Banjul",
    country_code: "GM",
    lat: 13.4548761,
    lon: -16.5790323,
  },
  {
    art_state_id: "ASTFE70DCDA22",
    state_code: "W",
    name: "West Coast Division",
    country_code: "GM",
    lat: 5.9772798,
    lon: 116.0754288,
  },
  {
    art_state_id: "AST4C977870C8",
    state_code: "U",
    name: "Upper River Division",
    country_code: "GM",
    lat: 13.4257366,
    lon: -14.0072348,
  },
  {
    art_state_id: "AST705E885446",
    state_code: "M",
    name: "Central River Division",
    country_code: "GM",
    lat: 13.5994469,
    lon: -14.8921668,
  },
  {
    art_state_id: "AST51E76FD10E",
    state_code: "L",
    name: "Lower River Division",
    country_code: "GM",
    lat: 13.3553306,
    lon: -15.92299,
  },
  {
    art_state_id: "AST7CCC57AFA5",
    state_code: "N",
    name: "North Bank Division",
    country_code: "GM",
    lat: 13.5285436,
    lon: -16.0169971,
  },
  {
    art_state_id: "AST705E2FD178",
    state_code: "BE",
    name: "Beyla Prefecture",
    country_code: "GN",
    lat: 8.9198178,
    lon: -8.3088441,
  },
  {
    art_state_id: "ASTC2733662FF",
    state_code: "MD",
    name: "Mandiana Prefecture",
    country_code: "GN",
    lat: 10.6172827,
    lon: -8.6985716,
  },
  {
    art_state_id: "ASTF1D3AFFA2B",
    state_code: "YO",
    name: "Yomou Prefecture",
    country_code: "GN",
    lat: 7.5696279,
    lon: -9.2591571,
  },
  {
    art_state_id: "ASTBE28CDA6E2",
    state_code: "FR",
    name: "Fria Prefecture",
    country_code: "GN",
    lat: 10.3674543,
    lon: -13.5841871,
  },
  {
    art_state_id: "AST5023FED923",
    state_code: "B",
    name: "Boké Region",
    country_code: "GN",
    lat: 11.1864672,
    lon: -14.1001326,
  },
  {
    art_state_id: "ASTFAC0D50C51",
    state_code: "L",
    name: "Labé Region",
    country_code: "GN",
    lat: 11.3232042,
    lon: -12.2891314,
  },
  {
    art_state_id: "AST48754CAFC3",
    state_code: "NZ",
    name: "Nzérékoré Prefecture",
    country_code: "GN",
    lat: 7.7478359,
    lon: -8.8252502,
  },
  {
    art_state_id: "ASTBFEF2ECFA7",
    state_code: "DB",
    name: "Dabola Prefecture",
    country_code: "GN",
    lat: 10.7297806,
    lon: -11.1107854,
  },
  {
    art_state_id: "AST351D2136F3",
    state_code: "LA",
    name: "Labé Prefecture",
    country_code: "GN",
    lat: 11.3541939,
    lon: -12.3463875,
  },
  {
    art_state_id: "AST0F0F6F3A85",
    state_code: "DU",
    name: "Dubréka Prefecture",
    country_code: "GN",
    lat: 9.7907348,
    lon: -13.5147735,
  },
  {
    art_state_id: "AST4C9D07D962",
    state_code: "FA",
    name: "Faranah Prefecture",
    country_code: "GN",
    lat: 9.9057399,
    lon: -10.8000051,
  },
  {
    art_state_id: "AST5B1388DD8D",
    state_code: "FO",
    name: "Forécariah Prefecture",
    country_code: "GN",
    lat: 9.3886187,
    lon: -13.0817903,
  },
  {
    art_state_id: "AST553A0ED960",
    state_code: "N",
    name: "Nzérékoré Region",
    country_code: "GN",
    lat: 8.038587,
    lon: -8.8362755,
  },
  {
    art_state_id: "AST40FA3B8B6F",
    state_code: "GA",
    name: "Gaoual Prefecture",
    country_code: "GN",
    lat: 11.5762804,
    lon: -13.3587288,
  },
  {
    art_state_id: "AST2BF65D0464",
    state_code: "C",
    name: "Conakry",
    country_code: "GN",
    lat: 9.6411855,
    lon: -13.5784012,
  },
  {
    art_state_id: "AST9198A484E7",
    state_code: "TE",
    name: "Télimélé Prefecture",
    country_code: "GN",
    lat: 10.9089364,
    lon: -13.0299331,
  },
  {
    art_state_id: "AST5B2AF3B4A5",
    state_code: "DI",
    name: "Dinguiraye Prefecture",
    country_code: "GN",
    lat: 11.6844222,
    lon: -10.8000051,
  },
  {
    art_state_id: "ASTC6E46366F9",
    state_code: "MM",
    name: "Mamou Prefecture",
    country_code: "GN",
    lat: 10.5736024,
    lon: -11.8891721,
  },
  {
    art_state_id: "AST5C991B7F0D",
    state_code: "LE",
    name: "Lélouma Prefecture",
    country_code: "GN",
    lat: 11.183333,
    lon: -12.933333,
  },
  {
    art_state_id: "AST0F7C7343B1",
    state_code: "KS",
    name: "Kissidougou Prefecture",
    country_code: "GN",
    lat: 9.2252022,
    lon: -10.0807298,
  },
  {
    art_state_id: "ASTCC5C95E3B1",
    state_code: "KB",
    name: "Koubia Prefecture",
    country_code: "GN",
    lat: 11.582354,
    lon: -11.8920237,
  },
  {
    art_state_id: "AST5D3B9C6E13",
    state_code: "KD",
    name: "Kindia Prefecture",
    country_code: "GN",
    lat: 10.1013292,
    lon: -12.7135121,
  },
  {
    art_state_id: "AST2E3F59D555",
    state_code: "PI",
    name: "Pita Prefecture",
    country_code: "GN",
    lat: 10.8062086,
    lon: -12.7135121,
  },
  {
    art_state_id: "AST529A5BED20",
    state_code: "KO",
    name: "Kouroussa Prefecture",
    country_code: "GN",
    lat: 10.6489229,
    lon: -9.8850586,
  },
  {
    art_state_id: "ASTE1C64AC785",
    state_code: "TO",
    name: "Tougué Prefecture",
    country_code: "GN",
    lat: 11.3841583,
    lon: -11.6157773,
  },
  {
    art_state_id: "ASTD4ADDF811F",
    state_code: "K",
    name: "Kankan Region",
    country_code: "GN",
    lat: 10.120923,
    lon: -9.5450974,
  },
  {
    art_state_id: "ASTC4576F063B",
    state_code: "M",
    name: "Mamou Region",
    country_code: "GN",
    lat: 10.5736024,
    lon: -11.8891721,
  },
  {
    art_state_id: "AST6CEF425CDA",
    state_code: "BF",
    name: "Boffa Prefecture",
    country_code: "GN",
    lat: 10.1808254,
    lon: -14.0391615,
  },
  {
    art_state_id: "ASTB7827F9876",
    state_code: "ML",
    name: "Mali Prefecture",
    country_code: "GN",
    lat: 11.983709,
    lon: -12.2547919,
  },
  {
    art_state_id: "ASTEE5456CD2D",
    state_code: "D",
    name: "Kindia Region",
    country_code: "GN",
    lat: 10.1781694,
    lon: -12.989615,
  },
  {
    art_state_id: "AST7792CD6849",
    state_code: "MC",
    name: "Macenta Prefecture",
    country_code: "GN",
    lat: 8.4615795,
    lon: -9.2785583,
  },
  {
    art_state_id: "AST22A1DEAEB9",
    state_code: "KN",
    name: "Koundara Prefecture",
    country_code: "GN",
    lat: 12.4894021,
    lon: -13.3067562,
  },
  {
    art_state_id: "ASTA2902B2B6A",
    state_code: "KA",
    name: "Kankan Prefecture",
    country_code: "GN",
    lat: 10.3034465,
    lon: -9.3673084,
  },
  {
    art_state_id: "ASTE50A7E07C8",
    state_code: "CO",
    name: "Coyah Prefecture",
    country_code: "GN",
    lat: 9.7715535,
    lon: -13.3125299,
  },
  {
    art_state_id: "AST57B23DE202",
    state_code: "DL",
    name: "Dalaba Prefecture",
    country_code: "GN",
    lat: 10.6868176,
    lon: -12.2490697,
  },
  {
    art_state_id: "ASTFE29582FFB",
    state_code: "SI",
    name: "Siguiri Prefecture",
    country_code: "GN",
    lat: 11.4148113,
    lon: -9.1788304,
  },
  {
    art_state_id: "AST0A9E178E81",
    state_code: "LO",
    name: "Lola Prefecture",
    country_code: "GN",
    lat: 7.9613818,
    lon: -8.3964938,
  },
  {
    art_state_id: "AST645DB85954",
    state_code: "BK",
    name: "Boké Prefecture",
    country_code: "GN",
    lat: 11.0847379,
    lon: -14.3791912,
  },
  {
    art_state_id: "AST3E9C7B3117",
    state_code: "KE",
    name: "Kérouané Prefecture",
    country_code: "GN",
    lat: 9.2536643,
    lon: -9.0128926,
  },
  {
    art_state_id: "AST9175895141",
    state_code: "GU",
    name: "Guéckédou Prefecture",
    country_code: "GN",
    lat: 8.5649688,
    lon: -10.1311163,
  },
  {
    art_state_id: "ASTB4752AA1A7",
    state_code: "TO",
    name: "Tombali Region",
    country_code: "GW",
    lat: 11.3632696,
    lon: -14.9856176,
  },
  {
    art_state_id: "AST8B08CAC975",
    state_code: "CA",
    name: "Cacheu Region",
    country_code: "GW",
    lat: 12.0551416,
    lon: -16.0640179,
  },
  {
    art_state_id: "AST55BF14E652",
    state_code: "BM",
    name: "Biombo Region",
    country_code: "GW",
    lat: 11.8529061,
    lon: -15.7351171,
  },
  {
    art_state_id: "ASTAB456EDA63",
    state_code: "QU",
    name: "Quinara Region",
    country_code: "GW",
    lat: 11.795562,
    lon: -15.1726816,
  },
  {
    art_state_id: "AST96BB35AE91",
    state_code: "S",
    name: "Sul Province",
    country_code: "GW",
    lat: -10.2866578,
    lon: 20.7122465,
  },
  {
    art_state_id: "AST367C67B343",
    state_code: "N",
    name: "Norte Province",
    country_code: "GW",
    lat: 7.8721811,
    lon: 123.8857747,
  },
  {
    art_state_id: "ASTE55F88C983",
    state_code: "OI",
    name: "Oio Region",
    country_code: "GW",
    lat: 12.2760709,
    lon: -15.3131185,
  },
  {
    art_state_id: "ASTAAFF02E587",
    state_code: "GA",
    name: "Gabú Region",
    country_code: "GW",
    lat: 11.8962488,
    lon: -14.1001326,
  },
  {
    art_state_id: "AST3F4C30832E",
    state_code: "BA",
    name: "Bafatá",
    country_code: "GW",
    lat: 12.1735243,
    lon: -14.652952,
  },
  {
    art_state_id: "AST180A0FAAC0",
    state_code: "L",
    name: "Leste Province",
    country_code: "GW",
    lat: 12.100035,
    lon: -14.9000214,
  },
  {
    art_state_id: "ASTC81B377050",
    state_code: "BL",
    name: "Bolama Region",
    country_code: "GW",
    lat: 11.1480591,
    lon: -16.1345705,
  },
  {
    art_state_id: "AST77A89D1BC2",
    state_code: "9",
    name: "Woleu-Ntem Province",
    country_code: "GA",
    lat: 2.2989827,
    lon: 11.4466914,
  },
  {
    art_state_id: "ASTF2912DCEE1",
    state_code: "6",
    name: "Ogooué-Ivindo Province",
    country_code: "GA",
    lat: 0.8818311,
    lon: 13.1740348,
  },
  {
    art_state_id: "AST5B4CE7AF7B",
    state_code: "5",
    name: "Nyanga Province",
    country_code: "GA",
    lat: -2.8821033,
    lon: 11.1617356,
  },
  {
    art_state_id: "ASTAF858B8B80",
    state_code: "2",
    name: "Haut-Ogooué Province",
    country_code: "GA",
    lat: -1.4762544,
    lon: 13.914399,
  },
  {
    art_state_id: "ASTCA13F4219D",
    state_code: "1",
    name: "Estuaire Province",
    country_code: "GA",
    lat: 0.4432864,
    lon: 10.0807298,
  },
  {
    art_state_id: "AST39A57FB88E",
    state_code: "8",
    name: "Ogooué-Maritime Province",
    country_code: "GA",
    lat: -1.3465975,
    lon: 9.7232673,
  },
  {
    art_state_id: "ASTA631C8153C",
    state_code: "7",
    name: "Ogooué-Lolo Province",
    country_code: "GA",
    lat: -0.8844093,
    lon: 12.4380581,
  },
  {
    art_state_id: "ASTCC1D2B1F0D",
    state_code: "3",
    name: "Moyen-Ogooué Province",
    country_code: "GA",
    lat: -0.442784,
    lon: 10.439656,
  },
  {
    art_state_id: "ASTFC21876AEE",
    state_code: "4",
    name: "Ngounié Province",
    country_code: "GA",
    lat: -1.4930303,
    lon: 10.9807003,
  },
  {
    art_state_id: "AST78F4496EDB",
    state_code: "TU",
    name: "Tshuapa District",
    country_code: "CD",
    lat: -0.9903023,
    lon: 23.0288844,
  },
  {
    art_state_id: "AST9BCEA5FF25",
    state_code: "TA",
    name: "Tanganyika Province",
    country_code: "CD",
    lat: -6.2740118,
    lon: 27.9249002,
  },
  {
    art_state_id: "ASTFFA515973A",
    state_code: "HU",
    name: "Haut-Uele",
    country_code: "CD",
    lat: 3.5845154,
    lon: 28.299435,
  },
  {
    art_state_id: "AST5EDCA0A5E0",
    state_code: "KE",
    name: "Kasaï-Oriental",
    country_code: "CD",
    lat: -6.033623,
    lon: 23.5728501,
  },
  {
    art_state_id: "AST1983D886E1",
    state_code: "OR",
    name: "Orientale Province",
    country_code: "CD",
    lat: 1.6406296,
    lon: 26.419389,
  },
  {
    art_state_id: "AST577BAF3A87",
    state_code: "KW",
    name: "Kasaï-Occidental",
    country_code: "CD",
    lat: -5.3205473,
    lon: 21.8568586,
  },
  {
    art_state_id: "AST25D56B0745",
    state_code: "SK",
    name: "South Kivu",
    country_code: "CD",
    lat: -3.011658,
    lon: 28.299435,
  },
  {
    art_state_id: "AST0234096811",
    state_code: "NU",
    name: "Nord-Ubangi District",
    country_code: "CD",
    lat: 3.7878726,
    lon: 21.4752851,
  },
  {
    art_state_id: "AST3F15D0236F",
    state_code: "KG",
    name: "Kwango District",
    country_code: "CD",
    lat: -6.4337409,
    lon: 17.668887,
  },
  {
    art_state_id: "AST4AB80655B7",
    state_code: "KN",
    name: "Kinshasa",
    country_code: "CD",
    lat: -4.4419311,
    lon: 15.2662931,
  },
  {
    art_state_id: "ASTCBC8B477A7",
    state_code: "KA",
    name: "Katanga Province",
    country_code: "CD",
    lat: -8.8851145,
    lon: 26.419389,
  },
  {
    art_state_id: "AST1AB5DF0FC7",
    state_code: "SA",
    name: "Sankuru District",
    country_code: "CD",
    lat: -2.8437453,
    lon: 23.3823545,
  },
  {
    art_state_id: "ASTE57FD2B67A",
    state_code: "EQ",
    name: "Équateur",
    country_code: "CD",
    lat: -1.831239,
    lon: -78.183406,
  },
  {
    art_state_id: "ASTB2CD0C97F1",
    state_code: "MA",
    name: "Maniema",
    country_code: "CD",
    lat: -3.0730929,
    lon: 26.0413889,
  },
  {
    art_state_id: "ASTEAACAFDFA5",
    state_code: "BC",
    name: "Bas-Congo province",
    country_code: "CD",
    lat: -5.2365685,
    lon: 13.914399,
  },
  {
    art_state_id: "AST4A758D941A",
    state_code: "LO",
    name: "Lomami Province",
    country_code: "CD",
    lat: -6.1453931,
    lon: 24.524264,
  },
  {
    art_state_id: "ASTFAEB9C951D",
    state_code: "SU",
    name: "Sud-Ubangi",
    country_code: "CD",
    lat: 3.2299942,
    lon: 19.1880047,
  },
  {
    art_state_id: "ASTBA202A3734",
    state_code: "NK",
    name: "North Kivu",
    country_code: "CD",
    lat: -0.7917729,
    lon: 29.0459927,
  },
  {
    art_state_id: "AST0B210C4ACF",
    state_code: "HK",
    name: "Haut-Katanga Province",
    country_code: "CD",
    lat: -10.4102075,
    lon: 27.5495846,
  },
  {
    art_state_id: "ASTBFB4175221",
    state_code: "IT",
    name: "Ituri Interim Administration",
    country_code: "CD",
    lat: 1.5957682,
    lon: 29.4179324,
  },
  {
    art_state_id: "ASTBBF35816B3",
    state_code: "MO",
    name: "Mongala District",
    country_code: "CD",
    lat: 1.9962324,
    lon: 21.4752851,
  },
  {
    art_state_id: "ASTF03C50E37C",
    state_code: "BU",
    name: "Bas-Uele",
    country_code: "CD",
    lat: 3.9901009,
    lon: 24.9042208,
  },
  {
    art_state_id: "AST929D9EA9C6",
    state_code: "BN",
    name: "Bandundu Province",
    country_code: "CD",
    lat: -4.163679,
    lon: 18.8076195,
  },
  {
    art_state_id: "AST980A6D009F",
    state_code: "MN",
    name: "Mai-Ndombe Province",
    country_code: "CD",
    lat: -2.6357434,
    lon: 18.4276047,
  },
  {
    art_state_id: "ASTD690D505D6",
    state_code: "TO",
    name: "Tshopo District",
    country_code: "CD",
    lat: 0.5455462,
    lon: 24.9042208,
  },
  {
    art_state_id: "ASTD13F5F22BD",
    state_code: "KS",
    name: "Kasaï District",
    country_code: "CD",
    lat: -5.0471979,
    lon: 20.7122465,
  },
  {
    art_state_id: "AST3E1B0F71CC",
    state_code: "HL",
    name: "Haut-Lomami District",
    country_code: "CD",
    lat: -7.7052752,
    lon: 24.9042208,
  },
  {
    art_state_id: "ASTB3EE8026B2",
    state_code: "KL",
    name: "Kwilu District",
    country_code: "CD",
    lat: -5.1188825,
    lon: 18.4276047,
  },
  {
    art_state_id: "AST043FD636EE",
    state_code: "CU",
    name: "Cuyuni-Mazaruni",
    country_code: "GY",
    lat: 6.4642141,
    lon: -60.2110752,
  },
  {
    art_state_id: "AST2281EBEDBE",
    state_code: "PT",
    name: "Potaro-Siparuni",
    country_code: "GY",
    lat: 4.7855853,
    lon: -59.2879977,
  },
  {
    art_state_id: "ASTD6C89B1156",
    state_code: "MA",
    name: "Mahaica-Berbice",
    country_code: "GY",
    lat: 6.238496,
    lon: -57.9162555,
  },
  {
    art_state_id: "AST62377160B6",
    state_code: "UD",
    name: "Upper Demerara-Berbice",
    country_code: "GY",
    lat: 5.3064879,
    lon: -58.1892921,
  },
  {
    art_state_id: "AST767EBA257B",
    state_code: "BA",
    name: "Barima-Waini",
    country_code: "GY",
    lat: 7.4882419,
    lon: -59.6564494,
  },
  {
    art_state_id: "AST43BBC78C8E",
    state_code: "PM",
    name: "Pomeroon-Supenaam",
    country_code: "GY",
    lat: 7.1294166,
    lon: -58.9206295,
  },
  {
    art_state_id: "AST4913E5A544",
    state_code: "EB",
    name: "East Berbice-Corentyne",
    country_code: "GY",
    lat: 2.7477922,
    lon: -57.4627259,
  },
  {
    art_state_id: "AST8355D1337A",
    state_code: "DE",
    name: "Demerara-Mahaica",
    country_code: "GY",
    lat: 6.546426,
    lon: -58.0982046,
  },
  {
    art_state_id: "ASTB3EC177379",
    state_code: "ES",
    name: "Essequibo Islands-West Demerara",
    country_code: "GY",
    lat: 6.5720132,
    lon: -58.4629997,
  },
  {
    art_state_id: "ASTFD51B3FC3A",
    state_code: "UT",
    name: "Upper Takutu-Upper Essequibo",
    country_code: "GY",
    lat: 2.9239595,
    lon: -58.7373634,
  },
  {
    art_state_id: "AST14F28DE8C5",
    state_code: "15",
    name: "Presidente Hayes Department",
    country_code: "PY",
    lat: -23.3512605,
    lon: -58.7373634,
  },
  {
    art_state_id: "AST8578780D8B",
    state_code: "14",
    name: "Canindeyú",
    country_code: "PY",
    lat: -24.1378735,
    lon: -55.6689636,
  },
  {
    art_state_id: "AST400D62DEAA",
    state_code: "4",
    name: "Guairá Department",
    country_code: "PY",
    lat: -25.8810932,
    lon: -56.2929381,
  },
  {
    art_state_id: "AST8E6661DB78",
    state_code: "5",
    name: "Caaguazú",
    country_code: "PY",
    lat: -25.4645818,
    lon: -56.013851,
  },
  {
    art_state_id: "AST5F6A8E3F91",
    state_code: "9",
    name: "Paraguarí Department",
    country_code: "PY",
    lat: -25.6262174,
    lon: -57.1520642,
  },
  {
    art_state_id: "ASTE47CB5B5FA",
    state_code: "6",
    name: "Caazapá",
    country_code: "PY",
    lat: -26.1827713,
    lon: -56.3712327,
  },
  {
    art_state_id: "AST408A34CDB9",
    state_code: "2",
    name: "San Pedro Department",
    country_code: "PY",
    lat: -24.1948668,
    lon: -56.561647,
  },
  {
    art_state_id: "AST5CCEFCEF03",
    state_code: "11",
    name: "Central Department",
    country_code: "PY",
    lat: 36.1559229,
    lon: -95.9662075,
  },
  {
    art_state_id: "AST944128CA9F",
    state_code: "7",
    name: "Itapúa",
    country_code: "PY",
    lat: -26.7923623,
    lon: -55.6689636,
  },
  {
    art_state_id: "AST2F95A69FF5",
    state_code: "1",
    name: "Concepción Department",
    country_code: "PY",
    lat: -23.4214264,
    lon: -57.4344451,
  },
  {
    art_state_id: "AST2204E38E2A",
    state_code: "19",
    name: "Boquerón Department",
    country_code: "PY",
    lat: -21.7449254,
    lon: -60.9540073,
  },
  {
    art_state_id: "AST15FFE0CC91",
    state_code: "12",
    name: "Ñeembucú Department",
    country_code: "PY",
    lat: -27.0299114,
    lon: -57.825395,
  },
  {
    art_state_id: "AST99A416C1B2",
    state_code: "13",
    name: "Amambay Department",
    country_code: "PY",
    lat: -22.5590272,
    lon: -56.0249982,
  },
  {
    art_state_id: "ASTBB1AAF522D",
    state_code: "3",
    name: "Cordillera Department",
    country_code: "PY",
    lat: -25.2289491,
    lon: -57.0111681,
  },
  {
    art_state_id: "AST40E4F96A79",
    state_code: "10",
    name: "Alto Paraná Department",
    country_code: "PY",
    lat: -25.6075546,
    lon: -54.9611836,
  },
  {
    art_state_id: "AST9C162CAC18",
    state_code: "16",
    name: "Alto Paraguay Department",
    country_code: "PY",
    lat: -20.0852508,
    lon: -59.4720904,
  },
  {
    art_state_id: "ASTC07E0A0C52",
    state_code: "8",
    name: "Misiones Department",
    country_code: "PY",
    lat: -26.8433512,
    lon: -57.1013188,
  },
  {
    art_state_id: "AST93DBB3747A",
    state_code: "41",
    name: "Jaffna District",
    country_code: "LK",
    lat: 9.6930468,
    lon: 80.1651854,
  },
  {
    art_state_id: "ASTBBCEA4A708",
    state_code: "21",
    name: "Kandy District",
    country_code: "LK",
    lat: 7.2931588,
    lon: 80.6350107,
  },
  {
    art_state_id: "AST4E8F9AFE85",
    state_code: "13",
    name: "Kalutara District",
    country_code: "LK",
    lat: 6.6084686,
    lon: 80.1428584,
  },
  {
    art_state_id: "AST6D3E3540CD",
    state_code: "81",
    name: "Badulla District",
    country_code: "LK",
    lat: 6.9934009,
    lon: 81.0549815,
  },
  {
    art_state_id: "ASTD6C6AE7D23",
    state_code: "33",
    name: "Hambantota District",
    country_code: "LK",
    lat: 6.1535816,
    lon: 81.127149,
  },
  {
    art_state_id: "AST6015CF5989",
    state_code: "31",
    name: "Galle District",
    country_code: "LK",
    lat: 6.057749,
    lon: 80.2175572,
  },
  {
    art_state_id: "AST74A5E6B8B8",
    state_code: "42",
    name: "Kilinochchi District",
    country_code: "LK",
    lat: 9.3677971,
    lon: 80.3213106,
  },
  {
    art_state_id: "ASTFFB7EB2F4F",
    state_code: "23",
    name: "Nuwara Eliya District",
    country_code: "LK",
    lat: 6.9606532,
    lon: 80.7692758,
  },
  {
    art_state_id: "ASTEE76175C63",
    state_code: "53",
    name: "Trincomalee District",
    country_code: "LK",
    lat: 8.6013069,
    lon: 81.1196075,
  },
  {
    art_state_id: "AST9017C33141",
    state_code: "62",
    name: "Puttalam District",
    country_code: "LK",
    lat: 8.0259915,
    lon: 79.8471272,
  },
  {
    art_state_id: "ASTF6FD2F0B80",
    state_code: "92",
    name: "Kegalle District",
    country_code: "LK",
    lat: 7.1204053,
    lon: 80.3213106,
  },
  {
    art_state_id: "AST26DE02E5F0",
    state_code: "2",
    name: "Central Province",
    country_code: "LK",
    lat: 7.3819490000000005,
    lon: 80.72342798602898,
  },
  {
    art_state_id: "AST662CBF4CEC",
    state_code: "52",
    name: "Ampara District",
    country_code: "LK",
    lat: 7.2911685,
    lon: 81.6723761,
  },
  {
    art_state_id: "AST52148EBE61",
    state_code: "7",
    name: "North Central Province",
    country_code: "LK",
    lat: 8.1995638,
    lon: 80.6326916,
  },
  {
    art_state_id: "ASTE7D7333AA0",
    state_code: "3",
    name: "Southern Province",
    country_code: "LK",
    lat: 6.149,
    lon: 80.73181777500001,
  },
  {
    art_state_id: "AST8AFE2B7838",
    state_code: "1",
    name: "Western Province",
    country_code: "LK",
    lat: 6.8275297,
    lon: 79.93129599390583,
  },
  {
    art_state_id: "ASTEB239FEA51",
    state_code: "9",
    name: "Sabaragamuwa Province",
    country_code: "LK",
    lat: 6.7395941,
    lon: 80.365865,
  },
  {
    art_state_id: "ASTCD98FCB8EE",
    state_code: "12",
    name: "Gampaha District",
    country_code: "LK",
    lat: 7.0712619,
    lon: 80.0087746,
  },
  {
    art_state_id: "AST84BCFAACF8",
    state_code: "43",
    name: "Mannar District",
    country_code: "LK",
    lat: 8.9809531,
    lon: 79.9043975,
  },
  {
    art_state_id: "ASTFFD9BA0815",
    state_code: "32",
    name: "Matara District",
    country_code: "LK",
    lat: 5.9449348,
    lon: 80.5487997,
  },
  {
    art_state_id: "AST51FC5DD166",
    state_code: "91",
    name: "Ratnapura district",
    country_code: "LK",
    lat: 6.7055168,
    lon: 80.3848389,
  },
  {
    art_state_id: "AST07770A4D30",
    state_code: "5",
    name: "Eastern Province",
    country_code: "LK",
    lat: 7.7473677,
    lon: 81.58863541343001,
  },
  {
    art_state_id: "AST41F8D2B812",
    state_code: "44",
    name: "Vavuniya District",
    country_code: "LK",
    lat: 8.7594739,
    lon: 80.5000334,
  },
  {
    art_state_id: "AST869579FC9B",
    state_code: "22",
    name: "Matale District",
    country_code: "LK",
    lat: 7.4659646,
    lon: 80.6234259,
  },
  {
    art_state_id: "AST64B8E3176E",
    state_code: "8",
    name: "Uva Province",
    country_code: "LK",
    lat: 6.8427612,
    lon: 81.3399414,
  },
  {
    art_state_id: "ASTD94C268794",
    state_code: "72",
    name: "Polonnaruwa District",
    country_code: "LK",
    lat: 7.9395567,
    lon: 81.0003403,
  },
  {
    art_state_id: "ASTE23E40DE68",
    state_code: "4",
    name: "Northern Province",
    country_code: "LK",
    lat: 9.28,
    lon: 80.28717778193072,
  },
  {
    art_state_id: "ASTE8ED099778",
    state_code: "45",
    name: "Mullaitivu District",
    country_code: "LK",
    lat: 9.2675388,
    lon: 80.8128254,
  },
  {
    art_state_id: "ASTE323031EDB",
    state_code: "11",
    name: "Colombo District",
    country_code: "LK",
    lat: 6.9269557,
    lon: 79.8617306,
  },
  {
    art_state_id: "ASTAF994F0370",
    state_code: "71",
    name: "Anuradhapura District",
    country_code: "LK",
    lat: 8.3318305,
    lon: 80.4029017,
  },
  {
    art_state_id: "ASTF4EDC82136",
    state_code: "6",
    name: "North Western Province",
    country_code: "LK",
    lat: 7.7584091,
    lon: 80.1875065,
  },
  {
    art_state_id: "AST5C8C98E932",
    state_code: "51",
    name: "Batticaloa District",
    country_code: "LK",
    lat: 7.8292781,
    lon: 81.4718387,
  },
  {
    art_state_id: "AST1940D73066",
    state_code: "82",
    name: "Monaragala District",
    country_code: "LK",
    lat: 6.8727781,
    lon: 81.3506832,
  },
  {
    art_state_id: "ASTD2FE627DA5",
    state_code: "M",
    name: "Mohéli",
    country_code: "KM",
    lat: -12.3377376,
    lon: 43.7334089,
  },
  {
    art_state_id: "AST291355AA18",
    state_code: "A",
    name: "Anjouan",
    country_code: "KM",
    lat: -12.2138145,
    lon: 44.4370606,
  },
  {
    art_state_id: "AST20B82A0F48",
    state_code: "G",
    name: "Grande Comore",
    country_code: "KM",
    lat: -11.7167338,
    lon: 43.3680788,
  },
  {
    art_state_id: "AST59C68A6ED8",
    state_code: "AT",
    name: "Atacama Region",
    country_code: "CL",
    lat: -27.5660558,
    lon: -70.050314,
  },
  {
    art_state_id: "AST3E827C9A0A",
    state_code: "RM",
    name: "Santiago Metropolitan Region",
    country_code: "CL",
    lat: -33.4375545,
    lon: -70.6504896,
  },
  {
    art_state_id: "ASTBE252B0FF9",
    state_code: "CO",
    name: "Coquimbo Region",
    country_code: "CL",
    lat: -30.540181,
    lon: -70.8119953,
  },
  {
    art_state_id: "AST79BE337422",
    state_code: "AR",
    name: "Araucanía Region",
    country_code: "CL",
    lat: -38.948921,
    lon: -72.331113,
  },
  {
    art_state_id: "AST9463378944",
    state_code: "BI",
    name: "Bío Bío Region",
    country_code: "CL",
    lat: -37.4464428,
    lon: -72.1416132,
  },
  {
    art_state_id: "ASTB4A3BF3E64",
    state_code: "AI",
    name: "Aysén Region",
    country_code: "CL",
    lat: -46.378345,
    lon: -72.3007623,
  },
  {
    art_state_id: "AST15B2FDCE39",
    state_code: "AP",
    name: "Arica y Parinacota Region",
    country_code: "CL",
    lat: -18.5940485,
    lon: -69.4784541,
  },
  {
    art_state_id: "AST624423C201",
    state_code: "VS",
    name: "Valparaíso",
    country_code: "CL",
    lat: -33.047238,
    lon: -71.6126885,
  },
  {
    art_state_id: "AST4341460892",
    state_code: "NB",
    name: "Ñuble Region",
    country_code: "CL",
    lat: -36.7225743,
    lon: -71.7622481,
  },
  {
    art_state_id: "AST313C8C1E43",
    state_code: "AN",
    name: "Antofagasta Region",
    country_code: "CL",
    lat: -23.8369104,
    lon: -69.2877535,
  },
  {
    art_state_id: "AST4A9F3EBF35",
    state_code: "ML",
    name: "Maule Region",
    country_code: "CL",
    lat: -35.5163603,
    lon: -71.5723953,
  },
  {
    art_state_id: "ASTFE4C974DA0",
    state_code: "LR",
    name: "Los Ríos Region",
    country_code: "CL",
    lat: -40.2310217,
    lon: -72.331113,
  },
  {
    art_state_id: "ASTDFA357926D",
    state_code: "LL",
    name: "Los Lagos Region",
    country_code: "CL",
    lat: -41.9197779,
    lon: -72.1416132,
  },
  {
    art_state_id: "AST1AA41C743B",
    state_code: "MA",
    name: "Magellan and the Chilean Antarctic Region",
    country_code: "CL",
    lat: -52.2064316,
    lon: -72.1685001,
  },
  {
    art_state_id: "AST421C3E97A8",
    state_code: "TA",
    name: "Tarapacá Region",
    country_code: "CL",
    lat: -20.2028799,
    lon: -69.2877535,
  },
  {
    art_state_id: "AST1A8DA1650A",
    state_code: "LI",
    name: "O'Higgins",
    country_code: "CL",
    lat: -34.5755374,
    lon: -71.0022311,
  },
  {
    art_state_id: "AST205E0F96FC",
    state_code: "CM",
    name: "Commewijne District",
    country_code: "SR",
    lat: 5.740211,
    lon: -54.8731219,
  },
  {
    art_state_id: "AST11220BEC8F",
    state_code: "NI",
    name: "Nickerie District",
    country_code: "SR",
    lat: 5.5855469,
    lon: -56.8311117,
  },
  {
    art_state_id: "ASTDD25E860FD",
    state_code: "PR",
    name: "Para District",
    country_code: "SR",
    lat: 5.4817318,
    lon: -55.2259207,
  },
  {
    art_state_id: "ASTDC1EB3EE18",
    state_code: "CR",
    name: "Coronie District",
    country_code: "SR",
    lat: 5.6943271,
    lon: -56.2929381,
  },
  {
    art_state_id: "AST29E0D93E07",
    state_code: "PM",
    name: "Paramaribo District",
    country_code: "SR",
    lat: 5.8520355,
    lon: -55.2038278,
  },
  {
    art_state_id: "AST05FEAE16C6",
    state_code: "WA",
    name: "Wanica District",
    country_code: "SR",
    lat: 5.7323762,
    lon: -55.2701235,
  },
  {
    art_state_id: "AST440514DD1B",
    state_code: "MA",
    name: "Marowijne District",
    country_code: "SR",
    lat: 5.6268128,
    lon: -54.2593118,
  },
  {
    art_state_id: "AST37CAC1978B",
    state_code: "BR",
    name: "Brokopondo District",
    country_code: "SR",
    lat: 4.7710247,
    lon: -55.0493375,
  },
  {
    art_state_id: "AST0A1BD90745",
    state_code: "SI",
    name: "Sipaliwini District",
    country_code: "SR",
    lat: 3.6567382,
    lon: -56.2035387,
  },
  {
    art_state_id: "AST0A9DE09158",
    state_code: "SA",
    name: "Saramacca District",
    country_code: "SR",
    lat: 5.7240813,
    lon: -55.6689636,
  },
  {
    art_state_id: "AST68B56C19AB",
    state_code: "01",
    name: "Riyadh Region",
    country_code: "SA",
    lat: 22.7554385,
    lon: 46.2091547,
  },
  {
    art_state_id: "AST70D4324470",
    state_code: "02",
    name: "Makkah Region",
    country_code: "SA",
    lat: 21.5235584,
    lon: 41.9196471,
  },
  {
    art_state_id: "AST2178B43919",
    state_code: "03",
    name: "Al Madinah Region",
    country_code: "SA",
    lat: 24.8403977,
    lon: 39.3206241,
  },
  {
    art_state_id: "ASTF11B906DF4",
    state_code: "07",
    name: "Tabuk Region",
    country_code: "SA",
    lat: 28.2453335,
    lon: 37.6386622,
  },
  {
    art_state_id: "ASTC6AD705366",
    state_code: "14",
    name: "'Asir Region",
    country_code: "SA",
    lat: 19.0969062,
    lon: 42.8637875,
  },
  {
    art_state_id: "ASTBC50B246E4",
    state_code: "08",
    name: "Northern Borders Region",
    country_code: "SA",
    lat: 30.0799162,
    lon: 42.8637875,
  },
  {
    art_state_id: "AST3A3E588767",
    state_code: "06",
    name: "Ha'il Region",
    country_code: "SA",
    lat: 27.7076143,
    lon: 41.9196471,
  },
  {
    art_state_id: "AST6B632564D2",
    state_code: "04",
    name: "Eastern Province",
    country_code: "SA",
    lat: 23.3036077,
    lon: 50.1258804,
  },
  {
    art_state_id: "AST3A5DF05AD3",
    state_code: "12",
    name: "Al Jawf Region",
    country_code: "SA",
    lat: 29.887356,
    lon: 39.3206241,
  },
  {
    art_state_id: "ASTA1FADAD635",
    state_code: "09",
    name: "Jizan Region",
    country_code: "SA",
    lat: 17.1738176,
    lon: 42.7076107,
  },
  {
    art_state_id: "AST78DE174153",
    state_code: "11",
    name: "Al Bahah Region",
    country_code: "SA",
    lat: 20.2722739,
    lon: 41.441251,
  },
  {
    art_state_id: "ASTA813CE4826",
    state_code: "10",
    name: "Najran Region",
    country_code: "SA",
    lat: 18.3514664,
    lon: 45.6007108,
  },
  {
    art_state_id: "ASTDA20E99562",
    state_code: "05",
    name: "Al-Qassim Region",
    country_code: "SA",
    lat: 26.207826,
    lon: 43.483738,
  },
  {
    art_state_id: "AST3F035EC362",
    state_code: "14",
    name: "Plateaux Department",
    country_code: "CG",
    lat: -2.0680088,
    lon: 15.4068079,
  },
  {
    art_state_id: "AST0EC89D1AAD",
    state_code: "16",
    name: "Pointe-Noire",
    country_code: "CG",
    lat: -4.7691623,
    lon: 11.866362,
  },
  {
    art_state_id: "ASTF04338B399",
    state_code: "8",
    name: "Cuvette Department",
    country_code: "CG",
    lat: -0.2877446,
    lon: 16.1580937,
  },
  {
    art_state_id: "ASTE17BBDE2EC",
    state_code: "7",
    name: "Likouala Department",
    country_code: "CG",
    lat: 2.043924,
    lon: 17.668887,
  },
  {
    art_state_id: "AST6827E1ECC8",
    state_code: "11",
    name: "Bouenza Department",
    country_code: "CG",
    lat: -4.1128079,
    lon: 13.7289167,
  },
  {
    art_state_id: "AST0CAF4AE2A1",
    state_code: "5",
    name: "Kouilou Department",
    country_code: "CG",
    lat: -4.1428413,
    lon: 11.8891721,
  },
  {
    art_state_id: "AST4AE9697E7A",
    state_code: "2",
    name: "Lékoumou Department",
    country_code: "CG",
    lat: -3.170382,
    lon: 13.3587288,
  },
  {
    art_state_id: "AST0CF9CDFF97",
    state_code: "15",
    name: "Cuvette-Ouest Department",
    country_code: "CG",
    lat: 0.144755,
    lon: 14.4723301,
  },
  {
    art_state_id: "ASTFAD0599618",
    state_code: "BZV",
    name: "Brazzaville",
    country_code: "CG",
    lat: -4.2633597,
    lon: 15.2428853,
  },
  {
    art_state_id: "ASTF581BD6914",
    state_code: "13",
    name: "Sangha Department",
    country_code: "CG",
    lat: 1.4662328,
    lon: 15.4068079,
  },
  {
    art_state_id: "AST4CAF65E5D4",
    state_code: "9",
    name: "Niari Department",
    country_code: "CG",
    lat: -3.18427,
    lon: 12.2547919,
  },
  {
    art_state_id: "AST6419C52EDB",
    state_code: "12",
    name: "Pool Department",
    country_code: "CG",
    lat: -3.7762628,
    lon: 14.8454619,
  },
  {
    art_state_id: "ASTC5E88965C4",
    state_code: "QUI",
    name: "Quindío Department",
    country_code: "CO",
    lat: 4.4610191,
    lon: -75.667356,
  },
  {
    art_state_id: "AST37483E42E3",
    state_code: "CUN",
    name: "Cundinamarca Department",
    country_code: "CO",
    lat: 5.026003,
    lon: -74.0300122,
  },
  {
    art_state_id: "AST0D5FAB50AA",
    state_code: "CHO",
    name: "Chocó Department",
    country_code: "CO",
    lat: 5.2528033,
    lon: -76.8259652,
  },
  {
    art_state_id: "ASTA9DF69A6F8",
    state_code: "NSA",
    name: "Norte de Santander Department",
    country_code: "CO",
    lat: 7.9462831,
    lon: -72.8988069,
  },
  {
    art_state_id: "AST43E7D49C37",
    state_code: "MET",
    name: "Meta",
    country_code: "CO",
    lat: 39.7673258,
    lon: -104.9753595,
  },
  {
    art_state_id: "AST8F0554D2C2",
    state_code: "RIS",
    name: "Risaralda Department",
    country_code: "CO",
    lat: 5.3158475,
    lon: -75.9927652,
  },
  {
    art_state_id: "AST4C24C8B9E7",
    state_code: "ATL",
    name: "Atlántico Department",
    country_code: "CO",
    lat: 10.6966159,
    lon: -74.8741045,
  },
  {
    art_state_id: "AST98E42CEEEB",
    state_code: "ARA",
    name: "Arauca Department",
    country_code: "CO",
    lat: 6.547306,
    lon: -71.0022311,
  },
  {
    art_state_id: "ASTBE3F655054",
    state_code: "GUA",
    name: "Guainía Department",
    country_code: "CO",
    lat: 2.585393,
    lon: -68.5247149,
  },
  {
    art_state_id: "AST2AD6414081",
    state_code: "TOL",
    name: "Tolima Department",
    country_code: "CO",
    lat: 4.0925168,
    lon: -75.1545381,
  },
  {
    art_state_id: "AST7F262FA367",
    state_code: "CAU",
    name: "Cauca Department",
    country_code: "CO",
    lat: 2.7049813,
    lon: -76.8259652,
  },
  {
    art_state_id: "ASTBA04D01F65",
    state_code: "VAU",
    name: "Vaupés Department",
    country_code: "CO",
    lat: 0.8553561,
    lon: -70.8119953,
  },
  {
    art_state_id: "ASTA3D5B7EE31",
    state_code: "MAG",
    name: "Magdalena Department",
    country_code: "CO",
    lat: 10.4113014,
    lon: -74.4056612,
  },
  {
    art_state_id: "ASTCED9C5BFAC",
    state_code: "CAL",
    name: "Caldas Department",
    country_code: "CO",
    lat: 5.29826,
    lon: -75.2479061,
  },
  {
    art_state_id: "AST22C62A30F7",
    state_code: "GUV",
    name: "Guaviare Department",
    country_code: "CO",
    lat: 2.043924,
    lon: -72.331113,
  },
  {
    art_state_id: "AST67977ED5DB",
    state_code: "LAG",
    name: "La Guajira Department",
    country_code: "CO",
    lat: 11.3547743,
    lon: -72.5204827,
  },
  {
    art_state_id: "ASTCBE8E49E78",
    state_code: "ANT",
    name: "Antioquia Department",
    country_code: "CO",
    lat: 7.1986064,
    lon: -75.3412179,
  },
  {
    art_state_id: "ASTECA0E79DCD",
    state_code: "CAQ",
    name: "Caquetá Department",
    country_code: "CO",
    lat: 0.869892,
    lon: -73.8419063,
  },
  {
    art_state_id: "AST4D675B9507",
    state_code: "CAS",
    name: "Casanare Department",
    country_code: "CO",
    lat: 5.7589269,
    lon: -71.5723953,
  },
  {
    art_state_id: "AST9C558849F2",
    state_code: "BOL",
    name: "Bolívar Department",
    country_code: "CO",
    lat: 8.6704382,
    lon: -74.0300122,
  },
  {
    art_state_id: "AST44B020AF9E",
    state_code: "VID",
    name: "Vichada Department",
    country_code: "CO",
    lat: 4.4234452,
    lon: -69.2877535,
  },
  {
    art_state_id: "AST7FBCAF7BFD",
    state_code: "AMA",
    name: "Amazonas Department",
    country_code: "CO",
    lat: -1.4429123,
    lon: -71.5723953,
  },
  {
    art_state_id: "AST7FBA7D28C6",
    state_code: "PUT",
    name: "Putumayo Department",
    country_code: "CO",
    lat: 0.4359506,
    lon: -75.5276699,
  },
  {
    art_state_id: "AST5426E19CA1",
    state_code: "NAR",
    name: "Nariño Department",
    country_code: "CO",
    lat: 1.289151,
    lon: -77.35794,
  },
  {
    art_state_id: "AST36BF7C26DF",
    state_code: "COR",
    name: "Córdoba Department",
    country_code: "CO",
    lat: 8.049293,
    lon: -75.57405,
  },
  {
    art_state_id: "AST49716F2F4F",
    state_code: "CES",
    name: "Cesar Department",
    country_code: "CO",
    lat: 9.3372948,
    lon: -73.6536209,
  },
  {
    art_state_id: "ASTC4CF1753E6",
    state_code: "SAP",
    name: "Archipelago of Saint Andréws, Providence and Saint Catalina",
    country_code: "CO",
    lat: 12.5567324,
    lon: -81.7185253,
  },
  {
    art_state_id: "AST5A79AD7C5F",
    state_code: "SAN",
    name: "Santander Department",
    country_code: "CO",
    lat: 6.6437076,
    lon: -73.6536209,
  },
  {
    art_state_id: "ASTD43C925BBB",
    state_code: "SUC",
    name: "Sucre Department",
    country_code: "CO",
    lat: 8.813977,
    lon: -74.723283,
  },
  {
    art_state_id: "AST2913EAE54E",
    state_code: "BOY",
    name: "Boyacá Department",
    country_code: "CO",
    lat: 5.454511,
    lon: -73.362003,
  },
  {
    art_state_id: "AST1E4293DB2C",
    state_code: "VAC",
    name: "Valle del Cauca Department",
    country_code: "CO",
    lat: 3.8008893,
    lon: -76.6412712,
  },
  {
    art_state_id: "AST18840CCFFC",
    state_code: "W",
    name: "Galápagos Province",
    country_code: "EC",
    lat: -0.9537691,
    lon: -90.9656019,
  },
  {
    art_state_id: "ASTB075D3E60E",
    state_code: "U",
    name: "Sucumbíos Province",
    country_code: "EC",
    lat: 0.0889231,
    lon: -76.8897557,
  },
  {
    art_state_id: "AST7630736338",
    state_code: "Y",
    name: "Pastaza Province",
    country_code: "EC",
    lat: -1.4882265,
    lon: -78.0031057,
  },
  {
    art_state_id: "ASTDDD3B73873",
    state_code: "T",
    name: "Tungurahua Province",
    country_code: "EC",
    lat: -1.2635284,
    lon: -78.5660852,
  },
  {
    art_state_id: "AST38D1F840F3",
    state_code: "Z",
    name: "Zamora-Chinchipe Province",
    country_code: "EC",
    lat: -4.0655892,
    lon: -78.9503525,
  },
  {
    art_state_id: "AST620061CA6C",
    state_code: "R",
    name: "Los Ríos Province",
    country_code: "EC",
    lat: -1.0230607,
    lon: -79.4608897,
  },
  {
    art_state_id: "AST736B4327B7",
    state_code: "I",
    name: "Imbabura Province",
    country_code: "EC",
    lat: 0.3499768,
    lon: -78.1260129,
  },
  {
    art_state_id: "AST5C66BAC815",
    state_code: "SE",
    name: "Santa Elena Province",
    country_code: "EC",
    lat: -2.2267105,
    lon: -80.859499,
  },
  {
    art_state_id: "ASTE6E2BA2D46",
    state_code: "M",
    name: "Manabí Province",
    country_code: "EC",
    lat: -1.0543434,
    lon: -80.452644,
  },
  {
    art_state_id: "AST846040E681",
    state_code: "G",
    name: "Guayas Province",
    country_code: "EC",
    lat: -1.9574839,
    lon: -79.9192702,
  },
  {
    art_state_id: "AST90F33BA59C",
    state_code: "C",
    name: "Carchi Province",
    country_code: "EC",
    lat: 0.5026912,
    lon: -77.9042521,
  },
  {
    art_state_id: "ASTE87C277DAD",
    state_code: "N",
    name: "Napo Province",
    country_code: "EC",
    lat: -0.9955964,
    lon: -77.8129684,
  },
  {
    art_state_id: "AST33B6E05E44",
    state_code: "F",
    name: "Cañar Province",
    country_code: "EC",
    lat: -2.5589315,
    lon: -78.9388191,
  },
  {
    art_state_id: "AST1545574E8D",
    state_code: "S",
    name: "Morona-Santiago Province",
    country_code: "EC",
    lat: -2.3051062,
    lon: -78.1146866,
  },
  {
    art_state_id: "AST5B366839AA",
    state_code: "SD",
    name: "Santo Domingo de los Tsáchilas Province",
    country_code: "EC",
    lat: -0.2521882,
    lon: -79.1879383,
  },
  {
    art_state_id: "AST532E02D0ED",
    state_code: "B",
    name: "Bolívar Province",
    country_code: "EC",
    lat: -1.7095828,
    lon: -79.0450429,
  },
  {
    art_state_id: "AST841B51D6D8",
    state_code: "X",
    name: "Cotopaxi Province",
    country_code: "EC",
    lat: -0.8384206,
    lon: -78.6662678,
  },
  {
    art_state_id: "AST640AF25AF7",
    state_code: "E",
    name: "Esmeraldas",
    country_code: "EC",
    lat: 0.9681789,
    lon: -79.6517202,
  },
  {
    art_state_id: "AST339E5B0BB8",
    state_code: "A",
    name: "Azuay Province",
    country_code: "EC",
    lat: -2.8943068,
    lon: -78.9968344,
  },
  {
    art_state_id: "ASTC5564E7A33",
    state_code: "O",
    name: "El Oro Province",
    country_code: "EC",
    lat: -3.2592413,
    lon: -79.9583541,
  },
  {
    art_state_id: "AST437435F1DA",
    state_code: "H",
    name: "Chimborazo Province",
    country_code: "EC",
    lat: -1.6647995,
    lon: -78.6543255,
  },
  {
    art_state_id: "AST10D8D93725",
    state_code: "D",
    name: "Orellana Province",
    country_code: "EC",
    lat: -0.4545163,
    lon: -76.9950286,
  },
  {
    art_state_id: "AST79AC169D7B",
    state_code: "P",
    name: "Pichincha Province",
    country_code: "EC",
    lat: -0.1464847,
    lon: -78.4751945,
  },
  {
    art_state_id: "AST9156867931",
    state_code: "OB",
    name: "Obock Region",
    country_code: "DJ",
    lat: 12.3895691,
    lon: 43.0194897,
  },
  {
    art_state_id: "AST6B548B2180",
    state_code: "DJ",
    name: "Djibouti",
    country_code: "DJ",
    lat: 11.825138,
    lon: 42.590275,
  },
  {
    art_state_id: "AST7B8E97A63B",
    state_code: "DI",
    name: "Dikhil Region",
    country_code: "DJ",
    lat: 11.1054336,
    lon: 42.3704744,
  },
  {
    art_state_id: "AST879A546A69",
    state_code: "TA",
    name: "Tadjourah Region",
    country_code: "DJ",
    lat: 11.9338885,
    lon: 42.3938375,
  },
  {
    art_state_id: "ASTB1CF13AF5D",
    state_code: "AR",
    name: "Arta Region",
    country_code: "DJ",
    lat: 11.5255528,
    lon: 42.8479474,
  },
  {
    art_state_id: "AST6033B5725C",
    state_code: "AS",
    name: "Ali Sabieh Region",
    country_code: "DJ",
    lat: 11.1928973,
    lon: 42.941698,
  },
  {
    art_state_id: "ASTD93816EA3E",
    state_code: "HM",
    name: "Hama Governorate",
    country_code: "SY",
    lat: 35.1887865,
    lon: 37.2115829,
  },
  {
    art_state_id: "AST7A39398BDF",
    state_code: "RD",
    name: "Rif Dimashq Governorate",
    country_code: "SY",
    lat: 33.5167289,
    lon: 36.954107,
  },
  {
    art_state_id: "AST9F4A6667B4",
    state_code: "SU",
    name: "As-Suwayda Governorate",
    country_code: "SY",
    lat: 32.7989156,
    lon: 36.7819505,
  },
  {
    art_state_id: "ASTAECC90CC0E",
    state_code: "DY",
    name: "Deir ez-Zor Governorate",
    country_code: "SY",
    lat: 35.2879798,
    lon: 40.3088626,
  },
  {
    art_state_id: "ASTDF2B279F9E",
    state_code: "LA",
    name: "Latakia Governorate",
    country_code: "SY",
    lat: 35.6129791,
    lon: 36.0023225,
  },
  {
    art_state_id: "AST3BF595261F",
    state_code: "DI",
    name: "Damascus Governorate",
    country_code: "SY",
    lat: 33.5151444,
    lon: 36.3931354,
  },
  {
    art_state_id: "AST49C66CC593",
    state_code: "ID",
    name: "Idlib Governorate",
    country_code: "SY",
    lat: 35.8268798,
    lon: 36.6957216,
  },
  {
    art_state_id: "ASTE9C4278EBE",
    state_code: "HA",
    name: "Al-Hasakah Governorate",
    country_code: "SY",
    lat: 36.405515,
    lon: 40.7969149,
  },
  {
    art_state_id: "AST6C5D10517D",
    state_code: "HI",
    name: "Homs Governorate",
    country_code: "SY",
    lat: 34.2567123,
    lon: 38.3165725,
  },
  {
    art_state_id: "AST5DBD89B8D7",
    state_code: "QU",
    name: "Quneitra Governorate",
    country_code: "SY",
    lat: 33.0776318,
    lon: 35.8934136,
  },
  {
    art_state_id: "AST61FE7C165C",
    state_code: "RA",
    name: "Al-Raqqah Governorate",
    country_code: "SY",
    lat: 35.9594106,
    lon: 38.9981052,
  },
  {
    art_state_id: "AST3C2E5EEF22",
    state_code: "DR",
    name: "Daraa Governorate",
    country_code: "SY",
    lat: 32.9248813,
    lon: 36.1762615,
  },
  {
    art_state_id: "AST65A99197DC",
    state_code: "HL",
    name: "Aleppo Governorate",
    country_code: "SY",
    lat: 36.2262393,
    lon: 37.4681396,
  },
  {
    art_state_id: "AST585C288C84",
    state_code: "TA",
    name: "Tartus Governorate",
    country_code: "SY",
    lat: 35.0006652,
    lon: 36.0023225,
  },
  {
    art_state_id: "ASTECD46C3A87",
    state_code: "F",
    name: "Fianarantsoa Province",
    country_code: "MG",
    lat: -22.353624,
    lon: 46.8252838,
  },
  {
    art_state_id: "ASTB06F697F42",
    state_code: "U",
    name: "Toliara Province",
    country_code: "MG",
    lat: -23.3516191,
    lon: 43.6854936,
  },
  {
    art_state_id: "AST4B0B02DF7E",
    state_code: "D",
    name: "Antsiranana Province",
    country_code: "MG",
    lat: -13.771539,
    lon: 49.5279996,
  },
  {
    art_state_id: "ASTFDF317D678",
    state_code: "T",
    name: "Antananarivo Province",
    country_code: "MG",
    lat: -18.7051474,
    lon: 46.8252838,
  },
  {
    art_state_id: "AST4FCF8FA902",
    state_code: "A",
    name: "Toamasina Province",
    country_code: "MG",
    lat: -18.1442811,
    lon: 49.3957836,
  },
  {
    art_state_id: "AST636820AD51",
    state_code: "M",
    name: "Mahajanga Province",
    country_code: "MG",
    lat: -16.523883,
    lon: 46.516262,
  },
  {
    art_state_id: "AST60DB54188F",
    state_code: "MA",
    name: "Mogilev Region",
    country_code: "BY",
    lat: 53.5101791,
    lon: 30.4006444,
  },
  {
    art_state_id: "ASTB486A423CC",
    state_code: "HO",
    name: "Gomel Region",
    country_code: "BY",
    lat: 52.1648754,
    lon: 29.1333251,
  },
  {
    art_state_id: "AST94870FFFC8",
    state_code: "HR",
    name: "Grodno Region",
    country_code: "BY",
    lat: 53.6599945,
    lon: 25.3448571,
  },
  {
    art_state_id: "AST479D33B6DD",
    state_code: "MI",
    name: "Minsk Region",
    country_code: "BY",
    lat: 54.1067889,
    lon: 27.4129245,
  },
  {
    art_state_id: "AST65FE447554",
    state_code: "HM",
    name: "Minsk",
    country_code: "BY",
    lat: 53.9006011,
    lon: 27.558972,
  },
  {
    art_state_id: "AST2018EB28CB",
    state_code: "BR",
    name: "Brest Region",
    country_code: "BY",
    lat: 52.5296641,
    lon: 25.460648,
  },
  {
    art_state_id: "ASTE4BC8CBF98",
    state_code: "VI",
    name: "Vitebsk Region",
    country_code: "BY",
    lat: 55.2959833,
    lon: 28.7583627,
  },
  {
    art_state_id: "ASTD400A4ACF4",
    state_code: "MB",
    name: "Murqub",
    country_code: "LY",
    lat: 32.4599677,
    lon: 14.1001326,
  },
  {
    art_state_id: "AST2C54FD9A5D",
    state_code: "NQ",
    name: "Nuqat al Khams",
    country_code: "LY",
    lat: 32.6914909,
    lon: 11.8891721,
  },
  {
    art_state_id: "ASTD3A9818FC7",
    state_code: "ZA",
    name: "Zawiya District",
    country_code: "LY",
    lat: 32.7630282,
    lon: 12.7364962,
  },
  {
    art_state_id: "ASTD02F6BD9E8",
    state_code: "WA",
    name: "Al Wahat District",
    country_code: "LY",
    lat: 29.0466808,
    lon: 21.8568586,
  },
  {
    art_state_id: "ASTD6DFA32213",
    state_code: "SB",
    name: "Sabha District",
    country_code: "LY",
    lat: 27.0365406,
    lon: 14.4290236,
  },
  {
    art_state_id: "AST0F84103D8F",
    state_code: "DR",
    name: "Derna District",
    country_code: "LY",
    lat: 32.755613,
    lon: 22.6377432,
  },
  {
    art_state_id: "AST2FA4D8AED3",
    state_code: "MQ",
    name: "Murzuq District",
    country_code: "LY",
    lat: 25.9182262,
    lon: 13.9260001,
  },
  {
    art_state_id: "AST738A9ADF6E",
    state_code: "MJ",
    name: "Marj District",
    country_code: "LY",
    lat: 32.0550363,
    lon: 21.1891151,
  },
  {
    art_state_id: "AST67B18F228E",
    state_code: "GT",
    name: "Ghat District",
    country_code: "LY",
    lat: 24.9640371,
    lon: 10.1759285,
  },
  {
    art_state_id: "AST8FC618D9C7",
    state_code: "JU",
    name: "Jufra",
    country_code: "LY",
    lat: 27.9835135,
    lon: 16.912251,
  },
  {
    art_state_id: "ASTF7B225570A",
    state_code: "TB",
    name: "Tripoli District",
    country_code: "LY",
    lat: 32.6408021,
    lon: 13.2663479,
  },
  {
    art_state_id: "AST25BCC93188",
    state_code: "KF",
    name: "Kufra District",
    country_code: "LY",
    lat: 23.3112389,
    lon: 21.8568586,
  },
  {
    art_state_id: "AST835919C154",
    state_code: "WD",
    name: "Wadi al Hayaa District",
    country_code: "LY",
    lat: 26.4225926,
    lon: 12.6216211,
  },
  {
    art_state_id: "AST7E534A1355",
    state_code: "JG",
    name: "Jabal al Gharbi District",
    country_code: "LY",
    lat: 30.2638032,
    lon: 12.8054753,
  },
  {
    art_state_id: "ASTDF3675C926",
    state_code: "WS",
    name: "Wadi al Shatii District",
    country_code: "LY",
    lat: 27.7351468,
    lon: 12.4380581,
  },
  {
    art_state_id: "ASTD76FDF11FE",
    state_code: "NL",
    name: "Nalut District",
    country_code: "LY",
    lat: 31.8742348,
    lon: 10.9750484,
  },
  {
    art_state_id: "AST04AFCEF672",
    state_code: "SR",
    name: "Sirte District",
    country_code: "LY",
    lat: 31.189689,
    lon: 16.5701927,
  },
  {
    art_state_id: "AST33A6555E00",
    state_code: "MI",
    name: "Misrata District",
    country_code: "LY",
    lat: 32.3255884,
    lon: 15.0992556,
  },
  {
    art_state_id: "AST3DCFE53C69",
    state_code: "JI",
    name: "Jafara",
    country_code: "LY",
    lat: 32.4525904,
    lon: 12.9435536,
  },
  {
    art_state_id: "ASTDC6B2E1F60",
    state_code: "JA",
    name: "Jabal al Akhdar",
    country_code: "LY",
    lat: 23.1856081,
    lon: 57.3713879,
  },
  {
    art_state_id: "AST4FBE9AD2C6",
    state_code: "BA",
    name: "Benghazi",
    country_code: "LY",
    lat: 32.1194242,
    lon: 20.0867909,
  },
  {
    art_state_id: "AST652C4ABF98",
    state_code: "RB",
    name: "Ribeira Brava Municipality",
    country_code: "CV",
    lat: 16.6070739,
    lon: -24.2033843,
  },
  {
    art_state_id: "ASTCFCA74E1B2",
    state_code: "TA",
    name: "Tarrafal",
    country_code: "CV",
    lat: 15.2760578,
    lon: -23.7484077,
  },
  {
    art_state_id: "ASTBE9F273CFB",
    state_code: "RS",
    name: "Ribeira Grande de Santiago",
    country_code: "CV",
    lat: 14.9830298,
    lon: -23.6561725,
  },
  {
    art_state_id: "ASTA09BBA80E8",
    state_code: "CA",
    name: "Santa Catarina",
    country_code: "CV",
    lat: -27.2423392,
    lon: -50.2188556,
  },
  {
    art_state_id: "AST0071162E27",
    state_code: "SD",
    name: "São Domingos",
    country_code: "CV",
    lat: 15.0286165,
    lon: -23.563922,
  },
  {
    art_state_id: "AST1744C52A63",
    state_code: "MO",
    name: "Mosteiros",
    country_code: "CV",
    lat: 37.8904348,
    lon: -25.8207556,
  },
  {
    art_state_id: "AST03E73CD0EF",
    state_code: "PR",
    name: "Praia",
    country_code: "CV",
    lat: 14.93305,
    lon: -23.5133267,
  },
  {
    art_state_id: "ASTBDD9AB6D3E",
    state_code: "PN",
    name: "Porto Novo",
    country_code: "CV",
    lat: 6.4968574,
    lon: 2.6288523,
  },
  {
    art_state_id: "ASTA4DE929DA2",
    state_code: "SM",
    name: "São Miguel",
    country_code: "CV",
    lat: 37.780411,
    lon: -25.4970466,
  },
  {
    art_state_id: "AST7BD63F0EA4",
    state_code: "MA",
    name: "Maio Municipality",
    country_code: "CV",
    lat: 15.2003098,
    lon: -23.1679793,
  },
  {
    art_state_id: "AST9D2A7086A5",
    state_code: "S",
    name: "Sotavento Islands",
    country_code: "CV",
    lat: 15,
    lon: -24,
  },
  {
    art_state_id: "AST6F6D5C2E4E",
    state_code: "SO",
    name: "São Lourenço dos Órgãos",
    country_code: "CV",
    lat: 15.0537841,
    lon: -23.6085612,
  },
  {
    art_state_id: "AST594370799F",
    state_code: "B",
    name: "Barlavento Islands",
    country_code: "CV",
    lat: 16.8236845,
    lon: -23.9934881,
  },
  {
    art_state_id: "AST3BE40E4571",
    state_code: "CF",
    name: "Santa Catarina do Fogo",
    country_code: "CV",
    lat: 14.9309104,
    lon: -24.3222577,
  },
  {
    art_state_id: "ASTE3DECD1605",
    state_code: "BR",
    name: "Brava",
    country_code: "CV",
    lat: 40.9897778,
    lon: -73.6835715,
  },
  {
    art_state_id: "ASTACE7FAA008",
    state_code: "PA",
    name: "Paul",
    country_code: "CV",
    lat: 37.0625,
    lon: -95.677068,
  },
  {
    art_state_id: "AST8417A46C66",
    state_code: "SL",
    name: "Sal",
    country_code: "CV",
    lat: 26.5958122,
    lon: -80.2045083,
  },
  {
    art_state_id: "ASTABBB2F4272",
    state_code: "BV",
    name: "Boa Vista",
    country_code: "CV",
    lat: 38.743466,
    lon: -120.7304297,
  },
  {
    art_state_id: "ASTA1458BE3F0",
    state_code: "SF",
    name: "São Filipe",
    country_code: "CV",
    lat: 14.8951679,
    lon: -24.4945636,
  },
  {
    art_state_id: "AST7B2216DFCD",
    state_code: "SV",
    name: "São Vicente",
    country_code: "CV",
    lat: -23.9607157,
    lon: -46.3962022,
  },
  {
    art_state_id: "AST6E2CBDB0D2",
    state_code: "RG",
    name: "Ribeira Grande",
    country_code: "CV",
    lat: 37.8210369,
    lon: -25.5148137,
  },
  {
    art_state_id: "AST7FD58DC057",
    state_code: "TS",
    name: "Tarrafal de São Nicolau",
    country_code: "CV",
    lat: 16.5636498,
    lon: -24.354942,
  },
  {
    art_state_id: "AST560C66C19C",
    state_code: "CR",
    name: "Santa Cruz",
    country_code: "CV",
    lat: 36.9741171,
    lon: -122.0307963,
  },
  {
    art_state_id: "AST891334CDC1",
    state_code: "SH",
    name: "Schleswig-Holstein",
    country_code: "DE",
    lat: 54.2193672,
    lon: 9.6961167,
  },
  {
    art_state_id: "ASTA64D91DA42",
    state_code: "BW",
    name: "Baden-Württemberg",
    country_code: "DE",
    lat: 48.6616037,
    lon: 9.3501336,
  },
  {
    art_state_id: "ASTF780A58253",
    state_code: "MV",
    name: "Mecklenburg-Vorpommern",
    country_code: "DE",
    lat: 53.6126505,
    lon: 12.4295953,
  },
  {
    art_state_id: "AST080B6BE4F8",
    state_code: "NI",
    name: "Lower Saxony",
    country_code: "DE",
    lat: 52.6367036,
    lon: 9.8450766,
  },
  {
    art_state_id: "AST4E37394ADC",
    state_code: "BY",
    name: "Bavaria",
    country_code: "DE",
    lat: 48.7904472,
    lon: 11.4978895,
  },
  {
    art_state_id: "ASTB65015E767",
    state_code: "BE",
    name: "Berlin",
    country_code: "DE",
    lat: 52.5200066,
    lon: 13.404954,
  },
  {
    art_state_id: "AST8BA090E967",
    state_code: "ST",
    name: "Saxony-Anhalt",
    country_code: "DE",
    lat: 51.9502649,
    lon: 11.6922734,
  },
  {
    art_state_id: "ASTCB5A2AD875",
    state_code: "BB",
    name: "Brandenburg",
    country_code: "DE",
    lat: 52.4125287,
    lon: 12.5316444,
  },
  {
    art_state_id: "ASTFB8DACA874",
    state_code: "HB",
    name: "Bremen",
    country_code: "DE",
    lat: 53.0792962,
    lon: 8.8016936,
  },
  {
    art_state_id: "AST1DF062995E",
    state_code: "TH",
    name: "Thuringia",
    country_code: "DE",
    lat: 51.0109892,
    lon: 10.845346,
  },
  {
    art_state_id: "AST744091348A",
    state_code: "HH",
    name: "Hamburg",
    country_code: "DE",
    lat: 53.5510846,
    lon: 9.9936819,
  },
  {
    art_state_id: "AST9E977B1A7D",
    state_code: "NW",
    name: "North Rhine-Westphalia",
    country_code: "DE",
    lat: 51.4332367,
    lon: 7.6615938,
  },
  {
    art_state_id: "ASTA90D5D3C24",
    state_code: "HE",
    name: "Hesse",
    country_code: "DE",
    lat: 50.6520515,
    lon: 9.1624376,
  },
  {
    art_state_id: "AST2C357A98EF",
    state_code: "RP",
    name: "Rhineland-Palatinate",
    country_code: "DE",
    lat: 50.118346,
    lon: 7.3089527,
  },
  {
    art_state_id: "AST19EF0560D8",
    state_code: "SL",
    name: "Saarland",
    country_code: "DE",
    lat: 49.3964234,
    lon: 7.0229607,
  },
  {
    art_state_id: "AST91036B0EE1",
    state_code: "SN",
    name: "Saxony",
    country_code: "DE",
    lat: 51.1045407,
    lon: 13.2017384,
  },
  {
    art_state_id: "AST7F3EE5FDA7",
    state_code: "E",
    name: "Mafeteng District",
    country_code: "LS",
    lat: -29.8041008,
    lon: 27.5026174,
  },
  {
    art_state_id: "AST0CAFA63E70",
    state_code: "F",
    name: "Mohale's Hoek District",
    country_code: "LS",
    lat: -30.1425917,
    lon: 27.4673845,
  },
  {
    art_state_id: "ASTB2FAAA9EEB",
    state_code: "J",
    name: "Mokhotlong District",
    country_code: "LS",
    lat: -29.2573193,
    lon: 28.9528645,
  },
  {
    art_state_id: "ASTFB9AF7BA7A",
    state_code: "H",
    name: "Qacha's Nek District",
    country_code: "LS",
    lat: -30.1114565,
    lon: 28.678979,
  },
  {
    art_state_id: "ASTA344A4C498",
    state_code: "C",
    name: "Leribe District",
    country_code: "LS",
    lat: -28.8638065,
    lon: 28.0478826,
  },
  {
    art_state_id: "ASTABB80DFC45",
    state_code: "G",
    name: "Quthing District",
    country_code: "LS",
    lat: -30.4015687,
    lon: 27.7080133,
  },
  {
    art_state_id: "ASTE10E6D02DF",
    state_code: "A",
    name: "Maseru District",
    country_code: "LS",
    lat: -29.516565,
    lon: 27.8311428,
  },
  {
    art_state_id: "ASTC49B475EEF",
    state_code: "B",
    name: "Butha-Buthe District",
    country_code: "LS",
    lat: -28.7653754,
    lon: 28.2468148,
  },
  {
    art_state_id: "ASTBD4947701B",
    state_code: "D",
    name: "Berea District",
    country_code: "LS",
    lat: 41.3661614,
    lon: -81.8543026,
  },
  {
    art_state_id: "AST68F96C6DF8",
    state_code: "K",
    name: "Thaba-Tseka District",
    country_code: "LS",
    lat: -29.5238975,
    lon: 28.6089752,
  },
  {
    art_state_id: "ASTB314D0E456",
    state_code: "MO",
    name: "Montserrado County",
    country_code: "LR",
    lat: 6.5525815,
    lon: -10.5296115,
  },
  {
    art_state_id: "AST0ECBD1305C",
    state_code: "RI",
    name: "River Cess County",
    country_code: "LR",
    lat: 5.9025328,
    lon: -9.456155,
  },
  {
    art_state_id: "AST6BD499FE2D",
    state_code: "BG",
    name: "Bong County",
    country_code: "LR",
    lat: 6.8295019,
    lon: -9.3673084,
  },
  {
    art_state_id: "AST6758E04059",
    state_code: "SI",
    name: "Sinoe County",
    country_code: "LR",
    lat: 5.49871,
    lon: -8.6600586,
  },
  {
    art_state_id: "AST35403FFEBA",
    state_code: "CM",
    name: "Grand Cape Mount County",
    country_code: "LR",
    lat: 7.0467758,
    lon: -11.0711758,
  },
  {
    art_state_id: "ASTDD806BD6FB",
    state_code: "LO",
    name: "Lofa County",
    country_code: "LR",
    lat: 8.1911184,
    lon: -9.7232673,
  },
  {
    art_state_id: "ASTC8784AA993",
    state_code: "RG",
    name: "River Gee County",
    country_code: "LR",
    lat: 5.2604894,
    lon: -7.87216,
  },
  {
    art_state_id: "ASTFA3924A124",
    state_code: "GG",
    name: "Grand Gedeh County",
    country_code: "LR",
    lat: 5.9222078,
    lon: -8.2212979,
  },
  {
    art_state_id: "AST8A96420A90",
    state_code: "GB",
    name: "Grand Bassa County",
    country_code: "LR",
    lat: 6.2308452,
    lon: -9.8124935,
  },
  {
    art_state_id: "AST1BC09317AE",
    state_code: "BM",
    name: "Bomi County",
    country_code: "LR",
    lat: 6.7562926,
    lon: -10.8451467,
  },
  {
    art_state_id: "ASTD3D19D861E",
    state_code: "MY",
    name: "Maryland County",
    country_code: "LR",
    lat: 39.0457549,
    lon: -76.6412712,
  },
  {
    art_state_id: "AST5329043BE8",
    state_code: "MG",
    name: "Margibi County",
    country_code: "LR",
    lat: 6.5151875,
    lon: -10.3048897,
  },
  {
    art_state_id: "ASTC73F7B4B9A",
    state_code: "GP",
    name: "Gbarpolu County",
    country_code: "LR",
    lat: 7.4952637,
    lon: -10.0807298,
  },
  {
    art_state_id: "AST0E662D8E32",
    state_code: "GK",
    name: "Grand Kru County",
    country_code: "LR",
    lat: 4.7613862,
    lon: -8.2212979,
  },
  {
    art_state_id: "AST4BFEA499F7",
    state_code: "NI",
    name: "Nimba",
    country_code: "LR",
    lat: 7.6166667,
    lon: -8.4166667,
  },
  {
    art_state_id: "AST2D60C9D25D",
    state_code: "ZA",
    name: "Ad Dhahirah Governorate",
    country_code: "OM",
    lat: 23.2161674,
    lon: 56.4907444,
  },
  {
    art_state_id: "AST0E8599C59D",
    state_code: "BS",
    name: "Al Batinah North Governorate",
    country_code: "OM",
    lat: 24.3419846,
    lon: 56.7298904,
  },
  {
    art_state_id: "AST8CEF8E2971",
    state_code: "BJ",
    name: "Al Batinah South Governorate",
    country_code: "OM",
    lat: 23.4314903,
    lon: 57.4239796,
  },
  {
    art_state_id: "ASTAD54BD8586",
    state_code: "BA",
    name: "Al Batinah Region",
    country_code: "OM",
    lat: 24.3419846,
    lon: 56.7298904,
  },
  {
    art_state_id: "AST1515DD4AA2",
    state_code: "SH",
    name: "Ash Sharqiyah Region",
    country_code: "OM",
    lat: 22.7141196,
    lon: 58.5308064,
  },
  {
    art_state_id: "ASTBFD6292CC4",
    state_code: "MU",
    name: "Musandam Governorate",
    country_code: "OM",
    lat: 26.1986144,
    lon: 56.2460949,
  },
  {
    art_state_id: "AST4F63C05E87",
    state_code: "SS",
    name: "Ash Sharqiyah North Governorate",
    country_code: "OM",
    lat: 22.7141196,
    lon: 58.5308064,
  },
  {
    art_state_id: "AST1373771805",
    state_code: "SJ",
    name: "Ash Sharqiyah South Governorate",
    country_code: "OM",
    lat: 22.0158249,
    lon: 59.3251922,
  },
  {
    art_state_id: "ASTB4DD77009A",
    state_code: "MA",
    name: "Muscat Governorate",
    country_code: "OM",
    lat: 23.5880307,
    lon: 58.3828717,
  },
  {
    art_state_id: "AST7B108A99A1",
    state_code: "WU",
    name: "Al Wusta Governorate",
    country_code: "OM",
    lat: 19.9571078,
    lon: 56.2756846,
  },
  {
    art_state_id: "AST973C4527BC",
    state_code: "ZU",
    name: "Dhofar Governorate",
    country_code: "OM",
    lat: 17.0322121,
    lon: 54.1425214,
  },
  {
    art_state_id: "AST1339170EC0",
    state_code: "DA",
    name: "Ad Dakhiliyah Governorate",
    country_code: "OM",
    lat: 22.8588758,
    lon: 57.5394356,
  },
  {
    art_state_id: "AST41363527E5",
    state_code: "BU",
    name: "Al Buraimi Governorate",
    country_code: "OM",
    lat: 24.1671413,
    lon: 56.1142253,
  },
  {
    art_state_id: "AST52E70AAEDD",
    state_code: "NG",
    name: "Ngamiland",
    country_code: "BW",
    lat: -19.1905321,
    lon: 23.0011989,
  },
  {
    art_state_id: "AST44487A9C2D",
    state_code: "GH",
    name: "Ghanzi District",
    country_code: "BW",
    lat: -21.8652314,
    lon: 21.8568586,
  },
  {
    art_state_id: "ASTE34FB1A632",
    state_code: "KL",
    name: "Kgatleng District",
    country_code: "BW",
    lat: -24.1970445,
    lon: 26.2304616,
  },
  {
    art_state_id: "ASTD05BAF26D3",
    state_code: "SO",
    name: "Southern District",
    country_code: "BW",
    lat: -24.7998688,
    lon: 24.5800692,
  },
  {
    art_state_id: "ASTE52B6516B7",
    state_code: "SE",
    name: "South-East District",
    country_code: "BW",
    lat: 31.2163798,
    lon: -82.3527044,
  },
  {
    art_state_id: "AST7422A62389",
    state_code: "NW",
    name: "North-West District",
    country_code: "BW",
    lat: 39.3446307,
    lon: -76.6854283,
  },
  {
    art_state_id: "AST963171E01E",
    state_code: "KG",
    name: "Kgalagadi District",
    country_code: "BW",
    lat: -24.7550285,
    lon: 21.8568586,
  },
  {
    art_state_id: "AST7927DD93E9",
    state_code: "CE",
    name: "Central District",
    country_code: "BW",
    lat: -21.4790187,
    lon: 26.2154174,
  },
  {
    art_state_id: "ASTC96D3AEC33",
    state_code: "NE",
    name: "North-East District",
    country_code: "BW",
    lat: 37.5884461,
    lon: -94.6863782,
  },
  {
    art_state_id: "AST15C5B8A40E",
    state_code: "KW",
    name: "Kweneng District",
    country_code: "BW",
    lat: -23.8367249,
    lon: 25.2837585,
  },
  {
    art_state_id: "AST7F6E213B25",
    state_code: "CO",
    name: "Collines Department",
    country_code: "BJ",
    lat: 8.3022297,
    lon: 2.302446,
  },
  {
    art_state_id: "AST9C03569860",
    state_code: "KO",
    name: "Kouffo Department",
    country_code: "BJ",
    lat: 7.0035894,
    lon: 1.7538817,
  },
  {
    art_state_id: "AST0AF3094FED",
    state_code: "DO",
    name: "Donga Department",
    country_code: "BJ",
    lat: 9.7191867,
    lon: 1.6760691,
  },
  {
    art_state_id: "AST186CB2C3CF",
    state_code: "ZO",
    name: "Zou Department",
    country_code: "BJ",
    lat: 7.3469268,
    lon: 2.0665197,
  },
  {
    art_state_id: "ASTED7EA1DE8E",
    state_code: "PL",
    name: "Plateau Department",
    country_code: "BJ",
    lat: 7.3445141,
    lon: 2.539603,
  },
  {
    art_state_id: "AST1CF51E70A6",
    state_code: "MO",
    name: "Mono Department",
    country_code: "BJ",
    lat: 37.9218608,
    lon: -118.9528645,
  },
  {
    art_state_id: "AST8ED4C7615C",
    state_code: "AK",
    name: "Atakora Department",
    country_code: "BJ",
    lat: 10.7954931,
    lon: 1.6760691,
  },
  {
    art_state_id: "ASTE11BF9B033",
    state_code: "AL",
    name: "Alibori Department",
    country_code: "BJ",
    lat: 10.9681093,
    lon: 2.7779813,
  },
  {
    art_state_id: "AST35F25B0223",
    state_code: "BO",
    name: "Borgou Department",
    country_code: "BJ",
    lat: 9.5340864,
    lon: 2.7779813,
  },
  {
    art_state_id: "ASTA04B957A13",
    state_code: "AQ",
    name: "Atlantique Department",
    country_code: "BJ",
    lat: 6.6588391,
    lon: 2.2236667,
  },
  {
    art_state_id: "ASTFDB18ED009",
    state_code: "OU",
    name: "Ouémé Department",
    country_code: "BJ",
    lat: 6.6148152,
    lon: 2.4999918,
  },
  {
    art_state_id: "ASTE48FCDDE8E",
    state_code: "LI",
    name: "Littoral Department",
    country_code: "BJ",
    lat: 6.3806973,
    lon: 2.4406387,
  },
  {
    art_state_id: "ASTEE6635700D",
    state_code: "MH",
    name: "Machinga District",
    country_code: "MW",
    lat: -14.9407263,
    lon: 35.4781926,
  },
  {
    art_state_id: "AST676FA5E97B",
    state_code: "ZO",
    name: "Zomba District",
    country_code: "MW",
    lat: -15.3765857,
    lon: 35.3356518,
  },
  {
    art_state_id: "AST99FE180079",
    state_code: "MW",
    name: "Mwanza District",
    country_code: "MW",
    lat: -2.4671197,
    lon: 32.8986812,
  },
  {
    art_state_id: "AST29B35470F5",
    state_code: "NS",
    name: "Nsanje District",
    country_code: "MW",
    lat: -16.7288202,
    lon: 35.1708741,
  },
  {
    art_state_id: "ASTDF12E4C4D4",
    state_code: "SA",
    name: "Salima District",
    country_code: "MW",
    lat: -13.6809586,
    lon: 34.4198243,
  },
  {
    art_state_id: "AST3564D9511B",
    state_code: "CT",
    name: "Chitipa district",
    country_code: "MW",
    lat: -9.7037655,
    lon: 33.2700253,
  },
  {
    art_state_id: "ASTEBF3D8D303",
    state_code: "NU",
    name: "Ntcheu District",
    country_code: "MW",
    lat: -14.9037538,
    lon: 34.7740793,
  },
  {
    art_state_id: "AST8B418336FF",
    state_code: "RU",
    name: "Rumphi District",
    country_code: "MW",
    lat: -10.7851537,
    lon: 34.3310364,
  },
  {
    art_state_id: "AST82A2302571",
    state_code: "DO",
    name: "Dowa District",
    country_code: "MW",
    lat: -13.6041256,
    lon: 33.8857747,
  },
  {
    art_state_id: "AST6D4F02C6F5",
    state_code: "KR",
    name: "Karonga District",
    country_code: "MW",
    lat: -9.9036365,
    lon: 33.9750018,
  },
  {
    art_state_id: "AST4F14F90375",
    state_code: "C",
    name: "Central Region",
    country_code: "MW",
    lat: -13.2687204,
    lon: 33.9301963,
  },
  {
    art_state_id: "AST72C8F2222E",
    state_code: "LK",
    name: "Likoma District",
    country_code: "MW",
    lat: -12.0584005,
    lon: 34.7354031,
  },
  {
    art_state_id: "AST80C0BBAE63",
    state_code: "KS",
    name: "Kasungu District",
    country_code: "MW",
    lat: -13.1367065,
    lon: 33.258793,
  },
  {
    art_state_id: "ASTEE55A5C4B7",
    state_code: "NB",
    name: "Nkhata Bay District",
    country_code: "MW",
    lat: -11.7185042,
    lon: 34.3310364,
  },
  {
    art_state_id: "AST2F39E922E5",
    state_code: "BA",
    name: "Balaka District",
    country_code: "MW",
    lat: -15.0506595,
    lon: 35.0828588,
  },
  {
    art_state_id: "AST1AD5BF59FD",
    state_code: "DE",
    name: "Dedza District",
    country_code: "MW",
    lat: -14.1894585,
    lon: 34.2421597,
  },
  {
    art_state_id: "AST9973B856BE",
    state_code: "TH",
    name: "Thyolo District",
    country_code: "MW",
    lat: -16.1299177,
    lon: 35.1268781,
  },
  {
    art_state_id: "AST73086D94CA",
    state_code: "MC",
    name: "Mchinji District",
    country_code: "MW",
    lat: -13.7401525,
    lon: 32.9888319,
  },
  {
    art_state_id: "AST1BB0DF4B84",
    state_code: "NK",
    name: "Nkhotakota District",
    country_code: "MW",
    lat: -12.7541961,
    lon: 34.2421597,
  },
  {
    art_state_id: "ASTFA6047C140",
    state_code: "LI",
    name: "Lilongwe District",
    country_code: "MW",
    lat: -14.0475228,
    lon: 33.617577,
  },
  {
    art_state_id: "AST97B1432476",
    state_code: "BL",
    name: "Blantyre District",
    country_code: "MW",
    lat: -15.6778541,
    lon: 34.9506625,
  },
  {
    art_state_id: "AST01A6137EEA",
    state_code: "MU",
    name: "Mulanje District",
    country_code: "MW",
    lat: -15.9346434,
    lon: 35.5220012,
  },
  {
    art_state_id: "AST67BEB06EE0",
    state_code: "MZ",
    name: "Mzimba District",
    country_code: "MW",
    lat: -11.7475452,
    lon: 33.5280072,
  },
  {
    art_state_id: "ASTD5974C87E1",
    state_code: "N",
    name: "Northern Region",
    country_code: "MW",
    lat: -13.2687204,
    lon: 33.9301963,
  },
  {
    art_state_id: "AST9DDC9E8EC4",
    state_code: "S",
    name: "Southern Region",
    country_code: "MW",
    lat: 32.7504957,
    lon: -97.3315476,
  },
  {
    art_state_id: "ASTF7BD0FC1E0",
    state_code: "CK",
    name: "Chikwawa District",
    country_code: "MW",
    lat: -16.1958446,
    lon: 34.7740793,
  },
  {
    art_state_id: "ASTB38371C71A",
    state_code: "PH",
    name: "Phalombe District",
    country_code: "MW",
    lat: -15.7092038,
    lon: 35.6532848,
  },
  {
    art_state_id: "AST65990163E8",
    state_code: "CR",
    name: "Chiradzulu District",
    country_code: "MW",
    lat: -15.7423151,
    lon: 35.2587964,
  },
  {
    art_state_id: "AST8B6502B310",
    state_code: "MG",
    name: "Mangochi District",
    country_code: "MW",
    lat: -14.1388248,
    lon: 35.0388164,
  },
  {
    art_state_id: "ASTFFC1F1DC9D",
    state_code: "NI",
    name: "Ntchisi District",
    country_code: "MW",
    lat: -13.2841992,
    lon: 33.8857747,
  },
  {
    art_state_id: "AST89C6B22417",
    state_code: "KEN",
    name: "Kénédougou Province",
    country_code: "BF",
    lat: 11.3919395,
    lon: -4.976654,
  },
  {
    art_state_id: "AST624AFAE0AC",
    state_code: "NAM",
    name: "Namentenga Province",
    country_code: "BF",
    lat: 13.0812584,
    lon: -0.5257823,
  },
  {
    art_state_id: "AST27A344D1DA",
    state_code: "12",
    name: "Sahel Region",
    country_code: "BF",
    lat: 14.1000865,
    lon: -0.1494988,
  },
  {
    art_state_id: "AST484B6CDDB7",
    state_code: "06",
    name: "Centre-Ouest Region",
    country_code: "BF",
    lat: 11.8798466,
    lon: -2.302446,
  },
  {
    art_state_id: "AST279C50FE0E",
    state_code: "NAO",
    name: "Nahouri Province",
    country_code: "BF",
    lat: 11.2502267,
    lon: -1.135302,
  },
  {
    art_state_id: "AST6BF481CF53",
    state_code: "PAS",
    name: "Passoré Province",
    country_code: "BF",
    lat: 12.8881221,
    lon: -2.2236667,
  },
  {
    art_state_id: "AST431C265CB6",
    state_code: "ZOU",
    name: "Zoundwéogo Province",
    country_code: "BF",
    lat: 11.6141174,
    lon: -0.9820668,
  },
  {
    art_state_id: "AST127ABA434E",
    state_code: "SIS",
    name: "Sissili Province",
    country_code: "BF",
    lat: 11.2441219,
    lon: -2.2236667,
  },
  {
    art_state_id: "AST8547FEEA3F",
    state_code: "BAN",
    name: "Banwa Province",
    country_code: "BF",
    lat: 12.1323053,
    lon: -4.1513764,
  },
  {
    art_state_id: "ASTC116D516C3",
    state_code: "BGR",
    name: "Bougouriba Province",
    country_code: "BF",
    lat: 10.8722646,
    lon: -3.3388917,
  },
  {
    art_state_id: "AST1AE8FE554D",
    state_code: "GNA",
    name: "Gnagna Province",
    country_code: "BF",
    lat: 12.8974992,
    lon: 0.0746767,
  },
  {
    art_state_id: "AST9AED43944E",
    state_code: "MOU",
    name: "Mouhoun",
    country_code: "BF",
    lat: 12.1432381,
    lon: -3.3388917,
  },
  {
    art_state_id: "AST4170932485",
    state_code: "YAG",
    name: "Yagha Province",
    country_code: "BF",
    lat: 13.3576157,
    lon: 0.7532809,
  },
  {
    art_state_id: "AST625ED59AF8",
    state_code: "11",
    name: "Plateau-Central Region",
    country_code: "BF",
    lat: 12.2537648,
    lon: -0.7532809,
  },
  {
    art_state_id: "AST7A6E74754A",
    state_code: "SMT",
    name: "Sanmatenga Province",
    country_code: "BF",
    lat: 13.3565304,
    lon: -1.0586135,
  },
  {
    art_state_id: "ASTB4514C424E",
    state_code: "05",
    name: "Centre-Nord Region",
    country_code: "BF",
    lat: 13.1724464,
    lon: -0.9056623,
  },
  {
    art_state_id: "ASTBB6550F3ED",
    state_code: "TAP",
    name: "Tapoa Province",
    country_code: "BF",
    lat: 12.2497072,
    lon: 1.6760691,
  },
  {
    art_state_id: "AST919E24D08F",
    state_code: "HOU",
    name: "Houet Province",
    country_code: "BF",
    lat: 11.1320447,
    lon: -4.2333355,
  },
  {
    art_state_id: "AST017FA325BE",
    state_code: "ZON",
    name: "Zondoma Province",
    country_code: "BF",
    lat: 13.1165926,
    lon: -2.4208713,
  },
  {
    art_state_id: "ASTB4593E7802",
    state_code: "BLG",
    name: "Boulgou",
    country_code: "BF",
    lat: 11.4336766,
    lon: -0.3748354,
  },
  {
    art_state_id: "ASTBC6C898744",
    state_code: "KMD",
    name: "Komondjari Province",
    country_code: "BF",
    lat: 12.7126527,
    lon: 0.6773046,
  },
  {
    art_state_id: "AST1690167373",
    state_code: "KOP",
    name: "Koulpélogo Province",
    country_code: "BF",
    lat: 11.5247674,
    lon: 0.1494988,
  },
  {
    art_state_id: "AST9E3AA1A23C",
    state_code: "TUI",
    name: "Tuy Province",
    country_code: "BF",
    lat: 38.888684,
    lon: -77.004719,
  },
  {
    art_state_id: "ASTF9D903A6D3",
    state_code: "IOB",
    name: "Ioba Province",
    country_code: "BF",
    lat: 11.0562034,
    lon: -3.0175712,
  },
  {
    art_state_id: "ASTFC2AFFA448",
    state_code: "03",
    name: "Centre",
    country_code: "BF",
    lat: 12.36732,
    lon: -1.5430869129979032,
  },
  {
    art_state_id: "AST92F37E186A",
    state_code: "SOR",
    name: "Sourou Province",
    country_code: "BF",
    lat: 13.341803,
    lon: -2.9375739,
  },
  {
    art_state_id: "ASTE8CD849A74",
    state_code: "01",
    name: "Boucle du Mouhoun Region",
    country_code: "BF",
    lat: 12.4166,
    lon: -3.4195527,
  },
  {
    art_state_id: "ASTC21023B2E1",
    state_code: "SEN",
    name: "Séno Province",
    country_code: "BF",
    lat: 14.0072234,
    lon: -0.0746767,
  },
  {
    art_state_id: "AST95DDB41C2B",
    state_code: "13",
    name: "Sud-Ouest Region",
    country_code: "BF",
    lat: 10.4231493,
    lon: -3.2583626,
  },
  {
    art_state_id: "ASTFDC67E09B7",
    state_code: "OUB",
    name: "Oubritenga Province",
    country_code: "BF",
    lat: 12.7096087,
    lon: -1.443469,
  },
  {
    art_state_id: "AST968C63FBFD",
    state_code: "NAY",
    name: "Nayala Province",
    country_code: "BF",
    lat: 12.6964558,
    lon: -3.0175712,
  },
  {
    art_state_id: "ASTF5BDB9279B",
    state_code: "GOU",
    name: "Gourma Province",
    country_code: "BF",
    lat: 12.1624473,
    lon: 0.6773046,
  },
  {
    art_state_id: "AST5226D120C4",
    state_code: "OUD",
    name: "Oudalan Province",
    country_code: "BF",
    lat: 14.471902,
    lon: -0.4502368,
  },
  {
    art_state_id: "ASTE502815D41",
    state_code: "ZIR",
    name: "Ziro Province",
    country_code: "BF",
    lat: 11.6094995,
    lon: -1.9099238,
  },
  {
    art_state_id: "AST9037F439E4",
    state_code: "KOS",
    name: "Kossi Province",
    country_code: "BF",
    lat: 12.960458,
    lon: -3.9062688,
  },
  {
    art_state_id: "AST4C6D1EFB02",
    state_code: "KOW",
    name: "Kourwéogo Province",
    country_code: "BF",
    lat: 12.7077495,
    lon: -1.7538817,
  },
  {
    art_state_id: "AST493585D5B8",
    state_code: "GAN",
    name: "Ganzourgou Province",
    country_code: "BF",
    lat: 12.2537648,
    lon: -0.7532809,
  },
  {
    art_state_id: "AST1271AF90F6",
    state_code: "07",
    name: "Centre-Sud Region",
    country_code: "BF",
    lat: 11.5228911,
    lon: -1.0586135,
  },
  {
    art_state_id: "AST4A9DA95C2F",
    state_code: "YAT",
    name: "Yatenga Province",
    country_code: "BF",
    lat: 13.6249344,
    lon: -2.3813621,
  },
  {
    art_state_id: "AST12CA5260FB",
    state_code: "LOR",
    name: "Loroum Province",
    country_code: "BF",
    lat: 13.8129814,
    lon: -2.0665197,
  },
  {
    art_state_id: "AST2478821E46",
    state_code: "BAZ",
    name: "Bazèga Province",
    country_code: "BF",
    lat: 11.9767692,
    lon: -1.443469,
  },
  {
    art_state_id: "AST20095E8A18",
    state_code: "02",
    name: "Cascades Region",
    country_code: "BF",
    lat: 10.4072992,
    lon: -4.5624426,
  },
  {
    art_state_id: "ASTB9DF2AB7D4",
    state_code: "SNG",
    name: "Sanguié Province",
    country_code: "BF",
    lat: 12.1501861,
    lon: -2.6983868,
  },
  {
    art_state_id: "AST4C609047BA",
    state_code: "BAM",
    name: "Bam Province",
    country_code: "BF",
    lat: 13.446133,
    lon: -1.5983959,
  },
  {
    art_state_id: "AST8756EFCD21",
    state_code: "NOU",
    name: "Noumbiel Province",
    country_code: "BF",
    lat: 9.8440946,
    lon: -2.9775558,
  },
  {
    art_state_id: "AST4F604D6531",
    state_code: "KMP",
    name: "Kompienga Province",
    country_code: "BF",
    lat: 11.5238362,
    lon: 0.7532809,
  },
  {
    art_state_id: "ASTA0E5C3F009",
    state_code: "08",
    name: "Est Region",
    country_code: "BF",
    lat: 12.4365526,
    lon: 0.9056623,
  },
  {
    art_state_id: "ASTC800CE6BC4",
    state_code: "LER",
    name: "Léraba Province",
    country_code: "BF",
    lat: 10.6648785,
    lon: -5.3102505,
  },
  {
    art_state_id: "AST6B092EF8DA",
    state_code: "BAL",
    name: "Balé Province",
    country_code: "BF",
    lat: 11.7820602,
    lon: -3.0175712,
  },
  {
    art_state_id: "AST2B7B7781E7",
    state_code: "KOT",
    name: "Kouritenga Province",
    country_code: "BF",
    lat: 12.1631813,
    lon: -0.2244662,
  },
  {
    art_state_id: "ASTD7B6BD0323",
    state_code: "04",
    name: "Centre-Est Region",
    country_code: "BF",
    lat: 11.5247674,
    lon: -0.1494988,
  },
  {
    art_state_id: "ASTD5473EC51A",
    state_code: "PON",
    name: "Poni Province",
    country_code: "BF",
    lat: 10.3325996,
    lon: -3.3388917,
  },
  {
    art_state_id: "AST207000F311",
    state_code: "10",
    name: "Nord Region, Burkina Faso",
    country_code: "BF",
    lat: 13.718252,
    lon: -2.302446,
  },
  {
    art_state_id: "ASTC3560F4FCD",
    state_code: "09",
    name: "Hauts-Bassins Region",
    country_code: "BF",
    lat: 11.4942003,
    lon: -4.2333355,
  },
  {
    art_state_id: "AST7193906BE4",
    state_code: "SOM",
    name: "Soum Province",
    country_code: "BF",
    lat: 14.0962841,
    lon: -1.366216,
  },
  {
    art_state_id: "AST0988AC316C",
    state_code: "COM",
    name: "Comoé Province",
    country_code: "BF",
    lat: 10.4072992,
    lon: -4.5624426,
  },
  {
    art_state_id: "ASTFEF21C0261",
    state_code: "KAD",
    name: "Kadiogo Province",
    country_code: "BF",
    lat: 12.3425897,
    lon: -1.443469,
  },
  {
    art_state_id: "AST41774532BE",
    state_code: "IS",
    name: "Islamabad Capital Territory",
    country_code: "PK",
    lat: 33.7204997,
    lon: 73.0405277,
  },
  {
    art_state_id: "ASTC69CB83D0B",
    state_code: "GB",
    name: "Gilgit-Baltistan",
    country_code: "PK",
    lat: 35.8025667,
    lon: 74.9831808,
  },
  {
    art_state_id: "ASTFA9664E2C2",
    state_code: "KP",
    name: "Khyber Pakhtunkhwa",
    country_code: "PK",
    lat: 34.9526205,
    lon: 72.331113,
  },
  {
    art_state_id: "AST3688FB2473",
    state_code: "JK",
    name: "Azad Kashmir",
    country_code: "PK",
    lat: 33.9259055,
    lon: 73.7810334,
  },
  {
    art_state_id: "AST816112F1D5",
    state_code: "TA",
    name: "Federally Administered Tribal Areas",
    country_code: "PK",
    lat: 32.667476,
    lon: 69.8597406,
  },
  {
    art_state_id: "ASTDEB0AA835E",
    state_code: "BA",
    name: "Balochistan",
    country_code: "PK",
    lat: 28.4907332,
    lon: 65.0957792,
  },
  {
    art_state_id: "AST7BDA7661FE",
    state_code: "SD",
    name: "Sindh",
    country_code: "PK",
    lat: 25.8943018,
    lon: 68.5247149,
  },
  {
    art_state_id: "AST73FA3B2836",
    state_code: "PB",
    name: "Punjab",
    country_code: "PK",
    lat: 31.1471305,
    lon: 75.3412179,
  },
  {
    art_state_id: "ASTF393D486D1",
    state_code: "RA",
    name: "Al Rayyan Municipality",
    country_code: "QA",
    lat: 25.2522551,
    lon: 51.4388713,
  },
  {
    art_state_id: "AST81D2CEBC49",
    state_code: "SH",
    name: "Al-Shahaniya",
    country_code: "QA",
    lat: 25.4106386,
    lon: 51.1846025,
  },
  {
    art_state_id: "AST502C01C0BA",
    state_code: "WA",
    name: "Al Wakrah",
    country_code: "QA",
    lat: 25.1659314,
    lon: 51.5975524,
  },
  {
    art_state_id: "AST5A8CA278A5",
    state_code: "MS",
    name: "Madinat ash Shamal",
    country_code: "QA",
    lat: 26.1182743,
    lon: 51.2157265,
  },
  {
    art_state_id: "AST4FB13F7370",
    state_code: "DA",
    name: "Doha",
    country_code: "QA",
    lat: 25.2854473,
    lon: 51.5310398,
  },
  {
    art_state_id: "ASTB8CE70C1AA",
    state_code: "ZA",
    name: "Al Daayen",
    country_code: "QA",
    lat: 25.5784559,
    lon: 51.4821387,
  },
  {
    art_state_id: "AST803A9E58AD",
    state_code: "KH",
    name: "Al Khor",
    country_code: "QA",
    lat: 25.6804078,
    lon: 51.4968502,
  },
  {
    art_state_id: "AST49F0FB5246",
    state_code: "US",
    name: "Umm Salal Municipality",
    country_code: "QA",
    lat: 25.4875242,
    lon: 51.396568,
  },
  {
    art_state_id: "ASTBD2993801C",
    state_code: "RM",
    name: "Rumonge Province",
    country_code: "BI",
    lat: -3.9754049,
    lon: 29.4388014,
  },
  {
    art_state_id: "AST6B9B32D0D8",
    state_code: "MY",
    name: "Muyinga Province",
    country_code: "BI",
    lat: -2.7793511,
    lon: 30.2974199,
  },
  {
    art_state_id: "AST6AE15D4064",
    state_code: "MW",
    name: "Mwaro Province",
    country_code: "BI",
    lat: -3.5025918,
    lon: 29.6499162,
  },
  {
    art_state_id: "ASTB170FE05B5",
    state_code: "MA",
    name: "Makamba Province",
    country_code: "BI",
    lat: -4.3257062,
    lon: 29.6962677,
  },
  {
    art_state_id: "ASTD33CD8DFA8",
    state_code: "RT",
    name: "Rutana Province",
    country_code: "BI",
    lat: -3.8791523,
    lon: 30.0665236,
  },
  {
    art_state_id: "AST307351F615",
    state_code: "CI",
    name: "Cibitoke Province",
    country_code: "BI",
    lat: -2.8102897,
    lon: 29.1855785,
  },
  {
    art_state_id: "AST9C4AC7B53E",
    state_code: "RY",
    name: "Ruyigi Province",
    country_code: "BI",
    lat: -3.446207,
    lon: 30.2512728,
  },
  {
    art_state_id: "ASTE65C229C26",
    state_code: "KY",
    name: "Kayanza Province",
    country_code: "BI",
    lat: -3.0077981,
    lon: 29.6499162,
  },
  {
    art_state_id: "AST2CF2A36D7A",
    state_code: "MU",
    name: "Muramvya Province",
    country_code: "BI",
    lat: -3.2898398,
    lon: 29.6499162,
  },
  {
    art_state_id: "ASTE5F957B4B4",
    state_code: "KR",
    name: "Karuzi Province",
    country_code: "BI",
    lat: -3.1340347,
    lon: 30.112735,
  },
  {
    art_state_id: "ASTB07BE118E6",
    state_code: "KI",
    name: "Kirundo Province",
    country_code: "BI",
    lat: -2.5762882,
    lon: 30.112735,
  },
  {
    art_state_id: "ASTEDE0CE3A9A",
    state_code: "BB",
    name: "Bubanza Province",
    country_code: "BI",
    lat: -3.1572403,
    lon: 29.3714909,
  },
  {
    art_state_id: "AST32EF36913A",
    state_code: "GI",
    name: "Gitega Province",
    country_code: "BI",
    lat: -3.4929051,
    lon: 29.9277947,
  },
  {
    art_state_id: "ASTB937F44E18",
    state_code: "BM",
    name: "Bujumbura Mairie Province",
    country_code: "BI",
    lat: -3.3884141,
    lon: 29.3482646,
  },
  {
    art_state_id: "ASTD7D42AFB63",
    state_code: "NG",
    name: "Ngozi Province",
    country_code: "BI",
    lat: -2.8958243,
    lon: 29.8815203,
  },
  {
    art_state_id: "ASTD588AA5342",
    state_code: "BL",
    name: "Bujumbura Rural Province",
    country_code: "BI",
    lat: -3.5090144,
    lon: 29.464359,
  },
  {
    art_state_id: "ASTF11332CD9F",
    state_code: "CA",
    name: "Cankuzo Province",
    country_code: "BI",
    lat: -3.1527788,
    lon: 30.6199895,
  },
  {
    art_state_id: "AST968F31405A",
    state_code: "BR",
    name: "Bururi Province",
    country_code: "BI",
    lat: -3.9006851,
    lon: 29.5107708,
  },
  {
    art_state_id: "ASTB9BAF6673F",
    state_code: "FS",
    name: "Flores Department",
    country_code: "UY",
    lat: -33.5733753,
    lon: -56.8945028,
  },
  {
    art_state_id: "AST95945F1659",
    state_code: "SJ",
    name: "San José Department",
    country_code: "UY",
    lat: 37.3492968,
    lon: -121.9056049,
  },
  {
    art_state_id: "AST6D995DC27E",
    state_code: "AR",
    name: "Artigas Department",
    country_code: "UY",
    lat: -30.6175112,
    lon: -56.9594559,
  },
  {
    art_state_id: "AST025A69F26F",
    state_code: "MA",
    name: "Maldonado Department",
    country_code: "UY",
    lat: -34.5597932,
    lon: -54.8628552,
  },
  {
    art_state_id: "AST6C7F4E91EA",
    state_code: "RV",
    name: "Rivera Department",
    country_code: "UY",
    lat: -31.4817421,
    lon: -55.2435759,
  },
  {
    art_state_id: "AST4DB2F5E5FC",
    state_code: "CO",
    name: "Colonia Department",
    country_code: "UY",
    lat: -34.1294678,
    lon: -57.6605184,
  },
  {
    art_state_id: "AST8512AE560A",
    state_code: "DU",
    name: "Durazno Department",
    country_code: "UY",
    lat: -33.0232454,
    lon: -56.0284644,
  },
  {
    art_state_id: "AST3D70982F77",
    state_code: "RN",
    name: "Río Negro Department",
    country_code: "UY",
    lat: -32.7676356,
    lon: -57.4295207,
  },
  {
    art_state_id: "ASTA416120A17",
    state_code: "CL",
    name: "Cerro Largo Department",
    country_code: "UY",
    lat: -32.4411032,
    lon: -54.3521753,
  },
  {
    art_state_id: "AST036F332B58",
    state_code: "PA",
    name: "Paysandú Department",
    country_code: "UY",
    lat: -32.0667366,
    lon: -57.3364789,
  },
  {
    art_state_id: "AST51E91596D6",
    state_code: "CA",
    name: "Canelones Department",
    country_code: "UY",
    lat: -34.5408717,
    lon: -55.93076,
  },
  {
    art_state_id: "AST4036E2DA52",
    state_code: "TT",
    name: "Treinta y Tres Department",
    country_code: "UY",
    lat: -33.0685086,
    lon: -54.2858627,
  },
  {
    art_state_id: "AST3C820485AF",
    state_code: "LA",
    name: "Lavalleja Department",
    country_code: "UY",
    lat: -33.9226175,
    lon: -54.9765794,
  },
  {
    art_state_id: "AST5AF59D3085",
    state_code: "RO",
    name: "Rocha Department",
    country_code: "UY",
    lat: -33.9690081,
    lon: -54.021485,
  },
  {
    art_state_id: "AST85A0DA1B80",
    state_code: "FD",
    name: "Florida Department",
    country_code: "UY",
    lat: 28.0359495,
    lon: -82.4579289,
  },
  {
    art_state_id: "AST8D12BCD0FF",
    state_code: "MO",
    name: "Montevideo Department",
    country_code: "UY",
    lat: -34.8181587,
    lon: -56.2138256,
  },
  {
    art_state_id: "ASTE52DA37882",
    state_code: "SO",
    name: "Soriano Department",
    country_code: "UY",
    lat: -33.5102792,
    lon: -57.7498103,
  },
  {
    art_state_id: "AST84F9C37790",
    state_code: "SA",
    name: "Salto Department",
    country_code: "UY",
    lat: -31.388028,
    lon: -57.9612455,
  },
  {
    art_state_id: "AST6DA87B5801",
    state_code: "TA",
    name: "Tacuarembó Department",
    country_code: "UY",
    lat: -31.7206837,
    lon: -55.9859887,
  },
  {
    art_state_id: "ASTF94F3EE23C",
    state_code: "KFS",
    name: "Kafr el-Sheikh Governorate",
    country_code: "EG",
    lat: 31.3085444,
    lon: 30.8039474,
  },
  {
    art_state_id: "AST63780794FE",
    state_code: "C",
    name: "Cairo Governorate",
    country_code: "EG",
    lat: 29.9537564,
    lon: 31.5370003,
  },
  {
    art_state_id: "AST7E97FFE40D",
    state_code: "DT",
    name: "Damietta Governorate",
    country_code: "EG",
    lat: 31.3625799,
    lon: 31.6739371,
  },
  {
    art_state_id: "AST79915FF6D4",
    state_code: "ASN",
    name: "Aswan Governorate",
    country_code: "EG",
    lat: 23.6966498,
    lon: 32.7181375,
  },
  {
    art_state_id: "AST6F57CE9604",
    state_code: "SHG",
    name: "Sohag Governorate",
    country_code: "EG",
    lat: 26.693834,
    lon: 32.174605,
  },
  {
    art_state_id: "AST9EDA92E627",
    state_code: "SIN",
    name: "North Sinai Governorate",
    country_code: "EG",
    lat: 30.282365,
    lon: 33.617577,
  },
  {
    art_state_id: "ASTD520758E64",
    state_code: "MNF",
    name: "Monufia Governorate",
    country_code: "EG",
    lat: 30.5972455,
    lon: 30.9876321,
  },
  {
    art_state_id: "AST050149D8B4",
    state_code: "PTS",
    name: "Port Said Governorate",
    country_code: "EG",
    lat: 31.0758606,
    lon: 32.2653887,
  },
  {
    art_state_id: "ASTA5E316B94C",
    state_code: "BNS",
    name: "Beni Suef Governorate",
    country_code: "EG",
    lat: 28.8938837,
    lon: 31.4456179,
  },
  {
    art_state_id: "AST3A2C3A10A4",
    state_code: "MT",
    name: "Matrouh Governorate",
    country_code: "EG",
    lat: 29.569635,
    lon: 26.419389,
  },
  {
    art_state_id: "ASTC55C6F45F7",
    state_code: "KB",
    name: "Qalyubia Governorate",
    country_code: "EG",
    lat: 30.3292368,
    lon: 31.2168466,
  },
  {
    art_state_id: "AST44F05B620F",
    state_code: "SUZ",
    name: "Suez Governorate",
    country_code: "EG",
    lat: 29.3682255,
    lon: 32.174605,
  },
  {
    art_state_id: "ASTA357E4DDEB",
    state_code: "GH",
    name: "Gharbia Governorate",
    country_code: "EG",
    lat: 30.8753556,
    lon: 31.03351,
  },
  {
    art_state_id: "ASTD78C8EC66F",
    state_code: "ALX",
    name: "Alexandria Governorate",
    country_code: "EG",
    lat: 30.8760568,
    lon: 29.742604,
  },
  {
    art_state_id: "AST8396922029",
    state_code: "AST",
    name: "Asyut Governorate",
    country_code: "EG",
    lat: 27.2133831,
    lon: 31.4456179,
  },
  {
    art_state_id: "AST82245384A2",
    state_code: "JS",
    name: "South Sinai Governorate",
    country_code: "EG",
    lat: 29.3101828,
    lon: 34.1531947,
  },
  {
    art_state_id: "AST3751915EC6",
    state_code: "FYM",
    name: "Faiyum Governorate",
    country_code: "EG",
    lat: 29.3084021,
    lon: 30.8428497,
  },
  {
    art_state_id: "AST5797A197DB",
    state_code: "GZ",
    name: "Giza Governorate",
    country_code: "EG",
    lat: 28.7666216,
    lon: 29.2320784,
  },
  {
    art_state_id: "AST97F96A9475",
    state_code: "BA",
    name: "Red Sea Governorate",
    country_code: "EG",
    lat: 24.6826316,
    lon: 34.1531947,
  },
  {
    art_state_id: "AST91B92E721A",
    state_code: "BH",
    name: "Beheira Governorate",
    country_code: "EG",
    lat: 30.8480986,
    lon: 30.3435506,
  },
  {
    art_state_id: "AST861EA95712",
    state_code: "LX",
    name: "Luxor Governorate",
    country_code: "EG",
    lat: 25.3944444,
    lon: 32.4920088,
  },
  {
    art_state_id: "AST95AB265B08",
    state_code: "MN",
    name: "Minya Governorate",
    country_code: "EG",
    lat: 28.284729,
    lon: 30.5279096,
  },
  {
    art_state_id: "AST330B73F69A",
    state_code: "IS",
    name: "Ismailia Governorate",
    country_code: "EG",
    lat: 30.5830934,
    lon: 32.2653887,
  },
  {
    art_state_id: "AST467E56D9D3",
    state_code: "DK",
    name: "Dakahlia Governorate",
    country_code: "EG",
    lat: 31.1656044,
    lon: 31.4913182,
  },
  {
    art_state_id: "AST13DD7BB5CA",
    state_code: "WAD",
    name: "New Valley Governorate",
    country_code: "EG",
    lat: 24.5455638,
    lon: 27.1735316,
  },
  {
    art_state_id: "AST459F7DE016",
    state_code: "KN",
    name: "Qena Governorate",
    country_code: "EG",
    lat: 26.2346033,
    lon: 32.9888319,
  },
  {
    art_state_id: "AST5EBB610B68",
    state_code: "AG",
    name: "Agaléga",
    country_code: "MU",
    lat: -10.4,
    lon: 56.6166667,
  },
  {
    art_state_id: "ASTA1E140C635",
    state_code: "RO",
    name: "Rodrigues",
    country_code: "MU",
    lat: -19.7245385,
    lon: 63.4272185,
  },
  {
    art_state_id: "AST9CA6D68F64",
    state_code: "PA",
    name: "Pamplemousses District",
    country_code: "MU",
    lat: -20.1136008,
    lon: 57.575926,
  },
  {
    art_state_id: "ASTF3078D63E4",
    state_code: "CC",
    name: "Cargados Carajos",
    country_code: "MU",
    lat: -16.583333,
    lon: 59.616667,
  },
  {
    art_state_id: "ASTBBBB0F228C",
    state_code: "VP",
    name: "Vacoas-Phoenix",
    country_code: "MU",
    lat: -20.2984026,
    lon: 57.4938355,
  },
  {
    art_state_id: "ASTF3DF0C1841",
    state_code: "MO",
    name: "Moka District",
    country_code: "MU",
    lat: -20.2399782,
    lon: 57.575926,
  },
  {
    art_state_id: "AST4067AD46D8",
    state_code: "FL",
    name: "Flacq District",
    country_code: "MU",
    lat: -20.2257836,
    lon: 57.7119274,
  },
  {
    art_state_id: "AST2D3626D05D",
    state_code: "CU",
    name: "Curepipe",
    country_code: "MU",
    lat: -20.3170872,
    lon: 57.5265289,
  },
  {
    art_state_id: "ASTD9FD6CDF79",
    state_code: "PU",
    name: "Port Louis",
    country_code: "MU",
    lat: -20.1608912,
    lon: 57.5012222,
  },
  {
    art_state_id: "AST4595695348",
    state_code: "SA",
    name: "Savanne District",
    country_code: "MU",
    lat: -20.473953,
    lon: 57.4853561,
  },
  {
    art_state_id: "ASTCF605B6244",
    state_code: "QB",
    name: "Quatre Bornes",
    country_code: "MU",
    lat: -20.2674718,
    lon: 57.4796981,
  },
  {
    art_state_id: "AST3C6ECEFF14",
    state_code: "BL",
    name: "Rivière Noire District",
    country_code: "MU",
    lat: -20.3708492,
    lon: 57.3948649,
  },
  {
    art_state_id: "ASTE1892EF6CF",
    state_code: "PL",
    name: "Port Louis District",
    country_code: "MU",
    lat: -20.1608912,
    lon: 57.5012222,
  },
  {
    art_state_id: "AST46D1ABC7FA",
    state_code: "RR",
    name: "Rivière du Rempart District",
    country_code: "MU",
    lat: -20.0560983,
    lon: 57.6552389,
  },
  {
    art_state_id: "AST93D9421910",
    state_code: "BR",
    name: "Beau Bassin-Rose Hill",
    country_code: "MU",
    lat: -20.2230305,
    lon: 57.468383,
  },
  {
    art_state_id: "AST7593E082D8",
    state_code: "PW",
    name: "Plaines Wilhems District",
    country_code: "MU",
    lat: -20.3054872,
    lon: 57.4853561,
  },
  {
    art_state_id: "AST37FCE25C79",
    state_code: "GP",
    name: "Grand Port District",
    country_code: "MU",
    lat: -20.3851546,
    lon: 57.6665742,
  },
  {
    art_state_id: "AST4BFE532D23",
    state_code: "GUE",
    name: "Guelmim Province",
    country_code: "MA",
    lat: 28.9883659,
    lon: -10.0527498,
  },
  {
    art_state_id: "ASTED0A46316D",
    state_code: "AOU",
    name: "Aousserd Province",
    country_code: "MA",
    lat: 22.5521538,
    lon: -14.3297353,
  },
  {
    art_state_id: "ASTB527649A17",
    state_code: "HOC",
    name: "Al Hoceïma Province",
    country_code: "MA",
    lat: 35.2445589,
    lon: -3.9317468,
  },
  {
    art_state_id: "ASTB7B5733FA7",
    state_code: "LAR",
    name: "Larache Province",
    country_code: "MA",
    lat: 35.1744271,
    lon: -6.1473964,
  },
  {
    art_state_id: "AST65BC4C77E3",
    state_code: "OUA",
    name: "Ouarzazate Province",
    country_code: "MA",
    lat: 30.9335436,
    lon: -6.937016,
  },
  {
    art_state_id: "ASTD5DC927337",
    state_code: "BOM",
    name: "Boulemane Province",
    country_code: "MA",
    lat: 33.3625159,
    lon: -4.7303397,
  },
  {
    art_state_id: "ASTCF93D3A874",
    state_code: "02",
    name: "Oriental",
    country_code: "MA",
    lat: 37.069683,
    lon: -94.512277,
  },
  {
    art_state_id: "AST25B644E761",
    state_code: "BEM",
    name: "Béni-Mellal Province",
    country_code: "MA",
    lat: 32.342443,
    lon: -6.375799,
  },
  {
    art_state_id: "AST965FFC2A29",
    state_code: "SYB",
    name: "Sidi Youssef Ben Ali",
    country_code: "MA",
    lat: 31.6084373,
    lon: -7.9653067,
  },
  {
    art_state_id: "AST3CAE65E39E",
    state_code: "CHI",
    name: "Chichaoua Province",
    country_code: "MA",
    lat: 31.5383581,
    lon: -8.7646388,
  },
  {
    art_state_id: "ASTE784227B17",
    state_code: "BOD",
    name: "Boujdour Province",
    country_code: "MA",
    lat: 26.1252493,
    lon: -14.4847347,
  },
  {
    art_state_id: "ASTEA7D1C7C6C",
    state_code: "KHE",
    name: "Khémisset Province",
    country_code: "MA",
    lat: 33.8153704,
    lon: -6.0573302,
  },
  {
    art_state_id: "AST3800EC780C",
    state_code: "TIZ",
    name: "Tiznit Province",
    country_code: "MA",
    lat: 29.693392,
    lon: -9.732157,
  },
  {
    art_state_id: "ASTDF383DFACC",
    state_code: "05",
    name: "Béni Mellal-Khénifra",
    country_code: "MA",
    lat: 32.5719184,
    lon: -6.0679194,
  },
  {
    art_state_id: "AST36A3980EB5",
    state_code: "SIK",
    name: "Sidi Kacem Province",
    country_code: "MA",
    lat: 34.2260172,
    lon: -5.7129164,
  },
  {
    art_state_id: "ASTF0DD10688A",
    state_code: "JDI",
    name: "El Jadida Province",
    country_code: "MA",
    lat: 33.2316326,
    lon: -8.5007116,
  },
  {
    art_state_id: "AST748DAE83C4",
    state_code: "NAD",
    name: "Nador Province",
    country_code: "MA",
    lat: 34.9171926,
    lon: -2.8577105,
  },
  {
    art_state_id: "AST422669E778",
    state_code: "SET",
    name: "Settat Province",
    country_code: "MA",
    lat: 32.9924242,
    lon: -7.6222665,
  },
  {
    art_state_id: "AST84B3429074",
    state_code: "ZAG",
    name: "Zagora Province",
    country_code: "MA",
    lat: 30.5786093,
    lon: -5.8987139,
  },
  {
    art_state_id: "AST149C7C1329",
    state_code: "MED",
    name: "Mediouna Province",
    country_code: "MA",
    lat: 33.4540939,
    lon: -7.516602,
  },
  {
    art_state_id: "AST1CFAAFD7C1",
    state_code: "BER",
    name: "Berkane Province",
    country_code: "MA",
    lat: 34.8840876,
    lon: -2.341887,
  },
  {
    art_state_id: "AST5DBCF04249",
    state_code: "TNT",
    name: "Tan-Tan Province",
    country_code: "MA",
    lat: 28.03012,
    lon: -11.1617356,
  },
  {
    art_state_id: "ASTA6208AFF51",
    state_code: "NOU",
    name: "Nouaceur Province",
    country_code: "MA",
    lat: 33.3670393,
    lon: -7.5732537,
  },
  {
    art_state_id: "ASTEFB7BFC662",
    state_code: "07",
    name: "Marrakesh-Safi",
    country_code: "MA",
    lat: 31.7330833,
    lon: -8.1338558,
  },
  {
    art_state_id: "ASTA00AC9D04E",
    state_code: "SEF",
    name: "Sefrou Province",
    country_code: "MA",
    lat: 33.8305244,
    lon: -4.8353154,
  },
  {
    art_state_id: "ASTF955A7BE73",
    state_code: "08",
    name: "Drâa-Tafilalet",
    country_code: "MA",
    lat: 31.1499538,
    lon: -5.3939551,
  },
  {
    art_state_id: "ASTAC3603646C",
    state_code: "HAJ",
    name: "El Hajeb Province",
    country_code: "MA",
    lat: 33.685735,
    lon: -5.3677844,
  },
  {
    art_state_id: "ASTCA54FA226C",
    state_code: "ESM",
    name: "Es Semara Province",
    country_code: "MA",
    lat: 26.741856,
    lon: -11.6783671,
  },
  {
    art_state_id: "AST702DE6C7F3",
    state_code: "LAA",
    name: "Laâyoune Province",
    country_code: "MA",
    lat: 27.1500384,
    lon: -13.1990758,
  },
  {
    art_state_id: "ASTCBAFD1F8FC",
    state_code: "INE",
    name: "Inezgane-Aït Melloul Prefecture",
    country_code: "MA",
    lat: 30.3509098,
    lon: -9.389511,
  },
  {
    art_state_id: "AST64459F44E4",
    state_code: "09",
    name: "Souss-Massa",
    country_code: "MA",
    lat: 30.2750611,
    lon: -8.1338558,
  },
  {
    art_state_id: "AST5031E43EDD",
    state_code: "TAZ",
    name: "Taza Province",
    country_code: "MA",
    lat: 34.2788953,
    lon: -3.5812692,
  },
  {
    art_state_id: "ASTB04D5A9526",
    state_code: "ASZ",
    name: "Assa-Zag Province",
    country_code: "MA",
    lat: 28.1402395,
    lon: -9.7232673,
  },
  {
    art_state_id: "AST055A9149DF",
    state_code: "11",
    name: "Laâyoune-Sakia El Hamra",
    country_code: "MA",
    lat: 27.8683194,
    lon: -11.9804613,
  },
  {
    art_state_id: "AST8DF5A488AE",
    state_code: "ERR",
    name: "Errachidia Province",
    country_code: "MA",
    lat: 31.9051275,
    lon: -4.7277528,
  },
  {
    art_state_id: "AST272BA2A0B7",
    state_code: "FAH",
    name: "Fahs Anjra Province",
    country_code: "MA",
    lat: 35.7601992,
    lon: -5.6668306,
  },
  {
    art_state_id: "AST43F239538B",
    state_code: "FIG",
    name: "Figuig Province",
    country_code: "MA",
    lat: 32.1092613,
    lon: -1.229806,
  },
  {
    art_state_id: "ASTBDA45400A5",
    state_code: "CHT",
    name: "Shtouka Ait Baha Province",
    country_code: "MA",
    lat: 30.1072422,
    lon: -9.2785583,
  },
  {
    art_state_id: "AST14315E97B4",
    state_code: "06",
    name: "Casablanca-Settat",
    country_code: "MA",
    lat: 33.2160872,
    lon: -7.4381355,
  },
  {
    art_state_id: "AST2ADD2806BA",
    state_code: "BES",
    name: "Ben Slimane Province",
    country_code: "MA",
    lat: 33.6189698,
    lon: -7.1305536,
  },
  {
    art_state_id: "AST3D05F9A7FD",
    state_code: "10",
    name: "Guelmim-Oued Noun",
    country_code: "MA",
    lat: 28.4844281,
    lon: -10.0807298,
  },
  {
    art_state_id: "AST9F8F2610C8",
    state_code: "12",
    name: "Dakhla-Oued Ed-Dahab",
    country_code: "MA",
    lat: 22.7337892,
    lon: -14.2861116,
  },
  {
    art_state_id: "AST71E85DCE65",
    state_code: "JRA",
    name: "Jerada Province",
    country_code: "MA",
    lat: 34.3061791,
    lon: -2.1794136,
  },
  {
    art_state_id: "ASTD8F9BA1538",
    state_code: "KEN",
    name: "Kénitra Province",
    country_code: "MA",
    lat: 34.2540503,
    lon: -6.5890166,
  },
  {
    art_state_id: "AST1AAA936E63",
    state_code: "KES",
    name: "Kelaat Sraghna Province",
    country_code: "MA",
    lat: 32.0522767,
    lon: -7.3516558,
  },
  {
    art_state_id: "ASTE3ED65A506",
    state_code: "CHE",
    name: "Chefchaouen Province",
    country_code: "MA",
    lat: 35.018172,
    lon: -5.1432068,
  },
  {
    art_state_id: "AST7B5C8096C8",
    state_code: "SAF",
    name: "Safi Province",
    country_code: "MA",
    lat: 32.2989872,
    lon: -9.1013498,
  },
  {
    art_state_id: "ASTC831676FAB",
    state_code: "TAT",
    name: "Tata Province",
    country_code: "MA",
    lat: 29.750877,
    lon: -7.9756343,
  },
  {
    art_state_id: "ASTCA0B11B1ED",
    state_code: "03",
    name: "Fès-Meknès",
    country_code: "MA",
    lat: 34.062529,
    lon: -4.7277528,
  },
  {
    art_state_id: "AST30F6F24750",
    state_code: "TAR",
    name: "Taroudant Province",
    country_code: "MA",
    lat: 30.4727126,
    lon: -8.8748765,
  },
  {
    art_state_id: "AST75460910BA",
    state_code: "MOU",
    name: "Moulay Yacoub Province",
    country_code: "MA",
    lat: 34.0874479,
    lon: -5.1784019,
  },
  {
    art_state_id: "AST78BA298D0C",
    state_code: "ESI",
    name: "Essaouira Province",
    country_code: "MA",
    lat: 31.5084926,
    lon: -9.7595041,
  },
  {
    art_state_id: "AST30EDA1BEA9",
    state_code: "KHN",
    name: "Khénifra Province",
    country_code: "MA",
    lat: 32.9340471,
    lon: -5.661571,
  },
  {
    art_state_id: "AST651998E35D",
    state_code: "TET",
    name: "Tétouan Province",
    country_code: "MA",
    lat: 35.5888995,
    lon: -5.3625516,
  },
  {
    art_state_id: "AST269EC28F8E",
    state_code: "OUD",
    name: "Oued Ed-Dahab Province",
    country_code: "MA",
    lat: 22.7337892,
    lon: -14.2861116,
  },
  {
    art_state_id: "ASTE026595181",
    state_code: "HAO",
    name: "Al Haouz Province",
    country_code: "MA",
    lat: 31.2956729,
    lon: -7.87216,
  },
  {
    art_state_id: "ASTF4069A5A60",
    state_code: "AZI",
    name: "Azilal Province",
    country_code: "MA",
    lat: 32.004262,
    lon: -6.5783387,
  },
  {
    art_state_id: "AST29AB1F9688",
    state_code: "TAI",
    name: "Taourirt Province",
    country_code: "MA",
    lat: 34.212598,
    lon: -2.6983868,
  },
  {
    art_state_id: "AST8CBF68490F",
    state_code: "TAO",
    name: "Taounate Province",
    country_code: "MA",
    lat: 34.536917,
    lon: -4.6398693,
  },
  {
    art_state_id: "ASTBF10BCF28C",
    state_code: "01",
    name: "Tanger-Tétouan-Al Hoceïma",
    country_code: "MA",
    lat: 35.2629558,
    lon: -5.5617279,
  },
  {
    art_state_id: "AST6BB8BBCEA6",
    state_code: "IFR",
    name: "Ifrane Province",
    country_code: "MA",
    lat: 33.5228062,
    lon: -5.1109552,
  },
  {
    art_state_id: "AST8415DC09EB",
    state_code: "KHO",
    name: "Khouribga Province",
    country_code: "MA",
    lat: 32.886023,
    lon: -6.9208655,
  },
  {
    art_state_id: "ASTDC4D6A957F",
    state_code: "P",
    name: "Cabo Delgado Province",
    country_code: "MZ",
    lat: -12.3335474,
    lon: 39.3206241,
  },
  {
    art_state_id: "AST92A2DCD54E",
    state_code: "Q",
    name: "Zambezia Province",
    country_code: "MZ",
    lat: -16.5638987,
    lon: 36.6093926,
  },
  {
    art_state_id: "AST84EF8FF326",
    state_code: "G",
    name: "Gaza Province",
    country_code: "MZ",
    lat: -23.0221928,
    lon: 32.7181375,
  },
  {
    art_state_id: "ASTF9AD004234",
    state_code: "I",
    name: "Inhambane Province",
    country_code: "MZ",
    lat: -22.8527997,
    lon: 34.5508758,
  },
  {
    art_state_id: "AST3AB7D9A822",
    state_code: "S",
    name: "Sofala Province",
    country_code: "MZ",
    lat: -19.2039073,
    lon: 34.8624166,
  },
  {
    art_state_id: "AST4E600AD6DD",
    state_code: "L",
    name: "Maputo Province",
    country_code: "MZ",
    lat: -25.2569876,
    lon: 32.5372741,
  },
  {
    art_state_id: "AST85D66483B5",
    state_code: "A",
    name: "Niassa Province",
    country_code: "MZ",
    lat: -12.7826202,
    lon: 36.6093926,
  },
  {
    art_state_id: "AST49F66B06CD",
    state_code: "T",
    name: "Tete Province",
    country_code: "MZ",
    lat: -15.6596056,
    lon: 32.7181375,
  },
  {
    art_state_id: "AST37917EEC15",
    state_code: "MPM",
    name: "Maputo",
    country_code: "MZ",
    lat: -25.969248,
    lon: 32.5731746,
  },
  {
    art_state_id: "AST35BF86763D",
    state_code: "N",
    name: "Nampula Province",
    country_code: "MZ",
    lat: -14.7604931,
    lon: 39.3206241,
  },
  {
    art_state_id: "AST5FF40CCCF5",
    state_code: "B",
    name: "Manica Province",
    country_code: "MZ",
    lat: -19.5059787,
    lon: 33.438353,
  },
  {
    art_state_id: "AST68EC3A16EA",
    state_code: "01",
    name: "Hodh Ech Chargui Region",
    country_code: "MR",
    lat: 18.6737026,
    lon: -7.092877,
  },
  {
    art_state_id: "ASTCAEE76845C",
    state_code: "05",
    name: "Brakna Region",
    country_code: "MR",
    lat: 17.2317561,
    lon: -13.1740348,
  },
  {
    art_state_id: "AST59EE25F5D6",
    state_code: "11",
    name: "Tiris Zemmour Region",
    country_code: "MR",
    lat: 24.5773764,
    lon: -9.9018131,
  },
  {
    art_state_id: "AST222AF5F296",
    state_code: "04",
    name: "Gorgol Region",
    country_code: "MR",
    lat: 15.9717357,
    lon: -12.6216211,
  },
  {
    art_state_id: "ASTE4CC30A5ED",
    state_code: "12",
    name: "Inchiri Region",
    country_code: "MR",
    lat: 20.0280561,
    lon: -15.4068079,
  },
  {
    art_state_id: "AST645A576E47",
    state_code: "14",
    name: "Nouakchott-Nord Region",
    country_code: "MR",
    lat: 18.1130205,
    lon: -15.8994956,
  },
  {
    art_state_id: "AST09E4A97607",
    state_code: "07",
    name: "Adrar Region",
    country_code: "MR",
    lat: 19.8652176,
    lon: -12.8054753,
  },
  {
    art_state_id: "ASTE8FC101C9A",
    state_code: "09",
    name: "Tagant Region",
    country_code: "MR",
    lat: 18.5467527,
    lon: -9.9018131,
  },
  {
    art_state_id: "AST3C850777B6",
    state_code: "08",
    name: "Dakhlet Nouadhibou",
    country_code: "MR",
    lat: 20.5985588,
    lon: -16.2522143,
  },
  {
    art_state_id: "AST561F51B3FE",
    state_code: "15",
    name: "Nouakchott-Sud Region",
    country_code: "MR",
    lat: 17.9709288,
    lon: -15.9464874,
  },
  {
    art_state_id: "ASTB95C962B69",
    state_code: "06",
    name: "Trarza Region",
    country_code: "MR",
    lat: 17.8664964,
    lon: -14.6587821,
  },
  {
    art_state_id: "ASTEA1B23563C",
    state_code: "03",
    name: "Assaba Region",
    country_code: "MR",
    lat: 16.7759558,
    lon: -11.5248055,
  },
  {
    art_state_id: "AST69068637AB",
    state_code: "10",
    name: "Guidimaka Region",
    country_code: "MR",
    lat: 15.2557331,
    lon: -12.2547919,
  },
  {
    art_state_id: "AST3A69258564",
    state_code: "02",
    name: "Hodh El Gharbi Region",
    country_code: "MR",
    lat: 16.6912149,
    lon: -9.5450974,
  },
  {
    art_state_id: "AST2345AE2D94",
    state_code: "13",
    name: "Nouakchott-Ouest Region",
    country_code: "MR",
    lat: 18.1511357,
    lon: -15.993491,
  },
  {
    art_state_id: "ASTD68A0DF25A",
    state_code: "WTO",
    name: "Western Tobago",
    country_code: "TT",
    lat: 11.1897072,
    lon: -60.7795452,
  },
  {
    art_state_id: "AST008156F52B",
    state_code: "CTT",
    name: "Couva-Tabaquite-Talparo Regional Corporation",
    country_code: "TT",
    lat: 10.4297145,
    lon: -61.373521,
  },
  {
    art_state_id: "AST4F44E199B0",
    state_code: "ETO",
    name: "Eastern Tobago",
    country_code: "TT",
    lat: 11.2979348,
    lon: -60.5588524,
  },
  {
    art_state_id: "ASTF275C41A53",
    state_code: "MRC",
    name: "Rio Claro-Mayaro Regional Corporation",
    country_code: "TT",
    lat: 10.2412832,
    lon: -61.0937206,
  },
  {
    art_state_id: "AST260FD7B02C",
    state_code: "SJL",
    name: "San Juan-Laventille Regional Corporation",
    country_code: "TT",
    lat: 10.6908578,
    lon: -61.4552213,
  },
  {
    art_state_id: "AST2374348312",
    state_code: "TUP",
    name: "Tunapuna-Piarco Regional Corporation",
    country_code: "TT",
    lat: 10.6859096,
    lon: -61.3035248,
  },
  {
    art_state_id: "AST86AE248E58",
    state_code: "SFO",
    name: "San Fernando",
    country_code: "TT",
    lat: 34.2819461,
    lon: -118.4389719,
  },
  {
    art_state_id: "AST971F453DBA",
    state_code: "PTF",
    name: "Point Fortin",
    country_code: "TT",
    lat: 10.1702737,
    lon: -61.6713386,
  },
  {
    art_state_id: "AST64ACF3301F",
    state_code: "SGE",
    name: "Sangre Grande Regional Corporation",
    country_code: "TT",
    lat: 10.5852939,
    lon: -61.1315813,
  },
  {
    art_state_id: "AST5848214F8F",
    state_code: "ARI",
    name: "Arima",
    country_code: "TT",
    lat: 46.7931604,
    lon: -71.2584311,
  },
  {
    art_state_id: "AST25B1F84508",
    state_code: "POS",
    name: "Port of Spain",
    country_code: "TT",
    lat: 10.6603196,
    lon: -61.5085625,
  },
  {
    art_state_id: "ASTDF5D25A643",
    state_code: "SIP",
    name: "Siparia Regional Corporation",
    country_code: "TT",
    lat: 10.1245626,
    lon: -61.5603244,
  },
  {
    art_state_id: "AST397A3187C9",
    state_code: "PED",
    name: "Penal-Debe Regional Corporation",
    country_code: "TT",
    lat: 10.1337402,
    lon: -61.4435474,
  },
  {
    art_state_id: "AST5B27AAF9CE",
    state_code: "CHA",
    name: "Chaguanas",
    country_code: "TT",
    lat: 10.5168387,
    lon: -61.4114482,
  },
  {
    art_state_id: "AST9618C888C0",
    state_code: "DMN",
    name: "Diego Martin Regional Corporation",
    country_code: "TT",
    lat: 10.7362286,
    lon: -61.5544836,
  },
  {
    art_state_id: "AST304086FF2D",
    state_code: "PRT",
    name: "Princes Town Regional Corporation",
    country_code: "TT",
    lat: 10.1786746,
    lon: -61.2801996,
  },
  {
    art_state_id: "AST1BEC53AD72",
    state_code: "M",
    name: "Mary Region",
    country_code: "TM",
    lat: 36.9481623,
    lon: 62.4504154,
  },
  {
    art_state_id: "AST53C037DDFB",
    state_code: "L",
    name: "Lebap Region",
    country_code: "TM",
    lat: 38.1272462,
    lon: 64.7162415,
  },
  {
    art_state_id: "ASTADF1509121",
    state_code: "S",
    name: "Ashgabat",
    country_code: "TM",
    lat: 37.9600766,
    lon: 58.3260629,
  },
  {
    art_state_id: "ASTFA89AD9520",
    state_code: "B",
    name: "Balkan Region",
    country_code: "TM",
    lat: 41.8101472,
    lon: 21.0937311,
  },
  {
    art_state_id: "AST72EB5FBFC8",
    state_code: "D",
    name: "Daşoguz Region",
    country_code: "TM",
    lat: 41.8368737,
    lon: 59.9651904,
  },
  {
    art_state_id: "AST078B5334DA",
    state_code: "A",
    name: "Ahal Region",
    country_code: "TM",
    lat: 38.6399398,
    lon: 59.4720904,
  },
  {
    art_state_id: "AST6E38953F61",
    state_code: "B",
    name: "Beni Department",
    country_code: "BO",
    lat: -14.3782747,
    lon: -65.0957792,
  },
  {
    art_state_id: "AST581DA48744",
    state_code: "O",
    name: "Oruro Department",
    country_code: "BO",
    lat: -18.5711579,
    lon: -67.7615983,
  },
  {
    art_state_id: "ASTDBB8A27342",
    state_code: "S",
    name: "Santa Cruz Department",
    country_code: "BO",
    lat: -16.7476037,
    lon: -62.0750998,
  },
  {
    art_state_id: "AST94C842E99B",
    state_code: "T",
    name: "Tarija Department",
    country_code: "BO",
    lat: -21.5831595,
    lon: -63.9586111,
  },
  {
    art_state_id: "ASTFD4ABA7DF3",
    state_code: "N",
    name: "Pando Department",
    country_code: "BO",
    lat: -10.7988901,
    lon: -66.9988011,
  },
  {
    art_state_id: "AST13CDAD6ADA",
    state_code: "L",
    name: "La Paz Department",
    country_code: "BO",
    lat: -17.0568696,
    lon: -64.9912286,
  },
  {
    art_state_id: "AST868AA6DFF2",
    state_code: "C",
    name: "Cochabamba Department",
    country_code: "BO",
    lat: -17.5681675,
    lon: -65.475736,
  },
  {
    art_state_id: "AST0F39823AD7",
    state_code: "H",
    name: "Chuquisaca Department",
    country_code: "BO",
    lat: -20.0249144,
    lon: -64.1478236,
  },
  {
    art_state_id: "ASTD8D2B2F676",
    state_code: "P",
    name: "Potosí Department",
    country_code: "BO",
    lat: -20.624713,
    lon: -66.9988011,
  },
  {
    art_state_id: "AST86F94C5D75",
    state_code: "04",
    name: "Saint George Parish",
    country_code: "VC",
    lat: 42.957609,
    lon: -81.326705,
  },
  {
    art_state_id: "AST5EA944CD3B",
    state_code: "05",
    name: "Saint Patrick Parish",
    country_code: "VC",
    lat: 39.7509186,
    lon: -94.8450556,
  },
  {
    art_state_id: "AST96C3A7CB12",
    state_code: "02",
    name: "Saint Andrew Parish",
    country_code: "VC",
    lat: 43.0242999,
    lon: -81.2025,
  },
  {
    art_state_id: "AST2912346070",
    state_code: "03",
    name: "Saint David Parish",
    country_code: "VC",
    lat: 43.8523095,
    lon: -79.5236654,
  },
  {
    art_state_id: "ASTE13D32F0EA",
    state_code: "06",
    name: "Grenadines Parish",
    country_code: "VC",
    lat: 13.0122965,
    lon: -61.2277301,
  },
  {
    art_state_id: "ASTEEC03980AC",
    state_code: "01",
    name: "Charlotte Parish",
    country_code: "VC",
    lat: 13.2175451,
    lon: -61.1636244,
  },
  {
    art_state_id: "ASTFD8A1C808D",
    state_code: "SH",
    name: "Sharjah Emirate",
    country_code: "AE",
    lat: 25.0753974,
    lon: 55.7578403,
  },
  {
    art_state_id: "AST8E94A40401",
    state_code: "DU",
    name: "Dubai",
    country_code: "AE",
    lat: 25.2048493,
    lon: 55.2707828,
  },
  {
    art_state_id: "AST018DB7C1AC",
    state_code: "UQ",
    name: "Umm al-Quwain",
    country_code: "AE",
    lat: 25.5426324,
    lon: 55.5475348,
  },
  {
    art_state_id: "AST1AE51A0F25",
    state_code: "FU",
    name: "Fujairah",
    country_code: "AE",
    lat: 25.1288099,
    lon: 56.3264849,
  },
  {
    art_state_id: "AST61C3C77036",
    state_code: "RK",
    name: "Ras al-Khaimah",
    country_code: "AE",
    lat: 25.6741343,
    lon: 55.9804173,
  },
  {
    art_state_id: "AST8B7D17D6B1",
    state_code: "AJ",
    name: "Ajman Emirate",
    country_code: "AE",
    lat: 25.4052165,
    lon: 55.5136433,
  },
  {
    art_state_id: "ASTE3DA54B7A3",
    state_code: "AZ",
    name: "Abu Dhabi Emirate",
    country_code: "AE",
    lat: 24.453884,
    lon: 54.3773438,
  },
  {
    art_state_id: "AST78836C2614",
    state_code: "RA",
    name: "districts of Republican Subordination",
    country_code: "TJ",
    lat: 39.0857902,
    lon: 70.2408325,
  },
  {
    art_state_id: "AST4C4BE324A6",
    state_code: "KT",
    name: "Khatlon Province",
    country_code: "TJ",
    lat: 37.9113562,
    lon: 69.097023,
  },
  {
    art_state_id: "AST460C3B94B7",
    state_code: "GB",
    name: "Gorno-Badakhshan Autonomous Province",
    country_code: "TJ",
    lat: 38.412732,
    lon: 73.087749,
  },
  {
    art_state_id: "ASTE02E01AC7C",
    state_code: "SU",
    name: "Sughd Province",
    country_code: "TJ",
    lat: 39.5155326,
    lon: 69.097023,
  },
  {
    art_state_id: "AST1C92E6163D",
    state_code: "TNQ",
    name: "Tainan County",
    country_code: "TW",
    lat: 22.9997281,
    lon: 120.2270277,
  },
  {
    art_state_id: "ASTA0FBFF40C5",
    state_code: "ILA",
    name: "Yilan County",
    country_code: "TW",
    lat: 24.7021073,
    lon: 121.7377502,
  },
  {
    art_state_id: "AST17EF60935C",
    state_code: "PEN",
    name: "Penghu County",
    country_code: "TW",
    lat: 23.5711899,
    lon: 119.5793157,
  },
  {
    art_state_id: "AST6539B92D2A",
    state_code: "CHA",
    name: "Changhua County",
    country_code: "TW",
    lat: 24.0517963,
    lon: 120.5161352,
  },
  {
    art_state_id: "ASTD480B7E848",
    state_code: "PIF",
    name: "Pingtung County",
    country_code: "TW",
    lat: 22.5519759,
    lon: 120.5487597,
  },
  {
    art_state_id: "ASTA94FD9FE86",
    state_code: "TXG",
    name: "Taichung",
    country_code: "TW",
    lat: 24.1477358,
    lon: 120.6736482,
  },
  {
    art_state_id: "AST275A8731BE",
    state_code: "NAN",
    name: "Nantou County",
    country_code: "TW",
    lat: 23.9609981,
    lon: 120.9718638,
  },
  {
    art_state_id: "AST7B9BE4600E",
    state_code: "CYI",
    name: "Chiayi County",
    country_code: "TW",
    lat: 23.4518428,
    lon: 120.2554615,
  },
  {
    art_state_id: "AST83F536EA6C",
    state_code: "KHQ",
    name: "Kaohsiung County",
    country_code: "TW",
    lat: 22.6272784,
    lon: 120.3014353,
  },
  {
    art_state_id: "AST928DEE8CF1",
    state_code: "TTT",
    name: "Taitung County",
    country_code: "TW",
    lat: 22.7972447,
    lon: 121.0713702,
  },
  {
    art_state_id: "AST21F12C1095",
    state_code: "HUA",
    name: "Hualien County",
    country_code: "TW",
    lat: 23.9871589,
    lon: 121.6015714,
  },
  {
    art_state_id: "AST96DB73957B",
    state_code: "KHH",
    name: "Kaohsiung",
    country_code: "TW",
    lat: 22.6272784,
    lon: 120.3014353,
  },
  {
    art_state_id: "AST5F3FA25CCB",
    state_code: "MIA",
    name: "Miaoli County",
    country_code: "TW",
    lat: 24.560159,
    lon: 120.8214265,
  },
  {
    art_state_id: "ASTB9847574BF",
    state_code: "TXQ",
    name: "Taichung County",
    country_code: "TW",
    lat: 24.1477358,
    lon: 120.6736482,
  },
  {
    art_state_id: "ASTE25C796369",
    state_code: "KIN",
    name: "Kinmen",
    country_code: "TW",
    lat: 24.3487792,
    lon: 118.3285644,
  },
  {
    art_state_id: "AST26C43C840B",
    state_code: "YUN",
    name: "Yunlin County",
    country_code: "TW",
    lat: 23.7092033,
    lon: 120.4313373,
  },
  {
    art_state_id: "ASTB403AE21B5",
    state_code: "HSZ",
    name: "Hsinchu",
    country_code: "TW",
    lat: 24.8138287,
    lon: 120.9674798,
  },
  {
    art_state_id: "ASTC3B5A663F8",
    state_code: "CYQ",
    name: "Chiayi City",
    country_code: "TW",
    lat: 23.4800751,
    lon: 120.4491113,
  },
  {
    art_state_id: "AST7B3151A63C",
    state_code: "TAO",
    name: "Taoyuan City",
    country_code: "TW",
    lat: 24.9936281,
    lon: 121.3009798,
  },
  {
    art_state_id: "AST2A17E8AB7E",
    state_code: "LIE",
    name: "Lienchiang County",
    country_code: "TW",
    lat: 26.1505556,
    lon: 119.9288889,
  },
  {
    art_state_id: "ASTDFC3577AE1",
    state_code: "TNN",
    name: "Tainan",
    country_code: "TW",
    lat: 22.9997281,
    lon: 120.2270277,
  },
  {
    art_state_id: "ASTDFA6688AF6",
    state_code: "TPE",
    name: "Taipei",
    country_code: "TW",
    lat: 25.0329694,
    lon: 121.5654177,
  },
  {
    art_state_id: "AST18760518EB",
    state_code: "HSQ",
    name: "Hsinchu County",
    country_code: "TW",
    lat: 24.8387226,
    lon: 121.0177246,
  },
  {
    art_state_id: "AST9EEB66CEEA",
    state_code: "SK",
    name: "Northern Red Sea Region",
    country_code: "ER",
    lat: 16.2583997,
    lon: 38.8205454,
  },
  {
    art_state_id: "AST1C3D032E8F",
    state_code: "AN",
    name: "Anseba Region",
    country_code: "ER",
    lat: 16.4745531,
    lon: 37.8087693,
  },
  {
    art_state_id: "ASTD00FF94508",
    state_code: "MA",
    name: "Maekel Region",
    country_code: "ER",
    lat: 15.3551409,
    lon: 38.8623683,
  },
  {
    art_state_id: "AST3B43BD7C3E",
    state_code: "DU",
    name: "Debub Region",
    country_code: "ER",
    lat: 14.9478692,
    lon: 39.1543677,
  },
  {
    art_state_id: "ASTC88C0714C4",
    state_code: "GB",
    name: "Gash-Barka Region",
    country_code: "ER",
    lat: 15.4068825,
    lon: 37.6386622,
  },
  {
    art_state_id: "AST464261BA1E",
    state_code: "DK",
    name: "Southern Red Sea Region",
    country_code: "ER",
    lat: 13.5137103,
    lon: 41.7606472,
  },
  {
    art_state_id: "ASTE7F483F874",
    state_code: "2",
    name: "Southern Peninsula Region",
    country_code: "IS",
    lat: 63.9154803,
    lon: -22.3649667,
  },
  {
    art_state_id: "ASTEA2C18BEE5",
    state_code: "1",
    name: "Capital Region",
    country_code: "IS",
    lat: 38.5656957,
    lon: -92.1816949,
  },
  {
    art_state_id: "ASTF783D4953D",
    state_code: "4",
    name: "Westfjords",
    country_code: "IS",
    lat: 65.919615,
    lon: -21.8811764,
  },
  {
    art_state_id: "ASTAFEF48580F",
    state_code: "7",
    name: "Eastern Region",
    country_code: "IS",
    lat: 64.9841821,
    lon: -18.1059013,
  },
  {
    art_state_id: "ASTF0A43355A5",
    state_code: "8",
    name: "Southern Region",
    country_code: "IS",
    lat: 64.9841821,
    lon: -18.1059013,
  },
  {
    art_state_id: "AST92CA51DAFB",
    state_code: "5",
    name: "Northwestern Region",
    country_code: "IS",
    lat: 41.9133932,
    lon: -73.0471688,
  },
  {
    art_state_id: "AST98FB2B2840",
    state_code: "3",
    name: "Western Region",
    country_code: "IS",
    lat: 64.9841821,
    lon: -18.1059013,
  },
  {
    art_state_id: "AST644CC47BA3",
    state_code: "6",
    name: "Northeastern Region",
    country_code: "IS",
    lat: 43.2994285,
    lon: -74.2179326,
  },
  {
    art_state_id: "ASTACACBD538F",
    state_code: "C",
    name: "Río Muni",
    country_code: "GQ",
    lat: 1.4610606,
    lon: 9.6786894,
  },
  {
    art_state_id: "AST89743D59BA",
    state_code: "KN",
    name: "Kié-Ntem Province",
    country_code: "GQ",
    lat: 2.028093,
    lon: 11.0711758,
  },
  {
    art_state_id: "AST4B386BBF07",
    state_code: "WN",
    name: "Wele-Nzas Province",
    country_code: "GQ",
    lat: 1.4166162,
    lon: 11.0711758,
  },
  {
    art_state_id: "AST5B31540725",
    state_code: "LI",
    name: "Litoral Province",
    country_code: "GQ",
    lat: 1.5750244,
    lon: 9.8124935,
  },
  {
    art_state_id: "ASTF6B642701E",
    state_code: "I",
    name: "Insular Region",
    country_code: "GQ",
    lat: 37.09024,
    lon: -95.712891,
  },
  {
    art_state_id: "AST48CB5EEB7A",
    state_code: "BS",
    name: "Bioko Sur Province",
    country_code: "GQ",
    lat: 3.4209785,
    lon: 8.6160674,
  },
  {
    art_state_id: "ASTE2E1773B56",
    state_code: "AN",
    name: "Annobón Province",
    country_code: "GQ",
    lat: -1.4268782,
    lon: 5.6352801,
  },
  {
    art_state_id: "AST9E4EF7256E",
    state_code: "CS",
    name: "Centro Sur Province",
    country_code: "GQ",
    lat: 1.3436084,
    lon: 10.439656,
  },
  {
    art_state_id: "ASTFF4B303A04",
    state_code: "BN",
    name: "Bioko Norte Province",
    country_code: "GQ",
    lat: 3.6595072,
    lon: 8.7921836,
  },
  {
    art_state_id: "ASTC633DE8C4E",
    state_code: "CHH",
    name: "Chihuahua",
    country_code: "MX",
    lat: 28.6329957,
    lon: -106.0691004,
  },
  {
    art_state_id: "AST96B3FC09CF",
    state_code: "OAX",
    name: "Oaxaca",
    country_code: "MX",
    lat: 17.0731842,
    lon: -96.7265889,
  },
  {
    art_state_id: "AST519FE18AEB",
    state_code: "SIN",
    name: "Sinaloa",
    country_code: "MX",
    lat: 25.1721091,
    lon: -107.4795173,
  },
  {
    art_state_id: "AST9CBA13DEC9",
    state_code: "MEX",
    name: "México",
    country_code: "MX",
    lat: 23.634501,
    lon: -102.552784,
  },
  {
    art_state_id: "AST8B24356C94",
    state_code: "CHP",
    name: "Chiapas",
    country_code: "MX",
    lat: 16.7569318,
    lon: -93.1292353,
  },
  {
    art_state_id: "ASTB1554D0403",
    state_code: "NLE",
    name: "Nuevo León",
    country_code: "MX",
    lat: 25.592172,
    lon: -99.9961947,
  },
  {
    art_state_id: "AST15D12EA5B7",
    state_code: "DUR",
    name: "Durango",
    country_code: "MX",
    lat: 37.27528,
    lon: -107.8800667,
  },
  {
    art_state_id: "AST0E9E268766",
    state_code: "TAB",
    name: "Tabasco",
    country_code: "MX",
    lat: 17.8409173,
    lon: -92.6189273,
  },
  {
    art_state_id: "AST2F3E9298F1",
    state_code: "QUE",
    name: "Querétaro",
    country_code: "MX",
    lat: 20.5887932,
    lon: -100.3898881,
  },
  {
    art_state_id: "AST50F84BBD0A",
    state_code: "AGU",
    name: "Aguascalientes",
    country_code: "MX",
    lat: 21.8852562,
    lon: -102.2915677,
  },
  {
    art_state_id: "AST2302B41A35",
    state_code: "BCN",
    name: "Baja California",
    country_code: "MX",
    lat: 30.8406338,
    lon: -115.2837585,
  },
  {
    art_state_id: "ASTCCA40223E4",
    state_code: "TLA",
    name: "Tlaxcala",
    country_code: "MX",
    lat: 19.318154,
    lon: -98.2374954,
  },
  {
    art_state_id: "AST90F1A7A7DF",
    state_code: "GRO",
    name: "Guerrero",
    country_code: "MX",
    lat: 17.4391926,
    lon: -99.5450974,
  },
  {
    art_state_id: "AST3ABD9CED55",
    state_code: "BCS",
    name: "Baja California Sur",
    country_code: "MX",
    lat: 26.0444446,
    lon: -111.6660725,
  },
  {
    art_state_id: "ASTA5AB48F64D",
    state_code: "SLP",
    name: "San Luis Potosí",
    country_code: "MX",
    lat: 22.1564699,
    lon: -100.9855409,
  },
  {
    art_state_id: "AST877254BFBD",
    state_code: "ZAC",
    name: "Zacatecas",
    country_code: "MX",
    lat: 22.7708555,
    lon: -102.5832426,
  },
  {
    art_state_id: "AST8D477CFEF8",
    state_code: "TAM",
    name: "Tamaulipas",
    country_code: "MX",
    lat: 24.26694,
    lon: -98.8362755,
  },
  {
    art_state_id: "ASTB86459A333",
    state_code: "VER",
    name: "Veracruz",
    country_code: "MX",
    lat: 19.173773,
    lon: -96.1342241,
  },
  {
    art_state_id: "ASTCA775EBDA1",
    state_code: "MOR",
    name: "Morelos",
    country_code: "MX",
    lat: 18.6813049,
    lon: -99.1013498,
  },
  {
    art_state_id: "AST49411D0094",
    state_code: "YUC",
    name: "Yucatán",
    country_code: "MX",
    lat: 20.7098786,
    lon: -89.0943377,
  },
  {
    art_state_id: "ASTC85238E831",
    state_code: "ROO",
    name: "Quintana Roo",
    country_code: "MX",
    lat: 19.1817393,
    lon: -88.4791376,
  },
  {
    art_state_id: "AST7341A80176",
    state_code: "SON",
    name: "Sonora",
    country_code: "MX",
    lat: 37.9829496,
    lon: -120.3821724,
  },
  {
    art_state_id: "AST5D3D119716",
    state_code: "GUA",
    name: "Guanajuato",
    country_code: "MX",
    lat: 21.0190145,
    lon: -101.2573586,
  },
  {
    art_state_id: "AST8E5F89F1F3",
    state_code: "HID",
    name: "Hidalgo",
    country_code: "MX",
    lat: 26.1003547,
    lon: -98.2630684,
  },
  {
    art_state_id: "AST7BA5BACA51",
    state_code: "COA",
    name: "Coahuila",
    country_code: "MX",
    lat: 27.058676,
    lon: -101.7068294,
  },
  {
    art_state_id: "ASTABFF4D6E5F",
    state_code: "COL",
    name: "Colima",
    country_code: "MX",
    lat: 19.2452342,
    lon: -103.7240868,
  },
  {
    art_state_id: "ASTF42CBC4B07",
    state_code: "CMX",
    name: "Mexico City",
    country_code: "MX",
    lat: 19.4326077,
    lon: -99.133208,
  },
  {
    art_state_id: "AST3E5C02ED0C",
    state_code: "MIC",
    name: "Michoacán",
    country_code: "MX",
    lat: 19.5665192,
    lon: -101.7068294,
  },
  {
    art_state_id: "ASTE5F9AD2C5F",
    state_code: "CAM",
    name: "Campeche",
    country_code: "MX",
    lat: 19.8301251,
    lon: -90.5349087,
  },
  {
    art_state_id: "AST2B1D9201AD",
    state_code: "PUE",
    name: "Puebla",
    country_code: "MX",
    lat: 19.0414398,
    lon: -98.2062727,
  },
  {
    art_state_id: "AST302E67DC74",
    state_code: "NAY",
    name: "Nayarit",
    country_code: "MX",
    lat: 21.7513844,
    lon: -104.8454619,
  },
  {
    art_state_id: "ASTEF4DB9704E",
    state_code: "81",
    name: "Krabi",
    country_code: "TH",
    lat: 8.0862997,
    lon: 98.9062835,
  },
  {
    art_state_id: "AST44CD43E496",
    state_code: "85",
    name: "Ranong",
    country_code: "TH",
    lat: 9.9528702,
    lon: 98.6084641,
  },
  {
    art_state_id: "AST29EF82EC73",
    state_code: "39",
    name: "Nong Bua Lam Phu",
    country_code: "TH",
    lat: 17.2218247,
    lon: 102.4260368,
  },
  {
    art_state_id: "ASTB508458B8F",
    state_code: "11",
    name: "Samut Prakan",
    country_code: "TH",
    lat: 13.5990961,
    lon: 100.5998319,
  },
  {
    art_state_id: "AST4632394C0E",
    state_code: "84",
    name: "Surat Thani",
    country_code: "TH",
    lat: 9.1341949,
    lon: 99.3334198,
  },
  {
    art_state_id: "AST5DA802A4B3",
    state_code: "51",
    name: "Lamphun",
    country_code: "TH",
    lat: 18.5744606,
    lon: 99.0087221,
  },
  {
    art_state_id: "ASTE08C9FF41D",
    state_code: "43",
    name: "Nong Khai",
    country_code: "TH",
    lat: 17.8782803,
    lon: 102.7412638,
  },
  {
    art_state_id: "AST133B20ABDB",
    state_code: "40",
    name: "Khon Kaen",
    country_code: "TH",
    lat: 16.4321938,
    lon: 102.8236214,
  },
  {
    art_state_id: "AST6A86484A51",
    state_code: "22",
    name: "Chanthaburi",
    country_code: "TH",
    lat: 12.6112485,
    lon: 102.1037806,
  },
  {
    art_state_id: "ASTC9A21B50EB",
    state_code: "19",
    name: "Saraburi",
    country_code: "TH",
    lat: 14.5289154,
    lon: 100.9101421,
  },
  {
    art_state_id: "AST6FB86D73F4",
    state_code: "93",
    name: "Phatthalung",
    country_code: "TH",
    lat: 7.6166823,
    lon: 100.0740231,
  },
  {
    art_state_id: "ASTCE4BDCB749",
    state_code: "53",
    name: "Uttaradit",
    country_code: "TH",
    lat: 17.6200886,
    lon: 100.0992942,
  },
  {
    art_state_id: "AST73B519B20E",
    state_code: "17",
    name: "Sing Buri",
    country_code: "TH",
    lat: 14.8936253,
    lon: 100.3967314,
  },
  {
    art_state_id: "AST0F3116804A",
    state_code: "50",
    name: "Chiang Mai",
    country_code: "TH",
    lat: 18.7883439,
    lon: 98.9853008,
  },
  {
    art_state_id: "AST286B24EB3F",
    state_code: "60",
    name: "Nakhon Sawan",
    country_code: "TH",
    lat: 15.6987382,
    lon: 100.11996,
  },
  {
    art_state_id: "AST44192DDEE1",
    state_code: "95",
    name: "Yala",
    country_code: "TH",
    lat: 44.0579117,
    lon: -123.1653848,
  },
  {
    art_state_id: "ASTEA50A460DE",
    state_code: "14",
    name: "Phra Nakhon Si Ayutthaya",
    country_code: "TH",
    lat: 14.3692325,
    lon: 100.5876634,
  },
  {
    art_state_id: "AST2876C5A28B",
    state_code: "12",
    name: "Nonthaburi",
    country_code: "TH",
    lat: 13.8591084,
    lon: 100.5216508,
  },
  {
    art_state_id: "AST1072E65EF5",
    state_code: "23",
    name: "Trat",
    country_code: "TH",
    lat: 12.2427563,
    lon: 102.5174734,
  },
  {
    art_state_id: "ASTE969A20061",
    state_code: "30",
    name: "Nakhon Ratchasima",
    country_code: "TH",
    lat: 14.9738493,
    lon: 102.083652,
  },
  {
    art_state_id: "AST490F4F5A40",
    state_code: "57",
    name: "Chiang Rai",
    country_code: "TH",
    lat: 19.9104798,
    lon: 99.840576,
  },
  {
    art_state_id: "ASTC631C60485",
    state_code: "70",
    name: "Ratchaburi",
    country_code: "TH",
    lat: 13.5282893,
    lon: 99.8134211,
  },
  {
    art_state_id: "ASTBE70377F1F",
    state_code: "13",
    name: "Pathum Thani",
    country_code: "TH",
    lat: 14.0208391,
    lon: 100.5250276,
  },
  {
    art_state_id: "AST795102F80C",
    state_code: "47",
    name: "Sakon Nakhon",
    country_code: "TH",
    lat: 17.1664211,
    lon: 104.1486055,
  },
  {
    art_state_id: "AST3074A61CC1",
    state_code: "75",
    name: "Samut Songkhram",
    country_code: "TH",
    lat: 13.4098217,
    lon: 100.0022645,
  },
  {
    art_state_id: "ASTCC520C9955",
    state_code: "73",
    name: "Nakhon Pathom",
    country_code: "TH",
    lat: 13.8140293,
    lon: 100.0372929,
  },
  {
    art_state_id: "AST21F4FA7B22",
    state_code: "74",
    name: "Samut Sakhon",
    country_code: "TH",
    lat: 13.5475216,
    lon: 100.2743956,
  },
  {
    art_state_id: "AST4CF6B4D483",
    state_code: "58",
    name: "Mae Hong Son",
    country_code: "TH",
    lat: 19.3020296,
    lon: 97.9654368,
  },
  {
    art_state_id: "ASTF9C96E0C0D",
    state_code: "65",
    name: "Phitsanulok",
    country_code: "TH",
    lat: 16.8211238,
    lon: 100.2658516,
  },
  {
    art_state_id: "ASTED7BE150BA",
    state_code: "S",
    name: "Pattaya",
    country_code: "TH",
    lat: 12.9235557,
    lon: 100.8824551,
  },
  {
    art_state_id: "AST5273E17BE2",
    state_code: "77",
    name: "Prachuap Khiri Khan",
    country_code: "TH",
    lat: 11.7938389,
    lon: 99.7957564,
  },
  {
    art_state_id: "ASTA4A65B2D68",
    state_code: "42",
    name: "Loei",
    country_code: "TH",
    lat: 17.4860232,
    lon: 101.7223002,
  },
  {
    art_state_id: "ASTD9D60F895B",
    state_code: "45",
    name: "Roi Et",
    country_code: "TH",
    lat: 16.0538196,
    lon: 103.6520036,
  },
  {
    art_state_id: "ASTD3BAAE9773",
    state_code: "71",
    name: "Kanchanaburi",
    country_code: "TH",
    lat: 14.1011393,
    lon: 99.4179431,
  },
  {
    art_state_id: "ASTF3C2CF4117",
    state_code: "34",
    name: "Ubon Ratchathani",
    country_code: "TH",
    lat: 15.2448453,
    lon: 104.8472995,
  },
  {
    art_state_id: "ASTCE1566E6E1",
    state_code: "20",
    name: "Chon Buri",
    country_code: "TH",
    lat: 13.3611431,
    lon: 100.9846717,
  },
  {
    art_state_id: "ASTBD1B12373B",
    state_code: "66",
    name: "Phichit",
    country_code: "TH",
    lat: 16.2740876,
    lon: 100.3346991,
  },
  {
    art_state_id: "ASTFEB91AB077",
    state_code: "67",
    name: "Phetchabun",
    country_code: "TH",
    lat: 16.301669,
    lon: 101.1192804,
  },
  {
    art_state_id: "ASTF099946C89",
    state_code: "62",
    name: "Kamphaeng Phet",
    country_code: "TH",
    lat: 16.4827798,
    lon: 99.5226618,
  },
  {
    art_state_id: "AST444DE03218",
    state_code: "44",
    name: "Maha Sarakham",
    country_code: "TH",
    lat: 16.0132015,
    lon: 103.1615169,
  },
  {
    art_state_id: "AST46A8F65495",
    state_code: "21",
    name: "Rayong",
    country_code: "TH",
    lat: 12.6813957,
    lon: 101.2816261,
  },
  {
    art_state_id: "ASTC6004AB61E",
    state_code: "15",
    name: "Ang Thong",
    country_code: "TH",
    lat: 14.5896054,
    lon: 100.455052,
  },
  {
    art_state_id: "AST54E2764FC0",
    state_code: "80",
    name: "Nakhon Si Thammarat",
    country_code: "TH",
    lat: 8.4324831,
    lon: 99.9599033,
  },
  {
    art_state_id: "ASTA9021879D4",
    state_code: "35",
    name: "Yasothon",
    country_code: "TH",
    lat: 15.792641,
    lon: 104.1452827,
  },
  {
    art_state_id: "AST419F5BA661",
    state_code: "18",
    name: "Chai Nat",
    country_code: "TH",
    lat: 15.1851971,
    lon: 100.125125,
  },
  {
    art_state_id: "AST906AB170BE",
    state_code: "37",
    name: "Amnat Charoen",
    country_code: "TH",
    lat: 15.8656783,
    lon: 104.6257774,
  },
  {
    art_state_id: "AST1F4A399518",
    state_code: "72",
    name: "Suphanburi",
    country_code: "TH",
    lat: 14.4744892,
    lon: 100.1177128,
  },
  {
    art_state_id: "AST278882A168",
    state_code: "63",
    name: "Tak",
    country_code: "TH",
    lat: 45.0299646,
    lon: -93.1049815,
  },
  {
    art_state_id: "AST6623D4CE1E",
    state_code: "86",
    name: "Chumphon",
    country_code: "TH",
    lat: 10.4930496,
    lon: 99.1800199,
  },
  {
    art_state_id: "ASTAEFB2F8216",
    state_code: "41",
    name: "Udon Thani",
    country_code: "TH",
    lat: 17.3646969,
    lon: 102.8158924,
  },
  {
    art_state_id: "AST00307F556A",
    state_code: "54",
    name: "Phrae",
    country_code: "TH",
    lat: 18.1445774,
    lon: 100.1402831,
  },
  {
    art_state_id: "AST5CF12AB02F",
    state_code: "27",
    name: "Sa Kaeo",
    country_code: "TH",
    lat: 13.824038,
    lon: 102.0645839,
  },
  {
    art_state_id: "AST04B94EF48A",
    state_code: "55",
    name: "Nan",
    country_code: "TH",
    lat: 45.522208,
    lon: -122.9863281,
  },
  {
    art_state_id: "AST9427D018B0",
    state_code: "32",
    name: "Surin",
    country_code: "TH",
    lat: 37.0358271,
    lon: -95.6276367,
  },
  {
    art_state_id: "AST25B07EFBAB",
    state_code: "76",
    name: "Phetchaburi",
    country_code: "TH",
    lat: 12.9649215,
    lon: 99.6425883,
  },
  {
    art_state_id: "AST1A86ABFC79",
    state_code: "38",
    name: "Bueng Kan",
    country_code: "TH",
    lat: 18.3609104,
    lon: 103.6464463,
  },
  {
    art_state_id: "AST21378BEFCB",
    state_code: "31",
    name: "Buri Ram",
    country_code: "TH",
    lat: 14.9951003,
    lon: 103.1115915,
  },
  {
    art_state_id: "ASTE83A8FAB96",
    state_code: "26",
    name: "Nakhon Nayok",
    country_code: "TH",
    lat: 14.2069466,
    lon: 101.2130511,
  },
  {
    art_state_id: "AST8AEFDCC6D5",
    state_code: "83",
    name: "Phuket",
    country_code: "TH",
    lat: 7.8804479,
    lon: 98.3922504,
  },
  {
    art_state_id: "ASTD76D766942",
    state_code: "91",
    name: "Satun",
    country_code: "TH",
    lat: 6.6238158,
    lon: 100.0673744,
  },
  {
    art_state_id: "AST0B9287D049",
    state_code: "56",
    name: "Phayao",
    country_code: "TH",
    lat: 19.2154367,
    lon: 100.2023692,
  },
  {
    art_state_id: "AST5605EE95A2",
    state_code: "90",
    name: "Songkhla",
    country_code: "TH",
    lat: 7.1897659,
    lon: 100.5953813,
  },
  {
    art_state_id: "AST9A2E0A8C01",
    state_code: "94",
    name: "Pattani",
    country_code: "TH",
    lat: 6.7618308,
    lon: 101.3232549,
  },
  {
    art_state_id: "AST74A42F44A6",
    state_code: "92",
    name: "Trang",
    country_code: "TH",
    lat: 7.5644833,
    lon: 99.6239334,
  },
  {
    art_state_id: "AST2A67900587",
    state_code: "25",
    name: "Prachin Buri",
    country_code: "TH",
    lat: 14.0420699,
    lon: 101.6600874,
  },
  {
    art_state_id: "AST871329EDE1",
    state_code: "16",
    name: "Lopburi",
    country_code: "TH",
    lat: 14.7995081,
    lon: 100.6533706,
  },
  {
    art_state_id: "ASTD0AA82180C",
    state_code: "52",
    name: "Lampang",
    country_code: "TH",
    lat: 18.2855395,
    lon: 99.5127895,
  },
  {
    art_state_id: "ASTDAD812ECE3",
    state_code: "64",
    name: "Sukhothai",
    country_code: "TH",
    lat: 43.6485556,
    lon: -79.3746639,
  },
  {
    art_state_id: "ASTB50F0184F8",
    state_code: "49",
    name: "Mukdahan",
    country_code: "TH",
    lat: 16.5435914,
    lon: 104.7024121,
  },
  {
    art_state_id: "ASTA5F4A296F8",
    state_code: "33",
    name: "Si Sa Ket",
    country_code: "TH",
    lat: 15.1186009,
    lon: 104.3220095,
  },
  {
    art_state_id: "ASTBF5F7BFB58",
    state_code: "48",
    name: "Nakhon Phanom",
    country_code: "TH",
    lat: 17.392039,
    lon: 104.7695508,
  },
  {
    art_state_id: "AST4ED6C56DB9",
    state_code: "82",
    name: "Phang Nga",
    country_code: "TH",
    lat: 8.4501414,
    lon: 98.5255317,
  },
  {
    art_state_id: "ASTF7C3FFFB57",
    state_code: "46",
    name: "Kalasin",
    country_code: "TH",
    lat: 16.438508,
    lon: 103.5060994,
  },
  {
    art_state_id: "AST4F7A062DC8",
    state_code: "61",
    name: "Uthai Thani",
    country_code: "TH",
    lat: 15.3835001,
    lon: 100.0245527,
  },
  {
    art_state_id: "AST725DC83BB2",
    state_code: "24",
    name: "Chachoengsao",
    country_code: "TH",
    lat: 13.6904194,
    lon: 101.0779596,
  },
  {
    art_state_id: "AST9B7ADC5F70",
    state_code: "96",
    name: "Narathiwat",
    country_code: "TH",
    lat: 6.4254607,
    lon: 101.8253143,
  },
  {
    art_state_id: "ASTD88241FCDF",
    state_code: "10",
    name: "Bangkok",
    country_code: "TH",
    lat: 13.7563309,
    lon: 100.5017651,
  },
  {
    art_state_id: "AST1D7619D036",
    state_code: "39",
    name: "Hiiu County",
    country_code: "EE",
    lat: 58.9239553,
    lon: 22.5919468,
  },
  {
    art_state_id: "AST43006218E6",
    state_code: "84",
    name: "Viljandi County",
    country_code: "EE",
    lat: 58.2821746,
    lon: 25.5752233,
  },
  {
    art_state_id: "ASTBA85624EC7",
    state_code: "78",
    name: "Tartu County",
    country_code: "EE",
    lat: 58.4057128,
    lon: 26.801576,
  },
  {
    art_state_id: "ASTD96221CB8B",
    state_code: "82",
    name: "Valga County",
    country_code: "EE",
    lat: 57.9103441,
    lon: 26.1601819,
  },
  {
    art_state_id: "AST89E92D88DB",
    state_code: "70",
    name: "Rapla County",
    country_code: "EE",
    lat: 58.8492625,
    lon: 24.7346569,
  },
  {
    art_state_id: "ASTDB690A13B1",
    state_code: "86",
    name: "Võru County",
    country_code: "EE",
    lat: 57.7377372,
    lon: 27.1398938,
  },
  {
    art_state_id: "AST3704CD7639",
    state_code: "74",
    name: "Saare County",
    country_code: "EE",
    lat: 58.4849721,
    lon: 22.6136408,
  },
  {
    art_state_id: "AST15D26A01C4",
    state_code: "67",
    name: "Pärnu County",
    country_code: "EE",
    lat: 58.5261952,
    lon: 24.4020159,
  },
  {
    art_state_id: "AST29344596F8",
    state_code: "65",
    name: "Põlva County",
    country_code: "EE",
    lat: 58.1160622,
    lon: 27.2066394,
  },
  {
    art_state_id: "ASTD206A05FA7",
    state_code: "59",
    name: "Lääne-Viru County",
    country_code: "EE",
    lat: 59.3018816,
    lon: 26.3280312,
  },
  {
    art_state_id: "AST9460502BF2",
    state_code: "49",
    name: "Jõgeva County",
    country_code: "EE",
    lat: 58.7506143,
    lon: 26.3604878,
  },
  {
    art_state_id: "ASTFC1DBFD560",
    state_code: "51",
    name: "Järva County",
    country_code: "EE",
    lat: 58.8866713,
    lon: 25.5000624,
  },
  {
    art_state_id: "AST4913DC0D83",
    state_code: "37",
    name: "Harju County",
    country_code: "EE",
    lat: 59.3334239,
    lon: 25.2466974,
  },
  {
    art_state_id: "AST37428563A7",
    state_code: "57",
    name: "Lääne County",
    country_code: "EE",
    lat: 58.9722742,
    lon: 23.8740834,
  },
  {
    art_state_id: "ASTF0474D83AD",
    state_code: "44",
    name: "Ida-Viru County",
    country_code: "EE",
    lat: 59.2592663,
    lon: 27.4136535,
  },
  {
    art_state_id: "ASTE35C2620B1",
    state_code: "MC",
    name: "Moyen-Chari Region",
    country_code: "TD",
    lat: 9.0639998,
    lon: 18.4276047,
  },
  {
    art_state_id: "ASTEEF10082D2",
    state_code: "MO",
    name: "Mayo-Kebbi Ouest Region",
    country_code: "TD",
    lat: 10.4113014,
    lon: 15.5943388,
  },
  {
    art_state_id: "AST60F66FB1CC",
    state_code: "SI",
    name: "Sila Region",
    country_code: "TD",
    lat: 12.13074,
    lon: 21.2845025,
  },
  {
    art_state_id: "ASTFF6B3DA4CE",
    state_code: "HL",
    name: "Hadjer-Lamis",
    country_code: "TD",
    lat: 12.4577273,
    lon: 16.7234639,
  },
  {
    art_state_id: "AST30F4F680A8",
    state_code: "BO",
    name: "Borkou",
    country_code: "TD",
    lat: 17.8688845,
    lon: 18.8076195,
  },
  {
    art_state_id: "ASTFBE4072DBC",
    state_code: "EE",
    name: "Ennedi-Est",
    country_code: "TD",
    lat: 16.3420496,
    lon: 23.0011989,
  },
  {
    art_state_id: "AST901D40FEC9",
    state_code: "GR",
    name: "Guéra Region",
    country_code: "TD",
    lat: 11.1219015,
    lon: 18.4276047,
  },
  {
    art_state_id: "ASTE8278D8CC2",
    state_code: "LC",
    name: "Lac Region",
    country_code: "TD",
    lat: 13.6915377,
    lon: 14.1001326,
  },
  {
    art_state_id: "AST7659C87E04",
    state_code: "EN",
    name: "Ennedi Region",
    country_code: "TD",
    lat: 17.5414578,
    lon: 21.8568586,
  },
  {
    art_state_id: "AST01A3EEC687",
    state_code: "TA",
    name: "Tandjilé Region",
    country_code: "TD",
    lat: 9.6625729,
    lon: 16.7234639,
  },
  {
    art_state_id: "AST1AECBB6A6B",
    state_code: "ME",
    name: "Mayo-Kebbi Est Region",
    country_code: "TD",
    lat: 9.4046039,
    lon: 14.8454619,
  },
  {
    art_state_id: "AST4CCEDADD1F",
    state_code: "WF",
    name: "Wadi Fira Region",
    country_code: "TD",
    lat: 15.0892416,
    lon: 21.4752851,
  },
  {
    art_state_id: "AST47173F09F2",
    state_code: "OD",
    name: "Ouaddaï Region",
    country_code: "TD",
    lat: 13.748476,
    lon: 20.7122465,
  },
  {
    art_state_id: "ASTAB56A3B07C",
    state_code: "BG",
    name: "Bahr el Gazel",
    country_code: "TD",
    lat: 14.7702266,
    lon: 16.912251,
  },
  {
    art_state_id: "ASTC0F0FB9C32",
    state_code: "EO",
    name: "Ennedi-Ouest",
    country_code: "TD",
    lat: 18.977563,
    lon: 21.8568586,
  },
  {
    art_state_id: "ASTB71D86600E",
    state_code: "LO",
    name: "Logone Occidental Region",
    country_code: "TD",
    lat: 8.759676,
    lon: 15.876004,
  },
  {
    art_state_id: "ASTA4EA873243",
    state_code: "ND",
    name: "N'Djamena",
    country_code: "TD",
    lat: 12.1348457,
    lon: 15.0557415,
  },
  {
    art_state_id: "AST3A80B7EE8F",
    state_code: "TI",
    name: "Tibesti Region",
    country_code: "TD",
    lat: 21.3650031,
    lon: 16.912251,
  },
  {
    art_state_id: "ASTF46AC26EFF",
    state_code: "KA",
    name: "Kanem Region",
    country_code: "TD",
    lat: 14.8781262,
    lon: 15.4068079,
  },
  {
    art_state_id: "AST4213B69C07",
    state_code: "MA",
    name: "Mandoul Region",
    country_code: "TD",
    lat: 8.603091,
    lon: 17.4795173,
  },
  {
    art_state_id: "AST5B9D83F12F",
    state_code: "BA",
    name: "Batha Region",
    country_code: "TD",
    lat: 13.9371775,
    lon: 18.4276047,
  },
  {
    art_state_id: "ASTCDC28DCD65",
    state_code: "LR",
    name: "Logone Oriental Region",
    country_code: "TD",
    lat: 8.3149949,
    lon: 16.3463791,
  },
  {
    art_state_id: "AST7D328EE0FD",
    state_code: "SA",
    name: "Salamat Region",
    country_code: "TD",
    lat: 10.9691601,
    lon: 20.7122465,
  },
  {
    art_state_id: "ASTB75251EA1F",
    state_code: "BY",
    name: "Berry Islands",
    country_code: "BS",
    lat: 25.6250042,
    lon: -77.8252203,
  },
  {
    art_state_id: "ASTD715BD3151",
    state_code: "NB",
    name: "Nichollstown and Berry Islands",
    country_code: "BS",
    lat: 25.7236234,
    lon: -77.8310104,
  },
  {
    art_state_id: "AST507D2E3D23",
    state_code: "GT",
    name: "Green Turtle Cay",
    country_code: "BS",
    lat: 26.7747107,
    lon: -77.3295708,
  },
  {
    art_state_id: "ASTA5162E148A",
    state_code: "CE",
    name: "Central Eleuthera",
    country_code: "BS",
    lat: 25.1362037,
    lon: -76.1435915,
  },
  {
    art_state_id: "AST54F95E8240",
    state_code: "GH",
    name: "Governor's Harbour",
    country_code: "BS",
    lat: 25.1948096,
    lon: -76.2439622,
  },
  {
    art_state_id: "ASTB05540BCF8",
    state_code: "HR",
    name: "High Rock",
    country_code: "BS",
    lat: 46.6843415,
    lon: -121.9017461,
  },
  {
    art_state_id: "AST43BB5662AB",
    state_code: "WG",
    name: "West Grand Bahama",
    country_code: "BS",
    lat: 26.659447,
    lon: -78.52065,
  },
  {
    art_state_id: "ASTB8879B119B",
    state_code: "RC",
    name: "Rum Cay District",
    country_code: "BS",
    lat: 23.6854676,
    lon: -74.8390162,
  },
  {
    art_state_id: "AST3E6B8A0151",
    state_code: "AK",
    name: "Acklins",
    country_code: "BS",
    lat: 22.3657708,
    lon: -74.0535126,
  },
  {
    art_state_id: "ASTE3D0E3363E",
    state_code: "NE",
    name: "North Eleuthera",
    country_code: "BS",
    lat: 25.4647517,
    lon: -76.675922,
  },
  {
    art_state_id: "AST1A49F6384D",
    state_code: "CO",
    name: "Central Abaco",
    country_code: "BS",
    lat: 26.3555029,
    lon: -77.1485163,
  },
  {
    art_state_id: "AST0F883BE12D",
    state_code: "MH",
    name: "Marsh Harbour",
    country_code: "BS",
    lat: 26.5241653,
    lon: -77.0909809,
  },
  {
    art_state_id: "AST04E2DF3F89",
    state_code: "BP",
    name: "Black Point",
    country_code: "BS",
    lat: 41.3951024,
    lon: -71.4650556,
  },
  {
    art_state_id: "AST53F54BB29F",
    state_code: "SP",
    name: "Sandy Point",
    country_code: "BS",
    lat: 39.0145464,
    lon: -76.3998925,
  },
  {
    art_state_id: "ASTEF2061C3F4",
    state_code: "SE",
    name: "South Eleuthera",
    country_code: "BS",
    lat: 24.7708562,
    lon: -76.2131474,
  },
  {
    art_state_id: "ASTF5743FC715",
    state_code: "SO",
    name: "South Abaco",
    country_code: "BS",
    lat: 26.0640591,
    lon: -77.2635038,
  },
  {
    art_state_id: "ASTE53069FBF9",
    state_code: "IN",
    name: "Inagua",
    country_code: "BS",
    lat: 21.0656066,
    lon: -73.323708,
  },
  {
    art_state_id: "ASTFCA9D3129A",
    state_code: "LI",
    name: "Long Island",
    country_code: "BS",
    lat: 40.789142,
    lon: -73.134961,
  },
  {
    art_state_id: "ASTFE45CC6045",
    state_code: "CI",
    name: "Cat Island",
    country_code: "BS",
    lat: 30.2280136,
    lon: -89.1014933,
  },
  {
    art_state_id: "AST5A2D8B773B",
    state_code: "EX",
    name: "Exuma",
    country_code: "BS",
    lat: 23.6192598,
    lon: -75.9695465,
  },
  {
    art_state_id: "AST3D9D407E32",
    state_code: "HI",
    name: "Harbour Island",
    country_code: "BS",
    lat: 25.50011,
    lon: -76.6340511,
  },
  {
    art_state_id: "ASTEAA741634B",
    state_code: "EG",
    name: "East Grand Bahama",
    country_code: "BS",
    lat: 26.6582823,
    lon: -78.2248291,
  },
  {
    art_state_id: "ASTDE10AB109E",
    state_code: "RI",
    name: "Ragged Island",
    country_code: "BS",
    lat: 41.597431,
    lon: -71.260202,
  },
  {
    art_state_id: "ASTFCC5E63696",
    state_code: "NO",
    name: "North Abaco",
    country_code: "BS",
    lat: 26.7871697,
    lon: -77.4357739,
  },
  {
    art_state_id: "AST3763E56C88",
    state_code: "NS",
    name: "North Andros",
    country_code: "BS",
    lat: 24.7063805,
    lon: -78.0195387,
  },
  {
    art_state_id: "AST4CFF81CCE3",
    state_code: "KB",
    name: "Kemps Bay",
    country_code: "BS",
    lat: 24.02364,
    lon: -77.545349,
  },
  {
    art_state_id: "ASTA95689BD86",
    state_code: "FC",
    name: "Fresh Creek",
    country_code: "BS",
    lat: 40.6543756,
    lon: -73.8947939,
  },
  {
    art_state_id: "AST9F97028160",
    state_code: "SR",
    name: "San Salvador and Rum Cay",
    country_code: "BS",
    lat: 23.6854676,
    lon: -74.8390162,
  },
  {
    art_state_id: "AST8C10DBF3F3",
    state_code: "CK",
    name: "Crooked Island",
    country_code: "BS",
    lat: 22.6390982,
    lon: -74.006509,
  },
  {
    art_state_id: "AST93CE83C277",
    state_code: "SA",
    name: "South Andros",
    country_code: "BS",
    lat: 23.9713556,
    lon: -77.6077865,
  },
  {
    art_state_id: "ASTE5AB97D139",
    state_code: "RS",
    name: "Rock Sound",
    country_code: "BS",
    lat: 39.0142443,
    lon: -95.6708989,
  },
  {
    art_state_id: "ASTE6C50791B0",
    state_code: "HT",
    name: "Hope Town",
    country_code: "BS",
    lat: 26.5009504,
    lon: -76.9959872,
  },
  {
    art_state_id: "AST2131A74937",
    state_code: "MC",
    name: "Mangrove Cay",
    country_code: "BS",
    lat: 24.1481425,
    lon: -77.7680952,
  },
  {
    art_state_id: "ASTE83CF79AFD",
    state_code: "FP",
    name: "Freeport",
    country_code: "BS",
    lat: 42.2966861,
    lon: -89.6212271,
  },
  {
    art_state_id: "AST75FDD1451C",
    state_code: "SS",
    name: "San Salvador Island",
    country_code: "BS",
    lat: 24.0775546,
    lon: -74.4760088,
  },
  {
    art_state_id: "ASTBC9FBF0301",
    state_code: "AC",
    name: "Acklins and Crooked Islands",
    country_code: "BS",
    lat: 22.3657708,
    lon: -74.0535126,
  },
  {
    art_state_id: "AST91976EEEFD",
    state_code: "BI",
    name: "Bimini",
    country_code: "BS",
    lat: 24.6415325,
    lon: -79.8506226,
  },
  {
    art_state_id: "ASTD2D87F15E4",
    state_code: "SW",
    name: "Spanish Wells",
    country_code: "BS",
    lat: 26.3250599,
    lon: -81.7980328,
  },
  {
    art_state_id: "AST135E8B17DF",
    state_code: "CS",
    name: "Central Andros",
    country_code: "BS",
    lat: 24.4688482,
    lon: -77.973865,
  },
  {
    art_state_id: "AST86D23CE2BA",
    state_code: "GC",
    name: "Grand Cay",
    country_code: "BS",
    lat: 27.2162615,
    lon: -78.3230559,
  },
  {
    art_state_id: "AST0E6CB6A2BB",
    state_code: "MG",
    name: "Mayaguana District",
    country_code: "BS",
    lat: 22.4017714,
    lon: -73.0641396,
  },
  {
    art_state_id: "AST0F07096F19",
    state_code: "J",
    name: "San Juan Province",
    country_code: "AR",
    lat: -34.9964963,
    lon: -64.9672817,
  },
  {
    art_state_id: "AST7593794BA0",
    state_code: "G",
    name: "Santiago del Estero Province",
    country_code: "AR",
    lat: -27.7833574,
    lon: -64.264167,
  },
  {
    art_state_id: "AST99479E53A6",
    state_code: "D",
    name: "San Luis Province",
    country_code: "AR",
    lat: -33.2962042,
    lon: -66.3294948,
  },
  {
    art_state_id: "AST846AEE8A6D",
    state_code: "T",
    name: "Tucumán Province",
    country_code: "AR",
    lat: -26.8221127,
    lon: -65.2192903,
  },
  {
    art_state_id: "AST16FA798ABA",
    state_code: "W",
    name: "Corrientes",
    country_code: "AR",
    lat: -27.4692131,
    lon: -58.8306349,
  },
  {
    art_state_id: "AST47F838FE3A",
    state_code: "R",
    name: "Río Negro Province",
    country_code: "AR",
    lat: -40.8261434,
    lon: -63.0266339,
  },
  {
    art_state_id: "ASTA581A2D2F5",
    state_code: "H",
    name: "Chaco Province",
    country_code: "AR",
    lat: -27.4257175,
    lon: -59.0243784,
  },
  {
    art_state_id: "ASTE51ECFD8C6",
    state_code: "S",
    name: "Santa Fe Province",
    country_code: "AR",
    lat: -31.5855109,
    lon: -60.7238016,
  },
  {
    art_state_id: "ASTBF82180529",
    state_code: "X",
    name: "Córdoba Province",
    country_code: "AR",
    lat: -34.9964963,
    lon: -64.9672817,
  },
  {
    art_state_id: "ASTD48E05194F",
    state_code: "A",
    name: "Salta Province",
    country_code: "AR",
    lat: -24.7997688,
    lon: -65.4150367,
  },
  {
    art_state_id: "ASTDF388B2808",
    state_code: "N",
    name: "Misiones Province",
    country_code: "AR",
    lat: -27.4269255,
    lon: -55.9467076,
  },
  {
    art_state_id: "AST854878A211",
    state_code: "Y",
    name: "Jujuy Province",
    country_code: "AR",
    lat: -24.1843397,
    lon: -65.302177,
  },
  {
    art_state_id: "AST0EE8A2E19B",
    state_code: "M",
    name: "Mendoza",
    country_code: "AR",
    lat: -32.8894587,
    lon: -68.8458386,
  },
  {
    art_state_id: "ASTCEE7E89E3A",
    state_code: "K",
    name: "Catamarca Province",
    country_code: "AR",
    lat: -28.4715877,
    lon: -65.7877209,
  },
  {
    art_state_id: "ASTF330C80095",
    state_code: "Q",
    name: "Neuquén Province",
    country_code: "AR",
    lat: -38.94587,
    lon: -68.0730925,
  },
  {
    art_state_id: "AST157FBE6AD8",
    state_code: "Z",
    name: "Santa Cruz Province",
    country_code: "AR",
    lat: -51.6352821,
    lon: -69.2474353,
  },
  {
    art_state_id: "AST5CE0D122DF",
    state_code: "V",
    name: "Tierra del Fuego Province",
    country_code: "AR",
    lat: -54.8053998,
    lon: -68.3242061,
  },
  {
    art_state_id: "ASTFE02181EC8",
    state_code: "U",
    name: "Chubut Province",
    country_code: "AR",
    lat: -43.2934246,
    lon: -65.1114818,
  },
  {
    art_state_id: "ASTFCBDCFACDB",
    state_code: "P",
    name: "Formosa Province",
    country_code: "AR",
    lat: -26.1894804,
    lon: -58.2242806,
  },
  {
    art_state_id: "AST4E20E71D23",
    state_code: "F",
    name: "La Rioja Province",
    country_code: "AR",
    lat: -29.4193793,
    lon: -66.8559932,
  },
  {
    art_state_id: "AST2F20BA8A99",
    state_code: "E",
    name: "Entre Ríos Province",
    country_code: "AR",
    lat: -31.7746654,
    lon: -60.4956461,
  },
  {
    art_state_id: "AST6AAB8D6F3A",
    state_code: "L",
    name: "La Pampa",
    country_code: "AR",
    lat: -36.6147573,
    lon: -64.2839209,
  },
  {
    art_state_id: "ASTED1143561E",
    state_code: "B",
    name: "Buenos Aires Province",
    country_code: "AR",
    lat: -37.2017285,
    lon: -59.8410697,
  },
  {
    art_state_id: "ASTC257E60460",
    state_code: "QC",
    name: "Quiché Department",
    country_code: "GT",
    lat: 15.4983808,
    lon: -90.9820668,
  },
  {
    art_state_id: "ASTAA6899D07B",
    state_code: "JA",
    name: "Jalapa Department",
    country_code: "GT",
    lat: 14.6121446,
    lon: -89.9626799,
  },
  {
    art_state_id: "AST24A967D8E1",
    state_code: "IZ",
    name: "Izabal Department",
    country_code: "GT",
    lat: 15.4976517,
    lon: -88.864698,
  },
  {
    art_state_id: "ASTE4EB058B13",
    state_code: "SU",
    name: "Suchitepéquez Department",
    country_code: "GT",
    lat: 14.4215982,
    lon: -91.4048249,
  },
  {
    art_state_id: "AST96C0134294",
    state_code: "SO",
    name: "Sololá Department",
    country_code: "GT",
    lat: 14.748523,
    lon: -91.2891036,
  },
  {
    art_state_id: "AST7134FAB3B5",
    state_code: "PR",
    name: "El Progreso Department",
    country_code: "GT",
    lat: 14.9388732,
    lon: -90.0746767,
  },
  {
    art_state_id: "ASTF2DCA535E9",
    state_code: "TO",
    name: "Totonicapán Department",
    country_code: "GT",
    lat: 14.9173402,
    lon: -91.3613923,
  },
  {
    art_state_id: "AST76FD14DDC0",
    state_code: "RE",
    name: "Retalhuleu Department",
    country_code: "GT",
    lat: 14.5245485,
    lon: -91.685788,
  },
  {
    art_state_id: "AST8387C9D99C",
    state_code: "SR",
    name: "Santa Rosa Department",
    country_code: "GT",
    lat: 38.4405759,
    lon: -122.7037543,
  },
  {
    art_state_id: "ASTF28C6B2D79",
    state_code: "CQ",
    name: "Chiquimula Department",
    country_code: "GT",
    lat: 14.7514999,
    lon: -89.4742177,
  },
  {
    art_state_id: "ASTD61C0ECE17",
    state_code: "SM",
    name: "San Marcos Department",
    country_code: "GT",
    lat: 14.9309569,
    lon: -91.9099238,
  },
  {
    art_state_id: "ASTE5ADF7B406",
    state_code: "QZ",
    name: "Quetzaltenango Department",
    country_code: "GT",
    lat: 14.792433,
    lon: -91.714958,
  },
  {
    art_state_id: "AST89935035F9",
    state_code: "PE",
    name: "Petén Department",
    country_code: "GT",
    lat: 16.912033,
    lon: -90.2995785,
  },
  {
    art_state_id: "AST71E6EAE5D4",
    state_code: "HU",
    name: "Huehuetenango Department",
    country_code: "GT",
    lat: 15.5879914,
    lon: -91.6760691,
  },
  {
    art_state_id: "AST15EA8C8351",
    state_code: "AV",
    name: "Alta Verapaz Department",
    country_code: "GT",
    lat: 15.5942883,
    lon: -90.1494988,
  },
  {
    art_state_id: "ASTE3C4A5AE91",
    state_code: "GU",
    name: "Guatemala Department",
    country_code: "GT",
    lat: 14.5649401,
    lon: -90.5257823,
  },
  {
    art_state_id: "AST4D54846A9B",
    state_code: "JU",
    name: "Jutiapa Department",
    country_code: "GT",
    lat: 14.1930802,
    lon: -89.9253233,
  },
  {
    art_state_id: "AST78F0E3F49D",
    state_code: "BV",
    name: "Baja Verapaz Department",
    country_code: "GT",
    lat: 15.1255867,
    lon: -90.3748354,
  },
  {
    art_state_id: "AST3329AE3B8E",
    state_code: "CM",
    name: "Chimaltenango Department",
    country_code: "GT",
    lat: 14.5634787,
    lon: -90.9820668,
  },
  {
    art_state_id: "AST96D10056F6",
    state_code: "SA",
    name: "Sacatepéquez Department",
    country_code: "GT",
    lat: 14.5178379,
    lon: -90.7152749,
  },
  {
    art_state_id: "AST513B59EC25",
    state_code: "ES",
    name: "Escuintla Department",
    country_code: "GT",
    lat: 14.1910912,
    lon: -90.9820668,
  },
  {
    art_state_id: "ASTF5C52CFCBB",
    state_code: "MDD",
    name: "Madre de Dios",
    country_code: "PE",
    lat: -11.7668705,
    lon: -70.8119953,
  },
  {
    art_state_id: "ASTFEFCF23566",
    state_code: "HUV",
    name: "Huancavelica",
    country_code: "PE",
    lat: -12.7861978,
    lon: -74.9764024,
  },
  {
    art_state_id: "AST5F95F9B985",
    state_code: "ANC",
    name: "Áncash",
    country_code: "PE",
    lat: -9.3250497,
    lon: -77.5619419,
  },
  {
    art_state_id: "ASTAF67140BD3",
    state_code: "ARE",
    name: "Arequipa",
    country_code: "PE",
    lat: -16.4090474,
    lon: -71.537451,
  },
  {
    art_state_id: "ASTE8DB8BECE4",
    state_code: "PUN",
    name: "Puno",
    country_code: "PE",
    lat: -15.8402218,
    lon: -70.0218805,
  },
  {
    art_state_id: "AST196F934F75",
    state_code: "LAL",
    name: "La Libertad",
    country_code: "PE",
    lat: 13.490697,
    lon: -89.3084607,
  },
  {
    art_state_id: "AST73C34D8922",
    state_code: "UCA",
    name: "Ucayali",
    country_code: "PE",
    lat: -9.8251183,
    lon: -73.087749,
  },
  {
    art_state_id: "ASTF229DE48AC",
    state_code: "AMA",
    name: "Amazonas",
    country_code: "PE",
    lat: -5,
    lon: -78,
  },
  {
    art_state_id: "AST42EBA1272D",
    state_code: "PAS",
    name: "Pasco",
    country_code: "PE",
    lat: 46.2305049,
    lon: -119.0922316,
  },
  {
    art_state_id: "AST868BC844C3",
    state_code: "HUC",
    name: "Huanuco",
    country_code: "PE",
    lat: -9.9207648,
    lon: -76.2410843,
  },
  {
    art_state_id: "AST7F999785F7",
    state_code: "CAJ",
    name: "Cajamarca",
    country_code: "PE",
    lat: -7.1617465,
    lon: -78.5127855,
  },
  {
    art_state_id: "ASTD937F61170",
    state_code: "TUM",
    name: "Tumbes",
    country_code: "PE",
    lat: -3.5564921,
    lon: -80.4270885,
  },
  {
    art_state_id: "ASTCD6C617EF6",
    state_code: "CUS",
    name: "Cusco",
    country_code: "PE",
    lat: -13.53195,
    lon: -71.9674626,
  },
  {
    art_state_id: "ASTF7F8208BC2",
    state_code: "AYA",
    name: "Ayacucho",
    country_code: "PE",
    lat: -13.1638737,
    lon: -74.2235641,
  },
  {
    art_state_id: "AST9E0725DE35",
    state_code: "JUN",
    name: "Junín",
    country_code: "PE",
    lat: -11.1581925,
    lon: -75.9926306,
  },
  {
    art_state_id: "ASTE051330E41",
    state_code: "SAM",
    name: "San Martín",
    country_code: "PE",
    lat: 37.0849464,
    lon: -121.6102216,
  },
  {
    art_state_id: "ASTE3D73F2123",
    state_code: "LIM",
    name: "Lima",
    country_code: "PE",
    lat: -12.0463731,
    lon: -77.042754,
  },
  {
    art_state_id: "AST3EC72E2E96",
    state_code: "TAC",
    name: "Tacna",
    country_code: "PE",
    lat: -18.0065679,
    lon: -70.2462741,
  },
  {
    art_state_id: "ASTE72BFDA92E",
    state_code: "PIU",
    name: "Piura",
    country_code: "PE",
    lat: -5.1782884,
    lon: -80.6548882,
  },
  {
    art_state_id: "AST95AB67BD4F",
    state_code: "MOQ",
    name: "Moquegua",
    country_code: "PE",
    lat: -17.1927361,
    lon: -70.9328138,
  },
  {
    art_state_id: "AST78B2C25EAD",
    state_code: "APU",
    name: "Apurímac",
    country_code: "PE",
    lat: -14.0504533,
    lon: -73.087749,
  },
  {
    art_state_id: "AST3979DF84B6",
    state_code: "ICA",
    name: "Ica",
    country_code: "PE",
    lat: 42.3528832,
    lon: -71.0430097,
  },
  {
    art_state_id: "AST6F6C95DBA4",
    state_code: "CAL",
    name: "Callao",
    country_code: "PE",
    lat: -12.0508491,
    lon: -77.1259843,
  },
  {
    art_state_id: "AST6B484D9EC3",
    state_code: "LAM",
    name: "Lambayeque",
    country_code: "PE",
    lat: -6.7197666,
    lon: -79.9080757,
  },
  {
    art_state_id: "AST92E1BFE16B",
    state_code: "11",
    name: "Redonda",
    country_code: "AG",
    lat: 16.938416,
    lon: -62.3455148,
  },
  {
    art_state_id: "AST77809C6EA0",
    state_code: "07",
    name: "Saint Peter Parish",
    country_code: "AG",
    lat: 17.2234721,
    lon: -61.9554608,
  },
  {
    art_state_id: "AST2FF2FC8A2C",
    state_code: "06",
    name: "Saint Paul Parish",
    country_code: "AG",
    lat: 17.2234721,
    lon: -61.9554608,
  },
  {
    art_state_id: "AST1614F6B5F2",
    state_code: "04",
    name: "Saint John Parish",
    country_code: "AG",
    lat: 17.2234721,
    lon: -61.9554608,
  },
  {
    art_state_id: "AST702B912D34",
    state_code: "05",
    name: "Saint Mary Parish",
    country_code: "AG",
    lat: 17.2234721,
    lon: -61.9554608,
  },
  {
    art_state_id: "AST2A561723F4",
    state_code: "10",
    name: "Barbuda",
    country_code: "AG",
    lat: 17.6266242,
    lon: -61.7713028,
  },
  {
    art_state_id: "AST944B790F1E",
    state_code: "03",
    name: "Saint George Parish",
    country_code: "AG",
    lat: 17.2234721,
    lon: -61.9554608,
  },
  {
    art_state_id: "AST621C102F67",
    state_code: "08",
    name: "Saint Philip Parish",
    country_code: "AG",
    lat: 40.4368258,
    lon: -80.0685532,
  },
  {
    art_state_id: "AST6E1A932397",
    state_code: "06",
    name: "South Bačka District",
    country_code: "RS",
    lat: 45.4890344,
    lon: 19.6976187,
  },
  {
    art_state_id: "AST53CB0AC0F2",
    state_code: "22",
    name: "Pirot District",
    country_code: "RS",
    lat: 43.0874036,
    lon: 22.5983044,
  },
  {
    art_state_id: "ASTF15D282DF8",
    state_code: "04",
    name: "South Banat District",
    country_code: "RS",
    lat: 45.0027457,
    lon: 21.0542509,
  },
  {
    art_state_id: "AST778AD44F8D",
    state_code: "01",
    name: "North Bačka District",
    country_code: "RS",
    lat: 45.9803394,
    lon: 19.5907001,
  },
  {
    art_state_id: "AST4894637F92",
    state_code: "23",
    name: "Jablanica District",
    country_code: "RS",
    lat: 42.948156,
    lon: 21.8129321,
  },
  {
    art_state_id: "ASTE74C8C25FF",
    state_code: "02",
    name: "Central Banat District",
    country_code: "RS",
    lat: 45.4788485,
    lon: 20.6082522,
  },
  {
    art_state_id: "AST4031447E5E",
    state_code: "14",
    name: "Bor District",
    country_code: "RS",
    lat: 44.0698918,
    lon: 22.0985086,
  },
  {
    art_state_id: "ASTC5C5F6BA8E",
    state_code: "21",
    name: "Toplica District",
    country_code: "RS",
    lat: 43.1906592,
    lon: 21.3407762,
  },
  {
    art_state_id: "AST1C10C67D40",
    state_code: "08",
    name: "Mačva District",
    country_code: "RS",
    lat: 44.5925314,
    lon: 19.5082246,
  },
  {
    art_state_id: "AST7B9AA68EA1",
    state_code: "19",
    name: "Rasina District",
    country_code: "RS",
    lat: 43.5263525,
    lon: 21.1588178,
  },
  {
    art_state_id: "AST5E6973A9E9",
    state_code: "24",
    name: "Pčinja District",
    country_code: "RS",
    lat: 42.5836362,
    lon: 22.1430215,
  },
  {
    art_state_id: "AST0701BA0213",
    state_code: "20",
    name: "Nišava District",
    country_code: "RS",
    lat: 43.3738902,
    lon: 21.9322331,
  },
  {
    art_state_id: "AST68962242C4",
    state_code: "XPR",
    name: "Prizren District",
    country_code: "XK",
    lat: 42.2152522,
    lon: 20.7414772,
  },
  {
    art_state_id: "ASTFC5B7EAF2E",
    state_code: "09",
    name: "Kolubara District",
    country_code: "RS",
    lat: 44.3509811,
    lon: 20.0004305,
  },
  {
    art_state_id: "ASTDD1FE0FFB7",
    state_code: "18",
    name: "Raška District",
    country_code: "RS",
    lat: 43.3373461,
    lon: 20.5734005,
  },
  {
    art_state_id: "ASTBEAB22FA5A",
    state_code: "05",
    name: "West Bačka District",
    country_code: "RS",
    lat: 45.7355385,
    lon: 19.1897364,
  },
  {
    art_state_id: "ASTFE0F63E4C2",
    state_code: "17",
    name: "Moravica District",
    country_code: "RS",
    lat: 43.84147,
    lon: 20.2904987,
  },
  {
    art_state_id: "AST8C992853CB",
    state_code: "00",
    name: "Belgrade",
    country_code: "RS",
    lat: 44.786568,
    lon: 20.4489216,
  },
  {
    art_state_id: "AST52E7198A0C",
    state_code: "16",
    name: "Zlatibor District",
    country_code: "RS",
    lat: 43.645417,
    lon: 19.7101455,
  },
  {
    art_state_id: "ASTB7F23AB2EE",
    state_code: "15",
    name: "Zaječar District",
    country_code: "RS",
    lat: 43.9015048,
    lon: 22.2738011,
  },
  {
    art_state_id: "ASTB344CC6028",
    state_code: "11",
    name: "Braničevo District",
    country_code: "RS",
    lat: 44.6982246,
    lon: 21.5446775,
  },
  {
    art_state_id: "AST1FAB2BB9A5",
    state_code: "VO",
    name: "Vojvodina",
    country_code: "RS",
    lat: 45.2608651,
    lon: 19.8319338,
  },
  {
    art_state_id: "ASTCD4796D006",
    state_code: "12",
    name: "Šumadija District",
    country_code: "RS",
    lat: 44.2050678,
    lon: 20.7856565,
  },
  {
    art_state_id: "AST81B3C39485",
    state_code: "03",
    name: "North Banat District",
    country_code: "RS",
    lat: 45.906839,
    lon: 19.9993417,
  },
  {
    art_state_id: "AST22EF80F384",
    state_code: "13",
    name: "Pomoravlje District",
    country_code: "RS",
    lat: 43.9591379,
    lon: 21.271353,
  },
  {
    art_state_id: "ASTCCA2D6E2F4",
    state_code: "XPE",
    name: "Peć District",
    country_code: "XK",
    lat: 42.6592155,
    lon: 20.2887624,
  },
  {
    art_state_id: "ASTFD56DE8FD7",
    state_code: "07",
    name: "Srem District",
    country_code: "RS",
    lat: 45.0029171,
    lon: 19.8013773,
  },
  {
    art_state_id: "ASTB2E4F78C85",
    state_code: "10",
    name: "Podunavlje District",
    country_code: "RS",
    lat: 44.4729156,
    lon: 20.9901426,
  },
  {
    art_state_id: "AST893C4CFAD7",
    state_code: "10",
    name: "Westmoreland Parish",
    country_code: "JM",
    lat: 18.2944378,
    lon: -78.1564432,
  },
  {
    art_state_id: "AST446AAC8F63",
    state_code: "11",
    name: "Saint Elizabeth Parish",
    country_code: "JM",
    lat: 38.9925308,
    lon: -94.58992,
  },
  {
    art_state_id: "AST074DAD810C",
    state_code: "06",
    name: "Saint Ann Parish",
    country_code: "JM",
    lat: 37.2871452,
    lon: -77.4103533,
  },
  {
    art_state_id: "ASTBFD679C154",
    state_code: "08",
    name: "Saint James Parish",
    country_code: "JM",
    lat: 30.0179292,
    lon: -90.7913227,
  },
  {
    art_state_id: "ASTE66B6B7154",
    state_code: "14",
    name: "Saint Catherine Parish",
    country_code: "JM",
    lat: 18.0364134,
    lon: -77.0564464,
  },
  {
    art_state_id: "ASTA56A7CA418",
    state_code: "05",
    name: "Saint Mary Parish",
    country_code: "JM",
    lat: 36.092522,
    lon: -95.973844,
  },
  {
    art_state_id: "AST5A516D8200",
    state_code: "01",
    name: "Kingston Parish",
    country_code: "JM",
    lat: 17.9683271,
    lon: -76.782702,
  },
  {
    art_state_id: "ASTE5DE400F1C",
    state_code: "09",
    name: "Hanover Parish",
    country_code: "JM",
    lat: 18.4097707,
    lon: -78.133638,
  },
  {
    art_state_id: "ASTEC974FAE87",
    state_code: "03",
    name: "Saint Thomas Parish",
    country_code: "JM",
    lat: 41.4425389,
    lon: -81.7402218,
  },
  {
    art_state_id: "AST3D6F55BF81",
    state_code: "02",
    name: "Saint Andrew",
    country_code: "JM",
    lat: 37.2245103,
    lon: -95.7021189,
  },
  {
    art_state_id: "AST8CE2407CBB",
    state_code: "04",
    name: "Portland Parish",
    country_code: "JM",
    lat: 18.0844274,
    lon: -76.4100267,
  },
  {
    art_state_id: "ASTE4A0BAA005",
    state_code: "13",
    name: "Clarendon Parish",
    country_code: "JM",
    lat: 17.9557183,
    lon: -77.2405153,
  },
  {
    art_state_id: "ASTA48C08D244",
    state_code: "12",
    name: "Manchester Parish",
    country_code: "JM",
    lat: 18.0669654,
    lon: -77.5160788,
  },
  {
    art_state_id: "AST3112EB6BA6",
    state_code: "07",
    name: "Trelawny Parish",
    country_code: "JM",
    lat: 18.3526143,
    lon: -77.6077865,
  },
  {
    art_state_id: "ASTF6749EB4D9",
    state_code: "05",
    name: "Dennery Quarter",
    country_code: "LC",
    lat: 13.9267393,
    lon: -60.9190988,
  },
  {
    art_state_id: "ASTD5989DB65E",
    state_code: "01",
    name: "Anse la Raye Quarter",
    country_code: "LC",
    lat: 13.9459424,
    lon: -61.0369468,
  },
  {
    art_state_id: "AST7F10EECDF0",
    state_code: "02",
    name: "Castries Quarter",
    country_code: "LC",
    lat: 14.0101094,
    lon: -60.9874687,
  },
  {
    art_state_id: "AST3E4E383F71",
    state_code: "07",
    name: "Laborie Quarter",
    country_code: "LC",
    lat: 13.7522783,
    lon: -60.9932889,
  },
  {
    art_state_id: "AST0A19B7EFA0",
    state_code: "03",
    name: "Choiseul Quarter",
    country_code: "LC",
    lat: 13.7750154,
    lon: -61.048591,
  },
  {
    art_state_id: "AST40BBBE31CC",
    state_code: "12",
    name: "Canaries",
    country_code: "LC",
    lat: 28.2915637,
    lon: -16.6291304,
  },
  {
    art_state_id: "ASTDFC4E8078D",
    state_code: "08",
    name: "Micoud Quarter",
    country_code: "LC",
    lat: 13.8211871,
    lon: -60.9001934,
  },
  {
    art_state_id: "ASTE0F5BCAF36",
    state_code: "11",
    name: "Vieux Fort Quarter",
    country_code: "LC",
    lat: 13.720608,
    lon: -60.9496433,
  },
  {
    art_state_id: "ASTB8091E206E",
    state_code: "10",
    name: "Soufrière Quarter",
    country_code: "LC",
    lat: 13.8570986,
    lon: -61.0573248,
  },
  {
    art_state_id: "AST2A956FB6AC",
    state_code: "09",
    name: "Praslin Quarter",
    country_code: "LC",
    lat: 13.8752392,
    lon: -60.8994663,
  },
  {
    art_state_id: "AST1DAFEE752B",
    state_code: "06",
    name: "Gros Islet Quarter",
    country_code: "LC",
    lat: 14.0843578,
    lon: -60.9452794,
  },
  {
    art_state_id: "ASTAF51E5FD3B",
    state_code: "04",
    name: "Dauphin Quarter",
    country_code: "LC",
    lat: 14.0103396,
    lon: -60.9190988,
  },
  {
    art_state_id: "AST211486F0B7",
    state_code: "66",
    name: "Hưng Yên",
    country_code: "VN",
    lat: 20.8525711,
    lon: 106.0169971,
  },
  {
    art_state_id: "ASTD516C8E85B",
    state_code: "45",
    name: "Đồng Tháp",
    country_code: "VN",
    lat: 10.4937989,
    lon: 105.6881788,
  },
  {
    art_state_id: "ASTF2085936E3",
    state_code: "43",
    name: "Bà Rịa-Vũng Tàu",
    country_code: "VN",
    lat: 10.5417397,
    lon: 107.2429976,
  },
  {
    art_state_id: "AST21C77407C4",
    state_code: "21",
    name: "Thanh Hóa",
    country_code: "VN",
    lat: 19.806692,
    lon: 105.7851816,
  },
  {
    art_state_id: "AST04F1B99058",
    state_code: "28",
    name: "Kon Tum",
    country_code: "VN",
    lat: 14.3497403,
    lon: 108.0004606,
  },
  {
    art_state_id: "ASTEBC155C9CA",
    state_code: "71",
    name: "Điện Biên",
    country_code: "VN",
    lat: 21.8042309,
    lon: 103.1076525,
  },
  {
    art_state_id: "AST117B26EBB0",
    state_code: "70",
    name: "Vĩnh Phúc",
    country_code: "VN",
    lat: 21.3608805,
    lon: 105.5474373,
  },
  {
    art_state_id: "ASTEB7CDD963E",
    state_code: "20",
    name: "Thái Bình",
    country_code: "VN",
    lat: 20.4463471,
    lon: 106.3365828,
  },
  {
    art_state_id: "ASTCD08F1CC76",
    state_code: "27",
    name: "Quảng Nam",
    country_code: "VN",
    lat: 15.5393538,
    lon: 108.019102,
  },
  {
    art_state_id: "ASTEDB8540A73",
    state_code: "73",
    name: "Hậu Giang",
    country_code: "VN",
    lat: 9.757898,
    lon: 105.6412527,
  },
  {
    art_state_id: "ASTC0C10841DB",
    state_code: "59",
    name: "Cà Mau",
    country_code: "VN",
    lat: 9.1526728,
    lon: 105.1960795,
  },
  {
    art_state_id: "AST15C04A018A",
    state_code: "03",
    name: "Hà Giang",
    country_code: "VN",
    lat: 22.8025588,
    lon: 104.9784494,
  },
  {
    art_state_id: "AST65937FB14F",
    state_code: "22",
    name: "Nghệ An",
    country_code: "VN",
    lat: 19.2342489,
    lon: 104.9200365,
  },
  {
    art_state_id: "AST22E5B921B0",
    state_code: "46",
    name: "Tiền Giang",
    country_code: "VN",
    lat: 10.4493324,
    lon: 106.3420504,
  },
  {
    art_state_id: "AST66092BBE34",
    state_code: "04",
    name: "Cao Bằng",
    country_code: "VN",
    lat: 22.635689,
    lon: 106.2522143,
  },
  {
    art_state_id: "AST0F00BD2AF1",
    state_code: "HP",
    name: "Haiphong",
    country_code: "VN",
    lat: 20.8449115,
    lon: 106.6880841,
  },
  {
    art_state_id: "AST78E35BC39E",
    state_code: "06",
    name: "Yên Bái",
    country_code: "VN",
    lat: 21.7167689,
    lon: 104.8985878,
  },
  {
    art_state_id: "AST0B1B148EF1",
    state_code: "57",
    name: "Bình Dương",
    country_code: "VN",
    lat: 11.3254024,
    lon: 106.477017,
  },
  {
    art_state_id: "AST8D063B09E4",
    state_code: "18",
    name: "Ninh Bình",
    country_code: "VN",
    lat: 20.2506149,
    lon: 105.9744536,
  },
  {
    art_state_id: "ASTC21DC05E4E",
    state_code: "40",
    name: "Bình Thuận",
    country_code: "VN",
    lat: 11.0903703,
    lon: 108.0720781,
  },
  {
    art_state_id: "ASTF39AC271BA",
    state_code: "36",
    name: "Ninh Thuận",
    country_code: "VN",
    lat: 11.6738767,
    lon: 108.8629572,
  },
  {
    art_state_id: "AST0186955B69",
    state_code: "67",
    name: "Nam Định",
    country_code: "VN",
    lat: 20.4388225,
    lon: 106.1621053,
  },
  {
    art_state_id: "AST44A4A27E1F",
    state_code: "49",
    name: "Vĩnh Long",
    country_code: "VN",
    lat: 10.239574,
    lon: 105.9571928,
  },
  {
    art_state_id: "ASTCBEB904AE1",
    state_code: "56",
    name: "Bắc Ninh",
    country_code: "VN",
    lat: 21.121444,
    lon: 106.1110501,
  },
  {
    art_state_id: "AST5D0834A18A",
    state_code: "09",
    name: "Lạng Sơn",
    country_code: "VN",
    lat: 21.853708,
    lon: 106.761519,
  },
  {
    art_state_id: "ASTA6A0598A85",
    state_code: "34",
    name: "Khánh Hòa",
    country_code: "VN",
    lat: 12.2585098,
    lon: 109.0526076,
  },
  {
    art_state_id: "AST0D2076CE28",
    state_code: "44",
    name: "An Giang",
    country_code: "VN",
    lat: 10.5215836,
    lon: 105.1258955,
  },
  {
    art_state_id: "AST7FC60C4372",
    state_code: "07",
    name: "Tuyên Quang",
    country_code: "VN",
    lat: 21.7767246,
    lon: 105.2280196,
  },
  {
    art_state_id: "AST9CDE1AF37A",
    state_code: "50",
    name: "Bến Tre",
    country_code: "VN",
    lat: 10.2433556,
    lon: 106.375551,
  },
  {
    art_state_id: "AST6C083D2841",
    state_code: "58",
    name: "Bình Phước",
    country_code: "VN",
    lat: 11.7511894,
    lon: 106.7234639,
  },
  {
    art_state_id: "AST39E043AF72",
    state_code: "26",
    name: "Thừa Thiên-Huế",
    country_code: "VN",
    lat: 16.467397,
    lon: 107.5905326,
  },
  {
    art_state_id: "ASTBCF257D71F",
    state_code: "14",
    name: "Hòa Bình",
    country_code: "VN",
    lat: 20.6861265,
    lon: 105.3131185,
  },
  {
    art_state_id: "ASTF120341F18",
    state_code: "47",
    name: "Kiên Giang",
    country_code: "VN",
    lat: 9.8249587,
    lon: 105.1258955,
  },
  {
    art_state_id: "AST6C1FD20CD3",
    state_code: "68",
    name: "Phú Thọ",
    country_code: "VN",
    lat: 21.268443,
    lon: 105.2045573,
  },
  {
    art_state_id: "AST2EFDF644F1",
    state_code: "63",
    name: "Hà Nam",
    country_code: "VN",
    lat: 20.5835196,
    lon: 105.92299,
  },
  {
    art_state_id: "AST876E5593F3",
    state_code: "25",
    name: "Quảng Trị",
    country_code: "VN",
    lat: 16.7403074,
    lon: 107.1854679,
  },
  {
    art_state_id: "AST4BBBF7F336",
    state_code: "55",
    name: "Bạc Liêu",
    country_code: "VN",
    lat: 9.2940027,
    lon: 105.7215663,
  },
  {
    art_state_id: "AST223AFEFB07",
    state_code: "51",
    name: "Trà Vinh",
    country_code: "VN",
    lat: 9.812741,
    lon: 106.2992912,
  },
  {
    art_state_id: "ASTB06789179D",
    state_code: "DN",
    name: "Da Nang",
    country_code: "VN",
    lat: 16.0544068,
    lon: 108.2021667,
  },
  {
    art_state_id: "ASTDF30058452",
    state_code: "69",
    name: "Thái Nguyên",
    country_code: "VN",
    lat: 21.5671559,
    lon: 105.8252038,
  },
  {
    art_state_id: "ASTB51064282F",
    state_code: "41",
    name: "Long An",
    country_code: "VN",
    lat: 10.5607168,
    lon: 106.6497623,
  },
  {
    art_state_id: "AST78C8328B96",
    state_code: "24",
    name: "Quảng Bình",
    country_code: "VN",
    lat: 17.6102715,
    lon: 106.3487474,
  },
  {
    art_state_id: "ASTB6FAB8D4BA",
    state_code: "HN",
    name: "Hanoi",
    country_code: "VN",
    lat: 21.0277644,
    lon: 105.8341598,
  },
  {
    art_state_id: "AST46C169DABC",
    state_code: "SG",
    name: "Ho Chi Minh City",
    country_code: "VN",
    lat: 10.8230989,
    lon: 106.6296638,
  },
  {
    art_state_id: "ASTC1C776CB4D",
    state_code: "05",
    name: "Sơn La",
    country_code: "VN",
    lat: 21.1022284,
    lon: 103.7289167,
  },
  {
    art_state_id: "AST2B0BE66228",
    state_code: "30",
    name: "Gia Lai",
    country_code: "VN",
    lat: 13.8078943,
    lon: 108.109375,
  },
  {
    art_state_id: "AST0FF0CE03EC",
    state_code: "13",
    name: "Quảng Ninh",
    country_code: "VN",
    lat: 21.006382,
    lon: 107.2925144,
  },
  {
    art_state_id: "ASTF753368E5F",
    state_code: "54",
    name: "Bắc Giang",
    country_code: "VN",
    lat: 21.2819921,
    lon: 106.1974769,
  },
  {
    art_state_id: "AST5A36780FD2",
    state_code: "23",
    name: "Hà Tĩnh",
    country_code: "VN",
    lat: 18.3559537,
    lon: 105.8877494,
  },
  {
    art_state_id: "AST6D819F59AE",
    state_code: "02",
    name: "Lào Cai",
    country_code: "VN",
    lat: 22.4809431,
    lon: 103.9754959,
  },
  {
    art_state_id: "ASTBBD64428F5",
    state_code: "35",
    name: "Lâm Đồng",
    country_code: "VN",
    lat: 11.5752791,
    lon: 108.1428669,
  },
  {
    art_state_id: "AST6BDC636CD0",
    state_code: "52",
    name: "Sóc Trăng",
    country_code: "VN",
    lat: 9.602521,
    lon: 105.9739049,
  },
  {
    art_state_id: "AST7A3862E6AE",
    state_code: "15",
    name: "Hà Tây",
    country_code: "VN",
    lat: 39.109563,
    lon: 117.223371,
  },
  {
    art_state_id: "ASTA5380EF6A3",
    state_code: "39",
    name: "Đồng Nai",
    country_code: "VN",
    lat: 11.0686305,
    lon: 107.1675976,
  },
  {
    art_state_id: "AST75A8081EE2",
    state_code: "53",
    name: "Bắc Kạn",
    country_code: "VN",
    lat: 22.3032923,
    lon: 105.876004,
  },
  {
    art_state_id: "ASTEEC133306C",
    state_code: "72",
    name: "Đắk Nông",
    country_code: "VN",
    lat: 12.2646476,
    lon: 107.609806,
  },
  {
    art_state_id: "ASTC7C4A51CCF",
    state_code: "32",
    name: "Phú Yên",
    country_code: "VN",
    lat: 13.0881861,
    lon: 109.0928764,
  },
  {
    art_state_id: "AST8FAC2B8F2F",
    state_code: "01",
    name: "Lai Châu",
    country_code: "VN",
    lat: 22.3862227,
    lon: 103.4702631,
  },
  {
    art_state_id: "ASTC083129536",
    state_code: "37",
    name: "Tây Ninh",
    country_code: "VN",
    lat: 11.3351554,
    lon: 106.1098854,
  },
  {
    art_state_id: "ASTFF6253C848",
    state_code: "61",
    name: "Hải Dương",
    country_code: "VN",
    lat: 20.9373413,
    lon: 106.3145542,
  },
  {
    art_state_id: "ASTC1200A1CEE",
    state_code: "29",
    name: "Quảng Ngãi",
    country_code: "VN",
    lat: 15.1213873,
    lon: 108.8044145,
  },
  {
    art_state_id: "AST9669790B0B",
    state_code: "33",
    name: "Đắk Lắk",
    country_code: "VN",
    lat: 12.7100116,
    lon: 108.2377519,
  },
  {
    art_state_id: "AST6D8F6CDB44",
    state_code: "31",
    name: "Bình Định",
    country_code: "VN",
    lat: 14.1665324,
    lon: 108.902683,
  },
  {
    art_state_id: "ASTA17D7E2255",
    state_code: "11",
    name: "Saint Peter Basseterre Parish",
    country_code: "KN",
    lat: 17.3102911,
    lon: -62.7147533,
  },
  {
    art_state_id: "ASTE40B3991AB",
    state_code: "N",
    name: "Nevis",
    country_code: "KN",
    lat: 17.1553558,
    lon: -62.5796026,
  },
  {
    art_state_id: "AST960A0D3AD0",
    state_code: "01",
    name: "Christ Church Nichola Town Parish",
    country_code: "KN",
    lat: 17.3604812,
    lon: -62.7617837,
  },
  {
    art_state_id: "ASTE8DC70FD1B",
    state_code: "09",
    name: "Saint Paul Capisterre Parish",
    country_code: "KN",
    lat: 17.4016683,
    lon: -62.8257332,
  },
  {
    art_state_id: "ASTDC0F9F176D",
    state_code: "05",
    name: "Saint James Windward Parish",
    country_code: "KN",
    lat: 17.1769633,
    lon: -62.5796026,
  },
  {
    art_state_id: "AST48F6CD690D",
    state_code: "02",
    name: "Saint Anne Sandy Point Parish",
    country_code: "KN",
    lat: 17.3725333,
    lon: -62.8441133,
  },
  {
    art_state_id: "AST2FD8025A64",
    state_code: "04",
    name: "Saint George Gingerland Parish",
    country_code: "KN",
    lat: 17.1257759,
    lon: -62.5619811,
  },
  {
    art_state_id: "AST32B4E45E4C",
    state_code: "10",
    name: "Saint Paul Charlestown Parish",
    country_code: "KN",
    lat: 17.1346297,
    lon: -62.6133816,
  },
  {
    art_state_id: "AST1F5AF5DAB1",
    state_code: "12",
    name: "Saint Thomas Lowland Parish",
    country_code: "KN",
    lat: 17.1650513,
    lon: -62.6089753,
  },
  {
    art_state_id: "AST2064E8BDC2",
    state_code: "07",
    name: "Saint John Figtree Parish",
    country_code: "KN",
    lat: 17.1155748,
    lon: -62.6031004,
  },
  {
    art_state_id: "AST966F852CE2",
    state_code: "K",
    name: "Saint Kitts",
    country_code: "KN",
    lat: 17.3433796,
    lon: -62.7559043,
  },
  {
    art_state_id: "ASTDC96E6DA43",
    state_code: "13",
    name: "Saint Thomas Middle Island Parish",
    country_code: "KN",
    lat: 17.3348813,
    lon: -62.8088251,
  },
  {
    art_state_id: "ASTDC3139E6FD",
    state_code: "15",
    name: "Trinity Palmetto Point Parish",
    country_code: "KN",
    lat: 17.3063519,
    lon: -62.7617837,
  },
  {
    art_state_id: "AST05AF4C9971",
    state_code: "08",
    name: "Saint Mary Cayon Parish",
    country_code: "KN",
    lat: 17.3462071,
    lon: -62.7382671,
  },
  {
    art_state_id: "ASTC741A0BEED",
    state_code: "06",
    name: "Saint John Capisterre Parish",
    country_code: "KN",
    lat: 17.3810341,
    lon: -62.7911833,
  },
  {
    art_state_id: "ASTB7EE59E5B3",
    state_code: "27",
    name: "Daegu",
    country_code: "KR",
    lat: 35.8714354,
    lon: 128.601445,
  },
  {
    art_state_id: "AST2E865CEA86",
    state_code: "41",
    name: "Gyeonggi Province",
    country_code: "KR",
    lat: 37.4138,
    lon: 127.5183,
  },
  {
    art_state_id: "AST71EF021193",
    state_code: "28",
    name: "Incheon",
    country_code: "KR",
    lat: 37.4562557,
    lon: 126.7052062,
  },
  {
    art_state_id: "ASTE13C721CD0",
    state_code: "11",
    name: "Seoul",
    country_code: "KR",
    lat: 37.566535,
    lon: 126.9779692,
  },
  {
    art_state_id: "ASTC5B6CB4202",
    state_code: "30",
    name: "Daejeon",
    country_code: "KR",
    lat: 36.3504119,
    lon: 127.3845475,
  },
  {
    art_state_id: "AST9002EDF5AD",
    state_code: "45",
    name: "North Jeolla Province",
    country_code: "KR",
    lat: 35.7175,
    lon: 127.153,
  },
  {
    art_state_id: "AST45102CC99B",
    state_code: "31",
    name: "Ulsan",
    country_code: "KR",
    lat: 35.5383773,
    lon: 129.3113596,
  },
  {
    art_state_id: "AST63B5953B68",
    state_code: "49",
    name: "Jeju",
    country_code: "KR",
    lat: 33.9568278,
    lon: -84.13135,
  },
  {
    art_state_id: "AST147C6638C7",
    state_code: "43",
    name: "North Chungcheong Province",
    country_code: "KR",
    lat: 36.8,
    lon: 127.7,
  },
  {
    art_state_id: "AST6204996FF0",
    state_code: "47",
    name: "North Gyeongsang Province",
    country_code: "KR",
    lat: 36.4919,
    lon: 128.8889,
  },
  {
    art_state_id: "AST3C6F76DBA1",
    state_code: "46",
    name: "South Jeolla Province",
    country_code: "KR",
    lat: 34.8679,
    lon: 126.991,
  },
  {
    art_state_id: "AST67D24006D2",
    state_code: "48",
    name: "South Gyeongsang Province",
    country_code: "KR",
    lat: 35.4606,
    lon: 128.2132,
  },
  {
    art_state_id: "AST3294029080",
    state_code: "29",
    name: "Gwangju",
    country_code: "KR",
    lat: 35.1595454,
    lon: 126.8526012,
  },
  {
    art_state_id: "ASTDD2DEFDE7C",
    state_code: "44",
    name: "South Chungcheong Province",
    country_code: "KR",
    lat: 36.5184,
    lon: 126.8,
  },
  {
    art_state_id: "ASTF008BE0D2F",
    state_code: "26",
    name: "Busan",
    country_code: "KR",
    lat: 35.1795543,
    lon: 129.0756416,
  },
  {
    art_state_id: "AST6024C80CBE",
    state_code: "50",
    name: "Sejong City",
    country_code: "KR",
    lat: 34.0523323,
    lon: -118.3084897,
  },
  {
    art_state_id: "ASTCCE497BB70",
    state_code: "42",
    name: "Gangwon Province",
    country_code: "KR",
    lat: 37.8228,
    lon: 128.1555,
  },
  {
    art_state_id: "ASTE438B71C07",
    state_code: "06",
    name: "Saint Patrick Parish",
    country_code: "GD",
    lat: 12.1360374,
    lon: -61.6904045,
  },
  {
    art_state_id: "ASTEB04296A5B",
    state_code: "03",
    name: "Saint George Parish",
    country_code: "GD",
    lat: 12.1360374,
    lon: -61.6904045,
  },
  {
    art_state_id: "ASTF1CEB09970",
    state_code: "01",
    name: "Saint Andrew Parish",
    country_code: "GD",
    lat: 12.1360374,
    lon: -61.6904045,
  },
  {
    art_state_id: "AST71667D2E3E",
    state_code: "05",
    name: "Saint Mark Parish",
    country_code: "GD",
    lat: 40.5881863,
    lon: -73.9495701,
  },
  {
    art_state_id: "AST9FB15713C8",
    state_code: "10",
    name: "Carriacou and Petite Martinique",
    country_code: "GD",
    lat: 12.4785888,
    lon: -61.4493842,
  },
  {
    art_state_id: "ASTE212046877",
    state_code: "04",
    name: "Saint John Parish",
    country_code: "GD",
    lat: 30.1118331,
    lon: -90.4879916,
  },
  {
    art_state_id: "ASTB4844BC74C",
    state_code: "02",
    name: "Saint David Parish",
    country_code: "GD",
    lat: 12.1360374,
    lon: -61.6904045,
  },
  {
    art_state_id: "ASTE14704A6BD",
    state_code: "GHA",
    name: "Ghazni",
    country_code: "AF",
    lat: 33.5450587,
    lon: 68.4173972,
  },
  {
    art_state_id: "ASTC4400BC8CE",
    state_code: "BDG",
    name: "Badghis",
    country_code: "AF",
    lat: 35.1671339,
    lon: 63.7695384,
  },
  {
    art_state_id: "AST559F2EA7B9",
    state_code: "BAM",
    name: "Bamyan",
    country_code: "AF",
    lat: 34.8100067,
    lon: 67.8212104,
  },
  {
    art_state_id: "AST5385447D2A",
    state_code: "HEL",
    name: "Helmand",
    country_code: "AF",
    lat: 39.2989361,
    lon: -76.6160472,
  },
  {
    art_state_id: "ASTAE8B325041",
    state_code: "ZAB",
    name: "Zabul",
    country_code: "AF",
    lat: 32.1918782,
    lon: 67.1894488,
  },
  {
    art_state_id: "AST6767B5ED43",
    state_code: "BGL",
    name: "Baghlan",
    country_code: "AF",
    lat: 36.1789026,
    lon: 68.7453064,
  },
  {
    art_state_id: "AST531D8BFD66",
    state_code: "KNR",
    name: "Kunar",
    country_code: "AF",
    lat: 34.8465893,
    lon: 71.097317,
  },
  {
    art_state_id: "AST2C2C81A08E",
    state_code: "PKA",
    name: "Paktika",
    country_code: "AF",
    lat: 32.2645386,
    lon: 68.5247149,
  },
  {
    art_state_id: "ASTB01FCC7AC6",
    state_code: "KHO",
    name: "Khost",
    country_code: "AF",
    lat: 33.3338472,
    lon: 69.9371673,
  },
  {
    art_state_id: "ASTAEF0A675B4",
    state_code: "KAP",
    name: "Kapisa",
    country_code: "AF",
    lat: 34.9810572,
    lon: 69.6214562,
  },
  {
    art_state_id: "AST84805092BD",
    state_code: "NUR",
    name: "Nuristan",
    country_code: "AF",
    lat: 35.3250223,
    lon: 70.9071236,
  },
  {
    art_state_id: "AST331301CE71",
    state_code: "PAN",
    name: "Panjshir",
    country_code: "AF",
    lat: 38.8802391,
    lon: -77.1717238,
  },
  {
    art_state_id: "ASTE220207BCE",
    state_code: "NAN",
    name: "Nangarhar",
    country_code: "AF",
    lat: 34.1718313,
    lon: 70.6216794,
  },
  {
    art_state_id: "AST03A5A8C7BD",
    state_code: "SAM",
    name: "Samangan",
    country_code: "AF",
    lat: 36.3155506,
    lon: 67.9642863,
  },
  {
    art_state_id: "AST3B41DD3FAB",
    state_code: "BAL",
    name: "Balkh",
    country_code: "AF",
    lat: 36.7550603,
    lon: 66.8975372,
  },
  {
    art_state_id: "ASTAC6834B61B",
    state_code: "SAR",
    name: "Sar-e Pol",
    country_code: "AF",
    lat: 36.216628,
    lon: 65.93336,
  },
  {
    art_state_id: "ASTA689EE4E7B",
    state_code: "JOW",
    name: "Jowzjan",
    country_code: "AF",
    lat: 36.8969692,
    lon: 65.6658568,
  },
  {
    art_state_id: "ASTCB91CC655D",
    state_code: "HER",
    name: "Herat",
    country_code: "AF",
    lat: 34.352865,
    lon: 62.2040287,
  },
  {
    art_state_id: "AST044C9D47FC",
    state_code: "GHO",
    name: "Ghōr",
    country_code: "AF",
    lat: 34.0995776,
    lon: 64.905955,
  },
  {
    art_state_id: "AST0F7424C7A4",
    state_code: "FYB",
    name: "Faryab",
    country_code: "AF",
    lat: 36.0795613,
    lon: 64.905955,
  },
  {
    art_state_id: "ASTA88FDC940E",
    state_code: "KAN",
    name: "Kandahar",
    country_code: "AF",
    lat: 31.628871,
    lon: 65.7371749,
  },
  {
    art_state_id: "AST54399F7C7D",
    state_code: "LAG",
    name: "Laghman",
    country_code: "AF",
    lat: 34.6897687,
    lon: 70.1455805,
  },
  {
    art_state_id: "ASTE2500E82FC",
    state_code: "DAY",
    name: "Daykundi",
    country_code: "AF",
    lat: 33.669495,
    lon: 66.0463534,
  },
  {
    art_state_id: "AST05C6C80217",
    state_code: "TAK",
    name: "Takhar",
    country_code: "AF",
    lat: 36.6698013,
    lon: 69.4784541,
  },
  {
    art_state_id: "AST5C7E4EC404",
    state_code: "PIA",
    name: "Paktia",
    country_code: "AF",
    lat: 33.706199,
    lon: 69.3831079,
  },
  {
    art_state_id: "ASTAFA0E74D57",
    state_code: "PAR",
    name: "Parwan",
    country_code: "AF",
    lat: 34.9630977,
    lon: 68.8108849,
  },
  {
    art_state_id: "AST46371C40EF",
    state_code: "NIM",
    name: "Nimruz",
    country_code: "AF",
    lat: 31.0261488,
    lon: 62.4504154,
  },
  {
    art_state_id: "AST6DF7B6DD9E",
    state_code: "LOG",
    name: "Logar",
    country_code: "AF",
    lat: 34.0145518,
    lon: 69.1923916,
  },
  {
    art_state_id: "ASTD9EF9BA4B5",
    state_code: "URU",
    name: "Urozgan",
    country_code: "AF",
    lat: 32.9271287,
    lon: 66.1415263,
  },
  {
    art_state_id: "ASTF7E585C585",
    state_code: "FRA",
    name: "Farah",
    country_code: "AF",
    lat: 32.495328,
    lon: 62.2626627,
  },
  {
    art_state_id: "AST0DD34CAB46",
    state_code: "KDZ",
    name: "Kunduz Province",
    country_code: "AF",
    lat: 36.7285511,
    lon: 68.8678982,
  },
  {
    art_state_id: "AST123F83AF07",
    state_code: "BDS",
    name: "Badakhshan",
    country_code: "AF",
    lat: 36.7347725,
    lon: 70.8119953,
  },
  {
    art_state_id: "AST8FDF231888",
    state_code: "KAB",
    name: "Kabul",
    country_code: "AF",
    lat: 34.5553494,
    lon: 69.207486,
  },
  {
    art_state_id: "AST241590531D",
    state_code: "VIC",
    name: "Victoria",
    country_code: "AU",
    lat: -36.5986096,
    lon: 144.6780052,
  },
  {
    art_state_id: "ASTC2984BB31E",
    state_code: "SA",
    name: "South Australia",
    country_code: "AU",
    lat: -30.0002315,
    lon: 136.2091547,
  },
  {
    art_state_id: "AST6C3846BBF1",
    state_code: "QLD",
    name: "Queensland",
    country_code: "AU",
    lat: -20.9175738,
    lon: 142.7027956,
  },
  {
    art_state_id: "AST0077B2DB12",
    state_code: "WA",
    name: "Western Australia",
    country_code: "AU",
    lat: -27.6728168,
    lon: 121.6283098,
  },
  {
    art_state_id: "AST33546440CB",
    state_code: "ACT",
    name: "Australian Capital Territory",
    country_code: "AU",
    lat: -35.4734679,
    lon: 149.0123679,
  },
  {
    art_state_id: "ASTD04A3BD979",
    state_code: "TAS",
    name: "Tasmania",
    country_code: "AU",
    lat: -41.4545196,
    lon: 145.9706647,
  },
  {
    art_state_id: "AST8D7B8F7964",
    state_code: "NSW",
    name: "New South Wales",
    country_code: "AU",
    lat: -31.2532183,
    lon: 146.921099,
  },
  {
    art_state_id: "ASTE9AC750D5B",
    state_code: "NT",
    name: "Northern Territory",
    country_code: "AU",
    lat: -19.4914108,
    lon: 132.5509603,
  },
  {
    art_state_id: "AST7C36480FB9",
    state_code: "05",
    name: "Vavaʻu",
    country_code: "TO",
    lat: -18.622756,
    lon: -173.9902982,
  },
  {
    art_state_id: "ASTE84F5A7129",
    state_code: "04",
    name: "Tongatapu",
    country_code: "TO",
    lat: -21.1465968,
    lon: -175.2515482,
  },
  {
    art_state_id: "AST5E9C654C16",
    state_code: "02",
    name: "Haʻapai",
    country_code: "TO",
    lat: -19.75,
    lon: -174.366667,
  },
  {
    art_state_id: "ASTC8A054D307",
    state_code: "03",
    name: "Niuas",
    country_code: "TO",
    lat: -15.9594,
    lon: -173.783,
  },
  {
    art_state_id: "AST926E5EC696",
    state_code: "01",
    name: "ʻEua",
    country_code: "TO",
    lat: 37.09024,
    lon: -95.712891,
  },
  {
    art_state_id: "AST9E0E2FD6AA",
    state_code: "00",
    name: "Markazi Province",
    country_code: "IR",
    lat: 34.612305,
    lon: 49.8547266,
  },
  {
    art_state_id: "AST4A5F27715B",
    state_code: "06",
    name: "Khuzestan Province",
    country_code: "IR",
    lat: 31.4360149,
    lon: 49.041312,
  },
  {
    art_state_id: "ASTE0F1FF1CC6",
    state_code: "16",
    name: "Ilam Province",
    country_code: "IR",
    lat: 33.2957618,
    lon: 46.670534,
  },
  {
    art_state_id: "AST72CEAF89FA",
    state_code: "05",
    name: "Kermanshah Province",
    country_code: "IR",
    lat: 34.4576233,
    lon: 46.670534,
  },
  {
    art_state_id: "AST8C5DE6F390",
    state_code: "01",
    name: "Gilan Province",
    country_code: "IR",
    lat: 37.2809455,
    lon: 49.5924134,
  },
  {
    art_state_id: "ASTAAA149D24D",
    state_code: "14",
    name: "Chaharmahal and Bakhtiari Province",
    country_code: "IR",
    lat: 31.9970419,
    lon: 50.6613849,
  },
  {
    art_state_id: "AST1E86AE1AFE",
    state_code: "25",
    name: "Qom Province",
    country_code: "IR",
    lat: 34.6415764,
    lon: 50.8746035,
  },
  {
    art_state_id: "AST80FDED07DB",
    state_code: "10",
    name: "Isfahan Province",
    country_code: "IR",
    lat: 33.2771073,
    lon: 52.3613378,
  },
  {
    art_state_id: "AST397416C567",
    state_code: "04",
    name: "West Azarbaijan Province",
    country_code: "IR",
    lat: 37.4550062,
    lon: 45,
  },
  {
    art_state_id: "ASTADDCCA1D94",
    state_code: "19",
    name: "Zanjan Province",
    country_code: "IR",
    lat: 36.5018185,
    lon: 48.3988186,
  },
  {
    art_state_id: "ASTC97CE6DF8B",
    state_code: "17",
    name: "Kohgiluyeh and Boyer-Ahmad Province",
    country_code: "IR",
    lat: 30.724586,
    lon: 50.8456323,
  },
  {
    art_state_id: "AST7ECDFA3ACA",
    state_code: "09",
    name: "Razavi Khorasan Province",
    country_code: "IR",
    lat: 35.1020253,
    lon: 59.1041758,
  },
  {
    art_state_id: "AST4C5AD3D33F",
    state_code: "15",
    name: "Lorestan Province",
    country_code: "IR",
    lat: 33.5818394,
    lon: 48.3988186,
  },
  {
    art_state_id: "AST49F6B670E3",
    state_code: "30",
    name: "Alborz Province",
    country_code: "IR",
    lat: 35.9960467,
    lon: 50.9289246,
  },
  {
    art_state_id: "ASTCC3D3B85F7",
    state_code: "29",
    name: "South Khorasan Province",
    country_code: "IR",
    lat: 32.5175643,
    lon: 59.1041758,
  },
  {
    art_state_id: "AST8F5EF42081",
    state_code: "11",
    name: "Sistan and Baluchestan",
    country_code: "IR",
    lat: 27.5299906,
    lon: 60.5820676,
  },
  {
    art_state_id: "ASTEDE3209BD9",
    state_code: "18",
    name: "Bushehr Province",
    country_code: "IR",
    lat: 28.7620739,
    lon: 51.5150077,
  },
  {
    art_state_id: "ASTA64E3FF351",
    state_code: "27",
    name: "Golestan Province",
    country_code: "IR",
    lat: 37.2898123,
    lon: 55.1375834,
  },
  {
    art_state_id: "ASTFFF7267BA9",
    state_code: "24",
    name: "Ardabil Province",
    country_code: "IR",
    lat: 38.4853276,
    lon: 47.8911209,
  },
  {
    art_state_id: "ASTCF1BD4EA70",
    state_code: "12",
    name: "Kurdistan Province",
    country_code: "IR",
    lat: 35.9553579,
    lon: 47.1362125,
  },
  {
    art_state_id: "AST1459E4AF1D",
    state_code: "21",
    name: "Yazd Province",
    country_code: "IR",
    lat: 32.1006387,
    lon: 54.4342138,
  },
  {
    art_state_id: "ASTC70666F172",
    state_code: "22",
    name: "Hormozgan Province",
    country_code: "IR",
    lat: 27.138723,
    lon: 55.1375834,
  },
  {
    art_state_id: "AST86B4656561",
    state_code: "02",
    name: "Mazandaran Province",
    country_code: "IR",
    lat: 36.2262393,
    lon: 52.5318604,
  },
  {
    art_state_id: "ASTD1A549721D",
    state_code: "07",
    name: "Fars Province",
    country_code: "IR",
    lat: 29.1043813,
    lon: 53.045893,
  },
  {
    art_state_id: "AST47003F0BB7",
    state_code: "20",
    name: "Semnan Province",
    country_code: "IR",
    lat: 35.2255585,
    lon: 54.4342138,
  },
  {
    art_state_id: "AST4ED6525E84",
    state_code: "26",
    name: "Qazvin Province",
    country_code: "IR",
    lat: 36.0881317,
    lon: 49.8547266,
  },
  {
    art_state_id: "ASTD6BC25E8BE",
    state_code: "28",
    name: "North Khorasan Province",
    country_code: "IR",
    lat: 37.4710353,
    lon: 57.1013188,
  },
  {
    art_state_id: "AST33AA09C3D7",
    state_code: "08",
    name: "Kerman Province",
    country_code: "IR",
    lat: 29.4850089,
    lon: 57.6439048,
  },
  {
    art_state_id: "ASTF6881E9C25",
    state_code: "03",
    name: "East Azerbaijan Province",
    country_code: "IR",
    lat: 37.9035733,
    lon: 46.2682109,
  },
  {
    art_state_id: "ASTD1C5D497F4",
    state_code: "23",
    name: "Tehran Province",
    country_code: "IR",
    lat: 35.7248416,
    lon: 51.381653,
  },
  {
    art_state_id: "ASTC217C986F3",
    state_code: "NIT",
    name: "Niutao Island Council",
    country_code: "TV",
    lat: -6.1064258,
    lon: 177.3438429,
  },
  {
    art_state_id: "ASTAF273460A5",
    state_code: "NMG",
    name: "Nanumanga",
    country_code: "TV",
    lat: -6.2858019,
    lon: 176.319928,
  },
  {
    art_state_id: "AST73FC67B07C",
    state_code: "NUI",
    name: "Nui",
    country_code: "TV",
    lat: -7.2388768,
    lon: 177.1485232,
  },
  {
    art_state_id: "AST783AF27AC8",
    state_code: "NMA",
    name: "Nanumea",
    country_code: "TV",
    lat: -5.6881617,
    lon: 176.1370148,
  },
  {
    art_state_id: "ASTA126A14240",
    state_code: "VAI",
    name: "Vaitupu",
    country_code: "TV",
    lat: -7.4767327,
    lon: 178.6747675,
  },
  {
    art_state_id: "ASTC437BB1E8B",
    state_code: "FUN",
    name: "Funafuti",
    country_code: "TV",
    lat: -8.5211471,
    lon: 179.1961926,
  },
  {
    art_state_id: "AST487D43D22C",
    state_code: "NKF",
    name: "Nukufetau",
    country_code: "TV",
    lat: -8,
    lon: 178.5,
  },
  {
    art_state_id: "AST629A6F5532",
    state_code: "NKL",
    name: "Nukulaelae",
    country_code: "TV",
    lat: -9.381111,
    lon: 179.852222,
  },
  {
    art_state_id: "AST639B0D0084",
    state_code: "DQ",
    name: "Dhi Qar Governorate",
    country_code: "IQ",
    lat: 31.1042292,
    lon: 46.3624686,
  },
  {
    art_state_id: "AST039DE9DEDC",
    state_code: "BB",
    name: "Babylon Governorate",
    country_code: "IQ",
    lat: 32.468191,
    lon: 44.5501935,
  },
  {
    art_state_id: "AST617BCB2C9A",
    state_code: "QA",
    name: "Al-Qādisiyyah Governorate",
    country_code: "IQ",
    lat: 32.043691,
    lon: 45.1494505,
  },
  {
    art_state_id: "AST5D1FE90C13",
    state_code: "KA",
    name: "Karbala Governorate",
    country_code: "IQ",
    lat: 32.4045493,
    lon: 43.8673222,
  },
  {
    art_state_id: "AST90EC997EB2",
    state_code: "MU",
    name: "Al Muthanna Governorate",
    country_code: "IQ",
    lat: 29.9133171,
    lon: 45.2993862,
  },
  {
    art_state_id: "ASTC0BD01003E",
    state_code: "BG",
    name: "Baghdad Governorate",
    country_code: "IQ",
    lat: 33.3152618,
    lon: 44.3660653,
  },
  {
    art_state_id: "AST7B5C48917F",
    state_code: "BA",
    name: "Basra Governorate",
    country_code: "IQ",
    lat: 30.5114252,
    lon: 47.8296253,
  },
  {
    art_state_id: "ASTCA44529A7F",
    state_code: "SD",
    name: "Saladin Governorate",
    country_code: "IQ",
    lat: 34.5337527,
    lon: 43.483738,
  },
  {
    art_state_id: "AST606D46449A",
    state_code: "NA",
    name: "Najaf Governorate",
    country_code: "IQ",
    lat: 31.3517486,
    lon: 44.0960311,
  },
  {
    art_state_id: "ASTF6DF37E989",
    state_code: "NI",
    name: "Nineveh Governorate",
    country_code: "IQ",
    lat: 36.229574,
    lon: 42.2362435,
  },
  {
    art_state_id: "AST19811B180B",
    state_code: "AN",
    name: "Al Anbar Governorate",
    country_code: "IQ",
    lat: 32.5597614,
    lon: 41.9196471,
  },
  {
    art_state_id: "AST01837E6DAC",
    state_code: "DI",
    name: "Diyala Governorate",
    country_code: "IQ",
    lat: 33.7733487,
    lon: 45.1494505,
  },
  {
    art_state_id: "ASTD9E29DF541",
    state_code: "MA",
    name: "Maysan Governorate",
    country_code: "IQ",
    lat: 31.8734002,
    lon: 47.1362125,
  },
  {
    art_state_id: "ASTD6BC74F9AB",
    state_code: "DA",
    name: "Dohuk Governorate",
    country_code: "IQ",
    lat: 36.9077252,
    lon: 43.0631689,
  },
  {
    art_state_id: "AST0ECF4D67D1",
    state_code: "AR",
    name: "Erbil Governorate",
    country_code: "IQ",
    lat: 36.5570628,
    lon: 44.3851263,
  },
  {
    art_state_id: "AST3B7E638F97",
    state_code: "SU",
    name: "Sulaymaniyah Governorate",
    country_code: "IQ",
    lat: 35.5466348,
    lon: 45.3003683,
  },
  {
    art_state_id: "AST63EBC37D41",
    state_code: "WA",
    name: "Wasit Governorate",
    country_code: "IQ",
    lat: 32.6024094,
    lon: 45.7520985,
  },
  {
    art_state_id: "AST5BA1328843",
    state_code: "KI",
    name: "Kirkuk Governorate",
    country_code: "IQ",
    lat: 35.3292014,
    lon: 43.9436788,
  },
  {
    art_state_id: "AST0F44B5EEC3",
    state_code: "20",
    name: "Svay Rieng Province",
    country_code: "KH",
    lat: 11.142722,
    lon: 105.8290298,
  },
  {
    art_state_id: "AST5AA1E07F3B",
    state_code: "13",
    name: "Preah Vihear Province",
    country_code: "KH",
    lat: 14.0085797,
    lon: 104.8454619,
  },
  {
    art_state_id: "AST8735906A33",
    state_code: "14",
    name: "Prey Veng Province",
    country_code: "KH",
    lat: 11.3802442,
    lon: 105.5005483,
  },
  {
    art_state_id: "ASTAF6E1F8F98",
    state_code: "21",
    name: "Takéo Province",
    country_code: "KH",
    lat: 10.9321519,
    lon: 104.798771,
  },
  {
    art_state_id: "ASTC92538719D",
    state_code: "2",
    name: "Battambang Province",
    country_code: "KH",
    lat: 13.0286971,
    lon: 102.989615,
  },
  {
    art_state_id: "ASTDE677899E7",
    state_code: "15",
    name: "Pursat Province",
    country_code: "KH",
    lat: 12.2720956,
    lon: 103.7289167,
  },
  {
    art_state_id: "AST54A2FB5910",
    state_code: "23",
    name: "Kep Province",
    country_code: "KH",
    lat: 10.536089,
    lon: 104.3559158,
  },
  {
    art_state_id: "ASTCF76083DC7",
    state_code: "4",
    name: "Kampong Chhnang Province",
    country_code: "KH",
    lat: 12.1392352,
    lon: 104.5655273,
  },
  {
    art_state_id: "AST96DF95E36D",
    state_code: "24",
    name: "Pailin Province",
    country_code: "KH",
    lat: 12.9092962,
    lon: 102.6675575,
  },
  {
    art_state_id: "AST78E66625D3",
    state_code: "7",
    name: "Kampot Province",
    country_code: "KH",
    lat: 10.7325351,
    lon: 104.3791912,
  },
  {
    art_state_id: "ASTB014A6F849",
    state_code: "9",
    name: "Koh Kong Province",
    country_code: "KH",
    lat: 11.5762804,
    lon: 103.3587288,
  },
  {
    art_state_id: "AST6D1E65C00C",
    state_code: "8",
    name: "Kandal Province",
    country_code: "KH",
    lat: 11.2237383,
    lon: 105.1258955,
  },
  {
    art_state_id: "AST023DE5D59C",
    state_code: "1",
    name: "Banteay Meanchey Province",
    country_code: "KH",
    lat: 13.7531914,
    lon: 102.989615,
  },
  {
    art_state_id: "ASTACDA5C148C",
    state_code: "11",
    name: "Mondulkiri Province",
    country_code: "KH",
    lat: 12.7879427,
    lon: 107.1011931,
  },
  {
    art_state_id: "ASTE0E05DE93C",
    state_code: "10",
    name: "Kratié Province",
    country_code: "KH",
    lat: 12.5043608,
    lon: 105.9699878,
  },
  {
    art_state_id: "ASTA048F3B7F1",
    state_code: "22",
    name: "Oddar Meanchey Province",
    country_code: "KH",
    lat: 14.1609738,
    lon: 103.8216261,
  },
  {
    art_state_id: "AST25D971FC67",
    state_code: "5",
    name: "Kampong Speu Province",
    country_code: "KH",
    lat: 11.6155109,
    lon: 104.3791912,
  },
  {
    art_state_id: "AST8D2C043CA1",
    state_code: "18",
    name: "Sihanoukville Province",
    country_code: "KH",
    lat: 10.7581899,
    lon: 103.8216261,
  },
  {
    art_state_id: "AST0F1A2AB0F3",
    state_code: "16",
    name: "Ratanakiri Province",
    country_code: "KH",
    lat: 13.8576607,
    lon: 107.1011931,
  },
  {
    art_state_id: "AST6B06073084",
    state_code: "3",
    name: "Kampong Cham Province",
    country_code: "KH",
    lat: 12.0982918,
    lon: 105.3131185,
  },
  {
    art_state_id: "ASTEDB13F0B8A",
    state_code: "17",
    name: "Siem Reap Province",
    country_code: "KH",
    lat: 13.330266,
    lon: 104.1001326,
  },
  {
    art_state_id: "ASTF52B2230BC",
    state_code: "19",
    name: "Stung Treng Province",
    country_code: "KH",
    lat: 13.576473,
    lon: 105.9699878,
  },
  {
    art_state_id: "AST2369D7F7F2",
    state_code: "12",
    name: "Phnom Penh",
    country_code: "KH",
    lat: 11.5563738,
    lon: 104.9282099,
  },
  {
    art_state_id: "ASTC29A602D44",
    state_code: "09",
    name: "North Hamgyong Province",
    country_code: "KP",
    lat: 41.8148758,
    lon: 129.4581955,
  },
  {
    art_state_id: "AST69A1226803",
    state_code: "10",
    name: "Ryanggang Province",
    country_code: "KP",
    lat: 41.2318921,
    lon: 128.5076359,
  },
  {
    art_state_id: "AST3E6418C90C",
    state_code: "02",
    name: "South Pyongan Province",
    country_code: "KP",
    lat: 39.3539178,
    lon: 126.168271,
  },
  {
    art_state_id: "AST0CCB249B0E",
    state_code: "04",
    name: "Chagang Province",
    country_code: "KP",
    lat: 40.7202809,
    lon: 126.5621137,
  },
  {
    art_state_id: "AST2BF42774CF",
    state_code: "07",
    name: "Kangwon Province",
    country_code: "KP",
    lat: 38.8432393,
    lon: 127.5597067,
  },
  {
    art_state_id: "AST84CE536D21",
    state_code: "08",
    name: "South Hamgyong Province",
    country_code: "KP",
    lat: 40.3725339,
    lon: 128.298884,
  },
  {
    art_state_id: "ASTF160CA1A4E",
    state_code: "13",
    name: "Rason",
    country_code: "KP",
    lat: 42.2569063,
    lon: 130.2977186,
  },
  {
    art_state_id: "AST9DE97F6CF6",
    state_code: "03",
    name: "North Pyongan Province",
    country_code: "KP",
    lat: 39.9255618,
    lon: 125.3928025,
  },
  {
    art_state_id: "ASTCB3C7AF2CA",
    state_code: "05",
    name: "South Hwanghae Province",
    country_code: "KP",
    lat: 38.2007215,
    lon: 125.4781926,
  },
  {
    art_state_id: "AST644BD53953",
    state_code: "06",
    name: "North Hwanghae Province",
    country_code: "KP",
    lat: 38.3786085,
    lon: 126.4364363,
  },
  {
    art_state_id: "AST92073E81C6",
    state_code: "01",
    name: "Pyongyang",
    country_code: "KP",
    lat: 39.0392193,
    lon: 125.7625241,
  },
  {
    art_state_id: "AST485DFE17AE",
    state_code: "ML",
    name: "Meghalaya",
    country_code: "IN",
    lat: 25.4670308,
    lon: 91.366216,
  },
  {
    art_state_id: "AST4980FCB9EF",
    state_code: "HR",
    name: "Haryana",
    country_code: "IN",
    lat: 29.0587757,
    lon: 76.085601,
  },
  {
    art_state_id: "AST4477E7DEFC",
    state_code: "MH",
    name: "Maharashtra",
    country_code: "IN",
    lat: 19.7514798,
    lon: 75.7138884,
  },
  {
    art_state_id: "AST8DC8294A6C",
    state_code: "GA",
    name: "Goa",
    country_code: "IN",
    lat: 15.2993265,
    lon: 74.123996,
  },
  {
    art_state_id: "AST329909B939",
    state_code: "MN",
    name: "Manipur",
    country_code: "IN",
    lat: 24.6637173,
    lon: 93.9062688,
  },
  {
    art_state_id: "ASTC28BD0CA93",
    state_code: "PY",
    name: "Puducherry",
    country_code: "IN",
    lat: 11.9415915,
    lon: 79.8083133,
  },
  {
    art_state_id: "AST5888FDAE2A",
    state_code: "TG",
    name: "Telangana",
    country_code: "IN",
    lat: 18.1124372,
    lon: 79.0192997,
  },
  {
    art_state_id: "AST0393B13E56",
    state_code: "OR",
    name: "Odisha",
    country_code: "IN",
    lat: 20.9516658,
    lon: 85.0985236,
  },
  {
    art_state_id: "ASTD0A865C257",
    state_code: "RJ",
    name: "Rajasthan",
    country_code: "IN",
    lat: 27.0238036,
    lon: 74.2179326,
  },
  {
    art_state_id: "AST5F3F67AE54",
    state_code: "PB",
    name: "Punjab",
    country_code: "IN",
    lat: 30.9293211,
    lon: 75.5004841,
  },
  {
    art_state_id: "AST2F704FFF2F",
    state_code: "UT",
    name: "Uttarakhand",
    country_code: "IN",
    lat: 30.066753,
    lon: 79.0192997,
  },
  {
    art_state_id: "ASTD9CEB6FF7A",
    state_code: "AP",
    name: "Andhra Pradesh",
    country_code: "IN",
    lat: 15.9128998,
    lon: 79.7399875,
  },
  {
    art_state_id: "ASTE62D7283E8",
    state_code: "NL",
    name: "Nagaland",
    country_code: "IN",
    lat: 26.1584354,
    lon: 94.5624426,
  },
  {
    art_state_id: "ASTEA11F445B1",
    state_code: "LD",
    name: "Lakshadweep",
    country_code: "IN",
    lat: 10.3280265,
    lon: 72.7846336,
  },
  {
    art_state_id: "AST5382E20240",
    state_code: "HP",
    name: "Himachal Pradesh",
    country_code: "IN",
    lat: 31.1048294,
    lon: 77.1733901,
  },
  {
    art_state_id: "ASTAC17140342",
    state_code: "DL",
    name: "Delhi",
    country_code: "IN",
    lat: 28.7040592,
    lon: 77.1024902,
  },
  {
    art_state_id: "ASTAC61776E5B",
    state_code: "UP",
    name: "Uttar Pradesh",
    country_code: "IN",
    lat: 26.8467088,
    lon: 80.9461592,
  },
  {
    art_state_id: "AST5F4E28D224",
    state_code: "AN",
    name: "Andaman and Nicobar Islands",
    country_code: "IN",
    lat: 11.7400867,
    lon: 92.6586401,
  },
  {
    art_state_id: "AST829D58BCEB",
    state_code: "AR",
    name: "Arunachal Pradesh",
    country_code: "IN",
    lat: 28.2179994,
    lon: 94.7277528,
  },
  {
    art_state_id: "AST1A835A1B43",
    state_code: "JH",
    name: "Jharkhand",
    country_code: "IN",
    lat: 23.6101808,
    lon: 85.2799354,
  },
  {
    art_state_id: "ASTC43C4F6C78",
    state_code: "KA",
    name: "Karnataka",
    country_code: "IN",
    lat: 15.3172775,
    lon: 75.7138884,
  },
  {
    art_state_id: "AST94189FE080",
    state_code: "AS",
    name: "Assam",
    country_code: "IN",
    lat: 26.2006043,
    lon: 92.9375739,
  },
  {
    art_state_id: "ASTE064BFB48D",
    state_code: "KL",
    name: "Kerala",
    country_code: "IN",
    lat: 10.8505159,
    lon: 76.2710833,
  },
  {
    art_state_id: "ASTE914E15CB0",
    state_code: "JK",
    name: "Jammu and Kashmir",
    country_code: "IN",
    lat: 33.277839,
    lon: 75.3412179,
  },
  {
    art_state_id: "ASTE113659E69",
    state_code: "GJ",
    name: "Gujarat",
    country_code: "IN",
    lat: 22.258652,
    lon: 71.1923805,
  },
  {
    art_state_id: "AST853F40150F",
    state_code: "CH",
    name: "Chandigarh",
    country_code: "IN",
    lat: 30.7333148,
    lon: 76.7794179,
  },
  {
    art_state_id: "AST4C656D27D1",
    state_code: "DH",
    name: "Dadra and Nagar Haveli and Daman and Diu",
    country_code: "IN",
    lat: 20.3973736,
    lon: 72.8327991,
  },
  {
    art_state_id: "AST765A57FE4F",
    state_code: "SK",
    name: "Sikkim",
    country_code: "IN",
    lat: 27.5329718,
    lon: 88.5122178,
  },
  {
    art_state_id: "AST3F32BA4596",
    state_code: "TN",
    name: "Tamil Nadu",
    country_code: "IN",
    lat: 11.1271225,
    lon: 78.6568942,
  },
  {
    art_state_id: "AST9A2A13F2A5",
    state_code: "MZ",
    name: "Mizoram",
    country_code: "IN",
    lat: 23.164543,
    lon: 92.9375739,
  },
  {
    art_state_id: "ASTF75DCDCEC2",
    state_code: "BR",
    name: "Bihar",
    country_code: "IN",
    lat: 25.0960742,
    lon: 85.3131194,
  },
  {
    art_state_id: "AST7157A9F0ED",
    state_code: "TR",
    name: "Tripura",
    country_code: "IN",
    lat: 23.9408482,
    lon: 91.9881527,
  },
  {
    art_state_id: "ASTBC718F3C6C",
    state_code: "MP",
    name: "Madhya Pradesh",
    country_code: "IN",
    lat: 22.9734229,
    lon: 78.6568942,
  },
  {
    art_state_id: "ASTBF644560F5",
    state_code: "CT",
    name: "Chhattisgarh",
    country_code: "IN",
    lat: 21.2786567,
    lon: 81.8661442,
  },
  {
    art_state_id: "AST8905668554",
    state_code: "CH",
    name: "Choluteca Department",
    country_code: "HN",
    lat: 13.2504325,
    lon: -87.1422895,
  },
  {
    art_state_id: "ASTDC4D8AFB56",
    state_code: "CM",
    name: "Comayagua Department",
    country_code: "HN",
    lat: 14.5534828,
    lon: -87.6186379,
  },
  {
    art_state_id: "AST1E777DA362",
    state_code: "EP",
    name: "El Paraíso Department",
    country_code: "HN",
    lat: 13.9821294,
    lon: -86.4996546,
  },
  {
    art_state_id: "AST6E911AB038",
    state_code: "IN",
    name: "Intibucá Department",
    country_code: "HN",
    lat: 14.372734,
    lon: -88.2461183,
  },
  {
    art_state_id: "ASTEA0C03607C",
    state_code: "IB",
    name: "Bay Islands Department",
    country_code: "HN",
    lat: 16.4826614,
    lon: -85.8793252,
  },
  {
    art_state_id: "AST8D592DC450",
    state_code: "CR",
    name: "Cortés Department",
    country_code: "HN",
    lat: 15.4923508,
    lon: -88.0900762,
  },
  {
    art_state_id: "ASTB71482D68A",
    state_code: "AT",
    name: "Atlántida Department",
    country_code: "HN",
    lat: 15.6696283,
    lon: -87.1422895,
  },
  {
    art_state_id: "ASTF37A07F859",
    state_code: "GD",
    name: "Gracias a Dios Department",
    country_code: "HN",
    lat: 15.341806,
    lon: -84.6060449,
  },
  {
    art_state_id: "AST2A82E3453E",
    state_code: "CP",
    name: "Copán Department",
    country_code: "HN",
    lat: 14.9360838,
    lon: -88.864698,
  },
  {
    art_state_id: "AST9608AF25CF",
    state_code: "OL",
    name: "Olancho Department",
    country_code: "HN",
    lat: 14.8067406,
    lon: -85.7666645,
  },
  {
    art_state_id: "AST1556B5B300",
    state_code: "CL",
    name: "Colón Department",
    country_code: "HN",
    lat: 15.6425965,
    lon: -85.520024,
  },
  {
    art_state_id: "AST6E11F0B5E5",
    state_code: "FM",
    name: "Francisco Morazán Department",
    country_code: "HN",
    lat: 14.45411,
    lon: -87.0624261,
  },
  {
    art_state_id: "ASTD9B7AF603C",
    state_code: "SB",
    name: "Santa Bárbara Department",
    country_code: "HN",
    lat: 15.1202795,
    lon: -88.4016041,
  },
  {
    art_state_id: "ASTC17C7AB5A0",
    state_code: "LE",
    name: "Lempira Department",
    country_code: "HN",
    lat: 14.1887698,
    lon: -88.556531,
  },
  {
    art_state_id: "ASTCBEACE3BF5",
    state_code: "VA",
    name: "Valle Department",
    country_code: "HN",
    lat: 13.5782936,
    lon: -87.5791287,
  },
  {
    art_state_id: "AST9C87E1ACB9",
    state_code: "OC",
    name: "Ocotepeque Department",
    country_code: "HN",
    lat: 14.5170347,
    lon: -89.0561532,
  },
  {
    art_state_id: "AST492630C360",
    state_code: "YO",
    name: "Yoro Department",
    country_code: "HN",
    lat: 15.2949679,
    lon: -87.1422895,
  },
  {
    art_state_id: "AST25484F678B",
    state_code: "LP",
    name: "La Paz Department",
    country_code: "HN",
    lat: -15.0892416,
    lon: -68.5247149,
  },
  {
    art_state_id: "AST8C94C760E2",
    state_code: "NTL",
    name: "Northland Region",
    country_code: "NZ",
    lat: -35.4136172,
    lon: 173.9320806,
  },
  {
    art_state_id: "AST9470447A1B",
    state_code: "MWT",
    name: "Manawatu-Wanganui Region",
    country_code: "NZ",
    lat: -39.7273356,
    lon: 175.4375574,
  },
  {
    art_state_id: "AST31F6CFD9C0",
    state_code: "WKO",
    name: "Waikato Region",
    country_code: "NZ",
    lat: -37.6190862,
    lon: 175.023346,
  },
  {
    art_state_id: "AST537F6DF9C2",
    state_code: "OTA",
    name: "Otago Region",
    country_code: "NZ",
    lat: -45.4790671,
    lon: 170.1547567,
  },
  {
    art_state_id: "ASTB85217FCF3",
    state_code: "MBH",
    name: "Marlborough Region",
    country_code: "NZ",
    lat: -41.5916883,
    lon: 173.7624053,
  },
  {
    art_state_id: "ASTC7DE7D1420",
    state_code: "WTC",
    name: "West Coast Region",
    country_code: "NZ",
    lat: 62.4113634,
    lon: -149.0729714,
  },
  {
    art_state_id: "AST86673EEDF7",
    state_code: "WGN",
    name: "Wellington Region",
    country_code: "NZ",
    lat: -41.0299323,
    lon: 175.4375574,
  },
  {
    art_state_id: "ASTA0C9769C6F",
    state_code: "CAN",
    name: "Canterbury Region",
    country_code: "NZ",
    lat: -43.7542275,
    lon: 171.1637245,
  },
  {
    art_state_id: "AST64FF382379",
    state_code: "CIT",
    name: "Chatham Islands",
    country_code: "NZ",
    lat: -44.0057523,
    lon: -176.5400674,
  },
  {
    art_state_id: "ASTA2B3BD90C9",
    state_code: "GIS",
    name: "Gisborne District",
    country_code: "NZ",
    lat: -38.1358174,
    lon: 178.3239309,
  },
  {
    art_state_id: "AST8F2E8DD4A3",
    state_code: "TKI",
    name: "Taranaki Region",
    country_code: "NZ",
    lat: -39.3538149,
    lon: 174.4382721,
  },
  {
    art_state_id: "ASTCBF97B6373",
    state_code: "NSN",
    name: "Nelson Region",
    country_code: "NZ",
    lat: -41.2985397,
    lon: 173.2441491,
  },
  {
    art_state_id: "ASTF620724C66",
    state_code: "STL",
    name: "Southland Region",
    country_code: "NZ",
    lat: -45.8489159,
    lon: 167.6755387,
  },
  {
    art_state_id: "AST00EADC2DA1",
    state_code: "AUK",
    name: "Auckland Region",
    country_code: "NZ",
    lat: -36.6675328,
    lon: 174.7733325,
  },
  {
    art_state_id: "AST9DC55998DD",
    state_code: "TAS",
    name: "Tasman District",
    country_code: "NZ",
    lat: -41.4571184,
    lon: 172.820974,
  },
  {
    art_state_id: "ASTD9D2FD281A",
    state_code: "BOP",
    name: "Bay of Plenty Region",
    country_code: "NZ",
    lat: -37.4233917,
    lon: 176.7416374,
  },
  {
    art_state_id: "AST7996AC2080",
    state_code: "HKB",
    name: "Hawke's Bay Region",
    country_code: "NZ",
    lat: -39.6016597,
    lon: 176.5804473,
  },
  {
    art_state_id: "AST4F141FCC98",
    state_code: "05",
    name: "Saint John Parish",
    country_code: "DM",
    lat: 15.573915849999999,
    lon: -61.43697569762344,
  },
  {
    art_state_id: "AST903A81DADE",
    state_code: "08",
    name: "Saint Mark Parish",
    country_code: "DM",
    lat: 15.22852655,
    lon: -61.35000414820221,
  },
  {
    art_state_id: "ASTC9F6BE1832",
    state_code: "03",
    name: "Saint David Parish",
    country_code: "DM",
    lat: 15.4223996,
    lon: -61.288411814915165,
  },
  {
    art_state_id: "AST96CAE05A89",
    state_code: "04",
    name: "Saint George Parish",
    country_code: "DM",
    lat: 15.305884899999999,
    lon: -61.34772863702494,
  },
  {
    art_state_id: "AST815DE8ADD4",
    state_code: "09",
    name: "Saint Patrick Parish",
    country_code: "DM",
    lat: 15.27704255,
    lon: -61.29647749811258,
  },
  {
    art_state_id: "AST56A135AC97",
    state_code: "11",
    name: "Saint Peter Parish",
    country_code: "DM",
    lat: 40.4524194,
    lon: -80.0085056,
  },
  {
    art_state_id: "ASTF3534FE317",
    state_code: "02",
    name: "Saint Andrew Parish",
    country_code: "DM",
    lat: 15.544674700000002,
    lon: -61.35301656075525,
  },
  {
    art_state_id: "ASTEB50873146",
    state_code: "07",
    name: "Saint Luke Parish",
    country_code: "DM",
    lat: 42.1051363,
    lon: -80.0570722,
  },
  {
    art_state_id: "AST735DE722AB",
    state_code: "10",
    name: "Saint Paul Parish",
    country_code: "DM",
    lat: 38.86146,
    lon: -90.7435619,
  },
  {
    art_state_id: "AST9DC9B7C0CF",
    state_code: "06",
    name: "Saint Joseph Parish",
    country_code: "DM",
    lat: 39.0222712,
    lon: -94.7176504,
  },
  {
    art_state_id: "AST1CB174384E",
    state_code: "08",
    name: "El Seibo Province",
    country_code: "DO",
    lat: 18.7658496,
    lon: -69.040668,
  },
  {
    art_state_id: "ASTFD63303836",
    state_code: "12",
    name: "La Romana Province",
    country_code: "DO",
    lat: 18.4310271,
    lon: -68.9837373,
  },
  {
    art_state_id: "AST2C5A1960AC",
    state_code: "24",
    name: "Sánchez Ramírez Province",
    country_code: "DO",
    lat: 19.052706,
    lon: -70.1492264,
  },
  {
    art_state_id: "AST62090162B7",
    state_code: "19",
    name: "Hermanas Mirabal Province",
    country_code: "DO",
    lat: 19.3747559,
    lon: -70.3513235,
  },
  {
    art_state_id: "AST4231D40F1B",
    state_code: "04",
    name: "Barahona Province",
    country_code: "DO",
    lat: 18.2139066,
    lon: -71.1043759,
  },
  {
    art_state_id: "AST82EB2E8080",
    state_code: "21",
    name: "San Cristóbal Province",
    country_code: "DO",
    lat: 18.4180414,
    lon: -70.1065849,
  },
  {
    art_state_id: "AST509573A5C5",
    state_code: "18",
    name: "Puerto Plata Province",
    country_code: "DO",
    lat: 19.7543225,
    lon: -70.8332847,
  },
  {
    art_state_id: "ASTB283215505",
    state_code: "32",
    name: "Santo Domingo Province",
    country_code: "DO",
    lat: 18.5104253,
    lon: -69.8404054,
  },
  {
    art_state_id: "AST72DE9397FA",
    state_code: "14",
    name: "María Trinidad Sánchez Province",
    country_code: "DO",
    lat: 19.3734597,
    lon: -69.8514439,
  },
  {
    art_state_id: "AST376466C173",
    state_code: "01",
    name: "Distrito Nacional",
    country_code: "DO",
    lat: 18.4860575,
    lon: -69.9312117,
  },
  {
    art_state_id: "AST7D088D498E",
    state_code: "17",
    name: "Peravia Province",
    country_code: "DO",
    lat: 18.2786594,
    lon: -70.3335887,
  },
  {
    art_state_id: "ASTA174C164A3",
    state_code: "10",
    name: "Independencia",
    country_code: "DO",
    lat: 32.6335748,
    lon: -115.4289294,
  },
  {
    art_state_id: "AST1E40AB246A",
    state_code: "22",
    name: "San Juan Province",
    country_code: "DO",
    lat: -31.5287127,
    lon: -68.5360403,
  },
  {
    art_state_id: "AST30487DA1AA",
    state_code: "28",
    name: "Monseñor Nouel Province",
    country_code: "DO",
    lat: 18.9215234,
    lon: -70.3836815,
  },
  {
    art_state_id: "ASTD4BF245222",
    state_code: "26",
    name: "Santiago Rodríguez Province",
    country_code: "DO",
    lat: 19.4713181,
    lon: -71.3395801,
  },
  {
    art_state_id: "AST1CB38310F8",
    state_code: "16",
    name: "Pedernales Province",
    country_code: "DO",
    lat: 17.8537626,
    lon: -71.3303209,
  },
  {
    art_state_id: "AST8E3D8DD56D",
    state_code: "09",
    name: "Espaillat Province",
    country_code: "DO",
    lat: 19.6277658,
    lon: -70.2786775,
  },
  {
    art_state_id: "AST4B78069A97",
    state_code: "20",
    name: "Samaná Province",
    country_code: "DO",
    lat: 19.2058371,
    lon: -69.3362949,
  },
  {
    art_state_id: "AST6CFD7A76B1",
    state_code: "27",
    name: "Valverde Province",
    country_code: "DO",
    lat: 19.5881221,
    lon: -70.980331,
  },
  {
    art_state_id: "AST3CD0A6E31D",
    state_code: "03",
    name: "Baoruco Province",
    country_code: "DO",
    lat: 18.4879898,
    lon: -71.4182249,
  },
  {
    art_state_id: "AST86E1320F6E",
    state_code: "30",
    name: "Hato Mayor Province",
    country_code: "DO",
    lat: 18.7635799,
    lon: -69.2557637,
  },
  {
    art_state_id: "AST3C28803CAA",
    state_code: "05",
    name: "Dajabón Province",
    country_code: "DO",
    lat: 19.5499241,
    lon: -71.7086514,
  },
  {
    art_state_id: "ASTF409CB183C",
    state_code: "25",
    name: "Santiago Province",
    country_code: "DO",
    lat: -33.45,
    lon: -70.6667,
  },
  {
    art_state_id: "ASTE630E26382",
    state_code: "11",
    name: "La Altagracia Province",
    country_code: "DO",
    lat: 18.5850236,
    lon: -68.6201072,
  },
  {
    art_state_id: "AST67DD02E8D2",
    state_code: "23",
    name: "San Pedro de Macorís",
    country_code: "DO",
    lat: 18.46266,
    lon: -69.3051234,
  },
  {
    art_state_id: "ASTDD5D460813",
    state_code: "29",
    name: "Monte Plata Province",
    country_code: "DO",
    lat: 18.8080878,
    lon: -69.7869146,
  },
  {
    art_state_id: "AST353445F87A",
    state_code: "31",
    name: "San José de Ocoa Province",
    country_code: "DO",
    lat: 18.543858,
    lon: -70.5041816,
  },
  {
    art_state_id: "AST73C9B24732",
    state_code: "06",
    name: "Duarte Province",
    country_code: "DO",
    lat: 19.2090823,
    lon: -70.0270004,
  },
  {
    art_state_id: "ASTA08F2B6028",
    state_code: "02",
    name: "Azua Province",
    country_code: "DO",
    lat: 18.4552709,
    lon: -70.7380928,
  },
  {
    art_state_id: "AST8F2130C167",
    state_code: "15",
    name: "Monte Cristi Province",
    country_code: "DO",
    lat: 19.7396899,
    lon: -71.4433984,
  },
  {
    art_state_id: "ASTB11494565B",
    state_code: "13",
    name: "La Vega Province",
    country_code: "DO",
    lat: 19.2211554,
    lon: -70.5288753,
  },
  {
    art_state_id: "AST439AB72AAD",
    state_code: "ND",
    name: "Nord",
    country_code: "HT",
    lat: 43.190526,
    lon: -89.437921,
  },
  {
    art_state_id: "AST06451CF953",
    state_code: "NI",
    name: "Nippes",
    country_code: "HT",
    lat: 18.3990735,
    lon: -73.4180211,
  },
  {
    art_state_id: "AST33C88B5796",
    state_code: "GA",
    name: "Grand'Anse",
    country_code: "HT",
    lat: 12.0166667,
    lon: -61.7666667,
  },
  {
    art_state_id: "ASTA01A624A9C",
    state_code: "OU",
    name: "Ouest",
    country_code: "HT",
    lat: 45.4547249,
    lon: -73.6502365,
  },
  {
    art_state_id: "AST1B698E0B69",
    state_code: "NE",
    name: "Nord-Est",
    country_code: "HT",
    lat: 19.4889723,
    lon: -71.8571331,
  },
  {
    art_state_id: "AST35129EA9C5",
    state_code: "SD",
    name: "Sud",
    country_code: "HT",
    lat: 29.9213248,
    lon: -90.0973772,
  },
  {
    art_state_id: "AST78E0E1D5E6",
    state_code: "AR",
    name: "Artibonite",
    country_code: "HT",
    lat: 19.362902,
    lon: -72.4258145,
  },
  {
    art_state_id: "ASTBDB6B68BC0",
    state_code: "SE",
    name: "Sud-Est",
    country_code: "HT",
    lat: 18.2783598,
    lon: -72.3547915,
  },
  {
    art_state_id: "AST68B894A860",
    state_code: "CE",
    name: "Centre",
    country_code: "HT",
    lat: 32.8370251,
    lon: -96.7773882,
  },
  {
    art_state_id: "AST0AFF69009E",
    state_code: "NO",
    name: "Nord-Ouest",
    country_code: "HT",
    lat: 19.8374009,
    lon: -73.0405277,
  },
  {
    art_state_id: "AST4F0CDBFF85",
    state_code: "SV",
    name: "San Vicente Department",
    country_code: "SV",
    lat: 13.5868561,
    lon: -88.7493998,
  },
  {
    art_state_id: "AST064B1C3F6F",
    state_code: "SA",
    name: "Santa Ana Department",
    country_code: "SV",
    lat: 14.1461121,
    lon: -89.5120084,
  },
  {
    art_state_id: "AST4A04621C41",
    state_code: "US",
    name: "Usulután Department",
    country_code: "SV",
    lat: 13.4470634,
    lon: -88.556531,
  },
  {
    art_state_id: "ASTA7D223C2A8",
    state_code: "MO",
    name: "Morazán Department",
    country_code: "SV",
    lat: 13.7682,
    lon: -88.1291387,
  },
  {
    art_state_id: "ASTE0186FDC8F",
    state_code: "CH",
    name: "Chalatenango Department",
    country_code: "SV",
    lat: 14.1916648,
    lon: -89.1705998,
  },
  {
    art_state_id: "AST35A8DEE90F",
    state_code: "CA",
    name: "Cabañas Department",
    country_code: "SV",
    lat: 13.8648288,
    lon: -88.7493998,
  },
  {
    art_state_id: "ASTBED0C2351F",
    state_code: "SS",
    name: "San Salvador Department",
    country_code: "SV",
    lat: 13.7739997,
    lon: -89.2086773,
  },
  {
    art_state_id: "ASTCAA192F48F",
    state_code: "LI",
    name: "La Libertad Department",
    country_code: "SV",
    lat: 13.6817661,
    lon: -89.3606298,
  },
  {
    art_state_id: "AST5DF6CAAB91",
    state_code: "SM",
    name: "San Miguel Department",
    country_code: "SV",
    lat: 13.4451041,
    lon: -88.2461183,
  },
  {
    art_state_id: "AST80938C985C",
    state_code: "PA",
    name: "La Paz Department",
    country_code: "SV",
    lat: 13.8000382,
    lon: -88.9140683,
  },
  {
    art_state_id: "AST59E2B7F84A",
    state_code: "CU",
    name: "Cuscatlán Department",
    country_code: "SV",
    lat: 13.8661957,
    lon: -89.0561532,
  },
  {
    art_state_id: "AST94666D4711",
    state_code: "UN",
    name: "La Unión Department",
    country_code: "SV",
    lat: 13.4886443,
    lon: -87.8942451,
  },
  {
    art_state_id: "ASTD33BB017FF",
    state_code: "AH",
    name: "Ahuachapán Department",
    country_code: "SV",
    lat: 13.8216148,
    lon: -89.9253233,
  },
  {
    art_state_id: "AST6166AC4037",
    state_code: "SO",
    name: "Sonsonate Department",
    country_code: "SV",
    lat: 13.682358,
    lon: -89.6628111,
  },
  {
    art_state_id: "AST18D0431AC7",
    state_code: "151",
    name: "Braslovče Municipality",
    country_code: "SI",
    lat: 46.2836192,
    lon: 15.041832,
  },
  {
    art_state_id: "AST0DC0632734",
    state_code: "058",
    name: "Lenart Municipality",
    country_code: "SI",
    lat: 46.5834424,
    lon: 15.8262125,
  },
  {
    art_state_id: "AST4AAF215B03",
    state_code: "171",
    name: "Oplotnica",
    country_code: "SI",
    lat: 46.387163,
    lon: 15.4458131,
  },
  {
    art_state_id: "AST6B7D4940F5",
    state_code: "134",
    name: "Velike Lašče Municipality",
    country_code: "SI",
    lat: 45.8336591,
    lon: 14.6362363,
  },
  {
    art_state_id: "ASTA84324DEBD",
    state_code: "159",
    name: "Hajdina Municipality",
    country_code: "SI",
    lat: 46.4185014,
    lon: 15.8244722,
  },
  {
    art_state_id: "AST7442192849",
    state_code: "092",
    name: "Podčetrtek Municipality",
    country_code: "SI",
    lat: 46.1739542,
    lon: 15.6013816,
  },
  {
    art_state_id: "AST03E21E5453",
    state_code: "152",
    name: "Cankova Municipality",
    country_code: "SI",
    lat: 46.718237,
    lon: 16.0197222,
  },
  {
    art_state_id: "AST4523BE4EF4",
    state_code: "137",
    name: "Vitanje Municipality",
    country_code: "SI",
    lat: 46.3815323,
    lon: 15.2950687,
  },
  {
    art_state_id: "ASTDD2262BF0D",
    state_code: "111",
    name: "Sežana Municipality",
    country_code: "SI",
    lat: 45.7275109,
    lon: 13.8661931,
  },
  {
    art_state_id: "ASTF936CA8390",
    state_code: "045",
    name: "Kidričevo Municipality",
    country_code: "SI",
    lat: 46.3957572,
    lon: 15.7925906,
  },
  {
    art_state_id: "AST45874451F9",
    state_code: "015",
    name: "Črenšovci Municipality",
    country_code: "SI",
    lat: 46.5720029,
    lon: 16.2877346,
  },
  {
    art_state_id: "AST8466E68D22",
    state_code: "036",
    name: "Idrija Municipality",
    country_code: "SI",
    lat: 46.0040939,
    lon: 13.9775493,
  },
  {
    art_state_id: "AST3367FC0C7F",
    state_code: "185",
    name: "Trnovska Vas Municipality",
    country_code: "SI",
    lat: 46.5294035,
    lon: 15.8853118,
  },
  {
    art_state_id: "AST990C431A62",
    state_code: "138",
    name: "Vodice Municipality",
    country_code: "SI",
    lat: 46.1896643,
    lon: 14.4938539,
  },
  {
    art_state_id: "ASTF252B69FCF",
    state_code: "103",
    name: "Ravne na Koroškem Municipality",
    country_code: "SI",
    lat: 46.5521194,
    lon: 14.9599084,
  },
  {
    art_state_id: "AST14C3D7B014",
    state_code: "167",
    name: "Lovrenc na Pohorju Municipality",
    country_code: "SI",
    lat: 46.5419638,
    lon: 15.4000443,
  },
  {
    art_state_id: "AST9620CCB815",
    state_code: "069",
    name: "Majšperk Municipality",
    country_code: "SI",
    lat: 46.3503019,
    lon: 15.7340595,
  },
  {
    art_state_id: "ASTDB101955A4",
    state_code: "066",
    name: "Loški Potok Municipality",
    country_code: "SI",
    lat: 45.6909637,
    lon: 14.598597,
  },
  {
    art_state_id: "ASTFCE1EBF602",
    state_code: "023",
    name: "Domžale Municipality",
    country_code: "SI",
    lat: 46.1438269,
    lon: 14.6375279,
  },
  {
    art_state_id: "AST3ADDF892A4",
    state_code: "209",
    name: "Rečica ob Savinji Municipality",
    country_code: "SI",
    lat: 46.323379,
    lon: 14.922367,
  },
  {
    art_state_id: "AST0ED0DF752D",
    state_code: "172",
    name: "Podlehnik Municipality",
    country_code: "SI",
    lat: 46.3310782,
    lon: 15.8785836,
  },
  {
    art_state_id: "ASTB8A33D82F1",
    state_code: "013",
    name: "Cerknica Municipality",
    country_code: "SI",
    lat: 45.7966255,
    lon: 14.392177,
  },
  {
    art_state_id: "ASTA1BAE22FB2",
    state_code: "189",
    name: "Vransko Municipality",
    country_code: "SI",
    lat: 46.239006,
    lon: 14.9527249,
  },
  {
    art_state_id: "AST611AEE7288",
    state_code: "181",
    name: "Sveta Ana Municipality",
    country_code: "SI",
    lat: 46.65,
    lon: 15.845278,
  },
  {
    art_state_id: "AST9ABB44B900",
    state_code: "008",
    name: "Brezovica Municipality",
    country_code: "SI",
    lat: 45.9559351,
    lon: 14.4349952,
  },
  {
    art_state_id: "ASTC4185C5CEA",
    state_code: "148",
    name: "Benedikt Municipality",
    country_code: "SI",
    lat: 46.6155841,
    lon: 15.8957281,
  },
  {
    art_state_id: "ASTBCCE197509",
    state_code: "019",
    name: "Divača Municipality",
    country_code: "SI",
    lat: 45.6806069,
    lon: 13.9720312,
  },
  {
    art_state_id: "AST0627A3EED4",
    state_code: "077",
    name: "Moravče Municipality",
    country_code: "SI",
    lat: 46.1362781,
    lon: 14.746001,
  },
  {
    art_state_id: "AST3781ABF834",
    state_code: "112",
    name: "Slovenj Gradec City Municipality",
    country_code: "SI",
    lat: 46.4877718,
    lon: 15.0729478,
  },
  {
    art_state_id: "AST9A044B1878",
    state_code: "121",
    name: "Škocjan Municipality",
    country_code: "SI",
    lat: 45.9175454,
    lon: 15.3101736,
  },
  {
    art_state_id: "AST39E6A9CC8E",
    state_code: "120",
    name: "Šentjur Municipality",
    country_code: "SI",
    lat: 46.2654339,
    lon: 15.408,
  },
  {
    art_state_id: "AST7D14524411",
    state_code: "089",
    name: "Pesnica Municipality",
    country_code: "SI",
    lat: 46.6088755,
    lon: 15.6757051,
  },
  {
    art_state_id: "AST7DE9E3A28A",
    state_code: "022",
    name: "Dol pri Ljubljani Municipality",
    country_code: "SI",
    lat: 46.0884386,
    lon: 14.6424792,
  },
  {
    art_state_id: "ASTCFCDCCDA9C",
    state_code: "065",
    name: "Loška Dolina Municipality",
    country_code: "SI",
    lat: 45.6477908,
    lon: 14.4973147,
  },
  {
    art_state_id: "AST48DAA1DC50",
    state_code: "160",
    name: "Hoče–Slivnica Municipality",
    country_code: "SI",
    lat: 46.477858,
    lon: 15.6476005,
  },
  {
    art_state_id: "ASTA65ED3DC4F",
    state_code: "153",
    name: "Cerkvenjak Municipality",
    country_code: "SI",
    lat: 46.5670711,
    lon: 15.9429753,
  },
  {
    art_state_id: "ASTFC875FF5B5",
    state_code: "082",
    name: "Naklo Municipality",
    country_code: "SI",
    lat: 46.2718663,
    lon: 14.3156932,
  },
  {
    art_state_id: "AST1EE0145E96",
    state_code: "014",
    name: "Cerkno Municipality",
    country_code: "SI",
    lat: 46.1288414,
    lon: 13.9894027,
  },
  {
    art_state_id: "AST1D72C736BB",
    state_code: "149",
    name: "Bistrica ob Sotli Municipality",
    country_code: "SI",
    lat: 46.0565579,
    lon: 15.6625947,
  },
  {
    art_state_id: "ASTEAC2B68CE9",
    state_code: "043",
    name: "Kamnik Municipality",
    country_code: "SI",
    lat: 46.2221666,
    lon: 14.6070727,
  },
  {
    art_state_id: "AST068A3003B2",
    state_code: "006",
    name: "Bovec Municipality",
    country_code: "SI",
    lat: 46.3380495,
    lon: 13.5524174,
  },
  {
    art_state_id: "AST843E120F37",
    state_code: "143",
    name: "Zavrč Municipality",
    country_code: "SI",
    lat: 46.35713,
    lon: 16.0477747,
  },
  {
    art_state_id: "AST5072E4725D",
    state_code: "001",
    name: "Ajdovščina Municipality",
    country_code: "SI",
    lat: 45.8870776,
    lon: 13.9042818,
  },
  {
    art_state_id: "ASTACD2011DE3",
    state_code: "091",
    name: "Pivka Municipality",
    country_code: "SI",
    lat: 45.6789296,
    lon: 14.2542689,
  },
  {
    art_state_id: "ASTAC535DBFAA",
    state_code: "127",
    name: "Štore Municipality",
    country_code: "SI",
    lat: 46.2222514,
    lon: 15.3126116,
  },
  {
    art_state_id: "AST9B2FCE469F",
    state_code: "051",
    name: "Kozje Municipality",
    country_code: "SI",
    lat: 46.0733211,
    lon: 15.5596719,
  },
  {
    art_state_id: "AST2713D10F3C",
    state_code: "123",
    name: "Municipality of Škofljica",
    country_code: "SI",
    lat: 45.9840962,
    lon: 14.5746626,
  },
  {
    art_state_id: "AST8186AC2321",
    state_code: "174",
    name: "Prebold Municipality",
    country_code: "SI",
    lat: 46.2359136,
    lon: 15.0936912,
  },
  {
    art_state_id: "AST18E7F72C9A",
    state_code: "156",
    name: "Dobrovnik Municipality",
    country_code: "SI",
    lat: 46.6538662,
    lon: 16.3506594,
  },
  {
    art_state_id: "ASTCABFC5504F",
    state_code: "079",
    name: "Mozirje Municipality",
    country_code: "SI",
    lat: 46.339435,
    lon: 14.9602413,
  },
  {
    art_state_id: "AST615157A3A7",
    state_code: "011",
    name: "City Municipality of Celje",
    country_code: "SI",
    lat: 46.2397495,
    lon: 15.2677063,
  },
  {
    art_state_id: "ASTE7ABD287E4",
    state_code: "147",
    name: "Žiri Municipality",
    country_code: "SI",
    lat: 46.0472499,
    lon: 14.1098451,
  },
  {
    art_state_id: "AST077DAC74CA",
    state_code: "162",
    name: "Horjul Municipality",
    country_code: "SI",
    lat: 46.0225378,
    lon: 14.2986269,
  },
  {
    art_state_id: "AST3C66998BD7",
    state_code: "184",
    name: "Tabor Municipality",
    country_code: "SI",
    lat: 46.2107921,
    lon: 15.0174249,
  },
  {
    art_state_id: "AST682E313996",
    state_code: "099",
    name: "Radeče Municipality",
    country_code: "SI",
    lat: 46.0666954,
    lon: 15.1820438,
  },
  {
    art_state_id: "ASTD4EDBB2179",
    state_code: "136",
    name: "Vipava Municipality",
    country_code: "SI",
    lat: 45.8412674,
    lon: 13.9609613,
  },
  {
    art_state_id: "AST88DC5C5BA3",
    state_code: "055",
    name: "Kungota",
    country_code: "SI",
    lat: 46.6418793,
    lon: 15.6036288,
  },
  {
    art_state_id: "AST2F4F3CB878",
    state_code: "114",
    name: "Slovenske Konjice Municipality",
    country_code: "SI",
    lat: 46.3369191,
    lon: 15.4214708,
  },
  {
    art_state_id: "AST84D19BAD87",
    state_code: "088",
    name: "Osilnica Municipality",
    country_code: "SI",
    lat: 45.5418467,
    lon: 14.7156303,
  },
  {
    art_state_id: "AST6DE22A951F",
    state_code: "005",
    name: "Borovnica Municipality",
    country_code: "SI",
    lat: 45.9044525,
    lon: 14.3824189,
  },
  {
    art_state_id: "AST84453B3D33",
    state_code: "090",
    name: "Piran Municipality",
    country_code: "SI",
    lat: 45.5288856,
    lon: 13.5680735,
  },
  {
    art_state_id: "AST0F5D25B355",
    state_code: "003",
    name: "Bled Municipality",
    country_code: "SI",
    lat: 46.3683266,
    lon: 14.1145798,
  },
  {
    art_state_id: "AST78F9CC62EF",
    state_code: "163",
    name: "Jezersko Municipality",
    country_code: "SI",
    lat: 46.3942794,
    lon: 14.4985559,
  },
  {
    art_state_id: "AST3B5550CF9A",
    state_code: "098",
    name: "Rače–Fram Municipality",
    country_code: "SI",
    lat: 46.4542083,
    lon: 15.6329467,
  },
  {
    art_state_id: "ASTC3E16D5699",
    state_code: "084",
    name: "Nova Gorica City Municipality",
    country_code: "SI",
    lat: 45.976276,
    lon: 13.7308881,
  },
  {
    art_state_id: "ASTE32E0545FF",
    state_code: "176",
    name: "Razkrižje Municipality",
    country_code: "SI",
    lat: 46.5226339,
    lon: 16.2668638,
  },
  {
    art_state_id: "AST4FCF3F5110",
    state_code: "177",
    name: "Ribnica na Pohorju Municipality",
    country_code: "SI",
    lat: 46.5356145,
    lon: 15.2674538,
  },
  {
    art_state_id: "AST29488F9538",
    state_code: "081",
    name: "Muta Municipality",
    country_code: "SI",
    lat: 46.6097366,
    lon: 15.1629995,
  },
  {
    art_state_id: "ASTE51D5FC1BF",
    state_code: "107",
    name: "Rogatec Municipality",
    country_code: "SI",
    lat: 46.2286626,
    lon: 15.6991338,
  },
  {
    art_state_id: "AST1DF202A0C2",
    state_code: "028",
    name: "Gorišnica Municipality",
    country_code: "SI",
    lat: 46.4120271,
    lon: 16.0133089,
  },
  {
    art_state_id: "ASTAB44A2A630",
    state_code: "056",
    name: "Kuzma Municipality",
    country_code: "SI",
    lat: 46.8351038,
    lon: 16.08071,
  },
  {
    art_state_id: "AST4E8CC3E285",
    state_code: "076",
    name: "Mislinja Municipality",
    country_code: "SI",
    lat: 46.4429403,
    lon: 15.1987678,
  },
  {
    art_state_id: "AST5D45822562",
    state_code: "026",
    name: "Duplek Municipality",
    country_code: "SI",
    lat: 46.5010016,
    lon: 15.7546307,
  },
  {
    art_state_id: "AST650B196C9F",
    state_code: "130",
    name: "Trebnje Municipality",
    country_code: "SI",
    lat: 45.9080163,
    lon: 15.0131905,
  },
  {
    art_state_id: "AST60546F5F12",
    state_code: "009",
    name: "Brežice Municipality",
    country_code: "SI",
    lat: 45.9041096,
    lon: 15.5943639,
  },
  {
    art_state_id: "AST54DD4136E5",
    state_code: "020",
    name: "Dobrepolje Municipality",
    country_code: "SI",
    lat: 45.8524951,
    lon: 14.7083109,
  },
  {
    art_state_id: "AST4099242922",
    state_code: "158",
    name: "Grad Municipality",
    country_code: "SI",
    lat: 46.808732,
    lon: 16.109206,
  },
  {
    art_state_id: "AST463ED25145",
    state_code: "078",
    name: "Moravske Toplice Municipality",
    country_code: "SI",
    lat: 46.6856932,
    lon: 16.2224582,
  },
  {
    art_state_id: "ASTDE7A2D318A",
    state_code: "067",
    name: "Luče Municipality",
    country_code: "SI",
    lat: 46.3544925,
    lon: 14.7471504,
  },
  {
    art_state_id: "AST0CCB0D44AC",
    state_code: "075",
    name: "Miren–Kostanjevica Municipality",
    country_code: "SI",
    lat: 45.8436029,
    lon: 13.6276647,
  },
  {
    art_state_id: "AST0974B46707",
    state_code: "087",
    name: "Ormož Municipality",
    country_code: "SI",
    lat: 46.4353333,
    lon: 16.154374,
  },
  {
    art_state_id: "AST31EA181D2A",
    state_code: "033",
    name: "Šalovci Municipality",
    country_code: "SI",
    lat: 46.8533568,
    lon: 16.2591791,
  },
  {
    art_state_id: "AST5A8686543B",
    state_code: "169",
    name: "Miklavž na Dravskem Polju Municipality",
    country_code: "SI",
    lat: 46.5082628,
    lon: 15.6952065,
  },
  {
    art_state_id: "ASTBDEBA6891D",
    state_code: "198",
    name: "Makole Municipality",
    country_code: "SI",
    lat: 46.3168697,
    lon: 15.6664126,
  },
  {
    art_state_id: "ASTC78924FFEE",
    state_code: "059",
    name: "Lendava Municipality",
    country_code: "SI",
    lat: 46.5513483,
    lon: 16.4419839,
  },
  {
    art_state_id: "AST3FC2C23A5A",
    state_code: "141",
    name: "Vuzenica Municipality",
    country_code: "SI",
    lat: 46.5980836,
    lon: 15.1657237,
  },
  {
    art_state_id: "AST9506476B7F",
    state_code: "044",
    name: "Kanal ob Soči Municipality",
    country_code: "SI",
    lat: 46.067353,
    lon: 13.620335,
  },
  {
    art_state_id: "AST9C4DDD1126",
    state_code: "096",
    name: "Ptuj City Municipality",
    country_code: "SI",
    lat: 46.4199535,
    lon: 15.8696884,
  },
  {
    art_state_id: "ASTFABB6E41B9",
    state_code: "182",
    name: "Sveti Andraž v Slovenskih Goricah Municipality",
    country_code: "SI",
    lat: 46.5189747,
    lon: 15.9498262,
  },
  {
    art_state_id: "AST8A2B2B183D",
    state_code: "178",
    name: "Selnica ob Dravi Municipality",
    country_code: "SI",
    lat: 46.5513918,
    lon: 15.492941,
  },
  {
    art_state_id: "AST0D8EAC8B9A",
    state_code: "102",
    name: "Radovljica Municipality",
    country_code: "SI",
    lat: 46.3355827,
    lon: 14.2094534,
  },
  {
    art_state_id: "AST182D503EFD",
    state_code: "016",
    name: "Črna na Koroškem Municipality",
    country_code: "SI",
    lat: 46.4704529,
    lon: 14.8499998,
  },
  {
    art_state_id: "AST02587E9029",
    state_code: "106",
    name: "Rogaška Slatina Municipality",
    country_code: "SI",
    lat: 46.2453973,
    lon: 15.6265014,
  },
  {
    art_state_id: "AST3900DCEDAC",
    state_code: "093",
    name: "Podvelka Municipality",
    country_code: "SI",
    lat: 46.6221952,
    lon: 15.3889922,
  },
  {
    art_state_id: "AST65CD993A68",
    state_code: "104",
    name: "Ribnica Municipality",
    country_code: "SI",
    lat: 45.7400303,
    lon: 14.7265782,
  },
  {
    art_state_id: "AST9519066770",
    state_code: "085",
    name: "City Municipality of Novo Mesto",
    country_code: "SI",
    lat: 45.8010824,
    lon: 15.1710089,
  },
  {
    art_state_id: "AST2D559363CD",
    state_code: "170",
    name: "Mirna Peč Municipality",
    country_code: "SI",
    lat: 45.8481574,
    lon: 15.087945,
  },
  {
    art_state_id: "AST4CB8069DE8",
    state_code: "166",
    name: "Križevci Municipality",
    country_code: "SI",
    lat: 46.5701821,
    lon: 16.1092653,
  },
  {
    art_state_id: "ASTE2A5FA5F53",
    state_code: "200",
    name: "Poljčane Municipality",
    country_code: "SI",
    lat: 46.3139853,
    lon: 15.5784791,
  },
  {
    art_state_id: "AST7A19BF6238",
    state_code: "007",
    name: "Brda Municipality",
    country_code: "SI",
    lat: 45.9975652,
    lon: 13.5270474,
  },
  {
    art_state_id: "AST959832EBDD",
    state_code: "119",
    name: "Šentjernej Municipality",
    country_code: "SI",
    lat: 45.843413,
    lon: 15.3378312,
  },
  {
    art_state_id: "AST45C107383C",
    state_code: "070",
    name: "Maribor City Municipality",
    country_code: "SI",
    lat: 46.5506496,
    lon: 15.6205439,
  },
  {
    art_state_id: "ASTF34B5C1E4A",
    state_code: "046",
    name: "Kobarid Municipality",
    country_code: "SI",
    lat: 46.2456971,
    lon: 13.5786949,
  },
  {
    art_state_id: "AST440A7190B3",
    state_code: "168",
    name: "Markovci Municipality",
    country_code: "SI",
    lat: 46.3879309,
    lon: 15.9586014,
  },
  {
    art_state_id: "ASTAF0AD8DD7D",
    state_code: "139",
    name: "Vojnik Municipality",
    country_code: "SI",
    lat: 46.2920581,
    lon: 15.302058,
  },
  {
    art_state_id: "AST7C67564A3D",
    state_code: "129",
    name: "Trbovlje Municipality",
    country_code: "SI",
    lat: 46.1503563,
    lon: 15.0453137,
  },
  {
    art_state_id: "AST542EA72D7D",
    state_code: "128",
    name: "Tolmin Municipality",
    country_code: "SI",
    lat: 46.1857188,
    lon: 13.7319838,
  },
  {
    art_state_id: "AST1781406771",
    state_code: "126",
    name: "Šoštanj Municipality",
    country_code: "SI",
    lat: 46.3782836,
    lon: 15.0461378,
  },
  {
    art_state_id: "AST11580F9787",
    state_code: "191",
    name: "Žetale Municipality",
    country_code: "SI",
    lat: 46.2742833,
    lon: 15.7913359,
  },
  {
    art_state_id: "ASTE8656C1AC3",
    state_code: "131",
    name: "Tržič Municipality",
    country_code: "SI",
    lat: 46.3593514,
    lon: 14.3006623,
  },
  {
    art_state_id: "ASTE0C2A64FAE",
    state_code: "132",
    name: "Turnišče Municipality",
    country_code: "SI",
    lat: 46.6137504,
    lon: 16.32021,
  },
  {
    art_state_id: "AST54D0B0CB23",
    state_code: "155",
    name: "Dobrna Municipality",
    country_code: "SI",
    lat: 46.3356141,
    lon: 15.2259732,
  },
  {
    art_state_id: "ASTE2219C789F",
    state_code: "201",
    name: "Renče–Vogrsko Municipality",
    country_code: "SI",
    lat: 45.8954617,
    lon: 13.6785673,
  },
  {
    art_state_id: "AST71022A96A4",
    state_code: "197",
    name: "Kostanjevica na Krki Municipality",
    country_code: "SI",
    lat: 45.8316638,
    lon: 15.4411906,
  },
  {
    art_state_id: "ASTF82B7FCC92",
    state_code: "116",
    name: "Sveti Jurij ob Ščavnici Municipality",
    country_code: "SI",
    lat: 46.5687452,
    lon: 16.0222528,
  },
  {
    art_state_id: "AST3C0D338618",
    state_code: "146",
    name: "Železniki Municipality",
    country_code: "SI",
    lat: 46.2256377,
    lon: 14.1693617,
  },
  {
    art_state_id: "ASTF8A71E001F",
    state_code: "188",
    name: "Veržej Municipality",
    country_code: "SI",
    lat: 46.5841135,
    lon: 16.16208,
  },
  {
    art_state_id: "AST0F0BD3C0ED",
    state_code: "190",
    name: "Žalec Municipality",
    country_code: "SI",
    lat: 46.2519712,
    lon: 15.1650072,
  },
  {
    art_state_id: "ASTE40A7C95DC",
    state_code: "115",
    name: "Starše Municipality",
    country_code: "SI",
    lat: 46.4674331,
    lon: 15.7640546,
  },
  {
    art_state_id: "AST601084C05C",
    state_code: "204",
    name: "Sveta Trojica v Slovenskih Goricah Municipality",
    country_code: "SI",
    lat: 46.5680809,
    lon: 15.8823064,
  },
  {
    art_state_id: "AST3AF0E831DB",
    state_code: "180",
    name: "Solčava Municipality",
    country_code: "SI",
    lat: 46.4023526,
    lon: 14.6802304,
  },
  {
    art_state_id: "ASTBEAB83E5A9",
    state_code: "140",
    name: "Vrhnika Municipality",
    country_code: "SI",
    lat: 45.9502719,
    lon: 14.3276422,
  },
  {
    art_state_id: "ASTF1138E9267",
    state_code: "202",
    name: "Središče ob Dravi",
    country_code: "SI",
    lat: 46.3959282,
    lon: 16.2704915,
  },
  {
    art_state_id: "AST861A8A91E0",
    state_code: "105",
    name: "Rogašovci Municipality",
    country_code: "SI",
    lat: 46.8055785,
    lon: 16.0345237,
  },
  {
    art_state_id: "AST07C9F4DA0B",
    state_code: "074",
    name: "Mežica Municipality",
    country_code: "SI",
    lat: 46.5215027,
    lon: 14.852134,
  },
  {
    art_state_id: "ASTB28375D54E",
    state_code: "042",
    name: "Juršinci Municipality",
    country_code: "SI",
    lat: 46.4898651,
    lon: 15.980923,
  },
  {
    art_state_id: "ASTD9F6DD7598",
    state_code: "187",
    name: "Velika Polana Municipality",
    country_code: "SI",
    lat: 46.5731715,
    lon: 16.3444126,
  },
  {
    art_state_id: "AST9939FD625F",
    state_code: "110",
    name: "Sevnica Municipality",
    country_code: "SI",
    lat: 46.0070317,
    lon: 15.3045679,
  },
  {
    art_state_id: "ASTCE8364CEE8",
    state_code: "142",
    name: "Zagorje ob Savi Municipality",
    country_code: "SI",
    lat: 46.1345202,
    lon: 14.9964384,
  },
  {
    art_state_id: "ASTFEC3E4EDF9",
    state_code: "061",
    name: "Ljubljana City Municipality",
    country_code: "SI",
    lat: 46.0569465,
    lon: 14.5057515,
  },
  {
    art_state_id: "ASTC073D1D6B6",
    state_code: "031",
    name: "Gornji Petrovci Municipality",
    country_code: "SI",
    lat: 46.8037128,
    lon: 16.2191379,
  },
  {
    art_state_id: "AST6ED251D59D",
    state_code: "173",
    name: "Polzela Municipality",
    country_code: "SI",
    lat: 46.280897,
    lon: 15.0737321,
  },
  {
    art_state_id: "AST7CF2DCC2E3",
    state_code: "205",
    name: "Sveti Tomaž Municipality",
    country_code: "SI",
    lat: 46.4835283,
    lon: 16.079442,
  },
  {
    art_state_id: "AST109A2B936B",
    state_code: "175",
    name: "Prevalje Municipality",
    country_code: "SI",
    lat: 46.5621146,
    lon: 14.8847861,
  },
  {
    art_state_id: "AST8CB9289735",
    state_code: "101",
    name: "Radlje ob Dravi Municipality",
    country_code: "SI",
    lat: 46.6135732,
    lon: 15.2354438,
  },
  {
    art_state_id: "AST473110DFB8",
    state_code: "192",
    name: "Žirovnica Municipality",
    country_code: "SI",
    lat: 46.3954403,
    lon: 14.1539632,
  },
  {
    art_state_id: "ASTADE6682F41",
    state_code: "179",
    name: "Sodražica Municipality",
    country_code: "SI",
    lat: 45.7616565,
    lon: 14.6352853,
  },
  {
    art_state_id: "ASTE5271652B0",
    state_code: "150",
    name: "Bloke Municipality",
    country_code: "SI",
    lat: 45.7728141,
    lon: 14.5063459,
  },
  {
    art_state_id: "ASTF8828E7D67",
    state_code: "194",
    name: "Šmartno pri Litiji Municipality",
    country_code: "SI",
    lat: 46.0454971,
    lon: 14.8410133,
  },
  {
    art_state_id: "AST026939F567",
    state_code: "108",
    name: "Ruše Municipality",
    country_code: "SI",
    lat: 46.5206265,
    lon: 15.4817869,
  },
  {
    art_state_id: "AST7470F7ED46",
    state_code: "157",
    name: "Dolenjske Toplice Municipality",
    country_code: "SI",
    lat: 45.7345711,
    lon: 15.0129493,
  },
  {
    art_state_id: "AST3B0CBA4826",
    state_code: "004",
    name: "Bohinj Municipality",
    country_code: "SI",
    lat: 46.3005652,
    lon: 13.9427195,
  },
  {
    art_state_id: "AST8647806E32",
    state_code: "164",
    name: "Komenda Municipality",
    country_code: "SI",
    lat: 46.206488,
    lon: 14.5382499,
  },
  {
    art_state_id: "AST647C02763C",
    state_code: "207",
    name: "Gorje Municipality",
    country_code: "SI",
    lat: 46.3802458,
    lon: 14.0685339,
  },
  {
    art_state_id: "AST645DDBC43C",
    state_code: "124",
    name: "Šmarje pri Jelšah Municipality",
    country_code: "SI",
    lat: 46.2287025,
    lon: 15.5190353,
  },
  {
    art_state_id: "AST1A916AFA5D",
    state_code: "037",
    name: "Ig Municipality",
    country_code: "SI",
    lat: 45.9588868,
    lon: 14.5270528,
  },
  {
    art_state_id: "AST1ED3083838",
    state_code: "052",
    name: "Kranj City Municipality",
    country_code: "SI",
    lat: 46.2585021,
    lon: 14.3543569,
  },
  {
    art_state_id: "AST3926DDF0E8",
    state_code: "097",
    name: "Puconci Municipality",
    country_code: "SI",
    lat: 46.7200418,
    lon: 16.0997792,
  },
  {
    art_state_id: "ASTBC767E9693",
    state_code: "206",
    name: "Šmarješke Toplice Municipality",
    country_code: "SI",
    lat: 45.8680377,
    lon: 15.2347422,
  },
  {
    art_state_id: "AST28D26D6973",
    state_code: "024",
    name: "Dornava Municipality",
    country_code: "SI",
    lat: 46.4443513,
    lon: 15.9889159,
  },
  {
    art_state_id: "ASTD2EF420D45",
    state_code: "017",
    name: "Črnomelj Municipality",
    country_code: "SI",
    lat: 45.5361225,
    lon: 15.1944143,
  },
  {
    art_state_id: "AST307A313B24",
    state_code: "100",
    name: "Radenci Municipality",
    country_code: "SI",
    lat: 46.6231121,
    lon: 16.0506903,
  },
  {
    art_state_id: "AST191E58EFB9",
    state_code: "027",
    name: "Gorenja Vas–Poljane Municipality",
    country_code: "SI",
    lat: 46.1116582,
    lon: 14.1149348,
  },
  {
    art_state_id: "ASTF4FA2D9F84",
    state_code: "062",
    name: "Ljubno Municipality",
    country_code: "SI",
    lat: 46.3443125,
    lon: 14.8335492,
  },
  {
    art_state_id: "ASTF4A8FC1179",
    state_code: "154",
    name: "Dobje Municipality",
    country_code: "SI",
    lat: 46.1370037,
    lon: 15.394129,
  },
  {
    art_state_id: "AST138C972243",
    state_code: "125",
    name: "Šmartno ob Paki Municipality",
    country_code: "SI",
    lat: 46.3290372,
    lon: 15.0333937,
  },
  {
    art_state_id: "AST8387B75018",
    state_code: "199",
    name: "Mokronog–Trebelno Municipality",
    country_code: "SI",
    lat: 45.9088529,
    lon: 15.1596736,
  },
  {
    art_state_id: "AST706F970F67",
    state_code: "212",
    name: "Mirna Municipality",
    country_code: "SI",
    lat: 45.9515647,
    lon: 15.0620977,
  },
  {
    art_state_id: "AST97DCE68044",
    state_code: "117",
    name: "Šenčur Municipality",
    country_code: "SI",
    lat: 46.2433699,
    lon: 14.4192223,
  },
  {
    art_state_id: "AST0EA1E2B61D",
    state_code: "135",
    name: "Videm Municipality",
    country_code: "SI",
    lat: 46.363833,
    lon: 15.8781212,
  },
  {
    art_state_id: "ASTA9EAE09CEC",
    state_code: "002",
    name: "Beltinci Municipality",
    country_code: "SI",
    lat: 46.6079153,
    lon: 16.2365127,
  },
  {
    art_state_id: "ASTDA3B6263C0",
    state_code: "068",
    name: "Lukovica Municipality",
    country_code: "SI",
    lat: 46.1696293,
    lon: 14.6907259,
  },
  {
    art_state_id: "AST329FAE5F6D",
    state_code: "095",
    name: "Preddvor Municipality",
    country_code: "SI",
    lat: 46.3017139,
    lon: 14.4218165,
  },
  {
    art_state_id: "AST7EDC1751A5",
    state_code: "018",
    name: "Destrnik Municipality",
    country_code: "SI",
    lat: 46.4922368,
    lon: 15.8777956,
  },
  {
    art_state_id: "AST12A27466B3",
    state_code: "039",
    name: "Ivančna Gorica Municipality",
    country_code: "SI",
    lat: 45.9395841,
    lon: 14.8047626,
  },
  {
    art_state_id: "AST8E1948B677",
    state_code: "208",
    name: "Log–Dragomer Municipality",
    country_code: "SI",
    lat: 46.0178747,
    lon: 14.3687767,
  },
  {
    art_state_id: "AST21483C6792",
    state_code: "193",
    name: "Žužemberk Municipality",
    country_code: "SI",
    lat: 45.820035,
    lon: 14.9535919,
  },
  {
    art_state_id: "AST7507F5780B",
    state_code: "021",
    name: "Dobrova–Polhov Gradec Municipality",
    country_code: "SI",
    lat: 46.0648896,
    lon: 14.3168195,
  },
  {
    art_state_id: "ASTBB0D0E3F0C",
    state_code: "196",
    name: "Municipality of Cirkulane",
    country_code: "SI",
    lat: 46.3298322,
    lon: 15.9980666,
  },
  {
    art_state_id: "AST2F1AADF551",
    state_code: "012",
    name: "Cerklje na Gorenjskem Municipality",
    country_code: "SI",
    lat: 46.2517054,
    lon: 14.4857979,
  },
  {
    art_state_id: "AST37B5636CF9",
    state_code: "211",
    name: "Šentrupert Municipality",
    country_code: "SI",
    lat: 45.9873142,
    lon: 15.0829783,
  },
  {
    art_state_id: "AST9519833C1D",
    state_code: "010",
    name: "Tišina Municipality",
    country_code: "SI",
    lat: 46.6541884,
    lon: 16.0754781,
  },
  {
    art_state_id: "AST5B750D8E85",
    state_code: "080",
    name: "Murska Sobota City Municipality",
    country_code: "SI",
    lat: 46.6432147,
    lon: 16.1515754,
  },
  {
    art_state_id: "AST84548DDFEE",
    state_code: "054",
    name: "Municipality of Krško",
    country_code: "SI",
    lat: 45.9589609,
    lon: 15.4923555,
  },
  {
    art_state_id: "AST1F220A6E52",
    state_code: "049",
    name: "Komen Municipality",
    country_code: "SI",
    lat: 45.8175235,
    lon: 13.7482711,
  },
  {
    art_state_id: "ASTB856F711D0",
    state_code: "122",
    name: "Škofja Loka Municipality",
    country_code: "SI",
    lat: 46.1409844,
    lon: 14.2811873,
  },
  {
    art_state_id: "ASTBBE45EF1CD",
    state_code: "183",
    name: "Šempeter–Vrtojba Municipality",
    country_code: "SI",
    lat: 45.9290095,
    lon: 13.6415594,
  },
  {
    art_state_id: "AST5DC20A1AAA",
    state_code: "195",
    name: "Municipality of Apače",
    country_code: "SI",
    lat: 46.6974679,
    lon: 15.9102534,
  },
  {
    art_state_id: "AST7D617311D3",
    state_code: "050",
    name: "Koper City Municipality",
    country_code: "SI",
    lat: 45.548059,
    lon: 13.7301877,
  },
  {
    art_state_id: "ASTF7DA636C17",
    state_code: "086",
    name: "Odranci Municipality",
    country_code: "SI",
    lat: 46.5901017,
    lon: 16.2788165,
  },
  {
    art_state_id: "ASTC21C88CEAD",
    state_code: "035",
    name: "Hrpelje–Kozina Municipality",
    country_code: "SI",
    lat: 45.6091192,
    lon: 13.9379148,
  },
  {
    art_state_id: "AST975A3AFFF5",
    state_code: "040",
    name: "Izola Municipality",
    country_code: "SI",
    lat: 45.5313557,
    lon: 13.6664649,
  },
  {
    art_state_id: "ASTE466ED2356",
    state_code: "073",
    name: "Metlika Municipality",
    country_code: "SI",
    lat: 45.6480715,
    lon: 15.3177838,
  },
  {
    art_state_id: "AST5B00E1D4F7",
    state_code: "118",
    name: "Šentilj Municipality",
    country_code: "SI",
    lat: 46.6862839,
    lon: 15.7103567,
  },
  {
    art_state_id: "AST8BEE712F96",
    state_code: "047",
    name: "Kobilje Municipality",
    country_code: "SI",
    lat: 46.68518,
    lon: 16.3936719,
  },
  {
    art_state_id: "AST329C2F5F4B",
    state_code: "213",
    name: "Ankaran Municipality",
    country_code: "SI",
    lat: 45.578451,
    lon: 13.7369174,
  },
  {
    art_state_id: "AST305630D028",
    state_code: "161",
    name: "Hodoš Municipality",
    country_code: "SI",
    lat: 46.8314134,
    lon: 16.321068,
  },
  {
    art_state_id: "AST852D8D82C6",
    state_code: "210",
    name: "Sveti Jurij v Slovenskih Goricah Municipality",
    country_code: "SI",
    lat: 46.6170791,
    lon: 15.7804677,
  },
  {
    art_state_id: "AST717C0D369E",
    state_code: "083",
    name: "Nazarje Municipality",
    country_code: "SI",
    lat: 46.2821741,
    lon: 14.9225629,
  },
  {
    art_state_id: "AST482B112556",
    state_code: "094",
    name: "Postojna Municipality",
    country_code: "SI",
    lat: 45.774939,
    lon: 14.2134263,
  },
  {
    art_state_id: "AST2B7778B16A",
    state_code: "165",
    name: "Kostel Municipality",
    country_code: "SI",
    lat: 45.4928255,
    lon: 14.8708235,
  },
  {
    art_state_id: "AST1C2888A1F8",
    state_code: "113",
    name: "Slovenska Bistrica Municipality",
    country_code: "SI",
    lat: 46.3919813,
    lon: 15.5727869,
  },
  {
    art_state_id: "AST720143DDF3",
    state_code: "203",
    name: "Straža Municipality",
    country_code: "SI",
    lat: 45.7768428,
    lon: 15.0948694,
  },
  {
    art_state_id: "AST22C8713709",
    state_code: "186",
    name: "Trzin Municipality",
    country_code: "SI",
    lat: 46.1298241,
    lon: 14.5577637,
  },
  {
    art_state_id: "AST73DDDE8855",
    state_code: "048",
    name: "Kočevje Municipality",
    country_code: "SI",
    lat: 45.6428,
    lon: 14.8615838,
  },
  {
    art_state_id: "AST16D608BB3D",
    state_code: "032",
    name: "Grosuplje Municipality",
    country_code: "SI",
    lat: 45.9557645,
    lon: 14.658899,
  },
  {
    art_state_id: "AST4899BD358A",
    state_code: "041",
    name: "Jesenice Municipality",
    country_code: "SI",
    lat: 46.4367047,
    lon: 14.0526057,
  },
  {
    art_state_id: "ASTDF824DA88B",
    state_code: "057",
    name: "Laško Municipality",
    country_code: "SI",
    lat: 46.1542236,
    lon: 15.2361491,
  },
  {
    art_state_id: "ASTA44BBCC7D1",
    state_code: "030",
    name: "Gornji Grad Municipality",
    country_code: "SI",
    lat: 46.2961712,
    lon: 14.8062347,
  },
  {
    art_state_id: "AST6FA233493C",
    state_code: "053",
    name: "Kranjska Gora Municipality",
    country_code: "SI",
    lat: 46.4845293,
    lon: 13.7857145,
  },
  {
    art_state_id: "ASTE782110869",
    state_code: "034",
    name: "Hrastnik Municipality",
    country_code: "SI",
    lat: 46.1417288,
    lon: 15.0844894,
  },
  {
    art_state_id: "AST39AA30D6EF",
    state_code: "144",
    name: "Zreče Municipality",
    country_code: "SI",
    lat: 46.4177786,
    lon: 15.3709431,
  },
  {
    art_state_id: "ASTF2C21A36BF",
    state_code: "029",
    name: "Gornja Radgona Municipality",
    country_code: "SI",
    lat: 46.6767099,
    lon: 15.9910847,
  },
  {
    art_state_id: "ASTCF83F5264D",
    state_code: "038",
    name: "Municipality of Ilirska Bistrica",
    country_code: "SI",
    lat: 45.5791323,
    lon: 14.2809729,
  },
  {
    art_state_id: "AST6FD773969B",
    state_code: "025",
    name: "Dravograd Municipality",
    country_code: "SI",
    lat: 46.589219,
    lon: 15.0246021,
  },
  {
    art_state_id: "ASTE749887CB6",
    state_code: "109",
    name: "Semič Municipality",
    country_code: "SI",
    lat: 45.6520534,
    lon: 15.1820701,
  },
  {
    art_state_id: "ASTB03FBCE7FB",
    state_code: "060",
    name: "Litija Municipality",
    country_code: "SI",
    lat: 46.0573226,
    lon: 14.8309636,
  },
  {
    art_state_id: "AST2D9BC680FE",
    state_code: "072",
    name: "Mengeš Municipality",
    country_code: "SI",
    lat: 46.1659122,
    lon: 14.5719694,
  },
  {
    art_state_id: "AST5D18A0E8C3",
    state_code: "071",
    name: "Medvode Municipality",
    country_code: "SI",
    lat: 46.141908,
    lon: 14.4032596,
  },
  {
    art_state_id: "AST47A8C16CDB",
    state_code: "064",
    name: "Logatec Municipality",
    country_code: "SI",
    lat: 45.917611,
    lon: 14.2351451,
  },
  {
    art_state_id: "AST2C2E484EF4",
    state_code: "063",
    name: "Ljutomer Municipality",
    country_code: "SI",
    lat: 46.5190848,
    lon: 16.1893216,
  },
  {
    art_state_id: "AST1FC352B339",
    state_code: "BC",
    name: "Banská Bystrica Region",
    country_code: "SK",
    lat: 48.5312499,
    lon: 19.382874,
  },
  {
    art_state_id: "AST746A13CB01",
    state_code: "KI",
    name: "Košice Region",
    country_code: "SK",
    lat: 48.6375737,
    lon: 21.0834225,
  },
  {
    art_state_id: "AST82E8B37E8E",
    state_code: "PV",
    name: "Prešov Region",
    country_code: "SK",
    lat: 49.1716773,
    lon: 21.3742001,
  },
  {
    art_state_id: "AST3F2A32D70F",
    state_code: "TA",
    name: "Trnava Region",
    country_code: "SK",
    lat: 48.3943898,
    lon: 17.7216205,
  },
  {
    art_state_id: "AST9A530F95EE",
    state_code: "BL",
    name: "Bratislava Region",
    country_code: "SK",
    lat: 48.3118304,
    lon: 17.1973299,
  },
  {
    art_state_id: "AST305FADCBA8",
    state_code: "NI",
    name: "Nitra Region",
    country_code: "SK",
    lat: 48.0143765,
    lon: 18.5416504,
  },
  {
    art_state_id: "AST3019DE5354",
    state_code: "TC",
    name: "Trenčín Region",
    country_code: "SK",
    lat: 48.8086758,
    lon: 18.2324026,
  },
  {
    art_state_id: "ASTD53A298F11",
    state_code: "ZI",
    name: "Žilina Region",
    country_code: "SK",
    lat: 49.2031435,
    lon: 19.3645733,
  },
  {
    art_state_id: "ASTFD44180C6E",
    state_code: "CM",
    name: "Cimișlia District",
    country_code: "MD",
    lat: 46.5250851,
    lon: 28.7721835,
  },
  {
    art_state_id: "ASTA913420FF4",
    state_code: "OR",
    name: "Orhei District",
    country_code: "MD",
    lat: 47.38604,
    lon: 28.8303082,
  },
  {
    art_state_id: "AST4DFFAE7D67",
    state_code: "BD",
    name: "Bender Municipality",
    country_code: "MD",
    lat: 46.8227551,
    lon: 29.4620101,
  },
  {
    art_state_id: "AST92443D1F4F",
    state_code: "NI",
    name: "Nisporeni District",
    country_code: "MD",
    lat: 47.0751349,
    lon: 28.1768155,
  },
  {
    art_state_id: "ASTE3FADA8257",
    state_code: "SI",
    name: "Sîngerei District",
    country_code: "MD",
    lat: 47.6389134,
    lon: 28.1371816,
  },
  {
    art_state_id: "AST146C286102",
    state_code: "CS",
    name: "Căușeni District",
    country_code: "MD",
    lat: 46.6554715,
    lon: 29.4091222,
  },
  {
    art_state_id: "ASTF322B5F9C5",
    state_code: "CL",
    name: "Călărași District",
    country_code: "MD",
    lat: 47.286946,
    lon: 28.274531,
  },
  {
    art_state_id: "AST271A2DFB7D",
    state_code: "GL",
    name: "Glodeni District",
    country_code: "MD",
    lat: 47.7790156,
    lon: 27.516801,
  },
  {
    art_state_id: "AST0B2C739D0B",
    state_code: "AN",
    name: "Anenii Noi District",
    country_code: "MD",
    lat: 46.8795663,
    lon: 29.2312175,
  },
  {
    art_state_id: "AST3B8E416BCE",
    state_code: "IA",
    name: "Ialoveni District",
    country_code: "MD",
    lat: 46.863086,
    lon: 28.8234218,
  },
  {
    art_state_id: "AST735ACCC4F3",
    state_code: "FL",
    name: "Florești District",
    country_code: "MD",
    lat: 47.8667849,
    lon: 28.3391864,
  },
  {
    art_state_id: "AST558C6DF1DD",
    state_code: "TE",
    name: "Telenești District",
    country_code: "MD",
    lat: 47.4983962,
    lon: 28.3676019,
  },
  {
    art_state_id: "AST248A32E072",
    state_code: "TA",
    name: "Taraclia District",
    country_code: "MD",
    lat: 45.898651,
    lon: 28.6671644,
  },
  {
    art_state_id: "ASTE1CF17BFF7",
    state_code: "CU",
    name: "Chișinău Municipality",
    country_code: "MD",
    lat: 47.0104529,
    lon: 28.8638102,
  },
  {
    art_state_id: "AST601F9022E0",
    state_code: "SO",
    name: "Soroca District",
    country_code: "MD",
    lat: 48.1549743,
    lon: 28.2870783,
  },
  {
    art_state_id: "AST3434231BFF",
    state_code: "BR",
    name: "Briceni District",
    country_code: "MD",
    lat: 48.3632022,
    lon: 27.0750398,
  },
  {
    art_state_id: "AST8A0B27950F",
    state_code: "RI",
    name: "Rîșcani District",
    country_code: "MD",
    lat: 47.9070153,
    lon: 27.5374996,
  },
  {
    art_state_id: "ASTC5AEF3E74E",
    state_code: "ST",
    name: "Strășeni District",
    country_code: "MD",
    lat: 47.1450267,
    lon: 28.6136736,
  },
  {
    art_state_id: "AST635F4CF4CD",
    state_code: "SV",
    name: "Ștefan Vodă District",
    country_code: "MD",
    lat: 46.5540488,
    lon: 29.702242,
  },
  {
    art_state_id: "AST61CA34E5F7",
    state_code: "BS",
    name: "Basarabeasca District",
    country_code: "MD",
    lat: 46.423706,
    lon: 28.8935492,
  },
  {
    art_state_id: "AST30EF886A4C",
    state_code: "CT",
    name: "Cantemir District",
    country_code: "MD",
    lat: 46.2771742,
    lon: 28.2009653,
  },
  {
    art_state_id: "AST90401CAD41",
    state_code: "FA",
    name: "Fălești District",
    country_code: "MD",
    lat: 47.5647725,
    lon: 27.7265593,
  },
  {
    art_state_id: "AST2E28902B19",
    state_code: "HI",
    name: "Hîncești District",
    country_code: "MD",
    lat: 46.8281147,
    lon: 28.5850889,
  },
  {
    art_state_id: "ASTE5A8C06828",
    state_code: "DU",
    name: "Dubăsari District",
    country_code: "MD",
    lat: 47.2643942,
    lon: 29.1550348,
  },
  {
    art_state_id: "ASTE329205023",
    state_code: "DO",
    name: "Dondușeni District",
    country_code: "MD",
    lat: 48.2338305,
    lon: 27.5998087,
  },
  {
    art_state_id: "AST13F64726D2",
    state_code: "GA",
    name: "Gagauzia",
    country_code: "MD",
    lat: 46.0979435,
    lon: 28.6384645,
  },
  {
    art_state_id: "AST4A077BEB9E",
    state_code: "UN",
    name: "Ungheni District",
    country_code: "MD",
    lat: 47.2305767,
    lon: 27.7892661,
  },
  {
    art_state_id: "AST7C4040CCD8",
    state_code: "ED",
    name: "Edineț District",
    country_code: "MD",
    lat: 48.1678991,
    lon: 27.2936143,
  },
  {
    art_state_id: "AST1CB68276D7",
    state_code: "SD",
    name: "Șoldănești District",
    country_code: "MD",
    lat: 47.8147389,
    lon: 28.7889586,
  },
  {
    art_state_id: "ASTA3186E6817",
    state_code: "OC",
    name: "Ocnița District",
    country_code: "MD",
    lat: 48.4110435,
    lon: 27.4768092,
  },
  {
    art_state_id: "ASTE44992BC3B",
    state_code: "CR",
    name: "Criuleni District",
    country_code: "MD",
    lat: 47.2136114,
    lon: 29.1557519,
  },
  {
    art_state_id: "ASTD703D64B83",
    state_code: "CA",
    name: "Cahul District",
    country_code: "MD",
    lat: 45.8939404,
    lon: 28.1890275,
  },
  {
    art_state_id: "AST95B6D63FA7",
    state_code: "DR",
    name: "Drochia District",
    country_code: "MD",
    lat: 48.0797788,
    lon: 27.8604114,
  },
  {
    art_state_id: "AST95475381B7",
    state_code: "BA",
    name: "Bălți Municipality",
    country_code: "MD",
    lat: 47.7539947,
    lon: 27.9184148,
  },
  {
    art_state_id: "ASTF07BCBFF72",
    state_code: "RE",
    name: "Rezina District",
    country_code: "MD",
    lat: 47.7180447,
    lon: 28.8871024,
  },
  {
    art_state_id: "ASTFF32CE470D",
    state_code: "SN",
    name: "Transnistria autonomous territorial unit",
    country_code: "MD",
    lat: 47.2152972,
    lon: 29.4638054,
  },
  {
    art_state_id: "ASTB3104A19C7",
    state_code: "086",
    name: "Salacgrīva Municipality",
    country_code: "LV",
    lat: 57.7580883,
    lon: 24.3543181,
  },
  {
    art_state_id: "AST3A18477A70",
    state_code: "105",
    name: "Vecumnieki Municipality",
    country_code: "LV",
    lat: 56.6062337,
    lon: 24.5221891,
  },
  {
    art_state_id: "AST5A190699D1",
    state_code: "064",
    name: "Naukšēni Municipality",
    country_code: "LV",
    lat: 57.9295361,
    lon: 25.5119266,
  },
  {
    art_state_id: "AST03C59B3E21",
    state_code: "036",
    name: "Ilūkste Municipality",
    country_code: "LV",
    lat: 55.9782547,
    lon: 26.2965088,
  },
  {
    art_state_id: "ASTD0CE5250D4",
    state_code: "033",
    name: "Gulbene Municipality",
    country_code: "LV",
    lat: 57.2155645,
    lon: 26.6452955,
  },
  {
    art_state_id: "ASTE5C81279E8",
    state_code: "056",
    name: "Līvāni Municipality",
    country_code: "LV",
    lat: 56.3550942,
    lon: 26.172519,
  },
  {
    art_state_id: "ASTDD025AA951",
    state_code: "087",
    name: "Salaspils Municipality",
    country_code: "LV",
    lat: 56.8608152,
    lon: 24.3497881,
  },
  {
    art_state_id: "AST415E5FE071",
    state_code: "106",
    name: "Ventspils Municipality",
    country_code: "LV",
    lat: 57.2833682,
    lon: 21.8587558,
  },
  {
    art_state_id: "AST52BC4144A3",
    state_code: "083",
    name: "Rundāle Municipality",
    country_code: "LV",
    lat: 56.409721,
    lon: 24.0124139,
  },
  {
    art_state_id: "AST5314C7FA7A",
    state_code: "072",
    name: "Pļaviņas Municipality",
    country_code: "LV",
    lat: 56.6177313,
    lon: 25.7194043,
  },
  {
    art_state_id: "AST752562AAA2",
    state_code: "103",
    name: "Vārkava Municipality",
    country_code: "LV",
    lat: 56.2465744,
    lon: 26.5664371,
  },
  {
    art_state_id: "AST35B503BAF8",
    state_code: "039",
    name: "Jaunpiebalga Municipality",
    country_code: "LV",
    lat: 57.1433471,
    lon: 25.9951888,
  },
  {
    art_state_id: "ASTBC88BEE6CD",
    state_code: "090",
    name: "Sēja Municipality",
    country_code: "LV",
    lat: 57.2006995,
    lon: 24.5922821,
  },
  {
    art_state_id: "ASTC183C5E294",
    state_code: "099",
    name: "Tukums Municipality",
    country_code: "LV",
    lat: 56.9672868,
    lon: 23.1524379,
  },
  {
    art_state_id: "AST0D15BFBB71",
    state_code: "023",
    name: "Cibla Municipality",
    country_code: "LV",
    lat: 56.6102344,
    lon: 27.8696598,
  },
  {
    art_state_id: "ASTA212159DD9",
    state_code: "019",
    name: "Burtnieki Municipality",
    country_code: "LV",
    lat: 57.6949004,
    lon: 25.2764777,
  },
  {
    art_state_id: "ASTDEC6C80459",
    state_code: "051",
    name: "Ķegums Municipality",
    country_code: "LV",
    lat: 56.7475357,
    lon: 24.7173645,
  },
  {
    art_state_id: "AST7E4DD897AE",
    state_code: "049",
    name: "Krustpils Municipality",
    country_code: "LV",
    lat: 56.5415578,
    lon: 26.2446397,
  },
  {
    art_state_id: "AST325ABB3CCF",
    state_code: "021",
    name: "Cesvaine Municipality",
    country_code: "LV",
    lat: 56.9679264,
    lon: 26.3083172,
  },
  {
    art_state_id: "AST65C8336378",
    state_code: "092",
    name: "Skrīveri Municipality",
    country_code: "LV",
    lat: 56.6761391,
    lon: 25.0978849,
  },
  {
    art_state_id: "AST0B952B3906",
    state_code: "067",
    name: "Ogre Municipality",
    country_code: "LV",
    lat: 56.8147355,
    lon: 24.6044555,
  },
  {
    art_state_id: "ASTF8ABD7A3F8",
    state_code: "068",
    name: "Olaine Municipality",
    country_code: "LV",
    lat: 56.7952353,
    lon: 24.0153589,
  },
  {
    art_state_id: "AST71176672C5",
    state_code: "054",
    name: "Limbaži Municipality",
    country_code: "LV",
    lat: 57.5403227,
    lon: 24.7134451,
  },
  {
    art_state_id: "ASTE349A645BF",
    state_code: "057",
    name: "Lubāna Municipality",
    country_code: "LV",
    lat: 56.8999269,
    lon: 26.7198789,
  },
  {
    art_state_id: "AST15A47C2C29",
    state_code: "043",
    name: "Kandava Municipality",
    country_code: "LV",
    lat: 57.0340673,
    lon: 22.7801813,
  },
  {
    art_state_id: "AST034AC1653D",
    state_code: "VEN",
    name: "Ventspils",
    country_code: "LV",
    lat: 57.3937216,
    lon: 21.5647066,
  },
  {
    art_state_id: "ASTC2381E6C4C",
    state_code: "048",
    name: "Krimulda Municipality",
    country_code: "LV",
    lat: 57.1791273,
    lon: 24.7140127,
  },
  {
    art_state_id: "AST0B7463882B",
    state_code: "082",
    name: "Rugāji Municipality",
    country_code: "LV",
    lat: 57.0056023,
    lon: 27.1317203,
  },
  {
    art_state_id: "ASTBEC7876E50",
    state_code: "041",
    name: "Jelgava Municipality",
    country_code: "LV",
    lat: 56.5895689,
    lon: 23.6610481,
  },
  {
    art_state_id: "AST4FAC41C06B",
    state_code: "101",
    name: "Valka Municipality",
    country_code: "LV",
    lat: 57.77439,
    lon: 26.017005,
  },
  {
    art_state_id: "ASTE978EA27BA",
    state_code: "084",
    name: "Rūjiena Municipality",
    country_code: "LV",
    lat: 57.8937291,
    lon: 25.3391008,
  },
  {
    art_state_id: "AST82683BD572",
    state_code: "012",
    name: "Babīte Municipality",
    country_code: "LV",
    lat: 56.954155,
    lon: 23.945399,
  },
  {
    art_state_id: "AST84F8C15BA6",
    state_code: "027",
    name: "Dundaga Municipality",
    country_code: "LV",
    lat: 57.5049167,
    lon: 22.3505114,
  },
  {
    art_state_id: "AST701DF1746A",
    state_code: "074",
    name: "Priekule Municipality",
    country_code: "LV",
    lat: 56.4179413,
    lon: 21.5503336,
  },
  {
    art_state_id: "AST85E6F80312",
    state_code: "110",
    name: "Zilupe Municipality",
    country_code: "LV",
    lat: 56.3018985,
    lon: 28.133959,
  },
  {
    art_state_id: "AST5FE0AEAB3E",
    state_code: "102",
    name: "Varakļāni Municipality",
    country_code: "LV",
    lat: 56.6688006,
    lon: 26.5636414,
  },
  {
    art_state_id: "AST1552D67EB1",
    state_code: "065",
    name: "Nereta Municipality",
    country_code: "LV",
    lat: 56.1986655,
    lon: 25.3252969,
  },
  {
    art_state_id: "ASTE09D345909",
    state_code: "059",
    name: "Madona Municipality",
    country_code: "LV",
    lat: 56.8598923,
    lon: 26.2276201,
  },
  {
    art_state_id: "AST6BCD323AD4",
    state_code: "085",
    name: "Sala Municipality",
    country_code: "LV",
    lat: 59.9679613,
    lon: 16.4978217,
  },
  {
    art_state_id: "ASTF51E23CE2E",
    state_code: "052",
    name: "Ķekava Municipality",
    country_code: "LV",
    lat: 56.8064351,
    lon: 24.1939493,
  },
  {
    art_state_id: "AST890BDF2A2E",
    state_code: "066",
    name: "Nīca Municipality",
    country_code: "LV",
    lat: 56.3464983,
    lon: 21.065493,
  },
  {
    art_state_id: "AST0BFF539331",
    state_code: "026",
    name: "Dobele Municipality",
    country_code: "LV",
    lat: 56.626305,
    lon: 23.2809066,
  },
  {
    art_state_id: "ASTC4C45B2A92",
    state_code: "042",
    name: "Jēkabpils Municipality",
    country_code: "LV",
    lat: 56.291932,
    lon: 25.9812017,
  },
  {
    art_state_id: "AST30D8971FB4",
    state_code: "088",
    name: "Saldus Municipality",
    country_code: "LV",
    lat: 56.6665088,
    lon: 22.4935493,
  },
  {
    art_state_id: "AST5A33272655",
    state_code: "079",
    name: "Roja Municipality",
    country_code: "LV",
    lat: 57.5046713,
    lon: 22.8012164,
  },
  {
    art_state_id: "AST5CD688FA8A",
    state_code: "034",
    name: "Iecava Municipality",
    country_code: "LV",
    lat: 56.5986793,
    lon: 24.1996272,
  },
  {
    art_state_id: "AST483223832E",
    state_code: "069",
    name: "Ozolnieki Municipality",
    country_code: "LV",
    lat: 56.6756305,
    lon: 23.8994816,
  },
  {
    art_state_id: "ASTE453E962B5",
    state_code: "089",
    name: "Saulkrasti Municipality",
    country_code: "LV",
    lat: 57.2579418,
    lon: 24.4183146,
  },
  {
    art_state_id: "ASTAAF2EB9882",
    state_code: "030",
    name: "Ērgļi Municipality",
    country_code: "LV",
    lat: 56.9237065,
    lon: 25.6753852,
  },
  {
    art_state_id: "ASTF76E46EA1B",
    state_code: "001",
    name: "Aglona Municipality",
    country_code: "LV",
    lat: 56.1089006,
    lon: 27.1286227,
  },
  {
    art_state_id: "ASTCA33FBC2FC",
    state_code: "JUR",
    name: "Jūrmala",
    country_code: "LV",
    lat: 56.947079,
    lon: 23.6168485,
  },
  {
    art_state_id: "AST625060EBC1",
    state_code: "093",
    name: "Skrunda Municipality",
    country_code: "LV",
    lat: 56.6643458,
    lon: 22.0045729,
  },
  {
    art_state_id: "AST1D1B5BA6AD",
    state_code: "029",
    name: "Engure Municipality",
    country_code: "LV",
    lat: 57.16235,
    lon: 23.2196634,
  },
  {
    art_state_id: "AST5309EC1763",
    state_code: "037",
    name: "Inčukalns Municipality",
    country_code: "LV",
    lat: 57.0994342,
    lon: 24.685557,
  },
  {
    art_state_id: "AST9D350918CF",
    state_code: "062",
    name: "Mārupe Municipality",
    country_code: "LV",
    lat: 56.8965717,
    lon: 24.0460049,
  },
  {
    art_state_id: "AST0CAD4D87A6",
    state_code: "063",
    name: "Mērsrags Municipality",
    country_code: "LV",
    lat: 57.3306881,
    lon: 23.1023707,
  },
  {
    art_state_id: "AST95409739F0",
    state_code: "046",
    name: "Koknese Municipality",
    country_code: "LV",
    lat: 56.720556,
    lon: 25.4893909,
  },
  {
    art_state_id: "AST78CC8EC567",
    state_code: "044",
    name: "Kārsava Municipality",
    country_code: "LV",
    lat: 56.7645842,
    lon: 27.7358295,
  },
  {
    art_state_id: "AST141B576A7F",
    state_code: "020",
    name: "Carnikava Municipality",
    country_code: "LV",
    lat: 57.1024121,
    lon: 24.2108662,
  },
  {
    art_state_id: "ASTFD5BD31036",
    state_code: "077",
    name: "Rēzekne Municipality",
    country_code: "LV",
    lat: 56.3273638,
    lon: 27.3284331,
  },
  {
    art_state_id: "ASTBC30BC0920",
    state_code: "107",
    name: "Viesīte Municipality",
    country_code: "LV",
    lat: 56.3113085,
    lon: 25.5070464,
  },
  {
    art_state_id: "ASTE6F2ABF2FB",
    state_code: "009",
    name: "Ape Municipality",
    country_code: "LV",
    lat: 57.5392697,
    lon: 26.6941649,
  },
  {
    art_state_id: "AST81408CD1A1",
    state_code: "028",
    name: "Durbe Municipality",
    country_code: "LV",
    lat: 56.6279857,
    lon: 21.4916245,
  },
  {
    art_state_id: "AST19039AE20B",
    state_code: "097",
    name: "Talsi Municipality",
    country_code: "LV",
    lat: 57.3415208,
    lon: 22.5713125,
  },
  {
    art_state_id: "ASTF7E6218C28",
    state_code: "LPX",
    name: "Liepāja",
    country_code: "LV",
    lat: 56.5046678,
    lon: 21.010806,
  },
  {
    art_state_id: "AST342D74267F",
    state_code: "061",
    name: "Mālpils Municipality",
    country_code: "LV",
    lat: 57.0084119,
    lon: 24.9574278,
  },
  {
    art_state_id: "AST00E9FB4940",
    state_code: "094",
    name: "Smiltene Municipality",
    country_code: "LV",
    lat: 57.4230332,
    lon: 25.900278,
  },
  {
    art_state_id: "AST793E8D79D4",
    state_code: "DGV",
    name: "Daugavpils",
    country_code: "LV",
    lat: 55.874736,
    lon: 26.536179,
  },
  {
    art_state_id: "ASTD6EBEBBE4D",
    state_code: "JKB",
    name: "Jēkabpils",
    country_code: "LV",
    lat: 56.501455,
    lon: 25.878299,
  },
  {
    art_state_id: "AST7BC4167720",
    state_code: "016",
    name: "Bauska Municipality",
    country_code: "LV",
    lat: 56.4101868,
    lon: 24.2000689,
  },
  {
    art_state_id: "ASTE7410F2905",
    state_code: "104",
    name: "Vecpiebalga Municipality",
    country_code: "LV",
    lat: 57.0603356,
    lon: 25.8161592,
  },
  {
    art_state_id: "AST04CE4A3A84",
    state_code: "071",
    name: "Pāvilosta Municipality",
    country_code: "LV",
    lat: 56.8865424,
    lon: 21.1946849,
  },
  {
    art_state_id: "AST73690E92A8",
    state_code: "018",
    name: "Brocēni Municipality",
    country_code: "LV",
    lat: 56.7347541,
    lon: 22.6357371,
  },
  {
    art_state_id: "AST5331DF6660",
    state_code: "022",
    name: "Cēsis Municipality",
    country_code: "LV",
    lat: 57.3102897,
    lon: 25.2676125,
  },
  {
    art_state_id: "ASTE1D3EAB1A3",
    state_code: "032",
    name: "Grobiņa Municipality",
    country_code: "LV",
    lat: 56.539632,
    lon: 21.166892,
  },
  {
    art_state_id: "AST51B52B6C2D",
    state_code: "017",
    name: "Beverīna Municipality",
    country_code: "LV",
    lat: 57.5197109,
    lon: 25.6073654,
  },
  {
    art_state_id: "AST5B6119F270",
    state_code: "002",
    name: "Aizkraukle Municipality",
    country_code: "LV",
    lat: 56.646108,
    lon: 25.2370854,
  },
  {
    art_state_id: "ASTAB0DF8197B",
    state_code: "VMR",
    name: "Valmiera",
    country_code: "LV",
    lat: 57.5384659,
    lon: 25.4263618,
  },
  {
    art_state_id: "AST00898ECC98",
    state_code: "047",
    name: "Krāslava Municipality",
    country_code: "LV",
    lat: 55.8951464,
    lon: 27.1814577,
  },
  {
    art_state_id: "ASTC6CD54F692",
    state_code: "038",
    name: "Jaunjelgava Municipality",
    country_code: "LV",
    lat: 56.5283659,
    lon: 25.3921443,
  },
  {
    art_state_id: "AST477BE5FDD6",
    state_code: "091",
    name: "Sigulda Municipality",
    country_code: "LV",
    lat: 57.1055092,
    lon: 24.8314259,
  },
  {
    art_state_id: "ASTB75A02BA5C",
    state_code: "108",
    name: "Viļaka Municipality",
    country_code: "LV",
    lat: 57.1722263,
    lon: 27.6673188,
  },
  {
    art_state_id: "ASTE7DDE7E5C7",
    state_code: "095",
    name: "Stopiņi Municipality",
    country_code: "LV",
    lat: 56.936449,
    lon: 24.2872949,
  },
  {
    art_state_id: "ASTA5D6FFAA12",
    state_code: "076",
    name: "Rauna Municipality",
    country_code: "LV",
    lat: 57.331693,
    lon: 25.6100339,
  },
  {
    art_state_id: "ASTF619C5AD71",
    state_code: "098",
    name: "Tērvete Municipality",
    country_code: "LV",
    lat: 56.4119201,
    lon: 23.3188332,
  },
  {
    art_state_id: "AST7333381DD9",
    state_code: "010",
    name: "Auce Municipality",
    country_code: "LV",
    lat: 56.460168,
    lon: 22.9054781,
  },
  {
    art_state_id: "AST39136E1199",
    state_code: "013",
    name: "Baldone Municipality",
    country_code: "LV",
    lat: 56.74246,
    lon: 24.3911544,
  },
  {
    art_state_id: "AST5B73A8FD47",
    state_code: "073",
    name: "Preiļi Municipality",
    country_code: "LV",
    lat: 56.1511157,
    lon: 26.7439767,
  },
  {
    art_state_id: "AST5D1BC06A2E",
    state_code: "005",
    name: "Aloja Municipality",
    country_code: "LV",
    lat: 57.767136,
    lon: 24.8770839,
  },
  {
    art_state_id: "AST118BBFAA33",
    state_code: "006",
    name: "Alsunga Municipality",
    country_code: "LV",
    lat: 56.9828531,
    lon: 21.5555919,
  },
  {
    art_state_id: "AST160E993308",
    state_code: "109",
    name: "Viļāni Municipality",
    country_code: "LV",
    lat: 56.5456171,
    lon: 26.9167927,
  },
  {
    art_state_id: "AST9E453EA3EA",
    state_code: "007",
    name: "Alūksne Municipality",
    country_code: "LV",
    lat: 57.4254485,
    lon: 27.0424968,
  },
  {
    art_state_id: "AST544489736D",
    state_code: "055",
    name: "Līgatne Municipality",
    country_code: "LV",
    lat: 57.1944204,
    lon: 25.0940681,
  },
  {
    art_state_id: "ASTF52DAEC834",
    state_code: "040",
    name: "Jaunpils Municipality",
    country_code: "LV",
    lat: 56.7314194,
    lon: 23.0125616,
  },
  {
    art_state_id: "ASTD9C9CBE8FF",
    state_code: "050",
    name: "Kuldīga Municipality",
    country_code: "LV",
    lat: 56.9687257,
    lon: 21.9613739,
  },
  {
    art_state_id: "ASTD68E70B0E9",
    state_code: "RIX",
    name: "Riga",
    country_code: "LV",
    lat: 56.9496487,
    lon: 24.1051865,
  },
  {
    art_state_id: "ASTF9FFF5B9F7",
    state_code: "025",
    name: "Daugavpils Municipality",
    country_code: "LV",
    lat: 55.8991783,
    lon: 26.6102012,
  },
  {
    art_state_id: "AST9387816998",
    state_code: "080",
    name: "Ropaži Municipality",
    country_code: "LV",
    lat: 56.9615786,
    lon: 24.6010476,
  },
  {
    art_state_id: "AST59C28B9B3A",
    state_code: "096",
    name: "Strenči Municipality",
    country_code: "LV",
    lat: 57.6225471,
    lon: 25.8048086,
  },
  {
    art_state_id: "ASTBB4A54E1AD",
    state_code: "045",
    name: "Kocēni Municipality",
    country_code: "LV",
    lat: 57.5226292,
    lon: 25.3349507,
  },
  {
    art_state_id: "ASTE9B6A3A62E",
    state_code: "003",
    name: "Aizpute Municipality",
    country_code: "LV",
    lat: 56.7182596,
    lon: 21.6072759,
  },
  {
    art_state_id: "AST4488D990DC",
    state_code: "008",
    name: "Amata Municipality",
    country_code: "LV",
    lat: 56.9938726,
    lon: 25.2627675,
  },
  {
    art_state_id: "ASTFF41DF6DCA",
    state_code: "014",
    name: "Baltinava Municipality",
    country_code: "LV",
    lat: 56.9458468,
    lon: 27.6441066,
  },
  {
    art_state_id: "AST97A243633C",
    state_code: "004",
    name: "Aknīste Municipality",
    country_code: "LV",
    lat: 56.1613037,
    lon: 25.7484827,
  },
  {
    art_state_id: "ASTFFBAC34674",
    state_code: "JEL",
    name: "Jelgava",
    country_code: "LV",
    lat: 56.6511091,
    lon: 23.7213541,
  },
  {
    art_state_id: "AST8D23D5CE11",
    state_code: "058",
    name: "Ludza Municipality",
    country_code: "LV",
    lat: 56.545959,
    lon: 27.7143199,
  },
  {
    art_state_id: "AST921063812F",
    state_code: "078",
    name: "Riebiņi Municipality",
    country_code: "LV",
    lat: 56.343619,
    lon: 26.8018138,
  },
  {
    art_state_id: "AST8BED570A97",
    state_code: "081",
    name: "Rucava Municipality",
    country_code: "LV",
    lat: 56.1593124,
    lon: 21.1618121,
  },
  {
    art_state_id: "ASTB92A2B7148",
    state_code: "024",
    name: "Dagda Municipality",
    country_code: "LV",
    lat: 56.0956089,
    lon: 27.532459,
  },
  {
    art_state_id: "AST6CBF782380",
    state_code: "015",
    name: "Balvi Municipality",
    country_code: "LV",
    lat: 57.132624,
    lon: 27.2646685,
  },
  {
    art_state_id: "ASTA20DE6074E",
    state_code: "075",
    name: "Priekuļi Municipality",
    country_code: "LV",
    lat: 57.3617138,
    lon: 25.4410423,
  },
  {
    art_state_id: "AST086A461AFA",
    state_code: "070",
    name: "Pārgauja Municipality",
    country_code: "LV",
    lat: 57.3648122,
    lon: 24.9822045,
  },
  {
    art_state_id: "AST0504B2F6EA",
    state_code: "100",
    name: "Vaiņode Municipality",
    country_code: "LV",
    lat: 56.4154271,
    lon: 21.8513984,
  },
  {
    art_state_id: "AST7ACE75BF22",
    state_code: "REZ",
    name: "Rēzekne",
    country_code: "LV",
    lat: 56.5099223,
    lon: 27.3331357,
  },
  {
    art_state_id: "AST8BEC4BFBCA",
    state_code: "031",
    name: "Garkalne Municipality",
    country_code: "LV",
    lat: 57.0190387,
    lon: 24.3826181,
  },
  {
    art_state_id: "AST7A713FCFDB",
    state_code: "035",
    name: "Ikšķile Municipality",
    country_code: "LV",
    lat: 56.8373667,
    lon: 24.4974745,
  },
  {
    art_state_id: "AST90D57F2AFE",
    state_code: "053",
    name: "Lielvārde Municipality",
    country_code: "LV",
    lat: 56.7392976,
    lon: 24.9711618,
  },
  {
    art_state_id: "ASTE3F884DE5F",
    state_code: "060",
    name: "Mazsalaca Municipality",
    country_code: "LV",
    lat: 57.9267749,
    lon: 25.0669895,
  },
  {
    art_state_id: "ASTFE84826318",
    state_code: "VI",
    name: "Viqueque Municipality",
    country_code: "TL",
    lat: -8.8597918,
    lon: 126.3633516,
  },
  {
    art_state_id: "AST79A79B24CF",
    state_code: "LI",
    name: "Liquiçá Municipality",
    country_code: "TL",
    lat: -8.6674095,
    lon: 125.2587964,
  },
  {
    art_state_id: "AST83E2B5A8A0",
    state_code: "ER",
    name: "Ermera District",
    country_code: "TL",
    lat: -8.7524802,
    lon: 125.3987294,
  },
  {
    art_state_id: "AST795F3B6039",
    state_code: "MT",
    name: "Manatuto District",
    country_code: "TL",
    lat: -8.5155608,
    lon: 126.0159255,
  },
  {
    art_state_id: "AST63C2988188",
    state_code: "AN",
    name: "Ainaro Municipality",
    country_code: "TL",
    lat: -9.0113171,
    lon: 125.5220012,
  },
  {
    art_state_id: "AST11826B2E15",
    state_code: "MF",
    name: "Manufahi Municipality",
    country_code: "TL",
    lat: -9.0145495,
    lon: 125.8279959,
  },
  {
    art_state_id: "ASTA98C107A5A",
    state_code: "AL",
    name: "Aileu municipality",
    country_code: "TL",
    lat: -8.7043994,
    lon: 125.6095474,
  },
  {
    art_state_id: "ASTF458734E6F",
    state_code: "BA",
    name: "Baucau Municipality",
    country_code: "TL",
    lat: -8.4714308,
    lon: 126.4575991,
  },
  {
    art_state_id: "ASTC9FD946772",
    state_code: "CO",
    name: "Cova Lima Municipality",
    country_code: "TL",
    lat: -9.2650375,
    lon: 125.2587964,
  },
  {
    art_state_id: "AST9F926A137D",
    state_code: "LA",
    name: "Lautém Municipality",
    country_code: "TL",
    lat: -8.3642307,
    lon: 126.9043845,
  },
  {
    art_state_id: "ASTDB82E8385E",
    state_code: "DI",
    name: "Dili municipality",
    country_code: "TL",
    lat: -8.2449613,
    lon: 125.5876697,
  },
  {
    art_state_id: "AST8C8708F569",
    state_code: "BO",
    name: "Bobonaro Municipality",
    country_code: "TL",
    lat: -8.9655406,
    lon: 125.2587964,
  },
  {
    art_state_id: "ASTD787C7D31E",
    state_code: "350",
    name: "Peleliu",
    country_code: "PW",
    lat: 7.0022906,
    lon: 134.2431628,
  },
  {
    art_state_id: "AST96852763C1",
    state_code: "222",
    name: "Ngardmau",
    country_code: "PW",
    lat: 7.5850486,
    lon: 134.5596089,
  },
  {
    art_state_id: "ASTFB4A2B29D0",
    state_code: "004",
    name: "Airai",
    country_code: "PW",
    lat: 7.3966118,
    lon: 134.5690225,
  },
  {
    art_state_id: "AST780607966A",
    state_code: "050",
    name: "Hatohobei",
    country_code: "PW",
    lat: 3.0070658,
    lon: 131.1237781,
  },
  {
    art_state_id: "ASTD372676F8B",
    state_code: "212",
    name: "Melekeok",
    country_code: "PW",
    lat: 7.5150286,
    lon: 134.5972518,
  },
  {
    art_state_id: "AST70E6E18FE3",
    state_code: "224",
    name: "Ngatpang",
    country_code: "PW",
    lat: 7.4710994,
    lon: 134.5266466,
  },
  {
    art_state_id: "ASTB809D774D9",
    state_code: "150",
    name: "Koror",
    country_code: "PW",
    lat: 7.3375646,
    lon: 134.4889469,
  },
  {
    art_state_id: "AST2CB30D3FE2",
    state_code: "218",
    name: "Ngarchelong",
    country_code: "PW",
    lat: 7.7105469,
    lon: 134.6301646,
  },
  {
    art_state_id: "ASTF34BD93C7A",
    state_code: "228",
    name: "Ngiwal",
    country_code: "PW",
    lat: 7.5614764,
    lon: 134.6160619,
  },
  {
    art_state_id: "ASTC3785E0F5A",
    state_code: "370",
    name: "Sonsorol",
    country_code: "PW",
    lat: 5.3268119,
    lon: 132.2239117,
  },
  {
    art_state_id: "AST71F67E8245",
    state_code: "226",
    name: "Ngchesar",
    country_code: "PW",
    lat: 7.452328,
    lon: 134.5784342,
  },
  {
    art_state_id: "AST7E8B22E8A1",
    state_code: "214",
    name: "Ngaraard",
    country_code: "PW",
    lat: 7.60794,
    lon: 134.6348645,
  },
  {
    art_state_id: "AST6A1E417E09",
    state_code: "010",
    name: "Angaur",
    country_code: "PW",
    lat: 6.909223,
    lon: 134.1387934,
  },
  {
    art_state_id: "AST1FFB88BD54",
    state_code: "100",
    name: "Kayangel",
    country_code: "PW",
    lat: 8.07,
    lon: 134.702778,
  },
  {
    art_state_id: "AST271A747C59",
    state_code: "002",
    name: "Aimeliik",
    country_code: "PW",
    lat: 7.4455859,
    lon: 134.5030878,
  },
  {
    art_state_id: "ASTE4270FEC21",
    state_code: "227",
    name: "Ngeremlengui",
    country_code: "PW",
    lat: 7.5198397,
    lon: 134.5596089,
  },
  {
    art_state_id: "AST5268C6C6B9",
    state_code: "644",
    name: "Břeclav District",
    country_code: "CZ",
    lat: 48.75314,
    lon: 16.8825169,
  },
  {
    art_state_id: "ASTA1234A0EE7",
    state_code: "312",
    name: "Český Krumlov District",
    country_code: "CZ",
    lat: 48.8127354,
    lon: 14.3174657,
  },
  {
    art_state_id: "AST90B5DE4524",
    state_code: "323",
    name: "Plzeň-City District",
    country_code: "CZ",
    lat: 49.7384314,
    lon: 13.3736371,
  },
  {
    art_state_id: "AST41D77AEC37",
    state_code: "643",
    name: "Brno-Country District",
    country_code: "CZ",
    lat: 49.1250138,
    lon: 16.4558824,
  },
  {
    art_state_id: "AST08CBF50B49",
    state_code: "20B",
    name: "Příbram District",
    country_code: "CZ",
    lat: 49.6947959,
    lon: 14.082381,
  },
  {
    art_state_id: "AST1864678173",
    state_code: "532",
    name: "Pardubice District",
    country_code: "CZ",
    lat: 49.9444479,
    lon: 16.2856916,
  },
  {
    art_state_id: "AST6F02DD66FA",
    state_code: "804",
    name: "Nový Jičín District",
    country_code: "CZ",
    lat: 49.5943251,
    lon: 18.0135356,
  },
  {
    art_state_id: "ASTA23355D9EE",
    state_code: "112",
    name: "Prague 12",
    country_code: "CZ",
    lat: 50.0039305,
    lon: 14.4171875,
  },
  {
    art_state_id: "AST4F91714629",
    state_code: "523",
    name: "Náchod District",
    country_code: "CZ",
    lat: 50.4145722,
    lon: 16.1656347,
  },
  {
    art_state_id: "AST757518C2E4",
    state_code: "713",
    name: "Prostějov District",
    country_code: "CZ",
    lat: 49.4418401,
    lon: 17.1277904,
  },
  {
    art_state_id: "AST98DE700476",
    state_code: "72",
    name: "Zlín Region",
    country_code: "CZ",
    lat: 49.2162296,
    lon: 17.7720353,
  },
  {
    art_state_id: "ASTE3F4568130",
    state_code: "422",
    name: "Chomutov District",
    country_code: "CZ",
    lat: 50.4583872,
    lon: 13.301791,
  },
  {
    art_state_id: "AST018908CFD6",
    state_code: "20",
    name: "Central Bohemian Region",
    country_code: "CZ",
    lat: 49.8782223,
    lon: 14.9362955,
  },
  {
    art_state_id: "ASTE2C9ED0ED9",
    state_code: "113",
    name: "Prague 13",
    country_code: "CZ",
    lat: 50.053234,
    lon: 14.3096392,
  },
  {
    art_state_id: "ASTA6B8C32555",
    state_code: "311",
    name: "České Budějovice District",
    country_code: "CZ",
    lat: 48.9775553,
    lon: 14.5150747,
  },
  {
    art_state_id: "AST16D9022C46",
    state_code: "105",
    name: "Prague 5",
    country_code: "CZ",
    lat: 50.0697589,
    lon: 14.3777983,
  },
  {
    art_state_id: "ASTA068E96FF3",
    state_code: "20C",
    name: "Rakovník District",
    country_code: "CZ",
    lat: 50.106123,
    lon: 13.7396623,
  },
  {
    art_state_id: "AST38FDF1A4BB",
    state_code: "802",
    name: "Frýdek-Místek District",
    country_code: "CZ",
    lat: 49.6819305,
    lon: 18.3673216,
  },
  {
    art_state_id: "AST536B0F7154",
    state_code: "314",
    name: "Písek District",
    country_code: "CZ",
    lat: 49.3419938,
    lon: 14.246976,
  },
  {
    art_state_id: "AST56CF6B12C2",
    state_code: "645",
    name: "Hodonín District",
    country_code: "CZ",
    lat: 48.8529391,
    lon: 17.1260025,
  },
  {
    art_state_id: "AST75C814700E",
    state_code: "101",
    name: "Prague 1",
    country_code: "CZ",
    lat: 50.0887101,
    lon: 14.4155981,
  },
  {
    art_state_id: "AST386D44C241",
    state_code: "724",
    name: "Zlín District",
    country_code: "CZ",
    lat: 49.1696052,
    lon: 17.802522,
  },
  {
    art_state_id: "AST48CEBFCFB2",
    state_code: "325",
    name: "Plzeň-North District",
    country_code: "CZ",
    lat: 49.8774893,
    lon: 13.2537428,
  },
  {
    art_state_id: "ASTB8ED3D844D",
    state_code: "317",
    name: "Tábor District",
    country_code: "CZ",
    lat: 49.3646293,
    lon: 14.7191293,
  },
  {
    art_state_id: "ASTF364AFEC97",
    state_code: "109",
    name: "Prague 9",
    country_code: "CZ",
    lat: 50.111297,
    lon: 14.5025507,
  },
  {
    art_state_id: "ASTC4F9E93175",
    state_code: "116",
    name: "Prague 16",
    country_code: "CZ",
    lat: 49.9831867,
    lon: 14.3617133,
  },
  {
    art_state_id: "AST907F31BAA7",
    state_code: "642",
    name: "Brno-City District",
    country_code: "CZ",
    lat: 49.1950602,
    lon: 16.6068371,
  },
  {
    art_state_id: "AST1C2C82C737",
    state_code: "106",
    name: "Prague 6",
    country_code: "CZ",
    lat: 50.1005785,
    lon: 14.3954363,
  },
  {
    art_state_id: "AST2E2390BDFD",
    state_code: "111",
    name: "Prague 11",
    country_code: "CZ",
    lat: 50.031167,
    lon: 14.5070817,
  },
  {
    art_state_id: "ASTEAA2C3A7E0",
    state_code: "533",
    name: "Svitavy District",
    country_code: "CZ",
    lat: 49.7551629,
    lon: 16.4691861,
  },
  {
    art_state_id: "AST246DC3F4F7",
    state_code: "723",
    name: "Vsetín District",
    country_code: "CZ",
    lat: 49.379325,
    lon: 18.0618162,
  },
  {
    art_state_id: "AST322FE77C89",
    state_code: "411",
    name: "Cheb District",
    country_code: "CZ",
    lat: 50.0795334,
    lon: 12.3698636,
  },
  {
    art_state_id: "ASTE6938B88E5",
    state_code: "712",
    name: "Olomouc District",
    country_code: "CZ",
    lat: 49.593778,
    lon: 17.2508787,
  },
  {
    art_state_id: "ASTD7AEC6666B",
    state_code: "63",
    name: "Vysočina Region",
    country_code: "CZ",
    lat: 49.4490052,
    lon: 15.6405934,
  },
  {
    art_state_id: "AST9A98335617",
    state_code: "42",
    name: "Ústí nad Labem Region",
    country_code: "CZ",
    lat: 50.6119037,
    lon: 13.7870086,
  },
  {
    art_state_id: "AST7AE4D68F32",
    state_code: "120",
    name: "Horní Počernice",
    country_code: "CZ",
    lat: 50.1127167,
    lon: 14.5976685,
  },
  {
    art_state_id: "AST1B75AE4806",
    state_code: "315",
    name: "Prachatice District",
    country_code: "CZ",
    lat: 49.01091,
    lon: 14.0000005,
  },
  {
    art_state_id: "AST00120D4B98",
    state_code: "525",
    name: "Trutnov District",
    country_code: "CZ",
    lat: 50.5653838,
    lon: 15.9090923,
  },
  {
    art_state_id: "AST7CAC68882B",
    state_code: "521",
    name: "Hradec Králové District",
    country_code: "CZ",
    lat: 50.2414805,
    lon: 15.6743,
  },
  {
    art_state_id: "AST23FD2DFE4E",
    state_code: "41",
    name: "Karlovy Vary Region",
    country_code: "CZ",
    lat: 50.1435,
    lon: 12.7501899,
  },
  {
    art_state_id: "AST9898E34C4B",
    state_code: "208",
    name: "Nymburk District",
    country_code: "CZ",
    lat: 50.1855816,
    lon: 15.0436604,
  },
  {
    art_state_id: "AST5F8B136667",
    state_code: "326",
    name: "Rokycany District",
    country_code: "CZ",
    lat: 49.8262827,
    lon: 13.6874943,
  },
  {
    art_state_id: "AST801C41F5B2",
    state_code: "806",
    name: "Ostrava-City District",
    country_code: "CZ",
    lat: 49.8209226,
    lon: 18.2625243,
  },
  {
    art_state_id: "ASTEFF5212CFA",
    state_code: "114",
    name: "Prague 14",
    country_code: "CZ",
    lat: 50.1060036,
    lon: 14.5571837,
  },
  {
    art_state_id: "AST1B16AC4FD8",
    state_code: "803",
    name: "Karviná District",
    country_code: "CZ",
    lat: 49.8566524,
    lon: 18.5432186,
  },
  {
    art_state_id: "AST838B5DDBBA",
    state_code: "104",
    name: "Prague 4",
    country_code: "CZ",
    lat: 50.0433092,
    lon: 14.4388741,
  },
  {
    art_state_id: "AST595E76A707",
    state_code: "53",
    name: "Pardubice Region",
    country_code: "CZ",
    lat: 49.9444479,
    lon: 16.2856916,
  },
  {
    art_state_id: "ASTF635F0D354",
    state_code: "71",
    name: "Olomouc Region",
    country_code: "CZ",
    lat: 49.6586549,
    lon: 17.0811406,
  },
  {
    art_state_id: "AST2731594C00",
    state_code: "513",
    name: "Liberec District",
    country_code: "CZ",
    lat: 50.7564101,
    lon: 14.9965041,
  },
  {
    art_state_id: "ASTABAF57FD8C",
    state_code: "322",
    name: "Klatovy District",
    country_code: "CZ",
    lat: 49.3955549,
    lon: 13.2950937,
  },
  {
    art_state_id: "ASTBB289EA219",
    state_code: "722",
    name: "Uherské Hradiště District",
    country_code: "CZ",
    lat: 49.0597969,
    lon: 17.4958501,
  },
  {
    art_state_id: "ASTB0BD96A43D",
    state_code: "721",
    name: "Kroměříž District",
    country_code: "CZ",
    lat: 49.2916582,
    lon: 17.39938,
  },
  {
    art_state_id: "AST89F043B88B",
    state_code: "108",
    name: "Prague 8",
    country_code: "CZ",
    lat: 50.1176575,
    lon: 14.4670668,
  },
  {
    art_state_id: "AST0F607D7C35",
    state_code: "413",
    name: "Sokolov District",
    country_code: "CZ",
    lat: 50.2013434,
    lon: 12.6054636,
  },
  {
    art_state_id: "AST2022D7DADB",
    state_code: "514",
    name: "Semily District",
    country_code: "CZ",
    lat: 50.6051576,
    lon: 15.3281409,
  },
  {
    art_state_id: "ASTEC786F0BF0",
    state_code: "634",
    name: "Třebíč District",
    country_code: "CZ",
    lat: 49.2147869,
    lon: 15.8795516,
  },
  {
    art_state_id: "ASTC8608E6E99",
    state_code: "10",
    name: "Prague",
    country_code: "CZ",
    lat: 50.0755381,
    lon: 14.4378005,
  },
  {
    art_state_id: "ASTC72292C845",
    state_code: "427",
    name: "Ústí nad Labem District",
    country_code: "CZ",
    lat: 50.6119037,
    lon: 13.7870086,
  },
  {
    art_state_id: "AST1501B959E0",
    state_code: "80",
    name: "Moravian-Silesian Region",
    country_code: "CZ",
    lat: 49.7305327,
    lon: 18.2332637,
  },
  {
    art_state_id: "AST8BF8D26974",
    state_code: "51",
    name: "Liberec Region",
    country_code: "CZ",
    lat: 50.659424,
    lon: 14.7632424,
  },
  {
    art_state_id: "AST01F9B36D5D",
    state_code: "64",
    name: "South Moravian Region",
    country_code: "CZ",
    lat: 48.9544528,
    lon: 16.7676899,
  },
  {
    art_state_id: "ASTE373F4DC04",
    state_code: "110",
    name: "Prague 10",
    country_code: "CZ",
    lat: 50.0717466,
    lon: 14.4871656,
  },
  {
    art_state_id: "ASTEEA7F1E267",
    state_code: "412",
    name: "Karlovy Vary District",
    country_code: "CZ",
    lat: 50.1435,
    lon: 12.7501899,
  },
  {
    art_state_id: "AST35FC88505E",
    state_code: "423",
    name: "Litoměřice District",
    country_code: "CZ",
    lat: 50.5384197,
    lon: 14.1305458,
  },
  {
    art_state_id: "ASTA656141577",
    state_code: "209",
    name: "Prague-East District",
    country_code: "CZ",
    lat: 49.9389307,
    lon: 14.7924472,
  },
  {
    art_state_id: "ASTEC7EECD9EB",
    state_code: "32",
    name: "Plzeň Region",
    country_code: "CZ",
    lat: 49.4134812,
    lon: 13.3157246,
  },
  {
    art_state_id: "ASTC08F8213AE",
    state_code: "324",
    name: "Plzeň-South District",
    country_code: "CZ",
    lat: 49.5904885,
    lon: 13.5715861,
  },
  {
    art_state_id: "AST3AB3C1CCAE",
    state_code: "421",
    name: "Děčín District",
    country_code: "CZ",
    lat: 50.7725563,
    lon: 14.2127612,
  },
  {
    art_state_id: "AST2506355784",
    state_code: "107",
    name: "Prague 7",
    country_code: "CZ",
    lat: 50.102049,
    lon: 14.4329519,
  },
  {
    art_state_id: "AST79611A8BB9",
    state_code: "631",
    name: "Havlíčkův Brod District",
    country_code: "CZ",
    lat: 49.6043364,
    lon: 15.5796552,
  },
  {
    art_state_id: "AST196FCFBC15",
    state_code: "512",
    name: "Jablonec nad Nisou District",
    country_code: "CZ",
    lat: 50.7220528,
    lon: 15.1703135,
  },
  {
    art_state_id: "ASTEB4CFC0D59",
    state_code: "632",
    name: "Jihlava District",
    country_code: "CZ",
    lat: 49.3983782,
    lon: 15.5870415,
  },
  {
    art_state_id: "ASTD3908C5DAD",
    state_code: "52",
    name: "Hradec Králové Region",
    country_code: "CZ",
    lat: 50.3512484,
    lon: 15.7976459,
  },
  {
    art_state_id: "AST6CF73C1237",
    state_code: "641",
    name: "Blansko District",
    country_code: "CZ",
    lat: 49.3648502,
    lon: 16.6477552,
  },
  {
    art_state_id: "ASTFD1C77E568",
    state_code: "102",
    name: "Prague 2",
    country_code: "CZ",
    lat: 50.0744958,
    lon: 14.4355247,
  },
  {
    art_state_id: "ASTCAF8E77605",
    state_code: "424",
    name: "Louny District",
    country_code: "CZ",
    lat: 50.3539812,
    lon: 13.8033551,
  },
  {
    art_state_id: "AST1ABC57FEC4",
    state_code: "204",
    name: "Kolín District",
    country_code: "CZ",
    lat: 49.9883293,
    lon: 15.0551977,
  },
  {
    art_state_id: "ASTA213EAF885",
    state_code: "20A",
    name: "Prague-West District",
    country_code: "CZ",
    lat: 49.8935235,
    lon: 14.3293779,
  },
  {
    art_state_id: "ASTD1DFB9FF84",
    state_code: "202",
    name: "Beroun District",
    country_code: "CZ",
    lat: 49.9573428,
    lon: 13.9840715,
  },
  {
    art_state_id: "ASTE510D5AB7E",
    state_code: "426",
    name: "Teplice District",
    country_code: "CZ",
    lat: 50.6584605,
    lon: 13.7513227,
  },
  {
    art_state_id: "ASTA557ECFB48",
    state_code: "646",
    name: "Vyškov District",
    country_code: "CZ",
    lat: 49.2127445,
    lon: 16.9855927,
  },
  {
    art_state_id: "ASTB9D4B2477F",
    state_code: "805",
    name: "Opava District",
    country_code: "CZ",
    lat: 49.9083757,
    lon: 17.916338,
  },
  {
    art_state_id: "AST58C3D8D169",
    state_code: "313",
    name: "Jindřichův Hradec District",
    country_code: "CZ",
    lat: 49.1444823,
    lon: 15.0061389,
  },
  {
    art_state_id: "ASTEA63D720BC",
    state_code: "711",
    name: "Jeseník District",
    country_code: "CZ",
    lat: 50.2246249,
    lon: 17.1980471,
  },
  {
    art_state_id: "ASTDBF845729C",
    state_code: "714",
    name: "Přerov District",
    country_code: "CZ",
    lat: 49.4671356,
    lon: 17.5077332,
  },
  {
    art_state_id: "AST70EBE698EA",
    state_code: "201",
    name: "Benešov District",
    country_code: "CZ",
    lat: 49.6900828,
    lon: 14.7764399,
  },
  {
    art_state_id: "AST6E29273F04",
    state_code: "316",
    name: "Strakonice District",
    country_code: "CZ",
    lat: 49.2604043,
    lon: 13.9103085,
  },
  {
    art_state_id: "AST574F91A591",
    state_code: "425",
    name: "Most District",
    country_code: "CZ",
    lat: 37.1554083,
    lon: -94.2948884,
  },
  {
    art_state_id: "AST4E4859CD83",
    state_code: "647",
    name: "Znojmo District",
    country_code: "CZ",
    lat: 48.9272327,
    lon: 16.1037808,
  },
  {
    art_state_id: "AST85DD0FBAAE",
    state_code: "203",
    name: "Kladno District",
    country_code: "CZ",
    lat: 50.1940258,
    lon: 14.1043657,
  },
  {
    art_state_id: "AST1BFAD1A087",
    state_code: "121",
    name: "Prague 21",
    country_code: "CZ",
    lat: 50.0745502,
    lon: 14.6691854,
  },
  {
    art_state_id: "AST1B7B1207CB",
    state_code: "511",
    name: "Česká Lípa District",
    country_code: "CZ",
    lat: 50.6785201,
    lon: 14.5396991,
  },
  {
    art_state_id: "ASTA9ABC2FE37",
    state_code: "531",
    name: "Chrudim District",
    country_code: "CZ",
    lat: 49.8830216,
    lon: 15.8290866,
  },
  {
    art_state_id: "AST48BC0E7740",
    state_code: "103",
    name: "Prague 3",
    country_code: "CZ",
    lat: 50.0843739,
    lon: 14.470725,
  },
  {
    art_state_id: "AST9A674186DE",
    state_code: "524",
    name: "Rychnov nad Kněžnou District",
    country_code: "CZ",
    lat: 50.1659651,
    lon: 16.2776842,
  },
  {
    art_state_id: "ASTB26025B0BA",
    state_code: "115",
    name: "Prague 15",
    country_code: "CZ",
    lat: 50.0527083,
    lon: 14.5306647,
  },
  {
    art_state_id: "AST00A2C48F87",
    state_code: "206",
    name: "Mělník District",
    country_code: "CZ",
    lat: 50.3104415,
    lon: 14.5179223,
  },
  {
    art_state_id: "AST79EEA15DF9",
    state_code: "31",
    name: "South Bohemian Region",
    country_code: "CZ",
    lat: 48.9457789,
    lon: 14.4416055,
  },
  {
    art_state_id: "AST141CB42E3B",
    state_code: "522",
    name: "Jičín District",
    country_code: "CZ",
    lat: 50.4353325,
    lon: 15.361044,
  },
  {
    art_state_id: "ASTE6A8FB6E0C",
    state_code: "321",
    name: "Domažlice District",
    country_code: "CZ",
    lat: 49.4397027,
    lon: 12.9311435,
  },
  {
    art_state_id: "AST15CBBBB92C",
    state_code: "715",
    name: "Šumperk District",
    country_code: "CZ",
    lat: 49.9778407,
    lon: 16.9717754,
  },
  {
    art_state_id: "ASTD2DEA53C54",
    state_code: "207",
    name: "Mladá Boleslav District",
    country_code: "CZ",
    lat: 50.4252317,
    lon: 14.9362477,
  },
  {
    art_state_id: "ASTC1EBAC52ED",
    state_code: "801",
    name: "Bruntál District",
    country_code: "CZ",
    lat: 49.9881767,
    lon: 17.4636941,
  },
  {
    art_state_id: "AST155E151E7E",
    state_code: "633",
    name: "Pelhřimov District",
    country_code: "CZ",
    lat: 49.4306207,
    lon: 15.222983,
  },
  {
    art_state_id: "ASTB28C64E864",
    state_code: "327",
    name: "Tachov District",
    country_code: "CZ",
    lat: 49.7987803,
    lon: 12.6361921,
  },
  {
    art_state_id: "ASTAA7900DF9D",
    state_code: "534",
    name: "Ústí nad Orlicí District",
    country_code: "CZ",
    lat: 49.9721801,
    lon: 16.3996617,
  },
  {
    art_state_id: "ASTF270597D59",
    state_code: "635",
    name: "Žďár nad Sázavou District",
    country_code: "CZ",
    lat: 49.5643012,
    lon: 15.939103,
  },
  {
    art_state_id: "ASTF25BFC90FE",
    state_code: "02",
    name: "North East Community Development Council",
    country_code: "SG",
    lat: 45.0118113,
    lon: -93.2468107,
  },
  {
    art_state_id: "AST46B16B03B2",
    state_code: "04",
    name: "South East Community Development Council",
    country_code: "SG",
    lat: 39.286307,
    lon: -76.5691237,
  },
  {
    art_state_id: "AST5E2AB292CF",
    state_code: "01",
    name: "Central Singapore Community Development Council",
    country_code: "SG",
    lat: 1.289514,
    lon: 103.8143879,
  },
  {
    art_state_id: "ASTA3B429B8B8",
    state_code: "05",
    name: "South West Community Development Council",
    country_code: "SG",
    lat: 39.925691,
    lon: -75.231058,
  },
  {
    art_state_id: "ASTB97DEC2B6B",
    state_code: "03",
    name: "North West Community Development Council",
    country_code: "SG",
    lat: 39.107093,
    lon: -94.457336,
  },
  {
    art_state_id: "ASTA92DA68978",
    state_code: "09",
    name: "Ewa District",
    country_code: "NR",
    lat: -0.5087241,
    lon: 166.9369384,
  },
  {
    art_state_id: "ASTE033B6BA85",
    state_code: "13",
    name: "Uaboe District",
    country_code: "NR",
    lat: -0.5202222,
    lon: 166.9311761,
  },
  {
    art_state_id: "AST2DFDABF0A0",
    state_code: "01",
    name: "Aiwo District",
    country_code: "NR",
    lat: -0.5340012,
    lon: 166.9138873,
  },
  {
    art_state_id: "AST31FAA04F2C",
    state_code: "11",
    name: "Meneng District",
    country_code: "NR",
    lat: -0.546724,
    lon: 166.938379,
  },
  {
    art_state_id: "AST4172BEB924",
    state_code: "02",
    name: "Anabar District",
    country_code: "NR",
    lat: -0.5133517,
    lon: 166.9484624,
  },
  {
    art_state_id: "AST9350AC77CD",
    state_code: "12",
    name: "Nibok District",
    country_code: "NR",
    lat: -0.5196208,
    lon: 166.9189301,
  },
  {
    art_state_id: "ASTBC897A3F78",
    state_code: "05",
    name: "Baiti District",
    country_code: "NR",
    lat: -0.510431,
    lon: 166.9275744,
  },
  {
    art_state_id: "AST5FF643CDBD",
    state_code: "10",
    name: "Ijuw District",
    country_code: "NR",
    lat: -0.5202767,
    lon: 166.9571046,
  },
  {
    art_state_id: "ASTE347813BD0",
    state_code: "07",
    name: "Buada District",
    country_code: "NR",
    lat: -0.5328777,
    lon: 166.9268541,
  },
  {
    art_state_id: "AST0611762567",
    state_code: "04",
    name: "Anibare District",
    country_code: "NR",
    lat: -0.5294758,
    lon: 166.9513432,
  },
  {
    art_state_id: "ASTB1CD4A6934",
    state_code: "14",
    name: "Yaren District",
    country_code: "NR",
    lat: -0.5466857,
    lon: 166.9210913,
  },
  {
    art_state_id: "AST1AA3868618",
    state_code: "06",
    name: "Boe District",
    country_code: "NR",
    lat: 39.0732776,
    lon: -94.5710498,
  },
  {
    art_state_id: "ASTEB50176C69",
    state_code: "08",
    name: "Denigomodu District",
    country_code: "NR",
    lat: -0.5247964,
    lon: 166.9167689,
  },
  {
    art_state_id: "AST75D3379346",
    state_code: "03",
    name: "Anetan District",
    country_code: "NR",
    lat: -0.5064343,
    lon: 166.9427006,
  },
  {
    art_state_id: "ASTA5F90D10EA",
    state_code: "18",
    name: "Zhytomyr Oblast",
    country_code: "UA",
    lat: 50.25465,
    lon: 28.6586669,
  },
  {
    art_state_id: "AST1C9F56E67D",
    state_code: "05",
    name: "Vinnytsia Oblast",
    country_code: "UA",
    lat: 49.233083,
    lon: 28.4682169,
  },
  {
    art_state_id: "AST74F54F6BF5",
    state_code: "21",
    name: "Zakarpattia Oblast",
    country_code: "UA",
    lat: 48.6208,
    lon: 22.287883,
  },
  {
    art_state_id: "ASTA79CD2E863",
    state_code: "32",
    name: "Kyiv Oblast",
    country_code: "UA",
    lat: 50.0529506,
    lon: 30.7667134,
  },
  {
    art_state_id: "AST6DB0058691",
    state_code: "46",
    name: "Lviv Oblast",
    country_code: "UA",
    lat: 49.839683,
    lon: 24.029717,
  },
  {
    art_state_id: "AST7CBBC52F5A",
    state_code: "09",
    name: "Luhansk Oblast",
    country_code: "UA",
    lat: 48.574041,
    lon: 39.307815,
  },
  {
    art_state_id: "AST53557931D5",
    state_code: "61",
    name: "Ternopil Oblast",
    country_code: "UA",
    lat: 49.553517,
    lon: 25.594767,
  },
  {
    art_state_id: "ASTC642343937",
    state_code: "12",
    name: "Dnipropetrovsk Oblast",
    country_code: "UA",
    lat: 48.464717,
    lon: 35.046183,
  },
  {
    art_state_id: "ASTB46ECF6FB2",
    state_code: "30",
    name: "Kiev",
    country_code: "UA",
    lat: 50.4501,
    lon: 30.5234,
  },
  {
    art_state_id: "ASTB996471E5A",
    state_code: "35",
    name: "Kirovohrad Oblast",
    country_code: "UA",
    lat: 48.507933,
    lon: 32.262317,
  },
  {
    art_state_id: "AST5F73F7D446",
    state_code: "77",
    name: "Chernivtsi Oblast",
    country_code: "UA",
    lat: 48.291683,
    lon: 25.935217,
  },
  {
    art_state_id: "AST34D11238CC",
    state_code: "48",
    name: "Mykolaiv Oblast",
    country_code: "UA",
    lat: 46.975033,
    lon: 31.9945829,
  },
  {
    art_state_id: "AST9FC34B89D6",
    state_code: "71",
    name: "Cherkasy Oblast",
    country_code: "UA",
    lat: 49.444433,
    lon: 32.059767,
  },
  {
    art_state_id: "ASTDA33EE3BE3",
    state_code: "68",
    name: "Khmelnytsky Oblast",
    country_code: "UA",
    lat: 49.422983,
    lon: 26.9871331,
  },
  {
    art_state_id: "AST1FEFAA76D6",
    state_code: "26",
    name: "Ivano-Frankivsk Oblast",
    country_code: "UA",
    lat: 48.922633,
    lon: 24.711117,
  },
  {
    art_state_id: "AST63C2407B38",
    state_code: "56",
    name: "Rivne Oblast",
    country_code: "UA",
    lat: 50.6199,
    lon: 26.251617,
  },
  {
    art_state_id: "AST4DD280A315",
    state_code: "65",
    name: "Kherson Oblast",
    country_code: "UA",
    lat: 46.635417,
    lon: 32.616867,
  },
  {
    art_state_id: "ASTACE95BEE9B",
    state_code: "59",
    name: "Sumy Oblast",
    country_code: "UA",
    lat: 50.9077,
    lon: 34.7981,
  },
  {
    art_state_id: "AST12C58312E2",
    state_code: "63",
    name: "Kharkiv Oblast",
    country_code: "UA",
    lat: 49.9935,
    lon: 36.230383,
  },
  {
    art_state_id: "ASTC90E2357B9",
    state_code: "23",
    name: "Zaporizhzhya Oblast",
    country_code: "UA",
    lat: 47.8388,
    lon: 35.139567,
  },
  {
    art_state_id: "ASTCB61063076",
    state_code: "51",
    name: "Odessa Oblast",
    country_code: "UA",
    lat: 46.484583,
    lon: 30.7326,
  },
  {
    art_state_id: "AST6CF0907336",
    state_code: "43",
    name: "Autonomous Republic of Crimea",
    country_code: "UA",
    lat: 44.952117,
    lon: 34.102417,
  },
  {
    art_state_id: "ASTB67CAC2EE7",
    state_code: "07",
    name: "Volyn Oblast",
    country_code: "UA",
    lat: 50.747233,
    lon: 25.325383,
  },
  {
    art_state_id: "AST8CC1A2A218",
    state_code: "14",
    name: "Donetsk Oblast",
    country_code: "UA",
    lat: 48.015883,
    lon: 37.80285,
  },
  {
    art_state_id: "AST242ABCDAF8",
    state_code: "74",
    name: "Chernihiv Oblast",
    country_code: "UA",
    lat: 51.4982,
    lon: 31.2893499,
  },
  {
    art_state_id: "AST187F3309E2",
    state_code: "07",
    name: "Gabrovo Province",
    country_code: "BG",
    lat: 42.86847,
    lon: 25.316889,
  },
  {
    art_state_id: "ASTBC31DF7BFF",
    state_code: "21",
    name: "Smolyan Province",
    country_code: "BG",
    lat: 41.5774148,
    lon: 24.7010871,
  },
  {
    art_state_id: "AST84D79B58DC",
    state_code: "14",
    name: "Pernik Province",
    country_code: "BG",
    lat: 42.605199,
    lon: 23.0377916,
  },
  {
    art_state_id: "AST5AB03277EE",
    state_code: "12",
    name: "Montana Province",
    country_code: "BG",
    lat: 43.4085148,
    lon: 23.2257589,
  },
  {
    art_state_id: "AST55C86F99F9",
    state_code: "05",
    name: "Vidin Province",
    country_code: "BG",
    lat: 43.9961739,
    lon: 22.8679515,
  },
  {
    art_state_id: "AST7461755FCB",
    state_code: "17",
    name: "Razgrad Province",
    country_code: "BG",
    lat: 43.5271705,
    lon: 26.5241228,
  },
  {
    art_state_id: "AST435B4B261E",
    state_code: "01",
    name: "Blagoevgrad Province",
    country_code: "BG",
    lat: 42.0208614,
    lon: 23.0943356,
  },
  {
    art_state_id: "AST10E0069713",
    state_code: "20",
    name: "Sliven Province",
    country_code: "BG",
    lat: 42.6816702,
    lon: 26.3228569,
  },
  {
    art_state_id: "ASTFDD52B4EC9",
    state_code: "16",
    name: "Plovdiv Province",
    country_code: "BG",
    lat: 42.1354079,
    lon: 24.7452904,
  },
  {
    art_state_id: "ASTD673EC271C",
    state_code: "09",
    name: "Kardzhali Province",
    country_code: "BG",
    lat: 41.6338416,
    lon: 25.3776687,
  },
  {
    art_state_id: "AST387A05A052",
    state_code: "10",
    name: "Kyustendil Province",
    country_code: "BG",
    lat: 42.2868799,
    lon: 22.6939635,
  },
  {
    art_state_id: "AST528984E5ED",
    state_code: "26",
    name: "Haskovo Province",
    country_code: "BG",
    lat: 41.9344178,
    lon: 25.5554672,
  },
  {
    art_state_id: "AST3B2049F5E8",
    state_code: "22",
    name: "Sofia City Province",
    country_code: "BG",
    lat: 42.7570109,
    lon: 23.4504683,
  },
  {
    art_state_id: "AST90587AA737",
    state_code: "15",
    name: "Pleven Province",
    country_code: "BG",
    lat: 43.4170169,
    lon: 24.6066708,
  },
  {
    art_state_id: "ASTE672F531B8",
    state_code: "24",
    name: "Stara Zagora Province",
    country_code: "BG",
    lat: 42.4257709,
    lon: 25.6344855,
  },
  {
    art_state_id: "ASTD1851E3CB1",
    state_code: "19",
    name: "Silistra Province",
    country_code: "BG",
    lat: 44.1147101,
    lon: 27.2671454,
  },
  {
    art_state_id: "AST645B9F97E6",
    state_code: "04",
    name: "Veliko Tarnovo Province",
    country_code: "BG",
    lat: 43.0756539,
    lon: 25.61715,
  },
  {
    art_state_id: "AST3A6F1C0CA1",
    state_code: "11",
    name: "Lovech Province",
    country_code: "BG",
    lat: 43.1367798,
    lon: 24.7139335,
  },
  {
    art_state_id: "ASTFC01B8105B",
    state_code: "06",
    name: "Vratsa Province",
    country_code: "BG",
    lat: 43.2101806,
    lon: 23.552921,
  },
  {
    art_state_id: "AST61FEEF946F",
    state_code: "13",
    name: "Pazardzhik Province",
    country_code: "BG",
    lat: 42.1927567,
    lon: 24.3336226,
  },
  {
    art_state_id: "AST1D55CFE805",
    state_code: "18",
    name: "Ruse Province",
    country_code: "BG",
    lat: 43.8355964,
    lon: 25.9656144,
  },
  {
    art_state_id: "ASTF9C865D1E4",
    state_code: "25",
    name: "Targovishte Province",
    country_code: "BG",
    lat: 43.2462349,
    lon: 26.5691251,
  },
  {
    art_state_id: "AST303333E66F",
    state_code: "02",
    name: "Burgas Province",
    country_code: "BG",
    lat: 42.5048,
    lon: 27.4626079,
  },
  {
    art_state_id: "AST16CC536C99",
    state_code: "28",
    name: "Yambol Province",
    country_code: "BG",
    lat: 42.4841494,
    lon: 26.5035296,
  },
  {
    art_state_id: "AST219D483C42",
    state_code: "03",
    name: "Varna Province",
    country_code: "BG",
    lat: 43.2046477,
    lon: 27.9105488,
  },
  {
    art_state_id: "ASTA831196F0A",
    state_code: "08",
    name: "Dobrich Province",
    country_code: "BG",
    lat: 43.572786,
    lon: 27.8272802,
  },
  {
    art_state_id: "AST20259CD7C9",
    state_code: "23",
    name: "Sofia Province",
    country_code: "BG",
    lat: 42.67344,
    lon: 23.8334937,
  },
  {
    art_state_id: "AST3B692E44A5",
    state_code: "SV",
    name: "Suceava County",
    country_code: "RO",
    lat: 47.5505548,
    lon: 25.741062,
  },
  {
    art_state_id: "AST2F1A40BD0C",
    state_code: "HD",
    name: "Hunedoara County",
    country_code: "RO",
    lat: 45.793638,
    lon: 22.9975993,
  },
  {
    art_state_id: "AST902A342AF4",
    state_code: "AG",
    name: "Arges",
    country_code: "RO",
    lat: 45.0722527,
    lon: 24.8142726,
  },
  {
    art_state_id: "AST7C9A8CBB02",
    state_code: "BH",
    name: "Bihor County",
    country_code: "RO",
    lat: 47.0157516,
    lon: 22.172266,
  },
  {
    art_state_id: "AST2D6481F6E0",
    state_code: "AB",
    name: "Alba",
    country_code: "RO",
    lat: 44.7009153,
    lon: 8.0356911,
  },
  {
    art_state_id: "AST17D965D522",
    state_code: "IF",
    name: "Ilfov County",
    country_code: "RO",
    lat: 44.535548,
    lon: 26.2324886,
  },
  {
    art_state_id: "AST10422CE8D8",
    state_code: "GR",
    name: "Giurgiu County",
    country_code: "RO",
    lat: 43.9037076,
    lon: 25.9699265,
  },
  {
    art_state_id: "ASTE8A1233FD8",
    state_code: "TL",
    name: "Tulcea County",
    country_code: "RO",
    lat: 45.0450565,
    lon: 29.0324912,
  },
  {
    art_state_id: "ASTF9A5F8947C",
    state_code: "TR",
    name: "Teleorman County",
    country_code: "RO",
    lat: 44.0160491,
    lon: 25.2986628,
  },
  {
    art_state_id: "AST67F2827555",
    state_code: "PH",
    name: "Prahova County",
    country_code: "RO",
    lat: 45.0891906,
    lon: 26.0829313,
  },
  {
    art_state_id: "ASTA8F061332B",
    state_code: "B",
    name: "Bucharest",
    country_code: "RO",
    lat: 44.4267674,
    lon: 26.1025384,
  },
  {
    art_state_id: "ASTF41025D81E",
    state_code: "NT",
    name: "Neamț County",
    country_code: "RO",
    lat: 46.9758685,
    lon: 26.3818764,
  },
  {
    art_state_id: "AST9F4AD7BEBE",
    state_code: "CL",
    name: "Călărași County",
    country_code: "RO",
    lat: 44.3658715,
    lon: 26.7548607,
  },
  {
    art_state_id: "AST359A8D07D2",
    state_code: "BN",
    name: "Bistrița-Năsăud County",
    country_code: "RO",
    lat: 47.2486107,
    lon: 24.5322814,
  },
  {
    art_state_id: "AST6B544604C1",
    state_code: "CJ",
    name: "Cluj County",
    country_code: "RO",
    lat: 46.7941797,
    lon: 23.6121492,
  },
  {
    art_state_id: "AST63BA7E6575",
    state_code: "IS",
    name: "Iași County",
    country_code: "RO",
    lat: 47.2679653,
    lon: 27.2185662,
  },
  {
    art_state_id: "ASTE625EC8CC3",
    state_code: "BR",
    name: "Braila",
    country_code: "RO",
    lat: 45.2652463,
    lon: 27.9594714,
  },
  {
    art_state_id: "AST62351EDCD5",
    state_code: "CT",
    name: "Constanța County",
    country_code: "RO",
    lat: 44.212987,
    lon: 28.2550055,
  },
  {
    art_state_id: "AST2F6B416848",
    state_code: "OT",
    name: "Olt County",
    country_code: "RO",
    lat: 44.200797,
    lon: 24.5022981,
  },
  {
    art_state_id: "AST97B7648A9E",
    state_code: "AR",
    name: "Arad County",
    country_code: "RO",
    lat: 46.2283651,
    lon: 21.6597819,
  },
  {
    art_state_id: "AST087C2D9A24",
    state_code: "BT",
    name: "Botoșani County",
    country_code: "RO",
    lat: 47.8924042,
    lon: 26.7591781,
  },
  {
    art_state_id: "AST95671F62FA",
    state_code: "SJ",
    name: "Sălaj County",
    country_code: "RO",
    lat: 47.2090813,
    lon: 23.2121901,
  },
  {
    art_state_id: "AST11F71BBBC3",
    state_code: "DJ",
    name: "Dolj County",
    country_code: "RO",
    lat: 44.1623022,
    lon: 23.6325054,
  },
  {
    art_state_id: "ASTFA4A97F567",
    state_code: "IL",
    name: "Ialomița County",
    country_code: "RO",
    lat: 44.603133,
    lon: 27.3789914,
  },
  {
    art_state_id: "AST6C5D28B3C9",
    state_code: "BC",
    name: "Bacău County",
    country_code: "RO",
    lat: 46.3258184,
    lon: 26.662378,
  },
  {
    art_state_id: "AST18491517F0",
    state_code: "DB",
    name: "Dâmbovița County",
    country_code: "RO",
    lat: 44.9289893,
    lon: 25.425385,
  },
  {
    art_state_id: "AST5980D15B40",
    state_code: "SM",
    name: "Satu Mare County",
    country_code: "RO",
    lat: 47.7668905,
    lon: 22.9241377,
  },
  {
    art_state_id: "AST6C536265A8",
    state_code: "GL",
    name: "Galați County",
    country_code: "RO",
    lat: 45.7800569,
    lon: 27.8251576,
  },
  {
    art_state_id: "ASTD16F12E9B1",
    state_code: "TM",
    name: "Timiș County",
    country_code: "RO",
    lat: 45.8138902,
    lon: 21.3331055,
  },
  {
    art_state_id: "ASTF632D31BF9",
    state_code: "HR",
    name: "Harghita County",
    country_code: "RO",
    lat: 46.4928507,
    lon: 25.6456696,
  },
  {
    art_state_id: "ASTE617791663",
    state_code: "GJ",
    name: "Gorj County",
    country_code: "RO",
    lat: 44.9485595,
    lon: 23.2427079,
  },
  {
    art_state_id: "ASTAC1BF7F36B",
    state_code: "MH",
    name: "Mehedinți County",
    country_code: "RO",
    lat: 44.5515053,
    lon: 22.9044157,
  },
  {
    art_state_id: "AST8650873D76",
    state_code: "VS",
    name: "Vaslui County",
    country_code: "RO",
    lat: 46.4631059,
    lon: 27.7398031,
  },
  {
    art_state_id: "AST42BDFFDA66",
    state_code: "CS",
    name: "Caraș-Severin County",
    country_code: "RO",
    lat: 45.1139646,
    lon: 22.0740993,
  },
  {
    art_state_id: "AST7997D03BB4",
    state_code: "CV",
    name: "Covasna County",
    country_code: "RO",
    lat: 45.9426347,
    lon: 25.8918984,
  },
  {
    art_state_id: "ASTF067EAD1CB",
    state_code: "SB",
    name: "Sibiu County",
    country_code: "RO",
    lat: 45.9269106,
    lon: 24.2254807,
  },
  {
    art_state_id: "ASTB2CF5EBE4D",
    state_code: "BZ",
    name: "Buzău County",
    country_code: "RO",
    lat: 45.3350912,
    lon: 26.7107578,
  },
  {
    art_state_id: "ASTAD25D2F74D",
    state_code: "VL",
    name: "Vâlcea County",
    country_code: "RO",
    lat: 45.0798051,
    lon: 24.0835283,
  },
  {
    art_state_id: "AST28EDEAC490",
    state_code: "VN",
    name: "Vrancea County",
    country_code: "RO",
    lat: 45.8134876,
    lon: 27.0657531,
  },
  {
    art_state_id: "AST5CD1E411A1",
    state_code: "BV",
    name: "Brașov County",
    country_code: "RO",
    lat: 45.7781844,
    lon: 25.22258,
  },
  {
    art_state_id: "AST5336D86D21",
    state_code: "MM",
    name: "Maramureș County",
    country_code: "RO",
    lat: 46.5569904,
    lon: 24.6723215,
  },
  {
    art_state_id: "AST2D85107FA6",
    state_code: "AL",
    name: "Aiga-i-le-Tai",
    country_code: "WS",
    lat: -13.8513791,
    lon: -172.0325401,
  },
  {
    art_state_id: "AST9FEC74E145",
    state_code: "SA",
    name: "Satupa'itea",
    country_code: "WS",
    lat: -13.6538214,
    lon: -172.6159271,
  },
  {
    art_state_id: "AST4EC1FE3326",
    state_code: "AA",
    name: "A'ana",
    country_code: "WS",
    lat: -13.898418,
    lon: -171.9752995,
  },
  {
    art_state_id: "AST1BB799F9B8",
    state_code: "FA",
    name: "Fa'asaleleaga",
    country_code: "WS",
    lat: -13.6307638,
    lon: -172.2365981,
  },
  {
    art_state_id: "ASTE90D2433B4",
    state_code: "AT",
    name: "Atua",
    country_code: "WS",
    lat: -13.9787053,
    lon: -171.6254283,
  },
  {
    art_state_id: "ASTCFC8BC1EC2",
    state_code: "VS",
    name: "Vaisigano",
    country_code: "WS",
    lat: -13.5413827,
    lon: -172.7023383,
  },
  {
    art_state_id: "AST30AE5E850E",
    state_code: "PA",
    name: "Palauli",
    country_code: "WS",
    lat: -13.7294579,
    lon: -172.4536115,
  },
  {
    art_state_id: "AST6F8DB4F8A4",
    state_code: "VF",
    name: "Va'a-o-Fonoti",
    country_code: "WS",
    lat: -13.9470903,
    lon: -171.5431872,
  },
  {
    art_state_id: "AST05B5A40A5A",
    state_code: "GE",
    name: "Gaga'emauga",
    country_code: "WS",
    lat: -13.5428666,
    lon: -172.366887,
  },
  {
    art_state_id: "ASTC289AF0579",
    state_code: "TU",
    name: "Tuamasaga",
    country_code: "WS",
    lat: -13.9163592,
    lon: -171.8224362,
  },
  {
    art_state_id: "AST77C99C0A09",
    state_code: "GI",
    name: "Gaga'ifomauga",
    country_code: "WS",
    lat: -13.5468007,
    lon: -172.4969331,
  },
  {
    art_state_id: "ASTB1BC83993D",
    state_code: "TOB",
    name: "Torba",
    country_code: "VU",
    lat: 37.07653,
    lon: 27.456573,
  },
  {
    art_state_id: "ASTEA6CDAA1BE",
    state_code: "PAM",
    name: "Penama",
    country_code: "VU",
    lat: -15.3795758,
    lon: 167.9053182,
  },
  {
    art_state_id: "AST8D52B28D2A",
    state_code: "SEE",
    name: "Shefa",
    country_code: "VU",
    lat: 32.805765,
    lon: 35.169971,
  },
  {
    art_state_id: "ASTFB1B12E4B4",
    state_code: "MAP",
    name: "Malampa",
    country_code: "VU",
    lat: -16.4011405,
    lon: 167.6077865,
  },
  {
    art_state_id: "AST391ADFE899",
    state_code: "SAM",
    name: "Sanma",
    country_code: "VU",
    lat: -15.4840017,
    lon: 166.9182097,
  },
  {
    art_state_id: "AST56831D7F77",
    state_code: "TAE",
    name: "Tafea",
    country_code: "VU",
    lat: -18.7237827,
    lon: 169.0645056,
  },
  {
    art_state_id: "AST184632B137",
    state_code: "CT",
    name: "Honiara",
    country_code: "SB",
    lat: -9.4456381,
    lon: 159.9728999,
  },
  {
    art_state_id: "AST93787A7959",
    state_code: "TE",
    name: "Temotu Province",
    country_code: "SB",
    lat: -10.686929,
    lon: 166.0623979,
  },
  {
    art_state_id: "AST13F54B6B2F",
    state_code: "IS",
    name: "Isabel Province",
    country_code: "SB",
    lat: -8.0592353,
    lon: 159.1447081,
  },
  {
    art_state_id: "AST37927FC7E9",
    state_code: "CH",
    name: "Choiseul Province",
    country_code: "SB",
    lat: -7.0501494,
    lon: 156.9511459,
  },
  {
    art_state_id: "AST0016671EB2",
    state_code: "MK",
    name: "Makira-Ulawa Province",
    country_code: "SB",
    lat: -10.5737447,
    lon: 161.8096941,
  },
  {
    art_state_id: "ASTD81FCAF303",
    state_code: "ML",
    name: "Malaita Province",
    country_code: "SB",
    lat: -8.9446168,
    lon: 160.9071236,
  },
  {
    art_state_id: "AST8FA46D0BBE",
    state_code: "CE",
    name: "Central Province",
    country_code: "SB",
    lat: -8.9833335,
    lon: 159.75,
  },
  {
    art_state_id: "AST518528796B",
    state_code: "GU",
    name: "Guadalcanal Province",
    country_code: "SB",
    lat: -9.5773284,
    lon: 160.1455805,
  },
  {
    art_state_id: "AST011B450EEA",
    state_code: "WE",
    name: "Western Province",
    country_code: "SB",
    lat: -8.7053941,
    lon: 159.1070693851845,
  },
  {
    art_state_id: "ASTE379F7AD99",
    state_code: "RB",
    name: "Rennell and Bellona Province",
    country_code: "SB",
    lat: -11.6131435,
    lon: 160.1693949,
  },
  {
    art_state_id: "AST98ABDC4DB7",
    state_code: "D",
    name: "Burgundy",
    country_code: "FR",
    lat: 47.0525047,
    lon: 4.3837215,
  },
  {
    art_state_id: "AST1EDD799948",
    state_code: "C",
    name: "Auvergne",
    country_code: "FR",
    lat: 45.7032695,
    lon: 3.3448536,
  },
  {
    art_state_id: "AST9F3CE62BD4",
    state_code: "S",
    name: "Picardy",
    country_code: "FR",
    lat: 49.6636127,
    lon: 2.5280732,
  },
  {
    art_state_id: "AST1654112A84",
    state_code: "G",
    name: "Champagne-Ardenne",
    country_code: "FR",
    lat: 48.7934092,
    lon: 4.4725249,
  },
  {
    art_state_id: "AST25A6E8B4AE",
    state_code: "L",
    name: "Limousin",
    country_code: "FR",
    lat: 45.8932231,
    lon: 1.5696018,
  },
  {
    art_state_id: "AST4E629718BA",
    state_code: "O",
    name: "Nord-Pas-de-Calais",
    country_code: "FR",
    lat: 50.4801153,
    lon: 2.7937265,
  },
  {
    art_state_id: "AST45573D0BE7",
    state_code: "BL",
    name: "Saint Barthélemy",
    country_code: "FR",
    lat: 17.9005134,
    lon: -62.8205871,
  },
  {
    art_state_id: "AST1B151F5012",
    state_code: "NAQ",
    name: "Nouvelle-Aquitaine",
    country_code: "FR",
    lat: 45.7087182,
    lon: 0.626891,
  },
  {
    art_state_id: "AST9972EEAEF0",
    state_code: "IDF",
    name: "Île-de-France",
    country_code: "FR",
    lat: 48.8499198,
    lon: 2.6370411,
  },
  {
    art_state_id: "AST923FFCBEA0",
    state_code: "YT",
    name: "Mayotte",
    country_code: "FR",
    lat: -12.8275,
    lon: 45.166244,
  },
  {
    art_state_id: "ASTA52459BE7A",
    state_code: "ARA",
    name: "Auvergne-Rhône-Alpes",
    country_code: "FR",
    lat: 45.4471431,
    lon: 4.3852507,
  },
  {
    art_state_id: "AST8349701A95",
    state_code: "OCC",
    name: "Occitania",
    country_code: "FR",
    lat: 43.8927232,
    lon: 3.2827625,
  },
  {
    art_state_id: "ASTEE89FEFBD7",
    state_code: "WF-AL",
    name: "Alo",
    country_code: "FR",
    lat: 40.7158362,
    lon: -73.9596317,
  },
  {
    art_state_id: "ASTE6907686FD",
    state_code: "M",
    name: "Lorraine",
    country_code: "FR",
    lat: 48.8744233,
    lon: 6.2080932,
  },
  {
    art_state_id: "AST76C6752E0F",
    state_code: "PDL",
    name: "Pays de la Loire",
    country_code: "FR",
    lat: 47.7632836,
    lon: -0.3299687,
  },
  {
    art_state_id: "AST4F41B36A03",
    state_code: "K",
    name: "Languedoc-Roussillon",
    country_code: "FR",
    lat: 43.5912356,
    lon: 3.2583626,
  },
  {
    art_state_id: "ASTA9D8305EC9",
    state_code: "NOR",
    name: "Normandy",
    country_code: "FR",
    lat: 48.8798704,
    lon: 0.1712529,
  },
  {
    art_state_id: "AST6C2E58D9B6",
    state_code: "I",
    name: "Franche-Comté",
    country_code: "FR",
    lat: 47.1343207,
    lon: 6.0223016,
  },
  {
    art_state_id: "ASTD51FE3922B",
    state_code: "COR",
    name: "Corsica",
    country_code: "FR",
    lat: 42.0396042,
    lon: 9.0128926,
  },
  {
    art_state_id: "AST227D6F366D",
    state_code: "BRE",
    name: "Brittany",
    country_code: "FR",
    lat: 48.2020471,
    lon: -2.9326435,
  },
  {
    art_state_id: "AST63FF285AC7",
    state_code: "B",
    name: "Aquitaine",
    country_code: "FR",
    lat: 44.7002222,
    lon: -0.2995785,
  },
  {
    art_state_id: "ASTDAD289BA25",
    state_code: "MF",
    name: "Saint Martin",
    country_code: "FR",
    lat: 18.0708298,
    lon: -63.0500809,
  },
  {
    art_state_id: "ASTD181D3C85A",
    state_code: "WF",
    name: "Wallis and Futuna",
    country_code: "FR",
    lat: -14.2938,
    lon: -178.1165,
  },
  {
    art_state_id: "ASTE6E04FF12D",
    state_code: "A",
    name: "Alsace",
    country_code: "FR",
    lat: 48.3181795,
    lon: 7.4416241,
  },
  {
    art_state_id: "AST281E43E38D",
    state_code: "PAC",
    name: "Provence-Alpes-Côte d'Azur",
    country_code: "FR",
    lat: 43.9351691,
    lon: 6.0679194,
  },
  {
    art_state_id: "ASTE1B6182C1C",
    state_code: "V",
    name: "Rhône-Alpes",
    country_code: "FR",
    lat: 45.6402905,
    lon: 5.6864994,
  },
  {
    art_state_id: "AST333D822008",
    state_code: "P",
    name: "Lower Normandy",
    country_code: "FR",
    lat: 48.8788472,
    lon: -0.5157492,
  },
  {
    art_state_id: "AST4B92B313D6",
    state_code: "T",
    name: "Poitou-Charentes",
    country_code: "FR",
    lat: 45.903552,
    lon: -0.3091837,
  },
  {
    art_state_id: "AST2AAF527148",
    state_code: "75",
    name: "Paris",
    country_code: "FR",
    lat: 48.856614,
    lon: 2.3522219,
  },
  {
    art_state_id: "AST5991E0C36B",
    state_code: "WF-UV",
    name: "Uvea",
    country_code: "FR",
    lat: 41.983298,
    lon: -87.668362,
  },
  {
    art_state_id: "AST939A5FB37A",
    state_code: "CVL",
    name: "Centre-Val de Loire",
    country_code: "FR",
    lat: 47.7515686,
    lon: 1.6750631,
  },
  {
    art_state_id: "AST1B643ED442",
    state_code: "WF-SG",
    name: "Sigave",
    country_code: "FR",
    lat: -14.2732592,
    lon: -178.16329,
  },
  {
    art_state_id: "ASTB67ADE38BD",
    state_code: "GES",
    name: "Grand Est",
    country_code: "FR",
    lat: 48.699803,
    lon: 6.1878074,
  },
  {
    art_state_id: "ASTFB40A3F678",
    state_code: "PM",
    name: "Saint Pierre and Miquelon",
    country_code: "FR",
    lat: 46.8852,
    lon: -56.3159,
  },
  {
    art_state_id: "AST0FA5466317",
    state_code: "GF",
    name: "French Guiana",
    country_code: "FR",
    lat: 3.933889,
    lon: -53.125782,
  },
  {
    art_state_id: "ASTA129997297",
    state_code: "RE",
    name: "Réunion",
    country_code: "FR",
    lat: -21.115141,
    lon: 55.536384,
  },
  {
    art_state_id: "AST146B91A951",
    state_code: "PF",
    name: "French Polynesia",
    country_code: "FR",
    lat: -17.679742,
    lon: -149.406843,
  },
  {
    art_state_id: "ASTE8C2249F40",
    state_code: "BFC",
    name: "Bourgogne-Franche-Comté",
    country_code: "FR",
    lat: 47.2805127,
    lon: 4.9994372,
  },
  {
    art_state_id: "AST09D55D0DC3",
    state_code: "Q",
    name: "Upper Normandy",
    country_code: "FR",
    lat: 49.524641,
    lon: 0.8828329,
  },
  {
    art_state_id: "AST58A84E7535",
    state_code: "MQ",
    name: "Martinique",
    country_code: "FR",
    lat: 14.641528,
    lon: -61.024174,
  },
  {
    art_state_id: "AST8BC38063DF",
    state_code: "HDF",
    name: "Hauts-de-France",
    country_code: "FR",
    lat: 50.4801153,
    lon: 2.7937265,
  },
  {
    art_state_id: "ASTC96D5FCB38",
    state_code: "GP",
    name: "Guadeloupe",
    country_code: "FR",
    lat: 16.265,
    lon: -61.551,
  },
  {
    art_state_id: "AST76D44D8AB9",
    state_code: "WBK",
    name: "West New Britain Province",
    country_code: "PG",
    lat: -5.7047432,
    lon: 150.0259466,
  },
  {
    art_state_id: "AST356D7F0E1D",
    state_code: "NSB",
    name: "Bougainville",
    country_code: "PG",
    lat: -6.3753919,
    lon: 155.3807101,
  },
  {
    art_state_id: "ASTD2E658739A",
    state_code: "JWK",
    name: "Jiwaka Province",
    country_code: "PG",
    lat: -5.8691154,
    lon: 144.6972774,
  },
  {
    art_state_id: "ASTE8DB52A721",
    state_code: "HLA",
    name: "Hela",
    country_code: "PG",
    lat: 42.3329516,
    lon: -83.0482618,
  },
  {
    art_state_id: "AST3BC6E35DFB",
    state_code: "EBR",
    name: "East New Britain",
    country_code: "PG",
    lat: -4.6128943,
    lon: 151.8877321,
  },
  {
    art_state_id: "ASTB04F2B016E",
    state_code: "MPL",
    name: "Morobe Province",
    country_code: "PG",
    lat: -6.8013737,
    lon: 146.561647,
  },
  {
    art_state_id: "AST4249C19D26",
    state_code: "SAN",
    name: "Sandaun Province",
    country_code: "PG",
    lat: -3.7126179,
    lon: 141.6834275,
  },
  {
    art_state_id: "AST2C0AE2A72C",
    state_code: "NCD",
    name: "Port Moresby",
    country_code: "PG",
    lat: -9.4438004,
    lon: 147.1802671,
  },
  {
    art_state_id: "ASTB3D39FC419",
    state_code: "NPP",
    name: "Oro Province",
    country_code: "PG",
    lat: -8.8988063,
    lon: 148.1892921,
  },
  {
    art_state_id: "ASTC902EC05D3",
    state_code: "GPK",
    name: "Gulf",
    country_code: "PG",
    lat: 37.0548315,
    lon: -94.4370419,
  },
  {
    art_state_id: "AST614148C366",
    state_code: "WHM",
    name: "Western Highlands Province",
    country_code: "PG",
    lat: -5.6268128,
    lon: 144.2593118,
  },
  {
    art_state_id: "ASTFE3CECCFB7",
    state_code: "NIK",
    name: "New Ireland Province",
    country_code: "PG",
    lat: -4.2853256,
    lon: 152.9205918,
  },
  {
    art_state_id: "AST238F8B4C99",
    state_code: "MRL",
    name: "Manus Province",
    country_code: "PG",
    lat: -2.0941169,
    lon: 146.8760951,
  },
  {
    art_state_id: "ASTC699118572",
    state_code: "MPM",
    name: "Madang Province",
    country_code: "PG",
    lat: -4.9849733,
    lon: 145.1375834,
  },
  {
    art_state_id: "ASTEAA7441488",
    state_code: "SHM",
    name: "Southern Highlands Province",
    country_code: "PG",
    lat: -6.4179083,
    lon: 143.5635637,
  },
  {
    art_state_id: "ASTB3C58006E9",
    state_code: "EHG",
    name: "Eastern Highlands Province",
    country_code: "PG",
    lat: -6.5861674,
    lon: 145.6689636,
  },
  {
    art_state_id: "AST1CE41E00D0",
    state_code: "CPK",
    name: "Chimbu Province",
    country_code: "PG",
    lat: -6.3087682,
    lon: 144.8731219,
  },
  {
    art_state_id: "AST973D9B9127",
    state_code: "CPM",
    name: "Central Province",
    country_code: "PG",
    lat: -5.6816069,
    lon: 144.2489081,
  },
  {
    art_state_id: "AST8CA94BA1AE",
    state_code: "EPW",
    name: "Enga Province",
    country_code: "PG",
    lat: -5.3005849,
    lon: 143.5635637,
  },
  {
    art_state_id: "ASTA2E6D4B63E",
    state_code: "MBA",
    name: "Milne Bay Province",
    country_code: "PG",
    lat: -9.5221451,
    lon: 150.6749653,
  },
  {
    art_state_id: "AST2C5C9261A2",
    state_code: "WPD",
    name: "Western Province",
    country_code: "PG",
    lat: -5.6816069,
    lon: 144.2489081,
  },
  {
    art_state_id: "AST16FF094297",
    state_code: "OH",
    name: "Ohio",
    country_code: "US",
    lat: 40.4172871,
    lon: -82.907123,
  },
  {
    art_state_id: "AST1FBF2033E2",
    state_code: "LA",
    name: "Ladakh",
    country_code: "IN",
    lat: 34.2268475,
    lon: 77.5619419,
  },
  {
    art_state_id: "AST80AB51D63B",
    state_code: "WB",
    name: "West Bengal",
    country_code: "IN",
    lat: 22.9867569,
    lon: 87.8549755,
  },
  {
    art_state_id: "AST5D2107AE3C",
    state_code: "57",
    name: "Sinop Province",
    country_code: "TR",
    lat: 41.5594749,
    lon: 34.8580532,
  },
  {
    art_state_id: "ASTE367D8660E",
    state_code: "A",
    name: "Capital District",
    country_code: "VE",
    lat: 41.2614846,
    lon: -95.9310807,
  },
  {
    art_state_id: "AST04DDA3B63A",
    state_code: "C",
    name: "Apure",
    country_code: "VE",
    lat: 6.9269483,
    lon: -68.5247149,
  },
  {
    art_state_id: "AST96906FF6E9",
    state_code: "JAL",
    name: "Jalisco",
    country_code: "MX",
    lat: 20.6595382,
    lon: -103.3494376,
  },
  {
    art_state_id: "ASTAEF1177663",
    state_code: "RR",
    name: "Roraima",
    country_code: "BR",
    lat: 2.7375971,
    lon: -62.0750998,
  },
  {
    art_state_id: "AST45D4329A21",
    state_code: "09",
    name: "Guarda District",
    country_code: "PT",
    lat: 40.5385972,
    lon: -7.2675772,
  },
  {
    art_state_id: "AST40929E57CE",
    state_code: "DEV",
    name: "Devonshire Parish",
    country_code: "BM",
    lat: 32.3038062,
    lon: -64.7606954,
  },
  {
    art_state_id: "AST6F797E6E9F",
    state_code: "HA",
    name: "Hamilton Parish",
    country_code: "BM",
    lat: 32.3449432,
    lon: -64.72365,
  },
  {
    art_state_id: "ASTA16668B5FE",
    state_code: "HAM",
    name: "Hamilton Municipality",
    country_code: "BM",
    lat: 43.323944,
    lon: -79.9059219,
  },
  {
    art_state_id: "AST24DEF56F9E",
    state_code: "PAG",
    name: "Paget Parish",
    country_code: "BM",
    lat: 32.281074,
    lon: -64.7784787,
  },
  {
    art_state_id: "AST6205CD2F86",
    state_code: "PEM",
    name: "Pembroke Parish",
    country_code: "BM",
    lat: 32.3007672,
    lon: -64.796263,
  },
  {
    art_state_id: "AST6D36942F86",
    state_code: "SG",
    name: "Saint George's Municipality",
    country_code: "BM",
    lat: 37.0965278,
    lon: -113.5684164,
  },
  {
    art_state_id: "AST88397DCDB7",
    state_code: "SGE",
    name: "Saint George's Parish",
    country_code: "BM",
    lat: 17.1257759,
    lon: -62.5619811,
  },
  {
    art_state_id: "AST7BFBE7D361",
    state_code: "SAN",
    name: "Sandys Parish",
    country_code: "BM",
    lat: 32.2999528,
    lon: -64.8674103,
  },
  {
    art_state_id: "AST30F839358A",
    state_code: "SMI",
    name: "Smith's Parish,",
    country_code: "BM",
    lat: 32.3133966,
    lon: -64.7310588,
  },
  {
    art_state_id: "AST9664AAD846",
    state_code: "SOU",
    name: "Southampton Parish",
    country_code: "BM",
    lat: 32.2540095,
    lon: -64.8259058,
  },
  {
    art_state_id: "AST1482852A78",
    state_code: "WAR",
    name: "Warwick Parish",
    country_code: "BM",
    lat: 32.2661534,
    lon: -64.8081198,
  },
  {
    art_state_id: "AST3E79D9C8FA",
    state_code: "HUI",
    name: "Huila Department",
    country_code: "CO",
    lat: 2.5359349,
    lon: -75.5276699,
  },
  {
    art_state_id: "AST8F4967B05F",
    state_code: "XUF",
    name: "Uroševac District (Ferizaj)",
    country_code: "XK",
    lat: 42.3701844,
    lon: 21.1483281,
  },
  {
    art_state_id: "AST13BF208E77",
    state_code: "XDG",
    name: "Đakovica District (Gjakove)",
    country_code: "XK",
    lat: 42.4375756,
    lon: 20.3785438,
  },
  {
    art_state_id: "ASTBDBB086776",
    state_code: "XGJ",
    name: "Gjilan District",
    country_code: "XK",
    lat: 42.4635206,
    lon: 21.4694011,
  },
  {
    art_state_id: "AST8A01B353DD",
    state_code: "XKM",
    name: "Kosovska Mitrovica District",
    country_code: "XK",
    lat: 42.8913909,
    lon: 20.8659995,
  },
  {
    art_state_id: "ASTDDA4737F4F",
    state_code: "XPI",
    name: "Pristina (Priştine)",
    country_code: "XK",
    lat: 42.6629138,
    lon: 21.1655028,
  },
  {
    art_state_id: "ASTAA6D09E96A",
    state_code: "C",
    name: "Autonomous City Of Buenos Aires",
    country_code: "AR",
    lat: -34.6036844,
    lon: -58.3815591,
  },
  {
    art_state_id: "ASTAE8186BAEE",
    state_code: "NP",
    name: "New Providence",
    country_code: "BS",
    lat: 40.6984348,
    lon: -74.4015405,
  },
  {
    art_state_id: "AST6C8B75B2CC",
    state_code: "27",
    name: "Shumen",
    country_code: "BG",
    lat: 43.2712398,
    lon: 26.9361286,
  },
  {
    art_state_id: "AST8110DDE6BB",
    state_code: "NYL",
    name: "Yuen Long District",
    country_code: "HK",
    lat: 22.44559,
    lon: 114.02218,
  },
  {
    art_state_id: "AST8D5E03C928",
    state_code: "NTW",
    name: "Tsuen Wan District",
    country_code: "HK",
    lat: 22.36281,
    lon: 114.12907,
  },
  {
    art_state_id: "AST903A5893A3",
    state_code: "NTP",
    name: "Tai Po District",
    country_code: "KH",
    lat: 22.45085,
    lon: 114.16422,
  },
  {
    art_state_id: "ASTAB0503D17E",
    state_code: "NSK",
    name: "Sai Kung District",
    country_code: "HK",
    lat: 22.38143,
    lon: 114.27052,
  },
  {
    art_state_id: "AST4A5DB3F00D",
    state_code: "NIS",
    name: "Islands District",
    country_code: "HK",
    lat: 22.26114,
    lon: 113.94608,
  },
  {
    art_state_id: "ASTE64300BA96",
    state_code: "HCW",
    name: "Central and Western District",
    country_code: "HK",
    lat: 22.28666,
    lon: 114.15497,
  },
  {
    art_state_id: "AST6A26D78546",
    state_code: "HWC",
    name: "Wan Chai",
    country_code: "HK",
    lat: 22.27968,
    lon: 114.17168,
  },
  {
    art_state_id: "ASTD6AA64B938",
    state_code: "HEA",
    name: "Eastern",
    country_code: "HK",
    lat: 22.28411,
    lon: 114.22414,
  },
  {
    art_state_id: "AST8435482BC3",
    state_code: "HSO",
    name: "Southern",
    country_code: "HK",
    lat: 22.24725,
    lon: 114.15884,
  },
  {
    art_state_id: "ASTFC59BDB6ED",
    state_code: "KYT",
    name: "Yau Tsim Mong",
    country_code: "HK",
    lat: 22.32138,
    lon: 114.1726,
  },
  {
    art_state_id: "AST5CA10C2FC6",
    state_code: "KSS",
    name: "Sham Shui Po",
    country_code: "HK",
    lat: 22.33074,
    lon: 114.1622,
  },
  {
    art_state_id: "AST39B21AE387",
    state_code: "KKC",
    name: "Kowloon City",
    country_code: "HK",
    lat: 22.3282,
    lon: 114.19155,
  },
  {
    art_state_id: "ASTB7FD9E6B08",
    state_code: "KWT",
    name: "Wong Tai Sin",
    country_code: "HK",
    lat: 22.33353,
    lon: 114.19686,
  },
  {
    art_state_id: "AST2E89D5EAF7",
    state_code: "KKT",
    name: "Kwun Tong",
    country_code: "HK",
    lat: 22.31326,
    lon: 114.22581,
  },
  {
    art_state_id: "AST312085A08A",
    state_code: "NKT",
    name: "Kwai Tsing",
    country_code: "HK",
    lat: 22.35488,
    lon: 114.08401,
  },
  {
    art_state_id: "ASTD1E058AD07",
    state_code: "NTM",
    name: "Tuen Mun",
    country_code: "HK",
    lat: 22.39163,
    lon: 113.9770885,
  },
  {
    art_state_id: "AST47AE0889CF",
    state_code: "NNO",
    name: "North",
    country_code: "HK",
    lat: 22.49471,
    lon: 114.13812,
  },
  {
    art_state_id: "ASTB4765BA28A",
    state_code: "NST",
    name: "Sha Tin",
    country_code: "HK",
    lat: 22.38715,
    lon: 114.19534,
  },
  {
    art_state_id: "ASTD9B34F3DCE",
    state_code: "22",
    name: "Sidi Bel Abbès",
    country_code: "DZ",
    lat: 34.6806024,
    lon: -1.0999495,
  },
  {
    art_state_id: "ASTBC0D2FD80B",
    state_code: "49",
    name: "El M'ghair",
    country_code: "DZ",
    lat: 33.9540561,
    lon: 5.1346418,
  },
  {
    art_state_id: "ASTDE5E37C3BD",
    state_code: "50",
    name: "El Menia",
    country_code: "DZ",
    lat: 31.364225,
    lon: 2.5784495,
  },
  {
    art_state_id: "AST592665222B",
    state_code: "51",
    name: "Ouled Djellal",
    country_code: "DZ",
    lat: 34.4178221,
    lon: 4.9685843,
  },
  {
    art_state_id: "AST99F4578800",
    state_code: "52",
    name: "Bordj Baji Mokhtar",
    country_code: "DZ",
    lat: 22.966335,
    lon: -3.9472732,
  },
  {
    art_state_id: "AST7D59C16709",
    state_code: "53",
    name: "Béni Abbès",
    country_code: "DZ",
    lat: 30.0831042,
    lon: -2.8345052,
  },
  {
    art_state_id: "AST2313E6DF44",
    state_code: "54",
    name: "Timimoun",
    country_code: "DZ",
    lat: 29.678906,
    lon: 0.5004608,
  },
  {
    art_state_id: "ASTDE06BBC0FC",
    state_code: "55",
    name: "Touggourt",
    country_code: "DZ",
    lat: 33.1248476,
    lon: 5.7832715,
  },
  {
    art_state_id: "AST6A47642F37",
    state_code: "56",
    name: "Djanet",
    country_code: "DZ",
    lat: 23.8310872,
    lon: 8.7004672,
  },
  {
    art_state_id: "ASTF78144E5F9",
    state_code: "57",
    name: "In Salah",
    country_code: "DZ",
    lat: 27.2149229,
    lon: 1.8484396,
  },
  {
    art_state_id: "ASTBD9204A936",
    state_code: "58",
    name: "In Guezzam",
    country_code: "DZ",
    lat: 20.3864323,
    lon: 4.7789394,
  },
  {
    art_state_id: "ASTEB758BD95B",
    state_code: "MS",
    name: "Mureș County",
    country_code: "RO",
    lat: 46.5569904,
    lon: 24.6723215,
  },
  {
    art_state_id: "AST5E5154F230",
    state_code: "VGG",
    name: "Volgograd Oblast",
    country_code: "RU",
    lat: 49.2587393,
    lon: 39.8154463,
  },
  {
    art_state_id: "ASTFCA67284E9",
    state_code: "CL",
    name: "La Colle",
    country_code: "MC",
    lat: 43.7327465,
    lon: 7.4137276,
  },
  {
    art_state_id: "ASTA6FB866E02",
    state_code: "CO",
    name: "La Condamine",
    country_code: "MC",
    lat: 43.7350665,
    lon: 7.419906,
  },
  {
    art_state_id: "AST5EA28C99A2",
    state_code: "MG",
    name: "Moneghetti",
    country_code: "MC",
    lat: 43.7364927,
    lon: 7.4153383,
  },
  {
    art_state_id: "AST6AF71C5978",
    state_code: "13",
    name: "Hamadan Province",
    country_code: "IR",
    lat: 34.9193607,
    lon: 47.4832925,
  },
];
