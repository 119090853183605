import { glossary } from "../../data/local/glossary";
import { enums } from "../../data/local/enums";
import { SearchParams } from "../../data/interfaces/SearchParams";
import { conditions } from "../../data/local/conditions";

export const getGlossaryTermByKeyword = (keyword: string) => {
  return glossary.find((item) => item.keyword === keyword);
};

export const getEnumOptionsByKeyword = (keyword: string) => {
  return enums.find((item) => item.keyword === keyword);
};

export const getValueByKeywordAndEnumKey = (keyword: string, key: string) => {
  const options = getEnumOptionsByKeyword(keyword);
  let value;
  if (options) {
    value = options.options[key];
  }
  return value;
};

export const getMeshHeadingByUi = (ui: string) => {
  return conditions.find((term) => term.ui === ui)?.heading;
};

export const getUiByMeshHeading = (heading: string) => {
  return conditions.find((term) => term.heading === heading)?.ui;
};

export const getParamsByValues = (values: any) => {
  const newSearchParams: SearchParams = {
    ...(values.nct && { primary_id: values.nct }),
    ...(values.title && { title: values.title }),
    ...(values.status && { status: values.status }),
    ...(values.phase && { phase: values.phase }),
    ...(values.sponsor && { sponsor: values.sponsor }),
    ...(values.type && { type: values.type }),
    ...(values.condition && { condition: values.condition.split("-")[0] }),
    ...(values.area && { area: values.area }),
    ...(values.severity && { severity: values.severity }),
    ...(values.intervention && { intervention: values.intervention }),
    ...(values.drug && { drug: values.drug.split("-")[0] }),
    ...(values.roa && { roa: values.roa }),
    ...(values.target && { target: values.target }),
    ...(values.healthy && { healthy: values.healthy === "true" ? true : false }),
    ...(values.sex && { sex: values.sex }),
    ...(values.ancestry && { ancestry: values.ancestry }),
    ...(values.country && { country: values.country }),
  };
  for (const [key, value] of Object.entries(newSearchParams)) {
    if (Array.isArray(newSearchParams[key]) && newSearchParams[key].length === 0) {
      console.log("Deleting empty key", key, value);
      delete newSearchParams[key];
    }
  }
  return newSearchParams;
};

export const getValuesByParams = (searchParams: SearchParams) => {
  const defaultValues = {
    ...(searchParams.primary_id && { nct: searchParams.primary_id }),
    ...(searchParams.title && { title: searchParams.title }),
    ...(searchParams.status && { status: searchParams.status }),
    ...(searchParams.phase && { phase: searchParams.phase }),
    ...(searchParams.sponsor && { sponsor: searchParams.sponsor }),
    ...(searchParams.type && { type: searchParams.type }),
    ...(searchParams.condition && { condition: searchParams.condition }),
    ...(searchParams.area && { area: searchParams.area }),
    ...(searchParams.severity && { severity: searchParams.severity }),
    ...(searchParams.intervention && { intervention: searchParams.intervention }),
    ...(searchParams.drug && { drug: searchParams.drug }),
    ...(searchParams.roa && { roa: searchParams.roa }),
    ...(searchParams.target && { target: searchParams.target }),
    ...(searchParams.healthy && { healthy: searchParams.healthy.toString() }),
    ...(searchParams.sex && { sex: searchParams.sex }),
    ...(searchParams.ancestry && { ancestry: searchParams.ancestry }),
    ...(searchParams.country && { country: searchParams.country }),
  };
  return defaultValues;
};

////////// *** GENERIC FUNCTIONS *** //////////

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getKeyByValue = (object: Record<string, any>, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getNumberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
