interface Country {
  art_country_id: string;
  iso_num: number;
  alpha2: string;
  alpha3: string;
  name: string;
  ct_name: string | null;
  alias: string | null;
  race: string;
  ethnicity: string;
}

export const countries: Country[] = [
  {
    art_country_id: "ACRCE4187ECEB",
    iso_num: 4,
    alpha2: "AF",
    alpha3: "AFG",
    name: "Afghanistan",
    ct_name: "Afghanistan",
    alias: null,
    race: "MU",
    ethnicity: "Pashtun",
  },
  {
    art_country_id: "ACR1F27CA0CCE",
    iso_num: 248,
    alpha2: "AX",
    alpha3: "ALA",
    name: "Åland Islands",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "White",
  },
  {
    art_country_id: "ACR8CF6D2556C",
    iso_num: 8,
    alpha2: "AL",
    alpha3: "ALB",
    name: "Albania",
    ct_name: "Albania",
    alias: null,
    race: "C",
    ethnicity: "Albanian",
  },
  {
    art_country_id: "ACRFBC19AA539",
    iso_num: 12,
    alpha2: "DZ",
    alpha3: "DZA",
    name: "Algeria",
    ct_name: "Algeria",
    alias: null,
    race: "MU",
    ethnicity: "Arab-Berber",
  },
  {
    art_country_id: "ACR813B4BB26D",
    iso_num: 16,
    alpha2: "AS",
    alpha3: "ASM",
    name: "American Samoa",
    ct_name: "American Samoa",
    alias: null,
    race: "I",
    ethnicity: "Samoan",
  },
  {
    art_country_id: "ACR88EE60B57D",
    iso_num: 20,
    alpha2: "AD",
    alpha3: "AND",
    name: "Andorra",
    ct_name: "Andorra",
    alias: null,
    race: "C",
    ethnicity: "Spanish",
  },
  {
    art_country_id: "ACR511E540244",
    iso_num: 24,
    alpha2: "AO",
    alpha3: "AGO",
    name: "Angola",
    ct_name: "Angola",
    alias: null,
    race: "B",
    ethnicity: "Ovimbundu",
  },
  {
    art_country_id: "ACR94E1DD29AB",
    iso_num: 660,
    alpha2: "AI",
    alpha3: "AIA",
    name: "Anguilla",
    ct_name: null,
    alias: null,
    race: "B",
    ethnicity: "Anguillan",
  },
  {
    art_country_id: "ACRCFF796153B",
    iso_num: 10,
    alpha2: "AQ",
    alpha3: "ATA",
    name: "Antarctica",
    ct_name: "Antarctica",
    alias: null,
    race: "C",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR3709C1BF39",
    iso_num: 28,
    alpha2: "AG",
    alpha3: "ATG",
    name: "Antigua and Barbuda",
    ct_name: "Antigua and Barbuda",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR5694B29809",
    iso_num: 32,
    alpha2: "AR",
    alpha3: "ARG",
    name: "Argentina",
    ct_name: "Argentina",
    alias: null,
    race: "C",
    ethnicity: "Spanish",
  },
  {
    art_country_id: "ACR007D2A8D64",
    iso_num: 51,
    alpha2: "AM",
    alpha3: "ARM",
    name: "Armenia",
    ct_name: "Armenia",
    alias: null,
    race: "C",
    ethnicity: "Armenian",
  },
  {
    art_country_id: "ACR838CD85AC9",
    iso_num: 533,
    alpha2: "AW",
    alpha3: "ABW",
    name: "Aruba",
    ct_name: "Aruba",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR4EF1DD346D",
    iso_num: 36,
    alpha2: "AU",
    alpha3: "AUS",
    name: "Australia",
    ct_name: "Australia",
    alias: null,
    race: "C",
    ethnicity: "White",
  },
  {
    art_country_id: "ACRA5B041199E",
    iso_num: 40,
    alpha2: "AT",
    alpha3: "AUT",
    name: "Austria",
    ct_name: "Austria",
    alias: null,
    race: "C",
    ethnicity: "White",
  },
  {
    art_country_id: "ACR60D25AD2D7",
    iso_num: 31,
    alpha2: "AZ",
    alpha3: "AZE",
    name: "Azerbaijan",
    ct_name: "Azerbaijan",
    alias: null,
    race: "C",
    ethnicity: "Azeri",
  },
  {
    art_country_id: "ACR7E5C42824F",
    iso_num: 44,
    alpha2: "BS",
    alpha3: "BHS",
    name: "Bahamas",
    ct_name: "Bahamas",
    alias: null,
    race: "B",
    ethnicity: "Afro-Bahamians",
  },
  {
    art_country_id: "ACR773EB6F202",
    iso_num: 48,
    alpha2: "BH",
    alpha3: "BHR",
    name: "Bahrain",
    ct_name: "Bahrain",
    alias: null,
    race: "C",
    ethnicity: "Bahraini",
  },
  {
    art_country_id: "ACR360727AB7F",
    iso_num: 50,
    alpha2: "BD",
    alpha3: "BGD",
    name: "Bangladesh",
    ct_name: "Bangladesh",
    alias: null,
    race: "C",
    ethnicity: "Bengali",
  },
  {
    art_country_id: "ACR013F0D9D0D",
    iso_num: 52,
    alpha2: "BB",
    alpha3: "BRB",
    name: "Barbados",
    ct_name: "Barbados",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR1D94D47CAC",
    iso_num: 112,
    alpha2: "BY",
    alpha3: "BLR",
    name: "Belarus",
    ct_name: "Belarus",
    alias: null,
    race: "C",
    ethnicity: "Belorussian",
  },
  {
    art_country_id: "ACRDB34AE0925",
    iso_num: 56,
    alpha2: "BE",
    alpha3: "BEL",
    name: "Belgium",
    ct_name: "Belgium",
    alias: null,
    race: "C",
    ethnicity: "Fleming",
  },
  {
    art_country_id: "ACRFB8D9B9F63",
    iso_num: 84,
    alpha2: "BZ",
    alpha3: "BLZ",
    name: "Belize",
    ct_name: "Belize",
    alias: null,
    race: "MU",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACR086D1BCC16",
    iso_num: 204,
    alpha2: "BJ",
    alpha3: "BEN",
    name: "Benin",
    ct_name: "Benin",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR48A64F5A18",
    iso_num: 60,
    alpha2: "BM",
    alpha3: "BMU",
    name: "Bermuda",
    ct_name: "Bermuda",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR8F36A02278",
    iso_num: 64,
    alpha2: "BT",
    alpha3: "BTN",
    name: "Bhutan",
    ct_name: "Bhutan",
    alias: null,
    race: "A",
    ethnicity: "Bhote",
  },
  {
    art_country_id: "ACRBE79FDE1E2",
    iso_num: 68,
    alpha2: "BO",
    alpha3: "BOL",
    name: "Bolivia (Plurinational State of)",
    ct_name: "Bolivia",
    alias: null,
    race: "MU",
    ethnicity: "Quechua",
  },
  {
    art_country_id: "ACRAB1457398B",
    iso_num: 535,
    alpha2: "BQ",
    alpha3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR13285A2337",
    iso_num: 70,
    alpha2: "BA",
    alpha3: "BIH",
    name: "Bosnia and Herzegovina",
    ct_name: "Bosnia and Herzegovina",
    alias: null,
    race: "C",
    ethnicity: "Bosniak",
  },
  {
    art_country_id: "ACR2A49E44752",
    iso_num: 72,
    alpha2: "BW",
    alpha3: "BWA",
    name: "Botswana",
    ct_name: "Botswana",
    alias: null,
    race: "B",
    ethnicity: "Tswana",
  },
  {
    art_country_id: "ACR42B7A58E3A",
    iso_num: 74,
    alpha2: "BV",
    alpha3: "BVT",
    name: "Bouvet Island",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR2F8517AE35",
    iso_num: 76,
    alpha2: "BR",
    alpha3: "BRA",
    name: "Brazil",
    ct_name: "Brazil",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR4B997184D3",
    iso_num: 86,
    alpha2: "IO",
    alpha3: "IOT",
    name: "British Indian Ocean Territory",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "British",
  },
  {
    art_country_id: "ACRA14021D45B",
    iso_num: 96,
    alpha2: "BN",
    alpha3: "BRN",
    name: "Brunei Darussalam",
    ct_name: "Brunei Darussalam",
    alias: null,
    race: "MU",
    ethnicity: "Malay",
  },
  {
    art_country_id: "ACR811B62716C",
    iso_num: 100,
    alpha2: "BG",
    alpha3: "BGR",
    name: "Bulgaria",
    ct_name: "Bulgaria",
    alias: null,
    race: "C",
    ethnicity: "Bulgarian",
  },
  {
    art_country_id: "ACREDF1A0B8FC",
    iso_num: 854,
    alpha2: "BF",
    alpha3: "BFA",
    name: "Burkina Faso",
    ct_name: "Burkina Faso",
    alias: null,
    race: "B",
    ethnicity: "Mossi",
  },
  {
    art_country_id: "ACR53FB3EB643",
    iso_num: 108,
    alpha2: "BI",
    alpha3: "BDI",
    name: "Burundi",
    ct_name: "Burundi",
    alias: null,
    race: "B",
    ethnicity: "Hutu",
  },
  {
    art_country_id: "ACR74F12BEB92",
    iso_num: 132,
    alpha2: "CV",
    alpha3: "CPV",
    name: "Cabo Verde",
    ct_name: "Cape Verde",
    alias: null,
    race: "MU",
    ethnicity: "Creole",
  },
  {
    art_country_id: "ACRC132CC1B5E",
    iso_num: 116,
    alpha2: "KH",
    alpha3: "KHM",
    name: "Cambodia",
    ct_name: "Cambodia",
    alias: null,
    race: "A",
    ethnicity: "Khmer",
  },
  {
    art_country_id: "ACR9ABE4F2393",
    iso_num: 120,
    alpha2: "CM",
    alpha3: "CMR",
    name: "Cameroon",
    ct_name: "Cameroon",
    alias: null,
    race: "B",
    ethnicity: "Cameroon Highlanders",
  },
  {
    art_country_id: "ACREDF3B95386",
    iso_num: 124,
    alpha2: "CA",
    alpha3: "CAN",
    name: "Canada",
    ct_name: "Canada",
    alias: null,
    race: "C",
    ethnicity: "French",
  },
  {
    art_country_id: "ACR4AC5073B31",
    iso_num: 136,
    alpha2: "KY",
    alpha3: "CYM",
    name: "Cayman Islands",
    ct_name: "Cayman Islands",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR4B3476DF62",
    iso_num: 140,
    alpha2: "CF",
    alpha3: "CAF",
    name: "Central African Republic",
    ct_name: "Central African Republic",
    alias: null,
    race: "B",
    ethnicity: "Baya",
  },
  {
    art_country_id: "ACR1D5E8855D7",
    iso_num: 148,
    alpha2: "TD",
    alpha3: "TCD",
    name: "Chad",
    ct_name: "Chad",
    alias: null,
    race: "B",
    ethnicity: "Sara",
  },
  {
    art_country_id: "ACR9ECFA62255",
    iso_num: 152,
    alpha2: "CL",
    alpha3: "CHL",
    name: "Chile",
    ct_name: "Chile",
    alias: null,
    race: "C",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRDFA8F1F190",
    iso_num: 156,
    alpha2: "CN",
    alpha3: "CHN",
    name: "China",
    ct_name: "China",
    alias: null,
    race: "A",
    ethnicity: "Chinese",
  },
  {
    art_country_id: "ACRCD05B8BE07",
    iso_num: 162,
    alpha2: "CX",
    alpha3: "CXR",
    name: "Christmas Island",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "Chinese",
  },
  {
    art_country_id: "ACRA2ADC11977",
    iso_num: 166,
    alpha2: "CC",
    alpha3: "CCK",
    name: "Cocos (Keeling) Islands",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR780665EDBE",
    iso_num: 170,
    alpha2: "CO",
    alpha3: "COL",
    name: "Colombia",
    ct_name: "Colombia",
    alias: null,
    race: "C",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACRA0B17B3FA7",
    iso_num: 174,
    alpha2: "KM",
    alpha3: "COM",
    name: "Comoros",
    ct_name: "Comoros",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR6562EDC95B",
    iso_num: 178,
    alpha2: "CG",
    alpha3: "COG",
    name: "Congo",
    ct_name: "Congo",
    alias: null,
    race: "B",
    ethnicity: "Kongo",
  },
  {
    art_country_id: "ACR14F0614340",
    iso_num: 180,
    alpha2: "CD",
    alpha3: "COD",
    name: "Congo, Democratic Republic of the",
    ct_name: "Congo, The Democratic Republic of the",
    alias: "The Democratic Republic of the Congo",
    race: "B",
    ethnicity: "Bantu",
  },
  {
    art_country_id: "ACRFA656574A1",
    iso_num: 184,
    alpha2: "CK",
    alpha3: "COK",
    name: "Cook Islands",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Māori",
  },
  {
    art_country_id: "ACR7A0E66242D",
    iso_num: 188,
    alpha2: "CR",
    alpha3: "CRI",
    name: "Costa Rica",
    ct_name: "Costa Rica",
    alias: null,
    race: "C",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR71A4DC5589",
    iso_num: 384,
    alpha2: "CI",
    alpha3: "CIV",
    name: "Côte d'Ivoire",
    ct_name: "Côte D'Ivoire",
    alias: null,
    race: "B",
    ethnicity: "Akan",
  },
  {
    art_country_id: "ACRBC73D9100F",
    iso_num: 191,
    alpha2: "HR",
    alpha3: "HRV",
    name: "Croatia",
    ct_name: "Croatia",
    alias: null,
    race: "C",
    ethnicity: "Croat",
  },
  {
    art_country_id: "ACR851FEB0233",
    iso_num: 192,
    alpha2: "CU",
    alpha3: "CUB",
    name: "Cuba",
    ct_name: "Cuba",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR68E0EA37E0",
    iso_num: 531,
    alpha2: "CW",
    alpha3: "CUW",
    name: "Curaçao",
    ct_name: null,
    alias: null,
    race: "B",
    ethnicity: "Curaçao",
  },
  {
    art_country_id: "ACR5830E07780",
    iso_num: 196,
    alpha2: "CY",
    alpha3: "CYP",
    name: "Cyprus",
    ct_name: "Cyprus",
    alias: null,
    race: "C",
    ethnicity: "Greek",
  },
  {
    art_country_id: "ACR70167D3A01",
    iso_num: 203,
    alpha2: "CZ",
    alpha3: "CZE",
    name: "Czechia",
    ct_name: "Czechia",
    alias: "Czech Republic",
    race: "C",
    ethnicity: "Czech",
  },
  {
    art_country_id: "ACRA35CB6ECB0",
    iso_num: 208,
    alpha2: "DK",
    alpha3: "DNK",
    name: "Denmark",
    ct_name: "Denmark",
    alias: null,
    race: "C",
    ethnicity: "Scandinavian",
  },
  {
    art_country_id: "ACR74FA498279",
    iso_num: 262,
    alpha2: "DJ",
    alpha3: "DJI",
    name: "Djibouti",
    ct_name: "Djibouti",
    alias: null,
    race: "B",
    ethnicity: "Somali",
  },
  {
    art_country_id: "ACR72456C7151",
    iso_num: 212,
    alpha2: "DM",
    alpha3: "DMA",
    name: "Dominica",
    ct_name: "Dominica",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR37242E4579",
    iso_num: 214,
    alpha2: "DO",
    alpha3: "DOM",
    name: "Dominican Republic",
    ct_name: "Dominican Republic",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRDE6150150D",
    iso_num: 218,
    alpha2: "EC",
    alpha3: "ECU",
    name: "Ecuador",
    ct_name: "Ecuador",
    alias: null,
    race: "C",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACRD71AD10CB0",
    iso_num: 818,
    alpha2: "EG",
    alpha3: "EGY",
    name: "Egypt",
    ct_name: "Egypt",
    alias: null,
    race: "C",
    ethnicity: "Egyptian",
  },
  {
    art_country_id: "ACR6716F4E37B",
    iso_num: 222,
    alpha2: "SV",
    alpha3: "SLV",
    name: "El Salvador",
    ct_name: "El Salvador",
    alias: null,
    race: "C",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACRA63AEF6D43",
    iso_num: 226,
    alpha2: "GQ",
    alpha3: "GNQ",
    name: "Equatorial Guinea",
    ct_name: "Equatorial Guinea",
    alias: null,
    race: "B",
    ethnicity: "Fang",
  },
  {
    art_country_id: "ACRB5D89E89C0",
    iso_num: 232,
    alpha2: "ER",
    alpha3: "ERI",
    name: "Eritrea",
    ct_name: "Eritrea",
    alias: null,
    race: "B",
    ethnicity: "Tigrinya",
  },
  {
    art_country_id: "ACR82F56FB7A1",
    iso_num: 233,
    alpha2: "EE",
    alpha3: "EST",
    name: "Estonia",
    ct_name: "Estonia",
    alias: null,
    race: "C",
    ethnicity: "Estonian",
  },
  {
    art_country_id: "ACR243E336B89",
    iso_num: 748,
    alpha2: "SZ",
    alpha3: "SWZ",
    name: "Eswatini",
    ct_name: "Swaziland",
    alias: null,
    race: "B",
    ethnicity: "Swazi",
  },
  {
    art_country_id: "ACR8841DBEB44",
    iso_num: 231,
    alpha2: "ET",
    alpha3: "ETH",
    name: "Ethiopia",
    ct_name: "Ethiopia",
    alias: null,
    race: "B",
    ethnicity: "Oromo",
  },
  {
    art_country_id: "ACR5B7013D8DB",
    iso_num: 238,
    alpha2: "FK",
    alpha3: "FLK",
    name: "Falkland Islands (Malvinas)",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "Falklanders",
  },
  {
    art_country_id: "ACRA1000941B8",
    iso_num: 234,
    alpha2: "FO",
    alpha3: "FRO",
    name: "Faroe Islands",
    ct_name: "Faroe Islands",
    alias: null,
    race: "C",
    ethnicity: "Faroese",
  },
  {
    art_country_id: "ACR404199EAEA",
    iso_num: 242,
    alpha2: "FJ",
    alpha3: "FJI",
    name: "Fiji",
    ct_name: "Fiji",
    alias: null,
    race: "MU",
    ethnicity: "Fijian",
  },
  {
    art_country_id: "ACR22AC9DD19E",
    iso_num: 246,
    alpha2: "FI",
    alpha3: "FIN",
    name: "Finland",
    ct_name: "Finland",
    alias: null,
    race: "C",
    ethnicity: "Finn",
  },
  {
    art_country_id: "ACR2DC34539D4",
    iso_num: 250,
    alpha2: "FR",
    alpha3: "FRA",
    name: "France",
    ct_name: "France",
    alias: null,
    race: "C",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRC59E31248B",
    iso_num: 254,
    alpha2: "GF",
    alpha3: "GUF",
    name: "French Guiana",
    ct_name: "French Guiana",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR374528E688",
    iso_num: 258,
    alpha2: "PF",
    alpha3: "PYF",
    name: "French Polynesia",
    ct_name: "French Polynesia",
    alias: null,
    race: "MU",
    ethnicity: "Polynesian",
  },
  {
    art_country_id: "ACR48D3E9D5F3",
    iso_num: 260,
    alpha2: "TF",
    alpha3: "ATF",
    name: "French Southern Territories",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR48E704EBD6",
    iso_num: 266,
    alpha2: "GA",
    alpha3: "GAB",
    name: "Gabon",
    ct_name: "Gabon",
    alias: null,
    race: "B",
    ethnicity: "Bantu",
  },
  {
    art_country_id: "ACRAF1E444EFE",
    iso_num: 270,
    alpha2: "GM",
    alpha3: "GMB",
    name: "Gambia",
    ct_name: "Gambia",
    alias: null,
    race: "B",
    ethnicity: "Mandinka",
  },
  {
    art_country_id: "ACR6E935DB782",
    iso_num: 268,
    alpha2: "GE",
    alpha3: "GEO",
    name: "Georgia",
    ct_name: "Georgia",
    alias: null,
    race: "C",
    ethnicity: "Georgian",
  },
  {
    art_country_id: "ACRF9DC9DA4EF",
    iso_num: 276,
    alpha2: "DE",
    alpha3: "DEU",
    name: "Germany",
    ct_name: "Germany",
    alias: null,
    race: "C",
    ethnicity: "German",
  },
  {
    art_country_id: "ACR0646F1EDDA",
    iso_num: 288,
    alpha2: "GH",
    alpha3: "GHA",
    name: "Ghana",
    ct_name: "Ghana",
    alias: null,
    race: "B",
    ethnicity: "Akan",
  },
  {
    art_country_id: "ACR58B4A77F6D",
    iso_num: 292,
    alpha2: "GI",
    alpha3: "GIB",
    name: "Gibraltar",
    ct_name: "Gibraltar",
    alias: null,
    race: "C",
    ethnicity: "Gibraltarian",
  },
  {
    art_country_id: "ACR01558C5E6D",
    iso_num: 300,
    alpha2: "GR",
    alpha3: "GRC",
    name: "Greece",
    ct_name: "Greece",
    alias: null,
    race: "C",
    ethnicity: "Greek",
  },
  {
    art_country_id: "ACR28B9EEA293",
    iso_num: 304,
    alpha2: "GL",
    alpha3: "GRL",
    name: "Greenland",
    ct_name: "Greenland",
    alias: null,
    race: "A",
    ethnicity: "Inuit",
  },
  {
    art_country_id: "ACR7F71268F44",
    iso_num: 308,
    alpha2: "GD",
    alpha3: "GRD",
    name: "Grenada",
    ct_name: "Grenada",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR853B980235",
    iso_num: 312,
    alpha2: "GP",
    alpha3: "GLP",
    name: "Guadeloupe",
    ct_name: "Guadeloupe",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR98F24EDFAD",
    iso_num: 316,
    alpha2: "GU",
    alpha3: "GUM",
    name: "Guam",
    ct_name: "Guam",
    alias: null,
    race: "MU",
    ethnicity: "Chamorro",
  },
  {
    art_country_id: "ACR6BEFE9C87D",
    iso_num: 320,
    alpha2: "GT",
    alpha3: "GTM",
    name: "Guatemala",
    ct_name: "Guatemala",
    alias: null,
    race: "C",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACRBCEB91C455",
    iso_num: 831,
    alpha2: "GG",
    alpha3: "GGY",
    name: "Guernsey",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "Guernsey",
  },
  {
    art_country_id: "ACR07960D91DA",
    iso_num: 324,
    alpha2: "GN",
    alpha3: "GIN",
    name: "Guinea",
    ct_name: "Guinea",
    alias: null,
    race: "B",
    ethnicity: "Peuhl",
  },
  {
    art_country_id: "ACR950BB55898",
    iso_num: 624,
    alpha2: "GW",
    alpha3: "GNB",
    name: "Guinea-Bissau",
    ct_name: "Guinea-Bissau",
    alias: null,
    race: "B",
    ethnicity: "Balanta",
  },
  {
    art_country_id: "ACR081C76C0E6",
    iso_num: 328,
    alpha2: "GY",
    alpha3: "GUY",
    name: "Guyana",
    ct_name: "Guyana",
    alias: null,
    race: "MU",
    ethnicity: "East Indian",
  },
  {
    art_country_id: "ACRBA431F2BD4",
    iso_num: 332,
    alpha2: "HT",
    alpha3: "HTI",
    name: "Haiti",
    ct_name: "Haiti",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR3CEFE84700",
    iso_num: 334,
    alpha2: "HM",
    alpha3: "HMD",
    name: "Heard Island and McDonald Islands",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR6619A382BF",
    iso_num: 336,
    alpha2: "VA",
    alpha3: "VAT",
    name: "Holy See",
    ct_name: "Holy See (Vatican City State)",
    alias: null,
    race: "C",
    ethnicity: "Italian",
  },
  {
    art_country_id: "ACRB5D1373361",
    iso_num: 340,
    alpha2: "HN",
    alpha3: "HND",
    name: "Honduras",
    ct_name: "Honduras",
    alias: null,
    race: "C",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACR1F1AF71E2B",
    iso_num: 344,
    alpha2: "HK",
    alpha3: "HKG",
    name: "Hong Kong",
    ct_name: "Hong Kong",
    alias: null,
    race: "A",
    ethnicity: "Chinese",
  },
  {
    art_country_id: "ACRDB367C5F72",
    iso_num: 348,
    alpha2: "HU",
    alpha3: "HUN",
    name: "Hungary",
    ct_name: "Hungary",
    alias: null,
    race: "C",
    ethnicity: "Hungarian",
  },
  {
    art_country_id: "ACR0BFB65BED6",
    iso_num: 352,
    alpha2: "IS",
    alpha3: "ISL",
    name: "Iceland",
    ct_name: "Iceland",
    alias: null,
    race: "C",
    ethnicity: "Celtic",
  },
  {
    art_country_id: "ACREABFCFB948",
    iso_num: 356,
    alpha2: "IN",
    alpha3: "IND",
    name: "India",
    ct_name: "India",
    alias: null,
    race: "C",
    ethnicity: "Indo-Aryan",
  },
  {
    art_country_id: "ACR10EABEF80F",
    iso_num: 360,
    alpha2: "ID",
    alpha3: "IDN",
    name: "Indonesia",
    ct_name: "Indonesia",
    alias: null,
    race: "A",
    ethnicity: "Javanese",
  },
  {
    art_country_id: "ACR93A2C39E9E",
    iso_num: 364,
    alpha2: "IR",
    alpha3: "IRN",
    name: "Iran (Islamic Republic of)",
    ct_name: "Iran, Islamic Republic of",
    alias: null,
    race: "C",
    ethnicity: "Persian",
  },
  {
    art_country_id: "ACR7706C915EA",
    iso_num: 368,
    alpha2: "IQ",
    alpha3: "IRQ",
    name: "Iraq",
    ct_name: "Iraq",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR5583421388",
    iso_num: 372,
    alpha2: "IE",
    alpha3: "IRL",
    name: "Ireland",
    ct_name: "Ireland",
    alias: null,
    race: "C",
    ethnicity: "Celtic",
  },
  {
    art_country_id: "ACRD2AA20CC9E",
    iso_num: 833,
    alpha2: "IM",
    alpha3: "IMN",
    name: "Isle of Man",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "Manx",
  },
  {
    art_country_id: "ACR5EE5475263",
    iso_num: 376,
    alpha2: "IL",
    alpha3: "ISR",
    name: "Israel",
    ct_name: "Israel",
    alias: null,
    race: "C",
    ethnicity: "Jewish",
  },
  {
    art_country_id: "ACR4DFEDFBE41",
    iso_num: 380,
    alpha2: "IT",
    alpha3: "ITA",
    name: "Italy",
    ct_name: "Italy",
    alias: null,
    race: "C",
    ethnicity: "Italian",
  },
  {
    art_country_id: "ACR6AE262E5D0",
    iso_num: 388,
    alpha2: "JM",
    alpha3: "JAM",
    name: "Jamaica",
    ct_name: "Jamaica",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR66B5482BDA",
    iso_num: 392,
    alpha2: "JP",
    alpha3: "JPN",
    name: "Japan",
    ct_name: "Japan",
    alias: null,
    race: "A",
    ethnicity: "Japanese",
  },
  {
    art_country_id: "ACR4D5D9CE7BA",
    iso_num: 832,
    alpha2: "JE",
    alpha3: "JEY",
    name: "Jersey",
    ct_name: "Jersey",
    alias: null,
    race: "MU",
    ethnicity: "Jersey",
  },
  {
    art_country_id: "ACRB8169E76C8",
    iso_num: 400,
    alpha2: "JO",
    alpha3: "JOR",
    name: "Jordan",
    ct_name: "Jordan",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR432EA801D1",
    iso_num: 398,
    alpha2: "KZ",
    alpha3: "KAZ",
    name: "Kazakhstan",
    ct_name: "Kazakhstan",
    alias: null,
    race: "MU",
    ethnicity: "Kazak",
  },
  {
    art_country_id: "ACRD5D47E48C5",
    iso_num: 404,
    alpha2: "KE",
    alpha3: "KEN",
    name: "Kenya",
    ct_name: "Kenya",
    alias: null,
    race: "B",
    ethnicity: "Kikuyu",
  },
  {
    art_country_id: "ACR185534B0F4",
    iso_num: 296,
    alpha2: "KI",
    alpha3: "KIR",
    name: "Kiribati",
    ct_name: "Kiribati",
    alias: null,
    race: "I",
    ethnicity: "Micronesian",
  },
  {
    art_country_id: "ACR7540DE74B7",
    iso_num: 408,
    alpha2: "KP",
    alpha3: "PRK",
    name: "Korea (Democratic People's Republic of)",
    ct_name: "Korea, Democratic People's Republic of",
    alias: null,
    race: "A",
    ethnicity: "Korean",
  },
  {
    art_country_id: "ACR349ACEC9C6",
    iso_num: 410,
    alpha2: "KR",
    alpha3: "KOR",
    name: "Korea, Republic of",
    ct_name: "Korea, Republic of",
    alias: null,
    race: "A",
    ethnicity: "Korean",
  },
  {
    art_country_id: "ACR5B51CDD88B",
    iso_num: 414,
    alpha2: "KW",
    alpha3: "KWT",
    name: "Kuwait",
    ct_name: "Kuwait",
    alias: null,
    race: "C",
    ethnicity: "Kuwaiti",
  },
  {
    art_country_id: "ACR8AA25202EF",
    iso_num: 417,
    alpha2: "KG",
    alpha3: "KGZ",
    name: "Kyrgyzstan",
    ct_name: "Kyrgyzstan",
    alias: null,
    race: "A",
    ethnicity: "Kyrgyz",
  },
  {
    art_country_id: "ACR76256518D9",
    iso_num: 418,
    alpha2: "LA",
    alpha3: "LAO",
    name: "Lao People's Democratic Republic",
    ct_name: "Lao People's Democratic Republic",
    alias: null,
    race: "A",
    ethnicity: "Lao Loum",
  },
  {
    art_country_id: "ACR1CE35087EA",
    iso_num: 428,
    alpha2: "LV",
    alpha3: "LVA",
    name: "Latvia",
    ct_name: "Latvia",
    alias: null,
    race: "C",
    ethnicity: "Latvian",
  },
  {
    art_country_id: "ACRD1AD9C0796",
    iso_num: 422,
    alpha2: "LB",
    alpha3: "LBN",
    name: "Lebanon",
    ct_name: "Lebanon",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACRFC258E137E",
    iso_num: 426,
    alpha2: "LS",
    alpha3: "LSO",
    name: "Lesotho",
    ct_name: "Lesotho",
    alias: null,
    race: "B",
    ethnicity: "Sotho",
  },
  {
    art_country_id: "ACR03D21289B1",
    iso_num: 430,
    alpha2: "LR",
    alpha3: "LBR",
    name: "Liberia",
    ct_name: "Liberia",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRE875ADE40F",
    iso_num: 434,
    alpha2: "LY",
    alpha3: "LBY",
    name: "Libya",
    ct_name: "Libyan Arab Jamahiriya",
    alias: null,
    race: "MU",
    ethnicity: "Arab-Berber",
  },
  {
    art_country_id: "ACR9F5012BEC2",
    iso_num: 438,
    alpha2: "LI",
    alpha3: "LIE",
    name: "Liechtenstein",
    ct_name: "Liechtenstein",
    alias: null,
    race: "C",
    ethnicity: "Alemannic",
  },
  {
    art_country_id: "ACR1ACE405618",
    iso_num: 440,
    alpha2: "LT",
    alpha3: "LTU",
    name: "Lithuania",
    ct_name: "Lithuania",
    alias: null,
    race: "C",
    ethnicity: "Lithuanian",
  },
  {
    art_country_id: "ACR0132DB8DDD",
    iso_num: 442,
    alpha2: "LU",
    alpha3: "LUX",
    name: "Luxembourg",
    ct_name: "Luxembourg",
    alias: null,
    race: "C",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR7D562CF182",
    iso_num: 446,
    alpha2: "MO",
    alpha3: "MAC",
    name: "Macao",
    ct_name: "Macau",
    alias: null,
    race: "A",
    ethnicity: "Chinese",
  },
  {
    art_country_id: "ACR81287449DF",
    iso_num: 450,
    alpha2: "MG",
    alpha3: "MDG",
    name: "Madagascar",
    ct_name: "Madagascar",
    alias: null,
    race: "MU",
    ethnicity: "Malayo-Indonesian",
  },
  {
    art_country_id: "ACRC14D7B1C8C",
    iso_num: 454,
    alpha2: "MW",
    alpha3: "MWI",
    name: "Malawi",
    ct_name: "Malawi",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR4A6899161F",
    iso_num: 458,
    alpha2: "MY",
    alpha3: "MYS",
    name: "Malaysia",
    ct_name: "Malaysia",
    alias: null,
    race: "MU",
    ethnicity: "Malay",
  },
  {
    art_country_id: "ACR3AE2CB1D0F",
    iso_num: 462,
    alpha2: "MV",
    alpha3: "MDV",
    name: "Maldives",
    ct_name: "Maldives",
    alias: null,
    race: "C",
    ethnicity: "Dhivehin",
  },
  {
    art_country_id: "ACRDAA4D1848C",
    iso_num: 466,
    alpha2: "ML",
    alpha3: "MLI",
    name: "Mali",
    ct_name: "Mali",
    alias: null,
    race: "B",
    ethnicity: "Mande",
  },
  {
    art_country_id: "ACRF66251FEE6",
    iso_num: 470,
    alpha2: "MT",
    alpha3: "MLT",
    name: "Malta",
    ct_name: "Malta",
    alias: null,
    race: "C",
    ethnicity: "Maltese",
  },
  {
    art_country_id: "ACR972738F074",
    iso_num: 584,
    alpha2: "MH",
    alpha3: "MHL",
    name: "Marshall Islands",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Micronesian",
  },
  {
    art_country_id: "ACR050DC7A23A",
    iso_num: 474,
    alpha2: "MQ",
    alpha3: "MTQ",
    name: "Martinique",
    ct_name: "Martinique",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRE7CB402867",
    iso_num: 478,
    alpha2: "MR",
    alpha3: "MRT",
    name: "Mauritania",
    ct_name: "Mauritania",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR3605836FAD",
    iso_num: 480,
    alpha2: "MU",
    alpha3: "MUS",
    name: "Mauritius",
    ct_name: "Mauritius",
    alias: null,
    race: "MU",
    ethnicity: "Indo-Mauritian",
  },
  {
    art_country_id: "ACR4838438509",
    iso_num: 175,
    alpha2: "YT",
    alpha3: "MYT",
    name: "Mayotte",
    ct_name: "Mayotte",
    alias: null,
    race: "MU",
    ethnicity: "Comorian",
  },
  {
    art_country_id: "ACRC565D0DED7",
    iso_num: 484,
    alpha2: "MX",
    alpha3: "MEX",
    name: "Mexico",
    ct_name: "Mexico",
    alias: null,
    race: "C",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACR126BE598C5",
    iso_num: 583,
    alpha2: "FM",
    alpha3: "FSM",
    name: "Micronesia (Federated States of)",
    ct_name: "Federated States of Micronesia",
    alias: null,
    race: "I",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR136509BA77",
    iso_num: 498,
    alpha2: "MD",
    alpha3: "MDA",
    name: "Moldova, Republic of",
    ct_name: "Moldova, Republic of",
    alias: null,
    race: "C",
    ethnicity: "Moldavian",
  },
  {
    art_country_id: "ACR7E85EFC64C",
    iso_num: 492,
    alpha2: "MC",
    alpha3: "MCO",
    name: "Monaco",
    ct_name: "Monaco",
    alias: null,
    race: "C",
    ethnicity: "French",
  },
  {
    art_country_id: "ACRE385460B77",
    iso_num: 496,
    alpha2: "MN",
    alpha3: "MNG",
    name: "Mongolia",
    ct_name: "Mongolia",
    alias: null,
    race: "A",
    ethnicity: "Mongol",
  },
  {
    art_country_id: "ACR839F6F87A1",
    iso_num: 499,
    alpha2: "ME",
    alpha3: "MNE",
    name: "Montenegro",
    ct_name: "Montenegro",
    alias: "Former Serbia and Montenegro",
    race: "C",
    ethnicity: "Montenegrin",
  },
  {
    art_country_id: "ACR3727A8E87D",
    iso_num: 500,
    alpha2: "MS",
    alpha3: "MSR",
    name: "Montserrat",
    ct_name: "Montserrat",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR386C2FCE50",
    iso_num: 504,
    alpha2: "MA",
    alpha3: "MAR",
    name: "Morocco",
    ct_name: "Morocco",
    alias: null,
    race: "MU",
    ethnicity: "Arab-Berber",
  },
  {
    art_country_id: "ACRA90E95A6E3",
    iso_num: 508,
    alpha2: "MZ",
    alpha3: "MOZ",
    name: "Mozambique",
    ct_name: "Mozambique",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR15B4AC7AD2",
    iso_num: 104,
    alpha2: "MM",
    alpha3: "MMR",
    name: "Myanmar",
    ct_name: "Myanmar",
    alias: null,
    race: "MU",
    ethnicity: "Burman",
  },
  {
    art_country_id: "ACR14EE25439B",
    iso_num: 516,
    alpha2: "NA",
    alpha3: "NAM",
    name: "Namibia",
    ct_name: "Namibia",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRE74A794015",
    iso_num: 520,
    alpha2: "NR",
    alpha3: "NRU",
    name: "Nauru",
    ct_name: "Nauru",
    alias: null,
    race: "I",
    ethnicity: "Nauruan",
  },
  {
    art_country_id: "ACRFE961C5227",
    iso_num: 524,
    alpha2: "NP",
    alpha3: "NPL",
    name: "Nepal",
    ct_name: "Nepal",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRCD58A56DCE",
    iso_num: 528,
    alpha2: "NL",
    alpha3: "NLD",
    name: "Netherlands",
    ct_name: "Netherlands",
    alias: null,
    race: "C",
    ethnicity: "Dutch",
  },
  {
    art_country_id: "ACR654F33E80A",
    iso_num: 530,
    alpha2: "AN",
    alpha3: "ANT",
    name: "Netherlands Antilles",
    ct_name: "Netherlands Antilles",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR19E8A8B765",
    iso_num: 540,
    alpha2: "NC",
    alpha3: "NCL",
    name: "New Caledonia",
    ct_name: "New Caledonia",
    alias: null,
    race: "MU",
    ethnicity: "Kanak",
  },
  {
    art_country_id: "ACRF4B7D8F0DD",
    iso_num: 554,
    alpha2: "NZ",
    alpha3: "NZL",
    name: "New Zealand",
    ct_name: "New Zealand",
    alias: null,
    race: "MU",
    ethnicity: "European",
  },
  {
    art_country_id: "ACRF62EE1DC02",
    iso_num: 558,
    alpha2: "NI",
    alpha3: "NIC",
    name: "Nicaragua",
    ct_name: "Nicaragua",
    alias: null,
    race: "MU",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACR5D81D3011E",
    iso_num: 562,
    alpha2: "NE",
    alpha3: "NER",
    name: "Niger",
    ct_name: "Niger",
    alias: null,
    race: "B",
    ethnicity: "Hausa",
  },
  {
    art_country_id: "ACR62293B04D5",
    iso_num: 566,
    alpha2: "NG",
    alpha3: "NGA",
    name: "Nigeria",
    ct_name: "Nigeria",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRF94936841D",
    iso_num: 570,
    alpha2: "NU",
    alpha3: "NIU",
    name: "Niue",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Niuean",
  },
  {
    art_country_id: "ACRBC59A44A35",
    iso_num: 574,
    alpha2: "NF",
    alpha3: "NFK",
    name: "Norfolk Island",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "Australian",
  },
  {
    art_country_id: "ACRE094BD4EB7",
    iso_num: 807,
    alpha2: "MK",
    alpha3: "MKD",
    name: "North Macedonia",
    ct_name: "North Macedonia",
    alias: "Macedonia, The Former Yugoslav Republic of",
    race: "C",
    ethnicity: "Macedonians",
  },
  {
    art_country_id: "ACR9CBF40310A",
    iso_num: 580,
    alpha2: "MP",
    alpha3: "MNP",
    name: "Northern Mariana Islands",
    ct_name: "Northern Mariana Islands",
    alias: null,
    race: "MU",
    ethnicity: "Filipino",
  },
  {
    art_country_id: "ACR34CE6BA934",
    iso_num: 578,
    alpha2: "NO",
    alpha3: "NOR",
    name: "Norway",
    ct_name: "Norway",
    alias: null,
    race: "C",
    ethnicity: "Norwegian",
  },
  {
    art_country_id: "ACRA1540398FE",
    iso_num: 512,
    alpha2: "OM",
    alpha3: "OMN",
    name: "Oman",
    ct_name: "Oman",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR93BCFB6080",
    iso_num: 586,
    alpha2: "PK",
    alpha3: "PAK",
    name: "Pakistan",
    ct_name: "Pakistan",
    alias: null,
    race: "C",
    ethnicity: "Punjabi",
  },
  {
    art_country_id: "ACRFEE66A6B6B",
    iso_num: 585,
    alpha2: "PW",
    alpha3: "PLW",
    name: "Palau",
    ct_name: "Palau",
    alias: null,
    race: "MU",
    ethnicity: "Palauan",
  },
  {
    art_country_id: "ACRE3DD46DC2E",
    iso_num: 275,
    alpha2: "PS",
    alpha3: "PSE",
    name: "Palestine, State of",
    ct_name: "Palestinian Territories, Occupied",
    alias: "Palestinian Territory, occupied",
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR2438736745",
    iso_num: 591,
    alpha2: "PA",
    alpha3: "PAN",
    name: "Panama",
    ct_name: "Panama",
    alias: null,
    race: "MU",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACR408007ED68",
    iso_num: 598,
    alpha2: "PG",
    alpha3: "PNG",
    name: "Papua New Guinea",
    ct_name: "Papua New Guinea",
    alias: null,
    race: "I",
    ethnicity: "Melanesian",
  },
  {
    art_country_id: "ACR9408767EE2",
    iso_num: 600,
    alpha2: "PY",
    alpha3: "PRY",
    name: "Paraguay",
    ct_name: "Paraguay",
    alias: null,
    race: "MU",
    ethnicity: "Mestizo",
  },
  {
    art_country_id: "ACR663A9160B3",
    iso_num: 604,
    alpha2: "PE",
    alpha3: "PER",
    name: "Peru",
    ct_name: "Peru",
    alias: null,
    race: "MU",
    ethnicity: "Amerindian",
  },
  {
    art_country_id: "ACR0FC5C6B242",
    iso_num: 608,
    alpha2: "PH",
    alpha3: "PHL",
    name: "Philippines",
    ct_name: "Philippines",
    alias: null,
    race: "A",
    ethnicity: "Tagalog",
  },
  {
    art_country_id: "ACR24448BFD70",
    iso_num: 612,
    alpha2: "PN",
    alpha3: "PCN",
    name: "Pitcairn",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR53878B15FA",
    iso_num: 616,
    alpha2: "PL",
    alpha3: "POL",
    name: "Poland",
    ct_name: "Poland",
    alias: null,
    race: "C",
    ethnicity: "Polish",
  },
  {
    art_country_id: "ACR90CAAF0DA0",
    iso_num: 620,
    alpha2: "PT",
    alpha3: "PRT",
    name: "Portugal",
    ct_name: "Portugal",
    alias: null,
    race: "C",
    ethnicity: " Portuguese",
  },
  {
    art_country_id: "ACR707331B27F",
    iso_num: 630,
    alpha2: "PR",
    alpha3: "PRI",
    name: "Puerto Rico",
    ct_name: "Puerto Rico",
    alias: null,
    race: "C",
    ethnicity: "Spanish",
  },
  {
    art_country_id: "ACRF35A87285B",
    iso_num: 634,
    alpha2: "QA",
    alpha3: "QAT",
    name: "Qatar",
    ct_name: "Qatar",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACRC5661D4267",
    iso_num: 638,
    alpha2: "RE",
    alpha3: "REU",
    name: "Réunion",
    ct_name: "Réunion",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR9AD1FF7D5E",
    iso_num: 642,
    alpha2: "RO",
    alpha3: "ROU",
    name: "Romania",
    ct_name: "Romania",
    alias: null,
    race: "C",
    ethnicity: "Romanian",
  },
  {
    art_country_id: "ACRED747139AA",
    iso_num: 643,
    alpha2: "RU",
    alpha3: "RUS",
    name: "Russian Federation",
    ct_name: "Russian Federation",
    alias: null,
    race: "C",
    ethnicity: "Russian",
  },
  {
    art_country_id: "ACR9D60AF635F",
    iso_num: 646,
    alpha2: "RW",
    alpha3: "RWA",
    name: "Rwanda",
    ct_name: "Rwanda",
    alias: null,
    race: "B",
    ethnicity: "Hutu",
  },
  {
    art_country_id: "ACR866459DFDF",
    iso_num: 652,
    alpha2: "BL",
    alpha3: "BLM",
    name: "Saint Barthélemy",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR3C4EC2E7EB",
    iso_num: 654,
    alpha2: "SH",
    alpha3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR2F815578AB",
    iso_num: 659,
    alpha2: "KN",
    alpha3: "KNA",
    name: "Saint Kitts and Nevis",
    ct_name: "Saint Kitts and Nevis",
    alias: null,
    race: "B",
    ethnicity: "Afro-Caribbeans",
  },
  {
    art_country_id: "ACRBAB3B7829E",
    iso_num: 662,
    alpha2: "LC",
    alpha3: "LCA",
    name: "Saint Lucia",
    ct_name: "Saint Lucia",
    alias: null,
    race: "B",
    ethnicity: "Afro-Caribbeans",
  },
  {
    art_country_id: "ACRF3ABF1DD02",
    iso_num: 663,
    alpha2: "MF",
    alpha3: "MAF",
    name: "Saint Martin (French part)",
    ct_name: "Saint Martin",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR4A101833F0",
    iso_num: 666,
    alpha2: "PM",
    alpha3: "SPM",
    name: "Saint Pierre and Miquelon",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "Basques",
  },
  {
    art_country_id: "ACRE6553E5BD5",
    iso_num: 670,
    alpha2: "VC",
    alpha3: "VCT",
    name: "Saint Vincent and the Grenadines",
    ct_name: "Saint Vincent and the Grenadines",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR6C0D0593C7",
    iso_num: 882,
    alpha2: "WS",
    alpha3: "WSM",
    name: "Samoa",
    ct_name: "Samoa",
    alias: null,
    race: "I",
    ethnicity: "Samoan",
  },
  {
    art_country_id: "ACRCB01E5AAF9",
    iso_num: 674,
    alpha2: "SM",
    alpha3: "SMR",
    name: "San Marino",
    ct_name: "San Marino",
    alias: null,
    race: "C",
    ethnicity: "Sammarinesi",
  },
  {
    art_country_id: "ACR68D5D80788",
    iso_num: 678,
    alpha2: "ST",
    alpha3: "STP",
    name: "Sao Tome and Principe",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRF7C9D6EB21",
    iso_num: 682,
    alpha2: "SA",
    alpha3: "SAU",
    name: "Saudi Arabia",
    ct_name: "Saudi Arabia",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACRF127848557",
    iso_num: 686,
    alpha2: "SN",
    alpha3: "SEN",
    name: "Senegal",
    ct_name: "Senegal",
    alias: null,
    race: "B",
    ethnicity: "Wolof",
  },
  {
    art_country_id: "ACR5D1307291B",
    iso_num: 688,
    alpha2: "RS",
    alpha3: "SRB",
    name: "Serbia",
    ct_name: "Serbia",
    alias: "Former Serbia and Montenegro",
    race: "C",
    ethnicity: "Serb",
  },
  {
    art_country_id: "ACRB286FE702A",
    iso_num: 690,
    alpha2: "SC",
    alpha3: "SYC",
    name: "Seychelles",
    ct_name: "Seychelles",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRFDD948FBDB",
    iso_num: 694,
    alpha2: "SL",
    alpha3: "SLE",
    name: "Sierra Leone",
    ct_name: "Sierra Leone",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR975AB09099",
    iso_num: 702,
    alpha2: "SG",
    alpha3: "SGP",
    name: "Singapore",
    ct_name: "Singapore",
    alias: null,
    race: "A",
    ethnicity: "Chinese",
  },
  {
    art_country_id: "ACR751443F279",
    iso_num: 534,
    alpha2: "SX",
    alpha3: "SXM",
    name: "Sint Maarten (Dutch part)",
    ct_name: null,
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRFC40941168",
    iso_num: 703,
    alpha2: "SK",
    alpha3: "SVK",
    name: "Slovakia",
    ct_name: "Slovakia",
    alias: null,
    race: "C",
    ethnicity: "Slovak",
  },
  {
    art_country_id: "ACR1D3EFB3C87",
    iso_num: 705,
    alpha2: "SI",
    alpha3: "SVN",
    name: "Slovenia",
    ct_name: "Slovenia",
    alias: null,
    race: "C",
    ethnicity: "Slovene",
  },
  {
    art_country_id: "ACR468E509BEF",
    iso_num: 90,
    alpha2: "SB",
    alpha3: "SLB",
    name: "Solomon Islands",
    ct_name: "Solomon Islands",
    alias: null,
    race: "I",
    ethnicity: "Melanesian",
  },
  {
    art_country_id: "ACRFC7DE9039A",
    iso_num: 706,
    alpha2: "SO",
    alpha3: "SOM",
    name: "Somalia",
    ct_name: "Somalia",
    alias: null,
    race: "B",
    ethnicity: "Somali",
  },
  {
    art_country_id: "ACRFDEC5211F2",
    iso_num: 710,
    alpha2: "ZA",
    alpha3: "ZAF",
    name: "South Africa",
    ct_name: "South Africa",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR287F1B6FAC",
    iso_num: 239,
    alpha2: "GS",
    alpha3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    ct_name: "South Georgia and the South Sandwich Islands",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRBA2E4EB20D",
    iso_num: 728,
    alpha2: "SS",
    alpha3: "SSD",
    name: "South Sudan",
    ct_name: "South Sudan",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRDC6C041A27",
    iso_num: 724,
    alpha2: "ES",
    alpha3: "ESP",
    name: "Spain",
    ct_name: "Spain",
    alias: null,
    race: "C",
    ethnicity: "Spanish",
  },
  {
    art_country_id: "ACRA535BFDAEA",
    iso_num: 144,
    alpha2: "LK",
    alpha3: "LKA",
    name: "Sri Lanka",
    ct_name: "Sri Lanka",
    alias: null,
    race: "A",
    ethnicity: "Sinhalese",
  },
  {
    art_country_id: "ACRD0892C0E28",
    iso_num: 729,
    alpha2: "SD",
    alpha3: "SDN",
    name: "Sudan",
    ct_name: "Sudan",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRCF51C02A8A",
    iso_num: 740,
    alpha2: "SR",
    alpha3: "SUR",
    name: "Suriname",
    ct_name: "Suriname",
    alias: null,
    race: "MU",
    ethnicity: "Hindustanis",
  },
  {
    art_country_id: "ACR9F346AFB16",
    iso_num: 744,
    alpha2: "SJ",
    alpha3: "SJM",
    name: "Svalbard and Jan Mayen",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "Norwegian",
  },
  {
    art_country_id: "ACRFA99BAC8D8",
    iso_num: 752,
    alpha2: "SE",
    alpha3: "SWE",
    name: "Sweden",
    ct_name: "Sweden",
    alias: null,
    race: "C",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR3FA46E15BA",
    iso_num: 756,
    alpha2: "CH",
    alpha3: "CHE",
    name: "Switzerland",
    ct_name: "Switzerland",
    alias: null,
    race: "C",
    ethnicity: "German",
  },
  {
    art_country_id: "ACR51852FDAC0",
    iso_num: 760,
    alpha2: "SY",
    alpha3: "SYR",
    name: "Syrian Arab Republic",
    ct_name: "Syrian Arab Republic",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR7716094E5C",
    iso_num: 158,
    alpha2: "TW",
    alpha3: "TWN",
    name: "Taiwan, Province of China",
    ct_name: "Taiwan",
    alias: null,
    race: "A",
    ethnicity: "Taiwanese",
  },
  {
    art_country_id: "ACR6433CF07DC",
    iso_num: 762,
    alpha2: "TJ",
    alpha3: "TJK",
    name: "Tajikistan",
    ct_name: "Tajikistan",
    alias: null,
    race: "MU",
    ethnicity: "Tajik",
  },
  {
    art_country_id: "ACR52DA790B31",
    iso_num: 834,
    alpha2: "TZ",
    alpha3: "TZA",
    name: "Tanzania, United Republic of",
    ct_name: "Tanzania",
    alias: null,
    race: "B",
    ethnicity: "Bantu",
  },
  {
    art_country_id: "ACR809BD25D60",
    iso_num: 764,
    alpha2: "TH",
    alpha3: "THA",
    name: "Thailand",
    ct_name: "Thailand",
    alias: null,
    race: "A",
    ethnicity: "Thai",
  },
  {
    art_country_id: "ACR941759A53E",
    iso_num: 626,
    alpha2: "TL",
    alpha3: "TLS",
    name: "Timor-Leste",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Austronesian",
  },
  {
    art_country_id: "ACR545E7A1F8B",
    iso_num: 768,
    alpha2: "TG",
    alpha3: "TGO",
    name: "Togo",
    ct_name: "Togo",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR5B6222A4C6",
    iso_num: 772,
    alpha2: "TK",
    alpha3: "TKL",
    name: "Tokelau",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Polynesian",
  },
  {
    art_country_id: "ACRDEFC2DF796",
    iso_num: 776,
    alpha2: "TO",
    alpha3: "TON",
    name: "Tonga",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Polynesian",
  },
  {
    art_country_id: "ACR4293E479A2",
    iso_num: 780,
    alpha2: "TT",
    alpha3: "TTO",
    name: "Trinidad and Tobago",
    ct_name: "Trinidad and Tobago",
    alias: null,
    race: "C",
    ethnicity: "Indian",
  },
  {
    art_country_id: "ACR1D5FACB559",
    iso_num: 788,
    alpha2: "TN",
    alpha3: "TUN",
    name: "Tunisia",
    ct_name: "Tunisia",
    alias: null,
    race: "MU",
    ethnicity: "Arab-Berber",
  },
  {
    art_country_id: "ACR85CC4E4C07",
    iso_num: 792,
    alpha2: "TR",
    alpha3: "TUR",
    name: "Turkey",
    ct_name: "Turkey",
    alias: null,
    race: "C",
    ethnicity: "Turkish",
  },
  {
    art_country_id: "ACREF57D5440B",
    iso_num: 795,
    alpha2: "TM",
    alpha3: "TKM",
    name: "Turkmenistan",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "Turkmen",
  },
  {
    art_country_id: "ACR35B1F098BF",
    iso_num: 796,
    alpha2: "TC",
    alpha3: "TCA",
    name: "Turks and Caicos Islands",
    ct_name: null,
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRED73F62BC4",
    iso_num: 798,
    alpha2: "TV",
    alpha3: "TUV",
    name: "Tuvalu",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Polynesian",
  },
  {
    art_country_id: "ACR801632B0B4",
    iso_num: 800,
    alpha2: "UG",
    alpha3: "UGA",
    name: "Uganda",
    ct_name: "Uganda",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR2F3190C85C",
    iso_num: 804,
    alpha2: "UA",
    alpha3: "UKR",
    name: "Ukraine",
    ct_name: "Ukraine",
    alias: null,
    race: "C",
    ethnicity: "Ukrainian",
  },
  {
    art_country_id: "ACRD4F951A60E",
    iso_num: 784,
    alpha2: "AE",
    alpha3: "ARE",
    name: "United Arab Emirates",
    ct_name: "United Arab Emirates",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR9BFBC1F0AC",
    iso_num: 826,
    alpha2: "GB",
    alpha3: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland",
    ct_name: "United Kingdom",
    alias: null,
    race: "C",
    ethnicity: "English",
  },
  {
    art_country_id: "ACRA03A265C37",
    iso_num: 840,
    alpha2: "US",
    alpha3: "USA",
    name: "United States of America",
    ct_name: "United States",
    alias: null,
    race: "MU",
    ethnicity: "White",
  },
  {
    art_country_id: "ACR8D84606FBD",
    iso_num: 581,
    alpha2: "UM",
    alpha3: "UMI",
    name: "United States Minor Outlying Islands",
    ct_name: "United States Minor Outlying Islands",
    alias: null,
    race: "MU",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR98BF14CFB2",
    iso_num: 858,
    alpha2: "UY",
    alpha3: "URY",
    name: "Uruguay",
    ct_name: "Uruguay",
    alias: null,
    race: "C",
    ethnicity: "White",
  },
  {
    art_country_id: "ACRAAB6A8A985",
    iso_num: 860,
    alpha2: "UZ",
    alpha3: "UZB",
    name: "Uzbekistan",
    ct_name: "Uzbekistan",
    alias: null,
    race: "A",
    ethnicity: "Uzbek",
  },
  {
    art_country_id: "ACRD295D6DA98",
    iso_num: 548,
    alpha2: "VU",
    alpha3: "VUT",
    name: "Vanuatu",
    ct_name: "Vanuatu",
    alias: null,
    race: "I",
    ethnicity: "Ni-Vanuatu",
  },
  {
    art_country_id: "ACR470C642587",
    iso_num: 862,
    alpha2: "VE",
    alpha3: "VEN",
    name: "Venezuela (Bolivarian Republic of)",
    ct_name: "Venezuela",
    alias: null,
    race: "C",
    ethnicity: "Spanish",
  },
  {
    art_country_id: "ACR779C80FFE3",
    iso_num: 704,
    alpha2: "VN",
    alpha3: "VNM",
    name: "Viet Nam",
    ct_name: "Vietnam",
    alias: null,
    race: "A",
    ethnicity: "Viet",
  },
  {
    art_country_id: "ACRD805DDDB49",
    iso_num: 92,
    alpha2: "VG",
    alpha3: "VGB",
    name: "Virgin Islands (British)",
    ct_name: null,
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR994BC83132",
    iso_num: 850,
    alpha2: "VI",
    alpha3: "VIR",
    name: "Virgin Islands (U.S.)",
    ct_name: "Virgin Islands (U.S.)",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACR81CB6B36AE",
    iso_num: 876,
    alpha2: "WF",
    alpha3: "WLF",
    name: "Wallis and Futuna",
    ct_name: null,
    alias: null,
    race: "I",
    ethnicity: "Polynesian",
  },
  {
    art_country_id: "ACRE231659A3D",
    iso_num: 732,
    alpha2: "EH",
    alpha3: "ESH",
    name: "Western Sahara",
    ct_name: null,
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR27487E3DC2",
    iso_num: 887,
    alpha2: "YE",
    alpha3: "YEM",
    name: "Yemen",
    ct_name: "Yemen",
    alias: null,
    race: "C",
    ethnicity: "Arab",
  },
  {
    art_country_id: "ACR6598865761",
    iso_num: 891,
    alpha2: "YU",
    alpha3: "YUG",
    name: "Yugoslavia",
    ct_name: "Former Yugoslavia",
    alias: null,
    race: "C",
    ethnicity: "Serbs",
  },
  {
    art_country_id: "ACRA19A8CE596",
    iso_num: 894,
    alpha2: "ZM",
    alpha3: "ZMB",
    name: "Zambia",
    ct_name: "Zambia",
    alias: null,
    race: "B",
    ethnicity: "N/A",
  },
  {
    art_country_id: "ACRFED9F77E4E",
    iso_num: 716,
    alpha2: "ZW",
    alpha3: "ZWE",
    name: "Zimbabwe",
    ct_name: "Zimbabwe",
    alias: null,
    race: "B",
    ethnicity: "Shona",
  },
  {
    art_country_id: "ACR068AC95737",
    iso_num: 1000,
    alpha2: "XK",
    alpha3: "XKX",
    name: "Kosovo",
    ct_name: "Kosovo",
    alias: null,
    race: "C",
    ethnicity: "Kosovar",
  },
];
