import { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Button, Card, Modal, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Bar } from "@ant-design/plots";
import Break from "../../../../assets/components/other/Break";
import { StudySummary } from "../../../../data/interfaces/study/Study";
import SearchTags from "../modules/SearchTags";
import { interpolateTurbo } from "d3";
import { getValueByKeywordAndEnumKey } from "../../../../assets/js/utils";
import { PhaseEnum, StatusEnum } from "../../../../data/interfaces/study/StudyEnums";

interface TimelineProps {
  isVisible: boolean;
  onClose: () => void;
  data?: StudySummary[];
  updateData: () => void;
}

interface TimelineData {
  id: String;
  brief_title: string;
  phase: PhaseEnum;
  dates: string[];
  has_start_date: boolean;
  has_end_date: boolean;
  phase_id: string;
  status: StatusEnum;
  start_date: string;
  completion_date: string;
  sponsor: string;
}

const { Text } = Typography;

const Timeline: React.FC<TimelineProps> = ({ isVisible, onClose, data, updateData }) => {
  const [fullData, setFullData] = useState<TimelineData[]>([]);
  const [filteredData, setFilteredData] = useState<TimelineData[]>([]);
  const [activeValues, setActiveValues] = useState<string[]>([]);

  function getTooltip(nameKey: any, myArray: any) {
    const tempArray = [];
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].id === nameKey) {
        tempArray.push(myArray[i].brief_title);
        tempArray.push(myArray[i].phase);
        tempArray.push(myArray[i].start_date);
        tempArray.push(myArray[i].completion_date);
        tempArray.push(myArray[i].sponsor);
        tempArray.push(myArray[i].status);
      }
    }
    return tempArray;
  }

  let minDate = "2050-01-01";
  let maxDate = "1970-01-01";
  const timelineData: TimelineData[] = [];

  // Define minDate and MaxDate
  data?.forEach((study) => {
    const startDateObj = study.dates.find((date) => {
      return date.name === "SD";
    });
    const endDateObj = study.dates.find((date) => {
      return date.name === "PCD";
    });
    if (startDateObj && startDateObj.date < minDate) {
      minDate = startDateObj.date;
    }
    if (endDateObj && endDateObj.date > maxDate) {
      maxDate = endDateObj.date;
    }
  });

  data?.forEach((study) => {
    const startDateObj = study.dates.find((date) => {
      return date.name === "SD";
    });
    const endDateObj = study.dates.find((date) => {
      return date.name === "PCD";
    });
    let studyStartDate = startDateObj && startDateObj.date ? startDateObj.date : minDate;
    let hasStartDate = startDateObj && startDateObj.date ? true : false;
    let studyEndDate = endDateObj && endDateObj.date ? endDateObj.date : maxDate;
    let hasEndDate = endDateObj && endDateObj.date ? true : false;

    const timeline = {
      id: study.id_list.primary_id,
      dates: [studyStartDate.substring(0, 10), studyEndDate.substring(0, 10)],
      has_start_date: hasStartDate,
      has_end_date: hasEndDate,
      phase: study.phase,
      phase_id: study.phase + "-" + study.id_list.primary_id,
      brief_title: study.brief_title,
      status: study.overall_status,
      start_date: startDateObj && startDateObj.date ? startDateObj.date.substring(0, 10) : "Unknown",
      completion_date: endDateObj && endDateObj.date ? endDateObj.date.substring(0, 10) : "Unknown",
      sponsor: study.sponsors.map((s) => s.agency).join(", "),
    };
    timelineData.push(timeline);
  });
  // setFilteredData(timelineData);
  // setFullData(timelineData);

  // const processValue = (value: string, addOrRemove: "add" | "remove") => {
  //   if (addOrRemove === "add") {
  //     if (!activeValues.includes(value)) {
  //       let updateValues = [...activeValues, value];
  //     }
  //   } else {
  //   }
  // };

  // const filterData = (value: string, addOrRemove: "add" | "remove") => {
  //   if (addOrRemove === "add") {
  //   }
  // };

  const getPhaseToColour = (phase_id: string) => {
    const phase = phase_id.split("-")[0];
    const hasStartDate = true;
    const hasEndDate = true;
    const startOpacity = hasStartDate ? 0.95 : 0.2;
    const endOpacity = hasEndDate ? 0.95 : 0.2;
    const getColour = (interpolationFactor: number, opacity: number) => {
      return interpolateTurbo(interpolationFactor).replace(")", ", " + opacity + ")");
    };
    const getString = (startFactor: number, endFactor: number) => {
      return "l(180) 0:" + getColour(startFactor, startOpacity) + " 1:" + getColour(endFactor, endOpacity);
    };
    const phaseToColour: any = {
      EP1: getString(0.1, 0.15),
      P1: getString(0.16, 0.2),
      P12: getString(0.21, 0.25),
      P2: getString(0.26, 0.3),
      P23: getString(0.31, 0.35),
      P3: getString(0.36, 0.4),
      P4: getString(0.41, 0.55),
      NA: "#aaa",
    };
    return phaseToColour[phase];
  };

  const getItems = () => {
    let allPhases: string[] = [];
    timelineData.forEach((entry) => {
      if (!allPhases.includes(entry.phase.toString())) {
        allPhases.push(entry.phase.toString());
      }
    });
    allPhases.sort();
    if (allPhases.indexOf("NA") !== -1) {
      allPhases = allPhases.filter((item) => {
        return item !== "NA";
      });
      allPhases.push("NA");
    }
    const items = allPhases.map((phase) => {
      const item = {
        id: phase,
        name: getValueByKeywordAndEnumKey("phase", phase),
        marker: { style: { fill: getPhaseToColour(phase) }, spacing: 5 },
        value: phase,
      };
      return item;
    });
    return items;
  };

  const items: any[] = getItems();

  const config = {
    color: function color(study: any) {
      const { phase_id, has_start_date, has_end_date } = study;
      return getPhaseToColour(phase_id);
      // let startOpacity = has_start_date ? 0.95 : 0.2;
      // let endOpacity = has_end_date ? 0.95 : 0.2;
      // if (phase === "EP1") {
      //   return "#17EAD9";
      // }
      // if (phase === "P1") {
      //   return "#00c4ff";
      // }
      // if (phase === "P12") {
      //   return "#1890FF";
      // }
      // if (phase === "P2") {
      //   return "#677DE9";
      // }
      // if (phase === "P23") {
      //   return "#756EED";
      // }
      // if (phase === "P3") {
      //   return "#A73EC7";
      // }
      // if (phase === "P4") {
      //   // return "l(180) 0:#48a9fe 1:#02c0db";
      //   return "l(180) 0:#48a9fe 1:rgba(2, 192, 219, 0.2)";
      // }
      // if (phase === "NA") {
      //   return "#414756";
      // }
      // return "#000";
    },
    minDate,
    maxDate,
    legend: {
      offsetX: 8,
      custom: true,
      items,
      radio: undefined,
    },
  };

  return (
    <Modal
      key="timeline-modal"
      style={{ top: 50 }}
      width={1000}
      title="Timeline"
      closeIcon={<CloseCircleOutlined />}
      visible={isVisible}
      bodyStyle={{
        backgroundColor: "lightgray",
        boxShadow: "inset 0 -3px 5px 0px rgb(0 0 0 / 20%), inset 0 3px 5px 2px rgb(0 0 0 / 20%)",
      }}
      footer={[
        <Button key="btn-ok" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      <SearchTags updateData={updateData} areTagsVisible={true} />
      <Card key="timeline" className="slightly-frosted-card" bordered={false}>
        <Bar
          data={timelineData}
          color={config.color}
          xField="dates"
          yField="id"
          seriesField="phase_id"
          maxBarWidth={10}
          barStyle={{ radius: [4, 4, 4, 4] }}
          xAxis={{
            type: "time",
            // mask: "YYYY-MM-DD",
            min: config.minDate.substring(0, 10),
            max: config.maxDate.substring(0, 10),
          }}
          height={350}
          // tooltip={config.tooltip}
          tooltip={{
            // position: "bottom",
            customContent: (title) => {
              const resultObject = getTooltip(title, timelineData);
              const briefTitle = resultObject[0];
              const phase = resultObject[1];
              const startDate = resultObject[2];
              const completionDate = resultObject[3];
              const sponsor = resultObject[4];
              const status = resultObject[5];
              return ReactDOMServer.renderToStaticMarkup(
                <Card size="small" title={title} bordered={false} bodyStyle={{ width: "400px" }}>
                  <Text strong>Brief title: </Text>
                  <Text>{briefTitle}</Text>
                  <Break height={0} />
                  <Text strong>Phase: </Text>
                  <Text>{getValueByKeywordAndEnumKey("phase", phase)}</Text>
                  <Break height={0} />
                  <Text strong>Start date: </Text>
                  <Text>{startDate}</Text>
                  <Break height={0} />
                  <Text strong>Completion date: </Text>
                  <Text>{completionDate}</Text>
                  <Break height={0} />
                  <Text strong>Sponsor(s): </Text>
                  <Text>{sponsor}</Text>
                  <Break height={0} />
                  <Text strong>Status: </Text>
                  <Text>{getValueByKeywordAndEnumKey("status", status)}</Text>
                  <Break height={0} />
                </Card>
              );
            },
          }}
          isRange
          scrollbar={
            data?.length && data?.length > 10
              ? {
                  type: "vertical",
                }
              : undefined
          }
          // legend={{
          //   position: "top-left",
          // }}
          legend={{ ...config.legend, position: "top-left" }}
          onReady={(plot) => {
            plot.on("legend-item-name:click", (evt: any) => {
              const { x, y } = evt;
              let tgtId = evt.target?.cfg?.delegateObject?.item?.id;
              let tgtValue = evt.target?.cfg?.delegateObject?.item?.value;
              let tgtUnchecked = evt.target?.cfg?.delegateObject?.item?.unchecked;
              // const { xField } = plot.options;
              // const tooltipData = plot.chart.getTooltipItems({ x, y });
              console.log("Legend Item Mouse Down", tgtId, tgtValue, tgtUnchecked);
            });
            plot.on("legend:click", (...args: any[]) => {
              console.log("Legend click", ...args);
            });
          }}
        />
      </Card>
    </Modal>
  );
};

export default Timeline;
