import { useContext } from "react";
import { Card, Col, Divider, Row, Tag, Typography } from "antd";
import Gap from "../../../../assets/components/other/Gap";
import SearchContext from "../../../../data/api/SearchContext";
import { capitalizeFirstLetter, getEnumOptionsByKeyword, getMeshHeadingByUi } from "../../../../assets/js/utils";
import { cloneDeep } from "lodash";

interface SearchTagsProps {
  areTagsVisible: boolean;
  updateData: () => void;
}

const SearchTags: React.FC<SearchTagsProps> = ({ areTagsVisible, updateData }) => {
  const { Text } = Typography;
  const { getSearchParams, setSearchParams } = useContext(SearchContext);
  const searchParams = getSearchParams();

  const onTagClose = (key: string, value: string) => {
    console.log("Tag key-value", key, value);
    const params = cloneDeep(searchParams);
    if (Array.isArray(searchParams[key])) {
      if (params[key].length === 1) {
        delete params[key];
      } else {
        const enums = getEnumOptionsByKeyword(key)?.options;
        if (enums) {
          const index = params[key].indexOf(value);
          if (index > -1) {
            params[key].splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    } else {
      delete params[key];
    }
    console.log("Params in tags", params);
    setSearchParams(params);
    updateData();
  };

  let paramTags: JSX.Element[] = [];
  Object.entries(searchParams).forEach(([key, value], index) => {
    let tags: JSX.Element[] = [];
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        tags.push(
          <Tag key={item + "-" + index} closable onClose={() => onTagClose(key, item)}>
            {getEnumOptionsByKeyword(key)?.options[item]}
          </Tag>
        );
      });
    } else {
      const isEnum = key === "type" || key === "area" || key === "sex" || key === "country";
      const isCondition = key === "condition";
      tags.push(
        <Tag key={key + "-" + index} closable onClose={() => onTagClose(key, value)}>
          {isEnum ? getEnumOptionsByKeyword(key)?.options[value] : isCondition ? getMeshHeadingByUi(value) : value}
        </Tag>
      );
    }
    const isLastEntry = Object.entries(searchParams).length === index + 1;
    paramTags.push(
      <span key={"div" + index}>
        <Gap key={key + "-gap-" + index} width={8} />
        <Text key={key + "-text-" + index} strong>
          {key === "roa" ? "Routes of administration" : capitalizeFirstLetter(key)}:{" "}
        </Text>
        <Gap key={key + "-gap2-" + index} width={4} />
        {tags}
        {!isLastEntry && <Divider key={key + "-divider-" + index} type="vertical" />}
      </span>
    );
  });

  return (
    <Row className="mg-btm-16" key="controls" gutter={16} style={!areTagsVisible ? { display: "none" } : {}}>
      <Col span={24}>
        <Card bordered={false}>
          {paramTags.length > 0 ? (
            paramTags
          ) : (
            <Text className="mg-btm-0" type="secondary">
              No parameters specified
            </Text>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SearchTags;
