import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import PublicLayoutLogo from "./logo/PublicLayoutLogo";
import "./PublicLayout.css";

const { Footer, Header, Content } = Layout;

const PublicLayout = () => {
  return (
    <Layout className="layout" style={{ backgroundColor: "white" }}>
      <Header className="public-header">
        <PublicLayoutLogo />
      </Header>
      <Content className="public-content">
        <div style={{ padding: 24, minHeight: "90vh" }}>
          <Outlet />
        </div>
      </Content>
      <Footer
        className="public-footer"
        style={{ textAlign: "center", backgroundColor: "white" }}
      >
        Aucrum ©2022
      </Footer>
    </Layout>
  );
};

export default PublicLayout;
